import {
  MeasureReducer,
  ReportBuilderDataSourceId,
} from '@principle-theorem/principle-core/interfaces';
import { FactTables, toMeasureBuilderData } from '@principle-theorem/reporting';
import { ReportBuilderColumns } from './report-builder-columns';
import { type IReportBuilderDataSource } from './report-builder-data-source';
import { ReportBuilderChartFilters } from './report-builder-chart-filters';
import { ChartFilters } from './chart-filters';
import { ReportBuilderGroups } from './report-builder-groups';

const factTable = FactTables.appointmentServiceCodeEvent.latestEvent;

export const APPOINTMENT_SERVICE_CODES_REPORT_BUILDER_DATA_SOURCE: IReportBuilderDataSource =
  {
    id: ReportBuilderDataSourceId.TreatmentStepServiceCodes,
    name: 'Service Codes by Appointment',
    description:
      'One result per service code within Principle. This includes information about the appointment, practice, and patient associated with each service code.',
    factTable,
    queryableTimestamps: [{ property: factTable.appointment.date }],
    groupByOptions: [
      ReportBuilderGroups.toGroup('Common', [
        ReportBuilderGroups.toOption(factTable.practitioner),
        ReportBuilderGroups.toOption(factTable.practice.name),
        ReportBuilderGroups.toOption(factTable.brand.name),
        ReportBuilderGroups.toOption(factTable.code),
        ReportBuilderGroups.toOption(factTable.codeType),
        ReportBuilderGroups.toOption(factTable.treatmentName, [
          MeasureReducer.Count,
        ]),
        ReportBuilderGroups.toOption(factTable.treatmentCategory, [
          MeasureReducer.Count,
        ]),
        ReportBuilderGroups.toOption(factTable.chartedSurfaces, [
          MeasureReducer.Count,
        ]),
        ReportBuilderGroups.toOption(factTable.count),
      ]),
      ReportBuilderGroups.appointment(factTable.appointment),
      ReportBuilderGroups.invoice(factTable.invoice),
      ReportBuilderGroups.patient(factTable.patient),
    ],
    availableColumns: [
      ReportBuilderColumns.toColumnGroup('Service Code', [
        factTable.code,
        factTable.treatmentName,
        factTable.treatmentCategory,
        factTable.codeType,
        factTable.quantity,
        factTable.price,
        factTable.tax,
        factTable.chartedSurfaces,
        factTable.practitioner,
      ]),
      ReportBuilderColumns.toColumnGroup('Appointment', [
        ...ReportBuilderColumns.appointment(factTable.appointment),
        factTable.practice.name,
        factTable.treatmentPlan.name,
        factTable.treatmentPlanLink,
        factTable.appointmentLink,
      ]),
      ReportBuilderColumns.toColumnGroup('Invoice', [
        ...ReportBuilderColumns.invoice(factTable.invoice),
        factTable.invoiceLink,
      ]),
      ReportBuilderColumns.toColumnGroup('Patient', [
        ...ReportBuilderColumns.patient(factTable.patient),
        factTable.patientLink,
      ]),
    ],
    defaultColumns: ReportBuilderColumns.toReferences([
      factTable.appointment.date,
      factTable.patient.name,
      factTable.code,
      factTable.quantity,
      factTable.price,
      factTable.practitioner,
      factTable.appointment.practice,
      factTable.appointmentLink,
    ]),
    availableCharts: [
      ReportBuilderChartFilters.predefined(
        'overview.brand.price',
        factTable.brand.name,
        ChartFilters.numberChart('Total Price', factTable.brand.name, [
          toMeasureBuilderData(factTable.price),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'overview.practice.price',
        factTable.practice.name,
        ChartFilters.pieChart('Practice By Price', factTable.practice.name, [
          toMeasureBuilderData(factTable.price),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'overview.practice.count',
        factTable.practice.name,
        ChartFilters.pieChart('Practice By Count', factTable.practice.name, [
          toMeasureBuilderData(factTable.quantity),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'overview.totalCost',
        factTable.brand.name,
        ChartFilters.numberChart('Service Code Total', factTable.brand.name, [
          toMeasureBuilderData(factTable.price),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'overview.practitioner.price',
        factTable.practitioner,
        ChartFilters.rowChart(`Practitioner By Price`, factTable.practitioner, [
          toMeasureBuilderData(factTable.price),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'overview.practitioner.count',
        factTable.practitioner,
        ChartFilters.rowChart(`Practitioner By Count`, factTable.practitioner, [
          toMeasureBuilderData(factTable.quantity),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'overview.appointment.status',
        factTable.appointment.status,
        ChartFilters.pieChart(
          'Appointment Status',
          factTable.appointment.status,
          [toMeasureBuilderData(factTable.count)]
        )
      ),
      ReportBuilderChartFilters.predefined(
        'overview.invoice.status',
        factTable.invoice.status,
        ChartFilters.pieChart('Invoice Status', factTable.invoice.status, [
          toMeasureBuilderData(factTable.price),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'serviceCode.code.select',
        factTable.code,
        ChartFilters.selectMenu('Service Codes', factTable.code, [
          toMeasureBuilderData(factTable.quantity),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'serviceCode.code',
        factTable.code,
        ChartFilters.columnChart(
          'Service Codes By Price',
          factTable.code,
          [toMeasureBuilderData(factTable.price, MeasureReducer.Sum)],
          { width: ChartFilters.gridSpan(3), rotateXAxisLabels: true }
        )
      ),
      ReportBuilderChartFilters.predefined(
        'serviceCode.type',
        factTable.codeType,
        ChartFilters.pieChart('Service Code Type', factTable.codeType, [
          toMeasureBuilderData(factTable.quantity, MeasureReducer.Count),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'serviceCode.treatment.name',
        factTable.treatmentName,
        ChartFilters.columnChart(
          'Treatment',
          factTable.treatmentName,
          [toMeasureBuilderData(factTable.price, MeasureReducer.Sum)],
          { width: ChartFilters.gridSpan(3), rotateXAxisLabels: true }
        )
      ),
      ReportBuilderChartFilters.predefined(
        'serviceCode.treatmentCategory',
        factTable.treatmentCategory,
        ChartFilters.columnChart(
          'Treatment Category',
          factTable.treatmentCategory,
          [toMeasureBuilderData(factTable.price)],
          { rotateXAxisLabels: true },
          { groupLimit: 15, otherGroupLabel: 'Other', colourOverride: true }
        )
      ),
      ReportBuilderChartFilters.predefined(
        'appointment.tags',
        factTable.appointment.tags,
        ChartFilters.rowChart(
          'Tags',
          factTable.appointment.tags,
          [toMeasureBuilderData(factTable.price)],
          { groupLimit: 15, otherGroupLabel: 'Other' }
        )
      ),
      ReportBuilderChartFilters.predefined(
        'appointment.tags.select',
        factTable.appointment.tags,
        ChartFilters.selectMenu('Tags', factTable.appointment.tags, [
          toMeasureBuilderData(factTable.price),
        ])
      ),
      ...ReportBuilderChartFilters.patient(factTable.patient, factTable.price),
    ],
  };
