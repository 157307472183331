import {
  ChartableSurface,
  IAreaSummary,
  IAreaSummaryValue,
  IChartedRef,
  IChartedSurface,
  ToothSurface,
  TOOTH_SURFACE_SORT_MAP,
} from '@principle-theorem/principle-core/interfaces';
import { uniq, upperFirst } from 'lodash';
import { ChartedSurface } from './charted-surface';

export class AreaSummary {
  static init(overrides?: Partial<IAreaSummary>): IAreaSummary {
    return {
      label: '',
      values: [],
      ...overrides,
    };
  }

  static asCompact(summary: IAreaSummary): string {
    const isWholeMouthOnly: boolean = summary.values.every(
      (surface: IAreaSummaryValue) => {
        return ChartedSurface.isOnSurface(
          surface.surface,
          ChartableSurface.WholeMouth
        );
      }
    );
    if (isWholeMouthOnly) {
      return upperFirst(summary.label);
    }

    const values: string = uniq(
      summary.values.map((surface: IAreaSummaryValue) => {
        if (surface.label === String(ChartableSurface.Crown)) {
          return upperFirst(surface.label);
        }
        return surface.label.substr(0, 1).trim();
      })
    ).join('');

    if (!values) {
      return upperFirst(summary.label);
    }
    return `${upperFirst(summary.label)} ${values}`;
  }
}

export class ToothSummary implements IAreaSummary {
  label: string = '';
  values: IAreaSummaryValue[] = [];

  constructor(chartedRef: Partial<IChartedRef>, items: IChartedSurface[]) {
    if (!chartedRef.tooth) {
      return;
    }
    this.label = `${chartedRef.tooth.quadrant}${chartedRef.tooth.quadrantIndex}`;
    this.values = this._getToothSurfaces(items);
  }

  asCompact(): string {
    const values: string = this.values
      .filter(
        (surface: IAreaSummaryValue) =>
          !ChartedSurface.isOnSurface(
            surface.surface,
            ChartableSurface.WholeTooth
          )
      )
      .map((surface: IAreaSummaryValue) => surface.label.substr(0, 1).trim())
      .join('')
      .toUpperCase();
    return `${this.label} ${values}`;
  }

  private _getToothSurfaces(items: IChartedSurface[]): IAreaSummaryValue[] {
    const surfaces: IAreaSummaryValue[] = items.map((item: IChartedSurface) => {
      return {
        label:
          item.chartedRef.tooth && item.chartedRef.tooth.surface
            ? item.chartedRef.tooth.surface
            : '',
        surface: item,
      };
    });
    return surfaces.sort(this._sort);
  }

  private _sort(
    summaryA: IAreaSummaryValue,
    summaryB: IAreaSummaryValue
  ): number {
    if (
      !TOOTH_SURFACE_SORT_MAP[summaryA.label as ToothSurface] ||
      !TOOTH_SURFACE_SORT_MAP[summaryB.label as ToothSurface]
    ) {
      return 0;
    }
    const surfaceMapA: number =
      TOOTH_SURFACE_SORT_MAP[summaryA.label as ToothSurface];
    const surfaceMapB: number =
      TOOTH_SURFACE_SORT_MAP[summaryB.label as ToothSurface];

    return surfaceMapA > surfaceMapB ? 1 : -1;
  }
}
