import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  Appointment,
  Invoice,
  Patient,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  type IAppointment,
  type IPatient,
  type IPractice,
  isConfiguredReferrer,
  isContactReferrer,
  isPatientReferrer,
  PatientCollection,
} from '@principle-theorem/principle-core/interfaces';
import {
  type DocumentReference,
  type Timestamp,
  where,
  getDocs,
} from '@principle-theorem/shared';
import {
  collectionGroupQuery,
  isSameRef,
  type ITimePeriod,
  multiFilter,
  multiSwitchMap,
  type RequireProps,
  sortTimestampAsc,
  toTimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first, groupBy } from 'lodash';
import { from, type Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

export interface IReferralSourcesReportState {
  dateRange: ITimePeriod;
  summaries: IReferralSourcesGrouping[];
  selectedSummary?: IReferralSourcesGrouping;
  isLoading: boolean;
}

export interface IReferralSourcesGrouping {
  referralSource: string;
  records: IReferralSourcesReportRecord[];
  treatmentTotal: number;
}

export interface IReferralSourcesReportRecord {
  patient: WithRef<IPatient>;
  appointment: WithRef<RequireProps<IAppointment, 'event'>>;
  treatmentAmount: number;
}

export interface IReferralSourcesReportRequest {
  startDate: Timestamp;
  endDate: Timestamp;
  practiceRef: DocumentReference<IPractice>;
}

@Injectable()
export class ReferralSourcesReportStore extends ComponentStore<IReferralSourcesReportState> {
  readonly isLoading$ = this.select((data) => data.isLoading);
  readonly summaries$ = this.select((data) => data.summaries);
  readonly selectedSummary$ = this.select((data) => data.selectedSummary);
  readonly dateRange$ = this.select((data) => data.dateRange);

  readonly loadTransactions = this.effect(
    (request$: Observable<IReferralSourcesReportRequest>) =>
      request$.pipe(
        tap((request) =>
          this.setState({
            summaries: [],
            isLoading: true,
            dateRange: toTimePeriod(request.startDate, request.endDate),
          })
        ),
        switchMap((reportRequest) =>
          getDocs(
            collectionGroupQuery<RequireProps<IAppointment, 'event'>>(
              PatientCollection.Appointments
            ),
            where('practice.ref', '==', reportRequest.practiceRef),
            where('event.from', '>=', reportRequest.startDate),
            where('event.from', '<=', reportRequest.endDate),
            where('status', '==', AppointmentStatus.Complete)
          )
        ),
        multiSwitchMap((appointment) =>
          Appointment.patient$(appointment).pipe(
            switchMap((patient) =>
              from(Patient.firstScheduledAppointment(patient)).pipe(
                map((firstAppointment) => ({
                  patient,
                  appointment,
                  isFirstAppointment: isSameRef(appointment, firstAppointment),
                }))
              )
            )
          )
        ),
        multiFilter((record) => record.isFirstAppointment),
        multiSwitchMap((record) =>
          from(Appointment.invoice(record.appointment)).pipe(
            map(
              (invoice): IReferralSourcesReportRecord => ({
                ...record,
                treatmentAmount: invoice ? Invoice.total(invoice) : 0,
              })
            )
          )
        ),
        map((records) =>
          compact(
            Object.values(
              groupBy(records, (record) => record.patient.ref.path)
            ).map((record) => {
              record.sort((recordA, recordB) =>
                sortTimestampAsc(
                  recordA.appointment.event.from,
                  recordB.appointment.event.from
                )
              );
              return first(record);
            })
          )
        ),
        map((records) =>
          Object.entries(
            groupBy(records, (record) => {
              if (!record.patient.referrer) {
                return 'No Referrer';
              }
              if (!('ref' in record.patient.referrer)) {
                return 'Other';
              }
              if (
                isPatientReferrer(record.patient.referrer) ||
                !('ref' in record.patient.referrer)
              ) {
                return 'Patients';
              }
              if (isContactReferrer(record.patient.referrer)) {
                return 'Contacts';
              }
              if (isConfiguredReferrer(record.patient.referrer)) {
                return record.patient.referrer.name;
              }
            })
          ).map(([referralSource, referralRecords]) => ({
            referralSource,
            records: referralRecords,
            treatmentTotal: referralRecords.reduce(
              (total, record) => total + record.treatmentAmount,
              0
            ),
          }))
        ),
        tap((summaries) =>
          this.patchState({
            summaries,
            isLoading: false,
            selectedSummary: undefined,
          })
        )
      )
  );

  readonly selectSummary = this.effect(
    (summary$: Observable<IReferralSourcesGrouping>) =>
      summary$.pipe(
        tap((summary) => this.patchState({ selectedSummary: summary }))
      )
  );

  readonly clearSelectedSummary = this.effect((_clear$: Observable<void>) =>
    _clear$.pipe(tap(() => this.patchState({ selectedSummary: undefined })))
  );
}
