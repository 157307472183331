import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { errorNil, type WithRef } from '@principle-theorem/shared';
import { type Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export class StateBasedRouterLinkFactory {
  constructor(
    private _brand$: Observable<WithRef<IBrand> | undefined>,
    private _practice$: Observable<WithRef<IPractice> | undefined>
  ) {}

  organisation$(segments: string[] = []): Observable<string[]> {
    return of(['/', ...segments]);
  }

  brand$(segments: string[] = []): Observable<string[]> {
    return this._brand$.pipe(
      errorNil(),
      map((brand) => [brand.slug, ...segments]),
      switchMap((route) => this.organisation$(route))
    );
  }

  practice$(
    segments: string[] = [],
    practiceOverride?: WithRef<IPractice>
  ): Observable<string[]> {
    const practice$ = practiceOverride ? of(practiceOverride) : this._practice$;
    return practice$.pipe(
      errorNil(),
      map((practice) => [practice.slug, ...segments]),
      switchMap((route) => this.brand$(route))
    );
  }
}
