import { Brand, toINamedDocuments } from '@principle-theorem/principle-core';
import {
  CustomMappingAssociatedValueType,
  CustomMappingOption,
  CustomMappingType,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
  type ITag,
  type ITreatmentCategory,
} from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  type IReffable,
  type WithRef,
  XSLXImporterExporter,
  asyncForEach,
  multiSortBy$,
  nameSorter,
  query$,
  snapshot,
  undeletedQuery,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import {
  AppointmentCategorySourceEntity,
  type IExactAppointmentCategory,
} from '../../source/entities/appointment-category';
import {
  type AppointmentCategoryDestination,
  AppointmentCategoryResourceType,
  AppointmentToTreatmentCategoryToXSLX,
} from './appointment-to-treatment-category-to-xslx';
import { XSLXToAppointmentCategoryToTreatmentCategory } from './xslx-to-appointment-category-treatment-category';
import { of } from 'rxjs';

export const APPOINTMENT_CATEGORY_TO_TREATMENT_CATEGORY_MAPPING_TYPE =
  'appointmentCategoryToTreatmentCategory';

export const APPOINTMENT_CATEGORY_TO_TREATMENT_CATEGORY_MAPPING =
  CustomMapping.init({
    metadata: {
      label: 'Appointment Category to Treatment Category',
      description: `
        Used to map appointment categories in Exact to treatment categories in Principle.
      `,
      type: APPOINTMENT_CATEGORY_TO_TREATMENT_CATEGORY_MAPPING_TYPE,
    },
    type: CustomMappingType.SelectionList,
  });

export class AppointmentCategoryToTreatmentCategoryMappingHandler extends BaseCustomMappingHandler<AppointmentCategoryDestination> {
  customMapping = APPOINTMENT_CATEGORY_TO_TREATMENT_CATEGORY_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const records = await this._getSourceCategories(migration);
    return sortBy(
      records.map((record) => ({
        label: record.name,
        value: record.id.toString(),
      })),
      'label'
    );
  }

  async getSelectionListOptions(
    _migration: WithRef<IPracticeMigration>
  ): Promise<CustomMappingOption[]> {
    return snapshot(
      of([
        {
          value: AppointmentCategoryResourceType.TreatmentCategory,
          description: 'Map this to a treatment category',
          hasAssociatedValue: true,
          associatedValueType: CustomMappingAssociatedValueType.NamedDocument,
          associatedValueDescription:
            'Which treatment category should be used for this?',
        },
        {
          value: AppointmentCategoryResourceType.AppointmentTag,
          description: 'Map this to an appointment tag',
          hasAssociatedValue: true,
          associatedValueType: CustomMappingAssociatedValueType.NamedDocument,
          associatedValueDescription:
            'Which appointment tag should be used for this?',
        },
      ])
    );
  }

  async getTreatmentCategories(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<ITreatmentCategory>[]> {
    return snapshot(
      query$(
        undeletedQuery(
          Brand.treatmentCategoryCol(migration.configuration.brand)
        )
      ).pipe(toINamedDocuments(), multiSortBy$(nameSorter()))
    );
  }

  async getAppointmentTags(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<ITag>[]> {
    return snapshot(
      query$(
        undeletedQuery(Brand.appointmentTagCol(migration.configuration.brand))
      ).pipe(toINamedDocuments(), multiSortBy$(nameSorter()))
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const fileName = `appointment-to-treatment-category-mapping`;
    const appointmentCategories = await this._getSourceCategories(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    await new XSLXImporterExporter().download(
      fileName,
      appointmentCategories,
      new AppointmentToTreatmentCategoryToXSLX(
        await snapshot(this.getRecords$(translationMap)),
        await this.getTreatmentCategories(migration),
        await this.getAppointmentTags(migration)
      )
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToAppointmentCategoryToTreatmentCategory()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const sourceOptions = await this.getSourceOptions(migration);
    const treatmentCategories = await this.getTreatmentCategories(migration);
    const appointmentTags = await this.getAppointmentTags(migration);

    await asyncForEach(items, async (item) => {
      const matchingOption = sourceOptions.find(
        (option) => option.label === item.label
      );
      if (!matchingOption) {
        return;
      }

      const destination = [...treatmentCategories, ...appointmentTags].find(
        (category) => category.name === item.mapTo
      );
      if (!destination) {
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: destination.ref,
          destinationValue: item.mapTo,
          sourceIdentifier: matchingOption.label,
          sourceLabel: matchingOption.label,
        },
        translationMap
      );
    });
  }

  private async _getSourceCategories(
    migration: IReffable<IPracticeMigration>
  ): Promise<IExactAppointmentCategory[]> {
    const appointmentCategories = new AppointmentCategorySourceEntity();
    const records = await appointmentCategories
      .getRecords$(migration, 1000)
      .toPromise();
    return sortBy(
      records.map((record) => record.data.data),
      'name'
    );
  }
}
