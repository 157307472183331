import { Injectable } from '@angular/core';
import { BridgeCommandsService } from '../../../bridge-commands.service';
import {
  DocumentReference,
  Firestore,
  WithRef,
} from '@principle-theorem/shared';
import {
  IBridgeDevice,
  IPatient,
  ITwainDevice,
} from '@principle-theorem/principle-core/interfaces';
import {
  TwainSyncDevicesCommand,
  TwainToDeviceCommand,
  TwainTriggerScanCommand,
  TwainTriggerScanCommandLegacy,
} from '@principle-theorem/principle-bridge-core';

@Injectable()
export class TwainFeatureService {
  constructor(private _bridgeCommands: BridgeCommandsService) {}

  async triggerScan(
    patient: WithRef<IPatient>,
    device: ITwainDevice,
    deviceRef: DocumentReference<IBridgeDevice>
  ): Promise<void> {
    const command: TwainTriggerScanCommand = {
      type: TwainToDeviceCommand.TriggerScan,
      data: {
        patient,
        device,
      },
    };
    await this._bridgeCommands.sendCommand(command, deviceRef);
  }

  async triggerScanLegacy(
    patient: WithRef<IPatient>,
    deviceRef: DocumentReference<IBridgeDevice>
  ): Promise<void> {
    const command: TwainTriggerScanCommandLegacy = {
      type: TwainToDeviceCommand.TriggerScan,
      data: {
        patient,
      },
    };
    await this._bridgeCommands.sendCommand(command, deviceRef);
  }

  async syncDevices(
    deviceRef: DocumentReference<IBridgeDevice>
  ): Promise<void> {
    const bridgeDevice = await Firestore.getDoc(deviceRef);
    await Firestore.patchDoc(deviceRef, {
      twainSettings: {
        devices: bridgeDevice.twainSettings?.devices ?? [],
        syncing: true,
      },
    });
    const command: TwainSyncDevicesCommand = {
      type: TwainToDeviceCommand.SyncDevices,
      data: {},
    };
    await this._bridgeCommands.sendCommand(command, deviceRef);
  }
}
