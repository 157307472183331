import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  signal,
  Signal,
} from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { IBreadcrumb, NgSharedModule } from '@principle-theorem/ng-shared';
import { type IConfirmAppointmentInfo } from '@principle-theorem/principle-core/interfaces';
import {
  DATE_FORMAT,
  TIME_FORMAT,
  toMomentTz,
} from '@principle-theorem/shared';
import { Moment } from 'moment-timezone';
import { AddToCalendarLink } from '../../lib/add-to-calendar-link';

@Component({
  selector: 'pr-patient-confirm-appointment-details',
  templateUrl: './patient-confirm-appointment-details.component.html',
  styleUrls: ['./patient-confirm-appointment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NgSharedModule, NgMaterialModule],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex flex-col gap-4' },
})
export class PatientConfirmAppointmentDetailsComponent {
  data = signal<IConfirmAppointmentInfo | undefined>(undefined);
  dateFormat = DATE_FORMAT;
  timeFormat = TIME_FORMAT;
  startAt: Signal<Moment | undefined>;
  addToCalendarLinks: Signal<IBreadcrumb[]>;

  @Input()
  set info(info: IConfirmAppointmentInfo) {
    if (info) {
      this.data.set(info);
    }
  }

  constructor() {
    this.startAt = computed(() => {
      const info = this.data();
      return info ? toMomentTz(info.startAt, info.practiceTimezone) : undefined;
    });
    this.addToCalendarLinks = computed(() => {
      const info = this.data();
      return info ? this._addToCalendarLinks(info) : [];
    });
  }

  private _addToCalendarLinks(info: IConfirmAppointmentInfo): IBreadcrumb[] {
    const event = AddToCalendarLink.calendarEventFromAppointmentInfo(info);
    return [
      { label: 'Google', path: AddToCalendarLink.google(event) },
      { label: 'Apple', path: AddToCalendarLink.ics(event) },
      { label: 'Outlook', path: AddToCalendarLink.outlook(event) },
    ];
  }
}
