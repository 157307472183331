import { type IMigrationSnapshot } from '@principle-theorem/migration-runner';
import { TypeGuard, isEnumValue, isObject } from '@principle-theorem/shared';
import { isString } from 'lodash';
import {
  type BlockNodes,
  InlineNodes,
  type ListNodes,
  RootNodes,
} from './extensions/available-extensions';
import { isNodeSchemaOfType } from './migrations/helpers';

export type NodeSchema<
  Type extends string = string,
  ChildNodeTypes extends string = RawSchemaNodes
> = {
  type: Type;
  attrs?: AttributeSchema;
  content?: (NodeSchema<ChildNodeTypes> | TextNodeSchema)[];
  marks?: MarkSchema[];
};

export type AttributeSchema = {
  /**
   * A CSS class name or a space-separated set of class names to be
   * added to the classes that the node already had.
   */
  class?: string;
  /**
   * A string of CSS to be added to the node's existing style property.
   */
  style?: string;
  /**
   * When non-null, the target node is wrapped in a DOM element of this type (and the other attributes are applied to this element).
   */
  nodeName?: string;
  [key: string]: AttributeSchemaValue;
};

export type TextNodeSchema = ITextNodeSchema;

export type AttributeSchemaValue =
  | string
  | number
  | string[]
  | number[]
  | undefined;

export interface ITextNodeSchema extends NodeSchema<InlineNodes.Text> {
  text: string;
}

export type MarkSchema = {
  type: string;
  attrs?: AttributeSchema;
};

export type RawInlineNodes = NodeSchema<InlineNodes>[];

export type RawSchema = IRawSchema;

export interface IRawSchema extends NodeSchema<RootNodes.Doc, BlockNodes> {
  content?: NodeSchema<BlockNodes>[];
}

export type RawSchemaNodes = ListNodes | InlineNodes | BlockNodes;

export interface ISerialisedVersionedSchema {
  content: string;
  migrations: IMigrationSnapshot;
}

export interface ISerialisedRawSchema {
  rawSchemaValue: string;
}

export type SerialisedMixedSchema =
  | ISerialisedVersionedSchema
  | ISerialisedRawSchema;

export type VersionedSchema = {
  content: RawSchema;
  migrations: IMigrationSnapshot;
};

export type MixedSchema = VersionedSchema | RawSchema;

export enum ContentType {
  Markdown = 'markdown',
  JSON = 'json',
  HTML = 'html',
}

export type Content = string | RawSchema;

export function isRawSchema(item: unknown): item is RawSchema {
  return isObject(item) && isNodeSchemaOfType(item, RootNodes.Doc);
}

export function isVersionedSchema(item: unknown): item is VersionedSchema {
  return (
    isObject(item) &&
    'migrations' in item &&
    'content' in item &&
    isRawSchema(item.content)
  );
}

export function isMixedSchema(item: unknown): item is MixedSchema {
  return isRawSchema(item) || isVersionedSchema(item);
}

export function getMixedContent(item: MixedSchema): RawSchema {
  return isRawSchema(item) ? item : item.content;
}

export function isSerialisedVersionedSchema(
  item: unknown
): item is ISerialisedVersionedSchema {
  return (
    isObject(item) &&
    'migrations' in item &&
    'content' in item &&
    isString(item.content)
  );
}

export function isSerialisedRawSchema(
  item: unknown
): item is ISerialisedRawSchema {
  return (
    isObject(item) && 'rawSchemaValue' in item && isString(item.rawSchemaValue)
  );
}

export function isSerialisedMixedSchema(
  item: unknown
): item is SerialisedMixedSchema {
  return isSerialisedVersionedSchema(item) || isSerialisedRawSchema(item);
}

export function isRawInlineNodes(
  item: object | VersionedSchema | Content | NodeSchema | RawInlineNodes
): item is RawInlineNodes {
  return TypeGuard.arrayOf(
    (node): node is NodeSchema<InlineNodes> =>
      isObject(node) &&
      isString(node.type) &&
      isEnumValue(InlineNodes, node.type)
  )(item);
}
