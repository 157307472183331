<mat-toolbar [color]="data.toolbarColor">{{ data.title }}</mat-toolbar>

<mat-dialog-content>
  <div fxLayout="column">
    <p *ngFor="let line of lines; trackBy: trackByLine">{{ line }}</p>
    <span *ngIf="data.note" class="mat-caption">{{ data.note }}</span>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button
      mat-stroked-button
      mat-dialog-close
      [color]="data.submitColor"
      cdkFocusInitial
    >
      {{ data.submitLabel }}
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
