import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  toMentionContent,
  type RawInlineNodes,
} from '@principle-theorem/editor';
import {
  Automation,
  AutomationConfiguration,
  Patient,
  TimezoneResolver,
  TreatmentStep,
  toMention,
} from '@principle-theorem/principle-core';
import {
  AutomationStatus,
  ITreatmentStep,
  MentionResourceType,
  PatientRelationshipType,
  type IAutomation,
  type TreatmentStepAutomation,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_TIME_FORMAT,
  DocumentReference,
  Firestore,
  addBulk,
  addDoc,
  asyncForEach,
  guardFilter,
  isReffable,
  multiSwitchMap,
  serialise$,
  snapshot,
  toEntityModels$,
  toMomentTz,
  toTimestamp,
  unserialise$,
  type WithRef,
} from '@principle-theorem/shared';
import { of, type Observable } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { AutomationActions } from '../actions';
import { AutomationsFacade } from '../facades/automations.facade';
import { getAutomationEntity, type AutomationEntity } from '../models';

@Injectable()
export class AutomationsEffects {
  private _actions$ = inject(Actions);
  private _automationsFacade = inject(AutomationsFacade);
  private _resetAction$: Observable<Action>;
  loadTreatmentStepAutomations$: Observable<Action> = createEffect(() =>
    this._loadTreatmentStepAutomations$()
  );
  saveAutomations$: Observable<Action> = createEffect(() =>
    this._saveAutomations$()
  );
  addAutomation$: Observable<Action> = createEffect(() =>
    this._addAutomation$()
  );
  loadBrandAutomations$: Observable<Action> = createEffect(() =>
    this._loadBrandAutomations$()
  );
  loadPatientAutomations$: Observable<Action> = createEffect(() =>
    this._loadPatientAutomations$()
  );
  loadConfigAutomations$: Observable<Action> = createEffect(() =>
    this._loadConfigAutomations$()
  );
  updateAutomation$: Observable<Action> = createEffect(() =>
    this._updateAutomation$()
  );
  forceResend$: Observable<Action> = createEffect(() => this._forceResend$());
  cancelAutomation$: Observable<Action> = createEffect(() =>
    this._cancelAutomation$()
  );

  constructor() {
    this._resetAction$ = this._actions$.pipe(
      ofType(AutomationActions.resetAutomationsState)
    );
  }

  private _loadTreatmentStepAutomations$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AutomationActions.loadTreatmentStepAutomations),
      unserialise$(),
      concatMap(({ treatmentStep }) =>
        TreatmentStep.automations$(treatmentStep).pipe(
          toEntityModels$(),
          serialise$(),
          map((automations) =>
            AutomationActions.resetStateOnLoadSuccess({ automations })
          ),
          catchError((error) =>
            of(
              AutomationActions.loadAutomationsFailure({ error: String(error) })
            )
          ),
          takeUntil(this._resetAction$)
        )
      )
    );
  }

  private _loadBrandAutomations$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AutomationActions.loadBrandAutomations),
      unserialise$(),
      switchMap(({ brand, dateFrom, dateTo }) => {
        const automations$: Observable<AutomationEntity[]> =
          Automation.automations$(brand, dateFrom, dateTo).pipe(
            multiSwitchMap(async (automation) => {
              const patientMention = await snapshot(
                resolvePatientMention$(automation)
              );
              const appointment = await snapshot(
                Automation.resolveAppointment$(automation)
              );
              let appointmentSummary = 'Unscheduled';
              if (appointment?.event?.from) {
                const timezone = await TimezoneResolver.fromEvent(appointment);
                appointmentSummary = toMomentTz(
                  appointment.event?.from,
                  timezone
                ).format(DATE_TIME_FORMAT);
              }

              return {
                ...automation,
                practiceRef: automation.practiceRef
                  ? automation.practiceRef
                  : appointment?.practice.ref,
                patientMention,
                appointmentSummary,
              };
            }),
            toEntityModels$()
          );

        return automations$.pipe(
          serialise$(),
          map((automations) =>
            AutomationActions.resetStateOnLoadSuccess({ automations })
          ),
          catchError((error) =>
            of(
              AutomationActions.loadAutomationsFailure({ error: String(error) })
            )
          ),
          takeUntil(this._resetAction$)
        );
      })
    );
  }

  private _loadConfigAutomations$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AutomationActions.loadConfigAutomations),
      unserialise$(),
      switchMap(({ config, dateFrom, dateTo }) => {
        const automations$: Observable<AutomationEntity[]> =
          AutomationConfiguration.automations$(config, dateFrom, dateTo).pipe(
            multiSwitchMap(async (automation) => {
              const patientMention = await snapshot(
                resolvePatientMention$(automation)
              );
              const appointment = await snapshot(
                Automation.resolveAppointment$(automation)
              );
              let appointmentSummary = 'Unscheduled';
              if (appointment?.event?.from) {
                const timezone = await TimezoneResolver.fromEvent(appointment);
                appointmentSummary = toMomentTz(
                  appointment.event?.from,
                  timezone
                ).format(DATE_TIME_FORMAT);
              }

              return {
                ...automation,
                practiceRef: automation.practiceRef
                  ? automation.practiceRef
                  : appointment?.practice.ref,
                patientMention,
                appointmentSummary,
              };
            }),
            toEntityModels$()
          );

        return automations$.pipe(
          serialise$(),
          map((automations) =>
            AutomationActions.resetStateOnLoadSuccess({ automations })
          ),
          catchError((error) =>
            of(
              AutomationActions.loadAutomationsFailure({ error: String(error) })
            )
          ),
          takeUntil(this._resetAction$)
        );
      })
    );
  }

  private _loadPatientAutomations$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AutomationActions.loadPatientAutomations),
      unserialise$(),
      switchMap(({ patient }) =>
        Patient.withPatientRelationships$(
          patient,
          [PatientRelationshipType.DuplicatePatient],
          Patient.automations$
        ).pipe(
          toEntityModels$(),
          serialise$(),
          map((automations) =>
            AutomationActions.resetStateOnLoadSuccess({ automations })
          ),
          catchError((error) =>
            of(
              AutomationActions.loadAutomationsFailure({ error: String(error) })
            )
          ),
          takeUntil(this._resetAction$)
        )
      )
    );
  }

  private _saveAutomations$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AutomationActions.saveTreatmentStepAutomations),
      map((action) => action.treatmentStep),
      unserialise$(),
      withLatestFrom(
        this._automationsFacade.newAutomations$,
        this._automationsFacade.reffableAutomations$
      ),
      concatMap(
        async ([treatmentStep, newAutomations, reffableAutomations]) => {
          await addBulk(
            TreatmentStep.automationCol(treatmentStep),
            newAutomations.map((automation) => getAutomationEntity(automation))
          );
          await asyncForEach(reffableAutomations, (automation) =>
            Firestore.patchDoc(automation.ref, getAutomationEntity(automation))
          );
        }
      ),
      map(() => AutomationActions.saveAutomationsSuccess())
    );
  }

  private _addAutomation$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AutomationActions.addAutomation),
      unserialise$(),
      concatMap(async ({ automation, stepRef }) => {
        if (!stepRef) {
          return;
        }
        await addDoc(
          TreatmentStep.automationCol({
            ref: stepRef as DocumentReference<ITreatmentStep>,
          }),
          automation
        );
      }),
      map(() => AutomationActions.saveAutomationSuccess())
    );
  }

  private _updateAutomation$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AutomationActions.updateAutomation),
      filter((action) => !action.skipEffect),
      unserialise$(),
      concatLatestFrom((action) =>
        this._automationsFacade
          .getAutomation$(action.update.id)
          .pipe(guardFilter(isReffable))
      ),
      concatMap(async ([action, automation]) => {
        try {
          await Firestore.patchDoc(automation.ref, action.update.changes);
          return AutomationActions.saveAutomationSuccess();
        } catch (error) {
          return AutomationActions.saveAutomationFailure({
            error: String(error),
          });
        }
      })
    );
  }

  private _forceResend$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AutomationActions.forceResend),
      unserialise$(),
      concatMap(({ automation }) =>
        Firestore.patchDoc(automation.ref, {
          triggerDate: toTimestamp(),
          status: AutomationStatus.Pending,
        })
      ),
      map(() => AutomationActions.saveAutomationSuccess())
    );
  }

  private _cancelAutomation$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AutomationActions.cancelAutomation),
      unserialise$(),
      map((action) => action.automation),
      concatMap(async (automation) => {
        if (automation.ref) {
          await Firestore.patchDoc(automation.ref, {
            status: AutomationStatus.Cancelled,
          });
        }
      }),
      map(() => AutomationActions.saveAutomationSuccess())
    );
  }
}

function resolvePatientMention$(
  automation: WithRef<IAutomation<TreatmentStepAutomation>>
): Observable<RawInlineNodes | undefined> {
  return Automation.resolvePatient$(automation).pipe(
    map((patient) => {
      const mention = toMention(patient, MentionResourceType.Patient);
      return [toMentionContent(mention)];
    })
  );
}
