import {
  AppointmentAction,
  AppointmentStatus,
  IMeasureMetadata,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import { IAppointmentEventFact } from '@principle-theorem/reporting/interfaces';
import { get } from 'lodash';
import { BigQueryTable } from '../../big-query-tables';
import {
  BrandQueryScopeConfig,
  DateRangeQueryScopeConfig,
  PracticeQueryScopeConfig,
} from '../../query-scope-config/query-scope-configs';
import { GroupBy, IReportingQuery } from '../../querying';
import { BaseFactMeasures, latestEventId } from '../base-measures';
import {
  getProperty,
  MeasureLinkFactory,
  MeasurePath,
} from '../data-accessor-factory';
import { AppointmentDimensionMeasures } from '../dimensions/appointment-dimension';
import { BrandDimensionMeasures } from '../dimensions/brand-dimension';
import { PatientDimensionMeasures } from '../dimensions/patient-dimension';
import { PracticeDimensionMeasures } from '../dimensions/practice-dimension';
import { ReferrerDimensionMeasures } from '../dimensions/referrer-dimension';
import { StafferDimensionMeasures } from '../dimensions/staffer-dimension';
import { TreatmentPlanDimensionMeasures } from '../dimensions/treatment-plan-dimension';
import {
  BooleanMeasureFilter,
  ValueEqualsMeasureFilter,
} from '../measure-filters';
import {
  ComparableProperties,
  MeasureTransformMap,
  noFilterAccessor,
} from '../measure-properties';
import { CanBeChartedProperty, CanDoAllProperty } from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { QueryFactory } from '../query-factory';
import { InvoiceDimensionMeasures } from '../dimensions/invoice-dimension';

export class AppointmentEventFactMeasures
  extends BaseFactMeasures
  implements MeasureTransformMap<ComparableProperties<IAppointmentEventFact>>
{
  id = 'appointmentEvent';
  readonly table = BigQueryTable.AppointmentEvent;
  readonly name = 'Appointments';
  scopes = [
    new BrandQueryScopeConfig(),
    new PracticeQueryScopeConfig(),
    new DateRangeQueryScopeConfig(),
  ];

  get appointment(): AppointmentDimensionMeasures {
    return new AppointmentDimensionMeasures(
      BigQueryTable.Appointments,
      'appointment',
      {
        sourceJoinKey: MeasurePath.docRef('appointment.ref'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get practitioner(): StafferDimensionMeasures {
    return new StafferDimensionMeasures(BigQueryTable.Staff, 'practitioner', {
      sourceJoinKey: MeasurePath.docRef('practitioner.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get treatmentPlan(): TreatmentPlanDimensionMeasures {
    return new TreatmentPlanDimensionMeasures(
      BigQueryTable.TreatmentPlans,
      'treatmentPlan',
      {
        sourceJoinKey: MeasurePath.docRef('treatmentPlan.ref'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get practice(): PracticeDimensionMeasures {
    return new PracticeDimensionMeasures(BigQueryTable.Practices, 'practice', {
      sourceJoinKey: MeasurePath.docRef('practice.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get patient(): PatientDimensionMeasures {
    return new PatientDimensionMeasures(BigQueryTable.Patients, 'patient', {
      sourceJoinKey: MeasurePath.docRef('patient.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get brand(): BrandDimensionMeasures {
    return new BrandDimensionMeasures(BigQueryTable.Brands, 'brand', {
      sourceJoinKey: MeasurePath.docRef('brand.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get invoice(): InvoiceDimensionMeasures {
    return new InvoiceDimensionMeasures(BigQueryTable.Invoices, 'invoice', {
      sourceJoinKey: MeasurePath.docRef('appointment.invoiceRef'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get referrer(): ReferrerDimensionMeasures {
    return new ReferrerDimensionMeasures(this.table, 'referrer');
  }

  get duration(): CanBeChartedProperty {
    const propertyName = 'duration';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Appointment Duration',
        summary: 'The total duration of the appointment in minutes.',
        formatter: MeasureFormatter.Minutes,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get action(): CanBeChartedProperty {
    const propertyName = 'action';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: propertyName,
        label: 'Appointment Action',
        summary: '',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get nextAppointmentBooked(): CanDoAllProperty {
    const propertyName = 'nextAppointmentBooked';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.boolean(
      {
        id: propertyName,
        label: 'Next Appointment Booked At Checkout',
        summary:
          'Whether the next appointment was booked on the same day as the appointment or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      true
    );
  }

  get isFirstAppointment(): CanDoAllProperty {
    const propertyName = 'isFirstAppointment';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.boolean(
      {
        id: propertyName,
        label: 'Is First Appointment',
        summary: `Whether this is the patient's first appointment or not. This can also be used to filter to new/existing patients.`,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get waitTime(): CanBeChartedProperty {
    const propertyName = 'waitTime';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Wait Time',
        summary:
          'The amount of time that the Patient was waiting between being marked as "arrived", and the appointment being marked as "in progress"',
        formatter: MeasureFormatter.Minutes,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get treatmentCost(): CanBeChartedProperty {
    const propertyName = 'treatmentCost';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Treatment Amount',
        summary:
          'Total amount of tretment cost for the appointment including any treatment base prices.',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get hourlyRate(): CanBeChartedProperty {
    const propertyName = 'hourlyRate';
    const valueMeasure = this.measureRef('treatmentCost');
    const durationMeasure = this.measureRef('duration');
    const measure: CanBeChartedProperty = new CanBeChartedProperty({
      metadata: {
        id: propertyName,
        label: 'Hourly Rate',
        summary:
          'The average hourly rate for the appointment, comparing the total cost of the appointment to the total duration.',
        formatter: MeasureFormatter.Currency,
      },
      measure: {
        propertyName,
        query: this.buildQuery()
          .attributes([
            valueMeasure.attributePath,
            durationMeasure.attributePath,
          ])
          .get(),
        dataAccessor: (fact) => {
          const value = getProperty<number>(
            fact,
            valueMeasure.factPropertyPath,
            0
          );
          const duration =
            get(fact, durationMeasure.factPropertyPath, 0) / 60 || 0;
          const hourlyRate = value / duration;
          return isNaN(hourlyRate) ? 0 : hourlyRate;
        },
        filterAccessor: noFilterAccessor(),
      },
    });

    measure.reduceByRatio(this.durationInHours);
    return measure;
  }

  get durationInHours(): CanBeChartedProperty {
    const propertyName = 'duration';
    const measure = this.measureRef(propertyName);
    return new CanBeChartedProperty({
      metadata: {
        id: 'durationInHours',
        label: 'Duration in Hours',
        summary: 'The total duration of the appointment in hours',
        formatter: MeasureFormatter.Hours,
      },
      measure: {
        propertyName,
        query: this.buildQuery().attributes([measure.attributePath]).get(),
        dataAccessor: (fact) =>
          getProperty<number>(fact, measure.factPropertyPath, 0) / 60 || 0,
        filterAccessor: noFilterAccessor(),
      },
    });
  }

  get newPatient(): CanDoAllProperty {
    return new CanDoAllProperty({
      ...this.isFirstAppointment
        .clone()
        .filterBy(new BooleanMeasureFilter(true)),
      metadata: {
        id: 'newPatient',
        label: 'New Patient',
        summary: '',
        formatter: MeasureFormatter.Number,
      },
    });
  }

  get existingPatient(): CanDoAllProperty {
    return new CanDoAllProperty({
      ...this.isFirstAppointment
        .clone()
        .filterBy(new BooleanMeasureFilter(false)),
      metadata: {
        id: 'existingPatient',
        label: 'Existing Patients',
        summary: '',
        formatter: MeasureFormatter.Number,
      },
    });
  }

  get treatments(): CanDoAllProperty {
    const propertyName = 'treatments';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.array(
      {
        id: propertyName,
        label: 'Treatments',
        summary:
          'A list of treatments that were performed during the appointment',
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'config.name'
    );
  }

  get serviceCodes(): CanDoAllProperty {
    const propertyName = 'serviceCodes';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.array(
      {
        id: propertyName,
        label: 'Service Codes',
        summary:
          'A list of service codes that were performed during the appointment',
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'code'
    );
  }

  get latestEvent(): AppointmentEventFactMeasures {
    const measures = new AppointmentEventFactMeasures();
    measures.id = latestEventId(measures);
    measures._query = QueryFactory.fromTable(measures.table)
      .override(measures._query)
      .latestEvent(measures.table, GroupBy.Appointment)
      .filterSoftDeleted(measures.appointment.measureRef('deleted'))
      .get();
    return measures;
  }

  get timeUntilAppointment(): CanBeChartedProperty {
    const propertyName = 'timeUntilAppointment';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Time Until Appointment',
        summary: '',
        formatter: MeasureFormatter.Minutes,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get completed(): CanBeChartedProperty {
    const metadata: IMeasureMetadata = {
      id: 'completed',
      label: 'Completed Appointments',
      summary: '',
    };
    const measure = this.action
      .clone()
      .filterBy(
        new ValueEqualsMeasureFilter(AppointmentAction.Completed)
      ).measure;
    this._query = { ...this._query, ...measure };
    return new CanBeChartedProperty({ metadata, measure });
  }

  get cancelled(): CanBeChartedProperty {
    const metadata: IMeasureMetadata = {
      id: 'cancelled',
      label: 'Cancelled Appointments',
      summary: '',
    };
    const measure = this.action
      .clone()
      .filterBy(
        new ValueEqualsMeasureFilter(AppointmentAction.Cancelled)
      ).measure;
    this._query = { ...this._query, ...measure };
    return new CanBeChartedProperty({ metadata, measure });
  }

  get rescheduled(): CanBeChartedProperty {
    const metadata: IMeasureMetadata = {
      id: 'rescheduled',
      label: 'Rescheduled Appointments',
      summary: '',
    };
    const measure = this.action
      .clone()
      .filterBy(
        new ValueEqualsMeasureFilter(AppointmentAction.Rescheduled)
      ).measure;
    this._query = { ...this._query, ...measure };
    return new CanBeChartedProperty({ metadata, measure });
  }

  get scheduled(): CanBeChartedProperty {
    const metadata: IMeasureMetadata = {
      id: 'scheduled',
      label: 'Scheduled Appointments',
      summary: '',
    };
    const measure = this.action
      .clone()
      .filterBy(
        new ValueEqualsMeasureFilter(AppointmentAction.Scheduled)
      ).measure;
    this._query = { ...this._query, ...measure };
    return new CanBeChartedProperty({ metadata, measure });
  }

  get unscheduled(): CanBeChartedProperty {
    const metadata: IMeasureMetadata = {
      id: 'unscheduled',
      label: 'Cancelled Appointments',
      summary: '',
    };
    const measure = this.latestEvent.appointment.status.filterBy(
      new ValueEqualsMeasureFilter(AppointmentStatus.Unscheduled)
    ).measure;
    this._query = { ...this._query, ...measure };
    return new CanBeChartedProperty({ metadata, measure });
  }

  get link(): CanBeChartedProperty {
    const brand = this.brand.measureRef('slug');
    const appointment = this.appointment.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.link(
      {
        id: 'appointment.link',
        label: 'Appointment Link',
        summary: 'Principle link for the given appointment',
      },
      appointment,
      this.buildQuery()
        .mergeJoins(this.brand.query.joins)
        .mergeJoins(this.appointment.query.joins)
        .attributes([brand.attributePath, appointment.attributePath])
        .get(),
      brand,
      MeasureLinkFactory.appointment
    );
  }

  get patientLink(): CanBeChartedProperty {
    const brand = this.brand.measureRef('slug');
    const patient = this.patient.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.link(
      {
        id: 'patient.link',
        label: 'Patient Link',
        summary: 'Principle link for the given patient',
      },
      patient,
      this.buildQuery()
        .mergeJoins(this.brand.query.joins)
        .mergeJoins(this.patient.query.joins)
        .attributes([brand.attributePath, patient.attributePath])
        .get(),
      brand,
      MeasureLinkFactory.patient
    );
  }

  get invoiceLink(): CanBeChartedProperty {
    const brand = this.brand.measureRef('slug');
    const patient = this.patient.measureRef(MeasurePath.docRef('ref'));
    const invoice = this.invoice.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.link(
      {
        id: 'invoice.link',
        label: 'Invoice Link',
        summary: 'Principle link for the given invoice',
      },
      invoice,
      this.buildQuery()
        .mergeJoins(this.brand.query.joins)
        .mergeJoins(this.patient.query.joins)
        .mergeJoins(this.invoice.query.joins)
        .attributes([
          brand.attributePath,
          patient.attributePath,
          invoice.attributePath,
        ])
        .get(),
      brand,
      MeasureLinkFactory.invoice
    );
  }
}

export const queries: Record<string, IReportingQuery> = {
  treatments: {
    table: BigQueryTable.AppointmentEvent,
    attributes: ['treatments'],
    groupBy: [
      // GroupBy.Treatment,
    ],
  },
  nextBooked: {
    table: BigQueryTable.AppointmentEvent,
    attributes: ['*'],
    // operation: AttributeOperation.Count,
    filters: ['nextAppointmentBooked === true'],
  },
  noNextBooked: {
    table: BigQueryTable.AppointmentEvent,
    attributes: ['*'],
    // operation: AttributeOperation.Count,
    filters: ['nextAppointmentBooked === false'],
  },
  timeUsed: {
    table: BigQueryTable.AppointmentEvent,
    attributes: ['duration'],
    // operation: AttributeOperation.Sum,
  },
  treatmentTypes: {
    table: BigQueryTable.AppointmentEvent,
    attributes: ['treatments'],
    groupBy: [
      // GroupBy.Treatment,
    ],
  },
  treatmentTypeAmounts: {
    subQuery: {
      table: BigQueryTable.AppointmentEvent,
      attributes: ['treatments'],
      // operation: AttributeOperation.Unnest,
    },
    table: BigQueryTable.AppointmentEvent,
    attributes: ['cost'],
    // operation: AttributeOperation.Sum,
  },
};
