import { ReportBuilderDataSourceId } from '@principle-theorem/principle-core/interfaces';
import { FactTables, toMeasureBuilderData } from '@principle-theorem/reporting';
import { ChartFilters } from './chart-filters';
import { ReportBuilderChartFilters } from './report-builder-chart-filters';
import { ReportBuilderColumns } from './report-builder-columns';
import { type IReportBuilderDataSource } from './report-builder-data-source';
import { ReportBuilderGroups } from './report-builder-groups';

const factTable = FactTables.accountCreditEvent.latestEvent;

export const ACCOUNT_CREDITS_REPORT_BUILDER_DATA_SOURCE: IReportBuilderDataSource =
  {
    id: ReportBuilderDataSourceId.AccountCredits,
    name: 'Account Credits',
    description:
      'One result per Account Credit within Principle. This includes information about the patient it was allocated to and the invoice it was taken from.',
    factTable,
    queryableTimestamps: [{ property: factTable.accountCredit.createdAt }],
    groupByOptions: [
      ReportBuilderGroups.toGroup('Common', [
        ReportBuilderGroups.toOption(
          factTable.accountCredit.reservedForPractitioner
        ),
        ReportBuilderGroups.toOption(factTable.practice.name),
        ReportBuilderGroups.toOption(factTable.brand.name),
        ReportBuilderGroups.toOption(factTable.count),
      ]),
      ReportBuilderGroups.toGroup('Account Credit', [
        ...ReportBuilderGroups.accountCredit(factTable.accountCredit).options,
        ReportBuilderGroups.toOption(factTable.treatmentCategory),
      ]),
      ReportBuilderGroups.invoice(factTable.invoice),
      ReportBuilderGroups.patient(factTable.patient),
    ],
    availableColumns: [
      ReportBuilderColumns.toColumnGroup('Account Credit', [
        factTable.remaining,
        factTable.hasRemaining,
        ...ReportBuilderColumns.accountCredit(factTable.accountCredit),
        factTable.treatmentCategory,
      ]),
      ReportBuilderColumns.toColumnGroup('Invoice', [
        ...ReportBuilderColumns.invoice(factTable.invoice),
        factTable.invoiceLink,
      ]),
      ReportBuilderColumns.toColumnGroup('Patient', [
        ...ReportBuilderColumns.patient(factTable.patient),
        factTable.patientLink,
      ]),
      ReportBuilderColumns.toColumnGroup('Practice', [factTable.practice.name]),
    ],
    defaultColumns: ReportBuilderColumns.toReferences([
      factTable.patient.name,
      factTable.accountCredit.reservedForPractitioner,
      factTable.treatmentCategory,
      factTable.accountCredit.createdAt,
      factTable.invoiceLink,
      factTable.accountCredit.amount,
      factTable.accountCredit.used,
      factTable.remaining,
    ]),
    availableCharts: [
      ReportBuilderChartFilters.predefined(
        'overview.brand.price',
        factTable.brand.name,
        ChartFilters.numberChart('Total Price', factTable.brand.name, [
          toMeasureBuilderData(factTable.accountCredit.amount),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'overview.practice',
        factTable.practice.name,
        ChartFilters.pieChart('Practice', factTable.practice.name, [
          toMeasureBuilderData(factTable.accountCredit.amount),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'overview.totalAccountCredits',
        factTable.accountCredit.createdAt,
        ChartFilters.columnChartOverTime(
          'Total Account Credits',
          factTable.accountCredit.createdAt,
          [toMeasureBuilderData(factTable.accountCredit.amount)],
          { rotateXAxisLabels: true }
        )
      ),
      ...ReportBuilderChartFilters.patient(
        factTable.patient,
        factTable.accountCredit.amount
      ),
      ...ReportBuilderChartFilters.invoice(
        factTable.invoice,
        factTable.accountCredit.amount
      ).filter(
        (chart) =>
          ![
            'invoice.productsTotal',
            'invoice.treatmentsTotal',
            'invoice.products',
            'invoice.treatments',
            'invoice.serviceCodes',
          ].includes(chart.id)
      ),
      ReportBuilderChartFilters.predefined(
        'accountCredit.total',
        factTable.brand.name,
        ChartFilters.numberChart('Total Amount', factTable.brand.name, [
          toMeasureBuilderData(factTable.accountCredit.amount),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'accountCredit.totalUsed',
        factTable.brand.name,
        ChartFilters.numberChart('Total Used', factTable.brand.name, [
          toMeasureBuilderData(factTable.accountCredit.used),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'accountCredit.totalRemaining',
        factTable.brand.name,
        ChartFilters.numberChart('Total Remaining', factTable.brand.name, [
          toMeasureBuilderData(factTable.remaining),
        ])
      ),
      ...ReportBuilderChartFilters.accountCredit(
        FactTables.accountCreditEvent.latestEvent.accountCredit,
        factTable.accountCredit.amount
      ),
      ReportBuilderChartFilters.predefined(
        'accountCredit.treatmentCategory',
        factTable.treatmentCategory,
        ChartFilters.columnChart(
          'Treatment Category',
          factTable.treatmentCategory,
          [toMeasureBuilderData(factTable.accountCredit.amount)],
          { rotateXAxisLabels: true },
          { groupLimit: 15, otherGroupLabel: 'Other', colourOverride: true }
        )
      ),
    ],
  };
