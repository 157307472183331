import {
  initVersionedSchema,
  MixedSchema,
  RawInlineNodes,
} from '@principle-theorem/editor';
import {
  AutomatedNotificationType,
  AutomationStatus,
  AutomationType,
  EMPTY_RENDERED_TEMPLATE,
  IAutomatedNotification,
  IAutomation,
  IAutomationConfiguration,
  IAutomationResource,
  IBrand,
  ICloudTask,
  IGeneratedTask,
  IPractice,
  IStaffer,
  ITeam,
  TaskPriority,
  TemplateScope,
  TimingDirection,
  TimingUnit,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseMock,
  INamedTypeDocument,
  MockGenerator,
  MockTimestamp,
  Properties,
  PropertiesOf,
} from '@principle-theorem/shared';
import { MockDocRef, MockNamedDocument } from '@principle-theorem/testing';
import { ConditionLogic } from '../../condition-logic/condition-logic';

export class AutomatedNotificationMock
  extends BaseMock
  implements Properties<IAutomatedNotification>
{
  name = '';
  subject = '';
  type = AutomatedNotificationType.EMAIL;
  timing = {
    direction: TimingDirection.Before,
    amount: 1,
    unit: TimingUnit.Months,
  };
  customTemplate = {
    content: initVersionedSchema(),
    scope: TemplateScope.Appointment,
    renderedTemplate: EMPTY_RENDERED_TEMPLATE,
  };
  requiredConditions = ConditionLogic.collection([
    ConditionLogic.and([ConditionLogic.always()]),
  ]);
}

export class GeneratedTaskMock
  extends BaseMock
  implements PropertiesOf<IGeneratedTask>
{
  title = [] as RawInlineNodes;
  comment: MixedSchema = initVersionedSchema();
  assignee = MockNamedDocument<IStaffer | ITeam>();
  priority = TaskPriority.Low;
  isForTeam = false;
  timing = {
    direction: TimingDirection.Before,
    amount: 1,
    unit: TimingUnit.Months,
  };
}

export class AutomationMock
  extends BaseMock
  implements Properties<IAutomation<IAutomationResource>>
{
  status = AutomationStatus.Pending;
  type = AutomationType.AutomatedNotification;
  data = {
    timing: {
      direction: TimingDirection.Before,
      amount: 1,
      unit: TimingUnit.Months,
    },
  };
  cloudTask = {
    name: 'task-name',
    createTime: MockTimestamp(),
    scheduleTime: MockTimestamp(),
    dispatchCount: 0,
    responseCount: 0,
  };
  cloudTaskHistory = [] as ICloudTask[];
  creator = {
    ...MockNamedDocument<IStaffer>(),
    type: 'staffer',
  } as INamedTypeDocument;
  triggerDate = MockTimestamp();
  triggerAfterDate = MockTimestamp();
  brandRef = MockDocRef<IBrand>();
  practiceRef = MockDocRef<IPractice>();
  configRef = MockDocRef<IAutomationConfiguration>();
}

export function MockAutomatedNotification(
  automationOverrides?: Partial<IAutomation<IAutomationResource>>,
  notificationOverrides?: Partial<IAutomatedNotification>
): IAutomation<IAutomatedNotification> {
  const data: IAutomatedNotification = {
    ...MockGenerator.generate(AutomatedNotificationMock),
    ...notificationOverrides,
  };
  return MockGenerator.generate(AutomationMock, {
    data,
    ...automationOverrides,
  });
}

export function MockGeneratedTask(
  automationOverrides?: Partial<IAutomation<IAutomationResource>>,
  taskOverrides?: Partial<IGeneratedTask>
): IAutomation<IGeneratedTask> {
  return MockGenerator.generate(AutomationMock, {
    ...automationOverrides,
    data: MockGenerator.generate(GeneratedTaskMock, { ...taskOverrides }),
  });
}
