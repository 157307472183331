import * as moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';
import * as MockHicapsResponses from './mock-hicaps-connect-responses';
import {
  HicapsConnectMethod,
  PrincipleHicapsConnect,
  PrincipleHicapsConnectRequest,
  PrincipleHicapsConnectResponse,
  PrincipleHicapsConnectSuccess,
} from './principle-hicaps-connect';

type MockFn<T extends HicapsConnectMethod> = (
  request: PrincipleHicapsConnectRequest<T>
) => PrincipleHicapsConnectResponse<T>;

type MockHicapsConnectResponseDataMap = {
  [T in HicapsConnectMethod]: MockFn<T>;
};

export class MockPrincipleHicapsConnectResponse
  implements MockHicapsConnectResponseDataMap
{
  mock<T extends HicapsConnectMethod>(
    request: PrincipleHicapsConnectRequest<T>
  ): PrincipleHicapsConnectResponse<T> {
    const mockFn = this[request.methodName] as unknown as MockFn<T>;
    const response = mockFn.call(this, request);
    if (!response) {
      throw new Error('Mock data not found');
    }
    return response;
  }

  response<T extends HicapsConnectMethod>(
    processUid: string,
    data: PrincipleHicapsConnectSuccess<T>['data']
  ): PrincipleHicapsConnectSuccess<T> {
    return PrincipleHicapsConnect.createSuccessResponse<T>(processUid, data);
  }

  principleHicapsLogsRead(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.PrincipleHicapsLogs_Read>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.PrincipleHicapsLogs_Read> {
    return this.response<HicapsConnectMethod.PrincipleHicapsLogs_Read>(
      request.processUid,
      `[15/08/2023 7:11:03 PM +10:00] DEBUG: Request: {"uid":"09eda00e-f3d7-44b3-b835-867100d4e2ca","methodName":"principleHicapsLogsReadAndDelete"}\r\n`
    );
  }

  principleHicapsLogsDelete(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.PrincipleHicapsLogs_Delete>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.PrincipleHicapsLogs_Delete> {
    return this.response<HicapsConnectMethod.PrincipleHicapsLogs_Delete>(
      request.processUid,
      {}
    );
  }

  principleHicapsLogsReadAndDelete(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.PrincipleHicapsLogs_ReadAndDelete>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.PrincipleHicapsLogs_ReadAndDelete> {
    return this.response<HicapsConnectMethod.PrincipleHicapsLogs_ReadAndDelete>(
      request.processUid,
      `[15/08/2023 7:11:03 PM +10:00] DEBUG: Request: {"uid":"09eda00e-f3d7-44b3-b835-867100d4e2ca","methodName":"principleHicapsLogsReadAndDelete"}\r\n`
    );
  }

  getVersion(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.GetVersion>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.GetVersion> {
    return this.response<HicapsConnectMethod.GetVersion>(
      request.processUid,
      `1.0.3.32.R1`
    );
  }

  getDefaultServer(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.GetDefaultServer>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.GetDefaultServer> {
    return this.response<HicapsConnectMethod.GetDefaultServer>(
      request.processUid,
      `${MockHicapsResponses.ServerUrl}:${MockHicapsResponses.ServerPort}`
    );
  }

  getDefaultServerConfig(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.GetDefaultServerConfig>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.GetDefaultServerConfig> {
    return this.response<HicapsConnectMethod.GetDefaultServerConfig>(
      request.processUid,
      `${MockHicapsResponses.ServerUrl}:${MockHicapsResponses.ServerPort}`
    );
  }

  ClearTerminalList(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.ClearTerminalList>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.ClearTerminalList> {
    return this.response<HicapsConnectMethod.ClearTerminalList>(
      request.processUid,
      {}
    );
  }

  getStandAloneMode(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.GetStandAloneMode>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.GetStandAloneMode> {
    return this.response<HicapsConnectMethod.GetStandAloneMode>(
      request.processUid,
      undefined
    );
  }

  getTerminalList(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.GetTerminalList>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.GetTerminalList> {
    return this.response<HicapsConnectMethod.GetTerminalList>(
      request.processUid,
      [
        `${MockHicapsResponses.ServerUrl}:${MockHicapsResponses.ServerPort}:${MockHicapsResponses.TerminalId}:COM5`,
      ]
    );
  }

  getTerminalListById(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.GetTerminalListById>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.GetTerminalListById> {
    return this.response<HicapsConnectMethod.GetTerminalListById>(
      request.processUid,
      [MockHicapsResponses.TerminalId, 'SE102A']
    );
  }

  DisplayStatusWindow(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.DisplayStatusWindow>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.DisplayStatusWindow> {
    return this.response<HicapsConnectMethod.DisplayStatusWindow>(
      request.processUid,
      {}
    );
  }

  sendAllItemList(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendAllItemList>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendAllItemList> {
    return this.response<HicapsConnectMethod.SendAllItemList>(
      request.processUid,
      MockHicapsResponses.AllItemListResponse
    );
  }

  sendAllItemResponseCodeList(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendAllItemResponseCodeList>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendAllItemResponseCodeList> {
    return this.response<HicapsConnectMethod.SendAllItemResponseCodeList>(
      request.processUid,
      MockHicapsResponses.AllItemResponseCodeListResponse
    );
  }

  sendAllMerchantList(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendAllMerchantList>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendAllMerchantList> {
    return this.response<HicapsConnectMethod.SendAllMerchantList>(
      request.processUid,
      MockHicapsResponses.AllMerchantListResponse
    );
  }

  sendAllPharmItemList(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendAllPharmItemList>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendAllPharmItemList> {
    return this.response<HicapsConnectMethod.SendAllPharmItemList>(
      request.processUid,
      MockHicapsResponses.AllPharmItemListResponse
    );
  }

  sendAllProviderList(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendAllProviderList>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendAllProviderList> {
    return this.response<HicapsConnectMethod.SendAllProviderList>(
      request.processUid,
      MockHicapsResponses.AllProviderListResponse
    );
  }

  sendAllTransCodeListResponse(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendAllTransCodeListResponse>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendAllTransCodeListResponse> {
    return this.response<HicapsConnectMethod.SendAllTransCodeListResponse>(
      request.processUid,
      MockHicapsResponses.AllTransCodeListResponse
    );
  }

  sendCardList(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendCardList>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendCardList> {
    return this.response<HicapsConnectMethod.SendCardList>(
      request.processUid,
      MockHicapsResponses.CardListResponse
    );
  }

  sendCardRead(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendCardRead>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendCardRead> {
    return this.response<HicapsConnectMethod.SendCardRead>(
      request.processUid,
      MockHicapsResponses.CardReadResponse
    );
  }

  sendCashout(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendCashout>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendCashout> {
    return this.response<HicapsConnectMethod.SendCashout>(request.processUid, {
      ...MockHicapsResponses.CashoutResponse,
      CashoutAmount: request.data.CashoutAmount,
      ExpiryDate:
        request.data.ExpiryDate ??
        MockHicapsResponses.CashoutResponse.ExpiryDate,
      InvoiceNumber: uuid(),
      SurchargeAmount: 0,
      TransactionDate: moment().toISOString(),
    });
  }

  sendClaimCancelRequest(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendClaimCancelRequest>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendClaimCancelRequest> {
    return this.response<HicapsConnectMethod.SendClaimCancelRequest>(
      request.processUid,
      {
        ...MockHicapsResponses.ClaimCancelResponse,
        BenefitAmount: request.data.TransactionAmount,
        ExpiryDate: moment().toISOString(),
        PrimaryAccountNumber:
          request.data.PrimaryAccountNumber ??
          MockHicapsResponses.ClaimCancelResponse.PrimaryAccountNumber,
        ProviderNumberId:
          request.data.ProviderNumberId ??
          MockHicapsResponses.ClaimCancelResponse.ProviderNumberId,
        RrnNumber:
          request.data.RrnNumber ??
          MockHicapsResponses.ClaimCancelResponse.RrnNumber,
        TransactionDate: moment().toISOString(),
      }
    );
  }

  sendClaimPharmRequest(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendClaimPharmRequest>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendClaimPharmRequest> {
    return this.response<HicapsConnectMethod.SendClaimPharmRequest>(
      request.processUid,
      MockHicapsResponses.ClaimPharmResponse
    );
  }

  sendClaimRequest(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendClaimRequest>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendClaimRequest> {
    return this.response<HicapsConnectMethod.SendClaimRequest>(
      request.processUid,
      {
        ...MockHicapsResponses.ClaimResponse,
        BenefitAmount: request.data.TransactionAmount * 0.69,
        TransactionAmount: request.data.TransactionAmount,
        ProviderNumberId: request.data.ProviderNumberId,
        ClaimDetails:
          request.data.ClaimDetails ??
          MockHicapsResponses.ClaimResponse.ClaimDetails,
        ClaimDetailsStr:
          request.data.ClaimDetails ??
          MockHicapsResponses.ClaimResponse.ClaimDetails,
        ResponseTime: moment().toISOString(),
        RrnNumber: uuid(),
        MembershipId: request.data.MembershipId,
        PatientNameDetails:
          request.data.PatientNameDetails ??
          MockHicapsResponses.ClaimResponse.PatientNameDetails,
        PatientNameDetailsStr:
          request.data.PatientNameDetails ??
          MockHicapsResponses.ClaimResponse.PatientNameDetails,
        PrimaryAccountNumber:
          request.data.PrimaryAccountNumber ??
          MockHicapsResponses.ClaimResponse.PrimaryAccountNumber,
        TransactionDate: moment().toISOString(),
      }
    );
  }

  sendEftposDeposit(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendEftposDeposit>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendEftposDeposit> {
    return this.response<HicapsConnectMethod.SendEftposDeposit>(
      request.processUid,
      MockHicapsResponses.EftposDepositResponse
    );
  }

  sendEftposTransListing(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendEftposTransListing>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendEftposTransListing> {
    return this.response<HicapsConnectMethod.SendEftposTransListing>(
      request.processUid,
      MockHicapsResponses.EftposTransListingResponse
    );
  }

  sendGetTerminals(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendGetTerminals>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendGetTerminals> {
    return this.response<HicapsConnectMethod.SendGetTerminals>(
      request.processUid,
      MockHicapsResponses.GetTerminalResponse
    );
  }

  sendHicapsTotals(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendHicapsTotals>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendHicapsTotals> {
    return this.response<HicapsConnectMethod.SendHicapsTotals>(
      request.processUid,
      MockHicapsResponses.HicapsTotalsResponse
    );
  }

  sendHicapsTransListing(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendHicapsTransListing>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendHicapsTransListing> {
    return this.response<HicapsConnectMethod.SendHicapsTransListing>(
      request.processUid,
      MockHicapsResponses.HicapsTransListingResponse
    );
  }

  sendMedicareClaimRequest(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendMedicareClaimRequest>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendMedicareClaimRequest> {
    return this.response<HicapsConnectMethod.SendMedicareClaimRequest>(
      request.processUid,
      {
        ...MockHicapsResponses.MedicareClaimResponse,
        ServiceTypeCde: request.data.ServiceTypeCde,
        ServicingProviderNum: request.data.ServicingProviderNum,
        RequestOverrideTypeCde:
          request.data.RequestOverrideTypeCde ??
          MockHicapsResponses.MedicareClaimResponse.RequestOverrideTypeCde,
        RequestIssueDate:
          request.data.RequestIssueDate ??
          MockHicapsResponses.MedicareClaimResponse.RequestIssueDate,
        RequestingProviderNum:
          request.data.RequestingProviderNum ??
          MockHicapsResponses.MedicareClaimResponse.RequestingProviderNum,
        ReferringProviderNum: request.data.ReferringProviderNum,
        ReferralPeriodTypeCde: request.data.ReferralPeriodTypeCde,
        ReferralOverrideTypeCde: request.data.ReferralOverrideTypeCde,
        ReferralIssueDate: request.data.ReferralIssueDate,
        PayeeProviderNum: request.data.PayeeProviderNum,
        PatientMedicareCardNum: request.data.PatientMedicareCardNum,
        PatientIRN: request.data.PatientIRN,
        ClaimantMedicareCardNum:
          request.data.ClaimantMedicareCardNum ??
          MockHicapsResponses.MedicareClaimResponse.ClaimantMedicareCardNum,
        ClaimantIRN:
          request.data.ClaimantIRN ??
          MockHicapsResponses.MedicareClaimResponse.ClaimantIRN,
        AccountReferenceId: request.data.AccountReferenceId,
        TransactionAmount: request.data.TransactionAmount,
        TransactionDate: moment().toISOString(),
      }
    );
  }

  sendMedicareMerchantList(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendMedicareMerchantList>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendMedicareMerchantList> {
    return this.response<HicapsConnectMethod.SendMedicareMerchantList>(
      request.processUid,
      MockHicapsResponses.MedicareMerchantListResponse
    );
  }

  sendMerchantList(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendMerchantList>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendMerchantList> {
    return this.response<HicapsConnectMethod.SendMerchantList>(
      request.processUid,
      MockHicapsResponses.MerchantListResponse
    );
  }

  sendPrintLastReceipt(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendPrintLastReceipt>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendPrintLastReceipt> {
    return this.response<HicapsConnectMethod.SendPrintLastReceipt>(
      request.processUid,
      MockHicapsResponses.PrintLastReceiptResponse
    );
  }

  sendQuotePharmRequest(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendQuotePharmRequest>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendQuotePharmRequest> {
    return this.response<HicapsConnectMethod.SendQuotePharmRequest>(
      request.processUid,
      MockHicapsResponses.QuotePharmResponse
    );
  }

  sendQuoteRequest(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendQuoteRequest>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendQuoteRequest> {
    return this.response<HicapsConnectMethod.SendQuoteRequest>(
      request.processUid,
      {
        ...MockHicapsResponses.QuoteResponse,
        BenefitAmount: request.data.TransactionAmount * 0.69,
        TransactionAmount: request.data.TransactionAmount,
        ProviderNumberId: request.data.ProviderNumberId,
        ClaimDetails:
          request.data.ClaimDetails ??
          MockHicapsResponses.ClaimResponse.ClaimDetails,
        ClaimDetailsStr:
          request.data.ClaimDetails ??
          MockHicapsResponses.ClaimResponse.ClaimDetails,
        ResponseTime: moment().toISOString(),
        RrnNumber: uuid(),
        MembershipId: request.data.MembershipId,
        PatientNameDetails:
          request.data.PatientNameDetails ??
          MockHicapsResponses.ClaimResponse.PatientNameDetails,
        PatientNameDetailsStr:
          request.data.PatientNameDetails ??
          MockHicapsResponses.ClaimResponse.PatientNameDetails,
        PrimaryAccountNumber:
          request.data.PrimaryAccountNumber ??
          MockHicapsResponses.ClaimResponse.PrimaryAccountNumber,
        TransactionDate: moment().toISOString(),
      }
    );
  }

  sendRefund(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendRefund>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendRefund> {
    return this.response<HicapsConnectMethod.SendRefund>(request.processUid, {
      ...MockHicapsResponses.RefundResponse,
      ExpiryDate:
        request.data.ExpiryDate ??
        MockHicapsResponses.RefundResponse.ExpiryDate,
      InvoiceNumber: uuid(),
      MerchantId: request.data.MerchantId,
      TransactionAmount: request.data.TransactionAmount,
      TransactionDate: moment().toISOString(),
    });
  }

  sendSale(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendSale>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendSale> {
    return this.response<HicapsConnectMethod.SendSale>(request.processUid, {
      ...MockHicapsResponses.SaleResponse,
      ExpiryDate:
        request.data.ExpiryDate ?? MockHicapsResponses.SaleResponse.ExpiryDate,
      InvoiceNumber: uuid(),
      MerchantId: request.data.MerchantId,
      PrimaryAccountNumber:
        request.data.PrimaryAccountNumber ??
        MockHicapsResponses.SaleResponse.PrimaryAccountNumber,
      TransactionAmount: request.data.TransactionAmount,
      TransactionDate: moment().toISOString(),
    });
  }

  sendSaleCashout(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendSaleCashout>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendSaleCashout> {
    return this.response<HicapsConnectMethod.SendSaleCashout>(
      request.processUid,
      {
        ...MockHicapsResponses.SaleCashoutResponse,
        ExpiryDate:
          request.data.ExpiryDate ??
          MockHicapsResponses.SaleResponse.ExpiryDate,
        InvoiceNumber: uuid(),
        MerchantId: request.data.MerchantId,
        PrimaryAccountNumber:
          request.data.PrimaryAccountNumber ??
          MockHicapsResponses.SaleResponse.PrimaryAccountNumber,
        TransactionAmount: request.data.TransactionAmount,
        CashoutAmount: request.data.CashoutAmount,
        TransactionDate: moment().toISOString(),
      }
    );
  }

  sendSettlement(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendSettlement>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendSettlement> {
    return this.response<HicapsConnectMethod.SendSettlement>(
      request.processUid,
      MockHicapsResponses.SettlementResponse
    );
  }

  sendTerminalTest(
    request: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendTerminalTest>
  ): PrincipleHicapsConnectResponse<HicapsConnectMethod.SendTerminalTest> {
    return this.response<HicapsConnectMethod.SendTerminalTest>(
      request.processUid,
      MockHicapsResponses.TerminalTestResponse
    );
  }
}
