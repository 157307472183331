import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Appointment, Interaction } from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IInteraction,
  type IInteractionV2,
  isInteractionV2,
  IPatient,
  IInteractiveResource,
} from '@principle-theorem/principle-core/interfaces';
import {
  deleteDoc,
  isWithRef,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAppointmentFollowUp } from '../../pages/follow-ups-dashboard/follow-ups-dashboard.component';
import { FollowUpsService } from '../../follow-ups.service';
import { IActionButton } from '@principle-theorem/ng-shared';
import { PatientActionsFactoryService } from '@principle-theorem/ng-interactions';

@Component({
  selector: 'pr-follow-up-body',
  templateUrl: './follow-up-body.component.html',
  styleUrls: ['./follow-up-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FollowUpBodyComponent {
  followUp$: Observable<IAppointmentFollowUp>;
  appointment$: Observable<WithRef<IAppointment>>;
  patient$: Observable<WithRef<IPatient>>;
  patientUrl$: Observable<string[]>;
  interactions$: Observable<WithRef<IInteractionV2>[]>;
  interactiveResource$: Observable<IInteractiveResource>;
  patientActions: IActionButton[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAppointmentFollowUp,
    public dialogRef: MatDialogRef<FollowUpBodyComponent>,
    public followUpService: FollowUpsService,
    public actionFactory: PatientActionsFactoryService
  ) {
    this.followUp$ = of(data);
    this.appointment$ = this.followUp$.pipe(
      map((followUp) => followUp.appointment)
    );
    this.patient$ = this.followUp$.pipe(map((followUp) => followUp.patient));
    this.interactions$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.interactions$(appointment))
    );
    this.interactiveResource$ = this.appointment$.pipe(
      map((appointment) => ({
        interactions$: this.interactions$,
        add: async (interaction) => {
          await Appointment.addInteraction(appointment, interaction);
        },
      }))
    );
    this.patientUrl$ = this.patient$.pipe(
      map((patient) => ['patients', patient.ref.id])
    );
    this.patientActions = [
      actionFactory.sms,
      actionFactory.call,
      actionFactory.email,
    ].map((action) =>
      action.bind(actionFactory)(this.patient$, this.interactiveResource$)
    );
  }

  async addInteraction(interaction: IInteractionV2): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    await Appointment.addInteraction(appointment, interaction);
  }

  async updateInteraction(
    interaction: IInteraction | WithRef<IInteractionV2>
  ): Promise<void> {
    if (!isInteractionV2(interaction) || !isWithRef(interaction)) {
      return;
    }
    await Interaction.amend(interaction);
  }

  async deleteInteraction(
    interaction: IInteraction | WithRef<IInteractionV2>
  ): Promise<void> {
    if (!isInteractionV2(interaction) || !isWithRef(interaction)) {
      return;
    }
    await deleteDoc(interaction.ref);
  }
}
