import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import {
  type IInteraction,
  type IInteractionV2,
  type IPatient,
  isInteractionV2,
  IAppointment,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot, type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-scheduling-notes-tab',
    templateUrl: './scheduling-notes-tab.component.html',
    styleUrls: ['./scheduling-notes-tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SchedulingNotesTabComponent {
  interactions$: Observable<IInteractionV2[]>;
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  currentAppointment$: Observable<WithRef<IAppointment> | undefined>;
  hasNotifications$ = new ReplaySubject<boolean>(1);

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(private _schedulingFacade: AppointmentSchedulingFacade) {
    this.interactions$ = this._schedulingFacade.interactions$;
    this.currentAppointment$ = this._schedulingFacade.currentAppointment$;
  }

  updateInteractions(interactions: IInteractionV2[]): void {
    this._schedulingFacade.updateInteractions(interactions);
  }

  async addInteraction(
    interaction: IInteraction | WithRef<IInteractionV2>
  ): Promise<void> {
    if (!isInteractionV2(interaction)) {
      return;
    }
    const interactions = await snapshot(this.interactions$);
    this._schedulingFacade.updateInteractions([...interactions, interaction]);
  }

  async updateInteraction(
    updatedInteraction: IInteraction | WithRef<IInteractionV2>
  ): Promise<void> {
    if (!isInteractionV2(updatedInteraction)) {
      return;
    }
    const interactions = await snapshot(this.interactions$);
    this._schedulingFacade.updateInteractions(
      interactions.map((interaction) =>
        interaction.uid === updatedInteraction.uid
          ? updatedInteraction
          : interaction
      )
    );
  }

  async deleteInteraction(
    deletedInteraction: IInteraction | WithRef<IInteractionV2>
  ): Promise<void> {
    if (!isInteractionV2(deletedInteraction)) {
      return;
    }
    const interactions = await snapshot(this.interactions$);
    this._schedulingFacade.updateInteractions(
      interactions.filter(
        (interaction) => interaction.uid !== deletedInteraction.uid
      )
    );
  }
}
