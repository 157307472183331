import { isString } from 'lodash';
import { getEnumValues } from '@principle-theorem/shared';
import type { IMedipassConfig } from '@medipass/partner-sdk';

export type MedipassAppConfig = Pick<
  IMedipassConfig,
  'appId' | 'appVersion' | 'env'
>;

export enum MedipassEnvironment {
  Staging = 'stg',
  Production = 'prod',
}

export function isMedipassEnvironment(
  data: unknown
): data is MedipassEnvironment {
  const envs: string[] = getEnumValues(MedipassEnvironment);
  return isString(data) && envs.includes(data);
}

export enum MedipassWebhookEvent {
  TransactionUpdated = 'transactionUpdated',
  InvoiceCreated = 'invoiceCreated',
  InvoiceCompleted = 'invoiceCompleted',
  InvoiceCancelled = 'invoiceCancelled',
  InvoiceBalancePaid = 'invoiceBalancePaid',
  MemberApprovedInvoice = 'memberApprovedInvoice',
  MemberRejectedInvoice = 'memberRejectedInvoice',
  MedipassAutoCancelledInvoice = 'medipassAutoCancelledInvoice',
  HealthFundApprovedInvoice = 'healthFundApprovedInvoice',
  HealthFundRejectedInvoice = 'healthFundRejectedInvoice',
  HealthFundPaidInvoice = 'healthFundPaidInvoice',
}

export enum MedipassClaimStatus {
  Accepted = 'accepted',
  Approved = 'approved',
  Paid = 'paid',
  Cancelled = 'cancelled',
  Rejected = 'rejected',
  Reversed = 'reversed',
  UnderReview = 'under-review',
}

export enum MedipassTransactionStatus {
  Pending = 'pending',
  Cancelled = 'cancelled',
  Accepted = 'accepted',
  Completed = 'completed',
}

export enum MedipassPaymentStatus {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
}

export enum MedipassPaymentType {
  InPerson = 'inperson',
}
