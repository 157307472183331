import { RawSchema } from '@principle-theorem/editor';
import { INotificationContextRenderer } from '@principle-theorem/notifications';
import { NotificationType } from '@principle-theorem/principle-core/interfaces';
import {
  AddedTaskContextRenderer,
  CompletedTaskContextRenderer,
  PatientBookedAppointmentContextRenderer,
  PatientCancelledAppointmentContextRenderer,
  PatientRescheduledAppointmentContextRenderer,
  ReopenedTaskContextRenderer,
  StafferBookedAppointmentContextRenderer,
  StafferCancelledAppointmentContextRenderer,
  StafferRescheduledAppointmentContextRenderer,
  TaskMentionedUserContextRenderer,
} from './context-renderers';

export const NOTIFICATION_PROVIDERS: INotificationContextRenderer<
  RawSchema,
  NotificationType
>[] = [
  new PatientBookedAppointmentContextRenderer(),
  new StafferBookedAppointmentContextRenderer(),
  new PatientRescheduledAppointmentContextRenderer(),
  new StafferRescheduledAppointmentContextRenderer(),
  new PatientCancelledAppointmentContextRenderer(),
  new StafferCancelledAppointmentContextRenderer(),
  new TaskMentionedUserContextRenderer(),
  new CompletedTaskContextRenderer(),
  new ReopenedTaskContextRenderer(),
  new AddedTaskContextRenderer(),
];
