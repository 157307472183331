import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { PatientCustomFormsService } from '../../../patient-custom-forms.service';
import { PatientFormStore } from '../patient-form.store';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { WithRef, snapshotDefined } from '@principle-theorem/shared';
import { IPatientForm } from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-patient-form-actions',
  templateUrl: './patient-form-actions.component.html',
  styleUrl: './patient-form-actions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex flex-row items-center gap-4' },
  standalone: false,
})
export class PatientFormActionsComponent {
  readonly store = inject(PatientFormStore);
  readonly customForms = inject(PatientCustomFormsService);
  readonly organisation = inject(OrganisationService);

  async reissueForm(form: WithRef<IPatientForm>): Promise<void> {
    const staffer = await snapshotDefined(this.organisation.staffer$);
    await this.customForms.reissueForm(form, staffer, true);
  }
}
