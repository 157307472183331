<div
  class="selected-display"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="8px"
>
  <div class="selected-colour" [style.backgroundColor]="selected?.value"></div>
  <div fxFlex>
    <div class="mat-caption">{{ label }}</div>
    <div *ngIf="selected">{{ selected.name }}</div>
  </div>
</div>

<div class="options" fxLayout="row wrap" fxLayoutAlign="start start">
  <div
    *ngFor="let option of options; trackBy: trackByOption"
    class="option"
    [style.backgroundColor]="option.value"
    [ngClass]="{ selected: option.value === selected?.value }"
    (click)="selected = option"
  ></div>
</div>
