import {
  IMeasureMetadata,
  TreatmentPlanAction,
  TreatmentPlanStatus,
} from '@principle-theorem/principle-core/interfaces';
import { ITreatmentPlanEventFact } from '@principle-theorem/reporting/interfaces';
import { BigQueryTable } from '../../big-query-tables';
import {
  BrandQueryScopeConfig,
  DateRangeQueryScopeConfig,
  PracticeQueryScopeConfig,
} from '../../query-scope-config/query-scope-configs';
import { GroupBy } from '../../querying';
import { BaseFactMeasures, latestEventId } from '../base-measures';
import { MeasureLinkFactory, MeasurePath } from '../data-accessor-factory';
import { BrandDimensionMeasures } from '../dimensions/brand-dimension';
import { ModelEventDimensionMeasures } from '../dimensions/model-event-dimension';
import { PatientDimensionMeasures } from '../dimensions/patient-dimension';
import { PracticeDimensionMeasures } from '../dimensions/practice-dimension';
import { ReferrerDimensionMeasures } from '../dimensions/referrer-dimension';
import { StafferDimensionMeasures } from '../dimensions/staffer-dimension';
import { TreatmentPlanDimensionMeasures } from '../dimensions/treatment-plan-dimension';
import { ValueEqualsMeasureFilter } from '../measure-filters';
import {
  ComparableProperties,
  IMeasure,
  MeasureTransformMap,
} from '../measure-properties';
import { CanBeChartedProperty } from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { QueryFactory } from '../query-factory';

export class TreatmentPlanEventFactMeasures
  extends BaseFactMeasures
  implements MeasureTransformMap<ComparableProperties<ITreatmentPlanEventFact>>
{
  id = 'treatmentPlanEvent';
  readonly table = BigQueryTable.TreatmentPlanEvent;
  readonly name = 'Treatment Plan';
  scopes = [
    new BrandQueryScopeConfig(),
    new PracticeQueryScopeConfig(),
    new DateRangeQueryScopeConfig(),
  ];

  get practitioner(): StafferDimensionMeasures {
    return new StafferDimensionMeasures(BigQueryTable.Staff, 'practitioner', {
      sourceJoinKey: MeasurePath.docRef('practitioner.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get treatmentPlan(): TreatmentPlanDimensionMeasures {
    return new TreatmentPlanDimensionMeasures(
      BigQueryTable.TreatmentPlans,
      'treatmentPlan',
      {
        sourceJoinKey: MeasurePath.docRef('treatmentPlan.ref'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get practice(): PracticeDimensionMeasures {
    return new PracticeDimensionMeasures(BigQueryTable.Practices, 'practice', {
      sourceJoinKey: MeasurePath.docRef('practice.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get patient(): PatientDimensionMeasures {
    return new PatientDimensionMeasures(BigQueryTable.Patients, 'patient', {
      sourceJoinKey: MeasurePath.docRef('patient.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get referrer(): ReferrerDimensionMeasures {
    return new ReferrerDimensionMeasures(this.table, 'referrer');
  }

  get brand(): BrandDimensionMeasures {
    return new BrandDimensionMeasures(BigQueryTable.Brands, 'brand', {
      sourceJoinKey: MeasurePath.docRef('brand.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get event(): ModelEventDimensionMeasures<
    TreatmentPlanAction,
    TreatmentPlanStatus
  > {
    return new ModelEventDimensionMeasures(
      this.table,
      'event',
      'Treatment Plan'
    );
  }

  get latestEvent(): TreatmentPlanEventFactMeasures {
    const measures = new TreatmentPlanEventFactMeasures();
    measures.id = latestEventId(measures);
    measures._query = QueryFactory.fromQuery({
      table: measures.table,
      ...measures._query,
    })
      .latestEvent(measures.table, GroupBy.TreatmentPlan)
      .filterSoftDeleted(measures.treatmentPlan.measureRef('deleted'))
      .get();
    return measures;
  }

  get draft(): CanBeChartedProperty {
    const metadata: IMeasureMetadata = {
      id: 'draft',
      label: 'Draft',
      summary: '',
    };
    const measure: IMeasure = this.event.statusAfter.filterBy(
      new ValueEqualsMeasureFilter(TreatmentPlanStatus.Draft)
    ).measure;
    this._query = { ...this._query, ...measure };
    return new CanBeChartedProperty({
      metadata,
      measure,
    });
  }

  get offered(): CanBeChartedProperty {
    const metadata: IMeasureMetadata = {
      id: 'offered',
      label: 'Offered',
      summary: '',
    };
    const measure: IMeasure = this.event.statusAfter.filterBy(
      new ValueEqualsMeasureFilter(TreatmentPlanStatus.Offered)
    ).measure;
    this._query = { ...this._query, ...measure };
    return new CanBeChartedProperty({
      metadata,
      measure,
    });
  }

  get accepted(): CanBeChartedProperty {
    const metadata: IMeasureMetadata = {
      id: 'accepted',
      label: 'Accepted',
      summary: '',
    };
    const measure: IMeasure = this.event.statusAfter.filterBy(
      new ValueEqualsMeasureFilter(TreatmentPlanStatus.Accepted)
    ).measure;
    this._query = { ...this._query, ...measure };
    return new CanBeChartedProperty({
      metadata,
      measure,
    });
  }

  get rejected(): CanBeChartedProperty {
    const metadata: IMeasureMetadata = {
      id: 'rejected',
      label: 'Rejected',
      summary: '',
    };
    const measure: IMeasure = this.event.statusAfter.filterBy(
      new ValueEqualsMeasureFilter(TreatmentPlanStatus.Declined)
    ).measure;
    this._query = { ...this._query, ...measure };
    return new CanBeChartedProperty({
      metadata,
      measure,
    });
  }

  get inProgress(): CanBeChartedProperty {
    const metadata: IMeasureMetadata = {
      id: 'inProgress',
      label: 'In Progress',
      summary: '',
    };
    const measure: IMeasure = this.event.statusAfter.filterBy(
      new ValueEqualsMeasureFilter(TreatmentPlanStatus.InProgress)
    ).measure;
    this._query = { ...this._query, ...measure };
    return new CanBeChartedProperty({
      metadata,
      measure,
    });
  }

  get completed(): CanBeChartedProperty {
    const metadata: IMeasureMetadata = {
      id: 'completed',
      label: 'Completed',
      summary: '',
    };
    const measure: IMeasure = this.event.statusAfter.filterBy(
      new ValueEqualsMeasureFilter(TreatmentPlanStatus.Completed)
    ).measure;
    this._query = { ...this._query, ...measure };
    return new CanBeChartedProperty({
      metadata,
      measure,
    });
  }

  get patientLink(): CanBeChartedProperty {
    const brand = this.brand.measureRef('slug');
    const patient = this.patient.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.link(
      {
        id: 'patient.link',
        label: 'Patient Link',
        summary: 'Principle link for the given patient',
      },
      patient,
      this.buildQuery()
        .mergeJoins(this.brand.query.joins)
        .mergeJoins(this.patient.query.joins)
        .attributes([brand.attributePath, patient.attributePath])
        .get(),
      brand,
      MeasureLinkFactory.patient
    );
  }
}
