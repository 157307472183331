import { TaxRate } from '@principle-theorem/accounting';
import {
  TreatmentPlan,
  hasMergeConflicts,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  IAppointment,
  IArraySorter,
  IDestinationEntity,
  IDestinationEntityRecord,
  IGetRecordResponse,
  IHasSourceIdentifier,
  IMigratedDataSummary,
  IPatient,
  IPracticeMigration,
  ISourceEntityHandler,
  ISourceEntityRecord,
  IStaffer,
  ITranslationMap,
  ITreatmentCategory,
  ITreatmentConfiguration,
  ITreatmentPlan,
  ITreatmentStep,
  MergeConflictDestinationEntityRecord,
  SkippedDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  ISODateType,
  Reffable,
  Timestamp,
  Transaction,
  WithRef,
  asyncForAll,
  asyncForEach,
  runTransaction,
  safeCombineLatest,
  toTimestamp,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { compact, omit, sortBy } from 'lodash';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ItemCodeResourceMapType } from '../../mappings/item-codes-to-xlsx';
import { TranslationMapHandler } from '../../translation-map';
import { BaseDestinationEntity } from '../base-destination-entity';
import { DestinationEntity } from '../destination-entity';
import { PatientIdFilter } from '../filters/patient-id-filter';

export const PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE =
  'patientTreatmentPlan';

export const PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE =
  'patientTreatmentStep';

export const PATIENT_TREATMENT_STEP_ASSOCIATED_APPOINTMENT_CUSTOM_MAPPING_TYPE =
  'patientTreatmentStepAppointment';

export const PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY = DestinationEntity.init(
  {
    metadata: {
      key: PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
      label: 'Patient Treatment Plans',
      description: `This migrates the appointment procedures for each patient's appointment and creates a single step on a single plan for each appointment.`,
    },
  }
);

export interface IPatientTreatmentPlanJobData<
  Patient extends object,
  Translations extends object = object,
  Filters extends object = object,
> {
  sourcePatient: IGetRecordResponse<Patient, Translations, Filters>;
  defaultTreatmentConfiguration: WithRef<ITreatmentConfiguration>;
  treatmentConfigurations: WithRef<ITreatmentConfiguration>[];
  treatmentConfigurationMappings: WithRef<
    ITranslationMap<ITreatmentConfiguration>
  >[];
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[];
  staff: WithRef<ITranslationMap<IStaffer>>[];
  practitioners: WithRef<IStaffer>[];
  treatmentCategories: WithRef<ITreatmentCategory>[];
  taxRate: TaxRate;
}

export interface IStepWithIdentifiers
  extends ITreatmentStep,
    IHasSourceIdentifier {
  createdAt: Timestamp;
  date?: ISODateType;
}
export interface ITreatmentPlanStepPair {
  plan: ITreatmentPlan & IHasSourceIdentifier;
  steps: IStepWithIdentifiers[];
}

export interface IPatientTreatmentPlanMigrationData {
  patientRef: DocumentReference<IPatient>;
  sourcePatientId: string;
  planPairs: ITreatmentPlanStepPair[];
}

export interface ITreatmentPlanSuccessData {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  treatmentPlanRefs: DocumentReference<ITreatmentPlan>[];
  treatmentStepRefs: DocumentReference<ITreatmentStep>[];
}

export abstract class BasePatientTreatmentPlanDestinationEntity<
  PatientRecord extends object,
  JobData extends IPatientTreatmentPlanJobData<PatientRecord>,
> extends BaseDestinationEntity<
  ITreatmentPlanSuccessData,
  JobData,
  IPatientTreatmentPlanMigrationData
> {
  destinationEntity = PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY;

  abstract patientSourceEntity: ISourceEntityHandler<PatientRecord[]>;

  get sourceCountComparison(): ISourceEntityHandler<PatientRecord[]> {
    return this.patientSourceEntity;
  }

  override canMigrateByIdRange = true;

  override filters = [
    new PatientIdFilter<JobData>((jobData) =>
      this.patientSourceEntity
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  override sorters: IArraySorter[] = [
    {
      key: 'chartedSurfaces',
      sortByPath: [
        'resolvedAt.seconds',
        'chartedRef.wholeMouth',
        'chartedRef.tooth.quadrant',
        'chartedRef.tooth.quadrantIndex',
        'chartedRef.tooth.surface',
      ],
    },
    {
      key: 'treatments',
      sortByPath: [
        'type',
        'config.ref.id',
        'scopeRef',
        'price',
        'attributedTo.ref.id',
        'adaCodes[0].code.name',
        'chartedSurfaces[0].resolvedAt.seconds',
        'chartedSurfaces[0].chartedRef.wholeMouth',
        'chartedSurfaces[0].chartedRef.tooth.quadrant',
        'chartedSurfaces[0].chartedRef.tooth.quadrantIndex',
        'chartedSurfaces[0].chartedRef.tooth.surface',
      ],
    },
    {
      key: 'serviceCodes',
      sortByPath: 'code.name',
    },
    {
      key: 'planPairs',
      sortByPath: 'plan.name',
    },
    {
      key: 'steps',
      sortByPath: 'name',
    },
  ];

  sourceCountDataAccessor(
    data: JobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getDestinationEntityRecordUid(data: JobData): string {
    return data.sourcePatient.record.uid;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<ITreatmentPlanSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      Firestore.getDoc(record.data.sourceRef),
      safeCombineLatest(
        record.data.treatmentPlanRefs.map((treatmentPlanRef) =>
          Firestore.getDoc(treatmentPlanRef)
        )
      ),
      safeCombineLatest(
        record.data.treatmentStepRefs.map((treatmentStepRef) =>
          Firestore.getDoc(treatmentStepRef)
        )
      ),
    ]).pipe(
      map(([sourceRecord, treatmentPlans, treatmentSteps]) => [
        {
          label: 'Source Record',
          data: sourceRecord,
        },
        ...treatmentPlans.map((treatmentPlan) => ({
          label: `Treatment Plan - ${treatmentPlan.name}`,
          data: treatmentPlan,
        })),
        ...treatmentSteps.map((treatmentStep) => ({
          label: `Treatment Step - ${treatmentStep.name}`,
          data: treatmentStep,
        })),
      ])
    );
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IPatientTreatmentPlanMigrationData
  ): Promise<IPatientTreatmentPlanMigrationData | undefined> {
    try {
      const mergeConflictPlanPairs = await asyncForEach(
        data.planPairs,
        async ({ plan, steps }) => {
          const planRef = await translationMap.getDestination(
            plan.sourceIdentifier,
            PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE
          );

          if (!planRef) {
            return;
          }

          const existingPlan = await Firestore.getDoc(
            planRef as DocumentReference<ITreatmentPlan>
          );

          const planMergeConflict = hasMergeConflicts(plan, {
            ...existingPlan,
            steps: [],
          });

          const existingSteps: IStepWithIdentifiers[] = [];

          const stepMergeConflicts = await asyncForEach(steps, async (step) => {
            const stepRef = await translationMap.getDestination<ITreatmentStep>(
              step.sourceIdentifier,
              PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
            );

            if (!stepRef) {
              return false;
            }

            const existingStep = await Firestore.getDoc(stepRef);

            existingSteps.push({
              ...omit(existingStep, 'ref'),
              sourceIdentifier: step.sourceIdentifier,
              createdAt: step.createdAt,
            });

            const stepMergeConflict = hasMergeConflicts(
              omit(step, ['sourceIdentifier']),
              omit(existingStep, ['sourceIdentifier']),
              ['chartedAt'],
              [
                {
                  key: 'chartedSurfaces',
                  sortByPath: [
                    'resolvedAt.seconds',
                    'chartedRef.wholeMouth',
                    'chartedRef.tooth.quadrant',
                    'chartedRef.tooth.quadrantIndex',
                    'chartedRef.tooth.surface',
                  ],
                },
                {
                  key: 'treatments',
                  sortByPath: [
                    'type',
                    'config.ref.id',
                    'scopeRef',
                    'price',
                    'attributedTo.ref.id',
                    'adaCodes[0].code.name',
                    'chartedSurfaces[0].resolvedAt.seconds',
                    'chartedSurfaces[0].chartedRef.wholeMouth',
                    'chartedSurfaces[0].chartedRef.tooth.quadrant',
                    'chartedSurfaces[0].chartedRef.tooth.quadrantIndex',
                    'chartedSurfaces[0].chartedRef.tooth.surface',
                  ],
                },
              ]
            );

            return stepMergeConflict;
          });

          const hasIncorrectStepCount =
            existingPlan.steps.length !== existingSteps.length;

          const hasMergeConflict = [
            hasIncorrectStepCount,
            planMergeConflict,
            ...stepMergeConflicts,
          ].some((stepMergeConflict) => stepMergeConflict);

          if (hasMergeConflict) {
            return {
              plan: {
                ...omit(existingPlan, 'ref'),
                steps: [],
                sourceIdentifier: plan.sourceIdentifier,
              },
              steps: sortBy(existingSteps, 'name'),
            };
          }
        }
      );

      if (compact(mergeConflictPlanPairs).length) {
        return {
          ...data,
          planPairs: compact(mergeConflictPlanPairs),
        };
      }
    } catch (error) {
      return;
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: JobData,
    _migrationData: IPatientTreatmentPlanMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: JobData,
    migrationData: IPatientTreatmentPlanMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      migrationData.planPairs = migrationData.planPairs.filter(
        (planPairs) => planPairs.steps.length > 0
      );
      if (!migrationData.planPairs.length) {
        return this._buildSuccessResponse(jobData.sourcePatient, [], []);
      }

      const { planRefs, stepRefs } = await this._upsertTreatmentPlans(
        migrationData,
        translationMap
      );

      return this._buildSuccessResponse(
        jobData.sourcePatient,
        planRefs,
        stepRefs
      );
    } catch (error) {
      return this._buildErrorResponse(jobData.sourcePatient, String(error));
    }
  }

  protected _buildSuccessResponse(
    patient: JobData['sourcePatient'],
    treatmentPlanRefs: DocumentReference<ITreatmentPlan>[],
    treatmentStepRefs: DocumentReference<ITreatmentStep>[]
  ): IDestinationEntityRecord<ITreatmentPlanSuccessData> {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      data: {
        sourceRef: patient.record.ref,
        treatmentPlanRefs,
        treatmentStepRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: patient.record.ref,
      migratedAt: toTimestamp(),
    };
  }

  protected _buildErrorResponse(
    patient: JobData['sourcePatient'],
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: patient.record.ref,
      errorMessage:
        errorMessage ?? 'Missing required properties for treatment plan',
      failData: {
        patientRef: patient.record.ref,
      },
    };
  }

  protected _buildSkippedResponse(
    patient: JobData['sourcePatient']
  ): IDestinationEntityRecord & SkippedDestinationEntityRecord {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      status: DestinationEntityRecordStatus.Skipped,
      sourceRef: patient.record.ref,
    };
  }

  private async _upsertTreatmentPlans(
    migrationData: IPatientTreatmentPlanMigrationData,
    translationMap: TranslationMapHandler
  ): Promise<{
    planRefs: DocumentReference<ITreatmentPlan>[];
    stepRefs: DocumentReference<ITreatmentStep>[];
  }> {
    const results = await runTransaction((transaction) =>
      asyncForAll(migrationData.planPairs, async ({ plan, steps }) => {
        const planDestinationRef =
          await translationMap.getDestination<ITreatmentPlan>(
            plan.sourceIdentifier,
            PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE
          );

        const planRef = await FirestoreMigrate.upsertDoc(
          TreatmentPlan.col({
            ref: migrationData.patientRef,
          }),
          {
            ...plan,
            deleted: false,
          },
          planDestinationRef,
          transaction
        );

        if (!planDestinationRef) {
          await translationMap.upsert(
            {
              sourceIdentifier: plan.sourceIdentifier,
              destinationIdentifier: planRef,
              resourceType: PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
            },
            transaction
          );
        }

        const stepRefs = await asyncForAll(steps, async (step) => {
          const stepDestinationRef =
            await translationMap.getDestination<ITreatmentStep>(
              step.sourceIdentifier,
              PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
            );

          let appointment = step.appointment;

          try {
            const existingStep = stepDestinationRef
              ? await Firestore.safeGetDoc(stepDestinationRef)
              : undefined;

            if (!appointment && existingStep?.appointment) {
              appointment = existingStep?.appointment;
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
          }

          const stepRef = await FirestoreMigrate.upsertDoc(
            TreatmentPlan.treatmentStepCol({
              ref: planRef,
            }),
            {
              ...step,
              appointment,
              deleted: false,
            },
            stepDestinationRef,
            transaction
          );

          const resolvedAppointment = appointment
            ? await Firestore.getDoc(appointment)
            : undefined;
          if (resolvedAppointment) {
            await FirestoreMigrate.patchDoc(
              resolvedAppointment.ref,
              {
                treatmentPlan: TreatmentPlan.treatmentStepToAssociatedTreatment(
                  { ...plan, ref: planRef },
                  { ...step, ref: stepRef }
                ),
              },
              undefined,
              transaction
            );
          }

          if (!stepDestinationRef) {
            await translationMap.upsert(
              {
                sourceIdentifier: step.sourceIdentifier,
                destinationIdentifier: stepRef,
                resourceType: PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
              },
              transaction
            );
          }

          await this._associateAppointmentWithStep(
            { ...step, ref: stepRef } as Reffable<IStepWithIdentifiers>,
            {
              ...plan,
              deleted: false,
              ref: planRef,
            },
            translationMap,
            transaction
          );

          return stepRef;
        });

        await FirestoreMigrate.patchDoc(
          planRef,
          {
            steps: stepRefs,
          },
          undefined,
          transaction
        );

        return { planRefs: [planRef], stepRefs };
      })
    );

    return {
      planRefs: results.map((result) => result.planRefs).flat(),
      stepRefs: results.map((result) => result.stepRefs).flat(),
    };
  }

  private async _associateAppointmentWithStep(
    step: Reffable<IStepWithIdentifiers>,
    resolvedPlan: Reffable<ITreatmentPlan>,
    translationMap: TranslationMapHandler,
    transaction: Transaction
  ): Promise<void> {
    const stepAppointmentRef =
      await translationMap.getDestination<IAppointment>(
        step.sourceIdentifier,
        PATIENT_TREATMENT_STEP_ASSOCIATED_APPOINTMENT_CUSTOM_MAPPING_TYPE
      );

    const appointmentRef = step.appointment ?? stepAppointmentRef;

    if (!appointmentRef) {
      return;
    }

    await FirestoreMigrate.updateDoc(
      step.ref,
      {
        appointment: appointmentRef,
      },
      transaction
    );

    const appointment = await Firestore.getDoc(appointmentRef);
    const isFromDefaultPlan = appointment.treatmentPlan.name.endsWith(
      'Migrated Appointments'
    );
    if (isFromDefaultPlan) {
      await FirestoreMigrate.deleteDoc(
        appointment.treatmentPlan.treatmentStep.ref,
        transaction
      );
    }

    if (!stepAppointmentRef) {
      await translationMap.upsert(
        {
          sourceIdentifier: step.sourceIdentifier,
          destinationIdentifier: appointmentRef,
          resourceType:
            PATIENT_TREATMENT_STEP_ASSOCIATED_APPOINTMENT_CUSTOM_MAPPING_TYPE,
        },
        transaction
      );
    }

    await FirestoreMigrate.updateDoc(
      appointmentRef,
      {
        treatmentPlan: TreatmentPlan.treatmentStepToAssociatedTreatment(
          resolvedPlan,
          step
        ),
      },
      transaction
    );
  }
}

export async function findTreatmentStepForAppointment(
  translationMap: TranslationMapHandler,
  stepSourceIdentifier: string
): Promise<DocumentReference<ITreatmentStep> | undefined> {
  const stepDestinationRef =
    await translationMap.getDestination<ITreatmentStep>(
      stepSourceIdentifier,
      PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
    );

  return stepDestinationRef;
}
