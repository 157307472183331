import {
  InlineNodes,
  findNodesOfType,
  getActiveNode,
  nodeTypeIsActive,
  type IMenuButton,
  removeLink,
  LINK_KEYMAP,
  BlockNodes,
} from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { Fragment, type Node } from '@tiptap/pm/model';
import { BasicMenuButtonComponent } from '../../menu-bar/basic-menu-button/basic-menu-button.component';
import { NodeSelection } from '@tiptap/pm/state';

export function createLinkMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'basic',
      buttonText: 'Link',
      tooltip: 'Link',
      shortcut: LINK_KEYMAP,
      icon: 'link',
      command: (editor) => {
        if (!editor.view.dispatch) {
          return false;
        }
        const type = editor.schema.nodes[InlineNodes.Link];

        if (nodeTypeIsActive(editor.state, type)) {
          const node = getActiveNode(editor.state, type);
          if (!node) {
            return false;
          }
          removeLink(editor.state, editor.view.dispatch, node);
          return false;
        }

        const transaction = editor.state.tr;

        const textNodes: Node[] = findNodesOfType(
          transaction.selection.content().content,
          editor.schema.nodes[InlineNodes.Text]
        );

        transaction.replaceSelectionWith(
          type.create(undefined, Fragment.fromArray(textNodes))
        );

        const resolvedPos = transaction.doc.resolve(
          transaction.selection.anchor -
            (transaction.selection.$anchor.nodeBefore?.nodeSize ?? 0)
        );

        transaction.setSelection(new NodeSelection(resolvedPos));
        editor.view.dispatch(transaction);
        return true;
      },
      isActiveFn: (editor) => editor.isActive(InlineNodes.Link),
      canActivateFn: (editor) => editor.isActive(BlockNodes.Paragraph),
      isDisabledFn: (editor) =>
        !(
          editor.isActive(BlockNodes.Paragraph) ||
          editor.isActive(BlockNodes.Heading)
        ),
    },
  });
}
