<ng-container *ngIf="clinicalNotesDisplay$ | async as clinicalNotes">
  <ng-container *ngIf="clinicalNotes.length">
    <button
      *ngIf="type === 'button'"
      mat-icon-button
      matTooltip="Download clinical notes"
      (click)="downloadClinicalNotes()"
    >
      <mat-icon>file_download</mat-icon>
    </button>

    <button
      *ngIf="type === 'menu'"
      mat-menu-item
      (click)="downloadClinicalNotes()"
    >
      <mat-icon>file_download</mat-icon>
      <span>Download Clinical Notes</span>
    </button>

    <div #content hidden class="content">
      <ng-container *ngIf="practice$ | async as practice">
        <div class="flex justify-between gap-4">
          <h3 *ngIf="brand$ | async as brand">{{ brand.name }}</h3>
          <h3>Clinical Notes</h3>
        </div>

        <div class="practice-details-container">
          <p>{{ practice.name }}</p>
          <p>{{ practice.address }}</p>
          <p>{{ practice.email }}</p>
          <p>{{ practice.phone }}</p>
        </div>
      </ng-container>

      <div class="flex flex-col gap-6">
        <div *ngIf="patient$ | async as patient">
          <p>Patient: {{ patient.name }}</p>
          <p>
            Date of birth:
            {{ patient.dateOfBirth | moment | amDateFormat: dateFormat }}
          </p>
        </div>

        <div
          class="note-container"
          *ngFor="let clinicalNote of clinicalNotes; trackBy: trackByNote"
        >
          <div class="flex items-center justify-between gap-4">
            <p>
              Date:
              {{ clinicalNote.recordDate | moment | amDateFormat: dateFormat }}
            </p>
            <p>Recorded by: {{ clinicalNote.owner }}</p>
          </div>
          <mat-divider />
          <p
            *ngFor="
              let content of clinicalNote.content;
              trackBy: trackByContent
            "
          >
            {{ content }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
