import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { snapshot } from '@principle-theorem/shared';
import { loadStripe, type Stripe } from '@stripe/stripe-js';
import { from, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { type IStripeConfig, NG_STRIPE_CONFIG } from './stripe-config';

@Injectable()
export class StripeService {
  public stripe$: Observable<Stripe | undefined>;

  constructor(
    @Inject(NG_STRIPE_CONFIG) public config: IStripeConfig,
    private _snackbar: MatSnackBar
  ) {
    this.stripe$ = from(loadStripe(config.apiKey)).pipe(
      map((stripe) => stripe || undefined)
    );
  }

  get stripe(): Promise<Stripe | undefined> {
    return snapshot(this.stripe$);
  }

  showStripeInitError(): void {
    this._snackbar.open('Unable to load Stripe');
  }
}
