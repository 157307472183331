import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Interaction } from '@principle-theorem/principle-core';
import { type IInteractionV2 } from '@principle-theorem/principle-core/interfaces';
import {
  type ArchivedDocument,
  filterUndefined,
  type WithRef,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IInteractionAmendmentHistoryDialogData {
  interaction: WithRef<IInteractionV2>;
  selectedInteraction?: WithRef<ArchivedDocument<IInteractionV2>>;
}

@Component({
    selector: 'pr-interaction-amendment-history-dialog',
    templateUrl: './interaction-amendment-history-dialog.component.html',
    styleUrls: ['./interaction-amendment-history-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InteractionAmendmentHistoryDialogComponent {
  amendmentHistory$: Observable<WithRef<ArchivedDocument<IInteractionV2>>[]>;
  selectedInteraction$: Observable<WithRef<ArchivedDocument<IInteractionV2>>>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: IInteractionAmendmentHistoryDialogData
  ) {
    this.amendmentHistory$ = Interaction.amendmentHistory$(data.interaction);
    this.selectedInteraction$ = this.amendmentHistory$.pipe(
      map(
        (amendmentHistory) =>
          data.selectedInteraction ?? first(amendmentHistory)
      ),
      filterUndefined()
    );
  }
}
