import { isObject } from '@principle-theorem/shared';
import {
  type IBaseTransactionCompleteCallbackData,
  type IPurchaseRequestParams,
  isTransactionCompleteCallbackData,
} from '@principle-theorem/tyro';

export interface ITyroBaseExtendedData {
  response: IBaseTransactionCompleteCallbackData;
}

export interface IPurchaseTransactionExtendedData
  extends ITyroBaseExtendedData {
  request: IPurchaseRequestParams;
  response: IBaseTransactionCompleteCallbackData;
}

export function isPurchaseTransactionExtendedData(
  data: unknown
): data is IPurchaseTransactionExtendedData {
  return (
    isObject(data) &&
    // TODO: #3ax3vq Check request property?
    isTransactionCompleteCallbackData(data.response)
  );
}
