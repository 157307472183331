import { VersionedSchema } from '@principle-theorem/editor';
import { getEnumValues } from '@principle-theorem/shared';
import { isString } from 'lodash';

export enum CustomFormElementType {
  Container = 'container',
  Section = 'section',
  Content = 'content',
  Text = 'text',
  Textarea = 'textarea',
  Number = 'number',
  Checkbox = 'checkbox',
  Date = 'date',
  Dropdown = 'dropdown',
  Signature = 'signature',
}

export const CUSTOM_FORM_ELEMENT_DESCRIPTION: {
  [key in CustomFormElementType]: string;
} = {
  [CustomFormElementType.Container]: '',
  [CustomFormElementType.Section]:
    'Layout your elements in sections. Sections can have multiple columns.',
  [CustomFormElementType.Content]:
    'Write content for your form. Include images, links, and more.',
  [CustomFormElementType.Text]: 'A basic text field',
  [CustomFormElementType.Textarea]: 'A large text field',
  [CustomFormElementType.Number]: 'A number field',
  [CustomFormElementType.Checkbox]: 'A checkbox field',
  [CustomFormElementType.Date]: 'A date field',
  [CustomFormElementType.Dropdown]:
    'A dropdown field with options you can configure',
  [CustomFormElementType.Signature]: 'A signature field for users to sign',
};

export function isCustomFormElementType(
  data: unknown
): data is CustomFormElementType {
  return (
    isString(data) &&
    getEnumValues(CustomFormElementType).includes(data as CustomFormElementType)
  );
}

export enum CustomFormElementCategory {
  Section = 'section',
  Layout = 'layout',
  Field = 'field',
}

interface ICustomFormElement<
  Type extends CustomFormElementType,
  Options extends object,
> {
  uid: string;
  type: Type;
  options: Options;
  children: AnyCustomFormElement[];
  dropListCategory?: CustomFormElementCategory;
}

export interface ICustomFormSectionOptions {
  title?: string;
  numberOfColumns: number;
}

export type CustomFormSectionElement = ICustomFormElement<
  CustomFormElementType.Section,
  ICustomFormSectionOptions
>;

export interface ICustomFormContainerOptions {
  direction?: 'row' | 'column';
}
export type CustomFormContainerElement = ICustomFormElement<
  CustomFormElementType.Container,
  ICustomFormContainerOptions
>;

export interface ICustomFormContentOptions {
  content: VersionedSchema;
  editableOnIssue: boolean;
}

export type CustomFormContentElement = ICustomFormElement<
  CustomFormElementType.Content,
  ICustomFormContentOptions
>;

export interface ICustomFormFieldOptions {
  dataKey: string;
  label: string;
  required: boolean;
}

export type CustomFormFieldElement = ICustomFormElement<
  | CustomFormElementType.Text
  | CustomFormElementType.Textarea
  | CustomFormElementType.Number
  | CustomFormElementType.Checkbox
  | CustomFormElementType.Date
  | CustomFormElementType.Signature,
  ICustomFormFieldOptions
>;

export interface ICustomFormDropdownOptions extends ICustomFormFieldOptions {
  dataKey: string;
  label: string;
  required: boolean;
  options: string[];
}

export type CustomFormDropdownElement = ICustomFormElement<
  CustomFormElementType.Dropdown,
  ICustomFormDropdownOptions
>;

export type CustomFormInputFieldElement =
  | CustomFormContentElement
  | CustomFormFieldElement
  | CustomFormDropdownElement;

export type AnyCustomFormElement =
  | CustomFormContainerElement
  | CustomFormSectionElement
  | CustomFormInputFieldElement;
