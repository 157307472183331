import { NgModule } from '@angular/core';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ComponentsModule } from '../components/components.module';
import { ChatsDashboardComponent } from './chats-dashboard/chats-dashboard.component';

@NgModule({
  imports: [
    NgSharedModule,
    NgMaterialModule,
    NgPrincipleSharedModule,
    ComponentsModule,
    NgInteractionsModule,
  ],
  declarations: [ChatsDashboardComponent],
  exports: [ChatsDashboardComponent],
})
export class ChatsPagesModule {}
