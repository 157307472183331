import {
  ADA_CODE_TREATMENT_CONFIG_ID,
  FeeSchedule,
  TreatmentConfiguration,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  IHasSourceIdentifier,
  IMigratedDataSummary,
  ITranslationMap,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type ISourceEntityRecord,
  type IStaffer,
  type ITreatmentConfiguration,
  type ITreatmentPlan,
  type ITreatmentStep,
  type MergeConflictDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  HISTORY_DATE_FORMAT,
  asyncForEach,
  getDoc,
  getDoc$,
  getError,
  reduce2DArray,
  safeCombineLatest,
  toMomentTz,
  toTimestamp,
  type DocumentReference,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, omit } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { FirestoreMigrate } from '../../../destination/destination';
import { DestinationEntity } from '../../../destination/destination-entity';
import { DestinationEntityRecord } from '../../../destination/destination-entity-record';
import { PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE } from '../../../destination/entities/patient-treatment-plans';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { resolveFeeSchedule } from '../../../mappings/fee-schedules';
import { ItemCodeResourceMapType } from '../../../mappings/item-codes-to-xlsx';
import { PracticeMigration } from '../../../practice-migrations';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientAppointmentProcedureSourceEntity,
  type IPraktikaAppointmentProcedure,
  type IPraktikaAppointmentProcedureFilters,
  type IPraktikaAppointmentProcedureTranslations,
} from '../../source/entities/appointment-procedure';
import {
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
  type IPraktikaPatient,
  type IPraktikaPatientFilters,
  type IPraktikaPatientTranslations,
} from '../../source/entities/patient';
import { PatientAppointmentSourceEntity } from '../../source/entities/patient-appointment';
import { PraktikaItemCodeMappingHandler } from '../mappings/item-code';
import { PraktikaItemCodeToTreatmentMappingHandler } from '../mappings/item-code-to-treatment';
import { PraktikaPracticeMappingHandler } from '../mappings/practices';
import { PraktikaStafferMappingHandler } from '../mappings/staff';
import { getChartedTreatments } from './patient-treatment-plan';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';

export const PATIENT_TREATMENT_PLAN_PROPOSAL_DESTINATION_ENTITY =
  DestinationEntity.init({
    metadata: {
      key: 'patientTreatmentPlanProposals',
      label: 'Patient Treatment Plan Proposals',
      description: `This migrates the quote procedures for each patient.`,
    },
  });

export const PATIENT_TREATMENT_PLAN_PROPOSAL_CUSTOM_MAPPING_TYPE =
  'patientTreatmentPlanProposal';

export const PATIENT_TREATMENT_STEP_PROPOSAL_CUSTOM_MAPPING_TYPE =
  'patientTreatmentStepProposal';

interface ITreatmentPlanStepPair extends IHasSourceIdentifier {
  createdAt: Timestamp;
  plan: ITreatmentPlan;
  step: ITreatmentStep;
}

interface ITreatmentPlanStepDocPair {
  planRef: DocumentReference<ITreatmentPlan>;
  stepRef: DocumentReference<ITreatmentStep>;
}

interface ITreatmentPlanProposalSuccessData {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  planStepPairs: ITreatmentPlanStepDocPair[];
}

interface IQuoteData {
  id: string;
  date: Timestamp;
  patientId: string;
  procedures: IGetRecordResponse<
    IPraktikaAppointmentProcedure,
    IPraktikaAppointmentProcedureTranslations,
    IPraktikaAppointmentProcedureFilters
  >[];
}

interface IPatientTreatmentPlanProposalJobData {
  sourcePatient: IGetRecordResponse<
    IPraktikaPatient,
    IPraktikaPatientTranslations,
    IPraktikaPatientFilters
  >;
  defaultTreatmentConfiguration: WithRef<ITreatmentConfiguration>;
  treatmentConfigurations: WithRef<ITreatmentConfiguration>[];
  treatmentConfigurationMappings: WithRef<
    ITranslationMap<ITreatmentConfiguration>
  >[];
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[];
  staff: WithRef<ITranslationMap<IStaffer>>[];
}

interface IPatientTreatmentPlanProposalMigrationData {
  planPairs: ITreatmentPlanStepPair[];
  patientRef: DocumentReference<IPatient>;
}

export class PatientTreatmentPlanProposalDestinationEntity extends BaseDestinationEntity<
  ITreatmentPlanProposalSuccessData,
  IPatientTreatmentPlanProposalJobData,
  IPatientTreatmentPlanProposalMigrationData
> {
  destinationEntity = PATIENT_TREATMENT_PLAN_PROPOSAL_DESTINATION_ENTITY;

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
    appointmentProcedures: new PatientAppointmentProcedureSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new PraktikaStafferMappingHandler(),
    practice: new PraktikaPracticeMappingHandler(),
    itemCodes: new PraktikaItemCodeMappingHandler(),
    itemCodeToTreatment: new PraktikaItemCodeToTreatmentMappingHandler(),
  };

  sourceCountDataAccessor(
    data: IPatientTreatmentPlanProposalJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<ITreatmentPlanProposalSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return safeCombineLatest(
      record.data.planStepPairs.map((pair) =>
        combineLatest([getDoc(pair.planRef), getDoc(pair.stepRef)]).pipe(
          map(([plan, step]) => ({
            plan,
            step,
          }))
        )
      )
    ).pipe(
      map((treatmentPlanPairs) =>
        treatmentPlanPairs.map((treatmentPlanPair) => [
          {
            label: `Treatment Plan - ${treatmentPlanPair.plan.name}`,
            data: treatmentPlanPair.plan,
          },
          {
            label: `Treatment Step - ${treatmentPlanPair.step.name}`,
            data: treatmentPlanPair.step,
          },
        ])
      ),
      reduce2DArray()
    );
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean
  ): Observable<IPatientTreatmentPlanProposalJobData[]> {
    const brand$ = PracticeMigration.brand$(migration);
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);
    const treatmentConfigurationMappings$ =
      this.customMappings.itemCodeToTreatment.getRecords$(translationMap);
    const defaultTreatmentConfiguration$ = brand$.pipe(
      switchMap((brand) =>
        getDoc$(TreatmentConfiguration.col(brand), ADA_CODE_TREATMENT_CONFIG_ID)
      )
    );
    const treatmentConfigurations$ = brand$.pipe(
      switchMap((brand) => TreatmentConfiguration.all$(brand))
    );

    return this.sourceEntities.patients
      .getRecords$(
        migration,
        500,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        withLatestFrom(
          staff$,
          defaultTreatmentConfiguration$,
          treatmentConfigurations$,
          sourceItemCodes$,
          treatmentConfigurationMappings$
        ),
        map(
          ([
            sourcePatients,
            staff,
            defaultTreatmentConfiguration,
            treatmentConfigurations,
            sourceItemCodes,
            treatmentConfigurationMappings,
          ]) =>
            sourcePatients.map((sourcePatient) => ({
              sourcePatient,
              staff,
              defaultTreatmentConfiguration,
              treatmentConfigurations,
              sourceItemCodes,
              treatmentConfigurationMappings,
            }))
        )
      );
  }

  getDestinationEntityRecordUid(
    data: IPatientTreatmentPlanProposalJobData
  ): string {
    return data.sourcePatient.record.uid;
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientTreatmentPlanProposalJobData
  ): Promise<
    | IPatientTreatmentPlanProposalMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      throw new Error(`Couldn't resolve patient`);
    }

    const practitionerMap = data.sourcePatient.data.data
      .patient_preferredproviderid
      ? data.staff.find((staffer) => {
          if (!staffer.sourceIdentifier) {
            return;
          }
          return (
            staffer.sourceIdentifier ===
            data.sourcePatient.data.data.patient_preferredproviderid?.toString()
          );
        })
      : undefined;

    // Default provider is used from custom mapping with id: 0
    const defaultPractitioner = data.staff.find(
      (staffer) => staffer.sourceIdentifier === '0'
    )?.destinationIdentifier;

    const practitioner = practitionerMap?.destinationIdentifier
      ? await getDoc(practitionerMap?.destinationIdentifier)
      : defaultPractitioner
        ? await getDoc(defaultPractitioner)
        : undefined;

    if (!practitioner) {
      throw new Error(`Couldn't resolve practitioner`);
    }

    const procedures =
      await this.sourceEntities.appointmentProcedures.filterRecords(
        migration,
        'patientId',
        data.sourcePatient.data.data.patient_id.toString()
      );

    const quotes: IQuoteData[] = [];

    procedures
      .filter((procedure) => procedure.record.filters.isPlanProposal)
      .map((procedure) => {
        const quoteId = procedure.data.data.quote_id?.toString();
        if (!quoteId) {
          return;
        }
        const quoteFound = quotes.find(
          (quote) => quote.id === quoteId.toString()
        );
        if (quoteFound) {
          if (
            quoteFound.date.seconds >
            procedure.data.translations.createdDate.seconds
          ) {
            quoteFound.date = procedure.data.translations.createdDate;
          }
          quoteFound.procedures.push(procedure);
          return;
        }
        quotes.push({
          id: quoteId,
          date: procedure.data.translations.createdDate,
          patientId: data.sourcePatient.data.data.patient_id.toString(),
          procedures: [procedure],
        });
      });

    const planPairs = await asyncForEach(compact(quotes), async (quote) => {
      return this._buildTreatmentPlanData(
        translationMap,
        data,
        quote,
        practitioner,
        migration
      );
    });

    return {
      planPairs,
      patientRef,
    };
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IPatientTreatmentPlanProposalMigrationData
  ): Promise<IPatientTreatmentPlanProposalMigrationData | undefined> {
    try {
      const mergeConflictPlanPairs = await asyncForEach(
        data.planPairs,
        async (planPair) => {
          const planRef = await translationMap.getDestination(
            planPair.sourceIdentifier,
            PATIENT_TREATMENT_PLAN_PROPOSAL_CUSTOM_MAPPING_TYPE
          );

          if (!planRef) {
            return;
          }

          const existingPlan = await Firestore.getDoc(
            planRef as DocumentReference<ITreatmentPlan>
          );

          const planMergeConflict = DestinationEntityRecord.hasMergeConflicts(
            planPair.plan,
            {
              ...existingPlan,
              steps: [],
            }
          );

          const existingSteps: ITreatmentStep[] = [];

          const stepMergeConflicts = await asyncForEach(
            [planPair.step],
            async (step) => {
              const stepRef = await translationMap.getDestination(
                planPair.sourceIdentifier,
                PATIENT_TREATMENT_STEP_PROPOSAL_CUSTOM_MAPPING_TYPE
              );

              if (!stepRef) {
                return false;
              }

              const existingStep = await Firestore.getDoc(
                stepRef as DocumentReference<ITreatmentStep>
              );
              existingSteps.push({
                ...omit(existingStep, 'ref'),
              });

              const stepMergeConflict =
                DestinationEntityRecord.hasMergeConflicts(
                  omit(step, ['sourceIdentifier']),
                  omit(existingStep, ['sourceIdentifier']),
                  ['chartedAt'],
                  [
                    {
                      key: 'chartedSurfaces',
                      sortByPath: [
                        'resolvedAt.seconds',
                        'chartedRef.wholeMouth',
                        'chartedRef.tooth.quadrant',
                        'chartedRef.tooth.quadrantIndex',
                        'chartedRef.tooth.surface',
                      ],
                    },
                    {
                      key: 'treatments',
                      sortByPath: [
                        'type',
                        'config.ref.id',
                        'scopeRef',
                        'price',
                        'attributedTo.ref.id',
                        'adaCodes[0].code.name',
                        'chartedSurfaces[0].resolvedAt.seconds',
                        'chartedSurfaces[0].chartedRef.wholeMouth',
                        'chartedSurfaces[0].chartedRef.tooth.quadrant',
                        'chartedSurfaces[0].chartedRef.tooth.quadrantIndex',
                        'chartedSurfaces[0].chartedRef.tooth.surface',
                      ],
                    },
                  ]
                );

              return stepMergeConflict;
            }
          );

          const hasIncorrectStepCount =
            existingPlan.steps.length !== existingSteps.length;

          const hasMergeConflict = [
            hasIncorrectStepCount,
            planMergeConflict,
            ...stepMergeConflicts,
          ].some((stepMergeConflict) => stepMergeConflict);

          if (hasMergeConflict) {
            return {
              ...planPair,
              plan: {
                ...omit(existingPlan, 'ref'),
                steps: [],
              },
              step: existingSteps[0] ?? undefined,
            };
          }
        }
      );

      if (compact(mergeConflictPlanPairs).length) {
        return {
          ...data,
          planPairs: compact(mergeConflictPlanPairs),
        };
      }
    } catch (error) {
      return;
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientTreatmentPlanProposalJobData,
    _migrationData: IPatientTreatmentPlanProposalMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    jobData: IPatientTreatmentPlanProposalJobData,
    migrationData: IPatientTreatmentPlanProposalMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const planPairs = await asyncForEach(
        migrationData.planPairs,
        (planPair) =>
          this._upsertTreatmentPlan(
            planPair,
            translationMapHandler,
            migrationData.patientRef
          )
      );

      return this._buildSuccessResponse(jobData.sourcePatient, planPairs);
    } catch (error) {
      return this._buildErrorResponse(jobData.sourcePatient, getError(error));
    }
  }

  private _buildSuccessResponse(
    patient: IGetRecordResponse<IPraktikaPatient, IPraktikaPatientTranslations>,
    planStepPairs: ITreatmentPlanStepDocPair[]
  ): IDestinationEntityRecord<ITreatmentPlanProposalSuccessData> {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      data: {
        sourceRef: patient.record.ref,
        planStepPairs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  private async _upsertTreatmentPlan(
    pair: ITreatmentPlanStepPair,
    translationMap: TranslationMapHandler,
    patientRef: DocumentReference<IPatient>
  ): Promise<ITreatmentPlanStepDocPair> {
    const planDestinationRef = await translationMap.getDestination(
      pair.sourceIdentifier,
      PATIENT_TREATMENT_PLAN_PROPOSAL_CUSTOM_MAPPING_TYPE
    );

    const planRef = await FirestoreMigrate.upsertDoc(
      TreatmentPlan.col({
        ref: patientRef,
      }),
      { ...pair.plan, createdAt: pair.createdAt },
      planDestinationRef?.id
    );

    if (!planDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: pair.sourceIdentifier,
        destinationIdentifier: planRef,
        resourceType: PATIENT_TREATMENT_PLAN_PROPOSAL_CUSTOM_MAPPING_TYPE,
      });
    }

    const stepDestinationRef = await translationMap.getDestination(
      pair.sourceIdentifier,
      PATIENT_TREATMENT_STEP_PROPOSAL_CUSTOM_MAPPING_TYPE
    );

    const stepRef = await FirestoreMigrate.upsertDoc(
      TreatmentPlan.treatmentStepCol({
        ref: planRef,
      }),
      { ...pair.step, createdAt: pair.createdAt },
      stepDestinationRef?.id
    );

    if (!stepDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: pair.sourceIdentifier,
        destinationIdentifier: stepRef,
        resourceType: PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
      });
    }

    await FirestoreMigrate.patchDoc(planRef, {
      steps: [stepRef],
    });

    return { planRef, stepRef };
  }

  private async _buildTreatmentPlanData(
    translationMap: TranslationMapHandler,
    data: IPatientTreatmentPlanProposalJobData,
    quote: IQuoteData,
    practitioner: WithRef<IStaffer>,
    migration: WithRef<IPracticeMigration>
  ): Promise<ITreatmentPlanStepPair> {
    const createdAt = toMomentTz(quote.date, migration.configuration.timezone);
    const formattedDate = createdAt.format(HISTORY_DATE_FORMAT);
    const planName = `Quote ${quote.id} - ${formattedDate}`;
    const status = createdAt.isBefore(moment().subtract(6, 'months'))
      ? TreatmentPlanStatus.Declined
      : TreatmentPlanStatus.Offered;
    const plan = TreatmentPlan.init({
      name: planName,
      status,
    });

    const defaultFeeSchedule = await FeeSchedule.getPreferredOrDefault(
      migration.configuration.organisation
    );
    const treatments = await getChartedTreatments(
      quote.procedures.map((procedure) => procedure.data.data),
      practitioner,
      resolveFeeSchedule(translationMap, defaultFeeSchedule),
      data,
      migration.configuration.timezone
    );

    const step = TreatmentStep.init({
      name: 'Treatments',
      status: TreatmentStepStatus.Incomplete,
      treatments,
    });

    return {
      createdAt: quote.date,
      sourceIdentifier: quote.id,
      plan,
      step,
    };
  }

  private _buildErrorResponse(
    patient: IGetRecordResponse<IPraktikaPatient, IPraktikaPatientTranslations>,
    errorMessage?: string
  ): IDestinationEntityRecord<ITreatmentPlanProposalSuccessData> {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage:
        errorMessage ??
        'Missing required properties for treatment plan proposal',
      failData: {
        sourceRef: patient.record.ref,
      },
    };
  }
}
