import { Inject, Injectable, Optional } from '@angular/core';
import {
  httpsCallable,
  serialise,
  snapshot,
  type IAddLogRequest,
} from '@principle-theorem/shared';
import {
  LOGGER_METADATA_RESOLVER,
  type ILoggerMetadataResolver,
} from './logger-metadata-resolver';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(
    @Optional()
    @Inject(LOGGER_METADATA_RESOLVER)
    private _metadataResolver: ILoggerMetadataResolver
  ) {}

  async log(
    severity: IAddLogRequest['severity'],
    message: string,
    payload: object
  ): Promise<void> {
    const metadata = await this._getMetadata();
    const jsonPayload = {
      payload,
      ...metadata,
    };

    try {
      await snapshot(
        httpsCallable<IAddLogRequest, void>('http-logging-addLog')(
          serialise({ severity, message, jsonPayload })
        )
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to log error', error);
    }
  }

  private async _getMetadata(): Promise<object> {
    if (!this._metadataResolver) {
      // eslint-disable-next-line no-console
      console.warn(`Could not resolve metadata for log`);
      return {};
    }
    return this._metadataResolver.resolveMetadata();
  }
}
