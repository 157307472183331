<mat-icon
  aria-hidden="false"
  [matBadge]="badge"
  [matBadgeHidden]="expanded || !badge"
  [matBadgeColor]="badgeColor"
  [matTooltip]="label"
  [matTooltipDisabled]="expanded"
>
  {{ icon }}
</mat-icon>

@if (expanded) {
  <div class="flex flex-row items-start justify-start">
    <h4>{{ label }}</h4>
    <small *ngIf="isDev" matTooltip="This feature is dev status">Dev</small>
    <small *ngIf="isAlpha" matTooltip="This feature is alpha status">
      Alpha
    </small>
  </div>
  <span
    class="trailing-badge flex grow"
    matBadgeSize="medium"
    [matBadge]="badge$ | async"
    [matBadgeHidden]="(badge$ | async) === 0"
    [matBadgeColor]="badgeColor"
  >
  </span>
}
