<div
  class="flex flex-col items-center gap-2 rounded-lg border-l-4 bg-blue-100 p-2 text-center sm:flex-row sm:gap-4 sm:p-4 sm:text-left"
>
  <div class="flex items-center justify-center">
    <mat-icon color="primary">info</mat-icon>
  </div>
  <p class="!m-0 flex flex-auto flex-col">
    <ng-content />
  </p>
  <ng-content select="button" />
</div>
