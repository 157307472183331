import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { ISterilisationPackContent } from '@principle-theorem/principle-core/interfaces';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { MatDialog } from '@angular/material/dialog';
import {
  IPackContentsDialogData,
  PackContentsDialogComponent,
} from '../pack-contents-dialog/pack-contents-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LetDirective } from '@ngrx/component';

@Component({
  selector: 'pr-pack-contents-editor',
  standalone: true,
  imports: [CommonModule, NgMaterialModule, LetDirective],
  templateUrl: './pack-contents-editor.component.html',
  styleUrl: './pack-contents-editor.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackContentsEditorComponent {
  private _dialog = inject(MatDialog);
  private _snackBar = inject(MatSnackBar);
  content$ = new BehaviorSubject<ISterilisationPackContent[]>([]);

  @Input()
  set content(content: ISterilisationPackContent[]) {
    if (content) {
      this.content$.next(content);
    }
  }

  @Output() contentChange = new EventEmitter<ISterilisationPackContent[]>();

  async editPackContent(): Promise<void> {
    const updatedContent = await this._dialog
      .open<
        PackContentsDialogComponent,
        IPackContentsDialogData,
        ISterilisationPackContent[] | undefined
      >(
        PackContentsDialogComponent,
        DialogPresets.flex({ data: { content: this.content$.value } })
      )
      .afterClosed()
      .toPromise();

    if (!updatedContent) {
      return;
    }
    this.contentChange.emit(updatedContent);
    this._snackBar.open('Pack contents updated');
  }
}
