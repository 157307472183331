import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  HISTORY_DATE_FORMAT,
  type ISODateType,
} from '@principle-theorem/shared';

@Component({
    selector: 'pr-patient-date-of-birth',
    templateUrl: './patient-date-of-birth.component.html',
    styleUrls: ['./patient-date-of-birth.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientDateOfBirthComponent {
  readonly dateFormat = HISTORY_DATE_FORMAT;
  @Input() dateOfBirth: ISODateType | undefined;
}
