import { NgModule } from '@angular/core';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { IntegrationDisplayComponent } from './integration-display/integration-display.component';
import { SidexisModule } from './sidexis/sidexis.module';
import { EzdentModule } from './ezdent/ezdent.module';
import { TwainModule } from './twain/twain.module';
import { RomexisModule } from './romexis/romexis.module';
import { VixWinModule } from './vixwin/vixwin.module';
import { CliniviewModule } from './cliniview/cliniview.module';
import { IDixelModule } from './idixel/idixel.module';
import { VistaSoftModule } from './vistasoft/vistasoft.module';

@NgModule({
  declarations: [IntegrationDisplayComponent],
  imports: [
    SidexisModule,
    NgSharedModule,
    EzdentModule,
    TwainModule,
    RomexisModule,
    VixWinModule,
    CliniviewModule,
    IDixelModule,
    VistaSoftModule,
  ],
  exports: [
    SidexisModule,
    IntegrationDisplayComponent,
    EzdentModule,
    TwainModule,
    RomexisModule,
    VixWinModule,
    CliniviewModule,
    IDixelModule,
    VistaSoftModule,
  ],
})
export class IntegrationsModule {}
