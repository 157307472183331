<div
  *ngIf="(bloc.isDeleted$ | async) === false"
  fxLayout="row"
  fxLayoutAlign="end"
  fxLayoutGap="16px"
>
  <button
    *ngIf="bloc.hasRelatedAppointments$ | ngrxPush"
    mat-stroked-button
    (click)="bloc.openRelatedAppointments()"
  >
    Related Appointments
  </button>

  <span fxFlex></span>

  <button
    mat-stroked-button
    *ngIf="bloc.hasAmendmentHistory$ | ngrxPush"
    (click)="bloc.openAmendmentHistory()"
    ptElementTarget="invoice-amendment-history-button"
  >
    Amendment History
  </button>

  <button
    mat-stroked-button
    [matMenuTriggerFor]="moreMenu"
    ptElementTarget="invoice-actions-button"
  >
    Invoice Actions
    <mat-icon>expand_more</mat-icon>
  </button>
  <mat-menu #moreMenu="matMenu" ptElementTarget="invoice-actions-button-menu">
    <button
      mat-menu-item
      *ngIf="bloc.canIssueInvoice$ | async"
      (click)="bloc.issue()"
      ptElementTarget="issue-invoice-button"
    >
      <mat-icon>receipt</mat-icon>
      <span>Issue</span>
    </button>
    <button
      mat-menu-item
      *ngIf="bloc.canAmendInvoice$ | async"
      (click)="bloc.amend()"
      matTooltip="Revert invoice back to draft and make changes"
      ptElementTarget="amend-invoice-button"
    >
      <mat-icon>undo</mat-icon>
      <span>Amend Invoice</span>
    </button>

    <button
      mat-menu-item
      *ngIf="bloc.canBalanceOverpaymentWithDeposit$ | ngrxPush"
      (click)="bloc.balanceOverpaymentWithDeposit()"
      ptElementTarget="balance-overpayment-with-deposit-button"
      matTooltip="Balance Invoice by converting overpayments into account credit"
    >
      <mat-icon>balance</mat-icon>
      <span>Balance Invoice</span>
    </button>

    <div
      *ngIf="bloc.canCancelInvoice$ | async"
      [matTooltip]="cancelMessage$ | async"
    >
      <button
        mat-menu-item
        color="warn"
        [disabled]="bloc.requiresRefundsToCancel$ | async"
        (click)="bloc.cancel()"
        ptElementTarget="cancel-invoice-button"
      >
        <mat-icon
          [ngClass]="{
            'icon-colour': (bloc.requiresRefundsToCancel$ | async) === false
          }"
        >
          cancel
        </mat-icon>
        <span>Cancel</span>
      </button>
    </div>
    <button
      mat-menu-item
      *ngIf="bloc.canWriteOffInvoice$ | async"
      (click)="bloc.writeOff()"
      ptElementTarget="write-off-invoice-button"
    >
      <mat-icon color="warn">credit_card_off</mat-icon>
      <span>Write Off</span>
    </button>
    <button
      mat-menu-item
      *ngIf="bloc.canReplaceInvoice$ | async"
      (click)="bloc.replaceInvoice()"
      matTooltip="A new draft invoice will be created as replacement"
      ptElementTarget="replace-invoice-button"
    >
      <mat-icon>published_with_changes</mat-icon>
      <span>Replace</span>
    </button>
    <button
      mat-menu-item
      *ngIf="bloc.canRevertToIssued$ | async"
      (click)="bloc.revertToIssued()"
      matTooltip="This invoice will be marked as issued"
      ptElementTarget="revert-to-issued-invoice-button"
    >
      <mat-icon>undo</mat-icon>
      <span>Revert to Issued</span>
    </button>
    <div *ngIf="bloc.canDelete$ | async" [matTooltip]="cancelMessage$ | async">
      <button
        mat-menu-item
        *ngIf="bloc.canDelete$ | async"
        (click)="bloc.delete()"
        [disabled]="bloc.requiresRefundsToCancel$ | async"
        ptElementTarget="delete-invoice-button"
      >
        <mat-icon
          [ngClass]="{
            'icon-colour': (bloc.requiresRefundsToCancel$ | async) === false
          }"
        >
          delete
        </mat-icon>
        <span>Delete</span>
      </button>
    </div>

    <mat-divider />
    <button mat-menu-item (click)="bloc.sendAsSMS()">
      <mat-icon>sms</mat-icon>
      <span>Send as SMS</span>
    </button>
    <button mat-menu-item (click)="bloc.sendAsEmail()">
      <mat-icon>email</mat-icon>
      <span>Send as Email</span>
    </button>

    <mat-divider />
    <button
      matTooltip="Print Invoice"
      mat-menu-item
      (click)="bloc.print()"
      ptElementTarget="print-invoice-button"
    >
      <mat-icon>print</mat-icon>
      <span>Print</span>
    </button>
  </mat-menu>
</div>
