<ng-container *ngIf="gapStore.searchFilters$ | async as filters">
  <mat-chip-listbox [selectable]="false">
    @if (dateRangeDisplay$ | async; as dateRange) {
      <mat-chip-option (removed)="removeDateRangeFilter()">
        {{ dateRange }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
    }

    @if (filters.practitioners; as practitioners) {
      @if (practitioners.length) {
        <mat-chip-option
          *ngFor="let practitioner of practitioners; trackBy: trackByStaff"
          (removed)="removePractitionerFilter(filters, practitioner)"
        >
          {{ practitioner.user.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      }
    }

    @if (filters.timeRange; as timeRange) {
      @if (timeRange.from; as from) {
        <mat-chip-option
          (removed)="
            removeFilter({ timeRange: { from: undefined, to: timeRange.to } })
          "
        >
          From: {{ from }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      }
      @if (timeRange.to; as to) {
        <mat-chip-option
          (removed)="
            removeFilter({ timeRange: { from: timeRange.from, to: undefined } })
          "
        >
          To: {{ to }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      }
    }

    @if (filters.duration; as duration) {
      @if (duration.hours; as hours) {
        <mat-chip-option
          (removed)="
            removeFilter({
              duration: { hours: undefined, minutes: duration.minutes }
            })
          "
        >
          Hours: {{ hours }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      }

      @if (duration.minutes; as minutes) {
        <mat-chip-option
          (removed)="
            removeFilter({
              duration: { hours: duration.hours, minutes: undefined }
            })
          "
        >
          Minutes: {{ minutes }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      }
    }

    @if (gapStore.hasActiveFilters$ | async) {
      <mat-chip-option
        class="!bg-primary-300"
        [ngStyle]="{ '--mdc-chip-label-text-color': 'white' }"
        (removed)="gapStore.clearFilters()"
      >
        Clear All
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
    }
  </mat-chip-listbox>
</ng-container>
