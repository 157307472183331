import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { type MatCheckboxChange } from '@angular/material/checkbox';
import { MatSort } from '@angular/material/sort';
import { type RawInlineNodes, toTextContent } from '@principle-theorem/editor';
import {
  extendSortingDataAccessor,
  ObservableDataSource,
  timestampSortingAccessor,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  AUTOMATION_TYPES_LABEL_MAP,
  type AutomationType,
  isGeneratedTask,
} from '@principle-theorem/principle-core/interfaces';
import { DATE_TIME_FORMAT, isWithRef } from '@principle-theorem/shared';
import { ReplaySubject, Subject } from 'rxjs';
import {
  type AutomationEntity,
  type IAutomationListData,
} from '@principle-theorem/principle-core';

export type AutomationLayoutMode = 'table' | 'list';

@Component({
    selector: 'pr-automation-list-display',
    templateUrl: './automation-list-display.component.html',
    styleUrls: ['./automation-list-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AutomationListDisplayComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByData =
    TrackByFunctions.nestedField<IAutomationListData>('automation.uid');
  listData$: ReplaySubject<IAutomationListData[]> = new ReplaySubject(1);
  @Output() selectAutomation = new EventEmitter<string>();
  @Output()
  unselectAutomation = new EventEmitter<string>();
  @Output()
  editAutomation = new EventEmitter<AutomationEntity>();
  @Output()
  cancelAutomation = new EventEmitter<AutomationEntity>();
  @Output()
  restoreAutomation = new EventEmitter<AutomationEntity>();
  @Output()
  resendAutomation = new EventEmitter<AutomationEntity>();
  @Input() multiSelect = false;
  @Input() layout: AutomationLayoutMode = 'list';
  readonly dateFormat = DATE_TIME_FORMAT;
  dataSource: ObservableDataSource<IAutomationListData>;
  displayedColumns: string[] = [
    'select',
    'icon',
    'title',
    'type',
    'createdBy',
    'patient',
    'appointment',
    'triggerDate',
    'status',
    'more',
  ];

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor() {
    this.dataSource = new ObservableDataSource(this.listData$);
    this.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, sortHeaderId) => this._sortingDataAccessor(data, sortHeaderId)
    );
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @Input()
  set automations(automations: IAutomationListData[]) {
    if (automations) {
      this.listData$.next(automations);
    }
  }

  toggle(change: MatCheckboxChange, automation: AutomationEntity): void {
    if (change.checked) {
      return this.selectAutomation.emit(automation.uid);
    }
    this.unselectAutomation.emit(automation.uid);
  }

  getTitle(automation: AutomationEntity): RawInlineNodes {
    return isGeneratedTask(automation.data)
      ? automation.data.title
      : [toTextContent(automation.data.name)];
  }

  getTypeLabel(type: AutomationType): string {
    return AUTOMATION_TYPES_LABEL_MAP[type];
  }

  private _sortingDataAccessor(
    data: IAutomationListData,
    sortHeaderId: string
  ): string | number | undefined {
    switch (sortHeaderId) {
      case 'status':
        return data.automation.status;
      case 'type':
        return data.automation.type;
      case 'triggerDate':
        return timestampSortingAccessor(data.automation.triggerDate);
      case 'createdAt':
        return isWithRef(data)
          ? timestampSortingAccessor(data.automation.createdAt)
          : undefined;
      default:
        return;
    }
  }
}
