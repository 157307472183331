import {
  getMentions,
  getMixedContent,
  getSchemaSize,
  initVersionedSchema,
  mergeSchemas,
  MixedSchema,
  RawInlineNodes,
} from '@principle-theorem/editor';
import {
  IInteraction,
  IPrincipleMention,
  IWorkflowItem,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import { TIME_FORMAT, toMoment } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Interaction } from '../interaction/interaction';

export function getWorkflowItemMentions(model: {
  title: RawInlineNodes;
  description?: MixedSchema;
}): IPrincipleMention[] {
  return [
    ...getMentions<MentionResourceType>(model.title),
    ...(model.description
      ? getMentions<MentionResourceType>(model.description)
      : []),
  ];
}

export class WorkflowItem {
  static dueEndOfDay(item: IWorkflowItem): boolean {
    if (!item.dueDate) {
      return false;
    }
    // TODO: Build with timezone - CU-251edxw
    const endOfDay: moment.Moment = moment().endOf('day');
    return (
      toMoment(item.dueDate).format(TIME_FORMAT) ===
      endOfDay.format(TIME_FORMAT)
    );
  }

  static hasDueTime(item: Pick<IWorkflowItem, 'dueDate'>): boolean {
    if (!item.dueDate) {
      return false;
    }
    // TODO: Build with timezone - CU-251edxw
    const endOfDay: moment.Moment = moment().endOf('day');
    return (
      toMoment(item.dueDate).format(TIME_FORMAT) !==
      endOfDay.format(TIME_FORMAT)
    );
  }

  static addInteractions(
    item: IWorkflowItem,
    data: Partial<IInteraction>[]
  ): void {
    data.forEach((interaction: Partial<IInteraction>) => {
      item.interactions.push(Interaction.init(interaction));
    });
  }

  static lastInteraction(item: IWorkflowItem): IInteraction | undefined {
    const interactionLength: number = item.interactions.length;
    if (!interactionLength) {
      return;
    }
    return item.interactions[interactionLength - 1];
  }

  static lastInteractionSummary(item: IWorkflowItem): MixedSchema | undefined {
    const lastInteraction = WorkflowItem.lastInteraction(item);
    if (!lastInteraction) {
      return;
    }
    return WorkflowItem.summariseInteraction(lastInteraction);
  }

  static summariseInteraction(interaction: IInteraction): MixedSchema {
    const notes = getSchemaSize(interaction.content)
      ? interaction.content
      : initVersionedSchema(interaction.title);
    return interaction.owner
      ? notes
      : initVersionedSchema(
          mergeSchemas([
            initVersionedSchema('System - '),
            getMixedContent(notes),
          ])
        );
  }
}
