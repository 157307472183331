import {
  Appointment as Patient,
  TreatmentPlan,
  hasMergeConflicts,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  IAppointment,
  IAssociatedTreatment,
  IBrand,
  IDestinationEntity,
  IDestinationEntityRecord,
  IGetRecordResponse,
  IInteractionV2,
  IMigratedDataSummary,
  IPatient,
  IPracticeMigration,
  ISourceEntityHandler,
  ISourceEntityRecord,
  IStaffer,
  ITranslationMap,
  ITreatmentPlan,
  ITreatmentStep,
  MergeConflictDestinationEntityRecord,
  SkippedDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  Timestamp,
  WithRef,
  asDocRef,
  resolveSequentially,
  asyncForEach,
  firstResult,
  getError,
  isObject,
  safeCombineLatest,
  toTimestamp,
  where,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { isString, omit } from 'lodash';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationMapHandler } from '../../translation-map';
import { BaseDestinationEntity } from '../base-destination-entity';
import { DestinationEntity } from '../destination-entity';
import {
  PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
  PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
} from './patient-treatment-plans';

export const PATIENT_APPOINTMENT_RESOURCE_TYPE = 'patientAppointment';

export const PATIENT_APPOINTMENT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_APPOINTMENT_RESOURCE_TYPE,
    label: 'Patient Appointments',
    description: '',
  },
});

export interface IAppointmentSuccessData {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  patientRef: DocumentReference<IPatient>;
  appointmentRefs: DocumentReference<IAppointment>[];
}

export interface IPatientAppointmentJobData<
  PatientRecord extends object,
  PatientTranslations extends object,
> {
  sourcePatient: IGetRecordResponse<PatientRecord, PatientTranslations>;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  practitioners: WithRef<IStaffer>[];
  brand: WithRef<IBrand>;
}

export interface IPatientAppointmentBuildData {
  appointmentUid: string;
  appointment: Omit<IAppointment, 'treatmentPlan'> &
    Partial<Pick<IAppointment, 'treatmentPlan'>>;
  createdAt?: Timestamp;
  planUid: string;
  stepUid: string;
  plan: ITreatmentPlan;
  step: ITreatmentStep;
  interactions: IInteractionV2[];
}

export interface IPatientAppointmentMigrationData {
  patientRef: DocumentReference<IPatient>;
  sourcePatientId: string;
  appointments: IPatientAppointmentBuildData[];
}

export abstract class BasePatientAppointmentDestinationEntity<
  PatientRecord extends object,
  AppointmentTranslations extends object,
  JobData extends IPatientAppointmentJobData<
    PatientRecord,
    AppointmentTranslations
  >,
> extends BaseDestinationEntity<
  IAppointmentSuccessData,
  JobData,
  IPatientAppointmentMigrationData
> {
  abstract patientSourceEntity: ISourceEntityHandler<Patient[]>;
  abstract treatmentPlanName: string;

  get sourceCountComparison(): ISourceEntityHandler<Patient[]> {
    return this.patientSourceEntity;
  }

  sourceCountDataAccessor(
    data: JobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getDestinationEntityRecordUid(data: JobData): string {
    return data.sourcePatient.record.uid;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IAppointmentSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      Firestore.getDoc(record.data.sourceRef),
      Firestore.getDoc(record.data.patientRef),
      safeCombineLatest(
        record.data.appointmentRefs.map((appointmentRef) =>
          Firestore.getDoc(appointmentRef)
        )
      ),
    ]).pipe(
      map(([sourcePatient, patient, appointments]) => {
        const data: IMigratedDataSummary[] = [
          {
            label: 'Source Patient',
            data: sourcePatient,
          },
          {
            label: 'Patient',
            data: patient,
          },
        ];

        data.push(
          ...appointments.map((appointment) => ({
            label: 'Appointments',
            data: appointment,
          }))
        );

        return data;
      })
    );
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IPatientAppointmentMigrationData
  ): Promise<IPatientAppointmentMigrationData | undefined> {
    const existingAppointments: IPatientAppointmentBuildData[] = [];

    const appointmentMergeConflicts = await asyncForEach(
      data.appointments,
      async (appointment) => {
        const appointmentRef = await translationMap.getDestination(
          appointment.appointmentUid,
          PATIENT_APPOINTMENT_RESOURCE_TYPE
        );

        try {
          const existingAppointment = appointmentRef
            ? await Firestore.getDoc(asDocRef<IAppointment>(appointmentRef))
            : undefined;

          if (!existingAppointment) {
            return;
          }

          const builtData: IPatientAppointmentBuildData = {
            ...appointment,
            appointment: {
              ...existingAppointment,
            },
          };

          existingAppointments.push(builtData);

          return hasMergeConflicts(
            appointment.appointment,
            omit(existingAppointment, 'treatmentPlan'),
            ['dateFrom', 'dateTo'],
            [
              {
                key: 'tags',
                typeGuardFn: (item): item is unknown =>
                  isObject(item) && isString(item.name),
                sortByPath: 'name',
              },
            ]
          );
        } catch (error) {
          return false;
        }
      }
    );

    if (appointmentMergeConflicts.some((mergeConflict) => mergeConflict)) {
      return {
        ...data,
        appointments: existingAppointments,
      };
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: JobData,
    _migrationData: IPatientAppointmentMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: JobData,
    migrationData: IPatientAppointmentMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const appointmentRefs = await resolveSequentially(
        migrationData.appointments,
        async (appointmentData) => {
          const appointmentRef = await this._upsertAppointment(
            migrationData.patientRef,
            appointmentData,
            translationMap
          );

          await FirestoreMigrate.upsertBulk(
            Patient.interactionCol({ ref: appointmentRef }),
            appointmentData.interactions,
            'uid'
          );

          return appointmentRef;
        }
      );

      return this._buildSuccessResponse(
        jobData.sourcePatient,
        migrationData.patientRef,
        appointmentRefs
      );
    } catch (error) {
      return this._buildErrorResponse(jobData.sourcePatient, getError(error));
    }
  }

  protected _buildSuccessResponse(
    patient: IGetRecordResponse<PatientRecord>,
    patientRef: DocumentReference<IPatient>,
    appointmentRefs: DocumentReference<IAppointment>[]
  ): IDestinationEntityRecord<IAppointmentSuccessData> {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      data: {
        sourceRef: patient.record.ref,
        patientRef,
        appointmentRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: patient.record.ref,
      migratedAt: toTimestamp(),
    };
  }

  protected _buildErrorResponse(
    patient: IGetRecordResponse<PatientRecord>,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: patient.record.ref,
      errorMessage: errorMessage ?? 'Missing required properties for patient',
      failData: {
        patientRef: patient.record.ref,
      },
    };
  }

  protected _buildSkippedResponse(
    patient: IGetRecordResponse<PatientRecord>
  ): IDestinationEntityRecord & SkippedDestinationEntityRecord {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      status: DestinationEntityRecordStatus.Skipped,
      sourceRef: patient.record.ref,
    };
  }

  private async _upsertAppointment(
    patientRef: DocumentReference<IPatient>,
    data: IPatientAppointmentBuildData,
    translationMap: TranslationMapHandler
  ): Promise<DocumentReference<IAppointment>> {
    const patientAppointmentDestinationRef =
      await translationMap.getDestination<IAppointment>(
        data.appointmentUid,
        PATIENT_APPOINTMENT_RESOURCE_TYPE
      );

    const appointment: IAppointment = {
      ...data.appointment,
      treatmentPlan: await this._upsertAppointmentPlanStepPair(
        translationMap,
        patientRef,
        data
      ),
      createdAt: data.createdAt ?? toTimestamp(),
      deleted: false,
    };

    const appointmentRef = await FirestoreMigrate.upsertDoc(
      Patient.col({
        ref: patientRef,
      }),
      appointment,
      patientAppointmentDestinationRef
    );

    if (!patientAppointmentDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: data.appointmentUid,
        destinationIdentifier: appointmentRef,
        resourceType: PATIENT_APPOINTMENT_RESOURCE_TYPE,
      });
    }

    await FirestoreMigrate.patchDoc(
      appointment.treatmentPlan.treatmentStep.ref,
      {
        appointment: appointmentRef,
      },
      undefined
    );
    return appointmentRef;
  }

  private async _upsertAppointmentPlanStepPair(
    translationMap: TranslationMapHandler,
    patientRef: DocumentReference<IPatient>,
    data: IPatientAppointmentBuildData
  ): Promise<IAssociatedTreatment> {
    if (data.appointment.treatmentPlan) {
      await translationMap.upsert({
        sourceIdentifier: `appointment-${data.appointmentUid}`,
        destinationIdentifier: data.appointment.treatmentPlan.ref,
        resourceType: PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
      });

      await translationMap.upsert({
        sourceIdentifier: `appointment-${data.appointmentUid}`,
        destinationIdentifier: data.appointment.treatmentPlan.treatmentStep.ref,
        resourceType: PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
      });

      return data.appointment.treatmentPlan;
    }

    let planDestinationRef =
      await translationMap.getDestination<ITreatmentPlan>(
        `appointment-${data.appointmentUid}`,
        PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE
      );

    const stepDestinationRef =
      await translationMap.getDestination<ITreatmentStep>(
        `appointment-${data.appointmentUid}`,
        PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
      );

    if (!planDestinationRef) {
      const existingPlan = await firstResult(
        TreatmentPlan.col({
          ref: patientRef,
        }),
        where('name', '==', data.plan.name)
      );

      if (existingPlan) {
        planDestinationRef = existingPlan.ref;
        await translationMap.upsert({
          sourceIdentifier: `appointment-${data.appointmentUid}`,
          destinationIdentifier: existingPlan.ref,
          resourceType: PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
        });
      }
    }

    const planRef = await FirestoreMigrate.upsertDoc(
      TreatmentPlan.col({
        ref: patientRef,
      }),
      { ...data.plan, deleted: false },
      planDestinationRef
    );

    if (!planDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: `appointment-${data.appointmentUid}`,
        destinationIdentifier: planRef,
        resourceType: PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
      });
    }

    const stepRef = await FirestoreMigrate.upsertDoc<ITreatmentStep>(
      TreatmentPlan.treatmentStepCol({
        ref: planRef,
      }),
      { ...data.step, deleted: false },
      stepDestinationRef
    );

    if (!stepDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: `appointment-${data.appointmentUid}`,
        destinationIdentifier: stepRef,
        resourceType: PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
      });
    }

    return TreatmentPlan.treatmentStepToAssociatedTreatment(
      { ...data.plan, ref: planRef },
      { ...data.step, ref: stepRef }
    );
  }
}
