import { ComponentStore } from '@ngrx/component-store';
import { ITimePeriod, Timestamp, toTimestamp } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';

export interface ISterilisationTableFiltersState {
  from: Timestamp;
  to: Timestamp;
  searchValue: string;
  hideAllocated: boolean;
  hideWithCycle: boolean;
}

const initialState: ISterilisationTableFiltersState = {
  from: toTimestamp(moment().subtract(7, 'days').startOf('day')),
  to: toTimestamp(moment().endOf('day')),
  searchValue: '',
  hideAllocated: false,
  hideWithCycle: false,
};

export class SterilisationTableFilterStore extends ComponentStore<ISterilisationTableFiltersState> {
  readonly from$ = this.select((state) => state.from);
  readonly to$ = this.select((state) => state.to);
  readonly searchValue$ = this.select((state) => state.searchValue);
  readonly hideAllocated$ = this.select((state) => state.hideAllocated);
  readonly hideWithCycle$ = this.select((state) => state.hideWithCycle);

  readonly updateRange = this.updater((state, range: ITimePeriod) => ({
    ...state,
    from: toTimestamp(range.from.startOf('day')),
    to: toTimestamp(range.to.endOf('day')),
  }));

  readonly updateSearchValue = this.updater((state, searchValue: string) => ({
    ...state,
    searchValue,
  }));

  readonly toggleHideAllocated = this.updater((state) => ({
    ...state,
    hideAllocated: !state.hideAllocated,
  }));

  readonly toggleHideWithCycle = this.updater((state) => ({
    ...state,
    hideWithCycle: !state.hideWithCycle,
  }));

  constructor() {
    super(initialState);
  }
}
