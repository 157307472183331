import { Injectable } from '@angular/core';
import { ScheduleSummary } from '@principle-theorem/principle-core';
import {
  AppointmentSummary,
  CalendarEventSummary,
  IAppointment,
  ICalendarEvent,
  IEventable,
  IScheduleSummaryEventable,
  isAppointmentEventType,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, snapshot } from '@principle-theorem/shared';
import { BehaviorSubject } from 'rxjs';

export enum SummaryEventActionType {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

@Injectable({
  providedIn: 'root',
})
export class ScheduleSummaryEventActionsService {
  private _isUserUpdatingEvent$ = new BehaviorSubject<boolean>(false);

  private _selectedCalendarEventSummary$ = new BehaviorSubject<
    CalendarEventSummary | undefined
  >(undefined);

  private _selectedAppointmentSummary$ = new BehaviorSubject<
    AppointmentSummary | undefined
  >(undefined);

  private _createCalendarSummary$ = new BehaviorSubject<
    CalendarEventSummary | undefined
  >(undefined);

  private _deleteCalendarSummary$ = new BehaviorSubject<
    CalendarEventSummary | undefined
  >(undefined);

  private _updateCalendarSummary$ = new BehaviorSubject<{
    oldEvent: CalendarEventSummary | undefined;
    newEvent: CalendarEventSummary | undefined;
  }>({ oldEvent: undefined, newEvent: undefined });

  private _updateAppointmentSummary$ = new BehaviorSubject<{
    oldAppointment: AppointmentSummary | undefined;
    newAppointment: AppointmentSummary | undefined;
  }>({ oldAppointment: undefined, newAppointment: undefined });

  selectedAppointmentSummary$ =
    this._selectedAppointmentSummary$.asObservable();
  isUserUpdatingEvent$ = this._isUserUpdatingEvent$.asObservable();
  addCalendarSummary$ = this._createCalendarSummary$.asObservable();
  removeCalendarSummary$ = this._deleteCalendarSummary$.asObservable();
  updateCalendarSummary$ = this._updateCalendarSummary$.asObservable();
  updateAppointmentSummary$ = this._updateAppointmentSummary$.asObservable();

  setUserUpdatingEvent(isActive: boolean): void {
    this._isUserUpdatingEvent$.next(isActive);
  }

  setSelectedSummary(selectedSummary?: IScheduleSummaryEventable): void {
    if (!selectedSummary) {
      this._clearSelectedSummaries();
      return;
    }

    if (isAppointmentEventType(selectedSummary.event.type)) {
      const appointmentSummary = selectedSummary as AppointmentSummary;
      this._selectedAppointmentSummary$.next(appointmentSummary);
      return;
    }

    const calendarSummary = selectedSummary as CalendarEventSummary;
    this._selectedCalendarEventSummary$.next(calendarSummary);
  }

  async setCalendarEvent(
    action: SummaryEventActionType,
    updatedCalendarEvent?: WithRef<ICalendarEvent>
  ): Promise<void> {
    const currentSummary = await snapshot(this._selectedCalendarEventSummary$);
    const newSummary = await ScheduleSummary.getSummaryFromEventable(
      updatedCalendarEvent,
      updatedCalendarEvent?.isBlocking
    );

    switch (action) {
      case SummaryEventActionType.Create:
        this._createCalendarSummary$.next(newSummary);
        break;
      case SummaryEventActionType.Delete:
        this._deleteCalendarSummary$.next(currentSummary);
        break;
      case SummaryEventActionType.Update:
        if (!newSummary) {
          return;
        }
        this._updateCalendarSummary$.next({
          oldEvent: currentSummary,
          newEvent: newSummary,
        });
        break;
      default:
        break;
    }
  }

  async updateAppointmentSummary(
    oldAppointment: WithRef<IAppointment>,
    newAppointment: WithRef<IAppointment>
  ): Promise<void> {
    if (!oldAppointment.event || !newAppointment.event) {
      return;
    }
    const oldEvent = await ScheduleSummary.getSummaryFromEventable(
      oldAppointment as IEventable<IAppointment>
    );
    const newEvent = await ScheduleSummary.getSummaryFromEventable(
      newAppointment as IEventable<IAppointment>
    );

    if (!oldEvent || !newEvent) {
      return;
    }

    this._updateAppointmentSummary$.next({
      oldAppointment: oldEvent,
      newAppointment: newEvent,
    });
  }

  reset(): void {
    this._isUserUpdatingEvent$.next(false);
    this._createCalendarSummary$.next(undefined);
    this._deleteCalendarSummary$.next(undefined);
    this._updateCalendarSummary$.next({
      oldEvent: undefined,
      newEvent: undefined,
    });
    this._updateAppointmentSummary$.next({
      oldAppointment: undefined,
      newAppointment: undefined,
    });
  }

  private _clearSelectedSummaries(): void {
    this._selectedAppointmentSummary$.next(undefined);
    this._selectedCalendarEventSummary$.next(undefined);
  }
}
