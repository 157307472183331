import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  filterUndefined,
  getDoc$,
  type WithRef,
} from '@principle-theorem/shared';
import { type IPractice } from '@principle-theorem/principle-core/interfaces';
import { Brand, type Practice } from '@principle-theorem/principle-core';
import { AbstractResolver } from '@principle-theorem/ng-shared';
import { OrganisationService } from './organisation.service';

@Injectable()
export class PracticeResolverService extends AbstractResolver<
  WithRef<IPractice>
> {
  practices: Practice[];

  constructor(router: Router, private _organisation: OrganisationService) {
    super(router);
  }

  getItemById(uid: string): Observable<WithRef<IPractice>> {
    return this._organisation.brand$.pipe(
      filterUndefined(),
      switchMap((brand) => getDoc$(Brand.practiceCol(brand), uid))
    );
  }
}
