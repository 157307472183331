import { Injectable } from '@angular/core';
import {
  VixWinAddPatientRecordCommand,
  VixWinOpenPatientCommand,
  VixWinToDeviceCommand,
} from '@principle-theorem/principle-bridge-core';
import {
  IPatient,
  IBridgeDevice,
  IPractice,
  BridgeDeviceStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  DocumentReference,
  snapshot,
  getParentDocRef,
  multiFind,
} from '@principle-theorem/shared';
import { BridgeCommandsService } from '../../../bridge-commands.service';
import { Practice } from '@principle-theorem/principle-core';
import {
  ConfirmDialogComponent,
  DialogPresets,
  IConfirmationDialogInput,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class VixWinFeatureService {
  constructor(
    private _bridgeCommands: BridgeCommandsService,
    private _dialog: MatDialog
  ) {}

  async openPatient(
    patient: WithRef<IPatient>,
    deviceRef: DocumentReference<IBridgeDevice>
  ): Promise<void> {
    const practiceRef = getParentDocRef<IPractice>(deviceRef);
    const masterDevice = await snapshot(
      Practice.bridgeDevices$({ ref: practiceRef }).pipe(
        multiFind((device) => device.settings?.vixWin?.isMaster ?? false)
      )
    );
    const masterDeviceActive =
      masterDevice && masterDevice.status === BridgeDeviceStatus.Active;

    if (!masterDeviceActive) {
      const data = confirmationDialogData({
        title: 'No Master Bridge Device',
        prompt: [
          'The VixWin master bridge device is offline or not set up. Uploads from VixWin to Principle will not work until the master bridge is available.',
          'Continue with opening the patient in VixWin?',
        ],
        submitLabel: 'Open Patient',
      });
      const confirmed = await this._dialog
        .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
          ConfirmDialogComponent,
          DialogPresets.small({ data })
        )
        .afterClosed()
        .toPromise();

      if (!confirmed) {
        return;
      }
    } else {
      const addPatientRecordCommand: VixWinAddPatientRecordCommand = {
        type: VixWinToDeviceCommand.AddPatientRecord,
        data: {
          patient,
        },
      };
      await this._bridgeCommands.sendCommand(
        addPatientRecordCommand,
        masterDevice.ref
      );
    }

    const openPatientCommand: VixWinOpenPatientCommand = {
      type: VixWinToDeviceCommand.OpenPatient,
      data: {
        patient,
      },
    };
    await this._bridgeCommands.sendCommand(openPatientCommand, deviceRef);
  }
}
