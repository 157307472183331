import {
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { Brand, toAccountDetails } from '@principle-theorem/principle-core';
import {
  type IAccountDetails,
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot, type WithRef } from '@principle-theorem/shared';
import { from, noop, type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'pr-practice-details-selector',
  templateUrl: './practice-details-selector.component.html',
  styleUrls: ['./practice-details-selector.component.scss'],
})
export class PracticeDetailsSelectorComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject<void>();
  private _brand$: ReplaySubject<WithRef<IBrand>> = new ReplaySubject(1);
  private _selectedDetails$: Subject<IAccountDetails> = new ReplaySubject(1);
  trackByPractice = TrackByFunctions.ref<WithRef<IPractice>>();
  ctrl: TypedFormControl<IPractice> = new TypedFormControl<IPractice>();
  practices$: Observable<WithRef<IPractice>[]>;
  @Output()
  selectedChanged = new EventEmitter<IAccountDetails>();

  @Input()
  set brand(brand: WithRef<IBrand>) {
    if (brand) {
      this._brand$.next(brand);
    }
  }

  @Input()
  set selected(details: IAccountDetails) {
    if (details) {
      this._selectedDetails$.next(details);
    }
  }

  constructor() {
    this.practices$ = this._brand$.pipe(
      switchMap((brand) => Brand.practices$(brand))
    );

    this.ctrl.valueChanges
      .pipe(
        map((practice) => toAccountDetails(practice)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((details: IAccountDetails) =>
        this.selectedChanged.next(details)
      );

    this._selectedDetails$
      .pipe(
        tap((details) => from(this.setSelected(details))),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async setSelected(details: IAccountDetails): Promise<void> {
    const practice: WithRef<IPractice> | undefined = await snapshot(
      this._findPracticeByDetails$(details)
    );
    if (practice && practice !== this.ctrl.value) {
      this.ctrl.setValue(practice, { emitEvent: false });
    }
  }

  compareFn(a: WithRef<IPractice>, b: WithRef<IPractice>): boolean {
    return a && b ? a.ref.path === b.ref.path : false;
  }

  private _findPracticeByDetails$(
    details: IAccountDetails
  ): Observable<WithRef<IPractice> | undefined> {
    return this.practices$.pipe(
      map((practices: WithRef<IPractice>[]) =>
        practices.find((practice: WithRef<IPractice>) => {
          return this._isSame(toAccountDetails(practice), details);
        })
      )
    );
  }

  private _isSame(a: IAccountDetails, b: IAccountDetails): boolean {
    return a.name === b.name && a.address === b.address;
  }
}
