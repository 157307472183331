import { Injectable } from '@angular/core';
import { type Data, Router } from '@angular/router';
import { BaseCurrentFirestoreModel } from '@principle-theorem/ng-principle-shared';
import { type WithRef } from '@principle-theorem/shared';
import { type ITeam } from '@principle-theorem/principle-core/interfaces';

@Injectable({
  providedIn: 'root',
  useFactory: (route: Router) => new CurrentTeamService(route),
  deps: [Router],
})
export class CurrentTeamService extends BaseCurrentFirestoreModel<
  WithRef<ITeam>
> {
  protected _propertyAccessor(data: Data): WithRef<ITeam> | undefined {
    return data.team as WithRef<ITeam>;
  }
}
