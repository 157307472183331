import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { getSchemaSize } from '@principle-theorem/editor';
import {
  OrganisationService,
  TagType,
} from '@principle-theorem/ng-principle-shared';
import { validFormGroupChanges$ } from '@principle-theorem/ng-shared';
import { Brand } from '@principle-theorem/principle-core';
import {
  type IInteractionV2,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import { type CollectionReference } from '@principle-theorem/shared';
import { filterUndefined, type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { EditorPresetsService } from '../editor/editor-presets.service';
import { type INoteFormData, NoteFormGroup } from './note-form';
import { AnyExtension } from '@tiptap/core';

@Component({
  selector: 'pr-note-form',
  templateUrl: './note-form.component.html',
  styleUrls: ['./note-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  interaction$ = new ReplaySubject<WithRef<IInteractionV2>>(1);
  noteForm = new NoteFormGroup();
  extensions: AnyExtension[];
  tagCol$: Observable<CollectionReference<ITag>>;
  isDisabled$ = new BehaviorSubject<boolean>(true);
  tagType = TagType.PatientNote;
  @Input() placeholder = 'Add Note';
  @Output() noteChange = new EventEmitter<INoteFormData>();

  @Input()
  set interaction(interaction: WithRef<IInteractionV2>) {
    if (interaction) {
      this.interaction$.next(interaction);
    }
  }

  constructor(
    editorPresets: EditorPresetsService,
    private _organisation: OrganisationService
  ) {
    this.extensions = editorPresets.defaultExtensions();
    this.tagCol$ = this._organisation.brand$.pipe(
      filterUndefined(),
      map((brand) => Brand.patientNoteTagCol(brand))
    );

    this.interaction$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((interaction) => {
        this.noteForm.patchValue({ ...interaction }, { emitEvent: false });
      });

    validFormGroupChanges$(this.noteForm)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((note) => {
        this.noteChange.emit(note);
      });

    this.noteForm.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((form) =>
        this.isDisabled$.next(getSchemaSize(form.content) === 0)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
