import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthService,
  type AuthUser,
  WorkspaceService,
} from '@principle-theorem/ng-auth';
import {
  ManagementService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  type IBreadcrumb,
  ProfileImageService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IUser,
} from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, type WithRef } from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SwitchUserService } from '../../auth/switch-user-list/switch-user.service';

@Component({
    selector: 'pr-toolbar-dropdown',
    templateUrl: './toolbar-dropdown.component.html',
    styleUrls: ['./toolbar-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ToolbarDropdownComponent {
  profileImage = inject(ProfileImageService);
  trackByLink = TrackByFunctions.label<IBreadcrumb>();
  trackByBrand = TrackByFunctions.ref<WithRef<IBrand>>();
  disableChangeUser$: Observable<boolean>;
  authUser$: Observable<AuthUser>;
  user$: Observable<IUser>;
  hasWorkspaces$: Observable<boolean>;
  isManagementUser$: Observable<boolean>;
  readonly iconSize: number = 36;

  constructor(
    public auth: AuthService,
    public organisation: OrganisationService,
    management: ManagementService,
    public workspace: WorkspaceService,
    private _switchUser: SwitchUserService,
    private _router: Router
  ) {
    this.user$ = this.organisation.user$.pipe(filterUndefined());
    this.authUser$ = combineLatest([
      this.auth.authUser$.pipe(filterUndefined()),
      this.user$,
    ]).pipe(
      map(([authUser, user]) => ({ ...authUser, displayName: user.name }))
    );
    this.disableChangeUser$ = this._switchUser.otherUserSessions$.pipe(
      map((sessions) => sessions.length <= 0)
    );
    this.hasWorkspaces$ = workspace.workspaces$.pipe(
      map((workspaces) => workspaces.length > 1)
    );
    this.isManagementUser$ = management.user$.pipe(map((user) => !!user));
  }

  async changeWorkspace(): Promise<void> {
    await this._router.navigate(['/', 'workspaces']);
  }
}
