import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  Timestamp,
  TypeGuard,
  toTimestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_NOTE_RESOURCE_TYPE = 'patientNotes';

export const PATIENT_NOTE_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient Note List',
    description: '',
    idPrefix: PATIENT_NOTE_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface IPTPATNTS {
  // Patient Notes
  PATNUMBER: unknown; // Patient ID
  SEQNUMBER: unknown; //
  INITIALS: unknown; //
  DATE: unknown; //
  DATECREATED: unknown; //
  DETAILS: unknown; //
  NOTESTYPE: unknown; //
  STRUCKOUT: unknown; //
  HIGHLIGHT: unknown; //
  TOPRINT: unknown; //
  DATEAMENDED: unknown; //
  DOCTORNUMBER: unknown; //
  TOOTHNUMBER: unknown; //
  ONHOLD: unknown; //
  ALGORITHM: unknown; //
  COLORNUMBER: unknown; //
  COLORNUMBER2: unknown; //
  PICTURENUMBER: unknown; //
  ENTRYTIME: unknown; //
  BRANCHNUMBER: unknown; // Practice ID
  IMAGETYPE: unknown; //
  FORMNUMBER: unknown; //
  FORMTYPE: unknown; //
  AUDIONUMBER: unknown; //
  GRAPHICSNUMBER: unknown; //
  CATEGORY: unknown; //
  VIEWFLAG: unknown; //
  DIAGNOSISNUMBER: unknown; //
  SN01: unknown; //
  SN02: unknown; //
  SN03: unknown; //
  SN04: unknown; //
  SN05: unknown; //
  SN06: unknown; //
  SN07: unknown; //
  SN08: unknown; //
  SN09: unknown; //
}

export interface IOasisPatientNote {
  id: number;
  noteId: number;
  noteType?: number;
  createdAt: string;
  createdAtTime: string;
  updatedAt: string;
  initials?: string;
  patientId: number;
  practitionerId?: number;
  toothNumber?: number;
  categoryId?: number;
  categoryName?: string;
  note: string;
}

export function isOasisPatientNote(item: unknown): item is IOasisPatientNote {
  return TypeGuard.interface<IOasisPatientNote>({
    id: isNumber,
    noteId: isNumber,
    noteType: TypeGuard.nilOr(isNumber),
    createdAt: isString,
    createdAtTime: isString,
    updatedAt: isString,
    initials: TypeGuard.nilOr(isString),
    patientId: isNumber,
    practitionerId: TypeGuard.nilOr(isNumber),
    toothNumber: TypeGuard.nilOr(isNumber),
    categoryId: TypeGuard.nilOr(isNumber),
    categoryName: TypeGuard.nilOr(isString),
    note: isString,
  })(item);
}

export interface IOasisPatientNoteTranslations {
  createdAt: Timestamp;
}

export interface IOasisPatientNoteFilters {
  patientId: number;
}

const PATIENT_NOTE_SOURCE_QUERY = `
SELECT
	DISTINCT(note.note_id) AS id,
	category.category_name,
	note.*
FROM (
	SELECT
		SEQNUMBER AS note_id,
		NOTESTYPE AS note_type,

    CASE
      WHEN strpos(DATE, '-') > 0
        THEN to_char(convert_to_date(DATE, 'YYYY-MM-DD'), 'DD/MM/YYYY')
      ELSE NULLIF(DATE, 'NULL')
    END AS created_at,
		ENTRYTIME AS created_at_time,
		DATEAMENDED AS updated_at,
		convert_to_integer(PATNUMBER) AS patient_id,
		DOCTORNUMBER AS practitioner_id,
		convert_to_integer(NULLIF(CATEGORY, 0)) AS category_id,
		TOOTHNUMBER AS tooth_number,
		REPLACE(DETAILS, '��', '\r\n') AS note
	FROM PTPATNTS
) AS note
LEFT JOIN (
	SELECT
		convert_to_integer(REGEXP_REPLACE(SKEY, 'CLNCATGSE', '')) AS category_id,
		F1 AS category_name
	FROM SYTBLENT
  WHERE SKEY LIKE 'CLNCATGSE%'
) AS category
ON category.category_id = note.category_id
WHERE note.patient_id IS NOT NULL
ORDER BY id
`;

export class PatientNoteSourceEntity extends BaseSourceEntity<
  IOasisPatientNote,
  IOasisPatientNoteTranslations,
  IOasisPatientNoteFilters
> {
  sourceEntity = PATIENT_NOTE_SOURCE_ENTITY;
  entityResourceType = PATIENT_NOTE_RESOURCE_TYPE;
  sourceQuery = PATIENT_NOTE_SOURCE_QUERY;
  verifySourceFn = isOasisPatientNote;
  allowOffsetJob = true;
  override defaultOffsetSize = 50000;

  translate(
    data: IOasisPatientNote,
    timezone: Timezone
  ): IOasisPatientNoteTranslations {
    return {
      createdAt: toTimestamp(
        moment.tz(
          `${data.createdAt} ${data.createdAtTime}`,
          `${DAY_MONTH_YEAR_FORMAT} h:mm:ss a`,
          timezone
        )
      ),
    };
  }

  getSourceRecordId(data: IOasisPatientNote): number {
    return data.id;
  }

  getSourceLabel(data: IOasisPatientNote): string {
    return `${data.id}-${data.createdAt}`;
  }

  getFilterData(
    data: IOasisPatientNote,
    _timezone: Timezone
  ): IOasisPatientNoteFilters {
    return {
      patientId: data.patientId,
    };
  }
}
