import { isMedipassBaseExtendedData } from '@principle-theorem/medipass';
import { type IDynamicComponentRenderer } from '@principle-theorem/ng-shared';
import {
  TransactionProvider,
  isManualExtendedData,
  isSmartpayPurchaseTransactionExtendedData,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import { isObject, type WithRef } from '@principle-theorem/shared';
import {
  isEasyclaimCompleteCallback,
  isHealthPointBaseTransactionCallbackData,
} from '@principle-theorem/tyro';
import {
  HicapsConnectExtendedDataComponent,
  isHicapsConnectTransaction,
} from './hicaps-connect/hicaps-connect-extended-data/hicaps-connect-extended-data.component';
import { ManualExtendedDataComponent } from './manual/manual-extended-data/manual-extended-data.component';
import { MedipassExtendedDataComponent } from './medipass/medipass-extended-data/medipass-extended-data.component';
import { SmartpayExtendedDataComponent } from './smartpay/smartpay-extended-data/smartpay-extended-data.component';
import { TyroExtendedDataComponent } from './tyro/tyro-extended-data/tyro-extended-data.component';

export const TRANSACTION_EXTENDED_DATA_RENDERERS: IDynamicComponentRenderer<
  unknown,
  WithRef<ITransaction>
>[] = [
  {
    component: TyroExtendedDataComponent,
    isFor: (transaction) => {
      return (
        isObject(transaction.extendedData) &&
        (isHealthPointBaseTransactionCallbackData(
          transaction.extendedData.response
        ) ||
          isEasyclaimCompleteCallback(transaction.extendedData.response))
      );
    },
  },
  {
    component: MedipassExtendedDataComponent,
    isFor: (transaction) =>
      isMedipassBaseExtendedData(transaction.extendedData),
  },
  {
    component: ManualExtendedDataComponent,
    isFor: (transaction) =>
      transaction.provider === TransactionProvider.Manual &&
      isManualExtendedData(transaction.extendedData),
  },
  {
    component: HicapsConnectExtendedDataComponent,
    isFor: (transaction) => isHicapsConnectTransaction(transaction),
  },
  {
    component: SmartpayExtendedDataComponent,
    isFor: (transaction) => {
      return (
        isObject(transaction.extendedData) &&
        isSmartpayPurchaseTransactionExtendedData(transaction.extendedData)
      );
    },
  },
];
