import {
  Component,
  Input,
  HostBinding,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'pt-initials-icon',
  standalone: true,
  template: '{{ initials }}',
  styleUrls: ['./initials-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InitialsIconComponent {
  private _name = '';
  @Input() diameter = 40;

  @Input()
  set name(name: string) {
    if (name) {
      this._name = name;
    }
  }

  get name(): string {
    return this._name;
  }

  @HostBinding('style.height')
  @HostBinding('style.width')
  @HostBinding('style.line-height')
  get size(): string {
    return this._toPixels(this.diameter);
  }

  @HostBinding('style.font-size')
  get fontSize(): string {
    return this._toPixels(this.diameter / 2.5);
  }

  get initials(): string {
    const matches = this.name.match(/\b(\w)/g) || [];
    if (matches.length < 2) {
      return this.name.substr(0, 3);
    }
    return matches.join('').toUpperCase();
  }

  private _toPixels(value: number): string {
    return `${value}px`;
  }
}
