import {
  BlockNodes,
  InlineNodes,
  isNodeSchema,
  type NodeSchema,
  type RawSchema,
  type RawSchemaNodes,
  recursiveFind,
  recursiveReplace,
  type SchemaDataProvider,
} from '@principle-theorem/editor';
import {
  type IMigration,
  type IMigrationLogger,
  Migration,
} from '@principle-theorem/migration-runner';
import { get, isArray, last } from 'lodash';

@Migration({
  name: 'move-video-embed-mark-to-node',
  uid: 'eb0eb88d-77dd-42ac-b6e1-1dbf3062cb92',
})
export class MoveVideoEmbedMarkToNodeMigration implements IMigration {
  async up(
    dryRun: boolean,
    _logger: IMigrationLogger,
    data: SchemaDataProvider
  ): Promise<void> {
    const schema = await data.get();
    const newSchema = recursiveReplace(schema, (baseNode, _parents) => {
      if (
        !isNodeSchema(baseNode) ||
        !isArray(baseNode.content) ||
        !baseNode.content.length
      ) {
        return baseNode;
      }

      const content: NodeSchema[] = [];

      baseNode.content.map((paragraph) => {
        if (
          paragraph.type !== BlockNodes.Paragraph ||
          !isArray(paragraph.content) ||
          !paragraph.content.length
        ) {
          content.push(paragraph);
          return;
        }

        const hasEmbeddedVideo = paragraph.content.some(
          (link) =>
            link.type === InlineNodes.Link &&
            link.content?.some((text) => hasVideoEmbedMark(text, paragraph))
        );

        if (!hasEmbeddedVideo) {
          content.push(paragraph);
          return;
        }

        const subContent: NodeSchema[] = [
          {
            ...paragraph,
            content: [],
          },
        ];

        paragraph.content.map((link, index) => {
          const videoEmbed = recursiveFind(
            link,
            (mark) => String(get(mark, 'type')) === 'video-embed'
          );
          if (!videoEmbed) {
            last(subContent)?.content?.push(link);
            return;
          }

          subContent.push({
            type: BlockNodes.VideoEmbed,
            attrs: {
              src: String(get(videoEmbed, 'attrs.src')),
              width: String(get(videoEmbed, 'attrs.width')),
              height: String(get(videoEmbed, 'attrs.height')),
            },
          });

          const isLastItem = index + 1 === paragraph.content?.length;
          if (!isLastItem) {
            subContent.push({
              ...paragraph,
              content: [],
            });
          }
        });

        content.push(...subContent);
      });

      baseNode.content = content as NodeSchema<RawSchemaNodes>[];
      return baseNode;
    });

    if (!dryRun) {
      await data.update(newSchema as RawSchema);
    }
  }

  async down(
    dryRun: boolean,
    _logger: IMigrationLogger,
    data: SchemaDataProvider
  ): Promise<void> {
    const schema = await data.get();
    const newSchema = recursiveReplace(schema, (item, parents) => {
      if (!isVideoEmbedNodeSchema(item, last(parents))) {
        return item;
      }
      const newNode: NodeSchema = {
        type: BlockNodes.Paragraph,
        content: [
          {
            attrs: {
              href: item.attrs?.src,
            },
            content: [
              {
                marks: [
                  {
                    attrs: {
                      height: item.attrs?.height,
                      src: item.attrs?.src,
                      width: item.attrs?.width,
                    },
                    type: 'video-embed',
                  },
                ],
                text: String(item.attrs?.src),
                type: InlineNodes.Text,
              },
            ],
            type: InlineNodes.Link,
          },
        ],
      };
      return newNode;
    });

    if (!dryRun) {
      await data.update(newSchema as RawSchema);
    }
  }
}

function isVideoEmbedNodeSchema(
  item: object,
  parent?: object
): item is NodeSchema<BlockNodes.VideoEmbed> {
  return (
    isNodeSchema(item, parent) &&
    String(item.type) === String(BlockNodes.VideoEmbed)
  );
}

function hasVideoEmbedMark(item: object, parent?: object): item is NodeSchema {
  return isNodeSchema(item, parent) &&
    item.marks &&
    item.marks.some((mark) => mark.type === 'video-embed')
    ? true
    : false;
}
