import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PrincipleEditorModule } from '@principle-theorem/ng-interactions';
import { TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  IPrescriptionItem,
  PrescriptionItemFormData,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, snapshot } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-prescription-item-form',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    PrincipleEditorModule,
    MatAutocompleteModule,
  ],
  templateUrl: './prescription-item-form.component.html',
  styleUrl: './prescription-item-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrescriptionItemFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  formGroup$ = new ReplaySubject<TypedFormGroup<PrescriptionItemFormData>>(1);
  filteredItems$: Observable<WithRef<IPrescriptionItem>[]>;
  configPrescriptionItems$ = new BehaviorSubject<WithRef<IPrescriptionItem>[]>(
    []
  );

  @Input() compact = true;
  @Output() submitChange = new EventEmitter<PrescriptionItemFormData>();

  @Input()
  set formGroup(formGroup: TypedFormGroup<PrescriptionItemFormData>) {
    if (formGroup) {
      this.formGroup$.next(formGroup);
    }
  }

  @Input()
  set configPrescriptionItems(items: WithRef<IPrescriptionItem>[]) {
    if (items) {
      this.configPrescriptionItems$.next(items);
    }
  }

  constructor() {
    this.filteredItems$ = this.formGroup$.pipe(
      switchMap(({ controls }) =>
        controls.medicationName.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value || ''))
        )
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async setSelectedItem(event: MatAutocompleteSelectedEvent): Promise<void> {
    const item = event.option.value as WithRef<IPrescriptionItem>;
    const formGroup = await snapshot(this.formGroup$);
    formGroup.reset();
    formGroup.patchValue(item, { emitEvent: false });
    formGroup.markAllAsTouched();
    formGroup.markAsDirty();
  }

  private _filter(value: string): WithRef<IPrescriptionItem>[] {
    const options = this.configPrescriptionItems$.getValue();

    if (!isString(value)) {
      return options;
    }

    const filterValue = value.toLowerCase();
    return options.filter((option) =>
      option.medicationName.toLowerCase().includes(filterValue)
    );
  }
}
