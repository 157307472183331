import {
  type AbstractControl,
  type ValidationErrors,
  type ValidatorFn,
  Validators,
} from '@angular/forms';

export const MIN_PHONE_NUMBER_LENGTH = 8;

export class CustomValidators {
  static phoneNumber: ValidatorFn = Validators.minLength(
    MIN_PHONE_NUMBER_LENGTH
  );

  static requireOneOf(controlKeys: string[]): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const oneControlPasses: boolean = controlKeys
        .map((controlKey: string) => formGroup.get(controlKey))
        .some((control: AbstractControl | null) => {
          if (!control) {
            return false;
          }
          return !Validators.required(control);
        });
      if (oneControlPasses) {
        // eslint-disable-next-line no-null/no-null
        return null;
      }
      const keys: string = controlKeys.join(', ');
      return {
        requireOneOf: `One of the following fields is required: ${keys}.`,
      };
    };
  }
}
