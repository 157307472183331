import {
  AssetType,
  IAsset,
  IAssetRequirement,
  IAssetRequirements,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';

export class AssetRequirements {
  static count(requirements: IAssetRequirements): number {
    return this.flatten(requirements).length;
  }

  static addRequirement(
    requirements: IAssetRequirements,
    asset: WithRef<IAsset>
  ): void {
    const found: IAssetRequirement | undefined = this.flatten(
      requirements
    ).find((req) => req.asset.path === asset.ref.path);

    if (found) {
      found.quantity += 1;
      return;
    }

    const newReq: IAssetRequirement = {
      quantity: 1,
      asset: asset.ref,
    };
    this.getAssetGroup(requirements, asset).push(newReq);
  }

  static removeRequirement(
    requirements: IAssetRequirements,
    search: IAssetRequirement
  ): void {
    requirements.instruments = requirements.instruments.filter(
      (asset) => asset !== search
    );
    requirements.equipment = requirements.equipment.filter(
      (asset) => asset !== search
    );
    requirements.consumables = requirements.consumables.filter(
      (asset) => asset !== search
    );
  }

  static flatten(requirements: IAssetRequirements): IAssetRequirement[] {
    return [
      ...requirements.instruments,
      ...requirements.equipment,
      ...requirements.consumables,
    ];
  }

  static getAssetGroup(
    requirements: IAssetRequirements,
    asset: IAsset
  ): IAssetRequirement[] {
    // TODO
    if (asset.type === AssetType.Instrument) {
      return requirements.instruments;
    } else if (asset.type === AssetType.Equipment) {
      return requirements.equipment;
    } else if (asset.type === AssetType.Consumable) {
      return requirements.consumables;
    }
    throw new Error(`Couldn't find asset group`);
  }
}
