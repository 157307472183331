import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import {
  MixedSchema,
  getSchemaSize,
  isMixedSchema,
} from '@principle-theorem/editor';
import { ReplaySubject, type Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { type TypedFormControl } from '../forms/typed-form-group';

@Component({
  selector: 'pt-character-counter',
  templateUrl: './character-counter.component.html',
  styleUrls: ['./character-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CharacterCounterComponent {
  formCtrl$ = new ReplaySubject<TypedFormControl<string | MixedSchema>>(1);
  count$: Observable<number>;
  @Input() label = 'characters';
  @Input() divisor = 1;

  @Input()
  set formCtrl(formCtrl: TypedFormControl<string | MixedSchema>) {
    if (formCtrl) {
      this.formCtrl$.next(formCtrl);
    }
  }

  constructor() {
    this.count$ = this.formCtrl$.pipe(
      switchMap((formCtrl) =>
        formCtrl.valueChanges.pipe(startWith(formCtrl.value))
      ),
      map((value) =>
        isMixedSchema(value) ? getSchemaSize(value) : value.length
      ),
      map((count) => Math.ceil(count / this.divisor)),
      map((count) => (isNaN(count) ? 0 : count)),
      startWith(0)
    );
  }
}
