import {
  Component,
  Input,
  ChangeDetectionStrategy,
  HostBinding,
} from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'g [prChartedItemsBadge]',
    templateUrl: './charted-items-badge.component.html',
    styleUrls: ['./charted-items-badge.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChartedItemsBadgeComponent {
  @Input() count = 0;

  @HostBinding('style.r')
  @Input()
  radius = 32;
}
