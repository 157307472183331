import { isArray, isFunction, mapValues, toPairs } from 'lodash';
import { isObject } from '../common';
import { isTimestamp } from '../firebase/timestamp';
import { getInheritedPropertyDescriptors } from '../helpers';

export abstract class BaseMock {
  serialise<T extends this>(): T {
    const serialisedGetters = this._accessGetters(this);
    const obj = {
      ...this,
      ...serialisedGetters,
    } as T;
    return obj;
  }

  private _serialiseValue(value: unknown): unknown {
    if (isTimestamp(value)) {
      return value;
    }
    if (value instanceof BaseMock) {
      return value.serialise();
    }
    if (isArray(value)) {
      return value.map((child: unknown) => this._serialiseValue(child));
    }
    if (isObject(value)) {
      return mapValues(value, (child) => this._serialiseValue(child));
    }
    return value;
  }

  private _accessGetters<T extends object>(item: T): Partial<T> {
    return toPairs(getInheritedPropertyDescriptors(item))
      .filter(([_key, descriptor]) => descriptor && isFunction(descriptor.get))
      .filter(([key]) => !key.startsWith('_'))
      .reduce((serialised: Partial<T>, [key]) => {
        try {
          const value = item[key as keyof T];
          return { ...serialised, [key]: this._serialiseValue(value) };
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(`Error calling getter ${key}`, error);
          return serialised;
        }
      }, {});
  }
}
