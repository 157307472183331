<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
  <pr-eventable-header-template
    *ngIf="event$ | async as event"
    [start]="event.from | momentTimezone$ : event.practice.ref | ngrxPush"
    [end]="event.to | momentTimezone$ : event.practice.ref | ngrxPush"
    [title]="event.type | splitCamel | titlecase"
    fxFlex
  >
    <div
      prEventableHeaderIcon
      class="type-icon {{ event.type }}"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <mat-icon>{{ event.type | eventTypeIcon }}</mat-icon>
    </div>
    <ng-container *ngIf="eventable$ | ngrxPush as eventable">
      <span *ngIf="eventable | map : calendarEventTitle as title">
        {{ title }}
      </span>
    </ng-container>
  </pr-eventable-header-template>

  <mat-icon class="alert-icon" *ngIf="hasAlert$ | ngrxPush">warning</mat-icon>

  <div
    *ngIf="eventable$ | ngrxPush as eventable"
    fxLayout="row"
    fxLayoutAlign="end center"
  >
    <button
      *ngIf="canEditEvent$ | ngrxPush"
      mat-stroked-button
      prExpansionDisable
      matTooltip="Edit Event"
      (click)="editEvent(eventable)"
    >
      Edit
    </button>

    <pr-eventable-menu
      *ngIf="canEditEvent$ | ngrxPush"
      prExpansionDisable
      [event]="eventable"
     />
  </div>
</div>
