import { StorageResponseAPI } from '@principle-theorem/ng-shared';
import {
  CustomChartType,
  type IBrand,
  type IOrganisation,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  generateBuilderData,
  type IChartConfig,
  type IDataBuilder,
  type IReportingQuery,
  IMeasureBuilderData,
} from '@principle-theorem/reporting';
import {
  getParentDocRef,
  multiMap,
  toMoment,
  type ITimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
import type * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { BehaviorSubject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AreaChart } from '../charts/area-chart';
import { BAR_CHART_OPTIONS, BarChart } from '../charts/bar-chart';
import { ChartBuilder, type IChartDataHandler } from '../charts/chart-builder';
import { type IChartCard } from '../charts/chart-card';
import { ComboChart } from '../charts/combo-chart';
import { ForecastChart } from '../charts/forecast-chart';
import { HISTOGRAM_OPTIONS, Histogram } from '../charts/histogram';
import { LineChart } from '../charts/line-chart';
import { PIE_CHART_OPTIONS, PieChart } from '../charts/pie-chart';
import { SanKeyDiagram } from '../charts/san-key-diagram';
import { ScatterChart } from '../charts/scatter-chart';
import { BigQueryDataSource } from '../data-sources/big-query-data-source';

export class DateRangeDataBuilder implements IDataBuilder {
  dateChange: BehaviorSubject<ITimePeriod>;
  dataSource: BigQueryDataSource<unknown>;
  summary: string;

  constructor(
    api: StorageResponseAPI,
    from: moment.Moment,
    to: moment.Moment,
    public brand$: Observable<WithRef<IBrand>>,
    public practices$: Observable<WithRef<IPractice>[]>
  ) {
    this.dateChange = new BehaviorSubject<ITimePeriod>({
      from,
      to,
    });

    const orgRef$ = brand$.pipe(
      map((brand) => getParentDocRef<IOrganisation>(brand.ref))
    );
    this.dataSource = new BigQueryDataSource(api, orgRef$);
  }

  async build(query: IReportingQuery): Promise<unknown[]> {
    const brandRef$ = this.brand$.pipe(map((brand) => brand.ref));
    const practiceRefs$ = this.practices$.pipe(
      multiMap((practice) => practice.ref)
    );
    const scopeRequests = await this.dataSource.buildAllScopeRequests(
      brandRef$,
      practiceRefs$,
      this.from,
      this.to
    );
    return this.dataSource.build(query, scopeRequests);
  }

  get from(): Moment {
    return toMoment(this.dateChange.value.from);
  }

  get to(): Moment {
    return toMoment(this.dateChange.value.to);
  }

  updateRange(newFrom: moment.Moment, newTo: moment.Moment): void {
    const updateFrom = !newFrom.isSame(this.from);
    const updateTo = !newTo.isSame(this.to);
    if (!updateFrom && !updateTo) {
      return;
    }

    const from = updateFrom ? newFrom : this.from;
    const to = updateTo ? newTo : this.to;

    this.dateChange.next({ from, to });
  }

  toChart<T extends IChartDataHandler>(
    chartType: {
      new (dataBuilder: IDataBuilder, config: IChartConfig): T;
    },
    config: IChartConfig
  ): IChartCard {
    const chartData: T = new chartType(this, config);
    return {
      title: config.labels.title,
      chartBuilder: new ChartBuilder(chartData),
      summary: config.summary,
    };
  }

  toLineChart(config: IChartConfig): IChartCard {
    return this.toChart<LineChart>(LineChart, config);
  }

  toBarChart(config: IChartConfig): IChartCard {
    const chart: IChartCard = this.toChart<BarChart>(BarChart, config);
    chart.chartBuilder.addChartOptions(BAR_CHART_OPTIONS);
    return chart;
  }

  toPieChart(config: IChartConfig): IChartCard {
    const chart: IChartCard = this.toChart<PieChart>(PieChart, config);
    chart.chartBuilder.addChartOptions(PIE_CHART_OPTIONS);
    return chart;
  }

  toComboChart(config: IChartConfig): IChartCard {
    return this.toChart<ComboChart>(ComboChart, config);
  }

  toAreaChart(config: IChartConfig): IChartCard {
    return this.toChart<AreaChart>(AreaChart, config);
  }

  toForecastChart(config: IChartConfig): IChartCard {
    return this.toChart<ForecastChart>(ForecastChart, config);
  }

  toSanKeyDiagram(config: IChartConfig): IChartCard {
    return this.toChart<SanKeyDiagram>(SanKeyDiagram, config);
  }

  toHistogram(config: IChartConfig): IChartCard {
    const chart: IChartCard = this.toChart<Histogram>(Histogram, config);
    chart.chartBuilder.addChartOptions(HISTOGRAM_OPTIONS);
    return chart;
  }

  toScatterChart(config: IChartConfig): IChartCard {
    return this.toChart<ScatterChart>(ScatterChart, config);
  }

  toSingleValueChart(
    measure: IMeasureBuilderData[],
    title?: string
  ): IChartCard {
    const config = toSingleMetricTarget(measure, title);
    return this.toBarChart(config);
  }
}

export function toSingleMetricTarget(
  measures: IMeasureBuilderData[],
  title?: string
): IChartConfig {
  if (!title) {
    title = '';
  }

  return {
    type: CustomChartType.NumberSummary,
    builderData: generateBuilderData({
      measures,
    }),
    labels: { title },
  };
}
