import {
  FeeScheduleMappingHandler,
  IBaseMigrationFeeSchedule,
} from '../../../mappings/fee-schedules';
import {
  FeeScheduleSourceEntity,
  ID4WFeeSchedule,
} from '../../source/entities/fee-schedule';

export class D4WFeeScheduleMappingHandler extends FeeScheduleMappingHandler<
  ID4WFeeSchedule,
  FeeScheduleSourceEntity
> {
  override sourceEntity = new FeeScheduleSourceEntity();
  translateFn = (record: ID4WFeeSchedule): IBaseMigrationFeeSchedule => ({
    ...record,
    name: record.description,
  });
}
