import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_APPOINTMENT_CANCELLATION_RESOURCE_TYPE =
  'patientAppointmentCancellation';

export const PATIENT_APPOINTMENT_CANCELLATION_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Appointment Cancellation List',
      description: '',
      idPrefix: PATIENT_APPOINTMENT_CANCELLATION_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ID4WAppointmentCancellation {
  id: number;
  patient_id: number;
  appointment_id: number;
  appointment_book_id: number;
  user_id: number;
  reason_id: number | null;
  cancellation_reason: string;
  created_at: string;
}

export function isD4WAppointmentCancellation(
  item: unknown
): item is ID4WAppointmentCancellation {
  return TypeGuard.interface<ID4WAppointmentCancellation>({
    id: isNumber,
    patient_id: isNumber,
    appointment_id: isNumber,
    appointment_book_id: isNumber,
    user_id: isNumber,
    reason_id: TypeGuard.nilOr(isNumber),
    cancellation_reason: isString,
    created_at: isString,
  })(item);
}

export interface ID4WAppointmentCancellationTranslations {
  createdAt: Timestamp;
}

export interface ID4WAppointmentCancellationFilters {
  patientId: string;
  appointmentId: string;
  userId: string;
  createdAt: Timestamp;
}

const PATIENT_APPOINTMENT_CANCELLATION_SOURCE_QUERY = `
SELECT
  cts_id AS id,
  appt_id AS appointment_id,
  patient_id,
  app_book_id AS appointment_book_id,
  user_id,
  reason_id,
  reason_ext AS cancellation_reason,
  CONCAT(reg_date, ' ',reg_time) AS created_at
FROM a_cancellation_list
WHERE
  patient_id IS NOT NULL AND
  appt_id IS NOT NULL
`;

const PATIENT_APPOINTMENT_CANCELLATION_SOURCE_QUERY_V2 = `
SELECT
  cts_id AS id,
  appt_id AS appointment_id,
  patient_id,
  app_book_id AS appointment_book_id,
  user_id,
  reason_id,
  reason_ext AS cancellation_reason,
  CONCAT(reg_date, ' ',reg_time) AS created_at
FROM a_cancellation_list
WHERE
  patient_id IS NOT NULL AND
  appt_id IS NOT NULL
`;

export class PatientAppointmentCancellationSourceEntity extends BaseSourceEntity<
  ID4WAppointmentCancellation,
  ID4WAppointmentCancellationTranslations,
  ID4WAppointmentCancellationFilters
> {
  sourceEntity = PATIENT_APPOINTMENT_CANCELLATION_SOURCE_ENTITY;
  entityResourceType = PATIENT_APPOINTMENT_CANCELLATION_RESOURCE_TYPE;
  sourceQuery = [
    PATIENT_APPOINTMENT_CANCELLATION_SOURCE_QUERY,
    PATIENT_APPOINTMENT_CANCELLATION_SOURCE_QUERY_V2,
  ];
  verifySourceFn = isD4WAppointmentCancellation;
  override transformDataFn = flow([]);
  override dateFilterField: keyof ID4WAppointmentCancellationFilters =
    'createdAt';

  translate(
    appointment: ID4WAppointmentCancellation,
    timezone: Timezone
  ): ID4WAppointmentCancellationTranslations {
    return {
      createdAt: toTimestamp(
        moment.tz(appointment.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getSourceRecordId(data: ID4WAppointmentCancellation): number {
    return data.id;
  }

  getSourceLabel(data: ID4WAppointmentCancellation): string {
    return `${data.id} ${data.patient_id} ${data.appointment_id}`;
  }

  getFilterData(
    data: ID4WAppointmentCancellation,
    timezone: Timezone
  ): ID4WAppointmentCancellationFilters {
    return {
      patientId: data.patient_id.toString(),
      appointmentId: data.appointment_id.toString(),
      userId: data.user_id.toString(),
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }
}
