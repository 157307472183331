import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';
import { Storage, getDownloadURL, ref } from '@angular/fire/storage';
import { BlockNodes, NodeGroup } from '@principle-theorem/editor';
import {
  EditorNode,
  EditorNodeComponent,
  type IDomParsing,
  type IDomSerialising,
  type IHasUid,
  NodeAttribute,
} from '@principle-theorem/ng-prosemirror';
import { shareReplayCold, snapshot } from '@principle-theorem/shared';
import { isString } from 'lodash';
import {
  type DOMOutputSpec,
  type Node as ProsemirrorNode,
  type ParseRule,
} from '@tiptap/pm/model';
import { type Observable, of, ReplaySubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { type IResizeEvent } from '../../../drag-resize/drag-resize.component';

export interface IEditorVideoAttributes extends IHasUid {
  src: string;
  width?: string;
}

@EditorNode({
  name: BlockNodes.Video,
  group: NodeGroup.Block,
  inline: false,
  draggable: true,
  defining: true,
  selectable: true,
  isolating: true,
})
@Component({
  selector: 'pt-video-node',
  templateUrl: './video-node.component.html',
  styleUrls: ['./video-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoNodeComponent
  extends EditorNodeComponent<IEditorVideoAttributes>
  implements IHasUid, IDomParsing, IDomSerialising, IEditorVideoAttributes
{
  private _src$: ReplaySubject<string> = new ReplaySubject(1);
  videoUrl$: Observable<string>;
  storagePath = '';
  @NodeAttribute() @Input() width = '400px';
  @NodeAttribute() @Input() uid: string = uuid();

  constructor(elementRef: ElementRef, private _storage: Storage) {
    super(elementRef);

    this.videoUrl$ = this._src$.pipe(
      switchMap((src) => {
        try {
          return getDownloadURL(ref(this._storage, src));
        } catch (error) {
          return of(src);
        }
      }),
      filter((url): url is string => isString(url)),
      shareReplayCold()
    );
  }

  @NodeAttribute()
  @Input()
  set src(src: string) {
    if (src) {
      this.storagePath = src;
      this._src$.next(src);
    }
  }

  updateSize($event: IResizeEvent): void {
    this.width = $event.width;
  }

  async resizeEnd(): Promise<void> {
    this.update.next({
      src: await snapshot(this._src$),
      width: this.width,
      uid: this.uid,
    });
  }

  override parseHTML(): ParseRule[] {
    return [
      {
        tag: 'video[src]',
        getAttrs: (dom) => {
          if (!(dom instanceof HTMLElement)) {
            return false;
          }

          const width = dom.style.width
            ? dom.style.width
            : dom.getAttribute('width');

          return {
            src: dom.getAttribute('storage-path'),
            width,
          };
        },
      },
    ];
  }

  renderHTML(data: { node: ProsemirrorNode }): DOMOutputSpec {
    return [
      'video',
      { ...data.node.attrs, 'storage-path': String(data.node.attrs.src) },
    ];
  }
}
