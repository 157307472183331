import { type ComponentType } from '@angular/cdk/portal';
import { ApplicationRef, Injector, Type } from '@angular/core';
import { type ExtensionRegisterReturnFn } from '@principle-theorem/editor';
import { AtLeast, ObjectOfType, isArray } from '@principle-theorem/shared';
import {
  AnyExtension,
  Node,
  NodeConfig,
  NodeViewRenderer,
  NodeViewRendererProps,
} from '@tiptap/core';
import { AttributeSpec, NodeSpec } from '@tiptap/pm/model';
import { PluginKey, Plugin, Transaction, EditorState } from '@tiptap/pm/state';
import {
  AngularNodeView,
  IAngularNodeViewRendererOptions,
  IEditorTransaction,
} from './angular-node-view-renderer';
import { type IEditorNodeComponent } from './components/editor-node.component';
import { nodeAttributesKey } from './decorators/node-attribute';
import { Observable, Subject } from 'rxjs';
import { EditorView } from '@tiptap/pm/view';

function coerceMetadata<T>(
  property: string,
  component: ComponentType<unknown>
): T | undefined {
  if (!Reflect.hasMetadata(property, component.prototype as object)) {
    return;
  }

  return (
    (Reflect.getMetadata(property, component.prototype as object) as T) ??
    undefined
  );
}

/**
 * A wrapper for ProsemirrorProxyService service to handle creating Angular
 * components that fit the same interface as non-Angular extenstions.
 */
export function wrapAngularExtension<
  T extends IEditorNodeComponent,
  ConfigurationData extends object = object
>(
  component: ComponentType<T>,
  associatedPlugins: ExtensionRegisterReturnFn<Plugin>[] = [],
  configurationData?: ConfigurationData
): (injector: Injector) => AnyExtension {
  return (injector: Injector) => {
    const schema = coerceMetadata<NodeSpec>('schema', component) as NodeSpec;
    const nodeOptions = Object.entries(schema ?? {}).reduce(
      (acc, [key, value]) => {
        if (value === undefined) {
          return acc;
        }
        return {
          ...acc,
          [key]: value as unknown,
        };
      },
      {} as Partial<NodeConfig>
    );

    const transaction$ = new Subject<IEditorTransaction>();
    const event$ = new Subject<Event>();
    const name = coerceMetadata<string>('name', component);
    if (!name) {
      // eslint-disable-next-line no-console
      throw new Error(
        'The component provided to wrapAngularExtension must have a name'
      );
    }

    return Node.create({
      name,
      ...nodeOptions,

      addAttributes() {
        return buildAttributesFromComponent(component);
      },

      addNodeView() {
        return angularNodeViewRenderer(
          component,
          {
            injector,
            applicationRef: injector.get(ApplicationRef),
          },
          schema,
          transaction$.asObservable(),
          event$.asObservable(),
          configurationData
        );
      },

      renderHTML: nodeOptions.renderHTML,
      addCommands: nodeOptions.addCommands,
      addGlobalAttributes: nodeOptions.addGlobalAttributes,

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      addProseMirrorPlugins() {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        return [
          ...(nodeOptions.addProseMirrorPlugins
            ? nodeOptions.addProseMirrorPlugins.bind(this)()
            : []),
          ...associatedPlugins.map((plugin) => plugin(this.editor)),
          new Plugin({
            key: new PluginKey(`${name}-node-view`),
            props: {
              handleKeyDown(
                this: unknown,
                _view: EditorView,
                event: KeyboardEvent
              ) {
                event$.next(event);
                return false;
              },
            },
            appendTransaction: (
              transactions: readonly Transaction[],
              oldState: EditorState,
              newState: EditorState
            ) => {
              transaction$.next({
                transactions,
                oldState,
                newState,
              });
              return undefined;
            },
          }),
        ];
      },
    });
  };
}

function buildAttributesFromComponent<
  Component extends IEditorNodeComponent,
  AttributeData extends object = object
>(component: ComponentType<Component>): ObjectOfType<AttributeSpec> {
  const attributes: ObjectOfType<AttributeSpec> = {};
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const componentInputs =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Reflect.getMetadata(nodeAttributesKey, component.prototype) || [];
  if (isArray(componentInputs)) {
    componentInputs.map(
      (propKey) =>
        (attributes[propKey as keyof ObjectOfType<AttributeData>] = {
          default: undefined,
        })
    );
  }
  return attributes;
}

export function angularNodeViewRenderer(
  viewComponent: Type<IEditorNodeComponent>,
  options: AtLeast<IAngularNodeViewRendererOptions, 'injector'>,
  schema: NodeSpec,
  transaction$: Observable<IEditorTransaction>,
  event$: Observable<Event>,
  initialData?: object
): NodeViewRenderer {
  return (props: NodeViewRendererProps) => {
    return new AngularNodeView(
      viewComponent,
      props,
      event$,
      schema,
      transaction$,
      options,
      initialData
    );
  };
}
