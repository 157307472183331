<mat-toolbar color="primary">
  <span>Write a prompt to generate text</span>
</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content class="flex flex-col">
    <mat-form-field class="!flex">
      <mat-label>What do you want to generate?</mat-label>
      <textarea matInput formControlName="prompt" rows="5"></textarea>
      <mat-hint>Write a description of what content you want written</mat-hint>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions class="!pt-0">
    <pt-buttons-container padding="none">
      <button mat-flat-button color="primary">Save</button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
