import { MixedSchema, type RawInlineNodes } from '@principle-theorem/editor';
import {
  type DocumentReference,
  type IFirestoreModel,
  type INamedDocument,
  type IRecurrencePattern,
  type Timestamp,
} from '@principle-theorem/shared';
import {
  type IAutomationTiming,
  type TimingUnit,
} from '../automation/automation-timing';
import { type IStaffer } from '../staffer/staffer';
import { type ITeam } from '../team/team';
import { type ITask, type TaskPriority } from './task';

export interface IRecurringTask extends IFirestoreModel {
  title: RawInlineNodes;
  description?: MixedSchema;
  priority: TaskPriority;
  recurrencePattern: IRecurrencePattern;
  assignedUser?: INamedDocument<IStaffer>;
  assignedTeam?: INamedDocument<ITeam>;
  owner: INamedDocument<IStaffer>;
  completedAt?: Timestamp;
  createdTasks: ICreatedTask[];
  recurrenceTiming?: RecurringTaskDueTiming;
}

export type RecurringTaskDueTiming = Omit<
  IAutomationTiming,
  'direction' | 'daysOfWeek'
>;

export interface IDueDate {
  unit: TimingUnit;
  amount: number;
}

export interface ICreatedTask {
  dateShown: Timestamp;
  ref: DocumentReference<ITask>;
}

export interface IRecurringTaskConfiguration extends IRecurringTask {
  description: MixedSchema;
}
