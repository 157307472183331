import {
  SterilisationStatus,
  type ISterilisationCycle,
  IMedia,
  SterilisationCycleCollection,
  IStaffer,
  IPractice,
  ISterilisationRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  ArchivedDocument,
  AtLeast,
  CollectionReference,
  DocumentArchive,
  DocumentReference,
  Firestore,
  IReffable,
  WithRef,
  all$,
  asyncForEach,
  multiSort,
  orderBy,
  query$,
  snapshot,
  subCollection,
  toTimestamp,
  undeletedQuery,
  where,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { SterilisationRecord } from './sterilisation-record';
import { Practice } from '../practice/practice';

export class SterilisationCycle {
  static init(
    data: AtLeast<ISterilisationCycle, 'id' | 'machine' | 'staffer'>
  ): ISterilisationCycle {
    return {
      ...data,
      runDate: toTimestamp(),
      status: SterilisationStatus.Pending,
      name: `${data.id} - ${data.machine.name}`,
    };
  }

  static isPassing(cycle: ISterilisationCycle): boolean {
    return cycle.status === SterilisationStatus.Passed;
  }

  static async markFailed(
    cycle: WithRef<ISterilisationCycle>,
    staffer: DocumentReference<IStaffer>
  ): Promise<void> {
    const cycleRecords = await snapshot(SterilisationCycle.records$(cycle));
    await asyncForEach(cycleRecords, async (record) => {
      await DocumentArchive.snapshotToArchive(
        record,
        SterilisationRecord.archiveCol(record),
        undefined,
        staffer
      );
      await Firestore.patchDoc(record.ref, {
        status: SterilisationStatus.Failed,
      });
    });

    await Firestore.patchDoc(cycle.ref, {
      status: SterilisationStatus.Failed,
    });
    await SterilisationCycle.archive(cycle, staffer);
  }

  static async markPassed(
    cycle: WithRef<ISterilisationCycle>,
    staffer: DocumentReference<IStaffer>
  ): Promise<void> {
    const cycleRecords = await snapshot(SterilisationCycle.records$(cycle));
    await asyncForEach(cycleRecords, async (record) =>
      Firestore.patchDoc(record.ref, {
        status: SterilisationStatus.Passed,
      })
    );

    await Firestore.patchDoc(cycle.ref, {
      status: SterilisationStatus.Passed,
    });
    await SterilisationCycle.archive(cycle, staffer);
  }

  static mediaCol(
    cycle: IReffable<ISterilisationCycle>
  ): CollectionReference<IMedia> {
    return subCollection<IMedia>(cycle.ref, SterilisationCycleCollection.Media);
  }

  static media$(
    cycle: IReffable<ISterilisationCycle>
  ): Observable<WithRef<IMedia>[]> {
    return all$(undeletedQuery(SterilisationCycle.mediaCol(cycle)));
  }

  static storagePath(cycle: IReffable<ISterilisationCycle>): string {
    return cycle.ref.path;
  }

  static archive(
    cycle: WithRef<ISterilisationCycle>,
    staffer: DocumentReference<IStaffer>
  ): Promise<DocumentReference<ArchivedDocument<ISterilisationCycle>>> {
    return DocumentArchive.snapshotToArchive(
      cycle,
      SterilisationCycle.archiveCol(cycle),
      undefined,
      staffer
    );
  }

  static archiveCol(
    cycle: IReffable<ISterilisationCycle>
  ): CollectionReference<ArchivedDocument<ISterilisationCycle>> {
    return subCollection<ArchivedDocument<ISterilisationCycle>>(
      cycle.ref,
      SterilisationCycleCollection.CycleHistory
    );
  }

  static editHistory$(
    cycle: IReffable<ISterilisationCycle>
  ): Observable<WithRef<ArchivedDocument<ISterilisationCycle>>[]> {
    return query$(SterilisationCycle.archiveCol(cycle)).pipe(
      multiSort((aRecord, bRecord) =>
        DocumentArchive.sortByArchivedAt(aRecord, bRecord)
      )
    );
  }

  static records$(
    cycle: IReffable<ISterilisationCycle>
  ): Observable<WithRef<ISterilisationRecord>[]> {
    const practiceRef = Firestore.getParentDocRef<IPractice>(cycle.ref);
    return query$(
      undeletedQuery(Practice.sterilisationRecordCol({ ref: practiceRef })),
      where('cycle.ref', '==', cycle.ref),
      orderBy('createdAt', 'desc')
    );
  }
}
