<div fxLayout="column" fxLayoutGap="16px">

  <ng-container *ngrxLet="unusedCredits$ as unusedCredits">
    <div *ngIf="unusedCredits.length">
      <h3>Unused Account Credits</h3>
      <p>
        Unused credits will be deleted. They will be re-issued once the invoice
        has been issued and paid.
      </p>
      <ng-container
        *ngTemplateOutlet="creditDisplay; context: { credits: unusedCredits }"
       />
    </div>
  </ng-container>

  <ng-container *ngrxLet="usedCredits$ as usedCredits">
    <ng-container *ngrxLet="pairs$ as pairs">
      <div *ngIf="usedCredits.length">
        <h3>Used Account Credits</h3>
        <p>
          The account credit associated with this invoice has been used and must be refunded before amending or
          refunding this invoice. Any paid invoices associated with the transactions below will be re-issued, if
          currently paid.
        </p>
        <ng-container
          *ngTemplateOutlet="creditDisplay; context: { credits: usedCredits }"
         />

        <div fxLayout="column" class="invoice-container">
          <div
            *ngFor="let pair of pairs; trackBy: trackByInvoice"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="invoice-item"
          >
            <div fxLayout="column">
              <div>
                <strong>Invoice: </strong>
                {{ pair.invoice.reference }}
              </div>
              <div>
                <strong>Used:</strong>
                <span class="warn">
                  {{ pair.transactions | map: sumTransactions:this | async | currency }}
                </span>
              </div>
            </div>
            <button
              mat-stroked-button
              color="warn"
              matTooltip="Refund credit from invoice"
              (click)="refundCredit(pair)"
            >
              Refund
            </button>
          </div>
        </div>

      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #creditDisplay let-credits="credits">
  <div class="credits">
    <ng-container *ngIf="credits.length; else noCredits">
      <div
        *ngFor="let credit of credits; trackBy: trackByCredit"
        class="credit"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="8px"
      >
        <div class="mat-caption">{{ credit.type | uppercase }}</div>
        <div fxFlex>{{ credit.description }}</div>
        <div>
          <ng-container *ngIf="credit.used">
            <span class="used">Used {{ credit.used | currency }}</span> /
          </ng-container>
          {{ credit.amount | currency }}
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #noCredits>
  <div class="credit">
    <div class="mat-caption">None</div>
  </div>
</ng-template>
