<pr-gap-shortlist-item-container
  *ngIf="appointment$ | async as appointment"
  [appointment]="appointment"
>
  <div class="flex flex-1 flex-col">
    <pr-gap-shortlist-item-header
      [patient]="patient$ | async"
      [waitListItem]="appointment"
      [isShortlisted]="true"
      [gapCandidate]="gapCandidate$ | async"
      (confirmForGap)="confirmForGap()"
      (removeFromShortlist)="removeFromShortlist()"
      (openWaitlistConfiguration)="openWaitlistConfiguration()"
      (showSchedulingNotes)="showSchedulingNotes()"
    />

    <div class="flex flex-col gap-2 px-4 py-2">
      <pr-gap-shortlist-item-appointment-info
        [appointment]="appointment"
        [score]="score$ | async"
      />

      <div *ngrxLet="gapCandidate$ as candidate" class="space-between flex">
        <div class="flex flex-1 flex-col gap-1">
          <span class="mat-caption">Portion of gap offered</span>
          <div *ngIf="candidate">
            {{ candidate.event.from | moment | amDateFormat: 'hh:mma' }} -
            {{ candidate.event.to | moment | amDateFormat: 'hh:mma' }}
          </div>
        </div>

        <div class="flex flex-col gap-1">
          <span class="mat-caption self-end">Patient Contact</span>
          <div class="flex items-center gap-4">
            <button
              *ngrxLet="
                candidate
                  | pairMap: 'phone' : hasBeenContacted$ : this as contacted
              "
              mat-icon-button
              [matTooltip]="contacted ? 'Phone Offer Sent' : 'Send Phone Offer'"
              (click)="sendPhoneOffer()"
            >
              <mat-icon [color]="contacted">phone</mat-icon>
            </button>
            <button
              *ngrxLet="
                candidate
                  | pairMap: 'sms' : hasBeenContacted$ : this as contacted
              "
              mat-icon-button
              [matTooltip]="contacted ? 'SMS Offer Sent' : 'Send SMS Offer'"
              (click)="sendSMSOffer()"
            >
              <mat-icon [color]="contacted"> sms </mat-icon>
            </button>
            <button
              *ngrxLet="
                candidate
                  | pairMap: 'email' : hasBeenContacted$ : this as contacted
              "
              mat-icon-button
              [matTooltip]="contacted ? 'Email Offer Sent' : 'Send Email Offer'"
              (click)="sendEmailOffer()"
            >
              <mat-icon [color]="contacted"> email </mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</pr-gap-shortlist-item-container>
