import { NICKNAME_REGEX } from '@principle-theorem/principle-core/interfaces';
import { removeAdditionalSpaces, splitName } from '@principle-theorem/shared';
import { first, last, trimEnd, trimStart } from 'lodash';

export class NameHelpers {
  /**
   * Gets string in brackets
   */
  static nickname(name: string): string {
    const nickname = first(new RegExp(NICKNAME_REGEX).exec(name));
    return trimEnd(trimStart(nickname, '('), ')');
  }

  static firstName(name: string): string {
    return first(splitName(NameHelpers.fullName(name))) ?? '';
  }

  static lastName(name: string): string {
    const names = splitName(NameHelpers.fullName(name));
    return names.length > 1 ? last(names) ?? '' : '';
  }

  /**
   * Excluding Nickname
   */
  static fullName(name: string): string {
    const nickname = NameHelpers.nickname(name);
    const noNickname = name.replace(`(${nickname})`, '');
    return removeAdditionalSpaces(noNickname);
  }

  static initials(name: string): string {
    const matches = NameHelpers.fullName(name).match(/\b(\w)/g) || [];
    return matches.join('').toUpperCase();
  }
}
