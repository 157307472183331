import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  type RouterStateSnapshot,
  Router,
  type UrlTree,
} from '@angular/router';
import { AuthGuard } from './auth-guard';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class IsAuthenticatedGuard extends AuthGuard {
  constructor(private _router: Router, auth: AuthService) {
    super(auth);
  }

  async canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (await this.isLoggedIn()) {
      return true;
    }
    const redirectTo = state.url;
    return this._router.createUrlTree(['/login'], {
      queryParams: { redirectTo },
    });
  }
}
