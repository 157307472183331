import { isObject } from '../../common';
import { AtLeast } from '../../utility-types';
import { type IWithActors } from '../interfaces';

export function isUpdatedBy<T extends Partial<IWithActors>>(
  item: T,
  actor: string
): boolean {
  return item.updatedBy === actor;
}

export function isCreatedBy(
  item: Partial<IWithActors>,
  actor: string
): boolean {
  return item.createdBy === actor;
}

export function isWithActorUpdate(
  item: unknown
): item is AtLeast<IWithActors, 'updatedBy'> {
  return isObject(item) && 'updatedBy' in item;
}

export function isWithActors(item: unknown): item is IWithActors {
  return isObject(item) && 'createdBy' in item && 'updatedBy' in item;
}
