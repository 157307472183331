import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  getSchemaText,
  MixedSchema,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  EditorPresetsService,
  SendEmailService,
  SendSMSService,
  versionedSchemaToHtml,
} from '@principle-theorem/ng-interactions';
import {
  OrganisationService,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import {
  BasicDialogService,
  DialogPresets,
  NG_SHARED_CONFIG,
  type INgSharedConfig,
} from '@principle-theorem/ng-shared';
import {
  addInteractions,
  balanceOverpaymentWithDeposit,
  cancelInvoice,
  compileTemplateSchema,
  getAccountCreditFromTransactions,
  getAccountCreditTransaction,
  getInvoiceTransactions,
  hasValidEmail,
  hasValidMobile,
  Interaction,
  Invoice,
  InvoiceContextBuilder,
  issueInvoice,
  replaceInvoice,
  ScopeDataBuilder,
  stafferToNamedDoc,
  SystemTemplates,
  toMention,
} from '@principle-theorem/principle-core';
import {
  InteractionType,
  MentionResourceType,
  SystemTemplate,
  TransactionAction,
  type IInvoice,
  type IPatient,
  type IPractice,
  type IPrincipleMention,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  snapshot,
  snapshotDefined,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AccountingFunctionsService } from '../../accounting-functions.service';
import {
  AmendInvoiceConfirmDialogComponent,
  type IAmendInvoiceConfirmDialogData,
} from '../amend-invoice-confirm-dialog/amend-invoice-confirm-dialog.component';
import {
  InvoiceAmendmentHistoryDialogComponent,
  type IInvoiceAmendmentHistoryDialogData,
} from '../invoice-amendment-history-dialog/invoice-amendment-history-dialog.component';
import {
  RelatedAppointmentsDialogComponent,
  type IRelatedAppointmentsDialogData,
} from '../related-appointments-dialog/related-appointments-dialog.component';
import {
  BALANCE_OVERPAYMENT_CONFIRMATION_DIALOG_DATA,
  CANCEL_INVOICE_CONFIRMATION_DIALOG_DATA,
  WRITE_OFF_INVOICE_CONFIRMATION_DIALOG_DATA,
} from './invoice-action-menu/invoice-action-dialog-data';
import { InvoiceInteractionsDialogComponent } from './invoice-interactions-dialog/invoice-interactions-dialog.component';
import { TaxRate } from '@principle-theorem/accounting';

interface ISendInvoiceData {
  content: MixedSchema;
  contact: IPrincipleMention;
  patient: WithRef<IPatient>;
  staffer: WithRef<IStaffer>;
  practice: WithRef<IPractice>;
}

@Injectable()
export class InvoiceActionService {
  private _sharedConfig: INgSharedConfig = inject(NG_SHARED_CONFIG);
  staffer$: Observable<WithRef<IStaffer>>;
  taxRate$: Observable<TaxRate>;

  constructor(
    private _stateNav: StateBasedNavigationService,
    private _accountingFunctions: AccountingFunctionsService,
    private _baseDialog: BasicDialogService,
    private _dialog: MatDialog,
    private _sendSms: SendSMSService,
    private _sendEmail: SendEmailService,
    private _editorPresets: EditorPresetsService,
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService
  ) {
    this.staffer$ = this._organisation.staffer$.pipe(filterUndefined());
    this.taxRate$ = this._organisation.taxRate$.pipe(filterUndefined());
  }

  async issue(invoice: WithRef<IInvoice>): Promise<void> {
    const staffer = await snapshot(this.staffer$);
    await issueInvoice(invoice, staffer);
  }

  async cancel(invoice: WithRef<IInvoice>): Promise<void> {
    const reason = await this._baseDialog.prompt(
      CANCEL_INVOICE_CONFIRMATION_DIALOG_DATA
    );
    if (!reason) {
      return;
    }

    const patient = await snapshot(Invoice.patient$(invoice));
    const transactions = await getInvoiceTransactions(invoice);
    const credit = getAccountCreditFromTransactions(invoice, transactions);
    if (credit) {
      await Invoice.addAccountCredit(patient, credit);
      const creditTransaction = getAccountCreditTransaction(
        credit,
        invoice.to.name,
        invoice.from.name,
        invoice.practice.ref
      );
      await this._accountingFunctions.addTransactionToInvoice(
        invoice,
        creditTransaction,
        TransactionAction.Cancel
      );
    }
    const staffer = await snapshot(this.staffer$);
    await cancelInvoice(invoice, { reason }, staffer);
  }

  async amend(invoice: WithRef<IInvoice>): Promise<void> {
    const confirmed = await this._dialog
      .open<
        AmendInvoiceConfirmDialogComponent,
        IAmendInvoiceConfirmDialogData,
        boolean
      >(
        AmendInvoiceConfirmDialogComponent,
        DialogPresets.medium({ data: { invoice } })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }
    const staffer = await snapshot(this.staffer$);
    await Invoice.revertToDraft(invoice, staffer);
  }

  async writeOff(invoice: WithRef<IInvoice>): Promise<void> {
    const confirmed = await this._baseDialog.confirm(
      WRITE_OFF_INVOICE_CONFIRMATION_DIALOG_DATA
    );
    if (!confirmed) {
      return;
    }
    const staffer = await snapshot(this.staffer$);
    await Invoice.writeOffInvoice(invoice, staffer);
  }

  async openRelatedAppointments(invoice: WithRef<IInvoice>): Promise<void> {
    const appointments = await snapshot(
      Invoice.getAssociatedAppointments$(invoice)
    );
    await this._dialog
      .open<
        RelatedAppointmentsDialogComponent,
        IRelatedAppointmentsDialogData,
        undefined
      >(
        RelatedAppointmentsDialogComponent,
        DialogPresets.large({ data: { appointments } })
      )
      .afterClosed()
      .toPromise();
  }

  async openInteractionHistory(invoice: WithRef<IInvoice>): Promise<void> {
    await this._dialog
      .open<
        InvoiceInteractionsDialogComponent,
        IAmendInvoiceConfirmDialogData,
        undefined
      >(
        InvoiceInteractionsDialogComponent,
        DialogPresets.medium({ data: { invoice } })
      )
      .afterClosed()
      .toPromise();
  }

  async openAmendmentHistory(invoice: WithRef<IInvoice>): Promise<void> {
    await this._dialog
      .open<
        InvoiceAmendmentHistoryDialogComponent,
        IInvoiceAmendmentHistoryDialogData,
        undefined
      >(
        InvoiceAmendmentHistoryDialogComponent,
        DialogPresets.large({
          height: '80%',
          width: '1000px',
          data: { invoice },
        })
      )
      .afterClosed()
      .toPromise();
  }

  async balanceOverpaymentWithDeposit(
    invoice: WithRef<IInvoice>
  ): Promise<void> {
    const confirmed = await this._baseDialog.confirm(
      BALANCE_OVERPAYMENT_CONFIRMATION_DIALOG_DATA
    );
    if (!confirmed) {
      return;
    }
    const staffer = await snapshot(this.staffer$);
    const taxRate = await snapshot(this.taxRate$);
    await balanceOverpaymentWithDeposit(invoice, staffer, taxRate);
  }

  async print(invoice: WithRef<IInvoice>): Promise<void> {
    const patientRef = Invoice.patientDocRef(invoice);
    await this._stateNav.brand([
      'patients',
      patientRef.id,
      'account',
      'invoices',
      invoice.ref.id,
      'print',
    ]);
  }

  async sendAsSMS(invoice: WithRef<IInvoice>): Promise<void> {
    const data = await this._getSendInvoiceData(
      invoice,
      SystemTemplate.InvoiceSMS
    );
    if (!data) {
      return;
    }

    const error = await this._sendSms.sendMessage(
      getSchemaText(data.content),
      data.patient,
      data.staffer.ref,
      data.practice.ref
    );
    if (error) {
      this._snackBar.open(error.message);
      return;
    }

    const interaction = Interaction.init({
      type: InteractionType.Sms,
      title: [
        toMentionContent(toMention(data.staffer, MentionResourceType.Staffer)),
        toTextContent(` sent SMS to `),
        toMentionContent(data.contact),
      ],
      owner: stafferToNamedDoc(data.staffer),
      content: data.content,
    });

    await addInteractions({ contact: data.contact }, interaction);
    this._snackBar.open('SMS Sent Successfully');
  }

  async sendAsEmail(invoice: WithRef<IInvoice>): Promise<void> {
    const data = await this._getSendInvoiceData(
      invoice,
      SystemTemplate.InvoiceEmail
    );
    if (!data) {
      return;
    }

    const extensions = this._editorPresets.defaultToHTMLExtensions();
    const content = versionedSchemaToHtml(data.content, extensions);
    const sendEmailData = {
      subject: `Invoice from ${data.practice.name}`,
      content: content,
    };

    const error = await this._sendEmail.send(
      data.contact,
      sendEmailData,
      data.practice
    );
    if (error) {
      this._snackBar.open(error.message);
      return;
    }

    const interaction = Interaction.init({
      type: InteractionType.Email,
      title: [
        toMentionContent(toMention(data.staffer, MentionResourceType.Staffer)),
        toTextContent(` sent Email to `),
        toMentionContent(data.contact),
      ],
      owner: stafferToNamedDoc(data.staffer),
      content: data.content,
    });

    await addInteractions({ contact: data.contact }, interaction);
    this._snackBar.open('Email Sent Successfully');
  }

  async replace(invoice: WithRef<IInvoice>): Promise<void> {
    const staffer = await snapshot(this.staffer$);
    const taxRate = await snapshot(this.taxRate$);
    try {
      await replaceInvoice(invoice, staffer, taxRate);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  private async _getSendInvoiceData(
    invoice: WithRef<IInvoice>,
    systemTemplate: SystemTemplate
  ): Promise<ISendInvoiceData | undefined> {
    const patient = await snapshot(Invoice.patient$(invoice));
    const organisation = await snapshotDefined(
      this._organisation.organisation$
    );

    if (
      (systemTemplate === SystemTemplate.InvoiceSMS &&
        !hasValidMobile(
          patient,
          organisation.region,
          organisation.integrations?.smsProvider
        )) ||
      (systemTemplate === SystemTemplate.InvoiceEmail &&
        !hasValidEmail(patient))
    ) {
      this._snackBar.open(`Cannot resolve patient contact details`);
      return;
    }

    const contact = toMention(patient, MentionResourceType.Patient);
    const practice = await snapshot(
      this._organisation.practice$.pipe(filterUndefined())
    );
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );

    const scopeData = await ScopeDataBuilder.buildInvoiceScopeData(
      invoice,
      this._sharedConfig.appUrl
    );
    const context = new InvoiceContextBuilder(scopeData).build();

    const content = await snapshot(
      this._organisation.brand$.pipe(
        filterUndefined(),
        map((brand) => SystemTemplates.docRef(systemTemplate, brand.ref)),
        switchMap((ref) => SystemTemplates.getSystemTemplate$(ref)),
        map((templateDoc) =>
          compileTemplateSchema(templateDoc.content, context)
        )
      )
    );

    return {
      content,
      contact,
      patient,
      staffer,
      practice,
    };
  }
}
