import { Injectable } from '@angular/core';
import { type Data, Router } from '@angular/router';
import { type WithRef } from '@principle-theorem/shared';
import { type IAppointment } from '@principle-theorem/principle-core/interfaces';
import { BaseCurrentFirestoreModel } from './base-current-firestore-model';

@Injectable({
  providedIn: 'root',
  useFactory: (route: Router) => new CurrentAppointmentScope(route),
  deps: [Router],
})
export class CurrentAppointmentScope extends BaseCurrentFirestoreModel<
  WithRef<IAppointment>
> {
  protected _propertyAccessor(data: Data): WithRef<IAppointment> | undefined {
    if (data.appointment) {
      return data.appointment as WithRef<IAppointment>;
    }
  }
}
