import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { type AnyChartedItemConfiguration } from '@principle-theorem/principle-core/interfaces';

export interface IAddChartedTreatmentDialogData {
  includeMultiTreatments: boolean;
}

@Component({
  selector: 'pr-add-charted-treatment-dialog',
  templateUrl: './add-charted-treatment-dialog.component.html',
  styleUrls: ['./add-charted-treatment-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddChartedTreatmentDialogComponent {
  constructor(
    @Inject(DIALOG_DATA) public data: IAddChartedTreatmentDialogData,
    private _dialogRef: DialogRef<
      AnyChartedItemConfiguration,
      AddChartedTreatmentDialogComponent
    >
  ) {}

  addChartable(treatment: AnyChartedItemConfiguration): void {
    this._dialogRef.close(treatment);
  }
}
