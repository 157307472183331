import { Injectable } from '@angular/core';
import {
  ChartFacade,
  TreatmentStepsFacade,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  isChartedMultiStepTreatment,
  isChartedMultiStepTreatmentStep,
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isWithRef,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { AddTreatmentToMultiTreatmentProposalProvider } from './charted-surface/chart/add-treatment-to-multi-treatment-proposal-provider';
import { AddTreatmentToProposalProvider } from './charted-surface/chart/add-treatment-to-proposal-provider';
import { AddTreatmentToStoreStepProvider } from './charted-surface/store-treatment-plan/add-treatment-to-store-step-provider';
import { type IEditTreatmentProvider } from './charted-surface/add-charted-surface-provider';

@Injectable()
export class TreatmentProviderService {
  constructor(
    private _chartFacade: ChartFacade,
    private _treatmentStepsFacade: TreatmentStepsFacade
  ) {}

  async getProvider(
    treatmentPlan?: IChartedMultiStepTreatment | WithRef<ITreatmentPlan>,
    treatmentStep?: IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>
  ): Promise<IEditTreatmentProvider> {
    const feeSchedule = await snapshot(
      this._chartFacade.getFeeScheduleManager().currentSchedule$
    );

    if (isWithRef<ITreatmentStep>(treatmentStep)) {
      const step = await snapshot(
        this._treatmentStepsFacade
          .getTreatmentStep$(treatmentStep.ref.id)
          .pipe(filterUndefined())
      );
      return new AddTreatmentToStoreStepProvider(
        this._treatmentStepsFacade,
        step,
        feeSchedule
      );
    }

    if (
      isChartedMultiStepTreatment(treatmentPlan) &&
      isChartedMultiStepTreatmentStep(treatmentStep)
    ) {
      return new AddTreatmentToMultiTreatmentProposalProvider(
        this._chartFacade,
        treatmentPlan,
        treatmentStep,
        feeSchedule
      );
    }

    return new AddTreatmentToProposalProvider(this._chartFacade, feeSchedule);
  }
}
