import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DYNAMIC_COMPONENT_DATA } from '@principle-theorem/ng-shared';
import { type ITyroBaseExtendedData } from '@principle-theorem/principle-core/interfaces';
import {
  fromXMLPayload,
  isEasyclaimCompleteCallback,
  isHealthPointBaseTransactionCallbackData,
} from '@principle-theorem/tyro';
import { type ITransactionExtendedDataDisplayData } from '../../../transactions-display/transaction-display/transaction-display-line/transaction-display-line.component';
import { TyroEasyclaimPayload } from '@principle-theorem/principle-core';

@Component({
  selector: 'pr-tyro-extended-data',
  templateUrl: './tyro-extended-data.component.html',
  styleUrls: ['./tyro-extended-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TyroExtendedDataComponent {
  status?: string;

  constructor(
    @Inject(DYNAMIC_COMPONENT_DATA)
    public data: ITransactionExtendedDataDisplayData<ITyroBaseExtendedData>
  ) {
    this.status = this._getStatus(data.transaction.extendedData);
  }

  private _getStatus(data?: ITyroBaseExtendedData): string {
    if (!data) {
      return '';
    }
    if (isHealthPointBaseTransactionCallbackData(data.response)) {
      return data.response.result;
    }
    if (isEasyclaimCompleteCallback(data.response)) {
      const payload = fromXMLPayload(data.response.payload);
      const errorCode = TyroEasyclaimPayload.getErrorCode(payload);
      return errorCode ? `ERROR CODE: ${errorCode}` : '';
    }
    return '';
  }
}
