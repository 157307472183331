import { Overlay } from '@angular/cdk/overlay';
import { Directive, ElementRef, Input, type OnDestroy } from '@angular/core';
import {
  BasicDialogService,
  ConnectedDialogConfig,
  MouseInteractionsService,
} from '@principle-theorem/ng-shared';
import {
  type IChartedItemDetail,
  type IDentalChartViewSurface,
} from '@principle-theorem/principle-core/interfaces';
import {
  fromEvent,
  merge,
  type Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { delay, repeat, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  ChartedItemsHoverSummaryDialogComponent,
  IItemsSummaryData,
} from './charted-items-hover-summary-dialog/charted-items-hover-summary-dialog.component';
import { DialogRef } from '@angular/cdk/dialog';

@Directive({
    selector: '[prChartedItemsBadgeHover]',
    standalone: false
})
export class ChartedItemsBadgeHoverDirective implements OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();
  private _dialogRef?: DialogRef<
    unknown,
    ChartedItemsHoverSummaryDialogComponent
  >;
  view$: ReplaySubject<IDentalChartViewSurface> = new ReplaySubject(1);
  @Input() offsetX = 0;
  @Input() offsetY = 20;

  constructor(
    private _element: ElementRef<HTMLElement>,
    private _dialog: BasicDialogService,
    private _overlay: Overlay,
    private _mouseInteractions: MouseInteractionsService
  ) {
    const enter$: Observable<MouseEvent> = fromEvent<MouseEvent>(
      this._element.nativeElement,
      'mouseenter'
    );

    const out$: Observable<MouseEvent> = fromEvent<MouseEvent>(
      this._element.nativeElement,
      'mouseout'
    );

    const hover$: Observable<MouseEvent> = enter$.pipe(
      delay(500),
      // eslint-disable-next-line rxjs/no-unsafe-takeuntil
      takeUntil(merge(out$, this._mouseInteractions.down$)),
      repeat()
    );

    hover$
      .pipe(withLatestFrom(this.view$), takeUntil(this._destroy$))
      .subscribe(([_, view]) => {
        const chartedItems: IChartedItemDetail[] = view.chartedItems;
        if (!chartedItems.length) {
          return;
        }
        this._dialogRef = this._dialog.connected<
          IItemsSummaryData,
          unknown,
          ChartedItemsHoverSummaryDialogComponent
        >(
          ChartedItemsHoverSummaryDialogComponent,
          this._getDialogConfig({ chartedItems })
        );
      });

    merge(out$, this._mouseInteractions.down$)
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this._dialogRef && this._dialogRef.close());
  }

  @Input()
  set view(view: IDentalChartViewSurface) {
    if (!view) {
      return;
    }
    this.view$.next(view);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _getDialogConfig(
    data: IItemsSummaryData
  ): ConnectedDialogConfig<
    IItemsSummaryData,
    unknown,
    ChartedItemsHoverSummaryDialogComponent
  > {
    return {
      connectedTo: this._element,
      width: `200px`,
      data,
      hasBackdrop: false,
      scrollStrategy: this._overlay.scrollStrategies.close({ threshold: 10 }),
      positions: [
        {
          panelClass: ['no-padding', 'elevated'],
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetX: this.offsetX,
          offsetY: this.offsetY,
        },
      ],
    };
  }
}
