import { Injectable } from '@angular/core';
import { IReffable, type WithRef } from '@principle-theorem/shared';
import { StateBasedNavigationService } from '@principle-theorem/ng-principle-shared';
import {
  AppointmentSummary,
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { Appointment } from '@principle-theorem/principle-core';
import { AppointmentCardAction } from './appointment-card-action-interface';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class OpenAppointmentActionService extends AppointmentCardAction {
  name = 'Open Appointment';

  constructor(private _stateNav: StateBasedNavigationService) {
    super();
  }

  isVisible(appointment: WithRef<IAppointment> | AppointmentSummary): boolean {
    if (!appointment.event) {
      return false;
    }

    return (
      Appointment.inProgress(appointment) ||
      Appointment.isCheckingOut(appointment) ||
      Appointment.isComplete(appointment) ||
      Appointment.isBefore(appointment.event, moment(), 'day')
    );
  }

  async run(
    appointment: WithRef<IAppointment>,
    patient: IReffable<IPatient>
  ): Promise<void> {
    this.loading$.next(true);
    await this.navigate(appointment, patient);
    this.loading$.next(false);
  }

  updateEventableSummary(
    _eventable: AppointmentSummary,
    _updateFn: (
      oldEvent: AppointmentSummary,
      newEvent: AppointmentSummary
    ) => void
  ): void {
    // no-op
  }

  async navigate(
    appointment: WithRef<IAppointment>,
    patient: IReffable<IPatient>
  ): Promise<void> {
    await this._stateNav.brand([
      'patients',
      patient.ref.id,
      'appointments',
      appointment.ref.id,
    ]);
  }
}
