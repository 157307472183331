import {
  getQuadrant,
  getQuadrantIndex,
  toothNumberToDeciduous,
} from '@principle-theorem/principle-core';
import {
  ChartableSurface,
  Quadrant,
  SourceEntityMigrationType,
  isToothNumber,
  isToothSurface,
  type IChartedRef,
  type IChartedTooth,
  type ISourceEntity,
  type ToothNumber,
  type ToothSurface,
} from '@principle-theorem/principle-core/interfaces';
import { isObject } from '@principle-theorem/shared';
import { flow, isBoolean, isNull, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { cleanObjectStrings } from './lib/conversion-helpers';

export const PATIENT_TOOTH_CONDITION_RESOURCE_TYPE = 'patientToothCondition';

export const PATIENT_TOOTH_CONDITION_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Tooth Condition List',
      description: '',
      idPrefix: PATIENT_TOOTH_CONDITION_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ID4WPatientToothConditionQuery {
  id: number;
  patient_id: number;
  chart_id: number;
  tooth_number: string;
  tooth_surface: number;
  notes: string | null;
  is_baby_tooth: boolean;
  condition_type_id: number;
  condition_type_name: string;
}

export interface ID4WPatientToothCondition {
  id: number;
  patient_id: number;
  chart_id: number;
  tooth_number: ToothNumber | null;
  tooth_surface: ToothSurface | null;
  notes: string | null;
  is_baby_tooth: boolean;
  condition_type_id: number;
  condition_type_name: string;
}

function isD4WPatientToothCondition(
  item: unknown
): item is ID4WPatientToothCondition {
  return (
    isObject(item) &&
    isNumber(item.id) &&
    isNumber(item.patient_id) &&
    isNumber(item.chart_id) &&
    (isToothNumber(item.tooth_number) || isNull(item.tooth_number)) &&
    ((isString(item.tooth_surface) &&
      isToothSurface(item.tooth_surface as ToothSurface)) ||
      isNull(item.tooth_surface)) &&
    (isString(item.notes) || isNull(item.notes)) &&
    isBoolean(item.is_baby_tooth) &&
    isNumber(item.condition_type_id) &&
    isString(item.condition_type_name)
  );
}

export interface ID4WPatientToothConditionFilters {
  patientId: string;
  chartId: string;
  toothNumber?: string;
}

const PATIENT_TOOTH_CONDITION_SOURCE_QUERY = `SELECT * FROM (
  SELECT
    chart_notes_cond_id AS id,
    patient_id,
    chart_id,
    tooth_n AS tooth_number,
    NULLIF(notes, '') AS notes,
    convert_to_boolean(baby) AS is_baby_tooth,
    zone_num AS tooth_surface,
    condtype_id AS condition_type_id,
    now_or_tr_plan
  FROM chart_notes_conditions
  WHERE now_or_tr_plan != 2
) AS patient_conditions
INNER JOIN (
  SELECT
    condtype_name AS condition_type_name,
    condtype_id AS condition_type_id
  FROM notes_conditions_type
) AS condition_types
ON patient_conditions.condition_type_id = condition_types.condition_type_id
INNER JOIN (
  SELECT
    chart_id,
    convert_to_boolean(active) AS active
  FROM charting
  ORDER BY patient_id, chart_id DESC
) AS charts
ON charts.chart_id = patient_conditions.chart_id
ORDER BY id
`;

export class PatientToothConditionSourceEntity extends BaseSourceEntity<
  ID4WPatientToothCondition,
  unknown,
  ID4WPatientToothConditionFilters
> {
  sourceEntity = PATIENT_TOOTH_CONDITION_SOURCE_ENTITY;
  entityResourceType = PATIENT_TOOTH_CONDITION_RESOURCE_TYPE;
  sourceQuery = PATIENT_TOOTH_CONDITION_SOURCE_QUERY;
  allowOffsetJob = true;
  override defaultOffsetSize = 50000;
  verifySourceFn = isD4WPatientToothCondition;
  override transformDataFn = flow([transformToothConditionResults]);

  translate(_data: ID4WPatientToothCondition): unknown {
    return {};
  }

  getFilterData(
    data: ID4WPatientToothCondition
  ): ID4WPatientToothConditionFilters {
    return {
      patientId: data.patient_id.toString(),
      chartId: data.chart_id.toString(),
      toothNumber: data.tooth_number ?? undefined,
    };
  }

  getSourceRecordId(data: ID4WPatientToothCondition): number {
    return data.id;
  }

  getSourceLabel(data: ID4WPatientToothCondition): string {
    return `${data.id} ${data.patient_id}`;
  }
}

function transformToothConditionResults(
  rows: ID4WPatientToothConditionQuery[]
): ID4WPatientToothCondition[] {
  return rows
    .map((row) => {
      const toothNumber = convertConditionToothRefToISONotation(
        row.tooth_number
      );
      const toothSurface =
        toothNumber && row.tooth_surface
          ? zoneNumberToToothSurface(toothNumber, row.tooth_surface)
          : undefined;
      return {
        ...row,
        // eslint-disable-next-line no-null/no-null
        tooth_number: toothNumber ?? null,
        // eslint-disable-next-line no-null/no-null
        tooth_surface: toothSurface ?? null,
      };
    })
    .map(cleanObjectStrings);
}

function zoneNumberToToothSurface(
  toothNumber: ToothNumber,
  zoneNumber: number
): ToothSurface | undefined {
  const quadrant = toothNumber ? getQuadrant(toothNumber) : undefined;
  if (!quadrant) {
    return;
  }

  const upperLeftMap: Record<number, ToothSurface> = {
    1: ChartableSurface.Facial,
    2: ChartableSurface.Mesial,
    3: ChartableSurface.Lingual,
    4: ChartableSurface.Distal,
    5: ChartableSurface.Occlusal,
    6: ChartableSurface.Occlusal,
  };
  const upperRightMap: Record<number, ToothSurface> = {
    1: ChartableSurface.Facial,
    2: ChartableSurface.Distal,
    3: ChartableSurface.Lingual,
    4: ChartableSurface.Mesial,
    5: ChartableSurface.Occlusal,
    6: ChartableSurface.Occlusal,
  };
  const lowerLeftMap: Record<number, ToothSurface> = {
    1: ChartableSurface.Lingual,
    2: ChartableSurface.Mesial,
    3: ChartableSurface.Facial,
    4: ChartableSurface.Distal,
    5: ChartableSurface.Occlusal,
    6: ChartableSurface.Occlusal,
  };
  const lowerRightMap: Record<number, ToothSurface> = {
    1: ChartableSurface.Lingual,
    2: ChartableSurface.Distal,
    3: ChartableSurface.Facial,
    4: ChartableSurface.Mesial,
    5: ChartableSurface.Occlusal,
    6: ChartableSurface.Occlusal,
  };

  const zoneNumberMap: Record<Quadrant, Record<number, ToothSurface>> = {
    [Quadrant.AdultUpperLeft]: upperLeftMap,
    [Quadrant.DeciduousUpperLeft]: upperLeftMap,
    [Quadrant.AdultUpperRight]: upperRightMap,
    [Quadrant.DeciduousUpperRight]: upperRightMap,
    [Quadrant.AdultLowerLeft]: lowerLeftMap,
    [Quadrant.DeciduousLowerLeft]: lowerLeftMap,
    [Quadrant.AdultLowerRight]: lowerRightMap,
    [Quadrant.DeciduousLowerRight]: lowerRightMap,
  };

  return zoneNumberMap[quadrant][zoneNumber];
}

function convertConditionToothRefToISONotation(
  toothRef: string
): ToothNumber | undefined {
  const conversions: Record<string, ToothNumber> = {
    '1': '18',
    '2': '17',
    '3': '16',
    '4': '15',
    '5': '14',
    '6': '13',
    '7': '12',
    '8': '11',
    '9': '21',
    '10': '22',
    '11': '23',
    '12': '24',
    '13': '25',
    '14': '26',
    '15': '27',
    '16': '28',
    '17': '48',
    '18': '47',
    '19': '46',
    '20': '45',
    '21': '44',
    '22': '43',
    '23': '42',
    '24': '41',
    '25': '31',
    '26': '32',
    '27': '33',
    '28': '34',
    '29': '35',
    '30': '36',
    '31': '37',
    '32': '38',
  };

  return conversions[toothRef];
}

export function getConditionChartedRef(
  toothNumber: ToothNumber | null,
  surface?: ToothSurface,
  isDeciduous: boolean = false
): Partial<IChartedRef>[] {
  const adjustedToothNumber =
    isDeciduous && toothNumber
      ? toothNumberToDeciduous(toothNumber)
      : toothNumber;
  const quadrant = adjustedToothNumber
    ? getQuadrant(adjustedToothNumber)
    : undefined;
  const quadrantIndex = adjustedToothNumber
    ? getQuadrantIndex(adjustedToothNumber)
    : undefined;
  if (!adjustedToothNumber || !quadrant || !quadrantIndex) {
    return [
      {
        unscoped: true,
      },
    ];
  }

  const toothRef: IChartedTooth = {
    quadrant,
    quadrantIndex,
    surface,
  };

  return [
    {
      tooth: toothRef,
    },
  ];
}
