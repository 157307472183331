import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  ContentContainerComponent,
  EmptyStateComponent,
  GlobalStoreService,
} from '@principle-theorem/ng-principle-shared';
import {
  ObservableDataTable,
  PaginatedFirestoreTable,
  PipesModule,
  TrackByFunctions,
  extendSortingDataAccessor,
  timestampSortingAccessor,
} from '@principle-theorem/ng-shared';
import { ServiceProviderHandler } from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  IInvoice,
  IPricedServiceCodeEntry,
  IStaffer,
  ITreatmentPlan,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  HISTORY_DATE_FORMAT,
  isTimestamp,
  Timestamp,
  toMoment,
} from '@principle-theorem/shared';
import { compact, startCase } from 'lodash';
import { MomentModule } from 'ngx-moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToothHistoryStatusChipComponent } from '../tooth-history-status-chip/tooth-history-status-chip.component';
import {
  IToothHistoryRecord,
  ToothHistoryStore,
  TreatmentStatus,
} from '../tooth-history.store';
import { RouterModule } from '@angular/router';

const DEFAULT_PAGE_SIZE = 25;
const DISPLAY_COLUMNS = [
  'charted',
  'surface',
  'type',
  'treatmentStatus',
  'treatmentDate',
  'name',
  'fee',
];

@Component({
  selector: 'pr-tooth-history-list',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    EmptyStateComponent,
    PipesModule,
    MomentModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    ContentContainerComponent,
    ToothHistoryStatusChipComponent,
    RouterModule,
  ],
  templateUrl: './tooth-history-list.component.html',
  styleUrls: ['./tooth-history-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToothHistoryListComponent implements AfterViewInit {
  private _globalStore = inject(GlobalStoreService);
  public store = inject(ToothHistoryStore);
  public trackByUid = TrackByFunctions.uniqueId<IToothHistoryRecord>();
  table = new PaginatedFirestoreTable<IToothHistoryRecord>(DEFAULT_PAGE_SIZE);
  TreatmentStatus = TreatmentStatus;

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.table.dataTable.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator)
  set tablePaginator(paginator: MatPaginator) {
    this.table.dataTable.dataSource.paginator = paginator;
  }

  @Output() openPlan = new EventEmitter<DocumentReference<ITreatmentPlan>>();
  @Output() openInvoice = new EventEmitter<DocumentReference<IInvoice>>();

  readonly dateFormat = HISTORY_DATE_FORMAT;

  readonly emptySearch = toSignal(
    this.store.filteredRecords$.pipe(map((col) => !col.length))
  );
  readonly totalRecordCount = toSignal(
    this.store.records$.pipe(map((col) => col.length))
  );

  constructor() {
    this.table.dataTable = new ObservableDataTable(this.store.filteredRecords$);
    this.table.dataTable.pageSize = DEFAULT_PAGE_SIZE;
    this.table.dataTable.displayColumns = DISPLAY_COLUMNS;
  }

  ngAfterViewInit(): void {
    this.table.dataTable.dataSource.sortingDataAccessor =
      extendSortingDataAccessor((data, sortHeaderId) =>
        this._sortingDataAccessor(data, sortHeaderId)
      );
  }

  getTreatmentDate(date: Timestamp | AppointmentStatus.Unscheduled): string {
    return isTimestamp(date)
      ? toMoment(date).format(this.dateFormat)
      : startCase(AppointmentStatus.Unscheduled);
  }

  getStafferName$(
    practitionerRef: DocumentReference<IStaffer>
  ): Observable<string> {
    return this._globalStore.getStafferName$({ ref: practitionerRef });
  }

  displayCodes(codes: IPricedServiceCodeEntry[]): string {
    const resolvedCodes = codes.map((entry) =>
      ServiceProviderHandler.resolveServiceCode(entry.type, entry.code)
    );
    return compact(resolvedCodes)
      .map(({ code }) => code)
      .join(', ');
  }

  private _sortingDataAccessor(
    data: IToothHistoryRecord,
    sortHeaderId: string
  ): string | number | undefined {
    switch (sortHeaderId) {
      case 'charted':
        return timestampSortingAccessor(data.chartedAt);
      case 'treatmentDate':
        return timestampSortingAccessor(
          isTimestamp(data.treatmentDate) ? data.treatmentDate : undefined
        );
      case 'name':
        return data.item.config.name.toLowerCase();
      case 'status':
        return data.treatmentStatus?.toLowerCase();
      case 'type':
        return data.type.toLowerCase();
      default:
        return;
    }
  }
}
