import {
  EventType,
  IEventable,
  IEventTimePeriod,
  isAppointment,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  getTimePeriodDuration,
  ITimePeriod,
  TimeBucket,
  timePeriodsAdjacent,
  timePeriodsIntersect,
  toNamedDocument,
  toTimePeriod,
} from '@principle-theorem/shared';
import { get, omit } from 'lodash';
import { unitOfTime } from 'moment-timezone';
import { TimezoneResolver } from '../../timezone';
import { stafferToNamedDoc } from '../common';
import { Event } from './event';

export class EventTimePeriod {
  static init(
    overrides: AtLeast<IEventTimePeriod, 'from' | 'to' | 'practice' | 'staffer'>
  ): IEventTimePeriod {
    return {
      practice: toNamedDocument(overrides.practice),
      staffer: stafferToNamedDoc(overrides.staffer),
      overlappingEvents: [],
      adjacentEvents: [],
      overlapDuration: 0,
      ...omit(overrides, ['practice', 'staffer']),
    };
  }

  static eventableOverlapsRange(
    eventable: IEventable,
    time: ITimePeriod
  ): boolean {
    return timePeriodsIntersect(
      Event.toTimePeriod(eventable.event, TimezoneResolver.fromMoment(time)),
      time,
      false,
      'minutes'
    );
  }

  static findOverlappingEventables(
    currentlyUsedTimes: IEventable[],
    time: ITimePeriod
  ): IEventable[] {
    return currentlyUsedTimes.filter((currentlyUsedTime) =>
      EventTimePeriod.eventableOverlapsRange(currentlyUsedTime, time)
    );
  }

  static hasOverlappingEventables(
    currentlyUsedTimes: IEventable[],
    time: ITimePeriod
  ): boolean {
    return currentlyUsedTimes.some((currentlyUsedTime) =>
      EventTimePeriod.eventableOverlapsRange(currentlyUsedTime, time)
    );
  }

  static calculateOverlapDuration(
    overlappingEvents: IEventable[],
    time: ITimePeriod,
    unit: unitOfTime.Base = 'minutes'
  ): number {
    return TimeBucket.fromEvents(
      overlappingEvents
        .filter(
          (overlappingEvent) =>
            isAppointment(overlappingEvent) ||
            (get(overlappingEvent, 'isBlocking', true) &&
              overlappingEvent.event.type !== EventType.RosteredOn)
        )
        .map((overlappingEvent) => overlappingEvent.event),
      TimezoneResolver.fromMoment(time)
    )
      .betweenTime(time.from, time.to, true)
      .mergeOverlapping()
      .get()
      .map((overlappingTime) => getTimePeriodDuration(overlappingTime, unit))
      .reduce((total, timeDuration) => total + timeDuration, 0);
  }

  static findAdjacentEventables(
    events: IEventable[],
    time: ITimePeriod
  ): IEventable[] {
    return events.filter((eventable) =>
      timePeriodsAdjacent(
        toTimePeriod(
          eventable.event.from,
          eventable.event.to,
          TimezoneResolver.fromMoment(time)
        ),
        time,
        1,
        'minutes'
      )
    );
  }
}
