import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IHealthcareClaim,
  type IInvoice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  type ITransactionDisplay,
  toTransactionDisplays,
} from './transaction-display';

@Component({
  selector: 'pr-transactions-display',
  templateUrl: './transactions-display.component.html',
  styleUrls: ['./transactions-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsDisplayComponent {
  private _transactions$ = new ReplaySubject<WithRef<ITransaction>[]>(1);
  invoice$ = new ReplaySubject<WithRef<IInvoice>>(1);
  claim$ = new ReplaySubject<IHealthcareClaim | undefined>(1);
  trackByDisplay = TrackByFunctions.field<ITransactionDisplay>('reference');
  displays$: Observable<ITransactionDisplay[]>;

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this.invoice$.next(invoice);
    }
  }

  @Input() set claim(claim: IHealthcareClaim | undefined) {
    this.claim$.next(claim);
  }

  @Input()
  set transactions(transactions: WithRef<ITransaction>[]) {
    if (transactions) {
      this._transactions$.next(transactions);
    }
  }

  constructor() {
    this.displays$ = this._transactions$.pipe(
      map((transactions) => toTransactionDisplays(transactions))
    );
  }
}
