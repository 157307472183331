import { IBaseMigrationItemCode } from '../../../interfaces';
import { BaseItemCodeToConditionMappingHandler } from '../../../mappings/base-item-code-to-condition';
import {
  ADAItemSourceEntity,
  IExactADAItem,
} from '../../source/entities/ada-item';

export class ExactItemCodeToConditionMappingHandler extends BaseItemCodeToConditionMappingHandler<
  IExactADAItem,
  ADAItemSourceEntity
> {
  sourceEntity = new ADAItemSourceEntity();
  translateFn = (record: IExactADAItem): IBaseMigrationItemCode => ({
    id: record.id,
    itemCode: record.itemCode,
    description: record.description ?? '',
  });
}
