import { ChangeDetectionStrategy, Component } from '@angular/core';
import { type IBreadcrumb } from '@principle-theorem/ng-shared';
import { ReconciliationReportFacade } from './store/reconciliation-report.facade';

@Component({
  selector: 'pr-reconciliation-report',
  templateUrl: './reconciliation-report.component.html',
  styleUrls: ['./reconciliation-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReconciliationReportComponent {
  breadcrumbs: IBreadcrumb[] = [{ label: 'Reporting' }];

  constructor(public reportFacade: ReconciliationReportFacade) {}
}
