import { type ITimestampRange } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';

export enum ScheduleModifierType {
  Add = 'add',
  Move = 'move',
  Delete = 'delete',
}

export interface IScheduleModifier {
  type: ScheduleModifierType;
  dates: (ITimestampRange | Timestamp)[];
}

export interface IScheduleModifierSingleDate {
  type: ScheduleModifierType;
  date: ITimestampRange | Timestamp;
}

export type MoveModifier = {
  type: ScheduleModifierType.Move;
  dates: ITimestampRange[];
};

export type AddModifier = {
  type: ScheduleModifierType.Add;
  dates: Timestamp[];
};

export type RemoveModifer = {
  type: ScheduleModifierType.Delete;
  dates: Timestamp[];
};

export type ScheduleModifierOption = RemoveModifer;

export function isMoveModifier(
  modifier: IScheduleModifier
): modifier is MoveModifier {
  return modifier.type === ScheduleModifierType.Move;
}

export function isRemoveModifier(
  modifier: IScheduleModifier
): modifier is RemoveModifer {
  return modifier.type === ScheduleModifierType.Delete;
}

export function isAddModifier(
  modifier: IScheduleModifier
): modifier is AddModifier {
  return modifier.type === ScheduleModifierType.Add;
}
