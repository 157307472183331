import { first, isEqual } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { shareReplayHot, snapshot } from '../../rxjs';
import { serialise } from '../../serialisers/primitive-object-serialiser';
import { WithRef } from '../interfaces';
import {
  CollectionReference,
  DocumentReference,
  Query,
  QueryConstraint,
} from './adaptor/firestore.node';
import { query$ } from './collection';
import { Firestore } from './firestore';
import { map } from 'rxjs/operators';

export class FirestoreCache<T extends object> {
  private _accessOrder: Map<string, void> = new Map();
  private _items$: Map<string, Observable<WithRef<T>>> = new Map();

  constructor(
    private _onDestroy$: Observable<void> = AppDestroy.onDestroy$,
    private _limit?: number,
    private _cacheEnabled: boolean = false
  ) {}

  doc$(ref: DocumentReference<T>): Observable<WithRef<T>> {
    const path = ref.path;
    if (!this._cacheEnabled) {
      return Firestore.doc$(ref);
    }
    if (!this._items$.has(path)) {
      this._items$.set(
        path,
        Firestore.doc$(ref).pipe(shareReplayHot(this._onDestroy$))
      );
    }

    if (this._limit) {
      this._markAsRecentlyUsed(path);

      if (this._items$.size > this._limit) {
        this._evictLeastRecentlyUsed();
      }
    }

    return this._items$.get(path) as Observable<WithRef<T>>;
  }

  getDoc(ref: DocumentReference<T>): Promise<WithRef<T>> {
    return snapshot(this.doc$(ref));
  }

  setCacheEnabled(cacheEnabled: boolean): void {
    this._cacheEnabled = cacheEnabled;
  }

  private _markAsRecentlyUsed(path: string): void {
    this._accessOrder.delete(path);
    this._accessOrder.set(path, undefined);
  }

  private _evictLeastRecentlyUsed(): void {
    const leastRecentlyUsedKey = String(this._accessOrder.keys().next().value);
    if (leastRecentlyUsedKey) {
      this._items$.delete(leastRecentlyUsedKey);
      this._accessOrder.delete(leastRecentlyUsedKey);
    }
  }
}

export class FirestoreQueryCache<T extends object> {
  queries$: {
    properties: object;
    results$: Observable<WithRef<T>[]>;
  }[] = [];

  constructor(
    private _onDestroy$: Observable<void> = AppDestroy.onDestroy$,
    private _cacheEnabled: boolean = false
  ) {}

  query$(
    properties: object,
    col: CollectionReference<T> | Query<T> | string,
    ...queryConstraints: QueryConstraint[]
  ): Observable<WithRef<T>[]> {
    const serialisedProperties = serialise(properties);
    const existingQuery = this.queries$.find((query) =>
      isEqual(query.properties, serialisedProperties)
    );

    if (existingQuery) {
      return existingQuery.results$;
    }

    const newQuery$ = query$(col, ...queryConstraints);
    if (!this._cacheEnabled) {
      return newQuery$;
    }

    const query = {
      properties: serialisedProperties,
      results$: newQuery$.pipe(shareReplayHot(this._onDestroy$)),
    };
    this.queries$.push(query);
    return query.results$;
  }

  find$(
    properties: object,
    col: CollectionReference<T> | Query<T> | string,
    ...queryConstraints: QueryConstraint[]
  ): Observable<WithRef<T> | undefined> {
    return this.query$(properties, col, ...queryConstraints).pipe(
      map((items) => first(items))
    );
  }

  setCacheEnabled(cacheEnabled: boolean): void {
    this._cacheEnabled = cacheEnabled;
  }
}

export class AppDestroy {
  private static _onDestroy$ = new Subject<void>();
  static onDestroy$ = this._onDestroy$.asObservable();

  static destroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
