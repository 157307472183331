import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPerioTableCell } from '@principle-theorem/principle-core/interfaces';
import { serialise } from '@principle-theorem/shared';
import { deletePerioValues, upsertPerioValue } from '../actions/perio-table';
import { ChartId } from '../reducers/active-charts/chart-context-state';
import { type IChartState } from '../reducers/reducers';

@Injectable({
  providedIn: 'root',
})
export class PerioChartFacade {
  private _store = inject(Store<IChartState>);

  upsertPerioValue(changes: IPerioTableCell[]): void {
    this._store.dispatch(
      upsertPerioValue(serialise({ id: ChartId.InAppointment, changes }))
    );
  }

  deletePerioValues(cells?: IPerioTableCell[]): void {
    this._store.dispatch(
      deletePerioValues(serialise({ id: ChartId.InAppointment, cells }))
    );
  }
}
