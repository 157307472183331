import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type WithRef } from '@principle-theorem/shared';
import { type IAppointment } from '@principle-theorem/principle-core/interfaces';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-appointment-status',
  templateUrl: './appointment-status.component.html',
  styleUrls: ['./appointment-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentStatusComponent {
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);
  status$: Observable<string>;

  constructor() {
    this.status$ = this.appointment$.pipe(
      map((appointment) => appointment.status.replace(/([A-Z])/g, ' $1'))
    );
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }
}
