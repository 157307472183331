import {
  isObject,
  type DocumentReference,
  type INamedDocument,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type ITreatmentPlan } from '../clinical-charting/treatment/treatment-plan';
import { type IPatient } from '../patient/patient';
import { type IStaffer } from '../staffer/staffer';
import {
  isInvoiceLineItem,
  type IHasLineItems,
  type IInvoiceLineItem,
} from './line-item';
import { IAccountCreditRefunded } from '../account-credit/account-credit';
import { ITreatmentCategory } from '../treatment-category';

export enum InvoiceLineItemType {
  Product = 'product',
  Treatment = 'treatment',
  TreatmentBasePrice = 'treatmentBasePrice',
  ServiceCode = 'adaCode',
  Deposit = 'deposit',
  CreditTransfer = 'creditTransfer',
  CreditRefund = 'creditRefund',
  Refund = 'refund',
  Fee = 'fee',
}

export enum RefundType {
  Card = 'card',
  Credit = 'credit',
}

export interface ICustomLineItem extends IInvoiceLineItem {
  type: InvoiceLineItemType;
}

export function isCustomLineItem(item: unknown): item is ICustomLineItem {
  return isInvoiceLineItem(item) && isObject(item) && isString(item.type);
}

export type IProductLineItem = ICustomLineItem;
export type IFeeLineItem = ICustomLineItem;

export interface IServiceCodeLineItem extends ICustomLineItem {
  code: string;
  toothId?: string;
}

export interface ITreatmentRef {
  planRef?: DocumentReference<ITreatmentPlan>;
  treatmentUuid: string;
  attributedTo: INamedDocument<IStaffer>;
}

export interface ITreatmentBasePriceLineItem extends ICustomLineItem {
  treatmentRef: ITreatmentRef;
}

export interface ITreatmentLineItem
  extends ICustomLineItem,
    IHasLineItems<IServiceCodeLineItem | ITreatmentBasePriceLineItem> {
  treatmentRef: ITreatmentRef;
  patientRef?: DocumentReference<IPatient>;
}

export interface IDepositLineItem extends ICustomLineItem {
  max: number;
  treatments: INamedDocument[];
  attributedTo?: INamedDocument<IStaffer>;
  forTreatmentCategoryRef?: DocumentReference<ITreatmentCategory>;
}

export interface ICreditTransferLineItem extends ICustomLineItem {
  patientTransferredRefs: {
    from: DocumentReference<IPatient>;
    to: DocumentReference<IPatient>;
  };
  attributedTo?: INamedDocument<IStaffer>;
}

export interface ICreditRefundLineItem extends ICustomLineItem {
  creditsUsed: IAccountCreditRefunded[];
}

export interface IRefundLineItem extends ICustomLineItem {
  refundType: RefundType;
  sourceInvoice: DocumentReference;
}

export function lineItemIsType<T extends ICustomLineItem>(
  type: InvoiceLineItemType
): (item: ICustomLineItem) => item is T {
  return (item: ICustomLineItem): item is T => item.type === type;
}

type LineItemFilter<T extends ICustomLineItem> = (
  item: ICustomLineItem
) => item is T;

export const isProductLineItem: LineItemFilter<IProductLineItem> =
  lineItemIsType<IProductLineItem>(InvoiceLineItemType.Product);

export const isTreatmentLineItem: LineItemFilter<ITreatmentLineItem> =
  lineItemIsType<ITreatmentLineItem>(InvoiceLineItemType.Treatment);

export const isTreatmentBasePriceLineItem: LineItemFilter<ITreatmentLineItem> =
  lineItemIsType<ITreatmentLineItem>(InvoiceLineItemType.TreatmentBasePrice);

export const isDepositLineItem: LineItemFilter<IDepositLineItem> =
  lineItemIsType<IDepositLineItem>(InvoiceLineItemType.Deposit);

export const isFeeLineItem: LineItemFilter<IFeeLineItem> =
  lineItemIsType<IFeeLineItem>(InvoiceLineItemType.Fee);

export const isRefundLineItem: LineItemFilter<IRefundLineItem> =
  lineItemIsType<IRefundLineItem>(InvoiceLineItemType.Refund);

export const isCreditTransferLineItem: LineItemFilter<ICreditTransferLineItem> =
  lineItemIsType<ICreditTransferLineItem>(InvoiceLineItemType.CreditTransfer);

export const isCreditRefundLineItem: LineItemFilter<ICreditRefundLineItem> =
  lineItemIsType<ICreditRefundLineItem>(InvoiceLineItemType.CreditRefund);
