import { NgModule } from '@angular/core';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { InviteUserDialogComponent } from '../../components/users/invite-user-dialog/invite-user-dialog.component';
import { CoreModule } from '../../core/core.module';
import { AuthComponentsModule } from '../auth/auth-components.module';
import { AccessSelectorComponent } from './access-selector/access-selector.component';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { RoleAssignmentComponent } from './role-assignment/role-assignment.component';
import { UserListItemComponent } from './user-list-item/user-list-item.component';
import { UsersComponent } from './users/users.component';

@NgModule({
  imports: [
    NgSharedModule,
    NgPrincipleSharedModule,
    CoreModule,
    AuthComponentsModule,
  ],
  declarations: [
    UsersComponent,
    InviteUserDialogComponent,
    ManageUserComponent,
    AccessSelectorComponent,
    RoleAssignmentComponent,
    UserListItemComponent,
  ],
  exports: [
    UsersComponent,
    InviteUserDialogComponent,
    ManageUserComponent,
    UserListItemComponent,
  ],
})
export class UsersModule {}
