import { InjectionToken } from '@angular/core';
import { type Timestamp } from '@principle-theorem/shared';

export interface IIntercomConfig {
  appId: string;
  secretKey: string;
  appName: string;
}

export const NG_INTERCOM_CONFIG: InjectionToken<IIntercomConfig> =
  new InjectionToken('IIntercomConfig');

export type IntercomCustomData = Record<
  string,
  | Date
  | Timestamp
  | string
  | boolean
  | number
  | undefined
  | null
  | (string | number)[]
>;

export interface IIntercomUser {
  name: string;
  email: string;
  phone?: string;
  company?: {
    company_id: string;
    name: string;
  };
  createdAt: Date;
  customData?: IntercomCustomData;
}
