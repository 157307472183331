<mat-list-item
  class="mat-mdc-list-item-interactive"
  *ngIf="plan$ | async as plan"
>
  <div class="title basis-4/12" *ngIf="patient$ | async as patient">
    <span class="title truncate">{{ patient.name }}</span>
  </div>
  <div class="basis-5/12">
    <span class="target truncate"
      >{{ plan.name }} ({{ price$ | async | currency }})</span
    >
  </div>
  <div class="flex flex-1 items-center justify-end">
    <div class="flex flex-col items-end justify-center">
      <span class="updated-date">{{
        plan.createdAt | moment | amDateFormat : dateFormat
      }}</span>
      <span *ngIf="showProgress$ | async" class="status"
        >{{ progress$ | async | percent }} Complete</span
      >
    </div>
  </div>
</mat-list-item>
