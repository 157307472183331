import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalLinkRedirectComponent } from './external-link-redirect/external-link-redirect.component';
import { NgPrincipleModule } from '@principle-theorem/ng-principle';

@NgModule({
  declarations: [ExternalLinkRedirectComponent],
  imports: [CommonModule, NgPrincipleModule],
})
export class ExternalLinkModule {}
