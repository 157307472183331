<mat-toolbar color="accent">Related Appointments</mat-toolbar>

<mat-dialog-content>
  <div
    *ngFor="let link of links$ | ngrxPush; trackBy: trackByLink"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
  >
    <div>
      <div>{{ link.patient.name }}</div>
      <div class="mat-caption">
        {{ link.appointment.treatmentPlan.treatmentStep.name }} -
        {{ link.appointment.treatmentPlan.name }}
      </div>
    </div>

    <div>
      <div>{{ link.appointment.practitioner.name }}</div>
      <div class="mat-caption">
        {{ link.appointment.event.practice.name }}
      </div>
    </div>
    <div
      *ngIf="
        link.appointment.event.from
          | momentTimezone$ : link.appointment.event.practice.ref
          | ngrxPush
          | moment as eventStart
      "
    >
      <div>
        {{ eventStart | amDateFormat : dateFormat }}
      </div>
      <div class="mat-caption">
        {{ eventStart | amDateFormat : timeFormat }}
      </div>
    </div>

    <span fxFlex></span>
    <div>
      <a
        mat-stroked-button
        mat-dialog-close
        [prBrandRouterLink]="link.linkSegments"
      >
        Open Checkout
      </a>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
  </pt-buttons-container>
</mat-dialog-actions>
