import { Directive, Input } from '@angular/core';
import { Editor } from '@tiptap/core';
import { ReplaySubject } from 'rxjs';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class EditorMenuItemComponent {
  editor$ = new ReplaySubject<Editor>(1);
  buttonText = '';
  tooltip = '';
  active = true;

  @Input()
  set editor(editor: Editor) {
    if (editor) {
      this.editor$.next(editor);
    }
  }
}
