import { rand, randFloat } from '@ngneat/falso';
import {
  IManualExtendedData,
  IManualTransactionType,
  IPractice,
  IStaffer,
  ITransaction,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import {
  ArchivedDocument,
  BaseFirestoreMock,
  getEnumValues,
  MockGenerator,
  Properties,
  WithRef,
} from '@principle-theorem/shared';
import {
  MockDocRef,
  MockNamedDocument,
  MockWithRef,
} from '@principle-theorem/testing';
import { v4 as uuid } from 'uuid';
import { MockAccount } from './account.mock';

export class TransactionMock
  extends BaseFirestoreMock
  implements Properties<Omit<ITransaction, 'extendedData'>>
{
  uid = uuid();
  provider = rand(getEnumValues(TransactionProvider));
  reference = uuid();
  type = TransactionType.Incoming;
  status = TransactionStatus.Complete;
  from = MockAccount.mockAccountDetails().name;
  to = MockAccount.mockAccountDetails().name;
  amount = randFloat({
    min: 50,
    max: 250,
  });
  description = 'Transaction description';
  practiceRef = MockDocRef<IPractice>();
  amendmentOf = MockDocRef<ArchivedDocument<ITransaction>>();
  attributedTo = MockDocRef<IStaffer>();
}

export function MockPaymentsForAmount(amount: number): WithRef<ITransaction>[] {
  if (amount === 0) {
    return [];
  }
  return [
    MockWithRef({
      ...MockGenerator.generate(TransactionMock, {
        provider: TransactionProvider.Cash,
        type: TransactionType.Incoming,
        status: TransactionStatus.Complete,
        amount,
      }),
    }),
  ];
}

export function MockManualTransaction(): ITransaction<IManualExtendedData> {
  return {
    ...MockGenerator.generate(TransactionMock),
    description: 'description',
    extendedData: {
      transactionType:
        MockNamedDocument<IManualTransactionType>('transactionType'),
    },
  };
}
