import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  inject,
} from '@angular/core';
import { PerioChartFacade } from '@principle-theorem/ng-clinical-charting/store';
import { PipesModule } from '@principle-theorem/ng-shared';
import { type IPerioTableCell } from '@principle-theorem/principle-core/interfaces';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Observable, Subject } from 'rxjs';
import { bufferWhen, debounceTime, map, takeUntil } from 'rxjs/operators';
import { PerioTableComponent } from '../perio-table/perio-table.component';
import { PerioTable } from '../perio/perio-table';
import { PerioChartStore } from './perio-chart.store';

@Component({
  selector: 'pr-perio-chart-ui',
  templateUrl: './perio-chart-ui.component.html',
  styleUrls: ['./perio-chart-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    PerioTableComponent,
    NgxSkeletonLoaderModule,
    PipesModule,
  ],
  providers: [PerioChartStore],
})
export class PerioChartUiComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _perioChartFacade = inject(PerioChartFacade);
  public perioChartStore = inject(PerioChartStore);
  inputStopped$: Observable<void>;
  cellChanges$: Subject<IPerioTableCell> = new Subject();

  constructor() {
    this.inputStopped$ = this.cellChanges$.pipe(
      debounceTime(500),
      map(() => undefined)
    );

    this.cellChanges$
      .pipe(
        bufferWhen(() => this.inputStopped$),
        takeUntil(this._onDestroy$)
      )
      .subscribe((changes) => this._perioChartFacade.upsertPerioValue(changes));
  }

  handleChange(change: IPerioTableCell): void {
    this.cellChanges$.next(change);
  }

  deleteCells(cells?: IPerioTableCell[]): void {
    this._perioChartFacade.deletePerioValues(cells);
  }

  getLabel(label: string): string {
    return PerioTable.labelForPerioTable(label);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
