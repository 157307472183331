import { type IProfileLoader } from './profile-loader';
import { type INamedDocument, type WithRef } from '@principle-theorem/shared';
import { type IStaffer } from '@principle-theorem/principle-core/interfaces';
import { LoadStafferActionService } from '@principle-theorem/ng-interactions';

export class StafferLoader implements IProfileLoader {
  showInitials = true;
  name: string;
  details: string;

  constructor(
    public staffer: WithRef<IStaffer>,
    public loader: LoadStafferActionService
  ) {
    this.name = staffer.user.name;
    this.details = staffer.teams
      .map((team: INamedDocument) => team.name)
      .join(', ');
  }

  async do(): Promise<void> {
    await this.loader.do(this.staffer);
  }
}
