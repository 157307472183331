<button
  mat-button
  [matMenuTriggerFor]="textBadgeMenu"
  [matMenuTriggerRestoreFocus]="false"
  [ngClass]="{ active: isActive$ | async }"
  matTooltip="Text Badge"
  matTooltipShowDelay="300"
>
  <mat-icon
    class="text-badge"
    [ngStyle]="{ 'background-color': (activeBadgeColour$ | async) }"
    >font_download</mat-icon
  >
</button>

<mat-menu #textBadgeMenu="matMenu">
  <ng-template matMenuContent>
    <div class="heading">Text Badge</div>
    <ng-container>
      <ng-container *ngFor="let textBadge of textBadges; trackBy: trackByItem">
        <button
          mat-menu-item
          [ngClass]="{
            active: (activeBadgeColour$ | async) === textBadge.colour
          }"
          (click)="runCommand(textBadge)"
        >
          <mat-icon
            class="text-badge"
            [ngStyle]="{ 'background-color': textBadge.colour }"
            >font_download</mat-icon
          >
          <span>{{ textBadge.buttonText }}</span>
        </button>
      </ng-container>
    </ng-container>
  </ng-template>
</mat-menu>
