<mat-menu #typeMenu="matMenu">
  <ng-template matMenuContent>
    <ng-container *ngFor="let type of groupTypes; trackBy: trackByType">
      <button
        mat-menu-item
        *ngIf="group.type !== type"
        (click)="changeType(type)"
      >
        <span class="capitalize">{{ type }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<div class="button-spacer">
  <button mat-stroked-button [matMenuTriggerFor]="typeMenu">
    <span class="capitalize">{{ group.type }}</span>
  </button>
</div>

<span class="mat-caption flex-1">{{ description }}</span>

<button mat-icon-button color="warn" (click)="deleted.emit()">
  <mat-icon>delete</mat-icon>
</button>
