import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  GapStoreService,
  IGapDayPair,
} from '@principle-theorem/ng-principle-shared';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-pending-gaps-list',
    templateUrl: './pending-gaps-list.component.html',
    styleUrls: ['./pending-gaps-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PendingGapsListComponent {
  groupedPendingGaps$ = new ReplaySubject<IGapDayPair[]>(1);

  @Input()
  set groupedPendingGaps(groupedPendingGaps: IGapDayPair[]) {
    if (groupedPendingGaps) {
      this.groupedPendingGaps$.next(groupedPendingGaps);
    }
  }

  constructor(public gapStore: GapStoreService) {}
}
