import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  PatientRelationshipType,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot, type WithRef } from '@principle-theorem/shared';
import {
  IMergePatientDialogData,
  MergePatientDialogComponent,
} from '../merge-patient-dialog/merge-patient-dialog.component';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-merge-patient-button',
    templateUrl: './merge-patient-button.component.html',
    styleUrls: ['./merge-patient-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MergePatientButtonComponent {
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  isDisabled$: Observable<boolean>;

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(private _dialog: MatDialog) {
    this.isDisabled$ = this.patient$.pipe(
      map((patient) =>
        patient.relationships.some(
          (relationship) =>
            relationship.type === PatientRelationshipType.PrimaryPatient
        )
      )
    );
  }

  async mergePatient(): Promise<void> {
    const patient = await snapshot(this.patient$);
    this._dialog.open<
      MergePatientDialogComponent,
      IMergePatientDialogData,
      WithRef<IPatient>
    >(MergePatientDialogComponent, DialogPresets.medium({ data: { patient } }));
  }
}
