import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { STAFF_DESTINATION_ENTITY } from '../../destination/entities/staff';

export const PROVIDER_RESOURCE_TYPE = 'providers';

export const PROVIDER_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Provider List',
    description: '',
    idPrefix: PROVIDER_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface IOasisProvider {
  id: number;
  name: string;
  providerNumber?: string;
  initials?: string;
}

export function isOasisProvider(item: unknown): item is IOasisProvider {
  return TypeGuard.interface<IOasisProvider>({
    id: isNumber,
    name: isString,
    providerNumber: TypeGuard.nilOr(isString),
    initials: TypeGuard.nilOr(isString),
  })(item);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOasisProviderTranslations {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOasisProviderFilters {}

const PROVIDER_SOURCE_QUERY = `
SELECT
	convert_to_integer(REGEXP_REPLACE(SKEY, '[A-Za-z]+', '')) AS id,
	F1 AS name,
	F6 AS provider_number,
	F42 AS initials
FROM SYUSRDEF
WHERE SKEY LIKE 'DOCNAMEXE%'
`;

export class ProviderSourceEntity extends BaseSourceEntity<
  IOasisProvider,
  IOasisProviderTranslations,
  IOasisProviderFilters
> {
  sourceEntity = PROVIDER_SOURCE_ENTITY;
  entityResourceType = PROVIDER_RESOURCE_TYPE;
  sourceQuery = PROVIDER_SOURCE_QUERY;
  verifySourceFn = isOasisProvider;

  migrationDestinations = [STAFF_DESTINATION_ENTITY.metadata.key];

  translate(
    _data: IOasisProvider,
    _timezone: Timezone
  ): IOasisProviderTranslations {
    return {};
  }

  getSourceRecordId(data: IOasisProvider): number {
    return data.id;
  }

  getSourceLabel(data: IOasisProvider): string {
    return data.name;
  }

  getFilterData(
    _data: IOasisProvider,
    _timezone: Timezone
  ): IOasisProviderFilters {
    return {};
  }
}
