import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  IChatMessage,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { Firestore, WithRef, snapshotDefined } from '@principle-theorem/shared';
import { xorBy } from 'lodash';

export interface IReactionSelectorDialogData {
  message: WithRef<IChatMessage>;
}

@Component({
  selector: 'pr-reaction-selector-dialog',
  templateUrl: './reaction-selector-dialog.component.html',
  styleUrl: './reaction-selector-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactionSelectorDialogComponent {
  constructor(
    @Inject(DIALOG_DATA) private _data: IReactionSelectorDialogData,
    private _dialogRef: DialogRef<ReactionSelectorDialogComponent>,
    private _organisation: OrganisationService
  ) {}

  async emojiSelected(emoji?: string): Promise<void> {
    if (!emoji) {
      return;
    }

    this._dialogRef.close();

    const staffer = await snapshotDefined(this._organisation.staffer$);
    await updateReaction(this._data.message, staffer, emoji);
  }
}

async function updateReaction(
  message: WithRef<IChatMessage>,
  staffer: WithRef<IStaffer>,
  emoji: string
): Promise<void> {
  await Firestore.patchDoc(message.ref, {
    reactions: xorBy(
      [...message.reactions],
      [
        {
          emoji,
          authorRef: staffer.ref,
        },
      ],
      (reaction) => `${reaction.authorRef.path} ${reaction.emoji}`
    ),
  });
}
