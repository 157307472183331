import {
  BrandCollection,
  IBrand,
  IManualTransactionType,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  CollectionReference,
  IReffable,
  WithRef,
  all$,
  multiSortBy$,
  nameSorter,
  query$,
  subCollection,
  undeletedQuery,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';

export class ManualTransactionType {
  static init(
    overrides: AtLeast<IManualTransactionType, 'name'>
  ): IManualTransactionType {
    return { deleted: false, ...overrides };
  }

  static col(
    brand: IReffable<IBrand>
  ): CollectionReference<IManualTransactionType> {
    return subCollection<IManualTransactionType>(
      brand.ref,
      BrandCollection.ManualTransactionTypes
    );
  }

  static all$(
    brand: IReffable<IBrand>,
    includeDeleted: boolean = false
  ): Observable<WithRef<IManualTransactionType>[]> {
    const collection = this.col(brand);
    const results$ = includeDeleted
      ? all$(collection)
      : query$(undeletedQuery(collection));
    return results$.pipe(multiSortBy$(nameSorter()));
  }
}

export const DEFAULT_MANUAL_TRANSACTION_TYPES = [
  'Direct Deposit',
  'CDBS',
  'ZIP Pay',
  'EFTPOS',
  'DVA',
  'Afterpay',
  'Government Voucher',
  'Health Fund Claim',
].map((name) => ManualTransactionType.init({ name }));
