import { NgModule } from '@angular/core';
import { NgCalendarStoreModule } from '@principle-theorem/ng-calendar/store';
import { ComponentsModule } from './components/components.module';
import { ManageCalendarEventService } from './manage-calendar-event.service';
import { NgCalendarCoreModule } from './ng-calendar-core.module';

@NgModule({
  imports: [ComponentsModule, NgCalendarCoreModule, NgCalendarStoreModule],
  exports: [ComponentsModule],
  providers: [ManageCalendarEventService],
})
export class NgCalendarModule {}
