<form fxFill [formGroup]="form">
  <div *ngIf="(hideToggle$ | async) === false" class="checkbox">
    <mat-checkbox formControlName="addToWaitlist">
      Add to Waitlist
    </mat-checkbox>
  </div>

  <div fxLayout="column" *ngIf="form.controls.addToWaitlist.value">
    <div fxLayout="row" fxLayoutGap="16px">
      <mat-form-field fxFlex="50">
        <mat-label>Date From</mat-label>
        <input
          matInput
          formControlName="dateFrom"
          [required]="true"
          [min]="now"
          [matDatepicker]="dateFrom"
          (focus)="dateFrom.open()"
          (click)="dateFrom.open()"
        />
        <mat-datepicker-toggle matIconSuffix [for]="dateFrom" />
        <mat-datepicker #dateFrom />
      </mat-form-field>

      <mat-form-field fxFlex="50">
        <mat-label>Date To</mat-label>
        <input
          matInput
          formControlName="dateTo"
          [required]="true"
          [min]="form.controls.dateFrom.value"
          [matDatepicker]="dateTo"
          (focus)="dateTo.open()"
          (click)="dateTo.open()"
        />
        <mat-datepicker-toggle matIconSuffix [for]="dateTo" />
        <mat-datepicker #dateTo />
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="16px">
      <pr-date-selector
        fxFlex
        placeholder="Time From"
        [interval]="timeInterval"
        [formControl]="form.controls.timeFrom"
        [timeFrom]="openTime$ | async"
        [timeTo]="closeTime$ | async"
      />

      <pr-date-selector
        fxFlex
        placeholder="Time To"
        [interval]="timeInterval"
        [formControl]="form.controls.timeTo"
        [timeFrom]="openTime$ | async"
        [timeTo]="closeTime$ | async"
      />
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-around center"
      formGroupName="days"
    >
      <div fxFlex>
        <mat-checkbox formControlName="monday">Mon</mat-checkbox>
      </div>
      <div fxFlex>
        <mat-checkbox formControlName="tuesday">Tue</mat-checkbox>
      </div>
      <div fxFlex>
        <mat-checkbox formControlName="wednesday">Wed</mat-checkbox>
      </div>
      <div fxFlex>
        <mat-checkbox formControlName="thursday">Thu</mat-checkbox>
      </div>
      <div fxFlex>
        <mat-checkbox formControlName="friday">Fri</mat-checkbox>
      </div>
      <div fxFlex>
        <mat-checkbox formControlName="saturday">Sat</mat-checkbox>
      </div>
      <div fxFlex>
        <mat-checkbox formControlName="sunday">Sun</mat-checkbox>
      </div>
    </div>

    <mat-form-field class="urgency">
      <mat-label>Appointment Urgency</mat-label>
      <mat-select
        formControlName="urgency"
        [compareWith]="isSelectedUrgency"
        [required]="true"
      >
        <mat-option
          *ngFor="let level of levels; trackBy: trackByUrgency"
          [value]="level.value"
        >
          {{ level.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="notes">
      <mat-form-field fxFlex>
        <mat-label>Notes</mat-label>
        <pr-content-editor
          placeholder="Notes"
          formControlName="notes"
          [menuEnabled]="false"
        />
        <mat-hint align="start"
          >Any extra details which help determine whether they'll be available
          for appointment gap.</mat-hint
        >
      </mat-form-field>
    </div>
  </div>
</form>
