<mat-list
  *ngIf="healthFundCard && healthFundCard.membershipNumber; else noDetails"
>
  <mat-list-item *ngIf="healthFundCard.fundCode?.length">
    <span matListItemTitle>{{ healthFundCard.fundCode }}</span>
    <span matListItemLine>Health Fund Name</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>{{ healthFundCard.membershipNumber }}</span>
    <span matListItemLine>Membership Number</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>{{ healthFundCard.memberNumber }}</span>
    <span matListItemLine>Member Number</span>
  </mat-list-item>
</mat-list>

<ng-template #noDetails>
  <p class="mat-caption layout-margin">No Card Details</p>
</ng-template>

<pt-buttons-container padding="all-round">
  <button *ngIf="healthFundCard" mat-stroked-button (click)="remove.emit()">
    Remove
  </button>
  <button mat-flat-button color="primary" (click)="edit.emit()">
    {{ healthFundCard ? 'Edit' : 'Add' }} Card Details
  </button>
</pt-buttons-container>
