import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageMap } from '@ngx-pwa/local-storage';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { filterUndefined } from '@principle-theorem/shared';
import {
  OrganisationService,
  UserSessionsBloc,
  type IUserSession,
} from '@principle-theorem/ng-principle-shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SwitchUserDialogComponent } from '../switch-user-dialog/switch-user-dialog.component';
import { AuthService } from '@principle-theorem/ng-auth';
import { FirebaseError } from 'firebase/app';

enum EmailPasswordLoginErrorCode {
  InvalidEmail = 'auth/invalid-email',
  UserDisabled = 'auth/user-disabled',
  UserNotFound = 'auth/user-not-found',
  WrongPassword = 'auth/wrong-password',
}

@Injectable()
export class SwitchUserService {
  private _userSessionsBloc: UserSessionsBloc;
  otherUserSessions$: Observable<IUserSession[]>;

  constructor(
    private _organisation: OrganisationService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _localStorage: StorageMap,
    private _authService: AuthService,
    @Optional() @Inject(DOCUMENT) private _document?: Document
  ) {
    this._userSessionsBloc = new UserSessionsBloc(this._localStorage);
    const currentUser$ = this._organisation.user$.pipe(
      filterUndefined(),
      map((user) => user.email)
    );
    this.otherUserSessions$ = combineLatest([
      this._userSessionsBloc.userSessions$,
      currentUser$,
    ]).pipe(
      map(([sessions, currentUser]) =>
        sessions.filter((session) => session.email !== currentUser)
      )
    );
  }

  async requestSwitchUser(data: IUserSession): Promise<void> {
    const signInMethods = await this._authService.getSignInMethods(data.email);

    if (signInMethods.includes('google.com')) {
      await this._authService.signInWithGoogleProvider();
      return;
    }

    const password = await this._getPassword(data);
    if (!password) {
      return;
    }
    try {
      await this._organisation.switchUser(data.email, password);
    } catch (error) {
      this._handleError(
        (error as FirebaseError).code as EmailPasswordLoginErrorCode
      );
      return;
    }
    this._snackBar.open(`User changed to ${data.name}`);
    if (this._document) {
      this._document.location.reload();
    }
  }

  private async _getPassword(data: IUserSession): Promise<string | undefined> {
    return this._dialog
      .open<SwitchUserDialogComponent, IUserSession, string>(
        SwitchUserDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
  }

  private _handleError(code: EmailPasswordLoginErrorCode): void {
    const errorMessage = this._getErrorMessage(code);
    if (errorMessage) {
      this._snackBar.open(errorMessage);
    }
  }

  private _getErrorMessage(
    code: EmailPasswordLoginErrorCode
  ): string | undefined {
    switch (code) {
      case EmailPasswordLoginErrorCode.InvalidEmail:
      case EmailPasswordLoginErrorCode.UserDisabled:
      case EmailPasswordLoginErrorCode.UserNotFound:
        return `User not found`;
      case EmailPasswordLoginErrorCode.WrongPassword:
        return `Password incorrect or user does not have a password set`;
      default:
        return;
    }
  }
}
