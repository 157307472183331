import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { Appointment, TreatmentStep } from '@principle-theorem/principle-core';
import {
  IAppointment,
  IAutomation,
  TreatmentStepAutomation,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, isRefChanged$, snapshot } from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppointmentAutomationsDialogComponent } from '../../scheduling/appointment-automations-dialog/appointment-automations-dialog.component';

@Component({
  selector: 'pr-appointment-history-card-automations',
  templateUrl: './appointment-history-card-automations.component.html',
  styleUrls: ['./appointment-history-card-automations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentHistoryCardAutomationsComponent {
  automations$: Observable<WithRef<IAutomation<TreatmentStepAutomation>>[]>;
  appointment$ = new ReplaySubject<WithRef<IAppointment>>(1);

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor(private _dialog: MatDialog) {
    this.automations$ = this.appointment$.pipe(
      isRefChanged$(),
      switchMap((appointment) => Appointment.treatmentStep(appointment)),
      switchMap((step) => TreatmentStep.automations$(step))
    );
  }

  async openAutomations(): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    const config = DialogPresets.medium({
      data: {
        appointment,
      },
    });
    this._dialog.open(AppointmentAutomationsDialogComponent, config);
  }
}
