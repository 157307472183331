import {
  CustomMappingType,
  ICustomMappingSourceOption,
  IPractice,
  IPracticeMigration,
  ITranslationMap,
} from '@principle-theorem/principle-core/interfaces';
import { CustomMapping } from '../../../custom-mapping';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import {
  IExactStaffer,
  StafferSourceEntity,
} from '../../source/entities/staff';
import {
  Firestore,
  IBlobFilenamePair,
  INamedDocument,
  IReffable,
  WithRef,
  XSLXImporterExporter,
  asyncForEach,
  multiMap,
  multiSortBy$,
  nameSorter,
  snapshot,
  undeletedQuery,
} from '@principle-theorem/shared';
import { IBaseMigrationStaffer } from '../../../interfaces';
import { sortBy, uniqBy } from 'lodash';
import { from } from 'rxjs';
import { Brand } from '@principle-theorem/principle-core';
import { TranslationMapHandler } from '../../../translation-map';
import { PracticeMigration } from '../../../practice-migrations';
import { StafferToPracticeToXSLX } from './practitioner-to-practice-to-xslx';
import { XSLXToStafferToPractice } from './xslx-to-staffer-to-practice';

export const STAFFER_TO_PRACTICE_MAPPING_TYPE = 'staffToPractice';

export const STAFFER_TO_PRACTICE_MAPPING = CustomMapping.init({
  metadata: {
    label: 'Staffer to Practice',
    description: `
      Used to map Staff in Exact to practices in Principle. Appointments using the source staff id will then
      have their event location assigned to the mapped practice. This is only applicable for multi-practice migrations
    `,
    type: STAFFER_TO_PRACTICE_MAPPING_TYPE,
  },
  type: CustomMappingType.DocumentReference,
  labelOverrides: {
    sourceIdentifier: 'Exact User ID',
    sourceLabel: 'Exact User',
    destinationIdentifier: 'Principle Practice',
  },
});

export class StaffToPracticeMapping extends BaseCustomMappingHandler<IPractice> {
  customMapping = STAFFER_TO_PRACTICE_MAPPING;
  sourceEntity = new StafferSourceEntity();
  translateFn = (record: IExactStaffer): IBaseMigrationStaffer => ({
    id: record.id,
    name: record.name ?? '',
    providerNo: '',
  });

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 1000)
      .toPromise();
    const options = records
      .map((record) => record.data.data)
      .map((record) => {
        const staffer = this.translateFn(record);
        return {
          label: `${staffer.name} ${staffer.providerNo}`,
          value: this.sourceEntity.getSourceRecordId(record).toString(),
        };
      });

    return sortBy(
      [
        ...options,
        {
          label: `Default Practice (When practitioner is missing or ID not matched)`,
          value: '0',
        },
      ],
      'label'
    );
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<IPractice>[]> {
    return snapshot(
      from(
        Firestore.getDocs(
          undeletedQuery(Brand.practiceCol(migration.configuration.brand))
        )
      ).pipe(
        multiMap((practice) => ({
          name: practice.name,
          ref: practice.ref,
        })),
        multiSortBy$(nameSorter())
      )
    );
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, staff, translator } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(fileName, staff, translator);
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, staff, translator } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(fileName, staff, translator);
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToStafferToPractice()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const sourceOptions = await this.getSourceOptions(migration);
    const practices = await snapshot(
      Brand.practices$(migration.configuration.brand)
    );

    await asyncForEach(items, async (item) => {
      const matchingOption = sourceOptions.find(
        (sourceOption) => sourceOption.value === item.id
      );
      if (!matchingOption || !item.mapTo) {
        return;
      }

      const label = matchingOption.label;
      const value = matchingOption.value;

      const foundPractice = practices.find(
        (practice) => practice.name === item.mapTo
      );
      if (!foundPractice) {
        // eslint-disable-next-line no-console
        console.error(
          `Mapping error: ${this.customMapping.metadata.label} - Couldn't find practice for item`,
          item
        );
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: foundPractice.ref,
          destinationValue: item.mapTo,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    staff: IBaseMigrationStaffer[];
    translator: StafferToPracticeToXSLX;
  }> {
    const fileName = this.getFileName();
    const staff = await this._getUserOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const translator = new StafferToPracticeToXSLX(
      await snapshot(Brand.practices$(migration.configuration.brand)),
      await this.getRecords(translationMap)
    );
    return { fileName, staff, translator };
  }

  private async _getUserOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<IBaseMigrationStaffer[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 10000)
      .toPromise();
    return sortBy(
      uniqBy(
        records.map((record) => this.translateFn(record.data.data)),
        (record) => record.id
      ),
      'name'
    );
  }
}

export async function resolveExactStaffLocation(
  stafferInitials: string,
  translationMap: TranslationMapHandler,
  practiceMappings: WithRef<ITranslationMap<IPractice>>[],
  migrationPractices: INamedDocument<IPractice>[]
): Promise<WithRef<IPractice> | undefined> {
  if (migrationPractices.length === 1) {
    return Firestore.getDoc(migrationPractices[0].ref);
  }

  const practiceRef = await translationMap.getBySource<IPractice>(
    stafferInitials.replace(/\//g, '-'),
    STAFFER_TO_PRACTICE_MAPPING_TYPE
  );
  if (practiceRef?.destinationIdentifier) {
    return Firestore.getDoc(practiceRef.destinationIdentifier);
  }

  const defaultPractice = practiceMappings.find(
    (practice) => practice.sourceIdentifier === '0'
  )?.destinationIdentifier;
  return defaultPractice ? Firestore.getDoc(defaultPractice) : undefined;
}
