import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'pt-stripe-connect-button',
    templateUrl: './stripe-connect-button.component.html',
    styleUrls: ['./stripe-connect-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StripeConnectButtonComponent {}
