<ng-container *ngIf="action$ | async as action">
  <pt-action-list-button
    *ngIf="showAction$ | async"
    [isDisabled]="isDisabled$ | async"
    [icon]="action.icon"
    [label]="label$ | async"
    [info]="info$ | async"
    [tooltip]="action.tooltip"
    (click)="doAction()"
  />
</ng-container>
