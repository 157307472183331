@if (groupedMachines$ | async; as machines) {
  @if (!machines.length) {
    <pr-empty-state image="list" title="machines configured" />
  } @else {
    <mat-list>
      @for (group of machines; track group.practiceName) {
        @if (displayPracticeNames$ | async) {
          <h3 class="mat-headline-6 !m-0">{{ group.practiceName }}</h3>
        }
        @for (machine of group.machines; track machine.ref.path) {
          <div class="flex w-full items-center justify-between gap-4">
            <div class="h-full flex-1">
              <h3 class="!m-0">{{ machine.name }} ({{ machine.id }})</h3>
              <p class="mat-caption !m-0">{{ machine.description }}</p>
            </div>

            <div class="flex items-center justify-end gap-4">
              <div>
                <button mat-icon-button (click)="edit.emit(machine)">
                  <mat-icon>edit</mat-icon>
                </button>
                <button
                  mat-icon-button
                  color="warn"
                  (click)="delete.emit(machine)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
        }
      }
    </mat-list>
  }
}
