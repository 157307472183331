import {
  getSchemaText,
  initVersionedSchema,
  toTextContent,
} from '@principle-theorem/editor';
import {
  ChartedCondition,
  ChartedSurface,
  ClinicalChart,
  ClinicalNote,
  ConditionConfiguration,
  ConditionConfigurationName,
  MockAllTeeth,
  getQuadrant,
  getQuadrantIndex,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  ITranslationMap,
  PerioDataPoint,
  PerioMeasurement,
  Quadrant,
  isToothSurface,
  type FailedDestinationEntityRecord,
  type IBrand,
  type IChartedCondition,
  type IChartedSurface,
  type IClinicalChart,
  type IClinicalNote,
  type IConditionConfiguration,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPerioRecord,
  type IPracticeMigration,
  type IStaffer,
  type IToothRef,
  type ToothNumber,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  asyncForEach,
  find$,
  getDoc,
  multiFilter,
  reduceToSingleArray,
  snapshot,
  toISODate,
  toInt,
  toMomentTz,
  toNamedDocument,
  where,
  type DocumentReference,
  type IIdentifiable,
  type INamedDocument,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first, groupBy, uniqBy } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, type Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import { type IArraySorter } from '../../../destination/destination-entity-record';
import {
  BasePatientClinicalChartDestinationEntity,
  IBaseClinicalChartJobData,
  IClinicalChartMigrationData,
  IGeneratedCharts,
} from '../../../destination/entities/patient-clinical-charts';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { PracticeIdFilter } from '../../../destination/filters/practice-id-filter';
import { PracticeMigration } from '../../../practice-migrations';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  D4W_CONDITION_MAP,
  D4W_CONDITION_PRINCIPLE_MAP,
  type D4WCondition,
} from '../../source/entities/lib/conditions';
import {
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
  type ID4WPatient,
} from '../../source/entities/patient';
import {
  PatientChartSourceEntity,
  type ID4WPatientChart,
  type ID4WPatientChartFilters,
  type ID4WPatientChartTranslations,
} from '../../source/entities/patient-chart';
import {
  PatientMissingToothSourceEntity,
  d4wMissingToothToISONotation,
  type ID4WPatientMissingTooth,
  type ID4WPatientMissingToothFilters,
  type ID4WPatientMissingToothTranslations,
} from '../../source/entities/patient-missing-teeth';
import {
  PATIENT_PERIO_CHART_RESOURCE_TYPE,
  PatientPerioChartSourceEntity,
  type ID4WPatientPerioChart,
  type ID4WPatientPerioChartFilters,
  type ID4WPatientPerioChartRecord,
  type ID4WPatientPerioChartTranslations,
} from '../../source/entities/patient-perio-chart';
import {
  PatientPreExistingTreatmentSourceEntity,
  type ID4WPatientPreExistingTreatment,
  type ID4WPatientPreExistingTreatmentFilters,
} from '../../source/entities/patient-pre-existing-treatment';
import {
  PatientToothConditionSourceEntity,
  getConditionChartedRef,
  type ID4WPatientToothCondition,
} from '../../source/entities/patient-tooth-condition';
import { TOOTH_SURFACES_MAP } from '../../source/entities/patient-treatment';
import { PatientTreatmentNoteSourceEntity } from '../../source/entities/patient-treatment-note';
import { D4WItemCodeToConditionMappingHandler } from '../mappings/item-code-to-condition';
import { D4WItemCodeToNoteMappingHandler } from '../mappings/item-code-to-note';
import { D4WItemCodeMappingHandler } from '../mappings/item-codes';
import { D4WStafferMappingHandler } from '../mappings/staff';
import { getChartedRefs } from './patient-treatment-plan';
import { PatientDestinationEntity } from './patients';

export const PATIENT_CLINICAL_CHART_CUSTOM_MAPPING_TYPE =
  'patientClinicalChart';

export const PATIENT_CLINICAL_CHART_DESTINATION_ENTITY = DestinationEntity.init(
  {
    metadata: {
      key: 'patientClinicalCharts',
      label: 'Patient Clinical Charts',
      description: `The conversion will be done as follows:
        Conditions:
        - Conditions are stored on the clinical chart as expected

        Pre-existing treatments:
        - Any treatment in the "Current Condition" section of the D4W chart can't be converted due the nature of how they're tracked.

        Treatments:
        - All treatments, whether performend or not, will be put in a single treatment plan.
        - Any performed treatments will be grouped by their treatment date.
        - Any pending treatments will be combined into a single future treatment step.
        - Any appointments booked for the patient will need to have treatment added to it manually.

        A "latest" chart will be added to include all:
          - Dentition Records
          - Clinical Notes
          - Conditions
          - Latest Periodontal Chart

        This acts as the baseline chart going forward in Principle. The most latest information will be on this chart. The dates for when notes, conditions, and treatments were added will be mirrored.

        Some things to be aware of:
        - Periodontal FGM measurements can't be converted to Principle

        If no practitioner is available for the following, the preferred practitioner for the patient has been used:
          - Conditions
          - Flagged Treatments
          - Clinical Notes
      `,
    },
  }
);

interface IPerioChartData {
  id: string;
  date: Timestamp;
  patientId: string;
  practitionerId: string;
  data: IGetRecordResponse<
    ID4WPatientPerioChart,
    ID4WPatientPerioChartTranslations
  >[];
}

interface IChartData {
  id: string;
  date: Timestamp;
  patientId: string;
  practitionerId?: string;
  data: IGetRecordResponse<ID4WPatientChart, ID4WPatientChartTranslations>[];
}

export interface IClinicalChartJobData
  extends IBaseClinicalChartJobData<ID4WPatient> {
  existingTreatmentMappings: WithRef<
    ITranslationMap<IConditionConfiguration>
  >[];
}

export class PatientClinicalChartDestinationEntity extends BasePatientClinicalChartDestinationEntity<
  ID4WPatient,
  IClinicalChartJobData
> {
  periodontalResourceType = PATIENT_PERIO_CHART_RESOURCE_TYPE;
  destinationEntity = PATIENT_CLINICAL_CHART_DESTINATION_ENTITY;

  override filters = [
    new PracticeIdFilter<IClinicalChartJobData>((jobData) =>
      jobData.sourcePatient.data.data.practice_id.toString()
    ),
    new PatientIdFilter<IClinicalChartJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  override sorters: IArraySorter[] = [
    {
      key: 'clinicalNotes',
      sortByPath: (data) => getSchemaText((data as IClinicalNote).content),
    },
    {
      key: 'conditions',
      sortByPath: ['type', 'config.ref.id', 'scopeRef'],
    },
  ];

  override canMigrateByIdRange = true;

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    clinicalCharts: new PatientChartSourceEntity(),
    clinicalNotes: new PatientTreatmentNoteSourceEntity(),
    missingTeeth: new PatientMissingToothSourceEntity(),
    periodontalChart: new PatientPerioChartSourceEntity(),
    toothConditions: new PatientToothConditionSourceEntity(),
    preExistingTreatments: new PatientPreExistingTreatmentSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  customMappings = {
    staff: new D4WStafferMappingHandler(),
    itemCodes: new D4WItemCodeMappingHandler(),
    itemCodesToNotes: new D4WItemCodeToNoteMappingHandler(),
    itemCodeToCondition: new D4WItemCodeToConditionMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean,
    _fromDate?: Timestamp,
    _toDate?: Timestamp,
    fromId?: string,
    toId?: string
  ): Observable<IClinicalChartJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);
    const sourceItemCodesToNotes$ =
      this.customMappings.itemCodesToNotes.getRecords$(translationMap);
    const existingTreatmentMappings$ =
      this.customMappings.itemCodeToCondition.getRecords$(translationMap);

    return this.sourceEntities.patients
      .getRecords$(
        migration,
        500,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        multiFilter((patient) => {
          if (!fromId || !toId) {
            return true;
          }

          return (
            patient.data.data.patient_id >= toInt(fromId) &&
            patient.data.data.patient_id <= toInt(toId)
          );
        }),
        withLatestFrom(
          staff$,
          sourceItemCodes$,
          sourceItemCodesToNotes$,
          existingTreatmentMappings$
        ),
        map(
          ([
            sourcePatients,
            staff,
            sourceItemCodes,
            sourceItemCodesToNotes,
            existingTreatmentMappings,
          ]) =>
            sourcePatients.map((sourcePatient) => ({
              staff,
              sourceItemCodes,
              sourceItemCodesToNotes,
              existingTreatmentMappings,
              sourcePatient,
              destinationEntity,
            }))
        )
      );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IClinicalChartJobData
  ): Promise<
    | IClinicalChartMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const errorResponseData = {
      label: data.sourcePatient.record.label,
      uid: data.sourcePatient.record.uid,
      ref: data.sourcePatient.record.ref,
    };

    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        errorResponseData,
        `Couldn't resolve patient`
      );
    }

    // Default provider is used from custom mapping with id: 0
    let practitionerMap = data.staff.find(
      (staffer) => staffer.sourceIdentifier === '0'
    )?.destinationIdentifier;

    const providerId = data.sourcePatient.data.data.provider_id?.toString();
    if (providerId) {
      practitionerMap =
        data.staff.find((staffer) => staffer.sourceIdentifier === providerId)
          ?.destinationIdentifier ||
        ((await translationMap.getBySource(providerId, STAFFER_RESOURCE_TYPE))
          ?.destinationIdentifier as DocumentReference<IStaffer> | undefined);
    }

    if (!practitionerMap) {
      return this._buildErrorResponse(
        errorResponseData,
        `Couldn't resolve practitioner ${
          data.sourcePatient.data.data.provider_id ?? ''
        }`
      );
    }

    const charts = await this._buildClinicalChartData(
      migration,
      practitionerMap,
      sourcePatientId,
      data.staff,
      translationMap,
      data
    );

    return {
      sourcePatientId,
      patientRef,
      charts,
    };
  }

  private async _buildClinicalChartData(
    migration: WithRef<IPracticeMigration>,
    practitionerRef: DocumentReference<IStaffer>,
    patientUid: string,
    staff: WithRef<ITranslationMap<IStaffer>>[],
    translationMap: TranslationMapHandler,
    data: IClinicalChartJobData
  ): Promise<IGeneratedCharts> {
    const sourcePerioChartItems =
      await this.sourceEntities.periodontalChart.filterRecords(
        migration,
        'patientId',
        patientUid
      );

    const perioClinicalCharts = await buildPerioCharts(
      sourcePerioChartItems,
      staff,
      translationMap
    );

    const conditions = await this.sourceEntities.toothConditions.filterRecords(
      migration,
      'patientId',
      patientUid
    );

    const preExistingTreatments =
      await this.sourceEntities.preExistingTreatments.filterRecords(
        migration,
        'patientId',
        patientUid
      );

    const missingTeeth = await this.sourceEntities.missingTeeth.filterRecords(
      migration,
      'patientId',
      patientUid
    );

    const brand = await snapshot(PracticeMigration.brand$(migration));
    const practitioner = stafferToNamedDoc(
      await Firestore.getDoc(practitionerRef)
    );

    const sourceCharts = await this.sourceEntities.clinicalCharts.filterRecords(
      migration,
      'patientId',
      patientUid
    );

    const clinicalCharts = await buildClinicalCharts(
      data,
      practitioner,
      migration,
      brand,
      sourceCharts,
      conditions,
      missingTeeth,
      preExistingTreatments,
      staff,
      translationMap
    );

    return {
      clinicalCharts,
      perioCharts: compact(perioClinicalCharts),
    };
  }
}

async function buildClinicalCharts(
  data: IClinicalChartJobData,
  defaultPractitioner: INamedDocument<IStaffer>,
  migration: WithRef<IPracticeMigration>,
  brand: WithRef<IBrand>,
  sourceCharts: IGetRecordResponse<
    ID4WPatientChart,
    ID4WPatientChartTranslations,
    ID4WPatientChartFilters
  >[],
  sourceConditions: IGetRecordResponse<ID4WPatientToothCondition>[],
  missingTeeth: IGetRecordResponse<
    ID4WPatientMissingTooth,
    ID4WPatientMissingToothTranslations,
    ID4WPatientMissingToothFilters
  >[],
  preExistingTreatments: IGetRecordResponse<
    ID4WPatientPreExistingTreatment,
    unknown,
    ID4WPatientPreExistingTreatmentFilters
  >[],
  staff: WithRef<ITranslationMap<IStaffer>>[],
  translationMap: TranslationMapHandler
): Promise<(IClinicalChart & IIdentifiable)[]> {
  const charts: IChartData[] = [];

  sourceCharts.map((sourceChart) => {
    const chartFound = charts.find(
      (chart) => chart.id === sourceChart.data.data.id.toString()
    );
    if (chartFound) {
      chartFound.data.push(sourceChart);
      return;
    }
    charts.push({
      id: sourceChart.data.data.id.toString(),
      date: sourceChart.data.translations.createdAt,
      patientId: sourceChart.record.filters.patientId,
      practitionerId: sourceChart.record.filters.providerId,
      data: [sourceChart],
    });
  });

  return asyncForEach(charts, async (chart) => {
    const createdAt = chart.date;
    const createdBy = stafferToNamedDoc(
      chart.practitionerId
        ? await getPractitioner(chart.practitionerId, staff, translationMap)
        : defaultPractitioner
    );

    const conditions = [
      ...(await buildMissingTeeth(
        missingTeeth.filter(
          (missingTooth) =>
            missingTooth.data.data.chart_id?.toString() === chart.id
        ),
        createdBy,
        brand
      )),
      ...(await buildConditions(
        migration,
        sourceConditions.filter(
          (sourceCondition) =>
            sourceCondition.data.data.chart_id?.toString() === chart.id
        ),
        createdBy,
        brand
      )),
      ...(await buildPreExistingTreatments(
        preExistingTreatments.filter(
          (sourcePreExistingTreatment) =>
            sourcePreExistingTreatment.data.data.chart_id?.toString() ===
            chart.id
        ),
        createdBy,
        brand,
        data.existingTreatmentMappings
      )),
    ];

    return {
      ...ClinicalChart.init({
        teeth: MockAllTeeth(),
        conditions,
        createdAt,
        createdBy,
        immutable: true,
      }),
      uid: chart.id,
    };
  });
}

async function buildPerioCharts(
  sourcePerioChartItems: IGetRecordResponse<
    ID4WPatientPerioChart,
    ID4WPatientPerioChartTranslations,
    ID4WPatientPerioChartFilters
  >[],
  staff: WithRef<ITranslationMap<IStaffer>>[],
  translationMap: TranslationMapHandler
): Promise<(IClinicalChart & IIdentifiable)[]> {
  const perioCharts: IPerioChartData[] = [];

  sourcePerioChartItems.map((sourcePerioChartItem) => {
    const perioChartFound = perioCharts.find(
      (perioChart) =>
        perioChart.id === sourcePerioChartItem.data.data.id.toString()
    );
    if (perioChartFound) {
      perioChartFound.data.push(sourcePerioChartItem);
      return;
    }
    perioCharts.push({
      id: sourcePerioChartItem.data.data.id.toString(),
      date: sourcePerioChartItem.data.translations.createdAt,
      patientId: sourcePerioChartItem.record.filters.patientId,
      practitionerId: sourcePerioChartItem.record.filters.providerId,
      data: [sourcePerioChartItem],
    });
  });

  return asyncForEach(perioCharts, async (perioChart) => {
    const createdAt = perioChart.date;
    const perioRecords: IPerioRecord[] = compact(
      reduceToSingleArray(
        perioChart.data.map((perioChartData) => {
          const groupedRecords = groupBy(
            perioChartData.data.data.records,
            (record) =>
              record.is_upper_jaw
                ? record.tooth_row_index_number
                : record.tooth_row_index_number + 16
          );
          return Object.entries(groupedRecords).map(([toothNumber, records]) =>
            buildPerioRecord(toothNumber as ToothNumber, records)
          );
        })
      )
    );

    const createdBy = await getPractitioner(
      perioChart.practitionerId,
      staff,
      translationMap
    );

    return {
      ...ClinicalChart.init({
        perioRecords,
        teeth: MockAllTeeth(),
        createdAt,
        createdBy,
        immutable: true,
      }),
      uid: perioChart.id,
    };
  });
}

async function getPractitioner(
  practitionerId: string,
  staff: WithRef<ITranslationMap<IStaffer>>[],
  translationMap: TranslationMapHandler
): Promise<INamedDocument<IStaffer>> {
  const practitionerMap =
    staff.find((staffer) => staffer.sourceIdentifier === practitionerId)
      ?.destinationIdentifier ||
    ((await translationMap.getBySource(practitionerId, STAFFER_RESOURCE_TYPE))
      ?.destinationIdentifier as DocumentReference<IStaffer> | undefined);

  if (!practitionerMap) {
    throw new Error(`Couldn't find practitioner with id ${practitionerId}`);
  }

  const creator = await getDoc(practitionerMap);
  return stafferToNamedDoc(creator);
}

async function buildMissingTeeth(
  missingTeeth: IGetRecordResponse<
    ID4WPatientMissingTooth,
    ID4WPatientMissingToothTranslations,
    ID4WPatientMissingToothFilters
  >[],
  practitioner: INamedDocument<IStaffer>,
  brand: WithRef<IBrand>
): Promise<IChartedCondition[]> {
  const missingToothCondition = await snapshot(
    find$(
      ConditionConfiguration.col(brand),
      where('name', '==', ConditionConfigurationName.MissingTooth)
    )
  );

  if (!missingToothCondition) {
    throw new Error(`Missing tooth condition is missing`);
  }

  return compact(
    uniqBy(missingTeeth, (missingToothRecord) => {
      const missingTooth = missingToothRecord.data.data;
      const uniqueAttributes = [
        missingTooth.tooth_number,
        missingTooth.is_child,
      ];
      return uniqueAttributes.join('');
    }).map((missingToothRecord) => {
      const chartedAt = missingToothRecord.data.translations.createdAt;
      const missingTooth = missingToothRecord.data.data;
      return {
        ...ChartedCondition.fromConfig(missingToothCondition),
        chartedSurfaces: missingTooth.tooth_number
          ? getChartedRefs(
              d4wMissingToothToISONotation(
                missingTooth.tooth_number.toString()
              ),
              [],
              missingTooth.is_child
            ).map((chartedRef) =>
              ChartedSurface.init({
                chartedBy: practitioner,
                chartedAt,
                chartedRef,
              })
            )
          : [],
      };
    })
  );
}

async function buildConditions(
  migration: WithRef<IPracticeMigration>,
  conditions: IGetRecordResponse<ID4WPatientToothCondition>[],
  practitioner: INamedDocument<IStaffer>,
  brand: WithRef<IBrand>
): Promise<IChartedCondition[]> {
  const builtConditions = await asyncForEach(
    Object.values(
      groupBy(conditions, (conditionRecord) => {
        const condition = conditionRecord.data.data;
        const uniqueAttributes = [
          condition.tooth_number,
          condition.is_baby_tooth,
          condition.condition_type_id,
        ];
        return uniqueAttributes.join('-');
      })
    ),
    async (conditionRecords) => {
      const conditionRecord = first(conditionRecords);
      if (!conditionRecord) {
        return;
      }

      const conditionTypeId = conditionRecord.data.data
        .condition_type_id as D4WCondition;
      const resolvedCondition = D4W_CONDITION_PRINCIPLE_MAP[conditionTypeId];

      const principleCondition = await snapshot(
        find$(
          ConditionConfiguration.col(brand),
          where(
            'name',
            '==',
            resolvedCondition ?? ConditionConfigurationName.Other
          )
        )
      );

      if (!principleCondition) {
        throw new Error(
          `No condition could be resolved for "${D4W_CONDITION_MAP[conditionTypeId]}"`
        );
      }

      const noChartedSurfaces = conditionRecords.every(
        (condition) => !condition.data.data.tooth_number
      );
      const noNotes = conditionRecords.every(
        (condition) => !condition.data.data.notes?.trim()
      );

      if (!noChartedSurfaces && !noNotes) {
        return;
      }

      const chartedSurfaces = conditionRecords.flatMap((condition) =>
        getConditionChartedRef(
          condition.data.data.tooth_number,
          condition.data.data.tooth_surface ?? undefined,
          condition.data.data.is_baby_tooth
        ).map((chartedRef) =>
          ChartedSurface.init({
            chartedBy: practitioner,
            chartedRef,
          })
        )
      );

      const notes = uniqBy(
        conditionRecords,
        (condition) => condition.data.data.notes?.trim()
      )
        .filter((condition) => !!condition.data.data.notes?.trim())
        .map((condition) =>
          ClinicalNote.init({
            owner: practitioner,
            content: initVersionedSchema(
              compact([
                toTextContent(D4W_CONDITION_MAP[conditionTypeId]),
                condition.data.data.notes
                  ? toTextContent(condition.data.data.notes)
                  : undefined,
              ])
            ),
            recordDate: toISODate(
              toMomentTz(
                conditionRecord.data.createdAt,
                migration.configuration.timezone
              )
            ),
          })
        );

      return ChartedCondition.init({
        config: toNamedDocument(principleCondition),
        chartedSurfaces,
        notes,
      });
    }
  );

  return compact(builtConditions);
}

async function buildPreExistingTreatments(
  preExistingTreatments: IGetRecordResponse<
    ID4WPatientPreExistingTreatment,
    unknown,
    ID4WPatientPreExistingTreatmentFilters
  >[],
  practitioner: INamedDocument<IStaffer>,
  brand: WithRef<IBrand>,
  existingTreatmentMappings: WithRef<ITranslationMap<IConditionConfiguration>>[]
): Promise<IChartedCondition[]> {
  const builtTreatments = await asyncForEach(
    Object.values(
      groupBy(preExistingTreatments, (treatmentRecord) => {
        const treatment = treatmentRecord.data.data;
        const uniqueAttributes = [
          treatment.tooth_ref ?? '',
          treatment.is_baby_tooth,
          treatment.item_id,
        ];
        return uniqueAttributes.join('-');
      })
    ),
    async (treatmentRecords) => {
      const treatmentRecord = first(treatmentRecords);
      if (!treatmentRecord) {
        return;
      }

      const itemCodeId = treatmentRecord.data.data.item_id.toString();
      const mappedTreatment = existingTreatmentMappings.find(
        (mapping) => mapping.sourceIdentifier === itemCodeId
      );
      const conditionConfiguration = mappedTreatment?.destinationIdentifier
        ? await getDoc(mappedTreatment?.destinationIdentifier)
        : await snapshot(
            find$(
              ConditionConfiguration.col(brand),
              where('name', '==', ConditionConfigurationName.Other)
            )
          );

      if (!conditionConfiguration) {
        throw new Error(
          `No condition could be resolved for pre-existing treatments`
        );
      }

      const noChartedSurfaces = treatmentRecords.every(
        (treatment) => !treatment.data.data.tooth_ref
      );
      const noNotes = treatmentRecords.every(
        (treatment) => !treatment.data.data.description?.trim()
      );

      if (!noChartedSurfaces && !noNotes) {
        return;
      }

      const chartedSurfaces = treatmentRecords.flatMap((treatment) =>
        buildChartedSurfaces(treatment.data.data, practitioner)
      );

      const notes = uniqBy(
        treatmentRecords,
        (treatment) => treatment.data.data.description?.trim()
      )
        .filter((treatment) => !!treatment.data.data.description?.trim())
        .map((treatment) =>
          ClinicalNote.init({
            owner: practitioner,
            content: initVersionedSchema([
              toTextContent(
                `${treatment.data.data.item_code} - ${treatment.data.data.description}`
              ),
            ]),
            recordDate: toISODate(moment()),
          })
        );

      return ChartedCondition.init({
        config: toNamedDocument(conditionConfiguration),
        chartedSurfaces,
        notes,
      });
    }
  );

  return compact(builtTreatments);
}

function buildChartedSurfaces(
  treatment: ID4WPatientPreExistingTreatment,
  practitioner: INamedDocument<IStaffer>
): IChartedSurface[] {
  if (!treatment.tooth_surface.length) {
    return getConditionChartedRef(
      treatment.tooth_ref,
      undefined,
      treatment.is_baby_tooth
    ).map((chartedRef) =>
      ChartedSurface.init({
        chartedBy: practitioner,
        chartedRef,
      })
    );
  }

  return reduceToSingleArray(
    compact(
      treatment.tooth_surface.map((toothSurface) => {
        const translatedSurface = TOOTH_SURFACES_MAP[toothSurface];
        if (translatedSurface && !isToothSurface(translatedSurface)) {
          return;
        }
        return getConditionChartedRef(
          treatment.tooth_ref,
          translatedSurface,
          treatment.is_baby_tooth
        ).map((chartedRef) =>
          ChartedSurface.init({
            chartedBy: practitioner,
            chartedRef,
          })
        );
      })
    )
  );
}

function buildPerioRecord(
  toothNumber: ToothNumber,
  records: ID4WPatientPerioChartRecord[]
): IPerioRecord | undefined {
  const toothRef = buildToothRef(
    d4wMissingToothToISONotation(toothNumber.toString())
  );

  if (!toothRef) {
    return;
  }

  return {
    toothRef,
    data: {
      [PerioMeasurement.Mobility]: 0,
      [PerioMeasurement.Recession]: {
        facialMesial: determinePerioValue(
          records,
          PerioMeasurement.Recession,
          PerioDataPoint.FacialMesial,
          toothRef
        ),
        facialCentral: determinePerioValue(
          records,
          PerioMeasurement.Recession,
          PerioDataPoint.FacialCentral,
          toothRef
        ),
        facialDistal: determinePerioValue(
          records,
          PerioMeasurement.Recession,
          PerioDataPoint.FacialDistal,
          toothRef
        ),
        palatalMesial: determinePerioValue(
          records,
          PerioMeasurement.Recession,
          PerioDataPoint.PalatalMesial,
          toothRef
        ),
        palatalCentral: determinePerioValue(
          records,
          PerioMeasurement.Recession,
          PerioDataPoint.PalatalCentral,
          toothRef
        ),
        palatalDistal: determinePerioValue(
          records,
          PerioMeasurement.Recession,
          PerioDataPoint.PalatalDistal,
          toothRef
        ),
      },
      [PerioMeasurement.Pocket]: {
        facialMesial: determinePerioValue(
          records,
          PerioMeasurement.Pocket,
          PerioDataPoint.FacialMesial,
          toothRef
        ),
        facialCentral: determinePerioValue(
          records,
          PerioMeasurement.Pocket,
          PerioDataPoint.FacialCentral,
          toothRef
        ),
        facialDistal: determinePerioValue(
          records,
          PerioMeasurement.Pocket,
          PerioDataPoint.FacialDistal,
          toothRef
        ),
        palatalMesial: determinePerioValue(
          records,
          PerioMeasurement.Pocket,
          PerioDataPoint.PalatalMesial,
          toothRef
        ),
        palatalCentral: determinePerioValue(
          records,
          PerioMeasurement.Pocket,
          PerioDataPoint.PalatalCentral,
          toothRef
        ),
        palatalDistal: determinePerioValue(
          records,
          PerioMeasurement.Pocket,
          PerioDataPoint.PalatalDistal,
          toothRef
        ),
      },
      [PerioMeasurement.Bleeding]: {
        facialMesial: determinePerioValue(
          records,
          PerioMeasurement.Bleeding,
          PerioDataPoint.FacialMesial,
          toothRef
        ),
        facialCentral: determinePerioValue(
          records,
          PerioMeasurement.Bleeding,
          PerioDataPoint.FacialCentral,
          toothRef
        ),
        facialDistal: determinePerioValue(
          records,
          PerioMeasurement.Bleeding,
          PerioDataPoint.FacialDistal,
          toothRef
        ),
        palatalMesial: determinePerioValue(
          records,
          PerioMeasurement.Bleeding,
          PerioDataPoint.PalatalMesial,
          toothRef
        ),
        palatalCentral: determinePerioValue(
          records,
          PerioMeasurement.Bleeding,
          PerioDataPoint.PalatalCentral,
          toothRef
        ),
        palatalDistal: determinePerioValue(
          records,
          PerioMeasurement.Bleeding,
          PerioDataPoint.PalatalDistal,
          toothRef
        ),
      },
      [PerioMeasurement.Furcation]: {
        facialMesial: determinePerioValue(
          records,
          PerioMeasurement.Furcation,
          PerioDataPoint.FacialMesial,
          toothRef
        ),
        facialCentral: determinePerioValue(
          records,
          PerioMeasurement.Furcation,
          PerioDataPoint.FacialCentral,
          toothRef
        ),
        facialDistal: determinePerioValue(
          records,
          PerioMeasurement.Furcation,
          PerioDataPoint.FacialDistal,
          toothRef
        ),
        palatalMesial: determinePerioValue(
          records,
          PerioMeasurement.Furcation,
          PerioDataPoint.PalatalMesial,
          toothRef
        ),
        palatalCentral: determinePerioValue(
          records,
          PerioMeasurement.Furcation,
          PerioDataPoint.PalatalCentral,
          toothRef
        ),
        palatalDistal: determinePerioValue(
          records,
          PerioMeasurement.Furcation,
          PerioDataPoint.PalatalDistal,
          toothRef
        ),
      },
      [PerioMeasurement.Suppuration]: {
        facialMesial: determinePerioValue(
          records,
          PerioMeasurement.Suppuration,
          PerioDataPoint.FacialMesial,
          toothRef
        ),
        facialCentral: determinePerioValue(
          records,
          PerioMeasurement.Suppuration,
          PerioDataPoint.FacialCentral,
          toothRef
        ),
        facialDistal: determinePerioValue(
          records,
          PerioMeasurement.Suppuration,
          PerioDataPoint.FacialDistal,
          toothRef
        ),
        palatalMesial: determinePerioValue(
          records,
          PerioMeasurement.Suppuration,
          PerioDataPoint.PalatalMesial,
          toothRef
        ),
        palatalCentral: determinePerioValue(
          records,
          PerioMeasurement.Suppuration,
          PerioDataPoint.PalatalCentral,
          toothRef
        ),
        palatalDistal: determinePerioValue(
          records,
          PerioMeasurement.Suppuration,
          PerioDataPoint.PalatalDistal,
          toothRef
        ),
      },
    },
  };
}

function determinePerioValue(
  records: ID4WPatientPerioChartRecord[],
  measurement: PerioMeasurement,
  measurementPoint: PerioDataPoint,
  toothRef: IToothRef
): number {
  const rightSectionDataPointMap: Record<PerioDataPoint, number> = {
    [PerioDataPoint.FacialDistal]: 1,
    [PerioDataPoint.FacialCentral]: 2,
    [PerioDataPoint.FacialMesial]: 3,
    [PerioDataPoint.PalatalDistal]: 4,
    [PerioDataPoint.PalatalCentral]: 5,
    [PerioDataPoint.PalatalMesial]: 6,
  };

  const leftSectionDataPointMap: Record<PerioDataPoint, number> = {
    [PerioDataPoint.FacialMesial]: 1,
    [PerioDataPoint.FacialCentral]: 2,
    [PerioDataPoint.FacialDistal]: 3,
    [PerioDataPoint.PalatalMesial]: 4,
    [PerioDataPoint.PalatalCentral]: 5,
    [PerioDataPoint.PalatalDistal]: 6,
  };

  const perioFacialMap: Record<PerioMeasurement, number> = {
    [PerioMeasurement.Recession]: -1,
    [PerioMeasurement.MGJ]: 2,
    [PerioMeasurement.Pocket]: 3,
    [PerioMeasurement.Furcation]: 4,
    [PerioMeasurement.Mobility]: 5,
    [PerioMeasurement.Bleeding]: 6,
    [PerioMeasurement.Suppuration]: 7,
  };

  const perioPalatalMap: Record<PerioMeasurement, number> = {
    [PerioMeasurement.Recession]: -1,
    [PerioMeasurement.Furcation]: -1,
    [PerioMeasurement.Mobility]: -1,
    [PerioMeasurement.MGJ]: 2,
    [PerioMeasurement.Pocket]: 3,
    [PerioMeasurement.Bleeding]: 4,
    [PerioMeasurement.Suppuration]: 5,
  };

  const matchingRecord = records.find((record) => {
    const dataPointMapNumber = record.is_facial
      ? record.sub_column_number
      : record.sub_column_number + 3;

    const dataPointMap = [
      Quadrant.AdultUpperRight,
      Quadrant.AdultLowerRight,
      Quadrant.DeciduousUpperRight,
      Quadrant.DeciduousLowerRight,
    ].includes(toothRef.quadrant)
      ? rightSectionDataPointMap
      : leftSectionDataPointMap;

    const isMatchingDataPoint =
      dataPointMap[measurementPoint] === dataPointMapNumber;

    if (!isMatchingDataPoint) {
      return false;
    }

    const referenceMap = record.is_facial ? perioFacialMap : perioPalatalMap;
    const row = referenceMap[measurement];
    if (row !== record.row_number) {
      return false;
    }

    return true;
  });

  if (!matchingRecord) {
    return 0;
  }

  return matchingRecord.p_value;
}

function buildToothRef(toothNumber: ToothNumber | null): IToothRef | undefined {
  const quadrant = toothNumber ? getQuadrant(toothNumber) : undefined;
  const quadrantIndex = toothNumber ? getQuadrantIndex(toothNumber) : undefined;

  if (!quadrant || !quadrantIndex) {
    return;
  }
  return {
    quadrant,
    quadrantIndex,
  };
}
