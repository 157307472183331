import { stafferToNamedDoc } from '@principle-theorem/principle-core';
import {
  IChartedTreatment,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  ISO_TIME_FORMAT,
  Timestamp,
  Timezone,
  WithRef,
  toInt,
  toTimestamp,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import * as moment from 'moment-timezone';
import { IExactTreatment } from '../source/entities/patient-treatments';

export function convertExactId(inputString: string): string {
  const splitter = ':';
  if (inputString.indexOf(splitter) === -1) {
    return inputString;
  }

  const modifiedParts = inputString
    .split(splitter)
    .map((part) => part.replace(/^0+/, ''));

  return compact(modifiedParts).join('-');
}

export const EXACT_DATE_TIME_FORMAT = `${DAY_MONTH_YEAR_FORMAT} ${ISO_TIME_FORMAT}`;

export const EXACT_COMMENT_CODES = ['AUTONOTE', '***', '000', '000*'];

export const EXACT_NON_TREATMENT_CODES = [
  'GST',
  '*GST',
  '**GST',
  '#GST',
  'GST - NH1 - RRD',
  'GST - TECH',
  'W',
  '*N/A',
  ...EXACT_COMMENT_CODES,
];

export function exactPatientIdIsWithinRange(
  patientId: string,
  fromId: string | undefined,
  toId: string | undefined
): boolean {
  if (!fromId || !toId) {
    return true;
  }
  const parsedId = getBasicIdFromExactId(patientId);
  return parsedId >= toInt(fromId) && parsedId <= toInt(toId);
}

export function getBasicIdFromExactId(exactId: string): number {
  return parseInt(
    exactId.indexOf('-') === -1 ? exactId : exactId.split('-')[0],
    10
  );
}

export function getResolvedTreatmentData(
  treatment: IExactTreatment,
  practitioner: WithRef<IStaffer>,
  timezone: Timezone
): Pick<IChartedTreatment, 'resolvedAt' | 'resolvedBy'> | undefined {
  const date = moment.tz(
    treatment.completed_date,
    EXACT_DATE_TIME_FORMAT,
    timezone
  );
  if (date.year() === 1) {
    return;
  }
  return {
    resolvedAt: toTimestamp(date),
    resolvedBy: stafferToNamedDoc(practitioner),
  };
}

/**
 * Returns the current date if the date is set to 01/01/0001 00:00:00 in Exact source data
 * @param date
 * @param timezone
 * @returns Timestamp
 */
export function getExactSourceDate(
  date: string | null,
  timezone: Timezone
): Timestamp {
  const parsedDate = date
    ? moment.tz(date, EXACT_DATE_TIME_FORMAT, timezone)
    : moment.tz(timezone);
  return parsedDate.year() === 1 ? toTimestamp() : toTimestamp(parsedDate);
}
