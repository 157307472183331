<div class="layout-padding practitioner-income-spacer">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h2>Select a practitioner</h2>
    <pt-download-report
      [data]="reportFacade.results$ | async"
      [dateRange]="dateRange$ | async"
      [csvTranslator]="csvTranslator$ | async"
    />
  </div>
</div>

<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="practitioner">
    <th mat-header-cell *matHeaderCellDef>Practitioner</th>
    <td mat-cell *matCellDef="let record">
      <a href="javascript:void(0);" (click)="reportFacade.inspect(record)">
        {{ record.practitioner.name }}
      </a>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="depositsTakenAmount">
    <th mat-header-cell *matHeaderCellDef>
      <pr-value-with-icon>Deposits</pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record">
      <pr-value-with-icon>
        {{ record.total.depositsTakenAmount | currency }}
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon>
        <strong>{{
          'depositsTakenAmount' | map: sumAmount$ : this | async | currency
        }}</strong>
      </pr-value-with-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="creditsAppliedAmount">
    <th mat-header-cell *matHeaderCellDef>
      <pr-value-with-icon>Credits</pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record">
      <pr-value-with-icon>
        {{ record.total.creditsAppliedAmount | currency }}
        <mat-icon
          *ngIf="record.total.creditsAppliedWarning"
          [matTooltip]="tooltips.creditsAppliedWarning"
        >
          info
        </mat-icon>
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon>
        <strong>{{
          'creditsAppliedAmount' | map: sumAmount$ : this | async | currency
        }}</strong>
      </pr-value-with-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="discountsAppliedAmount">
    <th mat-header-cell *matHeaderCellDef>
      <pr-value-with-icon>Discounts</pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record">
      <pr-value-with-icon>
        {{ record.total.discountsAppliedAmount | currency }}
        <mat-icon
          *ngIf="record.total.discountsAppliedWarning"
          [matTooltip]="tooltips.discountsAppliedWarning"
        >
          info
        </mat-icon>
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon>
        <strong>{{
          'discountsAppliedAmount' | map: sumAmount$ : this | async | currency
        }}</strong>
      </pr-value-with-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="treatmentsInvoicedAmount">
    <th mat-header-cell *matHeaderCellDef>
      <pr-value-with-icon>
        Treatment Invoiced
        <mat-icon [matTooltip]="tooltips.treatmentsInvoicedHelp">
          help
        </mat-icon>
      </pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record">
      <pr-value-with-icon>
        {{ record.total.treatmentsInvoicedAmount | currency }}
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon>
        <strong>{{
          'treatmentsInvoicedAmount' | map: sumAmount$ : this | async | currency
        }}</strong>
      </pr-value-with-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="invoicedCommissionAmount">
    <th mat-header-cell *matHeaderCellDef>
      <pr-value-with-icon>
        Recievable Amount
        <mat-icon [matTooltip]="tooltips.invoicedCommissionHelp">
          help
        </mat-icon>
      </pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record">
      <pr-value-with-icon>
        {{ record.total.invoicedCommissionAmount | currency }}
        <mat-icon
          *ngIf="record.total.invoicedCommissionWarning"
          [matTooltip]="tooltips.invoicedCommissionWarning"
        >
          info
        </mat-icon>
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon>
        <strong>{{
          'invoicedCommissionAmount' | map: sumAmount$ : this | async | currency
        }}</strong>
      </pr-value-with-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="receiptedCommissionAmount">
    <th mat-header-cell *matHeaderCellDef>
      <pr-value-with-icon>
        Receipted Amount
        <mat-icon [matTooltip]="tooltips.receiptedCommissionHelp">
          help
        </mat-icon>
      </pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record">
      <pr-value-with-icon>
        {{ record.total.receiptedCommissionAmount | currency }}
        <mat-icon
          *ngIf="record.total.receiptedCommissionWarning"
          [matTooltip]="tooltips.receiptedCommissionWarning"
        >
          info
        </mat-icon>
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon>
        <strong>{{
          'receiptedCommissionAmount'
            | map: sumAmount$ : this
            | async
            | currency
        }}</strong>
      </pr-value-with-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns$ | async"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns$ | async"></tr>
</table>
