import { createSelector } from '@ngrx/store';
import { getChartModuleState } from '../../selectors';
import { getSelectedTreatmentPlansStepIds } from './treatment-plans.selectors';
import { getTreatmentStepEntities } from './treatment-steps.selectors';

export const getTreatmentPlanningState = createSelector(
  getChartModuleState,
  (chart) => chart.treatmentPlanning
);

export const getSelectedTreatmentPlansTreatmentSteps = createSelector(
  getSelectedTreatmentPlansStepIds,
  getTreatmentStepEntities,
  (ids, treatmentStepEntities) => ids.map((id) => treatmentStepEntities[id])
);
