import {
  getQuadrant,
  getQuadrantIndex,
} from '@principle-theorem/principle-core';
import {
  ChartableSurface,
  IChartedTooth,
  IToothRef,
  type ToothNumber,
} from '@principle-theorem/principle-core/interfaces';
import { isEnumValue } from '@principle-theorem/shared';
import { compact } from 'lodash';

export type ExactToothNumber =
  | 'LL1'
  | 'LL2'
  | 'LL3'
  | 'LL4'
  | 'LL5'
  | 'LL6'
  | 'LL7'
  | 'LL8'
  | 'LLA'
  | 'LLB'
  | 'LLC'
  | 'LLD'
  | 'LLE'
  | 'LR1'
  | 'LR2'
  | 'LR3'
  | 'LR4'
  | 'LR5'
  | 'LR6'
  | 'LR7'
  | 'LR8'
  | 'LRA'
  | 'LRB'
  | 'LRC'
  | 'LRD'
  | 'LRE'
  | 'UL1'
  | 'UL2'
  | 'UL3'
  | 'UL4'
  | 'UL5'
  | 'UL6'
  | 'UL7'
  | 'UL8'
  | 'ULA'
  | 'ULB'
  | 'ULC'
  | 'ULD'
  | 'ULE'
  | 'UR1'
  | 'UR2'
  | 'UR3'
  | 'UR4'
  | 'UR5'
  | 'UR6'
  | 'UR7'
  | 'UR8'
  | 'URA'
  | 'URB'
  | 'URC'
  | 'URD'
  | 'URE';

export function exactToothConverter(tooth: string): ToothNumber | undefined {
  const conversions: Record<ExactToothNumber, ToothNumber> = {
    LL1: '31',
    LL2: '32',
    LL3: '33',
    LL4: '34',
    LL5: '35',
    LL6: '36',
    LL7: '37',
    LL8: '38',
    LLA: '71',
    LLB: '72',
    LLC: '73',
    LLD: '74',
    LLE: '75',
    LR1: '41',
    LR2: '42',
    LR3: '43',
    LR4: '44',
    LR5: '45',
    LR6: '46',
    LR7: '47',
    LR8: '48',
    LRA: '81',
    LRB: '82',
    LRC: '83',
    LRD: '84',
    LRE: '85',
    UL1: '21',
    UL2: '22',
    UL3: '23',
    UL4: '24',
    UL5: '25',
    UL6: '26',
    UL7: '27',
    UL8: '28',
    ULA: '61',
    ULB: '62',
    ULC: '63',
    ULD: '64',
    ULE: '65',
    UR1: '11',
    UR2: '12',
    UR3: '13',
    UR4: '14',
    UR5: '15',
    UR6: '16',
    UR7: '17',
    UR8: '18',
    URA: '51',
    URB: '52',
    URC: '53',
    URD: '54',
    URE: '55',
  };

  return conversions[tooth as ExactToothNumber];
}

export function toothRangeConverter(toothRange: string): ToothNumber[] {
  const teeth = toothRange.split(',');
  return compact(teeth.map((tooth) => exactToothConverter(tooth)));
}

export function toothSurfacesConverter(
  toothSurfaces: string
): ChartableSurface[] {
  if (toothSurfaces === 'None') {
    return [];
  }

  const exactSurfaces = toothSurfaces.split(',');
  return exactSurfaces
    .map((exactSurface) =>
      exactSurface.split(/(?=[A-Z])/).map((word) => word.toLowerCase())
    )
    .reduce((surfaces: ChartableSurface[], splitSurfaces) => {
      splitSurfaces.map((surface) => {
        if (surface === 'top') {
          surface = ChartableSurface.Occlusal;
        }
        if (
          !isEnumValue(ChartableSurface, surface) ||
          surfaces.includes(surface)
        ) {
          return;
        }
        surfaces.push(surface);
      });

      return surfaces;
    }, []);
}

export type ExactPerioToothLabel =
  | 'PermanentUR_3rdMolar'
  | 'PermanentUR_2ndMolar'
  | 'PermanentUR_1stMolar'
  | 'PermanentUR_2ndBicuspid'
  | 'PermanentUR_1stBicuspid'
  | 'PermanentUR_Cuspid'
  | 'PermanentUR_LateralIncisor'
  | 'PermanentUR_CentralIncisor'
  | 'PermanentUL_CentralIncisor'
  | 'PermanentUL_LateralIncisor'
  | 'PermanentUL_Cuspid'
  | 'PermanentUL_1stBicuspid'
  | 'PermanentUL_2ndBicuspid'
  | 'PermanentUL_1stMolar'
  | 'PermanentUL_2ndMolar'
  | 'PermanentUL_3rdMolar'
  | 'PermanentLL_3rdMolar'
  | 'PermanentLL_2ndMolar'
  | 'PermanentLL_1stMolar'
  | 'PermanentLL_2ndBicuspid'
  | 'PermanentLL_1stBicuspid'
  | 'PermanentLL_Cuspid'
  | 'PermanentLL_LateralIncisor'
  | 'PermanentLL_CentralIncisor'
  | 'PermanentLR_CentralIncisor'
  | 'PermanentLR_LateralIncisor'
  | 'PermanentLR_Cuspid'
  | 'PermanentLR_1stBicuspid'
  | 'PermanentLR_2ndBicuspid'
  | 'PermanentLR_1stMolar'
  | 'PermanentLR_2ndMolar'
  | 'PermanentLR_3rdMolar';

export function exactPerioToothLabelConverter(tooth: string): ToothNumber {
  const conversions: Record<ExactPerioToothLabel, ToothNumber> = {
    PermanentUR_3rdMolar: '18',
    PermanentUR_2ndMolar: '17',
    PermanentUR_1stMolar: '16',
    PermanentUR_2ndBicuspid: '15',
    PermanentUR_1stBicuspid: '14',
    PermanentUR_Cuspid: '13',
    PermanentUR_LateralIncisor: '12',
    PermanentUR_CentralIncisor: '11',
    PermanentUL_CentralIncisor: '21',
    PermanentUL_LateralIncisor: '22',
    PermanentUL_Cuspid: '23',
    PermanentUL_1stBicuspid: '24',
    PermanentUL_2ndBicuspid: '25',
    PermanentUL_1stMolar: '26',
    PermanentUL_2ndMolar: '27',
    PermanentUL_3rdMolar: '28',
    PermanentLL_3rdMolar: '38',
    PermanentLL_2ndMolar: '37',
    PermanentLL_1stMolar: '36',
    PermanentLL_2ndBicuspid: '35',
    PermanentLL_1stBicuspid: '34',
    PermanentLL_Cuspid: '33',
    PermanentLL_LateralIncisor: '32',
    PermanentLL_CentralIncisor: '31',
    PermanentLR_CentralIncisor: '41',
    PermanentLR_LateralIncisor: '42',
    PermanentLR_Cuspid: '43',
    PermanentLR_1stBicuspid: '44',
    PermanentLR_2ndBicuspid: '45',
    PermanentLR_1stMolar: '46',
    PermanentLR_2ndMolar: '47',
    PermanentLR_3rdMolar: '48',
  };

  try {
    return conversions[tooth as ExactPerioToothLabel];
  } catch (error) {
    throw new Error(`Invalid Exact Perio tooth number: ${tooth}`);
  }
}

export function buildToothRef(
  toothNumber: ToothNumber | null
): IToothRef | undefined {
  const quadrant = toothNumber ? getQuadrant(toothNumber) : undefined;
  const quadrantIndex = toothNumber ? getQuadrantIndex(toothNumber) : undefined;

  if (!quadrant || !quadrantIndex) {
    return;
  }
  return {
    quadrant,
    quadrantIndex,
  };
}

export function getExactChartedRefs(
  toothNumber: ToothNumber | null,
  surfaces: ChartableSurface[]
): (
  | { unscoped: true }
  | { tooth: IToothRef }
  | { tooth: Required<IChartedTooth> }
)[] {
  const tooth = buildToothRef(toothNumber);
  if (!tooth) {
    return [{ unscoped: true }];
  }

  if (!surfaces.length) {
    return [{ tooth }];
  }

  return surfaces.map((surface) => ({
    tooth: { ...tooth, surface },
  }));
}
