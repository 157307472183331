import { ITeam } from '@principle-theorem/principle-core/interfaces';
import { initFirestoreModel } from '@principle-theorem/shared';

export function MockTeam(name?: string): ITeam {
  const team: ITeam = {
    name: '',
    ...initFirestoreModel(),
  };
  if (name) {
    team.name = name;
  }
  return team;
}

export const TEAMS = [
  'Dental Assistants',
  'Dentists',
  'Front Office Coordinators',
  'Hygienists/OHTs',
  'Management',
].map((name) => MockTeam(name));
