import {
  IParticipant,
  IParticipantAvatar,
  IPatient,
  IStaffer,
  ParticipantType,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  asDocRef,
  snapshot,
} from '@principle-theorem/shared';
import { switchMap } from 'rxjs/operators';
import { OrganisationCache } from '../organisation/organisation-cache';
import { Staffer } from '../staffer/staffer';

export class ParticipantAvatarFactory {
  async create(participant: IParticipant): Promise<IParticipantAvatar> {
    return {
      ...participant,
      profileImageURL: await this._resolveAvatar(participant),
    };
  }

  private async _resolveAvatar(
    particpant: IParticipant
  ): Promise<string | undefined> {
    switch (particpant.type) {
      case ParticipantType.Patient:
        return this._resolvePatientAvatar(asDocRef<IPatient>(particpant.ref));
      default:
        return this._resolveStafferAvatar(asDocRef<IStaffer>(particpant.ref));
    }
  }

  private async _resolvePatientAvatar(
    ref: DocumentReference<IPatient>
  ): Promise<string | undefined> {
    const patient = await Firestore.getDoc(ref);

    return patient.profileImageURL;
  }

  private async _resolveStafferAvatar(
    ref: DocumentReference<IStaffer>
  ): Promise<string | undefined> {
    const user = await snapshot(
      OrganisationCache.staff.get
        .doc$(ref)
        .pipe(switchMap((staffer) => Staffer.user$(staffer)))
    );

    return user.profileImageURL;
  }
}
