import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'pr-chart-clear-selection-button',
  templateUrl: './chart-clear-selection-button.component.html',
  styleUrls: ['./chart-clear-selection-button.component.sass'],
})
export class ChartClearSelectionButtonComponent {
  @Output() clearSelection: EventEmitter<void> = new EventEmitter<void>();
}
