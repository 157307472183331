import { createSelector } from '@ngrx/store';
import { appointmentSuggestionsAdapter } from '../reducers/appointment-suggestions.reducer';
import { getAppointmentSchedulingModuleState } from './appointment-scheduling.selectors';

const { selectAll, selectEntities } =
  appointmentSuggestionsAdapter.getSelectors();

export const getAppointmentSuggestionsState = createSelector(
  getAppointmentSchedulingModuleState,
  (state) => state.appointmentSuggestions
);

export const getSuggestionsLoaded = createSelector(
  getAppointmentSuggestionsState,
  (state) => state.loaded
);

export const getSuggestionSearchType = createSelector(
  getAppointmentSuggestionsState,
  (state) => state.searchType
);

export const getSuggestions = createSelector(
  getAppointmentSuggestionsState,
  selectAll
);

export const getSuggestionEntities = createSelector(
  getAppointmentSuggestionsState,
  selectEntities
);

export const getSelectedSuggestion = createSelector(
  getAppointmentSuggestionsState,
  (state) => state.selectedSuggestion
);

export const getAvailableTimes = createSelector(
  getAppointmentSuggestionsState,
  (state) => state.availableTimes
);

export const getGapCandidates = createSelector(
  getAppointmentSuggestionsState,
  (state) => state.gapCandidates
);

export const getCalendarEvents = createSelector(
  getAppointmentSuggestionsState,
  (state) => state.calendarEvents
);
