import {
  type IGetRecordResponse,
  PatientStatus,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import {
  BaseExistingPatientDestinationEntity,
  type IExistingPatientMergeConflictSummary,
} from '../../../destination/entities/existing-patient';
import {
  IExactPatientTranslations,
  PatientSourceEntity,
  type IExactPatient,
} from '../../source/entities/patient';
import { getContactNumbers, getGender, getName } from './patient';

export class ExistingPatientDestinationEntity extends BaseExistingPatientDestinationEntity<IExactPatient> {
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: this.patientSourceEntity,
  };

  getDateOfBirth(
    sourcePatient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>
  ): string | undefined {
    return sourcePatient.data.translations.dateOfBirth;
  }

  filterMatchingPatients(
    sourcePatient: IExactPatient,
    matchingPatients: WithRef<IPatient>[]
  ): WithRef<IPatient>[] {
    return matchingPatients.filter(
      (patient) =>
        [sourcePatient.first_name ?? '', sourcePatient.last_name ?? ''].every(
          (name) => patient.name.toLowerCase().includes(name.toLowerCase())
        ) && patient.status === PatientStatus.Active
    );
  }

  buildConflictSummary(
    sourcePatient: IExactPatient
  ): Omit<IExistingPatientMergeConflictSummary, 'status' | 'patientRefs'> {
    const sourcePatientId = this.patientSourceEntity
      .getSourceRecordId(sourcePatient)
      .toString();

    return {
      sourcePatientId,
      patientName: getName(sourcePatient),
      dateOfBirth: sourcePatient.date_of_birth ?? '',
      gender: getGender(sourcePatient),
      phone: getContactNumbers(sourcePatient),
      email: sourcePatient.email ?? '',
    };
  }
}
