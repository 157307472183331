<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
  <div fxLayout="column" class="time" *ngIf="start$ | ngrxPush as start">
    <p class="mat-caption">
      {{ start | amDateFormat: dateFormat }}
      <ng-container *ngIf="end$ | ngrxPush as end">
        - {{ end | amDateFormat: dateFormat }}</ng-container
      >
    </p>

    <p class="mat-caption" *ngIf="duration$ | ngrxPush as duration">
      Duration: {{ duration }}
    </p>

    <p class="mat-caption">
      <ng-content select="pr-eventable-next-stage"></ng-content>
    </p>
  </div>

  <ng-content select="[prEventableHeaderIcon]"></ng-content>

  <div fxFlex fxLayout="column">
    <ng-container *ngIf="titleDirective; else defaultTitle">
      <ng-content select="[prEventableHeaderTitle]"></ng-content>
    </ng-container>
    <ng-template #defaultTitle>
      <h3 class="mat-headline-6">{{ title }}</h3>
    </ng-template>

    <p class="mat-body-2">
      <ng-content></ng-content>
    </p>
  </div>
</div>
