import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { AccountNotificationsComponent } from './account-notifications/account-notifications.component';
import { AccountModule } from './account.module';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'notifications',
        component: AccountNotificationsComponent,
        data: { titleSuffix: 'Notifications' },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), AccountModule],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
