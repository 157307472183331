import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  ChecklistItemAction,
  type IChecklistItem,
} from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-checklist-items',
  templateUrl: './checklist-items.component.html',
  styleUrls: ['./checklist-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistItemsComponent {
  trackByChecklistItem = TrackByFunctions.title<IChecklistItem>();
  checklist$ = new ReplaySubject<IChecklistItem[]>(1);
  @Output() updateItem = new EventEmitter<IChecklistItem>();
  @Output() deleteItem = new EventEmitter<IChecklistItem>();

  @Input()
  set checklist(checklist: IChecklistItem[]) {
    if (checklist) {
      this.checklist$.next(checklist);
    }
  }

  useCustomIcon(item: IChecklistItem): boolean {
    if (item.action !== ChecklistItemAction.None) {
      return true;
    }
    return false;
  }
}
