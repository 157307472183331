import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { Invoice } from '@principle-theorem/principle-core';
import {
  IInvoice,
  IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { isWithRef, snapshot, WithRef } from '@principle-theorem/shared';
import {
  HealthFundMemberNumberDialogComponent,
  IHealthFundMemberNumberDialogData,
} from './health-fund-member-number-dialog.component';

@Injectable()
export class HealthFundMemberNumberDialogService {
  constructor(private _dialog: MatDialog) {}

  async open(
    invoice: IInvoice | WithRef<IInvoice>,
    patient?: WithRef<IPatient>
  ): Promise<IHealthFundMemberNumberDialogData | undefined> {
    const resolvedPatient = isWithRef<IInvoice>(invoice)
      ? await snapshot(Invoice.patient$(invoice))
      : patient;
    return this._dialog
      .open<
        HealthFundMemberNumberDialogComponent,
        IPatient,
        IHealthFundMemberNumberDialogData
      >(
        HealthFundMemberNumberDialogComponent,
        DialogPresets.medium({ data: resolvedPatient, autoFocus: true })
      )
      .afterClosed()
      .toPromise();
  }
}
