import { createAction, props } from '@ngrx/store';
import { type SerialisedData } from '@principle-theorem/shared';
import { type IWaitListDetails } from '@principle-theorem/principle-core';

enum WaitListDetails {
  Reset = '[Wait List Details] Reset',
  SetValue = '[Wait List Details] Set Value',
  ValueChange = '[Wait List Details] Value Change',
}

interface ISetWaitListDetails {
  waitlistDetails: IWaitListDetails;
}

interface IUpdateWaitListDetails {
  update: Partial<IWaitListDetails>;
}

export const reset = createAction(WaitListDetails.Reset);

export const setWaitListDetails = createAction(
  WaitListDetails.SetValue,
  props<SerialisedData<ISetWaitListDetails>>()
);

export const waitlistDetailsChange = createAction(
  WaitListDetails.ValueChange,
  props<SerialisedData<IUpdateWaitListDetails>>()
);
