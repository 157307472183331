<div
  class="mx-auto mt-8 grid w-full max-w-5xl grid-cols-1 gap-4 lg:grid-cols-2"
>
  @if (confirmAppointment.loading()) {
    <div class="rounded-lg bg-white shadow-lg lg:col-span-2">
      <pr-patient-portal-loader>Loading Appointment</pr-patient-portal-loader>
    </div>
  }

  @if (confirmAppointment.errorMessage()) {
    <div class="rounded-lg bg-white shadow-lg lg:col-span-2">
      <pr-empty-state
        image="broken"
        title="appointment found"
        [descriptionEnabled]="false"
      />
    </div>
  }

  @if (confirmAppointment.tokenData(); as info) {
    <div class="rounded-lg bg-white shadow-lg lg:col-span-2">
      <pr-patient-portal-splash type="success">
        Appointment Confirmed
      </pr-patient-portal-splash>
    </div>

    <div class="col-span-1 flex flex-col gap-4">
      <pr-patient-confirm-appointment-details
        class="rounded-lg bg-white p-4 shadow-lg"
        [info]="info"
      />

      <div class="flex grow flex-col gap-4 rounded-lg bg-white p-4 shadow-lg">
        <div class="flex flex-col">
          <h3 class="!m-0 !font-bold">Forms to complete</h3>
          <div class="text-xs opacity-50">
            Please fill out the following forms before you attend your
            appointment:
          </div>
        </div>
        <pr-patient-forms-list />
      </div>
    </div>

    <pr-patient-confirm-appointment-location
      class="col-span-1 grow rounded-lg bg-white p-4 shadow-lg"
      [info]="info"
    />
  }
</div>
