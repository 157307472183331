<div
  class="editor editor-input-container"
  role="group"
  [attr.aria-describedby]="userAriaDescribedBy"
  [attr.aria-labelledby]="parentFormField?.getLabelId()"
>
  <tiptap-editor
    fxFlex
    class="editor-content"
    outputFormat="json"
    [editor]="editor"
    [formControl]="editorCtrl"
  />
</div>
