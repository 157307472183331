import { Injectable } from '@angular/core';
import {
  asDocRef,
  doc$,
  filterUndefined,
  type IReffable,
  shareReplayCold,
} from '@principle-theorem/shared';
import { type Observable, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { BaseCurrentModel } from './base-current-model';

@Injectable()
export abstract class BaseCurrentFirestoreModel<
  Model extends IReffable<unknown>,
> extends BaseCurrentModel<Model> {
  readonly doc$ = this.asObservable$().pipe(shareReplayCold());

  /**
   * Returns current model as Observable, skipping undefined values
   *
   * @deprecated use asObservable$ instead
   */
  override asObservable(): Observable<Model> {
    return this.model$.pipe(
      filterUndefined(),
      switchMap((model: Model) => doc$(asDocRef<Model>(model.ref))),
      startWith(this.model$.value),
      filterUndefined()
    );
  }

  override asObservable$(): Observable<Model | undefined> {
    return this.model$.pipe(
      switchMap((model) => {
        return model ? doc$(asDocRef<Model>(model.ref)) : of(undefined);
      })
    );
  }
}
