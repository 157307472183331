import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IChat } from '@principle-theorem/principle-core/interfaces';
import { DocumentReference } from '@principle-theorem/shared';

@Component({
    selector: 'pr-chats-dashboard',
    templateUrl: './chats-dashboard.component.html',
    styleUrls: ['./chats-dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChatsDashboardComponent {
  constructor(
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  async openChat(chatRef: DocumentReference<IChat>): Promise<void> {
    await this._router.navigate(['./', chatRef.id], {
      relativeTo: this._route,
    });
  }
}
