import {
  IExpectedSourceRecordSize,
  SourceEntityMigrationType,
  type IPracticeMigration,
  type ISourceEntity,
  ServiceCodeType,
} from '@principle-theorem/principle-core/interfaces';
import {
  TypeGuard,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import {
  compact,
  flow,
  get,
  groupBy,
  isBoolean,
  isNumber,
  isString,
} from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { runQuery } from '../../../source/connection';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
} from '../../../source/source-helpers';
import { ServiceProviderHandler } from '@principle-theorem/principle-core';
import { FEE_SCHEDULE_DESTINATION_ENTITY } from '../../../destination/entities/fee-schedules';
import { FEE_SCHEDULE_RESOURCE_TYPE } from '../../../mappings/fee-schedules';

export const FEE_SCHEDULE_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Fee Schedule List',
    description: '',
    idPrefix: FEE_SCHEDULE_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

interface IOasisFeeScheduleResult {
  id: number;
  name: string;
  preferredFeeScheduleCodes: string[];
  codeId: string;
  description: string;
  descriptionExtended?: string;
  fee1?: number;
  fee2?: number;
  fee3?: number;
  fee4?: number;
  fee5?: number;
  fee6?: number;
  fee7?: number;
  fee8?: number;
  fee9?: number;
  rootItemCode?: string;
  hasGst: boolean;
  isDiscount: boolean;
}

export interface IOasisFeeSchedule {
  id: number;
  name: string;
  preferredFeeScheduleCodes: string[];
  items: IOasisFeeScheduleItem[];
}

export function isOasisFeeSchedule(item: unknown): item is IOasisFeeSchedule {
  return TypeGuard.interface<IOasisFeeSchedule>({
    id: isNumber,
    name: isString,
    preferredFeeScheduleCodes: TypeGuard.arrayOf(isString),
    items: TypeGuard.arrayOf(isOasisFeeScheduleItem),
  })(item);
}

export interface IOasisFeeScheduleItem {
  itemId: string;
  code: string;
  price?: number;
  hasGST: boolean;
}

export function isOasisFeeScheduleItem(
  item: unknown
): item is IOasisFeeScheduleItem {
  return TypeGuard.interface<IOasisFeeScheduleItem>({
    itemId: isString,
    code: isString,
    price: TypeGuard.nilOr(isNumber),
    hasGST: isBoolean,
  })(item);
}

const OASIS_FEE_SCHEDULE_SOURCE_QUERY = `
SELECT fee_schedule.*, code.* FROM (
	SELECT
		convert_to_integer(REGEXP_REPLACE(SKEY, 'ITEMFDEHE', '')) AS id,
		F1 AS name,
		ARRAY(
			SELECT
				REGEXP_REPLACE(SKEY, 'CLASSIFYE', '')
			FROM SYTBLENT AS aliases
			WHERE
				aliases.F2 = REGEXP_REPLACE(schedule.SKEY, 'ITEMFDEHE', '')
				AND SKEY LIKE 'CLASSIFYE%'
    ) as preferred_fee_schedule_codes
	FROM SYTBLENT AS schedule
	WHERE SKEY LIKE 'ITEMFDEHE%'
) AS fee_schedule
LEFT JOIN (
  SELECT
    REGEXP_REPLACE(SKEY, 'ITEMNUMBE', '') AS code_id,
    F1 AS description,
    NULLIF(F2, '') AS description_extended,
    F5 AS fee_1,
    F6 AS fee_2,
    F7 AS fee_3,
    F8 AS fee_4,
    F9 AS fee_5,
    F10 AS fee_6,
    F11 AS fee_7,
    F12 AS fee_8,
    F13 AS fee_9,
    F14 AS root_item_code,
    convert_to_boolean(F21) AS has_gst,
    convert_to_boolean(F22) AS is_discount
  FROM SYTBLENT
  WHERE SKEY LIKE 'ITEMNUMBE%'
) AS code
ON code.code_id IS NOT NULL
`;

const OASIS_FEE_SCHEDULE_ESTIMATE_QUERY = `
SELECT fee_schedule.* FROM (
	SELECT
		convert_to_integer(REGEXP_REPLACE(SKEY, 'ITEMFDEHE', '')) AS id,
		F1 AS name
	FROM SYTBLENT
	WHERE SKEY LIKE 'ITEMFDEHE%'
) AS fee_schedule
`;

export class FeeScheduleSourceEntity extends BaseSourceEntity<IOasisFeeSchedule> {
  sourceEntity = FEE_SCHEDULE_SOURCE_ENTITY;
  entityResourceType = FEE_SCHEDULE_RESOURCE_TYPE;
  sourceQuery = OASIS_FEE_SCHEDULE_SOURCE_QUERY;
  verifySourceFn = isOasisFeeSchedule;

  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    transformFeeScheduleResults,
  ]);

  migrationDestinations = [FEE_SCHEDULE_DESTINATION_ENTITY.metadata.key];

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const response = await runQuery<IOasisFeeScheduleResult>(
      migration,
      OASIS_FEE_SCHEDULE_ESTIMATE_QUERY
    );

    return {
      expectedSize: response.rows.length,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }

  translate(_staffer: IOasisFeeSchedule): object {
    return {};
  }

  getSourceRecordId(data: Pick<IOasisFeeSchedule, 'id'>): string {
    return `${data.id}`;
  }

  getSourceLabel(data: IOasisFeeSchedule): string {
    return data.name;
  }
}

function transformFeeScheduleResults(
  feeScheduleResults: IOasisFeeScheduleResult[]
): IOasisFeeSchedule[] {
  const feeScheduleGroups = groupBy(feeScheduleResults, (result) => result.id);
  return Object.values(feeScheduleGroups).map((items) => {
    return {
      id: items[0].id,
      name: items[0].name,
      preferredFeeScheduleCodes: items[0].preferredFeeScheduleCodes,
      items: compact(
        items.map((item) => {
          const code = ServiceProviderHandler.resolveServiceCode(
            ServiceCodeType.ADA,
            item.codeId
          )?.code.toString();

          if (!code) {
            return;
          }

          const price = parseFloat(String(get(item, `fee${item.id}`, 0))) || 0;

          return {
            itemId: item.codeId,
            code,
            price,
            hasGST: item.hasGst,
          };
        })
      ),
    };
  });
}
