<mat-toolbar color="accent">Select Gap Time Range</mat-toolbar>
<form>
  <mat-dialog-content fxLayout="column">
    <mat-form-field>
      <mat-select
        placeholder="What part of the gap do you want to offer?"
        [required]="true"
        [(value)]="selectedOption"
      >
        <mat-option
          *ngFor="
            let appointmentOption of appointmentOptions$ | async;
            trackBy: trackByAppointmentOption
          "
          [value]="appointmentOption"
        >
          {{ appointmentOption.from | amDateFormat : timeFormat }} -
          {{ appointmentOption.to | amDateFormat : timeFormat }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" (click)="save()">Save</button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
