import {
  Colour,
  type IFirestoreModel,
  type INamedDocument,
  isEnumValue,
  isObject,
  type WithRef,
} from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import type * as moment from 'moment-timezone';
import { type IClinicalChart } from '../clinical-charting/clinical-chart';
import { type ITreatmentPlan } from '../clinical-charting/treatment/treatment-plan';
import {
  type ITreatmentStep,
  type ITreatmentStepDisplay,
} from '../clinical-charting/treatment/treatment-step';
import { type IStatusHistory } from '../common';
import { EventType, type IEvent, type IEventHistory } from '../event/event';
import { type IWaitListItem } from '../gap/wait-list-item';
import { type IInvoice } from '../invoice/invoice';
import { type IPractice } from '../practice/practice';
import { type IStaffer } from '../staffer/staffer';
import { type ITag } from '../tag/tag';
import { type IAppointmentDependency } from './appointment-dependency';
import { type IAppointmentRequest } from './appointment-request';
import { type ICancellation } from './cancellation';
import { type IFollowUp } from './follow-up';

export enum AppointmentAction {
  Scheduled = 'scheduled',
  Rescheduled = 'rescheduled',
  WaitListRescheduled = 'waitListRescheduled',
  Cancelled = 'cancelled',
  Confirmed = 'confirmed',
  Started = 'started',
  Arrived = 'arrived',
  CheckedIn = 'checkedIn',
  CheckingOut = 'checkingOut',
  Completed = 'completed',
  Unknown = 'unknown',
}

export enum AppointmentStatus {
  Unscheduled = 'unscheduled',
  Scheduled = 'scheduled',
  Confirmed = 'confirmed',
  Arrived = 'arrived',
  CheckedIn = 'checkedIn',
  InProgress = 'inProgress',
  CheckingOut = 'checkingOut',
  Complete = 'complete',
  Cancelled = 'cancelled',
}

export interface IAppointment extends IFirestoreModel {
  event?: IEvent;
  eventHistory: IEventHistory[];
  status: AppointmentStatus;
  statusHistory: IStatusHistory<AppointmentStatus>[];
  practitioner: INamedDocument<IStaffer>;
  treatmentPlan: IAssociatedTreatment;
  waitListItem?: IWaitListItem;
  cancellationHistory: ICancellation[];
  dependencies: IAppointmentDependency[];
  deleted: boolean;
  clinicalChart?: DocumentReference<IClinicalChart>;
  invoiceRef?: DocumentReference<IInvoice>;
  activeFollowUp?: WithRef<IFollowUp>;
  practice: INamedDocument<IPractice>;
  tags: INamedDocument<ITag>[];
  appointmentRequestRef?: DocumentReference<IAppointmentRequest>;
}

export function isAppointmentEventType(eventType: EventType): boolean {
  return [EventType.Appointment].includes(eventType);
}

export function isAppointment(item: unknown): item is IAppointment {
  return (
    isObject(item) &&
    'status' in item &&
    isEnumValue(AppointmentStatus, item.status)
  );
}

export interface IAssociatedTreatmentStep
  extends INamedDocument<ITreatmentStep> {
  duration: number;
  display: ITreatmentStepDisplay;
}

export interface IAssociatedTreatment extends INamedDocument<ITreatmentPlan> {
  treatmentStep: IAssociatedTreatmentStep;
}

export enum AppointmentCollection {
  FollowUps = 'followUps',
  Checklists = 'checklists',
  Interactions = 'appointmentInteractions',
  SchedulingEvents = 'schedulingEvents',
}

export const APPOINTMENT_STATUS_COLOUR_MAP: {
  [key in AppointmentStatus]: Colour;
} = {
  [AppointmentStatus.Unscheduled]: Colour.BlueGrey,
  [AppointmentStatus.Scheduled]: Colour.Red,
  [AppointmentStatus.Confirmed]: Colour.Orange,
  [AppointmentStatus.Arrived]: Colour.Yellow,
  [AppointmentStatus.CheckedIn]: Colour.LightBlue,
  [AppointmentStatus.InProgress]: Colour.Blue,
  [AppointmentStatus.CheckingOut]: Colour.Green,
  [AppointmentStatus.Complete]: Colour.Teal,
  [AppointmentStatus.Cancelled]: Colour.Red,
};

export interface INextStage {
  name: string;
  due?: moment.Moment;
}

export type AppointmentItemViewType = 'sidebar' | 'card';
