import { ReportBuilderDataSourceId } from '@principle-theorem/principle-core/interfaces';
import { FactTables, toMeasureBuilderData } from '@principle-theorem/reporting';
import { ChartFilters } from './chart-filters';
import { ReportBuilderChartFilters } from './report-builder-chart-filters';
import { ReportBuilderColumns } from './report-builder-columns';
import { type IReportBuilderDataSource } from './report-builder-data-source';
import { ReportBuilderGroups } from './report-builder-groups';

const factTable = FactTables.patientInteractionEvent.latestEvent;

export const PATIENT_INTERACTIONS_REPORT_BUILDER_DATA_SOURCE: IReportBuilderDataSource =
  {
    id: ReportBuilderDataSourceId.PatientInteractions,
    name: 'Patient Interactions',
    description: '',
    factTable: factTable,
    queryableTimestamps: [{ property: factTable.interaction.createdAt }],
    groupByOptions: [
      ReportBuilderGroups.toGroup('Common', [
        ReportBuilderGroups.toOption(factTable.brand.name),
        ReportBuilderGroups.toOption(factTable.count),
      ]),
      ReportBuilderGroups.patientInteraction(factTable.interaction),
      ReportBuilderGroups.patient(factTable.patient),
    ],
    availableColumns: [
      ReportBuilderColumns.toColumnGroup(
        'Patient Interaction',
        ReportBuilderColumns.patientInteraction(factTable.interaction)
      ),
      ReportBuilderColumns.toColumnGroup('Patient', [
        ...ReportBuilderColumns.patient(factTable.patient),
        factTable.patientLink,
      ]),
    ],
    defaultColumns: ReportBuilderColumns.toReferences([
      factTable.interaction.createdAt,
      factTable.patient.name,
      factTable.interaction.owner,
      factTable.interaction.title,
      factTable.interaction.type,
      factTable.interaction.pinned,
      factTable.interaction.tags,
      factTable.interaction.content,
    ]),
    availableCharts: [
      ReportBuilderChartFilters.predefined(
        'overview.brand.price',
        factTable.brand.name,
        ChartFilters.numberChart('Total Interactions', factTable.brand.name, [
          toMeasureBuilderData(factTable.count),
        ])
      ),
      ...ReportBuilderChartFilters.patientInteraction(
        factTable.interaction,
        factTable.count
      ),
      ...ReportBuilderChartFilters.patient(factTable.patient, factTable.count),
    ],
  };
