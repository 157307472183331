import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, combineLatest, of } from 'rxjs';
import { PatientCustomFormsService } from '../../../patient-custom-forms.service';
import { PatientFormStore } from '../patient-form.store';
import {
  IPatient,
  IPatientForm,
  IStaffer,
  PatientFormStatus,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, snapshot, snapshotDefined } from '@principle-theorem/shared';
import { isEmpty } from 'lodash';
import { map, switchMap } from 'rxjs/operators';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { PatientForm } from '@principle-theorem/principle-core';
import { NgMaterialModule } from '@principle-theorem/ng-material';

interface IConfirmPatientDetailsRequest {
  patient: WithRef<IPatient>;
  form: WithRef<IPatientForm>;
  patientUpdates: Partial<IPatient>;
  staffer: WithRef<IStaffer>;
}

@Component({
    selector: 'pr-patient-form-confirm-button',
    imports: [CommonModule, NgMaterialModule],
    templateUrl: './patient-form-confirm-button.component.html',
    styleUrl: './patient-form-confirm-button.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientFormConfirmButtonComponent {
  private _organisation = inject(OrganisationService);
  readonly store = inject(PatientFormStore);
  readonly customForms = inject(PatientCustomFormsService);
  canConfirm$: Observable<boolean>;
  confirmDisabled$: Observable<boolean>;
  @Output() confirmed = new EventEmitter<void>();

  constructor() {
    this.canConfirm$ = this.store.form$.pipe(
      map((form) => this._canConfirm(form))
    );
    this.confirmDisabled$ = this.store.form$.pipe(
      switchMap((form) => this._confirmDisabled$(form))
    );
  }

  async confirm(): Promise<void> {
    const form = await snapshot(this.store.form$);
    if (!form) {
      return;
    }
    if (PatientForm.isPatientDetailsForm(form)) {
      return this._confirmPatientDetails();
    }

    const staffer = await snapshotDefined(this._organisation.staffer$);
    await this.customForms.confirmForm(form, staffer);
    this.confirmed.emit();
  }

  private _canConfirm(form?: WithRef<IPatientForm>): boolean {
    if (!form) {
      return false;
    }
    if (PatientForm.isPatientDetailsForm(form)) {
      return true;
    }
    return form?.status === PatientFormStatus.Submitted;
  }

  private _confirmDisabled$(form?: WithRef<IPatientForm>): Observable<boolean> {
    if (!form) {
      return of(true);
    }
    if (PatientForm.isPatientDetailsForm(form)) {
      return this._getConfirmPatientDetailsRequest$().pipe(
        map((request) => !request)
      );
    }
    const notSubmitted = form?.status !== PatientFormStatus.Submitted;
    return of(notSubmitted);
  }

  private _getConfirmPatientDetailsRequest$(): Observable<
    IConfirmPatientDetailsRequest | undefined
  > {
    return combineLatest([
      this.store.form$,
      this.store.patient$,
      this.store.patientUpdates$,
      this._organisation.staffer$,
    ]).pipe(
      map(([form, patient, patientUpdates, staffer]) => {
        if (!form || !patient || isEmpty(patientUpdates) || !staffer) {
          return;
        }
        return { form, patient, patientUpdates, staffer };
      })
    );
  }

  private async _confirmPatientDetails(): Promise<void> {
    const request = await snapshot(this._getConfirmPatientDetailsRequest$());
    if (!request) {
      return;
    }
    await this.customForms.confirmPatientDetails(
      request.patient,
      request.form,
      request.patientUpdates,
      request.staffer
    );
    this.confirmed.emit();
  }
}
