import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CurrentPatientScope } from '@principle-theorem/ng-principle-shared';
import { AbstractResolver } from '@principle-theorem/ng-shared';
import { Patient } from '@principle-theorem/principle-core';
import {
  IPatientForm,
  IPatient,
  PatientRelationshipType,
} from '@principle-theorem/principle-core/interfaces';
import { getDoc$, type WithRef } from '@principle-theorem/shared';
import { first } from 'lodash';
import { of, type Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class PatientFormResolverService extends AbstractResolver<
  WithRef<IPatientForm>
> {
  getItemById(
    uid: string,
    route: ActivatedRouteSnapshot
  ): Observable<WithRef<IPatientForm> | undefined> {
    const patientScope: CurrentPatientScope = new CurrentPatientScope(
      this._router
    );
    const patient: WithRef<IPatient> | undefined =
      patientScope.findModel(route);

    if (!patient) {
      throw new Error('No Patient found');
    }

    return Patient.withPatientRelationships$(
      patient,
      [PatientRelationshipType.DuplicatePatient],
      (patientReffable) =>
        getDoc$(Patient.formCol(patientReffable), uid).pipe(
          map((form) => [form]),
          catchError(() => of([]))
        )
    ).pipe(
      map((forms) => first(forms)),
      map((form) => (form?.deleted ? undefined : form))
    );
  }
}
