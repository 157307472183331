import { Extension } from '@tiptap/core';
import { ListNodes } from './available-extensions';

export const DISABLE_ENTER_EXTENSION = Extension.create({
  addKeyboardShortcuts() {
    return {
      Enter: () => true,
      'Mod-Enter': () => true,
      'Shift-Enter': ({ editor }) => {
        return editor.commands.first(({ commands }) => [
          () => commands.newlineInCode(),
          () => commands.splitListItem(ListNodes.ListItem),
          () => commands.createParagraphNear(),
          () => commands.liftEmptyBlock(),
          () => commands.splitBlock(),
        ]);
      },
    };
  },
});
