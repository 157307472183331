import { Contact } from '@principle-theorem/principle-core';
import {
  FailedDestinationEntityRecord,
  IReferralSourceConfiguration,
  ITranslationMap,
  SourceEntityRecordStatus,
  isContactDetails,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IPracticeMigration,
  SkippedDestinationEntityRecord,
  IDestinationEntityJobRunOptions,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import {
  BaseContactDestinationEntity,
  IContactJobData,
  IContactMigrationData,
} from '../../../destination/entities/contact';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import {
  ContactSourceEntity,
  type ICorePracticeContact,
} from '../../source/entities/contacts';
import { CorePracticeContactMappingHandler } from '../mappings/contact-to-referral-source';

export interface IJobData extends IContactJobData<ICorePracticeContact> {
  referralSources: WithRef<ITranslationMap<IReferralSourceConfiguration>>[];
}

export class ContactDestinationEntity extends BaseContactDestinationEntity<
  ICorePracticeContact,
  IJobData
> {
  contactSourceEntity = new ContactSourceEntity();

  customMappings = {
    referralSources: new CorePracticeContactMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IJobData[]> {
    const brand$ = PracticeMigration.brand$(migration);
    const referralSources$ =
      this.customMappings.referralSources.getRecords$(translationMap);

    return this.buildSourceRecordQuery$(
      migration,
      this.contactSourceEntity,
      runOptions
    ).pipe(
      withLatestFrom(brand$, referralSources$),
      map(([sourceContacts, brand, referralSources]) =>
        sourceContacts.map((sourceContact) => ({
          sourceContact,
          brand,
          referralSources,
        }))
      )
    );
  }

  buildMigrationData(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IJobData
  ):
    | IContactMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
    | (IDestinationEntityRecord & SkippedDestinationEntityRecord) {
    if (data.sourceContact.record.status === SourceEntityRecordStatus.Invalid) {
      return this._buildErrorResponse(
        data.sourceContact,
        'Source contact is invalid'
      );
    }

    const contactData = data.sourceContact.data.data;
    const sourceContactId =
      this.contactSourceEntity.getSourceRecordId(contactData);

    const isMappedToReferralSource = data.referralSources.some(
      (referralSource) =>
        referralSource.sourceIdentifier === sourceContactId.toString() &&
        !!referralSource.destinationIdentifier
    );

    if (isMappedToReferralSource) {
      return this._buildSkippedResponse(data.sourceContact);
    }

    const contactDetails = {
      name: contactData.name,
      email: contactData.email,
      phone: contactData.telephone,
      address: getContactAddress(contactData),
      mobileNumber: contactData.mobile,
    };

    if (!isContactDetails(contactDetails)) {
      return this._buildErrorResponse(
        data.sourceContact,
        'Missing Required contact properties'
      );
    }

    return {
      sourceContactId,
      contact: Contact.init(contactDetails),
    };
  }
}

function getContactAddress(contact: ICorePracticeContact): string | undefined {
  const address =
    `${contact.addressLine1} ${contact.addressLine2} ${contact.suburb} ${contact.state} ${contact.country} ${contact.postcode}`.trim();
  return address.length > 0 ? address : undefined;
}
