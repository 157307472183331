import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgPatientModule } from '@principle-theorem/ng-patient';
import { NgPaymentsModule } from '@principle-theorem/ng-payments';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ReportingCoreModule } from '../../core/reporting-core.module';
import { ReportingComponentsModule } from '../../reporting-components/reporting-components.module';
import { PendingPaymentsReportTableComponent } from './pending-payments-table/pending-payments-table.component';
import { PendingPaymentsComponent } from './pending-payments.component';
import { PendingPaymentsStore } from './pending-payments.store';

@NgModule({
  imports: [
    RouterModule,
    ReportingCoreModule,
    ReportingComponentsModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    NgPatientModule,
    NgPaymentsModule,
  ],
  declarations: [PendingPaymentsComponent, PendingPaymentsReportTableComponent],
  providers: [PendingPaymentsStore],
})
export class PendingPaymentsModule {}
