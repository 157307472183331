import {
  Fragment,
  type MarkType,
  type Node,
  NodeType,
  Slice,
} from '@tiptap/pm/model';
import { Plugin } from '@tiptap/pm/state';
import { GetAttrsFn } from './extension';

export function pasteRule(
  regexp: RegExp,
  type: MarkType | NodeType,
  getAttrs?: GetAttrsFn<string>,
  getContentFn?: (text: string) => Fragment
): Plugin {
  const handler = (fragment: Fragment): Fragment => {
    const nodes: Node[] = [];

    fragment.forEach((child) => {
      if (child.isText) {
        const { text } = child;
        let pos = 0;
        let match;

        do {
          match = text ? regexp.exec(text) : undefined;

          if (match) {
            const start = match.index;
            const end = start + match[0].length;
            const attrs = getAttrs ? getAttrs(match[0]) : {};

            if (start > 0) {
              nodes.push(child.cut(pos, start));
            }

            if (type instanceof NodeType) {
              const node = type.create(
                attrs,
                getContentFn ? getContentFn(match[0]) : undefined
              );
              nodes.push(node);
            } else {
              const mark = type.create(attrs);
              nodes.push(
                child.cut(start, end).mark(mark.addToSet(child.marks))
              );
            }

            pos = end;
          }
        } while (match);

        if (text && pos < text.length) {
          nodes.push(child.cut(pos));
        }
      } else {
        nodes.push(child.copy(handler(child.content)));
      }
    });

    return Fragment.fromArray(nodes);
  };

  return new Plugin({
    props: {
      transformPasted: (slice) =>
        new Slice(handler(slice.content), slice.openStart, slice.openEnd),
    },
  });
}
