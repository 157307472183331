import { randNumber } from '@ngneat/falso';
import { IGapFilledFact } from '@principle-theorem/reporting/interfaces';
import {
  BaseMock,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
} from '@principle-theorem/shared';
import { AppointmentDimensionMock } from '../dimensions/appointment-dimension.mock';
import { BrandDimensionMock } from '../dimensions/brand-dimension.mock';
import { SerialisedDocumentReferenceMock } from '../dimensions/common.mock';
import { PatientDimensionMock } from '../dimensions/patient-dimension.mock';
import { PracticeDimensionMock } from '../dimensions/practice-dimension.mock';
import { StafferDimensionMock } from '../dimensions/staffer-dimension.mock';
import { TreatmentPlanDimensionMock } from '../dimensions/treatment-plan-dimension.mock';
import { BigQueryGapMock, BigQueryChartedTreatmentMock } from './common.mock';

export class GapFilledFactMock extends BaseMock implements IGapFilledFact {
  patientRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  appointmentRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  treatmentPlanRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practitionerRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practiceRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  brandRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  brand = MockGenerator.generate(BrandDimensionMock);
  practice = MockGenerator.generate(PracticeDimensionMock);
  practitioner = MockGenerator.generate(StafferDimensionMock);
  gap = MockGenerator.generate(BigQueryGapMock);
  patient = MockGenerator.generate(PatientDimensionMock);
  referrer = this.patient.referrer;
  appointment = MockGenerator.generate(AppointmentDimensionMock);
  treatmentPlan = MockGenerator.generate(TreatmentPlanDimensionMock);
  treatments = [
    MockGenerator.generate(BigQueryChartedTreatmentMock),
    MockGenerator.generate(BigQueryChartedTreatmentMock),
  ];
  timestamp = toSerialisedTimestamp(MockTimestamp());
  duration = randNumber({
    min: 15,
    max: 180,
  });
}
