import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '../components/components.module';
import { ConditionConfigModule } from './condition-config/condition-config.module';
import { MultiTreatmentConfigModule } from './multi-treatment-config/multi-treatment-config.module';
import { TreatmentConfigModule } from './treatment-config/treatment-config.module';
import { ChartingHistoryComponent } from './charting-history/charting-history.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    TreatmentConfigModule,
    MultiTreatmentConfigModule,
    ConditionConfigModule,
  ],
  declarations: [ChartingHistoryComponent],
})
export class PagesModule {}
