import {
  IClinicalChart,
  ITooth,
  IToothRef,
  Quadrant,
} from '@principle-theorem/principle-core/interfaces';
import {
  INode,
  LinkedList,
  UndirectedGraph,
  UndirectedGraphNode,
} from '@principle-theorem/shared';
import { ClinicalChart } from './clinical-chart';
import { isAdultQuadrant, isUpperQuadrant } from './core/quadrant';

function getQuadrantMap(): Map<Quadrant, Quadrant> {
  return new Map<Quadrant, Quadrant>([
    [Quadrant.AdultUpperRight, Quadrant.DeciduousUpperRight],
    [Quadrant.AdultUpperLeft, Quadrant.DeciduousUpperLeft],
    [Quadrant.AdultLowerLeft, Quadrant.DeciduousLowerLeft],
    [Quadrant.AdultLowerRight, Quadrant.DeciduousLowerRight],
  ]);
}

export function buildToothGraph(
  chart: IClinicalChart = ClinicalChart.init()
): UndirectedGraph<IToothRef> {
  const graph: UndirectedGraph<IToothRef> = new UndirectedGraph<IToothRef>();
  const adultUpper: ITooth[] = [];
  const adultLower: ITooth[] = [];
  const deciduousUpper: ITooth[] = [];
  const deciduousLower: ITooth[] = [];

  chart.teeth.map((tooth: ITooth) => {
    if (isUpperQuadrant(tooth.toothRef.quadrant)) {
      isAdultQuadrant(tooth.toothRef.quadrant)
        ? adultUpper.push(tooth)
        : deciduousUpper.push(tooth);
      return;
    }
    isAdultQuadrant(tooth.toothRef.quadrant)
      ? adultLower.push(tooth)
      : deciduousLower.push(tooth);
  });

  [adultUpper, adultLower, deciduousUpper, deciduousLower]
    .map((arch: ITooth[]) => {
      return new LinkedList<IToothRef>(arch.map((tooth) => tooth.toothRef));
    })
    .map((list: LinkedList<IToothRef>) => {
      list.iterate((node: INode<IToothRef>) => {
        if (node.next) {
          graph.addNeighbours(node.value, node.next.value);
        }
      });
    });

  const quadrantMap: Map<Quadrant, Quadrant> = getQuadrantMap();
  graph.nodes.map((node: UndirectedGraphNode<IToothRef>) => {
    const adjacentQuadrant: Quadrant | undefined = quadrantMap.get(
      node.value.quadrant
    );
    if (!adjacentQuadrant) {
      return;
    }

    const adjacentToothRef: IToothRef = {
      quadrant: adjacentQuadrant,
      quadrantIndex: node.value.quadrantIndex,
    };
    const foundNode: UndirectedGraphNode<IToothRef> | undefined =
      graph.findByValue(adjacentToothRef);

    if (foundNode) {
      foundNode.neighbours.map((ref: IToothRef) => node.addNeighbour(ref));
      node.neighbours.map((ref: IToothRef) => foundNode.addNeighbour(ref));
    }
  });

  return graph;
}

export const TOOTH_GRAPH: UndirectedGraph<IToothRef> = buildToothGraph();
