import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  signal,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { PipesModule, TypedFormControl } from '@principle-theorem/ng-shared';
import {
  MockAllTeeth,
  MockAdultTeeth,
  MockDeciduousTeeth,
  isSameToothRef,
} from '@principle-theorem/principle-core';
import {
  ChartView,
  ITooth,
} from '@principle-theorem/principle-core/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-tooth-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    PipesModule,
    MatCheckboxModule,
  ],
  templateUrl: './tooth-selector.component.html',
  styleUrls: ['./tooth-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToothSelectorComponent implements OnDestroy {
  private _chart = inject(ChartFacade);
  private _onDestroy$ = new Subject<void>();
  toothSelectCtrl = new TypedFormControl<ITooth[]>([]);
  teethOptions = signal<ITooth[]>([]);
  allTeethSelected = signal<boolean>(false);
  someTeethSelected = signal<boolean>(false);

  @Output() teethChanged = new EventEmitter<ITooth[]>();

  @Input()
  set teeth(teeth: ITooth[]) {
    if (teeth) {
      this.toothSelectCtrl.setValue(teeth, { emitEvent: false });
    }
  }

  constructor() {
    this.toothSelectCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((tooth) => {
        const options = this.teethOptions();
        this.allTeethSelected.set(tooth.length === options.length);
        this.someTeethSelected.set(
          tooth.length > 0 && tooth.length < options.length
        );
        this.teethChanged.emit(tooth);
      });

    this._chart
      .chartView$(ChartId.InAppointment)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((view) => this._setTeethOptions(view));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  toggleAllSelection(select: boolean): void {
    if (select) {
      this.toothSelectCtrl.setValue(this.teethOptions());
      return;
    }
    this.toothSelectCtrl.setValue([]);
  }

  compareFn(toothA: ITooth, toothB: ITooth): boolean {
    return toothA && toothB
      ? isSameToothRef(toothA.toothRef, toothB.toothRef)
      : false;
  }

  private _setTeethOptions(view: ChartView): void {
    if (view === ChartView.Adult) {
      this.teethOptions.set(MockAdultTeeth());
      return;
    }
    if (view === ChartView.Deciduous) {
      this.teethOptions.set(MockDeciduousTeeth());
      return;
    }
    this.teethOptions.set(MockAllTeeth());
  }
}
