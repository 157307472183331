import { type ISoftDelete } from '@principle-theorem/shared';
import { initVersionedSchema } from './extensions/raw-schema-helpers';
import { MixedSchema } from './schema';

export interface ISnippet extends ISoftDelete {
  name: string;
  body: MixedSchema;
  description?: string;
  keyword?: string;
}

export class Snippet {
  static init(overrides?: Partial<ISnippet>): ISnippet {
    return {
      name: '',
      body: initVersionedSchema(),
      deleted: false,
      ...overrides,
    };
  }
}

export const SNIPPET_TRIGGER = '#';
