import { type ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  NgModuleRef,
  type OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { type IUserWorkspace, multiSwitchMap } from '@principle-theorem/shared';
import { noop } from 'lodash';
import {
  combineLatest,
  from,
  type Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { AuthFirebaseFunctionsService } from '../auth-firebase-functions.service';
import { WorkspaceService } from '../workspace.service';

export interface IWorkspaceSelector {
  workspaces: IUserWorkspace[];
  workspaceSelected: Observable<IUserWorkspace>;
}

@Component({
  selector: 'pt-workspace-selector',
  templateUrl: './workspace-selector.component.html',
  styleUrls: ['./workspace-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceSelectorComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _workspacesContainer$ = new ReplaySubject<ViewContainerRef>(1);
  private _workspaceSelectors$ = new ReplaySubject<
    ComponentType<IWorkspaceSelector>[]
  >(1);
  workspaces$: Observable<IUserWorkspace[]>;
  loadingWorkspace = false;

  @Input()
  set workspaceSelectors(
    workspaceSelectors: ComponentType<IWorkspaceSelector>[]
  ) {
    if (workspaceSelectors) {
      this._workspaceSelectors$.next(workspaceSelectors);
    }
  }

  @ViewChild('workspacesContainer', { static: false, read: ViewContainerRef })
  set workspacesContainer(workspacesContainer: ViewContainerRef) {
    if (workspacesContainer) {
      this._workspacesContainer$.next(workspacesContainer);
    }
  }

  constructor(
    private _router: Router,
    public workspaceService: WorkspaceService,
    private _functions: AuthFirebaseFunctionsService,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _moduleRef: NgModuleRef<unknown>,
    private _cdr: ChangeDetectorRef
  ) {
    this.workspaces$ = from(this._functions.getUserWorkspaces());

    this.workspaces$
      .pipe(
        filter((workspaces) => workspaces.length === 1),
        map((workspaces) => workspaces[0]),
        switchMap((workspace) => this._selectWorkspace(workspace)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);

    const workspaceSelectorRefs$ = combineLatest([
      this._workspacesContainer$,
      this._workspaceSelectors$,
      this.workspaces$,
    ]).pipe(
      map(([workspacesContainer, workspaceSelectors, workspaces]) => {
        workspacesContainer.clear();
        return workspaceSelectors.map((workspaceSelector) => {
          const componentFactory =
            this._componentFactoryResolver.resolveComponentFactory(
              workspaceSelector
            );
          const componentRef = workspacesContainer.createComponent(
            componentFactory,
            undefined,
            undefined,
            undefined,
            this._moduleRef
          );
          componentRef.instance.workspaces = workspaces;
          this._cdr.detectChanges();
          return componentRef;
        });
      })
    );

    combineLatest([workspaceSelectorRefs$, this.workspaces$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([components, workspaces]) => {
        components.map((component) => {
          component.instance.workspaces = workspaces;
        });
        this._cdr.detectChanges();
      });

    workspaceSelectorRefs$
      .pipe(
        multiSwitchMap((component) =>
          component.instance.workspaceSelected.pipe(
            switchMap((workspace) => this._selectWorkspace(workspace))
          )
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => noop());
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private async _selectWorkspace(workspace: IUserWorkspace): Promise<void> {
    this.loadingWorkspace = true;
    await this.workspaceService.setWorkspace(workspace);
    await this._router.navigate(['/']);
  }
}
