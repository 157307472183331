import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
} from '@angular/core';
import { angularEditorExtensions } from '@principle-theorem/ng-editor';
import {
  InputSearchFilter,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { type INotification } from '@principle-theorem/notifications';
import { type NotificationType } from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject, type Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { MENTION_BUTTON_PROVIDER } from '@principle-theorem/ng-interactions';
import { NotificationTypeFilter } from './notification-type-filter';
import { type WithRef } from '@principle-theorem/shared';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { AnyExtension } from '@tiptap/core';

@Component({
  selector: 'pr-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent {
  notifications$: BehaviorSubject<WithRef<INotification>[]> =
    new BehaviorSubject<WithRef<INotification>[]>([]);
  filteredNotifications$: Observable<WithRef<INotification>[]>;
  selectedTypeFilters$: BehaviorSubject<NotificationType[]> =
    new BehaviorSubject<NotificationType[]>([]);

  search: TypedFormControl<string> = new TypedFormControl<string>('');
  searchFilter: InputSearchFilter<WithRef<INotification>>;
  notificationExtensions: AnyExtension[];

  @Input()
  set notifications(notifications: WithRef<INotification>[]) {
    if (!notifications) {
      return;
    }
    this.notifications$.next(notifications);
  }

  constructor(
    private _injector: Injector,
    public organisation: OrganisationService
  ) {
    this.notificationExtensions = [
      angularEditorExtensions.mention(this._injector, {
        providers: [MENTION_BUTTON_PROVIDER],
      }),
    ];
    this.searchFilter = new InputSearchFilter<WithRef<INotification>>(
      this.notifications$,
      this.search.valueChanges.pipe(startWith('')),
      ['type']
    );

    this.filteredNotifications$ = new NotificationTypeFilter(
      this.searchFilter.results$,
      this.selectedTypeFilters$
    ).results$;
  }

  filterResults(selectedTagFilters: NotificationType[]): void {
    this.selectedTypeFilters$.next(selectedTagFilters);
  }
}
