import { Injectable } from '@angular/core';
import {
  IPerioSettings,
  SwitchDirection,
  SwitchPattern,
  TableStyle,
} from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PerioSettingsService {
  autoSwitchCells$ = new BehaviorSubject<boolean>(false);
  switchPattern$ = new BehaviorSubject<SwitchPattern>(SwitchPattern.Zigzag);
  direction$ = new BehaviorSubject<SwitchDirection>(SwitchDirection.Default);
  tableStyle$ = new BehaviorSubject<TableStyle>(TableStyle.Table);
  isStyleTable$ = new BehaviorSubject<boolean>(true);
  isStyleBorderless$ = new BehaviorSubject<boolean>(false);

  applyStafferSettings(settings: IPerioSettings | undefined): void {
    if (!settings) {
      return;
    }
    this.autoSwitchCells$.next(settings.autoSwitchCells);
    this.switchPattern$.next(settings.switchPattern);
    this.direction$.next(settings.switchDirection);
    this.tableStyle$.next(settings.tableStyle);
    this.isStyleTable$.next(settings.tableStyle === TableStyle.Table);
    this.isStyleBorderless$.next(settings.tableStyle === TableStyle.Borderless);
  }
}
