import {
  type IAssociatedTreatment,
  type IFeeSchedule,
  type IPatient,
  type IStaffer,
  isTreatmentPlanFromTemplate,
  isTreatmentPlanWithBookableStep,
  type ITreatmentCategory,
  type ITreatmentPlan,
  type ITreatmentPlanFromTemplate,
  type ITreatmentPlanPairFromTemplate,
  type ITreatmentStep,
  type ITreatmentTemplateWithStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  doc$,
  type INamedDocument,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { drop, first } from 'lodash';
import { type TreatmentPlanStepPair } from './appointment-details';
import { ChartedTreatment } from '../clinical-charting/treatment/charted-treatment';
import { FeeScheduleManager } from '../clinical-charting/fees/fee-schedule/fee-schedule-manager';
import { TreatmentTemplate } from '../clinical-charting/treatment/treatment-template';
import { of } from 'rxjs';
import { TreatmentPlan } from '../clinical-charting/treatment/treatment-plan';
import { TreatmentStep } from '../clinical-charting/treatment/treatment-step';

interface ITreatmentPlanStepsPair {
  plan: ITreatmentPlanFromTemplate;
  steps: ITreatmentStep[];
}

export class AppointmentTreatmentPlanAssociator {
  static async getAssociatedTreatmentPlan(
    patient: WithRef<IPatient>,
    template: TreatmentPlanStepPair,
    overridePlan?: WithRef<ITreatmentPlan>
  ): Promise<IAssociatedTreatment | undefined> {
    if (overridePlan) {
      const step = await TreatmentPlan.addTreatmentStep(
        overridePlan,
        template.step
      );

      return TreatmentPlan.treatmentStepToAssociatedTreatment(
        overridePlan,
        step
      );
    }

    if (isTreatmentPlanWithBookableStep(template)) {
      return TreatmentPlan.treatmentStepToAssociatedTreatment(
        template.plan,
        template.step
      );
    }

    const treatmentPlan = await TreatmentPlan.saveNewPlan(
      TreatmentPlan.col(patient),
      template.plan,
      [template.step]
    );
    const treatmentStep = await snapshot(
      doc$<ITreatmentStep>(treatmentPlan.steps[0])
    );
    return TreatmentPlan.treatmentStepToAssociatedTreatment(
      treatmentPlan,
      treatmentStep
    );
  }

  static async getTreatmentPlanStepsPair(
    template: ITreatmentTemplateWithStep | ITreatmentPlanPairFromTemplate,
    feeSchedule: WithRef<IFeeSchedule>,
    treatmentCategories: WithRef<ITreatmentCategory>[],
    practitioner?: INamedDocument<IStaffer>
  ): Promise<ITreatmentPlanStepsPair> {
    const plan = {
      ...template.plan,
      practitioner: practitioner,
      isTemplate: isTreatmentPlanFromTemplate(template.plan)
        ? template.plan.isTemplate
        : false,
    };

    const emptyStep = {
      ...TreatmentStep.init({
        name: template.step.name,
      }),
      template: template.step.template,
    };

    if (!practitioner) {
      return {
        plan,
        steps: [emptyStep],
      };
    }

    const implementor = TreatmentTemplate.getImplementor(
      template.step.template,
      practitioner
    );

    if (!implementor) {
      return {
        plan,
        steps: [emptyStep],
      };
    }

    const feeScheduleManager = new FeeScheduleManager(of(feeSchedule));

    const templateSteps = await TreatmentTemplate.toTreatmentSteps(
      implementor,
      feeSchedule,
      treatmentCategories
    );

    const steps = await asyncForEach(templateSteps, (step) =>
      this.updateStepTreatments(step, feeScheduleManager)
    );

    return {
      plan,
      steps: [
        {
          ...emptyStep,
          ...first(steps),
        },
        ...drop(steps),
      ],
    };
  }

  static async updateStepTreatments(
    step: ITreatmentStep,
    feeScheduleManager: FeeScheduleManager
  ): Promise<ITreatmentStep> {
    return {
      ...step,
      treatments: await asyncForEach(step.treatments, (treatment) =>
        ChartedTreatment.applyFeeSchedule(
          treatment,
          step.treatments,
          feeScheduleManager,
          false
        )
      ),
    };
  }
}
