<ng-container *ngIf="event$ | async as event">
  <ng-container *ngIf="eventTimeData$ | async as eventTimeData">
    <div class="calendar-event-time" [ngStyle]="eventStyle$ | async">
      <div
        class="pointer-events-auto flex items-center"
        (click)="selectEvent(event)"
      >
        <span> {{ eventTimeData.eventTime }} - </span>
        <strong
          [ptTruncateTooltip]="eventTimeData.title"
          class="title truncate"
        >
          {{ eventTimeData.title }}
        </strong>
      </div>
    </div>
  </ng-container>
</ng-container>
