import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import {
  REPORTING_ACQUISITIONS_FEATURE,
  REPORTING_DAILY_DASHBOARD_FEATURE,
  REPORTING_PATIENT_FLOW_FEATURE,
  REPORTING_PRACTICES_FEATURE,
  REPORTING_PRACTITIONERS_V2_FEATURE,
  REPORTING_REPORT_BUILDER_FEATURE,
} from '@principle-theorem/principle-core/features';
import { AcquisitionsComponent } from './acquisitions/acquisitions.component';
import { CustomReportResolverService } from './custom-reports/custom-report-resolver.service';
import { CustomReportComponent } from './custom-reports/custom-report/custom-report.component';
import { CustomReportsComponent } from './custom-reports/custom-reports.component';
import { HasAccessToCustomReportGuard } from './custom-reports/has-access-to-custom-report.guard';
import { DailyDashboardComponent } from './daily-dashboard/daily-dashboard.component';
import { LEGACY_REPORT_ROUTES } from './legacy-reports/legacy-reports-routes';
import { PatientFlowComponent } from './patient-flow/patient-flow.component';
import { PracticeReportingComponent } from './practice-reporting/practice-reporting.component';
import { PractitionerReportingComponent } from './practitioner-reporting/practitioner-reporting.component';
import { ReportBuilderComponent } from './report-builder/report-builder.component';
import { ReportingModule } from './reporting.module';

const routes: Routes = [
  {
    path: 'daily-dashboard',
    component: DailyDashboardComponent,
    data: {
      title: 'Daily Dashboard',
      feature: REPORTING_DAILY_DASHBOARD_FEATURE,
    },
  },
  {
    path: 'patient-flow',
    component: PatientFlowComponent,
    data: {
      title: 'Patient Flow',
      feature: REPORTING_PATIENT_FLOW_FEATURE,
    },
  },
  {
    path: 'practitioners',
    component: PractitionerReportingComponent,
    data: {
      title: 'Practitioners Reporting',
      feature: REPORTING_PRACTITIONERS_V2_FEATURE,
    },
  },
  {
    path: 'acquisitions',
    component: AcquisitionsComponent,
    data: {
      title: 'Aquisitions',
      feature: REPORTING_ACQUISITIONS_FEATURE,
    },
  },
  {
    path: 'practices',
    component: PracticeReportingComponent,
    data: {
      title: 'Practices Reporting',
      feature: REPORTING_PRACTICES_FEATURE,
    },
  },
  {
    path: 'report-builder',
    component: ReportBuilderComponent,
    data: {
      title: 'Report Builder',
      feature: REPORTING_REPORT_BUILDER_FEATURE,
    },
  },
  {
    path: 'custom-reports',
    children: [
      {
        path: '',
        component: CustomReportsComponent,
        data: {
          title: 'Custom Reports',
          feature: REPORTING_REPORT_BUILDER_FEATURE,
        },
      },
      {
        path: ':uid',
        component: CustomReportComponent,
        canActivate: [HasAccessToCustomReportGuard],
        data: {
          title: 'Custom Report',
          feature: REPORTING_REPORT_BUILDER_FEATURE,
        },
        resolve: {
          customReport: CustomReportResolverService,
        },
      },
    ],
  },
  {
    path: 'legacy',
    children: LEGACY_REPORT_ROUTES,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), ReportingModule],
  exports: [RouterModule],
})
export class ReportingRoutingModule {}
