import {
  type INamedDocument,
  type ITimePeriod,
} from '@principle-theorem/shared';
import { type IPractice } from '../practice/practice';
import { type IStaffer } from '../staffer/staffer';
import { type IEventable } from './event';

export interface IEventTimePeriod extends ITimePeriod {
  practice: INamedDocument<IPractice>;
  staffer: INamedDocument<IStaffer>;
  overlappingEvents: IEventable[];
  overlapDuration: number;
  adjacentEvents: IEventable[];
}
