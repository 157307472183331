<mat-toolbar color="accent">Generate Bridge Download</mat-toolbar>

<mat-dialog-content class="p-4">
  <div class="flex flex-col gap-2">
    <div *ngrxLet="progressMessage$ as message">{{ message }}</div>
    <mat-progress-bar
      mode="buffer"
      [value]="progress$ | async"
    />

    <pt-buttons-container>
      @if (showCloseButton$ | async) {
        <button mat-stroked-button mat-dialog-close>Close</button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="downloadDisabled$ | async"
          (click)="download()"
        >
          Download
        </button>
      }
    </pt-buttons-container>
  </div>
</mat-dialog-content>
