<div class="flex flex-col gap-2 p-4">
  <h3 class="!m-0 !font-bold">Google Tag Manager (GTM)</h3>

  <p class="!m-0">
    A Google Tag Manager Tracking ID is a series of letters and numbers that
    starts with
    <code
      class="rounded-lg bg-slate-100 px-2 py-1 font-mono font-bold text-slate-500"
      >GTM-</code
    >. To create a Google Tag Manager account follow the
    <a
      href="https://support.google.com/tagmanager/answer/6103696?hl=en#:~:text=In%20Tag%20Manager%2C%20click%20Workspace,as%20%22GTM%2DXXXXXX%22."
      target="_blank"
      >Official Documentation</a
    >
  </p>

  <ol class="list-inside list-decimal">
    <li>
      In
      <a href="https://tagmanager.google.com/" target="_blank">Tag Manager</a>,
      click <strong>Admin</strong>.
    </li>
    <li>
      Near the top right of the window, find your container ID, formatted as
      <code
        class="rounded-lg bg-slate-100 px-2 py-1 font-mono font-bold text-slate-500"
        >GTM-XXXXXX</code
      >. This is your ID.
    </li>
  </ol>

  <div>
    <img
      src="/assets/help-images/tracking-codes/google-tag-manager-accounts.png"
      alt="Google Tag Manager - Tracking Code"
    />
  </div>
</div>
