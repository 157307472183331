import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';

@Component({
  selector: 'pr-icon-chip',
  templateUrl: './icon-chip.component.html',
  styleUrls: ['./icon-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NgMaterialModule],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class:
      'flex shrink-0 select-none flex-row items-center gap-2 rounded border border-solid border-slate-200 p-0.5',
  },
})
export class IconChipComponent {
  @Input() icon: string = 'question_mark';
  @Input() iconClass: 'string';
}
