import { AnyExtension, Extension } from '@tiptap/core';
import { COLUMN_BLOCK } from './column-block';
import { COLUMN } from './column';

export interface IColumnExtensionOptions {
  column?: boolean;
  columnBlock?: boolean;
}

export function createColumnExtension(): AnyExtension {
  return Extension.create<IColumnExtensionOptions>({
    name: 'columns',

    addExtensions() {
      const extensions = [];

      if (this.options.column !== false) {
        extensions.push(COLUMN);
      }

      if (this.options.columnBlock !== false) {
        extensions.push(COLUMN_BLOCK);
      }

      return extensions;
    },
  });
}
