import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  Invoice,
  OrganisationCache,
  Patient,
  resolveProvider$,
  toAccountDetails,
} from '@principle-theorem/principle-core';
import {
  IAccountDetails,
  IBasePatient,
  IPatient,
  IPatientContactDetails,
  type IBalance,
  type ICustomLineItem,
  type IInvoice,
  type IInvoiceItemGroupWithProvider,
  type IInvoiceLineItemGroup,
  type IPractice,
  type IProviderData,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  serialise,
  snapshot,
  type DocumentReference,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  from,
  of,
  type Observable,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { invoiceToBalance } from '../balance-display/balance';
import { LineItemsDisplay } from './line-items-display';

@Component({
  selector: 'pr-invoice-display',
  templateUrl: './invoice-display.component.html',
  styleUrls: ['./invoice-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceDisplayComponent {
  invoice$ = new ReplaySubject<WithRef<IInvoice>>(1);
  groups$ = new ReplaySubject<IInvoiceItemGroupWithProvider[]>(1);
  overrideBalance$ = new BehaviorSubject<IBalance | undefined>(undefined);
  trackByGroup =
    TrackByFunctions.uniqueId<IInvoiceLineItemGroup<ICustomLineItem>>();
  trackByLineItem = TrackByFunctions.uniqueId<ICustomLineItem>();
  lineItems: LineItemsDisplay;
  balance$: Observable<IBalance | undefined>;
  practiceRef$: Observable<DocumentReference<IPractice>>;
  patient$: Observable<WithRef<IPatient>>;
  nameHasChanged$: Observable<boolean>;

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this.invoice$.next(invoice);
    }
  }

  @Input()
  set groups(groups: IInvoiceItemGroupWithProvider[]) {
    if (groups) {
      this.groups$.next(groups);
    }
  }

  @Input()
  set balance(balance: IBalance) {
    if (balance) {
      this.overrideBalance$.next(balance);
    }
  }

  constructor(private _snackBar: MatSnackBar) {
    this.lineItems = new LineItemsDisplay(
      this.invoice$.pipe(map((invoice) => invoice.items))
    );
    this.balance$ = this.overrideBalance$.pipe(
      switchMap((balance) =>
        balance ? of(balance) : this.invoice$.pipe(invoiceToBalance())
      )
    );
    this.practiceRef$ = this.invoice$.pipe(
      map((invoice) => invoice.practice.ref)
    );

    this.patient$ = this.invoice$.pipe(
      switchMap((invoice) =>
        OrganisationCache.patients.getDoc(Invoice.patientDocRef(invoice))
      )
    );

    this.nameHasChanged$ = combineLatest([
      this.invoice$.pipe(map((invoice) => serialise(invoice.to))),
      from(this._buildInvoiceTo()).pipe(map((to) => serialise(to))),
    ]).pipe(
      map(
        ([currentAccountDetails, newAccountDetails]) =>
          !isEqual(currentAccountDetails, newAccountDetails)
      )
    );
  }

  resolveProvider$(
    group: IInvoiceLineItemGroup<ICustomLineItem>
  ): Observable<(INamedDocument & Partial<IProviderData>) | undefined> {
    return this.practiceRef$.pipe(
      switchMap((practiceRef) => resolveProvider$(group, practiceRef))
    );
  }

  async resyncName(): Promise<void> {
    const invoice = await snapshot(this.invoice$);

    await Firestore.patchDoc(invoice.ref, {
      to: await this._buildInvoiceTo(),
    });

    this._snackBar.open('Patient name updated');
  }

  private async _buildInvoiceTo(): Promise<IAccountDetails> {
    const patient = await snapshot(this.patient$);
    const primaryContact = await Patient.resolvePrimaryContact(patient.ref);
    const onBehalfOf = toAccountDetails(
      patient as IBasePatient & IPatientContactDetails
    );

    return primaryContact
      ? {
          ...toAccountDetails(primaryContact),
          onBehalfOf,
        }
      : onBehalfOf;
  }
}
