import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { TypesenseSearchService } from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  SelectionListStore,
} from '@principle-theorem/ng-shared';
import { TaskInteractionsDialogComponent } from '@principle-theorem/ng-tasks';
import { ITypesenseTaskWithRef } from '@principle-theorem/principle-core';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import { getDoc, isSameRef, type WithRef } from '@principle-theorem/shared';
import { Observable, ReplaySubject, Subject, interval, merge, of } from 'rxjs';
import { map, switchMap, takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'pr-patient-tasks',
  templateUrl: './patient-tasks.component.html',
  styleUrls: ['./patient-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SelectionListStore],
})
export class PatientTasksComponent {
  tasks$: Observable<ITypesenseTaskWithRef[]>;
  sort: MatSort = new MatSort();
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  pollTasks$ = new Subject<void>();

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(
    private _dialog: MatDialog,
    public selectionList: SelectionListStore<ITypesenseTaskWithRef>,
    private _search: TypesenseSearchService
  ) {
    this.tasks$ = this.patient$.pipe(
      switchMap((patient) =>
        merge(
          of(patient),
          this.pollTasks$.pipe(
            switchMap(() =>
              interval(1000).pipe(
                takeWhile((timer) => timer < 10),
                map(() => patient)
              )
            )
          )
        )
      ),
      switchMap((patient) => this._search.patientTaskQuery$(patient.ref)),
      map((results) => results.results),
      tap((results) => {
        this.selectionList.loadOptions(results);
      })
    );

    this.selectionList.setCompareFn(isSameRef);
  }

  async editTask(task: ITypesenseTaskWithRef): Promise<void> {
    const config: MatDialogConfig = DialogPresets.fullscreen({
      data: {
        task: await getDoc(task.ref),
      },
    });
    await this._dialog
      .open(TaskInteractionsDialogComponent, config)
      .afterClosed()
      .toPromise();
    this.pollTasks$.next();
  }

  updateTask(): void {
    this.pollTasks$.next();
  }
}
