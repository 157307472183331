import { IPatient } from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { IBaseCommand, IBridgeIntegration } from '../../send-command-to-device';

export const SIDEXIS_INTEGRATION: IBridgeIntegration = {
  label: 'Sidexis',
  description:
    'Two-way integration for taking and uploading radiographic images using Sidexis 4',
};

export type SidexisTriggerImageCaptureCommand = IBaseCommand<
  SidexisToDeviceCommand.TriggerImageCapture,
  {
    patient: WithRef<IPatient>;
  }
>;

export enum SidexisAction {
  SyncImageInfo = 'syncImageInfo',
  GetCapturedImageInfo = 'getCapturedImageInfo',
}

export enum SidexisToDeviceCommand {
  TriggerImageCapture = 'sidexis.triggerImageCapture',
}
