import {
  ITranslationMap,
  type ICustomMapping,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentData,
  addDoc,
  asColRef,
  find$,
  query$,
  snapshot,
  where,
  type CollectionReference,
  type DocumentReference,
  type WithRef,
  query,
  firstResult,
  Firestore,
  Transaction,
} from '@principle-theorem/shared';
import { from, type Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

export class TranslationMapHandler {
  constructor(
    private _translationMapCol: CollectionReference<ITranslationMap>
  ) {}

  findCustomMapping$(
    resourceMap: ICustomMapping
  ): Observable<WithRef<ITranslationMap> | undefined> {
    return find$(
      this._translationMapCol,
      where('type', '==', resourceMap.metadata.type)
    );
  }

  async add(
    translationMap: ITranslationMap,
    transaction?: Transaction
  ): Promise<void> {
    await addDoc(
      this._translationMapCol,
      translationMap,
      undefined,
      transaction
    );
  }

  async upsert(
    translationMap: ITranslationMap,
    transaction?: Transaction
  ): Promise<void> {
    await snapshot(
      from(
        firstResult(
          this._translationMapCol,
          where('sourceIdentifier', '==', translationMap.sourceIdentifier),
          where('resourceType', '==', translationMap.resourceType)
        )
      ).pipe(
        concatMap((foundTranslationMap) => {
          if (!foundTranslationMap) {
            return this.add(translationMap, transaction);
          }
          return Firestore.saveDoc(
            {
              ...foundTranslationMap,
              ...translationMap,
            },
            undefined,
            transaction
          );
        })
      )
    );
  }

  getByType$<T extends object = object, R = unknown>(
    resourceType: string
  ): Observable<WithRef<ITranslationMap<T, R>>[]> {
    return query$(
      asColRef<ITranslationMap<T, R>>(this._translationMapCol),
      where('resourceType', '==', resourceType)
    );
  }

  getByType<T extends object = object, R = unknown>(
    resourceType: string
  ): Promise<WithRef<ITranslationMap<T, R>>[]> {
    return query(
      asColRef<ITranslationMap<T, R>>(this._translationMapCol),
      where('resourceType', '==', resourceType)
    );
  }

  getSource(
    destinationIdentifier: DocumentReference,
    resourceType: string
  ): Promise<string | undefined> {
    return snapshot(
      from(
        firstResult(
          this._translationMapCol,
          where('destinationIdentifier', '==', destinationIdentifier),
          where('resourceType', '==', resourceType)
        )
      ).pipe(map((translationMap) => translationMap?.sourceIdentifier))
    );
  }

  async getDestination<T extends object = object, R = unknown>(
    sourceIdentifier: string,
    resourceType: string,
    transaction?: Transaction
  ): Promise<DocumentReference<T> | undefined> {
    const translationMap = await this.getBySource<T, R>(
      sourceIdentifier,
      resourceType
    );

    if (translationMap && transaction) {
      const record = await Firestore.safeGetDoc(
        translationMap.ref,
        transaction
      );
      return record?.destinationIdentifier;
    }
    return translationMap?.destinationIdentifier;
  }

  getBySource<T extends object = object, R = unknown>(
    sourceIdentifier: string,
    resourceType: string
  ): Promise<WithRef<ITranslationMap<T, R>> | undefined> {
    return snapshot(
      from(
        firstResult(
          asColRef<ITranslationMap<T, R>>(this._translationMapCol),
          where('sourceIdentifier', '==', sourceIdentifier),
          where('resourceType', '==', resourceType)
        )
      )
    );
  }

  getByDestination<T extends object = DocumentData, R = unknown>(
    destinationIdentifier: DocumentReference<T>,
    resourceType: string
  ): Promise<WithRef<ITranslationMap<T, R>> | undefined> {
    return snapshot(
      from(
        firstResult(
          asColRef<ITranslationMap<T, R>>(this._translationMapCol),
          where('destinationIdentifier', '==', destinationIdentifier),
          where('resourceType', '==', resourceType)
        )
      )
    );
  }
}
