import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  IPricedServiceCodeGroup,
  ServiceCodeGroupType,
} from '@principle-theorem/principle-core/interfaces';
import {
  IChartedSurface,
  IScopeRef,
} from '@principle-theorem/principle-core/interfaces';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-service-item-exclusive-group-list',
  templateUrl: './service-item-exclusive-group-list.component.html',
  styleUrls: ['./service-item-exclusive-group-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceItemExclusiveGroupListComponent {
  trackByGroup = TrackByFunctions.index<IPricedServiceCodeGroup>();
  groups$ = new ReplaySubject<IPricedServiceCodeGroup[]>(1);
  @Input() chartedSurfaces: IChartedSurface[] = [];
  @Input() scope: IScopeRef;
  @Input() disabled = false;
  @Input() compact = false;
  @Output() groupsChange = new EventEmitter<IPricedServiceCodeGroup[]>();

  @Input()
  set groups(groups: IPricedServiceCodeGroup[]) {
    if (groups) {
      this.groups$.next(groups);
    }
  }

  groupUpdate(
    groups: IPricedServiceCodeGroup[],
    current: IPricedServiceCodeGroup,
    changes: Partial<IPricedServiceCodeGroup>
  ): void {
    const change = groups.map((group) => {
      if (group.uid !== current.uid) {
        return group;
      }
      return {
        ...group,
        ...changes,
      };
    });
    this.groupsChange.next(change);
  }

  delete(
    groups: IPricedServiceCodeGroup[],
    group: IPricedServiceCodeGroup
  ): void {
    this.groupsChange.emit(groups.filter((item) => item.uid !== group.uid));
  }

  isExclusive(group: IPricedServiceCodeGroup): boolean {
    return group.type === ServiceCodeGroupType.Exclusive;
  }
}
