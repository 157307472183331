<div fxLayout="column" fxLayoutGap="8px">
  <div class="flex items-center justify-between gap-4">
    <h2>Appointment Details</h2>
    <pr-appointment-score
      scoreType="appointmentSuggestion"
      [score]="score$ | async"
    />
  </div>
  <div *ngrxLet="selectedEvent$ as selectedEvent" class="flex flex-col gap-1.5">
    <ng-container *ngIf="selectedEvent">
      <div class="flex items-center gap-6">
        <mat-icon matTooltip="Date">calendar_today</mat-icon>
        <pr-formatted-event-field
          field="from"
          [event]="selectedEvent"
          [format]="dateFormat"
        />
      </div>

      <div class="flex items-center gap-6">
        <mat-icon matTooltip="Time">schedule</mat-icon>
        <span>{{ timeDisplay$ | ngrxPush }}</span>
      </div>

      <ng-container *ngrxLet="practices$ as practices">
        <div *ngIf="practices.length > 1" class="flex items-center gap-6">
          <mat-icon matTooltip="Practice">location_on</mat-icon>
          <span>{{ (selectedPractice$ | async)?.name }}</span>
        </div>
      </ng-container>

      <div
        *ngIf="participants$ | ngrxPush as participants"
        class="flex items-center gap-6"
      >
        <mat-icon matTooltip="Event participants">group</mat-icon>
        <div fxLayout="column">
          <div
            *ngFor="
              let participant of participants;
              trackBy: trackByParticipant
            "
            class="flex items-center gap-3"
          >
            <pt-user-icon
              [name]="participant.name"
              [src]="participant | profileImage$ | ngrxPush"
              [diameter]="30"
              [matTooltip]="participant.name"
            />
            <div>{{ participant.name }}</div>
          </div>
        </div>
      </div>
    </ng-container>

    <div>
      <a
        href="javascript:void(0);"
        class="action-link"
        (click)="showAppointmentOptions.emit()"
      >
        <mat-icon>visibility</mat-icon> See more appointment options
      </a>
    </div>
  </div>
</div>
