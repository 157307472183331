import { ServiceProviderHandler } from '@principle-theorem/principle-core';
import {
  ITranslationMap,
  ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  INamedDocument,
  isINamedDocument,
  type AtLeast,
  type IXSLXExport,
  type XSLXCell,
} from '@principle-theorem/shared';
import type { Column } from 'exceljs';
import { IBaseMigrationItemCode } from '../interfaces';

export enum ItemCodeResourceMapType {
  TreatmentConfiguration = 'Treatment Configuration',
  ServiceCode = 'Service Code',
  Omit = 'Omit',
}

export const ITEM_CODE_CUSTOM_MAPPING_TYPE = 'itemCodeMapping';

export interface IItemCodeXLSX {
  code: string;
  description: string;
  associatedValue?: string;
}

export const ITEM_CODE_HEADERS: AtLeast<Column, 'key' | 'header'>[] = [
  {
    key: 'code',
    header: 'code',
  },
  {
    key: 'description',
    header: 'Description',
    width: 60,
  },
  {
    key: 'associatedValue',
    header: 'Associated Value',
    width: 30,
  },
];

export class ItemCodesToXLSX<
  T extends IBaseMigrationItemCode = IBaseMigrationItemCode,
> implements IXSLXExport<T, IItemCodeXLSX>
{
  headers = ITEM_CODE_HEADERS;

  constructor(
    private _existingMappings: ITranslationMap<
      object,
      ItemCodeResourceMapType
    >[],
    private _treatmentConfigurations: INamedDocument<ITreatmentConfiguration>[],
    private _suggestedMappings: { [key: string]: string }
  ) {}

  translate(records: T[]): Record<keyof IItemCodeXLSX, XSLXCell>[] {
    return records.map((record) => {
      const existingRecord = this._existingMappings.find((existingMapping) =>
        [record.id.toString(), record.itemCode].includes(
          existingMapping.sourceIdentifier
        )
      );

      const associatedValue = this._treatmentConfigurations.map(
        (item) => item.name
      );

      const suggestedMapping = this._suggestedMappings[record.itemCode];
      const suggestedMappingServiceCode = suggestedMapping
        ? ServiceProviderHandler.findServiceCode(suggestedMapping)
        : undefined;

      return {
        code: {
          value: record.itemCode,
        },
        description: {
          value: record.description,
        },
        associatedValue: {
          value: existingRecord?.associatedValue
            ? isINamedDocument(existingRecord.associatedValue)
              ? existingRecord.associatedValue.name
              : existingRecord.associatedValue
            : suggestedMappingServiceCode?.code.toString(),
          dataValidation: {
            type: 'list',
            formulae: [`"${associatedValue.join(',')}"`],
            allowBlank: true,
            showErrorMessage: false,
          },
        },
      };
    });
  }
}
