import { IPrescriptionItem } from '@principle-theorem/principle-core/interfaces';
import { AtLeast, initFirestoreModel } from '@principle-theorem/shared';

export class PrescriptionItem {
  static init(
    overrides: AtLeast<IPrescriptionItem, 'medicationName'>
  ): IPrescriptionItem {
    return {
      ...initFirestoreModel(),
      ...overrides,
    };
  }
}
