import {
  TypeGuard,
  isDocRef,
  isObject,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { PatientOptionalField, type IBrand } from '../brand';
import { ICustomFormData } from '../custom-forms/custom-form-helpers';
import { type IPatientFormSchema } from '../custom-forms/patient-form';
import { PatientHealthCardType } from './healthfund-cards';
import { type IPatient } from './patient';
import { type IReferralSourceConfiguration } from './referral-source';

export interface IPatientTokenData {
  patient: DocumentReference<IPatient>;
}

export const isPatientTokenData = TypeGuard.interface<IPatientTokenData>({
  patient: isDocRef,
});

export interface ISubmitMedicalFormRequest {
  tokenUid: string;
  data: IPatientFormSchema<object>;
}

export interface ISubmitPatientDetailsFormRequest {
  tokenUid: string;
  data: IPatientFormSchema<Partial<IPatient>>;
}

export const isSubmitMedicalFormRequest =
  TypeGuard.interface<ISubmitMedicalFormRequest>({
    tokenUid: isString,
    data: isObject,
  });

export const isSubmitPatientDetailsFormRequest =
  TypeGuard.interface<ISubmitPatientDetailsFormRequest>({
    tokenUid: isString,
    data: isObject,
  });

export interface IPatientMedicalHistoryFormTokenData {
  brand: WithRef<IBrand>;
  patientName: string;
  alreadySubmitted: boolean;
  referralSources: WithRef<IReferralSourceConfiguration>[];
  requiredFields: PatientOptionalField[];
  healthCardTypes: PatientHealthCardType[];
  customFormData: ICustomFormData;
}
