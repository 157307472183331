import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import { NgIntercomComponentsModule } from '@principle-theorem/ng-intercom';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { AppVersionModule, NgSharedModule } from '@principle-theorem/ng-shared';
import { SidebarGroupComponent } from './sidebar-items/sidebar-group/sidebar-group.component';
import { SidebarItemsComponent } from './sidebar-items/sidebar-items.component';
import { SidebarLinkComponent } from './sidebar-link/sidebar-link.component';
import { SidebarPracticeSelectorDialogComponent } from './sidebar-practice-selector-dialog/sidebar-practice-selector-dialog.component';
import { SidebarPracticeSelectorComponent } from './sidebar-practice-selector/sidebar-practice-selector.component';
import { SidebarComponent } from './sidebar.component';

@NgModule({
  declarations: [
    SidebarComponent,
    SidebarPracticeSelectorComponent,
    SidebarLinkComponent,
    SidebarItemsComponent,
    SidebarGroupComponent,
    SidebarPracticeSelectorDialogComponent,
  ],
  exports: [
    SidebarComponent,
    SidebarItemsComponent,
    SidebarGroupComponent,
    SidebarPracticeSelectorComponent,
  ],
  imports: [
    NgSharedModule,
    RouterModule,
    AppVersionModule,
    NgPrincipleSharedModule,
    NgFeatureFlagsModule,
    NgIntercomComponentsModule,
  ],
})
export class SidebarModule {}
