<form
  [formGroup]="formGroup"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="16px"
  *ngIf="lineItem$ | async as lineItem"
  class="line-item"
>
  <div
    fxFlex
    class="input-box"
    *ngIf="canEditDescription$ | async; else descriptionDisplay"
  >
    <input matInput formControlName="description" />

    @if (lineItem | map: isDeposit) {
      <div>
        <a
          href="javascript:void(0);"
          class="text-xs"
          matTooltip="Assign to a practitioner and/or treatment category"
          (click)="triggerDepositAssignment(lineItem)"
        >
          @if (lineItem | map: resolveProvider; as provider) {
            Deposit for <pr-treatment-provider [name]="provider?.name" />
          } @else {
            Deposit Unassigned
          }
        </a>
        @if (
          lineItem | map: resolveTreatmentCategory$ : this | async;
          as treatmentCategory
        ) {
          <div
            [matTooltip]="treatmentCategory.name"
            class="inline-block h-3 w-3 rounded-full"
            [style.backgroundColor]="treatmentCategory.colour.value"
          ></div>
        }
      </div>
    }
  </div>

  <ng-template #descriptionDisplay>
    <div fxFlex>{{ lineItem.description }}</div>
  </ng-template>

  <div class="quantity">
    <pr-increase-decrease-input
      *ngIf="canEditQuantity$ | async; else quantityDisplay"
      [slim]="true"
      (decrease)="addQty(-1)"
      [decreaseDisabled]="lineItem.quantity <= 0"
      (increase)="addQty(1)"
    >
      {{ formGroup.value.quantity }}
    </pr-increase-decrease-input>
    <ng-template #quantityDisplay>
      <span class="quantity-inset">{{ lineItem.quantity }}</span>
    </ng-template>
  </div>

  <div class="amount" fxLayout="row" fxLayoutAlign="end">
    <div
      *ngIf="
        lineItem.taxStatus | map: canChangeTaxAmount$ : this | ngrxPush;
        else taxDisplay
      "
      fxLayout="row"
      class="input-box"
    >
      $<input
        matInput
        class="tax-input"
        type="number"
        min="0"
        step="1"
        formControlName="tax"
        autocomplete="off"
      />
    </div>
    <ng-template #taxDisplay>
      <span class="amount-inset">{{ lineItem.tax | currency }}</span>
    </ng-template>
  </div>

  <div class="amount">
    <div
      *ngIf="canEditPrice$ | async; else priceDisplay"
      fxLayout="row"
      class="input-box"
    >
      $<input
        matInput
        class="price-input"
        type="number"
        min="0"
        step="1"
        formControlName="amount"
        autocomplete="off"
      />
    </div>
    <ng-template #priceDisplay>
      <span class="amount-inset">{{ lineItem.amount | currency }}</span>
    </ng-template>
  </div>
</form>
