<mat-toolbar color="accent">Create Calendar Event</mat-toolbar>

<mat-dialog-content>
  <pr-calendar-event-form
    (submitted)="save($event)"
    [calendarEvent]="data.calendarEvent"
    #calendarEventForm="prCalendarEventForm"
   />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <pt-loader-button
      type="flat"
      [loading]="calendarEventForm.saving$ | async"
      [disabled]="calendarEventForm.form.invalid"
      color="primary"
      (clicked)="calendarEventForm.submit()"
    >
      Create
    </pt-loader-button>
  </pt-buttons-container>
</mat-dialog-actions>
