<mat-toolbar color="accent">
  <span>Edit Sterilisation Content</span>
</mat-toolbar>

<mat-dialog-content *ngrxLet="content$ as content">
  <div class="flex flex-col gap-2">
    @for (item of content; track item.item.ref.path; let index = $index) {
      <div class="flex items-center justify-between">
        {{ item.item.name }}

        <div class="flex items-center gap-4">
          <div class="flex items-center gap-1">
            <button
              mat-icon-button
              class="small-button"
              [disabled]="item.quantity < 1"
              (click)="changeQuantity(item, item.quantity - 1)"
            >
              <mat-icon>remove</mat-icon>
            </button>

            <input
              class="custom-input-default w-10"
              min="0"
              [value]="item.quantity"
            />
            <button
              mat-icon-button
              class="small-button"
              (click)="changeQuantity(item, item.quantity + 1)"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
      </div>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button (click)="dialogRef.close()">Close</button>
  <button mat-flat-button color="primary" (click)="save()">Save</button>
</mat-dialog-actions>
