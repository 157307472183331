import {
  IAppointment,
  ICandidate,
  IPatient,
  WaitListItemState,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  INamedDocument,
  MockTimestamp,
  Properties,
} from '@principle-theorem/shared';
import { DocumentReference, Timestamp } from '@principle-theorem/shared';
import { MockDocRef, MockNamedDocument } from '@principle-theorem/testing';

export class CandidateMock
  extends BaseFirestoreMock
  implements Properties<ICandidate>
{
  get appointment(): DocumentReference<IAppointment> {
    return MockDocRef<IAppointment>();
  }

  get patient(): INamedDocument<IPatient> {
    return MockNamedDocument<IPatient>();
  }

  get state(): WaitListItemState {
    return WaitListItemState.Available;
  }

  get offerTimeFrom(): Timestamp {
    return MockTimestamp();
  }

  get offerTimeTo(): Timestamp {
    return MockTimestamp();
  }
}
