import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-automation-list-toolbar',
    templateUrl: './automation-list-toolbar.component.html',
    styleUrls: ['./automation-list-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AutomationListToolbarComponent {
  selectedIds$: ReplaySubject<string[]> = new ReplaySubject(1);
  isAllSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  hasSomeSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  @Output() checkboxChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cancelSelected: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  set selectedIds(selectedIds: string[]) {
    if (selectedIds) {
      this.selectedIds$.next(selectedIds);
    }
  }

  @Input()
  set isAllSelected(isAllSelected: BooleanInput) {
    this.isAllSelected$.next(coerceBooleanProperty(isAllSelected));
  }

  @Input()
  set hasSomeSelected(hasSomeSelected: BooleanInput) {
    this.hasSomeSelected$.next(coerceBooleanProperty(hasSomeSelected));
  }
}
