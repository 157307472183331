<div class="error-message mat-caption" *ngIf="templateError">
  {{ templateError }}
</div>

<div *ngIf="isHTMLTemplate$ | async; else plainText">
  <pr-content-view
    *ngIf="result$ | async as result"
    [content]="result"
   />
</div>
<ng-template #plainText>
  <span class="plain-text">{{ plainTextResult$ | async }}</span>
</ng-template>
