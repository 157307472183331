import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountingFunctionsService } from '@principle-theorem/ng-principle-accounting';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  Invoice,
  Transaction,
  getBaseTransaction,
} from '@principle-theorem/principle-core';
import {
  IInvoice,
  ITransaction,
  InvoiceStatus,
  TransactionAction,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import {
  Region,
  toTimestamp,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { omit } from 'lodash';
import { of, type Observable } from 'rxjs';
import { RefundDepositPromptService } from '../transaction-components/refund-deposit-prompt/refund-deposit-prompt.service';
import { TransactionAmountDialog } from '../transaction-components/transaction-amount-dialog/transaction-amount-dialog.service';
import {
  TransactionProviderType,
  type ITransactionProvider,
} from '../transaction-provider';
import {
  DiscountTransactionComponent,
  type IDiscountTransactionData,
} from './discount-transaction.component';

@Injectable()
export class DiscountTransactionProvider implements ITransactionProvider {
  providerId = TransactionProvider.Discount;
  providerType = TransactionProviderType.Payment;
  providerRegions = [Region.Australia, Region.NewZealand];
  isEnabled$ = of(true);

  constructor(
    private _dialog: MatDialog,
    private _snackbar: MatSnackBar,
    private _amountDialog: TransactionAmountDialog,
    private _refundDepositPrompt: RefundDepositPromptService,
    private _accountFunctions: AccountingFunctionsService
  ) {}

  canCapture$(invoice: WithRef<IInvoice>): Observable<boolean> {
    const canCapture =
      Invoice.canAddTransactions(invoice) &&
      invoice.status !== InvoiceStatus.Paid;
    return of(canCapture);
  }

  async capture(
    invoice: WithRef<IInvoice>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const data = { ...(await getBaseTransaction(invoice)), invoice };
    data.from = invoice.from.name;
    const transaction = await this._dialog
      .open<
        DiscountTransactionComponent,
        IDiscountTransactionData,
        ITransaction
      >(DiscountTransactionComponent, DialogPresets.medium({ data }))
      .afterClosed()
      .toPromise();
    if (!transaction) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }
    return this._accountFunctions.addTransactionToInvoice(
      invoice,
      transaction,
      TransactionAction.Add
    );
  }

  async refundTransaction(
    invoice: WithRef<IInvoice>,
    transaction: WithRef<ITransaction>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const acceptedRefundAlert =
      await this._refundDepositPrompt.showRefundDepositAlert(invoice);
    if (!acceptedRefundAlert) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }
    const result = await this._amountDialog.openForRefund('Discount Refund', {
      invoice,
      transaction,
      latestTransaction: transaction,
    });
    if (!result || result.amount <= 0) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }
    const refundTransaction = Transaction.init({
      ...omit(transaction, 'ref'),
      amount: result.amount,
      type: TransactionType.Outgoing,
      status: TransactionStatus.Complete,
      createdAt: toTimestamp(),
      updatedAt: toTimestamp(),
    });
    return this._accountFunctions.addTransactionToInvoice(
      invoice,
      refundTransaction,
      TransactionAction.Refund
    );
  }
}
