import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  type AnyChartedItemConfiguration,
  type IBrand,
  type IConditionConfiguration,
  type IMultiTreatmentConfiguration,
  type IQuickChartingConfigurations,
  type IStaffer,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isChanged$,
  serialise,
  unserialise$,
  type DocumentReference,
  type SerialisedData,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  addAndChartConditionConfiguration,
  addAndChartTreatmentConfiguration,
  addConditionConfiguration,
  addMultiTreatmentConfiguration,
  addTreatmentConfiguration,
} from '../actions/charted-configurations';
import {
  addQuickChartingItem,
  removeQuickChartingItem,
} from '../actions/quick-charting';
import { type ChartId } from '../reducers/active-charts/chart-context-state';
import { type IChartState } from '../reducers/reducers';
import {
  getConditionConfigurationEntities,
  getConditionConfigurations,
  getMultiTreatmentConfigurations,
  getTreatmentConfigurationEntities,
  getTreatmentConfigurations,
} from '../selectors';
import { getChartContextQuickCharting } from '../selectors/active-charts.selectors';

@Injectable()
export class ChartedConfigurationFacade {
  private _store = inject(Store<IChartState>);

  combinedConfigurations$: Observable<
    WithRef<
      | IConditionConfiguration
      | IMultiTreatmentConfiguration
      | ITreatmentConfiguration
    >[]
  >;
  combinedTreatmentConfigurations$: Observable<
    WithRef<IMultiTreatmentConfiguration | ITreatmentConfiguration>[]
  >;

  treatmentConfigurations$: Observable<WithRef<ITreatmentConfiguration>[]>;
  multiTreatmentConfigurations$: Observable<
    WithRef<IMultiTreatmentConfiguration>[]
  >;
  conditionConfigurations$: Observable<WithRef<IConditionConfiguration>[]>;

  constructor() {
    this.treatmentConfigurations$ = this._store.pipe(
      select(getTreatmentConfigurations),
      unserialise$()
    );
    this.multiTreatmentConfigurations$ = this._store.pipe(
      select(getMultiTreatmentConfigurations),
      unserialise$()
    );
    this.conditionConfigurations$ = this._store.pipe(
      select(getConditionConfigurations),
      unserialise$()
    );
    this.combinedTreatmentConfigurations$ = combineLatest([
      this.treatmentConfigurations$,
      this.multiTreatmentConfigurations$,
    ]).pipe(
      map(([treatments, multiTreatments]) => [
        ...treatments,
        ...multiTreatments,
      ])
    );

    this.combinedConfigurations$ = combineLatest([
      this._store.pipe(select(getConditionConfigurations)),
      this._store.pipe(select(getTreatmentConfigurations)),
      this._store.pipe(select(getMultiTreatmentConfigurations)),
    ]).pipe(
      map(([conditions, treatments, multiTreatments]) => [
        ...conditions,
        ...treatments,
        ...multiTreatments,
      ]),
      isChanged$(),
      unserialise$()
    );
  }

  addAndChartConditionConfiguration(
    id: ChartId,
    config: IConditionConfiguration
  ): void {
    this._store.dispatch(
      addAndChartConditionConfiguration(serialise({ id, config }))
    );
  }

  addAndChartTreatmentConfiguration(
    id: ChartId,
    config: ITreatmentConfiguration
  ): void {
    this._store.dispatch(
      addAndChartTreatmentConfiguration(serialise({ id, config }))
    );
  }

  addConditionConfiguration(
    entity: WithRef<IStaffer> | WithRef<IBrand>,
    config: IConditionConfiguration
  ): void {
    this._store.dispatch(
      addConditionConfiguration(serialise({ entity, config }))
    );
  }

  addTreatmentConfiguration(
    entity: WithRef<IStaffer> | WithRef<IBrand>,
    config: ITreatmentConfiguration
  ): void {
    this._store.dispatch(
      addTreatmentConfiguration(serialise({ entity, config }))
    );
  }

  addMultiTreatmentConfiguration(
    entity: WithRef<IStaffer> | WithRef<IBrand>,
    config: IMultiTreatmentConfiguration
  ): void {
    this._store.dispatch(
      addMultiTreatmentConfiguration(serialise({ entity, config }))
    );
  }

  // TODO: this should be a selector
  getConditionConfiguration$(
    ref: DocumentReference
  ): Observable<WithRef<IConditionConfiguration>> {
    return this._store.pipe(
      select(getConditionConfigurationEntities),
      unserialise$(),
      map((entities) => entities[ref.id]),
      filterUndefined()
    );
  }

  // TODO: this should be a selector
  getTreatmentConfiguration$(
    ref: DocumentReference
  ): Observable<WithRef<ITreatmentConfiguration>> {
    return this._store.pipe(
      select(getTreatmentConfigurationEntities),
      unserialise$(),
      map((entities) => entities[ref.id]),
      filterUndefined()
    );
  }

  getChartContextQuickCharting$(
    id: ChartId
  ): Observable<SerialisedData<IQuickChartingConfigurations> | undefined> {
    return this._store.pipe(select(getChartContextQuickCharting(id)));
  }

  itemIsInQuickChart$(
    id: ChartId,
    item: AnyChartedItemConfiguration
  ): Observable<boolean> {
    return this.getChartContextQuickCharting$(id).pipe(
      map((data) => {
        if (!data) {
          return false;
        }
        const itemRefs = [
          ...data.multiTreatments,
          ...data.treatments,
          ...data.conditions,
        ].map((quickChartItem) => quickChartItem.ref);
        return itemRefs.some(
          (itemRef) => itemRef.referenceValue === item.ref.path
        );
      })
    );
  }

  getQuickChartingConditions$(
    id: ChartId
  ): Observable<WithRef<IConditionConfiguration>[]> {
    return this.getChartContextQuickCharting$(id).pipe(
      switchMap((data) => {
        if (!data) {
          return of([]);
        }
        const itemRefs = data.conditions.map((item) => item.ref);
        return this.conditionConfigurations$.pipe(
          map((configs) =>
            configs.filter((config) =>
              itemRefs.some(
                (itemRef) => itemRef.referenceValue === config.ref.path
              )
            )
          )
        );
      })
    );
  }

  getQuickChartingTreatments$(
    id: ChartId
  ): Observable<
    WithRef<ITreatmentConfiguration | IMultiTreatmentConfiguration>[]
  > {
    return this.getChartContextQuickCharting$(id).pipe(
      switchMap((data) => {
        if (!data) {
          return of([]);
        }
        const itemRefs = [...data.multiTreatments, ...data.treatments].map(
          (item) => item.ref
        );
        return this.combinedTreatmentConfigurations$.pipe(
          map((configs) =>
            configs.filter((config) =>
              itemRefs.some(
                (itemRef) => itemRef.referenceValue === config.ref.path
              )
            )
          )
        );
      })
    );
  }

  addQuickChartingItem(
    id: ChartId,
    item: AnyChartedItemConfiguration,
    itemType: keyof IQuickChartingConfigurations
  ): void {
    this._store.dispatch(
      addQuickChartingItem(
        serialise({
          id,
          quickChartingItem: { ref: item.ref },
          itemType,
        })
      )
    );
  }

  removeQuickChartingItem(
    id: ChartId,
    item: AnyChartedItemConfiguration,
    itemType: keyof IQuickChartingConfigurations
  ): void {
    this._store.dispatch(
      removeQuickChartingItem(
        serialise({
          id,
          quickChartingItem: { ref: item.ref },
          itemType,
        })
      )
    );
  }
}
