import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ContactFormDialogComponent } from './contact-form-dialog/contact-form-dialog.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { MemberContactListComponent } from './member-contact-list/member-contact-list.component';

@NgModule({
  declarations: [
    MemberContactListComponent,
    ContactFormComponent,
    ContactFormDialogComponent,
  ],
  imports: [
    NgPrincipleSharedModule,
    NgMaterialModule,
    NgSharedModule,
    ReactiveFormsModule,
  ],
  exports: [MemberContactListComponent],
})
export class ComponentsModule {}
