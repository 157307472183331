import { InjectionToken } from '@angular/core';
import { type IMedipassConfig } from '@medipass/partner-sdk';
import { type Observable } from 'rxjs';

export const DEFAULT_MEDIPASS_LOGO_URL =
  'https://docs.medipass.io/static/6d0826a36b200061ef77570998db9304/59139/medipass-logo-white.png';

export interface IMedipassConfigProvider {
  medipassLogoUrl: string;
  getConfig$(): Observable<IMedipassConfig | undefined>;
}

export const NG_MEDIPASS_CONFIG_PROVIDER: InjectionToken<IMedipassConfigProvider> =
  new InjectionToken('IMedipassConfigProvider');
