<div class="flex gap-2">
  <div class="label-wrapper flex flex-1 flex-col">
    <div class="flex items-center justify-between gap-4">
      <div class="flex flex-1 gap-1.5">
        <ng-container *ngIf="config$ | async as config">
          <mat-icon
            *ngIf="config.display?.icon.slug"
            class="item-icon"
            [ngStyle]="{ color: config.display.colour.value }"
            [svgIcon]="config.display.icon.slug"
           />
          <h4
            [matTooltip]="config.name"
            [matTooltipShowDelay]="300"
            class="item-name"
            [ngClass]="{ compact: compact }"
          >
            {{ config.name }}
          </h4>
        </ng-container>
        <ng-container *ngIf="item$ | async as item">
          <mat-icon
            class="note"
            *ngIf="item.notes.length"
            [matTooltip]="noteTooltipText$ | async"
          >
            description
          </mat-icon>
        </ng-container>
      </div>
      <div class="flex items-center justify-end gap-0.5">
        <ng-content select=".header-buttons"></ng-content>
      </div>
    </div>
    <div class="flex flex-1 items-center gap-2">
      <ng-content select=".drag-handle"></ng-content>
      <ng-content select=".checkbox"></ng-content>
      <ng-content select=".attributed-to"></ng-content>
      <ng-content select=".change-treatment-package"></ng-content>
      <ng-container *ngIf="item$ | async as item">
        <pr-charted-items-surface-list
          *ngIf="config$ | async as config"
          [chartedSurfaces]="item.chartedSurfaces"
          [availableSurfaces]="config.availableSurfaces"
          [ngClass]="{ highlighted: highlighted }"
          [disabled]="item | map: isMultiTreatment"
          (editSurfaces)="editSurfaces.emit()"
         />
      </ng-container>
      <ng-content select=".header-info"></ng-content>
      <span class="flex-1"></span>
      <span class="price">{{ price$ | async | currency }}</span>
    </div>
  </div>

  <ng-content></ng-content>
</div>
