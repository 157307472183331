import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { Appointment, Patient } from '@principle-theorem/principle-core';
import {
  filterUndefined,
  HISTORY_DATE_FORMAT,
  multiMap,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-patient-last-visit',
    templateUrl: './patient-last-visit.component.html',
    styleUrls: ['./patient-last-visit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientLastVisitComponent {
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  lastAppointment$: Observable<WithRef<IAppointment> | undefined>;
  lastTreatments$: Observable<string>;
  readonly dateFormat = HISTORY_DATE_FORMAT;

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor() {
    this.lastAppointment$ = this._getLastAppointment$();
    this.lastTreatments$ = this._getLastTreatments$();
  }

  private _getLastAppointment$(): Observable<
    WithRef<IAppointment> | undefined
  > {
    return this.patient$.pipe(
      switchMap((patient: WithRef<IPatient>) =>
        patient ? Patient.lastCompletedAppointment$(patient) : of(undefined)
      )
    );
  }

  private _getLastTreatments$(): Observable<string> {
    return this.lastAppointment$.pipe(
      filterUndefined(),
      switchMap((appointment) => Appointment.treatmentStep$(appointment)),
      map((treatmentStep) => (treatmentStep ? treatmentStep.treatments : [])),
      multiMap((treatment) => treatment.config.name),
      map((treatments) => treatments.join('\n'))
    );
  }
}
