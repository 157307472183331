import type { LogSeverity } from 'firebase-functions/lib/logger';
import { isString } from 'lodash';
import { isObject } from '../../common';
import { AtLeast } from '../../utility-types';

export interface IAddLogRequest {
  severity: LogSeverity;
  message: string;
  jsonPayload: object;
}

export class AddLogRequest {
  static init(data: AtLeast<IAddLogRequest, 'message'>): IAddLogRequest {
    return {
      severity: 'INFO',
      jsonPayload: {},
      ...data,
    };
  }
}

export function isAddLogRequest(data: unknown): data is IAddLogRequest {
  return (
    isObject(data) &&
    isString(data.severity) &&
    isString(data.message) &&
    isObject(data.jsonPayload)
  );
}
