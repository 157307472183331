import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  OptionGroupSearchFilter,
  TypedAbstractControl,
  TypedFormControl,
  formControlChanges$,
  toSearchStream,
} from '@principle-theorem/ng-shared';
import {
  ICustomReportDefinedColumn,
  MeasureFormatter,
  MeasureReducer,
} from '@principle-theorem/principle-core/interfaces';
import {
  CanBeChartedProperty,
  ICanBeChartedProperty,
  ICanGroupMeasuresProperty,
} from '@principle-theorem/reporting';
import {
  ComparableValue,
  filterUndefined,
  multiMap,
  shareReplayCold,
} from '@principle-theorem/shared';
import { isString, uniq } from 'lodash';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ReportBuilderColumns } from '../../../../models/report-builder-data-sources/report-builder-columns';
import {
  IGroupBySection,
  IGroupBySectionOption,
  IReportBuilderDataSource,
} from '../../../../models/report-builder-data-sources/report-builder-data-source';
import { getD3NumberFormatter } from '../../../core/chart-builders/dc-chart-builders/dc-single-value-chart-builder';
import { ReportBuilderStore, toWithIds } from '../../report-builder.store';
import {
  IResolvedColumnProperty,
  IResolvedColumnSection,
  ResolvedColumns,
} from '../report-builder-column-selector-dialog/resolved-columns';

export class ReportBuilderManageChartBloc {
  dataPointSearchCtrl = new TypedFormControl<string>();
  dataPointSearch: OptionGroupSearchFilter<IResolvedColumnProperty>;
  resolvedDataPointSections$: Observable<IResolvedColumnSection[]>;
  groupBySearchCtrl = new TypedFormControl<string>();
  groupBySearch: OptionGroupSearchFilter<IGroupBySectionOption>;
  resolvedGroupBySections$: Observable<IGroupBySection[]>;
  filterOptions$: Observable<ComparableValue[]>;
  reducerOptions$ = new ReplaySubject<MeasureReducer[]>(1);
  incompatibleGroupBySummary$: Observable<string | undefined>;

  constructor(
    private _store: ReportBuilderStore,
    filteredData: object[] = [],
    private _onDestroy$: Observable<void>,
    public formControls: Partial<{
      measure: TypedAbstractControl<ICanBeChartedProperty>;
      groupBy: TypedAbstractControl<ICanGroupMeasuresProperty>;
      reducer: TypedAbstractControl<MeasureReducer>;
      filters: TypedAbstractControl<ComparableValue[]>;
    }>,
    private _excludeDataPointFormatters: MeasureFormatter[]
  ) {
    const measure$ = this.formControls.measure
      ? formControlChanges$(this.formControls.measure).pipe(shareReplayCold())
      : of(undefined);

    this.filterOptions$ = measure$.pipe(
      map((measure) => {
        if (!measure) {
          return [];
        }

        if (!this.canFilterDataPoint(measure)) {
          return [];
        }

        return uniq(
          filteredData.map((row) => measure.measure.dataAccessor(row)).flat()
        ).sort();
      }),
      shareReplayCold()
    );

    this.filterOptions$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((filterOptions) => {
        this.formControls.filters?.setValue([]);

        if (!filterOptions.length) {
          this.formControls.filters?.disable();
          this.formControls.filters?.updateValueAndValidity();
          return;
        }

        this.formControls.filters?.enable();
        this.formControls.filters?.updateValueAndValidity();
      });

    if (this.formControls.measure) {
      measure$.pipe(takeUntil(this._onDestroy$)).subscribe((measure) => {
        if (!measure) {
          this.reducerOptions$.next([MeasureReducer.Count]);
          return;
        }

        if (!this.canSummariseDataPoint(measure)) {
          this.formControls.reducer?.setValue(MeasureReducer.Count);
          this.formControls.reducer?.disable();
          this.reducerOptions$.next([MeasureReducer.Count]);
          return;
        }

        this.formControls.reducer?.enable();
        this.reducerOptions$.next([MeasureReducer.Average, MeasureReducer.Sum]);
      });
    }

    this.resolvedDataPointSections$ = this._store.dataSource$.pipe(
      filterUndefined(),
      map((dataSource) => this._toResolvedDataPointSections(dataSource))
    );

    this.dataPointSearch = new OptionGroupSearchFilter<IResolvedColumnProperty>(
      this.resolvedDataPointSections$.pipe(
        multiMap((section) => ({
          name: section.name,
          options: section.columns.map((column) => column),
          skipFilter: false,
        }))
      ),
      toSearchStream(this.dataPointSearchCtrl),
      ['property.metadata.label', 'property.metadata.summary']
    );

    this.resolvedGroupBySections$ = this._store.dataSource$.pipe(
      map((dataSource) => dataSource?.groupByOptions ?? [])
    );

    this.groupBySearch = new OptionGroupSearchFilter<IGroupBySectionOption>(
      this.resolvedGroupBySections$.pipe(
        multiMap((section) => ({
          ...section,
          skipFilter: false,
        }))
      ),
      toSearchStream(this.groupBySearchCtrl),
      ['measure.metadata.label', 'measure.metadata.summary']
    );

    this.incompatibleGroupBySummary$ = this.formControls.groupBy
      ? formControlChanges$(this.formControls.groupBy).pipe(
          withLatestFrom(this.resolvedGroupBySections$),
          map(([selected, sections]) => {
            if (!selected) {
              return;
            }

            const groupBy = sections
              .flatMap((section) => section.options)
              .find(
                (availableGroupBy) =>
                  availableGroupBy.measure.metadata.id === selected.metadata.id
              );

            if (!groupBy) {
              return;
            }

            const reducer = this.formControls.reducer?.value;
            const isCompatible = this.isCompatibleWithReducer(
              groupBy,
              reducer ?? undefined
            );

            if (isCompatible) {
              return;
            }

            return this.getCompatibleReducerSummary(groupBy);
          })
        )
      : of('');
  }

  dataPointSelected(property?: CanBeChartedProperty): void {
    if (!property) {
      return;
    }
    this.formControls.measure?.setValue(property);
  }

  groupBySelected(event: MatAutocompleteSelectedEvent): void {
    if (!event.option.value) {
      return;
    }
    this.formControls.groupBy?.setValue(event.option.value);
  }

  blurMeasure(): void {
    if (isString(this.dataPointSearchCtrl.value)) {
      this.dataPointSearchCtrl.setValue('', { emitEvent: false });
      this.formControls.measure?.reset(undefined);
    }
  }

  blurGroupBy(): void {
    if (isString(this.groupBySearchCtrl.value)) {
      this.groupBySearchCtrl.setValue('', { emitEvent: false });
      this.formControls.groupBy?.reset(undefined);
    }
  }

  isCompatibleWithReducer$(option: IGroupBySectionOption): Observable<boolean> {
    if (!this.formControls.reducer) {
      return of(false);
    }
    return formControlChanges$(this.formControls.reducer).pipe(
      map((reducer) => this.isCompatibleWithReducer(option, reducer))
    );
  }

  isCompatibleWithReducer(
    option: IGroupBySectionOption,
    reducer?: MeasureReducer
  ): boolean {
    if (!reducer) {
      return false;
    }

    return option.compatibleReducers.includes(reducer);
  }

  convertReducer(value: MeasureReducer): string {
    switch (value) {
      case MeasureReducer.Sum:
        return 'Total value of data point';
      case MeasureReducer.Average:
        return 'Average value of data point';
      case MeasureReducer.Count:
        return 'Count the number of times the filtered values appear';
      default:
        return value;
    }
  }

  getCompatibleReducerSummary(
    option: IGroupBySectionOption
  ): string | undefined {
    if (option.compatibleReducers.length > 1) {
      const reducerSummary = option.compatibleReducers
        .map((reducer) => this.convertReducer(reducer))
        .join('; ');

      return `This option is only compatible with data points that are calculated with: ${reducerSummary}`;
    }

    if (option.compatibleReducers.length === 0) {
      return 'This option is not compatible with any data points';
    }

    if (option.compatibleReducers[0] === MeasureReducer.Count) {
      return 'The selection option may give misleading results for for currency and number based data points';
    }

    return undefined;
  }

  canSummariseDataPoint(value?: ICanBeChartedProperty): boolean {
    return !!getD3NumberFormatter(
      value?.metadata.formatter,
      value?.metadata.formatterValue
    );
  }

  canFilterDataPoint(value?: ICanBeChartedProperty): boolean {
    return !getD3NumberFormatter(
      value?.metadata.formatter,
      value?.metadata.formatterValue
    );
  }

  convertBoolean(value: ComparableValue): ComparableValue {
    if (value === true) {
      return 'Yes';
    }

    if (value === false) {
      return 'No';
    }

    return value;
  }

  displayFn(value?: CanBeChartedProperty | string): string {
    if (!value) {
      return '';
    }

    if (isString(value)) {
      return value;
    }

    return value.metadata.label;
  }

  private _toResolvedDataPointSections(
    dataSource: IReportBuilderDataSource
  ): IResolvedColumnSection[] {
    const columnGroups = ReportBuilderColumns.mergeColumnGroups([
      ...dataSource.availableColumns,
      ReportBuilderColumns.toColumnGroup('general', [
        dataSource.factTable.count,
      ]),
    ]);

    return columnGroups
      .map((section) => ({
        ...section,
        columns: this._filterColumns(section.columns),
      }))
      .map((section) => {
        return {
          ...section,
          columns: ResolvedColumns.toColumns(
            dataSource,
            toWithIds(section.columns)
          ),
        };
      });
  }

  private _filterColumns(
    columns: ICustomReportDefinedColumn[]
  ): ICustomReportDefinedColumn[] {
    return columns.filter((column) => {
      if (column.id.endsWith('.count')) {
        return false;
      }

      const formatter =
        column.measure.metadata.formatter ?? MeasureFormatter.Text;
      const hasExcludedFormatter =
        this._excludeDataPointFormatters.includes(formatter);
      return !hasExcludedFormatter;
    });
  }
}
