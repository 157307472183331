import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { type Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import {
  clearBrandSlug,
  clearPracticeSlug,
  setBrandSlug,
  setPracticeSlug,
} from '../actions/current-scope.actions';
import { CurrentScopeFacade } from '../current-scope.facade';

/**
 * The below sections will be left commented until we decide a refactor path to leverage the
 * store for currentScope over the existing scope services.
 * https://app.clickup.com/t/1wwcbu
 */

@Injectable()
export class CurrentScopeEffects {
  // private _unsubscribeBrand$: Subject<void> = new Subject();
  // private _unsubscribePractice$: Subject<void> = new Subject();
  private _actions$ = inject(Actions);
  private _principleCoreFacade = inject(CurrentScopeFacade);

  handleBrandSlug$: Observable<Action> = createEffect(() =>
    this._handleBrandSlug$()
  );
  handlePracticeSlug$: Observable<Action> = createEffect(() =>
    this._handlePracticeSlug$()
  );
  // setBrand$: Observable<Action> = createEffect(() => this._setBrand$());
  // clearBrand$: Observable<Action> = createEffect(() => this._clearBrand$());
  // setPractice$: Observable<Action> = createEffect(() => this._setPractice$());
  // clearPractice$: Observable<Action> = createEffect(() =>
  //   this._clearPractice$()
  // );

  private _handleBrandSlug$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(routerNavigatedAction),
      switchMap(() => this._principleCoreFacade.brandSlug$),
      distinctUntilChanged(),
      map((slug) => (slug ? setBrandSlug({ slug }) : clearBrandSlug()))
    );
  }

  private _handlePracticeSlug$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(routerNavigatedAction),
      switchMap(() => this._principleCoreFacade.practiceSlug$),
      distinctUntilChanged(),
      map((slug) => (slug ? setPracticeSlug({ slug }) : clearPracticeSlug()))
    );
  }

  // private _setBrand$(): Observable<Action> {
  //   return this._actions$.pipe(
  //     ofType(setBrandSlug),
  //     switchMap(({ slug }) => {
  //       if (!slug) {
  //         return of(undefined);
  //       }
  //       return this._organisation.brandCol$.pipe(
  //         switchMap(
  //           (brandCol) =>
  //             brandCol.query((query) =>
  //               query.where('slug', '==', slug).limit(1)
  //             ).all$
  //         ),
  //         takeUntil(this._unsubscribeBrand$),
  //         map((brands) => brands[0]),
  //         take(1)
  //       );
  //     }),
  //     serialise$(),
  //     map((brand) =>
  //       brand
  //         ? loadBrandSuccess({ brand })
  //         : loadBrandFail({ error: 'Failed to Load Brand' })
  //     )
  //   );
  // }

  // private _clearBrand$(): Observable<Action> {
  //   return this._actions$.pipe(
  //     ofType(clearBrandSlug),
  //     tap(() => this._unsubscribeBrand$.next()),
  //     map(() => clearBrand())
  //   );
  // }

  // private _setPractice$(): Observable<Action> {
  //   return this._actions$.pipe(
  //     ofType(setPracticeSlug),
  //     switchMap(({ slug }) => {
  //       if (!slug) {
  //         return of(undefined);
  //       }
  //       return this._principleCoreFacade.currentBrand$.pipe(
  //         filterUndefined(),
  //         switchMap((currentBrand) => {
  //           return Brand.practiceCol(
  //             currentBrand,
  //           ).query((query) => query.where('slug', '==', slug).limit(1)).all$;
  //         }),
  //         takeUntil(this._unsubscribePractice$),
  //         map((practices) => (practices ? practices[0] : undefined)),
  //         take(1)
  //       );
  //     }),
  //     serialise$(),
  //     map((practice) =>
  //       practice
  //         ? loadPracticeSuccess({ practice })
  //         : loadPracticeFail({ error: 'Failed to Load Practice' })
  //     )
  //   );
  // }

  // private _clearPractice$(): Observable<Action> {
  //   return this._actions$.pipe(
  //     ofType(clearPracticeSlug),
  //     tap(() => this._unsubscribePractice$.next()),
  //     map(() => clearPractice())
  //   );
  // }
}
