import {
  IHealthPointTransactionExtendedData,
  isHealthPointTransactionExtendedData,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import { toMoment } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ITransactionAction,
  ITransactionActionsData,
} from '../../../transaction-action';
import { TransactionHelpers } from '../../../transaction-helpers';
import { TyroHealthPointTransactionProvider } from '../tyro-health-point-transaction-provider.service';

export class CancelHealthPointTransaction
  implements ITransactionAction<IHealthPointTransactionExtendedData>
{
  icon = 'cancel';
  label = 'Cancel';
  tooltip = 'Can only be cancelled on the same day';
  inProgress$ = new BehaviorSubject<boolean>(false);
  typeGuardFn = isHealthPointTransactionExtendedData;

  constructor(private _provider: TyroHealthPointTransactionProvider) {}

  canDo$(
    data: ITransactionActionsData<IHealthPointTransactionExtendedData>
  ): Observable<boolean> {
    return TransactionHelpers.lastCompletedTransaction$(
      data,
      isHealthPointTransactionExtendedData
    ).pipe(
      map((transaction) => {
        if (!transaction) {
          return false;
        }
        const isSuccessfulClaim =
          transaction.type === TransactionType.Incoming &&
          transaction.status === TransactionStatus.Complete;

        const now = moment();
        const createdAt = toMoment(transaction.createdAt);
        const isSameDay = now.isSame(createdAt, 'day');

        return isSuccessfulClaim && isSameDay;
      })
    );
  }

  async do(
    data: ITransactionActionsData<IHealthPointTransactionExtendedData>
  ): Promise<void> {
    this.inProgress$.next(true);
    await this._provider.cancel(
      data.invoice,
      data.latestTransaction,
      data.claim
    );
    this.inProgress$.next(false);
  }
}
