import {
  IAppointmentScopeData,
  IAppointmentTemplateContext,
  IContextBuilder,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  Timezone,
  TIME_FORMAT,
  toMoment,
  CALENDAR_TIME_FORMAT_LOWERCASE,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Staffer } from '../../models/staffer/staffer';
import { getMissingContextPlaceholder } from '../template-context-resolver';
import { PatientContextBuilder } from './patient-context';
import { PracticeContextBuilder } from './practice-context';

export class AppointmentContextBuilder
  implements IContextBuilder<IAppointmentTemplateContext>
{
  private _patientContextBuilder: PatientContextBuilder;
  private _practiceContextBuilder: PracticeContextBuilder;
  private _timezone: Timezone;

  constructor(private _data: IAppointmentScopeData) {
    this._patientContextBuilder = new PatientContextBuilder(this._data);
    this._practiceContextBuilder = new PracticeContextBuilder(this._data);
    this._timezone = this._data.practice.settings.timezone;
  }

  build(): IAppointmentTemplateContext {
    const appointmentStartDate = this._data.appointment.event
      ? moment(toMoment(this._data.appointment.event.from))
          .tz(this._timezone)
          .calendar(undefined, CALENDAR_TIME_FORMAT_LOWERCASE)
      : 'Unscheduled';

    const appointmentStartTime = this._data.appointment.event
      ? moment(toMoment(this._data.appointment.event.from))
          .tz(this._timezone)
          .format(TIME_FORMAT)
      : 'Unscheduled';

    const appointmentEndTime = this._data.appointment.event
      ? moment(toMoment(this._data.appointment.event.to))
          .tz(this._timezone)
          .format(TIME_FORMAT)
      : 'Unscheduled';

    const appointmentDate = this._data.appointment.event
      ? moment(toMoment(this._data.appointment.event.from))
          .tz(this._timezone)
          .format(DAY_MONTH_YEAR_FORMAT)
      : 'Unscheduled';

    const providerData = Staffer.getProviderData(
      this._data.practitioner,
      this._data.practice.ref
    );
    return {
      ...this._patientContextBuilder.build(),
      ...this._practiceContextBuilder.build(),
      appointmentPractitionerFullName: this._data.appointment.practitioner.name,
      appointmentPractitionerProviderNumber:
        providerData?.providerNumber ??
        getMissingContextPlaceholder('providerNumber'),
      appointmentTreatmentPlanName: this._data.appointment.treatmentPlan.name,
      appointmentTreatmentStepName:
        this._data.appointment.treatmentPlan.treatmentStep.name,
      appointmentStartDate,
      appointmentStartTime,
      appointmentEndTime,
      appointmentDate,
      appointmentConfirmationUrl: `${this._data.confirmLink} `,
    };
  }
}
