import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { AnyCustomFormElement } from '@principle-theorem/principle-core/interfaces';
import { compact } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface IAddElementEvent {
  parentUid: string;
  item: AnyCustomFormElement;
  index: number;
}

interface IMoveElementEvent {
  previousContainerId: string;
  previousContainerChildren: AnyCustomFormElement[];
  previousIndex: number;
  currentContainerId: string;
  currentContainerChildren: AnyCustomFormElement[];
  currentIndex: number;
}

interface IFormBuilderState {
  editAll: boolean;
  layout?: AnyCustomFormElement[];
  selectedUid?: string;
  dropListIds?: string[];
}

@Injectable()
export class FormBuilderService extends ComponentStore<IFormBuilderState> {
  readonly dropListIds$ = this.select((state) => state.dropListIds ?? []);
  readonly layout$ = this.select((state) => state).pipe(
    map((state) => state.layout)
  );
  readonly selectedUid$ = this.select((state) => state.selectedUid);
  readonly selected$ = this.select(
    this.layout$,
    this.selectedUid$,
    (layout, selectedUid) => this._findSelected(layout, selectedUid)
  );
  readonly dragging$ = new BehaviorSubject<boolean>(false);

  readonly registerDropLists = this.updater<string[]>((state, ids) =>
    this._registerDropListIds(state, ids)
  );
  readonly unregisterDropLists = this.updater<string[]>((state, ids) =>
    this._unregisterDropListIds(state, ids)
  );
  readonly setLayout = this.updater<AnyCustomFormElement[]>(
    (state, layout) => ({ ...state, layout })
  );
  readonly setEditAll = this.updater<boolean>((state, editAll) => ({
    ...state,
    editAll,
  }));
  readonly addElement = this.updater<IAddElementEvent>((state, event) =>
    this._addElement(state, event)
  );
  readonly removeElement = this.updater<string>((state, itemUid) =>
    this._removeElement(state, itemUid)
  );
  readonly updateElement = this.updater<AnyCustomFormElement>(
    (state, element) => this._updateElement(state, element)
  );
  readonly moveElement = this.updater<IMoveElementEvent>((state, event) =>
    this._moveElement(state, event)
  );
  readonly selectElement = this.updater<string | undefined>(
    (state, selectedUid) => ({ ...state, selectedUid })
  );

  constructor() {
    super({ editAll: false });
  }

  isSelected$(element: AnyCustomFormElement): Observable<boolean> {
    return this.selected$.pipe(
      map((selected) => selected?.uid === element.uid)
    );
  }

  private _addElement(
    state: IFormBuilderState,
    event: IAddElementEvent
  ): IFormBuilderState {
    if (!state.layout) {
      return state;
    }
    const parent = this._findElementByUid(state.layout, event.parentUid);
    const parentArray = parent?.children ?? state.layout;
    parentArray.splice(event.index, 0, event.item);
    return { ...state, selectedUid: event.item.uid };
  }

  private _removeElement(
    state: IFormBuilderState,
    itemUid: string
  ): IFormBuilderState {
    if (!state.layout) {
      return state;
    }
    const parent = this._findElementParentByUid(state.layout, itemUid);
    const parentArray = parent?.children ?? state.layout;
    const childIndex = parentArray.findIndex((child) => child.uid === itemUid);
    if (childIndex === undefined || childIndex < 0) {
      return state;
    }
    parentArray.splice(childIndex, 1);
    return { ...state };
  }

  private _updateElement(
    state: IFormBuilderState,
    element: AnyCustomFormElement
  ): IFormBuilderState {
    if (!state.layout) {
      return state;
    }
    const parent = this._findElementParentByUid(state.layout, element.uid);
    const parentArray = parent?.children ?? state.layout;
    const childIndex = parentArray.findIndex(
      (child) => child.uid === element.uid
    );
    if (childIndex === undefined || childIndex < 0) {
      return state;
    }
    parentArray.splice(childIndex, 1, element);
    return { ...state };
  }

  private _moveElement(
    state: IFormBuilderState,
    event: IMoveElementEvent
  ): IFormBuilderState {
    if (event.previousContainerId === event.currentContainerId) {
      moveItemInArray(
        event.currentContainerChildren,
        event.previousIndex,
        event.currentIndex
      );
      return { ...state };
    }
    transferArrayItem(
      event.previousContainerChildren,
      event.currentContainerChildren,
      event.previousIndex,
      event.currentIndex
    );
    return { ...state };
  }

  private _registerDropListIds(
    state: IFormBuilderState,
    ids: string[]
  ): IFormBuilderState {
    return {
      ...state,
      dropListIds: compact([state.dropListIds, ids]).flat(),
    };
  }

  private _unregisterDropListIds(
    state: IFormBuilderState,
    ids: string[]
  ): IFormBuilderState {
    return {
      ...state,
      dropListIds: state.dropListIds?.filter((id) => !ids.includes(id)),
    };
  }

  private _findSelected(
    layout?: AnyCustomFormElement[],
    selectedUid?: string
  ): AnyCustomFormElement | undefined {
    if (!layout || !selectedUid) {
      return;
    }
    return this._findElementByUid(layout, selectedUid);
  }

  private _findElementByUid(
    elements: AnyCustomFormElement[],
    uid: string
  ): AnyCustomFormElement | undefined {
    for (const element of elements) {
      if (element.uid === uid) {
        return element;
      }
      const found = this._findElementByUid(element.children, uid);
      if (found) {
        return found;
      }
    }
  }

  private _findElementParentByUid(
    elements: AnyCustomFormElement[],
    uid: string
  ): AnyCustomFormElement | undefined {
    for (const element of elements) {
      if (element.children.some((child) => child.uid === uid)) {
        return element;
      }
      const found = this._findElementParentByUid(element.children, uid);
      if (found) {
        return found;
      }
    }
  }
}
