import { Money } from '@principle-theorem/accounting';
import { getBaseTransaction } from '@principle-theorem/principle-core';
import {
  ISmartpayPurchaseTransactionExtendedData,
  SmartpayTransactionResult,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
  type IInvoice,
  type IPractice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  toInt,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { Transaction } from '../transaction/transaction';

export class Smartpay {
  static async toPurchaseTransaction(
    provider: TransactionProvider.SmartpayCard | TransactionProvider.SmartpayQR,
    invoice: WithRef<IInvoice>,
    practiceRef: DocumentReference<IPractice>,
    request: ISmartpayPurchaseTransactionExtendedData['request'],
    response: ISmartpayPurchaseTransactionExtendedData['response']
  ): Promise<ITransaction<ISmartpayPurchaseTransactionExtendedData>> {
    const base = await Smartpay.toTransaction(
      provider,
      invoice,
      practiceRef,
      response
    );
    return Transaction.init({
      ...base,
      extendedData: {
        request,
        response,
      },
    });
  }

  static async toPurchaseRefundTransaction(
    provider: TransactionProvider.SmartpayCard | TransactionProvider.SmartpayQR,
    invoice: WithRef<IInvoice>,
    practiceRef: DocumentReference<IPractice>,
    request: ISmartpayPurchaseTransactionExtendedData['request'],
    response: ISmartpayPurchaseTransactionExtendedData['response'],
    initialTransaction?: WithRef<
      ITransaction<ISmartpayPurchaseTransactionExtendedData>
    >
  ): Promise<ITransaction<ISmartpayPurchaseTransactionExtendedData>> {
    const base = await Smartpay.toTransaction(
      provider,
      invoice,
      practiceRef,
      response,
      TransactionType.Outgoing
    );
    return Transaction.init({
      ...base,
      reference: initialTransaction?.reference || base.reference,
      extendedData: {
        request,
        response,
      },
    });
  }

  static async toTransaction(
    provider: TransactionProvider,
    invoice: WithRef<IInvoice>,
    practiceRef: DocumentReference<IPractice>,
    response: ISmartpayPurchaseTransactionExtendedData['response'],
    type: TransactionType = TransactionType.Incoming
  ): Promise<ITransaction> {
    return Transaction.init({
      ...(await getBaseTransaction(invoice, type)),
      amount: Money.fromCents(toInt(response.data.AmountTotal)),
      reference: response.transactionId,
      status: Smartpay.getStatus(response),
      provider,
      practiceRef,
    });
  }

  static getStatus(
    data: ISmartpayPurchaseTransactionExtendedData['response']
  ): TransactionStatus {
    if (
      data.transactionStatus === 'PENDING' ||
      data.data.TransactionResult === SmartpayTransactionResult.Delayed
    ) {
      return TransactionStatus.Pending;
    }

    if (data.data.TransactionResult === SmartpayTransactionResult.Accepted) {
      return TransactionStatus.Complete;
    }

    return TransactionStatus.Failed;
  }
}
