<mat-toolbar color="accent">Select TWAIN Device</mat-toolbar>

<mat-dialog-content class="!text-inherit" *ngrxLet="isSyncing$ as syncing">
  <div class="flex flex-col gap-6 pb-4">
    <div class="h-6 self-end text-xs">
      @if (syncing) {
        <div class="text-primary-400 flex items-center gap-2">
          <mat-spinner [diameter]="18" />
          <span>Syncing devices</span>
        </div>
      } @else {
        <div class="text-success-400 flex items-center gap-2">
          <mat-icon>done</mat-icon>
          <div>Devices synced</div>
        </div>
      }
    </div>

    @if (allDevices$ | async; as allDevices) {
      @if (allDevices.length) {
        @if (enabledDevices$ | async; as enabledDevices) {
          <div
            *ngrxLet="bridgeDevice$ as bridgeDevice"
            class="flex flex-col gap-2"
          >
            @for (device of enabledDevices; track device.uid) {
              <div class="flex items-center gap-2">
                <button
                  class="flex-1 !justify-start"
                  mat-flat-button
                  (click)="dialogRef.close(device)"
                >
                  {{ device.name }}
                </button>

                <div class="flex gap-4">
                  @if (device.multiImageEnabled) {
                    <mat-icon
                      color="primary"
                      class="opacity-80"
                      matTooltip="Multiple Images Enabled"
                      >burst_mode</mat-icon
                    >
                  }

                  @if (device.vistaSoftConnectEnabled) {
                    <mat-icon
                      color="primary"
                      class="opacity-80"
                      matTooltip="VistaSoft Connect Enabled"
                      >visibility</mat-icon
                    >
                  }
                </div>

                <pr-twain-device-settings
                  [twainDeviceUid]="device.uid"
                  [bridgeDevice]="bridgeDevice"
                  [disabled]="syncing"
                />
              </div>
            }
          </div>
        }

        @if (disabledDevices$ | async; as disabledDevices) {
          @if (disabledDevices.length) {
            <div>
              <div
                class="my-2 flex cursor-pointer items-center px-1.5"
                (click)="showDisabled.set(!showDisabled())"
              >
                <span class="flex-1">Deleted Devices</span>
                <mat-icon>
                  {{ showDisabled() ? 'expand_less' : 'expand_more' }}
                </mat-icon>
              </div>

              @if (showDisabled()) {
                <div class="flex flex-col gap-2 pl-4 text-sm">
                  @for (device of disabledDevices; track device.uid) {
                    <div class="flex items-center">
                      <span class="flex-1">{{ device.name }}</span>
                      <button
                        mat-icon-button
                        matTooltip="Restore Device"
                        [disabled]="syncing"
                        (click)="restoreDevice(device.uid)"
                      >
                        <mat-icon class="opacity-70">
                          restore_from_trash
                        </mat-icon>
                      </button>
                    </div>
                  }
                </div>
              }
            </div>
          }
        }
      } @else {
        <pr-empty-state image="list" title="devices" />
      }
    }
  </div>
</mat-dialog-content>
