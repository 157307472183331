import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgEditorModule } from '@principle-theorem/ng-editor';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { FollowUpBodyComponent } from './follow-up-body/follow-up-body.component';
import { FollowUpComponent } from './follow-up/follow-up.component';
import { CancelFollowUpComponent } from './cancel-follow-up/cancel-follow-up.component';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FollowUpListItemComponent } from './follow-up-list-item/follow-up-list-item.component';
import { FollowUpListItemsComponent } from './follow-up-list-items/follow-up-list-items.component';
import { FollowUpListViewComponent } from './follow-up-list-view/follow-up-list-view.component';
import { FollowUpListGroupedItemsComponent } from './follow-up-list-grouped-items/follow-up-list-grouped-items.component';

@NgModule({
  imports: [
    NgMaterialModule,
    ReactiveFormsModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    NgInteractionsModule,
    NgEditorModule,
    NgxSkeletonLoaderModule,
  ],
  declarations: [
    FollowUpBodyComponent,
    FollowUpComponent,
    CancelFollowUpComponent,
    FollowUpListItemComponent,
    FollowUpListViewComponent,
    FollowUpListItemsComponent,
    FollowUpListGroupedItemsComponent,
  ],
  exports: [
    FollowUpBodyComponent,
    FollowUpComponent,
    CancelFollowUpComponent,
    FollowUpListItemComponent,
    FollowUpListViewComponent,
    FollowUpListItemsComponent,
  ],
})
export class FollowUpsComponentsModule {}
