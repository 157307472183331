import { MeasureFormatter } from '@principle-theorem/principle-core/interfaces';
import { ITaskCompletedFact } from '@principle-theorem/reporting/interfaces';
import { BigQueryTable } from '../../big-query-tables';
import {
  BrandQueryScopeConfig,
  DateRangeQueryScopeConfig,
  PracticeQueryScopeConfig,
} from '../../query-scope-config/query-scope-configs';
import { BaseFactMeasures } from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import { BrandDimensionMeasures } from '../dimensions/brand-dimension';
import { PracticeDimensionMeasures } from '../dimensions/practice-dimension';
import {
  ComparableProperties,
  MeasureTransformMap,
} from '../measure-properties';
import { CanBeChartedProperty } from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';

// export class TaskCompletedFact extends BaseDimension<Fact>
//   implements
//   NestedDimensionTransformMap<NestedDimensionProperties<Fact>>,
//   DimensionTransformMap<DateProperties<Fact>>,
//   DimensionTransformMap<ArrayProperties<Fact>>,
//   DimensionTransformMap<ComparableProperties<Fact>> {

//   get task(): ITask {
//     return {} as ITask;
//   }

//   get recurringConfiguration(): IRecurringTask {
//     return {} as IRecurringTask;
//   }

//   get owner(): IStaffer {
//     return {} as IStaffer;
//   }

//   get assignedUser(): IStaffer {
//     return {} as IStaffer;
//   }

//   get assignedTeam(): ITeam {
//     return {} as ITeam;
//   }
// }

export class TaskCompletedFactMeasures
  extends BaseFactMeasures
  implements MeasureTransformMap<ComparableProperties<ITaskCompletedFact>>
{
  id = 'taskCompleted';
  readonly table = BigQueryTable.TaskCompleted;
  readonly name = 'Completed Tasks';
  scopes = [
    new BrandQueryScopeConfig(),
    new PracticeQueryScopeConfig(),
    new DateRangeQueryScopeConfig(),
  ];

  get practice(): PracticeDimensionMeasures {
    return new PracticeDimensionMeasures(BigQueryTable.Practices, 'practice', {
      sourceJoinKey: MeasurePath.docRef('practice.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get brand(): BrandDimensionMeasures {
    return new BrandDimensionMeasures(BigQueryTable.Brands, 'brand', {
      sourceJoinKey: MeasurePath.docRef('brand.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get isOverdue(): CanBeChartedProperty {
    const propertyName = 'isOverdue';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.boolean(
      {
        id: propertyName,
        label: 'Is Overdue',
        summary: 'Whether the task is overdue or not',
        formatter: MeasureFormatter.Boolean,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get overdueBy(): CanBeChartedProperty {
    const propertyName = 'overdueBy';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Overdue By',
        summary: '',
        formatter: MeasureFormatter.Day,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }
}
