import { Practice, toINamedDocuments } from '@principle-theorem/principle-core';
import {
  CustomMappingType,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  XSLXImporterExporter,
  asyncForEach,
  hardDeleteDoc,
  multiSortBy$,
  nameSorter,
  reduce2DArray,
  snapshot,
  type INamedDocument,
  type IReffable,
  type WithRef,
  IBlobFilenamePair,
} from '@principle-theorem/shared';
import { sortBy, uniq } from 'lodash';
import { combineLatest } from 'rxjs';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import { CORE_PRACTICE_MEDIA_CATEGORY_TAG_NAME_MAP } from '../../source/entities/patient-media';
import { PatientMediaCategorySourceEntity } from '../../source/entities/patient-media-categories';
import { MediaCategoriesToXSLX } from './media-categories-to-xlsx';
import { XSLXToMediaCategories } from './xlsx-to-media-categories';

const PATIENT_MEDIA_CATEGORY_CUSTOM_MAPPING_TYPE =
  'patientMediaCategoryCustomMapping';

export const MEDIA_CATEGORIES_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Media Categories',
    description:
      'This allows us to map the subfolder of a Core Practice file as a tag when migrating to Principle. We first need to copy the patient files into the migration bucket before the source categories can be populated.',
    type: PATIENT_MEDIA_CATEGORY_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.DocumentReference,
});

export class CorePracticeMediaTagMappingHandler extends BaseCustomMappingHandler<ITag> {
  customMapping = MEDIA_CATEGORIES_MAPPING;

  async getSourceOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const options = await this.getMediaCategoryOptions(migration);

    return sortBy(
      options.map((name) => ({
        label: name,
        value: name,
      })),
      'label'
    );
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<ITag>[]> {
    return snapshot(
      combineLatest(
        migration.configuration.practices.map((practice) =>
          Practice.mediaTags$(practice)
        )
      ).pipe(reduce2DArray(), toINamedDocuments(), multiSortBy$(nameSorter()))
    );
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, mediaCategories, translator } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(
      fileName,
      mediaCategories,
      translator
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, mediaCategories, translator } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(
      fileName,
      mediaCategories,
      translator
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToMediaCategories()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const records = await this.getRecords(translationMap);
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));

    const sourceOptions = await this.getSourceOptions(migration);
    const mediaTags = await this.getDestinationOptions(migration);

    await asyncForEach(items, async (item) => {
      const label = item.category;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.label === label
      )?.value;
      if (!value) {
        return;
      }

      const matches = new RegExp(/^tag\(([a-zA-Z0-9]+)\).*$/).exec(item.mapTo);
      const tagId = matches ? matches[1] : undefined;

      if (!tagId) {
        return;
      }

      const tag = mediaTags.find((mediaTag) => mediaTag.ref.id === tagId);

      if (!tag) {
        // eslint-disable-next-line no-console
        console.error(
          `Mapping error: ${this.customMapping.metadata.label} - Couldn't find tag for item`,
          item
        );
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: tag.ref,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  async getMediaCategoryOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<string[]> {
    const entity = new PatientMediaCategorySourceEntity();
    const records = await entity.getRecords$(migration, 1000).toPromise();

    const options = records.reduce((current: string[], record) => {
      if (!record.data.data.id) {
        return current;
      }

      const category =
        CORE_PRACTICE_MEDIA_CATEGORY_TAG_NAME_MAP[record.data.data.id];
      if (!category) {
        return current;
      }

      return uniq([...current, category]);
    }, []);

    return options.sort();
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    mediaCategories: string[];
    translator: MediaCategoriesToXSLX;
  }> {
    const mediaTags = await this.getDestinationOptions(migration);
    const fileName = this.getFileName();
    const mediaCategories = await this.getMediaCategoryOptions(migration);
    const translator = new MediaCategoriesToXSLX(mediaTags);
    return { fileName, mediaCategories, translator };
  }
}
