import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  type IPerioTable,
  type IPerioTableCell,
} from '@principle-theorem/ng-perio-charting';
import { serialise, unserialise$ } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import {
  loadPerioChartSuccess,
  upsertPerioValue,
} from '../actions/perio-table';
import { ChartId } from '../reducers/active-charts/chart-context-state';
import { type IChartState } from '../reducers/reducers';
import { getPerioChartTables } from '../selectors/perio-chart';

@Injectable()
export class PerioChartFacade {
  private _store = inject(Store<IChartState>);
  perioTables$: Observable<IPerioTable[]>;

  constructor() {
    this.perioTables$ = this._store.pipe(
      select(getPerioChartTables),
      unserialise$()
    );
  }

  setPerioTables(tables: IPerioTable[]): void {
    this._store.dispatch(loadPerioChartSuccess(serialise({ tables })));
  }

  upsertPerioValue(change: IPerioTableCell): void {
    this._store.dispatch(
      upsertPerioValue(serialise({ id: ChartId.InAppointment, change }))
    );
  }
}
