import { Injectable } from '@angular/core';
import { AppointmentManager } from '@principle-theorem/ng-appointment/store';
import {
  OrganisationService,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { Appointment } from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  AppointmentSummary,
  type IAppointment,
  type IPatient,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  IReffable,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { OpenAppointmentActionService } from './open-appointment-action.service';

@Injectable()
export class StartAppointmentActionService extends OpenAppointmentActionService {
  override name = 'Start Appointment';

  constructor(
    _stateNav: StateBasedNavigationService,
    private _organisation: OrganisationService
  ) {
    super(_stateNav);
  }

  override isVisible(
    appointment: WithRef<IAppointment> | AppointmentSummary
  ): boolean {
    return Appointment.checkedIn(appointment);
  }

  override async run(
    appointment: WithRef<IAppointment>,
    patient: IReffable<IPatient>
  ): Promise<void> {
    await this.updateStatus(appointment);
    await super.run(appointment, patient);
  }

  override updateEventableSummary(
    eventable: AppointmentSummary,
    updateFn: (
      oldEvent: AppointmentSummary,
      newEvent: AppointmentSummary
    ) => void
  ): void {
    const metadata = {
      ...eventable.metadata,
      status: AppointmentStatus.InProgress,
    };

    updateFn(eventable, { ...eventable, metadata });
  }

  async updateStatus(appointment: WithRef<IAppointment>): Promise<void> {
    const staffer: WithRef<IStaffer> = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    await AppointmentManager.markInProgress(staffer, appointment);
  }
}
