import {
  AnyCustomFormElement,
  CustomFormContainerElement,
  CustomFormContentElement,
  CustomFormElementCategory,
  CustomFormElementType,
  CustomFormFieldElement,
} from '@principle-theorem/principle-core/interfaces';

export class CustomFormElement {
  static getCategory(
    elementType: CustomFormElementType
  ): CustomFormElementCategory {
    switch (elementType) {
      case CustomFormElementType.Section:
        return CustomFormElementCategory.Section;
      case CustomFormElementType.Container:
        return CustomFormElementCategory.Layout;
      default:
        return CustomFormElementCategory.Field;
    }
  }

  static isLayout(
    element: AnyCustomFormElement
  ): element is CustomFormContainerElement | CustomFormContentElement {
    return [
      CustomFormElementType.Container,
      CustomFormElementType.Content,
    ].includes(element.type);
  }

  static isInputField(
    element: AnyCustomFormElement
  ): element is CustomFormFieldElement {
    return [
      CustomFormElementType.Content,
      CustomFormElementType.Text,
      CustomFormElementType.Textarea,
      CustomFormElementType.Number,
      CustomFormElementType.Checkbox,
      CustomFormElementType.Date,
      CustomFormElementType.Dropdown,
      CustomFormElementType.Signature,
    ].includes(element.type);
  }

  static isHorizontal(element: AnyCustomFormElement): boolean {
    switch (element.type) {
      case CustomFormElementType.Container:
        return element.options.direction === 'row';
      case CustomFormElementType.Section:
        return true;
      default:
        return false;
    }
  }
}
