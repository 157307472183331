import {
  Action,
  ActionReducer,
  createReducer,
  on,
  type MetaReducer,
} from '@ngrx/store';
import {
  type IInvoiceReportRequest,
  type IPractitionerGrouping,
} from '@principle-theorem/reporting';
import { serialise, type SerialisedData } from '@principle-theorem/shared';
import {
  clearSelectedResult,
  inspectResult,
  loadReport,
  loadReportFailure,
  loadReportSuccess,
  setQuery,
  setReportType,
} from './practitioner-income.actions';

export const PRACTITIONER_INCOME_REPORT_FEATURE_KEY =
  'practitioner-income-report';

export enum PractitionerIncomeReportType {
  ByInvoiceIssuedDate = 'byInvoiceIssuedDate',
  ByInvoicePaidDate = 'byInvoicePaidDate',
  ByTransactionDate = 'byTransactionDate',
}

export interface IPractitionerIncomeReportState {
  isLoading: boolean;
  errorMessage?: string;
  results: IPractitionerGrouping[];
  reportType: PractitionerIncomeReportType;
  query?: IInvoiceReportRequest;
  inspected?: IPractitionerGrouping;
}

const initialState: IPractitionerIncomeReportState = {
  isLoading: false,
  results: [],
  reportType: PractitionerIncomeReportType.ByTransactionDate,
};

export const metaReducers: MetaReducer<
  SerialisedData<IPractitionerIncomeReportState>
>[] = [];

export function reducers(
  state: SerialisedData<IPractitionerIncomeReportState> | undefined,
  action: Action
): SerialisedData<IPractitionerIncomeReportState> {
  return practitionerIncomeReportReducer(state, action);
}

export const practitionerIncomeReportReducer: ActionReducer<
  SerialisedData<IPractitionerIncomeReportState>,
  Action
> = createReducer(
  serialise(initialState),

  on(setQuery, (state, action) => ({
    ...state,
    query: action,
  })),

  on(setReportType, (state, action) => ({
    ...state,
    reportType: action.reportType,
  })),

  on(loadReport, (state, _action) => ({
    ...state,
    isLoading: true,
    inspected: undefined,
  })),

  on(loadReportSuccess, (state, action) => ({
    ...state,
    results: action.results,
    isLoading: false,
  })),

  on(loadReportFailure, (state, action) => ({
    ...state,
    results: [],
    isLoading: false,
    errorMessage: action.errorMessage,
  })),

  on(inspectResult, (state, action) => ({
    ...state,
    inspected: action,
  })),

  on(clearSelectedResult, (state, _action) => ({
    ...state,
    inspected: undefined,
  }))
);
