import {
  Directive,
  EventEmitter,
  type OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { shareReplayCold } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { combineLatest, type Observable, type OperatorFunction } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { TypedFormControl } from '../forms/typed-form-group';
import { flattenFields } from '../search-field/input-search-filter';

export interface ITaggableComponent<T> {
  searchCtrl: TypedFormControl<string>;
  filteredGroups$: Observable<IOptionGroup<T>[]>;
  autocomplete: MatAutocomplete;
  selectedChanged: EventEmitter<T>;
  optionsChange: EventEmitter<T[]>;

  getOptionGroups$(): Observable<IOptionGroup<T>[]>;

  filter(
    val: string | IOptionGroup<T>,
    groups: IOptionGroup<T>[]
  ): IOptionGroup<T>[];
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class TaggableComponent<T>
  implements OnInit, ITaggableComponent<T>
{
  filteredGroups$: Observable<IOptionGroup<T>[]>;
  searchCtrl = new TypedFormControl<string>();
  @ViewChild(MatAutocomplete, { static: true }) autocomplete: MatAutocomplete;
  @Output() selectedChanged: EventEmitter<T> = new EventEmitter<T>();
  @Output() optionsChange: EventEmitter<T[]> = new EventEmitter<T[]>();

  abstract getOptionGroups$(): Observable<IOptionGroup<T>[]>;

  abstract filter(
    val: string | IOptionGroup<T>,
    groups: IOptionGroup<T>[]
  ): IOptionGroup<T>[];

  ngOnInit(): void {
    this.filteredGroups$ = combineLatest([
      this.searchCtrl.valueChanges.pipe(startWith('')),
      this.getOptionGroups$(),
    ]).pipe(
      map(([val, groups]: [string, IOptionGroup<T>[]]) => {
        return val ? this.filter(val, groups) : groups.slice();
      })
    );
  }
}

export interface IOptionGroup<T> {
  name: string;
  options: T[];
  skipFilter: boolean;
}

export function toOptionGroup<T>(
  name: string,
  skipFilter: boolean = false
): OperatorFunction<T[], IOptionGroup<T>> {
  return map((options: T[]) => ({
    name,
    options,
    skipFilter,
  }));
}

export class OptionGroupSearchFilter<T> {
  private _flatten: (item: T) => string;
  results$: Observable<IOptionGroup<T>[]>;

  constructor(
    items$: Observable<IOptionGroup<T>[]>,
    searchInput$: Observable<string | T>,
    comparisonFields: string[] | ((item: T) => string)
  ) {
    this._flatten = flattenFields(comparisonFields);
    this.results$ = combineLatest([
      items$,
      searchInput$.pipe(
        filter((search: string | T): search is string => isString(search)),
        startWith('')
      ),
    ]).pipe(
      map(([groups, search]) => {
        return groups
          .map((group) => ({ ...group }))
          .map((group) => {
            group.options = group.options.filter((item) => {
              if (group.skipFilter) {
                return true;
              }
              return this._isMatch(
                item,
                this._replaceNbsp(search.toLowerCase())
              );
            });
            return group;
          });
      }),
      shareReplayCold()
    );
  }

  private _isMatch(item: T, search: string): boolean {
    if (typeof search !== 'string') {
      return false;
    }
    return this._flatten(item).toLowerCase().includes(search);
  }

  private _replaceNbsp(value: string): string {
    return value.replace(/\u00a0/g, ' ');
  }
}
