import { type SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { type MatListOption } from '@angular/material/list';
import {
  type PermissionDisplay,
  type PermissionGroup,
} from '@principle-theorem/feature-flags';
import { FeatureFlagsService } from '@principle-theorem/ng-feature-flags';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type Permission } from '@principle-theorem/principle-core/interfaces';
import { reduceToSingleArrayFn } from '@principle-theorem/shared';
import { uniq } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-permission-selector',
  templateUrl: './permission-selector.component.html',
  styleUrls: ['./permission-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PermissionSelectorComponent),
      multi: true,
    },
  ],
})
export class PermissionSelectorComponent
  implements ControlValueAccessor, OnDestroy
{
  private _onDestroy$: Subject<void> = new Subject();
  private _touched$: Subject<void> = new Subject<void>();
  selected$ = new BehaviorSubject<Permission[]>([]);
  trackByPermissionGroup = TrackByFunctions.field<PermissionGroup>('name');
  trackByPermissionDisplay = TrackByFunctions.field<PermissionDisplay>('name');
  groups: PermissionGroup[];
  @Input() isDisabled = false;
  @Output() selectedChange: EventEmitter<Permission[]> = new EventEmitter<
    Permission[]
  >();

  @Input()
  set selected(selected: Permission[]) {
    this.selected$.next(selected);
  }

  constructor(private _features: FeatureFlagsService) {
    this.groups = this._features.getFeaturePermissions();

    this.selected$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((selected: Permission[]) =>
        this.selectedChange.next(selected)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  updateSelectedPermissions(options: SelectionModel<MatListOption>): void {
    this._touched$.next();
    const selected = options.selected.map((option) => option.value as unknown);
    const permissions = this.groups
      .map((group) => group.children)
      .reduce(reduceToSingleArrayFn, [])
      .map((permission) => permission.value);

    this.selected$.next(
      permissions.filter((permission) => selected.includes(permission))
    );
  }

  selectPermissions(
    permissionDisplays: PermissionDisplay[],
    isChecked: boolean
  ): void {
    this._touched$.next();
    const permissions = permissionDisplays.map(
      (permission) => permission.value
    );
    if (isChecked) {
      this.selected$.next(uniq([...this.selected$.value, ...permissions]));
      return;
    }
    this.selected$.next(
      this.selected$.value.filter((item) => !permissions.includes(item))
    );
  }

  selectGroup(group: PermissionGroup, isChecked: boolean): void {
    this.selectPermissions(group.children, isChecked);
  }

  writeValue(value: Permission[]): void {
    this.selected = value;
  }

  registerOnChange(fn: () => void): void {
    this.selected$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this._touched$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  compareFn(permissionA: Permission, permissionB: Permission): boolean {
    return permissionA === permissionB;
  }
}
