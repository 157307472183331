import {
  DocumentReference,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { IArraySorter } from '../common';
import { type IDestinationEntity } from './destination-entity';
import {
  type FailedDestinationEntityRecord,
  type IDestinationEntityRecord,
  type MergeConflictDestinationEntityRecord,
  type SkippedDestinationEntityRecord,
} from './destination-entity-record';
import {
  type IDestinationJobFilter,
  type IDestinationJobFilterHandler,
} from './destination-job-filter';
import { type IPracticeMigration } from './practice-migration';
import { type ISourceEntityHandler } from './source';
import { ISourceEntityRecord } from './source-entity-record';
import { type ITranslationMapHandler } from './translation-mappings';

export interface IDestinationEntityJobRunOptions {
  skipMigrated?: boolean;
  retryFailed?: boolean;
  retryMigrated?: boolean;
}

export interface IDestinationEntityHandler<
  SuccessRecordData extends object = object,
  InitialJobData extends object = object,
  BuiltMigrationData extends object = object,
> {
  destinationEntity: IDestinationEntity;
  sourceEntities?: Record<symbol, ISourceEntityHandler>;
  sourceCountComparison?: ISourceEntityHandler;
  destinationEntities?: Record<symbol, IDestinationEntityHandler>;
  filters: IDestinationJobFilterHandler<InitialJobData>[];
  sorters?: IArraySorter[];
  batchLimit: number;
  canMigrateByDateRange: boolean;
  canMigrateByIdRange: boolean;
  canHandle(destination: IDestinationEntity): boolean;
  getDestinationEntityRecordUid(data: InitialJobData): string;
  sourceCountDataAccessor(
    data: InitialJobData
  ): DocumentReference<ISourceEntityRecord>;
  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: ITranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<InitialJobData[]>;
  buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: ITranslationMapHandler,
    data: InitialJobData
  ):
    | Promise<
        | BuiltMigrationData
        | (IDestinationEntityRecord & FailedDestinationEntityRecord)
        | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
      >
    | (
        | BuiltMigrationData
        | (IDestinationEntityRecord & FailedDestinationEntityRecord)
        | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
      );
  shouldSkipRecord(
    jobData: InitialJobData,
    filters: IDestinationJobFilter[]
  ): boolean;
  hasMergeConflict(
    translationMap: ITranslationMapHandler,
    data: BuiltMigrationData
  ): Promise<BuiltMigrationData | undefined> | (BuiltMigrationData | undefined);
  buildMergeConflictRecord(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: ITranslationMapHandler,
    jobData: InitialJobData,
    migrationData: BuiltMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord;
  runJob(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: ITranslationMapHandler,
    jobData: InitialJobData,
    migrationData: BuiltMigrationData
  ): Promise<IDestinationEntityRecord>;
  getEntity$(
    migration: IReffable<IPracticeMigration>
  ): Observable<IDestinationEntity | undefined>;
  getRecords$(
    migration: IReffable<IPracticeMigration>,
    bufferSize: number,
    initialRecord?: WithRef<IDestinationEntityRecord>
  ): Observable<WithRef<IDestinationEntityRecord>[]>;
  getMigratedData$?(
    record: IDestinationEntityRecord<SuccessRecordData>
  ): Observable<IMigratedDataSummary[]>;
  rollbackRecord?(
    record: IDestinationEntityRecord<SuccessRecordData>
  ): Promise<void>;
}

export interface IMigrateErrorData {
  resumeData: object;
  errorMessage: string;
}

export interface IMigratedDataSummary {
  label: string;
  data: object;
}
