import {
  createEntityAdapter,
  type EntityAdapter,
  type EntityState,
} from '@ngrx/entity';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { type IConditionConfiguration } from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import { loadConditionConfigurationsSuccess } from '../../actions/charted-configurations';

export type IConditionConfigurationState = EntityState<
  SerialisedData<WithRef<IConditionConfiguration>>
>;

export const conditionConfigurationAdapter: EntityAdapter<
  SerialisedData<WithRef<IConditionConfiguration>>
> = createEntityAdapter<SerialisedData<WithRef<IConditionConfiguration>>>({
  selectId: (config) => config.ref.id,
  sortComparer: false,
});

export const initialConditionConfiguration: IConditionConfigurationState =
  conditionConfigurationAdapter.getInitialState();

export const conditionConfigurationReducer: ActionReducer<
  IConditionConfigurationState,
  Action
> = createReducer(
  initialConditionConfiguration,

  on(loadConditionConfigurationsSuccess, (state, action) =>
    conditionConfigurationAdapter.upsertMany(action.configs, {
      ...state,
    })
  )

  // on(reset, () => initialConditionConfiguration)
);
