<div class="appointment-info layout-padding" *ngIf="info$ | async as info">
  <div *ngIf="appointmentStartAt$ | async as startAt">
    <h3>Time</h3>
    <p>
      {{ startAt | amDateFormat: dateTimeFormat }}
    </p>
  </div>
  <div *ngIf="info.duration">
    <h3>Duration</h3>
    <p>{{ info.duration }}</p>
  </div>
  <div>
    <h3>Location</h3>
    <p>{{ info.practice }}</p>
    <p>{{ info.practiceAddress }}</p>
  </div>
  <div *ngIf="info.practitioner">
    <h3>Practitioner</h3>
    <p>{{ info.practitioner }}</p>
  </div>
  <div *ngIf="info.treatment">
    <h3>Treatment</h3>
    <p>{{ info.treatment }}</p>
  </div>
</div>
