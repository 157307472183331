import { Injectable } from '@angular/core';
import { AccountingFunctionsService } from '@principle-theorem/ng-principle-accounting';
import {
  CurrentScopeFacade,
  UpdateHealthCardsService,
} from '@principle-theorem/ng-principle-shared';
import { BasicDialogService } from '@principle-theorem/ng-shared';
import { TyroService } from '@principle-theorem/ng-tyro';
import {
  Invoice,
  MedicareClaimType,
  OrganisationCache,
  Practice,
} from '@principle-theorem/principle-core';
import {
  TransactionAction,
  TransactionProvider,
  type IHealthcareClaim,
  type IInvoice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  guardFilter,
  snapshot,
  type DocumentReference,
  type WithRef,
  Region,
} from '@principle-theorem/shared';
import {
  isBaseTransactionCompleteCallbackData,
  isEasyclaimErrorCallback,
  isNotStarted,
  isTyroResponse,
} from '@principle-theorem/tyro';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MedicareClaimTypeHelpers } from '../../transaction-components/medicare-components/medicare-claim-type-helpers';
import { MedicareDialogService } from '../../transaction-components/medicare-components/medicare-dialog-service';
import {
  TransactionProviderType,
  type ITransactionProvider,
} from '../../transaction-provider';
import { TYRO_ERROR_DIALOG_DATA } from '../base-transaction';
import { SelectTyroTerminalService } from '../select-tyro-terminal/select-tyro-terminal.service';
import { EasyclaimBuilder } from './easyclaim-builder.service';
import { toEasyclaimPartPaidTransaction } from './easyclaim-transaction';

@Injectable()
export class TyroEasyclaimPartPaidTransactionProvider
  implements ITransactionProvider
{
  providerId = TransactionProvider.TyroEasyClaimPartPaid;
  providerType = TransactionProviderType.Medicare;
  providerRegions = [Region.Australia];
  isEnabled$: Observable<boolean>;

  constructor(
    private _tyro: TyroService,
    private _currentScopeFacade: CurrentScopeFacade,
    private _selectTerminal: SelectTyroTerminalService,
    private _easyclaimBuilder: EasyclaimBuilder,
    private _basicDialog: BasicDialogService,
    private _accountFunctions: AccountingFunctionsService,
    private _updateHealthCards: UpdateHealthCardsService,
    private _medicareDialog: MedicareDialogService
  ) {
    this.isEnabled$ = this._currentScopeFacade.currentPractice$.pipe(
      map((practice) => Practice.isTyroEnabled(practice))
    );
  }

  canCapture$(invoice: WithRef<IInvoice>): Observable<boolean> {
    return MedicareClaimTypeHelpers.getDefinition(
      MedicareClaimType.PartPaid
    ).canClaim$(invoice);
  }

  async capture(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const formData = await this._medicareDialog.bulkBill(invoice, claim, true);
    if (!formData) {
      return;
    }

    const terminal = await this._selectTerminal.getTerminalData();
    if (!terminal) {
      return;
    }
    const request = this._easyclaimBuilder.buildEasyclaimPartPaidClaim(
      invoice,
      claim,
      formData,
      terminal.terminalData
    );
    const response = await snapshot(
      this._tyro
        .initiatePartPaidEasyclaim$(request)
        .pipe(guardFilter(isTyroResponse))
    );
    if (isNotStarted(response) || !response) {
      return;
    }
    if (!isBaseTransactionCompleteCallbackData(response)) {
      // eslint-disable-next-line no-console
      console.error('Invaid data for easyclaim part paid response', response);
      await this._basicDialog.alert(TYRO_ERROR_DIALOG_DATA);
      return;
    }
    if (isEasyclaimErrorCallback(response)) {
      await this._basicDialog.alert({
        ...TYRO_ERROR_DIALOG_DATA,
        title: 'Notice',
        prompt: [
          `Result: ${response.result.toUpperCase()}`,
          `Easyclaim Id: ${response.easyclaimTransactionId}`,
          `Status Code: ${response.statusCode}`,
        ],
      });
    }
    const transaction = await toEasyclaimPartPaidTransaction(
      invoice,
      terminal.practiceRef,
      claim,
      request,
      response,
      formData
    );

    const result = await this._accountFunctions.addTransactionToInvoice(
      invoice,
      transaction,
      TransactionAction.Add,
      claim
    );

    if (!result) {
      return;
    }
    const patient = await OrganisationCache.patients.getDoc(
      Invoice.patientDocRef(invoice)
    );
    await this._updateHealthCards.open(
      this._updateHealthCards.factory(patient).fromTransaction(transaction)
    );

    return result;
  }
}
