/**
 * Decorator applies base class properties into the derived class
 * To use it, first define the base class:
 *
 * class Activatable {
 *   active: boolean = true;
 * }
 *
 * And where you want to apply it:
 *
 * @Mixin([Activatable])
 * class Thing implements Activatable {
 *   active: boolean;
 * }
 *
 * Pass in as many classes as required. You must implement the class still
 * in order to preserve the property signatures on the derived class.
 * @param baseClasses Function[]
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Mixin(
  // eslint-disable-next-line @typescript-eslint/ban-types
  baseClasses: Function[]
  // eslint-disable-next-line @typescript-eslint/ban-types
): (derivedClass: Function) => void {
  return (derivedClass) => {
    baseClasses.map((baseClass) => {
      Object.getOwnPropertyNames(baseClass.prototype).map(
        (name: string): void => {
          const descriptor: PropertyDescriptor | undefined =
            Object.getOwnPropertyDescriptor(baseClass.prototype, name);

          if (name === 'constructor') {
            return;
          }

          if (descriptor) {
            Object.defineProperty(derivedClass.prototype, name, descriptor);
            return;
          }

          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          derivedClass.prototype[name] = baseClass.prototype[name];
        }
      );
    });
  };
}
