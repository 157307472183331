import { isObject } from '@principle-theorem/shared';
import { get } from 'lodash';

export function resolveHelpArticleId(
  routeSegments: string[]
): number | undefined {
  const result = get(
    INTERCOM_CONTEXTUAL_HELP_IDS,
    routeSegments[routeSegments.length - 1],
    undefined
  );

  if (isObject(result)) {
    const foundSegment = routeSegments
      .slice(0, -1)
      .reverse()
      .find((segment) => !!result[segment]);

    return foundSegment
      ? result[foundSegment]
      : get(result, 'default', undefined);
  }

  return result;
}

const INTERCOM_CONTEXTUAL_HELP_IDS: Record<
  string,
  number | Record<string, number | undefined>
> = {
  timeline: 5171973,
  history: {
    schedule: 9393250,
    default: undefined,
  },
  dashboard: 4517372,
  practitioner: 5084145,
  gaps: 5147382,
  'appointment-requests': 5348763,
  'follow-ups': 5147556,
  patients: {
    reporting: 9059234,
    default: 5178195,
  },
  profile: 5212555,
  staff: {
    brand: 5471667,
    default: 9042022,
  },
  list: 4343835,
  'lab-jobs': 9003061,
  labs: 9003061,
  contacts: 4405582,
  calendar: 5059396,
  conversations: 9044452,
  chats: 9044487,
  automations: {
    brand: 9044816,
    default: 9042352,
  },
  'report-builder': 9054207,
  'custom-reports': 9054207,
  'practitioner-income': 6151007,
  'patient-reports': 9059234,
  reconciliation: 5664266,
  'pending-payments': 6239809,
  'outstanding-invoices': 6214551,
  'fee-schedules': 5209490,
  users: 4527402,
  roles: 6974682,
  integrations: 9044662,
  general: {
    brand: 9044745,
  },
  accounting: 9044751,
  tags: 5244598,
  'referral-sources': 9068843,
  'cancellation-reasons': 9068917,
  'document-templates': 5334723,
  'treatment-templates': 5052024,
  'treatment-categories': 5570378,
  snippets: 4527447,
  products: 5341517,
  teams: 9045620,
  conditions: 4665922,
  treatments: 4665942,
  'multi-treatments': 5378269,
};
