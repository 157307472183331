import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  ISterilisationEquipment,
  ISterilisationPackContent,
} from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';
import { INamedDocument, isSameRef } from '@principle-theorem/shared';
import { ReactiveFormsModule } from '@angular/forms';
import { NgMaterialModule } from '@principle-theorem/ng-material';

@Component({
  selector: 'pr-sterilisation-pack-content-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgMaterialModule],
  templateUrl: './sterilisation-pack-content-input.component.html',
  styleUrl: './sterilisation-pack-content-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SterilisationPackContentInputComponent {
  items$ = new ReplaySubject<INamedDocument<ISterilisationEquipment>[]>(1);
  @Input() form: TypedFormGroup<ISterilisationPackContent>;

  @Input()
  set items(items: INamedDocument<ISterilisationEquipment>[]) {
    if (items) {
      this.items$.next(items);
    }
  }

  isSelectedItem(
    item: INamedDocument<ISterilisationEquipment>,
    selectedItem: INamedDocument<ISterilisationEquipment>
  ): boolean {
    return isSameRef(item, selectedItem);
  }
}
