import { isDocRef, isTimestamp, TypeGuard } from '@principle-theorem/shared';
import {
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type IBrand } from '../brand';
import { type IPractice } from '../practice/practice';
import { type ReportingReference } from './custom-report';

export enum QueryScopeConfigId {
  Brand = 'brand',
  Practice = 'practice',
  DateRange = 'dateRange',
}

export interface IBrandQueryRequest {
  brandRef: DocumentReference<IBrand>;
}

export const isBrandQueryRequest = TypeGuard.interface<IBrandQueryRequest>({
  brandRef: isDocRef,
});

export interface IPracticeQueryRequest {
  practiceRefs: DocumentReference<IPractice>[];
}

export const isPracticeQueryRequest =
  TypeGuard.interface<IPracticeQueryRequest>({
    practiceRefs: TypeGuard.arrayOf(isDocRef),
  });

export interface IDateRangeQueryRequest {
  from: Timestamp;
  to: Timestamp;
  queryableTimestamp: ReportingReference;
}

export const isDateRangeQueryRequest =
  TypeGuard.interface<IDateRangeQueryRequest>({
    from: isTimestamp,
    to: isTimestamp,
    queryableTimestamp: isString,
  });

export interface IQueryScopeRequests {
  [QueryScopeConfigId.Brand]?: IBrandQueryRequest;
  [QueryScopeConfigId.Practice]?: IPracticeQueryRequest;
  [QueryScopeConfigId.DateRange]?: IDateRangeQueryRequest;
}

export const isQueryScopeRequests = TypeGuard.interface<IQueryScopeRequests>({
  brand: TypeGuard.undefinedOr(isBrandQueryRequest),
  practice: TypeGuard.undefinedOr(isPracticeQueryRequest),
  dateRange: TypeGuard.undefinedOr(isDateRangeQueryRequest),
});
