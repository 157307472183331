<div class="flex flex-col gap-4">
  @if (treatmentConfig$ | async; as treatmentConfig) {
    <div class="flex flex-row items-center justify-end gap-4">
      <pt-button-with-menu
        label="Add Existing Automation"
        color="primary"
        type="flat"
        (buttonClick)="treatmentAutomation.addExisting(treatmentConfig)"
      >
        @if (templateScope$ | async; as templateScope) {
          <button
            mat-menu-item
            (click)="
              treatmentAutomation.createNotification(
                treatmentConfig,
                templateScope
              )
            "
          >
            Create Notification Automation
          </button>
        }
        <button
          mat-menu-item
          (click)="treatmentAutomation.createTask(treatmentConfig)"
        >
          Create Task Automation
        </button>
        <button
          mat-menu-item
          (click)="treatmentAutomation.createForm(treatmentConfig)"
        >
          Create Generate Form Automation
        </button>
      </pt-button-with-menu>
    </div>

    <div class="flex flex-col gap-4">
      @for (
        automationConfig of automationConfigs$ | async;
        track automationConfig.ref.path
      ) {
        <pr-automation-configuration-list-item [config]="automationConfig">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              (click)="
                treatmentAutomation.remove(treatmentConfig, automationConfig)
              "
            >
              <mat-icon>cancel</mat-icon>Remove from Treatment
            </button>

            <mat-divider />

            @if (templateScope$ | async; as templateScope) {
              <button
                mat-menu-item
                (click)="
                  treatmentAutomation.edit(automationConfig, templateScope)
                "
              >
                <mat-icon>edit</mat-icon>Edit
              </button>
            }
            @if (automationConfig.isActive) {
              <button
                mat-menu-item
                (click)="treatmentAutomation.setActive(automationConfig, false)"
              >
                <mat-icon>highlight_off</mat-icon>
                Disable
              </button>
            } @else {
              <button
                mat-menu-item
                (click)="treatmentAutomation.setActive(automationConfig, true)"
              >
                <mat-icon>check_circle</mat-icon>
                Enable
              </button>
            }
            <button
              mat-menu-item
              (click)="treatmentAutomation.delete(automationConfig)"
              color="warn"
            >
              <mat-icon color="warn">delete</mat-icon>
              Delete
            </button>
          </mat-menu>
        </pr-automation-configuration-list-item>
      } @empty {
        <pr-empty-state image="task" title="automations" />
      }
    </div>
  }
</div>
