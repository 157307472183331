import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { type ICreateConfigurationData } from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
  toSearchStream,
} from '@principle-theorem/ng-shared';
import { treatmentConfigurationsToGroups } from '@principle-theorem/principle-core';
import {
  type ITreatmentCategory,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  type WithRef,
  all$,
  snapshot,
  undeletedQuery,
} from '@principle-theorem/shared';
import {
  type CollectionReference,
  type DocumentReference,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import {
  BehaviorSubject,
  type Observable,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CreateTreatmentConfigurationComponent } from '../create-treatment-configuration/create-treatment-configuration.component';

interface ITreatmentCategoryGroup {
  category?: WithRef<ITreatmentCategory>;
  treatments: WithRef<ITreatmentConfiguration>[];
}

@Component({
    selector: 'pr-treatment-configuration-list',
    templateUrl: './treatment-configuration-list.component.html',
    styleUrls: ['./treatment-configuration-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreatmentConfigurationListComponent {
  private _categories$ = new BehaviorSubject<WithRef<ITreatmentCategory>[]>([]);
  private _collection$ = new ReplaySubject<
    CollectionReference<ITreatmentConfiguration>
  >(1);
  @Input() basePath?: string;
  searchInput = new TypedFormControl<string>();
  trackByGroup = TrackByFunctions.ref<ITreatmentCategoryGroup>('category.ref');
  trackByTreatment = TrackByFunctions.ref<ITreatmentConfiguration>();
  groups$: Observable<ITreatmentCategoryGroup[]>;
  emptyState$: Observable<boolean>;

  constructor(private _dialog: MatDialog, private _router: Router) {
    const treatments$ = this._collection$.pipe(
      switchMap((col) => all$(undeletedQuery(col)))
    );

    const treatmentsFilter = new InputSearchFilter(
      treatments$,
      toSearchStream(this.searchInput),
      ['name']
    );

    this.groups$ = combineLatest([
      this._categories$,
      treatmentsFilter.results$,
    ]).pipe(
      map(([categories, treatments]) =>
        treatmentConfigurationsToGroups(categories, treatments)
      ),
      map((category) => sortBy(category, ['category.name']))
    );

    this.emptyState$ = treatments$.pipe(
      map((treatments) => !treatments.length)
    );
  }

  @Input()
  set categories(categories: WithRef<ITreatmentCategory>[]) {
    if (categories) {
      this._categories$.next(categories);
    }
  }

  @Input()
  set collection(collection: CollectionReference<ITreatmentConfiguration>) {
    if (collection) {
      this._collection$.next(collection);
    }
  }

  async createTreatmentConfig(): Promise<void> {
    const collection = await snapshot(this._collection$);
    if (!collection) {
      return;
    }

    const data: ICreateConfigurationData<ITreatmentConfiguration> = {
      collection,
    };

    const docRef = await this._dialog
      .open<
        CreateTreatmentConfigurationComponent,
        ICreateConfigurationData<ITreatmentConfiguration>,
        DocumentReference
      >(CreateTreatmentConfigurationComponent, DialogPresets.small({ data }))
      .afterClosed()
      .toPromise();

    if (!docRef) {
      return;
    }

    await this._router.navigate(this.getRoute(docRef.id));
  }

  getRoute(uid: string): string[] {
    return [this.basePath || this._router.url, uid];
  }
}
