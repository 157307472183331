import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import {
  CurrentBrandScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  type TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  type IAssigneeGroup,
  type IBrand,
  isStaffer,
  type IStaffer,
  type ITeam,
} from '@principle-theorem/principle-core/interfaces';
import { Brand, stafferToNamedDoc } from '@principle-theorem/principle-core';
import {
  filterUndefined,
  isSameRef,
  snapshot,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { isString, sortBy } from 'lodash';
import { combineLatest, type Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-task-assignee',
  templateUrl: './task-assignee.component.html',
  styleUrls: ['./task-assignee.component.scss'],
  exportAs: 'prTaskAssignee',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskAssigneeComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByGroup =
    TrackByFunctions.label<
      IAssigneeGroup<WithRef<IStaffer> | WithRef<ITeam>>
    >();
  trackByAssignee = TrackByFunctions.ref<WithRef<IStaffer> | WithRef<ITeam>>();
  @Input() assigneeCtrl: TypedFormControl<
    string | WithRef<ITeam> | WithRef<IStaffer>
  >;
  staff: IAssigneeGroup<WithRef<IStaffer>> = {
    label: 'Staff',
    assignees: [],
  };
  teams: IAssigneeGroup<WithRef<ITeam>> = {
    label: 'Teams',
    assignees: [],
  };
  staffer: WithRef<IStaffer>;
  assignees$: Observable<IAssigneeGroup<WithRef<IStaffer> | WithRef<ITeam>>[]>;

  constructor(
    private _organisation: OrganisationService,
    private _brandScope: CurrentBrandScope
  ) {}

  async ngOnInit(): Promise<void> {
    await this.load();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  validateAssignee(): void {
    if (isString(this.assigneeCtrl.value)) {
      this.assigneeCtrl.setValue('', { emitEvent: false });
    }
  }

  async load(): Promise<void> {
    this.assignees$ = this.assigneeCtrl.valueChanges.pipe(
      startWith(''),
      map((filterValue) => this._filterGroup(filterValue))
    );

    this.staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const brand: WithRef<IBrand> = await this._brandScope.toPromise();
    combineLatest([Brand.teams$(brand), Brand.staff$(brand)])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([teams, staff]) => {
        this.teams.assignees = sortBy(teams, ['name']);
        this.staff.assignees = sortBy(staff, ['user.name']);
      });
  }

  displayFn(assignee?: WithRef<IStaffer> | WithRef<ITeam>): string {
    if (!assignee) {
      return '';
    }
    if (isStaffer(assignee)) {
      return assignee.user.name;
    }
    return assignee.name;
  }

  assigneeName(assignee: WithRef<IStaffer> | WithRef<ITeam>): string {
    return isStaffer(assignee) ? assignee.user.name : assignee.name;
  }

  assignSelf(): void {
    const staffer = this.staff.assignees.find((user: WithRef<IStaffer>) =>
      isSameRef(user, this.staffer)
    );
    if (staffer) {
      this.assigneeCtrl.setValue(staffer);
    }
  }

  private _filterGroup(
    filterValue: string | WithRef<ITeam> | WithRef<IStaffer>
  ): IAssigneeGroup<WithRef<IStaffer> | WithRef<ITeam>>[] {
    if (!filterValue || typeof filterValue !== 'string') {
      return [this.teams, this.staff];
    }

    return [this.teams, this.staff]
      .map((group: IAssigneeGroup<WithRef<IStaffer> | WithRef<ITeam>>) => ({
        label: group.label,
        assignees: group.assignees.filter((assignee) => {
          const assigneeDoc = isStaffer(assignee)
            ? stafferToNamedDoc(assignee)
            : toNamedDocument(assignee);
          return (
            assigneeDoc.name.toLowerCase().indexOf(filterValue.toLowerCase()) >
            -1
          );
        }),
      }))
      .filter((group) => group.assignees.length > 0);
  }
}
