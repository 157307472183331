import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { MatDialogRef } from '@angular/material/dialog';
import { Validators } from '@angular/forms';
import { type IUser } from '@principle-theorem/principle-core/interfaces';

export type InviteFormData = Pick<IUser, 'name' | 'email'>;

@Component({
    selector: 'pr-invite-user-dialog',
    templateUrl: './invite-user-dialog.component.html',
    styleUrls: ['./invite-user-dialog.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InviteUserDialogComponent {
  inviteForm: TypedFormGroup<InviteFormData> =
    new TypedFormGroup<InviteFormData>({
      name: new TypedFormControl<string>('', Validators.required),
      email: new TypedFormControl<string>('', [
        Validators.required,
        Validators.email,
      ]),
    });

  constructor(private _dialogRef: MatDialogRef<InviteUserDialogComponent>) {}

  submit(): void {
    if (this.inviteForm.invalid) {
      return;
    }
    this._dialogRef.close(this.inviteForm.value);
  }
}
