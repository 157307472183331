import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type WithRef } from '@principle-theorem/shared';
import {
  IScheduleSummaryEventable,
  type IAppointment,
} from '@principle-theorem/principle-core/interfaces';
import { Appointment, Event } from '@principle-theorem/principle-core';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-gap-warnings',
    templateUrl: './gap-warnings.component.html',
    styleUrls: ['./gap-warnings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GapWarningsComponent {
  displayWarning$: Observable<boolean>;
  gap$: ReplaySubject<IScheduleSummaryEventable> = new ReplaySubject(1);
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);
  appointmentDuration$: Observable<number>;
  gapDuration$: Observable<number>;

  @Input()
  set gap(gap: IScheduleSummaryEventable) {
    if (gap) {
      this.gap$.next(gap);
    }
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor() {
    this.appointmentDuration$ = this.appointment$.pipe(
      map((appointment) => Appointment.duration(appointment))
    );
    this.gapDuration$ = this.gap$.pipe(map((gap) => Event.duration(gap.event)));

    this.displayWarning$ = combineLatest([
      this.appointmentDuration$,
      this.gapDuration$,
    ]).pipe(
      map(
        ([appointmentDuration, gapDuration]) =>
          appointmentDuration > gapDuration
      )
    );
  }
}
