import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import { type FormGroup } from '@angular/forms';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  TypedFormGroup,
  validFormGroupChanges$,
  type TypedFormElements,
} from '@principle-theorem/ng-shared';
import {
  QueryScopeConfigId,
  type IDateRangeQueryRequest,
  type IQueryScopeRequests,
  type ReportingReference,
} from '@principle-theorem/principle-core/interfaces';
import { BaseMeasures, QueryScopeBuilder } from '@principle-theorem/reporting';
import {
  ITimePeriod,
  toMomentRange,
  toTimestamp,
} from '@principle-theorem/shared';
import { keys } from 'lodash';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  type Observable,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { type IReportBuilderDataSource } from '../../../models/report-builder-data-sources/report-builder-data-source';
import {
  BrandQueryScopeForm,
  DateRangeQueryScopeForm,
  PracticeQueryScopeForm,
  type IQueryScopeForms,
} from './scope-form';

@Component({
  selector: 'pr-query-scope-form',
  templateUrl: './query-scope-form.component.html',
  styleUrls: ['./query-scope-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'prQueryScopeForm',
})
export class QueryScopeFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _dataSource$ = new ReplaySubject<IReportBuilderDataSource>(1);
  factTable$: Observable<BaseMeasures>;
  scopeForms: IQueryScopeForms;
  rootForm = new TypedFormGroup<IQueryScopeRequests>(
    {} as TypedFormElements<IQueryScopeRequests>
  );
  lockQueryableTimestamp$ = new BehaviorSubject<ReportingReference | undefined>(
    undefined
  );
  showQueryableTimestamp$: Observable<boolean>;
  defaultDateRange$ = new BehaviorSubject<ITimePeriod | undefined>(undefined);
  @Output() formChange = new EventEmitter<IQueryScopeRequests>();
  @Output() dateRangeChange = new EventEmitter<ITimePeriod>();

  @Input()
  set lockQueryableTimestamp(
    lockQueryableTimestamp: ReportingReference | undefined
  ) {
    this.lockQueryableTimestamp$.next(lockQueryableTimestamp);
  }

  @Input()
  set dataSource(dataSource: IReportBuilderDataSource) {
    if (dataSource) {
      this._dataSource$.next(dataSource);
    }
  }

  @Input()
  set dateRange(dateRange: ITimePeriod) {
    if (dateRange) {
      this.defaultDateRange$.next(dateRange);
    }
  }

  constructor(private _organisation: OrganisationService) {
    this.scopeForms = {
      [QueryScopeConfigId.Brand]: new BrandQueryScopeForm(
        this._organisation.userBrands$
      ),
      [QueryScopeConfigId.Practice]: new PracticeQueryScopeForm(
        this._organisation.userPractices$
      ),
      [QueryScopeConfigId.DateRange]: new DateRangeQueryScopeForm(
        this._dataSource$.pipe(map((source) => source.queryableTimestamps))
      ),
    };

    this.showQueryableTimestamp$ = this.lockQueryableTimestamp$.pipe(
      map((queryableTimestamp) => !queryableTimestamp)
    );

    this.factTable$ = this._dataSource$.pipe(
      map((dataSource) => dataSource.factTable)
    );
    combineLatest([this.factTable$, this.lockQueryableTimestamp$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([factTable, lockQueryableTimestamp]) => {
        this._setupRootForm(factTable, lockQueryableTimestamp);
        void this._setupDefaultValues(lockQueryableTimestamp);
      });

    validFormGroupChanges$(this.rootForm)
      .pipe(
        map(() => this.rootForm.getRawValue()),
        takeUntil(this._onDestroy$)
      )
      .subscribe((formValue) => this.formChange.emit(formValue));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  setDateRange(dateRange: ITimePeriod, dateRangeForm: FormGroup): void {
    const from = toTimestamp(dateRange.from);
    const to = toTimestamp(dateRange.to);
    dateRangeForm.patchValue({ from, to });
    this.dateRangeChange.emit(dateRange);
  }

  private _setupRootForm(
    factTable: BaseMeasures,
    _lockQueryableTimestamp?: ReportingReference
  ): void {
    const builder = new QueryScopeBuilder(factTable);
    keys(this.rootForm.controls).map((key) => {
      if (!builder.hasScope(key)) {
        this.rootForm.removeControl(key);
      }
    });
    factTable.scopes.map((scope) => {
      const scopeForm = this.scopeForms[scope.id as QueryScopeConfigId];
      if (!scopeForm) {
        return;
      }
      this.rootForm.addControl(scope.id, scopeForm.formGroup);
    });
  }

  private async _setupDefaultValues(
    lockQueryableTimestamp?: ReportingReference
  ): Promise<void> {
    if (this.scopeForms.brand) {
      this.scopeForms.brand.formGroup.patchValue(
        await this.scopeForms.brand.getDefaultValue()
      );
    }
    if (this.scopeForms.practice) {
      this.scopeForms.practice.formGroup.patchValue(
        await this.scopeForms.practice.getDefaultValue()
      );
    }
    if (this.scopeForms.dateRange) {
      await this._setupDefaultDateRange(lockQueryableTimestamp);
    }
  }

  private async _setupDefaultDateRange(
    lockQueryableTimestamp?: ReportingReference
  ): Promise<void> {
    const overrides: Partial<IDateRangeQueryRequest> = {};
    const current = this.scopeForms.dateRange.formGroup.getRawValue();
    if (current.from && current.to) {
      overrides.from = current.from;
      overrides.to = current.to;
    }
    if (lockQueryableTimestamp) {
      overrides.queryableTimestamp = lockQueryableTimestamp;
    }

    const defaultValue =
      await this.scopeForms.dateRange.getDefaultValue(overrides);
    this.scopeForms.dateRange.formGroup.patchValue(defaultValue);
    this.defaultDateRange$.next(
      toMomentRange(defaultValue.from, defaultValue.to)
    );
    if (lockQueryableTimestamp) {
      const queryableTimestampCtrl =
        this.scopeForms.dateRange.formGroup.controls.queryableTimestamp;
      queryableTimestampCtrl.disable();
    }
  }
}
