<mat-toolbar color="accent">Select Terminal/Provider</mat-toolbar>

<mat-dialog-content>
  <mat-action-list>
    <button
      *ngFor="let option of options$ | async; trackBy: trackByOptions"
      mat-list-item
      (click)="select(option)"
    >
      <div matListItemIcon class="flex !h-[42px] !w-[42px]">
        @if (option.terminal.practitionerRef) {
          <pt-user-icon
            [src]="
              option.terminal.practitionerRef
                | map: getStafferImage$ : this
                | async
            "
            [name]="
              option.terminal.practitionerRef
                | map: getStafferName$ : this
                | async
            "
            [diameter]="42"
          />
        } @else {
          <mat-icon
            class="practice-icon text-gray-500"
            [matTooltip]="practiceName$ | async"
            >store</mat-icon
          >
        }
      </div>

      <span matListItemTitle>{{ option.terminal.name }}</span>
      <span
        matListItemLine
        class="mat-caption is-paired"
        *ngIf="option.isCurrentlyPaired"
      >
        [ Currently Paired ]
      </span>
      <span matListItemLine class="mat-caption">
        Merchant Id: {{ option.terminal.mid }}, Terminal Id:
        {{ option.terminal.tid }}
      </span>
    </button>
  </mat-action-list>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
  </pt-buttons-container>
</mat-dialog-actions>
