import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  Invoice,
  InvoiceClaimGenerator,
  InvoiceDisplayBloc,
  IPractitionerClaimSection,
} from '@principle-theorem/principle-core';
import {
  type IInvoice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import { wakeUpFunction, type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TransactionProviderType } from '../transactions/transaction-providers/transaction-provider';
import { TransactionProviders } from '../transactions/transaction-providers/transaction-providers.service';

@Component({
    selector: 'pr-invoice-transactions',
    templateUrl: './invoice-transactions.component.html',
    styleUrls: ['./invoice-transactions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InvoiceTransactionsComponent {
  invoice$: ReplaySubject<WithRef<IInvoice>> = new ReplaySubject(1);
  transactions$: Observable<ITransaction[]>;
  bloc = new InvoiceDisplayBloc(this.invoice$);
  practitionerClaims$: Observable<IPractitionerClaimSection[]>;

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this.invoice$.next(invoice);
    }
  }

  constructor(transactionProviders: TransactionProviders) {
    this.transactions$ = this.invoice$.pipe(
      switchMap((invoice) => Invoice.transactions$(invoice)),
      switchMap((transactions) =>
        transactionProviders.filterTransactionsByProviderType(
          transactions,
          TransactionProviderType.Payment
        )
      )
    );
    this.practitionerClaims$ = this.invoice$.pipe(
      switchMap((invoice) =>
        InvoiceClaimGenerator.getPractitionerClaims(invoice)
      )
    );

    void wakeUpFunction('http-accounting-addTransaction');
  }
}
