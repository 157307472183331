import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { type IMultiTreatmentConfiguration } from '@principle-theorem/principle-core/interfaces';
import {
  CurrentBrandScope,
  CurrentStafferScope,
} from '@principle-theorem/ng-principle-shared';
import { type IBreadcrumb } from '@principle-theorem/ng-shared';
import {
  doc$,
  filterUndefined,
  findProp,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-multi-treatment-config-edit',
    templateUrl: './multi-treatment-config-edit.component.html',
    styleUrls: ['./multi-treatment-config-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MultiTreatmentConfigEditComponent {
  multiTreatmentConfig$: Observable<WithRef<IMultiTreatmentConfiguration>>;
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(
    private _route: ActivatedRoute,
    private _currentBrand: CurrentBrandScope,
    private _currentStaffer: CurrentStafferScope
  ) {
    this.multiTreatmentConfig$ = this._route.data.pipe(
      findProp<WithRef<IMultiTreatmentConfiguration>>('multiTreatmentConfig'),
      filterUndefined(),
      switchMap((data) => doc$(data.ref))
    );

    this.breadcrumbs$ = combineLatest([
      this._currentBrand.doc$.pipe(filterUndefined()),
      this._currentStaffer.doc$,
      this.multiTreatmentConfig$,
    ]).pipe(
      map(([brand, staffer, multiTreatmentConfig]) => {
        if (staffer) {
          return [
            { label: 'Settings', path: '../../../../../' },
            { label: brand.name },
            { label: 'My Account' },
            { label: 'Multi Treatment Configurations', path: '../' },
            { label: multiTreatmentConfig.name },
          ];
        }
        return [
          { label: 'Settings', path: '../../../../../' },
          { label: brand.name },
          { label: 'Multi Treatment Configurations', path: '../' },
          { label: multiTreatmentConfig.name },
        ];
      })
    );
  }
}
