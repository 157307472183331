import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import {
  NgSharedModule,
  TypedFormControl,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  AUTO_ALLOCATED_PLACEHOLDER,
  AUTO_ALLOCATED_TOOLTIP,
} from '@principle-theorem/principle-core';
import { IStaffer } from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  INamedDocument,
  isSameRef,
} from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-attributed-to-selector',
    imports: [CommonModule, NgSharedModule, NgPrincipleSharedModule],
    templateUrl: './attributed-to-selector.component.html',
    styleUrl: './attributed-to-selector.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AttributedToSelectorComponent),
            multi: true,
        },
    ]
})
export class AttributedToSelectorComponent
  implements ControlValueAccessor, OnDestroy
{
  private _onDestroy$ = new Subject<void>();
  onChange?: (staffer: DocumentReference<IStaffer> | undefined) => void;
  onTouched?: () => void;
  isSameRef = isSameRef;
  autoAllocated = AUTO_ALLOCATED_PLACEHOLDER;
  autoAllocateTooltip = AUTO_ALLOCATED_TOOLTIP;
  formCtrl = new TypedFormControl<DocumentReference<IStaffer> | undefined>(
    undefined
  );

  @Input() staff: INamedDocument<IStaffer>[] = [];

  constructor() {
    formControlChanges$(this.formCtrl)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => {
        if (this.onChange) {
          this.onChange(value);
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  writeValue(obj: DocumentReference<IStaffer> | undefined): void {
    this.formCtrl.setValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled
      ? this.formCtrl.disable({ emitEvent: false })
      : this.formCtrl.enable({ emitEvent: false });
  }
}
