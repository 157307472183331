import { Brand } from '@principle-theorem/principle-core';
import {
  CustomMappingType,
  ITranslationMap,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  XSLXImporterExporter,
  asyncForEach,
  isSameRef,
  multiMap,
  multiSortBy$,
  nameSorter,
  snapshot,
  toInt,
  undeletedQuery,
  type INamedDocument,
  type IReffable,
  type WithRef,
  Firestore,
} from '@principle-theorem/shared';
import { sortBy, uniqBy } from 'lodash';
import { BaseCustomMappingHandler } from '../base-custom-mapping-handler';
import { CustomMapping } from '../custom-mapping';
import { IBaseMigrationStaffer } from '../interfaces';
import { StafferToPractitionerToXSLX } from '../mappings/staffer-to-practitioner-to-xlsx';
import { XSLXToStafferToPractitioner } from '../mappings/xlsx-to-staffer-to-practitioner';
import { PracticeMigration } from '../practice-migrations';
import { BaseSourceEntity } from '../source/base-source-entity';
import { TranslationMapHandler } from '../translation-map';
import { from } from 'rxjs';

export const STAFF_CUSTOM_MAPPING_TYPE = 'staffCustomMapping';

export const STAFFER_MAPPING = CustomMapping.init({
  metadata: {
    label: 'Staff',
    description: `Used for mapping practitioners to the correct Staffer in Principle.

    The Source Identifier is the staff id, the Destination Identifier is the DocumentReference path for the staff member.`,
    type: STAFF_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.DocumentReference,
});

export abstract class StafferMappingHandler<
  Staffer extends object,
  SourceEntity extends BaseSourceEntity<Staffer>,
> extends BaseCustomMappingHandler<IStaffer> {
  customMapping = STAFFER_MAPPING;
  abstract translateFn: (record: Staffer) => IBaseMigrationStaffer;
  abstract sourceEntity: SourceEntity;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 1000)
      .toPromise();
    const options = records
      .map((record) => record.data.data)
      .map((record) => {
        const staffer = this.translateFn(record);
        return {
          label: `${staffer.name} ${staffer.providerNo}`,
          value: this.sourceEntity.getSourceRecordId(record).toString(),
        };
      });

    return sortBy(
      [
        ...options,
        {
          label: `Default Practitioner (When no practitioner is found)`,
          value: '0',
        },
      ],
      'label'
    );
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument[]> {
    return snapshot(
      from(
        Firestore.getDocs(
          undeletedQuery(Brand.stafferCol(migration.configuration.brand))
        )
      ).pipe(
        multiMap((staffer) => ({
          name: `${staffer.user.name} - ${staffer.ref.id}`,
          ref: staffer.ref,
        })),
        multiSortBy$(nameSorter())
      )
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const fileName = `staff-mapping`;
    const appointmentStatuses = await this._getUserOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    await new XSLXImporterExporter().download(
      fileName,
      appointmentStatuses,
      new StafferToPractitionerToXSLX(
        await snapshot(Brand.staff$(migration.configuration.brand)),
        await snapshot(this.getRecords$(translationMap))
      )
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToStafferToPractitioner()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const sourceOptions = await this.getSourceOptions(migration);
    const staff = await snapshot(Brand.staff$(migration.configuration.brand));

    await asyncForEach(items, async (item) => {
      const matchingOption = sourceOptions.find(
        (sourceOption) => sourceOption.value === item.id
      );

      if (!matchingOption) {
        return;
      }

      const label = matchingOption.label;
      const value = matchingOption.value;

      const staffer = staff.find(
        (searchStaffer) => searchStaffer.user.name === item.mapTo
      );

      if (!staffer) {
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: staffer.ref,
          destinationValue: item.mapTo,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getUserOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<IBaseMigrationStaffer[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 10000)
      .toPromise();
    return sortBy(
      uniqBy(
        records.map((record) => this.translateFn(record.data.data)),
        (record) => record.id
      ),
      'first_name'
    );
  }
}

export function getPractitionerOrDefault(
  practitionerId: string | number | undefined,
  staffMap: WithRef<ITranslationMap<IStaffer>>[],
  practitioners: WithRef<IStaffer>[]
): WithRef<IStaffer> | undefined {
  const defaultProviderMap = staffMap.find(
    (staffer) => staffer.sourceIdentifier === '0'
  );

  const providerId = practitionerId?.toString();

  if (!providerId && !defaultProviderMap) {
    return;
  }

  const practitionerMap =
    !providerId || toInt(providerId) <= 0
      ? defaultProviderMap?.destinationIdentifier
      : staffMap.find((staffer) => staffer.sourceIdentifier === providerId)
          ?.destinationIdentifier;

  if (!practitionerMap) {
    return;
  }

  const practitioner = practitioners.find((searchPractitioner) =>
    isSameRef(searchPractitioner.ref, practitionerMap)
  );

  return practitioner;
}
