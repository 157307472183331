<div
  class="drag-handle"
  [ngClass]="{ disabled: cdkDragDisabled }"
  [ptDefaultTemplate]="defaultHandle"
>
  <ng-content select="[cdkDragHandle]"></ng-content>
</div>

<ng-template #defaultHandle>
  <mat-icon class="!p-0">drag_handle</mat-icon>
</ng-template>

<div class="box-content flex flex-1 flex-col items-stretch justify-center">
  <ng-content></ng-content>
</div>
