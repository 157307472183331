<pt-breadcrumbs current="Labs" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="layout-padding">
  <div fxLayout="row" fxLayoutGap="16px" class="layout-margin">
    <pt-search-field fxFlex>
      <input
        matInput
        placeholder="Search"
        #search
        (keyup)="searchLabs(search.value)"
      />
    </pt-search-field>
    <button mat-raised-button color="primary" (click)="addLab()">
      Create Lab
    </button>
  </div>

  <pr-empty-state *ngIf="emptyState$ | async" image="list" title="labs" />

  <div class="layout-margin mat-elevation-z1">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="dataSource.loading$ | async"
    />

    <ng-container *ngIf="dataSource.filteredData.length">
      <table
        mat-table
        #labTable
        matSort
        matSortActive="name"
        matSortDirection="asc"
        [dataSource]="dataSource"
        [trackBy]="trackByUid"
      >
        <mat-header-row *matHeaderRowDef="displayColumns" />
        <mat-row *matRowDef="let row; columns: displayColumns" />

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="truncate">
            Name
          </mat-header-cell>
          <mat-cell *matCellDef="let lab" class="truncate">
            <a [routerLink]="['./', lab.ref.id]">{{ lab.name }}</a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="address">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="truncate">
            Address
          </mat-header-cell>
          <mat-cell *matCellDef="let lab" class="truncate">
            {{ lab.address }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="phone">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="truncate">
            Phone
          </mat-header-cell>
          <mat-cell *matCellDef="let lab" class="truncate">
            <a [routerLink]="[]" (click)="openPhone(lab)">{{ lab.phone }}</a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="truncate">
            Email
          </mat-header-cell>
          <mat-cell *matCellDef="let lab" class="truncate">
            <a [routerLink]="[]" (click)="openEmail(lab)">{{ lab.email }}</a>
          </mat-cell>
        </ng-container>
      </table>

      <mat-paginator
        #paginator
        [length]="dataSource.data.length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        showFirstLastButtons
      />
    </ng-container>

    <pr-empty-state
      *ngIf="emptySearch$ | async"
      image="search"
      title="search results"
      [searchInputValue]="search.value"
    />
  </div>
</div>
