import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { type IBridgeDevice } from '@principle-theorem/principle-core/interfaces';

export type EditBridgeDeviceFormData = Pick<IBridgeDevice, 'name'>;

export interface IEditBridgeDeviceRequest {
  title: string;
  formData?: Partial<EditBridgeDeviceFormData>;
}

@Component({
  selector: 'pr-edit-bridge-device-dialog',
  templateUrl: './edit-bridge-device-dialog.component.html',
  styleUrls: ['./edit-bridge-device-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditBridgeDeviceDialogComponent {
  form = new TypedFormGroup<EditBridgeDeviceFormData>({
    name: new TypedFormControl<string>(
      '',
      Validators.pattern(/[a-zA-Z][-a-zA-Z0-9._+~%]+/)
    ),
  });

  constructor(
    private _dialogRef: MatDialogRef<
      EditBridgeDeviceDialogComponent,
      EditBridgeDeviceFormData
    >,
    @Inject(MAT_DIALOG_DATA) public data: Partial<IEditBridgeDeviceRequest>
  ) {
    if (data.formData) {
      this.form.patchValue(data.formData);
    }
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }
    this._dialogRef.close(this.form.getRawValue());
  }
}
