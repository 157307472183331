import {
  initVersionedSchema,
  toListContent,
  toParagraphContent,
} from '@principle-theorem/editor';
import { initFirestoreModel } from '@principle-theorem/shared';
import { type ISnippet } from '@principle-theorem/editor';

export const SNIPPETS: ISnippet[] = [
  {
    name: 'New Patient Exam',
    body: initVersionedSchema([
      toParagraphContent(`Title/Description, tooth no.:`),
      toListContent([]),
      toParagraphContent(`Prior warnings:`),
      toListContent([]),
      toParagraphContent(`Process/steps and materials:`),
      toListContent([]),
      toParagraphContent(`Post op instructions:`),
      toListContent([]),
      toParagraphContent(`Discussion points:`),
      toListContent([]),
    ]),
    description: '',
    keyword: '',
  },
  {
    name: 'Anaesthetics',
    body: initVersionedSchema([
      toListContent([
        `Type of anaesthetic used`,
        `Amount of anaesthetic used`,
        `Type of injection given`,
        `Any adverse reactions, allergies, or events.`,
      ]),
    ]),
    description: '',
    keyword: '',
  },
  {
    name: 'Restorations',
    body: initVersionedSchema([
      toListContent([
        `Base/linings used`,
        `Restoration material and shades used`,
        `Unusual depth or other features`,
        `Pin placement [no, yes]`,
        `Pulp exposure (size, location, mechanical/carious), if this has occurred.`,
      ]),
    ]),
    description: '',
    keyword: '',
  },
  {
    name: 'Exodontia',
    body: initVersionedSchema([
      toListContent([
        `Reasons for extraction`,
        `Radiographic evidence to support decision for extraction`,
        `Any complications`,
        `An indication if post-operative instructions were given`,
        `An indication if haemostasis has been achieved`,
        `Need for review, as required.`,
      ]),
    ]),
    description: '',
    keyword: '',
  },
  {
    name: 'Minor Oral Surgery',
    body: initVersionedSchema([
      toListContent([
        `Reason for procedure`,
        `Procedure undertaken including technique used`,
        `Supporting test/data/symptoms`,
        `Any complications`,
        `An indication if haemostasis has been achieved`,
        `An indication if post-operative instructions were given`,
        `Need for review, as required`,
      ]),
    ]),
    description: '',
    keyword: '',
  },
  {
    name: 'Medication',
    body: initVersionedSchema([
      toListContent([
        `The type of medication prescribed`,
        `Reason for administration of prescription`,
        `The dose of medication and indication of the method of delivery`,
        `If antibiotic prophylaxis is used, the time of administration and the time of commencement of treatment`,
        `Any adverse reactions, allergies, or events`,
        `Results of antibiotic sensitivity testing, as required`,
        `Discussions with the patient's medical practitioner.`,
      ]),
    ]),
    description: '',
    keyword: '',
  },
  {
    name: 'Phone Message Left',
    body: initVersionedSchema(),
    description: '',
    keyword: '',
  },
  {
    name: 'Clean/Exam',
    body: initVersionedSchema([
      toParagraphContent(`Expressed Concerns:`),
      toListContent([]),
      toParagraphContent(`Reason for coming in:`),
      toListContent([]),
      toParagraphContent(`Cleaning Habits:`),
      toListContent([]),
      toParagraphContent(`Examination (gum & decay):`),
      toListContent([]),
      toParagraphContent(`Recommendations given:`),
      toListContent([]),
    ]),
    description: '',
    keyword: '',
  },
].map((data: Partial<ISnippet>) => ({
  name: '',
  body: initVersionedSchema(),
  ...initFirestoreModel(),
  ...data,
}));
