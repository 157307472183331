<button
  mat-icon-button
  [matMenuTriggerFor]="searchTypeMenu"
  matTooltip="Change search type"
>
  <mat-icon>manage_search</mat-icon>
</button>

<mat-menu #searchTypeMenu="matMenu">
  <ng-container *ngIf="selectedSearchOption$ | async as selectedSearchOption">
    <button
      *ngFor="
        let searchTypeOption of searchTypeOptions;
        trackBy: trackBySearchType
      "
      mat-menu-item
      [disabled]="selectedSearchOption === searchTypeOption.value"
      [matTooltip]="searchTypeOption.hint"
      (click)="selectedSearchOption$.next(searchTypeOption.value)"
    >
      <mat-icon>{{ searchTypeOption.icon }}</mat-icon>
      <span>{{ searchTypeOption.label }}</span>
    </button>
  </ng-container>
</mat-menu>
