import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  ObservableDataSource,
  TrackByFunctions,
  extendSortingDataAccessor,
} from '@principle-theorem/ng-shared';
import { MeasureFormatter } from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, multiMap } from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { BehaviorSubject, Subject, combineLatest, type Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { type IDataPoint } from '../../../../models/report/charts/measure-data-interfaces';
import { ReportBuilderStore } from '../../report-builder.store';
import {
  ResolvedColumns,
  type IResolvedColumnProperty,
} from '../report-builder-column-selector-dialog/resolved-columns';
import { ReportBuilderColumnManager } from './report-builder-column-manager';

@Component({
  selector: 'pr-report-builder-table',
  templateUrl: './report-builder-table.component.html',
  styleUrls: ['./report-builder-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ReportBuilderColumnManager],
})
export class ReportBuilderTableComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByColumn =
    TrackByFunctions.nestedField<IResolvedColumnProperty>('definition.uid');
  dataSource: ObservableDataSource;
  columns$ = new BehaviorSubject<IResolvedColumnProperty[]>([]);
  columnIds$: Observable<string[]>;

  pageSizeOptions = [50, 100, 250, 500];
  previousIndex: number;

  @ViewChild(MatPaginator)
  set paginator(paginator: MatPaginator) {
    if (paginator) {
      this.dataSource.paginator = paginator;
    }
  }

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor(
    public store: ReportBuilderStore,
    public columnManager: ReportBuilderColumnManager
  ) {
    const filteredData$ = this.store.results$.pipe(
      map((results) => results?.filtered ?? [])
    );
    this.dataSource = new ObservableDataSource(filteredData$);
    this.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, sortHeaderId) => this._sortingDataAccessor(data, sortHeaderId)
    );

    const selectedColumns$ = this.store.display$.pipe(
      map((display) => display?.columns ?? [])
    );

    combineLatest([
      this.store.dataSource$.pipe(filterUndefined()),
      selectedColumns$,
    ])
      .pipe(
        map(([dataSource, columns]) =>
          dataSource ? ResolvedColumns.toColumns(dataSource, columns) : []
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((columns) => this.columns$.next(columns));

    this.columnIds$ = this.columns$.pipe(
      multiMap((column) => column.definition.uid)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.dataSource.disconnect();
  }

  getDataPoint(data: IGetDataPointRequest<unknown>): IDataPoint {
    const fact = data.row;
    const property = data.column.property;
    return {
      label: ResolvedColumns.getLabel(data.column),
      formatter: property.metadata.formatter,
      formatterValue: property.metadata.formatterValue,
      value: property.measure.dataAccessor(fact),
    };
  }

  isLink(dataPoint?: IDataPoint | null): boolean {
    return dataPoint?.formatter === MeasureFormatter.Link;
  }

  hasValidLink(dataPoint?: IDataPoint | null): boolean {
    return !!dataPoint?.value;
  }

  isText(dataPoint?: IDataPoint | null): boolean {
    return dataPoint?.formatter === MeasureFormatter.Text;
  }

  private _sortingDataAccessor(
    record: unknown,
    sortHeaderId: string
  ): string | number | undefined {
    const column = this.columns$
      .getValue()
      .find((columnItem) => columnItem.definition.uid === sortHeaderId);

    if (!column) {
      return;
    }

    const value = column.property.measure.sortAccessor
      ? column.property.measure.sortAccessor(record)
      : column.property.measure.dataAccessor(record);

    if (isString(value) || isNumber(value)) {
      return value;
    }

    return value.toString();
  }
}

interface IGetDataPointRequest<T> {
  row: Record<string, T>;
  column: IResolvedColumnProperty;
}
