import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  DAY_MONTH_YEAR_FORMAT,
  ITimePeriod,
  snapshot,
  toMoment,
} from '@principle-theorem/shared';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { CSVExporterService, ICSVExport } from '../csv-exporter.service';
import { map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '../dialog-presets';
import {
  DownloadReportSettingsDialogComponent,
  DownloadReportSettingsDialogResult,
  IDownloadReportSettingsDialogData,
} from './download-report-settings-dialog/download-report-settings-dialog.component';

@Component({
  selector: 'pt-download-report',
  templateUrl: './download-report.component.html',
  styleUrl: './download-report.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadReportComponent {
  dateRange$ = new BehaviorSubject<ITimePeriod | undefined>(undefined);
  data$ = new ReplaySubject<object[]>(1);
  csvTranslator$ = new ReplaySubject<ICSVExport<object, object>>(1);
  isDisabled$: Observable<boolean>;

  @Input()
  set data(data: object[]) {
    if (data) {
      this.data$.next(data);
    }
  }

  @Input()
  set dateRange(dateRange: ITimePeriod | undefined) {
    this.dateRange$.next(dateRange);
  }

  @Input()
  set csvTranslator(csvTranslator: ICSVExport<object, object>) {
    if (csvTranslator) {
      this.csvTranslator$.next(csvTranslator);
    }
  }

  constructor(
    private _csvExporter: CSVExporterService,
    private _dialog: MatDialog
  ) {
    this.isDisabled$ = this.data$.pipe(
      map((data) => !data.length),
      startWith(true)
    );
  }

  async downloadReport(): Promise<void> {
    const csvTranslator = await snapshot(this.csvTranslator$);
    const dateRange = await snapshot(this.dateRange$);

    let defaultFileName = csvTranslator.defaultFileName;
    if (dateRange) {
      const startDate = toMoment(dateRange.from).format(DAY_MONTH_YEAR_FORMAT);
      const endDate = toMoment(dateRange.to).format(DAY_MONTH_YEAR_FORMAT);
      defaultFileName = `${csvTranslator.defaultFileName}-${startDate}-${endDate}`;
    }

    const settings = await this._dialog
      .open<
        DownloadReportSettingsDialogComponent,
        IDownloadReportSettingsDialogData,
        DownloadReportSettingsDialogResult
      >(
        DownloadReportSettingsDialogComponent,
        DialogPresets.small({
          data: {
            defaultFileName,
          },
        })
      )
      .afterClosed()
      .toPromise();
    if (!settings) {
      return;
    }

    const data = await snapshot(this.data$);
    await this._csvExporter.download(settings.fileName, data, csvTranslator, {
      includeHeaders: settings.includeHeaders,
      includeTotals: settings.includeTotals,
    });
  }
}
