<ng-container *ngIf="loadingState">
  <pr-list-skeleton *ngIf="store.loading$ | async" />
</ng-container>

<pr-empty-state
  *ngIf="emptyState$ | async"
  image="list"
  title="New Form Submissions"
 />

<ng-container *ngIf="store.submittedForms$ | async as forms">
  <ng-container *ngIf="forms.length">
    <ng-content></ng-content>

    <ng-container *ngFor="let form of forms; trackBy: trackByRef">
      <div
        matRipple
        *ngIf="form | map : displaySubmittedForm : this"
        (click)="openForm(form)"
        class="mb-2 flex w-full cursor-pointer justify-between rounded border border-solid border-gray-300 px-4 py-2 hover:bg-gray-100"
      >
        <div class="flex flex-col gap-1">
          <p class="!mb-0 text-xs text-gray-400">Form</p>
          <a>{{ form.ref.id | map : getFormName }}</a>
        </div>
        <div class="flex flex-col gap-1">
          <p class="!mb-0 text-xs text-gray-400">Submitted</p>
          <span>
            {{ form.form.date | moment | amTimeAgo }}
          </span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
