import {
  DocumentReference,
  ISoftDelete,
  Timestamp,
  TypeGuard,
  isDocRef,
  isTimestamp,
} from '@principle-theorem/shared';
import { IStaffer } from '../staffer/staffer';
import { IPractice } from '../practice/practice';
import { MixedSchema, isMixedSchema } from '@principle-theorem/editor';
import { isBoolean, isString } from 'lodash';

export enum ChatStatus {
  Open = 'open',
  Archived = 'archived',
}

export enum ChatType {
  Direct = 'direct',
  Group = 'group',
}

export enum ChatCollection {
  Messages = 'chatMessages',
  Interactions = 'chatInteractions',
}

export interface IChatMessage extends ISoftDelete {
  content: MixedSchema;
  authorRef: DocumentReference<IStaffer>;
  practiceRef: DocumentReference<IPractice>;
  readBy: DocumentReference<IStaffer>[];
  reactions: {
    emoji: string;
    authorRef: DocumentReference<IStaffer>;
  }[];
}

export interface IChat extends ISoftDelete {
  label?: string;
  type: ChatType;
  ownerRef: DocumentReference<IStaffer>;
  practiceRef: DocumentReference<IPractice>;
  participants: DocumentReference<IStaffer>[];
  status: ChatStatus;
  lastMessage?: DocumentReference<IChatMessage>;
  lastMessageAt?: Timestamp;
  muted?: boolean;
}

export function isChat(chat: unknown): chat is IChat {
  return TypeGuard.interface<IChat>({
    label: TypeGuard.undefinedOr(isString),
    type: TypeGuard.enumValue(ChatType),
    ownerRef: isDocRef,
    practiceRef: isDocRef,
    participants: TypeGuard.arrayOf(isDocRef),
    status: TypeGuard.enumValue(ChatStatus),
    lastMessage: TypeGuard.undefinedOr(isDocRef),
    lastMessageAt: TypeGuard.undefinedOr(isTimestamp),
    muted: TypeGuard.undefinedOr(isBoolean),
    deleted: isBoolean,
  })(chat);
}

export function isChatMessage(message: unknown): message is IChatMessage {
  return TypeGuard.interface<IChatMessage>({
    content: isMixedSchema,
    authorRef: isDocRef,
    practiceRef: isDocRef,
    readBy: TypeGuard.arrayOf(isDocRef),
    reactions: TypeGuard.arrayOf(
      TypeGuard.interface({
        emoji: isString,
        authorRef: isDocRef,
      })
    ),
    deleted: isBoolean,
  })(message);
}
