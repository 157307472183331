import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IOrganisation,
  OrganisationStatus,
} from '@principle-theorem/principle-core/interfaces';
import { patchDoc, snapshot, WithRef } from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-organisation-actions',
  templateUrl: './organisation-actions.component.html',
  styleUrls: ['./organisation-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationActionsComponent {
  organisation$ = new ReplaySubject<WithRef<IOrganisation>>(1);
  isPending$: Observable<boolean>;
  isActive$: Observable<boolean>;
  isCancelled$: Observable<boolean>;

  @Input()
  set organisation(organisation: WithRef<IOrganisation>) {
    if (organisation) {
      this.organisation$.next(organisation);
    }
  }

  constructor() {
    this.isPending$ = this.organisation$.pipe(
      map((organisation) => organisation.status === OrganisationStatus.Pending)
    );

    this.isActive$ = this.organisation$.pipe(
      map((organisation) => organisation.status === OrganisationStatus.Active)
    );

    this.isCancelled$ = this.organisation$.pipe(
      map(
        (organisation) => organisation.status === OrganisationStatus.Cancelled
      )
    );
  }

  async markActive(): Promise<void> {
    const organisation = await snapshot(this.organisation$);
    await patchDoc(organisation.ref, {
      status: OrganisationStatus.Active,
    });
  }

  async markPending(): Promise<void> {
    const organisation = await snapshot(this.organisation$);
    await patchDoc(organisation.ref, {
      status: OrganisationStatus.Pending,
    });
  }

  async markCancelled(): Promise<void> {
    const organisation = await snapshot(this.organisation$);
    await patchDoc(organisation.ref, {
      status: OrganisationStatus.Cancelled,
    });
  }
}
