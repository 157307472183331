import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  IClaimEstimateSummary,
  IClaimEstimateSummaryItem,
} from '../../claim-estimate/claim-estimate-provider';

@Component({
    selector: 'pr-claim-estimate-summary-dialog',
    templateUrl: './claim-estimate-summary-dialog.component.html',
    styleUrls: ['./claim-estimate-summary-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ClaimEstimateSummaryDialogComponent {
  trackByCode = TrackByFunctions.index<IClaimEstimateSummaryItem>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: IClaimEstimateSummary) {}
}
