import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_ATTACHMENT_RESOURCE_TYPE = 'patientAttachments';

export const PATIENT_ATTACHMENT_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Attachment List',
      description: '',
      idPrefix: PATIENT_ATTACHMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export enum CorePracticePatientAttachmentTag {
  Invoice = 'invoice',
  Referral = 'referral',
  Payment = 'payment',
  Medical = 'medical',
  Form = 'form',
  Report = 'report',
  LapSheet = 'lapSheet',
  TreatmentPlan = 'treatPlan',
  NewPatient = 'newPat',
}

export interface ICorePracticePatientAttachment {
  id: string; // wDhLFrdEMUqahD6Zyv5JeQ
  fileName: string; // jonathan invoice.jpg
  attachmentDate: string; // 2021-11-24 22:19:18.843
  patientId: number;
  tag: CorePracticePatientAttachmentTag; // invoice
  isDeleted: boolean;
}

export function isCorePracticePatientAttachment(
  item: unknown
): item is ICorePracticePatientAttachment {
  return TypeGuard.interface<ICorePracticePatientAttachment>({
    id: isString,
    fileName: isString,
    attachmentDate: isString,
    patientId: isNumber,
    tag: isString,
    isDeleted: isBoolean,
  })(item);
}

export interface ICorePracticePatientAttachmentTranslations {}

export interface ICorePracticePatientAttachmentFilters {
  patientId: number;
}

const PATIENT_ATTACHMENT_SOURCE_QUERY = `
SELECT
  Identifier AS id,
  FileName AS file_name,
  AttachmentDate AS attachment_date,
  PatientId AS patient_id,
  Tag AS tag,
  convert_to_boolean(IsDeleted) AS is_deleted
FROM tblAttachment
`;

export class PatientAttachmentSourceEntity extends BaseSourceEntity<
  ICorePracticePatientAttachment,
  ICorePracticePatientAttachmentTranslations,
  ICorePracticePatientAttachmentFilters
> {
  sourceEntity = PATIENT_ATTACHMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_ATTACHMENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_ATTACHMENT_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientAttachment;

  translate(
    _data: ICorePracticePatientAttachment,
    _timezone: Timezone
  ): ICorePracticePatientAttachmentTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientAttachment): string {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientAttachment): string {
    return `${data.id} - ${data.fileName}`;
  }

  getFilterData(
    data: ICorePracticePatientAttachment,
    _timezone: Timezone
  ): ICorePracticePatientAttachmentFilters {
    return {
      patientId: data.patientId,
    };
  }
}
