import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { TypedFormControl, toSearchStream } from '@principle-theorem/ng-shared';
import {
  ISterilisationPack,
  ISterilisationEquipment,
  ISterilisationPackContent,
  SterilisableItem,
} from '@principle-theorem/principle-core/interfaces';
import { INamedDocument } from '@principle-theorem/shared';
import { SterilisationStore } from '../../stores/sterilisation.store';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { isString } from 'lodash';
import { map } from 'rxjs/operators';
import { ReactiveFormsModule } from '@angular/forms';
import { PackContentsEditorComponent } from '../pack-contents-editor/pack-contents-editor.component';

interface ISterilisationPackGroup<T> {
  label: string;
  items: INamedDocument<T>[];
}

interface ISterilisationPackGroups {
  packs: ISterilisationPackGroup<ISterilisationPack>;
  equipment: ISterilisationPackGroup<ISterilisationEquipment>;
}

@Component({
    selector: 'pr-sterilisation-pack-selector',
    imports: [NgMaterialModule, ReactiveFormsModule, PackContentsEditorComponent],
    templateUrl: './sterilisation-pack-selector.component.html',
    styleUrl: './sterilisation-pack-selector.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SterilisationPackSelectorComponent {
  private _store = inject(SterilisationStore);
  packGroups$: Observable<ISterilisationPackGroups>;
  filteredPackGroups$: Observable<ISterilisationPackGroups>;
  content$ = new BehaviorSubject<ISterilisationPackContent[]>([]);
  pack$ = new BehaviorSubject<INamedDocument<SterilisableItem> | undefined>(
    undefined
  );
  packGroupSearchCtrl = new TypedFormControl<
    string | INamedDocument<SterilisableItem>
  >('');

  @Input()
  set content(content: ISterilisationPackContent[]) {
    if (content) {
      this.content$.next(content);
    }
  }

  @Input()
  set pack(pack: INamedDocument<SterilisableItem>) {
    if (pack) {
      this.pack$.next(pack);
      this.packGroupSearchCtrl.setValue(pack);
    }
  }

  @Output() optionSelected = new EventEmitter<
    INamedDocument<SterilisableItem>
  >();
  @Output() updateContent = new EventEmitter<ISterilisationPackContent[]>();

  constructor() {
    this.packGroups$ = combineLatest([
      this._store.packs$,
      this._store.equipment$,
    ]).pipe(
      map(([packs, equipment]) => ({
        packs: {
          label: 'Packs',
          items: packs,
        },
        equipment: {
          label: 'Equipment',
          items: equipment,
        },
      }))
    );

    const searchStream = toSearchStream(this.packGroupSearchCtrl);

    this.filteredPackGroups$ = combineLatest([
      this.packGroups$,
      searchStream.pipe(
        map((searchValue) => this.displayFn(searchValue).toLowerCase())
      ),
    ]).pipe(
      map(([groups, searchValue]) => this._filterGroups(searchValue, groups))
    );
  }

  displayFn(value: INamedDocument<SterilisableItem> | string): string {
    if (!value) {
      return '';
    }
    if (!isString(value)) {
      return value.name;
    }
    return value;
  }

  private _filterGroups(
    searchValue: string,
    groups: ISterilisationPackGroups
  ): ISterilisationPackGroups {
    return {
      packs: {
        label: groups.packs.label,
        items: this._filterItems(groups.packs.items, searchValue),
      },
      equipment: {
        label: groups.equipment.label,
        items: this._filterItems(groups.equipment.items, searchValue),
      },
    };
  }

  private _filterItems<T>(
    items: INamedDocument<T>[],
    filterValue: string
  ): INamedDocument<T>[] {
    return items.filter((item) =>
      item.name.toLowerCase().includes(filterValue)
    );
  }
}
