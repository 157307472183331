import { type ISoftDelete } from '@principle-theorem/shared';
import {
  type CollectionReference,
  type Timestamp,
} from '@principle-theorem/shared';

export interface IToken extends ISoftDelete {
  tokenUid: string;
}

export interface ITemporaryToken extends IToken {
  expiresAt: Timestamp;
}

export interface ITokenStorage<T extends IToken> {
  generate(
    data: Omit<IToken, 'tokenUid'>,
    tokenCollection: CollectionReference<T>
  ): Promise<string>;
  remove(
    tokenUid: string,
    tokenCollection: CollectionReference<T>
  ): Promise<void>;
  get(
    tokenUid: string,
    tokenCollection: CollectionReference<T>
  ): Promise<T | undefined>;
  has(
    tokenUid: string,
    tokenCollection: CollectionReference<T>
  ): Promise<boolean>;
}
