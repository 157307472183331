import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import {
  serialise,
  toEntityModel,
  unserialise$,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import {
  addPatientFromResolver,
  setSelectedPatient,
} from '../actions/patients.actions';
import type * as fromPatients from '../reducers/patients.reducer';
import { getSelectedPatient } from '../reducers/patients.reducer';
import { type PatientEntity } from '../reducers/states';

@Injectable()
export class PatientsFacade {
  private _store = inject(Store<fromPatients.IPatientsState>);
  currentPatient$: Observable<PatientEntity | undefined>;

  constructor() {
    this.currentPatient$ = this._store.pipe(
      select(getSelectedPatient),
      unserialise$()
    );
  }

  loadPatient(patient: WithRef<IPatient>): void {
    const entity = toEntityModel(serialise(patient));

    this._store.dispatch(
      addPatientFromResolver({
        patient: { ...entity },
      })
    );
    this._store.dispatch(setSelectedPatient({ id: entity.uid }));
  }
}
