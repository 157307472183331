import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import {
  type MatAutocomplete,
  type MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type MatSelectionList } from '@angular/material/list';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  AreaSummary,
  AreaSummaryFactory,
  ChartedCondition,
  TreatmentTypeConfiguration,
} from '@principle-theorem/principle-core';
import {
  type IAreaSummary,
  type IBrand,
  type IChartedCondition,
  type IChartedSurface,
  type IStaffer,
  type TreatmentTypeConfiguration as ITreatmentTypeConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  combineReduce,
  multiFilter,
  shareReplayCold,
  type WithRef,
} from '@principle-theorem/shared';
import { isEqual, isString } from 'lodash';
import { BehaviorSubject, combineLatest, type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'pr-treat-condition-dialog',
    templateUrl: './treat-condition-dialog.component.html',
    styleUrls: ['./treat-condition-dialog.component.scss'],
    standalone: false
})
export class TreatConditionDialogComponent {
  trackBySummary = TrackByFunctions.label<IAreaSummary>();
  trackByTreatment = TrackByFunctions.ref<ITreatmentTypeConfiguration>();
  matchingTreatments$: Observable<ITreatmentTypeConfiguration[]>;
  selectedTreatments: ITreatmentTypeConfiguration[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  summaryCtrl: TypedFormControl<string> = new TypedFormControl();
  filteredSummaries$: Observable<IAreaSummary[]>;
  summaries$: BehaviorSubject<IAreaSummary[]> = new BehaviorSubject<
    IAreaSummary[]
  >([]);
  allSummaries: IAreaSummary[] = [];

  @ViewChild('treatmentsList')
  treatmentsList: MatSelectionList;
  @ViewChild('summaryInput', { static: true })
  summaryInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ITreatConditionData,
    public dialogRef: MatDialogRef<
      TreatConditionDialogComponent,
      ITreatConditionResult
    >
  ) {
    this._loadTreatmentOptions();

    this.summaries$.next(this._buildAreaSummariesBySurface());
    this.allSummaries = this._buildAreaSummariesBySurface();

    const search$: Observable<string> = this.summaryCtrl.valueChanges.pipe(
      startWith(''),
      shareReplayCold()
    );
    this.filteredSummaries$ = combineLatest([search$, this.summaries$]).pipe(
      map(([summary, _]) => {
        return summary ? this._filter(summary) : this._availableSummaries();
      })
    );
  }

  save(): void {
    const treatments: ITreatmentTypeConfiguration[] =
      this.treatmentsList.selectedOptions.selected.map(
        (option) => option.value as ITreatmentTypeConfiguration
      );

    if (!treatments.length) {
      this.dialogRef.close();
    }

    const chartedSurfaces: IChartedSurface[] = this.summaries$.value
      .map((summary) => {
        return summary.values.map((value) => value.surface);
      })
      .reduce(
        (surfaces, currentSurfaces) => [...surfaces, ...currentSurfaces],
        []
      );
    this.dialogRef.close({
      treatments,
      chartedSurfaces,
    });
  }

  removeSurface(summary: IAreaSummary): void {
    const summaries: IAreaSummary[] = this.summaries$.value;
    const index: number = summaries.indexOf(summary);

    if (index < 0) {
      return;
    }

    summaries.splice(index, 1);
    this.summaries$.next(summaries);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.summaries$.next([
      ...this.summaries$.value,
      event.option.value as IAreaSummary,
    ]);
    this.summaryInput.nativeElement.value = '';
    this.summaryCtrl.setValue('');
  }

  asCompact(summary: IAreaSummary): string {
    return AreaSummary.asCompact(summary);
  }

  private _filter(value: string | IAreaSummary): IAreaSummary[] {
    if (!isString(value)) {
      return this._availableSummaries();
    }
    return this._availableSummaries().filter(
      (summary) =>
        AreaSummary.asCompact(summary)
          .toLowerCase()
          .indexOf(value.toLowerCase()) === 0
    );
  }

  private _loadTreatmentOptions(): void {
    this.matchingTreatments$ = combineReduce([
      TreatmentTypeConfiguration.chartableItems$(this.data.brand),
      TreatmentTypeConfiguration.chartableItems$(this.data.chartingAs),
    ]).pipe(
      multiFilter((item) =>
        item.conditions.some(
          (condition) => this.data.condition.config.ref.path === condition.path
        )
      )
    );
  }

  private _availableSummaries(): IAreaSummary[] {
    const summaries: IAreaSummary[] = this.allSummaries
      .slice()
      .filter((summary: IAreaSummary) => {
        return !this.summaries$.value.some((selectedSummary: IAreaSummary) => {
          return isEqual(summary, selectedSummary);
        });
      });
    return summaries;
  }

  private _buildAreaSummariesBySurface(): IAreaSummary[] {
    const factory: AreaSummaryFactory = new AreaSummaryFactory();
    return factory.create(
      ChartedCondition.allChartedSurfaces(this.data.condition)
    );
  }
}

export interface ITreatConditionData {
  chartingAs: WithRef<IStaffer>;
  brand: WithRef<IBrand>;
  condition: IChartedCondition;
}

export interface ITreatConditionResult {
  treatments: ITreatmentTypeConfiguration[];
  chartedSurfaces: IChartedSurface[];
}
