import { InlineNodes, type IMenuButton } from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../menu-bar/basic-menu-button/basic-menu-button.component';
import { MENTION_KEYMAP, createMention } from './mention-keymap';

export function createMentionMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'basic',
      buttonText: 'Mention',
      tooltip: 'Mention',
      shortcut: MENTION_KEYMAP,
      icon: 'alternate_email',
      command: (editor) => createMention(editor),
      isActiveFn: (editor) => editor.isActive(InlineNodes.MentionAutocomplete),
    },
  });
}
