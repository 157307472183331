import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import {
  formControlChanges$,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { CONDITION_LABEL_MAP } from '@principle-theorem/principle-core';
import {
  type ConditionLogicId,
  type IConditionLogicConfiguration,
  type IDynamicForm,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isChanged$,
  shareReplayCold,
} from '@principle-theorem/shared';
import { keys, mapValues } from 'lodash';
import { from, merge, type Observable, ReplaySubject, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { ConditionalLogicStore } from '../conditional-logic-store.service';

@Component({
    selector: 'pr-conditional-logic-item',
    templateUrl: './conditional-logic-item.component.html',
    styleUrls: ['./conditional-logic-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConditionalLogicItemComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _condition$ = new ReplaySubject<
    IConditionLogicConfiguration<unknown>
  >(1);
  trackByConditionId = TrackByFunctions.variable<ConditionLogicId>();
  dynamicForm$: Observable<IDynamicForm>;
  hasDynamicForm$: Observable<boolean>;
  conditionCtrl = new TypedFormControl<ConditionLogicId>();
  formValue$ = new Subject<unknown>();
  @Output() conditionChange = new EventEmitter<
    IConditionLogicConfiguration<unknown>
  >();

  @Input()
  set condition(condition: IConditionLogicConfiguration<unknown>) {
    if (condition) {
      this._condition$.next(condition);
    }
  }

  constructor(public store: ConditionalLogicStore) {
    this._condition$
      .pipe(
        map((condition) => condition.conditionId),
        distinctUntilChanged(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((conditionId) =>
        this.conditionCtrl.setValue(conditionId, { emitEvent: false })
      );

    this.dynamicForm$ = this._condition$.pipe(
      map((condition) => condition.conditionId),
      filterUndefined(),
      withLatestFrom(this._condition$),
      switchMap(([conditionId, condition]) =>
        this._getDynamicForm$(conditionId, condition)
      ),
      shareReplayCold()
    );

    this.hasDynamicForm$ = this.dynamicForm$.pipe(
      map((form) => keys(form).length >= 1)
    );

    const initialValue$ = this.dynamicForm$.pipe(
      map((dynamicForm) => getInitialValue(dynamicForm))
    );

    const formValueChanges$ = this.formValue$.pipe(
      withLatestFrom(this._condition$),
      map(([formValue, condition]) => ({
        config: formValue,
        conditionId: this.conditionCtrl.value,
        uid: condition.uid,
      }))
    );

    const conditionChanges$ = formControlChanges$(this.conditionCtrl).pipe(
      filterUndefined(),
      switchMap((conditionId) =>
        merge(initialValue$, this.formValue$).pipe(
          withLatestFrom(this._condition$),
          map(([config, condition]) => ({
            config,
            conditionId,
            uid: condition.uid,
          }))
        )
      )
    );

    merge(formValueChanges$, conditionChanges$)
      .pipe(isChanged$(), takeUntil(this._onDestroy$))
      .subscribe((conditionConfig) =>
        this.conditionChange.emit(conditionConfig)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  formatLabel(label: ConditionLogicId): string {
    return CONDITION_LABEL_MAP[label];
  }

  compareConditionId(
    aCondition: ConditionLogicId,
    bCondition: ConditionLogicId
  ): boolean {
    return aCondition === bCondition;
  }

  private _getDynamicForm$(
    conditionId: ConditionLogicId,
    condition: IConditionLogicConfiguration<unknown>
  ): Observable<IDynamicForm> {
    const initialValue =
      condition.conditionId === conditionId ? condition.config : undefined;
    return from(this.store.getDynamicForm(conditionId, initialValue));
  }
}

function getInitialValue(dynamicForm?: IDynamicForm): Record<string, unknown> {
  if (!dynamicForm) {
    return {};
  }
  return mapValues(dynamicForm, (field): unknown => field.initialValue);
}

export function setInitialValue(
  dynamicForm: IDynamicForm | undefined,
  overrideValue: Record<string, unknown>
): Record<string, unknown> {
  return mapValues(dynamicForm, (field, key) => {
    const initialValue: unknown = overrideValue[key] ?? field.initialValue;
    return { ...field, initialValue };
  });
}
