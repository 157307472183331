import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogInput,
} from '@principle-theorem/ng-shared';
import { type IProduct } from '@principle-theorem/principle-core/interfaces';
import {
  deleteDoc,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { UpsertProductDialogComponent } from '../upsert-product-dialog/upsert-product-dialog.component';

@Component({
    selector: 'pr-product-list-item',
    templateUrl: './product-list-item.component.html',
    styleUrls: ['./product-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProductListItemComponent {
  product$ = new ReplaySubject<WithRef<IProduct>>(1);

  constructor(private _dialog: MatDialog, private _snackBar: MatSnackBar) {}

  @Input()
  set product(product: WithRef<IProduct>) {
    if (product) {
      this.product$.next(product);
    }
  }

  async edit(): Promise<void> {
    const product = await snapshot(this.product$);
    const dialogRef: MatDialogRef<UpsertProductDialogComponent> =
      this._dialog.open(
        UpsertProductDialogComponent,
        DialogPresets.medium({
          data: {
            product,
          },
        })
      );

    const productChange: WithRef<IProduct> = await dialogRef
      .afterClosed()
      .toPromise<WithRef<IProduct>>();
    if (!productChange) {
      return;
    }
    await patchDoc(product.ref, productChange);
    this._snackBar.open('Product Updated');
  }

  async delete(): Promise<void> {
    const product = await snapshot(this.product$);
    const data = confirmationDialogData({
      title: 'Delete Product',
      prompt: `Are you sure you want to delete ${product.name}?`,
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }
    await deleteDoc(product.ref);
    this._snackBar.open(`${product.name} Deleted`);
  }
}
