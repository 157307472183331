<div *ngrxLet="state$ | async as state" class="relative flex items-start gap-2">
  <ng-content></ng-content>
  <div *ngIf="state" class="flex flex-col items-center gap-2.5">
    <span *ngIf="state.hasPatientChanges" class="warning-background"></span>
    <mat-icon class="tooltip-icon" [matTooltip]="state.hintMessage">
      info
    </mat-icon>
    <button
      mat-icon-button
      [matTooltip]="state.tooltipMessage"
      (click)="revertChange.emit()"
    >
      <mat-icon>swap_horiz</mat-icon>
    </button>
  </div>
</div>
