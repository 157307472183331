<ng-container *ngIf="visibleActions$ | ngrxPush as visibleActions">
  <div *ngIf="visibleActions.length" fxLayout="row" fxLayoutGap="16px">
    <pr-appointment-card-action
      *ngFor="let action of visibleActions; trackBy: trackByAction"
      [action]="action"
      [appointment]="appointment$ | ngrxPush"
      [patient]="patient$ | ngrxPush"
      (updateEventable)="updateEventable.emit($event)"
    />
  </div>
</ng-container>
