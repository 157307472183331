import { asDocRef, getDoc, IProvider } from '@principle-theorem/shared';
import {
  ICandidateCalendarEvent,
  IPrincipleMention,
  ITemplateContextOption,
  MentionResourceType,
  TemplateScope,
} from '@principle-theorem/principle-core/interfaces';
import {
  GapContextBuilder,
  ScopeDataBuilder,
} from '@principle-theorem/principle-core';

export class GapMentionContextProvider
  implements IProvider<TemplateScope[], Promise<ITemplateContextOption[]>>
{
  constructor(private _mention: IPrincipleMention, private _appUrl: string) {}

  canProvide(scopes: TemplateScope[]): boolean {
    const type = this._mention.resource.type;
    const isGapScope = scopes.includes(TemplateScope.GapOffer);
    const isGapResource = type === MentionResourceType.Gap;
    return isGapScope && isGapResource;
  }

  async execute(): Promise<ITemplateContextOption[]> {
    const gapCandidate = await getDoc(
      asDocRef<ICandidateCalendarEvent>(this._mention.resource.ref)
    );
    const scopeData = await ScopeDataBuilder.buildGapScopeData(
      gapCandidate,
      this._appUrl
    );
    if (!scopeData) {
      return [];
    }
    const context = new GapContextBuilder(scopeData).build();

    return [
      {
        label: 'Gap Candidate Offer',
        scope: TemplateScope.GapOffer,
        context,
        scopeData,
      },
    ];
  }
}
