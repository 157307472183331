<mat-button-toggle-group
  class="filter-presets"
  [value]="activeType$ | async"
  [hideSingleSelectionIndicator]="true"
  (change)="typeSelected.emit($event.value)"
>
  <mat-button-toggle
    *ngFor="let filter of typeFilters; trackBy: trackByFilter"
    [value]="filter"
  >
    <span>{{ filter }}</span>
  </mat-button-toggle>
</mat-button-toggle-group>
