import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { ChartType } from '@principle-theorem/principle-core/interfaces';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { getEnumValues } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-chart-type-selector',
  templateUrl: './chart-type-selector.component.html',
  styleUrls: ['./chart-type-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartTypeSelectorComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByType = TrackByFunctions.variable<ChartType>();
  types: ChartType[] = getEnumValues(ChartType);
  typeCtrl: TypedFormControl<ChartType> = new TypedFormControl(
    ChartType.Clinical
  );
  @Output()
  selectedChange: EventEmitter<ChartType> = new EventEmitter<ChartType>();

  @Input() set selected(selected: ChartType) {
    if (selected) {
      this.typeCtrl.setValue(selected, { emitEvent: false });
    }
  }

  constructor() {
    this.typeCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => this.selectedChange.emit(value));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
