import { Pipe, type PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Pipe({
  name: 'routePath',
})
export class RoutePathPipe implements PipeTransform {
  constructor(private _router: Router) {}

  transform(route: string, routePrefix: string = this._router.url): string {
    if (routePrefix.endsWith('/')) {
      return `${routePrefix.slice(0, -1)}/${route}`;
    }
    return `${routePrefix}/${route}`;
  }
}
