<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="mx-auto flex max-w-5xl flex-col gap-4 p-4">
  <pr-content-container>
    <div class="flex items-center justify-between gap-4">
      <h2 class="text-xl font-bold">Treatment Templates</h2>
      <pt-buttons-container>
        <button mat-flat-button color="primary" (click)="addTemplate()">
          Create Treatment Template
        </button>
      </pt-buttons-container>
    </div>

    <p class="font-bold">Available for booking online</p>
    @if (publicTreatments(); as publicTreatments) {
      @if (publicTreatments.length) {
        <pr-information-box>
          The treatment templates displayed here will appear in the same order
          on the online booking page.
        </pr-information-box>
        <mat-list cdkDropList (cdkDropListDropped)="dropPublicTemplate($event)">
          @for (
            treatmentTemplate of publicTreatments;
            track treatmentTemplate.ref.path
          ) {
            <div class="flex" cdkDrag>
              <div
                class="flex cursor-move items-center justify-center"
                cdkDragHandle
              >
                <mat-icon>drag_handle</mat-icon>
              </div>
              <pr-treatment-template
                class="w-full"
                [brand]="brand$ | async"
                [treatmentTemplate]="treatmentTemplate"
                (deleteTemplate)="deleteTemplate($event)"
                (editTemplate)="editTemplate($event)"
              />
            </div>
          }
        </mat-list>
      } @else {
        <pr-empty-state image="list" title="public templates" />
      }
    }

    <div class="mb-8 mt-4 border-b border-solid border-slate-300"></div>

    <p class="font-bold">Only available for booking by staff</p>
    @if (privateTreatments(); as privateTreatments) {
      @if (privateTreatments.length) {
        <mat-list>
          @for (
            treatmentTemplate of privateTreatments;
            track treatmentTemplate.ref.path
          ) {
            <pr-treatment-template
              [brand]="brand$ | async"
              [treatmentTemplate]="treatmentTemplate"
              (deleteTemplate)="deleteTemplate($event)"
              (editTemplate)="editTemplate($event)"
            />
          }
        </mat-list>
      } @else {
        <pr-empty-state image="list" title="private templates" />
      }
    }
  </pr-content-container>
</div>
