import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  type RouterStateSnapshot,
  type UrlTree,
  Router,
} from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { OrganisationService } from '../organisation.service';
import { map } from 'rxjs/operators';
import {
  filterUndefined,
  isSameRef,
  Time24hrType,
  TIME_FORMAT_24HR,
} from '@principle-theorem/shared';
import { ManagementService } from './management.service';
import * as moment from 'moment-timezone';

export enum UserRestrictedReason {
  Time = 'time',
  IP = 'ip',
}

export interface IUserRestrictedQueryParams {
  reason: UserRestrictedReason;
  restrictedUntil?: Time24hrType;
}

@Injectable()
export class IsTimeRestrictedGuard {
  constructor(
    private _router: Router,
    private _organisation: OrganisationService,
    private _management: ManagementService
  ) {}

  canActivate(
    _: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return combineLatest([
      this._organisation.practice$.pipe(filterUndefined()),
      this._management.user$,
      this._organisation.user$,
    ]).pipe(
      map(([practice, managementUser, user]) => {
        const userRestrictions = practice.restrictions?.userRestrictions;
        if (
          managementUser ||
          !userRestrictions?.isEnabled ||
          !userRestrictions?.restrictions.length
        ) {
          return true;
        }

        const found = userRestrictions.restrictions.find((restriction) =>
          isSameRef(restriction.user, user)
        );
        if (!found) {
          return true;
        }

        const now = moment()
          .tz(practice.settings.timezone)
          .format(TIME_FORMAT_24HR) as Time24hrType;

        const queryParams: IUserRestrictedQueryParams = {
          reason: UserRestrictedReason.Time,
          restrictedUntil: found.allowedTo,
        };

        return isWithinTimeRange(now, found.allowedFrom, found.allowedTo)
          ? true
          : this._router.createUrlTree(['/user-restricted'], {
              queryParams,
            });
      })
    );
  }
}

export function isWithinTimeRange(
  now: Time24hrType,
  from: Time24hrType,
  to: Time24hrType
): boolean {
  return now >= from && now <= to;
}
