import { type IXSLXImport } from '@principle-theorem/shared';
import type { Row } from 'exceljs';
import {
  IMedicalConditionToMedicalHistoryXSLX,
  MEDICAL_CONDITION_TO_MEDICAL_HISTORY_HEADERS,
} from './medical-condition-to-medical-history-to-xlsx';

export class XSLXToMedicalConditionToMedicalHistory
  implements IXSLXImport<IMedicalConditionToMedicalHistoryXSLX>
{
  headers = MEDICAL_CONDITION_TO_MEDICAL_HISTORY_HEADERS;

  translate(row: Row): IMedicalConditionToMedicalHistoryXSLX {
    return {
      sourceId: row.getCell('sourceId').value?.toString() ?? '',
      sourceName: row.getCell('sourceName').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IMedicalConditionToMedicalHistoryXSLX['mapTo'],
    };
  }
}
