import { Injectable } from '@angular/core';
import { FirebaseFunctionsService } from '@principle-theorem/ng-firebase';
import {
  PATIENT_PORTAL_DISPATCHER,
  PatientAction,
  PatientActionRequest,
  type IPatientMedicalHistoryFormTokenData,
  type ISubmitMedicalFormRequest,
  type IPatientFormSchema,
  type ITokenRequest,
} from '@principle-theorem/principle-core/interfaces';
import {
  type SerialisedData,
  unserialise$,
  serialise,
} from '@principle-theorem/shared';
import { type Observable, of, from } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PatientMedicalHistoryService {
  constructor(private _functions: FirebaseFunctionsService) {}

  resolvePatientMedicalHistoryData$(
    tokenUid: string
  ): Observable<IPatientMedicalHistoryFormTokenData | undefined> {
    const payload = {
      action: PatientAction.GetPatientMedicalHistoryFormData,
      data: { tokenUid },
    };

    const request = this._functions.call<
      PatientActionRequest<ITokenRequest>,
      SerialisedData<IPatientMedicalHistoryFormTokenData>
    >(PATIENT_PORTAL_DISPATCHER, payload);

    return from(request).pipe(
      catchError(() => of(undefined)),
      unserialise$()
    );
  }

  async submitFormData(
    tokenUid: string,
    patientFormSchema: IPatientFormSchema
  ): Promise<void> {
    const data: SerialisedData<ISubmitMedicalFormRequest> = serialise({
      tokenUid,
      data: patientFormSchema,
    });
    const payload = {
      action: PatientAction.SubmitPatientMedicalForm,
      data,
    };

    await this._functions.call<
      PatientActionRequest<SerialisedData<ISubmitMedicalFormRequest>>
    >(PATIENT_PORTAL_DISPATCHER, payload);
  }
}
