import {
  rand,
  randEmail,
  randFullName,
  randPhoneNumber,
  randStreetAddress,
} from '@ngneat/falso';
import {
  IPatientForm,
  PatientFormStatus,
  GENDERS,
  ICustomFormConfiguration,
  IPatientFormSchema,
  PatientFormType,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  MockISODate,
  Properties,
  toTimestamp,
} from '@principle-theorem/shared';
import { MockNamedDocument } from '@principle-theorem/testing';

export class PatientDetailsFormMock
  extends BaseFirestoreMock
  implements Properties<IPatientForm>
{
  form = PATIENT_DETAILS_MOCK_DATA;
  status = PatientFormStatus.Submitted;
  statusHistory = [];
  formType = PatientFormType.PatientDetailsForm;
  issuedAt = toTimestamp();
  template = MockNamedDocument<ICustomFormConfiguration>();
}

export const PATIENT_DETAILS_MOCK_DATA: IPatientFormSchema = {
  data: {
    name: `${randFullName()}`,
    dateOfBirth: MockISODate(),
    gender: rand(GENDERS),
    email: randEmail(),
    contactNumbers: [
      { label: '', number: randPhoneNumber() },
      { label: 'mobile', number: '0478123456' },
    ],
    address: randStreetAddress(),
  },
  date: toTimestamp(),
};
