import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store, select } from '@ngrx/store';
import { IFollowUpFormData } from '@principle-theorem/ng-follow-ups';
import {
  GlobalStoreService,
  getCurrentBrand,
  type IPrincipleState,
} from '@principle-theorem/ng-principle-shared';
import {
  ChartedItemScopeResolver,
  Event,
  Practice,
  TreatmentStep,
  upsertTreatmentsToStep,
  type OptionFinderQuery,
  OrganisationCache,
} from '@principle-theorem/principle-core';
import {
  ISchedulingEventData,
  isTreatmentStepFromTemplate,
  type IAppointment,
  type IAppointmentRequest,
  type IAppointmentSuggestion,
  type IBrand,
  type ICalendarEvent,
  type ICandidateCalendarEvent,
  type IChartedSurface,
  type IChecklistItem,
  type IEvent,
  type IEventTimePeriod,
  type IFeeSchedule,
  type IGap,
  type IImplementsTreatmentTemplate,
  type IInteractionV2,
  type IPatient,
  type IPatientDetails,
  type IPractice,
  type IStaffer,
  type ITag,
  type ITreatmentConfiguration,
  type ITreatmentStep,
  type ITreatmentStepFromTemplate,
  type ITreatmentTemplateWithStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  DEFAULT_CLOSE_TIME,
  DEFAULT_OPEN_TIME,
  filterUndefined,
  guardFilter,
  isINamedDocument,
  isWithRef,
  serialise,
  snapshot,
  toEntityModel,
  unserialise$,
  type INamedDocument,
  type ITimestampRange,
  type WithRef,
} from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { BehaviorSubject, combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  AppointmentDetailsActions,
  AppointmentRequestActions,
  AppointmentSelectorActions,
  AppointmentSuggestionActions,
  CancellationActions,
  PatientDetailsActions,
  SchedulingActions,
  WaitListActions,
} from '../actions';
import { type IRequestedAppointmentOption } from '../actions/appointment-scheduling.actions';
import {
  resetAppointmentSuggestions,
  setSearchType,
} from '../actions/appointment-suggestions.actions';
import {
  getFilterOptionsRangeSubject,
  isTreatmentOption,
  type AppointmentSuggestionEntity,
  type IAppointmentDetails,
  type IAppointmentFilterOptions,
  type IDefaultOption,
  type IWaitListDetails,
} from '../models';
import { type AppointmentSuggestionSearchType } from '../models/appointment-search';
import {
  filterSuggestions$,
  getPreferredEvent$,
} from '../models/appointment-suggestions';
import { type IAppointmentSchedulingPartialState } from '../reducers/appointment-scheduling.reducer';
import {
  getAppointment,
  getAppointmentDetails,
  getAppointmentFilterOptions,
  getAppointmentRequest,
  getAppointmentSaving,
  getChecklists,
  getPatientDetails,
  getPatientIdParam,
  getSchedulingInteractions,
  getSelectedPatient,
  getTags,
  getWaitListDetails,
} from '../selectors/appointment-scheduling.selectors';
import {
  getAvailableTimes,
  getCalendarEvents,
  getGapCandidates,
  getSelectedSuggestion,
  getSuggestionSearchType,
  getSuggestions,
  getSuggestionsLoaded,
} from '../selectors/appointment-suggestions.selectors';

@Injectable()
export class AppointmentSchedulingFacade {
  private _store = inject(
    Store<IAppointmentSchedulingPartialState & IPrincipleState>
  );
  patientIdParam$: Observable<string | undefined>;
  appointmentDetails$: Observable<IAppointmentDetails>;
  selectedSuggestion$: Observable<AppointmentSuggestionEntity | undefined>;
  selectedEvent$: Observable<IEvent | undefined>;
  selectedPatient$: Observable<WithRef<IPatient> | undefined>;
  patientDetails$: Observable<IPatientDetails | undefined>;
  waitlistDetails$: Observable<IWaitListDetails>;
  appointmentFilterOptions$: Observable<IAppointmentFilterOptions>;
  selectedPractitioner$: Observable<INamedDocument<IStaffer> | IDefaultOption>;
  practitioner$: Observable<WithRef<IStaffer> | undefined>;
  selectedPractice$: Observable<WithRef<IPractice> | undefined>;
  practice$: Observable<WithRef<IPractice> | undefined>;
  openTime$: Observable<Moment>;
  closeTime$: Observable<Moment>;
  preferredEvent$: Observable<ITimestampRange>;
  filterRange$: Observable<OptionFinderQuery>;
  brand$: Observable<WithRef<IBrand>>;
  availableTimes$: Observable<IEventTimePeriod[]>;
  gapCandidates$: Observable<WithRef<ICandidateCalendarEvent>[]>;
  calendarEvents$: Observable<WithRef<ICalendarEvent>[]>;
  loadingSuggestions$: Observable<boolean>;
  suggestionSearchType$: Observable<AppointmentSuggestionSearchType>;
  appointmentSuggestions$: Observable<AppointmentSuggestionEntity[]>;
  savingAppointment$: Observable<boolean>;
  interactions$: Observable<IInteractionV2[]>;
  currentAppointment$: Observable<WithRef<IAppointment> | undefined>;
  tags$: Observable<INamedDocument<ITag>[]>;
  checklists$: Observable<IChecklistItem[]>;
  appointmentRequest$: Observable<WithRef<IAppointmentRequest> | undefined>;
  selectedTreatmentDuration$: Observable<number>;
  selectedEventDurationWarning$: Observable<string>;
  durationWarningMessage$: Observable<string>;
  durationPlaceholder$: BehaviorSubject<string> = new BehaviorSubject(
    'Appointment Duration'
  );
  savingPatient$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private _globalState: GlobalStoreService,
    private _snackBar: MatSnackBar
  ) {
    this.patientIdParam$ = this._store.pipe(select(getPatientIdParam));
    this.appointmentDetails$ = this._store.pipe(
      select(getAppointmentDetails),
      unserialise$()
    );
    this.selectedSuggestion$ = this._store.pipe(
      select(getSelectedSuggestion),
      unserialise$()
    );
    this.selectedEvent$ = this.selectedSuggestion$.pipe(
      map((suggestion) => (suggestion ? suggestion.event : undefined))
    );
    this.selectedPatient$ = this._store.pipe(
      select(getSelectedPatient),
      unserialise$()
    );
    this.patientDetails$ = this._store.pipe(
      select(getPatientDetails),
      unserialise$()
    );
    this.waitlistDetails$ = this._store.pipe(
      select(getWaitListDetails),
      unserialise$()
    );
    this.appointmentFilterOptions$ = this._store.pipe(
      select(getAppointmentFilterOptions),
      unserialise$()
    );
    this.selectedPractitioner$ = this._getSelectedPractitioner$();
    this.practitioner$ = this.selectedPractitioner$.pipe(
      switchMap((selected) =>
        isINamedDocument<IStaffer>(selected)
          ? this._globalState.getStaffer$(selected.ref)
          : of(undefined)
      )
    );
    this.selectedPractice$ = this._getSelectedPractice$();
    this.practice$ = this.selectedPractice$.pipe(
      map((selected) => (isWithRef(selected) ? selected : undefined))
    );
    this.openTime$ = this.selectedPractice$.pipe(
      map((practice) =>
        practice ? Practice.openTime(practice) : DEFAULT_OPEN_TIME
      )
    );
    this.closeTime$ = this.selectedPractice$.pipe(
      map((practice) =>
        practice ? Practice.closeTime(practice) : DEFAULT_CLOSE_TIME
      )
    );
    this.preferredEvent$ = this.appointmentDetails$.pipe(
      filterUndefined(),
      map((details) => details.duration ?? 0),
      getPreferredEvent$
    );
    this.filterRange$ = this.appointmentFilterOptions$.pipe(
      map(getFilterOptionsRangeSubject)
    );
    this.brand$ = this._store.pipe(
      select(getCurrentBrand),
      unserialise$(),
      filterUndefined()
    );
    this.availableTimes$ = this._store.pipe(
      select(getAvailableTimes),
      unserialise$()
    );
    this.gapCandidates$ = this._store.pipe(
      select(getGapCandidates),
      unserialise$()
    );
    this.calendarEvents$ = this._store.pipe(
      select(getCalendarEvents),
      unserialise$()
    );
    this.loadingSuggestions$ = this._store.pipe(select(getSuggestionsLoaded));
    this.suggestionSearchType$ = this._store.pipe(
      select(getSuggestionSearchType)
    );
    this.appointmentSuggestions$ = this._store.pipe(
      select(getSuggestions),
      unserialise$(),
      filterSuggestions$(this.appointmentFilterOptions$, this.closeTime$)
    );
    this.savingAppointment$ = this._store.pipe(select(getAppointmentSaving));
    this.interactions$ = this._store.pipe(
      select(getSchedulingInteractions),
      unserialise$()
    );
    this.currentAppointment$ = this._store.pipe(
      select(getAppointment),
      unserialise$()
    );
    this.tags$ = this._store.pipe(select(getTags), unserialise$());
    this.checklists$ = this._store.pipe(select(getChecklists), unserialise$());
    this.appointmentRequest$ = this._store.pipe(
      select(getAppointmentRequest),
      unserialise$()
    );
    this.selectedTreatmentDuration$ = this._getSelectedTreatmentDuration$();
    this.selectedEventDurationWarning$ = this._selectedEventDurationWarning$();
    this.durationWarningMessage$ = this._durationWarningMessage$();
  }

  setPatientDetails(patientDetails: IPatientDetails): void {
    this._store.dispatch(
      PatientDetailsActions.setPatientDetails(serialise({ patientDetails }))
    );
  }

  patchPatientDetails(
    patientDetails: Partial<IPatientDetails>,
    save: boolean = false
  ): void {
    this._store.dispatch(
      PatientDetailsActions.patchPatientDetails(
        serialise({ patientDetails, save })
      )
    );
  }

  savePatient(): void {
    this.savingPatient$.next(true);
    this._store.dispatch(PatientDetailsActions.savePatient());
  }

  selectPatient(patient: WithRef<IPatient>): void {
    this._store.dispatch(
      PatientDetailsActions.selectPatient(serialise({ patient }))
    );
  }

  clearPatient(): void {
    this._store.dispatch(PatientDetailsActions.clearPatient());
  }

  clearPatientDetails(): void {
    this._store.dispatch(PatientDetailsActions.clearPatientDetails());
  }

  setAppointment(
    appointment: WithRef<IAppointment>,
    updateSelectedSuggestion: boolean = true
  ): void {
    this._store.dispatch(
      SchedulingActions.setExistingAppointment(
        serialise({ appointment, updateSelectedSuggestion })
      )
    );
  }

  setSuggestionSearchType(searchType: AppointmentSuggestionSearchType): void {
    this._store.dispatch(
      setSearchType({
        searchType,
      })
    );
  }

  appointmentFormChange(
    appointmentDetails: Partial<IAppointmentDetails>
  ): void {
    this._store.dispatch(
      AppointmentDetailsActions.appointmentDetailsChange(
        serialise({ appointmentDetails })
      )
    );
  }

  selectTreatmentTemplate(template: ITreatmentTemplateWithStep): void {
    this._store.dispatch(
      AppointmentDetailsActions.selectTreatmentTemplate(serialise({ template }))
    );
  }

  setAppointmentRequest(
    appointmentRequest: WithRef<IAppointmentRequest>
  ): void {
    this._store.dispatch(
      AppointmentRequestActions.setAppointmentRequest(
        serialise({ appointmentRequest })
      )
    );
  }

  setRequestedAppointmentOption(
    appointmentOptions: IRequestedAppointmentOption
  ): void {
    this._store.dispatch(
      SchedulingActions.setRequestedAppointmentOption(
        serialise(appointmentOptions)
      )
    );
  }

  addAppointmentSuggestion(suggestion: IAppointmentSuggestion): void {
    const appointmentSuggestion = toEntityModel(suggestion);

    this._store.dispatch(
      AppointmentSuggestionActions.addAppointmentSuggestion(
        serialise({ appointmentSuggestion })
      )
    );
  }

  cancelAppointmentRequest(
    appointmentRequest: WithRef<IAppointmentRequest>
  ): void {
    this._store.dispatch(
      AppointmentRequestActions.cancelAppointmentRequest(
        serialise({ ref: appointmentRequest.ref })
      )
    );
  }

  loadAppointmentAvailability(): void {
    this._store.dispatch(
      AppointmentSuggestionActions.loadAppointmentAvailability()
    );
  }

  resetAppointmentSuggestions(): void {
    this._store.dispatch(resetAppointmentSuggestions());
  }

  appointmentFilterOptionsChange(change: IAppointmentFilterOptions): void {
    this._store.dispatch(resetAppointmentSuggestions());
    this._store.dispatch(
      AppointmentSelectorActions.updateFilterOptions(serialise({ change }))
    );
  }

  selectSuggestion(id: string): void {
    return this._store.dispatch(
      AppointmentSuggestionActions.selectSuggestion({ id })
    );
  }

  unselectSuggestion(): void {
    return this._store.dispatch(
      AppointmentSuggestionActions.unselectSuggestion()
    );
  }

  toggleSuggestion(id?: string): void {
    return id ? this.selectSuggestion(id) : this.unselectSuggestion();
  }

  saveNewAppointment(schedulingEventData: ISchedulingEventData): void {
    this._store.dispatch(
      SchedulingActions.saveNewAppointment(serialise({ schedulingEventData }))
    );
  }

  rescheduleAppointment(schedulingEventData: ISchedulingEventData): void {
    this._store.dispatch(
      SchedulingActions.rescheduleAppointment(
        serialise({ schedulingEventData })
      )
    );
  }

  reset(): void {
    const resetAppointment = SchedulingActions.setExistingAppointment({
      appointment: undefined,
      updateSelectedSuggestion: true,
    });
    this._store.dispatch(resetAppointment);
    this._store.dispatch(AppointmentSuggestionActions.reset());
    this._store.dispatch(SchedulingActions.resetSchedulingState());
  }

  updateInteractions(interactions: IInteractionV2[]): void {
    this._store.dispatch(
      SchedulingActions.updateInteractions(serialise({ interactions }))
    );
  }

  resetWaitList(): void {
    this._store.dispatch(WaitListActions.reset());
  }

  updateWaitListDetails(update: IWaitListDetails): void {
    this._store.dispatch(
      WaitListActions.waitlistDetailsChange(serialise({ update }))
    );
  }

  cancelAppointment(
    followUpData: IFollowUpFormData,
    schedulingEventData: ISchedulingEventData
  ): void {
    this._store.dispatch(
      CancellationActions.cancelAppointment(
        serialise({ followUpData, schedulingEventData })
      )
    );
  }

  updateTags(tags: INamedDocument<ITag>[]): void {
    this._store.dispatch(
      SchedulingActions.updateAppointmentTags(serialise({ tags }))
    );
  }

  updateChecklists(checklists: IChecklistItem[]): void {
    this._store.dispatch(
      SchedulingActions.updateAppointmentChecklists(serialise({ checklists }))
    );
  }

  setAppointmentDetailsFromGap(gap: IGap): void {
    this._store.dispatch(
      SchedulingActions.setAppointmentDetailsFromGap(serialise({ gap }))
    );
  }

  async addTreatmentToStep(
    config: WithRef<ITreatmentConfiguration>,
    surfaces: IChartedSurface[],
    feeSchedule: WithRef<IFeeSchedule>,
    attributedTo?: INamedDocument<IStaffer>
  ): Promise<void> {
    const treatmentPlanPair = await snapshot(
      this.appointmentDetails$.pipe(map((appointment) => appointment.treatment))
    );

    if (!treatmentPlanPair || !isTreatmentOption(treatmentPlanPair)) {
      return;
    }

    const scopeResolver: ChartedItemScopeResolver =
      new ChartedItemScopeResolver();
    const surfaceScopeRefPairs = scopeResolver.reduceChartedSurfacesToScope(
      config,
      surfaces
    );

    treatmentPlanPair.step = await upsertTreatmentsToStep(
      treatmentPlanPair.step,
      config,
      surfaceScopeRefPairs,
      scopeResolver,
      feeSchedule,
      attributedTo
    );

    treatmentPlanPair.step = await TreatmentStep.updateDisplayPrimaryCategory(
      treatmentPlanPair.step,
      await snapshot(this._globalState.treatmentCategories$)
    );

    this.appointmentFormChange({
      treatment: treatmentPlanPair,
    });
  }

  async updateTreatmentStep(
    changes: Partial<
      WithRef<ITreatmentStep> | (ITreatmentStep & ITreatmentStepFromTemplate)
    >
  ): Promise<void> {
    const treatmentPlanPair = await snapshot(
      this.appointmentDetails$.pipe(map((appointment) => appointment.treatment))
    );

    if (!treatmentPlanPair || !isTreatmentOption(treatmentPlanPair)) {
      return;
    }

    treatmentPlanPair.step = {
      ...treatmentPlanPair.step,
      ...changes,
    };

    treatmentPlanPair.step = await TreatmentStep.updateDisplayPrimaryCategory(
      treatmentPlanPair.step,
      await snapshot(this._globalState.treatmentCategories$)
    );

    this.appointmentFormChange({
      treatment: treatmentPlanPair,
    });
  }

  async removeTreatmentFromStep(treatmentUuid: string): Promise<void> {
    const treatmentPlanPair = await snapshot(
      this.appointmentDetails$.pipe(map((appointment) => appointment.treatment))
    );

    if (!treatmentPlanPair || !isTreatmentOption(treatmentPlanPair)) {
      return;
    }

    treatmentPlanPair.step = TreatmentStep.deleteTreatment(
      treatmentPlanPair.step,
      treatmentUuid
    );

    await this.updateTreatmentStep(treatmentPlanPair.step);
    this._snackBar.open(`Treatment removed`);
  }

  private _durationWarningMessage$(): Observable<string> {
    return combineLatest([
      this.selectedTreatmentDuration$,
      this.appointmentDetails$.pipe(map((appointment) => appointment.duration)),
    ]).pipe(
      map(([treatmentDuration, selectedDuration]) =>
        selectedDuration && selectedDuration < treatmentDuration
          ? `This procedure has a default duration time of ${treatmentDuration} minutes.`
          : ''
      )
    );
  }

  private _selectedEventDurationWarning$(): Observable<string> {
    return combineLatest([
      this.selectedTreatmentDuration$,
      this.selectedEvent$,
    ]).pipe(
      map(([treatmentDuration, selectedEvent]) => {
        if (!selectedEvent) {
          return '';
        }
        const duration = Event.duration(selectedEvent);
        if (duration >= treatmentDuration) {
          return '';
        }
        const diff = treatmentDuration - duration;
        return `Selected event is ${diff} minutes short of the treatment default ${treatmentDuration} minutes`;
      })
    );
  }

  private _getSelectedTreatmentDuration$(): Observable<number> {
    return this.appointmentDetails$.pipe(
      map((appointment) => appointment.treatment),
      filterUndefined(),
      guardFilter(isTreatmentOption),
      map((treatmentPlanPair) => {
        if (!treatmentPlanPair) {
          return 0;
        }

        const step = treatmentPlanPair.step;

        if (!isTreatmentStepFromTemplate(step)) {
          this.durationPlaceholder$.next('Appointment Duration');
          return step.schedulingRules.duration;
        }

        let lowDuration = 0;
        let highDuration = 0;
        step.template.implementedBy.map(
          (implementedBy: IImplementsTreatmentTemplate) => {
            if (implementedBy.duration > highDuration) {
              highDuration = implementedBy.duration;
            }
            if (implementedBy.duration < lowDuration || lowDuration === 0) {
              lowDuration = implementedBy.duration;
            }
          }
        );
        if (lowDuration === highDuration || !highDuration || !lowDuration) {
          this.durationPlaceholder$.next('Appointment Duration');
          return lowDuration;
        }
        this.durationPlaceholder$.next(
          `Appointment Duration between ${lowDuration} and ${highDuration}`
        );
        return 0;
      })
    );
  }

  private _getSelectedPractitioner$(): Observable<
    INamedDocument<IStaffer> | IDefaultOption
  > {
    return combineLatest([this.selectedEvent$, this.appointmentDetails$]).pipe(
      map(([event, appointmentDetails]) => {
        if (event && event.organiser) {
          return event.organiser;
        }
        return appointmentDetails.practitioner;
      })
    );
  }

  private _getSelectedPractice$(): Observable<WithRef<IPractice> | undefined> {
    return combineLatest([
      this.selectedEvent$.pipe(
        switchMap((event) =>
          event
            ? OrganisationCache.practices.doc$(event.practice.ref)
            : of(undefined)
        )
      ),
      this.appointmentDetails$.pipe(map((details) => details.practice)),
    ]).pipe(
      map(([eventPractice, appointmentDetailsPractice]) => {
        if (eventPractice) {
          return eventPractice;
        }
        return appointmentDetailsPractice
          ? appointmentDetailsPractice
          : undefined;
      })
    );
  }
}
