import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { OrganisationFeeScheduleCollectionResolver } from '@principle-theorem/ng-clinical-fees';
import {
  BrandResolverService,
  HasPermissionGuard,
  IsIpRestrictedGuard,
  IsTimeRestrictedGuard,
  PracticeResolverService,
} from '@principle-theorem/ng-principle-shared';
import {
  BrandPermissions,
  OrganisationPermissions,
  PracticePermissions,
} from '@principle-theorem/principle-core/features';
import { FeeScheduleType } from '@principle-theorem/principle-core/interfaces';
import { AccountSnippetsListComponent } from './brands/account/account-snippets-list/account-snippets-list.component';
import { StafferAccountResolverService } from './brands/account/staffer-account-resolver.service';
import { AccountingComponent } from './brands/accounting/accounting.component';
import { BrandAutomationsComponent } from './brands/brand-automations/brand-automations.component';
import { BrandConditionConfigurationCollectionResolverService } from './brands/brand-condition-configuration-collection-resolver.service';
import { BrandCustomFormCollectionResolverService } from './brands/brand-custom-form-collection-resolver.service';
import { BrandFeeScheduleCollectionResolver } from './brands/brand-fee-schedule-collection-resolver.service';
import { BrandMultiTreatmentConfigurationCollectionResolverService } from './brands/brand-multi-treatment-configuration-collection-resolver.service';
import { BrandPracticeCollectionResolverService } from './brands/brand-practice-collection-resolver.service';
import { BrandPrescriptionItemCollectionResolverService } from './brands/brand-prescription-item-collection-resolver.service';
import { BrandProductCollectionResolverService } from './brands/brand-product-collection-resolver.service';
import { BrandSterilisationComponent } from './brands/brand-sterilisation/brand-sterilisation.component';
import { BrandTagsComponent } from './brands/brand-tags/brand-tags.component';
import { BrandTemplateCollectionResolver } from './brands/brand-template-collection-resolver.service';
import { BrandTreatmentConfigurationCollectionResolverService } from './brands/brand-treatment-configuration-collection-resolver.service';
import { BrandTreatmentTemplatesComponent } from './brands/brand-treatment-templates/brand-treatment-templates.component';
import { CancellationReasonsComponent } from './brands/cancellation-reasons/cancellation-reasons.component';
import { GeneralBrandComponent } from './brands/general-brand/general-brand.component';
import { MedicalHistoryFormPreviewComponent } from './brands/medical-history/medical-history-form-preview/medical-history-form-preview.component';
import { PracticeListComponent } from './brands/practices/practice-list/practice-list.component';
import { PrescriptionItemsComponent } from './brands/prescriptions/prescription-items/prescription-items.component';
import { ProductListComponent } from './brands/products/product-list/product-list.component';
import { ReferralSourcesComponent } from './brands/referral-sources/referral-sources.component';
import { StafferCollectionResolverService } from './brands/staff/staffer-collection-resolver.service';
import { TeamCollectionResolverService } from './brands/teams/team-collection-resolver.service';
import { TemplateCollectionResolver } from './brands/templating/template-collection-resolver';
import { TreatmentCategoriesListComponent } from './brands/treatment-categories/treatment-categories-list/treatment-categories-list.component';
import { GeneralPracticeComponent } from './practices/general-practice/general-practice.component';
import { PatientPortalComponent } from './practices/patient-portal/patient-portal/patient-portal.component';
import { PracticeIntegrationsComponent } from './practices/practice-integrations/practice-integrations.component';
import { PracticeOpeningHoursComponent } from './practices/practice-opening-hours/practice-opening-hours.component';
import { PracticeFeeScheduleResolver } from './practices/practice-schedule-resolver.service';
import { PracticeTagManagementComponent } from './practices/practice-tag-management/practice-tag-management.component';
import { SecurityComponent } from './security/security.component';
import { SettingsPagesModule } from './settings-pages.module';
import { SettingsComponent } from './settings.component';

const sharedSettingsGuards = [
  HasPermissionGuard,
  IsIpRestrictedGuard,
  IsTimeRestrictedGuard,
];

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
    children: [
      {
        path: 'workspace',
        children: [
          {
            path: 'fee-schedules',
            canActivate: sharedSettingsGuards,
            data: {
              title: 'Workspace Fee Schedules',
              feeScheduleType: FeeScheduleType.Organisation,
            },
            resolve: {
              feeScheduleCollection: OrganisationFeeScheduleCollectionResolver,
            },
            loadChildren: () =>
              import('./fee-schedule/fee-schedule-routing.module').then(
                (mod) => mod.FeeScheduleRoutingModule
              ),
          },
          {
            path: 'users',
            canActivate: sharedSettingsGuards,
            data: {
              title: 'Users',
              permissions: [OrganisationPermissions.OrganisationConfigureAuth],
            },
            loadChildren: () =>
              import('./users/user-pages-routing.module').then(
                (mod) => mod.UserPagesRoutingModule
              ),
          },
          {
            path: 'roles',
            canActivate: sharedSettingsGuards,
            data: {
              title: 'Roles',
              permissions: [OrganisationPermissions.OrganisationConfigureAuth],
            },
            loadChildren: () =>
              import('./roles/role-pages-routing.module').then(
                (mod) => mod.RolePagesRoutingModule
              ),
          },
          {
            path: 'integrations',
            canActivate: sharedSettingsGuards,
            data: {
              title: 'Integrations',
              permissions: [
                OrganisationPermissions.OrganisationConfigureIntegrations,
              ],
            },
            loadChildren: () =>
              import('./integrations/integrations-routing.module').then(
                (mod) => mod.IntegrationsRoutingModule
              ),
          },
          {
            path: 'security',
            component: SecurityComponent,
            canActivate: sharedSettingsGuards,
            data: {
              permissions: [OrganisationPermissions.OrganisationConfigureAuth],
            },
          },
        ],
      },
      {
        path: 'brand/:uid',
        resolve: {
          brand: BrandResolverService,
        },
        children: [
          {
            path: 'general',
            component: GeneralBrandComponent,
            canActivate: sharedSettingsGuards,
            data: {
              permissions: [
                BrandPermissions.BrandConfigure,
                BrandPermissions.BrandConfigureGeneral,
              ],
            },
          },
          {
            path: 'accounting',
            component: AccountingComponent,
          },
          {
            path: 'treatment-templates',
            component: BrandTreatmentTemplatesComponent,
            canActivate: sharedSettingsGuards,
            data: {
              permissions: [
                BrandPermissions.BrandConfigure,
                BrandPermissions.BrandConfigureClinical,
              ],
            },
          },
          {
            path: 'treatment-categories',
            component: TreatmentCategoriesListComponent,
            canActivate: sharedSettingsGuards,
            data: {
              permissions: [
                BrandPermissions.BrandConfigure,
                BrandPermissions.BrandConfigureClinical,
              ],
            },
          },
          {
            path: 'tags',
            component: BrandTagsComponent,
            canActivate: sharedSettingsGuards,
            data: {
              permissions: [
                BrandPermissions.BrandConfigure,
                BrandPermissions.TagsManage,
              ],
            },
          },
          {
            path: 'sterilisation',
            component: BrandSterilisationComponent,
            canActivate: [HasPermissionGuard],
            data: {
              permissions: [BrandPermissions.BrandConfigure],
            },
          },
          {
            path: 'referral-sources',
            component: ReferralSourcesComponent,
            canActivate: sharedSettingsGuards,
            data: {
              permissions: [
                BrandPermissions.BrandConfigure,
                BrandPermissions.BrandConfigureGeneral,
              ],
            },
          },
          {
            path: 'cancellation-reasons',
            component: CancellationReasonsComponent,
            canActivate: sharedSettingsGuards,
            data: {
              permissions: [
                BrandPermissions.BrandConfigure,
                BrandPermissions.BrandConfigureGeneral,
              ],
            },
          },
          {
            path: 'automations',
            component: BrandAutomationsComponent,
            canActivate: sharedSettingsGuards,
            data: {
              permissions: [
                BrandPermissions.BrandConfigure,
                BrandPermissions.BrandConfigureAutomations,
              ],
            },
          },
          {
            path: 'prescriptions',
            component: PrescriptionItemsComponent,
            resolve: {
              [BrandPrescriptionItemCollectionResolverService.resolverKey]:
                BrandPrescriptionItemCollectionResolverService,
            },
            canActivate: sharedSettingsGuards,
            data: {
              permissions: [
                BrandPermissions.BrandConfigure,
                BrandPermissions.BrandConfigurePrescriptions,
              ],
            },
          },
          {
            path: 'products',
            component: ProductListComponent,
            resolve: {
              [BrandProductCollectionResolverService.resolverKey]:
                BrandProductCollectionResolverService,
            },
            canActivate: sharedSettingsGuards,
            data: {
              permissions: [
                BrandPermissions.BrandConfigure,
                BrandPermissions.BrandConfigureProducts,
              ],
            },
          },
          {
            path: 'practices',
            component: PracticeListComponent,
            resolve: {
              [BrandPracticeCollectionResolverService.resolverKey]:
                BrandPracticeCollectionResolverService,
            },
            canActivate: sharedSettingsGuards,
            data: {
              permissions: [PracticePermissions.PracticeManage],
            },
          },
          {
            path: 'custom-forms',
            resolve: {
              [BrandCustomFormCollectionResolverService.resolverKey]:
                BrandCustomFormCollectionResolverService,
            },
            loadChildren: () =>
              import('./brands/custom-forms/custom-forms-routing.module').then(
                (mod) => mod.CustomFormsRoutingModule
              ),
          },
          {
            path: 'document-templates',
            resolve: {
              [TemplateCollectionResolver.resolverKey]:
                BrandTemplateCollectionResolver,
            },
            canActivate: sharedSettingsGuards,
            data: {
              permissions: [
                BrandPermissions.BrandConfigure,
                BrandPermissions.BrandConfigureGeneral,
              ],
            },
            loadChildren: () =>
              import('./brands/templating/templating-routing.module').then(
                (mod) => mod.TemplatingRoutingModule
              ),
          },
          {
            path: 'fee-schedules',
            resolve: {
              feeScheduleCollection: BrandFeeScheduleCollectionResolver,
            },
            canActivate: sharedSettingsGuards,
            data: {
              permissions: [
                BrandPermissions.BrandConfigure,
                BrandPermissions.BrandConfigureGeneral,
              ],
            },
            loadChildren: () =>
              import('./fee-schedule/fee-schedule-routing.module').then(
                (mod) => mod.FeeScheduleRoutingModule
              ),
          },
          {
            path: 'teams',
            canActivate: sharedSettingsGuards,
            data: {
              title: 'Teams',
              permissions: [
                BrandPermissions.BrandConfigure,
                BrandPermissions.BrandConfigureTeams,
              ],
            },
            resolve: {
              teamCollection: TeamCollectionResolverService,
            },
            loadChildren: () =>
              import('./brands/teams/teams-routing.module').then(
                (mod) => mod.TeamsRoutingModule
              ),
          },
          {
            path: 'staff',
            canActivate: sharedSettingsGuards,
            data: {
              title: 'Staff',
              permissions: [
                BrandPermissions.BrandConfigure,
                BrandPermissions.BrandConfigureStaff,
              ],
            },
            resolve: {
              stafferCollection: StafferCollectionResolverService,
            },
            loadChildren: () =>
              import('./brands/staff/staff-routing.module').then(
                (mod) => mod.StaffRoutingModule
              ),
          },
          {
            path: 'charting',
            resolve: {
              conditionConfigurationCol:
                BrandConditionConfigurationCollectionResolverService,
              treatmentConfigurationCol:
                BrandTreatmentConfigurationCollectionResolverService,
              multiTreatmentConfigurationCol:
                BrandMultiTreatmentConfigurationCollectionResolverService,
            },
            canActivate: sharedSettingsGuards,
            data: {
              permissions: [
                BrandPermissions.BrandConfigure,
                BrandPermissions.BrandConfigureClinical,
              ],
            },
            loadChildren: () =>
              import('@principle-theorem/ng-clinical-charting').then(
                (mod) => mod.PagesRoutingModule
              ),
          },
          {
            path: 'snippets',
            component: AccountSnippetsListComponent,
            canActivate: sharedSettingsGuards,
            data: {
              permissions: [
                BrandPermissions.BrandConfigure,
                BrandPermissions.BrandConfigureGeneral,
              ],
            },
          },
          {
            path: 'account',
            resolve: {
              staffer: StafferAccountResolverService,
            },
            loadChildren: () =>
              import('./brands/account/account-settings-routing.module').then(
                (mod) => mod.AccountSettingsRoutingModule
              ),
          },
          {
            path: 'medical-history',
            component: MedicalHistoryFormPreviewComponent,
            canActivate: sharedSettingsGuards,
            data: {
              permissions: [
                BrandPermissions.BrandConfigure,
                BrandPermissions.BrandConfigureGeneral,
              ],
            },
          },
        ],
      },
      {
        path: 'practice/:uid',
        resolve: {
          practice: PracticeResolverService,
        },
        children: [
          {
            path: 'general',
            component: GeneralPracticeComponent,
          },
          {
            path: 'opening-hours',
            component: PracticeOpeningHoursComponent,
          },
          {
            path: 'tags',
            component: PracticeTagManagementComponent,
          },
          {
            path: 'integrations',
            component: PracticeIntegrationsComponent,
          },
          {
            path: 'fee-schedules',
            data: {
              feeScheduleType: FeeScheduleType.Practice,
            },
            resolve: {
              feeScheduleCollection: PracticeFeeScheduleResolver,
            },
            loadChildren: () =>
              import('./fee-schedule/fee-schedule-routing.module').then(
                (mod) => mod.FeeScheduleRoutingModule
              ),
          },
          {
            path: 'patient-portal',
            component: PatientPortalComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), SettingsPagesModule],
  exports: [RouterModule],
})
export class SettingsPagesRoutingModule {}
