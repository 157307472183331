import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CurrentPatientScope,
  GlobalStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogInput,
  SelectionListStore,
  TrackByFunctions,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import {
  ChartedTreatment,
  FeeScheduleManager,
  getServiceCodeItems,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  type AnyChartedItemConfiguration,
  type ChartableSurface,
  ChartedItemType,
  type IChartedItem,
  type IChartedItemSelectionSummary,
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  type IChartedRef,
  type IChartedServiceSmartGroup,
  type IChartedTreatment,
  type IChartedTreatmentGroup,
  type IFeeSchedule,
  type IPatient,
  type IPricedServiceCodeEntry,
  type IStaffer,
  type ITreatmentCategory,
  type ITreatmentConfiguration,
  type ITreatmentPackage,
  type ITreatmentPlan,
  type ITreatmentStep,
  isChartedTreatmentGroup,
  IPricedServiceCodeGroup,
} from '@principle-theorem/principle-core/interfaces';
import {
  type WithRef,
  asyncForEach,
  filterUndefined,
  getDoc,
  multiMap,
  reduceToSingleArray,
  shareReplayCold,
  snapshot,
} from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { sortBy, uniq, uniqBy } from 'lodash';
import { BehaviorSubject, type Observable, ReplaySubject, of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { type IEditChartableData } from '../../chartable-surface-updater';
import {
  ChangeBasePriceDialogComponent,
  type IChangeBasePriceDialogData,
} from '../change-base-price-dialog/change-base-price-dialog.component';

@Component({
  selector: 'pr-treatment-scope',
  templateUrl: './treatment-scope.component.html',
  styleUrls: ['./treatment-scope.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentScopeComponent {
  trackByTreatments = TrackByFunctions.uniqueId<IChartedTreatment>();
  treatment$ = new ReplaySubject<IChartedTreatment | IChartedTreatmentGroup>(1);
  step$ = new BehaviorSubject<
    WithRef<ITreatmentStep> | IChartedMultiStepTreatmentStep | undefined
  >(undefined);
  plan$ = new BehaviorSubject<
    WithRef<ITreatmentPlan> | IChartedMultiStepTreatment | undefined
  >(undefined);
  config$: Observable<WithRef<ITreatmentConfiguration>>;
  @Input() expanded = false;
  @Input() disabled = false;
  @Input() compact = false;
  @Output() treatmentChanged = new EventEmitter<IChartedTreatment>();
  @Output() treatmentDeleted = new EventEmitter<IChartedTreatment>();
  @Output() updateChartable = new EventEmitter<IEditChartableData>();
  @Output() categoryChanged = new EventEmitter<WithRef<ITreatmentCategory>>();
  selectedSurfaces$ = new ReplaySubject<Partial<IChartedRef>[]>(1);
  feeSchedule$: Observable<WithRef<IFeeSchedule>>;
  serviceCodeSummary$: Observable<string>;
  clearTooltip$: Observable<string>;
  treatmentCategory$: Observable<
    DocumentReference<ITreatmentCategory> | undefined
  >;
  availableSurfaces$: Observable<ChartableSurface[]>;
  treatmentPackages$: Observable<ITreatmentPackage[]>;
  patient$: Observable<WithRef<IPatient>>;

  @Input()
  set treatment(treatment: IChartedTreatment | IChartedTreatmentGroup) {
    if (treatment) {
      this.treatment$.next(treatment);
    }
  }

  @Input()
  set selectedSurfaces(selectedSurfaces: Partial<IChartedRef>[]) {
    if (selectedSurfaces) {
      this.selectedSurfaces$.next(selectedSurfaces);
    }
  }

  @Input()
  set step(step: WithRef<ITreatmentStep> | IChartedMultiStepTreatmentStep) {
    if (step) {
      this.step$.next(step);
    }
  }

  @Input()
  set plan(plan: WithRef<ITreatmentPlan> | IChartedMultiStepTreatment) {
    if (plan) {
      this.plan$.next(plan);
    }
  }

  constructor(
    private _dialog: MatDialog,
    private _globalStore: GlobalStoreService,
    private _patientScope: CurrentPatientScope,
    public organisation: OrganisationService,
    @Optional()
    public selectionList?: SelectionListStore<
      IChartedItemSelectionSummary<IChartedItem>
    >
  ) {
    this.patient$ = this._patientScope.doc$.pipe(filterUndefined());

    this.feeSchedule$ = this.treatment$.pipe(
      switchMap((treatment) =>
        this._globalStore.getFeeSchedule$(treatment.feeSchedule.ref)
      )
    );

    this.serviceCodeSummary$ = this.treatment$.pipe(
      withLatestFrom(this.organisation.taxRate$.pipe(filterUndefined())),
      map(([treatment, taxRate]) => {
        if (isChartedTreatmentGroup(treatment)) {
          return uniqBy(
            reduceToSingleArray(treatment.treatments.map(getServiceCodeItems)),
            (code) => code.code
          );
        }
        return getServiceCodeItems(treatment, taxRate);
      }),
      multiMap((serviceCode) => serviceCode.code),
      map((serviceCodes) => sortBy(serviceCodes).join(', '))
    );

    this.config$ = this.treatment$.pipe(
      switchMap((treatment) => getDoc(treatment.config.ref)),
      shareReplayCold()
    );

    this.treatmentPackages$ = this.config$.pipe(
      map((config) => config.packages)
    );

    this.availableSurfaces$ = this.config$.pipe(
      map((config) => config.availableSurfaces)
    );

    this.treatmentCategory$ = this.config$.pipe(
      map(({ category }) => category)
    );

    this.clearTooltip$ = this.treatment$.pipe(
      map((treatment) =>
        treatment.attributedTo
          ? `Clear ${treatment.attributedTo.name} as Treatment Provider`
          : ''
      )
    );
  }

  async deleteTreatment(
    treatment: IChartedTreatment | IChartedTreatmentGroup
  ): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Treatment',
      prompt: `Are you sure you want to delete this treatment?`,
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }

    if (isChartedTreatmentGroup(treatment)) {
      treatment.treatments.map((chartedTreatment) =>
        this.treatmentDeleted.emit(chartedTreatment)
      );
      return;
    }
    this.treatmentDeleted.emit(treatment);
  }

  treatmentChange(treatment: IChartedTreatment): void {
    this.treatmentChanged.emit(treatment);
  }

  updateSmartGroups(
    treatment: IChartedTreatment,
    serviceCodeSmartGroups: IChartedServiceSmartGroup[]
  ): void {
    this.treatmentChange({
      ...treatment,
      serviceCodeSmartGroups,
    });
  }

  updateExclusiveGroups(
    serviceCodeGroups: IPricedServiceCodeGroup[],
    treatment: IChartedTreatment
  ): void {
    this.treatmentChange({
      ...treatment,
      serviceCodeGroups,
    });
  }

  updateServiceCodes(
    treatment: IChartedTreatment,
    serviceCodes: IPricedServiceCodeEntry[]
  ): void {
    this.treatmentChange({
      ...treatment,
      serviceCodes,
    });
  }

  async updateBasePrice(basePrice: number): Promise<void> {
    const treatment = await snapshot(this.treatment$);
    if (isChartedTreatmentGroup(treatment)) {
      return;
    }
    treatment.basePrice = basePrice;
    this.treatmentChange(treatment);
  }

  async addBasePrice(
    treatment: IChartedTreatment | IChartedTreatmentGroup
  ): Promise<void> {
    if (isChartedTreatmentGroup(treatment)) {
      return;
    }
    const data: IChangeBasePriceDialogData = { basePrice: treatment.basePrice };

    const response = await this._dialog
      .open<
        ChangeBasePriceDialogComponent,
        IChangeBasePriceDialogData,
        IChangeBasePriceDialogData
      >(ChangeBasePriceDialogComponent, DialogPresets.small({ data }))
      .afterClosed()
      .toPromise();

    if (!response) {
      return;
    }

    this.treatmentChange({
      ...treatment,
      basePrice: response.basePrice,
    });
  }

  deleteBasePrice(treatment: IChartedTreatment): void {
    this.treatmentChange({
      ...treatment,
      basePrice: 0,
    });
  }

  async setFeeSchedule(feeSchedule: WithRef<IFeeSchedule>): Promise<void> {
    const treatment = await snapshot(this.treatment$);
    const step = await snapshot(this.step$);
    const manager = this._getFeeScheduleManager(feeSchedule);

    if (isChartedTreatmentGroup(treatment)) {
      await asyncForEach(treatment.treatments, async (chartedTreatment) =>
        this.treatmentChange(
          await ChartedTreatment.applyFeeSchedule(
            chartedTreatment,
            step?.treatments ?? [],
            manager,
            true,
            true
          )
        )
      );
      return;
    }

    this.treatmentChange(
      await ChartedTreatment.applyFeeSchedule(
        treatment,
        step?.treatments ?? [],
        manager,
        true,
        true
      )
    );
  }

  async setAttributedTo(staffer: WithRef<IStaffer> | undefined): Promise<void> {
    const treatment = await snapshot(this.treatment$);
    const attributedTo = staffer ? stafferToNamedDoc(staffer) : undefined;

    if (isChartedTreatmentGroup(treatment)) {
      treatment.treatments.map((chartedTreatment) =>
        this.treatmentChange({
          ...chartedTreatment,
          attributedTo,
        })
      );
      return;
    }

    this.treatmentChange({
      ...treatment,
      attributedTo,
    });
  }

  isTreatmentGroup(
    treatment: IChartedTreatment | IChartedTreatmentGroup
  ): boolean {
    return isChartedTreatmentGroup(treatment);
  }

  isSelected$(
    item: IChartedItem<AnyChartedItemConfiguration>
  ): Observable<boolean> {
    return this.step$.pipe(
      switchMap(
        (step) =>
          this.selectionList?.isSelected$({
            item,
            step,
          }) ?? of(false)
      )
    );
  }

  isCheckboxDisabled$(
    item: IChartedTreatment | IChartedTreatmentGroup
  ): Observable<boolean> {
    return (this.selectionList?.selected$ || of([])).pipe(
      map((selected) => {
        if (!selected.length) {
          return false;
        }

        const itemTypes = uniq(
          selected.map((selectedItem) => selectedItem.item.type)
        );

        if (
          item.type === ChartedItemType.ChartedTreatmentGroup &&
          itemTypes.includes(ChartedItemType.ChartedTreatment)
        ) {
          return false;
        }

        return !itemTypes.includes(item.type);
      })
    );
  }

  async setSelected(
    item: IChartedTreatment | IChartedTreatmentGroup,
    isSelected: boolean
  ): Promise<void> {
    const step = await snapshot(this.step$);
    const plan = await snapshot(this.plan$);
    if (isChartedTreatmentGroup(item)) {
      item.treatments.map(
        (treatment) =>
          this.selectionList?.setSelected(
            {
              item: treatment,
              step,
              plan,
            },
            isSelected
          )
      );
      return;
    }
    this.selectionList?.setSelected(
      {
        item,
        step,
        plan,
      },
      isSelected
    );
  }

  setTreatmentPackage(
    selectedPackageUid: string,
    packages: ITreatmentPackage[],
    treatment: IChartedTreatment
  ): void {
    const selectedPackage = packages.find(
      (treatmentPackage) => treatmentPackage.uid === selectedPackageUid
    );

    if (!selectedPackage) {
      return;
    }

    this.treatmentChange(
      ChartedTreatment.applyPackagePricing(treatment, selectedPackage)
    );
  }

  getStepTreatments$(
    step$: Observable<
      WithRef<ITreatmentStep> | IChartedMultiStepTreatmentStep | undefined
    >
  ): Observable<IChartedTreatment[]> {
    return step$.pipe(map((step) => step?.treatments ?? []));
  }

  private _getFeeScheduleManager(
    feeSchedule: WithRef<IFeeSchedule>
  ): FeeScheduleManager {
    return new FeeScheduleManager(of(feeSchedule));
  }
}
