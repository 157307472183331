import {
  coerceBooleanProperty,
  coerceNumberProperty,
} from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Optional,
  Output,
  ViewChild,
  signal,
  type OnDestroy,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  type DraggableOptions,
  type Element,
  type InteractEvent,
  type Interactable,
  type ResizableOptions,
  type ResizeEvent,
} from '@interactjs/types';
import { GapStoreService } from '@principle-theorem/ng-principle-shared';
import { BreakpointService } from '@principle-theorem/ng-shared';
import { isSameEvent } from '@principle-theorem/principle-core';
import {
  EventType,
  IScheduleSummaryEventable,
  ITimelineNode,
  type ITimelineDisplayOptions,
} from '@principle-theorem/principle-core/interfaces';
import {
  durationToHumanisedTime,
  snapshot,
  type ITimePeriod,
} from '@principle-theorem/shared';
import interact from 'interactjs';
import { isUndefined, pick } from 'lodash';
import type * as moment from 'moment-timezone';
import { duration } from 'moment-timezone';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  from,
  type Observable,
} from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { InteractiveTimelineDisplayCalculator } from '../interactive-timeline-display-calculator';
import { type ITimelineNodeSummary } from '../interactive-timeline-drag-tooltip/interactive-timeline-drag-tooltip.component';
import { InteractiveTimelineDragareaComponent } from '../interactive-timeline-dragarea/interactive-timeline-dragarea.component';
import {
  getDragEdges,
  getDragOptions,
  getMinSizeModifier,
  getResizeRestrictModifier,
  getSnapGridModifier,
  type IDragStartPosition,
} from './interactable-options';
import { InteractableRect, type IInteractableRect } from './interactable-rect';

export interface ITimelineNodeEvent<T> {
  event: T;
  node: InteractiveTimelineNodeComponent;
}

@Component({
  selector: 'pr-interactive-timeline-node',
  templateUrl: './interactive-timeline-node.component.html',
  styleUrls: ['./interactive-timeline-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InteractiveTimelineNodeComponent
  implements OnDestroy, AfterContentInit
{
  private _onDestroy$ = new Subject<void>();
  private _startHandle$ = new ReplaySubject<ElementRef<HTMLElement>>(1);
  private _endHandle$ = new ReplaySubject<ElementRef<HTMLElement>>(1);
  private _options$ = new ReplaySubject<ITimelineDisplayOptions>(1);
  private _node$ = new ReplaySubject<ITimelineNode<unknown>>(1);
  private _dragArea$ = new BehaviorSubject<string | Element>('parent');
  private _trackIndex$ = new BehaviorSubject<number>(0);
  private _element: HTMLElement;
  private _interactable?: Interactable;
  private _originalStyle?: Partial<CSSStyleDeclaration>;
  private _rect$ = new BehaviorSubject<IInteractableRect>(
    InteractableRect.init()
  );
  private _cancelAction$ = new Subject<void>();
  private _dragContainer?: HTMLElement;
  private _timeRange$ = new ReplaySubject<ITimePeriod>(1);
  private _loaded$ = new ReplaySubject<void>(1);

  day$ = new ReplaySubject<ITimePeriod>(1);
  moving$ = new BehaviorSubject<boolean>(false);
  summary$: Observable<ITimelineNodeSummary>;
  dragStartPosition$ = new BehaviorSubject<IDragStartPosition | undefined>(
    undefined
  );
  isSelected = signal(false);
  isHighlighted$ = new ReplaySubject<boolean>(1);
  shouldShowHighlighted$: Observable<boolean>;

  @Output() dragEnd = new EventEmitter<ITimelineNodeEvent<InteractEvent>>();
  @Output() resizeEnd = new EventEmitter<ITimelineNodeEvent<ResizeEvent>>();
  @Output() tapEnd = new EventEmitter<ITimelineNodeEvent<void>>();
  @Output() moving = new EventEmitter<boolean>();
  @HostBinding('class.draggable') isDraggable: boolean;
  @HostBinding('class.resizable') isResizable: boolean;
  @HostBinding('class.active') isActive: boolean;
  @HostBinding('class.horizontal') isHorizontal = true;
  @HostBinding('class.vertical') isVertical = false;
  @HostBinding('style.zIndex') zIndex: number;

  @ViewChild('startHandle')
  set startHandle(startHandle: ElementRef<HTMLElement>) {
    if (startHandle) {
      this._startHandle$.next(startHandle);
    }
  }

  @ViewChild('endHandle')
  set endHandle(endHandle: ElementRef<HTMLElement>) {
    if (endHandle) {
      this._endHandle$.next(endHandle);
    }
  }

  @Input()
  set options(options: ITimelineDisplayOptions) {
    if (options) {
      this._options$.next(options);
    }
  }

  @Input()
  set day(day: ITimePeriod) {
    if (day) {
      this.day$.next(day);
    }
  }

  @Input()
  set timeRange(timeRange: ITimePeriod) {
    if (timeRange) {
      this._timeRange$.next(timeRange);
    }
  }

  @Input()
  set dragArea(
    dragArea: string | Element | InteractiveTimelineDragareaComponent
  ) {
    if (dragArea instanceof InteractiveTimelineDragareaComponent) {
      this._dragArea$.next(dragArea.elementRef.nativeElement);
      return;
    }
    this._dragArea$.next(dragArea || 'parent');
  }

  @Input()
  set node(node: ITimelineNode<unknown>) {
    if (node) {
      this._node$.next(node);
    }
  }

  @Input()
  set trackIndex(trackIndex: number) {
    this._trackIndex$.next(coerceNumberProperty(trackIndex));
  }

  @Input({
    transform: coerceBooleanProperty,
  })
  set isHighlighted(isHighlighted: boolean) {
    this.isHighlighted$.next(isHighlighted);
  }

  constructor(
    elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(DOCUMENT) private _document: Document,
    private _breakpoint: BreakpointService,
    private _gapStore: GapStoreService
  ) {
    this._element = elementRef.nativeElement;
    interact.dynamicDrop(true);

    const cancelled$ = this._cancelAction$.pipe(
      switchMap(() => from([true, false])),
      startWith(false)
    );

    this.moving$.pipe(takeUntil(this._onDestroy$)).subscribe((isActive) => {
      this.isActive = isActive;
      this.moving.emit(isActive);
    });

    const draggableOptions$ = this._getDraggableOptions$();
    draggableOptions$
      .pipe(
        map((options) => !isUndefined(options)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((isDraggable) => (this.isDraggable = isDraggable));

    const resizableOptions$ = this._getResizableOptions$();
    resizableOptions$
      .pipe(
        map((options) => !isUndefined(options)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((isResizable) => (this.isResizable = isResizable));

    combineLatest([draggableOptions$, resizableOptions$, cancelled$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([draggable, resizable, cancelAction]) => {
        if (cancelAction) {
          this.moving$.next(false);
        }
        if (this._interactable) {
          this._interactable.unset();
        }
        this._interactable = interact(this._element);
        if (this._interactable) {
          this._interactable.on('tap', (event: void) =>
            this.tapEnd.emit({ event, node: this })
          );
        }
        if (this._interactable && draggable) {
          this._interactable.draggable(draggable);
        }
        if (this._interactable && resizable) {
          this._interactable.resizable(resizable);
        }
        this._render(cancelAction);
      });

    combineLatest([
      this._options$,
      this._node$,
      this.day$,
      this._trackIndex$,
      cancelled$,
      this._timeRange$,
    ])
      .pipe(
        map(([options, node, day, trackIndex, _cancelAction, timeRange]) =>
          this._getInitialRect(options, node, day, trackIndex, timeRange)
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((rect) => {
        this._rect$.next(rect);
        this._render();
      });

    this.summary$ = this.getChangeSummary$();

    this._options$
      .pipe(
        map(InteractiveTimelineDisplayCalculator.isHorizontal),
        distinctUntilChanged(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((isHorizontal) => {
        this.isHorizontal = isHorizontal;
        this.isVertical = !isHorizontal;
      });

    this._dragContainer =
      (this._document
        .getElementsByClassName('drag-scroll-container')
        .item(0) as HTMLElement) ?? undefined;

    combineLatest([this._gapStore.selectedGap$, this._node$])
      .pipe(
        map(
          ([selectedGap, node]) =>
            !!selectedGap &&
            isSameEvent(
              selectedGap.event,
              (node.data as IScheduleSummaryEventable).event
            )
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((isSelected) => this.setSelected(isSelected, true));

    this._loaded$
      .pipe(
        switchMap(() => this.isHighlighted$),
        filter((isHighlighted) => isHighlighted),
        delay(400),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this._scrollToElement());

    this.shouldShowHighlighted$ = combineLatest([
      toObservable(this.isSelected),
      this.isHighlighted$,
      this._node$,
    ]).pipe(
      map(([isSelected, isHighlighted, node]) => {
        const isGap =
          (node.data as IScheduleSummaryEventable).event.type === EventType.Gap;
        return isHighlighted || (isSelected && isGap);
      })
    );
  }

  ngAfterContentInit(): void {
    this._loaded$.next();
  }

  ngOnDestroy(): void {
    this._interactable?.unset();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @HostListener('document:keydown.escape')
  cancelDrag(): void {
    this._cancelAction$.next();
  }

  async getNodeTime(relativeTo: moment.Moment): Promise<ITimePeriod> {
    return InteractiveTimelineDisplayCalculator.getTimeFromRect(
      await snapshot(this._options$),
      await snapshot(this._timeRange$),
      this._rect$.value,
      relativeTo
    );
  }

  getChangeSummary$(): Observable<ITimelineNodeSummary> {
    return combineLatest([
      this._options$,
      this.moving$,
      this._rect$,
      this.day$,
      this._timeRange$,
    ]).pipe(
      filter(([_options, moving, _rect, _day, _timeRange]) => moving),
      map(([options, _moving, rect, day, timeRange]) => {
        const change = InteractiveTimelineDisplayCalculator.getTimeFromRect(
          options,
          timeRange,
          rect,
          day.from
        );
        return {
          from: change.from,
          to: change.to,
          duration: durationToHumanisedTime(
            duration(change.to.diff(change.from))
          ),
        };
      })
    );
  }

  setSelected(selected: boolean, scrollIntoView: boolean = false): void {
    this.isSelected.set(selected);
    this.zIndex = selected ? 300 : 200;

    if (selected && scrollIntoView) {
      this._scrollToElement();
    }
  }

  resetPosition(): void {
    Object.assign(this._element.style, this._originalStyle);
    this._cancelAction$.next();
  }

  private _scrollToElement(): void {
    this._element.scrollIntoView({
      behavior: 'instant',
    });
  }

  private _getDraggableOptions$(): Observable<DraggableOptions | undefined> {
    return combineLatest([
      this._options$,
      this._dragArea$,
      this._node$,
      this._breakpoint.isDesktop$,
    ]).pipe(
      map(([options, dragArea, node, isDesktop]) => {
        if (!node.dragEnabled) {
          return undefined;
        }
        return {
          autoScroll: {
            container: this._dragContainer,
            enabled: true,
          },
          hold: isDesktop ? 0 : 1000,
          ...getDragOptions(options, dragArea, this.dragStartPosition$, {
            start: () => this._dragStart(dragArea),
            move: (event: InteractEvent) => this._drag({ event, node: this }),
            end: (event: InteractEvent) => {
              this.moving$.next(false);
              this.dragEnd.emit({ event, node: this });
            },
          }),
        };
      })
    );
  }

  private _getResizableOptions$(): Observable<ResizableOptions | undefined> {
    return combineLatest([
      this._options$,
      this._dragArea$,
      this._node$,
      this._startHandle$,
      this._endHandle$,
    ]).pipe(
      map(([options, dragArea, node, startHandle, endHandle]) => {
        if (!node.resizeEnabled) {
          return undefined;
        }
        return {
          origin: dragArea,
          edges: getDragEdges(
            options,
            startHandle.nativeElement,
            endHandle.nativeElement
          ),
          modifiers: [
            getSnapGridModifier(options, dragArea, this.dragStartPosition$),
            getResizeRestrictModifier(dragArea),
            getMinSizeModifier(options),
          ],
          listeners: {
            start: () => this._dragStart(dragArea),
            move: (event: ResizeEvent) => this._resize(options, event),
            end: (event: ResizeEvent) => {
              this.moving$.next(false);
              this.resizeEnd.emit({ event, node: this });
            },
          },
        };
      })
    );
  }

  private _dragStart(dragArea: string | Element): void {
    if (dragArea instanceof HTMLElement) {
      this.dragStartPosition$.next({
        left: dragArea.offsetParent?.scrollLeft ?? 0,
        top: dragArea.offsetParent?.scrollTop ?? 0,
      });
    }
    this._start();
  }

  private _start(): void {
    this.moving$.next(true);
    if (this._originalStyle) {
      return;
    }
    this._originalStyle = pick(this._element.style, [
      'transform',
      'width',
      'height',
    ]);
  }

  private _drag(event: ITimelineNodeEvent<InteractEvent>): void {
    const rect = this._rect$.value;
    this._rect$.next({
      ...rect,
      x: rect.x + event.event.dx,
      y: rect.y + event.event.dy,
    });
    this._render();
  }

  private _getInitialRect(
    options: ITimelineDisplayOptions,
    node: ITimelineNode<unknown>,
    day: ITimePeriod,
    trackIndex: number,
    timeRange: ITimePeriod
  ): IInteractableRect {
    const position = InteractiveTimelineDisplayCalculator.timeToPosition(
      options,
      timeRange,
      node.from,
      day.from
    );
    const durationSize = InteractiveTimelineDisplayCalculator.getNodeWidth(
      options,
      node
    );
    const crossAxisPosition = InteractiveTimelineDisplayCalculator.getNodeY(
      options,
      trackIndex
    );
    const trackSize =
      InteractiveTimelineDisplayCalculator.getNodeHeight(options);
    return InteractiveTimelineDisplayCalculator.createOrientationAwareRect(
      options,
      position,
      durationSize,
      trackSize,
      crossAxisPosition
    );
  }

  private _resize(_options: ITimelineDisplayOptions, event: ResizeEvent): void {
    const rect = this._rect$.value;
    this._rect$.next({
      ...rect,
      x: rect.x + (event.deltaRect?.left ?? 0),
      y: rect.y + (event.deltaRect?.top ?? 0),
      width: event.rect.width,
      height: event.rect.height,
    });
    this._render();
  }

  private _render(cancelAction: boolean = false): void {
    if (cancelAction) {
      Object.assign(this._element.style, this._originalStyle);
      return;
    }
    InteractableRect.render(this._rect$.value, this._element);
  }
}
