import {
  TextFormatting,
  type IMenuButton,
  BlockNodes,
} from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../menu-bar/basic-menu-button/basic-menu-button.component';

export const UNDERLINE_KEYMAP = 'Mod-u';

export function createUnderlineMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'basic',
      buttonText: 'Underline',
      tooltip: 'Underline',
      shortcut: UNDERLINE_KEYMAP,
      icon: 'format_underlined',
      command: (editor) => editor.chain().focus().toggleUnderline().run(),
      isActiveFn: (editor) => editor.isActive(TextFormatting.Underline),
      canActivateFn: (editor) => editor.isActive(BlockNodes.Paragraph),
    },
  });
}
