<div
  fxFill
  [fxLayout]="isHorizontal ? 'row' : 'column'"
  fxLayoutAlign="start center"
  [fxLayoutGap]="isHorizontal ? '16px' : '2px'"
  *ngIf="group$ | ngrxPush as staffer; else loading"
  class="user-preview"
>
  <div class="staffer-container">
    <pt-user-icon
      [src]="url$ | async"
      [name]="name$ | async"
      [diameter]="35"
     />

    <div
      *prHasPermission="rosterManagePermission"
      class="menu-button"
      matTooltip="{{ staffer.user.name }}'s Roster Settings"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <button mat-icon-button [matMenuTriggerFor]="moreMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="isHorizontal">
    {{ name$ | async }}
  </div>

  <div
    *ngIf="isVertical"
    [ngStyle]="{ 'width.px': (trackSizeInPixels$ | async) }"
    class="mat-caption vertical-name"
  >
    {{ name$ | async }}
  </div>

  <mat-menu #moreMenu="matMenu">
    <ng-template matMenuContent>
      <button mat-menu-item (click)="editRoster(staffer)">
        <mat-icon>today</mat-icon>
        <span>Edit Today's Roster</span>
      </button>
      <a
        mat-menu-item
        [prBrandRouterLink]="['staff', staffer.ref.id, 'profile', 'rostering']"
      >
        <mat-icon>calendar_month</mat-icon>
        <span>Edit Main Roster</span>
      </a>
    </ng-template>
  </mat-menu>
</div>

<ng-template #loading>
  <mat-spinner [diameter]="35" />
</ng-template>
