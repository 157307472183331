import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReportBuilderStore } from '../../../report-builder/report-builder.store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BasicDialogService } from '@principle-theorem/ng-shared';

@Component({
    selector: 'pr-custom-report-save-bar',
    templateUrl: './custom-report-save-bar.component.html',
    styleUrl: './custom-report-save-bar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CustomReportSaveBarComponent {
  disabled$: Observable<boolean>;
  loaded$: Observable<boolean>;

  constructor(
    private _store: ReportBuilderStore,
    private _snackBar: MatSnackBar,
    private _dialog: BasicDialogService
  ) {
    this.disabled$ = this._store.hasEdits$.pipe(map((hasEdits) => !hasEdits));
    this.loaded$ = this._store.isLoading$.pipe(map((isLoading) => !isLoading));
  }

  async saveChanges(): Promise<void> {
    await this._store.saveReportChanges();
    this._snackBar.open('Custom Report saved');
  }

  async discardChanges(): Promise<void> {
    await this._store.discardReportChanges();
    this._snackBar.open('Custom Report changes discarded');
  }

  async cancel(): Promise<void> {
    const confirmed = await this._dialog.confirm({
      title: 'Unsaved Changes',
      prompt:
        'Exiting Edit mode will discard all unsaved changes. Are you sure?',
    });
    if (!confirmed) {
      return;
    }

    await this.discardChanges();
    this._store.patchState((state) => {
      if (!state.query) {
        return state;
      }

      return {
        query: {
          ...state.query,
          editMode: false,
        },
      };
    });
  }
}
