import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ManageMedipassIntegrationComponent } from './manage-medipass-integration/manage-medipass-integration.component';
import { MedipassIntegrationFormComponent } from './medipass-integration-form/medipass-integration-form.component';
import { MedipassService } from './medipass.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgMaterialModule,
    NgSharedModule,
  ],
  declarations: [
    ManageMedipassIntegrationComponent,
    MedipassIntegrationFormComponent,
  ],
  exports: [ManageMedipassIntegrationComponent],
  providers: [MedipassService],
})
export class NgMedipassModule {}
