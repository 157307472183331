export enum ChartMouseEvent {
  Click = 'click',
  DragStart = 'drag-start',
  DragUpdate = 'drag-update',
  DragEnd = 'drag-end',
}

export enum ChartKeybind {
  Right = 'right',
  Left = 'left',
  Up = 'up',
  Down = 'down',
}

export class ChartKeybindInteraction {
  constructor(
    public keybind: ChartKeybind,
    public multi: boolean = false,
    public parent: SVGSVGElement
  ) {}
}

export function isMultiEvent(event: MouseEvent | KeyboardEvent): boolean {
  return event.altKey || event.metaKey || event.shiftKey;
}
