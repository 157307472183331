import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import {
  PatientConfirmAppointmentComponent,
  PatientFormsPageComponent,
} from '@principle-theorem/ng-patient-portal';
import { PatientMedicalHistoryComponent } from './components/patient-medical-history/patient-medical-history.component';
import { PatientViewInvoiceComponent } from './components/patient-view-invoice/patient-view-invoice.component';
import { PatientGapOfferComponent } from './components/patient-gap-offer/patient-gap-offer.component';
import { NgPatientModule } from './ng-patient.module';

const routes: Routes = [
  {
    path: 'confirm-appointment',
    data: { title: 'Confirm Appointment' },
    component: PatientConfirmAppointmentComponent,
  },
  {
    path: 'confirm-appointment/:token',
    data: { title: 'Confirm Appointment' },
    component: PatientConfirmAppointmentComponent,
  },
  {
    path: 'medical-history',
    data: { title: 'Medical History' },
    component: PatientMedicalHistoryComponent,
  },
  {
    path: 'medical-history/:token',
    data: { title: 'Medical History' },
    component: PatientMedicalHistoryComponent,
  },
  {
    path: 'view-invoice',
    data: { title: 'View Invoice' },
    component: PatientViewInvoiceComponent,
  },
  {
    path: 'appointment-reschedule',
    data: { title: 'Appointment Reschedule' },
    component: PatientGapOfferComponent,
  },
  {
    path: 'appointment-reschedule/:token',
    data: { title: 'Appointment Reschedule' },
    component: PatientGapOfferComponent,
  },
  {
    path: 'view-invoice/:token',
    data: { title: 'View Invoice' },
    component: PatientViewInvoiceComponent,
  },
  {
    path: 'forms',
    data: { title: 'My Forms' },
    component: PatientFormsPageComponent,
  },
  {
    path: 'forms/:token',
    data: { title: 'My Forms' },
    component: PatientFormsPageComponent,
  },
];

@NgModule({
  imports: [NgPatientModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NgPatientActionsRoutingModule {}
