import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BAR_CHART_OPTIONS } from '../../../models/report/charts/bar-chart';
import { type ChartBuilder } from '../../../models/report/charts/chart-builder';
import { type IChartCard } from '../../../models/report/charts/chart-card';
import { ChartType, FactTables } from '@principle-theorem/reporting';
import {
  type ChartComponentLoader,
  resolveDynamicChartDefinition,
} from '../../core/chart-builders/dynamic-chart-resolver';
import { MeasureDataChartComponent } from '../../core/measure-data-chart-component';
import { type ITableHeaderReplacement } from '../../reporting-components/table-chart/table-chart.component';
import {
  CustomChartType,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-practice-patients',
  templateUrl: './practice-patients.component.html',
  styleUrls: ['./practice-patients.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PracticePatientsComponent extends MeasureDataChartComponent {
  replacementHeaders: ITableHeaderReplacement[] = [
    {
      from: 'Owner',
      to: 'Practice',
    },
  ];
  tableData: IChartCard;
  newAndExistingChartBuilder: ChartBuilder;
  patientNumbersChart: ChartBuilder;
  patientValueChart: ChartBuilder;
  patientNumbers: ChartComponentLoader;

  _redraw(): void {
    this.newAndExistingChartBuilder = this.dataBuilder
      .toBarChart(
        this._chartMeasures(
          CustomChartType.Bar,
          [
            FactTables.appointmentEvent.existingPatient.reduceByCount(),
            FactTables.appointmentEvent.newPatient.reduceByCount(),
          ],
          FactTables.appointmentEvent.practice.name
        )
      )
      .chartBuilder.addChartOptions(BAR_CHART_OPTIONS)
      .addChartOptions({
        bars: 'horizontal',
        isStacked: true,
        vAxis: { title: '' },
      });

    this.patientNumbers = resolveDynamicChartDefinition(
      this.dataBuilder,
      this._chartMeasures(
        CustomChartType.Row,
        [
          FactTables.appointmentEvent.newPatient.reduceByCount(),
          FactTables.appointmentEvent.existingPatient.reduceByCount(),
        ],
        FactTables.appointmentEvent.practice.name
      ),
      ChartType.Row
    );

    this.patientNumbersChart = this.dataBuilder
      .toPieChart(
        this._chartMeasures(
          CustomChartType.Pie,
          [
            FactTables.appointmentEvent.newPatient.reduceByCount(),
            FactTables.appointmentEvent.existingPatient.reduceByCount(),
          ],
          FactTables.appointmentEvent.practice.name
        )
      )
      .chartBuilder.addChartOptions({
        legend: {
          position: 'right',
        },
      });

    this.tableData = this._tableChartCard(
      [
        FactTables.appointmentEvent.appointment.count.setLabel('Patients Seen'),
        FactTables.appointmentEvent.newPatient.reduceByCount(),
        FactTables.appointmentEvent.existingPatient.reduceByCount(),
        FactTables.appointmentEvent.treatmentCost
          .reduceByAverage()
          .setLabel('Average Patient Spend'),
        FactTables.appointmentEvent.waitTime
          .reduceByAverage()
          .setLabel('Average Wait Time (mins)'),
      ],
      FactTables.appointmentEvent.practice.name
    );

    this.patientValueChart = this._dualAxisBarChart(
      [
        // 'caseAcceptanceRate',
        FactTables.treatmentPlanEvent.accepted
          .reduceByRatio(FactTables.treatmentPlanEvent.offered)
          .setLabel('Case Acceptance Rate')
          .setFormatter(MeasureFormatter.Percentage),
        // 'averagePatientSpend',
        FactTables.appointmentEvent.treatmentCost
          .reduceByRatio(FactTables.appointmentEvent.count)
          .setLabel('Average Patient Spend'),
      ],
      FactTables.appointmentEvent.practice.name
    );
  }
}
