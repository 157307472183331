import { Pipe, type PipeTransform } from '@angular/core';
import { type Timestamp } from '@principle-theorem/shared';
import {
  type Timezone,
  toMoment,
  type ISODateType,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Moment as TimezoneMoment } from 'moment-timezone';
// eslint-disable-next-line no-restricted-imports
import { Moment } from 'moment';

@Pipe({
  name: 'moment',
})
export class MomentPipe implements PipeTransform {
  transform(
    value?: Timestamp | ISODateType | TimezoneMoment,
    timezoneOverride?: Timezone
  ): Moment {
    const momentValue = value ? toMoment(value) : moment();
    return timezoneOverride
      ? (momentValue.tz(timezoneOverride) as Moment)
      : (momentValue as Moment);
  }
}
