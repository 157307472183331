import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FollowUpsComponentsModule } from './components/follow-up-components.module';
import { FollowUpPagesModule } from './pages/follow-up-pages.module';
import { FollowUpsService } from './follow-ups.service';

@NgModule({
  imports: [CommonModule, FollowUpsComponentsModule, FollowUpPagesModule],
  providers: [FollowUpsService],
})
export class NgFollowUpsModule {}
