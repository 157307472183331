import { IMatSelectOption } from '@principle-theorem/ng-shared';

export interface INotificationSound extends IMatSelectOption<string> {
  volume?: number;
}

export const NOTIFICATION_SOUNDS: INotificationSound[] = [
  {
    label: 'Notification (Default)',
    value: 'notification.mp3',
    volume: 0.75,
  },
  {
    label: 'Affirmative',
    value: 'affirmative.wav',
  },
  {
    label: 'Alien',
    value: 'alien.wav',
  },
  {
    label: 'Bike Bell',
    value: 'bike-bell.wav',
    volume: 0.75,
  },
  {
    label: 'Chime',
    value: 'chime.wav',
  },
  {
    label: 'Chime 2',
    value: 'chime-2.wav',
  },
  {
    label: 'Digi',
    value: 'digi.mp3',
    volume: 0.75,
  },
  {
    label: 'Finger Cymbals',
    value: 'finger-cymbals.wav',
  },
  {
    label: 'Laser',
    value: 'laser.wav',
  },
  {
    label: 'Meow',
    value: 'meow.wav',
  },
  {
    label: 'New Item',
    value: 'new-item.mp3',
    volume: 0.75,
  },
  {
    label: 'Old Cell Phone',
    value: 'old-cell-phone.mp3',
    volume: 0.9,
  },
  {
    label: 'Parrot Whistle',
    value: 'parrot-whistle.mp3',
  },
  {
    label: 'Percussion',
    value: 'percussion.wav',
    volume: 0.75,
  },
  {
    label: 'Plane Announcement',
    value: 'plane-announcement.mp3',
    volume: 0.9,
  },
  {
    label: 'Pop',
    value: 'pop.wav',
  },
  {
    label: 'Rasta',
    value: 'rasta.wav',
  },
  {
    label: 'Reception Bell',
    value: 'reception-bell.wav',
    volume: 0.75,
  },
  {
    label: 'Ring',
    value: 'ring.wav',
    volume: 0.75,
  },
  {
    label: 'Sword',
    value: 'sword.wav',
  },
  {
    label: 'Tambourine',
    value: 'tambourine.wav',
  },
  {
    label: 'Darth Vader',
    value: 'darth-vader.mp3',
    volume: 0.9,
  },
  {
    label: 'Lightsaber',
    value: 'lightsaber.wav',
  },
  {
    label: 'R2D2',
    value: 'r2d2.mp3',
  },
  {
    label: 'Yoda',
    value: 'yoda.mp3',
  },
  {
    label: 'Mario Coin',
    value: 'mario-coin.wav',
    volume: 0.9,
  },
  {
    label: 'Mario Jump',
    value: 'mario-jump.wav',
  },
  {
    label: 'Mario Pause',
    value: 'mario-pause.wav',
    volume: 0.9,
  },
  {
    label: 'Mario Stomp',
    value: 'mario-stomp.wav',
  },
];
