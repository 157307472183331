import { Pipe, type PipeTransform } from '@angular/core';
import { formatTimeStringDisplay } from '@principle-theorem/shared';

@Pipe({
  name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: string): string {
    return formatTimeStringDisplay(value);
  }
}
