import {
  IAppointment,
  IAssociatedTreatment,
  IFeeSchedule,
  IPatient,
  IStaffer,
  ITeam,
  IUser,
  TreatmentConfigurationName,
} from '@principle-theorem/principle-core/interfaces';
import {
  INamedDocument,
  MockGenerator,
  WithRef,
  toNamedDocument,
} from '@principle-theorem/shared';
import { MockNamedDocument, MockWithRef } from '@principle-theorem/testing';
import { PatientMock } from '../patient/patient.mock';
import { PractitionerMock } from '../staffer/practitioner.mock';
import {
  MockTreatmentPlan,
  getMockTreatmentStep,
} from '../clinical-charting/treatment/treatment-plan.mock';
import { AppointmentMock } from './appointment.mock';

export interface IAppointmentContext {
  patient: WithRef<IPatient>;
  practitioner: INamedDocument<IStaffer>;
  associatedTreatment: IAssociatedTreatment;
  appointment: WithRef<IAppointment>;
  staffer: WithRef<IStaffer>;
}

export function MockAppointmentContext(
  feeSchedule: INamedDocument<IFeeSchedule>
): IAppointmentContext {
  const patient: WithRef<IPatient> = MockWithRef(
    MockGenerator.generate(PatientMock, {
      name: 'Black Chartruce',
      profileImageURL:
        `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
        `26730794_10156200656618958_6380998990042008023_n.jpg?alt=media&token=8a297c7d-f15a-4c82-9291-6a328675ca49`,
    })
  );

  const practitionerName = 'Practitioner Name';
  const staffer = MockWithRef(
    MockGenerator.generate(PractitionerMock, {
      user: MockNamedDocument<IUser>(practitionerName),
      teams: [MockNamedDocument<ITeam>('Practitioners')],
    })
  );

  const practitioner: INamedDocument<IStaffer> = {
    ref: staffer.ref,
    name: practitionerName,
  };

  const plan = MockWithRef(
    MockTreatmentPlan({ practitioner, name: 'Invisalign' })
  );

  const step = MockWithRef(
    getMockTreatmentStep(
      'Invisalign Stage 1',
      [TreatmentConfigurationName.InvisalignWorkUp],
      [],
      feeSchedule,
      [practitioner]
    )
  );

  const associatedTreatment: IAssociatedTreatment = {
    ...toNamedDocument(plan),
    treatmentStep: {
      ...toNamedDocument(step),
      duration: step.schedulingRules.duration,
      display: step.display,
    },
  };

  const appointment = MockWithRef(
    MockGenerator.generate(AppointmentMock, {
      treatmentPlan: associatedTreatment,
    })
  );
  return { patient, practitioner, staffer, associatedTreatment, appointment };
}
