<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
  <pr-appointment-card-actions
    [actions]="actions"
    [appointment]="appointment$ | ngrxPush"
    [patient]="patient$ | ngrxPush"
  />

  <pr-appointment-menu
    *ngIf="showMenu"
    prExpansionDisable
    [appointment]="appointment$ | ngrxPush"
    [patient]="patient$ | ngrxPush"
  />
</div>
