export interface ICustomMappingsMetadata {
  label: string;
  description: string;
}

export interface ICustomMappings {
  metadata: ICustomMappingsMetadata;
}

export enum CustomMappingType {
  DocumentReference = 'documentReference',
  SelectionList = 'selectionList',
  ExclusionList = 'exclusionList',
  SourceLink = 'sourceLink',
  SingleValue = 'singleValue',
}

export type DocumentReferenceCustomMapping = {
  type: CustomMappingType.DocumentReference;
};

export type ExclusionCustomMapping = {
  type: CustomMappingType.ExclusionList;
};

export type SelectionCustomMapping = {
  type: CustomMappingType.SelectionList;
};

export type SourceLinkCustomMapping = {
  type: CustomMappingType.SourceLink;
};

export type SingleValueCustomMapping = {
  type: CustomMappingType.SingleValue;
  placeholder: string;
  hint: string;
} & (
  | {
      inputType: 'select';
      options: ICustomMappingSourceOption[];
    }
  | {
      inputType: 'text' | 'number';
    }
);

export enum CustomMappingAssociatedValueType {
  NamedDocument = 'Named Document',
  String = 'String',
}

export type CustomMappingOption = {
  value: string;
  description: string;
} & (
  | { hasAssociatedValue: false }
  | {
      hasAssociatedValue: true;
      associatedValueType: CustomMappingAssociatedValueType;
      associatedValueDescription: string;
    }
);

export enum CustomMappingStatus {
  Locked = 'locked',
  Unlocked = 'unlocked',
}

export type ICustomMapping = {
  uid: string;
  metadata: ICustomMappingMetadata;
  status: CustomMappingStatus;
  labelOverrides?: Partial<{
    sourceIdentifier: string;
    sourceLabel: string;
    destinationIdentifier: string;
    destinationValue: string;
    associatedValue: string;
  }>;
} & (
  | DocumentReferenceCustomMapping
  | SelectionCustomMapping
  | ExclusionCustomMapping
  | SourceLinkCustomMapping
  | SingleValueCustomMapping
);

export interface ICustomMappingMetadata {
  label: string;
  description: string;
  type: string;
  allowManualInput?: boolean;
}

export interface ICustomMappingSourceOption {
  label: string;
  value: string;
}
