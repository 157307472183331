import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { type MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { type RawInlineNodes, toTextContent } from '@principle-theorem/editor';
import {
  extendSortingDataAccessor,
  ObservableDataSource,
  timestampSortingAccessor,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  type AutomationStatus,
  type AutomationType,
  AUTOMATION_GRACE_PERIOD,
  AUTOMATION_TYPES_LABEL_MAP,
  type IPractice,
  isAutomatedNotification,
  IPatient,
  AutomatedNotificationType,
  TreatmentStepAutomation,
  TimingDirection,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  type DocumentReference,
  type Timestamp,
  filterUndefined,
} from '@principle-theorem/shared';
import { DATE_TIME_FORMAT, toMoment } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import {
  type AutomationEntity,
  type IRescheduleAutomationListData,
  type IRescheduleListData,
} from '../../store/models';
import {
  hasValidEmail,
  hasValidMobile,
} from '@principle-theorem/principle-core';
import { map } from 'rxjs/operators';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';

@Component({
  selector: 'pr-automation-reschedule-list',
  templateUrl: './automation-reschedule-list.component.html',
  styleUrls: ['./automation-reschedule-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomationRescheduleListComponent implements OnDestroy {
  private _organisation = inject(OrganisationService);
  readonly dateFormat = DATE_TIME_FORMAT;
  automations$ = new ReplaySubject<IRescheduleAutomationListData[]>(1);
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  trackByData =
    TrackByFunctions.nestedField<IRescheduleAutomationListData>(
      'automation.uid'
    );
  dataSource: ObservableDataSource<IRescheduleAutomationListData>;
  displayedColumns = ['title', 'status', 'actions'];

  @Output() editAutomation = new EventEmitter<AutomationEntity>();
  @Output() cancelAutomation = new EventEmitter<AutomationEntity>();
  @Output() setAutomationPending = new EventEmitter<AutomationEntity>();

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @Input()
  set automations(automations: IRescheduleAutomationListData[]) {
    if (automations) {
      this.automations$.next(automations);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor() {
    this.dataSource = new ObservableDataSource(this.automations$);
    this.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, sortHeaderId) => this._sortingDataAccessor(data, sortHeaderId)
    );
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  edit(automation: AutomationEntity): void {
    this.editAutomation.emit(automation);
  }

  getTitle(automation: AutomationEntity): RawInlineNodes {
    return isAutomatedNotification(automation.data)
      ? [toTextContent(automation.data.name)]
      : automation.data.title;
  }

  getTypeLabel(type: AutomationType): string {
    return AUTOMATION_TYPES_LABEL_MAP[type];
  }

  getStatus(data: IRescheduleListData): AutomationStatus {
    return data.changes?.status ?? data.automation.status;
  }

  getPracticeRef(
    data: IRescheduleListData
  ): DocumentReference<IPractice> | undefined {
    return data.changes?.practiceRef ?? data.automation.practiceRef;
  }

  handleToggle(
    event: MatSlideToggleChange,
    automation: AutomationEntity
  ): void {
    if (!event.checked) {
      return this.cancelAutomation.emit(automation);
    }
    this.setAutomationPending.emit(automation);
  }

  isBeforeNow(timestamp: Timestamp): boolean {
    const gracePeriod = moment().add(AUTOMATION_GRACE_PERIOD);
    return toMoment(timestamp).isSameOrBefore(gracePeriod);
  }

  willWaitForAppointmentComplete(data: IRescheduleListData): boolean {
    return data.automation.data.timing.direction === TimingDirection.After;
  }

  missingContactDetailsHint$(
    automation: TreatmentStepAutomation
  ): Observable<string | undefined> {
    return combineLatest([
      this.patient$,
      this._organisation.organisation$.pipe(filterUndefined()),
    ]).pipe(
      map(([patient, org]) => {
        if (!isAutomatedNotification(automation)) {
          return;
        }
        switch (automation.type) {
          case AutomatedNotificationType.SMS:
            return !hasValidMobile(
              patient,
              org.region,
              org.integrations?.smsProvider
            )
              ? 'Automation requires a valid mobile number'
              : undefined;
          case AutomatedNotificationType.EMAIL:
            return !hasValidEmail(patient)
              ? 'Automation requires a valid email address'
              : undefined;
          default:
            return undefined;
        }
      })
    );
  }

  private _sortingDataAccessor(
    data: IRescheduleAutomationListData,
    sortHeaderId: string
  ): string | number | undefined {
    switch (sortHeaderId) {
      case 'status':
        return data.automation.status;
      case 'type':
        return data.automation.type;
      case 'triggerDate':
        return timestampSortingAccessor(data.automation.triggerDate);
      default:
        return;
    }
  }
}
