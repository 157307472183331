import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { Address } from '@principle-theorem/principle-core';
import {
  AddressComponentType,
  Countries,
  CountriesWithStates,
  IAddressMetadata,
  IManualAddressInput,
  PLACES_AUTOCOMPLETE_OPTIONS,
  STATE_MAP,
} from '@principle-theorem/principle-core/interfaces';
import { getEnumValues } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { GeocodeService } from '../../maps/geocode.service';

class ManualAddressForm extends TypedFormGroup<IManualAddressInput> {
  constructor() {
    super({
      streetName: new TypedFormControl<string>(''),
      subpremise: new TypedFormControl<string>(''),
      city: new TypedFormControl<string>(''),
      state: new TypedFormControl<string>(''),
      country: new TypedFormControl<string>(''),
      postalCode: new TypedFormControl<string>(''),
    });
  }
}

@Component({
    selector: 'pr-address-input',
    imports: [
        CommonModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatIconModule,
    ],
    templateUrl: './address-input.component.html',
    styleUrls: ['./address-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddressInputComponent),
            multi: true,
        },
    ]
})
export class AddressInputComponent
  implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor
{
  private _onDestroy$ = new Subject<void>();
  private _geocodeService = inject(GeocodeService);
  private _input: ElementRef<HTMLInputElement>;
  private _states = signal<string[]>([]);
  private _verifiedAddress = signal<IAddressMetadata | undefined>(undefined);
  filteredCountries = signal<string[]>([]);
  filteredStates = signal<string[]>([]);
  manualInput = signal(false);
  addressIsVerified = signal(false);
  autocompleteEnabled = signal(false);
  manualAddressForm = new ManualAddressForm();
  addressCtrl = new TypedFormControl<string | IAddressMetadata>();

  @ViewChild('addressInput')
  set input(element: ElementRef<HTMLInputElement>) {
    if (element) {
      this._input = element;
      this._setupAutocomplete();
    }
  }

  @Input() placeholder = 'Enter address';
  @Input() label = 'Address';
  @Input() required = false;

  onChange: (_: unknown) => void;
  onTouched: (_: unknown) => void;

  ngOnInit(): void {
    this._handleCountryValueChanges();
    this._setStatesOnCountryChange();
    this._handleStateValueChanges();
    this._handleManualAddressFormChanges();
    this._handleAddressValueChanges();
  }

  async ngAfterViewInit(): Promise<void> {
    await this._geocodeService.loader.load();
    this.autocompleteEnabled.set(true);
    this._setupAutocomplete();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  writeValue(value: IAddressMetadata | null): void {
    if (!value) {
      this.addressCtrl.reset();
      this.manualAddressForm.reset();
      this._verifiedAddress.set(undefined);
      this.addressIsVerified.set(false);
      return;
    }

    if (value && value.address) {
      this.addressCtrl.setValue(value.address, { emitEvent: false });
    }

    if (value && value.components) {
      const components = value.components;
      this.manualAddressForm.patchValue(
        { ...components, city: components.city ?? components.suburb },
        { emitEvent: false }
      );
      this.manualAddressForm.controls.streetName.patchValue(
        Address.streetAddressFromComponents(value.components),
        { emitEvent: false }
      );
    }

    if (value.isVerified) {
      this._verifiedAddress.set(value);
      this.addressIsVerified.set(true);
    }

    if (!value.isVerified) {
      this._verifiedAddress.set(undefined);
      this.addressIsVerified.set(false);
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.addressCtrl.disable() : this.addressCtrl.enable();
  }

  toggleManualInput(): void {
    this.manualInput.update((value) => !value);
    this.manualAddressForm.markAllAsTouched();
    this.manualInput()
      ? this._initManualAddressForm()
      : this._initAutocompleteInput();
  }

  clearForm(): void {
    this.manualAddressForm.reset();
    this.manualAddressForm.markAsUntouched();
    this.manualAddressForm.markAsPristine();
  }

  private _initManualAddressForm(): void {
    this.addressIsVerified.set(false);
    this._getUsersLocation();
  }

  private _initAutocompleteInput(): void {
    const verifiedAddress = this._verifiedAddress();
    if (verifiedAddress) {
      this.writeValue(verifiedAddress);
      this.onChange(verifiedAddress);
    }
  }

  private _getUsersLocation(): void {
    navigator.geolocation.getCurrentPosition((position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      this._geocodeService
        .geocodeFromCoordinates$({
          latitude,
          longitude,
        })
        .pipe(takeUntil(this._onDestroy$))
        .subscribe((location) => {
          const address = Address.transformAddressComponents(location);
          if (!address) {
            return;
          }
          const state = address[AddressComponentType.State];
          const country = address[AddressComponentType.Country];
          this.manualAddressForm.controls.state.setValue(state);
          this.manualAddressForm.controls.country.setValue(country);
        });
    });
  }

  private _setupAutocomplete(): void {
    if (!this._input || !this.autocompleteEnabled()) {
      return;
    }
    const autocomplete = new google.maps.places.Autocomplete(
      this._input.nativeElement,
      PLACES_AUTOCOMPLETE_OPTIONS
    );

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      const metadata = Address.metadata(place);

      if (!metadata) {
        return;
      }

      this._verifiedAddress.set(metadata);
      this.writeValue(metadata);
      this.onChange(metadata);
    });
  }

  private _filterStates(value: string): string[] {
    return this._states().filter((country) =>
      country.toLowerCase().includes(value.toLowerCase())
    );
  }

  private _filterCountries(value: string): string[] {
    return getEnumValues(Countries).filter((country) =>
      country.toLowerCase().includes(value.toLowerCase())
    );
  }

  private _handleCountryValueChanges(): void {
    this.manualAddressForm.controls.country.valueChanges
      .pipe(
        startWith(''),
        map((value) => this._filterCountries(value || '')),
        takeUntil(this._onDestroy$)
      )
      .subscribe((countries) => this.filteredCountries.set(countries));
  }

  private _setStatesOnCountryChange(): void {
    this.manualAddressForm.controls.country.valueChanges
      .pipe(
        map((country) => {
          const result = country
            ? getEnumValues(CountriesWithStates).find(
                (countryWithStates) => countryWithStates === country
              )
            : undefined;
          return result ? STATE_MAP[result] : [];
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((states) =>
        states ? this._states.set(states.sort()) : this._states.set([])
      );
  }

  private _handleStateValueChanges(): void {
    this.manualAddressForm.controls.state.valueChanges
      .pipe(
        startWith(''),
        map((value) => this._filterStates(value || '')),
        takeUntil(this._onDestroy$)
      )
      .subscribe((states) => this.filteredStates.set(states));
  }

  private _handleManualAddressFormChanges(): void {
    this.manualAddressForm.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => {
        if (this.manualAddressForm.invalid) {
          this.manualAddressForm.markAllAsTouched();
          this.onChange({ address: undefined });
          return;
        }

        const metadata = {
          address: Address.format(value),
          isVerified: false,
          components: {
            [AddressComponentType.StreetName]: value.streetName,
            [AddressComponentType.Subpremise]: value.subpremise,
            [AddressComponentType.City]: value.city,
            [AddressComponentType.State]: value.state,
            [AddressComponentType.Country]: value.country,
            [AddressComponentType.PostalCode]: value.postalCode,
          },
        };

        this.onChange(metadata);
      });
  }

  private _handleAddressValueChanges(): void {
    this.addressCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => {
        const isSameAsVerified = this._verifiedAddress()?.address === value;

        isSameAsVerified
          ? this.addressIsVerified.set(true)
          : this.addressIsVerified.set(false);

        if (!value && this.onChange) {
          // eslint-disable-next-line no-null/no-null
          this.addressCtrl.setErrors(null);
          this.onChange({ address: undefined });
        }

        if (!this.addressIsVerified() && value) {
          this.addressCtrl.setErrors({ invalidAddress: true });
        }
      });
  }
}
