import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { type IBreadcrumb } from '@principle-theorem/ng-shared';
import {
  type IOrganisation,
  type IRole,
} from '@principle-theorem/principle-core/interfaces';
import { Organisation } from '@principle-theorem/principle-core';
import { type CollectionReference } from '@principle-theorem/shared';
import {
  filterUndefined,
  findProp,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleListComponent {
  roleCollection$: Observable<CollectionReference<IRole>>;
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(private _route: ActivatedRoute) {
    const organisation$ = this._route.data.pipe(
      findProp<WithRef<IOrganisation>>('organisation'),
      filterUndefined()
    );

    this.roleCollection$ = this._route.data.pipe(
      findProp<WithRef<IOrganisation>>('organisation'),
      filterUndefined(),
      map((organisation) => Organisation.roleCol(organisation))
    );

    this.breadcrumbs$ = organisation$.pipe(
      map((organisation) => [
        { label: 'Organisations', path: '../../' },
        { label: organisation.name, path: '../' },
        { label: 'Roles' },
      ])
    );
  }
}
