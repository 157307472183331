import { randCompanyName, randSlug } from '@ngneat/falso';
import {
  IOrganisation,
  OrganisationStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  Properties,
  Region,
} from '@principle-theorem/shared';

export class OrganisationMock
  extends BaseFirestoreMock
  implements Properties<IOrganisation>
{
  name = randCompanyName();
  slug = randSlug();
  status = OrganisationStatus.Active;
  region = Region.Australia;
  integrations = {};
  subscription = {
    subscriptionId: 'sub_123',
    customerId: 'cus_123',
  };
}
