import { MeasureFormatter } from '@principle-theorem/principle-core/interfaces';
import { IAppointmentServiceCodeEvent } from '@principle-theorem/reporting/interfaces';
import { BigQueryTable } from '../../big-query-tables';
import { GroupBy, IUnnestQuery } from '../../querying';
import { latestEventId } from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import { AppointmentDimensionMeasureFactory } from '../dimensions/appointment-dimension';
import {
  CanDoAllProperty,
  ComparableProperties,
  MeasureTransformMap,
} from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { QueryFactory } from '../query-factory';
import { BaseTreatmentStepEventFactMeasures } from './base-treatment-step-event';

export class AppointmentServiceCodeFactMeasures
  extends BaseTreatmentStepEventFactMeasures
  implements
    MeasureTransformMap<
      ComparableProperties<
        Omit<IAppointmentServiceCodeEvent, 'name' | 'status' | 'deleted'>
      >
    >
{
  id = 'appointmentServiceCodeEvent';
  readonly name = 'Appointment Service Codes';
  readonly table = BigQueryTable.TreatmentStepEvent;

  get latestEvent(): AppointmentServiceCodeFactMeasures {
    const serviceCodesUnnest: IUnnestQuery = {
      alias: 'serviceCode',
      property: 'serviceCodes',
    };

    const measures = new AppointmentServiceCodeFactMeasures();
    measures.id = latestEventId(measures);
    measures._query = QueryFactory.fromTable(measures.table)
      .override(measures._query)
      .latestEvent(measures.table, GroupBy.TreatmentStep)
      .mergeJoins(measures.treatmentStep.query.joins)
      .filterSoftDeleted(measures.treatmentStep.measureRef('deleted'))
      .unnest(serviceCodesUnnest)
      .get();
    return measures;
  }

  get practitioner(): CanDoAllProperty {
    const propertyName = 'serviceCode.practitioner.name';
    const measure = this.measureRef(propertyName, true);
    return MeasurePropertyFactory.string(
      {
        id: propertyName,
        label: 'Practitioner Name',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'No Practitioner'
    );
  }

  get codeType(): CanDoAllProperty {
    const propertyName = 'serviceCode.type';
    const measure = this.measureRef(propertyName, true);
    return MeasurePropertyFactory.string(
      {
        id: propertyName,
        label: 'Service Code Type',
        summary: 'The type of service code',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get code(): CanDoAllProperty {
    const propertyName = 'serviceCode.code';
    const measure = this.measureRef(propertyName, true);
    return MeasurePropertyFactory.string(
      {
        id: propertyName,
        label: 'Service Code',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get quantity(): CanDoAllProperty {
    const propertyName = 'serviceCode.quantity';
    const measure = this.measureRef(propertyName, true);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Service Code Quantity',
        formatter: MeasureFormatter.Number,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get price(): CanDoAllProperty {
    const propertyName = 'serviceCode.price';
    const measure = this.measureRef(propertyName, true);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Service Code Total Price',
        summary: 'The total price of the service code',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get tax(): CanDoAllProperty {
    const propertyName = 'serviceCode.tax';
    const measure = this.measureRef(propertyName, true);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Service Code Total Tax',
        summary: 'The total tax of the service code',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get chartedSurfaces(): CanDoAllProperty {
    const propertyName = 'serviceCode.chartedSurfaces';
    const measure = this.measureRef(propertyName, true);
    return MeasurePropertyFactory.array(
      {
        id: propertyName,
        label: 'Charted Surfaces',
        summary: 'The charted surfaces associated with the service code',
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      measure.factPropertyPath,
      (fact) => fact
    );
  }

  get treatmentName(): CanDoAllProperty {
    const propertyName = 'serviceCode.treatment.name';
    const measure = this.measureRef(propertyName, true);
    return MeasurePropertyFactory.string(
      {
        id: propertyName,
        label: 'Treatment Name',
        summary: 'The name of the treatment associated with the service code',
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get treatmentCategory(): CanDoAllProperty {
    const treatmentCategory = 'serviceCode.treatmentCategory';
    const labelMeasure = this.measureRef(`${treatmentCategory}.name`, true);
    const colourMeasure = this.measureRef(
      `${treatmentCategory}.colour.value`,
      true
    );
    const dataMeasure = this.measureRef(
      MeasurePath.docRef(`${treatmentCategory}.ref`),
      true
    );

    return AppointmentDimensionMeasureFactory.treatmentCategory(
      {
        id: this._pathWithPrefix('treatmentCategory'),
        label: 'Treatment Category',
        summary:
          'The category of the treatment associated with the service code',
      },
      labelMeasure,
      colourMeasure,
      dataMeasure,
      this.buildQuery()
        .attributes([
          labelMeasure.attributePath,
          colourMeasure.attributePath,
          dataMeasure.attributePath,
        ])
        .get()
    );
  }
}
