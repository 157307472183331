import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HotTableModule } from '@handsontable/angular';
import { NgCoreModule } from '@principle-theorem/ng-core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  NumericCellType,
  TextCellType,
  registerCellType,
} from 'handsontable/cellTypes';
import {
  AutoColumnSize,
  AutoRowSize,
  Autofill,
  BasePlugin,
  CopyPaste,
  MergeCells,
  NestedHeaders,
  registerPlugin,
} from 'handsontable/plugins';
import { PerioChartDisplayComponent } from './perio-chart-display/perio-chart-display.component';
import { PerioTableDisplayComponent } from './perio-table-display/perio-table-display.component';

registerCellType(NumericCellType);
registerCellType(TextCellType);
registerPlugin(BasePlugin);
registerPlugin(AutoColumnSize);
registerPlugin(AutoRowSize);
registerPlugin(Autofill);
registerPlugin(CopyPaste);
registerPlugin(NestedHeaders);
registerPlugin(MergeCells);

@NgModule({
  declarations: [PerioTableDisplayComponent, PerioChartDisplayComponent],
  imports: [CommonModule, NgMaterialModule, NgCoreModule, HotTableModule],
  exports: [PerioChartDisplayComponent, PerioTableDisplayComponent],
})
export class NgPerioChartingModule {}
