import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { SystemTemplateSelectorsComponent } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { IResolvedTemplateWithName } from '@principle-theorem/principle-core/interfaces';
import { compact } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import {
  IPrintTreatmentPlanData,
  IPrintTreatmentPlanDialogData,
  ITreatmentStepWithLineItems,
} from '../treatment-plan-print/treatment-plan-print.component';
import { FormsModule } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'pr-treatment-plan-print-settings-dialog',
    templateUrl: './treatment-plan-print-settings-dialog.component.html',
    styleUrl: './treatment-plan-print-settings-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        NgMaterialModule,
        NgSharedModule,
        SystemTemplateSelectorsComponent,
        FormsModule,
    ]
})
export class TreatmentPlanPrintSettingsDialogComponent {
  @ViewChild('treatmentSteps') treatmentSteps: MatSelectionList;
  selectedTemplates$ = new BehaviorSubject<IResolvedTemplateWithName[]>([]);
  displayTreatmentPlanName = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IPrintTreatmentPlanDialogData,
    private _dialogRef: MatDialogRef<
      TreatmentPlanPrintSettingsDialogComponent,
      IPrintTreatmentPlanData
    >
  ) {}

  print(): void {
    const treatmentSteps = this.data.treatmentSteps.filter((step) =>
      this.treatmentSteps.selectedOptions.selected
        .map((option) => option.value as ITreatmentStepWithLineItems)
        .includes(step)
    );

    this._dialogRef.close({
      treatmentSteps,
      templates: compact(this.selectedTemplates$.value),
      displayTreatmentPlanName: this.displayTreatmentPlanName,
    });
  }

  drop(event: CdkDragDrop<ITreatmentStepWithLineItems[]>): void {
    moveItemInArray(
      this.data.treatmentSteps,
      event.previousIndex,
      event.currentIndex
    );
  }

  close(): void {
    this._dialogRef.close();
  }
}
