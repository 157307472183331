import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { SourceEntity } from '../../../source/source-entity';
import { ADAItemSourceEntity } from './ada-item';
import { ExactTreatmentType } from './patient-treatments';

export const PATIENT_USED_ITEM_CODE_TREATMENT_RESOURCE_TYPE =
  'usedItemCodeTreatments';

export const PATIENT_USED_ITEM_CODE_TREATMENT_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Item Codes Used as Treatments List',
      description: '',
      idPrefix: PATIENT_USED_ITEM_CODE_TREATMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

const TREATMENT_SOURCE_QUERY = `
SELECT
  DISTINCT(servicecode) AS item_code,
  servicedescription AS description,
  DENSE_RANK() OVER (results) AS id
FROM convtreatment
WHERE LOWER(treatmenttype) != '${ExactTreatmentType.Base}'
AND servicecode != ''
WINDOW results AS (ORDER BY servicecode)
ORDER BY id
  `;

export class UsedItemCodeTreatmentSourceEntity extends ADAItemSourceEntity {
  override sourceEntity = PATIENT_USED_ITEM_CODE_TREATMENT_SOURCE_ENTITY;
  override entityResourceType = PATIENT_USED_ITEM_CODE_TREATMENT_RESOURCE_TYPE;
  override sourceQuery = TREATMENT_SOURCE_QUERY;
}
