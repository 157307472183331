import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MedicalFormAlerts } from '@principle-theorem/ng-custom-forms';
import {
  CurrentBrandScope,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { IBreadcrumb } from '@principle-theorem/ng-shared';
import {
  Brand,
  DEFAULT_MEDICAL_HISTORY_FORM_CONFIG,
  buildCustomFormConfiguration,
} from '@principle-theorem/principle-core';
import {
  CustomFormDataResolverConfigMap,
  CustomFormType,
  IBrand,
  ICustomFormConfiguration,
  IFormSchema,
  IPatient,
  ISubmittedForm,
  type ICustomFormData,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  WithRef,
  doc,
  filterUndefined,
  isPathChanged$,
  snapshot,
  toTimestamp,
} from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { ReplaySubject, combineLatest, of, type Observable } from 'rxjs';
import { auditTime, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-medical-history-form-preview',
  templateUrl: './medical-history-form-preview.component.html',
  styleUrls: ['./medical-history-form-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicalHistoryFormPreviewComponent {
  form$: Observable<ICustomFormData<unknown>>;
  brand$: Observable<WithRef<IBrand>>;
  breadcrumbs$: Observable<IBreadcrumb[]>;
  schema$: Observable<IFormSchema>;
  resolverConfigs$: Observable<CustomFormDataResolverConfigMap>;
  submittedForm$ = new ReplaySubject<ISubmittedForm>(1);
  medicalFormAlerts: MedicalFormAlerts;

  constructor(
    private _currentBrand: CurrentBrandScope,
    stateNav: StateBasedNavigationService,
    dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.brand$ = this._currentBrand.doc$.pipe(filterUndefined());
    this.breadcrumbs$ = this.brand$.pipe(
      map((brand) => [
        { label: 'Settings', path: '../../../' },
        { label: brand.name },
        { label: 'Medical History' },
      ])
    );

    const customFormConfig$ = this._currentBrand.doc$.pipe(
      filterUndefined(),
      switchMap((brand) => Brand.medicalHistoryFormConfig$(brand)),
      map((config) => this._toCustomMaterialForm(config))
    );

    this.form$ = customFormConfig$.pipe(isPathChanged$('schema', isEqual));

    this.schema$ = customFormConfig$.pipe(
      map(
        (config) => config?.schema ?? DEFAULT_MEDICAL_HISTORY_FORM_CONFIG.schema
      ),
      filterUndefined()
    );

    this.resolverConfigs$ = customFormConfig$.pipe(
      map((config) => config?.dataResolverConfig ?? {})
    );

    const latestFormData$ = combineLatest([
      customFormConfig$,
      this.submittedForm$.pipe(auditTime(200)),
    ]).pipe(
      map(([form, submittedForm]) => ({
        ...submittedForm,
        ...form,
      }))
    );

    this.medicalFormAlerts = new MedicalFormAlerts(
      of({} as WithRef<IPatient>),
      latestFormData$,
      stateNav,
      dialog
    );
  }

  async updateForm(data: object): Promise<void> {
    this.submittedForm$.next({
      data,
      schema: await snapshot(this.schema$),
      disableChanges: false,
      date: toTimestamp(),
    });
  }

  async updateAlerts(
    data: ICustomFormData<unknown>['dataResolverConfig']
  ): Promise<void> {
    const customFormConfigRef = await snapshot(
      this._currentBrand.doc$.pipe(
        filterUndefined(),
        map((brand) =>
          doc(Brand.customFormsConfigCol(brand), CustomFormType.MedicalHistory)
        )
      )
    );

    await Firestore.patchDoc(customFormConfigRef, {
      dataResolverConfig: data,
    });

    this._snackBar.open('Medical History Alerts Updated');
  }

  private _toCustomMaterialForm(
    config?: ICustomFormConfiguration
  ): ICustomFormConfiguration {
    return buildCustomFormConfiguration(config);
  }
}
