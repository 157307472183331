import {
  IRestrictAccessRole,
  IRestrictAccessStaffer,
  IRole,
  IStaffer,
  RestrictAccessEntity,
} from '@principle-theorem/principle-core/interfaces';
import { multiMap, WithRef } from '@principle-theorem/shared';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { stafferToNamedDoc } from '../common';
import { Staffer } from '../staffer/staffer';

export class RestrictAccess {
  static toRoleAccess(role: WithRef<IRole>): IRestrictAccessRole {
    return {
      ref: role.ref,
      name: role.name,
      type: 'role',
    };
  }

  static toStafferAccess(staffer: WithRef<IStaffer>): IRestrictAccessStaffer {
    return {
      ...stafferToNamedDoc(staffer),
      type: 'staffer',
    };
  }

  static hasAccess(
    restrictAccessTo: RestrictAccessEntity[],
    accessEntities: RestrictAccessEntity[]
  ): boolean {
    if (!restrictAccessTo.length) {
      return true;
    }
    return accessEntities.some((entity) =>
      restrictAccessTo.some((access) => access.ref.path === entity.ref.path)
    );
  }

  static getAccessEntities$(
    staffer?: WithRef<IStaffer>
  ): Observable<RestrictAccessEntity[]> {
    if (!staffer) {
      return of([]);
    }
    const stafferAccess = this.toStafferAccess(staffer);
    return Staffer.roles$(staffer).pipe(
      multiMap((role) => this.toRoleAccess(role)),
      map((roleAccesses) => [...roleAccesses, stafferAccess])
    );
  }
}
