import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  Optional,
  Self,
  type AfterViewInit,
  type OnDestroy,
} from '@angular/core';
import {
  FormGroupDirective,
  NgControl,
  type ControlValueAccessor,
} from '@angular/forms';
import {
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import {
  DEFAULT_INLINE_EXTENSIONS,
  MixedSchema,
  getMixedContent,
  isMixedSchema,
  isRawSchema,
  type Content,
  type RawInlineNodes,
} from '@principle-theorem/editor';
import { TypedFormControl } from '@principle-theorem/ng-shared';
import { get, noop } from 'lodash';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { EditorPresetsService } from '../editor-presets.service';
import { Editor } from '@tiptap/core';

@Component({
  selector: 'pr-editor-input',
  templateUrl: './editor-input.component.html',
  styleUrls: ['./editor-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MatFormFieldControl, useExisting: EditorInputComponent },
  ],
})
export class EditorInputComponent
  implements
    MatFormFieldControl<MixedSchema | RawInlineNodes | Content>,
    ControlValueAccessor,
    AfterViewInit,
    OnDestroy
{
  static nextId = 0;

  private _required = false;
  private _disabled = false;
  private _placeholder = '';
  private _onDestroy$: Subject<void> = new Subject();

  editorCtrl = new TypedFormControl<MixedSchema | RawInlineNodes | Content>();
  controlType = 'editor-input';
  stateChanges: Subject<void> = new Subject();
  focused = false;
  errorState = false;
  editor: Editor;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;

  @HostBinding()
  id = `editor-input-${EditorInputComponent.nextId++}`;
  @HostBinding('class.flex-1') isFlex = true;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private _cdr: ChangeDetectorRef,
    @Optional() public parentFormField: MatFormField,
    editorPresets: EditorPresetsService,
    @Optional() private _parentFormGroup: FormGroupDirective
  ) {
    // eslint-disable-next-line no-null/no-null
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }

    this._focusMonitor
      .monitor(this._elementRef.nativeElement, true)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((origin) => {
        this.focused = !!origin;
        this.stateChanges.next();
      });

    this.editor = new Editor({
      extensions: [
        ...DEFAULT_INLINE_EXTENSIONS,
        ...editorPresets.defaultInlineExtensions(),
      ],
    });
  }

  set value(content: MixedSchema | RawInlineNodes | Content | null) {
    if (content) {
      this.editorCtrl.setValue(this._extractContent(content));
    }
    this.stateChanges.next();
  }

  @HostListener('keydown.enter')
  submit(): void {
    this._parentFormGroup?.ngSubmit.emit();
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  // eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
  onTouched = (): void => {};

  ngAfterViewInit(): void {
    this._cdr.detectChanges();
  }

  writeValue(content: MixedSchema | RawInlineNodes | Content): void {
    this.value = content;
    this._cdr.detectChanges();
  }

  registerOnChange(fn: () => void): void {
    this.editorCtrl.valueChanges
      .pipe(
        map((content) => this._extractContent(content)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get empty(): boolean {
    return this.editor.isEmpty;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef.nativeElement);
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector(
      `.${this.controlType}-container`
    );
    if (!controlElement) {
      return;
    }
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_event: MouseEvent): void {
    noop();
  }

  private _extractContent(
    content: MixedSchema | RawInlineNodes | Content
  ): RawInlineNodes | Content {
    if (isRawSchema(content)) {
      return get(content, 'content[0].content', []) as RawInlineNodes;
    }

    if (isMixedSchema(content)) {
      return get(
        getMixedContent(content),
        'content[0].content',
        []
      ) as RawInlineNodes;
    }

    return content;
  }
}
