import { Validators } from '@angular/forms';
import { type IPricingRuleItem } from '@principle-theorem/principle-core/interfaces';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { v4 as uuid } from 'uuid';

export class PricingRuleFormGroup extends TypedFormGroup<IPricingRuleItem> {
  constructor() {
    super({
      price: new TypedFormControl<number>(),
      startUnit: new TypedFormControl<number>({ value: 1, disabled: true }, [
        Validators.min(1),
        Validators.required,
      ]),
      endUnit: new TypedFormControl<number>(undefined),
      selected: new TypedFormControl<boolean>(false),
      uid: new TypedFormControl<string>(uuid()),
    });
  }
}
