import * as firestoreTesting from './helpers';
import { noop } from 'lodash';
import {
  type CollectionReference,
  type DocumentReference,
  type DocumentSnapshot,
  type Transaction,
} from '../adaptor';

export function docRef<T>(ref?: DocumentReference<T>): DocumentReference<T> {
  return {
    ...(ref ?? firestoreTesting.docRef<T>()),
    set: noop,
    onSnaphot: noop,
  } as unknown as DocumentReference<T>;
}

export function colRef<T>(
  ref?: CollectionReference<T>
): CollectionReference<T> {
  return {
    ...(ref ?? firestoreTesting.colRef<T>()),
  } as CollectionReference<T>;
}

export function docSnapshot<T>(
  ref?: DocumentReference<T>
): DocumentSnapshot<T> {
  return {
    ...firestoreTesting.docSnapshot<T>(ref),
    data: noop,
  } as DocumentSnapshot<T>;
}

export function transaction(): Transaction {
  return {
    set: noop,
  } as unknown as Transaction;
}
