import { NgModule } from '@angular/core';
import { CliniviewOpenPatientComponent } from './cliniview-open-patient/cliniview-open-patient.component';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { CliniviewFeatureService } from './cliniview-feature.service';

@NgModule({
  declarations: [CliniviewOpenPatientComponent],
  imports: [NgSharedModule],
  providers: [CliniviewFeatureService],
})
export class CliniviewModule {}
