import { startsWith, toPairs, uniq } from 'lodash';
import { getRowNumberAttribute } from './helpers';
import {
  FACT_TABLE_PREFIX,
  toBigQueryAlias,
  toFactPropertyName,
} from './models/base-measures';
import {
  GroupBy,
  IOrderBy,
  IReportingQuery,
  ITableJoin,
  IUnnestQuery,
  SortOrder,
} from './querying';

export interface IBaseQuery {
  table: string;
  alias: string;
  attributes: string[];
}

export interface ITableQuery extends IBaseQuery {
  table: string;
  alias: string;
  attributes: string[];
  uniqueByProperty?: string;
  orderByProperty?: string;
}

export class QueryBuilder {
  /**
   * Compreses a query string by removing; line breaks,
   * duplicate spaces and spaces inside brackets.
   * This makes it easier to compare and test queries.
   */
  static flatten(query: string): string {
    return query
      .replace(/(\r\n|\n|\r)/gm, ' ')
      .replace(/\s+/g, ' ')
      .replace(/\(\s+/g, '(')
      .replace(/\s+\)/g, ')')
      .trim();
  }

  static getAttributes(attributes: string[]): string[] {
    return attributes.length > 0 ? uniq(attributes) : ['*'];
  }

  static buildSubQuery(query: IReportingQuery): BigQuerySQL {
    const innerQuery = new BigQuerySQL()
      .select(this.getAttributes(query.attributes))
      .from(query.table);
    if (!query.filters?.length) {
      return innerQuery;
    }
    return new BigQuerySQL()
      .with({ innerQuery })
      .select(['*'])
      .from('innerQuery')
      .where(query.filters);
  }

  static buildJoinTableQuery(query: ITableQuery): BigQuerySQL {
    const attributes = this.getAttributes(query.attributes).map((attribute) =>
      toBigQueryAlias(attribute, query.alias)
    );
    const rowNumberAlias = `${query.alias}_row_number`;
    return this.buildSubQuery({
      table: query.table,
      attributes: [
        ...attributes,
        getRowNumberAttribute(
          rowNumberAlias,
          query.uniqueByProperty,
          query.orderByProperty,
          SortOrder.Descending
        ),
      ],
      filters: [`${rowNumberAlias} = 1`],
    });
  }

  static joinTables(
    tableQueries: Record<string, string | BigQuerySQL>,
    sourceTable: string,
    tableJoins: ITableJoin[]
  ): string {
    const joins = tableJoins.map((join) => {
      const sourceProperty = toFactPropertyName(join.sourceJoinKey, 'fact');
      const destinationProperty = toFactPropertyName(
        join.destinationJoinKey,
        join.alias
      );
      return `${join.joinType} JOIN ${join.alias} ON ${sourceTable}.${sourceProperty} = ${join.alias}.${destinationProperty}`;
    });
    return new BigQuerySQL()
      .with(tableQueries)
      .select(['*'])
      .from(sourceTable)
      .join(joins)
      .get();
  }

  static getJoinTableQueries(query: IReportingQuery): ITableQuery[] {
    return (query.joins ?? []).map((join) => {
      const attributePrefix = `${join.alias}.`;
      const joinDestinationAttributes: string[] = [
        `${attributePrefix}${join.destinationJoinKey}`,
      ];
      const attributes = this.getAttributes([
        ...joinDestinationAttributes,
        ...query.attributes,
      ])
        .filter((attribute) => startsWith(attribute, attributePrefix))
        .map((attribute) => attribute.replace(attributePrefix, ''));
      return {
        alias: join.alias,
        table: join.table,
        attributes,
        uniqueByProperty: join.destinationJoinKey,
        orderByProperty: join.orderByProperty,
      };
    });
  }

  static getRootAttributes(query: IReportingQuery): string[] {
    const joins = query.joins ?? [];
    const joinSourceAttributes = joins.map((join) => join.sourceJoinKey);

    const rootAttributes = this.getAttributes(query.attributes).filter(
      (attribute) => !this.isJoinedAttribute(attribute, query.joins)
    );

    return [...joinSourceAttributes, ...rootAttributes];
  }

  static buildRootQuery(query: IReportingQuery): BigQuerySQL {
    const attributes = this.getRootAttributes(query).map((attribute) =>
      toBigQueryAlias(attribute, FACT_TABLE_PREFIX)
    );

    const subQuery = query.subQuery
      ? this.buildSubQuery(query.subQuery)
      : undefined;

    const innerQuery = subQuery
      ? new BigQuerySQL().with({ subQuery }).select(attributes).from('subQuery')
      : new BigQuerySQL().select(attributes).from(query.table);
    return innerQuery.unest(query.unnest);
  }

  static isJoinedAttribute(
    attribute: string,
    joins: ITableJoin[] = []
  ): boolean {
    return joins.some((join) => attribute.startsWith(`${join.alias}.`));
  }

  static getSelectAttributes(query: IReportingQuery): string[] {
    return this.getAttributes(query.attributes).map((attribute) => {
      return this.isJoinedAttribute(attribute, query.joins)
        ? toFactPropertyName(attribute)
        : toFactPropertyName(attribute, FACT_TABLE_PREFIX);
    });
  }

  static buildQuery(query: IReportingQuery): string {
    const rootTable = this.buildRootQuery(query);
    const tables = this.getJoinTableQueries(query).reduce(
      (acc: Record<string, string | BigQuerySQL>, tableQuery) => ({
        ...acc,
        [tableQuery.alias]: this.buildJoinTableQuery(tableQuery),
      }),
      { [query.table]: rootTable }
    );
    const joinedQuery = this.joinTables(tables, query.table, query.joins ?? []);

    return new BigQuerySQL()
      .with({ query: joinedQuery })
      .select(this.getSelectAttributes(query))
      .from('query')
      .where(query.filters)
      .groupBy(query.groupBy)
      .orderBy(query.orderBy)
      .get();
  }
}

class BigQuerySQL {
  private _sql: string;

  constructor(query?: string | BigQuerySQL) {
    this._sql = this._coerceSQL(query);
  }

  toString(): string {
    return this._sql;
  }

  get(): string {
    return this.toString();
  }

  with(aliases: Record<string, string | BigQuerySQL>): this {
    const parts = toPairs(aliases)
      .map(([alias, query]) => {
        const sql = this._coerceSQL(query);
        return `${alias} AS (${sql})`;
      })
      .join(', ');
    this._sql += ` WITH ${parts}`;
    return this;
  }

  select(attributes: string[]): this {
    const attributesStr = uniq(attributes).join(', ');
    this._sql += ` SELECT ${attributesStr}`;
    return this;
  }

  from(target: string): this {
    this._sql += ` FROM ${target}`;
    return this;
  }

  where(filters?: string[]): this {
    if (filters && filters.length) {
      this._sql += ` WHERE ${filters.join(' AND ')}`;
    }
    return this;
  }

  groupBy(queryGroupBy?: GroupBy[]): this {
    if (queryGroupBy && queryGroupBy.length) {
      this._sql += ` GROUP BY ${queryGroupBy.join(', ')}`;
    }
    return this;
  }

  orderBy(orderBy?: IOrderBy[]): this {
    if (orderBy && orderBy.length) {
      const orderByStr = orderBy
        .map((order) => `${String(order.attribute)} ${order.sortOrder}`)
        .join(', ');
      this._sql += ` ORDER BY ${orderByStr}`;
    }
    return this;
  }

  unest(unnest?: IUnnestQuery): this {
    if (unnest) {
      this._sql += `, UNNEST(${unnest.property}) AS ${unnest.alias}`;
    }
    return this;
  }

  join(joins: string[]): this {
    this._sql += ` ${joins.join(' \n')}`;
    return this;
  }

  private _coerceSQL(sql?: string | BigQuerySQL): string {
    return sql?.toString() ?? '';
  }
}
