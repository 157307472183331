import {
  type INamedDocument,
  isINamedDocument,
  isObject,
} from '@principle-theorem/shared';
import { type IEvent, isEvent } from '../event/event';
import { type IStaffer } from '../staffer/staffer';

export enum GapAction {
  Created = 'created',
  Filling = 'filling',
  Filled = 'filled',
}

export enum GapStatus {
  Available = 'available',
  Filling = 'filling',
  Filled = 'filled',
}

export interface IGap {
  event: IEvent;
  practitioner: INamedDocument<IStaffer>;
}

export function isGap(item: unknown): item is IGap {
  return (
    isObject(item) &&
    'event' in item &&
    isEvent(item.event) &&
    'practitioner' in item &&
    isINamedDocument(item.practitioner)
  );
}

export const GAP_DAYS_INCREMENT = 3;
