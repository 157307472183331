<mat-list>
  <ng-container
    *ngFor="let step of steps$ | async; last as isLast; trackBy: trackByStep"
  >
    <div mat-subheader>{{ step.name }}</div>
    <mat-list-item>
      <small
        *ngFor="let treatment of step.treatments; trackBy: trackByTreatment"
        >{{ treatment.config.name }}</small
      >
    </mat-list-item>
    <mat-divider *ngIf="!isLast" />
  </ng-container>
</mat-list>
