<mat-toolbar color="accent">Select Practice</mat-toolbar>

<mat-dialog-content>
  <ng-container *ngIf="options$ | async as options">
    <ng-container *ngIf="options.length; else noPractices">
      <mat-list>
        <ng-container *ngFor="let option of options; trackBy: trackByBrand">
          <div mat-subheader>{{ option.brand.name }}</div>
          <mat-list-item
            class="mat-mdc-list-item-interactive"
            ptElementTarget="{{ practice.slug }}-selector-item"
            *ngFor="let practice of option.practices; trackBy: trackByPractice"
            (click)="updatePractice(option.brand, practice)"
          >
            <span matListItemTitle>{{ practice.name }}</span>
          </mat-list-item>
        </ng-container>
      </mat-list>
    </ng-container>
  </ng-container>
  <ng-template #noPractices>No Practices</ng-template>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
  </pt-buttons-container>
</mat-dialog-actions>
