import { Component, Input } from '@angular/core';
import {
  type CanBeChartedProperty,
  generateBuilderData,
  type IChartConfig,
  toMeasureBuilderData,
} from '@principle-theorem/reporting';
import { type ComparableObject } from 'crossfilter2';
import { type ChartBuilder } from '../../../models/report/charts/chart-builder';
import { SingleValueTransformer } from '../../../models/report/charts/data-transformers/single-value-transformer';
import { type IDataPoint } from '../../../models/report/charts/measure-data-interfaces';
import { MeasureDataChartComponent } from '../../core/measure-data-chart-component';
import { CustomChartType } from '@principle-theorem/principle-core/interfaces';

@Component({
    selector: 'pr-drilldown-preview',
    templateUrl: './drilldown-preview.component.html',
    styleUrls: ['./drilldown-preview.component.scss'],
    standalone: false
})
export class DrilldownPreviewComponent extends MeasureDataChartComponent {
  private _measure: CanBeChartedProperty;
  chartBuilder: ChartBuilder;
  total: IDataPoint;
  @Input() selected?: boolean = false;
  @Input() disabled?: boolean = false;

  @Input()
  set measure(measure: CanBeChartedProperty) {
    this._measure = measure;
    this._redraw();
  }

  get measure(): CanBeChartedProperty {
    return this._measure;
  }

  protected _redraw(): void {
    if (!this.dataBuilder || !this.measure) {
      return;
    }

    const chart: ChartBuilder = this.dataBuilder.toAreaChart(
      this._config
    ).chartBuilder;

    chart.addChartOptions(this._chartOptions);
    this.chartBuilder = chart;
    void this._recalculateTotal();
  }

  private async _recalculateTotal(): Promise<void> {
    if (!this.dataBuilder || !this.measure) {
      return;
    }

    const transformer: SingleValueTransformer = new SingleValueTransformer();
    const value: ComparableObject = await transformer.transform(
      this.chartBuilder.chartDataHandler.config,
      this.chartBuilder.chartDataHandler.dataBuilder,
      this._measure
    );
    this.total = {
      value: parseFloat(String(value)),
      label: this._measure.metadata.label,
      formatter: this._measure.metadata.formatter,
    };
  }

  private get _config(): IChartConfig {
    return {
      type: CustomChartType.NumberSummary,
      builderData: generateBuilderData({
        plottedOverTime: true,
        measures: [toMeasureBuilderData(this.measure)],
      }),
      labels: {
        title: '',
      },
    };
  }

  private get _chartOptions(): IDrilldownPreviewOptions {
    return {
      width: 180,
      height: 20,
      chartArea: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
      backgroundColor: 'none',
      legend: { position: 'none' },
      enableInteractivity: false,
      axisTitlesPosition: 'none',
      vAxis: {
        textPosition: 'none',
        gridlines: {
          color: '#333',
          count: 0,
        },
      },
      hAxis: {
        textPosition: 'none',
        baselineColor: '#FFF',
      },
    };
  }
}

export interface IDrilldownPreviewOptions {
  width: number;
  height: number;
  chartArea: {
    top: number;
    right: number;
    bottom: number;
    left: number;
    width: string;
    height: string;
  };
  backgroundColor: 'none';
  legend: { position: 'none' };
  enableInteractivity: boolean;
  axisTitlesPosition: 'none';
  vAxis: {
    textPosition: 'none';
    gridlines: {
      color: string;
      count: number;
    };
  };
  hAxis: {
    textPosition: 'none';
    baselineColor: string;
  };
}
