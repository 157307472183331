import { AnyExtension } from '@tiptap/core';
import { ListItem } from '@tiptap/extension-list-item';
import { BlockNodes, ListNodes } from '../../available-extensions';

export function createListItemExtension(): AnyExtension {
  return ListItem.extend({
    name: ListNodes.ListItem,
  }).configure({
    bulletListTypeName: BlockNodes.BulletedList,
    orderedListTypeName: BlockNodes.OrderedList,
  });
}
