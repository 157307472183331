import { type QueryList } from '@angular/core';
import { type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export function queryListToObservable<T = unknown>(
  list: QueryList<T>
): Observable<T[]> {
  list.notifyOnChanges();
  return list.changes.pipe(
    startWith<QueryList<T>, QueryList<T>>(list),
    map((listSnapshot: QueryList<T>): T[] => listSnapshot.toArray())
  );
}
