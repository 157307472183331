import { ITreatmentCategory } from '@principle-theorem/principle-core/interfaces';
import { AtLeast } from '@principle-theorem/shared';

export class TreatmentCategory {
  static init(
    overrides: AtLeast<ITreatmentCategory, 'name' | 'colour'>
  ): ITreatmentCategory {
    return {
      deleted: false,
      ...overrides,
    };
  }
}
