import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import {
  EventableQueries,
  OrganisationCache,
  TimezoneResolver,
  getUniqueStaffParticipants,
} from '@principle-theorem/principle-core';
import {
  CalendarUnit,
  CalendarView,
  EventType,
  TimelineDisplayOrientation,
  TimelineDisplayZoom,
  type IAppointmentSuggestion,
  type IEventable,
  type IPracticeSettings,
  type IStaffer,
  IScheduleSummaryEventable,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  TimeBucket,
  filterUndefined,
  isChanged$,
  isSameRange,
  isSameRef,
  safeCombineLatest,
  shareReplayCold,
  type ITimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { EventableTimelineStore } from 'libs/ng-eventable/src/lib/components/eventable-timeline/eventable-timeline.store';
import { compact } from 'lodash';
import {
  ReplaySubject,
  Subject,
  combineLatest,
  from,
  type Observable,
} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

@Component({
    selector: 'pr-context-display',
    templateUrl: './context-display.component.html',
    styleUrls: ['./context-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [EventableTimelineStore],
    standalone: false
})
export class ContextDisplayComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  staff$: Observable<WithRef<IStaffer>[]>;
  events$ = new ReplaySubject<IEventable[]>(1);
  range$: Observable<ITimePeriod>;
  openingHours$: Observable<ITimePeriod[]>;
  practiceViewSettings$: Observable<IPracticeSettings['timeline']>;
  zoom = TimelineDisplayZoom.AutoFit;
  orientation = TimelineDisplayOrientation.Horizontal;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _suggestion: IAppointmentSuggestion,
    private _global: GlobalStoreService,
    private _eventableTimelineStore: EventableTimelineStore
  ) {
    this.events$.next([
      this._suggestion,
      ...this._suggestion.overlappingEvents,
      ...this._suggestion.adjacentEvents,
    ]);

    this.staff$ = this.events$.pipe(
      switchMap((events) => this._getStaff$(events))
    );

    this.range$ = combineLatest([
      this.events$,
      TimezoneResolver.fromEvent(this._suggestion),
    ]).pipe(
      map(([events, timezone]) =>
        TimeBucket.fromEvents(
          events.map((event) => event.event),
          timezone
        ).merge()
      ),
      filterUndefined()
    );

    this.openingHours$ = this.range$.pipe(map((range) => [range]));

    this.practiceViewSettings$ = OrganisationCache.practices
      .doc$(this._suggestion.event.practice.ref)
      .pipe(map((practice) => practice.settings.timeline));

    combineLatest([
      this._getScheduleSummaryEventables$(),
      this.range$.pipe(distinctUntilChanged(isSameRange)),
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([events, dateRange]) =>
        this._eventableTimelineStore.loadEvents({ events, dateRange })
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _getScheduleSummaryEventables$(): Observable<
    IScheduleSummaryEventable[]
  > {
    return combineLatest([
      this.range$.pipe(distinctUntilChanged(isSameRange)),
      from(Firestore.getDoc(this._suggestion.event.practice.ref)),
      this.staff$.pipe(isChanged$(isSameRef)),
      this._global.rosterSchedules$,
    ]).pipe(
      switchMap(([dateRange, practice, practitioners, allStaffSchedules]) =>
        EventableQueries.getScheduleSummaryEventablesWithFallback$(
          dateRange,
          practice,
          practitioners,
          allStaffSchedules,
          [EventType.GapCandidate, EventType.Gap, EventType.RosteredOn],
          CalendarUnit.Day,
          CalendarView.Timeline
        )
      ),
      shareReplayCold()
    );
  }

  private _getStaff$(events: IEventable[]): Observable<WithRef<IStaffer>[]> {
    return safeCombineLatest(
      getUniqueStaffParticipants(events).map((participant) =>
        this._global.getStaffer$(participant.ref)
      )
    ).pipe(map(compact));
  }
}
