<div
  [ngClass]="[
    shadowColor,
    shadow,
    padding,
    margin,
    width,
    borderRadius,
    backgroundColor,
    overflow,
    height,
    maxHeight,
    maxWidth
  ]"
  class="box-border"
>
  <ng-content />
</div>
