import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  type ComponentRef,
  Directive,
  ElementRef,
  inject,
  Injector,
  Input,
  type OnDestroy,
} from '@angular/core';
import { type MatMenu, type MatMenuTrigger } from '@angular/material/menu';
import { snapshot } from '@principle-theorem/shared';
import { fromEvent, type Observable, Subject } from 'rxjs';
import { takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { MenuTriggerPlaceholderComponent } from './menu-trigger-placeholder/menu-trigger-placeholder.component';

export interface IMenuComponent {
  menu$: Observable<MatMenu>;
}

export interface IMenuPlaceholder {
  menuTrigger: MatMenuTrigger;
  x: string;
  y: string;
}

@Directive({
  selector: '[ptRightClickMenu]',
})
export class RightClickMenuDirective implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _document: Document = inject(DOCUMENT);
  placeholder$: Subject<MenuTriggerPlaceholderComponent> = new Subject();
  menu$: Subject<MatMenu> = new Subject();

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _elementRef: ElementRef<HTMLElement>,
    private _injector: Injector,
    private _appRef: ApplicationRef
  ) {
    fromEvent<MouseEvent>(this._elementRef.nativeElement, 'contextmenu')
      .pipe(
        tap((event: MouseEvent) => event.preventDefault()),
        withLatestFrom(this.menu$),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([event, menu]) => {
        const placeholder = this._initPlaceholder(menu);
        placeholder.ref.instance.x = `${event.clientX}px`;
        placeholder.ref.instance.y = `${event.clientY}px`;
        setTimeout(() => void this._handleMenu(placeholder));
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @Input('ptRightClickMenu')
  set menuComponent(menuComponent: IMenuComponent) {
    if (menuComponent.menu$) {
      menuComponent.menu$
        .pipe(takeUntil(this._onDestroy$))
        .subscribe((menu: MatMenu) => this.menu$.next(menu));
      return;
    }
  }

  @Input()
  set placeholder(placeholder: MenuTriggerPlaceholderComponent) {
    this.placeholder$.next(placeholder);
  }

  private _initPlaceholder(menu: MatMenu): IComponentPlaceholder {
    const portal: DomPortalOutlet = new DomPortalOutlet(
      this._document.documentElement,
      this._componentFactoryResolver,
      this._appRef,
      this._injector
    );
    const componentPortal: ComponentPortal<MenuTriggerPlaceholderComponent> =
      new ComponentPortal(MenuTriggerPlaceholderComponent);
    const componentRef: ComponentRef<MenuTriggerPlaceholderComponent> =
      portal.attachComponentPortal(componentPortal);

    componentRef.instance.menu = menu;
    componentRef.changeDetectorRef.markForCheck();
    return {
      componentPortal,
      ref: componentRef,
    };
  }

  private async _handleMenu(placeholder: IComponentPlaceholder): Promise<void> {
    placeholder.ref.instance.menuTrigger.openMenu();

    await snapshot(placeholder.ref.instance.menuTrigger.menuClosed);
    placeholder.ref.destroy();
  }
}

interface IComponentPlaceholder {
  componentPortal: ComponentPortal<MenuTriggerPlaceholderComponent>;
  ref: ComponentRef<MenuTriggerPlaceholderComponent>;
}
