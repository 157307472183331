import { roundTo2Decimals } from '@principle-theorem/accounting';
import { TransactionOperators } from '@principle-theorem/principle-core';
import {
  IBrand,
  IInvoice,
  IPractice,
  ITransaction,
  InvoiceStatus,
  PatientCollection,
} from '@principle-theorem/principle-core/interfaces';
import {
  ArchivedDocument,
  CollectionReference,
  DataCollection,
  DocumentArchive,
  DocumentReference,
  Firestore,
  IDataTable,
  ISODateType,
  ITimePeriod,
  Timestamp,
  WithRef,
  collectionGroupQuery,
  isDocRef,
  resolveSequentially,
  resolveWithConcurrency,
  toISODate,
  toMoment,
  toTimestamp,
  where,
} from '@principle-theorem/shared';
import { compact, toPairs } from 'lodash';
import { Brand } from '../models/brand';
import { Invoice } from '../models/invoice/invoice';
import { dateRangeToPracticeTimezone } from './helpers';

interface IVersionsAsOfDate {
  invoice: IInvoice;
  transactions: ITransaction[];
}

interface IDebtorsAsOfDateRow {
  invoiceCreated: ISODateType;
  invoiceReference: string;
  invoiceURL: string;
  patientName: string;
  patientURL: string;
  invoicePractice: string;
  invoiceIssued: ISODateType;
  invoiceDue: ISODateType;
  invoiceAmount: string;
  paidAsOfDate: string;
  outstandingAmount: string;
}

const headersMap: {
  [key in keyof IDebtorsAsOfDateRow]: string;
} = {
  invoiceCreated: 'Created',
  invoiceReference: 'Invoice',
  invoiceURL: 'Invoice URL',
  patientName: 'Patient',
  patientURL: 'Patient URL',
  invoicePractice: 'Practice',
  invoiceIssued: 'Issued',
  invoiceDue: 'Due',
  invoiceAmount: 'Invoice Amount',
  paidAsOfDate: 'Paid as of Date',
  outstandingAmount: 'Outstanding Amount',
};

export class DebtorsAsOfDate {
  async run(
    brandRef: DocumentReference<IBrand>,
    dateRange: ITimePeriod,
    appHost: string,
    concurrency: number = 25
  ): Promise<IDataTable<IDebtorsAsOfDateRow>[]> {
    const brand = await Firestore.getDoc(brandRef);
    const practices = await Firestore.getDocs(
      Brand.practiceCol({ ref: brandRef })
    );
    const practiceTables = await resolveSequentially(
      practices,
      async (practice) => {
        const practiceRange = dateRangeToPracticeTimezone(practice, dateRange);
        const invoices = await this._getInvoicesCreatedBeforeDate(
          practice.ref,
          practiceRange.to
        );
        const rows = await resolveWithConcurrency(
          invoices,
          concurrency,
          (invoice) =>
            this._buildInvoiceRow(invoice, practiceRange.to, brand, appHost)
        );
        return this._toDataTable(practice.name, compact(rows));
      }
    );
    return practiceTables;
  }

  private async _getInvoicesCreatedBeforeDate(
    practiceRef: DocumentReference<IPractice>,
    asOfDate: moment.Moment
  ): Promise<WithRef<IInvoice>[]> {
    const invoiceQuery = collectionGroupQuery<IInvoice>(
      PatientCollection.Invoices,
      where('practice.ref', '==', practiceRef),
      where('createdAt', '<=', toTimestamp(asOfDate))
    );
    return Firestore.getDocs(invoiceQuery);
  }

  private async _buildInvoiceRow(
    currentInvoice: WithRef<IInvoice>,
    asOfMoment: moment.Moment,
    brand: WithRef<IBrand>,
    appHost: string
  ): Promise<IDebtorsAsOfDateRow | undefined> {
    const asOfDate = toTimestamp(asOfMoment);
    const data = await this._resolveDataAsOfDate(currentInvoice, asOfDate);
    if (!data) {
      return;
    }
    const statusAsOfDate = Invoice.statusAsOfDate(data.invoice, asOfDate);
    const skipInvoiceStatus = [
      InvoiceStatus.Draft,
      InvoiceStatus.WrittenOff,
      InvoiceStatus.Cancelled,
    ];
    if (
      data.invoice.deleted ||
      !statusAsOfDate ||
      skipInvoiceStatus.includes(statusAsOfDate.status)
    ) {
      return;
    }

    const transactions = new TransactionOperators(data.transactions);

    const invoiceAmount = roundTo2Decimals(Invoice.total(currentInvoice));
    const paidAsOfDate = roundTo2Decimals(transactions.paidToDate());
    const outstandingAmount = roundTo2Decimals(invoiceAmount - paidAsOfDate);

    if (outstandingAmount <= 0) {
      return;
    }

    const patientRef = Invoice.patientDocRef(currentInvoice);
    const patient = await Firestore.getDoc(patientRef);
    const practice = await Firestore.getDoc(data.invoice.practice.ref);

    const patientUrl = [brand.slug, 'patients', patientRef];
    const invoiceUrl = [...patientUrl, 'account/invoices', currentInvoice.ref];

    return {
      invoiceCreated: this._toISODate(data.invoice.createdAt),
      invoiceReference: data.invoice.reference,
      invoiceURL: this._toLink(appHost, invoiceUrl),
      patientName: patient.name,
      patientURL: this._toLink(appHost, patientUrl),
      invoicePractice: practice.name,
      invoiceIssued: this._toISODate(data.invoice.issuedAt),
      invoiceDue: this._toISODate(data.invoice.due),
      invoiceAmount: DataCollection.formatCurrency(invoiceAmount),
      paidAsOfDate: DataCollection.formatCurrency(paidAsOfDate),
      outstandingAmount: DataCollection.formatCurrency(outstandingAmount),
    };
  }

  private async _resolveDataAsOfDate(
    currentInvoice: WithRef<IInvoice>,
    asOfDate: Timestamp
  ): Promise<IVersionsAsOfDate | undefined> {
    const invoice = await this._resolveVersionAsOfDate(
      currentInvoice,
      Invoice.amendmentHistoryCol(currentInvoice),
      asOfDate
    );
    if (!invoice) {
      return;
    }
    const transactions = await this._getTransactionsAsOfDate(
      currentInvoice,
      asOfDate
    );
    return { invoice, transactions };
  }

  private async _getTransactionsAsOfDate(
    currentInvoice: WithRef<IInvoice>,
    asOfDate: Timestamp
  ): Promise<ITransaction[]> {
    const transactions = await Invoice.getTransactions(currentInvoice);
    const asOfMoment = toMoment(asOfDate);
    const transactionsAsOfDate = transactions.filter((transaction) =>
      toMoment(transaction.createdAt).isSameOrBefore(asOfMoment)
    );
    return compact(transactionsAsOfDate);
  }

  private async _resolveVersionAsOfDate<T>(
    currentDocument: WithRef<T>,
    archiveCollection: CollectionReference<ArchivedDocument<T>>,
    asOfDate: Timestamp
  ): Promise<T | undefined> {
    if (toMoment(currentDocument.createdAt).isAfter(toMoment(asOfDate))) {
      return;
    }
    const archivedInvoices = await Firestore.getDocs(archiveCollection);
    const archivedVersion = DocumentArchive.findVersionAsOfDate(
      archivedInvoices,
      asOfDate
    );
    return archivedVersion ?? currentDocument;
  }

  private _toDataTable(
    practiceName: string,
    practiceRows: IDebtorsAsOfDateRow[]
  ): IDataTable<IDebtorsAsOfDateRow> {
    return {
      name: `Practice - ${practiceName}`,
      data: practiceRows,
      columns: toPairs(headersMap).map(([key, header]) => ({ key, header })),
    };
  }

  private _toISODate(timestamp: Timestamp | undefined): string {
    return timestamp ? toISODate(timestamp) : '';
  }

  private _toLink(
    appHostUrl: string,
    segments: (DocumentReference | string)[]
  ): string {
    return [appHostUrl, ...segments]
      .map((segment) => (isDocRef(segment) ? segment.id : segment))
      .join('/');
  }
}
