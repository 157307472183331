import {
  ITranslationMap,
  PatientStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  type AtLeast,
  getEnumValues,
  type IXSLXExport,
  type XSLXCell,
} from '@principle-theorem/shared';
import type { Column } from 'exceljs';
import {
  type ID4WPatientStatus,
  PatientStatusSourceEntity,
} from '../../source/entities/patient-status';

export interface IPatientStatusXSLX {
  id: string;
  name: string;
  mapTo: PatientStatus;
}

export const PATIENT_STATUS_HEADERS: AtLeast<Column, 'key' | 'header'>[] = [
  {
    key: 'id',
    header: 'Id',
  },
  {
    key: 'name',
    header: 'Name',
    width: 30,
  },
  {
    key: 'mapTo',
    header: 'Map To',
    width: 30,
  },
];

export class PatientStatusesToXSLX
  implements IXSLXExport<ID4WPatientStatus, IPatientStatusXSLX>
{
  headers = PATIENT_STATUS_HEADERS;

  constructor(
    private _existingMappings: ITranslationMap<object, PatientStatus>[],
    private _suggestedMappings: { [key: string]: string }
  ) {}

  translate(
    records: ID4WPatientStatus[]
  ): Record<keyof IPatientStatusXSLX, XSLXCell>[] {
    const options = getEnumValues(PatientStatus).sort();
    const source = new PatientStatusSourceEntity();
    return records.map((record) => {
      const sourceId = source.getSourceRecordId(record).toString();
      const existingRecord = this._existingMappings.find(
        (existingMapping) => existingMapping.sourceIdentifier === sourceId
      );

      const suggestedMappingTreatmentName =
        this._suggestedMappings[record.name] ?? undefined;
      const mappedStatus =
        existingRecord?.destinationValue ?? suggestedMappingTreatmentName;

      return {
        id: {
          value: sourceId,
        },
        name: {
          value: record.name,
        },
        mapTo: {
          value: mappedStatus,
          dataValidation: {
            type: 'list',
            formulae: [`"${options.join(',')}"`],
          },
        },
      };
    });
  }
}
