import { DocumentReference } from '@principle-theorem/shared';
import {
  ITemplateDefinition,
  TemplateScope,
  TemplateType,
} from '@principle-theorem/principle-core/interfaces';

import {
  initVersionedSchema,
  unserialiseRawSchema,
} from '@principle-theorem/editor';
import { TemplateDefinition } from './template-definition';

export enum DefaultDocumentTemplate {
  OnlineBookingConfirmation = 'Online Booking Confirmation',
  MedicalCertificate = 'Medical Certificate',
  ManualSMSConfirmation = 'Manual SMS Confirmation',
  ManualMedicalHistoryFormLink = 'Manual Medical History Form Link',
  TwoDaySMSConfirmation = '2-day SMS Confirmation',
}

const twoDaySMSReminderText = `Hi {{ patientFirstName }}, your dental appointment with {{ practiceName }} is on {{ appointmentStartDate }}. Please click the link to confirm: {{ appointmentConfirmationUrl }} or call the practice on {{ practicePhoneNumber }} to reschedule/cancel your appointment. Thank you!`;

const DEFAULT_DOCUMENT_TEMPLATES: Partial<ITemplateDefinition>[] = [
  {
    name: DefaultDocumentTemplate.OnlineBookingConfirmation,
    scope: TemplateScope.Appointment,
    content: initVersionedSchema(
      unserialiseRawSchema(
        `{"type":"doc","content":[{"type":"paragraph","attrs":{"align":""},"content":[{"type":"text","text":"Hi {{ patientFirstName }}, your online booking request has been scheduled for {{ appointmentStartDate }}. We look forward to seeing you soon at {{ practiceName }}!"}]}]}`
      )
    ),
  },
  {
    name: DefaultDocumentTemplate.MedicalCertificate,
    scope: TemplateScope.Appointment,
    type: TemplateType.Html,
    content: initVersionedSchema(
      unserialiseRawSchema(
        `{"type":"doc","content":[{"type":"paragraph","attrs":{"align":""},"content":[{"type":"text","text":"{{ nowDate }}"}]},{"type":"paragraph","attrs":{"align":""}},{"type":"paragraph","attrs":{"align":""},"content":[{"type":"text","text":"To Whom It May Concern,"}]},{"type":"paragraph","attrs":{"align":""},"content":[{"type":"text","text":" "}]},{"type":"paragraph","attrs":{"align":""},"content":[{"type":"text","marks":[{"type":"bold"}],"text":"RE:"},{"type":"text","text":" {{ patientFullName }}         "},{"type":"text","marks":[{"type":"bold"}],"text":"DOB:"},{"type":"text","text":" {{ patientDateOfBirth }}"}]},{"type":"paragraph","attrs":{"align":""}},{"type":"paragraph","attrs":{"align":""},"content":[{"type":"text","text":"This is to certify that {{ patientFullName }} has presented for dental treatment today and I believe {{ patientFirstName }} is unfit for work for the duration of "},{"type":"text","marks":[{"type":"italic"}],"text":"<Date Range>"},{"type":"text","text":" inclusive."}]},{"type":"paragraph","attrs":{}},{"type":"paragraph","attrs":{},"content":[{"type":"text","text":"Kind Regards,"}]},{"type":"paragraph","attrs":{"align":""},"content":[{"type":"text","text":"{{ appointmentPractitionerFullName }}"}]},{"type":"paragraph","attrs":{"align":""}},{"type":"paragraph","attrs":{"align":""},"content":[{"type":"text","text":"{{ practiceName }}"}]},{"type":"paragraph","attrs":{"align":""},"content":[{"type":"text","text":"{{ practiceAddress }}"}]},{"type":"paragraph","attrs":{"align":""},"content":[{"type":"text","text":"{{ practicePhoneNumber }}"}]},{"type":"paragraph","attrs":{"align":""},"content":[{"type":"text","text":"{{ practiceEmail }}"}]}]}`
      )
    ),
  },
  {
    name: DefaultDocumentTemplate.ManualSMSConfirmation,
    scope: TemplateScope.Appointment,
    content: initVersionedSchema(
      unserialiseRawSchema(
        `{"type":"doc","content":[{"type":"paragraph","attrs":{"align":""},"content":[{"type":"text","text":"Hi {{ patientFirstName }}, your dental appointment with {{ practiceName }} is on {{ appointmentStartDate }}. Please click the link to confirm: {{ appointmentConfirmationUrl }} or call the practice on {{ practicePhoneNumber }}  to reschedule/cancel your appointment. Thank you!"}]}]}`
      )
    ),
  },
  {
    name: DefaultDocumentTemplate.ManualMedicalHistoryFormLink,
    scope: TemplateScope.Appointment,
    content: initVersionedSchema(
      unserialiseRawSchema(
        `{"type":"doc","content":[{"type":"paragraph","attrs":{"align":""},"content":[{"type":"text","text":"Hi {{ patientFirstName }}, we're looking forward to seeing you at  {{ practiceName }}. "}]},{"type":"paragraph","attrs":{"align":""},"content":[{"type":"text","text":"We'd really appreciate if you could  update your Medical History Form ahead of your appointment here: {{ medicalHistoryLink }}."}]},{"type":"paragraph","attrs":{"align":""},"content":[{"type":"text","text":" Thank you!"}]}]}`
      )
    ),
  },
  {
    name: DefaultDocumentTemplate.TwoDaySMSConfirmation,
    scope: TemplateScope.Appointment,
    content: initVersionedSchema(twoDaySMSReminderText),
    renderedTemplate: {
      text: twoDaySMSReminderText,
      html: twoDaySMSReminderText,
    },
  },
];

export function getDefaultDocumentTemplates(
  ownerScope: DocumentReference
): ITemplateDefinition[] {
  return DEFAULT_DOCUMENT_TEMPLATES.map((template) =>
    TemplateDefinition.init({ ...template, ownerScope })
  );
}
