import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  type OnDestroy,
} from '@angular/core';
import {
  type ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { type MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  InputSearchFilter,
  toSearchStream,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { type IReferralSource } from '@principle-theorem/principle-core/interfaces';
import { guardFilter } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReferrerSelectorStore } from './referrer-selector.store';

@Component({
    selector: 'pr-referrer-selector',
    templateUrl: './referrer-selector.component.html',
    styleUrls: ['./referrer-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ReferrerSelectorComponent),
        },
    ],
    standalone: false
})
export class ReferrerSelectorComponent
  implements ControlValueAccessor, OnDestroy
{
  private _onDestroy$: Subject<void> = new Subject();
  onChange: (_: unknown) => void;
  onTouched: (_: unknown) => void;
  trackByReferrer = TrackByFunctions.field<IReferralSource>('name');
  searchCtrl = new TypedFormControl<string>();
  referrerSearchFilter: InputSearchFilter<IReferralSource>;
  isRequired$ = new BehaviorSubject<boolean>(false);

  constructor(private _store: ReferrerSelectorStore) {
    this._store.loadReferrers(
      this.searchCtrl.valueChanges.pipe(guardFilter(isString))
    );

    this.referrerSearchFilter = new InputSearchFilter<IReferralSource>(
      this._store.referralSources$,
      toSearchStream(this.searchCtrl),
      ['name']
    );

    this.isRequired$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((isRequired) => {
        if (isRequired) {
          this.searchCtrl.setValidators(Validators.required);
          this.searchCtrl.updateValueAndValidity();
        }
      });
  }

  @Input()
  set required(required: BooleanInput) {
    this.isRequired$.next(coerceBooleanProperty(required));
  }

  optionSelected(event: MatAutocompleteSelectedEvent): void {
    if (!event.option.value) {
      return;
    }
    this.onChange(event.option.value);
  }

  blur(): void {
    if (isString(this.searchCtrl.value)) {
      this.searchCtrl.setValue('', { emitEvent: false });
      this.onChange(undefined);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  writeValue(value: string): void {
    this.searchCtrl.setValue(value ?? '', { emitEvent: false });
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.searchCtrl.disable();
      return;
    }
    this.searchCtrl.enable();
  }

  displaySelected(referrer: IReferralSource): string {
    if (!referrer) {
      return '';
    }
    return referrer.name;
  }
}
