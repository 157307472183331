import { MeasureFormatter } from '@principle-theorem/principle-core/interfaces';
import { ITreatmentPlanCompletedFact } from '@principle-theorem/reporting/interfaces';
import { BigQueryTable } from '../../big-query-tables';
import {
  BrandQueryScopeConfig,
  DateRangeQueryScopeConfig,
  PracticeQueryScopeConfig,
} from '../../query-scope-config/query-scope-configs';
import { BaseFactMeasures } from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import { BrandDimensionMeasures } from '../dimensions/brand-dimension';
import { PatientDimensionMeasures } from '../dimensions/patient-dimension';
import { PracticeDimensionMeasures } from '../dimensions/practice-dimension';
import { ReferrerDimensionMeasures } from '../dimensions/referrer-dimension';
import { StafferDimensionMeasures } from '../dimensions/staffer-dimension';
import { TreatmentPlanDimensionMeasures } from '../dimensions/treatment-plan-dimension';
import {
  ComparableProperties,
  MeasureTransformMap,
} from '../measure-properties';
import { CanBeChartedProperty } from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';

// export class TreatmentPlanCompletedFact extends BaseDimension<Fact>
//   implements
//   NestedDimensionTransformMap<NestedDimensionProperties<Fact>>,
//   DimensionTransformMap<DateProperties<Fact>>,
//   DimensionTransformMap<ArrayProperties<Fact>>,
//   DimensionTransformMap<ComparableProperties<Fact>> {

//   // TODO: Add correct typing `string[]` once PR is merged
//   get invoices(): Dimension<Fact, any> {
//     return this.ndx.dimension((facts: Fact): any => {
//       return flatMap(facts.invoices, (invoice: IInvoice) => toMoment(invoice.paidAt));
//     }, true);
//   }

//   // TODO: Add correct typing `string[]` once PR is merged
//   get appointments(): Dimension<Fact, any> {
//     return this.ndx.dimension((facts: Fact): any => {
//       return flatMap(facts.appointments, (appointment: IAppointment) => appointment.status);
//     }, true);
//   }

//   get finalCost(): Dimension<Fact, number> {
//     return this.ndx.dimension((facts: Fact): number => {
//       return facts.finalCost;
//     });
//   }

//   get initialCost(): Dimension<Fact, number> {
//     return this.ndx.dimension((facts: Fact): number => {
//       return facts.initialCost;
//     });
//   }

//   get planChanges(): Dimension<Fact, number> {
//     return this.ndx.dimension((facts: Fact): number => {
//       return facts.planChanges;
//     });
//   }

//   get totalDuration(): Dimension<Fact, number> {
//     return this.ndx.dimension((facts: Fact): number => {
//       return facts.totalDuration;
//     });
//   }
// }

export class TreatmentPlanCompletedFactMeasures
  extends BaseFactMeasures
  implements
    MeasureTransformMap<ComparableProperties<ITreatmentPlanCompletedFact>>
{
  readonly id = 'treatmentPlanCompleted';
  readonly table = BigQueryTable.TreatmentPlanCompleted;
  readonly name = 'Completed Treatment Plan';
  scopes = [
    new BrandQueryScopeConfig(),
    new PracticeQueryScopeConfig(),
    new DateRangeQueryScopeConfig(),
  ];

  get practitioner(): StafferDimensionMeasures {
    return new StafferDimensionMeasures(BigQueryTable.Staff, 'practitioner', {
      sourceJoinKey: MeasurePath.docRef('practitioner.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get treatmentPlan(): TreatmentPlanDimensionMeasures {
    return new TreatmentPlanDimensionMeasures(
      BigQueryTable.TreatmentPlans,
      'treatmentPlan',
      {
        sourceJoinKey: MeasurePath.docRef('treatmentPlan.ref'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get practice(): PracticeDimensionMeasures {
    return new PracticeDimensionMeasures(BigQueryTable.Practices, 'practice', {
      sourceJoinKey: MeasurePath.docRef('practice.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get patient(): PatientDimensionMeasures {
    return new PatientDimensionMeasures(BigQueryTable.Patients, 'patient', {
      sourceJoinKey: MeasurePath.docRef('patient.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get referrer(): ReferrerDimensionMeasures {
    return new ReferrerDimensionMeasures(this.table, 'referrer');
  }

  get brand(): BrandDimensionMeasures {
    return new BrandDimensionMeasures(BigQueryTable.Brands, 'brand', {
      sourceJoinKey: MeasurePath.docRef('brand.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get finalCost(): CanBeChartedProperty {
    const propertyName = 'finalCost';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Final Cost',
        summary: '',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get initialCost(): CanBeChartedProperty {
    const propertyName = 'initialCost';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Initial Cost',
        summary: '',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get planChanges(): CanBeChartedProperty {
    const propertyName = 'planChanges';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Plan Changes',
        summary: '',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get totalDuration(): CanBeChartedProperty {
    const propertyName = 'totalDuration';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Total Duration',
        summary: '',
        formatter: MeasureFormatter.Minutes,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }
}
