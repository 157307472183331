import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DYNAMIC_COMPONENT_DATA } from '@principle-theorem/ng-shared';
import { Observable, of } from 'rxjs';
import { IIntegrationActionData } from '../../../../integration-action-data';
import { map } from 'rxjs/operators';
import { CliniviewFeatureService } from '../cliniview-feature.service';
import { BridgeDeviceSelectorService } from '../../../bridge-device-selector-dialog/bridge-device-selector.service';
import { CLINIVIEW_INTEGRATION } from '@principle-theorem/principle-bridge-core';

@Component({
    selector: 'pr-cliniview-open-patient',
    templateUrl: './cliniview-open-patient.component.html',
    styleUrl: './cliniview-open-patient.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CliniviewOpenPatientComponent {
  isDisabled$: Observable<boolean>;

  constructor(
    @Inject(DYNAMIC_COMPONENT_DATA)
    private _data: IIntegrationActionData,
    private _cliniview: CliniviewFeatureService,
    private _bridgeDeviceSelector: BridgeDeviceSelectorService
  ) {
    this.isDisabled$ = of(this._data.patient).pipe(
      map((patient) => !patient.dateOfBirth)
    );
  }

  async openPatient(): Promise<void> {
    const deviceRef = await this._bridgeDeviceSelector.selectDevice(
      CLINIVIEW_INTEGRATION
    );
    if (!deviceRef) {
      return;
    }

    await this._cliniview.openPatient(this._data.patient, deviceRef);
  }
}
