import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  BasicDialogService,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { Patient } from '@principle-theorem/principle-core';
import { IPatient } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';

export interface IMergePatientDialogData {
  patient: WithRef<IPatient>;
}

@Component({
    selector: 'pr-merge-patient-dialog',
    templateUrl: './merge-patient-dialog.component.html',
    styleUrls: ['./merge-patient-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MergePatientDialogComponent {
  patientCtrl = new TypedFormControl<WithRef<IPatient>>();

  constructor(
    public dialogRef: MatDialogRef<
      MergePatientDialogComponent,
      WithRef<IPatient>
    >,
    @Inject(MAT_DIALOG_DATA) public data: IMergePatientDialogData,
    private _basicDialog: BasicDialogService
  ) {}

  async mergePatients(): Promise<void> {
    if (!this.patientCtrl.valid) {
      return;
    }

    const primaryPatient = this.patientCtrl.value;

    if (
      primaryPatient.dateOfBirth !== this.data.patient.dateOfBirth ||
      primaryPatient.name !== this.data.patient.name
    ) {
      const confirmed = await this._basicDialog.confirm({
        prompt: `Are you sure you want to merge these two patients? Their personal details don't match exactly.`,
        title: `Confirm merging of patients`,
        submitLabel: 'Yes, merge these two patients',
        submitColor: 'warn',
        cancelLabel: 'Cancel',
      });
      if (!confirmed) {
        return;
      }
    }

    await Patient.markAsDuplicate(this.data.patient, primaryPatient);
    this.dialogRef.close();
  }
}
