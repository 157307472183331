import {
  formatCurrency,
  formatPercent,
  getCurrencySymbol,
} from '@angular/common';
import {
  IMeasureMetadata,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import { type DataPointValue } from '@principle-theorem/reporting';
import {
  DAY_MONTH_YEAR_FORMAT,
  TIME_FORMAT,
  isArray,
  toInt,
} from '@principle-theorem/shared';
import { isBoolean, isString } from 'lodash';
import * as moment from 'moment-timezone';

export type DataPointValueFormatterFn = (value: DataPointValue) => string;
const defaultDateFormat = `${DAY_MONTH_YEAR_FORMAT} - ${TIME_FORMAT}`;

export class DataPointValueFormatter {
  static readonly locale = 'en-AU';

  static getFormatter(
    formatter?: MeasureFormatter,
    formatterValue?: IMeasureMetadata['formatterValue']
  ): DataPointValueFormatterFn {
    return (value) =>
      DataPointValueFormatter.format(value, formatter, formatterValue);
  }

  static format(
    value: DataPointValue,
    formatter?: MeasureFormatter,
    formatterValue?: IMeasureMetadata['formatterValue']
  ): string {
    if (isArray(value)) {
      return value
        .map((dataPoint) =>
          DataPointValueFormatter.format(
            dataPoint as DataPointValue,
            formatter,
            formatterValue
          )
        )
        .join(', ');
    }

    if (formatterValue && !isString(formatterValue)) {
      return formatterValue(value).toString();
    }

    switch (formatter) {
      case MeasureFormatter.Boolean:
        return DataPointValueFormatter.formatBoolean(value);
      case MeasureFormatter.Percentage:
        return DataPointValueFormatter.formatPercentage(value);
      case MeasureFormatter.Currency:
        return DataPointValueFormatter.formatCurrency(value);
      case MeasureFormatter.Number:
        return DataPointValueFormatter.formatNumber(value);
      case MeasureFormatter.Minutes:
        return DataPointValueFormatter.formatMins(value);
      case MeasureFormatter.Prefix:
        return DataPointValueFormatter.formatPrefix(value, formatterValue);
      case MeasureFormatter.Suffix:
        return DataPointValueFormatter.formatSuffix(value, formatterValue);
      case MeasureFormatter.Timestamp:
        return DataPointValueFormatter.formatTimestamp(value, formatterValue);
      case MeasureFormatter.Day:
        return DataPointValueFormatter.formatTimestamp(
          value,
          DAY_MONTH_YEAR_FORMAT
        );
      case MeasureFormatter.Time:
        return DataPointValueFormatter.formatTimestamp(value, TIME_FORMAT);
      default:
        return value.toString();
    }
  }

  static formatBoolean(value: string | number | boolean): string {
    return value ? 'Yes' : 'No';
  }

  static formatCurrency(value: DataPointValue, decimals: number = 2): string {
    const num = DataPointValueFormatter.coerceToNumber(value);
    const digitsInfo = `0.${decimals}-${decimals}`;
    const currencyCode = 'AUD';
    const currency = getCurrencySymbol(
      currencyCode,
      'narrow',
      DataPointValueFormatter.locale
    );
    return formatCurrency(
      num,
      DataPointValueFormatter.locale,
      currency,
      currencyCode,
      digitsInfo
    );
  }

  static formatPercentage(value: DataPointValue): string {
    return formatPercent(
      DataPointValueFormatter.coerceToNumber(value),
      DataPointValueFormatter.locale
    );
  }

  static formatNumber(value: DataPointValue): string {
    return DataPointValueFormatter.coerceToNumber(value).toLocaleString();
  }

  static formatPrefix(value: DataPointValue, formatterValue?: string): string {
    const num = DataPointValueFormatter.coerceToNumber(value);
    if (!formatterValue) {
      // eslint-disable-next-line no-console
      console.error('No prefix value');
      return num.toString();
    }
    return `${formatterValue} ${num}`;
  }

  static formatSuffix(value: DataPointValue, formatterValue?: string): string {
    const num = DataPointValueFormatter.coerceToNumber(value);
    if (!formatterValue) {
      // eslint-disable-next-line no-console
      console.error('No suffix value');
      return num.toString();
    }
    return `${num} ${formatterValue}`;
  }

  static formatMins(value: DataPointValue): string {
    return DataPointValueFormatter.formatSuffix(toInt(value), 'mins');
  }

  static formatTimestamp(
    value: DataPointValue,
    formatterValue: string = defaultDateFormat
  ): string {
    if (!value) {
      return '';
    }
    if (!isString(value)) {
      // eslint-disable-next-line no-console
      console.error('Cannot format timestamp', value);
      return value.toString();
    }
    return moment(value).format(formatterValue);
  }

  static coerceToNumber(value: DataPointValue): number {
    if (isString(value)) {
      return Number(value);
    }
    if (isBoolean(value)) {
      return value ? 1 : 0;
    }
    return value;
  }
}
