import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  type AppointmentStatus,
  APPOINTMENT_STATUS_COLOUR_MAP,
} from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-treatment-step-appointment-status',
  templateUrl: './treatment-step-appointment-status.component.html',
  styleUrls: ['./treatment-step-appointment-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentStepAppointmentStatusComponent {
  @Input() status: AppointmentStatus;
  isDense = false;
  isInline = false;
  statusColourMap = APPOINTMENT_STATUS_COLOUR_MAP;

  @Input()
  set dense(dense: BooleanInput) {
    this.isDense = coerceBooleanProperty(dense);
  }

  @Input()
  set inline(inline: BooleanInput) {
    this.isInline = coerceBooleanProperty(inline);
  }
}
