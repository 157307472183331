<ng-container *ngIf="notification$ | ngrxPush as notification">
  <ng-container *ngIf="content$ | ngrxPush as content">
    <ng-container *ngIf="preview$ | ngrxPush; else default">
      <div fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex>
          <div fxLayout="column" fxLayoutAlign="start start">
            <div
              ptEditorView
              [extensions]="extensions$ | ngrxPush"
              [content]="content$ | ngrxPush"
            ></div>
            <small class="date">{{ distance$ | ngrxPush }}</small>
          </div>
        </div>

        <div>
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="markRead(notification)">
              <mat-icon>done</mat-icon>
              <span>Mark as Read</span>
            </button>
            <button
              *ngIf="canDisable"
              mat-menu-item
              (click)="disableNotification(notification)"
            >
              <mat-icon>notifications_off</mat-icon>
              <span>Disable Alerts Like This</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </ng-container>

    <ng-template #default>
      <div fxLayout="row" fxLayoutAlign="start center" class="gap-4">
        <pt-colour-sliver
          [colour]="sliverColour$ | ngrxPush"
          [matTooltip]="sliverTooltip$ | ngrxPush"
          class="self-stretch"
        />
        <div fxFlex>
          <div class="my-2 flex flex-col items-start justify-start">
            <div
              ptEditorView
              [content]="content"
              [extensions]="extensions$ | ngrxPush"
            ></div>
            <small class="date">{{ distance$ | ngrxPush }}</small>
          </div>
        </div>

        <pt-buttons-container padding="all-round">
          <button
            [disabled]="notification.viewed"
            mat-icon-button
            (click)="markRead(notification)"
            matTooltip="Mark as Read"
          >
            <mat-icon>done</mat-icon>
          </button>
          <button
            *ngIf="canDisable"
            mat-icon-button
            (click)="disableNotification(notification)"
            matTooltip="Disable Alerts Like This"
          >
            <mat-icon>notifications_off</mat-icon>
          </button>
        </pt-buttons-container>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
