<table class="w-full" mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let terminal">
      {{ terminal.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="merchantId">
    <th mat-header-cell *matHeaderCellDef>Merchant Id</th>
    <td mat-cell *matCellDef="let terminal">
      {{ terminal.merchantId }}
    </td>
  </ng-container>

  <ng-container matColumnDef="terminalId">
    <th mat-header-cell *matHeaderCellDef>Terminal Id</th>
    <td mat-cell *matCellDef="let terminal">
      {{ terminal.terminalId }}
    </td>
  </ng-container>

  <ng-container matColumnDef="practitioner">
    <th mat-header-cell *matHeaderCellDef>Associated Practitioner</th>
    <td mat-cell *matCellDef="let terminal">
      @if (terminal.practitionerRef) {
        <pr-staffer-display
          *ngIf="
            terminal.practitionerRef
              | map: globalStore.getStaffer$ : globalStore
              | async as staffer
          "
          [staffer]="staffer"
        />
      } @else {
        <span class="mat-caption opacity-50">No Practitioner Associated</span>
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="bridgeDevice">
    <th mat-header-cell *matHeaderCellDef class="w-28">Bridge Device</th>
    <td mat-cell *matCellDef="let terminal" class="w-28">
      {{ terminal | map: bridgeDeviceName$ | async }}
    </td>
  </ng-container>

  <ng-container matColumnDef="lastActive">
    <th mat-header-cell *matHeaderCellDef class="w-28">Last Synced</th>
    <td mat-cell *matCellDef="let terminal" class="w-28">
      {{ terminal.lastActive | moment | amDateFormat: dateFormat }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>
      <button
        class="w-full"
        *ngIf="practice$ | async as practice"
        mat-flat-button
        color="primary"
        (click)="hicapsConnect.syncTerminalsForDevice(practice)"
      >
        Sync Terminals
      </button>
    </th>
    <td mat-cell *matCellDef="let terminal" class="!text-right">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <ng-template matMenuContent>
          <button mat-menu-item (click)="hicapsConnect.editTerminal(terminal)">
            <mat-icon color="accent">edit</mat-icon> Edit
          </button>
          <button
            mat-menu-item
            (click)="hicapsConnect.deleteTerminal(terminal)"
          >
            <mat-icon color="warn">delete</mat-icon> Delete
          </button>

          <button mat-menu-item (click)="hicapsConnect.testTerminal(terminal)">
            <mat-icon color="accent">sync</mat-icon> Test Terminal
          </button>
          <button mat-menu-item (click)="hicapsConnect.viewLogs(terminal)">
            <mat-icon color="accent">receipt_long</mat-icon> View Logs
          </button>
        </ng-template>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
