import { type InteractEvent } from '@interactjs/core/InteractEvent';
import { type ResizeEvent } from '@interactjs/types';
import { type SchedulingActions } from '@principle-theorem/ng-appointment/store';
import { stafferToNamedDoc, Event } from '@principle-theorem/principle-core';
import {
  EventType,
  type IEvent,
  type IPractice,
  type IStaffer,
  type ITimelineDisplayOptions,
  type ITimelineDataGroup,
  type ITimelineNode,
  ParticipantType,
  IScheduleSummaryEventable,
  isAppointmentEventType,
} from '@principle-theorem/principle-core/interfaces';
import {
  type ITimePeriod,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { InteractiveTimelineDisplayCalculator } from './interactive-timeline-display-calculator';
import { type ITimelineTapEvent } from './interactive-timeline-dropzone/interactive-timeline-dropzone.component';
import { type ITimelineNodeEvent } from './interactive-timeline-node/interactive-timeline-node.component';

export type CreateNode = ITimelineNode<
  IManageEventable | IScheduleSummaryEventable
> & {
  day: ITimePeriod;
  trackIndex: number;
  uid: string;
};

export interface IManageEventable {
  type: 'manage';
  event: IEvent;
}

export function isManageEventable(
  item: IScheduleSummaryEventable | IManageEventable
): item is IManageEventable {
  return 'type' in item && item.type === 'manage';
}

export interface IHandleDropSuccess {
  appointmentOptions: SchedulingActions.IRequestedAppointmentOption;
  createNode: CreateNode;
}

export interface ITimelineNodeSelection {
  event: ITimelineNodeEvent<InteractEvent | ResizeEvent | void>;
  item: ITimelineNode<IScheduleSummaryEventable>;
}

export class InteractiveTimelineLogic {
  static handleDrop(
    options: ITimelineDisplayOptions,
    selectedNode: ITimelineNodeSelection | undefined,
    practice: WithRef<IPractice> | undefined,
    event: ITimelineTapEvent,
    group: ITimelineDataGroup<IScheduleSummaryEventable, WithRef<IStaffer>>,
    day: ITimePeriod,
    initialTrackIndex: number
  ): IHandleDropSuccess | undefined {
    if (
      !practice ||
      !selectedNode ||
      !isAppointmentEventType(selectedNode.item.data.event.type)
    ) {
      return;
    }

    const isHorizontal =
      InteractiveTimelineDisplayCalculator.isHorizontal(options);
    const nodeTrackPosition = isHorizontal
      ? (event.event.layerY as number)
      : (event.event.layerX as number);

    const trackIndex =
      InteractiveTimelineDisplayCalculator.getTrackIndex(
        options,
        nodeTrackPosition
      ) + initialTrackIndex;

    const duration = Math.abs(
      selectedNode.item.from.diff(selectedNode.item.to, 'minutes')
    );

    const toTime = moment(event.time).add(duration, 'minutes');

    const newEvent = Event.init({
      type: EventType.Appointment,
      from: toTimestamp(event.time),
      to: toTimestamp(toTime),
      practice,
      participants: [
        {
          ...stafferToNamedDoc(group.group),
          type: ParticipantType.Staffer,
        },
        ...Event.patients(selectedNode.item.data.event),
      ],
    });

    const createNode = {
      trackIndex,
      uid: selectedNode.item.data.ref
        ? selectedNode.item.data.ref.id
        : selectedNode.item.data.uid,
      day,
      from: event.time,
      to: toTime,
      dragEnabled: true,
      resizeEnabled: true,
      data: {
        ...selectedNode.item.data,
        event: newEvent,
      },
    };
    const appointmentOptions = {
      params: {
        from: event.time,
        to: toTime,
        staffer: group.group.ref,
        practice: practice.ref,
      },
    };
    return { appointmentOptions, createNode };
  }
}
