import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { ChartedCondition } from '@principle-theorem/principle-core';
import {
  type AnyChartedItemConfiguration,
  type IChartedRef,
  type IChartedItem,
  type IChartedItemConfiguration,
  type IConditionConfiguration,
  isConditionConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import {
  type IChartedSurfaceProvider,
  type IChartSurfaceEvent,
} from '../add-charted-surface-provider';

export class AddConditionToChartProvider implements IChartedSurfaceProvider {
  constructor(private _chartStore: ChartFacade) {}

  canProvide(chartable: AnyChartedItemConfiguration): boolean {
    return isConditionConfiguration(chartable);
  }

  async addChartedItem(
    event: IChartSurfaceEvent,
    chartable: WithRef<IConditionConfiguration>
  ): Promise<void> {
    await this._chartStore.addCondition(
      ChartId.InAppointment,
      ChartedCondition.fromConfig(chartable),
      event.surfaces
    );
  }

  async removeSurfacesFromChartedItem(
    surfaceRefs: Partial<IChartedRef>[],
    item: IChartedItem<IChartedItemConfiguration>
  ): Promise<void> {
    await this._chartStore.removeChartedSurfaces(
      ChartId.InAppointment,
      surfaceRefs,
      item
    );
  }
}
