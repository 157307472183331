@if (patient$ | async; as patient) {
  <div class="mx-auto grid max-w-4xl grid-cols-1 gap-4 p-4 md:grid-cols-2">
    <div
      class="flex flex-col gap-4 rounded-md bg-white p-4 shadow md:col-span-2"
    >
      <div class="flex flex-row items-center gap-4">
        <h2 class="!m-0 flex grow text-lg !font-bold">Patient Forms</h2>
        <button mat-button color="primary" (click)="issueForm(patient)">
          <mat-icon>add</mat-icon>
          Generate Form
        </button>
      </div>

      <pr-patient-submitted-forms-list
        [patient]="patient"
        [imageEmptyState]="true"
        (formSelected)="openForm($event)"
      />
    </div>

    <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow">
      <h3 class="!m-0 !font-bold">Patient Forms Link</h3>
      <div class="max-w-prose">
        A patient viewing this link will be directed to fill out any generated
        forms. Once submitted, the form will disappear from the list. Send this
        link to a patient after generating a form you want them to fill out, or
        as a part of an automation.
      </div>
      <div class="flex max-w-prose grow">
        We recommend including this link in your appointment confirmation
        messaging and setting up automations to generate medical history and
        patient details forms when needed.
      </div>
      <div class="flex flex-col">
        <button
          mat-button
          color="primary"
          (click)="customForms.copyPatientFormsLink(patient)"
        >
          <mat-icon>link</mat-icon>
          Copy Patient Forms Link
        </button>
        <button
          mat-button
          color="primary"
          (click)="customForms.openPatientFormsLink(patient)"
        >
          <mat-icon>open_in_new</mat-icon>
          Open Patient Forms
        </button>
      </div>
    </div>

    <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow">
      <h3 class="!m-0 !font-bold">Medical History Form Link</h3>
      <div class="max-w-prose">
        A patient viewing this link will be directed to fill out a patient
        details form and a medical history form. The patient will always be
        required to fill out patient details and medical history before they can
        be submitted. You can send this link to a patient manually or as part of
        an automation.
      </div>
      <div class="flex max-w-prose grow">
        The patient will be unable to access other forms using this link.
      </div>
      <div class="flex flex-col">
        <button
          mat-button
          color="primary"
          (click)="customForms.copyMedicalHistoryFormLink(patient)"
        >
          <mat-icon>link</mat-icon>
          Copy Medical History Form Link
        </button>
        <button
          mat-button
          color="primary"
          (click)="customForms.openMedicalHistoryFormLink(patient)"
        >
          <mat-icon>open_in_new</mat-icon>
          Open Medical History Form
        </button>
      </div>
    </div>
  </div>
}
