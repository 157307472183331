import {
  IChartedServiceSmartGroup,
  IPricedServiceCodeEntry,
  IServiceSmartGroup,
  ServiceSmartGroupName,
  SMART_GROUPS,
} from '@principle-theorem/principle-core/interfaces';
import { asyncForEach, AtLeast, snapshot } from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';
import { FeeScheduleManager } from '../fees/fee-schedule/fee-schedule-manager';
import { PricedServiceCodeTranslator } from '../service-items/service-item';
import { PricedServiceCodeEntry } from './service-code';
import { getServiceCodeNumberString } from './service-provider';

export class ChartedServiceSmartGroup {
  static init(
    overrides: AtLeast<IChartedServiceSmartGroup, 'serviceCodeType' | 'scope'>
  ): IChartedServiceSmartGroup {
    return {
      uid: uuid(),
      title: '',
      description: '',
      serviceCodes: [],
      ...overrides,
    };
  }

  static getSelected(
    group: IChartedServiceSmartGroup
  ): IPricedServiceCodeEntry | undefined {
    if (!group.selected) {
      return undefined;
    }
    const selectedUid = group.selected;
    return group.serviceCodes.find(
      (item) =>
        getServiceCodeNumberString(item.code) ===
        getServiceCodeNumberString(selectedUid)
    );
  }

  static async applyFeeSchedule(
    serviceGroup: IChartedServiceSmartGroup,
    stepSmartGroupServiceCodes: IPricedServiceCodeEntry[],
    manager: FeeScheduleManager,
    convertServiceCodes: boolean,
    changingFeeSchedule: boolean
  ): Promise<IChartedServiceSmartGroup> {
    const feeSchedule = await snapshot(manager.currentSchedule$);
    const serviceCodes = await asyncForEach(
      serviceGroup.serviceCodes,
      (entry: IPricedServiceCodeEntry) =>
        PricedServiceCodeEntry.applyFeeSchedule(
          entry,
          stepSmartGroupServiceCodes,
          manager,
          convertServiceCodes,
          changingFeeSchedule
        )
    );

    const translatedSelected = serviceGroup.selected
      ? PricedServiceCodeTranslator.convertToType(
          {
            code: serviceGroup.selected,
            type:
              serviceGroup.serviceCodes[0].type ?? feeSchedule.serviceCodeType,
          },
          feeSchedule.serviceCodeType
        )
      : undefined;

    return {
      ...serviceGroup,
      serviceCodes,
      selected: translatedSelected?.code,
    };
  }
}

export function getSmartGroupByName(
  uid: ServiceSmartGroupName
): IServiceSmartGroup | undefined {
  return SMART_GROUPS.find((group) => group.uid === String(uid));
}
