<div
  #timelineWidth
  class="flex flex-1"
  [ngClass]="{
    'flex-row': isHorizontal,
    'flex-col': isVertical
  }"
>
  <div
    *ngFor="let hour of hours$ | ngrxPush; trackBy: trackByHour; first as first"
    class="hour flex flex-1 place-content-start items-stretch"
    [ngClass]="{
      'flex-col': isHorizontal,
      'flex-row': isVertical
    }"
  >
    <div class="label flex flex-1 flex-col place-content-center items-center">
      <ng-container *ngIf="!first">{{ hour }}</ng-container>
    </div>
    <div
      class="hour-increments flex"
      [ngClass]="{
        'flex-row': isHorizontal,
        'flex-col': isVertical
      }"
    >
      <div
        *ngIf="borderFlexSize$ | async as borderFlexSize"
        [ngClass]="{
          'flex-row': isHorizontal,
          'flex-col': isVertical
        }"
        [ngStyle]="{
          flex: '1 1 100%',
          'max-width': isHorizontal ? borderFlexSize + '%' : 'auto',
          'max-height': isVertical ? borderFlexSize + '%' : 'auto'
        }"
      ></div>
      <div
        *ngFor="
          let hourIncrement of hourIncrements$ | async;
          trackBy: trackByIncrement
        "
        class="flex flex-1 place-content-start items-center gap-1"
        [ngClass]="{
          'flex-col': isHorizontal,
          'flex-row': isVertical
        }"
        [ngStyle]="{
          flex: '1 1 100%',
          'max-width': isHorizontal
            ? (incrementFlexSize$ | async) + '%'
            : 'auto',
          'max-height': isVertical ? (incrementFlexSize$ | async) + '%' : 'auto'
        }"
      >
        <div>{{ hourIncrement }}</div>
        <div class="increment-line"></div>
      </div>
      <div
        *ngIf="borderFlexSize$ | async as borderFlexSize"
        [ngStyle]="{
          flex: '1 1 100%',
          'max-width': isHorizontal ? borderFlexSize + '%' : 'auto',
          'max-height': isVertical ? borderFlexSize + '%' : 'auto'
        }"
      ></div>
    </div>
  </div>
</div>
