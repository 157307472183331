<div class="flex flex-col gap-4">
  <div class="flex w-full flex-wrap items-center gap-2">
    <pr-tooth-selector
      [teeth]="selectedTeeth$ | async"
      (teethChanged)="selectedTeeth$.next($event)"
    />
    <pr-tooth-history-list-filters class="grow" />
  </div>
  <pr-tooth-history-list
    (openPlan)="openPlan.emit($event)"
    (openInvoice)="openInvoice.emit($event)"
  />
</div>
