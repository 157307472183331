import { PrincipleHicapsConnect } from '@principle-theorem/hicaps-connect';
import { HicapsConnectResponseCodes } from '@principle-theorem/principle-core';
import {
  AnyHicapsConnectExtendedData,
  HicapsConnectExtendedDataTypeGuard,
} from '@principle-theorem/principle-core/interfaces';

export class HicapsConnectReceipt {
  static hasExtendedDataToShowReceipt(
    extendedData: AnyHicapsConnectExtendedData
  ): boolean {
    const isError = PrincipleHicapsConnect.isErrorResponse();
    if (!extendedData.response || isError(extendedData.response)) {
      return false;
    }
    if (
      !HicapsConnectExtendedDataTypeGuard.isHealthFund(extendedData) &&
      !HicapsConnectExtendedDataTypeGuard.isHealthFundCancel(extendedData)
    ) {
      return false;
    }
    return HicapsConnectResponseCodes.isClaimSuccessful(
      extendedData.response.data.ResponseCode
    );
  }
}
