import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  CurrentScopeFacade,
  GlobalStoreService,
} from '@principle-theorem/ng-principle-shared';
import {
  ChartableSurfaceResolver,
  ChartedCondition,
  ChartedSurface,
  ConditionConfiguration,
  MultiTreatmentConfiguration,
  TreatmentConfiguration,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  type IBrand,
  type IChartedItemConfiguration,
  type IChartedSurface,
  type IConditionConfiguration,
  type IStaffer,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  filterUndefined,
  getDoc,
  patchDoc,
  serialise,
  snapshot,
  unserialise$,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { chartActions } from '..';
import {
  loadConditionConfigurationsSuccess,
  loadMultiTreatmentConfigurationsSuccess,
  loadTreatmentConfigurationsSuccess,
} from '../actions';
import {
  addQuickChartingItem,
  removeQuickChartingItem,
} from '../actions/quick-charting';
import { ChartFacade } from '../facades/chart.facade';
import { ChartedConfigurationFacade } from '../facades/charted-configuration.facade';
import { type ChartId } from '../reducers/active-charts/chart-context-state';

@Injectable()
export class ChartableConfigurationEffects {
  private _actions$ = inject(Actions);
  private _chartFacade = inject(ChartFacade);
  private _chartedConfigFacade = inject(ChartedConfigurationFacade);
  private _currentScopeFacade = inject(CurrentScopeFacade);
  private _globalStore = inject(GlobalStoreService);

  loadConditionConfigurations$: Observable<Action> = createEffect(() =>
    this._loadConditionConfigurations$()
  );
  loadTreatmentConfigurations$: Observable<Action> = createEffect(() =>
    this._loadTreatmentConfigurations$()
  );
  loadMultiTreatmentConfigurations$: Observable<Action> = createEffect(() =>
    this._loadMultiTreatmentConfigurations$()
  );
  addConditionConfiguration$ = createEffect(
    () => this._addConditionConfiguration$(),
    { dispatch: false }
  );
  addAndChartConditionConfiguration$ = createEffect(
    () => this._addAndChartConditionConfiguration$(),
    { dispatch: false }
  );
  addTreatmentConfiguration$ = createEffect(
    () => this._addTreatmentConfiguration$(),
    { dispatch: false }
  );
  addAndChartTreatmentConfiguration$ = createEffect(
    () => this._addAndChartTreatmentConfiguration$(),
    { dispatch: false }
  );
  updateQuickChartConfiguration$ = createEffect(
    () => this._updateQuickChartConfiguration$(),
    { dispatch: false }
  );

  constructor(private _snackBar: MatSnackBar) {}

  private _loadConditionConfigurations$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(chartActions.setChartingAs),
      unserialise$(),
      concatMap(() => this._globalStore.conditionConfigurations$),
      map((configs) =>
        loadConditionConfigurationsSuccess({
          configs: serialise(configs),
        })
      )
    );
  }

  private _loadTreatmentConfigurations$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(chartActions.setChartingAs),
      unserialise$(),
      concatMap(() =>
        this._currentScopeFacade.currentBrand$.pipe(
          filterUndefined(),
          switchMap((brand) => TreatmentConfiguration.all$(brand))
        )
      ),
      map((configs) =>
        loadTreatmentConfigurationsSuccess({
          configs: serialise(configs),
        })
      )
    );
  }

  private _loadMultiTreatmentConfigurations$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(chartActions.setChartingAs),
      unserialise$(),
      concatMap(() =>
        this._currentScopeFacade.currentBrand$.pipe(
          filterUndefined(),
          switchMap((brand) => MultiTreatmentConfiguration.all$(brand))
        )
      ),
      map((configs) =>
        loadMultiTreatmentConfigurationsSuccess({
          configs: serialise(configs),
        })
      )
    );
  }

  private _addTreatmentConfiguration$(): Observable<void> {
    return this._actions$.pipe(
      ofType(chartActions.addTreatmentConfiguration),
      unserialise$(),
      concatMap(async (action) => {
        await this._addTreatmentConfig(action.entity, action.config);
      })
    );
  }

  private _addAndChartTreatmentConfiguration$(): Observable<void> {
    return this._actions$.pipe(
      ofType(chartActions.addAndChartTreatmentConfiguration),
      unserialise$(),
      concatMap(async (action) => {
        const staffer = await snapshot(
          this._chartFacade.chartingAs$(action.id)
        );
        const brand = await snapshot(
          this._currentScopeFacade.currentBrand$.pipe(filterUndefined())
        );
        const feeSchedule = await snapshot(
          this._chartFacade.getFeeScheduleManager().currentSchedule$
        );

        const treatmentConfigRef = await this._addTreatmentConfig(
          brand,
          action.config
        );
        const chartable = await getDoc(treatmentConfigRef);
        const chartedSurfaces = await this._getChartedSurfaces(
          action.id,
          staffer,
          chartable
        );
        await this._chartFacade.addTreatment(
          action.id,
          chartable,
          chartedSurfaces,
          feeSchedule
        );
      })
    );
  }

  private _addConditionConfiguration$(): Observable<void> {
    return this._actions$.pipe(
      ofType(chartActions.addConditionConfiguration),
      unserialise$(),
      concatMap(async (action) => {
        await this._addConditionConfig(action.entity, action.config);
      })
    );
  }

  private _addAndChartConditionConfiguration$(): Observable<void> {
    return this._actions$.pipe(
      ofType(chartActions.addAndChartConditionConfiguration),
      unserialise$(),
      concatMap(async (action) => {
        const staffer = await snapshot(
          this._chartFacade.chartingAs$(action.id)
        );
        const brand = await snapshot(
          this._currentScopeFacade.currentBrand$.pipe(filterUndefined())
        );
        const conditionConfigRef = await this._addConditionConfig(
          brand,
          action.config
        );
        const chartable = await getDoc(conditionConfigRef);
        const chartedSurfaces = await this._getChartedSurfaces(
          action.id,
          staffer,
          chartable
        );
        await this._chartFacade.addCondition(
          action.id,
          ChartedCondition.fromConfig(chartable),
          chartedSurfaces
        );
      })
    );
  }

  private _updateQuickChartConfiguration$(): Observable<void> {
    return this._actions$.pipe(
      ofType(addQuickChartingItem, removeQuickChartingItem),
      unserialise$(),
      concatLatestFrom((action) =>
        this._chartedConfigFacade
          .getChartContextQuickCharting$(action.id)
          .pipe(unserialise$())
      ),
      concatMap(async ([action, contextChartingAs]) => {
        const staffer = await snapshot(
          this._chartFacade.chartingAs$(action.id)
        );
        await patchDoc(staffer.ref, {
          quickChartingConfiguration: contextChartingAs,
        });
      })
    );
  }

  private async _getChartedSurfaces(
    chartId: ChartId,
    staffer: WithRef<IStaffer>,
    chartable: WithRef<IChartedItemConfiguration>
  ): Promise<IChartedSurface[]> {
    const selectedSurfaces = await snapshot(
      this._chartFacade.selectedSurfacesState$(chartId)
    );
    return ChartableSurfaceResolver.getChartedRefs(
      chartable,
      selectedSurfaces
    ).map((chartedRef) =>
      ChartedSurface.init({
        chartedRef,
        chartedBy: stafferToNamedDoc(staffer),
      })
    );
  }

  private async _addConditionConfig(
    entity: WithRef<IStaffer> | WithRef<IBrand>,
    conditionConfig: Partial<IConditionConfiguration>
  ): Promise<DocumentReference<IConditionConfiguration>> {
    const ref = await addDoc(
      ConditionConfiguration.col(entity),
      ConditionConfiguration.init(conditionConfig)
    );
    this._snackBar.open('Condition configuration saved');
    return ref;
  }

  private async _addTreatmentConfig(
    entity: WithRef<IStaffer> | WithRef<IBrand>,
    treatmentConfig: Partial<ITreatmentConfiguration>
  ): Promise<DocumentReference<ITreatmentConfiguration>> {
    const ref = await addDoc(
      TreatmentConfiguration.col(entity),
      TreatmentConfiguration.init(treatmentConfig)
    );
    this._snackBar.open('Treatment configuration saved');
    return ref;
  }
}
