<mat-toolbar class="!bg-white">
  <ng-container *ngrxLet="patient$ as patient">
    <ng-container *ngrxLet="appointment$ as appointment">
      <div
        class="flex-1 truncate"
        *ngIf="appointment.event; else unscheduledAppointment"
      >
        Cancel Appointment - {{ patient.name }} -
        <pr-formatted-event-field
          field="from"
          [event]="appointment.event"
          [format]="dateFormat"
        />
      </div>
      <ng-template #unscheduledAppointment>
        <div class="flex-1 truncate">Appointment Currently Unscheduled</div>
      </ng-template>
    </ng-container>
  </ng-container>
  <button mat-icon-button (click)="back()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<div class="border-b border-solid border-slate-300 bg-white"></div>

<div class="flex flex-auto flex-row overflow-hidden">
  <div class="flex flex-auto">
    <div class="h-full w-full overflow-scroll">
      <form
        [formGroup]="cancelFormGroup"
        class="mx-auto flex max-w-2xl flex-col gap-4 p-4"
      >
        <div
          class="m-0 box-border flex w-full flex-col gap-4 overflow-auto rounded-md bg-white p-4 shadow-md shadow-slate-500/10"
        >
          <div class="flex flex-row justify-center">
            <h3 class="!m-0 flex-auto text-base !font-bold">Follow Up</h3>
            <mat-slide-toggle formControlName="createFollowUp" />
          </div>

          @if (cancelFormGroup.controls.createFollowUp.value) {
            <div class="flex w-full items-center gap-4">
              <mat-form-field class="flex-1">
                <mat-label>Predefined dates</mat-label>
                <mat-select [formControl]="datePresetCtrl">
                  @for (preset of datePresets; track preset.label) {
                    <mat-option [value]="preset">
                      {{ preset.label }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <mat-form-field class="flex-1">
                <mat-label>Follow up date</mat-label>
                <input
                  matInput
                  placeholder="Follow up date"
                  formControlName="followUpDate"
                  [matDatepicker]="followUpDate"
                  [min]="minDate"
                  (focus)="followUpDate.open()"
                  (click)="followUpDate.open()"
                />
                <mat-datepicker-toggle matIconSuffix [for]="followUpDate" />
                <mat-datepicker #followUpDate />
              </mat-form-field>
            </div>
          } @else {
            <mat-form-field class="flex-1">
              <mat-label>Why isn't a follow up being set?</mat-label>
              <pr-content-editor
                formControlName="noFollowUpReason"
                placeholder="Why isn't a follow up being set?"
                [menuEnabled]="false"
              />
              <mat-hint align="start">
                In nearly all cases we want to ensure that a follow up is set.
              </mat-hint>
            </mat-form-field>
          }
        </div>

        <div
          class="m-0 box-border flex w-full flex-col gap-4 overflow-auto rounded-md bg-white p-4 shadow-md shadow-slate-500/10"
        >
          <h3 class="!m-0 text-base !font-bold">Cancellation Notes</h3>
          <mat-form-field>
            <mat-label>Comments</mat-label>
            <pr-content-editor
              formControlName="comments"
              [menuEnabled]="false"
            />
          </mat-form-field>
        </div>

        <div class="flex flex-row justify-end gap-2">
          <pr-scheduling-event-reason-selector
            formControlName="reason"
            [reasons]="reasons$ | async"
            [schedulingConditions]="schedulingConditions$ | async"
          />

          <button
            mat-flat-button
            color="primary"
            (click)="submit()"
            [disabled]="disableSubmit$ | async"
          >
            Cancel Appointment
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="sidebar bg-white">
    <div class="h-full w-full overflow-scroll">
      <pr-appointment-sidebar
        class="!top-auto"
        [patient]="patient$ | async"
        [appointment]="appointment$ | async"
      />
    </div>
  </div>
</div>
