<h2 *ngIf="title">{{ title }}</h2>
<div fxFill fxLayout="column" class="mat-elevation-z1">
  <pr-chart-tab-group class="tab-group">
    <ng-container *ngFor="let tab of chartCards; trackBy: trackByChartCard">
      <ng-container *ngIf="!tab.summary">
        <pr-chart-tab
          [title]="tab.title"
          [unit]="tab.unit"
          [value]="tab.value?.value"
          [chartBuilder]="tab.chartBuilder"
         />
      </ng-container>
      <ng-container *ngIf="tab.summary">
        <pr-chart-tab
          [title]="tab.title"
          [chartBuilder]="tab.chartBuilder"
          [summary]="tab.summary"
         />
      </ng-container>
    </ng-container>
  </pr-chart-tab-group>
  <div fxFlex class="report-card-footer" fxLayout="row">
    <div fxLayout="column" fxLayoutAlign="end start">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-select
          fxFlex="25"
          placeholder="Report Range"
          [value]="selectedRange"
          (selectionChange)="onDateChange()"
        >
          <mat-option
            *ngFor="let range of ranges; trackBy: trackTimeRange"
            [value]="range"
          >
            {{ range.title }}
          </mat-option>
        </mat-select>
      </div>
    </div>
  </div>
</div>
