@if (schedulingEvent$ | async; as schedulingEvent) {
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      (click)="actions.viewSchedulingNotes(schedulingEvent)"
    >
      View Scheduling Notes
    </button>

    <button
      mat-menu-item
      (click)="actions.openPatientAppointments(schedulingEvent)"
    >
      Open Patient Appointments
    </button>

    <button
      mat-menu-item
      [disabled]="
        schedulingEvent | map: actions.disableShowOnTimeline : actions
      "
      (click)="actions.showOnTimeline(schedulingEvent)"
    >
      Show On Timeline
    </button>

    <button
      mat-menu-item
      [disabled]="
        schedulingEvent | map: actions.disableChangeReason$ : actions | async
      "
      (click)="actions.changeReason(schedulingEvent)"
    >
      Change Reason
    </button>
  </mat-menu>
}
