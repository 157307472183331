import { type IMentionable } from '../mention/mention';

export enum NotificationType {
  PatientBookedAppointment = 'patientBookedAppointment',
  PatientRescheduledAppointment = 'patientRescheduledAppointment',
  PatientCancelledAppointment = 'patientCancelledAppointment',
  StafferBookedAppointment = 'stafferBookedAppointment',
  StafferRescheduledAppointment = 'stafferRescheduledAppointment',
  StafferCancelledAppointment = 'stafferCancelledAppointment',
  AddedTask = 'addedTask',
  CompletedTask = 'completedTask',
  ReopenedTask = 'reopenedTask',
  TaskMentionedUser = 'taskMentionedUser',
  Other = 'other',
}

export const NOTIFICATION_TYPES: NotificationType[] = [
  NotificationType.PatientBookedAppointment,
  NotificationType.PatientRescheduledAppointment,
  NotificationType.PatientCancelledAppointment,
  NotificationType.StafferBookedAppointment,
  NotificationType.StafferRescheduledAppointment,
  NotificationType.StafferCancelledAppointment,
  NotificationType.AddedTask,
  NotificationType.CompletedTask,
  NotificationType.ReopenedTask,
  NotificationType.TaskMentionedUser,
  NotificationType.Other,
];

export type INotificationResources = IMentionable;
