import {
  IAutomationConfiguration,
  IBrand,
  isStaffer,
  IStaffer,
  ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  asyncForEach,
  getDoc,
  getDocs,
  getParentDocRef,
  IBulkUploadData,
  IIdentifiable,
  patchDoc,
  snapshot,
  WithRef,
} from '@principle-theorem/shared';
import { DocumentReference } from '@principle-theorem/shared';
import { ConditionConfiguration } from '../conditions/condition-configuration';
import { TreatmentConfiguration } from './treatment-configuration';
import { buildTreatmentConfigurationSeedData } from './treatment-configuration-seed-data';
import { Brand } from '../../brand';
import { AutomationConfiguration } from '../../automation/automation-configuration';

async function getTreatmentConfigs(
  entity: WithRef<IStaffer> | WithRef<IBrand>
): Promise<(ITreatmentConfiguration & Partial<IIdentifiable>)[]> {
  const conditionConfigurations = await snapshot(
    ConditionConfiguration.all$(entity)
  );

  const brandRef = isStaffer(entity)
    ? getParentDocRef<IBrand>(entity.ref)
    : entity.ref;

  const treatmentCategories = await snapshot(
    Brand.treatmentCategories$({ ref: brandRef })
  );
  const automationConfigurations = await getDocs(
    AutomationConfiguration.col({ ref: brandRef })
  );

  return buildTreatmentConfigurationSeedData(
    conditionConfigurations,
    treatmentCategories,
    automationConfigurations
  );
}

export async function upsertTreatmentConfigurations(
  entity: WithRef<IStaffer> | WithRef<IBrand>
): Promise<void> {
  const treatmentConfigurations = await getTreatmentConfigs(entity);
  const writeData = treatmentConfigurations
    .filter((treatmentConfiguration) => !!treatmentConfiguration.uid)
    .map((treatmentConfiguration) => ({
      collectionRef: TreatmentConfiguration.col(entity),
      data: treatmentConfiguration,
      uid: treatmentConfiguration.uid,
    }));
  return upsertBulkTreatments(writeData);
}

export async function setTreatmentConfigurations(
  entity: WithRef<IStaffer> | WithRef<IBrand>
): Promise<void> {
  const treatmentConfigurations = await getTreatmentConfigs(entity);
  const writeData = treatmentConfigurations.map((treatmentConfiguration) => ({
    collectionRef: TreatmentConfiguration.col(entity),
    data: treatmentConfiguration,
    uid: treatmentConfiguration.uid,
  }));
  return upsertBulkTreatments(writeData);
}

async function upsertBulkTreatments(
  items: IBulkUploadData<ITreatmentConfiguration>[]
): Promise<void> {
  await asyncForEach(items, async (item) => {
    const treatmentRef = await addDoc(item.collectionRef, item.data, item.uid);
    await asyncForEach(
      [...item.data.generatedTaskRefs, ...item.data.automatedNotificationRefs],
      (automationRef) => addTreatmentToAutomation(automationRef, treatmentRef)
    );
  });
}

async function addTreatmentToAutomation(
  automationRef: DocumentReference<IAutomationConfiguration>,
  treatmentRef: DocumentReference<ITreatmentConfiguration>
): Promise<void> {
  const automation = await getDoc(automationRef);
  await patchDoc(automationRef, {
    treatmentRefs: [...automation.treatmentRefs, treatmentRef],
  });
}
