import {
  DateRangeFilter,
  ICustomReportFilterValue,
  IMeasureMetadata,
  MeasureFormatter,
  MeasureReducer,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, isObject, uid } from '@principle-theorem/shared';
import {
  ComparableObject,
  ComparableValue,
  Dimension,
  Group,
  Grouping,
  NaturallyOrderedValue,
} from 'crossfilter2';
import { cloneDeep, isBoolean, isEqual, isString, uniqWith } from 'lodash';
import { IReportingQuery, getCustomReportFilterSQL } from '../querying';
import { IGroupedDimension } from './grouped-dimension';
import { IMeasureFilter } from './measure-filters';

export interface ICustomReportQueryMetadata {
  key: string;
  queryForValues: (values: string[]) => Omit<IReportingQuery, 'table'>;
}

export interface IChartBuilderData {
  plottedOverTime: boolean;
  accumulateOverTime: boolean;
  colourOverride: boolean;
  groupByDimension?: ICanGroupMeasuresProperty;
  measures: IMeasureBuilderData[];
  groupLimit?: number;
  otherGroupLabel?: string;
}

export interface IMeasureBuilderData {
  uid: string;
  measure: CanBeChartedProperty;
  label?: string;
  reducer?: MeasureReducer;
  filters?: ComparableValue[];
}

export function toMeasureBuilderData(
  measure: CanBeChartedProperty,
  reducer?: MeasureReducer,
  label?: string,
  filters?: ComparableValue[]
): IMeasureBuilderData {
  return { uid: uid(), measure, label, reducer, filters };
}

export type DataAccessor = (fact: unknown) => ComparableValue;
export type LabelAccessor = (fact: unknown) => string;
export type NumberAccessor = (fact: unknown) => number;
export type ColourAccessor = (fact: unknown) => string;
export type FilterAccessor = (
  values: ICustomReportFilterValue[]
) => string | undefined;

export interface IGroupByDimensionMetadata {
  queryAttributes: string[];
  dataAccessor: DataAccessor;
  isArrayDataAccessor?: boolean;
  labelAccessor?: LabelAccessor;
  colourAccessor?: ColourAccessor;
}

export interface IMeasure {
  propertyName: string;
  query: IReportingQuery;
  dataAccessor: DataAccessor;
  filterAccessor: FilterAccessor;
  sortAccessor?: DataAccessor;
  reduceBy?: ReduceByFunction;
}

export function noFilterAccessor(): FilterAccessor {
  return (_filters) => undefined;
}

export function basicFilterAccessor(
  propertyPath: string,
  convertValuesToNull: (string | number | DateRangeFilter)[] = []
): FilterAccessor {
  return (filters) =>
    getCustomReportFilterSQL(propertyPath, filters, convertValuesToNull);
}

export function getFormatterDefaultValue(
  formatter: MeasureFormatter
): ComparableValue {
  const defaultValueMap: Record<MeasureFormatter, ComparableValue> = {
    [MeasureFormatter.Boolean]: false,
    [MeasureFormatter.Currency]: 0,
    [MeasureFormatter.Custom]: '',
    [MeasureFormatter.Day]: 0,
    [MeasureFormatter.Link]: '',
    [MeasureFormatter.Hours]: 0,
    [MeasureFormatter.Minutes]: 0,
    [MeasureFormatter.Month]: 0,
    [MeasureFormatter.Number]: 0,
    [MeasureFormatter.Percentage]: 0,
    [MeasureFormatter.Prefix]: '',
    [MeasureFormatter.Suffix]: '',
    [MeasureFormatter.Text]: '',
    [MeasureFormatter.Time]: '',
    [MeasureFormatter.Timestamp]: '',
  };
  return defaultValueMap[formatter];
}

export type ComparablePropertyNames<T> = {
  [K in keyof T]: T[K] extends ComparableValue ? K : never;
}[keyof T];
export type ComparableProperties<T> = Pick<T, ComparablePropertyNames<T>>;

export type MeasureTransformMap<Model> = {
  [K in keyof Model]: CanBeChartedProperty;
};

export type IReportingProperty =
  | ICanBeChartedProperty
  | ICanGroupMeasuresProperty;

export type ReportingProperty = CanBeChartedProperty | CanGroupMeasuresProperty;

export type CrossfilterGroup = Group<
  unknown,
  IGroupedDimension,
  NaturallyOrderedValue
>;

export type CrossfilterGroupValue = Grouping<
  IGroupedDimension,
  NaturallyOrderedValue
>;

export interface ICanBeChartedProperty {
  metadata: IMeasureMetadata;
  measure: IMeasure;
}

export const isCanBeChartedProperty =
  TypeGuard.interface<ICanBeChartedProperty>({
    metadata: isObject,
    measure: isObject,
  });

export interface ICanGroupMeasuresProperty {
  metadata: IMeasureMetadata;
  groupMeasure: IGroupByDimensionMetadata;
}

export const isCanGroupMeasuresProperty =
  TypeGuard.interface<ICanGroupMeasuresProperty>({
    metadata: isObject,
    groupMeasure: isObject,
  });

export type ICanDoAllProperty = ICanBeChartedProperty &
  ICanGroupMeasuresProperty;

export const isCanDoAllProperty = TypeGuard.merge(
  isCanBeChartedProperty,
  isCanGroupMeasuresProperty
);

export interface IMeasureRef {
  /**
   * Attribute path given to the query function.
   * Dot seperated path to the attribute.
   */
  attributePath: string;

  /**
   * Path to the property on the returned query data.
   * Underscore seperated path to the property
   */
  factPropertyPath: string;
}

export const isQueryBy = TypeGuard.interface<IMeasureRef>({
  attributePath: isString,
  factPropertyPath: isString,
});

export interface ICanQueryByTimestampProperty extends ICanDoAllProperty {
  queryBy: IMeasureRef;
}

export const isCanQueryByTimestampProperty = TypeGuard.merge(
  isCanDoAllProperty,
  TypeGuard.interface<
    Omit<ICanQueryByTimestampProperty, keyof ICanDoAllProperty>
  >({
    queryBy: isQueryBy,
  })
);

export interface IReduceByRate extends ComparableObject {
  count: number;
  numerator: number;
  denominator: number;
}

export interface IReduceByMultiplier extends ComparableObject {
  count: number;
  multiplicand: number;
  multiplier: number;
}

export type ReduceByFunction = (
  dimension: Dimension<unknown, ComparableObject>
) => Group<unknown, IGroupedDimension, ComparableObject>;

export class CanBeChartedProperty implements ICanBeChartedProperty {
  public metadata: IMeasureMetadata;
  public measure: IMeasure;

  constructor(data: ICanBeChartedProperty) {
    this.metadata = data.metadata;
    this.measure = data.measure;
    if (this.measure.reduceBy) {
      this.reduceByCount();
    }
  }

  clone(): CanBeChartedProperty {
    return new CanBeChartedProperty({
      metadata: cloneDeep(this.metadata),
      measure: cloneDeep(this.measure),
    });
  }

  setMetadata(metadata: Partial<IMeasureMetadata>): this {
    this.metadata = { ...this.metadata, ...metadata };
    return this;
  }

  setLabel(label: string): this {
    this.metadata.label = label;
    return this;
  }

  setFormatter(
    formatter: MeasureFormatter,
    formatterValue?: IMeasureMetadata['formatterValue']
  ): this {
    this.metadata.formatter = formatter;
    if (formatterValue) {
      this.metadata.formatterValue = formatterValue;
    }
    return this;
  }

  groupBy(groupBy: ICanGroupMeasuresProperty): this {
    this.measure.query.attributes.push(...groupBy.groupMeasure.queryAttributes);
    if (isCanBeChartedProperty(groupBy)) {
      if (!this.measure.query.joins) {
        this.measure.query.joins = [];
      }
      this.measure.query.joins = uniqWith(
        [...this.measure.query.joins, ...(groupBy.measure.query.joins ?? [])],
        isEqual
      );
    }
    return this;
  }

  filterBy(filter: IMeasureFilter, measure: IMeasure = this.measure): this {
    const query: string = filter.value(measure);
    if (!this.measure.query.filters) {
      this.measure.query.filters = [];
    }
    this.measure.query.filters.push(query);
    return this;
  }

  reduceBySum(): this {
    this.measure.reduceBy = (
      dimension: Dimension<unknown, ComparableObject>
    ) => {
      return (
        dimension
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .group<any, ComparableObject>()
          .reduceSum((fact) =>
            parseFloat(this.measure.dataAccessor(fact)?.toString() ?? '0')
          )
      );
    };
    return this;
  }

  reduceByCount(): this {
    this.metadata.formatter = MeasureFormatter.Number;
    this.measure.reduceBy = (
      dimension: Dimension<unknown, ComparableObject>
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return dimension.group<any, ComparableObject>().reduceCount();
    };
    return this;
  }

  reduceByAverage(): this {
    return this.reduceByRatio();
  }

  reduceByMultiplier(multiplier: ICanBeChartedProperty): this {
    let multiplierAccessor: DataAccessor | undefined;
    if (multiplier && multiplier.measure.dataAccessor) {
      multiplierAccessor = multiplier.measure.dataAccessor;
    }
    this.measure.reduceBy = (
      dimension: Dimension<unknown, ComparableObject>
    ) => {
      return dimension.group<IGroupedDimension, IReduceByMultiplier>().reduce(
        (data: IReduceByMultiplier, facts) => {
          ++data.count;
          data.multiplicand += parseFloat(
            this.measure.dataAccessor(facts).toString()
          );
          data.multiplier += multiplierAccessor
            ? parseFloat(multiplierAccessor(facts).toString())
            : data.count;
          data.valueOf = () => data.multiplicand * data.multiplier || 0;
          return data;
        },
        (data: IReduceByMultiplier, facts) => {
          --data.count;
          data.multiplicand -= parseFloat(
            this.measure.dataAccessor(facts).toString()
          );
          data.multiplier -= multiplierAccessor
            ? parseFloat(multiplierAccessor(facts).toString())
            : data.count;
          data.valueOf = () => data.multiplicand * data.multiplier || 0;
          return data;
        },
        () => {
          return {
            count: 0,
            multiplicand: 0,
            multiplier: 0,
            valueOf: () => 0,
          };
        }
      );
    };
    return this;
  }

  reduceByRatio(denominator?: ICanBeChartedProperty): this {
    let denominatorAccessor: DataAccessor | undefined;
    if (denominator && denominator.measure.dataAccessor) {
      denominatorAccessor = denominator.measure.dataAccessor;
    }
    this.measure.reduceBy = (
      dimension: Dimension<unknown, ComparableObject>
    ) => {
      return dimension.group<IGroupedDimension, IReduceByRate>().reduce(
        (data: IReduceByRate, facts) => {
          ++data.count;
          data.numerator += booleanSafeParseFloat(
            this.measure.dataAccessor(facts)
          );
          if (denominatorAccessor) {
            data.denominator += booleanSafeParseFloat(
              denominatorAccessor(facts)
            );
          } else {
            data.denominator = data.count;
          }
          data.valueOf = () => data.numerator / data.denominator || 0;
          return data;
        },
        (data: IReduceByRate, facts) => {
          --data.count;
          data.numerator -= booleanSafeParseFloat(
            this.measure.dataAccessor(facts)
          );
          if (denominatorAccessor) {
            data.denominator -= booleanSafeParseFloat(
              denominatorAccessor(facts)
            );
          } else {
            data.denominator = data.count;
          }
          data.valueOf = () => data.numerator / data.denominator || 0;
          return data;
        },
        () => {
          return {
            count: 0,
            numerator: 0,
            denominator: 0,
            valueOf: () => 0,
          };
        }
      );
    };
    return this;
  }

  reduceByCustom(reducerFn: ReduceByFunction): this {
    this.measure.reduceBy = reducerFn;
    return this;
  }

  reduceBy(reducer: MeasureReducer): this {
    switch (reducer) {
      case MeasureReducer.Sum:
        return this.reduceBySum();
      case MeasureReducer.Average:
        return this.reduceByAverage();
      case MeasureReducer.Count:
        return this.reduceByCount();
      default:
        return this;
    }
  }
}

function booleanSafeParseFloat(value: unknown): number {
  return isBoolean(value) ? Number(value) : parseFloat(String(value));
}

export class CanDoAllProperty
  extends CanBeChartedProperty
  implements ICanDoAllProperty
{
  public groupMeasure: IGroupByDimensionMetadata;

  constructor(data: ICanDoAllProperty) {
    super(data);
    this.groupMeasure = data.groupMeasure;
  }

  override clone(): CanDoAllProperty {
    return new CanDoAllProperty({
      metadata: cloneDeep(this.metadata),
      measure: cloneDeep(this.measure),
      groupMeasure: cloneDeep(this.groupMeasure),
    });
  }
}

export class CanGroupMeasuresProperty implements ICanGroupMeasuresProperty {
  public metadata: IMeasureMetadata;
  public groupMeasure: IGroupByDimensionMetadata;

  constructor(data: ICanGroupMeasuresProperty) {
    this.metadata = data.metadata;
    this.groupMeasure = data.groupMeasure;
  }
}

export class CanQueryByTimestampProperty
  extends CanDoAllProperty
  implements ICanQueryByTimestampProperty
{
  queryBy: IMeasureRef;

  constructor(data: ICanQueryByTimestampProperty) {
    super(data);
    this.queryBy = data.queryBy;
  }
}
