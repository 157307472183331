<mat-form-field
  fxFlex
  [appearance]="appearance"
  [floatLabel]="showNone ? 'always' : 'auto'"
  [subscriptSizing]="subscriptSizing"
>
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [formControl]="selectedCtrl"
    [compareWith]="compareFn"
    [disabled]="disabled"
    [placeholder]="showNone ? 'None' : undefined"
  >
    @if (showNone) {
      <mat-option>None</mat-option>
    }
    <mat-option
      *ngFor="let staffer of staff$ | async; trackBy: trackByStaffer"
      [value]="staffer"
    >
      {{ staffer.user.name }}
    </mat-option>
  </mat-select>
  @if (showClear) {
    <button
      type="button"
      matIconSuffix
      mat-icon-button
      [disabled]="!selectedCtrl.value"
      (click)="clearStaffer($event)"
    >
      <mat-icon>close</mat-icon>
    </button>
  }
</mat-form-field>
