import {
  FormLayoutElementType,
  FormSchemaPropertyType,
  type IChildFlexLayoutElement,
  type IFormLayoutElement,
  type IFormSchema,
  type IParentFlexLayout,
} from '@principle-theorem/principle-core/interfaces';
import { formFlexRow, prefixLayoutItems } from '../../custom-form-helpers';

/**
 * CONDITIONS
 */

const PAEDIATRIC_CONDITIONS_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    anxiety: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Anxiety',
    },
    attention: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Attention / learning issues',
    },
    asthma: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Asthma / breathing issues',
    },
    autism: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Autism Spectrum Disorder',
    },
    bleeding: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Bleeding issues',
    },
    cancer: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Cancer',
    },
    cleftPalate: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Cleft lip / palate',
    },
    craniofacialAbnormality: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Craniofacial abnormality',
    },
    developmentalDelay: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Developmental delay',
    },
    diabetes: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Diabetes',
    },
    gastrointestinalIssues: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Gastrointestinal issues',
    },
    heartIssues: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Heart issues',
    },
    immuneSystemIssues: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Immune system issues',
    },
    kidneyLiverIssues: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Kidney / liver issues',
    },
    physicalDisability: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Physical disability',
    },
    prematurity: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Prematurity',
    },
    sightOrSpeechIssues: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Sight or speech issues',
    },
    seizures: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Seizures',
    },
    other: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Other',
    },
    details: {
      type: FormSchemaPropertyType.String,
      title: `Please provide details (if applicable)`,
    },
  },
};

export const PAEDIATRIC_CONDITIONS_FORM_LAYOUT: IChildFlexLayoutElement[] = [
  {
    type: FormLayoutElementType.Flex,
    items: [
      formFlexRow([
        { key: 'anxiety' },
        { key: 'craniofacialAbnormality' },
        { key: 'physicalDisability' },
      ]),
      formFlexRow([
        { key: 'attention' },
        { key: 'developmentalDelay' },
        { key: 'prematurity' },
      ]),
      formFlexRow([
        { key: 'asthma' },
        { key: 'diabetes' },
        { key: 'sightOrSpeechIssues' },
      ]),
      formFlexRow([
        { key: 'autism' },
        { key: 'gastrointestinalIssues' },
        { key: 'seizures' },
      ]),
      formFlexRow([
        { key: 'bleeding' },
        { key: 'heartIssues' },
        { key: 'other' },
      ]),
      formFlexRow([
        { key: 'cancer' },
        { key: 'immuneSystemIssues' },
        { type: FormLayoutElementType.Div },
      ]),
      formFlexRow([
        { key: 'cleftPalate' },
        { key: 'kidneyLiverIssues' },
        { type: FormLayoutElementType.Div },
      ]),
      formFlexRow([{ key: 'details' }]),
    ],
  },
];

/**
 * ALLERGIES
 */

const ALLERGIES_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    // hasAllergies: {
    //   type: FormSchemaPropertyType.Boolean,
    //   title: 'Does your child have allergies?',
    // },
    allergies: {
      type: FormSchemaPropertyType.String,
      title: 'Does your child have allergies?',
    },
    // hasFamilyHistory: {
    //   type: FormSchemaPropertyType.Boolean,
    //   title: 'Is there a family history of any severe allergies?',
    // },
    familyHistory: {
      type: FormSchemaPropertyType.String,
      title: 'Is there a family history of any severe allergies?',
    },
    // hasIntolerances: {
    //   type: FormSchemaPropertyType.Boolean,
    //   title: 'Does your child have any intolerances?',
    // },
    intolerances: {
      type: FormSchemaPropertyType.String,
      title: 'Does your child have any intolerances?',
    },
  },
};

export const ALLERGIES_FORM_LAYOUT: IChildFlexLayoutElement[] = [
  {
    type: FormLayoutElementType.Flex,
    items: [
      formFlexRow([
        // { key: 'hasAllergies' },
        { key: 'allergies' },
      ]),
      formFlexRow([
        // { key: 'hasFamilyHistory' },
        { key: 'familyHistory' },
      ]),
      formFlexRow([
        // { key: 'hasIntolerances' },
        { key: 'intolerances' },
      ]),
    ],
  },
];

/**
 * MEDICATIONS
 */

const MEDICATIONS_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    // hasMedications: {
    //   type: FormSchemaPropertyType.Boolean,
    //   title: 'Is your child taking any regular medications?',
    // },
    medications: {
      type: FormSchemaPropertyType.String,
      title: 'Is your child taking any regular medications?',
    },
  },
};

export const MEDICATIONS_FORM_LAYOUT: IChildFlexLayoutElement[] = [
  {
    type: FormLayoutElementType.Flex,
    items: [
      formFlexRow([
        // { key: 'hasMedications' },
        { key: 'medications' },
      ]),
    ],
  },
];

/**
 * OTHER INFO
 */

const OTHER_INFO_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    // hasImmunisations: {
    //   type: FormSchemaPropertyType.Boolean,
    //   title: `Are your child's immunisations up to date?`,
    // },
    immunisations: {
      type: FormSchemaPropertyType.String,
      title: `Are your child's immunisations up to date? If No, please provide details`,
    },
    // hasSurgeries: {
    //   type: FormSchemaPropertyType.Boolean,
    //   title: `Has your child ever had any hospitalisations or surgeries?`,
    // },
    surgeries: {
      type: FormSchemaPropertyType.String,
      title: 'Has your child ever had any hospitalisations or surgeries?',
    },
    // hasSpecialists: {
    //   type: FormSchemaPropertyType.Boolean,
    //   title: `Does your child see any medical specialists?`,
    // },
    specialists: {
      type: FormSchemaPropertyType.String,
      title: 'Does your child see any medical specialists?',
    },
    // hasAlliedHealthProfessionals: {
    //   type: FormSchemaPropertyType.Boolean,
    //   title: `Does your child see any allied health professionals?`,
    // },
    alliedHealthProfessionals: {
      type: FormSchemaPropertyType.String,
      title: 'Does your child see any allied health professionals?',
    },

    // regularPractitioner: {
    //   type: FormSchemaPropertyType.String,
    //   title: `Who is your child's regular medical practitioner?`,
    // },
    // regularPractitionerPhone: {
    //   type: FormSchemaPropertyType.String,
    //   title: 'Phone number',
    // },
  },
};

export const OTHER_INFO_FORM_LAYOUT: IChildFlexLayoutElement[] = [
  {
    type: FormLayoutElementType.Flex,
    items: [
      formFlexRow([
        // { key: 'hasImmunisations' },
        { key: 'immunisations' },
      ]),
      formFlexRow([
        // { key: 'hasSurgeries' },
        { key: 'surgeries' },
      ]),
      formFlexRow([
        // { key: 'hasSpecialists' },
        { key: 'specialists' },
      ]),
      formFlexRow([
        // { key: 'hasAlliedHealthProfessionals' },
        { key: 'alliedHealthProfessionals' },
      ]),
      // formFlexRow([
      //   { key: 'regularPractitioner' },
      //   { key: 'regularPractitionerPhone' },
      // ]),
    ],
  },
];

/**
 * Medical Background
 */

export const PAEDIATRIC_MEDICAL_BACKGROUND_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    conditions: PAEDIATRIC_CONDITIONS_FORM_SCHEMA,
    allergies: ALLERGIES_FORM_SCHEMA,
    medications: MEDICATIONS_FORM_SCHEMA,
    other: OTHER_INFO_FORM_SCHEMA,
  },
};

export const PAEDIATRIC_MEDICAL_BACKGROUND_FORM_LAYOUT: (
  | IParentFlexLayout
  | IFormLayoutElement
)[] = [
  {
    key: 'conditions',
    type: FormLayoutElementType.Flex,
    options: { flex: '1 1 100%' },
    title: 'Does your child have (or has ever had) any of the following?',
    items: prefixLayoutItems(PAEDIATRIC_CONDITIONS_FORM_LAYOUT, 'conditions'),
  },
  {
    key: 'allergies',
    type: FormLayoutElementType.Flex,
    options: { flex: '1 1 100%' },
    notitle: true,
    items: prefixLayoutItems(ALLERGIES_FORM_LAYOUT, 'allergies'),
  },
  {
    key: 'medications',
    type: FormLayoutElementType.Flex,
    options: { flex: '1 1 100%' },
    notitle: true,
    items: prefixLayoutItems(MEDICATIONS_FORM_LAYOUT, 'medications'),
  },
  {
    key: 'other',
    type: FormLayoutElementType.Flex,
    options: { flex: '1 1 100%' },
    notitle: true,
    items: prefixLayoutItems(OTHER_INFO_FORM_LAYOUT, 'other'),
  },
];
