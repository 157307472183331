import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { CurrentAppointmentScope } from '@principle-theorem/ng-principle-shared';
import { AbstractResolver } from '@principle-theorem/ng-shared';
import { Appointment, TreatmentPlan } from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type ITreatmentPlan,
  TreatmentStepStatus,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class AppointmentTreatmentPlanResolverService extends AbstractResolver<ITreatmentPlan> {
  constructor(router: Router) {
    super(router);
  }

  getItemById(
    _uid: string,
    route: ActivatedRouteSnapshot
  ): Observable<ITreatmentPlan> {
    const appointmentScope: CurrentAppointmentScope =
      new CurrentAppointmentScope(this._router);
    const appointment: WithRef<IAppointment> | undefined =
      appointmentScope.findModel(route);

    if (!appointment) {
      throw new Error('Treatment Plan could not be resolved');
    }

    return Appointment.treatmentPlan$(appointment).pipe(
      tap((plan) =>
        TreatmentPlan.findStepByAppointment$(plan, appointment.ref).pipe(
          map((step) => {
            if (!step || step.status !== TreatmentStepStatus.Incomplete) {
              return;
            }
            step.status = TreatmentStepStatus.Current;
          })
        )
      )
    );
  }
}
