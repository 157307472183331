import { isSameChartedRef } from '@principle-theorem/principle-core';
import {
  type AnyChartedItemConfiguration,
  type IChartedCondition,
  type IChartedItem,
  type IChartedItemConfiguration,
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  type IChartedRef,
  type IStaffer,
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { differenceWith } from 'lodash';
import { AddChartable, RemoveChartable } from './add-chartable';
import { type IChartedSurfaceProvider } from './charted-surface/add-charted-surface-provider';

export interface IEditChartableData {
  chartable: AnyChartedItemConfiguration;
  selectedSurfaces: Partial<IChartedRef>[];
  item: IChartedItem<IChartedItemConfiguration> | IChartedCondition;
  chartingAs: WithRef<IStaffer>;
  plan?: IChartedMultiStepTreatment | WithRef<ITreatmentPlan>;
  step?: IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>;
}

export class ChartableSurfaceUpdater {
  static async updateTreatmentSurfaces(
    data: IEditChartableData,
    surfaceProviders: IChartedSurfaceProvider[]
  ): Promise<void> {
    await new AddChartable(surfaceProviders).add({
      selectedSurfaces: data.selectedSurfaces,
      chartable: data.chartable,
      chartingAs: data.chartingAs,
    });

    const removedSurfaces = ChartableSurfaceUpdater.getRemovedSurfaces(data);
    if (removedSurfaces.length) {
      await new RemoveChartable(surfaceProviders).remove(
        data.chartable,
        removedSurfaces,
        data.item
      );
    }
  }

  static async updateConditionSurfaces(
    data: IEditChartableData,
    surfaceProviders: IChartedSurfaceProvider[]
  ): Promise<void> {
    await new AddChartable(surfaceProviders).add({
      selectedSurfaces: data.selectedSurfaces,
      chartable: data.chartable,
      chartingAs: data.chartingAs,
    });

    const removedSurfaces = ChartableSurfaceUpdater.getRemovedSurfaces(data);
    if (removedSurfaces.length) {
      await new RemoveChartable(surfaceProviders).remove(
        data.chartable,
        removedSurfaces,
        data.item
      );
    }
  }

  static getRemovedSurfaces(data: IEditChartableData): Partial<IChartedRef>[] {
    const currentSurfaces = data.item.chartedSurfaces.map(
      (surface) => surface.chartedRef
    );
    return differenceWith(
      currentSurfaces,
      data.selectedSurfaces,
      isSameChartedRef
    );
  }
}
