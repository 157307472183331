<mat-toolbar color="accent" class="min-h-[50px]">
  {{ data?.formIssue ? 'Edit' : 'Add' }} Generate Form Automation
</mat-toolbar>

<div class="flex grow flex-col overflow-scroll bg-slate-100">
  <form class="flex flex-col gap-4 p-4" [formGroup]="form">
    <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
      <h3 class="!m-0 !font-bold">Automation Settings</h3>
      <mat-form-field class="flex flex-auto" subscriptSizing="dynamic">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>

      @if (data?.practices) {
        <mat-form-field
          class="flex flex-auto"
          subscriptSizing="dynamic"
          floatLabel="always"
        >
          <mat-label>Practice</mat-label>
          <mat-select formControlName="practiceRef" placeholder="All Practices">
            <mat-option [value]="undefined">All Practices</mat-option>
            @for (practice of data?.practices; track practice.ref.path) {
              <mat-option [value]="practice.ref">
                {{ practice.name }}
              </mat-option>
            }
          </mat-select>

          @if (form.controls.practiceRef.value) {
            <button
              mat-icon-button
              matIconSuffix
              (click)="
                $event.stopImmediatePropagation();
                form.controls.practiceRef.reset(undefined)
              "
            >
              <mat-icon>clear</mat-icon>
            </button>
          }
        </mat-form-field>
      }
    </div>

    <h2 class="!m-0 !mt-4 font-bold">When do you want this to run?</h2>

    <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
      <h3 class="!m-0 !font-bold">Automation Timing</h3>

      @if (data?.useRelativeTime) {
        <pr-automation-timing-form
          [value]="form.controls.timing.value"
          (formChange)="timingChange($event)"
        />
      } @else {
        <div fxLayout="row" fxLayoutGap="16px">
          <mat-form-field subscriptSizing="dynamic">
            <mat-label>Trigger Date</mat-label>
            <input
              matInput
              formControlName="triggerDate"
              required
              [matDatepicker]="triggerDate"
              (focus)="triggerDate.open()"
              (click)="triggerDate.open()"
            />
            <mat-datepicker-toggle matIconSuffix [for]="triggerDate" />
            <mat-datepicker #triggerDate />
            @if (form.controls.triggerDate.value) {
              <button
                mat-icon-button
                matIconSuffix
                aria-label="Clear"
                type="button"
                (click)="clearTriggerDate($event)"
              >
                <mat-icon>close</mat-icon>
              </button>
            }
          </mat-form-field>

          <pr-date-selector
            placeholder="Trigger Time"
            formControlName="triggerTime"
            subscriptSizing="dynamic"
            [required]="true"
            [interval]="timeInterval"
          />
        </div>
      }
    </div>

    @if (availableConditions.length) {
      <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
        <div class="flex flex-row items-center gap-2">
          <h3 class="!m-0 !font-bold">Automation Conditions</h3>
          <mat-icon
            class="icon-sm opacity-50"
            [matTooltip]="automationConditionsHelp"
            >info</mat-icon
          >
        </div>
        <pr-conditional-logic-form
          [availableConditions]="availableConditions"
          [defaultCondition]="defaultCondition"
          [conditions]="form.value.requiredConditions"
          (conditionsUpdated)="requiredConditionsChange($event)"
        />
      </div>
    }

    <h2 class="!m-0 !mt-4 font-bold">What do you want to happen?</h2>

    <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
      <h3 class="!m-0 !font-bold">Generate Form for Patient</h3>
      <div class="flex flex-row">
        <mat-form-field class="flex flex-auto" subscriptSizing="dynamic">
          <mat-label>Form to Generate</mat-label>
          <mat-select
            formControlName="formConfigurationRef"
            [compareWith]="compareFn"
          >
            @for (
              formConfiguration of formConfigurations$ | async;
              track formConfiguration.ref.path
            ) {
              <mat-option [value]="formConfiguration.ref">
                {{ formConfiguration.name }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    @if (formIssueConfig) {
      <pr-automation-treatments [config]="formIssueConfig" />
    }
  </form>
</div>

<pt-page-footer>
  <pt-buttons-container padding="none">
    <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
    <button type="submit" mat-flat-button color="primary" (click)="save()">
      Save
    </button>
  </pt-buttons-container>
</pt-page-footer>
