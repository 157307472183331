import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IHistoryItem } from '@principle-theorem/ng-principle-shared';
import { type IInteractionV2 } from '@principle-theorem/principle-core/interfaces';
import {
  type ArchivedDocument,
  DocumentArchive,
  isSameRef,
  multiMap,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

type AmendedInteraction = WithRef<ArchivedDocument<IInteractionV2>>;

@Component({
    selector: 'pr-interaction-amendment-history',
    templateUrl: './interaction-amendment-history.component.html',
    styleUrls: ['./interaction-amendment-history.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InteractionAmendmentHistoryComponent {
  amendmentHistory$ = new ReplaySubject<AmendedInteraction[]>(1);
  selectedInteraction$ = new ReplaySubject<AmendedInteraction>(1);
  historyItems$: Observable<IHistoryItem<AmendedInteraction>[]>;
  selectedHistoryItem$: Observable<
    IHistoryItem<AmendedInteraction> | undefined
  >;

  @Input()
  set amendmentHistory(amendmentHistory: WithRef<AmendedInteraction>[]) {
    if (amendmentHistory) {
      this.amendmentHistory$.next(amendmentHistory);
    }
  }

  @Input()
  set selectedInteraction(selectedInteraction: WithRef<AmendedInteraction>) {
    if (selectedInteraction) {
      this.selectedInteraction$.next(selectedInteraction);
    }
  }

  constructor() {
    this.historyItems$ = this.amendmentHistory$.pipe(
      multiMap((interaction) => this._createInteractionHistoryItem(interaction))
    );
    this.selectedHistoryItem$ = combineLatest([
      this.historyItems$,
      this.selectedInteraction$,
    ]).pipe(
      map(([historyItems, selectedInteraction]) =>
        this._findInteractionHistoryItem(selectedInteraction, historyItems)
      )
    );
  }

  private _findInteractionHistoryItem(
    interaction: AmendedInteraction,
    historyItems: IHistoryItem<AmendedInteraction>[]
  ): IHistoryItem<AmendedInteraction> | undefined {
    return historyItems.find((historyItem) =>
      isSameRef(historyItem.value.ref, interaction)
    );
  }

  private _createInteractionHistoryItem(
    interaction: AmendedInteraction
  ): IHistoryItem<AmendedInteraction> {
    return {
      label: DocumentArchive.getLabel(interaction),
      timestamp: interaction.archivedAt,
      value: interaction,
    };
  }
}
