<mat-toolbar color="accent">Pricing Rule Scope</mat-toolbar>
<div class="layout-margin">
  <p>
    <strong>Per Quadrant</strong><br />
    Used when the the ADA code price changes are broken down by Quadrant.<br />
    <small class="mat-caption"
      >Eg. A 311 where there is a step down fee for each subsequent tooth
      removal or part per quadrant.</small
    >
  </p>
  <p>
    <strong>Per Tooth</strong><br />
    Used when the the ADA code price changes are broken down by Tooth.<br />
    <small class="mat-caption"
      >Eg. A 597 where there is a step down fee for each subsequent post for the
      same tooth.</small
    >
  </p>
  <p>
    <strong>None</strong><br />
    Select None if the pricing should be calculated based on the number of times
    the ADA code was performed.
  </p>
</div>
