import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SchedulingEventActionsService } from './scheduling-event-actions.service';
import {
  ISchedulingEvent,
  ISchedulingEventSummary,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { SchedulingEventSummary } from '@principle-theorem/principle-core';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-scheduling-event-actions',
  templateUrl: './scheduling-event-actions.component.html',
  styleUrls: ['./scheduling-event-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SchedulingEventActionsService],
})
export class SchedulingEventActionsComponent {
  private _schedulingEventSummary$ = new ReplaySubject<
    WithRef<ISchedulingEventSummary>
  >(1);
  schedulingEvent$: Observable<WithRef<ISchedulingEvent>>;

  @Input()
  set schedulingEventSummary(
    schedulingEventSummary: WithRef<ISchedulingEventSummary>
  ) {
    if (schedulingEventSummary) {
      this._schedulingEventSummary$.next(schedulingEventSummary);
    }
  }

  constructor(public actions: SchedulingEventActionsService) {
    this.schedulingEvent$ = this._schedulingEventSummary$.pipe(
      switchMap((summary) => SchedulingEventSummary.schedulingEvent$(summary))
    );
  }
}
