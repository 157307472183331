import type * as moment from 'moment-timezone';
import {
  MockTotalExpensesStatistic,
  MockRevenueKPIStatistic,
  MockGrossIncomeStatistic,
} from './base-statistics';
import { type BrandStatistics } from './brand-statistics';
import {
  type IStatistics,
  type IOrganisationStats,
} from '@principle-theorem/reporting';

export class OrganisationStatistics implements IStatistics<IOrganisationStats> {
  name = '';
  date: moment.Moment;

  brands: BrandStatistics[];

  stats: IOrganisationStats = {
    revenue: MockGrossIncomeStatistic(),
    costs: MockTotalExpensesStatistic(),
    revenueKPI: MockRevenueKPIStatistic(),
  };
}
