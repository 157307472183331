@if (customFormConfig$ | async; as customFormConfig) {
  <div class="flex min-h-[44px] flex-row items-center gap-4">
    <div class="flex grow flex-row items-center">
      <button mat-icon-button [routerLink]="['../']">
        <mat-icon>chevron_left</mat-icon>
      </button>

      <h3 class="!m-0 !font-bold">Update {{ customFormConfig.name }}</h3>
    </div>

    <button
      mat-stroked-button
      [disabled]="formIsEmpty$ | async"
      (click)="clearForm()"
    >
      Clear Form
    </button>
  </div>

  <div class="rounded-md bg-white p-4 shadow">
    @if (submitting$ | async) {
      <div class="flex flex-col items-center justify-center">
        <mat-spinner />
        <p class="mat-body-2">Saving {{ customFormConfig.name }}</p>
      </div>
    } @else {
      @if (form$ | async) {
        <pr-custom-form
          [form]="form$ | async"
          (formChanges)="formData$.next($event)"
          (submitted)="submit($event)"
        />
      } @else {
        <div class="flex flex-col items-center justify-center">
          <mat-spinner />
          <p class="mat-body-2">Loading {{ customFormConfig.name }}</p>
        </div>
      }
    }
  </div>
}
