import {
  IPatientMetadataDisplay,
  PatientMetadataDisplayFormat,
  PatientMetadataDisplayTarget,
  PatientMetadataValue,
} from '@principle-theorem/principle-core/interfaces';
import { AtLeast } from '@principle-theorem/shared';

export class PatientMetadataDisplay {
  static init(
    overrides: AtLeast<IPatientMetadataDisplay, 'label' | 'metadataKey'>
  ): IPatientMetadataDisplay {
    return {
      deleted: false,
      displayTargets: [],
      format: PatientMetadataDisplayFormat.LabelValue,
      hexColour: '#000000',
      onlyShowIfHasValue: true,
      ...overrides,
    };
  }

  static buildFormattedValue(
    metadataDisplay: IPatientMetadataDisplay,
    rawValue?: PatientMetadataValue
  ): string | undefined {
    const value = Array.isArray(rawValue) ? rawValue.join(', ') : rawValue;
    if (metadataDisplay.onlyShowIfHasValue && !value) {
      return;
    }
    switch (metadataDisplay.format) {
      case PatientMetadataDisplayFormat.Label:
        return metadataDisplay.label;
      case PatientMetadataDisplayFormat.Value:
        return `${value}`;
      default:
        return `${metadataDisplay.label}: ${value}`;
    }
  }

  static filterByTarget<T extends IPatientMetadataDisplay>(
    displays: T[],
    target: PatientMetadataDisplayTarget
  ): T[] {
    return displays.filter((display) =>
      display.displayTargets.includes(target)
    );
  }

  static coerceStringValue(
    stringValue?: string
  ): PatientMetadataValue | undefined {
    if (!stringValue) {
      return;
    }
    if (stringValue === 'false') {
      return false;
    }
    if (stringValue === 'true') {
      return true;
    }

    const stringArray = stringValue.split(',').map((s) => s.trim());
    if (stringArray.length > 1) {
      return stringArray;
    }

    const parsedNumber = parseFloat(stringValue);
    if (!isNaN(parsedNumber)) {
      return parsedNumber;
    }

    return stringValue;
  }
}
