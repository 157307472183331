import {
  IExpectedSourceRecordSize,
  SourceEntityMigrationType,
  type IPracticeMigration,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  TypeGuard,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { flow, groupBy, isBoolean, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { runQuery } from '../../../source/connection';
import { SourceEntity } from './../../../source/source-entity';

export const FEE_SCHEDULE_RESOURCE_TYPE = 'feeSchedule';

export const FEE_SCHEDULE_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Fee Schedules',
    description: '',
    idPrefix: FEE_SCHEDULE_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

interface IPraktikaFeeScheduleResult
  extends IPraktikaFeeSchedule,
    IPraktikaFeeScheduleItem {}

export interface IPraktikaFeeSchedule {
  id: number;
  practice_id: number | null;
  name: string;
  is_primary: boolean;
  type_id: number;
  is_shared: boolean;
  is_default: boolean;
  effective_from: string; // 2021-04-08 10:02:24
  effective_to: string | null; // 2021-04-08 10:02:24
  items: IPraktikaFeeScheduleItem[];
}

export function isPraktikaFeeSchedule(
  item: unknown
): item is IPraktikaFeeSchedule {
  return TypeGuard.interface<IPraktikaFeeSchedule>({
    id: isNumber,
    practice_id: TypeGuard.nilOr(isNumber),
    name: isString,
    is_primary: isBoolean,
    type_id: isNumber,
    is_shared: isBoolean,
    is_default: isBoolean,
    effective_from: isString,
    effective_to: TypeGuard.nilOr(isString),
    items: TypeGuard.arrayOf(isPraktikaFeeScheduleItem),
  })(item);
}

export interface IPraktikaFeeScheduleItem {
  item_id: number;
  code_id: number;
  code: string;
  price: number;
  is_frequent_procedure: boolean;
  custom_code: string | null;
  custom_description: string | null;
  override_tax_code: string | number | null;
  recommended_duration: number;
}

export function isPraktikaFeeScheduleItem(
  item: unknown
): item is IPraktikaFeeScheduleItem {
  return TypeGuard.interface<IPraktikaFeeScheduleItem>({
    item_id: isNumber,
    code_id: isNumber,
    code: isString,
    price: isNumber,
    is_frequent_procedure: isBoolean,
    custom_code: TypeGuard.nilOr(isString),
    custom_description: TypeGuard.nilOr(isString),
    override_tax_code: TypeGuard.nilOr(TypeGuard.isOneOf(isString, isNumber)),
    recommended_duration: isNumber,
  })(item);
}

const FEE_SCHEDULE_SOURCE_QUERY = `
SELECT
  fee_schedule.id,
  fee_schedule.name,
  fee_schedule.is_primary,
  fee_schedule.type_id,
  fee_schedule.is_shared,
  fee_schedule.is_default,
  fee_schedule.effective_from,
  fee_schedule.effective_to,
  fee_schedule_item.item_id,
  fee_schedule_item.fee_schedule_id,
  fee_schedule_item.code_id,
  fee_schedule_item.price,
  fee_schedule_item.is_frequent_procedure,
  fee_schedule_item.custom_code,
  fee_schedule_item.custom_description,
  fee_schedule_item.override_tax_code,
  fee_schedule_item.recommended_duration,
  ada_code.code
FROM (
  SELECT
    convert_to_integer(iFeeScheduleId) AS id,
    vchFeeScheduleName AS name,
    convert_to_boolean(bPrimary) AS is_primary,
    convert_to_integer(iFeeScheduleTypeId) AS type_id,
    convert_to_boolean(bShared) AS is_shared,
    convert_to_boolean(bDefault) AS is_default,
    dtEffFrom AS effective_from,
    dtEffTo AS effective_to
  FROM practice_fee_schedules
  ) AS fee_schedule
INNER JOIN (
  SELECT
    convert_to_integer(iItemId) AS item_id,
    convert_to_integer(iFeeScheduleId) AS fee_schedule_id,
    convert_to_integer(iCodeId) AS code_id,
    convert_to_decimal(Fee) AS price,
    convert_to_boolean(bFrequentProcedure) AS is_frequent_procedure,
    vchCustomCode AS custom_code,
    vchCustomDescription AS custom_description,
    OverrideTaxCode AS override_tax_code,
    convert_to_integer(iRecommendedDuration) AS recommended_duration,
    dtEffFrom AS effective_from
  FROM practice_fee_schedules_item
) AS fee_schedule_item
ON fee_schedule.id = fee_schedule_item.fee_schedule_id
INNER JOIN (
  SELECT
    convert_to_integer(iCodeId) AS code_id,
    convert_to_integer(iGroupId) AS group_id,
    convert_to_integer(iTypeId) AS type_id,
    vchCode AS code,
    vchADACodeRef AS ada_code_ref,
    vchCodeDescShort AS code_desc_short,
    vchCodeDescFull AS code_desc_full,
    convert_to_boolean(bRequiresToothNumber) AS requires_tooth_number,
    convert_to_integer(iRequiresToothSurfaces) AS requires_tooth_surfaces,
    convert_to_integer(iTaxCodeId) AS tax_code_id,
    dtEffFrom AS effective_from
  FROM  practice_fee_schedules_code
) AS ada_code
ON fee_schedule_item.code_id = ada_code.code_id
ORDER BY fee_schedule.id ASC`;

export class FeeScheduleSourceEntity extends BaseSourceEntity<IPraktikaFeeSchedule> {
  sourceEntity = FEE_SCHEDULE_SOURCE_ENTITY;
  entityResourceType = FEE_SCHEDULE_RESOURCE_TYPE;
  sourceQuery = FEE_SCHEDULE_SOURCE_QUERY;
  verifySourceFn = isPraktikaFeeSchedule;
  override transformDataFn = flow([transformFeeScheduleResults]);

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const response = await runQuery<IPraktikaFeeScheduleResult>(
      migration,
      this.sourceQuery
    );

    const expectedSize = transformFeeScheduleResults(response.rows).length;

    return {
      expectedSize,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }

  translate(_staffer: IPraktikaFeeSchedule): object {
    return {};
  }

  getSourceRecordId(data: IPraktikaFeeSchedule): number {
    return data.id;
  }

  getSourceLabel(data: IPraktikaFeeSchedule): string {
    return data.name;
  }
}

function transformFeeScheduleResults(
  feeScheduleResults: IPraktikaFeeScheduleResult[]
): IPraktikaFeeSchedule[] {
  const feeScheduleGroups = groupBy(
    feeScheduleResults,
    (result) => `${result.id}`
  );
  return Object.values(feeScheduleGroups).map((items) => ({
    id: items[0].id,
    practice_id: items[0].practice_id,
    name: items[0].name,
    is_primary: items[0].is_primary,
    type_id: items[0].type_id,
    is_shared: items[0].is_shared,
    is_default: items[0].is_default,
    effective_from: items[0].effective_from,
    effective_to: items[0].effective_to,
    items: items.map((item) => ({
      item_id: item.item_id,
      code_id: item.code_id,
      code: item.code,
      price: parseFloat(item.price.toString()),
      is_frequent_procedure: item.is_frequent_procedure,
      custom_code: item.custom_code,
      custom_description: item.custom_description,
      override_tax_code: item.override_tax_code,
      recommended_duration: item.recommended_duration,
    })),
  }));
}
