import {
  INamedDocument,
  type DocumentReference,
  type ISODateType,
  type ITimestampRange,
  type WithRef,
  isObject,
} from '@principle-theorem/shared';
import { AppointmentStatus, IAppointment } from '../appointment/appointment';
import { IAppointmentDependency } from '../appointment/appointment-dependency';
import {
  ICalendarEvent,
  type ICalendarEventSchedule,
} from '../event/calendar-event';
import { type IEvent } from '../event/event';
import { type IPractice } from '../practice/practice';
import { type IStaffer } from '../staffer/staffer';
import { ITag } from '../tag/tag';
import { ITreatmentCategory } from '../treatment-category';
import { IPatient } from '../patient/patient';

type CommonEventProperties = {
  label: string;
  pinnedNotes: string[];
  tags: INamedDocument<ITag>[];
};

type AppointmentProperties = {
  patientRef: DocumentReference<IPatient>;
  status: AppointmentStatus;
  dependencies: IAppointmentDependency[];
  categoryRef?: DocumentReference<ITreatmentCategory>;
  treatmentPlanName: string;
  treatmentStepName: string;
};

type CalendarEventProperties = {
  scheduleRef?: DocumentReference<ICalendarEventSchedule>;
};

export type ScheduleSummaryEventMetadata<T extends object = object> =
  CommonEventProperties &
    (T extends IAppointment ? AppointmentProperties : CalendarEventProperties);

export type AppointmentSummary = IScheduleSummaryEventable<IAppointment>;
export type CalendarEventSummary = IScheduleSummaryEventable<ICalendarEvent>;

export interface IScheduleSummaryEvent<T extends object = object> {
  event: IEvent;
  ref: DocumentReference<T>;
  isBlocking: boolean;
  metadata: ScheduleSummaryEventMetadata<T>;
}

export interface IScheduleSummaryEventable<T extends object = object>
  extends Omit<IScheduleSummaryEvent<T>, 'ref'> {
  ref?: DocumentReference<T>;
  uid: string;
}

export interface IScheduleSummaryTarget {
  staffer: DocumentReference<IStaffer>;
  practice: DocumentReference<IPractice>;
  day: ISODateType;
}

export interface IScheduleSummary extends IScheduleSummaryTarget {
  events: IScheduleSummaryEvent[];
  gaps: ITimestampRange[];
}

export interface IScheduleSummaryUpsertAction {
  target: IScheduleSummaryTarget;
  data: IScheduleSummary;
  existing?: WithRef<IScheduleSummary>;
}

export function isAppointmentSummary(
  item: unknown
): item is AppointmentSummary {
  return (
    isObject(item) &&
    'event' in item &&
    'isBlocking' in item &&
    'metadata' in item &&
    isAppointmentMetadata(item.metadata)
  );
}

function isAppointmentMetadata(item: unknown): item is AppointmentProperties {
  return (
    isObject(item) &&
    'patientRef' in item &&
    'status' in item &&
    'dependencies' in item &&
    'treatmentPlanName' in item &&
    'treatmentStepName' in item
  );
}

export function isCalendarEventSummary(
  item: unknown
): item is CalendarEventSummary {
  return (
    isObject(item) &&
    'event' in item &&
    'isBlocking' in item &&
    'metadata' in item &&
    !isAppointmentMetadata(item.metadata)
  );
}
