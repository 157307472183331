/* eslint-disable @typescript-eslint/naming-convention */

/**
 * Refers to the Source that the CCV was obtained.
 */
export enum CCVSource {
  'Unknown' = 1,
  'Telephone' = 2,
  'Mail' = 3,
  'Internet' = 4,
}

/**
 * Refers to the Reason that the CCV was NOT ABLE TO BE OBTAINED.
 */
export enum CCVReason {
  'NoneOnCard' = 1,
  'NotReadable' = 2,
  'Bypassed' = 3,
}

/**
 * Indicates the period of referral.
 *
 * This field must be entered if you have entered a referring provider number
 * and referral issue date.
 *
 * Please refer to the EasyClaim Reference Guide on the
 * Medicare Website for more information.
 *
 * 'S' = Standard
 * 'I' = Indefinite
 */
export type ReferralPeriodType = 'S' | 'I';

/**
 * This field must be set if NO Referring Provider Number is Set.
 *
 * It indicates to Medicare why referred services were provided without
 * referral from another provider.
 *
 * Lost or emergency referral indication
 *
 * This data is only required in instances where a written referral was
 * lost or in the case of an emergency situation where the servicing
 * provider was of the opinion that the service needed to be rendered as
 * quickly as possible.
 *
 * Lost and emergency referrals are applicable to initial consultation
 * items only. All referral requirements must be met for subsequent
 * consultation items.
 *
 * 'L' = Lost Referral
 * 'E' = Emergency
 * 'N' = Not Required - May need to be entered where referral details
 *       are not required for certain Items.
 *
 * Please refer to the EasyClaim Reference Guide on the
 * Medicare Website for more information.
 */
export type ReferralOverrideType = 'L' | 'E' | 'N';

export interface SaleResponse extends BaseResponse {
  ApprovalCode: string;
  AuthResponseCode: string;
  ExpiryDate: string;
  InvoiceNumber: string;
  MerchantId: string;
  PrimaryAccountNumber: string;
  PrintReceiptData: string;
  ProviderName: string;
  RrnNumber: string;
  SurchargeAmount: number;
  TerminalId: string;
  TransactionAmount: number;
  TransactionDate: string;
}

export interface SaleCashoutResponse extends SaleResponse {
  CashoutAmount: number;
}

export interface EftposTransListingResponse extends SaleCashoutResponse {
  SaleTransactionList: SaleResponse[];
  SaleCashoutTransactionList: SaleCashoutResponse[];
  RefundTransactionList: RefundResponse[];
  EftposDepositTransactionList: EftposDepositResponse[];
  CashoutTransactionList: CashoutResponse[];
  SubTransCode: string;
  TransactionDate: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetTerminalRequest extends BaseRequest {}

export interface GetTerminalResponse extends BaseResponse {
  ServerData: string[];
  TerminalList: string[];
  TerminalListStr: string[];
}

export interface HicapsTotalsRequest extends BaseRequest {
  PrintReceiptOnTerminal: boolean;
  ProviderNumberId: string;
  TodayTransactions: boolean;
}

export interface HicapsTotalsResponse extends BaseResponse {
  HicapsTotalsListDetails: string[];
  HicapsTotalsListDetailsStr: string[];
  TransactionDate: string;
}

export interface HicapsTransListingRequest extends BaseRequest {
  ProviderNumberId: string;
}

export interface HicapsTransListingResponse extends ClaimResponse {
  ClaimCancelTransactionList: ClaimCancelResponse[];
  ClaimTransactionList: ClaimResponse[];
  QuoteTransactionList: QuoteResponse[];
  SubTransCode: string;
}

export enum ClaimTypeCde {
  /**
   * The PatientContributionAmount field must be 0
   */
  FullyPaid = 1,

  /**
   * The PatientContributionAmount field must be greater than 0.
   * The PatientContributionAmount field must be less than the charge.
   */
  PartPaid = 2,

  /**
   * The PatientContributionAmount field must be 0.
   * The charge must be greater than 0.
   */
  UnPaid = 3,

  /**
   * The ChargeAmount must be 0.
   * The PatientContributionAMount field must be 0.
   */
  BulkBillClaim = 4,
}

export enum ServiceTypeCde {
  General = 'G',
  Optical = 'O',
  Specialist = 'S',
  Pathology = 'P',
  Diagnostic = 'D',
}

export interface MedicareClaimRequest extends BaseRequest {
  ClaimType: ClaimTypeCde;
  ServiceTypeCde: ServiceTypeCde;
  ServicingProviderNum: string;
  RequestPeriodTypeCde?: string;
  RequestOverrideTypeCde?: string;
  RequestIssueDate?: string;
  RequestingProviderNum?: string;
  ReferringProviderNum?: string;
  ReferralPeriodTypeCde?: ReferralPeriodType;
  ReferralOverrideTypeCde?: ReferralOverrideType;
  ReferralIssueDate?: string;
  PayeeProviderNum?: string;
  PatientMedicareCardNum: string;
  PatientIRN: string;
  ClaimDetails?: string[];
  ClaimantMedicareCardNum?: string;
  ClaimantIRN?: string;
  CevRequestInd: 'Y' | 'N';
  BenefitAssigned?: number;
  AccountReferenceId: string;
  TransactionAmount: number;
}

export interface MedicareClaimResponse extends BaseResponse {
  PrimaryAccountNumber: string;
  ReferralIssueDate?: string;
  ReferralOverrideTypeCde?: ReferralOverrideType;
  ReferralPeriodTypeCde?: ReferralPeriodType;
  ReferringProviderName?: string;
  ReferringProviderNum?: string;
  RequestingProviderNum: string;
  RequestIssueDate: string;
  RequestOverrideTypeCde: string;
  RequestTypeCde: ServiceTypeCde;
  ServiceTypeCde: ServiceTypeCde;
  ServicingProviderName: string;
  ServicingProviderNum: string;
  TerminalId: string;
  TransactionAmount: number;
  PayeeProviderNum?: string;
  TransactionDate: string;
  PatientMedicareCardNum: string;
  PatientIRN: string;
  AcceptanceTypeCde: string;
  AccountPaidInd: string;
  AccountReferenceId: string;
  AssessmentErrorCde: string;
  ClaimantFirstName: string;
  ClaimantIRN: string;
  PatientLastName: string;
  ClaimantLastName: string;
  MedicareClaimDetails: string[];
  MedicareClaimDetailsStr: string[];
  MedicareEligibilityStatus: string;
  MedicareError: string;
  MerchantId: string;
  PatientFirstName: string;
  ClaimantMedicareCardNum: string;
  TransactionId: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MedicareMerchantListRequest extends BaseRequest {}

export interface MedicareMerchantListResponse extends BaseResponse {
  MedicareMerchantListDetails: string[];
  MedicareMerchantListDetailsStr: string[];
  TransactionDate: string;
}

export interface MerchantListResponse extends BaseResponse {
  MerchantListDetails: string[];
  MerchantListDetailsStr: string[];
  TransactionDate: string;
}

export interface EftposTransListingRequest extends BaseRequest {
  MerchantId?: string;
}

export interface PrintLastReceiptRequest extends BaseRequest {
  PrintReceiptOnTerminal: boolean;
}

export interface PrintLastReceiptResponse extends ClaimResponse {
  InvoiceNumber: string;
  RrnNumber: string;
  SubTransCode: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface QuoteRequest extends ClaimRequest {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface QuoteResponse extends ClaimResponse {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface QuotePharmRequest extends ClaimPharmRequest {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface QuotePharmResponse extends ClaimPharmResponse {}

export interface SaleRequest extends BaseRequest {
  MerchantId: string;
  /**
   * Must be > 0
   */
  TransactionAmount: number;
  PrimaryAccountNumber?: string;
  CCV?: string;
  CCVReason?: CCVReason;
  CCVSource?: CCVSource;
  ExpiryDate?: string;
  // PrintCustomerReceipt: boolean;
  // PrintCustomerReceiptPrompt: boolean;
  // PrintMerchantReceipt: boolean;
  // PromptCardDetails: boolean;
}

export interface SaleCashoutRequest extends SaleRequest {
  /**
   * Must be > 0
   */
  CashoutAmount: number;
}

export interface SettlementRequest extends BaseRequest {
  /**
   * Normally set to 1
   */
  AcquirerId: string;
  MerchantId: string;
  PrintTxnListing: boolean;
  SettlementType: string;
}

export interface SettlementResponse extends BaseResponse {
  AcquirerId: string;
  AuthResponseCode: string;
  MerchantId: string;
  ProviderName: string;
  TerminalId: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StatusResponse extends BaseResponse {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TerminalTestRequest extends BaseRequest {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MerchantListRequest extends BaseRequest {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EftposDepositResponse extends RefundResponse {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AllItemResponseCodeListRequest extends BaseRequest {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EftposDepositRequest extends RefundRequest {}

// My testing reveals ServerURl isn't required, an empty object would suffice in these requests
export interface BaseMessage {
  ServerUrl: string;
  // MoreIndicator: number;
  // RequestResponseIndicator: number;
  // FormatVersion: number;
  // MsgId: string;
  // ReadOnly: boolean;
  // ComputerName: string;
  SoftwareVendorName: string;
}

export interface BaseRequest extends BaseMessage {
  // ClientVersion: string;
  PmsKey: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AllItemListRequest extends BaseRequest {}

export interface BaseResponse extends BaseMessage {
  ClientVersion: string;
  ComPort: string;
  ResponseCode: string;
  ResponseText: string;
  ResponseTime: string;
  ServerVersion: string;
  TerminalVersion: string;
}

export interface AllItemListResponse extends BaseResponse {
  ItemListDescription: string[];
  ItemListDescriptionStr: string[];
  TransactionDate: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AllPharmItemListRequest extends AllItemListRequest {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AllPharmItemListResponse extends AllItemListResponse {}

export interface TerminalTestResponse extends BaseResponse {
  MerchantId: string;
  TerminalId: string;
  TransactionDate: string;
}

export interface AllItemResponseCodeListResponse extends BaseResponse {
  ItemResponseCodeDescription: string[];
  TransactionDate: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AllMerchantListRequest extends BaseRequest {}

export interface AllMerchantListResponse extends BaseResponse {
  MerchantListDetails: {
    terminalId: string;
    merchantId: string;
    providerName: string;
  }[];
  MerchantListDetailsStr: string[];
  TransactionDate: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AllProviderListRequest extends BaseRequest {}

export interface AllProviderListResponse extends BaseResponse {
  ProviderListDetails: {
    terminalId: string;
    merchantId: string;
    providerNumberId: string;
    providerName: string;
    providerType: string;
    payeeProviderNumberId: string;
    terminalNumber: string;
  }[];
  ProviderListDetailsStr: string[];
  TransactionDate: string;
}

export interface RefundResponse extends BaseResponse {
  ApprovalCode: string;
  AuthResponseCode: string;
  ExpiryDate: string;
  InvoiceNumber: string;
  MerchantId: string;
  PrimaryAccountNumber: string;
  PrintReceiptData: string;
  ProviderName: string;
  RrnNumber: string;
  SurchargeAmount: number;
  TerminalId: string;
  TerminalSwipe: string;
  TransactionAmount: number;
  TransactionDate: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AllTransCodeListRequest extends BaseRequest {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CardListRequest extends BaseRequest {}

export interface CardListResponse extends BaseResponse {
  CardFundDetails: string[];
  CardFundDetailsStr: string[];
  TransactionDate: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CardReadRequest extends BaseRequest {}

export interface CardReadResponse extends BaseResponse {
  ExpiryDate: string;
  PrimaryAccountNumber: string;
  SecondTrack: string;
  TrackData: string;
  TransactionDate: string;
}

export interface CashoutRequest extends BaseRequest {
  MerchantId: string;
  CashoutAmount: number;
  PrimaryAccountNumber?: string;
  CCV?: string;
  CCVReason?: CCVReason;
  CCVSource?: CCVSource;
  ExpiryDate?: string;
  PrintCustomerReceipt: boolean;
  PrintCustomerReceiptPrompt: boolean;
  PrintMerchantReceipt: boolean;
}

export interface CashoutResponse extends BaseResponse {
  ApprovalCode: string;
  AuthResponseCode: string;
  CashoutAmount: number;
  ExpiryDate: string;
  InvoiceNumber: string;
  MerchantId: string;
  PrimaryAccountNumber: string;
  PrintReceiptData: string;
  ProviderName: string;
  RrnNumber: string;
  SurchargeAmount: number;
  TerminalId: string;
  TerminalSwipe: string;
  TransactionDate: string;
}

export interface ClaimCancelRequest extends BaseRequest {
  ProviderNumberId: string;
  TransactionAmount: number;
  RrnNumber: string;
  PrimaryAccountNumber?: string;
  NonSettlementFlag?: boolean;
  PatientNameDetails?: string[];
  PrintReceiptOnTerminal: boolean;
}

export interface ClaimCancelResponse extends BaseResponse {
  BenefitAmount: number;
  ClaimDetails: string[];
  ClaimDetailsStr: string[];
  ExpiryDate: string;
  MembershipId: string;
  MerchantId: string;
  PrimaryAccountNumber: string;
  ProviderName: string;
  ProviderNumberId: string;
  RrnNumber: string;
  TerminalId: string;
  TransactionDate: string;
}

export interface ClaimRequest extends BaseRequest {
  ProviderNumberId: string;
  PrimaryAccountNumber?: string;
  ClaimDetails?: string[];
  MembershipId: string;
  NonSettlementFlag?: boolean;
  PatientNameDetails?: string[];
  PrintReceiptOnTerminal: boolean;
  TransactionAmount: number;
}

export interface ClaimPharmRequest extends ClaimRequest {
  ScriptDetails: string[];
}

export interface ClaimResponse extends BaseResponse {
  BenefitAmount: number;
  ClaimDetails: string[];
  ClaimDetailsStr: string[];
  ExpiryDate: string;
  MembershipId: string;
  MerchantId: string;
  PatientNameDetails: string[];
  PatientNameDetailsStr: string[];
  PrimaryAccountNumber: string;
  ProviderName: string;
  ProviderNumberId: string;
  RrnNumber: string;
  TerminalId: string;
  TransactionAmount: number;
  TransactionDate: string;
}

export interface ClaimPharmResponse extends ClaimResponse {
  ScriptDetails: string[];
  ScriptDetailsStr: string[];
}

export interface RefundRequest extends BaseRequest {
  MerchantId: string;
  /**
   * Must be > 0
   */
  TransactionAmount: number;
  CCV?: string;
  CCVReason?: CCVReason;
  CCVSource?: CCVSource;
  ExpiryDate?: string;
  // MerchantPassword: string;
  // PrimaryAccountNumber: string;
  // PrintCustomerReceipt: boolean;
  // PrintCustomerReceiptPrompt: boolean;
  // PrintMerchantReceipt: boolean;
  // PromptCardDetails: boolean;
}

export interface AllTransCodeListResponse extends BaseResponse {
  TransactionDate: string;
  TransCodeDescription: string[];
}
