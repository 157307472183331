import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { initVersionedSchema, MixedSchema } from '@principle-theorem/editor';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import {
  isDisabled$,
  NG_SHARED_CONFIG,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
  type INgSharedConfig,
} from '@principle-theorem/ng-shared';
import {
  TemplateScope,
  TemplateType,
  type IBrand,
  type IInteractionData,
  type IPractice,
  type ITemplatesWithContext,
  type WithTemplateResource,
} from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, combineLatest, Subject, type Observable } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import {
  MentionToTemplates,
  type IPopulatedTemplate,
} from '../contextual-actions/interaction-actions/template-context-resolvers/mention-to-templates';
import { filterTemplatesWithContextByScopes } from '../sms/sms.component';
import { IEmailFormData, SendEmailService } from './send-email.service';

@Component({
  selector: 'pr-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class EmailComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _sharedConfig: INgSharedConfig = inject(NG_SHARED_CONFIG);
  trackByTemplate = TrackByFunctions.field<IPopulatedTemplate>('name');
  brands$: Observable<WithRef<IBrand>[]>;
  submitting$ = new BehaviorSubject<boolean>(false);
  isDisabled$: Observable<boolean>;
  templates$: Observable<ITemplatesWithContext>;
  emailForm = new TypedFormGroup<IEmailFormData>({
    subject: new TypedFormControl<string>('', Validators.required),
    practice: new TypedFormControl<WithRef<IPractice>>(
      undefined,
      Validators.required
    ),
    content: new TypedFormControl<MixedSchema>(
      initVersionedSchema(),
      Validators.required
    ),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: WithTemplateResource<IInteractionData>,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EmailComponent>,
    private _sendEmailService: SendEmailService,
    private _currentScope: CurrentScopeFacade
  ) {
    const brand$ = this._currentScope.currentBrand$.pipe(filterUndefined());
    this.brands$ = brand$.pipe(map((brand) => [brand]));
    this.isDisabled$ = isDisabled$(this.emailForm);
    this._currentScope.currentPractice$
      .pipe(filterUndefined(), takeUntil(this._onDestroy$))
      .subscribe((practice) => this.emailForm.patchValue({ practice }));

    const practice$ =
      this._currentScope.currentPractice$.pipe(filterUndefined());

    this.templates$ = combineLatest([brand$, practice$]).pipe(
      switchMap(([brand, practice]) => {
        const mentionToTemplates = new MentionToTemplates(
          practice.ref,
          this._sharedConfig.appUrl
        );
        return mentionToTemplates.getTemplates(
          data.templateResource,
          brand.ref,
          TemplateType.Html
        );
      }),
      filterUndefined(),
      map((templates) =>
        filterTemplatesWithContextByScopes(templates, [
          TemplateScope.Appointment,
          TemplateScope.Invoice,
        ])
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async submit(): Promise<void> {
    if (!this.emailForm.valid) {
      return;
    }
    this.submitting$.next(true);
    const formData = this.emailForm.getRawValue();
    const error = await this._sendEmailService.send(
      this.data.contact,
      formData
    );

    if (error) {
      this.submitting$.next(false);
      this._snackBar.open(error.message);
      this.dialogRef.close();
      return;
    }

    this.submitting$.next(false);
    this._snackBar.open('Email Sent Successfully');
    this.dialogRef.close();
  }

  templateSelected(template: IPopulatedTemplate): void {
    this.emailForm.patchValue({ content: template.content });
  }
}
