import { rand, randNumber } from '@ngneat/falso';
import {
  ChartedItemType,
  IChartedTreatment,
  IFeeSchedule,
  IInvoice,
  IStaffer,
  ITreatmentConfiguration,
  SCOPED_SURFACES,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  MockTimestamp,
  Properties,
} from '@principle-theorem/shared';
import { MockDocRef, MockNamedDocument } from '@principle-theorem/testing';
import { v4 as uuid } from 'uuid';
import { MockChartedRef } from './charted-treatment.mock';
import { MockChartableSurfaces } from '../core/chartable-surface.mock';
import { TaxStrategy } from '@principle-theorem/accounting';

export class TreatmentMock
  extends BaseFirestoreMock
  implements Properties<IChartedTreatment>
{
  type: ChartedItemType.ChartedTreatment = ChartedItemType.ChartedTreatment;
  feeSchedule = MockNamedDocument<IFeeSchedule>();
  uuid = uuid();
  config = MockNamedDocument<ITreatmentConfiguration>();
  chartedRef = MockChartedRef();
  surface = rand(MockChartableSurfaces());
  chartedAt = MockTimestamp();
  chartedBy = MockNamedDocument<IStaffer>();
  attributedTo = MockNamedDocument<IStaffer>();
  resolvedAt = MockTimestamp();
  resolvedBy = MockNamedDocument<IStaffer>();
  price = randNumber({ min: 25, max: 4000 });
  serviceCodeSmartGroups = [];
  serviceCodeGroups = [];
  serviceCodes = [];
  chartedSurfaces = [];
  notes = [];
  basePrice = 0;
  isGrouped = false;
  invoices = [
    {
      invoiceRef: MockDocRef<IInvoice>(),
      lineItemUid: uuid(),
      amount: randNumber({ min: 25, max: 4000 }),
    },
  ];
  scopeRef = {
    scope: rand(SCOPED_SURFACES),
  };
  treatmentPackageId = uuid();
  taxStatus = TaxStrategy.GSTFree;
}
