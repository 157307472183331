import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_NOTE_CATEGORY_RESOURCE_TYPE = 'patientNoteCategories';

export const PATIENT_NOTE_CATEGORY_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Note Categories',
      description: '',
      idPrefix: PATIENT_NOTE_CATEGORY_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IOasisPatientNoteCategory {
  id: number;
  name: string;
}

export function isOasisPatientNoteCategory(
  item: unknown
): item is IOasisPatientNoteCategory {
  return TypeGuard.interface<IOasisPatientNoteCategory>({
    id: isNumber,
    name: isString,
  })(item);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOasisPatientNoteCategoryTranslations {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOasisPatientNoteCategoryFilters {}

const PATIENT_NOTE_CATEGORY_SOURCE_QUERY = `
SELECT
	convert_to_integer(REGEXP_REPLACE(SKEY, 'CLNCATGSE', '')) AS id,
	F1 AS name
FROM SYTBLENT
WHERE SKEY LIKE 'CLNCATGSE%'
`;

export class PatientNoteCategorySourceEntity extends BaseSourceEntity<
  IOasisPatientNoteCategory,
  IOasisPatientNoteCategoryTranslations,
  IOasisPatientNoteCategoryFilters
> {
  sourceEntity = PATIENT_NOTE_CATEGORY_SOURCE_ENTITY;
  entityResourceType = PATIENT_NOTE_CATEGORY_RESOURCE_TYPE;
  sourceQuery = PATIENT_NOTE_CATEGORY_SOURCE_QUERY;
  verifySourceFn = isOasisPatientNoteCategory;

  translate(
    _data: IOasisPatientNoteCategory,
    _timezone: Timezone
  ): IOasisPatientNoteCategoryTranslations {
    return {};
  }

  getSourceRecordId(data: IOasisPatientNoteCategory): number {
    return data.id;
  }

  getSourceLabel(data: IOasisPatientNoteCategory): string {
    return `${data.id} - ${data.name}`;
  }

  getFilterData(
    _data: IOasisPatientNoteCategory,
    _timezone: Timezone
  ): IOasisPatientNoteCategoryFilters {
    return {};
  }
}
