<div fxLayout="column" fxLayoutGap="16px">
  <div fxLayout="row" fxLayoutGap="16px">
    <pt-search-field fxFlex>
      <input matInput placeholder="Search" [formControl]="searchCtrl" />
    </pt-search-field>

    <button mat-raised-button color="primary" (click)="captureMedia()">
      Capture
    </button>
    <pr-integration-display
      [patient]="patient$ | async"
      [appIntegrationFeature]="appIntegrationFeature"
    />
  </div>

  <div
    *ngIf="(media$ | async)?.length"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div>
      <div fxLayout="row" fxLayoutGap="16px">
        <div class="checkbox">
          <mat-checkbox
            color="primary"
            [indeterminate]="selectionList.isSomeSelected$ | async"
            [checked]="selectionList.isAllSelected$ | async"
            (change)="selectionList.toggleAllSelected()"
          />
        </div>
        <ng-container *ngIf="(selectionList.isNoneSelected$ | async) === false">
          <mat-divider [vertical]="true" />

          <button
            mat-icon-button
            matTooltip="Download"
            (click)="downloadSelected()"
          >
            <mat-icon>cloud_download</mat-icon>
          </button>
          <button
            mat-icon-button
            color="warn"
            matTooltip="Delete"
            (click)="deleteSelected()"
          >
            <mat-icon>delete</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Edit Tags"
            (click)="editSelectedTags()"
          >
            <mat-icon>label</mat-icon>
          </button>
        </ng-container>
      </div>
    </div>

    <pr-media-settings />
  </div>

  <pt-file-upload-to-storage
    *ngIf="patient$ | async as patient"
    [path]="patient | map: getStoragePath"
    (uploadStarted)="addMedia($event, patient)"
  >
    <pr-media-selector-grid
      [selectionList]="selectionList"
      [patientMediaSettings]="patientMediaSettings$ | async"
      [media]="mediaSearch.results$ | async"
      (openMedia)="editMedia($event)"
    />
  </pt-file-upload-to-storage>
</div>
