import { Injectable, inject } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import {
  type IPrincipleState,
  TimezoneService,
} from '@principle-theorem/ng-principle-shared';
import {
  filterUndefined,
  type ITimePeriod,
  unserialise$,
  getTimePeriodStartEnd,
  isArray,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CalendarActions } from '../actions';
import {
  type ICalendarPartialState,
  type ICalendarState,
} from '../calendar.reducer';
import * as CalendarSelectors from '../calendar.selectors';
import { type ICalendarParams } from '../models/calendar-params';
import {
  CalendarUnit,
  CalendarView,
} from '@principle-theorem/principle-core/interfaces';

@Injectable()
export class CalendarFacade {
  private _store = inject(Store<ICalendarPartialState & IPrincipleState>);
  state$: Observable<ICalendarState>;
  loaded$: Observable<boolean>;
  range$: Observable<ITimePeriod>;
  unit$: Observable<CalendarUnit>;
  view$: Observable<CalendarView>;
  unitParam$: Observable<string | undefined>;
  dateParam$: Observable<string | undefined>;
  calendarParams$: Observable<Partial<ICalendarParams>>;

  constructor(private _timezoneService: TimezoneService) {
    this.state$ = this._store.pipe(select(CalendarSelectors.getCalendarState));
    this.loaded$ = this._store.pipe(
      select(CalendarSelectors.getCalendarLoaded)
    );
    this.unit$ = this._store.pipe(select(CalendarSelectors.getCalendarUnit));
    this.view$ = this._store.pipe(select(CalendarSelectors.getCalendarView));
    this.unitParam$ = this._store.pipe(
      select(CalendarSelectors.getCalendarUnitParam),
      map((filter) => (isArray(filter) ? filter[0] : filter))
    );
    this.dateParam$ = this._store.pipe(
      select(CalendarSelectors.getCalendarDateParam),
      map((filter) => (isArray(filter) ? filter[0] : filter))
    );
    this.calendarParams$ = this._store.pipe(
      select(CalendarSelectors.getCalendarQueryParams)
    );

    this.range$ = this._store.pipe(
      select(CalendarSelectors.getCalendarRange),
      unserialise$(),
      filterUndefined(),
      switchMap((range) =>
        this._timezoneService.currentPractice.timezone$.pipe(
          map((timezone) => getTimePeriodStartEnd(range, timezone))
        )
      )
    );
  }

  nextRange(): void {
    this._dispatch(CalendarActions.nextCalendarRange());
  }

  previousRange(): void {
    this._dispatch(CalendarActions.previousCalendarRange());
  }

  setUnit(unit: CalendarUnit): void {
    this._dispatch(CalendarActions.setCalendarUnit({ unit }));
  }

  reset(): void {
    this._dispatch(CalendarActions.resetCalendar());
  }

  private _dispatch(action: Action): void {
    this._store.dispatch(action);
  }
}
