<div class="profile-icon-button">
  <ng-template #loadingUser>
    <mat-spinner
      mode="indeterminate"
      color="accent"
      [diameter]="iconSize"
     />
  </ng-template>

  <ng-container *ngIf="user$ | async as user; else loadingUser">
    <div>
      <pt-user-icon
        [name]="user.name"
        [src]="user | profileImage$ | async"
        [diameter]="iconSize"
        matRipple
        [matMenuTriggerFor]="accountMenu"
       />
      <mat-icon
        *ngIf="isManagementUser$ | async"
        class="management-user-icon"
        matTooltip="Management User"
        [matMenuTriggerFor]="accountMenu"
        >admin_panel_settings</mat-icon
      >
    </div>
    <mat-menu #accountMenu="matMenu">
      <ng-template matMenuContent>
        <pr-auth-user-card [user]="user" />
        <button
          mat-menu-item
          [matMenuTriggerFor]="changeUserMenu"
          [disabled]="disableChangeUser$ | async"
        >
          Change User
        </button>
        <mat-menu
          #changeUserMenu="matMenu"
          overlapTrigger
          class="no-padding wide"
        >
          <pr-switch-user-list />
        </mat-menu>

        <button
          class="change-workspace"
          mat-menu-item
          *ngIf="hasWorkspaces$ | async"
          (click)="changeWorkspace()"
        >
          Change Workspace
          <span class="workspace-slug"
            >({{ workspace.workspace$ | async }})</span
          >
        </button>

        <mat-nav-list dense>
          <ng-container *ngIf="organisation.staffer$ | async as staffer">
            <mat-divider />
            <div mat-subheader>My Profile</div>
            <a mat-list-item [prBrandRouterLink]="['staff', staffer.ref.id]">
              Schedule
            </a>
            <a
              mat-list-item
              [prBrandRouterLink]="['staff', staffer.ref.id, 'treatment-plans']"
            >
              Treatment Plans
            </a>
          </ng-container>
          <a mat-list-item routerLink="/settings">Account Settings</a>

          <mat-divider />
          <a mat-list-item (click)="auth.logout()">Sign Out</a>
        </mat-nav-list>
      </ng-template>
    </mat-menu>
  </ng-container>
</div>
