<div fxFill fxLayout="column">
  <form
    fxFill
    fxLayout="column"
    fxLayoutAlign="space-around stretch"
    [formGroup]="configurationFormGroup"
    (ngSubmit)="submit()"
  >
    <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>Title</mat-label>
        <pr-editor-input formControlName="title" [required]="true" />
      </mat-form-field>
    </div>

    <div
      fxLayout="column"
      fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="space-around center"
      fxLayoutGap="16px"
    >
      <div fxFlex>
        <pr-task-assignee fxFlex [assigneeCtrl]="assigneeFormControl" />
      </div>
      <mat-form-field fxFlex>
        <mat-label>Priority</mat-label>
        <mat-select formControlName="priority" [required]="true">
          <mat-option
            *ngFor="let priority of taskPriorities; trackBy: trackByPriority"
            [value]="priority"
          >
            {{ priority | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      fxLayout="column"
      fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="space-around center"
      fxLayoutGap="16px"
    >
      <mat-form-field fxFlex>
        <mat-label>Frequency</mat-label>
        <mat-select
          [required]="true"
          formControlName="recurringFrequency"
          (selectionChange)="setRecurrence($event)"
          [compareWith]="isSelectedFrequency"
        >
          <mat-option
            *ngFor="let frequency of frequencies; trackBy: trackByFrequency"
            [value]="frequency"
          >
            {{ frequency }}
          </mat-option>
        </mat-select>

        <button
          type="button"
          mat-icon-button
          matIconSuffix
          *ngIf="hasCustomFrequency$ | async"
          matTooltip="Edit custom settings"
          (click)="editCustomRecurrence($event)"
        >
          <mat-icon>settings</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>Start Date</mat-label>
        <input
          matInput
          [formControl]="startDateCtrl"
          [matDatepicker]="startDate"
          (focus)="startDate.open()"
          (click)="startDate.open()"
          (dateChange)="setStartDate($event)"
        />

        <mat-datepicker-toggle matIconSuffix [for]="startDate" />
        <mat-datepicker #startDate />

        <button
          matIconSuffix
          mat-icon-button
          aria-label="Clear"
          type="button"
          (click)="clearStartDate($event)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    @if (showDaysOfWeek$ | async) {
      <mat-form-field>
        <mat-label>On these days</mat-label>
        <mat-select
          multiple
          [formControl]="daysOfWeekCtrl"
          (selectionChange)="setDaysOfWeek($event.value)"
        >
          @for (day of daysOfWeek; track day) {
            <mat-option [value]="day">{{ day | titlecase }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    }

    <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>Comment</mat-label>
        <pr-content-editor
          [menuEnabled]="false"
          formControlName="description"
          placeholder="Add a comment"
        />
      </mat-form-field>
    </div>
    <h3 class="mat-caption">Add due date</h3>
    <pr-recurring-task-due-date [form]="configurationFormGroup" />
    <button type="submit" hidden></button>
  </form>
</div>
