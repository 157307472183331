import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'pr-list-skeleton',
    templateUrl: './list-skeleton.component.html',
    styleUrls: ['./list-skeleton.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ListSkeletonComponent {}
