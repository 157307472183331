<pt-editor
  fxFlex
  class="max-w-full"
  [formControl]="formControl"
  [extensions]="extensions"
  [menuItems]="menuItems"
  [slashMenuItems]="slashMenuItems"
  [menuEnabled]="menuEnabled"
  [uploader]="uploader"
  [disabled]="disabled"
/>
