import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SubscriptSizing } from '@angular/material/form-field';
import { CanBeChartedProperty } from '@principle-theorem/reporting';
import { firstValueFrom } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { ReportBuilderManageChartBloc } from '../report-builder-results-table/report-builder-add-chart-dialog/report-builder-manage-chart-bloc';

@Component({
  selector: 'pr-report-builder-data-point-selector',
  templateUrl: './report-builder-data-point-selector.component.html',
  styleUrls: ['./report-builder-data-point-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportBuilderDataPointSelectorComponent {
  bloc$ = new ReplaySubject<ReportBuilderManageChartBloc>(1);
  @Input() subscriptSizing: SubscriptSizing = 'fixed';
  @Input() label: string = 'Data Point';

  @Output()
  dataPointSelected = new EventEmitter<CanBeChartedProperty>();

  @Input()
  set bloc(bloc: ReportBuilderManageChartBloc) {
    if (bloc) {
      this.bloc$.next(bloc);
    }
  }

  async handleSelect(event: MatAutocompleteSelectedEvent): Promise<void> {
    const bloc = await firstValueFrom(this.bloc$);
    if (bloc) {
      bloc.dataPointSelected(
        event.option.value as CanBeChartedProperty | undefined
      );
    }

    if (event.option.value) {
      this.dataPointSelected.emit(
        event.option.value as CanBeChartedProperty | undefined
      );
    }
  }
}
