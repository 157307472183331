<pt-breadcrumbs current="Contacts" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="layout-padding">
  <div fxLayout="row" fxLayoutGap="16px" class="layout-margin">
    <pt-search-field fxFlex>
      <input
        matInput
        placeholder="Search"
        #search
        (keyup)="searchContacts(search.value)"
      />
    </pt-search-field>

    <button mat-raised-button color="primary" (click)="addContact()">
      Create Contact
    </button>
  </div>

  <pr-empty-state *ngIf="emptyState$ | async" image="list" title="contacts" />

  <div class="layout-margin mat-elevation-z1">
    <mat-progress-bar
      *ngIf="dataTable.dataSource.loading$ | async"
      mode="indeterminate"
    />

    <ng-container *ngIf="dataTable.dataSource.filteredData.length">
      <mat-table
        [dataSource]="dataTable.dataSource"
        [trackBy]="trackByContact"
        matSort
        matSortActive="name"
        matSortDirection="asc"
      >
        <mat-header-row *matHeaderRowDef="dataTable.displayColumns" />
        <mat-row *matRowDef="let contact; columns: dataTable.displayColumns" />

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="truncate">
            Name
          </mat-header-cell>
          <mat-cell *matCellDef="let contact" class="truncate">
            <a
              *ngIf="contact | map: isContact; else isLab"
              [routerLink]="['./', contact.ref.id]"
              >{{ contact.name }}</a
            >
            <ng-template #isLab>
              <a [routerLink]="['../labs', contact.ref.id]">{{
                contact.name
              }}</a>
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="address">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="truncate">
            Address
          </mat-header-cell>
          <mat-cell *matCellDef="let contact" class="truncate">
            {{ contact.address }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="phone">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="truncate">
            Phone
          </mat-header-cell>
          <mat-cell *matCellDef="let contact" class="truncate">
            <a [routerLink]="[]" (click)="openPhone(contact)">
              {{ contact.phone }}
            </a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="truncate">
            Email
          </mat-header-cell>
          <mat-cell *matCellDef="let contact" class="truncate">
            <a [routerLink]="[]" (click)="openEmail(contact)">
              {{ contact.email }}
            </a>
          </mat-cell>
        </ng-container>
      </mat-table>
    </ng-container>
    <mat-paginator
      #paginator
      [length]="dataTable.dataSource.data.length"
      [pageSize]="dataTable.pageSize"
      [pageSizeOptions]="dataTable.pageSizeOptions"
      showFirstLastButtons
    />
    <pr-empty-state
      *ngIf="emptySearch$ | async"
      image="search"
      title="search results"
      [searchInputValue]="search.value"
    />
  </div>
</div>
