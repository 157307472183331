import { SourceEntityMigrationType } from '@principle-theorem/principle-core/interfaces';
import { TypeGuard } from '@principle-theorem/shared';
import { flow, isBoolean, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const APPOINTMENT_ROOM_RESOURCE_TYPE = 'appointmentRoom';

export const APPOINTMENT_ROOM_SOURCE_ENTITY = SourceEntity.init({
  metadata: {
    label: 'Appointment Room List',
    description: '',
    idPrefix: APPOINTMENT_ROOM_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface IExactAppointmentRoom {
  id: string;
  name: string;
  code: string;
  location_id: string;
  is_active: boolean;
}

function isExactAppointmentRoom(item: unknown): item is IExactAppointmentRoom {
  return TypeGuard.interface<IExactAppointmentRoom>({
    id: isString,
    name: isString,
    code: isString,
    location_id: isString,
    is_active: isBoolean,
  })(item);
}

const APPOINTMENT_ROOM_SOURCE_QUERY = `
  SELECT
    id::text,
    name,
    code,
    isactive AS is_active,
    locationid::text AS location_id
  FROM
    convappointmentroom
`;

export class AppointmentRoomSourceEntity extends BaseSourceEntity<IExactAppointmentRoom> {
  sourceEntity = APPOINTMENT_ROOM_SOURCE_ENTITY;
  entityResourceType = APPOINTMENT_ROOM_RESOURCE_TYPE;
  sourceQuery = APPOINTMENT_ROOM_SOURCE_QUERY;
  verifySourceFn = isExactAppointmentRoom;
  override transformDataFn = flow([]);

  translate(): object {
    return {};
  }

  getSourceRecordId(data: IExactAppointmentRoom): string {
    return data.id;
  }

  getSourceLabel(data: IExactAppointmentRoom): string {
    return `${data.id} ${data.name}`;
  }
}
