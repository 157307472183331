import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { AccountCardComponent } from './account-summary/account-card/account-card.component';
import { AccountCreditSummaryComponent } from './account-summary/account-credit-summary/account-credit-summary.component';
import { AccountInvoiceSummaryComponent } from './account-summary/account-invoice-summary/account-invoice-summary.component';
import { AccountSummaryDatapointComponent } from './account-summary/account-summary-datapoint/account-summary-datapoint.component';
import { BasicAccountSummaryComponent } from './account-summary/basic-account-summary/basic-account-summary.component';
import { AmendInvoiceConfirmDialogComponent } from './amend-invoice-confirm-dialog/amend-invoice-confirm-dialog.component';
import { BalanceDisplayComponent } from './balance-display/balance-display.component';
import { InvoiceAmendmentHistoryDialogComponent } from './invoice-amendment-history-dialog/invoice-amendment-history-dialog.component';
import { InvoiceAmendmentHistoryComponent } from './invoice-amendment-history/invoice-amendment-history.component';
import { InvoiceCreditSummaryComponent } from './invoice-credit-summary/invoice-credit-summary.component';
import { InvoiceAccountDetailsComponent } from './invoice-display/invoice-account-details/invoice-account-details.component';
import { InvoiceActionMenuComponent } from './invoice-display/invoice-action-menu/invoice-action-menu.component';
import { InvoiceActionService } from './invoice-display/invoice-action.service';
import { InvoiceDisplayComponent } from './invoice-display/invoice-display.component';
import { InvoiceHeaderComponent } from './invoice-display/invoice-header/invoice-header.component';
import { InvoiceInteractionsDialogComponent } from './invoice-display/invoice-interactions-dialog/invoice-interactions-dialog.component';
import { InvoiceLineItemDisplayComponent } from './invoice-display/invoice-line-item-display/invoice-line-item-display.component';
import { InvoiceLineItemHeaderComponent } from './invoice-display/invoice-line-item-header/invoice-line-item-header.component';
import { LineItemGroupComponent } from './invoice-display/line-item-group/line-item-group.component';
import { TreatmentProviderComponent } from './invoice-display/treatment-provider/treatment-provider.component';
import { AccountCreditReservedForSelectorDialogComponent } from './invoice-edit/account-credit-reserved-for-selector-dialog/account-credit-reserved-for-selector-dialog.component';
import { AccountCreditReservedForSelectorDialogService } from './invoice-edit/account-credit-reserved-for-selector-dialog/account-credit-reserved-for-selector-dialog.service';
import { InvoiceEditComponent } from './invoice-edit/invoice-edit.component';
import { InvoiceLineItemEditComponent } from './invoice-edit/invoice-line-item-edit/invoice-line-item-edit.component';
import { LineItemSelectorComponent } from './invoice-edit/line-item-selector/line-item-selector.component';
import { PracticeDetailsSelectorComponent } from './invoice-edit/practice-details-selector/practice-details-selector.component';
import { RelatedAppointmentsDialogComponent } from './related-appointments-dialog/related-appointments-dialog.component';
import { TaxStatusComponent } from './tax-status/tax-status.component';

@NgModule({
  imports: [
    CommonModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    NgInteractionsModule,
  ],
  declarations: [
    InvoiceEditComponent,
    LineItemSelectorComponent,
    InvoiceAccountDetailsComponent,
    InvoiceDisplayComponent,
    InvoiceHeaderComponent,
    BalanceDisplayComponent,
    PracticeDetailsSelectorComponent,
    InvoiceLineItemEditComponent,
    InvoiceLineItemHeaderComponent,
    LineItemGroupComponent,
    InvoiceLineItemDisplayComponent,
    TaxStatusComponent,
    InvoiceActionMenuComponent,
    TreatmentProviderComponent,
    InvoiceAmendmentHistoryComponent,
    InvoiceAmendmentHistoryDialogComponent,
    RelatedAppointmentsDialogComponent,
    AccountCreditReservedForSelectorDialogComponent,
    AmendInvoiceConfirmDialogComponent,
    InvoiceCreditSummaryComponent,
    InvoiceInteractionsDialogComponent,
    AccountCreditSummaryComponent,
    AccountInvoiceSummaryComponent,
    AccountSummaryDatapointComponent,
    BasicAccountSummaryComponent,
    AccountCardComponent,
  ],
  exports: [
    InvoiceEditComponent,
    InvoiceDisplayComponent,
    BalanceDisplayComponent,
    LineItemGroupComponent,
    InvoiceLineItemDisplayComponent,
    InvoiceLineItemHeaderComponent,
    TaxStatusComponent,
    InvoiceActionMenuComponent,
    TreatmentProviderComponent,
    InvoiceAmendmentHistoryComponent,
    RelatedAppointmentsDialogComponent,
    InvoiceCreditSummaryComponent,
    AccountCreditSummaryComponent,
    AccountInvoiceSummaryComponent,
    BasicAccountSummaryComponent,
    AccountCardComponent,
  ],
  providers: [
    InvoiceActionService,
    AccountCreditReservedForSelectorDialogService,
  ],
})
export class ComponentsModule {}
