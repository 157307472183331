import { IBridgeIntegration } from '@principle-theorem/principle-bridge-core';
import {
  BridgeDeviceFeatureStatus,
  IBridgeDeviceFeature,
} from '@principle-theorem/principle-core/interfaces';

export class BridgeDeviceFeature {
  static isIntegration(
    feature: IBridgeDeviceFeature,
    integration: IBridgeIntegration
  ): boolean {
    return this.isFeature(feature, integration.label);
  }

  static isFeature(
    feature: IBridgeDeviceFeature,
    featureLabel: string
  ): boolean {
    return feature.label.toLowerCase() === featureLabel.toLowerCase();
  }

  static isEnabled(feature: IBridgeDeviceFeature): boolean {
    return feature.status === BridgeDeviceFeatureStatus.Enabled;
  }
}
