import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { StateBasedNavigationService } from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { Task } from '@principle-theorem/principle-core';
import {
  type IPractice,
  type ITask,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  findProp,
  getDoc$,
  type WithRef,
} from '@principle-theorem/shared';
import { merge, noop, type Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import {
  type ITaskInteractionDialogData,
  TaskInteractionsDialogComponent,
} from './components/task-interactions-dialog/task-interactions-dialog.component';

export class TaskLoadDialogBloc {
  openTask$: Subject<WithRef<ITask>> = new Subject();

  constructor(
    private _route: ActivatedRoute,
    private _dialog: MatDialog,
    private _stateNav: StateBasedNavigationService,
    private _onDestroy$: Observable<void>,
    private _practice$: Observable<WithRef<IPractice>>
  ) {
    this._syncQueryParam();
    this._loadTaskFromQueryParam();
    this._handleEditDialogTrigger();
  }

  /**
   * Open form to edit task and view interactions
   * @param task Task
   */
  private _editTask(
    task: WithRef<ITask>
  ): MatDialogRef<TaskInteractionsDialogComponent> {
    const data: ITaskInteractionDialogData = { task };
    const config: MatDialogConfig = DialogPresets.fullscreen({
      data,
    });
    return this._dialog.open(TaskInteractionsDialogComponent, config);
  }

  private _handleEditDialogTrigger(): void {
    this.openTask$
      .pipe(
        switchMap((task: WithRef<ITask>) => this._editTask(task).afterClosed()),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => {
        void this._stateNav.practice(['tasks', 'list'], {
          // eslint-disable-next-line no-null/no-null
          queryParams: { task: null },
          queryParamsHandling: 'merge',
        });
      });
  }

  private _syncQueryParam(): void {
    this.openTask$.subscribe((task: WithRef<ITask>) => {
      void this._stateNav.practice(['tasks', 'list'], {
        queryParams: { task: task.ref.id },
        queryParamsHandling: 'merge',
      });
    });
  }

  private _loadTaskFromQueryParam(): void {
    this._route.queryParams
      .pipe(
        findProp<string>('task'),
        filterUndefined(),
        withLatestFrom(this._practice$),
        switchMap(([taskUid, practice]) =>
          getDoc$(Task.col(practice), taskUid)
        ),
        tap((task) => this.openTask$.next(task)),
        takeUntil(merge(this._onDestroy$, this.openTask$))
      )
      .subscribe(noop);
  }
}
