import {
  IDestination,
  IDestinationEntity,
  IDestinationEntityHandler,
  IDestinationHandler,
  IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  asyncForEach,
  firstResult$,
  snapshot,
  upsertDocByProperty,
  where,
} from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PracticeMigration } from '../practice-migrations';

export abstract class BaseDestinationHandler implements IDestinationHandler {
  abstract migrationType: string;
  abstract migrationVersion: string;
  abstract destination: IDestination;
  abstract entityHandlers: IDestinationEntityHandler[];

  get entities(): IDestinationEntity[] {
    return this.entityHandlers.map((handler) => handler.destinationEntity);
  }

  canHandle(mappings: IDestination): boolean {
    return isEqual(mappings.metadata, this.destination.metadata);
  }

  async addEntities(migration: WithRef<IPracticeMigration>): Promise<void> {
    await asyncForEach(this.entities, async (entity) => {
      const hasEntity = await snapshot(
        firstResult$(
          PracticeMigration.destinationEntityCol({
            ref: migration.ref,
          }),
          where('uid', '==', entity.metadata.key)
        ).pipe(
          map((result) => !!result),
          catchError(() => of(undefined)),
          map((doc) => (doc ? true : false))
        )
      );

      if (hasEntity) {
        return;
      }

      return upsertDocByProperty(
        PracticeMigration.destinationEntityCol({
          ref: migration.ref,
        }),
        entity,
        'uid',
        entity.metadata.key
      );
    });
  }
}
