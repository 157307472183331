import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  type EventType,
  EVENT_TYPES,
} from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-event-type-selector',
  templateUrl: './event-type-selector.component.html',
  styleUrls: ['./event-type-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventTypeSelectorComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByType = TrackByFunctions.variable<EventType>();
  filtersCtrl: TypedFormControl<EventType[]> = new TypedFormControl<
    EventType[]
  >(EVENT_TYPES);
  availableEventTypes$ = new BehaviorSubject<EventType[]>(EVENT_TYPES);

  @Input()
  set availableEventTypes(availableEventTypes: EventType[]) {
    if (availableEventTypes) {
      this.availableEventTypes$.next(availableEventTypes);
    }
  }

  @Output() selectedChange: EventEmitter<EventType[]> = new EventEmitter<
    EventType[]
  >();

  @Input()
  set selected(types: EventType[]) {
    this.filtersCtrl.setValue(types, { emitEvent: false });
  }

  constructor() {
    this.filtersCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((filters: EventType[]) => this.selectedChange.next(filters));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
