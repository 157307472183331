import { trim } from 'lodash';

interface IHealthFundProvider {
  name: string;
  cardPrefix: string;
  itemsAccepted: number;
}

export const HEALTH_FUND_PROVIDERS: IHealthFundProvider[] = [
  {
    name: 'ACA Health Benefits Fund',
    cardPrefix: '90360012',
    itemsAccepted: 21,
  },
  { name: 'ADF Family Health', cardPrefix: '90360109', itemsAccepted: 23 },
  {
    name: 'ahm Health Insurance',
    cardPrefix: '90360041',
    itemsAccepted: 24,
  },
  { name: 'AIA Health Insurance', cardPrefix: '90360138', itemsAccepted: 23 },
  { name: 'APIA', cardPrefix: '90360002', itemsAccepted: 24 },
  { name: 'Australian Unity', cardPrefix: '90360008', itemsAccepted: 24 },
  { name: 'Budget Direct', cardPrefix: '90360042', itemsAccepted: 24 },
  { name: 'BUPA', cardPrefix: '90360006', itemsAccepted: 16 },
  {
    name: 'CBHS Health Fund Limited',
    cardPrefix: '90360009',
    itemsAccepted: 24,
  },
  { name: 'CBHS Corporate', cardPrefix: '90360139', itemsAccepted: 24 },
  { name: 'CUA Health (Qld time )', cardPrefix: '90360045', itemsAccepted: 16 },
  { name: 'Defence Health', cardPrefix: '90360020', itemsAccepted: 23 },
  { name: 'Doctors Health Fund', cardPrefix: '90360005', itemsAccepted: 23 },
  {
    name: 'Emergency Services Health',
    cardPrefix: '90360140',
    itemsAccepted: 23,
  },
  { name: 'Frank Health Insurance', cardPrefix: '90360042', itemsAccepted: 24 },
  { name: 'GMHBA', cardPrefix: '90360042', itemsAccepted: 14 },
  { name: 'Grand United Health', cardPrefix: '90360043', itemsAccepted: 15 },
  { name: 'HBF (WA time )', cardPrefix: '90360025', itemsAccepted: 23 },
  { name: 'HCF', cardPrefix: '90360015', itemsAccepted: 16 },
  { name: 'Health Care Insurance', cardPrefix: '90360013', itemsAccepted: 23 },
  { name: 'Health.com.au', cardPrefix: '90360125', itemsAccepted: 23 },
  { name: 'Health Partners', cardPrefix: '90360024', itemsAccepted: 24 },
  { name: 'HIF of WA', cardPrefix: '90360026', itemsAccepted: 16 },
  {
    name: 'Hunter Health Insurance',
    cardPrefix: '90360058',
    itemsAccepted: 23,
  },
  {
    name: 'Insurance Commission Western Australia',
    cardPrefix: '90360155',
    itemsAccepted: 1,
  },
  {
    name: 'Latrobe Health Services',
    cardPrefix: '90360038',
    itemsAccepted: 16,
  },
  {
    name: 'Medibank Private Limited',
    cardPrefix: '90360004',
    itemsAccepted: 16,
  },
  { name: 'Mildura Health Fund', cardPrefix: '90360048', itemsAccepted: 9 },
  { name: 'Navy Health', cardPrefix: '90360010', itemsAccepted: 23 },
  { name: 'NIB Health Funds Ltd', cardPrefix: '90360002', itemsAccepted: 22 },
  {
    name: 'Nurses &Midwives Health',
    cardPrefix: '90360142',
    itemsAccepted: 23,
  },
  { name: 'OneMedifund', cardPrefix: '90360088', itemsAccepted: 23 },
  { name: 'Peoplecare', cardPrefix: '90360016', itemsAccepted: 23 },
  { name: 'Police Health', cardPrefix: '90360021', itemsAccepted: 23 },
  {
    name: 'Qantas Assure Health Insurance',
    cardPrefix: '90360002',
    itemsAccepted: 22,
  },
  { name: 'Phoenix Health Fund', cardPrefix: '90360019', itemsAccepted: 23 },
  {
    name: 'Queensland Country Health',
    cardPrefix: '90360022',
    itemsAccepted: 23,
  },
  {
    name: 'Reserve Bank Health Society',
    cardPrefix: '90360089',
    itemsAccepted: 23,
  },
  { name: 'RT Health Fund Ltd', cardPrefix: '90360084', itemsAccepted: 23 },
  { name: 'St. Lukes Health', cardPrefix: '90360044', itemsAccepted: 23 },
  { name: 'Suncorp', cardPrefix: '90360002', itemsAccepted: 22 },
  { name: 'Teachers Health Fund', cardPrefix: '90360011', itemsAccepted: 16 },
  { name: 'Teachers Union Health', cardPrefix: '90360018', itemsAccepted: 23 },
  { name: 'Territory Health Fund', cardPrefix: '90360022', itemsAccepted: 23 },
  { name: 'Transport Health', cardPrefix: '90360056', itemsAccepted: 23 },
  { name: 'Uni Health', cardPrefix: '9036001', itemsAccepted: 24 },
  { name: 'Westfund', cardPrefix: '90360096', itemsAccepted: 23 },

  // Hicaps Testing Fund
  { name: 'MBP', cardPrefix: '90369998', itemsAccepted: 24 },
];

export function findHealthFundByCardNumber(
  cardNumber: string
): IHealthFundProvider | undefined {
  const card = trim(cardNumber);
  return HEALTH_FUND_PROVIDERS.find((healthFund) =>
    card.startsWith(healthFund.cardPrefix)
  );
}
