import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StafferSettingsStoreService } from '@principle-theorem/ng-principle-shared';
import {
  ConfirmDialogComponent,
  DialogPresets,
  FileManagerService,
  IMediaManagerUpdateEvent,
  IUploadStartedEvent,
  SelectionListStore,
  TypedFormControl,
  confirmationDialogData,
  type IConfirmationDialogInput,
} from '@principle-theorem/ng-shared';
import { Media, Patient } from '@principle-theorem/principle-core';
import {
  AppIntegrationFeature,
  ITag,
  type IMedia,
  type IPatient,
  type IStafferSettings,
} from '@principle-theorem/principle-core/interfaces';
import {
  INamedDocument,
  addDoc,
  asyncForEach,
  deleteDoc,
  isSameRef,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { differenceWith, intersectionWith, uniqWith } from 'lodash';
import { ReplaySubject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MediaSearch } from '../../media-search';
import {
  CaptureMediaDialogComponent,
  ICaptureMediaDialogRequest,
  ICaptureMediaDialogResponse,
} from '../capture-media-dialog/capture-media-dialog.component';
import {
  EditMediaDialogComponent,
  type IEditMediaDialogData,
} from '../edit-media-dialog/edit-media-dialog.component';
import { MediaTagsDialogComponent } from '../media-tags-dialog/media-tags-dialog.component';

@Component({
  selector: 'pr-media-manager',
  templateUrl: './media-manager.component.html',
  styleUrls: ['./media-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SelectionListStore],
})
export class MediaManagerComponent {
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  media$ = new ReplaySubject<WithRef<IMedia>[]>(1);
  searchCtrl = new TypedFormControl<string>();
  mediaSearch: MediaSearch;
  appIntegrationFeature = AppIntegrationFeature.MediaCapture;
  patientMediaSettings$: Observable<IStafferSettings['patientMedia']>;

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  @Input()
  set media(media: WithRef<IMedia>[]) {
    if (media) {
      this.media$.next(media);
    }
  }

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _fileDownload: FileManagerService,
    public selectionList: SelectionListStore<WithRef<IMedia>>,
    private _settings: StafferSettingsStoreService
  ) {
    this.mediaSearch = new MediaSearch(
      this.media$,
      this.searchCtrl.valueChanges
    );
    this.selectionList.setCompareFn(isSameRef);
    this.selectionList.loadOptions(this.mediaSearch.results$);
    this.patientMediaSettings$ = this._settings.patientMedia$;
  }

  async captureMedia(): Promise<void> {
    const patient = await snapshot(this.patient$);
    const data: ICaptureMediaDialogRequest = {
      mediaCollectionRef: Patient.mediaCol(patient),
      storagePath: Patient.storagePath(patient),
      multiCapture: true,
    };
    await this._dialog
      .open<
        CaptureMediaDialogComponent,
        ICaptureMediaDialogRequest,
        ICaptureMediaDialogResponse
      >(CaptureMediaDialogComponent, DialogPresets.flex({ data }))
      .afterClosed()
      .toPromise();
  }

  async editMedia({
    media,
    orderedMedia,
  }: Omit<IEditMediaDialogData, 'editMode'>): Promise<void> {
    const editMode = await snapshot(
      this.patientMediaSettings$.pipe(
        map((settings) => settings.imageEditMode ?? true)
      )
    );
    const config = DialogPresets.fullscreen<IEditMediaDialogData>({
      data: { media, editMode, orderedMedia },
    });

    await this._dialog
      .open<
        EditMediaDialogComponent,
        IEditMediaDialogData,
        IMediaManagerUpdateEvent
      >(EditMediaDialogComponent, config)
      .afterClosed()
      .toPromise();
  }

  async downloadSelected(): Promise<void> {
    const selected = await snapshot(this.selectionList.selected$);
    await this._fileDownload.downloadFiles('files', selected);
  }

  async editSelectedTags(): Promise<void> {
    const selected = await snapshot(this.selectionList.selected$);
    const allTags = selected.map((media) => media.tags);
    const sharedTags = intersectionWith(...allTags, isSameRef);

    const updatedTags = await this._dialog
      .open<
        MediaTagsDialogComponent,
        INamedDocument<ITag>[],
        INamedDocument<ITag>[]
      >(MediaTagsDialogComponent, DialogPresets.small({ data: sharedTags }))
      .afterClosed()
      .toPromise();

    if (!updatedTags) {
      return;
    }

    const addedTags = differenceWith(updatedTags, sharedTags, isSameRef);
    const removedTags = differenceWith(sharedTags, updatedTags, isSameRef);

    this.selectionList.resetSelected();
    await asyncForEach(selected, async (media) => {
      const remainingTags = differenceWith(media.tags, removedTags, isSameRef);
      media.tags = uniqWith([...remainingTags, ...addedTags], isSameRef);
      await patchDoc(media.ref, {
        tags: media.tags,
      });
      this.selectionList.setSelected(media, true);
    });

    this._snackBar.open('Tags Updated');
  }

  async deleteSelected(): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Media',
      prompt: 'Are you sure you want to delete selected media?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }
    const selected = await snapshot(this.selectionList.selected$);
    await asyncForEach(selected, (media) => deleteDoc(media.ref));
    this.selectionList.resetSelected();
    this._snackBar.open('Media Deleted');
  }

  getStoragePath(patient: WithRef<IPatient>): string {
    return Patient.storagePath(patient);
  }

  async addMedia(
    event: IUploadStartedEvent,
    patient: WithRef<IPatient>
  ): Promise<void> {
    await event.task;
    const media = Media.init({
      name: event.file.name,
      path: event.storagePath,
    });
    await addDoc(Patient.mediaCol(patient), media);
  }
}

export function isDicomFile(filePath: string): boolean {
  return filePath.toLowerCase().endsWith('.dcm');
}
