import {
  IntegrationType,
  type IIntegration,
} from '@principle-theorem/integrations';
import {
  OrganisationCollection,
  OrganisationStatus,
  RootCollection,
  type IBrand,
  type IOrganisation,
  type IRole,
  type IUser,
} from '@principle-theorem/principle-core/interfaces';
import {
  all$,
  asColRef,
  shareReplayCold,
  slugify,
  subCollection,
  undeletedQuery,
  where,
  type AtLeast,
  type CollectionReference,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { type ITemporaryOrgDataToken } from '@principle-theorem/temporary-tokens';
import { compact, sortBy } from 'lodash';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganisationCache } from './organisation-cache';

export class Organisation {
  static init(
    overrides: AtLeast<IOrganisation, 'name' | 'region'>
  ): IOrganisation {
    return {
      status: OrganisationStatus.Pending,
      ...overrides,
      slug: slugify(overrides.name.toLowerCase()),
    };
  }

  static col(): CollectionReference<IOrganisation> {
    return asColRef<IOrganisation>(RootCollection.Organisations);
  }

  static all$(): Observable<WithRef<IOrganisation>[]> {
    return all$(this.col()).pipe(
      map((organisations) => sortBy(organisations, ['name'])),
      shareReplayCold()
    );
  }

  static userCol(
    organisation: IReffable<IOrganisation>
  ): CollectionReference<IUser> {
    return subCollection<IUser>(organisation.ref, OrganisationCollection.Users);
  }

  static users$(
    organisation: IReffable<IOrganisation>,
    filterEnabled: boolean = true
  ): Observable<WithRef<IUser>[]> {
    return OrganisationCache.users.byOrganisation.query$(
      {
        organisationRef: organisation.ref,
        filterEnabled,
      },
      Organisation.userCol(organisation),
      ...compact([filterEnabled ? where('isEnabled', '==', true) : undefined])
    );
  }

  static brandCol(
    organisation: IReffable<IOrganisation>
  ): CollectionReference<IBrand> {
    return subCollection<IBrand>(
      organisation.ref,
      OrganisationCollection.Brands
    );
  }

  static brands$(
    organisation: IReffable<IOrganisation>
  ): Observable<WithRef<IBrand>[]> {
    return all$(undeletedQuery(Organisation.brandCol(organisation)));
  }

  static temporaryTokenCol(
    organisation: IReffable<IOrganisation>
  ): CollectionReference<ITemporaryOrgDataToken> {
    return subCollection<ITemporaryOrgDataToken>(
      organisation.ref,
      OrganisationCollection.TemporaryTokens
    );
  }

  static integrationCol<IntegrationType extends object = object>(
    organisation: IReffable<IOrganisation>
  ): CollectionReference<IIntegration<IntegrationType>> {
    return subCollection<IIntegration<IntegrationType>>(
      organisation.ref,
      OrganisationCollection.Integrations
    );
  }

  static roleCol(
    organisation: IReffable<IOrganisation>
  ): CollectionReference<IRole> {
    return subCollection<IRole>(organisation.ref, OrganisationCollection.Roles);
  }

  static roles$(
    organisation: IReffable<IOrganisation>
  ): Observable<WithRef<IRole>[]> {
    return all$(undeletedQuery(Organisation.roleCol(organisation)));
  }
}

export function isTwilioSMSProvider(organisation: IOrganisation): boolean {
  return organisation.integrations?.smsProvider === IntegrationType.Twilio;
}

export function isPodiumSMSProvider(organisation: IOrganisation): boolean {
  return organisation.integrations?.smsProvider === IntegrationType.Podium;
}

export function isTNZSMSProvider(organisation: IOrganisation): boolean {
  return organisation.integrations?.smsProvider === IntegrationType.TNZ;
}
