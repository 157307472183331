import { isObject, TypeGuard } from '@principle-theorem/shared';
import { isString } from 'lodash';
import {
  ICustomFormContent,
  type IOldCustomFormConfiguration,
} from './custom-forms/custom-form-config';
import {
  IPatientForm,
  isPatientForm,
  IPatientFormSchema,
} from './custom-forms/patient-form';

export interface ISerialisedJsonSchemaForm {
  schema?: string;
  layout?: string;
}

export const isSerialisedJsonSchemaForm =
  TypeGuard.interface<ISerialisedJsonSchemaForm>({
    schema: TypeGuard.undefinedOr(isString),
    layout: TypeGuard.undefinedOr(isString),
  });

export interface ISerialisedCustomFormConfiguration
  extends Omit<IOldCustomFormConfiguration, 'schema' | 'layout'> {
  schema?: string;
  layout?: string;
}

export const isSerialisedCustomFormConfiguration =
  TypeGuard.interface<ISerialisedCustomFormConfiguration>({
    name: isString,
    dataResolverConfig: TypeGuard.undefinedOr(isString),
    schema: TypeGuard.undefinedOr(isString),
    layout: TypeGuard.undefinedOr(isString),
  });

export interface ISerialisedCustomFormContent
  extends Omit<ICustomFormContent, 'jsonSchemaForm' | 'layout'> {
  jsonSchemaForm: ISerialisedJsonSchemaForm;
  layout?: string;
}

export function isSerialisedCustomFormContent(
  data: unknown
): data is ISerialisedCustomFormContent {
  return (
    isObject(data) &&
    isSerialisedJsonSchemaForm(data.jsonSchemaForm) &&
    TypeGuard.undefinedOr(isString)(data.layout)
  );
}

export interface ISerialisedPatientForm<T extends object = object>
  extends Omit<IPatientForm<T>, 'form'> {
  form: Omit<IPatientFormSchema<T>, 'schema' | 'layout'> &
    ISerialisedJsonSchemaForm;
}

export function isSerialisedPatientForm<T extends object = object>(
  data: unknown
): data is ISerialisedPatientForm<T> {
  return (
    isObject(data) &&
    isPatientForm(data) &&
    TypeGuard.undefinedOr(isSerialisedJsonSchemaForm)(data.form)
  );
}
