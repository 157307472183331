import {
  UUID_INVALID_CHARACTERS,
  toTimestamp,
} from '@principle-theorem/shared';
import {
  type CollectionReference,
  type Query,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import {
  type ITemporaryOrgDataToken,
  TemporaryOrgDataTokenStorage,
} from './temporary-org-data-token';

export async function createCSRF<T extends object>(
  tokensCollection: CollectionReference<ITemporaryOrgDataToken<T>>,
  data: T,
  expiresAt: moment.Moment = moment().add(1, 'hour'),
  short = false
): Promise<string> {
  const tokenStorage: TemporaryOrgDataTokenStorage<T> =
    new TemporaryOrgDataTokenStorage();

  const orgRef = tokensCollection.parent;
  if (!orgRef) {
    throw new Error('Failed to resolve Organisation');
  }

  const token: Omit<ITemporaryOrgDataToken<T>, 'tokenUid'> = {
    orgUid: orgRef.id,
    expiresAt: toTimestamp(expiresAt),
    deleted: false,
    data,
  };

  return tokenStorage.generate(token, tokensCollection, short);
}

export async function removeCSRF<T extends object>(
  tokensColRef: CollectionReference<ITemporaryOrgDataToken<T>>,
  token: ITemporaryOrgDataToken<T>
): Promise<void> {
  const tokenStorage: TemporaryOrgDataTokenStorage<T> =
    new TemporaryOrgDataTokenStorage();
  await tokenStorage.remove(token.tokenUid, tokensColRef);
}

export async function findCSRF<T extends object>(
  colQuery: Query<ITemporaryOrgDataToken<T>>,
  tokenUid: string
): Promise<ITemporaryOrgDataToken<T> | undefined> {
  const tokenStorage: TemporaryOrgDataTokenStorage<T> =
    new TemporaryOrgDataTokenStorage();
  return tokenStorage.get(tokenUid, colQuery);
}

export function sanitiseCSRF(token: string): string {
  return token.replace(UUID_INVALID_CHARACTERS, '');
}
