import { Component } from '@angular/core';
import { AuthService } from '@principle-theorem/ng-auth';

@Component({
  selector: 'pr-unauthorised',
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.scss'],
})
export class UnauthorisedComponent {
  constructor(private _auth: AuthService) {}

  async logout(): Promise<void> {
    return this._auth.logout();
  }
}
