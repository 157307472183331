import { getEnumValues } from '@principle-theorem/shared';

export enum PricingScope {
  PerTooth = 'perTooth',
  PerQuadrant = 'perQuadrant',
}

export enum PricingUnitType {
  PerTooth = 'perTooth',
  PerQuadrant = 'perQuadrant',
}

export enum PricingRuleType {
  Flat = 'flat',
  Tiered = 'tiered',
  StairStep = 'stairStep',
}

export const PRICING_RULE_TYPES = getEnumValues(PricingRuleType);
export const PRICING_SCOPES = getEnumValues(PricingScope);
export const PRICING_UNIT_TYPES = [PricingUnitType.PerTooth];

export interface IPricingRule {
  type: PricingRuleType;
  ruleItems: IPricingRuleItem[];
  unitType?: PricingUnitType;
  scope?: PricingScope;
}

export interface IPricingRuleItem {
  uid: string;
  price?: number;
  startUnit: number;
  endUnit?: number;
  selected: boolean;
}

export interface IPricingRuleProvider {
  canProvide(rule: IPricingRule): boolean;
  determinePrice(
    rule: IPricingRule,
    unitCount: number,
    unitIndex: number
  ): number | undefined;
}
