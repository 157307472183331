import {
  toTextContent,
  NodeSchema,
  InlineNodes,
  TextFormatting,
} from '@principle-theorem/editor';

abstract class InteractionElement {
  constructor(public value: string) {}

  toNodeSchema(): NodeSchema<InlineNodes> {
    return toTextContent(this.value);
  }
}

export class InteractionProperty extends InteractionElement {
  override toNodeSchema(): NodeSchema<InlineNodes> {
    return toTextContent(this.value, {}, [{ type: TextFormatting.Code }]);
  }
}
