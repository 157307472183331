import {
  isSameToothRef,
  isRightQuadrant,
} from '@principle-theorem/principle-core';
import {
  type ITooth,
  type IToothRef,
  Quadrant,
} from '@principle-theorem/principle-core/interfaces';
import {
  type IPerioDataPoints,
  type IPerioRecord,
  PerioMeasurement,
  PERIO_DATA_POINTS,
  PERIO_MEASUREMENTS,
} from '@principle-theorem/principle-core/interfaces';
import { invertNumber } from '@principle-theorem/shared';
import { concat, get, unzipWith } from 'lodash';
import { type IPerioTableCell } from '../perio-table/perio-table';

export function recordsForTeeth(
  records: IPerioRecord[],
  teeth: ITooth[]
): IPerioRecord[] {
  return teeth.map((tooth) => {
    const mathingRecord = records.find((record) =>
      isSameToothRef(record.toothRef, tooth.toothRef)
    );

    return (
      mathingRecord ?? {
        toothRef: tooth.toothRef,
        data: {},
      }
    );
  });
}

export function transformPerioRecords(
  records: IPerioRecord[]
): IPerioTableCell[][] {
  const transformed = records
    .sort(sortByQuadrantDisplayOrder)
    .map((record) => transformPerioRecord(record));
  return unzipWith<IPerioTableCell[], IPerioTableCell[]>(transformed, concat);
}

export function transformPerioRecord(
  record: IPerioRecord
): IPerioTableCell[][] {
  return PERIO_MEASUREMENTS.map((measurement) => {
    const pairs = getRecordMeasurements(record, measurement);
    return isRightQuadrant(record.toothRef.quadrant) ? pairs : pairs.reverse();
  });
}

function getRecordMeasurements(
  record: IPerioRecord,
  measurement: PerioMeasurement
): IPerioTableCell[] {
  if (measurement === PerioMeasurement.Mobility) {
    return asDataPointsCellPairs(
      record.toothRef,
      measurement,
      record.data[measurement]
    );
  }
  return getDataPointsCellPairs(
    record.toothRef,
    measurement,
    record.data[measurement]
  );
}

export function asDataPointsCellPairs(
  toothRef: IToothRef,
  prefix: PerioMeasurement,
  value?: number
): IPerioTableCell[] {
  return PERIO_DATA_POINTS.map((dataPoint) => ({
    metadata: {
      toothRef,
      dataPath: `${prefix}`,
      dataPoint,
      measurement: prefix,
    },
    value,
  }));
}

export function getDataPointsCellPairs(
  toothRef: IToothRef,
  prefix: PerioMeasurement,
  points?: Partial<IPerioDataPoints>
): IPerioTableCell[] {
  return PERIO_DATA_POINTS.map((dataPoint) => ({
    metadata: {
      toothRef,
      dataPath: `${prefix}.${dataPoint}`,
      dataPoint,
      measurement: prefix,
    },
    value: get(points, dataPoint),
  })).sort(sortByDataPoint());
}

export function sortByDataPoint(): (
  aCell: IPerioTableCell,
  bCell: IPerioTableCell
) => number {
  return (aCell: IPerioTableCell, bCell: IPerioTableCell) => {
    const aIndex = PERIO_DATA_POINTS.indexOf(aCell.metadata.dataPoint);
    const bIndex = PERIO_DATA_POINTS.indexOf(bCell.metadata.dataPoint);
    return aIndex > bIndex ? 1 : -1;
  };
}

const quadrantDisplayOrder: Quadrant[] = [
  Quadrant.AdultUpperRight,
  Quadrant.AdultUpperLeft,
  Quadrant.AdultLowerRight,
  Quadrant.AdultLowerLeft,
  Quadrant.DeciduousUpperRight,
  Quadrant.DeciduousUpperLeft,
  Quadrant.DeciduousLowerRight,
  Quadrant.DeciduousLowerLeft,
];

function sortByQuadrantDisplayOrder(a: IPerioRecord, b: IPerioRecord): number {
  if (a.toothRef.quadrant === b.toothRef.quadrant) {
    const result = a.toothRef.quadrantIndex > b.toothRef.quadrantIndex ? 1 : -1;
    return isRightQuadrant(a.toothRef.quadrant) ? invertNumber(result) : result;
  }
  const aOrderIndex = quadrantDisplayOrder.indexOf(a.toothRef.quadrant);
  const bOrderIndex = quadrantDisplayOrder.indexOf(b.toothRef.quadrant);
  return aOrderIndex > bOrderIndex ? 1 : -1;
}
