interface IPosition {
  x: number;
  y: number;
}

export class ClientPosition implements IPosition {
  constructor(public x: number, public y: number) {}

  toSVG(parent: SVGSVGElement): SVGPosition {
    return toSVGPosition(parent, this);
  }
}

export class SVGPosition implements IPosition {
  constructor(
    public parent: SVGSVGElement,
    public x: number,
    public y: number
  ) {}
}

abstract class BoundingBox {
  public min: IPosition;
  public max: IPosition;

  get width(): number {
    return Math.abs(this.max.x - this.min.x);
  }

  get height(): number {
    return Math.abs(this.max.y - this.min.y);
  }
}
export class ClientBoundingBox extends BoundingBox {
  constructor(
    public override min: ClientPosition,
    public override max: ClientPosition
  ) {
    super();
  }

  get center(): ClientPosition {
    const centerX: number = this.min.x + this.width / 2;
    const centerY: number = this.min.y + this.height / 2;
    return new ClientPosition(centerX, centerY);
  }
}

export function getClientBoundingBox(element: Element): ClientBoundingBox {
  const rect: ClientRect | DOMRect = element.getBoundingClientRect();
  const minPos: ClientPosition = new ClientPosition(rect.left, rect.top);
  const maxPos: ClientPosition = new ClientPosition(rect.right, rect.bottom);
  return new ClientBoundingBox(minPos, maxPos);
}

export class SVGBoundingBox extends BoundingBox {
  constructor(
    public override min: SVGPosition,
    public override max: SVGPosition
  ) {
    super();
  }

  get parent(): SVGSVGElement {
    return this.min.parent;
  }

  get center(): SVGPosition {
    const centerX: number = this.min.x + this.width / 2;
    const centerY: number = this.min.y + this.height / 2;
    return new SVGPosition(this.parent, centerX, centerY);
  }
}

function toSVGPosition(
  parent: SVGSVGElement,
  position: ClientPosition
): SVGPosition {
  const ctm = parent.getScreenCTM();
  if (!ctm) {
    throw new Error(`Couldn't find parent elements coordinates`);
  }
  const point = parent.createSVGPoint();
  point.x = position.x;
  point.y = position.y;
  const transformed = point.matrixTransform(ctm.inverse());
  return new SVGPosition(parent, transformed.x, transformed.y);
}

export function toSVGBoundingBox(
  parent: SVGSVGElement,
  box: ClientBoundingBox
): SVGBoundingBox {
  return new SVGBoundingBox(
    toSVGPosition(parent, box.min),
    toSVGPosition(parent, box.max)
  );
}

type SVGElementTag =
  | 'g'
  | 'path'
  | 'rect'
  | 'circle'
  | 'foreignElement'
  | 'text';
export function createSVGElement(elem: SVGElementTag): SVGElement {
  const type = `svg:${elem}`;
  const svg: SVGElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    type
  );
  svg.setAttributeNS(
    'http://www.w3.org/2000/xmlns/',
    'xmlns:xlink',
    'http://www.w3.org/1999/xlink'
  );
  return svg;
}
