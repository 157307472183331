import {
  Component,
  Input,
  type OnChanges,
  type SimpleChanges,
} from '@angular/core';
import {
  type IChartContents,
  type IChartDataPoint,
  type IDataPoint,
} from '../../../models/report/charts/measure-data-interfaces';
import { type IChartCard } from '../../../models/report/charts/chart-card';
import { TrackByFunctions } from '@principle-theorem/ng-shared';

export interface ILiveMeasureCard {
  primary: IChartCard;
  breakDown: IChartCard;
}

@Component({
    selector: 'pr-live-measures-card',
    templateUrl: './live-measures-card.component.html',
    styleUrls: ['./live-measures-card.component.sass'],
    standalone: false
})
export class LiveMeasuresCardComponent implements OnChanges {
  trackByHeader = TrackByFunctions.variable<string>();
  trackByDataPoint = TrackByFunctions.combine<IChartDataPoint>('label');
  trackByValue = TrackByFunctions.label<IDataPoint>();
  @Input() charts: ILiveMeasureCard;
  primaryChart: IChartCard;
  breakDownChart: IChartCard;
  breakDownData: IChartContents;

  ngOnChanges(changes: SimpleChanges): void {
    const updatedCharts = changes.charts.currentValue as ILiveMeasureCard;
    this.primaryChart = updatedCharts.primary;
    this.breakDownChart = updatedCharts.breakDown;
    this.breakDownData =
      this.breakDownChart.chartBuilder.chartDataHandler.chartContents;
  }
}
