<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="16px" class="wrapper">
  <h1 class="mat-headline-4">General Settings</h1>
  <form
    fxLayout="column"
    fxLayoutGap="16px"
    class="layout-margin"
    novalidate
    [formGroup]="form"
    (ngSubmit)="save()"
  >
    <div fxLayout="row" fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" [required]="true" />
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>Email</mat-label>
        <input
          matInput
          type="email"
          formControlName="email"
          [required]="true"
        />
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>Phone</mat-label>
        <input matInput formControlName="phone" [required]="true" />
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="16px" formGroupName="settings">
      <mat-form-field fxFlex>
        <mat-label>Timezone</mat-label>
        <mat-select formControlName="timezone" [required]="true">
          <mat-option
            *ngFor="let timezone of timezones; trackBy: trackByTimezone"
            [value]="timezone"
          >
            {{ timezone }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>Address</mat-label>
        <input matInput formControlName="address" [required]="true" />
      </mat-form-field>
    </div>

    <pr-map-preview [coordinates]="coordinates$ | async" />

    <pt-buttons-container>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        [disabled]="!form.valid"
      >
        Save Changes
      </button>
    </pt-buttons-container>
  </form>
</div>
