import { toTextContent } from '@principle-theorem/editor';
import {
  ContactNumberLabel,
  IAppointmentRequest,
  IBrand,
  IPatient,
  IPatientData,
  IPatientRelationship,
  IsPrimaryContact,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  Firestore,
  WithRef,
  addDoc,
  toNamedDocument,
} from '@principle-theorem/shared';
import { omit } from 'lodash';
import { Brand } from '../brand';
import { CalendarEvent } from '../event/calendar-event';
import { Patient } from '../patient/patient';
import { PatientRelationship } from '../patient/patient-relationship';

export class AppointmentRequest {
  static init(
    overrides: AtLeast<
      IAppointmentRequest,
      'practitioner' | 'practice' | 'patient' | 'template' | 'event'
    >
  ): IAppointmentRequest {
    const base = CalendarEvent.init(overrides);
    return {
      ...base,
      title: [toTextContent('Appointment Request')],
      ...overrides,
    };
  }

  static async getPatientForAppointmentRequest(
    brand: WithRef<IBrand>,
    patientData: IPatientData,
    selectedPatient?: WithRef<IPatient>,
    selectedPrimaryPatient?: WithRef<IPatient>
  ): Promise<WithRef<IPatient>> {
    const primaryPatient =
      selectedPrimaryPatient ??
      (await this._createPrimaryPatient(patientData, brand));

    if (!patientData.patient || !patientData.patient.relationshipType) {
      return primaryPatient;
    }

    const primaryContact = {
      type: patientData.patient.relationshipType,
      patient: toNamedDocument(
        primaryPatient as WithRef<IPatient & IsPrimaryContact>
      ),
    };

    const patient = selectedPatient
      ? await this._addPrimaryContactToPatient(selectedPatient, primaryContact)
      : await this._createPatient(patientData, brand, primaryContact);

    await PatientRelationship.addPrimaryRelationship(patient);
    return patient;
  }

  private static async _createPrimaryPatient(
    patientData: IPatientData,
    brand: WithRef<IBrand>
  ): Promise<WithRef<IPatient>> {
    const addressMetadata = patientData.primaryContact.address;
    const metadata = { address: addressMetadata };
    const address = metadata.address?.address;

    const patientInfo = Patient.init({
      ...omit(patientData.primaryContact, 'mobileNumber'),
      address,
      metadata,
      contactNumbers: [
        {
          label: ContactNumberLabel.Mobile,
          number: patientData.primaryContact.mobileNumber,
        },
      ],
    });
    const patientDocRef = await addDoc(Brand.patientCol(brand), patientInfo);
    return Firestore.getDoc(patientDocRef);
  }

  private static async _createPatient(
    patientData: IPatientData,
    brand: WithRef<IBrand>,
    primaryContact: IPatientRelationship<IPatient & IsPrimaryContact>
  ): Promise<WithRef<IPatient>> {
    const patient = Patient.init({
      ...omit(patientData.patient, 'mobileNumber'),
      primaryContact,
    });
    const patientDoc = await addDoc(Brand.patientCol(brand), patient);
    return Firestore.getDoc(patientDoc);
  }

  private static async _addPrimaryContactToPatient(
    selectedPatient: WithRef<IPatient>,
    primaryContact: IPatientRelationship<IPatient & IsPrimaryContact>
  ): Promise<WithRef<IPatient>> {
    await Firestore.patchDoc(selectedPatient.ref, {
      primaryContact,
    });
    return { ...selectedPatient, primaryContact };
  }
}
