import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { patchDoc, type WithRef } from '@principle-theorem/shared';
import {
  type ILab,
  type ILabJobType,
} from '@principle-theorem/principle-core/interfaces';

export interface IAddLabJobTypeDialogData {
  lab: WithRef<ILab>;
}

@Component({
  selector: 'pr-lab-job-type-form-dialog',
  templateUrl: './lab-job-type-form-dialog.component.html',
  styleUrls: ['./lab-job-type-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabJobTypeFormDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<LabJobTypeFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAddLabJobTypeDialogData
  ) {}

  async submitted(labJobType: ILabJobType): Promise<void> {
    await patchDoc(this.data.lab.ref, {
      labJobTypes: [...this.data.lab.labJobTypes, labJobType],
    });
    this.dialogRef.close();
  }
}
