import { createEntityAdapter, type EntityAdapter } from '@ngrx/entity';
import {
  Action,
  ActionReducer,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { type SerialisedData } from '@principle-theorem/shared';
import * as PatientsActions from '../actions/patients.actions';
import {
  getPatientModuleState,
  type IPatientModuleState,
  type IPatientsState,
  type PatientEntity,
} from './states';
export { IPatientsState } from './states';

export const PATIENTS_FEATURE_KEY = 'patients';

export const patientsAdapter: EntityAdapter<SerialisedData<PatientEntity>> =
  createEntityAdapter<SerialisedData<PatientEntity>>({
    selectId: (patient) => patient.uid,
    sortComparer: false,
  });

export const initialState: IPatientsState = patientsAdapter.getInitialState({
  loaded: false,
});

export const reducer: ActionReducer<IPatientsState, Action> = createReducer(
  initialState,

  on(PatientsActions.addPatientFromResolver, (state, action) =>
    patientsAdapter.upsertOne(action.patient, {
      ...state,
    })
  ),

  on(PatientsActions.setSelectedPatient, (state, action) => ({
    ...state,
    selectedId: action.id,
  }))
);

export const { selectAll, selectEntities } = patientsAdapter.getSelectors();

export const getPatientsState = createSelector(
  getPatientModuleState,
  (state: IPatientModuleState) => state.patients
);

export const getPatientsLoaded = createSelector(
  getPatientsState,
  (state: IPatientsState) => state.loaded
);

export const getPatientsError = createSelector(
  getPatientsState,
  (state: IPatientsState) => state.error
);

export const getAllPatients = createSelector(
  getPatientsState,
  (state: IPatientsState) => selectAll(state)
);

export const getPatientsEntities = createSelector(
  getPatientsState,
  (state: IPatientsState) => selectEntities(state)
);

export const getSelectedPatientId = createSelector(
  getPatientsState,
  (state: IPatientsState) => state.selectedId
);

export const getSelectedPatient = createSelector(
  getPatientsEntities,
  getSelectedPatientId,
  (entities, selectedId?) => {
    return selectedId ? entities[selectedId] : undefined;
  }
);
