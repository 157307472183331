import { JsonSchemaFormService } from '@ajsf/core';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TypedFormControl,
  formControlChanges$,
  formControlDisabled$,
} from '@principle-theorem/ng-shared';
import { ISO_DATE_FORMAT, isDate, toMoment } from '@principle-theorem/shared';
import { isString } from 'lodash';
import moment, { isMoment } from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ILayoutNode, IOptions } from '../custom-form-widget-interfaces';

@Component({
  selector: 'pr-custom-form-date-picker-widget',
  templateUrl: './custom-form-date-picker-widget.component.html',
  styleUrls: ['./custom-form-date-picker-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex' },
  standalone: false,
})
export class CustomFormDatePickerWidgetComponent implements OnInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  formControl: FormControl;
  momentControl = new TypedFormControl<moment.Moment>();
  controlName: string;
  controlValue: string;
  controlDisabled = false;
  boundControl = false;
  options: IOptions;
  @Input() layoutNode: ILayoutNode;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  constructor(private _jsf: JsonSchemaFormService) {}

  ngOnInit(): void {
    this.options = this.layoutNode.options || {};
    this._jsf.initializeControl(this);
    if (
      !this.options.notitle &&
      !this.options.description &&
      this.options.placeholder
    ) {
      this.options.description = this.options.placeholder;
    }

    const validators = this.options.required ? [Validators.required] : [];
    this.formControl.setValidators(validators);
    this.momentControl.setValidators(validators);

    formControlDisabled$(this.formControl)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((isDisabled) => {
        isDisabled
          ? this.momentControl.disable({ emitEvent: false })
          : this.momentControl.enable({ emitEvent: false });
      });

    formControlChanges$(this.formControl)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => this._updateMomentControlValue(value));

    formControlChanges$(this.momentControl)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) =>
        this.formControl.setValue(value?.toDate(), { emitEvent: true })
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getLabel(options: IOptions): string | undefined {
    return options.notitle ? '' : options.title;
  }

  private _updateMomentControlValue(value?: unknown): void {
    const currentValue = this.momentControl.value?.format(ISO_DATE_FORMAT);
    const newValue =
      isDate(value) || isMoment(value)
        ? toMoment(value).format(ISO_DATE_FORMAT)
        : value;
    if (newValue === currentValue) {
      return;
    }
    if (isString(newValue)) {
      this.momentControl.setValue(toMoment(newValue), { emitEvent: false });
      return;
    }
    this.momentControl.reset(undefined, { emitEvent: false });
  }
}
