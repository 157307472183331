import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';
import { type IContextResolver } from '@principle-theorem/principle-core';
import {
  type ITemplateContext,
  type ITemplateDefinition,
} from '@principle-theorem/principle-core/interfaces';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { type ITemplateFormData } from '../template-editor-form/template-editor-forms';

@Component({
    selector: 'pr-template-editor',
    templateUrl: './template-editor.component.html',
    styleUrls: ['./template-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TemplateEditorComponent {
  private _contextResolver$ = new ReplaySubject<IContextResolver>(1);
  isSystemTemplate = signal(false);
  isAutomationTemplate = signal(false);
  template$ = new ReplaySubject<ITemplateDefinition>(1);
  context$: Observable<ITemplateContext>;
  @Output() templateChange = new EventEmitter<ITemplateFormData>();

  @Input()
  set template(template: ITemplateDefinition) {
    if (template) {
      this.template$.next(template);
    }
  }

  @Input()
  set contextResolver(resolver: IContextResolver) {
    if (resolver) {
      this._contextResolver$.next(resolver);
    }
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('isSystemTemplate')
  set isSystemTemplateInput(value: BooleanInput) {
    this.isSystemTemplate.set(coerceBooleanProperty(value));
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('isAutomationTemplate')
  set isAutomationTemplateInput(value: BooleanInput) {
    this.isAutomationTemplate.set(coerceBooleanProperty(value));
  }

  constructor() {
    this.context$ = combineLatest([
      this.template$,
      this._contextResolver$,
    ]).pipe(
      switchMap(async ([template, resolver]) =>
        resolver.resolve([template.scope])
      ),
      map((context) => context || {})
    );
  }
}
