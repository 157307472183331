<div fxLayout="column" fxLayoutGap="16px">
  <pt-buttons-container>
    <button mat-raised-button color="primary" (click)="addPackage()">
      Add New Package
    </button>
  </pt-buttons-container>

  <ng-container *ngIf="multiTreatmentConfig$ | async as multiTreatmentConfig">
    <pr-edit-multi-treatment-package
      *ngFor="let package of packages$ | async; trackBy: trackByPackage"
      [multiTreatmentPackage]="package"
      [multiTreatmentConfig]="multiTreatmentConfig"
      (updatePackage)="updatePackage(package.uid, $event)"
      (deletePackage)="deletePackage(package.uid)"
     />
  </ng-container>
</div>
