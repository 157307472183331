import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeeScheduleFacade } from '@principle-theorem/ng-clinical-charting/store';
import {
  CurrentPracticeScope,
  GeocodeService,
} from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
  formControlChanges$,
  type IBreadcrumb,
} from '@principle-theorem/ng-shared';
import {
  Brand,
  FeeSchedule,
  Practice,
} from '@principle-theorem/principle-core';
import {
  FeeScheduleScope,
  IFeeSchedule,
  IFeeScheduleGroup,
  type IGeoCoordinates,
  type IPractice,
  type IPracticeSettings,
} from '@principle-theorem/principle-core/interfaces';
import {
  DEFAULT_TIMEZONE,
  DocumentReference,
  Firestore,
  TIMEZONES,
  filterUndefined,
  isSameRef,
  slugify,
  snapshot,
  titlecase,
  type Timezone,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  of,
  type Observable,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';

type IPracticeFormData = Pick<
  IPractice,
  'name' | 'address' | 'email' | 'phone' | 'settings'
>;

interface IFeeScheduleSummary {
  scope: FeeScheduleScope;
  scopeName: string;
  feeScheduleRef: DocumentReference<IFeeSchedule>;
  feeScheduleName: string;
}

@Component({
  selector: 'pr-general-practice',
  templateUrl: './general-practice.component.html',
  styleUrls: ['./general-practice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GeneralPracticeComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByTimezone = TrackByFunctions.variable<Timezone>();
  isSameFeeSchedule = isSameRef;
  practice$: Observable<WithRef<IPractice>>;

  timezones: Timezone[] = TIMEZONES;
  coordinates$ = new BehaviorSubject<IGeoCoordinates | undefined>(undefined);

  feeScheduleGroups$: Observable<IFeeScheduleGroup[]>;
  defaultFeeSchedule$: Observable<WithRef<IFeeSchedule>>;
  unselectedFeeScheduleLabel$: Observable<string>;
  selectedFeeScheduleLabel$: Observable<string>;

  settingsForm = new TypedFormGroup<
    Pick<IPracticeSettings, 'timezone' | 'defaultFeeSchedule'>
  >({
    timezone: new TypedFormControl(DEFAULT_TIMEZONE, Validators.required),
    defaultFeeSchedule: new TypedFormControl<
      DocumentReference<IFeeSchedule> | undefined
    >(undefined),
  });
  form = new TypedFormGroup<IPracticeFormData>({
    name: new TypedFormControl('', Validators.required),
    address: new TypedFormControl('', Validators.required),
    email: new TypedFormControl('', [Validators.required, Validators.email]),
    phone: new TypedFormControl('', Validators.required),
    settings: this.settingsForm,
  });
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(
    private _snackBar: MatSnackBar,
    private _geocodeService: GeocodeService,
    private _currentPractice: CurrentPracticeScope,
    private _feeSchedules: FeeScheduleFacade
  ) {
    this.practice$ = this._currentPractice.doc$.pipe(filterUndefined());
    this.practice$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((practice) => this.form.patchValue(practice));

    this.practice$
      .pipe(
        take(1),
        switchMap(async (practice) => {
          const brandRef = Practice.brandRef(practice);
          const organisationRef = Brand.organisationRef({ ref: brandRef });
          return {
            organisation: await Firestore.getDoc(organisationRef),
            brand: await Firestore.getDoc(brandRef),
            practice,
          };
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(({ organisation, brand, practice }) =>
        this._feeSchedules.loadFeeSchedules({ organisation, brand, practice })
      );

    this.feeScheduleGroups$ = this._feeSchedules.feeScheduleGroups$;
    this.defaultFeeSchedule$ = this.practice$.pipe(
      switchMap((practice) => Firestore.doc$(FeeSchedule.defaultRef(practice)))
    );
    this.unselectedFeeScheduleLabel$ = combineLatest([
      this.feeScheduleGroups$,
      this.defaultFeeSchedule$,
    ]).pipe(
      map(
        ([groups, feeSchedule]) =>
          `Auto - ${this._getFeeScheduleLabel(groups, feeSchedule.ref)}`
      )
    );
    this.selectedFeeScheduleLabel$ = combineLatest([
      this.feeScheduleGroups$,
      this.unselectedFeeScheduleLabel$,
      formControlChanges$(this.settingsForm.controls.defaultFeeSchedule),
    ]).pipe(
      map(
        ([schedules, unselectedLabel, selected]) =>
          this._getFeeScheduleLabel(schedules, selected) ?? unselectedLabel
      )
    );

    formControlChanges$(this.form.controls.address)
      .pipe(
        debounceTime(500),
        switchMap((address) =>
          address
            ? this._geocodeService
                .geocodeAddress$(address)
                .pipe(catchError(() => of(undefined)))
            : of(undefined)
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((coordinates) => this.coordinates$.next(coordinates));

    this.breadcrumbs$ = this._currentPractice.doc$.pipe(
      filterUndefined(),
      map((practice) => [
        { label: 'Settings', path: '../../../' },
        { label: practice.name },
        { label: 'General Settings' },
      ])
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async save(): Promise<void> {
    const practice = await snapshot(this.practice$);
    const coordinates = await snapshot(this.coordinates$);
    const changes = this.form.getRawValue();
    await Firestore.saveDoc({
      ...practice,
      ...changes,
      slug: slugify(changes.name.toLowerCase()),
      coordinates,
    });
    this._snackBar.open('Practice Updated');
  }

  private _getFeeScheduleLabel(
    scheduleGroups: IFeeScheduleGroup[],
    feeScheduleRef?: DocumentReference<IFeeSchedule>
  ): string | undefined {
    if (!feeScheduleRef) {
      return;
    }
    const summaries = this._getFeeScheduleSummaries(scheduleGroups);
    const selected = summaries.find((summary) =>
      isSameRef(summary.feeScheduleRef, feeScheduleRef)
    );
    if (!selected) {
      return;
    }
    const scopeTitle =
      selected.scope !== FeeScheduleScope.Organisation
        ? `(${titlecase(selected.scope)})`
        : undefined;
    return compact([
      selected.scopeName,
      scopeTitle,
      `-`,
      selected.feeScheduleName,
    ]).join(' ');
  }

  private _getFeeScheduleSummaries(
    groups: IFeeScheduleGroup[]
  ): IFeeScheduleSummary[] {
    return groups.reduce((acc: IFeeScheduleSummary[], group) => {
      const groupSummaries = group.schedules.map((schedule) => ({
        scope: group.scope,
        scopeName: group.name,
        feeScheduleRef: schedule.ref,
        feeScheduleName: schedule.name,
      }));
      return [acc, groupSummaries].flat();
    }, []);
  }
}
