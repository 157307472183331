import { MockTimeUntilAppointment } from '@principle-theorem/principle-core';
import {
  PaymentPlanAction,
  PaymentPlanStatus,
} from '@principle-theorem/principle-core/interfaces';
import { IPaymentPlanEventFact } from '@principle-theorem/reporting/interfaces';
import {
  BaseMock,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
} from '@principle-theorem/shared';
import { BrandDimensionMock } from '../dimensions/brand-dimension.mock';
import { SerialisedDocumentReferenceMock } from '../dimensions/common.mock';
import { PatientDimensionMock } from '../dimensions/patient-dimension.mock';
import { PaymentPlanDimensionMock } from '../dimensions/payment-plan-dimension.mock';

export class PaymentPlanEventFactMock
  extends BaseMock
  implements IPaymentPlanEventFact
{
  patientRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  brandRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  paymentPlan = MockGenerator.generate(PaymentPlanDimensionMock);
  brand = MockGenerator.generate(BrandDimensionMock);
  patient = MockGenerator.generate(PatientDimensionMock);
  referrer = this.patient.referrer;
  timestamp = toSerialisedTimestamp(MockTimestamp());
  event = {
    action: PaymentPlanAction.Complete,
    statusBefore: PaymentPlanStatus.Active,
    statusAfter: PaymentPlanStatus.Complete,
  };
  timeUntilAppointment = MockTimeUntilAppointment();
}
