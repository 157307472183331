import { type ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { PhoneComponent } from '../../phone/phone.component';
import { InteractionWithTemplatesService } from './interaction-with-templates.service';

@Injectable()
export class PhoneService extends InteractionWithTemplatesService<PhoneComponent> {
  override icon = 'call';
  override name = 'Call';
  override dialogComponent: ComponentType<PhoneComponent> = PhoneComponent;
}
