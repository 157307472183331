import {
  TextFormatting,
  type IMenuButton,
  BlockNodes,
} from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../menu-bar/basic-menu-button/basic-menu-button.component';

export function createCodeMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'basic',
      buttonText: 'Code',
      tooltip: 'Code',
      icon: 'code',
      command: (editor) => editor.chain().focus().toggleCode().run(),
      isActiveFn: (editor) => editor.isActive(TextFormatting.Code),
      canActivateFn: (editor) => editor.isActive(BlockNodes.Paragraph),
    },
  });
}
