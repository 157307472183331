<ng-container *ngIf="noMedicalHistory$ | async as noMedicalHistory">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <mat-icon matTooltip="Patient Alerts" color="warn">report_problem</mat-icon>
    <mat-chip-listbox [selectable]="false">
      <mat-chip
        matTooltip="Update Medical History"
        (click)="bloc.openMedicalHistoryForm()"
        color="warn"
        class="mat-caption"
        highlighted
      >
        {{ noMedicalHistory }}
      </mat-chip>
    </mat-chip-listbox>
  </div>
</ng-container>
