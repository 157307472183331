import { IMatchesADAScope } from './base-rule-handler';
import {
  IScopeRef,
  IChartedSurface,
  ServiceCodeScope,
  IADACodeRules,
} from '@principle-theorem/principle-core/interfaces';

export class PerAnterior implements IMatchesADAScope {
  scope: ServiceCodeScope = ServiceCodeScope.PerAnterior;

  matches(
    _rule: IADACodeRules,
    _treatmentScope: IScopeRef,
    surfaces: IChartedSurface[]
  ): boolean {
    return surfaces.some((surface: IChartedSurface) => {
      if (!surface.chartedRef.tooth) {
        return false;
      }
      return surface.chartedRef.tooth.quadrantIndex <= 3;
    });
  }
}
