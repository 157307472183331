@if (automation$ | async; as automation) {
  <div class="flex flex-row items-center gap-4">
    @if (withCheckbox) {
      <div (click)="$event.stopPropagation()">
        <mat-checkbox
          color="primary"
          [checked]="checked$ | async"
          (change)="checkedChange.emit($event)"
        />
      </div>
    }

    <pr-automation-icon [automation]="automation.data" />

    <div class="flex grow flex-col">
      <div class="title truncate">
        <pr-content-text [content]="title$ | async" />
      </div>

      <div class="text-xs opacity-50">
        From: {{ automation.creator.name }}
        <mat-icon
          class="icon-3"
          [matTooltip]="automation.creator.type | splitCamel | titlecase"
        >
          info
        </mat-icon>
      </div>
    </div>

    @if (triggerDate$ | async; as triggerDate) {
      <div
        class="text-xs"
        [ngClass]="{ 'new-date': isNewTriggerDate$ | async }"
        matTooltip="New trigger date calculated from new event time"
      >
        {{ triggerDate | moment | amDateFormat: dateFormat }}
      </div>
    }

    <pr-automation-status class="w-20" [status]="automation.status" />

    <ng-content />
  </div>
}
