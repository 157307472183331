<div class="timeline" fxLayout="column">
  <mat-accordion class="popout timeline-content">
    <mat-expansion-panel
      *ngFor="let alert of schedulingAlerts; trackBy: trackByAlert"
      hideToggle="hideToggle"
    >
      <mat-expansion-panel-header
        (click)="displayGapOnTimeline(alert)"
        prEnableExpansionPanelHeaderActions
      >
        <pr-alerts-header fxFill [schedulingAlert]="alert" />
      </mat-expansion-panel-header>

      <div class="timeline-card">
        <mat-divider />
        <pr-scheduling-alert-item [alert]="alert" />
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
