import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  type RouterStateSnapshot,
  type UrlTree,
} from '@angular/router';
import { type Observable } from 'rxjs';

export interface IComponentCanDeactivate {
  canDeactivate: () => boolean;
}

@Injectable({ providedIn: 'root' })
export class PendingChangesGuard {
  canDeactivate(
    component: IComponentCanDeactivate,
    _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    _nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!component.canDeactivate) {
      return true;
    }
    return component.canDeactivate()
      ? true
      : confirm(
          'You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.'
        );
  }
}
