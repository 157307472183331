import { MixedSchema } from '@principle-theorem/editor';
import {
  isEnumValue,
  isINamedDocument,
  isObject,
  type DocumentReference,
  type INamedDocument,
} from '@principle-theorem/shared';
import { type IAppointment } from '../appointment/appointment';
import { type IStatusHistory } from '../common';
import { type ILab, type ILabJobType } from '../lab/lab';
import { type IPatient } from '../patient/patient';
import { type IWorkflowItem } from '../workflow-item/workflow-item';

export enum LabJobAction {
  Sent = 'sent',
  Received = 'received',
}

export enum LabJobStatus {
  Sending = 'sending',
  Sent = 'sent',
  Received = 'received',
}

export const LAB_JOB_STATUSES: LabJobStatus[] = [
  LabJobStatus.Sending,
  LabJobStatus.Sent,
  LabJobStatus.Received,
];

export const LAB_JOB_STATUS_COLOUR_MAP: {
  [key in LabJobStatus]: string;
} = {
  [LabJobStatus.Sending]: 'default',
  [LabJobStatus.Sent]: 'primary',
  [LabJobStatus.Received]: 'accent',
};

export interface ILabJob extends IWorkflowItem {
  lab: INamedDocument<ILab>;
  status: LabJobStatus;
  statusHistory: IStatusHistory<LabJobStatus>[];
  patient: INamedDocument<IPatient>;
  appointment?: IAppointmentDocument;
  type: ILabJobType;
  cost: number;
  description?: MixedSchema;
}

export function isLabJob(item: unknown): item is ILabJob {
  return (
    isObject(item) &&
    'lab' in item &&
    isINamedDocument(item.lab) &&
    'status' in item &&
    isEnumValue(LabJobStatus, item.status)
  );
}

export interface IAppointmentDocument {
  treatmentPlanName?: string;
  ref: DocumentReference<IAppointment>;
}
