import { randNumber } from '@ngneat/falso';
import { FactsCommon } from '@principle-theorem/reporting/interfaces';
import {
  duplicate,
  ITimePeriod,
  Timezone,
  toSerialisedTimestamp,
  toTimePeriod,
  toTimestamp,
  wrapWithSeed,
} from '@principle-theorem/shared';
import { flatten } from 'lodash';
import * as moment from 'moment-timezone';
import { IChartConfig } from '../chart-config';

const startDate = moment('2022-01-01T09:00:00.000');
const DEFAULT_MOCK_TIME_PERIOD = toTimePeriod(
  startDate.clone(),
  startDate.clone().add(5, 'days')
);

// TODO: ahh do we need this?
export class CommonMockValues {
  static staff = [
    {
      name: 'Dr Acula',
      ref: 'testing/document/reference/dr-acula',
    },
    {
      name: 'Dr Octopus',
      ref: 'testing/document/reference/dr-octopus',
    },
  ];
  static referrers = [
    {
      name: 'Google',
      ref: 'testing/document/reference/google',
    },
    {
      name: 'Word of Mouth',
      ref: 'testing/document/reference/word-of-mouth',
    },
  ];
  static practices = [
    {
      name: 'Golden Dental',
      ref: 'testing/document/reference/golden-dental',
    },
    {
      name: 'Silver Smiles',
      ref: 'testing/document/reference/silver-smiles',
    },
  ];
}

export function MockTimestampedFactFn<T extends FactsCommon.ITimestampedFact>(
  data: Omit<T, 'timestamp'>
): (timestampStr: string) => T {
  return (timestampStr: string) => {
    const timestamp = toSerialisedTimestamp(
      toTimestamp(moment.tz(timestampStr, Timezone.AustraliaSydney))
    );
    return { ...data, timestamp } as T;
  };
}

function MockTimestampedResults<T extends FactsCommon.ITimestampedFact>(
  getDataFn: (timestamp: string, index: number) => Omit<T, 'timestamp'>[],
  dateRange: ITimePeriod = DEFAULT_MOCK_TIME_PERIOD
): T[] {
  const numberOfDays = 1 + dateRange.to.diff(dateRange.from, 'days');
  const valuesPerDay = duplicate({}, numberOfDays).map((_, index) => {
    const timestamp = dateRange.from.clone().add(index, 'days').toISOString();
    return getDataFn(timestamp, index).map((value) =>
      MockTimestampedFactFn(value)(timestamp)
    );
  });
  return flatten(valuesPerDay);
}

export function MockTimestampedFacts<T extends FactsCommon.ITimestampedFact>(
  seed: string,
  getDataFn: (itemSeed: string, timestamp: string) => Omit<T, 'timestamp'>,
  getItemsForDayFn?: (timestamp: string) => number,
  dateRange: ITimePeriod = DEFAULT_MOCK_TIME_PERIOD
): T[] {
  return MockTimestampedResults((timestamp) => {
    const daySeed = `${seed}:${timestamp}`;
    const count = wrapWithSeed(daySeed, () =>
      getItemsForDayFn
        ? getItemsForDayFn(timestamp)
        : randNumber({ min: 0, max: 12 })
    );
    return duplicate({}, count).map((_, index) => {
      const itemSeed = `${daySeed}:${index}`;
      return wrapWithSeed(itemSeed, () => getDataFn(itemSeed, timestamp));
    });
  }, dateRange);
}

export interface IChartConfigTest<T = unknown> {
  config: IChartConfig;
  seed: string;
  mockFact: () => T;
}
