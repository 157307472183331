import { ILab } from '@principle-theorem/principle-core/interfaces';
import { initFirestoreModel } from '@principle-theorem/shared';

export class Lab {
  static init(overrides?: Partial<ILab>): ILab {
    return {
      name: '',
      address: '',
      email: '',
      phone: '',
      labJobTypes: [],
      notes: [],
      interactions: [],
      ...initFirestoreModel(),
      ...overrides,
    };
  }
}
