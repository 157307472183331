import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { Roster } from '@principle-theorem/principle-core';
import {
  type IPractice,
  type IRosterTime,
  type IScheduleRange,
} from '@principle-theorem/principle-core/interfaces';
import {
  type DayOfWeek,
  isTime24hrType,
  saveDoc,
  type Time24hrType,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';

@Component({
    selector: 'pr-edit-practice-opening-hours',
    templateUrl: './edit-practice-opening-hours.component.html',
    styleUrls: ['./edit-practice-opening-hours.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditPracticeOpeningHoursComponent {
  practice: WithRef<IPractice>;
  dayOfWeek: DayOfWeek;
  timeInterval: moment.Duration = moment.duration(30, 'minutes');
  form = new TypedFormGroup<IScheduleRange>({
    from: new TypedFormControl<Time24hrType>().withGuard(isTime24hrType),
    to: new TypedFormControl<Time24hrType>().withGuard(isTime24hrType),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IPracticeOpenHoursData,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditPracticeOpeningHoursComponent>
  ) {
    this.practice = data.practice;
    this.dayOfWeek = data.dayOfWeek;
    const openingTimes: IScheduleRange | undefined = Roster.getOpeningTimes(
      this.practice.schedule,
      this.dayOfWeek
    );
    if (openingTimes) {
      this.form.patchValue(openingTimes);
    }
  }

  async save(): Promise<void> {
    const data: IScheduleRange = this.form.getRawValue();
    const day: IRosterTime[] = Roster.getByDay(
      this.practice.schedule,
      this.dayOfWeek
    );
    if (!day.length) {
      this.practice.schedule.days.push({
        dayOfWeek: this.dayOfWeek,
        shift: data,
      });
    } else {
      day[0].shift = data;
    }
    await saveDoc(this.practice);
    this._snackBar.open('Times updated');
    this.dialogRef.close();
  }
}

export interface IPracticeOpenHoursData {
  practice: WithRef<IPractice>;
  dayOfWeek: DayOfWeek;
}
