import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { recursiveReplace } from '@principle-theorem/editor';
import {
  InputSearchFilter,
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
  toSearchStream,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import {
  CustomFormDataResolverConfigMap,
  FormSchemaPropertyType,
  ICustomFormData,
  ICustomFormDataResolverConfig,
  IFormSchema,
} from '@principle-theorem/principle-core/interfaces';
import { isObject } from '@principle-theorem/shared';
import { cloneDeep, differenceBy, isString } from 'lodash';
import { Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

export enum ResolvedAlertType {
  Conditions = 'conditions',
  Allergies = 'allergies',
  History = 'history',
}

@Component({
    selector: 'pr-medical-history-alerts-configure',
    templateUrl: './medical-history-alerts-configure.component.html',
    styleUrls: ['./medical-history-alerts-configure.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MedicalHistoryAlertsConfigureComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  schema$ = new ReplaySubject<IFormSchema>(1);
  resolverConfigs$ = new ReplaySubject<CustomFormDataResolverConfigMap>(1);
  availableOptions$: Observable<ICustomFormDataResolverConfig[]>;
  form = new TypedFormGroup<CustomFormDataResolverConfigMap>({
    conditions: new TypedFormArray<ICustomFormDataResolverConfig>([]),
    allergies: new TypedFormArray<ICustomFormDataResolverConfig>([]),
    history: new TypedFormArray<ICustomFormDataResolverConfig>([]),
  });
  @Output() updateAlerts = new EventEmitter<
    ICustomFormData['dataResolverConfig']
  >();
  alertType = ResolvedAlertType;
  alertsSearchFilter: InputSearchFilter<ICustomFormDataResolverConfig>;

  alertSearchCtrl = new TypedFormControl<
    ICustomFormDataResolverConfig | string
  >();

  get conditions(): TypedFormArray<ICustomFormDataResolverConfig> {
    return this.form.controls
      .conditions as TypedFormArray<ICustomFormDataResolverConfig>;
  }

  get conditionControls(): TypedFormGroup<
    Pick<ICustomFormDataResolverConfig, 'path' | 'mapTitle'>
  >[] {
    return this.conditions.controls as TypedFormGroup<
      Pick<ICustomFormDataResolverConfig, 'path' | 'mapTitle'>
    >[];
  }

  get allergies(): TypedFormArray<ICustomFormDataResolverConfig> {
    return this.form.controls
      .allergies as TypedFormArray<ICustomFormDataResolverConfig>;
  }

  get allergyControls(): TypedFormGroup<
    Pick<ICustomFormDataResolverConfig, 'path' | 'mapTitle'>
  >[] {
    return this.allergies.controls as TypedFormGroup<
      Pick<ICustomFormDataResolverConfig, 'path' | 'mapTitle'>
    >[];
  }

  get history(): TypedFormArray<ICustomFormDataResolverConfig> {
    return this.form.controls
      .history as TypedFormArray<ICustomFormDataResolverConfig>;
  }

  get historyControls(): TypedFormGroup<
    Pick<ICustomFormDataResolverConfig, 'path' | 'mapTitle'>
  >[] {
    return this.history.controls as TypedFormGroup<
      Pick<ICustomFormDataResolverConfig, 'path' | 'mapTitle'>
    >[];
  }

  @Input()
  set resolverConfigs(resolverConfigs: CustomFormDataResolverConfigMap) {
    if (resolverConfigs) {
      this.resolverConfigs$.next(resolverConfigs);
    }
  }

  @Input()
  set schema(schema: IFormSchema) {
    if (schema) {
      this.schema$.next(schema);
    }
  }

  constructor() {
    this.availableOptions$ = combineLatest([
      this.resolverConfigs$.pipe(
        map((resolverConfigs) => this.getUsedOptions(resolverConfigs))
      ),
      this.schema$.pipe(map((schema) => this.getValidOptions(schema))),
    ]).pipe(
      map(([usedOptions, validOptions]) =>
        differenceBy(validOptions, usedOptions, (option) => option.path)
      )
    );

    const alertsSearchStream = toSearchStream(this.alertSearchCtrl);

    this.alertsSearchFilter = new InputSearchFilter(
      this.availableOptions$,
      alertsSearchStream.pipe(
        map((stream) => stream as ICustomFormDataResolverConfig)
      ),
      ['mapTitle', 'path']
    );

    this.resolverConfigs$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((configs) => {
        this.conditions.reset([], { emitEvent: false });
        this.allergies.reset([], { emitEvent: false });
        this.history.reset([], { emitEvent: false });

        configs.conditions?.map((config) =>
          this.addAlert(config, ResolvedAlertType.Conditions, false)
        );

        configs.allergies?.map((config) =>
          this.addAlert(config, ResolvedAlertType.Allergies, false)
        );

        configs.history?.map((config) =>
          this.addAlert(config, ResolvedAlertType.History, false)
        );
      });

    validFormGroupChanges$(this.form)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this.updateAlerts.emit(this.form.getRawValue()));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  addAlert(
    alert: ICustomFormDataResolverConfig,
    type: ResolvedAlertType,
    emitEvent: boolean = true
  ): void {
    switch (type) {
      case ResolvedAlertType.Conditions:
        this.conditions.push(this._createResolverConfigFormGroup(alert), {
          emitEvent,
        });
        break;
      case ResolvedAlertType.Allergies:
        this.allergies.push(this._createResolverConfigFormGroup(alert), {
          emitEvent,
        });
        break;
      case ResolvedAlertType.History:
        this.history.push(this._createResolverConfigFormGroup(alert), {
          emitEvent,
        });
        break;
      default:
        break;
    }
    this.alertSearchCtrl.setValue('');
  }

  removeAlert(index: number, type: ResolvedAlertType): void {
    switch (type) {
      case ResolvedAlertType.Conditions:
        this.conditions.removeAt(index);
        break;
      case ResolvedAlertType.Allergies:
        this.allergies.removeAt(index);
        break;
      case ResolvedAlertType.History:
        this.history.removeAt(index);
        break;
      default:
        break;
    }
  }

  getUsedOptions(
    resolverConfigs: CustomFormDataResolverConfigMap
  ): ICustomFormDataResolverConfig[] {
    return Object.values(resolverConfigs).reduce(
      (acc, config) => acc.concat(config),
      []
    );
  }

  getValidOptions(schema: IFormSchema): ICustomFormDataResolverConfig[] {
    const options: ICustomFormDataResolverConfig[] = [];
    recursiveReplace(cloneDeep(schema), (option, _parents, key) => {
      if (isObject(option.properties) && 'properties' in option) {
        return option.properties;
      }

      if (isObject(option) && 'type' in option && 'title' in option) {
        if (
          [
            FormSchemaPropertyType.Boolean,
            FormSchemaPropertyType.String,
          ].includes(option.type as FormSchemaPropertyType)
        ) {
          options.push({
            path: key ?? '',
            mapTitle: String(option.title),
          });
        }
      }

      return option;
    });

    return options;
  }

  displayFn(value: string | ICustomFormDataResolverConfig): string {
    if (!value) {
      return '';
    }
    if (!isString(value)) {
      return value.mapTitle ?? value.path;
    }
    return value;
  }

  private _createResolverConfigFormGroup(
    alert: ICustomFormDataResolverConfig
  ): TypedFormGroup<Pick<ICustomFormDataResolverConfig, 'path' | 'mapTitle'>> {
    return new TypedFormGroup<
      Pick<ICustomFormDataResolverConfig, 'path' | 'mapTitle'>
    >({
      path: new TypedFormControl<string>(alert.path),
      mapTitle: new TypedFormControl<string>(alert.mapTitle, {
        updateOn: 'blur',
      }),
    });
  }
}
