import { type IChartConfig } from '@principle-theorem/reporting';
import * as d3 from 'd3';
import { type IDCTransformResult } from '../../../../models/report/charts/data-transformers/dc-data-transformer';
import { type ID3ChartRenderer } from '../../d3-chart/d3-chart.component';
import { type ID3ChartBuilder } from './d3-chart-builder';
import {
  addAxisScales,
  addLegend,
  DEFAULT_D3_CHART_CONFIG,
  type ID3ChartConfig,
  setupChart,
  toCrossfilterGroupMap,
  translate,
} from './d3-chart-shared';
import {
  formatCrossfilter,
  type GroupedD3ChartData,
} from './d3-grouped-crossfilter';

export class D3GroupedRowChartBuilder implements ID3ChartBuilder {
  getLabel(config: IChartConfig): string {
    return `Grouped: ` + config.labels.title;
  }

  getRenderer(results: IDCTransformResult[]): ID3ChartRenderer | undefined {
    const groupMap = toCrossfilterGroupMap(results);
    const data = formatCrossfilter(groupMap);
    return {
      render: (elemSelector) =>
        renderGroupedRowChart(elemSelector, data, DEFAULT_D3_CHART_CONFIG),
    };
  }
}

function renderGroupedRowChart<T extends Record<string, unknown>>(
  elem: Element,
  items: GroupedD3ChartData<T>[],
  config: ID3ChartConfig
): void {
  const chart = setupChart(elem, items, config);

  const ySub = d3
    .scaleBand()
    .domain(chart.subgroups)
    .range([0, chart.yAxis.bandwidth()])
    .padding(0.05);

  const chartGroups = chart.inner
    .append('g')
    .selectAll('g')
    .data(items)
    .enter();
  const chartSubGroups = chartGroups
    .append('g')
    .attr('transform', (d) =>
      translate(0, chart.yAxis(String(d.metadata.groupBy)))
    )
    .selectAll('rect')
    .data((d) => chart.subgroups.map((key) => ({ key, value: d[key] })))
    .enter();

  chartSubGroups
    .append('rect')
    .attr('x', 0)
    .attr('y', (d) => {
      const y = ySub(d.key);
      // console.log('y', { d, y });
      return y ?? 0;
    })
    .attr('width', (d) => {
      const result = chart.xAxis(d.value);
      // console.log('width', { result, d });
      return result ?? 0;
    })
    .attr('height', ySub.bandwidth())
    .attr('fill', (d) => chart.colourScale(d.key));

  addAxisScales(chart.inner, chart.innerHeight, chart.xAxis, chart.yAxis);
  addLegend(
    chart.inner,
    chart.innerHeight,
    chart.innerWidth,
    chart.subgroups,
    chart.colourScale
  );
}
