import {
  MixedSchema,
  VersionedSchemaUnserialiser,
} from '@principle-theorem/editor';
import {
  AppointmentStatus,
  isEventable,
  type IAppointment,
  type IBrand,
  type IClinicalNote,
  type IEventable,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  Firestore,
  type ITimePeriod,
  bufferedQuery$,
  multiConcatMap,
  multiFilter,
  multiMap,
  query$,
  reduce2DArray,
  shareReplayCold,
  snapshot,
  toISODate,
  where,
  type DocumentReference,
  type WithRef,
  type IDataTable,
  Timezone,
  toMomentTz,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { type Observable } from 'rxjs';
import { map, scan, switchMap, take } from 'rxjs/operators';
import { dateRangeToBrandTimezone } from './helpers';
import { EventableQueries } from '../models/eventable-queries';
import { Appointment } from '../models/appointment/appointment';
import { ClinicalNote } from '../models/clinical-charting/core/clinical-note';
import { Brand } from '../models/brand';
import { TimezoneResolver } from '../timezone';

interface IAppointmentsWithoutClinicalNotesData {
  patient: WithRef<IPatient>;
  appointment: WithRef<IAppointment> & IEventable;
}

export enum AppointmentsWithoutClinicalNotesRange {
  Weekly,
  Monthly,
  All,
}

interface IRow {
  appointmentDate: string;
  patient: string;
  practice: string;
  practitioner: string;
  treatmentPlan: string;
}

export class AppointmentsWithoutClinicalNotes {
  async run(
    brandRef: DocumentReference<IBrand>,
    timeRange?: ITimePeriod
  ): Promise<IDataTable<IRow>[]> {
    const brand = await Firestore.getDoc(brandRef);
    const timezone = await TimezoneResolver.fromBrandRef(brandRef);
    const data$ = this._getData$(brand, timezone, timeRange);
    const rows = await data$
      .pipe(
        multiMap((item) => this._toRow(item, timezone)),
        scan((acc: IRow[], results) => [...acc, ...results], [])
      )
      .toPromise();

    return this._toDataTables(rows);
  }

  private _getData$(
    brand: WithRef<IBrand>,
    timezone: Timezone,
    timeRange?: ITimePeriod
  ): Observable<IAppointmentsWithoutClinicalNotesData[]> {
    const unserialiser = new VersionedSchemaUnserialiser();
    return timeRange
      ? this._getForDateRange$(brand, timeRange, unserialiser, timezone)
      : this._getAllData$(brand, unserialiser, timezone);
  }

  private _getForDateRange$(
    brand: WithRef<IBrand>,
    dateRange: ITimePeriod,
    unserialiser: VersionedSchemaUnserialiser,
    timezone: Timezone
  ): Observable<IAppointmentsWithoutClinicalNotesData[]> {
    const appointments$ = EventableQueries.appointments$(
      { ref: brand.ref },
      dateRangeToBrandTimezone(brand, dateRange)
    ).pipe(
      take(1),
      multiFilter(
        (appointment) => appointment.status === AppointmentStatus.Complete
      )
    );

    return appointments$.pipe(
      multiConcatMap((appointment) =>
        Appointment.patient$(appointment).pipe(
          take(1),
          switchMap((patient) =>
            ClinicalNote.all$(patient).pipe(
              take(1),
              multiMap((clinicalNote) => ({
                ...clinicalNote,
                content: unserialiser.transform(
                  clinicalNote.content
                ) as MixedSchema,
              })),
              map((clinicalNotes) => ClinicalNote.filterEmpty(clinicalNotes)),
              map((clinicalNotes) => {
                return this._getAppointments(
                  appointment,
                  clinicalNotes,
                  patient,
                  timezone
                );
              })
            )
          )
        )
      ),
      map(compact),
      shareReplayCold()
    );
  }

  private _getAllData$(
    brand: WithRef<IBrand>,
    unserialiser: VersionedSchemaUnserialiser,
    timezone: Timezone
  ): Observable<IAppointmentsWithoutClinicalNotesData[]> {
    return bufferedQuery$(
      Brand.patientCol({ ref: brand.ref }),
      100,
      'ref'
    ).pipe(
      multiConcatMap(async (patient) => {
        const clinicalNotes = await snapshot(
          ClinicalNote.all$(patient).pipe(
            multiMap((clinicalNote) => ({
              ...clinicalNote,
              content: unserialiser.transform(
                clinicalNote.content
              ) as MixedSchema,
            })),
            map((notes) => ClinicalNote.filterEmpty(notes))
          )
        );
        const appointments = await snapshot(
          query$(
            Appointment.col(patient),
            where('status', '==', AppointmentStatus.Complete)
          )
        );
        return appointments.map((appointment) => {
          return this._getAppointments(
            appointment,
            clinicalNotes,
            patient,
            timezone
          );
        });
      }),
      reduce2DArray(),
      map(compact),
      shareReplayCold()
    );
  }

  private _getAppointments(
    appointment: WithRef<IAppointment>,
    clinicalNotes: WithRef<IClinicalNote>[],
    patient: WithRef<IPatient>,
    timezone: Timezone
  ): IAppointmentsWithoutClinicalNotesData | undefined {
    if (
      !isEventable(appointment) ||
      appointment.treatmentPlan.name.endsWith('Migrated Appointments')
    ) {
      return;
    }
    const hasNotes = clinicalNotes.some(
      (note) => note.recordDate === toISODate(appointment.event.from, timezone)
    );
    if (hasNotes) {
      return;
    }
    return {
      patient,
      appointment,
    };
  }

  private _toRow(
    data: IAppointmentsWithoutClinicalNotesData,
    timezone: Timezone
  ): IRow {
    return {
      appointmentDate: toMomentTz(data.appointment.event.from, timezone).format(
        DAY_MONTH_YEAR_FORMAT
      ),
      patient: data.patient.name,
      practice: data.appointment.practice.name,
      practitioner: data.appointment.practitioner.name,
      treatmentPlan: data.appointment.treatmentPlan.name,
    };
  }

  private _toDataTables(data: IRow[]): IDataTable<IRow>[] {
    return [
      {
        name: 'Missing Clinical Notes',
        data,
        columns: [
          { key: 'appointmentDate', header: 'Appointment Date' },
          { key: 'patient', header: 'Patient' },
          { key: 'practice', header: 'Practice' },
          { key: 'practitioner', header: 'Practitioner' },
          { key: 'treatmentPlan', header: 'Treatment Plan' },
        ],
      },
    ];
  }
}
