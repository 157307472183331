import {
  type AtLeast,
  type ISODateType,
  type IXSLXExport,
  type XSLXCell,
} from '@principle-theorem/shared';
import type { Column } from 'exceljs';
import { type ID4WADAItem } from '../../source/entities/ada-item';

export interface IPatientXSLX {
  patientId: string;
  dateOfBirth?: ISODateType;
  lastName: string;
  firstName: string;
}

export const PATIENT_HEADERS: AtLeast<Column, 'key' | 'header'>[] = [
  {
    key: 'patientId',
    header: 'Patient Id',
    width: 10,
  },
  {
    key: 'dateOfBirth',
    header: 'Date of Birth',
    width: 20,
  },
  {
    key: 'lastName',
    header: 'Last Name',
    width: 25,
  },
  {
    key: 'firstName',
    header: 'First Name',
    width: 25,
  },
];

export class PatientsToXSLX implements IXSLXExport<ID4WADAItem, IPatientXSLX> {
  headers = PATIENT_HEADERS;

  translate(_records: unknown[]): Record<keyof IPatientXSLX, XSLXCell>[] {
    return [
      {
        patientId: {
          value: undefined,
        },
        dateOfBirth: {
          value: undefined,
        },
        lastName: {
          value: undefined,
        },
        firstName: {
          value: undefined,
        },
      },
    ];
  }
}
