<div class="editor-container flex gap-1">
  <div class="hide-overflow flex-none basis-6/12">
    <div #flaggedTreatment class="flex items-center justify-between">
      <h2>Flagged Treatment</h2>
      <div>
        <pr-add-charted-treatment-button
          [connectedTo]="flaggedTreatment | map : toElementRef"
          (addChartable)="
            addFlaggedTreatment($event.chartable, $event.selectedSurfaces)
          "
        />
        <ng-container *ngIf="flaggedTreatments$ | async as flagged">
          <button
            *ngIf="flagged.length"
            mat-icon-button
            (click)="selectAllFlaggedTreatments()"
            matTooltip="Select All Flagged Treatments"
            aria-label="Select All Flagged Treatments"
          >
            <mat-icon>checklist</mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
    <p class="mat-caption">
      Charted treatments that are not on a treatment plan
    </p>
    <pr-flagged-treatment
      [stepDragDrop]="stepDragDrop"
      (updateChartable)="updateChartable($event)"
    />
  </div>

  <div
    class="hide-overflow flex flex-none basis-6/12 flex-col"
    *ngrxLet="currentPlan$ as currentPlan"
  >
    <ng-container *ngIf="showPlanView; else consolidatedView">
      <div class="plan-margin flex flex-col gap-2">
        <pr-treatment-plan-editor-header
          [plan]="currentPlan"
          (setFeeSchedule)="setFeeSchedule.emit($event)"
        />

        <pr-treatment-plan-editor
          [stepDragDrop]="stepDragDrop"
          [plan]="currentPlan"
          [disabled]="planDisabled$ | ngrxPush"
        />

        <ng-container *ngIf="childPlans$ | async as childPlans">
          <ng-container *ngIf="childPlans.length">
            <div class="child-plan-list">
              <h3>Child Plans</h3>
              <p class="mat-caption">
                Plan(s) previously linked from an Initial Consultation plan
              </p>
              <a
                *ngFor="let plan of childPlans; trackBy: trackByPlan"
                class="action-link step-container"
                [routerLink]="['../../treatment-plans', plan.ref.id]"
              >
                {{ plan.name }}
              </a>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #consolidatedView>
      <div class="plan-margin">
        <pr-treatment-plans-consolidated
          [patient]="patient"
          [stepDragDrop]="stepDragDrop"
          (selectedTreatmentPlan)="updateCurrentPlan($event)"
        />
      </div>
    </ng-template>
  </div>
</div>
