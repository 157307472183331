import { Directive, ElementRef, Optional } from '@angular/core';
import {
  MatDialogClose,
  MatDialog,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import { Dialog, DialogRef } from '@angular/cdk/dialog';

@Directive({
  selector: 'button[pt-dialog-close], button[ptDialogClose]',
  exportAs: 'prConnectedDialogClose',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(click)': 'dialogRef.close(dialogResult)',
    '[attr.aria-label]': 'ariaLabel || null',
    type: 'button', // Prevents accidental form submits.
  },
})
export class ConnectedDialogCloseDirective extends MatDialogClose {
  constructor(
    @Optional() dialogRef: DialogRef<unknown>,
    elementRef: ElementRef<HTMLElement>,
    dialog: Dialog
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    super(dialogRef, elementRef, dialog as unknown as MatDialog);
  }
}

@Directive({
  selector: '[pt-dialog-title], [ptDialogTitle]',
  exportAs: 'ptDialogTitle',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'pt-dialog-title',
    '[id]': 'id',
  },
})
export class ConnectedDialogTitleDirective extends MatDialogTitle {
  constructor(
    @Optional() dialogRef: DialogRef<unknown>,
    elementRef: ElementRef<HTMLElement>,
    dialog: Dialog
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    super(dialogRef, elementRef, dialog as unknown as MatDialog);
  }
}

@Directive({
  selector: `[pt-dialog-content], pt-dialog-content, [ptDialogContent]`,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'pt-dialog-content' },
})
export class ConnectedDialogContentDirective extends MatDialogContent {}

@Directive({
  selector: `[pt-dialog-actions], pt-dialog-actions, [ptDialogActions]`,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'pt-dialog-actions' },
})
export class ConnectedDialogActionsDirective extends MatDialogActions {}
