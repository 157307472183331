import { type WithRef } from '@principle-theorem/shared';
import {
  ChartItemDisplayType,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { type IChartedItem } from '@principle-theorem/principle-core/interfaces';
import { isChartedCondition } from '@principle-theorem/principle-core/interfaces';
import { ChartedItem } from '@principle-theorem/principle-core';

export function getChartItemDisplayType(
  item: IChartedItem,
  chartingAs?: WithRef<IStaffer>
): ChartItemDisplayType {
  if (isChartedCondition(item)) {
    if (ChartedItem.isResolved(item)) {
      return ChartItemDisplayType.ResolvedCondition;
    }
    return ChartItemDisplayType.CurrentCondition;
  }

  // Treatment
  if (!ChartedItem.isResolved(item)) {
    return ChartItemDisplayType.ProposedTreatment;
  }

  if (!item.resolvedBy || !chartingAs) {
    return ChartItemDisplayType.ExistingTreatment;
  }
  return ChartItemDisplayType.CompletedTreatment;
}
