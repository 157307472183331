import { ISnippet } from '@principle-theorem/editor';
import { INotification } from '@principle-theorem/notifications';
import {
  ISODateType,
  Region,
  Timezone,
  WithRef,
} from '@principle-theorem/shared';
import { IAccountCredit } from './account-credit/account-credit';
import { IAppointment } from './appointment/appointment';
import { ISchedulingEventReason } from './appointment/cancellation';
import { IChecklistItem } from './appointment/checklist-item';
import { IRole } from './auth/role';
import { IAutomation, IAutomationConfiguration } from './automation/automation';
import { AnyAutomation } from './automation/automations';
import { IBrand } from './brand';
import { IClinicalChart } from './clinical-charting/clinical-chart';
import { IConditionConfiguration } from './clinical-charting/conditions/condition-configuration';
import { IClinicalNote } from './clinical-charting/core/clinical-note';
import { IFeeSchedule } from './clinical-charting/fees/fee-schedule/fee-schedule';
import { IMultiTreatmentConfiguration } from './clinical-charting/treatment/multi-treatment-configuration';
import { ITreatmentConfiguration } from './clinical-charting/treatment/treatment-configuration';
import { ITreatmentPlan } from './clinical-charting/treatment/treatment-plan';
import { ITreatmentStep } from './clinical-charting/treatment/treatment-step';
import { ITreatmentTemplate } from './clinical-charting/treatment/treatment-template';
import { IContact } from './contact/contact';
import { IPatientForm } from './custom-forms/patient-form';
import { ICalendarEvent, ICalendarEventSchedule } from './event/calendar-event';
import { IScheduleSummary } from './event/schedule-summary';
import { IFollowUp } from './follow-up/follow-up';
import { IInteractionV2 } from './interaction/interaction';
import { IInvoice } from './invoice/invoice';
import { ILabJob } from './lab-job/lab-job';
import { ILab } from './lab/lab';
import { IMedia } from './media/media';
import { IOrganisation } from './organisation/organisation';
import { IPatientPhoneNumber } from './patient-phone-number';
import { IPatient } from './patient/patient';
import { IPatientDocument } from './patient/patient-document';
import { IPatientMetadataDisplay } from './patient/patient-metadata';
import { IReferralSourceConfiguration } from './patient/referral-source';
import { IPractice } from './practice/practice';
import { IPrescriptionItem } from './prescription/prescription';
import { IProduct } from './product/product';
import { IStaffer } from './staffer/staffer';
import {
  ISterilisationCycleType,
  ISterilisationEquipment,
  ISterilisationMachine,
  ISterilisationPack,
  ISterilisationRecord,
} from './sterilisation/sterilisation-record';
import { ITag } from './tag/tag';
import { IRecurringTaskConfiguration } from './task/recurring-task';
import { ITask } from './task/task';
import { ITeam } from './team/team';
import { ITemplateDefinition } from './templating/template-definition';
import { IManualTransactionType } from './transaction/manual-transaction-type';
import { ITreatmentCategory } from './treatment-category';
import { IUser } from './user';

export interface IExportedDemoOrganisation {
  anchorDate: ISODateType;
  brands: IExportedDemoBrand[];
  feeSchedules: WithRef<IFeeSchedule>[];
  organisation: WithRef<IOrganisation>;
  roles: WithRef<IRole>[];
  users: WithRef<IUser>[];
}

export interface IExportedDemoBrand {
  appointmentTags: WithRef<ITag>[];
  automationConfigurations: WithRef<IAutomationConfiguration>[];
  brand: WithRef<IBrand>;
  calendarEvents: WithRef<ICalendarEvent>[];
  cancellationReasons: WithRef<ISchedulingEventReason>[];
  conditionConfigurations: WithRef<IConditionConfiguration>[];
  contacts: WithRef<IContact>[];
  eventTags: WithRef<ITag>[];
  feeSchedules: WithRef<IFeeSchedule>[];
  labs: WithRef<ILab>[];
  manualTransactionTypes: WithRef<IManualTransactionType>[];
  multiTreatmentConfigurations: WithRef<IMultiTreatmentConfiguration>[];
  patientMetadataDisplays: WithRef<IPatientMetadataDisplay>[];
  patientNoteTags: WithRef<ITag>[];
  patientPhoneNumbers: WithRef<IPatientPhoneNumber>[];
  patientTags: WithRef<ITag>[];
  patients: IExportedDemoPatient[];
  practices: IExportedDemoPractice[];
  prescriptionItems: WithRef<IPrescriptionItem>[];
  products: WithRef<IProduct>[];
  referralSources: WithRef<IReferralSourceConfiguration>[];
  snippets: WithRef<ISnippet>[];
  staff: IExportedDemoStaffer[];
  sterilisationCycleTypes: WithRef<ISterilisationCycleType>[];
  sterilisationEquipment: WithRef<ISterilisationEquipment>[];
  sterilisationMachines: WithRef<ISterilisationMachine>[];
  sterilisationPacks: WithRef<ISterilisationPack>[];
  teams: WithRef<ITeam>[];
  templates: WithRef<ITemplateDefinition>[];
  treatmentCategories: WithRef<ITreatmentCategory>[];
  treatmentConfigurations: WithRef<ITreatmentConfiguration>[];
  treatmentTemplates: WithRef<ITreatmentTemplate>[];
}

export interface IExportedDemoTreatmentPlan {
  treatmentPlan: WithRef<ITreatmentPlan>;
  treatmentSteps: IExportedDemoTreatmentStep[];
}

export interface IExportedDemoTreatmentStep {
  automations: WithRef<IAutomation<AnyAutomation>>[];
  treatmentStep: WithRef<ITreatmentStep>;
}

export interface IExportedDemoPatient {
  appointments: IExportedDemoAppointment[];
  clinicalCharts: WithRef<IClinicalChart>[];
  clinicalNotes: WithRef<IClinicalNote>[];
  credits: WithRef<IAccountCredit>[];
  history: WithRef<IInteractionV2>[];
  invoices: WithRef<IInvoice>[];
  media: WithRef<IMedia>[];
  patient: WithRef<IPatient>;
  patientDocuments: WithRef<IPatientDocument>[];
  submittedForms: WithRef<IPatientForm>[];
  treatmentPlans: IExportedDemoTreatmentPlan[];
}

export interface IExportedDemoPractice {
  contacts: WithRef<IContact>[];
  feeSchedules: WithRef<IFeeSchedule>[];
  labJobs: WithRef<ILabJob>[];
  practice: WithRef<IPractice>;
  products: WithRef<IProduct>[];
  recurringTaskConfigurations: WithRef<IRecurringTaskConfiguration>[];
  scheduleSummaries: WithRef<IScheduleSummary>[];
  sterilisationRecords: WithRef<ISterilisationRecord>[];
  tags: WithRef<ITag>[];
  tasks: WithRef<ITask>[];
}

export interface IExportedDemoStaffer {
  conditionConfigurations: WithRef<IConditionConfiguration>[];
  feeSchedules: WithRef<IFeeSchedule>[];
  multiTreatmentConfigurations: WithRef<IMultiTreatmentConfiguration>[];
  notifications: WithRef<INotification>[];
  rosterSchedules: WithRef<ICalendarEventSchedule>[];
  snippets: WithRef<ISnippet>[];
  staffer: WithRef<IStaffer>;
  treatmentConfigurations: WithRef<ITreatmentConfiguration>[];
}

export interface IExportedDemoAppointment {
  appointment: WithRef<IAppointment>;
  appointmentInteractions: WithRef<IInteractionV2>[];
  checklists: WithRef<IChecklistItem>[];
  followUps: WithRef<IFollowUp>[];
}

export const REGION_DEFAULT_TIMEZONE: Record<Region, Timezone> = {
  [Region.Australia]: Timezone.AustraliaSydney,
  [Region.NewZealand]: Timezone.NZ,
};

export const REGION_DEFAULT_ADDRESS: Record<
  Region,
  Pick<IPractice, 'address' | 'coordinates'>
> = {
  [Region.Australia]: {
    address: '80 Longueville Rd, Lane Cove, NSW 2066',
    coordinates: {
      latitude: -33.8132601,
      longitude: 151.170703,
    },
  },
  [Region.NewZealand]: {
    address: '91 Aro Street, Aro Valley, Wellington 6021',
    coordinates: {
      latitude: -41.2954481,
      longitude: 174.7668642,
    },
  },
};
