<button mat-icon-button [matMenuTriggerFor]="filtersMenu">
  <mat-icon>filter_list</mat-icon>
</button>

<mat-menu
  #filtersMenu="matMenu"
  [overlapTrigger]="true"
  classList="chart-selector-menu"
>
  <ng-template matMenuContent>
    <h2>Filters</h2>

    <ng-container
      *ngFor="let filterGroup of filterGroups; trackBy: trackByType"
    >
      <mat-divider />

      <button
        *ngFor="let filterOption of filterGroup; trackBy: trackByType"
        mat-menu-item
        (click)="select(filterOption, $event)"
      >
        <mat-icon
          *ngIf="isSelected(filterOption); else notSelected"
          color="primary"
        >
          check_box
        </mat-icon>
        {{ filterOption }}
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<ng-template #notSelected>
  <mat-icon>check_box_outline_blank</mat-icon>
</ng-template>
