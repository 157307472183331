import { NgModule, type ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgClinicalChartingModule,
  ServiceGroupsModule,
} from '@principle-theorem/ng-clinical-charting';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleAccountingModule } from '@principle-theorem/ng-principle-accounting';
import { InvoiceTransactionsComponent } from './invoice-transactions/invoice-transactions.component';
import { PaymentPlanComponent } from './methods/payment-plan/payment-plan.component';
import {
  NG_PAYMENTS_CONFIG,
  type INgPaymentsConfig,
} from './ng-payments-config';
import { PayInvoiceComponent } from './pay-invoice/pay-invoice.component';
import { TransactionsModule } from './transactions/transactions.module';

@NgModule({
  imports: [
    ServiceGroupsModule,
    NgPrincipleAccountingModule,
    TransactionsModule,
    NgClinicalChartingModule,
    NgMaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    PaymentPlanComponent,
    PayInvoiceComponent,
    InvoiceTransactionsComponent,
  ],
  exports: [
    TransactionsModule,
    PayInvoiceComponent,
    InvoiceTransactionsComponent,
  ],
})
export class NgPaymentsModule {
  static forRoot(
    config: INgPaymentsConfig
  ): ModuleWithProviders<NgPaymentsModule> {
    return {
      providers: [
        {
          provide: NG_PAYMENTS_CONFIG,
          useValue: config,
        },
      ],
      ngModule: NgPaymentsModule,
    };
  }
}
