import {
  TaskAction,
  TaskStatus,
} from '@principle-theorem/principle-core/interfaces';
import { ITaskEventFact } from '@principle-theorem/reporting/interfaces';
import { BigQueryTable } from '../../big-query-tables';
import {
  BrandQueryScopeConfig,
  DateRangeQueryScopeConfig,
  PracticeQueryScopeConfig,
} from '../../query-scope-config/query-scope-configs';
import { GroupBy } from '../../querying';
import { BaseFactMeasures, latestEventId } from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import { BrandDimensionMeasures } from '../dimensions/brand-dimension';
import { ModelEventDimensionMeasures } from '../dimensions/model-event-dimension';
import { PracticeDimensionMeasures } from '../dimensions/practice-dimension';
import {
  ComparableProperties,
  MeasureTransformMap,
} from '../measure-properties';
import { QueryFactory } from '../query-factory';

// export class TaskEventFact extends BaseDimension<Fact>
//   implements
//   NestedDimensionTransformMap<NestedDimensionProperties<Fact>>,
//   DimensionTransformMap<DateProperties<Fact>>,
//   DimensionTransformMap<ArrayProperties<Fact>>,
//   DimensionTransformMap<ComparableProperties<Fact>> {

//   get task(): ITask {
//     return {} as ITask;
//   }

//   get recurringConfiguration(): IRecurringTask {
//     return {} as IRecurringTask;
//   }

//   get owner(): IStaffer {
//     return {} as IStaffer;
//   }

//   get assignedUser(): IStaffer {
//     return {} as IStaffer;
//   }

//   get assignedTeam(): ITeam {
//     return {} as ITeam;
//   }

// }

export class TaskEventFactMeasures
  extends BaseFactMeasures
  implements MeasureTransformMap<ComparableProperties<ITaskEventFact>>
{
  id = 'taskEvent';
  readonly table = BigQueryTable.TaskEvent;
  readonly name = 'Tasks';
  scopes = [
    new BrandQueryScopeConfig(),
    new PracticeQueryScopeConfig(),
    new DateRangeQueryScopeConfig(),
  ];

  get practice(): PracticeDimensionMeasures {
    return new PracticeDimensionMeasures(BigQueryTable.Practices, 'practice', {
      sourceJoinKey: MeasurePath.docRef('practice.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get brand(): BrandDimensionMeasures {
    return new BrandDimensionMeasures(BigQueryTable.Brands, 'brand', {
      sourceJoinKey: MeasurePath.docRef('brand.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get event(): ModelEventDimensionMeasures<TaskAction, TaskStatus> {
    return new ModelEventDimensionMeasures(this.table, 'event', 'Task');
  }

  get latestEvent(): TaskEventFactMeasures {
    const measures = new TaskEventFactMeasures();
    measures.id = latestEventId(measures);
    measures._query = QueryFactory.fromTable(measures.table)
      .override(measures._query)
      .latestEvent(measures.table, GroupBy.Task)
      .filterSoftDeleted(measures.measureRef('task.deleted'))
      .get();
    return measures;
  }
}
