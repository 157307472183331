import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { type MatSelectionListChange } from '@angular/material/list';
import { ReplaySubject } from 'rxjs';
import { TrackByFunctions } from '../track-by';
import { type IFilterOption } from './filter-option';

@Component({
  selector: 'pt-multi-filter-group',
  templateUrl: './multi-filter-group.component.html',
  styleUrls: ['./multi-filter-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiFilterGroupComponent {
  trackByFilter = TrackByFunctions.uniqueId<IFilterOption>();
  statusFilters$ = new ReplaySubject<IFilterOption[]>(1);
  activeStatuses$ = new ReplaySubject<string[]>(1);
  @Input() tooltip: string;
  @Input() compact: boolean = false;
  @Input() displayBadge: boolean = false;

  @Output()
  statusFilterChange = new EventEmitter<string[]>();

  @Input()
  set statusFilters(filters: IFilterOption[]) {
    if (filters) {
      this.statusFilters$.next(filters);
    }
  }

  @Input()
  set activeStatuses(statuses: string[]) {
    if (statuses) {
      this.activeStatuses$.next(statuses);
    }
  }

  updateSelected(event: MatSelectionListChange): void {
    this.statusFilterChange.next(
      event.source.selectedOptions.selected.map(
        (option) => option.value as string
      )
    );
  }
}
