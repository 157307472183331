import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  inject,
} from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  VersionedSchema,
  initVersionedSchema,
} from '@principle-theorem/editor';
import { PrincipleEditorModule } from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  CurrentPracticeScope,
  NgPrincipleSharedModule,
} from '@principle-theorem/ng-principle-shared';
import {
  ButtonsContainerComponent,
  TypedFormControl,
  TypedFormGroup,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import { Practice, stafferToNamedDoc } from '@principle-theorem/principle-core';
import {
  ISterilisationPackContent,
  type IPatient,
  type IStaffer,
  type ISterilisationCycle,
  type ISterilisationPack,
  type ISterilisationRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  isSameRef,
  multiMap,
  multiSort,
  sortByCreatedAt,
  toNamedDocument,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import {
  SterilisationStore,
  resolvePackContent,
} from '../../stores/sterilisation.store';
import { SterilisationPackSelectorComponent } from '../sterilisation-pack-selector/sterilisation-pack-selector.component';

export interface IEditSterilisationRecordData {
  record: WithRef<ISterilisationRecord>;
}

export type IEditSterilisationRecordReturnData = Omit<
  ISterilisationRecord,
  'practice' | 'appointment' | 'deleted' | 'status'
>;

@Component({
  selector: 'pr-edit-sterilisation-record-dialog',
  imports: [
    NgMaterialModule,
    ReactiveFormsModule,
    NgPrincipleSharedModule,
    ButtonsContainerComponent,
    PrincipleEditorModule,
    SterilisationPackSelectorComponent,
  ],
  templateUrl: './edit-sterilisation-record-dialog.component.html',
  styleUrls: ['./edit-sterilisation-record-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SterilisationStore],
})
export class EditSterilisationRecordDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  practiceScope = inject(CurrentPracticeScope);
  store = inject(SterilisationStore);

  form = new TypedFormGroup<IEditSterilisationRecordReturnData>({
    data: new TypedFormControl<string>('', Validators.required),
    patient: new TypedFormControl<INamedDocument<IPatient>>(),
    scannedBy: new TypedFormControl<INamedDocument<IStaffer>>(),
    cycle: new TypedFormControl<INamedDocument<ISterilisationCycle>>(),
    pack: new TypedFormControl<INamedDocument<ISterilisationPack>>(),
    content: new TypedFormControl<ISterilisationPackContent[]>([]),
    notes: new TypedFormControl<VersionedSchema>(initVersionedSchema()),
  });

  availableCycles$: Observable<INamedDocument<ISterilisationCycle>[]>;
  packContent$: Observable<ISterilisationPackContent[]>;

  constructor(
    private _dialogRef: MatDialogRef<
      EditSterilisationRecordDialogComponent,
      IEditSterilisationRecordReturnData
    >,
    @Inject(MAT_DIALOG_DATA) public data: IEditSterilisationRecordData
  ) {
    if (!data) {
      return;
    }
    this.form.patchValue(data.record);

    this.packContent$ = formControlChanges$(this.form.controls.pack).pipe(
      switchMap((pack) => resolvePackContent(data.record, pack))
    );

    this.packContent$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((content) => this.form.controls.content.setValue(content));

    this.availableCycles$ = Practice.sterilisationCycles$(
      data.record.practice
    ).pipe(multiSort(sortByCreatedAt), multiMap(toNamedDocument));

    this.store.loadPacks(
      Firestore.doc$(Practice.brandDoc(data.record.practice))
    );
    this.store.loadEquipment(
      Firestore.doc$(Practice.brandDoc(data.record.practice))
    );
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }
    const formData = this.form.getRawValue();
    this._dialogRef.close({
      ...formData,
      patient: formData.patient ? toNamedDocument(formData.patient) : undefined,
      scannedBy: formData.scannedBy
        ? stafferToNamedDoc(formData.scannedBy)
        : undefined,
    });
  }

  isSelectedNamedDocument(
    namedDocument: INamedDocument,
    selectedNamedDocument: INamedDocument
  ): boolean {
    return isSameRef(namedDocument, selectedNamedDocument);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
