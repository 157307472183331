import { type ISource } from '@principle-theorem/principle-core/interfaces';
import { getConfigurationItem } from '../source/source';

export function getBucketStoragePath(source: ISource): string {
  return getConfigurationItem(source, 'source bucket storage path', '');
}

export interface ICopiedFileInfo {
  path: string;
  metadata: unknown;
}

export interface IStorageSyncResponse {
  storageFiles: ICopiedFileInfo[];
}
