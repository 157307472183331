import { Validators } from '@angular/forms';
import {
  initVersionedSchema,
  type RawInlineNodes,
  type VersionedSchema,
} from '@principle-theorem/editor';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  TaskPriority,
  type IRecurringTaskConfiguration,
  type IStaffer,
  type ITeam,
  type RecurringTaskDueTiming,
  type TimingUnit,
} from '@principle-theorem/principle-core/interfaces';
import {
  type Frequency,
  type IRecurrencePattern,
  type Time24hrType,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { getSummary } from '../../task-form-summary';

export interface IRecurringTaskFormData
  extends Omit<
    IRecurringTaskConfiguration,
    | 'createdTasks'
    | 'completedAt'
    | 'createdAt'
    | 'deleted'
    | 'updatedAt'
    | 'assignedUser'
    | 'assignedTeam'
    | 'owner'
    | 'dueTime'
    | 'recurrencePattern'
  > {
  recurringFrequency?: Frequency;
  assignee?: WithRef<IStaffer> | WithRef<ITeam>;
  recurrencePattern?: IRecurrencePattern;
}

// TODO: Move the operations specific to converting from/to recurring configurations to this class
export class RecurringTaskConfigurationForm extends TypedFormGroup<IRecurringTaskFormData> {
  summary$: Observable<string> =
    this.controls.recurrenceTiming.valueChanges.pipe(
      startWith(''),
      map(() => getSummary(this.getRawValue()))
    );

  constructor() {
    super({
      title: new TypedFormControl<RawInlineNodes>([], Validators.required),
      description: new TypedFormControl<VersionedSchema>(initVersionedSchema()),
      priority: new TypedFormControl<TaskPriority>(
        TaskPriority.Low,
        Validators.required
      ),
      recurringFrequency: new TypedFormControl<Frequency>(),
      assignee: new TypedFormControl<WithRef<IStaffer> | WithRef<ITeam>>(
        undefined,
        Validators.required
      ),
      recurrencePattern: new TypedFormControl<IRecurrencePattern>(),
      recurrenceTiming: new TypedFormGroup<RecurringTaskDueTiming>({
        time: new TypedFormControl<Time24hrType>(),
        unit: new TypedFormControl<TimingUnit>(),
        amount: new TypedFormControl<number>(),
      }),
    });
  }
}
