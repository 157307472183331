import {
  type INamedDocument,
  isEnumValue,
  isINamedDocument,
  isObject,
} from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { ChartableSurface } from './chartable-surface';
import { type IChartedSurface } from './charted-surface';
import { type IClinicalNote } from './clinical-note';
import { Arch, Quadrant } from './quadrant';
import { type IScopeRef } from './scoped-surface';
import { type IToothRef } from './tooth';
import { IStaffer } from '../../staffer/staffer';

export enum ChartedItemType {
  Generic = 'generic',
  ChartedCondition = 'chartedCondition',
  ChartedTreatment = 'chartedTreatment',
  ChartedTreatmentGroup = 'chartedTreatmentGroup',
  TreatmentStep = 'treatmentStep',
  Treatment = 'treatment',
  ChartedMultiStepTreatment = 'chartedMultiStepTreatment',
}

export interface IChartedItem<T extends object = object> {
  uuid: string;
  type: ChartedItemType;
  config: INamedDocument<T>;
  chartedSurfaces: IChartedSurface[];
  resolvedBy?: INamedDocument<IStaffer>;
  resolvedAt?: Timestamp;
  notes: IClinicalNote[];
  scopeRef: IScopeRef;
}

export interface IChartedRef {
  wholeMouth: boolean;
  arch: Arch;
  quadrant: Quadrant;
  multipleTeeth: IToothRef[];
  tooth: IChartedTooth;
  unscoped: boolean;
}

export interface IChartedTooth extends IToothRef {
  surface?: ChartableSurface;
}

export function isChartedTooth(
  data: Partial<IChartedRef>
): data is IChartedTooth {
  return !!data.tooth;
}

export function isChartedToothWithSurface(
  data: Partial<IChartedRef>
): data is Required<Pick<IChartedRef, 'tooth'>> {
  return !!data.tooth && isEnumValue(ChartableSurface, data.tooth.surface);
}

export function hasArch(
  data: Partial<IChartedRef>
): data is Pick<IChartedRef, 'arch'> {
  return isObject(data) && 'arch' in data && isEnumValue(Arch, data.arch);
}

export function hasQuadrant(
  data: Partial<IChartedRef>
): data is Pick<IChartedRef, 'quadrant'> {
  return (
    isObject(data) && 'quadrant' in data && isEnumValue(Quadrant, data.quadrant)
  );
}

export function hasTooth(
  data: Partial<IChartedRef>
): data is Pick<IChartedRef, 'tooth'> {
  return isObject(data) && isChartedTooth(data);
}

export function isChartedItem(item: unknown): item is IChartedItem {
  return (
    isObject(item) &&
    'config' in item &&
    isINamedDocument(item.config) &&
    'chartedSurfaces' in item &&
    Array.isArray(item.chartedSurfaces) &&
    'notes' in item &&
    Array.isArray(item.notes)
  );
}
