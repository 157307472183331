import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  inject,
} from '@angular/core';
import { PatientFormStore } from '../patient-form.store';
import { WithRef } from '@principle-theorem/shared';
import { IPatientForm } from '@principle-theorem/principle-core/interfaces';

@Component({
    selector: 'pr-patient-form-toolbar',
    templateUrl: './patient-form-toolbar.component.html',
    styleUrl: './patient-form-toolbar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientFormToolbarComponent {
  readonly store = inject(PatientFormStore);
  @Output() formSelected = new EventEmitter<WithRef<IPatientForm>>();
}
