export enum ValueComparison {
  LessThan = '<',
  LessThanOrEqualTo = '<=',
  EqualTo = '=',
  NotEqualTo = '!=',
  GreaterThanOrEqualTo = '>=',
  GreaterThan = '>',
}

export function compareValues(
  a: number,
  comparison: ValueComparison,
  b: number
): boolean {
  switch (comparison) {
    case ValueComparison.LessThan:
      return a < b;
    case ValueComparison.LessThanOrEqualTo:
      return a <= b;
    case ValueComparison.EqualTo:
      return a === b;
    case ValueComparison.NotEqualTo:
      return a !== b;
    case ValueComparison.GreaterThanOrEqualTo:
      return a >= b;
    case ValueComparison.GreaterThan:
      return a > b;
    default:
      return false;
  }
}
