<json-schema-form
  *ngrxLet="jsonSchemaForm$ as jsonSchemaForm"
  [ngClass]="{ readonly: isDisabled$ | ngrxPush }"
  [form]="jsonSchemaForm"
  [framework]="jsonSchemaForm.framework"
  [widgets]="customWidgets"
  (isValid)="isValid.emit($event)"
  (onChanges)="formChanges.emit($event)"
  (onSubmit)="submit($event)"
/>
