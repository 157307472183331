import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { CurrentPatientScope } from '@principle-theorem/ng-principle-shared';
import { AbstractResolver } from '@principle-theorem/ng-shared';
import {
  PatientRelationshipType,
  type IPatient,
  type IPatientDocument,
} from '@principle-theorem/principle-core/interfaces';
import { Patient } from '@principle-theorem/principle-core';
import { getDoc$, type WithRef } from '@principle-theorem/shared';
import { of, type Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { first } from 'lodash';

@Injectable()
export class PatientDocumentResolverService extends AbstractResolver<
  WithRef<IPatientDocument>
> {
  constructor(router: Router) {
    super(router);
  }

  getItemById(
    uid: string,
    route: ActivatedRouteSnapshot
  ): Observable<WithRef<IPatientDocument> | undefined> {
    const patientScope: CurrentPatientScope = new CurrentPatientScope(
      this._router
    );
    const patient: WithRef<IPatient> | undefined =
      patientScope.findModel(route);

    if (!patient) {
      throw new Error('No Patient found');
    }

    return Patient.withPatientRelationships$(
      patient,
      [PatientRelationshipType.DuplicatePatient],
      (patientReffable) =>
        getDoc$(Patient.patientDocumentCol(patientReffable), uid).pipe(
          map((document) => [document]),
          catchError(() => of([]))
        )
    ).pipe(map((documents) => first(documents)));
  }
}
