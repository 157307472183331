import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Brand } from '@principle-theorem/principle-core';
import {
  IBrand,
  ISterilisationCycleType,
  ISterilisationEquipment,
  ISterilisationMachine,
  ISterilisationPack,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  INamedDocument,
  IReffable,
  WithRef,
  addDoc,
  deleteDoc,
  isRefChanged$,
  isSameRef,
  multiSortBy$,
  nameSorter,
  snapshot,
} from '@principle-theorem/shared';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  ISterilisationCycleTypeDialogData,
  SterilisationCycleTypeDialogComponent,
} from '../components/sterilisation-cycle-type-dialog/sterilisation-cycle-type-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  IConfirmationDialogInput,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import {
  ISterilisationMachineDialogData,
  SterilisationMachineDialogComponent,
} from '../components/sterilisation-machine-dialog/sterilisation-machine-dialog.component';
import {
  ISterilisationPackDialogData,
  SterilisationPackDialogComponent,
} from '../components/sterilisation-pack-dialog/sterilisation-pack-dialog.component';
import {
  ISterilisationEquipmentDialogData,
  SterilisationEquipmentDialogComponent,
} from '../components/sterilisation-equipment-dialog/sterilisation-equipment-dialog.component';

interface ISterilisationSettingsState {
  machines: WithRef<ISterilisationMachine>[];
  cycleTypes: WithRef<ISterilisationCycleType>[];
  equipment: INamedDocument<ISterilisationEquipment>[];
  packs: WithRef<ISterilisationPack>[];
}

const initialState: ISterilisationSettingsState = {
  machines: [],
  cycleTypes: [],
  packs: [],
  equipment: [],
};

@Injectable()
export class SterilisationSettingsStore extends ComponentStore<ISterilisationSettingsState> {
  private _dialog = inject(MatDialog);
  private _snackBar = inject(MatSnackBar);

  readonly cycleTypes$ = this.select((state) => state.cycleTypes);
  readonly equipment$ = this.select((state) => state.equipment);
  readonly machines$ = this.select((state) => state.machines);
  readonly packs$ = this.select((state) => state.packs);

  readonly load = this.effect((brand$: Observable<WithRef<IBrand>>) =>
    brand$.pipe(
      isRefChanged$(),
      switchMap((brand) => {
        const packs$ = Brand.sterilisationPacks$(brand).pipe(
          multiSortBy$(nameSorter())
        );
        const machines$ = Brand.sterilisationMachines$(brand).pipe(
          multiSortBy$(nameSorter())
        );
        const equipment$ = Brand.sterilisationEquipment$(brand).pipe(
          multiSortBy$(nameSorter())
        );
        const cycleTypes$ = Brand.sterilisationCycleTypes$(brand).pipe(
          multiSortBy$(nameSorter())
        );

        return combineLatest([packs$, machines$, equipment$, cycleTypes$]);
      }),
      map(([packs, machines, equipment, cycleTypes]) => ({
        packs,
        machines,
        equipment,
        cycleTypes,
      })),
      // eslint-disable-next-line no-console
      tapResponse((data) => this.patchState(data), console.error)
    )
  );

  constructor() {
    super(initialState);
  }

  async addEditCycleType(
    cycleType?: WithRef<ISterilisationCycleType>,
    brand?: WithRef<IBrand>
  ): Promise<void> {
    const result = await this._dialog
      .open<
        SterilisationCycleTypeDialogComponent,
        ISterilisationCycleTypeDialogData,
        ISterilisationCycleType | undefined
      >(
        SterilisationCycleTypeDialogComponent,
        DialogPresets.medium({ data: { cycleType } })
      )
      .afterClosed()
      .toPromise();

    if (!result) {
      return;
    }
    if (cycleType) {
      await Firestore.patchDoc(cycleType.ref, result);
      this._snackBar.open('Sterilisation Cycle Type updated');
      return;
    }
    if (!brand) {
      return;
    }
    await addDoc(Brand.sterilisationCycleTypesCol(brand), result);
    this._snackBar.open('Sterilisation Cycle Type created');
  }

  async deleteCycleType(
    cycleType: WithRef<ISterilisationCycleType>
  ): Promise<void> {
    const confirmed = await this._confirmDelete(
      'Delete Sterilisation Cycle Type',
      'Are you sure you want to delete this sterilisation cycle type?'
    );
    if (!confirmed) {
      return;
    }

    await deleteDoc(cycleType.ref);
    this._snackBar.open('Sterilisation Cycle Type deleted');
  }

  async addEditMachine(
    data: ISterilisationMachineDialogData,
    brand: IReffable<IBrand>
  ): Promise<void> {
    const result = await this._dialog
      .open<
        SterilisationMachineDialogComponent,
        ISterilisationMachineDialogData,
        ISterilisationMachine
      >(SterilisationMachineDialogComponent, DialogPresets.medium({ data }))
      .afterClosed()
      .toPromise();

    if (!result) {
      return;
    }

    if (data.machine) {
      await Firestore.patchDoc(data.machine.ref, result);
      this._snackBar.open('Sterilisation machine updated');
      return;
    }

    await addDoc(Brand.sterilisationMachineCol(brand), result);
    this._snackBar.open('Sterilisation machine added');
  }

  async deleteMachine(machine: WithRef<ISterilisationMachine>): Promise<void> {
    const confirmed = await this._confirmDelete(
      'Delete Sterilisation Machine',
      'Are you sure you want to delete this machine?'
    );
    if (!confirmed) {
      return;
    }

    await deleteDoc(machine.ref);
    this._snackBar.open('Sterilisation machine deleted');
    this.patchState((state) => ({
      machines: state.machines.filter(
        (storeMachine) => !isSameRef(machine, storeMachine)
      ),
    }));
  }

  async addEditPack(
    pack?: WithRef<ISterilisationPack>,
    brand?: WithRef<IBrand>
  ): Promise<void> {
    const items = await snapshot(this.equipment$);
    const result = await this._dialog
      .open<
        SterilisationPackDialogComponent,
        ISterilisationPackDialogData,
        ISterilisationPack | undefined
      >(
        SterilisationPackDialogComponent,
        DialogPresets.medium({
          data: {
            pack,
            items,
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!result) {
      return;
    }
    if (pack) {
      await Firestore.patchDoc(pack.ref, result);
      this._snackBar.open('Sterilisation Pack updated');
      return;
    }
    if (!brand) {
      return;
    }
    await addDoc(Brand.sterilisationPacksCol(brand), result);
    this._snackBar.open('Sterilisation Pack created');
  }

  async deletePack(pack: WithRef<ISterilisationPack>): Promise<void> {
    const confirmed = await this._confirmDelete(
      'Delete Sterilisation Pack',
      'Are you sure you want to delete this sterilisation pack?'
    );
    if (!confirmed) {
      return;
    }

    await deleteDoc(pack.ref);
    this._snackBar.open('Sterilisation Pack deleted');
  }

  async addEditEquipment(
    equipment?: WithRef<ISterilisationEquipment>,
    brand?: WithRef<IBrand>
  ): Promise<void> {
    const result = await this._dialog
      .open<
        SterilisationEquipmentDialogComponent,
        ISterilisationEquipmentDialogData,
        ISterilisationEquipment | undefined
      >(
        SterilisationEquipmentDialogComponent,
        DialogPresets.medium({ data: { equipment } })
      )
      .afterClosed()
      .toPromise();

    if (!result) {
      return;
    }
    if (equipment) {
      await Firestore.patchDoc(equipment.ref, result);
      this._snackBar.open('Sterilisation Equipment updated');
      return;
    }
    if (!brand) {
      return;
    }
    await addDoc(Brand.sterilisationEquipmentCol(brand), result);
    this._snackBar.open('Sterilisation Equipment created');
  }

  async deleteEquipment(
    equipment: WithRef<ISterilisationEquipment>
  ): Promise<void> {
    const confirmed = await this._confirmDelete(
      'Delete Sterilisation Equipment?',
      'Are you sure you want to delete this sterilisation equipment?'
    );
    if (!confirmed) {
      return;
    }

    await deleteDoc(equipment.ref);
    this._snackBar.open('Sterilisation Equipment deleted');
  }

  private async _confirmDelete(
    title: string,
    prompt: string
  ): Promise<boolean | undefined> {
    const data = confirmationDialogData({
      title,
      prompt,
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    return this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
  }
}
