import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgAppointmentModule } from '@principle-theorem/ng-appointment';
import { NgEventableModule } from '@principle-theorem/ng-eventable';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  MultiPractitionerSelectButtonComponent,
  NgPrincipleSharedModule,
  WaitListStore,
} from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { CandidateDetailComponent } from './gaps-v1/candidate-detail/candidate-detail.component';
import { CandidateGapTimeComponent } from './candidate-gap-time/candidate-gap-time.component';
import { GapCandidateItemComponent } from './gaps-v1/gap-candidate-list/gap-candidate-item/gap-candidate-item.component';
import { GapCandidateListComponent } from './gaps-v1/gap-candidate-list/gap-candidate-list.component';
import { GapCardComponent } from './gaps-v1/gap-card/gap-card.component';
import { GapAppointmentTooltipComponent } from './gap-appointment-tooltip/gap-appointment-tooltip.component';
import { GapCandidateShortlistComponent } from './gap-candidate-shortlist/gap-candidate-shortlist.component';
import { GapDateRangeSelectorComponent } from './gap-date-range-selector/gap-date-range-selector.component';
import { GapDayHeaderComponent } from './gap-day-header/gap-day-header.component';
import { GapHeaderComponent } from './gaps-v1/gap-header/gap-header.component';
import { GapItemListComponent } from './gap-item-list/gap-item-list.component';
import { GapItemComponent } from './gap-item/gap-item.component';
import { GapOfferComponent } from './gap-offer/gap-offer.component';
import { GapSearchFiltersComponent } from './gap-search-filters/gap-search-filters.component';
import { GapSearchComponent } from './gap-search/gap-search.component';
import { GapShortlistItemAppointmentInfoComponent } from './gap-shortlist-item/gap-shortlist-item-appointment-info/gap-shortlist-item-appointment-info.component';
import { GapShortlistItemContainerComponent } from './gap-shortlist-item/gap-shortlist-item-container/gap-shortlist-item-container.component';
import { GapShortlistItemHeaderComponent } from './gap-shortlist-item/gap-shortlist-item-header/gap-shortlist-item-header.component';
import { GapShortlistItemComponent } from './gap-shortlist-item/gap-shortlist-item.component';
import { GapSidebarComponent } from './gap-sidebar/gap-sidebar.component';
import { GapWaitlistItemComponent } from './gap-waitlist-item/gap-waitlist-item.component';
import { GapWarningsComponent } from './gaps-v1/gap-warnings/gap-warnings.component';
import { MoveAppointmentComponent } from './gaps-v1/move-appointment/move-appointment.component';
import { PendingGapsListComponent } from './pending-gaps-list/pending-gaps-list.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { WaitListComponent } from './gaps-v1/wait-list/wait-list.component';
import { WaitListWarningsComponent } from './gaps-v1/wait-list/wait-list-warnings/wait-list-warnings.component';
import { GapListComponent } from './gaps-v1/gap-list/gap-list.component';
import { WaitListSortSelectorComponent } from './wait-list-sort-selector/wait-list-sort-selector.component';

@NgModule({
  imports: [
    NgSharedModule,
    NgPrincipleSharedModule,
    NgMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    NgAppointmentModule,
    NgInteractionsModule,
    NgEventableModule,
    NgxSkeletonLoaderModule,
    MultiPractitionerSelectButtonComponent,
    WaitListSortSelectorComponent,
    GapShortlistItemContainerComponent,
    GapAppointmentTooltipComponent,
    GapShortlistItemAppointmentInfoComponent,
  ],
  declarations: [
    GapSidebarComponent,
    GapDateRangeSelectorComponent,
    GapDayHeaderComponent,
    GapItemComponent,
    GapCandidateShortlistComponent,
    GapItemListComponent,
    GapWaitlistItemComponent,
    GapShortlistItemComponent,
    GapShortlistItemHeaderComponent,
    GapSearchComponent,
    GapSearchFiltersComponent,
    PendingGapsListComponent,
    CandidateGapTimeComponent,
    GapOfferComponent,
    // v1
    MoveAppointmentComponent,
    CandidateDetailComponent,
    WaitListComponent,
    WaitListWarningsComponent,
    GapListComponent,
    GapHeaderComponent,
    GapCardComponent,
    GapWarningsComponent,
    GapCandidateListComponent,
    GapCandidateItemComponent,
  ],
  exports: [
    GapSidebarComponent,
    GapSearchComponent,
    GapShortlistItemComponent,
    // v1
    GapListComponent,
    GapCardComponent,
    GapHeaderComponent,
  ],
  providers: [WaitListStore],
})
export class ComponentsModule {}
