import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Patient } from '@principle-theorem/principle-core';
import {
  FormStatus,
  PatientRelationshipType,
  type IPatient,
  type ISubmittedFormHistory,
} from '@principle-theorem/principle-core/interfaces';
import {
  isPathChanged$,
  multiFilter,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

export interface IPatientSubmittedFormsListState {
  submittedForms: WithRef<ISubmittedFormHistory>[];
  loading: boolean;
}

const initialState: IPatientSubmittedFormsListState = {
  submittedForms: [],
  loading: false,
};

@Injectable()
export class PatientSubmittedFormsListStore extends ComponentStore<IPatientSubmittedFormsListState> {
  readonly submittedForms$ = this.select((store) => store.submittedForms);
  readonly loading$ = this.select((store) => store.loading);

  readonly loadSubmittedForms = this.effect(
    (patient$: Observable<WithRef<IPatient>>) => {
      return patient$.pipe(
        isPathChanged$('ref.id'),
        tap(() => this.setState({ submittedForms: [], loading: true })),
        switchMap((patient) =>
          Patient.withPatientRelationships$(
            patient,
            [PatientRelationshipType.DuplicatePatient],
            Patient.forms$
          )
        ),
        multiFilter(({ status }) => status === FormStatus.Submitted),
        tap((forms) =>
          this.patchState({ submittedForms: forms, loading: false })
        )
      );
    }
  );

  constructor() {
    super(initialState);
  }
}
