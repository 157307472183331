import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ChartedConfigurationFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { TreatmentConfiguration } from '@principle-theorem/principle-core';
import {
  type ChartableSurface,
  type IChartedItemDisplay,
  type IGeneralTreatmentConfiguration,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject } from 'rxjs';
import {
  type IAddChartedItem,
  toAddChartedItemData,
} from '../add-charted-item';

@Component({
  selector: 'pr-add-charted-treatment',
  templateUrl: './add-charted-treatment.component.html',
  styleUrls: ['./add-charted-treatment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddChartedTreatmentComponent {
  treatmentConfig: ITreatmentConfiguration;
  isValid$ = new BehaviorSubject<boolean>(false);

  constructor(
    private _configurationStore: ChartedConfigurationFacade,
    private _dialogRef: MatDialogRef<AddChartedTreatmentComponent>,
    @Inject(MAT_DIALOG_DATA) data: IAddChartedItem
  ) {
    this.treatmentConfig = TreatmentConfiguration.init(
      toAddChartedItemData(data)
    );
  }

  updateGeneralTreatmentConfig(config: IGeneralTreatmentConfiguration): void {
    this.isValid$.next(true);
    this.treatmentConfig = { ...this.treatmentConfig, ...config };
  }

  updateDisplayOptions(display: IChartedItemDisplay): void {
    this.treatmentConfig.display = display;
  }

  updateSelectedSurfaces(surfaces: ChartableSurface[]): void {
    this.treatmentConfig.availableSurfaces = surfaces;
  }

  add(): void {
    if (!this.isValid$.value) {
      return;
    }
    this._configurationStore.addAndChartTreatmentConfiguration(
      ChartId.InAppointment,
      this.treatmentConfig
    );
    this._dialogRef.close();
  }
}
