<pt-breadcrumbs current="Tools" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="wrapper">
  <div fxLayout="column" fxLayoutGap="16px">
    <pr-tool-card colour="warn" [loading]="revokeAuthSessionsLoading$ | async">
      <h3 class="mat-headline-6">Log All Users Out</h3>
      <p class="mat-body-2">
        Force all users to re-authenticate by logging in again. Useful for
        resetting user sessions after an update which affects auth code.
      </p>
      <button
        mat-stroked-button
        [disabled]="revokeAuthSessionsLoading$ | async"
        (click)="revokeAuthSessions()"
      >
        Revoke Auth Sessions
      </button>
    </pr-tool-card>

    <pr-tool-card [loading]="seedingKeys$ | async">
      <h3 class="mat-headline-6">Typesense</h3>
      <p class="mat-body-2">Seed API Typesense Resources</p>
      <div class="mb-4">
        <button
          mat-flat-button
          color="primary"
          [disabled]="seedingKeys$ | async"
          (click)="seedTypesenseKeys()"
        >
          Generate Keys
        </button>
      </div>

      <div>
        <mat-form-field *ngIf="organisations$ | async as organisations">
          <mat-label>Organisations</mat-label>
          <mat-select [formControl]="organisationControl">
            <mat-option
              *ngFor="
                let organisation of organisations;
                trackBy: trackByOrganisation
              "
              [value]="organisation"
              >{{ organisation.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div class="flex gap-2">
        <pt-loader-button
          color="primary"
          type="flat"
          [loading]="seedingTasks$ | async"
          [disabled]="!organisationControl.value"
          (clicked)="seedTypesenseTasks()"
        >
          Sync Tasks
        </pt-loader-button>
        <pt-loader-button
          color="primary"
          type="flat"
          [loading]="seedingPatients$ | async"
          [disabled]="!organisationControl.value"
          (clicked)="seedTypesensePatients()"
        >
          Sync Patients
        </pt-loader-button>
      </div>
    </pr-tool-card>

    <pr-tool-card colour="warn" [loading]="runner.isMigrating$ | async">
      <pr-migration-runner #runner="prMigrationRunner" />
    </pr-tool-card>

    <pr-tool-card>
      <div class="flex flex-col">
        <h3 class="mat-headline-6">Convert Custom Reports To New Charting</h3>
        <pt-loader-button
          class="mb-4"
          color="primary"
          type="flat"
          [loading]="convertingCustomReports$ | async"
          (clicked)="convertCustomReports(true)"
        >
          Convert Custom Reports (Dry Run)
        </pt-loader-button>
        <pt-loader-button
          color="primary"
          type="flat"
          [loading]="convertingCustomReports$ | async"
          (clicked)="convertCustomReports(false)"
        >
          Convert Custom Reports
        </pt-loader-button>
      </div>
    </pr-tool-card>

    <pr-tool-card>
      <pr-big-query-job-selector />
    </pr-tool-card>
  </div>
</div>
