import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
} from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { MapPreviewComponent } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { type IConfirmAppointmentInfo } from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-patient-confirm-appointment-location',
  templateUrl: './patient-confirm-appointment-location.component.html',
  styleUrls: ['./patient-confirm-appointment-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NgSharedModule,
    NgMaterialModule,
    MapPreviewComponent,
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex flex-col gap-4' },
})
export class PatientConfirmAppointmentLocationComponent {
  data = signal<IConfirmAppointmentInfo | undefined>(undefined);

  @Input()
  set info(info: IConfirmAppointmentInfo) {
    if (info) {
      this.data.set(info);
    }
  }

  openMapsLink(address: string): string {
    const query = address.replace(/\s/g, '+');
    return `https://www.google.com/maps/search/?api=1&query=${query}`;
  }
}
