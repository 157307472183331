import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'pr-information-box',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './information-box.component.html',
  styleUrl: './information-box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationBoxComponent {}
