<input
  class="service-code-input w-full"
  placeholder="+ Add Item"
  aria-label="State"
  [matAutocomplete]="auto"
  [formControl]="searchCtrl"
  #autoComplete="matAutocompleteTrigger"
  (click)="autoComplete.openPanel()"
/>

<mat-autocomplete
  #auto="matAutocomplete"
  autoActiveFirstOption
  [displayWith]="displayFn"
  (optionSelected)="optionSelected($event)"
>
  <cdk-virtual-scroll-viewport
    #viewport
    class="autocomplete-viewport"
    itemSize="48"
    minBufferPx="250"
    maxBufferPx="300"
  >
    <ng-container *ngIf="search.results$ | async as groups">
      <ng-container *ngFor="let group of groups; trackBy: trackByGroup">
        <ng-container *ngIf="group.options.length">
          <mat-optgroup [label]="group.name">
            <mat-option
              *ngFor="let option of group.options; trackBy: trackByOption"
              [value]="option"
            >
              <span class="mat-caption"
                >{{ option.code }} - {{ option.title }}</span
              >
            </mat-option>
          </mat-optgroup>
        </ng-container>
      </ng-container>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</mat-autocomplete>
