import {
  type ExtensionRegisterReturnFn,
  pasteRule,
  BlockNodes,
} from '@principle-theorem/editor';
import { Editor } from '@tiptap/core';
import { type Plugin } from '@tiptap/pm/state';

export interface IRegexProvider {
  provider: string;
  testRegex: RegExp;
  urlRegex: RegExp;
  urlText: string;
}

export const PROVIDERS_REGEXERS: IRegexProvider[] = [
  {
    provider: 'youtube',
    testRegex:
      // eslint-disable-next-line no-useless-escape
      /^\S*((youtu.be)|(youtube.com))\/((v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))?\??v?=?([^#\&\?\s]*)\S*\b/g,
    urlRegex:
      // eslint-disable-next-line no-useless-escape
      /(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/)?([0-9a-zA-Z_\-]+)(.+)?/g,
    urlText: 'https://www.youtube.com/embed/$1?$2',
  },
  {
    provider: 'vimeo',
    testRegex:
      // eslint-disable-next-line no-useless-escape
      /^\S*(?:vimeo.com)\/(?:channels(\/\w+\/)?|groups\/*\/videos\/\d+\/|video\/|)(\d+)(?:$|\/|\?)\b/g,
    urlRegex:
      // eslint-disable-next-line no-useless-escape
      /(?:https?:\/\/)?(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?(\/[a-zA-Z0-9_\-]+)?/i,
    urlText: 'https://player.vimeo.com/video/$1',
  },
  {
    provider: 'loom',
    testRegex:
      // eslint-disable-next-line no-useless-escape
      /^\S*(loom.com)\/((v\/)|(\/u\/\w\/)|(embed\/)|(share\/))?\??v?=?([^#\&\?\s]*)\S*\b/g,
    urlRegex:
      // eslint-disable-next-line no-useless-escape
      /(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:loom\.com)\/(?:share\/|embed\/)?([0-9a-zA-Z_\-]+)(.+)?/g,
    urlText: 'https://www.loom.com/embed/$1',
  },
];

export function createVideoEmbedPasteRules(): ExtensionRegisterReturnFn<Plugin>[] {
  return PROVIDERS_REGEXERS.map((provider: IRegexProvider) => {
    return (editor: Editor) =>
      pasteRule(
        provider.testRegex,
        editor.schema.nodes[BlockNodes.VideoEmbed],
        (url: string) => {
          const src = url.replace(provider.urlRegex, provider.urlText);
          return { src };
        }
      );
  });
}
