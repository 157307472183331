import type * as moment from 'moment-timezone';
import { type Timestamp } from '../../firebase/firestore/adaptor';

export interface IDateRange {
  from: Date;
  to: Date;
}

export interface ITimePeriod {
  from: moment.Moment;
  to: moment.Moment;
}

export interface ITimestampRange {
  from: Timestamp;
  to: Timestamp;
}
