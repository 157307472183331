import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard } from '@principle-theorem/shared';
import { flow, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { FEE_SCHEDULE_RESOURCE_TYPE } from '../../../mappings/fee-schedules';

export const FEE_SCHEDULE_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Fee Schedule',
    description: '',
    idPrefix: FEE_SCHEDULE_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface IExactFeeSchedule {
  name: string;
}

function isExactFeeSchedule(item: unknown): item is IExactFeeSchedule {
  return TypeGuard.interface<IExactFeeSchedule>({ name: isString })(item);
}

export class FeeScheduleSourceEntity extends BaseSourceEntity<IExactFeeSchedule> {
  sourceEntity = FEE_SCHEDULE_SOURCE_ENTITY;
  entityResourceType = FEE_SCHEDULE_RESOURCE_TYPE;
  sourceQuery = `SELECT name FROM convfeeschedule WHERE name IS NOT NULL`;
  verifySourceFn = isExactFeeSchedule;
  override transformDataFn = flow([]);

  translate(_item: IExactFeeSchedule): Record<symbol, unknown> {
    return {};
  }

  getSourceRecordId(item: IExactFeeSchedule): string {
    return item.name;
  }

  getSourceLabel(item: IExactFeeSchedule): string {
    return item.name;
  }
}
