import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IUser } from '@principle-theorem/principle-core/interfaces';
import { type Timestamp } from '@principle-theorem/shared';
import {
  DATE_TIME_WITH_YEAR_FORMAT,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-user-list-item',
    templateUrl: './user-list-item.component.html',
    styleUrls: ['./user-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserListItemComponent {
  user$ = new ReplaySubject<WithRef<IUser>>(1);
  readonly dateFormat = DATE_TIME_WITH_YEAR_FORMAT;

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  getLastActiveAt(user: WithRef<IUser>): Timestamp | undefined {
    return user.lastActiveAt ?? user.firstSignedInAt;
  }
}
