import { ElementRef } from '@angular/core';
import {
  isLowerQuadrantRight,
  isRightQuadrant,
} from '@principle-theorem/principle-core';
import {
  IPerioTable,
  IPerioTableCell,
  MAX_PERIO_CELL_AMOUNT,
  PerioMeasurement,
  SelectedCell,
  SwitchDirection,
  SwitchPattern,
} from '@principle-theorem/principle-core/interfaces';
import { compact, groupBy, isNumber, isUndefined, zip } from 'lodash';
import { PerioTable } from './perio-table';
import { PerioTableByTooth } from './perio-table-by-tooth';

export class PerioTableNavigation {
  static calculateNextNavigationIndex(
    navigationArray: (number | undefined)[][],
    currentIndex: number,
    direction: SwitchDirection,
    pattern: SwitchPattern
  ): number {
    const selectedCell = PerioTableNavigation.getCellCoordinates(
      navigationArray,
      currentIndex
    );

    const currentRowLength = navigationArray[selectedCell.row].length;
    const totalRows = navigationArray.length;
    const colSpan = PerioTableNavigation.calculateColSpan(
      navigationArray[selectedCell.row]
    );

    const { row, col } = PerioTableNavigation.calculateNextPosition(
      selectedCell,
      currentRowLength,
      totalRows,
      colSpan,
      direction,
      pattern,
      navigationArray
    );

    return navigationArray[row][col] ?? currentIndex;
  }

  static calculateNextPosition(
    selectedCell: SelectedCell,
    currentRowLength: number,
    totalRowCount: number,
    colSpan: number,
    direction: SwitchDirection,
    pattern: SwitchPattern,
    navigationArray: (number | undefined)[][]
  ): SelectedCell {
    let { col, row } = selectedCell;

    if (direction === SwitchDirection.Default) {
      col += colSpan;
    } else {
      col -= colSpan;
    }

    if (col >= currentRowLength || col < 0) {
      const nextRowLength =
        navigationArray[row + 1]?.length ?? navigationArray[0].length;

      row++;
      col = PerioTableNavigation.adjustColumnForOverflow(
        col,
        currentRowLength,
        nextRowLength,
        pattern
      );
    }

    row = PerioTableNavigation.adjustRowIfOutOfBounds(row, totalRowCount);

    if (navigationArray[row][col] === undefined) {
      return PerioTableNavigation.calculateNextPosition(
        { row, col },
        currentRowLength,
        totalRowCount,
        colSpan,
        direction,
        pattern,
        navigationArray
      );
    }

    return { row, col };
  }

  static adjustColumnForOverflow(
    col: number,
    currentRowLength: number,
    nextRowLength: number,
    pattern: SwitchPattern
  ): number {
    if (col >= currentRowLength) {
      return pattern !== SwitchPattern.Reset ? nextRowLength - 1 : 0;
    }
    if (col < 0) {
      return pattern !== SwitchPattern.Reset ? 0 : nextRowLength - 1;
    }
    return col;
  }

  static adjustRowIfOutOfBounds(row: number, totalRowCount: number): number {
    if (row >= totalRowCount) {
      return 0;
    }
    if (row < 0) {
      return totalRowCount - 1;
    }
    return row;
  }

  static getModifiedTables(perioTables: IPerioTable[]): IPerioTable[] {
    return perioTables.map((table) => {
      const mobilityIndex = PerioTable.getMeasurementRowIndex(
        table,
        PerioMeasurement.Mobility
      );

      const newMobilityRow =
        mobilityIndex !== -1
          ? table.rows[mobilityIndex].flatMap((cell) =>
              Array.from({ length: MAX_PERIO_CELL_AMOUNT }, () => cell)
            )
          : [];

      const newRows = table.rows.map((row, index) => {
        if (index === mobilityIndex) {
          return newMobilityRow;
        }
        return row;
      });

      return {
        ...table,
        rows: newRows.filter(
          (_, index) =>
            index !==
            PerioTable.getMeasurementRowIndex(table, PerioMeasurement.CAL)
        ),
      };
    });
  }

  static removeEmptyCells(tables: IPerioTable[]): IPerioTable[] {
    return tables.map((table) => {
      const filteredRows = table.rows.flatMap((row) => {
        const nonEmptyCells = row.filter((cell) =>
          isNumber(cell.navigationIndex)
        );
        return nonEmptyCells.length ? [nonEmptyCells] : [];
      });

      return {
        ...table,
        rows: filteredRows,
      };
    });
  }

  static buildNavByTable(perioTables: IPerioTable[]): (number | undefined)[][] {
    const modifiedTables = PerioTableNavigation.getModifiedTables(perioTables);
    const tableRows = modifiedTables.flatMap((table) => table.rows);
    return tableRows.map((row) => row.map((cell) => cell.navigationIndex));
  }

  static buildNavByMeasurement(
    perioTables: IPerioTable[]
  ): (number | undefined)[][] {
    const modifiedTables = PerioTableNavigation.getModifiedTables(perioTables);
    const tableRows = modifiedTables.map((table) => table.rows);
    const zipRows = compact(zip(...tableRows).flat());
    return zipRows.map((row) => row.map((cell) => cell.navigationIndex));
  }

  static buildNavByRow(perioTables: IPerioTable[]): (number | undefined)[][] {
    const modifiedTables = PerioTableNavigation.getModifiedTables(perioTables);
    const tableRows = modifiedTables.map((table) =>
      table.rows.map((row) =>
        Object.values(
          groupBy(row, (cell) => cell.metadata.toothRef.quadrant)
        ).sort((a) =>
          isLowerQuadrantRight(a[0].metadata.toothRef.quadrant) ? -1 : 1
        )
      )
    );

    const zipRows = compact(
      zip(...tableRows)
        .map((row) => row)
        .flat(2)
    );

    return zipRows.map((row) => row.map((cell) => cell.navigationIndex));
  }

  static buildNavigationMap(tables: IPerioTable[]): Map<number, number> {
    return tables.reduce<Map<number, number>>((navigationIndexMap, table) => {
      table.rows.forEach((row) =>
        row.forEach((cell) => {
          if (cell.navigationIndex === undefined) {
            return;
          }
          navigationIndexMap.set(cell.navigationIndex, navigationIndexMap.size);
        })
      );
      return navigationIndexMap;
    }, new Map<number, number>());
  }

  static calculateColSpan(row: (number | undefined)[]): number {
    const grouped = groupBy(compact(row), (cell) => cell);
    return Math.max(...Object.values(grouped).map((group) => group.length));
  }

  static assignCellProperties(tables: IPerioTable[]): IPerioTable[] {
    let navIndex = 0;

    return tables.map((table) => {
      const clonedRows = table.rows.map((row) =>
        row.map((cell) => ({
          ...cell,
          navigationIndex: cell.readOnly ? undefined : navIndex++,
          readOnly: PerioTable.isReadOnlyCell(cell),
        }))
      );

      return {
        ...table,
        rows: clonedRows,
      };
    });
  }

  static getCellCoordinates(
    navigationArray: (number | undefined)[][],
    index: number
  ): SelectedCell {
    for (let row = 0; row < navigationArray.length; row++) {
      const col = navigationArray[row].indexOf(index);
      if (col !== -1) {
        return { row, col };
      }
    }
    return { row: 0, col: 0 };
  }

  static focusCell(
    cellIndex: number,
    navMap: Map<number, number>,
    cells: ElementRef<HTMLInputElement>[]
  ): void {
    const target = PerioTableByTooth.getElementByIndex(
      cellIndex,
      navMap,
      cells
    );

    if (!target) {
      return;
    }

    target.nativeElement.focus();
  }

  static getNavigationIndexForArrowUp(
    currentIndex: number,
    navigationArray: (number | undefined)[][]
  ): number | undefined {
    const { row, col } = PerioTableNavigation.getCellCoordinates(
      navigationArray,
      currentIndex
    );

    for (let rowOffset = 1; row - rowOffset >= 0; rowOffset++) {
      const targetRow = row - rowOffset;
      const aboveNavIndex = navigationArray[targetRow][col];

      if (aboveNavIndex !== undefined) {
        return aboveNavIndex;
      }
    }

    return undefined;
  }

  static getNavigationIndexForArrowDown(
    currentIndex: number,
    navigationArray: (number | undefined)[][]
  ): number | undefined {
    const { row, col } = PerioTableNavigation.getCellCoordinates(
      navigationArray,
      currentIndex
    );

    for (
      let rowOffset = 1;
      row + rowOffset < navigationArray.length;
      rowOffset++
    ) {
      const targetRow = row + rowOffset;
      const belowNavIndex = navigationArray[targetRow][col];

      if (belowNavIndex !== undefined) {
        return belowNavIndex;
      }
    }

    return undefined;
  }

  static getDirectionFromSwitchPattern(
    pattern: SwitchPattern,
    navArray: (number | undefined)[][],
    cell: IPerioTableCell
  ): SwitchDirection {
    if (pattern === SwitchPattern.Quadrant) {
      return PerioTableNavigation.determineQuadrantDirection(cell);
    }

    if (pattern === SwitchPattern.Zigzag) {
      const navArrayRemoveEmptyRows = navArray.filter(
        (row) => !row.every(isUndefined)
      );
      return PerioTableNavigation.determineRowReverseDirection(
        navArrayRemoveEmptyRows,
        cell.navigationIndex ?? 0
      );
    }

    return SwitchDirection.Default;
  }

  static toggleDirection(currentDirection: SwitchDirection): SwitchDirection {
    return currentDirection === SwitchDirection.Default
      ? SwitchDirection.Reverse
      : SwitchDirection.Default;
  }

  static determineQuadrantDirection(cell: IPerioTableCell): SwitchDirection {
    return isRightQuadrant(cell.metadata.toothRef.quadrant)
      ? SwitchDirection.Default
      : SwitchDirection.Reverse;
  }

  static determineRowReverseDirection(
    navArray: (number | undefined)[][],
    navigationIndex: number
  ): SwitchDirection {
    const rowIndex = navArray.findIndex((row) => row.includes(navigationIndex));
    return rowIndex % 2 === 0
      ? SwitchDirection.Default
      : SwitchDirection.Reverse;
  }
}
