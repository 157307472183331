import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CompareToPipe } from './compare-to.pipe';
import { FindPipe } from './find.pipe';
import { FormControlErrorPipe } from './form-control-error.pipe';
import { FromNowPipe } from './from-now.pipe';
import { InArrayPipe } from './in-array.pipe';
import { IsSameRefPipe } from './is-same-ref.pipe';
import { JoinPipe } from './join.pipe';
import { MapPipe } from './map.pipe';
import { MediaQueryActivePipe } from './media-query-active.pipe';
import { MomentPipe } from './moment.pipe';
import { PairMapPipe } from './pair-map.pipe';
import { PredicatePipe } from './predicate.pipe';
import { ProfileImagePipe } from './profile-image.pipe';
import { RoutePathPipe } from './route-path.pipe';
import { SplitCamelPipe } from './split-camel.pipe';
import { SplitKebabPipe } from './split-kebab.pipe';
import { ToDatePipe } from './to-date.pipe';
import { ToNowPipe } from './to-now.pipe';
import { PairMapAsyncPipe } from './pair-map-async.pipe';
import { ToPixelsPipe } from './to-pixels.pipe';
import { TimeFormatPipe } from './time-format.pipe';
import { RemoveStorageUidPipe } from './remove-storage-uid.pipe';
import { UnserialisePipe } from './unserialise.pipe';
import { FromNowAsyncPipe } from './from-now-async.pipe';
import { TypeGuardPipe } from './type-guard.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    MomentPipe,
    SplitCamelPipe,
    SplitKebabPipe,
    ToNowPipe,
    FromNowPipe,
    ToDatePipe,
    ProfileImagePipe,
    JoinPipe,
    InArrayPipe,
    FormControlErrorPipe,
    RoutePathPipe,
    MediaQueryActivePipe,
    CompareToPipe,
    FindPipe,
    PredicatePipe,
    IsSameRefPipe,
    MapPipe,
    PairMapPipe,
    PairMapAsyncPipe,
    ToPixelsPipe,
    TimeFormatPipe,
    RemoveStorageUidPipe,
    UnserialisePipe,
    FromNowAsyncPipe,
    TypeGuardPipe,
  ],
  exports: [
    MomentPipe,
    SplitCamelPipe,
    SplitKebabPipe,
    ToNowPipe,
    FromNowPipe,
    ToDatePipe,
    ProfileImagePipe,
    JoinPipe,
    InArrayPipe,
    FormControlErrorPipe,
    RoutePathPipe,
    MediaQueryActivePipe,
    CompareToPipe,
    FindPipe,
    PredicatePipe,
    IsSameRefPipe,
    MapPipe,
    PairMapPipe,
    PairMapAsyncPipe,
    ToPixelsPipe,
    TimeFormatPipe,
    RemoveStorageUidPipe,
    UnserialisePipe,
    FromNowAsyncPipe,
    TypeGuardPipe,
  ],
})
export class PipesModule {}
