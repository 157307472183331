import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CurrentBrandScope,
  CurrentStafferScope,
  GlobalStoreService,
} from '@principle-theorem/ng-principle-shared';
import { type IBreadcrumb } from '@principle-theorem/ng-shared';
import { type ITreatmentConfiguration } from '@principle-theorem/principle-core/interfaces';
import { type CollectionReference } from '@principle-theorem/shared';
import { filterUndefined, findProp } from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-treatment-config-list',
  templateUrl: './treatment-config-list.component.html',
  styleUrls: ['./treatment-config-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentConfigListComponent {
  breadcrumbs$: Observable<IBreadcrumb[]>;
  treatmentConfigurationCol$: Observable<
    CollectionReference<ITreatmentConfiguration>
  >;

  constructor(
    private _route: ActivatedRoute,
    private _currentBrand: CurrentBrandScope,
    private _currentStaffer: CurrentStafferScope,
    public globalStore: GlobalStoreService
  ) {
    this.treatmentConfigurationCol$ = this._route.data.pipe(
      findProp<CollectionReference<ITreatmentConfiguration>>(
        'treatmentConfigurationCol'
      ),
      filterUndefined()
    );

    this.breadcrumbs$ = combineLatest([
      this._currentBrand.doc$.pipe(filterUndefined()),
      this._currentStaffer.doc$,
    ]).pipe(
      map(([brand, staffer]) => {
        if (staffer) {
          return [
            { label: 'Settings', path: '../../../../' },
            { label: brand.name },
            { label: 'My Account' },
            { label: 'Treatment Configurations' },
          ];
        }
        return [
          { label: 'Settings', path: '../../../../' },
          { label: brand.name },
          { label: 'Treatment Configurations' },
        ];
      })
    );
  }
}
