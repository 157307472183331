import { isEnumValue, isObject } from '@principle-theorem/shared';
import { isBoolean, isString } from 'lodash';

export function isQuestionCallbackData(
  data: unknown
): data is IQuestionCallbackData {
  return (
    isObject(data) && isObject(data.question) && isString(data.question.text)
  );
}

export function isStatusMessageCallbackData(
  data: unknown
): data is StatusMessageCallbackData {
  return isString(data);
}

export function isReceiptCallbackData(
  data: unknown
): data is IReceiptCallbackData {
  return (
    isObject(data) &&
    isObject(data.receipt) &&
    isBoolean(data.receipt.signatureRequired) &&
    isString(data.receipt.merchantReceipt)
  );
}

export interface ITerminalRequestParams {
  /**
   * Override the configured mid for multi-merchant terminals or if your
   * browser does not support local storage.
   */
  mid?: number;

  /**
   * Override the configured tid for multi-merchant terminals or if your
   * browser does not support local storage.
   */
  tid?: number;
}

export interface IIntegrationKeyRequestParams {
  /**
   * Supply the integration key if your browser does not support local storage.
   */
  integrationKey?: string;
}

export enum TransactionCompleteResult {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  REVERSED = 'REVERSED',
  DECLINED = 'DECLINED',
  SYSTEM_ERROR = 'SYSTEM ERROR',
  NOT_STARTED = 'NOT STARTED',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN',
  CLAIM_DECLINED = 'CLAIM_DECLINED',
  CANCEL_DECLINED = 'CANCEL_DECLINED',
  VOIDED = 'VOIDED',
}

export interface IBaseTransactionCompleteCallbackData {
  /**
   * One of the following values: APPROVED, CANCELLED, REVERSED, DECLINED,
   * SYSTEM ERROR, NOT STARTED, UNKNOWN.
   * The merchant will only receive money if this value is APPROVED.
   * UNKNOWN means the merchant should look at the terminal to determine what
   * happened. Typically this would indicate a network error.
   */
  result: TransactionCompleteResult;

  /**
   * Mentioned briefly in the docs.
   * "Please be sure to pass in the healthpointTransactionId which is the
   * transactionId returned in the response to the original HealthPoint claim"
   * Appears only to be absent from 'NOT STARTED' transactions
   */

  transactionId: string;
}

export function isBaseTransactionCompleteCallbackData(
  data: unknown
): data is IBaseTransactionCompleteCallbackData {
  return (
    isObject(data) &&
    isString(data.result) &&
    isEnumValue(TransactionCompleteResult, data.result) &&
    isString(data.transactionId)
  );
}

/**
 * Question Callback (TYRO.IClient only)
 * Invoked when the terminal requires the merchant to answer a question in order
 * to proceed with the transaction.
 */
export interface IQuestionCallbackData {
  /**
   * The question to be presented to the merchant consisting of:
   */
  question: {
    /**
     * The message to present to the merchant.
     */
    text: string;

    /**
     * The set of button labels to present. A button should be presented to the
     * merchant for each element in this array.
     */
    options?: string[];
  };

  /**
   * The callback to be used to provide the merchant's answer to the question.
   * The POS should call this function when the merchant clicks one of the
   * buttons passing the label of the clicked button (from the options array
   * in the question).
   */
  answerCallback: (answer: string) => void;
}

/**
 * Status Message Callback (TYRO.IClient only)
 * Invoked to advertise what is happening on terminal, which is typically facing
 * the customer rather than the merchant. Called with a single String argument.
 * For example "Select account".
 */
export type StatusMessageCallbackData = string;

/**
 * Invoked when integrated receipts are enabled and a merchant copy of the
 * receipt is available. Ignored if integrated receipt printing is disabled.
 */
export interface IReceiptCallbackData {
  receipt: {
    /**
     * Indicates whether a signature line should be printed and a customer
     * signature collected.
     */
    signatureRequired: boolean;

    /**
     * Text representation of the Tyro receipt intended for the merchant.
     * Formatted to be printed in a monospaced font.
     */
    merchantReceipt: string;
  };
}

export function isTyroResponse(
  data: unknown
): data is { result: TransactionCompleteResult } {
  return (
    isObject(data) &&
    isString(data.result) &&
    isEnumValue(TransactionCompleteResult, data.result)
  );
}

export function isNotStarted(
  data: unknown
): data is { result: TransactionCompleteResult.NOT_STARTED } {
  return (
    isObject(data) && data.result === TransactionCompleteResult.NOT_STARTED
  );
}
