import { type WithRef } from '@principle-theorem/shared';
import { type IAppointment } from '../../appointment/appointment';
import { type IStaffer } from '../../staffer/staffer';
import { type ITemplateContext } from '../templating';
import {
  type IPatientScopeData,
  type IPatientTemplateContext,
} from './patient-context';
import {
  type IPracticeScopeData,
  type IPracticeTemplateContext,
} from './practice-context';

export interface IAppointmentScopeData
  extends IPatientScopeData,
    IPracticeScopeData {
  appointment: WithRef<IAppointment>;
  practitioner: IStaffer;
  confirmLink: string;
}

export interface IAppointmentTemplateContext
  extends ITemplateContext,
    IPatientTemplateContext,
    IPracticeTemplateContext {
  appointmentStartDate: string;
  appointmentStartTime: string;
  appointmentEndTime: string;
  appointmentDate: string;
  appointmentPractitionerFullName: string;
  appointmentPractitionerProviderNumber: string;
  appointmentTreatmentPlanName: string;
  appointmentTreatmentStepName: string;
  appointmentConfirmationUrl: string;
  medicalHistoryLink: string;
}
