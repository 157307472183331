import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AppointmentCreateSidebarComponent,
  AppointmentCreateSidebarService,
  AppointmentInteractionsDialogComponent,
  IAppointmentCreateSidebarData,
  IWaitListConfigurationData,
  WaitlistConfigurationDialogComponent,
} from '@principle-theorem/ng-appointment';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import {
  CurrentPracticeScope,
  GapStoreService,
  OrganisationService,
  WaitListStore,
} from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  DynamicSidebarService,
} from '@principle-theorem/ng-shared';
import {
  Brand,
  Event,
  IMoveAppointmentSideBarData,
  removeCandidateFromAllShortlists,
  ScheduleSummary,
  stafferToNamedDoc,
  TypesenseWaitList,
  waitListCandidateToEvent,
} from '@principle-theorem/principle-core';
import {
  IBrand,
  ICandidateCalendarEvent,
  IPatient,
  IScheduleSummaryEventable,
  isEventable,
  IStaffer,
  ITypesenseWaitListWithRef,
  IWaitListCandidate,
  IWaitListItem,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  ITimePeriod,
  WithRef,
  addDocAsWithRef,
  asColRef,
  asyncForEach,
  deleteDoc,
  filterUndefined,
  isSameRef,
  isWithRef,
  snapshot,
  timePeriodsIntersect,
  toMoment,
  toMomentTz,
  toTimestamp,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import {
  CandidateGapTimeComponent,
  IGapTimeData,
} from './components/candidate-gap-time/candidate-gap-time.component';
import { InteractiveTimelineService } from '@principle-theorem/ng-eventable';
import { isUndefined } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class GapCandidateShortlistActionsService {
  brand$: Observable<WithRef<IBrand>>;
  staffer$: Observable<WithRef<IStaffer>>;

  constructor(
    private _dialog: MatDialog,
    private _organisation: OrganisationService,
    private _snackBar: MatSnackBar,
    private _sidebar: DynamicSidebarService,
    private _appointmentCreateSidebar: AppointmentCreateSidebarService,
    private _appointmentScheduling: AppointmentSchedulingFacade,
    private _practiceScope: CurrentPracticeScope,
    private _gapStore: GapStoreService,
    private _waitListStore: WaitListStore,
    private _interactiveTimeline: InteractiveTimelineService
  ) {
    this.brand$ = this._organisation.brand$.pipe(filterUndefined());
    this.staffer$ = this._organisation.staffer$.pipe(filterUndefined());
  }

  async openWaitlistConfiguration(
    typesenseWaitListItem: ITypesenseWaitListWithRef
  ): Promise<void> {
    const appointment = await Firestore.getDoc(typesenseWaitListItem.ref);
    const config: MatDialogConfig = DialogPresets.medium({
      data: { appointment },
    });
    const waitListItem = await this._dialog
      .open<
        WaitlistConfigurationDialogComponent,
        IWaitListConfigurationData,
        IWaitListItem | undefined
      >(WaitlistConfigurationDialogComponent, config)
      .afterClosed()
      .toPromise();
    if (!waitListItem) {
      return;
    }

    const updatedTypesenseWaitListItem =
      await TypesenseWaitList.fromAppointment({
        ...appointment,
        waitListItem,
      });
    if (!updatedTypesenseWaitListItem) {
      return;
    }

    this._waitListStore.updateWaitListItem({
      ...updatedTypesenseWaitListItem,
      ref: appointment.ref,
    });
  }

  showSchedulingNotes(
    appointment: ITypesenseWaitListWithRef,
    patient: WithRef<IPatient>
  ): void {
    const config: MatDialogConfig = DialogPresets.large({
      height: '80%',
      data: { appointment, patient },
    });
    this._dialog.open(AppointmentInteractionsDialogComponent, config);
  }

  async processWaitlistCandidate(
    candidate: IWaitListCandidate,
    gap: IScheduleSummaryEventable,
    actionFn: (
      candidate: IWaitListCandidate,
      gap: IScheduleSummaryEventable
    ) => Promise<void>
  ): Promise<void> {
    if (this._isMatchingOrOverlapping(candidate, gap)) {
      await actionFn(candidate, gap);
      return;
    }

    const timePeriod = await this._openGapTimeDialog(candidate, gap);
    if (!timePeriod) {
      return;
    }

    candidate.candidate.offerTimeFrom = toTimestamp(timePeriod.from);
    candidate.candidate.offerTimeTo = toTimestamp(timePeriod.to);
    await actionFn(candidate, gap);
  }

  async addCandidateToShortlist(
    candidate: IWaitListCandidate,
    gap: IScheduleSummaryEventable
  ): Promise<WithRef<ICandidateCalendarEvent>> {
    const candidateEvent = await this._waitlistCandidateToCalendarEvent(
      candidate,
      gap
    );
    const appointment = await Firestore.getDoc(candidate.candidate.appointment);
    if (appointment.waitListItem) {
      await Firestore.patchDoc(appointment.ref, {
        waitListItem: {
          ...appointment.waitListItem,
          inShortList: true,
        },
      });
    }
    return candidateEvent;
  }

  async removeCandidateFromShortlist(
    candidate: WithRef<ICandidateCalendarEvent>
  ): Promise<void> {
    await deleteDoc(candidate.ref);
    this._snackBar.open(
      `${candidate.candidate.patient.name} removed from shortlist`
    );
  }

  async openMoveAppointment(
    candidate: WithRef<ICandidateCalendarEvent>,
    gap: IScheduleSummaryEventable
  ): Promise<void> {
    const data = await this._getMoveAppointmentData(candidate, gap);
    const range = this._getOfferTimes(data);
    const currentAppointment = await snapshot(
      this._appointmentScheduling.currentAppointment$
    );

    this._setAppointmentScheduling(data, range, gap);

    if (!isSameRef(currentAppointment, data.appointment)) {
      this._updateAppointmentScheduling(data);
    }

    this._sidebar.open<
      AppointmentCreateSidebarComponent,
      IAppointmentCreateSidebarData
    >(AppointmentCreateSidebarComponent, {
      cleanUpCallbackFn: async () => this._appointmentCreateSidebar.cleanUp(),
      data: {
        saveFn: async () => {
          await this.cleanUpApprovedCandidate(candidate);
          await this._gapStore.cleanUp();
          this._snackBar.open(
            `${candidate.candidate.patient.name} approved for gap`
          );
        },
      },
    });

    await this._createAndSelectCandidateTimelineNode(candidate, gap);
  }

  async cleanUpApprovedCandidate(
    candidate: WithRef<ICandidateCalendarEvent>
  ): Promise<void> {
    const brand = await snapshot(this.brand$);
    await removeCandidateFromAllShortlists(candidate, brand);
    await this._removeOverlappingCandidates(candidate);
  }

  private async _removeOverlappingCandidates(
    candidate: WithRef<ICandidateCalendarEvent>
  ): Promise<void> {
    const approvedTimePeriod = Event.toTimePeriod(candidate.event);
    const pendingGaps = await snapshot(this._gapStore.pendingGaps$);
    const foundPendingGap = pendingGaps.find((pendingGap) =>
      pendingGap.metadata.candidates.some((pendingCandidate) =>
        isSameRef(pendingCandidate, candidate)
      )
    );
    if (!foundPendingGap) {
      return;
    }
    const overlappingCandidates = foundPendingGap.metadata.candidates.filter(
      (gapCandidate) =>
        timePeriodsIntersect(
          Event.toTimePeriod(gapCandidate.event),
          approvedTimePeriod,
          true,
          'minute'
        )
    );

    await asyncForEach(overlappingCandidates, ({ ref }) => deleteDoc(ref));
  }

  private async _getMoveAppointmentData(
    candidate: IWaitListCandidate | WithRef<ICandidateCalendarEvent>,
    gap: IScheduleSummaryEventable
  ): Promise<IMoveAppointmentSideBarData> {
    const patient = await Firestore.getDoc(candidate.candidate.patient.ref);
    const practice = await snapshot(
      this._practiceScope.doc$.pipe(filterUndefined())
    );
    const gapCandidate = isWithRef(candidate)
      ? candidate
      : await this._waitlistCandidateToCalendarEvent(candidate, gap);
    const appointment = isWithRef(candidate)
      ? await Firestore.getDoc(candidate.candidate.appointment)
      : await Firestore.getDoc(candidate.appointment.ref);

    return {
      practice,
      appointment,
      patient,
      gapCandidate,
    };
  }

  private _getOfferTimes(data: IMoveAppointmentSideBarData): ITimePeriod {
    const { offerTimeFrom, offerTimeTo } = data.gapCandidate.candidate;
    const timezone = data.practice.settings.timezone;
    const from = toMomentTz(offerTimeFrom, timezone);
    const to = toMomentTz(offerTimeTo, timezone);
    return { from, to };
  }

  private _setAppointmentScheduling(
    data: IMoveAppointmentSideBarData,
    range: ITimePeriod,
    gap: IScheduleSummaryEventable
  ): void {
    this._appointmentScheduling.setRequestedAppointmentOption({
      params: {
        from: range.from,
        to: range.to,
        staffer: Event.staff(gap.event)[0].ref,
        practice: data.practice.ref,
      },
      appointment: data.appointment,
    });
  }

  private _updateAppointmentScheduling(
    data: IMoveAppointmentSideBarData
  ): void {
    this._appointmentScheduling.clearPatient();
    this._appointmentScheduling.setAppointment(data.appointment);
    this._appointmentScheduling.selectPatient(data.patient);
  }

  private _isMatchingOrOverlapping(
    candidate: IWaitListCandidate,
    gap: IScheduleSummaryEventable
  ): boolean {
    const offerFrom = toMoment(candidate.candidate.offerTimeFrom);
    const offerTo = toMoment(candidate.candidate.offerTimeTo);
    const gapFrom = toMoment(gap.event.from);
    const gapTo = toMoment(gap.event.to);
    const offerDuration = offerFrom.diff(offerTo, 'minutes');
    const isLongerThanGap = offerDuration >= Event.duration(gap.event);
    const isSameTimeAsGap = offerFrom.isSame(gapFrom) && offerTo.isSame(gapTo);

    return isLongerThanGap || isSameTimeAsGap;
  }

  private async _openGapTimeDialog(
    candidate: IWaitListCandidate,
    gap: IScheduleSummaryEventable
  ): Promise<ITimePeriod | undefined> {
    const config = DialogPresets.medium({
      data: {
        gap,
        candidate,
      },
    });
    return this._dialog
      .open<CandidateGapTimeComponent, IGapTimeData, ITimePeriod>(
        CandidateGapTimeComponent,
        config
      )
      .afterClosed()
      .toPromise();
  }

  private async _waitlistCandidateToCalendarEvent(
    candidate: IWaitListCandidate,
    gap: IScheduleSummaryEventable
  ): Promise<WithRef<ICandidateCalendarEvent>> {
    const staffer = await snapshot(this.staffer$);
    const brand = await snapshot(this.brand$);
    const event = await waitListCandidateToEvent(
      gap,
      candidate,
      stafferToNamedDoc(staffer)
    );

    return addDocAsWithRef<ICandidateCalendarEvent>(
      asColRef<ICandidateCalendarEvent>(Brand.calendarEventCol(brand)),
      event
    );
  }

  private async _createAndSelectCandidateTimelineNode(
    candidate: WithRef<ICandidateCalendarEvent>,
    gap: IScheduleSummaryEventable
  ): Promise<void> {
    const candidateAppointment = await Firestore.getDoc(
      candidate.candidate.appointment
    );
    const summary = isEventable(candidateAppointment)
      ? await ScheduleSummary.getSummaryFromEventable(candidateAppointment)
      : undefined;
    const trackIndex = await this._interactiveTimeline.getTrackIndexFromEvent(
      gap.event
    );

    await this._gapStore.cleanUp();

    if (!summary || isUndefined(trackIndex)) {
      return;
    }

    const practice = await Firestore.getDoc(candidateAppointment.practice.ref);
    const timezone = practice.settings.timezone;
    const day = {
      from: toMomentTz(candidate.event.from, timezone).startOf('day'),
      to: toMomentTz(candidate.event.from, timezone).endOf('day'),
    };

    const createNode = {
      trackIndex,
      day,
      uid: candidateAppointment.ref.id,
      from: toMomentTz(candidate.event.from, timezone),
      to: toMomentTz(candidate.event.to, timezone),
      dragEnabled: true,
      resizeEnabled: true,
      data: summary,
    };

    this._interactiveTimeline.setCreateNode(createNode);
    this._interactiveTimeline.selectedNode$.next({
      item: createNode,
    });
  }
}
