import { Pipe, type PipeTransform } from '@angular/core';
import { isEqual } from 'lodash';

@Pipe({
  name: 'compareTo',
})
export class CompareToPipe implements PipeTransform {
  transform<T, R>(
    valueA: T,
    valueB: R,
    compareFn?: (valueA: T, valueB: R) => boolean
  ): boolean {
    return compareFn ? compareFn(valueA, valueB) : isEqual(valueA, valueB);
  }
}
