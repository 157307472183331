<ng-container *ngIf="interaction$ | async as interaction">
  <div
    class="timeline-event"
    [ngClass]="interaction | map: getInteractionTypeColourClass"
    fxLayout="row"
  >
    <div class="timeline-icon">
      <div
        [ngClass]="interaction | map: getInteractionTypeColourClass"
        class="mat-elevation-z1 type-icon"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <mat-icon>{{ interaction | map: getInteractionTypeIcon }}</mat-icon>
      </div>
    </div>
    <div
      fxFlex
      fxLayout="column"
      class="timeline-card layout-padding mat-elevation-z1"
    >
      <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="8px">
        @if (schedulingEvent$ | async; as schedulingEvent) {
          <div class="flex flex-auto flex-col">
            <div class="flex flex-row flex-wrap gap-1 text-sm">
              @if (ownerMention$ | async; as ownerMention) {
                <pr-content-view [content]="ownerMention" />
              } @else {
                {{ unknown | titlecase }}
              }

              @if (schedulingEventDisplay$ | async; as display) {
                <div [ngClass]="display.textColour">
                  {{ schedulingEvent | map: getActionLabel : this }}
                </div>
              }
              <div>appointment.</div>
              @if (interaction.suffix) {
                <pr-content-view [content]="interaction.suffix" />
              }
            </div>
            <div class="text-xs opacity-50">
              @if (fullDate) {
                {{
                  schedulingEvent.scheduledAt
                    | moment
                    | amDateFormat: fullDateFormat
                }}
              } @else {
                {{
                  schedulingEvent.scheduledAt
                    | moment
                    | amDateFormat: dateFormat
                }}
              }
            </div>
          </div>
        } @else {
          <div>
            <pr-content-view [content]="title$ | async" />
            <p *ngIf="showCreatedAt$ | async" class="mat-caption">
              @if (fullDate) {
                {{
                  interaction.createdAt | moment | amDateFormat: fullDateFormat
                }}
              } @else {
                {{ interaction.createdAt | moment | amDateFormat: dateFormat }}
              }
            </p>
          </div>
        }

        <pr-interaction-actions
          [canEdit]="isEditable$ | async"
          [pinnableTypes]="pinnableTypes"
          [interaction]="interaction"
          (interactionUpdated)="updateInteraction(interaction, $event)"
          (interactionDeleted)="deleteInteraction(interaction)"
        />
      </div>
      @if (schedulingEvent$ | async; as schedulingEvent) {
        <pr-scheduling-event-interaction-details
          class="mt-0.5 rounded bg-slate-50 p-2 pl-4"
          [schedulingEvent]="schedulingEvent"
          [showSnapshotColour]="false"
        />
      }
      @if (tags$ | async; as tags) {
        @if (tags.length) {
          <pr-tag-display-list [compact]="true" [tags]="tags" />
        }
      }
      @if (hasComments$ | async) {
        <pr-content-view class="mt-2" [content]="interaction.content" />
      }
    </div>
  </div>
</ng-container>
