import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { roundTo2Decimals } from '@principle-theorem/accounting';
import {
  CurrentScopeFacade,
  GlobalStoreService,
} from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  AccountCredit,
  Transaction,
  payWithCredits,
} from '@principle-theorem/principle-core';
import {
  TransactionProvider,
  TransactionStatus,
  TransactionType,
  type IAccountCredit,
  type IAccountCreditExtendedData,
  type IInvoice,
  type IPayWithCreditResults,
  type IPractice,
  type ITransaction,
  ITreatmentCategory,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  isSameRef,
  sortTimestampAsc,
  type AtLeast,
  type DocumentReference,
  type WithRef,
  INamedDocument,
} from '@principle-theorem/shared';
import { min, sum } from 'lodash';
import { Subject, combineLatest, type Observable, of } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import {
  TransactionPracticeOptionMethods,
  TransactionPracticeOptions,
} from '../transaction-components/transaction-practice-options';

interface IReservedDetails {
  practitioner?: INamedDocument<IStaffer>;
  treatmentCategory?: WithRef<ITreatmentCategory>;
}
export interface IAccountCreditTransactionDialogData {
  transaction: AtLeast<ITransaction, 'to' | 'from'>;
  credits: WithRef<IAccountCredit>[];
  invoice: IInvoice;
}

export interface IAccountCreditTransactionReturnData
  extends IPayWithCreditResults {
  transaction: ITransaction<IAccountCreditExtendedData>;
}

interface IAccountCreditFormData {
  amount: number;
  accountCredits: WithRef<IAccountCredit>[];
  practiceRef: DocumentReference<IPractice>;
}

@Component({
  selector: 'pr-account-credit-transaction',
  templateUrl: './account-credit-transaction.component.html',
  styleUrls: ['./account-credit-transaction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountCreditTransactionComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByAccountCredit = TrackByFunctions.ref<IAccountCredit>();
  availableCredits: WithRef<IAccountCredit>[];
  isDisabled$: Observable<boolean>;
  available$: Observable<number>;
  limit$: Observable<number>;
  isLimitedByInvoiceRemaining$: Observable<boolean>;
  form = new TypedFormGroup<IAccountCreditFormData>({
    amount: new TypedFormControl<number>(0),
    accountCredits: new TypedFormControl<WithRef<IAccountCredit>[]>([]),
    practiceRef: new TypedFormControl<DocumentReference<IPractice>>(undefined),
  });
  automationListMessage = `Account credits selected below will only be "used" until the amount above is reached.`;
  practiceOptions: TransactionPracticeOptions;

  constructor(
    private _dialogRef: MatDialogRef<
      AccountCreditTransactionComponent,
      IAccountCreditTransactionReturnData
    >,
    @Inject(MAT_DIALOG_DATA) public data: IAccountCreditTransactionDialogData,
    private _currentScope: CurrentScopeFacade,
    private _globalStore: GlobalStoreService
  ) {
    this.availableCredits = this.data.credits
      .filter((credit) => AccountCredit.remaining(credit) > 0)
      .sort((itemA, itemB) =>
        sortTimestampAsc(itemA.createdAt, itemB.createdAt)
      );
    const creditsTotal = sum(
      this.availableCredits.map((credit) => AccountCredit.remaining(credit))
    );

    this.form.patchValue({
      amount: creditsTotal,
      accountCredits: this.availableCredits,
    });

    this.available$ = formControlChanges$(
      this.form.controls.accountCredits
    ).pipe(
      map((credits) =>
        credits
          ? sum(credits.map((credit) => AccountCredit.remaining(credit)))
          : 0
      )
    );

    this.isDisabled$ = combineLatest([
      this.available$.pipe(map((available) => available > 0)),
      this.form.valueChanges.pipe(
        map(() => this.form.valid),
        startWith(this.form.valid)
      ),
    ]).pipe(map(([hasCredits, isValid]) => !hasCredits || !isValid));

    this.limit$ = this.available$.pipe(
      map((available) =>
        roundTo2Decimals(min([available, this.data.transaction.amount]) || 0)
      )
    );

    this.isLimitedByInvoiceRemaining$ = combineLatest([
      this.limit$,
      this.available$,
    ]).pipe(map(([limit, available]) => limit < available));

    this.limit$.pipe(takeUntil(this._onDestroy$)).subscribe((limit) => {
      this.form.controls.amount.setValidators(Validators.max(limit));
      const value = this.form.controls.amount.value;
      if (value && value > limit) {
        this.form.controls.amount.setValue(limit);
      }
      this.form.updateValueAndValidity();
    });

    this.practiceOptions = new TransactionPracticeOptions(
      this._currentScope.currentPractice$,
      TransactionPracticeOptionMethods.toInvoicePracticeOption(
        this.data.invoice
      ),
      true
    );
    this.practiceOptions.initialValue$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((practice) =>
        this.form.controls.practiceRef.setValue(practice.ref)
      );

    this.practiceOptions.options$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((practices) => {
        if (practices.length > 1) {
          this.form.controls.practiceRef.setValidators(Validators.required);
          this.form.controls.practiceRef.updateValueAndValidity();
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  creditRemaining(credit: WithRef<IAccountCredit>): number {
    return AccountCredit.remaining(credit);
  }

  resolveTreatmentCategory$(
    credit: WithRef<IAccountCredit>
  ): Observable<WithRef<ITreatmentCategory> | undefined> {
    if (!credit.reservedFor.treatmentCategory) {
      return of(undefined);
    }
    return this._globalStore.getTreatmentCategory$(
      credit.reservedFor.treatmentCategory
    );
  }

  resolveReservedDetails$(
    credit: WithRef<IAccountCredit>
  ): Observable<IReservedDetails | undefined> {
    return this.resolveTreatmentCategory$(credit).pipe(
      map((treatmentCategory) => {
        if (!credit.reservedFor.practitioner && !treatmentCategory) {
          return;
        }
        return {
          treatmentCategory,
          practitioner: credit.reservedFor.practitioner,
        };
      })
    );
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }

    const formData = this.form.value;
    const applyCreditResult = payWithCredits(
      formData.accountCredits,
      formData.amount
    );

    const transaction = Transaction.init<IAccountCreditExtendedData>({
      ...this.data.transaction,
      ...Transaction.internalReference(TransactionProvider.AccountCredit),
      amount: formData.amount,
      type: TransactionType.Incoming,
      status: TransactionStatus.Complete,
      practiceRef: formData.practiceRef,
      extendedData: {
        accountCreditsUsed: applyCreditResult.alteredCredits.map(
          (alteredCredit) => ({
            name: alteredCredit.accountCredit.description,
            ref: alteredCredit.accountCredit.ref,
            amount: alteredCredit.amount,
            refunded: 0,
            totalRefunded: 0,
          })
        ),
      },
    });
    this._dialogRef.close({
      transaction,
      ...applyCreditResult,
    });
  }

  compareFn(
    accountCreditA: WithRef<IAccountCredit>,
    accountCreditB: WithRef<IAccountCredit>
  ): boolean {
    return accountCreditA && accountCreditB
      ? isSameRef(accountCreditA, accountCreditB)
      : false;
  }
}
