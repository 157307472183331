import { type IPrincipleMention } from '../mention/mention';
import { type ITemplateDefinition } from './template-definition';
import { type TemplateScope } from './template-scope';

export interface ITemplateScopeData {
  [key: string]: unknown;
}

/**
 * The data used to populate a template body.
 * All properties must be a string | number
 */
export interface ITemplateContext {
  [key: string]: string | number;
}

export interface ITemplateContextOption<
  T extends ITemplateScopeData = ITemplateScopeData,
> {
  label: string;
  scope: TemplateScope;
  context: ITemplateContext;
  scopeData: T;
}

/**
 * Denotes the ability to create a template context
 */
export interface IContextBuilder<TemplateContext extends ITemplateContext> {
  build(): TemplateContext;
}

export type WithTemplates<T> = T & ITemplatesWithContext;
export type WithTemplateResource<T> = T & {
  templateResource: IPrincipleMention;
};

export interface ITemplatesWithContext {
  templates: ITemplateDefinition[];
  contextOptions: ITemplateContextOption[];
}

export enum SystemTemplate {
  PrintHeader = 'printHeader',
  PrintFooter = 'printFooter',
  PrintInvoiceHeader = 'printInvoiceHeader',
  PrintInvoiceFooter = 'printInvoiceFooter',
  PrintTreatmentPlanHeader = 'printTreatmentPlanHeader',
  PrintTreatmentPlanFooter = 'printTreatmentPlanFooter',
  InvoiceSMS = 'invoiceSMS',
  InvoiceEmail = 'invoiceEmail',
}
