import { Component, Input, EventEmitter, Output } from '@angular/core';
import {
  ServiceCodeGroupType,
  type IServiceCodeGroup,
} from '@principle-theorem/principle-core/interfaces';
import { TrackByFunctions } from '@principle-theorem/ng-shared';

const descriptionMap: Map<ServiceCodeGroupType, string> = new Map([
  [ServiceCodeGroupType.Required, `Every item in this group will be recorded`],
  [
    ServiceCodeGroupType.Exclusive,
    `Only one item in this group will be recorded`,
  ],
  [
    ServiceCodeGroupType.Optional,
    `Any number of items in this group can be recorded`,
  ],
]);

@Component({
  selector: 'pr-service-group-header',
  templateUrl: './service-group-header.component.html',
  styleUrls: ['./service-group-header.component.scss'],
})
export class ServiceGroupHeaderComponent {
  trackByType = TrackByFunctions.variable<ServiceCodeGroupType>();
  groupTypes: ServiceCodeGroupType[] = Array.from(descriptionMap.keys());
  @Input() group: IServiceCodeGroup;
  @Output()
  groupChange = new EventEmitter<IServiceCodeGroup>();
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  changeType(type: ServiceCodeGroupType): void {
    this.group.type = type;
    this.groupChange.emit(this.group);
  }

  get description(): string {
    return descriptionMap.get(this.group.type) || '';
  }
}
