<ng-container *ngIf="notesWithHistory$ | async as notes">
  <div class="notes box-border flex h-full min-h-full w-full flex-col">
    <ng-container *ngIf="notes.length; else noNotes">
      @for (note of notes; track note.note.recordDate; let isLast = $last) {
        <div class="layout-margin note">
          <div class="header flex justify-between gap-4">
            <div>
              <h3>{{ note.note.owner.name }}</h3>

              <div class="flex gap-4">
                <p class="mat-caption">
                  Record Date
                  <span class="time">
                    {{
                      note.note.recordDate | moment | amDateFormat: dateFormat
                    }}
                  </span>
                </p>

                <p class="mat-caption">
                  Last Modified
                  <span class="time">
                    {{ note.note.updatedAt | moment | amTimeAgo }}
                  </span>
                </p>

                <div
                  class="lock flex gap-1.5"
                  [ngClass]="{
                    'unlocked-warning': note.note.immutable === false
                  }"
                >
                  <mat-icon>
                    {{ note.note.immutable ? 'lock' : 'lock_open' }}
                  </mat-icon>
                  <span class="mat-caption">
                    {{ note.note.immutable ? 'Completed' : 'Incomplete' }}
                  </span>
                </div>

                <div
                  *ngIf="note.history.length"
                  class="mat-caption text-primary-400 cursor-pointer"
                  (click)="openHistoryDialog(note.history)"
                >
                  View Edit History
                </div>
              </div>
            </div>
            <button mat-icon-button [matMenuTriggerFor]="moreMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #moreMenu="matMenu">
              <span [matTooltip]="note.note | map: noteLockedMessage">
                <button
                  [disabled]="note.note.immutable"
                  mat-menu-item
                  (click)="editClinicalNote(note.note)"
                >
                  <mat-icon>edit</mat-icon>
                  Edit Clinical Note
                </button>
              </span>
              <button
                *ngIf="note.note | map: isNoteOwner$ : this | async"
                (click)="toggleLock(note)"
                mat-menu-item
              >
                <mat-icon>{{
                  note.note.immutable ? 'lock' : 'lock_open'
                }}</mat-icon>
                {{ note.note.immutable ? 'Unlock' : 'Lock' }} Clinical Note
              </button>
            </mat-menu>
          </div>
          <pr-content-view class="content" [content]="note.note.content" />
        </div>
        <mat-divider *ngIf="!isLast" />
      }
    </ng-container>

    <ng-template #noNotes>
      <div class="layout-margin mat-caption">No clinical notes</div>
    </ng-template>
  </div>
</ng-container>
