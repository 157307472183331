import { type Funder } from '@medipass/partner-sdk';
import { TypeGuard, isObject } from '@principle-theorem/shared';
import { isString } from 'lodash';

export type InvoiceSessionKeyFunder = Funder | 'virtual-terminal';

export function isInvoiceSessionKeyFunder(
  data: unknown
): data is InvoiceSessionKeyFunder {
  const acceptedValues = [
    'virtual-terminal',
    'medicare',
    'dva',
    'hicaps',
    'patient-funded',
    'ghs',
    'icare',
  ];
  return isString(data) && acceptedValues.includes(data);
}

export interface IGetInvoiceSessionKeyRequest {
  orgUid: string;
  invoicePath: string;
  funder: InvoiceSessionKeyFunder;
  claimUid?: string;
}

export function isGetInvoiceSessionKeyRequest(
  data: unknown
): data is IGetInvoiceSessionKeyRequest {
  return (
    isObject(data) &&
    isString(data.orgUid) &&
    isString(data.invoicePath) &&
    isString(data.funder) &&
    TypeGuard.undefinedOr(isString)(data.claimUid)
  );
}

export interface IGetInvoiceSessionKeyResponse {
  sessionKey: string;
}
