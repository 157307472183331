<div fxLayout="column" fxLayoutGap="16px">
  <h1 class="mat-headline-4">Users</h1>

  <div fxLayout="row" fxLayoutGap="16px">
    <pt-search-field fxFlex>
      <input matInput placeholder="Search" [formControl]="searchCtrl" #search />
    </pt-search-field>
    <button mat-raised-button color="primary" (click)="invite()">Invite</button>
  </div>

  <pr-empty-state
    *ngIf="emptyState$ | async"
    image="list"
    title="users"
   />

  <ng-container *ngIf="activeUsers$ | async as activeUsers">
    <ng-container *ngIf="activeUsers.length">
      <h3 class="mat-subtitle-1">Active Users</h3>
      <mat-nav-list>
        <pr-user-list-item
          *ngFor="let user of activeUsers; trackBy: trackByUser"
          [user]="user"
          mat-list-item
          [routerLink]="['./', user.ref.id]"
         />
      </mat-nav-list>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="disabledUsers$ | async as disabledUsers">
    <ng-container *ngIf="disabledUsers.length">
      <h3
        class="mat-subtitle-1 disabled-users-heading"
        fxLayoutAlign="start center"
        (click)="disabledUserList.toggle()"
      >
        Disabled Users
        <mat-icon>
          {{ disabledUserList.expanded ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </h3>
      <pt-collapsible #disabledUserList>
        <ng-template ptCollapsibleContent>
          <mat-nav-list>
            <pr-user-list-item
              *ngFor="let user of disabledUsers; trackBy: trackByUser"
              [user]="user"
              mat-list-item
              [routerLink]="['./', user.ref.id]"
             />
          </mat-nav-list>
        </ng-template>
      </pt-collapsible>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="emptySearch$ | async">
    <pr-empty-state
      image="search"
      title="search results"
      [searchInputValue]="search.value"
     />
  </ng-container>
</div>
