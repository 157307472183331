import { MixedSchema, RawInlineNodes } from '@principle-theorem/editor';

export interface IPopupNotification {
  uid: string;
  groupId: string;
  title: string | RawInlineNodes;
  content: string | MixedSchema;
  hasTimeout: boolean;
  actions: {
    label: string;
    colour?: 'primary' | 'accent' | 'warn';
    style?: 'stroked' | 'flat';
    actionFn: () => void | Promise<void>;
  }[];
}
