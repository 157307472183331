import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectQueryParam } from '@principle-theorem/ng-principle-shared';
import {
  APPOINTMENT_SCHEDULING_MODULE_FEATURE_KEY,
  type IAppointmentSchedulingModuleState,
} from '../reducers/reducers';

export const getAppointmentSchedulingModuleState =
  createFeatureSelector<IAppointmentSchedulingModuleState>(
    APPOINTMENT_SCHEDULING_MODULE_FEATURE_KEY
  );

export const getAppointmentSchedulingState = createSelector(
  getAppointmentSchedulingModuleState,
  (state) => state.appointmentScheduling
);

export const getAppointmentDetails = createSelector(
  getAppointmentSchedulingState,
  (state) => state.appointmentDetails
);

export const getPatientDetails = createSelector(
  getAppointmentSchedulingState,
  (state) => state.patientDetails
);

export const getSelectedPatient = createSelector(
  getAppointmentSchedulingState,
  (state) => state.selectedPatient
);

export const getWaitListDetails = createSelector(
  getAppointmentSchedulingState,
  (state) => state.waitlistDetails
);

export const getAppointmentFilterOptions = createSelector(
  getAppointmentSchedulingState,
  (state) => state.filterOptions
);

export const getAppointmentSaving = createSelector(
  getAppointmentSchedulingState,
  (state) => state.savingAppointment
);

export const getSchedulingInteractions = createSelector(
  getAppointmentSchedulingState,
  (state) => state.interactions
);

export const getAppointment = createSelector(
  getAppointmentSchedulingState,
  (state) => state.appointment
);

export const getTags = createSelector(
  getAppointmentSchedulingState,
  (state) => state.tags
);

export const getChecklists = createSelector(
  getAppointmentSchedulingState,
  (state) => state.checklists
);

export const getAppointmentRequest = createSelector(
  getAppointmentSchedulingState,
  (state) => state.appointmentRequest
);

export const getPatientIdParam = selectQueryParam('patientUid');
