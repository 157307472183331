import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  convertValueFn,
} from '../../../source/source-helpers';
import { isExactADAItemListItem, type IExactADAItem } from './ada-item';
import { ExactTreatmentType } from './patient-treatments';
import { flow } from 'lodash';
import { upperFirst } from 'lodash';

export const PATIENT_USED_ITEM_CODE_CONDITION_RESOURCE_TYPE =
  'usedItemCodeConditions';

export const PATIENT_USED_ITEM_CODE_CONDITION_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Item Codes Used as Conditions List',
      description: '',
      idPrefix: PATIENT_USED_ITEM_CODE_CONDITION_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

const CONDITION_SOURCE_QUERY = `
SELECT
  DISTINCT(servicecode) AS item_code,
  servicedescription AS description,
  DENSE_RANK() OVER (results) AS id
FROM convtreatment
WHERE treatmenttype = '${upperFirst(ExactTreatmentType.Base)}'
WINDOW results AS (ORDER BY servicecode)
ORDER BY id
  `;

export class UsedItemCodeConditionSourceEntity extends BaseSourceEntity<IExactADAItem> {
  sourceEntity = PATIENT_USED_ITEM_CODE_CONDITION_SOURCE_ENTITY;
  entityResourceType = PATIENT_USED_ITEM_CODE_CONDITION_RESOURCE_TYPE;
  sourceQuery = CONDITION_SOURCE_QUERY;
  verifySourceFn = isExactADAItemListItem;
  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertValueFn((value) => String(value).replace(/\//g, ' '), 'itemCode'),
  ]);

  translate(_data: IExactADAItem): unknown {
    return {};
  }

  getSourceRecordId(data: IExactADAItem): number {
    return data.id;
  }

  getSourceLabel(data: IExactADAItem): string {
    return `${data.itemCode}`;
  }
}
