import {
  isNodeSchema,
  type NodeSchema,
  type RawSchema,
  recursiveReplace,
  type SchemaDataProvider,
  BlockNodes,
  type RawSchemaNodes,
} from '@principle-theorem/editor';
import {
  type IMigration,
  type IMigrationLogger,
  Migration,
} from '@principle-theorem/migration-runner';
import { last, isArray } from 'lodash';

@Migration({
  name: 'move-inline-image-to-block-node-block',
  uid: '07fff327-9e37-4682-b5c2-ce66debf2fbf',
})
export class MoveInlineImageToBlockNodeMigration implements IMigration {
  async up(
    dryRun: boolean,
    _logger: IMigrationLogger,
    data: SchemaDataProvider
  ): Promise<void> {
    const schema = await data.get();
    const newSchema = recursiveReplace(schema, (item, _parents) => {
      if (
        !isNodeSchema(item) ||
        !isArray(item.content) ||
        !item.content.length
      ) {
        return item;
      }

      const content: NodeSchema[] = [];

      item.content.map((contentItem) => {
        if (
          contentItem.type !== BlockNodes.Paragraph ||
          !isArray(contentItem.content) ||
          !contentItem.content.length
        ) {
          content.push(contentItem);
          return;
        }

        const hasImageInline = contentItem.content.some(
          (childContent) => childContent.type === BlockNodes.Image
        );

        if (!hasImageInline) {
          content.push(contentItem);
          return;
        }

        const subContent: NodeSchema[] = [
          {
            ...contentItem,
            content: [],
          },
        ];

        contentItem.content.map((childContent, index) => {
          if (!isImageInlineNodeSchema(childContent, contentItem)) {
            last(subContent)?.content?.push(childContent);
            return;
          }

          subContent.push(childContent);

          const isLastItem = index + 1 === contentItem.content?.length;
          if (!isLastItem) {
            subContent.push({
              ...contentItem,
              content: [],
            });
          }
        });

        content.push(...subContent);
      });

      item.content = content as NodeSchema<RawSchemaNodes>[];
      return item;
    });

    if (!dryRun) {
      await data.update(newSchema as RawSchema);
    }
  }

  async down(
    dryRun: boolean,
    _logger: IMigrationLogger,
    data: SchemaDataProvider
  ): Promise<void> {
    const schema = await data.get();
    const newSchema = recursiveReplace(schema, (item, parents) => {
      const parent = last(parents);
      const parentIsParagraph =
        parent &&
        isNodeSchema(parent) &&
        String(parent.type) === String(BlockNodes.Paragraph);
      if (
        !isNodeSchema(item) ||
        String(item.type) !== String(BlockNodes.Image) ||
        parentIsParagraph
      ) {
        return item;
      }

      return {
        type: BlockNodes.Paragraph,
        content: [item],
      };
    });

    if (!dryRun) {
      await data.update(newSchema as RawSchema);
    }
  }
}

function isImageInlineNodeSchema(
  item: object,
  parent?: object
): item is NodeSchema<BlockNodes.Image> {
  return (
    isNodeSchema(item, parent) &&
    String(item.type) === String(BlockNodes.Image) &&
    !!parent &&
    isNodeSchema(parent) &&
    String(parent.type) === String(BlockNodes.Paragraph)
  );
}
