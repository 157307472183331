import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  type IBreadcrumb,
  StorageResponseAPI,
} from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  errorNil,
  type ITimePeriod,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { DateRangeDataBuilder } from '../../../models/report/data-builders/date-range-data-builder';

@Component({
  selector: 'pr-outstanding-invoices-reporting',
  templateUrl: './outstanding-invoices-reporting.component.html',
  styleUrls: ['./outstanding-invoices-reporting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutstandingInvoicesReportingComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  breadcrumbs: IBreadcrumb[] = [{ label: 'Reporting' }];
  // TODO: Build with timezone - CU-251edxw
  from = moment().startOf('day').subtract({ months: 1 });
  to = moment().endOf('day');
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);
  brands$: Observable<WithRef<IBrand>[]>;
  dataBuilder: DateRangeDataBuilder;

  constructor(
    private _organisation: OrganisationService,
    scope: CurrentScopeFacade,
    api: StorageResponseAPI
  ) {
    this.brands$ = this._organisation.userBrands$;
    this.dataBuilder = new DateRangeDataBuilder(
      api,
      this.from,
      this.to,
      this._organisation.brand$.pipe(errorNil()),
      this.practice$.asObservable().pipe(map((practice) => [practice]))
    );

    scope.currentPractice$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((practice) => this.practice$.next(practice));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  updateDateRange(dateRange: ITimePeriod): void {
    this.dataBuilder.updateRange(
      toMoment(dateRange.from),
      toMoment(dateRange.to)
    );
  }
}
