<mat-toolbar>
  <div fxLayout="row" fxLayoutAlign="center stretch">
    <div class="checkbox">
      <mat-checkbox
        color="primary"
        [indeterminate]="hasSomeSelected$ | async"
        [checked]="isAllSelected$ | async"
        (change)="checkboxChange.emit($event.checked)"
       />
    </div>

    <ng-container *ngIf="selectedIds$ | async as selectedIds">
      <mat-divider *ngIf="selectedIds.length" [vertical]="true" />

      <div fxFlex *ngIf="selectedIds.length">
        <button
          mat-icon-button
          matTooltip="Cancel Selected"
          matTooltipShowDelay="500"
          (click)="cancelSelected.emit()"
        >
          <mat-icon>cancel_schedule_send</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</mat-toolbar>
