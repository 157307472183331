import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  ChartedItemScopeResolver,
  ChartedTreatment,
  ChartedTreatmentUpdater,
  TreatmentPlanProposal,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  ITreatmentPlanProposal,
  type IChartedItem,
  type IChartedItemConfiguration,
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  type IChartedRef,
  type IChartedSurface,
  type IChartedTreatment,
  type IFeeSchedule,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import {
  type IChartedSurfaceProvider,
  type IChartSurfaceEvent,
  type IEditTreatmentProvider,
  type TreatmentUpdateFn,
} from '../add-charted-surface-provider';
import { BaseAddTreatmentProvider } from '../base-add-treatment-provider';

export class AddTreatmentToMultiTreatmentProposalProvider
  extends BaseAddTreatmentProvider
  implements IChartedSurfaceProvider, IEditTreatmentProvider
{
  constructor(
    private _chartStore: ChartFacade,
    private _multiStepTreatment: IChartedMultiStepTreatment,
    private _multiStepTreatmentStep: IChartedMultiStepTreatmentStep,
    private _feeSchedule: WithRef<IFeeSchedule>
  ) {
    super();
  }

  async addSurfaceToTreatment(
    _event: IChartSurfaceEvent,
    surfaces: IChartedSurface[],
    config: WithRef<ITreatmentConfiguration>
  ): Promise<void> {
    const scopeResolver = new ChartedItemScopeResolver();
    const surfaceScopeRefPairs = scopeResolver.reduceChartedSurfacesToScope(
      config,
      surfaces
    );

    const treatments = surfaceScopeRefPairs.map((surfaceScopeRefPair) => ({
      ...ChartedTreatment.fromConfig(
        config,
        this._feeSchedule,
        surfaceScopeRefPair.scopeRef
      ),
      chartedSurfaces: surfaceScopeRefPair.surfaces,
    }));

    const syncedTreatments = await ChartedTreatmentUpdater.syncPricingRules([
      ...this._multiStepTreatmentStep.treatments,
      ...treatments,
    ]);

    this._chartStore.updatePlanProposal(
      ChartId.InAppointment,
      await this._updateMultiTreatments(syncedTreatments)
    );
  }

  async removeSurfacesFromTreatment(
    surfaceRefs: Partial<IChartedRef>[],
    item: IChartedItem<IChartedItemConfiguration>
  ): Promise<void> {
    await this._chartStore.removeChartedSurfaces(
      ChartId.InAppointment,
      surfaceRefs,
      item
    );
  }

  async addTreatments(treatments: IChartedTreatment[]): Promise<void> {
    const combinedTreatments = [
      ...this._multiStepTreatmentStep.treatments,
      ...treatments,
    ];
    const newTreatments =
      await ChartedTreatmentUpdater.syncPricingRules(combinedTreatments);

    this._chartStore.updatePlanProposal(
      ChartId.InAppointment,
      await this._updateMultiTreatments(newTreatments)
    );
  }

  async removeTreatments(treatments: IChartedTreatment[]): Promise<void> {
    const updatedSteps = this._multiStepTreatment.steps.map((step) =>
      treatments.reduce(
        (updatedStep, treatment) =>
          TreatmentStep.removeTreatment(updatedStep, treatment),
        step
      )
    );

    const multiTreatment = {
      ...this._multiStepTreatment,
      steps: await asyncForEach(updatedSteps, async (step) => {
        return {
          ...step,
          treatments: await ChartedTreatmentUpdater.syncPricingRules(
            step.treatments
          ),
        };
      }),
    };

    await this._chartStore.updateMultiTreatment(
      ChartId.InAppointment,
      multiTreatment
    );
  }

  async updateTreatments(
    treatments: IChartedTreatment[],
    stepTreatments: IChartedTreatment[],
    updateFn: TreatmentUpdateFn
  ): Promise<void> {
    const updatedTreatments = await asyncForEach(
      treatments,
      async (treatment) => updateFn(treatment, [...stepTreatments, treatment])
    );

    this._chartStore.updatePlanProposal(
      ChartId.InAppointment,
      await this._updateMultiTreatments(updatedTreatments)
    );
  }

  private async _updateMultiTreatments(
    treatments: IChartedTreatment[]
  ): Promise<ITreatmentPlanProposal> {
    const chart = await snapshot(
      this._chartStore.clinicalChartState$(ChartId.InAppointment)
    );

    const updatedTreatmentsOnMultiTreatment =
      TreatmentPlanProposal.updateTreatmentsOnMultiTreatment(
        chart.flaggedTreatment.multiTreatments,
        treatments,
        this._multiStepTreatment,
        this._multiStepTreatmentStep.uid
      );

    const multiTreatments = await asyncForEach(
      updatedTreatmentsOnMultiTreatment,
      async (multiTreatment) =>
        this._chartStore.updatePrimaryTreatmentCategories(multiTreatment)
    );

    return {
      ...chart.flaggedTreatment,
      multiTreatments,
    };
  }
}
