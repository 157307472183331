import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgPatientModule } from '@principle-theorem/ng-patient';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ReportingCoreModule } from '../../core/reporting-core.module';
import { ReportingComponentsModule } from '../../reporting-components/reporting-components.module';
import { PatientVisitsReportTableComponent } from './patient-visits-report/patient-visits-report-table/patient-visits-report-table.component';
import { PatientVisitsReportComponent } from './patient-visits-report/patient-visits-report.component';
import { PatientVisitsReportStore } from './patient-visits-report/patient-visits-report.store';
import { PatientsReportTypeSelectorComponent } from './patients-report-type-selector/patients-report-type-selector.component';
import { PatientsReportingComponent } from './patients-reporting.component';
import { ReferralSourcesReportDrilldownComponent } from './referral-sources/referral-sources-report-drilldown/referral-sources-report-drilldown.component';
import { ReferralSourcesReportSummaryComponent } from './referral-sources/referral-sources-report-summary/referral-sources-report-summary.component';
import { ReferralSourcesReportComponent } from './referral-sources/referral-sources-report.component';
import { ReferralSourcesReportStore } from './referral-sources/referral-sources-report.store';

@NgModule({
  imports: [
    RouterModule,
    ReportingCoreModule,
    ReportingComponentsModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    NgPatientModule,
  ],
  declarations: [
    PatientsReportingComponent,
    ReferralSourcesReportComponent,
    PatientVisitsReportComponent,
    PatientVisitsReportTableComponent,
    PatientsReportTypeSelectorComponent,
    ReferralSourcesReportSummaryComponent,
    ReferralSourcesReportDrilldownComponent,
  ],
  providers: [PatientVisitsReportStore, ReferralSourcesReportStore],
})
export class PatientsReportingModule {}
