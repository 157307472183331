import { type ComponentType } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IEditorMentionAttributes } from '@principle-theorem/editor';
import { type IMentionRenderComponent } from '@principle-theorem/ng-editor';
import { type IContextualAction } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IPrincipleMention,
  type MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import { toMention } from '@principle-theorem/principle-core';
import { asDocRef, type IProvider, snapshot } from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MentionActionResolverService } from '../mention-action-resolver.service';
import {
  ContextActionButtonBuilder,
  type IContextualActionButton,
} from '../../contextual-actions/contextual-actions-buttons';

export const MENTION_BUTTON_PROVIDER: IProvider<
  IEditorMentionAttributes,
  ComponentType<IMentionRenderComponent>
> = {
  canProvide: (_data) => true,
  execute: (_data) => MentionButtonsComponent,
};

@Component({
  selector: 'pr-mention-buttons',
  templateUrl: './mention-buttons.component.html',
  styleUrls: ['./mention-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentionButtonsComponent implements IEditorMentionAttributes {
  trackByAction =
    TrackByFunctions.nestedField<IContextualActionButton>('action.name');
  key$: ReplaySubject<string> = new ReplaySubject(1);
  path$: ReplaySubject<string> = new ReplaySubject(1);
  type$: ReplaySubject<MentionResourceType> = new ReplaySubject(1);
  mention$: Observable<IPrincipleMention>;
  contextButtons$: Observable<IContextualActionButton[]>;

  constructor(private _mentionActionResolver: MentionActionResolverService) {
    this.mention$ = combineLatest([this.key$, this.path$, this.type$]).pipe(
      map(([key, path, type]) =>
        toMention({ name: key, ref: asDocRef(path) }, type)
      )
    );
    this.contextButtons$ = this.mention$.pipe(
      map((mention) =>
        ContextActionButtonBuilder.build(mention).createMany(
          this._mentionActionResolver.resolveActions(mention.resource.type)
        )
      )
    );
  }

  @Input()
  set key(key: string) {
    if (key) {
      this.key$.next(key);
    }
  }

  @Input()
  set path(path: string) {
    if (path) {
      this.path$.next(path);
    }
  }

  @Input()
  set type(type: MentionResourceType) {
    if (type) {
      this.type$.next(type);
    }
  }

  async runAction(action: IContextualAction): Promise<void> {
    action.do(await snapshot(this.mention$));
  }
}
