import { ActivatedRoute } from '@angular/router';
import { IntercomService } from '@principle-theorem/ng-intercom';
import { Observable, combineLatest, of } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DATE_TIME_FORMAT } from '@principle-theorem/shared';
import { IConfirmGapOfferData } from '@principle-theorem/principle-core/interfaces';
import { isNull } from 'lodash';
import { map, switchMap, tap } from 'rxjs/operators';
import { PatientGapOfferService } from './patient-gap-offer.service';
import {
  PatientGapOfferState,
  PatientGapOfferStateBloc,
} from './patient-gap-offer-state';
import { sanitiseCSRF } from '@principle-theorem/temporary-tokens';

@Component({
    selector: 'pr-patient-gap-offer',
    templateUrl: './patient-gap-offer.component.html',
    styleUrls: ['./patient-gap-offer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientGapOfferComponent implements OnInit, OnDestroy {
  readonly dateFormat = DATE_TIME_FORMAT;
  readonly timeFormat = 'h:mm A';
  tokenUid$: Observable<string | undefined>;
  state = new PatientGapOfferStateBloc();
  data$: Observable<IConfirmGapOfferData | undefined>;

  constructor(
    private _patientGapOfferService: PatientGapOfferService,
    private _route: ActivatedRoute,
    private _intercom: IntercomService
  ) {
    this.tokenUid$ = combineLatest([
      this._route.queryParamMap.pipe(
        map((queryParams) => queryParams.get('token')),
        map((token) => (!isNull(token) ? token : undefined))
      ),
      this._route.paramMap.pipe(
        map((params) => params.get('token')),
        map((token) => (!isNull(token) ? token : undefined))
      ),
    ]).pipe(
      map(([queryToken, routeToken]) => queryToken ?? routeToken),
      map((token) => token && sanitiseCSRF(token))
    );

    this.data$ = this.tokenUid$.pipe(
      switchMap((tokenUid) =>
        tokenUid
          ? this._patientGapOfferService.resolveGapOfferData$(tokenUid)
          : of(undefined)
      ),
      tap((data) => this._setStateForGapOfferData(data))
    );
  }

  ngOnInit(): void {
    this._intercom.hideIcon();
  }

  ngOnDestroy(): void {
    this._intercom.showIcon();
  }

  private _setStateForGapOfferData(data?: IConfirmGapOfferData): void {
    if (!data) {
      this.state.state$.next(PatientGapOfferState.NotAvailable);
      return;
    }
    if (data.gapTaken) {
      this.state.state$.next(PatientGapOfferState.AlreadyFilled);
      return;
    }
    this.state.state$.next(PatientGapOfferState.Confirmed);
  }
}
