/* eslint-disable @nx/enforce-module-boundaries */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ChartFacade,
  ChartId,
  TreatmentStepsFacade,
} from '@principle-theorem/ng-clinical-charting/store';
import { CreateLabJobActionService } from '@principle-theorem/ng-labs';
import {
  GlobalStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  SelectionListStore,
} from '@principle-theorem/ng-shared';
import {
  SterilisationRecordDialogComponent,
  type ISterilisationRecordDialogRequest,
  type ISterilisationRecordDialogResponse,
} from '@principle-theorem/ng-sterilisation';
import { CreateTaskActionService } from '@principle-theorem/ng-tasks';
import {
  Appointment,
  AppointmentManager,
  ChartedItemTotalCalculator,
  ChartedTreatmentUpdater,
  Practice,
  Staffer,
  SterilisationRecord,
  TreatmentStep,
  stafferToNamedDoc,
  toMention,
} from '@principle-theorem/principle-core';
import {
  AutomationStatus,
  IChartedMultiStepTreatmentStep,
  ITag,
  InvoiceStatus,
  MentionResourceType,
  type AnyChartedItemConfiguration,
  type IAppointment,
  type IChartedItemSelectionSummary,
  type IChartedRef,
  type IChartedTreatment,
  type IClinicalChart,
  type IPatient,
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  asyncForEach,
  filterUndefined,
  Firestore,
  getCount,
  isWithRef,
  multiSwitchMap,
  snapshot,
  toNamedDocument,
  toQuery,
  undeletedQuery,
  where,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject, combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AddChartable } from '../../add-chartable';
import {
  ChartableSurfaceUpdater,
  type IEditChartableData,
} from '../../chartable-surface-updater';
import { AddTreatmentToStoreStepProvider } from '../../charted-surface/store-treatment-plan/add-treatment-to-store-step-provider';
import { ChecklistFormDialogComponent } from 'libs/ng-appointment/src/lib/components/scheduling/checklist-form-dialog/checklist-form-dialog.component';
import { AppointmentAutomationsDialogComponent } from 'libs/ng-appointment/src/lib/components/scheduling/appointment-automations-dialog/appointment-automations-dialog.component';
import { compact } from 'lodash';
import { toSignal } from '@angular/core/rxjs-interop';
import { PatientPrescriptionDialogComponent } from '@principle-theorem/ng-patient';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import {
  IPatientFormsDialogRequest,
  PatientFormsDialogComponent,
} from '@principle-theorem/ng-custom-forms';

@Component({
    selector: 'pr-todays-appointment',
    templateUrl: './todays-appointment.component.html',
    styleUrls: ['./todays-appointment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TodaysAppointmentComponent {
  private _global = inject(GlobalStoreService);
  private _organisation = inject(OrganisationService);
  disabled$: Observable<boolean>;
  selectedSurfaces$: Observable<Partial<IChartedRef>[]>;
  treatmentTotal$: Observable<number>;
  invoiceIssued$: Observable<boolean>;
  tags$: Observable<WithRef<ITag>[]>;
  appointmentCompleted$: Observable<boolean>;
  automationCount$: Observable<number>;
  checklistItemCount$: Observable<number>;
  step$ = new ReplaySubject<WithRef<ITreatmentStep>>(1);
  plan$ = new ReplaySubject<WithRef<ITreatmentPlan>>(1);
  appointment$ = new ReplaySubject<WithRef<IAppointment>>(1);
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  chart$ = new ReplaySubject<IClinicalChart>(1);
  expanded = false;
  hasPrescriptionPermissions = toSignal(this._canCreatePrescription$(), {
    initialValue: false,
  });

  @Input()
  set chart(chart: IClinicalChart) {
    if (chart) {
      this.chart$.next(chart);
    }
  }

  @Input()
  set step(step: WithRef<ITreatmentStep>) {
    if (!step) {
      return;
    }
    this.step$.next(step);
  }

  @Input()
  set plan(plan: WithRef<ITreatmentPlan>) {
    if (plan) {
      this.plan$.next(plan);
    }
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(
    private _treatmentStepsFacade: TreatmentStepsFacade,
    private _snackBar: MatSnackBar,
    public elementRef: ElementRef,
    private _chartStore: ChartFacade,
    private _dialog: MatDialog,
    private _createTaskActionService: CreateTaskActionService,
    private _createLabJobActionService: CreateLabJobActionService,
    public selectionList: SelectionListStore<
      IChartedItemSelectionSummary<IChartedTreatment>
    >
  ) {
    this.treatmentTotal$ = this.step$.pipe(
      map((step) => new ChartedItemTotalCalculator().step(step))
    );

    this.selectedSurfaces$ = this._chartStore.selectedSurfacesState$(
      ChartId.InAppointment
    );

    this.appointmentCompleted$ = this.appointment$.pipe(
      map((appointment) => Appointment.isComplete(appointment))
    );
    this.tags$ = this.appointment$.pipe(
      map((appointment) => appointment.tags),
      multiSwitchMap((tag) => this._global.getTag$(tag.ref)),
      map(compact)
    );

    this.invoiceIssued$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.invoice$(appointment)),
      map((invoice) =>
        invoice &&
        [InvoiceStatus.Issued, InvoiceStatus.Paid].includes(invoice.status)
          ? true
          : false
      )
    );

    const noAssociatedChart$ = this.appointment$.pipe(
      map((appointment) => !appointment.clinicalChart)
    );

    this.disabled$ = combineLatest([
      noAssociatedChart$,
      this.appointmentCompleted$,
    ]).pipe(
      map(
        ([noAssociatedChart, appointmentCompleted]) =>
          noAssociatedChart || appointmentCompleted
      )
    );

    this.automationCount$ = this.step$.pipe(
      switchMap((step) =>
        getCount(
          toQuery(
            TreatmentStep.automationCol(step),
            where('status', '!=', AutomationStatus.Cancelled)
          )
        )
      )
    );

    this.checklistItemCount$ = this.appointment$.pipe(
      switchMap((appointment) =>
        getCount(undeletedQuery(Appointment.checklistCol(appointment)))
      )
    );
  }

  async addChartable(
    chartable: AnyChartedItemConfiguration,
    selectedSurfaces: Partial<IChartedRef>[]
  ): Promise<void> {
    const step = await snapshot(this.step$);
    const feeSchedule = await snapshot(
      this._chartStore.getFeeScheduleManager().currentSchedule$
    );

    const addSurfaceProviders = [
      new AddTreatmentToStoreStepProvider(
        this._treatmentStepsFacade,
        step,
        feeSchedule
      ),
    ];
    await new AddChartable(addSurfaceProviders).add({
      selectedSurfaces,
      chartable,
      chartingAs: await snapshot(
        this._chartStore.chartingAs$(ChartId.InAppointment)
      ),
    });
    this._chartStore.setSelectedSurfaces(ChartId.InAppointment, []);
  }

  async updateChartable(data: IEditChartableData): Promise<void> {
    const step = await snapshot(this.step$);
    const feeSchedule = await snapshot(
      this._chartStore.getFeeScheduleManager().currentSchedule$
    );
    return ChartableSurfaceUpdater.updateTreatmentSurfaces(data, [
      new AddTreatmentToStoreStepProvider(
        this._treatmentStepsFacade,
        step,
        feeSchedule
      ),
    ]);
  }

  async updateTreatments(
    id: string,
    treatments: IChartedTreatment[]
  ): Promise<void> {
    this._treatmentStepsFacade.updateTreatmentStep(id, {
      treatments: await ChartedTreatmentUpdater.syncPricingRules(treatments),
    });
  }

  async deleteTreatment(treatment: IChartedTreatment): Promise<void> {
    const step = await snapshot(this.step$);
    await this._treatmentStepsFacade.removeTreatmentFromStep(
      treatment.uuid,
      step.ref
    );
    this._snackBar.open(`Treatment removed`);
  }

  async unlockAppointment(): Promise<void> {
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const appointment = await snapshot(this.appointment$);
    await AppointmentManager.markCheckingOut(staffer, appointment);
  }

  async addSterilisationRecords(): Promise<void> {
    const data: ISterilisationRecordDialogRequest = {
      patient: await snapshot(this.patient$),
      practice: await snapshot(
        this._organisation.practice$.pipe(filterUndefined())
      ),
      staffer: await snapshot(
        this._organisation.staffer$.pipe(filterUndefined())
      ),
    };

    const formData = await this._dialog
      .open<
        SterilisationRecordDialogComponent,
        ISterilisationRecordDialogRequest,
        ISterilisationRecordDialogResponse
      >(
        SterilisationRecordDialogComponent,
        DialogPresets.medium({ autoFocus: true, data })
      )
      .afterClosed()
      .toPromise();

    if (!formData || !formData.data.length) {
      return;
    }

    const appointment = await snapshot(this.appointment$);
    await asyncForEach(formData.data, (record) => {
      if (isWithRef(record)) {
        return Firestore.patchDoc(record.ref, {
          patient: toNamedDocument(formData.patient),
          appointment: appointment.ref,
        });
      }
      return addDoc(
        Practice.sterilisationRecordCol(appointment.practice),
        SterilisationRecord.init({
          data: record,
          patient: toNamedDocument(formData.patient),
          practice: appointment.practice,
          appointment: appointment.ref,
          scannedBy: stafferToNamedDoc(formData.staffer),
        })
      );
    });

    this._snackBar.open(
      `Sterilisation record${formData.data.length > 1 ? 's' : ''} added`
    );
  }

  async managePatientForms(): Promise<void> {
    const patient = await snapshot(this.patient$);
    this._dialog.open<
      PatientFormsDialogComponent,
      IPatientFormsDialogRequest,
      void
    >(
      PatientFormsDialogComponent,
      DialogPresets.medium<IPatientFormsDialogRequest>({
        data: { patient },
      })
    );
  }

  async addTask(): Promise<void> {
    const patient = await snapshot(this.patient$);
    await this._createTaskActionService.do(
      toMention(patient, MentionResourceType.Patient)
    );
  }

  async addLabJob(): Promise<void> {
    await this._createLabJobActionService.do();
  }

  async addPrescription(): Promise<void> {
    const patient = await snapshot(this.patient$);
    const { practitioner } = await snapshot(this.appointment$);
    const actionedBy = await snapshot(this._organisation.staffer$);
    const prescriber = await snapshot(
      this._global.getStaffer$(practitioner.ref)
    );

    this._dialog.open(
      PatientPrescriptionDialogComponent,
      DialogPresets.large({
        data: { patient, prescriber, actionedBy },
      })
    );
  }

  async addChecklistItem(): Promise<void> {
    const appointment = await snapshot(this.appointment$);

    const config = DialogPresets.medium({
      data: { appointment },
    });
    this._dialog.open(ChecklistFormDialogComponent, config);
  }

  async manageAutomations(): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    const config = DialogPresets.medium({
      data: {
        appointment,
      },
    });
    this._dialog.open(AppointmentAutomationsDialogComponent, config);
  }

  async selectAllTreatments(step: WithRef<ITreatmentStep>): Promise<void> {
    const plan = await snapshot(this.plan$);
    this.selectionList.resetSelected();

    step.treatments.map((treatment) =>
      this.selectionList.toggleSelected({
        item: treatment,
        step,
        plan,
      })
    );
  }

  getChecklistItemsTooltip(count?: number | null): string {
    return count ? `${count} Checklist Items` : 'Manage Checklist Items';
  }

  getAutomationsTooltip(count?: number | null): string {
    return count ? `${count} Automations` : 'Manage Automations';
  }

  async updateStep(
    stepChanges: Partial<
      IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>
    >
  ): Promise<void> {
    const step = await snapshot(this.step$);
    this._treatmentStepsFacade.updateTreatmentStep(step.ref.id, stepChanges);
  }

  private _canCreatePrescription$(): Observable<boolean> {
    const staffer$ = this._organisation.staffer$.pipe(filterUndefined());
    return staffer$.pipe(
      switchMap((staffer) =>
        Staffer.hasPermission$(staffer, PatientPermissions.PrescriptionManage)
      )
    );
  }
}
