import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  AreaSummary,
  AreaSummaryFactory,
} from '@principle-theorem/principle-core';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import {
  ChartableSurface,
  type IAreaSummary,
  type IChartedSurface,
} from '@principle-theorem/principle-core/interfaces';
import { isEqual } from 'lodash';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-charted-items-surface-list',
  templateUrl: './charted-items-surface-list.component.html',
  styleUrls: ['./charted-items-surface-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartedItemsSurfaceListComponent {
  factory = new AreaSummaryFactory();
  trackBySummary = TrackByFunctions.label<IAreaSummary>();
  chartedSurfaces$ = new ReplaySubject<IChartedSurface[]>(1);
  availableSurfaces$ = new ReplaySubject<ChartableSurface[]>(1);
  summaries$: Observable<string[]>;
  canSetSurfaces$: Observable<boolean>;
  canEditSurfaces$: Observable<boolean>;
  manageTreatmentPlan = [PatientPermissions.TreatmentPlanManage];
  @Input() disabled: boolean = false;

  @Output() editSurfaces = new EventEmitter<void>();

  @Input()
  set chartedSurfaces(chartedSurfaces: IChartedSurface[]) {
    if (chartedSurfaces) {
      this.chartedSurfaces$.next(chartedSurfaces);
    }
  }

  @Input()
  set availableSurfaces(availableSurfaces: ChartableSurface[]) {
    if (availableSurfaces) {
      this.availableSurfaces$.next(availableSurfaces);
    }
  }

  constructor() {
    this.summaries$ = this.chartedSurfaces$.pipe(
      map((surfaces) =>
        this.factory
          .create(surfaces)
          .map((summary) => AreaSummary.asCompact(summary))
      )
    );

    this.canSetSurfaces$ = this.availableSurfaces$.pipe(
      map((surfaces) =>
        surfaces.length
          ? !isEqual(surfaces, [ChartableSurface.Unscoped])
          : false
      )
    );
    this.canEditSurfaces$ = this.availableSurfaces$.pipe(
      map((surfaces) => !isEqual(surfaces, [ChartableSurface.WholeMouth]))
    );
  }
}
