import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_FORMAT,
  TypeGuard,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export interface IPraktikaAppointmentInvoicePayment {
  id: number;
  appointment_id: number;
  type_id: PraktikaPaymentType;
  effect: PraktikaTransactionEffect;
  is_reversal: boolean;
  reversed_payment_id: number | null;
  description: string;
  effective_date: string; // '2020-02-24'
  amount: number;
  notes: string;
  surcharge_amount: number;
  surcharge_adjustment_id: number | null;
  provider_number: string | null;
  provider_name: string | null;
  card_type_id: PraktikaCreditCardType | null;
  card_type: string | null;
  customer_payment_id: number;
  merchant_id: string | null;
  terminal_id: string | null;

  // entryType: PraktikaInvoiceAddType.Payment;
  // createdDate: string; // '2020-02-24 15:37:18'
  // rrn: string | null;
  // transactionId: number;
  // transactionReference: string | null;
  // terminalReference: string | null;
  // terminalType: PraktikaMerchantProvider;
  // isCombined: boolean;
}

export function isPraktikaAppointmentInvoicePayment(
  item: unknown
): item is IPraktikaAppointmentInvoicePayment {
  return TypeGuard.interface<IPraktikaAppointmentInvoicePayment>({
    id: isNumber,
    appointment_id: isNumber,
    type_id: TypeGuard.enumValue(PraktikaPaymentType),
    effect: TypeGuard.enumValue(PraktikaTransactionEffect),
    is_reversal: isBoolean,
    reversed_payment_id: TypeGuard.nilOr(isNumber),
    description: isString,
    effective_date: isString,
    amount: isNumber,
    notes: isString,
    surcharge_amount: isNumber,
    surcharge_adjustment_id: TypeGuard.nilOr(isNumber),
    provider_number: TypeGuard.nilOr(isString),
    provider_name: TypeGuard.nilOr(isString),
    card_type_id: TypeGuard.nilOr(TypeGuard.enumValue(PraktikaCreditCardType)),
    card_type: TypeGuard.nilOr(isString),
    customer_payment_id: isNumber,
    merchant_id: TypeGuard.nilOr(isString),
    terminal_id: TypeGuard.nilOr(isString),
  })(item);
}

export enum PraktikaTransationType {
  DiscountPercentCr = 1,
  DiscountCashCr = 2,
  TransferFromDepositCr = 3,
  PostingErrorCr = 4,
  OverpaymentCr = 5,
  DoctorsAdjustmentCr = 6,
  RemoveDuplicateChargeCr = 7,
  RemoveGstCr = 8,
  PatientChangedMindCr = 9,
  WriteOffCr = 10,
  BadDebtCr = 11,
  InitialBalanceCr = 12,
  MedicareCr = 13,
  InitialBalanceDr = 21,
  LabFeeDr = 22,
  PostingErrorDr = 23,
  AddGstDr = 24,
  DoctorsAdjustmentDr = 25,
  InterestOnAccountDr = 26,
  CollectionAgencyFeeDr = 27,
  TransferFromDepositDr = 28,
  PatientChangedMindDr = 29,
  SurchargeDr = 30,
  SurchargeCr = 31,
  MedicareDr = 32,
}

export enum PraktikaTransactionEffect {
  Cr = 'CR',
  Dr = 'DR',
}

export enum PraktikaInvoiceAddType {
  Payment = 1,
  Adjustment = 2,
}

export enum PraktikaPaymentType {
  Cash = 1,
  CreditCard = 2,
  Eftpos = 3,
  Private = 4,
  Cheque = 5,
  Reversal = 6,
  DirectDeposit = 7,
  Medicare = 8,
  CashRefund = 9,
  CreditCardRefund = 10,
  EftposRefund = 11,
  PrivateRefund = 12,
  ChequeRefund = 13,
  DirectDepositRefund = 15,
  MedicareRefund = 16,
}

export enum PraktikaCreditCardType {
  Debit = 0,
  Visa = 1,
  Mastercard = 2,
  DinersClub = 3,
  Amex = 4,
  JCB = 5,
}

export enum PraktikaMerchantProvider {
  None = 0,
  Hicaps = 1,
  Tyro = 2,
}

export const PATIENT_APPOINTMENT_INVOICE_PAYMENT_RESOURCE_TYPE =
  'patientAppointmentInvoicePayment';

export const PATIENT_APPOINTMENT_INVOICE_PAYMENT_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Appointment Invoice Payment List',
      description: '',
      idPrefix: PATIENT_APPOINTMENT_INVOICE_PAYMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IPraktikaAppointmentInvoicePaymentTranslations {
  effectiveDate: Timestamp;
}

export interface IPraktikaAppointmentInvoicePaymentFilters {
  appointmentId: string;
  effectiveDate: Timestamp;
}

const PATIENT_APPOINTMENT_INVOICE_PAYMENT_SOURCE_QUERY = `
SELECT
  convert_to_integer(iAppointmentId) as appointment_id,
  convert_to_integer(iPaymentId) as id,
  convert_to_integer(iPaymentType) as type_id,
  vchEffect as effect,
  convert_to_integer(iCreditCardType) as card_type_id,
  vchDesc as description,
  vchCCDesc as card_type,
  dtEffectiveDate as effective_date,
  convert_to_integer(iAmount) as amount,
  vchNotes as notes,
  convert_to_boolean(bReversal) as is_reversal,
  convert_to_integer(iReversedPaymentId) as reversed_payment_id,
  convert_to_integer(iCustomerPaymentId) as customer_payment_id,
  convert_to_integer(iSurchargeAmount) as surcharge_amount,
  convert_to_integer(iSurchargeAdjustmentId) as surcharge_adjustment_id,
  vchMerchantId::TEXT as merchant_id,
  vchTerminalId as terminal_id,
  vchProviderNumber as provider_number,
  vchProviderName as provider_name
FROM appointment_payments
ORDER BY id DESC
`;

export class PatientAppointmentInvoicePaymentSourceEntity extends BaseSourceEntity<
  IPraktikaAppointmentInvoicePayment,
  IPraktikaAppointmentInvoicePaymentTranslations,
  IPraktikaAppointmentInvoicePaymentFilters
> {
  sourceEntity = PATIENT_APPOINTMENT_INVOICE_PAYMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_APPOINTMENT_INVOICE_PAYMENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_APPOINTMENT_INVOICE_PAYMENT_SOURCE_QUERY;
  verifySourceFn = isPraktikaAppointmentInvoicePayment;
  override transformDataFn = flow([]);
  override dateFilterField: keyof IPraktikaAppointmentInvoicePaymentFilters =
    'effectiveDate';

  translate(
    note: IPraktikaAppointmentInvoicePayment,
    timezone: Timezone
  ): IPraktikaAppointmentInvoicePaymentTranslations {
    return {
      effectiveDate: toTimestamp(
        moment.tz(note.effective_date, ISO_DATE_FORMAT, timezone).startOf('day')
      ),
    };
  }

  getSourceRecordId(data: IPraktikaAppointmentInvoicePayment): string {
    return `${data.id}-${data.customer_payment_id}`;
  }

  getSourceLabel(data: IPraktikaAppointmentInvoicePayment): string {
    return this.getSourceRecordId(data);
  }

  getFilterData(
    data: IPraktikaAppointmentInvoicePayment,
    timezone: Timezone
  ): IPraktikaAppointmentInvoicePaymentFilters {
    return {
      appointmentId: data.appointment_id.toString(),
      effectiveDate: toTimestamp(
        moment.tz(data.effective_date, ISO_DATE_FORMAT, timezone).startOf('day')
      ),
    };
  }
}
