<div
  class="search-bar"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="16px"
>
  <pt-search-field fxFlex>
    <input
      matInput
      [formControl]="searchControl"
      placeholder="Search by title, type, or creator"
    />
  </pt-search-field>
</div>
<div
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayout.gt-sm="row"
  fxLayoutAlign.gt-sm="end center"
  fxLayoutGap="16px"
>
  <!-- TODO: https://app.clickup.com/t/2jrh2q -->
  <pr-automations-type-filter-group
    [activeType]="activeTypeFilter$ | async"
    (typeFilterSelect)="setTypeFilter($event)"
   />

  <div fxFlex fxHide fxShow.gt-xs></div>

  <pt-multi-filter-group
    tooltip="Filter by Status"
    [statusFilters]="statusFilters"
    [activeStatuses]="activeStatusFilter$ | async"
    (statusFilterChange)="setStatusFilter($event)"
   />
</div>

<div class="automations-list-container mat-elevation-z1">
  <pr-automation-list-toolbar
    [hasSomeSelected]="hasSomeSelected$ | async"
    [isAllSelected]="isAllSelected$ | async"
    [selectedIds]="selectedIds$ | async"
    (checkboxChange)="checkboxChange($event)"
    (cancelSelected)="cancelSelected()"
   />
  <div class="automations-list">
    <mat-progress-bar
      *ngIf="loading$ | async"
      mode="indeterminate"
     />
    <pr-automation-list-display
      [automations]="searchFilter.results$ | async"
      [multiSelect]="true"
      [layout]="layout"
      (selectAutomation)="selectAutomation($event)"
      (unselectAutomation)="unselectAutomation($event)"
      (editAutomation)="edit($event)"
      (cancelAutomation)="cancelAutomation($event)"
      (restoreAutomation)="restore($event)"
      (resendAutomation)="resend($event)"
     />
    <pr-empty-state
      *ngIf="emptyState$ | async"
      image="list"
      title="automations"
     />
    <pr-empty-state
      *ngIf="emptySearch$ | async"
      image="search"
      title="search results"
      [searchInputValue]="searchControl.value"
     />
  </div>
</div>
