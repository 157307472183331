import {
  IMeasureMetadata,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import { AtLeast, ISO_DATE_TIME_FORMAT } from '@principle-theorem/shared';
import { get, isBoolean, isString, startCase } from 'lodash';
import {
  BigQuerySQL,
  getCustomReportFilterSQL,
  IReportingQuery,
} from '../querying';
import { DataAccessorFactory, getProperty } from './data-accessor-factory';
import { NullMeasureFilter } from './measure-filters';
import { basicFilterAccessor, noFilterAccessor } from './measure-properties';
import {
  CanBeChartedProperty,
  CanDoAllProperty,
  CanQueryByTimestampProperty,
  IMeasureRef,
} from './measure-properties';

export function defaultMetadata(
  metadata: AtLeast<IMeasureMetadata, 'id' | 'label'>
): IMeasureMetadata {
  return {
    summary: '',
    ...metadata,
  };
}

export class MeasurePropertyFactory {
  static boolean(
    metadata: AtLeast<IMeasureMetadata, 'id' | 'label'>,
    measureRef: IMeasureRef,
    measureQuery: IReportingQuery,
    canBeUndefined: boolean = false
  ): CanDoAllProperty {
    const dataAccessor = DataAccessorFactory.property<string | boolean>(
      measureRef.factPropertyPath,
      canBeUndefined ? 'N/A' : false,
      undefined,
      (value) => !!value
    );

    return new CanDoAllProperty({
      metadata: defaultMetadata({
        formatter: MeasureFormatter.Boolean,
        ...metadata,
      }),
      measure: {
        propertyName: measureRef.attributePath,
        query: measureQuery,
        dataAccessor,
        filterAccessor: (filters) =>
          BigQuerySQL.or(
            filters.map((filter) =>
              new NullMeasureFilter(filter.value ? false : true).value({
                propertyName: measureRef.factPropertyPath,
              })
            )
          ),
      },
      groupMeasure: {
        queryAttributes: [measureRef.attributePath],
        dataAccessor,
        labelAccessor: (fact) => {
          const value = dataAccessor(fact);
          return isBoolean(value) ? (value ? 'Yes' : 'No') : 'N/A';
        },
      },
    });
  }

  static timestamp(
    metadata: AtLeast<IMeasureMetadata, 'id' | 'label'>,
    measureRef: IMeasureRef,
    measureQuery: IReportingQuery,
    formatter:
      | MeasureFormatter.Day
      | MeasureFormatter.Timestamp
      | MeasureFormatter.Time = MeasureFormatter.Timestamp,
    sortTimeFormat: string = ISO_DATE_TIME_FORMAT
  ): CanQueryByTimestampProperty {
    return new CanQueryByTimestampProperty({
      queryBy: measureRef,
      metadata: defaultMetadata({
        formatter,
        ...metadata,
      }),
      measure: {
        propertyName: measureRef.attributePath,
        query: measureQuery,
        dataAccessor: DataAccessorFactory.timestamp(
          measureRef.factPropertyPath,
          false
        ),
        sortAccessor: DataAccessorFactory.timestamp(
          measureRef.factPropertyPath,
          sortTimeFormat
        ),
        filterAccessor: basicFilterAccessor(measureRef.factPropertyPath),
      },
      groupMeasure: {
        queryAttributes: measureQuery.attributes,
        dataAccessor: DataAccessorFactory.timestamp(
          measureRef.factPropertyPath,
          false
        ),
        labelAccessor: DataAccessorFactory.timestamp(
          measureRef.factPropertyPath,
          'N/A'
        ),
      },
    });
  }

  static number(
    metadata: AtLeast<IMeasureMetadata, 'id' | 'label'>,
    measure: IMeasureRef,
    measureQuery: IReportingQuery,
    defaultValue: number = 0,
    postProcessFn?: (value: number) => number
  ): CanDoAllProperty {
    const dataPostProcessFn: ((value: string | number) => number) | undefined =
      postProcessFn
        ? (value) =>
            isString(value)
              ? postProcessFn(parseFloat(value))
              : postProcessFn(value)
        : undefined;
    return new CanDoAllProperty({
      metadata: defaultMetadata(metadata),
      measure: {
        propertyName: measure.attributePath,
        query: measureQuery,
        dataAccessor: DataAccessorFactory.property<number>(
          measure.factPropertyPath,
          defaultValue,
          undefined,
          dataPostProcessFn
        ),
        filterAccessor: basicFilterAccessor(measure.factPropertyPath),
      },
      groupMeasure: {
        queryAttributes: [measure.attributePath],
        dataAccessor: DataAccessorFactory.property<number>(
          measure.factPropertyPath,
          defaultValue,
          undefined,
          dataPostProcessFn
        ),
        labelAccessor: DataAccessorFactory.property<string>(
          measure.factPropertyPath,
          defaultValue.toString(),
          undefined,
          postProcessFn
            ? (value) => postProcessFn(parseFloat(value)).toString()
            : undefined
        ),
      },
    });
  }

  static string(
    metadata: AtLeast<IMeasureMetadata, 'id' | 'label'>,
    measure: IMeasureRef,
    measureQuery: IReportingQuery,
    defaultValue: string = '',
    dataAccessorFn?: (data: unknown) => string
  ): CanDoAllProperty {
    return new CanDoAllProperty({
      metadata: defaultMetadata(metadata),
      measure: {
        propertyName: measure.attributePath,
        query: measureQuery,
        dataAccessor: DataAccessorFactory.property<string>(
          measure.factPropertyPath,
          defaultValue,
          dataAccessorFn
        ),
        filterAccessor: basicFilterAccessor(measure.factPropertyPath),
      },
      groupMeasure: {
        queryAttributes: [measure.attributePath],
        dataAccessor: DataAccessorFactory.property<string>(
          measure.factPropertyPath,
          defaultValue,
          dataAccessorFn
        ),
        labelAccessor: DataAccessorFactory.property<string>(
          measure.factPropertyPath,
          defaultValue,
          dataAccessorFn
        ),
      },
    });
  }

  static enum<T extends string>(
    metadata: AtLeast<IMeasureMetadata, 'id' | 'label'>,
    measure: IMeasureRef,
    measureQuery: IReportingQuery,
    defaultValue: T | string
  ): CanDoAllProperty {
    return new CanDoAllProperty({
      metadata: defaultMetadata(metadata),
      measure: {
        propertyName: measure.attributePath,
        query: measureQuery,
        dataAccessor: DataAccessorFactory.property<string>(
          measure.factPropertyPath,
          defaultValue,
          undefined,
          startCase
        ),
        filterAccessor: basicFilterAccessor(measure.factPropertyPath),
      },
      groupMeasure: {
        queryAttributes: [measure.attributePath],
        dataAccessor: DataAccessorFactory.property<string>(
          measure.factPropertyPath,
          defaultValue
        ),
        labelAccessor: DataAccessorFactory.property<string>(
          measure.factPropertyPath,
          defaultValue,
          undefined,
          startCase
        ),
      },
    });
  }

  static docRef(
    metadata: AtLeast<IMeasureMetadata, 'id' | 'label'>,
    docRef: IMeasureRef,
    measureQuery: IReportingQuery,
    nameMeasure?: IMeasureRef,
    defaultValue: string = ''
  ): CanDoAllProperty {
    const name = nameMeasure ?? docRef;
    return new CanDoAllProperty({
      metadata: defaultMetadata(metadata),
      measure: {
        propertyName: name.attributePath,
        query: measureQuery,
        dataAccessor: DataAccessorFactory.property<string>(
          name.factPropertyPath,
          defaultValue
        ),
        filterAccessor: basicFilterAccessor(docRef.factPropertyPath),
      },
      groupMeasure: {
        queryAttributes: [name.attributePath, docRef.attributePath],
        dataAccessor: DataAccessorFactory.property<string>(
          docRef.factPropertyPath,
          defaultValue
        ),
        labelAccessor: DataAccessorFactory.property<string>(
          name.factPropertyPath,
          defaultValue
        ),
      },
    });
  }

  static label(
    metadata: AtLeast<IMeasureMetadata, 'id' | 'label'>,
    dataMeasure: IMeasureRef,
    labelMeasure: IMeasureRef,
    measureQuery: IReportingQuery,
    defaultValue: string = ''
  ): CanDoAllProperty {
    const name = labelMeasure ?? dataMeasure;
    return new CanDoAllProperty({
      metadata: defaultMetadata(metadata),
      measure: {
        propertyName: name.attributePath,
        query: measureQuery,
        dataAccessor: DataAccessorFactory.property<string>(
          name.factPropertyPath,
          defaultValue
        ),
        filterAccessor: basicFilterAccessor(dataMeasure.factPropertyPath),
      },
      groupMeasure: {
        queryAttributes: [name.attributePath, dataMeasure.attributePath],
        dataAccessor: DataAccessorFactory.property<string>(
          dataMeasure.factPropertyPath,
          defaultValue
        ),
        labelAccessor: DataAccessorFactory.property<string>(
          name.factPropertyPath,
          defaultValue
        ),
      },
    });
  }

  static array<T, R>(
    metadata: AtLeast<IMeasureMetadata, 'id' | 'label'>,
    measure: IMeasureRef,
    measureQuery: IReportingQuery,
    unnestKey: string,
    mapFn?: (fact: T[]) => R[]
  ): CanDoAllProperty {
    return new CanDoAllProperty({
      metadata: defaultMetadata(metadata),
      measure: {
        propertyName: measure.attributePath,
        query: measureQuery,
        dataAccessor: DataAccessorFactory.array<T, R>(
          measure.factPropertyPath,
          (item) => get(item, unnestKey) as R,
          mapFn
        ),
        filterAccessor: (filters) =>
          BigQuerySQL.objectInArray(
            measure.factPropertyPath,
            getCustomReportFilterSQL(unnestKey, filters)
          ),
      },
      groupMeasure: {
        queryAttributes: [measure.attributePath],
        dataAccessor: DataAccessorFactory.array<T, R>(
          measure.factPropertyPath,
          (item) => get(item, unnestKey) as R,
          mapFn
        ),
        isArrayDataAccessor: true,
      },
    });
  }

  static link(
    metadata: AtLeast<IMeasureMetadata, 'id' | 'label'>,
    refPathMeasure: IMeasureRef,
    measureQuery: IReportingQuery,
    brandSlugMeasure: IMeasureRef,
    getLinkFn: (slug: string, refPath: string) => string | undefined
  ): CanBeChartedProperty {
    return new CanBeChartedProperty({
      metadata: defaultMetadata({
        formatter: MeasureFormatter.Link,
        ...metadata,
      }),
      measure: {
        propertyName: refPathMeasure.attributePath,
        query: measureQuery,
        dataAccessor: (fact: unknown) => {
          const slug = getProperty<string>(
            fact,
            brandSlugMeasure.factPropertyPath,
            ''
          );
          const refPath = getProperty<string>(
            fact,
            refPathMeasure.factPropertyPath,
            ''
          );
          if (!slug || !refPath) {
            // eslint-disable-next-line no-console
            console.warn('Invalid invoice link', { slug, refPath });
          }
          return getLinkFn(slug, refPath) ?? '';
        },
        filterAccessor: noFilterAccessor(),
      },
    });
  }
}
