import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { type HasChartingOptions } from '@principle-theorem/principle-core/interfaces';
import {
  type ChartableSurface,
  type IChartedItemDisplay,
} from '@principle-theorem/principle-core/interfaces';
import { saveDoc, type WithRef } from '@principle-theorem/shared';

type TreatmentChartingOptionsConfig = WithRef<HasChartingOptions>;

@Component({
  selector: 'pr-treatment-charting-options',
  templateUrl: './treatment-charting-options.component.html',
  styleUrls: ['./treatment-charting-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentChartingOptionsComponent {
  private _treatmentConfig: TreatmentChartingOptionsConfig;
  surfaces: ChartableSurface[] = [];
  display: IChartedItemDisplay;

  constructor(private _snackBar: MatSnackBar) {}

  @Input()
  set treatmentConfig(treatmentConfig: TreatmentChartingOptionsConfig) {
    this._treatmentConfig = treatmentConfig;
    this.surfaces = treatmentConfig.availableSurfaces;
    this.display = treatmentConfig.display;
  }

  get treatmentConfig(): TreatmentChartingOptionsConfig {
    return this._treatmentConfig;
  }

  selectedSurfaces(surfaces: ChartableSurface[]): void {
    this.surfaces = surfaces;
  }

  selectedDisplayOptions(display: IChartedItemDisplay): void {
    this.display = display;
  }

  async save(): Promise<void> {
    this.treatmentConfig.display = this.display;
    this.treatmentConfig.availableSurfaces = this.surfaces;
    await saveDoc(this.treatmentConfig);
    this._snackBar.open('Treatment configuration saved');
  }
}
