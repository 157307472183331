import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DEFAULT_WAITLIST_PAGE_SIZE,
  GapStoreService,
  GlobalStoreService,
  IWaitListFilters,
  WaitListStore,
} from '@principle-theorem/ng-principle-shared';
import { TypedFormControl } from '@principle-theorem/ng-shared';
import { Event } from '@principle-theorem/principle-core';
import {
  IStaffer,
  ITypesenseWaitListWithRef,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_FORMAT,
  debounceUserInput,
  filterUndefined,
  multiMap,
  WithRef,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';

export enum CandidateShortlistViewType {
  Waitlist = 'Waitlist Suggestions',
  Appointments = 'All Appointments',
}

@Component({
  selector: 'pr-gap-candidate-shortlist',
  templateUrl: './gap-candidate-shortlist.component.html',
  styleUrls: ['./gap-candidate-shortlist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GapCandidateShortlistComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  readonly dateFormat = DATE_FORMAT;
  selectedPractitioners$ = new BehaviorSubject<WithRef<IStaffer>[]>([]);
  isSmartView$: Observable<boolean>;
  allWaitlistFilters$: Observable<IWaitListFilters>;
  waitlistSearchCtrl = new TypedFormControl<string>();
  allWaitlistResults$: Observable<ITypesenseWaitListWithRef[]>;
  activeViewType$ = new BehaviorSubject<CandidateShortlistViewType>(
    CandidateShortlistViewType.Waitlist
  );
  typeFilters = [
    {
      tooltip:
        'Find an appointment to fill the gap from your appointments with configured waitlist settings.',
      value: CandidateShortlistViewType.Waitlist,
    },
    {
      tooltip:
        'Find an appointment to fill the gap from all of your appointments, regardless of whether waitlist settings are set.',
      value: CandidateShortlistViewType.Appointments,
    },
  ];

  waitListPageSize = DEFAULT_WAITLIST_PAGE_SIZE;
  gapPractitioner$: Observable<WithRef<IStaffer>[] | undefined>;

  @ViewChild('shortlistContainer') shortlistContainer: ElementRef<HTMLElement>;

  @Output() clearGap = new EventEmitter<void>();

  constructor(
    public gapStore: GapStoreService,
    public waitListStore: WaitListStore,
    private _globalStore: GlobalStoreService
  ) {
    this.selectedPractitioners$
      .pipe(
        filterUndefined(),
        multiMap((practitioners) => practitioners.ref),
        takeUntil(this._onDestroy$)
      )
      .subscribe((practitioners) =>
        this.waitListStore.setWaitListFilters({ practitioners })
      );

    this.waitlistSearchCtrl.valueChanges
      .pipe(debounceUserInput(), startWith(''), takeUntil(this._onDestroy$))
      .subscribe((searchValue) =>
        this.waitListStore.setSearchValue(searchValue)
      );

    this.isSmartView$ = this.activeViewType$.pipe(
      map((viewType) => viewType === CandidateShortlistViewType.Waitlist)
    );

    this.gapPractitioner$ = this.gapStore.selectedGap$.pipe(
      map((gap) => (gap ? first(Event.staff(gap.event)) : undefined)),
      switchMap((participant) =>
        participant
          ? this._globalStore.getStaffer$(participant.ref)
          : of(undefined)
      ),
      map((practitioner) => (practitioner ? [practitioner] : undefined))
    );
  }

  setViewType(filter: CandidateShortlistViewType): void {
    this.activeViewType$.next(filter);
  }

  loadMoreAllWaitListResults(): void {
    this.waitListStore.patchState((state) => ({
      allWaitListPageSize:
        state.allWaitListPageSize + DEFAULT_WAITLIST_PAGE_SIZE,
    }));
  }

  loadMoreSmartWaitListResults(): void {
    this.waitListStore.patchState((state) => ({
      smartWaitListPageSize:
        state.smartWaitListPageSize + DEFAULT_WAITLIST_PAGE_SIZE,
    }));
  }

  scrollToTop(): void {
    this.shortlistContainer.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
