<pt-buttons-container>
  <pr-treatment-plan-view-type-selector
    [activeType]="viewType$ | async"
    (typeSelected)="changeView($event)"
   />
  <button mat-stroked-button (click)="changePlan()">Change Plan</button>
</pt-buttons-container>

<pr-treatment-steps-editor
  [currentPlan]="treatmentPlanning.selectedPlan$ | async"
  [flaggedTreatments]="treatmentPlanning.flaggedTreatments$ | async"
  [patient]="patient$ | async"
  [showPlanView]="showPlanView$ | async"
  (showPlan)="showPlan()"
  (setFeeSchedule)="setFeeSchedule($event)"
 />
