<mat-form-field
  [appearance]="appearance"
  [subscriptSizing]="subscriptSizing"
  fxFlex
>
  <mat-label>{{ label }}</mat-label>
  <mat-select [formControl]="ctrl" [compareWith]="bloc.compareFn">
    <mat-optgroup
      *ngFor="let group of bloc.groups$ | async; trackBy: bloc.trackByBrand"
      [label]="group.brand.name"
    >
      <mat-option
        *ngFor="let practice of group.practices; trackBy: bloc.trackByPractice"
        [value]="practice"
      >
        {{ practice.name }}
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>
