import { IPricingRuleItem } from '@principle-theorem/principle-core/interfaces';
import { v4 as uuid } from 'uuid';

export class PricingRuleItem {
  static init(overrides?: Partial<IPricingRuleItem>): IPricingRuleItem {
    return {
      uid: uuid(),
      price: undefined,
      startUnit: 1,
      selected: false,
      ...overrides,
    };
  }
}
