import { MeasureFormatter } from '@principle-theorem/principle-core/interfaces';
import { ISchedulingEventFact } from '@principle-theorem/reporting/interfaces';
import { BigQueryTable } from '../../big-query-tables';
import {
  BrandQueryScopeConfig,
  DateRangeQueryScopeConfig,
} from '../../query-scope-config/query-scope-configs';
import { GroupBy } from '../../querying';
import { BaseFactMeasures, latestEventId } from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import { AppointmentDimensionMeasures } from '../dimensions/appointment-dimension';
import { BrandDimensionMeasures } from '../dimensions/brand-dimension';
import { PatientDimensionMeasures } from '../dimensions/patient-dimension';
import { PracticeDimensionMeasures } from '../dimensions/practice-dimension';
import { ReferrerDimensionMeasures } from '../dimensions/referrer-dimension';
import { SchedulingEventDimensionMeasures } from '../dimensions/scheduling-event-dimension';
import { SchedulingEventReasonDimensionMeasures } from '../dimensions/scheduling-event-reason-dimension';
import { StafferDimensionMeasures } from '../dimensions/staffer-dimension';
import { TreatmentPlanDimensionMeasures } from '../dimensions/treatment-plan-dimension';
import {
  CanBeChartedProperty,
  CanDoAllProperty,
  CanQueryByTimestampProperty,
  ComparableProperties,
  MeasureTransformMap,
  noFilterAccessor,
} from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { QueryFactory } from '../query-factory';
import { isBoolean } from 'lodash';

export class SchedulingEventFactMeasures
  extends BaseFactMeasures
  implements MeasureTransformMap<ComparableProperties<ISchedulingEventFact>>
{
  id = 'schedulingEventFact';
  readonly table = BigQueryTable.SchedulingEventFact;
  readonly name = 'Scheduling History';
  scopes = [new BrandQueryScopeConfig(), new DateRangeQueryScopeConfig()];

  get latestEvent(): SchedulingEventFactMeasures {
    const measures = new SchedulingEventFactMeasures();
    measures.id = latestEventId(measures);
    measures._query = QueryFactory.fromTable(measures.table)
      .override(measures._query)
      .latestEvent(measures.table, GroupBy.SchedulingEvent)
      .filterSoftDeleted(measures.schedulingEvent.measureRef('deleted'))
      .get();
    return measures;
  }

  // -- IBrandScoped Fact Properties

  get brand(): BrandDimensionMeasures {
    return new BrandDimensionMeasures(BigQueryTable.Brands, 'brand', {
      sourceJoinKey: MeasurePath.docRef('brand.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  // IPatientScopedFact Properties

  get patient(): PatientDimensionMeasures {
    return new PatientDimensionMeasures(BigQueryTable.Patients, 'patient', {
      sourceJoinKey: MeasurePath.docRef('patient.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get referrer(): ReferrerDimensionMeasures {
    return new ReferrerDimensionMeasures(this.table, 'referrer');
  }

  // -- IAppointmentScopedFact Properties

  get appointment(): AppointmentDimensionMeasures {
    return new AppointmentDimensionMeasures(
      BigQueryTable.Appointments,
      'appointment',
      {
        sourceJoinKey: MeasurePath.docRef('appointmentRef'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get treatmentPlan(): TreatmentPlanDimensionMeasures {
    return new TreatmentPlanDimensionMeasures(
      BigQueryTable.TreatmentPlans,
      'treatmentPlan',
      {
        sourceJoinKey: MeasurePath.docRef('treatmentPlan.ref'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  // -- ISchedulingEventFact Properties

  get schedulingEvent(): SchedulingEventDimensionMeasures {
    return new SchedulingEventDimensionMeasures(
      BigQueryTable.SchedulingEvents,
      'schedulingEvent',
      {
        sourceJoinKey: MeasurePath.docRef('schedulingEventRef'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get scheduledAt(): CanQueryByTimestampProperty {
    const propertyName = 'scheduledAt';
    const measure = this.measureRef(MeasurePath.timestamp(propertyName));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Date of Scheduling Event',
        summary:
          'The date that the appointment was scheduled/rescheduled/cancelled',
        formatter: MeasureFormatter.Timestamp,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get originalAppointmentDate(): CanQueryByTimestampProperty {
    const propertyName = 'movedFromStartTime';
    const measure = this.measureRef(MeasurePath.timestamp(propertyName));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Original Appointment Date',
        summary:
          'The date of the appointment before it was rescheduled/cancelled. This will exclude newly scheduled appointments.',
        formatter: MeasureFormatter.Day,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get newAppointmentDate(): CanQueryByTimestampProperty {
    const propertyName = 'movedToStartTime';
    const measure = this.measureRef(MeasurePath.timestamp(propertyName));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'New/Rescheduled Appointment Date',
        summary:
          'The date of the appointment after it was scheduled/rescheduled. This will exclude cancelled appointments.',
        formatter: MeasureFormatter.Day,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get scheduledByPractice(): PracticeDimensionMeasures {
    const dimension = new PracticeDimensionMeasures(
      BigQueryTable.Practices,
      'scheduledByPractice',
      {
        sourceJoinKey: MeasurePath.docRef('scheduledByPracticeRef'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
    dimension.name.setLabel('Scheduled By Practice');
    return dimension;
  }

  get scheduledByStaffer(): StafferDimensionMeasures {
    const dimension = new StafferDimensionMeasures(
      BigQueryTable.Staff,
      'scheduledByStaffer',
      {
        sourceJoinKey: MeasurePath.docRef('scheduledByStafferRef'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
    dimension.name.setLabel('Scheduled By Staffer');
    return dimension;
  }

  get movedFromPractice(): PracticeDimensionMeasures {
    const dimension = new PracticeDimensionMeasures(
      BigQueryTable.Practices,
      'movedFromPractice',
      {
        sourceJoinKey: MeasurePath.docRef('movedFromPracticeRef'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
    dimension.name.setLabel('Moved From Practice');
    return dimension;
  }

  get movedFromStaffer(): StafferDimensionMeasures {
    const dimension = new StafferDimensionMeasures(
      BigQueryTable.Staff,
      'movedFromStaffer',
      {
        sourceJoinKey: MeasurePath.docRef('movedFromStafferRef'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
    dimension.name.setLabel('Moved From Practitioner');
    return dimension;
  }

  get movedToPractice(): PracticeDimensionMeasures {
    const dimension = new PracticeDimensionMeasures(
      BigQueryTable.Practices,
      'movedToPractice',
      {
        sourceJoinKey: MeasurePath.docRef('movedToPracticeRef'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
    dimension.name.setLabel('Moved To Practice');
    return dimension;
  }

  get movedToStaffer(): StafferDimensionMeasures {
    const dimension = new StafferDimensionMeasures(
      BigQueryTable.Staff,
      'movedToStaffer',
      {
        sourceJoinKey: MeasurePath.docRef('movedToStafferRef'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
    dimension.name.setLabel('Moved To Practitioner');
    return dimension;
  }

  get reason(): SchedulingEventReasonDimensionMeasures {
    return new SchedulingEventReasonDimensionMeasures(
      BigQueryTable.SchedulingEventReasons,
      'reason',
      {
        sourceJoinKey: MeasurePath.docRef('reasonRef'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get practitionerWasChanged(): CanDoAllProperty {
    const propertyName = 'practitionerWasChanged';
    const movedFromMeasure = this.movedFromStaffer.name.measure;
    const movedToMeasure = this.movedToStaffer.name.measure;

    const dataAccessor = (fact: unknown): boolean => {
      const movedFrom = movedFromMeasure.dataAccessor(fact);
      const movedTo = movedToMeasure.dataAccessor(fact);
      return movedFrom !== movedTo;
    };

    return new CanDoAllProperty({
      metadata: {
        id: propertyName,
        label: 'Practitioner Was Changed',
        summary:
          'Whether the practitioner was changed during a reschedule operation or not.',
        formatter: MeasureFormatter.Boolean,
      },
      measure: {
        propertyName,
        query: this.buildQuery()
          .merge([movedFromMeasure.query, movedToMeasure.query])
          .get(),
        dataAccessor,
        filterAccessor: noFilterAccessor(),
      },
      groupMeasure: {
        queryAttributes: [],
        dataAccessor,
        labelAccessor: (fact) => {
          const value = dataAccessor(fact);
          return isBoolean(value) ? (value ? 'Yes' : 'No') : 'N/A';
        },
      },
    });
  }

  get practiceWasChanged(): CanDoAllProperty {
    const propertyName = 'practiceWasChanged';
    const movedFromMeasure = this.movedFromPractice.name.measure;
    const movedToMeasure = this.movedToPractice.name.measure;

    const dataAccessor = (fact: unknown): boolean => {
      const movedFrom = movedFromMeasure.dataAccessor(fact);
      const movedTo = movedToMeasure.dataAccessor(fact);
      return movedFrom !== movedTo;
    };

    return new CanDoAllProperty({
      metadata: {
        id: propertyName,
        label: 'Practice Was Changed',
        summary:
          'Whether the practice was changed during a reschedule operation or not.',
        formatter: MeasureFormatter.Boolean,
      },
      measure: {
        propertyName,
        query: this.buildQuery()
          .merge([movedFromMeasure.query, movedToMeasure.query])
          .get(),
        dataAccessor,
        filterAccessor: noFilterAccessor(),
      },
      groupMeasure: {
        queryAttributes: [],
        dataAccessor,
        labelAccessor: (fact) => {
          const value = dataAccessor(fact);
          return isBoolean(value) ? (value ? 'Yes' : 'No') : 'N/A';
        },
      },
    });
  }

  get eventBeforeDurationMins(): CanBeChartedProperty {
    const propertyName = 'eventBeforeDurationMins';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Original duration',
        summary:
          'The duration of the appointment before it was rescheduled/cancelled',
        formatter: MeasureFormatter.Minutes,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get eventAfterDurationMins(): CanBeChartedProperty {
    const propertyName = 'eventAfterDurationMins';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'New duration',
        summary:
          'The duration of the appointment after it was scheduled/rescheduled',
        formatter: MeasureFormatter.Minutes,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get eventDurationChanged(): CanBeChartedProperty {
    const propertyName = 'eventDurationChanged';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.boolean(
      {
        id: propertyName,
        label: 'Has Changed Duration',
        summary: 'Whether the duration of the appointment has changed or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get eventMovedForward(): CanBeChartedProperty {
    const propertyName = 'eventMovedForward';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.boolean(
      {
        id: propertyName,
        label: 'Has Moved Forward',
        summary: 'Whether the appointment was moved to an earlier time or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get eventMovedBackwards(): CanBeChartedProperty {
    const propertyName = 'eventMovedBackwards';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.boolean(
      {
        id: propertyName,
        label: 'Has Moved Backwards',
        summary: 'Whether the appointment was moved to a later time or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get eventPracticeChanged(): CanBeChartedProperty {
    const propertyName = 'eventPracticeChanged';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.boolean(
      {
        id: propertyName,
        label: 'Has Changed Practice',
        summary: 'Whether the practice of the appointment was changed or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get eventPractitionerChanged(): CanBeChartedProperty {
    const propertyName = 'eventPractitionerChanged';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.boolean(
      {
        id: propertyName,
        label: 'Has Changed Practitioner',
        summary:
          'Whether the practitioner of the appointment was changed or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get treatmentCost(): CanBeChartedProperty {
    const propertyName = 'treatmentCost';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Treatment Amount',
        summary:
          'Total amount of tretment cost for the appointmen including any treatment base prices. This is calculated at the time of scheduling event.',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get isFirstAppointment(): CanDoAllProperty {
    const propertyName = 'isFirstAppointment';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.boolean(
      {
        id: propertyName,
        label: 'Is First Appointment',
        summary: `Whether this is the patient's first appointment or not. This can also be used to filter to new/existing patients. This is calculated at the time of scheduling event.`,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }
}
