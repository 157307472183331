import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Actions, ofType } from '@ngrx/effects';
import {
  AppointmentSchedulingFacade,
  CancellationActions,
  SchedulingActions,
} from '@principle-theorem/ng-appointment/store';
import {
  CurrentScopeFacade,
  OrganisationService,
  ScheduleSummaryEventActionsService,
} from '@principle-theorem/ng-principle-shared';
import {
  Brand,
  Event,
  Roster,
  ScheduleSummary,
  TimezoneResolver,
  isSameEvent,
} from '@principle-theorem/principle-core';
import {
  DayDeadzoneMap,
  DayEventMap,
  DayGroupMap,
  DayNodesMap,
  DayRangeMap,
  EventType,
  GroupDeadzoneMap,
  GroupMap,
  GroupNodesMap,
  IDeadzone,
  IEventable,
  IScheduleSummaryEventable,
  ITimelineDataGroup,
  ITimelineDay,
  ITimelineNode,
  isAppointmentEventType,
  isCalendarEventType,
  isGapCandidateEvent,
  isGapEvent,
  type IAppointment,
  type IBrand,
  type IPractice,
  type IStaffer,
  isAppointmentSummary,
  AppointmentStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_FORMAT,
  TimeBucket,
  chunkRange,
  filterUndefined,
  getDayRange,
  getTimePeriodStartEnd,
  isPathChanged$,
  isRefChanged$,
  isSameIdentifier,
  isSameRef,
  mergeDayAndTime,
  multiReduce,
  reduceToSingleArray,
  reduceToSingleArrayFn,
  safeCombineLatest,
  timePeriodWithin,
  timePeriodsIntersect,
  toISODate,
  toMoment,
  toMomentRange,
  toMomentTz,
  toTimePeriod,
  toTimestamp,
  trimPeriodToDay,
  unserialise$,
  type IReffable,
  type ITimePeriod,
  type Timezone,
  type WithRef,
  DocumentReference,
  asDocRef,
  isChanged$,
} from '@principle-theorem/shared';
import {
  compact,
  differenceWith,
  flatten,
  groupBy,
  intersectionBy,
  isEmpty,
  keyBy,
  partition,
  sum,
  toPairs,
  uniqBy,
  uniqWith,
  values,
} from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import {
  determineViewRange,
  getDayViewRanges,
  getNodeTimesFromDay,
} from '../interactive-timeline/timeline-data-source';

export interface ITimelineLoadData {
  staff: WithRef<IStaffer>[];
  openingHours: ITimePeriod[];
  dateRange: ITimePeriod;
  showRosteredOffStaff: boolean;
  hideEmptyDays: boolean;
}

type EventLoadPayload = {
  dateRange: ITimePeriod;
  events: IScheduleSummaryEventable[];
  hideCancelledAppointments?: boolean;
  displayGaps?: boolean;
};

export interface IEventableTimelineState {
  dateRange?: ITimePeriod;
  timeRange?: ITimePeriod;
  events: DayEventMap<IScheduleSummaryEventable>;
  gapEvents: IScheduleSummaryEventable[];
  days: ITimelineDay<IScheduleSummaryEventable, WithRef<IStaffer>>[];
  deadzones: DayDeadzoneMap;
  loading: boolean;
  staff: DayGroupMap<WithRef<IStaffer>>;
  rosteredOnTimes: GroupMap<ITimePeriod[]>;
  openingHours: ITimePeriod[];
  nodePairs: DayNodesMap<IScheduleSummaryEventable, WithRef<IStaffer>>;
  dayViewRanges: DayRangeMap;
}

const initialState: IEventableTimelineState = {
  events: {},
  gapEvents: [],
  staff: {},
  rosteredOnTimes: {},
  deadzones: {},
  nodePairs: {},
  dayViewRanges: {},
  days: [],
  openingHours: [],
  loading: true,
};

@Injectable()
export class EventableTimelineStore extends ComponentStore<IEventableTimelineState> {
  private _actions$ = inject(Actions);
  private _schedulingFacade = inject(AppointmentSchedulingFacade);
  private _scheduleSummaryActions = inject(ScheduleSummaryEventActionsService);

  private _organisation = inject(OrganisationService);
  private _currentScope = inject(CurrentScopeFacade);
  readonly loading$ = this.select((state) => state.loading);
  readonly days$ = this.select((state) => state.days);
  readonly events$ = this.select((state) => state.events);
  readonly gapEvents$ = this.select((state) => state.gapEvents);
  readonly deadzones$ = this.select((state) => state.deadzones);
  readonly nodePairs$ = this.select((state) => state.nodePairs);
  readonly rosteredOnTimes$ = this.select((state) => state.rosteredOnTimes);
  readonly dayViewRanges$ = this.select((state) => state.dayViewRanges);
  readonly openingHours$ = this.select((state) => state.openingHours);
  readonly staff$ = this.select((state) => state.staff);
  readonly dateRange$ = this.select((state) => state.dateRange).pipe(
    filterUndefined()
  );
  readonly timeRange$ = this.select((state) => state.timeRange).pipe(
    filterUndefined()
  );
  readonly displayRange$ = combineLatest([
    this.dateRange$,
    this.timeRange$,
  ]).pipe(
    map(([dateRange, timeRange]) => ({
      dateRange,
      timeRange,
    }))
  );

  readonly loadEvents = this.updater(
    (
      state,
      {
        events,
        dateRange,
        hideCancelledAppointments,
        displayGaps,
      }: EventLoadPayload
    ) => ({
      ...state,
      dateRange,
      events: this._filterEventsInRange(
        dateRange,
        events,
        hideCancelledAppointments,
        displayGaps
      ),
      gapEvents: events.filter((event) => isGapEvent(event.event)),
    })
  );

  readonly load = this.effect((data$: Observable<ITimelineLoadData>) => {
    const brand$ = this._currentScope.currentBrand$.pipe(
      filterUndefined(),
      isRefChanged$()
    );

    const allStaff$ = brand$.pipe(
      switchMap((brand) => Brand.staff$(brand, true)),
      isChanged$(isSameRef)
    );

    return combineLatest([
      data$,
      brand$,
      this._currentScope.currentPractice$.pipe(
        filterUndefined(),
        isPathChanged$('ref.path')
      ),
      this._organisation.practicePractitioners$.pipe(isChanged$(isSameRef)),
      allStaff$,
    ]).pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap(([data, brand, practice, practicePractitioners, allStaff]) =>
        combineLatest([
          this._getStaffRosteredOnTimes$(
            data.dateRange,
            brand,
            practice,
            data.showRosteredOffStaff ? practicePractitioners : data.staff
          ),
          this.events$,
          this.gapEvents$,
        ]).pipe(
          switchMap(async ([rosteredOnTimes, events, gapEvents]) => {
            const staff = this._getUniqueStaff(
              data.dateRange,
              data.staff,
              rosteredOnTimes,
              data.showRosteredOffStaff,
              events,
              allStaff,
              data.hideEmptyDays
            );

            const nodePairs = await this._getNodeGroupPairs(
              events,
              staff,
              rosteredOnTimes,
              gapEvents
            );

            const deadzones = this._resolveDeadzones(
              staff,
              data.dateRange,
              nodePairs,
              rosteredOnTimes,
              practice
            );

            const timeRange = this._getTimelineViewRange(
              nodePairs,
              data.openingHours,
              rosteredOnTimes,
              practice.settings.timezone
            );

            const dayViewRanges = getDayViewRanges({
              nodeRanges: getNodeTimesFromDay(nodePairs),
              openingHours: data.openingHours,
              staffRosteredOnTimes: compact(flatten(values(rosteredOnTimes))),
            });

            const days = this._buildDayGroups(
              timeRange,
              data.dateRange,
              staff,
              deadzones,
              nodePairs,
              dayViewRanges,
              data.staff,
              data.hideEmptyDays
            );

            return {
              staff,
              rosteredOnTimes,
              deadzones,
              nodePairs,
              dayViewRanges,
              days,
              timeRange,
              openingHours: data.openingHours,
              dateRange: data.dateRange,
            };
          })
        )
      ),
      tapResponse(
        (data) =>
          this.patchState({
            loading: false,
            ...data,
          }),
        // eslint-disable-next-line no-console
        console.error
      )
    );
  });

  constructor() {
    super(initialState);

    // add event on appointment creation
    this._actions$
      .pipe(
        ofType(SchedulingActions.saveNewAppointmentSuccess),
        map((action) => action.appointment),
        unserialise$(),
        switchMap((appointment) =>
          ScheduleSummary.toSummaryEvent(
            appointment.ref as DocumentReference<IAppointment>,
            appointment as IEventable<IAppointment>
          )
        ),
        filterUndefined(),
        takeUntil(this.destroy$)
      )
      .subscribe((event) => {
        if (!event) {
          return;
        }
        this.addEvent({
          ...event,
          uid: event.ref.id,
        });
      });

    // update events on appointment reschedule from sidebar
    this._actions$
      .pipe(
        ofType(SchedulingActions.rescheduleAppointmentSuccess),
        map((action) => action.appointment),
        unserialise$(),
        switchMap((appointment) =>
          ScheduleSummary.toSummaryEvent(
            appointment.ref as DocumentReference<IAppointment>,
            appointment as IEventable<IAppointment>
          )
        ),
        withLatestFrom(
          this._scheduleSummaryActions.selectedAppointmentSummary$
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(([newSummary, oldSummary]) => {
        if (!newSummary || !oldSummary) {
          return;
        }
        this.updateEvent(oldSummary, {
          ...newSummary,
          uid: newSummary.ref.id,
        });
        this._scheduleSummaryActions.reset();
      });

    // remove event on appointment cancel
    this._actions$
      .pipe(
        ofType(CancellationActions.cancelAppointment),
        unserialise$(),
        withLatestFrom(
          this._schedulingFacade.currentAppointment$.pipe(filterUndefined())
        ),
        switchMap(([_action, appointment]) =>
          ScheduleSummary.toSummaryEvent(
            appointment.ref,
            appointment as IEventable<IAppointment>
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((event) => {
        if (!event) {
          return;
        }
        this.deleteEvent({
          ...event,
          uid: event.ref.id,
        });
      });

    this._scheduleSummaryActions.updateAppointmentSummary$
      .pipe(filterUndefined(), takeUntil(this.destroy$))
      .subscribe(({ oldAppointment, newAppointment }) => {
        if (!oldAppointment || !newAppointment) {
          return;
        }
        this.updateEvent(oldAppointment, newAppointment);
        this._scheduleSummaryActions.reset();
      });

    this._scheduleSummaryActions.addCalendarSummary$
      .pipe(filterUndefined(), takeUntil(this.destroy$))
      .subscribe((event) => {
        this.addEvent(event);
        this._scheduleSummaryActions.reset();
      });

    this._scheduleSummaryActions.removeCalendarSummary$
      .pipe(filterUndefined(), takeUntil(this.destroy$))
      .subscribe((event) => {
        this.deleteEvent(event);
        this._scheduleSummaryActions.reset();
      });

    this._scheduleSummaryActions.updateCalendarSummary$
      .pipe(filterUndefined(), takeUntil(this.destroy$))
      .subscribe((event) => {
        if (!event.oldEvent || !event.newEvent) {
          return;
        }
        this.updateEvent(event.oldEvent, event.newEvent);
        this._scheduleSummaryActions.reset();
      });
  }

  addEvent<T extends object>(
    event: IScheduleSummaryEventable<T>,
    patchState = true,
    currentEvents?: DayEventMap<IScheduleSummaryEventable>
  ): DayEventMap<IScheduleSummaryEventable> {
    const events = currentEvents ?? this.get().events;
    const eventKey = toISODate(event.event.from);
    const existingEvents = events[eventKey] ?? [];
    const combinedEvents = uniqWith(
      [...existingEvents, event],
      this._isSameScheduleSummaryEventable
    );
    const updatedEvents = {
      ...events,
      [eventKey]: combinedEvents,
    };
    if (patchState) {
      this.patchState({ events: updatedEvents });
    }
    return updatedEvents;
  }

  deleteEvent<T extends object>(
    event: IScheduleSummaryEventable<T>,
    patchState = true,
    currentEvents?: DayEventMap<IScheduleSummaryEventable>
  ): DayEventMap<IScheduleSummaryEventable> {
    const events = currentEvents ?? this.get().events;
    const eventKey = toISODate(event.event.from);
    const updatedEvents = {
      ...events,
      [eventKey]:
        events[eventKey]?.filter(
          (existingEvent) => !isSameIdentifier(existingEvent, event)
        ) ?? [],
    };
    if (patchState) {
      this.patchState({ events: updatedEvents });
    }
    return updatedEvents;
  }

  updateEvent<T extends object>(
    oldEvent: IScheduleSummaryEventable<T>,
    newEvent: IScheduleSummaryEventable<T>
  ): void {
    let events = this.get().events;
    events = this.deleteEvent(oldEvent, false, events);
    events = this.addEvent(newEvent, false, events);
    this.patchState({ events });
  }

  getGroupIdentifier(staffer: IReffable<IStaffer>): string {
    return staffer.ref.path;
  }

  sortGroups(
    groups: ITimelineDataGroup<IScheduleSummaryEventable, WithRef<IStaffer>>[],
    staffOrder: WithRef<IStaffer>[]
  ): ITimelineDataGroup<IScheduleSummaryEventable, WithRef<IStaffer>>[] {
    const sortedGroups = compact(
      staffOrder.map((stafferRef) =>
        groups.find((group) => isSameRef(group.group, stafferRef))
      )
    );
    const unsortedGroups = differenceWith(
      groups,
      sortedGroups,
      (groupA, groupB) => isSameRef(groupA.group, groupB.group)
    );
    return [...sortedGroups, ...unsortedGroups];
  }

  resolveGroups(
    event: IScheduleSummaryEventable,
    staffMap: GroupMap<WithRef<IStaffer>>
  ): WithRef<IStaffer>[] {
    return compact(
      Event.staff(event.event).map(
        (participant) => staffMap[participant.ref.path]
      )
    );
  }

  async toTimelineNode(
    event: IScheduleSummaryEventable,
    staffRosteredOnTimes: GroupMap<ITimePeriod[]>,
    dayKey: string
  ): Promise<ITimelineNode<IScheduleSummaryEventable> | undefined> {
    const timezone = await TimezoneResolver.fromEvent(event);
    const timePeriod = toTimePeriod(
      toMomentTz(event.event.from, timezone),
      toMomentTz(event.event.to, timezone)
    );

    const isMultiDayEvent = !timePeriod.from.isSame(timePeriod.to, 'day');
    const isSingleParticipant = event.event.participants.length === 1;
    const isInteractableEvent =
      isCalendarEventType(event.event.type) &&
      isSingleParticipant &&
      !isMultiDayEvent;

    const interactionEnabled =
      isAppointmentEventType(event.event.type) || isInteractableEvent;

    if (isMultiDayEvent) {
      const day = moment.tz(dayKey, ISO_DATE_FORMAT, timezone);
      const stafferRosterOn = compact(
        flatten(
          Event.staff(event.event).map(
            (staffer) => staffRosteredOnTimes[this.getGroupIdentifier(staffer)]
          )
        )
      );

      const trimRange = {
        from: moment(day).startOf('day'),
        to: moment(day).endOf('day'),
      };

      if (timePeriod.to.isSame(day, 'day')) {
        trimRange.to = timePeriod.to;
      }
      if (timePeriod.from.isSame(day, 'day')) {
        trimRange.from = timePeriod.from;
      }

      const eventLimit = new TimeBucket(stafferRosterOn)
        .trim(trimRange.from, trimRange.to)
        .merge();

      if (!eventLimit) {
        return;
      }

      return {
        data: event,
        from: eventLimit.from,
        to: eventLimit.to,
        dragEnabled: interactionEnabled,
        resizeEnabled: interactionEnabled,
      };
    }

    return {
      data: event,
      from: timePeriod.from,
      to: timePeriod.to,
      dragEnabled: interactionEnabled,
      resizeEnabled: interactionEnabled,
    };
  }

  private _filterEventsInRange(
    range: ITimePeriod,
    events: IScheduleSummaryEventable[],
    hideCancelledAppointments = false,
    displayGaps = false
  ): DayEventMap<IScheduleSummaryEventable> {
    const eventsWithRange = events
      .filter((event) => {
        if (!hideCancelledAppointments || !isAppointmentSummary(event)) {
          return true;
        }
        return event.metadata.status !== AppointmentStatus.Cancelled;
      })
      .filter((event) =>
        displayGaps
          ? !isGapCandidateEvent(event.event)
          : !isGapEvent(event.event)
      )
      .map((event) => ({
        ...event,
        range: toMomentRange(event.event.from, event.event.to),
      }));

    return chunkRange(range, 1, 'day')
      .map((day) => {
        const dayRange = getDayRange(day.from);
        const filteredEvents = eventsWithRange.filter((event) => {
          return timePeriodsIntersect(dayRange, event.range, false, 'minutes');
        });

        return {
          [day.from.format(ISO_DATE_FORMAT)]: filteredEvents.map(
            (event) => event
          ),
        };
      })
      .reduce((allEvents, dayEvents) => ({ ...allEvents, ...dayEvents }), {});
  }

  private _getUniqueStaff(
    dateRange: ITimePeriod,
    selectedStaff: WithRef<IStaffer>[],
    rosteredOnTimes: DayEventMap<ITimePeriod>,
    showRosteredOffStaff: boolean,
    events: DayEventMap<IEventable | WithRef<IEventable>>,
    allStaff: WithRef<IStaffer>[],
    hideEmptyDays: boolean
  ): DayGroupMap<WithRef<IStaffer>> {
    return chunkRange(dateRange, 1, 'day')
      .map((day) => {
        const formattedDay = day.from.format(ISO_DATE_FORMAT);
        const staffWithEvents = this._getStaffWithEvents(allStaff, day, events);
        const selectedWithEvents = intersectionBy(
          selectedStaff,
          staffWithEvents,
          (staffer) => staffer.ref.path
        );
        const disabledStaff = staffWithEvents.filter(
          (staffer) => !!staffer.deleted
        );
        const selectedAndRostered = selectedStaff.filter((staffer) =>
          Roster.isRosteredOn(rosteredOnTimes[staffer.ref.path] ?? [], day.from)
        );

        const uniqueStaff = uniqWith(
          showRosteredOffStaff
            ? [...selectedStaff, ...disabledStaff]
            : [...selectedAndRostered, ...selectedWithEvents, ...disabledStaff],
          isSameRef
        );

        if (!uniqueStaff.length) {
          uniqueStaff.push(...selectedStaff);
        }

        const isEmptyDay =
          !this._getStaffWithEvents(uniqueStaff, day, events).length &&
          !selectedAndRostered.length;

        return {
          [formattedDay]: hideEmptyDays && isEmptyDay ? [] : uniqueStaff,
        };
      })
      .reduce((allGroups, group) => ({ ...allGroups, ...group }), {});
  }

  private _buildDayGroups(
    timeRange: ITimePeriod | undefined,
    searchRange: ITimePeriod,
    groups: DayGroupMap<WithRef<IStaffer>>,
    deadzoneMap: DayDeadzoneMap,
    nodesMap: DayNodesMap<IScheduleSummaryEventable, WithRef<IStaffer>>,
    dayViewRanges: DayRangeMap,
    staffOrder: WithRef<IStaffer>[],
    hideEmptyDays: boolean
  ): ITimelineDay<IScheduleSummaryEventable, WithRef<IStaffer>>[] {
    if (!timeRange) {
      return [];
    }
    const rangeChunks = chunkRange(searchRange, 1, 'day');

    const rangeChunkMap = keyBy(rangeChunks, (chunk) =>
      chunk.from.format(ISO_DATE_FORMAT)
    );

    return toPairs(rangeChunkMap).reduce<
      ITimelineDay<IScheduleSummaryEventable, WithRef<IStaffer>>[]
    >((builtDayGroups, [dayKey, date]) => {
      const isClosed = dayViewRanges[dayKey] === undefined;
      const dayNodes = isClosed ? {} : nodesMap[dayKey] ?? {};
      const isEmptyDay = isEmpty(dayNodes) && isEmpty(groups[dayKey]);

      if (hideEmptyDays && isEmptyDay) {
        return builtDayGroups;
      }

      const groupsForDay = groups[dayKey] ?? [];
      const dayGroups =
        isClosed || !groupsForDay.length ? staffOrder : groupsForDay;

      const dayRange = {
        from: mergeDayAndTime(date.from, timeRange.from),
        to: mergeDayAndTime(date.from, timeRange.to),
      };

      const dataGroups = this._buildDataGroups(
        dayGroups,
        deadzoneMap[dayKey] ?? {},
        dayNodes,
        dayRange
      );

      return [
        ...builtDayGroups,
        {
          day: getDayRange(date.from),
          isClosed,
          initialTrackIndex: this._countTrackIndexes(builtDayGroups),
          groups: this.sortGroups(dataGroups, staffOrder),
        },
      ];
    }, []);
  }

  private _countTrackIndexes(
    dayGroups: ITimelineDay<IScheduleSummaryEventable, WithRef<IStaffer>>[]
  ): number {
    return sum(
      flatten(
        dayGroups.map((dayGroup) =>
          dayGroup.groups.map((group) =>
            group.nodes.length > 1 ? group.nodes.length : 1
          )
        )
      )
    );
  }

  private _buildDataGroups(
    groups: WithRef<IStaffer>[],
    deadzoneMap: GroupDeadzoneMap,
    nodesMap: GroupNodesMap<IScheduleSummaryEventable, WithRef<IStaffer>>,
    timeRange: ITimePeriod
  ): ITimelineDataGroup<IScheduleSummaryEventable, WithRef<IStaffer>>[] {
    const dataGroups: Record<
      string,
      ITimelineDataGroup<IScheduleSummaryEventable, WithRef<IStaffer>>
    > = keyBy(
      groups.map((group) => ({ group, nodes: [], deadzones: [] })),
      'group.ref.path'
    );

    toPairs(nodesMap).map(([groupUid, nodes]) => {
      if (!dataGroups[groupUid]) {
        return;
      }
      dataGroups[groupUid].nodes = this._divideIntoTracks(
        compact(nodes).map((node) => node.node)
      );
    });
    toPairs(deadzoneMap).map(([groupUid, deadzones]) => {
      if (!dataGroups[groupUid]) {
        return;
      }

      const blockingDeadzones = new TimeBucket(
        compact(deadzones).filter((deadzone) => deadzone.isBlocking)
      )
        .trim(timeRange.from, timeRange.to)
        .mergeOverlapping()
        .get()
        .map((deadzone) => ({
          ...deadzone,
          isBlocking: true,
        }));

      const nonBlockingDeadzones = new TimeBucket(
        compact(deadzones).filter((deadzone) => !deadzone.isBlocking)
      )
        .trim(timeRange.from, timeRange.to)
        .mergeOverlapping()
        .get()
        .map((deadzone) => ({
          ...deadzone,
          isBlocking: false,
        }));

      dataGroups[groupUid].deadzones.push(
        ...blockingDeadzones,
        ...nonBlockingDeadzones
      );
    });

    return values(dataGroups);
  }

  private _divideIntoTracks(
    nodes: ITimelineNode<IScheduleSummaryEventable>[]
  ): ITimelineNode<IScheduleSummaryEventable>[][] {
    return nodes.reduce(
      (acc: ITimelineNode<IScheduleSummaryEventable>[][], node) => {
        const foundTrack = acc.find((track) => this._canGoOnTrack(track, node));
        if (foundTrack) {
          foundTrack.push(node);
          return acc;
        }
        return [...acc, [node]];
      },
      []
    );
  }

  private _canGoOnTrack(
    track: ITimelineNode<IScheduleSummaryEventable>[],
    node: ITimelineNode<IScheduleSummaryEventable>
  ): boolean {
    return track.every(
      (trackNode) => !timePeriodsIntersect(trackNode, node, false, 'minute')
    );
  }

  private _getStaffRosteredOnTimes$(
    searchRange: ITimePeriod,
    brand: WithRef<IBrand>,
    practice: WithRef<IPractice>,
    staff: WithRef<IStaffer>[]
  ): Observable<GroupMap<ITimePeriod[]>> {
    return safeCombineLatest(
      staff.map((staffer) =>
        Roster.rosteredTimes$(staffer, searchRange, brand, practice).pipe(
          map((rosteredTimes) => ({
            [this.getGroupIdentifier(staffer)]: rosteredTimes,
          }))
        )
      )
    ).pipe(
      multiReduce((mapped, currentMap) => ({ ...mapped, ...currentMap }), {})
    );
  }

  private _resolveDeadzones(
    staffMap: DayGroupMap<WithRef<IStaffer>>,
    range: ITimePeriod,
    nodePairs: DayNodesMap<IScheduleSummaryEventable, WithRef<IStaffer>>,
    rosteredOnTimes: Partial<Record<string, ITimePeriod[]>>,
    practice: WithRef<IPractice>
  ): DayDeadzoneMap {
    const staff = uniqBy(
      compact(flatten(Object.values(staffMap))),
      (staffer) => staffer.ref.path
    );

    const deadzones = chunkRange(range, 1, 'day').map((day) => {
      const newRange = getTimePeriodStartEnd(
        range,
        practice.settings.timezone,
        'day'
      );

      const stafferDeadzoneMap = staff
        .map((staffer) => {
          const stafferIdentifier = this.getGroupIdentifier(staffer);
          const rosteredOffTimes = getRosteredOffTimesForDay(
            rosteredOnTimes,
            stafferIdentifier,
            newRange,
            range,
            day
          );
          const blockingEvents = getBlockingEventsForDay(
            nodePairs,
            day,
            stafferIdentifier
          );

          return {
            [stafferIdentifier]: [...rosteredOffTimes, ...blockingEvents],
          };
        })
        .reduce((allGroups, group) => ({ ...allGroups, ...group }), {});

      return {
        [day.from.format(ISO_DATE_FORMAT)]: stafferDeadzoneMap,
      };
    });

    return deadzones.reduce(
      (allGroups, group) => ({
        ...allGroups,
        ...group,
      }),
      {}
    );
  }

  private async _getNodeGroupPairs(
    dayEvents: DayEventMap<IScheduleSummaryEventable>,
    dayGroupMap: DayGroupMap<WithRef<IStaffer>>,
    staffRosteredOnTimes: GroupMap<ITimePeriod[]>,
    gapEvents: IScheduleSummaryEventable[]
  ): Promise<DayNodesMap<IScheduleSummaryEventable, WithRef<IStaffer>>> {
    const dayPromises = toPairs(dayEvents).map(async ([dayKey, events]) => ({
      [dayKey]: await this._getDayNodeGroups(
        dayKey,
        dayGroupMap[dayKey] ?? [],
        compact(events),
        staffRosteredOnTimes,
        gapEvents
      ),
    }));
    const dayResults = await Promise.all(dayPromises);
    return dayResults.reduce((allDays, day) => ({ ...allDays, ...day }), {});
  }

  private async _getDayNodeGroups(
    dayKey: string,
    groups: WithRef<IStaffer>[],
    events: IScheduleSummaryEventable[],
    staffRosteredOnTimes: GroupMap<ITimePeriod[]>,
    gapEvents: IScheduleSummaryEventable[]
  ): Promise<GroupNodesMap<IScheduleSummaryEventable, WithRef<IStaffer>>> {
    const eventsWithMergedGapCandidates = this._mergeOverlappingGapCandidates(
      events,
      gapEvents
    );

    const groupMap = keyBy(groups, (group) => this.getGroupIdentifier(group));

    const dayNodes = compact(
      await Promise.all(
        eventsWithMergedGapCandidates.map((event) =>
          this.toTimelineNode(event, staffRosteredOnTimes, dayKey)
        )
      )
    );

    const allNodes = dayNodes
      .map((node) =>
        this.resolveGroups(node.data, groupMap).map((group) => ({
          node,
          group,
        }))
      )
      .reduce(reduceToSingleArrayFn, []);

    return groupBy(allNodes, (nodePair) =>
      this.getGroupIdentifier(nodePair.group)
    );
  }

  private _mergeOverlappingGapCandidates(
    events: IScheduleSummaryEventable[],
    gapEvents: IScheduleSummaryEventable[]
  ): IScheduleSummaryEventable[] {
    const [gapCandidates, otherEvents] = partition(events, ({ event }) =>
      isGapCandidateEvent(event)
    );

    const candidatesByStaffer = groupBy(
      gapCandidates,
      (candidate) => Event.staff(candidate.event)[0].ref.path
    );

    const mergeResults = Object.entries(candidatesByStaffer).flatMap(
      ([stafferPath, candidates]) => {
        const mergedTimePeriods = TimeBucket.fromEvents(
          candidates.map(({ event }) => event)
        )
          .mergeOverlapping()
          .get();

        return mergedTimePeriods.map((mergedPeriod) => {
          const candidatesWithinPeriod = candidates.filter(({ event }) =>
            timePeriodWithin(Event.toTimePeriod(event), mergedPeriod, true)
          );

          if (candidatesWithinPeriod.length === 1) {
            return candidatesWithinPeriod[0];
          }

          return this._buildMergedEvent(
            gapEvents,
            mergedPeriod,
            asDocRef<IStaffer>(stafferPath)
          );
        });
      }
    );

    return compact([...otherEvents, ...mergeResults]);
  }

  private _buildMergedEvent(
    gapEvents: IScheduleSummaryEventable[],
    timePeriod: ITimePeriod,
    staffer: DocumentReference<IStaffer>
  ): IScheduleSummaryEventable | undefined {
    const foundGap = gapEvents.find(
      (gap) =>
        timePeriodWithin(timePeriod, Event.toTimePeriod(gap.event), true) &&
        isSameRef(Event.staff(gap.event)[0], staffer)
    );
    if (!foundGap) {
      return;
    }

    return {
      ...foundGap,
      event: {
        ...foundGap.event,
        from: toTimestamp(timePeriod.from),
        to: toTimestamp(timePeriod.to),
        type: EventType.GapCandidate,
      },
      metadata: {
        ...foundGap.metadata,
        label: 'Gap Candidate',
      },
      isBlocking: true,
    };
  }

  private _getTimelineViewRange(
    nodePairs: DayNodesMap<IScheduleSummaryEventable, WithRef<IStaffer>>,
    openingHours: ITimePeriod[],
    groups: GroupMap<ITimePeriod[]>,
    timezone: Timezone
  ): ITimePeriod | undefined {
    const nodeRanges = getNodeTimesFromDay(nodePairs);
    const staffRosteredOnTimes = compact(flatten(values(groups)));

    return determineViewRange({
      nodeRanges,
      openingHours,
      staffRosteredOnTimes,
      timezone,
    });
  }

  private _getStaffWithEvents(
    staff: WithRef<IStaffer>[],
    day: ITimePeriod,
    events: DayEventMap<IEventable | WithRef<IEventable>>
  ): WithRef<IStaffer>[] {
    const staffMap = keyBy(staff, (staffer) => staffer.ref.id);

    const staffWithEvents = uniqWith(
      reduceToSingleArray(
        (events[day.from.format(ISO_DATE_FORMAT)] ?? [])
          .filter((event) => toMoment(event.event.from).isSame(day.from, 'day'))
          .map((event) => Event.staff(event.event))
      ),
      isSameRef
    );

    return compact(staffWithEvents.map((staffer) => staffMap[staffer.ref.id]));
  }

  private _isSameScheduleSummaryEventable(
    aSummary: IScheduleSummaryEventable,
    bSummary: IScheduleSummaryEventable
  ): boolean {
    return (
      aSummary.uid === bSummary.uid ||
      isSameEvent(aSummary.event, bSummary.event)
    );
  }
}

function getRosteredOffTimesForDay(
  rosteredOnTimes: Partial<Record<string, ITimePeriod[]>>,
  stafferIdentifier: string,
  newRange: ITimePeriod,
  range: ITimePeriod,
  day: ITimePeriod
): IDeadzone[] {
  return compact(
    new TimeBucket(
      new TimeBucket(rosteredOnTimes[stafferIdentifier] ?? [])
        .mergeOverlapping()
        .trim(newRange.from, newRange.to)
        .get()
    )
      .invertByRange(range.from, range.to)
      .get()
      .map((time) => {
        const timePeriod = trimPeriodToDay(time, day.from, newRange);
        if (!timePeriod) {
          return;
        }
        return {
          from: timePeriod.from,
          to: timePeriod.to,
          isBlocking: false,
        };
      })
  );
}

function getBlockingEventsForDay(
  nodePairs: DayNodesMap<IScheduleSummaryEventable, WithRef<IStaffer>>,
  day: ITimePeriod,
  stafferIdentifier: string
): IDeadzone[] {
  const dayNodes = nodePairs[day.from.format(ISO_DATE_FORMAT)];
  if (!dayNodes) {
    return [];
  }
  return (dayNodes[stafferIdentifier] ?? [])
    .filter(
      (node) =>
        isAppointmentEventType(node.node.data.event.type) ||
        (isCalendarEventType(node.node.data.event.type) &&
          node.node.data.isBlocking)
    )
    .map((node) => ({
      from: node.node.from,
      to: node.node.to,
      isBlocking: true,
    }));
}
