import {
  IAutomatedNotification,
  IAutomatedNotificationConfiguration,
} from '../notification/automated-notification';
import {
  IGeneratedTask,
  IGeneratedTaskConfiguration,
} from '../task/generated-task';
import {
  IAutomatedFormIssue,
  IAutomatedFormIssueConfiguration,
} from './automated-form-issue';

export type AnyAutomationConfiguration =
  | IAutomatedNotificationConfiguration
  | IGeneratedTaskConfiguration
  | IAutomatedFormIssueConfiguration;

export type AnyAutomation =
  | IAutomatedNotification
  | IGeneratedTask
  | IAutomatedFormIssue;
