import { type IDestination } from '@principle-theorem/principle-core/interfaces';
import { BaseDestinationHandler } from '../destination/base-destination-handler';
import { PatientDestinationEntity } from './destination/entities/patients';
import { StafferDestinationEntity } from './destination/entities/staff';
import { FeeScheduleDestinationEntity } from './destination/entities/fee-schedules';
import { PatientInteractionDestinationEntity } from './destination/entities/patient-interactions';
import { PatientClinicalNoteDestinationEntity } from './destination/entities/patient-clinical-notes';
import { PatientAppointmentDestinationEntity } from './destination/entities/patient-appointments';
import { PatientTreatmentPlanDestinationEntity } from './destination/entities/patient-treatment-plans';
import { PatientInvoicesDestinationEntity } from './destination/entities/patient-invoices';
import { PatientClinicalChartDestinationEntity } from './destination/entities/patient-clinical-charts';
import { PatientDocumentDestinationEntity } from './destination/entities/patient-documents';
import { PatientXrayDestinationEntity } from './destination/entities/patient-xrays';
import { PatientRelationshipDestinationEntity } from './destination/entities/patient-relationships';
import { ExistingPatientDestinationEntity } from './destination/entities/existing-patients';

export const OASIS_DESTINATION: IDestination = {
  metadata: {
    name: 'Principle Firestore',
    description: 'Main Principle Migration',
  },
  configuration: [],
};

export class OasisDestinationHandler extends BaseDestinationHandler {
  migrationType = 'OASiS';
  migrationVersion = 'v1';
  destination = OASIS_DESTINATION;

  entityHandlers = [
    new ExistingPatientDestinationEntity(),
    new FeeScheduleDestinationEntity(),
    new PatientAppointmentDestinationEntity(),
    new PatientDestinationEntity(),
    new PatientClinicalNoteDestinationEntity(),
    new PatientInteractionDestinationEntity(),
    new StafferDestinationEntity(),
    new PatientTreatmentPlanDestinationEntity(),
    new PatientInvoicesDestinationEntity(),
    new PatientClinicalChartDestinationEntity(),
    new PatientDocumentDestinationEntity(),
    new PatientXrayDestinationEntity(),
    new PatientRelationshipDestinationEntity(),
  ];
}
