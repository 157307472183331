export enum ImageSize {
  Profile = '_200x200',
  Card = '_320x320',
  Medium = '_640x640',
  Large = '_1280x1280',
}

export function getImageAtSize(
  imagePath: string,
  size: ImageSize,
  extension?: string
): string {
  const pathArr = imagePath.split('.');

  if (!extension) {
    pathArr.splice(-1, 0, size, '.');
    return pathArr.join('');
  }

  pathArr.pop();
  const fileName = pathArr.join('.');
  return [fileName, size, '.', extension].join('');
}
