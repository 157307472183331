import { filterUndefined } from '@principle-theorem/shared';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { diff, eq } from 'semver';

export enum MismatchType {
  Major = 'major',
  Minor = 'minor',
}

export class VersionBloc {
  onMismatch$: Observable<MismatchType>;

  constructor(
    public local$: Observable<string>,
    public remote$: Observable<string>
  ) {
    this.onMismatch$ = combineLatest([this.local$, this.remote$]).pipe(
      map(([local, remote]) => {
        if (diff(local, remote) === 'major') {
          return MismatchType.Major;
        }
        if (!eq(local, remote)) {
          return MismatchType.Minor;
        }
      }),
      filterUndefined()
    );
  }
}
