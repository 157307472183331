import { NgModule } from '@angular/core';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { EzdentFeatureService } from './ezdent-feature.service';
import { EzdentTriggerXRayComponent } from './ezdent-trigger-x-ray/ezdent-trigger-x-ray.component';

@NgModule({
  declarations: [EzdentTriggerXRayComponent],
  imports: [NgSharedModule],
  providers: [EzdentFeatureService],
})
export class EzdentModule {}
