import { Media, Patient } from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  ISourceEntityRecord,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IMedia,
  type IPatient,
  type IPracticeMigration,
  type ISource,
  type MergeConflictDestinationEntityRecord,
  IDestinationEntityJobRunOptions,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  asyncForEach,
  getError,
  toTimestamp,
  type DocumentReference,
  type Timezone,
  type WithRef,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { compact, groupBy, uniq } from 'lodash';
import * as moment from 'moment-timezone';
import { from, type Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  CopyFiles,
  getBucketStoragePath,
  type IStorageSyncResponse,
} from '../../../storage';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientSourceEntity,
  type IPraktikaPatient,
  type IPraktikaPatientFilters,
  type IPraktikaPatientTranslations,
} from '../../source/entities/patient';
import {
  PatientImageSourceEntity,
  type IPraktikaPatientImage,
  type IPraktikaPatientImageFilters,
  type IPraktikaPatientImageTranslations,
} from '../../source/entities/patient-image';
import { PatientDestinationEntity } from './patients';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';

export const PATIENT_FILE_RESOURCE_TYPE = 'patientFiles';

export const PATIENT_FILE_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_FILE_RESOURCE_TYPE,
    label: 'Patient Files',
    description: '',
  },
});

export interface IPatientFileDestinationRecord {
  fileRefs: DocumentReference<IMedia>[];
}

export interface IPatientFileJobData {
  sourcePatient: IGetRecordResponse<
    IPraktikaPatient,
    IPraktikaPatientTranslations,
    IPraktikaPatientFilters
  >;
  files: IFileSummary[];
}

interface IFileSummary {
  patientId: string;
  fileName: string;
  folderPath: string;
}

export interface IPatientFileMigrationData {
  sourcePatientId: string;
  patientRef: DocumentReference<IPatient>;
  images: IGetRecordResponse<
    IPraktikaPatientImage,
    IPraktikaPatientImageTranslations,
    IPraktikaPatientImageFilters
  >[];
  files: IFileSummary[];
}

export class PatientFileDestinationEntity extends BaseDestinationEntity<
  IPatientFileDestinationRecord,
  IPatientFileJobData,
  IPatientFileMigrationData
> {
  destinationEntity = PATIENT_FILE_DESTINATION_ENTITY;

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    patientImages: new PatientImageSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  sourceCountDataAccessor(
    data: IPatientFileJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientFileJobData[]> {
    const patientFiles$ = from(
      groupImagesByPatient(
        migration.configuration.projectId,
        getBucketStoragePath(migration.source),
        ''
      )
    );

    return patientFiles$.pipe(
      concatMap((patientFiles) => {
        return this.buildSourceRecordQuery$(
          migration,
          this.sourceEntities.patients,
          runOptions
        ).pipe(
          map((sourcePatients) =>
            sourcePatients.map((sourcePatient) => ({
              sourcePatient,
              files:
                patientFiles[sourcePatient.data.data.patient_id.toString()] ||
                [],
            }))
          )
        );
      })
    );
  }

  getDestinationEntityRecordUid(data: IPatientFileJobData): string {
    return data.sourcePatient.record.uid;
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientFileJobData
  ): Promise<
    | IPatientFileMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();
    const images = await this.sourceEntities.patientImages.filterRecords(
      migration,
      'patientId',
      sourcePatientId
    );

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `Couldn't resolve patient`
      );
    }

    return {
      sourcePatientId,
      patientRef,
      images,
      files: data.files,
    };
  }

  hasMergeConflict(
    _translationMap: TranslationMapHandler,
    _data: IPatientFileMigrationData
  ): IPatientFileMigrationData | undefined {
    return;
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientFileJobData,
    _migrationData: IPatientFileMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  async runJob(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    data: IPatientFileJobData,
    migrationData: IPatientFileMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const fileRefs = await this._addMediaToPatient(
        migrationData.sourcePatientId,
        migrationData.patientRef,
        migrationData.images,
        migrationData.files,
        translationMapHandler,
        migration.source,
        migration.configuration.projectId,
        migration.configuration.destinationBucket,
        migration.configuration.timezone
      );
      return this._buildSuccessResponse(data.sourcePatient, fileRefs);
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  private _buildSuccessResponse(
    sourcePatient: IGetRecordResponse<
      IPraktikaPatient,
      IPraktikaPatientTranslations
    >,
    fileRefs: DocumentReference<IMedia>[]
  ): IDestinationEntityRecord<IPatientFileDestinationRecord> {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      data: {
        fileRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: sourcePatient.record.ref,
      migratedAt: toTimestamp(),
    };
  }

  private _buildErrorResponse(
    sourcePatient: IGetRecordResponse<
      IPraktikaPatient,
      IPraktikaPatientTranslations
    >,
    errorMessage: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: sourcePatient.record.ref,
      errorMessage,
      failData: {
        patientRef: sourcePatient.record.ref,
      },
    };
  }

  private async _addMediaToPatient(
    sourcePatientId: string,
    patientRef: DocumentReference<IPatient>,
    images: IGetRecordResponse<
      IPraktikaPatientImage,
      IPraktikaPatientImageTranslations,
      IPraktikaPatientImageFilters
    >[],
    files: IFileSummary[],
    translationMap: TranslationMapHandler,
    source: ISource,
    projectId: string,
    destinationBucket: string,
    timezone: Timezone
  ): Promise<DocumentReference<IMedia>[]> {
    // eslint-disable-next-line no-console
    console.log(
      `Found ${files.length} files for patient ${sourcePatientId} in the bucket`
    );

    const folderPath = files[0]?.folderPath;

    if (!files.length || !folderPath) {
      return [];
    }

    const copyFolderResponse = await this._copySourceFilesToDestination(
      getBucketStoragePath(source),
      `${folderPath}/`,
      patientRef,
      projectId,
      destinationBucket
    );

    return asyncForEach(copyFolderResponse.storageFiles, async (file) => {
      const fileDestinationRef = await translationMap.getDestination(
        file.path,
        PATIENT_FILE_RESOURCE_TYPE
      );

      const fileName = file.path.split('/').pop();
      const matchingFile = images.find(
        (image) => image.data.data.filename === fileName
      );

      const createdAt = matchingFile
        ? toTimestamp(
            moment.tz(
              matchingFile.data.data.created_at,
              ISO_DATE_TIME_FORMAT,
              timezone
            )
          )
        : toTimestamp();

      const mediaRef = await FirestoreMigrate.upsertDoc(
        Patient.mediaCol({ ref: patientRef }),
        {
          ...Media.init({
            name: fileName,
            path: file.path,
          }),
          createdAt,
        },
        fileDestinationRef?.id
      );

      if (!fileDestinationRef) {
        await translationMap.upsert({
          sourceIdentifier: file.path,
          destinationIdentifier: mediaRef,
          resourceType: PATIENT_FILE_RESOURCE_TYPE,
        });
      }

      return mediaRef;
    });
  }

  private async _copySourceFilesToDestination(
    sourceBucket: string,
    sourcePath: string,
    patientRef: DocumentReference<IPatient>,
    projectId: string,
    destinationBucket: string
  ): Promise<IStorageSyncResponse> {
    const copyStorage = new CopyFiles(
      sourceBucket,
      sourcePath,
      destinationBucket,
      Patient.storagePath({ ref: patientRef }),
      projectId
    );

    return {
      storageFiles: await copyStorage.copyAll([/.*.db/], [], true),
    };
  }
}

export async function groupImagesByPatient(
  projectId: string,
  bucketName: string,
  bucketPath: string
): Promise<Record<string, IFileSummary[]>> {
  const storage = new CopyFiles(bucketName, bucketPath, '', '', projectId)
    .storage;

  const [files] = await storage.bucket(bucketName).getFiles({
    prefix: bucketPath,
  });

  const missingFields: string[] = [];

  const bucketFiles = uniq(
    compact(
      files.map((file) => {
        const folderPath = file.name.split('/');

        const fileSummary: IFileSummary = {
          patientId: '',
          fileName: '',
          folderPath: '',
        };

        const fileName = folderPath.pop() ?? '';
        const fullFolderPath = folderPath.join('/');
        const mainFolder =
          folderPath.length > 0 ? folderPath[folderPath.length - 1] : '';

        const matches = new RegExp(/([0-9]+)\(/).exec(mainFolder);
        fileSummary.patientId = matches?.[1] ?? '';
        fileSummary.folderPath = fullFolderPath;
        fileSummary.fileName = fileName;

        if (!fileSummary.patientId || !fileSummary.fileName) {
          missingFields.push(file.name);
        }

        return fileSummary;
      })
    )
  );

  return groupBy(bucketFiles, 'patientId');
}
