import { Injectable } from '@angular/core';
import { type Data, Router } from '@angular/router';
import { BaseCurrentFirestoreModel } from '@principle-theorem/ng-principle-shared';
import { type IPatientDocument } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';

@Injectable({
  providedIn: 'root',
  useFactory: (route: Router) => new CurrentPatientDocumentScope(route),
  deps: [Router],
})
export class CurrentPatientDocumentScope extends BaseCurrentFirestoreModel<
  WithRef<IPatientDocument>
> {
  protected _propertyAccessor(
    data: Data
  ): WithRef<IPatientDocument> | undefined {
    if (data.patientDocument) {
      return data.patientDocument as WithRef<IPatientDocument>;
    }
  }
}
