import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MedicalFormAlerts } from '@principle-theorem/ng-custom-forms';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import { IBreadcrumb } from '@principle-theorem/ng-shared';
import {
  Brand,
  CustomFormConfiguration,
  CustomFormContent,
  toMedicalHistoryForm,
} from '@principle-theorem/principle-core';
import {
  CustomFormDataResolverConfigMap,
  IBrand,
  ICustomFormContent,
  IFormSchema,
  IJsonSchemaFormWithResolverConfig,
  IPatientFormSchema,
  type ICustomFormData,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  WithRef,
  filterUndefined,
  isPathChanged$,
  snapshot,
  toTimestamp,
} from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { ReplaySubject, combineLatest, of, type Observable } from 'rxjs';
import { auditTime, map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-medical-history-form-preview',
    templateUrl: './medical-history-form-preview.component.html',
    styleUrls: ['./medical-history-form-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MedicalHistoryFormPreviewComponent {
  private _customFormContent$: Observable<
    WithRef<ICustomFormContent> | undefined
  >;
  form$: Observable<IJsonSchemaFormWithResolverConfig>;
  brand$: Observable<WithRef<IBrand>>;
  breadcrumbs$: Observable<IBreadcrumb[]>;
  schema$: Observable<IFormSchema>;
  resolverConfigs$: Observable<CustomFormDataResolverConfigMap>;
  submittedForm$ = new ReplaySubject<IPatientFormSchema>(1);
  medicalFormAlerts: MedicalFormAlerts;

  constructor(
    private _currentBrand: CurrentBrandScope,
    private _snackBar: MatSnackBar
  ) {
    this.brand$ = this._currentBrand.doc$.pipe(filterUndefined());
    this.breadcrumbs$ = this.brand$.pipe(
      map((brand) => [
        { label: 'Settings', path: '../../../' },
        { label: brand.name },
        { label: 'Medical History' },
      ])
    );

    this._customFormContent$ = this._currentBrand.doc$.pipe(
      filterUndefined(),
      switchMap((brand) => Brand.medicalHistoryFormConfig$(brand)),
      switchMap((config) =>
        config ? CustomFormConfiguration.getContent$(config) : of(undefined)
      )
    );
    const form$ = this._customFormContent$.pipe(
      map((content) =>
        content ? CustomFormContent.getJsonSchemaForm(content) : {}
      ),
      map((jsonSchemaForm) => toMedicalHistoryForm(jsonSchemaForm))
    );

    this.form$ = form$.pipe(isPathChanged$('schema', isEqual));

    this.schema$ = this.form$.pipe(
      map((jsonSchemaForm) => jsonSchemaForm.schema),
      filterUndefined()
    );

    this.resolverConfigs$ = form$.pipe(
      map((config) => config?.dataResolverConfig ?? {})
    );

    const latestFormData$ = combineLatest([
      form$,
      this.submittedForm$.pipe(auditTime(200)),
    ]).pipe(
      map(([form, submittedForm]) => ({
        ...submittedForm,
        ...form,
      }))
    );

    this.medicalFormAlerts = new MedicalFormAlerts(
      this.brand$,
      latestFormData$
    );
  }

  async updateForm(data: object): Promise<void> {
    this.submittedForm$.next({
      data,
      schema: await snapshot(this.schema$),
      date: toTimestamp(),
    });
  }

  async updateAlerts(
    data: ICustomFormData['dataResolverConfig']
  ): Promise<void> {
    const content = await snapshot(this._customFormContent$);
    if (content) {
      await Firestore.patchDoc(content.ref, { dataResolverConfig: data });
    }
    this._snackBar.open('Medical History Alerts Updated');
  }
}
