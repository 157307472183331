import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgCoreModule } from '@principle-theorem/ng-core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ChartArchComponent } from './chart-arch/chart-arch.component';
import { ChartMouthComponent } from './chart-mouth/chart-mouth.component';
import { AddToothDialogComponent } from './chart-quadrant/add-tooth-dialog/add-tooth-dialog.component';
import { ChartQuadrantMenuComponent } from './chart-quadrant/chart-quadrant-menu/chart-quadrant-menu.component';
import { ChartQuadrantComponent } from './chart-quadrant/chart-quadrant.component';
import { ChartSurfaceAttrFillDirective } from './chart-surface-attr-fill/chart-surface-attr-fill.directive';
import { ChartSurfaceDisableDirective } from './chart-surface-disable/chart-surface-disable.directive';
import { ChartSurfacePathStrokeDirective } from './chart-surface-path-stroke/chart-surface-path-stroke.directive';
import { ChartSurfaceStyleFillDirective } from './chart-surface-style-fill/chart-surface-style-fill.directive';
import { ChartSurfaceMenuComponent } from './chart-surface/chart-surface-menu/chart-surface-menu.component';
import { ChartSurfaceDirective } from './chart-surface/chart-surface.directive';
import { ChartToothSurfaceComponent } from './chart-tooth-surface/chart-tooth-surface.component';
import { ChartToothMenuComponent } from './chart-tooth/chart-tooth-menu/chart-tooth-menu.component';
import { ChartToothComponent } from './chart-tooth/chart-tooth.component';
import { ChartedItemsBadgeHoverDirective } from './charted-items-badge/charted-items-badge-hover.directive';
import { ChartedItemsBadgeComponent } from './charted-items-badge/charted-items-badge.component';
import { ChartedItemsHoverSummaryDialogComponent } from './charted-items-badge/charted-items-hover-summary-dialog/charted-items-hover-summary-dialog.component';
import { DentalChartSvgComponent } from './dental-chart-svg.component';
import { ChartKeybindInteractionsDirective } from './interactions/chart-keybind-interactions.directive';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { SVGTransformDirective } from './svg-transform/svg-transform.directive';

@NgModule({
  imports: [
    NgMaterialModule,
    ReactiveFormsModule,
    NgCoreModule,
    NgSharedModule,
  ],
  declarations: [
    ChartArchComponent,
    ChartMouthComponent,
    AddToothDialogComponent,
    ChartQuadrantMenuComponent,
    ChartQuadrantComponent,
    ChartSurfaceAttrFillDirective,
    ChartSurfaceDisableDirective,
    ChartSurfacePathStrokeDirective,
    ChartSurfaceStyleFillDirective,
    ChartSurfaceMenuComponent,
    ChartSurfaceDirective,
    ChartToothSurfaceComponent,
    ChartToothMenuComponent,
    ChartToothComponent,
    ChartedItemsBadgeHoverDirective,
    ChartedItemsBadgeComponent,
    ChartedItemsHoverSummaryDialogComponent,
    DentalChartSvgComponent,
    ChartKeybindInteractionsDirective,
    SVGTransformDirective,
    SvgIconComponent,
  ],
  exports: [
    DentalChartSvgComponent,
    ChartToothComponent,
    ChartSurfaceDirective,
  ],
})
export class DentalChartSvgModule {}
