import { Brand } from '@principle-theorem/principle-core';
import { type ITag } from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  type IReffable,
  multiMap,
  multiSortBy$,
  nameSorter,
  snapshot,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import {
  CustomMappingType,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { FeeScheduleSourceEntity } from '../../source/entities/fee-schedule';

export const FEE_SCHEDULE_TO_PATIENT_TAG_CUSTOM_MAPPING_TYPE =
  'feeScheduleToPatientTag';

export const FEE_SCHEDULE_TO_PATIENT_TAG_MAPPING: ICustomMapping =
  CustomMapping.init({
    metadata: {
      label: 'Fee Schedule To Patient Tag',
      description:
        'Allow for mapping a fee schedule to a patient tag. This will allow for the fee schedules to be used to add tags such as DVA and CDBS.',
      type: FEE_SCHEDULE_TO_PATIENT_TAG_CUSTOM_MAPPING_TYPE,
    },
    type: CustomMappingType.DocumentReference,
  });

export class D4WFeeScheduleToPatientTagMappingHandler extends BaseCustomMappingHandler<ITag> {
  customMapping = FEE_SCHEDULE_TO_PATIENT_TAG_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const entity = new FeeScheduleSourceEntity();
    const records = await entity.getRecords$(migration, 1000).toPromise();
    const options = records
      .map((record) => record.data.data)
      .map((record) => ({
        label: `${record.description}`,
        value: entity.getSourceRecordId(record).toString(),
      }));

    return sortBy(options, 'label');
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument[]> {
    return snapshot(
      Brand.patientTags$(migration.configuration.brand).pipe(
        multiMap(toNamedDocument),
        multiSortBy$(nameSorter())
      )
    );
  }
}
