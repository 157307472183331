import { type IMention } from '@principle-theorem/editor';
import { type IContextualAction } from '@principle-theorem/ng-principle-shared';
import {
  Appointment,
  hasValidEmail,
  OrganisationCache,
  Patient,
} from '@principle-theorem/principle-core';
import {
  type ICandidateCalendarEvent,
  type IAppointment,
  type IBasePatient,
  type IContact,
  type ILab,
  type IPatient,
  type IPatientContactDetails,
  type IPrincipleMention,
  MentionResourceType,
  AppointmentSummary,
} from '@principle-theorem/principle-core/interfaces';
import { asDocRef, Firestore, type WithRef } from '@principle-theorem/shared';
import { from, type Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export interface IContextualActionButton {
  action: IContextualAction;
  isDisabled$: Observable<boolean>;
  disabledMessage$: Observable<string>;
}

export interface IBaseContextActionButton {
  create(action: IContextualAction): IContextualActionButton;
  createMany(actions: IContextualAction[]): IContextualActionButton[];
  disableMessage$(actionName: string): Observable<string>;
  disableActionButton$(actionName: string): Observable<boolean>;
}

abstract class BaseContextualActionButtons implements IBaseContextActionButton {
  create(action: IContextualAction): IContextualActionButton {
    return {
      action,
      isDisabled$: this.disableActionButton$(action.name),
      disabledMessage$: this.disableMessage$(action.name),
    };
  }

  createMany(actions: IContextualAction[]): IContextualActionButton[] {
    return actions.map((action) => this.create(action));
  }

  disableMessage$(actionName: string): Observable<string> {
    return this.disableActionButton$(actionName).pipe(
      map((isDisabled) => {
        if (!isDisabled) {
          return '';
        }

        if (actionName === 'Email') {
          return 'No email address on record';
        }
        if (actionName === 'SMS') {
          return 'No mobile number on record';
        }
        if (actionName === 'Call') {
          return 'No phone number on record';
        }
        if (actionName === 'Show on Timeline') {
          return 'No event for appointment';
        }

        return '';
      })
    );
  }

  abstract disableActionButton$(actionName: string): Observable<boolean>;
}

export class PatientContextualActionButtons extends BaseContextualActionButtons {
  constructor(private _patient$: Observable<WithRef<IPatient> | undefined>) {
    super();
  }

  disableActionButton$(actionName: string): Observable<boolean> {
    if (actionName === 'Email') {
      return this._patient$.pipe(
        map((patient) => (patient ? !hasValidEmail(patient) : false))
      );
    }

    if (actionName === 'SMS') {
      return this._patient$.pipe(
        switchMap((patient) =>
          patient ? Patient.resolveMobileNumber(patient) : of(undefined)
        ),
        map((mobile) => (!mobile ? true : false))
      );
    }

    if (actionName === 'Call') {
      return this._patient$.pipe(
        switchMap((patient) =>
          patient ? Patient.resolveFirstContactNumber(patient) : of(undefined)
        ),
        map((number) => (!number ? true : false))
      );
    }

    return of(false);
  }
}

export class ContactContextualActionButtons extends BaseContextualActionButtons {
  constructor(
    private _contact$: Observable<WithRef<IContact | ILab> | undefined>
  ) {
    super();
  }

  disableActionButton$(actionName: string): Observable<boolean> {
    if (actionName === 'Email') {
      return this._contact$.pipe(
        map((contact) => (contact ? !hasValidEmail(contact) : false))
      );
    }

    if (actionName === 'SMS') {
      return this._contact$.pipe(
        map((contact) => (contact ? contact.mobileNumber : undefined)),
        map((mobile) => (!mobile ? true : false))
      );
    }

    if (actionName === 'Call') {
      return this._contact$.pipe(
        map((contact) => (contact ? contact.phone : undefined)),
        map((number) => (!number ? true : false))
      );
    }

    return of(false);
  }
}

export class AppointmentContextualActionButtons extends PatientContextualActionButtons {
  constructor(
    private _appointment$: Observable<
      WithRef<IAppointment> | AppointmentSummary
    >,
    patient$: Observable<WithRef<IPatient> | undefined>
  ) {
    super(patient$);
  }

  override disableActionButton$(actionName: string): Observable<boolean> {
    if (actionName === 'Show on Timeline') {
      return this._appointment$.pipe(map((appointment) => !appointment.event));
    }

    return super.disableActionButton$(actionName);
  }
}

export class ContextActionButtonBuilder {
  static build(mention: IPrincipleMention): IBaseContextActionButton {
    switch (mention.resource.type) {
      case MentionResourceType.Appointment:
        return new AppointmentContextualActionButtons(
          from(Firestore.getDoc(asDocRef<IAppointment>(mention.resource.ref))),
          resolveAppointmentMention$(
            mention as IMention<MentionResourceType.Appointment>
          )
        );
      case MentionResourceType.Patient:
      case MentionResourceType.Candidate:
        const patient = from(
          OrganisationCache.patients.getDoc(
            asDocRef<IPatient>(mention.resource.ref)
          )
        ).pipe(
          switchMap((resolvedPatient) =>
            Patient.resolveContactDetails$(resolvedPatient)
          )
        );
        return new PatientContextualActionButtons(patient);
      case MentionResourceType.Contact:
        const contact = from(
          Firestore.getDoc(asDocRef<IContact>(mention.resource.ref))
        );
        return new ContactContextualActionButtons(contact);
      case MentionResourceType.Lab:
        const lab = from(
          Firestore.getDoc(asDocRef<ILab>(mention.resource.ref))
        );
        return new ContactContextualActionButtons(lab);
      case MentionResourceType.Gap:
        const candidate = from(
          Firestore.getDoc(
            asDocRef<ICandidateCalendarEvent>(mention.resource.ref)
          )
        ).pipe(
          switchMap((gapCandidate) =>
            Firestore.getDoc(
              asDocRef<IPatient>(gapCandidate.candidate.patient.ref)
            )
          )
        );
        return new PatientContextualActionButtons(candidate);
      default:
        return new ContactContextualActionButtons(of(undefined));
    }
  }
}

function resolveAppointmentMention$(
  mention: IMention<MentionResourceType.Appointment>
): Observable<WithRef<IBasePatient & IPatientContactDetails> | undefined> {
  return from(
    Appointment.patient({ ref: asDocRef<IAppointment>(mention.resource.ref) })
  ).pipe(
    switchMap((appointmentPatient) =>
      Patient.resolveContactDetails$(appointmentPatient)
    )
  );
}
