import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';

export interface IIntegrationKeySheetData {
  integrationKey: string;
}

@Component({
    selector: 'pr-integration-key-sheet',
    templateUrl: './integration-key-sheet.component.html',
    styleUrls: ['./integration-key-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IntegrationKeySheetComponent {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<IntegrationKeySheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: IIntegrationKeySheetData,
    private _clipboard: Clipboard
  ) {}

  copy(event: MouseEvent): void {
    this._clipboard.copy(this.data.integrationKey);
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  download(event: MouseEvent): void {
    const blob = new Blob([this.data.integrationKey], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
}
