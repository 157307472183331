import { Injectable } from '@angular/core';
import { type Data, Router } from '@angular/router';
import { type WithRef } from '@principle-theorem/shared';
import { type ILab } from '@principle-theorem/principle-core/interfaces';
import { BaseCurrentFirestoreModel } from './base-current-firestore-model';

@Injectable({
  providedIn: 'root',
  useFactory: (route: Router) => new CurrentLabScope(route),
  deps: [Router],
})
export class CurrentLabScope extends BaseCurrentFirestoreModel<WithRef<ILab>> {
  protected _propertyAccessor(data: Data): WithRef<ILab> | undefined {
    if (data.lab) {
      return data.lab as WithRef<ILab>;
    }
  }
}
