import {
  ChecklistType,
  IChecklistItem,
} from '@principle-theorem/principle-core/interfaces';
import { uniqWith } from 'lodash';

export class TreatmentConfigurationChecklists {
  static getChecklistByType(
    checklist: IChecklistItem[],
    type: ChecklistType
  ): IChecklistItem[] {
    return checklist.filter(
      (checklistItem) => checklistItem.type === type && !checklistItem.deleted
    );
  }

  static upsertItem(
    checklist: IChecklistItem[],
    updatedItem: IChecklistItem,
    originalItem?: IChecklistItem
  ): IChecklistItem[] {
    return originalItem
      ? TreatmentConfigurationChecklists.updateItem(
          checklist,
          originalItem,
          updatedItem
        )
      : TreatmentConfigurationChecklists.addItem(checklist, updatedItem);
  }

  static addItem(
    checklist: IChecklistItem[],
    item: IChecklistItem
  ): IChecklistItem[] {
    return deduplicateChecklistItems([...checklist, item]);
  }

  static updateItem(
    checklist: IChecklistItem[],
    originalItem: IChecklistItem,
    updatedItem: IChecklistItem
  ): IChecklistItem[] {
    return deduplicateChecklistItems(
      checklist.map((checklistItem) =>
        checklistItem.title !== originalItem.title ? checklistItem : updatedItem
      )
    );
  }
}

export function deduplicateChecklistItems(
  items: IChecklistItem[]
): IChecklistItem[] {
  return uniqWith(items, isSameChecklistItem);
}

function isSameChecklistItem(
  itemA: IChecklistItem,
  itemB: IChecklistItem
): boolean {
  const compareFields: (keyof IChecklistItem)[] = [
    'type',
    'title',
    'action',
    'data',
  ];
  return compareFields.every((field) => itemA[field] === itemB[field]);
}
