import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isAutomationType } from '@principle-theorem/principle-core/interfaces';
import {
  type INamedTypeDocument,
  type Reffable,
  type SerialisedData,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import {
  automationsAdapter,
  AUTOMATIONS_FEATURE_KEY,
  type IAutomationsState,
} from './automations.reducer';
import { type AutomationEntity } from '@principle-theorem/principle-core';

const { selectAll, selectEntities } = automationsAdapter.getSelectors();

export const getAutomationsState = createFeatureSelector<IAutomationsState>(
  AUTOMATIONS_FEATURE_KEY
);

export const getAutomationsLoaded = createSelector(
  getAutomationsState,
  (state) => state.loaded
);

export const getAutomations = createSelector(getAutomationsState, (state) =>
  selectAll(state)
);

export const getAutomationEntities = createSelector(
  getAutomationsState,
  selectEntities
);

export const getSelectedAutomationId = createSelector(
  getAutomationsState,
  (state) => state.selectedId
);

export const getSelectedAutomation = createSelector(
  getAutomationEntities,
  getSelectedAutomationId,
  (entities, id) => (id ? entities[id] : undefined)
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getAutomationsByCreator = (creator: INamedTypeDocument) =>
  createSelector(getAutomations, (automations) =>
    automations.filter(
      (automation) => automation.creator.ref.referenceValue === creator.ref.path
    )
  );

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getAutomationById = (id: string) =>
  createSelector(getAutomationEntities, (entities) => entities[id]);

export const getUnsavedAutomations = createSelector(
  getAutomations,
  (automations) => automations.filter((automation) => !automation.ref)
);

export const getReffableAutomations = createSelector(
  getAutomations,
  (automations) =>
    automations.filter(
      (automation): automation is SerialisedData<Reffable<AutomationEntity>> =>
        automation.ref !== undefined
    )
);

export const getStatusFilter = createSelector(
  getAutomationsState,
  (state) => state.statusFilter
);

export const getTypeFilter = createSelector(
  getAutomationsState,
  (state) => state.typeFilter
);

export const getPracticeFilter = createSelector(
  getAutomationsState,
  (state) => state.practiceFilter
);

export const getFilteredAutomations = createSelector(
  getAutomations,
  getStatusFilter,
  getTypeFilter,
  getPracticeFilter,
  (automations, statuses, type, practice) => {
    return automations
      .filter((automation) => statuses.includes(automation.status))
      .filter((automation) =>
        !isAutomationType(type) ? true : automation.type === type
      )
      .filter((automation) =>
        practice ? automation.practiceRef?.referenceValue === practice : true
      );
  }
);

export const getFilteredAutomationCount = createSelector(
  getFilteredAutomations,
  (automations) => automations.length
);

export const getIsAllSelected = createSelector(
  getAutomationsState,
  getFilteredAutomationCount,
  (state, filteredCount) =>
    state.selectedIds.length > 0 && state.selectedIds.length === filteredCount
);

export const getIsNoneSelected = createSelector(
  getAutomationsState,
  (state) => !state.selectedIds.length
);

export const getListSelectedIds = createSelector(
  getAutomationsState,
  (state) => state.selectedIds
);

export const getListSelectedEntities = createSelector(
  getAutomationEntities,
  getListSelectedIds,
  (entities, ids) => (ids.length ? compact(ids.map((id) => entities[id])) : [])
);
