import {
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { compact } from 'lodash';
import { type WithRef } from '@principle-theorem/shared';
import { type IChartedItemConfiguration } from '@principle-theorem/principle-core/interfaces';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-quick-chart-bar',
    templateUrl: './quick-chart-bar.component.html',
    styleUrls: ['./quick-chart-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class QuickChartBarComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  items$ = new ReplaySubject<WithRef<IChartedItemConfiguration>[]>(1);
  hotkeys$: Observable<Hotkey[]>;
  trackByItem = TrackByFunctions.ref<WithRef<IChartedItemConfiguration>>();
  @Input() hotkeyMap: string[] = [];
  @Output() chartableAdded: EventEmitter<WithRef<IChartedItemConfiguration>> =
    new EventEmitter<WithRef<IChartedItemConfiguration>>();

  @Input()
  set items(items: WithRef<IChartedItemConfiguration>[]) {
    if (items) {
      this.items$.next(items);
    }
  }

  constructor(private _hotkeyService: HotkeysService) {
    this.hotkeys$ = this.items$.pipe(
      map((items) => {
        const hotkeys: (Hotkey | undefined)[] = items.map(
          (item, index: number) => {
            const hotkey: string | undefined = this.hotkeyMap[index];
            if (!this.hotkeyMap[index]) {
              return;
            }
            return this._createHotkey(item, hotkey);
          }
        );
        return compact(hotkeys);
      })
    );

    this.hotkeys$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((hotkeys) =>
        hotkeys.length
          ? this._hotkeyService.add(hotkeys)
          : this._hotkeyService.remove()
      );
  }

  ngOnDestroy(): void {
    this._hotkeyService.remove();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  addChartedItem(item: WithRef<IChartedItemConfiguration>): void {
    this.chartableAdded.emit(item);
  }

  private _createHotkey(
    item: WithRef<IChartedItemConfiguration>,
    hotkey: string
  ): Hotkey {
    return new Hotkey(
      hotkey,
      (): boolean => {
        this.addChartedItem(item);
        return false;
      },
      undefined,
      `Quick chart ${item.name}`
    );
  }
}
