import { findNodePosition, safeInsert } from '@principle-theorem/editor';
import {
  safeCombineLatest,
  snapshot,
  type IAttachment,
} from '@principle-theorem/shared';
import { Editor } from '@tiptap/core';
import { type Node } from '@tiptap/pm/model';
import { type EditorState } from '@tiptap/pm/state';
import { type EditorView } from '@tiptap/pm/view';
import { BehaviorSubject, combineLatest, type Observable } from 'rxjs';
import { concatMap, filter, map, tap } from 'rxjs/operators';

export enum MIMEFileType {
  Image = 'image',
  Video = 'video',
}

export interface IUploadProgress {
  attachment$: Observable<IAttachment>;
  isUploadComplete$: Observable<boolean>;
  progress$: Observable<number | undefined>;
}

export interface IUploader {
  upload: (event: File) => IUploadProgress;
}

export class FileUploader {
  async upload(
    uploadingNodeName: string,
    nodeName: string,
    fileType: MIMEFileType,
    uploader: IUploader,
    editor: Editor,
    position: number,
    fileList: FileList
  ): Promise<void> {
    const editorState: EditorState | undefined = editor.view.state;
    if (!editorState) {
      return;
    }

    const files: File[] = this._getFiles(fileList, fileType);

    if (!files.length) {
      return;
    }

    await snapshot(
      safeCombineLatest(
        files.map((file) =>
          this._uploadFile(
            editor,
            uploadingNodeName,
            position,
            uploader,
            file,
            nodeName
          )
        )
      )
    );
  }

  private async _uploadFile(
    editor: Editor,
    uploadingNodeName: string,
    position: number,
    uploader: IUploader,
    file: File,
    nodeName: string
  ): Promise<void> {
    const node$: BehaviorSubject<Node> = new BehaviorSubject(
      editor.view.state.schema.nodes[uploadingNodeName].create({
        progress: 0,
      })
    );

    editor.view.dispatch(
      safeInsert(node$.value, position)(editor.view.state.tr)
        .setMeta('addToHistory', false)
        .setMeta('preventUpdate', true)
    );

    const upload: IUploadProgress = uploader.upload(file);
    const progress$: Observable<number | undefined> = this._handleProgress$(
      upload.progress$,
      editor.view,
      node$,
      uploadingNodeName
    );

    const uploadComplete$: Observable<string> = this._handleUpload$(
      upload.attachment$,
      editor.view,
      node$,
      nodeName
    );

    await snapshot(
      combineLatest([progress$, upload.isUploadComplete$]).pipe(
        filter(
          ([progress, isUploadComplete]) =>
            !!progress && progress === 100 && isUploadComplete
        ),
        concatMap(() => uploadComplete$)
      )
    );
  }

  private _getFiles(fileList: FileList, fileType: MIMEFileType): File[] {
    const regex = new RegExp(fileType, 'i');
    return Array.from(fileList).filter((file) => regex.test(file.type));
  }

  private _handleProgress$(
    progress$: Observable<number | undefined>,
    view: EditorView,
    node$: BehaviorSubject<Node>,
    uploadingNodeName: string
  ): Observable<number | undefined> {
    return progress$.pipe(
      tap((progress) => {
        const nodePosition = findNodePosition(view.state.doc, node$.value);
        node$.next(
          view.state.schema.nodes[uploadingNodeName].create({
            progress,
          })
        );
        if (!nodePosition) {
          const fallbackNode = node$.value;
          const tr = view.state.tr.insert(
            view.state.doc.content.size,
            fallbackNode
          );
          view.dispatch(tr);
          return;
        }
        view.dispatch(
          view.state.tr
            .replaceRangeWith(
              nodePosition.pos,
              nodePosition.pos + node$.value.nodeSize,
              node$.value
            )
            .setMeta('addToHistory', false)
            .setMeta('preventUpdate', true)
        );
      })
    );
  }

  private _handleUpload$(
    attachment$: Observable<IAttachment>,
    view: EditorView,
    node$: BehaviorSubject<Node>,
    nodeName: string
  ): Observable<string> {
    return attachment$.pipe(
      map((attachment) => attachment.path),
      tap((src) => {
        const nodePosition = findNodePosition(view.state.doc, node$.value);
        if (!nodePosition) {
          const fallbackNode = view.state.schema.nodes[nodeName].create({
            src,
          });
          const tr = view.state.tr
            .insert(view.state.doc.content.size, fallbackNode)
            .setMeta('addToHistory', false)
            .setMeta('preventUpdate', true);
          view.dispatch(tr);
          return;
        }
        const from = nodePosition.pos;
        const to = nodePosition.pos + node$.value.nodeSize;
        view.dispatch(
          view.state.tr
            .replaceWith(
              from,
              to,
              view.state.schema.nodes[nodeName].create({
                src,
              })
            )
            .setMeta('addToHistory', false)
        );
      })
    );
  }
}
