import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type ICustomReportFilter } from '@principle-theorem/principle-core/interfaces';
import {
  type FactTableInterface,
  type ReportingProperty,
  resolveReportingProperty,
} from '@principle-theorem/reporting';
import { compact } from 'lodash';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReportBuilderDataSource } from '../../../../models/report-builder-data-sources/report-builder-data-source';

@Component({
    selector: 'pr-custom-report-filters',
    templateUrl: './custom-report-filters.component.html',
    styleUrls: ['./custom-report-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CustomReportFiltersComponent {
  private _factTable$ = new ReplaySubject<FactTableInterface<unknown>>(1);
  private _customFilters$ = new ReplaySubject<ICustomReportFilter[]>(1);
  filters$: Observable<ICustomReportFilterDisplay[]>;
  trackByFilter = TrackByFunctions.uniqueId<ICustomReportFilterDisplay>();

  @Input()
  set factTable(factTable: FactTableInterface<unknown>) {
    if (factTable) {
      this._factTable$.next(factTable);
    }
  }

  @Input()
  set filters(filters: ICustomReportFilter[]) {
    if (filters) {
      this._customFilters$.next(filters);
    }
  }

  constructor() {
    this.filters$ = combineLatest([
      this._factTable$,
      this._customFilters$,
    ]).pipe(
      map(([factTable, filters]) =>
        this._resolveFilterDisplays(factTable, filters)
      )
    );
  }

  private _resolveFilterDisplays(
    factTable: FactTableInterface<unknown>,
    filters: ICustomReportFilter[]
  ): ICustomReportFilterDisplay[] {
    const withProperties = filters.map((filter) => {
      const property = resolveReportingProperty(factTable, filter.id);
      if (!property) {
        return undefined;
      }
      return { filter, property };
    });
    return compact(withProperties).map(({ filter, property }) =>
      this._getDisplay(filter, property)
    );
  }

  private _getDisplay(
    filter: ICustomReportFilter,
    property: ReportingProperty
  ): ICustomReportFilterDisplay {
    const values = filter.values
      .map((filterValue) => filterValue.label)
      .join(', ');
    return {
      id: filter.id,
      label: ReportBuilderDataSource.getPropertyLabel(property),
      values,
    };
  }
}

interface ICustomReportFilterDisplay {
  id: string;
  label: string;
  values: string;
}
