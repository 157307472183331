import {
  Component,
  ElementRef,
  Input,
  type OnChanges,
  type OnDestroy,
  type AfterViewInit,
} from '@angular/core';
import { type ChartBuilder } from '../../../models/report/charts/chart-builder';
import { Subject } from 'rxjs';
import { GoogleChartsService } from '../google-charts.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { SidebarManagerService } from '@principle-theorem/ng-shared';

@Component({
  selector: 'pr-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.sass'],
})
export class ChartComponent implements AfterViewInit, OnChanges, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  readonly chartContainerClass: string = 'chart-placeholder';
  loading = false;
  @Input() chartBuilder: ChartBuilder;

  constructor(
    private _el: ElementRef<HTMLElement>,
    private _sidebar: SidebarManagerService,
    public googleCharts: GoogleChartsService
  ) {}

  ngAfterViewInit(): void {
    this.googleCharts.loaded
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this._draw());

    this._sidebar.animations$
      .pipe(switchMap((completed) => completed))
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this._draw());

    this._draw();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.chartBuilder.clear();
  }

  ngOnChanges(): void {
    this._draw();
  }

  private _draw(): void {
    const childElem = this._el.nativeElement.querySelector(
      `.${this.chartContainerClass}`
    );

    if (!this.chartBuilder || !this._hasLoadedPackages() || !childElem) {
      return;
    }

    void this.chartBuilder.draw(childElem);
  }

  private _hasLoadedPackages(): boolean {
    if (!this.chartBuilder) {
      return false;
    }
    return this.googleCharts.hasLoaded(this.chartBuilder.packages);
  }
}
