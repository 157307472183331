import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  AppointmentScoreComponent,
  AppointmentScoreType,
} from '@principle-theorem/ng-appointment';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import {
  NgSharedModule,
  UserIconComponent,
} from '@principle-theorem/ng-shared';
import {
  IStaffer,
  ITypesenseWaitListWithRef,
} from '@principle-theorem/principle-core/interfaces';
import {
  asDocRef,
  DATE_TIME_FORMAT,
  durationToHumanisedTime,
  toMomentTz,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { duration } from 'moment-timezone';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-gap-shortlist-item-appointment-info',
  templateUrl: './gap-shortlist-item-appointment-info.component.html',
  styleUrls: ['./gap-shortlist-item-appointment-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    UserIconComponent,
    AppointmentScoreComponent,
    CommonModule,
    NgSharedModule,
  ],
})
export class GapShortlistItemAppointmentInfoComponent {
  readonly dateFormat = DATE_TIME_FORMAT;
  appointment$ = new ReplaySubject<ITypesenseWaitListWithRef>(1);
  score$ = new ReplaySubject<number>(1);
  scoreType$: Observable<AppointmentScoreType>;
  duration$: Observable<string>;
  practitionerImage$: Observable<string | undefined>;
  practitionerName$: Observable<string | undefined>;
  appointmentDate$: Observable<moment.Moment | undefined>;

  @Input()
  set appointment(appointment: ITypesenseWaitListWithRef) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  @Input()
  set score(score: number) {
    if (score) {
      this.score$.next(score);
    }
  }

  constructor(private _globalStore: GlobalStoreService) {
    this.duration$ = this.appointment$.pipe(
      map((appointment) =>
        durationToHumanisedTime(
          duration(appointment.appointmentDuration ?? 0, 'minutes')
        )
      )
    );

    this.practitionerImage$ = this.appointment$.pipe(
      switchMap((appointment) =>
        this._globalStore.getStafferImage$({
          ref: asDocRef<IStaffer>(appointment.practitionerRef),
        })
      )
    );

    this.practitionerName$ = this.appointment$.pipe(
      switchMap((appointment) =>
        this._globalStore.getStafferName$({
          ref: asDocRef<IStaffer>(appointment.practitionerRef),
        })
      )
    );

    this.appointmentDate$ = this.appointment$.pipe(
      map((appointment) => {
        if (!appointment.appointmentFrom) {
          return;
        }
        const from = moment.unix(appointment.appointmentFrom);
        return toMomentTz(from, appointment.appointmentTimezone);
      })
    );

    this.scoreType$ = this.appointment$.pipe(
      map((appointment) =>
        appointment.days.length
          ? 'gapSuggestionWithWaitlist'
          : 'gapSuggestionWithoutWaitlist'
      )
    );
  }
}
