import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import {
  DialogPresets,
  ObservableDataTable,
  PaginatedFirestoreTable,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { type ISterilisationRecord } from '@principle-theorem/principle-core/interfaces';
import { type CollectionReference, where } from '@principle-theorem/shared';
import {
  DAY_MONTH_YEAR_FORMAT,
  getDoc,
  HISTORY_DATE_FORMAT,
  type ITimePeriod,
  patchDoc,
  toNamedDocument,
  toQuery,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  ReplaySubject,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  EditSterilisationRecordDialogComponent,
  type IEditSterilisationRecordData,
  type IEditSterilisationRecordReturnData,
} from '../edit-sterilisation-record-dialog/edit-sterilisation-record-dialog.component';
import { stafferToNamedDoc } from '@principle-theorem/principle-core';

const DEFAULT_PAGE_SIZE = 50;

@Component({
  selector: 'pr-sterilisation-list',
  templateUrl: './sterilisation-list.component.html',
  styleUrls: ['./sterilisation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SterilisationListComponent implements OnDestroy {
  readonly dateFormat = HISTORY_DATE_FORMAT;
  from$: BehaviorSubject<Moment> = new BehaviorSubject(
    moment().subtract(2, 'weeks')
  );
  to$: BehaviorSubject<Moment> = new BehaviorSubject(moment());
  trackByRef = TrackByFunctions.ref<WithRef<ISterilisationRecord>>();
  sterilisationRecordCol$ = new ReplaySubject<
    CollectionReference<ISterilisationRecord>
  >(1);
  paginatedTable = new PaginatedFirestoreTable<WithRef<ISterilisationRecord>>(
    DEFAULT_PAGE_SIZE
  );
  totalRecordCount$: Observable<number>;
  emptySearch$: Observable<boolean>;
  searchInputValue$: Observable<string>;

  @Input()
  set sterilisationRecordCol(
    sterilisationRecordCol: CollectionReference<ISterilisationRecord>
  ) {
    if (sterilisationRecordCol) {
      this.sterilisationRecordCol$.next(sterilisationRecordCol);
    }
  }

  constructor(private _dialog: MatDialog) {
    void this.paginatedTable.sortChange({
      active: 'createdAt',
      direction: 'desc',
    });

    const records$ = combineLatest([
      this.sterilisationRecordCol$,
      this.from$,
      this.to$,
    ]).pipe(
      switchMap(([sterilisationRecordCol, from, to]) =>
        this.paginatedTable.getRecords$(
          toQuery(
            sterilisationRecordCol,
            where('createdAt', '<=', toTimestamp(moment(to).endOf('day'))),
            where('createdAt', '>=', toTimestamp(moment(from).startOf('day')))
          )
        )
      )
    );

    this.totalRecordCount$ = this.paginatedTable.pageChange$.pipe(
      map((pageChange) => pageChange.pageSize * (pageChange.pageIndex + 1) + 1)
    );

    this.paginatedTable.dataTable = new ObservableDataTable(records$);
    this.paginatedTable.dataTable.pageSize = DEFAULT_PAGE_SIZE;
    this.paginatedTable.dataTable.pageSizeOptions = [50, 100, 500, 1000];
    this.paginatedTable.dataTable.displayColumns = [
      'data',
      'patient',
      'scannedBy',
      'createdAt',
    ];

    this.emptySearch$ = combineLatest([
      records$,
      this.paginatedTable.loading$,
    ]).pipe(map(([records, loading]) => !records.length && !loading));

    this.searchInputValue$ = combineLatest([this.from$, this.to$]).pipe(
      map(([from, to]) => {
        const format = DAY_MONTH_YEAR_FORMAT;
        return `date range ${from.format(format)} - ${to.format(format)}`;
      })
    );
  }

  ngOnDestroy(): void {
    this.paginatedTable.destroy();
  }

  updateDates(range: ITimePeriod): void {
    this.from$.next(range.from);
    this.to$.next(range.to);
  }

  async editRecord(record: WithRef<ISterilisationRecord>): Promise<void> {
    const patient = record.patient && (await getDoc(record.patient.ref));
    const scannedBy = record.scannedBy && (await getDoc(record.scannedBy.ref));
    const practice = await getDoc(record.practice.ref);
    const data = {
      data: record.data,
      patient,
      scannedBy,
      practice,
    };
    const updatedRecord = await this._dialog
      .open<
        EditSterilisationRecordDialogComponent,
        IEditSterilisationRecordData,
        IEditSterilisationRecordReturnData
      >(EditSterilisationRecordDialogComponent, DialogPresets.small({ data }))
      .afterClosed()
      .toPromise();

    if (!updatedRecord) {
      return;
    }

    await patchDoc(record.ref, {
      data: updatedRecord.data,
      patient: updatedRecord.patient && toNamedDocument(updatedRecord.patient),
      scannedBy:
        updatedRecord.scannedBy && stafferToNamedDoc(updatedRecord.scannedBy),
    });
  }
}
