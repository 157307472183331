import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  getSchemaSize,
  initVersionedSchema,
  type MixedSchema,
} from '@principle-theorem/editor';
import { type IReffable } from '@principle-theorem/shared';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  type INotable,
  type INote,
} from '@principle-theorem/principle-core/interfaces';
import { Note } from '@principle-theorem/principle-core';
import { type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

type FormData = Pick<INote, 'content' | 'pinned'>;

@Component({
    selector: 'pr-add-note',
    templateUrl: './add-note.component.html',
    styleUrls: ['./add-note.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddNoteComponent {
  @Input() resource: INotable & IReffable;
  @Output() noteAdded: EventEmitter<INote> = new EventEmitter<INote>();
  noteForm: TypedFormGroup<FormData> = new TypedFormGroup<FormData>({
    content: new TypedFormControl<MixedSchema>(
      initVersionedSchema(),
      Validators.required
    ),
    pinned: new TypedFormControl<boolean>(false),
  });
  buttonDisabled$: Observable<boolean>;

  constructor(private _snackBar: MatSnackBar) {
    this.buttonDisabled$ = this.noteForm.valueChanges.pipe(
      map((form) => !this.noteForm.dirty || !getSchemaSize(form.content)),
      startWith(true)
    );
  }

  onSubmit(): void {
    if (this.noteForm.invalid) {
      return;
    }

    const note: INote = Note.init(this.noteForm.getRawValue());
    this.noteAdded.emit(note);
    this.noteForm.controls.content.reset(initVersionedSchema());
    this._snackBar.open('Note added');
  }
}
