import { storyNameFromExport } from '@componentdriven/csf';
import { last } from 'lodash';

export function storiesModuleTitle(storiesFilePath: string): string {
  const [moduleType, moduleName, ...args] = storiesFilePath
    .split('/')
    .filter((segment) => segment !== '.');
  const fileName = last(args) || '';
  return [moduleType, moduleName, fileName]
    .map((segment) => segment.replace(/.stories.ts/g, ''))
    .map((segment) => storyNameFromExport(segment))
    .join(' / ');
}
