import { Injectable } from '@angular/core';
import { AppointmentCreator } from '@principle-theorem/ng-appointment/store';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  SchedulingEvent,
  stafferToNamedDoc,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  ISchedulingEventData,
  type IAppointment,
  type IPatient,
  type IPractice,
  type IStaffer,
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  deleteDoc,
  filterUndefined,
  getDoc,
  saveDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';

@Injectable()
export class TreatmentStepsEditorService {
  constructor(private _organisation: OrganisationService) {}

  async enableNextStep(
    patient: WithRef<IPatient>,
    defaultPractitioner: WithRef<IStaffer>,
    practice: WithRef<IPractice>,
    treatmentPlan: WithRef<ITreatmentPlan>
  ): Promise<void> {
    let nextStep = await TreatmentPlan.findStepBy(
      treatmentPlan,
      (appointment) => !appointment
    );
    if (!nextStep || nextStep.appointment) {
      nextStep = await TreatmentPlan.addTreatmentStep(treatmentPlan);
    }

    const practitioner = nextStep.practitionerRef
      ? await getDoc(nextStep.practitionerRef)
      : defaultPractitioner;

    await this.generateAppointmentForStep(
      patient,
      practitioner,
      practice,
      treatmentPlan,
      nextStep
    );
  }

  async generateAppointmentForStep(
    patient: WithRef<IPatient>,
    practitioner: WithRef<IStaffer>,
    practice: WithRef<IPractice>,
    plan: WithRef<ITreatmentPlan>,
    step: WithRef<ITreatmentStep>
  ): Promise<WithRef<IAppointment>> {
    const duration = await snapshot(TreatmentStep.getDuration$(step));
    const appointmentDetails = {
      practice,
      practitioner: stafferToNamedDoc(practitioner),
      treatment: {
        plan,
        step,
      },
      duration,
    };
    const scheduledByPractice = await snapshot(
      this._organisation.practice$.pipe(filterUndefined())
    );
    const schedulingEventData: ISchedulingEventData = {
      reasonSetManually: false,
      scheduledByPractice: scheduledByPractice.ref,
      schedulingConditions: SchedulingEvent.getSchedulingConditions(
        scheduledByPractice.settings.timezone
      ),
    };

    return AppointmentCreator.add(
      practitioner,
      patient,
      appointmentDetails,
      schedulingEventData
    );
  }

  async removeAppointmentFromStep(
    step: WithRef<ITreatmentStep>
  ): Promise<void> {
    if (!step.appointment) {
      return;
    }
    await saveDoc({
      ...step,
      appointment: undefined,
    });
    await deleteDoc(step.appointment);
  }
}
