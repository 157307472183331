import { Component, type OnInit } from '@angular/core';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  type IBreadcrumb,
  StorageResponseAPI,
} from '@principle-theorem/ng-shared';
import {
  FactTables,
  generateBuilderData,
  toMeasureBuilderData,
  type ICanDoAllProperty,
  type IChartConfig,
} from '@principle-theorem/reporting';
import {
  errorNil,
  type ITimePeriod,
  toMoment,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type IChartOptions } from '../../models/report/charts/chart-builder';
import { type IChartCard } from '../../models/report/charts/chart-card';
import { DateRangeDataBuilder } from '../../models/report/data-builders/date-range-data-builder';
import { CustomChartType } from '@principle-theorem/principle-core/interfaces';

interface IMeasureTotalCard {
  chart: IChartCard;
  measure: ICanDoAllProperty;
}

@Component({
    selector: 'pr-acquisitions',
    templateUrl: './acquisitions.component.html',
    styleUrls: ['./acquisitions.component.scss'],
    standalone: false
})
export class AcquisitionsComponent implements OnInit {
  from: moment.Moment = moment().subtract({ months: 1 });
  to: moment.Moment = moment();
  dataBuilder: DateRangeDataBuilder;
  channelsChart: IChartCard;
  patientsChart: IChartCard;
  bookedOnlineChart: IChartCard;
  // costPerConversionChart: IChartCard;
  // meanTreatmentPlanValueChart: IChartCard;
  // conversionsChart: IChartCard;
  // tableData: IChartCard;
  // replacementHeaders: ITableHeaderReplacement[];
  breadcrumbs: IBreadcrumb[] = [{ label: 'Reporting' }];
  patientsTotal: IMeasureTotalCard;
  bookedOnlineTotal: IMeasureTotalCard;

  constructor(
    private _organisation: OrganisationService,
    private _api: StorageResponseAPI
  ) {}

  ngOnInit(): void {
    this.dataBuilder = new DateRangeDataBuilder(
      this._api,
      this.from,
      this.to,
      this._organisation.brand$.pipe(errorNil()),
      this._organisation.userPractices$
    );
    this.loadCharts();
    // this.replacementHeaders = [{ from: 'id', to: 'Referrer' }];
  }

  updateDates(event: ITimePeriod): void {
    this.dataBuilder.updateRange(toMoment(event.from), toMoment(event.to));
    this.loadCharts();
  }

  loadCharts(): void {
    this.channelsChart = this._buildChannelsChart();
    this.patientsChart = this._buildNewPatientsChart();
    this.bookedOnlineChart = this._buildBookedOnlineChart();
    this.patientsTotal = {
      chart: this._buildNewPatientsChart(),
      measure: FactTables.appointmentEvent.newPatient.reduceByCount(),
    };
    this.bookedOnlineTotal = {
      chart: this._buildBookedOnlineChart(),
      measure: FactTables.appointmentEvent.appointment.bookedOnline,
    };
    // this._buildConversionsChart();
    // this._buildCostPerConversionChart();
    // this._buildMeanTreatmentPlanValueChart();
    // this.tableData = this.dataBuilder.toLineChart(CHANNELS_TABLE_DATA);
  }

  private _buildChannelsChart(): IChartCard {
    const channelsChart = this.dataBuilder.toPieChart(CHANNELS_CHART_DATA);
    channelsChart.chartBuilder.addChartOptions({
      pieHole: 0.4,
      chartArea: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      legend: {
        position: 'right',
        alignment: 'center',
      },
    });
    return channelsChart;
  }

  private _buildNewPatientsChart(): IChartCard {
    const patientsChart = this.dataBuilder.toLineChart(
      this._getNewPatientsChartConfig()
    );
    patientsChart.chartBuilder.addChartOptions(TIMELINE_CHART_OPTIONS);
    return patientsChart;
  }

  private _buildBookedOnlineChart(): IChartCard {
    const patientsChart = this.dataBuilder.toLineChart(
      this._getBookedOnlineChartConfig()
    );
    patientsChart.chartBuilder.addChartOptions(TIMELINE_CHART_OPTIONS);
    return patientsChart;
  }

  // private _buildConversionsChart(): void {
  //   const conversionsChart: IChartCard = this.dataBuilder.toBarChart(
  //     CONVERSIONS_CHART_DATA
  //   );
  //   conversionsChart.chartBuilder.addChartOptions({
  //     isStacked: true,
  //   });
  //   this.conversionsChart = conversionsChart;
  // }

  // private _buildCostPerConversionChart(): void {
  //   const costPerConversionChart: IChartCard = this.dataBuilder.toBarChart(
  //     COST_PER_CONVERSION
  //   );
  //   costPerConversionChart.chartBuilder.addChartOptions({
  //     vAxis: {
  //       format: 'currency',
  //     },
  //   });
  //   this.costPerConversionChart = costPerConversionChart;
  // }

  // private _buildMeanTreatmentPlanValueChart(): void {
  //   const meanTreatmentPlanValueChart: IChartCard = this.dataBuilder.toBarChart(
  //     MEAN_TREATMENT_PLAN_VALUE
  //   );
  //   meanTreatmentPlanValueChart.chartBuilder.addChartOptions({
  //     vAxis: {
  //       format: 'currency',
  //     },
  //   });
  //   this.meanTreatmentPlanValueChart = meanTreatmentPlanValueChart;
  // }

  private _getNewPatientsChartConfig(
    title: string = 'New Patients'
  ): IChartConfig {
    return {
      type: CustomChartType.Line,
      builderData: generateBuilderData({
        plottedOverTime: true,
        measures: [
          toMeasureBuilderData(
            FactTables.appointmentEvent.newPatient
              .groupBy(FactTables.appointmentEvent.timestampDay)
              .reduceByCount()
          ),
        ],
        groupByDimension: FactTables.appointmentEvent.timestampDay,
      }),
      labels: {
        title,
      },
    };
  }

  private _getBookedOnlineChartConfig(): IChartConfig {
    return {
      type: CustomChartType.Line,
      builderData: generateBuilderData({
        plottedOverTime: true,
        measures: [
          toMeasureBuilderData(
            FactTables.appointmentEvent.appointment.bookedOnline
              .groupBy(FactTables.appointmentEvent.timestampDay)
              .reduceByCount()
          ),
        ],
        groupByDimension: FactTables.appointmentEvent.timestampDay,
      }),
      labels: {
        title: 'Appointments Booked Online',
      },
    };
  }
}

// const CHANNELS_TABLE_DATA: IChartConfig = {
//   labels: {
//     title: 'Acquisition Sources',
//   },
//   builderData: generateBuilderData({
//     measures: [
//       // 'newPatients',
//       FactTables.appointmentCompleted.newPatient.reduceByCount(),
//       // 'caseAcceptanceRate',
//       FactTables.treatmentPlanEvent.accepted.reduceByRatio(
//         FactTables.treatmentPlanEvent.offered
//       ).setTitle('Case Acceptance Rate').setFormatter(MeasureFormatter.Percentage),
//       // 'totalRevenue',
//       FactTables.invoicePaid.invoice.total.reduceBySum(),
//       // 'cancellations',
//       FactTables.appointmentEvent.scopeBy(FactTables.appointmentEvent.cancelled)
//         .count.setLabel('Cancellations'),
//       // TODO: Integrate with Xero
//       // 'advertisingCosts',
//     ],
//     groupByDimension: FactTables.appointmentEvent.patient.referrer,
//   }),
// };

// const COST_PER_CONVERSION: IChartConfig = {
//   builderData: generateBuilderData({
//     measures: [
//       // TODO: Consolidate with alternate data sources
//       // 'costPerConversion',
//       // TODO: Track treatment plan value
//       // 'meanTreatmentPlanValue',
//     ],
//     groupByDimension: FactTables.appointmentEvent.patient.referrer,
//   }),
//   labels: {
//     title: 'Conversion Cost Vs. Treatment Value',
//   },
// };

// const MEAN_TREATMENT_PLAN_VALUE: IChartConfig = {
//   builderData: generateBuilderData({
//     measures: [
//       // TODO: Track treatment plan value
//       // 'meanTreatmentPlanValue',
//     ],
//     groupByDimension: FactTables.appointmentEvent.patient.referrer,
//   }),
//   labels: {
//     title: 'Average Treatment Plan Value',
//   },
// };

const CHANNELS_CHART_DATA: IChartConfig = {
  type: CustomChartType.Pie,
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.newPatient
          .groupBy(FactTables.appointmentEvent.patient.referrer)
          .reduceByCount()
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.patient.referrer,
  }),
  labels: {
    title: 'Acquisition Sources',
  },
};

// const CONVERSIONS_CHART_DATA: IChartConfig = {
//   builderData: generateBuilderData({
//     measures: [
//       // 'nextVisitsBooked',
//       FactTables.appointmentCompleted.nextAppointmentBooked
//         .filterBy(new BooleanMeasureFilter(true))
//         .reduceByCount(),
//       // 'noNextAppointment',
//       FactTables.appointmentCompleted.nextAppointmentBooked
//         .filterBy(new BooleanMeasureFilter(false))
//         .reduceByCount(),
//       // 'cancellations',
//       FactTables.appointmentEvent.scopeBy(FactTables.appointmentEvent.cancelled)
//         .count.setLabel('Cancellations'),
//     ],
//     groupByDimension: FactTables.appointmentEvent.patient.referrer,
//   }),
//   labels: {
//     title: 'Patient Behaviour',
//   },
// };

const TIMELINE_CHART_OPTIONS: Partial<IChartOptions> = {
  vAxis: {
    viewWindow: {
      min: 0,
    },
  },
  legend: {
    position: 'bottom',
    textStyle: {
      fontSize: '14',
    },
  },
  chartArea: {
    top: 0,
    right: 20,
    bottom: 50,
    left: 20,
  },
};
