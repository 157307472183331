import { NgModule } from '@angular/core';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ChartTabGroupComponent } from './chart-tab-group/chart-tab-group.component';
import { ChartTabComponent } from './chart-tab/chart-tab.component';
import { ChartComponent } from './chart/chart.component';
import { D3ChartComponent } from './d3-chart/d3-chart.component';
import { DcChartComponent } from './dc-chart/dc-chart.component';
import { DynamicD3ChartComponent } from './dynamic-d3-chart/dynamic-d3-chart.component';
import { DynamicDcChartComponent } from './dynamic-dc-chart/dynamic-dc-chart.component';
import { MetricPipe } from './metric.pipe';
import { ValueWithIconComponent } from './value-with-icon/value-with-icon.component';

@NgModule({
  imports: [NgSharedModule, NgPrincipleSharedModule],
  declarations: [
    ChartComponent,
    ChartTabComponent,
    ChartTabGroupComponent,
    MetricPipe,
    DcChartComponent,
    D3ChartComponent,
    DynamicDcChartComponent,
    DynamicD3ChartComponent,
    ValueWithIconComponent,
  ],
  exports: [
    ChartComponent,
    ChartTabComponent,
    ChartTabGroupComponent,
    MetricPipe,
    DcChartComponent,
    ValueWithIconComponent,
  ],
})
export class ReportingCoreModule {}
