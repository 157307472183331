import { type IReffable } from '@principle-theorem/shared';

export interface IQuickChartingConfigurations {
  conditions: IReffable[];
  treatments: IReffable[];
  multiTreatments: IReffable[];
}

export const QUICK_CHART_CONDITIONS_KEYMAP: string[] = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
];

export const QUICK_CHART_TREATMENTS_KEYMAP: string[] = [
  'q',
  'w',
  'e',
  'r',
  't',
  'y',
  'u',
  'i',
  'o',
  'p',
];
