import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WithRef } from '@principle-theorem/shared';
import { IPractice } from '@principle-theorem/principle-core/interfaces';

@Component({
    selector: 'pr-practice-details',
    imports: [CommonModule],
    templateUrl: './practice-details.component.html',
    styleUrl: './practice-details.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PracticeDetailsComponent {
  @Input() practice: WithRef<IPractice>;
}
