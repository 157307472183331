import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  type OnDestroy,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  Validators,
  type ControlValueAccessor,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { initVersionedSchema, MixedSchema } from '@principle-theorem/editor';
import {
  type EditorMenuItemComponent,
  type MenuButtonLoaderFn,
} from '@principle-theorem/ng-prosemirror';
import { DialogPresets, TypedFormControl } from '@principle-theorem/ng-shared';
import {
  TemplateType,
  type ITemplateContext,
} from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject, Subject, type Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  ContextPreviewDialogComponent,
  type IContextPreviewDialogData,
} from '../context-preview-dialog/context-preview-dialog.component';

@Component({
  selector: 'pr-template-editor-textarea',
  templateUrl: './template-editor-textarea.component.html',
  styleUrls: ['./template-editor-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TemplateEditorTextareaComponent),
      multi: true,
    },
  ],
})
export class TemplateEditorTextareaComponent
  implements ControlValueAccessor, OnDestroy
{
  private _onDestroy$ = new Subject<void>();
  context$ = new BehaviorSubject<ITemplateContext>({});
  templateType$ = new BehaviorSubject(TemplateType.PlainText);
  menuItems: MenuButtonLoaderFn<EditorMenuItemComponent, object>[];
  isHTMLTemplate$: Observable<boolean>;
  formControl = new TypedFormControl<MixedSchema>(
    initVersionedSchema(),
    Validators.required
  );
  templateVariablesTooltip =
    'Click here to see variables that will allow you to personalise your message. Copy and paste the variable into your message and Principle will fill this out based on the information collected on the Patient Profile.';

  @Input() required = true;
  @Input() editMode = true;

  @Input()
  set context(context: ITemplateContext) {
    this.context$.next(context);
  }

  @Input()
  set templateType(templateType: TemplateType) {
    if (templateType) {
      this.templateType$.next(templateType);
    }
  }

  constructor(
    private _cdr: ChangeDetectorRef,
    private _dialog: MatDialog
  ) {
    this.isHTMLTemplate$ = this.templateType$.pipe(
      map((templateType) => templateType === TemplateType.Html)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  // eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
  onTouched = (): void => {};

  writeValue(content: MixedSchema): void {
    if (!content) {
      return;
    }
    this.formControl.setValue(content);
    // this.stateChanges.next();
    this._cdr.detectChanges();
  }

  registerOnChange(fn: () => void): void {
    this.formControl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  async openContextPreviewDialog(): Promise<void> {
    const data: IContextPreviewDialogData = {
      context: this.context$.value,
    };
    await this._dialog
      .open(ContextPreviewDialogComponent, DialogPresets.medium({ data }))
      .afterClosed()
      .toPromise();
  }
}
