<pt-feature-info />
<pt-breadcrumbs current="Appointment Requests" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="layout-padding">
  <ng-container *ngIf="appointmentRequests$ | async as appointmentRequests">
    <div fxLayout="row-reverse" class="layout-padding">
      <a
        [href]="onlineBookingLink$ | ngrxPush"
        target="_blank"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="8px"
      >
        <span>View Booking Link</span>
        <mat-icon>open_in_new</mat-icon>
      </a>
    </div>
    <pr-appointment-requests-list
      *ngIf="appointmentRequests.length; else noAppointmentRequests"
      [appointmentRequests]="appointmentRequests"
    />
  </ng-container>
</div>

<ng-template #noAppointmentRequests>
  <pr-empty-state image="appointment" title="appointment requests" />
</ng-template>
