import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { Appointment, TreatmentStep } from '@principle-theorem/principle-core';
import {
  ICandidateCalendarEvent,
  IScheduleSummaryEventable,
  ITreatmentCategory,
} from '@principle-theorem/principle-core/interfaces';
import { Firestore, WithRef } from '@principle-theorem/shared';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TimelinePendingGapBadgeComponent } from '../timeline-pending-gap-badge/timeline-pending-gap-badge.component';

@Component({
  selector: 'pr-timeline-gap-tooltip',
  templateUrl: './timeline-gap-tooltip.component.html',
  styleUrls: ['./timeline-gap-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TimelinePendingGapBadgeComponent, NgSharedModule],
})
export class TimelineGapTooltipComponent {
  pendingGap$ = new ReplaySubject<IScheduleSummaryEventable>(1);

  @Input()
  set pendingGap(pendingGap: IScheduleSummaryEventable) {
    if (pendingGap) {
      this.pendingGap$.next(pendingGap);
    }
  }

  constructor(private _globalStore: GlobalStoreService) {}

  getTreatmentCategory$(
    candidate: WithRef<ICandidateCalendarEvent>
  ): Observable<WithRef<ITreatmentCategory> | undefined> {
    return Firestore.doc$(candidate.candidate.appointment).pipe(
      switchMap((appointment) => Appointment.treatmentStep$(appointment)),
      map(({ display }) => TreatmentStep.defaultDisplayRef(display)),
      switchMap((categoryRef) =>
        categoryRef
          ? this._globalStore.getTreatmentCategory$(categoryRef)
          : of(undefined)
      )
    );
  }
}
