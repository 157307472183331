<div class="h-full w-full bg-white">
  <div
    class="h-full w-full rounded-lg"
    [ngClass]="{
      highlighted: shouldShowHighlighted$ | async,
      'overflow-visible': isSelected(),
      'overflow-hidden': !isSelected()
    }"
  >
    <ng-content />
  </div>
  <pr-interactive-timeline-drag-tooltip [summary]="summary$ | ngrxPush" />
  <div #startHandle class="resize-handle resize-handle-start"></div>
  <div #endHandle class="resize-handle resize-handle-end"></div>
</div>
