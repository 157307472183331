import {
  DayOfWeekFormGroup,
  TypedFormControl,
  TypedFormGroup,
  formControlChanges$,
  type DayOfWeekFormData,
} from '@principle-theorem/ng-shared';
import { RecurrencePattern } from '@principle-theorem/principle-core';
import {
  CustomRecurrenceFrequency,
  DayOfWeek,
  EndingType,
  Frequency,
  RECURRENCE_FREQUENCY_DISPLAY_MAP,
  getEnumValues,
  toMoment,
  type IRecurrencePattern,
} from '@principle-theorem/shared';
import { get, pick } from 'lodash';
import * as moment from 'moment-timezone';
import { type Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface IRecurrenceFormData
  extends Pick<
    IRecurrencePattern,
    'occurrenceCount' | 'endingType' | 'seperationCount' | 'customFrequencyType'
  > {
  endingDate?: moment.Moment;
  monthlyOption?: string;
  daysOfWeek?: DayOfWeekFormData;
}

export class RecurrenceFormGroup extends TypedFormGroup<IRecurrenceFormData> {
  endings: EndingType[] = [EndingType.Never, EndingType.Date, EndingType.Count];

  constructor(private _onDestroy$: Subject<void>) {
    super({
      seperationCount: new TypedFormControl(1),
      customFrequencyType: new TypedFormControl(
        RECURRENCE_FREQUENCY_DISPLAY_MAP[0].value
      ),
      monthlyOption: new TypedFormControl(),
      endingType: new TypedFormControl<EndingType>(EndingType.Never),
      endingDate: new TypedFormControl<moment.Moment | undefined>({
        value: moment().add('1', 'week'),
        disabled: true,
      }).withGuard(moment.isMoment),
      occurrenceCount: new TypedFormControl({ value: 10, disabled: true }),
      daysOfWeek: new DayOfWeekFormGroup(),
    });

    formControlChanges$(this.controls.endingType)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((endingType) => {
        switch (endingType) {
          case EndingType.Date:
            this.controls.occurrenceCount.disable();
            this.controls.endingDate.enable();
            break;
          case EndingType.Count:
            this.controls.endingDate.disable();
            this.controls.occurrenceCount.enable();
            break;
          default:
            this.controls.occurrenceCount.disable();
            this.controls.endingDate.disable();
            break;
        }
      });
  }

  isWeekly(): boolean {
    return (
      this.controls.customFrequencyType.value ===
      CustomRecurrenceFrequency.Weekly
    );
  }

  isMonthly(): boolean {
    return (
      this.controls.customFrequencyType.value ===
      CustomRecurrenceFrequency.Monthly
    );
  }

  /**
   * Set the form controls ending values
   * @param pattern IRecurrencePattern
   */
  setEndByType(pattern: Partial<IRecurrencePattern>): void {
    if (pattern.endingType === EndingType.Count) {
      this.controls.endingType.setValue(EndingType.Count);
      this.controls.occurrenceCount.setValue(pattern.occurrenceCount);
    }
    if (pattern.endingType === EndingType.Date) {
      this.controls.endingType.setValue(EndingType.Date);
      this.controls.endingDate.setValue(
        pattern.endingDate ? toMoment(pattern.endingDate) : undefined
      );
    }
  }

  /**
   * Turn selected days of week into array for pattern
   * @returns DayOfWeek[]
   */
  getDaysofWeek(): DayOfWeek[] {
    const dayOfWeekGroup = this.controls.daysOfWeek.value;
    return getEnumValues(DayOfWeek).filter((dayOfWeek) => {
      const isSelected = get(dayOfWeekGroup, dayOfWeek, false);
      return isSelected;
    });
  }

  /**
   * Set selected days of week passing in the pattern's days of week
   * @param days DayOfWeek[]
   * @returns void
   */
  setSelectedDays(days: DayOfWeek[]): void {
    const dayOfWeekGroup = this.controls
      .daysOfWeek as TypedFormGroup<DayOfWeekFormData>;
    days.forEach((day: DayOfWeek) => {
      dayOfWeekGroup.controls[day].setValue(true);
    });
  }

  /**
   * Process form values into a recurrence pattern
   * @returns IRecurrencePattern
   */
  generateRecurrencePattern(): IRecurrencePattern {
    let pattern: IRecurrencePattern = RecurrencePattern.init({
      frequencyType: Frequency.Custom,
      customFrequencyType: this.controls.customFrequencyType.value ?? undefined,
      seperationCount: this.controls.seperationCount.value ?? undefined,
      daysOfWeek: this.getDaysofWeek(),
    });

    if (
      this.controls.endingType.value === EndingType.Count &&
      this.controls.occurrenceCount.value
    ) {
      pattern = RecurrencePattern.updateEndAfter(
        pattern,
        this.controls.occurrenceCount.value
      );
    }

    if (
      this.controls.endingType.value === EndingType.Date &&
      this.controls.endingDate.value
    ) {
      pattern = RecurrencePattern.updateEndOn(
        pattern,
        toMoment(this.controls.endingDate.value)
      );
    }

    return pattern;
  }

  patchRecurrencePattern(pattern: Partial<IRecurrencePattern>): void {
    this.patchValue({
      ...pick(pattern, ['seperationCount', 'customFrequencyType']),
    });
    this.setEndByType(pattern);
    this.setSelectedDays(pattern.daysOfWeek ?? []);
  }
}
