import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Invoice } from '@principle-theorem/principle-core';
import {
  type IAccountCredit,
  type IAccountCreditExtendedData,
  type IInvoice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

export interface IAmendInvoiceConfirmDialogData {
  invoice: WithRef<IInvoice>;
}

export interface IInvoiceCreditTransactionsPair {
  invoice: WithRef<IInvoice>;
  transactions: WithRef<ITransaction<IAccountCreditExtendedData>>[];
}

@Component({
    selector: 'pr-amend-invoice-confirm-dialog',
    templateUrl: './amend-invoice-confirm-dialog.component.html',
    styleUrls: ['./amend-invoice-confirm-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AmendInvoiceConfirmDialogComponent {
  credits$: Observable<WithRef<IAccountCredit>[]>;

  constructor(@Inject(MAT_DIALOG_DATA) data: IAmendInvoiceConfirmDialogData) {
    this.credits$ = Invoice.getExistingAccountCredits$(data.invoice);
  }
}
