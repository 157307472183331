import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {
  extendSortingDataAccessor,
  ObservableDataSource,
  timestampSortingAccessor,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  Invoice,
  TransactionOperators,
} from '@principle-theorem/principle-core';
import { type IInvoiceReportRecord } from '@principle-theorem/reporting';
import {
  ITimePeriod,
  toTimePeriod,
  type Timestamp,
  filterUndefined,
} from '@principle-theorem/shared';
import { DAY_MONTH_YEAR_FORMAT, toMoment } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { OutstandingInvoicesReportFacade } from '../store/outstanding-invoices.facade';
import {
  type IPendingProviderRow,
  OutstandingInvoicesToCSV,
  toPendingProviders,
} from './outstanding-invoices-to-csv';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-invoice-report-table',
    templateUrl: './invoice-report-table.component.html',
    styleUrls: ['./invoice-report-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InvoiceReportTableComponent implements OnDestroy {
  readonly dateFormat = DAY_MONTH_YEAR_FORMAT;
  trackByProvider = TrackByFunctions.field('provider');
  dataSource: ObservableDataSource<IInvoiceReportRecord>;
  csvTranslator = new OutstandingInvoicesToCSV();
  dateRange$: Observable<ITimePeriod>;
  displayedColumns: string[] = [
    'createdAt',
    'status',
    'invoice',
    'patient.name',
    'issuedAt',
    'due',
    'amount',
    'discounts',
    'outstanding',
    'pending',
  ];

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor(public reportFacade: OutstandingInvoicesReportFacade) {
    this.dataSource = new ObservableDataSource(this.reportFacade.results$);
    this.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, sortHeaderId) => this._sortingDataAccessor(data, sortHeaderId)
    );
    this.dateRange$ = this.reportFacade.query$.pipe(
      filterUndefined(),
      map((query) => toTimePeriod(query.startDate, query.endDate))
    );
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  amount(record: IInvoiceReportRecord): number {
    return Invoice.total(record.invoice);
  }

  discounts(record: IInvoiceReportRecord): number {
    return new TransactionOperators(record.transactions).discounts();
  }

  outstanding(record: IInvoiceReportRecord): number {
    return Invoice.balance(record.invoice, record.transactions);
  }

  pendingProviders(record: IInvoiceReportRecord): IPendingProviderRow[] {
    return toPendingProviders(record);
  }

  isOverdue(dueDate: Timestamp | undefined): boolean {
    return dueDate ? toMoment(dueDate).isBefore(moment()) : false;
  }

  private _sortingDataAccessor(
    record: IInvoiceReportRecord,
    sortHeaderId: string
  ): string | number | undefined {
    switch (sortHeaderId) {
      case 'createdAt':
        return timestampSortingAccessor(record.invoice.createdAt);
      case 'issuedAt':
        return timestampSortingAccessor(record.invoice.issuedAt);
      case 'due':
        return timestampSortingAccessor(record.invoice.due);
      case 'amount':
        return this.amount(record);
      case 'outstanding':
        return this.outstanding(record);
      case 'discounts':
        return this.discounts(record);
      default:
        return;
    }
  }
}
