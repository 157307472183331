import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'pr-profile-card-buttons',
    template: '<ng-content></ng-content>',
    standalone: false
})
export class ProfileCardButtonsComponent {
  @HostBinding('class') classes = 'pr-profile-card-buttons';
}
