<mat-toolbar color="accent">
  Treat {{ data.condition.config.name }}
</mat-toolbar>

<mat-dialog-content>
  <mat-form-field class="w-full">
    <mat-label>Available Surfaces</mat-label>
    <mat-chip-grid #chipGrid>
      <mat-chip-row
        *ngFor="let summary of summaries$ | async; trackBy: trackBySummary"
        (removed)="removeSurface(summary)"
      >
        {{ summary | map : asCompact }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-grid>

    <input
      placeholder="Treat surfaces..."
      #summaryInput
      [formControl]="summaryCtrl"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    />

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-option
        *ngFor="
          let summary of filteredSummaries$ | async;
          trackBy: trackBySummary
        "
        [value]="summary"
      >
        {{ asCompact(summary) }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <h3 class="!m-0">Treatment Options</h3>
  <ng-container *ngIf="matchingTreatments$ | async as matchingTreatments">
    <mat-selection-list #treatmentsList>
      <mat-list-option
        *ngFor="let treatment of matchingTreatments; trackBy: trackByTreatment"
        [value]="treatment"
      >
        <pr-search-result-charted-item [item]="treatment" />
      </mat-list-option>
    </mat-selection-list>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
    <button mat-flat-button color="primary" (click)="save()">Treat</button>
  </pt-buttons-container>
</mat-dialog-actions>
