import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { getDownloadURL, ref, Storage } from '@angular/fire/storage';
import {
  getImageAtSize,
  ImageSize,
  NgSharedModule,
} from '@principle-theorem/ng-shared';
import {
  IBrand,
  IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  shareReplayCold,
  WithRef,
} from '@principle-theorem/shared';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { PracticeDetailsComponent } from '../practice-details/practice-details.component';

@Component({
  selector: 'pr-patient-portal-header',
  templateUrl: './patient-portal-header.component.html',
  styleUrl: './patient-portal-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NgSharedModule, PracticeDetailsComponent],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex flex-col' },
})
export class PatientPortalHeaderComponent {
  private _storage = inject(Storage);
  brand$ = new ReplaySubject<WithRef<IBrand> | undefined>(1);
  practice$ = new ReplaySubject<WithRef<IPractice> | undefined>(1);
  brandLogoUrl$: Observable<string | undefined>;

  @Input()
  set brand(brand: WithRef<IBrand>) {
    this.brand$.next(brand);
  }

  @Input()
  set practice(practice: WithRef<IPractice>) {
    this.practice$.next(practice);
  }

  constructor() {
    this.brandLogoUrl$ = this.brand$.pipe(
      filterUndefined(),
      switchMap((brand) => this._logoUrl$(brand, this._storage))
    );
  }

  private _logoUrl$(
    brand: WithRef<IBrand>,
    storage: Storage,
    size: ImageSize = ImageSize.Medium
  ): Observable<string | undefined> {
    if (!brand.logoUrl) {
      return of(undefined);
    }

    const logoRef = ref(storage, getImageAtSize(brand.logoUrl, size, 'webp'));
    const fallbackRef = ref(storage, brand.logoUrl);

    return from(getDownloadURL(logoRef)).pipe(
      catchError(() => getDownloadURL(fallbackRef)),
      shareReplayCold()
    );
  }
}
