import { colord, extend } from 'colord';
import a11yPlugin from 'colord/plugins/a11y';
import { COLOUR_PALLETTE } from './colour-pallettes';
import { random } from 'lodash';

/**
 * @param hexColour Background color to determine overlay based off
 * @param threshold Number between 0 and 1. Luminance breakpoint.
 */
export function exceedsLuminanceThreshold(
  hexColour: string,
  threshold: number
): boolean {
  const result = getCompositeLuminance(hexColour);
  return result > threshold;
}

/**
 * @param hexColour Background color to determine overlay based off
 * @param threshold Number between 0 and 1.
 *                  0 will gaurantee a black result
 *                  1 will gaurantee a white result
 * @param defaultColour Default fallback if an error is thrown
 * @returns Hexiecimal colour value. #fff or #000
 */
export function getTextOverlayColour(
  hexColour: string,
  threshold: number = 0.5,
  defaultColour: string = '#172433'
): string {
  try {
    return exceedsLuminanceThreshold(hexColour, threshold) ? '#000' : '#fff';
  } catch (e) {
    return defaultColour;
  }
}

function getCompositeLuminance(
  hexColour: string,
  baseColour = '#ffffff'
): number {
  extend([a11yPlugin]);
  const colour = colord(hexColour);
  const base = colord(baseColour);

  if (!colour.alpha() || colour.alpha() === 1) {
    return colour.luminance();
  }

  const blendedWithBase = colord({
    r: colour.rgba.r * colour.rgba.a + base.rgba.r * (1 - colour.rgba.a),
    g: colour.rgba.g * colour.rgba.a + base.rgba.g * (1 - colour.rgba.a),
    b: colour.rgba.b * colour.rgba.a + base.rgba.b * (1 - colour.rgba.a),
  });

  return blendedWithBase.luminance();
}

export function randomHexColour(): string {
  return COLOUR_PALLETTE[random(0, COLOUR_PALLETTE.length - 1)];
}

export enum Colour {
  BlueGrey = '#3A4F62',
  Red = '#CD6561',
  Orange = '#DB966B',
  Yellow = '#C4BA4E',
  LightBlue = '#80DFFF',
  Blue = '#1E8BC3',
  Green = '#62A676',
  Teal = '#2C8585',
}
