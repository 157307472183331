import {
  IPatientForm,
  FormLayoutElementType,
  FormSchemaPropertyType,
  PatientFormStatus,
  ICustomFormConfiguration,
  IPatientFormSchema,
  PatientFormType,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  Properties,
  toTimestamp,
} from '@principle-theorem/shared';
import { MockNamedDocument } from '@principle-theorem/testing';

export class MedicalHistoryMock
  extends BaseFirestoreMock
  implements Properties<IPatientForm>
{
  form = MEDICAL_HISTORY_MOCK_DATA;
  status = PatientFormStatus.Submitted;
  statusHistory = [];
  formType = PatientFormType.CustomForm;
  issuedAt = toTimestamp();
  template = MockNamedDocument<ICustomFormConfiguration>();
}

export const MEDICAL_HISTORY_MOCK_DATA: IPatientFormSchema = {
  date: toTimestamp(),
  schema: {
    type: 'object',
    properties: {
      medicalHistory: {
        type: 'object',
        properties: {
          conditions: {
            type: 'object',
            properties: {
              bleeding: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Abnormal / excessive bleeding',
              },
              anaemia: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Anaemia',
              },
              arthritis: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Arthritis',
              },
              heartCondition: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Artificial heart valve / valve defect',
              },
              asthma: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Asthma',
              },
              bloodPressure: {
                type: FormSchemaPropertyType.String,
                title: 'Blood pressure',
                enum: ['N/A', 'High', 'Low'],
                default: 'N/A',
                required: true,
              },
              biphosphonate: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Biphosphonate medication',
              },
              cardiacSurgery: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Cardiac surgery / pacemaker',
              },
              diabetes: {
                type: FormSchemaPropertyType.String,
                title: 'Diabetes',
                enum: ['N/A', 'Type I', 'Type II'],
                default: 'N/A',
                required: true,
              },
              epilepsy: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Epilepsy',
              },
              hasGastrointestinalDisorder: {
                type: FormSchemaPropertyType.Boolean,
                title: 'I have a gastrointestinal disorder',
              },
              gastrointestinalDisorder: {
                type: FormSchemaPropertyType.String,
                title: 'Gastrointestinal disorder',
              },
              immuneDeficiency: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Immune deficiency',
              },
              hasJointReplacement: {
                type: FormSchemaPropertyType.Boolean,
                title: 'I have had a joint replacement',
              },
              replacedJoint: {
                type: FormSchemaPropertyType.String,
                title: 'Joint replaced',
              },
              hepatitis: {
                type: FormSchemaPropertyType.String,
                title: 'Hepatitis',
                enum: ['N/A', 'A', 'B', 'C', 'D'],
                default: 'N/A',
                required: true,
              },
              hiv: {
                type: FormSchemaPropertyType.Boolean,
                title: 'HIV',
              },
              isSmoker: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Past or Current smoker',
              },
              amountSmoked: {
                type: FormSchemaPropertyType.String,
                title: 'Approx. amount currently smoked',
              },
              kidneyDisease: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Kidney / Liver disease',
              },
              heartBurn: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Reflux / Heart Burn',
              },
              rheumaticFever: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Rheumatic Fever',
              },
              sleepApnoea: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Snoring or Sleep apnoea',
              },
              steroidMedication: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Steroid medication',
              },
              stroke: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Stroke',
              },
              thyroidDisorder: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Thyroid disorder',
              },
              other: {
                type: FormSchemaPropertyType.String,
                title: 'Any other medical conditions or major surgeries?',
              },
              pregnancyDueDate: {
                type: FormSchemaPropertyType.String,
                title: 'Due Date',
              },
              hasCancer: {
                type: FormSchemaPropertyType.Boolean,
                title: 'I have cancer',
              },
              cancerType: {
                type: FormSchemaPropertyType.String,
                title: 'Cancer type',
              },
              cancerTreatmentChemotherapy: {
                type: FormSchemaPropertyType.Boolean,
                title: 'I have had/ will have Chemotherapy',
              },
              cancerTreatmentRadiotherapy: {
                type: FormSchemaPropertyType.Boolean,
                title: 'I have had/ will have Radiotherapy',
              },
              isPregnant: {
                type: FormSchemaPropertyType.Boolean,
                title: 'I am pregnant',
              },
              hasPsychiatricDisorder: {
                type: FormSchemaPropertyType.Boolean,
                title: 'I have a psychiatric disorder',
              },
              psychiatricDisorderType: {
                type: FormSchemaPropertyType.String,
                title: 'Psychiatric disorder',
              },
            },
          },
          medications: {
            type: FormSchemaPropertyType.String,
            title: 'Medications?',
          },
          allergies: {
            type: 'object',
            properties: {
              penicillin: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Penicillin',
              },
              aspirin: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Aspirin',
              },
              codeine: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Codeine',
              },
              chlorhexidine: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Chlorhexidine',
              },
              iodine: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Iodine',
              },
              latex: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Latex',
              },
              other: {
                type: FormSchemaPropertyType.String,
                title: 'Other',
              },
            },
          },
        },
      },
      dentalHistory: {
        type: 'object',
        properties: {
          reason: {
            type: FormSchemaPropertyType.String,
            title: 'Reason for attending or any specific concerns',
          },
          antibioticsBeforeTreatment: {
            type: FormSchemaPropertyType.Boolean,
            title:
              'Do you need to take antibiotics before any dental treatment?',
          },
          dentalAnxiety: {
            type: FormSchemaPropertyType.Boolean,
            title:
              'Do you generally feel anxious about visiting the dentist or hygienist?',
          },
          lastVisit: {
            type: FormSchemaPropertyType.String,
            title: 'Last Dental Visit',
          },
          conditions: {
            type: 'object',
            properties: {
              toothache: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Toothache / pain',
              },
              discolouring: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Dark or discoloured teeth',
              },
              crowding: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Crooked / crowded teeth',
              },
              decay: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Decay / cavities',
              },
              brokenOrFilling: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Broken teeth or filling',
              },
              worn: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Worn down teeth',
              },
              sensitivity: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Cold sensitivity',
              },
              bleeding: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Bleeding gums',
              },
              halitosis: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Bad breath',
              },
              grinding: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Clenching or grinding',
              },
              musclePain: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Jaw / muscle pain',
              },
              ulcers: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Mouth Ulcers',
              },
            },
          },
          interestedIn: {
            type: 'object',
            properties: {
              whitening: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Teeth Whitening',
              },
              implants: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Dental Implants for missing teeth',
              },
              straightening: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Invisalign or teeth straightening',
              },
              veneers: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Veneers or cosmetic smile improvement',
              },
              wisdomRemoval: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Wisdom teeth removal',
              },
              sleepApnoea: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Anti-snoring and sleep apnoea treatment',
              },
              wrinkleRelaxers: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Wrinkle relaxers (eg, botox, dysport)',
              },
              fillers: {
                type: FormSchemaPropertyType.Boolean,
                title: 'Cosmetic dermal fillers (eg, lips, cheeks)',
              },
              dentalAnxietyManagement: {
                type: FormSchemaPropertyType.Boolean,
                title:
                  'Dental anxiety management options (eg, sedation, happy gas, etc)',
              },
            },
          },
          more: {
            type: FormSchemaPropertyType.String,
            title: `Is there anything else about previous dental experiences you'd like us to know?`,
          },
        },
      },
      termsAndConditions: {
        type: 'object',
        properties: {
          agreed: {
            type: FormSchemaPropertyType.Boolean,
            title: 'I agree to the terms and conditions',
            const: true,
          },
        },
        required: ['agreed'],
      },
    },
  },
  layout: [
    {
      key: 'medicalHistory',
      type: FormLayoutElementType.Div,
      notitle: true,
      htmlClass: 'layout-margin',
      items: [
        {
          key: 'medicalHistory.conditions',
          notitle: true,
          items: [
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.bleeding',
                },
                {
                  key: 'medicalHistory.conditions.anaemia',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.arthritis',
                },
                {
                  key: 'medicalHistory.conditions.heartCondition',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.asthma',
                },
                {
                  key: 'medicalHistory.conditions.biphosphonate',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.cardiacSurgery',
                },
                {
                  key: 'medicalHistory.conditions.epilepsy',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.immuneDeficiency',
                },
                {
                  key: 'medicalHistory.conditions.hiv',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.kidneyDisease',
                },
                {
                  key: 'medicalHistory.conditions.heartBurn',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.rheumaticFever',
                },
                {
                  key: 'medicalHistory.conditions.sleepApnoea',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.steroidMedication',
                },
                {
                  key: 'medicalHistory.conditions.stroke',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.thyroidDisorder',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.hasGastrointestinalDisorder',
                },
                {
                  key: 'medicalHistory.conditions.gastrointestinalDisorder',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.isSmoker',
                },
                {
                  key: 'medicalHistory.conditions.amountSmoked',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.hasJointReplacement',
                },
                {
                  key: 'medicalHistory.conditions.replacedJoint',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.hasPsychiatricDisorder',
                },
                {
                  key: 'medicalHistory.conditions.psychiatricDisorderType',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.hasCancer',
                },
                {
                  key: 'medicalHistory.conditions.cancerType',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.cancerTreatmentChemotherapy',
                },
                {
                  key: 'medicalHistory.conditions.cancerTreatmentRadiotherapy',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.isPregnant',
                },
                {
                  key: 'medicalHistory.conditions.pregnancyDueDate',
                  type: FormLayoutElementType.Date,
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.bloodPressure',
                },
                {
                  key: 'medicalHistory.conditions.diabetes',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.conditions.hepatitis',
                },
                {
                  key: 'medicalHistory.conditions.other',
                },
              ],
            },
          ],
        },
        {
          key: 'medicalHistory.medications',
          type: FormLayoutElementType.TextArea,
          placeholder: '(Including Supplements)',
        },
        {
          key: 'medicalHistory.allergies',
          title: 'Allergies?',
          items: [
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.allergies.penicillin',
                },
                {
                  key: 'medicalHistory.allergies.aspirin',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.allergies.codeine',
                },
                {
                  key: 'medicalHistory.allergies.chlorhexidine',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'medicalHistory.allergies.iodine',
                },
                {
                  key: 'medicalHistory.allergies.latex',
                },
              ],
            },
            {
              key: 'medicalHistory.allergies.other',
            },
          ],
        },
      ],
    },
    {
      type: FormLayoutElementType.Help,
      helpvalue: `<div class='mat-toolbar mat-toolbar-single-row'>Dental History & Concerns</div>`,
    },
    {
      key: 'dentalHistory',
      type: FormLayoutElementType.Div,
      notitle: true,
      htmlClass: 'layout-margin',
      items: [
        {
          key: 'dentalHistory.reason',
          type: FormLayoutElementType.TextArea,
        },
        {
          type: FormLayoutElementType.Flex,
          items: [
            {
              key: 'dentalHistory.lastVisit',
              type: FormLayoutElementType.Date,
            },
          ],
        },
        {
          type: FormLayoutElementType.Flex,
          items: [
            {
              key: 'dentalHistory.antibioticsBeforeTreatment',
            },
            {
              key: 'dentalHistory.dentalAnxiety',
            },
          ],
        },
        {
          key: 'dentalHistory.conditions',
          title: 'Please tick if any of the following apply to you',
          items: [
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'dentalHistory.conditions.toothache',
                },
                {
                  key: 'dentalHistory.conditions.discolouring',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'dentalHistory.conditions.crowding',
                },
                {
                  key: 'dentalHistory.conditions.decay',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'dentalHistory.conditions.brokenOrFilling',
                },
                {
                  key: 'dentalHistory.conditions.worn',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'dentalHistory.conditions.sensitivity',
                },
                {
                  key: 'dentalHistory.conditions.bleeding',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'dentalHistory.conditions.halitosis',
                },
                {
                  key: 'dentalHistory.conditions.grinding',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'dentalHistory.conditions.musclePain',
                },
                {
                  key: 'dentalHistory.conditions.ulcers',
                },
              ],
            },
          ],
        },
        {
          key: 'dentalHistory.interestedIn',
          title: 'Would you like more information on the following services?',
          items: [
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'dentalHistory.interestedIn.whitening',
                },
                {
                  key: 'dentalHistory.interestedIn.implants',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'dentalHistory.interestedIn.straightening',
                },
                {
                  key: 'dentalHistory.interestedIn.veneers',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'dentalHistory.interestedIn.wisdomRemoval',
                },
                {
                  key: 'dentalHistory.interestedIn.sleepApnoea',
                },
              ],
            },
            {
              type: FormLayoutElementType.Flex,
              items: [
                {
                  key: 'dentalHistory.interestedIn.wrinkleRelaxers',
                },
                {
                  key: 'dentalHistory.interestedIn.fillers',
                },
              ],
            },
            {
              key: 'dentalHistory.interestedIn.dentalAnxietyManagement',
            },
          ],
        },
        {
          key: 'dentalHistory.more',
          type: FormLayoutElementType.TextArea,
        },
      ],
    },
    {
      type: FormLayoutElementType.Help,
      helpvalue:
        '<div class="mat-toolbar mat-toolbar-single-row">Consent & Signature</div>',
    },
    {
      key: 'termsAndConditions',
      type: FormLayoutElementType.Div,
      notitle: true,
      htmlClass: 'layout-margin',
      items: [
        {
          type: FormLayoutElementType.Help,
          helpvalue: `
          \n <p class="mat-caption">
          \n All personal information collected is confidential and handled in
          \n      accordance to our privacy policy. See
          \n      <a href="https://principle.dental/legal/privacy-policy" target="_blank">principle.dental/legal/privacy-policy</a>
          \n    </p>
          \n    <p class="mat-caption">
          \n      By signing this form, you acknowledge and agree that: a) you have
          \n      accurately completed this pre-clinical questionnaire to the best of your
          \n      knowledge b) you consent to treatment agreed upon by you to be carried
          \n      out by the dentist and their staff c) you assume full financial
          \n      responsibility for treatment and d) payment is due in full at the time
          \n      of service unless other arrangements have been made.
          \n    </p>
          \n    <p class="mat-caption">
          \n      Cancellation policy: 48hrs notice is required for cancelling or
          \n      rebooking appointments, otherwise it may incur a cancellation fee or
          \n      refusal to reschedule.
          \n    </p>
          \n`,
        },
        {
          key: 'termsAndConditions.agreed',
        },
      ],
    },
  ],
  data: {
    medicalHistory: {
      conditions: {
        anaemia: false,
        arthritis: false,
        bleeding: false,
        heartCondition: false,
        asthma: true,
        biphosphonate: false,
        cardiacSurgery: false,
        epilepsy: false,
        hasGastrointestinalDisorder: false,
        immuneDeficiency: false,
        hasJointReplacement: false,
        hiv: false,
        isSmoker: false,
        kidneyDisease: false,
        heartBurn: true,
        rheumaticFever: false,
        sleepApnoea: true,
        steroidMedication: false,
        stroke: false,
        thyroidDisorder: false,
        hasCancer: false,
        cancerTreatmentChemotherapy: false,
        cancerTreatmentRadiotherapy: false,
        isPregnant: false,
        hasPsychiatricDisorder: false,
        diabetes: 'N/A',
        bloodPressure: 'N/A',
        hepatitis: 'N/A',
      },
      allergies: {
        penicillin: true,
        aspirin: false,
        codeine: false,
        chlorhexidine: false,
        iodine: false,
        latex: false,
      },
    },
    dentalHistory: {
      reason: 'Noticed blood when brushing',
      antibioticsBeforeTreatment: false,
      dentalAnxiety: true,
      lastVisit: '2018-01-17',
      conditions: {
        toothache: false,
        discolouring: false,
        crowding: false,
        decay: false,
        brokenOrFilling: false,
        worn: false,
        sensitivity: true,
        bleeding: true,
        halitosis: false,
        grinding: false,
        musclePain: false,
        ulcers: false,
      },
      interestedIn: {
        whitening: true,
        implants: false,
        straightening: true,
        veneers: false,
        wisdomRemoval: false,
        sleepApnoea: false,
        wrinkleRelaxers: false,
        fillers: false,
        dentalAnxietyManagement: false,
      },
      more: 'No',
    },
    termsAndConditions: {
      agreed: true,
    },
  },
};
