import {
  type InlineNodes,
  type ITextNodeSchema,
  type NodeSchema,
  type RawSchemaNodes,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import { Interaction, toMention } from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IEvent,
  type IInteractionV2,
  InteractionType,
  type IStaffer,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_TIME_FORMAT,
  durationToHumanisedTime,
  type INamedDocument,
  isSameRef,
  joinArrayWithItem,
  type Timezone,
  toMoment,
  toMomentTz,
  type WithRef,
} from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { compact, flatten } from 'lodash';
import * as moment from 'moment-timezone';

interface IEventChange<P, N = P> {
  previous: P;
  next: N;
  hasChanged: boolean;
}

export class AppointmentRescheduleInteractionBuilder {
  static getDurationChange(
    appointment: WithRef<IAppointment>,
    newEvent: IEvent
  ): IEventChange<number> {
    const next = toMoment(newEvent.to).diff(toMoment(newEvent.from), 'minutes');
    const previous = appointment.event
      ? toMoment(appointment.event.to).diff(
          toMoment(appointment.event.from),
          'minutes'
        )
      : 0;
    return { previous, next, hasChanged: next !== previous };
  }

  static getStartTimeChange(
    appointment: WithRef<IAppointment>,
    newEvent: IEvent
  ): IEventChange<Timestamp | undefined, Timestamp> {
    const previous = appointment.event?.from;
    const next = newEvent.from;

    const previousMoment = previous ? toMoment(previous) : undefined;
    const nextMoment = toMoment(next);
    const hasChanged = previousMoment
      ? !previousMoment.isSame(nextMoment, 'minute')
      : true;
    return { previous, next, hasChanged };
  }

  static getStafferChange(
    appointment: WithRef<IAppointment>,
    newEvent: IEvent
  ):
    | (ITextNodeSchema | NodeSchema<InlineNodes.Mention, RawSchemaNodes>)[]
    | undefined {
    const previous = appointment.event?.organiser;
    const next = newEvent.organiser;
    const hasChanged = !isSameRef(previous, next);
    const change = { previous, next, hasChanged };

    if (!change.hasChanged || !change.previous || !change.next) {
      return;
    }

    return [
      toTextContent(` changed practitioners from `),
      toMentionContent(toMention(change.previous, MentionResourceType.Staffer)),

      toTextContent(` to `),
      toMentionContent(toMention(change.next, MentionResourceType.Staffer)),
    ];
  }

  static buildRescheduleInteraction(
    appointment: WithRef<IAppointment>,
    newEvent: IEvent,
    owner: INamedDocument<IStaffer>,
    timezone: Timezone
  ): IInteractionV2 | undefined {
    const startTimeChange =
      AppointmentRescheduleInteractionBuilder.getStartTimeChange(
        appointment,
        newEvent
      );
    const dateFormatted = toMomentTz(startTimeChange.next, timezone).format(
      DATE_TIME_FORMAT
    );

    const durationChange =
      AppointmentRescheduleInteractionBuilder.getDurationChange(
        appointment,
        newEvent
      );

    const durationFormatted = durationToHumanisedTime(
      moment.duration(durationChange.next, 'minutes')
    );

    if (!startTimeChange.previous && !durationChange.previous) {
      return Interaction.init({
        title: [
          toMentionContent(toMention(owner, MentionResourceType.Staffer)),
          toTextContent(` scheduled appointment to ${dateFormatted}`),
          toTextContent(` for ${durationFormatted}`),
        ],
        type: InteractionType.AppointmentBook,
        owner,
      });
    }

    const stafferChangeInlineNode =
      AppointmentRescheduleInteractionBuilder.getStafferChange(
        appointment,
        newEvent
      );

    const startTimeChangeInlineNode = startTimeChange.hasChanged
      ? toTextContent(` rescheduled appointment to ${dateFormatted}`)
      : undefined;

    const durationChangeInlineNode = durationChange.hasChanged
      ? toTextContent(` changed appointment duration to ${durationFormatted}`)
      : undefined;

    const actionInlineNodes = joinArrayWithItem(
      compact([
        startTimeChangeInlineNode,
        durationChangeInlineNode,
        stafferChangeInlineNode,
      ]),
      toTextContent(` and`)
    );

    if (actionInlineNodes.length <= 0) {
      return;
    }

    return Interaction.init({
      title: [
        toMentionContent(toMention(owner, MentionResourceType.Staffer)),
        ...flatten(actionInlineNodes),
      ],
      type: InteractionType.AppointmentReschedule,
      owner,
    });
  }
}
