import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { DialogPresets, TrackByFunctions } from '@principle-theorem/ng-shared';
import { Practice } from '@principle-theorem/principle-core';
import { type ITyroTerminal } from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import {
  ISelectTerminalDialogData,
  SelectSmartpayTerminalComponent,
  toSelectedTerminal,
  type ISelectedTerminal,
} from './select-smartpay-terminal.component';
import { first } from 'lodash';

@Injectable()
export class SelectSmartpayTerminalService {
  trackByTerminal = TrackByFunctions.ref<WithRef<ITyroTerminal>>();

  constructor(
    private _dialog: MatDialog,
    private _currentScopeFacade: CurrentScopeFacade
  ) {}

  async getTerminalData(): Promise<ISelectedTerminal | undefined> {
    const practice = await snapshot(
      this._currentScopeFacade.currentPractice$.pipe(filterUndefined())
    );
    const terminals = await snapshot(Practice.smartpayTerminals$(practice));

    if (terminals.length === 1) {
      const terminal = first(terminals);
      if (!terminal) {
        return;
      }
      return toSelectedTerminal({
        terminal,
        isCurrentlyPaired: false,
        practiceRef: practice.ref,
      });
    }

    return this._dialog
      .open<
        SelectSmartpayTerminalComponent,
        ISelectTerminalDialogData,
        ISelectedTerminal
      >(
        SelectSmartpayTerminalComponent,
        DialogPresets.small({
          data: { terminals },
        })
      )
      .afterClosed()
      .toPromise();
  }
}
