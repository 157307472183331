import {
  Node as ProsemirrorNode,
  Fragment,
  type Mark,
  type MarkType,
  type NodeRange,
  type NodeType,
  type ResolvedPos,
} from '@tiptap/pm/model';
import {
  Selection,
  type EditorState,
  NodeSelection,
  Transaction,
} from '@tiptap/pm/state';
import { type AttributeSchema } from '../schema';
import { isString } from 'lodash';
import { Editor, EditorEvents, findParentNode } from '@tiptap/core';
import { Observable, fromEvent } from 'rxjs';

export type Range = { from: number; to: number };

export type ContentNodeWithPos = {
  start: number;
  depth: number;
} & NodeWithPos;

export type NodeWithPos = {
  pos: number;
  node: ProsemirrorNode;
};

export type DomAtPos = (pos: number) => { node: Node; offset: number };
export type FindPredicate = (node: ProsemirrorNode) => boolean;

export type Predicate = FindPredicate;
export type FindResult = ContentNodeWithPos | undefined;
export type NodeTypeParam = string | NodeType | NodeType[];

export function fromEditorEvents(editor: Editor): Observable<Editor> {
  return fromEvent<Editor>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    editor,
    'transaction' as keyof EditorEvents
  );
}

export function getMarkAttrs(
  state: EditorState,
  type: MarkType
): AttributeSchema | undefined {
  const { from, to } = state.selection;
  let marks: Mark[] = [];

  state.doc.nodesBetween(from, to, (node: ProsemirrorNode) => {
    marks = [...marks, ...node.marks];
  });

  const mark = marks.find((markItem) => markItem.type.name === type.name);

  if (mark) {
    return mark.attrs;
  }
}

export function getMarkRange(
  $pos: ResolvedPos,
  type: MarkType
): Range | undefined {
  const start = $pos.parent.childAfter($pos.parentOffset);

  if (!start.node) {
    return;
  }

  const link = start.node.marks.find((mark) => mark.type === type);
  if (!link) {
    return;
  }

  let startIndex = $pos.index();
  let startPos = $pos.start() + start.offset;
  let endIndex = startIndex + 1;
  let endPos = startPos + start.node.nodeSize;

  while (
    startIndex > 0 &&
    link.isInSet($pos.parent.child(startIndex - 1).marks)
  ) {
    startIndex -= 1;
    startPos -= $pos.parent.child(startIndex).nodeSize;
  }

  while (
    endIndex < $pos.parent.childCount &&
    link.isInSet($pos.parent.child(endIndex).marks)
  ) {
    endPos += $pos.parent.child(endIndex).nodeSize;
    endIndex += 1;
  }

  return { from: startPos, to: endPos };
}

export function markIsActive(state: EditorState, type: MarkType): boolean {
  const { from, $from, to, empty } = state.selection;

  if (empty) {
    return type.isInSet(state.storedMarks || $from.marks()) ? true : false;
  }
  return state.doc.rangeHasMark(from, to, type);
}

export function nodeEqualsType(
  types: NodeType | NodeType[],
  node: ProsemirrorNode
): boolean {
  return Array.isArray(types) ? types.includes(node.type) : node.type === types;
}

export function nodeTypeIsActive(
  state: EditorState,
  type: string | NodeType,
  attrs: object = {}
): boolean {
  const foundNode =
    findSelectedNodeOfType(type)(state.selection) ||
    findParentNode((node) => node.type === type)(state.selection);

  if (!Object.keys(attrs).length || !foundNode) {
    return !!foundNode;
  }

  return foundNode.node.hasMarkup(foundNode.node.type, attrs);
}

export function findSelectedNodeOfType(
  nodeType: string | NodeType | NodeType[]
): (selection: Selection) => ContentNodeWithPos | undefined {
  return (selection: Selection) => {
    if (!isNodeSelection(selection)) {
      return;
    }
    const { node, $from } = selection;
    if (equalNodeType(nodeType, node)) {
      return {
        node,
        pos: $from.pos,
        depth: $from.depth,
        start: $from.start(),
      };
    }
  };
}

export function isNodeSelection(
  selection: Selection
): selection is NodeSelection {
  return selection instanceof NodeSelection;
}

export function equalNodeType(
  nodeType: string | NodeType | NodeType[],
  node: ProsemirrorNode
): boolean {
  if (isString(nodeType)) {
    return node.type.name === nodeType;
  }
  return (
    (Array.isArray(nodeType) && nodeType.indexOf(node.type) > -1) ||
    node.type === nodeType
  );
}

export function nodeIsActive(
  state: EditorState,
  node: ProsemirrorNode
): boolean {
  const foundNode = getActiveNode(state, node.type);
  if (foundNode?.eq(node)) {
    return true;
  }

  return false;
}

export function selectionHasParentNode(
  state: EditorState,
  node: ProsemirrorNode
): boolean {
  const parentNode = findParentNodeOfType(node.type)(state.selection);

  if (parentNode?.node.eq(node)) {
    return true;
  }

  return false;
}

export function getActiveNode(
  state: EditorState,
  type: string | NodeType
): ProsemirrorNode | undefined {
  const foundNode =
    findSelectedNodeOfType(type)(state.selection) ||
    findParentNode((node) => {
      return isString(type) ? node.type.name === type : node.type === type;
    })(state.selection);

  if (!foundNode) {
    return;
  }

  return foundNode.node;
}

export function findNodePosition(
  doc: ProsemirrorNode,
  node: ProsemirrorNode
): ResolvedPos | undefined {
  let foundPosition: ResolvedPos | undefined;
  doc.descendants((decendantNode, position, _parent) => {
    if (decendantNode === node) {
      foundPosition = doc.resolve(position);
      return false;
    }
    return true;
  });
  return foundPosition;
}

export function findNodeRange(
  doc: ProsemirrorNode,
  node: ProsemirrorNode
): NodeRange | undefined {
  let foundPosition: NodeRange | undefined;
  doc.descendants((decendantNode, position, _parent) => {
    if (foundPosition) {
      return false;
    }
    if (decendantNode.eq(node)) {
      const from = doc.resolve(position + 1);
      const to = doc.resolve(position + 1 + decendantNode.content.size);
      foundPosition = from.blockRange(to) || undefined;
      return false;
    }
    return true;
  });
  return foundPosition;
}

export function findNodesOfType(
  doc: ProsemirrorNode | Fragment,
  nodeType: NodeType
): ProsemirrorNode[] {
  const foundNodes: ProsemirrorNode[] = [];
  doc.descendants((decendantNode, _position, _parent) => {
    if (decendantNode.type === nodeType) {
      foundNodes.push(decendantNode);
    }
    return true;
  });
  return foundNodes;
}

/**
 * Iterates over parent nodes starting from the given `$pos`, returning the closest node and its start position `predicate` returns truthy for. `start` points to the start position of the node, `pos` points directly before the node.
 *
 * @example
 * ```javascript
 * const predicate = node => node.type === schema.nodes.blockquote;
 * const parent = findParentNodeClosestToPos(state.doc.resolve(5), predicate);
 * ```
 */
export function findParentNodeClosestToPos(
  $pos: ResolvedPos,
  predicate: FindPredicate
): FindResult {
  for (let i = $pos.depth; i > 0; i--) {
    const node = $pos.node(i);
    if (predicate(node)) {
      return {
        pos: i > 0 ? $pos.before(i) : 0,
        start: $pos.start(i),
        depth: i,
        node,
      };
    }
  }
}

/**
 * Iterates over parent nodes, returning DOM reference of the closest node `predicate` returns truthy for.
 *
 * @example
 * ```javascript
 * const domAtPos = view.domAtPos.bind(view);
 * const predicate = node => node.type === schema.nodes.table;
 * const parent = findParentDomRef(predicate, domAtPos)(selection);
 * ```
 */
export function findParentDomRef(
  predicate: FindPredicate,
  domAtPos: DomAtPos
): (selection: Selection) => Node | undefined {
  return (selection) => {
    const parent = findParentNode(predicate)(selection);
    if (parent) {
      return findDomRefAtPos(parent.pos, domAtPos);
    }
  };
}

/**
 * Checks if there's a parent node `predicate` returns truthy for.
 *
 * @example
 * ```javascript
 * if (hasParentNode(node => node.type === schema.nodes.table)(selection)) {
 *   // ....
 * }
 * ```
 */
export function hasParentNode(
  predicate: FindPredicate
): (selection: Selection) => boolean {
  return (selection) => !!findParentNode(predicate)(selection);
}

/**
 * Iterates over parent nodes, returning closest node of a given `nodeType`. `start` points to the start position of the node, `pos` points directly before the node.
 *
 * @example
 * ```javascript
 * const parent = findParentNodeOfType(schema.nodes.paragraph)(selection);
 * ```
 */
export function findParentNodeOfType(
  nodeType: NodeTypeParam
): (selection: Selection) => FindResult {
  return (selection) => {
    return findParentNode((node) => equalNodeType(nodeType, node))(selection);
  };
}

/**
 * Iterates over parent nodes starting from the given `$pos`, returning closest node of a given `nodeType`. `start` points to the start position of the node, `pos` points directly before the node.
 *
 * @example
 * ```javascript
 * const parent = findParentNodeOfTypeClosestToPos(state.doc.resolve(10), schema.nodes.paragraph);
 * ```
 */
export function findParentNodeOfTypeClosestToPos(
  $pos: ResolvedPos,
  nodeType: NodeTypeParam
): FindResult {
  return findParentNodeClosestToPos($pos, (node: ProsemirrorNode) =>
    equalNodeType(nodeType, node)
  );
}

/**
 * Checks if there's a parent node of a given `nodeType`.
 *
 * @example
 * ```javascript
 * if (hasParentNodeOfType(schema.nodes.table)(selection)) {
 *   // ....
 * }
 * ```
 */
export function hasParentNodeOfType(
  nodeType: NodeTypeParam
): (selection: Selection) => boolean {
  return (selection) => {
    return hasParentNode((node) => equalNodeType(nodeType, node))(selection);
  };
}

/**
 * Iterates over parent nodes, returning DOM reference of the closest node of a given `nodeType`.
 *
 * @example
 * ```javascript
 * const domAtPos = view.domAtPos.bind(view);
 * const parent = findParentDomRefOfType(schema.nodes.codeBlock, domAtPos)(selection);
 * ```
 */
export function findParentDomRefOfType(
  nodeType: NodeTypeParam,
  domAtPos: DomAtPos
): (selection: Selection) => Node | undefined {
  return (selection) => {
    return findParentDomRef(
      (node) => equalNodeType(nodeType, node),
      domAtPos
    )(selection);
  };
}

/**
 * Returns position of the previous node.
 *
 * @example
 * ```javascript
 * const pos = findPositionOfNodeBefore(tr.selection);
 * ```
 */
export function findPositionOfNodeBefore(
  selection: Selection
): number | undefined {
  const { nodeBefore } = selection.$from;
  const maybeSelection = Selection.findFrom(selection.$from, -1);
  if (maybeSelection && nodeBefore) {
    // leaf node
    const parent = findParentNodeOfType(nodeBefore.type)(maybeSelection);
    if (parent) {
      return parent.pos;
    }
    return maybeSelection.$from.pos;
  }
}

/**
 * Returns DOM reference of a node at a given `position`. If the node type is of type `TEXT_NODE` it will return the reference of the parent node.
 *
 * @example
 * ```javascript
 * const domAtPos = view.domAtPos.bind(view);
 * const ref = findDomRefAtPos($from.pos, domAtPos);
 * ```
 */
export function findDomRefAtPos(position: number, domAtPos: DomAtPos): Node {
  const dom = domAtPos(position);
  const node = dom.node.childNodes[dom.offset];

  if (dom.node.nodeType === Node.TEXT_NODE && dom.node.parentNode) {
    return dom.node.parentNode;
  }

  if (!node || node.nodeType === Node.TEXT_NODE) {
    return dom.node;
  }

  return node;
}

/**
 * Returns a new transaction that inserts a given `content` at the current cursor position, or at a given `position`, if it is allowed by schema. If schema restricts such nesting, it will try to find an appropriate place for a given node in the document, looping through parent nodes up until the root document node.
 *
 * If `tryToReplace` is true and current selection is a NodeSelection, it will replace selected node with inserted content if its allowed by schema.
 *
 * If cursor is inside of an empty paragraph, it will try to replace that paragraph with the given content. If insertion is successful and inserted node has content, it will set cursor inside of that content.
 *
 * It will return an original transaction if the place for insertion hasn't been found.
 *
 * @example
 * ```javascript
 * const node = schema.nodes.extension.createChecked({});
 * dispatch(
 *   safeInsert(node)(tr)
 * );
 * ```
 */
export function safeInsert(
  content: ProsemirrorNode | Fragment,
  position?: number,
  tryToReplace?: boolean
): (tr: Transaction) => Transaction {
  return (tr) => {
    const hasPosition = typeof position === 'number';
    const { $from } = tr.selection;
    const $insertPos = hasPosition
      ? tr.doc.resolve(position)
      : isNodeSelection(tr.selection)
      ? tr.doc.resolve($from.pos + 1)
      : $from;
    const { parent } = $insertPos;

    // try to replace selected node
    if (isNodeSelection(tr.selection) && tryToReplace) {
      const oldTr = tr;
      tr = replaceSelectedNode(content)(tr);
      if (oldTr !== tr) {
        return tr;
      }
    }

    // try to replace an empty paragraph
    if (isEmptyParagraph(parent)) {
      const oldTr = tr;
      tr = replaceParentNodeOfType(parent.type, content)(tr);
      if (oldTr !== tr) {
        const pos = isSelectableNode(content)
          ? // for selectable node, selection position would be the position of the replaced parent
            $insertPos.before($insertPos.depth)
          : $insertPos.pos;
        return setSelection(content, pos, tr);
      }
    }

    // given node is allowed at the current cursor position
    if (canInsert($insertPos, content)) {
      tr.insert($insertPos.pos, content);
      const pos = hasPosition
        ? $insertPos.pos
        : isSelectableNode(content)
        ? // for atom nodes selection position after insertion is the previous pos
          tr.selection.$anchor.pos - 1
        : tr.selection.$anchor.pos;
      return cloneTr(setSelection(content, pos, tr));
    }

    // looking for a place in the doc where the node is allowed
    for (let i = $insertPos.depth; i > 0; i--) {
      const pos = $insertPos.after(i);
      const $pos = tr.doc.resolve(pos);
      if (canInsert($pos, content)) {
        tr.insert(pos, content);
        return cloneTr(setSelection(content, pos, tr));
      }
    }
    return tr;
  };
}

/**
 * Returns a new transaction that replaces selected node with a given `node`, keeping NodeSelection on the new `node`.
 * It will return the original transaction if either current selection is not a NodeSelection or replacing is not possible.
 *
 * @example
 * ```javascript
 * const node = schema.nodes.paragraph.createChecked({}, schema.text('new'));
 * dispatch(
 *   replaceSelectedNode(node)(tr)
 * );
 * ```
 */
export function replaceSelectedNode(
  content: ProsemirrorNode | Fragment
): (tr: Transaction) => Transaction {
  return (tr) => {
    if (isNodeSelection(tr.selection)) {
      const { $from, $to } = tr.selection;
      if (
        (content instanceof Fragment &&
          $from.parent.canReplace(
            $from.index(),
            $from.indexAfter(),
            content
          )) ||
        (content instanceof ProsemirrorNode &&
          $from.parent.canReplaceWith(
            $from.index(),
            $from.indexAfter(),
            content.type
          ))
      ) {
        return cloneTr(
          tr
            .replaceWith($from.pos, $to.pos, content)
            // restore node selection
            .setSelection(new NodeSelection(tr.doc.resolve($from.pos)))
        );
      }
    }
    return tr;
  };
}

/**
 * Checks if a given `node` is an empty paragraph
 */
export function isEmptyParagraph(node: ProsemirrorNode): boolean {
  return !node || (node.type.name === 'paragraph' && node.nodeSize === 2);
}

/**
 * Creates a new transaction object from a given transaction
 */
export function cloneTr(tr: Transaction): Transaction {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  return Object.assign(Object.create(tr), tr).setTime(Date.now());
}

/**
 * Returns a new transaction that replaces parent node of a given `nodeType` with the given `content`. It will return an original transaction if either parent node hasn't been found or replacing is not possible.
 *
 * @example
 * ```javascript
 * const node = schema.nodes.paragraph.createChecked({}, schema.text('new'));
 *
 * dispatch(
 *  replaceParentNodeOfType(schema.nodes.table, node)(tr)
 * );
 * ```
 */
export function replaceParentNodeOfType(
  nodeType: NodeType | NodeType[],
  content: ProsemirrorNode | Fragment
): (tr: Transaction) => Transaction {
  return (tr) => {
    if (!Array.isArray(nodeType)) {
      nodeType = [nodeType];
    }
    for (let i = 0, count = nodeType.length; i < count; i++) {
      const parent = findParentNodeOfType(nodeType[i])(tr.selection);
      if (parent) {
        const newTr = replaceNodeAtPos(parent.pos, content)(tr);
        if (newTr !== tr) {
          return newTr;
        }
      }
    }
    return tr;
  };
}

/**
 * Returns a `replace` transaction that replaces a node at a given position with the given `content`. It will return the original transaction if replacing is not possible. `position` should point at the position immediately before the node.
 */
export function replaceNodeAtPos(
  position: number,
  content: ProsemirrorNode | Fragment
): (tr: Transaction) => Transaction {
  return (tr) => {
    const node = tr.doc.nodeAt(position);
    const $pos = tr.doc.resolve(position);
    if (!node) {
      return tr;
    }

    if (canReplace($pos, content)) {
      tr = tr.replaceWith(position, position + node.nodeSize, content);
      const start = tr.selection.$from.pos - 1;
      // put cursor inside of the inserted node
      tr = setTextSelection(Math.max(start, 0), -1)(tr);
      // move cursor to the start of the node
      tr = setTextSelection(tr.selection.$from.start())(tr);
      return cloneTr(tr);
    }
    return tr;
  };
}

function isSelectableNode(
  node: ProsemirrorNode | Fragment
): node is ProsemirrorNode {
  return Boolean(
    node instanceof ProsemirrorNode && node.type && node.type.spec.selectable
  );
}

function shouldSelectNode(node: ProsemirrorNode | Fragment): boolean {
  return isSelectableNode(node) && node.type.isLeaf;
}

function setSelection(
  node: ProsemirrorNode | Fragment,
  pos: number,
  tr: Transaction
): Transaction {
  if (shouldSelectNode(node)) {
    return tr.setSelection(new NodeSelection(tr.doc.resolve(pos)));
  }
  return setTextSelection(pos)(tr);
}

/**
 * Returns a new transaction that tries to find a valid cursor selection starting at the given `position` and searching back if `dir` is negative, and forward if positive.
 *
 * If a valid cursor position hasn't been found, it will return the original transaction.
 *
 * @example
 * ```javascript
 * dispatch(
 *   setTextSelection(5)(tr)
 * );
 * ```
 */
export function setTextSelection(
  position: number,
  dir = 1
): (tr: Transaction) => Transaction {
  return (tr) => {
    const nextSelection = Selection.findFrom(
      tr.doc.resolve(position),
      dir,
      true
    );
    if (nextSelection) {
      return tr.setSelection(nextSelection);
    }
    return tr;
  };
}

/**
 * Checks if replacing a node at a given `$pos` inside of the `doc` node with the given `content` is possible.
 */
export function canReplace(
  $pos: ResolvedPos,
  content: ProsemirrorNode | Fragment
): boolean {
  const node = $pos.node($pos.depth);
  return (
    node &&
    node.type.validContent(
      content instanceof Fragment ? content : Fragment.from(content)
    )
  );
}

/**
 * Checks if a given `content` can be inserted at the given `$pos`
 *
 * @example
 * ```javascript
 * const { selection: { $from } } = state;
 * const node = state.schema.nodes.atom.createChecked();
 * if (canInsert($from, node)) {
 *   // ...
 * }
 * ```
 */
export function canInsert(
  $pos: ResolvedPos,
  content: ProsemirrorNode | Fragment
): boolean {
  const index = $pos.index();

  if (content instanceof Fragment) {
    return $pos.parent.canReplace(index, index, content);
  } else if (content instanceof ProsemirrorNode) {
    return $pos.parent.canReplaceWith(index, index, content.type);
  }
  return false;
}
