import {
  QueryConstraint,
  isObject,
  type CollectionReference,
  type ComparableValue,
  type IReffable,
  type Timestamp,
  type Timezone,
  type UnwrapArray,
  type WithRef,
} from '@principle-theorem/shared';
import { isArray, isString } from 'lodash';
import { type Observable } from 'rxjs';
import { type IPracticeMigration } from './practice-migration';
import {
  ISourceEntityRecord,
  ISourceEntityRecordData,
} from './source-entity-record';

export interface ISourceMetadata {
  name: string;
  version: string;
}

export interface ISource {
  metadata: ISourceMetadata;
  configuration: ISourceConfigurationItem[];
}

export interface ISourceConfigurationItem {
  name: string;
  description: string;
  value: string;
}

export interface ISourceHandler {
  migrationType: string;
  migrationVersion: string;
  source: ISource;
  entities: ISourceEntity[];
  entityHandlers: ISourceEntityHandler[];
  canHandle(source: ISource): boolean;
  addEntities(migration: WithRef<IPracticeMigration>): Promise<void>;
}

export enum SourceEntityStatus {
  Uninitialised = 'uninitialised',
  Failed = 'failed',
  Syncing = 'syncing',
  Synced = 'synced',
  Locked = 'locked',
}

export interface IExpectedSourceRecordSize {
  expectedSize: number;
  expectedSizeCalculatedAt: Timestamp;
}

export const SOURCE_ENTITY_STATUS_MAP: {
  [key in SourceEntityStatus]: string;
} = {
  [SourceEntityStatus.Uninitialised]: 'default',
  [SourceEntityStatus.Syncing]: 'accent',
  [SourceEntityStatus.Synced]: 'accent',
  [SourceEntityStatus.Locked]: 'primary',
  [SourceEntityStatus.Failed]: 'warn',
};

export type ISourceEntity = {
  uid: string;
  metadata: ISourceEntityMetadata;
  size?: number;
  sizeLastCalculatedAt?: Timestamp;
} & (
  | {
      status: SourceEntityStatus.Uninitialised | SourceEntityStatus.Syncing;
    }
  | {
      status: SourceEntityStatus.Failed;
      errorMessage: string;
      resumeData?: object;
    }
  | {
      status: SourceEntityStatus.Synced | SourceEntityStatus.Locked;
      lastSync: Timestamp;
    }
) &
  Partial<IExpectedSourceRecordSize>;

export function hasExpectRecordSize(
  entity: ISourceEntity
): entity is ISourceEntity & IExpectedSourceRecordSize {
  return (
    entity.expectedSize !== undefined &&
    entity.expectedSizeCalculatedAt !== undefined
  );
}

export enum SourceEntityMigrationType {
  Automatic = 'automatic',
  Manual = 'manual',
}

export interface ISourceEntityMetadata {
  label: string;
  description: string;
  idPrefix: string;
  migrationType: SourceEntityMigrationType;
}

export enum SourceEntityCollection {
  Records = 'sourceRecords',
}

export interface ISourceEntityHandler<
  In extends object[] = object[],
  Translations = unknown,
  Filters extends object = object,
> {
  sourceEntity: ISourceEntity;
  allowOffsetJob?: boolean;
  defaultOffsetSize?: number;
  requiredEntities?: Record<symbol, ISourceEntityHandler>;
  migrationDestinations?: string[];
  dateFilterField?: keyof Filters | string;
  canHandle(source: ISourceEntity): boolean;
  getFromSource$(
    migration: WithRef<IPracticeMigration>,
    startOffset?: number,
    endOffset?: number
  ): Observable<In | ISourceSyncErrorData>;
  verifySource(data: unknown): data is UnwrapArray<In>;
  getSourceRecordId(data: UnwrapArray<In>): string | number;
  getSourceLabel(record: UnwrapArray<In>): string;
  getFilterData?(data: UnwrapArray<In>, timezone: Timezone): Filters;
  filterRecords?<T extends ComparableValue>(
    migration: IReffable<IPracticeMigration>,
    filterKey: keyof Filters,
    value: T
  ): Promise<IGetRecordResponse<UnwrapArray<In>, Translations, Filters>[]>;
  translate(initialValue: UnwrapArray<In>, timezone: Timezone): Translations;
  getEntity$(
    migration: IReffable<IPracticeMigration>
  ): Observable<ISourceEntity | undefined>;
  getMapResourceType(): string;
  getRecord(
    migration: IReffable<IPracticeMigration>,
    recordId: string | number
  ): Promise<IGetRecordResponse<UnwrapArray<In>, Translations> | undefined>;
  getRecords$(
    migration: IReffable<IPracticeMigration>,
    bufferSize: number,
    queryConstraints?: QueryConstraint[],
    initialRecord?: WithRef<ISourceEntityRecord>,
    from?: Timestamp,
    to?: Timestamp
  ): Observable<IGetRecordResponse<UnwrapArray<In>, Translations>[]>;
  getCollection$(
    migration: IReffable<IPracticeMigration>
  ): Observable<CollectionReference<ISourceEntityRecord>>;
  combineRecordWithData$(
    record: WithRef<ISourceEntityRecord<Filters>>
  ): Observable<IGetRecordResponse<UnwrapArray<In>, Translations>>;
  getExpectedRecordSize(
    migration: IReffable<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize>;
}

export type SourceEntityTypeGuard<In> = (data: unknown) => data is In;

export interface IGetRecordResponse<
  T = unknown,
  R = unknown,
  Filters extends object = object,
> {
  record: WithRef<ISourceEntityRecord<Filters>>;
  data: WithRef<ISourceEntityRecordData<T, R>>;
}

export interface ISourceSyncErrorData {
  resumeData: object;
  errorMessage: string;
}

export function isSourceSyncErrorData(
  data: ISourceSyncErrorData | unknown[]
): data is ISourceSyncErrorData {
  return isObject(data) && isString(data.errorMessage) && !isArray(data);
}
