import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CurrentPracticeScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { DialogPresets, IBreadcrumb } from '@principle-theorem/ng-shared';
import {
  Practice,
  SterilisationRecord,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import { type IPractice } from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  type WithRef,
  addDoc,
  asyncForEach,
  filterUndefined,
  isWithRef,
  snapshot,
  toNamedDocument,
} from '@principle-theorem/shared';
import { Observable, Subject, combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
  type ISterilisationRecordDialogRequest,
  type ISterilisationRecordDialogResponse,
  SterilisationRecordDialogComponent,
} from '../../components/sterilisation-record-dialog/sterilisation-record-dialog.component';
import { SterilisationStore } from '../../stores/sterilisation.store';
import { BarcodeGeneratorDialogComponent } from '../../components/barcode-generator-dialog/barcode-generator-dialog.component';

@Component({
    selector: 'pr-sterilisation-page',
    templateUrl: './sterilisation-page.component.html',
    styleUrls: ['./sterilisation-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SterilisationStore],
    standalone: false
})
export class SterilisationPageComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  readonly store = inject(SterilisationStore);
  practice$: Observable<WithRef<IPractice>>;
  breadcrumbs: IBreadcrumb[] = [{ label: 'Sterilisation Tracking' }];
  navLinks: IBreadcrumb[] = [
    { label: 'Cycles', path: ['./cycles'] },
    { label: 'Records', path: ['./records'] },
  ];

  constructor(
    private _currentPracticeScope: CurrentPracticeScope,
    private _dialog: MatDialog,
    private _organisation: OrganisationService
  ) {
    this.practice$ = this._currentPracticeScope.doc$.pipe(filterUndefined());
    combineLatest([
      this._organisation.brand$.pipe(filterUndefined()),
      this.practice$,
    ])
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe(([brand, practice]) => {
        this.store.loadAvailableMachines({ brand, practice });
        this.store.loadCycleTypes(brand);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async addSteriCycle(): Promise<void> {
    const practice = await snapshot(this.practice$);
    await this.store.addCycle(practice);
  }

  async printBarcodes(): Promise<void> {
    await this._dialog
      .open<BarcodeGeneratorDialogComponent>(
        BarcodeGeneratorDialogComponent,
        DialogPresets.medium()
      )
      .afterClosed()
      .toPromise();
  }

  async addSteriRecord(): Promise<void> {
    const practice = await snapshot(this.practice$);
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const formData = await this._dialog
      .open<
        SterilisationRecordDialogComponent,
        ISterilisationRecordDialogRequest,
        ISterilisationRecordDialogResponse
      >(
        SterilisationRecordDialogComponent,
        DialogPresets.medium({ autoFocus: true, data: { staffer, practice } })
      )
      .afterClosed()
      .toPromise();

    if (!formData) {
      return;
    }

    await asyncForEach(formData.data, (record) => {
      if (isWithRef(record)) {
        return Firestore.patchDoc(record.ref, {
          patient: toNamedDocument(formData.patient),
        });
      }
      return addDoc(
        Practice.sterilisationRecordCol(practice),
        SterilisationRecord.init({
          data: record,
          patient: toNamedDocument(formData.patient),
          practice: toNamedDocument(practice),
          scannedBy: stafferToNamedDoc(formData.staffer),
        })
      );
    });
  }
}
