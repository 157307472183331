import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MixedSchema } from '@principle-theorem/editor';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrentPatientDocumentScope } from '../current-patient-document-scope';

@Component({
    selector: 'pr-patient-document-print',
    templateUrl: './patient-document-print.component.html',
    styleUrls: ['./patient-document-print.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientDocumentPrintComponent {
  content$: Observable<MixedSchema | undefined>;

  constructor(patientDocumentScope: CurrentPatientDocumentScope) {
    this.content$ = patientDocumentScope.doc$.pipe(
      map((patientDocument) => patientDocument?.content)
    );
  }
}
