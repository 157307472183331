<mat-toolbar color="accent">
  {{ data.pack ? 'Edit' : 'Create' }} Sterilisation Pack
</mat-toolbar>

<mat-dialog-content>
  <form [formGroup]="form" class="flex flex-col gap-4">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>

    <div formArrayName="content" class="flex flex-col gap-4">
      @for (
        control of content.controls;
        track index;
        let index = $index;
        let first = $first;
        let last = $last
      ) {
        <pr-sterilisation-pack-content-input
          [form]="control"
          [items]="data.items"
        >
          <div class="flex gap-2">
            <button
              mat-icon-button
              [disabled]="first"
              (click)="packContentControls.removeControl(index)"
              matTooltip="Remove sterilisation item"
            >
              <mat-icon>remove</mat-icon>
            </button>
            <button
              mat-icon-button
              matTooltip="Add new sterilisation item"
              (click)="packContentControls.addControl(index)"
              [disabled]="!last || data.items.length <= index + 1"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </pr-sterilisation-pack-content-input>
      }
    </div>

    <mat-form-field>
      <mat-label>Description</mat-label>
      <input matInput formControlName="description" />
      <mat-hint>Include a brief description of the packet contents</mat-hint>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!form.valid"
      (click)="save()"
    >
      {{ data.pack ? 'Update' : 'Create' }}
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
