import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeatureFlagsService } from '@principle-theorem/ng-feature-flags';
import { AccountingFunctionsService } from '@principle-theorem/ng-principle-accounting';
import {
  CurrentScopeFacade,
  UpdateHealthCardsService,
} from '@principle-theorem/ng-principle-shared';
import {
  Invoice,
  MedicareClaimType,
  OrganisationCache,
  Practice,
} from '@principle-theorem/principle-core';
import { HICAPS_CONNECT_FEATURE } from '@principle-theorem/principle-core/features';
import {
  IHealthcareClaim,
  IHicapsConnectTerminal,
  IInvoice,
  IPractice,
  ITransaction,
  TransactionAction,
  TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  Region,
  WithRef,
  getEnumValues,
  snapshot,
} from '@principle-theorem/shared';
import { Moment } from 'moment-timezone';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MedicareDialogService } from '../../transaction-components/medicare-components/medicare-dialog-service';
import {
  ITransactionProvider,
  TransactionProviderType,
} from '../../transaction-provider';
import { HicapsConnectHelpers } from '../hicaps-connect-helpers';
import { HicapsConnectService } from '../hicaps-connect.service';
import {
  HicapsConnectMedicareRequestBuilder,
  IMedicareClaimData,
} from './hicaps-connect-medicare-request-builder';
import { HicapsConnectMedicareTransactionBuilder } from './hicaps-connect-medicare-transaction-builder';

@Injectable()
export class HicapsConnectMedicareTransactionProvider
  implements ITransactionProvider
{
  providerId = TransactionProvider.HicapsConnectMedicare;
  providerType = TransactionProviderType.Medicare;
  providerRegions = [Region.Australia];
  isEnabled$: Observable<boolean>;

  constructor(
    private _hicapsConnect: HicapsConnectService,
    private _currentScopeFacade: CurrentScopeFacade,
    private _accountFunctions: AccountingFunctionsService,
    private _snackbar: MatSnackBar,
    private _medicareDialog: MedicareDialogService,
    private _updateHealthCards: UpdateHealthCardsService,
    private _featureFlags: FeatureFlagsService
  ) {
    this.isEnabled$ = this._currentScopeFacade.currentPractice$.pipe(
      map(
        (practice) =>
          this._featureFlags.isFeatureEnabled(HICAPS_CONNECT_FEATURE) &&
          Practice.isHicapsConnectEnabled(practice)
      )
    );
  }

  canCapture$(invoice: WithRef<IInvoice>): Observable<boolean> {
    const canCapture =
      !Invoice.isPaid(invoice) && Invoice.canAddTransactions(invoice);
    return of(canCapture);
  }

  async capture(
    invoice: WithRef<IInvoice>,
    claim?: IHealthcareClaim
  ): Promise<DocumentReference<ITransaction> | undefined> {
    if (!claim) {
      return;
    }

    const allClaimTypes = getEnumValues(MedicareClaimType);
    const claimType = await this._medicareDialog.selectClaimType(
      allClaimTypes,
      invoice,
      claim
    );

    switch (claimType) {
      case MedicareClaimType.BulkBill:
        return this._captureBulkBill(invoice, claim);
      case MedicareClaimType.FullyPaid:
        return this._captureFullyPaid(invoice, claim);
      case MedicareClaimType.PartPaid:
        return this._capturePartPaid(invoice, claim);
      case MedicareClaimType.Unpaid:
        return this._captureUnPaid(invoice, claim);
      default:
        return;
    }
  }

  private async _captureBulkBill(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const formData = await this._medicareDialog.bulkBill(invoice, claim, false);
    if (!formData) {
      return;
    }
    const terminal = await this._hicapsConnect.selectTerminal(invoice, claim);
    if (!terminal) {
      return;
    }
    const serviceDate = await this._getServiceDate(invoice);
    const medicareClaimData = HicapsConnectMedicareRequestBuilder.buildBulkBill(
      this._hicapsConnect.getConfig(),
      invoice,
      claim,
      terminal,
      formData,
      serviceDate
    );
    if (!medicareClaimData) {
      return;
    }
    return this._submitMedicareClaim(
      invoice,
      claim,
      terminal,
      medicareClaimData
    );
  }

  private async _captureFullyPaid(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const formData = await this._medicareDialog.patientClaim(invoice, claim);
    if (!formData) {
      return;
    }
    const terminal = await this._hicapsConnect.selectTerminal(invoice, claim);
    if (!terminal) {
      return;
    }
    const serviceDate = await this._getServiceDate(invoice);
    const medicareClaimData =
      HicapsConnectMedicareRequestBuilder.buildFullyPaid(
        this._hicapsConnect.getConfig(),
        invoice,
        claim,
        terminal,
        formData,
        serviceDate
      );
    if (!medicareClaimData) {
      return;
    }
    return this._submitMedicareClaim(
      invoice,
      claim,
      terminal,
      medicareClaimData
    );
  }

  private async _capturePartPaid(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const formData = await this._medicareDialog.bulkBill(invoice, claim, true);
    if (!formData) {
      return;
    }
    const terminal = await this._hicapsConnect.selectTerminal(invoice, claim);
    if (!terminal) {
      return;
    }
    const serviceDate = await this._getServiceDate(invoice);
    const medicareClaimData = HicapsConnectMedicareRequestBuilder.buildPartPaid(
      this._hicapsConnect.getConfig(),
      invoice,
      claim,
      terminal,
      formData,
      serviceDate
    );
    if (!medicareClaimData) {
      return;
    }
    return this._submitMedicareClaim(
      invoice,
      claim,
      terminal,
      medicareClaimData
    );
  }

  private async _captureUnPaid(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const formData = await this._medicareDialog.patientClaim(invoice, claim);
    if (!formData) {
      return;
    }
    const terminal = await this._hicapsConnect.selectTerminal(invoice, claim);
    if (!terminal) {
      return;
    }
    const serviceDate = await this._getServiceDate(invoice);
    const medicareClaimData = HicapsConnectMedicareRequestBuilder.buildUnPaid(
      this._hicapsConnect.getConfig(),
      invoice,
      claim,
      terminal,
      formData,
      serviceDate
    );
    if (!medicareClaimData) {
      return;
    }
    return this._submitMedicareClaim(
      invoice,
      claim,
      terminal,
      medicareClaimData
    );
  }

  private async _submitMedicareClaim(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim,
    terminal: WithRef<IHicapsConnectTerminal>,
    medicareClaimData: IMedicareClaimData
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const { request, extendedData } = medicareClaimData;
    this._snackbar.open('Sending request to terminal');

    const api = this._hicapsConnect.getDeviceAPI(terminal.bridgeDevice);
    const response = await snapshot(
      api.sendMedicareClaimRequest(request, extendedData)
    );

    const practiceRef = Firestore.getParentDocRef<IPractice>(terminal.ref);
    const transaction =
      await HicapsConnectMedicareTransactionBuilder.toMedicareTransaction(
        invoice,
        practiceRef,
        claim,
        request,
        response
      );

    const result = await this._accountFunctions.addTransactionToInvoice(
      invoice,
      transaction,
      TransactionAction.Add,
      claim
    );

    if (!result) {
      return;
    }
    const patient = await OrganisationCache.patients.getDoc(
      Invoice.patientDocRef(invoice)
    );
    await this._updateHealthCards.open(
      this._updateHealthCards.factory(patient).fromTransaction(transaction)
    );

    return result;
  }

  private async _getServiceDate(invoice: WithRef<IInvoice>): Promise<Moment> {
    const appointment = await Invoice.getAssociatedAppointment(invoice);
    return HicapsConnectHelpers.getServiceDate(
      invoice,
      appointment?.event?.from
    );
  }
}
