import {
  IAppointment,
  ICalendarEvent,
  IScheduleSummaryEventable,
} from '@principle-theorem/principle-core/interfaces';
import { type EntityModel } from '@principle-theorem/shared';

export type CalendarEventEntity = EntityModel<
  IScheduleSummaryEventable<IAppointment | ICalendarEvent>
>;
