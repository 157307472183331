import { IPatient } from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { IBaseCommand, IBridgeIntegration } from '../../send-command-to-device';

export const VIXWIN_INTEGRATION: IBridgeIntegration = {
  label: 'VixWin',
  description: 'Two-way integration with Dexis VixWin imaging software',
};

export type VixWinOpenPatientCommand = IBaseCommand<
  VixWinToDeviceCommand.OpenPatient,
  { patient: WithRef<IPatient> }
>;

export type VixWinAddPatientRecordCommand = IBaseCommand<
  VixWinToDeviceCommand.AddPatientRecord,
  { patient: WithRef<IPatient> }
>;

export enum VixWinToDeviceCommand {
  OpenPatient = 'vixWin.openPatient',
  AddPatientRecord = 'vixWin.addPatientRecord',
}
