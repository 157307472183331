import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { type Permission } from '@principle-theorem/feature-flags';
import { Brand, Staffer } from '@principle-theorem/principle-core';
import {
  IBrand,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { IReffable, type WithRef } from '@principle-theorem/shared';
import { combineLatest, of, type Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CurrentBrandScope } from '../../app-state/current-brand';

export interface IStaffSelectorState {
  staff: WithRef<IStaffer>[];
}

const initialState: IStaffSelectorState = {
  staff: [],
};

@Injectable()
export class StaffSelectorStore extends ComponentStore<IStaffSelectorState> {
  private _brandScope = inject(CurrentBrandScope);
  staff$ = this.select((state) => state.staff);

  readonly loadStaff = this.effect(
    (
      sources$: Observable<
        [
          IReffable<IBrand> | undefined,
          WithRef<IPractice> | undefined,
          Permission[],
        ]
      >
    ) => {
      const scopedBrand$ = this._brandScope.doc$;
      return combineLatest([sources$, scopedBrand$]).pipe(
        switchMap(([[brand, practice, permissions], scopedBrand]) =>
          this._resolveStaff$(permissions, brand, practice, scopedBrand)
        ),
        tap((staff) => this.setState({ staff }))
      );
    }
  );

  constructor() {
    super(initialState);
  }

  private _resolveStaff$(
    permissions: string[],
    brand?: IReffable<IBrand>,
    practice?: WithRef<IPractice>,
    scopedBrand?: WithRef<IBrand>
  ): Observable<WithRef<IStaffer>[]> {
    if (!brand) {
      brand = scopedBrand;
    }

    if (!brand) {
      return of([]);
    }

    if (!practice) {
      return permissions.length
        ? Staffer.hasPermissionsByBrand$(brand ?? scopedBrand, permissions)
        : Brand.staff$(brand ?? scopedBrand);
    }
    return permissions.length
      ? Staffer.hasPermissionsByPractice$(practice, permissions)
      : Staffer.byPractice$(practice);
  }
}
