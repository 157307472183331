/* eslint-disable @nx/enforce-module-boundaries */
import { type StepperSelectionEvent } from '@angular/cdk/stepper';
import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { MatStep } from '@angular/material/stepper';
import { ActivatedRoute, type ParamMap } from '@angular/router';
import {
  AppointmentSchedulingFacade,
  convertTemplateToPlanPair,
} from '@principle-theorem/ng-appointment/store';
import {
  GlobalStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  Brand,
  Patient,
  SchedulingEvent,
  Staffer,
  TreatmentPlan,
  TreatmentPlanStepPair,
  TreatmentStep,
  isRequiredAppointmentDetails,
  staffToNamedDocs,
  type IAppointmentDetails,
  type IPatientDetails,
  type IWaitListDetails,
} from '@principle-theorem/principle-core';
import {
  IAppointmentRequest,
  IBrand,
  IEvent,
  IOrganisation,
  ISchedulingEventData,
  ITreatmentCategory,
  isTreatmentStep,
  type IInteractionV2,
  type IPatient,
  type IPractice,
  type IStaffer,
  type ITag,
  type ITreatmentPlanPairFromTemplate,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  getDoc$,
  multiMap,
  multiSwitchMap,
  shareReplayCold,
  snapshot,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { Moment } from 'moment-timezone';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  of,
} from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { AppointmentAutomationsRescheduleComponent } from '../../components/scheduling/appointment-automations-reschedule/appointment-automations-reschedule.component';
import { AppointmentDetailsComponent } from '../../components/scheduling/appointment-details/appointment-details.component';
import { AppointmentSidebarComponent } from '../../components/scheduling/appointment-sidebar/appointment-sidebar.component';
import { PatientDetailsComponent } from '../../components/scheduling/patient-details/patient-details.component';
import { SchedulingScenarioService } from '../../scheduling-scenario.service';

interface ILocationData {
  organisation: WithRef<IOrganisation>;
  brand: WithRef<IBrand>;
  selectedPractice?: WithRef<IPractice>;
  currentPractice?: WithRef<IPractice>;
}

interface IAppointmentData {
  staffer: WithRef<IStaffer>;
  details: IAppointmentDetails;
  treatmentCategories: WithRef<ITreatmentCategory>[];
  patient?: WithRef<IPatient>;
}

type IPlanTemplateData = ILocationData & IAppointmentData;

@Component({
    selector: 'pr-create-appointment',
    templateUrl: './create-appointment.component.html',
    styleUrls: ['./create-appointment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CreateAppointmentComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  practices$: Observable<WithRef<IPractice>[]>;
  practitioners$: Observable<INamedDocument<IStaffer>[]>;
  treatmentStepPair$: Observable<TreatmentPlanStepPair | undefined>;
  automationReschedule$ =
    new ReplaySubject<AppointmentAutomationsRescheduleComponent>(1);

  openTime$: Observable<Moment>;
  closeTime$: Observable<Moment>;

  selectedEvent$: Observable<IEvent | undefined>;
  waitlistDetails$: Observable<IWaitListDetails>;
  patient$: Observable<WithRef<IPatient> | undefined>;
  appointmentDetails$: Observable<IAppointmentDetails>;
  interactions$: Observable<IInteractionV2[]>;
  appointmentRequest$: Observable<WithRef<IAppointmentRequest> | undefined>;

  planTemplates$: Observable<ITreatmentPlanPairFromTemplate[]>;
  canSave$: Observable<boolean>;
  saving$ = new BehaviorSubject<boolean>(false);

  @ViewChild(AppointmentDetailsComponent, { static: true })
  appointmentDetails: AppointmentDetailsComponent;

  @ViewChild('patientDetailsStep', { read: MatStep, static: true })
  _patientDetailsStep: MatStep;

  @ViewChild('appointmentSelectStep', { read: MatStep, static: true })
  _appointmentSelectStep: MatStep;

  @ViewChild(PatientDetailsComponent, { static: true })
  patientDetails: PatientDetailsComponent;

  @ViewChild(AppointmentSidebarComponent, { static: true })
  _patientSidebar: AppointmentSidebarComponent;

  @ViewChild(AppointmentAutomationsRescheduleComponent, { static: false })
  set automationReschedule(
    component: AppointmentAutomationsRescheduleComponent
  ) {
    if (component) {
      this.automationReschedule$.next(component);
    }
  }

  constructor(
    private _organisation: OrganisationService,
    private _schedulingFacade: AppointmentSchedulingFacade,
    private _location: Location,
    private _route: ActivatedRoute,
    private _schedulingScenario: SchedulingScenarioService,
    private _globalStore: GlobalStoreService
  ) {
    this._schedulingFacade.resetWaitList();
    this.openTime$ = this._schedulingFacade.openTime$;
    this.closeTime$ = this._schedulingFacade.closeTime$;

    this.selectedEvent$ = this._schedulingFacade.selectedEvent$;
    this.waitlistDetails$ = this._schedulingFacade.waitlistDetails$;
    this.patient$ = this._schedulingFacade.selectedPatient$;
    this.appointmentDetails$ = this._schedulingFacade.appointmentDetails$;
    this.interactions$ = this._schedulingFacade.interactions$;
    this.appointmentRequest$ = this._schedulingFacade.appointmentRequest$;

    this.planTemplates$ = this._getPlanTemplates$();
    this.canSave$ = this._getCanSave$();

    this._route.queryParamMap
      .pipe(
        map((params: ParamMap) => params.get('preserveState')),
        takeUntil(this._onDestroy$)
      )
      .subscribe((preserveState) => {
        if (!preserveState) {
          this._schedulingFacade.reset();
        }
      });

    this.practices$ = this._organisation.practices$;

    this.practitioners$ = combineLatest([
      this.appointmentDetails$.pipe(map((details) => details.practice)),
      this._schedulingFacade.brand$,
    ]).pipe(
      switchMap(([practice, brand]) =>
        practice
          ? Staffer.practitionersByPractice$(practice)
          : Staffer.practitionersByBrand$(brand)
      ),
      staffToNamedDocs()
    );

    this._organisation.practice$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((practice) => this.updateAppointmentDetails({ practice }));

    this._schedulingFacade.patientIdParam$
      .pipe(
        filterUndefined(),
        switchMap((id) =>
          this._schedulingFacade.brand$.pipe(
            switchMap((brand) =>
              getDoc$(Brand.patientCol({ ref: brand.ref }), id)
            )
          )
        ),
        filter((patient) => !Patient.isDisabledPatient(patient.status)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((patient) => this.selectPatient(patient));

    this.treatmentStepPair$ = this.appointmentDetails$.pipe(
      map((details) => details.treatment),
      shareReplayCold()
    );

    const checklists$ = this.appointmentDetails$.pipe(
      map((details) => details.treatment),
      shareReplayCold(),
      filter((treatmentStep) => isTreatmentStep(treatmentStep?.step)),
      switchMap((treatmentStep) =>
        treatmentStep
          ? TreatmentStep.getTreatmentConfigurationChecklists$(
              treatmentStep.step
            )
          : of([])
      )
    );

    checklists$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((checklists) =>
        this._schedulingFacade.updateChecklists(checklists)
      );
  }

  ngOnDestroy(): void {
    this._schedulingFacade.reset();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  selectPatient(patient: WithRef<IPatient>): void {
    this._schedulingFacade.selectPatient(patient);
  }

  patientDetailsChange(details: IPatientDetails): void {
    this._schedulingFacade.patchPatientDetails(details, false);
  }

  updateAppointmentDetails(details: Partial<IAppointmentDetails>): void {
    this._schedulingFacade.appointmentFormChange(details);
  }

  handleStepChange(event: StepperSelectionEvent): void {
    if (event.previouslySelectedStep === this._patientDetailsStep) {
      this._schedulingFacade.savePatient();
    }
    if (event.selectedStep === this._appointmentSelectStep) {
      this._schedulingFacade.loadAppointmentAvailability();
    }
  }

  updateInteractions(interactions: IInteractionV2[]): void {
    this._schedulingFacade.updateInteractions(interactions);
  }

  updateWaitList(details: IWaitListDetails): void {
    this._schedulingFacade.updateWaitListDetails(details);
  }

  updateTags(tags: INamedDocument<ITag>[]): void {
    this._schedulingFacade.updateTags(tags);
  }

  async save(): Promise<void> {
    this.saving$.next(true);

    const selectedEvent = await snapshot(this._schedulingFacade.selectedEvent$);
    const appointmentDetails = await snapshot(
      this._schedulingFacade.appointmentDetails$
    );
    const treatmentCategory = appointmentDetails.treatment
      ? TreatmentStep.defaultDisplayRef(
          appointmentDetails.treatment.step.display
        )
      : undefined;

    if (selectedEvent) {
      const isBlockedByDoubleBooking =
        await this._schedulingScenario.isBlockedByDoubleBooking(
          {
            event: selectedEvent,
          },
          treatmentCategory
        );
      if (isBlockedByDoubleBooking) {
        this.saving$.next(false);
        return;
      }
    }

    const automationRescheduleComponent = await snapshot(
      this.automationReschedule$
    );
    await automationRescheduleComponent.update();

    const practice = await snapshot(
      this._organisation.practice$.pipe(filterUndefined())
    );
    const schedulingConditions = SchedulingEvent.getSchedulingConditions(
      practice.settings.timezone,
      undefined,
      selectedEvent?.from
    );
    const schedulingEventData: ISchedulingEventData = {
      scheduledByPractice: practice.ref,
      reasonSetManually: false,
      schedulingConditions,
    };
    this._schedulingFacade.savePatient();
    this._schedulingFacade.saveNewAppointment(schedulingEventData);
    this._location.back();
  }

  private _getPlanTemplates$(): Observable<ITreatmentPlanPairFromTemplate[]> {
    return this._getPlanTemplateData$().pipe(
      switchMap(
        ({
          brand,
          selectedPractice,
          currentPractice,
          staffer,
          details,
          patient,
          organisation,
          treatmentCategories,
        }) =>
          Brand.treatmentTemplates$(brand).pipe(
            map((templates) =>
              templates.filter(
                (template) =>
                  template.implementedBy.length &&
                  template.enabledPractices.length
              )
            ),
            multiMap((template) =>
              TreatmentPlan.getTreatmentPlanFromTemplate(template, staffer)
            ),
            multiSwitchMap((template) =>
              convertTemplateToPlanPair(
                template,
                details,
                patient,
                organisation,
                treatmentCategories,
                details.practice ?? selectedPractice ?? currentPractice
              )
            )
          )
      )
    );
  }

  private _getPlanTemplateData$(): Observable<IPlanTemplateData> {
    const locationData$ = combineLatest([
      this._organisation.organisation$.pipe(filterUndefined()),
      this._schedulingFacade.brand$,
      this._schedulingFacade.practice$,
      this._organisation.practice$,
    ]).pipe(
      map(
        ([
          organisation,
          brand,
          selectedPractice,
          currentPractice,
        ]): ILocationData => ({
          organisation,
          brand,
          selectedPractice,
          currentPractice,
        })
      )
    );

    const planData$ = combineLatest([
      this._organisation.staffer$.pipe(filterUndefined()),
      this._schedulingFacade.appointmentDetails$,
      this._schedulingFacade.selectedPatient$,
      this._globalStore.treatmentCategories$,
    ]).pipe(
      map(
        ([
          staffer,
          details,
          patient,
          treatmentCategories,
        ]): IAppointmentData => ({
          staffer,
          details,
          patient,
          treatmentCategories,
        })
      )
    );

    return combineLatest([locationData$, planData$]).pipe(
      map(([locationData, planData]) => ({
        ...locationData,
        ...planData,
      }))
    );
  }

  private _getCanSave$(): Observable<boolean> {
    return combineLatest([
      this._schedulingFacade.savingAppointment$,
      this.patient$.pipe(map((patient) => (patient ? true : false))),
      this.appointmentDetails$.pipe(
        map((details) => isRequiredAppointmentDetails(details))
      ),
    ]).pipe(
      map(
        ([saving, hasPatient, hasTreatment]) =>
          !saving && hasPatient && hasTreatment
      )
    );
  }
}
