<pr-transaction-display-line
  *ngIf="display$ | async as display"
  [transaction]="display.current"
  [invoice]="invoice$ | async"
  [transactionDisplay]="display"
  [claim]="claim$ | async"
  [readonly]="isCreditNote$ | async"
>
  <ng-content />
</pr-transaction-display-line>
