import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BridgeCommandsService } from '@principle-theorem/ng-principle-bridge-cloud';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  BasicDialogService,
  DialogPresets,
  TrackByFunctions,
  confirmationDialogData,
  type IConfirmationDialogData,
} from '@principle-theorem/ng-shared';
import {
  CloudToBridgeCommand,
  IBaseCommand,
  PrincipleSettingsUpdate,
} from '@principle-theorem/principle-bridge-core';
import { BridgeDevice, Practice } from '@principle-theorem/principle-core';
import {
  BridgeDeviceDownloadStatus,
  IGenerateDownloadRequest,
  type IBridgeDevice,
  type IPractice,
  IGenerateIntegrationKeyRequest,
  IGenerateIntegrationKeyResponse,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  HISTORY_DATE_FORMAT,
  addDoc,
  deleteDoc,
  doc,
  httpsCallable,
  patchDoc,
  serialise,
  snapshot,
  snapshotDefined,
  type SerialisedData,
  type WithRef,
} from '@principle-theorem/shared';
import { kebabCase } from 'lodash';
import { ReplaySubject, type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  EditBridgeDeviceDialogComponent,
  type EditBridgeDeviceFormData,
  type IEditBridgeDeviceRequest,
} from './edit-bridge-device-dialog/edit-bridge-device-dialog.component';
import {
  EditBridgeSettingsDialogComponent,
  IEditBridgeSettingsRequest,
} from './edit-bridge-settings-dialog/edit-bridge-settings-dialog.component';
import {
  IViewBridgeLogsDialogData,
  ViewBridgeLogsDialogComponent,
} from './view-bridge-logs-dialog/view-bridge-logs-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  DownloadBridgeProgressDialogComponent,
  IDownloadBridgeProgressDialogData,
} from './download-bridge-progress-dialog/download-bridge-progress-dialog.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import {
  IntegrationKeySheetComponent,
  IIntegrationKeySheetData,
} from './integration-key-sheet/integration-key-sheet.component';

@Component({
    selector: 'pr-practice-bridge-settings',
    templateUrl: './practice-bridge-settings.component.html',
    styleUrls: ['./practice-bridge-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PracticeBridgeSettingsComponent {
  trackByDevice = TrackByFunctions.ref<WithRef<IBridgeDevice>>();
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);
  devices$: Observable<WithRef<IBridgeDevice>[]>;
  hasDownloadLink$: Observable<boolean>;
  readonly dateFormat = HISTORY_DATE_FORMAT;

  @Input()
  set practice(practice: WithRef<IPractice>) {
    if (practice) {
      this.practice$.next(practice);
    }
  }

  constructor(
    private _basicDialog: BasicDialogService,
    private _bottomSheet: MatBottomSheet,
    private _dialog: MatDialog,
    private _clipboard: Clipboard,
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService,
    private _bridgeCommands: BridgeCommandsService
  ) {
    this.devices$ = this.practice$.pipe(
      switchMap((practice) => Practice.bridgeDevices$(practice))
    );
  }

  async addNewDevice(): Promise<void> {
    const device = await this._openDeviceEdit({
      title: 'Add Bridge Device',
    });
    if (!device) {
      return;
    }
    const organisation = await snapshotDefined(
      this._organisation.organisation$
    );
    const practice = await snapshot(this.practice$);
    const docRef = doc(Practice.bridgeDeviceCol(practice));
    const deviceId = `${organisation.slug}-${kebabCase(device.name)}-${
      docRef.id
    }`;

    await addDoc(
      Practice.bridgeDeviceCol(practice),
      BridgeDevice.init({ ...device, deviceId })
    );
  }

  async editDevice(existing: WithRef<IBridgeDevice>): Promise<void> {
    const device = await this._openDeviceEdit({
      title: 'Edit Bridge Device',
      formData: existing,
    });
    if (!device) {
      return;
    }
    await patchDoc(existing.ref, device);
  }

  viewLogs(device: WithRef<IBridgeDevice>): void {
    this._basicDialog.open<
      ViewBridgeLogsDialogComponent,
      IViewBridgeLogsDialogData
    >(
      ViewBridgeLogsDialogComponent,
      DialogPresets.large({
        data: {
          device,
        },
      })
    );
  }

  async deleteDevice(device: WithRef<IBridgeDevice>): Promise<void> {
    const data: IConfirmationDialogData = confirmationDialogData({
      title: 'Delete Device',
      prompt: `Are you sure you want to delete ${device.name}?`,
      submitLabel: 'Delete',
      submitColor: 'warn',
    });

    const confirmed = await this._basicDialog.confirm(data);
    if (!confirmed) {
      return;
    }
    await deleteDoc(device.ref);
  }

  async generateIntegrationKey(device: WithRef<IBridgeDevice>): Promise<void> {
    if (device.integrationKey) {
      const confirmed = await this._basicDialog.confirm(
        confirmationDialogData({
          title: 'Regenerate Integration Key',
          prompt: [
            'Are you sure you want to generate a new integration key?',
            'If you are using the existing integration key, the device will no longer be able to connect.',
          ],
          submitLabel: 'Regenerate Integration Key',
          submitColor: 'warn',
        })
      );
      if (!confirmed) {
        return;
      }
    }

    this._snackBar.open('Generating Integration Key', 'Dismiss', {
      duration: 10000,
    });

    const practice = await snapshot(this.practice$);
    const data = serialise({
      deviceRef: device.ref,
      practiceRef: practice.ref,
    });
    const response = await snapshot(
      httpsCallable<
        SerialisedData<IGenerateIntegrationKeyRequest>,
        IGenerateIntegrationKeyResponse
      >('http-bridgeDevice-generateKey')(data)
    );

    this._snackBar.dismiss();
    this._bottomSheet.open<
      IntegrationKeySheetComponent,
      IIntegrationKeySheetData
    >(IntegrationKeySheetComponent, {
      data: {
        integrationKey: response.integrationKey,
      },
    });
  }

  async generateDownloadLink(device: WithRef<IBridgeDevice>): Promise<void> {
    const practice = await snapshot(this.practice$);
    if (!device.deviceId) {
      return;
    }

    if (device.integrationKey) {
      const data = confirmationDialogData({
        title: 'Bridge Device Already Integrated',
        prompt: [
          'This Device already has an integration key. Generating a new download link will void the current key and require re-installation from the generated installer.',
          'Proceed with link generation?',
        ],
        submitLabel: 'Generate Download Link',
      });
      const confirmed = await this._basicDialog.confirm(data);
      if (!confirmed) {
        return;
      }
    }

    const data = serialise<IGenerateDownloadRequest>({
      deviceRef: device.ref,
      practiceRef: practice.ref,
      deviceId: device.deviceId,
    });

    await Firestore.patchDoc(device.ref, {
      downloadStatus: BridgeDeviceDownloadStatus.Initialising,
    });
    void snapshot(
      httpsCallable<SerialisedData<IGenerateDownloadRequest>>(
        'http-bridgeDevice-generateDownload'
      )(data)
    );

    await this._dialog
      .open<
        DownloadBridgeProgressDialogComponent,
        IDownloadBridgeProgressDialogData
      >(
        DownloadBridgeProgressDialogComponent,
        DialogPresets.small({
          data: {
            deviceRef: device.ref,
          },
          disableClose: true,
        })
      )
      .afterClosed()
      .toPromise();
  }

  async updatedDeviceSettings(device: WithRef<IBridgeDevice>): Promise<void> {
    if (!device.settings) {
      return;
    }

    const updatedSettings = await this._basicDialog
      .open<
        EditBridgeSettingsDialogComponent,
        IEditBridgeSettingsRequest,
        IEditBridgeSettingsRequest
      >(
        EditBridgeSettingsDialogComponent,
        DialogPresets.large({
          data: {
            settings: device.settings,
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!updatedSettings) {
      return;
    }

    const command: IBaseCommand<
      CloudToBridgeCommand.UpdateSettings,
      PrincipleSettingsUpdate
    > = {
      type: CloudToBridgeCommand.UpdateSettings,
      data: updatedSettings.settings,
    };

    await this._bridgeCommands.sendCommand(command, device.ref);
  }

  copy(deviceId: string): void {
    this._snackBar.open('Copied to clipboard');
    this._clipboard.copy(deviceId);
  }

  download(url?: string): void {
    if (url) {
      window.open(url);
    }
  }

  private async _openDeviceEdit(
    data: IEditBridgeDeviceRequest
  ): Promise<EditBridgeDeviceFormData | undefined> {
    return this._basicDialog
      .open<
        EditBridgeDeviceDialogComponent,
        IEditBridgeDeviceRequest,
        EditBridgeDeviceFormData
      >(EditBridgeDeviceDialogComponent, DialogPresets.medium({ data }))
      .afterClosed()
      .toPromise();
  }
}
