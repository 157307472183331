import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { type IFeeSchedule } from '@principle-theorem/principle-core/interfaces';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { type CollectionReference } from '@principle-theorem/shared';
import { type ResolverResult, snapshot } from '@principle-theorem/shared';
import { FeeScheduleCollectionResolver } from './fee-schedule-collection-resolver.service';

@Injectable()
export class OrganisationFeeScheduleCollectionResolver extends FeeScheduleCollectionResolver {
  constructor(private _organisation: OrganisationService) {
    super();
  }

  resolve(
    _route: ActivatedRouteSnapshot
  ): ResolverResult<CollectionReference<IFeeSchedule> | undefined> {
    return snapshot(this._organisation.feeScheduleCol$);
  }
}
