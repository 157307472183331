import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IGeneratedTaskConfiguration } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-generated-task-configuration-list-item',
    templateUrl: './generated-task-configuration-list-item.component.html',
    styleUrls: ['./generated-task-configuration-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GeneratedTaskConfigurationListItemComponent {
  task$ = new ReplaySubject<WithRef<IGeneratedTaskConfiguration>>(1);

  @Input()
  set task(task: WithRef<IGeneratedTaskConfiguration>) {
    if (task) {
      this.task$.next(task);
    }
  }
}
