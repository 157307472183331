import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  ConfirmDialogComponent,
  DialogPresets,
  confirmationDialogData,
  type IConfirmationDialogInput,
} from '@principle-theorem/ng-shared';
import {
  AccountCredit,
  AccountCreditSplit,
  Invoice,
  InvoiceInteractionBuilder,
} from '@principle-theorem/principle-core';
import {
  IPractice,
  type IAccountCredit,
  type IInvoice,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  deleteDoc,
  filterUndefined,
  snapshot,
  undeleteDoc,
  type WithRef,
} from '@principle-theorem/shared';
import {
  EditAccountCreditDialogComponent,
  type IEditAccountCreditDialogData,
} from '../components/edit-account-credit-dialog/edit-account-credit-dialog.component';
import {
  ISplitAccountCreditDialogRequest,
  ISplitAccountCreditDialogResponse,
  SplitAccountCreditDialogComponent,
} from '../components/split-account-credit-dialog/split-account-credit-dialog.component';
import { Observable } from 'rxjs';
import { TaxRate } from '@principle-theorem/accounting';

interface IResolvedCreditDetails {
  practice?: WithRef<IPractice>;
  invoice?: WithRef<IInvoice>;
}

@Injectable()
export class AccountCreditActionsService {
  taxRate$: Observable<TaxRate>;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService
  ) {
    this.taxRate$ = this._organisation.taxRate$.pipe(filterUndefined());
  }

  async delete(item: WithRef<IAccountCredit>): Promise<void> {
    const confirmed = await this._getUserConfirmation(
      'Delete Account Credit',
      `Are you sure you want to delete ${this._getCreditLabel(item)}?`,
      'Delete'
    );
    if (!confirmed) {
      return;
    }
    await deleteDoc(item.ref);
    this._snackBar.open('Account Credit Deleted');
  }

  async restore(item: WithRef<IAccountCredit>): Promise<void> {
    const confirmed = await this._getUserConfirmation(
      'Restore Account Credit',
      `Are you sure you want to restore ${this._getCreditLabel(item)}?`,
      'Restore'
    );
    if (!confirmed) {
      return;
    }
    await undeleteDoc(item.ref);
    this._snackBar.open('Account Credit Restored');
  }

  async edit(item: WithRef<IAccountCredit>): Promise<void> {
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const brand = await snapshot(
      this._organisation.brand$.pipe(filterUndefined())
    );
    const { invoice, practice } = await this._resolveCreditDetails(item);
    const data: IEditAccountCreditDialogData = {
      credit: item,
      brand,
      practice,
    };

    const updateFields = await this._dialog
      .open<
        EditAccountCreditDialogComponent,
        IEditAccountCreditDialogData,
        Partial<WithRef<IAccountCredit>>
      >(EditAccountCreditDialogComponent, DialogPresets.small({ data }))
      .afterClosed()
      .toPromise();
    if (!updateFields) {
      return;
    }

    const credit: WithRef<IAccountCredit> = {
      ...item,
      ...updateFields,
      reservedFor: { ...item.reservedFor, ...updateFields?.reservedFor },
    };
    await AccountCredit.amend(credit, staffer.ref);

    if (invoice) {
      const taxRate = await snapshot(this.taxRate$);
      Invoice.upsertDepositLineItem(invoice, credit, taxRate);
      await Invoice.amendInvoice(invoice, staffer.ref);
      await Invoice.addInteraction(
        invoice,
        InvoiceInteractionBuilder.updatedDepositPractitioner(
          staffer,
          credit.reservedFor.practitioner
        )
      );
    }

    this._snackBar.open('Account Credit Saved');
  }

  canSplit(credit: WithRef<IAccountCredit>): boolean {
    return !credit.deleted;
  }

  async split(credit: WithRef<IAccountCredit>): Promise<void> {
    const splitBy = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const { practice } = await this._resolveCreditDetails(credit);
    const request: ISplitAccountCreditDialogRequest = {
      credit,
      practice,
    };
    const response = await this._dialog
      .open<
        SplitAccountCreditDialogComponent,
        ISplitAccountCreditDialogRequest,
        ISplitAccountCreditDialogResponse
      >(
        SplitAccountCreditDialogComponent,
        DialogPresets.large({ data: request })
      )
      .afterClosed()
      .toPromise();

    if (!response) {
      return;
    }

    const taxRate = await snapshot(this.taxRate$);
    await AccountCreditSplit.split(
      credit,
      response.splitCredit,
      response.useForTransactions,
      splitBy,
      taxRate
    );
    this._snackBar.open('Account Credit Split');
  }

  private _getCreditLabel(item: WithRef<IAccountCredit>): string {
    return `${item.type.toUpperCase()}: ${item.description}`;
  }

  private _getUserConfirmation(
    title: string,
    prompt: string,
    submitLabel: string
  ): Promise<boolean | undefined> {
    const data = confirmationDialogData({
      title,
      prompt,
      submitLabel,
      submitColor: 'warn',
    });
    return this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
  }

  private async _resolveCreditDetails(
    credit: WithRef<IAccountCredit>
  ): Promise<IResolvedCreditDetails> {
    const invoice = credit?.invoice
      ? await Firestore.getDoc<IInvoice>(credit.invoice)
      : undefined;
    const practice = invoice?.practice.ref
      ? await Firestore.getDoc(invoice.practice.ref)
      : undefined;
    return { invoice, practice };
  }
}
