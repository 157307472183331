import { type Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { differenceWith } from 'lodash';

export class SelectedItemsBloc<T> {
  unselected$: Observable<T[]>;

  constructor(
    public all$: Observable<T[]>,
    public selected$: Observable<T[]>,
    public isSame: (a: T, b: T) => boolean
  ) {
    this.unselected$ = combineLatest([this.all$, this.selected$]).pipe(
      map(([all, selected]: [T[], T[]]) =>
        differenceWith(all, selected, this.isSame)
      )
    );
  }
}
