import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import {
  ICustomReport,
  IRestrictAccessRole,
  IRestrictAccessStaffer,
  RestrictAccessEntity,
} from '@principle-theorem/principle-core/interfaces';
import { switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ReportBuilderStore } from '../../../report-builder/report-builder.store';
import {
  WithRef,
  all$,
  filterUndefined,
  multiMap,
  undeletedQuery,
} from '@principle-theorem/shared';
import {
  TypedFormControl,
  TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import { Validators } from '@angular/forms';
import { ICustomReportForm } from '../edit-custom-report-dialog/edit-custom-report-dialog.component';
import { RestrictAccess } from '@principle-theorem/principle-core';
import { isEqual } from 'lodash';

@Component({
    selector: 'pr-custom-report-form',
    templateUrl: './custom-report-form.component.html',
    styleUrl: './custom-report-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CustomReportFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  roles$: Observable<IRestrictAccessRole[]>;
  staff$: Observable<IRestrictAccessStaffer[]>;
  customReport$ = new BehaviorSubject<WithRef<ICustomReport> | undefined>(
    undefined
  );
  form = new TypedFormGroup<ICustomReportForm>({
    name: new TypedFormControl<string>('', [Validators.required]),
    description: new TypedFormControl<string>(''),
    restrictAccessTo: new TypedFormControl<RestrictAccessEntity[]>([]),
  });
  @Output() closeDialog = new EventEmitter<void>();
  @Output() saveCustomReport = new EventEmitter<ICustomReportForm>();

  @Input()
  set customReport(report: WithRef<ICustomReport>) {
    if (report) {
      this.customReport$.next(report);
    }
  }

  constructor(
    private _organisation: OrganisationService,
    private _store: ReportBuilderStore
  ) {
    this.staff$ = this._organisation.staff$.pipe(
      multiMap((staffer) => RestrictAccess.toStafferAccess(staffer))
    );

    this.roles$ = this._organisation.roleCol$.pipe(
      switchMap((col) => (col ? all$(undeletedQuery(col)) : of([]))),
      multiMap((role) => RestrictAccess.toRoleAccess(role))
    );

    this.customReport$
      .pipe(filterUndefined(), takeUntil(this._onDestroy$))
      .subscribe((report) =>
        this.form.patchValue(report, { emitEvent: false })
      );

    validFormGroupChanges$(this.form)
      .pipe(
        withLatestFrom(this.customReport$.pipe(filterUndefined())),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([formChanges, customReport]) => {
        this._store.patchState({ formChanges });

        const currentReportDetails = {
          name: customReport.name,
          description: customReport.description,
          restrictAccessTo: customReport.restrictAccessTo,
        };

        if (isEqual(formChanges, currentReportDetails)) {
          this._store.patchState({ formChanges: undefined });
        }
      });

    this._store.resetCustomReportForm$
      .pipe(
        withLatestFrom(this.customReport$.pipe(filterUndefined())),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([_, customReport]) =>
        this.form.patchValue(customReport, { emitEvent: false })
      );
  }

  compareAccessFn(a: RestrictAccessEntity, b: RestrictAccessEntity): boolean {
    return a && b ? a.ref.path === b.ref.path : false;
  }

  getRestrictAccessTo(formData: ICustomReportForm): RestrictAccessEntity[] {
    return formData.restrictAccessTo.length ? formData.restrictAccessTo : [];
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
