import {
  moveItemInArray,
  transferArrayItem,
  type CdkDragDrop,
} from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { ServiceCodeEntry } from '@principle-theorem/principle-core';
import {
  type IServiceCodeEntry,
  type IServiceCodeGroup,
} from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-service-groups-editor',
  templateUrl: './service-groups-editor.component.html',
  styleUrls: ['./service-groups-editor.component.scss'],
})
export class ServiceGroupsEditorComponent {
  trackByGroup = TrackByFunctions.field<IServiceCodeGroup>('type');
  trackByCode = TrackByFunctions.ref<IServiceCodeEntry>('code.ref');
  @Input() serviceCodeGroups: IServiceCodeGroup[] = [];
  @Output() serviceCodeGroupsChange = new EventEmitter<IServiceCodeGroup[]>();

  emitChanges(): void {
    this.serviceCodeGroupsChange.emit(this.serviceCodeGroups);
  }

  deleteGroup(group: IServiceCodeGroup): void {
    this.serviceCodeGroups = this.serviceCodeGroups.filter(
      (item) => item !== group
    );
    this.emitChanges();
  }

  drop(event: CdkDragDrop<IServiceCodeEntry[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    this.emitChanges();
  }

  increaseQty(entry: IServiceCodeEntry): void {
    entry.quantity += 1;
    this.emitChanges();
  }

  decreaseQty(entity: IServiceCodeEntry): void {
    entity.quantity -= 1;
    this.emitChanges();
  }

  deleteEntry(entry: IServiceCodeEntry): void {
    this.serviceCodeGroups.map((group) => {
      group.serviceCodes = group.serviceCodes.filter((item) => item !== entry);
    });
    this.emitChanges();
  }

  getCodeName(code: IServiceCodeEntry): string {
    return ServiceCodeEntry.getName(code) ?? '';
  }
}
