import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class GCloudErrorHandlerService
  extends ErrorHandler
  implements ErrorHandler
{
  constructor(private injector: Injector) {
    super();
  }

  override handleError(error: unknown): void {
    if (error instanceof Error) {
      const logger = this.injector.get(LoggerService);
      void logger.log('ERROR', error.message, { message: error.message });
    }
    super.handleError(error);
  }
}
