import {
  BlockNodes,
  isNodeSchema,
  type RawSchema,
  recursiveReplace,
  type SchemaDataProvider,
} from '@principle-theorem/editor';
import {
  type IMigration,
  type IMigrationLogger,
  Migration,
} from '@principle-theorem/migration-runner';
import { last } from 'lodash';

@Migration({
  name: 'fix-paragraph-type',
  uid: '1038abc6-52f0-4b25-8584-7dfb5522091a',
})
export class FixParagraphTypeMigration implements IMigration {
  async up(
    dryRun: boolean,
    _logger: IMigrationLogger,
    data: SchemaDataProvider
  ): Promise<void> {
    const replaceTypes = [
      'Left Align',
      'Center Align',
      'Right Align',
      'Justify',
    ];
    const schema = await data.get();
    const newSchema = recursiveReplace(schema, (item, parents) => {
      if (
        !isNodeSchema(item, last(parents)) ||
        !replaceTypes.includes(item.type)
      ) {
        return item;
      }
      return {
        ...item,
        type: BlockNodes.Paragraph,
      };
    });

    if (!dryRun) {
      await data.update(newSchema as RawSchema);
    }
  }

  async down(
    _dryRun: boolean,
    _logger: IMigrationLogger,
    _data: SchemaDataProvider
  ): Promise<void> {
    // Do nuzzing
  }
}
