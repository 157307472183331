import { PaymentPlanMock } from '@principle-theorem/principle-core';
import { PaymentPlanStatus } from '@principle-theorem/principle-core/interfaces';
import { IPaymentPlanDimension } from '@principle-theorem/reporting/interfaces';
import {
  MockGenerator,
  toSerialisedTimestamp,
  toTimestamp,
} from '@principle-theorem/shared';
import {
  BigQueryFirestoreModelMock,
  MockBigQueryStatusHistory,
  SerialisedDocumentReferenceMock,
} from './common.mock';

const paymentPlan = MockGenerator.generate(PaymentPlanMock);

export class PaymentPlanDimensionMock
  extends BigQueryFirestoreModelMock
  implements IPaymentPlanDimension
{
  timestamp = toSerialisedTimestamp(toTimestamp());
  name = paymentPlan.name;
  durationLength = paymentPlan.durationLength;
  durationType = paymentPlan.durationType;
  invoices = [MockGenerator.generate(SerialisedDocumentReferenceMock)];
  treatmentPlan = MockGenerator.generate(SerialisedDocumentReferenceMock);
  status = paymentPlan.status;
  statusHistory = [
    MockBigQueryStatusHistory(PaymentPlanStatus.Draft),
    MockBigQueryStatusHistory(PaymentPlanStatus.Active),
  ];
  paymentFrequency = paymentPlan.paymentFrequency;
  paymentSchedule = paymentPlan.paymentSchedule.map((paymentSchedule) => ({
    ...paymentSchedule,
    date: toSerialisedTimestamp(paymentSchedule.date),
  }));
  total = paymentPlan.total;
  completedAt = toSerialisedTimestamp(paymentPlan.completedAt);
}
