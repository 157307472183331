import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import {
  ChartedItem,
  ChartedItemScopeResolver,
  ChartedSurface,
  ChartedTreatmentUpdater,
  FeeScheduleManager,
  TreatmentStep,
  buildUpsertTreatmentData,
  isWithinSurfaces,
  type ISurfaceScopeRefPair,
} from '@principle-theorem/principle-core';
import {
  isChartedCondition,
  isChartedMultiStepTreatment,
  isChartedTreatment,
  type ChartItemDisplayType,
  type ChartSection,
  type ChartType,
  type ChartView,
  type ChartableSurface,
  type IAppointment,
  type IChartedCondition,
  type IChartedItem,
  type IChartedItemDetail,
  type IChartedMultiStepTreatment,
  type IChartedRef,
  type IChartedSurface,
  type IChartedTreatment,
  type IClinicalChart,
  type IDentalChartViewSurface,
  type IFeeSchedule,
  type IPatient,
  type IStaffer,
  type ITooth,
  type IToothRef,
  type ITreatmentConfiguration,
  type ITreatmentPlanProposal,
} from '@principle-theorem/principle-core/interfaces';
import {
  asDocRef,
  asyncForEach,
  filterUndefined,
  isChanged$,
  isSameRef,
  serialise,
  snapshot,
  unserialise$,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, isEqual } from 'lodash';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  addCondition,
  addMultiTreatment,
  addTooth,
  addTreatment,
  deselectSurface,
  loadChartFromAppointment,
  loadChartSuccess,
  removeChart,
  removeChartedSurface,
  removeChartedSurfaces,
  removeCondition,
  removeMultiTreatment,
  removeTooth,
  removeTreatment,
  resetChart,
  selectSurface,
  setChart,
  setChartSection,
  setChartTeeth,
  setChartType,
  setChartView,
  setChartedItemFilters,
  setChartingAs,
  setDisabledSurfaces,
  setIsStacked,
  setSelectedSurfaces,
  updateCondition,
  updateMultiTreatment,
  updatePlanProposal,
  updateToothRoots,
  updateTreatment,
  updateTreatments,
} from '../actions';
import {
  type ChartId,
  type IChartContextState,
} from '../reducers/active-charts/chart-context-state';
import { type IChartState } from '../reducers/reducers';
import {
  getChartContextChartingAs,
  getChartContextFilters,
  getChartEntity,
  getChartIsStacked,
  getChartSection,
  getChartType,
  getChartView,
  getChartedConditions,
  getChartedMultiTreatments,
  getChartedTreatments,
  getClinicalChartState,
  getDisabledSurfacesState,
  getPlanProposal,
  getSelectedSurfacesState,
} from '../selectors/active-charts.selectors';
import { FeeScheduleFacade } from './fee-schedule.facade';

@Injectable()
export class ChartFacade {
  private _store = inject(Store<IChartState>);

  constructor(
    private _feeScheduleState: FeeScheduleFacade,
    private _globalState: GlobalStoreService
  ) {}

  loadChartFromAppointment(
    id: ChartId,
    appointment: WithRef<IAppointment>,
    patient: WithRef<IPatient>
  ): void {
    this._store.dispatch(
      loadChartFromAppointment(
        serialise({
          id,
          appointment,
          patient,
        })
      )
    );
  }

  chartSection$(id: ChartId): Observable<ChartSection> {
    return this._store.pipe(select(getChartSection(id)), filterUndefined());
  }

  disabledSurfacesState$(id: ChartId): Observable<ChartableSurface[]> {
    return this._store.pipe(
      select(getDisabledSurfacesState(id)),
      filterUndefined()
    );
  }

  selectedSurfacesState$(id: ChartId): Observable<Partial<IChartedRef>[]> {
    return this._store.pipe(
      select(getSelectedSurfacesState(id)),
      filterUndefined()
    );
  }

  chartType$(id: ChartId): Observable<ChartType> {
    return this._store.pipe(select(getChartType(id)), filterUndefined());
  }

  chartView$(id: ChartId): Observable<ChartView> {
    return this._store.pipe(select(getChartView(id)), filterUndefined());
  }

  isStacked$(id: ChartId): Observable<boolean> {
    return this._store.pipe(select(getChartIsStacked(id)));
  }

  chartContextState$(id: ChartId): Observable<IChartContextState> {
    return this._store.pipe(
      select(getChartEntity(id)),
      filterUndefined(),
      unserialise$()
    );
  }

  planProposal$(id: ChartId): Observable<ITreatmentPlanProposal> {
    return this._store.pipe(
      select(getPlanProposal(id)),
      filterUndefined(),
      unserialise$()
    );
  }

  clinicalChartState$(
    id: ChartId
  ): Observable<WithRef<IClinicalChart> | IClinicalChart> {
    return this._store.pipe(
      select(getClinicalChartState(id)),
      filterUndefined(),
      unserialise$()
    );
  }

  removeChart(id: ChartId): void {
    return this._store.dispatch(removeChart({ id }));
  }

  chartedConditions$(id: ChartId): Observable<IChartedCondition[]> {
    return this._store.pipe(
      select(getChartedConditions(id)),
      filterUndefined(),
      unserialise$()
    );
  }

  chartedTreatments$(id: ChartId): Observable<IChartedTreatment[]> {
    return this._store.pipe(
      select(getChartedTreatments(id)),
      filterUndefined(),
      unserialise$()
    );
  }

  chartedMultiTreatments$(
    id: ChartId
  ): Observable<IChartedMultiStepTreatment[]> {
    return this._store.pipe(
      select(getChartedMultiTreatments(id)),
      filterUndefined(),
      unserialise$()
    );
  }

  chartingAs$(id: ChartId): Observable<WithRef<IStaffer>> {
    return this._store.pipe(
      select(getChartContextChartingAs(id)),
      unserialise$(),
      filterUndefined(),
      switchMap((chartingAs) =>
        this._globalState.getStaffer$(asDocRef<IStaffer>(chartingAs.ref))
      ),
      filterUndefined()
    );
  }

  chartContextFilters$(id: ChartId): Observable<ChartItemDisplayType[]> {
    return this._store.pipe(
      select(getChartContextFilters(id)),
      filterUndefined()
    );
  }

  chartedItems$(
    id: ChartId
  ): Observable<
    (IChartedCondition | IChartedTreatment | IChartedMultiStepTreatment)[]
  > {
    return combineLatest([
      this._store.pipe(select(getChartedConditions(id))),
      this._store.pipe(select(getChartedTreatments(id))),
      this._store.pipe(select(getChartedMultiTreatments(id))),
    ]).pipe(
      map(([conditions, treatments, multiTreatments]) =>
        compact([
          ...(conditions ?? []),
          ...(treatments ?? []),
          ...(multiTreatments ?? []),
        ])
      ),
      isChanged$((itemA, itemB) => isEqual(itemA, itemB)),
      unserialise$()
    );
  }

  canEdit$(id: ChartId): Observable<boolean> {
    return this.clinicalChartState$(id).pipe(map((chart) => !chart.immutable));
  }

  setChartType(id: ChartId, chartType: ChartType): void {
    this._store.dispatch(setChartType(serialise({ id, chartType })));
  }

  setDisabledSurfaces(id: ChartId, disabled: ChartableSurface[]): void {
    this._store.dispatch(setDisabledSurfaces(serialise({ id, disabled })));
  }

  isDisabledSurface$(
    id: ChartId,
    surface: ChartableSurface
  ): Observable<boolean> {
    return this.disabledSurfacesState$(id).pipe(
      map((disabled: ChartableSurface[]) => disabled.includes(surface))
    );
  }

  setIsStacked(id: ChartId, stackedRows: boolean): void {
    this._store.dispatch(setIsStacked({ id, stackedRows }));
  }

  setChartSection(id: ChartId, section: ChartSection): void {
    this._store.dispatch(setChartSection({ id, section }));
  }

  setSelectedSurfaces(id: ChartId, selected: Partial<IChartedRef>[]): void {
    this._store.dispatch(setSelectedSurfaces(serialise({ id, selected })));
  }

  selectSurface(id: ChartId, surface: Partial<IChartedRef>): void {
    this._store.dispatch(selectSurface(serialise({ id, surface })));
  }

  deselectSurface(id: ChartId, surface: Partial<IChartedRef>): void {
    this._store.dispatch(deselectSurface(serialise({ id, surface })));
  }

  isSelectedSurface$(
    id: ChartId,
    surface: Partial<IChartedRef>
  ): Observable<boolean> {
    return this.selectedSurfacesState$(id).pipe(
      unserialise$(),
      map((selected: Partial<IChartedRef>[]) =>
        isWithinSurfaces(surface, selected)
      )
    );
  }

  addTooth(id: ChartId, tooth: ITooth): void {
    this._store.dispatch(addTooth(serialise({ id, tooth })));
  }

  removeTooth(id: ChartId, toothRef: IToothRef): void {
    this._store.dispatch(removeTooth(serialise({ id, toothRef })));
  }

  updateToothRoots(id: ChartId, toothRef: IToothRef, roots: number): void {
    this._store.dispatch(updateToothRoots(serialise({ id, toothRef, roots })));
  }

  async resolveChartedItem(
    id: ChartId,
    item: IChartedItem,
    resolvedBy?: WithRef<IStaffer>
  ): Promise<void> {
    if (isChartedCondition(item)) {
      return this.updateCondition(id, ChartedItem.resolve(item, resolvedBy));
    }

    if (isChartedTreatment(item)) {
      return this.updateTreatment(id, ChartedItem.resolve(item, resolvedBy));
    }

    if (isChartedMultiStepTreatment(item)) {
      return this.updateMultiTreatment(
        id,
        ChartedItem.resolve(item, resolvedBy)
      );
    }
  }

  setChart(id: ChartId, chart: IClinicalChart | WithRef<IClinicalChart>): void {
    this._store.dispatch(setChart(serialise({ id, chart })));
  }

  resetChart(id: ChartId): void {
    this._store.dispatch(resetChart(serialise({ id })));
  }

  loadChartSuccess(id: ChartId, chart: IClinicalChart): void {
    this._store.dispatch(loadChartSuccess(serialise({ id, chart })));
  }

  setChartTeeth(id: ChartId, teeth: ITooth[]): void {
    this._store.dispatch(setChartTeeth(serialise({ id, teeth })));
  }

  setChartingAs(id: ChartId, staffer: WithRef<IStaffer>): void {
    this._store.dispatch(setChartingAs(serialise({ id, staffer })));
  }

  setChartedItemFilters(id: ChartId, filters: ChartItemDisplayType[]): void {
    this._store.dispatch(setChartedItemFilters(serialise({ id, filters })));
  }

  async removeChartedSurfaces(
    id: ChartId,
    chartedRefs: Partial<IChartedRef>[],
    item: IChartedItem
  ): Promise<void> {
    const chart: IClinicalChart = await snapshot(this.clinicalChartState$(id));

    chart.conditions.map((condition) => {
      const chartedSurfaces = this._getFilteredSurfaces(
        condition,
        chartedRefs,
        item
      );
      if (condition.chartedSurfaces.length === chartedSurfaces.length) {
        return;
      }
      if (!chartedSurfaces.length) {
        return this.removeCondition(id, condition.uuid);
      }

      return this._store.dispatch(
        updateCondition(
          serialise({
            id,
            condition: {
              uuid: condition.uuid,
              chartedSurfaces,
            },
          })
        )
      );
    });

    chart.flaggedTreatment.treatments.map((treatment) => {
      const chartedSurfaces = this._getFilteredSurfaces(
        treatment,
        chartedRefs,
        item
      );
      if (treatment.chartedSurfaces.length === chartedSurfaces.length) {
        return;
      }
      if (!chartedSurfaces.length) {
        return this.removeTreatment(id, treatment.uuid);
      }

      return this._store.dispatch(
        updateTreatment(
          serialise({
            id,
            treatment: {
              uuid: treatment.uuid,
              chartedSurfaces,
            },
          })
        )
      );
    });

    chart.flaggedTreatment.multiTreatments.map((multiTreatment) => {
      const multiTreatmentSurfaces = this._getFilteredSurfaces(
        multiTreatment,
        chartedRefs,
        item
      );

      let shouldUpdateMultiTreatment = false;
      if (
        multiTreatment.chartedSurfaces.length !== multiTreatmentSurfaces.length
      ) {
        shouldUpdateMultiTreatment = true;
      }

      if (!multiTreatmentSurfaces.length) {
        return this.removeMultiTreatment(id, multiTreatment.uuid);
      }

      multiTreatment.steps = multiTreatment.steps.map((step) => {
        return {
          ...step,
          treatments: compact(
            step.treatments.map((treatment) => {
              const treatmentSurfaces = this._getFilteredSurfaces(
                treatment,
                chartedRefs,
                item
              );

              if (!treatmentSurfaces.length) {
                return;
              }
              if (
                treatment.chartedSurfaces.length === treatmentSurfaces.length
              ) {
                shouldUpdateMultiTreatment = true;
              }
              return {
                ...treatment,
                chartedSurfaces: treatmentSurfaces,
              };
            })
          ),
        };
      });

      if (!shouldUpdateMultiTreatment) {
        return;
      }

      return this._store.dispatch(
        updateMultiTreatment(
          serialise({
            id,
            multiTreatment: {
              uuid: multiTreatment.uuid,
              steps: multiTreatment.steps,
              chartedSurfaces: multiTreatmentSurfaces,
            },
          })
        )
      );
    });

    this._store.dispatch(
      removeChartedSurfaces(
        serialise({
          id,
          item,
          chartedSurfaces: chartedRefs,
        })
      )
    );
  }

  async removeChartedSurface(
    id: ChartId,
    chartedRef: Partial<IChartedRef>,
    item: IChartedItem
  ): Promise<void> {
    await this.removeChartedSurfaces(id, [chartedRef], item);

    this._store.dispatch(
      removeChartedSurface(
        serialise({
          id,
          item,
          chartedSurface: chartedRef,
        })
      )
    );
  }

  async removeChartedItem(
    id: ChartId,
    view: IDentalChartViewSurface,
    itemDetail: IChartedItemDetail
  ): Promise<void> {
    const chartedSurface = itemDetail.item.chartedSurfaces.find((surface) =>
      ChartedSurface.isSameChartedRef(surface, view.id)
    );
    if (!chartedSurface) {
      return;
    }
    await this.removeChartedSurface(
      id,
      chartedSurface.chartedRef,
      itemDetail.item
    );
  }

  async addCondition(
    id: ChartId,
    condition: IChartedCondition,
    surfaces: IChartedSurface[]
  ): Promise<void> {
    if (!surfaces.length) {
      return;
    }

    const conditions = await snapshot(this.chartedConditions$(id));
    const currentCondition = conditions
      .filter((chartedCondition) => !ChartedItem.isResolved(chartedCondition))
      .find((chartedCondition) =>
        isSameRef(chartedCondition.config, condition.config)
      );

    if (!currentCondition) {
      return this._store.dispatch(
        addCondition(
          serialise({
            id,
            condition: {
              ...condition,
              chartedSurfaces: surfaces,
            },
          })
        )
      );
    }

    const filteredSurfaces: IChartedSurface[] = surfaces.filter(
      (surface: IChartedSurface) => {
        const alreadyCharted: boolean = currentCondition.chartedSurfaces.some(
          (currentSurface) =>
            isEqual(currentSurface.chartedRef, surface.chartedRef)
        );
        return !alreadyCharted;
      }
    );

    if (!filteredSurfaces.length) {
      return;
    }

    return this._store.dispatch(
      updateCondition(
        serialise({
          id,
          condition: {
            uuid: currentCondition.uuid,
            chartedSurfaces: [
              ...currentCondition.chartedSurfaces,
              ...filteredSurfaces,
            ],
          },
        })
      )
    );
  }

  updateCondition(id: ChartId, condition: IChartedCondition): void {
    this._store.dispatch(updateCondition(serialise({ id, condition })));
  }

  removeCondition(id: ChartId, uuid: string): void {
    this._store.dispatch(removeCondition(serialise({ id, uuid })));
  }

  async addTreatment(
    id: ChartId,
    config: WithRef<ITreatmentConfiguration>,
    surfaces: IChartedSurface[],
    feeSchedule: WithRef<IFeeSchedule>,
    attributedTo?: INamedDocument<IStaffer>
  ): Promise<void> {
    const scopeResolver = new ChartedItemScopeResolver();
    const surfaceScopeRefPairs = scopeResolver.reduceChartedSurfacesToScope(
      config,
      surfaces
    );

    const chart = await snapshot(this.clinicalChartState$(id));

    await this._addTreatment(
      config,
      surfaceScopeRefPairs,
      chart,
      scopeResolver,
      id,
      feeSchedule,
      attributedTo
    );
  }

  async updateMultiTreatment(
    id: ChartId,
    multiTreatment: IChartedMultiStepTreatment
  ): Promise<void> {
    this._store.dispatch(
      updateMultiTreatment(
        serialise({
          id,
          multiTreatment:
            await this.updatePrimaryTreatmentCategories(multiTreatment),
        })
      )
    );
  }

  removeMultiTreatment(id: ChartId, uuid: string): void {
    this._store.dispatch(removeMultiTreatment(serialise({ id, uuid })));
  }

  updateTreatment(id: ChartId, treatment: IChartedTreatment): void {
    this._store.dispatch(updateTreatment(serialise({ id, treatment })));
  }

  updatePlanProposal(id: ChartId, proposal: ITreatmentPlanProposal): void {
    this._store.dispatch(updatePlanProposal(serialise({ id, proposal })));
  }

  updateTreatments(id: ChartId, treatments: IChartedTreatment[]): void {
    this._store.dispatch(updateTreatments(serialise({ id, treatments })));
  }

  removeTreatment(id: ChartId, uuid: string): void {
    this._store.dispatch(removeTreatment(serialise({ id, uuid })));
  }

  getFeeScheduleManager(): FeeScheduleManager {
    return new FeeScheduleManager(
      this._feeScheduleState.selectedFeeSchedule$.pipe(filterUndefined())
    );
  }

  setChartView(id: ChartId, view: ChartView): void {
    this._store.dispatch(setChartView(serialise({ id, view })));
  }

  async addChartedMultiTreatment(
    id: ChartId,
    multiTreatment: IChartedMultiStepTreatment
  ): Promise<void> {
    this._store.dispatch(
      addMultiTreatment(
        serialise({
          id,
          multiTreatment:
            await this.updatePrimaryTreatmentCategories(multiTreatment),
        })
      )
    );
  }

  async updatePrimaryTreatmentCategories(
    multiTreatment: IChartedMultiStepTreatment
  ): Promise<IChartedMultiStepTreatment> {
    const categories = await snapshot(this._globalState.treatmentCategories$);

    return {
      ...multiTreatment,
      steps: await asyncForEach(multiTreatment.steps, async (step) =>
        TreatmentStep.updateDisplayPrimaryCategory(step, categories)
      ),
    };
  }

  private async _addTreatment(
    config: WithRef<ITreatmentConfiguration>,
    surfaceScopeRefPair: ISurfaceScopeRefPair[],
    chart: IClinicalChart | WithRef<IClinicalChart>,
    scopeResolver: ChartedItemScopeResolver,
    id: ChartId,
    feeSchedule: WithRef<IFeeSchedule>,
    attributedTo?: INamedDocument<IStaffer>
  ): Promise<void> {
    const data = await asyncForEach(surfaceScopeRefPair, (pair) =>
      buildUpsertTreatmentData(
        config,
        this.getFeeScheduleManager(),
        feeSchedule,
        pair,
        chart.flaggedTreatment.treatments,
        scopeResolver,
        attributedTo
      )
    );

    const allTreatments = data.map(([_, treatment]) => treatment);
    const syncedTreatments =
      await ChartedTreatmentUpdater.syncPricingRules(allTreatments);

    data.map(([existing, treatment]) => {
      const found = syncedTreatments.find(
        (syncedTreatment) => syncedTreatment.uuid === treatment.uuid
      );
      if (!found) {
        // eslint-disable-next-line no-console
        console.error(
          `Failed to find synced treatment for ${treatment.uuid}`,
          treatment
        );
        return;
      }

      const actionData = serialise({
        id,
        treatment: found,
      });

      const action = existing
        ? updateTreatment(actionData)
        : addTreatment(actionData);

      this._store.dispatch(action);
    });
  }

  private _getFilteredSurfaces(
    chartedItem:
      | IChartedCondition
      | IChartedTreatment
      | IChartedMultiStepTreatment,
    chartedRefsToRemove: Partial<IChartedRef>[],
    item: IChartedItem
  ): IChartedSurface[] {
    return chartedItem.chartedSurfaces.filter((chartedSurface) =>
      chartedRefsToRemove.every((chartedRefToRemove) => {
        const isDifferentTreatment = !isSameRef(
          chartedItem.config,
          item.config
        );
        const isDifferentSurface = !ChartedSurface.isSameChartedRef(
          chartedSurface,
          chartedRefToRemove
        );

        if (isDifferentTreatment || isDifferentSurface) {
          return true;
        }

        return false;
      })
    );
  }
}
