import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { GapsComponent } from './gaps/gaps.component';
import { PagesModule } from './pages.module';

const routes: Routes = [
  {
    path: '',
    component: GapsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), PagesModule],
  exports: [RouterModule],
})
export class GapPagesRoutingModule {}
