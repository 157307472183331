import {
  type ICalendarEventSchedule,
  type IEventable,
  type IScheduleSummary,
  type IScheduleSummaryEvent,
  type IScheduleSummaryTarget,
  type IScheduleSummaryUpsertAction,
} from '@principle-theorem/principle-core/interfaces';
import {
  type DocumentReference,
  type Transaction,
  type WithRef,
  addDoc,
  asyncForEach,
  isSameRef,
  Firestore,
  runTransaction,
} from '@principle-theorem/shared';
import { ScheduleSummaryHelpers } from './schedule-summary-helpers';
import { ScheduleSummary } from './schedule-summary';

export class ScheduleSummaryEventUpdater {
  static async update<T extends object>(
    ref: DocumentReference<T>,
    before?: WithRef<IEventable<T>>,
    after?: WithRef<IEventable<T>>,
    isBlocking: boolean = false,
    eventDeleted: boolean = false
  ): Promise<void> {
    const summary = await ScheduleSummary.toSummaryEvent(
      ref,
      after?.event ? after : undefined,
      isBlocking
    );

    const targets = ScheduleSummaryHelpers.uniqueTargets([
      ...(await ScheduleSummaryHelpers.getTargets(before?.event)),
      ...(await ScheduleSummaryHelpers.getTargets(after?.event)),
    ]);

    await runTransaction(async (transaction) => {
      const actions = await asyncForEach(targets, (target) =>
        this.getScheduleSummaryUpsertAction(
          target,
          eventDeleted,
          transaction,
          ref,
          summary
        )
      );
      await asyncForEach(actions, async (action) =>
        this.upsertAggregate(transaction, action)
      );
    });
  }

  static async upsertAggregate(
    transaction: Transaction,
    action?: IScheduleSummaryUpsertAction
  ): Promise<DocumentReference<IScheduleSummary> | undefined> {
    if (!action) {
      return;
    }

    if (action.existing) {
      await Firestore.patchDoc(action.existing.ref, action.data, transaction);
      return action.existing.ref;
    }
    return addDoc(
      ScheduleSummaryHelpers.getCollection(action.target),
      action.data,
      undefined,
      transaction
    );
  }

  static async getScheduleSummaryUpsertAction<T extends object>(
    target: IScheduleSummaryTarget,
    eventDeleted: boolean,
    transaction: Transaction,
    eventRef?: DocumentReference<T>,
    summary?: IScheduleSummaryEvent<T>
  ): Promise<IScheduleSummaryUpsertAction | undefined> {
    const existing = await ScheduleSummaryHelpers.findScheduleSummary(
      target,
      transaction
    );
    const scheduleData = await ScheduleSummaryHelpers.getStafferRosterSchedules(
      target.staffer,
      target.practice,
      transaction
    );
    const data = await this.getAggregateData(
      target,
      eventDeleted,
      scheduleData,
      eventRef,
      summary,
      existing
    );
    if (!data) {
      return;
    }
    return { target, data, existing };
  }

  static async getAggregateData<T extends object>(
    target: IScheduleSummaryTarget,
    eventDeleted: boolean,
    scheduleData: WithRef<ICalendarEventSchedule>[],
    eventRef?: DocumentReference<T>,
    summary?: IScheduleSummaryEvent<T>,
    existing?: IScheduleSummary
  ): Promise<IScheduleSummary> {
    const events = await this.getEvents(
      target,
      eventDeleted,
      summary,
      existing,
      eventRef
    );
    const gaps = await ScheduleSummaryHelpers.getGapTimes(
      target,
      scheduleData,
      events
    );
    return {
      ...target,
      ...existing,
      events,
      gaps,
    };
  }

  static async getEvents<T extends object = object>(
    target: IScheduleSummaryTarget,
    isEventDeleted: boolean,
    newSummary?: IScheduleSummaryEvent<T>,
    existingScheduleSummary?: IScheduleSummary,
    eventRefToCompare?: DocumentReference<T>
  ): Promise<IScheduleSummaryEvent[]> {
    const existingEvents = existingScheduleSummary?.events ?? [];

    const filteredEvents = existingEvents.filter(
      (existingSummary) => !isSameRef(existingSummary.ref, eventRefToCompare)
    );

    if (!newSummary || isEventDeleted) {
      return filteredEvents;
    }

    const isSummaryForSameDay = await ScheduleSummaryHelpers.isSummaryOnDay(
      target,
      newSummary
    );
    if (!isSummaryForSameDay) {
      return filteredEvents;
    }

    const isEventForStaffer = newSummary.event.participantRefs.some((ref) =>
      isSameRef(ref, target.staffer)
    );

    const isEventForSamePractice = isSameRef(
      newSummary.event.practice,
      target.practice
    );

    if (isEventForStaffer && isEventForSamePractice) {
      filteredEvents.push(newSummary);
    }

    return filteredEvents;
  }
}
