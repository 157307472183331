export interface IProvider<T extends object, R> {
  canProvide(data: T): boolean;
  execute(data: T): R;
}

export function getProviderResult<T extends object, R>(
  data: T,
  providers: IProvider<T, R>[]
): R | undefined {
  const matchingProvider = providers.find((provider) =>
    provider.canProvide(data)
  );
  if (!matchingProvider) {
    return;
  }
  return matchingProvider.execute(data);
}

export function getMultiProviderResults<T extends object, R>(
  data: T,
  providers: IProvider<T, R>[]
): R[] | undefined {
  const matchingProviders = providers.filter((provider) =>
    provider.canProvide(data)
  );
  if (!matchingProviders.length) {
    return;
  }
  return matchingProviders.map((provider) => provider.execute(data));
}
