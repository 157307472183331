<mat-toolbar color="accent">Create Lab Job</mat-toolbar>

<mat-dialog-content>
  <pr-lab-job-form
    #labJobForm="prLabJobForm"
    [showActions]="false"
    [lab]="data.lab"
    [patient]="data.patient"
    [appointment]="data.appointment"
   />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="saving$ | async"
      (click)="save(labJobForm.labJobFormGroup.getRawValue())"
    >
      Create
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
