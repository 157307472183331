<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<mat-drawer-container class="relative h-full w-full">
  <mat-drawer-content>
    <div class="flex flex-col gap-4 p-4">
      <h1 class="mat-headline-4 !mb-0">Medical History Preview</h1>

      <pr-information-box>
        Use this page to configure which responses on the Medical History Form
        trigger medical alerts on a patient's profile. Selecting responses on
        the form here will preview how alerts are displayed based on your
        preferences.
      </pr-information-box>

      <mat-card class="mb-4">
        <mat-card-content>
          <pr-custom-form
            hideSubmit
            [form]="form$ | async"
            (formChanges)="updateForm($event)"
          />
        </mat-card-content>
      </mat-card>
    </div>
  </mat-drawer-content>
  <mat-drawer mode="side" opened disableClose position="end">
    <div class="flex flex-col gap-4 p-4">
      <div class="flex flex-col gap-4">
        <h2 class="mat-headline-5 !mb-0">Alerts Preview</h2>

        <pr-information-box>
          Preview how your medical history alerts will be shown by interacting
          with the medical history form.
        </pr-information-box>

        <mat-card>
          <mat-card-content>
            <h2 class="mat-headline-6">Alert Badge</h2>
            <pr-medical-alerts-preview
              [alertCount]="medicalFormAlerts.alertCount$ | async"
            />
          </mat-card-content>
        </mat-card>

        <mat-card>
          <mat-card-content>
            <h2 class="mat-headline-6">Medical History Details</h2>

            @if (medicalFormAlerts.alertCount$ | async) {
              <pr-patient-medical-alert-details
                [conditions]="medicalFormAlerts.conditions$ | async"
                [allergies]="medicalFormAlerts.allergies$ | async"
                [history]="medicalFormAlerts.history$ | async"
              />
            } @else {
              <p>No alerts selected</p>
            }
          </mat-card-content>
        </mat-card>
      </div>

      <div class="flex flex-col gap-4">
        <h2 class="mat-headline-5 !mb-0">Manage Alerts</h2>
        <pr-information-box>
          Choose what fields on the medical history form should be added as an
          alert.
        </pr-information-box>
        <pr-medical-history-alerts-configure
          [schema]="schema$ | async"
          [resolverConfigs]="resolverConfigs$ | async"
          (updateAlerts)="updateAlerts($event)"
        />
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>
