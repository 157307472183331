<mat-toolbar color="accent">Update Healthfund Card</mat-toolbar>

<form [formGroup]="healthFundForm" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field fxFlex>
      <mat-label>Health Fund Name</mat-label>
      <input matInput formControlName="fundCode" />
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>Membership Number</mat-label>
      <input matInput formControlName="membershipNumber" [required]="true" />
      <mat-error
        *ngIf="healthFundForm.controls.membershipNumber.hasError('pattern')"
      >
        Must contain only numbers
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>Member Number</mat-label>
      <input
        matInput
        formControlName="memberNumber"
        [required]="true"
        maxlength="2"
      />
      <mat-hint align="end">
        Max {{ healthFundForm.value.memberNumber?.length || 0 }}/2
      </mat-hint>
      <mat-error
        *ngIf="healthFundForm.controls.memberNumber.hasError('pattern')"
      >
        Must contain only numbers
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button mat-stroked-button mat-dialog-close type="button">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        (click)="submit()"
        [disabled]="healthFundForm.invalid"
      >
        Save
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
