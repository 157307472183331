import { TransactionOperators } from '../transaction/transaction-operators';
import {
  ITransaction,
  PaymentPlanTransactionType,
} from '@principle-theorem/principle-core/interfaces';

export class PaymentPlanActions {
  static balance(
    transactions: ITransaction[],
    payments: ITransaction[]
  ): number {
    const total: number = new TransactionOperators(transactions)
      .completed()
      .byType(PaymentPlanTransactionType.CreditLine)
      .sum();
    const paidToDate = new TransactionOperators(payments).paidToDate();
    const discounts = new TransactionOperators(transactions).discounts();
    return total - paidToDate - discounts;
  }

  static remaining(transactions: ITransaction[]): number {
    const completed = new TransactionOperators(transactions).completed();
    const credits = completed.byType(PaymentPlanTransactionType.CreditLine);
    const refunds = completed.byType(
      PaymentPlanTransactionType.DisbursementRefund
    );
    const withdrawals = completed.byType(
      PaymentPlanTransactionType.DisbursementWithdrawal
    );
    return credits.sum() - withdrawals.sum() + refunds.sum();
  }
}
