import { type ISource } from '@principle-theorem/principle-core/interfaces';
import { BaseSourceHandler } from '../source/base-source-handler';
import { ADAItemSourceEntity } from './source/entities/ada-item';
import { AppointmentCategorySourceEntity } from './source/entities/appointment-category';
import { ContactsSourceEntity } from './source/entities/contacts';
import { FeeScheduleSourceEntity } from './source/entities/fee-schedule';
import { PatientSourceEntity } from './source/entities/patient';
import { AppointmentSourceEntity } from './source/entities/patient-appointment';
import { PatientBalanceSourceEntity } from './source/entities/patient-balance';
import { PatientDocumentsSourceEntity } from './source/entities/patient-documents';
import { PatientExternalLinksSourceEntity } from './source/entities/patient-external-links';
import { PatientNotesSourceEntity } from './source/entities/patient-notes';
import { PatientPerioExamsSourceEntity } from './source/entities/patient-perio-exams';
import { PatientTransactionsSourceEntity } from './source/entities/patient-transactions';
import { PatientTreatmentSourceEntity } from './source/entities/patient-treatments';
import { StafferSourceEntity } from './source/entities/staff';
import { UsedItemCodeConditionSourceEntity } from './source/entities/used-item-code-conditions';
import { UsedItemCodeTreatmentSourceEntity } from './source/entities/used-item-code-treatments';
import { NoteItemCodeTreatmentSourceEntity } from './source/entities/note-item-code-treatments';
import { AppointmentRoomSourceEntity } from './source/entities/appointment-room';
import { ExactContactTranslator } from './sql-seeding-translators/contact';

export const EXACT_SOURCE: ISource = {
  metadata: {
    name: 'Exact',
    version: 'v1',
  },
  configuration: [
    {
      name: 'Practice Id',
      description: 'Exact Practice Id',
      value: '',
    },
    {
      name: 'Database Username',
      description: 'Database Username',
      value: '',
    },
    {
      name: 'Database Password',
      description: 'Database Password',
      value: '',
    },
    {
      name: 'Database Connection Name',
      description:
        'Fully Qualified Name of Database. Eg. "practice-migrations:australia-southeast1:[instance_name]"',
      value: '',
    },
    {
      name: 'Source Bucket Storage Path',
      description: `Bucket storage path that patient files live under Eg. "gs://practice-name'`,
      value: '',
    },
  ],
};

export class ExactSourceHandler extends BaseSourceHandler {
  migrationType = 'Exact';
  migrationVersion = 'v1';
  source = EXACT_SOURCE;

  entityHandlers = [
    new StafferSourceEntity(),
    new PatientSourceEntity(),
    new ADAItemSourceEntity(),
    new ContactsSourceEntity(),
    new AppointmentSourceEntity(),
    new PatientTreatmentSourceEntity(),
    new FeeScheduleSourceEntity(),
    new PatientNotesSourceEntity(),
    new PatientPerioExamsSourceEntity(),
    new PatientTransactionsSourceEntity(),
    new PatientBalanceSourceEntity(),
    new PatientDocumentsSourceEntity(),
    new PatientExternalLinksSourceEntity(),
    new AppointmentCategorySourceEntity(),
    new UsedItemCodeConditionSourceEntity(),
    new UsedItemCodeTreatmentSourceEntity(),
    new NoteItemCodeTreatmentSourceEntity(),
    new AppointmentRoomSourceEntity(),
  ];

  sqlSeedingTranslators = [new ExactContactTranslator()];
}
