<form [formGroup]="form">
  <div>
    <div fxLayout="row" fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>Practice</mat-label>
        <mat-select
          formControlName="practice"
          [compareWith]="isSelectedNamedDocument"
          [required]="true"
        >
          <mat-option
            *ngFor="
              let practice of practices$ | async;
              trackBy: trackByPractice
            "
            [value]="practice"
          >
            {{ practice.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex>
        <!-- TODO: implement -->
        <!-- <mat-hint align="end" *ngIf="showPractitionerWarning$ | async">
          Treatment Plan by {{ treatmentPlanPractitioner$ | async }}
        </mat-hint> -->
        <mat-label>Practitioner</mat-label>
        <mat-select
          [required]="true"
          formControlName="practitioner"
          [compareWith]="isSelectedNamedDocument"
        >
          <mat-option [value]="anyPractitioner">
            {{ anyPractitioner.name }}
          </mat-option>
          <mat-option
            *ngFor="
              let practitioner of filteredPractitioners$ | ngrxPush;
              trackBy: trackByPractitioner
            "
            [value]="practitioner"
          >
            {{ practitioner.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="treatment-inputs-wrapper" fxLayout="row" fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>Treatment</mat-label>
        <input
          matInput
          [required]="true"
          formControlName="treatment"
          [matAutocomplete]="treatmentAutoComplete"
        />

        <mat-autocomplete
          #treatmentAutoComplete="matAutocomplete"
          [autoActiveFirstOption]="true"
          [displayWith]="displayFn"
        >
          <ng-container
            *ngIf="treatmentTemplateSearchFilter.results$ | async as planPairs"
          >
            <mat-optgroup
              label="New Appointment Options"
              *ngIf="planPairs.length"
            >
              <mat-option
                *ngFor="
                  let planPair of planPairs;
                  trackBy: trackByTemplatePlanPair
                "
                [value]="planPair"
              >
                {{ planPair.plan.name }}
              </mat-option>
            </mat-optgroup>
          </ng-container>
          <ng-container
            *ngIf="treatmentPlanSearchFilter.results$ | async as planPairs"
          >
            <mat-optgroup
              label="Existing Treatment Plan Options"
              *ngIf="planPairs.length"
            >
              <mat-option
                *ngFor="let planPair of planPairs; trackBy: trackByPlanPair"
                [value]="planPair"
              >
                <div
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  fxLayoutGap="8px"
                >
                  <div>{{ planPair.plan.name }} - {{ planPair.step.name }}</div>
                  <div class="mat-caption treatment-duration">
                    {{ planPair.step | map: getStepDuration | async }} minutes
                  </div>
                </div>
              </mat-option>
            </mat-optgroup>
          </ng-container>
        </mat-autocomplete>

        <button
          [disabled]="treatmentDisabled$ | async"
          type="button"
          matIconSuffix
          mat-icon-button
          (click)="clearTreatment()"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-hint
          *ngIf="selectedOverridePlan$ | async as overridePlan; else createNew"
        >
          <ng-container
            *ngIf="
              overridePlan | map: isMostRecentPlan : this | async;
              else elseTemplate
            "
          >
            Adding as step to most recent plan:
          </ng-container>
          <ng-template #elseTemplate> Adding as step to: </ng-template>
          <a
            href="javascript:void(0);"
            class="selected-plan"
            [matMenuTriggerFor]="planMenu"
          >
            <mat-icon>edit</mat-icon> {{ overridePlan.name }}
          </a>
        </mat-hint>
        <ng-template #createNew>
          <div *ngrxLet="allPatientPlans$ as patientPlans">
            <mat-hint *ngIf="patientPlans.length">
              A new Plan will be created for this treatment.
              <a
                href="javascript:void(0);"
                class="selected-plan"
                [matMenuTriggerFor]="planMenu"
              >
                <mat-icon>edit</mat-icon> Select from existing plans
              </a>
            </mat-hint>
          </div>
        </ng-template>
        <mat-menu #planMenu="matMenu">
          <button mat-menu-item (click)="setPlan()">Create New Plan</button>
          <ng-container *ngrxLet="allPatientPlans$ as patientPlans">
            <button
              *ngFor="
                let plan of patientPlans | map: filterPlans : this;
                trackBy: trackByPlan
              "
              mat-menu-item
              (click)="setPlan(plan)"
            >
              {{ plan.name }}
            </button>
          </ng-container>
        </mat-menu>
      </mat-form-field>

      <mat-form-field fxFlex>
        <input
          matInput
          type="number"
          [min]="minimumDuration"
          [placeholder]="durationPlaceholder$ | async"
          [step]="stepSize"
          formControlName="duration"
          [required]="(isTreatmentTemplate$ | async) === false"
        />
        <span matTextSuffix> mins</span>
        <mat-hint *ngIf="durationWarningMessage$ | async as warning">
          <span
            fxLayoutAlign="start center"
            fxLayoutGap="8px"
            class="duration-warning-message"
          >
            <mat-icon>warning</mat-icon> {{ warning }}
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>
</form>
