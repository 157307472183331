import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { VersionedSchema } from '@principle-theorem/editor';
import {
  ISchedulingEventDisplay,
  SchedulingEvent,
} from '@principle-theorem/principle-core';
import { ISchedulingEvent } from '@principle-theorem/principle-core/interfaces';
import {
  HISTORY_DATE_TIME_FORMAT,
  SystemActors,
  WithRef,
} from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-scheduling-event-interaction-header',
  templateUrl: './scheduling-event-interaction-header.component.html',
  styleUrls: ['./scheduling-event-interaction-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchedulingEventInteractionHeaderComponent {
  readonly dateFormat = HISTORY_DATE_TIME_FORMAT;
  schedulingEvent$ = new ReplaySubject<WithRef<ISchedulingEvent>>(1);
  display$: Observable<ISchedulingEventDisplay>;
  ownerMention$: Observable<VersionedSchema | undefined>;
  unknown = SystemActors.Unknown;

  @Input() set schedulingEvent(schedulingEvent: WithRef<ISchedulingEvent>) {
    if (schedulingEvent) {
      this.schedulingEvent$.next(schedulingEvent);
    }
  }

  constructor() {
    this.display$ = this.schedulingEvent$.pipe(
      map((schedulingEvent) =>
        SchedulingEvent.getDisplay(schedulingEvent.eventType)
      )
    );

    this.ownerMention$ = this.schedulingEvent$.pipe(
      switchMap((schedulingEvent) =>
        SchedulingEvent.resolveStafferMention(schedulingEvent)
      )
    );
  }

  getActionLabel(schedulingEvent: WithRef<ISchedulingEvent>): string {
    return SchedulingEvent.getActionlabel(schedulingEvent);
  }
}
