import { TaxStrategy } from '@principle-theorem/accounting';
import {
  Invoice,
  Transaction,
  TransactionOperators,
  stafferToNamedDoc,
  toAccountDetails,
} from '@principle-theorem/principle-core';
import {
  FailedDestinationEntityRecord,
  IServiceCodeLineItem,
  ITreatmentLineItem,
  InvoiceLineItemType,
  InvoiceStatus,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
  type IBasePatient,
  type IBrand,
  type ICustomLineItem,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IInvoice,
  type IPatient,
  type IPatientContactDetails,
  type IPractice,
  type IPracticeMigration,
  type ISourceEntityRecord,
  type IStaffer,
  type ITransaction,
  ITranslationMap,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Timestamp,
  asyncForEach,
  getDoc,
  getError,
  sortByCreatedAt,
  sortTimestampAsc,
  toISODate,
  toNamedDocument,
  type IIdentifiable,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, groupBy, sumBy } from 'lodash';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  getTransactionsForBalance,
  isExactPayment,
} from '../../../exact/destination/entities/lib/exact-invoice-builder';
import { PatientInvoicesDestinationEntity as ExactPatientInvoicesDestinationEntity } from '../../../exact/destination/entities/patient-invoices';
import { resolveExactStaffer } from '../../../exact/destination/mappings/staff';
import {
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
  type IExactPatient,
  type IExactPatientTranslations,
} from '../../../exact/source/entities/patient';
import { PatientBalanceSourceEntity } from '../../../exact/source/entities/patient-balance';
import {
  ExactTransactionType,
  type IExactTransaction,
  type IExactTransactionFilters,
  type IExactTransactionTranslations,
} from '../../../exact/source/entities/patient-transactions';
import { PatientTreatmentSourceEntity } from '../../../exact/source/entities/patient-treatments';
import { resolveMappedCode } from '../../../mappings/item-codes';
import { ItemCodeResourceMapType } from '../../../mappings/item-codes-to-xlsx';
import {
  DentrixTransactionSourceEntity,
  IDentrixTransaction,
} from '../../source/entities/patient-transactions';
import { TranslationMapHandler } from '../../../translation-map';

export const PATIENT_INVOICE_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: 'patientInvoices',
    label: 'Patient Invoices',
    description: `
      Exact has a flat table of transactions (incoming and outgoing) and does not track any payments back to any charges. To deal with this, any charges made on the same day are
      grouped into an invoice and their line items will be built by any found treatments (or the description as it exists in Exact if nothing is linked).

      Afterwards, all payments are ordered by date and we apply payments to the oldest invoices first. Any invoices that do not balance will all be the most recent ones. Any overpayments found
      at the end of this processing will be added to the last invoice in the order and will be added as deposit line items, the result will be patients that show credits in Principle.
    `,
  },
});

interface IInvoiceBuildData {
  invoice: IIdentifiable & IInvoice;
  transactions: (IIdentifiable & ITransaction)[];
}

interface IPatientInvoiceMigrationData {
  patientRef: DocumentReference<IPatient>;
  data: IInvoiceBuildData[];
  createdAt: Timestamp;
}

export interface IPatientInvoiceDestinationRecord {
  sourceRef: DocumentReference<ISourceEntityRecord<IExactPatient>>;
  invoiceData: {
    invoiceRef: DocumentReference<IInvoice>;
    transactionRefs: DocumentReference<ITransaction>[];
  }[];
}

export interface IPatientInvoiceJobData {
  sourcePatient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>;
  brand: WithRef<IBrand>;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  practitioners: WithRef<IStaffer>[];
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[];
}

export class PatientInvoicesDestinationEntity extends ExactPatientInvoicesDestinationEntity {
  override sourceEntities = {
    patients: new PatientSourceEntity(),
    transactions: new DentrixTransactionSourceEntity(),
    treatments: new PatientTreatmentSourceEntity(),
    balances: new PatientBalanceSourceEntity(),
  };

  override async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientInvoiceJobData
  ): Promise<
    | IPatientInvoiceMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const errorResponseData = {
      label: data.sourcePatient.record.label,
      uid: data.sourcePatient.record.uid,
      ref: data.sourcePatient.record.ref,
    };

    const practice = await getDoc(migration.configuration.practices[0].ref);
    const patientId = data.sourcePatient.data.data.patient_id;
    const patientRef = await translationMap.getDestination<IPatient>(
      patientId,
      PATIENT_RESOURCE_TYPE
    );
    if (!patientRef) {
      return this.buildErrorResponse(
        errorResponseData,
        `Couldn't resolve patient`
      );
    }

    const patient = await getDoc(patientRef);
    const patientTransactions =
      await this.sourceEntities.transactions.filterRecords(
        migration,
        'patientId',
        patientId
      );

    if (!patientTransactions.length) {
      // eslint-disable-next-line no-console
      console.error(`No transactions found for patient ${patientId}`);
      return {
        patientRef: patient.ref,
        data: [],
        createdAt: Timestamp.now(),
      };
    }

    const patientBalances = await this.sourceEntities.balances.filterRecords(
      migration,
      'patientId',
      patientId
    );

    const balance = patientBalances.length
      ? sumBy(
          patientBalances,
          (patientBalance) => patientBalance.data.data.balance
        )
      : 0;

    const transactionTotal = sumBy(
      patientTransactions,
      (transaction) => transaction.data.data.amount
    );

    if (balance !== transactionTotal) {
      // eslint-disable-next-line no-console
      console.log(
        `Patient ${patientId} has a balance mismatch, ${balance} !== ${transactionTotal}`
      );
    }

    try {
      const invoiceData = await this._buildInvoiceData(
        patient as IBasePatient & IPatientContactDetails,
        practice,
        patientTransactions,
        translationMap,
        data.staff,
        data.sourceItemCodes
      );

      return {
        patientRef: patient.ref,
        data: invoiceData,
        createdAt: Timestamp.now(),
      };
    } catch (error) {
      return this.buildErrorResponse(errorResponseData, getError(error));
    }
  }

  getInvoiceStatus(amountRemaining: number): InvoiceStatus {
    if (amountRemaining <= 0) {
      return InvoiceStatus.Paid;
    }

    if (amountRemaining > 0) {
      return InvoiceStatus.Issued;
    }

    return InvoiceStatus.Draft;
  }

  private async _buildInvoiceData(
    patient: IBasePatient & IPatientContactDetails,
    practice: WithRef<IPractice>,
    sourceTransactions: IGetRecordResponse<
      IDentrixTransaction,
      IExactTransactionTranslations,
      IExactTransactionFilters
    >[],
    translationMap: TranslationMapHandler,
    staff: WithRef<ITranslationMap<IStaffer, unknown>>[],
    sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[]
  ): Promise<IInvoiceBuildData[]> {
    const charges = sourceTransactions.filter(
      (transaction) => !isExactPayment(transaction.data.data)
    );
    let payments = this._getTransactions(
      sourceTransactions.filter((transaction) =>
        isExactPayment(transaction.data.data)
      ),
      practice.ref
    );

    const chargesGroupedByDate = groupBy(charges, (transaction) =>
      toISODate(transaction.data.translations.date)
    );

    const invoices = await asyncForEach(
      Object.values(chargesGroupedByDate),
      async (chargeGrouping) => {
        const sourceInvoice = chargeGrouping[0];
        const invoice = Invoice.init({
          due: sourceInvoice.data.translations.date,
          practice: toNamedDocument(practice),
          from: toAccountDetails(practice),
          to: toAccountDetails(patient),
          reference: sourceInvoice.data.data.reference ?? '',
          issuedAt: sourceInvoice.data.translations.date,
          createdAt: sourceInvoice.data.translations.date,
        });

        const treatmentItems: ITreatmentLineItem[] = [];
        const feeItems: ICustomLineItem[] = [];
        await asyncForEach(chargeGrouping, async (charge) => {
          const staffer = await resolveExactStaffer(
            charge.data.data.user_code ?? '',
            translationMap,
            staff
          );
          if (!staffer) {
            throw new Error(
              `Failed to resolve staffer: ${charge.data.data.user_code}`
            );
          }

          const treatmentRef = {
            treatmentUuid: charge.data.data.id,
            attributedTo: stafferToNamedDoc(staffer),
          };

          if (charge.data.data.type === ExactTransactionType.Procedure) {
            if (
              !charge.data.data.procedureCode ||
              !charge.data.data.procedureDescription
            ) {
              // eslint-disable-next-line no-console
              console.error('Missing code or description');
              return;
            }

            const serviceLineItem = this._getServiceCodeItem(
              charge,
              sourceItemCodes
            );

            if (!serviceLineItem) {
              // eslint-disable-next-line no-console
              console.error('Missing service line item');
              return;
            }

            // https://app.clickup.com/t/86cvdaqjq
            treatmentItems.push({
              uid: charge.data.data.id,
              type: InvoiceLineItemType.Treatment,
              treatmentRef,
              description: 'Dentrix Treatment',
              items: [serviceLineItem],
              amount: charge.data.data.amount,
              tax: 0,
              taxStatus: TaxStrategy.GSTApplicable,
              quantity: 1,
            });
          } else {
            feeItems.push({
              uid: charge.data.data.id,
              type: InvoiceLineItemType.Fee,
              description: charge.data.data.description,
              amount: charge.data.data.amount,
              tax: 0,
              taxStatus: TaxStrategy.GSTApplicable,
              quantity: 1,
            });
          }
        });
        return {
          ...invoice,
          items: [...treatmentItems, ...feeItems],
          uid: sourceInvoice.data.data.id,
        };
      }
    );

    const results: IInvoiceBuildData[] = [];
    invoices
      .sort((a, b) => sortTimestampAsc(a.createdAt, b.createdAt))
      .map((invoice, index) => {
        const [transactionsForInvoice, remainingTransactions] =
          getTransactionsForBalance(payments, Invoice.balance(invoice, []));
        payments = remainingTransactions;

        const isLastInvoice = index === invoices.length - 1;
        if (isLastInvoice && payments.length > 0) {
          transactionsForInvoice.push(...payments);
          const deposits: ICustomLineItem[] = payments.map((payment) => ({
            uid: payment.uid,
            type: InvoiceLineItemType.Deposit,
            description: payment.description ?? '',
            amount: payment.amount,
            tax: 0,
            taxStatus: TaxStrategy.GSTApplicable,
            quantity: 1,
          }));
          invoice.items.push(...deposits);
        }

        results.push({
          invoice,
          transactions: [...transactionsForInvoice],
        });
      });

    return results.map((invoiceData) => {
      const status = this.getInvoiceStatus(
        Invoice.balance(invoiceData.invoice, invoiceData.transactions)
      );
      const lastTransaction = new TransactionOperators(invoiceData.transactions)
        .completed()
        .sort(sortByCreatedAt)
        .reverse()
        .last();

      if (status === InvoiceStatus.Paid && lastTransaction) {
        invoiceData.invoice.paidAt = lastTransaction.createdAt;
      }
      Invoice.updateStatus(
        invoiceData.invoice,
        status,
        invoiceData.invoice.createdAt
      );

      return invoiceData;
    });
  }

  private _getServiceCodeItem(
    charge: IGetRecordResponse<IDentrixTransaction>,
    sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[]
  ): IServiceCodeLineItem | undefined {
    const code = charge.data.data.procedureCode
      ? resolveMappedCode(
          sourceItemCodes,
          charge.data.data.procedureCode,
          charge.data.data.procedureCode
        )
      : undefined;
    if (!code) {
      return;
    }

    return {
      uid: `serviceItem-${charge.data.data.id}`,
      type: InvoiceLineItemType.ServiceCode,
      code: code.code.toString(),
      description: `${code.code.toString()} -  ${
        charge.data.data.procedureDescription
      }`,
      amount: charge.data.data.amount,
      tax: 0,
      taxStatus: TaxStrategy.GSTApplicable,
      quantity: 1,
    };
  }

  private _getTransactions(
    sourcePayments: IGetRecordResponse<
      IExactTransaction,
      IExactTransactionTranslations,
      IExactTransactionFilters
    >[],
    practiceRef: DocumentReference<IPractice>
  ): (IIdentifiable & ITransaction)[] {
    return sourcePayments.map((sourcePayment) => {
      const payment = sourcePayment.data.data;
      const type =
        payment.amount < 0
          ? TransactionType.Incoming
          : TransactionType.Outgoing;
      const uid = compact([
        payment.id,
        payment.reference ?? payment.patient_id,
      ]).join('-');
      const createdAt = sourcePayment.data.translations.date;
      const provider = determineTransactionProvider(payment);

      const transaction = {
        reference: payment.reference ?? uid,
        type,
        status: TransactionStatus.Complete,
        from: '',
        to: '',
        amount: Math.abs(payment.amount),
        extendedData: payment,
        createdAt,
        practiceRef,
        description: payment.description,
      };

      return {
        ...Transaction.init({
          ...Transaction.internalReference(provider, uid),
          ...transaction,
        }),
      };
    });
  }
}

function determineTransactionProvider(
  transaction: IExactTransaction
): TransactionProvider {
  if (
    transaction.type === ExactTransactionType.Adjustment ||
    transaction.type === ExactTransactionType.Invoice ||
    transaction.type === ExactTransactionType.WriteOff
  ) {
    return TransactionProvider.Discount;
  }

  if (transaction.paymentType?.includes(TransactionProvider.Cash)) {
    return TransactionProvider.Cash;
  }

  return TransactionProvider.Manual;
}
