import { Pipe, type PipeTransform } from '@angular/core';
import { type AbstractControl } from '@angular/forms';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'formControlError$',
})
export class FormControlErrorPipe implements PipeTransform {
  transform(
    control: AbstractControl,
    errorLabel: string = 'error'
  ): Observable<string> {
    return control.statusChanges.pipe(
      map((_status) =>
        control.hasError(errorLabel) ? String(control.getError(errorLabel)) : ''
      )
    );
  }
}
