import { IDynamicComponentRenderer } from '@principle-theorem/ng-shared';

import {
  CLINIVIEW_INTEGRATION,
  EZDENT_INTEGRATION,
  IDIXEL_INTEGRATION,
  ROMEXIS_INTEGRATION,
  SIDEXIS_INTEGRATION,
  TWAIN_INTEGRATION,
  VISTASOFT_INTEGRATION,
  VIXWIN_INTEGRATION,
} from '@principle-theorem/principle-bridge-core';
import { BridgeDeviceFeature } from '@principle-theorem/principle-core';
import { AppIntegrationFeature } from '@principle-theorem/principle-core/interfaces';
import { EzdentTriggerXRayComponent } from './components/integrations/ezdent/ezdent-trigger-x-ray/ezdent-trigger-x-ray.component';
import { RomexisOpenPatientComponent } from './components/integrations/romexis/romexis-open-patient/romexis-open-patient.component';
import { SidexisTriggerXRayComponent } from './components/integrations/sidexis/sidexis-trigger-x-ray/sidexis-trigger-x-ray.component';
import { TwainTriggerScanComponent } from './components/integrations/twain/twain-trigger-scan/twain-trigger-scan.component';
import { IIntegrationActionData } from './integration-action-data';
import { VixWinOpenPatientComponent } from './components/integrations/vixwin/vixwin-open-patient/vixwin-open-patient.component';
import { CliniviewOpenPatientComponent } from './components/integrations/cliniview/cliniview-open-patient/cliniview-open-patient.component';
import { IDixelOpenPatientComponent } from './components/integrations/idixel/idixel-open-patient/idixel-open-patient.component';
import { VistaSoftOpenPatientComponent } from './components/integrations/vistasoft/vistasoft-open-patient/vistasoft-open-patient.component';

export const INTEGRATION_ACTION_RENDERERS: IDynamicComponentRenderer<
  unknown,
  IIntegrationActionData
>[] = [
  {
    component: SidexisTriggerXRayComponent,
    isFor: (data: IIntegrationActionData) =>
      data.appIntegrationFeature === AppIntegrationFeature.MediaCapture &&
      data.availableFeatures.some(
        (feature) =>
          BridgeDeviceFeature.isIntegration(feature, SIDEXIS_INTEGRATION) &&
          BridgeDeviceFeature.isEnabled(feature)
      ),
  },
  {
    component: EzdentTriggerXRayComponent,
    isFor: (data: IIntegrationActionData) =>
      data.appIntegrationFeature === AppIntegrationFeature.MediaCapture &&
      data.availableFeatures.some(
        (feature) =>
          BridgeDeviceFeature.isIntegration(feature, EZDENT_INTEGRATION) &&
          BridgeDeviceFeature.isEnabled(feature)
      ),
  },
  {
    component: TwainTriggerScanComponent,
    isFor: (data: IIntegrationActionData) =>
      data.appIntegrationFeature === AppIntegrationFeature.MediaCapture &&
      data.availableFeatures.some(
        (feature) =>
          BridgeDeviceFeature.isIntegration(feature, TWAIN_INTEGRATION) &&
          BridgeDeviceFeature.isEnabled(feature)
      ),
  },
  {
    component: RomexisOpenPatientComponent,
    isFor: (data: IIntegrationActionData) =>
      data.appIntegrationFeature === AppIntegrationFeature.MediaCapture &&
      data.availableFeatures.some(
        (feature) =>
          BridgeDeviceFeature.isIntegration(feature, ROMEXIS_INTEGRATION) &&
          BridgeDeviceFeature.isEnabled(feature)
      ),
  },
  {
    component: VixWinOpenPatientComponent,
    isFor: (data: IIntegrationActionData) =>
      data.appIntegrationFeature === AppIntegrationFeature.MediaCapture &&
      data.availableFeatures.some(
        (feature) =>
          BridgeDeviceFeature.isIntegration(feature, VIXWIN_INTEGRATION) &&
          BridgeDeviceFeature.isEnabled(feature)
      ),
  },
  {
    component: CliniviewOpenPatientComponent,
    isFor: (data: IIntegrationActionData) =>
      data.appIntegrationFeature === AppIntegrationFeature.MediaCapture &&
      data.availableFeatures.some(
        (feature) =>
          BridgeDeviceFeature.isIntegration(feature, CLINIVIEW_INTEGRATION) &&
          BridgeDeviceFeature.isEnabled(feature)
      ),
  },
  {
    component: IDixelOpenPatientComponent,
    isFor: (data: IIntegrationActionData) =>
      data.appIntegrationFeature === AppIntegrationFeature.MediaCapture &&
      data.availableFeatures.some(
        (feature) =>
          BridgeDeviceFeature.isIntegration(feature, IDIXEL_INTEGRATION) &&
          BridgeDeviceFeature.isEnabled(feature)
      ),
  },
  {
    component: VistaSoftOpenPatientComponent,
    isFor: (data: IIntegrationActionData) =>
      data.appIntegrationFeature === AppIntegrationFeature.MediaCapture &&
      data.availableFeatures.some(
        (feature) =>
          BridgeDeviceFeature.isIntegration(feature, VISTASOFT_INTEGRATION) &&
          BridgeDeviceFeature.isEnabled(feature)
      ),
  },
];
