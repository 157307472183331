import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { IInvoice } from '@principle-theorem/principle-core/interfaces';
import { WithRef, multiFilter } from '@principle-theorem/shared';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  of,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  IResolvedTransactionOption,
  ITransactionProvider,
  TransactionProviderType,
} from '../../../transaction-providers/transaction-provider';
import { TransactionProviders } from '../../../transaction-providers/transaction-providers.service';

interface IGroup {
  label: string;
  options: IResolvedTransactionOption[];
}

@Component({
  selector: 'pr-healthcare-claim-selector',
  templateUrl: './healthcare-claim-selector.component.html',
  styleUrls: ['./healthcare-claim-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthcareClaimSelectorComponent {
  private _invoice$ = new ReplaySubject<WithRef<IInvoice>>(1);
  private _disabled$ = new BehaviorSubject<boolean>(false);
  trackByGroup = TrackByFunctions.field<IGroup>('label');
  trackByOption = TrackByFunctions.nestedField<IResolvedTransactionOption>(
    'provider.providerId'
  );
  groups$: Observable<IGroup[]>;
  adding$ = of(false);
  disabled$: Observable<boolean>;
  @Output() providerSelected = new EventEmitter<ITransactionProvider>();

  @Input()
  set disabled(disabled: BooleanInput) {
    this._disabled$.next(coerceBooleanProperty(disabled));
  }

  constructor(private _transactions: TransactionProviders) {
    this.groups$ = this._getGroups$();
    const hasNoGroups$ = this.groups$.pipe(
      map((groups) => groups.length === 0)
    );
    this.disabled$ = combineLatest([this._disabled$, hasNoGroups$]).pipe(
      map(([disabled, hasNoGroups]) => disabled || hasNoGroups)
    );
  }

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this._invoice$.next(invoice);
    }
  }

  canCapture$(option?: IResolvedTransactionOption): Observable<boolean> {
    if (!option) {
      return of(false);
    }
    return this._invoice$.pipe(
      switchMap((invoice) => option.provider.canCapture$(invoice))
    );
  }

  private _getGroups$(): Observable<IGroup[]> {
    const healthFunds$ = this._transactions.optionsByProviderType$(
      TransactionProviderType.HealthFund
    );
    const medicare$ = this._transactions.optionsByProviderType$(
      TransactionProviderType.Medicare
    );
    const dva$ = this._transactions.optionsByProviderType$(
      TransactionProviderType.DVA
    );

    return combineLatest([healthFunds$, medicare$, dva$]).pipe(
      map(([healthFundProviders, medicareProviders, dvaProviders]) => [
        {
          label: 'Health Fund',
          options: healthFundProviders,
        },
        {
          label: 'Medicare',
          options: medicareProviders,
        },
        {
          label: 'DVA',
          options: dvaProviders,
        },
      ]),
      multiFilter((group) => group.options.length > 0)
    );
  }
}
