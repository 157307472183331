import { Component, Inject, NgZone, type OnDestroy } from '@angular/core';
import { keepUnstableUntilFirst, ɵAngularFireSchedulers } from '@angular/fire';
import { MatIconRegistry } from '@angular/material/icon';
import {
  DomSanitizer,
  Title,
  type SafeResourceUrl,
} from '@angular/platform-browser';
import { AuthService } from '@principle-theorem/ng-auth';
import {
  ChatNotificationsService,
  ChatsDashboardStore,
} from '@principle-theorem/ng-chats';
import {
  IntercomCustomData,
  IntercomService,
  type IIntercomUser,
} from '@principle-theorem/ng-intercom';
import { HotkeysService } from '@principle-theorem/ng-principle';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  APP_VERSION,
  AppVersionService,
  DatabaseUsageTrackerService,
  DynamicTitleService,
} from '@principle-theorem/ng-shared';
import {
  SourceEntityRecordDataSerialiser,
  SourceEntityRecordDataUnserialiser,
} from '@principle-theorem/practice-migrations';
import {
  OrganisationCache,
  PRINCIPLE_CORE_SERIALISERS,
} from '@principle-theorem/principle-core';
import {
  ConfigCollection,
  IBrand,
  IPractice,
  RootCollection,
  type IOrganisation,
  type IPublicConfig,
  type IUser,
} from '@principle-theorem/principle-core/interfaces';
import {
  FUNCTIONS_REGION,
  FirebaseFunctionsScheduler,
  FirestoreScheduler,
  SerialisationProvider,
  asColRef,
  filterUndefined,
  getDoc$,
  type WithRef,
} from '@principle-theorem/shared';
import { type IntercomBootInput } from '@supy-io/ngx-intercom';
import { getApp } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { isEqual } from 'lodash';
import * as LogRocket from 'logrocket';
import { Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';

/**
 * This should be mirrored in:
 * - apps/principle/src/app/app.component.ts
 * - apps/principle-functions/src/app/initialiseApp.ts
 * - apps/tinker/src/app/initialise-app.ts
 * - apps/principle-migrations/src/app/initialise-app.ts
 */
export const PRINCIPLE_SERIALISERS = [
  new SourceEntityRecordDataSerialiser(),
  new SourceEntityRecordDataUnserialiser(),
  ...PRINCIPLE_CORE_SERIALISERS,
];

const INTERCOM_BOOT_SETTINGS: IntercomBootInput = {
  custom_launcher_selector: '#intercom-launcher',
  hide_default_launcher: true,
};

interface IPrincipleIntercomCustomData extends IntercomCustomData {
  workspace: string;
  workspace_slug: string;
  brand_uid?: string;
  brand_slug?: string;
  practice_uid?: string;
  practice_slug?: string;
}

@Component({
    selector: 'pr-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();

  constructor(
    private _iconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    dynamicTitle: DynamicTitleService,
    title: Title,
    org: OrganisationService,
    intercom: IntercomService,
    version: AppVersionService,
    private _hotkeysService: HotkeysService,
    private _authService: AuthService,
    zone: NgZone,
    chats: ChatsDashboardStore,
    chatNotifications: ChatNotificationsService,
    usageTracker: DatabaseUsageTrackerService,
    @Inject(APP_VERSION) appVersion: string
  ) {
    // eslint-disable-next-line no-console
    console.log(`Running version: ${appVersion}`);
    if (environment.production) {
      LogRocket.init(environment.logRocketKey, {
        console: {
          shouldAggregateConsoleErrors: true,
        },
      });
    }
    this._registerIcons([
      { label: 'principle-watermark', file: 'principle-watermark-logo.svg' },
      { label: 'principle-logo', file: 'principle-logo.svg' },
      { label: 'principle-row-white', file: 'principle-row-white.svg' },
      { label: 'principle-stacked-white', file: 'principle-stacked-white.svg' },
    ]);
    this._registerSvgIconSets([`symbol-defs.svg`]);
    this._iconRegistry.registerFontClassAlias(
      'material-symbols',
      'material-symbols-outlined'
    );

    dynamicTitle.default = 'Principle';
    dynamicTitle.title$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value: string) => title.setTitle(value));

    if (environment.useEmulator) {
      connectFunctionsEmulator(getFunctions(), 'localhost', 5001);
      initializeFirestore(getApp(), {
        experimentalForceLongPolling: true,
        host: 'localhost:8080',
        ssl: false,
      });
    } else {
      intercom.boot(INTERCOM_BOOT_SETTINGS);

      combineLatest([org.user$, org.organisation$, org.brand$, org.practice$])
        .pipe(
          map(([user, organisation, brand, practice]) =>
            this._toIntercomUser(user, organisation, brand, practice)
          ),
          distinctUntilChanged(isEqual),
          takeUntil(this._onDestroy$)
        )
        .subscribe((user?: IIntercomUser) =>
          intercom.updateUser(user, INTERCOM_BOOT_SETTINGS)
        );

      combineLatest([
        org.user$.pipe(filterUndefined()),
        org.organisation$.pipe(filterUndefined()),
      ])
        .pipe(takeUntil(this._onDestroy$))
        .subscribe(([user, organisation]) => {
          LogRocket.identify(user.ref.path, {
            name: user.name,
            email: user.email,
            organisation: organisation.name,
          });
        });

      chats.loadChats(org.practice$.pipe(filterUndefined()));
      chatNotifications.init();
    }

    const scheduler = new ɵAngularFireSchedulers(zone);

    FirestoreScheduler.init(
      scheduler.outsideAngular,
      keepUnstableUntilFirst,
      getApp().name,
      environment.firebase
    );

    FirebaseFunctionsScheduler.init(
      scheduler.outsideAngular,
      FUNCTIONS_REGION,
      environment.firebase,
      environment.useEmulator ? ['localhost', 5001] : false
    );

    SerialisationProvider.init(PRINCIPLE_SERIALISERS);
    OrganisationCache.setCacheEnabled(true);

    this._hotkeysService.registerHotkeys();

    if (environment.production) {
      const public$ = getDoc$(
        asColRef<IPublicConfig>(RootCollection.Configuration),
        ConfigCollection.Public
      ).pipe(map((config) => config.version));

      const logoutFn = (): Promise<void> => this._authService.logout();
      version.watchForUpdates(public$, logoutFn);
    }

    usageTracker.track();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _toIntercomUser(
    user?: WithRef<IUser>,
    organisation?: WithRef<IOrganisation>,
    brand?: WithRef<IBrand>,
    practice?: WithRef<IPractice>
  ): IIntercomUser | undefined {
    if (!user || !organisation) {
      return undefined;
    }

    const customData: IPrincipleIntercomCustomData = {
      workspace: organisation.slug,
      workspace_uid: organisation.ref.id,
      workspace_slug: organisation.slug,
    };
    if (brand) {
      customData.brand_uid = brand?.ref.id;
      customData.brand_slug = brand?.slug;
    }
    if (practice) {
      customData.practice_uid = practice?.ref.id;
      customData.practice_slug = practice?.slug;
    }

    return {
      name: user.name,
      email: user.email,
      company: {
        company_id: organisation.ref.id,
        name: organisation.name,
      },
      createdAt: user.createdAt.toDate(),
      customData,
    };
  }

  private _registerIcons(icons: IIconAsset[]): void {
    icons.map((icon: IIconAsset) => {
      const path: SafeResourceUrl =
        this._domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/icons/${icon.file}`
        );
      this._iconRegistry.addSvgIconInNamespace('assets', icon.label, path);
    });
  }

  private _registerSvgIconSets(iconSetFiles: string[]): void {
    iconSetFiles.map((file: string) => {
      const path: SafeResourceUrl =
        this._domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/icons/${file}`
        );
      this._iconRegistry.addSvgIconSet(path);
    });
  }
}

interface IIconAsset {
  label: string;
  file: string;
}
