<form fxLayout="column" [formGroup]="noteForm">
  <mat-form-field fxFlex>
    <mat-label>{{ placeholder }}</mat-label>
    <pr-content-editor
      [placeholder]="placeholder"
      [extensions]="extensions"
      [menuEnabled]="false"
      formControlName="content"
    />
  </mat-form-field>
  <pr-tags-input
    [tagCol]="tagCol$ | async"
    formControlName="tags"
    [tagType]="tagType"
  />
</form>
