import { Injectable, type OnDestroy } from '@angular/core';
import { type Event, NavigationEnd, Router } from '@angular/router';
import { filter, skip } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationFocusService implements OnDestroy {
  private subscriptions = new Subscription();
  private navigationFocusRequests: HTMLElement[] = [];
  private skipLinkFocusRequests: HTMLElement[] = [];
  private skipLinkHref: string | null | undefined;

  readonly navigationEndEvents: Observable<NavigationEnd>;
  readonly softNavigations: Observable<NavigationEnd>;

  constructor(private router: Router) {
    this.navigationEndEvents = this.router.events.pipe(
      filter(
        (event: Event): event is NavigationEnd => event instanceof NavigationEnd
      )
    );

    this.softNavigations = this.navigationEndEvents.pipe(skip(1));

    this.subscriptions.add(
      this.softNavigations.subscribe(() => {
        // focus if url does not have fragment
        if (!this.router.url.split('#')[1]) {
          setTimeout(() => {
            if (this.navigationFocusRequests.length) {
              this.navigationFocusRequests[
                this.navigationFocusRequests.length - 1
              ].focus({ preventScroll: true });
            }
          }, 100);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  requestFocusOnNavigation(el: HTMLElement): void {
    this.navigationFocusRequests.push(el);
  }

  relinquishFocusOnNavigation(el: HTMLElement): void {
    this.navigationFocusRequests.splice(
      this.navigationFocusRequests.indexOf(el),
      1
    );
  }

  requestSkipLinkFocus(el: HTMLElement): void {
    this.skipLinkFocusRequests.push(el);
    this.setSkipLinkHref(el);
  }

  relinquishSkipLinkFocus(el: HTMLElement): void {
    this.skipLinkFocusRequests.splice(
      this.skipLinkFocusRequests.indexOf(el),
      1
    );
    const skipLinkFocusTarget =
      this.skipLinkFocusRequests[this.skipLinkFocusRequests.length - 1];
    this.setSkipLinkHref(skipLinkFocusTarget);
  }

  setSkipLinkHref(element?: HTMLElement): void {
    const baseUrl = this.router.url.split('#')[0];
    this.skipLinkHref = element ? `${baseUrl}#${element.id}` : undefined;
  }

  getSkipLinkHref(): string | null | undefined {
    return this.skipLinkHref;
  }

  isNavigationWithinComponentView(
    previousUrl: string,
    newUrl: string
  ): boolean {
    // eslint-disable-next-line no-useless-escape
    const componentViewExpression = /(components|cdk)\/([^\/]+)/;
    const previousUrlMatch = componentViewExpression.exec(previousUrl);
    const newUrlMatch = componentViewExpression.exec(newUrl);

    return previousUrl &&
      newUrl &&
      previousUrlMatch &&
      newUrlMatch &&
      previousUrlMatch[0] === newUrlMatch[0] &&
      previousUrlMatch[1] === newUrlMatch[1]
      ? true
      : false;
  }
}
