import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IHistoryItem } from '@principle-theorem/ng-principle-shared';
import { type IInvoice } from '@principle-theorem/principle-core/interfaces';
import {
  type ArchivedDocument,
  DocumentArchive,
  isSameRef,
  multiMap,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

type AmendedInvoice = WithRef<ArchivedDocument<IInvoice>>;

@Component({
  selector: 'pr-invoice-amendment-history',
  templateUrl: './invoice-amendment-history.component.html',
  styleUrls: ['./invoice-amendment-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceAmendmentHistoryComponent {
  amendmentHistory$ = new ReplaySubject<AmendedInvoice[]>(1);
  selectedInvoice$ = new ReplaySubject<AmendedInvoice>(1);
  historyItems$: Observable<IHistoryItem<AmendedInvoice>[]>;
  selectedHistoryItem$: Observable<IHistoryItem<AmendedInvoice> | undefined>;

  @Input()
  set amendmentHistory(amendmentHistory: WithRef<AmendedInvoice>[]) {
    if (amendmentHistory) {
      this.amendmentHistory$.next(amendmentHistory);
    }
  }

  @Input()
  set selectedInvoice(selectedInvoice: WithRef<AmendedInvoice>) {
    if (selectedInvoice) {
      this.selectedInvoice$.next(selectedInvoice);
    }
  }

  constructor() {
    this.historyItems$ = this.amendmentHistory$.pipe(
      multiMap((invoice) => this._createInvoiceHistoryItem(invoice))
    );
    this.selectedHistoryItem$ = combineLatest([
      this.historyItems$,
      this.selectedInvoice$,
    ]).pipe(
      map(([historyItems, selectedInvoice]) =>
        this._findInvoiceHistoryItem(selectedInvoice, historyItems)
      )
    );
  }

  private _findInvoiceHistoryItem(
    invoice: AmendedInvoice,
    historyItems: IHistoryItem<AmendedInvoice>[]
  ): IHistoryItem<AmendedInvoice> | undefined {
    return historyItems.find((historyItem) =>
      isSameRef(historyItem.value.ref, invoice)
    );
  }

  private _createInvoiceHistoryItem(
    invoice: AmendedInvoice
  ): IHistoryItem<AmendedInvoice> {
    return {
      label: DocumentArchive.getLabel(invoice),
      timestamp: invoice.archivedAt,
      value: invoice,
    };
  }
}
