import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import {
  filterUndefined,
  getTimePeriodStartEnd,
  toTimestamp,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { type DateRangeDataBuilder } from '../../../../models/report/data-builders/date-range-data-builder';
import { ReferralSourcesReportStore } from './referral-sources-report.store';

@Component({
    selector: 'pr-referral-sources-report',
    templateUrl: './referral-sources-report.component.html',
    styleUrls: ['./referral-sources-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ReferralSourcesReportComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _dataBuilder$ = new ReplaySubject<DateRangeDataBuilder>(1);

  @Input()
  set dataBuilder(dataBuilder: DateRangeDataBuilder) {
    if (dataBuilder) {
      this._dataBuilder$.next(dataBuilder);
    }
  }

  constructor(public store: ReferralSourcesReportStore) {
    const query$ = this._dataBuilder$.pipe(
      switchMap((builder) => {
        const practice$ = builder.practices$.pipe(
          map((practices) => first(practices))
        );
        return combineLatest([
          practice$.pipe(filterUndefined()),
          builder.dateChange,
        ]);
      }),
      map(([practice, range]) => {
        const timePeriod = getTimePeriodStartEnd(
          range,
          practice.settings.timezone
        );

        return {
          startDate: toTimestamp(timePeriod.from),
          endDate: toTimestamp(timePeriod.to),
          practiceRef: practice.ref,
        };
      })
    );

    this.store.loadTransactions(query$);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
