import { type TaxStrategy } from '@principle-theorem/accounting';
import { Region, type ISoftDelete } from '@principle-theorem/shared';
import { type ChartableSurface } from '../core/chartable-surface';
import { type IADACodeRules } from './providers/ada-code';

export enum ServiceCodeType {
  ADA = 'ADA',
  DVA = 'DVA',
  DVAProsthetic = 'DVAProsthetic',
  CDBS = 'CDBS',
  ACC = 'ACC',
  MOH = 'MOH',
}

export const MANAGED_SERVICE_CODE_TYPES = [
  ServiceCodeType.DVA,
  ServiceCodeType.DVAProsthetic,
  ServiceCodeType.CDBS,
  ServiceCodeType.ACC,
  ServiceCodeType.MOH,
];

export type ServiceCode = number | string;

export interface IServiceCode extends ISoftDelete {
  readonly code: ServiceCode;
  readonly type: ServiceCodeType;
  readonly title: string;
  readonly description: string;
  readonly rules: IADACodeRules;
  readonly scope: ChartableSurface[];
  readonly taxStatus: TaxStrategy;
  readonly claimCode?: ServiceCode;
  readonly rootCode?: ServiceCode;
  readonly fee?: number;
  readonly notes?: string;
}

export interface IServiceProvider {
  label: string;
  description: string;
  type: ServiceCodeType;
  parentType?: ServiceCodeType;
  items: ItemCodeAliases;
  region: Region;
}

export type ItemCodeAliases = Record<ServiceCode, IServiceCode>;

export interface IScopedServiceCode {
  code: ServiceCode;
  type: ServiceCodeType;
}

export function toScopedServiceCode(code: IServiceCode): IScopedServiceCode {
  return {
    code: code.code,
    type: code.type,
  };
}
