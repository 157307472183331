import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  Timestamp,
  TypeGuard,
  toTimestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { PATIENT_APPOINTMENT_RESOURCE_TYPE } from '../../../destination/entities/patient-appointments';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_APPOINTMENT_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Appointment List',
      description: '',
      idPrefix: PATIENT_APPOINTMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ICorePracticePatientAppointment {
  id: number;
  description?: string;
  note?: string;
  startTime: string; // 2021-11-25 22:00:00.000
  endTime: string; // 2021-11-25 22:30:00.000
  eventClassId?: number; // Map event class to treatment category
  cancelDate?: string; // 2021-11-15 23:13:06.820
  isDeleted: boolean;
  providerId?: number;
  patientId: number;
  locationId: number;
  calendarId: number;
}

export function isCorePracticePatientAppointment(
  item: unknown
): item is ICorePracticePatientAppointment {
  return TypeGuard.interface<ICorePracticePatientAppointment>({
    id: isNumber,
    description: TypeGuard.nilOr(isString),
    note: TypeGuard.nilOr(isString),
    startTime: isString,
    endTime: isString,
    eventClassId: TypeGuard.nilOr(isNumber),
    cancelDate: TypeGuard.nilOr(isString),
    isDeleted: isBoolean,
    providerId: TypeGuard.nilOr(isNumber),
    patientId: isNumber,
    locationId: isNumber,
    calendarId: isNumber,
  })(item);
}

export interface ICorePracticePatientAppointmentTranslations {
  from: Timestamp;
  to: Timestamp;
}

export interface ICorePracticePatientAppointmentFilters {
  providerId?: number;
  patientId: number;
  locationId: number;
  calendarId: number;
  from: Timestamp;
}

const PATIENT_APPOINTMENT_SOURCE_QUERY = `
SELECT
  convert_to_integer(AppointmentId) AS id,
  Description AS description,
  Note AS note,
  StartTime AS start_time,
  EndTime AS end_time,
  EventClassId AS event_class_id,
  CancelDate AS cancel_date,
  convert_to_boolean(IsDeleted) AS is_deleted,
  ProviderId AS provider_id,
  PatientId AS patient_id,
  LocationId AS location_id,
  CalendarId AS calendar_id
FROM tblAppointment
WHERE
  PatientId IS NOT NULL
  AND convert_to_boolean(IsDeleted) = false
  AND convert_to_integer(AppointmentId) != 0
`;

export class PatientAppointmentSourceEntity extends BaseSourceEntity<
  ICorePracticePatientAppointment,
  ICorePracticePatientAppointmentTranslations,
  ICorePracticePatientAppointmentFilters
> {
  sourceEntity = PATIENT_APPOINTMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_APPOINTMENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_APPOINTMENT_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientAppointment;

  translate(
    data: ICorePracticePatientAppointment,
    _timezone: Timezone
  ): ICorePracticePatientAppointmentTranslations {
    return {
      from: toTimestamp(moment.utc(data.startTime, ISO_DATE_TIME_FORMAT)),
      to: toTimestamp(moment.utc(data.endTime, ISO_DATE_TIME_FORMAT)),
    };
  }

  getSourceRecordId(data: ICorePracticePatientAppointment): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientAppointment): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientAppointment,
    _timezone: Timezone
  ): ICorePracticePatientAppointmentFilters {
    return {
      providerId: data.providerId,
      patientId: data.patientId,
      locationId: data.locationId,
      calendarId: data.calendarId,
      from: toTimestamp(moment.utc(data.startTime, ISO_DATE_TIME_FORMAT)),
    };
  }
}
