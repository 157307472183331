import { InjectionToken } from '@angular/core';
import { type INotificationContextRenderer } from '@principle-theorem/notifications';
import { type RawSchema } from '@principle-theorem/editor';

export interface INotificationProvider {
  preview: boolean;
}

export interface INotificationConfig {
  providers: INotificationContextRenderer<RawSchema>[];
}

export const NG_NOTIFICATION_CONFIG: InjectionToken<INotificationConfig> =
  new InjectionToken('INotificationConfig');
