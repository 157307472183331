import {
  IIntegration,
  IntegrationStorage,
  IntegrationType,
} from '@principle-theorem/integrations';
import {
  IOrganisation,
  IPractice,
  SenderType,
} from '@principle-theorem/principle-core/interfaces';
import {
  CollectionReference,
  DocumentReference,
} from '@principle-theorem/shared';
import {
  firebaseFunctionUrl,
  FUNCTIONS_REGION,
  isSameRef,
} from '@principle-theorem/shared';
import { resolveOrganisationFromSender } from '../interaction/send-sms-request';
import { Organisation } from '../organisation/organisation';

export function isPodiumIntegration(
  item: IIntegration
): item is IIntegration<IPodiumIntegrationData> {
  return item.type === IntegrationType.Podium;
}

export interface IPodiumLocationMap {
  locationUid: string;
  practiceRef: DocumentReference<IPractice>;
}

export interface IPodiumIntegrationData {
  oauth?: {
    scope: string[];
    refreshToken: string;
    accessToken: string;
  };
  webhook?: {
    uid: string;
    secret: string;
  };
  practiceLocations?: IPodiumLocationMap[];
}

export class PodiumIntegrationStorage extends IntegrationStorage<IPodiumIntegrationData> {
  constructor() {
    super(IntegrationType.Podium);
  }

  static init(data: Partial<IPodiumIntegrationData>): IPodiumIntegrationData {
    return {
      ...data,
    };
  }

  static isEnabled(data?: IPodiumIntegrationData): boolean {
    return data?.oauth?.accessToken &&
      data.oauth.refreshToken &&
      data.practiceLocations?.length
      ? true
      : false;
  }

  override async upsert(
    integrationsCollection: CollectionReference<
      IIntegration<IPodiumIntegrationData>
    >,
    data: Partial<IPodiumIntegrationData>
  ): Promise<void> {
    const current = await this.get(integrationsCollection);
    return super.upsert(
      integrationsCollection,
      PodiumIntegrationStorage.init({ ...current?.data, ...data })
    );
  }
}

export interface IPodiumLinkOptions {
  scopes: string[];
  oauthApiUrl: string;
  clientId: string;
  temporaryTokenUid: string;
}

export const PODIUM_SCOPES = [
  'read_locations',
  'read_messages',
  'write_messages',
  'read_templates',
];

export function createPodiumLink(options: IPodiumLinkOptions): string {
  const url: URL = new URL('https://api.podium.com/oauth/authorize');
  url.searchParams.set('redirect_uri', options.oauthApiUrl);
  url.searchParams.set('client_id', options.clientId);
  url.searchParams.set('state', options.temporaryTokenUid);
  url.searchParams.set('scope', options.scopes.join(' '));
  return url.href;
}

export async function resolvePodiumPracticeLocation(
  practiceRef: DocumentReference<IPractice>
): Promise<string | undefined> {
  const settings = await resolvePodiumIntegrationSettings(practiceRef);
  return settings?.practiceLocations?.find((practiceLocation) =>
    isSameRef(practiceLocation.practiceRef, practiceRef)
  )?.locationUid;
}

export async function resolveIntegrationPracticeFromLocationUid(
  organisationRef: DocumentReference<IOrganisation>,
  locationUid: string
): Promise<DocumentReference<IPractice> | undefined> {
  const data = await resolvePodiumOrgIntegrationSettings(organisationRef);
  if (!data) {
    return;
  }

  return data.practiceLocations?.find(
    (practiceLocation) =>
      practiceLocation.locationUid.replace(/ /g, '') ===
      locationUid.replace(/ /g, '')
  )?.practiceRef;
}

export async function resolvePodiumIntegrationSettings(
  practiceRef: DocumentReference<IPractice>
): Promise<IPodiumIntegrationData | undefined> {
  const org = await resolveOrganisationFromSender({
    type: SenderType.Practice,
    ref: practiceRef,
  });
  const integrationsCol =
    Organisation.integrationCol<IPodiumIntegrationData>(org);
  const storage = new PodiumIntegrationStorage();
  const data = await storage.get(integrationsCol);
  return data?.data;
}

export async function resolvePodiumOrgIntegrationSettings(
  organisationRef: DocumentReference<IOrganisation>
): Promise<IPodiumIntegrationData | undefined> {
  const integrationsCol = Organisation.integrationCol<IPodiumIntegrationData>({
    ref: organisationRef,
  });
  const storage = new PodiumIntegrationStorage();
  const data = await storage.get(integrationsCol);
  return data?.data;
}

export function generatePodiumOAuthUrl(projectId: string): string {
  return firebaseFunctionUrl(FUNCTIONS_REGION, projectId, `http-podium-oauth`);
}
