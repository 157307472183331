import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import type { VersionedSchema } from '@principle-theorem/editor';
import {
  Content,
  DEFAULT_EXTENSIONS,
  getMixedContent,
  isMixedSchema,
} from '@principle-theorem/editor';
import { AnyExtension, Editor } from '@tiptap/core';
import { uniqBy } from 'lodash';
import {
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  fromEvent,
} from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { angularEditorExtensions } from '../extensions/extensions';
import { isChanged$ } from '@principle-theorem/shared';

@Component({
  selector: 'pt-editor-view, [ptEditorView]',
  templateUrl: './editor-view.component.html',
  styleUrls: ['./editor-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorViewComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _content$ = new ReplaySubject<Content | VersionedSchema>(1);
  private _editor?: Editor;
  private _extensions$ = new ReplaySubject<AnyExtension[]>(1);
  private _readOnlyExtensions: AnyExtension[];
  editor$: Observable<Editor>;
  @Output() contentError: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  set content(content: Content | VersionedSchema) {
    if (!content) {
      return;
    }
    this._content$.next(content);
  }

  @Input()
  set extensions(extensions: AnyExtension[]) {
    if (extensions) {
      this._extensions$.next(extensions);
    }
  }

  constructor(private _injector: Injector) {
    this._readOnlyExtensions = uniqBy(
      [
        ...DEFAULT_EXTENSIONS,
        angularEditorExtensions.image(this._injector),
        angularEditorExtensions.video(this._injector),
        angularEditorExtensions.videoEmbed(this._injector),
      ],
      (extension) => extension.name
    );

    this.editor$ = combineLatest([
      this._extensions$,
      this._content$.pipe(
        isChanged$(),
        map((content) => {
          if (this._editor) {
            this._editor.commands.clearContent();
            this._editor.commands.setContent(
              isMixedSchema(content) ? getMixedContent(content) : content
            );
          }
          return content;
        })
      ),
    ]).pipe(
      map(([extensions, content]) => {
        if (this._editor) {
          return this._editor;
        }

        this._editor = new Editor({
          extensions: uniqBy(
            [...this._readOnlyExtensions, ...extensions],
            (extension) => extension.name
          ),
          editable: false,
          content: isMixedSchema(content) ? getMixedContent(content) : content,
        });
        return this._editor;
      }),
      tap((editor) => (this._editor = editor)),
      takeUntil(this._onDestroy$)
    );

    this.editor$
      .pipe(
        switchMap((editor) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fromEvent<Editor>(editor, 'change' as keyof EditorEvents)
        ),
        filter((editor) => !editor.state),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.contentError.emit());
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._editor?.destroy();
  }
}
