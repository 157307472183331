import {
  IEventable,
  IParticipant,
  IStaffer,
  ParticipantType,
} from '@principle-theorem/principle-core/interfaces';
import {
  INamedDocument,
  isINamedDocument,
  multiMap,
  toMoment,
  toNamedDocument,
  WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { OperatorFunction } from 'rxjs';

export function stafferToNamedDoc(
  staffer: WithRef<IStaffer> | INamedDocument<IStaffer>
): INamedDocument<IStaffer> {
  if (isINamedDocument(staffer)) {
    return toNamedDocument(staffer);
  }
  return {
    name: staffer.user.name,
    ref: staffer.ref,
  };
}

export function staffToNamedDocs(): OperatorFunction<
  (WithRef<IStaffer> | INamedDocument<IStaffer>)[],
  INamedDocument<IStaffer>[]
> {
  return (source) =>
    source.pipe(multiMap((staffer) => stafferToNamedDoc(staffer)));
}

export function stafferToParticipant(
  staffer: WithRef<IStaffer> | INamedDocument<IStaffer>
): IParticipant<IStaffer> {
  return {
    ...stafferToNamedDoc(staffer),
    type: ParticipantType.Staffer,
  };
}

export function staffToParticipants(): OperatorFunction<
  (WithRef<IStaffer> | INamedDocument<IStaffer>)[],
  IParticipant<IStaffer>[]
> {
  return (source) => source.pipe(multiMap(stafferToParticipant));
}

/**
 * Get the string representation of a number with ordinal suffix.
 * Eg. 1st, 3rd, 5th
 * @param {number} i
 * @returns {string}
 */
export function ordinalSuffix(value: number): string {
  const tenModulus: number = value % 10;
  const hundredModulus: number = value % 100;

  if (tenModulus === 1 && hundredModulus !== 11) {
    return `${value}st`;
  }
  if (tenModulus === 2 && hundredModulus !== 12) {
    return `${value}nd`;
  }
  if (tenModulus === 3 && hundredModulus !== 13) {
    return `${value}rd`;
  }
  return `${value}th`;
}

/**
 * Creates an array of number from the min to the max value
 *
 * @export
 * @param {number} min
 * @param {number} max
 * @returns {number[]}
 */
export function range(min: number, max: number): number[] {
  const numbers: number[] = [];
  for (let i: number = min; i <= max; i++) {
    numbers.push(i);
  }
  return numbers;
}

export class ResolveError extends Error {
  constructor(name: string, path: string) {
    super(`Couldn't resolve ${name} for ${path}`);
  }
}

export function orderEvents(
  eventableA: IEventable,
  eventableB: IEventable
): number {
  const eventableAFrom: moment.Moment = toMoment(eventableA.event.from);
  const eventableBFrom: moment.Moment = toMoment(eventableB.event.from);
  if (eventableAFrom.isAfter(eventableBFrom)) {
    return 1;
  }
  if (eventableAFrom.isBefore(eventableBFrom)) {
    return -1;
  }
  return 0;
}
