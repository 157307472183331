import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { IAppointmentFollowUp } from '../../pages/follow-ups-dashboard/follow-ups-dashboard.component';
import {
  HISTORY_DATE_FORMAT,
  Timestamp,
  WithRef,
  snapshot,
  toMoment,
  toTimestamp,
} from '@principle-theorem/shared';
import { map, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { FollowUpBodyComponent } from '../follow-up-body/follow-up-body.component';
import { DialogPresets, IActionButton } from '@principle-theorem/ng-shared';
import {
  IAppointment,
  IInteractiveResource,
  IPatient,
  ITreatmentCategory,
} from '@principle-theorem/principle-core/interfaces';
import { Appointment, TreatmentStep } from '@principle-theorem/principle-core';
import { FollowUpsService } from '../../follow-ups.service';
import { PatientActionsFactoryService } from '@principle-theorem/ng-interactions';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';

@Component({
  selector: 'pr-follow-up-list-item',
  templateUrl: './follow-up-list-item.component.html',
  styleUrl: './follow-up-list-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FollowUpListItemComponent {
  readonly dateFormat = HISTORY_DATE_FORMAT;
  followUp$ = new ReplaySubject<IAppointmentFollowUp>(1);
  appointment$: Observable<WithRef<IAppointment>>;
  interactiveResource$: Observable<IInteractiveResource>;
  patientUrl$: Observable<string[]>;
  followUpDate$: Observable<Timestamp | undefined>;
  patient$: Observable<WithRef<IPatient>>;
  followUpIsDue$: Observable<boolean>;
  isDueContact$: Observable<boolean>;
  treatmentCategory$: Observable<WithRef<ITreatmentCategory> | undefined>;
  patientActions: IActionButton[];

  @Input() hideDate = false;

  @Input()
  set followUp(followUp: IAppointmentFollowUp) {
    if (followUp) {
      this.followUp$.next(followUp);
    }
  }

  constructor(
    public followUpService: FollowUpsService,
    public actionFactory: PatientActionsFactoryService,
    private _dialog: MatDialog,
    private _globalStore: GlobalStoreService
  ) {
    this.followUpDate$ = this.followUp$.pipe(
      map((followUp) => followUp.appointment.activeFollowUp?.followUpDate)
    );
    this.appointment$ = this.followUp$.pipe(
      map((followUp) => followUp.appointment)
    );
    this.patientUrl$ = this.appointment$.pipe(
      map((appointment) => ['patients', Appointment.patientRef(appointment).id])
    );
    this.patient$ = this.followUp$.pipe(map((followUp) => followUp.patient));

    this.followUpIsDue$ = this.followUpDate$.pipe(
      map((followUpDate) =>
        followUpDate ? followUpDate <= toTimestamp() : true
      )
    );

    this.treatmentCategory$ = this.followUp$.pipe(
      map((followUp) =>
        TreatmentStep.defaultDisplayRef(
          followUp.appointment.treatmentPlan.treatmentStep.display
        )
      ),
      switchMap((displayRef) =>
        displayRef
          ? this._globalStore.getTreatmentCategory$(displayRef)
          : of(undefined)
      )
    );

    this.interactiveResource$ = this.appointment$.pipe(
      map((appointment) => ({
        interactions$: Appointment.interactions$(appointment),
        add: async (interaction) => {
          await Appointment.addInteraction(appointment, interaction);
        },
      }))
    );

    this.isDueContact$ = combineLatest([
      this.appointment$,
      this.followUp$,
    ]).pipe(
      map(([appointment, followUp]) => {
        if (!appointment.activeFollowUp || !followUp.lastContactedAt) {
          return true;
        }

        return !toMoment(followUp.lastContactedAt).isAfter(
          toMoment(appointment.activeFollowUp.createdAt)
        );
      })
    );

    this.patientActions = [
      actionFactory.sms,
      actionFactory.call,
      actionFactory.email,
    ].map((action) =>
      action.bind(actionFactory)(this.patient$, this.interactiveResource$)
    );
  }

  async openFollowUp(): Promise<void> {
    const followUp = await snapshot(this.followUp$);

    this._dialog.open(FollowUpBodyComponent, {
      ...DialogPresets.flex({
        minHeight: '80vh',
        minWidth: '70vw',
      }),
      data: followUp,
    });
  }
}
