import { Node, ResolvedPos, Slice } from '@tiptap/pm/model';
import { Selection, SelectionRange, TextSelection } from '@tiptap/pm/state';
import { COLUMN } from './column';
import { COLUMN_BLOCK } from './column-block';
import { BlockNodes } from '../../available-extensions';

type Mutable<T> = {
  -readonly [k in keyof T]: T[k];
};

export class ColumnSelection extends Selection {
  private _$from: ResolvedPos;
  private _$to: ResolvedPos;

  constructor(selection: Selection) {
    const { $from, $to } = selection;
    super($from, $to);
    this._$from = $from;
    this._$to = $to;
  }

  override get $from(): ResolvedPos {
    return this._$from;
  }

  override get $to(): ResolvedPos {
    return this._$to;
  }

  /**
   * Create a node selection from non-resolved positions.
   */
  static create(doc: Node, from: number, to: number): ColumnSelection {
    const $from = doc.resolve(from);
    const $to = doc.resolve(to);
    const selection = new TextSelection($from, $to);
    return new ColumnSelection(selection);
  }

  map(): ColumnSelection {
    return this;
  }

  override content(): Slice {
    return this.$from.doc.slice(this.from, this.to, true);
  }

  eq(other: Selection): boolean {
    return other instanceof ColumnSelection && other.anchor === this.anchor;
  }

  toJSON(): {
    type: string;
    from: number;
    to: number;
  } {
    return { type: BlockNodes.Column, from: this.from, to: this.to };
  }

  expandSelection(doc: Node): boolean | undefined {
    const fromNode = findParentNodeClosestToPos(
      doc,
      this.$from,
      resolveParentNode
    );
    if (!fromNode) {
      return false;
    }
    this._$from = doc.resolve(fromNode.pos);

    const toNode = findParentNodeClosestToPos(doc, this.$to, resolveParentNode);
    if (!toNode) {
      return false;
    }
    this._$to = doc.resolve(toNode.pos + toNode.node.nodeSize);

    if (this.getFirstNode()?.type.name === COLUMN_BLOCK.name) {
      const offset = 2;
      this._$from = doc.resolve(this.$from.pos + offset);
      this._$to = doc.resolve(this.$to.pos + offset);
    }

    const mutableThis = this as Mutable<ColumnSelection>;
    mutableThis.$anchor = this._$from;
    mutableThis.$head = this._$to;
    mutableThis.ranges = [new SelectionRange(this._$from, this._$to)];
  }

  getFirstNode(): Node | null {
    return this.content().content.firstChild;
  }
}

interface IPredicateProps {
  node: Node;
  depth: number;
  pos: number;
  start: number;
}

type Predicate = (doc: Node, props: IPredicateProps) => boolean;

function findParentNodeClosestToPos(
  doc: Node,
  $pos: ResolvedPos,
  predicate: Predicate
): IPredicateProps | undefined {
  for (let i = $pos.depth; i > 0; i--) {
    const node = $pos.node(i);
    const pos = i > 0 ? $pos.before(i) : 0;
    const start = $pos.start(i);
    const depth = i;
    if (predicate(doc, { node, pos, start, depth })) {
      return {
        start,
        depth,
        node,
        pos,
      };
    }
  }
}

function resolveParentNode(doc: Node, props: IPredicateProps): boolean {
  if (props.node.type.name === COLUMN.name) {
    return true;
  }
  return doc.resolve(props.pos).depth <= 0;
}
