import { type ComponentType } from '@angular/cdk/portal';
import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  type IInteractionV2,
  type IInteractionData,
  type IInteractiveResource,
  type IPrincipleMention,
} from '@principle-theorem/principle-core/interfaces';
import { type IInteractionAction } from './interaction-action';

@Injectable()
export abstract class BaseInteractionService<T> implements IInteractionAction {
  icon: string;
  name: string;
  dialogComponent: ComponentType<T>;
  actionUpdate = new EventEmitter<IInteractionV2>();

  constructor(private _dialog: MatDialog) {}

  async do(
    contact: IPrincipleMention,
    interactiveResource?: IInteractiveResource,
    templateResource?: IPrincipleMention
  ): Promise<void> {
    const data = await this._getDialogData(
      contact,
      interactiveResource,
      templateResource
    );
    if (!data) {
      return;
    }
    this._openDialog(data);
  }

  protected _getDialogData(
    contact: IPrincipleMention,
    resource?: IInteractiveResource,
    _templateResource?: IPrincipleMention
  ): Promise<IInteractionData | undefined> | IInteractionData | undefined {
    return { contact, resource };
  }

  private _openDialog(data: IInteractionData): void {
    const config: MatDialogConfig = DialogPresets.medium({
      data,
      autoFocus: true,
    });

    this._dialog
      .open<T, void, IInteractionV2>(this.dialogComponent, config)
      .afterClosed()
      .subscribe((interaction) => {
        if (!interaction) {
          return;
        }
        this.actionUpdate.emit(interaction);
      });
  }
}
