import { type Observable } from 'rxjs';
import {
  type AfterViewInit,
  ViewChild,
  Directive,
  type OnDestroy,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ObservableDataSource } from './observable-data-source';
import { type IDataTable } from './base-table';

export interface ITableOptions {
  displayColumns: string[];
  pageSizeOptions: number[];
  pageSize: number;
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class ObservableDataTable<T extends object>
  implements IDataTable<T, ObservableDataSource<T>>, AfterViewInit, OnDestroy
{
  dataSource: ObservableDataSource<T>;
  displayColumns: string[];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageSize = 10;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(data$: Observable<T[]>) {
    this.dataSource = new ObservableDataSource(data$);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
