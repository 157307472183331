<button
  *ngrxLet="isDisabled$ as disabled"
  mat-raised-button
  class="image !h-full"
  matTooltip="Romexis integration requires patient date of birth"
  [matTooltipDisabled]="!disabled"
  [matMenuTriggerFor]="menu"
  [disabled]="disabled"
>
  <div class="flex items-center gap-1">
    Romexis
    <mat-icon>arrow_drop_down</mat-icon>
  </div>
</button>

<mat-menu #menu="matMenu">
  @for (mode of captureModes; track mode.mode) {
    <button (click)="openRomexis(mode.mode)" mat-menu-item>
      {{ mode.label }}
    </button>
  }
</mat-menu>
