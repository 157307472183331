import {
  EventType,
  IEvent,
  IEventTimePeriod,
  IGap,
  ParticipantType,
} from '@principle-theorem/principle-core/interfaces';
import { AtLeast, toTimestamp } from '@principle-theorem/shared';
import { Event } from '../event/event';

export class Gap {
  static init(overrides: AtLeast<IGap, 'event' | 'practitioner'>): IGap {
    return {
      ...overrides,
    };
  }

  static fromEventTimePeriod(time: IEventTimePeriod): IGap {
    const event: IEvent = Event.init({
      type: EventType.Gap,
      from: toTimestamp(time.from),
      to: toTimestamp(time.to),
      organiser: time.staffer,
      practice: time.practice,
      participants: [
        {
          ...time.staffer,
          type: ParticipantType.Staffer,
        },
      ],
    });

    return Gap.init({
      practitioner: time.staffer,
      event,
    });
  }
}

export function isGapEventType(eventType: EventType): boolean {
  return [EventType.Gap].includes(eventType);
}
