import {
  type AnyChartedItemConfiguration,
  type IChartedItem,
  isTreatmentConfiguration,
  type ITreatmentConfiguration,
  type IChartedItemConfiguration,
  type IChartedRef,
} from '@principle-theorem/principle-core/interfaces';
import { type IChartedSurface } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import {
  type IChartedSurfaceProvider,
  type IChartSurfaceEvent,
} from './add-charted-surface-provider';

export abstract class BaseAddTreatmentProvider
  implements IChartedSurfaceProvider
{
  canProvide(chartable: AnyChartedItemConfiguration): boolean {
    return isTreatmentConfiguration(chartable);
  }

  async addChartedItem(
    event: IChartSurfaceEvent,
    chartable: WithRef<ITreatmentConfiguration>
  ): Promise<void> {
    await this.addSurfaceToTreatment(event, event.surfaces, chartable);
  }

  async removeSurfacesFromChartedItem(
    surfaces: Partial<IChartedRef>[],
    item: IChartedItem<IChartedItemConfiguration>
  ): Promise<void> {
    await this.removeSurfacesFromTreatment(surfaces, item);
  }

  abstract addSurfaceToTreatment(
    event: IChartSurfaceEvent,
    surfaces: IChartedSurface[],
    config: WithRef<ITreatmentConfiguration>
  ): Promise<void>;

  abstract removeSurfacesFromTreatment(
    surfaces: Partial<IChartedRef>[],
    item: IChartedItem<IChartedItemConfiguration>
  ): Promise<void>;
}
