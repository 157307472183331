import { NgModule } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { FollowUpsComponentsModule } from '../components/follow-up-components.module';
import { FollowUpsDashboardComponent } from './follow-ups-dashboard/follow-ups-dashboard.component';

@NgModule({
  imports: [
    NgSharedModule,
    NgMaterialModule,
    NgPrincipleSharedModule,
    FollowUpsComponentsModule,
  ],
  declarations: [FollowUpsDashboardComponent],
  exports: [FollowUpsDashboardComponent],
})
export class FollowUpPagesModule {}
