import {
  INamedDocument,
  IReffable,
  isSameRef,
} from '@principle-theorem/shared';

export function stafferIsOfRole(
  staffer?: IReffable,
  role?: INamedDocument
): boolean {
  return role && staffer ? isSameRef(role, staffer) : false;
}
