import * as moment from 'moment-timezone';
import { EventEmitter, Output, type OnInit, Directive } from '@angular/core';
import { type MeasureType } from '../../models/report/charts/measure-data-interfaces';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class RangeSelectorCard implements OnInit {
  @Output()
  rangeChanged: EventEmitter<IChangeEvent> = new EventEmitter<IChangeEvent>();
  selectedRange: ITimeRange;
  selectedScope: MeasureType;
  ranges: ITimeRange[] = [
    { title: 'Last Month', from: { months: -1 } },
    { title: 'Last Year', from: { months: -12 } },
  ];

  ngOnInit(): void {
    this.selectedRange = this.ranges[0];
    this.onDateChange();
  }

  onDateChange(): void {
    const change: IChangeEvent = {
      from: moment().add(this.selectedRange.from),
      to: moment().add(this.selectedRange.to),
    };
    this.rangeChanged.emit(change);
  }
}

export interface ITimeRange {
  title: string;
  to?: moment.DurationInputObject;
  from?: moment.DurationInputObject;
}

export interface IChangeEvent {
  from: moment.Moment;
  to: moment.Moment;
}
