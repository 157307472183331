import {
  AppointmentSummary,
  IAppointmentDateGroup,
  IAppointmentGroup,
} from '@principle-theorem/principle-core/interfaces';
import {
  CASUAL_DATE_FORMAT,
  ISO_DATE_FORMAT,
  ITimePeriod,
  MomentRange,
  toMoment,
} from '@principle-theorem/shared';
import { groupBy } from 'lodash';
import {
  AppointmentGroupSorter,
  ArrivingAppointments,
  CompletedAppointments,
  DepartingAppointments,
  WaitingAppointments,
} from './appointment-sorters';

export class AppointmentGrouper {
  groups: AppointmentGroupSorter[] = [
    new WaitingAppointments(),
    new DepartingAppointments(),
    new ArrivingAppointments(),
    new CompletedAppointments(),
  ];

  getByGroup(appointments: AppointmentSummary[]): IAppointmentGroup[] {
    return this.groups.map((sorter) => {
      const filtered = appointments
        .filter((appointment) => sorter.isInGroup(appointment))
        .sort(sorter.sort);

      return {
        name: sorter.name,
        appointments: filtered,
      };
    });
  }
}

export class AppointmentDateGrouper {
  groups: AppointmentGroupSorter[] = [
    new WaitingAppointments(),
    new DepartingAppointments(),
    new ArrivingAppointments(),
    new CompletedAppointments(),
  ];

  getByGroup(
    range: ITimePeriod,
    appointments: AppointmentSummary[]
  ): IAppointmentDateGroup[] {
    const dateGroups = groupBy(appointments, (appointment) =>
      toMoment(appointment.event.from).format(ISO_DATE_FORMAT)
    );
    return MomentRange.partitionBy(range, 'day').map((day) => ({
      id: day.format(ISO_DATE_FORMAT),
      name: day.format(CASUAL_DATE_FORMAT),
      groups: new AppointmentGrouper().getByGroup(
        dateGroups[day.format(ISO_DATE_FORMAT)] || []
      ),
    }));
  }
}
