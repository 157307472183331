import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  getSchemaSize,
  initVersionedSchema,
  toMentionContent,
  toTextContent,
  type VersionedSchema,
} from '@principle-theorem/editor';
import {
  DateService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  Interaction,
  stafferToNamedDoc,
  toMention,
} from '@principle-theorem/principle-core';
import {
  InteractionType,
  MentionResourceType,
  interactionTypeDisplayMap,
  type IInteraction,
  type IInteractionTypeMap,
  type IInteractionV2,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  snapshot,
  sortByCreatedAt,
  type WithRef,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  merge,
  type Observable,
} from 'rxjs';
import {
  filter,
  map,
  startWith,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { type NoteFormGroup } from '../note-form/note-form';

@Component({
  selector: 'pr-interactions',
  templateUrl: './interactions.component.html',
  styleUrls: ['./interactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InteractionsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByInteraction = TrackByFunctions.uniqueId<
    IInteraction | WithRef<IInteractionV2>
  >();
  interactions$ = new ReplaySubject<(IInteraction | WithRef<IInteractionV2>)[]>(
    1
  );
  pinnedInteractions$ = new ReplaySubject<
    (IInteraction | WithRef<IInteractionV2>)[]
  >(1);
  display: IInteractionTypeMap = interactionTypeDisplayMap;
  noteControl: TypedFormControl<VersionedSchema> = new TypedFormControl(
    initVersionedSchema(),
    Validators.required
  );
  buttonDisabled$: Observable<boolean>;
  addNote$ = new Subject<void>();
  isCompact$ = new BehaviorSubject<boolean>(false);
  useEditor$ = new BehaviorSubject<boolean>(true);
  readOnly$ = new BehaviorSubject<boolean>(false);
  pinnableTypes$ = new BehaviorSubject<InteractionType[]>([
    InteractionType.Note,
    InteractionType.Call,
  ]);

  @Input() enableScroll: boolean = false;
  @Output() interactionAdded = new EventEmitter<IInteractionV2>();
  @Output() interactionUpdated = new EventEmitter<
    IInteraction | WithRef<IInteractionV2>
  >();
  @Output() interactionDeleted = new EventEmitter<
    IInteraction | WithRef<IInteractionV2>
  >();
  editable$ = new BehaviorSubject<boolean>(false);
  @ViewChild('scrollMe', { static: true })
  myScrollContainer: ElementRef<HTMLElement>;

  @Input()
  set pinnableTypes(pinnableTypes: InteractionType[]) {
    if (pinnableTypes) {
      this.pinnableTypes$.next(pinnableTypes);
    }
  }

  @Input()
  set editable(editable: BooleanInput) {
    this.editable$.next(coerceBooleanProperty(editable));
  }

  @Input()
  set readOnly(readOnly: BooleanInput) {
    this.readOnly$.next(coerceBooleanProperty(readOnly));
  }

  @Input()
  set interactions(interactions: (IInteraction | WithRef<IInteractionV2>)[]) {
    if (interactions) {
      this.interactions$.next(interactions.sort(sortByCreatedAt).reverse());
    }
  }

  @Input()
  set pinnedInteractions(
    interactions: (IInteraction | WithRef<IInteractionV2>)[]
  ) {
    if (interactions) {
      this.pinnedInteractions$.next(
        interactions.sort(sortByCreatedAt).reverse()
      );
    }
  }

  @Input()
  set compact(compact: BooleanInput) {
    this.isCompact$.next(coerceBooleanProperty(compact));
  }

  @Input()
  set useEditor(useEditor: BooleanInput) {
    this.useEditor$.next(coerceBooleanProperty(useEditor));
  }

  constructor(
    public dateService: DateService,
    public snackBar: MatSnackBar,
    private _organisation: OrganisationService
  ) {
    merge(this.interactions$, this.interactionAdded)
      .pipe(startWith(undefined), takeUntil(this._onDestroy$))
      .subscribe(() => setTimeout(() => this._scrollToBottom(), 50));

    this.buttonDisabled$ = this.noteControl.valueChanges.pipe(
      map((value) => !this.noteControl.dirty || !getSchemaSize(value)),
      startWith(true)
    );

    this.addNote$
      .asObservable()
      .pipe(
        filter(() => getSchemaSize(this.noteControl.value) > 0),
        withLatestFrom(this._organisation.staffer$.pipe(filterUndefined())),
        map(([_, staffer]) => {
          const interaction = Interaction.init({
            type: InteractionType.Note,
            owner: stafferToNamedDoc(staffer),
            title: [
              toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
              toTextContent(` added a note`),
            ],
            content: this.noteControl.value,
          });
          this.interactionAdded.emit(interaction);
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.noteControl.setValue(initVersionedSchema()));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async addPatientNote(form: NoteFormGroup): Promise<void> {
    const { content, tags } = form.getRawValue();
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const interaction = Interaction.init({
      type: InteractionType.Note,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` added a Note`),
      ],
      owner: stafferToNamedDoc(staffer),
      content,
      tags,
    });
    this.interactionAdded.emit(interaction);
    form.reset();
  }

  private _scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {
      // Do nada
    }
  }
}
