import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  BridgeDeviceStatus,
  IBridgeDevice,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference } from '@principle-theorem/shared';
import { WithRef } from '@principle-theorem/shared';
import { first } from 'lodash';
import { Observable } from 'rxjs';
import { BridgeCommandsService } from '../../bridge-commands.service';
import { IBridgeIntegration } from '@principle-theorem/principle-bridge-core';

export interface IBridgeDeviceSelectorDialogData {
  integration: IBridgeIntegration;
}

@Component({
  selector: 'pr-bridge-device-selector-dialog',
  templateUrl: './bridge-device-selector-dialog.component.html',
  styleUrls: ['./bridge-device-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BridgeDeviceSelectorDialogComponent {
  devices$: Observable<WithRef<IBridgeDevice>[]>;
  trackByDevice = TrackByFunctions.ref<WithRef<IBridgeDevice>>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IBridgeDeviceSelectorDialogData,
    public _dialogRef: MatDialogRef<
      BridgeDeviceSelectorDialogComponent,
      DocumentReference<IBridgeDevice>
    >,
    bridgeCommands: BridgeCommandsService
  ) {
    this.devices$ = bridgeCommands.devicesByIntegration$(data.integration);
  }

  selectDevice(event: MatSelectionListChange): void {
    const selection = first(
      event.source.selectedOptions.selected.map(
        (option) => option.value as WithRef<IBridgeDevice>
      )
    );

    if (!selection) {
      return;
    }
    this._dialogRef.close(selection.ref);
  }

  isDisabled(device: WithRef<IBridgeDevice>): boolean {
    return device.status === BridgeDeviceStatus.Inactive;
  }
}
