import * as moment from 'moment-timezone';

export enum MetricFormatter {
  Currency = 'currency',
  Percentage = 'percentage',
  Number = 'number',
  Boolean = 'boolean',
  Prefix = 'prefix',
  Suffix = 'suffix',
  AverageNumber = 'averageNumber',
  AverageCurrency = 'averageCurrency',
}

export interface IStatistics<T extends IStatisticMetrics = IStatisticMetrics> {
  stats: T;
  name: string;
  date: moment.Moment;
}

export interface IStatisticsMocker {
  names?: string[];
  unit?: moment.DurationInputArg2;
  stats?: IStatisticMetrics;
}

export interface IStatisticMetrics {
  [key: string]: IStatistic;
}

export interface IStatistic {
  label: string;
  summary: string;
  metric: number;
  formatter: MetricFormatter;
}
