import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type MatMenu } from '@angular/material/menu';
import { type ISidebarItem } from '../sidebar-item';
import {
  SidebarManagerService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { buildIntercomTarget } from '@principle-theorem/ng-intercom';

@Component({
  selector: 'pr-sidebar-group',
  templateUrl: './sidebar-group.component.html',
  styleUrls: ['./sidebar-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarGroupComponent {
  trackByLink = TrackByFunctions.label<ISidebarItem>();
  @Input() label = '';
  @Input() tooltip = '';
  @Input() links: ISidebarItem[] = [];
  @Input() expanded = false;
  @Input() matMenu?: MatMenu;
  @Input() matMenuTooltip?: string;
  @Input() routePrefix: string = '';

  constructor(private _sidebar: SidebarManagerService) {}

  getRoutePath(routePrefix: string, route: string): string {
    if (routePrefix.endsWith('/')) {
      return `${routePrefix.slice(0, -1)}/${route}`;
    }
    return `${routePrefix}/${route}`;
  }

  closeSidebarIfMobile(): void {
    if (this._sidebar.isMobile) {
      this._sidebar.close();
    }
  }

  getIntercomTarget(link: ISidebarItem): string {
    return buildIntercomTarget(['sidebar', this.label, link.label]);
  }
}
