import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { Validators, type ValidatorFn } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { type RawInlineNodes } from '@principle-theorem/editor';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TrackByFunctions,
  TypedFormControl,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import { RecurrencePattern } from '@principle-theorem/principle-core';
import {
  type IPractice,
  type IStaffer,
  type ITask,
  type ITeam,
  type TaskPriority,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAYS_OF_WEEK,
  DayOfWeek,
  Frequency,
  type WithRef,
} from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Subject, type Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BaseTaskForm } from '../../base-task-form';
import { type TaskAssigneeComponent } from '../task-assignee/task-assignee.component';

@Component({
    selector: 'pr-task-form',
    templateUrl: './task-form.component.html',
    styleUrls: ['./task-form.component.scss'],
    providers: [...MOMENT_DATEPICKER_PROVIDERS],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TaskFormComponent extends BaseTaskForm implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject<void>();
  private _practice: WithRef<IPractice>;
  trackByFrequency = TrackByFunctions.variable<Frequency>();
  trackByPriority = TrackByFunctions.variable<TaskPriority>();
  hasCustomFrequency$: Observable<boolean>;
  showDaysOfWeek$: Observable<boolean>;
  task: WithRef<ITask>;
  minDate = moment();
  saving = false;
  summary = '';
  startDateControl = new TypedFormControl<Moment>();
  isRecurring$ = new BehaviorSubject<boolean>(false);
  daysOfWeek = DAYS_OF_WEEK;
  daysOfWeekCtrl = new TypedFormControl<DayOfWeek[]>(this.daysOfWeek);

  @ViewChild('assigneeForm', { static: true })
  assigneeForm: TaskAssigneeComponent;

  constructor(
    dialog: MatDialog,
    currentScope: CurrentScopeFacade,
    organisation: OrganisationService,
    @Inject(MAT_DIALOG_DATA) public data: ITaskInitialFormData,
    private _dialogRef: MatDialogRef<TaskFormComponent>,
    private _snackBar: MatSnackBar
  ) {
    super(dialog, currentScope, organisation);

    if (this.data.title) {
      this.taskFormGroup.controls.title.setValue(this.data.title);
    }

    organisation.staffer$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((assignee) => this.taskFormGroup.patchValue({ assignee }));

    this._practice = data.practice;
    if (data.staffer) {
      this.taskFormGroup.controls.assignee.setValue(data.staffer);
    }

    this.taskFormGroup.controls.dueTime.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((time) => {
        const fieldValidators: ValidatorFn[] | ValidatorFn = time
          ? Validators.required
          : [];
        this.taskFormGroup.controls.dueDate.setValidators(fieldValidators);
      });

    this.hasCustomFrequency$ = formControlChanges$(
      this.taskFormGroup.controls.recurringFrequency
    ).pipe(map((frequency) => frequency === Frequency.Custom));

    this.showDaysOfWeek$ = formControlChanges$(
      this.taskFormGroup.controls.recurringFrequency
    ).pipe(map((frequency) => frequency === Frequency.Daily));

    formControlChanges$(this.taskFormGroup.controls.recurringFrequency)
      .pipe(
        filter((frequency) => frequency !== Frequency.Daily),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.daysOfWeekCtrl.setValue(this.daysOfWeek));

    this.isRecurring$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((isRecurring) => {
        if (!isRecurring) {
          this._clearRecurringForm();
          return;
        }
        this._enableRecurringForm();
      });

    formControlChanges$(this.daysOfWeekCtrl)
      .pipe(
        filter((days) => !days?.length),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.daysOfWeekCtrl.setValue(this.daysOfWeek));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  get assignee(): TypedFormControl<WithRef<IStaffer> | WithRef<ITeam>> {
    return this.taskFormGroup.controls.assignee as TypedFormControl<
      WithRef<IStaffer> | WithRef<ITeam>
    >;
  }

  async submit(): Promise<void> {
    if (this.taskFormGroup.invalid) {
      return;
    }
    this.saving = true;

    await this._taskManager.add(
      this._practice,
      this.taskFormGroup.getRawValue()
    );
    this._snackBar.open('Task added');
    this._dialogRef.close();
  }

  clearStartDate(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.taskFormGroup.controls.recurrencePattern.setValue(
      RecurrencePattern.init({
        startDate: undefined,
      })
    );
    this.startDateControl.reset();
  }

  setDaysOfWeek(daysOfWeek: DayOfWeek[]): void {
    this.taskFormGroup.controls.recurrencePattern.setValue(
      RecurrencePattern.init({
        ...this.taskFormGroup.value.recurrencePattern,
        daysOfWeek,
      })
    );
  }

  private _clearRecurringForm(): void {
    this.taskFormGroup.controls.recurrenceTiming.reset();
    this.taskFormGroup.controls.recurrencePattern.reset();
    this.taskFormGroup.controls.recurringFrequency.setValue(undefined);
    this.taskFormGroup.controls.recurringFrequency.clearValidators();
    this.taskFormGroup.controls.recurringFrequency.updateValueAndValidity();
  }

  private _enableRecurringForm(): void {
    this.taskFormGroup.controls.dueDate.setValue(undefined);
    this.taskFormGroup.controls.dueTime.setValue(undefined);
    this.taskFormGroup.controls.recurringFrequency.setValidators(
      Validators.required
    );
    this.taskFormGroup.controls.recurringFrequency.updateValueAndValidity();
  }
}

export interface ITaskInitialFormData {
  practice: WithRef<IPractice>;
  staffer?: WithRef<IStaffer>;
  title?: RawInlineNodes;
}
