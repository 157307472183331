import { Pipe, type PipeTransform } from '@angular/core';
import { type ITag } from '@principle-theorem/principle-core/interfaces';
import { Tag } from '@principle-theorem/principle-core';

@Pipe({
    name: 'tagTextColour',
    standalone: false
})
export class TagTextColourPipe implements PipeTransform {
  transform(tag: ITag): string {
    return Tag.textColour(tag);
  }
}
