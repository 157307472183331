import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
  type OnDestroy,
  Signal,
  computed,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  EditorPresetsService,
  renderVersionedSchema,
} from '@principle-theorem/ng-interactions';
import {
  IMatSelectOption,
  TrackByFunctions,
  TypedAbstractControl,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import { getScopedConditions } from '@principle-theorem/principle-core';
import {
  ConditionLogicId,
  TEMPLATE_SCOPES,
  TemplateScope,
  TemplateType,
  type ITemplateContext,
  type ITemplateDefinition,
  type ITemplateVariant,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRefOrUnsaved,
  filterUndefined,
  isChanged$,
  shareReplayCold,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
} from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import { TemplateEditorFormStore } from './template-editor-form-store.service';
import {
  TemplateForm,
  type ITemplateFormData,
  type TemplateChangeData,
} from './template-editor-forms';

@Component({
  selector: 'pr-template-editor-form',
  templateUrl: './template-editor-form.component.html',
  styleUrls: ['./template-editor-form.component.scss'],
  providers: [TemplateEditorFormStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateEditorFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _variantsCache?: ITemplateVariant[];
  trackByVariant = TrackByFunctions.uniqueId<ITemplateVariant>();
  scopes: TemplateScope[] = TEMPLATE_SCOPES;
  typeOptions: IMatSelectOption<TemplateType>[] = [
    { value: TemplateType.PlainText, label: 'SMS Template' },
    { value: TemplateType.Html, label: 'Email Template' },
  ];
  isHTMLTemplate$ = new BehaviorSubject<boolean>(false);
  isSystemTemplate = signal(false);
  isAutomationTemplate = signal(false);
  hideNameField = signal(false);
  hasTemplateSettings: Signal<boolean>;
  templateType$: Observable<TemplateType>;
  templateForm = new TemplateForm();
  context$ = new BehaviorSubject<ITemplateContext>({});
  hasNoScope$ = new ReplaySubject<boolean>(1);
  variantsEnabled = signal(false);
  @Output() templateChange = new EventEmitter<TemplateChangeData>();
  variantHelpText = `Template Variants allow you to create multiple versions of
  a template. When a template is used, Principle will check each variant to see
  if it matches the variant's conditions. If a variant matches, it will be used
  instead of the default template.`;

  @Input()
  set template(template: WithRefOrUnsaved<ITemplateDefinition>) {
    if (!template || !this.templateForm.pristine) {
      return;
    }
    this.templateForm.patchValue(template, { emitEvent: true });
    const availableConditions = getScopedConditions(template.scope).filter(
      (condition) => condition !== ConditionLogicId.Always
    );
    this.store.patchState({
      variants: template.variants ?? [],
      availableConditions,
    });
    this.isHTMLTemplate$.next(template.type === TemplateType.Html);
    this.hasNoScope$.next(template.scope === TemplateScope.None);
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('isSystemTemplate')
  set isSystemTemplateInput(value: BooleanInput) {
    this.isSystemTemplate.set(coerceBooleanProperty(value));
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('isAutomationTemplate')
  set isAutomationTemplateInput(value: BooleanInput) {
    this.isAutomationTemplate.set(coerceBooleanProperty(value));
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('hideNameField')
  set hideNameFieldInput(value: BooleanInput) {
    this.hideNameField.set(coerceBooleanProperty(value));
  }

  @Input()
  set context(context: ITemplateContext) {
    this.context$.next(context);
  }

  constructor(
    private _editorPresets: EditorPresetsService,
    public store: TemplateEditorFormStore
  ) {
    // TODO: Scrub the content field for XSS??
    const formChanges$ = validFormGroupChanges$(this.templateForm).pipe(
      map(() => this.templateForm.getRawValue()),
      debounceTime(250),
      isChanged$(),
      shareReplayCold()
    );

    this.hasTemplateSettings = computed(
      () => !this.hideNameField() || !this.isAutomationTemplate()
    );

    store.hasVariants$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((hasVariants) => this.variantsEnabled.set(hasVariants));

    combineLatest([formChanges$, this.store.variants$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(
        ([formValue, variants]) =>
          void this._updateTemplate(formValue, variants)
      );

    combineLatest([
      toObservable(this.isSystemTemplate),
      toObservable(this.isAutomationTemplate),
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([isSystemTemplate, isAutomationTemplate]) =>
        this._updateFormInputs(isSystemTemplate, isAutomationTemplate)
      );

    this.templateType$ = this.isHTMLTemplate$.pipe(
      map((isHTML) => (isHTML ? TemplateType.Html : TemplateType.PlainText))
    );

    this.templateForm.controls.scope.valueChanges
      .pipe(
        filterUndefined(),
        tap((value) => this.hasNoScope$.next(value === TemplateScope.None)),
        map((value) => getScopedConditions(value)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((availableConditions) =>
        this.store.patchState({ availableConditions })
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  enableVariantsChanged(enableVariants: boolean): void {
    if (enableVariants) {
      if (!this._variantsCache?.length) {
        this.store.addVariant();
        return;
      }
      this.store.patchState({ variants: this._variantsCache });
      this._variantsCache = undefined;
      return;
    }
    this._variantsCache = this.store.state().variants;
    this.store.patchState({ variants: [] });
  }

  private _updateFormInputs(
    isSystemTemplate: boolean,
    isAutomationTemplate: boolean
  ): void {
    const updates: IFormFieldStatusUpdate[] = [
      {
        control: this.templateForm.controls.name,
        enabled: !isSystemTemplate && !isAutomationTemplate,
      },
      { control: this.templateForm.controls.type, enabled: !isSystemTemplate },
      {
        control: this.templateForm.controls.scope,
        enabled: !isSystemTemplate && !isAutomationTemplate,
      },
    ];

    updates.map((update) =>
      update.enabled ? update.control.enable() : update.control.disable()
    );
  }

  private _updateTemplate(
    formValue: ITemplateFormData,
    variants: ITemplateVariant[]
  ): void {
    const renderedTemplate = renderVersionedSchema(
      formValue.content,
      this._editorPresets.defaultToHTMLExtensions()
    );
    const updatedTemplate = { ...formValue, renderedTemplate, variants };
    this.templateChange.emit(updatedTemplate);
    this.isHTMLTemplate$.next(formValue.type === TemplateType.Html);
  }
}

interface IFormFieldStatusUpdate {
  control: TypedAbstractControl<unknown>;
  enabled: boolean;
}
