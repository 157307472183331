<mat-toolbar color="accent">Add Tooth</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content>
    <div fxLayout="column" formGroupName="toothRef">
      <mat-form-field>
        <mat-label>Quadrant</mat-label>
        <mat-select formControlName="quadrant" required>
          <mat-option
            *ngFor="let quadrant of quadrants; trackBy: trackByQuadrant"
            [value]="quadrant"
          >
            {{ quadrant }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Tooth Index</mat-label>
        <input
          matInput
          type="number"
          min="1"
          formControlName="quadrantIndex"
          [required]="true"
        />
      </mat-form-field>
    </div>

    <mat-form-field>
      <mat-label>Tooth Type</mat-label>
      <mat-select formControlName="type" required>
        <mat-option
          *ngFor="let toothType of toothTypes; trackBy: trackByToothType"
          [value]="toothType"
        >
          {{ toothType | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Number of Roots</mat-label>
      <mat-select formControlName="roots" required>
        <mat-option
          *ngFor="let roots of rootOptions; trackBy: trackByRoot"
          [value]="roots"
        >
          {{ roots }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" [disabled]="form.invalid">
        Save
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
