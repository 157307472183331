import {
  TaskAction,
  TaskStatus,
} from '@principle-theorem/principle-core/interfaces';
import { ITaskEventFact } from '@principle-theorem/reporting/interfaces';
import {
  BaseMock,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
} from '@principle-theorem/shared';
import { BrandDimensionMock } from '../dimensions/brand-dimension.mock';
import { SerialisedDocumentReferenceMock } from '../dimensions/common.mock';
import { PracticeDimensionMock } from '../dimensions/practice-dimension.mock';
import { RecurringTaskDimensionMock } from '../dimensions/recurring-task-dimension.mock';
import { StafferDimensionMock } from '../dimensions/staffer-dimension.mock';
import { TaskDimensionMock } from '../dimensions/task-dimension.mock';
import { TeamDimensionMock } from '../dimensions/team-dimension.mock';

export class TaskEventFactMock extends BaseMock implements ITaskEventFact {
  taskRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  ownerRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  assignedUserRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  assignedTeamRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practiceRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  brandRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  task = MockGenerator.generate(TaskDimensionMock);
  recurringConfiguration = MockGenerator.generate(RecurringTaskDimensionMock);
  owner = MockGenerator.generate(StafferDimensionMock);
  assignedUser = MockGenerator.generate(StafferDimensionMock);
  assignedTeam = MockGenerator.generate(TeamDimensionMock);
  brand = MockGenerator.generate(BrandDimensionMock);
  practice = MockGenerator.generate(PracticeDimensionMock);
  timestamp = toSerialisedTimestamp(MockTimestamp());
  event = {
    action: TaskAction.Completed,
    statusBefore: TaskStatus.Open,
    statusAfter: TaskStatus.Complete,
  };
}
