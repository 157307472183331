import { recursiveReplace } from '@principle-theorem/editor';
import {
  BASIC_MEDICAL_HISTORY_FORM_SCHEMA,
  Brand,
} from '@principle-theorem/principle-core';
import {
  CustomMappingAssociatedValueType,
  CustomMappingOption,
  CustomMappingType,
  FormSchemaPropertyType,
  ICustomFormDataResolverConfig,
  ICustomMapping,
  ICustomMappingSourceOption,
  IFormSchema,
  IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import {
  IReffable,
  WithRef,
  isArray,
  isObject,
} from '@principle-theorem/shared';
import { cloneDeep, sortBy } from 'lodash';
import { map } from 'rxjs/operators';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import { PatientAllergyOptionsSourceEntity } from '../../source/entities/patient-allergy-options';
import { Observable } from 'rxjs';

export const ALLERGY_CUSTOM_MAPPING_TYPE = 'medicalAllergyMapping';

export interface ICustomFormDataResolverConfigExtended
  extends ICustomFormDataResolverConfig {
  type: FormSchemaPropertyType;
  associatedValues?: string[];
}

export const ALLERGY_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Allergy Mapping',
    description: `Used to map Core Practice allergies to Principle medical history.`,
    type: ALLERGY_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.SelectionList,
});

export class CorePracticeAllergyMappingHandler extends BaseCustomMappingHandler<
  object,
  string
> {
  customMapping = ALLERGY_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const allergyOptions = new PatientAllergyOptionsSourceEntity();
    const records = await allergyOptions
      .getRecords$(migration, 1000)
      .toPromise();
    return sortBy(
      records
        .map((record) => record.data.data)
        .map((record) => ({
          label: record.description,
          value: record.description,
        })),
      'value'
    );
  }

  async getSelectionListOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<CustomMappingOption[]> {
    return Brand.medicalHistoryFormConfig$(migration.configuration.brand)
      .pipe(
        map((config) => {
          const schema = config?.schema ?? BASIC_MEDICAL_HISTORY_FORM_SCHEMA;
          return [
            {
              value: '',
              description: 'Omit',
              hasAssociatedValue: false,
              associatedValueType: CustomMappingAssociatedValueType.String,
              associatedValueDescription: '',
            },
            ...getValidMedicalHistoryFormOptions(schema).map((option) => ({
              value: option.path,
              description: option.mapTitle ?? '',
              hasAssociatedValue: option.associatedValues ? true : false,
              associatedValueType: CustomMappingAssociatedValueType.String,
              associatedValueDescription: 'Select an option',
            })),
          ];
        })
      )
      .toPromise();
  }

  getAssociatedValueOptions$(
    migration: IPracticeMigration,
    destinationValue: string
  ): Observable<{ name: string }[]> {
    return Brand.medicalHistoryFormConfig$(migration.configuration.brand).pipe(
      map((config) => {
        const schema = config?.schema ?? BASIC_MEDICAL_HISTORY_FORM_SCHEMA;
        const selectedOption = getValidMedicalHistoryFormOptions(schema).find(
          (option) => option.path === destinationValue
        );
        return (selectedOption?.associatedValues ?? []).map((name) => ({
          name,
        }));
      })
    );
  }
}

export function getValidMedicalHistoryFormOptions(
  schema: IFormSchema
): ICustomFormDataResolverConfigExtended[] {
  const options: ICustomFormDataResolverConfigExtended[] = [];
  recursiveReplace(cloneDeep(schema), (option, _parents, key) => {
    if (isObject(option.properties) && 'properties' in option) {
      return option.properties;
    }

    if (isObject(option) && 'type' in option && 'title' in option) {
      const type = option.type as FormSchemaPropertyType;
      const associatedValues =
        'enum' in option && isArray(option.enum)
          ? option.enum.map((value) => String(value))
          : undefined;
      if (
        [
          FormSchemaPropertyType.Boolean,
          FormSchemaPropertyType.String,
        ].includes(type)
      ) {
        options.push({
          path: key ?? '',
          mapTitle: String(option.title),
          type,
          associatedValues,
        });
      }
    }

    return option;
  });

  return sortBy(options, 'mapTitle');
}
