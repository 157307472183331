import { type WithRef } from '@principle-theorem/shared';
import { type ILabJob } from '@principle-theorem/principle-core/interfaces';

export enum LabJobSortId {
  Lab = 'lab',
  DueDate = 'dueDate',
  Newest = 'createdAt',
}

export interface ILabJobSortOption {
  id: LabJobSortId;
  icon: string;
  label: string;
  direction: 'asc' | 'desc';
  sortAccessor: (labJob: WithRef<ILabJob>) => number | string;
  sort?: (a: WithRef<ILabJob>, b: WithRef<ILabJob>) => number;
}

export const LAB_NAME_SORT: ILabJobSortOption = {
  id: LabJobSortId.Lab,
  icon: 'title',
  label: 'Lab Name',
  direction: 'asc',
  sortAccessor: (labJob: WithRef<ILabJob>) => {
    return labJob.lab.name;
  },
};

export const LAB_JOB_DUE_DATE_SORT: ILabJobSortOption = {
  id: LabJobSortId.DueDate,
  icon: 'date_range',
  label: 'Due Date',
  direction: 'asc',
  sortAccessor: (labJob: WithRef<ILabJob>) => {
    if (!labJob.dueDate) {
      return Infinity;
    }
    return labJob.dueDate.seconds;
  },
};

export const LAB_JOB_CREATED_SORT: ILabJobSortOption = {
  id: LabJobSortId.Newest,
  icon: 'acesss_time',
  label: 'Newest',
  direction: 'desc',
  sortAccessor: (labJob: WithRef<ILabJob>) => {
    return labJob.createdAt.seconds;
  },
};

export const LAB_JOB_SORT_OPTIONS: ILabJobSortOption[] = [
  LAB_NAME_SORT,
  LAB_JOB_DUE_DATE_SORT,
  LAB_JOB_CREATED_SORT,
];

export function labJobSortOptionById(
  id: string
): ILabJobSortOption | undefined {
  return LAB_JOB_SORT_OPTIONS.find(
    (option) => String(option.id) === String(id)
  );
}
