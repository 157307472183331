<div
  (click)="$event.stopPropagation()"
  class="flex grow flex-col items-stretch gap-2"
>
  <span>
    {{ label }}
    <mat-icon *ngIf="tooltip" [matTooltip]="tooltip">info</mat-icon>
  </span>

  <mat-form-field subscriptSizing="dynamic">
    <mat-label>{{ label }}</mat-label>
    <mat-select [formControl]="formCtrl">
      <mat-option
        *ngFor="let option of options$ | async; trackBy: trackByOption"
        [value]="option | map: optionToValue"
        >{{ option | map: optionToLabel | splitCamel | titlecase }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
