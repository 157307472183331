import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { BreakpointService } from '@principle-theorem/ng-shared';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'pr-navbar',
    templateUrl: './navbar.component.html',
    styleUrl: './navbar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NavbarComponent {
  showMobileMenu$ = new BehaviorSubject<boolean>(false);

  constructor(
    public org: OrganisationService,
    public breakpoint: BreakpointService
  ) {}

  toggleMenu(): void {
    this.showMobileMenu$.next(!this.showMobileMenu$.value);
  }
}
