import {
  type BooleanInput,
  coerceBooleanProperty,
  coerceNumberProperty,
} from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, type Observable, ReplaySubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { type IScoreClass } from '../appointment-options/appointment-options.component';

@Component({
  selector: 'pr-appointment-score',
  templateUrl: './appointment-score.component.html',
  styleUrls: ['./appointment-score.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentScoreComponent {
  score$ = new ReplaySubject<number>(1);
  vertical$ = new BehaviorSubject<boolean>(false);
  scoreClass$: Observable<IScoreClass>;

  @Input()
  set vertical(vertical: BooleanInput) {
    this.vertical$.next(coerceBooleanProperty(vertical));
  }

  @Input()
  set score(score: number) {
    this.score$.next(coerceNumberProperty(score));
  }

  constructor() {
    this.scoreClass$ = this.score$.pipe(
      map((score) => this.getScoreClass(score)),
      startWith({
        high: false,
        medium: false,
        low: false,
      })
    );
  }

  getScoreClass(score: number): IScoreClass {
    return {
      high: score >= 0.9,
      medium: score < 0.9 && score >= 0.7,
      low: score < 0.7,
    };
  }
}
