import { TypeGuard, type WithRef } from '@principle-theorem/shared';
import { type IBrand } from './brand';
import { type IDynamicForm } from './dynamic-form';
import { isString } from 'lodash';

/**
 * We must define the conditions available for each template scope.
 * - If you change scope, we need to let you know which conditions will immediatly fail.
 * - When selecting conditions, infer them from the template scope
 * - In the case of an automation, infer from that automation's scope (this is always appointment currently)
 */

export enum ConditionLogicId {
  Always = 'always',
  Never = 'never',
  PatientHasTag = 'patientHasTag',
  PatientHasMobileNumber = 'patientHasMobileNumber',
  PatientHasEmailAddress = 'patientHasEmailAddress',
  AppointmentHasTag = 'appointmentHasTag',
  AppointmentIsStatus = 'appointmentIsStatus',
  AppointmentConfirmed = 'appointmentConfirmed',
  IsFirstAppointment = 'isFirstAppointment',
  AppointmentIncludesTreatment = 'appointmentInlcudesTreatment',
  AppointmentExcludesTreatment = 'appointmentExcludesTreatment',
  AppointmentIsTreatmentCategory = 'appointmentIsTreatmentCategory',
  AppointmentIsNotTreatmentCategory = 'appointmentIsNotTreatmentCategory',
}

export interface IConditionLogicImplementation<
  Context,
  Config,
  Type = unknown,
> {
  conditionId: ConditionLogicId;
  description: string;
  isValidContext(context: unknown): context is Context;
  isTrue(config: Config, context: Context): boolean | Promise<boolean>;
  getForm(
    brand: WithRef<IBrand>,
    initialData?: Config
  ): Promise<IDynamicForm<Type>>;
}

/**
 * Saved in firestore, on the template
 * -----------------------------------------------------------------------------
 */
export interface IConditionLogicConfiguration<Config> {
  uid: string;
  conditionId: ConditionLogicId;
  config: Config;
}

/**
 * Two dimensional array of IConditionConfigurations, where the outer-array is
 * evaulated as an OR and the inner-array is evaluated as an AND
 * - This would be added to a documentation template variant, to determine if
 *   it should be used instead of the default
 * - This would be added to an automation configuration, to determine which
 *   action should be used when the automation is run (triggered)
 */
export type ConditionLogicConfigurationCollection = IOrConditions;

export interface IAndConditions {
  uid: string;
  and: IConditionLogicConfiguration<unknown>[];
}

export interface IOrConditions {
  uid: string;
  or: IAndConditions[];
}

export class ConditionLogicTypeGuard {
  static isAndConditions = TypeGuard.interface<IAndConditions>({
    uid: isString,
    and: TypeGuard.arrayOf(TypeGuard.noGuard()),
  });

  static isOrConditions = TypeGuard.interface<IOrConditions>({
    uid: isString,
    or: TypeGuard.arrayOf(this.isAndConditions),
  });

  static isConditionLogicConfigurationCollection = this.isOrConditions;
}
