import { randFullName } from '@ngneat/falso';
import {
  type Reffable,
  toTimestamp,
  type WithRef,
  type INamedDocument,
} from '@principle-theorem/shared';
import {
  type CollectionReference,
  DocumentData,
  type DocumentReference,
} from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';

function getPathId(path: string): string {
  const split = path.split('/');
  return split.pop() ?? uuid();
}

export function MockColRef<T>(path?: string): CollectionReference<T> {
  const defaultPath = `testing/collection/reference/${uuid()}`;
  const colPath = path ?? defaultPath;
  return {
    type: 'collection',
    id: getPathId(colPath),
    path: colPath,
  } as unknown as CollectionReference<T>;
}

export function MockDocRef<T>(path?: string): DocumentReference<T> {
  const defaultPath = `testing/document/reference/${uuid()}`;
  const documentPath = path ?? defaultPath;
  const paths = documentPath.split('/');
  paths.pop();
  const collectionPath = paths.join('/');
  const parentCollection = MockColRef(collectionPath);
  return {
    type: 'document',
    id: getPathId(documentPath),
    path: documentPath,
    collection: parentCollection,
    parent: parentCollection,
  } as unknown as DocumentReference<T>;
}

export function MockNamedDocument<T = DocumentData>(
  name?: string
): INamedDocument<T> {
  return {
    name: name || `${randFullName()}`,
    ref: MockDocRef<T>(),
  };
}

export function MockReffable<T>(item: T): Reffable<T> {
  return {
    ...item,
    ref: MockDocRef<T>(),
  };
}

export function MockWithRef<T>(item: T, path?: string): WithRef<T> {
  return {
    ref: MockDocRef<T>(path),
    createdAt: toTimestamp(),
    updatedAt: toTimestamp(),
    ...item,
  };
}
