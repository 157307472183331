import { Brand } from '@principle-theorem/principle-core';
import { type IStaffer } from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  type INamedDocument,
  type IReffable,
  multiMap,
  multiSortBy$,
  nameSorter,
  query$,
  snapshot,
  undeletedQuery,
  type WithRef,
  XSLXImporterExporter,
} from '@principle-theorem/shared';
import { sortBy, uniqBy } from 'lodash';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import {
  CustomMappingType,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import { AppointmentBookToPractitionerToXSLX } from './appointment-book-to-practitioner-to-xlsx';
import {
  CalendarSourceEntity,
  ICorePracticeCalendar,
} from '../../source/entities/calendars';
import { XSLXToAppointmentBookToPractitioner } from '../../../d4w/destination/mappings/xlsx-to-appointment-book-to-practitioner';

export const APPOINTMENT_BOOK_TO_PRACTITIONER_CUSTOM_MAPPING_TYPE =
  'appointmentBookToPractitioner';

export const APPOINTMENT_BOOK_TO_PRACTITIONER_MAPPING: ICustomMapping =
  CustomMapping.init({
    metadata: {
      label: 'Appointment Book to Practitioner',
      description: `Used to map appointments to a practitioner that are a part of an appointment book where the appointment had no practitioner selected.`,
      type: APPOINTMENT_BOOK_TO_PRACTITIONER_CUSTOM_MAPPING_TYPE,
    },
    type: CustomMappingType.DocumentReference,
  });

export class CorePracticeAppointmentBookToPractitionerMappingHandler extends BaseCustomMappingHandler<IStaffer> {
  customMapping = APPOINTMENT_BOOK_TO_PRACTITIONER_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const appointmentBooks = new CalendarSourceEntity();
    const records = await appointmentBooks
      .getRecords$(migration, 1000)
      .toPromise();
    return sortBy(
      records
        .map((record) => record.data.data)
        .map((record) => ({
          label: record.description,
          value: record.id.toString(),
        })),
      'label'
    );
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument[]> {
    return snapshot(
      query$(
        undeletedQuery(Brand.stafferCol(migration.configuration.brand))
      ).pipe(
        multiMap((staffer) => ({
          name: `${staffer.user.name} - ${staffer.ref.id}`,
          ref: staffer.ref,
        })),
        multiSortBy$(nameSorter())
      )
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const fileName = `appointment-book-mapping`;
    const appointmentStatuses =
      await this._getAppointmentStatusOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    await new XSLXImporterExporter().download(
      fileName,
      appointmentStatuses,
      new AppointmentBookToPractitionerToXSLX(
        await snapshot(Brand.staff$(migration.configuration.brand)),
        await snapshot(this.getRecords$(translationMap))
      )
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToAppointmentBookToPractitioner()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const sourceOptions = await this.getSourceOptions(migration);
    const staff = await snapshot(Brand.staff$(migration.configuration.brand));

    await asyncForEach(items, async (item) => {
      const matchingOption = sourceOptions.find(
        (sourceOption) => sourceOption.value === item.id
      );

      if (!matchingOption) {
        return;
      }

      const label = matchingOption.label;
      const value = matchingOption.value;

      const staffer = staff.find(
        (searchStaffer) => searchStaffer.user.name === item.mapTo
      );

      if (!staffer) {
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: staffer.ref,
          destinationValue: item.mapTo,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getAppointmentStatusOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICorePracticeCalendar[]> {
    const appointmentBookOptions = new CalendarSourceEntity();
    const records = await appointmentBookOptions
      .getRecords$(migration, 10000)
      .toPromise();
    return sortBy(
      uniqBy(records, (record) => record.data.data.id).map(
        (record) => record.data.data
      ),
      'description'
    );
  }
}
