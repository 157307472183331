import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MixedSchema, type VersionedSchema } from '@principle-theorem/editor';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { ChartedItem, ClinicalNote } from '@principle-theorem/principle-core';
import {
  type IChartedCondition,
  type IChartedItemConfiguration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  doc$,
  filterUndefined,
  snapshot,
  toISODate,
  toNamedDocument,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ChartDialogService } from '../../../chart-dialog.service';
import { type IEditChartableData } from '../../../chartable-surface-updater';
import { AddMultiTreatmentToProposalProvider } from '../../../charted-surface/chart/add-multi-treatment-to-proposal-provider';
import { AddTreatmentToProposalProvider } from '../../../charted-surface/chart/add-treatment-to-proposal-provider';
import { ChartedSurfaceEventHandler } from '../../../charted-surface/charted-surface-event-handler';
import {
  ChartedItemNotesDialogComponent,
  type IChartedItemNotesDialogData,
} from '../charted-item-notes-dialog/charted-item-notes-dialog.component';
import {
  TreatConditionDialogComponent,
  type ITreatConditionData,
  type ITreatConditionResult,
} from '../treat-condition-dialog/treat-condition-dialog.component';

@Component({
  selector: 'pr-charted-condition-card',
  templateUrl: './charted-condition-card.component.html',
  styleUrls: ['./charted-condition-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartedConditionCardComponent {
  condition$ = new ReplaySubject<IChartedCondition>(1);
  config$: Observable<WithRef<IChartedItemConfiguration>>;
  chartingAs$: Observable<WithRef<IStaffer>>;
  canEdit$: Observable<boolean>;
  noteTooltipText$: Observable<string>;
  @Input() highlighted = false;
  @Output()
  conditionChange = new EventEmitter<IChartedCondition>();
  @Output()
  conditionDelete = new EventEmitter<IChartedCondition>();
  @Output() updateConditionSurfaces = new EventEmitter<IEditChartableData>();
  @Input() disabled: boolean = false;

  @Input()
  set condition(condition: IChartedCondition) {
    if (condition) {
      this.condition$.next(condition);
    }
  }

  constructor(
    private _dialog: MatDialog,
    private _chartStore: ChartFacade,
    private _currentScopeFacade: CurrentScopeFacade,
    private _chartDialogService: ChartDialogService
  ) {
    this.config$ = this.condition$.pipe(
      switchMap((condition) => doc$(condition.config.ref))
    );
    this.chartingAs$ = this._chartStore.chartingAs$(ChartId.InAppointment);
    this.canEdit$ = this._chartStore.canEdit$(ChartId.InAppointment);

    this.noteTooltipText$ = this.condition$.pipe(
      map((condition) => {
        const note = condition.notes.length > 1 ? 'notes' : 'note';
        return `${condition.notes.length} ${note}`;
      })
    );
  }

  async addTreatment(condition: IChartedCondition): Promise<void> {
    const chartingAs = await snapshot(
      this._chartStore.chartingAs$(ChartId.InAppointment)
    );

    const brand = await snapshot(
      this._currentScopeFacade.currentBrand$.pipe(filterUndefined())
    );

    const data: ITreatConditionData = {
      condition,
      brand,
      chartingAs,
    };

    const treatmentResult = await this._dialog
      .open<
        TreatConditionDialogComponent,
        ITreatConditionData,
        ITreatConditionResult
      >(TreatConditionDialogComponent, DialogPresets.medium({ data }))
      .afterClosed()
      .toPromise();

    if (!treatmentResult) {
      return;
    }

    const feeSchedule = await snapshot(
      this._chartStore.getFeeScheduleManager().currentSchedule$
    );

    const chartedRefs = treatmentResult.chartedSurfaces.map(
      (surface) => surface.chartedRef
    );

    const addSurfaceProviders = [
      new AddTreatmentToProposalProvider(this._chartStore, feeSchedule),
      new AddMultiTreatmentToProposalProvider(
        this._chartStore,
        this._chartDialogService,
        toNamedDocument(feeSchedule)
      ),
    ];
    const chartedSurface = new ChartedSurfaceEventHandler(
      await snapshot(this.chartingAs$),
      addSurfaceProviders
    );

    for (let index = 0; index < treatmentResult.treatments.length; index++) {
      const treatment = treatmentResult.treatments[index];
      await chartedSurface.addChartable(treatment, chartedRefs);
    }
  }

  delete(condition: IChartedCondition): void {
    this.conditionDelete.emit(condition);
  }

  async resolve(
    condition: IChartedCondition,
    owner?: WithRef<IStaffer>
  ): Promise<void> {
    await this._chartStore.resolveChartedItem(
      ChartId.InAppointment,
      condition,
      owner
    );
  }

  async openNoteDialog(condition: IChartedCondition): Promise<void> {
    const content = await this._dialog
      .open<
        ChartedItemNotesDialogComponent,
        IChartedItemNotesDialogData | undefined,
        VersionedSchema
      >(ChartedItemNotesDialogComponent, DialogPresets.small())
      .afterClosed()
      .toPromise();
    if (!content) {
      return;
    }
    await this._addNote(condition, content);
  }

  isResolved(condition: IChartedCondition): boolean {
    return ChartedItem.isResolved(condition);
  }

  async editSurfaces(item: IChartedCondition): Promise<void> {
    const chartable = await snapshot(this.config$);
    const selectedSurfaces = await this._chartDialogService.getSurfaces(
      chartable,
      undefined,
      item
    );
    if (!selectedSurfaces) {
      return;
    }
    const chartingAs = await snapshot(
      this._chartStore.chartingAs$(ChartId.InAppointment)
    );
    this.updateConditionSurfaces.emit({
      chartable,
      selectedSurfaces,
      item,
      chartingAs,
    });
  }

  private async _addNote(
    condition: IChartedCondition,
    content: MixedSchema
  ): Promise<void> {
    const chartingAs: WithRef<IStaffer> = await snapshot(
      this._chartStore.chartingAs$(ChartId.InAppointment)
    );
    this.conditionChange.emit({
      ...condition,
      notes: [
        ...condition.notes,
        ClinicalNote.init({
          content,
          owner: toNamedDocument({ ...chartingAs, name: chartingAs.user.name }),
          recordDate: toISODate(toTimestamp()),
        }),
      ],
    });
  }
}
