import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { IPendingFollowUp } from '../../pending-follow-up';

@Component({
  selector: 'pr-follow-up-list-items',
  templateUrl: './follow-up-list-items.component.html',
  styleUrl: './follow-up-list-items.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FollowUpListItemsComponent {
  followUps$ = new ReplaySubject<IPendingFollowUp[]>(1);

  @Input()
  set followUps(followUps: IPendingFollowUp[]) {
    if (followUps) {
      this.followUps$.next(followUps);
    }
  }

  @Input() hideDate = false;
}
