import { type IChartData } from './chart-builder';
import {
  type IChartConfig,
  type IDataBuilder,
} from '@principle-theorem/reporting';
import { GenericDataTransformer } from './data-transformers/google-charts/generic-data-transformer';
import { type IChartContents } from './measure-data-interfaces';

export abstract class BaseChart {
  dataTransformer: GenericDataTransformer = new GenericDataTransformer();
  abstract data: IChartData;
  // TODO: This  shouldn't be relied on anymore but instead pull data from the transformer
  chartContents: IChartContents;
  dataBuilder: IDataBuilder;
  config: IChartConfig;

  constructor(dataBuilder: IDataBuilder, config: IChartConfig) {
    this.dataBuilder = dataBuilder;
    this.config = config;
  }

  async getDataTable(): Promise<google.visualization.DataTable> {
    return this.dataTransformer.transform(this.config, this.dataBuilder);
  }
}
