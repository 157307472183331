import { ChangeDetectionStrategy, Component } from '@angular/core';
import { type ChartBuilder } from '../../../models/report/charts/chart-builder';
import {
  type CanBeChartedProperty,
  FactTables,
  type ICanGroupMeasuresProperty,
} from '@principle-theorem/reporting';
import { MeasureDataChartComponent } from '../../core/measure-data-chart-component';
import { CustomChartType } from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-patient-demographics',
  templateUrl: './patient-demographics.component.html',
  styleUrls: ['./patient-demographics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientDemographicsComponent extends MeasureDataChartComponent {
  ageChartBuilder: ChartBuilder;
  distanceChartBuilder: ChartBuilder;
  genderChartBuilder: ChartBuilder;
  newVsExistingChartBuilder: ChartBuilder;

  protected _redraw(): void {
    this.ageChartBuilder = this._histogram(
      FactTables.appointmentEvent.patient.age,
      FactTables.appointmentEvent.brand.name
    );
    this.distanceChartBuilder = this._histogram(
      FactTables.appointmentEvent.patient.distance,
      FactTables.appointmentEvent.brand.name
    );
    this.genderChartBuilder = this._pieChart([
      FactTables.appointmentEvent.patient.gender
        .groupBy(FactTables.appointmentEvent.patient.gender)
        .reduceByCount(),
    ]);
    this.newVsExistingChartBuilder = this._pieChart([
      FactTables.appointmentEvent.newPatient,
      FactTables.appointmentEvent.existingPatient,
    ]);
  }

  protected override _pieChart(measures: CanBeChartedProperty[]): ChartBuilder {
    return this.dataBuilder
      .toPieChart(this._chartMeasures(CustomChartType.Pie, measures))
      .chartBuilder.addChartOptions({ height: 200, width: 200 });
  }

  private _histogram(
    measure: CanBeChartedProperty,
    groupByDimension: ICanGroupMeasuresProperty
  ): ChartBuilder {
    return this.dataBuilder.toHistogram(
      this._chartMeasures(
        CustomChartType.Histogram,
        [measure],
        groupByDimension
      )
    ).chartBuilder;
  }
}
