import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ChartFacade,
  ChartId,
  type IChartContextState,
} from '@principle-theorem/ng-clinical-charting/store';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { ChartedMultiStepTreatment } from '@principle-theorem/principle-core';
import {
  type ChartItemDisplayType,
  type IChartedServiceSmartGroup,
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  type IChartedRef,
  type IChartedTreatment,
  type IPricedServiceCodeEntry,
  type ITreatmentPlanProposal,
  IPricedServiceCodeGroup,
} from '@principle-theorem/principle-core/interfaces';
import { shareReplayCold } from '@principle-theorem/shared';
import { type IEditChartableData } from '../../../../chartable-surface-updater';
import { type Observable, ReplaySubject } from 'rxjs';
import { TreatmentProposalFilter } from './treatment-proposal-filter';

@Component({
    selector: 'pr-filtered-flagged-treatment',
    templateUrl: './filtered-flagged-treatment.component.html',
    styleUrls: ['./filtered-flagged-treatment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FilteredFlaggedTreatmentComponent {
  selectedSurfaces$: ReplaySubject<Partial<IChartedRef>> = new ReplaySubject(1);
  trackByTreatment = TrackByFunctions.uniqueId<IChartedTreatment>();
  trackByMultiTreatment =
    TrackByFunctions.uniqueId<IChartedMultiStepTreatment>();
  trackByFn: TrackByFunctions = new TrackByFunctions();
  filter: TreatmentProposalFilter = new TreatmentProposalFilter();
  context$: Observable<IChartContextState>;
  @Input() disabled: boolean = false;
  @Output() updateChartable = new EventEmitter<IEditChartableData>();

  constructor(
    private _snackBar: MatSnackBar,
    private _chartStore: ChartFacade
  ) {
    this.context$ = this._chartStore
      .chartContextState$(ChartId.InAppointment)
      .pipe(shareReplayCold());
  }

  @Input()
  set filters(filters: ChartItemDisplayType[]) {
    this.filter.filters$.next(filters);
  }

  @Input()
  set proposal(proposal: ITreatmentPlanProposal) {
    if (proposal) {
      this.filter.proposal$.next(proposal);
    }
  }

  @Input()
  set selectedSurfaces(selectedSurfaces: Partial<IChartedRef>) {
    if (selectedSurfaces) {
      this.selectedSurfaces$.next(selectedSurfaces);
    }
  }

  treatmentChange(treatment: IChartedTreatment): void {
    this._chartStore.updateTreatment(ChartId.InAppointment, treatment);
  }

  async multiTreatmentChange(
    step: IChartedMultiStepTreatmentStep,
    multiTreatment: IChartedMultiStepTreatment
  ): Promise<void> {
    await this._chartStore.updateMultiTreatment(
      ChartId.InAppointment,
      ChartedMultiStepTreatment.updateTreatmentStep(multiTreatment, step)
    );
  }

  async updateMultiTreatment(
    multiTreatment: IChartedMultiStepTreatment
  ): Promise<void> {
    await this._chartStore.updateMultiTreatment(
      ChartId.InAppointment,
      multiTreatment
    );
  }

  async updateMultiTreatmentStep(
    step: IChartedMultiStepTreatmentStep,
    multiTreatment: IChartedMultiStepTreatment
  ): Promise<void> {
    await this.updateMultiTreatment(
      ChartedMultiStepTreatment.updateTreatmentStep(multiTreatment, step)
    );
  }

  async removeMultiTreatmentStep(
    step: IChartedMultiStepTreatmentStep,
    multiTreatment: IChartedMultiStepTreatment
  ): Promise<void> {
    await this.updateMultiTreatment(
      ChartedMultiStepTreatment.removeTreatmentStep(multiTreatment, step)
    );
  }

  async addMultiTreatmentStep(
    multiTreatment: IChartedMultiStepTreatment
  ): Promise<void> {
    await this.updateMultiTreatment(
      ChartedMultiStepTreatment.addStep(multiTreatment)
    );
  }

  updateSmartGroups(
    serviceCodeSmartGroups: IChartedServiceSmartGroup[],
    treatment: IChartedTreatment
  ): void {
    treatment.serviceCodeSmartGroups = serviceCodeSmartGroups;
    this.treatmentChange(treatment);
  }

  updateExclusiveGroups(
    serviceCodeGroups: IPricedServiceCodeGroup[],
    treatment: IChartedTreatment
  ): void {
    treatment.serviceCodeGroups = serviceCodeGroups;
    this.treatmentChange(treatment);
  }

  updateServiceCodes(
    serviceCodes: IPricedServiceCodeEntry[],
    treatment: IChartedTreatment
  ): void {
    treatment.serviceCodes = serviceCodes;
    this.treatmentChange(treatment);
  }

  deleteTreatment(treatment: IChartedTreatment): void {
    this._chartStore.removeTreatment(ChartId.InAppointment, treatment.uuid);
    this._snackBar.open(`Treatment removed`);
  }

  deleteMultiTreatment(multiTreatment: IChartedMultiStepTreatment): void {
    this._chartStore.removeMultiTreatment(
      ChartId.InAppointment,
      multiTreatment.uuid
    );
    this._snackBar.open(`Treatment removed`);
  }
}
