import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { ManualTransactionType } from '@principle-theorem/principle-core';
import { type IManualTransactionType } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';

export interface IEditManualTransactionTypeDialogData {
  type?: WithRef<IManualTransactionType>;
}

@Component({
  selector: 'pr-edit-manual-transaction-type-dialog',
  templateUrl: './edit-manual-transaction-type-dialog.component.html',
  styleUrls: ['./edit-manual-transaction-type-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditManualTransactionTypeDialogComponent {
  title: string = 'Add Transaction Type';
  form = new TypedFormGroup<Omit<IManualTransactionType, 'deleted'>>({
    name: new TypedFormControl<string>('', Validators.required),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEditManualTransactionTypeDialogData,
    private _dialogRef: MatDialogRef<EditManualTransactionTypeDialogComponent>
  ) {
    if (data.type) {
      this.title = 'Edit Transaction Type';
      this.form.patchValue(data.type);
    }
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }
    this._dialogRef.close(ManualTransactionType.init(this.form.getRawValue()));
  }
}
