import { IBridgeIntegration } from '../../send-command-to-device';

export const HICAPS_CONNECT_INTEGRATION: IBridgeIntegration = {
  label: 'Hicaps',
  description: 'Integration for quotes, claims and transactions from Hicaps',
};

export enum HicapsConnectToDeviceCommand {
  RunProcess = 'hicapsConnect.runProcess',
}
