<pt-breadcrumbs current="Organisations" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="wrapper">
  <div fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutGap="16px">
      <pt-search-field fxFlex>
        <input matInput placeholder="Search" [formControl]="search" />
      </pt-search-field>
      <button mat-raised-button color="primary" (click)="addOrganisation()">
        Create Organisation
      </button>
      <button
        *ngIf="!isProduction"
        mat-raised-button
        color="primary"
        (click)="addDemoOrganisation()"
      >
        Create Demo Organisation
      </button>
    </div>

    <mat-nav-list>
      <ng-container *ngIf="searchFilter.results$ | async as organisations">
        <a
          *ngFor="
            let organisation of organisations;
            trackBy: trackByOrganisation
          "
          mat-list-item
          [routerLink]="['./', organisation.ref.id]"
        >
          <span matListItemTitle>{{ organisation.name }}</span>
          <span matListItemSubtitle class="mat-caption"
            >Region: {{ organisation.region }}</span
          >
          <div matListItemMeta>
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
              <pr-organisation-status [status]="organisation.status" />
              <mat-icon>chevron_right</mat-icon>
            </div>
          </div>
        </a>
      </ng-container>
    </mat-nav-list>
  </div>
</div>
