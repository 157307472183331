import { type PracticeMigrationEssentials } from '@principle-theorem/principle-core/interfaces';
import { PRAKTIKA_DESTINATION } from './destination';
import { PRAKTIKA_MAPPINGS } from './mappings';
import { PRAKTIKA_SOURCE } from './source';

export const PRAKTIKA_MIGRATION: PracticeMigrationEssentials = {
  metadata: {
    name: 'Praktika',
    version: 'v2',
    supportsMultiplePractices: false,
  },
  source: PRAKTIKA_SOURCE,
  destination: PRAKTIKA_DESTINATION,
  mappings: PRAKTIKA_MAPPINGS,
};
