import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  type RouterStateSnapshot,
  type UrlTree,
  Router,
} from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { OrganisationService } from '../organisation.service';
import { UserPublicIpService } from '@principle-theorem/ng-auth';
import { map, switchMap } from 'rxjs/operators';
import { filterUndefined } from '@principle-theorem/shared';
import { IPracticeIpWhitelist } from '@principle-theorem/principle-core/interfaces';
import { ManagementService } from './management.service';
import {
  IUserRestrictedQueryParams,
  UserRestrictedReason,
} from './is-time-restricted.guard';

@Injectable()
export class IsIpRestrictedGuard {
  constructor(
    private _router: Router,
    private _organisation: OrganisationService,
    private _management: ManagementService,
    private _userIp: UserPublicIpService
  ) {}

  canActivate(
    _: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const whitelistSettings$ = this._organisation.practice$.pipe(
      filterUndefined(),
      map((practice) => practice.restrictions?.ipWhitelist)
    );

    const queryParams: IUserRestrictedQueryParams = {
      reason: UserRestrictedReason.IP,
    };

    return combineLatest([whitelistSettings$, this._management.user$]).pipe(
      switchMap(([settings, managementUser]) => {
        if (managementUser || !settings || !settings.isEnabled) {
          return of(true);
        }

        return this._userIp.getIpAddress$().pipe(
          map((userIp) => {
            if (!userIp || !isIpRestricted(settings, userIp)) {
              return true;
            }
            return this._router.createUrlTree(['/user-restricted'], {
              queryParams,
            });
          })
        );
      })
    );
  }
}

export function isIpRestricted(
  whitelist: IPracticeIpWhitelist,
  userIp: string
): boolean {
  return (
    whitelist.isEnabled &&
    !!whitelist.whitelist.length &&
    !whitelist.whitelist.includes(userIp)
  );
}
