import {
  type IDentalChartViewTooth,
  type ITooth,
} from '@principle-theorem/principle-core/interfaces';
import { ChartElement, type IChartElement } from './chart-element';

const TOOTH_SPACING = 20;

export interface IChartTooth extends IChartElement {
  tooth: ITooth;
  display: IDentalChartViewTooth;
}

export class ChartTooth extends ChartElement {
  constructor(public tooth: ITooth, public display: IDentalChartViewTooth) {
    super();
    const startingOffset: number = TOOTH_SPACING / 2;
    const indexMultiplier: number = this.tooth.toothRef.quadrantIndex - 1;
    this.width = 200;
    this.height = 420;
    this.xPos = startingOffset + indexMultiplier * (this.width + TOOTH_SPACING);
  }

  override toInterface(): IChartTooth {
    return {
      ...super.toInterface(),
      tooth: this.tooth,
      display: this.display,
    };
  }
}
