<form [formGroup]="referralForm" fxLayout="column">
  <mat-slide-toggle formControlName="hasReferral">
    I have a referral
  </mat-slide-toggle>

  <ng-template #noReferral>
    <ng-container formGroupName="noReferral">
      <mat-form-field>
        <mat-label>Non-Referral Reason</mat-label>
        <mat-select formControlName="referralOverrideTypeCde">
          <mat-option
            *ngFor="
              let option of overrideSelect.options;
              trackBy: overrideSelect.trackBy
            "
            [value]="option.value"
          >
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </ng-template>

  <ng-container
    formGroupName="referral"
    *ngIf="referralForm.value.hasReferral; else noReferral"
  >
    <mat-form-field>
      <mat-label>Referring Provider Number</mat-label>
      <input
        matInput
        formControlName="providerNum"
        minlength="8"
        maxlength="8"
      />
      <mat-hint align="end">
        {{ referralForm.value.referral.providerNum.length || 0 }}/8 Characters
      </mat-hint>
      <mat-error *ngIf="hasProviderError()">
        Must not contain special characters
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Date of Issue</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        [max]="maxDate"
        formControlName="dateOfIssue"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
       />
      <mat-datepicker #picker />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Referral Period</mat-label>
      <mat-select formControlName="periodTypeCde">
        <mat-option
          *ngFor="
            let option of periodSelect.options;
            trackBy: periodSelect.trackBy
          "
          [value]="option.value"
        >
          {{ option.label }}
        </mat-option>
      </mat-select>
      <mat-hint>
        Standard referral from GP is typically 12 months. Specialist is 3
        months.
      </mat-hint>
    </mat-form-field>
  </ng-container>
</form>
