import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { type MatDatepicker } from '@angular/material/datepicker';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  MONTH_YEAR_FORMATS,
  TypedFormControl,
  TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import { type IMedicareCard } from '@principle-theorem/principle-core/interfaces';
import { sanatiseHealthCardNumber } from '@principle-theorem/principle-core';
import { ONLY_NUMBERS, toMoment, toTimestamp } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

interface IMedicareCardForm
  extends Pick<IMedicareCard, 'number' | 'subNumerate'> {
  expiryDate: Moment;
}

@Component({
    selector: 'pr-medicare-card-form',
    templateUrl: './medicare-card-form.component.html',
    styleUrls: ['./medicare-card-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ...MOMENT_DATEPICKER_PROVIDERS,
        { provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR_FORMATS },
    ],
    exportAs: 'prMedicareCardForm',
    standalone: false
})
export class MedicareCardFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  startOfMonth = moment().startOf('month');
  medicareForm = new TypedFormGroup<IMedicareCardForm>({
    number: new TypedFormControl<string>(undefined, [
      Validators.required,
      Validators.pattern(ONLY_NUMBERS),
      Validators.maxLength(10),
    ]),
    subNumerate: new TypedFormControl<string>(undefined, [
      Validators.required,
      Validators.pattern(ONLY_NUMBERS),
      Validators.maxLength(2),
    ]),
    expiryDate: new TypedFormControl<moment.Moment>(undefined, [
      Validators.required,
    ]).withGuard(moment.isMoment),
  });
  @Output() medicareCardChange = new EventEmitter<IMedicareCard>();

  constructor() {
    validFormGroupChanges$(this.medicareForm)
      .pipe(debounceTime(500), takeUntil(this._onDestroy$))
      .subscribe((medicareCard) =>
        this.medicareCardChange.emit({
          ...medicareCard,
          expiryDate: medicareCard.expiryDate
            ? toTimestamp(medicareCard.expiryDate.endOf('month'))
            : undefined,
        })
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  chosenDateHandler(
    dateSelected: Moment,
    datepicker: MatDatepicker<Moment>
  ): void {
    this.medicareForm.controls.expiryDate.setValue(dateSelected);
    datepicker.close();
  }

  @Input()
  set medicareCard(medicareCard: IMedicareCard) {
    if (medicareCard) {
      this.medicareForm.patchValue(
        {
          ...medicareCard,
          subNumerate: sanatiseHealthCardNumber(medicareCard.subNumerate),
          number: sanatiseHealthCardNumber(medicareCard.number),
          expiryDate: medicareCard.expiryDate
            ? toMoment(medicareCard.expiryDate).endOf('month')
            : undefined,
        },
        { emitEvent: false }
      );
    }
  }
}
