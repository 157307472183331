@if (followUps$ | async; as followUps) {
  @if (!followUps.length) {
    <div class="mt-20">
      <pr-empty-state image="list" title="Follow Ups" />
    </div>
  } @else {
    <div
      class="mb-4 mt-6 flex flex-col justify-between gap-2 sm:flex-row sm:items-center"
    >
      <div class="flex items-center gap-2">
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Sort By</mat-label>
          <mat-select [formControl]="sortByCtrl">
            @for (option of sortByOptions; track option) {
              <mat-option [value]="option">
                {{ option | splitCamel | titlecase }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <button
          mat-stroked-button
          class="square-button"
          [matTooltip]="sortTooltip$ | async"
          (click)="toggleSortOrder()"
        >
          <mat-icon>swap_vert</mat-icon>
        </button>
      </div>

      <mat-form-field
        class="!min-w-[250px] bg-white"
        appearance="outline"
        subscriptSizing="dynamic"
      >
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search..." [formControl]="searchCtrl" />
        <div
          *ngIf="searchCtrl.valueChanges | async"
          class="mr-2 flex cursor-pointer items-center"
          (click)="searchCtrl.reset()"
        >
          <mat-icon matSuffix>close</mat-icon>
        </div>
      </mat-form-field>
    </div>

    @if (noResults$ | async) {
      <pr-empty-state
        image="search"
        title="Follow Ups found"
        [searchInputValue]="searchCtrl.value"
      />
    } @else {
      @switch (sortBy$ | async) {
        @case ('date') {
          <pr-follow-up-list-grouped-items
            [hideDate]="true"
            [groups]="followUpsByDate$ | async"
          />
        }
        @case ('lastContacted') {
          <pr-follow-up-list-items
            [followUps]="followUpsByLastContacted$ | async"
          />
        }
        @case ('treatmentCategory') {
          <pr-follow-up-list-grouped-items
            [groups]="followUpsByCategory$ | async"
          />
        }
        @default {
          <pr-follow-up-list-grouped-items
            [hideDate]="true"
            [groups]="followUpsByDate$ | async"
          />
        }
      }
    }
  }
}
