import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'pr-value-with-icon',
    templateUrl: './value-with-icon.component.html',
    styleUrls: ['./value-with-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValueWithIconComponent {}
