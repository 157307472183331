import { type ValidatorFn, Validators } from '@angular/forms';
import { ContactNumberFormGroup } from '@principle-theorem/ng-principle-shared';
import {
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  type Gender,
  type IContactNumber,
  type IFeeSchedule,
  type IPractice,
  type IReferralSource,
  isContactNumber,
  type IStaffer,
  type ITag,
  PatientStatus,
  IAddressMetadata,
} from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  isISODateType,
  type ISODateType,
} from '@principle-theorem/shared';
import { isArray, isEmpty } from 'lodash';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IEditPatientFormData {
  name: string;
  dateOfBirth: ISODateType;
  email: string;
  contactNumbers: IContactNumber[];
  gender: Gender;
  status: PatientStatus;
  address: IAddressMetadata;
  referrer: IReferralSource;
  tags: INamedDocument<ITag>[];
  preferredDentist?: INamedDocument<IStaffer>;
  preferredHygienist?: INamedDocument<IStaffer>;
  preferredPractice?: INamedDocument<IPractice>;
  preferredFeeSchedule?: INamedDocument<IFeeSchedule>;
}

const initialValue = {
  name: '',
  dateOfBirth: undefined,
  email: '',
  contactNumbers: [{ label: '', number: '' }],
  gender: undefined,
  status: PatientStatus.Active,
  address: { address: '' },
  referrer: undefined,
  tags: [],
  preferredDentist: undefined,
  preferredHygienist: undefined,
  preferredPractice: undefined,
  preferredFeeSchedule: undefined,
};

const CONTACT_ERROR_MESSAGE =
  'At least Email or Contact Number is required if patient has no primary contact';

export class EditPatientFormGroup extends TypedFormGroup<IEditPatientFormData> {
  contactDetailsError$: Observable<string | undefined>;

  constructor() {
    super(
      {
        name: new TypedFormControl<string>('', Validators.required),
        dateOfBirth: new TypedFormControl<ISODateType>(undefined).withGuard(
          isISODateType
        ),
        email: new TypedFormControl<string>('', [Validators.email]),
        contactNumbers: new TypedFormArray<IContactNumber>([
          new ContactNumberFormGroup(false),
        ]),
        status: new TypedFormControl<PatientStatus>(
          PatientStatus.Active,
          Validators.required
        ),
        gender: new TypedFormControl<Gender>(undefined, Validators.required),
        address: new TypedFormControl<IAddressMetadata>({ address: '' }),
        referrer: new TypedFormControl<IReferralSource>(undefined),
        tags: new TypedFormControl<INamedDocument<ITag>[]>([]),
        preferredDentist: new TypedFormControl<INamedDocument<IStaffer>>(
          undefined
        ),
        preferredHygienist: new TypedFormControl<INamedDocument<IStaffer>>(
          undefined
        ),
        preferredPractice: new TypedFormControl<INamedDocument<IPractice>>(
          undefined
        ),
        preferredFeeSchedule: new TypedFormControl<
          INamedDocument<IFeeSchedule>
        >(undefined),
      },
      requiredContactDetails()
    );

    this.contactDetailsError$ = this.valueChanges.pipe(
      map(() =>
        this.hasError('contactDetails') ? CONTACT_ERROR_MESSAGE : undefined
      )
    );
  }

  override reset(options?: { emitEvent?: boolean }): void {
    super.reset(initialValue, options);
  }
}

function requiredContactDetails(): ValidatorFn {
  return (control) => {
    const contactNumberControl = control.get('contactNumbers');
    const emailControl = control.get('email');
    if (!contactNumberControl || !emailControl) {
      // eslint-disable-next-line no-null/no-null
      return null;
    }
    const contactValue = contactNumberControl.value as IContactNumber[];
    const hasContactNumber =
      isArray(contactValue) &&
      isContactNumber(contactValue[0]) &&
      !isEmpty(contactValue[0].number);
    if (isEmpty(emailControl.value) && !hasContactNumber) {
      return {
        contactDetails: CONTACT_ERROR_MESSAGE,
      };
    }
    // eslint-disable-next-line no-null/no-null
    return null;
  };
}
