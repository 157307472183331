import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  type IBreadcrumb,
  StorageResponseAPI,
} from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  errorNil,
  filterUndefined,
  type ITimePeriod,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { combineLatest, type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { DateRangeDataBuilder } from '../../../models/report/data-builders/date-range-data-builder';
import { type IReportRequest } from './practitioner-transactions-report/practitioner-transactions-report.component';
import { PractitionerIncomeReportFacade } from './store/practitioner-income.facade';
import { PractitionerIncomeReportType } from './store/practitioner-income.reducers';

@Component({
    selector: 'pr-practitioner-report-income',
    templateUrl: './practitioner-income-report.component.html',
    styleUrls: ['./practitioner-income-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PractitionerIncomeReportComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  // TODO: Build with timezone - CU-251edxw
  from: moment.Moment = moment().startOf('day').subtract({ months: 1 });
  to: moment.Moment = moment().endOf('day');
  dataBuilder: DateRangeDataBuilder;
  breadcrumbs: IBreadcrumb[] = [{ label: 'Reporting' }];
  brands$: Observable<WithRef<IBrand>[]>;
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);
  isTransactionReport$: Observable<boolean>;
  request$: Observable<IReportRequest>;
  runReport$ = new Subject<void>();

  constructor(
    private _organisation: OrganisationService,
    reportFacade: PractitionerIncomeReportFacade,
    scope: CurrentScopeFacade,
    api: StorageResponseAPI
  ) {
    this.dataBuilder = new DateRangeDataBuilder(
      api,
      this.from,
      this.to,
      this._organisation.brand$.pipe(errorNil()),
      this.practice$.asObservable().pipe(map((practice) => [practice]))
    );
    this.brands$ = this._organisation.userBrands$;
    this.isTransactionReport$ = reportFacade.reportType$.pipe(
      map(
        (reportType) =>
          reportType === PractitionerIncomeReportType.ByTransactionDate
      )
    );

    this.request$ = this.runReport$.pipe(
      switchMap(() =>
        combineLatest([
          this.dataBuilder.dateChange,
          this.dataBuilder.practices$,
        ]).pipe(
          take(1),
          map(([dateRange, practices]) => ({
            dateRange,
            practices,
          }))
        )
      )
    );

    scope.currentPractice$
      .pipe(filterUndefined(), take(1), takeUntil(this._onDestroy$))
      .subscribe((practice) => this.practice$.next(practice));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  updateDateRange(dateRange: ITimePeriod): void {
    this.dataBuilder.updateRange(
      toMoment(dateRange.from),
      toMoment(dateRange.to)
    );
  }
}
