import {
  IServiceProvider,
  SERVICE_PROVIDER_OPTIONS,
} from '@principle-theorem/principle-core/interfaces';
import { Region } from '@principle-theorem/shared';

export class ServiceProvider {
  static providersByRegion(region: Region): IServiceProvider[] {
    return SERVICE_PROVIDER_OPTIONS.filter(
      (provider) => provider.region === region
    );
  }
}
