import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type ITag } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';

export type ChipSize = 'sm' | undefined;

@Component({
    selector: 'pr-inline-tags-display',
    templateUrl: './inline-tags-display.component.html',
    styleUrls: ['./inline-tags-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InlineTagsDisplayComponent {
  trackByTag = TrackByFunctions.ref<WithRef<ITag>>();
  @Input() tags: WithRef<ITag>[] = [];
  @Input() size: ChipSize = undefined;
}
