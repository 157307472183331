import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { ExtractTransactionCardDetails } from '@principle-theorem/principle-core';
import {
  IPatient,
  ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  MONTH_YEAR_FORMAT,
  Timestamp,
  toMoment,
  WithRef,
} from '@principle-theorem/shared';
import {
  IUpdateHealthCardsRequest,
  UpdateHealthCardsComponent,
} from './update-health-cards.component';

@Injectable()
export class UpdateHealthCardsService {
  constructor(private _dialog: MatDialog) {}

  factory(patient: WithRef<IPatient>): UpdateHealthCardsRequestFactory {
    return new UpdateHealthCardsRequestFactory(patient);
  }

  async open(request?: UpdateHealthCardsRequestFactory): Promise<void> {
    const data = request?.build();
    if (!data) {
      return;
    }
    await this._dialog
      .open<UpdateHealthCardsComponent, IUpdateHealthCardsRequest, void>(
        UpdateHealthCardsComponent,
        DialogPresets.medium({ data })
      )
      .afterClosed()
      .toPromise();
  }
}

export class UpdateHealthCardsRequestFactory {
  private _request: IUpdateHealthCardsRequest;

  constructor(patient: WithRef<IPatient>) {
    this._request = { patient };
  }

  medicare(
    membershipNumber: string,
    subNumerate: string,
    expiryDate?: Timestamp
  ): this {
    const expiryHasChanged =
      expiryDate &&
      this._toDate(expiryDate) !==
        this._toDate(this._request.patient.medicareCard?.expiryDate);

    const hasChanged =
      this._request.patient.medicareCard?.number !== membershipNumber ||
      this._request.patient.medicareCard?.subNumerate !== subNumerate ||
      expiryHasChanged;
    if (hasChanged) {
      this._request.medicareCard = {
        number: membershipNumber,
        subNumerate,
        expiryDate,
      };
    }
    return this;
  }

  medicareFromTransaction(transaction: ITransaction): this {
    const cardDetails = ExtractTransactionCardDetails.medicareCard(transaction);
    if (!cardDetails) {
      return this;
    }
    return this.medicare(
      cardDetails.number,
      cardDetails.subNumerate,
      cardDetails.expiryDate
    );
  }

  dva(membershipNumber: string, expiryDate?: Timestamp): this {
    const hasChanged =
      this._request.patient.dvaCard?.number !== membershipNumber ||
      this._toDate(this._request.patient.dvaCard?.expiryDate) !==
        this._toDate(expiryDate);
    if (hasChanged) {
      this._request.dvaCard = { number: membershipNumber, expiryDate };
    }
    return this;
  }

  healthFund(
    membershipNumber: string,
    memberNumber: string,
    fundCode?: string
  ): this {
    const hasChanged =
      this._request.patient.healthFundCard?.membershipNumber !==
        membershipNumber ||
      this._request.patient.healthFundCard?.memberNumber !== memberNumber ||
      this._request.patient.healthFundCard?.fundCode !== fundCode;
    if (hasChanged) {
      this._request.healthFundCard = {
        membershipNumber,
        memberNumber,
        fundCode,
      };
    }
    return this;
  }

  healthFundFromTransaction(transaction: ITransaction): this {
    const cardDetails =
      ExtractTransactionCardDetails.healthFundCard(transaction);
    if (!cardDetails) {
      return this;
    }
    return this.healthFund(
      cardDetails.membershipNumber,
      cardDetails.memberNumber,
      cardDetails.fundCode
    );
  }

  fromTransaction(transaction: ITransaction): this {
    this.healthFundFromTransaction(transaction);
    this.medicareFromTransaction(transaction);
    return this;
  }

  build(): IUpdateHealthCardsRequest | undefined {
    const hasChanged =
      !!this._request.medicareCard ||
      !!this._request.dvaCard ||
      !!this._request.healthFundCard;
    return hasChanged ? this._request : undefined;
  }

  private _toDate(date?: Timestamp): string | undefined {
    return date ? toMoment(date).format(MONTH_YEAR_FORMAT) : undefined;
  }
}
