import { IBaseMigrationStaffer } from '../../../interfaces';
import { StafferMappingHandler } from '../../../mappings/staff';
import {
  StafferSourceEntity,
  type ID4WStaffer,
} from '../../source/entities/staff';

export class D4WStafferMappingHandler extends StafferMappingHandler<
  ID4WStaffer,
  StafferSourceEntity
> {
  override sourceEntity = new StafferSourceEntity();
  translateFn = (record: ID4WStaffer): IBaseMigrationStaffer => ({
    id: record.id,
    name: `${record.first_name} ${record.surname}`,
    providerNo: record.provider_no,
  });
}
