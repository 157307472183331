<div class="chart-toolbar-container flex flex-wrap items-center">
  <div class="flex gap-4">
    <pr-charting-history-selector
      *ngIf="showHistorySelector"
      [selected]="chart$ | async"
      (selectedChange)="selectChart($event)"
    />
    <pr-chart-type-selector
      [selected]="chartType$ | async"
      (selectedChange)="setChartType($event)"
    />
  </div>

  <span class="flex-1"></span>

  <div class="flex items-center gap-4">
    <pr-chart-view-selector
      [view]="view$ | async"
      (selectionChange)="selectView($event)"
    />
    <pr-chart-section-selector
      [section]="section$ | async"
      (selectionChange)="selectSection($event)"
    />

    <ng-content />
  </div>
</div>
