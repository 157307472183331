import { type WithRef } from '@principle-theorem/shared';
import { type IStaffer } from '../staffer/staffer';
import { type ITask } from './task';

export interface ITaskFilter {
  name: string;
  icon: string;
  slug: string;
  filter(task: WithRef<ITask>, staffer: WithRef<IStaffer>): boolean;
}
