<div
  class="flex h-full w-full flex-col items-center gap-4 overflow-scroll bg-[#fafafa] px-1 pb-1 pt-4 md:px-4"
>
  <div class="w-full max-w-7xl">
    <router-outlet />
  </div>

  <div class="my-4 flex justify-center">
    <a
      href="https://principle.dental"
      target="_blank"
      class="text-xs !text-gray-500"
    >
      Powered By Principle
    </a>
  </div>
</div>
