import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type ITemplateContext } from '@principle-theorem/principle-core/interfaces';
import { isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IContextPreviewDialogData {
  context: ITemplateContext;
}

@Component({
  selector: 'pr-context-preview-dialog',
  templateUrl: './context-preview-dialog.component.html',
  styleUrls: ['./context-preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextPreviewDialogComponent {
  hasContext$: Observable<boolean>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IContextPreviewDialogData) {
    this.hasContext$ = of(data).pipe(
      map((dialogData) => !isEmpty(dialogData.context))
    );
  }
}
