import { NgModule } from '@angular/core';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { REGION } from '@angular/fire/compat/functions';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FUNCTIONS_REGION } from '@principle-theorem/shared';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { RefItemPipe } from './ref-item.pipe';

@NgModule({
  declarations: [RefItemPipe],
  imports: [
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideAuth(() => getAuth()),
    provideFunctions(() => getFunctions()),
    NgxAuthFirebaseUIModule,
  ],
  exports: [RefItemPipe, NgxAuthFirebaseUIModule],
  providers: [{ provide: REGION, useValue: FUNCTIONS_REGION }],
})
export class NgFirebaseModule {}
