import {
  getTaxRateByRegion,
  roundTo2Decimals,
  TaxRate,
} from '@principle-theorem/accounting';
import {
  Brand,
  creditRefundToLineItem,
  feeToLineItem,
  hasMergeConflicts,
  Invoice,
  Patient,
  stafferToNamedDoc,
  toAccountDetails,
  Transaction,
  TransactionOperators,
  TreatmentPlan,
  treatmentToLineItem,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  IBrand,
  ICustomLineItem,
  IDestinationEntity,
  IDestinationEntityJobRunOptions,
  IDestinationEntityRecord,
  IInvoice,
  IMigratedDataSummary,
  IMigratedWithErrors,
  InvoiceStatus,
  InvoiceType,
  IPracticeMigration,
  ISourceEntityRecord,
  IStaffer,
  ITranslationMap,
  ITreatmentLineItem,
  MergeConflictDestinationEntityRecord,
  MigratedDestinationEntityRecord,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
  type IBasePatient,
  type IGetRecordResponse,
  type IPatient,
  type IPatientContactDetails,
  type IPractice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  asDocRef,
  asyncForEach,
  Firestore,
  getError,
  isINamedDocument,
  isSameRef,
  multiFilter,
  safeCombineLatest,
  snapshot,
  snapshotCombineLatest,
  sortByCreatedAt,
  Timestamp,
  toNamedDocument,
  toTimestamp,
  WithRef,
  type DocumentReference,
  type IIdentifiable,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { compact, first, sortBy, uniq, uniqBy } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import {
  buildExtendedData,
  PATIENT_TRANSACTION_RESOURCE_TYPE,
} from '../../../destination/entities/patient-invoices';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { resolveMappedCode } from '../../../mappings/item-codes';
import { ItemCodeResourceMapType } from '../../../mappings/item-codes-to-xlsx';
import { PRACTICE_MAPPING } from '../../../mappings/practices';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import { PatientCreditNoteLineItemSourceEntity } from '../../source/entities/patient-credit-note-line-items';
import {
  ICorePracticePatientCreditNote,
  ICorePracticePatientCreditNoteFilters,
  ICorePracticePatientCreditNoteTranslations,
  PatientCreditNoteSourceEntity,
} from '../../source/entities/patient-credit-notes';
import {
  ICorePracticePatientInvoiceLineItem,
  ICorePracticePatientInvoiceLineItemFilters,
  ICorePracticePatientInvoiceLineItemTranslations,
  PatientInvoiceLineItemSourceEntity,
} from '../../source/entities/patient-invoice-line-items';
import {
  ICorePracticePatientInvoice,
  ICorePracticePatientInvoiceFilters,
  ICorePracticePatientInvoiceTranslations,
  PATIENT_INVOICE_RESOURCE_TYPE,
  PatientInvoiceSourceEntity,
} from '../../source/entities/patient-invoices';
import {
  ICorePracticePatientPaymentAllocation,
  ICorePracticePatientPaymentAllocationFilters,
  ICorePracticePatientPaymentAllocationTranslations,
  PatientPaymentAllocationSourceEntity,
} from '../../source/entities/patient-payment-allocations';
import { PatientPaymentSourceEntity } from '../../source/entities/patient-payments';
import {
  ICorePracticePatientTreatment,
  ICorePracticePatientTreatmentFilters,
  ICorePracticePatientTreatmentTranslations,
  PatientTreatmentSourceEntity,
} from '../../source/entities/patient-treatments';
import {
  ICorePracticePatient,
  ICorePracticePatientFilters,
  ICorePracticePatientTranslations,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { CorePracticeItemCodeMappingHandler } from '../mappings/item-codes';
import { CorePracticePaymentTypeMappingHandler } from '../mappings/payment-type-to-provider';
import { CorePracticePracticeMappingHandler } from '../mappings/practices';
import { CorePracticeStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';

interface IInvoiceBuildData {
  invoice: IIdentifiable & IInvoice;
  transactions: (IIdentifiable & ITransaction)[];
  conflictSummary?: Omit<
    IPatientInvoiceMergeConflictInvoiceSummary,
    'invoiceRef' | 'transactionRefs'
  >;
}

interface IInvoiceUpdateData {
  invoiceRef: DocumentReference<IInvoice>;
  transactionRefs: DocumentReference<ITransaction>[];
  conflictSummary?: Omit<
    IPatientInvoiceMergeConflictInvoiceSummary,
    'invoiceRef' | 'transactionRefs'
  >;
}

interface IInvoiceSuccessData extends Omit<IInvoiceUpdateData, 'invoiceRef'> {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  invoiceRefs: DocumentReference<IInvoice>[];
  transactionRefs: DocumentReference<ITransaction>[];
}

export interface IPatientInvoiceJobData {
  sourcePatient: IGetRecordResponse<
    ICorePracticePatient,
    ICorePracticePatientTranslations,
    ICorePracticePatientFilters
  >;
  practices: WithRef<ITranslationMap<IPractice>>[];
  brand: WithRef<IBrand>;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  practitioners: WithRef<IStaffer>[];
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[];
  paymentTypes: WithRef<ITranslationMap<object, TransactionProvider>>[];
}

interface IPatientInvoiceMigrationData {
  patientRef: DocumentReference<IPatient>;
  invoices: IInvoiceBuildData[];
}

export enum CorePracticeMergeConflictInvoiceStatus {
  Unresolved = 'unresolved',
  Resolved = 'resolved',
}

export interface IPatientInvoiceMergeConflictInvoiceSummary {
  patientName: string;
  sourceInvoiceId: string;
  invoiceDate: Timestamp;
  invoiceRef: DocumentReference<IInvoice>;
  transactionRefs: DocumentReference<ITransaction>[];
  expectedAmount: number;
  actualAmount: number;
  status: CorePracticeMergeConflictInvoiceStatus;
}

export interface IPatientInvoiceMergeConflictData {
  invoices: IPatientInvoiceMergeConflictInvoiceSummary[];
}

export type ICorePracticePatientInvoiceMergeConflict = IMigratedWithErrors<
  IInvoiceSuccessData,
  IPatientInvoiceMergeConflictData
>;

export const PATIENT_INVOICE_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_INVOICE_RESOURCE_TYPE,
    label: 'Patient Invoices',
    description: ``,
  },
});

export class PatientInvoiceDestinationEntity extends BaseDestinationEntity<
  IInvoiceSuccessData,
  IPatientInvoiceJobData,
  IPatientInvoiceMigrationData,
  ICorePracticePatientInvoiceMergeConflict
> {
  destinationEntity = PATIENT_INVOICE_DESTINATION_ENTITY;

  override filters = [
    new PatientIdFilter<IPatientInvoiceJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  override canMigrateByIdRange = true;

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    invoices: new PatientInvoiceSourceEntity(),
    invoiceLineItems: new PatientInvoiceLineItemSourceEntity(),
    payments: new PatientPaymentSourceEntity(),
    paymentAllocations: new PatientPaymentAllocationSourceEntity(),
    treatments: new PatientTreatmentSourceEntity(),
    creditNotes: new PatientCreditNoteSourceEntity(),
    creditNoteLineItems: new PatientCreditNoteLineItemSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new CorePracticeStafferMappingHandler(),
    practices: new CorePracticePracticeMappingHandler(),
    itemCodes: new CorePracticeItemCodeMappingHandler(),
    paymentTypes: new CorePracticePaymentTypeMappingHandler(),
  };

  sourceCountDataAccessor(
    data: IPatientInvoiceJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IInvoiceSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      safeCombineLatest(
        record.data.invoiceRefs.map((invoiceRef) =>
          Firestore.getDoc(invoiceRef)
        )
      ),
      safeCombineLatest(
        record.data.transactionRefs.map((transactionRef) =>
          Firestore.getDoc(transactionRef)
        )
      ),
    ]).pipe(
      map(([invoices, transactions]) => {
        const data: IMigratedDataSummary[] = [];

        data.push(
          ...invoices.map((invoice) => ({
            label: 'Invoices',
            data: invoice,
          }))
        );

        data.push(
          ...transactions.map((transaction) => ({
            label: 'Transactions',
            data: transaction,
          }))
        );

        return data;
      })
    );
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientInvoiceJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords(translationMap),
      translationMap.getByType<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const practices$ = this.customMappings.practices.getRecords(translationMap);
    const brand$ = PracticeMigration.brand$(migration);
    const practitioners$ = brand$.pipe(
      switchMap((brand) => Firestore.getDocs(Brand.stafferCol(brand)))
    );
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords(translationMap);
    const paymentTypes$ =
      this.customMappings.paymentTypes.getRecords(translationMap);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([
        brand$,
        practices$,
        staff$,
        practitioners$,
        sourceItemCodes$,
        paymentTypes$,
      ]),
    ]).pipe(
      map(
        ([
          sourcePatients,
          [
            brand,
            practices,
            staff,
            practitioners,
            sourceItemCodes,
            paymentTypes,
          ],
        ]) =>
          sourcePatients.map((sourcePatient) => ({
            sourcePatient,
            brand,
            practices,
            staff,
            practitioners,
            sourceItemCodes,
            paymentTypes,
          }))
      )
    );
  }

  getDestinationEntityRecordUid(data: IPatientInvoiceJobData): string {
    return data.sourcePatient.record.uid;
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientInvoiceJobData
  ): Promise<
    | IPatientInvoiceMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients
      .getSourceRecordId(data.sourcePatient.data.data)
      .toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(data.sourcePatient, 'No patient found');
    }

    const organisation = await Firestore.getDoc(
      migration.configuration.organisation.ref
    );
    const taxRate = getTaxRateByRegion(organisation.region);

    try {
      const sourceInvoices = (
        await this.sourceEntities.invoices.filterRecords(
          migration,
          'patientId',
          data.sourcePatient.data.data.id
        )
      ).filter(
        (invoice) => !invoice.data.data.isDeleted && !invoice.data.data.isVoided
      );

      const invoices = await asyncForEach(sourceInvoices, async (invoice) => {
        const invoiceData = await this._buildInvoiceData(
          migration,
          data,
          patientRef,
          data.sourcePatient.data.data,
          invoice,
          translationMap,
          taxRate
        );

        if (!invoiceData) {
          throw new Error(`Invoice ${invoice.record.uid} has no amount`);
        }

        return invoiceData;
      });

      const sourceCreditNotes = (
        await this.sourceEntities.creditNotes.filterRecords(
          migration,
          'patientId',
          data.sourcePatient.data.data.id
        )
      ).filter(
        (creditNote) =>
          !creditNote.data.data.isDeleted && !creditNote.data.data.isVoided
      );

      const creditNotes = await asyncForEach(
        sourceCreditNotes,
        async (creditNote) => {
          const invoiceData = await this._buildCreditNoteData(
            migration,
            data,
            patientRef,
            data.sourcePatient.data.data,
            creditNote,
            translationMap
          );

          if (!invoiceData) {
            throw new Error(`Invoice ${creditNote.record.uid} has no amount`);
          }

          return invoiceData;
        }
      );

      return {
        patientRef,
        invoices: [...invoices, ...creditNotes],
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IPatientInvoiceMigrationData
  ): Promise<IPatientInvoiceMigrationData | undefined> {
    const existingInvoices: IInvoiceBuildData[] = [];

    const invoiceMergeConflicts = await asyncForEach(
      data.invoices,
      async (invoice) => {
        const invoiceRef = await translationMap.getDestination(
          invoice.invoice.uid,
          PATIENT_INVOICE_RESOURCE_TYPE
        );

        const transactionRefs = await asyncForEach(
          invoice.transactions,
          async (transaction) =>
            translationMap.getDestination(
              transaction.uid,
              PATIENT_TRANSACTION_RESOURCE_TYPE
            )
        );

        try {
          const existingInvoice = invoiceRef
            ? await Firestore.getDoc(asDocRef<IInvoice>(invoiceRef))
            : undefined;

          if (!existingInvoice) {
            return;
          }

          const existingTransactions = await asyncForEach(
            compact(transactionRefs),
            (transactionRef) =>
              Firestore.getDoc(asDocRef<ITransaction>(transactionRef))
          );

          const builtData: IInvoiceBuildData = {
            invoice: {
              ...existingInvoice,
              uid: invoice.invoice.uid,
            },
            transactions: sortBy(existingTransactions, 'uid'),
          };

          existingInvoices.push(builtData);

          return hasMergeConflicts(
            {
              ...invoice,
              transactions: sortBy(invoice.transactions, 'uid'),
            },
            builtData
          );
        } catch (error) {
          return false;
        }
      }
    );

    if (invoiceMergeConflicts.some((mergeConflict) => mergeConflict)) {
      return {
        ...data,
        invoices: existingInvoices,
      };
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientInvoiceJobData,
    _migrationData: IPatientInvoiceMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: IPatientInvoiceJobData,
    migrationData: IPatientInvoiceMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const updates = await asyncForEach(
        migrationData.invoices,
        (invoiceData) =>
          this._upsertInvoice(
            invoiceData,
            translationMap,
            migrationData.patientRef
          )
      );

      if (updates.some((update) => update.conflictSummary)) {
        return this._buildMigratedWithErrorsResponse(
          jobData.sourcePatient,
          updates
        );
      }

      return this._buildSuccessResponse(jobData.sourcePatient, updates);
    } catch (error) {
      return this._buildErrorResponse(
        jobData.sourcePatient,
        `Run job failed: ${getError(error)}`
      );
    }
  }

  private _buildSuccessResponse(
    invoice: IGetRecordResponse<
      ICorePracticePatient,
      ICorePracticePatientTranslations
    >,
    updateData: IInvoiceUpdateData[]
  ): IDestinationEntityRecord<IInvoiceSuccessData> &
    MigratedDestinationEntityRecord<IInvoiceSuccessData> {
    const { invoiceRefs, transactionRefs } = updateData.reduce<
      Pick<IInvoiceSuccessData, 'invoiceRefs' | 'transactionRefs'>
    >(
      (acc, current) => {
        return {
          invoiceRefs: [...acc.invoiceRefs, current.invoiceRef],
          transactionRefs: [...acc.transactionRefs, ...current.transactionRefs],
        };
      },
      {
        invoiceRefs: [],
        transactionRefs: [],
      }
    );

    return {
      uid: invoice.record.uid,
      label: invoice.record.label,
      data: {
        sourceRef: invoice.record.ref,
        invoiceRefs,
        transactionRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: invoice.record.ref,
      migratedAt: toTimestamp(),
    };
  }

  private _buildMigratedWithErrorsResponse(
    patient: IGetRecordResponse<
      ICorePracticePatient,
      ICorePracticePatientTranslations
    >,
    updateData: IInvoiceUpdateData[]
  ): ICorePracticePatientInvoiceMergeConflict {
    return {
      ...this._buildSuccessResponse(patient, updateData),
      status: DestinationEntityRecordStatus.MigratedWithErrors,
      failData: {
        invoices: compact(
          updateData.map((update) => {
            if (!update.conflictSummary) {
              return;
            }
            return {
              patientName: update.conflictSummary.patientName,
              sourceInvoiceId: update.conflictSummary.sourceInvoiceId,
              invoiceDate: update.conflictSummary.invoiceDate,
              invoiceRef: update.invoiceRef,
              transactionRefs: update.transactionRefs,
              expectedAmount: update.conflictSummary.expectedAmount,
              actualAmount: update.conflictSummary.actualAmount,
              status: CorePracticeMergeConflictInvoiceStatus.Unresolved,
            };
          })
        ),
      },
    };
  }

  private _buildErrorResponse(
    invoice: IGetRecordResponse<
      ICorePracticePatient,
      ICorePracticePatientTranslations
    >,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: invoice.record.uid,
      label: invoice.record.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: invoice.record.ref,
      errorMessage: errorMessage ?? 'Missing required properties for invoice',
      failData: {
        invoiceRef: invoice.record.ref,
      },
    };
  }

  private async _upsertInvoice(
    invoiceData: IInvoiceBuildData,
    translationMap: TranslationMapHandler,
    patientRef: DocumentReference<IPatient>
  ): Promise<IInvoiceUpdateData> {
    const invoiceDestinationRef = await translationMap.getDestination(
      invoiceData.invoice.uid,
      PATIENT_INVOICE_RESOURCE_TYPE
    );

    const invoiceRef = await FirestoreMigrate.upsertDoc(
      Patient.invoiceCol({
        ref: patientRef,
      }),
      { ...invoiceData.invoice },
      invoiceDestinationRef?.id
    );

    if (!invoiceDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: invoiceData.invoice.uid,
        destinationIdentifier: invoiceRef,
        resourceType: PATIENT_INVOICE_RESOURCE_TYPE,
      });
    }

    const transactionRefs = await asyncForEach(
      invoiceData.transactions,
      async (transaction) => {
        const transactionDestinationRef = await translationMap.getDestination(
          transaction.uid,
          PATIENT_TRANSACTION_RESOURCE_TYPE
        );

        const transactionRef = await FirestoreMigrate.upsertDoc(
          Invoice.transactionCol({
            ref: invoiceRef,
          }),
          transaction,
          transaction.uid
        );

        if (!transactionDestinationRef) {
          await translationMap.upsert({
            sourceIdentifier: transaction.uid,
            destinationIdentifier: transactionRef,
            resourceType: PATIENT_TRANSACTION_RESOURCE_TYPE,
          });
        }

        return transactionRef;
      }
    );

    return {
      invoiceRef,
      transactionRefs,
      conflictSummary: invoiceData.conflictSummary,
    };
  }

  private async _buildInvoiceData(
    migration: WithRef<IPracticeMigration>,
    data: IPatientInvoiceJobData,
    patientRef: DocumentReference<IPatient>,
    sourcePatient: ICorePracticePatient,
    sourceInvoice: IGetRecordResponse<
      ICorePracticePatientInvoice,
      ICorePracticePatientInvoiceTranslations,
      ICorePracticePatientInvoiceFilters
    >,
    translationMap: TranslationMapHandler,
    taxRate: TaxRate
  ): Promise<IInvoiceBuildData | undefined> {
    const sourceLineItems =
      await this.sourceEntities.invoiceLineItems.filterRecords(
        migration,
        'invoiceId',
        sourceInvoice.data.data.id
      );

    const sourcePaymentAllocations =
      await this.sourceEntities.paymentAllocations.filterRecords(
        migration,
        'invoiceId',
        sourceInvoice.data.data.id
      );

    const sourceTreatments = await this.sourceEntities.treatments.filterRecords(
      migration,
      'accountId',
      sourceInvoice.data.data.id
    );

    const practiceIds: (string | number)[] = uniq(
      sourceTreatments.map(
        (sourceTreatment) => sourceTreatment.data.data.locationId
      )
    );

    if (!practiceIds.length) {
      const defaultPractice = first(
        data.practices.map((practice) => practice.sourceIdentifier)
      );
      if (defaultPractice) {
        practiceIds.push(defaultPractice);
      }
    }

    const practiceRef = await this._resolvePracticeRef(
      practiceIds,
      sourceInvoice.data.data.id,
      translationMap
    );

    let conflictSummary: IInvoiceBuildData['conflictSummary'] | undefined;

    const transactions = await getTransactions(
      migration,
      sourceInvoice.data.translations.invoiceDate,
      sourceInvoice.data.data.discount,
      sourcePaymentAllocations,
      practiceRef,
      InvoiceType.Invoice,
      data.paymentTypes
    );

    const treatmentLineItems = await getTreatments(
      data,
      sourceLineItems,
      sourceTreatments,
      translationMap,
      taxRate
    );

    const miscLineItems = getMiscLineItems(
      data,
      sourceLineItems,
      sourceTreatments
    );

    const issuedAt = sourceInvoice.data.translations.invoiceDate;
    const due = sourceInvoice.data.translations.dueDate;

    const invoice = await this._generateInvoice(
      practiceRef,
      patientRef,
      treatmentLineItems,
      miscLineItems,
      issuedAt,
      due,
      sourceInvoice.data.data.id,
      sourceInvoice.data.data.invoiceNo,
      InvoiceType.Invoice
    );

    const paidAmount = roundTo2Decimals(
      sourceInvoice.data.data.paid + (sourceInvoice.data.data.discount || 0)
    );
    const transactionTotal = roundTo2Decimals(
      new TransactionOperators(transactions).sum()
    );

    if (paidAmount !== transactionTotal) {
      conflictSummary = {
        patientName: `${sourcePatient.firstName} ${sourcePatient.lastName}`,
        sourceInvoiceId: sourceInvoice.data.data.invoiceNo.toString(),
        invoiceDate: sourceInvoice.data.translations.invoiceDate,
        expectedAmount: paidAmount,
        actualAmount: transactionTotal,
        status: CorePracticeMergeConflictInvoiceStatus.Unresolved,
      };
    }

    const lastTransaction = new TransactionOperators(transactions)
      .completed()
      .sort(sortByCreatedAt)
      .reverse()
      .last();

    const status = getInvoiceStatus(sourceInvoice.data.data);
    if (status === InvoiceStatus.Paid && lastTransaction) {
      invoice.paidAt = lastTransaction.createdAt;
    }

    Invoice.updateStatus(invoice, status, issuedAt);

    return {
      invoice,
      transactions,
      conflictSummary,
    };
  }

  private async _buildCreditNoteData(
    migration: WithRef<IPracticeMigration>,
    data: IPatientInvoiceJobData,
    patientRef: DocumentReference<IPatient>,
    sourcePatient: ICorePracticePatient,
    sourceCreditNote: IGetRecordResponse<
      ICorePracticePatientCreditNote,
      ICorePracticePatientCreditNoteTranslations,
      ICorePracticePatientCreditNoteFilters
    >,
    translationMap: TranslationMapHandler
  ): Promise<IInvoiceBuildData | undefined> {
    const sourcePaymentAllocations =
      await this.sourceEntities.paymentAllocations.filterRecords(
        migration,
        'creditNoteId',
        sourceCreditNote.data.data.id
      );

    const practiceIds = [sourceCreditNote.data.data.locationId];

    const practiceRef = await this._resolvePracticeRef(
      practiceIds,
      sourceCreditNote.data.data.id,
      translationMap
    );

    let conflictSummary: IInvoiceBuildData['conflictSummary'] | undefined;

    const transactions = await getTransactions(
      migration,
      sourceCreditNote.data.translations.creditNoteDate,
      0,
      sourcePaymentAllocations,
      practiceRef,
      InvoiceType.CreditNote,
      data.paymentTypes
    );

    const issuedAt = sourceCreditNote.data.translations.creditNoteDate;

    const invoice = await this._generateInvoice(
      practiceRef,
      patientRef,
      [],
      [],
      issuedAt,
      issuedAt,
      sourceCreditNote.data.data.id,
      sourceCreditNote.data.data.id,
      InvoiceType.CreditNote
    );

    invoice.items = [
      creditRefundToLineItem(
        'Credit Note',
        sourceCreditNote.data.data.total,
        []
      ),
    ];

    const paidAmount = roundTo2Decimals(sourceCreditNote.data.data.paid);
    const transactionTotal = roundTo2Decimals(
      new TransactionOperators(transactions).sum()
    );

    if (paidAmount !== transactionTotal) {
      conflictSummary = {
        patientName: `${sourcePatient.firstName} ${sourcePatient.lastName}`,
        sourceInvoiceId: sourceCreditNote.data.data.id.toString(),
        invoiceDate: sourceCreditNote.data.translations.creditNoteDate,
        expectedAmount: paidAmount,
        actualAmount: transactionTotal,
        status: CorePracticeMergeConflictInvoiceStatus.Unresolved,
      };
    }

    const lastTransaction = new TransactionOperators(transactions)
      .completed()
      .sort(sortByCreatedAt)
      .reverse()
      .last();

    const status = getCreditNoteStatus(sourceCreditNote.data.data);
    if (status === InvoiceStatus.Paid && lastTransaction) {
      invoice.paidAt = lastTransaction.createdAt;
    }

    Invoice.updateStatus(invoice, status, issuedAt);

    return {
      invoice,
      transactions,
      conflictSummary,
    };
  }

  private async _generateInvoice(
    practiceRef: DocumentReference<IPractice>,
    patientRef: DocumentReference<IPatient>,
    treatmentLineItems: ITreatmentLineItem[],
    miscLineItems: ICustomLineItem[],
    issuedAt: Timestamp,
    due: Timestamp,
    sourceInvoiceId: number,
    invoiceNo: number,
    invoiceType: InvoiceType
  ): Promise<IIdentifiable & IInvoice> {
    const practice = await Firestore.getDoc(practiceRef);
    const primaryContact = await Patient.resolvePrimaryContact(patientRef);
    const resolvedPatient = await Firestore.getDoc(patientRef);
    const onBehalfOf = toAccountDetails(
      resolvedPatient as IBasePatient & IPatientContactDetails
    );
    const invoice = {
      ...Invoice.init({
        from: toAccountDetails(practice),
        to: primaryContact
          ? {
              ...toAccountDetails(primaryContact),
              onBehalfOf,
            }
          : onBehalfOf,
        practice: toNamedDocument(practice),
        items: [...treatmentLineItems, ...miscLineItems],
        createdAt: issuedAt,
        issuedAt,
        due,
        reference: sourceInvoiceId.toString(),
        type: invoiceType,
      }),
      uid: sourceInvoiceId.toString(),
      reference: invoiceNo.toString(),
    };
    return invoice;
  }

  private async _resolvePracticeRef(
    practiceIds: (number | string)[],
    sourceInvoiceId: number,
    translationMap: TranslationMapHandler
  ): Promise<DocumentReference<IPractice>> {
    if (!practiceIds.length) {
      throw new Error(
        `No practices found for treatments in invoice ${sourceInvoiceId}`
      );
    }

    if (practiceIds.length > 1) {
      const mappedPractices = await asyncForEach(
        practiceIds,
        async (practiceId) =>
          translationMap.getDestination<IPractice>(
            practiceId.toString(),
            PRACTICE_MAPPING.metadata.type
          )
      );

      const isSameMappedPractice = uniqBy(
        compact(mappedPractices),
        (practice) => practice.path
      );
      if (!isSameMappedPractice) {
        throw new Error(
          `Multiple practices found for treatments in invoice ${sourceInvoiceId}: ${practiceIds.join(
            ', '
          )}`
        );
      }
    }

    const sourcePracticeId = first(practiceIds);

    if (!sourcePracticeId) {
      throw new Error(
        `No practice found for treatments in invoice ${sourceInvoiceId}`
      );
    }

    const practiceRef = await translationMap.getDestination<IPractice>(
      sourcePracticeId.toString(),
      PRACTICE_MAPPING.metadata.type
    );

    if (!practiceRef) {
      throw new Error(`No practice found for id: ${sourcePracticeId}`);
    }
    return practiceRef;
  }
}

export async function getTransactions(
  migration: WithRef<IPracticeMigration>,
  invoiceDate: Timestamp,
  invoiceDiscount: number | undefined,
  sourcePaymentAllocations: IGetRecordResponse<
    ICorePracticePatientPaymentAllocation,
    ICorePracticePatientPaymentAllocationTranslations,
    ICorePracticePatientPaymentAllocationFilters
  >[],
  practiceRef: DocumentReference<IPractice>,
  invoiceType: InvoiceType,
  paymentTypes: ITranslationMap<object, TransactionProvider>[]
): Promise<(IIdentifiable & ITransaction)[]> {
  const discount = invoiceDiscount
    ? Transaction.init({
        ...Transaction.internalReference(
          TransactionProvider.Discount,
          'discount'
        ),
        type: TransactionType.Incoming,
        status: TransactionStatus.Complete,
        from: '',
        to: '',
        amount: roundTo2Decimals(invoiceDiscount),
        createdAt: invoiceDate,
        practiceRef,
      })
    : undefined;

  const payments = await asyncForEach(
    sourcePaymentAllocations,
    async (sourcePaymentAllocation) => {
      const paymentAllocation = sourcePaymentAllocation.data.data;
      const payment = await new PatientPaymentSourceEntity().getRecord(
        migration,
        paymentAllocation.paymentId
      );

      if (!payment) {
        throw new Error(
          `Payment not found for payment allocation ${paymentAllocation.id}`
        );
      }

      if (payment.data.data.isDeleted) {
        return;
      }

      const uid = paymentAllocation.id.toString();

      const mappedPaymentType = paymentTypes.find(
        (paymentType) =>
          paymentType.sourceIdentifier ===
          payment.data.data.paymentTypeId.toString()
      );

      const provider =
        mappedPaymentType?.destinationValue ?? TransactionProvider.Manual;

      const providerSubType = isINamedDocument(
        mappedPaymentType?.associatedValue
      )
        ? mappedPaymentType?.associatedValue
        : undefined;

      const extendedData = buildExtendedData(
        payment,
        provider,
        providerSubType
      );

      return {
        ...Transaction.init({
          ...Transaction.internalReference(provider, uid),
          description: payment.data.data.paymentTypeName,
          type:
            invoiceType === InvoiceType.Invoice
              ? TransactionType.Incoming
              : TransactionType.Outgoing,
          status: TransactionStatus.Complete,
          from: '',
          to: '',
          amount: roundTo2Decimals(paymentAllocation.amount),
          extendedData,
          createdAt: payment.data.translations.paymentDate,
          practiceRef,
        }),
      };
    }
  );

  return compact([discount, ...payments]);
}

async function getTreatments(
  data: IPatientInvoiceJobData,
  sourceLineItems: IGetRecordResponse<
    ICorePracticePatientInvoiceLineItem,
    ICorePracticePatientInvoiceLineItemTranslations,
    ICorePracticePatientInvoiceLineItemFilters
  >[],
  sourceTreatments: IGetRecordResponse<
    ICorePracticePatientTreatment,
    ICorePracticePatientTreatmentTranslations,
    ICorePracticePatientTreatmentFilters
  >[],
  translationMap: TranslationMapHandler,
  taxRate: TaxRate
): Promise<ITreatmentLineItem[]> {
  return compact(
    await asyncForEach(sourceLineItems, async (sourceLineItem) => {
      const lineItem = sourceLineItem.data.data;
      const treatment = sourceTreatments.find(
        (searchTreatment) =>
          searchTreatment.data.data.id === lineItem.treatmentId
      )?.data.data;

      if (!treatment) {
        throw new Error(`No treatment found for line item ${lineItem.id}`);
      }

      const procedureCode = treatment.itemCode;
      const code = resolveMappedCode(
        data.sourceItemCodes,
        lineItem.itemId.toString(),
        procedureCode
      );

      if (!code) {
        return;
      }

      const patientId = lineItem.patientId;
      const patientRef = await translationMap.getDestination<IPatient>(
        patientId.toString(),
        PATIENT_RESOURCE_TYPE
      );
      if (!patientRef) {
        throw new Error(`No patient for id ${patientId}`);
      }

      const treatmentPlans = await Firestore.getDocs(
        TreatmentPlan.col({ ref: patientRef })
      );

      const treatmentUid = treatment.id.toString();
      const existingTreatment = await snapshot(
        combineLatest(
          treatmentPlans.map((treatmentPlan) =>
            TreatmentPlan.treatmentById$(treatmentPlan, treatmentUid).pipe(
              map((planTreatment) => ({
                treatment: planTreatment,
                treatmentPlan,
              }))
            )
          )
        ).pipe(
          multiFilter((planTreatment) => !!planTreatment.treatment),
          map(first)
        )
      );

      if (!existingTreatment?.treatment) {
        throw new Error(`No treatment found for id ${treatment.id}`);
      }

      const providerId = lineItem.providerId.toString();
      const practitionerMap = data.staff.find(
        (staffer) => staffer.sourceIdentifier === providerId
      )?.destinationIdentifier;

      if (!practitionerMap) {
        throw new Error(`Couldn't resolve practitioner ${providerId ?? ''}`);
      }
      const practitioner = data.practitioners.find((searchPractitioner) =>
        isSameRef(searchPractitioner.ref, practitionerMap)
      );

      if (!practitioner) {
        throw new Error(`Couldn't resolve practitioner ${providerId ?? ''}`);
      }

      return {
        patientRef,
        ...treatmentToLineItem(
          existingTreatment.treatment,
          existingTreatment.treatmentPlan,
          stafferToNamedDoc(practitioner),
          taxRate
        ),
        tax: lineItem.taxAmount ?? 0,
      };
    })
  );
}

function getMiscLineItems(
  data: IPatientInvoiceJobData,
  sourceLineItems: IGetRecordResponse<
    ICorePracticePatientInvoiceLineItem,
    ICorePracticePatientInvoiceLineItemTranslations,
    ICorePracticePatientInvoiceLineItemFilters
  >[],
  sourceTreatments: IGetRecordResponse<
    ICorePracticePatientTreatment,
    ICorePracticePatientTreatmentTranslations,
    ICorePracticePatientTreatmentFilters
  >[]
): ICustomLineItem[] {
  return compact(
    sourceLineItems.map((lineItem) => {
      const treatment = sourceTreatments.find(
        (searchTreatment) =>
          searchTreatment.data.data.id === lineItem.data.data.treatmentId
      )?.data.data;

      if (!treatment) {
        throw new Error(
          `No treatment found for line item ${lineItem.data.data.id}`
        );
      }

      const procedureCode = treatment.itemCode;
      const code = resolveMappedCode(
        data.sourceItemCodes,
        lineItem.data.data.itemId.toString(),
        procedureCode
      );

      if (code) {
        return;
      }

      const description = treatment.itemCodeName
        ? `${treatment.itemCode} - ${treatment.itemCodeName}`
        : treatment.itemCode;
      const cost = roundTo2Decimals(lineItem.data.data.unitAmount);
      return {
        ...feeToLineItem(description, cost),
        quantity: treatment.quantity,
        tax: lineItem.data.data.taxAmount ?? 0,
      };
    })
  );
}

function getInvoiceStatus(invoice: ICorePracticePatientInvoice): InvoiceStatus {
  if (invoice.isVoided) {
    return InvoiceStatus.Cancelled;
  }
  if (invoice.isBadDebt) {
    return InvoiceStatus.WrittenOff;
  }
  if (invoice.isPaid) {
    return InvoiceStatus.Paid;
  }
  return InvoiceStatus.Issued;
}

function getCreditNoteStatus(
  invoice: ICorePracticePatientCreditNote
): InvoiceStatus {
  if (invoice.isVoided) {
    return InvoiceStatus.Cancelled;
  }
  if (invoice.isPaid) {
    return InvoiceStatus.Paid;
  }
  return InvoiceStatus.Issued;
}
