import { type IDestination } from '@principle-theorem/principle-core/interfaces';
import { BaseDestinationHandler } from '../destination/base-destination-handler';
import { PatientAppointmentDestinationEntity } from './destination/entities/patient-appointments';
import { PatientClinicalChartDestinationEntity } from './destination/entities/patient-clinical-charts';
import { PatientClinicalNoteDestinationEntity } from './destination/entities/patient-clinical-notes';
import { PatientDepositDestinationEntity } from './destination/entities/patient-deposits';
import { PatientInteractionDestinationEntity } from './destination/entities/patient-interactions';
import { PatientRelationshipDestinationEntity } from './destination/entities/patient-relationships';
import { PatientTreatmentPlanDestinationEntity } from './destination/entities/patient-treatment-plan';
import { PatientDestinationEntity } from './destination/entities/patients';
import { StafferDestinationEntity } from './destination/entities/staff';
import { PatientSterilisationDestinationEntity } from './destination/entities/sterilisation';
import { PatientInvoiceDestinationEntity } from './destination/entities/patient-invoices';
import { PatientFileDestinationEntity } from './destination/entities/patient-files';
import { PatientMedicalHistoryDestinationEntity } from './destination/entities/patient-medical-histories';

export const CORE_PRACTICE_DESTINATION: IDestination = {
  metadata: {
    name: 'Principle Firestore',
    description: 'Main Principle Migration',
  },
  configuration: [],
};

export class CorePracticeDestinationHandler extends BaseDestinationHandler {
  migrationType = 'Core Practice';
  migrationVersion = 'v1';
  destination = CORE_PRACTICE_DESTINATION;

  entityHandlers = [
    new PatientDestinationEntity(),
    new StafferDestinationEntity(),
    new PatientSterilisationDestinationEntity(),
    new PatientClinicalNoteDestinationEntity(),
    new PatientClinicalChartDestinationEntity(),
    new PatientAppointmentDestinationEntity(),
    new PatientDepositDestinationEntity(),
    new PatientTreatmentPlanDestinationEntity(),
    new PatientRelationshipDestinationEntity(),
    new PatientInteractionDestinationEntity(),
    new PatientInvoiceDestinationEntity(),
    new PatientFileDestinationEntity(),
    new PatientMedicalHistoryDestinationEntity(),
  ];
}
