import {
  ICustomFormConfiguration,
  ICustomFormData,
  IInteractionV2,
  IJsonSchemaFormWithResolverConfig,
  IPatient,
  IPatientForm,
  IPatientFormSchema,
  PATIENT_FORM_NAME,
  PatientFormCollection,
  PatientFormSpecialUid,
  PatientFormStatus,
  PatientFormType,
  PatientRelationshipType,
  isPatientForm,
} from '@principle-theorem/principle-core/interfaces';
import {
  ArchivedDocument,
  AtLeast,
  CollectionReference,
  DocumentReference,
  Firestore,
  IReffable,
  Timestamp,
  Transaction,
  WithRef,
  addDoc,
  all$,
  asReffable,
  isSameRef,
  multiFilter,
  multiSort,
  query$,
  sortByUpdatedAt,
  sortTimestampAsc,
  subCollection,
  toTimestamp,
  undeletedQuery,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Brand } from '../brand';
import { CustomFormConfiguration } from '../custom-forms/custom-form-configuration';
import { getCustomFormData } from '../custom-forms/custom-form-helpers';
import { toMedicalHistoryForm } from '../custom-forms/schema/medical-history-form-schema';
import { StatusHistory } from '../status-history';
import { Patient } from './patient';

export class PatientForm {
  static patientRef(
    form: IReffable<IPatientForm>
  ): DocumentReference<IPatient> {
    return Firestore.getParentDocRef<IPatient>(form.ref);
  }

  static getName(form: WithRef<IPatientForm>): string {
    if (form.template?.name) {
      return form.template.name;
    }
    if (form.ref.id === PatientFormSpecialUid.MedicalHistoryForm) {
      return PATIENT_FORM_NAME[PatientFormSpecialUid.MedicalHistoryForm];
    }
    if (
      form.ref.id === PatientFormSpecialUid.PatientDetailsForm ||
      form.formType === PatientFormType.PatientDetailsForm
    ) {
      return PATIENT_FORM_NAME[PatientFormSpecialUid.PatientDetailsForm];
    }
    return 'Unknown Form';
  }

  static getPatientFormType(form: WithRef<IPatientForm>): PatientFormType {
    if (form.formType) {
      return form.formType;
    }
    if (form.ref.id === PatientFormSpecialUid.PatientDetailsForm) {
      return PatientFormType.PatientDetailsForm;
    }
    return PatientFormType.CustomForm;
  }

  static getJsonSchemaForm(
    form: IPatientFormSchema
  ): IJsonSchemaFormWithResolverConfig | undefined {
    return {
      layout: form?.layout,
      schema: form?.schema,
      dataResolverConfig: form?.dataResolverConfig,
    };
  }

  /**
   * We don't store history for submitted forms as an archive anymore
   * Instead query for other form submissions using the same custom form config
   * @deprecated
   */
  static archiveCol(
    form: WithRef<IPatientForm>
  ): CollectionReference<ArchivedDocument<IPatientForm>> {
    return subCollection<ArchivedDocument<IPatientForm>>(
      form.ref,
      PatientFormCollection.SubmittedFormHistory
    );
  }

  static interactionCol(
    form: IReffable<IPatientForm>
  ): CollectionReference<IInteractionV2> {
    return subCollection<IInteractionV2>(
      form.ref,
      PatientFormCollection.PatientFormInteractions
    );
  }

  static interactions$(
    form: IReffable<IPatientForm>
  ): Observable<WithRef<IInteractionV2>[]> {
    return all$(undeletedQuery(PatientForm.interactionCol(form)));
  }

  static async addInteraction<T extends IPatientForm>(
    form: IReffable<T>,
    interaction: IInteractionV2,
    atomicTransaction?: Transaction
  ): Promise<void> {
    await addDoc(
      PatientForm.interactionCol(form as IReffable<IPatientForm>),
      interaction,
      undefined,
      atomicTransaction
    );
  }

  static allPatientForms$(
    patient: WithRef<IPatient>
  ): Observable<WithRef<IPatientForm>[]> {
    return Patient.withPatientRelationships$(
      patient,
      [PatientRelationshipType.DuplicatePatient],
      Patient.forms$
    ).pipe(multiFilter((form) => !form.deleted));
  }

  static relatedForms$(
    form: WithRef<IPatientForm>
  ): Observable<WithRef<IPatientForm>[]> {
    return from(Firestore.getDoc(PatientForm.patientRef(form))).pipe(
      switchMap((patient) => this.allPatientForms$(patient)),
      multiFilter((patientForm) => this.isRelated(form, patientForm)),
      multiSort(sortByUpdatedAt)
    );
  }

  static isRelated(
    aForm: WithRef<IPatientForm>,
    bForm: WithRef<IPatientForm>
  ): boolean {
    if (this.isPatientDetailsForm(aForm) && this.isPatientDetailsForm(bForm)) {
      return true;
    }
    if (aForm.formType !== bForm.formType) {
      return false;
    }
    if (!aForm.template || !bForm.template) {
      return false;
    }
    return isSameRef(aForm.template.ref, bForm.template.ref);
  }

  /**
   * We don't store history for submitted forms as an archive anymore
   * Instead query for other form submissions using the same custom form config
   * @deprecated
   */
  static archivedSubmittedForms$(
    form: WithRef<IPatientForm>
  ): Observable<WithRef<ArchivedDocument<IPatientForm>>[]> {
    return query$(undeletedQuery(PatientForm.archiveCol(form))).pipe(
      map((forms) =>
        forms
          .filter(
            (history): history is WithRef<ArchivedDocument<IPatientForm>> =>
              isPatientForm(history) && PatientForm.isSubmitted(history)
          )
          .sort((a, b) => sortTimestampAsc(a.form.date, b.form?.date))
          .reverse()
      )
    );
  }

  static updateStatus<T extends IPatientForm>(
    submittedForm: T,
    status: PatientFormStatus,
    updatedAt: Timestamp = toTimestamp()
  ): T {
    submittedForm.status = status;
    submittedForm.statusHistory = StatusHistory.add(
      status,
      submittedForm.statusHistory,
      updatedAt
    );
    return submittedForm;
  }

  static isSubmitted(form: WithRef<IPatientForm>): boolean {
    const submittedStatuses = [
      PatientFormStatus.Submitted,
      PatientFormStatus.Confirmed,
    ];
    return submittedStatuses.includes(form.status);
  }

  static latestSubmittedForm(
    forms: WithRef<IPatientForm>[]
  ): WithRef<IPatientForm<object>> | undefined {
    return this.latest(forms.filter((form) => this.isSubmitted(form)));
  }

  static latest(
    forms: WithRef<IPatientForm>[]
  ): WithRef<IPatientForm> | undefined {
    return first(forms.sort(sortByUpdatedAt));
  }

  static latestIssuedForm(
    forms: WithRef<IPatientForm>[]
  ): WithRef<IPatientForm> | undefined {
    return this.latest(
      forms.filter((form) => form.status === PatientFormStatus.Issued)
    );
  }

  static init(overrides: Partial<IPatientForm> = {}): IPatientForm {
    const status = overrides.status ?? PatientFormStatus.Issued;
    return {
      deleted: false,
      status,
      statusHistory: StatusHistory.add(status),
      formType: PatientFormType.CustomForm,
      form: {},
      ...overrides,
    };
  }

  static markSubmitted<T extends IPatientForm>(
    formData: T,
    formConfig?: WithRef<ICustomFormConfiguration>,
    submittedAt?: Timestamp
  ): T {
    const status = formConfig?.autoConfirm
      ? PatientFormStatus.Confirmed
      : PatientFormStatus.Submitted;
    formData.form.date = submittedAt ?? toTimestamp();
    return this.updateStatus(formData, status, formData.form.date);
  }

  static markConfirmed(
    formData: IPatientForm,
    confirmedAt?: Timestamp
  ): IPatientForm {
    if (!formData.form.date) {
      formData.form.date = confirmedAt ?? toTimestamp();
    }
    return this.updateStatus(
      formData,
      PatientFormStatus.Confirmed,
      confirmedAt
    );
  }

  static async upsertForm(
    patient: IReffable<IPatient>,
    interaction: IInteractionV2,
    form: IPatientForm,
    existing?: WithRef<IPatientForm>
  ): Promise<DocumentReference<IPatientForm>> {
    if (existing && !this.isSubmitted(existing)) {
      const docRef = await Firestore.saveDoc({ ...form, ref: existing.ref });
      await this.addInteraction({ ref: docRef }, interaction);
      return docRef;
    }

    const docRef = await addDoc(Patient.formCol(patient), form);
    await this.addInteraction({ ref: docRef }, interaction);
    return docRef;
  }

  static isMedicalHistoryForm(form: WithRef<IPatientForm>): boolean {
    if (form.formType !== PatientFormType.CustomForm || !form.template) {
      return false;
    }
    const brandRef = Patient.brandRef({ ref: PatientForm.patientRef(form) });
    const formConfigRef = Brand.medicalHistoryFormRef({ ref: brandRef });
    return isSameRef(form.template, formConfigRef);
  }

  static isPatientDetailsForm(form: WithRef<IPatientForm>): boolean {
    return form.formType === PatientFormType.PatientDetailsForm;
  }

  static async getCustomFormConfig(
    form: AtLeast<IPatientForm, 'template'>
  ): Promise<WithRef<ICustomFormConfiguration> | undefined> {
    return form.template ? Firestore.safeGetDoc(form.template.ref) : undefined;
  }

  static async getCustomFormData(
    form: WithRef<IPatientForm>,
    prefillForm: boolean = false
  ): Promise<ICustomFormData<object> | undefined> {
    if (this.isPatientDetailsForm(form)) {
      return;
    }
    const config = await this.getCustomFormConfig(form);
    const jsonSchema = await this.getJsonSchema(form, form.form, config);
    if (!jsonSchema) {
      return;
    }
    if (!prefillForm) {
      return getCustomFormData(jsonSchema);
    }
    const data = await this.getJsonSchemaData(form, form.form);
    return getCustomFormData(jsonSchema, data);
  }

  static async getJsonSchema(
    form: WithRef<IPatientForm>,
    submittedForm?: IPatientFormSchema<object>,
    config?: WithRef<ICustomFormConfiguration>
  ): Promise<IJsonSchemaFormWithResolverConfig | undefined> {
    const jsonSchema = submittedForm
      ? PatientForm.getJsonSchemaForm(submittedForm)
      : undefined;
    if (jsonSchema) {
      return PatientForm.isMedicalHistoryForm(form)
        ? toMedicalHistoryForm(jsonSchema)
        : jsonSchema;
    }

    const configRef = config?.ref ?? form.template?.ref;
    return configRef
      ? CustomFormConfiguration.getJsonSchemaForm(configRef, config)
      : undefined;
  }

  static async getJsonSchemaData(
    form: WithRef<IPatientForm>,
    submittedForm?: IPatientFormSchema<object>
  ): Promise<object | undefined> {
    if (submittedForm?.data) {
      return submittedForm.data;
    }
    if (!form.template) {
      return;
    }
    const patientRef = PatientForm.patientRef(form);

    const forms = await Patient.formsFromTemplate(
      { ref: patientRef },
      form.template.ref
    );
    const lastSubmitted = PatientForm.latestSubmittedForm(forms);
    return lastSubmitted?.form.data;
  }

  static submittedAt(form: WithRef<IPatientForm>): Timestamp | undefined {
    const lastSubmittedLog = StatusHistory.lastLogForStatus(
      form.statusHistory,
      PatientFormStatus.Submitted
    );
    return lastSubmittedLog?.updatedAt;
  }

  static async getPatientFormsUrl(
    patient: IReffable<IPatient>
  ): Promise<string[]> {
    const brand = await Firestore.getDoc(Patient.brandRef(patient));
    return ['/', brand.slug, 'patients', patient.ref.id, 'forms'];
  }

  static async getPatientFormUrl(
    patientForm: IReffable<IPatientForm>
  ): Promise<string[]> {
    const patient = asReffable(this.patientRef(patientForm));
    const formsUrl = await this.getPatientFormsUrl(patient);
    return [...formsUrl, patientForm.ref.id];
  }
}
