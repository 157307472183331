import * as SchedulingActions from './appointment-scheduling.actions';
import * as PatientDetailsActions from './patient-details.actions';
import * as AppointmentDetailsActions from './appointment-details.actions';
import * as AppointmentSelectorActions from './appointment-selector.actions';
import * as AppointmentSuggestionActions from './appointment-suggestions.actions';
import * as CancellationActions from './cancel-appointment.actions';
import * as WaitListActions from './waitlist-details.actions';
import * as AppointmentRequestActions from './appointment-requests.actions';
export {
  SchedulingActions,
  PatientDetailsActions,
  AppointmentDetailsActions,
  AppointmentSelectorActions,
  AppointmentSuggestionActions,
  CancellationActions,
  WaitListActions,
  AppointmentRequestActions,
};
