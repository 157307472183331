import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const EVENT_CLASS_RESOURCE_TYPE = 'eventClasses';

export const EVENT_CLASS_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Event Class List',
    description: '',
    idPrefix: EVENT_CLASS_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ICorePracticeEventClass {
  id: number;
  description: string; // "Check Up and Clean"
  isActive: boolean;
}

export function isCorePracticeEventClass(
  item: unknown
): item is ICorePracticeEventClass {
  return TypeGuard.interface<ICorePracticeEventClass>({
    id: isNumber,
    description: isString,
    isActive: isBoolean,
  })(item);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICorePracticeEventClassTranslations {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICorePracticeEventClassFilters {}

const EVENT_CLASS_SOURCE_QUERY = `
SELECT
  EventClassId AS id,
  Description AS description,
  convert_to_boolean(IsActive) AS is_active
FROM tblEventClass
`;

export class EventClassSourceEntity extends BaseSourceEntity<
  ICorePracticeEventClass,
  ICorePracticeEventClassTranslations,
  ICorePracticeEventClassFilters
> {
  sourceEntity = EVENT_CLASS_SOURCE_ENTITY;
  entityResourceType = EVENT_CLASS_RESOURCE_TYPE;
  sourceQuery = EVENT_CLASS_SOURCE_QUERY;
  verifySourceFn = isCorePracticeEventClass;

  translate(
    _data: ICorePracticeEventClass,
    _timezone: Timezone
  ): ICorePracticeEventClassTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticeEventClass): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticeEventClass): string {
    return `${data.id} - ${data.description}`;
  }

  getFilterData(
    _data: ICorePracticeEventClass,
    _timezone: Timezone
  ): ICorePracticeEventClassFilters {
    return {};
  }
}
