import { rand } from '@ngneat/falso';
import {
  TreatmentMock,
  getServiceCodeNumberString,
} from '@principle-theorem/principle-core';
import {
  ADA_SERVICE_CODES,
  EventType,
  ParticipantType,
} from '@principle-theorem/principle-core/interfaces';
import {
  DimensionsCommon,
  FactsCommon,
  ISerialisedScopedServiceCode,
} from '@principle-theorem/reporting/interfaces';
import {
  BaseMock,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
} from '@principle-theorem/shared';
import {
  BigQueryNamedDocumentMock,
  SerialisedDocumentReferenceMock,
} from '../dimensions/common.mock';

export class BigQueryEventMock
  extends BaseMock
  implements DimensionsCommon.IBigQueryEvent
{
  from = toSerialisedTimestamp(MockTimestamp());
  to = toSerialisedTimestamp(MockTimestamp());
  practice = MockGenerator.generate(BigQueryNamedDocumentMock);
  creator = MockGenerator.generate(BigQueryNamedDocumentMock);
  organiser = MockGenerator.generate(BigQueryNamedDocumentMock);
  participants = [
    {
      ...MockGenerator.generate(BigQueryNamedDocumentMock),
      type: ParticipantType.Patient,
    },
    {
      ...MockGenerator.generate(BigQueryNamedDocumentMock),
      type: ParticipantType.Staffer,
    },
  ];
  type = EventType.Misc;
}

export class BigQueryGapMock
  extends BaseMock
  implements FactsCommon.IBigQueryGap
{
  event = MockGenerator.generate(BigQueryEventMock);
  practitionerRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practitioner = MockGenerator.generate(BigQueryNamedDocumentMock);
}

const treatment = MockGenerator.generate(TreatmentMock);

export class BigQueryChartedTreatmentMock
  extends BaseMock
  implements FactsCommon.IBigQueryChartedTreatment
{
  type = treatment.type;
  scopeRef = treatment.scopeRef;
  serviceCodeSmartGroups = treatment.serviceCodeSmartGroups;
  serviceCodeGroups = treatment.serviceCodeGroups;
  serviceCodes = treatment.serviceCodes;
  invoices = treatment.invoices.map((invoice) => ({
    ...invoice,
    invoiceRef: MockGenerator.generate(SerialisedDocumentReferenceMock),
  }));
  price = treatment.price;
  basePrice = treatment.basePrice;
  feeSchedule = MockGenerator.generate(BigQueryNamedDocumentMock);
  attributedTo = MockGenerator.generate(BigQueryNamedDocumentMock);
  uuid = treatment.uuid;
  config = MockGenerator.generate(BigQueryNamedDocumentMock);
  chartedSurfaces = treatment.chartedSurfaces;
  resolvedBy = MockGenerator.generate(BigQueryNamedDocumentMock);
  resolvedAt = toSerialisedTimestamp(MockTimestamp());
  notes = treatment.notes;
}

const serviceCode: ISerialisedScopedServiceCode = rand(
  ADA_SERVICE_CODES.map((code) => ({
    code: getServiceCodeNumberString(code.code),
    type: code.type,
  }))
);

export class SerialisedScopedServiceCodeMock
  extends BaseMock
  implements ISerialisedScopedServiceCode
{
  code = serviceCode.code;
  type = serviceCode.type;
}
