import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { SelectionListStore } from '@principle-theorem/ng-shared';
import {
  type AnyChartedItemConfiguration,
  type IChartedItem,
  type IChartedItemSelectionSummary,
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  isChartedMultiStepTreatmentStep,
  isChartedTreatmentGroup,
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot, type WithRef } from '@principle-theorem/shared';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

type PlanType =
  | WithRef<ITreatmentPlan>
  | IChartedMultiStepTreatment
  | undefined;

type StepType =
  | WithRef<ITreatmentStep>
  | IChartedMultiStepTreatmentStep
  | undefined;

@Component({
    selector: 'pr-charted-item-toolbar',
    templateUrl: './charted-item-toolbar.component.html',
    styleUrls: ['./charted-item-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChartedItemToolbarComponent {
  item$ = new ReplaySubject<IChartedItem<AnyChartedItemConfiguration>>(1);
  plan$ = new ReplaySubject<PlanType>(1);
  step$ = new ReplaySubject<StepType>(1);
  disabled$ = new BehaviorSubject<boolean>(false);
  isSelected$: Observable<boolean>;
  isCheckboxDisabled$: Observable<boolean>;

  @Output() itemDelete = new EventEmitter<
    IChartedItem<AnyChartedItemConfiguration>
  >();

  @Input()
  set item(item: IChartedItem<AnyChartedItemConfiguration>) {
    if (item) {
      this.item$.next(item);
    }
  }

  @Input()
  set step(step: StepType) {
    this.step$.next(step);
  }

  @Input()
  set plan(plan: PlanType) {
    this.plan$.next(plan);
  }

  @Input()
  set disabled(isDisabled: BooleanInput) {
    this.disabled$.next(coerceBooleanProperty(isDisabled));
    if (!this.selectionList) {
      this.disabled$.next(true);
    }
  }

  constructor(
    @Optional()
    public selectionList?: SelectionListStore<IChartedItemSelectionSummary>
  ) {
    this.isSelected$ = combineLatest([this.item$, this.step$, this.plan$]).pipe(
      switchMap(([item, step, plan]) => {
        if (!this.selectionList) {
          return of(false);
        }

        return this.selectionList.isSelected$({
          item,
          step,
          plan,
        });
      })
    );

    this.isCheckboxDisabled$ = this.step$.pipe(
      switchMap((step) => {
        if (!this.selectionList) {
          return of(false);
        }
        return this.selectionList.selected$.pipe(
          map((selected) => {
            if (!selected.length) {
              return false;
            }
            const stepIdentifier = this._getStepIdentifier(step);
            return !selected
              .map((item) => this._getStepIdentifier(item.step))
              .includes(stepIdentifier);
          })
        );
      })
    );
  }

  async setSelected(
    item: IChartedItem<AnyChartedItemConfiguration>,
    isSelected: boolean
  ): Promise<void> {
    const plan = await snapshot(this.plan$);
    const step = await snapshot(this.step$);

    if (!this.selectionList) {
      return;
    }

    if (isChartedTreatmentGroup(item)) {
      item.treatments.map((_treatment) =>
        this.selectionList?.setSelected(
          {
            item,
            plan,
            step,
          },
          isSelected
        )
      );
      return;
    }
    this.selectionList.setSelected(
      {
        item,
        plan,
        step,
      },
      isSelected
    );
  }

  private _getStepIdentifier(step: StepType): string {
    if (!step) {
      return 'FLAGGED_TREATMENT';
    }
    if (isChartedMultiStepTreatmentStep(step)) {
      return step.uid;
    }
    return step.ref.id;
  }
}
