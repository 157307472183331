import { type DocumentReference } from '@principle-theorem/shared';
import {
  type IFirestoreModel,
  type INamedDocument,
  isEnumValue,
  isINamedDocument,
  isObject,
  type WithRef,
} from '@principle-theorem/shared';
import { isNil, isString } from 'lodash';
import { type IFollowUp } from '../../follow-up/follow-up';
import { type IStatusHistory } from '../../common';
import { type IPatient } from '../../patient/patient';
import { type IStaffer } from '../../staffer/staffer';
import { type IMultiTreatmentConfiguration } from './multi-treatment-configuration';
import { type ITreatmentStep } from './treatment-step';
import { type ITreatmentTemplate } from './treatment-template';
import { type IFeeSchedule } from '../fees/fee-schedule/fee-schedule';

export enum TreatmentPlanAction {
  Created = 'created',
  Deleted = 'deleted',
  Offered = 'offered',
  Accepted = 'accepted',
  Declined = 'declined',
  InProgress = 'inProgress',
  Modified = 'modified',
  Paused = 'paused',
  Completed = 'completed',
}

export enum TreatmentPlanStatus {
  Draft = 'draft',
  Offered = 'offered',
  Accepted = 'accepted',
  Declined = 'declined',
  InProgress = 'inProgress',
  Completed = 'completed',
}

type TreamPlanStatusOrder = Record<TreatmentPlanStatus, number>;

export const TREATMENT_PLAN_STATUS_ORDER_MAP: TreamPlanStatusOrder = {
  [TreatmentPlanStatus.Draft]: 0,
  [TreatmentPlanStatus.Offered]: 1,
  [TreatmentPlanStatus.Accepted]: 2,
  [TreatmentPlanStatus.Declined]: 3,
  [TreatmentPlanStatus.InProgress]: 4,
  [TreatmentPlanStatus.Completed]: 5,
};

export const TREATMENT_PLAN_STATUS_COLOUR_MAP: {
  [key in TreatmentPlanStatus]: string;
} = {
  [TreatmentPlanStatus.Draft]: 'default',
  [TreatmentPlanStatus.Offered]: 'primary',
  [TreatmentPlanStatus.Accepted]: 'accent',
  [TreatmentPlanStatus.Declined]: 'warn',
  [TreatmentPlanStatus.InProgress]: 'accent',
  [TreatmentPlanStatus.Completed]: 'default',
};

export enum TreatmentPlanCollection {
  TreatmentSteps = 'treatmentSteps',
}

export interface INamedTreatmentTemplate
  extends INamedDocument<IMultiTreatmentConfiguration> {
  isTemplate?: boolean;
}

export enum TreatmentPlanType {
  PractitionerProposed = 'Practitioner Proposed',
}

export const TREATMENT_PLAN_STATUSES: TreatmentPlanStatus[] = [
  TreatmentPlanStatus.Draft,
  TreatmentPlanStatus.Offered,
  TreatmentPlanStatus.Accepted,
  TreatmentPlanStatus.InProgress,
  TreatmentPlanStatus.Completed,
  TreatmentPlanStatus.Declined,
];

export interface ITreatmentPlan extends IFirestoreModel {
  name: string;
  steps: DocumentReference<ITreatmentStep>[];
  practitioner?: INamedDocument<IStaffer>;
  status: TreatmentPlanStatus;
  statusHistory: IStatusHistory<TreatmentPlanStatus>[];
  followUpHistory: IFollowUp[];
  type: TreatmentPlanType;
  children: INamedDocument<ITreatmentPlan>[];
  parent?: INamedDocument<ITreatmentPlan>;
  config?: DocumentReference<IMultiTreatmentConfiguration>;
  template?: DocumentReference<ITreatmentTemplate>;
  feeSchedule?: INamedDocument<IFeeSchedule>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITreatmentPlanAggregateTypes<T>
  extends Partial<TreatmentPlanAggregateTypes<T>> {}

type TreatmentPlanAggregateTypes<T> = {
  [key in TreatmentPlanStatus]: T;
};

export interface ITreatmentPlanWithPatient {
  plan: WithRef<ITreatmentPlan>;
  patient: WithRef<IPatient>;
}

export function isTreatmentPlan(data: unknown): data is ITreatmentPlan {
  return (
    isObject(data) &&
    isString(data.name) &&
    'status' in data &&
    isEnumValue(TreatmentPlanStatus, data.status) &&
    'type' in data &&
    (isNil(data.practitioner) || isINamedDocument(data.practitioner))
  );
}
