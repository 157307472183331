import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogData,
  type IConfirmationDialogInput,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  type ILab,
  type ILabJobType,
} from '@principle-theorem/principle-core/interfaces';
import {
  patchDoc,
  resolveProp$,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { isEqual, pick } from 'lodash';
import { type Observable, Subject } from 'rxjs';
import {
  type IAddLabJobTypeDialogData,
  LabJobTypeFormDialogComponent,
} from '../lab-job-type-form-dialog/lab-job-type-form-dialog.component';
import {
  type IUpdateLabJobTypeDialogData,
  UpdateLabJobTypeComponent,
} from '../update-lab-job-type/update-lab-job-type.component';

@Component({
  selector: 'pr-lab-job-types-list',
  templateUrl: './lab-job-types-list.component.html',
  styleUrls: ['./lab-job-types-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabJobTypesListComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByType = TrackByFunctions.field<ILabJobType>('name');
  lab$: Observable<WithRef<ILab>>;

  constructor(private _dialog: MatDialog, private _route: ActivatedRoute) {
    this.lab$ = resolveProp$<WithRef<ILab>>(this._route.data, 'lab');
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async addJobType(): Promise<void> {
    const data: IAddLabJobTypeDialogData = { lab: await snapshot(this.lab$) };
    const config: MatDialogConfig = DialogPresets.medium({ data });
    this._dialog.open(LabJobTypeFormDialogComponent, config);
  }

  async deleteType(labJobType: ILabJobType): Promise<void> {
    const data: IConfirmationDialogData = confirmationDialogData({
      title: 'Delete Lab Job Type',
      prompt: `Are you sure you want to delete the lab job type "${labJobType.name}"?`,
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!confirmed) {
      return;
    }

    const lab = await snapshot(this.lab$);
    const index: number = this._findLabJobType(lab, labJobType);
    if (index < 0) {
      return;
    }

    lab.labJobTypes.splice(index, 1);
    await patchDoc(lab.ref, {
      labJobTypes: lab.labJobTypes,
    });
  }

  async editType(labJobType: ILabJobType): Promise<void> {
    const lab = await snapshot(this.lab$);
    const data: IUpdateLabJobTypeDialogData = {
      lab,
      labJobType,
    };

    const index: number = this._findLabJobType(lab, labJobType);
    if (index < 0) {
      return;
    }

    const config: MatDialogConfig = DialogPresets.medium({ data });
    const updatedLabJobType = await this._dialog
      .open<
        UpdateLabJobTypeComponent,
        IUpdateLabJobTypeDialogData,
        ILabJobType
      >(UpdateLabJobTypeComponent, config)
      .afterClosed()
      .toPromise();
    if (!updatedLabJobType) {
      return;
    }

    await patchDoc(lab.ref, {
      labJobTypes: lab.labJobTypes.map((currentLabJobType, currentIndex) => {
        if (index !== currentIndex) {
          return currentLabJobType;
        }
        return updatedLabJobType;
      }),
    });
  }

  private _findLabJobType(lab: WithRef<ILab>, labJobType: ILabJobType): number {
    return lab.labJobTypes.findIndex((currentLabJobType) =>
      isEqual(
        pick(currentLabJobType, ['name', 'cost']),
        pick(labJobType, ['name', 'cost'])
      )
    );
  }
}
