import { type CollectionCreateSchema } from 'typesense/lib/Typesense/Collections';
import { PrincipleTypesenseCollection } from './typesense';
import { PATIENT_COLLECTION_SCHEMA } from './typesense-patient';
import { TASK_COLLECTION_SCHEMA } from './typesense-task';
import { WAIT_LIST_COLLECTION_SCHEMA } from './typesense-wait-list';

export const PRINCIPLE_TYPESENSE_COLLECTION_MAP: Record<
  PrincipleTypesenseCollection,
  CollectionCreateSchema
> = {
  [PrincipleTypesenseCollection.Patients]: PATIENT_COLLECTION_SCHEMA,
  [PrincipleTypesenseCollection.Tasks]: TASK_COLLECTION_SCHEMA,
  [PrincipleTypesenseCollection.WaitLists]: WAIT_LIST_COLLECTION_SCHEMA,
};
