import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IRelativeSchedulingRules } from '@principle-theorem/principle-core/interfaces';

@Component({
    selector: 'pr-relative-scheduling-summary',
    templateUrl: './relative-scheduling-summary.component.html',
    styleUrls: ['./relative-scheduling-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RelativeSchedulingSummaryComponent {
  @Input() rules: IRelativeSchedulingRules;
}
