import { rand } from '@ngneat/falso';
import {
  InvoiceLineItemType,
  InvoiceStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  DimensionsCommon,
  IInvoiceDimension,
} from '@principle-theorem/reporting/interfaces';
import {
  getEnumValues,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
  toTimestamp,
} from '@principle-theorem/shared';
import { random } from 'lodash';
import { v4 as uuid } from 'uuid';
import {
  BigQueryFirestoreModelMock,
  BigQueryNamedDocumentMock,
  MockBigQueryStatusHistory,
  SerialisedDocumentReferenceMock,
} from './common.mock';
import {
  InvoiceMock,
  TreatmentLineItemMock,
  ServiceLineItemMock,
  DepositLineItemMock,
  ProductLineItemMock,
  TreatmentBasePriceLineItemMock,
  RefundLineItemMock,
  FeeLineItemMock,
} from '@principle-theorem/principle-core';

const invoice = MockGenerator.generate(InvoiceMock);
const treatmentLineItem = MockGenerator.generate(TreatmentLineItemMock);

class BigQueryTreatmentBaseLineItemMock
  extends BigQueryFirestoreModelMock
  implements DimensionsCommon.ITreatmentBasePriceLineItem
{
  type = treatmentLineItem.type;
  uid = treatmentLineItem.uid;
  description = treatmentLineItem.description;
  amount = treatmentLineItem.amount;
  taxStatus = treatmentLineItem.taxStatus;
  tax = treatmentLineItem.tax;
  quantity = treatmentLineItem.quantity;
  treatmentRef = {
    planRef: MockGenerator.generate(SerialisedDocumentReferenceMock),
    treatmentUuid: uuid(),
    attributedTo: MockGenerator.generate(BigQueryNamedDocumentMock),
  };
}

export class BigQueryTreatmentLineItemMock
  extends BigQueryFirestoreModelMock
  implements DimensionsCommon.IBigQueryTreatmentLineItem
{
  type = treatmentLineItem.type;
  uid = treatmentLineItem.uid;
  description = treatmentLineItem.description;
  amount = treatmentLineItem.amount;
  taxStatus = treatmentLineItem.taxStatus;
  tax = treatmentLineItem.tax;
  quantity = treatmentLineItem.quantity;
  items = {
    [InvoiceLineItemType.TreatmentBasePrice]: [
      MockGenerator.generate(BigQueryTreatmentBaseLineItemMock),
      MockGenerator.generate(BigQueryTreatmentBaseLineItemMock),
    ],
    [InvoiceLineItemType.ServiceCode]: [
      MockGenerator.generate(ServiceLineItemMock),
      MockGenerator.generate(ServiceLineItemMock),
    ],
  };
  treatmentRef = {
    planRef: MockGenerator.generate(SerialisedDocumentReferenceMock),
    treatmentUuid: uuid(),
    attributedTo: MockGenerator.generate(BigQueryNamedDocumentMock),
  };
}

const depositLineItem = MockGenerator.generate(DepositLineItemMock);
export class BigQueryDepositLineItemMock
  extends BigQueryFirestoreModelMock
  implements DimensionsCommon.IBigQueryDepositLineItem
{
  type = depositLineItem.type;
  uid = depositLineItem.uid;
  description = depositLineItem.description;
  amount = depositLineItem.amount;
  taxStatus = depositLineItem.taxStatus;
  tax = depositLineItem.tax;
  quantity = depositLineItem.quantity;
  max = depositLineItem.max;
  treatments = [MockGenerator.generate(BigQueryNamedDocumentMock)];
}

const lineItems: DimensionsCommon.IInvoiceDimensionItems = {
  [InvoiceLineItemType.Product]: [
    MockGenerator.generate(ProductLineItemMock),
    MockGenerator.generate(ProductLineItemMock),
  ],
  [InvoiceLineItemType.Treatment]: [
    MockGenerator.generate(BigQueryTreatmentLineItemMock),
    MockGenerator.generate(BigQueryTreatmentLineItemMock),
  ],
  [InvoiceLineItemType.TreatmentBasePrice]: [
    MockGenerator.generate(TreatmentBasePriceLineItemMock),
    MockGenerator.generate(TreatmentBasePriceLineItemMock),
  ],
  [InvoiceLineItemType.ServiceCode]: [
    MockGenerator.generate(ServiceLineItemMock),
    MockGenerator.generate(ServiceLineItemMock),
  ],

  [InvoiceLineItemType.Deposit]: [
    MockGenerator.generate(DepositLineItemMock),
    MockGenerator.generate(DepositLineItemMock),
  ],
  [InvoiceLineItemType.Refund]: [
    MockGenerator.generate(RefundLineItemMock),
    MockGenerator.generate(RefundLineItemMock),
  ],
  [InvoiceLineItemType.Fee]: [
    MockGenerator.generate(FeeLineItemMock),
    MockGenerator.generate(FeeLineItemMock),
  ],
};

export class InvoiceDimensionMock
  extends BigQueryFirestoreModelMock
  implements IInvoiceDimension
{
  timestamp = toSerialisedTimestamp(toTimestamp());
  reference = uuid();
  status = rand([
    InvoiceStatus.Issued,
    InvoiceStatus.Issued,
    InvoiceStatus.Paid,
    InvoiceStatus.Paid,
    InvoiceStatus.Paid,
    InvoiceStatus.Paid,
    InvoiceStatus.Paid,
    ...getEnumValues(InvoiceStatus),
  ]);
  due = toSerialisedTimestamp(invoice.due);
  practice = MockGenerator.generate(BigQueryNamedDocumentMock);
  from = invoice.from;
  to = invoice.to;
  statusHistory = [MockBigQueryStatusHistory(InvoiceStatus.Draft)];
  items = lineItems;
  claims = invoice.claims.map((claim) => ({
    ...claim,
    practitioner: MockGenerator.generate(BigQueryNamedDocumentMock),
    transactions: claim.transactions.map((_transaction) =>
      MockGenerator.generate(SerialisedDocumentReferenceMock)
    ),
    items: claim.items.map((claimItem) => ({
      ...claimItem,
      provider: MockGenerator.generate(BigQueryNamedDocumentMock),
    })),
  }));
  subTotal = random(1, 60);
  tax = random(1, 6);
  total = random(1, 60);
}

export function MockCancelledInvoiceProps(): Pick<
  IInvoiceDimension,
  'status' | 'statusHistory' | 'cancelledAt' | 'cancellation'
> {
  return {
    status: InvoiceStatus.Cancelled,
    statusHistory: [
      MockBigQueryStatusHistory(InvoiceStatus.Draft),
      MockBigQueryStatusHistory(InvoiceStatus.Issued),
      MockBigQueryStatusHistory(InvoiceStatus.Cancelled),
    ],
    cancelledAt: toSerialisedTimestamp(invoice.cancelledAt),
    cancellation: {
      reason: invoice.cancellation.reason,
      replacement: MockGenerator.generate(SerialisedDocumentReferenceMock),
    },
  };
}

export function MockPaidInvoiceProps(): Pick<
  IInvoiceDimension,
  'status' | 'statusHistory' | 'paidAt'
> {
  return {
    status: InvoiceStatus.Paid,
    statusHistory: [
      MockBigQueryStatusHistory(InvoiceStatus.Draft),
      MockBigQueryStatusHistory(InvoiceStatus.Issued),
      MockBigQueryStatusHistory(InvoiceStatus.Paid),
    ],
    paidAt: toSerialisedTimestamp(MockTimestamp()),
  };
}

export function MockIssuedInvoiceProps(): Pick<
  IInvoiceDimension,
  'status' | 'statusHistory'
> {
  return {
    status: InvoiceStatus.Paid,
    statusHistory: [
      MockBigQueryStatusHistory(InvoiceStatus.Draft),
      MockBigQueryStatusHistory(InvoiceStatus.Issued),
      MockBigQueryStatusHistory(InvoiceStatus.Paid),
    ],
  };
}
