import { ToothNumberConversion } from '@principle-theorem/principle-core';
import {
  IExpectedSourceRecordSize,
  SourceEntityMigrationType,
  isToothNumber,
  type IPracticeMigration,
  type ISourceEntity,
  type ToothNumber,
} from '@principle-theorem/principle-core/interfaces';
import {
  TypeGuard,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, flow, isBoolean, isNull, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { runQuery } from '../../../source/connection';
import { SourceEntity } from '../../../source/source-entity';
import { D4WToothSurface, convertToToothSurfaces } from './patient-treatment';
import { OFFSET_PLACEHOLDER } from '../../../source/source-helpers';

export const PATIENT_PRE_EXISTING_TREATMENT_RESOURCE_TYPE =
  'patientPreExistingTreatment';

export const PATIENT_PRE_EXISTING_TREATMENT_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Pre-existing Treatment List',
      description: '',
      idPrefix: PATIENT_PRE_EXISTING_TREATMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

interface ID4WPatientPreExistingTreatmentResult {
  id: number;
  patient_id: number;
  tooth_ref: ToothNumber | null;
  tooth_surface: string;
  times: number | null;
  surface_list_numbers: string | null;
  is_baby_tooth: boolean;
  ref_number: number | string | null;
  ref_status: string | null;
  item_id: number;
  item_code: string;
  description: string;
  chart_id: number;
}

export interface ID4WPatientPreExistingTreatment
  extends Omit<ID4WPatientPreExistingTreatmentResult, 'tooth_surface'> {
  tooth_surface: D4WToothSurface[];
}

export function isD4WPatientPreExistingTreatment(
  item: unknown
): item is ID4WPatientPreExistingTreatment {
  return TypeGuard.interface<ID4WPatientPreExistingTreatment>({
    id: isNumber,
    patient_id: isNumber,
    tooth_ref: TypeGuard.undefinedOr(TypeGuard.isOneOf(isToothNumber, isNull)),
    tooth_surface: [
      TypeGuard.arrayOf(TypeGuard.enumValue(D4WToothSurface)),
      isNull,
    ],
    times: [isNumber, isNull],
    surface_list_numbers: [isString, isNull],
    is_baby_tooth: isBoolean,
    ref_number: [isNumber, isString, isNull],
    ref_status: [isString, isNull],
    item_id: isNumber,
    item_code: isString,
    description: isString,
    chart_id: isNumber,
  })(item);
}

export interface ID4WPatientPreExistingTreatmentFilters {
  patientId: string;
  itemId: string;
  chartId: string;
}

const PATIENT_PRE_EXISTING_TREATMENT_QUERY = `SELECT * FROM (
  SELECT
    chart_status_id AS id,
    patient_id,
    NULLIF(tooth::TEXT, '') AS tooth_ref,
    NULLIF(surface, '') AS tooth_surface,
    NULLIF(surf_list_numbers, '') AS surface_list_numbers,
    times,
    convert_to_boolean(baby) AS is_baby_tooth,
    ref_number,
    NULLIF(ref_status, '') AS ref_status,
    item_id,
    chart_id
  FROM chart_status
  WHERE
    chart_status_id IS NOT NULL AND
    ref_status != 'D'
  ORDER BY chart_status_id
  ${OFFSET_PLACEHOLDER}
) AS pre_existing_treatments
INNER JOIN (
  SELECT
    item_id,
    item AS item_code,
    description
  FROM procedures
) AS item_codes
ON pre_existing_treatments.item_id = item_codes.item_id
`;

const PATIENT_PRE_EXISTING_TREATMENT_ESTIMATE_QUERY = `SELECT * FROM (
  SELECT
    NULLIF(surface, '') AS tooth_surface,
    item_id
  FROM chart_status
  WHERE
    chart_status_id IS NOT NULL AND
    ref_status != 'D'
) AS pre_existing_treatments
INNER JOIN (
  SELECT
    item_id
  FROM procedures
) AS item_codes
ON pre_existing_treatments.item_id = item_codes.item_id
`;

export class PatientPreExistingTreatmentSourceEntity extends BaseSourceEntity<
  ID4WPatientPreExistingTreatment,
  unknown,
  ID4WPatientPreExistingTreatmentFilters
> {
  sourceEntity = PATIENT_PRE_EXISTING_TREATMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_PRE_EXISTING_TREATMENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_PRE_EXISTING_TREATMENT_QUERY;
  allowOffsetJob = true;
  verifySourceFn = isD4WPatientPreExistingTreatment;
  override defaultOffsetSize = 20000;
  override transformDataFn = flow([transformPreExistingTreatmentResults]);

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const response = await runQuery<ID4WPatientPreExistingTreatmentResult>(
      migration,
      PATIENT_PRE_EXISTING_TREATMENT_ESTIMATE_QUERY
    );

    const expectedSize = transformPreExistingTreatmentResults(
      response.rows
    ).length;

    return {
      expectedSize,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }

  translate(_data: ID4WPatientPreExistingTreatment): unknown {
    return {};
  }

  getSourceRecordId(data: ID4WPatientPreExistingTreatment): number {
    return data.id;
  }

  getSourceLabel(data: ID4WPatientPreExistingTreatment): string {
    return `${data.id} ${data.patient_id} ${data.chart_id}`;
  }

  getFilterData(
    data: ID4WPatientPreExistingTreatment
  ): ID4WPatientPreExistingTreatmentFilters {
    return {
      patientId: data.patient_id.toString(),
      itemId: data.item_id.toString(),
      chartId: data.chart_id.toString(),
    };
  }
}

function transformPreExistingTreatmentResults(
  rows: ID4WPatientPreExistingTreatmentResult[]
): ID4WPatientPreExistingTreatment[] {
  return compact(
    rows.map((row) => {
      const toothRef = row.tooth_ref
        ? ToothNumberConversion.convertToISONotation(row.tooth_ref)
        : row.tooth_ref;

      try {
        return {
          ...row,
          tooth_ref: toothRef,
          tooth_surface: convertToToothSurfaces(row.tooth_surface ?? ''),
        };
      } catch (error) {
        // We don't care about surfaces that aren't valid
        return {
          ...row,
          tooth_ref: toothRef,
          tooth_surface: [],
        };
      }
    })
  );
}
