import { LocalStorage } from '@principle-theorem/ng-principle-shared';

interface ICalendarLocalStore {
  from: string;
  to: string;
}

export const CALENDAR_LOCAL_STORAGE = new LocalStorage<ICalendarLocalStore>(
  'calendar-store'
);
