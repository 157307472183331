import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { CoreModule } from '../../core/core.module';
import { CreateRoleDialogComponent } from './create-role-dialog/create-role-dialog.component';
import { PermissionSelectorComponent } from './permission-selector/permission-selector.component';
import { RolesComponent } from './roles/roles.component';
import { ManageRoleComponent } from './manage-role/manage-role.component';

@NgModule({
  imports: [CommonModule, NgSharedModule, NgPrincipleSharedModule, CoreModule],
  declarations: [
    CreateRoleDialogComponent,
    PermissionSelectorComponent,
    RolesComponent,
    ManageRoleComponent,
  ],
  exports: [
    CreateRoleDialogComponent,
    PermissionSelectorComponent,
    RolesComponent,
    ManageRoleComponent,
  ],
})
export class RolesModule {}
