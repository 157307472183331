import { Injectable } from '@angular/core';
import { AccountingFunctionsService } from '@principle-theorem/ng-principle-accounting';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { BasicDialogService } from '@principle-theorem/ng-shared';
import { TyroService } from '@principle-theorem/ng-tyro';
import { Invoice, Practice } from '@principle-theorem/principle-core';
import {
  type IInvoice,
  type ITransaction,
  TransactionAction,
  TransactionProvider,
  type IPurchaseTransactionExtendedData,
} from '@principle-theorem/principle-core/interfaces';
import { Region, type DocumentReference } from '@principle-theorem/shared';
import { guardFilter, snapshot, type WithRef } from '@principle-theorem/shared';
import {
  isBaseTransactionCompleteCallbackData,
  isNotStarted,
  isTransactionCompleteCallbackData,
  isTyroResponse,
} from '@principle-theorem/tyro';
import { type Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RefundDepositPromptService } from '../../transaction-components/refund-deposit-prompt/refund-deposit-prompt.service';
import {
  type ITransactionProvider,
  TransactionProviderType,
} from '../../transaction-provider';
import { TYRO_ERROR_DIALOG_DATA } from '../base-transaction';
import { SelectTyroTerminalService } from '../select-tyro-terminal/select-tyro-terminal.service';
import { PurchaseBuilder } from './purchase-builder.service';
import {
  toPurchaseRefundTransaction,
  toPurchaseTransaction,
} from './purchase-transaction';

@Injectable()
export class TyroPurchaseTransactionProvider implements ITransactionProvider {
  providerId = TransactionProvider.TyroEftpos;
  providerType = TransactionProviderType.Payment;
  providerRegions = [Region.Australia];
  isEnabled$: Observable<boolean>;

  constructor(
    private _tyro: TyroService,
    private _currentScopeFacade: CurrentScopeFacade,
    private _selectTerminal: SelectTyroTerminalService,
    private _purchaseBuilder: PurchaseBuilder,
    private _basicDialog: BasicDialogService,
    private _refundDepositPrompt: RefundDepositPromptService,
    private _accountFunctions: AccountingFunctionsService
  ) {
    this.isEnabled$ = this._currentScopeFacade.currentPractice$.pipe(
      map((practice) => Practice.isTyroEnabled(practice))
    );
  }

  canCapture$(invoice: WithRef<IInvoice>): Observable<boolean> {
    const canCapture =
      !Invoice.isPaid(invoice) && Invoice.canAddTransactions(invoice);
    return of(canCapture);
  }

  async capture(
    invoice: WithRef<IInvoice>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const formData = await this._purchaseBuilder.openPurchaseForm(invoice);
    if (!formData || formData.amount <= 0) {
      return;
    }
    const terminal = await this._selectTerminal.getTerminalData();
    if (!terminal) {
      return;
    }
    const request = await this._purchaseBuilder.buildPurchase(
      invoice,
      formData,
      terminal.terminalData
    );
    const response = await snapshot(
      this._tyro.initiatePurchase$(request).pipe(guardFilter(isTyroResponse))
    );
    if (isNotStarted(response) || !response) {
      return;
    }
    if (!isBaseTransactionCompleteCallbackData(response)) {
      // eslint-disable-next-line no-console
      console.error('Invaid data for purchase response', response);
      await this._basicDialog.alert(TYRO_ERROR_DIALOG_DATA);
      return;
    }
    const transaction = await toPurchaseTransaction(
      invoice,
      terminal.practiceRef,
      request,
      response
    );
    return this._accountFunctions.addTransactionToInvoice(
      invoice,
      transaction,
      TransactionAction.Add
    );
  }

  async refundTransaction(
    invoice: WithRef<IInvoice>,
    transaction: WithRef<ITransaction<IPurchaseTransactionExtendedData>>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const acceptedRefundAlert =
      await this._refundDepositPrompt.showRefundDepositAlert(invoice);
    if (!acceptedRefundAlert) {
      return;
    }
    const formData = await this._purchaseBuilder.openRefundForm({
      invoice,
      transaction,
      latestTransaction: transaction,
    });
    if (!formData || formData.amount <= 0) {
      return;
    }
    const terminal = await this._selectTerminal.getTerminalData();
    if (!terminal) {
      return;
    }
    const request = this._purchaseBuilder.buildRefund(
      formData,
      terminal.terminalData
    );
    if (!request) {
      return;
    }
    const response = await snapshot(
      this._tyro
        .initiateRefund$(request)
        .pipe(guardFilter(isTransactionCompleteCallbackData))
    );

    if (!response) {
      return;
    }
    const refundTransaction = await toPurchaseRefundTransaction(
      invoice,
      terminal.practiceRef,
      request,
      response,
      transaction
    );
    return this._accountFunctions.addTransactionToInvoice(
      invoice,
      refundTransaction,
      TransactionAction.Refund
    );
  }
}
