import { Brand, PatientRelationship } from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  ISourceEntityRecord,
  PatientRelationshipType,
  type FailedDestinationEntityRecord,
  type IBrand,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPatientRelationship,
  type IPracticeMigration,
  type MergeConflictDestinationEntityRecord,
  type MigratedDestinationEntityRecord,
  IDestinationEntityJobRunOptions,
} from '@principle-theorem/principle-core/interfaces';
import {
  asDocRef,
  asyncForEach,
  find$,
  getError,
  snapshot,
  toNamedDocument,
  toTimestamp,
  where,
  type DocumentReference,
  type INamedDocument,
  type WithRef,
  Firestore,
  snapshotCombineLatest,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  IPatientDestinationRecord,
  PATIENT_RESOURCE_TYPE,
} from '../../../destination/entities/patient';
import { PracticeMigration } from '../../../practice-migrations';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientSourceEntity,
  type IPraktikaPatient,
  type IPraktikaPatientFilters,
  type IPraktikaPatientTranslations,
} from '../../source/entities/patient';
import {
  PatientRelationshipSourceEntity,
  PraktikaPatientRelationshipTypes,
  type IPraktikaPatientRelationship,
} from '../../source/entities/patient-relationship';
import { PatientDestinationEntity } from './patients';

export const PATIENT_RELATIONSHIP_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: 'patientRelationships',
    label: 'Patient Relationships',
    description: '',
  },
});

export interface IPatientRelationshipDestinationRecord {
  patientRef: DocumentReference;
}

export interface IPatientRelationshipJobData {
  brand: WithRef<IBrand>;
  sourcePatient: IGetRecordResponse<
    IPraktikaPatient,
    IPraktikaPatientTranslations,
    IPraktikaPatientFilters
  >;
}

export class PatientRelationshipDestinationEntity extends BaseDestinationEntity<
  IPatientRelationshipDestinationRecord,
  IPatientRelationshipJobData,
  IPatientRelationshipJobData
> {
  destinationEntity = PATIENT_RELATIONSHIP_DESTINATION_ENTITY;

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    patientRelationships: new PatientRelationshipSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  sourceCountDataAccessor(
    data: IPatientRelationshipJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMapHandler: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientRelationshipJobData[]> {
    const brand$ = PracticeMigration.brand$(migration);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([brand$]),
    ]).pipe(
      map(([sourcePatients, [brand]]) =>
        sourcePatients.map((sourcePatient) => ({
          sourcePatient,
          brand,
        }))
      )
    );
  }

  getDestinationEntityRecordUid(data: IPatientRelationshipJobData): string {
    return data.sourcePatient.record.uid;
  }

  buildMigrationData(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IPatientRelationshipJobData
  ):
    | IPatientRelationshipJobData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord) {
    return data;
  }

  hasMergeConflict(
    _translationMap: TranslationMapHandler,
    _data: IPatientRelationshipJobData
  ): IPatientRelationshipJobData | undefined {
    return;
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientRelationshipJobData,
    migrationData: IPatientRelationshipJobData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      label: migrationData.sourcePatient.record.label,
      uid: migrationData.sourcePatient.record.uid,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  async runJob(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    data: IPatientRelationshipJobData
  ): Promise<IDestinationEntityRecord> {
    const relationships = await this._getValidRelationships(
      data.sourcePatient,
      migration
    );

    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();
    const patientRef = await translationMapHandler.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        `Couldn't resolve patient`
      );
    }

    try {
      await this._addRelationshipsToPatient(
        relationships,
        translationMapHandler,
        data.brand,
        patientRef
      );
      return this._buildSuccessResponse(data.sourcePatient.record);
    } catch (error) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        getError(error)
      );
    }
  }

  private _buildSuccessResponse(
    patient: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>
  ): IDestinationEntityRecord<IPatientRelationshipDestinationRecord> {
    return {
      uid: patient.uid,
      label: patient.label,
      data: {
        patientRef: patient.ref,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: patient.ref,
      migratedAt: toTimestamp(),
    };
  }

  private _buildErrorResponse(
    patient: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>,
    errorMessage: string
  ): IDestinationEntityRecord<IPatientRelationshipDestinationRecord> {
    return {
      uid: patient.uid,
      label: patient.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: patient.ref,
      errorMessage,
      failData: {
        patientRef: patient.ref,
      },
    };
  }

  private async _addRelationshipsToPatient(
    praktikaRelationships: IPraktikaPatientRelationship[],
    translationMap: TranslationMapHandler,
    brand: WithRef<IBrand>,
    patientRef: DocumentReference<IPatient>
  ): Promise<void> {
    const relationships = compact(
      await asyncForEach(praktikaRelationships, async (guarantor) => {
        if (!guarantor.person_id) {
          throw new Error('No person_id');
        }
        const guarantorRef = await translationMap.getDestination<IPatient>(
          guarantor.person_id.toString(),
          PATIENT_RESOURCE_TYPE
        );
        if (!guarantorRef) {
          throw new Error(`No ref for ${guarantor.person_id}`);
        }
        return getPatientRelationship(
          brand,
          guarantor,
          asDocRef<IPatient>(guarantorRef)
        );
      })
    );
    if (relationships.length) {
      await FirestoreMigrate.patchDoc(patientRef, {
        relationships,
      });

      const patient = await Firestore.getDoc(patientRef);
      await PatientRelationship.addRelationshipBackReferences(patient);
    }
  }

  private async _getValidRelationships(
    sourcePatient: IGetRecordResponse<
      IPraktikaPatient,
      IPraktikaPatientTranslations,
      IPraktikaPatientFilters
    >,
    migration: WithRef<IPracticeMigration>
  ): Promise<IPraktikaPatientRelationship[]> {
    const relationships =
      await this.sourceEntities.patientRelationships.filterRecords(
        migration,
        'patientId',
        sourcePatient.data.data.patient_id.toString()
      );
    return relationships
      .filter(
        (guarantor) =>
          guarantor.data.data.person_id &&
          sourcePatient.data.data.patient_id.toString() !==
            guarantor.data.data.person_id.toString() &&
          guarantor.data.data.is_person
      )
      .map((relationship) => relationship.data.data);
  }
}

function getPatientRelationship(
  brand: WithRef<IBrand>,
  relationship: IPraktikaPatientRelationship,
  patientRef: DocumentReference<IPatient>
): Promise<IPatientRelationship | undefined> {
  return snapshot(
    find$(Brand.patientCol(brand), where('ref', '==', patientRef)).pipe(
      map((destinationPatient) => {
        if (!destinationPatient) {
          return;
        }
        const patient = toNamedDocument(destinationPatient);

        return buildRelationship(relationship, patient);
      })
    )
  );
}

export interface IPatientCombinedData {
  sourcePatient: IGetRecordResponse<
    IPraktikaPatient,
    IPraktikaPatientTranslations,
    IPraktikaPatientFilters
  >;
  record: WithRef<IDestinationEntityRecord<IPatientDestinationRecord>> &
    MigratedDestinationEntityRecord<IPatientDestinationRecord>;
}

function buildRelationship(
  relationship: IPraktikaPatientRelationship,
  patient: INamedDocument<IPatient>
): IPatientRelationship | undefined {
  switch (relationship.relationship_type_id) {
    case PraktikaPatientRelationshipTypes.Unknown:
    case PraktikaPatientRelationshipTypes.Carer:
    case PraktikaPatientRelationshipTypes.Employee:
    case PraktikaPatientRelationshipTypes.Beneficiary:
    case PraktikaPatientRelationshipTypes.InsuredPlaintiff:
    case PraktikaPatientRelationshipTypes.Grandchild:
    case PraktikaPatientRelationshipTypes.Grandparent:
    case PraktikaPatientRelationshipTypes.Dependent:
      return {
        patient,
        type: PatientRelationshipType.Unknown,
      };
    case PraktikaPatientRelationshipTypes.Sibling:
      return {
        patient,
        type: PatientRelationshipType.Sibling,
      };
    case PraktikaPatientRelationshipTypes.Spouse:
    case PraktikaPatientRelationshipTypes.Partner:
      return {
        patient,
        type: PatientRelationshipType.Partner,
      };
    case PraktikaPatientRelationshipTypes.Parent:
    case PraktikaPatientRelationshipTypes.StepParent:
    case PraktikaPatientRelationshipTypes.FosterParent:
      return {
        patient,
        type: PatientRelationshipType.Parent,
      };
    case PraktikaPatientRelationshipTypes.Child:
    case PraktikaPatientRelationshipTypes.FosterChild:
    case PraktikaPatientRelationshipTypes.StepChild:
      return {
        patient,
        type: PatientRelationshipType.Child,
      };
    default:
      return;
  }
}
