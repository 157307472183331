import {
  ServiceProviderHandler,
  SterilisationRecord,
} from '@principle-theorem/principle-core';
import {
  IDestinationEntityJobRunOptions,
  IHasSourceIdentifier,
  ITranslationMap,
  type FailedDestinationEntityRecord,
  type IBrand,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPractice,
  type IPracticeMigration,
  type ISterilisationRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  Timestamp,
  asyncForEach,
  getDoc,
  getError,
  snapshotCombineLatest,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import {
  BasePatientSterilisationDestinationEntity,
  ISterilisationRecordBaseJobData,
  ISterilisationRecordMigrationData,
} from '../../../destination/entities/sterilisation-records';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { PracticeIdFilter } from '../../../destination/filters/practice-id-filter';
import { ItemCodeNoteType } from '../../../mappings/item-codes-to-notes-xlsx';
import { PracticeMigration } from '../../../practice-migrations';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientSourceEntity,
  type ID4WPatient,
} from '../../source/entities/patient';
import {
  PatientTreatmentNoteSourceEntity,
  type ID4WPatientTreatmentNote,
  type ID4WPatientTreatmentNoteTranslations,
} from '../../source/entities/patient-treatment-note';
import { SterilisationRecordSourceEntity } from '../../source/entities/sterilisation-records';
import { D4WItemCodeToNoteMappingHandler } from '../mappings/item-code-to-note';
import { D4WPracticeMappingHandler } from '../mappings/practices';
import { PatientDestinationEntity } from './patients';

interface IPatientSterilisationJobData
  extends ISterilisationRecordBaseJobData<ID4WPatient> {
  brand: WithRef<IBrand>;
  practices: WithRef<ITranslationMap<IPractice>>[];
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeNoteType>>[];
}

export class SterilisationRecordDestinationEntity extends BasePatientSterilisationDestinationEntity<
  ID4WPatient,
  IPatientSterilisationJobData
> {
  override filters = [
    new PracticeIdFilter<IPatientSterilisationJobData>((jobData) =>
      jobData.sourcePatient.data.data.practice_id.toString()
    ),
    new PatientIdFilter<IPatientSterilisationJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    treatmentNotes: new PatientTreatmentNoteSourceEntity(),
    sterilisationRecords: new SterilisationRecordSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  customMappings = {
    itemCodes: new D4WItemCodeToNoteMappingHandler(),
    practices: new D4WPracticeMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientSterilisationJobData[]> {
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);
    const practices$ =
      this.customMappings.practices.getRecords$(translationMap);
    const brand$ = PracticeMigration.brand$(migration);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([brand$, practices$, sourceItemCodes$]),
    ]).pipe(
      map(([sourcePatients, [brand, practices, sourceItemCodes]]) =>
        sourcePatients.map((sourcePatient) => ({
          sourcePatient,
          brand,
          practices,
          sourceItemCodes,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientSterilisationJobData
  ): Promise<
    | ISterilisationRecordMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients
      .getSourceRecordId(data.sourcePatient.data.data)
      .toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `Couldn't resolve patient ${sourcePatientId}`
      );
    }

    const treatmentNotes =
      await this.sourceEntities.treatmentNotes.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

    try {
      const patient = await Firestore.getDoc(patientRef);

      const sterilisationRecords = await this._buildSterilisationRecords(
        migration,
        sourcePatientId,
        data,
        patient
      );

      const sterilisationRecordsFromNotes =
        await this._buildSterilisationNotesFromTreatments(
          treatmentNotes,
          patient,
          data
        );

      return {
        sterilisationRecords: [
          ...sterilisationRecordsFromNotes,
          ...sterilisationRecords,
        ],
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  private async _buildSterilisationRecords(
    migration: WithRef<IPracticeMigration>,
    sourcePatientId: string,
    data: IPatientSterilisationJobData,
    patient: WithRef<IPatient>
  ): Promise<
    (ISterilisationRecord &
      IHasSourceIdentifier & {
        createdAt: Timestamp;
      })[]
  > {
    return asyncForEach(
      await this.sourceEntities.sterilisationRecords.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      ),
      async (record) => {
        const practiceId = record.data.data.practice_id.toString();
        const practiceMap = data.practices.find(
          (searchPractice) =>
            searchPractice.sourceIdentifier === practiceId.toString()
        );

        if (!practiceMap?.destinationIdentifier) {
          throw new Error(`Can't find practice with id ${practiceId}`);
        }
        const practice = await Firestore.getDoc(
          practiceMap?.destinationIdentifier
        );

        return {
          ...SterilisationRecord.init({
            data: record.data.data.barcode,
            practice: toNamedDocument(practice),
            patient: toNamedDocument(patient),
          }),
          sourceIdentifier: `sterilisationRecord-${record.data.data.id.toString()}`,
          createdAt: record.data.translations.dateUsed,
        };
      }
    );
  }

  private async _buildSterilisationNotesFromTreatments(
    notes: IGetRecordResponse<
      ID4WPatientTreatmentNote,
      ID4WPatientTreatmentNoteTranslations
    >[],
    patient: WithRef<IPatient>,
    data: IPatientSterilisationJobData
  ): Promise<
    (ISterilisationRecord &
      IHasSourceIdentifier & {
        createdAt: Timestamp;
      })[]
  > {
    return compact(
      await asyncForEach(notes, async (note) => {
        const procedureCode = note.data.data.item_code;
        const code = ServiceProviderHandler.findServiceCode(procedureCode);

        if (code) {
          return;
        }

        const mappedCode = data.sourceItemCodes.find(
          (sourceItemCode) =>
            sourceItemCode.sourceIdentifier === procedureCode &&
            !!sourceItemCode.destinationValue
        );

        if (!mappedCode) {
          // eslint-disable-next-line no-console
          console.error(
            `Couldn't find mapped code for ${procedureCode} with id ${note.data.data.item_id}`
          );
          return;
        }

        if (
          mappedCode.destinationValue !== ItemCodeNoteType.SterilisationRecord
        ) {
          return;
        }

        const practiceId = note.data.data.practice_id.toString();
        const practiceMap = data.practices.find(
          (searchPractice) =>
            searchPractice.sourceIdentifier === practiceId.toString()
        );

        if (!practiceMap?.destinationIdentifier) {
          throw new Error(`Can't find practice with id ${practiceId}`);
        }
        const practice = await getDoc(practiceMap?.destinationIdentifier);

        return {
          ...SterilisationRecord.init({
            data: note.data.data.content,
            practice: toNamedDocument(practice),
            patient: toNamedDocument(patient),
          }),
          sourceIdentifier: `treatmentNote-${note.data.data.id.toString()}`,
          createdAt: note.data.translations.createdAt,
        };
      })
    );
  }
}
