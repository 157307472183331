import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type Gender } from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-patient-gender',
  templateUrl: './patient-gender.component.html',
  styleUrls: ['./patient-gender.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientGenderComponent {
  @Input() gender: Gender;
}
