<ng-container *ngIf="appointmentChecklist$ | async as checklists">
  <div fxLayout="column" fxLayoutGap="8px">
    <ng-container
      [ngTemplateOutlet]="content"
      [ngTemplateOutletContext]="{
        label: 'Pre-appointment Checklist',
        items: checklists.pre,
        type: type.Pre,
        titleCtrl: preTitleCtrl
      }"
     />

    <ng-container
      [ngTemplateOutlet]="content"
      [ngTemplateOutletContext]="{
        label: 'Post-appointment Checklist',
        items: checklists.post,
        type: type.Post,
        titleCtrl: postTitleCtrl
      }"
     />

    <ng-template
      #content
      let-label="label"
      let-type="type"
      let-items="items"
      let-titleCtrl="titleCtrl"
    >
      <div fxLayout="column">
        <mat-list fxFlex dense>
          <div mat-subheader>{{ label }}</div>
          <ng-container *ngIf="items.length; else noItems">
            <mat-list-item
              mat-list-option
              *ngFor="let item of items; trackBy: trackByItem"
            >
              <div fxFlex>{{ item.title }}</div>
              <div fxLayout="row" fxLayoutAlign="end center">
                <button
                  mat-icon-button
                  class="remove-button"
                  (click)="deleteItem(item)"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </mat-list-item>
          </ng-container>
        </mat-list>

        <ng-template #noItems>
          <div class="no-items">No Checklist Items</div>
        </ng-template>

        <pt-search-field icon="add" [slim]="true">
          <input
            matInput
            placeholder="Add Item"
            [formControl]="titleCtrl"
            (keydown.enter)="addItem(titleCtrl, type)"
          />
        </pt-search-field>
      </div>
    </ng-template>
  </div>
</ng-container>
