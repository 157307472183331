import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'toPixels',
})
export class ToPixelsPipe implements PipeTransform {
  transform(value: number): string {
    return `${value}px`;
  }
}
