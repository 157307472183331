import {
  ISourceEntityRecord,
  ITreatmentPlan,
  ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference } from '@principle-theorem/shared';

export const PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE =
  'patientTreatmentPlan';

export const PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE =
  'patientTreatmentStep';

export interface ITreatmentPlanSuccessData {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  treatmentPlanRefs: DocumentReference<ITreatmentPlan>[];
  treatmentStepRefs: DocumentReference<ITreatmentStep>[];
}
