import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const CALENDARS_RESOURCE_TYPE = 'calendars';

export const CALENDARS_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Calendar List',
    description: '',
    idPrefix: CALENDARS_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ICorePracticeCalendar {
  id: number;
  description: string;
  displayName: string;
  isDeleted: boolean;
}

export function isCorePracticeCalendar(
  item: unknown
): item is ICorePracticeCalendar {
  return TypeGuard.interface<ICorePracticeCalendar>({
    id: isNumber,
    description: isString,
    displayName: isString,
    isDeleted: isBoolean,
  })(item);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICorePracticeCalendarTranslations {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICorePracticeCalendarFilters {}

const CALENDARS_SOURCE_QUERY = `
SELECT
  CalendarId AS id,
  Description AS description,
  DisplayName AS display_name,
  convert_to_boolean(IsDeleted) AS is_deleted
FROM tblCalendar
`;

export class CalendarSourceEntity extends BaseSourceEntity<
  ICorePracticeCalendar,
  ICorePracticeCalendarTranslations,
  ICorePracticeCalendarFilters
> {
  sourceEntity = CALENDARS_SOURCE_ENTITY;
  entityResourceType = CALENDARS_RESOURCE_TYPE;
  sourceQuery = CALENDARS_SOURCE_QUERY;
  verifySourceFn = isCorePracticeCalendar;

  translate(
    _data: ICorePracticeCalendar,
    _timezone: Timezone
  ): ICorePracticeCalendarTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticeCalendar): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticeCalendar): string {
    return `${data.id} - ${data.displayName}`;
  }

  getFilterData(
    _data: ICorePracticeCalendar,
    _timezone: Timezone
  ): ICorePracticeCalendarFilters {
    return {};
  }
}
