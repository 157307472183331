import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BaseResolver } from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IProduct,
} from '@principle-theorem/principle-core/interfaces';
import { Brand } from '@principle-theorem/principle-core';
import { type CollectionReference } from '@principle-theorem/shared';
import { type WithRef } from '@principle-theorem/shared';

@Injectable()
export class BrandProductCollectionResolverService extends BaseResolver<
  CollectionReference<IProduct>
> {
  static readonly resolverKey: string = 'productCollection';

  resolve(route: ActivatedRouteSnapshot): CollectionReference<IProduct> {
    const brand = this._findInheritedRouteData<WithRef<IBrand>>(route, 'brand');
    if (!brand) {
      throw new Error('No Brand found');
    }
    return Brand.productCol(brand);
  }
}
