import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type MedicalHistoryListComponent } from '../medical-history-list/medical-history-list.component';
import { type PatientDetailsHistoryListComponent } from '../patient-details-history-list/patient-details-history-list.component';
import { FormDataProviderService } from './form-data-provider.service';

export type FormComponentType =
  | typeof MedicalHistoryListComponent
  | typeof PatientDetailsHistoryListComponent;

export interface IFormDialogData {
  title: string;
  patient: WithRef<IPatient>;
  component: FormComponentType;
}

@Component({
  selector: 'pr-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDialogComponent {
  constructor(
    private _formDataService: FormDataProviderService,
    public dialogRef: MatDialogRef<FormDialogComponent>,

    @Inject(MAT_DIALOG_DATA)
    public data: IFormDialogData
  ) {
    this._formDataService.setPatient(data.patient);
  }
}
