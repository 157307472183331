<mat-toolbar color="accent">
  Manual {{ isOutgoing ? 'Refund' : 'Payment' }}
</mat-toolbar>
<form [formGroup]="form" (submit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field>
      <mat-label>From</mat-label>
      <input
        matInput
        formControlName="from"
        cdkFocusInitial
        [required]="true"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>To</mat-label>
      <input matInput formControlName="to" [required]="true" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Status</mat-label>
      <mat-select [required]="true" formControlName="status">
        <mat-option
          *ngFor="let status of statuses; trackBy: trackByStatus"
          [value]="status"
        >
          {{ status | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Amount</mat-label>
      <span matTextPrefix>$&nbsp;</span>
      <input
        matInput
        type="number"
        formControlName="amount"
        min="0"
        [max]="maxAmount$ | async"
        [required]="true"
      />
      <mat-hint>Maximum {{ maxAmount$ | async | currency }}</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Transaction Type</mat-label>
      <mat-select required formControlName="transactionType">
        <mat-option
          *ngFor="
            let type of transactionTypes$ | async;
            trackBy: trackByTransactionType
          "
          [value]="type"
        >
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Description</mat-label>
      <input matInput type="text" formControlName="description" />
    </mat-form-field>

    <mat-form-field fxFlex *prHasPermission="[accountAdminPermission]">
      <mat-label>Date Received</mat-label>
      <input
        matInput
        formControlName="dateReceived"
        placeholder="Date Received"
        [matDatepicker]="dateReceived"
        [min]="amendDateStore.minDate$ | async"
        [max]="amendDateStore.maxDate$ | async"
        (focus)="dateReceived.open()"
        (click)="dateReceived.open()"
      />
      <mat-datepicker-toggle matSuffix [for]="dateReceived" />
      <mat-datepicker #dateReceived />
    </mat-form-field>

    @if (showAttributedTo) {
      <pr-attributed-to-selector
        *prHasPermission="[accountAdminPermission]"
        class="flex grow"
        formControlName="attributedTo"
        [staff]="staff"
      />
    }

    <ng-container *ngIf="practiceOptions.options$ | async as practices">
      <mat-form-field *ngIf="practices.length > 1">
        <mat-label>Practice</mat-label>
        <mat-select
          [required]="true"
          formControlName="practiceRef"
          [compareWith]="practiceOptions.compareFn"
        >
          <mat-option
            *ngFor="
              let practice of practices;
              trackBy: practiceOptions.trackByFn
            "
            [value]="practice.ref"
          >
            {{ practice.name }}
          </mat-option>
        </mat-select>
        <mat-hint>{{ practiceOptions.hint }}</mat-hint>
      </mat-form-field>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" [disabled]="form.invalid">
        Submit
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
