import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { type ITimePeriod } from '@principle-theorem/shared';

export function dateRangeToPracticeTimezone(
  practice: IPractice,
  dateRange: ITimePeriod
): ITimePeriod {
  return {
    from: dateRange.from.clone().tz(practice.settings.timezone).startOf('day'),
    to: dateRange.to.clone().tz(practice.settings.timezone).endOf('day'),
  };
}

export function dateRangeToBrandTimezone(
  brand: IBrand,
  dateRange: ITimePeriod
): ITimePeriod {
  return {
    from: dateRange.from.clone().tz(brand.settings.timezone).startOf('day'),
    to: dateRange.to.clone().tz(brand.settings.timezone).endOf('day'),
  };
}
