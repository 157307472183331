import {
  InlineNodes,
  SNIPPET_TRIGGER,
  labelPlugin,
  type ExtensionRegisterReturnFn,
} from '@principle-theorem/editor';
import { Editor } from '@tiptap/core';
import { keymap } from '@tiptap/pm/keymap';
import { type Plugin } from '@tiptap/pm/state';
import { v4 as uuid } from 'uuid';

export function createSnippetKeymap(): ExtensionRegisterReturnFn<Plugin> {
  return (editor: Editor) =>
    labelPlugin(
      `${InlineNodes.SnippetAutocomplete}-keymap`,
      keymap({
        [SNIPPET_TRIGGER]: () => createSnippet(editor),
      })
    );
}

export function createSnippet(editor: Editor): boolean {
  if (editor.isActive(InlineNodes.SnippetAutocomplete)) {
    return false;
  }

  return editor
    .chain()
    .focus()
    .insertContent({
      type: InlineNodes.SnippetAutocomplete,
      attrs: {
        uid: uuid(),
      },
      content: [
        {
          type: InlineNodes.Text,
          text: SNIPPET_TRIGGER,
        },
      ],
    })
    .focus()
    .run();
}
