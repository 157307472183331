<ng-container *ngIf="!showReceipt; else showReceiptTemplate">
  <span
    *ngFor="let segment of segments; trackBy: trackBySegment; let isLast = last"
  >
    {{ segment }} <ng-container *ngIf="!isLast">-</ng-container>
  </span>
</ng-container>

<ng-template #showReceiptTemplate>
  <pr-hicaps-connect-receipt
    class="block py-2"
    *ngIf="extendedData"
    [extendedData]="extendedData"
    receiptType="patient"
  />
</ng-template>
