import { _isNumberValue } from '@angular/cdk/coercion';
import {
  type AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AppointmentPopoverComponent,
  type IWaitListConfigurationData,
  WaitlistConfigurationDialogComponent,
} from '@principle-theorem/ng-appointment';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import {
  CurrentBrandScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  ObservableDataSource,
} from '@principle-theorem/ng-shared';
import {
  Appointment,
  Brand,
  CalendarEvent,
  Event,
  type IWaitListCandidate,
  stafferToNamedDoc,
  WaitListCandidate,
  waitListCandidateToEvent,
} from '@principle-theorem/principle-core';
import {
  EventType,
  type IAppointment,
  type ICandidateCalendarEvent,
  type IGap,
  isEventable,
  type IStaffer,
  WaitListUrgency,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  filterUndefined,
  isSameRef,
  type ITimePeriod,
  saveDoc,
  snapshot,
  toMoment,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
// eslint-disable-next-line no-restricted-imports
import { type Moment as MomentMoment } from 'moment';
import { of } from 'rxjs';
import {
  CandidateGapTimeComponent,
  type IGapTimeData,
} from '../candidate-gap-time/candidate-gap-time.component';
import { ManageCalendarEventService } from '@principle-theorem/ng-calendar';

@Component({
  selector: 'pr-wait-list',
  templateUrl: './wait-list.component.html',
  styleUrls: ['./wait-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaitListComponent implements AfterViewInit {
  private _matSort: MatSort;
  dataSource: ObservableDataSource<IWaitListCandidate> =
    new ObservableDataSource<IWaitListCandidate>(of([]));
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageSize = 10;
  today = moment() as MomentMoment;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() gap: IGap;
  @Input() gapCandidates: WithRef<ICandidateCalendarEvent>[];

  @Input()
  set waitlist(candidates: IWaitListCandidate[]) {
    if (candidates) {
      this.dataSource.items$ = of(candidates);
    }
  }

  columnsToDisplay: string[] = [
    'add',
    'edit',
    'patient',
    'practitioner',
    'duration',
    'appointment',
    'score',
    'urgency',
    // 'productivity',
    'warnings',
  ];

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService,
    private _brandScope: CurrentBrandScope,
    private _schedulingFacade: AppointmentSchedulingFacade,
    private _calendarEvent: ManageCalendarEventService
  ) {}

  @ViewChild(MatSort, { static: true })
  set matSort(sort: MatSort) {
    this._matSort = sort;
    this._setSortingAttributes();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.setFilterPredicate();
  }

  hasEvent(appointment: IAppointment): boolean {
    return isEventable(appointment);
  }

  duration(appointment: IAppointment): number {
    return Appointment.duration(appointment);
  }

  getMatchScore(waitListCandidate: IWaitListCandidate): number {
    return WaitListCandidate.getMatchScore(
      waitListCandidate.appointment,
      this.gap.event
    );
  }

  getTreatment(appointment: IAppointment): string {
    return `${appointment.treatmentPlan.name} - ${appointment.treatmentPlan.treatmentStep.name}`;
  }

  viewAppointmentCard(appointment: IAppointment): void {
    this._dialog.open(
      AppointmentPopoverComponent,
      DialogPresets.flex({
        data: appointment,
      })
    );
  }

  async addToShortlist(
    candidate: IWaitListCandidate,
    gap: IGap
  ): Promise<void> {
    const offerFrom: Moment = toMoment(candidate.candidate.offerTimeFrom);
    const offerTo: Moment = toMoment(candidate.candidate.offerTimeTo);
    const gapFrom: Moment = toMoment(gap.event.from);
    const gapTo: Moment = toMoment(gap.event.to);

    const offerDuration: number = offerFrom.diff(offerTo, 'minutes');
    const longerThanGap: boolean = offerDuration >= Event.duration(gap.event);
    const isSameTimeAsGap: boolean =
      offerFrom.isSame(gapFrom) && offerTo.isSame(gapTo);

    if (isSameTimeAsGap || longerThanGap) {
      await this._addCandidateToGap(candidate, gap);
      return;
    }

    const config: MatDialogConfig = DialogPresets.medium({
      data: {
        gap: this.gap,
        candidate,
      },
    });
    const timePeriod = await this._dialog
      .open<CandidateGapTimeComponent, IGapTimeData, ITimePeriod>(
        CandidateGapTimeComponent,
        config
      )
      .afterClosed()
      .toPromise();

    if (!timePeriod) {
      return;
    }

    candidate.candidate.offerTimeFrom = toTimestamp(timePeriod.from);
    candidate.candidate.offerTimeTo = toTimestamp(timePeriod.to);
    await this._addCandidateToGap(candidate, gap);
  }

  isGapCandidate(candidate: IWaitListCandidate): boolean {
    return this.gapCandidates.some((event) =>
      isSameRef(event.candidate.patient, candidate.candidate.patient)
    );
  }

  openWaitlistConfiguration(waitListCandidate: IWaitListCandidate): void {
    const data: IWaitListConfigurationData = {
      appointment: waitListCandidate.appointment,
    };
    const config: MatDialogConfig = DialogPresets.medium({ data });
    this._dialog.open<
      WaitlistConfigurationDialogComponent,
      IWaitListConfigurationData
    >(WaitlistConfigurationDialogComponent, config);
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue;
  }

  setFilterPredicate(): void {
    this.dataSource.filterPredicate = (
      waitListCandidate: IWaitListCandidate,
      filter: string
    ): boolean => {
      const patientName: string =
        waitListCandidate.candidate.patient.name.toLowerCase();
      const transformedFilter: string = filter.trim().toLowerCase();
      return patientName.indexOf(transformedFilter) !== -1;
    };
  }

  async appointmentFromGap(gap: IGap): Promise<void> {
    this._schedulingFacade.setAppointmentDetailsFromGap(gap);
    await this._router.navigate(['../../appointments/create'], {
      relativeTo: this._route,
      queryParams: {
        preserveState: true,
      },
      queryParamsHandling: 'merge',
    });
  }

  blockTime(gap: IGap): void {
    const calendarEvent = CalendarEvent.init({
      event: {
        ...gap.event,
        type: EventType.Misc,
      },
      isBlocking: true,
    });
    void this._calendarEvent.add(calendarEvent);
  }

  private async _addCandidateToGap(
    candidate: IWaitListCandidate,
    gap: IGap
  ): Promise<void> {
    const staffer: WithRef<IStaffer> = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const event: ICandidateCalendarEvent = await waitListCandidateToEvent(
      gap,
      candidate,
      stafferToNamedDoc(staffer)
    );
    const brand = await this._brandScope.toPromise();
    await addDoc(Brand.calendarEventCol(brand), event);

    if (candidate.appointment.waitListItem) {
      candidate.appointment.waitListItem.inShortList = true;
    }
    await saveDoc(candidate.appointment);
    this._snackBar.open('Added to short-list');
  }

  private _setSortingAttributes(): void {
    this.dataSource.sort = this._matSort;
    this.dataSource.sortingDataAccessor = (
      data: IWaitListCandidate,
      sortHeaderId: string
    ): string | number => {
      switch (sortHeaderId) {
        case 'patient':
          return data.candidate.patient.name;
        case 'appointment':
          if (!isEventable(data.appointment)) {
            return -1;
          }
          return Number(
            toMoment(data.appointment.event.from).format('YYYYMMDDHHmmss')
          );
        case 'urgency':
          return Number(
            data.appointment.waitListItem?.urgency || WaitListUrgency.Low
          );
        case 'productivity':
          return Number(data.productivity);
        case 'duration':
          return (
            Number(Appointment.duration(data.appointment)) +
            WaitListCandidate.getMatchScore(data.appointment, this.gap.event)
          );
        case 'score':
          return WaitListCandidate.getMatchScore(
            data.appointment,
            this.gap.event
          );
        default:
          const value = data[sortHeaderId as keyof IWaitListCandidate];
          return _isNumberValue(value) ? Number(value) : String(value);
      }
    };
  }
}
