import { ReportingPermissions } from '@principle-theorem/principle-core/features';
import {
  CustomChartType,
  IBaseChartBuilderConfig,
  IBrand,
  ICustomChartSettings,
  ICustomReport,
  ICustomReportChart,
  ICustomReportChartSection,
  ICustomReportColumn,
  ICustomReportDefinedColumn,
  IStaffer,
  RETIRED_REPORT_BUILDER_DATA_SOURCE_IDS,
  ReportingReference,
} from '@principle-theorem/principle-core/interfaces';
import {
  CanBeChartedProperty,
  IChartBuilderData,
} from '@principle-theorem/reporting';
import {
  DocumentReference,
  Firestore,
  IReffable,
  WithRef,
  snapshot,
} from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';
import { RestrictAccess } from '../auth/restrict-access';
import { Staffer } from '../staffer/staffer';

export class CustomReport {
  static isRetired(customReport: ICustomReport): boolean {
    return RETIRED_REPORT_BUILDER_DATA_SOURCE_IDS.includes(
      customReport.dataSource
    );
  }

  static async userHasAccess(
    report: WithRef<ICustomReport>,
    staffer?: WithRef<IStaffer>
  ): Promise<boolean> {
    if (!staffer) {
      return false;
    }
    const canEdit = await snapshot(
      Staffer.hasPermission$(
        staffer,
        ReportingPermissions.ReportingCustomReportEdit
      )
    );
    if (canEdit) {
      return true;
    }
    const accessEntities = await snapshot(
      RestrictAccess.getAccessEntities$(staffer)
    );
    return RestrictAccess.hasAccess(report.restrictAccessTo, accessEntities);
  }

  static chartSection(
    name: string,
    charts: ICustomReportChart[]
  ): ICustomReportChartSection {
    return { uid: uuid(), name, charts };
  }

  static chart<
    T extends ICustomChartSettings,
    Config extends IBaseChartBuilderConfig = IBaseChartBuilderConfig,
  >(
    id: CustomChartType,
    settings: T[],
    label: string,
    groupBy: ReportingReference,
    builderData?: Partial<
      Omit<IChartBuilderData, 'measures' | 'groupByDimension'>
    >,
    builderConfig?: Partial<Config>
  ): ICustomReportChart<T> {
    return {
      uid: uuid(),
      type: id,
      settings,
      label,
      groupBy,
      builderData,
      builderConfig,
    };
  }

  static column(id: string, label?: string): ICustomReportColumn {
    return { uid: uuid(), id, label };
  }

  static definedColumn(
    measure: CanBeChartedProperty,
    id: string,
    label?: string
  ): ICustomReportDefinedColumn {
    return { uid: uuid(), id, label, measure };
  }

  static brandRef(report: IReffable<ICustomReport>): DocumentReference<IBrand> {
    return Firestore.getParentDocRef<IBrand>(report.ref);
  }
}
