import { renderVersionedSchema } from '@principle-theorem/ng-interactions';
import {
  BrandCollection,
  type IAutomatedNotification,
  type IAutomation,
  type IGeneratedTask,
  isAutomatedNotification,
  type ITemplateDefinition,
  TreatmentStepCollection,
} from '@principle-theorem/principle-core/interfaces';
import {
  bufferedQuery$,
  collectionGroupQuery,
  patchDoc,
  type WithRef,
} from '@principle-theorem/shared';
import { type Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { type IMigrator } from './migrator';
import { AnyExtension } from '@tiptap/core';

export class RenderedTemplateMigrator implements IMigrator {
  constructor(private _extensions: AnyExtension[]) {}

  async migrate(dryRun: boolean, logger: Subject<string>): Promise<void> {
    await bufferedQuery$(
      collectionGroupQuery<ITemplateDefinition>(BrandCollection.Templates),
      250,
      'ref'
    )
      .pipe(
        switchMap(async (templates) => {
          const promises = templates.map((template) =>
            this._migrateDocumentTemplate(template, dryRun, logger)
          );
          return Promise.all(promises);
        })
      )
      .toPromise();
    logger.next(`Migrating Templates: DONE`);

    await bufferedQuery$(
      collectionGroupQuery<
        IAutomation<IAutomatedNotification | IGeneratedTask>
      >(TreatmentStepCollection.Automations),
      100,
      'ref'
    )
      .pipe(
        switchMap((automations) => {
          const promises = automations.map((automation) =>
            this._migrateAutomationCustomTemplate(automation, dryRun)
          );
          return Promise.all(promises);
        })
      )
      .toPromise();
    logger.next(`Migrating Automations: DONE`);
  }

  private async _migrateDocumentTemplate(
    template: WithRef<ITemplateDefinition>,
    dryRun: boolean,
    logger: Subject<string>
  ): Promise<boolean> {
    if (template.renderedTemplate) {
      return false;
    }
    try {
      const renderedTemplate = renderVersionedSchema(
        template.content,
        this._extensions
      );
      if (!dryRun) {
        await patchDoc(template.ref, { renderedTemplate });
      }
      logger.next(`Migrated: ${template.ref.path}`);
      return true;
    } catch (err) {
      logger.next(`Failed: ${template.ref.path}`);
      return false;
    }
  }

  private async _migrateAutomationCustomTemplate(
    automation: WithRef<IAutomation<IAutomatedNotification | IGeneratedTask>>,
    dryRun: boolean
  ): Promise<boolean> {
    if (!isAutomatedNotification(automation.data)) {
      return false;
    }
    if (!automation.data.customTemplate) {
      return false;
    }
    if (automation.data.customTemplate.renderedTemplate) {
      return false;
    }

    const customTemplate = {
      ...automation.data.customTemplate,
      renderedTemplate: renderVersionedSchema(
        automation.data.customTemplate.content,
        this._extensions
      ),
    };
    if (!dryRun) {
      await patchDoc(automation.ref, {
        data: {
          ...automation.data,
          customTemplate,
        },
      });
    }
    return true;
  }
}
