import { ChangeDetectionStrategy, Component } from '@angular/core';
import { type WithRef } from '@principle-theorem/shared';
import { CurrentPracticeScope } from '@principle-theorem/ng-principle-shared';
import { type IPractice } from '@principle-theorem/principle-core/interfaces';
import { type Observable } from 'rxjs';

@Component({
  selector: 'pr-receptionist-dashboard',
  templateUrl: './receptionist-dashboard.component.html',
  styleUrls: ['./receptionist-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceptionistDashboardComponent {
  practice$: Observable<WithRef<IPractice>>;

  constructor(private _practiceScope: CurrentPracticeScope) {
    this.practice$ = this._practiceScope.asObservable();
  }
}
