import { confirmationDialogData } from '@principle-theorem/ng-shared';

export const BALANCE_OVERPAYMENT_CONFIRMATION_DIALOG_DATA =
  confirmationDialogData({
    title: 'Balance Invoice',
    prompt: [
      `This will amend the invoice by adding a deposit line item equal to the 
      amount overpaid. This amount will then be available to the patient as 
      account credit.`,
      `Are you sure you want to balance the invoice?`,
    ],
    submitLabel: 'Balance Invoice',
    submitColor: 'warn',
  });

export const WRITE_OFF_INVOICE_CONFIRMATION_DIALOG_DATA =
  confirmationDialogData({
    title: 'Write Off Invoice',
    prompt: `Are you sure you want to write off this invoice?`,
    submitLabel: 'Write Off',
    submitColor: 'warn',
  });

export const CANCEL_INVOICE_CONFIRMATION_DIALOG_DATA = confirmationDialogData({
  title: 'Cancel Invoice',
  prompt: 'Reason for cancelling this invoice',
  submitLabel: 'Cancel Invoice',
  submitColor: 'warn',
});
