import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  type OnDestroy,
  type QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type INotable,
  type INote,
} from '@principle-theorem/principle-core/interfaces';
import {
  type IReffable,
  multiFilter,
  patchDoc,
  toMoment,
} from '@principle-theorem/shared';
import { type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { EditNoteComponent } from './edit-note/edit-note.component';

@Component({
    selector: 'pr-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NotesComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByNote = TrackByFunctions.uniqueId<INote>();
  resource$: ReplaySubject<INotable & IReffable> = new ReplaySubject(1);
  notes$: Observable<INote[]>;
  pinnedNotes$: Observable<INote[]>;
  @Input() addNoteBottom = false;
  @ViewChildren(EditNoteComponent) noteComponents: QueryList<EditNoteComponent>;
  @ViewChild('scrollMe', { static: false })
  myScrollContainer: ElementRef<HTMLElement>;

  @Input()
  @HostBinding('class.scroll')
  scroll = false;

  @Input()
  set resource(resource: INotable & IReffable) {
    this.resource$.next(resource);
  }

  constructor() {
    this.notes$ = this.resource$.pipe(
      map((resource) => this._sortNotes(resource.notes))
    );
    this.pinnedNotes$ = this.notes$.pipe(multiFilter((note) => note.pinned));

    this.notes$
      .pipe(startWith(undefined), takeUntil(this._onDestroy$))
      .subscribe(() => setTimeout(() => this._scrollToBottom(), 50));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async addNote(note: INote, resource: INotable & IReffable): Promise<void> {
    resource.notes.push(note);
    await patchDoc(resource.ref, { notes: resource.notes });
  }

  private _scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {
      // Do nada
    }
  }

  /**
   * Sort Patient Notes
   * @param notes
   */
  private _sortNotes(notes: INote[]): INote[] {
    return notes.sort((noteA: INote, noteB: INote): number => {
      const sortPinned: number = this._sortByPinned(noteA, noteB);
      if (sortPinned !== 0) {
        return sortPinned;
      }
      return this._sortByUpdated(noteA, noteB);
    });
  }

  /**
   * Sort notes by pinned statuses first
   * @param noteA
   * @param noteB
   */
  private _sortByPinned(noteA: INote, noteB: INote): number {
    if (noteA.pinned && !noteB.pinned) {
      return -1;
    }
    if (!noteA.pinned && noteB.pinned) {
      return 1;
    }
    return 0;
  }

  /**
   * Sort notes based on latest updated
   * @param noteA
   * @param noteB
   */
  private _sortByUpdated(noteA: INote, noteB: INote): number {
    if (toMoment(noteA.updatedAt).isAfter(toMoment(noteB.updatedAt))) {
      return -1;
    }
    if (toMoment(noteA.updatedAt).isBefore(toMoment(noteB.updatedAt))) {
      return 1;
    }
    return 0;
  }
}
