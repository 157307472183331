import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import { HotTableRegisterer } from '@handsontable/angular';
import {
  OrganisationService,
  PerioSettingsService,
} from '@principle-theorem/ng-principle-shared';
import type { default as HandsonTable } from 'handsontable';
import { ColumnDataGetterSetterFunction } from 'handsontable/common';
import { isNull, isNumber } from 'lodash';
import { ReplaySubject, Subject, type Observable } from 'rxjs';
import {
  filter,
  map,
  skip,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { perioTableToHandsOnTable } from '../perio-table/perio-hands-on-table';
import {
  isPerioMeasurementValue,
  resolveTableMetadata,
  type IPerioTable,
  type IPerioTableCell,
} from '../perio-table/perio-table';

const EXCLUDED_EVENT_SOURCES: HandsonTable.ChangeSource[] = [
  'loadData',
  'populateFromArray',
];

interface IAfterChangeEvent {
  changes: HandsonTable.CellChange[];
  source: HandsonTable.ChangeSource;
}

@Component({
  selector: 'pr-perio-table-display',
  templateUrl: './perio-table-display.component.html',
  styleUrls: ['./perio-table-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerioTableDisplayComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _hotRegisterer = new HotTableRegisterer();
  private _perioTable$ = new ReplaySubject<IPerioTable>(1);
  private _afterChange$ = new Subject<IAfterChangeEvent>();
  @Output() perioCellChange = new EventEmitter<IPerioTableCell>();
  settings$: Observable<HandsonTable.GridSettings>;
  hotId = uuid();

  @Input()
  set perioTable(perioTable: IPerioTable) {
    if (perioTable) {
      this._perioTable$.next(perioTable);
    }
  }

  constructor(
    private _perioSettings: PerioSettingsService,
    private _organisation: OrganisationService
  ) {
    this.settings$ = this._perioTable$.pipe(
      map((table) => perioTableToHandsOnTable(table)),
      map((settings) => ({
        ...settings,
        afterChange: (changes, source) =>
          this._afterChange$.next({
            changes: changes ?? [],
            source,
          }),
      }))
    );

    this._afterChange$
      .pipe(
        filter((event) => !EXCLUDED_EVENT_SOURCES.includes(event.source)),
        skip(1), // skips the edit event that is triggered when the table is first loaded
        withLatestFrom(this._perioTable$),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([event, perioTable]) => {
        this.handleChanges(perioTable, event.changes);
      });

    this._organisation.stafferSettings$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((settings) => {
        this._perioSettings.applyStafferSettings(settings?.charting?.perio);
      });

    this.settings$
      .pipe(skip(1), takeUntil(this._onDestroy$))
      .subscribe((settings) =>
        this._hotRegisterer.getInstance(this.hotId).updateSettings(settings)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  handleCellNavigation(event: KeyboardEvent): void {
    const isValidKeyEvent = this._perioSettings.isValidKeyEvent(event);
    const isAutoSwitchOn = this._perioSettings.autoSwitchCells$.getValue();

    if (isValidKeyEvent && isAutoSwitchOn) {
      const hotInstance = this._hotRegisterer.getInstance(this.hotId);
      this._perioSettings.handleCellNavigation(hotInstance, this.hotId);
    }
  }

  handleChanges(
    perioTable: IPerioTable,
    changes: HandsonTable.CellChange[]
  ): void {
    changes.map(([row, column, previous, current]) => {
      this._handleChange(perioTable, row, column, previous, current);
    });
  }

  private _handleChange(
    perioTable: IPerioTable,
    row: number,
    column: number | string | ColumnDataGetterSetterFunction,
    previous: unknown,
    current: unknown
  ): void {
    const sanitisedValue = isNull(current) ? undefined : current;
    if (
      previous === sanitisedValue ||
      !isPerioMeasurementValue(sanitisedValue)
    ) {
      return;
    }
    if (!isNumber(column)) {
      throw new Error(`Column is not a number: ${column.toString()}`);
    }
    const metadata = resolveTableMetadata(perioTable, row, column);
    this.perioCellChange.emit({ metadata, value: sanitisedValue });
  }
}
