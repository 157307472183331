import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BridgeDevice } from '@principle-theorem/principle-core';
import {
  IBridgeDevice,
  ITwainDevice,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  WithRef,
  snapshot,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ITwainDeviceSelectDialogData {
  deviceRef: DocumentReference<IBridgeDevice>;
}

@Component({
  selector: 'pr-twain-device-select-dialog',
  templateUrl: './twain-device-select-dialog.component.html',
  styleUrl: './twain-device-select-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwainDeviceSelectDialogComponent {
  allDevices$: Observable<ITwainDevice[]>;
  enabledDevices$: Observable<ITwainDevice[]>;
  disabledDevices$: Observable<ITwainDevice[]>;
  isSyncing$: Observable<boolean>;
  bridgeDevice$: Observable<WithRef<IBridgeDevice>>;
  showDisabled = signal(false);

  constructor(
    public dialogRef: MatDialogRef<TwainDeviceSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ITwainDeviceSelectDialogData
  ) {
    this.bridgeDevice$ = Firestore.doc$(data.deviceRef);
    this.allDevices$ = this.bridgeDevice$.pipe(
      map((bridgeDevice) => bridgeDevice.twainSettings?.devices ?? [])
    );
    this.enabledDevices$ = this.allDevices$.pipe(
      map((devices) => devices.filter((device) => !device.deleted))
    );
    this.disabledDevices$ = this.allDevices$.pipe(
      map((devices) => devices.filter((device) => device.deleted))
    );
    this.isSyncing$ = this.bridgeDevice$.pipe(
      map((bridgeDevice) => !!bridgeDevice.twainSettings?.syncing)
    );
  }

  async restoreDevice(deviceUid: string): Promise<void> {
    const bridgeDevice = await snapshot(this.bridgeDevice$);
    await BridgeDevice.patchTwainDevice(bridgeDevice, deviceUid, {
      deleted: false,
    });
  }
}
