<div class="layout-padding page-content">
  <div class="search-bar" fxLayout="row" fxLayoutGap="16px">
    <pt-search-field fxFlex>
      <input matInput placeholder="Search" [formControl]="searchCtrl" />
    </pt-search-field>
    <button mat-raised-button color="primary" (click)="addNewTask()">
      Create Recurring Task
    </button>
  </div>

  <mat-progress-bar
    mode="indeterminate"
    *ngIf="loading$ | async"
   />

  <pr-empty-state
    *ngIf="emptyState$ | async"
    image="task"
    title="recurring tasks"
   />

  <ng-container
    *ngFor="
      let configurationGroup of configurationGroups$ | async;
      trackBy: trackByGroup
    "
  >
    <div class="task-group" *ngIf="configurationGroup.configurations.length">
      <h2>{{ configurationGroup.name }}</h2>

      <mat-list dense>
        <mat-list-item
          *ngFor="
            let configuration of configurationGroup.configurations;
            trackBy: trackByConfiguration
          "
        >
          <pr-content-text
            matListItemTitle
            [content]="configuration.title"
           />
          <span matListItemLine class="mat-caption">{{
            configuration.recurrencePattern | map : getPatternSummary
          }}</span>

          <div matListItemMeta>
            <button mat-icon-button [matMenuTriggerFor]="configMenu">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #configMenu="matMenu">
              <button
                mat-menu-item
                *ngIf="!configuration.deleted"
                (click)="edit(configuration)"
              >
                <mat-icon>edit</mat-icon>
                Edit
              </button>

              <button
                mat-menu-item
                *ngIf="!configuration.deleted"
                (click)="delete(configuration)"
              >
                <mat-icon color="warn">delete</mat-icon>
                Delete
              </button>

              <button
                mat-menu-item
                *ngIf="configuration.deleted"
                (click)="undoDelete(configuration)"
              >
                <mat-icon>undo</mat-icon>
                Undo Delete
              </button>
            </mat-menu>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </ng-container>
</div>
