import { getInputType } from '@ajsf/core';
import {
  AnyFormLayout,
  FormLayoutElementType,
  IFormSchema,
  IFormSchemaProperty,
} from '@principle-theorem/principle-core/interfaces';
import { compact, get, isArray, isString, startCase, uniq } from 'lodash';

export const EMPTY_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {},
};

export class AngularJsonSchemaForm {
  static getSchemaPropertyPath(key: string): string {
    const propertyKey = 'properties';
    const path = key.split('.').join(`.${propertyKey}.`);
    return `${propertyKey}.${path}`;
  }

  static getSchemaProperty(
    schema: IFormSchema,
    key?: string
  ): IFormSchemaProperty | IFormSchema | undefined {
    if (!key) {
      return;
    }
    const path = this.getSchemaPropertyPath(key);
    const property: unknown = get(schema, path);
    return property as IFormSchemaProperty;
  }

  static getInputType(
    schema: IFormSchemaProperty | IFormSchema,
    layout?: AnyFormLayout
  ): string | undefined {
    const inputType: unknown = getInputType(schema, layout);
    return isString(inputType) ? inputType : undefined;
  }

  static getLayoutLabel(
    layout: AnyFormLayout,
    schema?: IFormSchemaProperty | IFormSchema
  ): string | undefined {
    if (layout.notitle) {
      return;
    }
    if (layout.title) {
      return layout.title;
    }
    const schemaTitle: unknown = get(schema, 'title');
    if (isString(schemaTitle)) {
      return schemaTitle;
    }
    const titleFromKey = layout.key?.split('.').pop();
    return titleFromKey ? startCase(titleFromKey) : undefined;
  }

  static getRawHtml(element: AnyFormLayout): string | undefined {
    if (element.type === FormLayoutElementType.Help) {
      const rawHelpValue: unknown = get(element, 'helpvalue');
      const helpValue = isString(rawHelpValue) ? rawHelpValue : undefined;
      return helpValue?.length ? helpValue : undefined;
    }
    return;
  }

  static getRequired(
    layout: AnyFormLayout,
    schema?: IFormSchemaProperty | IFormSchema
  ): boolean {
    const requiredValue = schema?.required;
    if (isArray(requiredValue)) {
      return !!layout.key && requiredValue.includes(layout.key);
    }
    return requiredValue ?? false;
  }

  static getEnumValues(
    _layout: AnyFormLayout,
    schema?: IFormSchemaProperty | IFormSchema
  ): string[] {
    if (!schema) {
      return [];
    }
    const values = (get(schema, 'enum') as string[] | undefined) ?? [];
    const defaultValue = get(schema, 'default') as string | undefined;
    return uniq(compact([defaultValue, ...values]));
  }
}
