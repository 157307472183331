import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountSummaryBloc } from '@principle-theorem/ng-principle-shared';
import { BasicDialogService } from '@principle-theorem/ng-shared';
import { Patient } from '@principle-theorem/principle-core';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import { deleteDoc, snapshot, type WithRef } from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-delete-patient-button',
    templateUrl: './delete-patient-button.component.html',
    styleUrls: ['./delete-patient-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DeletePatientButtonComponent {
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  isDisabled$: Observable<boolean>;

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(
    private _snackbar: MatSnackBar,
    private _basicDialog: BasicDialogService
  ) {
    this.isDisabled$ = this.patient$.pipe(map((patient) => patient.deleted));
  }

  async deletePatient(): Promise<void> {
    const patient = await snapshot(this.patient$);

    const accountSummaryBloc = new AccountSummaryBloc(this.patient$);
    const hasOutstandingDebt = await snapshot(accountSummaryBloc.outstanding$);
    const futureAppointment = await snapshot(
      Patient.nextScheduledAppointment$(patient)
    );

    if (hasOutstandingDebt || futureAppointment) {
      this._snackbar.open(
        'Patient must be finalised before deleting. Please ensure that there are no outstanding debts or future appointments.',
        'Dismiss',
        {
          duration: 10000,
        }
      );
      return;
    }

    const confirmed = await this._basicDialog.confirm({
      prompt: `Are you sure you want to delete this patient? This should only be used if the patient was created in error.`,
      title: `Confirm deleting patient`,
      submitLabel: 'Yes, delete the patient',
      submitColor: 'warn',
      cancelLabel: 'Cancel',
    });
    if (!confirmed) {
      return;
    }

    await deleteDoc(patient.ref);
    this._snackbar.open('Patient successfully deleted');
  }
}
