<ng-container *ngIf="dataSource.items$ | async as labJobs">
  <mat-card *ngIf="labJobs.length">
    <mat-card-header>
      <mat-card-title>Lab Jobs</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <pr-lab-job-list-display
        multiSelectDisabled
        [sort]="sort"
        [dataSource]="dataSource"
        [selectionList]="selectionList"
        (selectedItemChange)="editLabJob($event)"
      />
    </mat-card-content>
  </mat-card>
</ng-container>
