import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  CurrentAppointmentScope,
  CurrentPatientScope,
} from '@principle-theorem/ng-principle-shared';
import {
  Appointment,
  ClinicalChart,
  Patient,
} from '@principle-theorem/principle-core';
import {
  ChartType,
  type IAppointment,
  type IClinicalChart,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  getEnumValues,
  isWithRef,
  patchDoc,
  shareReplayCold,
  snapshot,
  type RequireProps,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, combineLatest, of, type Observable } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import {
  getMissingChartReason,
  type MissingChartReason,
} from './missing-chart-reasons';

@Component({
    selector: 'pr-appointment-charting',
    templateUrl: './appointment-charting.component.html',
    styleUrls: ['./appointment-charting.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentChartingComponent {
  appointment$: Observable<WithRef<IAppointment>>;
  patient$: Observable<WithRef<IPatient>>;
  chart$: Observable<WithRef<IClinicalChart> | undefined>;
  hasChart$: Observable<boolean>;
  missingChartReason$: Observable<MissingChartReason | undefined>;
  priorAppointmentLink$: Observable<string[]>;
  subsequentAppointmentLink$: Observable<string[]>;
  generatingChart$ = new BehaviorSubject<boolean>(false);
  isOutOfSync$: Observable<boolean>;
  selectedChartTypeIndex$: Observable<number>;
  chartType = ChartType;

  constructor(
    private _chartState: ChartFacade,
    private _appointmentScope: CurrentAppointmentScope,
    private _patientScope: CurrentPatientScope
  ) {
    this.appointment$ = this._appointmentScope.doc$.pipe(
      filterUndefined(),
      shareReplayCold()
    );
    this.patient$ = this._patientScope.doc$.pipe(filterUndefined());
    this.chart$ = this._chartState
      .clinicalChartState$(ChartId.InAppointment)
      .pipe(
        filter((chart): chart is WithRef<IClinicalChart> => isWithRef(chart)),
        shareReplayCold()
      );
    this.hasChart$ = combineLatest([this.chart$, this.appointment$]).pipe(
      map(([chart, appointment]) => !!appointment.clinicalChart || !!chart)
    );
    this.isOutOfSync$ = this._chartState.isOutOfSync$(ChartId.InAppointment);
    this.selectedChartTypeIndex$ = this._chartState
      .chartType$(ChartId.InAppointment)
      .pipe(map((chartType) => getEnumValues(ChartType).indexOf(chartType)));

    const subsequentAppointment$ = combineLatest([
      this.appointment$,
      this.patient$,
    ]).pipe(
      switchMap(([appointment, patient]) => {
        if (!appointment.event) {
          return of(undefined);
        }
        return Patient.getSubsequentAppointment$(
          patient,
          appointment as RequireProps<IAppointment, 'event'>
        );
      })
    );

    this.missingChartReason$ = combineLatest([
      subsequentAppointment$,
      this.appointment$,
      this.chart$.pipe(startWith(undefined)),
    ]).pipe(
      switchMap(([subsequentAppointment, currentAppointment, chart]) =>
        getMissingChartReason(currentAppointment, chart, subsequentAppointment)
      )
    );

    this.subsequentAppointmentLink$ = subsequentAppointment$.pipe(
      filterUndefined(),
      map((subsequentAppointment) => ['../../', subsequentAppointment.ref.id])
    );
  }

  async viewLatestChart(): Promise<void> {
    const patient = await snapshot(this.patient$);
    const latestChart = await snapshot(ClinicalChart.getLatestChart$(patient));

    if (!latestChart) {
      return;
    }

    this._chartState.setChart(ChartId.InAppointment, {
      ...latestChart,
      immutable: true,
    });
  }

  async generateChart(): Promise<void> {
    this.generatingChart$.next(true);
    const appointment = await snapshot(this.appointment$);
    if (appointment.clinicalChart) {
      await this.setChart(appointment);
      this.generatingChart$.next(false);
      return;
    }

    const patient = await snapshot(this.patient$);
    appointment.clinicalChart = await ClinicalChart.cloneFromLatest(patient);
    await patchDoc(appointment.ref, {
      clinicalChart: appointment.clinicalChart,
    });
    await this.setChart(appointment);
    this.generatingChart$.next(false);
  }

  async setChart(appointment: WithRef<IAppointment>): Promise<void> {
    const chart = await snapshot(Appointment.clinicalChart$(appointment));
    if (!chart) {
      // eslint-disable-next-line no-console
      console.error('Failed to Set Chart');
      return;
    }
    this._chartState.setChart(ChartId.InAppointment, chart);
  }

  setChartType(index: number): void {
    const type = index === 0 ? ChartType.Clinical : ChartType.Periodontal;
    this._chartState.setChartType(ChartId.InAppointment, type);
  }
}
