import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';

@Component({
  selector: 'pt-buttons-container',
  templateUrl: './buttons-container.component.html',
  styleUrls: ['./buttons-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NgMaterialModule],
})
export class ButtonsContainerComponent {
  @Input() alignment: 'start' | 'center' | 'end' | 'space-between' = 'end';
  @Input() direction: 'row' | 'column' = 'row';
  @Input() padding: 'vertical' | 'horizontal' | 'all-round' | 'none' =
    'vertical';

  public get fxAlignment(): string {
    return this.direction === 'row'
      ? `${this.alignment} center`
      : `start stretch`;
  }
}
