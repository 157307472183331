import { type DocumentReference } from '@principle-theorem/shared';
import { type IBrand } from '../brand';
import { type IOrganisation } from '../organisation/organisation';
import { type IPractice } from '../practice/practice';
import { type IStaffer } from '../staffer/staffer';

export enum SenderType {
  Staffer = 'staffer',
  Practice = 'practice',
  Brand = 'brand',
  Organisation = 'organisation',
}

export type Sender =
  | {
      ref: DocumentReference<IStaffer>;
      type: SenderType.Staffer;
    }
  | {
      ref: DocumentReference<IPractice>;
      type: SenderType.Practice;
    }
  | {
      ref: DocumentReference<IBrand>;
      type: SenderType.Brand;
    }
  | {
      ref: DocumentReference<IOrganisation>;
      type: SenderType.Organisation;
    };
