import { type IXSLXImport } from '@principle-theorem/shared';
import type { Row } from 'exceljs';
import {
  type IItemCodeToConditionXSLX,
  ITEM_CODE_TO_CONDITION_HEADERS,
} from './item-code-to-condition-to-xlsx';

export class XLSXToItemCodeToCondition
  implements IXSLXImport<IItemCodeToConditionXSLX>
{
  headers = ITEM_CODE_TO_CONDITION_HEADERS;

  translate(row: Row): IItemCodeToConditionXSLX {
    return {
      code: row.getCell('code').value?.toString() ?? '',
      description: row.getCell('description').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IItemCodeToConditionXSLX['mapTo'],
    };
  }
}
