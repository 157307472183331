import {
  TypeGuard,
  isObject,
  type IFirestoreModel,
  type INamedDocument,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type IInteraction } from '../interaction/interaction';
import { type INote } from '../note/note';

export type IContact = IFirestoreModel & IContactable;

interface IBaseContactDetails {
  name: string;
  mobileNumber?: string;
  jobTitle?: string;
}

interface IContactEmailContactDetails extends IBaseContactDetails {
  email: string;
  address?: string;
  phone?: string;
}

interface IContactPhoneContactDetails extends IBaseContactDetails {
  phone: string;
  email?: string;
  address?: string;
}

interface IContactAddressContactDetails extends IBaseContactDetails {
  address: string;
  email?: string;
  phone?: string;
}

export type IContactDetails =
  | IContactEmailContactDetails
  | IContactPhoneContactDetails
  | IContactAddressContactDetails;

export type IContactable = IContactDetails & {
  notes: INote[];
  interactions: IInteraction[];
  parentRef?: IContactRef;
};

export function isContactDetails(contact: unknown): contact is IContactDetails {
  return (
    isObject(contact) &&
    isString(contact.name) &&
    (isString(contact.email) ||
      isString(contact.address) ||
      isString(contact.phone)) &&
    TypeGuard.undefinedOr(isString)(contact.mobileNumber) &&
    TypeGuard.undefinedOr(isString)(contact.jobTitle)
  );
}

export function isContactable(contact: unknown): contact is IContactable {
  return (
    isObject(contact) &&
    'notes' in contact &&
    Array.isArray(contact.notes) &&
    'interactions' in contact &&
    Array.isArray(contact.interactions) &&
    isContactDetails(contact)
  );
}

export function isContact(contact: unknown): contact is IContact {
  return isContactable(contact);
}

export interface IContactRef extends INamedDocument {
  type: ContactResourceType;
}

export enum ContactResourceType {
  Contact = 'contact',
  Lab = 'lab',
}

/**
 * @deprecated
 * SubContacts to be removed entirely
 * Kept for migration purposes
 */
export type ISubContactDetails = IContactDetails & {
  uid: string;
};

/**
 * @deprecated
 * Original format for contact details
 * Kept for migration purposes
 */
export type IContactableDeprecated = IContactDetails & {
  notes: INote[];
  interactions: IInteraction[];
  subContacts: ISubContactDetails[];
};

export function isDeprecatedContact(
  contact: unknown
): contact is IContactableDeprecated {
  return isObject(contact) && 'subContacts' in contact;
}
