<div class="week-header" fxLayout="row">
  <div class="hour-list"></div>

  <ng-container *ngIf="days$ | async as days">
    <pr-week-day-header
      *ngFor="let day of days; trackBy: trackByDay"
      [day]="day"
      fxFlex
     />
  </ng-container>
</div>
