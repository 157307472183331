<mat-toolbar color="accent">Edit Group</mat-toolbar>

<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="flex flex-col gap-4">
      <pr-information-box class="mb-2">
        Select how you want your data grouped together. Some common ways to
        group your data would be to group by practitioner, date, or practice
        (for multi-practice reporting). For more nice reports you can also group
        by data points such as tags and categories.
      </pr-information-box>

      <mat-form-field fxFlex>
        <mat-label>Group Data By</mat-label>
        <input
          matInput
          #autoComplete="matAutocompleteTrigger"
          [matAutocomplete]="auto"
          [formControl]="groupBySearchCtrl"
          (click)="autoComplete.openPanel()"
          (blur)="blurGroupBy()"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="groupBySelected($event)"
        >
          @for (section of groupBySearch.results$ | async; track section.name) {
            @if (section.options.length) {
              <mat-optgroup [label]="section.name">
                @for (
                  option of section.options;
                  track option.measure.metadata.id
                ) {
                  <mat-option [value]="option.measure">
                    {{ option.measure.metadata.label }}
                  </mat-option>
                }
              </mat-optgroup>
            }
          }
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
    <button type="submit" mat-flat-button color="primary" (click)="submit()">
      Save
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
