import { type ISource } from '@principle-theorem/principle-core/interfaces';
import { BaseSourceHandler } from '../source/base-source-handler';
import { CalendarSourceEntity } from './source/entities/calendars';
import { ContactSourceEntity } from './source/entities/contacts';
import { EventClassSourceEntity } from './source/entities/event-classes';
import { InsuranceOptionSourceEntity } from './source/entities/insurance-options';
import { ItemCodeSourceEntity } from './source/entities/item-codes';
import { PatientAlertSourceEntity } from './source/entities/patient-alerts';
import { PatientAllergySourceEntity } from './source/entities/patient-allergies';
import { PatientAppointmentSourceEntity } from './source/entities/patient-appointments';
import { PatientAttachmentSourceEntity } from './source/entities/patient-attachments';
import { PatientChartedItemSourceEntity } from './source/entities/patient-charted-items';
import { PatientClaimItemSourceEntity } from './source/entities/patient-claim-items';
import { PatientClaimSourceEntity } from './source/entities/patient-claims';
import { PatientCreditNoteLineItemSourceEntity } from './source/entities/patient-credit-note-line-items';
import { PatientCreditNoteSourceEntity } from './source/entities/patient-credit-notes';
import { PatientInsuranceDetailSourceEntity } from './source/entities/patient-insurance-details';
import { PatientInteractionSourceEntity } from './source/entities/patient-interactions';
import { PatientInvoiceLineItemSourceEntity } from './source/entities/patient-invoice-line-items';
import { PatientInvoiceSourceEntity } from './source/entities/patient-invoices';
import { PatientMedicalConditionSourceEntity } from './source/entities/patient-medical-conditions';
import { PatientMedicalHistorySourceEntity } from './source/entities/patient-medical-histories';
import { PatientPaymentAllocationSourceEntity } from './source/entities/patient-payment-allocations';
import { PatientPaymentSourceEntity } from './source/entities/patient-payments';
import { PatientPeriodontalChartItemSourceEntity } from './source/entities/patient-periodontal-chart-items';
import { PatientPeriodontalChartSourceEntity } from './source/entities/patient-periodontal-charts';
import { PatientPrepaymentLineItemSourceEntity } from './source/entities/patient-prepayment-line-items';
import { PatientPrepaymentSourceEntity } from './source/entities/patient-prepayments';
import { PatientTreatmentNoteSourceEntity } from './source/entities/patient-treatment-notes';
import { PatientTreatmentSourceEntity } from './source/entities/patient-treatments';
import { PatientSourceEntity } from './source/entities/patients';
import { PaymentTypeSourceEntity } from './source/entities/payment-types';
import { PracticeLocationSourceEntity } from './source/entities/practice-locations';
import { ProviderSourceEntity } from './source/entities/providers';
import { SterilisationRecordSourceEntity } from './source/entities/sterilisation-records';
import { PatientMediaSourceEntity } from './source/entities/patient-media';
import { PatientMediaCategorySourceEntity } from './source/entities/patient-media-categories';
import { PatientAllergyOptionsSourceEntity } from './source/entities/patient-allergy-options';
import { PatientMedicalConditionOptionSourceEntity } from './source/entities/patient-medical-condition-options';
import { CorePracticeAppointmentsTranslator } from './sql-seeding-translators/appointments';
import { CorePracticePeriodontalChartItemsTranslator } from './sql-seeding-translators/periodontal-chart-items.ts';
import { CorePracticeSterilisationTrackingTranslator } from './sql-seeding-translators/sterilisation-tracking';

export const CORE_PRACTICE_SOURCE: ISource = {
  metadata: {
    name: 'Core Practice',
    version: 'v1',
  },
  configuration: [
    {
      name: 'Practice Id',
      description: 'Practice Id',
      value: '',
    },
    {
      name: 'Database Username',
      description: 'Database Username',
      value: '',
    },
    {
      name: 'Database Password',
      description: 'Database Password',
      value: '',
    },
    {
      name: 'Database Connection Name',
      description:
        'Fully Qualified Name of Database. Eg. "practice-migrations:australia-southeast1:[instance_name]"',
      value: '',
    },
    {
      name: 'Source Bucket Storage Path',
      description: `Bucket storage path that patient files live under Eg. "gs://practice-name'`,
      value: '',
    },
  ],
};

export class CorePracticeSourceHandler extends BaseSourceHandler {
  migrationType = 'Core Practice';
  migrationVersion = 'v1';
  source = CORE_PRACTICE_SOURCE;

  entityHandlers = [
    new CalendarSourceEntity(),
    new ContactSourceEntity(),
    new EventClassSourceEntity(),
    new SterilisationRecordSourceEntity(),
    new InsuranceOptionSourceEntity(),
    new ItemCodeSourceEntity(),
    new PatientAlertSourceEntity(),
    new PatientAllergySourceEntity(),
    new PatientAppointmentSourceEntity(),
    new PatientAttachmentSourceEntity(),
    new PatientChartedItemSourceEntity(),
    new PatientPeriodontalChartItemSourceEntity(),
    new PatientClaimItemSourceEntity(),
    new PatientClaimSourceEntity(),
    new PatientCreditNoteLineItemSourceEntity(),
    new PatientCreditNoteSourceEntity(),
    new PatientInsuranceDetailSourceEntity(),
    new PatientInteractionSourceEntity(),
    new PatientInvoiceLineItemSourceEntity(),
    new PatientInvoiceSourceEntity(),
    new PatientMedicalConditionSourceEntity(),
    new PatientMedicalHistorySourceEntity(),
    new PatientPaymentAllocationSourceEntity(),
    new PatientPaymentSourceEntity(),
    new ProviderSourceEntity(),
    new PatientPeriodontalChartSourceEntity(),
    new PatientPrepaymentLineItemSourceEntity(),
    new PatientPrepaymentSourceEntity(),
    new PatientTreatmentNoteSourceEntity(),
    new PatientTreatmentSourceEntity(),
    new PaymentTypeSourceEntity(),
    new PatientSourceEntity(),
    new PracticeLocationSourceEntity(),
    new PatientMediaSourceEntity(),
    new PatientMediaCategorySourceEntity(),
    new PatientAllergyOptionsSourceEntity(),
    new PatientMedicalConditionOptionSourceEntity(),
  ];

  sqlSeedingTranslators = [
    new CorePracticeAppointmentsTranslator(),
    new CorePracticePeriodontalChartItemsTranslator(),
    new CorePracticeSterilisationTrackingTranslator(),
  ];
}
