import {
  IHealthPointTransactionExtendedData,
  isHealthPointTransactionExtendedData,
} from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { GeneralProviderActionsService } from '../../../general-provider-actions.service';
import {
  ITransactionAction,
  ITransactionActionsData,
} from '../../../transaction-action';
import { TransactionHelpers } from '../../../transaction-helpers';

export class AmendHealthPointTransaction
  implements ITransactionAction<IHealthPointTransactionExtendedData>
{
  icon = 'edit';
  label = 'Amend';
  tooltip = 'Amend if claim has been manually adjusted via health fund';
  inProgress$ = new BehaviorSubject<boolean>(false);
  typeGuardFn = isHealthPointTransactionExtendedData;

  constructor(private _actions: GeneralProviderActionsService) {}

  canDo$(
    data: ITransactionActionsData<IHealthPointTransactionExtendedData>
  ): Observable<boolean> {
    return TransactionHelpers.canAmend$(data);
  }

  async do(
    data: ITransactionActionsData<IHealthPointTransactionExtendedData>
  ): Promise<void> {
    this.inProgress$.next(true);
    await this._actions.amend(
      data.invoice,
      data.latestTransaction,
      'Amend Heath Claim Amount'
    );
    this.inProgress$.next(false);
  }
}
