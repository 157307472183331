<form class="flex flex-col gap-4" [formGroup]="form">
  <mat-form-field subscriptSizing="dynamic">
    <mat-label>Label</mat-label>
    <input #autoFocusElement matInput formControlName="label" />
  </mat-form-field>

  <mat-slide-toggle
    class="px-2"
    labelPosition="before"
    formControlName="required"
  >
    Required
  </mat-slide-toggle>
</form>
