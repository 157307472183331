import {
  IConfirmAppointmentInfo,
  IEvent,
} from '@principle-theorem/principle-core/interfaces';
import { toMoment } from '@principle-theorem/shared';
import {
  CalendarEvent,
  google,
  ics,
  office365,
  outlook,
  yahoo,
} from 'calendar-link';
import { compact } from 'lodash';

export class AddToCalendarLink {
  /**
   * https://calendar.google.com/calendar/render...
   */
  static readonly google = google;

  /**
   * https://outlook.live.com/owa/...
   */
  static readonly outlook = outlook;

  /**
   * https://outlook.office.com/owa/...
   */
  static readonly office365 = office365;

  /**
   * https://calendar.yahoo.com/?v=60&title=...
   */
  static readonly yahoo = yahoo;

  /**
   * standard ICS file based on https://icalendar.org
   */
  static readonly ics = ics;

  /**
   * Build the calndar event required to generate links
   */
  static calendarEventFromEvent(event: IEvent, title: string): CalendarEvent {
    return {
      title,
      location: event.practice.name,
      start: toMoment(event.from).toISOString(),
      end: toMoment(event.to).toISOString(),
      busy: true,
    };
  }

  static calendarEventFromAppointmentInfo(
    event: IConfirmAppointmentInfo
  ): CalendarEvent {
    const title = compact([
      'Appointment',
      event.practitioner ? `with ${event.practitioner}` : undefined,
      `at ${event.practice}`,
    ]).join(' ');

    return {
      title,
      description: event.treatment,
      location: event.practiceAddress,
      start: toMoment(event.startAt).toISOString(),
      end: toMoment(event.endAt).toISOString(),
      busy: true,
    };
  }
}
