import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  ChartFacade,
  ChartId,
  TreatmentPlanFacade,
} from '@principle-theorem/ng-clinical-charting/store';
import { CurrentPatientScope } from '@principle-theorem/ng-principle-shared';
import {
  TypedFormControl,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  ITreatmentPlan,
  type ChartSection,
  type ChartView,
  type IClinicalChart,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  filterUndefined,
  isSameRef,
  patchDoc,
  snapshot,
  sortByCreatedAt,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-chart-view-toolbar',
    templateUrl: './chart-view-toolbar.component.html',
    styleUrls: ['./chart-view-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChartViewToolbarComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  section$: Observable<ChartSection>;
  view$: Observable<ChartView>;
  chart$: Observable<WithRef<IClinicalChart> | IClinicalChart>;
  treatmentPlans$: Observable<WithRef<ITreatmentPlan>[]>;
  treatmentPlanCtrl = new TypedFormControl<WithRef<ITreatmentPlan> | undefined>(
    undefined
  );
  @Input() showHistorySelector = true;

  constructor(
    private _chartStore: ChartFacade,
    private _patientScope: CurrentPatientScope,
    private _treatmentPlanFacade: TreatmentPlanFacade
  ) {
    this.section$ = this._chartStore.chartSection$(ChartId.InAppointment);
    this.view$ = this._chartStore.chartView$(ChartId.InAppointment);
    this.chart$ = this._chartStore.clinicalChartState$(ChartId.InAppointment);
    this.treatmentPlans$ = this._treatmentPlanFacade.treatmentPlans$.pipe(
      map((plans) => plans.sort(sortByCreatedAt))
    );

    combineLatest([
      this._treatmentPlanFacade.selectedTreatmentPlan$,
      this.treatmentPlans$,
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([selectedPlan, allPlans]) =>
        this.treatmentPlanCtrl.setValue(selectedPlan ?? first(allPlans), {
          emitEvent: false,
        })
      );

    formControlChanges$(this.treatmentPlanCtrl)
      .pipe(filterUndefined(), takeUntil(this._onDestroy$))
      .subscribe((plan) =>
        this._treatmentPlanFacade.selectTreatmentPlan(plan.ref)
      );
  }

  async selectView(view: ChartView): Promise<void> {
    if (view) {
      const selectedPatient = await snapshot(this._patientScope.doc$);
      if (selectedPatient) {
        await patchDoc(selectedPatient.ref, {
          settings: {
            charting: {
              view,
            },
          },
        });
      }
      this._chartStore.setChartView(ChartId.InAppointment, view);
      this._chartStore.setSelectedSurfaces(ChartId.InAppointment, []);
    }
  }

  selectSection(section: ChartSection): void {
    if (section) {
      this._chartStore.setChartSection(ChartId.InAppointment, section);
      this._chartStore.setSelectedSurfaces(ChartId.InAppointment, []);
    }
  }

  selectChart(chart: WithRef<IClinicalChart>): void {
    this._chartStore.setChart(ChartId.InAppointment, chart);
  }

  compareWithFn(
    aRef?: WithRef<ITreatmentPlan>,
    bRef?: WithRef<ITreatmentPlan>
  ): boolean {
    return isSameRef(aRef, bRef);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
