import { PractitionerIncomeReportType } from './store/practitioner-income.reducers';

const invoicePaidDisplayColumns = [
  'treatmentsInvoicedAmount',
  'depositsTakenAmount',
  'discountsAppliedAmount',
  'creditsAppliedAmount',
  'receiptedCommissionAmount',
];

const invoiceIssuedDisplayColumns = [
  'treatmentsInvoicedAmount',
  'depositsTakenAmount',
  'discountsAppliedAmount',
  'invoicedCommissionAmount',
];

export function getColumnsForReportType(
  reportType: PractitionerIncomeReportType
): string[] {
  return reportType === PractitionerIncomeReportType.ByInvoiceIssuedDate
    ? invoiceIssuedDisplayColumns
    : invoicePaidDisplayColumns;
}
