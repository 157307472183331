<ng-container *ngIf="treatmentPackage$ | async as package">
  <mat-card appearance="outlined" class="flex-1" class="treatment">
    <mat-card-content>
      <form [formGroup]="form" class="flex flex-col gap-2">
        <mat-form-field class="basis-9/12">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>

        <mat-slide-toggle formControlName="isDefault"
          >Apply package price by default</mat-slide-toggle
        >

        <mat-form-field>
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>

        <pr-edit-treatment-package-service-items
          [serviceCodes]="serviceCodes$ | async"
          [priceOverrides]="package.priceOverrides"
          (updateServiceItems)="updateServiceItems($event)"
         />

        <div class="flex items-center justify-between">
          <strong>Total Package Price</strong>
          <strong>{{ totalPrice$ | async | currency }}</strong>
        </div>

        <pt-buttons-container>
          <button mat-stroked-button color="warn" (click)="delete()">
            Delete
          </button>
          <button
            mat-flat-button
            color="primary"
            [disabled]="isDisabled$ | async"
            (click)="save()"
          >
            Save
          </button>
        </pt-buttons-container>
      </form>
    </mat-card-content>
  </mat-card>
</ng-container>
