import {
  type IGetRecordResponse,
  PatientStatus,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import {
  BaseExistingPatientDestinationEntity,
  type IExistingPatientMergeConflictSummary,
} from '../../../destination/entities/existing-patient';
import {
  ID4WPatient,
  PatientSourceEntity,
} from '../../source/entities/patient';
import { getGender, getName, getPhone } from './patients';

export class ExistingPatientDestinationEntity extends BaseExistingPatientDestinationEntity<ID4WPatient> {
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: this.patientSourceEntity,
  };

  getDateOfBirth(
    sourcePatient: IGetRecordResponse<ID4WPatient>
  ): string | undefined {
    return sourcePatient.data.data.dob ?? undefined;
  }

  filterMatchingPatients(
    sourcePatient: ID4WPatient,
    matchingPatients: WithRef<IPatient>[]
  ): WithRef<IPatient>[] {
    return matchingPatients.filter(
      (patient) =>
        [sourcePatient.firstname, sourcePatient.surname].every((name) =>
          patient.name.toLowerCase().includes(name.toLowerCase())
        ) && patient.status === PatientStatus.Active
    );
  }

  buildConflictSummary(
    sourcePatient: ID4WPatient
  ): Omit<IExistingPatientMergeConflictSummary, 'status' | 'patientRefs'> {
    const sourcePatientId = this.patientSourceEntity
      .getSourceRecordId(sourcePatient)
      .toString();

    return {
      sourcePatientId,
      patientName: getName(sourcePatient),
      dateOfBirth: sourcePatient.dob ?? '',
      gender: getGender(sourcePatient),
      phone: getPhone(sourcePatient),
      email: sourcePatient.email ?? '',
    };
  }
}
