import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { InteractionDialogsService } from '@principle-theorem/ng-interactions';
import { GeocodeService } from '@principle-theorem/ng-principle-shared';
import {
  type IGeoCoordinates,
  type ILab,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import { toMention } from '@principle-theorem/principle-core';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable, of, ReplaySubject, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-lab-card',
  templateUrl: './lab-card.component.html',
  styleUrls: ['./lab-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabCardComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  lab$ = new ReplaySubject<WithRef<ILab>>(1);
  coordinates$: Observable<IGeoCoordinates | undefined>;

  constructor(
    private _geocodeService: GeocodeService,
    public dialogService: InteractionDialogsService
  ) {
    this.coordinates$ = this.lab$.pipe(
      switchMap((lab) =>
        lab.address
          ? this._geocodeService.geocodeAddress$(lab.address)
          : of(undefined)
      )
    );

    this.lab$.pipe(take(1), takeUntil(this._onDestroy$)).subscribe((lab) => {
      this.dialogService.dialogData = {
        contact: toMention(lab, MentionResourceType.Lab),
      };
    });
  }

  @Input()
  set lab(lab: WithRef<ILab>) {
    if (lab) {
      this.lab$.next(lab);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
