import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { IBridgeDevice } from '@principle-theorem/principle-core/interfaces';
import { DocumentReference } from '@principle-theorem/shared';
import {
  BridgeDeviceSelectorDialogComponent,
  IBridgeDeviceSelectorDialogData,
} from './bridge-device-selector-dialog.component';
import { IBridgeIntegration } from '@principle-theorem/principle-bridge-core';

@Injectable()
export class BridgeDeviceSelectorService {
  constructor(private _dialog: MatDialog) {}

  async selectDevice(
    integration: IBridgeIntegration
  ): Promise<DocumentReference<IBridgeDevice> | undefined> {
    const config = DialogPresets.small<IBridgeDeviceSelectorDialogData>({
      data: { integration },
    });

    return this._dialog
      .open<
        BridgeDeviceSelectorDialogComponent,
        IBridgeDeviceSelectorDialogData,
        DocumentReference<IBridgeDevice>
      >(BridgeDeviceSelectorDialogComponent, config)
      .afterClosed()
      .toPromise();
  }
}
