@if (medicareForm$ | async; as form) {
  <div class="flex flex-wrap gap-4" [formGroup]="form">
    <mat-form-field class="flex-auto">
      <mat-label>Medicare Card Number</mat-label>
      <input matInput formControlName="number" />
      <mat-error *ngIf="form.controls.number.hasError('pattern')">
        Must contain only numbers
      </mat-error>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Medicare Member Number</mat-label>
      <input matInput formControlName="subNumerate" />
      <mat-error *ngIf="form.controls.subNumerate.hasError('pattern')">
        Must contain only numbers
      </mat-error>
      <mat-error *ngIf="form.controls.subNumerate.hasError('maxlength')">
        You can enter a maximum of 2 numbers
      </mat-error>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Expiry Date</mat-label>
      <input
        matInput
        [formControl]="expiryDateCtrl"
        [matDatepicker]="expiryDatepicker"
        [min]="startOfMonth"
        (click)="expiryDatepicker.open()"
        (change)="dateInputChangeHandler($event.target)"
      />
      <mat-datepicker-toggle matIconSuffix [for]="expiryDatepicker" />
      <mat-datepicker
        #expiryDatepicker
        startView="multi-year"
        panelClass="month-picker"
        (monthSelected)="chosenDateHandler($event, expiryDatepicker)"
      />
      <mat-hint>Month and Year</mat-hint>
    </mat-form-field>
  </div>
}
