import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  type IBreadcrumb,
  StorageResponseAPI,
} from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IPractice,
  TransactionStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  errorNil,
  filterUndefined,
  getTimePeriodStartEnd,
  type ITimePeriod,
  toMoment,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { combineLatest, type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { DateRangeDataBuilder } from '../../../models/report/data-builders/date-range-data-builder';
import { PendingPaymentsStore } from './pending-payments.store';

@Component({
    selector: 'pr-pending-payments',
    templateUrl: './pending-payments.component.html',
    styleUrls: ['./pending-payments.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PendingPaymentsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  from = moment().startOf('day').subtract({ months: 1 });
  to = moment().endOf('day');
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);
  breadcrumbs: IBreadcrumb[] = [{ label: 'Reporting' }];
  dataBuilder: DateRangeDataBuilder;
  brands$: Observable<WithRef<IBrand>[]>;

  constructor(
    private _organisation: OrganisationService,
    scope: CurrentScopeFacade,
    public store: PendingPaymentsStore,
    api: StorageResponseAPI
  ) {
    this.dataBuilder = new DateRangeDataBuilder(
      api,
      this.from,
      this.to,
      this._organisation.brand$.pipe(errorNil()),
      this.practice$.asObservable().pipe(map((practice) => [practice]))
    );

    this.brands$ = this._organisation.userBrands$;

    scope.currentPractice$
      .pipe(filterUndefined(), take(1), takeUntil(this._onDestroy$))
      .subscribe((practice) => this.practice$.next(practice));

    const query$ = combineLatest([
      this.practice$,
      this.dataBuilder.dateChange,
    ]).pipe(
      map(([practice, range]) => {
        const timePeriod = getTimePeriodStartEnd(
          range,
          practice.settings.timezone
        );

        return {
          startDate: toTimestamp(timePeriod.from),
          endDate: toTimestamp(timePeriod.to),
          practiceRef: practice.ref,
          status: TransactionStatus.Pending,
        };
      })
    );

    store.loadTransactions(query$);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  updateDateRange(dateRange: ITimePeriod): void {
    this.dataBuilder.updateRange(
      toMoment(dateRange.from),
      toMoment(dateRange.to)
    );
  }
}
