import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountingFunctionsService } from '@principle-theorem/ng-principle-accounting';
import {
  Invoice,
  RefundHelpers,
  Transaction,
  getBaseTransaction,
} from '@principle-theorem/principle-core';
import {
  TransactionAction,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
  type IInvoice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  Region,
  toTimestamp,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { of, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RefundDepositPromptService } from '../transaction-components/refund-deposit-prompt/refund-deposit-prompt.service';
import { TransactionAmountDialog } from '../transaction-components/transaction-amount-dialog/transaction-amount-dialog.service';
import { refundAvailableInfo } from '../transaction-helpers';
import {
  IRefundTransactionProvider,
  TransactionProviderType,
  type ITransactionProvider,
} from '../transaction-provider';

@Injectable()
export class CashTransactionProvider
  implements ITransactionProvider, IRefundTransactionProvider
{
  providerId = TransactionProvider.Cash;
  providerType = TransactionProviderType.Payment;
  providerRegions = [Region.Australia, Region.NewZealand];
  isEnabled$ = of(true);

  constructor(
    private _snackbar: MatSnackBar,
    private _amountDialog: TransactionAmountDialog,
    private _refundDepositPrompt: RefundDepositPromptService,
    private _accountFunctions: AccountingFunctionsService
  ) {}

  canCapture$(invoice: WithRef<IInvoice>): Observable<boolean> {
    const canCapture =
      !Invoice.isPaid(invoice) && Invoice.canAddTransactions(invoice);
    return of(canCapture);
  }

  async capture(
    invoice: WithRef<IInvoice>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const result = await this._amountDialog.openForInvoice(
      'Cash Transaction',
      invoice,
      { enableCurrentPractice: true, enableDateReceived: true }
    );
    if (!result || result.amount <= 0) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }
    const type = TransactionType.Incoming;
    const transaction: ITransaction = Transaction.init({
      ...(await getBaseTransaction(invoice, type)),
      ...Transaction.internalReference(TransactionProvider.Cash),
      status: TransactionStatus.Complete,
      amount: result.amount,
      practiceRef: result.practiceRef,
      createdAt: toTimestamp(result.dateReceived),
    });
    return this._accountFunctions.addTransactionToInvoice(
      invoice,
      transaction,
      TransactionAction.Add
    );
  }

  async refundTransaction(
    invoice: WithRef<IInvoice>,
    transaction: WithRef<ITransaction>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const acceptedRefundAlert =
      await this._refundDepositPrompt.showRefundDepositAlert(invoice);
    if (!acceptedRefundAlert) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }

    const result = await this._amountDialog.openForRefund('Cash Refund', {
      invoice,
      transaction,
      latestTransaction: transaction,
    });
    if (!result || result.amount <= 0) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }
    const refundTransaction = Transaction.init({
      ...(await getBaseTransaction(invoice, TransactionType.Outgoing)),
      ...Transaction.internalReference(TransactionProvider.Cash),
      reference: transaction.reference,
      status: TransactionStatus.Complete,
      amount: result.amount,
      practiceRef: transaction.practiceRef,
    });
    return this._accountFunctions.addTransactionToInvoice(
      invoice,
      refundTransaction,
      TransactionAction.Refund
    );
  }

  canRefund$(invoice: WithRef<IInvoice>): Observable<boolean> {
    return RefundHelpers.isRefundable$(invoice);
  }

  refundInfo$(invoice: WithRef<IInvoice>): Observable<string[]> {
    return Invoice.transactions$(invoice).pipe(
      map((transactions) =>
        RefundHelpers.getTotalRefundRemaining(transactions)
      ),
      map((refundRemaining) => [refundAvailableInfo(refundRemaining)])
    );
  }

  async refund(
    invoice: WithRef<IInvoice>,
    transaction?: WithRef<ITransaction>,
    disableAmount: boolean = false
  ): Promise<DocumentReference<ITransaction> | undefined> {
    if (transaction) {
      return this.refundTransaction(invoice, transaction);
    }
    const acceptedRefundAlert =
      await this._refundDepositPrompt.showRefundDepositAlert(invoice);
    if (!acceptedRefundAlert) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }
    const result = await this._amountDialog.openRefund(
      'Cash Refund',
      invoice,
      undefined,
      { enableCurrentPractice: true, enableDateReceived: true, disableAmount }
    );
    if (!result || result.amount <= 0) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }
    const type = TransactionType.Outgoing;
    const refundTransaction = Transaction.init({
      ...(await getBaseTransaction(invoice, type)),
      ...Transaction.internalReference(TransactionProvider.Cash),
      status: TransactionStatus.Complete,
      amount: result.amount,
      practiceRef: result.practiceRef,
      createdAt: toTimestamp(result.dateReceived),
    });

    return this._accountFunctions.addTransactionToInvoice(
      invoice,
      refundTransaction,
      TransactionAction.Add
    );
  }
}
