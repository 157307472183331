import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  type OnDestroy,
  Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  OrganisationService,
  StafferSettingsStoreService,
} from '@principle-theorem/ng-principle-shared';
import {
  BasicDialogService,
  DialogPresets,
  InputSearchFilter,
  SelectionListStore,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
  toSearchStream,
} from '@principle-theorem/ng-shared';
import {
  IChat,
  type IStafferSettings,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  getEnumValues,
  isChanged$,
  isSameRef,
  snapshotDefined,
} from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { BehaviorSubject, Subject, type Observable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import {
  ChatFilter,
  ChatsDashboardStore,
  IChatSummary,
} from '../../pages/chats-dashboard/chats-dashboard.store';
import {
  CreateChatDialogComponent,
  ICreateChatFormData,
} from '../create-chat-dialog/create-chat-dialog.component';
import { getSchemaText } from '@principle-theorem/editor';
import { NOTIFICATION_SOUNDS } from '@principle-theorem/ng-notifications';
import { DEFAULT_CHAT_SOUND } from '../../chat-notifications.service';

@Component({
    selector: 'pr-chat-list',
    templateUrl: './chat-list.component.html',
    styleUrl: './chat-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SelectionListStore],
    standalone: false
})
export class ChatListComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByFilterOptions = TrackByFunctions.variable<ChatFilter>();
  trackByChat = TrackByFunctions.ref<IChatSummary>('chat.ref');
  chats$: Observable<IChatSummary[]>;
  loading$: Observable<boolean>;
  searchCtrl = new TypedFormControl<string>('');
  searchFilter: InputSearchFilter<IChatSummary>;
  showCreate$ = new BehaviorSubject<boolean>(false);
  filterOptions = getEnumValues(ChatFilter);
  hasListFilter$: Observable<boolean>;
  settingsForm = new TypedFormGroup<IStafferSettings['chats']>({
    showAllPractices: new TypedFormControl<boolean>(),
    notificationSoundOverride: new TypedFormControl<string>(),
  });
  multiSelectEnabled$ = new BehaviorSubject<boolean>(false);
  @Input() preventRouteChange = false;
  @Output() chatSelected = new EventEmitter<DocumentReference<IChat>>();
  sounds = NOTIFICATION_SOUNDS;

  constructor(
    private _chatFacade: ChatsDashboardStore,
    private _router: Router,
    private _route: ActivatedRoute,
    private _settings: StafferSettingsStoreService,
    private _organisation: OrganisationService,
    public selectionList: SelectionListStore<IChatSummary>,
    private _dialog: BasicDialogService
  ) {
    this.chats$ = this._chatFacade.chats$;

    this.selectionList.setCompareFn((currentItem, newItem) =>
      isSameRef(currentItem.chat.ref, newItem.chat.ref)
    );
    this.selectionList.loadOptions(this.chats$);

    this.loading$ = this._chatFacade.loading$;
    this.hasListFilter$ = this._chatFacade.filterBy$.pipe(
      map((filterBy) => filterBy !== ChatFilter.Open)
    );

    this.searchFilter = new InputSearchFilter<IChatSummary>(
      this.chats$,
      toSearchStream(this.searchCtrl),
      (summary) => {
        const label = summary.chat.label ?? '';
        const lastMessage = summary.lastMessage
          ? getSchemaText(summary.lastMessage.content)
          : '';
        const members = summary.members.map((member) => member.name);
        return [label, lastMessage, ...members].join(' ');
      }
    );

    this._organisation.stafferSettings$
      .pipe(
        map((settings) => settings?.chats),
        take(1),
        takeUntil(this._onDestroy$)
      )
      .subscribe((chats) => {
        if (!chats) {
          return;
        }
        this.settingsForm.patchValue(chats, {
          emitEvent: false,
        });
      });

    this.settingsForm.valueChanges
      .pipe(isChanged$(), takeUntil(this._onDestroy$))
      .subscribe((settings) =>
        this._settings.updateStafferSettings({
          chats: settings,
        })
      );

    this.settingsForm.controls.notificationSoundOverride.valueChanges
      .pipe(isChanged$(), takeUntil(this._onDestroy$))
      .subscribe((notificationSoundOverride) => {
        const selectedSound = NOTIFICATION_SOUNDS.find(
          (notificationSound) =>
            notificationSound.value ===
            (notificationSoundOverride ?? DEFAULT_CHAT_SOUND)
        );

        const audio = new Audio(`/assets/sounds/${selectedSound?.value}`);
        audio.volume = 0.75 * (selectedSound?.volume ?? 1);
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        audio.addEventListener('canplaythrough', async () => {
          try {
            await audio.play();
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(`Error playing sound ${selectedSound?.value}`, error);
          }
        });
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  setFilter(filterBy: ChatFilter): void {
    this._chatFacade.setFilter(filterBy);
    this.selectionList.resetSelected();
  }

  async setSelectedChat(chat?: IChatSummary): Promise<void> {
    if (!chat || this.preventRouteChange) {
      return;
    }

    await this._router.navigate(['./', chat.chat.ref.id], {
      relativeTo: this._route,
    });
  }

  compareWithRef(
    summary: IChatSummary,
    selectedSummary: IChatSummary
  ): boolean {
    try {
      return isSameRef(summary.chat, selectedSummary.chat);
    } catch (error) {
      return isEqual(summary, selectedSummary);
    }
  }

  toggleMultiSelection(): void {
    this.multiSelectEnabled$.next(!this.multiSelectEnabled$.value);
    this.selectionList.resetSelected();
  }

  async addChat(): Promise<void> {
    const owner = await snapshotDefined(this._organisation.staffer$);

    const response = await this._dialog
      .open<CreateChatDialogComponent, void, ICreateChatFormData>(
        CreateChatDialogComponent,
        DialogPresets.large()
      )
      .afterClosed()
      .toPromise();

    if (!response) {
      return;
    }

    const chatRef = await this._chatFacade.addChat(
      owner.ref,
      response.participants,
      response.chatType
    );

    if (!chatRef) {
      return;
    }

    this.chatSelected.emit(chatRef);
  }
}
