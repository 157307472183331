import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'pr-recurring-task-confirm-delete',
    templateUrl: './recurring-task-confirm-delete.component.html',
    styleUrls: ['./recurring-task-confirm-delete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RecurringTaskConfirmDeleteComponent {
  constructor(
    private _dialogRef: MatDialogRef<RecurringTaskConfirmDeleteComponent>
  ) {}

  deleteConfiguration(): void {
    this._dialogRef.close(true);
  }

  keepConfiguration(): void {
    this._dialogRef.close(false);
  }
}
