<div class="m-6 flex flex-wrap gap-4 md:gap-6">
  <div class="flex flex-col gap-1">
    <p class="!m-0 text-xs uppercase text-slate-400">Practice</p>
    <p>{{ practice.name }}</p>
  </div>

  <div class="flex flex-col gap-1">
    <p class="!m-0 text-xs uppercase text-slate-400">Telephone</p>
    <p>{{ practice.phone }}</p>
  </div>

  <div class="flex flex-col gap-1">
    <p class="!m-0 text-xs uppercase text-slate-400">Address</p>
    <p>{{ practice.address }}</p>
  </div>
</div>
