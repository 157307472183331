import {
  IScheduleRange,
  ScheduleTime,
} from '@principle-theorem/principle-core/interfaces';
import {
  isObject,
  ITimePeriod,
  mergeDayAndTime,
  TIME_FORMAT_24HR,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import * as moment from 'moment-timezone';

export function isScheduledByStartEnd(
  data: ScheduleTime
): data is IScheduleRange {
  return (
    isObject(data) &&
    'from' in data &&
    isString(data.from) &&
    'to' in data &&
    isString(data.to)
  );
}

export function scheduleTimeToTimePeriod(
  scheduleTime: ScheduleTime,
  start: moment.Moment = moment()
): ITimePeriod {
  if (isScheduledByStartEnd(scheduleTime)) {
    return {
      from: mergeDayAndTime(start, scheduleTime.from),
      to: mergeDayAndTime(start, scheduleTime.to),
    };
  }

  const startTime = moment(scheduleTime.time, TIME_FORMAT_24HR);
  const from = mergeDayAndTime(start, startTime);
  const to = from.clone().add(scheduleTime.duration, scheduleTime.durationUnit);
  return {
    from,
    to,
  };
}
