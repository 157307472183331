import {
  MixedSchema,
  isMixedSchema,
  isRawInlineNodes,
  type RawInlineNodes,
} from '@principle-theorem/editor';
import {
  TypeGuard,
  isEnumValue,
  isINamedDocument,
  isObject,
  type ArchivedDocument,
  type DocumentReference,
  type INamedDocument,
  type ISoftDelete,
  type Timestamp,
} from '@principle-theorem/shared';
import { isArray, isBoolean, isString } from 'lodash';
import { type Observable } from 'rxjs';
import { type IPrincipleMention } from '../mention/mention';
import { type IStaffer } from '../staffer/staffer';
import { type ITag } from '../tag/tag';
import { ISchedulingEvent } from '../appointment/cancellation';

export enum InteractionCollection {
  History = 'interactionHistory',
}

export enum InteractionType {
  Patient = 'patient',
  Appointment = 'appointment',
  Invoice = 'invoice',
  Gap = 'gap',
  Payment = 'payment',
  Email = 'email',
  Sms = 'sms',
  Call = 'call',
  Task = 'task',
  Note = 'note',
  LabJob = 'lab-job',
  SmsReceived = 'sms.recieved',
  CallReceived = 'call.recieved',
  TaskCreate = 'task.create',
  TaskAssign = 'task.assign',
  TaskComplete = 'task.complete',
  TaskReopen = 'task.reopen',
  TaskDelete = 'task.delete',
  TaskUndelete = 'task.undelete',
  TaskEdit = 'task.edit',
  TaskEditDue = 'task.edit.due',
  AppointmentBook = 'appointment.book',
  AppointmentOffer = 'appointment.offer',
  AppointmentStart = 'appointment.start',
  AppointmentCancel = 'appointment.cancel',
  AppointmentConfirm = 'appointment.confirm',
  AppointmentReminder = 'appointment.reminder',
  AppointmentComplete = 'appointment.complete',
  AppointmentArrived = 'appointment.arrived',
  AppointmentCheckIn = 'appointment.checked-in',
  AppointmentCheckOut = 'appointment.checked-out',
  AppointmentReschedule = 'appointment.reschedule',
  AppointmentFollowUp = 'appointment.follow-up',
  ClinicalNote = 'clinical-note',
  Form = 'form',
  FormSubmit = 'form.submit',
  FormConfirm = 'form.confirm',
  FormIssue = 'form.issue',
  FormReissue = 'form.reissue',
}

export const INTERACTION_TYPE_LABEL_MAP: { [key in InteractionType]: string } =
  {
    [InteractionType.Patient]: 'Patient',
    [InteractionType.Appointment]: 'Appointment',
    [InteractionType.Invoice]: 'Invoice',
    [InteractionType.Gap]: 'Gap',
    [InteractionType.Payment]: 'Payment',
    [InteractionType.Email]: 'Email',
    [InteractionType.Sms]: 'SMS',
    [InteractionType.Call]: 'Call',
    [InteractionType.Task]: 'Task',
    [InteractionType.Note]: 'Note',
    [InteractionType.LabJob]: 'Lab Job',
    [InteractionType.SmsReceived]: 'SMS Recieved',
    [InteractionType.CallReceived]: 'Call Recieved',
    [InteractionType.TaskCreate]: 'Task Create',
    [InteractionType.TaskAssign]: 'Task Assign',
    [InteractionType.TaskComplete]: 'Task Complete',
    [InteractionType.TaskReopen]: 'Task Reopen',
    [InteractionType.TaskDelete]: 'Task Delete',
    [InteractionType.TaskUndelete]: 'Task Undelete',
    [InteractionType.TaskEdit]: 'Task Edit',
    [InteractionType.TaskEditDue]: 'Task Edit Due',
    [InteractionType.AppointmentBook]: 'Appointment Book',
    [InteractionType.AppointmentOffer]: 'Appointment Offer',
    [InteractionType.AppointmentStart]: 'Appointment Start',
    [InteractionType.AppointmentCancel]: 'Appointment Cancel',
    [InteractionType.AppointmentConfirm]: 'Appointment Confirm',
    [InteractionType.AppointmentReminder]: 'Appointment Reminder',
    [InteractionType.AppointmentComplete]: 'Appointment Complete',
    [InteractionType.AppointmentArrived]: 'Appointment Arrived',
    [InteractionType.AppointmentCheckIn]: 'Appointment Checked In',
    [InteractionType.AppointmentCheckOut]: 'Appointment Checked Out',
    [InteractionType.AppointmentReschedule]: 'Appointment Reschedule',
    [InteractionType.AppointmentFollowUp]: 'Appointment Follow Up',
    [InteractionType.ClinicalNote]: 'Clinical Note',
    [InteractionType.Form]: 'Form',
    [InteractionType.FormSubmit]: 'Form Submitted',
    [InteractionType.FormConfirm]: 'Form Confirmed',
    [InteractionType.FormIssue]: 'Form Issued',
    [InteractionType.FormReissue]: 'Form Reissued',
  };

export type WithContext<T> = T & {
  suffix?: RawInlineNodes;
  originalType?: InteractionType;
};

export interface IInteraction {
  uid: string;
  type: InteractionType;
  owner?: INamedDocument<IStaffer>;
  title: RawInlineNodes;
  action?: string;
  createdAt: Timestamp;
  content: MixedSchema;
}

export interface IInteractionV2 extends IInteraction, ISoftDelete {
  pinned: boolean;
  amendmentOf?: DocumentReference<ArchivedDocument<IInteractionV2>>;
  tags: INamedDocument<ITag>[];
  schedulingEvent?: DocumentReference<ISchedulingEvent>;
}

export interface IPinnedInteractionSummary {
  uid: string;
  createdAt: Timestamp;
  content: MixedSchema;
  createdBy?: DocumentReference<IStaffer>;
}

export interface IInteractable {
  interactions: IInteraction[];
}

export function isInteractable(data: unknown): data is IInteractable {
  return isObject(data) && 'interactions' in data && isArray(data.interactions);
}

export function isInteraction(data: unknown): data is IInteraction {
  return (
    isObject(data) &&
    isString(data.uid) &&
    isEnumValue(InteractionType, data.type) &&
    (isObject(data.title) || isArray(data.title)) &&
    isRawInlineNodes(data.title) &&
    isMixedSchema(data.content)
  );
}

export function isInteractionV2(data: unknown): data is IInteractionV2 {
  return (
    isObject(data) &&
    isInteraction(data) &&
    isBoolean(data.pinned) &&
    isBoolean(data.deleted) &&
    TypeGuard.arrayOf(isINamedDocument)(data.tags)
  );
}

export interface IInteractiveResource {
  interactions$: Observable<IInteraction[]>;
  add(interaction: IInteractionV2): Promise<void>;
}

export interface IInteractionData {
  contact: IPrincipleMention;
  resource?: IInteractiveResource;
}

export const INTERACTION_TYPES: InteractionType[] = [
  InteractionType.Patient,
  InteractionType.Appointment,
  InteractionType.Gap,
  InteractionType.Invoice,
  InteractionType.Payment,
  InteractionType.Email,
  InteractionType.Sms,
  InteractionType.Call,
  InteractionType.Task,
  InteractionType.LabJob,
  InteractionType.Note,
  InteractionType.ClinicalNote,
  InteractionType.Form,
];

export interface IInteractionTypeMap {
  [key: string]: string;
}

export const interactionTypeDisplayMap: IInteractionTypeMap = {
  [InteractionType.Patient]: 'person',
  [InteractionType.Appointment]: 'airline_seat_flat_angled',
  [InteractionType.Invoice]: 'receipt',
  [InteractionType.Gap]: 'crop_free',
  [InteractionType.Payment]: 'payment',
  [InteractionType.Email]: 'email',
  [InteractionType.Sms]: 'sms',
  [InteractionType.SmsReceived]: 'question_answer',
  [InteractionType.Call]: 'phone',
  [InteractionType.Task]: 'check_box',
  [InteractionType.Note]: 'text_snippet',
  [InteractionType.LabJob]: 'local_pharmacy',
  [InteractionType.TaskCreate]: 'add_box',
  [InteractionType.TaskAssign]: 'person_add',
  [InteractionType.TaskComplete]: 'done',
  [InteractionType.TaskReopen]: 'undo',
  [InteractionType.CallReceived]: 'call_received',
  [InteractionType.AppointmentBook]: 'schedule',
  [InteractionType.AppointmentOffer]: 'send',
  [InteractionType.AppointmentStart]: 'airline_seat_flat_angled',
  [InteractionType.AppointmentReminder]: 'send',
  [InteractionType.AppointmentConfirm]: 'done',
  [InteractionType.AppointmentComplete]: 'done',
  [InteractionType.AppointmentArrived]: 'flight_land',
  [InteractionType.AppointmentCheckIn]: 'airline_seat_recline_normal',
  [InteractionType.AppointmentCheckOut]: 'shopping_cart',
  [InteractionType.AppointmentReschedule]: 'schedule',
  [InteractionType.TaskEdit]: 'edit',
  [InteractionType.TaskEditDue]: 'timer',
  [InteractionType.ClinicalNote]: 'healing',
  [InteractionType.Form]: 'list_alt',
  [InteractionType.FormSubmit]: 'list_alt',
  [InteractionType.FormConfirm]: 'list_alt',
  [InteractionType.FormIssue]: 'list_alt',
  [InteractionType.FormReissue]: 'list_alt',
};
