@if (display$ | async; as display) {
  @if (schedulingEvent$ | async; as schedulingEvent) {
    <div class="flex flex-auto flex-row gap-2">
      <div class="py-1">
        <mat-icon [ngClass]="display.textColour">
          {{ display.iconName }}
        </mat-icon>
      </div>

      <div class="flex flex-auto flex-col">
        <div class="flex flex-row flex-wrap gap-1 text-xs">
          @if (ownerMention$ | async; as ownerMention) {
            <pr-content-view [content]="ownerMention" />
          } @else {
            {{ unknown | titlecase }}
          }

          <div [ngClass]="display.textColour">
            {{ schedulingEvent | map: getActionLabel : this }}
          </div>
          <div>appointment.</div>
        </div>
        <div class="text-xs opacity-50">
          {{ schedulingEvent.scheduledAt | moment | amDateFormat: dateFormat }}
        </div>
      </div>
    </div>
  }
}
