import {
  IScopeRef,
  IChartedSurface,
  ServiceCodeScope,
  IADACodeRules,
} from '@principle-theorem/principle-core/interfaces';
import { IMatchesADAScope } from './base-rule-handler';

export class PerMultipleSurface implements IMatchesADAScope {
  scope: ServiceCodeScope = ServiceCodeScope.PerMultipleSurface;

  matches(
    rule: IADACodeRules,
    _treatmentScope: IScopeRef,
    surfaces: IChartedSurface[]
  ): boolean {
    const matchingSurfaces: IChartedSurface[] = surfaces.filter(
      (surface: IChartedSurface) => {
        if (!surface.chartedRef.tooth || !surface.chartedRef.tooth.surface) {
          return false;
        }
        return true;
      }
    );

    const scopes: ServiceCodeScope[] = [
      ServiceCodeScope.PerMultipleSurface,
      ServiceCodeScope.PerAnteriorMultipleSurface,
      ServiceCodeScope.PerPosteriorMultipleSurface,
    ];

    if (!rule.scope) {
      return false;
    }
    return (
      scopes.includes(rule.scope) && matchingSurfaces.length === rule.scopeValue
    );
  }
}
