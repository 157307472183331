<mat-list-item *ngIf="lastInteraction$ | ngrxPush as lastInteraction">
  <mat-icon matListItemIcon>description</mat-icon>
  <span matListItemTitle>Scheduling Notes</span>
  <span matListItemLine class="note-line">
    <pr-content-view [content]="lastInteraction.content" />
  </span>
  <span matListItemLine>
    {{ lastInteraction.owner?.name }}
    {{
      lastInteraction.createdAt
        | moment
        | amCalendar : dateService.format.dateWithTimes
    }}
  </span>
  <a matListItemLine [routerLink]="[]" (click)="showNotes()">View More</a>
</mat-list-item>
