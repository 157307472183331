import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Invoice } from '@principle-theorem/principle-core';
import { InvoiceStatus } from '@principle-theorem/principle-core/interfaces';
import {
  type IInvoiceReportRecord,
  type IInvoiceReportRequest,
  practionerSummary,
  toPractitionerIncomeRecords,
} from '@principle-theorem/reporting';
import { multiFilter, serialise$ } from '@principle-theorem/shared';
import { from, type Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ReportingFunctions } from '../../../core/reporting-functions';
import {
  loadReport,
  loadReportFailure,
  loadReportSuccess,
  PractitionerIncomeReportActions,
} from './practitioner-income.actions';
import { PractitionerIncomeReportFacade } from './practitioner-income.facade';
import { PractitionerIncomeReportType } from './practitioner-income.reducers';

@Injectable()
export class PractitionerIncomeReportEffects {
  private _actions$ = inject(Actions);
  private _reportFacade = inject(PractitionerIncomeReportFacade);
  loadReport$: Observable<Action> = createEffect(() => this._loadReport$());
  setResults$: Observable<Action> = createEffect(() => this._setResults$());

  private _loadReport$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(
        PractitionerIncomeReportActions.SetQuery,
        PractitionerIncomeReportActions.SetReportType
      ),
      map(() => loadReport())
    );
  }

  private _setResults$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(PractitionerIncomeReportActions.LoadReport),
      withLatestFrom(this._reportFacade.query$, this._reportFacade.reportType$),
      switchMap(([_, query, reportType]) =>
        !query
          ? of(loadReportSuccess({ results: [] }))
          : this._getReportInvoices$(query, reportType).pipe(
              map((report) =>
                practionerSummary(toPractitionerIncomeRecords(report ?? []))
              ),
              serialise$(),
              map((results) => loadReportSuccess({ results })),
              catchError((err) => {
                // eslint-disable-next-line no-console
                console.error(err);
                const action = loadReportFailure({
                  errorMessage: 'Oops! There was a problem loading this report',
                });
                return of(action);
              })
            )
      )
    );
  }

  private _getReportInvoices$(
    query: IInvoiceReportRequest,
    reportType: PractitionerIncomeReportType
  ): Observable<IInvoiceReportRecord[]> {
    return reportType === PractitionerIncomeReportType.ByInvoiceIssuedDate
      ? from(ReportingFunctions.getIssuedInvoices(query)).pipe(
          multiFilter((invoice) => {
            if (
              Invoice.statusIsOneOf(invoice.invoice, [
                InvoiceStatus.Draft,
                InvoiceStatus.Cancelled,
              ])
            ) {
              return false;
            }
            return true;
          })
        )
      : from(
          ReportingFunctions.getPaidInvoices({
            ...query,
            status: InvoiceStatus.Paid,
          })
        );
  }
}
