import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { ReceptionistDashboardComponent } from './components/receptionist-dashboard/receptionist-dashboard.component';
import { NgCalendarModule } from './ng-calendar.module';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { TimelineComponent } from './pages/timeline/timeline.component';
import { ScheduleHistoryComponent } from './pages/schedule-history/schedule-history.component';
import {
  APPOINTMENTS_DASHBOARD_FEATURE,
  APPOINTMENTS_TIMELINE_FEATURE,
  CALENDAR_FEATURE,
  APPOINTMENTS_HISTORY_FEATURE,
} from '@principle-theorem/principle-core/features';
import {
  IsIpRestrictedGuard,
  IsTimeRestrictedGuard,
} from '@principle-theorem/ng-principle-shared';

const sharedCalendarGuards = [IsIpRestrictedGuard, IsTimeRestrictedGuard];

const routes: Routes = [
  {
    path: '',
    component: ScheduleComponent,
    children: [
      {
        path: 'dashboard',
        component: ReceptionistDashboardComponent,
        canActivate: sharedCalendarGuards,
        data: { feature: APPOINTMENTS_DASHBOARD_FEATURE },
      },
      {
        path: 'calendar',
        component: CalendarComponent,
        canActivate: sharedCalendarGuards,
        data: { feature: CALENDAR_FEATURE },
      },
      {
        path: 'timeline',
        component: TimelineComponent,
        canActivate: sharedCalendarGuards,
        data: { feature: APPOINTMENTS_TIMELINE_FEATURE },
      },
      {
        path: 'history',
        component: ScheduleHistoryComponent,
        canActivate: sharedCalendarGuards,
        data: { feature: APPOINTMENTS_HISTORY_FEATURE },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), NgCalendarModule],
  exports: [RouterModule],
})
export class NgCalendarRoutingModule {}
