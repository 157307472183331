export enum ChartActionTypes {
  // Chart persistence
  SetChart = '[Chart] Set Chart',
  ResetChart = '[Chart] Reset Chart',
  LoadChart = '[Chart] Load Chart',
  LoadChartSuccess = '[Chart] Load Chart Success',
  LoadChartFailure = '[Chart] Load Chart Failure',
  LoadChartFromAppointment = '[Chart] Load Chart From Appointment',
  SaveChart = '[Chart] Save Chart',
  RemoveChart = '[Chart] Remove Chart',
  // Charted surfaces
  UpdateChartedSurfaces = '[Chart] Update Charted Surfaces',
  RemoveChartedSurface = '[Chart] Remove Charted Surface',
  RemoveChartedSurfaces = '[Chart] Remove Charted Surfaces',
  // Selected surfaces
  SetSelectedSurfaces = '[Chart] Select Surfaces',
  SelectSurface = '[Chart] Select Surface',
  DeselectSurface = '[Chart] Deselect Surface',
  // Condition management
  AddCondition = '[Chart] Add Condition',
  UpdateCondition = '[Chart] Update Condition',
  RemoveCondition = '[Chart] Remove Condition',
  // Treatment mangement
  AddTreatment = '[Chart] Add Treatment',
  UpdateTreatment = '[Chart] Update Treatment',
  UpdateTreatments = '[Chart] Update Treatments',
  RemoveTreatment = '[Chart] Remove Treatment',
  // Multi treatment management
  AddMultiTreatment = '[Chart] Add Multi Treatment',
  UpdateMultiTreatment = '[Chart] Update Multi Treatment',
  RemoveMultiTreatment = '[Chart] Remove Multi Treatment',
  // Plan Proposal
  UpdatePlanProposal = '[Chart] Update Plan Proposal',
  // Tooth management
  AddTooth = '[Chart] Add Tooth',
  RemoveTooth = '[Chart] Remove Tooth',
  // Tooth root management
  UpdateToothRoots = '[Chart] Update Tooth Roots',
  // Clinical note management
  AddClinicalNote = '[Chart] Add Clinical Note',
  UpdateClinicalNote = '[Chart] Update Clinical Note',
  RemoveClinicalNote = '[Chart] Remove Clinical Note',
  EnsureClinicalNoteForStaffer = '[Chart] Ensure Clinical Note For Staffer',
  // View Management
  SetChartType = '[Chart] Set Chart Type',
  SetChartView = '[Chart] Set Chart View',
  SetChartTeeth = '[Chart] Set Chart Teeth',
  SetChartConfig = '[Chart] Set Chart Config',
  SetIsStacked = '[Chart] Set Is Stacked',
  SetChartLayout = '[Chart] Set Chart Layout',
  SetChartSection = '[Chart] Set Chart Section',
  SetDisabledSurfaces = '[Chart] Set Disabled Surfaces',
  // Charting context management
  SetChartingAs = '[Chart] Set Charting As',
  ChartingAsReset = '[Chart] Charting As Reset',
  SetChartedItemFilters = '[Chart] Set Charted Item Filters',
  SetForceAllowInteractivity = '[Chart] Set Force Allow Interactivity',
  // Condition configurations
  LoadConditionConfigurations = '[Chart] Load Condition Configurations',
  LoadConditionConfigurationsSuccess = '[Chart] Load Condition Configurations Success',
  LoadTreatmentConfigurations = '[Chart] Load Treatment Configurations',
  LoadTreatmentConfigurationsSuccess = '[Chart] Load Treatment Configurations Success',
  LoadMultiTreatmentConfigurations = '[Chart] Load Multi Treatment Configurations',
  LoadMultiTreatmentConfigurationsSuccess = '[Chart] Load Multi Treatment Configurations Success',
  AddConditionConfiguration = '[Chart] Add Condition Configuration',
  AddAndChartConditionConfiguration = '[Chart] Add And Chart Condition Configuration',
  AddTreatmentConfiguration = '[Chart] Add Treatment Configuration',
  AddAndChartTreatmentConfiguration = '[Chart] Add And Chart Treatment Configuration',
  AddMultiTreatmentConfiguration = '[Chart] Add Multi Treatment Configuration',
  // Quick Charting
  AddQuickChartingItem = '[Chart] Add Quick Charting Item',
  RemoveQuickChartingItem = '[Chart] Remove Quick Charting Item',
  // Fee Schedules
  LoadFeeSchedules = '[Chart] Load Fee Schedules',
  LoadFeeSchedulesSuccess = '[Chart] Load Fee Schedules Success',
  LoadFeeSchedulesFailure = '[Chart] Load Fee Schedules Failure',
  SelectFeeSchedule = '[Chart] Select Fee Schedule',
  SelectDefaultFeeSchedule = '[Chart] Select Default Fee Schedule',
  ClearFeeSchedule = '[Chart] Clear Fee Schedule',
  AddFeeSchedule = '[Chart] Add Fee Schedule',
  UpdateFeeSchedule = '[Chart] Update Fee Schedule',
  DeleteFeeSchedule = '[Chart] Delete Fee Schedule',
  // Perio Chart
  UpsertPerioValue = '[Chart] Upsert Perio Value',
  DeletePerioValues = '[Chart] Delete Perio Values',
}
