import { BaseDimensionMeasures } from '../base-measures';
import { CanDoAllProperty } from '../measure-properties';
import { MeasureTransformMap } from '../measure-properties';
import { FactsCommon } from '@principle-theorem/reporting/interfaces';
import { BigQueryTable } from '../../big-query-tables';
import { MeasurePropertyFactory } from '../measure-property-factory';

export class ModelEventDimensionMeasures<
    Action extends string,
    Status extends string
  >
  extends BaseDimensionMeasures
  implements MeasureTransformMap<FactsCommon.IModelEvent<Action, Status>>
{
  constructor(
    table: BigQueryTable,
    dimensionPrefix: string,
    public modelLabel: string
  ) {
    super(table, dimensionPrefix);
  }

  get action(): CanDoAllProperty {
    const propertyName = 'action';
    const label = `${this.modelLabel} Event Action`;
    return this._buildProperty(propertyName, label);
  }

  get statusBefore(): CanDoAllProperty {
    const propertyName = 'statusBefore';
    const label = `${this.modelLabel} Status After Event`;
    return this._buildProperty(propertyName, label);
  }

  get statusAfter(): CanDoAllProperty {
    const propertyName = 'statusAfter';
    const label = `${this.modelLabel} Status After Event`;
    return this._buildProperty(propertyName, label);
  }

  private _buildProperty(
    propertyName: string,
    label: string
  ): CanDoAllProperty {
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label,
        summary: '',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }
}
