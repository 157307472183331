import { Media, Patient } from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  MergeConflictDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IMedia,
  type IPatient,
  type IPracticeMigration,
  ISourceEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Timestamp,
  asyncForEach,
  getError,
  multiFilter,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, upperFirst } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { FirestoreMigrate } from '../../../destination/destination';
import { DestinationEntity } from '../../../destination/destination-entity';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { StorageFile, getBucketStoragePath } from '../../../storage';
import { TranslationMapHandler } from '../../../translation-map';
import {
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
  type IExactPatient,
  type IExactPatientTranslations,
} from '../../source/entities/patient';
import {
  PatientDocumentsSourceEntity,
  type IExactPatientDocument,
  type IExactPatientDocumentFilters,
  type IExactPatientDocumentTranslations,
} from '../../source/entities/patient-documents';
import { exactPatientIdIsWithinRange } from '../../util/helpers';
import { PatientDestinationEntity } from './patient';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';

export const PATIENT_DOCUMENTS_RESOURCE_TYPE = 'patientDocuments';

export const PATIENT_DOCUMENTS_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_DOCUMENTS_RESOURCE_TYPE,
    label: 'Patient Documents',
    description: ``,
  },
});

export interface IPatientFileDestinationRecord {
  fileRefs: DocumentReference<IMedia>[];
}

export interface IPatientDocumentJobData {
  sourcePatient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>;
}

export interface IPatientFileMigrationData {
  sourcePatientId: string;
  patientRef: DocumentReference<IPatient>;
  documents: IGetRecordResponse<
    IExactPatientDocument,
    IExactPatientDocumentTranslations,
    IExactPatientDocumentFilters
  >[];
}

export class PatientDocumentDestinationEntity extends BaseDestinationEntity<
  IPatientFileDestinationRecord,
  IPatientDocumentJobData,
  IPatientFileMigrationData
> {
  destinationEntity = PATIENT_DOCUMENTS_DESTINATION_ENTITY;

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    patientDocuments: new PatientDocumentsSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  override filters = [
    new PatientIdFilter<IPatientDocumentJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  sourceCountDataAccessor(
    data: IPatientDocumentJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getDestinationEntityRecordUid(data: IPatientDocumentJobData): string {
    return data.sourcePatient.record.uid;
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    skipMigrated: boolean,
    _fromDate?: Timestamp,
    _toDate?: Timestamp,
    fromId?: string,
    toId?: string
  ): Observable<IPatientDocumentJobData[]> {
    return this.sourceEntities.patients
      .getRecords$(
        migration,
        500,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        multiFilter((patient) =>
          exactPatientIdIsWithinRange(
            patient.data.data.patient_id,
            fromId,
            toId
          )
        ),
        map((sourcePatients) =>
          sourcePatients.map((sourcePatient) => ({
            sourcePatient,
            destinationEntity,
          }))
        )
      );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientDocumentJobData
  ): Promise<
    | IPatientFileMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();
    const documents = await this.sourceEntities.patientDocuments.filterRecords(
      migration,
      'patientId',
      sourcePatientId
    );
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `Couldn't resolve patient`
      );
    }

    return {
      sourcePatientId,
      patientRef,
      documents,
    };
  }

  async runJob(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    jobData: IPatientDocumentJobData,
    migrationData: IPatientFileMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const [fileRefs, failedFiles] = await this.addMediaToPatient(
        migration,
        translationMapHandler,
        migrationData
      );
      if (failedFiles.length) {
        this._buildErrorResponse(jobData.sourcePatient, failedFiles.join(', '));
      }
      return this._buildSuccessResponse(jobData.sourcePatient, fileRefs);
    } catch (error) {
      return this._buildErrorResponse(jobData.sourcePatient, getError(error));
    }
  }

  hasMergeConflict(
    _translationMap: TranslationMapHandler,
    _data: IPatientFileMigrationData
  ): IPatientFileMigrationData | undefined {
    return;
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientDocumentJobData,
    _migrationData: IPatientFileMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async addMediaToPatient(
    migration: WithRef<IPracticeMigration>,
    translationMap: TranslationMapHandler,
    migrationData: IPatientFileMigrationData
  ): Promise<[DocumentReference<IMedia>[], string[]]> {
    const failedFiles: string[] = [];
    const fileRefs = await asyncForEach(
      migrationData.documents,
      async (document) => {
        const folderParts = document.data.data.source_path
          .split('\\')
          .map((part) => {
            if (part === 'medical history') {
              return 'medical-history';
            }
            return part;
          });
        const [sourceFileName, extension] = folderParts.pop()?.split('.') ?? [];
        const month = upperFirst(folderParts.pop());
        folderParts.push(month);

        const filePath = [
          ...folderParts,
          `${sourceFileName.toUpperCase()}.${extension}`,
        ].join('/');

        const copyStorage = this.getCopyStorage(
          getBucketStoragePath(migration.source),
          migrationData.patientRef,
          migration.configuration.projectId,
          migration.configuration.destinationBucket
        );

        let destinationFilePath = await copyStorage.copy(filePath);
        if (!destinationFilePath) {
          const upperExtension = [
            ...folderParts,
            `${sourceFileName.toUpperCase()}.${extension.toUpperCase()}`,
          ].join('/');
          destinationFilePath = await copyStorage.copy(upperExtension);
        }

        if (!destinationFilePath) {
          failedFiles.push(filePath);
          return;
        }

        const documentDestinationRef = await translationMap.getDestination(
          document.record.uid,
          PATIENT_DOCUMENTS_RESOURCE_TYPE
        );

        const mediaRef = await FirestoreMigrate.upsertDoc(
          Patient.mediaCol({ ref: migrationData.patientRef }),
          {
            ...Media.init({
              name: document.data.data.title,
              path: destinationFilePath,
            }),
            createdAt: document.data.translations.date,
          },
          documentDestinationRef?.id
        );

        if (!documentDestinationRef) {
          await translationMap.upsert({
            sourceIdentifier: document.record.uid,
            destinationIdentifier: mediaRef,
            resourceType: PATIENT_DOCUMENTS_RESOURCE_TYPE,
          });
        }

        return mediaRef;
      }
    );

    return [compact(fileRefs), failedFiles];
  }

  getCopyStorage(
    sourceBucket: string,
    patientRef: DocumentReference<IPatient>,
    projectId: string,
    destinationBucket: string
  ): StorageFile {
    return new StorageFile(
      sourceBucket,
      destinationBucket,
      Patient.storagePath({ ref: patientRef }),
      projectId
    );
  }

  private _buildErrorResponse(
    sourcePatient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>,
    errorMessage: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage,
      failData: {
        patientRef: sourcePatient.record.ref,
      },
    };
  }

  private _buildSuccessResponse(
    sourcePatient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>,
    fileRefs: DocumentReference<IMedia>[]
  ): IDestinationEntityRecord<IPatientFileDestinationRecord> {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      data: {
        fileRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }
}
