import { type Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { isString } from 'lodash';
import { type WithRef } from '@principle-theorem/shared';
import { type ITag } from '@principle-theorem/principle-core/interfaces';

export class TagSearch {
  results$: Observable<WithRef<ITag>[]>;

  constructor(
    tags$: Observable<WithRef<ITag>[]>,
    search$: Observable<string | WithRef<ITag>>
  ) {
    this.results$ = combineLatest([tags$, search$]).pipe(
      map(([tags, search]) => {
        return this._searchFilter(tags, search);
      })
    );
  }

  private _searchFilter(
    tags: WithRef<ITag>[],
    search: string | WithRef<ITag>
  ): WithRef<ITag>[] {
    if (!isString(search)) {
      return tags;
    }
    const filterValue: string = search.toLowerCase();
    return tags.filter((tag: WithRef<ITag>) => {
      return tag.name.toLowerCase().includes(filterValue);
    });
  }
}
