import { ITaskBlockFilters } from '@principle-theorem/ng-principle-shared';
import { type IStaffer } from '@principle-theorem/principle-core/interfaces';
import { slugify, type WithRef } from '@principle-theorem/shared';
import { compact } from 'lodash';

export enum TaskPresetFilter {
  MyTasks = 'myTasks',
  WatchedTasks = 'watchedTasks',
  AllTasks = 'allTasks',
}

export interface ITaskPresetFilter {
  label: string;
  id: string;
  showBadge: boolean;
  filter: (currentStaffer: WithRef<IStaffer>) => Partial<ITaskBlockFilters>;
}

export const MY_TASKS_PRESET: ITaskPresetFilter = {
  label: 'My Tasks',
  id: slugify(TaskPresetFilter.MyTasks),
  showBadge: true,
  filter: (currentStaffer: WithRef<IStaffer>): Partial<ITaskBlockFilters> => ({
    assigneeRefs: compact([
      currentStaffer.ref,
      ...currentStaffer.teams.map((team) => team.ref),
    ]),
  }),
};

export const WATCHED_TASKS_PRESET: ITaskPresetFilter = {
  label: `Watched Tasks`,
  id: slugify(TaskPresetFilter.WatchedTasks),
  showBadge: true,
  filter: (currentStaffer: WithRef<IStaffer>): Partial<ITaskBlockFilters> => ({
    ownerRef: currentStaffer.ref,
  }),
};

export const ALL_TASKS_PRESET: ITaskPresetFilter = {
  label: `All Tasks`,
  id: slugify(TaskPresetFilter.AllTasks),
  showBadge: true,
  filter: (_currentStaffer: WithRef<IStaffer>) => ({}),
};

export const TASK_PRESET_FILTERS: ITaskPresetFilter[] = [
  MY_TASKS_PRESET,
  WATCHED_TASKS_PRESET,
  ALL_TASKS_PRESET,
];

export function taskPresetFilterById(
  presetId: string
): ITaskPresetFilter | undefined {
  return TASK_PRESET_FILTERS.find(
    (presetFilter) => presetFilter.id === presetId
  );
}
