import { Directive, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { type TypedFormGroup } from '@principle-theorem/ng-shared';
import { addDoc } from '@principle-theorem/shared';
import {
  type CollectionReference,
  type DocumentReference,
} from '@principle-theorem/shared';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class CreateConfiguration<
  Component,
  FormData extends object,
  Model extends FormData
> {
  loading = false;
  abstract form: TypedFormGroup<FormData>;

  constructor(
    private _dialogRef: MatDialogRef<Component, DocumentReference<Model>>,
    @Inject(MAT_DIALOG_DATA) private _data: ICreateConfigurationData<Model>
  ) {}

  async submit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    this.loading = true;

    const ref = await addDoc(
      this._data.collection,
      await this._transformData(this.form.value)
    );

    this.loading = false;
    this._dialogRef.close(ref);
  }

  protected abstract _transformData(formData: FormData): Model | Promise<Model>;
}

export interface ICreateConfigurationData<T> {
  collection: CollectionReference<T>;
}
