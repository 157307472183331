import {
  HealthcareClaimStatus,
  IHealthcareClaim,
  IHealthcareClaimProviderGroup,
  IInvoice,
  IProviderData,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, asyncForEach, isSameRef } from '@principle-theorem/shared';
import { compact, first, flatten, groupBy, sum, values } from 'lodash';
import { OrganisationCache } from '../organisation/organisation-cache';
import { Staffer } from '../staffer/staffer';
import {
  HealthcareClaimGenerator,
  IResolvedClaimItem,
  resolveLineItem,
  toClaimableItems,
} from './healthcare-claim-operators';
import { Invoice } from './invoice';

export interface IPractitionerClaimSection {
  practitioner: WithRef<IStaffer>;
  providerData?: IProviderData;
  claims: IHealthcareClaim[];
  unclaimedItems: IResolvedClaimItem[];
  unclaimedCount: number;
  unnclaimedAmount: number;
}

export class InvoiceClaimGenerator {
  static async getPractitionerClaims(
    invoice: WithRef<IInvoice>
  ): Promise<IPractitionerClaimSection[]> {
    const claims = (invoice.claims ?? []).filter((claim) =>
      this.isClaimVisible(claim)
    );
    const defaultPractitioner = await Invoice.findInvoicePractitioner(invoice);
    return this.toPractitionerClaims(invoice, claims, defaultPractitioner);
  }

  static isClaimVisible(claim: IHealthcareClaim): boolean {
    if (claim.status !== HealthcareClaimStatus.Unclaimed) {
      return true;
    }
    return claim.transactions.length > 0;
  }

  static async toPractitionerClaims(
    invoice: WithRef<IInvoice>,
    claims: IHealthcareClaim[],
    practitioner?: WithRef<IStaffer>
  ): Promise<IPractitionerClaimSection[]> {
    const claimable = await this.getClaimableGroups(
      invoice,
      practitioner,
      claims
    );
    const claimed = this.getClaimedGroups(invoice, claims);
    const grouped = groupBy(
      [...claimable, ...claimed],
      (group) => group.practitioner.ref.path
    );
    const sections = await asyncForEach(values(grouped), (groups) =>
      this.toClaimSection(invoice, groups, claims)
    );
    return compact(sections);
  }

  static async getClaimableGroups(
    invoice: WithRef<IInvoice>,
    practitioner?: WithRef<IStaffer>,
    claims: IHealthcareClaim[] = []
  ): Promise<IHealthcareClaimProviderGroup[]> {
    if (!practitioner) {
      // eslint-disable-next-line no-console
      console.warn('No default practitioner found for invoice');
      return [];
    }

    const claimable = HealthcareClaimGenerator.getAllClaimableItems(
      invoice.items,
      practitioner
    );
    const claimed = flatten(
      claims
        .filter((claim) => claim.status === HealthcareClaimStatus.Claimed)
        .map((claim) => claim.items)
    );
    const unclaimed = HealthcareClaimGenerator.calculateUnclaimed(
      claimable,
      claimed
    );
    return HealthcareClaimGenerator.groupByProvider(
      unclaimed,
      invoice.practice.ref
    );
  }

  static getClaimedGroups(
    _invoice: WithRef<IInvoice>,
    claims: IHealthcareClaim[]
  ): IHealthcareClaimProviderGroup[] {
    return claims.map((claim) => {
      return {
        items: [],
        practitioner: claim.practitioner,
        providerData: claim.providerData,
      };
    });
  }

  static async toClaimSection(
    invoice: WithRef<IInvoice>,
    groups: IHealthcareClaimProviderGroup[],
    allClaims: IHealthcareClaim[]
  ): Promise<IPractitionerClaimSection | undefined> {
    const practitionerRef = first(groups)?.practitioner.ref;
    if (!practitionerRef) {
      return;
    }
    const practitioner =
      await OrganisationCache.staff.get.getDoc(practitionerRef);
    const claims = allClaims.filter((claim) =>
      isSameRef(claim.practitioner, practitioner)
    );
    const unclaimedItems = flatten(groups.map((group) => group.items))
      .filter((item) => item.quantity > 0)
      .map((item) => resolveLineItem(invoice, item));
    const unnclaimedAmount = sum(
      toClaimableItems(unclaimedItems).map(
        (item) => item.quantity * item.serviceCode.amount
      )
    );
    const unclaimedCount = sum(
      unclaimedItems.map((item) => item.claimItem.quantity)
    );
    return {
      practitioner,
      providerData: Staffer.getProviderData(practitioner, invoice.practice.ref),
      claims,
      unclaimedItems,
      unclaimedCount,
      unnclaimedAmount,
    };
  }
}
