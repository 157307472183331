import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { type ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type AtLeast } from '@principle-theorem/shared';
import { isArray } from 'lodash';
import { TrackByFunctions } from '../track-by';

export interface IAlertDialogData {
  title: string;
  prompt: string | string[];
  note?: string;
  submitLabel: string;
  submitColor: ThemePalette;
  toolbarColor: ThemePalette;
}

export type IAlertDialogInput = AtLeast<IAlertDialogData, 'title' | 'prompt'>;

export function alertDialogData(data: IAlertDialogInput): IAlertDialogData {
  return {
    submitLabel: 'Confirm',
    submitColor: 'primary',
    toolbarColor: 'accent',
    ...data,
  };
}

@Component({
  selector: 'pt-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertDialogComponent {
  data: IAlertDialogData;
  trackByLine = TrackByFunctions.variable<string>();
  lines: string[];

  constructor(@Inject(MAT_DIALOG_DATA) data: IAlertDialogInput) {
    this.data = alertDialogData(data);
    this.lines = isArray(data.prompt) ? data.prompt : [data.prompt];
  }
}
