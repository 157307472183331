import { ActivatedRoute, ParamMap } from '@angular/router';
import { sanitiseCSRF } from '@principle-theorem/temporary-tokens';
import { compact, first, isError, isNull, isString } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export class InvalidTokenError extends Error {
  constructor() {
    super('Invalid or expired link');
  }
}

export class PatientPortalUI {
  static getRouteParams$(route: ActivatedRoute): Observable<ParamMap[]> {
    return combineLatest([route.queryParamMap, route.paramMap]);
  }

  static getRouteParam(
    params: ParamMap[],
    propertyName: string
  ): string | undefined {
    const tokens = params.map((param) => {
      const paramToken = param.get(propertyName);
      return !isNull(paramToken) ? paramToken : undefined;
    });
    const token = first(compact(tokens));
    if (!token) {
      return;
    }
    return sanitiseCSRF(token);
  }

  static getTokenUid$(route: ActivatedRoute): Observable<string | undefined> {
    return this.getRouteParams$(route).pipe(
      map((params) => this.getRouteParam(params, 'token')),
      distinctUntilChanged()
    );
  }

  static getErrorMessage(e: unknown): string {
    if (isError(e)) {
      return e.message;
    }
    if (isString(e)) {
      return e;
    }
    return 'Oops! Something went wrong. Please try again.';
  }
}
