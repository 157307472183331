<mat-toolbar color="accent">Select Terminal</mat-toolbar>

<mat-dialog-content>
  @if (data.terminals.length) {
    <mat-selection-list
      fxFlex
      hideSingleSelectionIndicator
      [multiple]="false"
      (selectionChange)="selectTerminal($event)"
    >
      @for (terminal of data.terminals; track trackByTerminal) {
        @if (terminal | map: bridgeDevice$ | async; as device) {
          <mat-list-option
            [disabled]="device | map: isDisabled"
            [value]="terminal"
          >
            <div class="flex flex-row items-center justify-between">
              <div class="flex flex-col items-start">
                <div class="text-base">{{ terminal.name }}</div>
                <div class="text-xs">{{ device.name }}</div>
              </div>
              <pr-bridge-device-status [status]="device.status" dense />
            </div>
          </mat-list-option>
        }
      }
    </mat-selection-list>
  } @else {
    <div class="layout-margin">
      <p>No terminals found</p>
    </div>
  }
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
