import { Injectable } from '@angular/core';
import { Storage, getDownloadURL, ref } from '@angular/fire/storage';
import { HttpsCallableOptions } from '@firebase/functions-types';
import {
  SerialisedData,
  httpsCallable,
  serialise,
  type IStorageResponse,
} from '@principle-theorem/shared';
import axios, { type AxiosProgressEvent } from 'axios';
import { isString } from 'lodash';
import { BehaviorSubject, from, type Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { DataStreamEvent, type IDataStreamEvent } from './data-stream';

export interface IStorageResponseLoader {
  load$<T>(response: IStorageResponse): Observable<IDataStreamEvent<T>>;
}

function calculatePercent(event: AxiosProgressEvent): number {
  return Math.floor(event.loaded * 1.0) / (event.total ?? 1);
}

export class StorageResponseLoader implements IStorageResponseLoader {
  constructor(private _storage: Storage) {}

  load$<T = unknown>(
    response: IStorageResponse
  ): Observable<IDataStreamEvent<T>> {
    const url$ = from(
      getDownloadURL(ref(this._storage, response.storageUrl))
    ).pipe(
      filter((url): url is string => isString(url)),
      take(1)
    );

    const progress$ = new BehaviorSubject<number>(0);
    const onDownloadProgress = (progressEvent: AxiosProgressEvent): void =>
      progress$.next(calculatePercent(progressEvent));

    const data$ = url$.pipe(
      switchMap((url) => axios.get(url, { onDownloadProgress })),
      map((data) => data.data as T),
      tap(() => progress$.complete())
    );

    return DataStreamEvent.from<T>(data$, progress$);
  }
}

@Injectable({
  providedIn: 'root',
})
export class StorageResponseAPI {
  private _loader: IStorageResponseLoader;

  constructor(storage: Storage) {
    this._loader = new StorageResponseLoader(storage);
  }

  get$<T, R>(
    functionName: string,
    requestData: T,
    options?: HttpsCallableOptions
  ): Observable<IDataStreamEvent<R>> {
    const request = serialise(requestData);
    const queryFn = httpsCallable<SerialisedData<T>, IStorageResponse>(
      functionName,
      options
    );

    return from(queryFn(request)).pipe(
      switchMap((response) => this._loader.load$<R>(response))
    );
  }
}
