import {
  IScopeRef,
  IChartedSurface,
  ServiceCodeScope,
  IADACodeRules,
} from '@principle-theorem/principle-core/interfaces';
import { IMatchesADAScope } from './base-rule-handler';
import { PerMultipleSurface } from './per-multiple-surface';
import { PerPosterior } from './per-posterior';

export class PerPosteriorMultipleSurface implements IMatchesADAScope {
  scope: ServiceCodeScope = ServiceCodeScope.PerPosteriorMultipleSurface;

  matches(
    rule: IADACodeRules,
    treatmentScope: IScopeRef,
    surfaces: IChartedSurface[]
  ): boolean {
    return (
      new PerPosterior().matches(rule, treatmentScope, surfaces) &&
      new PerMultipleSurface().matches(rule, treatmentScope, surfaces) &&
      rule.scope === this.scope
    );
  }
}
