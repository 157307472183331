import { NgModule } from '@angular/core';
import { CoreModule } from 'ng-flex-layout';
import { NgMedipassModule } from '@principle-theorem/ng-medipass';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NgStripeModule } from '@principle-theorem/ng-stripe';
import { NgTyroModule } from '@principle-theorem/ng-tyro';
import { AccountCreditTransactionProvider } from './account-credit/account-credit-transaction-provider.service';
import { AccountCreditTransactionComponent } from './account-credit/account-credit-transaction.component';
import { CashTransactionProvider } from './cash/cash-transaction-provider.service';
import { SelectClaimProviderComponent } from './claim-estimate/select-claim-provider/select-claim-provider.component';
import { AmendDiscountTransactionDialogComponent } from './discount/amend-discount-transaction-dialog/amend-discount-transaction-dialog.component';
import { DiscountTransactionProvider } from './discount/discount-transaction-provider.service';
import { DiscountTransactionComponent } from './discount/discount-transaction.component';
import { GeneralProviderActionsService } from './general-provider-actions.service';
import { HicapsConnectTransactionModule } from './hicaps-connect/hicaps-connect-transaction.module';
import { AmendManualTransactionDialogComponent } from './manual/amend-manual-transaction-dialog/amend-manual-transaction-dialog.component';
import { ManualExtendedDataComponent } from './manual/manual-extended-data/manual-extended-data.component';
import { ManualTransactionProvider } from './manual/manual-transaction-provider.service';
import { ManualTransactionComponent } from './manual/manual-transaction.component';
import { MedipassTransactionModule } from './medipass/medipass-transaction.module';
import { StripeTransactionProvider } from './stripe/stripe-transaction-provider.service';
import { StripeTransactionComponent } from './stripe/stripe-transaction.component';
import { TransactionActionFactoryService } from './transaction-action-factory.service';
import { TransactionComponentsModule } from './transaction-components/transaction-components.module';
import { TransactionProviders } from './transaction-providers.service';
import { TyroTransactionModule } from './tyro/tyro-transaction.module';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { NgPatientModule } from 'libs/ng-patient/src/lib/ng-patient.module';
import { AccountCreditTransferTransactionProvider } from './account-credit-transfer/account-credit-transfer-transaction-provider.service';
import { SelectSmartpayTerminalComponent } from './smartpay/select-smartpay-terminal/select-smartpay-terminal.component';
import { SelectSmartpayTerminalService } from './smartpay/select-smartpay-terminal/select-smartpay-terminal.service';
import { SmartpayCardPurchaseTransactionProvider } from './smartpay/smartpay-card-purchase-transaction-provider.service';
import { SmartpayExtendedDataComponent } from './smartpay/smartpay-extended-data/smartpay-extended-data.component';
import { SmartpayPurchaseBuilder } from './smartpay/smartpay-purchase-builder.service';
import { SmartpayQRPurchaseTransactionProvider } from './smartpay/smartpay-qr-purchase-transaction-provider.service';
import { SmartpayService } from './smartpay/smartpay.service';

@NgModule({
  imports: [
    NgPrincipleSharedModule,
    CoreModule,
    NgMedipassModule,
    NgStripeModule,
    NgTyroModule,
    NgPatientModule,
    TransactionComponentsModule,
    TyroTransactionModule,
    MedipassTransactionModule,
    NgSharedModule,
    HicapsConnectTransactionModule,
  ],
  providers: [
    ManualTransactionProvider,
    CashTransactionProvider,
    DiscountTransactionProvider,
    AccountCreditTransactionProvider,
    AccountCreditTransferTransactionProvider,
    StripeTransactionProvider,
    TransactionProviders,
    GeneralProviderActionsService,
    TransactionActionFactoryService,
    SmartpayCardPurchaseTransactionProvider,
    SmartpayQRPurchaseTransactionProvider,
    SelectSmartpayTerminalService,
    SmartpayService,
    SmartpayPurchaseBuilder,
  ],
  declarations: [
    ManualTransactionComponent,
    StripeTransactionComponent,
    DiscountTransactionComponent,
    AccountCreditTransactionComponent,
    ManualExtendedDataComponent,
    AmendManualTransactionDialogComponent,
    AmendDiscountTransactionDialogComponent,
    SelectClaimProviderComponent,
    SelectSmartpayTerminalComponent,
    SmartpayExtendedDataComponent,
  ],
  exports: [TransactionComponentsModule],
})
export class TransactionProvidersModule {}
