import { IBrandDimension } from '@principle-theorem/reporting/interfaces';
import { BaseDimensionMeasures } from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import { MeasureTransformMap } from '../measure-properties';
import { CanBeChartedProperty, CanDoAllProperty } from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';

export class BrandDimensionMeasures
  extends BaseDimensionMeasures
  implements MeasureTransformMap<Pick<IBrandDimension, 'name'>>
{
  get ref(): CanBeChartedProperty {
    const propertyName = 'ref';
    const ref = this.measureRef(MeasurePath.docRef(propertyName));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Brand Ref',
      },
      ref,
      this.buildQuery().attributes([ref.attributePath]).get()
    );
  }

  get name(): CanDoAllProperty {
    const propertyName = 'name';
    const name = this.measureRef(propertyName);
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Brand Name',
        summary: `The name of the brand that the practice belongs to. For multi-practice workspaces, this can be used to aggregate a data point across all practices.`,
      },
      docRef,
      this.buildQuery()
        .attributes([docRef.attributePath, name.attributePath])
        .get(),
      name
    );
  }
}
