import {
  isAdultQuadrant,
  isUpperQuadrant,
  isRightQuadrant,
} from '@principle-theorem/principle-core';
import {
  type ITooth,
  ChartView,
  ChartSection,
} from '@principle-theorem/principle-core/interfaces';

export class ChartViewFactory {
  constructor(private _teeth: ITooth[]) {}

  result(): ITooth[] {
    return this._teeth;
  }

  byArch(): IArchDisplay[] {
    return unzipByArch(this.result());
  }

  filterView(view: ChartView): ChartViewFactory {
    switch (view) {
      case ChartView.Adult:
        return this.adult();
      case ChartView.Deciduous:
        return this.deciduous();
      default:
        return this;
    }
  }

  filterSection(section: ChartSection): ChartViewFactory {
    switch (section) {
      case ChartSection.TopArch:
        return this.top();
      case ChartSection.BottomArch:
        return this.bottom();
      case ChartSection.TopRight:
        return this.top().right();
      case ChartSection.TopLeft:
        return this.top().left();
      case ChartSection.BottomLeft:
        return this.bottom().left();
      case ChartSection.BottomRight:
        return this.bottom().right();
      default:
        return this;
    }
  }

  smartView(): ChartViewFactory {
    return new ChartViewFactory(this._teeth);
  }

  adult(): ChartViewFactory {
    return new ChartViewFactory(
      this._teeth.filter((tooth: ITooth) => {
        return isAdultQuadrant(tooth.toothRef.quadrant);
      })
    );
  }

  deciduous(): ChartViewFactory {
    return new ChartViewFactory(
      this._teeth.filter((tooth: ITooth) => {
        return !isAdultQuadrant(tooth.toothRef.quadrant);
      })
    );
  }

  top(): ChartViewFactory {
    return new ChartViewFactory(
      this._teeth.filter((tooth: ITooth) => {
        return isUpperQuadrant(tooth.toothRef.quadrant);
      })
    );
  }

  bottom(): ChartViewFactory {
    return new ChartViewFactory(
      this._teeth.filter((tooth: ITooth) => {
        return !isUpperQuadrant(tooth.toothRef.quadrant);
      })
    );
  }

  right(): ChartViewFactory {
    return new ChartViewFactory(
      this._teeth.filter((tooth: ITooth) => {
        return isRightQuadrant(tooth.toothRef.quadrant);
      })
    );
  }

  left(): ChartViewFactory {
    return new ChartViewFactory(
      this._teeth.filter((tooth: ITooth) => {
        return !isRightQuadrant(tooth.toothRef.quadrant);
      })
    );
  }
}

enum ArchLabel {
  AdultUpper = 'Adult Upper',
  AdultLower = 'Adult Lower',
  DeciduousUpper = 'Deciduous Upper',
  DeciduousLower = 'Deciduous Lower',
}

interface IArchDisplay {
  label: ArchLabel;
  teeth: ITooth[];
}

function unzipByArch(teeth: ITooth[]): IArchDisplay[] {
  const viewFactory = new ChartViewFactory(teeth);
  const adult = viewFactory.adult();
  const deciduous = viewFactory.deciduous();
  return [
    { label: ArchLabel.AdultUpper, teeth: adult.top().result() },
    { label: ArchLabel.DeciduousUpper, teeth: deciduous.top().result() },
    { label: ArchLabel.AdultLower, teeth: adult.bottom().result() },
    { label: ArchLabel.DeciduousLower, teeth: deciduous.bottom().result() },
  ];
}
