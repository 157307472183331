import { Brand } from '@principle-theorem/principle-core';
import {
  type IBrand,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  type WithRef,
  isObject,
} from '@principle-theorem/shared';
import { type DocumentReference, doc } from '@principle-theorem/shared';
import { isString } from 'lodash';
import * as moment from 'moment-timezone';

export interface IManageAppointmentQueryParams {
  from: moment.Moment;
  to: moment.Moment;
  staffer: DocumentReference<IStaffer>;
  practice: DocumentReference<IPractice>;
}

export interface ISerialisedManageAppointmentQueryParams {
  from: string; // "2021-08-23 09:45:00"
  to: string; // "2021-08-23 10:45:00"
  staffer: string; // "701873e3-8bc6-49a6-b78b-258b56d93631"
  practice: string; // "701873e3-8bc6-49a6-b78b-258b56d93631"
}

export function isSerialisedManageAppointmentQueryParams(
  item: unknown
): item is ISerialisedManageAppointmentQueryParams {
  return (
    isObject(item) &&
    isString(item.from) &&
    isString(item.to) &&
    isString(item.staffer) &&
    isString(item.practice)
  );
}

export class ManageAppointmentQueryParams {
  static serialise(
    params: IManageAppointmentQueryParams
  ): ISerialisedManageAppointmentQueryParams {
    return {
      from: params.from.format(ISO_DATE_TIME_FORMAT),
      to: params.to.format(ISO_DATE_TIME_FORMAT),
      staffer: params.staffer.id,
      practice: params.practice.id,
    };
  }

  static unserialise(
    brand: WithRef<IBrand>,
    params: unknown
  ): IManageAppointmentQueryParams {
    if (!isSerialisedManageAppointmentQueryParams(params)) {
      throw new Error('Missing manage appointment query params');
    }
    return {
      from: moment(params.from),
      to: moment(params.to),
      staffer: doc(Brand.stafferCol(brand), params.staffer),
      practice: doc(Brand.practiceCol(brand), params.practice),
    };
  }
}
