import { NgModule } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ComponentsModule } from '../../components/components.module';
import { LabJobsTabsComponent } from './lab-jobs-tabs/lab-jobs-tabs.component';

@NgModule({
  imports: [
    ComponentsModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    NgMaterialModule,
  ],
  declarations: [LabJobsTabsComponent],
})
export class LabJobPagesModule {}
