import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { type ThemePalette } from '@angular/material/core';

@Component({
    selector: 'pr-tool-card',
    templateUrl: './tool-card.component.html',
    styleUrls: ['./tool-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ToolCardComponent {
  @Input() loading = false;
  @Input() colour: ThemePalette = 'primary';
}
