import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {
  CurrentPracticeScope,
  GeocodeService,
} from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
  formControlChanges$,
  type IBreadcrumb,
} from '@principle-theorem/ng-shared';
import {
  type IGeoCoordinates,
  type IPractice,
  type IPracticeSettings,
} from '@principle-theorem/principle-core/interfaces';
import {
  DEFAULT_TIMEZONE,
  Firestore,
  TIMEZONES,
  filterUndefined,
  findProp,
  slugify,
  snapshot,
  type Timezone,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, Subject, of, type Observable } from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

type IPracticeFormData = Pick<
  IPractice,
  'name' | 'address' | 'email' | 'phone' | 'settings'
>;

@Component({
  selector: 'pr-general-practice',
  templateUrl: './general-practice.component.html',
  styleUrls: ['./general-practice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralPracticeComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByTimezone = TrackByFunctions.variable<Timezone>();
  practice$: Observable<WithRef<IPractice>>;

  timezones: Timezone[] = TIMEZONES;
  coordinates$ = new BehaviorSubject<IGeoCoordinates | undefined>(undefined);
  form: TypedFormGroup<IPracticeFormData> =
    new TypedFormGroup<IPracticeFormData>({
      name: new TypedFormControl('', Validators.required),
      address: new TypedFormControl('', Validators.required),
      email: new TypedFormControl('', [Validators.required, Validators.email]),
      phone: new TypedFormControl('', Validators.required),
      settings: new TypedFormGroup<Pick<IPracticeSettings, 'timezone'>>({
        timezone: new TypedFormControl(DEFAULT_TIMEZONE, Validators.required),
      }),
    });
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(
    private _route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _geocodeService: GeocodeService,
    private _currentPractice: CurrentPracticeScope
  ) {
    this.practice$ = this._route.data.pipe(
      findProp<WithRef<IPractice>>('practice'),
      filterUndefined()
    );

    this.practice$.pipe(takeUntil(this._onDestroy$)).subscribe((practice) => {
      this.form.patchValue(practice);
    });

    formControlChanges$(this.form.controls.address)
      .pipe(
        debounceTime(500),
        switchMap((address) =>
          address
            ? this._geocodeService
                .geocodeAddress$(address)
                .pipe(catchError(() => of(undefined)))
            : of(undefined)
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((coordinates) => this.coordinates$.next(coordinates));

    this.breadcrumbs$ = this._currentPractice.doc$.pipe(
      filterUndefined(),
      map((practice) => [
        { label: 'Settings', path: '../../../' },
        { label: practice.name },
        { label: 'General Settings' },
      ])
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async save(): Promise<void> {
    const practice = await snapshot(this.practice$);
    const coordinates = await snapshot(this.coordinates$);
    const changes = this.form.getRawValue();
    await Firestore.saveDoc({
      ...practice,
      ...changes,
      slug: slugify(changes.name.toLowerCase()),
      coordinates,
    });
    this._snackBar.open('Practice Updated');
  }
}
