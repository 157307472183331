import { ChartElement } from './chart-element';
import { type IChartTooth } from './chart-tooth';
import {
  isUpperQuadrant,
  isRightQuadrant,
} from '@principle-theorem/principle-core';

export class ChartToothTexture extends ChartElement {
  constructor(public parent: IChartTooth) {
    super();
    this.width = 120;
    this.height = 120;
    this.xPos = parent.width / 2 - this.width / 2;
    this.yPos = parent.height * 0.57 - this.height / 2;
    this.yFlipped = isUpperQuadrant(parent.tooth.toothRef.quadrant);
    this.xFlipped = isRightQuadrant(parent.tooth.toothRef.quadrant);
  }
}
