<ng-container *ngIf="option$ | async as option">
  <pt-action-list-button
    *ngIf="option.provider.isEnabled$ | async"
    [imageUrl]="option.imageUrl"
    [icon]="option.icon"
    [label]="option.label"
    [isDisabled]="isDisabled$ | async"
    (click)="capture()"
  />
</ng-container>
