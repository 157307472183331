export interface IDynamicForm<Type = unknown> {
  [key: string]: AnyDynamicFormControl<Type>;
}

export enum DynamicFormType {
  Select = 'select',
  Number = 'number',
}

export type IDynamicFormControl<T> = {
  type: DynamicFormType;
  label: string;
  isRequired?: boolean;
} & (
  | {
      initialValue?: T;
      isMultiple?: false;
    }
  | {
      initialValue?: T[];
      isMultiple: boolean;
    }
);

export interface IDynamicFormSelectOption<T> {
  label: string;
  value: T;
}

export type IDynamicFormSelect<T> = IDynamicFormControl<T> & {
  type: DynamicFormType.Select;
  options: IDynamicFormSelectOption<T>[];
  compareWith: (a: T, b: T) => boolean;
};

export type IDynamicFormNumber<T> = IDynamicFormControl<T> & {
  type: DynamicFormType.Number;
};

export type AnyDynamicFormControl<T> =
  | IDynamicFormSelect<T>
  | IDynamicFormNumber<T>;
