import { NgModule } from '@angular/core';
import { ComponentsModule } from './components/components.module';
import { CreateTaskActionService } from './create-task-action.service';

@NgModule({
  imports: [ComponentsModule],
  exports: [ComponentsModule],
  providers: [CreateTaskActionService],
})
export class NgTasksModule {}
