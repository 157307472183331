import { AbstractTable } from '@principle-theorem/ng-shared';
import { type WithRef } from '@principle-theorem/shared';
import { FirebaseDataSource } from './firebase-data-source';

export abstract class FirebaseTable<T extends object> extends AbstractTable<
  T,
  FirebaseDataSource<T>
> {
  override dataSource: FirebaseDataSource<T> = new FirebaseDataSource<T>();

  trackByUid(_: number, item: WithRef<T>): string {
    return item.ref.id;
  }
}
