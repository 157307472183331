import { first, get, isNumber, isString, mapValues } from 'lodash';
import { isTimestamp } from './firebase/timestamp';
import { isDate, toMoment } from './time/time';
import { Timestamp } from './firebase/firestore/adaptor';
import { ISODateType } from './time/timezone';
import { ISO_DATE_FORMAT } from './time/date-time-formatting';
import { splitCamel, titlecase } from './helpers';

export interface IDataTable<T> {
  name: string;
  columns: IDataColumn<T>[];
  data: T[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyDataTable = IDataTable<any>;

export interface IDataColumn<T> {
  key: string;
  header: string;
  getValue?: (row: T) => DataValue;
  format?: DataFormat;
}

export type DataValue = null | number | string | boolean | Date | undefined;

export enum DataFormat {
  Currency = 'currency',
  Percent = 'percent',
  Date = 'date',
  DateTime = 'dateTime',
}

export interface IDataCollectionDownloader {
  download(fileName: string, tables: AnyDataTable[]): Promise<void>;
}

export class DataCollection {
  static inferColumnMap<T extends object>(
    records: T[]
  ): Record<keyof T, IDataColumn<unknown>> {
    const record = first(records);
    if (!record) {
      return {} as Record<keyof T, IDataColumn<unknown>>;
    }
    return mapValues(record, (_value, key) => ({
      key,
      header: titlecase(splitCamel(key)),
    }));
  }

  static getValueForColumn<T>(record: T, columnDef: IDataColumn<T>): DataValue {
    if (columnDef.getValue) {
      return columnDef.getValue(record);
    }
    const valueKey = columnDef.key;
    if (!valueKey) {
      // eslint-disable-next-line no-console
      console.warn('No value key for column', columnDef);
      return '';
    }
    return get(record, valueKey) as DataValue;
  }

  static formatValueForColumn<T>(
    value: DataValue,
    columnDef: IDataColumn<T>
  ): DataValue {
    switch (columnDef.format) {
      case DataFormat.Currency:
        return isNumber(value) ? DataCollection.formatCurrency(value) : value;
      case DataFormat.Percent:
        return isNumber(value) ? DataCollection.formatPercent(value) : value;
      case DataFormat.Date:
        return isDate(value) || isString(value) || isTimestamp(value)
          ? DataCollection.formatDate(value)
          : value;
      case DataFormat.DateTime:
        return isDate(value) || isString(value) || isTimestamp(value)
          ? DataCollection.formatDateTime(value)
          : value;
      default:
        return value;
    }
  }

  static formatCurrency(value: number): string {
    const amount = value.toFixed(2);
    return `$${amount}`;
  }

  static formatPercent(value: number): string {
    const percent = (value * 100).toFixed(2);
    return `${percent}%`;
  }

  static formatDate(
    timestamp: Timestamp | moment.Moment | Date | ISODateType
  ): string {
    return toMoment(timestamp).format(ISO_DATE_FORMAT);
  }

  static formatDateTime(
    timestamp: Timestamp | moment.Moment | Date | ISODateType
  ): string {
    return toMoment(timestamp).format();
  }
}
