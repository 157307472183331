import {
  IBrand,
  IPatientMetadataDisplay,
  ISchedulingEventReason,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  Transaction,
  WithRef,
  addDoc,
  asyncForEach,
  isSameRef,
} from '@principle-theorem/shared';
import { Brand } from './brand';
import { PatientMetadataDisplay } from './patient/patient-metadata';
import { ISchedulingEventReasonDefault } from './scheduling-event-reason-defaults';

export interface ISchedulingEventReasonSeederUpsertJob {
  existingReason?: WithRef<ISchedulingEventReason>;
  existingMetadataDisplay?: WithRef<IPatientMetadataDisplay>;
  defaultReason: ISchedulingEventReasonDefault;
}

export class SchedulingEventReasonSeeder {
  static getUpsertJobs(
    existingReasons: WithRef<ISchedulingEventReason>[],
    existingMetadataDisplays: WithRef<IPatientMetadataDisplay>[],
    defaultReasons: ISchedulingEventReasonDefault[]
  ): ISchedulingEventReasonSeederUpsertJob[] {
    return defaultReasons.map((defaultReason) => {
      const existingReason = this.findExistingReasonForTemplate(
        existingReasons,
        defaultReason
      );
      const existingMetadataDisplay =
        existingReason &&
        existingMetadataDisplays.find((display) =>
          isSameRef(display.ref, existingReason.metadataDisplayRef)
        );
      return { existingReason, existingMetadataDisplay, defaultReason };
    });
  }

  static findExistingReasonForTemplate(
    existingReasons: WithRef<ISchedulingEventReason>[],
    defaultReason: ISchedulingEventReasonDefault
  ): WithRef<ISchedulingEventReason> | undefined {
    return existingReasons.find((existingReason) => {
      return (
        existingReason.name === defaultReason.key ||
        existingReason.name === defaultReason.reasonTemplate.name ||
        existingReason.name === defaultReason.metadataDisplay.label
      );
    });
  }

  static async performUpsertJobs(
    brandRef: DocumentReference<IBrand>,
    jobs: ISchedulingEventReasonSeederUpsertJob[],
    transaction: Transaction
  ): Promise<DocumentReference<ISchedulingEventReason>[]> {
    return asyncForEach(jobs, async (job) =>
      this.upsertSchedulingEventReason(brandRef, job, transaction)
    );
  }

  static async upsertSchedulingEventReason(
    brandRef: DocumentReference<IBrand>,
    job: ISchedulingEventReasonSeederUpsertJob,
    transaction: Transaction
  ): Promise<DocumentReference<ISchedulingEventReason>> {
    const metadataDisplayRef = await this.upsertMetadataDisplay(
      brandRef,
      job,
      transaction
    );

    const newReason: ISchedulingEventReason = {
      ...job.defaultReason.reasonTemplate,
      metadataDisplayRef,
    };

    if (!job.existingReason) {
      return addDoc(
        Brand.cancellationReasonCol({ ref: brandRef }),
        newReason,
        undefined,
        transaction
      );
    }

    await Firestore.patchDoc(
      job.existingReason.ref,
      {
        ...newReason,
        name: job.existingReason.name,
        deleted: job.defaultReason.reasonTemplate.isSystemReason
          ? false
          : job.existingReason.deleted,
      },
      transaction
    );

    return job.existingReason.ref;
  }

  static async upsertMetadataDisplay(
    brandRef: DocumentReference<IBrand>,
    job: ISchedulingEventReasonSeederUpsertJob,
    transaction?: Transaction
  ): Promise<DocumentReference<IPatientMetadataDisplay>> {
    const updatedMetadataDisplay = PatientMetadataDisplay.init({
      ...job.existingMetadataDisplay,
      ...job.defaultReason.metadataDisplay,
    });
    if (job.existingMetadataDisplay) {
      await Firestore.patchDoc(
        job.existingMetadataDisplay.ref,
        { ...updatedMetadataDisplay, deleted: job.existingReason?.deleted },
        transaction
      );
      return job.existingMetadataDisplay.ref;
    }

    return addDoc(
      Brand.patientMetadataDisplayCol({ ref: brandRef }),
      updatedMetadataDisplay,
      undefined,
      transaction
    );
  }
}
