import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { type IRelativeSchedulingRules } from '@principle-theorem/principle-core/interfaces';
import {
  type ITreatmentStepConfiguration,
  type IMultiTreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  getDoc,
  safeCombineLatest,
  saveDoc,
  type WithRef,
} from '@principle-theorem/shared';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { type ITreatmentStep } from '@principle-theorem/principle-core/interfaces';
import { from, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface IStepFormData extends Pick<ITreatmentStep, 'name'> {
  schedulingRules: Partial<IRelativeSchedulingRules>;
}

interface ITreatmentStepConfigurationData {
  multiTreatmentConfig: WithRef<IMultiTreatmentConfiguration>;
  treatmentStep: ITreatmentStepConfiguration;
}

@Component({
    selector: 'pr-edit-treatment-step-configuration-dialog',
    templateUrl: './edit-treatment-step-configuration-dialog.component.html',
    styleUrls: ['./edit-treatment-step-configuration-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditTreatmentStepConfigurationDialogComponent {
  multiTreatmentConfig: WithRef<IMultiTreatmentConfiguration>;
  treatmentStep: ITreatmentStepConfiguration;
  form: TypedFormGroup<IStepFormData> = new TypedFormGroup<IStepFormData>({
    name: new TypedFormControl('', Validators.required),
    schedulingRules: new TypedFormGroup<Partial<IRelativeSchedulingRules>>({
      duration: new TypedFormControl<number>(),
      minDays: new TypedFormControl(0),
      maxDays: new TypedFormControl(0),
    }),
  });
  showAdvanced = false;
  stepDuration$: Observable<number>;
  stepIndex: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ITreatmentStepConfigurationData,
    private _dialogRef: MatDialogRef<EditTreatmentStepConfigurationDialogComponent>,
    private _snackBar: MatSnackBar
  ) {
    this.multiTreatmentConfig = data.multiTreatmentConfig;
    this.treatmentStep = data.treatmentStep;

    this.form.patchValue({
      name: this.treatmentStep.name,
      schedulingRules: {
        duration:
          this.treatmentStep.schedulingRules.duration > 0
            ? this.treatmentStep.schedulingRules.duration
            : undefined,
        minDays: this.treatmentStep.schedulingRules.minDays ?? 0,
        maxDays: this.treatmentStep.schedulingRules.maxDays ?? 0,
      },
    });

    this.stepDuration$ = safeCombineLatest(
      this.treatmentStep.treatments.map((treatmentConfig) =>
        from(getDoc(treatmentConfig.ref)).pipe(
          map((treatment) => treatment.duration * treatmentConfig.quantity)
        )
      )
    ).pipe(
      map((treatments) =>
        treatments.reduce((prev, duration) => prev + duration, 0)
      )
    );

    this.stepIndex = this.multiTreatmentConfig.steps.findIndex(
      (step) => step.name === this.treatmentStep.name
    );
    this.showAdvanced = this.hasDatesSet();
  }

  hasDatesSet(): boolean {
    const dates = this.form.controls.schedulingRules.value;
    return dates?.minDays || dates?.minDays ? true : false;
  }

  updateDates(change: MatSlideToggleChange): void {
    if (change.checked) {
      this.showAdvanced = true;
      return;
    }
    this.showAdvanced = false;
    this.clearMinDays();
    this.clearMaxDays();
  }

  async save(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    const data = this.form.getRawValue();
    const maxDays =
      data.schedulingRules.maxDays && data.schedulingRules.maxDays > 0
        ? data.schedulingRules.maxDays
        : undefined;
    const minDays =
      data.schedulingRules.minDays && data.schedulingRules.minDays > 0
        ? data.schedulingRules.minDays
        : undefined;

    this.treatmentStep = {
      ...this.treatmentStep,
      name: data.name,
      schedulingRules: {
        duration: data.schedulingRules.duration ?? 0,
        maxDays,
        minDays,
      },
    };

    const steps = this.multiTreatmentConfig.steps;
    steps[this.stepIndex] = this.treatmentStep;
    await saveDoc({
      ...this.multiTreatmentConfig,
      steps,
    });
    this._snackBar.open('Treatment Step Updated');
    this._dialogRef.close();
  }

  clearMinDays(): void {
    this.form.controls.schedulingRules.patchValue({
      minDays: 0,
    });
  }

  clearMaxDays(): void {
    this.form.controls.schedulingRules.patchValue({
      maxDays: 0,
    });
  }
}
