export function injectScript(
  id: string,
  src: string,
  type: string = 'text/javascript',
  isAsync: boolean = true,
  override: boolean = false
): Promise<void> {
  return new Promise((resolve, reject) => {
    const existing = document.getElementById(id);
    if (existing && !override) {
      reject(`Script already exists: ${id}`);
      return;
    }
    if (existing && override) {
      existing.remove();
    }

    const script = document.createElement('script');
    script.id = id;
    script.src = src;
    script.type = type;
    script.async = isAsync;
    script.addEventListener('load', () => resolve());
    script.addEventListener('error', (e) => reject(e.error));
    document.head.appendChild(script);
  });
}
