import { Injectable } from '@angular/core';
import {
  AppointmentCreateSidebarComponent,
  AppointmentCreateSidebarService,
  IAppointmentCreateSidebarData,
} from '@principle-theorem/ng-appointment';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import {
  CalendarEventEditSidebarComponent,
  CalendarEventSidebarStoreService,
} from '@principle-theorem/ng-calendar';
import { DynamicSidebarService } from '@principle-theorem/ng-shared';
import { CalendarEvent, Event } from '@principle-theorem/principle-core';
import {
  EventType,
  IEvent,
  IEventable,
  IPractice,
  IScheduleSummaryEventable,
  IStaffer,
  ITimelineDay,
  ITimelineDisplayOptions,
  ITimelineNode,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  ITimePeriod,
  WithRef,
  filterUndefined,
  isSameRef,
  snapshot,
} from '@principle-theorem/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { InteractiveTimelineDisplayCalculator } from './interactive-timeline-display-calculator';
import {
  CreateNode,
  IManageEventable,
  ITimelineNodeSelection,
} from './interactive-timeline-logic';

type Days = ITimelineDay<IEventable | WithRef<IEventable>, WithRef<IStaffer>>[];
type Node = ITimelineNode<IEventable<object> | WithRef<IEventable<object>>>;
interface ITrackIndexNode {
  track: number;
  node: Node;
}

@Injectable({
  providedIn: 'root',
})
export class InteractiveTimelineService {
  private _createNode$ = new BehaviorSubject<CreateNode | undefined>(undefined);
  private _options$ = new BehaviorSubject<ITimelineDisplayOptions | undefined>(
    undefined
  );
  selectedNode$ = new BehaviorSubject<ITimelineNodeSelection | undefined>(
    undefined
  );

  // TODO: Fix the way that this is assigned. It's not a good pattern.
  days$: Observable<Days>;

  readonly createNode$ = this._createNode$.asObservable();
  readonly options$ = this._options$.asObservable();
  readonly isHorizontal$: Observable<boolean>;

  constructor(
    private _appointmentScheduling: AppointmentSchedulingFacade,
    private _appointmentCreateSidebar: AppointmentCreateSidebarService,
    private _calendarEventSidebarStore: CalendarEventSidebarStoreService,
    private _sidebar: DynamicSidebarService
  ) {
    this.isHorizontal$ = this._options$.pipe(
      filterUndefined(),
      map(InteractiveTimelineDisplayCalculator.isHorizontal),
      distinctUntilChanged()
    );
  }

  setCreateNode(createNode: CreateNode | undefined): void {
    this._createNode$.next(createNode);
  }

  setOptions(options: ITimelineDisplayOptions): void {
    this._options$.next(options);
  }

  createNodeDataFromGap(
    gap: IScheduleSummaryEventable,
    eventType: EventType
  ): IManageEventable {
    return {
      type: 'manage',
      event: Event.init({
        ...gap.event,
        type: eventType,
      }),
    };
  }

  async handleCreateFromGap(
    time: ITimePeriod,
    day: ITimePeriod,
    staffer: DocumentReference<IStaffer>,
    createType: EventType,
    practice: WithRef<IPractice>,
    createNodeData: IManageEventable,
    trackIndex: number
  ): Promise<void> {
    await this.handleSidebarCreateActions(
      time,
      staffer,
      createType,
      practice,
      createNodeData
    );
    this.setCreateNode({
      trackIndex,
      day,
      uid: 'create',
      from: time.from,
      to: time.to,
      dragEnabled: true,
      resizeEnabled: true,
      data: createNodeData,
    });
  }

  async handleSidebarCreateActions(
    time: ITimePeriod,
    stafferRef: DocumentReference<IStaffer>,
    createType: EventType,
    practice: WithRef<IPractice>,
    createNodeData: IManageEventable
  ): Promise<void> {
    const currentSidebar = await snapshot(this._sidebar.currentComponent$);

    if (createType !== EventType.Appointment) {
      this._calendarEventSidebarStore.setState({
        calendarEvent: CalendarEvent.init({
          event: createNodeData.event,
          isBlocking: [EventType.PreBlock, EventType.RosteredOn].includes(
            createType
          )
            ? false
            : true,
        }),
      });

      if (currentSidebar === CalendarEventEditSidebarComponent) {
        return;
      }

      this._sidebar.open(CalendarEventEditSidebarComponent, {
        data: {
          saveFn: async () => Promise.resolve(),
        },
      });
      return;
    }

    const currentAppointment = await snapshot(
      this._appointmentScheduling.currentAppointment$
    );

    if (currentAppointment) {
      this._appointmentScheduling.reset();
    }

    this._appointmentScheduling.resetWaitList();
    this._appointmentScheduling.setRequestedAppointmentOption({
      params: {
        from: time.from,
        to: time.to,
        staffer: stafferRef,
        practice: practice.ref,
      },
    });

    if (currentSidebar === AppointmentCreateSidebarComponent) {
      return;
    }

    this._sidebar.open<
      AppointmentCreateSidebarComponent,
      IAppointmentCreateSidebarData
    >(AppointmentCreateSidebarComponent, {
      cleanUpCallbackFn: async () => this._appointmentCreateSidebar.cleanUp(),
      data: {
        saveFn: async () => Promise.resolve(),
      },
    });
  }

  async getTrackIndexFromEvent(event: IEvent): Promise<number | undefined> {
    const days = await snapshot(this.days$);
    const timelineNodes = this._flattenTimelineNodes(days);
    const foundNode = timelineNodes.find(({ node }) =>
      this._isMatchingEventNode(node, event)
    );
    return foundNode ? foundNode.track : undefined;
  }

  private _flattenTimelineNodes(days: Days): ITrackIndexNode[] {
    let track = -1;
    const timelineNodes: ITrackIndexNode[] = [];

    days.forEach((day) =>
      day.groups.forEach((group) => {
        if (group.nodes.length === 0) {
          track += 1;
          return;
        }
        group.nodes.forEach((events) => {
          track += 1;
          events.forEach((eventNode) =>
            timelineNodes.push({ track, node: eventNode })
          );
        });
      })
    );

    return timelineNodes;
  }

  private _isMatchingEventNode(node: Node, event: IEvent): boolean {
    return (
      node.data.event.type === event.type &&
      node.data.event.from.isEqual(event.from) &&
      node.data.event.to.isEqual(event.to) &&
      node.data.event.participantRefs.some((nodeRef) =>
        event.participantRefs.some((eventRef) => isSameRef(nodeRef, eventRef))
      )
    );
  }
}
