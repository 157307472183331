import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { RecurringTaskConfigurationComponent } from './recurring-task-configuration/recurring-task-configuration.component';
import { TaskListComponent } from './task-list/task-list.component';
import { TaskPagesModule } from './task-pages.module';
import { TaskTabsComponent } from './task-tabs/task-tabs.component';

const routes: Routes = [
  {
    path: '',
    component: TaskTabsComponent,
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: TaskListComponent,
        data: { title: 'Tasks' },
      },
      {
        path: 'recurring-configuration',
        component: RecurringTaskConfigurationComponent,
        data: { title: 'Recurring Tasks Configuration' },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), TaskPagesModule],
  exports: [RouterModule],
})
export class TaskPagesRoutingModule {}
