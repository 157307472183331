<button
  mat-icon-button
  (click)="previousDay()"
  matTooltip="Previous Day"
  class="rounded-button"
>
  <mat-icon aria-label="Previous Day">chevron_left</mat-icon>
</button>

<button
  mat-icon-button
  (click)="today()"
  matTooltip="Today"
  [disabled]="isToday$ | async"
>
  <mat-icon aria-label="Today">home</mat-icon>
</button>

<mat-form-field class="hide-input invisible">
  <input matInput [matDatepicker]="picker" [formControl]="dateCtrl" />
  <mat-datepicker #picker />
</mat-form-field>

<button mat-icon-button (click)="picker.open()" matTooltip="Select a Day">
  <mat-icon aria-label="Select Date">event</mat-icon>
</button>

<button
  mat-icon-button
  (click)="nextDay()"
  matTooltip="Next Day"
  class="rounded-button"
>
  <mat-icon aria-label="Next Day">chevron_right</mat-icon>
</button>
