import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_MEDICAL_CONDITION_OPTION_RESOURCE_TYPE =
  'patientMedicalConditionOptions';

export const PATIENT_MEDICAL_CONDITION_OPTION_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Medical Condition Option List',
      description: '',
      idPrefix: PATIENT_MEDICAL_CONDITION_OPTION_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ICorePracticePatientMedicalConditionOption {
  description: string; // "High / Low Blood Pressure"
}

export function isCorePracticePatientMedicalConditionOption(
  item: unknown
): item is ICorePracticePatientMedicalConditionOption {
  return TypeGuard.interface<ICorePracticePatientMedicalConditionOption>({
    description: isString,
  })(item);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICorePracticePatientMedicalConditionOptionTranslations {}

export interface ICorePracticePatientMedicalConditionOptionFilters {
  patientHistoryId: number;
}

const PATIENT_MEDICAL_CONDITION_OPTION_SOURCE_QUERY = `
SELECT
  Description AS description
FROM tblPatientMedCond
WHERE PatientHistoryId IS NOT NULL
GROUP BY Description
`;

export class PatientMedicalConditionOptionSourceEntity extends BaseSourceEntity<
  ICorePracticePatientMedicalConditionOption,
  ICorePracticePatientMedicalConditionOptionTranslations,
  ICorePracticePatientMedicalConditionOptionFilters
> {
  sourceEntity = PATIENT_MEDICAL_CONDITION_OPTION_SOURCE_ENTITY;
  entityResourceType = PATIENT_MEDICAL_CONDITION_OPTION_RESOURCE_TYPE;
  sourceQuery = PATIENT_MEDICAL_CONDITION_OPTION_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientMedicalConditionOption;

  translate(
    _data: ICorePracticePatientMedicalConditionOption,
    _timezone: Timezone
  ): ICorePracticePatientMedicalConditionOptionTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientMedicalConditionOption): string {
    return data.description.replace(/[^a-zA-Z0-9]+/g, '-');
  }

  getSourceLabel(data: ICorePracticePatientMedicalConditionOption): string {
    return data.description;
  }
}
