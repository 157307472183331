<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="wrapper">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab label="Saved Custom Reports">
      <ng-template matTabContent>
        <h1 class="layout-margin">Custom Reports</h1>

        <div class="layout-margin" *prHasPermission="[reportBuilderPermission]">
          <p>
            Build a report in the report builder. Filter by charts, configure
            your table and save it to run again in the future!
          </p>
          <button
            *prHasPermission="[createCustomReportsPermission]"
            mat-flat-button
            color="primary"
            routerLink="../report-builder"
          >
            Create Custom Report
          </button>
        </div>

        <div class="layout-margin">
          <mat-nav-list class="mat-elevation-z1">
            <a
              mat-list-item
              *ngFor="
                let customReport of customReports$ | async;
                trackBy: trackByCustomReport
              "
              [routerLink]="['./', customReport.ref.id]"
            >
              <span matListItemTitle>{{ customReport.name }}</span>
              <span matListItemLine class="mat-caption">
                {{ customReport.createdAt | moment | amDateFormat: dateFormat }}
              </span>
            </a>
          </mat-nav-list>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab label="Managed Reports">
      <ng-template matTabContent>
        <pr-system-reports />
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
