<mat-toolbar color="accent">Add Organisation</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>

    <mat-form-field fxFlex="50">
      <mat-label>Region</mat-label>
      <mat-select formControlName="region">
        @for (region of regions; track region) {
          <mat-option [value]="region">{{ region }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button mat-flat-button color="primary" [disabled]="!canSave()">
        Save
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
