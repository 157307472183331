import { isObject } from '@principle-theorem/shared';
import { isString } from 'lodash';

export interface ITokenRequest {
  tokenUid: string;
}

export function isTokenRequest(data: unknown): data is ITokenRequest {
  return isObject(data) && isString(data.tokenUid);
}
