import { createAction, props } from '@ngrx/store';
import { type IPractice } from '@principle-theorem/principle-core/interfaces';
import { type ITransactionSourceSummary } from '@principle-theorem/reporting';
import {
  type ITimePeriod,
  type SerialisedData,
  type WithRef,
} from '@principle-theorem/shared';

export enum ReconciliationReportActions {
  SetDateRange = '[Reconciliation Report] Set Date Range',
  SetPractice = '[Reconciliation Report] Set Practice',
  LoadReport = '[Reconciliation Report] Load Report',
  SetPrincipleReport = '[Reconciliation Report] Set Principle Report',
  SetTyroReport = '[Reconciliation Report] Set Tyro Report',
  SetHealthPointReport = '[Reconciliation Report] Set HealthPoint Report',
  SelectSummary = '[Reconciliation Report] Select Summary',
  ClearSelectedSummary = '[Reconciliation Report] Clear Selected Summary',
}

interface ISetDateRange {
  range: ITimePeriod;
}

export const setDateRange = createAction(
  ReconciliationReportActions.SetDateRange,
  props<SerialisedData<ISetDateRange>>()
);

interface ISetPractice {
  practice?: WithRef<IPractice>;
}

export const setPractice = createAction(
  ReconciliationReportActions.SetPractice,
  props<SerialisedData<ISetPractice>>()
);

export const loadReport = createAction(ReconciliationReportActions.LoadReport);

export const selectSummary = createAction(
  ReconciliationReportActions.SelectSummary,
  props<SerialisedData<ITransactionSourceSummary>>()
);

export const clearSelectedSummary = createAction(
  ReconciliationReportActions.ClearSelectedSummary
);
