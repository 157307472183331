import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgFirebaseModule } from '@principle-theorem/ng-firebase';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleBridgeCloudModule } from '@principle-theorem/ng-principle-bridge-cloud';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NgTyroModule } from '@principle-theorem/ng-tyro';
import { CoreModule } from '../../../core/core.module';
import { GeneralPracticeComponent } from './general-practice/general-practice.component';
import { AddPublicKeyDialogComponent } from './practice-integrations/practice-bridge-settings/add-public-key-dialog/add-public-key-dialog.component';
import { EditBridgeDeviceDialogComponent } from './practice-integrations/practice-bridge-settings/edit-bridge-device-dialog/edit-bridge-device-dialog.component';
import { IntegrationKeySheetComponent } from './practice-integrations/practice-bridge-settings/integration-key-sheet/integration-key-sheet.component';
import { PracticeBridgeSettingsComponent } from './practice-integrations/practice-bridge-settings/practice-bridge-settings.component';
import { PracticeHicapsConnectSettingsComponent } from './practice-integrations/practice-hicaps-connect-settings/practice-hicaps-connect-settings.component';
import { PracticeIntegrationsComponent } from './practice-integrations/practice-integrations.component';
import { EditTyroTerminalDialogComponent } from './practice-integrations/practice-tyro-settings/edit-tyro-terminal-dialog/edit-tyro-terminal-dialog.component';
import { PairTyroTerminalDialogComponent } from './practice-integrations/practice-tyro-settings/pair-tyro-terminal-dialog/pair-tyro-terminal-dialog.component';
import { PairTyroTerminalService } from './practice-integrations/practice-tyro-settings/pair-tyro-terminal-dialog/pair-tyro-terminal.service';
import { PracticeTyroSettingsComponent } from './practice-integrations/practice-tyro-settings/practice-tyro-settings.component';
import { TyroConfigurationComponent } from './practice-integrations/practice-tyro-settings/tyro-configuration/tyro-configuration.component';
import { TyroLogsDialogComponent } from './practice-integrations/practice-tyro-settings/tyro-logs-dialog/tyro-logs-dialog.component';
import { EditPracticeOpeningHoursComponent } from './practice-opening-hours/edit-practice-opening-hours/edit-practice-opening-hours.component';
import { PracticeOpeningHoursComponent } from './practice-opening-hours/practice-opening-hours.component';
import { PracticeFeeScheduleResolver } from './practice-schedule-resolver.service';
import { PracticeTagManagementComponent } from './practice-tag-management/practice-tag-management.component';
import { PracticeHicapsConnectTerminalsComponent } from './practice-integrations/practice-hicaps-connect-settings/practice-hicaps-connect-terminals/practice-hicaps-connect-terminals.component';
import { HicapsCustomRequestComponent } from './practice-integrations/practice-hicaps-connect-settings/hicaps-custom-request/hicaps-custom-request.component';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { EditBridgeSettingsDialogComponent } from './practice-integrations/practice-bridge-settings/edit-bridge-settings-dialog/edit-bridge-settings-dialog.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ViewBridgeLogsDialogComponent } from './practice-integrations/practice-bridge-settings/view-bridge-logs-dialog/view-bridge-logs-dialog.component';
import { PracticeTyroTerminalsComponent } from './practice-integrations/practice-tyro-settings/practice-tyro-terminals/practice-tyro-terminals.component';
import { DownloadBridgeProgressDialogComponent } from './practice-integrations/practice-bridge-settings/download-bridge-progress-dialog/download-bridge-progress-dialog.component';
import { PracticeSmartpayTerminalsComponent } from './practice-integrations/practice-smartpay-settings/practice-smartpay-terminals/practice-smartpay-terminals.component';
import { SmartpayConfigurationComponent } from './practice-integrations/practice-smartpay-settings/smartpay-configuration/smartpay-configuration.component';
import { PairSmartpayTerminalDialogComponent } from './practice-integrations/practice-smartpay-settings/pair-smartpay-terminal-dialog/pair-smartpay-terminal-dialog.component';
import { EditSmartpayTerminalDialogComponent } from './practice-integrations/practice-smartpay-settings/edit-smartpay-terminal-dialog/edit-smartpay-terminal-dialog.component';
import { PracticeSmartpaySettingsComponent } from './practice-integrations/practice-smartpay-settings/practice-smartpay-settings.component';
import { PractitionerSelectorComponent } from '@principle-theorem/ng-clinical-charting';

@NgModule({
  imports: [
    NgMaterialModule,
    NgSharedModule,
    NgTyroModule,
    CoreModule,
    ReactiveFormsModule,
    NgFirebaseModule,
    NgPrincipleSharedModule,
    NgPrincipleBridgeCloudModule,
    MonacoEditorModule,
    NgJsonEditorModule,
    PractitionerSelectorComponent,
  ],
  declarations: [
    GeneralPracticeComponent,
    PracticeOpeningHoursComponent,
    EditPracticeOpeningHoursComponent,
    PracticeTagManagementComponent,
    PracticeTyroSettingsComponent,
    EditTyroTerminalDialogComponent,
    PairTyroTerminalDialogComponent,
    TyroLogsDialogComponent,
    PracticeIntegrationsComponent,
    PracticeIntegrationsComponent,
    TyroConfigurationComponent,
    PracticeBridgeSettingsComponent,
    EditBridgeDeviceDialogComponent,
    IntegrationKeySheetComponent,
    AddPublicKeyDialogComponent,
    PracticeHicapsConnectSettingsComponent,
    PracticeHicapsConnectTerminalsComponent,
    HicapsCustomRequestComponent,
    EditBridgeSettingsDialogComponent,
    ViewBridgeLogsDialogComponent,
    PracticeTyroTerminalsComponent,
    DownloadBridgeProgressDialogComponent,
    PracticeSmartpaySettingsComponent,
    EditSmartpayTerminalDialogComponent,
    PairSmartpayTerminalDialogComponent,
    SmartpayConfigurationComponent,
    PracticeSmartpayTerminalsComponent,
  ],
  providers: [PairTyroTerminalService, PracticeFeeScheduleResolver],
})
export class PracticesModule {}
