import { type DocumentReference } from '@principle-theorem/shared';
import { isDocRef, isObject, TypeGuard } from '@principle-theorem/shared';
import { type IStaffer } from '../staffer/staffer';

export interface IDiscountExtendedData {
  practitionerRef?: DocumentReference<IStaffer>;
}

export function isDiscountExtendedData(
  data: unknown
): data is IDiscountExtendedData {
  return isObject(data) && TypeGuard.nilOr(isDocRef)(data.practitionerRef);
}
