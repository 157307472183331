import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Interaction, PatientForm } from '@principle-theorem/principle-core';
import {
  IInteractionV2,
  IPatientForm,
  PatientFormStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  deleteDoc,
  filterUndefined,
  findProp,
  snapshot,
} from '@principle-theorem/shared';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { PatientFormStore } from '../patient-form/patient-form.store';

@Component({
    selector: 'pr-patient-form-page',
    templateUrl: './patient-form-page.component.html',
    styleUrl: './patient-form-page.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [PatientFormStore],
    standalone: false
})
export class PatientFormPageComponent implements OnDestroy {
  private _router = inject(Router);
  private _route = inject(ActivatedRoute);
  private _onDestroy$ = new Subject<void>();
  readonly store = inject(PatientFormStore);
  patientForm$: Observable<WithRef<IPatientForm> | undefined>;
  interactions$: Observable<WithRef<IInteractionV2>[]>;

  constructor() {
    this.patientForm$ = this._route.data.pipe(
      findProp<WithRef<IPatientForm>>('patientForm')
    );

    this.patientForm$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((patientForm) => {
        const isDisabled = this._isDisabled(patientForm);
        this.store.setReadonly(isDisabled);
        this.store.loadForm(patientForm);
      });

    this.interactions$ = this.patientForm$.pipe(
      filterUndefined(),
      switchMap((form) => PatientForm.interactions$(form))
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  formName(form: WithRef<IPatientForm>): string {
    return PatientForm.getName(form);
  }

  async addInteraction(interaction: IInteractionV2): Promise<void> {
    const patientForm = await snapshot(this.patientForm$);
    if (!patientForm) {
      return;
    }
    await PatientForm.addInteraction(patientForm, interaction);
  }

  async updateInteraction(interaction: WithRef<IInteractionV2>): Promise<void> {
    await Interaction.amend(interaction);
  }

  async deleteInteraction(interaction: WithRef<IInteractionV2>): Promise<void> {
    await deleteDoc(interaction.ref);
  }

  async redirectToForm(form: WithRef<IPatientForm>): Promise<void> {
    const url = await PatientForm.getPatientFormUrl(form);
    await this._router.navigate(url, { relativeTo: this._route });
  }

  private _isDisabled(form?: WithRef<IPatientForm>): boolean {
    if (!form) {
      return true;
    }
    if (!PatientForm.isPatientDetailsForm(form)) {
      return true;
    }
    return form.status !== PatientFormStatus.Submitted;
  }
}
