<mat-toolbar color="accent">
  <span>Perio Settings</span>
</mat-toolbar>

<mat-dialog-content>
  <form [formGroup]="perioSettingsForm" class="flex flex-col gap-4">
    <div class="flex flex-col gap-4">
      <h2 class="text-xl font-bold">Movement Settings</h2>

      <div class="flex items-center justify-between gap-4">
        <div>
          <span>Cell switching behaviour</span>
        </div>
        <mat-button-toggle-group
          #autoSwitchCellsGroup
          formControlName="autoSwitchCells"
          aria-label="Cell switching behaviour"
        >
          <mat-button-toggle
            matTooltip="Automatically move to next cell"
            [value]="true"
          >
            Auto
          </mat-button-toggle>
          <mat-button-toggle
            matTooltip="Manual - Press Tab to move cell"
            [value]="false"
          >
            Manual
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      @if (autoSwitchCellsEnabled()) {
        <div class="flex items-center justify-between gap-4">
          <div>
            <span>Movement pattern</span>
          </div>
          <mat-form-field subscriptSizing="dynamic" class="!w-40">
            <mat-label>Movement</mat-label>
            <mat-select formControlName="switchPattern">
              <mat-option class="text-xs" [value]="movement.Reset">
                By Row
              </mat-option>
              <mat-option class="text-xs" [value]="movement.Zigzag">
                Row Reverse
              </mat-option>
              <mat-option class="text-xs" [value]="movement.Quadrant">
                By Quadrant
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <pr-information-box>
          <div class="text-xs">
            <span class="font-bold">By Row:</span> navigate cells left to
            right.<br />
            <span class="font-bold">Row Reverse:</span> alternate row
            directions.<br />
            <span class="font-bold">By Quadrant:</span> navigate quadrant
            outward in.
          </div>
        </pr-information-box>

        <div class="flex items-center justify-between gap-4">
          <div>
            <span>Reverse movement pattern direction</span>
          </div>
          <mat-button-toggle-group
            formControlName="switchDirection"
            aria-label="Cell switching direction"
          >
            <mat-button-toggle
              matTooltip="Default pattern direction"
              [value]="direction.Default"
            >
              Default
            </mat-button-toggle>
            <mat-button-toggle
              matTooltip="Reverses the default pattern direction."
              [value]="direction.Reverse"
            >
              Reverse
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <pr-information-box>
          <div class="text-xs">
            e.g. By Row, navigate cells right to left instead of left to right.
          </div>
        </pr-information-box>
      }
    </div>

    <div>
      <h2 class="text-xl font-bold">Display Settings</h2>
      <div class="flex items-center justify-between gap-4">
        <div>
          <span>Table style</span>
        </div>
        <mat-button-toggle-group
          formControlName="tableStyle"
          aria-label="table style"
        >
          <mat-button-toggle matTooltip="Table style" [value]="style.Table">
            <mat-icon>border_all</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle
            matTooltip="Borderless style"
            [value]="style.Borderless"
          >
            <mat-icon>border_clear</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
