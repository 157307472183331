import { createAction, props } from '@ngrx/store';
import { TaxRate } from '@principle-theorem/accounting';
import {
  type IAppointment,
  type IInvoice,
} from '@principle-theorem/principle-core/interfaces';
import {
  type Reffable,
  type SerialisedData,
  type WithRef,
} from '@principle-theorem/shared';

export enum InvoiceActionTypes {
  LoadDraftInvoice = '[Draft Invoice] Load Draft Invoice',
  LoadDraftInvoiceSuccess = '[Draft Invoice] Load Draft Invoice Success',
  LoadInvoices = '[Invoices] Load Patient Invoices',
  LoadInvoicesSuccess = '[Invoices] Load Patient Invoices Success',
  ResetState = '[Invoices] Reset State',
}

export interface ILoadDraftInvoiceSuccessData {
  invoiceRef: string;
}

export interface ILoadInvoiceSuccessData {
  invoices: WithRef<IInvoice>[];
}

export const loadInvoices = createAction(InvoiceActionTypes.LoadInvoices);
export const reset = createAction(InvoiceActionTypes.ResetState);

export interface ILoadDraftInvoiceData {
  appointment: Reffable<IAppointment>;
  taxRate: TaxRate;
}

export const loadDraftInvoice = createAction(
  InvoiceActionTypes.LoadDraftInvoice,
  props<SerialisedData<ILoadDraftInvoiceData>>()
);

export const loadDraftInvoiceSuccess = createAction(
  InvoiceActionTypes.LoadDraftInvoiceSuccess,
  props<SerialisedData<ILoadDraftInvoiceSuccessData>>()
);

export const loadInvoiceSuccess = createAction(
  InvoiceActionTypes.LoadInvoicesSuccess,
  props<SerialisedData<ILoadInvoiceSuccessData>>()
);
