<div class="flex flex-col gap-2">
  <span>
    {{ label }}
    @if (tooltip) {
      <mat-icon [matTooltip]="tooltip">info</mat-icon>
    }
  </span>
  <mat-form-field fxFlex>
    <input
      matInput
      [placeholder]="placeholder"
      [disabled]="disabled$ | async"
      [formControl]="formCtrl"
    />
  </mat-form-field>
</div>
