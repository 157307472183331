import { initVersionedSchema, toTextContent } from '@principle-theorem/editor';
import { Interaction } from '@principle-theorem/principle-core';
import {
  FailedDestinationEntityRecord,
  IDestinationEntityJobRunOptions,
  IHasSourceIdentifier,
  IInteractionV2,
  ITranslationMap,
  InteractionType,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IPatient,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  snapshotCombineLatest,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import {
  BasePatientInteractionDestinationEntity,
  IBasePatientInteractionJobData,
  IPatientInteractionMigrationData,
} from '../../../destination/entities/patient-interactions';
import { type TranslationMapHandler } from '../../../translation-map';
import { PatientAlertSourceEntity } from '../../source/entities/patient-alerts';
import { PatientInteractionSourceEntity } from '../../source/entities/patient-interactions';
import {
  ICorePracticePatient,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { PROVIDER_RESOURCE_TYPE } from '../../source/entities/providers';
import { CorePracticeStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';

interface IPatientInteractionJobData
  extends IBasePatientInteractionJobData<ICorePracticePatient> {
  staff: WithRef<ITranslationMap<IStaffer>>[];
}

export class PatientInteractionDestinationEntity extends BasePatientInteractionDestinationEntity<
  ICorePracticePatient,
  IPatientInteractionJobData
> {
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    patientInteractions: new PatientInteractionSourceEntity(),
    patientAlerts: new PatientAlertSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  customMappings = {
    staff: new CorePracticeStafferMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientInteractionJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords(translationMapHandler),
      translationMapHandler.getByType<IStaffer>(PROVIDER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([staff$]),
    ]).pipe(
      map(([sourcePatients, [staff]]) =>
        sourcePatients.map((sourcePatient) => ({
          sourcePatient,
          staff,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientInteractionJobData
  ): Promise<
    | IPatientInteractionMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.id;
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        `Couldn't resolve patient`
      );
    }

    const sourceInteractions =
      await this.sourceEntities.patientInteractions.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

    const interactions: (IInteractionV2 & IHasSourceIdentifier)[] =
      sourceInteractions.map((interaction) => ({
        sourceIdentifier: interaction.record.uid,
        ...Interaction.init({
          type: InteractionType.Note,
          title: [toTextContent(`Added a note`)],
          createdAt: toTimestamp(moment.utc(interaction.data.data.createDate)),
          content: initVersionedSchema(interaction.data.data.note || undefined),
        }),
      }));

    const sourceAlerts = (
      await this.sourceEntities.patientAlerts.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      )
    ).filter((alert) => {
      if (alert.data.data.isDeleted) {
        return false;
      }
      if (!alert.data.data.endDateUtc) {
        return true;
      }

      const endDate = moment.utc(alert.data.data.endDateUtc);
      return endDate.isAfter(moment());
    });

    const pinnedInteractions: (IInteractionV2 & IHasSourceIdentifier)[] =
      sourceAlerts.map((interaction) => ({
        sourceIdentifier: interaction.record.uid,
        ...Interaction.init({
          type: InteractionType.Note,
          title: [toTextContent(`Added a note`)],
          pinned: true,
          createdAt: toTimestamp(
            moment.utc(interaction.data.data.startDateUtc)
          ),
          content: initVersionedSchema(interaction.data.data.description),
        }),
      }));

    return {
      patientRef,
      interactions: [...interactions, ...pinnedInteractions],
    };
  }
}
