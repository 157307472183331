import { randNumber } from '@ngneat/falso';
import {
  IPerioData,
  IPerioDataPoints,
  IPerioRecord,
  IToothRef,
} from '@principle-theorem/principle-core/interfaces';
import { MockAllTeeth } from './core/tooth.mock';

export function MockMouthPerioRecords(): IPerioRecord[] {
  return MockAllTeeth()
    .map((tooth) => tooth.toothRef)
    .map((toothRef) => MockPerioRecord(toothRef));
}

export function MockPerioRecord(toothRef: IToothRef): IPerioRecord {
  return { toothRef, data: MockPerioData() };
}

export function MockPerioData(): IPerioData {
  return {
    mobility: randNumber({ min: 0, max: 3 }),
    recession: MockPerioDataPoint(),
    MGJ: MockPerioDataPoint(),
    pocket: MockPerioDataPoint(),
    bleeding: MockPerioDataPoint(),
    furcation: MockPerioDataPoint(),
    suppuration: MockPerioDataPoint(),
  };
}

export function MockPerioDataPoint(): IPerioDataPoints {
  return {
    facialMesial: randNumber({ min: 1, max: 8 }),
    facialCentral: randNumber({ min: 1, max: 8 }),
    facialDistal: randNumber({ min: 1, max: 8 }),
    palatalMesial: randNumber({ min: 1, max: 8 }),
    palatalCentral: randNumber({ min: 1, max: 8 }),
    palatalDistal: randNumber({ min: 1, max: 8 }),
  };
}
