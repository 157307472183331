import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { compact } from 'lodash';
import { PatientPrimaryContactFormComponent } from '../patient-primary-contact-form/patient-primary-contact-form.component';
import {
  type PrimaryContactWithRelationships,
  transformRelationshipData,
} from '../patient-relationship-form/patient-relationship-form';
import { PatientRelationshipFormComponent } from '../patient-relationship-form/patient-relationship-form.component';

@Component({
    selector: 'pr-patient-relationship-dialog',
    templateUrl: './patient-relationship-dialog.component.html',
    styleUrls: ['./patient-relationship-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientRelationshipDialogComponent {
  @ViewChild(PatientPrimaryContactFormComponent)
  primaryContactFormComponent: PatientPrimaryContactFormComponent;

  @ViewChild(PatientRelationshipFormComponent)
  relationshipFormComponent: PatientRelationshipFormComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PrimaryContactWithRelationships,
    private _dialogRef: MatDialogRef<
      PatientRelationshipDialogComponent,
      PrimaryContactWithRelationships
    >
  ) {}

  submit(): void {
    const primaryContact = transformRelationshipData(
      this.primaryContactFormComponent.form.getRawValue().primaryContact ??
        undefined
    );

    const relationships = compact(
      this.relationshipFormComponent.form
        .getRawValue()
        .relationships.map(transformRelationshipData)
    );

    return this._dialogRef.close({ primaryContact, relationships });
  }
}
