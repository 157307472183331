import { ConditionConfiguration } from '@principle-theorem/principle-core';
import {
  CustomMappingType,
  type IConditionConfiguration,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import {
  XSLXImporterExporter,
  asyncForEach,
  hardDeleteDoc,
  multiMap,
  multiSortBy$,
  nameSorter,
  snapshot,
  toNamedDocument,
  type INamedDocument,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { BaseCustomMappingHandler } from '../base-custom-mapping-handler';
import { CustomMapping } from '../custom-mapping';
import { IBaseMigrationItemCode } from '../interfaces';
import { PracticeMigration } from '../practice-migrations';
import { BaseSourceEntity } from '../source/base-source-entity';
import { TranslationMapHandler } from '../translation-map';
import { ITEM_CODE_TO_CONDITION_DEFAULT_MAPPINGS } from './ada-code-mappings';
import { ItemCodeToConditionToXSLX } from './item-code-to-condition-to-xlsx';
import { XLSXToItemCodeToCondition } from './xlsx-to-item-code-to-condition';

export const ITEM_CODE_TO_CONDITION_CUSTOM_MAPPING_TYPE =
  'itemCodeToConditionMapping';

export const ITEM_CODE_TO_CONDITION_MAPPING = CustomMapping.init({
  metadata: {
    label: 'Item Code To Condition',
    description: `Used for mapping pre-existing treatment item codes to condition configurations in Principle.`,
    type: ITEM_CODE_TO_CONDITION_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.DocumentReference,
});

export abstract class BaseItemCodeToConditionMappingHandler<
  ItemCode extends object,
  SourceEntity extends BaseSourceEntity<ItemCode>,
> extends BaseCustomMappingHandler<IConditionConfiguration> {
  customMapping = ITEM_CODE_TO_CONDITION_MAPPING;
  abstract translateFn: (record: ItemCode) => IBaseMigrationItemCode;
  abstract sourceEntity: SourceEntity;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 1000)
      .toPromise();

    const options = records
      .map((record) => record.data.data)
      .map((record) => {
        const translated = this.translateFn(record);
        return {
          label: `${translated.itemCode} - ${translated.description}`,
          value: this.sourceEntity.getSourceRecordId(record).toString(),
        };
      });

    return sortBy(options, 'label');
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<IConditionConfiguration>[]> {
    return snapshot(
      ConditionConfiguration.all$(migration.configuration.brand).pipe(
        multiMap(toNamedDocument),
        multiSortBy$(nameSorter())
      )
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const fileName = `item-code-to-condition-mapping`;
    const records = await this.sourceEntity
      .getRecords$(migration, 1000)
      .toPromise();

    const options = records
      .map((record) => record.data.data)
      .map((record) => this.translateFn(record));

    const itemCodes = sortBy(options, 'itemCode');

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    await new XSLXImporterExporter().download(
      fileName,
      itemCodes,
      new ItemCodeToConditionToXSLX(
        await this.getDestinationOptions(migration),
        await snapshot(this.getRecords$(translationMap)),
        ITEM_CODE_TO_CONDITION_DEFAULT_MAPPINGS
      )
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XLSXToItemCodeToCondition()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const records = await snapshot(this.getRecords$(translationMap));
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));

    const sourceOptions = await this.getSourceOptions(migration);
    const conditionConfigurations = await this.getDestinationOptions(migration);

    await asyncForEach(items, async (item) => {
      const mapTo = item.mapTo;
      const label = `${item.code} - ${item.description}`;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.label === label
      )?.value;
      if (!value || !item.mapTo) {
        return;
      }

      const conditionConfiguration = conditionConfigurations.find(
        (configuration) => configuration.name === mapTo
      );

      if (!conditionConfiguration) {
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: conditionConfiguration.ref,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }
}
