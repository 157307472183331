import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
  type OnDestroy,
} from '@angular/core';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  NG_TYRO_CONFIG_PROVIDER,
  type ITyroConfigProvider,
} from '@principle-theorem/ng-tyro';
import {
  type IPractice,
  type IPracticeTyroSettings,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  getEnumValues,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { TyroIClientUrl } from '@principle-theorem/tyro';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-tyro-configuration',
    templateUrl: './tyro-configuration.component.html',
    styleUrls: ['./tyro-configuration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TyroConfigurationComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _config: ITyroConfigProvider = inject(NG_TYRO_CONFIG_PROVIDER);
  trackByIClient = TrackByFunctions.variable<TyroIClientUrl>();
  clients = getEnumValues(TyroIClientUrl);
  form: TypedFormGroup<IPracticeTyroSettings>;
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);

  @Input()
  set practice(practice: WithRef<IPractice>) {
    if (practice) {
      this.practice$.next(practice);
    }
  }

  constructor() {
    this.form = new TypedFormGroup<IPracticeTyroSettings>({
      isEnabled: new TypedFormControl<boolean>(false),
      iClient: new TypedFormControl<TyroIClientUrl>(
        this._config.defaultIClient
      ),
      addCDBSAsPending: new TypedFormControl<boolean>(false),
    });
    this.practice$.pipe(takeUntil(this._onDestroy$)).subscribe((practice) => {
      this.form.patchValue(practice.tyroSettings ?? {});
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    const practice = await snapshot(this.practice$);
    const tyroSettings = this.form.getRawValue();
    await Firestore.patchDoc(practice.ref, { tyroSettings });
  }
}
