import { Timestamp } from '@principle-theorem/shared';
import { isEqual, isString, min } from 'lodash';
import 'reflect-metadata';
import {
  type IMigration,
  type IMigrationDataProvider,
  type IMigrationEntry,
  type IMigrationInstance,
  type IMigrationLogger,
  type IMigrationRunner,
  type IMigrationSnapshot,
  type IMigrationStorage,
  MigrationAction,
} from './interfaces';

export class NoopLogger implements IMigrationLogger {
  debug(_message: string): unknown {
    return;
  }

  info(_message: string): unknown {
    return;
  }

  warn(_message: string): unknown {
    return;
  }

  error(_message: string): unknown {
    return;
  }
}

export class MigrationRunner implements IMigrationRunner {
  dryRun: boolean = false;

  constructor(
    private _migrations: IMigration[],
    private _storage: IMigrationStorage,
    private _logger: IMigrationLogger,
    private _data?: IMigrationDataProvider
  ) {}

  async up(): Promise<void> {
    const migrations: IMigrationInstance[] =
      await this._getUnappliedMigrations();

    for (let index = 0; index < migrations.length; index++) {
      this._logger.info(`-------- ${index + 1}/${migrations.length} --------`);
      const migration: IMigrationInstance = migrations[index];
      const label = `${migration.metadata.name} (${migration.metadata.uid})`;
      this._logger.info(`Running ${label}`);
      try {
        await migration.migration.up(this.dryRun, this._logger, this._data);
        this._logger.info(`Finished ${label}`);
      } catch (error) {
        this._logger.info(`Error in ${label}`);
        throw error;
      }
      if (!this.dryRun) {
        await this._addStorageEntry(migration, MigrationAction.Up);
      }
    }
  }

  async down(count: number = 1): Promise<void> {
    const migrations: IMigrationInstance[] = (
      await this._getAppliedMigrations()
    ).reverse();
    const length: number = min([migrations.length, count]) || 0;

    for (let index = 0; index < length; index++) {
      this._logger.info(`-------- ${index + 1}/${length} --------`);
      const migration: IMigrationInstance = migrations[index];
      const label = `${migration.metadata.name} (${migration.metadata.uid})`;
      this._logger.info(`Reverting ${label}`);
      try {
        await migration.migration.down(this.dryRun, this._logger, this._data);
        this._logger.info(`Finished ${label}`);
      } catch (error) {
        this._logger.info(`Error in ${label}`);
        throw error;
      }
      if (!this.dryRun) {
        await this._addStorageEntry(migration, MigrationAction.Down);
      }
    }
  }

  async hasUnappliedMigrations(): Promise<boolean> {
    return (await this._getUnappliedMigrations()).length > 0;
  }

  private async _addStorageEntry(
    migration: IMigrationInstance,
    action: MigrationAction
  ): Promise<void> {
    await this._storage.add({
      migration: {
        uid: migration.metadata.uid,
        name: migration.metadata.name,
      },
      action,
      performedAt: Timestamp.now(),
    });
  }

  private _getRegisteredMigrations(): IMigrationInstance[] {
    return this._migrations.map((migration: IMigration, index: number) => {
      this._validateMigration(migration);
      const uid: unknown = Reflect.getMetadata('migration:uid', migration);
      const name: unknown = Reflect.getMetadata('migration:name', migration);

      if (!isString(uid) || !isString(name)) {
        throw new Error(
          `Registered migration with invalid uid or name at index ${index}`
        );
      }

      return {
        migration,
        metadata: {
          uid,
          name,
        },
      };
    });
  }

  private async _getUnappliedMigrations(): Promise<IMigrationInstance[]> {
    const pastMigrations: IMigrationInstance[] =
      await this._getAppliedMigrations();
    const registered = this._getRegisteredMigrations();
    return registered.filter(
      (migration: IMigrationInstance) =>
        !pastMigrations.some((pastMigration: IMigrationInstance) =>
          isEqual(migration.metadata, pastMigration.metadata)
        )
    );
  }

  private async _getAppliedMigrations(): Promise<IMigrationInstance[]> {
    const snapshot: IMigrationSnapshot = await this._storage.snapshot();
    const runMigrations: IMigrationEntry[] = snapshot.migrations.filter(
      (migration: IMigrationEntry) => migration.action === MigrationAction.Up
    );

    return this._getRegisteredMigrations().filter(
      (migration: IMigrationInstance) =>
        runMigrations.some((runMigration: IMigrationEntry) =>
          isEqual(migration.metadata, runMigration.migration)
        )
    );
  }

  private _validateMigration(migration: IMigration): void {
    if (
      !Reflect.hasMetadata('migration:name', migration) ||
      !Reflect.hasMetadata('migration:uid', migration)
    ) {
      throw new Error(
        `The given migration is missing the @Migration decorator`
      );
    }
  }
}
