import {
  addDoc,
  firstResult,
  firstResult$,
  isEnumValue,
  isObject,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import {
  type CollectionReference,
  deleteDoc,
  where,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum IntegrationType {
  Slack = 'slack',
  Medipass = 'medipass',
  Stripe = 'stripe',
  Twilio = 'twilio',
  Sendgrid = 'sendgrid',
  Typesense = 'typesense',
  Podium = 'podium',
  HicapsConnect = 'hicapsConnect',
  TNZ = 'tnz',
}

export interface IIntegration<T extends object = object> {
  type: IntegrationType;
  data: T;
}

export function isIntegration<T extends object>(
  data: unknown
): data is IIntegration<T> {
  return (
    isObject(data) &&
    isEnumValue(IntegrationType, data.type) &&
    isObject(data.data)
  );
}

export interface IIntegrationStorage<T extends object> {
  add(
    integrationsCollection: CollectionReference<IIntegration<T>>,
    data: T
  ): Promise<void>;
  remove(
    integrationsCollection: CollectionReference<IIntegration<T>>
  ): Promise<void>;
  get(
    integrationsCollection: CollectionReference<IIntegration<T>>
  ): Promise<IIntegration<T> | undefined>;
  has$(
    integrationsCollection: CollectionReference<IIntegration<T>>
  ): Observable<boolean>;
}

export class IntegrationStorage<T extends object>
  implements IIntegrationStorage<T>
{
  constructor(private _type: IntegrationType) {}

  col(
    integrationCollection: CollectionReference<IIntegration>
  ): CollectionReference<IIntegration<T>> {
    return integrationCollection as CollectionReference<IIntegration<T>>;
  }

  async add(
    integrationsCollection: CollectionReference<IIntegration<T>>,
    data: T
  ): Promise<void> {
    await addDoc(integrationsCollection, {
      type: this._type,
      data,
    });
  }

  async get(
    integrationsCollection: CollectionReference<IIntegration<T>>
  ): Promise<WithRef<IIntegration<T>> | undefined> {
    return snapshot(this.get$(integrationsCollection));
  }

  has$(
    integrationsCollection: CollectionReference<IIntegration<T>>
  ): Observable<boolean> {
    return firstResult$(
      integrationsCollection,
      where('type', '==', this._type)
    ).pipe(map((result) => !!result));
  }

  async remove(
    integrationsCollection: CollectionReference<IIntegration<T>>
  ): Promise<void> {
    const integration = await firstResult(
      integrationsCollection,
      where('type', '==', this._type)
    );
    if (!integration) {
      return;
    }
    await deleteDoc(integration.ref);
  }

  get$(
    integrationsCollection: CollectionReference<IIntegration<T>>
  ): Observable<WithRef<IIntegration<T>> | undefined> {
    return firstResult$<IIntegration<T>>(
      integrationsCollection,
      where('type', '==', this._type)
    );
  }

  async update(
    integrationsCollection: CollectionReference<IIntegration<T>>,
    data: T
  ): Promise<void> {
    const existing = await this.get(integrationsCollection);
    if (existing) {
      await patchDoc(existing.ref, { data });
      return;
    }
  }

  async upsert(
    integrationsCollection: CollectionReference<IIntegration<T>>,
    data: T
  ): Promise<void> {
    const existing = await this.get(integrationsCollection);
    if (existing) {
      await patchDoc(existing.ref, { data });
      return;
    }
    await this.add(integrationsCollection, data);
  }
}
