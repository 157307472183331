import {
  type IFormSchema,
  FormSchemaPropertyType,
  type IFormLayoutElement,
  FormLayoutElementType,
} from '@principle-theorem/principle-core/interfaces';
import { formFlexRow, prefixLayoutItems } from '../custom-form-helpers';

export const DENTAL_CONDITIONS_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    toothache: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Toothache / pain',
    },
    discolouring: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Dark or discoloured teeth',
    },
    crowding: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Crooked / crowded teeth',
    },
    decay: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Decay / cavities',
    },
    brokenOrFilling: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Broken teeth or filling',
    },
    worn: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Worn down teeth',
    },
    sensitivity: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Cold sensitivity',
    },
    bleeding: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Bleeding gums',
    },
    halitosis: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Bad breath',
    },
    grinding: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Clenching or grinding',
    },
    musclePain: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Jaw / muscle pain',
    },
    ulcers: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Mouth Ulcers',
    },
  },
};

const DENTAL_CONDITIONS_FORM_LAYOUT: IFormLayoutElement[] = [
  formFlexRow([{ key: 'toothache' }, { key: 'discolouring' }]),
  formFlexRow([{ key: 'crowding' }, { key: 'decay' }]),
  formFlexRow([{ key: 'brokenOrFilling' }, { key: 'worn' }]),
  formFlexRow([{ key: 'sensitivity' }, { key: 'bleeding' }]),
  formFlexRow([{ key: 'halitosis' }, { key: 'grinding' }]),
  formFlexRow([{ key: 'musclePain' }, { key: 'ulcers' }]),
];

export const EXPRESSION_OF_INTEREST_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    whitening: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Teeth Whitening',
    },
    implants: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Dental Implants for missing teeth',
    },
    straightening: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Invisalign or teeth straightening',
    },
    veneers: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Veneers or cosmetic smile improvement',
    },
    wisdomRemoval: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Wisdom teeth removal',
    },
    sleepApnoea: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Anti-snoring and sleep apnoea treatment',
    },
    wrinkleRelaxers: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Wrinkle relaxers (eg, botox, dysport)',
    },
    fillers: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Cosmetic dermal fillers (eg, lips, cheeks)',
    },
    dentalAnxietyManagement: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Dental anxiety management options (eg, sedation, happy gas, etc)',
    },
  },
};

const EXPRESSION_OF_INTEREST_FORM_LAYOUT: IFormLayoutElement[] = [
  formFlexRow([{ key: 'whitening' }, { key: 'implants' }]),
  formFlexRow([{ key: 'straightening' }, { key: 'veneers' }]),
  formFlexRow([{ key: 'wisdomRemoval' }, { key: 'sleepApnoea' }]),
  formFlexRow([{ key: 'wrinkleRelaxers' }, { key: 'fillers' }]),
  { key: 'dentalAnxietyManagement' },
];

export const DENTAL_HISTORY_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    reason: {
      type: FormSchemaPropertyType.String,
      title: 'Reason for attending or any specific concerns',
    },
    dentalAnxiety: {
      type: FormSchemaPropertyType.Boolean,
      title:
        'Do you generally feel anxious about visiting the dentist or hygienist?',
    },
    antibioticsBeforeTreatment: {
      type: FormSchemaPropertyType.Boolean,
      title: 'Do you need to take antibiotics before any dental treatment?',
    },
    lastVisit: {
      type: FormSchemaPropertyType.String,
      title: 'Last Dental Visit',
    },
    conditions: DENTAL_CONDITIONS_FORM_SCHEMA,
    interestedIn: EXPRESSION_OF_INTEREST_FORM_SCHEMA,
    more: {
      type: FormSchemaPropertyType.String,
      title: `Is there anything else about previous dental experiences you'd like us to know?`,
    },
  },
};

export const DENTAL_HISTORY_FORM_LAYOUT: IFormLayoutElement[] = [
  { key: 'reason', type: FormLayoutElementType.TextArea },
  formFlexRow([{ key: 'lastVisit', type: FormLayoutElementType.Date }]),
  formFlexRow([
    { key: 'antibioticsBeforeTreatment' },
    { key: 'dentalAnxiety' },
  ]),
  {
    key: 'conditions',
    title: 'Please tick if any of the following apply to you',
    items: prefixLayoutItems(DENTAL_CONDITIONS_FORM_LAYOUT, 'conditions'),
  },
  {
    key: 'interestedIn',
    title: 'Would you like more information on the following services?',
    items: prefixLayoutItems(
      EXPRESSION_OF_INTEREST_FORM_LAYOUT,
      'interestedIn'
    ),
  },
  { key: 'more', type: FormLayoutElementType.TextArea },
];
