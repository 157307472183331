import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NgTemplatingModule } from '@principle-theorem/ng-templating';
import { TemplateEditComponent } from './template-edit/template-edit.component';
import { TemplateListComponent } from './template-list/template-list.component';
import { TemplateResolver } from './template-resolver.service';

@NgModule({
  declarations: [TemplateListComponent, TemplateEditComponent],
  imports: [
    CommonModule,
    NgMaterialModule,
    NgTemplatingModule,
    RouterModule,
    NgFeatureFlagsModule,
    NgSharedModule,
    NgPrincipleSharedModule,
  ],
  providers: [TemplateResolver],
})
export class TemplatingModule {}
