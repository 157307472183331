import { type IDestination } from '@principle-theorem/principle-core/interfaces';
import { BaseDestinationHandler } from '../destination/base-destination-handler';
import { FeeScheduleDestinationEntity } from './destination/entities/fee-schedules';
import { PatientAppointmentDestinationEntity } from './destination/entities/patient-appointments';
import { PatientClinicalChartDestinationEntity } from './destination/entities/patient-clinical-charts';
import { PatientClinicalNoteDestinationEntity } from './destination/entities/patient-clinical-notes';
import { PatientDepositDestinationEntity } from './destination/entities/patient-deposits';
import { PatientFileDestinationEntity } from './destination/entities/patient-files';
import { PatientInteractionDestinationEntity } from './destination/entities/patient-interactions';
import { PatientInvoiceDestinationEntity } from './destination/entities/patient-invoices';
import { PatientMediaSuiteFileDestinationEntity } from './destination/entities/patient-media-suite-files';
import { PatientRelationshipDestinationEntity } from './destination/entities/patient-relationships';
import { PatientTreatmentPlanDestinationEntity } from './destination/entities/patient-treatment-plans';
import { PatientDestinationEntity } from './destination/entities/patients';
import { StafferDestinationEntity } from './destination/entities/staff';
import { SterilisationRecordDestinationEntity } from './destination/entities/sterilisation-records';
import { PatientProfilePhotoDestinationEntity } from './destination/entities/patient-profile-photos';
import { ExistingPatientDestinationEntity } from './destination/entities/existing-patients';

export const D4W_DESTINATION: IDestination = {
  metadata: {
    name: 'Principle Firestore',
    description: 'Main Principle Migration',
  },
  configuration: [],
};

export class D4WDestinationHandler extends BaseDestinationHandler {
  migrationType = 'D4W';
  migrationVersion = 'v1';
  destination = D4W_DESTINATION;

  entityHandlers = [
    new PatientDestinationEntity(),
    new FeeScheduleDestinationEntity(),
    new StafferDestinationEntity(),
    new PatientInteractionDestinationEntity(),
    new PatientClinicalChartDestinationEntity(),
    new PatientClinicalNoteDestinationEntity(),
    new PatientTreatmentPlanDestinationEntity(),
    new PatientAppointmentDestinationEntity(),
    new SterilisationRecordDestinationEntity(),
    new PatientInvoiceDestinationEntity(),
    new PatientRelationshipDestinationEntity(),
    new PatientFileDestinationEntity(),
    new PatientMediaSuiteFileDestinationEntity(),
    new PatientDepositDestinationEntity(),
    new PatientProfilePhotoDestinationEntity(),
    new ExistingPatientDestinationEntity(),
  ];
}
