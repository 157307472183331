import { rand, randNumber, randProductName, randSentence } from '@ngneat/falso';
import {
  TaxRate,
  TaxStrategy,
  calculateTaxFromTotal,
} from '@principle-theorem/accounting';
import {
  ICustomLineItem,
  IHealthcareClaim,
  IInvoice,
  IPractice,
  InvoiceLineItemType,
  InvoiceStatus,
  InvoiceType,
  initInvoiceSummary,
} from '@principle-theorem/principle-core/interfaces';
import {
  ArchivedDocument,
  BaseFirestoreMock,
  MockGenerator,
  MockTimestamp,
  Properties,
  WithRef,
  getEnumValues,
  toTimestamp,
} from '@principle-theorem/shared';
import {
  MockDocRef,
  MockNamedDocument,
  MockWithRef,
} from '@principle-theorem/testing';
import * as moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';
import { MockAccount } from './account.mock';
import { HealthcareClaimMock } from './health-claim.mock';
import {
  TreatmentLineItemMock,
  DepositLineItemMock,
  RefundLineItemMock,
  FeeLineItemMock,
  ProductLineItemMock,
} from './line-item.mock';

export class InvoiceMock
  extends BaseFirestoreMock
  implements Properties<IInvoice>
{
  claims: IHealthcareClaim[] = [MockGenerator.generate(HealthcareClaimMock)];
  uid = uuid();
  reference = uuid();
  status = InvoiceStatus.Cancelled;
  type = InvoiceType.Invoice;
  practice = MockNamedDocument<IPractice>();
  due = MockTimestamp();
  issuedAt = MockTimestamp();
  paidAt = MockTimestamp();
  cancelledAt = MockTimestamp();
  from = MockAccount.mockAccountDetails();
  to = MockAccount.mockAccountDetails();
  items = [
    new TreatmentLineItemMock(),
    new DepositLineItemMock(),
    new RefundLineItemMock(),
    new FeeLineItemMock(),
    new ProductLineItemMock(),
  ];
  statusHistory = [
    {
      status: InvoiceStatus.Draft,
      updatedAt: MockTimestamp(),
    },
    {
      status: InvoiceStatus.Issued,
      updatedAt: MockTimestamp(),
    },
    {
      status: InvoiceStatus.Paid,
      updatedAt: MockTimestamp(),
    },
  ];
  total = randNumber({
    min: 100,
    max: 2000,
  });
  cancellation = {
    reason: randSentence(),
    replacement: MockDocRef<IInvoice>(),
  };
  amendmentOf = MockDocRef<ArchivedDocument<IInvoice>>();
  summary = initInvoiceSummary();
  transactionAllocations = [];
}

export function MockInvoiceForAmount(amount: number): WithRef<IInvoice> {
  return MockWithRef({
    ...MockGenerator.generate(InvoiceMock),
    items: [
      MockGenerator.generate(ProductLineItemMock, { amount, quantity: 1 }),
    ],
  });
}

export class MockInvoice {
  static mockInvoiceLineItem(): ICustomLineItem {
    const amount = randNumber({ max: 9999 });
    return {
      uid: uuid(),
      description: randProductName(),
      amount,
      type: rand(getEnumValues(InvoiceLineItemType)),
      tax: calculateTaxFromTotal(amount, TaxRate.GST),
      taxStatus: rand(getEnumValues(TaxStrategy)),
      quantity: randNumber({ max: 3 }),
    };
  }

  static mockInvoice(): IInvoice {
    return {
      reference: `INV${uuid()}`,
      statusHistory: [
        {
          status: InvoiceStatus.Issued,
          updatedAt: toTimestamp(),
        },
      ],
      from: MockAccount.mockAccountDetails(),
      to: MockAccount.mockAccountDetails(),
      type: InvoiceType.Invoice,
      status: InvoiceStatus.Issued,
      due: toTimestamp(moment().add(2, 'weeks')),
      items: this.mockMany().map(this.mockInvoiceLineItem),
      createdAt: toTimestamp(),
      updatedAt: toTimestamp(),
      deleted: false,
      paidAt: toTimestamp(),
      practice: MockNamedDocument<IPractice>('Practice'),
      summary: initInvoiceSummary(),
    };
  }

  static mockMany(count?: number): string[] {
    if (!count) {
      count = randNumber({ min: 3, max: 25 });
    }
    return new Array<string>(count).fill('');
  }
}
