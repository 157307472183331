import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SterilisationListComponent } from './sterilisation-list/sterilisation-list.component';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { SterilisationRecordDialogComponent } from './sterilisation-record-dialog/sterilisation-record-dialog.component';
import { EditSterilisationRecordDialogComponent } from './edit-sterilisation-record-dialog/edit-sterilisation-record-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    NgMaterialModule,
    NgSharedModule,
    NgPrincipleSharedModule,
  ],
  declarations: [
    SterilisationListComponent,
    SterilisationRecordDialogComponent,
    EditSterilisationRecordDialogComponent,
  ],
  exports: [SterilisationListComponent, SterilisationRecordDialogComponent],
})
export class ComponentsModule {}
