<mat-toolbar
  class="flex items-center"
  color="accent"
>
  <span>SMS {{ data.patient.name }}</span>
</mat-toolbar>

<mat-dialog-content>
  <form class="flex flex-col gap-2" [formGroup]="smsForm">
    <div class="flex items-center gap-2">
      <mat-icon>phone_iphone</mat-icon>
      <h3 class="!mb-0">{{ mobileNumber$ | async }}</h3>
    </div>

    <div [hidden]="hasSinglePractice$ | async">
      <pr-practice-selector
        class="flex-1"
        label="Send From"
        formControlName="practice"
        [brands]="brands$ | async"
      />
    </div>

    <mat-form-field
      class="w-full"
      subscriptSizing="dynamic"
    >
      <mat-label>SMS Message</mat-label>
      <pr-content-editor
        placeholder="SMS Message"
        [formControl]="smsForm.controls.content"
        [menuEnabled]="false"
        [required]="true"
        [extensions]="extensions"
      />
      <pt-character-counter
        class="absolute bottom-2 right-0 text-sm opacity-50"
        label="SMS Segments"
        [divisor]="segmentSize"
        [formCtrl]="smsForm.controls.content"
      />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button (click)="dialogRef.close()">Close</button>
    <button
      mat-stroked-button
      [disabled]="(canRevert$ | async) === false"
      (click)="revertToSystemTemplate()"
    >
      Revert To System Template
    </button>
    <pt-loader-button
      color="primary"
      type="flat"
      [loading]="submitting$ | async"
      [disabled]="isDisabled$ | async"
      (click)="send()"
    >
      Send
    </pt-loader-button>
  </pt-buttons-container>
</mat-dialog-actions>
