import {
  initRawSchema,
  RawSchema,
  toMentionContent,
  toParagraphContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  INotification,
  INotificationContextRenderer,
} from '@principle-theorem/notifications';
import {
  NotificationType,
  IRescheduledAppointmentResources,
  isAppointmentResource,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  CALENDAR_TIME_FORMAT,
  IReffable,
  toMoment,
} from '@principle-theorem/shared';
import { Event } from '../../event/event';
import { toMention } from '../../mention/mention';
import { stafferIsOfRole } from './notification-helpers';

export class StafferRescheduledAppointmentContextRenderer
  implements
    INotificationContextRenderer<
      RawSchema,
      NotificationType,
      IRescheduledAppointmentResources
    >
{
  canRender(notification: INotification): boolean {
    return (
      notification.type ===
      String(NotificationType.StafferRescheduledAppointment)
    );
  }

  isResource(data: unknown): data is IRescheduledAppointmentResources {
    return isAppointmentResource<IRescheduledAppointmentResources>(data);
  }

  render(
    data: IRescheduledAppointmentResources,
    currentStaffer?: IReffable
  ): RawSchema {
    const isActor: boolean = stafferIsOfRole(currentStaffer, data.interactor);
    const originalEvent = data.appointment.event.before
      ? Event.init(data.appointment.event.before)
      : undefined;
    const rescheduledEvent = Event.init(data.appointment.event.after);
    const rescheduledStartTime: string = toMoment(
      rescheduledEvent.from
    ).calendar(undefined, CALENDAR_TIME_FORMAT);

    const patientMention = toMention(data.patient, MentionResourceType.Patient);
    const appointmentToMention = toMention(
      { name: rescheduledStartTime, ref: data.appointment.ref },
      MentionResourceType.Appointment
    );

    if (!originalEvent) {
      if (isActor) {
        return initRawSchema([
          toParagraphContent([
            toTextContent(`You scheduled `),
            toMentionContent(patientMention),
            toTextContent(' to '),
            toMentionContent(appointmentToMention),
          ]),
        ]);
      }

      if (data.interactor) {
        const interactorMention = toMention(
          data.interactor,
          MentionResourceType.Staffer
        );
        return initRawSchema([
          toParagraphContent([
            toMentionContent(interactorMention),
            toTextContent(` scheduled `),
            toMentionContent(patientMention),
            toTextContent(' to '),
            toMentionContent(appointmentToMention),
          ]),
        ]);
      }

      return initRawSchema([
        toParagraphContent([
          toTextContent(`System scheduled `),
          toMentionContent(patientMention),
          toTextContent(' to '),
          toMentionContent(appointmentToMention),
        ]),
      ]);
    }

    const originalStartTime: string = toMoment(originalEvent.from).calendar(
      undefined,
      CALENDAR_TIME_FORMAT
    );
    const appointmentFromMention = toMention(
      { name: originalStartTime, ref: data.appointment.ref },
      MentionResourceType.Appointment
    );

    if (isActor) {
      return initRawSchema([
        toParagraphContent([
          toTextContent(`You rescheduled `),
          toMentionContent(patientMention),
          toTextContent(' from '),
          toMentionContent(appointmentFromMention),
          toTextContent(' to '),
          toMentionContent(appointmentToMention),
        ]),
      ]);
    }

    if (data.interactor) {
      const interactorMention = toMention(
        data.interactor,
        MentionResourceType.Staffer
      );
      return initRawSchema([
        toParagraphContent([
          toMentionContent(interactorMention),
          toTextContent(` rescheduled `),
          toMentionContent(patientMention),
          toTextContent(' from '),
          toMentionContent(appointmentFromMention),
          toTextContent(' to '),
          toMentionContent(appointmentToMention),
        ]),
      ]);
    }

    return initRawSchema([
      toParagraphContent([
        toTextContent(`System rescheduled `),
        toMentionContent(patientMention),
        toTextContent(' from '),
        toMentionContent(appointmentFromMention),
        toTextContent(' to '),
        toMentionContent(appointmentToMention),
      ]),
    ]);
  }
}
