import { ITranslationMap } from '@principle-theorem/principle-core/interfaces';
import { AtLeast, IXSLXExport, XSLXCell } from '@principle-theorem/shared';
import { Column } from 'exceljs';
import { first } from 'lodash';

export interface ISingleValueToXSLX {
  label: string;
  mapTo: string;
}

export const SINGLE_VALUE_HEADERS: AtLeast<Column, 'key' | 'header'>[] = [
  {
    key: 'mapTo',
    header: 'Map To',
  },
];

export class SingleValuesToXSLX
  implements IXSLXExport<string, ISingleValueToXSLX>
{
  headers = SINGLE_VALUE_HEADERS;

  constructor(private _existingMappings: ITranslationMap<object, string>[]) {}

  translate(): Record<keyof ISingleValueToXSLX, XSLXCell>[] {
    const existingRecord = first(this._existingMappings);
    const mappedValue = existingRecord?.destinationValue;

    return [
      {
        label: { value: '' },
        mapTo: {
          value: mappedValue || '',
        },
      },
    ];
  }
}
