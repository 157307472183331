import { IPricedServiceCodeEntry } from '@principle-theorem/principle-core/interfaces';
import { min, max, sum } from 'lodash';

export abstract class ADACodePriceAccumulator {
  abstract getMin(codes: IPricedServiceCodeEntry[]): number;
  abstract getMax(codes: IPricedServiceCodeEntry[]): number;

  protected _getPrices(codes: IPricedServiceCodeEntry[]): number[] {
    return codes.map(
      (code: IPricedServiceCodeEntry) => code.priceOverride ?? code.price
    );
  }

  protected _onlyStagedForBilling(
    codes: IPricedServiceCodeEntry[]
  ): IPricedServiceCodeEntry[] {
    return codes.filter((code: IPricedServiceCodeEntry) => code.quantity > 0);
  }
}

export class RequiredADACodePriceAccumulator extends ADACodePriceAccumulator {
  getMin(codes: IPricedServiceCodeEntry[]): number {
    return sum(this._getPrices(codes));
  }
  getMax(codes: IPricedServiceCodeEntry[]): number {
    return this.getMin(codes);
  }
}

export class ExclusiveADACodePriceAccumulator extends ADACodePriceAccumulator {
  getMin(codes: IPricedServiceCodeEntry[]): number {
    return min(this._getPrices(codes)) || 0;
  }
  getMax(codes: IPricedServiceCodeEntry[]): number {
    return max(this._getPrices(codes)) || 0;
  }
}

export class OptionalADACodePriceAccumulator extends ADACodePriceAccumulator {
  getMin(_codes: IPricedServiceCodeEntry[]): number {
    return 0;
  }
  getMax(codes: IPricedServiceCodeEntry[]): number {
    return sum(this._getPrices(this._onlyStagedForBilling(codes)));
  }
}
