<div class="p-2">
  <ng-template #loading>
    <mat-progress-bar mode="indeterminate" />
  </ng-template>

  <ng-container *ngIf="appointment$ | async as appointment; else loading">
    <ng-container *ngIf="chart$ | async as chart">
      <mat-tab-group
        mat-stretch-tabs="false"
        mat-align-tabs="start"
        [selectedIndex]="selectedChartTypeIndex$ | async"
        (selectedIndexChange)="setChartType($event)"
      >
        <mat-tab [label]="chartType.Clinical | titlecase">
          <pr-chart-ui class="px-2 py-4" />
        </mat-tab>
        <mat-tab [label]="chartType.Periodontal | titlecase">
          <div class="px-2 py-4">
            <pr-chart-view-toolbar>
              <pr-perio-chart-settings [immutable]="chart.immutable" />
            </pr-chart-view-toolbar>
            <pr-perio-chart-ui />
          </div>
        </mat-tab>
      </mat-tab-group>
    </ng-container>

    <div
      fxFill
      class="no-chart"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <span [ngSwitch]="missingChartReason$ | async">
        <div *ngSwitchCase="'subsequentAppointmentExists'">
          <h2 class="mat-headline-5">
            A chart has been created on a later appointment. No chart can be
            generated for this appointment.
          </h2>
          <a [routerLink]="subsequentAppointmentLink$ | async">
            Please chart on the latest version.
          </a>
        </div>

        <div *ngSwitchCase="'appointmentNotScheduled'">
          <h2 class="mat-headline-5">
            Charting isn't available until the appointment has been scheduled.
          </h2>
        </div>

        <div *ngSwitchCase="'appointmentInFuture'">
          <h2 class="mat-headline-5">
            The chart hasn't been generated due to the appointment date being in
            the future. Would you still like to generate the chart? This will
            lock any charts prior to this appointment.
          </h2>
          <button
            mat-flat-button
            color="primary"
            (click)="generateChart()"
            [disabled]="generatingChart$ | async"
          >
            Generate
          </button>
        </div>

        <div *ngSwitchCase="'appointmentInPast'">
          <h2 class="mat-headline-5">
            The appointment wasn't started on the scheduled day and is now in
            the past. Would you still like to generate the chart?
          </h2>
          <button
            mat-flat-button
            color="primary"
            (click)="generateChart()"
            [disabled]="generatingChart$ | async"
          >
            Generate
          </button>
        </div>

        <div *ngSwitchCase="'noChartGenerated'">
          <h2 class="mat-headline-5">
            This appointment doesn't have a chart associated with it. Would you
            like to generate the chart? This will lock any previous charts.
          </h2>
          <div fxLayout="row" fxLayoutGap="16px">
            <button
              mat-flat-button
              color="primary"
              (click)="generateChart()"
              [disabled]="generatingChart$ | async"
            >
              Generate
            </button>
            <button mat-flat-button color="primary" (click)="viewLatestChart()">
              View Latest Chart
            </button>
          </div>
        </div>
      </span>
    </div>
  </ng-container>
</div>
