import {
  Media,
  Patient,
  hasMergeConflicts,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  type IDestinationEntityJobRunOptions,
  type IHasSourceIdentifier,
  type IMigratedWithErrors,
  type ISourceEntityRecord,
  MergeConflictDestinationEntityRecord,
  SkippedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IMedia,
  type IPatient,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  Timestamp,
  asyncForEach,
  getError,
  toTimestamp,
  type WithRef,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { compact, upperFirst } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { StorageFile, getBucketStoragePath } from '../../../storage';
import { TranslationMapHandler } from '../../../translation-map';
import {
  PatientSourceEntity,
  type IExactPatient,
  type IExactPatientTranslations,
} from '../../source/entities/patient';
import { PatientDocumentsSourceEntity } from '../../source/entities/patient-documents';
import { PatientDestinationEntity } from './patient';
import { type File } from '@google-cloud/storage';

export const PATIENT_DOCUMENTS_RESOURCE_TYPE = 'patientDocuments';

export const PATIENT_DOCUMENTS_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_DOCUMENTS_RESOURCE_TYPE,
    label: 'Patient Documents',
    description: ``,
  },
});

export interface IPatientFileDestinationRecord {
  fileRefs: DocumentReference<IMedia>[];
}

export interface IPatientDocumentJobData {
  sourcePatient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>;
}

export interface IPatientFileMigrationData {
  sourcePatientId: string;
  patientRef: DocumentReference<IPatient>;
  documents: IPatientFileSourceFileData[];
}

export interface IPatientFileSourceFileData extends IHasSourceIdentifier {
  name: string;
  sourceFilePath: string;
  createdAt: Timestamp;
}

export class PatientDocumentDestinationEntity extends BaseDestinationEntity<
  IPatientFileDestinationRecord,
  IPatientDocumentJobData,
  IPatientFileMigrationData
> {
  destinationEntity = PATIENT_DOCUMENTS_DESTINATION_ENTITY;
  override batchLimit = 1000;

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    patientDocuments: new PatientDocumentsSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  override filters = [
    new PatientIdFilter<IPatientDocumentJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  sourceCountDataAccessor(
    data: IPatientDocumentJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getDestinationEntityRecordUid(data: IPatientDocumentJobData): string {
    return data.sourcePatient.record.uid;
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientDocumentJobData[]> {
    return this.buildSourceRecordQuery$(
      migration,
      this.sourceEntities.patients,
      runOptions
    ).pipe(
      map((sourcePatients) =>
        sourcePatients.map((sourcePatient) => ({
          sourcePatient,
          destinationEntity,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientDocumentJobData
  ): Promise<
    | IPatientFileMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
    | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `Couldn't resolve patient`
      );
    }

    const sourceDocuments =
      await this.sourceEntities.patientDocuments.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

    const documents = sourceDocuments.map((document) => {
      const sourceFilePath = document.data.data.source_path
        .replace(/\\/g, '/')
        .replace('medical history', 'medical-history')
        .toLowerCase();

      const extension = sourceFilePath.split('.').pop();
      if (extension === 'bcx') {
        return;
      }

      return {
        sourceIdentifier: document.record.uid,
        name: document.data.data.title,
        createdAt: document.data.translations.date,
        sourceFilePath,
      };
    });

    return {
      sourcePatientId,
      patientRef,
      documents: compact(documents),
    };
  }

  async runJob(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    jobData: IPatientDocumentJobData,
    migrationData: IPatientFileMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const response = await this.addMediaToPatient(
        migration,
        translationMapHandler,
        migrationData
      );

      if (response.failedFiles.length) {
        return this._buildMigratedWithErrorsResponse(
          jobData.sourcePatient,
          response
        );
      }

      return this._buildSuccessResponse(
        jobData.sourcePatient,
        response.migratedFiles
      );
    } catch (error) {
      return this._buildErrorResponse(jobData.sourcePatient, getError(error));
    }
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IPatientFileMigrationData
  ): Promise<IPatientFileMigrationData | undefined> {
    const existingFiles: IPatientFileSourceFileData[] = [];

    const documentMergeConflicts = await asyncForEach(
      data.documents,
      async (document) => {
        const documentRef = await translationMap.getDestination<IMedia>(
          document.sourceIdentifier,
          PATIENT_DOCUMENTS_RESOURCE_TYPE
        );
        if (!documentRef) {
          return;
        }

        const mediaFile = await Firestore.getDoc(documentRef);
        const existingFile = {
          sourceIdentifier: document.sourceIdentifier,
          name: mediaFile.name,
          createdAt: mediaFile.createdAt,
          sourceFilePath: document.sourceFilePath,
        };
        existingFiles.push(existingFile);

        return hasMergeConflicts(document, existingFile);
      }
    );

    if (documentMergeConflicts.some((conflict) => conflict)) {
      return {
        ...data,
        documents: existingFiles,
      };
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientDocumentJobData,
    _migrationData: IPatientFileMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  async addMediaToPatient(
    migration: WithRef<IPracticeMigration>,
    translationMap: TranslationMapHandler,
    migrationData: IPatientFileMigrationData
  ): Promise<{
    migratedFiles: DocumentReference<IMedia>[];
    failedFiles: IPatientFileSourceFileData[];
  }> {
    const copyStorage = this.getCopyStorage(
      getBucketStoragePath(migration.source),
      migrationData.patientRef,
      migration.configuration.projectId,
      migration.configuration.destinationBucket
    );

    const failedFiles: IPatientFileSourceFileData[] = [];
    const migratedFiles = await asyncForEach(
      migrationData.documents,
      async (document) => {
        const sourceFile = await this.findFileBySourcePath(
          copyStorage,
          document.sourceFilePath
        );
        if (!sourceFile) {
          // eslint-disable-next-line no-console
          console.log(`File not found: ${document.sourceFilePath}`);
          failedFiles.push(document);
          return;
        }

        const destinationFilePath = await copyStorage.copy(sourceFile.name);

        if (!destinationFilePath) {
          // eslint-disable-next-line no-console
          console.log(`Failed to copy file: ${document.sourceFilePath}`);
          failedFiles.push(document);
          return;
        }

        const documentDestinationRef = await translationMap.getDestination(
          document.sourceIdentifier,
          PATIENT_DOCUMENTS_RESOURCE_TYPE
        );

        const mediaRef = await FirestoreMigrate.upsertDoc(
          Patient.mediaCol({ ref: migrationData.patientRef }),
          {
            ...Media.init({
              name: document.name,
              path: destinationFilePath,
            }),
            createdAt: document.createdAt,
          },
          documentDestinationRef?.id
        );

        if (!documentDestinationRef) {
          await translationMap.upsert({
            sourceIdentifier: document.sourceIdentifier,
            destinationIdentifier: mediaRef,
            resourceType: PATIENT_DOCUMENTS_RESOURCE_TYPE,
          });
        }

        return mediaRef;
      }
    );

    return { migratedFiles: compact(migratedFiles), failedFiles };
  }

  getCopyStorage(
    sourceBucket: string,
    patientRef: DocumentReference<IPatient>,
    projectId: string,
    destinationBucket: string
  ): StorageFile {
    return new StorageFile(
      sourceBucket,
      destinationBucket,
      Patient.storagePath({ ref: patientRef }),
      projectId
    );
  }

  _buildErrorResponse(
    sourcePatient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>,
    errorMessage: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: sourcePatient.record.ref,
      errorMessage,
      failData: {
        patientRef: sourcePatient.record.ref,
      },
    };
  }

  // TODO: we should be able to remove this and improve the logic below
  // if we first generate a script to normalise the file paths
  // when they're being uploaded to the source bucket
  async findFileBySourcePath(
    copyStorage: StorageFile,
    sourcePath: string
  ): Promise<File | undefined> {
    if (!sourcePath.includes('/')) {
      const [fileName, extension] = sourcePath.split('.');
      const file = await copyStorage.getSourceFile(sourcePath);
      const file2 = await copyStorage.getSourceFile(
        `${fileName.toUpperCase()}.${extension}`
      );
      const file3 = await copyStorage.getSourceFile(
        `${fileName.toUpperCase()}.${extension.toUpperCase()}`
      );
      return file || file2 || file3;
    }

    const folderParts = sourcePath.split('/');
    const month = upperFirst(folderParts[2]);
    const prefix = [...folderParts.slice(0, 2), month].join('/');
    const files = await copyStorage.getFiles(prefix);
    return files.find((file) => file.name.toLowerCase() === sourcePath);
  }

  private _buildMigratedWithErrorsResponse(
    sourcePatient: IGetRecordResponse<IExactPatient>,
    failData: {
      migratedFiles: DocumentReference<IMedia>[];
      failedFiles: IPatientFileSourceFileData[];
    }
  ): IMigratedWithErrors<
    IPatientFileDestinationRecord,
    { failedFiles: IPatientFileSourceFileData[] }
  > {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      data: {
        fileRefs: failData.migratedFiles,
      },
      failData: {
        failedFiles: failData.failedFiles,
      },
      status: DestinationEntityRecordStatus.MigratedWithErrors,
      sourceRef: sourcePatient.record.ref,
      migratedAt: toTimestamp(),
    };
  }

  private _buildSuccessResponse(
    sourcePatient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>,
    fileRefs: DocumentReference<IMedia>[]
  ): IDestinationEntityRecord<IPatientFileDestinationRecord> {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      data: {
        fileRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: sourcePatient.record.ref,
      migratedAt: toTimestamp(),
    };
  }
}
