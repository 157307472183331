import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ICompareFormFieldState } from '../patient-details-form-comparison.service';

@Component({
    selector: 'pr-patient-details-compare-field',
    templateUrl: './patient-details-compare-field.component.html',
    styleUrl: './patient-details-compare-field.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientDetailsCompareFieldComponent {
  state$ = new ReplaySubject<ICompareFormFieldState>(1);

  @Output() revertChange = new EventEmitter<void>();

  @Input()
  set state(state: ICompareFormFieldState | undefined) {
    this.state$.next(state);
  }
}
