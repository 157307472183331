import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import { FormsDashboardComponent } from './components/forms-dashboard/forms-dashboard.component';
import { PatientFormPrintComponent } from './components/patient-form-print/patient-form-print.component';
import { PatientFormUpdateComponent } from './components/patient-form-update/patient-form-update.component';
import { PatientFormPageComponent } from './components/patient-form-page/patient-form-page.component';
import { PatientFormNameResolverService } from './patient-form-name-resolver.service';
import { PatientFormResolverService } from './patient-form-resolver.service';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: FormsDashboardComponent,
        data: { titleSuffix: 'Submitted Forms' },
      },
      {
        path: ':uid',
        data: {
          permissions: [PatientPermissions.FormsManage],
        },
        resolve: {
          patientForm: PatientFormResolverService,
          titleSuffix: PatientFormNameResolverService,
        },
        children: [
          {
            path: '',
            component: PatientFormPageComponent,
          },
          {
            path: 'update',
            component: PatientFormUpdateComponent,
          },
          {
            path: 'print',
            component: PatientFormPrintComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [PatientFormResolverService, PatientFormNameResolverService],
})
export class PatientFormsRoutingModule {}
