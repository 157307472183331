import {
  ChangeDetectionStrategy,
  Component,
  inject,
  type OnDestroy,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CurrentPatientScope,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import {
  NG_SHARED_CONFIG,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  generateMedicalHistoryUrl,
  SubmittedForm,
} from '@principle-theorem/principle-core';
import {
  FormStatus,
  IPatient,
  type ISubmittedFormHistory,
  PatientForm,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  HISTORY_DATE_TIME_FORMAT,
  isSameRef,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { FormDataProviderService } from '../form-dialog/form-data-provider.service';
import { MedicalHistoryListStore } from './medical-history-list.store';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'pr-medical-history-list',
  templateUrl: './medical-history-list.component.html',
  styleUrls: ['./medical-history-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicalHistoryListComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _sharedConfig = inject(NG_SHARED_CONFIG);
  readonly dateFormat = HISTORY_DATE_TIME_FORMAT;
  patient$: Observable<WithRef<IPatient> | undefined>;
  trackByForm = TrackByFunctions.ref<WithRef<ISubmittedFormHistory>>();
  formSelectCtrl = new TypedFormControl<WithRef<ISubmittedFormHistory>>(
    undefined
  );

  constructor(
    public store: MedicalHistoryListStore,
    private _patientScope: CurrentPatientScope,
    private _formDataProvider: FormDataProviderService,
    private _snackBar: MatSnackBar,
    private _stateNav: StateBasedNavigationService,
    private _clipboard: Clipboard
  ) {
    this.patient$ = this._patientScope.doc$.pipe(
      withLatestFrom(this._formDataProvider.patient$),
      map(([scopedPatient, providerPatient]) =>
        scopedPatient ? scopedPatient : providerPatient
      )
    );

    this.store.loadPatient(this.patient$);

    this.store.submittedForm$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((form) => {
        if (form) {
          this.formSelectCtrl.patchValue(form, { emitEvent: false });
          this.store.loadDisabled(!isSameRef(form, form));
        }
      });

    this.formSelectCtrl.valueChanges
      .pipe(
        withLatestFrom(this.store.submittedForm$.pipe(filterUndefined())),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([selected, submittedForm]) =>
        this.store.loadDisabled(!isSameRef(selected, submittedForm))
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  compareFn(
    a: WithRef<ISubmittedFormHistory>,
    b: WithRef<ISubmittedFormHistory>
  ): boolean {
    return a && b ? isSameRef(a, b) : false;
  }

  async printSelected(form: WithRef<ISubmittedFormHistory>): Promise<void> {
    const patient = await snapshot(this.store.patient$.pipe(filterUndefined()));
    await this._stateNav.brand(
      [
        'patients',
        patient.ref.id,
        'forms',
        PatientForm.MedicalHistoryForm,
        'print',
      ],
      {
        queryParams: { path: encodeURIComponent(form.ref.path) },
      }
    );
  }

  async logHistoryCheck(): Promise<void> {
    const submittedForm = await snapshot(this.store.submittedForm$);

    if (!submittedForm || !this.formSelectCtrl.value) {
      return;
    }

    await patchDoc(submittedForm.ref, {
      ...SubmittedForm.updateStatus(submittedForm, FormStatus.Confirmed),
    });

    this._snackBar.open('Medical history details confirmed');
  }

  async copyMedicalHistoryFormLink(): Promise<void> {
    const patient = await snapshot(this.patient$);
    if (!patient) {
      return;
    }
    const link = await generateMedicalHistoryUrl(
      patient,
      this._sharedConfig.appUrl
    );
    this._clipboard.copy(link);
    this._snackBar.open('Link copied to clipboard');
  }
}
