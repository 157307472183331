import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  Hotkey,
  HotkeysService as AngularHotkeysService,
} from 'angular2-hotkeys';
import { SearchComponent } from '../components/toolbar/search/search.component';

interface IAppHotkey {
  hotkey: string | string[];
  label: string;
  actionFn: (event: KeyboardEvent, combo: string) => boolean | undefined;
}

@Injectable({ providedIn: 'root' })
export class HotkeysService {
  private _appHotkeys: IAppHotkey[] = [
    {
      label: 'Open global search',
      hotkey: 's',
      actionFn: (event) => {
        const target = event.target as HTMLElement;
        if (target.nodeName === 'MAT-SELECT') {
          return true;
        }
        this.openSearch();
      },
    },
  ];

  constructor(
    private _dialog: MatDialog,
    private _hotkeyService: AngularHotkeysService
  ) {}

  disable(): void {
    this._hotkeyService.pause(this._hotkeyService.hotkeys);
  }

  enable(): void {
    this._hotkeyService.unpause(this._hotkeyService.pausedHotkeys);
  }

  registerHotkeys(): void {
    this._appHotkeys.map((item) => this._createHotkey(item));
  }

  openSearch(): void {
    this._dialog.open(SearchComponent, DialogPresets.large({ height: '90vh' }));
  }

  private _createHotkey({ hotkey, label, actionFn }: IAppHotkey): void {
    this._hotkeyService.add(
      new Hotkey(
        hotkey,
        (event, combo): boolean => {
          return actionFn(event, combo) ?? false;
        },
        undefined,
        label,
        undefined,
        true
      )
    );
  }
}
