import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type IDVACard } from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-edit-dva-card',
  templateUrl: './edit-dva-card.component.html',
  styleUrls: ['./edit-dva-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditDVACardComponent {
  constructor(
    private _dialogRef: MatDialogRef<EditDVACardComponent, IDVACard>,
    @Inject(MAT_DIALOG_DATA) public dvaCard?: IDVACard
  ) {}

  submit(): void {
    this._dialogRef.close(this.dvaCard);
  }
}
