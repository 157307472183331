<mat-toolbar color="accent">Select Automation Configuration</mat-toolbar>

<mat-dialog-content>
  <div class="flex flex-col gap-4">
    @for (config of configs; track config.ref.path) {
      <pr-automation-configuration-list-item [config]="config">
        <button
          mat-flat-button
          class="shrink-0"
          color="primary"
          (click)="selectConfig(config)"
        >
          Use Configuration
        </button>
      </pr-automation-configuration-list-item>
    } @empty {
      <pr-empty-state image="list" title="automations" />
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
  </pt-buttons-container>
</mat-dialog-actions>
