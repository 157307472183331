<mat-toolbar color="accent">Select Gap Time Range</mat-toolbar>
<form>
  <mat-dialog-content fxLayout="column">
    <mat-label>Select which part of the gap you want to fill:</mat-label>
    <mat-selection-list hideSingleSelectionIndicator [multiple]="false">
      @for (
        appointmentOption of appointmentOptions$ | async;
        track appointmentOption.from
      ) {
        <mat-list-option
          [value]="appointmentOption"
          (click)="setSelected(appointmentOption)"
          >{{ appointmentOption.from | moment | amDateFormat: timeFormat }} -
          {{
            appointmentOption.to | moment | amDateFormat: timeFormat
          }}</mat-list-option
        >
      }
    </mat-selection-list>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
