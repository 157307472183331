import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type RawInlineNodes, toTextContent } from '@principle-theorem/editor';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IAutomatedNotification,
  type IAutomation,
  type IGeneratedTask,
  isAutomatedNotification,
} from '@principle-theorem/principle-core/interfaces';
import { SHORT_DATE_TIME_FORMAT } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-appointment-automations-summary',
  templateUrl: './appointment-automations-summary.component.html',
  styleUrls: ['./appointment-automations-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentAutomationsSummaryComponent {
  trackByAutomation = TrackByFunctions.combine<
    IAutomation<IAutomatedNotification | IGeneratedTask>
  >('triggerDate', 'type');
  automations$ = new ReplaySubject<
    IAutomation<IAutomatedNotification | IGeneratedTask>[]
  >(1);
  readonly dateFormat = SHORT_DATE_TIME_FORMAT;

  @Input()
  set automations(
    automations: IAutomation<IAutomatedNotification | IGeneratedTask>[]
  ) {
    if (automations) {
      this.automations$.next(automations);
    }
  }

  getTitle(
    automation: IAutomation<IAutomatedNotification | IGeneratedTask>
  ): RawInlineNodes {
    return isAutomatedNotification(automation.data)
      ? [toTextContent(automation.data.name)]
      : automation.data.title;
  }
}
