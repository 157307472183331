import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { toTextContent } from '@principle-theorem/editor';
import { ManageCalendarEventService } from '@principle-theorem/ng-calendar';
import {
  CurrentScopeFacade,
  OrganisationService,
  StafferSettingsStoreService,
} from '@principle-theorem/ng-principle-shared';
import {
  CalendarEvent,
  Event,
  Practice,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import { StafferPermissions } from '@principle-theorem/principle-core/features';
import { EventType } from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  getDoc,
  mergeDayAndTime,
  snapshot,
  toTimestamp,
} from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-interactive-timeline-day-menu',
  templateUrl: './interactive-timeline-day-menu.component.html',
  styleUrls: ['./interactive-timeline-day-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InteractiveTimelineDayMenuComponent {
  day$ = new ReplaySubject<Moment>(1);
  rosterManagePermission = [StafferPermissions.RosterManage];

  @Input()
  set day(day: Moment) {
    if (day) {
      this.day$.next(day);
    }
  }

  constructor(
    private _currentScope: CurrentScopeFacade,
    private _manager: ManageCalendarEventService,
    private _organisation: OrganisationService,
    private _timelineStore: StafferSettingsStoreService
  ) {}

  async closePractice(): Promise<void> {
    const day = await snapshot(this.day$);
    const practice = await snapshot(
      this._currentScope.currentPractice$.pipe(filterUndefined())
    );
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(
        filterUndefined(),
        map(stafferToNamedDoc)
      )
    );

    await this._manager.add(
      CalendarEvent.init({
        title: [toTextContent('Practice Closed')],
        isBlocking: true,
        event: Event.init({
          from: toTimestamp(mergeDayAndTime(day, Practice.openTime(practice))),
          to: toTimestamp(mergeDayAndTime(day, Practice.closeTime(practice))),
          practice,
          organiser: staffer,
          creator: staffer,
          type: EventType.PracticeClosed,
        }),
      })
    );
  }

  async addToRoster(): Promise<void> {
    const day = await snapshot(this.day$);
    const practice = await snapshot(
      this._currentScope.currentPractice$.pipe(filterUndefined())
    );
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(
        filterUndefined(),
        map(stafferToNamedDoc)
      )
    );

    const eventRef = await this._manager.add(
      CalendarEvent.init({
        title: [toTextContent('Rostered On')],
        isBlocking: false,
        event: Event.init({
          from: toTimestamp(mergeDayAndTime(day, Practice.openTime(practice))),
          to: toTimestamp(mergeDayAndTime(day, Practice.closeTime(practice))),
          practice,
          organiser: staffer,
          creator: staffer,
          type: EventType.RosteredOn,
        }),
      })
    );

    if (!eventRef) {
      return;
    }

    const selectedStaff = await snapshot(
      this._timelineStore.filteredStaffByPractice$
    );
    if (!selectedStaff.length) {
      return;
    }

    const calendarEvent = await getDoc(eventRef);
    const eventPractice = calendarEvent.event.practice.ref;
    const currentStaff = await snapshot(
      this._timelineStore.getStaffByPractice$(eventPractice)
    );

    const staffByPractice = {
      practice: calendarEvent.event.practice.ref,
      staff: [
        ...currentStaff,
        ...Event.staff(calendarEvent.event).map(
          (eventStaffer) => eventStaffer.ref
        ),
      ],
    };

    this._timelineStore.updateStafferSettings({
      timeline: {
        filteredStaffByPractice: [staffByPractice],
      },
    });
  }
}
