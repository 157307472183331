import { type DocumentReference } from '@principle-theorem/shared';
import { isObject } from '@principle-theorem/shared';
import {
  type IChartedItemConfiguration,
  isChartedItemConfiguration,
} from '../charted-item-configuration';
import {
  type ITreatmentConfiguration,
  type ITreatmentPackagePriceOverride,
} from './treatment-configuration';
import { type ITreatmentStepConfiguration } from './treatment-step-configuration';

export interface IMultiTreatmentConfiguration
  extends IChartedItemConfiguration {
  steps: ITreatmentStepConfiguration[];
  conditions: DocumentReference[];
  packages: IMultiTreatmentPackage[];
}

export function isMultiTreatmentConfiguration(
  item: unknown
): item is IMultiTreatmentConfiguration {
  return (
    isObject(item) &&
    'steps' in item &&
    Array.isArray(item.steps) &&
    'conditions' in item &&
    Array.isArray(item.conditions) &&
    isChartedItemConfiguration(item)
  );
}

export interface IMultiTreatmentPackage {
  uid: string;
  name: string;
  description: string;
  isDefault: boolean;
  steps: IMultiTreatmentPackageStep[];
}

export interface IMultiTreatmentPackageStep {
  uid: string;
  treatments: IMultiTreatmentPackageTreatment[];
}

export interface IMultiTreatmentPackageTreatment {
  treatmentRef: DocumentReference<ITreatmentConfiguration>;
  priceOverrides: ITreatmentPackagePriceOverride[];
}
