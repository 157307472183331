import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  inject,
  type OnDestroy,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getSchemaSize, getSchemaText } from '@principle-theorem/editor';
import {
  GlobalStoreService,
  NamedDocsToTags,
  OrganisationService,
  ScheduleSummaryEventActionsService,
  SummaryEventActionType,
  TimezoneService,
} from '@principle-theorem/ng-principle-shared';
import {
  DynamicSidebarService,
  ProfileImageService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  CalendarEvent,
  Event,
  Staffer,
  TimezoneResolver,
} from '@principle-theorem/principle-core';
import {
  EVENT_TYPES_ICONS,
  isCalendarEventFromSchedule,
  type EventType,
  type ICalendarEvent,
  type IUser,
} from '@principle-theorem/principle-core/interfaces';
import {
  HISTORY_DATE_FORMAT,
  TIME_FORMAT,
  deleteDoc,
  filterUndefined,
  getDoc,
  isSameRef,
  isWithRef,
  multiSwitchMap,
  snapshot,
  timePeriodToHumanisedTime,
  toMomentTz,
  toTimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
import { omit } from 'lodash';
import { Subject, combineLatest, type Observable } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { ManageCalendarEventService } from '../../manage-calendar-event.service';
import { CalendarEventFormComponent } from '../calendar-event-form/calendar-event-form.component';
import { type ICalendarEventFormData } from '../calendar-event-form/calendar-event.formgroup';
import { CalendarEventSidebarStoreService } from './calendar-event-sidebar-store.service';

export interface ICalendarEventSidebarData {
  calendarEvent: WithRef<ICalendarEvent>;
}

@Component({
    selector: 'pr-calendar-event-edit-sidebar',
    templateUrl: './calendar-event-edit-sidebar.component.html',
    styleUrls: ['./calendar-event-edit-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CalendarEventEditSidebarComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  profileImage = inject(ProfileImageService);
  calendarEvent$: Observable<WithRef<ICalendarEvent> | ICalendarEvent>;
  formEventData$: Observable<ICalendarEvent>;
  trackByParticipant = TrackByFunctions.ref<WithRef<IUser>>();
  participants$: Observable<WithRef<IUser>[]>;
  namedDocsToTags: NamedDocsToTags;
  hasNotes$: Observable<boolean>;
  dateSummary$: Observable<string>;
  hiddenFields$: Observable<(keyof ICalendarEventFormData)[]>;
  canEditTimes$: Observable<boolean>;
  @ViewChild(CalendarEventFormComponent)
  calendarEventForm: CalendarEventFormComponent;
  readonly timeFormat = TIME_FORMAT;

  constructor(
    private _calendarEvent: ManageCalendarEventService,
    private _organisation: OrganisationService,
    private _sidebar: DynamicSidebarService,
    private _snackBar: MatSnackBar,
    private readonly _componentStore: CalendarEventSidebarStoreService,
    private _timezone: TimezoneService,
    private _global: GlobalStoreService,
    private _scheduleSummaryEventActions: ScheduleSummaryEventActionsService
  ) {
    this.namedDocsToTags = new NamedDocsToTags(this._global);
    this.calendarEvent$ =
      this._componentStore.calendarEvent$.pipe(filterUndefined());
    this.formEventData$ = this.calendarEvent$.pipe(
      distinctUntilChanged(
        (currentEvent, newEvent) =>
          !CalendarEvent.hasChanged(currentEvent, newEvent)
      )
    );

    this.calendarEvent$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((calendarEvent) =>
        this.namedDocsToTags.namedDocs$.next(calendarEvent.eventTags)
      );

    this.participants$ = combineLatest([
      this._organisation.staff$,
      this.calendarEvent$,
    ]).pipe(
      map(([staff, calendarEvent]) => {
        return staff.filter((staffer) => {
          return calendarEvent.event.participants.some((participant) => {
            return isSameRef(participant, staffer);
          });
        });
      }),
      multiSwitchMap((staffer) => Staffer.user$(staffer))
    );

    this.hasNotes$ = this.calendarEvent$.pipe(
      map((event) => !!getSchemaSize(event.notes))
    );

    this.dateSummary$ = this.calendarEvent$.pipe(
      switchMap(async (calendarEvent) => {
        const timezone = await TimezoneResolver.fromEvent(calendarEvent);
        const fromDate = toMomentTz(calendarEvent.event.from, timezone);
        const summary = [fromDate.format(HISTORY_DATE_FORMAT)];
        if (
          calendarEvent.event.to &&
          !fromDate.isSame(toMomentTz(calendarEvent.event.to, timezone), 'day')
        ) {
          summary.push(
            toMomentTz(calendarEvent.event.to, timezone).format(
              HISTORY_DATE_FORMAT
            )
          );
        }
        return summary.join(' - ');
      })
    );

    this.canEditTimes$ = this.calendarEvent$.pipe(
      switchMap((calendarEvent) =>
        this._timezone
          .getEventRange$(calendarEvent.event)
          .pipe(
            map(
              (range) =>
                calendarEvent.event.participants.length > 1 ||
                !range.from.isSame(range.to, 'day')
            )
          )
      )
    );

    this.hiddenFields$ = this.canEditTimes$.pipe(
      map((canEditTimes) => {
        if (!canEditTimes) {
          const hiddenFields: (keyof ICalendarEventFormData)[] = [
            'type',
            'startTime',
            'endTime',
            'eventStartDate',
            'eventEndDate',
            'practice',
            'participants',
            'staffSearch',
          ];
          return hiddenFields;
        }
        return [];
      })
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async editEvent(
    calendarEvent: ICalendarEvent | WithRef<ICalendarEvent>
  ): Promise<void> {
    const newEvent = await this._calendarEvent.openEditDialog(calendarEvent);
    if (newEvent) {
      const newState = { calendarEvent: await getDoc(newEvent) };
      this._componentStore.setState(newState);
    }
  }

  async deleteEvent(): Promise<void> {
    const calendarEvent = await snapshot(this.calendarEvent$);
    if (isWithRef(calendarEvent)) {
      const prompt = `Are you sure you want to delete ${getSchemaText(
        calendarEvent.title
      )}?`;
      const confirmed = await this._calendarEvent.confirmDelete(prompt);
      if (!confirmed) {
        return;
      }
      await deleteDoc(calendarEvent.ref);
      await this._scheduleSummaryEventActions.setCalendarEvent(
        SummaryEventActionType.Delete
      );
      this._closeSidebar('Event Deleted');
      return;
    }

    if (isCalendarEventFromSchedule(calendarEvent)) {
      const prompt = `Are you sure you want to delete this occurrence of ${getSchemaText(
        calendarEvent.title
      )}?`;
      const confirmed = await this._calendarEvent.confirmDelete(prompt);
      if (!confirmed) {
        return;
      }

      await this._calendarEvent.removeOccurenceFromSchedule(
        calendarEvent.scheduleRef,
        calendarEvent.event.from
      );

      await this._calendarEvent.addDeletedEventOccurrence(calendarEvent);

      await this._scheduleSummaryEventActions.setCalendarEvent(
        SummaryEventActionType.Delete
      );
      this._closeSidebar('Event Occurrence Deleted');
      return;
    }
  }

  getIcon(eventType: EventType): string {
    const eventIcons = EVENT_TYPES_ICONS;
    return eventIcons[eventType];
  }

  async save(formData: ICalendarEvent): Promise<void> {
    const timelineEventChanges = await snapshot(this.calendarEvent$);
    const canEditTimes = await snapshot(this.canEditTimes$);

    const { event, eventHistory } = CalendarEvent.replaceEvent(
      timelineEventChanges,
      canEditTimes
        ? Event.init(formData.event)
        : Event.init({
            ...timelineEventChanges.event,
            ...omit(formData.event, ['from', 'to']),
          })
    );

    const updatedEvent = {
      ...timelineEventChanges,
      ...formData,
      event,
      eventHistory,
    };

    const updated = await this._calendarEvent.update(updatedEvent);

    if (!updated) {
      this.calendarEventForm.saving$.next(false);
      return;
    }
    this._snackBar.open('Event Updated');
    this._sidebar.close(true);
  }

  getDuration(event: ICalendarEvent): string {
    const range = toTimePeriod(event.event.from, event.event.to);
    return timePeriodToHumanisedTime(range);
  }

  private _closeSidebar(message?: string): void {
    this._sidebar.close();
    if (message) {
      this._snackBar.open(message);
    }
  }
}
