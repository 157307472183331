import { TreatmentPlanStatus } from '@principle-theorem/principle-core/interfaces';
import { ITreatmentPlanDimension } from '@principle-theorem/reporting/interfaces';
import { BaseDimensionMeasures } from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import { MeasureTransformMap } from '../measure-properties';
import { CanBeChartedProperty, CanDoAllProperty } from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';

export class TreatmentPlanDimensionMeasures
  extends BaseDimensionMeasures
  implements
    MeasureTransformMap<Pick<ITreatmentPlanDimension, 'status' | 'name'>>
{
  get ref(): CanBeChartedProperty {
    const propertyName = 'ref';
    const docRef = this.measureRef(MeasurePath.docRef(propertyName));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Treatment Plan Ref',
        summary: '',
      },
      docRef,
      this.buildQuery().attributes([docRef.attributePath]).get()
    );
  }

  get name(): CanDoAllProperty {
    const propertyName = 'name';
    const name = this.measureRef(propertyName);
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Treatment Plan Name',
        summary: '',
      },
      docRef,
      this.buildQuery()
        .attributes([docRef.attributePath, name.attributePath])
        .get(),
      name
    );
  }

  get status(): CanDoAllProperty {
    const propertyName = 'status';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.enum<TreatmentPlanStatus>(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Treatment Plan Status',
        summary: '',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      TreatmentPlanStatus.Draft
    );
  }
}
