import { BlockNodes, type IMenuButton } from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../menu-bar/basic-menu-button/basic-menu-button.component';

export const BLOCKQUOTE_KEYMAP = 'Mod-Shift-b';

export function createBlockquoteMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'submenu-detail',
      buttonText: 'Blockquote',
      tooltip: 'Blockquote',
      shortcut: BLOCKQUOTE_KEYMAP,
      icon: 'format_quote',
      command: (editor) => editor.chain().focus().toggleBlockquote().run(),
      isActiveFn: (editor) => editor.isActive(BlockNodes.Blockquote),
      canActivateFn: (editor) => editor.isActive(BlockNodes.Paragraph),
    },
  });
}
