import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DateService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type ILabJob,
  type IPractice,
  type IStaffer,
  LabJobStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { LabJobManager } from '../../lab-job-manager';
import { LabJobFormComponent } from '../lab-job-form/lab-job-form.component';
import { type ILabJobFormData } from '../lab-job-form/lab-job.formgroup';

@Component({
  selector: 'pr-lab-job-edit',
  templateUrl: './lab-job-edit.component.html',
  styleUrls: ['./lab-job-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabJobEditComponent {
  trackByStatus = TrackByFunctions.variable<LabJobStatus>();
  labJobForm$: ReplaySubject<LabJobFormComponent> =
    new ReplaySubject<LabJobFormComponent>(1);
  labJob$: ReplaySubject<WithRef<ILabJob>> = new ReplaySubject(1);
  @Input() practice: WithRef<IPractice>;
  @Output() cancelled = new EventEmitter<void>();

  @ViewChild(LabJobFormComponent, { static: false })
  set labJobForm(form: LabJobFormComponent) {
    if (form) {
      this.labJobForm$.next(form);
    }
  }

  @Input()
  set labJob(labJob: WithRef<ILabJob>) {
    if (labJob) {
      this.labJob$.next(labJob);
      void this.updateControls(labJob.status);
    }
  }

  constructor(
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService,
    public dateService: DateService
  ) {}

  async saveChanges(data: ILabJobFormData): Promise<void> {
    const staffer: WithRef<IStaffer> = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const labJobManager = new LabJobManager(this.practice, staffer);
    const labJob = await snapshot(this.labJob$);
    await labJobManager.update(labJob, data);
    this._snackBar.open(`Lab Job Updated`);
  }

  async updateControls(status: LabJobStatus): Promise<void> {
    const labJobForm = await snapshot(this.labJobForm$);
    [
      labJobForm.labJobFormGroup.controls.lab,
      labJobForm.labJobFormGroup.controls.type,
    ].map((control) => {
      if (status !== LabJobStatus.Sending) {
        control.disable();
        return;
      }
      control.enable();
    });
  }
}
