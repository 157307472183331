import { LoadPatientActionService } from '@principle-theorem/ng-interactions';
import { Patient } from '@principle-theorem/principle-core';
import {
  PatientStatus,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { type IProfileLoader } from './profile-loader';

export class PatientLoader implements IProfileLoader {
  showInitials = true;
  name: string;
  profileImageURL?: string;
  details: string;
  inactive: boolean;
  phone?: string;

  constructor(
    public patient: Pick<
      WithRef<IPatient>,
      | 'name'
      | 'profileImageURL'
      | 'ref'
      | 'contactNumbers'
      | 'dateOfBirth'
      | 'status'
    >,
    public loader: LoadPatientActionService
  ) {
    const age = Patient.age(patient);
    const dateOfBirth = patient.dateOfBirth
      ? toMoment(patient.dateOfBirth).format(DAY_MONTH_YEAR_FORMAT)
      : '';
    const inactive = patient.status === PatientStatus.Inactive;
    this.name = patient.name;
    this.profileImageURL = patient.profileImageURL;
    this.details = age ? `${dateOfBirth} (${age} yrs old)` : '';
    this.inactive = inactive;
    this.phone = first(patient.contactNumbers)?.number ?? '';
  }

  async do(): Promise<void> {
    await this.loader.do(this.patient);
  }
}
