import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type ISubmittedForm } from '@principle-theorem/principle-core/interfaces';
import { HISTORY_DATE_FORMAT } from '@principle-theorem/shared';

export interface IMedicalHistoryDialogData {
  form: ISubmittedForm;
  readonly: boolean;
}

@Component({
  selector: 'pr-medical-history-dialog',
  templateUrl: './medical-history-dialog.component.html',
  styleUrls: ['./medical-history-dialog.component.sass'],
})
export class MedicalHistoryDialogComponent {
  readonly dateFormat = HISTORY_DATE_FORMAT;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IMedicalHistoryDialogData
  ) {}
}
