import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
  ManagementService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { BaseResolver } from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { Brand } from '@principle-theorem/principle-core';
import { filterUndefined, type WithRef } from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class StafferAccountResolverService extends BaseResolver<
  WithRef<IStaffer>
> {
  constructor(
    private _organisation: OrganisationService,
    private _management: ManagementService
  ) {
    super();
  }

  resolve(route: ActivatedRouteSnapshot): Observable<WithRef<IStaffer>> {
    const brand = this._findInheritedRouteData<WithRef<IBrand>>(route, 'brand');
    if (!brand) {
      throw new Error('No Brand found');
    }
    return combineLatest([
      this._organisation.user$.pipe(filterUndefined()),
      this._management.user$,
    ]).pipe(
      switchMap(([user, managementUser]) =>
        managementUser
          ? this._organisation.staffer$
          : Brand.userStaffer$(brand, user)
      ),
      filterUndefined(),
      take(1)
    );
  }
}
