import {
  Appointment,
  TreatmentPlan,
  TreatmentStep,
  dateIsWithinBounds,
  Event,
} from '@principle-theorem/principle-core';
import {
  IAppointment,
  isEventable,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, isPathChanged$, toMoment } from '@principle-theorem/shared';

import * as moment from 'moment-timezone';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export class AppointmentCardWarnings {
  static schedulingConflicts$(
    appointment$: Observable<WithRef<IAppointment>>
  ): Observable<string[]> {
    return appointment$.pipe(
      map((appointment) => Appointment.getSchedulingConflicts(appointment))
    );
  }

  static durationWarning$(
    appointment$: Observable<WithRef<IAppointment>>
  ): Observable<string | undefined> {
    const eventDuration$ = appointment$.pipe(
      map((appointment) =>
        isEventable(appointment) ? Event.duration(appointment.event) : 0
      )
    );

    const step$ = appointment$.pipe(
      isPathChanged$('ref.id'),
      switchMap((appointment) =>
        TreatmentPlan.treatmentStepForAppointment$(appointment)
      )
    );

    const requestedMins$ = combineLatest([
      step$.pipe(
        switchMap((step) => (step ? TreatmentStep.getDuration$(step) : of(0)))
      ),
      eventDuration$,
    ]).pipe(
      map(([stepDuration, eventDuration]) =>
        stepDuration > 0 ? stepDuration : eventDuration
      )
    );

    const difference$ = combineLatest([requestedMins$, eventDuration$]).pipe(
      map(([requestedMins, eventDuration]) => requestedMins - eventDuration),
      map((difference) => Math.abs(difference))
    );

    return combineLatest([difference$, requestedMins$]).pipe(
      map(([difference, requestedMins]) => {
        if (difference === 0) {
          return;
        }
        const differenceType = difference > 0 ? 'shorter' : 'longer';
        return `Appointment is ${difference} mins ${differenceType} than the requested ${requestedMins} mins`;
      })
    );
  }

  static schedulingRuleConflict$(
    appointment$: Observable<WithRef<IAppointment>>
  ): Observable<string | undefined> {
    const plan$ = appointment$.pipe(
      isPathChanged$('ref.id'),
      switchMap((appointment) => Appointment.treatmentPlan$(appointment))
    );
    const step$ = appointment$.pipe(
      isPathChanged$('ref.id'),
      switchMap((appointment) =>
        TreatmentPlan.treatmentStepForAppointment$(appointment)
      )
    );

    const schedulingRuleMessage$ = combineLatest([
      appointment$,
      plan$,
      step$,
    ]).pipe(
      switchMap(([appointment, plan, step]) => {
        if (
          !plan ||
          !step ||
          !TreatmentStep.schedulingRulesHasDayRanges(step) ||
          !isEventable(appointment)
        ) {
          return of(undefined);
        }
        return TreatmentPlan.getStepAbsoluteSchedulingRules$(plan, step);
      })
    );

    return combineLatest([appointment$, schedulingRuleMessage$]).pipe(
      map(([appointment, schedulingRuleMessage]) => {
        const from = appointment.event?.from;
        const appointmentDate: moment.Moment = from ? toMoment(from) : moment();
        if (
          schedulingRuleMessage &&
          !dateIsWithinBounds(appointmentDate, schedulingRuleMessage)
        ) {
          return 'This appointment date conflicts with the set scheduling rules';
        }
      })
    );
  }
}
