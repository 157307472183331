import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type WithRef } from '@principle-theorem/shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type IEventable } from '@principle-theorem/principle-core/interfaces';
import { type Moment } from 'moment-timezone';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-week-header',
    templateUrl: './week-header.component.html',
    styleUrls: ['./week-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WeekHeaderComponent {
  trackByDay = TrackByFunctions.date<Moment>();
  days$: ReplaySubject<Moment[]> = new ReplaySubject<Moment[]>(1);
  events$: ReplaySubject<WithRef<IEventable>[]> = new ReplaySubject<
    WithRef<IEventable>[]
  >(1);

  @Input()
  set days(days: Moment[]) {
    if (!days) {
      return;
    }
    this.days$.next(days);
  }
}
