import { Injectable } from '@angular/core';
import { type Data, Router } from '@angular/router';
import { type WithRef } from '@principle-theorem/shared';
import { type IBrand } from '@principle-theorem/principle-core/interfaces';
import { BaseCurrentFirestoreModel } from './base-current-firestore-model';

@Injectable({
  providedIn: 'root',
  useFactory: (route: Router) => new CurrentBrandScope(route),
  deps: [Router],
})
export class CurrentBrandScope extends BaseCurrentFirestoreModel<
  WithRef<IBrand>
> {
  protected _propertyAccessor(data: Data): WithRef<IBrand> | undefined {
    if (data.brand) {
      return data.brand as WithRef<IBrand>;
    }
  }
}
