export enum BridgeDeviceFeatureStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Error = 'error',
}

export interface IBridgeDeviceFeature {
  label: string;
  description: string;
  status: BridgeDeviceFeatureStatus;
}
