<div class="layout-padding">
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <h3 class="mat-headline-6">Referral Sources</h3>
    <pt-download-report
      *ngrxLet="result$ as result"
      [data]="result.records"
      [dateRange]="dateRange$ | async"
      [csvTranslator]="csvTranslator"
    />
  </div>
</div>

<table
  mat-table
  [dataSource]="dataSource"
  fxFlex
  matSort
  matSortActive="appointmentDate"
  matSortDirection="asc"
>
  <ng-container matColumnDef="appointmentDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Appointment Date</th>
    <td mat-cell *matCellDef="let record">
      {{ record.appointment.event.from | moment | amDateFormat: dateFormat }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="patientName">
    <th mat-header-cell *matHeaderCellDef>Patient</th>
    <td mat-cell *matCellDef="let record">
      <a [routerLink]="record.patient | patientUrl$ | async" target="_blank">
        {{ record.patient.name }}
      </a>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="mobileNumber">
    <th mat-header-cell *matHeaderCellDef>Mobile</th>
    <td mat-cell *matCellDef="let record">
      {{ record.patient | map: getMobileNumber | async }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="referredBy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Referred By</th>
    <td mat-cell *matCellDef="let record">
      <pr-patient-referrer [referrer]="record.patient.referrer" />
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="referrerMobileNumber">
    <th mat-header-cell *matHeaderCellDef>Referrer Mobile</th>
    <td mat-cell *matCellDef="let record">
      {{ record.patient.referrer | map: getReferrerMobileNumber | async }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="treatmentAmount">
    <th mat-header-cell *matHeaderCellDef>
      <pr-value-with-icon>Treatment Amount</pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record">
      <pr-value-with-icon>
        {{ record.treatmentAmount | currency }}
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon *ngIf="result$ | async as result">
        <strong>{{ result.treatmentTotal | currency }}</strong>
      </pr-value-with-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
