/* eslint-disable no-null/no-null */
import {
  ChartableSurface,
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { PraktikaCondition } from './lib/conditions';
import { PatientSourceEntity } from './patient';

export interface IPraktikaToothCondition {
  patient_id: number;
  tooth_number: number;
  type_id: PraktikaCondition;
  surfaces: PraktikaToothSurfaces;
  created_date: string; // '2020-12-16 14:26:04'
}

export function isPraktikaToothCondition(
  item: unknown
): item is IPraktikaToothCondition {
  return TypeGuard.interface<IPraktikaToothCondition>({
    patient_id: isNumber,
    tooth_number: isNumber,
    type_id: TypeGuard.enumValue(PraktikaCondition),
    surfaces: (data): data is PraktikaToothSurfaces =>
      isString(data) &&
      data.split('').every((surface) => TOOTH_SURFACES.includes(surface)),
    created_date: isString,
  })(item);
}

export const TOOTH_SURFACES = [
  // Unknown surfaces
  '',
  '1', // Rct
  '2', // Rct
  '3', // Rct, Attrition
  '4', // Rct
  // Single-surface
  'O',
  'B',
  'L',
  'M',
  'D',
];

type O = 'O';
type B = 'B';
type L = 'L';
type M = 'M';
type D = 'D';

export type PraktikaToothSurfaces =
  | '1' // Rct
  | '2' // Rct
  | '3'
  | '4'
  | `${O | ''}${B | ''}${L | ''}${M | ''}${D | ''}`;

export const TOOTH_SURFACES_MAP: Partial<
  Record<PraktikaToothSurfaces, ChartableSurface>
> = {
  B: ChartableSurface.Facial,
  L: ChartableSurface.Lingual,
  M: ChartableSurface.Mesial,
  D: ChartableSurface.Distal,
  O: ChartableSurface.Occlusal,
};

export const PATIENT_TOOTH_CONDITION_RESOURCE_TYPE = 'patientToothCondition';

export const PATIENT_TOOTH_CONDITION_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Tooth Condition List',
      description: '',
      idPrefix: PATIENT_TOOTH_CONDITION_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IPraktikaToothConditionFilters {
  patientId: string;
  createdDate: Timestamp;
}

export interface IPraktikaToothConditionTranslations {
  createdDate: Timestamp;
}

const PATIENT_TOOTH_CONDITION_SOURCE_QUERY = `
SELECT
  iPatientNumber as patient_id,
  iToothNumber as tooth_number,
  iConditionTypeId as type_id,
  vchSurfaces as surfaces,
  dtCreated as created_date
FROM patient_tooth_conditions
ORDER BY created_date
`;

export class PatientToothConditionSourceEntity extends BaseSourceEntity<
  IPraktikaToothCondition,
  IPraktikaToothConditionTranslations,
  IPraktikaToothConditionFilters
> {
  sourceEntity = PATIENT_TOOTH_CONDITION_SOURCE_ENTITY;
  entityResourceType = PATIENT_TOOTH_CONDITION_RESOURCE_TYPE;
  sourceQuery = PATIENT_TOOTH_CONDITION_SOURCE_QUERY;
  verifySourceFn = isPraktikaToothCondition;
  override transformDataFn = flow([]);
  override dateFilterField: keyof IPraktikaToothConditionFilters =
    'createdDate';

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  translate(
    adjustment: IPraktikaToothCondition,
    timezone: Timezone
  ): IPraktikaToothConditionTranslations {
    return {
      createdDate: toTimestamp(
        moment.tz(adjustment.created_date, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getSourceRecordId(data: IPraktikaToothCondition): string {
    return [
      data.patient_id.toString(),
      data.tooth_number.toString(),
      data.type_id.toString(),
      data.surfaces.trim(),
      data.created_date,
    ]
      .join('-')
      .replace(
        // eslint-disable-next-line no-useless-escape
        /[\/\s:]/g,
        '-'
      );
  }

  getSourceLabel(data: IPraktikaToothCondition): string {
    return this.getSourceRecordId(data);
  }

  getFilterData(
    data: IPraktikaToothCondition,
    timezone: Timezone
  ): IPraktikaToothConditionFilters {
    return {
      patientId: data.patient_id.toString(),
      createdDate: toTimestamp(
        moment.tz(data.created_date, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }
}
