import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BaseResolver } from '@principle-theorem/ng-shared';
import { MultiTreatmentConfiguration } from '@principle-theorem/principle-core';
import {
  type IBrand,
  type IMultiTreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { type CollectionReference } from '@principle-theorem/shared';
import { type WithRef } from '@principle-theorem/shared';

@Injectable()
export class BrandMultiTreatmentConfigurationCollectionResolverService extends BaseResolver<
  CollectionReference<IMultiTreatmentConfiguration>
> {
  resolve(
    route: ActivatedRouteSnapshot
  ): CollectionReference<IMultiTreatmentConfiguration> {
    const brand = this._findInheritedRouteData<WithRef<IBrand>>(route, 'brand');
    if (!brand) {
      throw new Error('No Brand found');
    }
    return MultiTreatmentConfiguration.col(brand);
  }
}
