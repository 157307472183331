import { Pipe, type PipeTransform } from '@angular/core';
import { type WithRef, doc$ } from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

@Pipe({
  name: 'refItem$',
})
export class RefItemPipe implements PipeTransform {
  transform<T extends object>(
    value: DocumentReference<T>
  ): Observable<WithRef<T>> {
    return doc$(value.path);
  }
}
