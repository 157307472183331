import { Directive, HostBinding, Input } from '@angular/core';
import { type IDentalChartViewSurface } from '@principle-theorem/principle-core/interfaces';

@Directive({
    selector: '[prChartSurfaceDisable]',
    standalone: false
})
export class ChartSurfaceDisableDirective {
  @HostBinding('attr.pointer-events') pointerEvents = 'auto';

  @Input('prChartSurfaceDisable')
  set view(view: IDentalChartViewSurface) {
    if (!view || !view.texture || !view.texture.disablesSurface) {
      this.pointerEvents = 'auto';
      return;
    }
    this.pointerEvents = 'none';
  }
}
