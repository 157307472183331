import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { roundTo2Decimals } from '@principle-theorem/accounting';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TypedFormControl,
  TypedFormGroup,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  Invoice,
  TransactionAllocation,
  TransactionOperators,
} from '@principle-theorem/principle-core';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import {
  IInvoice,
  type IDiscountExtendedData,
  type IStaffer,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  INamedDocument,
  isSameRef,
  mergeDayAndTime,
  shareReplayCold,
  snapshot,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { type Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { type ITransactionAmountDialogData } from '../../transaction-components/transaction-amount-dialog/transaction-amount-dialog.component';
import { AmendTransactionDateStore } from '../../transaction-components/transaction-edit-dialog/amend-transaction-date.store';
import { determineNewCreatedAtDate } from '../../transaction-helpers';

export interface IAmendDiscountTransactionDialogData
  extends ITransactionAmountDialogData {
  transaction: WithRef<ITransaction<IDiscountExtendedData>>;
  invoice: IInvoice;
  activeDiscounts: WithRef<ITransaction<IDiscountExtendedData>>[];
  dateReceived?: Moment;
  attributedTo?: DocumentReference<IStaffer>;
}

export interface IAmendDiscountTransactionDialogResult {
  amount: number;
  description: string;
  dateReceived?: Moment;
  attributedTo?: DocumentReference<IStaffer>;
}

class AmendDiscountTransactionFormGroup extends TypedFormGroup<IAmendDiscountTransactionDialogResult> {
  constructor(data: IAmendDiscountTransactionDialogData) {
    const initialAmountValue = data.options.initialAmount ?? data.max;
    const roundedAmount = initialAmountValue
      ? roundTo2Decimals(initialAmountValue)
      : undefined;
    const amountValidators = compact([
      Validators.required,
      data.max ? Validators.max(data.max) : undefined,
    ]);

    super({
      description: new TypedFormControl<string>(
        data.transaction.description ?? ''
      ),
      dateReceived: new TypedFormControl<Moment>(data.dateReceived),
      amount: new TypedFormControl<number>(roundedAmount, amountValidators),
      attributedTo: new TypedFormControl<
        DocumentReference<IStaffer> | undefined
      >(data.attributedTo),
    });
  }
}

@Component({
    selector: 'pr-amend-discount-transaction-dialog',
    templateUrl: './amend-discount-transaction-dialog.component.html',
    styleUrls: ['./amend-discount-transaction-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [...MOMENT_DATEPICKER_PROVIDERS, AmendTransactionDateStore],
    standalone: false
})
export class AmendDiscountTransactionDialogComponent {
  form: AmendDiscountTransactionFormGroup;
  accountAdminPermission = PatientPermissions.AccountInvoiceAdmin;
  maxAmount$: Observable<number | undefined>;
  staff: INamedDocument<IStaffer>[];
  showAttributedTo: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAmendDiscountTransactionDialogData,
    private _dialogRef: MatDialogRef<
      AmendDiscountTransactionDialogComponent,
      IAmendDiscountTransactionDialogResult
    >,
    public amendDateStore: AmendTransactionDateStore
  ) {
    this.form = new AmendDiscountTransactionFormGroup(data);
    this.staff = Invoice.staffOnInvoice(this.data.invoice);
    this.showAttributedTo =
      this.staff.length > 1 ||
      TransactionAllocation.hasUnallocatedAmount(this.data.invoice);
    this.amendDateStore.loadAmendDates(data.transaction);
    const invoicePractitioners = Invoice.getPractitionerProportionsOnInvoice(
      this.data.invoice
    );

    this.maxAmount$ = formControlChanges$(this.form.controls.attributedTo).pipe(
      map((selectedPractitionerRef) => {
        if (!selectedPractitionerRef) {
          return this.data.transaction.amount;
        }

        const existingPractitionerDiscountAmount = new TransactionOperators(
          this.data.activeDiscounts
            .filter(
              (discount) =>
                !discount.attributedTo ||
                isSameRef(discount.attributedTo, selectedPractitionerRef)
            )
            .filter((discount) => !isSameRef(discount, this.data.transaction))
        ).paidToDate();

        const practitionerProportion = invoicePractitioners.find(
          (practitioner) =>
            isSameRef(practitioner.practitioner, selectedPractitionerRef)
        );

        if (!practitionerProportion) {
          return this.data.transaction.amount;
        }

        return (
          practitionerProportion.amount - existingPractitionerDiscountAmount
        );
      }),
      tap((maxValue) => {
        const currentValue = this.form.controls.amount.value ?? undefined;
        if (currentValue && currentValue > maxValue) {
          this.form.controls.amount.setValue(maxValue);
        }
        this.form.controls.amount.setValidators(
          compact([
            Validators.required,
            Validators.max(maxValue),
            Validators.min(0),
          ])
        );
        this.form.updateValueAndValidity();
      }),
      startWith(this.data.transaction.amount),
      shareReplayCold()
    );
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    const data = this.form.value;
    const dateReceived = data.dateReceived
      ? await determineNewCreatedAtDate(
          this.data.transaction,
          mergeDayAndTime(data.dateReceived, moment()),
          await snapshot(this.amendDateStore.minDate$),
          await snapshot(this.amendDateStore.maxDate$)
        )
      : undefined;

    this._dialogRef.close({
      ...this.form.value,
      dateReceived,
    });
  }

  comparePractitionerRefFn(
    value: DocumentReference<IStaffer>,
    practitioner: DocumentReference<IStaffer>
  ): boolean {
    if (!value || !practitioner) {
      return false;
    }
    return isSameRef(value, practitioner);
  }
}
