<div fxLayout="column">
  <div class="heading" fxLayout="row" fxLayoutAlign="space-between center">
    @if (expanded) {
      <h1>Settings</h1>
      <button mat-stroked-button (click)="returnToDashboard()">
        <mat-icon>chevron_left</mat-icon> Back
      </button>
    } @else {
      <button mat-icon-button (click)="returnToDashboard()" matTooltip="Back">
        <mat-icon>chevron_left</mat-icon>
      </button>
    }
  </div>

  <pr-sidebar-practice-selector
    [expanded]="expanded"
    (practiceSelected)="loadPractice($event)"
  />

  <ng-container *ngFor="let route of workspaceRoutes; trackBy: trackByRoute">
    <ng-container *prHasPermission="route.feature?.permissions">
      <pr-sidebar-group
        *ptFeatureEnabled="route.feature?.label"
        label="Workspace"
        [links]="route.children"
        [expanded]="expanded"
        routePrefix="./workspace"
      />
    </ng-container>
  </ng-container>

  <ng-container *ngIf="brand$ | async">
    <ng-container *ngFor="let route of brandSettings; trackBy: trackByRoute">
      <ng-container *prHasPermission="route.feature?.permissions">
        <pr-sidebar-group
          *ptFeatureEnabled="route.feature?.label"
          [label]="route.label"
          [tooltip]="route.tooltip"
          [links]="route.children"
          [expanded]="expanded"
          [routePrefix]="brandRoutePrefix$ | async"
        />
      </ng-container>
    </ng-container>

    <ng-container *ngIf="hasStaffer$ | async; else noStaffer">
      <ng-container
        *ngFor="let route of stafferSettings; trackBy: trackByRoute"
      >
        <pr-sidebar-group
          *ptFeatureEnabled="route.feature?.label"
          [label]="route.label"
          [tooltip]="route.tooltip"
          [links]="route.children"
          [expanded]="expanded"
          [routePrefix]="stafferRoutePrefix$ | async"
        />
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #noStaffer>
    <span class="layout-margin mat-caption"
      >You have no staff account for this brand. You can assign yourself to this
      brand via the
      <a [routerLink]="['/settings/workspace/users']">user manage page</a
      >.</span
    >
  </ng-template>

  <ng-container *ngIf="hasPracticeAccess$ | async; else noPracticeAccess">
    <ng-container *ngIf="practice$ | async">
      <ng-container
        *ngFor="let route of practiceSettings; trackBy: trackByRoute"
      >
        <pr-sidebar-group
          *ptFeatureEnabled="route.feature?.label"
          [label]="route.label"
          [tooltip]="route.tooltip"
          [links]="route.children"
          [expanded]="expanded"
          [routePrefix]="practiceRoutePrefix$ | async"
        />
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #noPracticeAccess>
    <span class="layout-margin mat-caption"
      >You don't have access to manage this practice. Your user can be given
      access to this practice via the
      <a [routerLink]="['/settings/workspace/users']">user manage page</a
      >.</span
    >
  </ng-template>
</div>
