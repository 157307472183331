<pr-reconciliation-transactions-drilldown
  *ngIf="reportFacade.selectedSummary$ | async as result; else selector"
  [result]="result"
  [dateRange]="reportFacade.range$ | async"
/>

<ng-template #selector>
  <ng-container *ngIf="report$ | async as report; else loading">
    <pt-download-report
      [data]="csvData$ | async"
      [dateRange]="reportFacade.range$ | async"
      [csvTranslator]="csvTranslator"
      fileName="reconciliation-transactions-summary"
    />
    <div fxLayout="column" fxLayoutGap="16px">
      <div class="reports" fxLayout="column" fxLayoutGap="8px">
        <ng-container *ngIf="report.revenue.sources.length; else noSources">
          <pr-reconciliation-transactions-summary [summary]="report.revenue" />
        </ng-container>
        <ng-template #noSources>
          <pr-empty-state
            image="list"
            title="transactions for the given date range"
          />
        </ng-template>
      </div>
      <div class="reports" *ngIf="report.discount.sources.length">
        <pr-reconciliation-transactions-summary [summary]="report.discount" />
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #loading>
  <div fxFlex fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner mode="indeterminate" />
  </div>
</ng-template>
