import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutomationsFacade } from '@principle-theorem/ng-automations';
import { Appointment } from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

export interface IAutomationsDialogData {
  appointment: IAppointment;
}

@Component({
  selector: 'pr-appointment-automations-dialog',
  templateUrl: './appointment-automations-dialog.component.html',
  styleUrls: ['./appointment-automations-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentAutomationsDialogComponent {
  treatmentStep$: Observable<WithRef<ITreatmentStep>>;

  constructor(
    public dialogRef: MatDialogRef<AppointmentAutomationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: IAutomationsDialogData,
    private _automationsFacade: AutomationsFacade
  ) {
    this.treatmentStep$ = Appointment.treatmentStep$(data.appointment);
  }

  async save(): Promise<void> {
    const treatmentStep = await snapshot(this.treatmentStep$);
    this._automationsFacade.saveAutomations(treatmentStep);
    this.dialogRef.close();
  }
}
