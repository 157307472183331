<div class="loader">
  <mat-progress-bar
    *ngIf="loading"
    mode="indeterminate"
    [color]="colour"
   />
</div>
<div class="inner">
  <ng-content></ng-content>
</div>
