import { type WithRef } from '../../interfaces';
import {
  type DocumentReference,
  type Query,
  type Transaction,
} from '../adaptor';
import { getDoc, updateDoc } from '../document';
import { Firestore } from '../firestore';
import { runTransaction } from '../firestore-resolver';

export class FirestoreTransactionRunner {
  static run<T>(
    updateFunction: (transaction: FirestoreTransactionHelper) => Promise<T>,
    existingTransaction?: Transaction
  ): Promise<T> {
    if (existingTransaction) {
      return updateFunction(
        new FirestoreTransactionHelper(existingTransaction)
      );
    }
    return runTransaction<T>((transaction) =>
      updateFunction(new FirestoreTransactionHelper(transaction))
    );
  }
}

export class FirestoreTransactionHelper {
  public transaction: Transaction;

  constructor(transaction: Transaction) {
    this.transaction = transaction;
  }

  static getDocs<Model extends object>(
    query: Query<Model>,
    transaction: Transaction
  ): Promise<WithRef<Model>[]> {
    return new FirestoreTransactionHelper(transaction).getDocs(query);
  }

  static getDoc<Model extends object>(
    ref: DocumentReference<Model>,
    transaction: Transaction
  ): Promise<WithRef<Model>> {
    return new FirestoreTransactionHelper(transaction).getDoc(ref);
  }

  static async updateDoc<Model extends object>(
    model: WithRef<Model>,
    transaction: Transaction
  ): Promise<void> {
    await new FirestoreTransactionHelper(transaction).updateDoc(model);
  }

  async getDocs<Model extends object>(
    query: Query<Model>
  ): Promise<WithRef<Model>[]> {
    return Firestore.getDocs<Model>(query);
  }

  async getDoc<Model extends object>(
    ref: DocumentReference<Model>
  ): Promise<WithRef<Model>> {
    return getDoc(
      ref as unknown as DocumentReference<Model>,
      this.transaction as unknown as Transaction
    );
  }

  async updateDoc<Model extends object>(model: WithRef<Model>): Promise<void> {
    await updateDoc(
      model.ref,
      model,
      this.transaction as unknown as Transaction
    );
  }
}
