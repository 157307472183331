<mat-toolbar color="accent">Create Task</mat-toolbar>

<mat-dialog-content>
  <ng-container *ngrxLet="isRecurring$ as isRecurring">
    <form [formGroup]="taskFormGroup" (ngSubmit)="submit()" fxLayout="column">
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Title</mat-label>
          <pr-editor-input [required]="true" formControlName="title" />
        </mat-form-field>

        <div
          fxLayout="column"
          fxLayout.gt-sm="row"
          fxLayoutAlign.gt-sm="space-around center"
          fxLayoutGap="16px"
        >
          <pr-task-assignee fxFlex [assigneeCtrl]="assignee" />

          <mat-form-field fxFlex>
            <mat-label>Priority</mat-label>
            <mat-select formControlName="priority">
              <mat-option
                *ngFor="
                  let priority of taskPriorities;
                  trackBy: trackByPriority
                "
                [value]="priority"
              >
                {{ priority | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <ng-container *ngIf="!isRecurring">
          <mat-form-field>
            <mat-label>Due Date</mat-label>
            <input
              matInput
              formControlName="dueDate"
              [matDatepicker]="dueDate"
              (focus)="dueDate.open()"
              (click)="dueDate.open()"
              [min]="minDate"
            />

            <mat-datepicker-toggle matIconSuffix [for]="dueDate" />
            <mat-datepicker #dueDate />

            <button
              matIconSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="clearDueDate($event)"
              type="button"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <pr-date-selector
            *ngIf="taskFormGroup.controls.dueDate.value"
            placeholder="Due Time"
            [interval]="timeInterval"
            formControlName="dueTime"
            [timeFrom]="openTime$ | async"
            [timeTo]="closeTime$ | async"
          />
        </ng-container>
      </div>

      <mat-form-field fxFlex>
        <mat-label>Comments</mat-label>
        <pr-content-editor
          formControlName="description"
          placeholder="Add a Comment"
          [menuEnabled]="false"
          (metaKeydownEvent)="submit()"
        />
      </mat-form-field>

      <mat-slide-toggle
        color="primary"
        (change)="isRecurring$.next($event.checked)"
        [checked]="isRecurring"
      >
        Create recurring task
      </mat-slide-toggle>

      <ng-container *ngIf="isRecurring">
        <h3>Recurrence Configuration</h3>
        <div
          fxLayout="column"
          fxLayout.gt-sm="row"
          fxLayoutAlign.gt-sm="space-around center"
          fxLayoutGap="16px"
        >
          <mat-form-field fxFlex>
            <mat-label>Frequency</mat-label>
            <mat-select
              [required]="true"
              formControlName="recurringFrequency"
              (selectionChange)="setRecurrence($event)"
              [compareWith]="isSelectedFrequency"
            >
              <mat-option
                *ngFor="let frequency of frequencies; trackBy: trackByFrequency"
                [value]="frequency"
              >
                {{ frequency }}
              </mat-option>
            </mat-select>

            <button
              type="button"
              mat-icon-button
              matIconSuffix
              *ngIf="hasCustomFrequency$ | async"
              matTooltip="Edit custom settings"
              (click)="editCustomRecurrence($event)"
            >
              <mat-icon>settings</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>Start Date</mat-label>
            <input
              matInput
              [formControl]="startDateControl"
              (dateChange)="setStartDate($event)"
              [matDatepicker]="startDate"
              (focus)="startDate.open()"
              (click)="startDate.open()"
            />

            <mat-datepicker-toggle matIconSuffix [for]="startDate" />
            <mat-datepicker #startDate />

            <button
              matIconSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="clearStartDate($event)"
              type="button"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        @if (showDaysOfWeek$ | async) {
          <mat-form-field>
            <mat-label>On these days</mat-label>
            <mat-select
              multiple
              [formControl]="daysOfWeekCtrl"
              (selectionChange)="setDaysOfWeek($event.value)"
            >
              @for (day of daysOfWeek; track day) {
                <mat-option [value]="day">{{ day | titlecase }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        }

        <h3 class="mat-caption">Add due date</h3>
        <pr-recurring-task-due-date [form]="taskFormGroup" />
      </ng-container>
    </form>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button type="button" mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="taskFormGroup.invalid || saving"
      (click)="submit()"
    >
      Create
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
