import { IXSLXImport } from '@principle-theorem/shared';
import {
  EVENT_CLASS_TO_TREATMENT_CATEGORY_HEADERS,
  IEventClassToTreatmentCategoryXSLX,
} from './event-class-to-treatment-category-to-xslx';
import { Row } from 'exceljs';

export class XSLXToEventClassToTreatmentCategory
  implements IXSLXImport<IEventClassToTreatmentCategoryXSLX>
{
  headers = EVENT_CLASS_TO_TREATMENT_CATEGORY_HEADERS;

  translate(row: Row): IEventClassToTreatmentCategoryXSLX {
    return {
      label: row.getCell('label').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IEventClassToTreatmentCategoryXSLX['mapTo'],
    };
  }
}
