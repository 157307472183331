import { ISchedulingEventReasonDimension } from '@principle-theorem/reporting/interfaces';
import { BaseDimensionMeasures } from '../base-measures';
import {
  CanBeChartedProperty,
  CanDoAllProperty,
  MeasureTransformMap,
} from '../measure-properties';
import { MeasurePath } from '../data-accessor-factory';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { MeasureFormatter } from '@principle-theorem/principle-core/interfaces';

type RequiredProperties =
  | 'name'
  | 'description'
  | 'eventTypes'
  | 'hrsBeforeAppointment'
  | 'moveToSameDayDefault'
  | 'fillGapDefault'
  | 'metadataKey'
  | 'isSystemReason'
  | 'metadataDisplayRef'
  | 'ref';

export class SchedulingEventReasonDimensionMeasures
  extends BaseDimensionMeasures
  implements
    MeasureTransformMap<
      Pick<ISchedulingEventReasonDimension, RequiredProperties>
    >
{
  get name(): CanDoAllProperty {
    const name = this.measureRef('name');
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('name'),
        label: 'Reschedule/Cancellation Reason',
        summary: '',
        formatter: MeasureFormatter.Text,
      },
      docRef,
      this.buildQuery()
        .attributes([name.attributePath, docRef.attributePath])
        .get(),
      name,
      'None'
    );
  }

  get description(): CanDoAllProperty {
    const propertyName = 'description';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Description',
        summary:
          'A user defined reason of how and when this reason should be used.',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get metadataKey(): CanDoAllProperty {
    const propertyName = 'metadataKey';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Metadata Key',
        summary: `The key this reason is accumulated to under in the patient's metadata.`,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get ref(): CanBeChartedProperty {
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('ref'),
        label: 'Scheduling Event Reason Ref',
        summary: '',
      },
      docRef,
      this.buildQuery().attributes([docRef.attributePath]).get()
    );
  }

  get metadataDisplayRef(): CanBeChartedProperty {
    const docRef = this.measureRef(MeasurePath.docRef('metadataDisplayRef'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('metadataDisplayRef'),
        label: 'Metadata Display Ref',
        summary: '',
      },
      docRef,
      this.buildQuery().attributes([docRef.attributePath]).get()
    );
  }

  get moveToSameDayDefault(): CanDoAllProperty {
    const propertyName = 'moveToSameDayDefault';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.boolean(
      {
        id: propertyName,
        label: 'Move to Same Day Default',
        summary:
          'This reason is used as a default when an appointment is moved to the same day',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get fillGapDefault(): CanDoAllProperty {
    const propertyName = 'fillGapDefault';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.boolean(
      {
        id: propertyName,
        label: 'Gap Filling Default',
        summary: 'This reason is used as a default when filling a gap',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get isSystemReason(): CanDoAllProperty {
    const propertyName = 'isSystemReason';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.boolean(
      {
        id: propertyName,
        label: 'System Reason',
        summary:
          'This reason was provided by Principle. It cannot be edited or deleted.',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get hrsBeforeAppointment(): CanBeChartedProperty {
    const propertyName = 'hrsBeforeAppointment';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Hours Before Appointment',
        summary:
          'This reason is used as a default when an appointment is rescheduled within x hours of it being scheduled to start.',
        formatter: MeasureFormatter.Hours,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get eventTypes(): CanDoAllProperty {
    const measure = this.measureRef('eventTypes');
    return MeasurePropertyFactory.array(
      {
        id: this._pathWithPrefix('eventTypes'),
        label: 'Scheduling Event Types',
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      measure.factPropertyPath,
      (fact) => fact
    );
  }
}
