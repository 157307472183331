import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'pr-patient-forms-tab',
  templateUrl: './patient-forms-tab.component.html',
  styleUrls: ['./patient-forms-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex h-full w-full' },
  standalone: false,
})
export class PatientFormsTabComponent {}
