import {
  type BooleanInput,
  coerceBooleanProperty,
  coerceNumberProperty,
  NumberInput,
} from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ScoreClass, WaitListScore } from '@principle-theorem/principle-core';
import { BehaviorSubject, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

export type AppointmentScoreType =
  | 'appointmentSuggestion'
  | 'gapSuggestionWithWaitlist'
  | 'gapSuggestionWithoutWaitlist';

interface IAppointmentScoreTooltips {
  [ScoreClass.Low]: string;
  [ScoreClass.Medium]: string;
  [ScoreClass.High]: string;
}

const APPOINTMENT_SCORE_TOOLTIP_MAP: Record<
  AppointmentScoreType,
  Record<ScoreClass, string>
> = {
  appointmentSuggestion: {
    [ScoreClass.High]: 'This is a good choice',
    [ScoreClass.Medium]:
      'This choice is a compromise. The duration is shorter than required, or overlaps other events.',
    [ScoreClass.Low]:
      'This is a bad choice. The duration is far too short, or overlaps other events.',
  },
  gapSuggestionWithWaitlist: {
    [ScoreClass.High]:
      'High confidence. The duration is good, and waitlist preferences are a match.',
    [ScoreClass.Medium]:
      'Medium confidence. The duration or waitlist preferences may not be ideal.',
    [ScoreClass.Low]:
      'Low confidence. The duration and waitlist preferences are not ideal.',
  },
  gapSuggestionWithoutWaitlist: {
    [ScoreClass.High]: 'High confidence. The duration is good.',
    [ScoreClass.Medium]: 'Medium confidence. The duration may not be ideal.',
    [ScoreClass.Low]: 'Low confidence. The duration is not ideal.',
  },
};

@Component({
  selector: 'pr-appointment-score',
  templateUrl: './appointment-score.component.html',
  styleUrls: ['./appointment-score.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatTooltipModule, NgSharedModule],
})
export class AppointmentScoreComponent {
  score$ = new ReplaySubject<number>(1);
  vertical$ = new BehaviorSubject<boolean>(false);
  scoreClass$: Observable<ScoreClass>;
  scoreType$ = new ReplaySubject<AppointmentScoreType>(1);
  tooltips$: Observable<IAppointmentScoreTooltips>;

  @Input()
  set vertical(vertical: BooleanInput) {
    this.vertical$.next(coerceBooleanProperty(vertical));
  }

  @Input()
  set score(score: NumberInput) {
    this.score$.next(coerceNumberProperty(score));
  }

  @Input()
  set scoreType(scoreType: AppointmentScoreType) {
    if (scoreType) {
      this.scoreType$.next(scoreType);
    }
  }

  constructor() {
    this.scoreClass$ = this.score$.pipe(
      map((score) => WaitListScore.getScoreClass(score))
    );

    this.tooltips$ = this.scoreType$.pipe(
      map((scoreType) => APPOINTMENT_SCORE_TOOLTIP_MAP[scoreType])
    );
  }
}
