import {
  IDiscountExtendedData,
  isDiscountExtendedData,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ITransactionAction,
  ITransactionActionsData,
} from '../../transaction-action';
import {
  getRefundInfo$,
  getRefundLabel$,
  getRefundRemaining$,
} from '../../transaction-helpers';
import { DiscountTransactionProvider } from '../discount-transaction-provider.service';

export class RefundDiscountTransaction
  implements ITransactionAction<IDiscountExtendedData>
{
  icon = 'undo';
  label = 'Refund';
  inProgress$ = new BehaviorSubject<boolean>(false);
  typeGuardFn = isDiscountExtendedData;

  constructor(private _provider: DiscountTransactionProvider) {}

  canDo$(
    data: ITransactionActionsData<IDiscountExtendedData>
  ): Observable<boolean> {
    return getRefundRemaining$(data).pipe(
      map((remaining) => {
        const isIncoming = data.transaction.type === TransactionType.Incoming;
        const hasRemaining = remaining > 0;
        return isIncoming && hasRemaining;
      })
    );
  }

  label$(
    data: ITransactionActionsData<IDiscountExtendedData>
  ): Observable<string> {
    return getRefundLabel$(data);
  }

  info$(
    data: ITransactionActionsData<IDiscountExtendedData>
  ): Observable<string[]> {
    return getRefundInfo$(data);
  }

  async do(
    data: ITransactionActionsData<IDiscountExtendedData>
  ): Promise<void> {
    this.inProgress$.next(true);
    await this._provider.refundTransaction(
      data.invoice,
      data.latestTransaction
    );
    this.inProgress$.next(false);
  }
}
