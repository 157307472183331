import { MixedSchema } from '@principle-theorem/editor';
import { type IFirestoreModel } from '@principle-theorem/shared';

export interface INotable {
  notes: INote[];
}

export interface INote extends IFirestoreModel {
  uid: string;
  content: MixedSchema;
  pinned: boolean;
}
