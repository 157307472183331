import { DIALOG_SCROLL_STRATEGY } from '@angular/cdk/dialog';
import {
  CdkScrollable,
  Overlay,
  ScrollDispatcher,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Output,
  Provider,
  forwardRef,
  inject,
  type AfterViewInit,
} from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  type ControlValueAccessor,
  type ValidationErrors,
} from '@angular/forms';
import {
  DEFAULT_EXTENSIONS,
  InlineNodes,
  MixedSchema,
  RawSchema,
  getMixedContent,
  isMixedSchema,
  type Content,
} from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import {
  BasicDialogService,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { snapshot } from '@principle-theorem/shared';
import { AnyExtension, Editor } from '@tiptap/core';
import { isString, uniqBy } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { EditorBloc } from '../editor-bloc';
import { createImageMenu } from '../extensions/image/image-menu';
import { createLinkMenu } from '../extensions/link/link-menu';
import {
  DEFAULT_BLOCK_MENU_BUTTONS,
  DEFAULT_MENU_BUTTONS,
} from '../extensions/menu-buttons';
import { createTableMenu } from '../extensions/table/table-menu';
import { type IUploader } from '../extensions/uploader';
import { BasicMenuButtonComponent } from '../menu-bar/basic-menu-button/basic-menu-button.component';
import { createFormattingToolbarMenu } from '../menu-bar/formatting-toolbar/formatting-toolbar';
import {
  ActivateType,
  ISelectMenuData,
  selectMenuPlugin,
} from '../menu-bar/select-menu/select-menu-view';
import { createSlashMenu } from '../menu-bar/slash-menu/slash-menu';

export function DIALOG_SCROLL_STRATEGY_PROVIDER_FACTORY(
  overlay: Overlay
): () => ScrollStrategy {
  return () => overlay.scrollStrategies.close();
}

export const DIALOG_SCROLL_STRATEGY_PROVIDER: Provider = {
  provide: DIALOG_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: DIALOG_SCROLL_STRATEGY_PROVIDER_FACTORY,
};

@Component({
  selector: 'pt-editor',
  exportAs: 'ptEditor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EditorComponent),
      multi: true,
    },
    DIALOG_SCROLL_STRATEGY_PROVIDER,
  ],
})
export class EditorComponent
  implements AfterViewInit, OnDestroy, ControlValueAccessor
{
  private _document: Document = inject(DOCUMENT);
  private _onDestroy$: Subject<void> = new Subject();
  private _scrollable?: CdkScrollable;
  private _disabled$ = new BehaviorSubject<boolean>(false);
  private _changeFn?: (value: Content | MixedSchema) => void;

  menuItems$ = new BehaviorSubject<MenuButtonLoaderFn[]>(DEFAULT_MENU_BUTTONS);

  slashMenuItems$: BehaviorSubject<
    MenuButtonLoaderFn<BasicMenuButtonComponent>[]
  > = new BehaviorSubject(DEFAULT_BLOCK_MENU_BUTTONS);

  editorCtrl = new TypedFormControl<Content | RawSchema>();
  editorBloc: EditorBloc;
  @Input() menuEnabled = true;
  @Output() contentError: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private _cdr: ChangeDetectorRef,
    private _dialog: BasicDialogService,
    @Inject(DIALOG_SCROLL_STRATEGY)
    private _scrollStrategy: () => ScrollStrategy,
    private _scrollDispatcher: ScrollDispatcher,
    private _ngZone: NgZone
  ) {
    this.editorBloc = new EditorBloc(false, this._disabled$);

    this.editorBloc.contentError
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this.contentError.emit());

    this.editorCtrl.valueChanges
      .pipe(
        map((content) => content),
        takeUntil(this._onDestroy$)
      )
      .subscribe((value) => {
        if (this._changeFn) {
          this._changeFn(value);
        }
      });

    this.editorBloc.editor$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((editor) => {
        if (this._scrollable) {
          this._scrollDispatcher.deregister(this._scrollable);
        }

        const scrollableElement = getScrollParent(editor.view.dom);
        if (!scrollableElement) {
          return;
        }

        const elementRef = new ElementRef(scrollableElement);
        this._scrollable = new CdkScrollable(
          elementRef,
          this._scrollDispatcher,
          this._ngZone
        );

        this._scrollDispatcher.register(this._scrollable);
      });
  }

  @Input()
  set disabled(disabled: boolean) {
    this._disabled$.next(disabled);
  }

  @Input()
  set menuItems(menuItems: MenuButtonLoaderFn[]) {
    if (menuItems) {
      this.menuItems$.next(menuItems);
    }
  }

  @Input()
  set slashMenuItems(
    slashMenuItems: MenuButtonLoaderFn<BasicMenuButtonComponent>[]
  ) {
    if (slashMenuItems) {
      this.slashMenuItems$.next(slashMenuItems);
    }
  }

  @Input()
  set uploader(uploader: IUploader) {
    if (uploader) {
      this.editorBloc.uploader$.next(uploader);
    }
  }

  @Input()
  set extensions(extensions: AnyExtension[]) {
    if (extensions) {
      this.editorBloc.extensions$.next(
        uniqBy(
          [...extensions, ...DEFAULT_EXTENSIONS],
          (extension) => extension.name
        )
      );
    }
  }

  async ngAfterViewInit(): Promise<void> {
    this._cdr.detectChanges();
    const editor = await snapshot(this.editorBloc.editor$);

    this._registerMenuPlugin(editor, createLinkMenu(), 'focus');
    this._registerMenuPlugin(editor, createImageMenu(editor), 'focus');
    this._registerMenuPlugin(editor, createTableMenu(editor), 'focus');
    this._registerMenuPlugin(
      editor,
      createFormattingToolbarMenu(editor, await snapshot(this.menuItems$)),
      'selection'
    );
    this._registerMenuPlugin(
      editor,
      createSlashMenu(editor, await snapshot(this.slashMenuItems$)),
      'focus'
    );
  }

  async ngOnDestroy(): Promise<void> {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    if (this._scrollable) {
      this._scrollDispatcher.deregister(this._scrollable);
    }
    this.editorBloc.destroy();
    const editor = await snapshot(this.editorBloc.editor$);
    editor.unregisterPlugin(`${InlineNodes.Link}-select-menu`);
  }

  writeValue(content: Content | MixedSchema): void {
    if (content || isString(content)) {
      this.editorCtrl.setValue(
        isString(content)
          ? content
          : isMixedSchema(content)
            ? getMixedContent(content)
            : content
      );
      this.editorBloc.content$.next(content);
    }
  }

  registerOnChange(fn: (value: Content | MixedSchema) => void): void {
    this._changeFn = fn;
  }

  registerOnTouched(_fn: () => void): void {
    // throw new Error('Method not implemented.');
  }

  validate(control: TypedFormControl<unknown>): ValidationErrors {
    if (!control.value) {
      return { required: true };
    }
    return {};
  }

  private _registerMenuPlugin(
    editor: Editor,
    component: ISelectMenuData,
    activateType: ActivateType
  ): void {
    editor.registerPlugin(
      selectMenuPlugin({
        pluginKey: component.pluginKey,
        editor,
        element: this._document.createElement('span'),
        shouldShowOverride: component.shouldShow,
        nodeType: component.nodeType,
        dialog: this._dialog,
        scrollStrategy: this._scrollStrategy,
        component,
        activateType,
      })
    );
  }
}

function getScrollParent(node: HTMLElement | null): HTMLElement | undefined {
  if (!node) {
    return;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentNode as HTMLElement);
  }
}
