<span
  *ngIf="editable$ | async; else readOnly"
  [ptEditorAutocomplete]="autoSnippet"
  #autoComplete="ptEditorAutocompleteTrigger"
  [editorContentValue]="content$ | async"
  [matAutocompleteDisabled]="disabled$ | async"
>
  <span class="angular-component-with-content">
    <span tiptapNodeViewContent></span>
  </span>
</span>
<mat-autocomplete
  #autoSnippet="matAutocomplete"
  [autoActiveFirstOption]="true"
  [panelWidth]="suggestionWidth"
  (optionSelected)="selectSnippet($event)"
>
  <ng-container *ngIf="searchFilter.results$ | async as groups">
    <ng-container *ngFor="let group of groups; trackBy: trackByGroup">
      <mat-optgroup *ngIf="group.options.length" [label]="group.name">
        <mat-option
          *ngFor="let option of group.options; trackBy: trackBySnippet"
          [value]="option"
        >
          {{ option.name }}
        </mat-option>
      </mat-optgroup>
    </ng-container>
  </ng-container>
</mat-autocomplete>

<ng-template #readOnly>
  <span class="angular-component-with-content">
    <span tiptapNodeViewContent></span>
  </span>
</ng-template>
