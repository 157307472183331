import { IBaseMigrationItemCode } from '../../../interfaces';
import { BaseItemCodeToTreatmentMappingHandler } from '../../../mappings/base-item-code-to-treatment';
import {
  IOasisItemCode,
  ItemCodeSourceEntity,
} from '../../source/entities/item-codes';

export class OasisItemCodeToTreatmentMappingHandler extends BaseItemCodeToTreatmentMappingHandler<
  IOasisItemCode,
  ItemCodeSourceEntity
> {
  sourceEntity = new ItemCodeSourceEntity();
  translateFn = (record: IOasisItemCode): IBaseMigrationItemCode => ({
    id: record.id,
    itemCode: record.id,
    description: record.description,
  });
}
