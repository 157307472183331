import {
  IPricingRule,
  IPricingRuleProvider,
  PricingRuleType,
} from '@principle-theorem/principle-core/interfaces';
import { first } from 'lodash';

export class StairStepPricingProvider implements IPricingRuleProvider {
  canProvide(rule: IPricingRule): boolean {
    return rule.type === PricingRuleType.StairStep;
  }

  determinePrice(
    pricingRule: IPricingRule,
    _unitCount: number,
    _unitIndex: number
  ): number | undefined {
    const selectedRule =
      pricingRule.ruleItems.find((rule) => rule.selected) ??
      first(pricingRule.ruleItems);
    return selectedRule?.price;
  }
}
