import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PACKAGE_MAP } from '../../models/report/charts/chart-builder';

@Injectable({
  providedIn: 'root',
})
export class GoogleChartsService {
  loaded: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor() {
    void this.load(this._requiredPackages());
  }

  async load(packages: string[]): Promise<void> {
    await google.charts.load('current', { packages });
    google.charts.setOnLoadCallback(() => this.loaded.next(packages));
  }

  hasLoaded(packages: string[]): boolean {
    return packages.every((pkgName: string) => {
      return this.loaded.value.includes(pkgName);
    });
  }

  private _requiredPackages(): string[] {
    const all: string[] = [];
    for (const key in PACKAGE_MAP) {
      const packages: string[] = PACKAGE_MAP[key] || [];
      packages.map((item: string) => {
        if (!all.includes(item)) {
          all.push(item);
        }
      });
    }
    return all;
  }
}
