import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import { FollowUpsService } from '../../follow-ups.service';
import { map } from 'rxjs/operators';
import {
  DATE_FORMAT,
  ISO_DATE_FORMAT,
  OrderByDirection,
  filterUndefined,
  getEnumValues,
  toMoment,
} from '@principle-theorem/shared';
import { difference, groupBy, orderBy } from 'lodash';
import {
  InputSearchFilter,
  TypedFormControl,
  formControlChanges$,
  toSearchStream,
} from '@principle-theorem/ng-shared';
import { IPendingFollowUp } from '../../pending-follow-up';

export interface IGroupedFollowUp {
  key: string;
  followUps: IPendingFollowUp[];
}

export enum FollowUpSortBy {
  Date = 'date',
  LastContacted = 'lastContacted',
  Category = 'treatmentCategory',
}

@Component({
    selector: 'pr-follow-up-list-view',
    templateUrl: './follow-up-list-view.component.html',
    styleUrl: './follow-up-list-view.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FollowUpListViewComponent {
  followUps$ = new ReplaySubject<IPendingFollowUp[]>(1);
  followUpsByDate$: Observable<IGroupedFollowUp[]>;
  followUpsByLastContacted$: Observable<IPendingFollowUp[]>;
  followUpsByCategory$: Observable<IGroupedFollowUp[]>;
  sortOrder$ = new BehaviorSubject<OrderByDirection>('asc');
  sortBy$: Observable<FollowUpSortBy>;
  sortTooltip$: Observable<string>;
  noResults$: Observable<boolean>;
  sortByCtrl = new TypedFormControl<FollowUpSortBy>(FollowUpSortBy.Date);
  searchCtrl = new TypedFormControl<string>('');
  searchFilter: InputSearchFilter<IPendingFollowUp>;
  sortByOptions = getEnumValues(FollowUpSortBy);
  dateFormat = DATE_FORMAT;

  @Input()
  set followUps(followUps: IPendingFollowUp[]) {
    if (followUps) {
      this.followUps$.next(followUps);
    }
  }

  constructor(public service: FollowUpsService) {
    this.searchFilter = new InputSearchFilter<IPendingFollowUp>(
      this.followUps$,
      toSearchStream(this.searchCtrl),
      [
        'patient.name',
        'appointment.treatmentPlan.name',
        'appointment.treatmentPlan.treatmentStep.name',
        'treatmentCategory.name',
      ]
    );

    this.noResults$ = this.searchFilter.results$.pipe(
      map((results) => !results.length)
    );

    this.followUpsByDate$ = combineLatest([
      this.searchFilter.results$,
      this.sortOrder$,
    ]).pipe(
      map(([followUps, sortOrder]) =>
        orderBy(this._groupByDate(followUps), (group) => group.key, sortOrder)
      )
    );

    this.followUpsByLastContacted$ = combineLatest([
      this.searchFilter.results$,
      this.sortOrder$,
    ]).pipe(
      map(([followUps, sortOrder]) => {
        const notContacted = followUps.filter(
          (followUp) => !followUp.lastContactedAt
        );
        const contacted = difference(followUps, notContacted);
        const sorted = orderBy(
          contacted,
          (followUp) => followUp.lastContactedAt,
          sortOrder
        );

        return sortOrder === 'asc'
          ? [...sorted, ...notContacted]
          : [...notContacted, ...sorted];
      })
    );

    this.followUpsByCategory$ = combineLatest([
      this.searchFilter.results$,
      this.sortOrder$,
    ]).pipe(
      map(([followUps, sortOrder]) =>
        orderBy(
          this._groupByCategory(followUps),
          (followUp) => followUp.key,
          sortOrder
        )
      )
    );

    this.sortTooltip$ = this.sortOrder$.pipe(
      map((sortOrder) =>
        sortOrder === 'asc' ? 'Sort Descending' : 'Sort Ascending'
      )
    );
    this.sortBy$ = formControlChanges$(this.sortByCtrl).pipe(filterUndefined());
  }

  toggleSortOrder(): void {
    this.sortOrder$.next(this.sortOrder$.value === 'asc' ? 'desc' : 'asc');
  }

  private _groupByCategory(followUps: IPendingFollowUp[]): IGroupedFollowUp[] {
    const groups = groupBy(followUps, (followUp) =>
      followUp.treatmentCategory
        ? followUp.treatmentCategory.name
        : 'Uncategorised'
    );

    return Object.entries(groups).map(([key, value]) => ({
      key,
      followUps: value,
    }));
  }

  private _groupByDate(followUps: IPendingFollowUp[]): IGroupedFollowUp[] {
    const groups = groupBy(followUps, (followUp) =>
      followUp.appointment.activeFollowUp?.followUpDate
        ? toMoment(followUp.appointment.activeFollowUp.followUpDate).format(
            ISO_DATE_FORMAT
          )
        : 'No Date Set'
    );

    return Object.entries(groups).map(([key, value]) => ({
      key,
      followUps: value,
    }));
  }
}
