import { ManualTransactionType } from '@principle-theorem/principle-core';
import {
  CustomMappingAssociatedValueType,
  CustomMappingOption,
  CustomMappingType,
  ICustomMapping,
  ICustomMappingSourceOption,
  IManualTransactionType,
  IPracticeMigration,
  TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import {
  INamedDocument,
  IReffable,
  WithRef,
  getEnumValues,
  multiMap,
  toNamedDocument,
} from '@principle-theorem/shared';
import { sortBy, upperFirst } from 'lodash';
import { Observable, of } from 'rxjs';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import { PaymentTypeSourceEntity } from '../../source/entities/payment-types';

export const PAYMENT_TYPE_CUSTOM_MAPPING_TYPE = 'paymentTypeMapping';

export const PAYMENT_TYPE_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Payment Type Mapping',
    description: `Used to map Core Practice Payment Types to Principle Transaction Types. Any unmapped payment types will be assigned to a Manual Transaction type.`,
    type: PAYMENT_TYPE_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.SelectionList,
});

export class CorePracticePaymentTypeMappingHandler extends BaseCustomMappingHandler<
  object,
  TransactionProvider,
  INamedDocument<IManualTransactionType>
> {
  customMapping = PAYMENT_TYPE_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const paymentTypes = new PaymentTypeSourceEntity();
    const records = await paymentTypes.getRecords$(migration, 1000).toPromise();
    return sortBy(
      records
        .map((record) => record.data.data)
        .map((record) => ({
          label: record.name,
          value: record.id.toString(),
        })),
      'label'
    );
  }

  async getSelectionListOptions(
    _migration: WithRef<IPracticeMigration>
  ): Promise<CustomMappingOption[]> {
    return of([
      {
        value: '',
        description: '',
        hasAssociatedValue: false,
        associatedValueType: CustomMappingAssociatedValueType.String,
        associatedValueDescription: '',
      },
      ...getEnumValues(TransactionProvider).map((provider) => ({
        value: provider,
        description: upperFirst(provider),
        hasAssociatedValue:
          provider === TransactionProvider.Manual ? true : false,
        associatedValueType: CustomMappingAssociatedValueType.NamedDocument,
        associatedValueDescription: 'Select an Manual Transaction Subtype',
      })),
    ]).toPromise();
  }

  getAssociatedValueOptions$(
    migration: IPracticeMigration,
    destinationValue: TransactionProvider
  ): Observable<INamedDocument<IManualTransactionType>[]> {
    if (destinationValue !== TransactionProvider.Manual) {
      return of([]);
    }

    return ManualTransactionType.all$(migration.configuration.brand).pipe(
      multiMap(toNamedDocument)
    );
  }
}
