import { type RawSchema } from '@principle-theorem/editor';
import { type IReffable, type WithRef } from '@principle-theorem/shared';
import {
  type INotification,
  type INotificationContextRenderer,
  renderNotification,
} from '@principle-theorem/notifications';
import { type Observable, type OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export function hasProvider$(
  providers: INotificationContextRenderer<RawSchema>[],
  user: IReffable
): OperatorFunction<WithRef<INotification>, boolean> {
  return (source$: Observable<WithRef<INotification>>) =>
    source$.pipe(
      map((notification) => renderNotification(notification, providers, user)),
      map((result) => result !== undefined)
    );
}
