<div *ngIf="step$ | async as step" class="container flex flex-col gap-2">
  <div class="flex items-center justify-between gap-4">
    <input
      class="custom-input-default min-w-0 basis-10/12"
      autocomplete="off"
      type="text"
      placeholder="Step Name"
      [formControl]="nameControl"
    />

    <div class="flex shrink-0 basis-2/12 items-center justify-between gap-1.5">
      <pr-treatment-step-category-indicator
        [treatmentStepDisplay]="step.display"
        (stepDisplayChange)="stepDisplayChange.emit($event)"
      />
      <div class="flex items-center justify-between gap-1">
        <ng-container *ngIf="canManageStep$ | async">
          <button
            mat-icon-button
            [ngClass]="{
              'has-items': (checklistItemCount$ | async) || 0 > 0
            }"
            [disabled]="isComplete$ | async"
            [matTooltip]="
              checklistItemCount$ | async | map: getChecklistItemsTooltip
            "
            (click)="addChecklistItem()"
          >
            <mat-icon>playlist_add</mat-icon>
          </button>

          <button
            mat-icon-button
            [ngClass]="{
              'has-items': (automationCount$ | async) || 0 > 0
            }"
            [matTooltip]="automationCount$ | async | map: getAutomationsTooltip"
            (click)="manageAutomations()"
          >
            <mat-icon>smart_toy</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="isIncomplete$ | async">
          <pr-add-charted-treatment-button
            [connectedTo]="elementRef"
            [inlcudeMultiTreatments]="inlcudeMultiTreatments"
            (addChartable)="
              addChartable($event.chartable, $event.selectedSurfaces)
            "
          />
        </ng-container>
        <ng-content />
        <button mat-icon-button [matMenuTriggerFor]="treatmentStepMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #treatmentStepMenu="matMenu">
          <ng-template matMenuContent>
            <button
              *ngIf="claimEstimateProvider.isEnabled$ | async"
              mat-menu-item
              (click)="getClaimEstimate()"
            >
              <mat-icon>request_quote</mat-icon>
              Get a Claim Estimate
            </button>
            <button mat-menu-item (click)="openEditStepDialog(step)">
              <mat-icon>edit</mat-icon>
              Manage Scheduling
            </button>
            <button
              *ngIf="step.treatments.length"
              mat-menu-item
              [disabled]="step | map: selectAllDisabled"
              (click)="selectAllTreatments(step)"
            >
              <mat-icon>checklist</mat-icon>
              Select All Treatments
            </button>
            <button
              *ngIf="!hideDelete"
              mat-menu-item
              [disabled]="step | map: deleteDisabled"
              (click)="stepDeleted.emit(step)"
            >
              <mat-icon color="warn">delete</mat-icon>
              Delete Step
            </button>
          </ng-template>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="second-line flex gap-4">
    <ng-container *ngIf="canChangeDuration$ | async; else appointmentInfo">
      <div class="flex flex-col gap-1">
        <span class="mat-caption hint">Duration</span>
        <div class="duration flex flex-wrap items-center gap-1">
          <input
            class="custom-input-default w-14"
            autocomplete="off"
            type="number"
            min="0"
            maxlength="3"
            [formControl]="durationControl"
            placeholder="{{ durationPlaceholder$ | async }}"
          />
          <label class="mat-caption">mins</label>
        </div>
      </div>
      <div class="flex flex-auto">
        <div class="flex flex-col gap-1">
          <span class="mat-caption hint">Unscheduled</span>
          <button
            *ngIf="canSchedule$ | async"
            mat-flat-button
            color="primary"
            class="dense"
            matTooltip="Allow Front Desk to book this in"
            (click)="generateAppointment()"
          >
            Enable Scheduling
          </button>
          <button
            *ngIf="canDisable$ | async"
            mat-stroked-button
            class="dense"
            matTooltip="Don't allow Front Desk to book this in"
            (click)="removeAppointment()"
          >
            Disable Scheduling
          </button>
        </div>
      </div>
      <div class="flex flex-col items-center gap-1">
        <span class="mat-caption hint">Provider</span>
        <ng-container [ngTemplateOutlet]="practitionerSelector" />
      </div>
      <ng-container [ngTemplateOutlet]="priceAndInvoice" />
    </ng-container>
    <ng-template #appointmentInfo>
      <ng-container
        *ngIf="appointment$ | async as appointment; else stepCompleted"
      >
        <div class="flex flex-col gap-1">
          <span class="mat-caption hint">Duration</span>
          <p class="mat-caption">
            {{ appointment | map: appointmentDuration }}
          </p>
        </div>
        <div class="flex flex-auto flex-col gap-1">
          <span [ngClass]="status" class="mat-caption">
            {{ status | splitCamel | titlecase }}
          </span>
          <p class="mat-caption">
            {{ appointment | map: appointmentDate }}
          </p>
        </div>
        <div class="flex flex-col items-center gap-1">
          <span class="mat-caption hint">Provider</span>
          <ng-container [ngTemplateOutlet]="practitionerSelector" />
        </div>
        <ng-container [ngTemplateOutlet]="priceAndInvoice" />
      </ng-container>
    </ng-template>

    <ng-template #stepCompleted>
      <div
        class="flex flex-col gap-1"
        *ngIf="appointment$ | async as appointment"
      >
        <span class="mat-caption hint">Duration</span>
        <p class="mat-caption">
          {{ appointment | map: appointmentDuration }}
        </p>
      </div>
      <div class="flex flex-auto flex-col gap-1">
        <span class="mat-caption completed">Completed</span>
      </div>
      <div class="flex flex-col items-center gap-1">
        <span class="mat-caption hint">Provider</span>
        <ng-container [ngTemplateOutlet]="practitionerSelector" />
      </div>
      <ng-container [ngTemplateOutlet]="priceAndInvoice" />
    </ng-template>
  </div>
</div>

<ng-template #practitionerSelector>
  <pr-assignee-profile-selector
    [staff]="organisation.practicePractitioners$ | async"
    selectTooltip="Select Appointment Provider"
    clearTooltip="Clear Appointment Provider"
    [selectedStaffer]="practitioner$ | async"
    [disabled]="(canChangePractitioner$ | async) === false"
    (stafferSelected)="patchStep({ practitionerRef: $event?.ref })"
  />
</ng-template>

<ng-template #priceAndInvoice>
  <div class="flex flex-col items-center gap-1">
    <span class="mat-caption hint">
      <span
        *ngIf="invoice$ | async as invoice; else noInvoice"
        class="invoice-status"
        [ngClass]="invoice.status"
      >
        {{ invoice.status | splitCamel | titlecase }}
      </span>
      <ng-template #noInvoice>Not Invoiced</ng-template>
    </span>
    <div class="mat-caption price">{{ price$ | async | currency }}</div>
  </div>
</ng-template>
