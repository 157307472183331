import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgClinicalChartingModule } from '@principle-theorem/ng-clinical-charting';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgIntercomComponentsModule } from '@principle-theorem/ng-intercom';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ComponentsModule } from '../../../../components/components.module';
import { AccountGeneralComponent } from './account-general/account-general.component';
import { AccountNotificationsComponent } from './account-notifications/account-notifications.component';
import { AccountSnippetsListComponent } from './account-snippets-list/account-snippets-list.component';
import { StafferAccountResolverService } from './staffer-account-resolver.service';
import { StafferConditionConfigurationCollectionResolverService } from './staffer-condition-configuration-collection-resolver.service';
import { StafferMultiTreatmentConfigurationCollectionResolverService } from './staffer-multi-treatment-configuration-collection-resolver.service';
import { StafferTreatmentConfigurationCollectionResolverService } from './staffer-treatment-configuration-collection-resolver.service';

@NgModule({
  imports: [
    NgMaterialModule,
    ReactiveFormsModule,
    NgSharedModule,
    RouterModule,
    ComponentsModule,
    NgInteractionsModule,
    NgFeatureFlagsModule,
    NgClinicalChartingModule,
    NgPrincipleSharedModule,
    NgIntercomComponentsModule,
  ],
  exports: [],
  declarations: [
    AccountSnippetsListComponent,
    AccountNotificationsComponent,
    AccountGeneralComponent,
  ],
  providers: [
    StafferConditionConfigurationCollectionResolverService,
    StafferTreatmentConfigurationCollectionResolverService,
    StafferMultiTreatmentConfigurationCollectionResolverService,
    StafferAccountResolverService,
  ],
})
export class AccountModule {}
