import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  filterUndefined,
  firstResult$,
  type WithRef,
} from '@principle-theorem/shared';
import {
  CurrentScopeFacade,
  OrganisationService,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { BaseResolver } from '@principle-theorem/ng-shared';
import { type IBrand } from '@principle-theorem/principle-core/interfaces';
import { from, type Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { where } from '@principle-theorem/shared';

@Injectable()
export class BrandResolverService extends BaseResolver<
  WithRef<IBrand> | undefined
> {
  constructor(
    protected _router: Router,
    private _organisation: OrganisationService,
    private _stateNav: StateBasedNavigationService,
    private _currentScopeFacade: CurrentScopeFacade
  ) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<WithRef<IBrand> | undefined> {
    const slug: string = route.paramMap.get('brand-slug') || '';

    return this._organisation.brandCol$.pipe(
      filterUndefined(),
      switchMap((brandCol) =>
        firstResult$(brandCol, where('slug', '==', slug))
      ),
      switchMap((foundBrand) => {
        if (!foundBrand) {
          return from(this._stateNav.organisation()).pipe(map(() => undefined));
        }
        this._currentScopeFacade.setBrandFromResolver(foundBrand);
        return of(foundBrand);
      }),
      take(1)
    );
  }
}
