import { createSelector } from '@ngrx/store';
import { type IChartState } from '../reducers/reducers';
import { getChartModuleState } from '../selectors';
import { type SerialisedData } from '@principle-theorem/shared';
import { feeScheduleAdapter } from '../reducers/fee-schedule.reducer';

const { selectAll } = feeScheduleAdapter.getSelectors();

export const selectFeeScheduleState = createSelector(
  getChartModuleState,
  (chart) => chart.feeSchedule
);

export const getFeeScheduleGroups = createSelector(
  selectFeeScheduleState,
  (feeSchedules) => feeSchedules.groups
);

export const getFeeSchedules = createSelector(
  selectFeeScheduleState,
  selectAll
);

const getSelectedFeeScheduleId = createSelector(
  selectFeeScheduleState,
  (config) => config.selectedFeeScheduleId
);

export const getSelectedFeeSchedule = createSelector(
  selectFeeScheduleState,
  getSelectedFeeScheduleId,
  (entities, uid) => (uid ? entities.entities[uid] : undefined)
);

export const getFeeScheduleEntities = createSelector(
  getChartModuleState,
  feeScheduleAdapter.getSelectors(
    (state: SerialisedData<IChartState>) => state.feeSchedule
  ).selectEntities
);
