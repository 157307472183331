import { TaxStrategy } from '@principle-theorem/accounting';
import { Region, type AtLeast } from '@principle-theorem/shared';
import { ChartableSurface } from '../../core/chartable-surface';
import {
  type IServiceCode,
  type IServiceProvider,
  type ItemCodeAliases,
  ServiceCodeType,
} from '../../service-provider/service-item';
import { ServiceCodeScope } from './ada-code';

export const BASE_ADA_CODES: Record<
  string,
  AtLeast<IServiceCode, 'code' | 'title' | 'description' | 'taxStatus'>
> = {
  '011': {
    code: '011',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Comprehensive oral examination',
    description:
      'A comprehensive evaluation and recording of the current status of the dentition, mouth and associated structures performed on a patient. This applies to new patients; established patients who have had a significant change in health conditions or established patients who have been absent from active treatment for two or more years. This may require interpretation of information acquired through additional diagnostic procedures reported and itemised separately. This evaluation includes recording an appropriate oral and medical history and any other relevant information.',
    rules: {},
    fee: 67,
  },
  '012': {
    code: '012',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Periodic oral examination',
    description: `An evaluation of the dentition, mouth and associated structures performed on a patient of record to determine any changes in the patient's oral and medical health status since a previous comprehensive or periodic examination. This may require interpretation of information acquired through additional diagnostic procedures reported and itemised separately.`,
    rules: {},
    fee: 60,
  },
  '013': {
    code: '013',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Oral examination – limited',
    description:
      'A limited evaluation of the dentition, mouth and associated structures performed on a patient. This may be for a specific oral health problem or complaint. This may require interpretation of information acquired through additional diagnostic procedures reported and itemised separately. This evaluation includes recording an appropriate oral and medical history and any other relevant information.',
    rules: {},
    fee: 53,
  },
  '014': {
    code: '014',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Consultation',
    description:
      'A consultation to seek advice or discuss treatment options regarding a specific dental or oral condition..This consultation includes recording an appropriate medical history and any other relevant information.',
    rules: {
      optionalRelevantCodes: [15],
    },
    fee: 67,
  },
  '015': {
    code: '015',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Consultation – extended (30 minutes or more)',
    description:
      'An extended consultation to seek advice or discuss treatment options regarding a specific dental or oral complaint. This consultation includes recording an appropriate medical history and any other relevant information.',
    rules: {
      scope: ServiceCodeScope.AppointmentTime,
      scopeValue: 30,
      optionalRelevantCodes: [14],
    },
    fee: 101,
  },
  '016': {
    code: '016',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Consultation by referral',
    description:
      'A consultation with a patient referred by a dental or medical practitioner for an opinion or management of a specific dental disorder. The consultation may not necessarily be with a specialist. The referring practitioner should be provided with a report from the consultant, included within the item number.',
    rules: {
      optionalRelevantCodes: [17],
    },
  },
  '017': {
    code: '017',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Consultation by referral – extended (30 minutes or more)',
    description:
      'An extended consultation with a patient referred by a dental or medical practitioner for an opinion or management of a specific dental disorder. The consultation may not necessarily be with a specialist..The referring practitioner should be provided with a report from the consultant, included within the item number.',
    rules: {
      scope: ServiceCodeScope.AppointmentTime,
      scopeValue: 30,
      optionalRelevantCodes: [16],
    },
  },
  '018': {
    code: '018',
    taxStatus: TaxStrategy.GSTApplicable,
    title: 'Written report (not elsewhere included)',
    description: `A written report not addressed to a referring practitioner involved in the patient's care.`,
    rules: {},
    fee: 140,
  },
  '019': {
    code: '019',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Letter of referral',
    description:
      'The preparation of correspondence referring a patient to another practitioner and providing appropriate information.',
    rules: {},
  },
  '022': {
    code: '022',
    scope: [ChartableSurface.WholeMouth],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Intraoral radiograph – per exposure #',
    description: 'Taking and interpreting an intraoral radiograph',
    rules: {
      scope: ServiceCodeScope.PerExposure,
    },
    fee: 45,
  },
  '024': {
    code: '024',
    scope: [ChartableSurface.WholeMouth],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Intraoral PA or B/W radiograph - each subs. Exposure (same day)',
    description:
      'Intraoral PA or B/W radiograph - each subs. Exposure (same day) - Victoria Only',
    rules: {
      scope: ServiceCodeScope.PerExposure,
    },
  },
  '025': {
    code: '025',
    scope: [ChartableSurface.WholeMouth, ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTPossible,
    title:
      'Intraoral radiograph – occlusal, maxillary, mandibular – per exposure',
    description:
      'Taking and interpreting an occlusal, maxillary or mandibular intraoral radiograph. This radiograph shows a more extensive view of teeth and maxillary or mandibular bone.',
    rules: {
      scope: ServiceCodeScope.PerExposure,
    },
    deleted: true,
  },
  '026': {
    code: '026',
    taxStatus: TaxStrategy.GSTPossible,
    title:
      'Cone Beam Computed Tomography – scan acquisition – per appointment #',
    description: `Acquisition of a Cone Beam Computed Tomography (CBCT) scan and the associated images. Reconstructed images from this scan are included in this item number. For interpretation and/or analysis, see items 087, 088, 089, 090, 091. CBCT is also referred to as 'CBVT'.`,
    rules: {
      scope: ServiceCodeScope.PerAppointment,
      optionalRelevantCodes: [87, 88, 89, 90, 91],
    },
  },
  '031': {
    code: '031',
    scope: [ChartableSurface.WholeMouth, ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Extraoral radiograph – maxillary, mandibular – per exposure',
    description:
      'Taking and interpreting a radiograph of the upper and/or lower jaw using a film placed outside the mouth – for example, oblique lateral radiograph.',
    rules: {
      scope: ServiceCodeScope.PerExposure,
    },
  },
  '033': {
    code: '033',
    taxStatus: TaxStrategy.GSTPossible,
    title:
      'Lateral, antero-posterior, postero-anterior or submento-vertex radiograph of the skull – per exposure #',
    description:
      'Taking and interpreting an extraoral radiograph of the head taken from the lateral, antero-posterior, posteroanterior or submento-vertex of the skull. No cephalostat is used.',
    rules: {
      scope: ServiceCodeScope.PerExposure,
    },
  },
  '035': {
    code: '035',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Radiograph of temporomandibular joint – per exposure',
    description:
      'Taking and interpreting a radiograph of the temporomandibular joint.',
    rules: {
      scope: ServiceCodeScope.PerExposure,
    },
  },
  '036': {
    code: '036',
    taxStatus: TaxStrategy.GSTPossible,
    title:
      'Cephalometric radiograph – lateral, antero-posterior, postero-anterior or submento-vertex – per exposure #.',
    description:
      'Taking and interpreting a radiograph of the head taken from the side, the front or the back or through the vertex of the skull for the purpose of measurement and analysis. A cephalostat is used to standardise the conditions of imaging.',
    rules: {
      scope: ServiceCodeScope.PerExposure,
    },
  },
  '037': {
    code: '037',
    scope: [ChartableSurface.WholeMouth],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Panoramic radiograph – per exposure',
    description:
      'Taking and interpreting an extraoral radiograph presenting a panoramic view of part or all of the mandible and/.or maxilla and/or adjacent structures.',
    rules: {
      scope: ServiceCodeScope.PerExposure,
    },
    fee: 105,
  },
  '038': {
    code: '038',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Hand–wrist radiograph for skeletal age assessment',
    description:
      'Taking and interpreting a radiograph of the wrist bones, used to assess the degree of skeletal development and maturity.',
    rules: {},
  },
  '039': {
    code: '039',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Computed tomography of the skull or parts thereof',
    description:
      'A radiograph is made of a region using tomographic X-ray equipment that may produce a series of diagnostic images. A cephalostat may be used to standardise the conditions of imaging (for interpretation and/or analysis, see 083). This procedure describes multiple images and should be itemised once only per CT scan',
    rules: {
      optionalRelevantCodes: [83],
    },
  },
  '041': {
    code: '041',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Bacteriological examination',
    description:
      'An examination for bacteria or other microorganisms in samples obtained from the patient.',
    rules: {},
  },
  '042': {
    code: '042',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Culture examination and identification',
    description:
      'A sample is taken from oral material and is cultured for examination and identification of the microorganisms present.',
    rules: {},
  },
  '043': {
    code: '043',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Antibiotic sensitibity test',
    description:
      'A labratory procedure where a live culture of a suspected pathogen is challenged with antibiotic samples to determine the sensitivity or sesistance of the microorganisms to the antibiotics tested.',
    rules: {},
  },
  '044': {
    code: '044',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Collection of specimen for pathology examination',
    description:
      'The non-surgical collection and preparation for transport of a specimen for examination at a pathology laboratory.',
    rules: {},
  },
  '047': {
    code: '047',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Saliva screening test',
    description:
      'Testing a saliva sample to assess its physiological properties',
    rules: {},
    fee: 56,
  },
  '048': {
    code: '048',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Microbiological screening test',
    description: 'Microbiological assessment of a sample.',
    rules: {},
  },
  '051': {
    code: '051',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Biopsy of tissue',
    description:
      'A surgical procedure to obtain a sample of tissue for pathological examination',
    rules: {},
  },
  '052': {
    code: '052',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Histopathological examination of tissue',
    description:
      'A histopathological examination to determine the disease status of a section of tissue.',
    rules: {},
  },
  '053': {
    code: '053',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Cytological investigation',
    description:
      'A microscopic examination of cells obtained from a smear or in situ staining of a suspected lesion to determine any aberrant characteristics that may suggest that the lesion is malignant or related to some other condition.',
    rules: {},
  },
  '054': {
    code: '054',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Oral mucosal screening',
    description:
      'A non-invasive test for screening oral mucosa for oral cancer, potentially malignant lesions and/or other conditions. This may involve the use of adjunctive diagnostic devices and techniques including white light reflectance, reflectance spectroscopy or auto-fluorescence.',
    rules: {},
    deleted: true,
  },
  '055': {
    code: '055',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Blood collection',
    description:
      'The collection of blood for diagnostic and/or treatment purposes.',
    rules: {},
  },
  '056': {
    code: '056',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Haematological examination',
    description: 'The haematological examination of a blood sample. ',
    rules: {},
  },
  '057': {
    code: '057',
    taxStatus: TaxStrategy.GSTFree,
    title: `Procedures to derive biologically active components from a collection of blood`,
    description: `Deriving biologically active components for patient treatment by processing blood collected from the patient.`,
    rules: {},
  },
  '059': {
    code: '059',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Comprehensive head and neck cancer examination and risk assessment',
    description:
      'A specific comprehensive examination that consists of visual inspection and manual palpation of extraoral head and neck structures and intraoral soft tissues, and may involve indirect laryngoscopy or fibre-optic pharyngolaryngoscopy. This includes a detailed patient risk assessment.',
    rules: {},
  },
  '061': {
    code: '061',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Pulp testing – per appointment',
    description:
      'A non-invasive test used to determine the clinical status of the pulp. It involves the application of external stimulation to the pulp to determine its response. Other teeth are generally tested for comparison. This procedure should be itemised only once for each appointment when pulp testing is performed.',
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
    fee: 31,
  },
  '071': {
    code: '071',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Diagnostic model – per model',
    description:
      'The production of a physical model from an impression or digital data. The model is used for examination and treatment planning procedures. This item is not to be used to describe a working model.',
    rules: {
      scope: ServiceCodeScope.PerModel,
    },
    fee: 66,
  },
  '072': {
    code: '072',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Photographic records – intraoral – per appointment #',
    description: 'Photographic images inside the mouth.',
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
    fee: 37,
  },
  '073': {
    code: '073',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Photographic records – extraoral – per appointment #',
    description:
      'Photographic images showing the face or a region of the head and neck outside the mouth.',
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
  },
  '074': {
    code: '074',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Diagnostic modelling – physical – per tooth #',
    description:
      'Physical modelling (e.g. using wax or other material) to facilitate the development of treatment plan.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '075': {
    code: '075',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Diagnostic modelling – digital – per tooth #',
    description:
      'Digital modelling to facilitate the development of treatment plan',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '076': {
    code: '076',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTPossible,
    title: `Intraoral evaluation of diagnostic modelling – per tooth or tooth replacement #`,
    description: `A procedure utilising data from 074 or 075 to create an intraoral simulation of proposed treatment to evaluate function, aesthetics and other characteristics`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
      optionalRelevantCodes: [74, 75],
    },
  },
  '081': {
    code: '081',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Cephalometric analysis – excluding radiographs',
    description:
      'The analysis of a cephalometric radiograph (item 036) by measurement of specific biological landmarks in order to determine and predict patterns of development of the cranio-maxillofacial growth complex. The procedure is often employed in orthodontic diagnosis.',
    rules: {
      optionalRelevantCodes: [36],
    },
  },
  '082': {
    code: '082',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Tooth–jaw size prediction analysis',
    description:
      'The analysis of records, including radiographs and casts, to predict the relationship between the anticipated size of the mandible, the maxilla and the teeth that these bones have to accommodate.',
    rules: {},
  },
  '083': {
    code: '083',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Tomographic analysis',
    description:
      'The analysis of a tomograph (item 039) by measurement of specific biological landmarks. The procedure is often employed in treatment planning for implant and other surgical procedures.',
    rules: {
      optionalRelevantCodes: [39],
    },
  },
  '085': {
    code: '085',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Electromyographic recording',
    description:
      'Utilisation of electronic apparatus to detect and record the sensitivity of the orofacial and associated musculature.',
    rules: {},
    deleted: true,
  },
  '086': {
    code: '086',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Electromyographic analysis',
    description:
      'The analysis of electromyographic recordings of the orofacial and associated musculature.',
    rules: {},
    deleted: true,
  },
  '087': {
    code: '087',
    scope: [ChartableSurface.Quadrant, ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTPossible,
    title:
      'Cone Beam Computed Tomography analysis and/or interpretation – small field of view (less than one complete dental arch) #',
    description: `The analysis and/or interpretation of a cone beam computed scan (refer to item 026) with a limited field of view. The procedure is often employed in diagnosis, treatment planning and analysis for dental procedures. CBCT is also referred to as 'CBVT`,
    rules: {
      optionalRelevantCodes: [26, 88, 89, 90, 91],
    },
  },
  '088': {
    code: '088',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTPossible,
    title:
      'Cone Beam Computed Tomography analysis and/or interpretation – maxillary or mandibular dentition (single arch) #',
    description: `The analysis and/or interpretation of a cone beam volumetric scan (refer to item 026) of the maxillary or mandibular dentition and supporting alveolar structures. The procedure is often employed in diagnosis,.treatment planning and analysis for dental procedures. CBVT is also known as 'CBCT'.`,
    rules: {
      optionalRelevantCodes: [26, 87, 89, 90, 91],
    },
  },
  '089': {
    code: '089',
    scope: [ChartableSurface.WholeMouth],
    taxStatus: TaxStrategy.GSTPossible,
    title:
      'Cone Beam Computed Tomography analysis and/or interpretation – maxillary and mandibular dentition (both arches) #',
    description: `The analysis and/or interpretation of a cone beam computed scan (refer to item 026) of the maxillary and mandibular dentition and supporting alveolar structures. The procedure is often employed in diagnosis, treatment planning and analysis for dental procedures. CBCT is also referred to as 'CBVT'.`,
    rules: {
      optionalRelevantCodes: [26, 87, 88, 90, 91],
    },
  },
  '090': {
    code: '090',
    taxStatus: TaxStrategy.GSTPossible,
    title:
      'Cone Beam Computed Tomography analysis and/or interpretation – temporomandibular joints only #',
    description: `The analysis and/or interpretation of a cone beam computed scan (refer to item 026) of the temporomandibular joints only, including an open mouth scan if required. The procedure is often employed in diagnosis, treatment planning and analysis for dental procedures. CBCT is also referred to as 'CBVT'.`,
    rules: {
      optionalRelevantCodes: [26, 87, 88, 89, 91],
    },
  },
  '091': {
    code: '091',
    taxStatus: TaxStrategy.GSTPossible,
    title:
      'Cone Beam Computed Tomography analysis and/or interpretation - orofacial structures #',
    description: `The analysis and/or interpretation of a cone beam computed scan (refer to item 026) of the entire dentition and supporting alveolar structures, also including any or all of the following: temporomandibular joints, paranasal sinuses and airway, cranial base, cervical spine. The procedure is often employed in diagnosis, treatment planning and analysis for dental procedures. CBCT is also referred to as 'CBVT'.`,
    rules: {
      optionalRelevantCodes: [26, 87, 88, 89, 90],
    },
  },
  '111': {
    code: '111',
    scope: [ChartableSurface.WholeMouth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Removal of plaque and/or stain',
    description:
      'Removal of dental plaque and/or stain from the surfaces of all teeth and/or implants. This item is included in procedures described by items 114, 115, 250 and 251.',
    rules: {
      includedInCodes: [114, 115, 250, 251],
    },
    fee: 68,
  },
  '113': {
    code: '113',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Recontouring and polishing of pre-existing restoration(s) – per tooth`,
    description: `The reshaping and polishing of pre-existing restorations`,
    rules: {},
  },
  '114': {
    code: '114',
    scope: [
      ChartableSurface.WholeMouth,
      ChartableSurface.Arch,
      ChartableSurface.Quadrant,
    ], // Also WholeSide
    taxStatus: TaxStrategy.GSTFree,
    title: 'Removal of calculus – first appointment',
    description:
      'Removal of calculus from the surfaces of teeth and/or implants.',
    rules: {
      includedInCodes: [250, 251],
      optionalRelevantCodes: [115],
    },
    fee: 123,
  },
  '115': {
    code: '115',
    scope: [
      ChartableSurface.WholeMouth,
      ChartableSurface.Arch,
      ChartableSurface.Quadrant,
    ], // Also WholeSide
    taxStatus: TaxStrategy.GSTFree,
    title: 'Removal of calculus – subsequent appointment',
    description:
      'This item describes procedures in item 114 when, because of the extent or degree of calculus, an additional appointment(s) is required to remove deposits from the teeth and/or implants.',
    rules: {
      includedInCodes: [250, 251],
    },
    fee: 119,
  },
  '116': {
    code: '116',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Enamel micro-abrasion – per tooth',
    description:
      'The chemo-physical removal of discoloured and/or other surface enamel defects resulting from developmental defects, altered mineralisation or decalcification of the superficial enamel layer.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '117': {
    code: '117',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Bleaching, internal – per application',
    description: `The modification of the colour of the discoloured crown of an endodontically treated tooth using chemical and/or physical methods applied internally. This is a treatment carried out exclusively in the surgery.`,
    rules: {},
  },
  '118': {
    code: '118',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Bleaching, external – per tooth #',
    description: `The modification of the colour of a tooth using chemical and/or physical methods applied externally. This is a clinical course of treatment carried out exclusively in the surgery.`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
    fee: 63,
  },
  '119': {
    code: '119',
    scope: [ChartableSurface.WholeMouth],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Bleaching, home application #',
    description: `Provision of clinical advice, risk advice, use instructions and necessary professional supervision, for application by a patient at home, of bleaching medicaments to the patient's dentition. For provision of required tray(s) and medicaments, see items 926 (where custom trays constructed) and 927.`,
    rules: {
      optionalRelevantCodes: [926, 927],
    },
    fee: 143,
  },
  '121': {
    code: '121',
    scope: [ChartableSurface.WholeMouth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Topical application of remineralisation agents, one treatment',
    description: `Application of remineralisation agents to the surfaces of the teeth. This may include activation of the agent. Not to be used as an intrinsic part of the restoration.`,
    rules: {},
    fee: 36,
  },
  '122': {
    code: '122',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Topical remineralisation agents, home application – per arch',
    description: `The prescribed use, by a patient at home, of a custom-made tray for the application of remineralisation agents to the patient's dentition. This procedure describes the complete course of treatment per arch. For provision of the tray and medicaments or other remineralisation agents, see items 926 and 927.`,
    rules: {
      scope: ServiceCodeScope.PerArch,
      optionalRelevantCodes: [926, 927],
    },
  },
  '123': {
    code: '123',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Application of a cariostatic agent – per tooth',
    description: `Application of a concentrated fluoride or remineralisation and/or cariostatic agent, such as preparations containing silver fluoride. This includes necessary activation of the agent. Not to be used as an intrinsic part of the restoration.`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '131': {
    code: '131',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Dietary analysis and advice',
    description: `Analysis of and advice on a patient's diet, including alcohol consumption, to correct any dietary imbalances or deficiencies that may contribute to oral diseases`,
    rules: {},
    fee: 38,
  },
  '141': {
    code: '141',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Oral hygiene instruction',
    description:
      'Instruction in techniques for the removal of bacterial plaque. Advice of appropriate toothpaste and medicaments may be included.',
    rules: {},
    fee: 40,
  },
  '142': {
    code: '142',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Tobacco counselling',
    description: `The analysis and assessment of a patient's tobacco use and advice on the prevention and cessation of the use of tobacco aimed at reducing the patient's risks of developing tobacco-related oral diseases and conditions.`,
    rules: {},
  },
  '151': {
    code: '151',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTApplicable,
    title: 'Provision of a mouthguard – indirect T',
    description: `Construction, provision and delivery of a mouthguard, using a model(s). The mouthguard is intended to prevent or mitigate sports injuries or soft tissue injuries that could occur from parafunction.`,
    rules: {},
    fee: 198,
  },
  '153': {
    code: '153',
    scope: [ChartableSurface.WholeMouth],
    taxStatus: TaxStrategy.GSTApplicable,
    title: 'Bi-maxillary mouthguard – indirect T',
    description: `Construction, provision and delivery of a bi-maxillary mouthguard using models. The mouthguard is intended to prevent or mitigate sports injuries.`,
    rules: {},
  },
  '161': {
    code: '161',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Fissure and/or tooth surface sealing – per tooth',
    description:
      'Sealing of non-carious pits, fissures, smooth surfaces or cracks in a tooth with an adhesive material..Any preparation prior to application of the sealant is included in this item number.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
    fee: 61,
  },
  '163': {
    code: '163',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTPossible,
    title: `Resin infiltration – per tooth #`,
    description: `Treatment of enamel lesions on smooth enamel surfaces by infiltration with a resin material. Any preparation prior to application of the resin infiltration is included in this item number`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '165': {
    code: '165',
    scope: [
      ChartableSurface.WholeMouth,
      ChartableSurface.WholeTooth,
      ChartableSurface.MultipleTeeth,
      ChartableSurface.Quadrant,
      ChartableSurface.Arch,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Desensitising procedure – per appointment',
    description:
      'This item describes all desensitising undertaken at a single appointment and may include a procedure to diminish or abolish the painful sensitivity and discomfort that can occur in exposed dentine.',
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
    fee: 42,
  },
  '171': {
    code: '171',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Odontoplasty – per tooth',
    description: `A procedure to modify the contour of the crown of a tooth or the anatomy of the fissure of a tooth.`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '213': {
    code: '213',
    scope: [ChartableSurface.WholeTooth, ChartableSurface.MultipleTeeth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Treatment of acute periodontal infection – per appointment',
    description:
      'This item describes the treatment of acute periodontal infection(s). It may include establishing drainage and the removal of calculus from the affected tooth (teeth) or implant.',
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
    fee: 110,
  },
  '221': {
    code: '221',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Clinical periodontal analysis and recording',
    description: `This is a special examination performed as part of the diagnosis and management of periodontal disease..The procedure consists of assessing and recording a patient's periodontal condition.`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
      includedInCodes: [242, 250, 251],
      optionalRelevantCodes: [114, 115],
    },
    fee: 73,
  },
  '222': {
    code: '222',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Periodontal debridement – per tooth',
    description: `The mechanical removal of biofilm and calculus from a periodontally diseased tooth to a level consistent with periodontal health. Where calculus removal is undertaken for other teeth at the same or a subsequent appointment that do not require debridement, it is appropriate to itemise 114/115 for these teeth in conjunction with but separate from the teeth that have undergone periodontal debridement.`,
    rules: {},
    fee: 51,
  },
  '223': {
    code: '223',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Non-surgical treatment of peri-implant disease – per implant',
    description:
      'The process of debridement using specialised instrumentation to remove the microbial biofilm from the implant and/or abutment surface, which may include localised application of medicaments.',
    rules: {
      scope: ServiceCodeScope.PerImplant,
    },
  },
  '225': {
    code: '225',
    scope: [ChartableSurface.Unscoped],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Non-surgical Perio Treat Per Visit (DVA ONLY)',
    description: 'Non-surgical Perio Treat Per Visit (DVA ONLY)',
    deleted: true,
  },
  '231': {
    code: '231',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Gingivectomy – per tooth',
    description:
      'The surgical removal of the soft tissue wall of the periodontal pocket or the removal of swollen, excess gingival tissue. The procedure includes periodontal debridement of the tooth.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '232': {
    code: '232',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Periodontal flap surgery – per tooth',
    description:
      'Incision and raising of a flap of gingival tissue to enable removal of inflammatory or granulation tissue..The procedure includes periodontal debridement of the tooth.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
      includedInCodes: [242],
    },
    fee: 295,
  },
  '233': {
    code: '233',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Surgical treatment of peri-implant disease - per implant',
    description:
      'Surgical access and flap elevation of the peri-implant soft tissues to access the implant surface for decontamination. The procedure includes the removal of inflammatory tissue. Grafting or regenerative materials where placed should be itemised separately.',
    rules: {
      scope: ServiceCodeScope.PerImplant,
      includedInCodes: [242],
    },
  },
  '234': {
    code: '234',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Application of biologically active material',
    description:
      'The application of a biologically active material in conjunction with periodontal surgery to enhance reattachment or regeneration. This includes materials such as porcine collagen and enamel matrix derivative materials.',
    rules: {},
  },
  '235': {
    code: '235',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Gingival graft – per tooth, implant or extraction socket',
    description: `Transference or transplanting gingival or other soft tissue from a donor area in the patient's mouth to an area around a tooth, implant or extraction socket to remedy a gingival deficiency.`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '236': {
    code: '236',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Guided tissue regeneration – per tooth or implant',
    description:
      'A surgical technique in which a membrane is adapted over a bony defect, the tooth root or an implant.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '237': {
    code: '237',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Guided tissue regeneration – membrane removal',
    description:
      'Surgical procedure to remove a previously positioned membrane.',
    rules: {},
  },
  '238': {
    code: '238',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Periodontal surgery for crown lengthening – per tooth',
    description:
      'A procedure to establish a more apical gingival margin for greater exposure of tooth structure, including reshaping of alveolar bone where required.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '239': {
    code: '239',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Gingival xenograft – per tooth`,
    description: `The insertion of a xenograft to remedy a gingival deficiency`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '241': {
    code: '241',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Root resection – per root',
    description:
      'Removal or amputation of one or more roots of a multi-rooted tooth with or without a portion of the crown.',
    rules: {
      scope: ServiceCodeScope.PerRoot,
    },
  },
  '242': {
    code: '242',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Osseous surgery – per tooth or implant',
    description:
      'Reshaping and modifying defects and deformities in the bone supporting and surrounding the tooth or implant. This includes procedures described in item 232 and 233. The procedure includes periodontal debridement of the tooth or implant.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '243': {
    code: '243',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Osseous graft – per tooth or implant',
    description:
      'A surgical procedure in which particulate bone, a synthetic substitute or other matrix is used to replace, repair or augment alveolar bone. It may be used to repair a bony defect around a tooth or dental implant. This is in addition to item numbers such as 232, 233, 245 and 684.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
      optionalRelevantCodes: [232, 233, 245, 684],
    },
  },
  '244': {
    code: '244',
    scope: [
      ChartableSurface.WholeTooth,
      ChartableSurface.MultipleTeeth,
      ChartableSurface.Arch,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Osseous graft – block',
    description:
      'A surgical procedure in which a block of bone is used for augmentation of a bony ridge, secured by screws or similar devices.',
    rules: {},
  },
  '245': {
    code: '245',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Periodontal surgery involving one tooth',
    description:
      'Surgical intervention to aid the resolution of a localised periodontal inflammatory condition involving one tooth..The item is also used to describe pericision.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '246': {
    code: '246',
    scope: [ChartableSurface.Quadrant], // Ideally right-side left-side
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Maxillary sinus augmentation – trans-alveolar technique – per sinus',
    description:
      'Surgical access to the maxillary sinus via the implant osteotomy site. May involve simultaneous placement of dental implants. Grafting or regenerative materials where used should be itemised separately',
    rules: {
      scope: ServiceCodeScope.PerSinus,
    },
  },
  '247': {
    code: '247',
    scope: [ChartableSurface.Quadrant], // Ideally right-side left-side
    taxStatus: TaxStrategy.GSTFree,
    title: 'Maxillary sinus augmentation – lateral wall approach – per sinus',
    description:
      'Surgical access in the maxillary sinus via a lateral wall approach with bone grafting. May involve simultaneous placement of dental implants. Grafting or regenerative materials where used should be itemised separately.',
    rules: {
      scope: ServiceCodeScope.PerSinus,
    },
  },
  '250': {
    code: '250',
    scope: [ChartableSurface.Quadrant],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Active non-surgical periodontal therapy – per quadrant',
    description:
      'Non-surgical management of uncontrolled periodontal disease in a quadrant with at least three teeth with clinical attachment loss of at least 3mm or pocketing of at least 4mm. This includes procedures described in items 111, 114, 115 and 222 for the quadrant. Items such as 113, 131 and 142 may be itemised separately.',
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
    fee: 238,
  },
  '251': {
    code: '251',
    scope: [ChartableSurface.Quadrant, ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Supportive periodontal therapy – per appointment',
    description:
      'Maintenance of periodontal health subsequent to active, non-surgical or surgical periodontal therapy. This includes procedures described in items 111, 114, 115 and 222. Items such as 113, 131 and 142 may be itemised separately.',
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
    fee: 208,
  },
  '311': {
    code: '311',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Removal of a tooth or part(s) thereof',
    description:
      'A procedure consisting of the removal of a tooth or part(s) thereof.',
    rules: {},
    fee: 205,
  },
  '314': {
    code: '314',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Sectional removal of a tooth or part(s) thereof',
    description:
      'The removal of a tooth or part(s) thereof in sections. Bone removal may be necessary',
    rules: {},
    fee: 272,
  },
  '316': {
    code: '316',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Removal of additional tooth or part(s) thereof',
    description:
      'Removal of additional tooth or part(s) thereof - Victoria Only',
    rules: {},
  },
  '322': {
    code: '322',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Surgical removal of a tooth or tooth fragment not requiring removal of bone or tooth division',
    description:
      'Removal of a tooth or tooth fragment where an incision and the raising of a mucoperiosteal flap are required,.but where removal of bone or sectioning of the tooth is not necessary to remove the tooth.',
    rules: {},
    fee: 313,
  },
  '323': {
    code: '323',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Surgical removal of a tooth or tooth fragment requiring removal of bone',
    description:
      'Removal of a tooth or tooth fragment where removal of bone is required after an incision and the raising of a mucoperiosteal flap.',
    rules: {},
    deleted: true,
  },
  '324': {
    code: '324',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Surgical removal of a tooth or tooth fragment requiring bone removal and/or tooth division`,
    description: `Removal of a tooth or tooth fragment with removal of bone and/or sectioning of the tooth after an incision and the raising of a mucoperiosteal flap. The tooth may be removed in sections.`,
    rules: {},
    fee: 428,
  },
  '331': {
    code: '331',
    scope: [ChartableSurface.Quadrant], // Can also be across multiple quadrants such as left-side, bottom arch
    taxStatus: TaxStrategy.GSTFree,
    title: 'Alveoloplasty – per segment ',
    description: `The surgical modification of the shape of a segment of the alveolar bone.`,
    rules: {
      scope: ServiceCodeScope.PerSegment,
    },
  },
  '332': {
    code: '332',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Ostectomy – per jaw',
    description:
      'The surgical modification of the bony architecture of the maxilla or mandible.',
    rules: {
      scope: ServiceCodeScope.PerJaw,
    },
  },
  '337': {
    code: '337',
    scope: [ChartableSurface.Quadrant], // Upper arch
    taxStatus: TaxStrategy.GSTFree,
    title: 'Reduction of fibrous tuberosity',
    description:
      'A surgical procedure involving removal of fibrous tissue from the posterior maxillary alveolar ridge in order to modify the ridge contour.',
    rules: {},
  },
  '338': {
    code: '338',
    scope: [ChartableSurface.Quadrant],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Reduction of flabby ridge – per segment',
    description:
      'A surgical procedure involving removal of unsupported soft tissue from a segment or quadrant of the maxillary or mandibular alveolar ridge.',
    rules: {
      scope: ServiceCodeScope.PerSegment,
    },
  },
  '341': {
    code: '341',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Removal of hyperplastic tissue',
    description:
      'The surgical removal of an area of hyperplastic tissue from the maxillary or mandibular alveolar ridge or adjacent mucosa.',
    rules: {},
  },
  '343': {
    code: '343',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Repositioning of muscle attachment',
    description:
      'The surgical repositioning of a muscle attachment to place it in a more favourable position.',

    rules: {},
  },
  '344': {
    code: '344',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Vestibuloplasty',
    description:
      'The surgical deepening of the buccal or labial vestibule in the mucosa.',
    rules: {},
  },
  '345': {
    code: '345',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Skin or mucosal graft',
    description: 'A surgical procedure involving a skin or mucosal graft.',
    rules: {},
  },
  '351': {
    code: '351',
    taxStatus: TaxStrategy.GSTFree,
    title: `Repair of skin and subcutaneous tissue or mucous membrane`,
    description: `The surgical cleaning and repair of a facial skin wound in the region of the mouth or jaws, or the repair of oral mucous membrane, where the wounds involve subcutaneous tissues`,
    rules: {},
  },
  '352': {
    code: '352',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Fracture of maxilla or mandible – not requiring fixation',
    description:
      'Conservative treatment of a fracture of the maxilla or mandible where there is no marked displacement or mobility of the fragments. No physical reduction or fixation is required.',
    rules: {},
  },
  '353': {
    code: '353',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Fracture of maxilla or mandible – with wiring of teeth or intraoral fixation',
    description:
      'Treatment of a fracture of the maxilla or mandible where interdental wiring or the application of a dental splint is indicated to provide reduction and fixation of the fragments. Where a splint is required, it should be itemised.',
    rules: {},
  },
  '354': {
    code: '354',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Fracture of maxilla or mandible – with external fixation',
    description:
      'Treatment of a fracture of the maxilla or mandible using sets of pins inserted through the skin and into the maxillary or mandibular bone to reduce and fix the fracture. The pins are locked into a firm relationship using clamps and a supporting apparatus.',
    rules: {},
  },
  '355': {
    code: '355',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Fracture of zygoma',
    description: 'Treatment of a fracture of the zygoma.',
    rules: {},
  },
  '359': {
    code: '359',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Fracture of the maxilla or mandible requiring open reduction',
    description:
      'Surgical treatment of a fracture of the maxilla or mandible where the fracture is exposed and reduction and fixation of the fragments are performed directly. The fragments, when reduced, may be fixed in position with wires or plates and screws.',
    rules: {},
  },
  '361': {
    code: '361',
    scope: [ChartableSurface.Arch], // Lower arch
    taxStatus: TaxStrategy.GSTFree,
    title: 'Mandible – relocation following dislocation',
    description:
      'Relocation of the condylar head of the mandible within the temporomandibular joint fossa following its dislocation. The procedure is usually performed by manipulation.',
    rules: {},
  },
  '363': {
    code: '363',
    scope: [ChartableSurface.Arch], // Lower arch
    taxStatus: TaxStrategy.GSTFree,
    title: 'Mandible – relocation requiring open operation',
    description:
      'Surgical exposure of a dislocated temporomandibular joint and its correct repositioning.',
    rules: {},
  },
  '365': {
    code: '365',
    scope: [ChartableSurface.Arch], // Upper arch
    taxStatus: TaxStrategy.GSTFree,
    title: 'Osteotomy – maxilla',
    description:
      'Surgical exposure and fracturing of the maxilla in predetermined planes in order to modify its form or position.',
    rules: {},
  },
  '366': {
    code: '366',
    scope: [ChartableSurface.Arch], // Lower arch
    taxStatus: TaxStrategy.GSTFree,
    title: 'Osteotomy – mandible',
    description:
      'Surgical exposure and fracturing of the mandible in predetermined planes in order to modify its form or position.',
    rules: {},
  },
  '371': {
    code: '371',
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Removal of tumour, cyst or scar – cutaneous, subcutaneous or in mucous membrane',
    description:
      'The surgical removal of a tumour, cyst or scar from cutaneous or subcutaneous tissues or from mucous membrane. Pathological review may be necessary.',
    rules: {},
  },
  '373': {
    code: '373',
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Removal of tumour, cyst or scar involving muscle, bone or other deep tissue',
    description:
      'The surgical removal of a tumour, cyst or scar involving muscle, bone or other deep tissue. Pathological review may be necessary.',
    rules: {},
  },
  '375': {
    code: '375',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Surgery to salivary duct',
    description:
      'A general item to describe any surgery to salivary ducts. It includes removal of stones and plastic surgery..Details of the procedure should be specified.',
    rules: {},
  },
  '376': {
    code: '376',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Surgery to salivary gland',
    description:
      'A general item to describe any surgery to the salivary gland that cannot be described by another item. Details of the procedure should be specified.',
    rules: {},
  },
  '377': {
    code: '377',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Repair of soft tissue',
    description: `An item to describe surgical procedures (including suturing) for the repair of soft tissues.`,
    rules: {},
  },
  '378': {
    code: '378',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Surgical removal of foreign body',
    description: 'The surgical removal of a foreign body impacted in tissues.',
    rules: {},
  },
  '379': {
    code: '379',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Marsupialisation of cyst',
    description:
      'The treatment of a cyst by surgically opening it to convert it from an enclosed sac to a partially open pouch.',
    rules: {},
  },
  '381': {
    code: '381',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Surgical exposure of an unerupted tooth – per tooth`,
    description:
      'The surgical exposure of an unerupted tooth to facilitate its eruption.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '382': {
    code: '382',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Surgical exposure and attachment of device for orthodontic traction',
    description:
      'Surgical exposure of an unerupted tooth and attachment of a device so that orthodontic traction may be applied.',
    rules: {},
  },
  '384': {
    code: '384',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Repositioning of displaced tooth/teeth – per tooth',
    description:
      'A procedure following trauma where the position of the displaced tooth/teeth is corrected by manipulation..Stabilising procedures are itemised separately.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '385': {
    code: '385',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Surgical repositioning of unerupted tooth – per tooth',
    description:
      'Surgical exposure and manipulation of an unerupted tooth to correct its position but not to detach the tooth from its supporting tissues.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '386': {
    code: '386',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Splinting of displaced tooth/teeth – per displaced tooth`,
    description: `A procedure following trauma where the position of the displaced tooth/teeth may be stabilised by splinting. For removal of splint, see item 656`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
      optionalRelevantCodes: [656],
    },
    fee: 199,
  },
  '387': {
    code: '387',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Replantation and splinting of a tooth – per avulsed or intentionally removed tooth`,
    description: `Replantation of a tooth that has been avulsed or intentionally removed. It may be held in the correct position by splinting. For removal of splint, see item 656.`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
      optionalRelevantCodes: [656],
    },
  },
  '388': {
    code: '388',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Transplantation of tooth or tooth bud',
    description:
      'Surgical access to and transplantation of an unerupted tooth or tooth bud to a new position prepared in the maxilla or mandible to receive it.',
    rules: {},
  },
  '389': {
    code: '389',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Surgery to isolate and preserve neurovascular tissue',
    description:
      'Additional surgery performed at the time of dento-alveolar surgery where damage to the neurovascular bundle may occur. The object of the additional surgery is to isolate and protect the neurovascular bundle from injury.',
    rules: {},
  },
  '391': {
    code: '391',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Surgical intervention of a frenum',
    description: `This item includes frenotomy, frenuloplasty or frenectomy. For appropriate usage, reference should be made to the ADA 2020 Ankyloglossia and Oral Frena Consensus Statement.`,
    rules: {},
    fee: 317,
  },
  '392': {
    code: 392, // Usually WholeTooth however could be anywhere in the '392'h
    taxStatus: TaxStrategy.GSTFree,
    title: 'Drainage of abscess',
    description:
      'Drainage and/or irrigation of an abscess other than through a root canal or at the time of extraction..The drainage may be through an incision or inserted tube.',
    rules: {},
    fee: 117,
  },
  '393': {
    code: '393',
    scope: [ChartableSurface.Quadrant], // Upper quadrants only as relates to the sinus
    taxStatus: TaxStrategy.GSTFree,
    title: 'Surgery involving the maxillary antrum',
    description:
      'A general item to describe any surgery of the maxillary antrum, including lavage, antrostomy, recovery of a foreign body or closure of an oro-antral communication. Details of the procedure should be specified.',
    rules: {},
  },
  '394': {
    code: '394',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Surgery for osteomyelitis',
    description:
      'A general item to describe any surgery for the treatment of osteomyelitis. Details of the procedure should be specified.',
    rules: {},
  },
  '395': {
    code: '395',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Repair of nerve trunk',
    description: 'A surgical procedure to repair a nerve trunk.',
    rules: {},
  },
  '399': {
    code: '399',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Control of reactionary or secondary post-operative haemorrhage',
    description:
      'This procedure describes the control of reactionary or secondary post-operative haemorrhage.',
    rules: {},
  },
  '411': {
    code: '411',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Direct pulp capping',
    description:
      'A procedure where an exposed pulp is directly covered with a protective dressing or cement.',
    rules: {},
    fee: 52,
  },
  '412': {
    code: '412',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Incomplete endodontic therapy (tooth not suitable for further treatment)',
    description:
      'A procedure where in assessing the suitability of a tooth for endodontic treatment a decision is made that the tooth is not suitable for restoration. Temporisation is itemised separately.',
    rules: {},
  },
  '414': {
    code: '414',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Pulpotomy',
    description: `Amputation of part of the pulp. The pulp remaining is then covered with a protective dressing or cement.`,
    rules: {},
    fee: 132,
  },
  '415': {
    code: '415',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Complete chemo-mechanical preparation of root canal – one canal',
    description:
      'Complete chemo-mechanical preparation, including removal of pulp or necrotic debris from a canal.',
    rules: {
      scope: ServiceCodeScope.FirstCanal,
    },
    fee: 308,
  },
  '416': {
    code: '416',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Complete chemo-mechanical preparation of root canal – each additional canal',
    description:
      'Complete chemo-mechanical preparation, including removal of pulp or necrotic debris from each additional canal of a tooth with multiple canals.',
    rules: {
      scope: ServiceCodeScope.AdditionalCanal,
    },
    fee: 149,
  },
  '417': {
    code: '417',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Root canal obturation – one canal',
    description:
      'The filling of a root canal, following chemo-mechanical preparation.',
    rules: {},
    fee: 307,
  },
  '418': {
    code: '418',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Root canal obturation – each additional canal',
    description:
      'The filling, following chemo-mechanical preparation, of each additional canal in a tooth with multiple canals.',
    rules: {},
    fee: 147,
  },
  '419': {
    code: '419',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Extirpation of pulp or debridement of root canal(s) `,
    description: `The partial removal of a tooth pulp for one or more of the following reasons: to relieve pain, to perform an assessment of root integrity, or to carry out an assessment of the tooth's suitability for restoration. Item numbers 415 and/or 416 should not be used at the same appointment as 419`,
    rules: {},
    fee: 223,
  },
  '421': {
    code: '421',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Resorbable root canal filling – primary tooth',
    description:
      'The placement of resorbable root canal filling material in a primary tooth.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '431': {
    code: '431',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Periapical curettage – per root',
    description:
      'Surgical exposure of the apical section of the root of a pulpless or endodontically treated tooth to remove associated pathological tissue.',
    rules: {
      scope: ServiceCodeScope.PerRoot,
    },
  },
  '432': {
    code: '432',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Apicectomy – per root',
    description:
      'Surgical exposure of the apical section of the root of a pulpless or endodontically treated tooth to remove the apical portion of the root, including curettage of the region.',
    rules: {
      scope: ServiceCodeScope.PerRoot,
    },
  },
  '433': {
    code: '433',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Exploratory periradicular surgery',
    description:
      'Surgery to investigate the integrity of the root and surrounding structures.',
    rules: {},
  },
  '434': {
    code: '434',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Apical seal – per canal',
    description: `A surgical method of debriding and filling the apical end of the root canal of a tooth. Periapical curettage and apicectomy should be itemised separately`,
    rules: {
      scope: ServiceCodeScope.PerCanal,
    },
  },
  '436': {
    code: '436',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Sealing of perforation',
    description:
      'A surgical method of sealing a communication between the pulp canal/chamber and the periradicular tissues.',
    rules: {},
  },
  '437': {
    code: '437',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Surgical treatment and repair of external root resorption – per tooth',
    description: 'Surgical treatment of external root resorption.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '438': {
    code: '438',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Hemisection',
    description: 'Separation of a multi-rooted tooth into two parts.',
    rules: {},
  },
  '445': {
    code: '445',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Exploration and/or negotiation of a calcified canal – per canal, per appointment',
    description:
      'Chemical and physical procedures to locate and/or negotiate an abnormally calcified root canal where specific time is devoted to this procedure.',
    rules: {
      scope: ServiceCodeScope.PerCanal,
    },
  },
  '451': {
    code: '451',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Removal of root filling – per canal',
    description:
      'Removal of the root filling from a previously obturated canal to enable endodontic retreatment.',
    rules: {},
    fee: 154,
  },
  '452': {
    code: '452',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Removal of a cemented root canal post or post crown',
    description: 'Removal of a cemented root canal post or post crown.',
    rules: {},
  },
  '453': {
    code: '453',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Removal or bypassing fractured endodontic instrument',
    description:
      'The removal or bypassing of an instrument lodged in the root canal.',
    rules: {},
  },
  '455': {
    code: '455',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Additional appointment for irrigation and/or dressing of the root canal system – per tooth`,
    description: `Separate appointment for additional irrigation of the root canal system and replacement of the intracanal dressing/medicament with therapeutic properties that facilitates healing/development of the root and periradicular tissues over time. This item is not to be used in conjunction with items 411–421 or 451–453 or 457`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
    fee: 160,
  },
  '457': {
    code: '457',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Obturation of resorption defect or perforation (non-surgical)',
    description:
      'Conservative treatment of a root perforation or resorption defect to repair the defect from within the root canal.',
    rules: {},
  },
  '458': {
    code: '458',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Interim therapeutic root filling – per tooth',
    description:
      'A procedure consisting of the insertion of a long-term provisional (temporary) root canal filling with therapeutic properties that facilitates healing/development of the root and periradicular tissues over an extended time.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
    deleted: true,
  },
  '459': {
    code: '459',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Guided endodontic repair – per tooth`,
    description: `The stimulation of an ingrowth of tissue to occupy the root canal space by means of a guided repair process, in one or multiple appointments. This item is not to be used in conjunction with item 417. Item 415 should be itemised separately`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '511': {
    code: '511',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Metallic restoration – one surface – direct',
    description:
      'Direct metallic restoration involving one surface of a tooth.',
    rules: {
      scope: ServiceCodeScope.PerMultipleSurface,
      scopeValue: 1,
    },
    fee: 166,
  },
  '512': {
    code: '512',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Metallic restoration – two surfaces – direct',
    description:
      'Direct metallic restoration involving two surfaces of a tooth.',
    rules: {
      scope: ServiceCodeScope.PerMultipleSurface,
      scopeValue: 2,
    },
    fee: 197,
  },
  '513': {
    code: '513',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Metallic restoration – three surfaces – direct',
    description:
      'Direct metallic restoration involving three surfaces of a tooth.',
    rules: {
      scope: ServiceCodeScope.PerMultipleSurface,
      scopeValue: 3,
    },
    fee: 229,
  },
  '514': {
    code: '514',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Metallic restoration – four surfaces – direct',
    description:
      'Direct metallic restoration involving four surfaces of a tooth.',
    rules: {
      scope: ServiceCodeScope.PerMultipleSurface,
      scopeValue: 4,
    },
    fee: 255,
  },
  '515': {
    code: '515',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Metallic restoration – five surfaces – direct',
    description:
      'Direct metallic restoration involving five surfaces of a tooth.',
    rules: {
      scope: ServiceCodeScope.PerMultipleSurface,
      scopeValue: 5,
    },
    fee: 287,
  },
  '521': {
    code: '521',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Adhesive restoration – one surface – anterior tooth – direct',
    description:
      'Direct restoration, using an adhesive technique and a tooth-coloured material, involving one surface of an anterior tooth.',
    rules: {
      scope: ServiceCodeScope.PerAnteriorMultipleSurface,
      scopeValue: 1,
    },
    fee: 167,
  },
  '522': {
    code: '522',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Adhesive restoration – two surfaces – anterior tooth – direct',
    description:
      'Direct restoration, using an adhesive technique and a tooth-coloured material, involving two surfaces of an anterior tooth.',
    rules: {
      scope: ServiceCodeScope.PerAnteriorMultipleSurface,
      scopeValue: 2,
    },
    fee: 202,
  },
  '523': {
    code: '523',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Adhesive restoration – three surfaces – anterior tooth – direct',
    description:
      'Direct restoration, using an adhesive technique and a tooth-coloured material, involving three surfaces of an anterior tooth.',
    rules: {
      scope: ServiceCodeScope.PerAnteriorMultipleSurface,
      scopeValue: 3,
    },
    fee: 233,
  },
  '524': {
    code: '524',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Adhesive restoration – four surfaces – anterior tooth – direct',
    description:
      'Direct restoration, using an adhesive technique and a tooth-coloured material, involving four surfaces of an anterior tooth.',
    rules: {
      scope: ServiceCodeScope.PerAnteriorMultipleSurface,
      scopeValue: 4,
    },
    fee: 266,
  },
  '525': {
    code: '525',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Adhesive restoration – five surfaces – anterior tooth – direct',
    description:
      'Direct restoration, using an adhesive technique and a tooth-coloured material, involving five surfaces of an anterior tooth.',
    rules: {
      scope: ServiceCodeScope.PerAnteriorMultipleSurface,
      scopeValue: 5,
    },
    fee: 306,
  },
  '526': {
    code: '526',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Adhesive restoration – veneer – anterior tooth – direct',
    description:
      'Direct bonding of a veneer of adhesive tooth-coloured material to the surface of an anterior tooth.',
    rules: {
      scope: ServiceCodeScope.PerAnterior,
    },
    fee: 395,
  },
  '531': {
    code: '531',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Adhesive restoration – one surface – posterior tooth – direct',
    description:
      'Direct restoration, using an adhesive technique and a tooth-coloured material, involving one surface of a posterior tooth.',
    rules: {
      scope: ServiceCodeScope.PerPosteriorMultipleSurface,
      scopeValue: 1,
    },
    fee: 178,
  },
  '532': {
    code: '532',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Adhesive restoration – two surfaces – posterior tooth – direct',
    description:
      'Direct restoration, using an adhesive technique and a tooth-coloured material, involving two surfaces of a posterior tooth.',
    rules: {
      scope: ServiceCodeScope.PerPosteriorMultipleSurface,
      scopeValue: 2,
    },
    fee: 220,
  },
  '533': {
    code: '533',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Adhesive restoration – three surfaces – posterior tooth – direct',
    description:
      'Direct restoration, using an adhesive technique and a tooth-coloured material, involving three surfaces of a posterior tooth.',
    rules: {
      scope: ServiceCodeScope.PerPosteriorMultipleSurface,
      scopeValue: 3,
    },
    fee: 256,
  },
  '534': {
    code: '534',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Adhesive restoration – four surfaces – posterior tooth – direct',
    description:
      'Direct restoration, using an adhesive technique and a tooth-coloured material, involving four surfaces of a posterior tooth.',
    rules: {
      scope: ServiceCodeScope.PerPosteriorMultipleSurface,
      scopeValue: 4,
    },
    fee: 286,
  },
  '535': {
    code: '535',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Adhesive restoration – five surfaces – posterior tooth – direct',
    description:
      'Direct restoration, using an adhesive technique and a tooth-coloured material, involving five surfaces of a posterior tooth.',
    rules: {
      scope: ServiceCodeScope.PerPosteriorMultipleSurface,
      scopeValue: 5,
    },
    fee: 323,
  },
  '536': {
    code: '536',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Adhesive restoration – veneer – posterior tooth – direct',
    description:
      'Direct bonding of a veneer of adhesive tooth-coloured material to the surface of a posterior tooth.',
    rules: {
      scope: ServiceCodeScope.PerPosterior,
    },
    fee: 393,
  },
  '537': {
    code: '537',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Adhesive restoration- 1 surf- ant',
    description: '',
    rules: {
      scope: ServiceCodeScope.PerAnterior,
    },
    deleted: true,
  },
  '538': {
    code: '538',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Adhesive restoration- 1 surf- ant',
    description: '',
    rules: {
      scope: ServiceCodeScope.PerAnterior,
    },
    deleted: true,
  },
  '539': {
    code: '539',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Adhesive restoration- 1 surf- ant',
    description: '',
    rules: {
      scope: ServiceCodeScope.PerAnterior,
    },
    deleted: true,
  },
  '541': {
    code: '541',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Metallic restoration – one surface – indirect',
    description:
      'Indirect metallic restoration, involving one surface of a tooth.',
    rules: {
      scope: ServiceCodeScope.PerMultipleSurface,
      scopeValue: 1,
    },
  },
  '542': {
    code: '542',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Metallic restoration – two surfaces – indirect',
    description:
      'Indirect metallic restoration, involving two surfaces of a tooth.',
    rules: {
      scope: ServiceCodeScope.PerMultipleSurface,
      scopeValue: 2,
    },
  },
  '543': {
    code: '543',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Metallic restoration – three surfaces – indirect',
    description:
      'Indirect metallic restoration, involving three surfaces of a tooth.',
    rules: {
      scope: ServiceCodeScope.PerMultipleSurface,
      scopeValue: 3,
    },
  },
  '544': {
    code: '544',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Metallic restoration – four surfaces – indirect',
    description:
      'Indirect metallic restoration, involving four surfaces of a tooth.',
    rules: {
      scope: ServiceCodeScope.PerMultipleSurface,
      scopeValue: 4,
    },
  },
  '545': {
    code: '545',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Metallic restoration – five surfaces – indirect',
    description:
      'Indirect metallic restoration, involving five surfaces of a tooth.',
    rules: {
      scope: ServiceCodeScope.PerMultipleSurface,
      scopeValue: 5,
    },
  },
  '551': {
    code: '551',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Tooth-coloured restoration – one surface – indirect',
    description:
      'Indirect tooth-coloured restoration, involving one surface of a tooth.',
    rules: {
      scope: ServiceCodeScope.PerMultipleSurface,
      scopeValue: 1,
    },
    fee: 989,
  },
  '552': {
    code: '552',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Tooth-coloured restoration – two surfaces – indirect',
    description:
      'Indirect tooth-coloured restoration, involving two surfaces of a tooth.',
    rules: {
      scope: ServiceCodeScope.PerMultipleSurface,
      scopeValue: 2,
    },
    fee: 1050,
  },
  '553': {
    code: '553',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Tooth-coloured restoration – three surfaces – indirect',
    description:
      'Indirect tooth-coloured restoration, involving three surfaces of a tooth',
    rules: {
      scope: ServiceCodeScope.PerMultipleSurface,
      scopeValue: 3,
    },
    fee: 1154,
  },
  '554': {
    code: '554',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Tooth-coloured restoration – four surfaces – indirect',
    description:
      'Indirect non-metallic restoration, involving four surfaces of a tooth.',
    rules: {
      scope: ServiceCodeScope.PerMultipleSurface,
      scopeValue: 4,
    },
    fee: 1257,
  },
  '555': {
    code: '555',
    scope: [
      ChartableSurface.Distal,
      ChartableSurface.Facial,
      ChartableSurface.Lingual,
      ChartableSurface.Mesial,
      ChartableSurface.Occlusal,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Tooth-coloured restoration – five surfaces – indirect',
    description:
      'Indirect non-metallic restoration, involving five surfaces of a tooth.',
    rules: {
      scope: ServiceCodeScope.PerMultipleSurface,
      scopeValue: 5,
    },
    fee: 1357,
  },
  '556': {
    code: '556',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Tooth-coloured restoration – veneer – indirect',
    description:
      'The attachment of a tooth-coloured veneer to the surface of a tooth. The veneer is constructed indirectly',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
    fee: 1320,
  },
  '571': {
    code: '571',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Adaptation of new restoration to existing removable prosthesis – per tooth',
    description:
      'Used in conjunction with restorative services where adaptation of the new restoration is required to accommodate the seating and/or retention of an existing removable prosthesis.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '572': {
    code: '572',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Provisional/interim restoration – per tooth`,
    description: `The provisional (interim) restoration of a tooth designed to last until the definitive restoration can be constructed or the tooth is removed. This item should be used only where the provisional (interim) restoration is not an intrinsic part of treatment. This item is not to be used for provisional (interim) sealing of the access cavity during endodontic treatment or for provisional (interim) restoration during construction of indirect restorations`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
    fee: 110,
  },
  '574': {
    code: '574',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Metal band',
    description:
      'The cementation of a metal band for diagnostic, protective purposes or for the placement of a provisional.(intermediate) restoration. Where a provisional (intermediate) restoration is placed, item 572 may also be used.',
    rules: {},
    fee: 131,
  },
  '575': {
    code: '575',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Pin retention – per pin',
    description:
      'Use of a pin to aid the retention and support of direct or indirect restorations in a tooth.',
    rules: {
      scope: ServiceCodeScope.PerPin,
    },
    fee: 40,
  },
  '576': {
    code: '576',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Metallic Crown - preformed',
    description: 'Metallic Crown - preformed.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
    deleted: true,
  },
  '577': {
    code: '577',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Cusp capping – per cusp',
    description:
      'This item is to be used in conjunction with items describing direct or indirect restorations of posterior teeth that are formed in such a manner as to restore the anatomical or functional height of a cusp.',
    rules: {
      scope: ServiceCodeScope.PerPosteriorCusp,
    },
    fee: 40,
  },
  '578': {
    code: '578',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Restoration of an incisal corner – per corner',
    description:
      'This item is to be used in conjunction with items describing direct or indirect restorations of anterior teeth that are formed in such a way as to restore the anatomical or functional shape of an incisal corner.',
    rules: {
      scope: ServiceCodeScope.PerAnteriorCorner,
    },
    fee: 43,
  },
  '579': {
    code: '579',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Bonding of tooth fragment',
    description:
      'The direct bonding of a tooth fragment as an alternative to placing a restoration.',
    rules: {},
    fee: 175,
  },
  '586': {
    code: '586',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Preformed Full Crown – metallic – significant tooth preparation`,
    description: `Placing a preformed metallic crown as a coronal restoration for a tooth where significant tooth preparation is required. Where the restoration of the tooth at the same appointment is required prior to placement of the crown, the use of item 627 is applicable.`,
    rules: {
      optionalRelevantCodes: [627],
    },
    fee: 296,
  },
  '587': {
    code: '587',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Preformed Full Crown – metallic – minimal tooth preparation',
    description: `Placing a preformed metallic crown as a coronal restoration for a tooth where minimal or no preparation of the tooth is required, referred to as the 'Hall' technique`,
    rules: {},
  },
  '588': {
    code: '588',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Preformed Full Crown – tooth-coloured`,
    description: `Placing a preformed tooth-coloured crown as a coronal restoration for a tooth. Where the restoration of the tooth at the same appointment is required prior to placement of the crown, the use of item 627 is applicable.`,
    rules: {
      optionalRelevantCodes: [627],
    },
  },
  '595': {
    code: '595',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Removal of indirect restoration',
    description:
      'The removal of a currently cemented definitive indirect restoration, which may be subsequently reinserted.',
    rules: {},
  },
  '596': {
    code: '596',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Recementing of indirect restoration',
    description: 'Recementing a previously cemented indirect restoration.',
    rules: {},
  },
  '597': {
    code: '597',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Post – direct',
    description:
      'Insertion of a post into a prepared root canal to provide an anchor for an artificial crown or other restoration.',
    rules: {},
    fee: 190,
  },
  '598': {
    code: '598',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Restoration root capping – direct`,
    description: `A direct restoration of the root of a decoronated tooth. Not to be used with 577`,
    rules: {},
  },
  '611': {
    code: '611',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Full crown – polymeric material – indirect`,
    description: `An artificial crown constructed with a polymeric material, restoring a natural tooth.`,
    rules: {},
  },
  '613': {
    code: '613',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Full crown – ceramic – indirect`,
    description: `An artificial crown constructed with a ceramic material, manufactured to precisely adapt to a pre-prepared natural tooth.`,
    rules: {},
    fee: 1642,
  },
  '615': {
    code: '615',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Full crown – veneered – indirect`,
    description: `An artificial crown constructed with a metallic base veneered with a tooth-coloured material, manufactured to precisely adapt to a pre-prepared natural tooth`,
    rules: {},
    fee: 1678,
  },
  '618': {
    code: '618',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Full crown – metallic – indirect`,
    description: `An artificial crown constructed with custom fabricated metal, manufactured to precisely adapt to a preprepared natural tooth.`,
    rules: {},
    fee: 1730,
  },
  '625': {
    code: '625',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Post and core for crown – indirect',
    description:
      'A post and core fabricated accurately to the dimension of the prepared root canal(s) and the desired coronal anatomy to provide an anchor foundation for an artificial crown.',
    rules: {},
    fee: 477,
  },
  '627': {
    code: '627',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Preliminary restoration for crown – direct`,
    description: `A direct restoration of the remains of a tooth to provide a base for an intended indirect crown, placed at the same appointment as the crown preparation. Pins and/or posts used should be itemised separately.`,
    rules: {},
    fee: 237,
  },
  '629': {
    code: '629',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Post and root cap – indirect',
    description:
      'A post and capping fitted to the root of a tooth. The post provides an anchor in the root canal of the tooth and the capping may provide support for an overdenture. It may incorporate a precision or magnetic attachment that should be separately itemised.',
    rules: {},
  },
  '631': {
    code: '631',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Provisional crown – per tooth',
    description:
      'Provisional restoration of a tooth with a crown that is designed to last until the definitive crown can be constructed or the tooth is removed. This item should be used only where a provisional crown is not an intrinsic part of another service. It should not be used for a provisional restoration between appointments during crown construction.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
      optionalRelevantCodes: [632, 633],
    },
    fee: 248,
  },
  '632': {
    code: '632',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Provisional bridge pontic – per pontic',
    description:
      'Placement of a fixed provisional bridge pontic supported by teeth or implants designed to last until the definitive bridge can be constructed and inserted. The provisional restorations of the abutments are itemised separately as 631. The provisional implant abutments are itemised separately as 633. This item should be used only where a provisional bridge is not an intrinsic part of the treatment being provided. It should not be used for a provisional bridge between appointments during bridge construction.',
    rules: {
      scope: ServiceCodeScope.PerPontic,
      optionalRelevantCodes: [631, 633],
    },
    fee: 284,
  },
  '633': {
    code: '633',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Provisional implant abutment – per abutment',
    description:
      'Placement of a specific provisional abutment to an implant designed to last until the definitive abutment(s).are fitted. These are generally fitted to accommodate a provisional prosthesis.',
    rules: {
      scope: ServiceCodeScope.PerAbutment,
      optionalRelevantCodes: [631, 632],
    },
    fee: 338,
  },
  '634': {
    code: '634',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Provisional implant restoration – per implant abutment',
    description:
      'The provisional (intermediate) restoration of an implant designed to last until the definitive restoration can be placed. This item should be used only where the provisional (intermediate) restoration is not an intrinsic part of treatment.',
    rules: {
      scope: ServiceCodeScope.PerAbutment,
    },
    fee: 428,
  },
  '642': {
    code: '642',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Bridge pontic – direct – per pontic',
    description:
      'The direct attachment of a replacement tooth to the adjoining abutment tooth/teeth. The pontic and attachment may utilise reinforcing materials. The number of pontics should be indicated and the attaching restorations should be appropriately itemised.',
    rules: {
      scope: ServiceCodeScope.PerPontic,
    },
  },
  '643': {
    code: '643',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Bridge pontic – indirect – per pontic',
    description:
      'The indirect provision of an artificial replacement tooth joined to a retainer(s) on an abutment tooth or osseointegrated implant. The number of pontics should be indicated and the retainer should be appropriately itemised.',
    rules: {
      scope: ServiceCodeScope.PerPontic,
    },
    fee: 1183,
  },
  '644': {
    code: '644',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Semi-fixed attachment',
    description:
      'The preparation within a restoration of a mechanical lock or keyway matched by a complementary portion on a bridge or prosthetic appliance. The other components of the bridge or prosthetic appliance should be appropriately itemised.',
    rules: {},
  },
  '645': {
    code: '645',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Precision or magnetic attachment',
    description:
      'Incorporated as part of a restoration, or a magnetic or precision retention device matched by a complementary attachment on a bridge or other prosthetic appliance. The other components of the bridge or prosthetic appliance should be appropriately itemised. For the prosthesis component, see item 735.',
    rules: {},
  },
  '648': {
    code: '648',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Bonded retainer – direct – per tooth`,
    description: `A retainer directly bonded to the abutment tooth/teeth for support of the bridge pontic(s). The pontic(s) should be itemised separately`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '649': {
    code: '649',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Bonded retainer – indirect – per tooth`,
    description: `A retainer and framework to be bonded to the abutment tooth/teeth for support of the bridge pontic(s) or a precision attachment. In the case of a bonded bridge, the number of pontics should be itemised separately.`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '651': {
    code: '651',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Recementing crown or veneer',
    description: 'Recementing a previously cemented crown/veneer.',
    rules: {},
    fee: 173,
  },
  '652': {
    code: '652',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Recementing bridge or splint – per abutment',
    description: 'Recementing a previously cemented bridge or splint.',
    rules: {
      scope: ServiceCodeScope.PerAbutment,
    },
  },
  '653': {
    code: '653',
    scope: [ChartableSurface.MultipleTeeth],
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Rebonding of bridge or splint where retreatment of bridge surface is required',
    description:
      'The rebonding of an enamel bonded bridge or splint that requires retreatment of the fitting surface.',
    rules: {},
  },
  '655': {
    code: '655',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Removal of crown',
    description:
      'The removal of a currently cemented definitive crown that may subsequently be reinserted.',
    rules: {},
  },
  '656': {
    code: '656',
    scope: [ChartableSurface.MultipleTeeth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Removal of bridge or splint`,
    description: `The removal of all, or part, of a bridge or splint`,
    rules: {},
  },
  '658': {
    code: '658',
    scope: [ChartableSurface.WholeTooth, ChartableSurface.MultipleTeeth],
    taxStatus: TaxStrategy.GSTPossible,
    title: `Repair of crown or bridge – indirect – per crown or pontic @`,
    description: `The extraoral repair of a crown or bridge`,
    rules: {},
  },
  '659': {
    code: '659',
    scope: [ChartableSurface.WholeTooth, ChartableSurface.MultipleTeeth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Repair of crown, bridge or veneer – direct – per crown, pontic or veneer`,
    description: `The intraoral repair of a cemented crown, bridge or veneer`,
    rules: {},
  },
  '661': {
    code: '661',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Fitting of implant abutment – per abutment`,
    description: `Fitting of the final implant abutment or the replacement of an existing abutment. This includes abutments with attachments designed to retain an overdenture.`,
    rules: {
      scope: ServiceCodeScope.PerAbutment,
    },
    fee: 879,
  },
  '663': {
    code: '663',
    scope: [ChartableSurface.WholeTooth, ChartableSurface.Unscoped],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Removal of implant and/or retention device',
    description: 'The surgical removal of an implant and/or retention device.',
    rules: {},
  },
  '664': {
    code: '664',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Fitting of bar for denture – per tooth or implant`,
    description: `A rigid bar affixed to a natural tooth or osseointegrated implant abutment to provide support and retention for a dental prosthesis. The bar may incorporate retention devices and each of these should be itemised as 645. The retention components within the prosthesis are itemised as 735.`,
    rules: {
      scope: ServiceCodeScope.PerAbutment,
      optionalRelevantCodes: [645, 735],
    },
  },
  '665': {
    code: '665',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Prosthesis with resin base attached to implants – removable – per arch',
    description:
      'A removable prosthesis carrying artificial teeth on a resin base supported by the implants to which it is attached. This prosthesis is designed for self-removal. Abutments attached to implants should be itemised as 661; retention components within prosthesis should be itemised as 735.',
    rules: {
      scope: ServiceCodeScope.PerArch,
      optionalRelevantCodes: [735],
    },
    fee: 2315,
  },
  '666': {
    code: '666',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: `Prosthesis with metal frame attached to implants – fixed – per arch`,
    description: `A fixed prosthesis carrying prosthetic teeth on a custom fabricated metal frame, supported by the implants to which it is attached and not designed for self-removal. This is inclusive of the sealing of the access to the abutment screws. Abutments attached to implants itemised as 661.`,
    rules: {
      scope: ServiceCodeScope.PerArch,
      optionalRelevantCodes: [661],
    },
    fee: 6046,
  },
  '667': {
    code: '667',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: `Prosthesis with metal frame attached to implants – removable – per arch`,
    description: `A removable prosthesis carrying prosthetic teeth on a custom fabricated metal frame. This prosthesis is attached to the implants and is designed for self-removal. Abutments attached to implants itemised as 661; retention components within prosthesis should be itemised as 735`,
    rules: {
      scope: ServiceCodeScope.PerArch,
      optionalRelevantCodes: [661, 735],
    },
    fee: 4033,
  },
  '668': {
    code: '668',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Implant prosthetic screw replacement`,
    description: `Replacement of an existing implant prosthetic screw.`,
    rules: {},
  },
  '669': {
    code: '669',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Removal and reattachment of prosthesis fixed to implant(s) – per implant`,
    description: `Removal for maintenance of a prosthesis that is fixed to an osseointegrated implant(s) and not designed for self-removal. Access to attaching screws and subsequent replacement is required. Repairs effected should be appropriately itemised.`,
    rules: {
      scope: ServiceCodeScope.PerImplant,
    },
  },
  '671': {
    code: '671',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Full crown attached to osseointegrated implant – non-metallic – indirect',
    description:
      'An artificial crown constructed of tooth-coloured material attached to an osseointegrated implant.',
    rules: {},
  },
  '672': {
    code: '672',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Full crown attached to osseointegrated implant – veneered – indirect',
    description:
      'An artificial crown constructed with a metallic base veneered with a tooth-coloured material attached to an osseointegrated implant.',
    rules: {},
    fee: 1900,
  },
  '673': {
    code: '673',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Full crown attached to osseointegrated implant – metallic – indirect`,
    description: `An artificial crown constructed with custom fabricated metal attached to an osseointegrated implant.`,
    rules: {},
  },
  '678': {
    code: '678',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: `Diagnostic template`,
    description: `A diagnostic radiographic template that facilitates oral and/or maxillofacial surgical planning`,
    rules: {},
  },
  '679': {
    code: '679',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Surgical implant guide',
    description:
      'Provision of an appliance that indicates the ideal location and angulation for insertion of implants.',
    rules: {},
  },
  '684': {
    code: '684',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Insertion of the first stage of two-stage endosseous implant – per implant`,
    description: `Surgical insertion of an implant, made of a biocompatible material. The soft tissues are closed over the implant until osseointegration is achieved. The number of implants should be indicated.`,
    rules: {
      scope: ServiceCodeScope.PerImplant,
    },
  },
  '685': {
    code: '685',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Insertion of the first stage of two-stage maxillofacial endosseous implant – per implant`,
    description: `Surgical insertion of an implant made of a biocompatible material. The soft tissues are closed over the implant until osseointegration is achieved. The implant is used to retain a maxillofacial prosthesis. Item number 691 should be used for the second-stage procedure`,
    rules: {
      scope: ServiceCodeScope.PerImplant,
    },
  },
  '686': {
    code: '686',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Insertion of a one-stage maxillofacial endosseous implant – per implant`,
    description: `Surgical insertion of an implant made of a biocompatible material. The implant is used to retain a maxillofacial prosthesis`,
    rules: {
      scope: ServiceCodeScope.PerImplant,
    },
  },
  '687': {
    code: '687',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Insertion of a mini-implant – per implant`,
    description: `Surgical insertion of a small diameter implant (< 3.0 mm), made of biocompatible material, where the abutment is an integral component of the implant.`,
    rules: {
      scope: ServiceCodeScope.PerImplant,
    },
  },
  '688': {
    code: '688',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Insertion of a one-stage endosseous implant – per implant`,
    description: `Surgical insertion of an implant, made of biocompatible material.`,
    rules: {
      scope: ServiceCodeScope.PerImplant,
    },
    fee: 2275,
  },
  '689': {
    code: '689',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Provisional implant',
    description:
      'Special purpose implant designed with the intention of being removed at a later stage.',
    rules: {},
  },
  '690': {
    code: '690',
    scope: [ChartableSurface.Quadrant],
    taxStatus: TaxStrategy.GSTFree,
    title: `Provisional retention or anchorage device`,
    description: `Special purpose retention or anchorage device attached to the jaws by screws or provisional implants, to reinforce anchorage and assist with orthodontic movements, and intended for removal when no longer required for orthodontic treatment. Other orthodontic treatment is to be itemised separately`,
    rules: {},
  },
  '691': {
    code: '691',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Second stage surgery of two-stage endosseous implant – per implant',
    description:
      'Surgical access to the previously osseointegrated implant to attach a transmucosal component, usually a healing abutment. The number of implants should be indicated. Other items listed in the prosthodontics section may be directly applied in the description of implant prostheses, if appropriate.',
    rules: {
      scope: ServiceCodeScope.PerImplant,
    },
  },
  '692': {
    code: '692',
    taxStatus: TaxStrategy.GSTFree,
    title: `Closure of the screw access chamber`,
    description: `Subsequent sealing of the access chamber or screw channel of a screw-retained implant prosthesis, per implant. The sealing of the access chamber when delivering a new prosthesis is intrinsic to the provision of the prosthesis`,
    rules: {},
  },
  '693': {
    code: '693',
    taxStatus: TaxStrategy.GSTFree,
    title: `Remodelling of a fixed implant prosthesis`,
    description: `Remodelling of a screw-retained, metal-resin, implant supported fixed prosthesis attached to implants including replacement of veneering materials. The prosthetic teeth should be itemised separately as 765 per tooth replaced.`,
    rules: {},
  },
  '694': {
    code: '694',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTPossible,
    title: `Repair or replacement of the ceramic component of an implant metal-ceramic or all ceramic crown or bridge – per prosthetic tooth`,
    description: `The extraoral repair or replacement of the ceramic component of an implant crown or bridge – per prosthetic tooth`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '695': {
    code: '695',
    taxStatus: TaxStrategy.GSTPossible,
    title: `Cleaning and polishing of an implant prosthesis @`,
    description: `The extraoral cleaning of an implant prosthesis. This can include removal of calculus and plaque, polishing and disinfection. Screw replacement and closure of the screw access chamber should be itemised separately`,
    rules: {},
  },
  '696': {
    code: '696',
    taxStatus: TaxStrategy.GSTFree,
    title: `Removal of fractured abutment screw`,
    description: `Removal of the apical threaded part of a fractured abutment screw.`,
    rules: {},
  },
  '711': {
    code: '711',
    scope: [ChartableSurface.Arch], // Upper only
    taxStatus: TaxStrategy.GSTFree,
    title: 'Complete maxillary denture',
    description:
      'Provision of a patient removable dental prosthesis replacing the natural teeth and adjacent tissues in the maxilla.',
    rules: {},
    fee: 1522,
  },
  '712': {
    code: '712',
    scope: [ChartableSurface.Arch], // Lower only
    taxStatus: TaxStrategy.GSTFree,
    title: 'Complete mandibular denture',
    description:
      'Provision of a patient removable dental prosthesis replacing the natural teeth and adjacent tissues in the mandible.',
    rules: {},
    fee: 1534,
  },
  '713': {
    code: '713',
    scope: [ChartableSurface.Arch], // Upper only
    taxStatus: TaxStrategy.GSTFree,
    title: `Provisional complete maxillary denture`,
    description: `Provision of a patient-removable dental prosthesis replacing the natural teeth and adjacent tissues in the maxilla that is designed to last until the definitive denture can be constructed. This item should be used only where a provisional denture is not an intrinsic part of items 711 or 719. Tissue conditioning may be required and should be itemised separately (see item 771).`,
    rules: {},
  },
  '714': {
    code: '714',
    scope: [ChartableSurface.Arch], // Lower only
    taxStatus: TaxStrategy.GSTFree,
    title: `Provisional complete mandibular denture`,
    description: `Provision of a patient-removable dental prosthesis replacing the natural teeth and adjacent tissues in the mandible that is designed to last until the definitive prosthesis can be constructed. This item should be used only where a provisional denture is not an intrinsic part of items 712 or 719. Tissue conditioning may be required and should be itemised separately (see item 771).`,
    rules: {},
  },
  '715': {
    code: '715',
    scope: [ChartableSurface.WholeMouth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Provisional complete maxillary and mandibular dentures`,
    description: `Provision of a patient-removable dental prosthesis replacing the natural teeth and adjacent tissues in both the maxilla and the mandible that is designed to last until the definitive prosthesis can be constructed. This item should be used only where a provisional denture is not an intrinsic part of items 711, 712 or 719. Tissue conditioning may be required and should be itemised separately (see item 771)`,
    rules: {},
  },
  '716': {
    code: '716',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: `Metal palate or plate`,
    description: `A reinforcing custom fabricated metal section added to a resin denture base. The other denture components should be appropriately itemised.`,
    rules: {},
  },
  '719': {
    code: '719',
    scope: [ChartableSurface.WholeMouth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Complete maxillary and mandibular dentures',
    description:
      'Provision of patient removable dental prostheses for the natural teeth and adjacent tissues in both the maxilla and the mandible.',
    rules: {},
    fee: 2765,
  },
  '721': {
    code: '721',
    scope: [ChartableSurface.Arch], // Upper only
    taxStatus: TaxStrategy.GSTFree,
    title: 'Partial maxillary denture – resin base',
    description:
      'Provision of a resin base for a patient removable dental prosthesis for the maxilla where some natural teeth remain. Other components of the denture such as teeth, rests, retainers and immediate tooth replacements should be appropriately itemised.',
    rules: {},
    fee: 883,
  },
  '722': {
    code: '722',
    scope: [ChartableSurface.Arch], // Lower only
    taxStatus: TaxStrategy.GSTFree,
    title: 'Partial mandibular denture – resin base',
    description:
      'Provision of a resin base for a patient removable dental prosthesis for the mandible where some natural teeth remain. Other components of the denture such as teeth, rests, retainers and immediate tooth replacements should be appropriately itemised.',
    rules: {},
  },
  '723': {
    code: '723',
    scope: [ChartableSurface.Arch], // Upper only
    taxStatus: TaxStrategy.GSTFree,
    title: `Provisional partial maxillary denture`,
    description: `Provision of a patient-removable partial dental prosthesis replacing the natural teeth and adjacent tissues in the maxilla that is designed to last until the definitive prosthesis can be constructed. This item should be used only where a provisional denture is not an intrinsic part of item 721. Other components of the denture such as teeth, rests, retainers and immediate tooth replacements should be appropriately itemised. This includes a provisional, clear, pressure or vacuum formed appliance with incorporated denture teeth.`,
    rules: {},
  },
  '724': {
    code: '724',
    scope: [ChartableSurface.Arch], // Lower only
    taxStatus: TaxStrategy.GSTFree,
    title: `Provisional partial mandibular denture`,
    description: `Provision of a patient-removable partial dental prosthesis replacing the natural teeth and adjacent tissues in the mandible that is designed to last until the definitive prosthesis can be constructed. This item should be used only where a provisional denture is not an intrinsic part of item 722. Other components of the denture such as teeth, rests, retainers and immediate tooth replacements should be appropriately itemised. This includes a provisional, clear, pressure or vacuum formed appliance with incorporated denture teeth.`,
    rules: {},
  },
  '727': {
    code: '727',
    scope: [ChartableSurface.Arch], // Upper only
    taxStatus: TaxStrategy.GSTFree,
    title: `Partial maxillary denture – custom fabricated metal framework`,
    description: `Provision of the framework for a patient-removable dental prosthesis made with a custom fabricated metal on which to replace teeth from the maxilla where some natural teeth remain. Other components of the denture such as teeth, rests, retainers and immediate tooth replacements should be appropriately itemised.`,
    rules: {},
    fee: 1625,
  },
  '728': {
    code: '728',
    scope: [ChartableSurface.Arch], // Lower only
    taxStatus: TaxStrategy.GSTFree,
    title: `Partial mandibular denture – custom fabricated metal framework`,
    description: `Provision of the framework for a patient removable dental prosthesis made with a custom fabricated metal on which to replace teeth from the mandible where some natural teeth remain. Other components of the denture such as teeth, rests, retainers and immediate tooth replacements should be appropriately itemised`,
    rules: {},
  },
  '730': {
    code: '730',
    taxStatus: TaxStrategy.GSTFree,
    title: `A code number for Department of Veterans' Affairs use only`,
    description: '',
    rules: {},
    deleted: true,
  },
  '731': {
    code: '731',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Retainer (clasp) – per tooth`,
    description: `A retainer or clasp that is attached to a partial denture that is adapted to an undercut in a tooth to aid retention. The number of retainers should be indicated.`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
    fee: 52,
  },
  '732': {
    code: '732',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Occlusal rest – per rest',
    description:
      'A unit of a partial denture that rests upon a tooth surface to provide support for the denture. The number of rests used should be indicated.',
    rules: {
      scope: ServiceCodeScope.PerRest,
    },
  },
  '733': {
    code: '733',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Tooth/teeth (partial denture)`,
    description: `An item to describe each denture tooth added to the base of a new partial denture. The number of denture teeth should be indicated.`,
    rules: {},
    fee: 51,
  },
  '734': {
    code: '734',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Overlays – per tooth',
    description:
      'An extension of a denture covering the occlusal surface of remaining teeth or deliberately retained roots..The number of overlays should be indicated.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '735': {
    code: '735',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Precision or magnetic denture attachment',
    description:
      'A preformed device within a prosthesis that connects to a precision or magnetic component on a tooth or implant. For the tooth component, see item 645. For the implant component, see item 661.',
    rules: {
      optionalRelevantCodes: [645, 661],
    },
  },
  '736': {
    code: '736',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Immediate tooth replacement – per tooth',
    description:
      'Provision within a denture to allow immediate replacement of an extracted tooth. The number of teeth so replaced should be indicated.',
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '737': {
    code: '737',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Resilient lining',
    description:
      'Provision of a resilient tissue-bearing surface for a removable prosthesis. This item may be used with the provision of a new, or the maintenance of pre-existing, prostheses. Associated services should be itemised separately.',
    rules: {},
    fee: 307,
  },
  '738': {
    code: '738',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Wrought bar',
    description: 'A wrought bar joining sections of a partial prosthesis.',
    rules: {},
  },
  '739': {
    code: '739',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: `Metal backing of denture teeth – per backing`,
    description: `A metallic extension of the metal partial denture framework to provide a backing. The number of backings should be indicated`,
    rules: {
      scope: ServiceCodeScope.PerBacking,
    },
  },
  '741': {
    code: '741',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Adjustment of a denture',
    description:
      'Adjustment of a denture to improve comfort, function or aesthetics. This item does not apply to routine adjustments following the insertion of a new denture or the maintenance or repair of an existing denture.',
    rules: {},
    fee: 68,
  },
  '743': {
    code: '743',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Relining – complete denture – processed',
    description:
      'Replacement of the tissue fitting surface of a complete denture to improve its accuracy and fit. The procedure requires multiple appointments.',
    rules: {},
    fee: 446,
  },
  '744': {
    code: '744',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Relining – partial denture – processed',
    description:
      'Replacement of the tissue fitting surface of a partial denture to improve its accuracy and fit. The procedure requires multiple appointments.',
    rules: {},
    fee: 401,
  },
  '745': {
    code: '745',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Remodelling – complete denture',
    description:
      'Replacement of the resin base of a complete denture, with or without rearrangement of the teeth, to improve its accuracy and fit. The procedure requires multiple appointments.',
    rules: {},
  },
  '746': {
    code: '746',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Remodelling – partial denture',
    description:
      'Replacement of the resin base of a partial denture, with or without rearrangement of the teeth, to improve its accuracy and fit. The procedure requires multiple appointments.',
    rules: {},
  },
  '751': {
    code: '751',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Relining – complete denture – direct',
    description:
      'Addition to the tissue fitting surface of a complete denture to improve its accuracy and fit, using a self or light cured material.',
    rules: {},
  },
  '752': {
    code: '752',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Relining – partial denture – direct',
    description:
      'Addition to the tissue fitting surface of a partial denture to improve its accuracy and fit, using a self or light cured material.',
    rules: {},
  },
  '753': {
    code: '753',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Cleaning and polishing of pre-existing denture',
    description:
      'The cleaning and polishing of a pre-existing denture not associated with any other item of maintenance or repair of the denture.',
    rules: {},
  },
  '754': {
    code: '754',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: `Denture base modification`,
    description: `Modification to a denture base to enhance aesthetics, function or to facilitate the retention of denture teeth.`,
    rules: {},
  },
  '755': {
    code: '755',
    taxStatus: TaxStrategy.GSTFree,
    title: `Maintenance of overdenture attachment – per attachment`,
    description: `Replacement of a polymer insert in an overdenture attachment.`,
    rules: {},
  },
  '761': {
    code: '761',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTPossible,
    title: `Reattaching pre-existing retainer (clasp) to denture @`,
    description: `Repair, insertion and adjustment of a denture involving reattachment of a pre-existing retainer (clasp).`,
    rules: {},
  },
  '762': {
    code: '762',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Replacing/adding retainer (clasp) to denture – per retainer (clasp)`,
    description: `Repair, insertion and adjustment of a denture involving replacement or addition of a new retainer (clasp).`,
    rules: {
      scope: ServiceCodeScope.PerClasp,
    },
  },
  '763': {
    code: '763',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Repairing broken base of a complete denture',
    description:
      'Repair, insertion and adjustment of a broken resin complete denture base.',
    rules: {},
    fee: 210,
  },
  '764': {
    code: '764',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Repairing broken base of a partial denture',
    description:
      'Repair, insertion and adjustment of a broken resin partial denture base.',
    rules: {},
  },
  '765': {
    code: '765',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Replacing and/or adding a new tooth to a denture or implant prosthesis – per tooth`,
    description: `Repair, insertion and adjustment of a denture or implant prosthesis involving replacement with or addition of a new tooth or teeth to a previously existing denture or implant prosthesis`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  '766': {
    code: '766',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTPossible,
    title: `Reattaching an existing tooth to a denture – per denture tooth $`,
    description: `Repair, insertion and adjustment of a denture involving reattachment of a pre-existing denture tooth or teeth.`,
    rules: {},
  },
  '768': {
    code: '768',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Adding a denture tooth to a partial denture to replace an extracted or decoronated tooth – per denture tooth`,
    description: `Provision of a denture tooth on an existing partial denture to replace a natural tooth that has been removed or decoronated prior to or at the time of issue of the modified denture. If the denture tooth is an immediate replacement, item 736 can be used as well.`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
    fee: 214,
  },
  '769': {
    code: '769',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTPossible,
    title: `Repair or addition to metal frame $`,
    description: `Repair of or addition to the metal frame of a denture. This is a complex procedure requiring the dismantling of the denture. Associated services should be itemised separately.`,
    rules: {},
  },
  '771': {
    code: '771',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Tissue conditioning – per application',
    description: `The provisional (temporary) lining of the tissue fitting surface of a denture with a conditioning material, to improve the health of the denture-supporting mucosa.`,
    rules: {},
  },
  '772': {
    code: '772',
    scope: [ChartableSurface.MultipleTeeth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Splint – resin`,
    description: `A resin appliance attached to the teeth for stabilising mobile or displaced teeth`,
    rules: {},
  },
  '773': {
    code: '773',
    scope: [ChartableSurface.MultipleTeeth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Splint – metal – indirect`,
    description: `A custom fabricated metal appliance bonded to the teeth to stabilise mobile or displaced teeth.`,
    rules: {},
  },
  '774': {
    code: '774',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: `Obturator`,
    description: `That component of a maxillofacial prosthesis used to close or maintain the integrity of the oral and nasal compartments resulting from a congenital or acquired defect. For a surgical/interim obturator see item 782`,
    rules: {},
    deleted: true,
  },
  '775': {
    code: '775',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Characterisation of denture base',
    description:
      'Stippling, staining, festooning or shaping rugae on the appropriate surface(s) of a denture.',
    rules: {},
  },
  '776': {
    code: '776',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Impression – dental appliance repair/modification',
    description:
      'An item to describe taking an impression where required for the repair or modification of a dental appliance.',
    rules: {},
    fee: 65,
  },
  '777': {
    code: '777',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Identification',
    description: `Marking a dental appliance with a patient's name or other form of enduring patient identification.`,
    rules: {},
  },
  '778': {
    code: '778',
    scope: [ChartableSurface.WholeTooth],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Inlay for denture tooth',
    description: 'Provision of an inlay in a denture tooth.',
    rules: {},
  },
  '779': {
    code: '779',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Surgical guide for an immediate denture',
    description:
      'Provision of an appliance that indicates the final ridge contours following extraction of teeth prior to immediate denture insertion.',
    rules: {},
  },
  '781': {
    code: '781',
    scope: [ChartableSurface.Arch], // Upper only
    taxStatus: TaxStrategy.GSTFree,
    title: `Obturator`,
    description: `That component of a maxillofacial prosthesis used to close or maintain the integrity of the oral and nasal compartments resulting from a congenital or acquired defect. For a surgical/interim obturator see item 782`,
    rules: {},
  },
  '782': {
    code: '782',
    scope: [ChartableSurface.Arch], // Upper only
    taxStatus: TaxStrategy.GSTFree,
    title: `Surgical/interim obturator`,
    description: `A temporary maxillofacial prosthesis inserted during or immediately following surgical or traumatic loss of a portion or all of the maxilla and contiguous alveolar structures`,
    rules: {},
  },
  '783': {
    code: '783',
    taxStatus: TaxStrategy.GSTFree,
    title: `Revision of surgical/interim obturator`,
    description: `Revision of a surgical/interim obturator during the ensuing post-surgical healing phase.`,
    rules: {},
  },
  '785': {
    code: '785',
    taxStatus: TaxStrategy.GSTFree,
    title: `Interim or diagnostic maxillofacial prosthesis`,
    description: `A provisional maxillofacial prosthesis delivered either at the time of surgery or soon after or during the rehabilitation phase of care to facilitate the design of the definitive prosthesis.`,
    rules: {},
  },
  '786': {
    code: '786',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: `Mandibular resection prosthesis`,
    description: `A maxillofacial prosthesis used to maintain a functional position for the jaws, improve speech and deglutition following trauma and/or surgery to the mandible and/or adjacent structures.`,
    rules: {},
  },
  '787': {
    code: '787',
    taxStatus: TaxStrategy.GSTFree,
    title: `Extraoral prosthesis – ear`,
    description: `An extraoral prosthesis that replaces an ear, retained by spectacles, adhesive or implants. The implants and attachments should be itemised separately.`,
    rules: {},
  },
  '788': {
    code: '788',
    taxStatus: TaxStrategy.GSTFree,
    title: `Extraoral prosthesis – nose`,
    description: `An extraoral prosthesis that replaces the nose and/or parts of the midface, retained by spectacles, adhesive or implants. The implants and attachments should be itemised separately.`,
    rules: {},
  },
  '789': {
    code: '789',
    taxStatus: TaxStrategy.GSTFree,
    title: `Extraoral prosthesis – eye`,
    description: `An extraoral prosthesis that replaces an eye, retained by spectacles, adhesive or implants. The implants and attachments should be itemised separately.`,
    rules: {},
  },
  '790': {
    code: '790',
    taxStatus: TaxStrategy.GSTFree,
    title: `Adjustment of a maxillofacial prosthesis`,
    description: `Adjustment of a maxillofacial prosthesis to improve comfort, function or aesthetics. This item does not apply to routine adjustments following the insertion of the prosthesis.`,
    rules: {},
  },
  '811': {
    code: '811',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Passive removable appliance – per arch',
    description:
      'A removable, one-arch appliance that may be attached to the dentition by clasps or similar device designed to maintain the position of the teeth in the dentition. The appliance does not provide pressure directed at tooth movement. Passive removable appliances are sometimes used following orthodontic active treatment to maintain a correction.',
    rules: {
      scope: ServiceCodeScope.PerArch,
    },
  },
  '821': {
    code: '821',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Active removable appliance – per arch',
    description:
      'A removable, one-arch appliance attached to the dentition by clasps or similar device that contains some elements capable of exerting pressure on either individual teeth or parts of the arch to achieve tooth or dental arch movement.',
    rules: {
      scope: ServiceCodeScope.PerArch,
    },
    fee: 995,
  },
  '823': {
    code: '823',
    scope: [ChartableSurface.WholeMouth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Functional orthopaedic appliance – custom fabrication',
    description:
      'The custom fabrication on models of an appliance whose primary action involves orthopaedic change of jaw shape or relationship utilising and modifying the effect of the environmental tissues. Many are bi-maxillary appliances involving upper and lower arches. Bi-maxillary appliances are regarded as a single appliance.',
    rules: {},
    fee: 1548,
  },
  '824': {
    code: '824',
    scope: [ChartableSurface.WholeMouth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Functional orthopaedic appliance – prefabricated',
    description:
      'A prefabricated elastomeric appliance whose primary action is to maintain or guide the positioning of teeth during eruption, and/or to influence muscle activity of the mouth and lower face. It can also be used to maintain tooth position following orthodontic alignment. Many are bi-maxillary appliances involving upper and lower arches. Bi-maxillary appliances are regarded as a single appliance.',
    rules: {},
  },
  '825': {
    code: '825',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: `Sequential plastic aligners – per arch`,
    description: `The provision and periodic direct clinical review by a dentist or orthodontist of a series of custom-made plastic aligners to move teeth gradually. This item number includes additional procedures that may be required to facilitate the movement of teeth, such as composite buttons. For retention appliances see 811 and 845`,
    rules: {
      scope: ServiceCodeScope.PerArch,
      optionalRelevantCodes: [811, 845],
    },
    fee: 2585,
  },
  '829': {
    code: '829',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Partial banding – per arch',
    description:
      'Application of bands and/or brackets to six teeth or fewer in the maxillary or mandibular arch, which can be attached to a resilient arch wire. The appliance is designed to correct either tooth position or arch form.',
    rules: {
      scope: ServiceCodeScope.PerArch,
    },
  },
  '831': {
    code: '831',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: `Full arch banding – per arch`,
    description: `Application of bands and brackets for attachment of resilient arch wires to most of the teeth of the maxillary or mandibular arch to correct the position of teeth or to correct the arch form. This procedure includes ongoing adjustments and replacement of arch wires. For removal of banding, see item 833. For retention appliances, see items 811 and 845. Additional orthodontic services may be necessary prior to or following full arch banding and should be itemised separately`,
    rules: {
      scope: ServiceCodeScope.PerArch,
      optionalRelevantCodes: [833, 811, 845],
    },
    fee: 2653,
  },
  '833': {
    code: '833',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Removal of banding – per arch',
    description: 'The removal of the bands and/or brackets from the teeth.',
    rules: {
      scope: ServiceCodeScope.PerArch,
    },
  },
  '841': {
    code: '841',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Fixed palatal or lingual arch appliance',
    description:
      'Insertion of an appliance, fixed to the palatal or lingual aspect of the dentition by bands, frequently applied in the molar region. It is aimed at either changing or maintaining the arch form and can also be designed to straighten individual teeth.',
    rules: {},
  },
  '842': {
    code: '842',
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Partial banding for inter-maxillary elastics (vertical and/or cross elastics)',
    description:
      'Application of bands and brackets to two or more teeth in maxillary and mandibular arches. Resilient arch wires may be attached to the brackets and inter-maxillary elastics between the two arches. The resulting appliances are designed to correct the position of teeth or arch form.',
    rules: {},
  },
  '843': {
    code: '843',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Expansion appliance – fixed – per arch',
    description:
      'Insertion of an appliance fixed to the maxillary or mandibular dentition by partial banding that exerts a force to expand or widen the maxillary or mandibular arch and/or teeth.',
    rules: {
      scope: ServiceCodeScope.PerArch,
    },
    fee: 1277,
  },
  '844': {
    code: '844',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: `Sagittal movement appliance`,
    description: `Insertion of an appliance fixed to the maxilla or mandible by temporary anchorage device/s. The appliance
      exerts force to move teeth sagittally. It may also have some orthopaedic effect.`,
    rules: {},
  },
  '845': {
    code: '845',
    scope: [
      ChartableSurface.Arch,
      ChartableSurface.WholeTooth,
      ChartableSurface.MultipleTeeth,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Passive appliance – fixed',
    description:
      'A passive appliance fixed to one or more teeth, designed to prevent movement of the teeth relative to each other or to the segment of the arch. The appliance can be used to maintain a space from the loss of a tooth..Fixed passive appliances may be used following active orthodontic treatment.',
    rules: {},
  },
  '846': {
    code: '846',
    scope: [
      ChartableSurface.Arch,
      ChartableSurface.WholeTooth,
      ChartableSurface.MultipleTeeth,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Minor tooth guidance – fixed',
    description:
      'A procedure using an appliance attached directly to teeth that provides movement or guidance to correct the position of a tooth.',
    rules: {},
  },
  '851': {
    code: '851',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Extraoral appliance',
    description:
      'This item describes a number of appliances worn extraorally. Some appliances are worn to correct mandibular protrusion and are not attached directly to the dentition. Other appliances in this category are attached to the dentition by either full or partial banding. Most of these appliances have a harness that is passed behind the head or to the front of the face to provide stabilisation for the forces applied. They are usually worn for a limited time each day.',
    rules: {},
  },
  '862': {
    code: '862',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Bonding of attachment for application of orthodontic force',
    description:
      'The bonding of a prefabricated attachment on a malpositioned tooth to facilitate orthodontic force application..The force application may utilise either fixed or removable appliances. This item should not be used to describe bracket attachment required by partial or full arch banding (items 829 or 831). Nor does it apply in conjunction with surgical exposure. The appropriate item in this case is item 382.',
    rules: {},
  },
  '871': {
    code: '871',
    taxStatus: TaxStrategy.GSTFree,
    title: `Orthodontic adjustment`,
    description: `Adjustment of an orthodontic appliance, either fixed or removable, that is not associated with ongoing treatment anticipated at the time of insertion of the appliance. This item should not be used with item numbers 811, 821, 823, 824, 825, 829, 831, 841, 842, 843, 844, 845, 846, 851, 862, 881 or 882.`,
    rules: {},
  },
  '872': {
    code: '872',
    scope: [
      ChartableSurface.Arch,
      ChartableSurface.WholeTooth,
      ChartableSurface.MultipleTeeth,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Reattachment of passive appliance – fixed',
    description:
      'The reattachment of a passive fixed appliance that may include any necessary cleaning or polishing of the appliance and/or teeth to which it is attached or bonded.',
    rules: {},
  },
  '873': {
    code: '873',
    scope: [
      ChartableSurface.Arch,
      ChartableSurface.WholeTooth,
      ChartableSurface.MultipleTeeth,
    ],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Repair of passive appliance – fixed',
    description:
      'The repair of a fixed passive appliance. This includes the removal and subsequent re-fitting of the appliance..Where an impression is required, it should be itemised appropriately.',
    rules: {},
  },
  '874': {
    code: '874',
    scope: [
      ChartableSurface.Arch,
      ChartableSurface.WholeTooth,
      ChartableSurface.MultipleTeeth,
    ],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Removal of passive appliance – fixed',
    description:
      'The removal of a passive fixed appliance and the necessary cleaning of the teeth to which it was attached.',
    rules: {},
  },
  '875': {
    code: '875',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTApplicable,
    title: 'Repair of removable appliance – resin base',
    description:
      'Repair of the resin base of a removable appliance. Where an impression is required, it should be itemised appropriately.',
    rules: {},
  },
  '876': {
    code: '876',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Repair of removable appliance – clasp, spring or tooth',
    description:
      'Repair or replacement of a clasp, spring or tooth on a removable appliance. Where an impression is required,.it should be itemised appropriately.',
    rules: {},
  },
  '877': {
    code: '877',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Addition to removable appliance – clasp, spring or tooth',
    description:
      'Addition of a clasp, spring or tooth to a removable appliance. Where an impression is required, it should be itemised appropriately.',
    rules: {},
  },
  '878': {
    code: '878',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Relining – removable appliance – processed',
    description:
      'Replacement of the tissue fitting surface of a removable appliance to improve its accuracy and fit.',
    rules: {},
  },
  '881': {
    code: '881',
    scope: [ChartableSurface.WholeMouth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Course of orthodontic treatment`,
    description: `This is an alternative system of coding encompassing all appointments subsequent to orthodontic diagnosis and treatment planning. The item refers to a complex course of treatment of active fixed appliance therapy in both arches and retention as required. If removable and/or fixed retention, functional appliances, sequential clear plastic aligner therapy, headgear, or any other complementary auxiliary appliances are utilised, they are an intrinsic part of this item number`,
    fee: 5163,
  },
  '882': {
    code: '882',
    scope: [ChartableSurface.WholeMouth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Course of orthodontic treatment with orthognathic surgery`,
    description: `This is an alternative system of coding encompassing all appointments subsequent to orthodontic diagnosis and treatment planning of an orthodontic case requiring adjunctive orthognathic surgery. The item refers to a treatment program of pre-surgical and post-surgical orthodontics utilising active fixed appliance therapy or sequential clear plastic aligner therapy, a combination of both, in both arches plus collaborative surgical planning and retention as required. If removable and/or fixed retention, functional appliances, headgear, surgical splints or any other complementary auxiliary appliances are utilised, they are an intrinsic part of this item number`,
  },
  '911': {
    code: '911',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Palliative care – per appointment',
    description: `An item to describe interim care to relieve pain, infection, bleeding or other problems not associated with other treatment, per appointment.`,
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
    fee: 88,
  },
  '915': {
    code: '915',
    taxStatus: TaxStrategy.GSTFree,
    title: 'After-hours callout',
    description:
      'An additional item to describe provision of treatment required after hours. Treatment provided should be itemised.',
    rules: {},
  },
  '916': {
    code: '916',
    taxStatus: TaxStrategy.GSTFree,
    title: `Travel to provide services`,
    description: `An additional item to describe travel from the usual place of practice to provide treatment. Not applicable to mobile dental services, branch practices or outreach services. Treatment provided should be itemised separately.`,
    rules: {},
  },
  '919': {
    code: '919',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Teleconsultation',
    description: `The remote provision of consultation and support to a patient by a dental practitioner. Items 131, 141 and 142 should be itemised separately`,
    rules: {},
  },
  '920': {
    code: '920',
    taxStatus: TaxStrategy.GSTPossible,
    title: `Extended teleconsultation – 30 minutes or more #`,
    description: `The remote provision of an extended consultation and support to a patient by a dental practitioner. Items 131, 141 and 142 should be itemised separately`,
    rules: {},
  },
  '921': {
    code: '921',
    taxStatus: TaxStrategy.GSTPossible,
    title: `Teleconsultation by referral #`,
    description: `The remote provision by referral of a consultation and support to a patient by a dental practitioner. Items 131, 141 and 142 should be itemised separately`,
    rules: {},
  },
  '926': {
    code: '926',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Individually made tray – medicament(s)',
    description:
      'A tray made for the application of medicaments to the teeth or supporting tissues.',
    rules: {},
    fee: 122,
  },
  '927': {
    code: '927',
    taxStatus: TaxStrategy.GSTPossible,
    title: `Provision of medication/medicament #`,
    description: `The supply, or administration under professional supervision, of appropriate medications and medicaments required for dental treatments.`,
    rules: {},
  },
  '928': {
    code: '928',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Intravenous cannulation and establishment of infusion',
    description:
      'The procedure of performing venepuncture, insertion of a cannula within the lumen of a vein and the establishment of infusion.',
    rules: {},
  },
  '929': {
    code: '929',
    taxStatus: TaxStrategy.GSTPossible,
    title: `Provision of neuromodulator therapy #`,
    description: `Injection of neuromodulators for the treatment of oral and maxillofacial diseases and disorders`,
    rules: {},
  },
  '941': {
    code: '941',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Local anaesthesia',
    description:
      'A procedure where local anaesthetic is used as a specific treatment for diagnosis or relief of pain and is not associated with other treatments.',
    rules: {},
  },
  '942': {
    code: '942',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Sedation – intravenous – per 30 minutes or part thereof',
    description:
      'Sedative drug(s) administered intravenously, usually in increments. The incremental administration may continue while dental treatment is being provided.',
    rules: {
      scope: ServiceCodeScope.PerTime,
      scopeValue: 30,
    },
    fee: 245,
  },
  '943': {
    code: '943',
    taxStatus: TaxStrategy.GSTFree,
    title: `Relative analgesia – inhalation of nitrous oxide and oxygen mixture – per 30 minutes or part thereof`,
    description: `The use of nitrous oxide and oxygen mixture inhalation to provide anxiolysis and analgesia while dental treatment is being provided.`,
    rules: {
      scope: ServiceCodeScope.PerTime,
      scopeValue: 30,
    },
    fee: 88,
  },
  '944': {
    code: '944',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Relaxation therapy',
    description:
      'Therapy that does not involve the use of drugs but that induces a lowered state of mental or autonomic arousal.',
    rules: {},
  },
  '945': {
    code: '945',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Low level laser therapy – per appointment',
    description:
      'Low level laser therapy (LLLT), also known as biostimulation or photobiomodulation, may be used for various dental applications.',
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
  },
  '948': {
    code: '948',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Dental acupuncture – per appointment',
    description:
      'Treatment by acupuncture needle insertion and/or laser, pressure or heat at specific and non-specific points where the same effect is achieved without a puncture.',
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
  },
  '949': {
    code: '949',
    taxStatus: TaxStrategy.GSTFree,
    title: `Treatment under general anaesthesia/sedation`,
    description: `The treatment of a patient under a general anaesthetic/sedation administered by another registered practitioner. This may require the provision of additional equipment, instruments, materials and staff by the practitioner(s) providing the dental service(s). The dental treatment provided should be itemised separately`,
    rules: {},
    fee: 225,
  },
  '961': {
    code: '961',
    scope: [ChartableSurface.WholeTooth, ChartableSurface.MultipleTeeth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Minor occlusal adjustment – per appointment',
    description:
      'The detection and correction of minor irregularities and traumatic tooth contacts.',
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
  },
  '963': {
    code: '963',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Clinical occlusal analysis, including muscle and joint palpation',
    description:
      'This item consists of the recording of three separate assessments:.(a) Occlusal assessment.An assessment of tooth contacts in various jaw positions, parafunctional activity, vertical dimensions,.tongue posture and speech..(b) Muscle assessment.Assessment by intraoral and extraoral palpation of jaw muscles; cervical muscle palpation; and measurement of jaw and cervical mobility..(c) Joint assessment.Includes palpation and auscultation of the temporomandibular joints, together with assessment of joint play. This item may be used in conjunction with other services.',
    rules: {},
  },
  '964': {
    code: '964',
    taxStatus: TaxStrategy.GSTPossible,
    title: `Registration and mounting of dental models for occlusal analysis #`,
    description: `This item describes the clinical procedures for accurate mounting of dental models or prostheses on an adjustable articulator or digital equivalent.`,
    rules: {},
  },
  '965': {
    code: '965',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title: `Occlusal appliance`,
    description: `An indirectly made custom occlusal appliance that fits over the functional surfaces of either the upper or the lower teeth used for diagnosis or therapy. It can be utilised in the following ways: for treatment of temporomandibular disorders; or as a diagnostic overlay prior to extensive rehabilitation; or to assist in preventing parafunctional wear or damage of teeth or restorations; or as an appliance made to locate the dentition and the jaws in predetermined positions when orthognathic jaw surgery is performed`,
    rules: {},
    fee: 594,
  },
  '966': {
    code: '966',
    taxStatus: TaxStrategy.GSTFree,
    title: `Adjustment of pre-existing occlusal appliance – per appointment`,
    description: `Alteration of a pre-existing occlusal appliance.`,
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
  },
  '967': {
    code: '967',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Pantographic tracing',
    description:
      'The clinical procedure of attaching and using a specifically designed recording apparatus to record graphically the movements of the jaw.',
    rules: {},
  },
  '968': {
    code: '968',
    scope: [ChartableSurface.WholeTooth, ChartableSurface.MultipleTeeth],
    taxStatus: TaxStrategy.GSTFree,
    title: 'Occlusal adjustment following occlusal analysis – per appointment',
    description:
      'The adjustment of the occlusion involving either natural or artificial teeth following occlusal analysis. The use of this item implies that procedures described as items 963 and/or 964 have been performed as a preparation for the occlusal adjustment.',
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
  },
  '971': {
    code: '971',
    taxStatus: TaxStrategy.GSTFree,
    title: `Physical therapy for temporomandibular joint and associated structures – per appointment `,
    description: `Application of adjunctive therapy such as heat, other radiation or ultrasonic therapy, as part of overall therapy of the temporomandibular joint and associated structures`,
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
  },
  '972': {
    code: '972',
    taxStatus: TaxStrategy.GSTPossible,
    title: `Repair/addition – occlusal appliance $`,
    description: `The repair/addition, reinsertion and adjustment of an occlusal appliance. An impression(s), if required, should be itemised`,
    rules: {},
  },
  '981': {
    code: '981',
    scope: [ChartableSurface.WholeTooth, ChartableSurface.MultipleTeeth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Splinting and stabilisation – direct – per tooth`,
    description: `The joining of adjacent teeth to provide mutual support (where not covered by item 386 or 387`,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
    fee: 148,
  },
  '982': {
    code: '982',
    taxStatus: TaxStrategy.GSTFree,
    title: 'Enamel stripping – per appointment',
    description:
      'The removal of enamel from the interdental surfaces of a tooth/teeth to reduce width.',
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
  },
  '983': {
    code: '983',
    scope: [ChartableSurface.Arch],
    taxStatus: TaxStrategy.GSTFree,
    title:
      'Single arch oral appliance for diagnosed snoring and obstructive snoring and sleep apnoea',
    description:
      'On request from a specialist physician, the provision and appropriate dental supervision of a removable single arch oral appliance to assist in the treatment of assessed snoring and obstructive sleep apnoea disorders. Reference should be made to the report published by the American Academy of Sleep Medicine entitled “Clinical Practice Guideline for the Treatment of Obstructive Sleep Apnoea and Snoring with Oral.Appliance Therapy: An Update for 2015” as endorsed by the ADA.',
    rules: {},
    deleted: true,
  },
  '984': {
    code: '984',
    scope: [ChartableSurface.WholeMouth],
    taxStatus: TaxStrategy.GSTFree,
    title: `Mandibular advancement appliance for diagnosed snoring and obstructive sleep apnoea`,
    description: `On request from an appropriately qualified medical practitioner, the provision and appropriate dental supervision of a custom, titratable, removable oral appliance for the treatment of diagnosed snoring and obstructive sleep apnoea disorders. Reference should be made to the report published by the American Academy of Sleep Medicine entitled Clinical Practice Guideline for the Treatment of Obstructive Sleep Apnoea and Snoring with Oral Appliance Therapy: An Update for 2015 as endorsed by the ADA.`,
    rules: {},
    fee: 1537,
  },
  '985': {
    code: '985',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Repair/addition – snoring or sleep apnoea device',
    description:
      'The repair/addition, reinsertion and adjustment of a snoring or sleep apnoea device. Any impression(s),.if required, should be itemised. See item 776.',
    rules: {},
  },
  '986': {
    code: '986',
    taxStatus: TaxStrategy.GSTFree,
    title: `Post-operative care not otherwise included`,
    description: `The provision of post-operative care by a dental practitioner who did not provide the initial treatment`,
    rules: {},
  },
  '987': {
    code: '987',
    taxStatus: TaxStrategy.GSTPossible,
    title: `Recontour tissue – per appointment #`,
    description: `Recontour of oral and associated tissue not described elsewhere in the Thirteenth Edition of the Australian Schedule of Dental Services and Glossary, such as injection of dermal fillers.`,
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
  },
  '990': {
    code: '990',
    taxStatus: TaxStrategy.GSTPossible,
    title: 'Treatment not otherwise included (specify)',
    description:
      'An item number used to identify dental treatment not elsewhere described. Adequate written description of the service is required.',
    rules: {},
  },
  '999': {
    code: '999',
    taxStatus: TaxStrategy.GSTApplicable,
    title: 'GST',
    description:
      'This item number is for use where practice accounting systems cannot accommodate GST applicable to nonclinical components.',
    rules: {},
  },
  '281': {
    code: '281',
    title: 'Non - surgical periodontal treatment',
    description: '',
    taxStatus: TaxStrategy.GSTFree,
    rules: {},
    deleted: true,
  },
  '282': {
    code: '282',
    title: 'Continuation Of Periodontal Treatment',
    description: '',
    taxStatus: TaxStrategy.GSTFree,
    rules: {},
    deleted: true,
  },
  '767': {
    code: '767',
    title:
      'Any repair or tooth replacement in addition to other repairs, alternations or other modifications for same denture on same day',
    description: '',
    taxStatus: TaxStrategy.GSTFree,
    rules: {},
    deleted: false,
  },
  '582': {
    code: '582',
    title: 'Veneer – direct',
    description: '',
    taxStatus: TaxStrategy.GSTFree,
    rules: {},
    deleted: true,
  },
  '583': {
    code: '583',
    title: 'Veneer – indirect',
    description: '',
    taxStatus: TaxStrategy.GSTFree,
    rules: {},
    deleted: true,
  },
  '325': {
    code: '325',
    title: 'Surgical removal tooth fragment - soft tissue',
    description: '',
    taxStatus: TaxStrategy.GSTFree,
    rules: {},
    deleted: true,
  },
  '326': {
    code: '326',
    title: 'Surgical removal tooth fragment - bone',
    description: '',
    taxStatus: TaxStrategy.GSTFree,
    rules: {},
    deleted: true,
  },
  // tyro
  '023': {
    code: '023',
    title: 'Tyro Test ADA Code (Non CDBS)',
    description: 'Tyro Test ADA Code (Non CDBS)',
    taxStatus: TaxStrategy.GSTFree,
    rules: {},
    deleted: true,
  },
  '5001': {
    code: '5001',
    title: 'Tyro Test ADA Code',
    description: 'Tyro Test ADA Code',
    taxStatus: TaxStrategy.GSTFree,
    rules: {},
    deleted: true,
  },
  '20200': {
    code: '20200',
    title: 'Tyro Bulk Bill Test ADA Code',
    description: 'Tyro Bulk Bill Test ADA Code',
    taxStatus: TaxStrategy.GSTFree,
    rules: {},
    deleted: true,
  },
  '034': {
    code: '034',
    title: 'Lateral Skull X-ray',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '046': {
    code: '046',
    title: 'Perio Disease Activity Kit',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '112': {
    code: '112',
    title: 'Scale & Clean',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '312': {
    code: '312',
    title: 'Removal Root Fragment',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '313': {
    code: '313',
    title: 'Removal Of Deciduous Tooth',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '321': {
    code: '321',
    title: 'Surgical Removal Erupted Tooth',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '329': {
    code: '329',
    title: 'Non Routine Post Op Treat - Per Visit',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '396': {
    code: '396',
    title: 'Orthopaedic',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '397': {
    code: '397',
    title: 'Reconstructive Plastic Surgery',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '398': {
    code: '398',
    title: 'Minor Soft Tissue Surgery',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '422': {
    code: '422',
    title: 'Double Root Therapy',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '423': {
    code: '423',
    title: 'Triple Root Therapy',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '424': {
    code: '424',
    title: 'Quad Root Therapy',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '454': {
    code: '454',
    title: 'Preparation Of Root Canal For Post',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '599': {
    code: '599',
    title: 'Full coronal ant - white - direct',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '617': {
    code: '617',
    title: 'Porcelain Veneer Crown',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '628': {
    code: '628',
    title: 'Composite / GIC Core For Crown',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '662': {
    code: '662',
    title: 'Provisional Implant Crown Abutment - Per Abutment',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
  '742': {
    code: '742',
    title: 'Adjustment Of Partial Denture',
    description: '',
    taxStatus: TaxStrategy.GSTPossible,
    deleted: true,
  },
};

export const ADA_CODE_MAP: ItemCodeAliases = Object.entries(
  BASE_ADA_CODES
).reduce(
  (codes, [key, code]) => ({
    ...codes,
    [key]: {
      scope: [ChartableSurface.Unscoped],
      rules: {},
      deleted: false,
      ...code,
      type: ServiceCodeType.ADA,
    },
  }),
  {}
);

export const ADA_SERVICE_CODES: IServiceCode[] = Object.values(ADA_CODE_MAP);

export const ADA_SERVICE: IServiceProvider = {
  label: 'ADA',
  description: 'Australia ADA Codes',
  type: ServiceCodeType.ADA,
  items: ADA_CODE_MAP,
  region: Region.Australia,
};
