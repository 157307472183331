import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CalendarFacade } from '@principle-theorem/ng-calendar/store';
import { CalendarUnit } from '@principle-theorem/principle-core/interfaces';
import { type Observable } from 'rxjs';

@Component({
  selector: 'pr-calendar-toolbar',
  templateUrl: './calendar-toolbar.component.html',
  styleUrls: ['./calendar-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarToolbarComponent {
  unit$: Observable<CalendarUnit>;

  constructor(private _calendarFacade: CalendarFacade) {
    this.unit$ = this._calendarFacade.unit$;
  }
}
