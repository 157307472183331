<pt-loader-button
  type="flat"
  color="primary"
  [cta]="true"
  [ngClass]="{ adding: adding$ | async, disabled: isDisabled$ | async }"
  [disabled]="isDisabled$ | async"
  [loading]="adding$ | async"
  [matMenuTriggerFor]="refundMenu"
>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <mat-icon *ngIf="(adding$ | async) === false"> currency_exchange </mat-icon>

    <div fxLayout="column" fxLayoutAlign="center start">
      <div class="label">Refund</div>
      <div *ngIf="info$ | async as subtext" class="subtext mat-caption">
        {{ subtext }}
      </div>
    </div>
  </div>
</pt-loader-button>

<mat-menu #refundMenu="matMenu" class="no-padding">
  <div>
    <pr-transaction-action-menu-button
      *ngFor="
        let refundable of refundableTransactions$ | async;
        trackBy: trackByRefundable
      "
      [action]="refundable.action"
      [data]="refundable.data"
    />

    <mat-divider />

    <pr-add-transaction-menu-button
      *ngFor="
        let option of transactions.refundProviderOptions$ | async;
        trackBy: transactions.trackByOption
      "
      [option]="option"
      [invoice]="invoice$ | async"
      [refund]="true"
    />
  </div>
</mat-menu>
