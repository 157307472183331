import { type ICSVExport } from '@principle-theorem/ng-shared';
import { ReportBuilderDataSource } from '../../../models/report-builder-data-sources/report-builder-data-source';
import { DataPointValueFormatter } from '../../core/data-point-value-formatter';
import {
  type IResolvedColumnProperty,
  ResolvedColumns,
} from './report-builder-column-selector-dialog/resolved-columns';
import { MeasureFormatter } from '@principle-theorem/principle-core/interfaces';

interface ICSVColumn {
  id: string;
  label: string;
  data: IResolvedColumnProperty;
}

export class ReportBuilderCSV
  implements ICSVExport<Record<string, unknown>, Record<string, unknown>>
{
  defaultFileName = 'report-builder';
  headers: string[] = [];
  columns: ICSVColumn[];

  constructor(private _selectedColumns: IResolvedColumnProperty[]) {
    this.columns = this._selectedColumns.map((column) => ({
      id: column.definition.id,
      label: ResolvedColumns.getLabel(column),
      data: column,
    }));
    this.headers = this.columns.map((column) => column.label);
  }

  translate(
    records: Record<string, unknown>[]
  ): Record<string, unknown>[] | Promise<Record<string, unknown>[]> {
    return records.map((record) => this._translateRecord(record));
  }

  private _translateRecord(
    record: Record<string, unknown>
  ): Record<string, unknown> {
    return this.columns.reduce(
      (acc: Record<string, unknown>, column) => ({
        ...acc,
        [column.id]: this._getValue(column, record),
      }),
      {}
    );
  }

  private _getValue(
    column: ICSVColumn,
    record: Record<string, unknown>
  ): string | number {
    const dataPoint = ReportBuilderDataSource.getDataPoint(column.data, record);
    if (!dataPoint) {
      return '';
    }
    if (
      dataPoint.formatter === MeasureFormatter.Number ||
      dataPoint.formatter === MeasureFormatter.Currency
    ) {
      return DataPointValueFormatter.coerceToNumber(dataPoint.value);
    }
    return DataPointValueFormatter.format(
      dataPoint.value,
      dataPoint.formatter,
      dataPoint.formatterValue
    );
  }
}
