import {
  HicapsConnect,
  HicapsConnectMethod,
  PrincipleHicapsConnect,
  PrincipleHicapsConnectResponse,
} from '@principle-theorem/hicaps-connect';
import {
  Transaction,
  getBaseTransaction,
} from '@principle-theorem/principle-core';
import {
  IHicapsConnectPaymentTransactionExtendedData,
  IHicapsConnectRefundTransactionExtendedData,
  IInvoice,
  IPractice,
  IStaffer,
  ITransaction,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  WithRef,
  toTimestamp,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import * as moment from 'moment-timezone';
import { HicapsConnectHelpers } from '../hicaps-connect-helpers';

export class HicapsConnectEftposTransactionBuilder {
  static async toEftposTransaction(
    invoice: WithRef<IInvoice>,
    practiceRef: DocumentReference<IPractice>,
    request: HicapsConnect.SaleRequest,
    response: PrincipleHicapsConnectResponse<HicapsConnectMethod.SendSale>,
    attributedTo?: DocumentReference<IStaffer>
  ): Promise<ITransaction<IHicapsConnectPaymentTransactionExtendedData>> {
    const base = await getBaseTransaction(invoice, TransactionType.Incoming);
    const amount = getEftposAmount(request, response);
    const extendedData = { request, response };

    if (!PrincipleHicapsConnect.hasSuccessResult(response)) {
      return Transaction.init({
        ...base,
        createdAt: toTimestamp(moment(response.timestamp)),
        reference: response.processUid,
        status: TransactionStatus.Failed,
        provider: TransactionProvider.HicapsConnectEftpos,
        practiceRef,
        amount,
        extendedData,
        attributedTo,
      });
    }

    return Transaction.init({
      ...base,
      createdAt: HicapsConnectHelpers.getTransactionDate(
        response.data.TransactionDate,
        response.timestamp
      ),
      reference: response.processUid,
      status: HicapsConnectHelpers.getStatus(response.data.ResponseCode),
      provider: TransactionProvider.HicapsConnectEftpos,
      practiceRef,
      amount,
      extendedData,
      attributedTo,
    });
  }

  static async toRefundTransaction(
    invoice: WithRef<IInvoice>,
    practiceRef: DocumentReference<IPractice>,
    request: HicapsConnect.RefundRequest,
    response: PrincipleHicapsConnectResponse<HicapsConnectMethod.SendRefund>,
    attributedTo?: DocumentReference<IStaffer>
  ): Promise<ITransaction<IHicapsConnectRefundTransactionExtendedData>> {
    const base = await getBaseTransaction(invoice, TransactionType.Outgoing);
    const amount = getEftposAmount(request, response);
    const extendedData = { request, response };

    if (!PrincipleHicapsConnect.hasSuccessResult(response)) {
      return Transaction.init({
        ...base,
        createdAt: toTimestamp(moment(response.timestamp)),
        reference: response.processUid,
        status: TransactionStatus.Failed,
        provider: TransactionProvider.HicapsConnectEftpos,
        practiceRef,
        amount,
        extendedData,
        attributedTo,
      });
    }

    return Transaction.init({
      ...base,
      createdAt: toTimestamp(moment(response.data.TransactionDate)),
      reference: response.processUid,
      status: HicapsConnectHelpers.getStatus(response.data.ResponseCode),
      provider: TransactionProvider.HicapsConnectEftpos,
      practiceRef,
      amount,
      extendedData,
      attributedTo,
    });
  }
}

function getEftposAmount(
  request: HicapsConnect.SaleRequest | HicapsConnect.RefundRequest,
  response?:
    | PrincipleHicapsConnectResponse<HicapsConnectMethod.SendSale>
    | PrincipleHicapsConnectResponse<HicapsConnectMethod.SendRefund>
): number {
  if (!response || !response.data || isString(response.data)) {
    return request.TransactionAmount;
  }
  const status = HicapsConnectHelpers.getStatus(response.data.ResponseCode);
  return status === TransactionStatus.Complete
    ? response.data.TransactionAmount
    : request.TransactionAmount;
}
