<form [formGroup]="labJobFormGroup" (ngSubmit)="save()">
  <div class="flex flex-col items-center justify-start">
    <pr-patient-selector fxFill formControlName="patient" />

    <mat-form-field fxFill>
      <mat-label>Appointment</mat-label>
      <mat-select
        formControlName="appointment"
        [compareWith]="isSelectedAppointment"
      >
        <mat-option>No Appointment</mat-option>
        <ng-container *ngIf="patientAppointments$ | async as appointments">
          <mat-option
            *ngFor="
              let appointment of appointments;
              trackBy: trackByAppointment
            "
            [value]="appointment"
          >
            <div *ngIf="appointment.event as event">
              <pr-formatted-event-field
                field="from"
                [event]="event"
                [format]="dateService.format.dateWithTimes"
               />
              - {{ appointment.treatmentPlan.name }}
            </div>
            <div *ngIf="!appointment.event?.from">
              Unscheduled - {{ appointment.treatmentPlan.name }}
            </div>
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="labs$ | async as labs">
      <mat-form-field fxFill>
        <mat-label>Lab</mat-label>
        <mat-select
          [required]="true"
          formControlName="lab"
          [compareWith]="isSelectedNamedDocument"
        >
          <mat-option
            *ngFor="let lab of labs; trackBy: trackByLab"
            [value]="lab"
          >
            {{ lab.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <mat-form-field fxFill>
      <mat-label>Job Type</mat-label>
      <mat-select
        formControlName="type"
        [compareWith]="isSelectedType"
        [required]="true"
      >
        <mat-option
          *ngFor="let type of labJobTypes$ | async; trackBy: trackByLabJobType"
          [value]="type"
        >
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>Wholesale Cost</mat-label>
      <input matInput formControlName="cost" type="number" />
      <span matTextPrefix>$&nbsp;</span>
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>Due Date (optional)</mat-label>
      <input
        matInput
        formControlName="dueDate"
        [matDatepicker]="dueDate"
        (focus)="dueDate.open()"
        (click)="dueDate.open()"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="dueDate"
       />
      <mat-datepicker #dueDate />
      <button
        matIconSuffix
        mat-icon-button
        aria-label="Clear"
        type="button"
        (click)="clearDueDate($event)"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field fxFill>
      <mat-label>Job Details</mat-label>
      <pr-content-editor
        [menuEnabled]="false"
        formControlName="description"
        placeholder="Add job details"
       />
    </mat-form-field>
  </div>

  <pt-buttons-container *ngIf="showActions">
    <button
      mat-stroked-button
      type="button"
      [disabled]="!labJobFormGroup.dirty"
      (click)="cancelled.emit()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="!labJobFormGroup.dirty"
      (click)="save()"
    >
      Save
    </button>
  </pt-buttons-container>
</form>
