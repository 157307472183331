import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgMedipassModule } from '@principle-theorem/ng-medipass';
import { NgPrincipleAccountingModule } from '@principle-theorem/ng-principle-accounting';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { AddPaymentButtonComponent } from './add-payment-button/add-payment-button.component';
import { AddRefundButtonComponent } from './add-refund-button/add-refund-button.component';
import { AddTransactionButtonComponent } from './add-transaction-button/add-transaction-button.component';
import { AddTransactionMenuButtonComponent } from './add-transaction-menu-button/add-transaction-menu-button.component';
import { AddTransactionComponent } from './add-transaction/add-transaction.component';
import { BuildClaimDialogComponent } from './healthcare-claims-list/build-claim-dialog/build-claim-dialog.component';
import { HealthcareClaimItemsSummaryComponent } from './healthcare-claims-list/healthcare-claim/healthcare-claim-items-summary/healthcare-claim-items-summary.component';
import { HealthcareClaimSelectorComponent } from './healthcare-claims-list/healthcare-claim/healthcare-claim-selector/healthcare-claim-selector.component';
import { HealthcareClaimComponent } from './healthcare-claims-list/healthcare-claim/healthcare-claim.component';
import { HealthcareClaimsListComponent } from './healthcare-claims-list/healthcare-claims-list.component';
import { TransactionAmendmentHistoryDialogComponent } from './transaction-amendment-history-dialog/transaction-amendment-history-dialog.component';
import { TransactionAmendmentHistoryComponent } from './transaction-amendment-history/transaction-amendment-history.component';
import { TransactionActionMenuButtonComponent } from './transaction-menu-actions/transaction-action-menu-button/transaction-action-menu-button.component';
import { TransactionMenuActionsComponent } from './transaction-menu-actions/transaction-menu-actions.component';
import { TransactionProvidersModule } from './transaction-providers/transaction-providers.module';
import { TransactionReportActionsComponent } from './transaction-report-actions/transaction-report-actions.component';
import { TransactionDisplayLineComponent } from './transactions-display/transaction-display/transaction-display-line/transaction-display-line.component';
import { TransactionDisplayComponent } from './transactions-display/transaction-display/transaction-display.component';
import { TransactionsDisplayComponent } from './transactions-display/transactions-display.component';
import { BuildClaimDialogService } from './healthcare-claims-list/build-claim-dialog/build-claim-dialog.service';

@NgModule({
  imports: [
    CommonModule,
    NgMaterialModule,
    NgSharedModule,
    NgMedipassModule,
    TransactionProvidersModule,
    NgPrincipleAccountingModule,
    NgPrincipleSharedModule,
  ],
  declarations: [
    TransactionsDisplayComponent,
    AddTransactionComponent,
    TransactionDisplayComponent,
    TransactionDisplayLineComponent,
    HealthcareClaimsListComponent,
    HealthcareClaimComponent,
    HealthcareClaimSelectorComponent,
    AddTransactionButtonComponent,
    TransactionAmendmentHistoryDialogComponent,
    TransactionAmendmentHistoryComponent,
    BuildClaimDialogComponent,
    HealthcareClaimItemsSummaryComponent,
    TransactionMenuActionsComponent,
    TransactionReportActionsComponent,
    AddTransactionMenuButtonComponent,
    AddRefundButtonComponent,
    AddPaymentButtonComponent,
    TransactionActionMenuButtonComponent,
  ],
  providers: [BuildClaimDialogService],
  exports: [
    TransactionsDisplayComponent,
    AddTransactionComponent,
    TransactionDisplayLineComponent,
    HealthcareClaimsListComponent,
    TransactionReportActionsComponent,
  ],
})
export class TransactionsModule {}
