import { PatientRelationship } from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  IBasePatient,
  IBrand,
  IDestinationEntity,
  IDestinationEntityJobRunOptions,
  IDestinationEntityRecord,
  IGetRecordResponse,
  IHasPrimaryContact,
  IMigratedDataSummary,
  IPatient,
  IPracticeMigration,
  ISourceEntityRecord,
  IsPrimaryContact,
  MergeConflictDestinationEntityRecord,
  PatientRelationshipType,
  SkippedDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  WithRef,
  asDocRef,
  getError,
  isSameRef,
  safeCombineLatest,
  toNamedDocument,
  toTimestamp,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import {
  IOasisPatient,
  IOasisPatientFilters,
  IOasisPatientTranslations,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import { PatientDestinationEntity } from './patients';

export const PATIENT_RELATIONSHIP_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: 'patientRelationships',
    label: 'Patient Relationships',
    description: '',
  },
});

export interface IPatientRelationshipDestinationRecord {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  primaryContactRef: DocumentReference<IPatient>;
}

export interface IPatientRelationshipJobData {
  brand: WithRef<IBrand>;
  sourcePatient: IGetRecordResponse<
    IOasisPatient,
    IOasisPatientTranslations,
    IOasisPatientFilters
  >;
}

export interface IPatientMigrationData {
  sourcePatientId: string;
  patientRef: DocumentReference<IPatient>;
  primaryContact: IHasPrimaryContact & {
    patientRef: DocumentReference<IPatient>;
  };
}

export class PatientRelationshipDestinationEntity extends BaseDestinationEntity<
  IPatientRelationshipDestinationRecord,
  IPatientRelationshipJobData,
  IPatientMigrationData
> {
  destinationEntity = PATIENT_RELATIONSHIP_DESTINATION_ENTITY;
  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  sourceCountDataAccessor(
    data: IPatientRelationshipJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getDestinationEntityRecordUid(data: IPatientRelationshipJobData): string {
    return data.sourcePatient.record.uid;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IPatientRelationshipDestinationRecord>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    const source$: Observable<IMigratedDataSummary> = Firestore.doc$(
      record.data.sourceRef
    ).pipe(
      map((sourcePatient) => ({
        label: 'Source Patient',
        data: sourcePatient,
      }))
    );
    const primary$: Observable<IMigratedDataSummary> = Firestore.doc$(
      record.data.primaryContactRef
    ).pipe(
      map((patient) => ({
        label: 'Primary Patient',
        data: patient,
      }))
    );

    return safeCombineLatest([source$, primary$]);
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientRelationshipJobData,
    _migrationData: IPatientMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  hasMergeConflict(
    _translationMap: TranslationMapHandler,
    _data: IPatientMigrationData
  ): IPatientMigrationData | undefined {
    return;
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientRelationshipJobData[]> {
    const brand$ = PracticeMigration.brand$(migration);
    return this.buildSourceRecordQuery$(
      migration,
      this.sourceEntities.patients,
      runOptions
    ).pipe(
      withLatestFrom(brand$),
      map(([patientRecords, brand]) =>
        patientRecords.map((sourcePatient) => ({ sourcePatient, brand }))
      )
    );
  }

  async buildMigrationData(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientRelationshipJobData
  ): Promise<
    | IPatientMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
    | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients
      .getSourceRecordId(data.sourcePatient.data.data)
      .toString();

    const accountPatientId =
      data.sourcePatient.data.data.accountPatientId.toString();
    if (accountPatientId === sourcePatientId) {
      return this._buildSkippedResponse(data.sourcePatient);
    }

    try {
      const sourcePatientRef = await translationMap.getDestination<IPatient>(
        sourcePatientId,
        PATIENT_RESOURCE_TYPE
      );
      const accountPatientRef = await translationMap.getDestination<IPatient>(
        accountPatientId,
        PATIENT_RESOURCE_TYPE
      );

      if (!sourcePatientRef) {
        throw new Error(
          `Patient relation can't be found for id ${sourcePatientId}`
        );
      }
      if (!accountPatientRef) {
        throw new Error(`Failed to resolve account patient record`);
      }

      if (isSameRef(sourcePatientRef, accountPatientRef)) {
        throw new Error(`Source and account patients are the same`);
      }

      return {
        sourcePatientId,
        patientRef: sourcePatientRef,
        primaryContact: {
          patientRef: accountPatientRef,
          primaryContact: {
            patient: toNamedDocument(
              await Firestore.getDoc(
                asDocRef<IBasePatient & IsPrimaryContact>(accountPatientRef)
              )
            ),
            type: PatientRelationshipType.Unknown,
          },
        },
      };
    } catch (error) {
      return this._buildErrorResponse(
        {
          label: data.sourcePatient.record.label,
          uid: data.sourcePatient.record.uid,
          ref: data.sourcePatient.record.ref,
        },
        getError(error)
      );
    }
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMapHandler: TranslationMapHandler,
    jobData: IPatientRelationshipJobData,
    migrationData: IPatientMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      await FirestoreMigrate.patchDoc(migrationData.patientRef, {
        primaryContact: migrationData.primaryContact.primaryContact,
      });

      await PatientRelationship.addPrimaryRelationship(
        await Firestore.getDoc(migrationData.patientRef)
      );

      return this._buildSuccessResponse(
        jobData.sourcePatient.record,
        migrationData.primaryContact.patientRef
      );
    } catch (error) {
      return this._buildErrorResponse(
        jobData.sourcePatient.record,
        getError(error)
      );
    }
  }

  private _buildSuccessResponse(
    sourcePatient: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>,
    primaryContactRef: DocumentReference<IPatient>
  ): IDestinationEntityRecord<IPatientRelationshipDestinationRecord> {
    return {
      uid: sourcePatient.uid,
      label: sourcePatient.label,
      data: {
        sourceRef: sourcePatient.ref,
        primaryContactRef,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: sourcePatient.ref,
      migratedAt: toTimestamp(),
    };
  }

  private _buildErrorResponse(
    patient: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>,
    errorMessage: string
  ): IDestinationEntityRecord<IPatientRelationshipDestinationRecord> &
    FailedDestinationEntityRecord {
    return {
      uid: patient.uid,
      label: patient.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: patient.ref,
      errorMessage,
      failData: {
        patientRef: patient.ref,
      },
    };
  }

  private _buildSkippedResponse(
    sourcePatient: IGetRecordResponse<IOasisPatient, IOasisPatientTranslations>
  ): IDestinationEntityRecord & SkippedDestinationEntityRecord {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      status: DestinationEntityRecordStatus.Skipped,
      sourceRef: sourcePatient.record.ref,
    };
  }
}
