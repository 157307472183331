import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { type ISmartpayTerminal } from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';

type EditSmartpayTerminalFormData = Pick<ISmartpayTerminal, 'name'>;

export interface IEditSmartpayTerminalRequest {
  title: string;
  terminal: WithRef<ISmartpayTerminal>;
}

@Component({
  selector: 'pr-edit-smartpay-terminal-dialog',
  templateUrl: './edit-smartpay-terminal-dialog.component.html',
  styleUrls: ['./edit-smartpay-terminal-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSmartpayTerminalDialogComponent {
  form = new TypedFormGroup<EditSmartpayTerminalFormData>({
    name: new TypedFormControl<string>(''),
  });

  constructor(
    private _dialogRef: MatDialogRef<
      EditSmartpayTerminalDialogComponent,
      Pick<ISmartpayTerminal, 'name'>
    >,
    @Inject(MAT_DIALOG_DATA) public data: IEditSmartpayTerminalRequest
  ) {
    this.form.patchValue(data.terminal);
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }
    this._dialogRef.close(this.form.value);
  }
}
