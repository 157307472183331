import { getSchemaText } from '@principle-theorem/editor';
import {
  AppointmentSummary,
  EventType,
  IAppointment,
  IEvent,
  IEventable,
  IParticipant,
  IPatient,
  IScheduleSummaryEventable,
  IStaffer,
  ParticipantType,
  isAppointment,
  isAppointmentRequest,
  isAppointmentSuggestion,
  isAppointmentSummary,
  isCalendarEvent,
  isEvent,
  isGapEvent,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  DocumentReference,
  IReffable,
  ITimePeriod,
  MINUTES_IN_DAY,
  Timestamp,
  Timezone,
  WithRef,
  durationToHumanisedTime,
  getTimeInRange,
  isSameRef,
  momentIsWithinDayBounds,
  multiFilter,
  reduceToSingleArrayFn,
  timeFromStartOfDay,
  timeFromStartOfRange,
  toMoment,
  toMomentRange,
  toNamedDocument,
  toTimePeriod,
  toTimestamp,
} from '@principle-theorem/shared';
import { differenceWith, uniqBy, uniqWith } from 'lodash';
import * as moment from 'moment-timezone';
import { MonoTypeOperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventTimePeriod } from './event-time-period';

export function isSameTimestamp(timeA?: Timestamp, timeB?: Timestamp): boolean {
  if (!timeA && !timeB) {
    return true;
  }
  if (!timeA || !timeB) {
    return false;
  }
  return timeA.isEqual(timeB);
}

export function isSameTimePeriod(
  periodA: ITimePeriod,
  periodB: ITimePeriod
): boolean {
  return periodA.from.isSame(periodB.from) && periodA.to.isSame(periodB.to);
}

export function isSameEvent(eventA: IEvent, eventB: IEvent): boolean {
  return (
    isEvent(eventA) &&
    isEvent(eventB) &&
    isSameTimestamp(eventA.from, eventB.from) &&
    isSameTimestamp(eventA.to, eventB.to) &&
    eventA.type === eventB.type &&
    differenceWith(eventA.participants, eventB.participants, isSameRef)
      .length === 0
  );
}

export class Event {
  static init(overrides: AtLeast<IEvent, 'practice'>): IEvent {
    const participantRefs = overrides?.participants?.length
      ? overrides.participants.map((participant) => participant.ref)
      : [];

    return {
      from: toTimestamp(),
      to: toTimestamp(),
      participants: [],
      participantRefs,
      type: EventType.Misc,
      ...overrides,
      practice: toNamedDocument(overrides.practice),
    };
  }

  static duration(event: Pick<IEvent, 'from' | 'to'>): number {
    const from: moment.Moment = toMoment(event.from).set({
      seconds: 0,
      milliseconds: 0,
    });
    const to: moment.Moment = toMoment(event.to).set({
      seconds: 0,
      milliseconds: 0,
    });

    const diff: number = to.diff(from);
    const duration: moment.Duration = moment.duration(diff);
    return duration.asMinutes();
  }

  static distance(event: IEvent): string {
    const distance: string = moment().to(toMoment(event.from));
    return distance.charAt(0).toUpperCase() + distance.slice(1);
  }

  static toTimePeriod(event: IEvent, timezone?: Timezone): ITimePeriod {
    return toTimePeriod(event.from, event.to, timezone);
  }

  /**
   * @deprecated Please use the Appointment.patient$ method to resolve the patient.
   */
  static patients(event: IEvent): IParticipant<IPatient>[] {
    return event.participants.filter(
      (participant): participant is IParticipant<IPatient> => {
        return participant.type === ParticipantType.Patient;
      }
    );
  }

  static staff(event: IEvent): IParticipant<IStaffer>[] {
    return event.participants.filter(
      (participant): participant is IParticipant<IStaffer> => {
        return participant.type === ParticipantType.Staffer;
      }
    );
  }

  static addParticipants(
    event: IEvent,
    newParticipants: IParticipant[]
  ): IEvent {
    const participants = uniqWith(
      [...event.participants, ...newParticipants],
      (participantA, participantB) =>
        isSameRef(participantA.ref, participantB.ref)
    );
    return {
      ...event,
      participants,
      participantRefs: participants.map((participant) => participant.ref),
    };
  }

  static removeParticipants(
    event: IEvent,
    removeParticipants: IParticipant[]
  ): IEvent {
    const participants = differenceWith(
      event.participants,
      removeParticipants,
      (participantA, participantB) =>
        isSameRef(participantA.ref, participantB.ref)
    );
    return {
      ...event,
      participants,
      participantRefs: participants.map((participant) => participant.ref),
    };
  }
}

export function isParticipant<T extends object = object>(
  event: IEventable,
  participantRef: DocumentReference<T>
): boolean {
  return event.event.participants.some((search) =>
    isSameRef(search, participantRef)
  );
}

export function filterEventTypes<T extends object>(
  excludeTypes: EventType[] = []
): MonoTypeOperatorFunction<WithRef<IEventable<T>>[]> {
  return multiFilter((event) => !excludeTypes.includes(event.event.type));
}

export function filterEventsByParticipants(
  participants: IParticipant[] = []
): MonoTypeOperatorFunction<WithRef<IEventable>[]> {
  return multiFilter((event) => {
    if (!participants.length) {
      return true;
    }
    return participants.some((participant) =>
      isParticipant(event, participant.ref)
    );
  });
}

export function filterEventsByPractice<T extends IEventable>(
  practice: IReffable
): MonoTypeOperatorFunction<T[]> {
  return multiFilter((event) => isSameRef(practice, event.event.practice));
}

export function sortEventsByDuration(): MonoTypeOperatorFunction<IEventable[]> {
  return map((events) => {
    return events.sort((eventA, eventB) => {
      const durationA = Event.duration(eventA.event);
      const durationB = Event.duration(eventB.event);
      if (durationA === durationB) {
        return 0;
      }
      return durationB - durationA;
    });
  });
}

export function isBlocking(eventable: IEventable): boolean {
  return isCalendarEvent(eventable)
    ? eventable.isBlocking
    : isAppointment(eventable)
      ? true
      : false;
}

export interface IEventDisplayBounds {
  top: number;
  bottom: number;
  height: number;
  left: number;
  right: number;
  width: number;
}

/**
 * Calculates the bounds for span event if it were placed in a rectangle which
 * represents a day (24 hour period). By default the returned values are
 * between 0 and 1 and can be scaled by the proper rectangle dimensions or the
 * rectangle dimensions can be passed to this function.
 *
 * @param event The event to find the bounds relative to
 * @param dayHeight The height of the rectangle of the day.
 * @param dayWidth The width of the rectangle of the day.
 * @param columnOffset The offset in the rectangle of the day to adjust this
 *    span by. This also reduces the width of the returned bounds to keep the
 *    bounds in the rectangle of the day.
 * @param clip `true` if the bounds should stay in the day rectangle, `false`
 *    and the bounds may go outside the rectangle of the day for multi-day
 *    spans.
 * @param offsetX How much to translate the left & right properties by.
 * @param offsetY How much to translate the top & bottom properties by.
 * @returns The calculated bounds for this span.
 */
export function getEventDisplayBounds(
  event: IEvent,
  dayHeight: number = 1,
  dayWidth: number = 1,
  columnOffset: number = 0,
  clip: boolean = true,
  offsetX: number = 0,
  offsetY: number = 0
): IEventDisplayBounds {
  const eventSpan = toMomentRange(event.from, event.to);
  const startDelta =
    timeFromStartOfDay(eventSpan.from, 'minutes') / MINUTES_IN_DAY;
  const endDelta = timeFromStartOfDay(eventSpan.to, 'minutes') / MINUTES_IN_DAY;

  const start: number = clip ? Math.max(0, startDelta) : startDelta;
  const end: number = clip ? Math.min(1, endDelta) : endDelta;

  const left: number = columnOffset;
  const right: number = dayWidth - left;

  const top: number = start * dayHeight;
  const bottom: number = end * dayHeight;

  return {
    top: top + offsetY,
    bottom: bottom + offsetY,
    height: bottom - top,
    left: left + offsetX,
    right: right + offsetX,
    width: right,
  };
}

export interface IHorizontalDisplayBounds {
  left: number;
  right: number;
  width: number;
}

/**
 * Simplified version of the getEventDisplayBounds and specific to horizontal display.
 *
 * @param event The event to calculate bounds for
 * @param relativeTo The span of time against which the bounds will be calculated. If the
 * event falls outside of this time bounds the start and end will be set to the min/max
 * respectively
 * @param dayWidth The width of the time span
 */
export function getEventHorizontalDisplay(
  event: IEvent,
  relativeTo: ITimePeriod,
  dayWidth: number = 1,
  offset: number = 0
): IHorizontalDisplayBounds {
  const eventSpan = toMomentRange(event.from, event.to);
  const startDelta = momentIsWithinDayBounds(eventSpan.from, relativeTo.from)
    ? timeFromStartOfRange(relativeTo, eventSpan.from, 'minutes') /
      getTimeInRange(relativeTo, 'minutes')
    : 0;
  const endDelta = momentIsWithinDayBounds(eventSpan.to, relativeTo.to)
    ? timeFromStartOfRange(relativeTo, eventSpan.to, 'minutes') /
      getTimeInRange(relativeTo, 'minutes')
    : 1;

  const start = Math.max(0, startDelta);
  const end = Math.min(1, endDelta);

  const left = offset + start * dayWidth + 1;
  const right = offset + end * dayWidth - 1;

  return {
    left,
    right,
    width: right - left,
  };
}

export function getAppointmentEventTitle(
  appointment: AppointmentSummary | IAppointment
): string {
  if (isAppointment(appointment)) {
    let title = appointment.treatmentPlan.name;
    if (appointment.treatmentPlan.treatmentStep.name) {
      title += ` - ${appointment.treatmentPlan.treatmentStep.name}`;
    }
    return title;
  }
  let title = appointment.metadata.treatmentPlanName;
  if (appointment.metadata.treatmentStepName) {
    title += ` - ${appointment.metadata.treatmentStepName}`;
  }
  return title;
}

export function getEventTitle(event: IScheduleSummaryEventable): string {
  if (isAppointmentSummary(event)) {
    return getAppointmentEventTitle(event);
  }

  if (isCalendarEvent(event)) {
    return getSchemaText(event.title);
  }
  if (event.metadata.label) {
    return event.metadata.label;
  }
  if (isAppointmentSuggestion(event)) {
    return 'Appointment Option';
  }
  if (isGapEvent(event.event)) {
    return durationToHumanisedTime(
      moment.duration(toMoment(event.event.to).diff(toMoment(event.event.from)))
    );
  }
  return '';
}

export function getUniqueStaffParticipants(
  events: IEventable[]
): IParticipant<IStaffer>[] {
  const staff = events
    .map((event) => Event.staff(event.event))
    .reduce(reduceToSingleArrayFn, []);
  return uniqBy(staff, (staffer) => staffer.ref.id);
}

export function eventableOverlapsBlockingEvent(
  currentEventable: IEventable,
  eventables: IEventable[]
): boolean {
  return EventTimePeriod.hasOverlappingEventables(
    eventables.filter((eventable) => {
      const isRequest = isAppointmentRequest(eventable);
      const isSameEventable = isSameRef(eventable.ref, currentEventable.ref);
      const isBlockingEvent =
        isAppointment(eventable) ||
        (isCalendarEvent(eventable) && eventable.isBlocking);
      return !isSameEventable && isBlockingEvent && !isRequest;
    }),
    Event.toTimePeriod(currentEventable.event)
  );
}
