import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LetDirective, PushPipe } from '@ngrx/component';
import { PrincipleEditorModule } from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ConditionalLogicFormComponent } from './conditional-logic-form/conditional-logic-form.component';
import { ConditionalLogicItemComponent } from './conditional-logic-form/conditional-logic-item/conditional-logic-item.component';
import { DynamicFormComponent } from './conditional-logic-form/conditional-logic-item/dynamic-form/dynamic-form.component';
import { ContextPreviewDialogComponent } from './context-preview-dialog/context-preview-dialog.component';
import { ContextPreviewComponent } from './context-preview/context-preview.component';
import { EditTemplateVariantComponent } from './template-editor-form/edit-template-variant/edit-template-variant.component';
import { TemplateEditorFormComponent } from './template-editor-form/template-editor-form.component';
import { TemplateEditorTextareaComponent } from './template-editor-textarea/template-editor-textarea.component';
import { TemplateEditorComponent } from './template-editor/template-editor.component';
import { TemplatePreviewComponent } from './template-preview/template-preview.component';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';

@NgModule({
  imports: [
    ReactiveFormsModule,
    NgSharedModule,
    NgMaterialModule,
    PrincipleEditorModule,
    NgPrincipleSharedModule,
    LetDirective,
    PushPipe,
  ],
  declarations: [
    TemplateEditorComponent,
    TemplatePreviewComponent,
    TemplateEditorFormComponent,
    ContextPreviewComponent,
    TemplateEditorTextareaComponent,
    ContextPreviewDialogComponent,
    EditTemplateVariantComponent,
    ConditionalLogicFormComponent,
    ConditionalLogicItemComponent,
    DynamicFormComponent,
  ],
  exports: [
    TemplateEditorComponent,
    TemplatePreviewComponent,
    TemplateEditorFormComponent,
    ContextPreviewComponent,
    TemplateEditorTextareaComponent,
    ConditionalLogicFormComponent,
  ],
})
export class NgTemplatingModule {}
