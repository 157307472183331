<div fxLayout="column" fxLayoutGap="16px">
  <h1 class="mat-headline-4">Roles</h1>

  <pr-information-box>
    A role is a collection of privileges that can be assigned to users. These
    privileges determine what a user can do within Principle. For example a
    practitioner would have a different set of privileges to a receptionist. A
    user can be assigned multiple roles.
  </pr-information-box>

  <div fxLayout="row" fxLayoutGap="16px">
    <pt-search-field fxFlex>
      <input matInput [formControl]="searchCtrl" placeholder="Search" #search />
    </pt-search-field>

    <button mat-raised-button color="primary" (click)="addRole()">
      Create Role
    </button>
  </div>

  <pr-empty-state *ngIf="emptyState$ | async" image="list" title="roles" />

  <ng-container *ngIf="roleFilter.results$ | async as roles">
    <ng-container *ngIf="roles.length; else noSearchResults">
      <mat-nav-list>
        <a
          *ngFor="let role of roles; trackBy: trackByRole"
          mat-list-item
          [routerLink]="['./', role.ref.id]"
        >
          <div matListItemTitle>{{ role.name }}</div>
          <div matListItemMeta>
            <mat-icon>chevron_right</mat-icon>
          </div>
        </a>
      </mat-nav-list>
    </ng-container>
  </ng-container>
</div>

<ng-template #noSearchResults>
  <pr-empty-state
    *ngIf="(emptyState$ | async) === false"
    image="search"
    title="search results"
    [searchInputValue]="search.value"
  />
</ng-template>
