import {
  IMeasureMetadata,
  InvoiceAction,
  InvoiceStatus,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import { IInvoiceEventFact } from '@principle-theorem/reporting/interfaces';
import * as moment from 'moment-timezone';
import { BigQueryTable } from '../../big-query-tables';
import {
  BrandQueryScopeConfig,
  DateRangeQueryScopeConfig,
  PracticeQueryScopeConfig,
} from '../../query-scope-config/query-scope-configs';
import { GroupBy } from '../../querying';
import { BaseFactMeasures, latestEventId } from '../base-measures';
import { MeasureLinkFactory, MeasurePath } from '../data-accessor-factory';
import { BrandDimensionMeasures } from '../dimensions/brand-dimension';
import { InvoiceDimensionMeasures } from '../dimensions/invoice-dimension';
import { ModelEventDimensionMeasures } from '../dimensions/model-event-dimension';
import { PatientDimensionMeasures } from '../dimensions/patient-dimension';
import { PracticeDimensionMeasures } from '../dimensions/practice-dimension';
import { ReferrerDimensionMeasures } from '../dimensions/referrer-dimension';
import { StafferDimensionMeasures } from '../dimensions/staffer-dimension';
import { TreatmentPlanDimensionMeasures } from '../dimensions/treatment-plan-dimension';
import {
  LessThanMeasureFilter,
  NullMeasureFilter,
  ValueEqualsMeasureFilter,
} from '../measure-filters';
import {
  CanBeChartedProperty,
  CanDoAllProperty,
  ComparableProperties,
  IMeasure,
  MeasureTransformMap,
} from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { QueryFactory } from '../query-factory';

export class InvoiceEventFactMeasures
  extends BaseFactMeasures
  implements MeasureTransformMap<ComparableProperties<IInvoiceEventFact>>
{
  id = 'invoiceEvent';
  readonly table = BigQueryTable.InvoiceEvent;
  readonly name = 'Invoices';
  scopes = [
    new BrandQueryScopeConfig(),
    new PracticeQueryScopeConfig(),
    new DateRangeQueryScopeConfig(),
  ];

  get invoice(): InvoiceDimensionMeasures {
    return new InvoiceDimensionMeasures(BigQueryTable.Invoices, 'invoice', {
      sourceJoinKey: MeasurePath.docRef('invoice.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  // TODO: Handle multiple practitioners
  // https://app.clickup.com/t/2k5b2f2
  get practitioner(): StafferDimensionMeasures {
    return new StafferDimensionMeasures(BigQueryTable.Staff, 'practitioner', {
      sourceJoinKey: MeasurePath.docRef('practitioner.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  // TODO: Handle multiple treatment plans
  // https://app.clickup.com/t/2k5b2f2
  get treatmentPlan(): TreatmentPlanDimensionMeasures {
    return new TreatmentPlanDimensionMeasures(
      BigQueryTable.TreatmentPlans,
      'treatmentPlan',
      {
        sourceJoinKey: MeasurePath.docRef('treatmentPlan.ref'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get practice(): PracticeDimensionMeasures {
    return new PracticeDimensionMeasures(BigQueryTable.Practices, 'practice', {
      sourceJoinKey: MeasurePath.docRef('practice.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get patient(): PatientDimensionMeasures {
    return new PatientDimensionMeasures(BigQueryTable.Patients, 'patient', {
      sourceJoinKey: MeasurePath.docRef('patient.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get referrer(): ReferrerDimensionMeasures {
    return new ReferrerDimensionMeasures(this.table, 'referrer');
  }

  get brand(): BrandDimensionMeasures {
    return new BrandDimensionMeasures(BigQueryTable.Brands, 'brand', {
      sourceJoinKey: MeasurePath.docRef('brand.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get event(): ModelEventDimensionMeasures<InvoiceAction, InvoiceStatus> {
    return new ModelEventDimensionMeasures(this.table, 'event', 'Invoice');
  }

  get latestEvent(): InvoiceEventFactMeasures {
    const measures = new InvoiceEventFactMeasures();
    measures.id = latestEventId(measures);
    measures._query = QueryFactory.fromTable(measures.table)
      .override(measures._query)
      .latestEvent(measures.table, GroupBy.Invoice)
      .filterSoftDeleted(measures.invoice.measureRef('deleted'))
      .get();
    return measures;
  }

  get amountRemaining(): CanBeChartedProperty {
    const propertyName = 'amountRemaining';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Invoice Amount Remaining',
        summary: 'The amount remaining on the invoice',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get discounts(): CanBeChartedProperty {
    const propertyName = 'discounts';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Invoice Discounts',
        summary: 'The total of any discounts on the invoice',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get paidToDate(): CanBeChartedProperty {
    const propertyName = 'paidToDate';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Invoice Amount Paid',
        summary: 'The amount paid on the invoice',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get completedTransactions(): CanBeChartedProperty {
    const propertyName = 'completedTransactions';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Completed Transactions',
        summary: 'The total of any completed transactions, including discounts',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get amountPayable(): CanBeChartedProperty {
    const propertyName = 'amountPayable';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Invoice Amount Payable',
        summary: 'The total amount payable on the invoice',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get subTotal(): CanBeChartedProperty {
    const propertyName = 'subTotal';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Invoice Subtotal',
        summary: 'The total of the invoice, excluding tax.',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get tax(): CanBeChartedProperty {
    const propertyName = 'tax';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Invoice Tax',
        summary: 'The total tax on the invoice',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get total(): CanBeChartedProperty {
    const propertyName = 'total';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Total Invoice Amount',
        summary: 'The total amount of the invoice, including tax.',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get isOverdue(): CanDoAllProperty {
    const measure = this.measureRef('isOverdue');
    return MeasurePropertyFactory.boolean(
      {
        id: this._pathWithPrefix('isOverdue'),
        label: 'Invoice Is Overdue',
        summary: 'Whether the invoice is overdue or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get overdueBy(): CanBeChartedProperty {
    const propertyName = 'overdueBy';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Invoice Overdue By',
        summary: 'The number of days the invoice is overdue by',
        formatter: MeasureFormatter.Day,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get active(): CanBeChartedProperty {
    const metadata: IMeasureMetadata = {
      id: 'active',
      label: 'Active Invoices',
      summary: '',
    };
    const measure: IMeasure = this.latestEvent.event.statusAfter.filterBy(
      new ValueEqualsMeasureFilter(InvoiceStatus.Issued)
    ).measure;
    this._query = { ...this._query, ...measure };
    return new CanBeChartedProperty({
      metadata,
      measure,
    });
  }

  get writtenOff(): CanBeChartedProperty {
    const metadata: IMeasureMetadata = {
      id: 'writtenOff',
      label: 'Written Off Invoices',
      summary: '',
    };
    const measure: IMeasure = this.latestEvent.event.statusAfter.filterBy(
      new ValueEqualsMeasureFilter(InvoiceStatus.WrittenOff)
    ).measure;
    this._query = { ...this._query, ...measure };
    return new CanBeChartedProperty({
      metadata,
      measure,
    });
  }

  get overdue(): CanBeChartedProperty {
    const metadata: IMeasureMetadata = {
      id: 'overdue',
      label: 'Overdue Invoices',
      summary: '',
    };
    const latestEvent: InvoiceEventFactMeasures = this.latestEvent;
    const measure: IMeasure = latestEvent.invoice.due
      .filterBy(new NullMeasureFilter(false))
      .filterBy(new LessThanMeasureFilter(moment().toISOString()))
      .filterBy(
        new ValueEqualsMeasureFilter(InvoiceStatus.Issued),
        latestEvent.invoice.status.measure
      ).measure;
    this._query = { ...this._query, ...measure };
    return new CanBeChartedProperty({
      metadata,
      measure,
    });
  }

  get paidOnCheckout(): CanDoAllProperty {
    const measure = this.measureRef('paidOnCheckout');
    return MeasurePropertyFactory.boolean(
      {
        id: this._pathWithPrefix('paidOnCheckout'),
        label: 'Invoice Paid On Checkout',
        summary:
          'Whether the invoice was paid on the same day as the appointment or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get link(): CanBeChartedProperty {
    const brand = this.brand.measureRef('slug');
    const invoice = this.invoice.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.link(
      {
        id: 'link',
        label: 'Invoice Link',
        summary: 'Principle link for the given invoice',
      },
      invoice,
      this.buildQuery()
        .mergeJoins(this.brand.query.joins)
        .mergeJoins(this.invoice.query.joins)
        .attributes([brand.attributePath, invoice.attributePath])
        .get(),
      brand,
      MeasureLinkFactory.invoice
    );
  }

  get patientLink(): CanBeChartedProperty {
    const brand = this.brand.measureRef('slug');
    const patient = this.patient.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.link(
      {
        id: 'patient.link',
        label: 'Patient Link',
        summary: 'Principle link for the given patient',
      },
      patient,
      this.buildQuery()
        .mergeJoins(this.brand.query.joins)
        .mergeJoins(this.patient.query.joins)
        .attributes([brand.attributePath, patient.attributePath])
        .get(),
      brand,
      MeasureLinkFactory.patient
    );
  }
}
