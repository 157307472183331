<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="32px" class="wrapper">
  <h2 class="text-xl font-bold">Tags</h2>

  <pr-content-container>
    <p class="font-bold">Calendar Event Tags</p>
    <pr-calendar-event-tags [brand]="brand$ | async" />
  </pr-content-container>

  <pr-content-container>
    <p class="font-bold">Appointment Tags</p>
    <pr-brand-appointment-tags [brand]="brand$ | async" />
  </pr-content-container>

  <pr-content-container>
    <p class="font-bold">Patient Tags</p>
    <pr-patient-tags [brand]="brand$ | async" />
  </pr-content-container>

  <pr-content-container>
    <p class="font-bold">Note Tags</p>
    <pr-patient-note-tags [brand]="brand$ | async" />
  </pr-content-container>
</div>
