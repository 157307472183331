import {
  Storage,
  UploadMetadata,
  UploadTaskSnapshot,
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytesResumable,
} from '@angular/fire/storage';
import {
  saveDoc,
  shareReplayCold,
  type WithRef,
} from '@principle-theorem/shared';
import * as md5 from 'md5';
import * as moment from 'moment-timezone';
import { from, of, type Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FileDisplayType, getFileDisplayType } from './file-display-types';
import { ImageSize, getImageAtSize } from './image-upload/image';

export interface IMediaPath {
  name: string;
  path: string;
}

export class MediaManager {
  constructor(
    private _storage: Storage,
    public storagePath: string
  ) {}

  async addMedia($event: IMediaManagerAddEvent): Promise<IMediaPath> {
    const metadata: UploadMetadata = {
      contentType: $event.file.type,
    };
    const path = buildUploadFilePath(this.storagePath, $event.file.name);
    const task = uploadBytesResumable(
      ref(this._storage, path),
      $event.file,
      metadata
    );
    return this._buildMediaDataFromSnapshot($event.file.name, await task);
  }

  async updateMedia($event: IMediaManagerUpdateEvent): Promise<void> {
    const data: WithRef<IMediaPath> = { ...$event.media };
    if ($event.file) {
      const snapshot = await uploadBytesResumable(
        ref(this._storage, $event.media.path),
        $event.file
      );
      data.path = this._buildMediaDataFromSnapshot(
        $event.file.name,
        snapshot
      ).path;
    }
    await saveDoc(data);
  }

  async saveAsCopy(
    event: IMediaManagerUpdateEvent,
    fileName: string
  ): Promise<IMediaPath | undefined> {
    if (!event.file) {
      return;
    }
    const newFile = new File([event.file], fileName, {
      type: event.file.type,
    });
    return this.addMedia({ file: newFile });
  }

  private _buildMediaDataFromSnapshot(
    fileName: string,
    snapshot: UploadTaskSnapshot
  ): IMediaPath {
    return {
      name: fileName,
      path: snapshot.ref.fullPath,
    };
  }
}

export interface IMediaManagerAddEvent {
  file: File;
}

export interface IMediaManagerUpdateEvent {
  media: WithRef<IMediaPath>;
  file?: File;
}

export function fileFromDataUrl(
  dataUrl: string,
  name?: string,
  options?: FilePropertyBag
): File {
  const properties: FilePropertyBag = {
    type: 'image/jpeg',
    ...options,
  };
  const blob: Blob = dataURItoBlob(dataUrl);
  const fileName: string = name ? name : md5(dataUrl) + '.jpg';
  return new File([blob], fileName, properties);
}

function dataURItoBlob(dataURI: string): Blob {
  const binary: string = atob(dataURI.split(',')[1]);
  const array: number[] = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
}

export function getPreviewImage$(
  storage: Storage,
  media: IMediaPath
): Observable<string | undefined> {
  const contentType$ = getContentType$(storage, media);
  return contentType$.pipe(
    switchMap((contentType) => {
      const isImage =
        contentType &&
        getFileDisplayType(contentType) === FileDisplayType.Image;
      return isImage ? getImageURL$(storage, media, true) : of(undefined);
    })
  );
}

export function getContentType$(
  storage: Storage,
  media: IMediaPath
): Observable<string | undefined> {
  return from(getMetadata(ref(storage, media.path))).pipe(
    map((metadata) => metadata?.contentType)
  );
}

export function getFileExtension$(
  storage: Storage,
  media: IMediaPath
): Observable<string | undefined> {
  return from(getMetadata(ref(storage, media.path))).pipe(
    map((metadata) => {
      const name = metadata?.name ?? '';
      const fileParts = name.split('.');
      if (fileParts.length > 1) {
        return fileParts.pop()?.toLowerCase();
      }
    })
  );
}

export function getImageURL$(
  storage: Storage,
  media: IMediaPath,
  preview: boolean = false,
  previewSize: ImageSize = ImageSize.Medium
): Observable<string> {
  if (preview) {
    return from(
      getDownloadURL(
        ref(storage, getImageAtSize(media.path, previewSize, 'webp'))
      )
    ).pipe(
      catchError(() => getDownloadURL(ref(storage, media.path))),
      shareReplayCold()
    );
  }

  return getDownloadURL$(storage, media);
}

export function getDownloadURL$(
  storage: Storage,
  media: IMediaPath
): Observable<string> {
  return from(getDownloadURL(ref(storage, media.path)));
}

export function buildUploadFilePath(path: string, fileName: string): string {
  const unixTimestamp: number = moment().unix();
  return `${path}/${unixTimestamp}_${fileName}`;
}
