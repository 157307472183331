import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, type SafeUrl } from '@angular/platform-browser';
import { PinturaEditorComponent } from '@pqina/angular-pintura';
import {
  createDefaultImageReader,
  getEditorDefaults,
  locale_en_gb,
  markup_editor_locale_en_gb,
  type PinturaEditorOptions,
  plugin_annotate_locale_en_gb,
  plugin_crop_locale_en_gb,
  plugin_finetune_locale_en_gb,
  plugin_redact_locale_en_gb,
  plugin_resize_locale_en_gb,
  blobToFile,
} from '@pqina/pintura';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  FileDisplayType,
  getTransformedMimeType,
} from '@principle-theorem/ng-shared';
import { snapshot } from '@principle-theorem/shared';
import { Jimp as JimpType, JimpConstructors } from '@jimp/core';
import 'jimp';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const Jimp: JimpType & JimpConstructors;

export const FALLBACK_FILE_ICON = '/assets/file-icon.svg';

@Component({
  selector: 'pr-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilePreviewComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  fileUrl$ = new ReplaySubject<string>(1);
  contentType$ = new ReplaySubject<string>(1);
  safeUrl$: Observable<SafeUrl>;
  fallbackImage = FALLBACK_FILE_ICON;
  error$ = new BehaviorSubject<string>('');
  displayType$ = new ReplaySubject<FileDisplayType>(1);
  loaded$ = new ReplaySubject<boolean>(1);
  pintura$ = new BehaviorSubject<PinturaEditorComponent<unknown> | undefined>(
    undefined
  );
  pinturaOptions$: Observable<PinturaEditorOptions>;
  editMode$ = new ReplaySubject<boolean>(1);

  @Output() edited = new EventEmitter<boolean>();

  @ViewChild(PinturaEditorComponent, { static: false })
  set pintura(pintura: PinturaEditorComponent<unknown>) {
    if (pintura) {
      this.pintura$.next(pintura);
    }
  }

  @Input()
  set contentType(contentType: string) {
    if (contentType) {
      this.contentType$.next(getTransformedMimeType(contentType));
    }
  }

  @Input()
  set fileUrl(fileUrl: string) {
    if (fileUrl) {
      this.fileUrl$.next(fileUrl);
    }
  }

  @Input()
  set displayType(displayType: FileDisplayType) {
    if (displayType) {
      this.displayType$.next(displayType);
    }
  }

  @Input()
  set loaded(loaded: boolean) {
    this.loaded$.next(coerceBooleanProperty(loaded));
  }

  @Input()
  set editMode(editMode: boolean) {
    this.editMode$.next(coerceBooleanProperty(editMode));
  }

  constructor(sanitizer: DomSanitizer) {
    this.pinturaOptions$ = this.editMode$.pipe(
      map(this._getPinturaOptions.bind(this))
    );

    this.safeUrl$ = this.fileUrl$.pipe(
      map((url) => sanitizer.bypassSecurityTrustResourceUrl(url))
    );
  }

  handleError(errorMessage: string = 'Failed to load file.'): void {
    this.error$.next(errorMessage);
  }

  async saveImage(): Promise<File | undefined> {
    const pintura = this.pintura$.value;
    if (!pintura?.editor) {
      return;
    }
    pintura.editor.disabled = true;
    const image = await pintura.editor.processImage();
    pintura.editor.disabled = false;
    return image.dest;
  }

  async onUpdate(): Promise<void> {
    const pintura = await snapshot(this.pintura$);
    const historyLength = pintura?.editor?.history.length;
    const hasEdits = !!historyLength && historyLength > 1;
    this.edited.emit(hasEdits);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _getPinturaOptions(isEditMode: boolean): PinturaEditorOptions {
    return {
      ...getEditorDefaults(),
      enableButtonExport: false,
      cropSelectPresetFilter: 'landscape',
      cropSelectPresetOptions: [
        [undefined, 'Custom'],
        [1, 'Square'],
        [4 / 3, 'Landscape'],
        [3 / 4, 'Portrait'],
      ],
      imageReader: createDefaultImageReader({
        preprocessImageFile: this._preprocessTiffFile.bind(this),
      }),
      locale: {
        ...locale_en_gb,
        ...plugin_crop_locale_en_gb,
        ...plugin_finetune_locale_en_gb,
        ...plugin_annotate_locale_en_gb,
        ...plugin_redact_locale_en_gb,
        ...plugin_resize_locale_en_gb,
        ...markup_editor_locale_en_gb,
      },
      ...this._getEditModeOptions(isEditMode),
    };
  }

  private _getEditModeOptions(
    isEditMode: boolean
  ): Partial<PinturaEditorOptions> {
    return {
      utils: isEditMode
        ? ['crop', 'finetune', 'annotate', 'redact', 'resize']
        : ['crop'],
      enableUtils: isEditMode,
      enableToolbar: isEditMode,
      enableZoom: isEditMode,
      enableZoomControls: isEditMode,
      enablePan: isEditMode,
      cropEnableImageSelection: isEditMode,
      cropEnableZoomAutoHide: isEditMode,
      cropEnableZoomInput: isEditMode,
      cropEnableZoomMatchImageAspectRatio: isEditMode,
      cropEnableRotationInput: isEditMode,
      cropEnableButtonRotateLeft: isEditMode,
      cropEnableButtonFlipHorizontal: isEditMode,
      cropEnableButtonFlipVertical: isEditMode,
      cropEnableSelectPreset: isEditMode,
      cropEnableRotateMatchImageAspectRatio: isEditMode ? 'always' : 'never',
    };
  }

  private async _preprocessTiffFile(file: File): Promise<File> {
    if (file.type !== 'image/tiff') {
      return file;
    }
    const buffer = Buffer.from(await file.arrayBuffer());
    const image = await Jimp.read(buffer);
    const convertedBuffer = await image.getBufferAsync('image/png');
    const blob = new Blob([convertedBuffer], { type: 'image/png' });
    return blobToFile(blob, file.name);
  }
}
