import { Injectable, type OnDestroy } from '@angular/core';
import {
  Auth,
  User,
  fetchSignInMethodsForEmail,
  signInWithPopup,
  user,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { GoogleAuthProvider, type IdTokenResult } from '@firebase/auth';
import {
  filterUndefined,
  shareReplayCold,
  snapshot,
  type IAuthClaims,
  type IUserWorkspace,
} from '@principle-theorem/shared';
import {
  ActionCodeInfo,
  AdditionalUserInfo,
  UserCredential,
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  getAdditionalUserInfo,
  getIdTokenResult,
  isSignInWithEmailLink,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signOut,
  verifyPasswordResetCode,
} from 'firebase/auth';
import { Observable, Subject, noop, of } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { AuthFirebaseFunctionsService } from './auth-firebase-functions.service';
import { WorkspaceService } from './workspace.service';

export type AuthUser = User;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  authUser$: Observable<AuthUser | undefined>;
  authToken$: Observable<IdTokenResult | undefined>;
  claims$: Observable<IAuthClaims | undefined>;

  constructor(
    private _auth: Auth,
    private _workspace: WorkspaceService,
    private _functions: AuthFirebaseFunctionsService,
    private _router: Router
  ) {
    this.authUser$ = this._userTokenChanged$().pipe(shareReplayCold());

    this.authToken$ = this.authUser$.pipe(
      switchMap((currentUser) =>
        currentUser ? getIdTokenResult(currentUser) : of(undefined)
      )
    );

    this.claims$ = this.authToken$.pipe(
      map((token) =>
        token ? (token.claims as unknown as IAuthClaims) : undefined
      ),
      distinctUntilChanged(),
      // eslint-disable-next-line rxjs/no-unsafe-takeuntil
      takeUntil(this._onDestroy$),
      shareReplayCold()
    );

    this.authUser$
      .pipe(
        filterUndefined(),
        switchMap(() => this.checkWorkspaceAccess())
      )
      .subscribe(noop);
  }

  ngOnDestroy(): void {
    // This isn't ever called on a service :S
    this._onDestroy$.next();
  }

  async checkWorkspaceAccess(): Promise<void> {
    const workspaces = await this._functions.getUserWorkspaces();
    this._workspace.setWorkspaces(workspaces);

    if (!workspaces.length) {
      return;
    }

    if (workspaces.length === 1) {
      return this._workspace.setWorkspace(workspaces[0]);
    }

    const workspace: string | undefined = await snapshot(
      this._workspace.workspace$
    );

    if (!workspace) {
      return this._workspace.setWorkspace(workspaces[0]);
    }

    const workspaceData: IUserWorkspace | undefined = workspaces.find(
      (currentWorkspace) => currentWorkspace.workspace === workspace
    );
    if (!workspaceData) {
      return this._workspace.setWorkspace(workspaces[0]);
    }

    return this._workspace.setWorkspace(workspaceData);
  }

  async updateClaims(): Promise<void> {
    await this._functions.setAuthClaims();
    await this.loadClaims();
  }

  async logout(): Promise<void> {
    await this._workspace.reset();
    await signOut(this._auth);
    await this._router.navigate(['/login']);
  }

  async applyActionCode(code: string): Promise<void> {
    await applyActionCode(this._auth, code);
  }

  async checkActionCode(code: string): Promise<ActionCodeInfo> {
    return checkActionCode(this._auth, code);
  }

  async verifyPasswordCode(code: string): Promise<string> {
    return verifyPasswordResetCode(this._auth, code);
  }

  async confirmPasswordReset(code: string, password: string): Promise<void> {
    await confirmPasswordReset(this._auth, code, password);
  }

  async signIn(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this._auth, email, password);
  }

  isSignInLink(url: string): boolean {
    return isSignInWithEmailLink(this._auth, url);
  }

  async signInWithEmailLink(
    email: string,
    url: string
  ): Promise<AdditionalUserInfo | undefined> {
    const userCredential = await signInWithEmailLink(this._auth, email, url);
    return getAdditionalUserInfo(userCredential) ?? undefined;
  }

  getSignInMethods(email: string): Promise<string[]> {
    return fetchSignInMethodsForEmail(this._auth, email);
  }

  async signInWithGoogleProvider(): Promise<void> {
    await signInWithPopup(this._auth, new GoogleAuthProvider());
  }

  async loadClaims(): Promise<void> {
    const currentUser = await snapshot(user(this._auth));
    if (!currentUser) {
      throw new Error('Not Authenticated');
    }
    await getIdTokenResult(currentUser, true);
  }

  private _userTokenChanged$(): Observable<AuthUser | undefined> {
    return new Observable((subscriber) => {
      const unsubscribe = this._auth.onIdTokenChanged((currentUser) =>
        subscriber.next(currentUser ?? undefined)
      );
      return () => unsubscribe;
    });
  }
}
