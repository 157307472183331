import { Organisation } from '@principle-theorem/principle-core';
import {
  CustomMappingType,
  IUser,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  IBlobFilenamePair,
  XSLXImporterExporter,
  asyncForEach,
  multiMap,
  multiSortBy$,
  nameSorter,
  snapshot,
  type INamedDocument,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import Fuse from 'fuse.js';
import { first, sortBy, uniqBy } from 'lodash';
import { from } from 'rxjs';
import { BaseCustomMappingHandler } from '../base-custom-mapping-handler';
import { CustomMapping } from '../custom-mapping';
import { IBaseMigrationStaffer } from '../interfaces';
import { StafferToUserToXSLX } from '../mappings/staffer-to-user-to-xlsx';
import { XSLXToStafferToUser } from '../mappings/xlsx-to-staffer-to-user';
import { PracticeMigration } from '../practice-migrations';
import { BaseSourceEntity } from '../source/base-source-entity';
import { TranslationMapHandler } from '../translation-map';

export const STAFF_TO_USER_CUSTOM_MAPPING_TYPE = 'staffToUserCustomMapping';

export const STAFF_TO_USER_MAPPING = CustomMapping.init({
  metadata: {
    label: 'Multi-staffer to User',
    description: `Used for mapping multiple of the same staff member to the respective user in Principle. This can happen in multi-practice environments where the practitioner is practicing in multiple practices.

    The Source Identifier is the staff id, the Destination Identifier is the DocumentReference path for the user.`,
    type: STAFF_TO_USER_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.DocumentReference,
  labelOverrides: {
    sourceIdentifier: 'Id',
    sourceLabel: 'Staff Member',
    destinationIdentifier: 'Principle Staff Member',
  },
});

export abstract class StaffToUserMappingHandler<
  Staffer extends object,
  SourceEntity extends BaseSourceEntity<Staffer>,
> extends BaseCustomMappingHandler<IUser> {
  customMapping = STAFF_TO_USER_MAPPING;
  abstract translateFn: (record: Staffer) => IBaseMigrationStaffer;
  abstract sourceEntity: SourceEntity;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 1000)
      .toPromise();
    const options = records
      .map((record) => record.data.data)
      .map((record) => {
        const staffer = this.translateFn(record);
        return {
          label: `${staffer.name} ${staffer.providerNo}`,
          value: this.sourceEntity.getSourceRecordId(record).toString(),
        };
      });

    return sortBy(options, 'label');
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<IUser>[]> {
    return snapshot(
      from(
        Firestore.getDocs(
          Organisation.userCol(migration.configuration.organisation)
        )
      ).pipe(
        multiMap((user) => ({
          name: user.name,
          ref: user.ref,
        })),
        multiSortBy$(nameSorter())
      )
    );
  }

  async autocompleteMappings(
    migration: WithRef<IPracticeMigration>
  ): Promise<void> {
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const sourceOptions = await this.getSourceOptions(migration);
    const destinationOptions = await this.getDestinationOptions(migration);
    const records = await this.getRecords(translationMap);

    const mappedItemCodes = records
      .filter((record) => !!record.destinationIdentifier)
      .map((record) => record.sourceIdentifier);
    const unmappedItemCodes = sourceOptions.filter(
      (option) => !mappedItemCodes.includes(option.value)
    );

    await asyncForEach(unmappedItemCodes, async (item) => {
      const fuse = new Fuse(destinationOptions, {
        keys: ['name'],
        includeScore: true,
        threshold: 0.3,
      });
      const matches = fuse.search(item.label);
      const match = first(matches)?.item;

      if (!match) {
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: match.ref,
          destinationValue: match.name,
          sourceIdentifier: item.value,
          sourceLabel: item.label,
        },
        translationMap
      );
    });
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, appointmentStatuses, translator } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(
      fileName,
      appointmentStatuses,
      translator
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, appointmentStatuses, translator } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(
      fileName,
      appointmentStatuses,
      translator
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToStafferToUser()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const sourceOptions = await this.getSourceOptions(migration);
    const users = await snapshot(
      Organisation.users$(migration.configuration.organisation)
    );

    await asyncForEach(items, async (item) => {
      const matchingOption = sourceOptions.find(
        (sourceOption) => sourceOption.value === item.id
      );

      if (!matchingOption) {
        return;
      }

      const label = matchingOption.label;
      const value = matchingOption.value;

      const user = users.find((searchUser) => searchUser.name === item.mapTo);

      if (!user) {
        // eslint-disable-next-line no-console
        console.error(
          `Mapping error: ${this.customMapping.metadata.label} - Couldn't find user for item`,
          item
        );
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: user.ref,
          destinationValue: item.mapTo,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    appointmentStatuses: IBaseMigrationStaffer[];
    translator: StafferToUserToXSLX;
  }> {
    const fileName = this.getFileName();
    const appointmentStatuses = await this._getUserOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const translator = new StafferToUserToXSLX(
      await snapshot(Organisation.users$(migration.configuration.organisation)),
      await this.getRecords(translationMap)
    );
    return { fileName, appointmentStatuses, translator };
  }

  private async _getUserOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<IBaseMigrationStaffer[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 10000)
      .toPromise();
    return sortBy(
      uniqBy(
        records.map((record) => this.translateFn(record.data.data)),
        (record) => record.id
      ),
      'name'
    );
  }
}
