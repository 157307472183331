<mat-list-item *ngIf="appointments$ | ngrxPush as appointments">
  <mat-icon matListItemIcon>compare_arrows</mat-icon>
  <span matListItemTitle>
    <span *ngIf="appointments.length">Upcoming Appointments</span>
    <span *ngIf="!appointments.length">No upcoming appointments</span>
  </span>
  <div
    matListItemLine
    *ngFor="let appointment of appointments; trackBy: trackByAppointment"
  >
    <pr-formatted-event-field
      *ngIf="appointment.event"
      field="from"
      [event]="appointment.event"
      [format]="dateService.format.dateWithTimes"
    />
    - {{ appointment.treatmentPlan.name }}
  </div>
</mat-list-item>
