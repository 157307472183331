import { type File, type Storage } from '@google-cloud/storage';

export interface IDataStorage {
  storage: Storage;
  getFile(): File;
}

export interface IFileStorageCopy extends IDataStorage {
  copy(destinationPath: string): Promise<File>;
}

export interface IFileStorage extends IDataStorage {
  getFilePath(): string;
  store(): Promise<File>;
}
