import {
  IPracticeMigration,
  ITranslationMap,
  type ICustomMapping,
  type ICustomMappingHandler,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentData,
  IBlobFilenamePair,
  slugify,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { type Observable } from 'rxjs';
import { type TranslationMapHandler } from './translation-map';

export abstract class BaseCustomMappingHandler<
  DestinationRefType extends object = DocumentData,
  DestinationValueType = unknown,
  AssociatedValueType = unknown,
> implements
    ICustomMappingHandler<
      DestinationRefType,
      DestinationValueType,
      AssociatedValueType
    >
{
  abstract customMapping: ICustomMapping;

  abstract downloadMapping(
    migration: WithRef<IPracticeMigration>
  ): Promise<void>;

  abstract getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair>;

  abstract uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: Blob
  ): Promise<void>;

  canHandle(customMapping: ICustomMapping): boolean {
    return isEqual(
      customMapping.metadata.type,
      this.customMapping.metadata.type
    );
  }

  upsertRecord(
    record: Omit<
      ITranslationMap<DestinationRefType, DestinationValueType>,
      'resourceType'
    >,
    translationMap: TranslationMapHandler
  ): Promise<void> {
    return translationMap.upsert({
      ...record,
      resourceType: this.customMapping.metadata.type,
    });
  }

  getRecords$(
    translationMap: TranslationMapHandler
  ): Observable<
    WithRef<ITranslationMap<DestinationRefType, DestinationValueType>>[]
  > {
    return translationMap.getByType$<DestinationRefType, DestinationValueType>(
      this.customMapping.metadata.type
    );
  }

  getRecords(
    translationMap: TranslationMapHandler
  ): Promise<
    WithRef<ITranslationMap<DestinationRefType, DestinationValueType>>[]
  > {
    return translationMap.getByType<DestinationRefType, DestinationValueType>(
      this.customMapping.metadata.type
    );
  }

  getBySource(
    sourceId: string,
    translationMap: TranslationMapHandler
  ): Promise<
    | WithRef<ITranslationMap<DestinationRefType, DestinationValueType>>
    | undefined
  > {
    return translationMap.getBySource(
      sourceId,
      this.customMapping.metadata.type
    );
  }

  getByDestination(
    destinationId: DocumentReference<DestinationRefType>,
    translationMap: TranslationMapHandler
  ): Promise<
    | WithRef<ITranslationMap<DestinationRefType, DestinationValueType>>
    | undefined
  > {
    return translationMap.getByDestination(
      destinationId,
      this.customMapping.metadata.type
    );
  }

  getFileName(): string {
    return slugify(this.customMapping.metadata.label);
  }
}
