import { type ISoftDelete } from '@principle-theorem/shared';
import { type ChecklistType } from './appointment-checklist';

export enum ChecklistItemAction {
  None = 'none',
  Print = 'print',
  Email = 'email',
}

export const CHECKLIST_ITEM_ACTIONS: ChecklistItemAction[] = [
  ChecklistItemAction.None,
  ChecklistItemAction.Print,
  ChecklistItemAction.Email,
];

export interface IChecklistItem extends ISoftDelete {
  type: ChecklistType;
  title: string;
  action: ChecklistItemAction;
  done: boolean;
  data?: string;
}
