import {
  IContextBuilder,
  ITemplateContext,
  ITemplateContextOption,
  TemplateScope,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  IProvider,
  reduceToSingleArrayFn,
} from '@principle-theorem/shared';

export function getMissingContextPlaceholder(name: string): string {
  return `MISSING ${name}`;
}

export interface IContextResolver {
  resolve(
    scopes: TemplateScope[]
  ): Promise<ITemplateContext> | ITemplateContext;
}

export class EmptyContextProvider
  implements IProvider<TemplateScope[], Promise<ITemplateContextOption[]>>
{
  canProvide(scopes: TemplateScope[]): boolean {
    return scopes.includes(TemplateScope.None);
  }

  async execute(): Promise<ITemplateContextOption[]> {
    return Promise.resolve([
      {
        label: '',
        scope: TemplateScope.None,
        scopeData: {},
        context: {},
      },
    ]);
  }
}

export class ContextResolverCollection {
  constructor(
    private _providers: IProvider<
      TemplateScope[],
      Promise<ITemplateContextOption[]>
    >[]
  ) {}

  async resolve(scopes: TemplateScope[]): Promise<ITemplateContextOption[]> {
    const found = this._providers.filter((provider) =>
      provider.canProvide(scopes)
    );
    if (!found.length) {
      throw new Error(
        `No ContextProvider found for scopes: ${scopes.join(',')}`
      );
    }
    return (
      await asyncForEach(found, (provider) => provider.execute(scopes))
    ).reduce(reduceToSingleArrayFn);
  }
}

export class ScopeContextResolver {
  constructor(
    private _providers: IProvider<TemplateScope[], ITemplateContext>[]
  ) {}

  resolve(scopes: TemplateScope[]): ITemplateContext {
    const found = this._providers.find((provider) =>
      provider.canProvide(scopes)
    );
    if (!found) {
      throw new Error(
        `No ScopeContextProvider found for scopes: ${scopes.join(',')}`
      );
    }
    return found.execute(scopes);
  }
}

export class ScopeContextProvider
  implements IProvider<TemplateScope[], ITemplateContext>
{
  constructor(
    private _scope: TemplateScope,
    private _builder: IContextBuilder<ITemplateContext>
  ) {}

  canProvide(scopes: TemplateScope[]): boolean {
    return scopes.includes(this._scope);
  }

  execute(): ITemplateContext {
    return this._builder.build();
  }
}
