<mat-toolbar color="accent">Who is the deposit for?</mat-toolbar>
<mat-dialog-content>
  <form
    [formGroup]="reservedForForm"
    (ngSubmit)="submit()"
    class="flex flex-col"
  >
    <pr-staff-selector
      formControlName="staffer"
      [practice]="data.practice"
      [permissions]="permissions"
      [showNone]="true"
    />

    <mat-form-field class="!flex grow" floatLabel="always">
      <mat-label>Treatment Category</mat-label>
      <mat-select
        formControlName="treatmentCategory"
        [compareWith]="treatmentCategoryBloc.compareFn"
        placeholder="None"
      >
        <mat-select-trigger>
          @if (treatmentCategoryBloc.selected$ | async; as treatmentCategory) {
            {{ treatmentCategory?.name }}
            <div
              class="inline-block h-3 w-3 rounded-full"
              [style.backgroundColor]="treatmentCategory?.colour.value"
            ></div>
          }
        </mat-select-trigger>
        <mat-option> None </mat-option>
        @for (
          treatmentCategory of treatmentCategoryBloc.options$ | async;
          track treatmentCategory.ref.path
        ) {
          <mat-option [value]="treatmentCategory.ref">
            {{ treatmentCategory.name }}
            <div
              class="inline-block h-3 w-3 rounded-full"
              [style.backgroundColor]="treatmentCategory.colour.value"
            ></div>
          </mat-option>
        }
      </mat-select>
      <button
        type="button"
        matIconSuffix
        mat-icon-button
        [disabled]="treatmentCategoryBloc.resetDisabled$ | async"
        (click)="treatmentCategoryBloc.clear($event)"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary">
        @if (isEdit) {
          Save
        } @else {
          Add
        }
      </button>
    </pt-buttons-container>
  </form>
</mat-dialog-content>
