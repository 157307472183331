import { Region } from '@principle-theorem/shared';

export function calculateTaxFromTotal(
  total: number,
  taxPercent: number
): number {
  const taxFraction = taxPercent / 100;
  return (total / (1 + taxFraction)) * taxFraction;
}

export enum TaxRate {
  GST = 10,
  GSTNZ = 15,
}

export enum TaxStrategy {
  GSTApplicable = 'GSTApplicable',
  GSTPossible = 'GSTPossible',
  GSTFree = 'GSTFree',
}

export function canChangeTaxAmount(taxStrategy: TaxStrategy): boolean {
  if (taxStrategy === TaxStrategy.GSTPossible) {
    return true;
  }
  return false;
}

export const REGION_TAX_RATES: Record<Region, TaxRate> = {
  [Region.Australia]: TaxRate.GST,
  [Region.NewZealand]: TaxRate.GSTNZ,
};

export function getTaxRateByRegion(region: Region): TaxRate {
  return REGION_TAX_RATES[region];
}
