<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  aria-label="perio settings menu"
>
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button (click)="openPerioSettings()" mat-menu-item>
    <mat-icon>settings</mat-icon>
    <span>Periodontal Settings</span>
  </button>
  <button
    (click)="deletePerioChartData()"
    mat-menu-item
    [disabled]="immutable$ | async"
  >
    <mat-icon color="warn">delete</mat-icon>
    <span class="text-warn-500">Delete Chart</span>
  </button>
</mat-menu>
