import {
  rand,
  randAvatar,
  randBoolean,
  randEmail,
  randFullName,
  randPhoneNumber,
} from '@ngneat/falso';
import {
  IBrand,
  IPractice,
  IRole,
  IUser,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  MockTimestamp,
  Properties,
} from '@principle-theorem/shared';
import { DocumentReference, Timestamp } from '@principle-theorem/shared';
import { PROFILE_PICS } from '../patient/patient.mock';

export class UserMock extends BaseFirestoreMock implements Properties<IUser> {
  get name(): string {
    return randFullName();
  }

  get phone(): string {
    return randPhoneNumber();
  }

  get email(): string {
    return randEmail();
  }

  get profileImageURL(): string {
    return randAvatar();
  }

  get roles(): DocumentReference<IRole>[] {
    return [];
  }

  get practices(): DocumentReference<IPractice>[] {
    return [];
  }

  get brands(): DocumentReference<IBrand>[] {
    return [];
  }

  get isEnabled(): boolean {
    return randBoolean();
  }

  get firstSignedInAt(): Timestamp {
    return MockTimestamp();
  }

  get lastSignInTime(): Timestamp {
    return MockTimestamp();
  }

  get lastActiveAt(): Timestamp {
    return MockTimestamp();
  }
}

/**
 * Mock a user
 *
 * @export
 * @param {string} name
 * @returns {Practitioner}
 */
export function MockUser(name: string): IUser {
  const emailPrefix: string = name.toLowerCase().split(' ').join('.');
  return {
    name,
    phone: randPhoneNumber(),
    email: `${emailPrefix}@principle.dental`,
    profileImageURL: rand(PROFILE_PICS),
    roles: [],
    practices: [],
    brands: [],
    isEnabled: true,
  };
}

/**
 * Contains a list of users names
 */
export const USERS: string[] = [
  'Pierre Fauchard',
  'Edward Angle',
  'Frank Abbott',
  'William Rollins',
  'Lucy Taylor',
];
