<div fxLayout="column" fxLayoutGap="16px">
  <pt-search-field icon="person_add">
    <input
      matInput
      placeholder="Assign Staffer"
      [formControl]="searchCtrl"
      [matAutocomplete]="assignStafferAuto"
    />
    <mat-autocomplete
      #assignStafferAuto="matAutocomplete"
      autoActiveFirstOption
      [displayWith]="displayFn"
      (optionSelected)="assign($event.option.value)"
    >
      <ng-container
        *ngIf="unselectedSearch.results$ | async as unselectedStaff"
      >
        <mat-option
          *ngFor="let staffer of unselectedStaff; trackBy: trackByStaffer"
          [value]="staffer"
        >
          {{ staffer.user.name }}
          <span class="mat-caption">
            <ng-container *ngIf="staffer.teams.length">-</ng-container>-
            <ng-container
              *ngFor="let team of staffer.teams; trackBy: trackByTeam"
            >
              {{ team.name }}
            </ng-container>
          </span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </pt-search-field>

  <mat-list>
    <mat-list-item
      *ngFor="let staffer of selected$ | async; trackBy: trackByStaffer"
    >
      <span matListItemTitle>{{ staffer.user.name }}</span>
      <div matListItemMeta>
        <button mat-icon-button color="warn" (click)="unassign(staffer)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </mat-list-item>
  </mat-list>
</div>
