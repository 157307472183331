import { AppointmentPermissions } from '@principle-theorem/principle-core/features';
import { IStaffer } from '@principle-theorem/principle-core/interfaces';
import { snapshot, WithRef } from '@principle-theorem/shared';
import { map } from 'rxjs/operators';
import { ConditionConfiguration } from '../clinical-charting/conditions/condition-configuration';
import {
  setConditionConfigurations,
  upsertConditionConfigurations,
} from '../clinical-charting/conditions/condition-configuration-defaults';
import { MultiTreatmentConfiguration } from '../clinical-charting/treatment/multi-treatment-configuration';
import { addMultiTreatmentConfigurations } from '../clinical-charting/treatment/multi-treatment-configuration-defaults';
import { TreatmentConfiguration } from '../clinical-charting/treatment/treatment-configuration';
import {
  setTreatmentConfigurations,
  upsertTreatmentConfigurations,
} from '../clinical-charting/treatment/treatment-configuration-defaults';
import { Staffer } from './staffer';

export async function setStafferDefaults(
  staffer: WithRef<IStaffer>,
  addConfigs: boolean = false
): Promise<void> {
  const canConductAppointments = await snapshot(
    Staffer.hasPermission$(staffer, AppointmentPermissions.AppointmentConduct)
  );
  if (!canConductAppointments) {
    return;
  }

  if (!addConfigs) {
    return;
  }

  const hasConditionConfigurations = await snapshot(
    ConditionConfiguration.all$(staffer).pipe(
      map((conditions) => conditions.length > 0)
    )
  );
  if (!hasConditionConfigurations) {
    await setConditionConfigurations(staffer);
  } else {
    await upsertConditionConfigurations(staffer);
  }

  const hasTreatmentConfigurations = await snapshot(
    TreatmentConfiguration.all$(staffer).pipe(
      map((treatments) => treatments.length > 0)
    )
  );
  if (!hasTreatmentConfigurations) {
    await setTreatmentConfigurations(staffer);
  } else {
    await upsertTreatmentConfigurations(staffer);
  }

  const hasMultiTreatments = await snapshot(
    MultiTreatmentConfiguration.all$(staffer).pipe(
      map((treatments) => treatments.length > 0)
    )
  );
  if (!hasMultiTreatments) {
    await addMultiTreatmentConfigurations(staffer);
  }
}
