import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TransactionProviders } from '@principle-theorem/ng-payments';
import { Invoice } from '@principle-theorem/principle-core';
import {
  IInvoice,
  InvoiceStatus,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-add-payment-button',
  templateUrl: './add-payment-button.component.html',
  styleUrls: ['./add-payment-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPaymentButtonComponent {
  invoice$ = new ReplaySubject<WithRef<IInvoice>>(1);
  canCapture$: Observable<boolean>;
  isDisabled$: Observable<boolean>;
  adding$: Observable<boolean>;
  info$: Observable<string | undefined>;

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this.invoice$.next(invoice);
    }
  }

  constructor(public transactions: TransactionProviders) {
    this.canCapture$ = this.invoice$.pipe(
      map(
        (invoice) =>
          invoice.status !== InvoiceStatus.Paid &&
          Invoice.canAddTransactions(invoice)
      )
    );
    this.isDisabled$ = this.canCapture$.pipe(map((canCapture) => !canCapture));
    this.info$ = of(undefined);
    this.adding$ = of(false);
  }
}
