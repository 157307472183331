import {
  IAccountDetails,
  IBasePatient,
  ICustomLineItem,
  IInvoice,
  InvoiceType,
  IPatient,
  IPatientContactDetails,
  IPractice,
  isPractice,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  IReffable,
  toNamedDocument,
  WithRef,
} from '@principle-theorem/shared';
import { Patient } from '../patient/patient';
import { Invoice } from './invoice';
import { InvoiceInteractionBuilder } from './invoice-interaction-builder';
import { NameHelpers } from '../patient/name-helpers';
import { OrganisationCache } from '../organisation/organisation-cache';

export async function createInvoice(
  patient: IReffable<IPatient>,
  practice: WithRef<IPractice>,
  staffer: WithRef<IStaffer>,
  items: ICustomLineItem[] = [],
  invoiceType: InvoiceType = InvoiceType.Invoice
): Promise<WithRef<IInvoice>> {
  const primaryContact = await Patient.resolvePrimaryContact(patient.ref);
  const resolvedPatient = await OrganisationCache.patients.getDoc(patient.ref);
  const onBehalfOf = toAccountDetails(
    resolvedPatient as IBasePatient & IPatientContactDetails
  );

  const invoice: IInvoice = Invoice.init({
    to: primaryContact
      ? {
          ...toAccountDetails(primaryContact),
          onBehalfOf,
        }
      : onBehalfOf,
    from: toAccountDetails(practice),
    practice: toNamedDocument(practice),
    items,
    type: invoiceType,
  });
  const ref = await addDoc(Patient.invoiceCol(patient), invoice);

  const result = { ...invoice, ref };
  await Invoice.addInteraction(
    result,
    InvoiceInteractionBuilder.createdInteraction(staffer)
  );
  return result;
}

export function toAccountDetails(
  entity: (IBasePatient & IPatientContactDetails) | IPractice
): IAccountDetails {
  const accountDetails: IAccountDetails = {
    name: NameHelpers.fullName(entity.name),
    address: entity.address,
  };

  if (isPractice(entity)) {
    accountDetails.phone = entity.phone;
  }

  return accountDetails;
}
