import { FeatureVisibility, IFeature } from '@principle-theorem/feature-flags';

export enum ContactPermissions {
  ContactManage = 'contacts.manage',
}

export const CONTACTS_FEATURE: IFeature = {
  label: 'contacts',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: ContactPermissions.ContactManage,
      description: `User can manage and interact with practice contacts.`,
    },
  ],
};
