import { Injectable } from '@angular/core';
import {
  IAccountCreditExtendedData,
  ITransaction,
  TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import { Region, type DocumentReference } from '@principle-theorem/shared';
import { of, type Observable } from 'rxjs';
import {
  TransactionProviderType,
  type ITransactionProvider,
} from '../transaction-provider';

@Injectable()
export class AccountCreditTransferTransactionProvider
  implements ITransactionProvider
{
  providerId = TransactionProvider.AccountCreditTransfer;
  providerType = TransactionProviderType.Payment;
  providerRegions = [Region.Australia, Region.NewZealand];
  isEnabled$ = of(false);

  constructor() {}

  canCapture$(): Observable<boolean> {
    return of(false);
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async capture(): Promise<
    DocumentReference<ITransaction<IAccountCreditExtendedData>> | undefined
  > {
    throw new Error('Method not implemented.');
  }
}
