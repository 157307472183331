/* eslint-disable no-null/no-null */
import {
  IExpectedSourceRecordSize,
  SourceEntityMigrationType,
  type IPracticeMigration,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  toTimestamp,
  type Timestamp,
  type Timezone,
  type WithRef,
} from '@principle-theorem/shared';
import {
  compact,
  flow,
  groupBy,
  isBoolean,
  isNull,
  isNumber,
  isString,
  orderBy,
} from 'lodash';
import * as moment from 'moment-timezone';
import { MomentInput } from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { runQuery } from '../../../source/connection';
import { SourceEntity } from '../../../source/source-entity';
import { OFFSET_PLACEHOLDER } from '../../../source/source-helpers';
import { PATIENT_INVOICE_DESTINATION_ENTITY } from '../../destination/entities/patient-invoices';

export const PATIENT_INVOICE_RESOURCE_TYPE = 'patientInvoice';

export const PATIENT_INVOICE_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient Invoice List',
    description: '',
    idPrefix: PATIENT_INVOICE_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ID4WPatientInvoice {
  account_id: number;
  practice_id: number;
  created_at: string;
  due_at: string | null;
  account_total: string;
  account_amount_paid: string;
  account_rebate: string;
  send_to_patient_id: number | null;
  send_to_third_party_id: number | string | null;
  payments: ID4WPatientInvoicePayment[];
}

export function isD4WPatientInvoice(item: unknown): item is ID4WPatientInvoice {
  return TypeGuard.interface<ID4WPatientInvoice>({
    account_id: isNumber,
    practice_id: isNumber,
    created_at: isString,
    due_at: [isString, isNull],
    account_total: isString,
    account_amount_paid: isString,
    account_rebate: isString,
    send_to_patient_id: [isNumber, isNull],
    send_to_third_party_id: [isNumber, isString, isNull],
    payments: TypeGuard.arrayOf(isD4WPatientInvoicePayment),
  })(item);
}

export interface ID4WPatientInvoicePayment {
  // These values are unique to the payment
  payment_id: number | null;
  total_payment_id: number | null;
  payment_type: D4WPaymentType | null;
  payment_comment: string | null;
  payment_allocation_id: number | null;
  payment_amount: string | null;
  total_payment_amount: string | null;

  // This value should be used for transaction amounts
  true_payment_amount: string | null;

  is_payment_from_deposit: boolean | null;
  reference_deposit_payment_id: string | number | null;

  is_deposit_payment: boolean;
  deposit_original_total_payment_id: number | null;
  deposit_patient_id: number | null;

  discount_category_id: string | number | null;
  reverse_id: number | null;

  has_transaction: boolean;
  transaction_id: number | null;
  payment_method: string | null;
  payment_method_id: D4WMethodOfPayment | number | null;
  tot_payment_created_at: string | null;
  paid_by_patient_id: number | null;
  paid_by_third_party_id: number | string | null;

  // These values are the same across the payment plan id
  payment_plan_paid_amount: string | null;
  installment_number: number;
  installment_amount: string;
  payment_plan_id: number;
  payment_plan_ref_status: string | null;
  payment_allocation_ref_status: string | null;
  allocation_details_ref_status: string | null;
  payments_ref_status: string | null;
  total_payment_ref_status: string | null;
}

export function isD4WPatientInvoicePayment(
  item: unknown
): item is ID4WPatientInvoicePayment {
  return TypeGuard.interface<ID4WPatientInvoicePayment>({
    payment_id: [isNumber, isNull],
    total_payment_id: [isNumber, isNull],
    payment_type: [TypeGuard.enumValue(D4WPaymentType), isNull],
    payment_comment: [isString, isNull],
    payment_allocation_id: [isNumber, isNull],
    payment_amount: [isString, isNull],
    total_payment_amount: [isString, isNull],
    true_payment_amount: [isString, isNull],
    is_payment_from_deposit: [isBoolean, isNull],
    reference_deposit_payment_id: [isString, isNumber, isNull],
    has_transaction: isBoolean,
    transaction_id: [isNumber, isNull],
    payment_method: [isString, isNull],
    payment_method_id: [
      TypeGuard.enumValue(D4WMethodOfPayment),
      isNumber,
      isNull,
    ],
    tot_payment_created_at: [
      (value): value is string => moment(value as MomentInput).isValid(),
      isNull,
    ],
    payment_plan_paid_amount: [isString, isNull],
    installment_number: isNumber,
    installment_amount: isString,
    payment_plan_id: isNumber,
    payment_plan_ref_status: [isString, isNull],
    payment_allocation_ref_status: [isString, isNull],
    allocation_details_ref_status: [isString, isNull],
    payments_ref_status: [isString, isNull],
    total_payment_ref_status: [isString, isNull],
    is_deposit_payment: isBoolean,
    deposit_original_total_payment_id: [isNumber, isNull],
    deposit_patient_id: [isNumber, isNull],
    discount_category_id: [isString, isNumber, isNull],
    reverse_id: [isNumber, isNull],
    paid_by_patient_id: [isNumber, isNull],
    paid_by_third_party_id: [isNumber, isString, isNull],
  })(item);
}

export interface ID4WPatientInvoiceResult
  extends ID4WPatientInvoice,
    ID4WPatientInvoicePayment {
  account_ref_status: string | null;
}

export interface ID4WPatientInvoiceResultAdditionalData {
  period_type: D4WPaymentPlanPeriodType;
  accept_fund_only: 'Y' | 'N' | 'P';
  total: string; // Total sum to be paid for the account
  rebate: string; // calucted total rebate from treat rows.
  amount_paid: string; // Already paid amount in currency of the account (calculated from payment records)
  accounts_sum_id: string; // Group Account ID (ref. ACCOUNTS_SUM)
  is_bad_debt: boolean;
  group_account_id: number; // Group Account ID, created this Account (ref. ACCOUNTS_SUM)
  is_surcharge: '0' | '1' | '2' | '3' | '4' | '5'; // Account type: 0=Usual , 1=Surcharge,  2=Bank Penalty for Bounced Payment, 3=Provider Penalty for Bounced Payment, 4=Account for refund item, 5=Reversal Account
  bounce_payment_id: number; // Penalty Acc: Reversal Payment  |  Refund Acc: Refund Payment (ref tot_payment)
  referral_medicare_id: number | null; // "REF ""patients_referral_medicare"""
  patient_id: number | null;
  third_party_id: number | null;
  created_at: string;
  number_of_installments: number;
  interest_rate: string;
  over_period_of: string;
  is_family_account: boolean;
  practice_id: number;
  provider_ids: string;
  lab_fees: string;
  ref_number: number | null;
  ref_status: string | null;
  is_cash: boolean;
  claim_id: number | null;
  accounting_period: string;
  source_acc_id: number | null;
  promotion_id: string | null;
  promotion_amount: string | null;
}

export enum D4WPaymentPlanPeriodType {
  Days = 1,
  Weeks = 2,
  Fortnights = 3,
  Months = 4,
}

export enum D4WPaymentType {
  Usual = 0, // Denoted as normal payment
  Discount = 1,
  RefundFromDeposit = 2, // Denoted as refund amount
  BouncingReversal = 3,
  RefundOfTreat = 4,
  Reversal = 5,
}

export enum D4WTransactionStatus {
  'Prepared Not sent' = 0,
  'Sent No Response' = 1,
  'Received UnProcessed' = 2,
  'Done' = 3,
  'Cancelled' = 4,
  'Prepared Not sent (Archived)' = 10,
  'Sent No Response (Archived)' = 11,
  'Received UnProcessed (Archived)' = 12,
  'Done (Archived)' = 13,
  'Cancelled (Archived)' = 14,
}

export enum D4WMethodOfPayment {
  'Cash' = 1,
  'MasterCard-not EFT' = 2,
  'Visa Card-not EFT' = 3,
  'Bankcard-not EFT' = 4,
  'Health Fund Cheque' = 5,
  'Private Cheque' = 6,
  'EFTPOS Debit Card' = 7,
  'Diners Club-not EFT' = 8,
  'American Express-not EFT' = 9,
  'Deposit' = 10,
  'EFTPOS Credit Card' = 11,
  'JCB-not EFT' = 12,
  'Other' = 13,
  'BarterCard' = 14,
  'TradeBanc' = 15,
  'BBX -barter' = 16,
  'HICAPS' = 17,
  'SADS' = 18,
  'Direct Payment' = 19,
  'Money Order' = 20,
  'OPTUS Health' = 21,
  'Discount' = 22,
  'HICAPS e-Payment' = 23,
  'Vet Affairs' = 24,
  'EFTPOS e-payment' = 25,
  'Fund e-Payment' = 26,
  'HNE' = 27,
  'Veterans Affair' = 28,
  'Visa-not EFT' = 29,
  'NDP Upfront' = 30,
  'NDP Upfront 2' = 31,
  'NDP Upfront 3' = 32,
  'EFTPOS PAYMENT' = 33,
  'Diners-not EFT' = 34,
  'AmEx-not EFT' = 35,
  'DentiCare' = 36,
  'EFT Credit Card' = 37,
  'NDP Upfront 4' = 38,
  'Other 2' = 39,
  'Direct Payment 2' = 45,
  'OPTUS Health 2' = 47,
  'Petty' = 50,
  'DVA e-Payment' = 51,
  'HealthPoint e-Payment' = 52,
  'Healthpay' = 53,
  'Whitecoat e-Payment' = 54,
  'Bonuses' = 55,
  'NDP Upfront 5' = 56,
}

export interface ID4WPatientInvoiceTranslations {
  createdAt: Timestamp;
  dueAt?: Timestamp;
}

export interface ID4WPatientInvoiceFilters {
  createdAt: Timestamp;
  accountId: string;
  practiceId: string;
  referenceDepositPaymentIds: string[];
  depositOriginalTotalPaymentIds: string[];
  depositPatientIds: string[];
  discountCategoryIds: string[];
  reverseIds: string[];
  paidByPatientIds: string[];
  paidByThirdPartyIds: string[];
  paymentIds: string[];
  totalPaymentIds: string[];
  paymentPlanIds: string[];
  paymentAllocationIds: string[];
}

const PATIENT_INVOICE_SOURCE_QUERY = `
SELECT
  total_payment.tot_payment_created_at,
  account.account_created_at AS created_at,
  payment_plan.due_at AS due_at,
  coalesce(transaction.has_transaction, false) AS has_transaction,
  transaction.transaction_id,
  method.description AS payment_method,
  payment.payment_method_id,
  payment_plan.installment_number AS installment_number,
  account.total AS account_total,
  account.amount_paid AS account_amount_paid,
  payment_plan.installment_amount AS installment_amount,
  payment_plan.amount_paid AS payment_plan_paid_amount,
  payment.amount AS payment_amount,
  total_payment.amount AS total_payment_amount,
  allocation_details.amount AS true_payment_amount,
  account.rebate AS account_rebate,
  total_payment.payment_type,
  payment.comment AS payment_comment,
  account.is_bad_debt,
  payment.is_payment_from_deposit,
  payment.reference_deposit_payment_id,
  coalesce(deposit.is_deposit, false) AS is_deposit_payment,
  deposit.original_total_payment_id AS deposit_original_total_payment_id,
  deposit.patient_id AS deposit_patient_id,
  total_payment.discount_category_id AS discount_category_id,
  total_payment.reverse_id,
  total_payment.paid_by_patient_id,
  total_payment.paid_by_third_party_id,
  send_to_patient_id,
  send_to_third_party_id,
  account.practice_id AS practice_id,
  payment.id AS payment_id,
  account.id AS account_id,
  total_payment.id AS total_payment_id,
  payment_plan.id AS payment_plan_id,
  payment_allocation.id AS payment_allocation_id,
  account.ref_status AS account_ref_status,
  payment_plan.ref_status AS payment_plan_ref_status,
  payment_allocation.ref_status AS payment_allocation_ref_status,
  allocation_details.ref_status AS allocation_details_ref_status,
  payment.ref_status AS payments_ref_status,
  total_payment.ref_status AS total_payment_ref_status
FROM  (
  SELECT
    id,
    NULLIF(CONCAT(date_created, ' ', time_created), ' ') AS account_created_at,
    send_acc_to_pat_id AS send_to_patient_id,
    send_acc_to_third_party_id AS send_to_third_party_id,
    practice_id,
    convert_to_boolean(is_cash) AS is_cash,
    convert_to_boolean(is_bad_debt) AS is_bad_debt,
    when_deleted AS deleted_at,
    ref_number,
    convert_empty_string_to_null(ref_status) AS ref_status,
    total,
    rebate,
    amount_paid
    FROM patients_accounts
  WHERE ref_status != 'D'
  ORDER BY id
  ${OFFSET_PLACEHOLDER}
) AS account
INNER JOIN (
  SELECT
    account_payment_plan_id AS id,
    patient_account_id AS account_id,
    instalment_number AS installment_number,
    instalment AS installment_amount,
    amount_paid,
    date_due AS due_at,
    ref_number,
    convert_empty_string_to_null(ref_status) AS ref_status
  FROM account_payment_plan
) AS payment_plan
ON payment_plan.account_id = account.id
LEFT JOIN (
  SELECT
    payment_allocation_id AS id,
    account_payment_plan_id AS payment_plan_id,
    ref_number,
    convert_empty_string_to_null(ref_status) AS ref_status,
    tot_paym_id AS total_payment_id,
    ref_number
  FROM payment_allocations
) AS payment_allocation
ON payment_allocation.payment_plan_id = payment_plan.id
LEFT JOIN (
  SELECT
    payment_allocation_id AS allocation_id,
    payment_id,
    payment_allocation_id,
    amount,
    convert_empty_string_to_null(ref_status) AS ref_status
  FROM payment_allocations_details
) AS allocation_details
ON allocation_details.payment_allocation_id = payment_allocation.id
LEFT JOIN (
  SELECT
    id,
    tot_paym_id AS total_payment_id,
    ref_number,
    convert_empty_string_to_null(ref_status) AS ref_status,
    method_of_paym_id AS payment_method_id,
    amount,
    NULLIF(check_name, '') AS comment,
    CASE
      WHEN deposit_tot_payment_id_from IS NOT NULL
        THEN TRUE
      ELSE FALSE
    END is_payment_from_deposit,
    deposit_tot_payment_id_from AS reference_deposit_payment_id
  FROM payments
) AS payment
ON payment.id = allocation_details.payment_id
LEFT JOIN (
  SELECT
    method_of_paym_id AS id,
    description
  FROM methods_of_paym
) AS method
ON payment.payment_method_id = method.id
LEFT JOIN (
  SELECT
    e_trans_id AS transaction_id,
    payment_id,
    TRUE AS has_transaction
  FROM e_transactions
) as transaction
ON payment.id::TEXT = transaction.payment_id::TEXT
LEFT JOIN (
  SELECT
    tot_paym_id AS id,
    CONCAT(date, ' ',time_created) AS tot_payment_created_at,
    CASE
      WHEN paid_by_patient IS NOT NULL
        THEN TRUE
      ELSE FALSE
    END paid_by_patient,
    paid_by_patient AS paid_by_patient_id,
    paid_by_third AS paid_by_third_party_id,
    tot_paym_amount AS amount,
    payment_type,
    practice_id,
    convert_to_integer(reverse_id) AS reverse_id,
    discount_category_id,
    ref_number,
    convert_empty_string_to_null(ref_status) AS ref_status
  FROM tot_payment
) AS total_payment
ON payment.total_payment_id = total_payment.id
LEFT JOIN (
  SELECT
    tot_payment_id_from AS original_total_payment_id,
    patient_id,
    amount AS deposit_amount_total,
    allocated AS deposit_used_total,
    provider_id,
    TRUE AS is_deposit
  FROM payment_deposit_to
  WHERE ref_status != 'D'
) AS deposit
ON deposit.original_total_payment_id = payment.total_payment_id
`;

const PATIENT_INVOICE_SOURCE_ESTIMATE_QUERY = `
SELECT
  account.id AS account_id
FROM  (
  SELECT
    id
    FROM patients_accounts
  WHERE ref_status != 'D'
) AS account
INNER JOIN (
  SELECT
    account_payment_plan_id AS id,
    patient_account_id AS account_id
  FROM account_payment_plan
) AS payment_plan
ON payment_plan.account_id = account.id
`;
export class PatientInvoiceSourceEntity extends BaseSourceEntity<
  ID4WPatientInvoice,
  ID4WPatientInvoiceTranslations,
  ID4WPatientInvoiceFilters
> {
  sourceEntity = PATIENT_INVOICE_SOURCE_ENTITY;
  entityResourceType = PATIENT_INVOICE_RESOURCE_TYPE;
  sourceQuery = PATIENT_INVOICE_SOURCE_QUERY;
  allowOffsetJob = true;
  verifySourceFn = isD4WPatientInvoice;
  override defaultOffsetSize = 100000;
  override dateFilterField: keyof ID4WPatientInvoiceFilters = 'createdAt';

  migrationDestinations = [PATIENT_INVOICE_DESTINATION_ENTITY.metadata.key];

  override transformDataFn = flow([transformQueryResults]);

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const response = await runQuery<{ account_id: number }>(
      migration,
      PATIENT_INVOICE_SOURCE_ESTIMATE_QUERY
    );

    const expectedSize = Object.values(
      groupBy(response.rows, (invoice) => invoice.account_id)
    ).length;

    return {
      expectedSize,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }

  translate(
    data: ID4WPatientInvoice,
    timezone: Timezone
  ): ID4WPatientInvoiceTranslations {
    return {
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
      dueAt: data.due_at
        ? toTimestamp(moment.tz(data.due_at, ISO_DATE_TIME_FORMAT, timezone))
        : undefined,
    };
  }

  getSourceRecordId(data: ID4WPatientInvoice): number {
    return data.account_id;
  }

  getSourceLabel(data: ID4WPatientInvoice): string {
    return `${data.account_id}`;
  }

  getFilterData(
    data: ID4WPatientInvoice,
    timezone: Timezone
  ): ID4WPatientInvoiceFilters {
    return {
      accountId: data.account_id.toString(),
      practiceId: data.practice_id.toString(),
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
      referenceDepositPaymentIds: compact(
        data.payments.map(
          (payment) => payment.reference_deposit_payment_id?.toString()
        )
      ),
      depositOriginalTotalPaymentIds: compact(
        data.payments.map(
          (payment) => payment.deposit_original_total_payment_id?.toString()
        )
      ),
      depositPatientIds: compact(
        data.payments.map((payment) => payment.deposit_patient_id?.toString())
      ),
      discountCategoryIds: compact(
        data.payments.map((payment) => payment.discount_category_id?.toString())
      ),
      reverseIds: compact(
        data.payments.map((payment) => payment.reverse_id?.toString())
      ),
      paidByPatientIds: compact(
        data.payments.map((payment) => payment.paid_by_patient_id?.toString())
      ),
      paidByThirdPartyIds: compact(
        data.payments.map(
          (payment) => payment.paid_by_third_party_id?.toString()
        )
      ),
      paymentIds: compact(
        data.payments.map((payment) => payment.payment_id?.toString())
      ),
      totalPaymentIds: compact(
        data.payments.map((payment) => payment.total_payment_id?.toString())
      ),
      paymentPlanIds: data.payments.map(
        (payment) => payment.payment_plan_id?.toString()
      ),
      paymentAllocationIds: compact(
        data.payments.map(
          (payment) => payment.payment_allocation_id?.toString()
        )
      ),
    };
  }
}

function transformQueryResults(
  rows: ID4WPatientInvoiceResult[]
): ID4WPatientInvoice[] {
  const invoiceGroups = groupBy(rows, (invoice) => invoice.account_id);

  return Object.values(invoiceGroups).map((invoiceGroup) => ({
    account_id: invoiceGroup[0].account_id,
    practice_id: invoiceGroup[0].practice_id,
    created_at: invoiceGroup[0].created_at,
    account_total: invoiceGroup[0].account_total,
    account_amount_paid: invoiceGroup[0].account_amount_paid,
    account_rebate: invoiceGroup[0].account_rebate,
    send_to_patient_id: invoiceGroup[0].send_to_patient_id,
    send_to_third_party_id: invoiceGroup[0].send_to_third_party_id,
    due_at: orderBy(
      invoiceGroup.map((invoice) => invoice.due_at),
      'desc'
    )[0],
    payments: invoiceGroup
      .filter((payment) =>
        [
          payment.payment_plan_ref_status,
          payment.payment_allocation_ref_status,
          payment.allocation_details_ref_status,
          payment.payments_ref_status,
          payment.total_payment_ref_status,
        ].every((status) => status !== 'D')
      )
      .map((payment) => ({
        payment_id: payment.payment_id,
        payment_type: payment.payment_type,
        payment_comment: payment.payment_comment,
        payment_allocation_id: payment.payment_allocation_id,
        payment_amount: payment.payment_amount,
        total_payment_amount: payment.total_payment_amount,
        true_payment_amount: payment.true_payment_amount,
        is_payment_from_deposit: payment.is_payment_from_deposit,
        reference_deposit_payment_id: payment.reference_deposit_payment_id,
        is_deposit_payment: payment.is_deposit_payment,
        deposit_original_total_payment_id:
          payment.deposit_original_total_payment_id,
        deposit_patient_id: payment.deposit_patient_id,
        has_transaction: payment.has_transaction,
        transaction_id: payment.transaction_id,
        payment_method: payment.payment_method,
        payment_method_id: payment.payment_method_id,
        tot_payment_created_at: payment.tot_payment_created_at,
        payment_plan_paid_amount: payment.payment_plan_paid_amount,
        installment_number: payment.installment_number,
        installment_amount: payment.installment_amount,
        payment_plan_id: payment.payment_plan_id,
        payment_plan_ref_status: payment.payment_plan_ref_status,
        payment_allocation_ref_status: payment.payment_allocation_ref_status,
        allocation_details_ref_status: payment.allocation_details_ref_status,
        payments_ref_status: payment.payments_ref_status,
        total_payment_ref_status: payment.total_payment_ref_status,
        total_payment_id: payment.total_payment_id,
        discount_category_id: payment.discount_category_id,
        reverse_id: payment.reverse_id,
        paid_by_patient_id: payment.paid_by_patient_id,
        paid_by_third_party_id: payment.paid_by_third_party_id,
      })),
  }));
}
