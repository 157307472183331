import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DateRangeService {
  selectedDayRange$ = new BehaviorSubject<number | undefined>(undefined);

  resetRange(): void {
    this.selectedDayRange$.next(undefined);
  }

  setRange(days: number): void {
    this.selectedDayRange$.next(days);
  }
}
