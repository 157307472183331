import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type ITemplateContextOption } from '@principle-theorem/principle-core/interfaces';

@Component({
    selector: 'pr-template-context-selector-dialog',
    templateUrl: './template-context-selector-dialog.component.html',
    styleUrls: ['./template-context-selector-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TemplateContextSelectorDialogComponent {
  trackByContextOption = TrackByFunctions.index<ITemplateContextOption>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public contextOptions: ITemplateContextOption[],
    public dialogRef: MatDialogRef<TemplateContextSelectorDialogComponent>
  ) {}
}
