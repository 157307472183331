import {
  all$,
  subCollection,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { type CollectionReference } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import {
  type ISourceEntity,
  SourceEntityCollection,
  SourceEntityStatus,
  type ISourceEntityRecord,
} from '@principle-theorem/principle-core/interfaces';

export class SourceEntity {
  static init(overrides: Pick<ISourceEntity, 'metadata'>): ISourceEntity {
    return {
      uid: overrides.metadata.idPrefix,
      status: SourceEntityStatus.Uninitialised,
      ...overrides,
    };
  }

  static recordCol<Filters extends object = object>(
    sourceEntity: IReffable<ISourceEntity>
  ): CollectionReference<ISourceEntityRecord<Filters>> {
    return subCollection<ISourceEntityRecord<Filters>>(
      sourceEntity.ref,
      SourceEntityCollection.Records
    );
  }

  static records$(
    sourceEntity: IReffable<ISourceEntity>
  ): Observable<WithRef<ISourceEntityRecord>[]> {
    return all$(SourceEntity.recordCol(sourceEntity));
  }

  static determineUidForRecord(
    rawDataId: string | number,
    sourceEntity: ISourceEntity
  ): string {
    return [sourceEntity.metadata.idPrefix, String(rawDataId)].join('-');
  }
}
