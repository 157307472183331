<pr-display-colour-bar direction="vertical" [category]="category$ | ngrxPush" />

<div
  class="header-row"
  fxFlex
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="16px"
  *ngIf="eventable$ | ngrxPush as eventable"
>
  <ng-container *ngIf="showPractitionerIcon">
    <ng-container *ngIf="practitioners$ | ngrxPush as practitioners">
      <pt-user-icon
        *ngFor="let practitioner of practitioners; trackBy: trackByPractitioner"
        [diameter]="mediaDiameter"
        [name]="practitioner.user.name"
        [src]="practitioner | map: stafferProfileImage$ : this | async"
      />
    </ng-container>
  </ng-container>

  <pr-eventable-header-template
    fxFlex
    [start]="
      eventable.event.from
        | momentTimezone$: eventable.event.practice.ref
        | ngrxPush
    "
    [end]="
      eventable.event.to
        | momentTimezone$: eventable.event.practice.ref
        | ngrxPush
    "
  >
    <pr-eventable-next-stage
      *ngIf="nextStage$ | ngrxPush as nextStage"
      [nextStage]="nextStage"
    />

    <div prEventableHeaderIcon>
      <pt-user-icon
        [diameter]="mediaDiameter"
        [name]="eventable.metadata.label"
        [src]="patientProfileImage$ | ngrxPush"
      />
    </div>

    <div fxLayout="column">
      <div
        prEventableHeaderTitle
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="16px"
      >
        <h3 class="mat-headline-6">{{ eventable.metadata.label }}</h3>
        <pr-new-patient-icon
          [appointment]="eventable"
          [patient]="{ ref: eventable.metadata.patientRef }"
        />
      </div>

      <div>
        {{ eventable.metadata.treatmentPlanName }} -
        {{ eventable.metadata.treatmentStepName }}
      </div>

      <pr-eventable-tags-preview
        *ngIf="hasTags$ | ngrxPush"
        [eventable]="eventable"
      />
    </div>
  </pr-eventable-header-template>

  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    (click)="$event.preventDefault(); $event.stopPropagation()"
  >
    <button
      *ngIf="accountSummary.outstanding$ | ngrxPush as unpaidInvoiceAmount"
      mat-icon-button
      matTooltip="Outstanding {{ unpaidInvoiceAmount | currency }}"
    >
      <mat-icon class="alert-icon" color="warn">payments</mat-icon>
    </button>
    <button
      *ngIf="hasAppointmentAlerts$ | ngrxPush"
      mat-icon-button
      matTooltip="Appointment not confirmed"
    >
      <mat-icon class="alert-icon" color="warn">warning</mat-icon>
    </button>

    <div fxLayout="row" fxLayoutGap="8px">
      <ng-container *ngIf="canCheckIn$ | ngrxPush">
        <ng-container
          *ngIf="
            checkInRequirementsCount$ | ngrxPush as checkInRequirementsCount;
            else simpleCheckIn
          "
        >
          <button
            mat-stroked-button
            prExpansionDisable
            matTooltip="Check In steps required"
            [matBadge]="checkInRequirementsCount"
            matBadgeColor="primary"
            (click)="openArrival(eventable)"
          >
            Check In
          </button>
        </ng-container>
        <ng-template #simpleCheckIn>
          <button
            mat-stroked-button
            prExpansionDisable
            matTooltip="Check In"
            (click)="checkIn(eventable)"
          >
            Check In
          </button>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="startAppointmentVisible$ | ngrxPush">
        <button
          mat-stroked-button
          prExpansionDisable
          (click)="runAction(startAppointment, eventable)"
        >
          Start
        </button>
      </ng-container>

      <ng-container *ngIf="openAppointmentVisible$ | ngrxPush">
        <button
          mat-stroked-button
          prExpansionDisable
          (click)="runAction(openAppointment, eventable)"
        >
          Open
        </button>
      </ng-container>

      <ng-container *ngIf="canCheckOut$ | ngrxPush">
        <a
          mat-stroked-button
          (click)="routeToCheckOut()"
          prExpansionDisable
          matTooltip="Check Out"
        >
          Check Out
        </a>
      </ng-container>

      <ng-container *ngIf="inProgress$ | ngrxPush">
        <button mat-stroked-button prExpansionDisable disabled>
          Check Out
        </button>
      </ng-container>
    </div>

    <pr-appointment-menu
      prExpansionDisable
      [patient]="patient$ | async"
      [appointment]="eventable"
      (updateEventableStatus)="updateEventableStatus(eventable, $event)"
    />
  </div>
</div>
