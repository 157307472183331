import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  ChartableSurfaceResolver,
  ChartedItemTotalCalculator,
  ChartedSurface,
  ChartedTreatment,
  MultiTreatmentPackage,
  surfaceFromRef,
} from '@principle-theorem/principle-core';
import {
  ChartableSurface,
  combineCompatibleToothSurfaces,
  IPricedServiceCodeGroup,
  type IChartedMultiStepTreatment,
  type IChartedRef,
  type IChartedSurface,
  type IFeeSchedule,
  type IMultiTreatmentConfiguration,
  type IMultiTreatmentPackage,
  type IPricedServiceCodeEntry,
  type ITreatmentConfiguration,
  type ITreatmentStepConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  filterUndefined,
  reduceToSingleArrayFn,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, findIndex, first, sumBy } from 'lodash';
import { combineLatest, type Observable } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { MultiTreatmentBuilder } from './multi-treatment-builder';

export interface IMultiTreatmentSurfaceSelectorData {
  label: string;
  chartable: WithRef<IMultiTreatmentConfiguration>;
  selectedSurfaces: Partial<IChartedRef>[];
}

export interface ITreatmentAndSurface {
  treatment: WithRef<ITreatmentConfiguration>;
  quantity: number;
  pricedServiceCodes: IPricedServiceCodeEntry[];
  serviceCodeGroups: IPricedServiceCodeGroup[];
  price: number;
  chartedSurfaces: IChartedSurface[];
  disabled: boolean;
}

export interface ITreatmentStepWithSurfaces {
  step: ITreatmentStepConfiguration;
  treatments: ITreatmentAndSurface[];
  price: number;
}

export interface ISelectedIndexes {
  stepIndex: number;
  treatmentIndex: number;
}

export interface IMultiTreatmentSelectorState {
  multiTreatmentConfiguration?: WithRef<IMultiTreatmentConfiguration>;
  steps: ITreatmentStepWithSurfaces[];
  price: number;
  selected: ISelectedIndexes[];
  selectedPackage?: IMultiTreatmentPackage;
}

export interface IUpdateTreatment {
  stepIndex: number;
  treatmentIndex: number;
  feeSchedule: WithRef<IFeeSchedule>;
  chartedSurfaces?: IChartedSurface[];
  serviceCode?: Partial<IPricedServiceCodeEntry>;
}

const initialState: IMultiTreatmentSelectorState = {
  steps: [],
  selected: [],
  price: 0,
};

@Injectable()
export class MultiTreatmentSelectorStore extends ComponentStore<IMultiTreatmentSelectorState> {
  private _multiTreatmentBuilder: MultiTreatmentBuilder;
  readonly multiTreatmentConfiguration$ = this.select(
    (store) => store.multiTreatmentConfiguration
  );
  readonly price$ = this.select((store) => store.price);
  readonly steps$ = this.select((store) => store.steps);
  readonly selected$ = this.select((store) => store.selected);
  readonly selectedPackage$ = this.select((store) => store.selectedPackage);
  readonly packages$ = this.select(
    (store) => store.multiTreatmentConfiguration?.packages ?? []
  );
  readonly allTreatmentsCharted$ = this.steps$.pipe(
    map((steps) =>
      steps.every((step) =>
        step.treatments.every(
          (treatment) =>
            treatment.disabled || treatment.chartedSurfaces.length >= 1
        )
      )
    )
  );

  readonly setMultiTreatmentConfiguration = this.effect(
    (config$: Observable<Omit<IMultiTreatmentSurfaceSelectorData, 'label'>>) =>
      config$.pipe(
        concatMap(async (config) => {
          const feeSchedule = await snapshot(
            this._chartStore.getFeeScheduleManager().currentSchedule$
          );
          const steps = await asyncForEach(config.chartable.steps, (step) =>
            this._multiTreatmentBuilder.initialiseTreatmentStepWithSurfaces(
              step,
              config.selectedSurfaces,
              feeSchedule
            )
          );

          const selected = this._getInitialSelection(steps);

          const defaultPackage = config.chartable.packages.find(
            (multiTreatmentPackage) => multiTreatmentPackage.isDefault
          );

          this.patchState((state) => ({
            ...state,
            multiTreatmentConfiguration: config.chartable,
            steps,
            price: sumBy(steps, 'price'),
            selected,
            selectedPackage: defaultPackage,
          }));

          if (!defaultPackage) {
            return;
          }

          this.updateSelectedPackage({
            package: defaultPackage,
            feeSchedule,
          });
        })
      )
  );

  readonly setSelected = this.updater(
    (
      state,
      data: {
        selected: IMultiTreatmentSelectorState['selected'];
        overrideSelected: boolean;
      }
    ) => ({
      ...state,
      selected: data.overrideSelected
        ? data.selected
        : [...state.selected, ...data.selected],
    })
  );

  readonly removeSelected = this.updater(
    (
      state,
      selected: {
        stepIndex: number;
        treatmentIndex: number;
      }
    ) => ({
      ...state,
      selected: state.selected.filter(
        (currentlySelected) =>
          currentlySelected.stepIndex !== selected.stepIndex ||
          currentlySelected.treatmentIndex !== selected.treatmentIndex
      ),
    })
  );

  readonly resetSurfaces = this.updater(
    (
      state,
      selected: {
        stepIndex: number;
        treatmentIndex: number;
      }
    ) => ({
      ...state,
      steps: state.steps.map((step, stepIndex) => {
        if (stepIndex !== selected.stepIndex) {
          return step;
        }
        return {
          ...step,
          treatments: step.treatments.map((treatment, treatmentIndex) =>
            treatmentIndex !== selected.treatmentIndex
              ? treatment
              : {
                  ...treatment,
                  chartedSurfaces: [],
                }
          ),
        };
      }),
    })
  );

  readonly updateSelectedPackage = this.updater(
    (
      state,
      data: {
        package: IMultiTreatmentPackage;
        feeSchedule: WithRef<IFeeSchedule>;
      }
    ) => {
      const steps = this._multiTreatmentBuilder.applyPackage(
        data.package,
        state.steps,
        data.feeSchedule
      );

      return {
        ...state,
        steps,
        price: sumBy(steps, 'price'),
        selectedPackage: data.package,
      };
    }
  );

  readonly updateTreatment = this.updater((state, data: IUpdateTreatment) => {
    const calc = new ChartedItemTotalCalculator();

    const steps = state.steps.map((step, index) => {
      if (index !== data.stepIndex) {
        return step;
      }
      const treatments = step.treatments.map((treatment, treatmentIndex) => {
        if (treatmentIndex !== data.treatmentIndex) {
          return treatment;
        }

        const pricedServiceCodes = this._updateServiceCodes(data, treatment);

        const chartedTreatment = ChartedTreatment.init({
          config: treatment.treatment,
          feeSchedule: data.feeSchedule,
          scopeRef: {
            scope: ChartableSurface.Unscoped,
          },
          serviceCodes: pricedServiceCodes,
        });

        const chartedSurfaces = this._updateChartedSurfaces(data, treatment);
        const treatmentPrice = calc.treatment(chartedTreatment);

        return {
          ...treatment,
          pricedServiceCodes,
          chartedSurfaces,
          price: treatment.disabled
            ? treatmentPrice
            : treatmentPrice * chartedSurfaces.length,
        };
      });

      return {
        ...step,
        treatments,
        price: sumBy(treatments, 'price'),
      };
    });

    return {
      ...state,
      steps,
      price: sumBy(steps, 'price'),
    };
  });

  constructor(private _chartStore: ChartFacade) {
    super(initialState);

    this._multiTreatmentBuilder = new MultiTreatmentBuilder(
      this._chartStore.getFeeScheduleManager(),
      this._chartStore.chartingAs$(ChartId.InAppointment)
    );
  }

  getTreatmentByIndex$(
    stepIndex: number,
    treatmentIndex: number
  ): Observable<ITreatmentAndSurface | undefined> {
    return this.steps$.pipe(
      map((steps) => steps[stepIndex]?.treatments[treatmentIndex])
    );
  }

  isSelectedPackage$(packageUid: string): Observable<boolean> {
    return this.selectedPackage$.pipe(
      map((selectedPackage) => selectedPackage?.uid === packageUid)
    );
  }

  isPackageCompleted$(packageUid: string): Observable<boolean> {
    return combineLatest([
      this.price$,
      this.multiTreatmentConfiguration$,
      this.selectedPackage$,
    ]).pipe(
      switchMap(
        async ([price, multiTreatmentConfiguration, selectedPackage]) => {
          if (
            !multiTreatmentConfiguration ||
            !selectedPackage ||
            selectedPackage.uid !== packageUid
          ) {
            return false;
          }

          const expectedTotal = await MultiTreatmentPackage.total(
            multiTreatmentConfiguration,
            selectedPackage
          );

          return price === expectedTotal;
        }
      )
    );
  }

  async getChartedMultiTreatment(): Promise<IChartedMultiStepTreatment> {
    const config = await snapshot(
      this.multiTreatmentConfiguration$.pipe(filterUndefined())
    );
    const feeSchedule = await snapshot(
      this._chartStore.getFeeScheduleManager().currentSchedule$
    );
    const steps = await snapshot(this.steps$);
    const selectedPackage = await snapshot(this.selectedPackage$);
    return this._multiTreatmentBuilder.getChartedMultiTreatment(
      config,
      steps,
      feeSchedule,
      selectedPackage
    );
  }

  private _updateServiceCodes(
    data: IUpdateTreatment,
    treatment: ITreatmentAndSurface
  ): IPricedServiceCodeEntry[] {
    const serviceCode = data.serviceCode;
    if (!serviceCode) {
      return treatment.pricedServiceCodes;
    }
    return treatment.pricedServiceCodes.map((currentServiceCode) => {
      if (currentServiceCode.uuid !== serviceCode.uuid) {
        return currentServiceCode;
      }
      return {
        ...currentServiceCode,
        ...serviceCode,
      };
    });
  }

  private _updateChartedSurfaces(
    data: IUpdateTreatment,
    treatment: ITreatmentAndSurface
  ): IChartedSurface[] {
    const chartedSurfaces = data.chartedSurfaces;
    if (!chartedSurfaces) {
      return treatment.chartedSurfaces;
    }
    if (!chartedSurfaces.length) {
      return [];
    }
    return ChartableSurfaceResolver.getChartedRefs(
      treatment.treatment,
      chartedSurfaces.map((chartedSurface) => chartedSurface.chartedRef)
    ).map((chartedRef) => {
      return ChartedSurface.init({
        ...chartedSurfaces[0],
        chartedRef,
        uuid: uuid(),
      });
    });
  }

  private _getInitialSelection(
    steps: ITreatmentStepWithSurfaces[]
  ): ISelectedIndexes[] {
    const selected = steps
      .map((step, stepIndex) => {
        return step.treatments.map((treatment, treatmentIndex) => {
          const noSurfaces = !treatment.chartedSurfaces.length;
          const canChart = this._surfacesAreChartable(
            treatment.chartedSurfaces.map((charted) =>
              surfaceFromRef(charted.chartedRef)
            )
          );

          if (noSurfaces || !canChart) {
            return;
          }
          return {
            stepIndex,
            treatmentIndex,
          };
        });
      })
      .map(compact)
      .reduce(reduceToSingleArrayFn, []);

    if (selected.length) {
      return selected;
    }

    return steps.reduce(
      (selection: ISelectedIndexes[], step, index) => [
        ...selection,
        ...this._reduceCompatibleTreatments(step.treatments, index),
      ],
      []
    );
  }

  private _reduceCompatibleTreatments(
    treatments: ITreatmentAndSurface[],
    stepIndex: number
  ): ISelectedIndexes[] {
    return treatments.reduce(
      (selection: ISelectedIndexes[], treatment, treatmentIndex) => {
        if (!selection.length) {
          const canChart = this._surfacesAreChartable(
            treatment.treatment.availableSurfaces
          );
          if (!canChart) {
            return [];
          }
          return [
            {
              stepIndex,
              treatmentIndex,
            },
          ];
        }

        const firstSelection = first(selection);
        let availableSurfaces = firstSelection
          ? treatments[firstSelection.treatmentIndex].treatment
              .availableSurfaces
          : [];

        if (!availableSurfaces.length) {
          return selection;
        }
        availableSurfaces = combineCompatibleToothSurfaces(availableSurfaces);
        const compatibleIndex = findIndex(
          treatments,
          (search) =>
            search.treatment.availableSurfaces.some((surface) =>
              availableSurfaces.includes(surface)
            ),
          treatmentIndex
        );
        if (compatibleIndex === -1) {
          return selection;
        }
        return [
          ...selection,
          {
            stepIndex,
            treatmentIndex: compatibleIndex,
          },
        ];
      },
      []
    );
  }

  private _surfacesAreChartable(surfaces: ChartableSurface[]): boolean {
    return (
      surfaces.length >= 1 &&
      surfaces.every(
        (surface) =>
          surface !== ChartableSurface.WholeMouth &&
          surface !== ChartableSurface.Unscoped
      )
    );
  }
}
