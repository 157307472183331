<h2 *ngIf="chartCard.title">{{ chartCard.title }}</h2>
<div class="mat-elevation-z1" fxLayout="column">
  <pr-chart [chartBuilder]="chartCard.chartBuilder" />

  <div
    class="report-card-footer mat-elevation-z1"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <mat-select
      fxFlex="15"
      placeholder="Report Range"
      [(value)]="selectedRange.value"
    >
      <mat-option
        *ngFor="let range of ranges; trackBy: trackByRange"
        [value]="range.value"
      >
        {{ range.title }}
      </mat-option>
    </mat-select>
  </div>
</div>
