import {
  IAppointment,
  IBrand,
  IOrganisation,
  IPatient,
  IPatientConfirmAppointmentTokenData,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference } from '@principle-theorem/shared';
import {
  getDoc,
  getParentDocRef,
  toMomentTz,
  WithRef,
} from '@principle-theorem/shared';
import { createCSRF } from '@principle-theorem/temporary-tokens';
import * as moment from 'moment-timezone';
import { TimezoneResolver } from '../../timezone';
import { Organisation } from '../organisation/organisation';

export async function generatePatientConfirmToken(
  org: WithRef<IOrganisation>,
  appointment: WithRef<IAppointment>
): Promise<string> {
  const tokenExpiry = await getTokenExpiryFromAppointment(appointment);
  const tokenData: IPatientConfirmAppointmentTokenData = {
    appointment: appointment.ref,
  };
  return createCSRF(
    Organisation.temporaryTokenCol(org),
    tokenData,
    tokenExpiry,
    true
  );
}

export async function generateSelfConfirmUrl(
  patientRef: DocumentReference<IPatient>,
  appointment: WithRef<IAppointment>,
  appUrl: string
): Promise<string> {
  const brandRef = getParentDocRef<IBrand>(patientRef.path);
  const orgRef = getParentDocRef<IOrganisation>(brandRef.path);
  const token = await generatePatientConfirmToken(
    await getDoc(orgRef),
    appointment
  );

  return [appUrl, 'confirm', token].join('/');
}

export async function getTokenExpiryFromAppointment(
  appointment: WithRef<IAppointment> | undefined
): Promise<moment.Moment> {
  if (!appointment || !appointment.event) {
    return moment().add(5, 'day');
  }
  const timezone = await TimezoneResolver.fromEvent(appointment.event);
  return toMomentTz(appointment.event.from, timezone).add(1, 'day');
}
