<ng-container *ngIf="treatmentConfig$ | async as treatmentConfig">
  <div class="flex flex-col">
    <div class="flex">
      <div class="search-box flex-1">
        <mat-form-field
          class="no-style label-wrapper-prefix box-border flex h-full min-h-full w-full flex-col no-underline"
        >
          <mat-icon matIconPrefix>add</mat-icon>
          <mat-label>Condition</mat-label>
          <input
            matInput
            placeholder="Add a Condition to resolve"
            [matAutocomplete]="autoConditions"
            [formControl]="conditionTypeSelector"
            #autoComplete="matAutocompleteTrigger"
            (click)="autoComplete.openPanel()"
          />
          <mat-autocomplete
            #autoConditions="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="add($event.option.value, treatmentConfig)"
          >
            <ng-container *ngIf="searchFilter.results$ | async as conditions">
              <mat-option
                *ngFor="let condition of conditions; trackBy: trackByCondition"
                [value]="condition"
              >
                <mat-icon
                  matIconPrefix
                  [ngStyle]="{ color: condition.display.colour.value }"
                  [svgIcon]="condition.display.icon.slug"
                 />
                {{ condition.name }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <ng-container *ngIf="conditionsResolvedByTreatment$ | async as conditions">
      <mat-list class="list" *ngIf="conditions.length">
        <mat-list-item
          *ngFor="let condition of conditions; trackBy: trackByCondition"
          class="!h-14 !py-2"
        >
          <div class="flex flex-1 items-center">
            <mat-icon
              class="icon"
              [ngStyle]="{ color: condition.display.colour.value }"
              [svgIcon]="condition.display.icon.slug"
             />
            <div class="items flex flex-1 flex-col">
              <span>{{ condition.name }}</span>
              <span class="mat-caption">
                <ng-container
                  *ngFor="
                    let surfaces of condition.availableSurfaces;
                    last as isLast;
                    trackBy: trackBySurface
                  "
                >
                  {{ surfaces | splitCamel | titlecase
                  }}<ng-container *ngIf="!isLast">,&nbsp;</ng-container>
                </ng-container>
              </span>
            </div>
            <button mat-icon-button (click)="editCondition(condition)">
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              color="warn"
              (click)="deleteCondition(condition, treatmentConfig)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-list-item>
      </mat-list>
    </ng-container>
  </div>
</ng-container>
