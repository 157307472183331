import { Injectable } from '@angular/core';
import {
  type IContextualAction,
  type IRoutable,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { Invoice } from '@principle-theorem/principle-core';
import {
  type IInvoice,
  isPrincipleMention,
} from '@principle-theorem/principle-core/interfaces';
import { type IReffable } from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';

@Injectable({
  providedIn: 'root',
})
export class LoadInvoiceActionService implements IContextualAction {
  icon = 'open_in_new';
  name = 'Open Invoice';

  constructor(private _stateNav: StateBasedNavigationService) {}

  async do(item: IRoutable | IReffable): Promise<void> {
    const invoiceRef = this._getInvoiceRef(item);
    if (!invoiceRef) {
      return;
    }
    const patientRef = Invoice.patientDocRef({ ref: invoiceRef });
    await this._stateNav.brand([
      'patients',
      patientRef.id,
      'account',
      'invoices',
      invoiceRef.id,
    ]);
  }

  private _getInvoiceRef(
    item: IRoutable | IReffable
  ): DocumentReference<IInvoice> | undefined {
    if (isPrincipleMention(item)) {
      return item.resource.ref as DocumentReference<IInvoice>;
    }
    return item.ref ? (item.ref as DocumentReference<IInvoice>) : undefined;
  }
}
