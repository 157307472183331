import {
  Invoice,
  LineItemActions,
  TransactionAllocation,
} from '@principle-theorem/principle-core';
import {
  IInvoice,
  INamedAllocationTarget,
  IPatient,
  IStaffer,
  ITransaction,
  ITransactionAllocation,
  TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import {
  INamedDocument,
  WithRef,
  customGroupBy,
  isSameRef,
} from '@principle-theorem/shared';
import { sum } from 'lodash';
import {
  IAccountCreditReportRecord,
  IDepostPaymentBreakdown,
} from './practitioner-income-report';

export interface IPractitionerIncomeReportGrouping {
  practitioner: INamedAllocationTarget;
  transactions: IPractitionerTransactionGrouping;
  accountCredits: IPractitionerAccountCreditGrouping;
}

export interface IPractitionerTransactionGrouping {
  practitioner: INamedAllocationTarget;
  records: IPractitionerTransactionReportRecord[];
  total: Pick<
    IPractitionerTransactionSummary,
    | 'accountCreditAmount'
    | 'discountAmount'
    | 'paymentAmount'
    | 'practitionerProportionAmount'
    | 'isComplexInvoice'
  >;
}

export interface IPractitionerAccountCreditGrouping {
  records: IAccountCreditReportRecord[];
  total: {
    reservedAccountCreditAmount: number;
  };
}

export interface IPractitionerTransactionReportRecord {
  patient: INamedDocument<IPatient>;
  practitioner: INamedAllocationTarget;
  invoice: WithRef<IInvoice>;
  transaction: WithRef<ITransaction>;
  accountCreditPaymentTransactions: IAccountCreditPaymentTransactionRecord[];
  summary: IPractitionerTransactionSummary;
}

export interface IAccountCreditPaymentTransactionRecord {
  payment: IDepostPaymentBreakdown;
  practitionerAmount: number;
}

export interface IPractitionerTransactionSummary {
  invoiceAmount: number;
  depositAmount: number;
  productAmount: number;
  feeAmount: number;
  treatmentAmounts: {
    practitioner: INamedDocument<IStaffer>;
    amount: number;
  }[];
  accountCreditAmount: number;
  discountAmount: number;
  paymentAmount: number;
  practitionerProportionAmount: number;
  isComplexInvoice: boolean;
  practitionerProportionRatio: number;
  allocation?: ITransactionAllocation;
}

export class PractitionerTransactionSummary {
  static reduce(
    summaries: IPractitionerTransactionSummary[]
  ): IPractitionerTransactionGrouping['total'] {
    return summaries.reduce(
      (acc: IPractitionerTransactionGrouping['total'], summary) => ({
        accountCreditAmount:
          acc.accountCreditAmount + summary.accountCreditAmount,
        discountAmount: acc.discountAmount + summary.discountAmount,
        paymentAmount: acc.paymentAmount + summary.paymentAmount,
        practitionerProportionAmount:
          acc.practitionerProportionAmount +
          summary.practitionerProportionAmount,
        isComplexInvoice:
          acc.isComplexInvoice || summary.isComplexInvoice ? true : false,
      }),
      {
        accountCreditAmount: 0,
        discountAmount: 0,
        paymentAmount: 0,
        practitionerProportionAmount: 0,
        isComplexInvoice: false,
      }
    );
  }
}
export class PractitionerAccountCreditSummary {
  static reduce(
    summaries: IAccountCreditReportRecord[]
  ): IPractitionerAccountCreditGrouping['total'] {
    return summaries.reduce(
      (acc: IPractitionerAccountCreditGrouping['total'], summary) => ({
        reservedAccountCreditAmount:
          acc.reservedAccountCreditAmount + summary.accountCredit.amount,
      }),
      {
        reservedAccountCreditAmount: 0,
      }
    );
  }
}

type AmountClassification = 'accountCredit' | 'discount' | 'claim' | 'payment';

export function createRecordSummary(
  invoice: WithRef<IInvoice>,
  transaction: WithRef<ITransaction>,
  practitioner: INamedAllocationTarget
): IPractitionerTransactionSummary {
  const summary: IPractitionerTransactionSummary = {
    invoiceAmount: 0,
    depositAmount: 0,
    productAmount: 0,
    feeAmount: 0,
    treatmentAmounts: [],
    accountCreditAmount: 0,
    discountAmount: 0,
    paymentAmount: 0,
    practitionerProportionAmount: 0,
    isComplexInvoice: false,
    practitionerProportionRatio: 0,
  };

  const providerMap: Record<TransactionProvider, AmountClassification> = {
    [TransactionProvider.Stripe]: 'payment',
    [TransactionProvider.Cash]: 'payment',
    [TransactionProvider.Manual]: 'payment',
    [TransactionProvider.Discount]: 'discount',
    [TransactionProvider.AccountCredit]: 'accountCredit',
    [TransactionProvider.AccountCreditTransfer]: 'accountCredit',
    [TransactionProvider.PaymentPlan]: 'payment',
    [TransactionProvider.TyroEftpos]: 'payment',
    [TransactionProvider.TyroHealthPoint]: 'claim',
    [TransactionProvider.TyroEasyClaimBulkBill]: 'claim',
    [TransactionProvider.TyroEasyClaimPartPaid]: 'claim',
    [TransactionProvider.TyroEasyClaimFullyPaid]: 'claim',
    [TransactionProvider.MedipassHicaps]: 'claim',
    [TransactionProvider.MedipassMedicare]: 'claim',
    [TransactionProvider.MedipassDVA]: 'claim',
    [TransactionProvider.MedipassPatientFunded]: 'payment',
    [TransactionProvider.MedipassVirtualTerminal]: 'payment',
    [TransactionProvider.MedipassGapPayment]: 'payment',
    [TransactionProvider.HicapsConnectEftpos]: 'payment',
    [TransactionProvider.HicapsConnectHealthFund]: 'claim',
    [TransactionProvider.HicapsConnectMedicare]: 'claim',
    [TransactionProvider.SmartpayCard]: 'payment',
    [TransactionProvider.SmartpayQR]: 'payment',
  };

  summary.invoiceAmount = Invoice.total(invoice);
  summary.depositAmount = LineItemActions.sum(Invoice.deposits(invoice));
  summary.productAmount = LineItemActions.sum(Invoice.products(invoice));
  summary.feeAmount = LineItemActions.sum(Invoice.fees(invoice));

  summary.treatmentAmounts = customGroupBy(
    Invoice.treatments(invoice),
    (treatment) => treatment.treatmentRef.attributedTo,
    isSameRef
  ).map((group) => ({
    practitioner: group.group,
    amount: LineItemActions.sum(group.items),
  }));

  const hasMultiplePractitioners = summary.treatmentAmounts.length > 1;
  const invoiceAmountIsDifferentToTreatmentAmount =
    summary.invoiceAmount !==
    sum(summary.treatmentAmounts.map((item) => item.amount));

  summary.isComplexInvoice =
    hasMultiplePractitioners || invoiceAmountIsDifferentToTreatmentAmount;

  const transactionAllocations = Invoice.findTransactionAllocations(
    invoice,
    transaction.ref
  );
  summary.allocation = transactionAllocations.find((allocation) =>
    TransactionAllocation.isAllocatedTo(allocation, practitioner.ref)
  );
  summary.practitionerProportionRatio =
    summary.allocation?.allocatedProportion ?? 0;
  const allocatedAmount = summary.allocation?.allocatedAmount ?? 0;

  switch (providerMap[transaction.provider]) {
    case 'payment':
      summary.paymentAmount = allocatedAmount;
      break;
    case 'accountCredit':
      summary.accountCreditAmount = allocatedAmount;
      break;
    case 'claim':
      summary.paymentAmount = allocatedAmount;
      break;
    case 'discount':
      summary.discountAmount = allocatedAmount;
      break;
    default:
      break;
  }

  summary.practitionerProportionAmount =
    summary.accountCreditAmount + summary.paymentAmount;

  return summary;
}
