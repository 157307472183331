import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  ViewChild,
  inject,
} from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import {
  VersionedSchema,
  initVersionedSchema,
} from '@principle-theorem/editor';
import {
  ContentEditorComponent,
  EditorPresetsService,
  PrincipleEditorModule,
} from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  TypedFormControl,
  TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import {
  CustomFormContentElement,
  ICustomFormContentOptions,
} from '@principle-theorem/principle-core/interfaces';
import { debounceUserInput, firstValueFrom } from '@principle-theorem/shared';
import { ReplaySubject, Subject } from 'rxjs';
import { distinctUntilKeyChanged, takeUntil } from 'rxjs/operators';
import { FormBuilderService } from '../../form-builder.service';

@Component({
    selector: 'pr-form-builder-content-options',
    templateUrl: './form-builder-content-options.component.html',
    styleUrl: './form-builder-content-options.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgMaterialModule,
        PrincipleEditorModule,
    ]
})
export class FormBuilderContentOptionsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _editorPresets = inject(EditorPresetsService);
  private _formBuilder = inject(FormBuilderService);
  element$ = new ReplaySubject<CustomFormContentElement>(1);
  extensions = this._editorPresets.defaultToHTMLExtensions();
  form = new TypedFormGroup<ICustomFormContentOptions>({
    content: new TypedFormControl<VersionedSchema>(initVersionedSchema(), [
      Validators.required,
    ]),
    editableOnIssue: new TypedFormControl<boolean>(false),
  });

  @ViewChild(ContentEditorComponent, { static: true })
  contentEditor: ContentEditorComponent;

  @Input()
  set element(element: CustomFormContentElement) {
    this.element$.next(element);
  }

  constructor() {
    this.element$
      .pipe(distinctUntilKeyChanged('uid'), takeUntil(this._onDestroy$))
      .subscribe((element) => {
        this.form.patchValue(element.options, { emitEvent: false });
        this._focus();
      });
    validFormGroupChanges$(this.form)
      .pipe(debounceUserInput(), takeUntil(this._onDestroy$))
      .subscribe((options) => void this._updateOptions(options));
  }

  ngOnDestroy(): void {
    if (this.form.valid) {
      void this._updateOptions(this.form.value);
    }
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private async _updateOptions(
    options: ICustomFormContentOptions
  ): Promise<void> {
    const element = await firstValueFrom(this.element$);
    if (element) {
      this._formBuilder.updateElement({ ...element, options });
    }
  }

  private _focus(): void {
    this.contentEditor.focus();
  }
}
