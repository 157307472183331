<div
  fxLayout="row wrap"
  fxLayoutAlign="start center"
  fxLayoutGap="16px"
  class="layout-margin"
>
  <pt-date-range-form
    [useRangePicker]="true"
    [from]="from"
    [to]="to"
    (changed)="updateDateRange($event)"
  />

  <pr-practice-selector
    subscriptSizing="dynamic"
    [brands]="brands$ | async"
    [practice]="currentPractice$ | async"
    (practiceSelected)="reportFacade.setPractice($event)"
  />
</div>
