import { Organisation } from '@principle-theorem/principle-core';
import {
  type IUser,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
  CustomMappingType,
} from '@principle-theorem/principle-core/interfaces';
import {
  all$,
  type INamedDocument,
  type IReffable,
  multiMap,
  multiSortBy$,
  nameSorter,
  snapshot,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import { StafferSourceEntity } from '../../source/entities/staff';

export const STAFF_TO_USER_CUSTOM_MAPPING_TYPE = 'staffToUserCustomMapping';

export const STAFF_TO_USER_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Multi-staffer to User',
    description: `Used for mapping multiple of the same staff member to the respective user in Principle. This can happen in multi-practice environments where the practitioner is practicing in multiple practices.

    The Source Identifier is the staff id, the Destination Identifier is the DocumentReference path for the user.`,
    type: STAFF_TO_USER_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.DocumentReference,
});

export class PraktikaStaffToUserMappingHandler extends BaseCustomMappingHandler<IUser> {
  customMapping = STAFF_TO_USER_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const entity = new StafferSourceEntity();
    const records = await entity.getRecords$(migration, 1000).toPromise();
    return sortBy(
      records
        .map((record) => record.data.data)
        .map((record) => ({
          label: `${record.first_name} ${record.last_name} ${
            record.provider_number ?? ''
          }`,
          value: entity.getSourceRecordId(record).toString(),
        })),
      'label'
    );
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument[]> {
    return snapshot(
      all$(Organisation.userCol(migration.configuration.organisation)).pipe(
        multiMap(toNamedDocument),
        multiSortBy$(nameSorter())
      )
    );
  }
}
