import { type IXSLXImport } from '@principle-theorem/shared';
import type { Row } from 'exceljs';
import {
  IPatientNoteCategoryToNoteXSLX,
  PATIENT_NOTE_CATEGORY_TO_NOTE_HEADERS,
} from './patient-note-categories-to-notes-to-xlsx';

export class XSLXToPatientNoteCategoriesToNotes
  implements IXSLXImport<IPatientNoteCategoryToNoteXSLX>
{
  headers = PATIENT_NOTE_CATEGORY_TO_NOTE_HEADERS;

  translate(row: Row): IPatientNoteCategoryToNoteXSLX {
    return {
      sourceId: row.getCell('sourceId').value?.toString() ?? '',
      sourceName: row.getCell('sourceName').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IPatientNoteCategoryToNoteXSLX['mapTo'],
    };
  }
}
