import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { Invoice } from '@principle-theorem/principle-core';
import {
  type IAccountCredit,
  type IInvoice,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot, type WithRef } from '@principle-theorem/shared';
import { isBoolean } from 'lodash';
import { map } from 'rxjs/operators';
import {
  type IRefundDepositPromptData,
  RefundDepositPromptComponent,
} from './refund-deposit-prompt.component';

@Injectable()
export class RefundDepositPromptService {
  constructor(private _dialog: MatDialog) {}

  async showRefundDepositAlert(invoice: WithRef<IInvoice>): Promise<boolean> {
    if (!Invoice.isPaid(invoice)) {
      return true;
    }
    const credits = await snapshot(Invoice.getExistingAccountCredits$(invoice));
    if (!credits.length) {
      return true;
    }
    return this._showPrompt(invoice, credits);
  }

  private _showPrompt(
    invoice: WithRef<IInvoice>,
    credits: WithRef<IAccountCredit>[]
  ): Promise<boolean> {
    const data: IRefundDepositPromptData = { invoice, credits };
    return this._dialog
      .open<
        RefundDepositPromptComponent,
        IRefundDepositPromptData,
        boolean | undefined
      >(RefundDepositPromptComponent, DialogPresets.medium({ data }))
      .afterClosed()
      .pipe(map((response) => (isBoolean(response) ? response : false)))
      .toPromise();
  }
}
