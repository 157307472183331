import { IBaseMigrationStaffer } from '../../../interfaces';
import { StafferMappingHandler } from '../../../mappings/staff';
import {
  IPraktikaStaffer,
  StafferSourceEntity,
} from '../../source/entities/staff';

export class PraktikaStafferMappingHandler extends StafferMappingHandler<
  IPraktikaStaffer,
  StafferSourceEntity
> {
  override sourceEntity = new StafferSourceEntity();
  translateFn = (record: IPraktikaStaffer): IBaseMigrationStaffer => ({
    id: record.id,
    name: `${record.first_name} ${record.last_name}`,
    providerNo: record.provider_number,
  });
}
