import {
  IExpectedSourceRecordSize,
  SourceEntityMigrationType,
  type IPracticeMigration,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  isObject,
  toTimestamp,
  type Timestamp,
  type Timezone,
  type WithRef,
} from '@principle-theorem/shared';
import { flow, groupBy, isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { runQuery } from '../../../source/connection';
import { SourceEntity } from '../../../source/source-entity';
import { OFFSET_PLACEHOLDER } from '../../../source/source-helpers';

export const PATIENT_PERIO_CHART_RESOURCE_TYPE = 'patientPerioChart';

export const PATIENT_PERIO_CHART_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Perio Chart List',
      description: '',
      idPrefix: PATIENT_PERIO_CHART_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ID4WPatientPerioChartResult {
  id: number;
  patient_id: string;
  provider_id: number;
  created_at: string;
  tooth_row_index_number: number;
  is_upper_jaw: boolean;
  is_facial: boolean;
  sub_column_number: number;
  row_number: number;
  p_value: number;
}

export interface ID4WPatientPerioChart {
  id: number;
  patient_id: string;
  provider_id: number;
  created_at: string;
  records: ID4WPatientPerioChartRecord[];
}

function isD4WPatientPerioChartItem(
  item: unknown
): item is ID4WPatientPerioChart {
  return (
    isObject(item) &&
    isNumber(item.id) &&
    isNumber(item.patient_id) &&
    isNumber(item.provider_id) &&
    isString(item.created_at) &&
    TypeGuard.arrayOf(isD4WPatientPerioChartRecordItem)(item.records)
  );
}

export interface ID4WPatientPerioChartRecord {
  tooth_row_index_number: number;
  is_upper_jaw: boolean;
  is_facial: boolean;
  sub_column_number: number;
  row_number: number;
  p_value: number;
}

function isD4WPatientPerioChartRecordItem(
  item: unknown
): item is ID4WPatientPerioChartRecord {
  return (
    isObject(item) &&
    isNumber(item.tooth_row_index_number) &&
    isBoolean(item.is_upper_jaw) &&
    isBoolean(item.is_facial) &&
    isNumber(item.sub_column_number) &&
    isNumber(item.row_number) &&
    isNumber(item.p_value)
  );
}

export interface ID4WPatientPerioChartTranslations {
  createdAt: Timestamp;
}

export interface ID4WPatientPerioChartFilters {
  createdAt: Timestamp;
  patientId: string;
  providerId: string;
}

const PATIENT_PERIO_CHART_QUERY = `
SELECT * FROM (
  SELECT
    perio_charting_id AS id,
    patient_id,
    date_time as created_at,
    doctor_id AS provider_id
  FROM perio_charting_one
  WHERE ref_status != 'D'
  ORDER BY perio_charting_id
  ${OFFSET_PLACEHOLDER}
) AS chart
INNER JOIN (
  SELECT
    perio_charting_id,
    tooth_no AS tooth_row_index_number,
    convert_to_boolean(upper_or_low_jaw) AS is_upper_jaw,
    convert_to_boolean(face_or_lingual) AS is_facial,
    sub_column_n AS sub_column_number,
    row_n AS row_number,
    p_value
  FROM perio_charting_many
) AS chart_records
ON chart.id = chart_records.perio_charting_id
ORDER BY id
`;

const PATIENT_PERIO_CHART_ESTIMATE_QUERY = `
SELECT * FROM (
  SELECT
    perio_charting_id AS id
  FROM perio_charting_one
  WHERE ref_status != 'D'
) AS chart
INNER JOIN (
  SELECT
    perio_charting_id
  FROM perio_charting_many
) AS chart_records
ON chart.id = chart_records.perio_charting_id
`;

export class PatientPerioChartSourceEntity extends BaseSourceEntity<
  ID4WPatientPerioChart,
  ID4WPatientPerioChartTranslations,
  ID4WPatientPerioChartFilters
> {
  sourceEntity = PATIENT_PERIO_CHART_SOURCE_ENTITY;
  entityResourceType = PATIENT_PERIO_CHART_RESOURCE_TYPE;
  sourceQuery = PATIENT_PERIO_CHART_QUERY;
  verifySourceFn = isD4WPatientPerioChartItem;
  override transformDataFn = flow([transformPerioResults]);

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const response = await runQuery<ID4WPatientPerioChart>(
      migration,
      PATIENT_PERIO_CHART_ESTIMATE_QUERY
    );

    const expectedSize = Object.values(
      groupBy(response.rows, (result) => result.id)
    ).length;

    return {
      expectedSize,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }

  translate(
    data: ID4WPatientPerioChart,
    timezone: Timezone
  ): ID4WPatientPerioChartTranslations {
    return {
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getFilterData(
    data: ID4WPatientPerioChart,
    timezone: Timezone
  ): ID4WPatientPerioChartFilters {
    return {
      patientId: data.patient_id.toString(),
      providerId: data.provider_id.toString(),
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getSourceRecordId(data: ID4WPatientPerioChart): number {
    return data.id;
  }

  getSourceLabel(data: ID4WPatientPerioChart): string {
    return `${data.id} ${data.patient_id}`;
  }
}

function transformPerioResults(
  rows: ID4WPatientPerioChartResult[]
): ID4WPatientPerioChart[] {
  const perioChartGroups = groupBy(rows, (result) => result.id);
  return Object.values(perioChartGroups).map((records) => ({
    id: records[0].id,
    patient_id: records[0].patient_id,
    provider_id: records[0].provider_id,
    created_at: records[0].created_at,
    records: records.map((item) => ({
      tooth_row_index_number: item.tooth_row_index_number,
      is_upper_jaw: item.is_upper_jaw,
      is_facial: item.is_facial,
      sub_column_number: item.sub_column_number,
      row_number: item.row_number,
      p_value: item.p_value,
    })),
  }));
}
