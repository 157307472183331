<ng-container *ngIf="form$ | async as form">
  <form [formGroup]="form" fxLayout="row" fxLayoutGap="8px">
    <ng-container *ngIf="rangeEnabled$ | async">
      <div class="unit-row-label" fxLayout="row" fxLayoutAlign="start center">
        Units {{ form.controls.startUnit.value }}
        <ng-container *ngIf="form.controls.endUnit.value"> to </ng-container>
      </div>

      <mat-form-field floatLabel="always" subscriptSizing="dynamic">
        <mat-label>End Unit</mat-label>
        <input
          matInput
          formControlName="endUnit"
          type="number"
          placeholder="& above"
          autocomplete="off"
          [min]="min$ | async"
          [disabled]="disabled$ | async"
        />
      </mat-form-field>
    </ng-container>

    <mat-form-field subscriptSizing="dynamic">
      <span matTextPrefix>$&nbsp;</span>
      <input
        matInput
        type="number"
        placeholder="{{ (defaultRuleItem$ | async)?.price ?? 0 }}"
        formControlName="price"
        min="0"
        autocomplete="off"
      />
    </mat-form-field>
  </form>
</ng-container>
