import { Injectable } from '@angular/core';
import { Invoice } from '@principle-theorem/principle-core';
import {
  type IHealthcareClaim,
  type IInvoice,
  type ITransaction,
  TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import { Region, type DocumentReference } from '@principle-theorem/shared';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable, of } from 'rxjs';
import { TransactionAmountDialog } from '../transaction-components/transaction-amount-dialog/transaction-amount-dialog.service';
import {
  type ITransactionProvider,
  TransactionProviderType,
} from '../transaction-provider';
import { MedipassProviderService } from './medipass-provider.service';

@Injectable()
export class MedipassVirtualTerminalTransactionProvider
  implements ITransactionProvider
{
  providerId = TransactionProvider.MedipassVirtualTerminal;
  providerType = TransactionProviderType.Payment;
  providerRegions = [Region.Australia];
  isEnabled$: Observable<boolean>;

  constructor(
    private _medipassProvider: MedipassProviderService,
    private _amountDialog: TransactionAmountDialog
  ) {
    this.isEnabled$ = this._medipassProvider.isEnabled$;
  }

  canCapture$(
    invoice: WithRef<IInvoice>,
    _claim?: IHealthcareClaim
  ): Observable<boolean> {
    const canCapture =
      !Invoice.isPaid(invoice) && Invoice.canAddTransactions(invoice);
    return of(canCapture);
  }

  async capture(
    invoice: WithRef<IInvoice>,
    claim?: IHealthcareClaim
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const formData = await this._amountDialog.openForInvoice(
      'Medipass - Virtual Terminal',
      invoice
    );
    if (!formData || formData.amount <= 0) {
      return;
    }
    return this._medipassProvider.openVirtualTerminal(invoice, formData, claim);
  }
}
