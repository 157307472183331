import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { type IIntegration } from '@principle-theorem/integrations';
import { type CollectionReference } from '@principle-theorem/shared';
import {
  firebaseFunctionUrl,
  FUNCTIONS_REGION,
  shareReplayCold,
  snapshot,
  toTimestamp,
} from '@principle-theorem/shared';
import {
  createSlackLink,
  type ISlackIntegrationData,
  SlackIntegrationStorage,
} from '@principle-theorem/slack-integration';
import {
  type ITemporaryOrgToken,
  TemporaryOrgTokenStorage,
} from '@principle-theorem/temporary-tokens';
import * as moment from 'moment-timezone';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  type ISlackIntegrationConfig,
  NG_SLACK_INTEGRATION_CONFIG,
} from '../config';

@Component({
    selector: 'pt-manage-slack-integration',
    templateUrl: './manage-slack-integration.component.html',
    styleUrls: ['./manage-slack-integration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ManageSlackIntegrationComponent {
  private _temporaryTokensCol$: ReplaySubject<
    CollectionReference<ITemporaryOrgToken>
  > = new ReplaySubject(1);
  private _integrationsCol$: ReplaySubject<
    CollectionReference<IIntegration<ISlackIntegrationData>>
  > = new ReplaySubject(1);
  private _orgUid$: ReplaySubject<string> = new ReplaySubject(1);
  slackStorage: SlackIntegrationStorage = new SlackIntegrationStorage();
  tokenStorage: TemporaryOrgTokenStorage = new TemporaryOrgTokenStorage();
  slackLink$: Observable<string>;
  enabled$: Observable<boolean>;

  constructor(
    @Inject(NG_SLACK_INTEGRATION_CONFIG) config: ISlackIntegrationConfig
  ) {
    const scopes: string[] = ['incoming-webhook', 'bot'];
    const oauthApiUrl: string = firebaseFunctionUrl(
      FUNCTIONS_REGION,
      config.projectId,
      'http-slack-oauth'
    );
    const temporaryTokenUid$: Observable<string> = combineLatest([
      this._orgUid$,
      this._temporaryTokensCol$,
    ]).pipe(
      switchMap(([orgUid, temporaryTokenCol]) =>
        this.tokenStorage.generate(
          {
            orgUid,
            expiresAt: toTimestamp(moment().add('10', 'minutes')),
            deleted: false,
          },
          temporaryTokenCol
        )
      )
    );

    this.slackLink$ = temporaryTokenUid$.pipe(
      map((temporaryTokenUid: string) =>
        createSlackLink({
          scopes,
          oauthApiUrl,
          clientId: config.slack.clientId,
          temporaryTokenUid,
        })
      ),
      shareReplayCold()
    );

    this.enabled$ = this._integrationsCol$.pipe(
      switchMap((integrationsCol) => this.slackStorage.has$(integrationsCol))
    );
  }

  @Input()
  set integrationsCol(
    integrationsCol: CollectionReference<IIntegration<ISlackIntegrationData>>
  ) {
    if (!integrationsCol) {
      return;
    }
    this._integrationsCol$.next(integrationsCol);
  }

  @Input()
  set temporaryTokensCol(
    temporaryTokensCol: CollectionReference<ITemporaryOrgToken>
  ) {
    if (!temporaryTokensCol) {
      return;
    }
    this._temporaryTokensCol$.next(temporaryTokensCol);
  }

  @Input()
  set orgUid(orgUid: string) {
    if (!orgUid) {
      return;
    }
    this._orgUid$.next(orgUid);
  }

  async removeIntegration(): Promise<void> {
    const integrationsCol = await snapshot(this._integrationsCol$);
    await this.slackStorage.remove(integrationsCol);
  }
}
