import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { type WithRef } from '@principle-theorem/shared';
import {
  type IPatient,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { Validators } from '@angular/forms';

export interface IEditSterilisationRecordData {
  data: string;
  practice: WithRef<IPractice>;
  patient?: WithRef<IPatient>;
  scannedBy?: WithRef<IStaffer>;
}

export type IEditSterilisationRecordReturnData = Omit<
  IEditSterilisationRecordData,
  'practice'
>;

@Component({
  selector: 'pr-edit-sterilisation-record-dialog',
  templateUrl: './edit-sterilisation-record-dialog.component.html',
  styleUrls: ['./edit-sterilisation-record-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSterilisationRecordDialogComponent {
  form = new TypedFormGroup<IEditSterilisationRecordReturnData>({
    data: new TypedFormControl<string>('', Validators.required),
    patient: new TypedFormControl<WithRef<IPatient>>(),
    scannedBy: new TypedFormControl<WithRef<IStaffer>>(),
  });
  practice: WithRef<IPractice>;
  constructor(
    private _dialogRef: MatDialogRef<
      EditSterilisationRecordDialogComponent,
      IEditSterilisationRecordReturnData
    >,
    @Inject(MAT_DIALOG_DATA) record: IEditSterilisationRecordData
  ) {
    if (!record) {
      return;
    }
    this.practice = record.practice;
    this.form.patchValue({
      data: record.data,
      patient: record.patient,
      scannedBy: record.scannedBy,
    });
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }
    const formData = this.form.getRawValue();
    this._dialogRef.close(formData);
  }
}
