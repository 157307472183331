<div
  class="action-list-button flex w-full flex-row items-center justify-start gap-2 p-2"
  [ngClass]="{
    'cursor-pointer hover:bg-zinc-100': !isDisabled,
    'disabled cursor-not-allowed opacity-50': isDisabled
  }"
  [matTooltipDisabled]="!tooltip"
  [matTooltip]="tooltip"
  matRipple
  [matRippleDisabled]="isDisabled"
  (click)="handleClick($event)"
>
  <img
    *ngIf="imageUrl"
    [src]="imageUrl"
    class="shrink-0 object-contain"
    [ngClass]="imageClass"
  />

  <mat-icon *ngIf="!imageUrl" class="ml-4 mr-8 shrink-0 opacity-50">
    {{ icon }}
  </mat-icon>

  <div class="flex flex-col items-start justify-start gap-1 pr-8">
    <span class="text-sm">{{ label }}</span>

    <span
      class="text-xs opacity-50"
      *ngFor="let info of info; trackBy: trackByInfo"
    >
      {{ info }}
    </span>
  </div>
</div>
