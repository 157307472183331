import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IResolvedClaimItem,
  TransactionOperators,
  reloadProviderData,
  resolveClaimItems,
} from '@principle-theorem/principle-core';
import {
  type IHealthcareClaim,
  type IInvoice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  resolveDocRefs$,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  type Observable,
} from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-healthcare-claim',
  templateUrl: './healthcare-claim.component.html',
  styleUrls: ['./healthcare-claim.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthcareClaimComponent {
  invoice$ = new ReplaySubject<WithRef<IInvoice>>(1);
  claim$ = new ReplaySubject<IHealthcareClaim>(1);
  transactions$: Observable<WithRef<ITransaction>[]>;
  received$: Observable<number>;
  refreshingProviderData$ = new BehaviorSubject<boolean>(false);
  isLocked$: Observable<boolean>;
  claimItems$: Observable<IResolvedClaimItem[]>;

  constructor() {
    this.claimItems$ = combineLatest([this.invoice$, this.claim$]).pipe(
      map(([invoice, claim]) => resolveClaimItems(invoice, claim))
    );
    this.transactions$ = this.claim$.pipe(
      switchMap((claim) => resolveDocRefs$(claim.transactions))
    );
    this.received$ = this.transactions$.pipe(
      map((transactions) =>
        new TransactionOperators(transactions).benefitReceived()
      ),
      startWith(0)
    );
  }

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this.invoice$.next(invoice);
    }
  }

  @Input()
  set claim(claim: IHealthcareClaim) {
    if (claim) {
      this.claim$.next(claim);
    }
  }

  async refreshProviderData(): Promise<void> {
    this.refreshingProviderData$.next(true);
    const invoice = await snapshot(this.invoice$);
    const claim = await snapshot(this.claim$);
    await reloadProviderData(invoice, claim);
    this.refreshingProviderData$.next(false);
  }
}
