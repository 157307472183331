import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { PatientForm } from '@principle-theorem/principle-core';
import {
  type IPatient,
  type IPatientForm,
} from '@principle-theorem/principle-core/interfaces';
import {
  HISTORY_DATE_TIME_FORMAT,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { PatientSubmittedFormsListStore } from './patient-submitted-forms-list.store';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
    selector: 'pr-patient-submitted-forms-list',
    templateUrl: './patient-submitted-forms-list.component.html',
    styleUrls: ['./patient-submitted-forms-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [PatientSubmittedFormsListStore],
    standalone: false
})
export class PatientSubmittedFormsListComponent {
  readonly store = inject(PatientSubmittedFormsListStore);
  readonly dateTimeFormat = HISTORY_DATE_TIME_FORMAT;
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);

  @Output() formSelected = new EventEmitter<WithRef<IPatientForm>>();

  @Input() imageEmptyState: boolean = false;
  @Input() loadingState: boolean = true;

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  @Input({ transform: coerceBooleanProperty })
  set hideConfirmedForms(hideConfirmedForms: boolean) {
    this.store.hideConfirmedForms(hideConfirmedForms);
  }

  constructor() {
    this.store.loadSubmittedForms(this.patient$);
  }

  getFormName(form: WithRef<IPatientForm>): string {
    return PatientForm.getName(form);
  }
}
