import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable, inject, type OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  DataStreamEvent,
  DialogPresets,
  StorageResponseAPI,
  type IDataStreamEvent,
} from '@principle-theorem/ng-shared';
import { Brand, CustomReport } from '@principle-theorem/principle-core';
import {
  CustomChartType,
  ICustomReport,
  MeasureFormatter,
  type ICustomChartSettings,
  type ICustomReportChart,
  type ICustomReportChartSection,
  type ICustomReportColumn,
  type ICustomReportData,
  type ICustomReportFilter,
  type IQueryScopeRequests,
  type ReportBuilderDataSourceId,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  WithRef,
  addDoc,
  filterUndefined,
  snapshot,
  uid,
  type WithId,
} from '@principle-theorem/shared';
import { default as crossfilter } from 'crossfilter2';
import * as dc from 'dc';
import { flatten, isEqual } from 'lodash';
import { Observable, Subject, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ReportBuilderDataSource } from '../../models/report-builder-data-sources/report-builder-data-source';
import { resolveDataSource } from '../../models/report-builder-data-sources/report-builder-data-sources';
import { ReportBuilderQueryBuilder } from '../../models/report-builder-data-sources/report-builder-query-builder';
import { BigQueryDataSource } from '../../models/report/data-sources/big-query-data-source';
import { type ICustomReportForm } from '../custom-reports/custom-report/edit-custom-report-dialog/edit-custom-report-dialog.component';
import { SaveCustomReportDialogComponent } from '../custom-reports/custom-report/save-custom-report-dialog/save-custom-report-dialog.component';
import { toCustomReport } from './report-builder-custom-report';
import {
  IReportBuildMoveToSectionFormData,
  IReportBuilderMoveToSectionDialogData,
  ReportBuilderMoveToSectionDialogComponent,
} from './report-builder-move-to-section-dialog/report-builder-move-to-section-dialog.component';
import {
  IReportBuilderAddChartRequest,
  IReportBuilderAddChartResponse,
  ReportBuilderAddChartDialogComponent,
} from './report-builder-results-table/report-builder-add-chart-dialog/report-builder-add-chart-dialog.component';
import {
  IReportBuildTableNameFormData,
  IReportBuilderTableNameDialogData,
  ReportBuilderTableNameDialogComponent,
} from './report-builder-results-table/report-builder-table-name-dialog/report-builder-table-name-dialog.component';

interface INdxEvent {
  ndx: crossfilter.Crossfilter<object>;
  eventType: crossfilter.EventType;
}

export interface IReportBuilderQueryForm {
  dataSource: ReportBuilderDataSourceId;
  scopeRequests: IQueryScopeRequests;
}

export interface IReportBuilderQuery
  extends ICustomReportData,
    IReportBuilderQueryForm {
  editMode: boolean;
  currentReport?: WithRef<ICustomReport>;
}

export interface IReportBuilderResults<T> {
  ndx: crossfilter.Crossfilter<T>;
  all: readonly T[];
  allCount: number;
  filtered: T[];
  filteredCount: number;
}

export interface IReportBuilderDisplay {
  columns: ICustomReportColumn[];
  chartSections: ICustomReportChartSection[];
  dynamicFilters: ICustomReportFilter[];
}

export interface IReportBuilderState<T = object> {
  isError: boolean;
  loading: boolean;
  progress?: number;
  query?: IReportBuilderQuery;
  results?: IReportBuilderResults<T>;
  display?: IReportBuilderDisplay;
  currentReport?: WithRef<ICustomReport>;
  formChanges?: ICustomReportForm;
}

const initialState: IReportBuilderState = {
  isError: false,
  loading: false,
};

@Injectable()
export class ReportBuilderStore
  extends ComponentStore<IReportBuilderState>
  implements OnDestroy
{
  private _organisation = inject(OrganisationService);

  readonly resetFilter$ = new Subject<string>();
  readonly resetCustomReportForm$ = new Subject<void>();
  readonly isLoading$ = this.select((state) => state.loading);
  readonly progress$ = this.select((state) => state.progress);
  readonly isError$ = this.select((state) => state.isError);

  readonly query$ = this.select((state) => state.query);
  readonly dataSource$ = this.select((state) =>
    resolveDataSource(state.query?.dataSource)
  );

  readonly hasData$ = this.select((state) => !!state.results?.allCount);
  readonly hasNoData$ = this.select(
    (state) => state.results && !state.results.allCount
  );

  readonly results$ = this.select((state) => state.results);
  readonly ndx$ = this.select((state) => state.results?.ndx).pipe(
    distinctUntilChanged()
  );
  readonly crossfilterEvent$: Observable<INdxEvent>;

  readonly display$ = this.select((state) => state.display);
  readonly editMode$ = this.select((state) => state.query?.editMode ?? false);
  readonly currentReport$ = this.select((state) => state.currentReport);
  readonly formChanges$ = this.select((state) => state.formChanges);

  readonly hasEdits$ = this.select(
    this.display$,
    this.currentReport$,
    this.formChanges$.pipe(map((formChanges) => !!formChanges)),
    (display, report, hasFormChanges) => {
      if (!display || !report) {
        return false;
      }
      const columnsChanged = !isEqual(display?.columns, report?.columns);
      const chartsChanged = !isEqual(
        display?.chartSections,
        report?.chartSections
      );
      return hasFormChanges || columnsChanged || chartsChanged;
    }
  );

  readonly loadQuery = this.effect((query$: Observable<IReportBuilderQuery>) =>
    query$.pipe(
      tap((query) => {
        this.patchState({
          query,
          results: undefined,
          display: undefined,
          isError: false,
          loading: true,
          progress: undefined,
          currentReport: query.currentReport,
        });
      }),
      switchMap((query) =>
        this._queryFactTable$(query).pipe(
          tap((event) => this._handleLoadStreamEvent(query, event)),
          catchError((error) => {
            this.patchState({
              isError: true,
              loading: false,
              progress: undefined,
            });
            // eslint-disable-next-line no-console
            console.error(error);
            return of(undefined);
          })
        )
      )
    )
  );
  readonly ndxEvent = this.effect((event$: Observable<INdxEvent>) =>
    event$.pipe(tap((event) => this._handleNdxEvent(event)))
  );

  readonly setDisplay = this.updater(
    (state, display?: IReportBuilderDisplay) => ({
      ...state,
      display,
    })
  );
  readonly reset = this.updater(() => initialState);

  constructor(
    private _api: StorageResponseAPI,
    private _dialog: MatDialog,
    private _snackbar: MatSnackBar,
    private _router: Router
  ) {
    super(initialState);

    this.crossfilterEvent$ = this._listenToNdxEvents$();
    this.crossfilterEvent$
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => this.ndxEvent(event));
  }

  async addChart<T extends ICustomChartSettings>(
    chart: ICustomReportChart<T>,
    sectionName: string = 'Charts',
    index?: number
  ): Promise<void> {
    const newChart = {
      ...chart,
      uid: uid(),
    };

    const current = await snapshot(this.display$);
    if (!current) {
      return;
    }

    const existingSection = current.chartSections.find(
      (section) => section.name === sectionName
    );

    if (!existingSection) {
      const newSection = CustomReport.chartSection(sectionName, [newChart]);
      this.setDisplay({
        ...current,
        chartSections: [...current.chartSections, newSection],
      });
      return;
    }

    const chartSections = current.chartSections.map((section) => {
      if (section.uid !== existingSection.uid) {
        return section;
      }

      const charts = [...section.charts];
      charts.splice(index ?? charts.length, 0, newChart);

      return {
        ...section,
        charts,
      };
    });

    this.setDisplay({ ...current, chartSections });
  }

  async editChart(
    chart: ICustomReportChart,
    sectionName: string
  ): Promise<void> {
    const current = await snapshot(this.display$);
    if (!current) {
      return;
    }

    const dataSource = await snapshot(this.dataSource$);
    if (!dataSource) {
      return;
    }
    const filteredData = await snapshot(
      this.results$.pipe(map((results) => results?.filtered ?? []))
    );
    const data: IReportBuilderAddChartRequest = {
      measures: [
        ...ReportBuilderDataSource.allColumns(dataSource).map(
          (column) => column.measure
        ),
        dataSource.factTable.count,
      ],
      groupBys: dataSource?.groupByOptions ?? [],
      filteredData,
      chart,
    };

    const updates = await this._dialog
      .open<
        ReportBuilderAddChartDialogComponent,
        IReportBuilderAddChartRequest,
        IReportBuilderAddChartResponse
      >(
        ReportBuilderAddChartDialogComponent,
        DialogPresets.almostFullscreen({ data })
      )
      .afterClosed()
      .toPromise();

    if (!updates) {
      return;
    }

    const updatedChart = {
      ...chart,
      type: updates.type,
      label: updates.label,
      groupBy: updates.groupBy.metadata.id,
      settings:
        updates.type === CustomChartType.Table
          ? chart.settings
          : [
              {
                uid: uid(),
                label: updates.measure.metadata.label,
                dataPoint: updates.measure.metadata.id,
                reducer: updates.reducer,
                filters: updates.filters,
              },
            ],
      builderConfig: {
        rotateXAxisLabels:
          updates.type === CustomChartType.Column ? true : false,
      },
      builderData: {
        colourOverride: !!updates.groupBy.groupMeasure.colourAccessor,
        plottedOverTime: [
          MeasureFormatter.Timestamp,
          MeasureFormatter.Day,
        ].includes(updates.groupBy.metadata.formatter ?? MeasureFormatter.Text)
          ? true
          : false,
      },
    };

    await this.patchChart(updatedChart, sectionName);
  }

  async editTable(
    chart: ICustomReportChart,
    sectionName: string
  ): Promise<void> {
    const current = await snapshot(this.display$);
    if (!current) {
      return;
    }

    const data: IReportBuilderTableNameDialogData = {
      chart,
    };

    const updates = await this._dialog
      .open<
        ReportBuilderTableNameDialogComponent,
        IReportBuilderTableNameDialogData,
        IReportBuildTableNameFormData
      >(ReportBuilderTableNameDialogComponent, DialogPresets.small({ data }))
      .afterClosed()
      .toPromise();

    if (!updates) {
      return;
    }

    const updatedTable = {
      ...chart,
      label: updates.label,
    };

    await this.patchChart(updatedTable, sectionName);
  }

  async patchChart(
    chart: ICustomReportChart,
    sectionName: string
  ): Promise<void> {
    const current = await snapshot(this.display$);
    if (!current) {
      return;
    }

    const existingSection = current.chartSections.find(
      (section) => section.name === sectionName
    );

    if (!existingSection) {
      const newSection = CustomReport.chartSection(sectionName, [chart]);
      this.setDisplay({
        ...current,
        chartSections: [...current.chartSections, newSection],
      });
      return;
    }

    const chartSections = current.chartSections.map((section) => {
      if (section.uid !== existingSection.uid) {
        return section;
      }

      const charts = [...section.charts];
      const chartIndex: number = charts.findIndex(
        (existingChart) => existingChart.uid === chart.uid
      );
      charts.splice(chartIndex, 1, chart);

      return {
        ...section,
        charts,
      };
    });
    this.setDisplay({ ...current, chartSections });
  }

  async moveToSection(
    chart: ICustomReportChart,
    currentSectionName: string
  ): Promise<void> {
    const current = await snapshot(this.display$);
    if (!current) {
      return;
    }

    const data: IReportBuilderMoveToSectionDialogData = {
      sectionNames: current.chartSections
        .map((section) => section.name)
        .filter((name) => name !== currentSectionName)
        .sort(),
    };

    const newSection = await this._dialog
      .open<
        ReportBuilderMoveToSectionDialogComponent,
        IReportBuilderMoveToSectionDialogData,
        IReportBuildMoveToSectionFormData
      >(
        ReportBuilderMoveToSectionDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!newSection) {
      return;
    }

    const chartSections = current.chartSections.map((section) => {
      if (section.name === currentSectionName) {
        const charts = [...section.charts];
        const chartIndex: number = charts.findIndex(
          (existingChart) => existingChart.uid === chart.uid
        );
        charts.splice(chartIndex, 1);
        return {
          ...section,
          charts,
        };
      }

      if (section.name === newSection?.sectionName) {
        return {
          ...section,
          charts: [...section.charts, chart],
        };
      }

      return section;
    });

    this.setDisplay({ ...current, chartSections });
  }

  async duplicateChart(
    chart: ICustomReportChart,
    sectionName: string
  ): Promise<void> {
    const current = await snapshot(this.display$);
    if (!current) {
      return;
    }

    const chartSections = current.chartSections.map((section) => {
      if (section.name !== sectionName) {
        return section;
      }

      const charts = [...section.charts];
      const chartIndex: number = charts.findIndex(
        (existingChart) => existingChart.uid === chart.uid
      );
      charts.splice(chartIndex, 0, { ...chart, uid: uid() });

      return {
        ...section,
        charts,
      };
    });
    this.setDisplay({ ...current, chartSections });
  }

  async moveChartLeft(
    chart: ICustomReportChart,
    sectionName: string
  ): Promise<void> {
    await this.moveChart(-1, chart, sectionName);
  }

  async moveChartRight(
    chart: ICustomReportChart,
    sectionName: string
  ): Promise<void> {
    await this.moveChart(1, chart, sectionName);
  }

  async moveChart(
    indexOffset: number,
    chart: ICustomReportChart,
    sectionName: string
  ): Promise<void> {
    const current = await snapshot(this.display$);
    if (!current) {
      return;
    }

    const chartSections = current.chartSections.map((section) => {
      if (section.name !== sectionName) {
        return section;
      }

      const sectionCharts = [...section.charts];
      const currentIndex = this._getChartIndex(chart, sectionCharts);

      if (currentIndex === -1) {
        return section;
      }

      moveItemInArray(sectionCharts, currentIndex, currentIndex + indexOffset);

      return {
        ...section,
        charts: sectionCharts,
      };
    });
    this.setDisplay({ ...current, chartSections });
  }

  async deleteChart(chart: ICustomReportChart): Promise<void> {
    const current = await snapshot(this.display$);
    if (!current) {
      return;
    }

    const chartSections = current.chartSections.map((section) => ({
      ...section,
      charts: section.charts.filter((item) => item.uid !== chart.uid),
    }));

    const dynamicFilters = current.dynamicFilters.filter(
      (filter) => filter.id !== chart.uid
    );
    this.setDisplay({ ...current, chartSections, dynamicFilters });
    this.resetFilter$.next(chart.uid);
  }

  async deleteFilter(filterId: string): Promise<void> {
    const current = await snapshot(this.display$);
    if (!current) {
      return;
    }

    const dynamicFilters = current.dynamicFilters.filter(
      (filter) => filter.id !== filterId
    );
    this.setDisplay({ ...current, dynamicFilters });
  }

  async saveAsCustomReport(): Promise<void> {
    const state = await snapshot(this.state$);
    if (!state.query || !state.display) {
      return;
    }

    const base = await this._dialog
      .open<SaveCustomReportDialogComponent, void, ICustomReportForm>(
        SaveCustomReportDialogComponent,
        DialogPresets.small()
      )
      .afterClosed()
      .toPromise();
    if (!base) {
      return;
    }
    const customReport = toCustomReport(base, state.query, state.display);
    const brandRef = state.query.scopeRequests.brand?.brandRef;
    if (!brandRef) {
      throw new Error('Brand is required');
    }

    const customReportRef = await addDoc(
      Brand.customReportCol({ ref: brandRef }),
      customReport
    );

    this._snackbar
      .open('Custom report added', 'Open Custom Report', {
        duration: 5000,
      })
      .onAction()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () =>
          void this._router.navigate([
            'reporting',
            'custom-reports',
            customReportRef.id,
          ])
      );
  }

  async saveReportChanges(): Promise<void> {
    const display = await snapshot(this.display$);
    const report = await snapshot(this.currentReport$);
    const hasEdits = await snapshot(this.hasEdits$);
    const formChanges = await snapshot(this.formChanges$);
    if (!hasEdits || !report) {
      return;
    }

    const updates = {
      columns: display?.columns ?? report.columns,
      chartSections: display?.chartSections ?? report.chartSections,
      ...formChanges,
    };
    await Firestore.patchDoc(report.ref, {
      ...updates,
    });
    this.patchState({
      currentReport: {
        ...report,
        ...updates,
      },
      formChanges: undefined,
    });

    this.patchState((state) => {
      if (!state.query) {
        return state;
      }

      return {
        query: {
          ...state.query,
          editMode: false,
        },
      };
    });
  }

  async discardReportChanges(): Promise<void> {
    const report = await snapshot(this.currentReport$);
    if (!report) {
      return;
    }
    this.setDisplay({
      columns: report.columns,
      chartSections: report.chartSections,
      dynamicFilters: [],
    });
    this.resetCustomReportForm$.next();
    this.patchState({ formChanges: undefined });
  }

  private _handleLoadStreamEvent<T extends object>(
    query: IReportBuilderQuery,
    event: IDataStreamEvent<T[]>
  ): void {
    if (DataStreamEvent.isProgress(event)) {
      this.patchState({
        loading: true,
        progress: event.value * 100,
      });
    }

    if (DataStreamEvent.isComplete(event)) {
      const ndx = crossfilter<T>(event.value);
      const results = this._getResults(ndx);
      const display = this._getDisplay(query);
      this.patchState({ results, display, loading: false });
      setTimeout(() => dc.renderAll(), 500); // TODO: Do not like this
    }
  }

  private _getDisplay(
    query: IReportBuilderQuery
  ): IReportBuilderDisplay | undefined {
    const dataSource = resolveDataSource(query.dataSource);
    const columns =
      query.columns ?? ReportBuilderDataSource.getDefaultColumns(dataSource);
    return {
      dynamicFilters: [],
      columns,
      chartSections: query.chartSections ?? [],
    };
  }

  private _queryFactTable$<T extends object>(
    query: IReportBuilderQuery
  ): Observable<IDataStreamEvent<T[]>> {
    const orgRef$ = this._organisation.organisation$.pipe(
      filterUndefined(),
      map((org) => org.ref)
    );
    const bigQuery = new BigQueryDataSource<T>(this._api, orgRef$);
    const dataSource = resolveDataSource(query.dataSource);
    if (!dataSource) {
      throw new Error('DataSource not found');
    }
    const columns =
      query.columns ?? ReportBuilderDataSource.getDefaultColumns(dataSource);
    const charts = flatten(
      query.chartSections?.map((section) => section.charts)
    );
    const filters = query.staticFilters ?? [];
    const reportingQuery = ReportBuilderQueryBuilder.buildQuery(
      dataSource,
      columns,
      charts,
      filters,
      query.editMode
    );
    if (!reportingQuery) {
      throw new Error('Reporting query not implemented');
    }
    return bigQuery.get$(reportingQuery, query.scopeRequests);
  }

  private _handleNdxEvent(event: INdxEvent): void {
    const results = this._getResults(event.ndx);
    this.patchState({ results });
  }

  private _getResults<T extends object>(
    ndx: crossfilter.Crossfilter<T>
  ): IReportBuilderResults<T> {
    const all = ndx.all();
    const filtered = ndx.allFiltered();
    return {
      ndx,
      all,
      allCount: all.length,
      filtered,
      filteredCount: filtered.length,
    };
  }

  private _listenToNdxEvents$(): Observable<INdxEvent> {
    return this.ndx$.pipe(
      filterUndefined(),
      switchMap(
        (ndx) =>
          new Observable<INdxEvent>((observer) =>
            ndx.onChange((eventType) => observer.next({ ndx, eventType }))
          )
      )
    );
  }

  private _getChartIndex(
    chart: ICustomReportChart,
    charts: ICustomReportChart[]
  ): number {
    return charts.findIndex((item) => item.uid === chart.uid);
  }
}

export function toWithIds(
  columns: ICustomReportColumn[]
): WithId<ICustomReportColumn>[] {
  return columns.map((column, index) => {
    return { ...column, uid: `${column.id}-${index}` };
  });
}
