import {
  ServiceProviderHandler,
  TreatmentConfiguration,
  toINamedDocuments,
} from '@principle-theorem/principle-core';
import {
  ALL_SERVICE_CODES,
  CustomMappingAssociatedValueType,
  CustomMappingOption,
  CustomMappingType,
  ITranslationMap,
  ITreatmentConfiguration,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
  type IServiceCode,
} from '@principle-theorem/principle-core/interfaces';
import {
  XSLXImporterExporter,
  asyncForEach,
  hardDeleteDoc,
  isINamedDocument,
  multiSortBy$,
  nameSorter,
  query$,
  snapshot,
  undeletedQuery,
  type INamedDocument,
  type IReffable,
  type WithRef,
  IBlobFilenamePair,
} from '@principle-theorem/shared';
import { compact, sortBy } from 'lodash';
import { of, type Observable } from 'rxjs';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import { IBaseMigrationItemCode } from '../../../interfaces';
import { ITEM_CODE_TO_SERVICE_CODE_MAPPINGS } from '../../../mappings/ada-code-mappings';
import {
  ITEM_CODE_CUSTOM_MAPPING_TYPE,
  ItemCodeResourceMapType,
  ItemCodesToXLSX,
} from '../../../mappings/item-codes-to-xlsx';
import { XLSXToItemCodes } from '../../../mappings/xlsx-to-item-codes';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import { IPraktikaAppointmentProcedure } from '../../source/entities/appointment-procedure';
import { FeeScheduleSourceEntity } from '../../source/entities/fee-schedule';

export const ITEM_CODE_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Item Codes',
    description: `Used for mapping notes on non-ada item codes to Principle.

    As Praktika allows for the creation of non ADA item codes, we need to allow the mapping of non-ADA codes that were used for treatment, to be mapped to an official ADA code/Treatment Configuration.

    In the case that a treatment has a fee, the treatment will be reflected on the invoice as expected, regardless of the item code.`,
    type: ITEM_CODE_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.SelectionList,
});

export class PraktikaItemCodeMappingHandler extends BaseCustomMappingHandler<
  object,
  ItemCodeResourceMapType
> {
  customMapping = ITEM_CODE_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const itemCodes = await this._getItemCodeOptions(migration);
    return itemCodes.map((record) => ({
      label: compact([record.itemCode, record.description]).join(' - '),
      value: record.id.toString(),
    }));
  }

  selectedOptionRequiresValue(
    destinationValue: ItemCodeResourceMapType
  ): boolean {
    if (
      [
        ItemCodeResourceMapType.ServiceCode,
        ItemCodeResourceMapType.TreatmentConfiguration,
      ].includes(destinationValue)
    ) {
      return true;
    }

    return false;
  }

  async getSelectionListOptions(
    _migration: WithRef<IPracticeMigration>
  ): Promise<CustomMappingOption[]> {
    return snapshot(
      of([
        {
          value: ItemCodeResourceMapType.ServiceCode,
          description: 'Map this to a Service Code',
          hasAssociatedValue: true,
          associatedValueType: CustomMappingAssociatedValueType.String,
          associatedValueDescription:
            'Which service code should be used for this?',
        },
        {
          value: ItemCodeResourceMapType.TreatmentConfiguration,
          description: 'Map this to a Treatment',
          hasAssociatedValue: true,
          associatedValueType: CustomMappingAssociatedValueType.NamedDocument,
          associatedValueDescription:
            'Which Treatment should be used for this?',
        },
        {
          value: ItemCodeResourceMapType.Omit,
          description: 'Omit this item code from the migration',
          hasAssociatedValue: false,
        },
      ])
    );
  }

  getAssociatedValueOptions$(
    migration: IPracticeMigration,
    destinationValue: ItemCodeResourceMapType
  ): Observable<{ name: string }[]> {
    if (destinationValue === ItemCodeResourceMapType.ServiceCode) {
      return of(
        sortBy(
          ALL_SERVICE_CODES.map((code) => ({ name: String(code.code) })),
          'name'
        )
      );
    }

    if (destinationValue === ItemCodeResourceMapType.TreatmentConfiguration) {
      return TreatmentConfiguration.all$(migration.configuration.brand).pipe(
        toINamedDocuments(),
        multiSortBy$(nameSorter())
      );
    }

    return of([]);
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, itemCodes, translator } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(fileName, itemCodes, translator);
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, itemCodes, translator } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(fileName, itemCodes, translator);
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XLSXToItemCodes()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const records = await this.getRecords(translationMap);
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));

    const sourceOptions = await this.getSourceOptions(migration);

    await asyncForEach(items, async (item) => {
      const label = item.description
        ? `${item.code} - ${item.description}`
        : item.code;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.label === label
      )?.value;
      if (!value || !item.associatedValue) {
        return;
      }

      const treatmentConfigurations =
        await this._getTreatmentConfigurations(migration);
      const treatmentConfiguration = treatmentConfigurations.find(
        (config) => config.name === item.associatedValue
      );
      if (treatmentConfiguration) {
        await this.upsertRecord(
          {
            associatedValue: treatmentConfiguration,
            destinationValue: ItemCodeResourceMapType.TreatmentConfiguration,
            sourceIdentifier: value,
            sourceLabel: label,
          },
          translationMap
        );
        return;
      }

      const serviceCode = ServiceProviderHandler.findServiceCode(
        item.associatedValue.trim()
      );
      if (serviceCode) {
        await this.upsertRecord(
          {
            associatedValue: serviceCode.code.toString(),
            destinationValue: ItemCodeResourceMapType.ServiceCode,
            sourceIdentifier: value,
            sourceLabel: label,
          },
          translationMap
        );
        return;
      }

      // eslint-disable-next-line no-console
      console.error(
        `Mapping error: ${this.customMapping.metadata.label} - Couldn't find value for item`,
        item
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    itemCodes: IBaseMigrationItemCode[];
    translator: ItemCodesToXLSX<IBaseMigrationItemCode>;
  }> {
    const fileName = this.getFileName();
    const itemCodes = await this._getItemCodeOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const translator = new ItemCodesToXLSX(
      await this.getRecords(translationMap),
      await this._getTreatmentConfigurations(migration),
      ITEM_CODE_TO_SERVICE_CODE_MAPPINGS
    );
    return { fileName, itemCodes, translator };
  }

  private async _getItemCodeOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<IBaseMigrationItemCode[]> {
    const feeScheduleSource = new FeeScheduleSourceEntity();
    const records = await feeScheduleSource
      .getRecords$(migration, 10000)
      .toPromise();
    const itemCodes = records
      .map((record) => {
        return record.data.data.items;
      })
      .flat()
      .map((item) => ({
        id: item.custom_code || item.code,
        itemCode: item.custom_code || item.code,
        description: item.custom_description || '',
      }));
    return sortBy(
      itemCodes.filter(
        (record) => !ServiceProviderHandler.findServiceCode(record.itemCode)
      ),
      'itemCode'
    );
  }

  private async _getTreatmentConfigurations(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<ITreatmentConfiguration>[]> {
    return snapshot(
      query$(
        undeletedQuery(
          TreatmentConfiguration.col(migration.configuration.brand)
        )
      ).pipe(toINamedDocuments(), multiSortBy$(nameSorter()))
    );
  }
}

export function resolveMappedCode(
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[],
  sourceTreatment: IPraktikaAppointmentProcedure
):
  | IServiceCode
  | INamedDocument<ITreatmentConfiguration>
  | ItemCodeResourceMapType.Omit
  | undefined {
  const procedureCode =
    sourceTreatment.code.toString() || sourceTreatment.ada_code?.toString();

  const mappedCode = sourceItemCodes.find(
    (sourceItemCode) => sourceItemCode.sourceIdentifier === procedureCode
  );

  if (mappedCode?.destinationValue === ItemCodeResourceMapType.Omit) {
    return ItemCodeResourceMapType.Omit;
  }

  if (
    mappedCode?.destinationValue ===
      ItemCodeResourceMapType.TreatmentConfiguration &&
    mappedCode?.associatedValue &&
    isINamedDocument<ITreatmentConfiguration>(mappedCode.associatedValue)
  ) {
    return mappedCode.associatedValue;
  }

  let code = ServiceProviderHandler.findServiceCode(sourceTreatment.code);
  if (!code && sourceTreatment.ada_code) {
    code = ServiceProviderHandler.findServiceCode(sourceTreatment.ada_code);
  }

  if (code) {
    return code;
  }

  if (
    mappedCode?.destinationValue !== ItemCodeResourceMapType.ServiceCode ||
    !mappedCode?.associatedValue
  ) {
    if (procedureCode && PRAKTIKA_SPECIAL_CODES[procedureCode]) {
      return ItemCodeResourceMapType.Omit;
    }

    throw new Error(`Could not find service code for ${procedureCode}`);
  }

  if (isINamedDocument(mappedCode.associatedValue)) {
    return ServiceProviderHandler.findServiceCode(
      mappedCode.associatedValue.ref.id
    );
  }

  return ServiceProviderHandler.findServiceCode(mappedCode.associatedValue);
}

export const PRAKTIKA_SPECIAL_CODES: { [key: string]: string } = {
  LB: 'Loyalty Bonus',
  '000': 'Free Service',
};
