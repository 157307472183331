import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { MomentModule } from 'ngx-moment';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ContentTextComponent } from './content-text/content-text.component';
import { ContextualActionsModule } from './contextual-actions/contextual-actions.module';
import { EditInteractionDialogComponent } from './edit-interaction-dialog/edit-interaction-dialog.component';
import { PrincipleEditorModule } from './editor/principle-editor.module';
import { EmailComponent } from './email/email.component';
import { SendEmailService } from './email/send-email.service';
import { SystemEmailDialogComponent } from './email/system-email-dialog/system-email-dialog.component';
import { InteractionActionsComponent } from './interaction-actions/interaction-actions.component';
import { InteractionTimelineEventComponent } from './interaction-timeline-event/interaction-timeline-event.component';
import { InteractionTimelineComponent } from './interaction-timeline/interaction-timeline.component';
import { InteractionAmendmentHistoryDialogComponent } from './interactions/interaction-amendment-history-dialog/interaction-amendment-history-dialog.component';
import { InteractionAmendmentHistoryComponent } from './interactions/interaction-amendment-history/interaction-amendment-history.component';
import { InteractionSummaryComponent } from './interactions/interaction/interaction-summary/interaction-summary.component';
import { InteractionComponent } from './interactions/interaction/interaction.component';
import { SchedulingEventInteractionDetailsComponent } from './interactions/interaction/scheduling-event-interaction-details/scheduling-event-interaction-details.component';
import { SchedulingEventInteractionHeaderComponent } from './interactions/interaction/scheduling-event-interaction-header/scheduling-event-interaction-header.component';
import { InteractionsComponent } from './interactions/interactions.component';
import { MentionModule } from './mention/mention.module';
import { NoteFormComponent } from './note-form/note-form.component';
import { NoteComponent } from './note/note.component';
import { NotesDialogComponent } from './notes-dialog/notes-dialog.component';
import { AddNoteComponent } from './notes/add-note/add-note.component';
import { EditNoteComponent } from './notes/edit-note/edit-note.component';
import { NotesComponent } from './notes/notes.component';
import { PhoneComponent } from './phone/phone.component';
import { PopupNotificationComponent } from './popup-notification/popup-notification.component';
import { PopupNotificationsComponent } from './popup-notifications/popup-notifications.component';
import { PrintContainerComponent } from './print-container/print-container.component';
import { PrintVariantSelectorDialogComponent } from './print-variant-selector-dialog/print-variant-selector-dialog.component';
import { SendSMSService } from './sms/send-sms.service';
import { SMSComponent } from './sms/sms.component';
import { SystemSmsDialogComponent } from './sms/system-sms-dialog/system-sms-dialog.component';
import { TemplateContextSelectorDialogComponent } from './template-context-selector-dialog/template-context-selector-dialog.component';
import { TemplateSelectorComponent } from './template-selector/template-selector.component';

@NgModule({
  imports: [
    CommonModule,
    NgSharedModule,
    ContextualActionsModule,
    PrincipleEditorModule,
    MentionModule,
    ReactiveFormsModule,
    MomentModule,
    FormsModule,
    NgPrincipleSharedModule,
    NgxSkeletonLoaderModule,
    PrintVariantSelectorDialogComponent,
  ],
  declarations: [
    EmailComponent,
    PhoneComponent,
    SMSComponent,
    InteractionSummaryComponent,
    InteractionComponent,
    InteractionsComponent,
    NotesComponent,
    AddNoteComponent,
    EditNoteComponent,
    NotesDialogComponent,
    ContentTextComponent,
    TemplateSelectorComponent,
    NoteComponent,
    InteractionTimelineComponent,
    InteractionTimelineEventComponent,
    PrintContainerComponent,
    InteractionAmendmentHistoryDialogComponent,
    InteractionAmendmentHistoryComponent,
    InteractionActionsComponent,
    EditInteractionDialogComponent,
    NoteFormComponent,
    TemplateContextSelectorDialogComponent,
    SystemSmsDialogComponent,
    SystemEmailDialogComponent,
    PopupNotificationsComponent,
    PopupNotificationComponent,
    SchedulingEventInteractionHeaderComponent,
    SchedulingEventInteractionDetailsComponent,
  ],
  providers: [SendEmailService, SendSMSService],
  exports: [
    InteractionsComponent,
    InteractionSummaryComponent,
    ContextualActionsModule,
    PrincipleEditorModule,
    MentionModule,
    NotesComponent,
    AddNoteComponent,
    EditNoteComponent,
    NotesDialogComponent,
    ContentTextComponent,
    TemplateSelectorComponent,
    InteractionTimelineComponent,
    PrintContainerComponent,
    InteractionTimelineEventComponent,
    PopupNotificationsComponent,
    SchedulingEventInteractionHeaderComponent,
    SchedulingEventInteractionDetailsComponent,
  ],
})
export class NgInteractionsModule {}
