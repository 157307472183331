import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  CalendarEventSummary,
  type ICalendarEvent,
  type IEvent,
} from '@principle-theorem/principle-core/interfaces';
import { Firestore, type WithRef } from '@principle-theorem/shared';

export type CalendarEventSidebarState = {
  calendarEvent: WithRef<ICalendarEvent> | ICalendarEvent | undefined;
};

@Injectable({
  providedIn: 'root',
})
export class CalendarEventSidebarStoreService extends ComponentStore<CalendarEventSidebarState> {
  readonly calendarEvent$ = this.select((state) => state.calendarEvent);
  readonly event$ = this.select((state) => state.calendarEvent?.event);

  constructor() {
    super({
      calendarEvent: undefined,
    });
  }

  async setCalendarEventFromSummary(
    calendarEventSummary: CalendarEventSummary
  ): Promise<void> {
    this.setState({
      calendarEvent: calendarEventSummary.ref
        ? await Firestore.getDoc(calendarEventSummary.ref)
        : undefined,
    });
  }

  patchEvent(event: Partial<IEvent>): void {
    this.patchState((state) => {
      if (!state.calendarEvent) {
        return state;
      }
      return {
        calendarEvent: {
          ...state.calendarEvent,
          event: {
            ...state.calendarEvent?.event,
            ...event,
          },
        },
      };
    });
  }
}
