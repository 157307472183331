import { rand, randNumber, randSentence } from '@ngneat/falso';
import {
  RawInlineNodes,
  VersionedSchema,
  initVersionedSchema,
  toTextContent,
} from '@principle-theorem/editor';
import {
  ICreatedTask,
  IPrincipleMention,
  IRecurringTask,
  IStaffer,
  ITask,
  ITeam,
  RecurringTaskDueTiming,
  TASK_PRIORITIES,
  TIMING_UNITS,
  TaskPriority,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  CUSTOM_RECURRENCE_FREQUENCIES,
  DAYS_OF_WEEK,
  EndingType,
  FREQUENCIES,
  INamedDocument,
  IRecurrencePattern,
  MockGenerator,
  MockTimestamp,
  Properties,
  Timestamp,
  toISODate,
  toTimestamp,
} from '@principle-theorem/shared';
import { MockDocRef, MockNamedDocument } from '@principle-theorem/testing';
import { MentionMock } from '../mention/mention.mock';

export class RecurringTaskMock
  extends BaseFirestoreMock
  implements Properties<IRecurringTask>
{
  get title(): RawInlineNodes {
    return [toTextContent(randSentence())];
  }

  get description(): VersionedSchema {
    return initVersionedSchema(randSentence());
  }

  get priority(): TaskPriority {
    return rand(TASK_PRIORITIES);
  }

  get assignedUser(): INamedDocument<IStaffer> {
    return MockNamedDocument<IStaffer>();
  }

  get assignedTeam(): INamedDocument<ITeam> {
    return MockNamedDocument<ITeam>();
  }

  get owner(): INamedDocument<IStaffer> {
    return MockNamedDocument<IStaffer>();
  }

  get mentions(): IPrincipleMention[] {
    return [MockGenerator.generate(MentionMock)];
  }

  get createdTasks(): ICreatedTask[] {
    return [MockCreatedTask(), MockCreatedTask()];
  }

  get recurrenceTiming(): RecurringTaskDueTiming {
    return {
      time: '00:00',
      amount: randNumber({
        min: 1,
        max: 5,
      }),
      unit: rand(TIMING_UNITS),
    };
  }

  get recurrencePattern(): IRecurrencePattern {
    return MockRecurrencePattern();
  }

  get completedAt(): Timestamp {
    return MockTimestamp();
  }
}

function MockRecurrencePattern(): IRecurrencePattern {
  return {
    frequencyType: rand(FREQUENCIES),
    customFrequencyType: rand(CUSTOM_RECURRENCE_FREQUENCIES),
    endingType: EndingType.Count,
    seperationCount: randNumber({
      min: 1,
      max: 5,
    }),
    occurrenceCount: randNumber({
      min: 1,
      max: 5,
    }),
    endingDate: toISODate(MockTimestamp()),
    daysOfWeek: DAYS_OF_WEEK,
    weeksOfMonth: [
      randNumber({
        min: 1,
        max: 4,
      }),
    ],
    daysOfMonth: [
      randNumber({
        min: 1,
        max: 28,
      }),
    ],
    monthsOfYear: [
      randNumber({
        min: 1,
        max: 12,
      }),
    ],
    startDate: toISODate(toTimestamp()),
  };
}

function MockCreatedTask(): ICreatedTask {
  return {
    dateShown: MockTimestamp(),
    ref: MockDocRef<ITask>(),
  };
}
