import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  ChartedConfigurationFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  type IChartedItemConfiguration,
  QUICK_CHART_CONDITIONS_KEYMAP,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

@Component({
    selector: 'pr-conditions-quick-chart',
    templateUrl: './conditions-quick-chart.component.html',
    styleUrls: ['./conditions-quick-chart.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConditionsQuickChartComponent {
  @Output() chartableAdded: EventEmitter<WithRef<IChartedItemConfiguration>> =
    new EventEmitter<WithRef<IChartedItemConfiguration>>();
  items$: Observable<WithRef<IChartedItemConfiguration>[]>;
  hotkeyMap: string[] = QUICK_CHART_CONDITIONS_KEYMAP;

  constructor(private _chartedItemStore: ChartedConfigurationFacade) {
    this.items$ = this._chartedItemStore.getQuickChartingConditions$(
      ChartId.InAppointment
    );
  }

  optionSelected(item: WithRef<IChartedItemConfiguration>): void {
    this.chartableAdded.emit(item);
  }
}
