@if (data(); as info) {
  @if (startAt(); as startAt) {
    <div class="flex flex-row gap-4">
      <div class="flex grow flex-col">
        <h3 class="!m-0 !font-bold">
          {{ startAt | moment | amDateFormat: dateFormat }}
        </h3>
        <div>
          {{ startAt | moment | amDateFormat: timeFormat }}
          @if (info.duration) {
            - {{ info.duration }}
          }
        </div>
      </div>

      <div>
        <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu">
          Add to calendar
        </button>
        <mat-menu #menu="matMenu">
          @for (link of addToCalendarLinks(); track link.path) {
            <a mat-menu-item [href]="link.path" target="_blank">
              {{ link.label }}
            </a>
          }
        </mat-menu>
      </div>
    </div>
  }

  @if (info.practitioner || info.treatment) {
    <div class="flex grow flex-col">
      @if (info.practitioner) {
        <h3 class="!m-0 !font-bold">{{ info.practitioner }}</h3>
      }
      @if (info.treatment) {
        <div>{{ info.treatment }}</div>
      }
    </div>
  }
}
