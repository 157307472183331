import {
  ISourceEntity,
  SourceEntityMigrationType,
  ToothNumber,
  isToothNumber,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISODateTimeType,
  ISODateType,
  ISOTimeType,
  Timestamp,
  Timezone,
  TypeGuard,
  isISODateTimeType,
  isISODateType,
  isISOTimeType,
  toFloat,
  toISODate,
  toTimestamp,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertDateToTimestampFn,
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  convertValueFn,
} from '../../../source/source-helpers';

export const PATIENT_TREATMENT_RESOURCE_TYPE = 'patientTreatments';

export const PATIENT_TREATMENT_SOURCE_ENTITY: ISourceEntity = SourceEntity.init(
  {
    metadata: {
      label: 'Patient Treatment List',
      description: '',
      idPrefix: PATIENT_TREATMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  }
);

export interface IOasisPatientTreatment {
  id: number;
  transactionGroupId?: string;
  appointmentDate: ISODateType;
  createdAt: ISODateType;

  itemCodeDescription: string;
  itemCode: string;
  toothNumber?: ToothNumber;
  minutes?: number;
  quantity?: number;

  accountPatientId?: number;
  patientId?: number; // This is set for all treatments. It's only missing from IOasisTransactionType.Treatment records when the description is "Plus GST" and "Loyalty Bonus".
  patientName?: string;

  accountThirdPartyId?: number;
  thirdPartyId?: number; // When the accountThirdPartyId is set, this can point to a patientId

  practitionerId: number;
  billedToPractitionerId: number;

  wasClaimedFor: boolean;
  claimBatchNumber?: number; // The batch number is always present in a claim and only for a claim. It always is for a IOasisTransactionType.Treatment. It also correlates to the claimNumber.
  claimReference?: string; // The transaction output from the terminal.
  claimStatusCode?: string; // Give a status response from cliams such as "01/Nil Benefit Payable on this Item".
  claimNumber?: string;
  claimKey?: string; // Looks to be a combination of the patientId twice in a row, followed by the claimBatchNumber.

  statementNumber?: number;

  isDeleted: boolean;
  deletedAt?: ISODateTimeType;

  locationId?: number;
  userId: number;
  userTime: ISOTimeType;
  invoiceNote?: string;

  description2?: string;

  amount?: number; // This is the sum of the different payment types below.
  paidAmount?: number; // This is never set when the value of any amounts below are set. It looks like it's only set on treatments to show how much of them have been paid.
  isPaid: boolean;
  thirdPartyAmount?: number;

  gstAmount?: number;
  gstNumber?: number; // Not clear what this is used for. If we have the gstAmount then we have all that we need.
  showInGst: boolean; // Whether to show in GST report. Seems to be linked to having a gstNumber.
}

export function isOasisPatientTreatment(
  item: unknown
): item is IOasisPatientTreatment {
  return TypeGuard.interface<IOasisPatientTreatment>({
    id: isNumber,
    transactionGroupId: TypeGuard.nilOr(isString),
    appointmentDate: isISODateType,
    createdAt: isISODateType,
    itemCodeDescription: isString,
    itemCode: isString,
    toothNumber: TypeGuard.nilOr(isToothNumber),
    minutes: TypeGuard.nilOr(isNumber),
    quantity: TypeGuard.nilOr(isNumber),
    accountPatientId: TypeGuard.nilOr(isNumber),
    patientId: TypeGuard.nilOr(isNumber),
    patientName: TypeGuard.nilOr(isString),
    accountThirdPartyId: TypeGuard.nilOr(isNumber),
    thirdPartyId: TypeGuard.nilOr(isNumber),
    practitionerId: isNumber,
    billedToPractitionerId: isNumber,
    wasClaimedFor: isBoolean,
    claimBatchNumber: TypeGuard.nilOr(isNumber),
    claimReference: TypeGuard.nilOr(isString),
    claimStatusCode: TypeGuard.nilOr(isString),
    claimNumber: TypeGuard.nilOr(isString),
    claimKey: TypeGuard.nilOr(isString),
    statementNumber: TypeGuard.nilOr(isNumber),
    isDeleted: isBoolean,
    deletedAt: TypeGuard.nilOr(isISODateTimeType),
    locationId: TypeGuard.nilOr(isNumber),
    userId: isNumber,
    userTime: isISOTimeType,
    invoiceNote: TypeGuard.nilOr(isString),
    description2: TypeGuard.nilOr(isString),
    amount: TypeGuard.nilOr(isNumber),
    paidAmount: TypeGuard.nilOr(isNumber),
    isPaid: isBoolean,
    thirdPartyAmount: TypeGuard.nilOr(isNumber),
    gstAmount: TypeGuard.nilOr(isNumber),
    gstNumber: TypeGuard.nilOr(isNumber),
    showInGst: isBoolean,
  })(item);
}

export interface IOasisPatientTreatmentTranslations {
  createdAt: Timestamp;
  appointmentDate: ISODateType;
  deletedAt?: Timestamp;
}

export interface IOasisPatientTreatmentFilters {
  practitionerId: number;
  billedToPractitionerId: number;
  appointmentDate: ISODateType;
  accountPatientId?: number;
  accountThirdPartyId?: number;
  patientId?: number;
}

const PATIENT_TREATMENT_SOURCE_QUERY = `
SELECT
  SEQNUMBER AS id,
  NULLIF(RUNNUMBER::TEXT, 'NULL') AS transaction_group_id,

  CASE
    WHEN convert_to_integer(ACCNUMBER) < 99000
      THEN convert_to_integer(ACCNUMBER)
    ELSE NULL
  END AS account_patient_id,
  convert_to_integer(PATNUMBER) AS patient_id,

  CASE
    WHEN convert_to_integer(ACCNUMBER) >= 99000
      THEN convert_to_integer(ACCNUMBER)
    ELSE NULL
  END AS account_third_party_id,
  NULLIF(convert_to_integer(THIRDPARTYNUMBER), 0) AS third_party_id,
  PATIENTNAME AS patient_name,

  CASE
    WHEN strpos (CONSULTDATE, '/') > 0
      THEN to_char(convert_to_date(CONSULTDATE, 'DD/MM/YYYY'), 'YYYY-MM-DD')
    ELSE CONSULTDATE
  END AS appointment_date,

  CASE
    WHEN strpos(ENTRYDATE, '/') > 0
      THEN to_char(convert_to_date(ENTRYDATE, 'DD/MM/YYYY'), 'YYYY-MM-DD')
    ELSE ENTRYDATE
  END AS created_at,

  convert_to_decimal(AMOUNT) AS amount,
  convert_to_decimal(PAID) AS paid_amount,
  CASE
    WHEN convert_to_decimal(AMOUNT) = convert_to_decimal(PAID)
      THEN TRUE
    ELSE FALSE
  END AS is_paid,
  convert_to_decimal(THIRDPARTYAMOUNT) AS third_party_amount,

  MINUTES as minutes,
  DESCRIPTION1 AS item_code_description,
  ITEMCODE AS item_code,
  convert_to_integer(FREQUENCY) AS quantity,
  CASE
    WHEN TRANSTYPE = 1
      THEN NULLIF(NULLIF(REFERENCE::TEXT, ''), '0')
    ELSE NULL
  END AS tooth_number,

  NULLIF(STATEMENTNUMBER, 0) AS statement_number,
  NULLIF(DESCRIPTION2, '') AS description_2,
  NULLIF(COMMENT, '') AS invoice_note,

  CONSULTDRNUMBER AS practitioner_id,
  BILLDRNUMBER AS billed_to_practitioner_id,

  CASE WHEN DELETED = -1
    THEN TRUE
    ELSE FALSE
  END AS is_deleted,
  CASE
    WHEN strpos(DELETEDATE, '-') > 0
      THEN to_char(convert_to_date(DELETEDATE, 'YYYY-MM-DD')
      +
      CASE
		    WHEN DELETETIME ~ '^\\d{1,2}:\\d{2}:\\d{2} (AM|PM)$'
		      THEN NULLIF(TRIM(DELETETIME), '')::TIME
		    ELSE
		      NULL
		  END,
      'YYYY-MM-DD HH:MI:SS')
    ELSE to_char(
      convert_to_date(DELETEDATE, 'DD/MM/YYYY')
      +
      CASE
		    WHEN DELETETIME ~ '^\\d{1,2}:\\d{2}:\\d{2} (AM|PM)$'
		      THEN NULLIF(TRIM(DELETETIME), '')::TIME
		    ELSE
		      NULL
		  END,
      'YYYY-MM-DD HH:MI:SS'
    )
  END AS deleted_at,

  USERNUMBER AS user_id,
  NULLIF(NULLIF(USERTIME, ''), 'NULL')::TIME AS user_time,
  convert_to_integer(LOCNO) AS location_id,

  convert_to_decimal(GSTAMOUNT) AS gst_amount,
  convert_to_boolean(SHOWINGST) AS show_in_gst,
  NULLIF(GSTNUMBER, 99999999) AS gst_number,

  CASE
    WHEN
      NULLIF(HICAPSFLAG::TEXT, 'NULL') IS NULL
      OR convert_to_integer(HICAPSFLAG) = 0
    THEN FALSE
    ELSE TRUE
  END AS was_claimed_for,
  NULLIF(BATCHNUMBER, 0) AS claim_batch_number,
  HICAPSREFERENCE AS claim_reference,
  CASE
    WHEN
      NULLIF(HICAPSFLAG::TEXT, 'NULL') IS NOT NULL
      AND convert_to_integer(HICAPSFLAG) != 0
    THEN NULLIF(DESCRIPTION4, '')
    ELSE NULL
  END AS claim_status_code,
  NULLIF(HICAPSCLAIMNUMBER::TEXT, 'NULL') AS claim_number,
  NULLIF(STATEKEY, '') AS claim_key
FROM PBARCMAS
WHERE
  convert_to_integer(TRANSTYPE) = 1
`;

export class PatientTreatmentsSourceEntity extends BaseSourceEntity<
  IOasisPatientTreatment,
  IOasisPatientTreatmentTranslations,
  IOasisPatientTreatmentFilters
> {
  sourceEntity = PATIENT_TREATMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_TREATMENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_TREATMENT_SOURCE_QUERY;
  verifySourceFn = isOasisPatientTreatment;
  override defaultOffsetSize = 25000;
  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertDateToTimestampFn(),
    convertValueFn(
      toFloat,
      'amount',
      'paidAmount',
      'thirdPartyAmount',
      'gstAmount'
    ),
    convertValueFn(
      (value) => (isToothNumber(value) ? value : undefined),
      'toothNumber'
    ),
  ]);

  translate(
    data: IOasisPatientTreatment,
    timezone: Timezone
  ): IOasisPatientTreatmentTranslations {
    return {
      createdAt: toTimestamp(moment.tz(data.createdAt, timezone)),
      appointmentDate: toISODate(moment.tz(data.appointmentDate, timezone)),
      deletedAt: data.deletedAt
        ? toTimestamp(moment.tz(data.deletedAt, timezone))
        : undefined,
    };
  }

  getSourceRecordId(data: IOasisPatientTreatment): number {
    return data.id;
  }

  getSourceLabel(data: IOasisPatientTreatment): string {
    return data.id.toString();
  }

  getFilterData(
    data: IOasisPatientTreatment,
    timezone: Timezone
  ): IOasisPatientTreatmentFilters {
    return {
      practitionerId: data.practitionerId,
      billedToPractitionerId: data.billedToPractitionerId,
      accountPatientId: data.accountPatientId,
      patientId: data.patientId,
      accountThirdPartyId: data.accountThirdPartyId,
      appointmentDate: toISODate(moment.tz(data.appointmentDate, timezone)),
    };
  }
}
