import { NgModule, type ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { FeatureEnabledDirective } from './feature-enabled.directive';
import { FeatureInfoComponent } from './feature-info/feature-info.component';
import { type INgFeatureFlagsConfig, NG_FEATURE_FLAGS_CONFIG } from './config';
import { FeatureFlagsService } from './feature-flags.service';

@NgModule({
  imports: [CommonModule, NgMaterialModule, FeatureInfoComponent],
  declarations: [FeatureEnabledDirective],
  providers: [FeatureFlagsService],
  exports: [FeatureEnabledDirective, FeatureInfoComponent],
})
export class NgFeatureFlagsModule {
  static forRoot(
    config: INgFeatureFlagsConfig
  ): ModuleWithProviders<NgFeatureFlagsModule> {
    return {
      ngModule: NgFeatureFlagsModule,
      providers: [
        {
          provide: NG_FEATURE_FLAGS_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
