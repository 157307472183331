import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  Patient,
  PatientMetadataDisplay,
} from '@principle-theorem/principle-core';
import {
  IPatient,
  IPatientMetadataDisplay,
  PatientMetadataDisplayTarget,
  PatientMetadataType,
  PatientMetadataValue,
  SYSTEM_METADATA_CONFIGURATIONS,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, toInt } from '@principle-theorem/shared';
import { compact } from 'lodash';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { PatientMetadataDisplayComponent } from '../patient-metadata-display/patient-metadata-display.component';
import { GlobalStoreService } from '../../../store/global-store.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

interface IMetadataDisplayElement {
  display: WithRef<IPatientMetadataDisplay>;
  value?: PatientMetadataValue;
}

@Component({
    selector: 'pr-patient-metadata-display-outlet',
    templateUrl: './patient-metadata-display-outlet.component.html',
    styleUrls: ['./patient-metadata-display-outlet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, PatientMetadataDisplayComponent]
})
export class PatientMetadataDisplayOutletComponent {
  private _displayTarget$ = new ReplaySubject<PatientMetadataDisplayTarget>(1);
  private _patient$ = new ReplaySubject<IPatient>(1);
  elements$: Observable<IMetadataDisplayElement[]>;

  @Input()
  set displayTarget(displayTarget: PatientMetadataDisplayTarget) {
    if (displayTarget) {
      this._displayTarget$.next(displayTarget);
    }
  }

  @Input()
  set patient(patient: IPatient) {
    if (patient) {
      this._patient$.next(patient);
    }
  }

  constructor(globalStore: GlobalStoreService) {
    const activeDisplays$ = combineLatest([
      this._displayTarget$,
      globalStore.patientMetadataDisplays$,
    ]).pipe(
      map(([displayTarget, displays]) =>
        PatientMetadataDisplay.filterByTarget(displays, displayTarget)
      )
    );
    this.elements$ = combineLatest([activeDisplays$, this._patient$]).pipe(
      map(([displays, patient]) =>
        this._toMetadataDisplayElements(displays, patient)
      )
    );
  }

  private _toMetadataDisplayElements(
    displays: WithRef<IPatientMetadataDisplay>[],
    patient: IPatient
  ): IMetadataDisplayElement[] {
    const elements = displays.map((display) => {
      const value = Patient.getMetadata(patient, display.metadataKey);
      const metadataConfig = SYSTEM_METADATA_CONFIGURATIONS.find(
        (systemConfig) => display.metadataKey.startsWith(`${systemConfig.key}.`)
      );

      const format = metadataConfig?.type;

      if (display.onlyShowIfHasValue && !value) {
        return;
      }

      if (format === PatientMetadataType.Boolean) {
        return { display, value: coerceBooleanProperty(value) };
      }

      if (format === PatientMetadataType.Number) {
        return { display, value: !value ? 0 : toInt(value) };
      }

      return { display, value };
    });
    return compact(elements);
  }
}
