import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { getSchemaText } from '@principle-theorem/editor';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  AutomatedNotification,
  AutomationConfiguration,
  GeneratedTask,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  AutomationCreator,
  isAutomatedNotificationConfiguration,
  type IAutomatedNotification,
  type IAutomation,
  type IAutomationConfiguration,
  type IBrand,
  type IEvent,
  type IGeneratedTask,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  asDocRef,
  filterUndefined,
  nameSorter,
  snapshot,
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { from, type Observable } from 'rxjs';
import { concatMap, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  GeneratedTaskDialogComponent,
  type IGeneratedTaskDialogData,
  type IGeneratedTaskReturnData,
} from '../components/generated-task-dialog/generated-task-dialog.component';
import {
  NotificationDialogComponent,
  type IAutomatedNotificationDialogData,
  type IAutomatedNotificationReturnData,
} from '../components/notification-dialog/notification-dialog.component';
import {
  ISelectAutomationTemplateResponse,
  SelectAutomationConfigurationDialogComponent,
  type ISelectAutomationTemplateRequest,
} from '../components/select-automation-configuration-dialog/select-automation-configuration-dialog.component';

@Injectable()
export class AutomationDialogService {
  constructor(
    private _dialog: MatDialog,
    private _organisation: OrganisationService
  ) {}

  async selectConfiguration(
    request: ISelectAutomationTemplateRequest
  ): Promise<ISelectAutomationTemplateResponse | undefined> {
    return this._dialog
      .open<
        SelectAutomationConfigurationDialogComponent,
        ISelectAutomationTemplateRequest,
        ISelectAutomationTemplateResponse
      >(
        SelectAutomationConfigurationDialogComponent,
        DialogPresets.medium({ height: '80%', data: request })
      )
      .afterClosed()
      .toPromise();
  }

  async openTaskDialog(
    data: IGeneratedTaskDialogData
  ): Promise<IGeneratedTaskReturnData | undefined> {
    return this._dialog
      .open<
        GeneratedTaskDialogComponent,
        IGeneratedTaskDialogData,
        IGeneratedTaskReturnData
      >(
        GeneratedTaskDialogComponent,
        DialogPresets.large({
          autoFocus: true,
          height: '80%',
          data,
        })
      )
      .afterClosed()
      .toPromise();
  }

  async openTaskAdd(
    event: IEvent,
    triggerAfterDate?: Timestamp
  ): Promise<IAutomation<IGeneratedTask> | undefined> {
    const generatedTask = await this.openTaskDialog({
      triggerDate: event.from,
      useRelativeTime: true,
    });
    return this._toTaskAutomation(event, triggerAfterDate, generatedTask);
  }

  async openTaskEdit(
    task: IGeneratedTask,
    event: IEvent,
    triggerDate?: Timestamp,
    triggerAfterDate?: Timestamp,
    useRelativeTime: boolean = true
  ): Promise<IAutomation<IGeneratedTask> | undefined> {
    const generatedTask = await this.openTaskDialog({
      task,
      triggerDate,
      useRelativeTime,
    });
    return this._toTaskAutomation(event, triggerAfterDate, generatedTask);
  }

  async openNotificationDialog(
    data: IAutomatedNotificationDialogData,
    event: IEvent,
    triggerAfterDate?: Timestamp
  ): Promise<IAutomation<IAutomatedNotification> | undefined> {
    const automatedNotification = await this._dialog
      .open<
        NotificationDialogComponent,
        IAutomatedNotificationDialogData,
        IAutomatedNotificationReturnData
      >(
        NotificationDialogComponent,
        DialogPresets.large({ height: '80%', data })
      )
      .afterClosed()
      .toPromise();
    return this._toNotificationAutomation(
      event,
      triggerAfterDate,
      automatedNotification
    );
  }

  openFromTemplateDialog(
    practiceRef: DocumentReference<IPractice>,
    event: IEvent
  ): Observable<
    | IAutomation<IAutomatedNotification>
    | IAutomation<IGeneratedTask>
    | undefined
  > {
    return from(this._getAutomationConfigs(practiceRef)).pipe(
      switchMap((request) => this.selectConfiguration(request)),
      withLatestFrom(this._organisation.staffer$.pipe(filterUndefined())),
      concatMap(async ([response, staffer]) => {
        if (!response) {
          return;
        }
        const brand = await snapshot(
          this._organisation.brand$.pipe(filterUndefined())
        );
        const creator = {
          ...stafferToNamedDoc(staffer),
          type: AutomationCreator.Staffer,
        };
        if (isAutomatedNotificationConfiguration(response.config)) {
          return AutomatedNotification.generateFromConfig(
            response.config,
            creator,
            event,
            brand.ref
          );
        }
        return GeneratedTask.generateFromConfig(
          response.config,
          creator,
          event,
          brand.ref
        );
      })
    );
  }

  private async _toNotificationAutomation(
    event: IEvent,
    triggerAfterDate?: Timestamp,
    automatedNotification?: IAutomatedNotificationReturnData
  ): Promise<IAutomation<IAutomatedNotification> | undefined> {
    if (!automatedNotification) {
      return;
    }
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const brandRef = asDocRef<IBrand>(Firestore.getParentDocRef(staffer.ref));
    const automation = await AutomatedNotification.toAutomation(
      automatedNotification,
      {
        ...stafferToNamedDoc(staffer),
        type: AutomationCreator.Staffer,
      },
      event,
      brandRef,
      automatedNotification.configRef
    );
    if (automatedNotification.triggerDate) {
      automation.triggerDate = automatedNotification.triggerDate;
    }

    automation.triggerAfterDate = triggerAfterDate;
    return automation;
  }

  private async _toTaskAutomation(
    event: IEvent,
    triggerAfterDate?: Timestamp,
    generatedTask?: IGeneratedTaskReturnData
  ): Promise<IAutomation<IGeneratedTask> | undefined> {
    if (!generatedTask) {
      return;
    }
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const brand = await snapshot(
      this._organisation.brand$.pipe(filterUndefined())
    );
    const automation = await GeneratedTask.toAutomation(
      generatedTask,
      {
        ...stafferToNamedDoc(staffer),
        type: AutomationCreator.Staffer,
      },
      event,
      brand.ref
    );
    if (generatedTask.triggerDate) {
      automation.triggerDate = generatedTask.triggerDate;
    }
    automation.triggerAfterDate = triggerAfterDate;
    return automation;
  }

  private async _getAutomationConfigs(
    practiceRef: DocumentReference<IPractice>
  ): Promise<
    Pick<
      ISelectAutomationTemplateRequest,
      'notificationConfigs' | 'taskConfigs'
    >
  > {
    const brand = await snapshot(this._organisation.brand$);
    if (!brand) {
      return { notificationConfigs: [], taskConfigs: [] };
    }

    const tasks = await snapshot(
      AutomationConfiguration.getGeneratedTaskConfigurations$(brand)
    );
    const filteredTasks = tasks.filter((config) =>
      automationConfigIsAvailable(config, practiceRef)
    );
    const taskConfigs = sortBy(filteredTasks, (config) =>
      getSchemaText(config.title)
    );

    const notifications = await snapshot(
      AutomationConfiguration.getNotificationConfigurations$(brand)
    );
    const filteredNotifications = notifications.filter((config) =>
      automationConfigIsAvailable(config, practiceRef)
    );
    const notificationConfigs = sortBy(filteredNotifications, nameSorter());

    return { notificationConfigs, taskConfigs };
  }
}

function automationConfigIsAvailable(
  config: IAutomationConfiguration,
  practiceRef: DocumentReference<IPractice>
): boolean {
  return (
    config.isActive &&
    AutomationConfiguration.isGlobal(config) &&
    AutomationConfiguration.isForPractice(config, practiceRef)
  );
}
