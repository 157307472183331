import { Quadrant } from '@principle-theorem/principle-core/interfaces';

export function isAdultQuadrant(quadrant: Quadrant): boolean {
  switch (quadrant) {
    case Quadrant.AdultUpperRight:
    case Quadrant.AdultUpperLeft:
    case Quadrant.AdultLowerLeft:
    case Quadrant.AdultLowerRight:
      return true;
    default:
      return false;
  }
}

export function isUpperQuadrant(quadrant: Quadrant): boolean {
  switch (quadrant) {
    case Quadrant.DeciduousUpperRight:
    case Quadrant.DeciduousUpperLeft:
    case Quadrant.AdultUpperRight:
    case Quadrant.AdultUpperLeft:
      return true;
    default:
      return false;
  }
}

export function isRightQuadrant(quadrant: Quadrant): boolean {
  switch (quadrant) {
    case Quadrant.DeciduousUpperRight:
    case Quadrant.DeciduousLowerRight:
    case Quadrant.AdultUpperRight:
    case Quadrant.AdultLowerRight:
      return true;
    default:
      return false;
  }
}

export function getAdultFromDeciduous(quadrant: Quadrant): Quadrant {
  switch (quadrant) {
    case Quadrant.DeciduousUpperRight:
      return Quadrant.AdultUpperRight;
    case Quadrant.DeciduousUpperLeft:
      return Quadrant.AdultUpperLeft;
    case Quadrant.DeciduousLowerLeft:
      return Quadrant.AdultLowerLeft;
    case Quadrant.DeciduousLowerRight:
      return Quadrant.AdultLowerRight;
    default:
      return quadrant;
  }
}
