import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { PatientDocumentPrintComponent } from './components/patient-document/patient-document-print/patient-document-print.component';
import { PatientDocumentResolverService } from './components/patient-document/patient-document-resolver.service';
import { PatientDocumentComponent } from './components/patient-document/patient-document.component';
import { PatientDocumentsComponent } from './components/patient-documents/patient-documents.component';
import { NgPatientModule } from './ng-patient.module';

const routes: Routes = [
  {
    path: '',
    component: PatientDocumentsComponent,
    data: { titleSuffix: 'Documents' },
  },
  {
    path: ':uid',
    resolve: {
      patientDocument: PatientDocumentResolverService,
    },
    children: [
      {
        path: '',
        component: PatientDocumentComponent,
        data: { titleSuffix: 'Documents' },
      },
      {
        path: 'print',
        component: PatientDocumentPrintComponent,
        data: { titleSuffix: 'Print' },
      },
    ],
  },
];

@NgModule({
  imports: [NgPatientModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NgPatientDocumentsRoutingModule {}
