import { Invoice, MedicareClaimType } from '@principle-theorem/principle-core';
import {
  IHealthcareClaim,
  IInvoice,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  TransactionHelpers,
  hasValidServiceDate$,
} from '../../transaction-helpers';

export class MedicareClaimTypeHelpers {
  static getDefinition(
    claimType: MedicareClaimType
  ): IMedicareClaimTypeDefinition {
    const claimTypeMap: Record<
      MedicareClaimType,
      IMedicareClaimTypeDefinition
    > = {
      [MedicareClaimType.BulkBill]: BULK_BILL_CLAIM_TYPE_DEFINITION,
      [MedicareClaimType.FullyPaid]: FULLY_PAID_CLAIM_TYPE_DEFINITION,
      [MedicareClaimType.PartPaid]: PART_PAID_CLAIM_TYPE_DEFINITION,
      [MedicareClaimType.Unpaid]: UNPAID_CLAIM_TYPE_DEFINITION,
    };
    return claimTypeMap[claimType];
  }
}

export interface IMedicareClaimTypeDefinition {
  claimType: MedicareClaimType;
  label: string;
  canClaim$: (
    invoice: WithRef<IInvoice>,
    claim?: IHealthcareClaim
  ) => Observable<boolean>;
  info: string;
  disabledReason: string;
}

const BULK_BILL_CLAIM_TYPE_DEFINITION: IMedicareClaimTypeDefinition = {
  claimType: MedicareClaimType.BulkBill,
  label: 'Bulk Bill',
  info: 'Only available before any transactions have been taken. Benefit paid to practice.',
  disabledReason:
    'A transaction has already been taken on this invoice or the treatment date is in the future.',
  canClaim$: (invoice: WithRef<IInvoice>, _claim?: IHealthcareClaim) =>
    combineLatest([
      of(Invoice.canAddTransactions(invoice)),
      TransactionHelpers.hasReceivedPayment$(invoice),
      hasValidServiceDate$(invoice),
    ]).pipe(
      map(
        ([canAddTransactions, hasReceivedPayment, hasValidServiceDate]) =>
          canAddTransactions && !hasReceivedPayment && hasValidServiceDate
      )
    ),
};

const FULLY_PAID_CLAIM_TYPE_DEFINITION: IMedicareClaimTypeDefinition = {
  claimType: MedicareClaimType.FullyPaid,
  label: 'Fully Paid',
  info: 'Patient has paid their account in full. Benefit paid to patient.',
  disabledReason:
    'Invoice has not been paid in full or the treatment date is in the future.',
  canClaim$: (invoice: WithRef<IInvoice>, _claim?: IHealthcareClaim) =>
    combineLatest([
      of(Invoice.isPaid(invoice)),
      hasValidServiceDate$(invoice),
    ]).pipe(
      map(
        ([invoiceIsPaid, hasValidServiceDate]) =>
          invoiceIsPaid && hasValidServiceDate
      )
    ),
};

const PART_PAID_CLAIM_TYPE_DEFINITION: IMedicareClaimTypeDefinition = {
  claimType: MedicareClaimType.PartPaid,
  label: 'Part Paid',
  info: 'Patient has paid a contribution toward the settlement of the account.',
  disabledReason:
    'No payment has been taken, the invoice is fully paid  or the treatment date is in the future.',
  canClaim$: (invoice: WithRef<IInvoice>, _claim?: IHealthcareClaim) =>
    combineLatest([
      of(Invoice.isPaid(invoice)),
      TransactionHelpers.hasReceivedPayment$(invoice),
      hasValidServiceDate$(invoice),
    ]).pipe(
      map(
        ([invoiceIsPaid, hasReceivedPayment, hasValidServiceDate]) =>
          hasReceivedPayment && !invoiceIsPaid && hasValidServiceDate
      )
    ),
};

const UNPAID_CLAIM_TYPE_DEFINITION: IMedicareClaimTypeDefinition = {
  claimType: MedicareClaimType.Unpaid,
  label: 'Unpaid',
  info: `Patient hasn't paid the account. Cheque given to patient for the practitioner.`,
  disabledReason:
    'A transaction has already been taken on this invoice or the treatment date is in the future.',
  canClaim$: (invoice: WithRef<IInvoice>, _claim?: IHealthcareClaim) =>
    combineLatest([
      of(Invoice.canAddTransactions(invoice)),
      TransactionHelpers.hasReceivedPayment$(invoice),
      hasValidServiceDate$(invoice),
    ]).pipe(
      map(
        ([canAddTransactions, hasReceivedPayment, hasValidServiceDate]) =>
          canAddTransactions && !hasReceivedPayment && hasValidServiceDate
      )
    ),
};
