<pr-chat-list [preventRouteChange]="true" (chatSelected)="showChat($event)" />
<mat-divider />
<a
  class="m-2"
  mat-button
  mat-dialog-close
  [prPracticeRouterLink]="['chats']"
  color="primary"
  >See all in Team Chats</a
>
