import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Brand } from '@principle-theorem/principle-core';
import {
  type IBrand,
  type IReferralSourceConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

interface IReferralSourceConfigurationsState {
  brand: WithRef<IBrand>;
  referralSources: WithRef<IReferralSourceConfiguration>[];
}

@Injectable()
export class ReferralSourcesStore extends ComponentStore<IReferralSourceConfigurationsState> {
  readonly brand$ = this.select((store) => store.brand);
  readonly referralSources$ = this.select((store) => store.referralSources);

  readonly loadBrand = this.effect((brand$: Observable<WithRef<IBrand>>) => {
    return brand$.pipe(
      tap((brand) => {
        this.setState({ brand, referralSources: [] });
        this.loadReferralSources(Brand.referralSources$(brand));
      })
    );
  });

  readonly loadReferralSources = this.effect(
    (referralSources$: Observable<WithRef<IReferralSourceConfiguration>[]>) =>
      referralSources$.pipe(
        tap((referralSources) => this.patchState({ referralSources }))
      )
  );
}
