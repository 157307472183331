<ng-container *ngrxLet="selected$ as selectedPermissions">
  <mat-selection-list
    #list
    [compareWith]="compareFn"
    (selectionChange)="updateSelectedPermissions($event.source.selectedOptions)"
  >
    <ng-container
      *ngFor="
        let group of groups;
        trackBy: trackByPermissionGroup;
        last as isLast
      "
    >
      <div class="permission-group">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h3 class="mat-headline-6">
            {{ group.name | splitKebab | titlecase }}
          </h3>

          <ng-container
            *ngIf="
              selectedPermissions | map : group.isChecked : group;
              else notSelected
            "
          >
            <a href="javascript:void(0);" (click)="selectGroup(group, false)">
              Deselect all
            </a>
          </ng-container>

          <ng-template #notSelected>
            <a href="javascript:void(0);" (click)="selectGroup(group, true)">
              Select all
            </a>
          </ng-template>
        </div>
      </div>
      <mat-list-option
        *ngFor="
          let permission of group.children;
          trackBy: trackByPermissionDisplay
        "
        [selected]="
          selectedPermissions | map : permission.isChecked : permission
        "
        [value]="permission.value"
        [disabled]="isDisabled"
      >
        {{ permission.name }}
      </mat-list-option>
      <mat-divider *ngIf="!isLast" />
    </ng-container>
  </mat-selection-list>
</ng-container>
