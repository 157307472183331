<pt-buttons-container>
  <button mat-flat-button color="primary" (click)="upsert()">
    New Checklist Item
  </button>
</pt-buttons-container>
<mat-list>
  <div mat-subheader>Pre Appointment</div>
  <ng-container
    *ngIf="preAppointmentChecklist$ | async as preAppointmentChecklist"
  >
    <pr-checklist-items
      *ngIf="preAppointmentChecklist.length; else noPreItems"
      [checklist]="preAppointmentChecklist"
      (updateItem)="upsert($event)"
      (deleteItem)="delete($event)"
    />
  </ng-container>

  <ng-template #noPreItems>
    <mat-list-item>
      <small class="mat-caption">No pre-appointment items</small>
    </mat-list-item>
  </ng-template>
</mat-list>

<mat-list>
  <div mat-subheader>Post Appointment</div>
  <ng-container
    *ngIf="postAppointmentChecklist$ | async as postAppointmentChecklist"
  >
    <pr-checklist-items
      *ngIf="postAppointmentChecklist.length; else noPostItems"
      [checklist]="postAppointmentChecklist"
      (updateItem)="upsert($event)"
      (deleteItem)="delete($event)"
    />
  </ng-container>

  <ng-template #noPostItems>
    <mat-list-item>
      <small class="mat-caption">No post-appointment items</small>
    </mat-list-item>
  </ng-template>
</mat-list>
