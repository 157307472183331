<ng-container *ngIf="labJob$ | async as labJob">
  <mat-toolbar
    color="accent"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <h2 class="title truncate">
      <pr-content-view [content]="labJob.title" />
    </h2>
    <pr-lab-job-status-workflow
      type="flat"
      color="primary"
      [labJob]="labJob"
     />
  </mat-toolbar>

  <mat-drawer-container fxFlex>
    <mat-drawer-content>
      <pr-interactions
        fxFill
        [enableScroll]="true"
        [interactions]="labJob.interactions"
        (interactionAdded)="addInteraction($event)"
       />
    </mat-drawer-content>

    <mat-drawer mode="side" position="end" opened>
      <pr-lab-job-edit
        [practice]="practice$ | async"
        [labJob]="labJob"
        (cancelled)="cancelled.emit()"
       />
    </mat-drawer>
  </mat-drawer-container>
</ng-container>
