import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  inject,
  type OnDestroy,
  Output,
} from '@angular/core';
import { type MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  CurrentScopeFacade,
  GlobalStoreService,
  OrganisationService,
  TagType,
} from '@principle-theorem/ng-principle-shared';
import {
  formControlChanges$,
  InputSearchFilter,
  MOMENT_DATEPICKER_PROVIDERS,
  ProfileImageService,
  toSearchStream,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  Brand,
  Event,
  Practice,
  Staffer,
  stafferToNamedDoc,
  stafferToParticipant,
} from '@principle-theorem/principle-core';
import {
  EVENT_TYPE_COLOUR_MAP,
  EventType,
  type IBrand,
  type ICalendarEvent,
  type IPractice,
  type IStaffer,
  type ITag,
  type ITreatmentCategory,
  type IUser,
} from '@principle-theorem/principle-core/interfaces';
import { type CollectionReference } from '@principle-theorem/shared';
import {
  errorGuard,
  filterUndefined,
  type INamedDocument,
  isArray,
  isSameRef,
  multiFilter,
  multiFind,
  multiMap,
  multiSwitchMap,
  snapshot,
  TIME_FORMAT_24HR,
  type WithRef,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { type Moment } from 'moment-timezone';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import {
  CalendarEventFormGroup,
  type ICalendarEventFormData,
} from './calendar-event.formgroup';
import { colord } from 'colord';

@Component({
    selector: 'pr-calendar-event-form',
    templateUrl: './calendar-event-form.component.html',
    styleUrls: ['./calendar-event-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'prCalendarEventForm',
    providers: [...MOMENT_DATEPICKER_PROVIDERS],
    standalone: false
})
export class CalendarEventFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _practice$: Observable<WithRef<IPractice>>;
  profileImage = inject(ProfileImageService);
  trackByEventType = TrackByFunctions.variable<EventType>();
  trackByPractice = TrackByFunctions.ref<WithRef<IPractice>>();
  trackByStaffer = TrackByFunctions.ref<IStaffer>('user.ref');
  trackByParticipant = TrackByFunctions.ref<WithRef<IUser>>();
  trackByCategory = TrackByFunctions.ref<WithRef<ITreatmentCategory>>();
  form = new CalendarEventFormGroup();
  staffSearchFilter: InputSearchFilter<IStaffer>;
  eventTypes: EventType[] = [
    EventType.Meeting,
    EventType.Break,
    EventType.PreBlock,
    EventType.Leave,
    EventType.Misc,
    EventType.RosteredOn,
    EventType.PracticeClosed,
  ];
  brand$: Observable<WithRef<IBrand>>;
  tagCol$: Observable<CollectionReference<ITag>>;
  practices$: Observable<WithRef<IPractice>[]>;
  staff$: Observable<WithRef<IStaffer>[]>;
  isMeeting$: Observable<boolean>;
  isLeave$: Observable<boolean>;
  canBeBlocking$: Observable<boolean>;
  participants$ = new ReplaySubject<WithRef<IUser>[]>(1);
  canHaveMultipleParticipants$: Observable<boolean>;
  canHaveSingleParticipant$: Observable<boolean>;
  isPreBlock$: Observable<boolean>;
  minimumEndDate$ = new ReplaySubject<Moment>(1);
  hiddenFields$ = new BehaviorSubject<(keyof ICalendarEventFormData)[]>([]);
  disabledFields$ = new BehaviorSubject<(keyof ICalendarEventFormData)[]>([]);
  saving$ = new BehaviorSubject<boolean>(false);
  allowedCategoryColours$ = new BehaviorSubject<string[]>([]);
  defaultPreBlockColour$ = new BehaviorSubject<string>(
    EVENT_TYPE_COLOUR_MAP[EventType.PreBlock]
  );
  tagType = TagType.Event;

  @Input()
  set hiddenFields(hiddenFields: (keyof ICalendarEventFormData)[]) {
    if (hiddenFields) {
      this.hiddenFields$.next(hiddenFields);
    }
  }

  @Input()
  set disabledFields(disabledFields: (keyof ICalendarEventFormData)[]) {
    if (disabledFields) {
      this.disabledFields$.next(disabledFields);
    }
  }

  @Output() submitted = new EventEmitter<ICalendarEvent>();

  @Input()
  set calendarEvent(event: ICalendarEvent) {
    if (event) {
      void this.form.updateControls(event);
    }
  }

  @Input()
  set participatingStaffer(staffer: WithRef<IStaffer>) {
    if (!staffer) {
      return;
    }
    this.form.addParticipant(staffer);
  }

  constructor(
    private _organisation: OrganisationService,
    private _currentScopeFacade: CurrentScopeFacade,
    public globalStore: GlobalStoreService
  ) {
    this.brand$ = this._organisation.brand$.pipe(filterUndefined());
    this.tagCol$ = this.brand$.pipe(map((brand) => Brand.eventTagCol(brand)));
    this.staff$ = formControlChanges$(this.form.controls.practice).pipe(
      switchMap((practice) =>
        practice
          ? Staffer.practitionersByPractice$(practice)
          : this._organisation.staff$
      )
    );
    this.practices$ = this._organisation.practices$;
    this._practice$ =
      this._currentScopeFacade.currentPractice$.pipe(filterUndefined());

    combineLatest([
      this.practices$.pipe(take(1)),
      this._currentScopeFacade.currentPractice$,
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([practices, currentPractice]) => {
        const practice = currentPractice ?? first(practices);
        if (practice) {
          this.form.controls.practice.setValue(practice);
        }
      });

    this.isMeeting$ = this.form.isSelectedType$(EventType.Meeting);
    this.isLeave$ = this.form.isSelectedType$([
      EventType.Leave,
      EventType.PracticeClosed,
    ]);
    this.canHaveMultipleParticipants$ = this.form.isSelectedType$([
      EventType.Meeting,
      EventType.Misc,
    ]);
    this.canHaveSingleParticipant$ = this.form.isSelectedType$([
      EventType.Break,
      EventType.Leave,
      EventType.RosteredOn,
      EventType.PreBlock,
    ]);
    this.canBeBlocking$ = this.form.isNotSelectedType$([
      EventType.PreBlock,
      EventType.RosteredOn,
      EventType.PracticeClosed,
    ]);
    this.isPreBlock$ = this.form.isSelectedType$([EventType.PreBlock]);

    this.form.controls.type.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => {
        const isPracticeClosed = value === EventType.PracticeClosed;
        this.form.toggleParticipantsRequired(isPracticeClosed ? false : true);
      });

    formControlChanges$(this.form.controls.participants)
      .pipe(
        errorGuard<INamedDocument[]>(isArray),
        switchMap((docs) =>
          this.staff$.pipe(
            multiFilter((staffer: WithRef<IStaffer>) =>
              docs.some((doc) => isSameRef(doc, staffer))
            )
          )
        ),
        multiSwitchMap((staffer) => Staffer.user$(staffer)),
        catchError(() => of([])),
        takeUntil(this._onDestroy$)
      )
      .subscribe((value) => this.participants$.next(value));

    this.form
      .isSelectedType$([EventType.PreBlock, EventType.RosteredOn])
      .pipe(
        distinctUntilChanged(),
        filter((isNonBlockingEvent) => isNonBlockingEvent),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.form.controls.isBlocking.setValue(false));

    this.form
      .isSelectedType$([EventType.PracticeClosed])
      .pipe(
        distinctUntilChanged(),
        filter((isPracticeClosedEvent) => isPracticeClosedEvent),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.form.controls.isBlocking.setValue(true));

    this.form
      .isSelectedType$([
        EventType.PracticeClosed,
        EventType.Leave,
        EventType.RosteredOn,
      ])
      .pipe(
        distinctUntilChanged(),
        filter((isClosed) => isClosed),
        switchMap(() =>
          combineLatest([this.form.selectedPractice$(), this.practices$]).pipe(
            map(([selectedPractice, practices]) =>
              practices.find((practice) =>
                isSameRef(practice, selectedPractice)
              )
            )
          )
        ),
        filterUndefined(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((practice) => {
        if (
          this.form.controls.eventEndDate.pristine &&
          !this.form.controls.eventEndDate.value
        ) {
          this.form.controls.eventEndDate.setValue(
            this.form.controls.eventStartDate.value
          );
        }

        if (!this.form.controls.startTime.touched) {
          this.form.controls.startTime.setValue(
            Practice.openTime(practice).format(TIME_FORMAT_24HR)
          );
        }
        if (!this.form.controls.endTime.touched) {
          this.form.controls.endTime.setValue(
            Practice.closeTime(practice).format(TIME_FORMAT_24HR)
          );
        }
        if (!this.form.controls.isBlocking.touched) {
          this.form.controls.isBlocking.setValue(true);
        }
      });

    this.form.controls.eventStartDate.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((date) => {
        if (date) {
          this.minimumEndDate$.next(date);

          const endDate = this.form.controls.eventEndDate.value;
          if (!endDate || date.isAfter(endDate)) {
            this.form.controls.eventEndDate.setValue(date);
          }
        }
      });

    this.initSearchFilter();

    this.disabledFields$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((fields) =>
        fields.map((field) => this.form.controls[field].disable())
      );

    formControlChanges$(this.form.controls.allowedTreatmentCategories)
      .pipe(
        filterUndefined(),
        multiSwitchMap((categoryRef) =>
          this.globalStore
            .getTreatmentCategory$(categoryRef)
            .pipe(filterUndefined())
        ),
        multiMap((category) => colord(category.colour.value).toHex()),
        takeUntil(this._onDestroy$)
      )
      .subscribe((colours) => {
        if (!colours.length) {
          this.form.controls.colourOverride.reset();
        }
        if (colours.length === 1) {
          this.form.controls.colourOverride.setValue(colours[0]);
        }
        this.allowedCategoryColours$.next(colours);
      });

    formControlChanges$(this.form.controls.colourOverride)
      .pipe(
        withLatestFrom(
          this._practice$.pipe(
            map((practice) => practice.settings.eventColourOverrides?.preBlock)
          )
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([formColour, colourOverride]) =>
        this.defaultPreBlockColour$.next(
          formColour ??
            colourOverride ??
            EVENT_TYPE_COLOUR_MAP[EventType.PreBlock]
        )
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  initSearchFilter(): void {
    this.staffSearchFilter = new InputSearchFilter<IStaffer>(
      this.staff$,
      toSearchStream(this.form.controls.staffSearch),
      ['user.name']
    );
  }

  addParticipant(event: MatAutocompleteSelectedEvent): void {
    this.form.addParticipant(event.option.value as WithRef<IStaffer>);
  }

  fieldEnabled(field: keyof ICalendarEventFormData): Observable<boolean> {
    return this.hiddenFields$.pipe(
      map((hiddenFields) => !hiddenFields.includes(field))
    );
  }

  async removeParticipant(user: WithRef<IUser>): Promise<void> {
    const foundStaffer = await snapshot(
      this.staff$.pipe(
        multiFind((staffer: WithRef<IStaffer>) => isSameRef(staffer.user, user))
      )
    );
    if (!foundStaffer) {
      return;
    }
    this.form.removeParticipant(foundStaffer);
  }

  displayFn(staff: IStaffer): string {
    if (!staff) {
      return '';
    }
    return staff.user.name;
  }

  isSelectedNamedDocument(
    namedDocument: INamedDocument,
    selectedNamedDocument: INamedDocument
  ): boolean {
    try {
      return isSameRef(namedDocument, selectedNamedDocument);
    } catch (error) {
      return namedDocument === selectedNamedDocument;
    }
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    this.saving$.next(true);
    const creator = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const calendarEvent: ICalendarEvent = await this.form.toCalendarEvent(
      stafferToNamedDoc(creator)
    );

    if (calendarEvent.event.type === EventType.PracticeClosed) {
      const staff = await snapshot(
        this.staff$.pipe(multiMap(stafferToParticipant))
      );
      calendarEvent.event = Event.addParticipants(calendarEvent.event, staff);
    }
    this.submitted.next(calendarEvent);
  }
}
