import { MeasureFormatter } from '@principle-theorem/principle-core/interfaces';
import { ILabJobReceivedFact } from '@principle-theorem/reporting/interfaces';
import { BigQueryTable } from '../../big-query-tables';
import {
  BrandQueryScopeConfig,
  DateRangeQueryScopeConfig,
  PracticeQueryScopeConfig,
} from '../../query-scope-config/query-scope-configs';
import { BaseFactMeasures } from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import { AppointmentDimensionMeasures } from '../dimensions/appointment-dimension';
import { BrandDimensionMeasures } from '../dimensions/brand-dimension';
import { LabDimensionMeasures } from '../dimensions/lab-dimension';
import { LabJobDimensionMeasures } from '../dimensions/lab-job-dimension';
import { PatientDimensionMeasures } from '../dimensions/patient-dimension';
import { PracticeDimensionMeasures } from '../dimensions/practice-dimension';
import { ReferrerDimensionMeasures } from '../dimensions/referrer-dimension';
import { StafferDimensionMeasures } from '../dimensions/staffer-dimension';
import { TreatmentPlanDimensionMeasures } from '../dimensions/treatment-plan-dimension';
import {
  ComparableProperties,
  MeasureTransformMap,
} from '../measure-properties';
import { CanBeChartedProperty } from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';

export class LabJobReceivedFactMeasures
  extends BaseFactMeasures
  implements MeasureTransformMap<ComparableProperties<ILabJobReceivedFact>>
{
  readonly id = 'labJobReceived';
  readonly table = BigQueryTable.LabJobReceived;
  readonly name = 'Lab Jobs Received';
  scopes = [
    new BrandQueryScopeConfig(),
    new PracticeQueryScopeConfig(),
    new DateRangeQueryScopeConfig(),
  ];

  get lab(): LabDimensionMeasures {
    return new LabDimensionMeasures(BigQueryTable.Labs, 'lab', {
      sourceJoinKey: MeasurePath.docRef('lab.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get labJob(): LabJobDimensionMeasures {
    return new LabJobDimensionMeasures(BigQueryTable.LabJobs, 'labJob', {
      sourceJoinKey: MeasurePath.docRef('labJob.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get appointment(): AppointmentDimensionMeasures {
    return new AppointmentDimensionMeasures(
      BigQueryTable.Appointments,
      'appointment',
      {
        sourceJoinKey: MeasurePath.docRef('appointment.ref'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get practitioner(): StafferDimensionMeasures {
    return new StafferDimensionMeasures(BigQueryTable.Staff, 'practitioner', {
      sourceJoinKey: MeasurePath.docRef('practitioner.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get treatmentPlan(): TreatmentPlanDimensionMeasures {
    return new TreatmentPlanDimensionMeasures(
      BigQueryTable.TreatmentPlans,
      'treatmentPlan',
      {
        sourceJoinKey: MeasurePath.docRef('treatmentPlan.ref'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get practice(): PracticeDimensionMeasures {
    return new PracticeDimensionMeasures(BigQueryTable.Practices, 'practice', {
      sourceJoinKey: MeasurePath.docRef('practice.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get patient(): PatientDimensionMeasures {
    return new PatientDimensionMeasures(BigQueryTable.Patients, 'patient', {
      sourceJoinKey: MeasurePath.docRef('patient.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get referrer(): ReferrerDimensionMeasures {
    return new ReferrerDimensionMeasures(this.table, 'referrer');
  }

  get brand(): BrandDimensionMeasures {
    return new BrandDimensionMeasures(BigQueryTable.Brands, 'brand', {
      sourceJoinKey: MeasurePath.docRef('brand.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get isOverdue(): CanBeChartedProperty {
    const measure = this.measureRef('isOverdue');
    return MeasurePropertyFactory.boolean(
      {
        id: 'isOverdue',
        label: 'Is Overdue',
        summary: 'Whether the invoice is overdue or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get overdueBy(): CanBeChartedProperty {
    const propertyName = 'overdueBy';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Overdue By',
        summary: '',
        formatter: MeasureFormatter.Day,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get requiredForAppointment(): CanBeChartedProperty {
    const propertyName = 'requiredForAppointment';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.boolean(
      {
        id: propertyName,
        label: 'Required For Appointment',
        summary: 'Whether the lab job is required for an appointment or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get timeUntilAppointment(): CanBeChartedProperty {
    const propertyName = 'timeUntilAppointment';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Time Until Appointment',
        summary: '',
        formatter: MeasureFormatter.Minutes,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }
}
