<div class="layout-margin" fxLayout="column">
  <h1>Patients</h1>

  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
    <div class="chart" fxFlex>
      <h2>Patient Distribution</h2>
      <pr-chart [chartBuilder]="patientNumbersChart" />
    </div>
    <div fxFlex class="layout-margin">
      <h2>New &amp; Existing Patients</h2>
      <pr-chart [chartBuilder]="newAndExistingChartBuilder" />
    </div>
  </div>

  <pr-table-chart
    fxFlex
    [chart]="tableData"
    [replacementHeaders]="replacementHeaders"
   />
</div>
