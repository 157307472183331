import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  Brand,
  Staffer,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  type IBrand,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  multiMap,
  multiSortBy$,
  nameSorter,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface IUpsertTreatmentTemplateState {
  brand: WithRef<IBrand>;
  practices: INamedDocument<IPractice>[];
  staff: INamedDocument<IStaffer>[];
}

@Injectable()
export class UpsertTreatmentTemplateStore extends ComponentStore<IUpsertTreatmentTemplateState> {
  readonly brand$ = this.select((store) => store.brand);
  readonly practices$ = this.select((store) => store.practices);
  readonly staff$ = this.select((store) => store.staff);

  readonly loadBrand = this.effect((brand$: Observable<WithRef<IBrand>>) => {
    return brand$.pipe(
      tap((brand) => {
        this.setState({ brand, practices: [], staff: [] });
        this.loadPractices(Brand.practices$(brand));
        this.loadStaff(Staffer.practitionersByBrand$(brand));
      })
    );
  });

  readonly loadPractices = this.effect(
    (practices$: Observable<WithRef<IPractice>[]>) => {
      return practices$.pipe(
        multiMap((practice) => toNamedDocument(practice)),
        multiSortBy$(nameSorter()),
        tap((practices) => this.patchState({ practices }))
      );
    }
  );

  readonly loadStaff = this.effect(
    (staff$: Observable<WithRef<IStaffer>[]>) => {
      return staff$.pipe(
        multiMap((staffer) => stafferToNamedDoc(staffer)),
        multiSortBy$(nameSorter()),
        tap((staff) => this.patchState({ staff }))
      );
    }
  );
}
