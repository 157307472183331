import {
  HicapsConnect,
  HicapsConnectMethod,
  PrincipleHicapsConnect,
  PrincipleHicapsConnectResponse,
} from '@principle-theorem/hicaps-connect';
import { TypeGuard } from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';

export interface IHicapsConnectPaymentTransactionExtendedData {
  request: HicapsConnect.SaleRequest;
  response?: PrincipleHicapsConnectResponse<HicapsConnectMethod.SendSale>;
}

export interface IHicapsConnectRefundTransactionExtendedData {
  request: HicapsConnect.RefundRequest;
  response?: PrincipleHicapsConnectResponse<HicapsConnectMethod.SendRefund>;
}

export interface IHicapsConnectHealthFundTransactionExtendedData {
  request: HicapsConnect.ClaimRequest;
  response?: PrincipleHicapsConnectResponse<HicapsConnectMethod.SendClaimRequest>;
}

export interface IHicapsConnectHealthFundCancelTransactionExtendedData {
  request: HicapsConnect.ClaimCancelRequest;
  response?: PrincipleHicapsConnectResponse<HicapsConnectMethod.SendClaimCancelRequest>;
}

export interface IHicapsConnectMedicareClaimTransactionExtendedData {
  request: HicapsConnect.MedicareClaimRequest;
  response?: PrincipleHicapsConnectResponse<HicapsConnectMethod.SendMedicareClaimRequest>;
}

export type AnyHicapsConnectExtendedData =
  | IHicapsConnectPaymentTransactionExtendedData
  | IHicapsConnectRefundTransactionExtendedData
  | IHicapsConnectHealthFundTransactionExtendedData
  | IHicapsConnectHealthFundCancelTransactionExtendedData
  | IHicapsConnectMedicareClaimTransactionExtendedData;

export class HicapsConnectExtendedDataTypeGuard {
  static isPayment =
    TypeGuard.interface<IHicapsConnectPaymentTransactionExtendedData>({
      request: TypeGuard.interface<HicapsConnect.SaleRequest>({
        MerchantId: isString,
        TransactionAmount: isNumber,
        PrimaryAccountNumber: TypeGuard.undefinedOr(isString),
        CCV: TypeGuard.undefinedOr(isString),
        CCVReason: TypeGuard.noGuard(),
        CCVSource: TypeGuard.noGuard(),
        ExpiryDate: TypeGuard.undefinedOr(isString),
        PmsKey: TypeGuard.noGuard(),
        ServerUrl: TypeGuard.noGuard(),
        SoftwareVendorName: TypeGuard.noGuard(),
      }),
      response: TypeGuard.undefinedOr(
        PrincipleHicapsConnect.isResponse<HicapsConnectMethod.SendSale>(
          TypeGuard.noGuard()
        )
      ),
    });

  static isRefund =
    TypeGuard.interface<IHicapsConnectRefundTransactionExtendedData>({
      request: TypeGuard.interface<HicapsConnect.RefundRequest>({
        MerchantId: isString,
        TransactionAmount: isNumber,
        CCV: TypeGuard.undefinedOr(isString),
        CCVReason: TypeGuard.noGuard(),
        CCVSource: TypeGuard.noGuard(),
        ExpiryDate: TypeGuard.undefinedOr(isString),
        PmsKey: TypeGuard.noGuard(),
        ServerUrl: TypeGuard.noGuard(),
        SoftwareVendorName: TypeGuard.noGuard(),
      }),
      response: TypeGuard.undefinedOr(
        PrincipleHicapsConnect.isResponse<HicapsConnectMethod.SendRefund>(
          TypeGuard.noGuard()
        )
      ),
    });

  static isHealthFund =
    TypeGuard.interface<IHicapsConnectHealthFundTransactionExtendedData>({
      request: TypeGuard.interface<HicapsConnect.ClaimRequest>({
        ProviderNumberId: isString,
        PrimaryAccountNumber: TypeGuard.undefinedOr(isString),
        ClaimDetails: TypeGuard.undefinedOr(TypeGuard.arrayOf(isString)),
        MembershipId: isString,
        NonSettlementFlag: TypeGuard.undefinedOr(isBoolean),
        PatientNameDetails: TypeGuard.undefinedOr(TypeGuard.arrayOf(isString)),
        PrintReceiptOnTerminal: isBoolean,
        TransactionAmount: isNumber,
        PmsKey: TypeGuard.noGuard(),
        ServerUrl: TypeGuard.noGuard(),
        SoftwareVendorName: TypeGuard.noGuard(),
      }),
      response: TypeGuard.undefinedOr(
        PrincipleHicapsConnect.isResponse<HicapsConnectMethod.SendClaimRequest>(
          TypeGuard.noGuard()
        )
      ),
    });

  static isHealthFundCancel =
    TypeGuard.interface<IHicapsConnectHealthFundCancelTransactionExtendedData>({
      request: TypeGuard.interface<HicapsConnect.ClaimCancelRequest>({
        PmsKey: TypeGuard.noGuard(),
        ServerUrl: TypeGuard.noGuard(),
        SoftwareVendorName: TypeGuard.noGuard(),
        ProviderNumberId: isString,
        TransactionAmount: isNumber,
        RrnNumber: isString,
        PrimaryAccountNumber: TypeGuard.undefinedOr(isString),
        NonSettlementFlag: TypeGuard.undefinedOr(isBoolean),
        PatientNameDetails: TypeGuard.undefinedOr(TypeGuard.arrayOf(isString)),
        PrintReceiptOnTerminal: isBoolean,
      }),
      response: TypeGuard.undefinedOr(
        PrincipleHicapsConnect.isResponse<HicapsConnectMethod.SendClaimCancelRequest>(
          TypeGuard.noGuard()
        )
      ),
    });

  static isMedicareClaim =
    TypeGuard.interface<IHicapsConnectMedicareClaimTransactionExtendedData>({
      request: TypeGuard.interface<HicapsConnect.MedicareClaimRequest>({
        PmsKey: TypeGuard.noGuard(),
        ServerUrl: TypeGuard.noGuard(),
        SoftwareVendorName: TypeGuard.noGuard(),
        ServicingProviderNum: isString,
        RequestPeriodTypeCde: TypeGuard.undefinedOr(isString),
        RequestOverrideTypeCde: TypeGuard.undefinedOr(isString),
        RequestIssueDate: TypeGuard.undefinedOr(isString),
        RequestingProviderNum: TypeGuard.undefinedOr(isString),
        ReferringProviderNum: TypeGuard.undefinedOr(isString),
        ReferralIssueDate: TypeGuard.undefinedOr(isString),
        PayeeProviderNum: TypeGuard.undefinedOr(isString),
        PatientMedicareCardNum: isString,
        PatientIRN: isString,
        ClaimDetails: TypeGuard.undefinedOr(TypeGuard.arrayOf(isString)),
        ClaimantMedicareCardNum: TypeGuard.undefinedOr(isString),
        ClaimantIRN: TypeGuard.undefinedOr(isString),
        BenefitAssigned: TypeGuard.undefinedOr(isNumber),
        AccountReferenceId: isString,
        TransactionAmount: isNumber,
        ReferralPeriodTypeCde: TypeGuard.undefinedOr(
          PrincipleHicapsConnect.isReferralPeriodType
        ),
        ReferralOverrideTypeCde: TypeGuard.undefinedOr(
          PrincipleHicapsConnect.isReferralOverrideType
        ),
        ClaimType: TypeGuard.noGuard(),
        ServiceTypeCde: TypeGuard.noGuard(),
        CevRequestInd: TypeGuard.noGuard(),
      }),
      response: TypeGuard.undefinedOr(
        PrincipleHicapsConnect.isResponse<HicapsConnectMethod.SendMedicareClaimRequest>(
          TypeGuard.noGuard()
        )
      ),
    });

  static isAny(
    extendedData?: unknown
  ): extendedData is AnyHicapsConnectExtendedData {
    const guards = [
      HicapsConnectExtendedDataTypeGuard.isPayment,
      HicapsConnectExtendedDataTypeGuard.isRefund,
      HicapsConnectExtendedDataTypeGuard.isHealthFundCancel,
      HicapsConnectExtendedDataTypeGuard.isHealthFund,
      HicapsConnectExtendedDataTypeGuard.isMedicareClaim,
    ];
    return guards.some((guard) => guard(extendedData));
  }
}
