import { type WithRef } from '@principle-theorem/shared';
import { D4WCustomMappingsHandler } from './d4w/mappings';
import {
  type ICustomMappingsHandler,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { PraktikaCustomMappingsHandler } from './praktika/mappings';
import { ExactCustomMappingsHandler } from './exact/mappings';
import { DentrixCustomMappingsHandler } from './dentrix/mappings';
import { CorePracticeCustomMappingsHandler } from './core-practice/mappings';

export const RESOURCE_MAPPINGS_HANDLERS: ICustomMappingsHandler[] = [
  new D4WCustomMappingsHandler(),
  new PraktikaCustomMappingsHandler(),
  new ExactCustomMappingsHandler(),
  new DentrixCustomMappingsHandler(),
  new CorePracticeCustomMappingsHandler(),
];

export function getCustomMappingsHandler(
  migration: WithRef<IPracticeMigration>
): ICustomMappingsHandler | undefined {
  return RESOURCE_MAPPINGS_HANDLERS.find((mappingsHandler) => {
    if (
      mappingsHandler.migrationType !== migration.metadata.name ||
      mappingsHandler.migrationVersion !== migration.metadata.version
    ) {
      return false;
    }
    return mappingsHandler.canHandle(migration.mappings);
  });
}

export async function initialiseCustomMappings(
  migration: WithRef<IPracticeMigration>
): Promise<void> {
  const handler = getCustomMappingsHandler(migration);
  if (!handler) {
    return;
  }

  await handler.addEntities(migration);
}
