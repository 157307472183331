import { toChartedRef } from '@principle-theorem/principle-core';
import {
  ChartableSurface,
  type IChartedRef,
  type IDentalChartView,
  type IDentalChartViewSurface,
  Quadrant,
} from '@principle-theorem/principle-core/interfaces';
import { compact } from 'lodash';
import { ChartQuadrant, type IChartQuadrant } from './chart-quadrant';
import { type IChartTooth } from './chart-tooth';
import { getDentalViewForChartedSurface } from './helpers';

export class ChartQuadrantMap {
  quadrants: IChartQuadrant[] = [];

  constructor(public view: IDentalChartView) {
    const quadrants: (IChartQuadrant | undefined)[] = [
      Quadrant.AdultUpperRight,
      Quadrant.AdultUpperLeft,
      Quadrant.AdultLowerLeft,
      Quadrant.AdultLowerRight,
      Quadrant.DeciduousUpperRight,
      Quadrant.DeciduousUpperLeft,
      Quadrant.DeciduousLowerLeft,
      Quadrant.DeciduousLowerRight,
    ].map((quadrant: Quadrant) => {
      const chartedRef: Partial<IChartedRef> = toChartedRef({
        surface: ChartableSurface.Quadrant,
        area: quadrant,
      });

      try {
        const quadrantView: IDentalChartViewSurface | undefined =
          getDentalViewForChartedSurface(chartedRef, this.view);
        if (!quadrantView) {
          return;
        }
        return new ChartQuadrant(quadrant, quadrantView).toInterface();
      } catch (error) {
        return;
      }
    });
    this.quadrants = compact(quadrants);
  }

  assignTeeth(teeth: IChartTooth[]): void {
    teeth.map((tooth: IChartTooth) => {
      this.updateQuadrant(
        tooth.tooth.toothRef.quadrant,
        (quadrant: IChartQuadrant) => quadrant.teeth.push(tooth)
      );
    });
  }

  updateQuadrant(
    quadrant: Quadrant,
    updateFn: (quadrant: IChartQuadrant) => void
  ): void {
    const chartQuadrant: IChartQuadrant | undefined =
      this.findQuadrant(quadrant);
    if (!chartQuadrant) {
      return;
    }
    updateFn(chartQuadrant);
  }

  findQuadrant(quadrant: Quadrant): IChartQuadrant | undefined {
    return this.quadrants.find((chartQuadrant: IChartQuadrant) => {
      return chartQuadrant.quadrant === quadrant;
    });
  }

  toArray(): IChartQuadrant[] {
    return this.quadrants;
  }
}
