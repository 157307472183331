<mat-toolbar color="accent">
  <div class="flex grow">
    @if (store.form$ | async; as patientForm) {
      {{ patientForm | map: getName : this }}
    } @else {
      Patient Form
    }
  </div>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>

<mat-dialog-content class="!flex !flex-col gap-2 !p-4">
  <pr-patient-form-toolbar (formSelected)="store.loadForm($event)" />
  <pr-patient-form-content />
</mat-dialog-content>

<mat-dialog-actions class="flex flex-row items-center justify-end gap-2 !p-4">
  <pr-patient-form-confirm-button (confirmed)="dialogRef.close()" />
  <button mat-stroked-button [mat-dialog-close]="true">Close</button>
</mat-dialog-actions>
