<form [formGroup]="form" class="flex flex-row gap-4">
  <div class="flex flex-auto flex-col gap-4">
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Where this is shown</mat-label>
      <mat-select formControlName="displayTargets" multiple>
        @for (displayTarget of displayTargetOptions; track displayTarget) {
          <mat-option [value]="displayTarget.value">
            {{ displayTarget.label }}
          </mat-option>
        }
      </mat-select>

      <mat-icon
        matSuffix
        class="opacity-50"
        #typeTooltipOrigin="cdkOverlayOrigin"
        cdkOverlayOrigin
      >
        help_outline
      </mat-icon>
      <pt-custom-tooltip [target]="typeTooltipOrigin">
        <pr-patient-metadata-outlet-help />
      </pt-custom-tooltip>
    </mat-form-field>

    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Label</mat-label>
      <input matInput formControlName="label" [required]="true" />
    </mat-form-field>

    @if ((hideFormat$ | async) === false) {
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Format</mat-label>
        <mat-select formControlName="format" [required]="true">
          @for (formatOption of formatOptions; track formatOption) {
            <mat-option [value]="formatOption.value">
              {{ formatOption.label }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    }

    @if ((hideHasValueSelector$ | async) === false) {
      <div>
        <mat-checkbox formControlName="onlyShowIfHasValue">
          Only show if the patient has a value
        </mat-checkbox>
      </div>
    }

    <pr-preview-patient-metadata-display
      [value]="previewValue"
      [display]="previewDisplay$ | async"
    />

    <ng-content />
  </div>

  <pt-colour-picker formControlName="hexColour" [inputHidden]="true" />
</form>
