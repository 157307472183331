import { ValidatorFn, Validators } from '@angular/forms';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  IPrescriptionItem,
  PrescriptionItemFormData,
} from '@principle-theorem/principle-core/interfaces';

export class PrescriptionItemFormGroup extends TypedFormGroup<PrescriptionItemFormData> {
  constructor(
    item?: IPrescriptionItem,
    validators?: { [key: string]: ValidatorFn | ValidatorFn[] }
  ) {
    super({
      medicationName: new TypedFormControl('', Validators.required),
      quantity: new TypedFormControl('', validators?.quantity || []),
      repeat: new TypedFormControl(undefined, validators?.repeat || []),
      instructions: new TypedFormControl('', validators?.instructions || []),
      safetyInformation: new TypedFormControl(
        '',
        validators?.safetyInformation || []
      ),
    });

    if (item) {
      this.patchValue(item, { emitEvent: false });
    }
  }
}
