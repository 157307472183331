import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ChartView,
  CHART_VIEWS,
} from '@principle-theorem/principle-core/interfaces';
import {
  CurrentBrandScope,
  CurrentStafferScope,
} from '@principle-theorem/ng-principle-shared';
import {
  type IBreadcrumb,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  type IChartSettings,
  type IStafferSettings,
} from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, patchDoc, snapshot } from '@principle-theorem/shared';
import { type Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-account-general',
  templateUrl: './account-general.component.html',
  styleUrls: ['./account-general.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountGeneralComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  chartingForm = new TypedFormGroup<Pick<IChartSettings, 'view'>>({
    view: new TypedFormControl(ChartView.Adult, Validators.required),
  });
  form = new TypedFormGroup<Pick<IStafferSettings, 'charting'>>({
    charting: this.chartingForm,
  });
  breadcrumbs$: Observable<IBreadcrumb[]>;
  trackByChartView = TrackByFunctions.variable<ChartView>();
  chartViews = CHART_VIEWS;

  constructor(
    private _snackBar: MatSnackBar,
    private _currentBrand: CurrentBrandScope,
    private _currentStaffer: CurrentStafferScope
  ) {
    this.breadcrumbs$ = this._currentBrand.doc$.pipe(
      filterUndefined(),
      map((brand) => {
        return [
          { label: 'Settings', path: '../../../../' },
          { label: brand.name },
          { label: 'My Account', path: '' },
          { label: 'General' },
        ];
      })
    );

    this._currentStaffer.doc$
      .pipe(
        take(1),
        map((staffer) => staffer?.settings),
        filterUndefined(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((settings) =>
        this.form.patchValue(settings, { emitEvent: false })
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async save(): Promise<void> {
    const staffer = await snapshot(
      this._currentStaffer.doc$.pipe(filterUndefined())
    );
    const settings = this.form.getRawValue();
    await patchDoc(staffer.ref, {
      settings: {
        ...staffer.settings,
        ...settings,
      },
    });
    this._snackBar.open('Changes Saved');
  }
}
