import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ForecastsCardComponent } from './forecasts-card/forecasts-card.component';
import { LiveMeasuresCardComponent } from './live-measures-card/live-measures-card.component';
import { OverviewCardComponent } from './overview-card/overview-card.component';
import { ScheduleCardComponent } from './schedule-card/schedule-card.component';
import { SingleChartCardComponent } from './single-chart-card/single-chart-card.component';
import { ReportingCoreModule } from '../core/reporting-core.module';
import { TableChartComponent } from './table-chart/table-chart.component';
import { MeasureHistorySummaryComponent } from './measure-history-summary/measure-history-summary.component';
import { DataPointsSummaryComponent } from './data-points-summary/data-points-summary.component';
import { BasicChartComponent } from './basic-chart/basic-chart.component';
import { SingleValueChartComponent } from './single-value-chart/single-value-chart.component';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';

@NgModule({
  imports: [
    CommonModule,
    NgPrincipleSharedModule,
    ReportingCoreModule,
    NgMaterialModule,
    NgSharedModule,
  ],
  declarations: [
    OverviewCardComponent,
    LiveMeasuresCardComponent,
    SingleChartCardComponent,
    ForecastsCardComponent,
    ScheduleCardComponent,
    TableChartComponent,
    MeasureHistorySummaryComponent,
    DataPointsSummaryComponent,
    BasicChartComponent,
    SingleValueChartComponent,
  ],
  exports: [
    OverviewCardComponent,
    LiveMeasuresCardComponent,
    SingleChartCardComponent,
    ForecastsCardComponent,
    ScheduleCardComponent,
    TableChartComponent,
    MeasureHistorySummaryComponent,
    DataPointsSummaryComponent,
    BasicChartComponent,
    SingleValueChartComponent,
  ],
})
export class ReportingComponentsModule {}
