<div
  fxFlex
  fxLayout="row"
  fxLayoutAlign="center center"
  *ngIf="store.isLoading$ | async; else showPage"
>
  <mat-spinner mode="indeterminate" />
</div>

<ng-template #showPage>
  <ng-container *ngIf="store.selectedSummary$ | async as result; else selector">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
      class="layout-padding"
    >
      <button mat-stroked-button (click)="store.clearSelectedSummary()">
        Back
      </button>
      <h2>{{ result.referralSource }}</h2>
    </div>

    <pr-referral-sources-report-drilldown
      [result]="result"
      [dateRange]="store.dateRange$ | async"
     />
  </ng-container>

  <ng-template #selector>
    <pr-referral-sources-report-summary
      [summaries]="store.summaries$ | async"
      [dateRange]="store.dateRange$ | async"
     />
  </ng-template>
</ng-template>
