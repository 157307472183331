import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-patient-address',
  templateUrl: './patient-address.component.html',
  styleUrls: ['./patient-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientAddressComponent {
  address$ = new ReplaySubject<string | undefined>(1);

  @Input()
  set address(address: string | undefined) {
    this.address$.next(address);
  }
}
