import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  ChartItemDisplayType,
  CHART_ITEM_DISPLAY_TYPES,
} from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-chart-filters-selector',
  templateUrl: './chart-filters-selector.component.html',
  styleUrls: ['./chart-filters-selector.component.sass'],
})
export class ChartFiltersSelectorComponent {
  trackByType = TrackByFunctions.variable<ChartItemDisplayType>();
  filterGroups: ChartItemDisplayType[][] = [
    [
      ChartItemDisplayType.CurrentCondition,
      ChartItemDisplayType.ResolvedCondition,
    ],
    [
      ChartItemDisplayType.ProposedTreatment,
      ChartItemDisplayType.ExistingTreatment,
      ChartItemDisplayType.CompletedTreatment,
    ],
  ];
  @Input() filters: ChartItemDisplayType[] = [...CHART_ITEM_DISPLAY_TYPES];
  @Output() selectionChange: EventEmitter<ChartItemDisplayType[]> =
    new EventEmitter<ChartItemDisplayType[]>();

  select(filter: ChartItemDisplayType, event: MouseEvent): void {
    event.stopPropagation();
    const index: number = this.filters.indexOf(filter);
    if (index < 0) {
      this.filters.push(filter);
    } else {
      this.filters.splice(index, 1);
    }
    this.selectionChange.emit([...this.filters]);
  }

  isSelected(filter: ChartItemDisplayType): boolean {
    return this.filters.includes(filter);
  }
}
