import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  Appointment,
  Candidate,
  Event,
  WaitListCandidate,
  WaitListScore,
} from '@principle-theorem/principle-core';
import {
  GapOfferStatus,
  ICandidate,
  ICandidateCalendarEvent,
  IPractice,
  IScheduleSummaryEventable,
  IStaffer,
  ITypesenseWaitListWithRef,
  IWaitListCandidate,
  SortReturnValue,
  WaitListUrgency,
} from '@principle-theorem/principle-core/interfaces';
import {
  DayOfWeek,
  DocumentReference,
  ISODateType,
  ITimePeriod,
  WithRef,
  asyncForEach,
  filterUndefined,
  isSameRef,
  multiFilter,
  multiMap,
  multiSort,
  multiSwitchMap,
  toISODate,
  toMoment,
  toMomentTz,
  toNamedDocument,
  toTimestamp,
} from '@principle-theorem/shared';
import { cloneDeep } from 'lodash';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { Observable, combineLatest, from, iif, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { OrganisationService } from '../organisation.service';
import {
  ISearchResults,
  TypesenseSearchService,
} from '../typesense/typesense-search.service';
import { GapStoreService } from './gaps-store.service';

export const DEFAULT_WAITLIST_PAGE_SIZE = 25;

export enum WaitListSortOption {
  AppointmentDate = 'appointmentDate',
  Urgency = 'urgency',
  Score = 'score',
  Duration = 'duration',
}

export const WAITLIST_SORT_OPTION_MAP: Record<WaitListSortOption, string[]> = {
  [WaitListSortOption.AppointmentDate]: ['appointmentFrom:asc'],
  [WaitListSortOption.Urgency]: ['urgencyIndex:desc'],
  [WaitListSortOption.Duration]: [
    'appointmentDuration:asc',
    'appointmentFrom:asc',
  ],
  [WaitListSortOption.Score]: ['appointmentFrom:asc'],
};

export interface IWaitListFilters {
  minimumDate?: Moment;
  dateRange?: ITimePeriod;
  urgencies?: WaitListUrgency[];
  inShortList?: boolean;
  practiceRef?: DocumentReference<IPractice>;
  practitioners?: DocumentReference<IStaffer>[];
  days?: DayOfWeek[];
  availableDates?: ISODateType[];
  appointmentDuration?: number;
}

interface IWaitListState {
  searchValue: string;
  allWaitListResults?: ISearchResults<ITypesenseWaitListWithRef>;
  smartWaitListResults?: ISearchResults<ITypesenseWaitListWithRef>;
  waitListFilters: IWaitListFilters;
  allWaitListPageSize: number;
  smartWaitListPageSize: number;
  sortBy: string[];
  sortByScore: boolean;
}

const initialWaitListFilters: IWaitListFilters = {
  inShortList: false,
  practitioners: [],
};

const initialState: IWaitListState = {
  searchValue: '',
  allWaitListResults: undefined,
  smartWaitListResults: undefined,
  waitListFilters: initialWaitListFilters,
  allWaitListPageSize: DEFAULT_WAITLIST_PAGE_SIZE,
  smartWaitListPageSize: DEFAULT_WAITLIST_PAGE_SIZE,
  sortBy: ['appointmentFrom:asc'],
  sortByScore: false,
};

@Injectable()
export class WaitListStore extends ComponentStore<IWaitListState> {
  private _typesense = inject(TypesenseSearchService);
  private _gapStore = inject(GapStoreService);
  private _organisation = inject(OrganisationService);

  readonly searchValue$ = this.select((state) => state.searchValue);
  readonly allWaitListResults$ = this.select(
    (state) => state.allWaitListResults
  );
  readonly smartWaitListResults$ = this.select(
    (state) => state.smartWaitListResults
  );
  readonly waitListFilters$ = this.select((state) => state.waitListFilters);
  readonly allWaitListPageSize$ = this.select(
    (state) => state.allWaitListPageSize
  );
  readonly smartWaitListPageSize$ = this.select(
    (state) => state.smartWaitListPageSize
  );
  readonly sortBy$ = this.select((state) => state.sortBy);
  readonly sortByScore$ = this.select((state) => state.sortByScore);

  readonly setWaitListFilters = this.updater(
    (state, filters: Partial<IWaitListFilters>) => ({
      ...state,
      waitListFilters: {
        ...state.waitListFilters,
        ...filters,
      },
    })
  );

  readonly setSearchValue = this.updater((state, searchValue: string) => ({
    ...state,
    searchValue,
  }));

  readonly setSortBy = this.updater((state, sortBy: string[]) => {
    return {
      ...state,
      sortBy,
      sortByScore:
        sortBy === WAITLIST_SORT_OPTION_MAP[WaitListSortOption.Score]
          ? true
          : false,
    };
  });

  readonly updateWaitListItem = this.updater(
    (state, waitListItem: ITypesenseWaitListWithRef) => {
      const allWaitListResults = this._updateSearchResults(
        waitListItem,
        state.allWaitListResults
      );
      const smartWaitListResults = this._updateSearchResults(
        waitListItem,
        state.smartWaitListResults
      );
      return {
        ...state,
        allWaitListResults,
        smartWaitListResults,
      };
    }
  );

  readonly allWaitListCandidates$ = combineLatest([
    this.allWaitListResults$.pipe(
      filterUndefined(),
      map((results) => results.results)
    ),
    this.sortByScore$,
    this._gapStore.selectedGap$.pipe(filterUndefined()),
    this._gapStore.gapCandidates$,
  ]).pipe(
    switchMap(([results, sortByScore, gap, gapCandidates]) =>
      from(
        asyncForEach(results, (result) =>
          this._appointmentToCandidate(gap, result)
        )
      ).pipe(
        multiFilter((candidate) =>
          this._filterShortlisted(candidate, gapCandidates)
        ),
        switchMap((candidates) =>
          iif(
            () => sortByScore,
            of(candidates).pipe(
              multiSort((candidateA, candidateB) =>
                this._sortCandidatesByScore(
                  gap,
                  candidateA.appointment,
                  candidateB.appointment
                )
              )
            ),
            of(candidates)
          )
        )
      )
    )
  );

  readonly smartWaitListCandidates$ = combineLatest([
    this.smartWaitListResults$.pipe(
      filterUndefined(),
      map((results) => results.results)
    ),
    this.sortByScore$,
    this._gapStore.gapCandidates$,
    this._gapStore.selectedGap$.pipe(filterUndefined()),
  ]).pipe(
    switchMap(([results, sortByScore, gapCandidates, gap]) =>
      of(results).pipe(
        multiFilter((appointment) =>
          this._appointmentFitsGap(gap, appointment)
        ),
        multiSwitchMap((appointment) =>
          this._appointmentToCandidate(gap, appointment)
        ),
        multiFilter((candidate) =>
          this._filterShortlisted(candidate, gapCandidates)
        ),
        switchMap((candidates) =>
          iif(
            () => sortByScore,
            of(candidates).pipe(
              multiSort((candidateA, candidateB) =>
                this._sortCandidatesByScore(
                  gap,
                  candidateA.appointment,
                  candidateB.appointment
                )
              )
            ),
            of(candidates)
          )
        )
      )
    )
  );

  readonly allSmartResultsLoaded$ = this.smartWaitListResults$.pipe(
    filterUndefined(),
    map((results) => results.results.length === results.numberFound)
  );
  readonly allResultsLoaded$ = this.allWaitListResults$.pipe(
    filterUndefined(),
    map((results) => results.results.length === results.numberFound)
  );

  constructor() {
    super(initialState);

    this.effect(() =>
      this._getAllWaitListResults$().pipe(
        tap((allWaitListResults) => this.patchState({ allWaitListResults }))
      )
    );

    this.effect(() =>
      this._getSmartWaitListResults$().pipe(
        tap((smartWaitListResults) => this.patchState({ smartWaitListResults }))
      )
    );

    this._organisation.brandPractitioners$
      .pipe(
        take(1),
        multiMap((practitioner) => practitioner.ref)
      )
      .subscribe((practitioners) => this.setWaitListFilters({ practitioners }));

    this._organisation.practice$
      .pipe(filterUndefined(), take(1))
      .subscribe((practice) =>
        this.setWaitListFilters({ practiceRef: practice.ref })
      );
  }

  private _getAllWaitListResults$(): Observable<
    ISearchResults<ITypesenseWaitListWithRef>
  > {
    return combineLatest([
      this.searchValue$,
      this.waitListFilters$,
      this.allWaitListPageSize$,
      this.sortBy$,
      this._organisation.practice$.pipe(filterUndefined()),
      this._gapStore.selectedGap$.pipe(filterUndefined()),
    ]).pipe(
      switchMap(([searchValue, filters, pageSize, sortBy, practice, gap]) =>
        this._typesense.waitListQuery$(
          of(searchValue),
          of({
            ...filters,
            minimumDate: toMomentTz(gap.event.from, practice.settings.timezone),
          }),
          of(sortBy),
          undefined,
          of(pageSize)
        )
      )
    );
  }

  private _getSmartWaitListResults$(): Observable<
    ISearchResults<ITypesenseWaitListWithRef>
  > {
    return combineLatest([
      this.searchValue$,
      this.waitListFilters$,
      this.smartWaitListPageSize$,
      this.sortBy$,
      this._organisation.practice$.pipe(filterUndefined()),
      this._gapStore.selectedGap$.pipe(filterUndefined()),
    ]).pipe(
      switchMap(([searchValue, filters, pageSize, sortBy, practice, gap]) =>
        this._typesense.waitListQuery$(
          of(searchValue),
          of({
            practiceRef: practice.ref,
            availableDates: [
              toISODate(toMomentTz(gap.event.from, practice.settings.timezone)),
            ],
            practitioners: filters.practitioners,
            minimumDate: toMomentTz(gap.event.from, practice.settings.timezone),
          }),
          of(sortBy),
          undefined,
          of(pageSize)
        )
      )
    );
  }

  private _updateSearchResults(
    waitListItem: ITypesenseWaitListWithRef,
    results?: ISearchResults<ITypesenseWaitListWithRef>
  ): ISearchResults<ITypesenseWaitListWithRef> | undefined {
    if (!results) {
      return;
    }

    const updatedResults = cloneDeep(results);
    const foundIndex = results.results.findIndex((result) =>
      isSameRef(result.ref, waitListItem.ref)
    );
    if (foundIndex === -1) {
      return results;
    }

    updatedResults.results[foundIndex] = waitListItem;
    return updatedResults;
  }

  private async _appointmentToCandidate(
    gap: IScheduleSummaryEventable,
    appointment: ITypesenseWaitListWithRef
  ): Promise<IWaitListCandidate> {
    return WaitListCandidate.init({
      candidate: await this._createCandidate(gap, appointment),
      appointment,
    });
  }

  private async _createCandidate(
    gap: IScheduleSummaryEventable,
    appointment: ITypesenseWaitListWithRef
  ): Promise<ICandidate> {
    const { appointmentDuration } = appointment;
    const gapDuration = Event.duration(gap.event);

    const duration =
      appointmentDuration && appointmentDuration < gapDuration
        ? appointmentDuration
        : gapDuration;
    const offerTimeFrom = gap.event.from;
    const offerTimeTo = toTimestamp(
      toMoment(gap.event.from).add(duration, 'minutes')
    );
    const patient = await Appointment.patient(appointment);
    return Candidate.init({
      status: GapOfferStatus.Available,
      appointment: appointment.ref,
      patient: toNamedDocument(patient),
      offerTimeFrom,
      offerTimeTo,
    });
  }

  private _appointmentFitsGap(
    gap: IScheduleSummaryEventable,
    appointment: ITypesenseWaitListWithRef
  ): boolean {
    if (!appointment.appointmentDuration) {
      return false;
    }
    if (!appointment.appointmentFrom) {
      return true;
    }
    return toMoment(gap.event.from).isBefore(
      toMoment(moment.unix(appointment.appointmentFrom))
    );
  }

  private _sortCandidatesByScore(
    gap: IScheduleSummaryEventable,
    appointmentA: ITypesenseWaitListWithRef,
    appointmentB: ITypesenseWaitListWithRef
  ): SortReturnValue {
    const scoreA = WaitListScore.getMatchScore(appointmentA, gap.event);
    const scoreB = WaitListScore.getMatchScore(appointmentB, gap.event);

    return scoreA === scoreB ? 0 : scoreA > scoreB ? -1 : 1;
  }

  private _filterShortlisted(
    candidate: IWaitListCandidate,
    gapCandidates: WithRef<ICandidateCalendarEvent>[]
  ): boolean {
    return !gapCandidates.some((gapCandidate) =>
      isSameRef(candidate.candidate.patient, gapCandidate.candidate.patient)
    );
  }
}
