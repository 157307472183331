import { Injectable } from '@angular/core';
import { type Data, Router } from '@angular/router';
import { BaseCurrentFirestoreModel } from '@principle-theorem/ng-principle-shared';
import { type ICustomReport } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';

@Injectable({
  providedIn: 'root',
  useFactory: (route: Router) => new CurrentCustomReportScope(route),
  deps: [Router],
})
export class CurrentCustomReportScope extends BaseCurrentFirestoreModel<
  WithRef<ICustomReport>
> {
  protected _propertyAccessor(data: Data): WithRef<ICustomReport> | undefined {
    if (data.customReport) {
      return data.customReport as WithRef<ICustomReport>;
    }
  }
}
