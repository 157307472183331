<ng-container *ngIf="buttonType$ | async as buttonType">
  <div *ngIf="buttonType === 'block'" class="block">
    <button
      fxFill
      mat-button
      [ngClass]="{ active: isActive$ | async, disabled: isDisabled$ | async }"
      (click)="fileInput.click()"
    >
      <div class="flex items-center p-1">
        <div class="icon">
          <mat-icon>{{ icon }}</mat-icon>
        </div>
        <span>{{ buttonText }}</span>
        <span fxFlex></span>
        <span class="shortcut" *ngIf="shortcut">{{
          shortcut | translateForPlatform
        }}</span>
      </div>
    </button>
  </div>

  <ng-container *ngIf="buttonType === 'submenu-detail'">
    <div class="submenu-detail">
      <button
        fxFill
        mat-menu-item
        [ngClass]="{ active: isActive$ | async }"
        [disabled]="isDisabled$ | async"
        (click)="runCommand()"
        [matTooltip]="
          tooltip +
          (shortcut ? ' (' + (shortcut | translateForPlatform) + ')' : '')
        "
        matTooltipShowDelay="300"
        matTooltipPosition="after"
        [matTooltipDisabled]="textOnly"
      >
        <div class="flex items-center p-1">
          <mat-icon *ngIf="!textOnly">{{ icon }}</mat-icon>
          <span>{{ buttonText }}</span>
          <span fxFlex></span>
          <span class="shortcut" *ngIf="shortcut">{{
            shortcut | translateForPlatform
          }}</span>
        </div>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="buttonType === 'basic'">
    <button
      class="flex grow !justify-start"
      mat-button
      (click)="fileInput.click()"
    >
      <mat-icon>{{ icon }}</mat-icon>
      <span>{{ buttonText }}</span>
    </button>
  </ng-container>

  <input
    type="file"
    [accept]="acceptedTypes.join(', ')"
    #fileInput
    (change)="startUpload($event)"
    [multiple]="true"
  />
</ng-container>
