import { createAction, props } from '@ngrx/store';
import { type IAppointmentRequest } from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';

enum AppointmentRequestActions {
  SetAppointmentRequest = '[Appointment Request] Set Appointment Request',
  ClearAppointmentRequest = '[Appointment Request] Clear Appointment Request',
  CancelAppointmentRequest = '[Appointment Request] Cancel Appointment Request',
}

interface ISetRequest {
  appointmentRequest: WithRef<IAppointmentRequest>;
}

export const setAppointmentRequest = createAction(
  AppointmentRequestActions.SetAppointmentRequest,
  props<SerialisedData<ISetRequest>>()
);

export const clearAppointmentRequest = createAction(
  AppointmentRequestActions.ClearAppointmentRequest
);

interface IHasRef {
  ref: DocumentReference<IAppointmentRequest>;
}

export const cancelAppointmentRequest = createAction(
  AppointmentRequestActions.CancelAppointmentRequest,
  props<SerialisedData<IHasRef>>()
);
