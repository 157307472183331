import {
  type AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  type MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { type MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ChartedConfigurationFacade } from '@principle-theorem/ng-clinical-charting/store';
import {
  DialogPresets,
  InputSearchFilter,
  toSearchStream,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { ChartableSurfaceResolver } from '@principle-theorem/principle-core';
import {
  type IChartedItemConfiguration,
  type IChartedRef,
  type IMultiTreatmentConfiguration,
  isMultiTreatmentConfiguration,
  isTreatmentConfiguration,
  type ITreatmentConfiguration,
  type ITreatmentConfigurationRef,
  type ServiceCode,
} from '@principle-theorem/principle-core/interfaces';
import {
  debounceUserInput,
  isSameRef,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, flatten, isString, sortBy } from 'lodash';
import { BehaviorSubject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { type IAddChartedItem } from '../../add-charted-item-dialog/add-charted-item';
import { AddChartedTreatmentComponent } from '../../add-charted-item-dialog/add-charted-treatment/add-charted-treatment.component';

interface ITreatmentWithAssociatedServiceCodes
  extends IChartedItemConfiguration {
  associatedServiceCodes: string[];
}

@Component({
    selector: 'pr-treatments-search',
    templateUrl: './treatments-search.component.html',
    styleUrls: ['./treatments-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreatmentsSearchComponent implements AfterViewInit {
  private _selectedSurfaces$: BehaviorSubject<Partial<IChartedRef>[]> =
    new BehaviorSubject<Partial<IChartedRef>[]>([]);
  trackByTreatment =
    TrackByFunctions.ref<
      WithRef<ITreatmentWithAssociatedServiceCodes | IChartedItemConfiguration>
    >();
  treatments$: Observable<
    WithRef<ITreatmentWithAssociatedServiceCodes | IChartedItemConfiguration>[]
  >;
  searchCtrl: TypedFormControl<string> = new TypedFormControl('');
  searchFilter: InputSearchFilter<
    WithRef<ITreatmentWithAssociatedServiceCodes | IChartedItemConfiguration>
  >;

  @Input() expanded = false;
  @Output() chartableAdded = new EventEmitter<
    WithRef<IChartedItemConfiguration>
  >();
  @ViewChild(MatAutocompleteTrigger, { static: true })
  trigger: MatAutocompleteTrigger;

  @Input() includeMultiTreatments = true;

  @Input()
  set selectedSurfaces(selectedSurfaces: Partial<IChartedRef>[]) {
    if (selectedSurfaces) {
      this._selectedSurfaces$.next(selectedSurfaces);
    }
  }

  constructor(
    private _dialog: MatDialog,
    private _chartedConfigStore: ChartedConfigurationFacade
  ) {
    this.treatments$ = ChartableSurfaceResolver.filterChartableItems$(
      this._chartedConfigStore.combinedTreatmentConfigurations$,
      this._selectedSurfaces$.pipe(debounceUserInput())
    ).pipe(
      map((chartableItems) => sortBy(chartableItems, (item) => item.name)),
      map((items) => this._resolveTreatmentsWithServiceCodes(items)),
      map((items) =>
        sortBy(items, (item) => {
          const isDisabled = this.disableMultiTreatment(item);
          return isDisabled ? 1 : 0;
        })
      )
    );

    this.searchFilter = new InputSearchFilter<
      WithRef<ITreatmentWithAssociatedServiceCodes | IChartedItemConfiguration>
    >(this.treatments$, toSearchStream(this.searchCtrl), [
      'name',
      'associatedServiceCodes',
    ]);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.expanded) {
        this.trigger.openPanel();
      }
    });
  }

  disableMultiTreatment(
    treatment: WithRef<
      ITreatmentWithAssociatedServiceCodes | IChartedItemConfiguration
    >
  ): boolean {
    return (
      isMultiTreatmentConfiguration(treatment) && !this.includeMultiTreatments
    );
  }

  optionSelected($event: MatAutocompleteSelectedEvent): void {
    this.chartableAdded.emit(
      $event.option.value as WithRef<IChartedItemConfiguration>
    );
    this.searchCtrl.setValue('');
  }

  async addNewTreatment(event: MatOptionSelectionChange): Promise<void> {
    if (!event.isUserInput) {
      return;
    }
    const data: IAddChartedItem = {
      name: this.searchCtrl.value,
      selectedSurfaces: await snapshot(this._selectedSurfaces$),
    };
    this._dialog.open(
      AddChartedTreatmentComponent,
      DialogPresets.large({ height: '80%', data })
    );
  }

  displayFn(condition?: IChartedItemConfiguration | string): string {
    if (!condition) {
      return '';
    }
    return !isString(condition) ? condition.name : condition;
  }

  private _resolveTreatmentsWithServiceCodes(
    items: WithRef<ITreatmentConfiguration | IMultiTreatmentConfiguration>[]
  ): WithRef<
    ITreatmentWithAssociatedServiceCodes | IChartedItemConfiguration
  >[] {
    const treatmentConfigs = items.filter(
      (item): item is WithRef<ITreatmentConfiguration> =>
        isTreatmentConfiguration(item)
    );

    const singleTreatmentServiceCodes = treatmentConfigs.map((treatment) => ({
      ...treatment,
      associatedServiceCodes: this._getTreatmentADACodes(treatment),
    }));

    const multiTreatmentServiceCodes = items
      .filter((item): item is WithRef<IMultiTreatmentConfiguration> =>
        isMultiTreatmentConfiguration(item)
      )
      .map((multiTreatment) => ({
        ...multiTreatment,
        associatedServiceCodes: this._getMultiTreatmentADACodes(
          multiTreatment,
          treatmentConfigs
        ),
      }));

    return [...multiTreatmentServiceCodes, ...singleTreatmentServiceCodes];
  }

  private _getTreatmentADACodes(
    treatment: ITreatmentConfiguration
  ): ServiceCode[] {
    const serviceCodeGroups = treatment.serviceCodeGroups.map(
      (serviceCodeGroup) =>
        serviceCodeGroup.serviceCodes.map((codes) => codes.code)
    );
    const smartServiceCodes = treatment.serviceCodeSmartGroups.map(
      (serviceCodeSmartGroup) =>
        serviceCodeSmartGroup.serviceCodes.map((code) => code)
    );

    return [...flatten(serviceCodeGroups), ...flatten(smartServiceCodes)];
  }

  private _getMultiTreatmentADACodes(
    multiTreatment: IMultiTreatmentConfiguration,
    treatmentConfigs: WithRef<ITreatmentConfiguration>[]
  ): ServiceCode[] {
    const treatmentRefs = multiTreatment.steps.reduce(
      (treatmentConfigRefs: ITreatmentConfigurationRef[], step) => [
        ...treatmentConfigRefs,
        ...step.treatments,
      ],
      []
    );
    const resolvedTreatments = compact(
      treatmentRefs.map((treatmentRef) =>
        treatmentConfigs.find((treatment) => isSameRef(treatment, treatmentRef))
      )
    );
    return flatten(
      resolvedTreatments.map((treatment) =>
        this._getTreatmentADACodes(treatment)
      )
    );
  }
}
