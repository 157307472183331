import {
  Appointment,
  Event,
  Patient,
  SchedulingEvent,
  TimezoneResolver,
  TreatmentStep,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  ISchedulingEventData,
  ParticipantType,
  TreatmentPlanStatus,
  isAutomatedNotification,
  isAutomation,
  isEventable,
  isGeneratedTask,
  isTreatmentPlanWithBookableStep,
  type IAppointment,
  type IAppointmentRequest,
  type IAutomation,
  type IBrand,
  type IChecklistItem,
  type IEvent,
  type IInteractionV2,
  type IPatient,
  type IStaffer,
  type ITag,
  type ITreatmentStep,
  type TreatmentStepAutomation,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  asyncForEach,
  isINamedDocument,
  snapshot,
  toNamedDocument,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { type CreateAppointmentDetails } from '../appointment-details';
import { AppointmentTreatmentPlanAssociator } from '../appointment-treatment-plan-associator';
import {
  convertWaitListDetailsToItem,
  initWaitListDetails,
  type IWaitListDetails,
} from '../waitlist-details';

export class AppointmentCreator {
  static async addTreatmentPlanAssociation(
    appointment: WithRef<IAppointment>,
    duration: number
  ): Promise<void> {
    const step = await Firestore.getDoc<ITreatmentStep>(
      appointment.treatmentPlan.treatmentStep.ref
    );
    if (step.appointment) {
      throw new Error('Selected Treatment Step already has appointment');
    }
    if (!step.schedulingRules.duration) {
      step.schedulingRules.duration = duration;
    }
    await Firestore.patchDoc(step.ref, {
      ...step,
      appointment: appointment.ref,
    });
  }

  static async syncPlanStatus(
    appointment: WithRef<IAppointment>
  ): Promise<void> {
    const plan = await Appointment.treatmentPlan(appointment);
    if (plan.status === TreatmentPlanStatus.InProgress) {
      return;
    }

    await Firestore.patchDoc(plan.ref, {
      status: TreatmentPlanStatus.InProgress,
    });
  }

  static async buildAppointmentFromDetails(
    appointmentDetails: CreateAppointmentDetails,
    patient: WithRef<IPatient>,
    owner: INamedDocument<IStaffer>,
    appointmentRequest?: WithRef<IAppointmentRequest>
  ): Promise<IAppointment> {
    const treatmentPlan =
      await AppointmentTreatmentPlanAssociator.getAssociatedTreatmentPlan(
        patient,
        appointmentDetails.treatment,
        appointmentDetails.overridePlan
      );

    if (!treatmentPlan) {
      throw new Error('No associated treatment plan found for appointment');
    }

    return Appointment.init({
      treatmentPlan,
      practitioner: owner,
      practice: toNamedDocument(appointmentDetails.practice),
      appointmentRequestRef: appointmentRequest?.ref,
    });
  }

  static setEvent(
    appointment: IAppointment,
    patient: WithRef<IPatient>,
    event?: IEvent
  ): void {
    if (event) {
      event = Event.addParticipants(event, [
        {
          ...toNamedDocument(patient),
          type: ParticipantType.Patient,
        },
      ]);
    }

    Appointment.replaceEvent(appointment, event);
    if (isEventable(appointment)) {
      appointment.status = AppointmentStatus.Scheduled;
    }
  }

  static async setMetadata(
    appointment: IAppointment,
    tags: INamedDocument<ITag>[] = [],
    brand: WithRef<IBrand>,
    waitlistDetails?: IWaitListDetails
  ): Promise<void> {
    appointment.tags = tags;

    const defaultWaitlistSettingsOn =
      brand.settings.scheduling?.defaultWaitlistSettingsOn;

    if (waitlistDetails || defaultWaitlistSettingsOn) {
      const timezone = await TimezoneResolver.fromEvent(appointment);
      appointment.waitListItem = convertWaitListDetailsToItem(
        timezone,
        waitlistDetails ?? initWaitListDetails()
      );
    }
  }

  static getPractitioner(
    appointmentDetails: CreateAppointmentDetails,
    event?: IEvent
  ): INamedDocument<IStaffer> | undefined {
    if (event) {
      return event.organiser ?? toNamedDocument(Event.staff(event)[0]);
    }
    return isINamedDocument<IStaffer>(appointmentDetails.practitioner)
      ? appointmentDetails.practitioner
      : undefined;
  }

  static async getAppointment(
    appointmentDetails: CreateAppointmentDetails,
    patient: WithRef<IPatient>,
    staffer: WithRef<IStaffer>,
    appointmentRequest?: WithRef<IAppointmentRequest>
  ): Promise<IAppointment | WithRef<IAppointment>> {
    if (isTreatmentPlanWithBookableStep(appointmentDetails.treatment)) {
      const existingAppointment = await snapshot(
        TreatmentStep.appointment$(appointmentDetails.treatment.step)
      );
      if (existingAppointment) {
        return existingAppointment;
      }
    }
    return AppointmentCreator.buildAppointmentFromDetails(
      appointmentDetails,
      patient,
      stafferToNamedDoc(staffer),
      appointmentRequest
    );
  }

  static async addAutomations(
    appointment: WithRef<IAppointment>,
    automations: IAutomation<TreatmentStepAutomation>[]
  ): Promise<void> {
    const filteredAutomations = automations.filter(
      (automation) =>
        isAutomation(automation, isGeneratedTask) ||
        isAutomation(automation, isAutomatedNotification)
    );
    if (!filteredAutomations.length) {
      return;
    }
    await Appointment.addAutomations(appointment, automations);
  }

  static async addChecklistItems(
    appointment: WithRef<IAppointment>,
    checklists: IChecklistItem[]
  ): Promise<void> {
    if (!checklists.length) {
      return;
    }
    await Appointment.addChecklistItems(appointment, checklists);
  }

  static async addInteractions(
    appointment: WithRef<IAppointment>,
    interactions: IInteractionV2[]
  ): Promise<void> {
    await asyncForEach(interactions, (interaction) =>
      Appointment.addInteraction(appointment, interaction)
    );
  }

  static async add(
    staffer: WithRef<IStaffer>,
    patient: WithRef<IPatient>,
    appointmentDetails: CreateAppointmentDetails,
    schedulingEventData: ISchedulingEventData,
    waitlistDetails?: IWaitListDetails,
    interactions: IInteractionV2[] = [],
    automations: IAutomation<TreatmentStepAutomation>[] = [],
    checklists: IChecklistItem[] = [],
    tags?: INamedDocument<ITag>[],
    event?: IEvent,
    appointmentRequest?: WithRef<IAppointmentRequest>
  ): Promise<WithRef<IAppointment>> {
    const newAppointment = await AppointmentCreator.getAppointment(
      appointmentDetails,
      patient,
      staffer,
      appointmentRequest
    );

    AppointmentCreator.setEvent(newAppointment, patient, event);

    const brand = await Firestore.getDoc(
      Firestore.getParentDocRef<IBrand>(patient.ref)
    );
    await AppointmentCreator.setMetadata(
      newAppointment,
      tags,
      brand,
      waitlistDetails
    );

    const appointment = await Patient.saveAppointment(patient, newAppointment);
    await AppointmentCreator.addTreatmentPlanAssociation(
      appointment,
      appointmentDetails.duration ?? 0
    );
    await AppointmentCreator.syncPlanStatus(appointment);
    await AppointmentCreator.addChecklistItems(appointment, checklists);
    await AppointmentCreator.addInteractions(appointment, interactions);
    await AppointmentCreator.addSchedulingEvent(
      appointment,
      staffer,
      schedulingEventData
    );
    await AppointmentCreator.addAutomations(appointment, automations);
    return appointment;
  }

  static async addSchedulingEvent(
    appointment: WithRef<IAppointment>,
    staffer: WithRef<IStaffer>,
    schedulingEventData: ISchedulingEventData
  ): Promise<void> {
    const eventAfter = SchedulingEvent.buildEventSnapshot(appointment);
    if (!SchedulingEvent.willCauseSchedulingEvent(undefined, eventAfter)) {
      return;
    }
    const schedulingEvent = SchedulingEvent.init({
      scheduledByStaffer: staffer.ref,
      scheduledByPractice: schedulingEventData.scheduledByPractice,
      reason: schedulingEventData.reason,
      reasonSetManually: schedulingEventData.reasonSetManually,
      schedulingConditions: schedulingEventData.schedulingConditions,
      eventAfter,
    });
    const owner = stafferToNamedDoc(staffer);
    await Appointment.addSchedulingEvent(appointment, schedulingEvent, owner);
  }
}
