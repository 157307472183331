<mat-drawer-container class="flex min-h-full w-full">
  <mat-drawer mode="side" position="end" opened disableClose>
    <pr-interactions
      compact
      editable
      [enableScroll]="true"
      [interactions]="interactions$ | ngrxPush"
      (interactionAdded)="addInteraction($event)"
      (interactionUpdated)="updateInteraction($event)"
      (interactionDeleted)="deleteInteraction($event)"
    />
  </mat-drawer>
  <mat-drawer-content>
    @if (store.form$ | async; as patientForm) {
      <div class="mx-auto flex max-w-6xl flex-col gap-2 p-4">
        <div class="flex flex-row items-center gap-4">
          <div class="flex grow flex-row items-center">
            <button mat-icon-button [routerLink]="['../']">
              <mat-icon>chevron_left</mat-icon>
            </button>

            <h3 class="!m-0 !font-bold">{{ patientForm | map: formName }}</h3>
          </div>

          <pr-patient-form-actions />
        </div>

        <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow">
          <pr-patient-form-toolbar (formSelected)="redirectToForm($event)" />
          <pr-patient-form-content />
        </div>
      </div>
    } @else {
      <pr-empty-state image="list" title="Patient Form" />
    }
  </mat-drawer-content>
</mat-drawer-container>
