import {
  Component,
  Output,
  EventEmitter,
  Input,
  type OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { Subject, ReplaySubject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { DEFAULT_TIME_RANGES } from './default-ranges';
import { type ITimeRange } from './time-range';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'pr-range-selector',
  templateUrl: './range-selector.component.html',
  styleUrls: ['./range-selector.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeSelectorComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByRange = TrackByFunctions.title<ITimeRange>();
  selected$: ReplaySubject<ITimeRange | undefined> = new ReplaySubject(1);
  rangeControl: TypedFormControl<ITimeRange> = new TypedFormControl();
  ranges: ITimeRange[] = DEFAULT_TIME_RANGES;
  @Input() label = 'Date Range';
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Output()
  rangeChanged: EventEmitter<ITimeRange> = new EventEmitter<ITimeRange>();
  @Output() rangeReset: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  set selected(range: ITimeRange | undefined) {
    this.selected$.next(range);
  }

  constructor() {
    this.rangeControl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value: ITimeRange) => {
        this.rangeChanged.emit(value);
      });

    this.selected$
      .pipe(
        map((selected) => {
          if (!selected) {
            return undefined;
          }
          return this.ranges.find((range) => range.title === selected.title);
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((found) => {
        if (found) {
          this.rangeControl.setValue(found);
          return;
        }
        this.rangeControl.reset(undefined, { emitEvent: false });
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
