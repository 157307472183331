import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CalendarUnit } from '@principle-theorem/principle-core/interfaces';
import {
  CASUAL_DATE_WITH_YEAR,
  DAY_OF_MONTH_FORMAT,
  type ITimePeriod,
  MomentRange,
  MONTH_FORMAT,
  YEAR_FORMAT,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  ReplaySubject,
} from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-calendar-range-header',
  templateUrl: './calendar-range-header.component.html',
  styleUrls: ['./calendar-range-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarRangeHeaderComponent {
  unit$ = new ReplaySubject<CalendarUnit>(1);
  range$ = new ReplaySubject<ITimePeriod>(1);
  isCalendarView$ = new BehaviorSubject<boolean>(false);
  header$: Observable<string>;

  @Input()
  set isCalendarView(isCalendarView: BooleanInput) {
    this.isCalendarView$.next(coerceBooleanProperty(isCalendarView));
  }

  @Input()
  set range(range: ITimePeriod) {
    if (!range) {
      return;
    }
    this.range$.next(range);
  }

  @Input()
  set unit(unit: CalendarUnit) {
    if (!unit) {
      return;
    }
    this.unit$.next(unit);
  }

  constructor() {
    this.header$ = combineLatest([
      this.unit$,
      this.range$,
      this.isCalendarView$,
    ]).pipe(
      map(([unit, range, isCalendarView]) => {
        if (!isCalendarView) {
          if (MomentRange.isSameDay(range)) {
            return range.from.clone().format(CASUAL_DATE_WITH_YEAR);
          }

          const formatString = `${MONTH_FORMAT} ${DAY_OF_MONTH_FORMAT}`;
          const fromString = range.from.clone().format(formatString);
          const toString = range.to
            .clone()
            .format(`${formatString} ${YEAR_FORMAT}`);
          return `${fromString} - ${toString}`;
        }

        switch (unit) {
          case CalendarUnit.Month:
            return range.from.clone().format(`${MONTH_FORMAT} ${YEAR_FORMAT}`);
          case CalendarUnit.Week:
            const formatString = `${MONTH_FORMAT} ${DAY_OF_MONTH_FORMAT}`;
            const fromString = range.from
              .clone()
              .startOf('week')
              .format(formatString);
            const toString = range.from
              .clone()
              .endOf('week')
              .format(`${formatString} ${YEAR_FORMAT}`);
            return `${fromString} - ${toString}`;
          default:
            return range.from.clone().format(CASUAL_DATE_WITH_YEAR);
        }
      })
    );
  }
}
