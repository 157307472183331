import {
  IBrandQueryRequest,
  IDateRangeQueryRequest,
  IPracticeQueryRequest,
  isBrandQueryRequest,
  isDateRangeQueryRequest,
  isPracticeQueryRequest,
  QueryScopeConfigId,
} from '@principle-theorem/principle-core/interfaces';
import { FACT_TABLE_PREFIX, toFactPropertyName } from '../models/base-measures';
import { resolveCanQueryByTimestampProperty } from '../models/fact-tables';
import { BigQuerySQL } from '../querying';
import {
  BuiltQueryScope,
  IQueryScopeBuilderContext,
  IQueryScopeConfig,
} from './query-scope-config';

export class BrandQueryScopeConfig
  implements IQueryScopeConfig<IBrandQueryRequest>
{
  id = QueryScopeConfigId.Brand;
  isValid = isBrandQueryRequest;

  constructor(
    public required: boolean = false,
    private _targetRefPath: string = 'brand.ref.referenceValue'
  ) {}

  buildQuery(request: IBrandQueryRequest): BuiltQueryScope {
    const brandTarget = this._targetRefPath;
    const propertyPath = toFactPropertyName(brandTarget, FACT_TABLE_PREFIX);
    return {
      attributes: [brandTarget],
      filters: [BigQuerySQL.docRef(propertyPath, '=', request.brandRef)],
    };
  }
}

export class PracticeQueryScopeConfig
  implements IQueryScopeConfig<IPracticeQueryRequest>
{
  id = QueryScopeConfigId.Practice;
  isValid = isPracticeQueryRequest;

  constructor(
    public required: boolean = false,
    private _targetRefPath: string = 'practice.ref.referenceValue'
  ) {}

  buildQuery(request: IPracticeQueryRequest): BuiltQueryScope {
    const practiceTarget = this._targetRefPath;
    const propertyPath = toFactPropertyName(practiceTarget, FACT_TABLE_PREFIX);
    const filter = BigQuerySQL.or(
      request.practiceRefs.map((practiceRef) =>
        BigQuerySQL.docRef(propertyPath, '=', practiceRef)
      )
    );
    return {
      attributes: [practiceTarget],
      filters: [filter],
    };
  }
}

export class DateRangeQueryScopeConfig
  implements IQueryScopeConfig<IDateRangeQueryRequest>
{
  id = QueryScopeConfigId.DateRange;
  isValid = isDateRangeQueryRequest;

  constructor(public required: boolean = true) {}

  buildQuery(
    request: IDateRangeQueryRequest,
    context: IQueryScopeBuilderContext
  ): BuiltQueryScope {
    const queryableTimestamp = resolveCanQueryByTimestampProperty(
      context.factTable,
      request.queryableTimestamp
    );
    if (!queryableTimestamp) {
      throw new Error(
        `Cannot query by timestamp '${request.queryableTimestamp}' on table '${context.factTable.id}'`
      );
    }
    return {
      attributes: [queryableTimestamp.queryBy.attributePath],
      filters: [
        BigQuerySQL.timestamp(
          queryableTimestamp.queryBy.factPropertyPath,
          '>=',
          request.from
        ),
        BigQuerySQL.timestamp(
          queryableTimestamp.queryBy.factPropertyPath,
          '<=',
          request.to
        ),
      ],
    };
  }
}
