import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  ConditionLogic,
  getConditionImplementation,
} from '@principle-theorem/principle-core';
import {
  type ConditionLogicConfigurationCollection,
  ConditionLogicId,
  type IAndConditions,
  type IConditionLogicConfiguration,
  type IDynamicForm,
  type IOrConditions,
} from '@principle-theorem/principle-core/interfaces';
import { flatten } from 'lodash';

export interface IConditionalLogicState {
  conditions: ConditionLogicConfigurationCollection;
  availableConditions: ConditionLogicId[];
  defaultCondition: ConditionLogicId.Never | ConditionLogicId.Always;
}

@Injectable()
export class ConditionalLogicStore extends ComponentStore<IConditionalLogicState> {
  readonly conditions$ = this.select((store) => store.conditions);
  readonly orGroups$ = this.select((store) => store.conditions.or);
  readonly availableConditions$ = this.select(
    (store) => store.availableConditions
  );
  readonly canDeleteConditions$ = this.select((store) =>
    this._canDeleteConditions(store.conditions)
  );

  constructor(private _brandScope: CurrentBrandScope) {
    super({
      availableConditions: [],
      defaultCondition: ConditionLogicId.Always,
      conditions: ConditionLogic.collection([
        ConditionLogic.and([ConditionLogic.always()]),
      ]),
    });
  }

  async getDynamicForm(
    conditionId?: ConditionLogicId,
    initialValue?: unknown
  ): Promise<IDynamicForm> {
    if (!conditionId) {
      return {};
    }
    const condition = getConditionImplementation(conditionId);
    const brand = await this._brandScope.toPromise();
    return condition.getForm(brand, initialValue);
  }

  addGroup(): void {
    this.patchState((state) => ({
      conditions: {
        ...state.conditions,
        or: [
          ...state.conditions.or,
          ConditionLogic.and([this._createDefaultCondition()]),
        ],
      },
    }));
  }

  deleteGroup(group: IAndConditions): void {
    this.patchState((state) => ({
      conditions: {
        ...state.conditions,
        or: state.conditions.or.filter((item) => item.uid !== group.uid),
      },
    }));
  }

  updateGroup(group: IAndConditions): void {
    this.patchState((state) => ({
      conditions: {
        ...state.conditions,
        or: state.conditions.or.map((item) =>
          item.uid === group.uid ? group : item
        ),
      },
    }));
  }

  addCondition(
    group: IAndConditions,
    overrideCondition?: IConditionLogicConfiguration<unknown>
  ): void {
    const condition = overrideCondition ?? this._createDefaultCondition();
    this.updateGroup({
      ...group,
      and: [...group.and, condition],
    });
  }

  deleteCondition(
    group: IAndConditions,
    condition: IConditionLogicConfiguration<unknown>
  ): void {
    const and = group.and.filter((item) => item.uid !== condition.uid);
    if (and.length === 0) {
      this.deleteGroup(group);
      return;
    }
    this.updateGroup({
      ...group,
      and: group.and.filter((item) => item.uid !== condition.uid),
    });
  }

  updateCondition(
    group: IAndConditions,
    condition: IConditionLogicConfiguration<unknown>
  ): void {
    this.updateGroup({
      ...group,
      and: group.and.map((item) =>
        item.uid === condition.uid ? condition : item
      ),
    });
  }

  private _canDeleteConditions(conditions: IOrConditions): boolean {
    const allConditions = flatten(conditions.or.map((or) => or.and));
    return allConditions.length > 1;
  }

  private _createDefaultCondition(): IConditionLogicConfiguration<unknown> {
    const state = this.state();
    if (state.defaultCondition === ConditionLogicId.Never) {
      return ConditionLogic.never();
    }
    return ConditionLogic.always();
  }
}
