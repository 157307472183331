<pr-chart-view-toolbar [showHistorySelector]="showHistorySelector">
  <pr-perio-chart-settings />
</pr-chart-view-toolbar>

<div *ngFor="let perioTable of perioTables$ | async; trackBy: trackByLabel">
  <pr-perio-chart-display
    [perioTable]="perioTable"
    (perioCellChange)="handleChange($event)"
  />
</div>
