import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { RawInlineNodes, getSchemaSize } from '@principle-theorem/editor';
import {
  GlobalStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  BasicDialogService,
  ConnectedDialogConfig,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import { Interaction } from '@principle-theorem/principle-core';
import {
  IInteractionV2,
  WithContext,
  isInteractionV2,
  type IChatMessage,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_TIME_FORMAT,
  filterUndefined,
  isSameRef,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject, of, type Observable, Subject } from 'rxjs';
import { ReactionSelectorDialogComponent } from '../reaction-selector-dialog/reaction-selector-dialog.component';
import { takeUntil } from 'rxjs/operators';

type MessageElements = QueryList<ElementRef<HTMLElement>>;
@Component({
    selector: 'pr-chat-body',
    templateUrl: './chat-body.component.html',
    styleUrls: ['./chat-body.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChatBodyComponent implements AfterViewInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  dateFormat = DATE_TIME_FORMAT;
  messages$ = new ReplaySubject<
    (WithRef<WithContext<IInteractionV2>> | WithRef<IChatMessage>)[]
  >(1);
  staffer$: Observable<WithRef<IStaffer>>;

  @Output() messagesLoaded = new EventEmitter<boolean>();
  @Output() messagesUpdated = new EventEmitter<boolean>();
  @ViewChildren('messageElement') messageElements: MessageElements;

  @Input()
  set messages(
    messages: (WithRef<WithContext<IInteractionV2>> | WithRef<IChatMessage>)[]
  ) {
    if (messages) {
      this.messages$.next(messages);
    }
  }

  constructor(
    private _globalStore: GlobalStoreService,
    private _organisation: OrganisationService,
    private _dialog: BasicDialogService
  ) {
    this.staffer$ = this._organisation.staffer$.pipe(filterUndefined());
  }

  ngAfterViewInit(): void {
    this.messagesLoaded.emit(true);

    this.messageElements.changes
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this.messagesUpdated.emit(true));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getAuthorName$(
    message: WithContext<IInteractionV2> | WithRef<IChatMessage>
  ): Observable<string | undefined> {
    if (isInteractionV2(message)) {
      return of(undefined);
    }
    return this._globalStore.getStafferName$({ ref: message.authorRef });
  }

  getAuthorImage$(
    message: WithContext<IInteractionV2> | WithRef<IChatMessage>
  ): Observable<string | undefined> {
    if (isInteractionV2(message)) {
      return of(undefined);
    }
    return this._globalStore.getStafferImage$({ ref: message.authorRef });
  }

  isMessage(
    message: WithContext<IInteractionV2> | WithRef<IChatMessage>
  ): message is WithRef<IChatMessage> {
    return !isInteractionV2(message);
  }

  addReaction(
    message: WithContext<IInteractionV2> | WithRef<IChatMessage>,
    elementRef: HTMLElement
  ): void {
    if (isInteractionV2(message)) {
      return;
    }

    this._dialog.connected(
      ReactionSelectorDialogComponent,
      this._getDialogConfig(message, elementRef)
    );
  }

  getReactions(
    message: WithContext<IInteractionV2> | WithRef<IChatMessage>
  ): IChatMessage['reactions'] {
    if (isInteractionV2(message)) {
      return [];
    }
    return message.reactions;
  }

  getInteractionTitle(
    message: WithContext<IInteractionV2> | WithRef<IChatMessage>
  ): RawInlineNodes {
    if (!isInteractionV2(message)) {
      return [];
    }
    return Interaction.getTitle(message);
  }

  hasContent(
    message: WithContext<IInteractionV2> | WithRef<IChatMessage>
  ): boolean {
    return getSchemaSize(message.content) > 0;
  }

  async isMessageAuthor(
    message: WithContext<IInteractionV2> | WithRef<IChatMessage>
  ): Promise<boolean> {
    if (isInteractionV2(message)) {
      return false;
    }
    const staffer = await snapshot(this.staffer$);
    return isSameRef(message.authorRef, staffer.ref);
  }

  private _getDialogConfig(
    message: WithRef<IChatMessage>,
    connectedTo: HTMLElement
  ): ConnectedDialogConfig {
    return {
      ...DialogPresets.flex({
        data: { message },
      }),
      connectedTo: new ElementRef(connectedTo),
      autoFocus: true,
      backdropClass: 'no-backdrop',
      positions: [
        {
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center',
          offsetX: -40,
          offsetY: 100,
        },
        {
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center',
          offsetX: -40,
          offsetY: 0,
        },
        {
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center',
          offsetX: -40,
          offsetY: -100,
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'center',
          offsetX: -40,
          offsetY: 100,
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'center',
          offsetX: -40,
          offsetY: 0,
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'center',
          offsetX: -40,
          offsetY: -100,
        },
      ],
    };
  }
}
