<mat-toolbar color="accent">Edit Table Name</mat-toolbar>

<mat-dialog-content fxLayout="column">
  <form [formGroup]="form" fxLayout="column" (ngSubmit)="submit()">
    <div class="flex flex-col gap-4">
      <mat-form-field fxFlex>
        <mat-label>Table Name</mat-label>
        <input matInput formControlName="label" />
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
    <button type="submit" mat-flat-button color="primary" (click)="submit()">
      Save
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
