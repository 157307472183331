<mat-toolbar color="accent">
  <span fxFlex>Email {{ data.contact.key }}</span>
</mat-toolbar>

<mat-dialog-content>
  <form fxLayout="column" [formGroup]="emailForm" (ngSubmit)="submit()">
    <pr-practice-selector
      fxFlex
      label="Send From"
      [brands]="brands$ | async"
      formControlName="practice"
    />

    <mat-form-field fxFlex>
      <mat-label>Subject</mat-label>
      <input matInput formControlName="subject" />
    </mat-form-field>

    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Email Message</mat-label>
      <pr-content-editor
        placeholder="Email Message"
        format="email"
        [menuEnabled]="false"
        formControlName="content"
        (metaKeydownEvent)="submit()"
      />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxFill>
    <pr-template-selector
      [templates]="(templates$ | async)?.templates"
      [contextOptions]="(templates$ | async)?.contextOptions"
      [resetOnSelection]="true"
      (templateSelected)="templateSelected($event)"
    />

    <pt-buttons-container padding="none">
      <button mat-stroked-button (click)="dialogRef.close()">Close</button>
      <pt-loader-button
        color="primary"
        type="flat"
        [loading]="submitting$ | async"
        [disabled]="isDisabled$ | async"
        (click)="submit()"
      >
        Send
      </pt-loader-button>
    </pt-buttons-container>
  </div>
</mat-dialog-actions>
