<div
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutGap="2px"
  *ngIf="summary$ | ngrxPush as summary"
>
  <div class="mat-caption">
    {{ summary.from | moment | amDateFormat: timeFormat }} -
    {{ summary.to | moment | amDateFormat: timeFormat }}
  </div>
  <div class="mat-caption">{{ summary.duration }}</div>
</div>
