import { TreatmentCategory } from './treatment-category';
import { WithRef } from '@principle-theorem/shared';
import { ITreatmentCategory } from '@principle-theorem/principle-core/interfaces';

export enum TreatmentCategoryName {
  Periodontics = 'Periodontics',
  Prosthodontics = 'Prosthodontics',
  PreventativeProphylacticBleachingServices = 'Preventative, Prophylactic & Bleaching Services',
  Consultation = 'Consultation',
  ClearAligners = 'Clear Aligners',
  Orthodontics = 'Orthodontics',
  Endodontics = 'Endodontics',
  RestorativeServices = 'Restorative Services',
  OralSurgery = 'Oral Surgery',
  ExistingPatientCheckUpClean = 'Existing Patient Check Up & Clean',
  NewPatient = 'New Patient',
  TeethWhitening = 'Teeth Whitening',
  Hygiene = 'Hygiene',
}

export function getTreatmentCategoryByName(
  name: TreatmentCategoryName,
  treatmentCategories: WithRef<ITreatmentCategory>[]
): WithRef<ITreatmentCategory> {
  const treatmentCategory = treatmentCategories.find(
    (category) => category.name === String(name)
  );
  if (!treatmentCategory) {
    throw new Error('Category not found');
  }
  return treatmentCategory;
}

export const DEFAULT_TREATMENT_CATEGORIES = [
  {
    name: TreatmentCategoryName.Periodontics,
    colour: { value: '#651fff', name: 'Deep Purple a400' },
  },
  {
    name: TreatmentCategoryName.Prosthodontics,
    colour: { name: 'Yellow a700', value: '#ffd600' },
  },
  {
    name: TreatmentCategoryName.PreventativeProphylacticBleachingServices,
    colour: { name: 'Orange a400', value: '#ff9100' },
  },
  {
    name: TreatmentCategoryName.Consultation,
    colour: { name: 'Brown a100', value: '#d7ccc8' },
  },
  {
    name: TreatmentCategoryName.ClearAligners,
    colour: { name: 'Blue Grey a400', value: '#78909c' },
  },
  {
    name: TreatmentCategoryName.Orthodontics,
    colour: { name: 'Pink a200', value: '#ff4081' },
  },
  {
    name: TreatmentCategoryName.Endodontics,
    colour: { name: 'Green a700', value: '#00c853' },
  },
  {
    name: TreatmentCategoryName.RestorativeServices,
    colour: { value: '#ff9100', name: 'Orange a400' },
  },
  {
    name: TreatmentCategoryName.OralSurgery,
    colour: { name: 'Red a700', value: '#d50000' },
  },
  {
    name: TreatmentCategoryName.ExistingPatientCheckUpClean,
    colour: { name: 'Purple a100', value: '#ea80fc' },
  },
  {
    name: TreatmentCategoryName.NewPatient,
    colour: { value: '#e040fb', name: 'Purple a200' },
  },
  {
    name: TreatmentCategoryName.TeethWhitening,
    colour: { name: 'Light Blue a200', value: '#40c4ff' },
  },
  {
    name: TreatmentCategoryName.Hygiene,
    colour: { name: 'Lime a700', value: '#aeea00' },
  },
].map((category) => TreatmentCategory.init(category));
