<div
  class="medicare-details"
  *ngIf="integration$ | async as integration; else disabled"
>
  <mat-list>
    <mat-list-item>
      <div matListItemTitle>{{ integration.data.apiKey }}</div>
      <div matListItemLine>API Key</div>
    </mat-list-item>
  </mat-list>
  <button mat-stroked-button (click)="removeIntegration()">
    Remove Medipass Integration
  </button>
</div>

<ng-template #disabled>
  <div class="medipass-button" (click)="addIntegration()">
    <img alt="Add to Medipass" [src]="config.medipassLogoUrl" />
  </div>
</ng-template>
