<div
  class="layout-padding"
  fxLayout="column"
  fxLayoutGap="16px"
  *ngIf="lab$ | async as lab"
>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h1>Lab Job Types</h1>
    <button mat-flat-button color="primary" (click)="addJobType()">
      Create Lab Job Type
    </button>
  </div>

  <span *ngIf="!lab.labJobTypes.length" class="mat-caption">
    This lab has no lab job types
  </span>

  <mat-list
    *ngIf="lab.labJobTypes.length; else noLabJobTypes"
    class="job-types-card mat-elevation-z1"
  >
    <mat-list-item *ngFor="let type of lab.labJobTypes; trackBy: trackByType">
      <div matListItemTitle>{{ type.name }}</div>
      <div matListItemLine>{{ type.cost | currency }}</div>

      <div matListItemMeta>
        <button mat-icon-button [matMenuTriggerFor]="labTypeMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #labTypeMenu="matMenu">
          <button mat-menu-item (click)="editType(type)">
            <mat-icon>edit</mat-icon>
            Edit
          </button>
          <button mat-menu-item color="warn" (click)="deleteType(type)">
            <mat-icon>delete</mat-icon>
            Delete
          </button>
        </mat-menu>
      </div>
    </mat-list-item>
  </mat-list>
</div>

<ng-template #noLabJobTypes>
  <pr-empty-state image="list" title="lab job types" />
</ng-template>
