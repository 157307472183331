import { Pipe, type PipeTransform } from '@angular/core';
import { TimezoneResolver } from '@principle-theorem/principle-core';
import { type IPractice } from '@principle-theorem/principle-core/interfaces';
import { toMomentTz } from '@principle-theorem/shared';
import {
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import type * as moment from 'moment-timezone';
import { type Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'momentTimezone$',
})
export class MomentTimezonePipe implements PipeTransform {
  transform(
    value: Timestamp | moment.Moment,
    practiceRef: DocumentReference<IPractice>
  ): Observable<moment.Moment> {
    return from(TimezoneResolver.fromPracticeRef(practiceRef)).pipe(
      map((timezone) => toMomentTz(value, timezone))
    );
  }
}
