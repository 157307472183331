import {
  TypeGuard,
  isDocRef,
  isEnumValue,
  isNamedTypeDocument,
  isObject,
  isTimestamp,
  type DocumentReference,
  type INamedTypeDocument,
  type ISoftDelete,
  type Timestamp,
} from '@principle-theorem/shared';
import { isBoolean } from 'lodash';
import * as moment from 'moment-timezone';
import { type IBrand } from '../brand';
import { type ITreatmentConfiguration } from '../clinical-charting/treatment/treatment-configuration';
import { type ICloudTask } from '../cloud-task/cloud-task';
import { ConditionLogicConfigurationCollection } from '../condition-logic';
import { type IPractice } from '../practice/practice';
import { type IAutomationTiming } from './automation-timing';

export enum AutomationStatus {
  Pending = 'pending',
  Queued = 'queued',
  Completed = 'completed',
  Failed = 'failed',
  Cancelled = 'cancelled',
  Disabled = 'disabled',
  Skipped = 'skipped',
}

export enum AutomationType {
  GeneratedTask = 'generatedTask',
  AutomatedNotification = 'automatedNotification',
  AutomatedFormIssue = 'automatedFormIssue',
  CloudTaskReschedule = 'cloudTaskReschedule',
}

export const AUTOMATION_TYPES_LABEL_MAP: { [key in AutomationType]: string } = {
  [AutomationType.GeneratedTask]: 'Task',
  [AutomationType.AutomatedNotification]: 'Notification',
  [AutomationType.AutomatedFormIssue]: 'Form',
  [AutomationType.CloudTaskReschedule]: '',
};

export function isAutomationType(data: unknown): data is AutomationType {
  return data && isEnumValue(AutomationType, data) ? true : false;
}

export enum AutomationCreator {
  Staffer = 'staffer',
  TreatmentConfiguration = 'treatmentConfiguration',
  Brand = 'brand',
  Practice = 'practice',
}

export interface IAutomationConfiguration extends ISoftDelete {
  isActive: boolean;
  practiceRef?: DocumentReference<IPractice>;
  treatmentRefs: DocumentReference<ITreatmentConfiguration>[];
}

export const isAutomationConfiguration =
  TypeGuard.interface<IAutomationConfiguration>({
    deleted: isBoolean,
    isActive: isBoolean,
    treatmentRefs: TypeGuard.arrayOf(isDocRef),
    practiceRef: TypeGuard.undefinedOr(isDocRef),
  });

export interface IAutomationResource {
  timing: IAutomationTiming;
  requiredConditions?: ConditionLogicConfigurationCollection;
}

export interface IAutomation<Resource extends IAutomationResource> {
  type: AutomationType;
  status: AutomationStatus;
  data: Resource;
  cloudTask?: ICloudTask;
  creator: INamedTypeDocument;
  triggerDate?: Timestamp;
  triggerAfterDate?: Timestamp;
  cloudTaskHistory: ICloudTask[];
  brandRef: DocumentReference<IBrand>;
  practiceRef?: DocumentReference<IPractice>;
  configRef?: DocumentReference<IAutomationConfiguration>;
}

export function isAutomation<T extends IAutomationResource>(
  automation: unknown,
  dataGuard: (data: unknown) => data is T
): automation is IAutomation<T> {
  return (
    isObject(automation) &&
    isEnumValue(AutomationType, automation.type) &&
    isEnumValue(AutomationStatus, automation.status) &&
    TypeGuard.undefinedOr(isTimestamp)(automation.triggerDate) &&
    isNamedTypeDocument(automation.creator) &&
    dataGuard(automation.data)
  );
}

export const AUTOMATION_STATUS_COLOUR_MAP: {
  [key in AutomationStatus]: string;
} = {
  [AutomationStatus.Pending]: 'default',
  [AutomationStatus.Queued]: 'accent',
  [AutomationStatus.Completed]: 'primary',
  [AutomationStatus.Skipped]: 'primary',
  [AutomationStatus.Failed]: 'warn',
  [AutomationStatus.Cancelled]: 'warn',
  [AutomationStatus.Disabled]: 'warn',
};

export const AUTOMATION_GRACE_PERIOD = moment.duration(2, 'minutes');
