import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { deleteDoc, patchDoc, type WithRef } from '@principle-theorem/shared';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogInput,
} from '@principle-theorem/ng-shared';
import { type ITag } from '@principle-theorem/principle-core/interfaces';
import {
  EditTagDialogComponent,
  type IEditTagDialogData,
  type TagFrom,
} from '../../edit-tag-dialog/edit-tag-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'pr-tag-list-item',
  templateUrl: './tag-list-item.component.html',
  styleUrls: ['./tag-list-item.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagListItemComponent {
  @Input() tag: WithRef<ITag>;

  constructor(private _dialog: MatDialog, private _snackBar: MatSnackBar) {}

  async edit(): Promise<void> {
    const updateData = await this._openEditDialog();
    if (!updateData) {
      return;
    }
    await patchDoc(this.tag.ref, updateData);
  }

  async delete(tag: WithRef<ITag>): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Tag',
      prompt: 'Are you sure you want to delete this tag?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }
    await deleteDoc(tag.ref);
    this._snackBar.open('Tag Deleted');
  }

  private async _openEditDialog(): Promise<TagFrom | undefined> {
    const config: MatDialogConfig<IEditTagDialogData> = {
      data: {
        title: 'Edit Tag',
        tag: this.tag,
      },
    };
    return this._dialog
      .open<EditTagDialogComponent, IEditTagDialogData, TagFrom>(
        EditTagDialogComponent,
        DialogPresets.small<IEditTagDialogData>(config)
      )
      .afterClosed()
      .toPromise();
  }
}
