import {
  ServiceCodeType,
  type ITreatmentConfiguration,
  ITranslationMap,
} from '@principle-theorem/principle-core/interfaces';
import {
  type AtLeast,
  type INamedDocument,
  isSameRef,
  type IXSLXExport,
  type XSLXCell,
} from '@principle-theorem/shared';
import type { Column } from 'exceljs';
import {
  PricedServiceCodeTranslator,
  ServiceProviderHandler,
} from '@principle-theorem/principle-core';
import { IBaseMigrationItemCode } from '../interfaces';

export interface IItemCodeToTreatmentXSLX {
  code: string;
  description: string;
  mapTo: string;
}

export const ITEM_CODE_TO_TREATMENT_HEADERS: AtLeast<
  Column,
  'key' | 'header'
>[] = [
  {
    key: 'code',
    header: 'code',
  },
  {
    key: 'description',
    header: 'Description',
    width: 60,
  },
  {
    key: 'mapTo',
    header: 'Map To',
    width: 30,
  },
];

export class ItemCodeToTreatmentToXSLX<T extends IBaseMigrationItemCode>
  implements IXSLXExport<T, IItemCodeToTreatmentXSLX>
{
  headers = ITEM_CODE_TO_TREATMENT_HEADERS;

  constructor(
    private _treatmentConfigurations: INamedDocument<ITreatmentConfiguration>[],
    private _existingMappings: ITranslationMap<ITreatmentConfiguration>[],
    private _suggestedMappings: { [key: string]: string }
  ) {}

  translate(records: T[]): Record<keyof IItemCodeToTreatmentXSLX, XSLXCell>[] {
    return records.map((record) => {
      const existingRecord = this._existingMappings.find(
        (existingMapping) =>
          existingMapping.sourceIdentifier === record.id.toString()
      );

      const destinationIdentifier = existingRecord?.destinationIdentifier;
      let suggestedMappingTreatmentName =
        this._suggestedMappings[record.itemCode] ?? undefined;
      if (!suggestedMappingTreatmentName) {
        const serviceCode = ServiceProviderHandler.findServiceCode(
          record.itemCode
        );
        if (serviceCode) {
          const parentCode = PricedServiceCodeTranslator.convertToType(
            serviceCode,
            ServiceCodeType.ADA
          );

          suggestedMappingTreatmentName =
            this._suggestedMappings[parentCode.code] ?? undefined;
        }
      }

      const mappedTreatment = destinationIdentifier
        ? this._treatmentConfigurations.find((configuration) =>
            isSameRef(configuration, destinationIdentifier)
          )
        : suggestedMappingTreatmentName
          ? this._treatmentConfigurations.find(
              (configuration) =>
                configuration.name === suggestedMappingTreatmentName
            )
          : undefined;

      return {
        code: {
          value: record.itemCode,
        },
        description: {
          value: record.description,
        },
        mapTo: {
          value: mappedTreatment?.name,
          dataValidation: {
            type: 'list',
            formulae: [
              `"${this._treatmentConfigurations
                .map((configuration) => configuration.name)
                .join(',')}"`,
            ],
          },
        },
      };
    });
  }
}
