<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="wrapper" *ngIf="user$ | async as user">
  <pr-manage-user
    [user]="user"
    [isLoggedInUser]="false"
    [brands]="brands$ | async"
    [roles]="roles$ | async"
    (inviteUser)="invite(user)"
  />
</div>
