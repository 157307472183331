import {
  IFileData,
  ISQLSeedingTranslator,
} from '@principle-theorem/principle-core/interfaces';
import { IProvider } from '@principle-theorem/shared';

export class CorePracticeAppointmentsTranslator
  implements ISQLSeedingTranslator, IProvider<IFileData, string[]>
{
  matchingFilenames = ['tblAppointment.csv'];
  commands = [`sed -i 's/Start/StartTime/'`, `sed -i 's/End/EndTime/'`];

  canProvide(data: IFileData): boolean {
    return this.matchingFilenames.includes(data.fileName);
  }

  execute(data: IFileData): string[] {
    return this.commands.map((cmd) => `${cmd} ${data.filePath}`);
  }
}
