import { User } from '@principle-theorem/principle-core';
import {
  ServiceTypeModality,
  SourceEntityRecordStatus,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  BaseStafferDestinationEntity,
  IStafferJobData,
  IStafferMigrationData,
} from '../../../destination/entities/staff';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  StafferSourceEntity,
  type IPraktikaStaffer,
} from '../../source/entities/staff';
import { PraktikaPracticeMappingHandler } from '../mappings/practices';
import { PraktikaStafferMappingHandler } from '../mappings/staff';
import { PraktikaStaffToUserMappingHandler } from '../mappings/staff-to-user';

export const STAFF_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: 'staff',
    label: 'Staff',
    description: `Roles will need to be assigned to practitioners with the "can conduct appointments" permission before any further migrating can be done.

    In the case of multiple practice migrations where there are staff accounts for the same person in each location, the user will need to be created beforehand and mapped to the Praktika staff accounts using the "Multi-staffer to User" custom mapping.`,
  },
});

export class StafferDestinationEntity extends BaseStafferDestinationEntity<IPraktikaStaffer> {
  destinationEntity = STAFF_DESTINATION_ENTITY;
  stafferSourceEntity = new StafferSourceEntity();
  stafferCustomMapping = new PraktikaStafferMappingHandler();
  staffToUserCustomMapping = new PraktikaStaffToUserMappingHandler();

  override sourceEntities = {
    staff: new StafferSourceEntity(),
  };

  customMappings = {
    staff: new PraktikaStafferMappingHandler(),
    staffToUser: new PraktikaStaffToUserMappingHandler(),
    practices: new PraktikaPracticeMappingHandler(),
  };

  buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IStafferJobData<IPraktikaStaffer>
  ):
    | IStafferMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord) {
    if (data.stafferRecord.record.status === SourceEntityRecordStatus.Invalid) {
      return this._buildErrorResponse(
        data.stafferRecord.record,
        'Source staffer is invalid'
      );
    }

    const sourceStafferId = this.sourceEntities.staff
      .getSourceRecordId(data.stafferRecord.data.data)
      .toString();

    const user = User.init({
      name: getStafferName(data.stafferRecord),
      email: '',
      practices: migration.configuration.practices.map(
        (practice) => practice.ref
      ),
      brands: [migration.configuration.brand.ref],
      isEnabled: false,
    });

    return {
      sourceStafferId,
      user,
      stafferDetails: {
        providerNumber: data.stafferRecord.data.data.provider_number ?? '',
        providerModality: ServiceTypeModality.GeneralDentist,
      },
    };
  }
}

function getStafferName(record: IGetRecordResponse<IPraktikaStaffer>): string {
  return `${record.data.data.first_name.trim()} ${record.data.data.last_name.trim()}`;
}
