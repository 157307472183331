<div fxLayout="column" fxLayoutGap="8px" *ngrxLet="eventable$ as eventable">
  <div class="flex flex-row gap-4">
    <mat-icon matTooltip="Time">schedule</mat-icon>
    <div fxLayout="column">
      <div
        *ngFor="
          let summary of eventTimeSummary$ | ngrxPush;
          trackBy: trackBySummary
        "
      >
        <div>{{ summary }}</div>
      </div>
    </div>
  </div>

  <div class="flex flex-row gap-4">
    <mat-icon matTooltip="Event type">
      {{ eventIcon$ | ngrxPush }}
    </mat-icon>
    <div>{{ eventable.event.type | splitCamel | titlecase }}</div>
  </div>

  <div class="flex flex-row gap-4">
    <mat-icon matTooltip="Title">title</mat-icon>
    <div>{{ title$ | ngrxPush }}</div>
  </div>

  @if (participants$ | async; as participants) {
    <div *ngIf="participants.length" class="flex flex-row gap-4">
      <mat-icon matTooltip="Event participants">group</mat-icon>
      <div fxLayout="column">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="12px"
          *ngFor="let participant of participants; trackBy: trackByParticipant"
        >
          <pt-user-icon
            [name]="participant.name"
            [src]="participant | profileImage$ | ngrxPush"
            [diameter]="30"
            [matTooltip]="participant.name"
          />
          <div>{{ participant.name }}</div>
        </div>
      </div>
    </div>
  }

  <pr-eventable-tags-preview [eventable]="eventable$ | ngrxPush" />
</div>
