<mat-toolbar color="accent"
  >{{ data.chart ? 'Edit' : 'Add' }} Chart</mat-toolbar
>

<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="flex items-center gap-4">
      <mat-stepper #stepper orientation="vertical" class="w-2/3">
        <mat-step>
          <ng-template matStepLabel
            >Select what data you want to see</ng-template
          >
          <ng-template matStepContent>
            <div class="flex flex-col gap-4">
              <pr-information-box class="mb-2">
                Select what data you'd like to see on the chart. This might be a
                value such as a dollar value ($), time value (mins or hrs), or
                counting the number of times something is in the report.
              </pr-information-box>

              <pr-report-builder-data-point-selector
                label="Data Point"
                [bloc]="bloc"
              />

              @if (bloc.filterOptions$ | async; as filterOptions) {
                @if (filterOptions.length) {
                  <div class="flex flex-col">
                    <mat-form-field class="flex-1" subscriptSizing="dynamic">
                      <mat-label>
                        Filter the data point's values to only a select few of
                        it's options
                      </mat-label>
                      <mat-select formControlName="filters" multiple>
                        @for (option of filterOptions; track option) {
                          <mat-option [value]="option">
                            {{ option | map: bloc.convertBoolean : bloc }}
                          </mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                    <div
                      class="px-4 py-1 text-[10.5px] leading-4 text-[rgba(0,0,0,.54)]"
                    >
                      Data points such as categories, tags, patient
                      demographics, "Yes/No" values can be filtered down to get
                      a count just for some of the values.
                    </div>
                  </div>
                }
              }

              <mat-form-field class="flex-1">
                <mat-label>
                  How do you want the data to be added together?
                </mat-label>
                <mat-select formControlName="reducer">
                  @for (option of bloc.reducerOptions$ | async; track option) {
                    <mat-option [value]="option">
                      {{ option | map: bloc.convertReducer : bloc }}
                    </mat-option>
                  }
                </mat-select>
                @if (form.controls.reducer.disabled) {
                  <mat-hint>
                    This data point can only be counted the number of times it
                    is in the report.
                  </mat-hint>
                }
              </mat-form-field>

              <div>
                <button
                  mat-flat-button
                  type="button"
                  color="primary"
                  matStepperNext
                >
                  Next
                </button>
              </div>
            </div>
          </ng-template>
        </mat-step>
        <mat-step
          [hasError]="(bloc.incompatibleGroupBySummary$ | async) !== undefined"
          [errorMessage]="bloc.incompatibleGroupBySummary$ | async"
        >
          <ng-template matStepLabel
            >How do you want to group the data?</ng-template
          >
          <ng-template matStepContent>
            <div class="flex flex-col gap-4">
              <pr-information-box class="mb-2">
                Select how you want your data grouped together. Some common ways
                to group your data would be to group by practitioner, date, or
                practice (for multi-practice reporting). For more nice reports
                you can also group by data points such as tags and categories.
              </pr-information-box>

              <mat-form-field class="flex-1">
                <mat-label>Group Data By</mat-label>
                <input
                  matInput
                  #autoComplete="matAutocompleteTrigger"
                  [matAutocomplete]="auto"
                  [formControl]="bloc.groupBySearchCtrl"
                  (click)="autoComplete.openPanel()"
                  (blur)="bloc.blurGroupBy()"
                />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="bloc.displayFn"
                  (optionSelected)="bloc.groupBySelected($event)"
                >
                  @for (
                    section of bloc.groupBySearch.results$ | async;
                    track section.name
                  ) {
                    @if (section.options.length) {
                      <mat-optgroup [label]="section.name">
                        @for (
                          option of section.options;
                          track option.measure.metadata.id
                        ) {
                          <mat-option
                            [value]="option.measure"
                            [matTooltip]="
                              option
                                | map: bloc.getCompatibleReducerSummary : bloc
                            "
                            [matTooltipDisabled]="
                              option
                                | map: bloc.isCompatibleWithReducer$ : bloc
                                | async
                            "
                            matTooltipPosition="right"
                            matTooltipShowDelay="300"
                          >
                            <span
                              [ngClass]="{
                                'text-red-500':
                                  (option
                                    | map: bloc.isCompatibleWithReducer$ : bloc
                                    | async) === false
                              }"
                              >{{ option.measure.metadata.label }}</span
                            >
                          </mat-option>
                        }
                      </mat-optgroup>
                    }
                  }
                </mat-autocomplete>
              </mat-form-field>

              <div class="flex gap-4">
                <button
                  mat-stroked-button
                  color="primary"
                  type="button"
                  matStepperPrevious
                >
                  Back
                </button>
                <button
                  mat-flat-button
                  color="primary"
                  type="button"
                  matStepperNext
                >
                  Next
                </button>
              </div>
            </div>
          </ng-template>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel
            >What type of chart do you want?</ng-template
          >
          <ng-template matStepContent>
            <div class="flex flex-col gap-4">
              <mat-form-field class="flex-1">
                <mat-label>Label</mat-label>
                <input matInput formControlName="label" />
              </mat-form-field>

              <mat-form-field class="flex-1">
                <mat-label>Chart Type</mat-label>
                <mat-select formControlName="type">
                  @for (chartType of chartTypes; track chartType) {
                    <mat-option [value]="chartType">
                      {{ chartType | splitCamel | titlecase }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <div>
                <button
                  mat-stroked-button
                  color="primary"
                  type="button"
                  matStepperPrevious
                >
                  Back
                </button>
              </div>
            </div>
          </ng-template>
        </mat-step>
      </mat-stepper>

      <div class="flex w-1/3 flex-col">
        <h2>Chart Preview</h2>
        @if (display$ | async; as display) {
          <div class="max-w-full self-center overflow-scroll">
            <pr-report-builder-chart
              sectionName=""
              readOnly
              class="!flex self-stretch"
              [display]="display"
            />
          </div>
        } @else {
          <div
            class="flex h-80 w-72 items-center justify-center self-center rounded-lg shadow-lg"
          >
            <span class="text-center text-gray-500"
              >Fill in details to see chart preview</span
            >
          </div>
        }
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      type="submit"
      mat-flat-button
      color="primary"
      [disabled]="isDisabled$ | async"
      (click)="submit()"
    >
      {{ data.chart ? 'Save' : 'Add' }} Chart
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
