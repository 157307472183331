<div class="flex gap-4">
  <pt-user-icon
    [src]="stafferImage$ | async"
    [name]="stafferName$ | async"
    [diameter]="26"
  />
  <span class="truncate">{{ stafferName$ | async }}</span>
</div>

<ng-template #loading>
  <mat-spinner [diameter]="26" />
</ng-template>
