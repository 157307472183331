import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentPatientScope } from '@principle-theorem/ng-principle-shared';
import { queryParam$ } from '@principle-theorem/ng-shared';
import { Patient } from '@principle-theorem/principle-core';
import {
  type IPatient,
  type ISubmittedFormHistory,
} from '@principle-theorem/principle-core/interfaces';
import {
  doc$,
  filterUndefined,
  MILLIS_IN_SECOND,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, Subject } from 'rxjs';
import { delay, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-medical-history-form-print',
  templateUrl: './medical-history-form-print.component.html',
  styleUrls: ['./medical-history-form-print.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicalHistoryFormPrintComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  patient$: Observable<WithRef<IPatient>>;
  selected$: Observable<ISubmittedFormHistory>;
  phone$: Observable<string | undefined>;

  constructor(
    private _patientScope: CurrentPatientScope,
    route: ActivatedRoute,
    private _location: Location
  ) {
    this.patient$ = this._patientScope.doc$.pipe(filterUndefined());
    this.phone$ = this.patient$.pipe(
      switchMap((patient) => Patient.resolveFirstContactNumber(patient))
    );

    const path$ = queryParam$(route, 'path').pipe(
      map((path) => decodeURIComponent(path))
    );

    this.selected$ = path$.pipe(
      switchMap((path) => doc$<ISubmittedFormHistory>(path))
    );

    combineLatest([this.selected$, this.patient$])
      .pipe(delay(MILLIS_IN_SECOND), takeUntil(this._onDestroy$))
      .subscribe(([selected, _]) => {
        if (selected !== undefined) {
          window.print();
        }
        this._location.back();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
