<ng-container *ngIf="buttonType$ | async as buttonType">
  <div *ngIf="buttonType === 'block'; else basic" class="block">
    <button
      fxFill
      mat-button
      [ngClass]="{ active: isActive$ | async, disabled: isDisabled$ | async }"
      (click)="fileInput.click()"
    >
      <div class="flex items-center p-1">
        <div class="icon">
          <mat-icon>{{ icon }}</mat-icon>
        </div>
        <span>{{ buttonText }}</span>
        <span fxFlex></span>
        <span class="shortcut" *ngIf="shortcut">{{
          shortcut | translateForPlatform
        }}</span>
      </div>
    </button>
  </div>

  <ng-template #basic>
    <button
      class="flex grow !justify-start"
      mat-button
      (click)="fileInput.click()"
    >
      <mat-icon>{{ icon }}</mat-icon>
      <span>{{ buttonText }}</span>
    </button>
  </ng-template>

  <input
    type="file"
    [accept]="acceptedTypes.join(', ')"
    #fileInput
    (change)="startUpload($event)"
    [multiple]="true"
  />
</ng-container>
