<mat-icon
  *ngIf="priority === 'high'"
  color="warn"
  matTooltip="High Priority"
  matTooltipShowDelay="500"
  >arrow_upward</mat-icon
>
<mat-icon
  *ngIf="priority === 'medium'"
  color="primary"
  matTooltip="Medium Priority"
  matTooltipShowDelay="500"
  >arrow_upward</mat-icon
>
<mat-icon
  *ngIf="priority === 'low'"
  matTooltip="Low Priority"
  matTooltipShowDelay="500"
  >arrow_downward</mat-icon
>
