import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isNull, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { cleanObjectStrings } from './lib/conversion-helpers';

export const PATIENT_DOCUMENT_RESOURCE_TYPE = 'patientDocument';

export const PATIENT_DOCUMENT_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient Document List',
    description: '',
    idPrefix: PATIENT_DOCUMENT_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ID4WPatientDocument {
  id: number;
  referral_letter_subtype_id: number | null;
  patient_id: number;
  filename: string;
  created_at: string;
}

export function isD4WPatientDocument(
  item: unknown
): item is ID4WPatientDocument {
  return TypeGuard.interface<ID4WPatientDocument>({
    id: isNumber,
    referral_letter_subtype_id: [isNumber, isNull],
    patient_id: isNumber,
    filename: isString,
    created_at: isString,
  })(item);
}

export interface ID4WPatientDocumentTranslations {
  createdAt: Timestamp;
}

export interface ID4WPatientDocumentFilters {
  patientId: string;
  createdAt: Timestamp;
}

const PATIENT_DOCUMENT_SOURCE_QUERY = `
SELECT
  letter_hist_id AS id,
  patient_id,
  referral_letter_subtype_id,
  letter_date AS created_at,
  letter_filename AS filename
FROM letters_history
WHERE letter_hist_id IS NOT NULL
`;

export class PatientDocumentSourceEntity extends BaseSourceEntity<
  ID4WPatientDocument,
  ID4WPatientDocumentTranslations,
  ID4WPatientDocumentFilters
> {
  sourceEntity = PATIENT_DOCUMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_DOCUMENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_DOCUMENT_SOURCE_QUERY;
  verifySourceFn = isD4WPatientDocument;
  override dateFilterField: keyof ID4WPatientDocumentFilters = 'createdAt';

  override transformDataFn = flow([
    (rows: ID4WPatientDocument[]) => rows.map(cleanObjectStrings),
  ]);

  translate(
    appointment: ID4WPatientDocument,
    timezone: Timezone
  ): ID4WPatientDocumentTranslations {
    return {
      createdAt: toTimestamp(
        moment.tz(appointment.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getSourceRecordId(data: ID4WPatientDocument): number {
    return data.id;
  }

  getSourceLabel(data: ID4WPatientDocument): string {
    return `${data.id} ${data.patient_id}`;
  }

  getFilterData(
    data: ID4WPatientDocument,
    timezone: Timezone
  ): ID4WPatientDocumentFilters {
    return {
      patientId: data.patient_id.toString(),
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }
}
