import {
  Component,
  ChangeDetectionStrategy,
  inject,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { RawInlineNodes, toTextContent } from '@principle-theorem/editor';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  IRescheduleListData,
  AutomationEntity,
  Automation,
  hasValidMobile,
  hasValidEmail,
} from '@principle-theorem/principle-core';
import {
  IPatient,
  isGeneratedTask,
  AutomationStatus,
  AnyAutomation,
  isAutomatedNotification,
  AutomatedNotificationType,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  sortByArrayIndex,
  filterUndefined,
} from '@principle-theorem/shared';
import { ReplaySubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-automation-reschedule-list',
    templateUrl: './automation-reschedule-list.component.html',
    styleUrls: ['./automation-reschedule-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AutomationRescheduleListComponent {
  private _organisation = inject(OrganisationService);
  private _automations$ = new ReplaySubject<IRescheduleListData[]>(1);
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  trackByData =
    TrackByFunctions.nestedField<IRescheduleListData>('automation.uid');
  automations$: Observable<IRescheduleListData[]>;

  @Output() editAutomation = new EventEmitter<AutomationEntity>();
  @Output() cancelAutomation = new EventEmitter<AutomationEntity>();
  @Output() setAutomationPending = new EventEmitter<AutomationEntity>();

  @Input()
  set automations(automations: IRescheduleListData[]) {
    if (automations) {
      this._automations$.next(automations);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor() {
    this.automations$ = this._automations$.pipe(
      map((items) => {
        const automationOrder = Automation.sort(
          items.map((item) => item.automation)
        );
        const automationUidOrder = automationOrder.map(
          (automation) => automation.uid
        );
        return items.sort(
          sortByArrayIndex(automationUidOrder, (item) => item.automation.uid)
        );
      })
    );
  }

  edit(automation: AutomationEntity): void {
    this.editAutomation.emit(automation);
  }

  getTitle(automation: AutomationEntity): RawInlineNodes {
    return isGeneratedTask(automation.data)
      ? automation.data.title
      : [toTextContent(automation.data.name)];
  }

  getStatus(data: IRescheduleListData): AutomationStatus {
    return data.changes?.status ?? data.automation.status;
  }

  handleToggle(
    event: MatSlideToggleChange,
    automation: AutomationEntity
  ): void {
    if (!event.checked) {
      return this.cancelAutomation.emit(automation);
    }
    this.setAutomationPending.emit(automation);
  }

  missingContactDetailsHint$(
    automation: AnyAutomation
  ): Observable<string | undefined> {
    return combineLatest([
      this.patient$,
      this._organisation.organisation$.pipe(filterUndefined()),
    ]).pipe(
      map(([patient, org]) => {
        if (!isAutomatedNotification(automation)) {
          return;
        }
        switch (automation.type) {
          case AutomatedNotificationType.SMS:
            return !hasValidMobile(
              patient,
              org.region,
              org.integrations?.smsProvider
            )
              ? 'Automation requires a valid mobile number'
              : undefined;
          case AutomatedNotificationType.EMAIL:
            return !hasValidEmail(patient)
              ? 'Automation requires a valid email address'
              : undefined;
          default:
            return undefined;
        }
      })
    );
  }
}
