import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnyAutomationConfiguration } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { AutomationConfigurationSort } from '../automated-configuration-list/automation-configuration-sort';

export interface ISelectAutomationTemplateRequest {
  configs: WithRef<AnyAutomationConfiguration>[];
}

export interface ISelectAutomationTemplateResponse {
  config: WithRef<AnyAutomationConfiguration>;
}

@Component({
    selector: 'pr-select-automation-configuration-dialog',
    templateUrl: './select-automation-configuration-dialog.component.html',
    styleUrls: ['./select-automation-configuration-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SelectAutomationConfigurationDialogComponent {
  configs: WithRef<AnyAutomationConfiguration>[];

  constructor(
    private _dialogRef: MatDialogRef<
      SelectAutomationConfigurationDialogComponent,
      ISelectAutomationTemplateResponse
    >,
    @Inject(MAT_DIALOG_DATA) public data: ISelectAutomationTemplateRequest
  ) {
    this.configs = AutomationConfigurationSort.sort(data.configs);
  }

  selectConfig(config: WithRef<AnyAutomationConfiguration>): void {
    this._dialogRef.close({ config });
  }
}
