import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { type WithRef, isSameRef } from '@principle-theorem/shared';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { type IStaffer } from '@principle-theorem/principle-core/interfaces';
import { of, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-owner-selector',
    templateUrl: './owner-selector.component.html',
    styleUrls: ['./owner-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OwnerSelectorComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByStaffer = TrackByFunctions.ref<WithRef<IStaffer>>();
  control: TypedFormControl<WithRef<IStaffer>> = new TypedFormControl();
  staff$: ReplaySubject<WithRef<IStaffer>[]> = new ReplaySubject(1);
  selected$: ReplaySubject<WithRef<IStaffer> | undefined> = new ReplaySubject(
    1
  );

  @Output() ownerChanged: EventEmitter<WithRef<IStaffer>> = new EventEmitter<
    WithRef<IStaffer>
  >();

  @Input()
  set staff(staff: WithRef<IStaffer>[]) {
    if (!staff || !staff.length) {
      return;
    }
    this.staff$.next(staff);
  }

  @Input()
  set selected(assignee: WithRef<IStaffer> | undefined) {
    this.selected$.next(assignee);
  }

  constructor() {
    this.control.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value: WithRef<IStaffer>) => {
        this.ownerChanged.emit(value);
      });

    this.selected$
      .pipe(
        switchMap((selected) => {
          if (!selected) {
            return of(undefined);
          }
          return this.staff$.pipe(
            map((staff) =>
              staff.find((staffer: WithRef<IStaffer>) =>
                isSameRef(staffer, selected)
              )
            )
          );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((found: WithRef<IStaffer> | undefined) => {
        if (found) {
          this.control.setValue(found);
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
