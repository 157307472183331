import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MediaObserver } from 'ng-flex-layout';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  CHARTABLE_SURFACES,
  ChartableSurface,
  type IChartedItem,
  type IChartedRef,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot, type INamedDocument } from '@principle-theorem/shared';
import { difference } from 'lodash';
import { type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CHART_ENTITY_ID } from '../dental-chart-svg/chart-entity-id';
import { ChartedItemsCollection } from '../dental-chart-svg/models/charted-items-collection';

export interface IChartSurfaceSelectorData {
  label: string;
  compatibleSurfaces: ChartableSurface[];
  selectedSurfaces: Partial<IChartedRef>[];
  config?: INamedDocument;
}

@Component({
  selector: 'pr-chart-surface-selector-dialog',
  templateUrl: './chart-surface-selector-dialog.component.html',
  styleUrls: ['./chart-surface-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CHART_ENTITY_ID,
      useValue: ChartId.InAppointment,
    },
  ],
  standalone: false,
})
export class ChartSurfaceSelectorDialogComponent {
  isStacked$: Observable<boolean>;
  filteredChartedItems$: Observable<IChartedItem[]>;

  constructor(
    public media: MediaObserver,
    private _chartStore: ChartFacade,
    @Inject(DIALOG_DATA) public data: IChartSurfaceSelectorData,
    public dialogRef: DialogRef<
      Partial<IChartedRef>[],
      ChartSurfaceSelectorDialogComponent
    >
  ) {
    this.isStacked$ = media
      .asObservable()
      .pipe(map((change) => change[0].mqAlias === 'sm'));

    if (
      data.compatibleSurfaces.includes(ChartableSurface.MultipleTeeth) &&
      !data.compatibleSurfaces.includes(ChartableSurface.WholeTooth)
    ) {
      data.compatibleSurfaces.push(ChartableSurface.WholeTooth);
    }
    this._chartStore.setDisabledSurfaces(
      ChartId.InAppointment,
      difference(CHARTABLE_SURFACES, data.compatibleSurfaces)
    );
    this._chartStore.setSelectedSurfaces(
      ChartId.InAppointment,
      data.selectedSurfaces
    );
    this._chartStore.forceAllowInteractivity(ChartId.InAppointment, true);

    this.filteredChartedItems$ = this._chartStore
      .chartedItems$(ChartId.InAppointment)
      .pipe(
        map((items) => {
          if (!data.config) {
            return [];
          }
          return new ChartedItemsCollection(items)
            .filterByTreatmentConfig(data.config)
            .deduplicate()
            .toArray();
        }),
        startWith([])
      );
  }

  close(): void {
    this.dialogRef.close();
  }

  async save(): Promise<void> {
    const surfaces: Partial<IChartedRef>[] = await snapshot(
      this._chartStore.selectedSurfacesState$(ChartId.InAppointment)
    );
    this.dialogRef.close(surfaces);
  }
}
