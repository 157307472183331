import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CurrentBrandScope,
  DateService,
  TimezoneService,
} from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  type IAppointment,
  type IBrand,
  type ICandidate,
  type ICandidateCalendarEvent,
  type IInteractiveResource,
  type IPrincipleMention,
  MentionResourceType,
  IScheduleSummaryEventable,
} from '@principle-theorem/principle-core/interfaces';
import {
  Appointment,
  Candidate,
  type IMoveAppointmentData,
  toMention,
} from '@principle-theorem/principle-core';
import {
  doc$,
  formatTimeFromTo,
  getDoc,
  saveDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MoveAppointmentComponent } from '../../move-appointment/move-appointment.component';
import { CandidateDetailComponent } from '../../candidate-detail/candidate-detail.component';

@Component({
    selector: 'pr-gap-candidate-item',
    templateUrl: './gap-candidate-item.component.html',
    styleUrls: ['./gap-candidate-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GapCandidateItemComponent {
  gap$ = new ReplaySubject<IScheduleSummaryEventable>(1);
  gapCandidate$ = new ReplaySubject<WithRef<ICandidateCalendarEvent>>(1);
  candidate$: Observable<ICandidate>;
  appointment$: Observable<WithRef<IAppointment>>;
  mention$: Observable<IPrincipleMention>;
  interactiveResource$: Observable<IInteractiveResource>;
  offeredTimeslot$: Observable<string>;
  isApproved$: Observable<boolean>;
  isUnavailable$: Observable<boolean>;
  isApprovedOrUnavailable$: Observable<boolean>;

  @Input()
  set gap(gap: IScheduleSummaryEventable) {
    if (gap) {
      this.gap$.next(gap);
    }
  }

  @Input()
  set gapCandidate(candidate: WithRef<ICandidateCalendarEvent>) {
    if (candidate) {
      this.gapCandidate$.next(candidate);
    }
  }

  constructor(
    public dateService: DateService,
    private _brandScope: CurrentBrandScope,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _timezone: TimezoneService
  ) {
    this.candidate$ = this.gapCandidate$.pipe(
      map((gapCandidate) => gapCandidate.candidate)
    );
    this.appointment$ = this.candidate$.pipe(
      switchMap((candidate) => doc$(candidate.appointment))
    );

    this.mention$ = this.candidate$.pipe(
      map((candidate) =>
        toMention(candidate.patient, MentionResourceType.Candidate)
      )
    );

    this.interactiveResource$ = this.appointment$.pipe(
      map((appointment) => ({
        interactions$: Appointment.interactions$(appointment),
        add: async (interaction) => {
          await Appointment.addInteraction(appointment, interaction);
        },
      }))
    );

    this.offeredTimeslot$ = this.gapCandidate$.pipe(
      switchMap((candidate) => this._timezone.getEventRange$(candidate.event)),
      map((range) => formatTimeFromTo(range.from, range.to))
    );

    this.isApproved$ = this.candidate$.pipe(
      map((candidate) => Candidate.isApproved(candidate))
    );

    this.isUnavailable$ = this.candidate$.pipe(
      map((candidate) => Candidate.isUnavailable(candidate))
    );

    this.isApprovedOrUnavailable$ = combineLatest([
      this.isApproved$,
      this.isUnavailable$,
    ]).pipe(map(([approved, unavailable]) => approved || unavailable));
  }

  async openCandidateDetails(): Promise<void> {
    const data: IMoveAppointmentData = await this._getMoveAppointmentData();
    const config: MatDialogConfig = DialogPresets.large({
      height: '80%',
      data,
    });
    this._dialog.open(CandidateDetailComponent, config);
  }

  async openMoveAppointment(event: Event): Promise<void> {
    event.stopPropagation();
    const data: IMoveAppointmentData = await this._getMoveAppointmentData();
    const config: MatDialogConfig = DialogPresets.fullscreen({ data });
    this._dialog.open<MoveAppointmentComponent, IMoveAppointmentData>(
      MoveAppointmentComponent,
      config
    );
  }

  toggleAvailable(candidate: ICandidate): ICandidate {
    if (Candidate.isUnavailable(candidate)) {
      return Candidate.available(candidate);
    }
    return Candidate.unavailable(candidate);
  }

  async updateAvailability(event: Event): Promise<void> {
    event.stopPropagation();
    const gapCandidate = await snapshot(this.gapCandidate$);
    gapCandidate.candidate = this.toggleAvailable(gapCandidate.candidate);

    let message = 'Candidate marked available for gap';
    if (Candidate.isUnavailable(gapCandidate.candidate)) {
      message = 'Candidate marked unavailable for gap';
    }

    await saveDoc(gapCandidate);
    this._snackBar.open(message);
  }

  private async _getMoveAppointmentData(): Promise<IMoveAppointmentData> {
    const brand: WithRef<IBrand> = await this._brandScope.toPromise();
    const gapCandidate = await snapshot(this.gapCandidate$);
    const gap = await snapshot(this.gap$);
    const patient = await getDoc(gapCandidate.candidate.patient.ref);
    const appointment = await snapshot(this.appointment$);

    return {
      brand,
      gapCandidate,
      gap,
      patient,
      appointment,
    };
  }
}
