<mat-toolbar color="accent">
  Tyro Logs - {{ env$ | async | titlecase }}
</mat-toolbar>

<mat-dialog-content>
  <div fxFlex>
    <iframe fxFill [src]="logsUrl$ | async" [title]="env$ | async"></iframe>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
