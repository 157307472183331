import { Action, combineReducers, type MetaReducer } from '@ngrx/store';
import {
  type HealthPointReconciliationReportResponse,
  type ReconciliationReportResponse,
} from '@principle-theorem/tyro';
import {
  type IPrincipleReconciliationReportData,
  type ITransactionSourceSummary,
} from '@principle-theorem/reporting';
import { type SerialisedData } from '@principle-theorem/shared';
import { type IReconciliatonQueryState, queryReducer } from './query.reducers';
import {
  healthPointReportReducer,
  principleReportReducer,
  tyroReportReducer,
} from './tyro.reducers';
import { selectedSummaryReducer } from './selected-summary';

export const RECONCILIATON_REPORT_FEATURE_KEY = 'reconciliation-report';

export interface IReconciliatonReportState {
  query: IReconciliatonQueryState;
  principleReport: IPrincipleReconciliationReportData | undefined;
  tyroReport: ReconciliationReportResponse | undefined;
  healthPointReport: HealthPointReconciliationReportResponse | undefined;
  selectedSummary: ITransactionSourceSummary | undefined;
}

export const metaReducers: MetaReducer<
  SerialisedData<IReconciliatonReportState>
>[] = [];

export function reducers(
  state: SerialisedData<IReconciliatonReportState> | undefined,
  action: Action
): SerialisedData<IReconciliatonReportState> {
  return combineReducers({
    query: queryReducer,
    principleReport: principleReportReducer,
    tyroReport: tyroReportReducer,
    healthPointReport: healthPointReportReducer,
    selectedSummary: selectedSummaryReducer,
  })(state, action);
}
