import { IPatient } from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { IBaseCommand, IBridgeIntegration } from '../../send-command-to-device';

export const IDIXEL_INTEGRATION: IBridgeIntegration = {
  label: 'i-Dixel',
  description: 'One way integration with i-Dixel imaging software',
};

export type IDixelOpenPatientCommand = IBaseCommand<
  IDixelToDeviceCommand.OpenPatient,
  { patient: WithRef<IPatient> }
>;

export enum IDixelToDeviceCommand {
  OpenPatient = 'idixel.openPatient',
}
