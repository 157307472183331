<ng-container *ngIf="selectionList.selected$ | async as selected">
  <ng-container *ngIf="selected.length">
    <div class="toolbar flex flex-col items-center justify-center">
      <div class="flex items-center justify-center gap-4">
        <button
          mat-icon-button
          matTooltip="Move Selected"
          aria-label="Move Selected"
          [matMenuTriggerFor]="moveTreatmentMenu"
        >
          <mat-icon>shuffle</mat-icon>
        </button>
        <pr-assignee-profile-selector
          selectTooltip="Change Provider"
          aria-label="Change Provider"
          [multiSelect]="true"
          [disableClear]="true"
          [staff]="organisation.brandPractitioners$ | async"
          (stafferSelected)="updateAttributedTo($event)"
         />
        <pr-staff-fee-schedule-menu
          matTooltip="Set Fee Schedule"
          aria-label="Set Fee Schedule"
          [multiSelect]="true"
          (scheduleSelected)="updateFeeSchedule($event)"
         />
        <button
          class="delete-treatment"
          mat-icon-button
          matTooltip="Delete Selected"
          aria-label="Delete Selected"
          (click)="confirmDeleteDialog()"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="Dismiss Selected / ESC"
          aria-label="Dismiss Selected or click the escape key"
          (click)="dismissSelected()"
        >
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>

<mat-menu #moveTreatmentMenu="matMenu">
  <button
    mat-menu-item
    disableRipple
    [matMenuTriggerFor]="moveToTreatmentPlan"
    [disabled]="treatmentPlansDisabled$ | async"
  >
    <mat-icon>drive_file_move</mat-icon>
    <span>Move to Treatment Plan</span>
  </button>
  <buttton
    mat-menu-item
    disableRipple
    [matMenuTriggerFor]="moveToMultiStepTreatment"
    [disabled]="multiStepTreatmentsDisabled$ | async"
  >
    <mat-icon>horizontal_split</mat-icon>
    <span>Move to Flagged Multi Treatment</span>
  </buttton>
  <button
    [disabled]="flaggedTreatmentsDisabled$ | async"
    mat-menu-item
    (click)="moveTreatments()"
  >
    <mat-icon>flag</mat-icon>
    <span>Move to Flagged Treatments</span>
  </button>
</mat-menu>

<mat-menu #moveToMultiStepTreatment="matMenu">
  <div
    *ngFor="
      let multiStepTreatment of multiStepTreatments$ | async;
      trackBy: trackByMultiStep
    "
    mat-menu-item
    disableRipple
    [matMenuTriggerFor]="moveToMultiStepTreatmentStep"
  >
    <span>{{ multiStepTreatment.config.name }}</span>
    <mat-menu #moveToMultiStepTreatmentStep="matMenu">
      <button
        *ngFor="let step of multiStepTreatment.steps; trackBy: trackByUuidFn"
        mat-menu-item
        [disabled]="step | map: isSameStep$:this | async"
        (click)="moveTreatments(step, multiStepTreatment)"
      >
        <span>{{ step.name }}</span>
      </button>
    </mat-menu>
  </div>
</mat-menu>

<mat-menu #moveToTreatmentPlan="matMenu">
  <div
    *ngFor="let treatmentPlan of treatmentPlans$ | async; trackBy: trackByPlan"
    mat-menu-item
    disableRipple
    [matMenuTriggerFor]="moveToTreatmentStep"
    [ngClass]="{ 'current-plan': treatmentPlan.isCurrentPlan }"
  >
    <span>{{ treatmentPlan.name }}</span>
    <mat-menu #moveToTreatmentStep="matMenu">
      <button
        *ngFor="let step of treatmentPlan.steps; trackBy: trackByUuidFn"
        mat-menu-item
        [disabled]="step | map: isSameStep$:this | async"
        (click)="moveTreatments(step, treatmentPlan)"
      >
        <span>{{ step.name }}</span>
      </button>
    </mat-menu>
  </div>
</mat-menu>
