import {
  MeasureReducer,
  ReportBuilderDataSourceId,
} from '@principle-theorem/principle-core/interfaces';
import { FactTables, toMeasureBuilderData } from '@principle-theorem/reporting';
import { ChartFilters } from './chart-filters';
import { ReportBuilderChartFilters } from './report-builder-chart-filters';
import { ReportBuilderColumns } from './report-builder-columns';
import { type IReportBuilderDataSource } from './report-builder-data-source';
import { ReportBuilderGroups } from './report-builder-groups';

const factTable = FactTables.schedulingEvent.latestEvent;

export const SCHEDULING_EVENTS_BUILDER_DATA_SOURCE: IReportBuilderDataSource = {
  id: ReportBuilderDataSourceId.SchedulingEvents,
  name: 'Scheduling History',
  description: `One result for each time an appointment is scheduled, rescheduled or cancelled.`,
  factTable,
  queryableTimestamps: [
    { property: factTable.scheduledAt },
    { property: factTable.originalAppointmentDate },
    { property: factTable.newAppointmentDate },
  ],
  groupByOptions: [
    ReportBuilderGroups.toGroup('Common', [
      ReportBuilderGroups.toOption(factTable.scheduledByStaffer.name),
      ReportBuilderGroups.toOption(factTable.brand.name),
      ReportBuilderGroups.toOption(factTable.reason.name),
      ReportBuilderGroups.toOption(factTable.schedulingEvent.eventType),
      ReportBuilderGroups.toOption(factTable.appointment.treatmentCategory),
      ReportBuilderGroups.toOption(factTable.isFirstAppointment),
      ReportBuilderGroups.toOption(factTable.appointment.isBookedOnline),
      ReportBuilderGroups.toOption(factTable.movedFromStaffer.name),
      ReportBuilderGroups.toOption(factTable.movedToStaffer.name),
      ReportBuilderGroups.toOption(factTable.practitionerWasChanged),
      ReportBuilderGroups.toOption(factTable.practiceWasChanged),
      ReportBuilderGroups.toOption(factTable.count),
    ]),
    ReportBuilderGroups.patient(factTable.patient),
    ReportBuilderGroups.appointment(factTable.appointment),
    ReportBuilderGroups.treatmentPlan(factTable.treatmentPlan),
    ReportBuilderGroups.schedulingEvent(factTable.schedulingEvent),
  ],
  availableColumns: [
    ReportBuilderColumns.toColumnGroup('Scheduling Event', [
      factTable.schedulingEvent.eventType,
      factTable.reason.name,
      factTable.schedulingEvent.wasMovedToSameDay,
      factTable.schedulingEvent.wasFillingGap,
      factTable.practitionerWasChanged,
      factTable.practiceWasChanged,
      factTable.eventDurationChanged,
      factTable.eventMovedForward,
      factTable.eventMovedBackwards,
      factTable.schedulingEvent.scheduledAt,
      factTable.scheduledByStaffer.name,
      factTable.scheduledByPractice.name,
    ]),
    ReportBuilderColumns.toColumnGroup('Appointment', [
      factTable.treatmentCost,
      factTable.isFirstAppointment,
      ...ReportBuilderColumns.appointment(factTable.appointment),
    ]),
    ReportBuilderColumns.toColumnGroup('Moved From', [
      factTable.schedulingEvent.eventBeforeStartTime,
      factTable.schedulingEvent.eventBeforeEndTime,
      factTable.eventBeforeDurationMins,
      factTable.movedFromStaffer.name,
      factTable.movedFromPractice.name,
    ]),
    ReportBuilderColumns.toColumnGroup('Moved To', [
      factTable.schedulingEvent.eventAfterStartTime,
      factTable.schedulingEvent.eventAfterEndTime,
      factTable.eventAfterDurationMins,
      factTable.movedToStaffer.name,
      factTable.movedToPractice.name,
    ]),
    ReportBuilderColumns.toColumnGroup(
      'Patient',
      ReportBuilderColumns.patient(factTable.patient)
    ),
    ReportBuilderColumns.toColumnGroup(
      'Treatment Plan',
      ReportBuilderColumns.treatmentPlan(factTable.treatmentPlan)
    ),
  ],
  defaultColumns: ReportBuilderColumns.toReferences([
    factTable.schedulingEvent.scheduledAt,
    factTable.schedulingEvent.eventType,
    factTable.reason.name,
    factTable.patient.name,
    factTable.isFirstAppointment,
    factTable.appointment.treatmentCategory,
    factTable.treatmentCost,
    factTable.schedulingEvent.eventBeforeStartTime,
    factTable.schedulingEvent.eventAfterStartTime,
    factTable.practitionerWasChanged,
    factTable.eventDurationChanged,
    factTable.scheduledByStaffer.name,
    factTable.schedulingEvent.wasMovedToSameDay,
    factTable.schedulingEvent.wasFillingGap,
  ]),
  availableCharts: [
    ReportBuilderChartFilters.predefined(
      'overview.schedulingEvent.reason',
      factTable.reason.name,
      ChartFilters.rowChart(
        'Reschedule/Cancellation Reasons',
        factTable.reason.name,
        [toMeasureBuilderData(factTable.count)]
      )
    ),
    ReportBuilderChartFilters.predefined(
      'overview.schedulingEvent.eventType',
      factTable.schedulingEvent.eventType,
      ChartFilters.pieChart(
        'Scheduling Events by Type',
        factTable.schedulingEvent.eventType,
        [toMeasureBuilderData(factTable.count)]
      )
    ),
    ReportBuilderChartFilters.predefined(
      'overview.wasFillingGap',
      factTable.schedulingEvent.wasFillingGap,
      ChartFilters.pieChart(
        'Moved to fill gap',
        factTable.schedulingEvent.wasFillingGap,
        [toMeasureBuilderData(factTable.count)]
      )
    ),
    ReportBuilderChartFilters.predefined(
      'overview.wasMovedToSameDay',
      factTable.schedulingEvent.wasMovedToSameDay,
      ChartFilters.pieChart(
        'Moved to same day',
        factTable.schedulingEvent.wasMovedToSameDay,
        [toMeasureBuilderData(factTable.count)]
      )
    ),
    ReportBuilderChartFilters.predefined(
      'overview.practitionerWasChanged',
      factTable.practitionerWasChanged,
      ChartFilters.pieChart(
        'Practitioner Was Changed',
        factTable.practitionerWasChanged,
        [toMeasureBuilderData(factTable.count)]
      )
    ),
    ReportBuilderChartFilters.predefined(
      'overview.daysBeforeOriginalBooking',
      factTable.brand.name,
      ChartFilters.numberChart(
        'Average Days Before Original Appointment',
        factTable.brand.name,
        [
          toMeasureBuilderData(
            factTable.schedulingEvent.daysBeforeOriginalAppointmentDate,
            MeasureReducer.Average
          ),
        ]
      )
    ),
    ReportBuilderChartFilters.predefined(
      'overview.daysBeforeNewBooking',
      factTable.brand.name,
      ChartFilters.numberChart(
        'Average Days Before New/Rescheduled Appointment',
        factTable.brand.name,
        [
          toMeasureBuilderData(
            factTable.schedulingEvent.daysBeforeNewAppointmentDate,
            MeasureReducer.Average
          ),
        ]
      )
    ),
    ReportBuilderChartFilters.predefined(
      'appointment.treatmentCategory',
      factTable.appointment.treatmentCategory,
      ChartFilters.columnChart(
        'Treatment Category',
        factTable.appointment.treatmentCategory,
        [toMeasureBuilderData(factTable.treatmentCost)],
        { rotateXAxisLabels: true },
        { groupLimit: 15, otherGroupLabel: 'Other', colourOverride: true }
      )
    ),
    ReportBuilderChartFilters.predefined(
      'appointment.isFirstAppointment',
      factTable.isFirstAppointment,
      ChartFilters.pieChart(
        'Is First Appointment',
        factTable.isFirstAppointment,
        [toMeasureBuilderData(factTable.count)]
      )
    ),
    ReportBuilderChartFilters.predefined(
      'appointment.isBookedOnline',
      factTable.appointment.isBookedOnline,
      ChartFilters.pieChart(
        'Is Booked Online',
        factTable.appointment.isBookedOnline,
        [toMeasureBuilderData(factTable.count)]
      )
    ),
  ],
};
