import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  HEALTH_CARDS_BY_REGION,
  IPatient,
  IPatientForm,
  PatientHealthCardType,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  HISTORY_DATE_TIME_FORMAT,
  MONTH_YEAR_FORMAT,
  WithRef,
} from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PatientDetailsFormCompareComponent } from '../patient-details-form-compare/patient-details-form-compare.component';

@Component({
  selector: 'pr-patient-details-form-display',
  exportAs: 'patientDetailsFormDisplay',
  templateUrl: './patient-details-form-display.component.html',
  styleUrl: './patient-details-form-display.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex flex-row ' },
  standalone: false,
})
export class PatientDetailsFormDisplayComponent {
  private _organisation = inject(OrganisationService);
  readonly dateFormat = HISTORY_DATE_TIME_FORMAT;
  readonly dateOfBirthFormat = DAY_MONTH_YEAR_FORMAT;
  readonly expiryFormat = MONTH_YEAR_FORMAT;
  healthCardTypes$: Observable<PatientHealthCardType[]>;
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  patientForm$ = new ReplaySubject<WithRef<IPatientForm>>(1);
  disabled$ = new ReplaySubject<boolean>(1);
  @Output() patientDetailsChanged = new EventEmitter<
    Partial<IPatient> | undefined
  >();

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  @Input()
  set patientForm(patientForm: WithRef<IPatientForm>) {
    if (patientForm) {
      this.patientForm$.next(patientForm);
    }
  }

  @Input({ transform: coerceBooleanProperty })
  set disabled(disabled: boolean) {
    this.disabled$.next(disabled);
  }

  @ViewChild('patientFormDetails')
  patientFormDetails?: PatientDetailsFormCompareComponent;

  constructor() {
    this.healthCardTypes$ = this._organisation.organisation$.pipe(
      map((organisation) =>
        organisation ? HEALTH_CARDS_BY_REGION[organisation.region] : []
      )
    );
  }

  get isValid(): boolean {
    return this.patientFormDetails?.form.valid ?? false;
  }

  healthCardEnabled$(type: string): Observable<boolean> {
    return this.healthCardTypes$.pipe(
      map((healthCardTypes) =>
        healthCardTypes.includes(type as PatientHealthCardType)
      )
    );
  }
}
