import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
  CurrentScopeFacade,
  GapStoreService,
  IGapDayPair,
  OrganisationService,
  StafferSettingsStoreService,
} from '@principle-theorem/ng-principle-shared';
import { MOMENT_DATEPICKER_PROVIDERS } from '@principle-theorem/ng-shared';
import { Event } from '@principle-theorem/principle-core';
import {
  IScheduleSummaryEventable,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  CASUAL_DATE_WITH_YEAR,
  DocumentReference,
  TIME_FORMAT,
  WithRef,
  filterUndefined,
  isChanged$,
  isSameRef,
  toISODate,
} from '@principle-theorem/shared';
import { compact, differenceWith, groupBy, mapValues } from 'lodash';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-gap-sidebar',
  templateUrl: './gap-sidebar.component.html',
  styleUrls: ['./gap-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
  standalone: false,
})
export class GapSidebarComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  groupedGaps$: Observable<IGapDayPair[]>;
  groupedPendingGaps$: Observable<IGapDayPair[]>;
  dateFormat = CASUAL_DATE_WITH_YEAR;
  timeFormat = TIME_FORMAT;

  constructor(
    public gapStore: GapStoreService,
    private _stafferSettings: StafferSettingsStoreService,
    currentScope: CurrentScopeFacade,
    organisation: OrganisationService
  ) {
    const staffOrder$ = currentScope.currentPractice$.pipe(
      filterUndefined(),
      switchMap((practice) =>
        this._stafferSettings.getOrderedStaffByPractice$(practice.ref)
      )
    );

    const practitioners$ = organisation.practicePractitioners$.pipe(
      isChanged$(isSameRef)
    );

    const gaps$ = combineLatest([
      this.gapStore.filteredGaps$,
      this.gapStore.searchResults$,
    ]).pipe(
      map(([gaps, searchResults]) =>
        searchResults.length ? searchResults : gaps
      )
    );

    const sortedGaps$ = combineLatest([
      staffOrder$,
      practitioners$,
      gaps$,
    ]).pipe(
      map(([staffOrder, pracititoners, gaps]) => {
        const sortedStaff = this._sortStaff(staffOrder, pracititoners);

        const sortedGaps = compact(
          sortedStaff.map((stafferRef) =>
            gaps.filter((gap) =>
              isSameRef(Event.staff(gap.event)[0], stafferRef)
            )
          )
        ).flat();

        const unsortedGaps = differenceWith(
          gaps,
          sortedGaps,
          (gap, sortedGap) => isSameRef(gap.ref, sortedGap.ref)
        );

        return [...sortedGaps, ...unsortedGaps];
      })
    );

    this.groupedGaps$ = sortedGaps$.pipe(
      map((sortedGaps) => this._groupGapsByDay(sortedGaps))
    );

    this.groupedPendingGaps$ = this.gapStore.pendingGaps$.pipe(
      map((gaps) => this._groupGapsByDay(gaps))
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _groupGapsByDay(gaps: IScheduleSummaryEventable[]): IGapDayPair[] {
    const gapsWithDay = gaps.map((gap) => ({
      day: toISODate(gap.event.from),
      gap,
    }));

    const groupedPairs = groupBy(gapsWithDay, 'day');
    const dayGapsGroups = mapValues(groupedPairs, (pairs) => ({
      day: pairs[0].day,
      gaps: pairs.map((pair) => pair.gap),
    }));
    return Object.values(dayGapsGroups);
  }

  private _sortStaff(
    staffOrder: DocumentReference<IStaffer>[],
    filteredPractitioners: WithRef<IStaffer>[]
  ): WithRef<IStaffer>[] {
    const sortedStaff = compact(
      staffOrder.map((stafferRef) =>
        filteredPractitioners.find((staffer) => isSameRef(staffer, stafferRef))
      )
    );

    const unsortedStaff = differenceWith(
      filteredPractitioners,
      sortedStaff,
      isSameRef
    );

    return [...sortedStaff, ...unsortedStaff];
  }
}
