import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { Storage, getDownloadURL, ref } from '@angular/fire/storage';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CurrentBrandScope,
  CurrentScopeFacade,
} from '@principle-theorem/ng-principle-shared';
import { PUBLIC_REPORTS } from '@principle-theorem/ng-reporting';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
  isDisabled$,
  type IBreadcrumb,
  ImageSize,
  getImageAtSize,
} from '@principle-theorem/ng-shared';
import { Brand, Practice } from '@principle-theorem/principle-core';
import {
  ADD_TO_WAITLIST_BY_DEFAULT,
  AppointmentConfirmationOptionalInfo,
  BridgeDeviceFeatureStatus,
  DEFAULT_SUCCESS_MESSAGE,
  IBrand,
  IBrandSettings,
  OPTIONAL_APPOINTMENT_CONFIRMATION_INFO,
  OPTIONAL_PATIENT_FIELDS,
  REPORTING_JOBS_DISPLAY,
  RESTRICT_TO_PRE_BLOCKS_BY_DEFAULT,
  type PatientOptionalField,
} from '@principle-theorem/principle-core/interfaces';
import {
  DEFAULT_TIMEZONE,
  Firestore,
  TIMEZONES,
  filterUndefined,
  multiMap,
  patchDoc,
  reduce2DArray,
  slugify,
  snapshot,
  type Timezone,
  type WithRef,
  shareReplayCold,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { Observable, Subject, combineLatest, from, of } from 'rxjs';
import {
  catchError,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';

type IBrandFormData = {
  name: string;
  settings: IBrandSettings;
};

@Component({
  selector: 'pr-general-brand',
  templateUrl: './general-brand.component.html',
  styleUrls: ['./general-brand.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralBrandComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByTimezone = TrackByFunctions.variable<Timezone>();
  brand$: Observable<WithRef<IBrand>>;
  hasIntegrations$: Observable<boolean>;
  timezones: Timezone[] = TIMEZONES;
  form = new TypedFormGroup<IBrandFormData>({
    name: new TypedFormControl('', Validators.required),
    settings: new TypedFormGroup<IBrandSettings>({
      timezone: new TypedFormControl(DEFAULT_TIMEZONE, Validators.required),
      scheduling: new TypedFormGroup<Required<IBrandSettings>['scheduling']>({
        defaultWaitlistSettingsOn: new TypedFormControl<boolean>(
          ADD_TO_WAITLIST_BY_DEFAULT
        ),
      }),
      onlineBookings: new TypedFormGroup<
        Required<
          Omit<IBrandSettings['onlineBookings'], 'treatmentTemplateOrder'>
        >
      >({
        restrictToPreBlocks: new TypedFormControl<boolean>(
          RESTRICT_TO_PRE_BLOCKS_BY_DEFAULT
        ),
        requestSuccessMessage: new TypedFormControl<string>(''),
      }),
      patientDetailsForm: new TypedFormGroup({
        requiredFields: new TypedFormControl<PatientOptionalField[]>([]),
      }),
      patient: new TypedFormGroup({
        requiredFields: new TypedFormControl<PatientOptionalField[]>([]),
      }),
      optionalDisplayInformation: new TypedFormGroup<
        Required<IBrandSettings>['optionalDisplayInformation']
      >({
        appointmentConfirmationInfo: new TypedFormControl<
          AppointmentConfirmationOptionalInfo[]
        >([]),
      }),
      treatmentPlanning: new TypedFormGroup({
        autoCompletePlanAfterLastStep: new TypedFormControl(false),
        includeCompletedTreatmentPlansInTimeline: new TypedFormControl(false),
      }),
      availableSystemReports: new TypedFormControl([]),
    }),
  });
  breadcrumbs$: Observable<IBreadcrumb[]>;
  patientFields = OPTIONAL_PATIENT_FIELDS;
  optionalAppointmentConfirmationInfo = OPTIONAL_APPOINTMENT_CONFIRMATION_INFO;
  isDisabled$ = isDisabled$(this.form);
  onlineBookingSuccessMessagePlaceholder = DEFAULT_SUCCESS_MESSAGE;
  managedReportOptions = sortBy(REPORTING_JOBS_DISPLAY, 'name');
  defaultReportsSummary = this._buildDefaultReportsSummary();
  logoUploadPath$: Observable<string>;
  brandLogoUrl$: Observable<string | undefined>;

  constructor(
    private _snackBar: MatSnackBar,
    private _storage: Storage,
    private _brandScope: CurrentBrandScope,
    currentScope: CurrentScopeFacade
  ) {
    this.brand$ = this._brandScope.doc$.pipe(filterUndefined());
    this.brand$.pipe(take(1), takeUntil(this._onDestroy$)).subscribe((brand) =>
      this.form.patchValue(brand, {
        emitEvent: false,
      })
    );
    this.brandLogoUrl$ = this.brand$.pipe(
      switchMap((brand) => this._logoUrl$(brand, this._storage))
    );
    this.logoUploadPath$ = this.brand$.pipe(
      map((brand) => Brand.logoStoragePath(brand))
    );
    this.breadcrumbs$ = this.brand$.pipe(
      map((brand) => [
        { label: 'Settings', path: '../../../' },
        { label: brand.name },
        { label: 'General' },
      ])
    );
    this.hasIntegrations$ = currentScope.currentPractice$.pipe(
      filterUndefined(),
      switchMap((practice) => Practice.bridgeDevices$(practice)),
      multiMap((device) => device.features),
      reduce2DArray(),
      map((features) =>
        features.some(
          (feature) => feature.status === BridgeDeviceFeatureStatus.Enabled
        )
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  integrationWarningMessage$(
    formGroupName: 'patient' | 'patientDetailsForm'
  ): Observable<string | undefined> {
    return combineLatest([
      this.form.valueChanges.pipe(
        startWith(this.form.value),
        map((changes) => changes.settings[formGroupName])
      ),
      this.hasIntegrations$,
    ]).pipe(
      map(([formValue, hasIntegrations]) => {
        const dateOfBirthRequired =
          formValue?.requiredFields?.includes('dateOfBirth');
        if (!dateOfBirthRequired && hasIntegrations) {
          return 'Patient date of birth is required for one or more active integrations';
        }
      })
    );
  }

  async save(): Promise<void> {
    const brand = await snapshot(this.brand$);
    const data = this.form.getRawValue();
    await patchDoc(brand.ref, {
      ...data,
      slug: slugify(data.name.toLowerCase()),
    });
    this._snackBar.open('Brand Updated');
    this.form.markAsPristine();
    this.form.updateValueAndValidity();
  }

  async updateImageUrl(storagePath: string): Promise<void> {
    const brand = await snapshot(this.brand$);
    await Firestore.patchDoc(brand.ref, { logoUrl: storagePath });
  }

  async removeLogo(): Promise<void> {
    const brand = await snapshot(this.brand$);
    await Firestore.patchDoc(brand.ref, { logoUrl: '' });
  }

  private _buildDefaultReportsSummary(): string {
    const defaultReports = this.managedReportOptions
      .filter((report) => PUBLIC_REPORTS.includes(report.type))
      .map((report) => report.name)
      .join(', ');

    return `The following reports are available to all brands regardless of selection: ${defaultReports}`;
  }

  private _logoUrl$(
    brand: WithRef<IBrand>,
    storage: Storage,
    size: ImageSize = ImageSize.Medium
  ): Observable<string | undefined> {
    if (!brand.logoUrl) {
      return of(undefined);
    }

    const logoRef = ref(storage, getImageAtSize(brand.logoUrl, size, 'webp'));
    const fallbackRef = ref(storage, brand.logoUrl);

    return from(getDownloadURL(logoRef)).pipe(
      catchError(() => getDownloadURL(fallbackRef)),
      shareReplayCold()
    );
  }
}
