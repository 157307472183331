import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
  inject,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { buildIntercomTarget } from '@principle-theorem/ng-intercom';
import { IContextualAction } from '@principle-theorem/ng-principle-shared';
import {
  ProfileImageService,
  type IOptionGroup,
} from '@principle-theorem/ng-shared';
import {
  reduceToSingleArray,
  shareReplayCold,
  snapshot,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { combineLatest, type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { type IProfileLoader } from './profile-loader';
import { SearchService } from './search.service';

export type SearchOptionGroup = IOptionGroup<
  IContextualAction | IProfileLoader
>;

@Component({
    selector: 'pr-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SearchComponent implements OnInit, OnDestroy {
  profileImage = inject(ProfileImageService);
  filteredGroups$: Observable<SearchOptionGroup[]>;
  emptySearch$: Observable<boolean>;
  readonly iconSize: number = 30;

  @ViewChild('searchInput', { read: ElementRef, static: true })
  searchInput: ElementRef<HTMLElement>;

  constructor(
    private _dialogRef: MatDialogRef<SearchComponent>,
    public search: SearchService
  ) {
    this.filteredGroups$ = combineLatest([
      this.search.groups$,
      this.search.search.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([groups, val]) => (val ? this.filter(val, groups) : groups.slice())),
      shareReplayCold()
    );
    this.emptySearch$ = this.filteredGroups$.pipe(
      map((groups) => groups.every((group) => !group.options.length))
    );
  }

  ngOnInit(): void {
    this.searchInput.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this.clearResults();
  }

  @HostListener('keyup.escape')
  closeDialog(): void {
    this._dialogRef.close();
  }

  filter(
    val: string | SearchOptionGroup,
    groups: SearchOptionGroup[]
  ): SearchOptionGroup[] {
    if (typeof val !== 'string') {
      return [];
    }

    const search: string = val.toLowerCase();

    return groups
      .map((group: SearchOptionGroup) => ({ ...group }))
      .map((group: SearchOptionGroup): SearchOptionGroup => {
        group.options = group.options.filter((item) => {
          if (group.skipFilter) {
            return true;
          }

          const searchKeys: string[] = [item.name];
          if (item.phone) {
            searchKeys.push(item.phone);
          }
          if (item.details) {
            searchKeys.push(item.details);
          }
          const searchIndex: number = searchKeys
            .join('')
            .toLowerCase()
            .indexOf(search);
          return searchIndex >= 0;
        });
        return group;
      });
  }

  displayFn(item?: IContextualAction): string {
    return item ? item.name : '';
  }

  optionSelected(option: IContextualAction | IProfileLoader): void {
    this.searchInput.nativeElement.blur();
    option.do();
    this.search.search.reset();
    this._dialogRef.close();
  }

  clearResults(): void {
    this.search.search.setValue('');
  }

  async selectFirstOption(): Promise<void> {
    const firstOption = await snapshot(
      this.filteredGroups$.pipe(
        map((groups) =>
          reduceToSingleArray(groups.map((group) => group.options))
        ),
        map((options) => first(options))
      )
    );
    if (firstOption) {
      this.optionSelected(firstOption);
    }
  }

  getIntercomTarget(item: {
    option: IContextualAction;
    group: SearchOptionGroup;
  }): string {
    return buildIntercomTarget(['search', item.group.name, item.option.name]);
  }

  isInactive(item: IContextualAction | IProfileLoader): boolean {
    return ('inactive' in item && item.inactive) ?? false;
  }
}
