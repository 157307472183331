export enum RootCollection {
  Configuration = 'config',
  Organisations = 'organisations',
  PracticeMigrations = 'practiceMigrations',
  ManagementJobs = 'managementJobs',
  ManagementStaff = 'managementStaff',
  ManagementUsers = 'managementUsers',
}

export enum ConfigCollection {
  Public = 'public',
}

export enum ConfigDocuments {
  DemoSpace = 'demo-space',
}

export interface IDemoSpace {
  fileNames: string[];
}

export interface IPublicConfig {
  version: string;
}
