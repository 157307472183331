import { type ISource } from '@principle-theorem/principle-core/interfaces';
import { find } from 'lodash';

export function getConfigurationItem<T>(
  source: ISource,
  name: string,
  defaultValue: T
): string | T {
  return (
    find(
      source.configuration,
      (configuration) => configuration.name.toLowerCase() === name.toLowerCase()
    )?.value ?? defaultValue
  );
}

export function updateConfigurationItem(
  source: ISource,
  name: string,
  value: string
): ISource {
  source.configuration = source.configuration.map((config) => {
    return config.name.toLowerCase() === name.toLowerCase()
      ? { ...config, value }
      : config;
  });

  return source;
}
