import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  IDVACard,
  IHealthFundCard,
  IMedicareCard,
  IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  MONTH_YEAR_FORMAT,
  patchDoc,
  WithRef,
} from '@principle-theorem/shared';

export interface IUpdateHealthCardsRequest {
  patient: WithRef<IPatient>;
  medicareCard?: IMedicareCard;
  healthFundCard?: IHealthFundCard;
  dvaCard?: IDVACard;
}

@Component({
  selector: 'pr-update-health-cards',
  templateUrl: './update-health-cards.component.html',
  styleUrls: ['./update-health-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateHealthCardsComponent {
  dateFormat = MONTH_YEAR_FORMAT;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUpdateHealthCardsRequest,
    private _dialogRef: MatDialogRef<UpdateHealthCardsComponent, void>
  ) {}

  async updateAll(): Promise<void> {
    const updates: Partial<IPatient> = {};
    if (this.data.medicareCard) {
      updates.medicareCard = this.data.medicareCard;
    }
    if (this.data.dvaCard) {
      updates.dvaCard = this.data.dvaCard;
    }
    if (this.data.healthFundCard) {
      updates.healthFundCard = this.data.healthFundCard;
    }
    await patchDoc(this.data.patient.ref, updates);
    this._dialogRef.close();
  }
}
