import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import {
  VersionedSchema,
  versionedSchemaToHtml,
} from '@principle-theorem/editor';
import {
  EditorPresetsService,
  PrincipleEditorModule,
} from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { AnyCustomFormElement } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';
import { TypeNarrowedCustomFormElement } from '../lib/type-narrowed-custom-form-element';

@Component({
    selector: 'pr-form-builder-element-preview',
    templateUrl: './form-builder-element-preview.component.html',
    styleUrl: './form-builder-element-preview.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        NgMaterialModule,
        NgSharedModule,
        PrincipleEditorModule,
    ]
})
export class FormBuilderElementPreviewComponent {
  private _editorPresets = inject(EditorPresetsService);
  private _element$ = new ReplaySubject<AnyCustomFormElement>(1);
  typed = new TypeNarrowedCustomFormElement(this._element$);

  @Input()
  set element(element: AnyCustomFormElement) {
    if (element) {
      this._element$.next(element);
    }
  }

  toHTML(content: VersionedSchema): string {
    const extensions = this._editorPresets.defaultToHTMLExtensions();
    return versionedSchemaToHtml(content, extensions);
  }
}
