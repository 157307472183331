import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  inject,
  signal,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  InformationBoxComponent,
  PerioSettingsService,
  StafferSettingsStoreService,
} from '@principle-theorem/ng-principle-shared';
import {
  ButtonsContainerComponent,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  DEFAULT_PERIO_SETTINGS,
  IPerioSettings,
  SwitchDirection,
  SwitchPattern,
  TableStyle,
} from '@principle-theorem/principle-core/interfaces';
import { getEnumValues } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-perio-chart-settings-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatToolbarModule,
    MatButtonModule,
    ButtonsContainerComponent,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    InformationBoxComponent,
    MatTooltipModule,
  ],
  templateUrl: './perio-chart-settings-dialog.component.html',
  styleUrl: './perio-chart-settings-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerioChartSettingsDialogComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _stafferSettings = inject(StafferSettingsStoreService);
  private _perioSettings = inject(PerioSettingsService);

  movement = SwitchPattern;
  style = TableStyle;
  direction = SwitchDirection;
  movementPatterns = getEnumValues(SwitchPattern);
  autoSwitchCellsEnabled = signal(true);

  perioSettingsForm = new TypedFormGroup<IPerioSettings>({
    autoSwitchCells: new TypedFormControl(
      DEFAULT_PERIO_SETTINGS.autoSwitchCells
    ),
    switchPattern: new TypedFormControl(DEFAULT_PERIO_SETTINGS.switchPattern),
    switchDirection: new TypedFormControl(
      DEFAULT_PERIO_SETTINGS.switchDirection
    ),
    tableStyle: new TypedFormControl(DEFAULT_PERIO_SETTINGS.tableStyle),
  });

  constructor() {
    this._stafferSettings.charting$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((settings) => {
        this.perioSettingsForm.patchValue(settings.perio, {
          emitEvent: false,
        });
        this._perioSettings.applyStafferSettings(settings.perio);
      });

    this.perioSettingsForm.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((settings) => {
        this._perioSettings.applyStafferSettings(settings);
        this._stafferSettings.updateStafferSettings({
          charting: { perio: settings },
        });
        this.autoSwitchCellsEnabled.set(settings.autoSwitchCells);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
