import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ManagementService,
  OrganisationService,
  StateBasedNavigationService,
  userHasPermissions$,
} from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { PracticeMigration } from '@principle-theorem/practice-migrations';
import { ReportingPermissions } from '@principle-theorem/principle-core/features';
import {
  type IBrand,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  asBoolean,
  filterUndefined,
  firstResult,
  getEnumValues,
  isPathChanged$,
  isSameRef,
  where,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { type ISidebarItem, type SidebarItem } from './sidebar-item';
import { SidebarItems, type ISideBarItemScope } from './sidebar-items';
import {
  MANAGEMENT_SIDEBAR_CONFIG,
  MIGRATION_SIDEBAR_CONFIG,
  OPERATIONS_SIDEBAR_CONFIG,
  REPORTING_SIDEBAR_CONFIG,
  SCHEDULING_SIDEBAR_CONFIG,
} from './sidebar-items-config';

interface ISideBarSection {
  label: string;
  routes: SidebarItem[];
}

@Component({
  selector: 'pr-sidebar-items',
  templateUrl: './sidebar-items.component.html',
  styleUrls: ['./sidebar-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarItemsComponent {
  trackByRoute = TrackByFunctions.label<ISidebarItem>();
  migrationRoutes$: Observable<ISideBarSection>;
  schedulingRoutes$: Observable<ISideBarSection>;
  operationRoutes$: Observable<ISideBarSection>;
  reportingRoutes$: Observable<ISideBarSection>;
  managementRoutes$: Observable<ISidebarItem[]>;
  hasReportingPermissions$: Observable<boolean>;
  hasMigrationPermissions$: Observable<boolean>;
  @Input() expanded = false;

  constructor(
    private _organisation: OrganisationService,
    private _management: ManagementService,
    private _stateNav: StateBasedNavigationService
  ) {
    const staffer$: Observable<WithRef<IStaffer> | undefined> =
      this._organisation.staffer$;

    this.migrationRoutes$ = combineLatest([
      this._organisation.brand$.pipe(filterUndefined()),
      this._organisation.practice$.pipe(filterUndefined()),
      staffer$,
    ]).pipe(
      map(([brand, practice, staffer]) => ({
        label: `Migration`,
        routes: this._rebuildMigrationRoutes(brand, practice, staffer),
      }))
    );

    this.schedulingRoutes$ = combineLatest([
      this._organisation.brand$.pipe(filterUndefined()),
      this._organisation.practice$.pipe(filterUndefined()),
      staffer$,
    ]).pipe(
      map(([brand, practice, staffer]) => ({
        label: `Scheduling`,
        routes: this._rebuildSchedulingRoutes(brand, practice, staffer),
      }))
    );

    this.operationRoutes$ = combineLatest([
      this._organisation.brand$.pipe(filterUndefined()),
      this._organisation.practice$.pipe(filterUndefined()),
      staffer$,
    ]).pipe(
      map(([brand, practice, staffer]) => ({
        label: `Operations`,
        routes: this._rebuildOperationalRoutes(brand, practice, staffer),
      }))
    );

    this.reportingRoutes$ = of({
      label: 'Reporting',
      routes: this._rebuildReportingRoutes(),
    });

    this.hasReportingPermissions$ = combineLatest([
      of(getEnumValues(ReportingPermissions)),
      this._organisation.userPermissions$,
      this._management.user$.pipe(isPathChanged$('ref.path')),
    ]).pipe(userHasPermissions$());

    this.hasMigrationPermissions$ = this._determineMigrationAccess();

    this.managementRoutes$ = this._management.user$.pipe(
      asBoolean(),
      map((isManager) => (isManager ? MANAGEMENT_SIDEBAR_CONFIG : []))
    );
  }

  async loadPractice(practice: WithRef<IPractice>): Promise<void> {
    await this._stateNav.practice([], {}, practice);
    window.location.reload();
  }

  private _determineMigrationAccess(): Observable<boolean> {
    return combineLatest([
      this._management.user$.pipe(isPathChanged$('ref.path'), asBoolean()),
      this._organisation.staffer$.pipe(isPathChanged$('ref.path')),
      this._organisation.organisation$.pipe(
        filterUndefined(),
        isPathChanged$('ref.path'),
        switchMap((organisation) =>
          firstResult(
            PracticeMigration.col(),
            where('configuration.organisation.ref', '==', organisation.ref)
          )
        )
      ),
    ]).pipe(
      map(([isManager, staffer, migration]) => {
        if (isManager) {
          return true;
        }

        if (!migration?.configuration.restrictAccessTo) {
          return false;
        }

        return migration.configuration.restrictAccessTo.some((restrictedRef) =>
          isSameRef(restrictedRef, staffer)
        );
      })
    );
  }

  private _rebuildMigrationRoutes(
    brand: WithRef<IBrand>,
    practice: WithRef<IPractice>,
    staffer?: WithRef<IStaffer>
  ): SidebarItem[] {
    const scope: ISideBarItemScope = {
      brand,
      practice,
      staffer,
      userPractices$: this._organisation.userPractices$,
    };
    const schedulingItems = new SidebarItems(MIGRATION_SIDEBAR_CONFIG);
    return schedulingItems.get(scope);
  }

  private _rebuildSchedulingRoutes(
    brand: WithRef<IBrand>,
    practice: WithRef<IPractice>,
    staffer?: WithRef<IStaffer>
  ): SidebarItem[] {
    const scope: ISideBarItemScope = {
      brand,
      practice,
      staffer,
      userPractices$: this._organisation.userPractices$,
    };
    const schedulingItems = new SidebarItems(SCHEDULING_SIDEBAR_CONFIG);
    return schedulingItems.get(scope);
  }

  private _rebuildOperationalRoutes(
    brand: WithRef<IBrand>,
    practice: WithRef<IPractice>,
    staffer?: WithRef<IStaffer>
  ): SidebarItem[] {
    const scope: ISideBarItemScope = {
      brand,
      practice,
      staffer,
      userPractices$: this._organisation.userPractices$,
    };
    const operationsItems = new SidebarItems(OPERATIONS_SIDEBAR_CONFIG);
    return operationsItems.get(scope);
  }

  private _rebuildReportingRoutes(): SidebarItem[] {
    const items = new SidebarItems(REPORTING_SIDEBAR_CONFIG);
    return items.get();
  }
}
