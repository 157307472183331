<mat-toolbar color="accent">Edit Sterilisation Record</mat-toolbar>

<mat-dialog-content>
  <form class="flex flex-col" [formGroup]="form" (ngSubmit)="save()">
    <mat-form-field>
      <mat-label>Sterilisation Record ID</mat-label>
      <input matInput formControlName="data" />
    </mat-form-field>
    <pr-patient-selector label="Patient" formControlName="patient" />
    <pr-staff-selector
      label="Scanned By"
      formControlName="scannedBy"
      [practice]="practiceScope.doc$ | async"
    />

    <mat-form-field>
      <mat-label>Cycle</mat-label>
      <mat-select
        formControlName="cycle"
        [compareWith]="isSelectedNamedDocument"
      >
        @for (cycle of availableCycles$ | async; track cycle.ref.path) {
          <mat-option [value]="cycle">
            {{ cycle.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <pr-sterilisation-pack-selector
      class="mb-6"
      [content]="packContent$ | async"
      [pack]="form.controls.pack.value"
      (updateContent)="form.controls.content.setValue($event)"
      (optionSelected)="form.controls.pack.setValue($event)"
    />

    <mat-form-field>
      <mat-label>Notes</mat-label>
      <pr-editor-input formControlName="notes" />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" (click)="save()">Save</button>
  </pt-buttons-container>
</mat-dialog-actions>
