import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgCustomFormsModule } from '@principle-theorem/ng-custom-forms';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { MedicalHistoryFormPreviewComponent } from './medical-history-form-preview/medical-history-form-preview.component';

@NgModule({
  declarations: [MedicalHistoryFormPreviewComponent],
  imports: [
    NgSharedModule,
    NgMaterialModule,
    ReactiveFormsModule,
    NgFeatureFlagsModule,
    NgPrincipleSharedModule,
    NgCustomFormsModule,
  ],
  exports: [MedicalHistoryFormPreviewComponent],
})
export class MedicalHistoryModule {}
