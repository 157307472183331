import { LineItemActions } from '@principle-theorem/principle-core';
import {
  ICustomLineItem,
  InvoiceLineItemType,
  InvoiceStatus,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import {
  IBigQueryServiceCodeLineItem,
  IBigQueryTreatmentLineItem,
  IInvoiceDimension,
} from '@principle-theorem/reporting/interfaces';
import { flatten, get } from 'lodash';
import { BaseDimensionMeasures } from '../base-measures';
import {
  DataAccessorFactory,
  getProperty,
  MeasurePath,
} from '../data-accessor-factory';
import { MeasureTransformMap, noFilterAccessor } from '../measure-properties';
import {
  CanBeChartedProperty,
  CanDoAllProperty,
  CanQueryByTimestampProperty,
} from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';

// TODO: All of these are pretty much incorrect
// https://app.clickup.com/t/2k5b2f2
export class InvoiceDimensionMeasures
  extends BaseDimensionMeasures
  implements MeasureTransformMap<Pick<IInvoiceDimension, 'status' | 'due'>>
{
  get ref(): CanDoAllProperty {
    const propertyName = 'ref';
    const measure = this.measureRef(MeasurePath.docRef(propertyName));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Invoice Ref',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get treatmentTotal(): CanBeChartedProperty {
    const propertyName = `items.${InvoiceLineItemType.Treatment}`;
    const measure = this.measureRef(propertyName);
    return new CanBeChartedProperty({
      metadata: {
        id: this._pathWithPrefix('treatmentTotal'),
        label: 'Invoice Treatment Total',
        summary: 'The total value of treatments on the invoice',
        formatter: MeasureFormatter.Currency,
      },
      measure: {
        propertyName: measure.attributePath,
        query: this.buildQuery().attributes([measure.attributePath]).get(),
        dataAccessor: (fact) =>
          LineItemActions.total(
            getProperty<ICustomLineItem[]>(fact, measure.factPropertyPath, [])
          ),
        filterAccessor: noFilterAccessor(),
      },
    });
  }

  get treatments(): CanDoAllProperty {
    const propertyName = `items.${InvoiceLineItemType.Treatment}`;
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.array(
      {
        id: this._pathWithPrefix('treatments'),
        label: 'Treatments',
        summary: `A list of the treatments that are on the invoice`,
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'description',
      (items: IBigQueryTreatmentLineItem[]) =>
        items.map((item) => item.description.replace(/\s-\s\(.*$/g, ''))
    );
  }

  get serviceCodes(): CanDoAllProperty {
    const propertyName = `items.${InvoiceLineItemType.Treatment}`;
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.array(
      {
        id: this._pathWithPrefix('serviceCodes'),
        label: 'Service Codes',
        summary: `A list of the service codes that are on the invoice`,
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'items',
      (items) =>
        flatten(
          items.map(
            (item) =>
              get(item, 'items.adaCode', []) as IBigQueryServiceCodeLineItem[]
          )
        ).map((code) => code.code)
    );
  }

  get productTotal(): CanBeChartedProperty {
    const propertyName = `items.${InvoiceLineItemType.Product}`;
    const measure = this.measureRef(propertyName);
    return new CanBeChartedProperty({
      metadata: {
        id: this._pathWithPrefix('productTotal'),
        label: 'Invoice Product Total',
        summary: 'The total value of products on the invoice',
        formatter: MeasureFormatter.Currency,
      },
      measure: {
        propertyName: measure.attributePath,
        query: this.buildQuery().attributes([measure.attributePath]).get(),
        dataAccessor: (fact) =>
          LineItemActions.total(
            getProperty<ICustomLineItem[]>(fact, measure.factPropertyPath, [])
          ),
        filterAccessor: noFilterAccessor(),
      },
    });
  }

  get products(): CanDoAllProperty {
    const propertyName = `items.${InvoiceLineItemType.Product}`;
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.array(
      {
        id: this._pathWithPrefix('products'),
        label: 'Products',
        summary: `A list of the products that are on the invoice`,
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'description'
    );
  }

  get depositTotal(): CanBeChartedProperty {
    const propertyName = `items.${InvoiceLineItemType.Deposit}`;
    const measure = this.measureRef(propertyName);
    return new CanBeChartedProperty({
      metadata: {
        id: this._pathWithPrefix('depositTotal'),
        label: 'Invoice Deposit Total',
        summary: 'The total value of deposits on the invoice',
        formatter: MeasureFormatter.Currency,
      },
      measure: {
        propertyName: measure.attributePath,
        query: this.buildQuery().attributes([measure.attributePath]).get(),
        dataAccessor: (fact) =>
          LineItemActions.total(
            getProperty<ICustomLineItem[]>(fact, measure.factPropertyPath, [])
          ),
        filterAccessor: noFilterAccessor(),
      },
    });
  }

  // This is misleading as people might think this is the total of the refund transactions. We're not using this type of line item so I'm commenting it out for now.
  // get refundTotal(): CanBeChartedProperty {
  //   const propertyName = `items.${InvoiceLineItemType.Refund}`;
  //   const measure = this.measureRef(propertyName);
  //   return new CanBeChartedProperty({
  //     metadata: {
  //       id: this._pathWithPrefix('refundTotal'),
  //       label: 'Invoice Refund Total',
  //       summary: 'The total value of refunds on the invoice',
  //       formatter: MeasureFormatter.Currency,
  //     },
  //     measure: {
  //       propertyName: measure.attributePath,
  //       query: this.buildQuery().attributes([measure.attributePath]).get(),
  //       dataAccessor: (fact) =>
  //         LineItemActions.total(
  //           getProperty<ICustomLineItem[]>(fact, measure.factPropertyPath, [])
  //         ),
  //       filterAccessor: noFilterAccessor(),
  //     },
  //   });
  // }

  get status(): CanDoAllProperty {
    const measure = this.measureRef('status');
    return MeasurePropertyFactory.enum<InvoiceStatus>(
      {
        id: this._pathWithPrefix('status'),
        label: 'Invoice Status',
        summary: 'The status of the invoice',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'Not Invoiced'
    );
  }

  get reference(): CanBeChartedProperty {
    const propertyName = 'reference';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Invoice Number',
        summary: 'The reference number of the invoice, beginning with "INV".',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'unknown'
    );
  }

  get total(): CanBeChartedProperty {
    const propertyName = 'total';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Invoice Total',
        summary: 'The total value of the invoice',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get due(): CanDoAllProperty {
    const propertyName = 'due';
    const measure = this.measureRef(MeasurePath.timestamp(propertyName));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Invoice Due Date',
        summary: 'Date that the invoice was due',
        formatter: MeasureFormatter.Day,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  /**
   * @deprecated Use the fact level properties instead.
   * `practitionerFees` or `practitionerPayments`.
   */
  get practitionerIncome(): CanBeChartedProperty {
    const unnest = {
      alias: 'item',
      property: this._pathWithPrefix('items.treatment'),
    };
    const propertyName = `${unnest.alias}`;
    const measure = this.measureRef(propertyName);

    return new CanBeChartedProperty({
      metadata: {
        id: this._pathWithPrefix('practitionerIncome'),
        label: 'Invoice Practitioner Income',
        summary: '',
        formatter: MeasureFormatter.Currency,
      },
      measure: {
        propertyName,
        query: this.buildQuery()
          .attributes([measure.attributePath])
          .unnest(unnest)
          .get(),
        dataAccessor: DataAccessorFactory.property<number>(
          `${measure.factPropertyPath}.amount`,
          0
        ),
        filterAccessor: noFilterAccessor(),
      },
    });
  }

  get createdAt(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('createdAt'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('createdAt'),
        label: 'Invoice Created',
        summary:
          'Date that the invoice was created. This is useful for grouping invoices by day.',
        formatter: MeasureFormatter.Day,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get issuedAt(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('issuedAt'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('issuedAt'),
        label: 'Invoice Issued At',
        summary: 'Date that the invoice was issed at',
        formatter: MeasureFormatter.Day,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      MeasureFormatter.Day
    );
  }

  get paidAt(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('paidAt'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('paidAt'),
        label: 'Invoice Paid At',
        summary: 'Date that the invoice was paid at',
        formatter: MeasureFormatter.Day,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      MeasureFormatter.Day
    );
  }
}
