import {
  ChartedCondition,
  ChartedSurface,
  ClinicalChart,
  MockAllTeeth,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  ChartableSurface,
  FailedDestinationEntityRecord,
  ITranslationMap,
  PerioMeasurement,
  type IChartedSurface,
  type IClinicalChart,
  type IConditionConfiguration,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPerioData,
  type IPerioDataPoints,
  type IPerioRecord,
  type IPracticeMigration,
  type IStaffer,
  type ToothNumber,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  asyncForEach,
  getDoc,
  getError,
  multiFilter,
  reduceToSingleArrayFn,
  toNamedDocument,
  toTimestamp,
  type IIdentifiable,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, groupBy } from 'lodash';
import * as moment from 'moment-timezone';
import { Observable, combineLatest } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  BasePatientClinicalChartDestinationEntity,
  IBaseClinicalChartJobData,
  IClinicalChartMigrationData,
  IGeneratedCharts,
} from '../../../destination/entities/patient-clinical-charts';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { TranslationMapHandler } from '../../../translation-map';
import {
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
  type IExactPatient,
  type IExactPatientTranslations,
} from '../../source/entities/patient';
import {
  PATIENT_PERIO_EXAMS_RESOURCE_TYPE,
  PatientPerioExamsSourceEntity,
  type IExactPatientPerioChart,
  type IExactPerioChartTranslations,
  type IExactPerioExam,
} from '../../source/entities/patient-perio-exams';
import {
  ExactTreatmentType,
  PatientTreatmentSourceEntity,
  type IExactTreatment,
  type IExactTreatmentFilters,
  type IExactTreatmentTranslations,
} from '../../source/entities/patient-treatments';
import {
  exactPatientIdIsWithinRange,
  getResolvedTreatmentData,
} from '../../util/helpers';
import { buildToothRef, getExactChartedRefs } from '../../util/tooth';
import { ExactItemCodeToConditionMappingHandler } from '../mappings/item-code-to-condition';
import { ExactItemCodeMappingHandler } from '../mappings/item-codes';
import {
  ExactStafferMappingHandler,
  resolveExactStaffer,
} from '../mappings/staff';
import { PatientDestinationEntity } from './patient';

export const PATIENT_CLINICAL_CHART_CUSTOM_MAPPING_TYPE =
  'patientClinicalChart';

export const PATIENT_CLINICAL_CHART_DESTINATION_ENTITY = DestinationEntity.init(
  {
    metadata: {
      key: 'patientClinicalCharts',
      label: 'Patient Clinical Charts',
      description: `
      According to Exact, all treatments with a type of 'Base' are considered the current state of the patient mouth. We will use only those treatments and, with a custom mapping for conditions,
      map anything listed as a treatment back to a Principle defined Condition Configuration.

      - A single Chart will be added to include all these conditions.
      - Perio charts will be added and timestamped according to the date of the Perio Exam in Exact
    `,
    },
  }
);

export interface IClinicalChartJobData
  extends IBaseClinicalChartJobData<IExactPatient> {
  sourcePatient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  existingTreatmentMappings: WithRef<
    ITranslationMap<IConditionConfiguration>
  >[];
}

export class PatientClinicalChartDestinationEntity extends BasePatientClinicalChartDestinationEntity<
  IExactPatient,
  IClinicalChartJobData
> {
  destinationEntity = PATIENT_CLINICAL_CHART_DESTINATION_ENTITY;
  periodontalResourceType = PATIENT_PERIO_EXAMS_RESOURCE_TYPE;
  sourceCountComparison = new PatientSourceEntity();
  override canMigrateByIdRange = true;

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    treatments: new PatientTreatmentSourceEntity(),
    periodontalChart: new PatientPerioExamsSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  customMappings = {
    staff: new ExactStafferMappingHandler(),
    itemCodes: new ExactItemCodeMappingHandler(),
    itemCodeToCondition: new ExactItemCodeToConditionMappingHandler(),
  };

  override filters = [
    new PatientIdFilter<IClinicalChartJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean,
    _fromDate?: Timestamp,
    _toDate?: Timestamp,
    fromId?: string,
    toId?: string
  ): Observable<IClinicalChartJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const existingTreatmentMappings$ =
      this.customMappings.itemCodeToCondition.getRecords$(translationMap);
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);

    return this.sourceEntities.patients
      .getRecords$(
        migration,
        500,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        multiFilter((patient) =>
          exactPatientIdIsWithinRange(
            patient.data.data.patient_id,
            fromId,
            toId
          )
        ),
        withLatestFrom(staff$, existingTreatmentMappings$, sourceItemCodes$),
        map(
          ([
            sourcePatients,
            staff,
            existingTreatmentMappings,
            sourceItemCodes,
          ]) =>
            sourcePatients.map((sourcePatient) => ({
              staff,
              sourcePatient,
              destinationEntity,
              existingTreatmentMappings,
              sourceItemCodes,
            }))
        )
      );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IClinicalChartJobData
  ): Promise<
    | IClinicalChartMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const errorResponseData = {
      label: data.sourcePatient.record.label,
      uid: data.sourcePatient.record.uid,
      ref: data.sourcePatient.record.ref,
    };

    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        errorResponseData,
        `Couldn't resolve patient`
      );
    }

    try {
      const charts = await this._buildClinicalChartData(
        migration,
        sourcePatientId,
        translationMap,
        data
      );

      return {
        sourcePatientId,
        patientRef,
        charts,
      };
    } catch (error) {
      return this._buildErrorResponse(errorResponseData, getError(error));
    }
  }

  private async _buildClinicalChartData(
    migration: WithRef<IPracticeMigration>,
    patientUid: string,
    translationMap: TranslationMapHandler,
    data: IClinicalChartJobData
  ): Promise<IGeneratedCharts> {
    const sourcePerioChartItems =
      await this.sourceEntities.periodontalChart.filterRecords(
        migration,
        'patientId',
        patientUid
      );

    const perioCharts = await buildPerioCharts(
      sourcePerioChartItems,
      translationMap,
      data
    );

    const conditions = await this.sourceEntities.treatments.filterRecords(
      migration,
      'patientId',
      patientUid,
      undefined,
      undefined,
      (record) => record.data.data.treatment_type !== ExactTreatmentType.Planned
    );

    const clinicalCharts = await this._buildClinicalCharts(
      data,
      migration,
      conditions,
      translationMap
    );

    return {
      clinicalCharts,
      perioCharts,
    };
  }

  private async _buildClinicalCharts(
    data: IClinicalChartJobData,
    migration: WithRef<IPracticeMigration>,
    sourceConditions: IGetRecordResponse<
      IExactTreatment,
      IExactTreatmentTranslations,
      IExactTreatmentFilters
    >[],
    translationMap: TranslationMapHandler
  ): Promise<(IClinicalChart & IIdentifiable)[]> {
    const chartedConditions = await asyncForEach(
      sourceConditions,
      async (sourceCondition) => {
        const condition = sourceCondition.data.data;
        const practitioner = await resolveExactStaffer(
          condition.provider_code,
          translationMap,
          data.staff
        );
        if (!practitioner) {
          throw new Error(
            `Couldn't resolve practitioner id: ${condition.provider_code} for condition: ${condition.service_code}`
          );
        }

        const mappedCondition = data.existingTreatmentMappings.find(
          (mapping) => mapping.sourceIdentifier === condition.service_code
        );
        const conditionConfig = mappedCondition?.destinationIdentifier
          ? await getDoc(mappedCondition.destinationIdentifier)
          : undefined;
        if (!conditionConfig) {
          return;
        }

        const resolvedTreatmentData = getResolvedTreatmentData(
          condition,
          practitioner,
          migration.configuration.timezone
        );

        const chartedSurfaces = this._getChartedSurfaces(
          condition,
          practitioner
        ).map((chartedSurface) => ({
          ...chartedSurface,
          chartedAt: resolvedTreatmentData?.resolvedAt ?? toTimestamp(),
          resolvedAt: resolvedTreatmentData?.resolvedAt,
          resolvedBy: resolvedTreatmentData?.resolvedBy,
        }));

        return ChartedCondition.init({
          config: toNamedDocument(conditionConfig),
          chartedSurfaces,
          resolvedAt: resolvedTreatmentData?.resolvedAt,
          resolvedBy: resolvedTreatmentData?.resolvedBy,
        });
      }
    );

    return [
      {
        ...ClinicalChart.init({
          teeth: MockAllTeeth(),
          conditions: compact(chartedConditions),
          createdAt: toTimestamp(moment.tz(migration.configuration.timezone)),
        }),
        uid: `clinicalChart-${data.sourcePatient.record.uid}`,
      },
    ];
  }

  private _getChartedSurfaces(
    sourceTreatment: IExactTreatment,
    practitioner: WithRef<IStaffer>
  ): IChartedSurface[] {
    if (!sourceTreatment.tooth_range && !sourceTreatment.tooth) {
      return [];
    }

    const teeth =
      sourceTreatment.tooth_range ?? compact([sourceTreatment.tooth]);
    return teeth
      .map((tooth) =>
        getExactChartedRefs(tooth, sourceTreatment.tooth_surfaces).map(
          (chartedRef) =>
            ChartedSurface.init({
              chartedBy: stafferToNamedDoc(practitioner),
              chartedRef,
            })
        )
      )
      .reduce(reduceToSingleArrayFn, []);
  }
}

async function buildPerioCharts(
  sourcePerioChartItems: IGetRecordResponse<
    IExactPatientPerioChart,
    IExactPerioChartTranslations
  >[],
  translationMap: TranslationMapHandler,
  data: IClinicalChartJobData
): Promise<(IClinicalChart & IIdentifiable)[]> {
  return asyncForEach(sourcePerioChartItems, async (perioChart) => {
    const createdAt = perioChart.data.translations.examDate;
    const perioRecords = buildPerioRecords(perioChart.data.data.records);

    const staffer = perioChart.data.data.provider_id
      ? await resolveExactStaffer(
          perioChart.data.data.provider_id,
          translationMap,
          data.staff
        )
      : undefined;

    return {
      ...ClinicalChart.init({
        perioRecords,
        teeth: MockAllTeeth(),
        createdAt,
        createdBy: staffer ? stafferToNamedDoc(staffer) : undefined,
        immutable: true,
      }),
      uid: perioChart.data.data.id,
    };
  });
}

function buildPerioRecords(
  records: IExactPerioExam[]
): IPerioRecord[] | undefined {
  const groupedByTooth = groupBy(records, (record) => record.tooth);
  return Object.entries(groupedByTooth).map(([toothNumber, groupedRecords]) => {
    const toothRef = buildToothRef(toothNumber as ToothNumber);
    if (!toothRef) {
      throw new Error(`Failed to build tooth ref for ${toothNumber}`);
    }
    return {
      toothRef,
      data: buildPerioRecordData(groupedRecords),
    };
  });
}

function buildPerioRecordData(records: IExactPerioExam[]): Partial<IPerioData> {
  return {
    [PerioMeasurement.Mobility]:
      records.find((record) => !!record.mobility)?.mobility ?? 0,
    [PerioMeasurement.Bleeding]: records.reduce(
      (acc, record) => ({ ...acc, ...getBleeding(record) }),
      {} as Partial<IPerioDataPoints>
    ),
    [PerioMeasurement.Suppuration]: records.reduce(
      (acc, record) => ({ ...acc, ...getSuppuration(record) }),
      {} as Partial<IPerioDataPoints>
    ),
    [PerioMeasurement.Pocket]: records.reduce(
      (acc, record) => ({ ...acc, ...getPocket(record) }),
      {} as Partial<IPerioDataPoints>
    ),
    [PerioMeasurement.Furcation]: records.reduce(
      (acc, record) => ({ ...acc, ...getFurcation(record) }),
      {} as Partial<IPerioDataPoints>
    ),
    [PerioMeasurement.Recession]: records.reduce(
      (acc, record) => ({ ...acc, ...getRecession(record) }),
      {} as Partial<IPerioDataPoints>
    ),
  };
}

function getPocket(record: IExactPerioExam): Partial<IPerioDataPoints> {
  if (record.tooth_side === ChartableSurface.Facial) {
    return {
      facialMesial: record.mesial_pocketdepth ?? 0,
      facialDistal: record.distal_pocketdepth ?? 0,
      facialCentral: record.central_pocketdepth ?? 0,
    };
  }
  return {
    palatalCentral: record.central_pocketdepth ?? 0,
    palatalDistal: record.distal_pocketdepth ?? 0,
    palatalMesial: record.mesial_pocketdepth ?? 0,
  };
}

function getFurcation(record: IExactPerioExam): Partial<IPerioDataPoints> {
  if (record.tooth_side === ChartableSurface.Facial) {
    return {
      facialMesial: record.mesial_furcationgrade ?? 0,
      facialDistal: record.distal_furcationgrade ?? 0,
      facialCentral: record.central_furcationgrade ?? 0,
    };
  }
  return {
    palatalCentral: record.central_furcationgrade ?? 0,
    palatalDistal: record.distal_furcationgrade ?? 0,
    palatalMesial: record.mesial_furcationgrade ?? 0,
  };
}

function getRecession(record: IExactPerioExam): Partial<IPerioDataPoints> {
  if (record.tooth_side === ChartableSurface.Facial) {
    return {
      facialMesial: record.mesial_gingivalmargin ?? 0,
      facialDistal: record.distal_gingivalmargin ?? 0,
      facialCentral: record.central_gingivalmargin ?? 0,
    };
  }
  return {
    palatalCentral: record.central_gingivalmargin ?? 0,
    palatalDistal: record.distal_gingivalmargin ?? 0,
    palatalMesial: record.mesial_gingivalmargin ?? 0,
  };
}

function getBleeding(record: IExactPerioExam): Partial<IPerioDataPoints> {
  if (record.tooth_side === ChartableSurface.Facial) {
    return {
      facialMesial: record.mesial_bleeding ? 1 : 0,
      facialDistal: record.distal_bleeding ? 1 : 0,
      facialCentral: record.central_bleeding ? 1 : 0,
    };
  }

  return {
    palatalCentral: record.central_bleeding ? 1 : 0,
    palatalDistal: record.distal_bleeding ? 1 : 0,
    palatalMesial: record.mesial_bleeding ? 1 : 0,
  };
}

function getSuppuration(record: IExactPerioExam): Partial<IPerioDataPoints> {
  if (record.tooth_side === ChartableSurface.Facial) {
    return {
      facialMesial: record.mesial_suppuration ? 1 : 0,
      facialDistal: record.distal_suppuration ? 1 : 0,
      facialCentral: record.central_suppuration ? 1 : 0,
    };
  }

  return {
    palatalCentral: record.central_suppuration ? 1 : 0,
    palatalDistal: record.distal_suppuration ? 1 : 0,
    palatalMesial: record.mesial_suppuration ? 1 : 0,
  };
}
