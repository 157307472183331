import { inject, Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { type IGeoCoordinates } from '@principle-theorem/principle-core/interfaces';
import { type Observable, from, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { type IGoogleMapsConfig, MAPS_CONFIG } from './config';

@Injectable()
export class GeocodeService {
  private _config: IGoogleMapsConfig = inject(MAPS_CONFIG);
  private _geocoder: google.maps.Geocoder;
  loader: Loader;

  constructor() {
    this.loader = new Loader({
      apiKey: this._config.apiKey,
      language: this._config.language,
      region: this._config.region,
      libraries: ['places'],
    });
  }

  geocodeAddress$(practice: string): Observable<IGeoCoordinates | undefined> {
    return this._ensureMapsLoaded().pipe(
      switchMap(() => this._parseAddress(practice))
    );
  }

  geocodeFromAddress$(
    address: string
  ): Observable<google.maps.GeocoderAddressComponent[]> {
    return this._ensureMapsLoaded().pipe(
      switchMap(() => this._getAddress(address))
    );
  }

  geocodeFromCoordinates$(
    coordinates: IGeoCoordinates
  ): Observable<google.maps.GeocoderAddressComponent[]> {
    return this._ensureMapsLoaded().pipe(
      switchMap(() => this._getLocation(coordinates))
    );
  }

  private _initGeocoder(): void {
    this._geocoder = new google.maps.Geocoder();
  }

  private _ensureMapsLoaded(): Observable<boolean> {
    if (this._geocoder) {
      return of(true);
    }
    return from(this.loader.load()).pipe(
      tap(() => this._initGeocoder()),
      map(() => true)
    );
  }

  private async _getAddress(
    address: string
  ): Promise<google.maps.GeocoderAddressComponent[]> {
    const results = await this._geocoder.geocode({ address });
    return results.results[0].address_components;
  }

  private async _getLocation(
    coordinates: IGeoCoordinates
  ): Promise<google.maps.GeocoderAddressComponent[]> {
    const results = await this._geocoder.geocode({
      location: {
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      },
    });
    return results.results[0].address_components;
  }

  private async _parseAddress(
    practice: string
  ): Promise<IGeoCoordinates | undefined> {
    const results = await this._geocoder.geocode({ address: practice });
    const firstResult = results.results[0];
    if (!firstResult) {
      return;
    }
    return {
      latitude: firstResult.geometry.location.lat(),
      longitude: firstResult.geometry.location.lng(),
    };
  }
}
