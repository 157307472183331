import {
  IChartedServiceExclusiveGroup,
  IPricedServiceCodeEntry,
  ServiceCodeGroupType,
} from '@principle-theorem/principle-core/interfaces';
import { asyncForEach, snapshot } from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';
import { FeeScheduleManager } from '../fees/fee-schedule/fee-schedule-manager';
import { PricedServiceCodeTranslator } from '../service-items/service-item';
import { PricedServiceCodeEntry } from './service-code';
import { getServiceCodeNumberString } from './service-provider';

export class ChartedServiceExclusiveGroup {
  static init(
    overrides: Partial<IChartedServiceExclusiveGroup>
  ): IChartedServiceExclusiveGroup {
    return {
      uid: uuid(),
      serviceCodes: [],
      ...overrides,
      type: ServiceCodeGroupType.Exclusive,
    };
  }

  static getSelected(
    group: IChartedServiceExclusiveGroup
  ): IPricedServiceCodeEntry | undefined {
    if (!group.selected) {
      return undefined;
    }
    const selectedUid = group.selected;
    return group.serviceCodes.find(
      (item) =>
        getServiceCodeNumberString(item.code) ===
        getServiceCodeNumberString(selectedUid)
    );
  }

  static async applyFeeSchedule(
    serviceGroup: IChartedServiceExclusiveGroup,
    stepServiceCodes: IPricedServiceCodeEntry[],
    manager: FeeScheduleManager,
    convertServiceCodes: boolean,
    changingFeeSchedule: boolean
  ): Promise<IChartedServiceExclusiveGroup> {
    const feeSchedule = await snapshot(manager.currentSchedule$);
    const serviceCodes = await asyncForEach(
      serviceGroup.serviceCodes,
      (entry: IPricedServiceCodeEntry) =>
        PricedServiceCodeEntry.applyFeeSchedule(
          entry,
          stepServiceCodes,
          manager,
          convertServiceCodes,
          changingFeeSchedule
        )
    );

    const translatedSelected = serviceGroup.selected
      ? PricedServiceCodeTranslator.convertToType(
          {
            code: serviceGroup.selected,
            type:
              serviceGroup.serviceCodes[0].type ?? feeSchedule.serviceCodeType,
          },
          feeSchedule.serviceCodeType
        )
      : undefined;

    return {
      ...serviceGroup,
      serviceCodes,
      selected: translatedSelected?.code,
    };
  }
}
