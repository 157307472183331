import {
  type ChartItemDisplayType,
  type IChartedItem,
  type IChartedMultiStepTreatment,
  type IChartedTreatment,
  type IStaffer,
  type ITreatmentPlanProposal,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChartedItemsCollection } from '../../../dental-chart-svg/models/charted-items-collection';

export class TreatmentProposalFilter {
  filters$: ReplaySubject<ChartItemDisplayType[]> = new ReplaySubject(1);
  proposal$: ReplaySubject<ITreatmentPlanProposal> = new ReplaySubject(1);
  chartingAs$: ReplaySubject<WithRef<IStaffer>> = new ReplaySubject(1);
  treatments$: Observable<IChartedTreatment[]>;
  multiTreatments$: Observable<IChartedMultiStepTreatment[]>;

  constructor() {
    this.treatments$ = this._treatments$();
    this.multiTreatments$ = this._multiTreatments$();
  }

  private _treatments$(): Observable<IChartedTreatment[]> {
    return combineLatest([
      this.proposal$,
      this.filters$,
      this.chartingAs$,
    ]).pipe(
      map(([proposal, displayTypes, staffer]) => {
        return this._filterByTypes<IChartedTreatment>(
          proposal.treatments,
          displayTypes,
          staffer
        );
      })
    );
  }

  private _multiTreatments$(): Observable<IChartedMultiStepTreatment[]> {
    return combineLatest([
      this.proposal$,
      this.filters$,
      this.chartingAs$,
    ]).pipe(
      map(([proposal, displayTypes, staffer]): IChartedMultiStepTreatment[] => {
        return this._filterByTypes<IChartedMultiStepTreatment>(
          proposal.multiTreatments,
          displayTypes,
          staffer
        );
      })
    );
  }

  private _filterByTypes<T extends IChartedItem>(
    items: T[],
    displayTypes: ChartItemDisplayType[],
    staffer: WithRef<IStaffer>
  ): T[] {
    return new ChartedItemsCollection<T>(items)
      .filterByTypes(displayTypes, staffer)
      .toArray();
  }
}
