import {
  Action,
  ActionReducer,
  createReducer,
  on,
  type MetaReducer,
} from '@ngrx/store';
import {
  type IInvoiceReportRecord,
  type IInvoiceReportRequest,
} from '@principle-theorem/reporting';
import { serialise, type SerialisedData } from '@principle-theorem/shared';
import {
  loadReport,
  loadReportFailure,
  loadReportSuccess,
  setQuery,
} from './outstanding-invoices.actions';

export const OUTSTANDING_INVOICES_REPORT_FEATURE_KEY =
  'outstanding-invoices-report';

export interface IOutstandingInvoicesReportState {
  isLoading: boolean;
  errorMessage?: string;
  results: IInvoiceReportRecord[];
  query?: IInvoiceReportRequest;
}

const initialState: IOutstandingInvoicesReportState = {
  isLoading: false,
  results: [],
};

export const metaReducers: MetaReducer<
  SerialisedData<IOutstandingInvoicesReportState>
>[] = [];

export function reducers(
  state: SerialisedData<IOutstandingInvoicesReportState> | undefined,
  action: Action
): SerialisedData<IOutstandingInvoicesReportState> {
  return practitionerIncomeReportReducer(state, action);
}

export const practitionerIncomeReportReducer: ActionReducer<
  SerialisedData<IOutstandingInvoicesReportState>,
  Action
> = createReducer(
  serialise(initialState),

  on(setQuery, (state, action) => ({
    ...state,
    query: action,
  })),

  on(loadReport, (state, _action) => ({
    ...state,
    isLoading: true,
  })),

  on(loadReportSuccess, (state, action) => ({
    ...state,
    errorMessage: undefined,
    results: action.results,
    isLoading: false,
  })),

  on(loadReportFailure, (state, action) => ({
    ...state,
    results: [],
    isLoading: false,
    errorMessage: action.errorMessage,
  }))
);
