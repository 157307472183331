import { TaxStrategy } from '@principle-theorem/accounting';
import { IProduct } from '@principle-theorem/principle-core/interfaces';
import { initFirestoreModel } from '@principle-theorem/shared';
import { BaseFirestoreMock, Properties } from '@principle-theorem/shared';

export class ProductMock
  extends BaseFirestoreMock
  implements Properties<IProduct>
{
  name: string = 'Product Name';
  cost: number = 100;
  taxStatus = TaxStrategy.GSTFree;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProductMock extends Pick<IProduct, 'name' | 'cost'> {}

const MOCK_PRODUCTS: IProductMock[] = [
  { name: 'Ibuprofen 200mg 24 pack', cost: 8 },
  { name: 'Paracetemol 500mg 24 pack', cost: 8 },
  { name: 'Ibuprofen + Paracetemol Combo 20 pack', cost: 12 },
  { name: 'Amoxicillin 500mg 20 pack', cost: 25 },
  { name: 'Tooth Mousse', cost: 29 },
  { name: 'Pixters 10 pack', cost: 12 },
  { name: 'Caredent Brushes 15x pack', cost: 16 },
  { name: 'Teeth Whitening Gel 10 %', cost: 25 },
  { name: 'Teeth Whitening Gel 20 %', cost: 28 },
  { name: 'Oral B Electric Tooth Brush Z5500', cost: 89 },
  { name: 'End Tuft Brush', cost: 14 },
  { name: `Children's Dental Health Kit`, cost: 12 },
];

const TYRO_TEST_PRODUCTS: IProductMock[] = [
  { name: `Purchase Success`, cost: 100 },
  { name: `Purchase Declined`, cost: 100.01 },
  { name: `Purchase Signature`, cost: 100.08 },
  { name: `Purchase No Signature`, cost: 100.11 },
  { name: `Foreign Currency Approved`, cost: 112 },
  { name: `Foreign Currency Declined`, cost: 112.01 },
  { name: `Foreign Currency Signature`, cost: 112.08 },
  { name: `EFTPOS Express Approved`, cost: 35 },
  { name: `Purchase Not Approved`, cost: 600 },
  { name: `3 Question`, cost: 601 },
  { name: `1 Question`, cost: 602 },
  { name: `Response - REVERSED`, cost: 6.6 },
  { name: `Response - SYSTEM ERROR`, cost: 8.8 },
  { name: `Response - 202 ERROR`, cost: 2.02 },
  { name: `Response - 202 ERROR then DECLINED`, cost: 202.02 },
  { name: `Response - 400`, cost: 4.0 },
  { name: `Response - 403`, cost: 4.03 },
  { name: `Response - 404`, cost: 4.04 },
  { name: `Response - 409`, cost: 4.09 },
  { name: `Response - 410`, cost: 4.1 },
  { name: `Response - 500`, cost: 5.0 },
  { name: `Response - 501`, cost: 5.01 },
  { name: `Response - 502`, cost: 5.02 },
  { name: `Response - 503`, cost: 5.03 },
  { name: `Response - 504`, cost: 5.04 },
  { name: `Tipping - 01`, cost: 220 },
  { name: `Tipping - 02`, cost: 220.11 },
  { name: `Tipping - 03`, cost: 222.11 },
  { name: `Tipping - 04`, cost: 222.0 },
  { name: `Tipping - 05/06`, cost: 222.08 },
  { name: `Tipping - 07`, cost: 232 },
  { name: `Tipping - 08`, cost: 220.01 },
  { name: `Tipping - 09`, cost: 222.01 },
  { name: `Tipping - 10`, cost: 232.01 },
  { name: `Storage - VISA`, cost: 102 },
  { name: `Storage - Mastercard`, cost: 103 },
  { name: `Storage - American Express`, cost: 104 },
  { name: `Storage - Diners Club`, cost: 105 },
  { name: `Storage - JCB`, cost: 106 },
  { name: `Storage - UPI`, cost: 108 },
  { name: `Split - 50`, cost: 50 },
  { name: `Split - 150`, cost: 150 },
  { name: `Split - 200`, cost: 200 },
].map((item) => ({ ...item, name: `TYRO: ${item.name}` }));

export const PRODUCTS: IProduct[] = [
  ...TYRO_TEST_PRODUCTS,
  ...MOCK_PRODUCTS,
].map((data) => ({
  ...initFirestoreModel(),
  ...data,
  taxStatus: TaxStrategy.GSTApplicable,
}));
