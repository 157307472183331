import {
  initVersionedSchema,
  type RawInlineNodes,
  toTextContent,
} from '@principle-theorem/editor';
import {
  EventType,
  IAppointment,
  ICalendarEvent,
  ICandidateCalendarEvent,
  IEvent,
  IParticipant,
  IPatient,
  IPractice,
  IStaffer,
  ParticipantType,
  GapOfferStatus,
  ICandidate,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  WithRef,
  initFirestoreModel,
  isSameRef,
  isWithRef,
  ITimePeriod,
  MockTimestamp,
  toTimestamp,
  DocumentReference,
  INamedDocument,
} from '@principle-theorem/shared';
import {
  MockDocRef,
  MockNamedDocument,
  MockReffable,
} from '@principle-theorem/testing';
import * as moment from 'moment-timezone';

export class CalendarEvent {
  static init(overrides: AtLeast<ICalendarEvent, 'event'>): ICalendarEvent {
    return {
      locked: false,
      isBlocking: true,
      title: [],
      notes: initVersionedSchema(),
      eventHistory: [],
      eventTags: [],
      ...initFirestoreModel(),
      ...overrides,
    };
  }

  static includesStaffer(
    calendarEvent: ICalendarEvent,
    stafferRef: DocumentReference<IStaffer>
  ): boolean {
    return calendarEvent.event.participants.some((participant) =>
      isSameRef(participant.ref, stafferRef)
    );
  }

  static participantsByType<T extends IStaffer | IPatient>(
    event: IEvent,
    type: ParticipantType
  ): IParticipant<T>[] {
    return event.participants.filter(
      (participant): participant is IParticipant<T> => participant.type === type
    );
  }

  static replaceEvent(
    calendarEvent: ICalendarEvent,
    newEvent: IEvent
  ): Pick<ICalendarEvent, 'event' | 'eventHistory'> {
    const lastEvent =
      calendarEvent.eventHistory[calendarEvent.eventHistory.length - 1];
    const isSameEvent: boolean = lastEvent
      ? lastEvent.event === calendarEvent.event
      : false;
    if (!isSameEvent) {
      const eventHistory = [
        ...calendarEvent.eventHistory,
        {
          createdAt: toTimestamp(),
          event: calendarEvent.event,
        },
      ];
      return {
        ...calendarEvent,
        eventHistory,
        event: newEvent,
      };
    }

    return {
      ...calendarEvent,
      eventHistory: calendarEvent.eventHistory,
      event: newEvent,
    };
  }

  static hasChanged(
    currentEvent: ICalendarEvent | WithRef<ICalendarEvent> | undefined,
    newEvent: ICalendarEvent | WithRef<ICalendarEvent>
  ): boolean {
    if (!currentEvent) {
      return true;
    }

    if (
      !currentEvent.event.from.isEqual(newEvent.event.from) ||
      !currentEvent.event.to.isEqual(newEvent.event.to)
    ) {
      return true;
    }

    if (
      isWithRef<ICalendarEvent>(currentEvent) &&
      !isWithRef<ICalendarEvent>(newEvent)
    ) {
      return true;
    }

    if (
      !isWithRef<ICalendarEvent>(currentEvent) &&
      isWithRef<ICalendarEvent>(newEvent)
    ) {
      return true;
    }

    if (
      isWithRef<ICalendarEvent>(currentEvent) &&
      isWithRef<ICalendarEvent>(newEvent) &&
      !isSameRef(currentEvent, newEvent)
    ) {
      return true;
    }

    if (
      !isWithRef<ICalendarEvent>(currentEvent) &&
      !isWithRef<ICalendarEvent>(newEvent) &&
      (!isSameRef(currentEvent.scheduleRef, newEvent.scheduleRef) ||
        !currentEvent.event.from.isEqual(newEvent.event.from))
    ) {
      return true;
    }

    return false;
  }
}

export class CandidateCalendarEvent {
  static init(
    overrides: AtLeast<ICandidateCalendarEvent, 'candidate' | 'event'>
  ): ICandidateCalendarEvent {
    return {
      ...CalendarEvent.init({ event: overrides.event, isBlocking: false }),
      title: [toTextContent('Gap Candidate')],
      offerMade: false,
      ...overrides,
    };
  }
}

export function MockCalendarEvent(
  title: RawInlineNodes,
  range: ITimePeriod,
  type: EventType = EventType.Meeting,
  staffer: INamedDocument<IStaffer> = MockNamedDocument<IStaffer>(),
  overrides: Partial<ICalendarEvent> = {}
): WithRef<ICalendarEvent> {
  const participants = [{ ...staffer, type: ParticipantType.Staffer }];
  const participantRefs = participants.map((participant) => participant.ref);

  const calendarEvent: ICalendarEvent = {
    createdAt: toTimestamp(),
    deleted: false,
    event: {
      from: toTimestamp(range.from),
      to: toTimestamp(range.to),
      type,
      creator: staffer,
      organiser: staffer,
      participants,
      participantRefs,
      practice: MockNamedDocument<IPractice>('McMahons Point'),
    },
    eventTags: [],
    eventHistory: [],
    isBlocking: true,
    locked: false,
    notes: initVersionedSchema(),
    title,
    updatedAt: toTimestamp(),
    ...overrides,
  };
  return MockReffable(calendarEvent);
}

export function MockCandidateCalendarEvent(): WithRef<ICandidateCalendarEvent> {
  const range = { from: moment(), to: moment().add(1, 'hour') };
  const calendarEvent = MockCalendarEvent(
    [toTextContent('Gap Candidate')],
    range,
    EventType.GapCandidate
  );
  const candidate: ICandidate = {
    appointment: MockDocRef<IAppointment>(),
    patient: MockNamedDocument<IPatient>(),
    status: GapOfferStatus.Available,
    offerTimeFrom: MockTimestamp(),
    offerTimeTo: MockTimestamp(),
  };

  const candidateCalendarEvent = {
    ...calendarEvent,
    candidate,
    offerMade: false,
  };

  return MockReffable(candidateCalendarEvent);
}

export function calendarEventHasChanged(
  currentEvent: ICalendarEvent | WithRef<ICalendarEvent> | undefined,
  newEvent: ICalendarEvent | WithRef<ICalendarEvent>
): boolean {
  if (!currentEvent) {
    return true;
  }

  if (
    isWithRef<ICalendarEvent>(currentEvent) &&
    !isWithRef<ICalendarEvent>(newEvent)
  ) {
    return true;
  }

  if (
    !isWithRef<ICalendarEvent>(currentEvent) &&
    isWithRef<ICalendarEvent>(newEvent)
  ) {
    return true;
  }

  if (
    isWithRef<ICalendarEvent>(currentEvent) &&
    isWithRef<ICalendarEvent>(newEvent) &&
    !isSameRef(currentEvent, newEvent)
  ) {
    return true;
  }

  if (
    !isWithRef<ICalendarEvent>(currentEvent) &&
    !isWithRef<ICalendarEvent>(newEvent) &&
    (!isSameRef(currentEvent.scheduleRef, newEvent.scheduleRef) ||
      !currentEvent.event.from.isEqual(newEvent.event.from))
  ) {
    return true;
  }

  return false;
}
