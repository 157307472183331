import { type Observable } from 'rxjs';
import { map, scan } from 'rxjs/operators';
import {
  type DocumentChange,
  type DocumentChangeType,
  type Query,
} from './adaptor';
import { fromCollectionRef, type IDocumentChangeAction } from './from-ref';
import { isSameRef } from '../doc-ref';

const ALLOWED_EVENTS: DocumentChangeType[] = ['added', 'removed', 'modified'];

/**
 * Return a stream of document changes on a query. These results are in sort order.
 */
export function sortedChanges<T extends object>(
  query: Query<T>
): Observable<IDocumentChangeAction<T>[]> {
  return fromCollectionRef<T>(query).pipe(
    map((changes) => changes.payload.docChanges()),
    scan<DocumentChange<T>[]>(
      (current, changes) => combineChanges<T>(current, changes),
      []
    ),
    map((snapshots) =>
      snapshots.map((change) => ({ type: change.type, payload: change }))
    )
  );
}

/**
 * Combines the total result set from the current set of changes from an incoming set
 * of changes.
 */
function combineChanges<T extends object>(
  current: DocumentChange<T>[],
  changes: DocumentChange<T>[]
): DocumentChange<T>[] {
  changes.map((change) => {
    // skip unwanted change types
    if (ALLOWED_EVENTS.indexOf(change.type) > -1) {
      current = combineChange(current, change);
    }
  });
  return current;
}

/**
 * Creates a new sorted array from a new change.
 */
function combineChange<T extends object>(
  combined: DocumentChange<T>[],
  change: DocumentChange<T>
): DocumentChange<T>[] {
  switch (change.type) {
    case 'added':
      if (
        combined[change.newIndex] &&
        isSameRef(combined[change.newIndex].doc.ref, change.doc.ref)
      ) {
        // Not sure why the duplicates are getting fired
      } else {
        return sliceAndSplice(combined, change.newIndex, 0, change);
      }
      break;
    case 'modified':
      if (
        combined[change.oldIndex] === undefined ||
        isSameRef(combined[change.oldIndex].doc.ref, change.doc.ref)
      ) {
        // When an item changes position we first remove it
        // and then add it's new position
        if (change.oldIndex !== change.newIndex) {
          const copiedArray = combined.slice();
          copiedArray.splice(change.oldIndex, 1);
          copiedArray.splice(change.newIndex, 0, change);
          return copiedArray;
        } else {
          return sliceAndSplice(combined, change.newIndex, 1, change);
        }
      }
      break;
    case 'removed':
      if (
        combined[change.oldIndex] &&
        isSameRef(combined[change.oldIndex].doc.ref, change.doc.ref)
      ) {
        return sliceAndSplice(combined, change.oldIndex, 1);
      }
      break;
    default:
      break;
  }
  return combined;
}

/**
 * Splice arguments on top of a sliced array, to break top-level ===
 * this is useful for change-detection
 */
function sliceAndSplice<T>(
  original: T[],
  start: number,
  deleteCount: number,
  ...args: T[]
): T[] {
  const returnArray = original.slice();
  returnArray.splice(start, deleteCount, ...args);
  return returnArray;
}
