import { type PageEvent } from '@angular/material/paginator';
import { type Sort } from '@angular/material/sort';
import {
  query$,
  snapshot,
  toQuery,
  type UnwrapWithRef,
  type WithRef,
} from '@principle-theorem/shared';
import {
  type Query,
  endBefore,
  limit,
  orderBy,
  startAfter,
} from '@principle-theorem/shared';
import { first, get, last } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  ReplaySubject,
} from 'rxjs';
import { map, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { type ObservableDataTable } from './observable-data-table';

interface ISort {
  active: string;
  direction: 'asc' | 'desc';
}

export class PaginatedFirestoreTable<T extends object> {
  firstRecord$ = new BehaviorSubject<T | undefined>(undefined);
  lastRecord$ = new BehaviorSubject<T | undefined>(undefined);
  pageChange$ = new ReplaySubject<PageEvent>(1);
  sort$ = new BehaviorSubject<ISort>({
    active: 'uid',
    direction: 'asc',
  });
  pageSize$: Observable<number>;
  pageIndex$: Observable<number>;
  dataTable: ObservableDataTable<T>;
  loading$ = new BehaviorSubject<boolean>(false);

  constructor(pageSize: number) {
    this.pageSize$ = this.pageChange$.pipe(
      map((pageChange) => pageChange.pageSize)
    );

    this.pageIndex$ = this.pageChange$.pipe(
      map((pageChange) => pageChange.pageIndex)
    );

    this.pageChange$.next({
      pageIndex: 0,
      pageSize,
      length: 0,
    });
  }

  destroy(): void {
    this.dataTable.ngOnDestroy();
  }

  async sortChange(sort: Sort): Promise<void> {
    const pageChange = await snapshot(this.pageChange$);
    this.pageChange$.next({
      ...pageChange,
      pageIndex: 0,
    });
    this.sort$.next({
      active: sort.active,
      direction: sort.direction || 'asc',
    });
  }

  updatePage(page: PageEvent): void {
    this.pageChange$.next(page);
  }

  getRecords$(
    collectionRef: Query<UnwrapWithRef<T>>
  ): Observable<WithRef<UnwrapWithRef<T>>[]> {
    return combineLatest([
      this.pageChange$.pipe(startWith(undefined)),
      this.sort$,
    ]).pipe(
      tap(() => this.loading$.next(true)),
      withLatestFrom(this.firstRecord$, this.lastRecord$, this.pageSize$),
      switchMap(([[pageChange, sort], firstRecord, lastRecord, pageSize]) => {
        return query$(
          this._buildQuery(
            collectionRef,
            pageChange,
            sort,
            firstRecord,
            lastRecord,
            pageSize
          )
        ).pipe(
          tap((records) => this.lastRecord$.next(last(records) as WithRef<T>)),
          tap((records) =>
            this.firstRecord$.next(first(records) as WithRef<T>)
          ),
          tap(() => this.loading$.next(false)),
          startWith([])
        );
      })
    );
  }

  private _buildQuery(
    collectionRef: Query<UnwrapWithRef<T>>,
    pageChange: PageEvent | undefined,
    sort: ISort,
    firstRecord: T | undefined,
    lastRecord: T | undefined,
    pageSize: number
  ): Query<UnwrapWithRef<T>> {
    const query = toQuery(
      collectionRef,
      limit(pageChange?.pageSize ?? pageSize),
      orderBy(sort.active, sort.direction)
    );

    if (pageChange?.pageIndex === 0) {
      return query;
    }

    if (
      pageChange?.previousPageIndex &&
      pageChange.previousPageIndex > pageChange.pageIndex &&
      firstRecord
    ) {
      return toQuery(
        query,
        endBefore(get(firstRecord, sort.active) as unknown)
      );
    }

    return toQuery(query, startAfter(get(lastRecord, sort.active)));
  }
}
