import {
  WithRef,
  all$,
  asColRef,
  shareReplayCold,
} from '@principle-theorem/shared';
import { CollectionReference } from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import {
  IStaffer,
  RootCollection,
} from '@principle-theorem/principle-core/interfaces';

export class ManagementStaffer {
  static col(): CollectionReference<IStaffer> {
    return asColRef<IStaffer>(RootCollection.ManagementStaff);
  }

  static all$(): Observable<WithRef<IStaffer>[]> {
    return all$(this.col()).pipe(shareReplayCold());
  }
}
