import { Component, type OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { errorNil, getDoc$, type WithRef } from '@principle-theorem/shared';
import { LoadTaskActionService } from '@principle-theorem/ng-interactions';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { queryParam$ } from '@principle-theorem/ng-shared';
import { type IBrand } from '@principle-theorem/principle-core/interfaces';
import { Brand } from '@principle-theorem/principle-core';
import { combineLatest, noop, type Observable, Subject, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'pr-external-link-redirect',
    templateUrl: './external-link-redirect.component.html',
    styleUrls: ['./external-link-redirect.component.scss'],
    standalone: false
})
export class ExternalLinkRedirectComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  constructor(
    taskAction: LoadTaskActionService,
    organisation: OrganisationService,
    route: ActivatedRoute
  ) {
    const brand$: Observable<WithRef<IBrand>> = queryParam$(
      route,
      'brand'
    ).pipe(
      switchMap((brandUid: string) =>
        organisation.brandCol$.pipe(
          errorNil(),
          switchMap((brandCol) => getDoc$(brandCol, brandUid))
        )
      )
    );

    const practice$ = combineLatest([
      brand$,
      queryParam$(route, 'practice'),
    ]).pipe(
      switchMap(([brand, practiceUid]) =>
        getDoc$(Brand.practiceCol(brand), practiceUid)
      )
    );

    combineLatest([queryParam$(route, 'task'), brand$, practice$])
      .pipe(
        tap(([taskUid, brand, practice]) =>
          combineLatest([
            of(organisation.selectBrand(brand)),
            of(organisation.selectPractice(practice)),
            taskAction.do({ uid: taskUid }),
          ])
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
