import { rand, randNumber } from '@ngneat/falso';
import {
  AppointmentAction,
  CustomChartType,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import { IChartConfig } from '../chart-config';
import { generateBuilderData } from '../models/base-measures';
import { FactTables } from '../models/fact-tables';
import { BooleanMeasureFilter } from '../models/measure-filters';
import { CommonMockValues, MockTimestampedFacts } from './common-mock-values';
import { toMeasureBuilderData } from '../models/measure-properties';

/**
 * Accumulative Revenue vs KPI
 */
export const accumulativeRevenue: IChartConfig = {
  type: CustomChartType.Line,
  labels: {
    title: `Accumulative Revenue vs KPI`,
  },
  builderData: generateBuilderData({
    plottedOverTime: true,
    accumulateOverTime: true,
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost.reduceBySum()
      ),
    ],
  }),
};

export const accumulativeRevenueResults = [
  MockTimestampedFacts('accumulativeRevenueSeed', () => ({
    treatmentCost: randNumber({ min: 150, max: 500 }),
  })),
];

/**
 * Gross Income vs Expenses
 * Gross Income
 * TODO: Based on invoiceEvent. We need transaction based facts for incremental reporting
 */
export const grossIncome: IChartConfig = {
  type: CustomChartType.Line,
  labels: {
    title: `Gross Income vs Expenses - Gross Income`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.invoiceEvent.total
          .groupBy(FactTables.invoiceEvent.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.invoiceEvent.practice.name,
  }),
};

export const grossIncomeResults = [
  MockTimestampedFacts('grossIncomeSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      total: randNumber({ min: 250, max: 1500 }),
    };
  }),
];

/**
 * Gross Income vs Expenses
 * Dental Draw
 * TODO: Based on invoiceEvent. We need transaction based facts for incremental reporting
 */
export const dentalDraw: IChartConfig = {
  type: CustomChartType.Line,
  labels: {
    title: `Gross Income vs Expenses - Dental Draw`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.invoiceEvent.invoice.practitionerIncome
          .groupBy(FactTables.invoiceEvent.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.invoiceEvent.practice.name,
  }),
};

export const dentalDrawResults = [
  MockTimestampedFacts('dentalDrawSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      dentalDraw: randNumber({ min: 200, max: 1250 }),
    };
  }),
];

/**
 * Gross Income vs Expenses
 * Staff Expenses
 * TODO: No Idea what this means or where to pull it from
 */
export const staffExpenses: IChartConfig = {
  type: CustomChartType.Line,
  labels: {
    title: `Gross Income vs Expenses - Staff Expenses`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const staffExpensesResults = [
  MockTimestampedFacts('staffExpensesSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      treatmentCost: randNumber({ min: 150, max: 500 }),
    };
  }),
];

/**
 * Gross Income vs Expenses
 * Lab Fees
 */
export const labFees: IChartConfig = {
  type: CustomChartType.Line,
  labels: {
    title: `Gross Income vs Expenses - Lab Fees`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.labJobEvent.latestEvent.labJob.cost
          .groupBy(FactTables.labJobReceived.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.labJobReceived.practice.name,
  }),
};

export const labFeesResults = [
  MockTimestampedFacts('labFeesSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      labJob_cost: randNumber({ min: 150, max: 500 }),
    };
  }),
];

/**
 * Gross Income vs Expenses
 * Consumables Expended
 * TODO: No Idea where to pull this from
 */
export const consumablesExpended: IChartConfig = {
  type: CustomChartType.Line,
  labels: {
    title: `Gross Income vs Expenses - Consumables Expended`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const consumablesExpendedResults = [
  MockTimestampedFacts('consumablesExpendedSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      treatmentCost: randNumber({ min: 150, max: 500 }),
    };
  }),
];

/**
 * Gross Income vs Expenses
 * Facility Cost
 * TODO: No Idea where to pull this from
 */
export const facilityCost: IChartConfig = {
  type: CustomChartType.Line,
  labels: {
    title: `Gross Income vs Expenses - Facility Cost`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const facilityCostResults = [
  MockTimestampedFacts('facilityCostSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      treatmentCost: randNumber({ min: 150, max: 500 }),
    };
  }),
];

/**
 * Gross Income vs Potential Income
 * Gross Income.
 * Already Implemented Above
 */

/**
 * Gross Income vs Potential Income
 * Lost Income
 * TODO: This needs more logic to determine the lost income. It should be: Hourly Rate * Gaps In Hours
 */
export const lostIncome: IChartConfig = {
  type: CustomChartType.Line,
  labels: {
    title: `Gross Income vs Potential Income - Lost Income`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const lostIncomeResults = [
  MockTimestampedFacts('lostIncomeSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      treatmentCost: randNumber({ min: 150, max: 500 }),
    };
  }),
];

/**
 * Revenue Distribution
 * TODO: Based on invoiceEvent. We need transaction based facts for incremental reporting
 */
export const revenueDistribution: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Revenue Distribution`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.invoiceEvent.total
          .groupBy(FactTables.invoiceEvent.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.invoiceEvent.practice.name,
  }),
};

export const revenueDistributionResults = [
  MockTimestampedFacts('revenueDistributionSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      total: randNumber({ min: 150, max: 500 }),
    };
  }),
];

/**
 * Patient Distribution
 */
export const patientDistribution: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Patient Distribution`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.count
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceByCount()
          .setFormatter(MeasureFormatter.Number)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const patientDistributionResults = [
  MockTimestampedFacts('patientDistributionSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      count: 1,
    };
  }),
];

/**
 * New & Existing Patients
 * Returning Patients Seen
 */
export const returningPatientsSeen: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `New & Existing Patients - Returning Patients Seen`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.existingPatient
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceByCount()
          .setFormatter(MeasureFormatter.Number)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const returningPatientsSeenResults = [
  MockTimestampedFacts('returningPatientsSeenSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      isFirstAppointment: false,
    };
  }),
];

/**
 * New & Existing Patients
 * New Patients
 */
export const newPatients: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `New & Existing Patients - New Patients`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.newPatient
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceByCount()
          .setFormatter(MeasureFormatter.Number)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const newPatientsResults = [
  MockTimestampedFacts('newPatientsSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      isFirstAppointment: true,
    };
  }),
];

/**
 * Chair Utilisation
 * Time Used
 * TODO: Assuming that the chair is used for the entire appointment
 */
export const timeUsed: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Chair Utilisation - Time Used`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.duration
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const timeUsedResults = [
  MockTimestampedFacts('timeUsedSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      duration: randNumber({ min: 5, max: 120 }),
    };
  }),
];

/**
 * Chair Utilisation
 * Time Unused
 * TODO: Not sure how to calculate this.
 */
export const timeUnused: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Chair Utilisation - Time Unused`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const timeUnusedResults = [
  MockTimestampedFacts('timeUnusedSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      treatmentCost: randNumber({ min: 150, max: 500 }),
    };
  }),
];

/**
 * Rescheduling Rate & Cancellation Rate
 * Rescheduling Rate
 * TODO: Currently gives a raw value, not a ratio. What would the value be out of?
 */
export const reschedulingRate: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Rescheduling Rate & Cancellation Rate - Rescheduling Rate`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.rescheduled
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceByCount()
          .setFormatter(MeasureFormatter.Number)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const reschedulingRateResults = [
  MockTimestampedFacts('reschedulingRateSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      event_action: AppointmentAction.Cancelled,
    };
  }),
];

/**
 * Rescheduling Rate & Cancellation Rate
 * Cancellation Rate
 * TODO: Currently gives a raw value, not a ratio. What would the value be out of?
 */
export const cancellationRate: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Rescheduling Rate & Cancellation Rate - Cancellation Rate`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.cancelled
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceByCount()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const cancellationRateResults = [
  MockTimestampedFacts('cancellationRateSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      event_action: AppointmentAction.Cancelled,
    };
  }),
];

/**
 * FTAs & UTAs
 * FTAs
 */
export const ftas: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `FTAs & UTAs - FTAs`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.cancelled
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceByCount()
          .setFormatter(MeasureFormatter.Number)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const ftasResults = [
  MockTimestampedFacts('ftasSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      event_action: AppointmentAction.Cancelled,
    };
  }),
];

/**
 * FTAs & UTAs
 * UTAs
 */
export const utas: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `FTAs & UTAs - UTAs`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.cancelled
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceByCount()
          .setFormatter(MeasureFormatter.Number)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const utasResults = [
  MockTimestampedFacts('utasSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      event_action: AppointmentAction.Cancelled,
    };
  }),
];

/**
 * Breakdown of Staff Days Off
 * Annual Leeave
 */
export const annualLeave: IChartConfig = {
  type: CustomChartType.Table,
  labels: {
    title: `Gross Income vs Expenses - Gross Income`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.cancelled
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceByCount()
          .setFormatter(MeasureFormatter.Number)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const annualLeaveResults = [
  MockTimestampedFacts('annualLeaveSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      treatmentCost: randNumber({ min: 150, max: 500 }),
    };
  }),
];

/**
 * Breakdown of Staff Days Off
 * Sick Leave
 */
export const sickLeave: IChartConfig = {
  type: CustomChartType.Table,
  labels: {
    title: `Gross Income vs Expenses - Gross Income`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const sickLeaveResults = [
  MockTimestampedFacts('sickLeaveSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      treatmentCost: randNumber({ min: 150, max: 500 }),
    };
  }),
];

/**
 * Breakdown of Staff Days Off
 * Registered Day Off
 */
export const registeredDayOff: IChartConfig = {
  type: CustomChartType.Table,
  labels: {
    title: `Gross Income vs Expenses - Gross Income`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const registeredDayOffResults = [
  MockTimestampedFacts('registeredDayOffSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      treatmentCost: randNumber({ min: 150, max: 500 }),
    };
  }),
];

/**
 * Completed Tasks & Lab Jobs
 * Completed Lab Jobs
 */
export const completedLabJobs: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Completed Tasks & Lab Jobs - Completed Lab Jobs`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.labJobReceived.count
          .groupBy(FactTables.labJobReceived.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Number)
      ),
    ],
    groupByDimension: FactTables.labJobReceived.practice.name,
  }),
};

export const completedLabJobsResults = [
  MockTimestampedFacts('completedLabJobsSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      count: 1,
    };
  }),
];

/**
 * Completed Tasks & Lab Jobs
 * Completed Tasks
 */
export const completedTasks: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Completed Tasks & Lab Jobs - Completed Tasks`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.taskCompleted.count
          .groupBy(FactTables.taskCompleted.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Number)
      ),
    ],
    groupByDimension: FactTables.taskCompleted.practice.name,
  }),
};

export const completedTasksResults = [
  MockTimestampedFacts('completedTasksSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      count: 1,
    };
  }),
];

/**
 * Full Time Equivalent
 * Chair Hours Available
 * TODO: Where would I pull this data from?
 */
export const chairHoursAvailable: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Gross Income vs Expenses - Gross Income`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const chairHoursAvailableResults = [
  MockTimestampedFacts('chairHoursAvailableSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      treatmentCost: randNumber({ min: 150, max: 500 }),
    };
  }),
];

/**
 * Full Time Equivalent
 * Staff Hours Available
 * TODO: Where would I pull this data from?
 */
export const staffHoursAvailable: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Gross Income vs Expenses - Gross Income`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const staffHoursAvailableResults = [
  MockTimestampedFacts('staffHoursAvailableSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      treatmentCost: randNumber({ min: 150, max: 500 }),
    };
  }),
];

/**
 * Ratio of Available Chair Hours to Staff Hours
 * TODO: Where would I pull this data from?
 */
export const chairHoursAvailableToStaffHours: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Gross Income vs Expenses - Gross Income`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const chairHoursAvailableToStaffHoursResults = [
  MockTimestampedFacts('chairHoursAvailableToStaffHoursSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      treatmentCost: randNumber({ min: 150, max: 500 }),
    };
  }),
];

/**
 * Debt Collections
 * Written Off Invoices
 */
export const debtCollectionsWrittenOffInvoices: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Debt Collections - Written Off Invoices`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.invoiceEvent.amountRemaining
          .filterBy(
            new BooleanMeasureFilter(true),
            FactTables.invoiceEvent.writtenOff.measure
          )
          .groupBy(FactTables.invoiceEvent.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.invoiceEvent.practice.name,
  }),
};

export const debtCollectionsWrittenOffInvoicesResults = [
  MockTimestampedFacts('debtCollectionsWrittenOffInvoicesSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      amountRemaining: randNumber({ min: 150, max: 500 }),
    };
  }),
];

/**
 * Debt Collections
 * Overdue Invoices
 */
export const debtCollectionsOverdueInvoices: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Gross Income vs Expenses - Gross Income`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.invoiceEvent.amountRemaining
          .filterBy(
            new BooleanMeasureFilter(true),
            FactTables.invoiceEvent.isOverdue.measure
          )
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const debtCollectionsOverdueInvoicesResults = [
  MockTimestampedFacts('debtCollectionsOverdueInvoicesSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      amountRemaining: randNumber({ min: 150, max: 500 }),
    };
  }),
];

/**
 * Debt Collections
 * Active Payment Plans Amount
 */
export const debtCollectionsActivePaymentPlansAmount: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Gross Income vs Expenses - Gross Income`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practice.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practice.name,
  }),
};

export const debtCollectionsActivePaymentPlansAmountResults = [
  MockTimestampedFacts('debtCollectionsActivePaymentPlansAmountSeed', () => {
    const practice = rand(CommonMockValues.practices);
    return {
      practice_ref_referenceValue: practice.ref,
      practice_name: practice.name,
      treatmentCost: randNumber({ min: 150, max: 500 }),
    };
  }),
];
