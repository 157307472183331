import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard } from '@principle-theorem/shared';
import { flow, isNull, isNumber, isString } from 'lodash';
import { IBaseMigrationItemCode } from '../../../interfaces';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
} from '../../../source/source-helpers';

export const ADA_ITEM_RESOURCE_TYPE = 'adaItem';

export const ADA_ITEM_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'ADA Item List',
    description: `
      Exact does not enforce explicit service codes in their system, and they do not provide any table that defines what service codes users can use in the system. To combat this
      we run a query for distinct uses of service codes in the treatment table. This may return some odd results so we MUST complete the translation maps
      to be uploaded in the Custom Mappings sections before doing the Treatment Plans and Charts destination migrations.
    `,
    idPrefix: ADA_ITEM_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface IExactADAItem extends IBaseMigrationItemCode {
  id: number;
}

export function isExactADAItemListItem(item: unknown): item is IExactADAItem {
  return TypeGuard.interface<IExactADAItem>({
    id: isNumber,
    itemCode: isString,
    description: [isString, isNull],
  })(item);
}

const ADA_ITEM_SOURCE_QUERY = `
SELECT
	DISTINCT(servicecode) AS item_code,
	servicedescription AS description,
	DENSE_RANK() OVER (results) AS id
FROM convtreatment
WINDOW results AS (ORDER BY servicecode)
ORDER BY id
`;

export class ADAItemSourceEntity extends BaseSourceEntity<IExactADAItem> {
  sourceEntity = ADA_ITEM_SOURCE_ENTITY;
  entityResourceType = ADA_ITEM_RESOURCE_TYPE;
  sourceQuery = ADA_ITEM_SOURCE_QUERY;
  verifySourceFn = isExactADAItemListItem;

  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    (rows: IExactADAItem[]) =>
      rows.map((row) => ({
        ...row,
        itemCode: row.itemCode.replace(/\//g, ' '),
      })),
  ]);

  translate(): Record<symbol, unknown> {
    return {};
  }

  getSourceRecordId(data: Pick<IExactADAItem, 'itemCode'>): string {
    return data.itemCode;
  }

  getSourceLabel(data: IExactADAItem): string {
    return `${data.itemCode}`;
  }
}
