import { type Timestamp } from '@principle-theorem/shared';

export interface IDataChange<Data> {
  before: Data;
  after: Data;
}

export interface IStatusHistory<Status> {
  status: Status;
  updatedAt: Timestamp;
}

export type SortReturnValue = -1 | 0 | 1;
