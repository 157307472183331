import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'pr-meta-pixel-tracking-code-help',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './meta-pixel-tracking-code-help.component.html',
  styleUrl: './meta-pixel-tracking-code-help.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaPixelTrackingCodeHelpComponent {}
