<ng-container *ngIf="user$ | async as user">
  <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="16px">
    <div fxFlex="50" fxLayout="column" fxLayoutGap="16px" [formGroup]="form">
      <h1 class="mat-headline-4">Edit User</h1>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" />
      </mat-form-field>

      <mat-slide-toggle
        *ngIf="(isLoggedInUser$ | async) === false"
        formControlName="isEnabled"
      >
        Is Enabled
      </mat-slide-toggle>

      <h1 class="mat-headline-4">Roles</h1>
      <pr-role-assignment
        [options]="roles$ | async"
        [roles]="user.roles"
        [disabled]="disabled$ | async"
        (rolesChange)="updateRoles($event)"
       />

      <h1 class="mat-headline-4">Access</h1>
      <pr-access-selector
        [brands]="brands$ | async"
        [access]="user"
        [disabled]="disabled$ | async"
        (accessChange)="updateAccess($event)"
       />
    </div>

    <pt-profile-image-manager
      [storagePath]="organisation.storagePath$ | async"
      [profile]="user"
     />
  </div>
  <pt-buttons-container padding="all-round">
    <button
      *ngIf="canInvite(user)"
      mat-stroked-button
      (click)="inviteUser.emit(user)"
    >
      Resend Invitation
    </button>
    <pt-loader-button
      color="primary"
      type="flat"
      [disabled]="form.invalid"
      [loading]="saving$ | async"
      (clicked)="save()"
    >
      Save
    </pt-loader-button>
  </pt-buttons-container>
</ng-container>
