import { Validators } from '@angular/forms';
import {
  type TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  type IPractice,
  type IProviderData,
  type IStaffer,
  type ServiceTypeModality,
} from '@principle-theorem/principle-core/interfaces';
import { ALPHA_NUMERIC } from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';

export class ProviderDetailsFormGroup extends TypedFormGroup<IProviderData> {
  constructor() {
    super({
      practiceRef: new TypedFormControl<DocumentReference<IPractice>>(),
      providerModality: new TypedFormControl<ServiceTypeModality>(),
      providerNumber: new TypedFormControl<string>('', [
        Validators.pattern(ALPHA_NUMERIC),
        Validators.minLength(8),
        Validators.maxLength(8),
      ]),
      payeeProviderNumber: new TypedFormControl<string>('', [
        Validators.pattern(ALPHA_NUMERIC),
        Validators.minLength(8),
        Validators.maxLength(8),
      ]),
    });
  }
}

export class ProviderDetailsControls {
  constructor(private formArray: TypedFormArray<IProviderData>) {}

  addControl(
    index: number,
    options?: {
      emitEvent?: boolean;
    }
  ): void {
    const insertIndex = index + 1;
    this.formArray.insert(insertIndex, new ProviderDetailsFormGroup(), options);
  }

  removeControl(index: number): void {
    if (index < 1) {
      return;
    }
    this.formArray.removeAt(index);
  }

  reset(options?: { emitEvent?: boolean }): void {
    this.formArray.clear(options);
    this.addControl(0, options);
  }

  initProviderDetailsControls(
    staffer: IStaffer,
    options?: {
      emitEvent?: boolean;
    }
  ): void {
    this.reset(options);
    staffer.providerDetails.map((_, index) =>
      index ? this.addControl(index, options) : undefined
    );
  }
}
