<div fxLayout="column" fxLayoutGap="16px">
  <pt-buttons-container>
    <button mat-raised-button color="primary" (click)="addPackage()">
      Add New Package
    </button>
  </pt-buttons-container>

  <ng-container *ngIf="treatmentConfig$ | async as treatmentConfig">
    <pr-edit-treatment-package
      *ngFor="let package of packages$ | async; trackBy: trackByPackage"
      [treatmentPackage]="package"
      [treatmentConfig]="treatmentConfig"
      (updatePackage)="updatePackage(package.uid, $event)"
      (deletePackage)="deletePackage(package.uid)"
     />
  </ng-container>
</div>
