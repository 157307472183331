<div fxLayout="column">
  <div class="layout-margin" fxLayout="row" fxLayoutAlign="end center">
    <pt-date-range-form
      [from]="from$ | async"
      [to]="to$ | async"
      (changed)="updateDates($event)"
    />
  </div>

  <div class="mat-elevation-z1 layout-margin bg-white">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="paginatedTable.loading$ | async"
    />

    <mat-table
      [dataSource]="paginatedTable.dataTable.dataSource"
      [trackBy]="trackByRef"
    >
      <mat-header-row
        *matHeaderRowDef="paginatedTable.dataTable.displayColumns"
      />
      <mat-row
        *matRowDef="
          let record;
          columns: paginatedTable.dataTable.displayColumns
        "
      />

      <ng-container matColumnDef="data">
        <mat-header-cell *matHeaderCellDef class="truncate">
          ID
        </mat-header-cell>
        <mat-cell *matCellDef="let record">
          <a
            href="javascript:void(0);"
            class="label-offset"
            (click)="editRecord(record)"
          >
            {{ record.data }}
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="patient">
        <mat-header-cell *matHeaderCellDef class="truncate">
          Patient
        </mat-header-cell>
        <mat-cell *matCellDef="let record" class="truncate">
          {{ record.patient.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="scannedBy">
        <mat-header-cell *matHeaderCellDef class="truncate">
          Scanned By
        </mat-header-cell>
        <mat-cell *matCellDef="let record" class="truncate">
          {{ record.scannedBy?.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <mat-header-cell *matHeaderCellDef class="truncate">
          Created On
        </mat-header-cell>
        <mat-cell *matCellDef="let record" class="truncate">
          {{ record.createdAt | moment | amDateFormat: dateFormat }}
        </mat-cell>
      </ng-container>
    </mat-table>

    <ng-container *ngIf="emptySearch$ | async">
      <pr-empty-state
        class="layout-margin"
        image="search"
        title="search results"
        [searchInputValue]="searchInputValue$ | async"
      />
    </ng-container>

    <mat-paginator
      #paginator
      [length]="totalRecordCount$ | async"
      [pageSize]="paginatedTable.pageSize$ | async"
      [pageSizeOptions]="paginatedTable.dataTable.pageSizeOptions"
      (page)="paginatedTable.updatePage($event)"
      showFirstLastButtons
    />
  </div>
</div>
