import { InjectionToken } from '@angular/core';
import { type IProfile, type WithRef } from '@principle-theorem/shared';
import { type ITypesenseConfig } from '@principle-theorem/typesense';
import { type Observable } from 'rxjs';

interface IStackdriverConfig {
  key: string;
  projectId: string;
}

export const TYPESENSE_SEARCH_CONFIG = new InjectionToken<ITypesenseConfig>(
  'typesense-search-config'
);

export interface INgSharedConfig {
  stackdriver: IStackdriverConfig;
  appUrl: string;
  production: boolean;
}

export const NG_SHARED_CONFIG: InjectionToken<INgSharedConfig> =
  new InjectionToken('INgSharedConfig');

export interface IOrganisationService {
  storagePath$: Observable<string>;
  user$: Observable<WithRef<IProfile>>;
}

export const ORGANISATION_SERVICE: InjectionToken<IOrganisationService> =
  new InjectionToken('IOrganisationService');
