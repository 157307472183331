<pt-breadcrumbs
  [breadcrumbs]="breadcrumbs"
  current="Practitioner Reports"
 />

<div class="layout-padding">
  <h2 class="layout-margin">Practitioner Income Report</h2>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
    class="layout-margin"
  >
    <pt-date-range-form
      [useRangePicker]="true"
      [from]="from"
      [to]="to"
      (changed)="updateDateRange($event)"
     />
    <pr-practice-selector
      [brands]="brands$ | async"
      [practice]="practice$ | async"
      (practiceSelected)="practice$.next($event)"
     />
  </div>

  <!-- <pr-practitioner-income [dataBuilder]="dataBuilder"></pr-practitioner-income> -->

  <!-- <pr-practitioner-collections [dataBuilder]="dataBuilder">
  </pr-practitioner-collections>

  <pr-practitioner-productivity [dataBuilder]="dataBuilder">
  </pr-practitioner-productivity>

  <pr-practitioner-patients [dataBuilder]="dataBuilder">
  </pr-practitioner-patients>

  <pr-practitioner-treatments [dataBuilder]="dataBuilder">
  </pr-practitioner-treatments> -->
</div>
