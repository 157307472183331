import { Tooth } from './tooth';
import { AtLeast } from '@principle-theorem/shared';
import {
  ITooth,
  Quadrant,
  ToothType,
} from '@principle-theorem/principle-core/interfaces';

function MockTooth(data: AtLeast<ITooth, 'toothRef'>): ITooth {
  return Tooth.init(data);
}

export function MockMolar(quadrant: Quadrant, quadrantIndex: number): ITooth {
  return MockTooth({
    type: ToothType.Molar,
    toothRef: { quadrant, quadrantIndex },
    roots: 3,
  });
}

function MockPremolar1(quadrant: Quadrant, quadrantIndex: number): ITooth {
  return MockTooth({
    type: ToothType.Premolar,
    toothRef: { quadrant, quadrantIndex },
    roots: 2,
  });
}

function MockPremolar2(quadrant: Quadrant, quadrantIndex: number): ITooth {
  return MockTooth({
    type: ToothType.Premolar,
    toothRef: { quadrant, quadrantIndex },
    roots: 1,
  });
}

function MockCanine(quadrant: Quadrant, quadrantIndex: number): ITooth {
  return MockTooth({
    type: ToothType.Canine,
    toothRef: { quadrant, quadrantIndex },
    roots: 1,
  });
}

function MockIncisor(quadrant: Quadrant, quadrantIndex: number): ITooth {
  return MockTooth({
    type: ToothType.Incisor,
    toothRef: { quadrant, quadrantIndex },
    roots: 1,
  });
}

export function MockAdultTeeth(): ITooth[] {
  return [
    // Upper Right
    MockMolar(Quadrant.AdultUpperRight, 8),
    MockMolar(Quadrant.AdultUpperRight, 7),
    MockMolar(Quadrant.AdultUpperRight, 6),
    MockPremolar2(Quadrant.AdultUpperRight, 5),
    MockPremolar1(Quadrant.AdultUpperRight, 4),
    MockCanine(Quadrant.AdultUpperRight, 3),
    MockIncisor(Quadrant.AdultUpperRight, 2),
    MockIncisor(Quadrant.AdultUpperRight, 1),
    // Upper Left
    MockIncisor(Quadrant.AdultUpperLeft, 1),
    MockIncisor(Quadrant.AdultUpperLeft, 2),
    MockCanine(Quadrant.AdultUpperLeft, 3),
    MockPremolar1(Quadrant.AdultUpperLeft, 4),
    MockPremolar2(Quadrant.AdultUpperLeft, 5),
    MockMolar(Quadrant.AdultUpperLeft, 6),
    MockMolar(Quadrant.AdultUpperLeft, 7),
    MockMolar(Quadrant.AdultUpperLeft, 8),
    // Lower Left
    MockPremolar1(Quadrant.AdultLowerLeft, 8),
    MockPremolar1(Quadrant.AdultLowerLeft, 7),
    MockPremolar1(Quadrant.AdultLowerLeft, 6),
    MockPremolar2(Quadrant.AdultLowerLeft, 5),
    MockPremolar2(Quadrant.AdultLowerLeft, 4),
    MockCanine(Quadrant.AdultLowerLeft, 3),
    MockIncisor(Quadrant.AdultLowerLeft, 2),
    MockIncisor(Quadrant.AdultLowerLeft, 1),
    // Lower Right
    MockIncisor(Quadrant.AdultLowerRight, 1),
    MockIncisor(Quadrant.AdultLowerRight, 2),
    MockCanine(Quadrant.AdultLowerRight, 3),
    MockPremolar2(Quadrant.AdultLowerRight, 4),
    MockPremolar2(Quadrant.AdultLowerRight, 5),
    MockPremolar1(Quadrant.AdultLowerRight, 6),
    MockPremolar1(Quadrant.AdultLowerRight, 7),
    MockPremolar1(Quadrant.AdultLowerRight, 8),
  ];
}

export function MockDeciduousTeeth(): ITooth[] {
  return [
    // Upper Right
    MockMolar(Quadrant.DeciduousUpperRight, 5),
    MockMolar(Quadrant.DeciduousUpperRight, 4),
    MockCanine(Quadrant.DeciduousUpperRight, 3),
    MockIncisor(Quadrant.DeciduousUpperRight, 2),
    MockIncisor(Quadrant.DeciduousUpperRight, 1),
    // Upper Left
    MockIncisor(Quadrant.DeciduousUpperLeft, 1),
    MockIncisor(Quadrant.DeciduousUpperLeft, 2),
    MockCanine(Quadrant.DeciduousUpperLeft, 3),
    MockMolar(Quadrant.DeciduousUpperLeft, 4),
    MockMolar(Quadrant.DeciduousUpperLeft, 5),
    // Lower Left
    MockPremolar1(Quadrant.DeciduousLowerLeft, 5),
    MockPremolar1(Quadrant.DeciduousLowerLeft, 4),
    MockCanine(Quadrant.DeciduousLowerLeft, 3),
    MockIncisor(Quadrant.DeciduousLowerLeft, 2),
    MockIncisor(Quadrant.DeciduousLowerLeft, 1),
    // Lower Right
    MockIncisor(Quadrant.DeciduousLowerRight, 1),
    MockIncisor(Quadrant.DeciduousLowerRight, 2),
    MockCanine(Quadrant.DeciduousLowerRight, 3),
    MockPremolar1(Quadrant.DeciduousLowerRight, 4),
    MockPremolar1(Quadrant.DeciduousLowerRight, 5),
  ];
}

export function MockAllTeeth(): ITooth[] {
  return [...MockAdultTeeth(), ...MockDeciduousTeeth()];
}
