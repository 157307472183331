import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { roundTo2Decimals } from '@principle-theorem/accounting';
import {
  formControlChanges$,
  MOMENT_DATEPICKER_PROVIDERS,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  type IPractitionerProportionInvoiceAmount,
  TransactionOperators,
} from '@principle-theorem/principle-core';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import {
  type IDiscountExtendedData,
  type IStaffer,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  isSameRef,
  mergeDayAndTime,
  shareReplayCold,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { type Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { type ITransactionAmountDialogData } from '../../transaction-components/transaction-amount-dialog/transaction-amount-dialog.component';
import { AmendTransactionDateStore } from '../../transaction-components/transaction-edit-dialog/amend-transaction-date.store';
import { determineNewCreatedAtDate } from '../../transaction-helpers';

export interface IAmendDiscountTransactionDialogData
  extends ITransactionAmountDialogData {
  transaction: WithRef<ITransaction<IDiscountExtendedData>>;
  invoicePractitioners: IPractitionerProportionInvoiceAmount[];
  activeDiscounts: WithRef<ITransaction<IDiscountExtendedData>>[];
  dateReceived?: Moment;
}

export interface IAmendDiscountTransactionDialogResult {
  amount: number;
  description: string;
  extendedData: IDiscountExtendedData;
  dateReceived?: Moment;
}

class DiscountExtendedDataFormGroup extends TypedFormGroup<IDiscountExtendedData> {
  constructor(extendedData?: IDiscountExtendedData) {
    super({
      practitionerRef: new TypedFormControl<DocumentReference<IStaffer>>(
        extendedData?.practitionerRef
      ),
    });
  }
}

class AmendDiscountTransactionFormGroup extends TypedFormGroup<IAmendDiscountTransactionDialogResult> {
  get extendedData(): TypedFormGroup<IDiscountExtendedData> {
    return this.controls.extendedData as TypedFormGroup<IDiscountExtendedData>;
  }

  constructor(data: IAmendDiscountTransactionDialogData) {
    const initialAmountValue = data.options.initialAmount ?? data.max;
    const roundedAmount = initialAmountValue
      ? roundTo2Decimals(initialAmountValue)
      : undefined;
    const amountValidators = compact([
      Validators.required,
      data.max ? Validators.max(data.max) : undefined,
    ]);

    super({
      description: new TypedFormControl<string>(
        data.transaction.description ?? ''
      ),
      dateReceived: new TypedFormControl<Moment>(data.dateReceived),
      amount: new TypedFormControl<number>(roundedAmount, amountValidators),
      extendedData: new DiscountExtendedDataFormGroup(
        data.transaction.extendedData ?? {}
      ),
    });
  }
}

@Component({
  selector: 'pr-amend-discount-transaction-dialog',
  templateUrl: './amend-discount-transaction-dialog.component.html',
  styleUrls: ['./amend-discount-transaction-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS, AmendTransactionDateStore],
})
export class AmendDiscountTransactionDialogComponent {
  form: AmendDiscountTransactionFormGroup;
  timeInterval = moment.duration(30, 'minutes');
  timeControl = new TypedFormControl<moment.Moment>().withGuard(
    moment.isMoment
  );
  accountAdminPermission = PatientPermissions.AccountInvoiceAdmin;
  trackByPractitionerRef =
    TrackByFunctions.ref<IPractitionerProportionInvoiceAmount>(
      'practitioner.ref'
    );
  maxAmount$: Observable<number | undefined>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAmendDiscountTransactionDialogData,
    private _dialogRef: MatDialogRef<
      AmendDiscountTransactionDialogComponent,
      IAmendDiscountTransactionDialogResult
    >,
    public amendDateStore: AmendTransactionDateStore
  ) {
    this.form = new AmendDiscountTransactionFormGroup(data);
    this.amendDateStore.loadAmendDates(data.transaction);

    this.maxAmount$ = formControlChanges$(
      this.form.extendedData.controls.practitionerRef
    ).pipe(
      map((selectedPractitionerRef) => {
        if (!selectedPractitionerRef) {
          return this.data.transaction.amount;
        }

        const existingPractitionerDiscountAmount = new TransactionOperators(
          this.data.activeDiscounts
            .filter(
              (discount) =>
                !discount.extendedData?.practitionerRef ||
                isSameRef(
                  discount.extendedData.practitionerRef,
                  selectedPractitionerRef
                )
            )
            .filter((discount) => !isSameRef(discount, this.data.transaction))
        ).paidToDate();

        const practitionerProportion = this.data.invoicePractitioners.find(
          (practitioner) =>
            isSameRef(practitioner.practitioner, selectedPractitionerRef)
        );

        if (!practitionerProportion) {
          return this.data.transaction.amount;
        }

        return (
          practitionerProportion.amount - existingPractitionerDiscountAmount
        );
      }),
      tap((maxValue) => {
        const currentValue = this.form.controls.amount.value ?? undefined;
        if (currentValue && currentValue > maxValue) {
          this.form.controls.amount.setValue(maxValue);
        }
        this.form.controls.amount.setValidators(
          compact([
            Validators.required,
            Validators.max(maxValue),
            Validators.min(0),
          ])
        );
        this.form.updateValueAndValidity();
      }),
      startWith(this.data.transaction.amount),
      shareReplayCold()
    );
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    const data = this.form.value;
    const dateReceived = data.dateReceived
      ? await determineNewCreatedAtDate(
          this.data.transaction,
          mergeDayAndTime(data.dateReceived, moment()),
          await snapshot(this.amendDateStore.minDate$),
          await snapshot(this.amendDateStore.maxDate$)
        )
      : undefined;

    this._dialogRef.close({
      ...this.form.value,
      dateReceived,
    });
  }

  comparePractitionerRefFn(
    value: DocumentReference<IStaffer>,
    practitioner: DocumentReference<IStaffer>
  ): boolean {
    if (!value || !practitioner) {
      return false;
    }
    return isSameRef(value, practitioner);
  }
}
