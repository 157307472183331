import { type DocumentReference } from '@principle-theorem/shared';
import { type IPractice } from '../practice/practice';

export interface ISendEmailRequest {
  to: string;
  subject: string;
  text: string;
  html: string;
  senderRef: DocumentReference<IPractice>;
}
