import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  Signal,
  computed,
  signal,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import {
  Automation,
  AutomationConfiguration,
  TimezoneResolver,
} from '@principle-theorem/principle-core';
import {
  IAutomation,
  IAutomationConfiguration,
  IAutomationResource,
  TreatmentStepAutomation,
  isAutomatedNotificationConfiguration,
  isEventable,
  isGeneratedTaskConfiguration,
  isNotificationAutomation,
  isTaskAutomation,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  Timestamp,
  WithRef,
  bufferedQuery$,
  multiConcatMap,
  toTimestamp,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface IUpdateExistingAutomationsRequest {
  config: WithRef<IAutomationConfiguration>;
}

@Component({
    selector: 'pr-update-existing-automations-dialog',
    templateUrl: './update-existing-automations-dialog.component.html',
    styleUrls: ['./update-existing-automations-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, NgMaterialModule, NgSharedModule]
})
export class UpdateExistingAutomationsDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();

  loading = signal(true);
  queryTotal = signal(0);

  updating = signal(false);
  current = signal(0);
  progress: Signal<number>;

  complete = signal(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUpdateExistingAutomationsRequest,
    private _dialogRef: MatDialogRef<UpdateExistingAutomationsDialogComponent>
  ) {
    this.progress = computed(() => {
      const queryTotal = this.queryTotal();
      if (queryTotal === undefined) {
        return 0;
      }
      return (this.current() / queryTotal) * 100;
    });

    void this.loadQueryTotal(this.data.config.ref);
  }

  async loadQueryTotal(
    configRef: DocumentReference<IAutomationConfiguration>
  ): Promise<void> {
    this.loading.set(true);
    const count = await AutomationConfiguration.getAutomationCount(configRef);
    this.queryTotal.set(count);
    this.loading.set(false);
    if (count <= 0) {
      this._dialogRef.close();
    }
  }

  async updateAutomations(): Promise<void> {
    this.updating.set(true);
    await bufferedQuery$(
      AutomationConfiguration.automationsQuery(this.data.config.ref),
      10,
      'ref'
    )
      .pipe(
        multiConcatMap(async (automation) => {
          await this._updateAutomation(automation);
          this.current.set(this.current() + 1);
        }),
        takeUntil(this._onDestroy$)
      )
      .toPromise();
    this.updating.set(false);
    this.complete.set(true);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private async _updateAutomation(
    automation: WithRef<IAutomation<TreatmentStepAutomation>>
  ): Promise<void> {
    if (Automation.hasAlreadyRun(automation)) {
      return;
    }

    if (
      isTaskAutomation(automation) &&
      isGeneratedTaskConfiguration(this.data.config)
    ) {
      return this._saveAutomationChanges(automation, {
        timing: this.data.config.timing,
        requiredConditions: this.data.config.requiredConditions,
        title: this.data.config.title,
        assignee: this.data.config.assignee,
        comment: this.data.config.comment,
        isForTeam: this.data.config.isForTeam,
        priority: this.data.config.priority,
      });
    }

    if (
      isNotificationAutomation(automation) &&
      isAutomatedNotificationConfiguration(this.data.config)
    ) {
      return this._saveAutomationChanges(automation, {
        timing: this.data.config.timing,
        requiredConditions: this.data.config.requiredConditions,
        name: this.data.config.name,
        subject: this.data.config.subject,
        type: this.data.config.type,
      });
    }
  }

  private async _saveAutomationChanges<T extends IAutomationResource>(
    automation: WithRef<IAutomation<T>>,
    data: Partial<T>
  ): Promise<void> {
    const triggerDate = await this._getNewTriggerDate({
      ...automation,
      data: { ...automation.data, ...data },
    });

    const updatedAutomation: WithRef<IAutomation<T>> = {
      ...automation,
      triggerDate,
      data: {
        ...automation.data,
        ...data,
      },
    };
    await Firestore.saveDoc(updatedAutomation);
  }

  private async _getNewTriggerDate<T extends IAutomationResource>(
    automation: WithRef<IAutomation<T>>
  ): Promise<Timestamp | undefined> {
    if (!automation.triggerDate) {
      return;
    }

    const appointment = await Automation.resolveAppointment(automation);
    if (!appointment || !isEventable(appointment)) {
      return;
    }

    const timezone = await TimezoneResolver.fromEvent(appointment.event);
    const expectedTriggerDate = Automation.getExpectedTriggerDate(
      automation,
      appointment.event,
      timezone
    );

    const now = moment.tz(timezone);
    const triggerDate = Automation.applyGracePeriod(
      expectedTriggerDate,
      timezone,
      now
    );

    return toTimestamp(triggerDate);
  }
}
