import {
  ISourceEntity,
  SourceEntityMigrationType,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISODateTimeType,
  ISODateType,
  ISOTimeType,
  Timestamp,
  Timezone,
  TypeGuard,
  isISODateTimeType,
  isISODateType,
  isISOTimeType,
  toFloat,
  toISODate,
  toTimestamp,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertDateToTimestampFn,
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  convertValueFn,
} from '../../../source/source-helpers';

export const PATIENT_PAYMENT_RESOURCE_TYPE = 'patientPayments';

export const PATIENT_PAYMENT_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient Payment List',
    description: '',
    idPrefix: PATIENT_PAYMENT_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export enum OasisTransactionType {
  Transaction = 'transaction',
  Payment = 'payment',
  Adjustment = 'adjustment',
  WriteOff = 'writeOff',
  Discount = 'discount',
}

export interface IOasisPatientPayment {
  id: number;
  transactionGroupId?: string;
  appointmentDate: ISODateType;
  createdAt: ISODateType;
  paymentDescription?: string;
  invoiceNote?: string;

  accountPatientId?: number;
  patientId?: number; // This is set for all treatments. It's only missing from IOasisTransactionType.Treatment records when the description is "Plus GST" and "Loyalty Bonus".
  patientName?: string;

  isThirdPartyPayment: boolean; // Correlated to the accountThirdPartyId field being set
  accountThirdPartyId?: number;
  thirdPartyId?: number; // Is always a third party id when it's set. accountPatientId isn't always set in these cases.

  practitionerId: number;
  billedToPractitionerId: number;
  billingPractitionerCode?: string; // There is always a thirdPartyId set, but no accountThirdPartyId. There is also an accountPatientId and patientId always set, as well as a description of Hicaps Payment [transactionId]. Other fields that are always set include, bankSlipNumber, bankedAt, claimReference, claimNumber. Even though it all points to a claim, the wasClaimedFor boolean is false.

  wasClaimedFor: boolean;
  claimAmount?: number;
  claimReference?: string; // The transaction output from the terminal.
  claimNumber?: string;

  manualPaymentTransactionReference?: number; // Only set when IOasisTransactionType.Payment. Commonly for cheques and direct debits.
  bankSlipNumber?: number;
  statementNumber?: number;
  showInBanking: boolean; // This might be about whether it's shown on reconciliation?

  isDeleted: boolean;
  deletedAt?: ISODateTimeType;

  isCancelled: boolean;
  bankedAt?: ISODateType;

  locationId?: number;
  userId: number;
  userTime?: ISOTimeType;

  description2?: string;
  drawerName?: string; // Name of patient/third party
  bank?: string; // Correlates to the thirdPartyId record

  amount?: number; // This is the sum of the different payment types below.
  cheques?: number; // showInBanking is always true and transactionType is always IOasisTransactionType.Payment.
  cash?: number; // showInBanking is always true and transactionType is always IOasisTransactionType.Payment.
  creditCard?: number; // showInBanking is always true and transactionType is always IOasisTransactionType.Payment.
  eftpos?: number; // showInBanking is always true and transactionType is always IOasisTransactionType.Payment.
  directDebit?: number; // showInBanking is always true and transactionType is always IOasisTransactionType.Payment.
  otherAmount?: number; // Not used but it would most likely behave like the others.
  thirdPartyAmount?: number; // showInBanking is always false with transactionType IOasisTransactionType.Treatment, and showInBanking is always true with transactionType IOasisTransactionType.Payment.

  gstNumber?: number; // Not clear what this is used for.
  showInGst: boolean; // Whether to show in GST report. Seems to be linked to having a gstNumber.
}

export function isOasisPatientPayment(
  item: unknown
): item is IOasisPatientPayment {
  return TypeGuard.interface<IOasisPatientPayment>({
    id: isNumber,
    transactionGroupId: TypeGuard.nilOr(isString),
    appointmentDate: isISODateType,
    createdAt: isISODateType,
    paymentDescription: TypeGuard.nilOr(isString),
    invoiceNote: TypeGuard.nilOr(isString),
    accountPatientId: TypeGuard.nilOr(isNumber),
    patientId: TypeGuard.nilOr(isNumber),
    patientName: TypeGuard.nilOr(isString),
    isThirdPartyPayment: isBoolean,
    accountThirdPartyId: TypeGuard.nilOr(isNumber),
    thirdPartyId: TypeGuard.nilOr(isNumber),
    practitionerId: isNumber,
    billedToPractitionerId: isNumber,
    billingPractitionerCode: TypeGuard.nilOr(isString),
    wasClaimedFor: isBoolean,
    claimAmount: TypeGuard.nilOr(isNumber),
    claimReference: TypeGuard.nilOr(isString),
    claimNumber: TypeGuard.nilOr(isString),
    manualPaymentTransactionReference: TypeGuard.nilOr(isNumber),
    bankSlipNumber: TypeGuard.nilOr(isNumber),
    statementNumber: TypeGuard.nilOr(isNumber),
    showInBanking: isBoolean,
    isDeleted: isBoolean,
    deletedAt: TypeGuard.nilOr(isISODateTimeType),
    isCancelled: isBoolean,
    bankedAt: TypeGuard.nilOr(isISODateType),
    locationId: TypeGuard.nilOr(isNumber),
    userId: isNumber,
    userTime: TypeGuard.nilOr(isISOTimeType),
    description2: TypeGuard.nilOr(isString),
    drawerName: TypeGuard.nilOr(isString),
    bank: TypeGuard.nilOr(isString),
    amount: TypeGuard.nilOr(isNumber),
    cheques: TypeGuard.nilOr(isNumber),
    cash: TypeGuard.nilOr(isNumber),
    creditCard: TypeGuard.nilOr(isNumber),
    eftpos: TypeGuard.nilOr(isNumber),
    directDebit: TypeGuard.nilOr(isNumber),
    otherAmount: TypeGuard.nilOr(isNumber),
    thirdPartyAmount: TypeGuard.nilOr(isNumber),
    gstNumber: TypeGuard.nilOr(isNumber),
    showInGst: isBoolean,
  })(item);
}

export interface IOasisPatientPaymentTranslations {
  createdAt: Timestamp;
  appointmentDate: ISODateType;
  deletedAt?: Timestamp;
}

export interface IOasisPatientPaymentFilters {
  practitionerId: number;
  billedToPractitionerId: number;
  accountPatientId?: number;
  accountThirdPartyId?: number;
  patientId?: number;
}

const PATIENT_PAYMENT_SOURCE_QUERY = `
SELECT
  SEQNUMBER AS id,
  NULLIF(RUNNUMBER::TEXT, 'NULL') AS transaction_group_id,

  CASE
    WHEN convert_to_integer(ACCNUMBER) < 99000
      THEN convert_to_integer(ACCNUMBER)
    ELSE NULL
  END AS account_patient_id,
  convert_to_integer(PATNUMBER) AS patient_id,

  CASE
    WHEN convert_to_integer(ACCNUMBER) >= 99000
      THEN convert_to_integer(ACCNUMBER)
    ELSE NULL
  END AS account_third_party_id,
  NULLIF(convert_to_integer(THIRDPARTYNUMBER), 0) AS third_party_id,
  PATIENTNAME AS patient_name,

  CASE
    WHEN strpos (CONSULTDATE, '/') > 0
      THEN to_char(convert_to_date(CONSULTDATE, 'DD/MM/YYYY'), 'YYYY-MM-DD')
    ELSE CONSULTDATE
  END AS appointment_date,

  CASE
    WHEN strpos(ENTRYDATE, '/') > 0
      THEN to_char(convert_to_date(ENTRYDATE, 'DD/MM/YYYY'), 'YYYY-MM-DD')
    ELSE ENTRYDATE
  END AS created_at,

  NULLIF(DESCRIPTION1, '') AS payment_description,
  NULLIF(COMMENT, '') AS invoice_note,

  CASE WHEN TRANSTYPE = 3
    THEN convert_to_integer(NULLIF(NULLIF(REFERENCE::TEXT, ''), '0'))
    ELSE NULL
  END AS manual_payment_transaction_reference,

  NULLIF(BANKSLIPNUMBER, 99999999) AS bank_slip_number,
  NULLIF(STATEMENTNUMBER, 0) AS statement_number,
  NULLIF(DESCRIPTION2, '') AS description_2,
  NULLIF(DRAWERNAME, '') AS drawer_name,
  NULLIF(BANK, '') AS bank,

  CONSULTDRNUMBER AS practitioner_id,
  BILLDRNUMBER AS billed_to_practitioner_id,
  CASE WHEN TRANSTYPE = 3
    THEN NULLIF(DESCRIPTION4, '')
    ELSE NULL
  END AS billing_practitioner_code,

  CASE WHEN DELETED = -1
    THEN TRUE
    ELSE FALSE
  END AS is_deleted,
  CASE
    WHEN strpos(DELETEDATE, '-') > 0
      THEN to_char(convert_to_date(DELETEDATE, 'YYYY-MM-DD')
      +
      CASE
		    WHEN DELETETIME ~ '^\\d{1,2}:\\d{2}:\\d{2} (AM|PM)$'
		      THEN NULLIF(TRIM(DELETETIME), '')::TIME
		    ELSE
		      NULL
		  END,
      'YYYY-MM-DD HH:MI:SS')
    ELSE to_char(
      convert_to_date(DELETEDATE, 'DD/MM/YYYY')
      +
      CASE
		    WHEN DELETETIME ~ '^\\d{1,2}:\\d{2}:\\d{2} (AM|PM)$'
		      THEN NULLIF(TRIM(DELETETIME), '')::TIME
		    ELSE
		      NULL
		  END,
      'YYYY-MM-DD HH:MI:SS'
    )
  END AS deleted_at,

  to_char(convert_to_date(BANKEDDATE, 'DD/MM/YYYY'), 'YYYY-MM-DD') AS banked_at,

  CASE WHEN CANCELLED = -1
    THEN TRUE
    ELSE FALSE
  END AS is_cancelled,
  CASE WHEN SHOWINBANKING = -1
    THEN TRUE
    ELSE FALSE
  END AS show_in_banking,

  USERNUMBER AS user_id,
  NULLIF(NULLIF(USERTIME, ''), 'NULL')::TIME AS user_time,
  convert_to_integer(LOCNO) AS location_id,

  convert_to_decimal(AMOUNT) AS amount,
  convert_to_decimal(CHEQUES) AS cheques,
  convert_to_decimal(CASH) AS cash,
  convert_to_decimal(CREDITCARD) AS credit_card,
  convert_to_decimal(EFTPOS) AS eftpos,
  convert_to_decimal(DIRECTDEBIT) AS direct_debit,
  convert_to_decimal(OTHERAMOUNT) AS other_amount,
  convert_to_decimal(THIRDPARTYAMOUNT) AS third_party_amount,
  CASE WHEN THIRDPARTYPAYMENT = 0
    THEN FALSE
    ELSE TRUE
  END AS is_third_party_payment,

  convert_to_boolean(SHOWINGST) AS show_in_gst,

  convert_to_decimal(HICAPS) AS claim_amount,
  CASE
    WHEN
      NULLIF(HICAPSFLAG::TEXT, 'NULL') IS NULL
      OR convert_to_integer(HICAPSFLAG) = 0
    THEN FALSE
    ELSE TRUE
  END AS was_claimed_for,
  HICAPSREFERENCE AS claim_reference,
  NULLIF(HICAPSCLAIMNUMBER::TEXT, 'NULL') AS claim_number
FROM PBARCMAS
WHERE
  convert_to_integer(TRANSTYPE) = 3
`;

export class PatientPaymentSourceEntity extends BaseSourceEntity<
  IOasisPatientPayment,
  IOasisPatientPaymentTranslations,
  IOasisPatientPaymentFilters
> {
  sourceEntity = PATIENT_PAYMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_PAYMENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_PAYMENT_SOURCE_QUERY;
  verifySourceFn = isOasisPatientPayment;
  override defaultOffsetSize = 25000;
  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertDateToTimestampFn(),
    convertValueFn(
      toFloat,
      'amount',
      'cheques',
      'cash',
      'creditCard',
      'eftpos',
      'directDebit',
      'otherAmount',
      'thirdPartyAmount',
      'claimAmount'
    ),
  ]);

  translate(
    data: IOasisPatientPayment,
    timezone: Timezone
  ): IOasisPatientPaymentTranslations {
    return {
      createdAt: toTimestamp(moment.tz(data.createdAt, timezone)),
      appointmentDate: toISODate(moment.tz(data.appointmentDate, timezone)),
      deletedAt: data.deletedAt
        ? toTimestamp(moment.tz(data.deletedAt, timezone))
        : undefined,
    };
  }

  getSourceRecordId(data: IOasisPatientPayment): number {
    return data.id;
  }

  getSourceLabel(data: IOasisPatientPayment): string {
    return data.id.toString();
  }

  getFilterData(
    data: IOasisPatientPayment,
    _timezone: Timezone
  ): IOasisPatientPaymentFilters {
    return {
      practitionerId: data.practitionerId,
      billedToPractitionerId: data.billedToPractitionerId,
      accountPatientId: data.accountPatientId,
      patientId: data.patientId,
      accountThirdPartyId: data.accountThirdPartyId,
    };
  }
}
