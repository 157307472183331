@if (displayStep()) {
  <div class="status step-container" [ngClass]="status$ | async">
    <pr-treatment-step-header
      [step]="step$ | async"
      [plan]="plan$ | async"
      [status]="status$ | async"
      [appointment]="appointment$ | async"
      [inlcudeMultiTreatments]="inlcudeMultiTreatments"
      [hideDelete]="hideDelete"
      (stepDeleted)="stepDeleted.emit($event)"
      (stepDisplayChange)="stepChanged.emit({ display: $event })"
    >
      <button
        mat-icon-button
        matTooltip="{{
          expanded ? 'Minimise All Treatments' : 'Expand All Treatments'
        }}"
        (click)="expanded = !expanded"
      >
        <mat-icon>{{ expanded ? 'unfold_less' : 'unfold_more' }}</mat-icon>
      </button>
    </pr-treatment-step-header>

    <pr-treatment-scope-list
      [expanded]="expanded"
      [disabled]="isComplete$ | async"
      [treatments]="treatments$ | async"
      [step]="step$ | async"
      [plan]="plan$ | async"
      (treatmentsChange)="updateTreatments($event)"
      (treatmentDeleted)="deleteTreatment($event)"
      (updateChartable)="updateChartable.emit($event)"
      (categoryChanged)="updateStepDisplayOverride($event)"
    />
  </div>
}
