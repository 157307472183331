import { ChangeDetectionStrategy, Component } from '@angular/core';
import { type IUserSession } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { SwitchUserService } from './switch-user.service';

@Component({
  selector: 'pr-switch-user-list',
  templateUrl: './switch-user-list.component.html',
  styleUrls: ['./switch-user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchUserListComponent {
  trackBySession = TrackByFunctions.field<IUserSession>('email');

  constructor(public switchUser: SwitchUserService) {}
}
