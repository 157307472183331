import { ReplaySubject, type Observable, type Subject } from 'rxjs';
import { distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { type SearchClient as TypesenseSearchClient } from 'typesense';
import {
  type SearchOptions,
  type SearchParams,
  type SearchResponse,
} from 'typesense/lib/Typesense/Documents';
import {
  type MultiSearchRequestSchema,
  type MultiSearchRequestsSchema,
  type MultiSearchResponse,
} from 'typesense/lib/Typesense/MultiSearch';

export class TypesenseSearchBloc<T extends object> {
  private _defaultSearchOptions: SearchOptions = {
    cacheSearchResultsForSeconds: 120,
  };
  results$ = new ReplaySubject<SearchResponse<T>>(1);

  constructor(
    client: TypesenseSearchClient,
    scopedCollectionName: string,
    search$: Observable<SearchParams>,
    private _onDestroy$: Subject<void>,
    searchOptionOverrides: SearchOptions = {}
  ) {
    this._defaultSearchOptions = {
      ...this._defaultSearchOptions,
      ...searchOptionOverrides,
    };

    search$
      .pipe(
        distinctUntilChanged(),
        switchMap((searchParams) =>
          client
            .collections<T>(scopedCollectionName)
            .documents()
            .search(searchParams, this._defaultSearchOptions)
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((results) => {
        this.results$.next(results as SearchResponse<T>);
      });
  }
}

export class TypesenseMultiSearchBloc<T extends object> {
  private _defaultSearchOptions: SearchOptions = {
    cacheSearchResultsForSeconds: 120,
  };
  results$ = new ReplaySubject<MultiSearchResponse<T[]>>(1);

  constructor(
    client: TypesenseSearchClient,
    requests$: Observable<MultiSearchRequestsSchema>,
    commonSearchParams: Partial<MultiSearchRequestSchema>,
    private _onDestroy$: Subject<void>
  ) {
    requests$
      .pipe(
        distinctUntilChanged(),
        switchMap((searches) =>
          client.multiSearch.perform(
            searches,
            commonSearchParams,
            this._defaultSearchOptions
          )
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((results) => {
        this.results$.next(results as MultiSearchResponse<T[]>);
      });
  }
}
