import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  inject,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { toMentionContent, toTextContent } from '@principle-theorem/editor';
import {
  OrganisationService,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import {
  CustomFormConfiguration,
  CustomFormContent,
  Interaction,
  PatientForm,
  getCustomFormData,
  getCustomSubmittedForm,
  toMention,
} from '@principle-theorem/principle-core';
import {
  ICustomFormConfiguration,
  IPatientForm,
  IPatientFormSchema,
  IStaffer,
  InteractionType,
  MentionResourceType,
  type ICustomFormData,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  filterUndefined,
  findProp,
  snapshot,
  snapshotDefined,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { isEmpty } from 'lodash';
import {
  ReplaySubject,
  Subject,
  combineLatest,
  of,
  type Observable,
} from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-patient-form-update',
  templateUrl: './patient-form-update.component.html',
  styleUrls: ['./patient-form-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex flex-col gap-4 max-w-6xl p-4 mx-auto' },
  standalone: false,
})
export class PatientFormUpdateComponent implements OnDestroy {
  private _route = inject(ActivatedRoute);
  private _onDestroy$ = new Subject<void>();
  private _patientForm$: Observable<WithRef<IPatientForm> | undefined>;
  customFormConfig$: Observable<WithRef<ICustomFormConfiguration>>;
  formData$ = new ReplaySubject<object | undefined>(1);
  formIsEmpty$: Observable<boolean>;
  patient$: Observable<WithRef<IPatient>>;
  submitting$ = new Subject<boolean>();
  form$: Observable<ICustomFormData>;

  constructor(
    private _stateNav: StateBasedNavigationService,
    private _organisation: OrganisationService
  ) {
    this._patientForm$ = this._route.data.pipe(
      findProp<WithRef<IPatientForm>>('patientForm')
    );

    this.formIsEmpty$ = this.formData$.pipe(map((data) => isEmpty(data)));

    this.patient$ = this._patientForm$.pipe(
      switchMap((form) =>
        form ? Firestore.getDoc(PatientForm.patientRef(form)) : of(undefined)
      ),
      filterUndefined()
    );
    this.customFormConfig$ = this._patientForm$.pipe(
      filterUndefined(),
      switchMap((patientForm) => PatientForm.getCustomFormConfig(patientForm)),
      filterUndefined()
    );
    const customFormContent$ = this.customFormConfig$.pipe(
      switchMap((config) => CustomFormConfiguration.getContent$(config)),
      filterUndefined()
    );

    this._patientForm$
      .pipe(
        switchMap((patientForm) =>
          patientForm
            ? PatientForm.getCustomFormData(patientForm, true)
            : of(undefined)
        ),
        map((form) => form?.data),
        takeUntil(this._onDestroy$)
      )
      .subscribe((formData) => this.formData$.next(formData));

    this.form$ = combineLatest([customFormContent$, this.formData$]).pipe(
      map(([customFormContent, data]) =>
        getCustomFormData(
          CustomFormContent.getJsonSchemaForm(customFormContent),
          data
        )
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async submit(data: object): Promise<void> {
    this.submitting$.next(true);
    const patient = await snapshot(this.patient$);
    const staffer = await snapshotDefined(this._organisation.staffer$);
    const customFormConfig = await snapshot(this.customFormConfig$);
    const customForm = await snapshot(this.form$);

    const existingForm = await snapshot(this._patientForm$);
    const formRef = await this._submitForm(
      patient,
      staffer,
      getCustomSubmittedForm(customForm, data),
      customFormConfig,
      existingForm
    );

    await this._stateNav.brand([
      'patients',
      patient.ref.id,
      'forms',
      formRef.id,
    ]);
    this.submitting$.next(false);
  }

  clearForm(): void {
    this.formData$.next(undefined);
  }

  private async _submitForm(
    patient: WithRef<IPatient>,
    staffer: WithRef<IStaffer>,
    patientFormSchema: IPatientFormSchema,
    customFormConfig: WithRef<ICustomFormConfiguration>,
    existingForm: WithRef<IPatientForm> | undefined
  ): Promise<DocumentReference<IPatientForm>> {
    const baseForm = existingForm ?? PatientForm.init({});
    const patientForm = PatientForm.markConfirmed({
      ...baseForm,
      form: patientFormSchema,
      template: toNamedDocument(customFormConfig),
    });

    const interaction = Interaction.init({
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` updated and confirmed the form`),
      ],
      type: InteractionType.FormConfirm,
    });

    return PatientForm.upsertForm(
      patient,
      interaction,
      patientForm,
      existingForm
    );
  }
}
