import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  GlobalStoreService,
  TagType,
} from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { Brand } from '@principle-theorem/principle-core';
import {
  type IBrand,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import { addDoc, snapshot, type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-brand-appointment-tags',
  templateUrl: './brand-appointment-tags.component.html',
  styleUrls: ['./brand-appointment-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandAppointmentTagsComponent {
  brand$ = new ReplaySubject<WithRef<IBrand>>(1);
  trackByTemplate = TrackByFunctions.ref<WithRef<ITag>>();
  appointmentTags$: Observable<WithRef<ITag>[]>;

  constructor(private _globalStore: GlobalStoreService) {
    this.appointmentTags$ = this._globalStore.getTagsByType$(
      TagType.Appointment
    );
  }

  @Input()
  set brand(brand: WithRef<IBrand>) {
    if (brand) {
      this.brand$.next(brand);
    }
  }

  async addTag(event: ITag): Promise<void> {
    const brand = await snapshot(this.brand$);
    await addDoc(Brand.appointmentTagCol(brand), event);
  }
}
