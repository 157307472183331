import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
  TrackByFunctions,
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { ServiceProviderHandler } from '@principle-theorem/principle-core';
import {
  type IPricedServiceCodeEntry,
  type IScopedServiceCode,
  type IServiceCode,
  type ITreatmentPackagePriceOverride,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot } from '@principle-theorem/shared';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-edit-treatment-package-service-items',
  templateUrl: './edit-treatment-package-service-items.component.html',
  styleUrls: ['./edit-treatment-package-service-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditTreatmentPackageServiceItemsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByIndex = TrackByFunctions.index();
  serviceCodes$ = new ReplaySubject<IPricedServiceCodeEntry[]>(1);
  priceOverrides$ = new ReplaySubject<ITreatmentPackagePriceOverride[]>(1);
  @Output() updateServiceItems = new EventEmitter<
    ITreatmentPackagePriceOverride[]
  >();
  serviceCodesCtrl = new TypedFormArray<ITreatmentPackagePriceOverride>([]);

  @Input()
  set serviceCodes(serviceCodes: IPricedServiceCodeEntry[]) {
    if (serviceCodes) {
      this.serviceCodes$.next(serviceCodes);
    }
  }

  @Input()
  set priceOverrides(priceOverrides: IPricedServiceCodeEntry[]) {
    if (priceOverrides) {
      this.priceOverrides$.next(priceOverrides);
    }
  }

  constructor() {
    combineLatest([this.serviceCodes$, this.priceOverrides$])
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe(([serviceCodes, priceOverrides]) => {
        serviceCodes.map((serviceCode) => {
          const priceOverride = priceOverrides.find(
            (price) =>
              price.code === serviceCode.code && price.type === serviceCode.type
          );

          this.serviceCodesCtrl.push(
            new TypedFormGroup<ITreatmentPackagePriceOverride>({
              code: new TypedFormControl(serviceCode.code),
              type: new TypedFormControl(serviceCode.type),
              price: new TypedFormControl(
                priceOverride?.price ?? undefined,
                Validators.required
              ),
            })
          );
        });
      });

    this.serviceCodesCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => {
        this.updateServiceItems.emit(value);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async resolveQuantity(scopedCode: IScopedServiceCode): Promise<number> {
    const foundCode = await snapshot(
      this.serviceCodes$.pipe(
        map((serviceCodes) =>
          serviceCodes.find(
            (serviceCode) =>
              serviceCode.code === scopedCode.code &&
              serviceCode.type === scopedCode.type
          )
        )
      )
    );
    return foundCode?.quantity ?? 0;
  }

  resolveServiceCode(scopedCode: IScopedServiceCode): IServiceCode | undefined {
    return ServiceProviderHandler.resolveServiceCode(
      scopedCode.type,
      scopedCode.code
    );
  }
}
