import {
  TransformEvent,
  isObject,
  type ITransformer,
} from '@principle-theorem/shared';
import {
  serialiseRawSchemaContent,
  serialiseSchemaContent,
  unserialiseRawSchemaContent,
  unserialiseVersionedSchemaContent,
} from './extensions/raw-schema-helpers';
import {
  isVersionedSchema,
  isSerialisedVersionedSchema,
  isRawSchema,
  isSerialisedRawSchema,
} from './schema';

export class VersionedSchemaSerialiser implements ITransformer {
  events = [TransformEvent.ToFirestore, TransformEvent.ToRedux];

  guard(_: unknown): _ is unknown {
    return true;
  }

  transform<T>(item: T): unknown {
    if (!isObject(item)) {
      return item;
    }

    if (Array.isArray(item)) {
      return this._serialiseChildren(item);
    }

    if (isVersionedSchema(item)) {
      return serialiseSchemaContent(item);
    }

    if (isRawSchema(item)) {
      return serialiseRawSchemaContent(item);
    }

    Object.entries(item).map(([key, value]) => {
      if (Array.isArray(value)) {
        item = {
          ...item,
          [key]: this._serialiseChildren(value),
        };
        return;
      }

      if (isVersionedSchema(value)) {
        item = {
          ...item,
          [key]: serialiseSchemaContent(value),
        };
      }

      if (isRawSchema(value)) {
        item = {
          ...item,
          [key]: serialiseRawSchemaContent(value),
        };
      }
    });
    return item;
  }

  private _serialiseChildren<T>(value: T[]): unknown[] {
    return value.map((child) => this.transform(child));
  }
}

export class VersionedSchemaUnserialiser implements ITransformer {
  events = [TransformEvent.FromFirestore, TransformEvent.FromRedux];

  guard(_: unknown): _ is unknown {
    return true;
  }

  transform<T>(item: T): unknown {
    if (!isObject(item)) {
      return item;
    }

    if (Array.isArray(item)) {
      return this._unserialiseChildren(item);
    }

    if (isSerialisedVersionedSchema(item)) {
      return unserialiseVersionedSchemaContent(item);
    }

    if (isSerialisedRawSchema(item)) {
      return unserialiseRawSchemaContent(item);
    }

    Object.entries(item).map(([key, value]) => {
      if (Array.isArray(value)) {
        item = {
          ...item,
          [key]: this._unserialiseChildren(value),
        };
        return;
      }

      if (isSerialisedVersionedSchema(value)) {
        item = {
          ...item,
          [key]: unserialiseVersionedSchemaContent(value),
        };
      }

      if (isSerialisedRawSchema(value)) {
        item = {
          ...item,
          [key]: unserialiseRawSchemaContent(value),
        };
      }
    });
    return item;
  }

  private _unserialiseChildren(value: unknown[]): unknown {
    return value.map((child) => this.transform(child));
  }
}
