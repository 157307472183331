import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TreatmentPlan } from '@principle-theorem/principle-core';
import {
  type ITreatmentPlan,
  type ITreatmentPlanWithPatient,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';

export interface ITreatmentPlanListDialogData {
  planPairs: ITreatmentPlanWithPatient[];
  todaysPlan?: DocumentReference<ITreatmentPlan>;
  selectedPlan?: DocumentReference<ITreatmentPlan>;
}

@Component({
    selector: 'pr-treatment-plan-list-dialog',
    templateUrl: './treatment-plan-list-dialog.component.html',
    styleUrls: ['./treatment-plan-list-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreatmentPlanListDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ITreatmentPlanListDialogData,
    private _dialogRef: MatDialogRef<
      TreatmentPlanListDialogComponent,
      WithRef<ITreatmentPlan> | ITreatmentPlan
    >
  ) {}

  selectPlan(plan: WithRef<ITreatmentPlan>): void {
    this._dialogRef.close(plan);
  }

  createPlan(): void {
    this._dialogRef.close(TreatmentPlan.init());
  }
}
