import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  IPatient,
  type IInvoice,
  type ITransaction,
  IStaffer,
  IHealthcareClaim,
} from '@principle-theorem/principle-core/interfaces';
import {
  firstValueFrom,
  multiFilter,
  multiMap,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HicapsConnectHealthFundClaimEstimateProvider } from '../hicaps-connect/health-fund/hicaps-connect-health-fund-claim-estimate-provider.service';
import { TransactionProviderError } from '../transaction-provider';
import { TyroHealthPointClaimEstimateProvider } from '../tyro/health-point/tyro-health-point-claim-estimate-provider.service';
import { IClaimEstimateProvider } from './claim-estimate-provider';
import { SelectClaimProviderComponent } from './select-claim-provider/select-claim-provider.component';
import {
  HealthcareClaimGenerator,
  resolveLineItem,
} from '@principle-theorem/principle-core';
import { BuildClaimDialogService } from '../../healthcare-claims-list/build-claim-dialog/build-claim-dialog.service';

@Injectable({ providedIn: 'root' })
export class ClaimEstimateProviders {
  private _providers: IClaimEstimateProvider[];
  isEnabled$: Observable<boolean>;

  constructor(
    tyro: TyroHealthPointClaimEstimateProvider,
    hicapsConnect: HicapsConnectHealthFundClaimEstimateProvider,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _buildClaimDialog: BuildClaimDialogService
  ) {
    this._providers = [tyro, hicapsConnect];
    this.isEnabled$ = this.onlyEnabled$().pipe(
      map((providers) => !!providers.length)
    );
  }

  async capture(
    invoice: IInvoice,
    practitioner: WithRef<IStaffer>,
    patient: WithRef<IPatient>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    try {
      const providers = await firstValueFrom(this.onlyEnabled$());
      if (!providers?.length) {
        throw new TransactionProviderError(`No enabled providers`);
      }

      const provider = await this._selectProvider(providers);
      if (!provider) {
        return;
      }

      const claim = await this._buildClaim(invoice, practitioner);
      if (!claim) {
        return;
      }

      return await provider.capture(invoice, claim, patient);
    } catch (e) {
      if (e instanceof TransactionProviderError) {
        this._snackBar.open(e.message);
      }
      throw e;
    }
  }

  onlyEnabled$(): Observable<IClaimEstimateProvider[]> {
    const checkItems = this._providers.map((provider) =>
      provider.isEnabled$.pipe(map((isEnabled) => ({ isEnabled, provider })))
    );
    return combineLatest(checkItems).pipe(
      multiFilter((item) => item.isEnabled),
      multiMap((item) => item.provider)
    );
  }

  private async _selectProvider(
    providers: IClaimEstimateProvider[]
  ): Promise<IClaimEstimateProvider | undefined> {
    if (providers.length === 1) {
      return first(providers);
    }

    return this._dialog
      .open<
        SelectClaimProviderComponent,
        IClaimEstimateProvider[],
        IClaimEstimateProvider
      >(SelectClaimProviderComponent, DialogPresets.small({ data: providers }))
      .afterClosed()
      .toPromise();
  }

  private async _buildClaim(
    invoice: IInvoice,
    practitioner: WithRef<IStaffer>
  ): Promise<IHealthcareClaim | undefined> {
    const unclaimedItems = HealthcareClaimGenerator.getAllClaimableItems(
      invoice.items,
      practitioner
    ).map((item) => resolveLineItem(invoice, item));

    return this._buildClaimDialog.open(
      invoice,
      practitioner,
      unclaimedItems,
      'Estimate Claim'
    );
  }
}
