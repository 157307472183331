import { Injectable } from '@angular/core';
import {
  type IContextualAction,
  type IRoutable,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { PatientForm } from '@principle-theorem/principle-core';
import {
  type IPatientForm,
  isPrincipleMention,
} from '@principle-theorem/principle-core/interfaces';
import { type IReffable } from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';

@Injectable({
  providedIn: 'root',
})
export class LoadFormActionService implements IContextualAction {
  icon = 'open_in_new';
  name = 'Open Form';

  constructor(private _stateNav: StateBasedNavigationService) {}

  async do(item: IRoutable | IReffable): Promise<void> {
    const formRef = this._getFormRef(item);
    if (!formRef) {
      return;
    }
    const patientRef = PatientForm.patientRef({ ref: formRef });
    await this._stateNav.brand([
      'patients',
      patientRef.id,
      'forms',
      formRef.id,
    ]);
  }

  private _getFormRef(
    item: IRoutable | IReffable
  ): DocumentReference<IPatientForm> | undefined {
    if (isPrincipleMention(item)) {
      return item.resource.ref as DocumentReference<IPatientForm>;
    }
    return item.ref ? (item.ref as DocumentReference<IPatientForm>) : undefined;
  }
}
