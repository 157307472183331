import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  type IAccountCredit,
  type IAccountCreditExtendedData,
  type IInvoice,
  type ITransaction,
  type IUsedAccountCredit,
} from '@principle-theorem/principle-core/interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  filterUndefined,
  isSameRef,
  type WithRef,
} from '@principle-theorem/shared';
import { AccountCredit } from '@principle-theorem/principle-core';
import { Subject, ReplaySubject } from 'rxjs';
import { flatMap, sum } from 'lodash';
import { map, takeUntil } from 'rxjs/operators';
import { Validators } from '@angular/forms';

export interface IRefundCreditTransactionDialogData {
  invoice: WithRef<IInvoice>;
  credits: WithRef<IAccountCredit>[];
  transaction: WithRef<ITransaction<IAccountCreditExtendedData>>;
  fromCredit?: WithRef<IAccountCredit>;
}

export interface IRefundCreditTransactionDialogResult {
  amount: number;
  usedCredits: IUsedAccountCredit[];
}

interface IRefundCreditTransactionFormData {
  amount: number;
  accountCredits: WithRef<IAccountCredit>[];
}

interface ICreditUsedAccountCreditsPair {
  credit: WithRef<IAccountCredit>;
  usedCredits: IUsedAccountCredit[];
}

@Component({
  selector: 'pr-refund-credit-transaction-dialog',
  templateUrl: './refund-credit-transaction-dialog.component.html',
  styleUrls: ['./refund-credit-transaction-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RefundCreditTransactionDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByAccountCredit = TrackByFunctions.ref<IAccountCredit>();
  creditPairs: ICreditUsedAccountCreditsPair[];
  max$ = new ReplaySubject<number>(1);
  form = new TypedFormGroup<IRefundCreditTransactionFormData>({
    amount: new TypedFormControl<number>(),
    accountCredits: new TypedFormControl<WithRef<IAccountCredit>[]>([]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IRefundCreditTransactionDialogData,
    private _dialogRef: MatDialogRef<
      RefundCreditTransactionDialogComponent,
      IRefundCreditTransactionDialogResult
    >
  ) {
    this.creditPairs = this.data.credits.map((credit) => {
      return {
        credit: credit,
        usedCredits: AccountCredit.usedAccountCreditsFromTransaction(
          credit,
          this.data.transaction
        ),
      };
    });

    const initialAmount = creditPairsToRefundableAmount(
      this.data.fromCredit
        ? this.creditPairs.filter((pair) =>
            isSameRef(pair.credit, this.data.fromCredit)
          )
        : this.creditPairs
    );

    this.form.controls.accountCredits.valueChanges
      .pipe(
        filterUndefined(),
        map((selected) =>
          creditPairsToRefundableAmount(
            this.creditPairs.filter((pair) =>
              selected.some((selectedCredit) =>
                isSameRef(selectedCredit, pair.credit)
              )
            )
          )
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((max) => {
        this.form.controls.amount.setValidators([Validators.max(max)]);
        this.form.controls.amount.patchValue(max);
        this.form.updateValueAndValidity();
        this.max$.next(max);
      });

    this.form.patchValue({
      accountCredits: this.data.fromCredit
        ? [this.data.fromCredit]
        : this.data.credits,
      amount: initialAmount,
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  creditAvailable(credit: WithRef<IAccountCredit>): number {
    return creditPairsToRefundableAmount(
      this.creditPairs.filter((pair) => isSameRef(pair.credit, credit))
    );
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }

    const selectedCredits = this.form.controls.accountCredits.value;

    if (!selectedCredits) {
      return;
    }

    const usedCredits = flatMap(
      this.creditPairs.filter((pair) =>
        selectedCredits.some((credit) => isSameRef(credit, pair.credit))
      ),
      (creditPair) => creditPair.usedCredits
    );

    this._dialogRef.close({
      amount: this.form.controls.amount.value ?? 0,
      usedCredits,
    });
  }

  compareFn(
    accountCreditA: WithRef<IAccountCredit>,
    accountCreditB: WithRef<IAccountCredit>
  ): boolean {
    return accountCreditA && accountCreditB
      ? isSameRef(accountCreditA, accountCreditB)
      : false;
  }
}

export function creditPairsToRefundableAmount(
  creditPairs: ICreditUsedAccountCreditsPair[]
): number {
  return sum(
    flatMap(creditPairs, (pair) =>
      pair.usedCredits.map(
        (usedCredit) => usedCredit.amount - usedCredit.refunded
      )
    )
  );
}
