import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  type OnDestroy,
} from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type ITimelineDisplayOptions,
  type ITimelineDisplayRange,
} from '@principle-theorem/principle-core/interfaces';
import { getHoursInRange, TIME_FORMAT } from '@principle-theorem/shared';
import { type Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { InteractiveTimelineDisplayCalculator } from '../interactive-timeline/interactive-timeline-display-calculator';

@Component({
  selector: 'pr-interactive-timeline-units',
  templateUrl: './interactive-timeline-units.component.html',
  styleUrls: ['./interactive-timeline-units.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InteractiveTimelineUnitsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _options$ = new ReplaySubject<ITimelineDisplayOptions>(1);
  private _displayRange$ = new ReplaySubject<ITimelineDisplayRange>(1);
  trackByHour = TrackByFunctions.variable<string>();
  trackByIncrement = TrackByFunctions.variable<number>();
  hours$: Observable<string[]>;
  hourIncrements$: Observable<number[]>;
  incrementFlexSize$: Observable<number>;
  borderFlexSize$: Observable<number>;
  @HostBinding('class.horizontal') isHorizontal = true;
  @HostBinding('class.vertical') isVertical = false;

  @Input()
  set options(options: ITimelineDisplayOptions) {
    if (options) {
      this._options$.next(options);
    }
  }

  @Input()
  set displayRange(displayRange: ITimelineDisplayRange) {
    if (displayRange) {
      this._displayRange$.next(displayRange);
    }
  }

  constructor() {
    this.hours$ = this._displayRange$.pipe(
      map((range) => getHoursInRange(range.timeRange, TIME_FORMAT))
    );
    this._options$
      .pipe(
        map(InteractiveTimelineDisplayCalculator.isHorizontal),
        distinctUntilChanged(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((isHorizontal) => {
        this.isHorizontal = isHorizontal;
        this.isVertical = !isHorizontal;
      });

    this.hourIncrements$ = this._options$.pipe(
      map((options) =>
        InteractiveTimelineDisplayCalculator.getHourIncrements(options)
      )
    );

    this.incrementFlexSize$ = this._options$.pipe(
      map((options) => (options.hourIncrement / 60) * 100)
    );

    this.borderFlexSize$ = this.incrementFlexSize$.pipe(
      map((incrementFlexSize) => incrementFlexSize / 2)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
