import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  NgSharedModule,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  ITimePeriod,
  getEnumValues,
  toTimestamp,
} from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import {
  IToothHistoryFilters,
  ToothHistoryStore,
  RecordType,
  TreatmentStatus,
} from '../tooth-history.store';
import { ToothHistoryStatusChipComponent } from '../tooth-history-status-chip/tooth-history-status-chip.component';

type IToothHistoryFiltersFormData = Pick<
  IToothHistoryFilters,
  'searchTerm' | 'type' | 'treatmentStatus'
>;

@Component({
  selector: 'pr-tooth-history-list-filters',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    NgSharedModule,
    ToothHistoryStatusChipComponent,
  ],
  templateUrl: './tooth-history-list-filters.component.html',
  styleUrls: ['./tooth-history-list-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToothHistoryListFiltersComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _store = inject(ToothHistoryStore);
  types = getEnumValues(RecordType);
  statuses = getEnumValues(TreatmentStatus);

  form = new TypedFormGroup<IToothHistoryFiltersFormData>({
    searchTerm: new TypedFormControl<string>(''),
    treatmentStatus: new TypedFormControl<TreatmentStatus[]>([]),
    type: new TypedFormControl<RecordType[]>([]),
  });

  constructor() {
    this.form.valueChanges
      .pipe(
        debounceTime(250),
        map((value) => ({ ...value, type: value.type || [] })),
        takeUntil(this._onDestroy$)
      )
      .subscribe((value) => this._store.updateFilters(value));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  updateDates(range: ITimePeriod): void {
    const filters = {
      from: range ? toTimestamp(range.from.startOf('day')) : undefined,
      to: range ? toTimestamp(range.to.endOf('day')) : undefined,
    };
    this._store.updateFilters(filters);
  }
}
