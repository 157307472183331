/* eslint-disable @nx/enforce-module-boundaries */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import {
  IPatientPrimaryContact,
  IPatientRelationships,
} from '@principle-theorem/ng-patient';
import {
  IPatientDetails,
  PatientRelationship,
  isPatientDetails,
} from '@principle-theorem/principle-core';
import {
  PATIENT_RELATIONSHIP_TYPES,
  isPatientWithPrimaryContact,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Region,
  WithRef,
  getDoc,
  snapshot,
} from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppointmentCreateSidebarService } from '../appointment-create-sidebar.service';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';

@Component({
    selector: 'pr-scheduling-patient-tab',
    templateUrl: './scheduling-patient-tab.component.html',
    styleUrls: ['./scheduling-patient-tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'prSchedulingPatientTab',
    standalone: false
})
export class SchedulingPatientTabComponent {
  patient$ = new ReplaySubject<WithRef<IPatient> | undefined>(1);
  relationshipData$: Observable<IPatientRelationships>;
  primaryContactData$: Observable<IPatientPrimaryContact>;
  validPatientDetails$: Observable<boolean>;
  Region = Region;

  @Input() newPatientName: string;
  @Input() tabGroup: MatTabGroup;

  @Input()
  set patient(patient: WithRef<IPatient> | undefined) {
    this.patient$.next(patient ?? undefined);
  }

  constructor(
    private _schedulingFacade: AppointmentSchedulingFacade,
    private _appointmentCreateSidebar: AppointmentCreateSidebarService,
    private _snackBar: MatSnackBar,
    public organisation: OrganisationService
  ) {
    this.relationshipData$ = this.patient$.pipe(
      map((patient) => {
        if (!patient) {
          return {
            relationships: [],
          };
        }
        return {
          relationships: patient.relationships.filter((relationship) =>
            PATIENT_RELATIONSHIP_TYPES.includes(relationship.type)
          ),
        };
      })
    );
    this.primaryContactData$ = this.patient$.pipe(
      map((patient) => ({
        primaryContact: isPatientWithPrimaryContact(patient)
          ? patient.primaryContact
          : undefined,
      }))
    );
    this.validPatientDetails$ = this._schedulingFacade.patientDetails$.pipe(
      map((patientDetails) => isPatientDetails(patientDetails))
    );
  }

  updatePatient(patientDetails: Partial<IPatientDetails>): void {
    this._schedulingFacade.patchPatientDetails(patientDetails, true);
  }

  async patientDetailsSubmitted(
    patientDetails: Partial<IPatientDetails>
  ): Promise<void> {
    if (!isPatientDetails(patientDetails)) {
      return;
    }
    const patient = await snapshot(this.patient$);
    this._schedulingFacade.setPatientDetails(patientDetails);
    this._appointmentCreateSidebar.hasPatientDetailsChanges(
      patientDetails,
      patient
    );
  }

  async primaryContactChange(data: IPatientPrimaryContact): Promise<void> {
    const patient = await snapshot(this.patient$);
    if (!patient) {
      return;
    }
    if (!data.primaryContact && isPatientWithPrimaryContact(patient)) {
      try {
        await PatientRelationship.removePrimaryContact(patient);
      } catch (error) {
        this._snackBar.open(
          `Cannot remove primary contact until contact details are added to the patient.`,
          `Close`,
          { duration: 15000, panelClass: 'actionSnackBar' }
        );
      }
    }
    this._schedulingFacade.patchPatientDetails(
      { primaryContact: data.primaryContact },
      true
    );
  }

  async relationshipChange(data: IPatientRelationships): Promise<void> {
    const patient = await snapshot(this.patient$);
    if (!patient) {
      return;
    }
    this._schedulingFacade.patchPatientDetails(
      { relationships: data.relationships },
      true
    );
    await this._updateBackReferences(patient.ref);
  }

  private async _updateBackReferences(
    ref: DocumentReference<IPatient>
  ): Promise<void> {
    const patient = await getDoc(ref);
    await PatientRelationship.addRelationshipBackReferences(patient);
  }
}
