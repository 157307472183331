import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import {
  AppointmentSchedulingFacade,
  DEFAULT_FROM_DATE,
  type IAppointmentFilterOptions,
} from '@principle-theorem/ng-appointment/store';
import { MOMENT_DATEPICKER_PROVIDERS } from '@principle-theorem/ng-shared';
import { isChanged$ } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import {
  BehaviorSubject,
  type Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { AppointmentSelectorFormGroup } from './appointment-selector.formgroup';
import { type IRelativeSchedulingRules } from '@principle-theorem/principle-core/interfaces';
import { Appointment } from '@principle-theorem/principle-core';

@Component({
  selector: 'pr-appointment-selector-form',
  templateUrl: './appointment-selector-form.component.html',
  styleUrls: ['./appointment-selector-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
})
export class AppointmentSelectorFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  form = new AppointmentSelectorFormGroup();
  openTime$ = new ReplaySubject<moment.Moment>(1);
  closeTime$ = new ReplaySubject<moment.Moment>(1);
  schedulingRules$: Observable<IRelativeSchedulingRules | undefined>;
  timeInterval: moment.Duration = moment.duration(30, 'minutes');
  // TODO: Build with timezone - CU-251edxw
  now: moment.Moment = moment();
  minimumEndDate$ = new BehaviorSubject<moment.Moment>(this.now);
  @Output()
  valueChange = new EventEmitter<IAppointmentFilterOptions>();

  @Input() showTimeFilters: boolean = true;

  @Input()
  set openTime(time: moment.Moment) {
    if (time) {
      this.openTime$.next(time);
    }
  }

  @Input()
  set closeTime(time: moment.Moment) {
    if (time) {
      this.closeTime$.next(time);
    }
  }

  constructor(private _schedulingFacade: AppointmentSchedulingFacade) {
    this.form.valueChanges
      .pipe(isChanged$(), takeUntil(this._onDestroy$))
      .subscribe((data) => this.valueChange.emit(data));

    this.form.controls.fromDate.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((date) => {
        if (date) {
          this.minimumEndDate$.next(date);

          const endDate = this.form.controls.toDate.value;
          if (!endDate || date.isAfter(endDate)) {
            this.form.controls.toDate.setValue(date);
          }
        }
      });

    this.schedulingRules$ = this._schedulingFacade.currentAppointment$.pipe(
      switchMap((appointment) => {
        if (!appointment) {
          return of(undefined);
        }
        return Appointment.treatmentStep$(appointment).pipe(
          map((step) => step.schedulingRules)
        );
      })
    );

    this.schedulingRules$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((schedulingRules) => {
        if (!schedulingRules) {
          return;
        }
        if (schedulingRules.minDays) {
          this.form.controls.fromDate.setValue(
            DEFAULT_FROM_DATE.clone().add(schedulingRules.minDays, 'days')
          );
        }
        if (schedulingRules.maxDays) {
          this.form.controls.toDate.setValue(
            DEFAULT_FROM_DATE.clone().add(schedulingRules.maxDays, 'days')
          );
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
