import { IBaseMigrationItemCode } from '../../../interfaces';
import { BaseItemCodeToConditionMappingHandler } from '../../../mappings/base-item-code-to-condition';
import {
  IOasisItemCode,
  ItemCodeSourceEntity,
} from '../../source/entities/item-codes';

export class OasisItemCodeToConditionMappingHandler extends BaseItemCodeToConditionMappingHandler<
  IOasisItemCode,
  ItemCodeSourceEntity
> {
  sourceEntity = new ItemCodeSourceEntity();
  translateFn = (record: IOasisItemCode): IBaseMigrationItemCode => ({
    id: record.id,
    itemCode: record.id,
    description: record.description,
  });
}
