import { initVersionedSchema } from '@principle-theorem/editor';
import {
  ICalendarEvent,
  ICalendarEventSchedule,
  ICalendarEventTemplate,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  DocumentReference,
  initFirestoreModel,
  ITimePeriod,
  toTimestampRange,
} from '@principle-theorem/shared';
import { omit } from 'lodash';
import { CalendarEvent } from './calendar-event';
import { Event } from './event';

export class CalendarEventTemplate {
  static init(
    overrides: AtLeast<ICalendarEventTemplate, 'event'>
  ): ICalendarEventTemplate {
    return {
      locked: false,
      isBlocking: true,
      title: [],
      notes: initVersionedSchema(),
      eventHistory: [],
      eventTags: [],
      ...initFirestoreModel(),
      ...overrides,
    };
  }

  static toCalendarEvent(
    template: ICalendarEventTemplate,
    eventTimes: ITimePeriod,
    scheduleRef?: DocumentReference<ICalendarEventSchedule>
  ): ICalendarEvent {
    const event = Event.init({
      ...template.event,
      ...toTimestampRange(eventTimes),
    });
    return CalendarEvent.init({
      ...omit(template, ['event.from', 'event.to']),
      event,
      scheduleRef,
    });
  }
}
