import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AutomationConfigurationService,
  AutomationDialogService,
} from '@principle-theorem/ng-automations';
import {
  ConfirmDialogComponent,
  DialogPresets,
  IConfirmationDialogInput,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import {
  AutomationConfiguration,
  Brand,
  TemplateDefinition,
  TreatmentConfiguration,
} from '@principle-theorem/principle-core';
import {
  AnyAutomationConfiguration,
  IAutomatedFormIssueConfiguration,
  IAutomatedNotificationConfiguration,
  IBrand,
  IGeneratedTaskConfiguration,
  ITreatmentConfiguration,
  TemplateScope,
  isAutomatedFormIssueConfiguration,
  isAutomatedNotificationConfiguration,
  isGeneratedTaskConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  WithRef,
  addDoc,
  addDocAsWithRef,
  isSameRef,
  isWithRef,
  resolveDocRefs$,
  undeletedQuery,
} from '@principle-theorem/shared';
import { uniqBy } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Injectable()
export class TreatmentStepAutomationConfigurationService {
  private _dialog = inject(MatDialog);
  private _snackBar = inject(MatSnackBar);
  private _automationDialog = inject(AutomationDialogService);
  private _automationConfigService = inject(AutomationConfigurationService);

  getTreatmentAutomationConfigs$(
    treatmentConfig: WithRef<ITreatmentConfiguration>
  ): Observable<WithRef<AnyAutomationConfiguration>[]> {
    const notifications$ = resolveDocRefs$(
      treatmentConfig.automatedNotificationRefs
    );
    const tasks$ = resolveDocRefs$(treatmentConfig.generatedTaskRefs);
    const forms$ = resolveDocRefs$(treatmentConfig.automatedFormIssueRefs);
    return combineLatest([notifications$, tasks$, forms$]).pipe(
      map((automations) => automations.flat()),
      debounceTime(50)
    );
  }

  async remove(
    treatmentConfig: WithRef<ITreatmentConfiguration>,
    automationConfig: WithRef<AnyAutomationConfiguration>
  ): Promise<void> {
    const willDeleteAutomation =
      uniqBy(automationConfig.treatmentRefs, (ref) => ref.path).length < 2;

    const prompt = willDeleteAutomation
      ? [
          `This automation is on no other treatments and will be deleted.`,
          `Are you sure you want to remove this automation from ${treatmentConfig.name} and delete it?`,
        ]
      : `Are you sure you want to remove this automation from ${treatmentConfig.name}?`;

    const data = confirmationDialogData({
      title: willDeleteAutomation
        ? 'Remove & Delete Automation'
        : 'Remove Automation',
      prompt,
      submitLabel: willDeleteAutomation ? 'Remove & Delete' : 'Remove',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }

    await AutomationConfiguration.removeTreatmentRef(
      automationConfig,
      treatmentConfig.ref
    );

    await this._removeAutomationFromTreatment(
      treatmentConfig,
      automationConfig
    );

    this._snackBar.open('Removed Automation');
  }

  async edit(
    config: WithRef<AnyAutomationConfiguration>,
    scope: TemplateScope
  ): Promise<void> {
    await this._automationConfigService.editConfig(config, scope);
  }

  async delete(config: WithRef<AnyAutomationConfiguration>): Promise<void> {
    await this._automationConfigService.deleteConfig(config);
  }

  async setActive(
    config: WithRef<AnyAutomationConfiguration>,
    isActive: boolean
  ): Promise<void> {
    await this._automationConfigService.setActive(config, isActive);
  }

  async addExisting(
    treatmentConfig: WithRef<ITreatmentConfiguration>
  ): Promise<void> {
    const brandRef = Firestore.getParentDocRef<IBrand>(treatmentConfig.ref);
    const configs = await Firestore.getDocs<AnyAutomationConfiguration>(
      undeletedQuery(AutomationConfiguration.col({ ref: brandRef }))
    );

    const response = await this._automationDialog.selectConfiguration({
      configs,
    });
    if (!response) {
      return;
    }

    await AutomationConfiguration.addTreatmentRef(
      response.config,
      treatmentConfig.ref
    );

    await this._addAutomationToTreatment(treatmentConfig, response.config);
    this._snackBar.open('Automation added');
  }

  async createNotification(
    treatmentConfig: WithRef<ITreatmentConfiguration>,
    scope: TemplateScope
  ): Promise<void> {
    const response = await this._automationConfigService.openEditDialog({
      scope,
    });
    if (!response) {
      return;
    }

    const brandRef = TreatmentConfiguration.getBrandRef(treatmentConfig);
    const templateRef = await addDoc(
      Brand.templateCol({ ref: brandRef }),
      TemplateDefinition.init({ ...response.template, ownerScope: brandRef })
    );

    const automationConfig =
      await addDocAsWithRef<IAutomatedNotificationConfiguration>(
        AutomationConfiguration.col({ ref: brandRef }),
        {
          ...response.config,
          treatmentRefs: [treatmentConfig.ref],
          templateRef,
        }
      );

    await Firestore.patchDoc(templateRef, {
      automatedConfigurationRef: automationConfig.ref,
    });

    await this._addAutomationToTreatment(treatmentConfig, automationConfig);
    this._snackBar.open('Notification configuration created');
  }

  async createTask(
    treatmentConfig: WithRef<ITreatmentConfiguration>
  ): Promise<void> {
    const response = await this._automationDialog.openTaskDialog({
      useRelativeTime: true,
    });
    if (!response) {
      return;
    }

    const brandRef = TreatmentConfiguration.getBrandRef(treatmentConfig);
    const automationConfig = await addDocAsWithRef<IGeneratedTaskConfiguration>(
      AutomationConfiguration.col({ ref: brandRef }),
      {
        ...AutomationConfiguration.init(),
        ...response,
        treatmentRefs: [treatmentConfig.ref],
      }
    );

    await this._addAutomationToTreatment(treatmentConfig, automationConfig);
    this._snackBar.open('Task configuration saved');
  }

  async createForm(
    treatmentConfig: WithRef<ITreatmentConfiguration>
  ): Promise<void> {
    const response = await this._automationDialog.openAutomatedFormIssueDialog({
      useRelativeTime: true,
    });

    if (!response) {
      return;
    }

    const brandRef = TreatmentConfiguration.getBrandRef(treatmentConfig);
    const automationConfig =
      await addDocAsWithRef<IAutomatedFormIssueConfiguration>(
        AutomationConfiguration.col({ ref: brandRef }),
        {
          ...AutomationConfiguration.init(),
          ...response,
          treatmentRefs: [treatmentConfig.ref],
        }
      );

    await this._addAutomationToTreatment(treatmentConfig, automationConfig);
    return Promise.resolve();
  }

  private async _removeAutomationFromTreatment(
    treatmentConfig: WithRef<ITreatmentConfiguration>,
    automationConfig: WithRef<AnyAutomationConfiguration>
  ): Promise<void> {
    await Firestore.patchDoc<ITreatmentConfiguration>(treatmentConfig.ref, {
      generatedTaskRefs: treatmentConfig.generatedTaskRefs.filter(
        (item) => !isSameRef(automationConfig, item)
      ),
      automatedNotificationRefs:
        treatmentConfig.automatedNotificationRefs.filter(
          (item) => !isSameRef(automationConfig, item)
        ),
      automatedFormIssueRefs: treatmentConfig.automatedFormIssueRefs.filter(
        (item) => !isSameRef(automationConfig, item)
      ),
    });
  }

  private async _addAutomationToTreatment(
    treatmentConfig: WithRef<ITreatmentConfiguration>,
    automationConfig: WithRef<AnyAutomationConfiguration>
  ): Promise<void> {
    if (
      isAutomatedNotificationConfiguration(automationConfig) &&
      isWithRef<IAutomatedNotificationConfiguration>(automationConfig)
    ) {
      await Firestore.patchDoc(treatmentConfig.ref, {
        automatedNotificationRefs: [
          ...treatmentConfig.automatedNotificationRefs,
          automationConfig.ref,
        ],
      });
      return;
    }
    if (
      isGeneratedTaskConfiguration(automationConfig) &&
      isWithRef<IGeneratedTaskConfiguration>(automationConfig)
    ) {
      await Firestore.patchDoc(treatmentConfig.ref, {
        generatedTaskRefs: [
          ...treatmentConfig.generatedTaskRefs,
          automationConfig.ref,
        ],
      });
      return;
    }
    if (
      isAutomatedFormIssueConfiguration(automationConfig) &&
      isWithRef<IAutomatedFormIssueConfiguration>(automationConfig)
    ) {
      await Firestore.patchDoc(treatmentConfig.ref, {
        automatedFormIssueRefs: [
          ...treatmentConfig.automatedFormIssueRefs,
          automationConfig.ref,
        ],
      });
      return;
    }
  }
}
