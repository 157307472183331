import { Directionality } from '@angular/cdk/bidi';
import { Overlay, ScrollStrategy, ViewportRuler } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Inject,
  NgZone,
  Optional,
  ViewContainerRef,
} from '@angular/core';
import {
  MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
  MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
  MAT_AUTOCOMPLETE_VALUE_ACCESSOR,
} from '@angular/material/autocomplete';
import { EditorAutocompleteTriggerBaseDirective } from './editor-autocomplete-trigger-base.directive';
import { MAT_MENU_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/menu';

@Directive({
  selector: `div[ptEditorAutocomplete], span[ptEditorAutocomplete]`,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'mat-autocomplete-trigger',
    '[attr.autocomplete]': 'autocompleteAttribute',
    '[attr.role]': 'autocompleteDisabled ? undefined : "combobox"',
    '[attr.aria-autocomplete]': 'autocompleteDisabled ? undefined : "list"',
    '[attr.aria-activedescendant]':
      '(panelOpen && activeOption) ? activeOption.id : undefined',
    '[attr.aria-expanded]':
      'autocompleteDisabled ? undefined : panelOpen.toString()',
    '[attr.aria-owns]':
      '(autocompleteDisabled || !panelOpen) ? undefined : autocomplete?.id',
    '[attr.aria-haspopup]': '!autocompleteDisabled',
    // Note: we use `focusin`, as opposed to `focus`, in order to open the panel
    // a little earlier. This avoids issues where IE delays the focusing of the input.
    '(focusin)': '_handleFocus()',
    '(blur)': '_onTouched()',
    '(input)': '_handleInput($event)',
    '(keydown)': '_handleKeydown($event)',
  },
  exportAs: 'ptEditorAutocompleteTrigger',
  providers: [
    MAT_AUTOCOMPLETE_VALUE_ACCESSOR,
    MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
    MAT_MENU_SCROLL_STRATEGY_FACTORY_PROVIDER,
  ],
})
export class EditorAutocompleteTriggerDirective extends EditorAutocompleteTriggerBaseDirective {
  protected _aboveClass = 'mat-autocomplete-panel-above';

  constructor(
    element: ElementRef<HTMLElement>,
    overlay: Overlay,
    viewContainerRef: ViewContainerRef,
    zone: NgZone,
    changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_AUTOCOMPLETE_SCROLL_STRATEGY)
    scrollStrategy: () => ScrollStrategy,
    @Optional() dir: Directionality,
    @Optional() @Inject(DOCUMENT) document: Document,
    viewportRuler: ViewportRuler
  ) {
    super(
      element,
      overlay,
      viewContainerRef,
      zone,
      changeDetectorRef,
      scrollStrategy,
      dir,
      document,
      viewportRuler
    );
  }
}
