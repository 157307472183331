import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { initVersionedSchema } from '@principle-theorem/editor';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import {
  FOLLOW_UP_DATE_PRESETS,
  IFollowUpDatePreset,
  IFollowUpFormData,
} from '@principle-theorem/ng-follow-ups';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TrackByFunctions,
  TypedFormControl,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  Brand,
  SchedulingEvent,
  SchedulingEventReason,
  TimezoneResolver,
} from '@principle-theorem/principle-core';
import {
  IAppointment,
  IPatient,
  ISchedulingEventConditions,
  ISchedulingEventData,
  ISchedulingEventReason,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_TIME_WITH_YEAR_FORMAT,
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { find } from 'lodash';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { CancelAppointmentFormGroup } from './cancel-appointment.formgroup';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';

@Component({
  selector: 'pr-cancel-appointment',
  templateUrl: './cancel-appointment.component.html',
  styleUrls: ['./cancel-appointment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
})
export class CancelAppointmentComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByReason = TrackByFunctions.ref<WithRef<ISchedulingEventReason>>();
  reasons$: Observable<WithRef<ISchedulingEventReason>[]>;
  readonly dateFormat = DATE_TIME_WITH_YEAR_FORMAT;
  cancelFormGroup = new CancelAppointmentFormGroup();
  appointment$: Observable<WithRef<IAppointment>>;
  patient$: Observable<WithRef<IPatient>>;
  disableSubmit$: Observable<boolean>;
  submitting$ = new BehaviorSubject<boolean>(false);
  minDate = moment();
  datePresets = FOLLOW_UP_DATE_PRESETS;
  datePresetCtrl = new TypedFormControl<IFollowUpDatePreset>(
    find(this.datePresets, { label: '2 Weeks' })
  );
  schedulingConditions$: Observable<ISchedulingEventConditions>;

  constructor(
    private _schedulingFacade: AppointmentSchedulingFacade,
    private _organisation: OrganisationService,
    private _location: Location
  ) {
    this.appointment$ =
      this._schedulingFacade.currentAppointment$.pipe(filterUndefined());
    this.patient$ =
      this._schedulingFacade.selectedPatient$.pipe(filterUndefined());
    formControlChanges$(this.cancelFormGroup.controls.createFollowUp)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((createFollowUp) => {
        if (createFollowUp) {
          this.cancelFormGroup.controls.noFollowUpReason.clearValidators();
          this.cancelFormGroup.controls.noFollowUpReason.reset(
            initVersionedSchema()
          );
        }
      });

    this.disableSubmit$ = combineLatest([
      this.appointment$,
      this.submitting$,
    ]).pipe(
      map(([appointment, submitting]) => !appointment.event || submitting)
    );

    this.schedulingConditions$ = this.appointment$.pipe(
      switchMap(async (appointment) =>
        SchedulingEvent.getSchedulingConditions(
          await TimezoneResolver.fromPracticeRef(appointment.practice.ref),
          appointment.event?.from
        )
      )
    );
    const reasons$ = this._schedulingFacade.brand$.pipe(
      switchMap((brand) => Brand.cancellationReasons$(brand))
    );
    this.reasons$ = combineLatest([reasons$, this.schedulingConditions$]).pipe(
      map(([reasons, conditions]) =>
        SchedulingEventReason.filterByEventType(reasons, conditions.eventType)
      )
    );

    formControlChanges$(this.datePresetCtrl)
      .pipe(filterUndefined(), takeUntil(this._onDestroy$))
      .subscribe((preset) =>
        this.cancelFormGroup.controls.followUpDate.setValue(
          moment().add(preset.amount, preset.unit)
        )
      );
  }

  ngOnDestroy(): void {
    this._schedulingFacade.reset();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  back(): void {
    this._location.back();
  }

  async submit(): Promise<void> {
    if (!this.cancelFormGroup.valid) {
      return;
    }
    this.submitting$.next(true);

    const formData = this.cancelFormGroup.getRawValue();
    const schedulingConditions = await snapshot(this.schedulingConditions$);
    const followUpData: IFollowUpFormData = {
      createFollowUp: formData.createFollowUp,
      followUpDate: formData.followUpDate,
      noFollowUpReason: formData.noFollowUpReason,
    };
    const practice = await snapshot(
      this._organisation.practice$.pipe(filterUndefined())
    );
    const schedulingEventData: ISchedulingEventData = {
      scheduledByPractice: practice.ref,
      reason: formData.reason.reason,
      reasonSetManually: formData.reason.reasonSetManually,
      comments: formData.comments,
      schedulingConditions,
    };

    this._schedulingFacade.cancelAppointment(followUpData, schedulingEventData);
  }
}
