import {
  AppointmentStatus,
  CollectionGroup,
  type IBrand,
  type IAppointment,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  type ITimePeriod,
  asyncForEach,
  collectionGroupQuery,
  isSameRef,
  multiFilter,
  query,
  shareReplayCold,
  snapshot,
  toMomentTz,
  toTimestamp,
  where,
  type WithRef,
  DataFormat,
  type IDataTable,
} from '@principle-theorem/shared';
import { flatten, groupBy, toPairs } from 'lodash';
import { map } from 'rxjs/operators';
import { Appointment } from '../models/appointment/appointment';
import { Brand } from '../models/brand';
import { Patient } from '../models/patient/patient';
import { TimezoneResolver } from '../timezone';
import { dateRangeToPracticeTimezone } from './helpers';

interface IAppointmentData {
  practitioner: string;
  practice: string;
  nextAppointmentBooked: boolean;
  bookedAtCheckout: boolean;
}

interface IRow {
  practitioner: string;
  practice: string;
  appointmentCount: number;
  nextAppointmentsBooked: number;
  bookedAtCheckout: number;
  rebookingRate: number;
}

/**
 * Percentage of appointments booked vs. not, broken down by practitioner
 * since the start of principle. Doesn't need to be done by clinic.
 */
export class PractitionerRebookingRates {
  async run(
    dateRange: ITimePeriod,
    brandRef: DocumentReference<IBrand>
  ): Promise<IDataTable<IRow>[]> {
    const appointments = await this._getAppointments(brandRef, dateRange);
    const appointmentsData =
      await this._getCompletedAppointmentsData(appointments);
    const rows = this._toRows(appointmentsData);
    return this._toDataTables(rows);
  }

  private async _getAppointments(
    brandRef: DocumentReference<IBrand>,
    dateRange: ITimePeriod
  ): Promise<WithRef<IAppointment>[]> {
    const practices = await query(
      Brand.practiceCol({ ref: brandRef }),
      where('deleted', '==', false)
    );
    const practiceAppointments = await asyncForEach(practices, (practice) => {
      const practiceDateRange = dateRangeToPracticeTimezone(
        practice,
        dateRange
      );
      return query(
        collectionGroupQuery<IAppointment>(CollectionGroup.Appointments),
        where('event.practice.ref', '==', practice.ref),
        where('event.from', '>=', toTimestamp(practiceDateRange.from)),
        where('event.from', '<=', toTimestamp(practiceDateRange.to))
      );
    });
    return flatten(practiceAppointments);
  }

  private async _getCompletedAppointmentsData(
    appointments: WithRef<IAppointment>[]
  ): Promise<IAppointmentData[]> {
    const completedAppointments = appointments.filter((appointment) =>
      [AppointmentStatus.CheckingOut, AppointmentStatus.Complete].includes(
        appointment.status
      )
    );
    const allAppointmentData = await asyncForEach(
      completedAppointments,
      async (appointment) => {
        const bookingData = await this._isNextAppointmentBooked(appointment);
        return {
          practitioner: appointment.practitioner.name,
          practice:
            appointment.event?.practice.name ?? appointment.practice.name,
          ...bookingData,
        };
      }
    );
    return flatten(allAppointmentData);
  }

  private async _isNextAppointmentBooked(
    appointment: WithRef<IAppointment>
  ): Promise<{ nextAppointmentBooked: boolean; bookedAtCheckout: boolean }> {
    const timezone = await TimezoneResolver.fromPracticeRef(
      appointment.practice.ref
    );
    const appointmentTimestamp = appointment.event
      ? toMomentTz(appointment.event.to, timezone)
      : undefined;
    if (!appointmentTimestamp) {
      return { nextAppointmentBooked: false, bookedAtCheckout: false };
    }

    const patient = await snapshot(Appointment.patient$(appointment));

    const appointments$ = Patient.appointments$(patient).pipe(
      multiFilter(
        (filterAppointment) => !isSameRef(appointment, filterAppointment)
      ),
      shareReplayCold()
    );

    const nextAppointmentBooked = await snapshot(
      appointments$.pipe(
        multiFilter((filterAppointment) => {
          if (!filterAppointment.event) {
            return false;
          }
          return toMomentTz(filterAppointment.event.from, timezone).isAfter(
            appointmentTimestamp
          );
        }),
        map((bookedAppointments) => bookedAppointments.length > 0)
      )
    );

    const bookedAtCheckout = await snapshot(
      appointments$.pipe(
        multiFilter((filterAppointment) => {
          const scheduledAt = Appointment.lastEnteredStatus(
            filterAppointment,
            AppointmentStatus.Scheduled
          );
          if (!scheduledAt) {
            return false;
          }

          return appointmentTimestamp.isSame(
            toMomentTz(scheduledAt, timezone),
            'day'
          );
        }),
        map((bookedAppointments) => bookedAppointments.length > 0)
      )
    );

    return {
      nextAppointmentBooked,
      bookedAtCheckout,
    };
  }

  private _toRows(appointmentsData: IAppointmentData[]): IRow[] {
    const byPractice = groupBy(
      appointmentsData,
      (appointment) => appointment.practice
    );
    const allRows = toPairs(byPractice).map(
      ([practice, practiceAppointments]) => {
        const byPractioner = groupBy(
          practiceAppointments,
          (appointment) => appointment.practitioner
        );
        const practitionerRows = toPairs(byPractioner).map(
          ([practitioner, practitionerAppointments]) =>
            this._appointmentsToRow(
              practitioner,
              practice,
              practitionerAppointments
            )
        );

        const practiceTotal = this._appointmentsToRow(
          `Total`,
          practice,
          practiceAppointments
        );
        return [...practitionerRows, practiceTotal];
      }
    );

    return flatten(allRows);
  }

  private _appointmentsToRow(
    practitioner: string,
    practice: string,
    appointments: IAppointmentData[]
  ): IRow {
    const appointmentCount = appointments.length;
    const bookedAtCheckout = appointments.filter(
      (appointment) => appointment.bookedAtCheckout
    ).length;
    const nextAppointmentsBooked = appointments.filter(
      (appointment) => appointment.nextAppointmentBooked
    ).length;
    const rebookingRate = nextAppointmentsBooked / appointmentCount;

    return {
      practitioner,
      practice,
      appointmentCount,
      bookedAtCheckout,
      nextAppointmentsBooked,
      rebookingRate,
    };
  }

  private _toDataTables(data: IRow[]): IDataTable<IRow>[] {
    return [
      {
        name: 'Practitioner Rebooking Rates',
        data,
        columns: [
          { key: 'practitioner', header: 'Practitioner' },
          { key: 'practice', header: 'Practice' },
          { key: 'appointmentCount', header: 'Total Appointments' },
          { key: 'nextAppointmentsBooked', header: 'Next Visits Booked' },
          { key: 'bookedAtCheckout', header: 'Booked at Checkout' },
          {
            key: 'rebookingRate',
            header: 'Rebooking Rate',
            format: DataFormat.Percent,
          },
        ],
      },
    ];
  }
}
