import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import {
  type HealthPointReconciliationReportResponse,
  type ReconciliationReportResponse,
} from '@principle-theorem/tyro';
import { type IPrincipleReconciliationReportData } from '@principle-theorem/reporting';
import { type SerialisedData } from '@principle-theorem/shared';
import {
  setHealthPointReport,
  setPrincipleReport,
  setTyroReport,
} from '../actions/report.actions';

export const principleReportReducer: ActionReducer<
  SerialisedData<IPrincipleReconciliationReportData | undefined>,
  Action
> = createReducer<
  SerialisedData<IPrincipleReconciliationReportData | undefined>
>(
  undefined,
  on(setPrincipleReport, (_state, action) => action.report)
);

export const tyroReportReducer: ActionReducer<
  SerialisedData<ReconciliationReportResponse | undefined>,
  Action
> = createReducer<SerialisedData<ReconciliationReportResponse | undefined>>(
  undefined,
  on(setTyroReport, (_state, action) => action.report)
);

export const healthPointReportReducer: ActionReducer<
  SerialisedData<HealthPointReconciliationReportResponse | undefined>,
  Action
> = createReducer<
  SerialisedData<HealthPointReconciliationReportResponse | undefined>
>(
  undefined,
  on(setHealthPointReport, (_state, action) => action.report)
);
