<mat-toolbar color="accent">
  <span *ngrxLet="patient$ as patient" class="title truncate">
    Waitlist Settings - {{ patient.name }}
    <ng-container *ngrxLet="appointment$ as appointment">
      <span *ngIf="appointment.event; else noEvent">
        in
        <ng-container
          *ngIf="
            appointment.event.from
              | momentTimezone$ : appointment.event.practice.ref
              | ngrxPush as from
          "
        >
          {{ from | moment | amDifference : today : 'days' }}
        </ng-container>
        days &#64;
        <pr-formatted-event-field
          field="from"
          [event]="appointment.event"
          format="hh:mma"
        />
      </span>
      <ng-template #noEvent>
        <span>Unscheduled</span>
      </ng-template>
    </ng-container>
  </span>
</mat-toolbar>

<mat-dialog-content>
  <div *ngrxLet="hasWaitlistSettings$ as hasWaitlistSettings">
    <pr-waitlist-configuration
      *ngIf="hasWaitlistSettings; else addToWaitlistButton"
      #waitlistConfiguration
      [hideToggle]="true"
      [openTime]="openTime$ | async"
      [closeTime]="closeTime$ | async"
      [waitlistDetails]="waitlistDetails$ | ngrxPush"
      (waitListChange)="waitlistFormGroup.patchValue($event)"
    />

    <ng-template #addToWaitlistButton>
      <p>This appointment is not on the waitlist.</p>
    </ng-template>
  </div>
</mat-dialog-content>

<mat-dialog-actions *ngrxLet="hasWaitlistSettings$ as hasWaitlistSettings">
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      *ngIf="!hasWaitlistSettings"
      color="primary"
      (click)="addToWaitlist()"
    >
      Add to Waitlist
    </button>
    <button
      mat-stroked-button
      *ngIf="hasWaitlistSettings"
      (click)="removeFromWaitlist()"
    >
      Remove From Waitlist
    </button>
    <button
      mat-flat-button
      *ngIf="hasWaitlistSettings"
      color="primary"
      [disabled]="waitlistFormGroup.invalid"
      (click)="save()"
    >
      Save
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
