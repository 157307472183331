import { Clipboard } from '@angular/cdk/clipboard';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  signal,
} from '@angular/core';
import {
  canSave$,
  IBreadcrumb,
  TypedFormControl,
  BasicDialogService,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  IPractice,
  IPracticeIpWhitelist,
  IPracticeRestrictions,
  IPracticeUserRestriction,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  skip,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import {
  WithRef,
  filterUndefined,
  Time24hrType,
  Firestore,
  multiSwitchMap,
  isSameRef,
  snapshot,
} from '@principle-theorem/shared';
import { UserPublicIpService } from '@principle-theorem/ng-auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { compact, differenceWith, findIndex } from 'lodash';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { Brand, Staffer } from '@principle-theorem/principle-core';
import { isIP } from 'is-ip';
import { toObservable } from '@angular/core/rxjs-interop';

interface IUserRestrictionForm {
  isEnabled: boolean;
  staff: WithRef<IStaffer>[];
  allowedFrom: Time24hrType;
  allowedTo: Time24hrType;
}

interface IIpRestrictionForm extends IPracticeIpWhitelist {
  practice: WithRef<IPractice>;
}

@Component({
  selector: 'pr-security',
  templateUrl: './security.component.html',
  styleUrl: './security.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecurityComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  breadcrumbs: IBreadcrumb[] = [
    { label: 'Settings', path: '../../' },
    { label: 'Security' },
  ];
  separatorKeysCodes = [ENTER, COMMA];
  saveDisabled$: Observable<boolean>;
  userIp$: Observable<string | undefined>;
  staffOptions$: Observable<WithRef<IStaffer>[]>;
  userRestrictions = signal<IPracticeUserRestriction[]>([]);
  selectedPractice = signal<WithRef<IPractice> | undefined>(undefined);
  staffSelectorCtrl = new TypedFormControl<WithRef<IStaffer>[]>([]);
  ipWhitelistForm = new TypedFormGroup<IIpRestrictionForm>({
    isEnabled: new TypedFormControl<boolean>(undefined),
    whitelist: new TypedFormControl<string[]>([]),
    practice: new TypedFormControl<WithRef<IPractice> | undefined>(undefined),
  });
  userRestrictionsForm = new TypedFormGroup<IUserRestrictionForm>({
    isEnabled: new TypedFormControl<boolean>(undefined),
    staff: this.staffSelectorCtrl,
    allowedFrom: new TypedFormControl<Time24hrType>(undefined),
    allowedTo: new TypedFormControl<Time24hrType>(undefined),
  });

  constructor(
    public organisation: OrganisationService,
    private _ipService: UserPublicIpService,
    private _snackBar: MatSnackBar,
    private _basicDialog: BasicDialogService,
    private _clipboard: Clipboard
  ) {
    this.userIp$ = this._ipService.getIpAddress$();

    this.organisation.practice$
      .pipe(take(1), filterUndefined(), takeUntil(this._onDestroy$))
      .subscribe((practice) => this.selectedPractice.set(practice));

    const practice$ = toObservable(this.selectedPractice).pipe(
      filterUndefined(),
      distinctUntilChanged(isSameRef),
      withLatestFrom(this.organisation.practices$),
      map(([selectedPractice, practices]) =>
        practices.find((practice) => isSameRef(practice, selectedPractice))
      ),
      filterUndefined()
    );

    const allStaff$ = practice$.pipe(
      switchMap((practice) => Staffer.byPractice$(practice))
    );

    const filterStaff$ = combineLatest([
      toObservable(this.userRestrictions),
      this.organisation.brand$.pipe(filterUndefined()),
    ]).pipe(
      switchMap(([userRestrictions, brand]) =>
        of(userRestrictions).pipe(
          multiSwitchMap((restriction) =>
            Brand.userStaffer$(brand, restriction.user)
          )
        )
      ),
      map(compact)
    );

    this.staffOptions$ = combineLatest([allStaff$, filterStaff$]).pipe(
      map(([allStaff, filterStaff]) =>
        differenceWith(allStaff, filterStaff, isSameRef)
      )
    );

    practice$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(({ restrictions }) => this.patchForm(restrictions));

    practice$
      .pipe(skip(1), takeUntil(this._onDestroy$))
      .subscribe(({ name }) =>
        this._snackBar.open(`Practice changed to ${name}`)
      );

    this.saveDisabled$ = combineLatest([
      canSave$(this.ipWhitelistForm),
      canSave$(this.userRestrictionsForm),
    ]).pipe(
      map(
        ([canSaveWhitelist, canSaveRestrictions]) =>
          !canSaveWhitelist && !canSaveRestrictions
      )
    );
  }

  copyIpToClipboard(ip: string): void {
    this._snackBar.open('Copied to clipboard');
    this._clipboard.copy(ip);
  }

  addIp(event: MatChipInputEvent): void {
    const ip = (event.value || '').trim();
    if (!isIP(ip)) {
      return;
    }

    const currentValue = this.ipWhitelistForm.controls.whitelist.value ?? [];
    this.ipWhitelistForm.controls.whitelist.patchValue([...currentValue, ip]);
    event.chipInput.clear();
    this.ipWhitelistForm.markAsDirty();
    this.ipWhitelistForm.updateValueAndValidity();
  }

  removeIp(ip: string): void {
    const currentIps = this.ipWhitelistForm.controls.whitelist.value ?? [];
    this.ipWhitelistForm.controls.whitelist.patchValue(
      currentIps.filter((currentIp) => currentIp !== ip)
    );
    this.ipWhitelistForm.markAsDirty();
    this.ipWhitelistForm.updateValueAndValidity();
  }

  editIp(ip: string, event: MatChipEditedEvent): void {
    const editedIp = event.value.trim();
    if (!editedIp) {
      this.removeIp(ip);
      return;
    }

    const currentIps = this.ipWhitelistForm.controls.whitelist.value ?? [];
    const foundIndex = findIndex(currentIps, (currentIp) => currentIp === ip);
    if (foundIndex === -1) {
      return;
    }
    currentIps[foundIndex] = editedIp;
    this.ipWhitelistForm.controls.whitelist.patchValue(currentIps);
    this.ipWhitelistForm.markAsDirty();
    this.ipWhitelistForm.updateValueAndValidity();
  }

  addUserRestriction(): void {
    const { staff, isEnabled, allowedFrom, allowedTo } =
      this.userRestrictionsForm.getRawValue();
    const addedRestrictions = staff.map(({ user }) => ({
      user,
      allowedFrom,
      allowedTo,
    }));

    this.userRestrictions.set(
      this.userRestrictions().concat(addedRestrictions)
    );
    this.userRestrictionsForm.reset();
    this.userRestrictionsForm.patchValue({ isEnabled });
  }

  deleteRestriction(restriction: IPracticeUserRestriction): void {
    this.userRestrictions.set(
      this.userRestrictions().filter(
        (existing) => !isSameRef(existing.user, restriction.user)
      )
    );
    this.userRestrictionsForm.markAsDirty();
    this.userRestrictionsForm.updateValueAndValidity();
  }

  async practiceSelected(practice: WithRef<IPractice>): Promise<void> {
    if (isSameRef(practice, this.selectedPractice())) {
      return;
    }

    const changePractice = (): void => {
      this.userRestrictions.set([]);
      this.selectedPractice.set(practice);
      this.markFormAsPristine();
    };

    const saveDisabled = await snapshot(this.saveDisabled$);
    if (saveDisabled) {
      changePractice();
      return;
    }

    const confirmed = await this._basicDialog.confirm({
      title: 'Unsaved Changes',
      prompt: 'You have unsaved changes. Are you sure you want to continue?',
    });
    if (confirmed) {
      changePractice();
    }
  }

  async save(): Promise<void> {
    const practice = this.selectedPractice();
    if (!practice) {
      return;
    }

    const { isEnabled } = this.userRestrictionsForm.getRawValue();

    await Firestore.patchDoc(practice.ref, {
      restrictions: {
        ...practice.restrictions,
        ipWhitelist: this.ipWhitelistForm.getRawValue(),
        userRestrictions: {
          isEnabled,
          restrictions: this.userRestrictions(),
        },
      },
    });

    this.markFormAsPristine();
    this._snackBar.open('Practice Restrictions Saved');
  }

  patchForm(restrictions?: IPracticeRestrictions): void {
    this.ipWhitelistForm.patchValue(
      restrictions?.ipWhitelist ?? {
        isEnabled: false,
        whitelist: [],
      }
    );
    this.userRestrictionsForm.patchValue({
      isEnabled: restrictions?.userRestrictions?.isEnabled,
    });
    this.userRestrictions.set(
      restrictions?.userRestrictions?.restrictions ?? []
    );
  }

  markFormAsPristine(): void {
    this.ipWhitelistForm.markAsPristine();
    this.ipWhitelistForm.updateValueAndValidity();
    this.userRestrictionsForm.markAsPristine();
    this.userRestrictionsForm.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
