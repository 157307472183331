import { type IFirestoreModel, isObject } from '@principle-theorem/shared';
import { isString } from 'lodash';

export interface ITeam extends IFirestoreModel {
  name: string;
}

export function isTeam(item: unknown): item is ITeam {
  return isObject(item) && 'name' in item && isString(item.name);
}
