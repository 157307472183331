import {
  TreatmentPlanAction,
  TreatmentPlanStatus,
} from '@principle-theorem/principle-core/interfaces';
import { ITreatmentPlanEventFact } from '@principle-theorem/reporting/interfaces';
import {
  BaseMock,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
} from '@principle-theorem/shared';
import { BrandDimensionMock } from '../dimensions/brand-dimension.mock';
import { SerialisedDocumentReferenceMock } from '../dimensions/common.mock';
import { PatientDimensionMock } from '../dimensions/patient-dimension.mock';
import { StafferDimensionMock } from '../dimensions/staffer-dimension.mock';
import { TreatmentPlanDimensionMock } from '../dimensions/treatment-plan-dimension.mock';

export class TreatmentPlanEventFactMock
  extends BaseMock
  implements ITreatmentPlanEventFact
{
  practitionerRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  treatmentPlanRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  patientRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  brandRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practitioner = MockGenerator.generate(StafferDimensionMock);
  treatmentPlan = MockGenerator.generate(TreatmentPlanDimensionMock);
  patient = MockGenerator.generate(PatientDimensionMock);
  referrer = this.patient.referrer;
  brand = MockGenerator.generate(BrandDimensionMock);
  timestamp = toSerialisedTimestamp(MockTimestamp());
  event = {
    action: TreatmentPlanAction.InProgress,
    statusBefore: TreatmentPlanStatus.Offered,
    statusAfter: TreatmentPlanStatus.Accepted,
  };
}
