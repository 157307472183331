import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  ArchivedDocument,
  DocumentArchive,
  Firestore,
  SHORT_DATE_TIME_FORMAT,
  WithRef,
} from '@principle-theorem/shared';
import {
  IStaffer,
  ISterilisationRecord,
  STERILISATION_STATUS_COLOUR_MAP,
  SterilisationStatus,
} from '@principle-theorem/principle-core/interfaces';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getSchemaText } from '@principle-theorem/editor';

export interface ISterilisationRecordHistoryDialogData {
  history: WithRef<ArchivedDocument<ISterilisationRecord>>[];
}

@Component({
  selector: 'pr-sterilisation-record-history-dialog',
  standalone: true,
  imports: [MatTableModule, NgPrincipleSharedModule, NgSharedModule],
  templateUrl: './sterilisation-record-history-dialog.component.html',
  styleUrl: './sterilisation-record-history-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SterilisationRecordHistoryDialogComponent {
  readonly dateFormat = SHORT_DATE_TIME_FORMAT;
  data = inject<ISterilisationRecordHistoryDialogData>(MAT_DIALOG_DATA);
  dataSource = new MatTableDataSource(
    this.data.history.sort((aRecord, bRecord) =>
      DocumentArchive.sortByArchivedAt(aRecord, bRecord)
    )
  );
  displayedColumns = [
    'cycle',
    'id',
    'editedAt',
    'editedBy',
    'patient',
    'appointment',
    'scannedBy',
    'pack',
    'notes',
    'status',
  ];

  async getEditedBy(
    record?: WithRef<ArchivedDocument<ISterilisationRecord>>
  ): Promise<WithRef<IStaffer> | undefined> {
    if (!record?.archivedBy) {
      return;
    }
    await Firestore.getDoc(record.archivedBy);
  }

  getNoteContent(record?: WithRef<ISterilisationRecord>): string {
    return record?.notes ? getSchemaText(record.notes) : '';
  }

  getStatusColour(status: SterilisationStatus): string {
    return STERILISATION_STATUS_COLOUR_MAP[status];
  }
}
