<ng-container *ngIf="selected$ | async as selected; else loader">
  <div class="layout-margin">
    <div
      class="patient-container"
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="32px"
    >
      <div *ngIf="patient$ | async as patient">
        <strong>Patient</strong>
        <div>{{ patient.name }}</div>
        <div>{{ patient.email }}</div>
        <div *ngIf="phone$ | async as phone">{{ phone }}</div>
      </div>
    </div>
    <pr-custom-form [form]="selected.form" [disabled]="true" />
  </div>
</ng-container>
<ng-template #loader>
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner />
  </div>
</ng-template>
