import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IBreadcrumb,
  type IConfirmationDialogInput,
  type IMatSelectGroupOptions,
  matSelectGroupOptions,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  SystemTemplates,
  TemplateDefinition,
} from '@principle-theorem/principle-core';
import {
  IAutomatedNotificationConfiguration,
  type IBrand,
  type ITemplateDefinition,
  TemplateScope,
} from '@principle-theorem/principle-core/interfaces';
import {
  type CollectionReference,
  type DocumentReference,
} from '@principle-theorem/shared';
import {
  addDoc,
  deleteDoc,
  filterUndefined,
  findProp,
  getDoc,
  getEnumValues,
  isReffable,
  multiFilter,
  multiSortBy$,
  multiSwitchMap,
  nameSorter,
  query$,
  snapshot,
  undeletedQuery,
  type WithRef,
  type WithRefOrUnsaved,
} from '@principle-theorem/shared';
import { compact, sortBy } from 'lodash';
import { type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TemplateCollectionResolver } from '../template-collection-resolver';

interface IAutomationTemplatePair {
  template: WithRef<ITemplateDefinition>;
  automation: WithRef<IAutomatedNotificationConfiguration>;
}

@Component({
  selector: 'pr-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateListComponent {
  trackByTemplate = TrackByFunctions.ref<WithRef<ITemplateDefinition>>();
  templateCol$: Observable<CollectionReference<ITemplateDefinition>>;
  noTemplates$: Observable<boolean>;
  breadcrumbs$: Observable<IBreadcrumb[]>;
  customTemplates$: Observable<
    IMatSelectGroupOptions<TemplateScope, WithRef<ITemplateDefinition>>
  >;
  systemTemplates$: Observable<WithRefOrUnsaved<ITemplateDefinition>[]>;
  automationPairs$: Observable<IAutomationTemplatePair[]>;
  trackByAutomationPair =
    TrackByFunctions.ref<IAutomationTemplatePair>('template.ref');

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _currentBrand: CurrentBrandScope,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.templateCol$ = this._route.data.pipe(
      findProp<CollectionReference<ITemplateDefinition>>(
        TemplateCollectionResolver.resolverKey
      ),
      filterUndefined()
    );

    const templates$ = this.templateCol$.pipe(
      switchMap((templateCol) => query$(undeletedQuery(templateCol))),
      multiSortBy$(nameSorter())
    );

    this.systemTemplates$ = this._currentBrand.doc$.pipe(
      filterUndefined(),
      switchMap((brand) => SystemTemplates.resolveSystemTemplates$(brand.ref))
    );

    this.automationPairs$ = templates$.pipe(
      multiSwitchMap(async (template) => {
        if (!template.automatedConfigurationRef) {
          return undefined;
        }
        return {
          template,
          automation: await getDoc(template.automatedConfigurationRef),
        };
      }),
      map((pairs) => compact(pairs))
    );

    this.customTemplates$ = templates$.pipe(
      multiFilter((template) => !template.automatedConfigurationRef),
      map((templates) =>
        getEnumValues(TemplateScope).map((scope) => ({
          group: scope,
          items: sortBy(
            templates
              .filter(
                (template) =>
                  !SystemTemplates.isSystemDocUid(template.ref.id) &&
                  template.scope === scope
              )
              .map((template) => ({
                label: template.name,
                value: template,
              })),
            'label'
          ),
        }))
      ),
      multiFilter((group) => group.items.length > 0),
      map((options) => matSelectGroupOptions(options))
    );

    this.noTemplates$ = templates$.pipe(
      multiFilter(
        (template) => !SystemTemplates.isSystemDocUid(template.ref.id)
      ),
      map((templates) => templates.length === 0)
    );

    this.breadcrumbs$ = this._currentBrand.doc$.pipe(
      filterUndefined(),
      map((brand) => [
        { label: 'Settings', path: '../../../' },
        { label: brand.name },
        { label: 'Templates' },
      ])
    );
  }

  async create(): Promise<void> {
    const brand: WithRef<IBrand> = await this._currentBrand.toPromise();
    const template: ITemplateDefinition = TemplateDefinition.init({
      name: 'New Template',
      ownerScope: brand.ref,
    });
    const ref = await addDoc(await snapshot(this.templateCol$), template);
    await this._redirectTo(ref);
  }

  async delete(template: WithRef<ITemplateDefinition>): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Template',
      prompt: 'Are you sure you want to delete this template?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }
    await deleteDoc(template.ref);
    this._snackBar.open('Template Deleted');
  }

  templateLink(template: WithRefOrUnsaved<ITemplateDefinition>): string[] {
    const uid = isReffable(template)
      ? template.ref.id
      : template.unsavedDocPointer.uid;
    if (!uid) {
      return [];
    }
    return ['./', uid];
  }

  private async _redirectTo(ref: DocumentReference): Promise<void> {
    const path: string[] = ['.', ref.id];
    const relativeTo: ActivatedRoute = this._route;
    await this._router.navigate(path, { relativeTo });
  }
}
