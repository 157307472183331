<form class="flex grow flex-col gap-4" [formGroup]="form">
  <mat-form-field subscriptSizing="dynamic">
    <mat-label>Title</mat-label>
    <input #autoFocusElement matInput formControlName="title" />
  </mat-form-field>

  <mat-form-field subscriptSizing="dynamic">
    <mat-label>Number of columns</mat-label>
    <input
      matInput
      type="number"
      formControlName="numberOfColumns"
      [min]="1"
      [max]="3"
    />
    <mat-hint>Max 3</mat-hint>
  </mat-form-field>
</form>
