import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IEmailFormData,
  ISystemTemplateInteractionDialogData,
  PhoneComponent,
  SystemEmailDialogComponent,
  SystemSmsDialogComponent,
} from '@principle-theorem/ng-interactions';
import { CurrentPracticeScope } from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  INgSharedConfig,
  NG_SHARED_CONFIG,
} from '@principle-theorem/ng-shared';
import {
  Appointment,
  GapContextBuilder,
  Patient,
  ScopeDataBuilder,
  TypesenseWaitList,
  WaitListScore,
  hasValidEmail,
  toMention,
} from '@principle-theorem/principle-core';
import {
  CandidateOfferType,
  IAppointment,
  ICandidateCalendarEvent,
  IInteractionData,
  IInteractionV2,
  IPatient,
  IScheduleSummaryEventable,
  ITypesenseWaitListWithRef,
  MentionResourceType,
  SystemTemplate,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  WithRef,
  asDocRef,
  filterUndefined,
  isEnumValue,
  snapshot,
} from '@principle-theorem/shared';
import { first, uniq } from 'lodash';
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GapCandidateShortlistActionsService } from '../../gap-candidate-shortlist-actions.service';

@Component({
  selector: 'pr-gap-shortlist-item',
  templateUrl: './gap-shortlist-item.component.html',
  styleUrls: ['./gap-shortlist-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GapShortlistItemComponent {
  private _sharedConfig: INgSharedConfig = inject(NG_SHARED_CONFIG);
  private _dialog = inject(MatDialog);
  private _snackBar = inject(MatSnackBar);
  private _practiceScope = inject(CurrentPracticeScope);
  gapCandidate$ = new ReplaySubject<WithRef<ICandidateCalendarEvent>>(1);
  gap$ = new ReplaySubject<IScheduleSummaryEventable>(1);
  score$: Observable<number>;
  patient$: Observable<WithRef<IPatient>>;
  appointment$: Observable<ITypesenseWaitListWithRef>;
  disableCall$: Observable<boolean>;
  disableSms$: Observable<boolean>;
  disabledEmail$: Observable<boolean>;

  @Input()
  set gap(gap: IScheduleSummaryEventable) {
    if (gap) {
      this.gap$.next(gap);
    }
  }

  @Input()
  set gapCandidate(candidate: WithRef<ICandidateCalendarEvent>) {
    if (candidate) {
      this.gapCandidate$.next(candidate);
    }
  }

  constructor(private _candidateActions: GapCandidateShortlistActionsService) {
    this.appointment$ = this.gapCandidate$.pipe(
      switchMap((candidate) => Firestore.doc$(candidate.candidate.appointment)),
      switchMap((appointment) =>
        TypesenseWaitList.fromAppointment(appointment)
      ),
      filterUndefined(),
      map((appointment) => ({
        ...appointment,
        ref: asDocRef<IAppointment>(appointment.ref),
      }))
    );
    this.patient$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.patient(appointment))
    );
    this.score$ = combineLatest([this.appointment$, this.gap$]).pipe(
      map(([appointment, gap]) =>
        WaitListScore.getMatchScore(appointment, gap.event)
      )
    );
    this.disableCall$ = this.patient$.pipe(
      map((patient) => !first(patient.contactNumbers))
    );
    this.disableSms$ = this.patient$.pipe(
      switchMap((patient) => Patient.getMobileNumber(patient)),
      map((mobileNumber) => !mobileNumber)
    );
    this.disabledEmail$ = this.patient$.pipe(
      map((patient) => !hasValidEmail(patient))
    );
  }

  async openWaitlistConfiguration(): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    await this._candidateActions.openWaitlistConfiguration(appointment);
  }

  async showSchedulingNotes(): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    const patient = await snapshot(this.patient$);
    this._candidateActions.showSchedulingNotes(appointment, patient);
  }

  async confirmForGap(): Promise<void> {
    const candidate = await snapshot(this.gapCandidate$);
    const gap = await snapshot(this.gap$);
    await this._candidateActions.openMoveAppointment(candidate, gap);
  }

  async removeFromShortlist(): Promise<void> {
    const candidate = await snapshot(this.gapCandidate$);
    await this._candidateActions.removeCandidateFromShortlist(candidate);
  }

  async sendPhoneOffer(): Promise<void> {
    const patient = await snapshot(this.patient$);
    const interaction = await this._dialog
      .open<PhoneComponent, IInteractionData, IInteractionV2>(
        PhoneComponent,
        DialogPresets.medium({
          data: {
            contact: toMention(patient, MentionResourceType.Patient),
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!interaction) {
      return;
    }
    const gapCandidate = await snapshot(this.gapCandidate$);
    await this._patchGapCandidate(gapCandidate, CandidateOfferType.Phone);
  }

  async sendSMSOffer(): Promise<void> {
    const gapCandidate = await snapshot(this.gapCandidate$);
    const data = await this._getSendOfferData(
      gapCandidate,
      SystemTemplate.GapOfferSMS
    );
    if (!data) {
      return;
    }

    const result = await this._dialog
      .open<
        SystemSmsDialogComponent,
        ISystemTemplateInteractionDialogData,
        IInteractionV2
      >(
        SystemSmsDialogComponent,
        DialogPresets.medium({
          data,
        })
      )
      .afterClosed()
      .toPromise();
    if (!result) {
      return;
    }

    await this._patchGapCandidate(gapCandidate, CandidateOfferType.Sms);
  }

  async sendEmailOffer(): Promise<void> {
    const gapCandidate = await snapshot(this.gapCandidate$);
    const data = await this._getSendOfferData(
      gapCandidate,
      SystemTemplate.GapOfferEmail
    );
    if (!data) {
      return;
    }
    const result = await this._dialog
      .open<
        SystemEmailDialogComponent,
        ISystemTemplateInteractionDialogData,
        IEmailFormData
      >(
        SystemEmailDialogComponent,
        DialogPresets.medium({
          data,
        })
      )
      .afterClosed()
      .toPromise();
    if (!result) {
      return;
    }

    await this._patchGapCandidate(gapCandidate, CandidateOfferType.Email);
  }

  hasBeenContacted$(
    gapCandidate: WithRef<ICandidateCalendarEvent>,
    offerType: string
  ): Observable<ThemePalette> {
    return of(
      !isEnumValue(CandidateOfferType, offerType)
        ? undefined
        : gapCandidate.offerTypes?.includes(offerType)
          ? 'primary'
          : undefined
    );
  }

  private async _getSendOfferData(
    gapCandidate: WithRef<ICandidateCalendarEvent>,
    systemTemplate: SystemTemplate
  ): Promise<ISystemTemplateInteractionDialogData | undefined> {
    const patient = await Firestore.getDoc(gapCandidate.candidate.patient.ref);
    const patientContactDetails = await Patient.resolveContactDetails(
      patient.ref
    );
    if (!patientContactDetails) {
      this._snackBar.open('Cannot resolve patient contact details');
      return;
    }

    const scopeData = await ScopeDataBuilder.buildGapScopeData(
      gapCandidate,
      this._sharedConfig.appUrl
    );
    if (!scopeData) {
      throw new Error('No scopeData found on gap candidate');
    }
    const context = new GapContextBuilder(scopeData).build();
    const practice = await snapshot(
      this._practiceScope.doc$.pipe(filterUndefined())
    );

    return {
      scopeData,
      context,
      patient,
      practice,
      systemTemplate,
    };
  }

  private async _patchGapCandidate(
    gapCandidate: WithRef<ICandidateCalendarEvent>,
    offerType: CandidateOfferType
  ): Promise<void> {
    await Firestore.patchDoc(gapCandidate.ref, {
      offerMade: true,
      offerTypes: uniq([...(gapCandidate.offerTypes ?? []), offerType]),
    });
  }
}
