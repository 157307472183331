import { MaterialDesignFrameworkModule } from '@ajsf/material';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  NgInteractionsModule,
  PrincipleEditorModule,
} from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { CustomFormComponent } from './components/custom-form/custom-form.component';
import { PreviewFormIssueDialogComponent } from './components/custom-form/preview-form-issue-dialog/preview-form-issue-dialog.component';
import { PreviewFormIssueComponent } from './components/custom-form/preview-form-issue-dialog/preview-form-issue/preview-form-issue.component';
import { CustomFormDatePickerWidgetComponent } from './components/custom-form/widgets/date-picker/custom-form-date-picker-widget.component';
import { CustomFormEditorContentWidgetComponent } from './components/custom-form/widgets/editor/custom-form-editor-content-widget.component';
import { CustomFormSignatureWidgetComponent } from './components/custom-form/widgets/signature/custom-form-signature-widget.component';
import { CustomFormTextareaWidgetComponent } from './components/custom-form/widgets/textarea/custom-form-textarea-widget.component';
import { PatientFormDialogComponent } from './components/form-dialog/patient-form-dialog.component';
import { FormStatusComponent } from './components/form-status/form-status.component';
import { FormsDashboardComponent } from './components/forms-dashboard/forms-dashboard.component';
import { MedicalAlertsPreviewComponent } from './components/medical-alerts-preview/medical-alerts-preview.component';
import { MedicalHistoryAlertsConfigureComponent } from './components/medical-history-alerts-configure/medical-history-alerts-configure.component';
import { PatientDetailsCompareFieldComponent } from './components/patient-details-form-compare/patient-details-compare-field/patient-details-compare-field.component';
import { PatientDetailsFormCompareComponent } from './components/patient-details-form-compare/patient-details-form-compare.component';
import { PatientDetailsFormDisplayComponent } from './components/patient-details-form-display/patient-details-form-display.component';
import { NationalHealthIndexNumberFormComponent } from './components/patient-details-form/national-health-index-number-form/national-health-index-number-form.component';
import { PatientDetailsDvaFormComponent } from './components/patient-details-form/patient-details-dva-form/patient-details-dva-form.component';
import { PatientDetailsFormComponent } from './components/patient-details-form/patient-details-form.component';
import { PatientDetailsHealthFundFormComponent } from './components/patient-details-form/patient-details-health-fund-form/patient-details-health-fund-form.component';
import { PatientDetailsMedicareFormComponent } from './components/patient-details-form/patient-details-medicare-form/patient-details-medicare-form.component';
import { PatientFormPageComponent } from './components/patient-form-page/patient-form-page.component';
import { PatientFormPrintComponent } from './components/patient-form-print/patient-form-print.component';
import { PatientFormSelectorComponent } from './components/patient-form-selector/patient-form-selector.component';
import { PatientFormUpdateComponent } from './components/patient-form-update/patient-form-update.component';
import { PatientFormActionsComponent } from './components/patient-form/patient-form-actions/patient-form-actions.component';
import { PatientFormConfirmButtonComponent } from './components/patient-form/patient-form-confirm-button/patient-form-confirm-button.component';
import { PatientFormContentComponent } from './components/patient-form/patient-form-content/patient-form-content.component';
import { PatientFormToolbarComponent } from './components/patient-form/patient-form-toolbar/patient-form-toolbar.component';
import { PatientFormsTabComponent } from './components/patient-forms-tab/patient-forms-tab.component';
import { PatientMedicalAlertDetailsComponent } from './components/patient-medical-alert-details/patient-medical-alert-details.component';
import { PatientSubmittedFormsListComponent } from './components/patient-submitted-forms-list/patient-submitted-forms-list.component';
import { PatientCustomFormsService } from './patient-custom-forms.service';
import { PatientFormsDialogComponent } from './components/patient-forms-dialog/patient-forms-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    MaterialDesignFrameworkModule,
    PrincipleEditorModule,
    NgMaterialModule,
    FormStatusComponent,
    PreviewFormIssueComponent,
    PatientFormSelectorComponent,
    PatientFormConfirmButtonComponent,
    NationalHealthIndexNumberFormComponent,
    NgInteractionsModule,
  ],
  declarations: [
    PatientFormUpdateComponent,
    CustomFormComponent,
    PatientFormsTabComponent,
    PatientFormPrintComponent,
    FormsDashboardComponent,
    PatientSubmittedFormsListComponent,
    PatientDetailsFormComponent,
    PatientDetailsFormCompareComponent,
    PatientFormDialogComponent,
    PatientDetailsCompareFieldComponent,
    PatientDetailsMedicareFormComponent,
    PatientDetailsHealthFundFormComponent,
    PatientDetailsDvaFormComponent,
    PatientMedicalAlertDetailsComponent,
    MedicalAlertsPreviewComponent,
    MedicalHistoryAlertsConfigureComponent,
    CustomFormSignatureWidgetComponent,
    CustomFormEditorContentWidgetComponent,
    CustomFormTextareaWidgetComponent,
    CustomFormDatePickerWidgetComponent,
    PreviewFormIssueDialogComponent,
    PatientDetailsFormDisplayComponent,
    PatientFormPageComponent,
    PatientFormToolbarComponent,
    PatientFormActionsComponent,
    PatientFormContentComponent,
    PatientFormsDialogComponent,
  ],
  exports: [
    PatientFormUpdateComponent,
    CustomFormComponent,
    PatientSubmittedFormsListComponent,
    PatientDetailsFormComponent,
    PatientMedicalAlertDetailsComponent,
    MedicalAlertsPreviewComponent,
    MedicalHistoryAlertsConfigureComponent,
    NationalHealthIndexNumberFormComponent,
    PatientFormsDialogComponent,
  ],
  providers: [PatientCustomFormsService],
})
export class NgCustomFormsModule {}
