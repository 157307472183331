import { Injectable } from '@angular/core';
import {
  TimelineNavigationService,
  type IContextualAction,
  type IRoutable,
} from '@principle-theorem/ng-principle-shared';
import {
  isPrincipleMention,
  type IAppointment,
  type IInteractiveResource,
  type IPrincipleMention,
} from '@principle-theorem/principle-core/interfaces';
import { Firestore, asDocRef, type IReffable } from '@principle-theorem/shared';

@Injectable()
export class LoadTimelineActionService implements IContextualAction {
  icon = 'today';
  name = 'Show on Timeline';

  constructor(private _timelineNav: TimelineNavigationService) {}

  async do(
    reffable: IRoutable | IReffable,
    _interactiveResource?: IInteractiveResource,
    templateResource?: IPrincipleMention
  ): Promise<void> {
    if (!isPrincipleMention(reffable)) {
      return;
    }

    const appointment = await Firestore.getDoc(
      asDocRef<IAppointment>(
        templateResource?.resource.ref ?? reffable.resource.ref
      )
    );

    await this._timelineNav.showOnTimeline(appointment);
  }
}
