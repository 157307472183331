@if (automation$ | async; as data) {
  @if (hasAlreadyRun$ | async) {
    @if (triggerDate$ | async; as triggerDate) {
      <div
        class="text-xs opacity-50"
        [ngClass]="{
          'text-primary-500': (hasFailed$ | async) === false,
          'text-warn-500': hasFailed$ | async
        }"
      >
        {{ data.automation.status | titlecase }} On:
        {{ triggerDate | moment | amDateFormat: dateFormat }}
      </div>
    }
  } @else if ((isNotGoingToRun$ | async) === false) {
    @if (data.newDate) {
      @if (customMessage$ | async; as message) {
        <div class="text-xs opacity-50" [matTooltip]="message.tooltip">
          {{ message.message }}
        </div>
      } @else {
        @if (triggerDate$ | async; as triggerDate) {
          <div
            class="text-xs opacity-50"
            matTooltip="New trigger date calculated from new event time"
          >
            {{ triggerDate | moment | amDateFormat: dateFormat }}
          </div>
        }
      }
    } @else {
      <div class="text-xs opacity-50">Missing New Event Date</div>
    }
  }
}
