import { Injectable, inject } from '@angular/core';
import { FirebaseFunctionsService } from '@principle-theorem/ng-firebase';
import {
  IPatientFormsTokenData,
  PatientAction,
  PatientActionRequest,
  ITokenRequest,
  PATIENT_PORTAL_DISPATCHER,
  IPatientFormSchema,
  ISubmitPatientFormRequest,
  ISubmitPatientDetailsFormRequest,
  IPatient,
  IConfirmAppointmentRequest,
  IConfirmAppointmentInfo,
} from '@principle-theorem/principle-core/interfaces';
import {
  SerialisedData,
  unserialise,
  serialise,
} from '@principle-theorem/shared';

@Injectable()
export class PatientPortalFunctionsService {
  private _functions = inject(FirebaseFunctionsService);

  async getPatientConfirmAppointmentInfo(
    tokenUid: string
  ): Promise<IConfirmAppointmentInfo> {
    const payload = {
      action: PatientAction.GetPatientConfirmAppointmentInfo,
      data: { tokenUid },
    };

    const response = await this._functions.call<
      PatientActionRequest<IConfirmAppointmentRequest>,
      SerialisedData<IConfirmAppointmentInfo>
    >(PATIENT_PORTAL_DISPATCHER, payload);

    return unserialise(response);
  }

  async getPatientFormsData(tokenUid: string): Promise<IPatientFormsTokenData> {
    const payload = {
      action: PatientAction.GetPatientFormsData,
      data: { tokenUid },
    };
    const response = await this._functions.call<
      PatientActionRequest<ITokenRequest>,
      SerialisedData<IPatientFormsTokenData>
    >(PATIENT_PORTAL_DISPATCHER, payload);
    return unserialise(response);
  }

  async submitPatientForm(
    tokenUid: string,
    formUid: string,
    data: IPatientFormSchema
  ): Promise<void> {
    await this._functions.call<PatientActionRequest<ISubmitPatientFormRequest>>(
      PATIENT_PORTAL_DISPATCHER,
      {
        action: PatientAction.SubmitPatientForm,
        data: { tokenUid, formUid, data },
      }
    );
  }

  async submitPatientDetailsForm(
    tokenUid: string,
    data: IPatientFormSchema<Partial<IPatient>>
  ): Promise<void> {
    await this._functions.call<
      PatientActionRequest<SerialisedData<ISubmitPatientDetailsFormRequest>>
    >(PATIENT_PORTAL_DISPATCHER, {
      action: PatientAction.SubmitPatientDetailsForm,
      data: serialise({ tokenUid, data }),
    });
  }
}
