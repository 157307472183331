import { Patient, ReferralSource } from '@principle-theorem/principle-core';
import {
  Gender,
  IContact,
  IDestinationEntityJobRunOptions,
  IHealthFundCard,
  IReferralSource,
  IReferralSourceConfiguration,
  ITranslationMap,
  PatientStatus,
  SourceEntityRecordStatus,
  type FailedDestinationEntityRecord,
  type IBrand,
  type IContactNumber,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IMedicareCard,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  ISO_DATE_FORMAT,
  getError,
  snapshotCombineLatest,
  toISODate,
  type WithRef,
} from '@principle-theorem/shared';
import * as he from 'he';
import { compact, first, sortBy } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  BasePatientDestinationEntity,
  IPatientMigrationData,
  PATIENT_RESOURCE_TYPE,
} from '../../../destination/entities/patient';
import { PracticeMigration } from '../../../practice-migrations';
import { type TranslationMapHandler } from '../../../translation-map';
import { ContactSourceEntity } from '../../source/entities/contacts';
import {
  ICorePracticePatientInsuranceDetail,
  PatientInsuranceDetailSourceEntity,
} from '../../source/entities/patient-insurance-details';
import {
  PatientSourceEntity,
  type ICorePracticePatient,
  type ICorePracticePatientFilters,
  type ICorePracticePatientTranslations,
} from '../../source/entities/patients';
import { PROVIDER_RESOURCE_TYPE } from '../../source/entities/providers';
import { CorePracticeContactMappingHandler } from '../mappings/contact-to-referral-source';
import { CorePracticeStafferMappingHandler } from '../mappings/staff';

export const PATIENT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_RESOURCE_TYPE,
    label: 'Patients',
    description: '',
  },
});

export interface IPatientJobData {
  sourcePatient: IGetRecordResponse<
    ICorePracticePatient,
    ICorePracticePatientTranslations,
    ICorePracticePatientFilters
  >;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  brand: WithRef<IBrand>;
  referralSources: WithRef<ITranslationMap<IReferralSourceConfiguration>>[];
  contacts: WithRef<ITranslationMap<IContact>>[];
}

export class PatientDestinationEntity extends BasePatientDestinationEntity<ICorePracticePatient> {
  destinationEntity = PATIENT_DESTINATION_ENTITY;
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    contacts: new ContactSourceEntity(),
    patientInsuranceDetails: new PatientInsuranceDetailSourceEntity(),
  };

  customMappings = {
    staff: new CorePracticeStafferMappingHandler(),
    referralSources: new CorePracticeContactMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords(translationMap),
      translationMap.getByType<IStaffer>(PROVIDER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const brand$ = PracticeMigration.brand$(migration);
    const referralSources$ =
      this.customMappings.referralSources.getRecords(translationMap);
    const contacts$ = translationMap.getByType<IContact>(
      this.sourceEntities.contacts.sourceEntity.metadata.idPrefix
    );

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),

      snapshotCombineLatest([staff$, brand$, referralSources$, contacts$]),
    ]).pipe(
      map(([patientRecords, [staff, brand, referralSources, contacts]]) =>
        patientRecords.map((sourcePatient) => ({
          sourcePatient,
          staff,
          brand,
          referralSources,
          contacts,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IPatientJobData
  ): Promise<
    | IPatientMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    if (data.sourcePatient.record.status === SourceEntityRecordStatus.Invalid) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        'Source patient is invalid'
      );
    }

    const patient = data.sourcePatient.data.data;
    const sourcePatientId =
      this.sourceEntities.patients.getSourceRecordId(patient);

    const insuranceDetails =
      await this.sourceEntities.patientInsuranceDetails.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

    const referrer = await this.getReferralSource(
      patient,
      data.referralSources,
      data.contacts
    );

    try {
      return {
        sourcePatientId: sourcePatientId.toString(),
        createdAt: data.sourcePatient.data.translations.joinDate,
        patient: Patient.init({
          name: getName(patient),
          email: patient.email,
          contactNumbers: getPhone(patient),
          address: getAddress(patient),
          dateOfBirth: patient.dateOfBirth
            ? toISODate(moment.utc(patient.dateOfBirth, ISO_DATE_FORMAT))
            : undefined,
          gender: getGender(patient),
          status: getStatus(patient),
          medicareCard: getMedicareCard(patient),
          referenceId: sourcePatientId.toString(),
          healthFundCard: getHealthcareCard(insuranceDetails),
          referrer,
        }),
      };
    } catch (error) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        getError(error)
      );
    }
  }

  async getReferralSource(
    patient: ICorePracticePatient,
    referralSources: WithRef<ITranslationMap<IReferralSourceConfiguration>>[],
    contacts: WithRef<ITranslationMap<IContact>>[]
  ): Promise<IReferralSource | undefined> {
    if (!patient.referralSourceId) {
      return;
    }

    const referralSourceMap = referralSources.find(
      (referralSource) =>
        referralSource.sourceIdentifier === patient.referralSourceId?.toString()
    );
    if (referralSourceMap?.destinationIdentifier) {
      const referralSource = await Firestore.getDoc(
        referralSourceMap.destinationIdentifier
      );
      return ReferralSource.toReferrer(referralSource);
    }

    const matchingContact = contacts.find(
      (contact) =>
        contact.sourceIdentifier === patient.referralSourceId?.toString()
    );
    if (!matchingContact?.destinationIdentifier) {
      return;
    }

    return ReferralSource.toReferrer(
      await Firestore.getDoc(matchingContact.destinationIdentifier)
    );
  }
}

function getGender(patient: ICorePracticePatient): Gender {
  switch (patient.sex) {
    case 'F':
      return Gender.Female;
    case 'M':
      return Gender.Male;
    case 'O':
      return Gender.Other;
    default:
      return Gender.NotSpecified;
  }
}

function getStatus(patient: ICorePracticePatient): PatientStatus {
  if (!patient.isActive || patient.isDeleted) {
    return PatientStatus.Inactive;
  }
  return PatientStatus.Active;
}

function getAddress(patient: ICorePracticePatient): string {
  const addressFields = compact([
    patient.addressLine1?.trim(),
    patient.addressLine2?.trim(),
    patient.suburb?.trim(),
    patient.state?.trim(),
    patient.country?.trim(),
    patient.postcode?.toString().trim(),
  ]);

  if (!addressFields.length) {
    return '';
  }

  return addressFields.join(', ');
}

function getPhone(patient: ICorePracticePatient): IContactNumber[] {
  const contactNumbers: IContactNumber[] = [
    { label: 'mobile', number: patient.mobile ?? '' },
    { label: 'home', number: patient.homePhone ?? '' },
    { label: 'work', number: patient.workPhone ?? '' },
  ];
  return contactNumbers.filter((contactNumber) => !!contactNumber.number);
}

export function getName(patient: ICorePracticePatient): string {
  const preferredName = patient.preferredName?.trim() ?? '';

  if (!preferredName || preferredName === patient.firstName) {
    return he.decode(`${patient.firstName} ${patient.lastName}`.trim());
  }
  return he.decode(
    `${patient.firstName} (${preferredName}) ${patient.lastName}`.trim()
  );
}

function getMedicareCard(patient: ICorePracticePatient): IMedicareCard {
  return {
    number: patient.medicareNo ?? '',
    subNumerate: '',
  };
}

function getHealthcareCard(
  cards: IGetRecordResponse<ICorePracticePatientInsuranceDetail>[]
): IHealthFundCard {
  const healthFundCard = first(
    sortBy(
      cards.filter((insuranceDetail) => !!insuranceDetail.data.data.memberCode),
      'id',
      'desc'
    )
  );
  return {
    fundCode: healthFundCard?.data.data.insuranceName ?? '',
    membershipNumber: healthFundCard?.data.data.memberCode ?? '',
    memberNumber: '',
  };
}
