<mat-list *ngIf="medicareCard; else noDetails">
  <mat-list-item>
    <span matListItemTitle>{{ medicareCard.number }}</span>
    <span matListItemLine>Number</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle>{{ medicareCard.subNumerate }}</span>
    <span matListItemLine>Sub Numerate</span>
  </mat-list-item>
  <mat-list-item>
    <span matListItemTitle *ngIf="medicareCard.expiryDate">{{
      medicareCard.expiryDate | moment | amDateFormat : dateFormat
    }}</span>
    <span matListItemLine>Expiry Date</span>
  </mat-list-item>
</mat-list>

<ng-template #noDetails>
  <p class="mat-caption layout-margin">No Card Details</p>
</ng-template>

<pt-buttons-container padding="all-round">
  <button *ngIf="medicareCard" mat-stroked-button (click)="remove.emit()">
    Remove
  </button>
  <button mat-flat-button color="primary" (click)="edit.emit()">
    {{ medicareCard ? 'Edit' : 'Add' }} Card Details
  </button>
</pt-buttons-container>
