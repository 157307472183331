import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  CurrentScopeFacade,
  ManagementService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  type IBreadcrumb,
} from '@principle-theorem/ng-shared';
import {
  Brand,
  RestrictAccess,
  Staffer,
} from '@principle-theorem/principle-core';
import { ReportingPermissions } from '@principle-theorem/principle-core/features';
import {
  RestrictAccessEntity,
  type ICustomReport,
  type IStaffer,
  IManagementUser,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  multiSortBy$,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-custom-reports',
  templateUrl: './custom-reports.component.html',
  styleUrls: ['./custom-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomReportsComponent {
  readonly dateFormat = DAY_MONTH_YEAR_FORMAT;
  customReports$: Observable<WithRef<ICustomReport>[]>;
  trackByCustomReport = TrackByFunctions.ref<WithRef<ICustomReport>>();
  reportBuilderPermission = ReportingPermissions.ReportingReportBuilder;
  createCustomReportsPermission =
    ReportingPermissions.ReportingCustomReportEdit;
  breadcrumbs: IBreadcrumb[] = [
    { label: 'Reporting' },
    { label: 'Custom Reports' },
  ];

  constructor(
    private _scope: CurrentScopeFacade,
    private _organisation: OrganisationService,
    private _management: ManagementService
  ) {
    const customReports$ = this._scope.currentBrand$.pipe(
      switchMap((brand) => (brand ? Brand.customReports$(brand) : of([]))),
      multiSortBy$((report) => report.name)
    );

    const permissions$ = combineLatest([
      this._organisation.staffer$,
      this._management.user$,
    ]).pipe(
      switchMap(([staffer, managementUser]) =>
        this._getCustomReportPermissions$(staffer, managementUser)
      )
    );

    this.customReports$ = combineLatest([customReports$, permissions$]).pipe(
      map(([reports, permissions]) => this._filterReports(reports, permissions))
    );
  }

  private _getCustomReportPermissions$(
    staffer?: WithRef<IStaffer>,
    managementUser?: IManagementUser
  ): Observable<ICustomReportPermissions> {
    const canEdit$ = this._canEditCustomReports$(staffer, managementUser);
    const accessEntities$ = staffer
      ? RestrictAccess.getAccessEntities$(staffer)
      : of([]);
    return combineLatest([canEdit$, accessEntities$]).pipe(
      map(([canEdit, accessEntities]) => ({ canEdit, accessEntities }))
    );
  }

  private _canEditCustomReports$(
    staffer?: WithRef<IStaffer>,
    managementUser?: IManagementUser
  ): Observable<boolean> {
    if (managementUser) {
      return of(true);
    }
    if (!staffer) {
      return of(false);
    }
    return Staffer.hasPermission$(
      staffer,
      ReportingPermissions.ReportingCustomReportEdit
    );
  }

  private _filterReports(
    reports: WithRef<ICustomReport>[],
    permissions: ICustomReportPermissions
  ): WithRef<ICustomReport>[] {
    return reports.filter(
      (report) =>
        permissions.canEdit ||
        RestrictAccess.hasAccess(
          report.restrictAccessTo,
          permissions.accessEntities
        )
    );
  }
}

interface ICustomReportPermissions {
  canEdit: boolean;
  accessEntities: RestrictAccessEntity[];
}
