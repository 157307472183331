import { type RawInlineNodes } from '@principle-theorem/editor';
import { type IFirestoreModel } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { type IInteraction } from '../interaction/interaction';

export interface IWorkflowItem extends IFirestoreModel {
  title: RawInlineNodes;
  dueDate?: Timestamp;
  completedDate?: Timestamp;
  interactions: IInteraction[];
}
