import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AuthFirebaseFunctionsService } from '@principle-theorem/ng-auth';
import { type IBreadcrumb } from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IOrganisation,
  type IRole,
  type IUser,
} from '@principle-theorem/principle-core/interfaces';
import { Organisation } from '@principle-theorem/principle-core';
import {
  filterUndefined,
  findProp,
  resolveProp$,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-org-user-edit',
  templateUrl: './org-user-edit.component.html',
  styleUrls: ['./org-user-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgUserEditComponent {
  breadcrumbs$: Observable<IBreadcrumb[]>;
  user$: Observable<WithRef<IUser>>;
  organisation$: Observable<WithRef<IOrganisation>>;
  brands$: Observable<WithRef<IBrand>[]>;
  roles$: Observable<WithRef<IRole>[]>;

  constructor(
    private _route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _functions: AuthFirebaseFunctionsService
  ) {
    this.organisation$ = this._route.data.pipe(
      findProp<WithRef<IOrganisation>>('organisation'),
      filterUndefined()
    );

    this.brands$ = this.organisation$.pipe(
      switchMap((organisation) => Organisation.brands$(organisation))
    );

    this.roles$ = this.organisation$.pipe(
      switchMap((organisation) => Organisation.roles$(organisation))
    );

    this.user$ = resolveProp$<WithRef<IUser>>(this._route.data, 'user');

    this.breadcrumbs$ = combineLatest([this.organisation$, this.user$]).pipe(
      map(([organisation, user]) => [
        { label: 'Organisations', path: '../../../' },
        { label: organisation.name, path: '../../' },
        { label: 'Users', path: '../' },
        { label: user.name },
      ])
    );
  }

  async invite(user: WithRef<IUser>): Promise<void> {
    const organisation: WithRef<IOrganisation> = await snapshot(
      this.organisation$
    );
    await this._functions.sendOrganisationInviteEmail(organisation, user);
    this._snackBar.open('Invitation Email Sent');
  }
}
