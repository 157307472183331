import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
  BasicDialogService,
  isDisabled$,
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  ChartedTreatment,
  MockFeeSchedule,
  TreatmentConfiguration,
} from '@principle-theorem/principle-core';
import {
  ChartableSurface,
  ServiceCodeGroupType,
  type IChartedTreatment,
  type IPricedServiceCodeEntry,
  type IScopedServiceCode,
  type ITreatmentConfiguration,
  type ITreatmentPackage,
  type ITreatmentPackagePriceOverride,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { MockWithRef } from '@principle-theorem/testing';
import { combineLatest, type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

export type TreatmentPackageForm = Omit<ITreatmentPackage, 'uid'>;

@Component({
    selector: 'pr-edit-treatment-package',
    templateUrl: './edit-treatment-package.component.html',
    styleUrls: ['./edit-treatment-package.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditTreatmentPackageComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  treatmentPackage$ = new ReplaySubject<ITreatmentPackage>(1);
  treatmentConfig$ = new ReplaySubject<WithRef<ITreatmentConfiguration>>(1);
  treatment$: Observable<IChartedTreatment>;
  serviceCodes$: Observable<IPricedServiceCodeEntry[]>;
  form = new TypedFormGroup<TreatmentPackageForm>({
    name: new TypedFormControl(''),
    description: new TypedFormControl(''),
    isDefault: new TypedFormControl(false),
    priceOverrides: new TypedFormArray<ITreatmentPackagePriceOverride>([]),
  });
  totalPrice$ = new ReplaySubject<number>(1);
  isDisabled$ = isDisabled$(this.form);
  @Output() updatePackage = new EventEmitter<TreatmentPackageForm>();
  @Output() deletePackage = new EventEmitter<void>();

  @Input()
  set treatmentPackage(treatmentPackage: ITreatmentPackage) {
    if (treatmentPackage) {
      this.treatmentPackage$.next(treatmentPackage);
    }
  }

  @Input()
  set treatmentConfig(treatmentConfig: WithRef<ITreatmentConfiguration>) {
    if (treatmentConfig) {
      this.treatmentConfig$.next(treatmentConfig);
    }
  }

  get priceOverrides(): TypedFormArray<ITreatmentPackagePriceOverride> {
    return this.form.controls
      .priceOverrides as TypedFormArray<ITreatmentPackagePriceOverride>;
  }

  constructor(private _dialog: BasicDialogService) {
    this.treatment$ = this.treatmentConfig$.pipe(
      map((treatmentConfig) =>
        ChartedTreatment.fromConfig(
          treatmentConfig,
          MockWithRef(MockFeeSchedule()),
          {
            scope: ChartableSurface.Unscoped,
          }
        )
      )
    );

    this.serviceCodes$ = this.treatmentConfig$.pipe(
      map((treatmentConfig) =>
        TreatmentConfiguration.getCombinedServiceCodes(treatmentConfig, [
          ServiceCodeGroupType.Required,
          ServiceCodeGroupType.Exclusive,
        ])
      )
    );

    combineLatest([this.serviceCodes$, this.treatmentPackage$])
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe(([serviceCodes, treatmentPackage]) => {
        const priceOverrides = treatmentPackage.priceOverrides;
        serviceCodes.map((serviceCode) => {
          const priceOverride = priceOverrides.find(
            (price) =>
              price.code === serviceCode.code && price.type === serviceCode.type
          );

          this.priceOverrides.push(
            new TypedFormGroup<ITreatmentPackagePriceOverride>({
              code: new TypedFormControl(serviceCode.code),
              type: new TypedFormControl(serviceCode.type),
              price: new TypedFormControl(
                priceOverride?.price ?? undefined,
                Validators.required
              ),
            })
          );
        });
        this.form.patchValue(treatmentPackage);
      });

    this.form.valueChanges
      .pipe(
        switchMap(async (form) => {
          const priceOverrides = form.priceOverrides;
          const serviceCodes = await asyncForEach(
            priceOverrides,
            async (priceOverride) => ({
              ...priceOverride,
              quantity: await this.resolveQuantity(priceOverride),
            })
          );
          return serviceCodes.reduce(
            (total, serviceCode) =>
              total + serviceCode.quantity * (serviceCode.price ?? 0),
            0
          );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((total) => this.totalPrice$.next(total));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }
    const data = this.form.getRawValue();
    this.updatePackage.emit({
      name: data.name,
      description: data.description,
      isDefault: data.isDefault ?? false,
      priceOverrides: this.priceOverrides.getRawValue(),
    });
  }

  async delete(): Promise<void> {
    const confirmed = await this._dialog.confirm({
      prompt: 'Are you sure you want to delete this package?',
      title: 'Delete',
      submitLabel: 'Yes, Delete',
      submitColor: 'warn',
      cancelLabel: 'Cancel',
    });

    if (!confirmed) {
      return;
    }

    this.deletePackage.emit();
  }

  updateServiceItems(priceOverrides: ITreatmentPackagePriceOverride[]): void {
    this.priceOverrides.patchValue(priceOverrides);
    this.form.markAsDirty();
  }

  async resolveQuantity(scopedCode: IScopedServiceCode): Promise<number> {
    const foundCode = await snapshot(
      this.serviceCodes$.pipe(
        map((serviceCodes) =>
          serviceCodes.find(
            (serviceCode) =>
              serviceCode.code === scopedCode.code &&
              serviceCode.type === scopedCode.type
          )
        )
      )
    );
    return foundCode?.quantity ?? 0;
  }
}
