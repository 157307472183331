import { BlockNodes, IMenuButton } from '@principle-theorem/editor';
import { MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../../menu-bar/basic-menu-button/basic-menu-button.component';

export function createAutocompleteButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'submenu-detail',
      buttonText: 'Autocomplete',
      tooltip: `Autocompletes the prior text`,
      shortcut: 'Tab',
      icon: 'auto_mode',
      command: (editor) => editor.chain().focus().aiComplete().focus().run(),
      isDisabledFn: (editor) => !editor.isActive(BlockNodes.Paragraph),
    },
  });
}
