import {
  IScopeRef,
  IChartedSurface,
  ServiceCodeScope,
  IADACodeRules,
} from '@principle-theorem/principle-core/interfaces';
import { IMatchesADAScope } from './base-rule-handler';
import { PerAnterior } from './per-anterior';
import { PerMultipleSurface } from './per-multiple-surface';

export class PerAnteriorMultipleSurface implements IMatchesADAScope {
  scope: ServiceCodeScope = ServiceCodeScope.PerAnteriorMultipleSurface;

  matches(
    rule: IADACodeRules,
    treatmentScope: IScopeRef,
    surfaces: IChartedSurface[]
  ): boolean {
    return (
      new PerAnterior().matches(rule, treatmentScope, surfaces) &&
      new PerMultipleSurface().matches(rule, treatmentScope, surfaces) &&
      rule.scope === this.scope
    );
  }
}
