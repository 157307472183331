<mat-form-field subscriptSizing="dynamic">
  <mat-label>Report Type</mat-label>
  <mat-select [required]="true" [formControl]="reportTypeControl">
    <mat-option
      *ngFor="let reportType of reportTypes; trackBy: trackByReportType"
      [value]="reportType.value"
    >
      {{ reportType.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
