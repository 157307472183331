import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { multiSort, sortTimestamp, toMoment } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { BehaviorSubject, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-history-list',
    templateUrl: './history-list.component.html',
    styleUrls: ['./history-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HistoryListComponent {
  private _items$ = new ReplaySubject<IHistoryItem<unknown>[]>(1);
  trackByItem = TrackByFunctions.label<IHistoryItem<unknown>>(); // TODO track by timestamp
  selected$ = new BehaviorSubject<IHistoryItem<unknown> | undefined>(undefined);
  isClosed$ = new BehaviorSubject<boolean>(true);
  sortedItems$: Observable<IHistoryItem<unknown>[]>;

  @Output()
  selectedChange = new EventEmitter<IHistoryItem<unknown> | undefined>();

  @Input()
  set selected(selected: IHistoryItem<unknown> | undefined) {
    this.selected$.next(selected);
  }

  @Input()
  set items(items: IHistoryItem<unknown>[]) {
    if (items) {
      this._items$.next(items);
    }
  }

  constructor() {
    this.sortedItems$ = this._items$.pipe(
      multiSort((aItem, bItem) =>
        sortTimestamp(aItem.timestamp, bItem.timestamp)
      )
    );
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.isClosed$.next(false);
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.isClosed$.next(true);
  }

  isSelectedItem$(item: IHistoryItem<unknown>): Observable<boolean> {
    const itemMoment = toMoment(item.timestamp);
    return this.selected$.pipe(
      map((selected) => {
        if (!selected) {
          return false;
        }
        return itemMoment.isSame(toMoment(selected.timestamp));
      })
    );
  }

  selectItem(item: IHistoryItem<unknown>): void {
    this.selectedChange.emit(item);
    this.selected$.next(item);
  }
}

export interface IHistoryItem<T> {
  label: string;
  timestamp: Timestamp;
  value: T;
}
