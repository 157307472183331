import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { ManagementPagesModule } from './management-pages.module';
import { OrgRoleResolver } from './org-role-resolver.service';
import { OrgUserEditComponent } from './org-user-edit/org-user-edit.component';
import { OrgUserResolver } from './org-user-resolver.service';
import { OrganisationEditComponent } from './organisation-edit/organisation-edit.component';
import { OrganisationResolver } from './organisation-resolver.service';
import { OrganisationsComponent } from './organisations/organisations.component';
import { RoleEditComponent } from './role-edit/role-edit.component';
import { RoleListComponent } from './role-list/role-list.component';
import { ToolsComponent } from './tools/tools.component';
import { UserListComponent } from './user-list/user-list.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'organisations',
    pathMatch: 'full',
  },
  {
    path: 'organisations',
    children: [
      {
        path: '',
        component: OrganisationsComponent,
      },
      {
        path: ':uid',
        resolve: {
          organisation: OrganisationResolver,
        },
        children: [
          {
            path: '',
            component: OrganisationEditComponent,
          },
          {
            path: 'users',
            children: [
              {
                path: '',
                component: UserListComponent,
              },
              {
                path: ':uid',
                resolve: {
                  user: OrgUserResolver,
                },
                children: [
                  {
                    path: '',
                    component: OrgUserEditComponent,
                  },
                ],
              },
            ],
          },
          {
            path: 'roles',
            children: [
              {
                path: '',
                component: RoleListComponent,
              },
              {
                path: ':uid',
                resolve: {
                  role: OrgRoleResolver,
                },
                children: [
                  {
                    path: '',
                    component: RoleEditComponent,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'migrations',
    loadChildren: () =>
      import('@principle-theorem/ng-practice-migrations').then(
        (mod) => mod.PracticeMigrationsPagesRoutingModule
      ),
  },
  {
    path: 'tools',
    component: ToolsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), ManagementPagesModule],
  exports: [RouterModule],
})
export class ManagementPagesRoutingModule {}
