import { Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Validators } from '@angular/forms';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { type TemplateChangeData } from '@principle-theorem/ng-templating';
import { ConditionLogic } from '@principle-theorem/principle-core';
import {
  AUTOMATED_NOTIFICATION_TYPES,
  AutomatedNotificationType,
  ConditionLogicConfigurationCollection,
  type IAutomatedNotificationConfiguration,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { isDocRef, type DocumentReference } from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TimingFormGroup } from '../timing.formgroup';

export type AutomatedNotificationConfigurationFormData = Pick<
  IAutomatedNotificationConfiguration,
  'name' | 'timing' | 'type' | 'subject' | 'practiceRef' | 'requiredConditions'
> & {
  template: TemplateChangeData;
};

export const AUTOMATION_CONDITIONS_HELP_TEXT = `The automation will run at the given time. If the conditions are NOT met, the automation will be skipped. If an automation has been skipped, you can run it again manually.`;

export const AUTOMATION_CUSTOM_CONFIGURATION_HELP_TEXT = `A Custom Configuration will replace the Automation Configuration for this automation. Use a Custom Configuration to tailor notifications to a patient or appointment.`;

export class AutomatedNotificationConfigurationForm extends TypedFormGroup<AutomatedNotificationConfigurationFormData> {
  showSubjectField: Signal<boolean | undefined>;
  isEmailNotification$: Observable<boolean>;

  constructor() {
    super({
      name: new TypedFormControl<string>('', Validators.required),
      timing: new TimingFormGroup(),
      type: new TypedFormControl<AutomatedNotificationType>(
        AUTOMATED_NOTIFICATION_TYPES[0]
      ),
      template: new TypedFormControl<TemplateChangeData>(
        undefined,
        Validators.required
      ),
      subject: new TypedFormControl<string>('', Validators.required),
      practiceRef: new TypedFormControl<
        DocumentReference<IPractice> | undefined
      >({
        value: undefined,
        disabled: true,
      }).withGuard(isDocRef),
      requiredConditions:
        new TypedFormControl<ConditionLogicConfigurationCollection>(
          ConditionLogic.collection([
            ConditionLogic.and([ConditionLogic.always()]),
          ])
        ),
    });

    this.isEmailNotification$ = this.controls.type.valueChanges.pipe(
      startWith(this.controls.type.value),
      map((type) => type === AutomatedNotificationType.EMAIL)
    );
    this.showSubjectField = toSignal(this.isEmailNotification$);
  }

  enableSubjectControl(enabled: boolean): void {
    if (enabled) {
      this.controls.subject.setValidators([Validators.required]);
      this.controls.subject.enable();
      this.controls.subject.updateValueAndValidity();
      return;
    }
    this.controls.subject.setValidators([]);
    this.controls.subject.disable();
    this.controls.subject.updateValueAndValidity();
  }
}
