import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IPopupNotification } from '@principle-theorem/notifications';

@Component({
  selector: 'pr-popup-notification',
  templateUrl: './popup-notification.component.html',
  styleUrl: './popup-notification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupNotificationComponent {
  @Input() notification: IPopupNotification;
}
