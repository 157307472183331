<pr-chat-header
  [compact]="true"
  [summary]="summary$ | async"
  [actions]="actions"
  (closeChat)="dialogRef.close()"
/>

<mat-dialog-content #chatContainer class="!block !pl-[14px] !pr-4">
  <div class="chat-body flex flex-1 basis-px flex-col gap-8 overflow-y-auto">
    @if (actions.content$ | async; as messages) {
      @if (messages.length) {
        @if (actions.canLoadMore$ | async) {
          <div class="flex items-center justify-center">
            @if (actions.loading$ | async) {
              <mat-icon class="text-primary-500 animate-spin">sync</mat-icon>
            } @else {
              <mat-icon class="text-slate-500">arrow_upward</mat-icon>
            }
          </div>
        }
      } @else {
        <div class="flex h-full w-full items-center justify-center">
          <pr-empty-state image="message" title="messages available" />
        </div>
      }
      <div class="w-full px-1">
        <pr-chat-body
          (messagesLoaded)="bloc.messagesLoaded$.next(true)"
          (messagesUpdated)="bloc.messagesUpdated$.next(true)"
          [messages]="messages"
        />
      </div>
    }
  </div>
</mat-dialog-content>

<div class="relative bg-white pt-2">
  @if (bloc.newMessagesAvailable$ | async) {
    <button
      mat-button
      (click)="bloc.viewNewMessages()"
      class="absolute left-2/4 top-0 z-20 -translate-x-1/2 -translate-y-1/2"
    >
      New Messages <mat-icon class="animate-bounce">arrow_downward</mat-icon>
    </button>
  }
  <pr-chat-footer [compact]="true" [summary]="summary$ | async" />
</div>
