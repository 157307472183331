<div class="grid grid-cols-4 gap-1" *ngIf="invoice$ | async as invoice">
  <pr-add-payment-button [invoice]="invoice" />
  <pr-add-refund-button [invoice]="invoice" />
  <pr-add-transaction-button
    *ngFor="
      let option of transactions.topLevelProviderOptions$ | async;
      trackBy: transactions.trackByOption
    "
    [option]="option"
    [invoice]="invoice"
  />
</div>
