import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import { Patient } from '@principle-theorem/principle-core';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-patient-age',
    templateUrl: './patient-age.component.html',
    styleUrls: ['./patient-age.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientAgeComponent {
  patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);
  age$: Observable<number | undefined>;

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor() {
    this.age$ = this.patient$.pipe(map((patient) => Patient.age(patient)));
  }
}
