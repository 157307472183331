import { InjectionToken } from '@angular/core';
import { snapshot, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

export interface ILoggerMetadataResolver<T extends object = object> {
  resolveMetadata(): Promise<T>;
}

export const LOGGER_METADATA_RESOLVER: InjectionToken<ILoggerMetadataResolver> =
  new InjectionToken<ILoggerMetadataResolver>('logger-metadata-resolver');

export async function resolveMetadataDocPath<T>(
  withRef$: Observable<WithRef<T> | undefined>
): Promise<string | undefined> {
  const data = await snapshot(withRef$);
  return data?.ref.path;
}
