import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Invoice } from '@principle-theorem/principle-core';
import {
  type IInteractionV2,
  type IInvoice,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

export interface IInvoiceInteractionsDialogData {
  invoice: WithRef<IInvoice>;
}

@Component({
    selector: 'pr-invoice-interactions-dialog',
    templateUrl: './invoice-interactions-dialog.component.html',
    styleUrls: ['./invoice-interactions-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InvoiceInteractionsDialogComponent {
  invoice: WithRef<IInvoice>;
  interactions$: Observable<WithRef<IInteractionV2>[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: IInvoiceInteractionsDialogData,
    public dialogRef: MatDialogRef<InvoiceInteractionsDialogComponent>
  ) {
    this.invoice = data.invoice;
    this.interactions$ = Invoice.interactions$(this.invoice);
  }

  async addInteraction(interaction: IInteractionV2): Promise<void> {
    await Invoice.addInteraction(this.invoice, interaction);
  }
}
