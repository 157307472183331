import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { InteractionDialogsService } from '@principle-theorem/ng-interactions';
import {
  CurrentBrandScope,
  FirebaseTable,
} from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { Brand, Lab, toMention } from '@principle-theorem/principle-core';
import {
  type IBrand,
  type ILab,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  filterUndefined,
  snapshot,
  undeletedQuery,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, Subject, BehaviorSubject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  type ILabCreateData,
  LabCreateComponent,
} from '../lab-create/lab-create.component';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'pr-lab-list',
  templateUrl: './lab-list.component.html',
  styleUrls: ['./lab-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabListComponent extends FirebaseTable<ILab> implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  override displayColumns: string[] = ['name', 'address', 'phone', 'email'];
  emptyState$: Observable<boolean>;
  emptySearch$ = new BehaviorSubject<boolean>(false);

  @ViewChild(MatSort)
  set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator)
  set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  constructor(
    private _brandScope: CurrentBrandScope,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public dialogService: InteractionDialogsService
  ) {
    super();

    const brand$ = this._brandScope.doc$.pipe(filterUndefined());
    brand$.pipe(take(1), takeUntil(this._onDestroy$)).subscribe((brand) => {
      this.dataSource.collection = undeletedQuery(Brand.labCol(brand));
    });

    this.emptyState$ = combineLatest([
      brand$.pipe(switchMap((brand) => Brand.labs$(brand))),
      this.dataSource.loading$,
    ]).pipe(map(([labs, loading]) => !labs.length && !loading));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async searchLabs(value: string): Promise<void> {
    this.applyFilter(value);
    const emptyState = await snapshot(this.emptyState$);
    !emptyState && !this.dataSource.filteredData.length
      ? this.emptySearch$.next(true)
      : this.emptySearch$.next(false);
  }

  async addLab(): Promise<void> {
    const data: ILabCreateData = {
      title: 'Add New Lab',
      submitLabel: 'Add New Lab',
    };
    const labData: Partial<ILab> | undefined = await this._dialog
      .open<LabCreateComponent, ILabCreateData, Partial<ILab>>(
        LabCreateComponent,
        DialogPresets.medium({ data })
      )
      .afterClosed()
      .toPromise();

    if (labData) {
      await this._createLab(labData);
    }
  }

  openEmail(lab: WithRef<ILab>): void {
    this.setDialogData(lab);
    this.dialogService.openEmail();
  }

  openPhone(lab: WithRef<ILab>): void {
    this.setDialogData(lab);
    this.dialogService.openPhone();
  }

  setDialogData(lab: WithRef<ILab>): void {
    this.dialogService.dialogData = {
      contact: toMention(lab, MentionResourceType.Lab),
    };
  }

  private async _createLab(data: Partial<ILab>): Promise<void> {
    const lab: ILab = Lab.init(data);
    const brand: WithRef<IBrand> = await this._brandScope.toPromise();
    await addDoc(Brand.labCol(brand), lab);
    this._snackBar.open('New Lab Added');
  }
}
