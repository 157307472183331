import {
  ICustomMapping,
  ICustomMappingHandler,
  ICustomMappings,
  ICustomMappingsHandler,
  IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  asyncForEach,
  firstResult$,
  snapshot,
  upsertDocByProperty,
  where,
} from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PracticeMigration } from '../practice-migrations';

export abstract class BaseCustomMappingsHandler
  implements ICustomMappingsHandler
{
  abstract migrationType: string;
  abstract migrationVersion: string;
  abstract customMapping: ICustomMappings;
  abstract mappingHandlers: ICustomMappingHandler[];

  get mappings(): ICustomMapping[] {
    return this.mappingHandlers.map((handler) => handler.customMapping);
  }

  canHandle(mappings: ICustomMappings): boolean {
    return isEqual(mappings.metadata, this.customMapping.metadata);
  }

  async addEntities(migration: WithRef<IPracticeMigration>): Promise<void> {
    await asyncForEach(this.mappings, async (mapping) => {
      const hasEntity = await snapshot(
        firstResult$(
          PracticeMigration.customMappingCol({
            ref: migration.ref,
          }),
          where('uid', '==', mapping.metadata.type)
        ).pipe(
          map((result) => !!result),
          catchError(() => of(undefined)),
          map((doc) => (doc ? true : false))
        )
      );

      if (hasEntity) {
        return;
      }

      return upsertDocByProperty(
        PracticeMigration.customMappingCol({
          ref: migration.ref,
        }),
        mapping,
        'uid',
        mapping.metadata.type
      );
    });
  }
}
