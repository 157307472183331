import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Appointment, LabJob } from '@principle-theorem/principle-core';
import {
  AppointmentSummary,
  type IAppointment,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, multiFilter } from '@principle-theorem/shared';
import { ReplaySubject, combineLatest, type Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-timeline-appointment-notifications',
  templateUrl: './timeline-appointment-notifications.component.html',
  styleUrls: ['./timeline-appointment-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineAppointmentNotificationsComponent {
  showConfirmationBadge$: Observable<boolean>;
  showLabJobBadge$: Observable<boolean>;
  isNotConfirmed$: Observable<boolean>;
  hasArrived$: Observable<boolean>;
  isLabJobLate$: Observable<boolean>;
  labJobReqired$: Observable<boolean>;
  labJobTooltip$: Observable<string | undefined>;
  appointment$ = new ReplaySubject<WithRef<IAppointment> | AppointmentSummary>(
    1
  );
  @Input() tooltipsEnabled = false;

  @Input()
  set appointment(appointment: WithRef<IAppointment> | AppointmentSummary) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor() {
    this.isNotConfirmed$ = this.appointment$.pipe(
      map((appointment) => Appointment.isUnconfirmed(appointment))
    );

    this.hasArrived$ = this.appointment$.pipe(
      map((appointment) => Appointment.canRevertToConfirmed(appointment)),
      startWith(false)
    );

    const labJobs$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.labJobs$(appointment))
    );

    this.labJobReqired$ = labJobs$.pipe(map((labJobs) => labJobs.length > 0));

    this.isLabJobLate$ = labJobs$.pipe(
      multiFilter((labJob) => LabJob.isOverdue(labJob)),
      map((labJobs) => labJobs.length > 0)
    );

    this.showLabJobBadge$ = combineLatest([
      this.labJobReqired$,
      this.isLabJobLate$,
    ]).pipe(
      map(([labJobReqired, isLabJobLate]) => labJobReqired || isLabJobLate)
    );

    this.labJobTooltip$ = combineLatest([
      this.labJobReqired$,
      this.isLabJobLate$,
    ]).pipe(
      map(([labJobReqired, isLabJobLate]) => {
        if (labJobReqired) {
          return 'Lab Job required for this appointment';
        }
        if (isLabJobLate) {
          return 'Lab Job overdue';
        }
      })
    );
  }
}
