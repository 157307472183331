import { FeatureVisibility, IFeature } from '@principle-theorem/feature-flags';

export enum LabPermissions {
  LabManage = 'labs.manage',
}

export const LABS_FEATURE: IFeature = {
  label: 'labs',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: LabPermissions.LabManage,
      description: `User can manage and interact with labs, lab job types, and lab contacts`,
    },
  ],
};
