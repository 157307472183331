import {
  ConditionConfigurationName,
  ExistingTreatmentConfigurationName,
} from '@principle-theorem/principle-core';
import { TreatmentConfigurationName } from '@principle-theorem/principle-core/interfaces';
import { ItemCodeNoteType } from './item-codes-to-notes-xlsx';

export const ITEM_CODE_TO_CONDITION_DEFAULT_MAPPINGS: {
  [key: string]: string;
} = {
  '074': ExistingTreatmentConfigurationName.Veneer,
  '161': ConditionConfigurationName.FissureSealant,
  '171': ConditionConfigurationName.ChippedBrokenFilling,
  '213': ConditionConfigurationName.Pericoronitis,
  '245': ConditionConfigurationName.Pericoronitis,
  '414': ConditionConfigurationName.RootCanalTreatment,
  '415': ConditionConfigurationName.RootCanalTreatment,
  '416': ConditionConfigurationName.RootCanalTreatment,
  '417': ConditionConfigurationName.RootCanalTreatment,
  '418': ConditionConfigurationName.RootCanalTreatment,
  '432': ConditionConfigurationName.RootCanalTreatment,
  '455': ConditionConfigurationName.RootCanalTreatment,
  '511': ConditionConfigurationName.AmalgamFilling,
  '512': ConditionConfigurationName.AmalgamFilling,
  '513': ConditionConfigurationName.AmalgamFilling,
  '514': ConditionConfigurationName.AmalgamFilling,
  '515': ConditionConfigurationName.AmalgamFilling,
  '521': ConditionConfigurationName.CompositeFilling,
  '522': ConditionConfigurationName.CompositeFilling,
  '523': ConditionConfigurationName.CompositeFilling,
  '524': ConditionConfigurationName.CompositeFilling,
  '525': ConditionConfigurationName.CompositeFilling,
  '521.': ConditionConfigurationName.CompositeFilling,
  '522.': ConditionConfigurationName.CompositeFilling,
  '523.': ConditionConfigurationName.CompositeFilling,
  '524.': ConditionConfigurationName.CompositeFilling,
  '525.': ConditionConfigurationName.CompositeFilling,
  '526': ExistingTreatmentConfigurationName.Veneer,
  '531': ConditionConfigurationName.CompositeFilling,
  '532': ConditionConfigurationName.CompositeFilling,
  '533': ConditionConfigurationName.CompositeFilling,
  '534': ConditionConfigurationName.CompositeFilling,
  '535': ConditionConfigurationName.CompositeFilling,
  '531.': ConditionConfigurationName.CompositeFilling,
  '532.': ConditionConfigurationName.CompositeFilling,
  '533.': ConditionConfigurationName.CompositeFilling,
  '534.': ConditionConfigurationName.CompositeFilling,
  '535.': ConditionConfigurationName.CompositeFilling,
  '536': ExistingTreatmentConfigurationName.Veneer,
  '541': ConditionConfigurationName.InlayOnlayMetallic,
  '542': ConditionConfigurationName.InlayOnlayMetallic,
  '543': ConditionConfigurationName.InlayOnlayMetallic,
  '544': ConditionConfigurationName.InlayOnlayMetallic,
  '545': ConditionConfigurationName.InlayOnlayMetallic,
  '551': ConditionConfigurationName.InlayOnlayCeramic,
  '552': ConditionConfigurationName.InlayOnlayCeramic,
  '553': ConditionConfigurationName.InlayOnlayCeramic,
  '554': ConditionConfigurationName.InlayOnlayCeramic,
  '555': ConditionConfigurationName.InlayOnlayCeramic,
  '556': ExistingTreatmentConfigurationName.Veneer,
  '567': ConditionConfigurationName.InlayOnlayMetallic,
  '568': ConditionConfigurationName.InlayOnlayMetallic,
  '569': ConditionConfigurationName.InlayOnlayMetallic,
  '577': ConditionConfigurationName.CompositeFilling,
  '582': ExistingTreatmentConfigurationName.Veneer,
  '583': ExistingTreatmentConfigurationName.Veneer,
  '661': ConditionConfigurationName.ImplantCrown,
  '672': ConditionConfigurationName.ImplantCrown,
  '711': ConditionConfigurationName.Denture,
  '712': ConditionConfigurationName.Denture,
  '713': ConditionConfigurationName.Denture,
  '714': ConditionConfigurationName.Denture,
  '715': ConditionConfigurationName.Denture,
  '719': ConditionConfigurationName.Denture,
  '721': ConditionConfigurationName.Denture,
  '721A': ConditionConfigurationName.Denture,
  '721B': ConditionConfigurationName.Denture,
  '721C': ConditionConfigurationName.Denture,
  '721D': ConditionConfigurationName.Denture,
  '721E': ConditionConfigurationName.Denture,
  '721F': ConditionConfigurationName.Denture,
  '722': ConditionConfigurationName.Denture,
  '722A': ConditionConfigurationName.Denture,
  '722B': ConditionConfigurationName.Denture,
  '722C': ConditionConfigurationName.Denture,
  '722D': ConditionConfigurationName.Denture,
  '722E': ConditionConfigurationName.Denture,
  '722F': ConditionConfigurationName.Denture,
  '723': ConditionConfigurationName.Denture,
  '724': ConditionConfigurationName.Denture,
  '743': ConditionConfigurationName.Denture,
  '744': ConditionConfigurationName.Denture,
  '841': ConditionConfigurationName.FixedOrthoRetainerBondedWire,
  '845': ConditionConfigurationName.FixedOrthoRetainerBondedWire,
  '88161': ConditionConfigurationName.FissureSealant,
  '88162': ConditionConfigurationName.FissureSealant,
  '88511': ConditionConfigurationName.AmalgamFilling,
  '88512': ConditionConfigurationName.AmalgamFilling,
  '88513': ConditionConfigurationName.AmalgamFilling,
  '88514': ConditionConfigurationName.AmalgamFilling,
  '88515': ConditionConfigurationName.AmalgamFilling,
  '88521': ConditionConfigurationName.CompositeFilling,
  '88522': ConditionConfigurationName.CompositeFilling,
  '88523': ConditionConfigurationName.CompositeFilling,
  '88524': ConditionConfigurationName.CompositeFilling,
  '88525': ConditionConfigurationName.CompositeFilling,
  '88521.': ConditionConfigurationName.CompositeFilling,
  '88522.': ConditionConfigurationName.CompositeFilling,
  '88523.': ConditionConfigurationName.CompositeFilling,
  '88524.': ConditionConfigurationName.CompositeFilling,
  '88525.': ConditionConfigurationName.CompositeFilling,
  '88526': ExistingTreatmentConfigurationName.Veneer,
  '88531': ConditionConfigurationName.CompositeFilling,
  '88532': ConditionConfigurationName.CompositeFilling,
  '88533': ConditionConfigurationName.CompositeFilling,
  '88534': ConditionConfigurationName.CompositeFilling,
  '88535': ConditionConfigurationName.CompositeFilling,
  '88531.': ConditionConfigurationName.CompositeFilling,
  '88532.': ConditionConfigurationName.CompositeFilling,
  '88533.': ConditionConfigurationName.CompositeFilling,
  '88534.': ConditionConfigurationName.CompositeFilling,
  '88535.': ConditionConfigurationName.CompositeFilling,
};

export const ITEM_CODE_TO_TREATMENT_DEFAULT_MAPPINGS: {
  [key: string]: string;
} = {
  '011': TreatmentConfigurationName.NewPatientComprehensiveExamAndClean,
  '012': TreatmentConfigurationName.PeriodicExam,
  '013': TreatmentConfigurationName.LimitedEmergencyExamination,
  '014': TreatmentConfigurationName.Consultation,
  '015': TreatmentConfigurationName.Consultation,
  '016': TreatmentConfigurationName.Consultation,
  '017': TreatmentConfigurationName.Consultation,
  '018': TreatmentConfigurationName.Consultation,
  '026': TreatmentConfigurationName.CBCTBothArchesAcquisitionAndInterpretation,
  '071': TreatmentConfigurationName.CosmeticOrRestorativeWorkUp,
  '072': TreatmentConfigurationName.DigitalIntraoralScan,
  '073': TreatmentConfigurationName.PhotographsDSLR,
  '074': TreatmentConfigurationName.DiagnosticWaxUpMockUpTryIn,
  '087':
    TreatmentConfigurationName.CBCTPartialSegmentAcquisitionAndInterpretation,
  '088': TreatmentConfigurationName.CBCTSingleArchAcquisitionAndInterpretation,
  '089': TreatmentConfigurationName.CBCTBothArchesAcquisitionAndInterpretation,
  '111': TreatmentConfigurationName.ScaleAndClean,
  '113': TreatmentConfigurationName.RecontourPolishRestoration,
  '114': TreatmentConfigurationName.ScaleAndClean,
  '115': TreatmentConfigurationName.ScaleAndClean,
  '116': TreatmentConfigurationName.EnamelMicroabrasion,
  '117': TreatmentConfigurationName.InternalBleachingPlacement,
  '118': TreatmentConfigurationName.InternalBleachingPlacement,
  '119': TreatmentConfigurationName.TeethWhiteningIssue,
  '121': TreatmentConfigurationName.ScaleAndClean,
  '122': TreatmentConfigurationName.RemineralisingTreatment,
  '123': TreatmentConfigurationName.RemineralisingTreatment,
  '141': TreatmentConfigurationName.OralHygieneInstructions,
  '151': TreatmentConfigurationName.MouthguardIssue,
  '161': TreatmentConfigurationName.FissureSealant,
  '162': TreatmentConfigurationName.FissureSealant,
  '165': TreatmentConfigurationName.DesensitisingTreatment,
  '171': TreatmentConfigurationName.OdontoplastyRecontouringToothStructure,
  '213': TreatmentConfigurationName.AcutePeriodontalInfectionTreatment,
  '221': TreatmentConfigurationName.PeriodontalMaintenanceSupportiveTreatment,
  '231': TreatmentConfigurationName.Gingivectomy,
  '235': TreatmentConfigurationName.GingivalSoftTissueGraft,
  '236': TreatmentConfigurationName.GuidedTissueRegenerationMembranePlacement,
  '237': TreatmentConfigurationName.GuidedTissueRegenerationMembraneRemoval,
  '238': TreatmentConfigurationName.CrownLengtheningPeriodontalSurgery,
  '243': TreatmentConfigurationName.BoneGraftParticulate,
  '244': TreatmentConfigurationName.BoneGraftBlock,
  '250': TreatmentConfigurationName.PeriodontalActiveTreatmentPerQuadrant,
  '251': TreatmentConfigurationName.PeriodontalMaintenanceSupportiveTreatment,
  '281': TreatmentConfigurationName.PeriodontalMaintenanceSupportiveTreatment,
  '282': TreatmentConfigurationName.PeriodontalMaintenanceSupportiveTreatment,
  '311': TreatmentConfigurationName.ExtractionStandard,
  '314': TreatmentConfigurationName.ExtractionSectional,
  '316': TreatmentConfigurationName.ExtractionStandard,
  '321': TreatmentConfigurationName.ExtractionSurgical,
  '322': TreatmentConfigurationName.ExtractionSurgical,
  '323': TreatmentConfigurationName.ExtractionSurgical,
  '324': TreatmentConfigurationName.ExtractionSurgical,
  '325': TreatmentConfigurationName.ExtractionSurgical,
  '326': TreatmentConfigurationName.ExtractionSurgical,
  '384': TreatmentConfigurationName.SplintingAndStabilisation,
  '386': TreatmentConfigurationName.SplintingAndStabilisation,
  '387': TreatmentConfigurationName.SplintingAndStabilisation,
  '392': TreatmentConfigurationName.DrainingOfAbscess,
  '414': TreatmentConfigurationName.Pulpotomy,
  '415': TreatmentConfigurationName.RootCanalChemomechanicalPreparation,
  '416': TreatmentConfigurationName.RootCanalChemomechanicalPreparation,
  '417': TreatmentConfigurationName.RootCanalObturation,
  '418': TreatmentConfigurationName.RootCanalObturation,
  '419': TreatmentConfigurationName.RootCanalEmergencyExtirpation,
  '432': TreatmentConfigurationName.Apicectomy,
  '451': TreatmentConfigurationName.RootCanalReTreatmentRemovalOfRootFilling,
  '455': TreatmentConfigurationName.RootCanalReTreatmentRemovalOfRootFilling,
  '511': TreatmentConfigurationName.AmalgamFillingDirectMetallicRestoration,
  '512': TreatmentConfigurationName.AmalgamFillingDirectMetallicRestoration,
  '513': TreatmentConfigurationName.AmalgamFillingDirectMetallicRestoration,
  '514': TreatmentConfigurationName.AmalgamFillingDirectMetallicRestoration,
  '515': TreatmentConfigurationName.AmalgamFillingDirectMetallicRestoration,
  '521': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '522': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '523': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '524': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '525': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '526': TreatmentConfigurationName.CompositeVeneer,
  '531': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '532': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '533': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '534': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '535': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '536': TreatmentConfigurationName.CompositeVeneer,
  '541':
    TreatmentConfigurationName.InlayOnlayIssueBondingofMetallicIndirectRestoration,
  '542':
    TreatmentConfigurationName.InlayOnlayIssueBondingofMetallicIndirectRestoration,
  '543':
    TreatmentConfigurationName.InlayOnlayIssueBondingofMetallicIndirectRestoration,
  '544':
    TreatmentConfigurationName.InlayOnlayIssueBondingofMetallicIndirectRestoration,
  '545':
    TreatmentConfigurationName.InlayOnlayIssueBondingofMetallicIndirectRestoration,
  '551':
    TreatmentConfigurationName.InlayOnlayIssueBondingOfToothColouredIndirectRestoration,
  '552':
    TreatmentConfigurationName.InlayOnlayIssueBondingOfToothColouredIndirectRestoration,
  '553':
    TreatmentConfigurationName.InlayOnlayIssueBondingOfToothColouredIndirectRestoration,
  '554':
    TreatmentConfigurationName.InlayOnlayIssueBondingOfToothColouredIndirectRestoration,
  '555':
    TreatmentConfigurationName.InlayOnlayIssueBondingOfToothColouredIndirectRestoration,
  '556': TreatmentConfigurationName.VeneerIssueBonding,
  '572': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '574': TreatmentConfigurationName.MetalBand,
  '575': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '577':
    TreatmentConfigurationName.InlayOnlayIssueBondingOfToothColouredIndirectRestoration,
  '578': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '579': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '583': TreatmentConfigurationName.VeneerIssueBonding,
  '613': TreatmentConfigurationName.CrownIssueBondingCeramicNonMetallic,
  '615': TreatmentConfigurationName.CrownIssueBondingVeneeredPFM,
  '618': TreatmentConfigurationName.CrownIssueBondingMetallic,
  '625': TreatmentConfigurationName.PostAndCorePlacement,
  '631': TreatmentConfigurationName.TemporaryCrown,
  '643': TreatmentConfigurationName.BridgeIssuePontics,
  '661': TreatmentConfigurationName.ImplantRestoration,
  '678': TreatmentConfigurationName.ImplantSurgeryFixturePlacement,
  '679': TreatmentConfigurationName.ImplantSurgeryFixturePlacement,
  '684': TreatmentConfigurationName.ImplantSurgeryFixturePlacement,
  '688': TreatmentConfigurationName.ImplantSurgeryFixturePlacement,
  '689': TreatmentConfigurationName.ImplantSurgeryFixturePlacement,
  '690': TreatmentConfigurationName.ImplantSurgeryFixturePlacement,
  '691':
    TreatmentConfigurationName.ImplantSurgerySecondStageHealingAbutmentPlacement,
  '694': TreatmentConfigurationName.ImplantSurgeryFixturePlacement,
  '711': TreatmentConfigurationName.CompleteDenture,
  '712': TreatmentConfigurationName.CompleteDenture,
  '713': TreatmentConfigurationName.CompleteDenture,
  '714': TreatmentConfigurationName.CompleteDenture,
  '715': TreatmentConfigurationName.CompleteDenture,
  '716': TreatmentConfigurationName.CompleteDenture,
  '719': TreatmentConfigurationName.CompleteDenture,
  '721': TreatmentConfigurationName.CompleteDenture,
  '722': TreatmentConfigurationName.CompleteDenture,
  '723': TreatmentConfigurationName.CompleteDenture,
  '724': TreatmentConfigurationName.CompleteDenture,
  '730': TreatmentConfigurationName.CompleteDenture,
  '731': TreatmentConfigurationName.CompleteDenture,
  '732': TreatmentConfigurationName.CompleteDenture,
  '733': TreatmentConfigurationName.CompleteDenture,
  '734': TreatmentConfigurationName.CompleteDenture,
  '735': TreatmentConfigurationName.CompleteDenture,
  '736': TreatmentConfigurationName.CompleteDenture,
  '737': TreatmentConfigurationName.CompleteDenture,
  '738': TreatmentConfigurationName.CompleteDenture,
  '739': TreatmentConfigurationName.CompleteDenture,
  '741': TreatmentConfigurationName.CompleteDenture,
  '743': TreatmentConfigurationName.CompleteDenture,
  '744': TreatmentConfigurationName.CompleteDenture,
  '745': TreatmentConfigurationName.CompleteDenture,
  '746': TreatmentConfigurationName.CompleteDenture,
  '751': TreatmentConfigurationName.CompleteDenture,
  '752': TreatmentConfigurationName.CompleteDenture,
  '753': TreatmentConfigurationName.CompleteDenture,
  '754': TreatmentConfigurationName.CompleteDenture,
  '761': TreatmentConfigurationName.CompleteDenture,
  '762': TreatmentConfigurationName.CompleteDenture,
  '763': TreatmentConfigurationName.CompleteDenture,
  '764': TreatmentConfigurationName.CompleteDenture,
  '765': TreatmentConfigurationName.CompleteDenture,
  '766': TreatmentConfigurationName.CompleteDenture,
  '767': TreatmentConfigurationName.CompleteDenture,
  '768': TreatmentConfigurationName.CompleteDenture,
  '769': TreatmentConfigurationName.CompleteDenture,
  '772': TreatmentConfigurationName.SplintIssue,
  '773': TreatmentConfigurationName.SplintIssue,
  '775': TreatmentConfigurationName.CompleteDenture,
  '777': TreatmentConfigurationName.CompleteDenture,
  '778': TreatmentConfigurationName.CompleteDenture,
  '779': TreatmentConfigurationName.CompleteDenture,
  '825': TreatmentConfigurationName.InvisalignIssuingFirstAligners,
  '845': TreatmentConfigurationName.FixedOrthoBondedWireRetainers,
  '846': TreatmentConfigurationName.FixedOrthoBondedWireRetainers,
  '862': TreatmentConfigurationName.InvisalignBondingOfAttachments,
  '872': TreatmentConfigurationName.FixedOrthoBondedWireRetainers,
  '873': TreatmentConfigurationName.FixedOrthoBondedWireRetainers,
  '874': TreatmentConfigurationName.FixedOrthoBondedWireRetainers,
  '88012': TreatmentConfigurationName.PeriodicExam,
  '88161': TreatmentConfigurationName.FissureSealant,
  '88162': TreatmentConfigurationName.FissureSealant,
  '88221': TreatmentConfigurationName.PeriodontalMaintenanceSupportiveTreatment,
  '88311': TreatmentConfigurationName.ExtractionStandard,
  '88314': TreatmentConfigurationName.ExtractionStandard,
  '88316': TreatmentConfigurationName.ExtractionStandard,
  '88322': TreatmentConfigurationName.ExtractionSurgical,
  '88323': TreatmentConfigurationName.ExtractionSurgical,
  '88324': TreatmentConfigurationName.ExtractionSurgical,
  '88326': TreatmentConfigurationName.ExtractionSurgical,
  '88384': TreatmentConfigurationName.SplintingAndStabilisation,
  '88386': TreatmentConfigurationName.SplintingAndStabilisation,
  '88387': TreatmentConfigurationName.SplintingAndStabilisation,
  '88414': TreatmentConfigurationName.Pulpotomy,
  '88415': TreatmentConfigurationName.RootCanalChemomechanicalPreparation,
  '88416': TreatmentConfigurationName.RootCanalChemomechanicalPreparation,
  '88417': TreatmentConfigurationName.RootCanalObturation,
  '88418': TreatmentConfigurationName.RootCanalObturation,
  '88419': TreatmentConfigurationName.RootCanalEmergencyExtirpation,
  '88455': TreatmentConfigurationName.RootCanalReTreatmentRemovalOfRootFilling,
  '88521': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '88522': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '88523': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '88524': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '88525': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '88531': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '88532': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '88533': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '88534': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '88535': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '88572': TreatmentConfigurationName.CompositeFillingDirectAdhesiveRestoration,
  '88721': TreatmentConfigurationName.CompleteDenture,
  '88722': TreatmentConfigurationName.CompleteDenture,
  '88723': TreatmentConfigurationName.CompleteDenture,
  '88724': TreatmentConfigurationName.CompleteDenture,
  '88731': TreatmentConfigurationName.CompleteDenture,
  '88733': TreatmentConfigurationName.CompleteDenture,
  '88736': TreatmentConfigurationName.CompleteDenture,
  '88741': TreatmentConfigurationName.CompleteDenture,
  '88761': TreatmentConfigurationName.CompleteDenture,
  '88762': TreatmentConfigurationName.CompleteDenture,
  '88764': TreatmentConfigurationName.CompleteDenture,
  '88765': TreatmentConfigurationName.CompleteDenture,
  '88766': TreatmentConfigurationName.CompleteDenture,
  '88768': TreatmentConfigurationName.CompleteDenture,
  '88943': TreatmentConfigurationName.NitrousOxideInhalationSedation,
  '926': TreatmentConfigurationName.TeethWhiteningIssue,
  '927': TreatmentConfigurationName.PrescriptionProvisionOfMedication,
  '942': TreatmentConfigurationName.NitrousOxideInhalationSedation,
  '943': TreatmentConfigurationName.NitrousOxideInhalationSedation,
  '961': TreatmentConfigurationName.ReviewOfRecentTreatment,
  '963': TreatmentConfigurationName.SplintIssue,
  '964': TreatmentConfigurationName.SplintIssue,
  '965': TreatmentConfigurationName.SplintIssue,
  '966': TreatmentConfigurationName.SplintIssue,
  '968': TreatmentConfigurationName.SplintIssue,
  '972': TreatmentConfigurationName.SplintAdjustmentAdditionRepair,
  '981': TreatmentConfigurationName.SplintingAndStabilisation,
  '982': TreatmentConfigurationName.InvisalignBondingOfAttachments,
  '983': TreatmentConfigurationName.MandibularAdvancementSplintIssue,
  '984': TreatmentConfigurationName.MandibularAdvancementSplintIssue,
  '985': TreatmentConfigurationName.SplintIssue,
};

export const ITEM_CODE_TO_NOTE_DEFAULT_MAPPINGS: {
  [key: string]: string;
} = {
  '020': ItemCodeNoteType.ClinicalNote,
  '021': ItemCodeNoteType.ClinicalNote,
  '022.pa': ItemCodeNoteType.ClinicalNote,
  '022bw1': ItemCodeNoteType.ClinicalNote,
  '022bw2': ItemCodeNoteType.ClinicalNote,
  '022pa': ItemCodeNoteType.ClinicalNote,
  '10975': ItemCodeNoteType.ClinicalNote,
  '10976': ItemCodeNoteType.ClinicalNote,
  '10977': ItemCodeNoteType.ClinicalNote,
  '181': ItemCodeNoteType.ClinicalNote,
  '311.': ItemCodeNoteType.ClinicalNote,
  '311.a': ItemCodeNoteType.ClinicalNote,
  '314.': ItemCodeNoteType.ClinicalNote,
  '314.a': ItemCodeNoteType.ClinicalNote,
  '322.': ItemCodeNoteType.ClinicalNote,
  '322.a': ItemCodeNoteType.ClinicalNote,
  '323.': ItemCodeNoteType.ClinicalNote,
  '323.a': ItemCodeNoteType.ClinicalNote,
  '324.': ItemCodeNoteType.ClinicalNote,
  '324.a': ItemCodeNoteType.ClinicalNote,
  '412o': ItemCodeNoteType.ClinicalNote,
  '441': ItemCodeNoteType.ClinicalNote,
  '443': ItemCodeNoteType.ClinicalNote,
  '471': ItemCodeNoteType.ClinicalNote,
  '472': ItemCodeNoteType.ClinicalNote,
  '473': ItemCodeNoteType.ClinicalNote,
  '474': ItemCodeNoteType.ClinicalNote,
  '475': ItemCodeNoteType.ClinicalNote,
  '476': ItemCodeNoteType.ClinicalNote,
  '477': ItemCodeNoteType.ClinicalNote,
  '478': ItemCodeNoteType.ClinicalNote,
  '479': ItemCodeNoteType.ClinicalNote,
  '480': ItemCodeNoteType.ClinicalNote,
  '481': ItemCodeNoteType.ClinicalNote,
  '482': ItemCodeNoteType.ClinicalNote,
  '482a': ItemCodeNoteType.ClinicalNote,
  '483': ItemCodeNoteType.ClinicalNote,
  '484': ItemCodeNoteType.ClinicalNote,
  '485': ItemCodeNoteType.ClinicalNote,
  '486': ItemCodeNoteType.ClinicalNote,
  '487': ItemCodeNoteType.ClinicalNote,
  '488': ItemCodeNoteType.ClinicalNote,
  '489': ItemCodeNoteType.ClinicalNote,
  '514o': ItemCodeNoteType.ClinicalNote,
  '515o': ItemCodeNoteType.ClinicalNote,
  '521o': ItemCodeNoteType.ClinicalNote,
  '522o': ItemCodeNoteType.ClinicalNote,
  '521.': ItemCodeNoteType.ClinicalNote,
  '522.': ItemCodeNoteType.ClinicalNote,
  '523.': ItemCodeNoteType.ClinicalNote,
  '523o': ItemCodeNoteType.ClinicalNote,
  '524.': ItemCodeNoteType.ClinicalNote,
  '525.': ItemCodeNoteType.ClinicalNote,
  '529': ItemCodeNoteType.ClinicalNote,
  '531.': ItemCodeNoteType.ClinicalNote,
  '532.': ItemCodeNoteType.ClinicalNote,
  '533.': ItemCodeNoteType.ClinicalNote,
  '534.': ItemCodeNoteType.ClinicalNote,
  '535.': ItemCodeNoteType.ClinicalNote,
  '541o': ItemCodeNoteType.ClinicalNote,
  '542o': ItemCodeNoteType.ClinicalNote,
  '543o': ItemCodeNoteType.ClinicalNote,
  '551o': ItemCodeNoteType.ClinicalNote,
  '552o': ItemCodeNoteType.ClinicalNote,
  '553o': ItemCodeNoteType.ClinicalNote,
  '554o': ItemCodeNoteType.ClinicalNote,
  '555o': ItemCodeNoteType.ClinicalNote,
  '556.': ItemCodeNoteType.ClinicalNote,
  '561': ItemCodeNoteType.ClinicalNote,
  '562': ItemCodeNoteType.ClinicalNote,
  '564': ItemCodeNoteType.ClinicalNote,
  '565': ItemCodeNoteType.ClinicalNote,
  '566': ItemCodeNoteType.ClinicalNote,
  '567': ItemCodeNoteType.ClinicalNote,
  '568': ItemCodeNoteType.ClinicalNote,
  '569': ItemCodeNoteType.ClinicalNote,
  '581': ItemCodeNoteType.ClinicalNote,
  '584': ItemCodeNoteType.ClinicalNote,
  '597.': ItemCodeNoteType.ClinicalNote,
  '611.': ItemCodeNoteType.ClinicalNote,
  '616': ItemCodeNoteType.ClinicalNote,
  '619': ItemCodeNoteType.ClinicalNote,
  '620': ItemCodeNoteType.ClinicalNote,
  '621': ItemCodeNoteType.ClinicalNote,
  '623': ItemCodeNoteType.ClinicalNote,
  '636': ItemCodeNoteType.ClinicalNote,
  '643*': ItemCodeNoteType.ClinicalNote,
  '646': ItemCodeNoteType.ClinicalNote,
  '647': ItemCodeNoteType.ClinicalNote,
  '721.': ItemCodeNoteType.ClinicalNote,
  '721A': ItemCodeNoteType.ClinicalNote,
  '721B': ItemCodeNoteType.ClinicalNote,
  '721C': ItemCodeNoteType.ClinicalNote,
  '721D': ItemCodeNoteType.ClinicalNote,
  '721E': ItemCodeNoteType.ClinicalNote,
  '721F': ItemCodeNoteType.ClinicalNote,
  '722.': ItemCodeNoteType.ClinicalNote,
  '722A': ItemCodeNoteType.ClinicalNote,
  '722B': ItemCodeNoteType.ClinicalNote,
  '722C': ItemCodeNoteType.ClinicalNote,
  '722D': ItemCodeNoteType.ClinicalNote,
  '722E': ItemCodeNoteType.ClinicalNote,
  '722F': ItemCodeNoteType.ClinicalNote,
  '727A': ItemCodeNoteType.ClinicalNote,
  '727B': ItemCodeNoteType.ClinicalNote,
  '727C': ItemCodeNoteType.ClinicalNote,
  '727D': ItemCodeNoteType.ClinicalNote,
  '727E': ItemCodeNoteType.ClinicalNote,
  '727F': ItemCodeNoteType.ClinicalNote,
  '728A': ItemCodeNoteType.ClinicalNote,
  '728B': ItemCodeNoteType.ClinicalNote,
  '728C': ItemCodeNoteType.ClinicalNote,
  '728D': ItemCodeNoteType.ClinicalNote,
  '728E': ItemCodeNoteType.ClinicalNote,
  '728F': ItemCodeNoteType.ClinicalNote,
  '761A': ItemCodeNoteType.ClinicalNote,
  '762A': ItemCodeNoteType.ClinicalNote,
  '765A': ItemCodeNoteType.ClinicalNote,
  '770DVA': ItemCodeNoteType.ClinicalNote,
  '85011': ItemCodeNoteType.ClinicalNote,
  '85012': ItemCodeNoteType.ClinicalNote,
  '85013': ItemCodeNoteType.ClinicalNote,
  '85022': ItemCodeNoteType.ClinicalNote,
  '85025': ItemCodeNoteType.ClinicalNote,
  '85031': ItemCodeNoteType.ClinicalNote,
  '85037': ItemCodeNoteType.ClinicalNote,
  '85039': ItemCodeNoteType.ClinicalNote,
  '85047': ItemCodeNoteType.ClinicalNote,
  '85051': ItemCodeNoteType.ClinicalNote,
  '85071': ItemCodeNoteType.ClinicalNote,
  '85111': ItemCodeNoteType.ClinicalNote,
  '85113': ItemCodeNoteType.ClinicalNote,
  '85114': ItemCodeNoteType.ClinicalNote,
  '85115': ItemCodeNoteType.ClinicalNote,
  '85117': ItemCodeNoteType.ClinicalNote,
  '85121': ItemCodeNoteType.ClinicalNote,
  '85123': ItemCodeNoteType.ClinicalNote,
  '85131': ItemCodeNoteType.ClinicalNote,
  '85141': ItemCodeNoteType.ClinicalNote,
  '85161': ItemCodeNoteType.ClinicalNote,
  '85165': ItemCodeNoteType.ClinicalNote,
  '85171': ItemCodeNoteType.ClinicalNote,
  '85213': ItemCodeNoteType.ClinicalNote,
  '85221': ItemCodeNoteType.ClinicalNote,
  '85222': ItemCodeNoteType.ClinicalNote,
  '85225': ItemCodeNoteType.ClinicalNote,
  '85231': ItemCodeNoteType.ClinicalNote,
  '85232': ItemCodeNoteType.ClinicalNote,
  '85238': ItemCodeNoteType.ClinicalNote,
  '85241': ItemCodeNoteType.ClinicalNote,
  '85245': ItemCodeNoteType.ClinicalNote,
  '85311': ItemCodeNoteType.ClinicalNote,
  '85314': ItemCodeNoteType.ClinicalNote,
  '85316': ItemCodeNoteType.ClinicalNote,
  '85322': ItemCodeNoteType.ClinicalNote,
  '85323': ItemCodeNoteType.ClinicalNote,
  '85324': ItemCodeNoteType.ClinicalNote,
  '85326': ItemCodeNoteType.ClinicalNote,
  '85331': ItemCodeNoteType.ClinicalNote,
  '85337': ItemCodeNoteType.ClinicalNote,
  '85338': ItemCodeNoteType.ClinicalNote,
  '85341': ItemCodeNoteType.ClinicalNote,
  '85377': ItemCodeNoteType.ClinicalNote,
  '85378': ItemCodeNoteType.ClinicalNote,
  '85381': ItemCodeNoteType.ClinicalNote,
  '85384': ItemCodeNoteType.ClinicalNote,
  '85386': ItemCodeNoteType.ClinicalNote,
  '85387': ItemCodeNoteType.ClinicalNote,
  '85391': ItemCodeNoteType.ClinicalNote,
  '85392': ItemCodeNoteType.ClinicalNote,
  '85411': ItemCodeNoteType.ClinicalNote,
  '85412': ItemCodeNoteType.ClinicalNote,
  '85414': ItemCodeNoteType.ClinicalNote,
  '85415': ItemCodeNoteType.ClinicalNote,
  '85416': ItemCodeNoteType.ClinicalNote,
  '85417': ItemCodeNoteType.ClinicalNote,
  '85418': ItemCodeNoteType.ClinicalNote,
  '85419': ItemCodeNoteType.ClinicalNote,
  '85431': ItemCodeNoteType.ClinicalNote,
  '85432': ItemCodeNoteType.ClinicalNote,
  '85433': ItemCodeNoteType.ClinicalNote,
  '85434': ItemCodeNoteType.ClinicalNote,
  '85436': ItemCodeNoteType.ClinicalNote,
  '85437': ItemCodeNoteType.ClinicalNote,
  '85438': ItemCodeNoteType.ClinicalNote,
  '85445': ItemCodeNoteType.ClinicalNote,
  '85451': ItemCodeNoteType.ClinicalNote,
  '85452': ItemCodeNoteType.ClinicalNote,
  '85453': ItemCodeNoteType.ClinicalNote,
  '85455': ItemCodeNoteType.ClinicalNote,
  '85457': ItemCodeNoteType.ClinicalNote,
  '85458': ItemCodeNoteType.ClinicalNote,
  '85511': ItemCodeNoteType.ClinicalNote,
  '85512': ItemCodeNoteType.ClinicalNote,
  '85513': ItemCodeNoteType.ClinicalNote,
  '85514': ItemCodeNoteType.ClinicalNote,
  '85515': ItemCodeNoteType.ClinicalNote,
  '85521': ItemCodeNoteType.ClinicalNote,
  '85521.': ItemCodeNoteType.ClinicalNote,
  '85522': ItemCodeNoteType.ClinicalNote,
  '85522.': ItemCodeNoteType.ClinicalNote,
  '85523': ItemCodeNoteType.ClinicalNote,
  '85523.': ItemCodeNoteType.ClinicalNote,
  '85524': ItemCodeNoteType.ClinicalNote,
  '85524.': ItemCodeNoteType.ClinicalNote,
  '85525': ItemCodeNoteType.ClinicalNote,
  '85525.': ItemCodeNoteType.ClinicalNote,
  '85531': ItemCodeNoteType.ClinicalNote,
  '85531.': ItemCodeNoteType.ClinicalNote,
  '85532': ItemCodeNoteType.ClinicalNote,
  '85532.': ItemCodeNoteType.ClinicalNote,
  '85533': ItemCodeNoteType.ClinicalNote,
  '85533.': ItemCodeNoteType.ClinicalNote,
  '85534': ItemCodeNoteType.ClinicalNote,
  '85534.': ItemCodeNoteType.ClinicalNote,
  '85535': ItemCodeNoteType.ClinicalNote,
  '85535.': ItemCodeNoteType.ClinicalNote,
  '85541': ItemCodeNoteType.ClinicalNote,
  '85542': ItemCodeNoteType.ClinicalNote,
  '85543': ItemCodeNoteType.ClinicalNote,
  '85544': ItemCodeNoteType.ClinicalNote,
  '85545': ItemCodeNoteType.ClinicalNote,
  '85551': ItemCodeNoteType.ClinicalNote,
  '85552': ItemCodeNoteType.ClinicalNote,
  '85553': ItemCodeNoteType.ClinicalNote,
  '85554': ItemCodeNoteType.ClinicalNote,
  '85555': ItemCodeNoteType.ClinicalNote,
  '85572': ItemCodeNoteType.ClinicalNote,
  '85574': ItemCodeNoteType.ClinicalNote,
  '85575': ItemCodeNoteType.ClinicalNote,
  '85576': ItemCodeNoteType.ClinicalNote,
  '85577': ItemCodeNoteType.ClinicalNote,
  '85578': ItemCodeNoteType.ClinicalNote,
  '85595': ItemCodeNoteType.ClinicalNote,
  '85596': ItemCodeNoteType.ClinicalNote,
  '85597': ItemCodeNoteType.ClinicalNote,
  '85613': ItemCodeNoteType.ClinicalNote,
  '85615': ItemCodeNoteType.ClinicalNote,
  '85618': ItemCodeNoteType.ClinicalNote,
  '85625': ItemCodeNoteType.ClinicalNote,
  '85627': ItemCodeNoteType.ClinicalNote,
  '85629': ItemCodeNoteType.ClinicalNote,
  '85631': ItemCodeNoteType.ClinicalNote,
  '85632': ItemCodeNoteType.ClinicalNote,
  '85642': ItemCodeNoteType.ClinicalNote,
  '85643': ItemCodeNoteType.ClinicalNote,
  '85644': ItemCodeNoteType.ClinicalNote,
  '85645': ItemCodeNoteType.ClinicalNote,
  '85649': ItemCodeNoteType.ClinicalNote,
  '85651': ItemCodeNoteType.ClinicalNote,
  '85652': ItemCodeNoteType.ClinicalNote,
  '85653': ItemCodeNoteType.ClinicalNote,
  '85655': ItemCodeNoteType.ClinicalNote,
  '85656': ItemCodeNoteType.ClinicalNote,
  '85658': ItemCodeNoteType.ClinicalNote,
  '85659': ItemCodeNoteType.ClinicalNote,
  '85661': ItemCodeNoteType.ClinicalNote,
  '85669': ItemCodeNoteType.ClinicalNote,
  '85671': ItemCodeNoteType.ClinicalNote,
  '85672': ItemCodeNoteType.ClinicalNote,
  '85673': ItemCodeNoteType.ClinicalNote,
  '85711': ItemCodeNoteType.ClinicalNote,
  '85712': ItemCodeNoteType.ClinicalNote,
  '85716': ItemCodeNoteType.ClinicalNote,
  '85719': ItemCodeNoteType.ClinicalNote,
  '85721': ItemCodeNoteType.ClinicalNote,
  '85722': ItemCodeNoteType.ClinicalNote,
  '85727': ItemCodeNoteType.ClinicalNote,
  '85728': ItemCodeNoteType.ClinicalNote,
  '85731': ItemCodeNoteType.ClinicalNote,
  '85732': ItemCodeNoteType.ClinicalNote,
  '85733': ItemCodeNoteType.ClinicalNote,
  '85735': ItemCodeNoteType.ClinicalNote,
  '85736': ItemCodeNoteType.ClinicalNote,
  '85737': ItemCodeNoteType.ClinicalNote,
  '85738': ItemCodeNoteType.ClinicalNote,
  '85739': ItemCodeNoteType.ClinicalNote,
  '85741': ItemCodeNoteType.ClinicalNote,
  '85743': ItemCodeNoteType.ClinicalNote,
  '85744': ItemCodeNoteType.ClinicalNote,
  '85745': ItemCodeNoteType.ClinicalNote,
  '85746': ItemCodeNoteType.ClinicalNote,
  '85751': ItemCodeNoteType.ClinicalNote,
  '85752': ItemCodeNoteType.ClinicalNote,
  '85753': ItemCodeNoteType.ClinicalNote,
  '85761': ItemCodeNoteType.ClinicalNote,
  '85762': ItemCodeNoteType.ClinicalNote,
  '85763': ItemCodeNoteType.ClinicalNote,
  '85764': ItemCodeNoteType.ClinicalNote,
  '85765': ItemCodeNoteType.ClinicalNote,
  '85767': ItemCodeNoteType.ClinicalNote,
  '85768': ItemCodeNoteType.ClinicalNote,
  '85769': ItemCodeNoteType.ClinicalNote,
  '85771': ItemCodeNoteType.ClinicalNote,
  '85772': ItemCodeNoteType.ClinicalNote,
  '85773': ItemCodeNoteType.ClinicalNote,
  '85776': ItemCodeNoteType.ClinicalNote,
  '85777': ItemCodeNoteType.ClinicalNote,
  '85811': ItemCodeNoteType.ClinicalNote,
  '85821': ItemCodeNoteType.ClinicalNote,
  '85823': ItemCodeNoteType.ClinicalNote,
  '85829': ItemCodeNoteType.ClinicalNote,
  '85831': ItemCodeNoteType.ClinicalNote,
  '85911': ItemCodeNoteType.ClinicalNote,
  '85926': ItemCodeNoteType.ClinicalNote,
  '85927': ItemCodeNoteType.ClinicalNote,
  '85949': ItemCodeNoteType.ClinicalNote,
  '85963': ItemCodeNoteType.ClinicalNote,
  '85964': ItemCodeNoteType.ClinicalNote,
  '85965': ItemCodeNoteType.ClinicalNote,
  '85966': ItemCodeNoteType.ClinicalNote,
  '85968': ItemCodeNoteType.ClinicalNote,
  '85971': ItemCodeNoteType.ClinicalNote,
  '85972': ItemCodeNoteType.ClinicalNote,
  '85981': ItemCodeNoteType.ClinicalNote,
  '85986': ItemCodeNoteType.ClinicalNote,
  '88000': ItemCodeNoteType.ClinicalNote,
  '88521.': ItemCodeNoteType.ClinicalNote,
  '88522.': ItemCodeNoteType.ClinicalNote,
  '88523.': ItemCodeNoteType.ClinicalNote,
  '88524.': ItemCodeNoteType.ClinicalNote,
  '88525.': ItemCodeNoteType.ClinicalNote,
  '88526': ItemCodeNoteType.ClinicalNote,
  '88531.': ItemCodeNoteType.ClinicalNote,
  '88532.': ItemCodeNoteType.ClinicalNote,
  '88533.': ItemCodeNoteType.ClinicalNote,
  '88534.': ItemCodeNoteType.ClinicalNote,
  '88535.': ItemCodeNoteType.ClinicalNote,
  '88576': ItemCodeNoteType.ClinicalNote,
  '911A': ItemCodeNoteType.ClinicalNote,
  '912': ItemCodeNoteType.ClinicalNote,
  '922': ItemCodeNoteType.ClinicalNote,
  '931': ItemCodeNoteType.ClinicalNote,
  '932': ItemCodeNoteType.ClinicalNote,
  '969': ItemCodeNoteType.ClinicalNote,
  '988': ItemCodeNoteType.PatientPaymentNote,
  '989': ItemCodeNoteType.PatientPaymentNote,
  '991': ItemCodeNoteType.GeneralPatientNote,
  '992': ItemCodeNoteType.PatientPaymentNote,
  '993': ItemCodeNoteType.ClinicalNote,
  '993A': ItemCodeNoteType.ClinicalNote,
  '994': ItemCodeNoteType.ClinicalNote,
  '995': ItemCodeNoteType.GeneralPatientNote,
  '996': ItemCodeNoteType.PatientPaymentNote,
  '997': ItemCodeNoteType.PatientPaymentNote,
  '998': ItemCodeNoteType.ClinicalNote,
  '9999': ItemCodeNoteType.ClinicalNote,
  STERI: ItemCodeNoteType.SterilisationRecord,
};

export const ITEM_CODE_TO_SERVICE_CODE_MAPPINGS: {
  [key: string]: string;
} = {
  D022: 'D022 Initial',
  'D022.': 'D022 Subsequent',
  D311: 'D311 Initial',
  'D311.': 'D311 Subsequent',
  D314: 'D314 Initial',
  'D314.': 'D314 Subsequent',
  D322: 'D322 Initial',
  'D322.': 'D322 Subsequent',
  D323: 'D323 Initial',
  'D323.': 'D323 Subsequent',
  D324: 'D324 Initial',
  'D324.': 'D324 Subsequent',
  'D521.': 'D521',
  'D522.': 'D522',
  'D523.': 'D523',
  'D524.': 'D524',
  'D525.': 'D525',
  'D531.': 'D531',
  'D532.': 'D532',
  'D533.': 'D533',
  'D534.': 'D534',
  'D535.': 'D535',
  D597: 'D597 Initial',
  'D597.': 'D597 Subsequent',
  S022: 'S022 Initial',
  'S022.': 'S022 Subsequent',
  S311: 'S311 Initial',
  'S311.': 'S311 Subsequent',
  S314: 'S314 Initial',
  'S314.': 'S314 Subsequent',
  S322: 'S322 Initial',
  'S322.': 'S322 Subsequent',
  S323: 'S323 Initial',
  'S323.': 'S323 Subsequent',
  S324: 'S324 Initial',
  'S324.': 'S324 Subsequent',
  'S521.': 'S521',
  'S522.': 'S522',
  'S523.': 'S523',
  'S524.': 'S524',
  'S525.': 'S525',
  'S531.': 'S531',
  'S532.': 'S532',
  'S533.': 'S533',
  'S534.': 'S534',
  'S535.': 'S535',
  S597: 'S597 Initial',
  'S597.': 'S597 Subsequent',
};
