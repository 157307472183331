import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { filterUndefined, isChanged$ } from '@principle-theorem/shared';
import {
  type HealthPointReconciliationReportResponse,
  type IBulkBillEasyclaimRequestParams,
  type IClientWithUI,
  type IEasyclaimCompleteCallback,
  type IEasyclaimRequestParams,
  type IHealthPointCancelClaimRequestParams,
  type IHealthPointClaimRequestParams,
  type IHealthPointReconciliationReportRequestParams,
  type IHealthPointTransactionCompleteCallbackData,
  type IHealthPointTransactionSuccessBaseCallbackData,
  type IPurchaseRequestParams,
  type IPurchaseTransactionCompleteCallbackData,
  type IQuestionCallbackData,
  type IReceiptCallbackData,
  type IReconciliationReportRequestParams,
  type IRefundRequestParams,
  isPairTerminalSuccess,
  type PairTerminalResponse,
  type ReconciliationReportResponse,
  type StatusMessageCallbackData,
} from '@principle-theorem/tyro';
import { Observable, Subject, type Subscriber } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  type ITyroConfigProvider,
  NG_TYRO_CONFIG_PROVIDER,
} from './ng-tyro-config';
import { loadTyroIClient } from './tyro-urls';

@Injectable()
export class TyroService {
  private _iClient$: Observable<IClientWithUI>;
  private _resetTransaction$ = new Subject<void>();
  private _document: Document = inject(DOCUMENT);
  private _config: ITyroConfigProvider = inject(NG_TYRO_CONFIG_PROVIDER);

  constructor() {
    this._iClient$ = this._config.getiClientBaseUrl$().pipe(
      isChanged$(),
      switchMap((iClientUrl) => loadTyroIClient(iClientUrl)),
      map(
        (tyro) =>
          new tyro.IClientWithUI(
            this._config.apiKey,
            this._config.posProductData
          )
      )
    );
  }

  initiateHealthPointClaim$(
    request: IHealthPointClaimRequestParams
  ): Observable<
    | IQuestionCallbackData
    | StatusMessageCallbackData
    | IHealthPointTransactionCompleteCallbackData
  > {
    const addLog = this._methodLogger('initiateHealthPointClaim');
    return this._createCall$((client, subscriber) => {
      addLog('init', { request });
      client.initiateHealthPointClaim(request, {
        statusMessageCallback: (response) => {
          addLog('statusMessageCallback', { request, response });
          subscriber.next(response);
        },
        questionCallback: (response) => {
          addLog('questionCallback', { request, response });
          subscriber.next(response);
        },
        transactionCompleteCallback: (response) => {
          addLog('transactionCompleteCallback', { request, response });
          const log = { request, response };
          // eslint-disable-next-line no-console
          console.log(log);
          // eslint-disable-next-line no-console
          console.log(JSON.stringify(log));
          subscriber.next(response);
          subscriber.complete();
        },
      });
    });
  }

  initiateHealthPointRebateEstimate$(
    request: IHealthPointClaimRequestParams
  ): Observable<
    | IQuestionCallbackData
    | StatusMessageCallbackData
    | IHealthPointTransactionCompleteCallbackData
  > {
    const addLog = this._methodLogger('initiateHealthPointRebateEstimate');
    return this._createCall$((client, subscriber) => {
      addLog('init', { request });
      client.initiateHealthPointRebateEstimate(request, {
        statusMessageCallback: (response) => {
          addLog('statusMessageCallback', { request, response });
          subscriber.next(response);
        },
        questionCallback: (response) => {
          addLog('questionCallback', { request, response });
          subscriber.next(response);
        },
        transactionCompleteCallback: (response) => {
          addLog('transactionCompleteCallback', { request, response });
          const log = { request, response };
          // eslint-disable-next-line no-console
          console.log(log);
          // eslint-disable-next-line no-console
          console.log(JSON.stringify(log));
          subscriber.next(response);
          subscriber.complete();
        },
      });
    });
  }

  initiatePurchase$(
    request: IPurchaseRequestParams
  ): Observable<
    | IPurchaseTransactionCompleteCallbackData
    | StatusMessageCallbackData
    | IQuestionCallbackData
    | IReceiptCallbackData
  > {
    const addLog = this._methodLogger('initiatePurchase');
    return this._createCall$((client, subscriber) => {
      addLog('init', { request });
      client.initiatePurchase(request, {
        statusMessageCallback: (response) => {
          addLog('statusMessageCallback', { request, response });
          subscriber.next(response);
        },
        questionCallback: (response) => {
          addLog('questionCallback', { request, response });
          subscriber.next(response);
        },
        receiptCallback: (response) => {
          addLog('receiptCallback', { request, response });
          subscriber.next(response);
        },
        transactionCompleteCallback: (response) => {
          addLog('transactionCompleteCallback', { request, response });
          const log = { request, response };
          // eslint-disable-next-line no-console
          console.log(log);
          // eslint-disable-next-line no-console
          console.log(JSON.stringify(log));
          subscriber.next(response);
          subscriber.complete();
        },
      });
    });
  }

  initiateFullyPaidEasyclaim$(
    request: IEasyclaimRequestParams
  ): Observable<IEasyclaimCompleteCallback> {
    const addLog = this._methodLogger('initiateFullyPaidEasyclaim');
    return this._createCall$((client, subscriber) => {
      addLog('init', { request });
      client.initiateFullyPaidEasyclaim(request, {
        transactionCompleteCallback: (response) => {
          addLog('transactionCompleteCallback', { request, response });
          const log = { request, response };
          // eslint-disable-next-line no-console
          console.log(log);
          // eslint-disable-next-line no-console
          console.log(JSON.stringify(log));
          subscriber.next(response);
          subscriber.complete();
        },
      });
    });
  }

  initiatePartPaidEasyclaim$(
    request: IEasyclaimRequestParams
  ): Observable<IEasyclaimCompleteCallback> {
    const addLog = this._methodLogger('initiatePartPaidEasyclaim');
    return this._createCall$((client, subscriber) => {
      addLog('init', { request });
      client.initiatePartPaidEasyclaim(request, {
        transactionCompleteCallback: (response) => {
          addLog('transactionCompleteCallback', { request, response });
          const log = { request, response };
          // eslint-disable-next-line no-console
          console.log(log);
          // eslint-disable-next-line no-console
          console.log(JSON.stringify(log));
          subscriber.next(response);
          subscriber.complete();
        },
      });
    });
  }

  initiateBulkBillEasyclaim$(
    request: IBulkBillEasyclaimRequestParams
  ): Observable<IEasyclaimCompleteCallback> {
    const addLog = this._methodLogger('initiateBulkBillEasyclaim');
    return this._createCall$((client, subscriber) => {
      addLog('init', { request });
      client.initiateBulkBillEasyclaim(request, {
        transactionCompleteCallback: (response) => {
          addLog('transactionCompleteCallback', { request, response });
          const log = { request, response };
          // eslint-disable-next-line no-console
          console.log(log);
          // eslint-disable-next-line no-console
          console.log(JSON.stringify(log));
          subscriber.next(response);
          subscriber.complete();
        },
      });
    });
  }

  cancelHealthPointClaim$(
    request: IHealthPointCancelClaimRequestParams
  ): Observable<IHealthPointTransactionSuccessBaseCallbackData> {
    const addLog = this._methodLogger('cancelHealthPointClaim');
    return this._createCall$((client, subscriber) => {
      addLog('init', { request });
      client.cancelHealthPointClaim(request, {
        transactionCompleteCallback: (response) => {
          addLog('transactionCompleteCallback', { request, response });
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const log = { request, response };
          // eslint-disable-next-line no-console
          console.log(log);
          // eslint-disable-next-line no-console
          console.log(JSON.stringify(log));
          subscriber.next(response);
          subscriber.complete();
        },
      });
    });
  }

  initiateRefund$(
    request: IRefundRequestParams
  ): Observable<IPurchaseTransactionCompleteCallbackData> {
    const addLog = this._methodLogger('initiateRefund');
    return this._createCall$((client, subscriber) => {
      addLog('init', { request });
      client.initiateRefund(request, {
        transactionCompleteCallback: (response) => {
          addLog('transactionCompleteCallback', { request, response });
          const log = { request, response };
          // eslint-disable-next-line no-console
          console.log(log);
          // eslint-disable-next-line no-console
          console.log(JSON.stringify(log));
          subscriber.next(response);
          subscriber.complete();
        },
      });
    });
  }

  reconciliationReport$(
    request: IReconciliationReportRequestParams
  ): Observable<ReconciliationReportResponse> {
    const addLog = this._methodLogger('reconciliationReport');
    return this._createCall$((client, subscriber) => {
      addLog('init', { request });
      client.reconciliationReport(request, (response) => {
        addLog('complete', { request, response });
        subscriber.next(response);
        subscriber.complete();
      });
    });
  }

  healthpointReconciliationReport$(
    request: IHealthPointReconciliationReportRequestParams
  ): Observable<HealthPointReconciliationReportResponse> {
    const addLog = this._methodLogger('healthpointReconciliationReport');
    return this._createCall$((client, subscriber) => {
      addLog('init', { request });
      client.healthpointReconciliationReport(request, (response) => {
        addLog('complete', { request, response });
        subscriber.next(response);
        subscriber.complete();
      });
    });
  }

  pairTerminal$(mid: string, tid: string): Observable<PairTerminalResponse> {
    const addLog = this._methodLogger('pairTerminal');
    return this._createCall$((client, subscriber) => {
      addLog('init', { request: { mid, tid } });
      client.pairTerminal(mid, tid, (response) => {
        addLog('complete', { request: { mid, tid }, response });
        subscriber.next(response);
        if (isPairTerminalSuccess(response) || response.status === 'failure') {
          subscriber.complete();
        }
      });
    });
  }

  private _createCall$<Events>(
    apiCallFn: (client: IClientWithUI, subscriber: Subscriber<Events>) => void
  ): Observable<Events> {
    this._resetTransaction$.next();

    return this._iClient$.pipe(
      take(1),
      tap(() => this._resetUI()),
      switchMap((client) =>
        new Observable<Events>((subscriber) =>
          apiCallFn(client, subscriber)
        ).pipe(takeUntil(this._resetTransaction$))
      ),
      filterUndefined()
    );
  }

  private _methodLogger(method: string): (label: string, data: object) => void {
    return (label: string, data: object) => {
      this._config.addLog(`TyroService.${method} - ${label}`, data);
    };
  }

  private _resetUI(): void {
    Array.from(this._document.getElementsByClassName('tyro-iclient-modal')).map(
      (element) => element.remove()
    );
    Array.from(this._document.getElementsByClassName('modal-backdrop')).map(
      (element) => element.remove()
    );
    this._document.getElementById('iclient-div-wrapper')?.remove();
  }
}
