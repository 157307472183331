import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { PatientActionsFactoryService } from '@principle-theorem/ng-interactions';
import { IActionButton, TrackByFunctions } from '@principle-theorem/ng-shared';
import { Appointment, TreatmentStep } from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  ChecklistType,
  type IAppointment,
  type IAutomation,
  type IChecklistItem,
  type IEvent,
  type ILabJob,
  type IPatient,
  type ITreatmentPlan,
  type TreatmentStepAutomation,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isPathChanged$,
  shareReplayHot,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { ReplaySubject, combineLatest, type Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  IAppointmentCardAction,
  type AppointmentCardAction,
} from './appointment-card-actions/actions/appointment-card-action-interface';
import { AppointmentCardWarnings } from './appointment-card-warnings';

interface IWarning {
  title: string;
  message: string;
}

@Component({
    selector: 'pr-appointment-card',
    templateUrl: './appointment-card.component.html',
    styleUrls: ['./appointment-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentCardComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByConflict = TrackByFunctions.variable<string>();
  trackByWarning = TrackByFunctions.nestedField<string>('message');
  appointment$ = new ReplaySubject<WithRef<IAppointment>>(1);
  event$: Observable<IEvent>;
  checklistItems$: Observable<IChecklistItem[]>;
  patient$: Observable<WithRef<IPatient>>;
  labJobs$: Observable<WithRef<ILabJob>[]>;
  treatmentPlan$: Observable<WithRef<ITreatmentPlan>>;
  automations$: Observable<WithRef<IAutomation<TreatmentStepAutomation>>[]>;
  patientActions: IActionButton[];
  schedulingConflicts$: Observable<string[]>;
  warnings$: Observable<IWarning[]>;
  @Input() actions: AppointmentCardAction[] = [];
  @Input() hidePatientHeader = false;
  @Output() updateEventable = new EventEmitter<IAppointmentCardAction>();

  constructor(actionFactory: PatientActionsFactoryService) {
    this.event$ = this.appointment$.pipe(
      map((appointment) => appointment.event),
      filterUndefined()
    );

    this.patient$ = this.appointment$.pipe(
      isPathChanged$('ref.id'),
      switchMap((appointment) => Appointment.patient(appointment)),
      shareReplayHot(this._onDestroy$)
    );

    this.patientActions = [
      actionFactory.edit(this.patient$),
      actionFactory.sms(this.patient$),
      actionFactory.call(this.patient$),
      actionFactory.email(this.patient$),
    ];

    this.treatmentPlan$ = this.appointment$.pipe(
      isPathChanged$('ref.id'),
      switchMap((appointment) => Appointment.treatmentPlan(appointment))
    );

    this.automations$ = this.appointment$.pipe(
      isPathChanged$('ref.id'),
      switchMap((appointment) => Appointment.treatmentStep(appointment)),
      switchMap((step) => TreatmentStep.automations$(step))
    );

    this.checklistItems$ = this._getChecklistItems$();
    this.labJobs$ = this._getLabJobs$();

    this.warnings$ = combineLatest([
      AppointmentCardWarnings.durationWarning$(this.appointment$),
      AppointmentCardWarnings.schedulingRuleConflict$(this.appointment$),
    ]).pipe(
      map(([durationMessage, schedulingMessage]) => {
        const durationWarning = durationMessage
          ? { title: 'Duration Warning', message: durationMessage }
          : undefined;
        const schedulingWarning = schedulingMessage
          ? { title: 'Scheduling Conflict', message: schedulingMessage }
          : undefined;
        return compact([durationWarning, schedulingWarning]);
      })
    );

    this.schedulingConflicts$ = AppointmentCardWarnings.schedulingConflicts$(
      this.appointment$
    );
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  isComplete(): boolean {
    return this.appointment.status === AppointmentStatus.Complete;
  }

  private _getChecklistItems$(): Observable<WithRef<IChecklistItem>[]> {
    return this.appointment$.pipe(
      switchMap((appointment) =>
        Appointment.checklistItems$(appointment, ChecklistType.Pre)
      )
    );
  }

  private _getLabJobs$(): Observable<WithRef<ILabJob>[]> {
    return this.appointment$.pipe(
      switchMap((appointment) => Appointment.labJobs$(appointment))
    );
  }
}
