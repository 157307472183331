<div fxLayout="column" [ngClass.gt-sm]="{ wrapper: true }">
  <div class="invoice-view">
    <div
      *ngIf="isLoading$ | async"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <mat-spinner />
      <p class="mat-caption">Loading your invoice...</p>
    </div>

    <ng-container *ngIf="invoice$ | async as invoice">
      <div class="buttons-container">
        <pt-buttons-container fxLayoutAlign="end" fxLayoutAlign.gt-sm="end">
          <button
            mat-stroked-button
            (click)="printInvoice()"
            [ngStyle.lt-md]="{ display: 'none' }"
          >
            <mat-icon>print</mat-icon>
            Print
          </button>
          <button mat-stroked-button (click)="downloadInvoice()">
            <mat-icon>file_download</mat-icon>
            Download
          </button>
        </pt-buttons-container>
      </div>
      <div #invoiceDisplay>
        <pr-invoice-display
          [invoice]="invoice"
          [balance]="balance$ | async"
          [groups]="groups$ | async"
        />
        @if (transactions$ | async; as transactions) {
          <div *ngIf="transactions.length" class="text-2xl font-bold">
            Transactions
          </div>
          @for (transaction of transactions; track transaction.uid) {
            <pr-patient-view-invoice-transaction [transaction]="transaction" />
          }
        }
      </div>
    </ng-container>
  </div>
</div>
