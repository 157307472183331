<mat-toolbar color="accent">
  <span>{{ data ? 'Update' : 'Add' }} Prescription Item</span>
</mat-toolbar>

<mat-dialog-content>
  <pr-prescription-item-form
    [formGroup]="prescriptionItemFormGroup"
    [compact]="false"
  />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      [disabled]="prescriptionItemFormGroup.invalid"
      (click)="save()"
      color="primary"
    >
      Save
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
