import { NgModule } from '@angular/core';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ComponentsModule } from '../../components/components.module';
import { TreatmentConfigEditComponent } from './treatment-config-edit/treatment-config-edit.component';
import { TreatmentConfigListComponent } from './treatment-config-list/treatment-config-list.component';
import { TreatmentConfigResolverService } from './treatment-config-resolver.service';

@NgModule({
  imports: [NgSharedModule, ComponentsModule, NgFeatureFlagsModule],
  declarations: [TreatmentConfigListComponent, TreatmentConfigEditComponent],
  providers: [TreatmentConfigResolverService],
})
export class TreatmentConfigModule {}
