<mat-toolbar color="accent">Pairing Terminal</mat-toolbar>
<mat-dialog-content>
  <mat-progress-bar mode="indeterminate" *ngIf="loading" />
  <div fxLayout="column" fxLayoutGap="16px">
    <p *ngIf="log">
      <strong>{{ log.status | splitCamel | uppercase }}:</strong>
      {{ log.message }}
    </p>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
