import { type ISource } from '@principle-theorem/principle-core/interfaces';
import { BaseSourceHandler } from '../source/base-source-handler';
import { ADAItemSourceEntity } from './source/entities/ada-item';
import { PatientAppointmentBookSourceEntity } from './source/entities/appointment-book';
import { AppointmentStatusTypeSourceEntity } from './source/entities/appointment-status-type';
import { ContactSourceEntity } from './source/entities/contact';
import { FeeScheduleSourceEntity } from './source/entities/fee-schedule';
import { PatientSourceEntity } from './source/entities/patient';
import { PatientAppointmentSourceEntity } from './source/entities/patient-appointment';
import { PatientAppointmentCancellationSourceEntity } from './source/entities/patient-appointment-cancellation';
import { PatientChartSourceEntity } from './source/entities/patient-chart';
import { PatientClaimSourceEntity } from './source/entities/patient-claim';
import { PatientCommunicationSourceEntity } from './source/entities/patient-communication';
import { PatientCommunicationReplySourceEntity } from './source/entities/patient-communication-reply';
import { PatientDepositSourceEntity } from './source/entities/patient-deposit';
import { PatientDocumentSourceEntity } from './source/entities/patient-document';
import { PatientFileCategorySourceEntity } from './source/entities/patient-file-category';
import { PatientHealthFundSourceEntity } from './source/entities/patient-health-fund';
import { PatientInvoiceSourceEntity } from './source/entities/patient-invoice';
import { PatientMissingToothSourceEntity } from './source/entities/patient-missing-teeth';
import { PatientPerioChartSourceEntity } from './source/entities/patient-perio-chart';
import { PatientPreExistingTreatmentSourceEntity } from './source/entities/patient-pre-existing-treatment';
import { PatientReferralSourceEntity } from './source/entities/patient-referral-sources';
import { PatientStatusSourceEntity } from './source/entities/patient-status';
import { PatientToothConditionSourceEntity } from './source/entities/patient-tooth-condition';
import { PatientTreatmentSourceEntity } from './source/entities/patient-treatment';
import { PatientTreatmentNoteSourceEntity } from './source/entities/patient-treatment-note';
import { StafferSourceEntity } from './source/entities/staff';
import { SterilisationRecordSourceEntity } from './source/entities/sterilisation-records';
import { D4WPatientsHFTranslator } from './sql-seeding-translators/patients-hf';
import { PatientProfilePhotoSourceEntity } from './source/entities/patient-profile-photos.ts';

export const D4W_SOURCE: ISource = {
  metadata: {
    name: 'D4W',
    version: 'v1',
  },
  configuration: [
    {
      name: 'Practice Id',
      description: 'D4W Practice Id',
      value: '',
    },
    {
      name: 'Database Username',
      description: 'Database Username',
      value: '',
    },
    {
      name: 'Database Password',
      description: 'Database Password',
      value: '',
    },
    {
      name: 'Database Connection Name',
      description:
        'Fully Qualified Name of Database. Eg. "practice-migrations:australia-southeast1:[instance_name]"',
      value: '',
    },
    {
      name: 'Source Bucket Storage Path',
      description: `Bucket storage path that patient files live under Eg. "gs://practice-name'`,
      value: '',
    },
    {
      name: 'Source Bucket MediaSuite Storage Path',
      description: `Folder name that patient MediaSuite files live under in the Source Bucket Storage Path eg: 'mediasuite'`,
      value: '',
    },
  ],
};

export class D4WSourceHandler extends BaseSourceHandler {
  migrationType = 'D4W';
  migrationVersion = 'v1';
  source = D4W_SOURCE;

  entityHandlers = [
    new PatientSourceEntity(),
    new FeeScheduleSourceEntity(),
    new StafferSourceEntity(),
    new PatientAppointmentSourceEntity(),
    new PatientAppointmentCancellationSourceEntity(),
    new PatientCommunicationSourceEntity(),
    new PatientCommunicationReplySourceEntity(),
    new ADAItemSourceEntity(),
    new PatientTreatmentSourceEntity(),
    new PatientTreatmentNoteSourceEntity(),
    new ContactSourceEntity(),
    new PatientChartSourceEntity(),
    new PatientMissingToothSourceEntity(),
    new PatientToothConditionSourceEntity(),
    new PatientPerioChartSourceEntity(),
    new PatientInvoiceSourceEntity(),
    new PatientDepositSourceEntity(),
    new PatientClaimSourceEntity(),
    new AppointmentStatusTypeSourceEntity(),
    new PatientAppointmentBookSourceEntity(),
    new PatientStatusSourceEntity(),
    new PatientDocumentSourceEntity(),
    new PatientFileCategorySourceEntity(),
    new SterilisationRecordSourceEntity(),
    new PatientPreExistingTreatmentSourceEntity(),
    new PatientHealthFundSourceEntity(),
    new PatientReferralSourceEntity(),
    new PatientProfilePhotoSourceEntity(),
  ];

  sqlSeedingTranslators = [new D4WPatientsHFTranslator()];
}
