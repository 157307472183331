<ng-container *ngrxLet="vertical$ as vertical">
  <div
    *ngrxLet="scoreClass$ as scoreClass"
    [fxLayout]="vertical ? 'column' : 'row'"
    fxLayoutGap="2px"
  >
    <div
      class="rating-score high"
      [ngClass]="{ disabled: !scoreClass.high }"
      matTooltip="This is a good choice"
      [matTooltipDisabled]="!scoreClass.high"
    ></div>
    <div
      class="rating-score medium"
      [ngClass]="{ disabled: !scoreClass.medium }"
      matTooltip="This choice is a compromise. The duration is shorter than required, or overlaps other events."
      [matTooltipDisabled]="!scoreClass.medium"
    ></div>
    <div
      class="rating-score low"
      [ngClass]="{ disabled: !scoreClass.low }"
      matTooltip="This is a bad choice. The duration is far too short, or overlaps other events."
      [matTooltipDisabled]="!scoreClass.low"
    ></div>
  </div>
</ng-container>
