<ng-template #showLoader>
  <div class="loader" fxFill fxLayoutAlign="center center">
    <mat-spinner />
  </div>
</ng-template>

<ng-container *ngIf="isDicom$ | async; else imageSource">
  <pr-dicom-viewer
    *ngIf="media$ | async as dicomMedia"
    [media]="dicomMedia"
    [hideToolbar]="true"
   />
</ng-container>
<ng-template #imageSource>
  <ng-container *ngIf="loaded$ | async; else showLoader">
    <ng-container *ngIf="fileUrl$ | async as url; else fileIcon">
      <img [src]="url" />
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #fileIcon>
  <div
    fxFill
    fxLayout="column"
    fxLayoutAlign="center center"
    *ngIf="fileExtension$ | async as extension"
  >
    <file-icon set="vivid" size="xl" [type]="extension" />
  </div>
</ng-template>

<div class="overlay" fxLayout="column">
  <div fxFlex class="tags">
    <pr-inline-tags-display
      size="sm"
      [tags]="namedDocsToTags.tags$ | async"
     />
  </div>

  <ng-container *ngIf="media$ | async as media">
    <div class="filename" fxLayout="column" fxLayoutAlign="center">
      <div>{{ media.name | removeStorageUid }}</div>
      <span class="mat-caption">
        {{ media.createdAt | moment | amDateFormat : dateFormat }}
      </span>
      <div
        *ngIf="media | map : hasExpired; else hasExpiry"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="8px"
      >
        <mat-icon color="warn">warning</mat-icon>
        <div class="mat-caption expired">This media has expired</div>
      </div>
      <ng-template #hasExpiry>
        <div class="expiry mat-caption" *ngIf="media.expiresAt">
          Expires: {{ media.expiresAt | moment | amDateFormat : dateFormat }}
        </div>
      </ng-template>
    </div>
  </ng-container>
</div>
