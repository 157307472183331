import { createAction, props } from '@ngrx/store';
import { type SerialisedData } from '@principle-theorem/shared';
import { type CalendarEventEntity } from '../models';

export enum CalendarEventsCollectionAction {
  LoadCalendarEventsSuccess = '[Calendar Events Collection] Load Calendar Events Success',
  LoadCalendarEventsFailure = '[Calendar Events Collection] Load Calendar Events Failure',
  DeleteCalendarEventSuccess = '[Calendar Events Collection] Delete Calendar Event Success',
}

interface ILoadCalendarEventsSuccessData {
  calendarEvents: CalendarEventEntity[];
}

interface ICalendarEventsCollectionError {
  error: string;
}

export const loadCalendarEventsSuccess = createAction(
  CalendarEventsCollectionAction.LoadCalendarEventsSuccess,
  props<SerialisedData<ILoadCalendarEventsSuccessData>>()
);

export const loadCalendarEventsFail = createAction(
  CalendarEventsCollectionAction.LoadCalendarEventsFailure,
  props<ICalendarEventsCollectionError>()
);

export const deleteCalendarEventSuccess = createAction(
  CalendarEventsCollectionAction.DeleteCalendarEventSuccess
);
