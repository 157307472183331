import { initVersionedSchema, toTextContent } from '@principle-theorem/editor';
import {
  Brand,
  Event,
  Interaction,
  TreatmentPlan,
  TreatmentStep,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  EventType,
  FailedDestinationEntityRecord,
  IDestinationEntity,
  IDestinationEntityJobRunOptions,
  ITag,
  ITranslationMap,
  ITreatmentCategory,
  InteractionType,
  ParticipantType,
  SkippedDestinationEntityRecord,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  isTag,
  type IDestinationEntityRecord,
  type IEvent,
  type IGetRecordResponse,
  type IInteractionV2,
  type IPatient,
  type IPractice,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  HISTORY_DATE_FORMAT,
  Timezone,
  getError,
  initFirestoreModel,
  isINamedDocument,
  snapshotCombineLatest,
  toMomentTz,
  toNamedDocument,
  toTimestamp,
  type INamedDocument,
  type WithRef,
  asyncForAll,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import {
  BasePatientAppointmentDestinationEntity,
  IPatientAppointmentBuildData,
  IPatientAppointmentJobData,
  IPatientAppointmentMigrationData,
  PATIENT_APPOINTMENT_RESOURCE_TYPE,
} from '../../../destination/entities/patient-appointments';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { ItemCodeResourceMapType } from '../../../mappings/item-codes-to-xlsx';
import { TranslationMapHandler } from '../../../translation-map';
import {
  IExactPatient,
  IExactPatientTranslations,
  PatientSourceEntity,
} from '../../source/entities/patient';
import {
  AppointmentSourceEntity,
  ExactAppointmentStatus,
  type IExactAppointment,
  type IExactAppointmentTranslations,
} from '../../source/entities/patient-appointment';
import { PatientNotesSourceEntity } from '../../source/entities/patient-notes';
import { PatientTreatmentSourceEntity } from '../../source/entities/patient-treatments';
import { AppointmentCategoryToTreatmentCategoryMappingHandler } from '../mappings/appointment-category-to-treatment-category';
import { AppointmentRoomToPractionerMappingHandler } from '../mappings/appointment-room-to-practitioner';
import { AppointmentCategoryDestination } from '../mappings/appointment-to-treatment-category-to-xslx';
import { ExactItemCodeMappingHandler } from '../mappings/item-codes';
import {
  StaffToPracticeMapping,
  resolveExactStaffLocation,
} from '../mappings/practitioner-to-practice-mapping';
import {
  ExactStafferMappingHandler,
  resolveExactStaffer,
} from '../mappings/staff';
import { PatientDestinationEntity } from './patient';
import { StafferDestinationEntity } from './staff';
import { compact } from 'lodash';

export const PATIENT_APPOINTMENT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_APPOINTMENT_RESOURCE_TYPE,
    label: 'Patient Appointments',
    description: '',
  },
});

interface IJobData
  extends IPatientAppointmentJobData<IExactPatient, IExactPatientTranslations> {
  appointmentRoomToPractitioner: WithRef<ITranslationMap<IStaffer>>[];
  appointmentToTreatmentCategory: WithRef<
    ITranslationMap<AppointmentCategoryDestination>
  >[];
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[];
  staffToPractice: WithRef<ITranslationMap<IPractice>>[];
}

export const EXACT_MIGRATED_APPOINTMENT_PLAN_NAME = `Exact - Migrated Appointments`;

export class PatientAppointmentDestinationEntity extends BasePatientAppointmentDestinationEntity<
  IExactPatient,
  IExactPatientTranslations,
  IJobData
> {
  destinationEntity = PATIENT_APPOINTMENT_DESTINATION_ENTITY;
  patientSourceEntity = new PatientSourceEntity();
  treatmentPlanName = EXACT_MIGRATED_APPOINTMENT_PLAN_NAME;

  override canMigrateByDateRange = true;
  override batchLimit = 10000;

  override filters = [
    new PatientIdFilter<IJobData>((jobData) =>
      jobData.sourcePatient.data.data.patient_id.toString()
    ),
  ];

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new AppointmentSourceEntity(),
    notes: new PatientNotesSourceEntity(),
    treatments: new PatientTreatmentSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new ExactStafferMappingHandler(),
    appointmentRoomToPractitioner:
      new AppointmentRoomToPractionerMappingHandler(),
    appointmentToTreatmentCategory:
      new AppointmentCategoryToTreatmentCategoryMappingHandler(),
    itemCodes: new ExactItemCodeMappingHandler(),
    staffToPractice: new StaffToPracticeMapping(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IJobData[]> {
    const brand$ = Firestore.doc$(migration.configuration.brand.ref);
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const practitioners$ = brand$.pipe(
      switchMap((brand) => Firestore.getDocs(Brand.stafferCol(brand)))
    );
    const appointmentRoomToPractitioner$ =
      this.customMappings.appointmentRoomToPractitioner.getRecords$(
        translationMap
      );
    const appointmentToTreatmentCategory$ =
      this.customMappings.appointmentToTreatmentCategory.getRecords$(
        translationMap
      );
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);
    const staffToPractice$ =
      this.customMappings.staffToPractice.getRecords$(translationMap);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([
        brand$,
        staff$,
        practitioners$,
        appointmentRoomToPractitioner$,
        appointmentToTreatmentCategory$,
        sourceItemCodes$,
        staffToPractice$,
      ]),
    ]).pipe(
      map(
        ([
          patients,
          [
            brand,
            staff,
            practitioners,
            appointmentRoomToPractitioner,
            appointmentToTreatmentCategory,
            sourceItemCodes,
            staffToPractice,
          ],
        ]) =>
          patients.map((sourcePatient) => ({
            sourcePatient,
            brand,
            staff,
            practitioners,
            appointmentRoomToPractitioner,
            appointmentToTreatmentCategory,
            sourceItemCodes,
            staffToPractice,
          }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IJobData
  ): Promise<
    | IPatientAppointmentMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
    | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients.getSourceRecordId(
      data.sourcePatient.data.data
    );

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `No patient with id ${sourcePatientId}`
      );
    }

    const patient = await Firestore.getDoc(patientRef);

    const sourceAppointments =
      await this.sourceEntities.appointments.filterRecords(
        migration,
        'patientId',
        data.sourcePatient.data.data.patient_id.toString()
      );

    try {
      const appointments: (IPatientAppointmentBuildData | undefined)[] =
        await asyncForAll(sourceAppointments, async (sourceAppointment) => {
          const appointmentUid =
            this.sourceEntities.appointments.getSourceRecordId(
              sourceAppointment.data.data
            );

          const practitioner = await this.resolvePractitioner(
            data,
            translationMap,
            sourceAppointment.data.data
          );
          if (!practitioner) {
            throw new Error(
              `No practitioner found for id ${sourceAppointment.data.data.practitioner_initials}`
            );
          }

          const practice = await resolveExactStaffLocation(
            sourceAppointment.data.data.practitioner_initials,
            translationMap,
            data.staffToPractice,
            migration.configuration.practices
          );
          if (!practice) {
            throw new Error(
              `No practice found for practitioner ${sourceAppointment.data.data.practitioner_initials}`
            );
          }

          const [appointment, interactions] = await this._buildAppointmentData(
            migration,
            patient,
            sourceAppointment,
            practitioner,
            practice
          );

          if (!appointment) {
            return;
          }

          const planUid = `${sourcePatientId}-appointments`;
          const stepUid = `${sourcePatientId}-${appointmentUid}-appointments`;
          const plan = TreatmentPlan.init({
            name: EXACT_MIGRATED_APPOINTMENT_PLAN_NAME,
            status: TreatmentPlanStatus.InProgress,
          });

          const timezone = migration.configuration.timezone;
          const appointmentDate = toMomentTz(
            sourceAppointment.data.translations.appointmentDateTime,
            timezone
          ).format(HISTORY_DATE_FORMAT);

          const categoryDestinationIdentifier =
            data.appointmentToTreatmentCategory.find(
              (categoryMap) =>
                categoryMap.sourceIdentifier ===
                sourceAppointment.data.data.appointment_category
            )?.associatedValue;

          const categoryDestination = isINamedDocument(
            categoryDestinationIdentifier
          )
            ? await Firestore.getDoc(categoryDestinationIdentifier.ref)
            : undefined;

          const overrideTreatmentCategory = isTag(categoryDestination)
            ? undefined
            : (categoryDestination?.ref as DocumentReference<ITreatmentCategory>);

          const appointmentTag = isTag(categoryDestination)
            ? (categoryDestination as INamedDocument<ITag>)
            : undefined;
          if (appointmentTag) {
            appointment.tags.push(appointmentTag);
          }

          const step = TreatmentStep.init({
            name: `Exact Appointment - ${appointmentDate}`,
            status:
              appointment.status === AppointmentStatus.Complete
                ? TreatmentStepStatus.Complete
                : TreatmentStepStatus.Incomplete,
            schedulingRules: {
              duration: appointment.event
                ? Event.duration(appointment.event)
                : 0,
            },
            display: {
              overrideTreatmentCategory,
            },
          });

          return {
            sourcePatientId: sourcePatientId.toString(),
            patientRef,
            appointmentUid,
            createdAt: sourceAppointment.data.translations.appointmentDateTime,
            appointment,
            planUid,
            plan,
            stepUid,
            step,
            interactions,
          };
        });

      return {
        patientRef,
        sourcePatientId: sourcePatientId.toString(),
        appointments: compact(appointments),
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  async resolvePractitioner(
    data: IJobData,
    translationMap: TranslationMapHandler,
    sourceAppointment: IExactAppointment
  ): Promise<WithRef<IStaffer> | undefined> {
    if (sourceAppointment.room_id) {
      const appointmentBookPractitionerMap =
        data.appointmentRoomToPractitioner.find(
          (practitionerMap) =>
            practitionerMap.sourceIdentifier === sourceAppointment.room_id
        );

      if (appointmentBookPractitionerMap?.sourceLink?.sourceIdentifier) {
        return resolveExactStaffer(
          appointmentBookPractitionerMap.sourceLink.sourceIdentifier,
          translationMap,
          data.staff
        );
      }
    }

    return resolveExactStaffer(
      sourceAppointment.practitioner_initials,
      translationMap,
      data.staff
    );
  }

  private async _buildAppointmentData(
    migration: WithRef<IPracticeMigration>,
    patient: WithRef<IPatient>,
    sourceAppointment: IGetRecordResponse<
      IExactAppointment,
      IExactAppointmentTranslations
    >,
    practitioner: WithRef<IStaffer>,
    practice: WithRef<IPractice>
  ): Promise<
    | [IPatientAppointmentBuildData['appointment'], IInteractionV2[]]
    | [undefined, undefined]
  > {
    // Their table does not contain a created_at date so we will use the appointment date
    const createdAt = sourceAppointment.data.translations.appointmentDateTime;
    const status = convertExactAppointmentStatus(
      sourceAppointment.data.data.appointment_status
    );
    const interactions: IInteractionV2[] = await this._buildInteractions(
      sourceAppointment,
      migration
    );

    if (sourceAppointment.data.data.appointment_note) {
      interactions.unshift(
        Interaction.init({
          type: InteractionType.Note,
          title: [toTextContent('Appointment note')],
          content: initVersionedSchema(
            sourceAppointment.data.data.appointment_note
          ),
          pinned: true,
          createdAt,
        })
      );
    }

    if (status === AppointmentStatus.Unscheduled) {
      return [undefined, undefined];
    }

    const appointmentEvent = this._getAppointmentEvent(
      sourceAppointment,
      patient,
      practice,
      practitioner,
      migration.configuration.timezone
    );

    const appointment: IPatientAppointmentBuildData['appointment'] = {
      event: appointmentEvent,
      eventHistory: [
        {
          event: appointmentEvent,
          createdAt,
        },
      ],
      status,
      statusHistory: [],
      practice: toNamedDocument(practice),
      practitioner: stafferToNamedDoc(practitioner),
      cancellationHistory: [],
      dependencies: [],
      tags: [],
      ...initFirestoreModel(),
    };

    return [appointment, interactions];
  }

  private async _buildInteractions(
    sourceAppointment: IGetRecordResponse<
      IExactAppointment,
      IExactAppointmentTranslations
    >,
    migration: WithRef<IPracticeMigration>
  ): Promise<IInteractionV2[]> {
    const notes = await this.sourceEntities.notes.filterRecords(
      migration,
      'appointmentId',
      sourceAppointment.data.data.appointment_id
    );
    return notes.map((note) => ({
      ...Interaction.init({
        type: InteractionType.Note,
        title: [toTextContent('Appointment note')],
        content: initVersionedSchema(note.data.data.note),
        createdAt: note.data.translations.entryDate,
      }),
    }));
  }

  private _getAppointmentEvent(
    sourceAppointment: IGetRecordResponse<
      IExactAppointment,
      IExactAppointmentTranslations
    >,
    patient: WithRef<IPatient>,
    practice: WithRef<IPractice>,
    practitioner: WithRef<IStaffer>,
    timezone: Timezone
  ): IEvent {
    const to = toTimestamp(
      toMomentTz(
        sourceAppointment.data.translations.appointmentDateTime,
        timezone
      ).add(moment.duration(sourceAppointment.data.data.appointment_duration))
    );
    return Event.init({
      from: sourceAppointment.data.translations.appointmentDateTime,
      to,
      practice: toNamedDocument(practice),
      type: EventType.Appointment,
      participants: [
        {
          ...toNamedDocument(patient),
          type: ParticipantType.Patient,
        },
        {
          ...stafferToNamedDoc(practitioner),
          type: ParticipantType.Staffer,
        },
      ],
    });
  }
}

function convertExactAppointmentStatus(
  status: ExactAppointmentStatus
): AppointmentStatus {
  switch (status) {
    case ExactAppointmentStatus.Booked:
      return AppointmentStatus.Scheduled;
    case ExactAppointmentStatus.Cancelled:
      return AppointmentStatus.Unscheduled;
    case ExactAppointmentStatus.Failed:
      return AppointmentStatus.Cancelled;
    case ExactAppointmentStatus.Complete:
      return AppointmentStatus.Complete;
    case ExactAppointmentStatus.Confirmed:
      return AppointmentStatus.Confirmed;
    default:
      return AppointmentStatus.Scheduled;
  }
}
