interface IBaseRecoverableErrorData {
  timestamp: string;
  functionName: string;
  processed: boolean;
  error: string;
  triggerType: string;
  metadata: unknown;
  data: string;
}

export interface IFirestoreEventRecoverableError
  extends IBaseRecoverableErrorData {
  triggerType: 'firestore-event';
  metadata: {
    documentPath: string;
  };
}

export interface IPubSubRecoverableError extends IBaseRecoverableErrorData {
  triggerType: 'pub-sub';
  metadata: {
    topic: string;
  };
}

export interface IHttpsFunctionRecoverableError
  extends IBaseRecoverableErrorData {
  triggerType: 'https-function';
  metadata: {
    // nothing
  };
}

export type RecoverableErrorData =
  | IFirestoreEventRecoverableError
  | IPubSubRecoverableError
  | IHttpsFunctionRecoverableError;
