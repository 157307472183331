import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'predicate',
})
export class PredicatePipe implements PipeTransform {
  transform<T, R>(
    value: T,
    predicateFn?: (value: T) => boolean,
    thisArg?: R
  ): boolean {
    if (thisArg && predicateFn) {
      predicateFn = predicateFn.bind(thisArg);
    }
    return predicateFn ? predicateFn(value) : !!value;
  }
}
