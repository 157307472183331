import {
  initRawSchema,
  RawSchema,
  toMentionContent,
  toParagraphContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  INotification,
  INotificationContextRenderer,
} from '@principle-theorem/notifications';
import {
  NotificationType,
  IRescheduledAppointmentResources,
  isAppointmentResource,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  CALENDAR_TIME_FORMAT,
  IReffable,
  toMoment,
} from '@principle-theorem/shared';
import { Event } from '../../event/event';
import { toMention } from '../../mention/mention';

export class PatientRescheduledAppointmentContextRenderer
  implements
    INotificationContextRenderer<
      RawSchema,
      NotificationType,
      IRescheduledAppointmentResources
    >
{
  canRender(notification: INotification): boolean {
    return (
      notification.type ===
      String(NotificationType.PatientRescheduledAppointment)
    );
  }

  isResource(data: unknown): data is IRescheduledAppointmentResources {
    return isAppointmentResource<IRescheduledAppointmentResources>(data);
  }

  render(
    data: IRescheduledAppointmentResources,
    _currentStaffer?: IReffable
  ): RawSchema {
    const originalEvent = data.appointment.event.before
      ? Event.init(data.appointment.event.before)
      : undefined;
    const rescheduledEvent = Event.init(data.appointment.event.after);
    const rescheduledStartTime: string = toMoment(
      rescheduledEvent.from
    ).calendar(undefined, CALENDAR_TIME_FORMAT);

    const patientMention = toMention(data.patient, MentionResourceType.Patient);
    const appointmentToMention = toMention(
      { name: rescheduledStartTime, ref: data.appointment.ref },
      MentionResourceType.Appointment
    );

    if (!originalEvent) {
      return initRawSchema([
        toParagraphContent([
          toMentionContent(patientMention),
          toTextContent(' rescheduled to '),
          toMentionContent(appointmentToMention),
        ]),
      ]);
    }

    const originalStartTime: string = toMoment(originalEvent.from).calendar(
      undefined,
      CALENDAR_TIME_FORMAT
    );
    const appointmentFromMention = toMention(
      { name: originalStartTime, ref: data.appointment.ref },
      MentionResourceType.Appointment
    );

    return initRawSchema([
      toParagraphContent([
        toMentionContent(patientMention),
        toTextContent(' rescheduled from '),
        toMentionContent(appointmentFromMention),
        toTextContent(' to '),
        toMentionContent(appointmentToMention),
      ]),
    ]);
  }
}
