import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DYNAMIC_COMPONENT_DATA } from '@principle-theorem/ng-shared';
import {
  type IDiscountExtendedData,
  isDiscountExtendedData,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import { getDoc } from '@principle-theorem/shared';
import { type ITransactionExtendedDataDisplayData } from '../../../transactions-display/transaction-display/transaction-display-line/transaction-display-line.component';

@Component({
  selector: 'pr-discount-extended-data',
  templateUrl: './discount-extended-data.component.html',
  styleUrls: ['./discount-extended-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountExtendedDataComponent {
  practitionerRef?: DocumentReference<IStaffer>;

  constructor(
    @Inject(DYNAMIC_COMPONENT_DATA)
    public data: ITransactionExtendedDataDisplayData<IDiscountExtendedData>
  ) {
    this._loadData(data.transaction.extendedData);
  }

  async getPractitionerName(
    practitionerRef: DocumentReference<IStaffer>
  ): Promise<string> {
    const practitioner = await getDoc(practitionerRef);
    return practitioner.user.name;
  }

  private _loadData(data?: IDiscountExtendedData): void {
    if (isDiscountExtendedData(data)) {
      this.practitionerRef = data.practitionerRef;
    }
  }
}
