import {
  TextFormatting,
  type IMenuButton,
  BlockNodes,
} from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../menu-bar/basic-menu-button/basic-menu-button.component';

export const ITALIC_KEYMAP = 'Mod-i';

export function createItalicMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'basic',
      buttonText: 'Italic',
      tooltip: 'Italic',
      shortcut: ITALIC_KEYMAP,
      icon: 'format_italic',
      command: (editor) => editor.chain().focus().toggleItalic().run(),
      isActiveFn: (editor) => editor.isActive(TextFormatting.Italic),
      canActivateFn: (editor) => editor.isActive(BlockNodes.Paragraph),
    },
  });
}
