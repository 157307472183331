import { type Observable, combineLatest } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { type NotificationType } from '@principle-theorem/principle-core/interfaces';
import { type INotification } from '@principle-theorem/notifications';
import { type WithRef } from '@principle-theorem/shared';

export class NotificationTypeFilter {
  results$: Observable<WithRef<INotification>[]>;

  constructor(
    items$: Observable<WithRef<INotification>[]>,
    selectedNotificationTypes$: Observable<NotificationType[]>
  ) {
    this.results$ = combineLatest(selectedNotificationTypes$, items$).pipe(
      map(([notificationTypes, notifications]) => {
        if (!notificationTypes.length) {
          return notifications;
        }
        return notifications.filter((notification) =>
          notificationTypes.some(
            (notificationType: string) => notification.type === notificationType
          )
        );
      }),
      startWith([])
    );
  }
}
