<ng-container *ngrxLet="currentStatus$ | async as currentStatus">
  <pt-workflow-update-button
    *ngrxLet="nextStatus$ as nextStatus"
    disablePrefix
    [color]="color"
    [type]="type"
    [currentState]="currentStatus.label"
    [nextState]="nextStatus.label"
    (buttonClick)="updateStatus(nextStatus.status)"
  >
    <ng-container
      *ngFor="let status of statuses; trackBy: trackByStatus; index as index"
    >
      <div
        [matTooltip]="
          { status, index }
            | map : buildStatusTooltip$ : this | async
        "
        [matTooltipDisabled]="({ status, index }| map : isDisabled$ : this| async) === false"
        matTooltipShowDelay="500"
        matTooltipPosition="right"
      >
        <button
          *ngIf="status | map : buildStatusWithLabel as statusWithLabel"
          mat-menu-item
          [ngClass]="{
            'current-status': status | map : isCurrentStatus$ : this | async
          }"
          [disabled]="
            { status, index }
              | map : isDisabled$ : this
              | async
          "
          (click)="updateStatus(status)"
        >
          {{ status | splitCamel | titlecase }}
        </button>
      </div>
    </ng-container>
  </pt-workflow-update-button>
</ng-container>
