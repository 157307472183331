import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  PasswordSetForm,
  type IPasswordSet,
} from '../password-form/password-forms';
import { type IUserSession } from '@principle-theorem/ng-principle-shared';

@Component({
  selector: 'pr-switch-user-dialog',
  templateUrl: './switch-user-dialog.component.html',
  styleUrls: ['./switch-user-dialog.component.sass'],
})
export class SwitchUserDialogComponent {
  form: PasswordSetForm = new PasswordSetForm();

  constructor(
    @Inject(MAT_DIALOG_DATA) public user: IUserSession,
    private _dialogRef: MatDialogRef<SwitchUserDialogComponent>
  ) {}

  submit(): void {
    if (this.form.invalid) {
      return;
    }
    const data: IPasswordSet = this.form.getRawValue();
    this._dialogRef.close(data.password);
  }
}
