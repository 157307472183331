import {
  initVersionedSchema,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  Interaction,
  stafferToNamedDoc,
  toMention,
} from '@principle-theorem/principle-core';
import {
  ITranslationMap,
  InteractionType,
  MentionResourceType,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IInteractionV2,
  type IPatient,
  type IPracticeMigration,
  type IStaffer,
  IHasSourceIdentifier,
  IGetRecordResponse,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  toMomentTz,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import {
  BasePatientInteractionDestinationEntity,
  IBasePatientInteractionJobData,
  IPatientInteractionMigrationData,
} from '../../../destination/entities/patient-interactions';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import {
  ITEM_CODE_NOTE_TO_INTERACTION_MAP,
  ItemCodeNoteType,
} from '../../../mappings/item-codes-to-notes-xlsx';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { TranslationMapHandler } from '../../../translation-map';
import {
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
  type IExactPatient,
} from '../../source/entities/patient';
import { PatientNotesSourceEntity } from '../../source/entities/patient-notes';
import {
  IExactTreatment,
  IExactTreatmentTranslations,
  PatientTreatmentSourceEntity,
} from '../../source/entities/patient-treatments';
import { ExactItemCodeToNoteMappingHandler } from '../mappings/item-code-to-notes';
import {
  ExactStafferMappingHandler,
  resolveExactStaffer,
} from '../mappings/staff';

export interface IPatientInteractionJobData
  extends IBasePatientInteractionJobData<IExactPatient> {
  staff: WithRef<ITranslationMap<IStaffer>>[];
  itemCodesToNotes: WithRef<ITranslationMap<object, ItemCodeNoteType>>[];
}

export class PatientInteractionsDestinationEntity extends BasePatientInteractionDestinationEntity<
  IExactPatient,
  IPatientInteractionJobData
> {
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    notes: new PatientNotesSourceEntity(),
    treatments: new PatientTreatmentSourceEntity(),
  };

  customMappings = {
    staff: new ExactStafferMappingHandler(),
    itemCodesToNotes: new ExactItemCodeToNoteMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean
  ): Observable<IPatientInteractionJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const itemCodesToNotes$ =
      this.customMappings.itemCodesToNotes.getRecords$(translationMap);

    return this.sourceEntities.patients
      .getRecords$(
        migration,
        1000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        withLatestFrom(staff$, itemCodesToNotes$),
        map(([patients, staff, itemCodesToNotes]) =>
          patients.map((sourcePatient) => ({
            sourcePatient,
            staff,
            itemCodesToNotes,
          }))
        )
      );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientInteractionJobData
  ): Promise<
    | IPatientInteractionMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.patient_id;
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        `Couldn't resolve patient`
      );
    }

    const patientNotes = await this.sourceEntities.notes.filterRecords(
      migration,
      'patientId',
      sourcePatientId
    );

    const interactions = await asyncForEach(patientNotes, async (note) => {
      const staffer = await resolveExactStaffer(
        note.data.data.user_id ?? '',
        translationMap,
        data.staff
      );

      const sourceIdentifier = this.sourceEntities.notes.getSourceRecordId(
        note.data.data
      );
      return {
        sourceIdentifier,
        ...Interaction.init({
          type: InteractionType.Note,
          owner: staffer ? stafferToNamedDoc(staffer) : undefined,
          title: [
            staffer
              ? toMentionContent(
                  toMention(staffer, MentionResourceType.Staffer)
                )
              : toTextContent('Unknown user'),
            toTextContent(` added a Note`),
          ],
          createdAt: note.data.translations.entryDate,
          content: initVersionedSchema(note.data.data.note || []),
        }),
      };
    });

    const patientNoteTreatments =
      await this.sourceEntities.treatments.filterRecords(
        migration,
        'patientId',
        sourcePatientId,
        undefined,
        undefined,
        (record) =>
          !!data.itemCodesToNotes.find(
            (mappedNoteCode) =>
              mappedNoteCode.sourceIdentifier === record.data.data.service_code
          ) && !!record.data.data.treatment_notes
      );

    const interactionsFromTreatments =
      await this._buildInteractionsFromTreatment(
        patientNoteTreatments,
        data,
        migration,
        translationMap
      );

    return {
      patientRef,
      interactions: compact([...interactions, ...interactionsFromTreatments]),
    };
  }

  private async _buildInteractionsFromTreatment(
    patientNoteTreatments: IGetRecordResponse<
      IExactTreatment,
      IExactTreatmentTranslations
    >[],
    data: IPatientInteractionJobData,
    migration: WithRef<IPracticeMigration>,
    translationMap: TranslationMapHandler
  ): Promise<(IInteractionV2 & IHasSourceIdentifier)[]> {
    const interactions = await asyncForEach(
      patientNoteTreatments,
      async (treatment) => {
        const mappedNote = data.itemCodesToNotes.find(
          (mappedNoteCode) =>
            mappedNoteCode.sourceIdentifier === treatment.data.data.service_code
        );
        if (
          !mappedNote?.destinationValue ||
          [
            ItemCodeNoteType.ClinicalNote,
            ItemCodeNoteType.PatientSocialNote,
            ItemCodeNoteType.SterilisationRecord,
          ].includes(mappedNote.destinationValue)
        ) {
          return;
        }

        const staffer = await resolveExactStaffer(
          treatment.data.data.provider_code ?? '',
          translationMap,
          data.staff
        );
        const sourceIdentifier =
          this.sourceEntities.treatments.getSourceRecordId(treatment.data.data);
        const type =
          ITEM_CODE_NOTE_TO_INTERACTION_MAP[mappedNote.destinationValue];
        const createdAt = treatment.data.translations.plannedDate
          ? toTimestamp(
              toMomentTz(
                treatment.data.translations.plannedDate,
                migration.configuration.timezone
              )
            )
          : undefined;
        const treatmentContent = treatment.data.data.treatment_notes ?? '';

        return {
          sourceIdentifier,
          ...Interaction.init({
            type,
            owner: staffer ? stafferToNamedDoc(staffer) : undefined,
            title: [
              staffer
                ? toMentionContent(
                    toMention(staffer, MentionResourceType.Staffer)
                  )
                : toTextContent('Unknown user'),
              toTextContent(` added a ${type} note`),
            ],
            createdAt,
            content: initVersionedSchema(treatmentContent || []),
          }),
        };
      }
    );

    return compact(interactions);
  }
}
