import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import {
  type ICustomReportChart,
  type ICustomReportFilter,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot } from '@principle-theorem/shared';
import { EMPTY, Observable, ReplaySubject, combineLatest, noop } from 'rxjs';
import { concatMap, filter, switchMap, takeUntil } from 'rxjs/operators';
import { type IDCChartEvent } from '../../../../core/chart-builders/dc-chart-builders/dc-chart-builder';
import { ReportBuilderStore } from '../../../report-builder.store';
import { type IChartDisplay } from './report-builder-chart-display';
import { DcChartComponent } from '../../../../core/dc-chart/dc-chart.component';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'pr-report-builder-chart',
  templateUrl: './report-builder-chart.component.html',
  styleUrls: ['./report-builder-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportBuilderChartComponent implements OnDestroy {
  private _onDestroy$ = new ReplaySubject<void>(1);
  private _dcChart$ = new ReplaySubject<DcChartComponent>(1);
  chart$ = new ReplaySubject<ICustomReportChart>(1);
  display$ = new ReplaySubject<IChartDisplay>(1);
  canResetFilter$: Observable<boolean>;
  @Input() sectionName: string;
  @Input({ transform: coerceBooleanProperty }) readOnly: boolean = false;

  @Input()
  set display(display: IChartDisplay) {
    if (display) {
      this.display$.next(display);
    }
  }

  @ViewChild('dcChart')
  set dcChart(dcChart: DcChartComponent) {
    if (dcChart) {
      this._dcChart$.next(dcChart);
    }
  }

  constructor(public store: ReportBuilderStore) {
    this.display$
      .pipe(
        switchMap(
          (display) =>
            display.chartDisplay?.eventListener$ ??
            display.tableDisplay?.eventListener$ ??
            EMPTY
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((event) => void this._updateChartFilter(event));

    combineLatest([this.display$, this.store.resetFilter$])
      .pipe(
        filter(
          ([display, resetFilterId]) => resetFilterId === display.chart.uid
        ),
        concatMap(() =>
          this._dcChart$.pipe(concatMap((dcChart) => dcChart.resetFilter()))
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);

    this.canResetFilter$ = this._dcChart$.pipe(
      switchMap((dcChart) => dcChart.canResetFilter$)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async resetFilter(): Promise<void> {
    const chart = await snapshot(this._dcChart$);
    await chart.resetFilter();
  }

  getLabel(chart: ICustomReportChart): string {
    return chart.label || chart.settings[0].label;
  }

  private async _updateChartFilter(event: IDCChartEvent): Promise<void> {
    const current = await snapshot(this.store.display$);
    if (!current) {
      return;
    }
    const customfilter: ICustomReportFilter = {
      id: event.groupByDimension.metadata.id,
      values: event.filterValues,
    };

    const existing = current.dynamicFilters.filter(
      (item) => item.id !== customfilter.id
    );
    const filters = [...existing, customfilter].filter(
      (reportFilter) => reportFilter.values.length
    );

    this.store.setDisplay({ ...current, dynamicFilters: filters });
  }
}
