import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { type TypedAbstractControl } from '@principle-theorem/ng-shared';
import {
  IPatientClaimDialogResponse,
  type IMedicareCard,
} from '@principle-theorem/principle-core/interfaces';
import type * as moment from 'moment-timezone';
import {
  getMaxReferralDate,
  type IBulkBillDialogData,
} from '../bulk-bill-dialog/bulk-bill-dialog.component';
import { DEFAULT_NO_REFERRAL } from '../claim-referral-form/claim-referral-form';

@Component({
  selector: 'pr-patient-claim-dialog',
  templateUrl: './patient-claim-dialog.component.html',
  styleUrls: ['./patient-claim-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientClaimDialogComponent {
  medicareCard: IMedicareCard;
  referralData = DEFAULT_NO_REFERRAL;
  maxDate: moment.Moment;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IBulkBillDialogData,
    private _dialogRef: MatDialogRef<
      PatientClaimDialogComponent,
      IPatientClaimDialogResponse
    >
  ) {
    this.maxDate = getMaxReferralDate(data.invoice);
    this.medicareCard = {
      number: '',
      subNumerate: '',
      ...data.patient.medicareCard,
    };
  }

  submit(): void {
    this._dialogRef.close({
      medicareCard: this.medicareCard,
      referralData: this.referralData,
    });
  }

  updateMedicareCard(cardUpdates: IMedicareCard): IMedicareCard {
    return (this.medicareCard = cardUpdates);
  }

  isInvalid(...forms: TypedAbstractControl<unknown>[]): boolean {
    return forms.some((form) => form.invalid);
  }
}
