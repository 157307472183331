import { randBoolean, randDepartment } from '@ngneat/falso';
import {
  ADA_SERVICE_CODES,
  IFeeSchedule,
  IServiceCodeFee,
  ServiceCodeType,
} from '@principle-theorem/principle-core/interfaces';
import { initFirestoreModel } from '@principle-theorem/shared';
import { PricingRule } from '../pricing-rules/pricing-rule';

export function MockFeeSchedule(mockAll: boolean = true): IFeeSchedule {
  const serviceCodes: IServiceCodeFee[] = ADA_SERVICE_CODES.map(
    (serviceCode) => ({
      serviceCode: {
        code: serviceCode.code,
        type: ServiceCodeType.ADA,
      },
      pricingRule: PricingRule.init(),
    })
  ).filter(() => (mockAll ? mockAll : randBoolean()));

  return {
    name: randDepartment(),
    serviceCodeType: ServiceCodeType.ADA,
    serviceCodes,
    ...initFirestoreModel(),
  };
}
