import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  CurrentBrandScope,
  CurrentPracticeScope,
  GlobalStoreService,
} from '@principle-theorem/ng-principle-shared';
import {
  IScheduleSummaryEventable,
  type IPractice,
  type IStaffer,
  IGap,
} from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject, combineLatest, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { GapShortListManager } from '../../gap-shortlist-manager';
import { first } from 'lodash';
import { Event, stafferToNamedDoc } from '@principle-theorem/principle-core';

@Component({
  selector: 'pr-gap-card',
  templateUrl: './gap-card.component.html',
  styleUrls: ['./gap-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GapCardComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();

  shortListManager: GapShortListManager;
  scheduleSummary$ = new ReplaySubject<IScheduleSummaryEventable>(1);
  gap$ = new ReplaySubject<IGap>(1);
  practitioner$: Observable<WithRef<IStaffer>>;
  practice$: Observable<WithRef<IPractice>>;

  @Input()
  set scheduleSummary(scheduleSummary: IScheduleSummaryEventable) {
    if (scheduleSummary) {
      this.scheduleSummary$.next(scheduleSummary);
    }
  }

  @Input()
  set gap(gap: IGap) {
    if (gap) {
      this.gap$.next(gap);
    }
  }

  constructor(
    private _currentPractice: CurrentPracticeScope,
    private _currentBrand: CurrentBrandScope,
    private _global: GlobalStoreService
  ) {
    this.practice$ = this._currentPractice.asObservable();

    this.practitioner$ = this.gap$.pipe(
      map((gap) => first(Event.staff(gap.event))),
      filterUndefined(),
      switchMap((practitioner) => this._global.getStaffer$(practitioner.ref)),
      filterUndefined()
    );

    combineLatest([this.scheduleSummary$, this.practitioner$])
      .pipe(
        map(([summary, practitioner]) => ({
          event: summary.event,
          practitioner: stafferToNamedDoc(practitioner),
        })),
        filterUndefined(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((gap) => this.gap$.next(gap));

    const brand$ = this._currentBrand.asObservable();
    this.shortListManager = new GapShortListManager(
      this.gap$,
      this.practitioner$,
      brand$,
      this.practice$
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
