import { ClinicalChart } from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  IClinicalChart,
  IDestinationEntity,
  IDestinationEntityRecord,
  IGetRecordResponse,
  IMigratedDataSummary,
  IPatient,
  IPracticeMigration,
  ISourceEntityHandler,
  ISourceEntityRecord,
  IStaffer,
  ITranslationMap,
  MergeConflictDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  IIdentifiable,
  WithRef,
  asyncForEach,
  getError,
  safeCombineLatest,
  toTimestamp,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ItemCodeResourceMapType } from '../../mappings/item-codes-to-xlsx';
import { TranslationMapHandler } from '../../translation-map';
import { BaseDestinationEntity } from '../base-destination-entity';
import { FirestoreMigrate } from '../destination';
import { DestinationEntityRecord } from '../destination-entity-record';

export interface IBaseClinicalChartJobData<PatientRecord extends object> {
  sourcePatient: IGetRecordResponse<PatientRecord>;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[];
}

export interface IClinicalChartMigrationData {
  sourcePatientId: string;
  patientRef: DocumentReference<IPatient>;
  charts: IGeneratedCharts;
}

export interface IClinicalChartSuccessData {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  clinicalChartRefs: DocumentReference<IClinicalChart>[];
  perioChartRefs: DocumentReference<IClinicalChart>[];
}

export interface IGeneratedCharts {
  clinicalCharts: (IClinicalChart & IIdentifiable)[];
  perioCharts: (IClinicalChart & IIdentifiable)[];
}

export abstract class BasePatientClinicalChartDestinationEntity<
  PatientRecord extends object,
  JobData extends IBaseClinicalChartJobData<PatientRecord>,
> extends BaseDestinationEntity<
  IClinicalChartSuccessData,
  JobData,
  IClinicalChartMigrationData
> {
  abstract sourceCountComparison: ISourceEntityHandler;
  abstract periodontalResourceType: string;

  sourceCountDataAccessor(
    data: JobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getDestinationEntityRecordUid(data: JobData): string {
    return data.sourcePatient.record.uid;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IClinicalChartSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      Firestore.getDoc(record.data.sourceRef),
      safeCombineLatest(
        record.data.perioChartRefs.map((perioChartRef) =>
          Firestore.getDoc(perioChartRef)
        )
      ),
      safeCombineLatest(
        record.data.clinicalChartRefs.map((clinicalChartRef) =>
          Firestore.getDoc(clinicalChartRef)
        )
      ),
    ]).pipe(
      map(([sourcePatient, clinicalCharts, perioCharts]) => {
        const data: IMigratedDataSummary[] = [
          {
            label: 'Source Patient',
            data: sourcePatient,
          },
        ];
        data.push(
          ...clinicalCharts.map((clinicalChart) => ({
            label: 'Clinical Chart',
            data: clinicalChart,
          }))
        );
        data.push(
          ...perioCharts.map((perioChart) => ({
            label: 'Perio Chart',
            data: perioChart,
          }))
        );

        return data;
      })
    );
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IClinicalChartMigrationData
  ): Promise<IClinicalChartMigrationData | undefined> {
    const existingCharts: (IClinicalChart & IIdentifiable)[] = [];

    const chartMergeConflicts = await asyncForEach(
      compact(data.charts.clinicalCharts),
      async (chart) => {
        const chartRef = await translationMap.getDestination(
          data.sourcePatientId,
          this.destinationEntity.metadata.key
        );

        if (!chartRef) {
          return false;
        }

        const existingClinicalChart = await Firestore.getDoc(
          chartRef as DocumentReference<IClinicalChart>
        );

        existingCharts.push({
          ...existingClinicalChart,
          uid: data.sourcePatientId,
        });

        return DestinationEntityRecord.hasMergeConflicts(
          chart,
          existingClinicalChart
        );
      }
    );

    const hasChartMergeConflicts = chartMergeConflicts.some(
      (chartMergeConflict) => chartMergeConflict
    );

    const existingPerioCharts: (IClinicalChart & IIdentifiable)[] = [];

    const perioChartMergeConflicts = await asyncForEach(
      data.charts.perioCharts,
      async (perioChart) => {
        const perioChartRef = await translationMap.getDestination(
          perioChart.uid,
          this.periodontalResourceType
        );

        if (!perioChartRef) {
          return false;
        }

        const existingPerioChart = await Firestore.getDoc(
          perioChartRef as DocumentReference<IClinicalChart>
        );
        existingPerioCharts.push({
          ...existingPerioChart,
          uid: perioChart.uid,
        });

        return DestinationEntityRecord.hasMergeConflicts(
          perioChart,
          existingPerioChart
        );
      }
    );

    const hasPerioMergeConflicts = perioChartMergeConflicts.some(
      (perioChartMergeConflict) => perioChartMergeConflict
    );

    if (hasChartMergeConflicts || hasPerioMergeConflicts) {
      return {
        ...data,
        charts: {
          ...data.charts,
          clinicalCharts: existingCharts,
          perioCharts: existingPerioCharts,
        },
      };
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: JobData,
    _migrationData: IClinicalChartMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    jobData: JobData,
    migrationData: IClinicalChartMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const clinicalChartRefs = await this._upsertClinicalCharts(
        migrationData.charts.clinicalCharts,
        translationMapHandler,
        migrationData.patientRef
      );

      const perioChartRefs = await this._upsertPerioCharts(
        migrationData.charts.perioCharts,
        translationMapHandler,
        migrationData.patientRef
      );

      return this._buildSuccessResponse(
        jobData.sourcePatient,
        perioChartRefs,
        clinicalChartRefs
      );
    } catch (error) {
      return this._buildErrorResponse(
        {
          label: jobData.sourcePatient.record.label,
          uid: jobData.sourcePatient.record.uid,
          ref: jobData.sourcePatient.record.ref,
        },
        getError(error)
      );
    }
  }

  protected _buildSuccessResponse(
    patient: IGetRecordResponse<PatientRecord>,
    perioChartRefs: DocumentReference<IClinicalChart>[],
    clinicalChartRefs: DocumentReference<IClinicalChart>[]
  ): IDestinationEntityRecord<IClinicalChartSuccessData> {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      data: {
        sourceRef: patient.record.ref,
        clinicalChartRefs,
        perioChartRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  protected _buildErrorResponse(
    patient: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>,
    errorMessage?: string
  ): IDestinationEntityRecord<IClinicalChartSuccessData> &
    FailedDestinationEntityRecord {
    return {
      uid: patient.uid,
      label: patient.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage: errorMessage ?? `Can't resolve data to generate charts`,
      failData: {
        patientRef: patient.ref,
      },
    };
  }

  private async _upsertClinicalCharts(
    charts: (IClinicalChart & IIdentifiable)[],
    translationMap: TranslationMapHandler,
    patientRef: DocumentReference<IPatient>
  ): Promise<DocumentReference<IClinicalChart>[]> {
    return asyncForEach(charts, async (chart) => {
      const chartDestinationRef = await translationMap.getDestination(
        chart.uid,
        this.destinationEntity.metadata.key
      );

      const chartRef = await FirestoreMigrate.upsertDoc(
        ClinicalChart.col({
          ref: patientRef,
        }),
        chart,
        chartDestinationRef?.id
      );

      if (!chartDestinationRef) {
        await translationMap.upsert({
          sourceIdentifier: chart.uid,
          destinationIdentifier: chartRef,
          resourceType: this.destinationEntity.metadata.key,
        });
      }

      return chartRef;
    });
  }

  private async _upsertPerioCharts(
    charts: (IClinicalChart & IIdentifiable)[],
    translationMap: TranslationMapHandler,
    patientRef: DocumentReference<IPatient>
  ): Promise<DocumentReference<IClinicalChart>[]> {
    return asyncForEach(charts, async (chart) => {
      const chartDestinationRef = await translationMap.getDestination(
        chart.uid,
        this.periodontalResourceType
      );

      const chartRef = await FirestoreMigrate.upsertDoc(
        ClinicalChart.col({
          ref: patientRef,
        }),
        chart,
        chartDestinationRef?.id
      );

      if (!chartDestinationRef) {
        await translationMap.upsert({
          sourceIdentifier: chart.uid,
          destinationIdentifier: chartRef,
          resourceType: this.periodontalResourceType,
        });
      }

      return chartRef;
    });
  }
}
