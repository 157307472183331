import { createSelector } from '@ngrx/store';
import { getParentDocRef } from '@principle-theorem/shared';
import { compact } from 'lodash';
import { treatmentStepsAdapter } from '../../reducers/treatment-plans/treatment-steps.reducer';
import { getChartModuleState } from '../../selectors';

const { selectEntities } = treatmentStepsAdapter.getSelectors();

export const getTreatmentStepsState = createSelector(
  getChartModuleState,
  (chart) => chart.treatmentPlanning.treatmentSteps
);

export const getTreatmentStepsLoaded = createSelector(
  getTreatmentStepsState,
  (state) => state.loaded
);

export const getTreatmentStepEntities = createSelector(
  getTreatmentStepsState,
  (state) => selectEntities(state)
);

export const getSelectedTreatmentStepId = createSelector(
  getTreatmentStepsState,
  (state) => state.selectedId
);

export const getSelectedTreatmentStep = createSelector(
  getTreatmentStepEntities,
  getSelectedTreatmentStepId,
  (entities, id) => (id ? entities[id] : undefined)
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getTreatmentStepsByIds = (ids: string[]) =>
  createSelector(getTreatmentStepEntities, (entities) =>
    ids.map((id) => entities[id])
  );

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getTreatmentStepsByPlanId = (id: string) =>
  createSelector(getTreatmentStepEntities, (entities) =>
    compact(Object.values(entities)).filter(
      (step) => getParentDocRef(step.ref.referenceValue).id === id
    )
  );

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getTreatmentStepById = (id: string) =>
  createSelector(getTreatmentStepEntities, (entities) => entities[id]);
