import { ViewportScroller } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { BlockNodes, NodeGroup } from '@principle-theorem/editor';
import {
  EditorNode,
  EditorNodeComponent,
  IRenderHTMLArguments,
  type IDomParsing,
  type IDomSerialising,
} from '@principle-theorem/ng-prosemirror';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  CommandProps,
  Editor,
  GlobalAttributes,
  RawCommands,
  mergeAttributes,
} from '@tiptap/core';
import { type DOMOutputSpec, type ParseRule } from '@tiptap/pm/model';
import { BehaviorSubject, ReplaySubject, Subject, fromEvent } from 'rxjs';
import { JQueryStyleEventEmitter } from 'rxjs/internal/observable/fromEvent';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';

export interface ITableOfContents {
  level: string;
  text: string;
  id: string;
}

declare module '@tiptap/core' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Commands<ReturnType> {
    tableOfContents: {
      /**
       * Set media
       */
      addTableOfContents: () => ReturnType;
    };
  }
}

@EditorNode({
  name: BlockNodes.TableOfContents,
  group: NodeGroup.Block,
  draggable: false,
  selectable: false,
  atom: true,
})
@Component({
  selector: 'pt-table-of-contents-node',
  templateUrl: './table-of-contents-node.component.html',
  styleUrls: ['./table-of-contents-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableOfContentsNodeComponent
  extends EditorNodeComponent
  implements IDomParsing, IDomSerialising, OnDestroy, OnInit
{
  private _onDestroy$ = new Subject<void>();
  trackByHeading = TrackByFunctions.field<ITableOfContents>('id');
  headings$ = new BehaviorSubject<ITableOfContents[]>([]);
  url$ = new ReplaySubject<string[]>(1);
  @HostBinding('class.no-drag') noDrag = true;

  constructor(
    elementRef: ElementRef,
    private _router: Router,
    private _viewportScroller: ViewportScroller
  ) {
    super(elementRef);

    this.editor$
      .pipe(
        switchMap((editor) =>
          fromEvent(
            editor as unknown as JQueryStyleEventEmitter,
            'update'
          ).pipe(
            startWith(undefined),
            map(() => editor)
          )
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((editor) => this.handleUpdate(editor));
  }

  ngOnInit(): void {
    const url = this._router.url;
    const hashPosition = url.indexOf('#');
    if (hashPosition > -1) {
      this.url$.next([url.substring(0, hashPosition)]);
      return;
    }
    this.url$.next([url]);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  scrollToAnchor(elementId: string): void {
    this._viewportScroller.scrollToAnchor(elementId);
  }

  override parseHTML(): ParseRule[] {
    return [
      {
        tag: 'toc',
      },
    ];
  }

  renderHTML(data: IRenderHTMLArguments): DOMOutputSpec {
    return ['toc', mergeAttributes(data.HTMLAttributes)];
  }

  addGlobalAttributes(): GlobalAttributes {
    return [
      {
        types: ['heading'],
        attributes: {
          id: {
            default: undefined,
            keepOnSplit: false,
          },
        },
      },
    ];
  }

  addCommands(): Partial<RawCommands> {
    return {
      addTableOfContents: () => (props: CommandProps) => {
        return props.commands.insertContentAt(0, {
          type: BlockNodes.TableOfContents,
        });
      },
    };
  }

  handleUpdate(editor: Editor): void {
    const headings: ITableOfContents[] = [];
    const transaction = editor.state.tr;

    editor.state.doc.descendants((node, pos) => {
      if (node.type.name === String(BlockNodes.Heading)) {
        const id = `heading-${headings.length + 1}`;

        if (node.attrs.id !== id) {
          transaction.setNodeMarkup(pos, undefined, {
            ...node.attrs,
            id,
          });
        }

        headings.push({
          level: node.attrs.level as string,
          text: node.textContent,
          id,
        });
      }
    });

    transaction.setMeta('addToHistory', false);
    transaction.setMeta('preventUpdate', true);
    editor.view.dispatch(transaction);
    this.headings$.next(headings);
  }
}
