import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { isObject } from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { STAFF_DESTINATION_ENTITY } from '../../destination/entities/staff';
import { cleanObjectStrings } from './lib/conversion-helpers';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';

export const STAFFER_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Staffer List',
    description: '',
    idPrefix: STAFFER_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ID4WStaffer {
  id: number;
  surname: string;
  first_name: string;
  provider_no: string;
  practice_id: number;
  is_active: boolean;
}

function isD4WStafferListItem(item: unknown): item is ID4WStaffer {
  return (
    isObject(item) &&
    isNumber(item.id) &&
    isString(item.first_name) &&
    isString(item.surname) &&
    isString(item.provider_no) &&
    isNumber(item.practice_id) &&
    isBoolean(item.is_active)
  );
}

const STAFFER_SOURCE_QUERY = `SELECT
  member_id AS id,
  firstname AS first_name,
  surname,
  provider_no_1_id AS provider_no,
  practice_id,
  convert_to_boolean(is_active) AS is_active
FROM staff
WHERE member_id != '-1' AND member_id IS NOT NULL
ORDER BY id
`;

export class StafferSourceEntity extends BaseSourceEntity<ID4WStaffer> {
  sourceEntity = STAFFER_SOURCE_ENTITY;
  entityResourceType = STAFFER_RESOURCE_TYPE;
  sourceQuery = STAFFER_SOURCE_QUERY;
  migrationDestionations = [STAFF_DESTINATION_ENTITY.metadata.key];
  verifySourceFn = isD4WStafferListItem;

  override transformDataFn = flow([
    (rows: ID4WStaffer[]) => rows.map(cleanObjectStrings),
  ]);

  translate(_staffer: ID4WStaffer): object {
    return {};
  }

  getSourceRecordId(data: ID4WStaffer): number {
    return data.id;
  }

  getSourceLabel(data: ID4WStaffer): string {
    return `${data.first_name} ${data.surname}`;
  }
}
