import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  DateRange,
  type MatDateRangeSelectionStrategy,
} from '@angular/material/datepicker';
import { type Moment } from 'moment-timezone';
import { DateRangeService } from './date-range.service';

@Injectable()
export class RangeSelectionStrategy
  implements MatDateRangeSelectionStrategy<Moment>
{
  constructor(
    private _dateAdapter: DateAdapter<Moment>,
    private _rangeService: DateRangeService
  ) {}

  selectionFinished(
    date: Moment | null,
    currentRange: DateRange<Moment>
  ): DateRange<Moment> {
    const selectedRange = this._rangeService.selectedDayRange$.value;
    if (!selectedRange && date) {
      let { start, end } = currentRange;

      if (start && end && !this._dateAdapter.sameDate(start, date)) {
        // eslint-disable-next-line no-null/no-null
        return new DateRange<Moment>(date, null);
      }

      if (!start) {
        start = date;
      } else if (
        !end &&
        date &&
        this._dateAdapter.compareDate(date, start) >= 0
      ) {
        end = date;
      } else {
        start = date;
        // eslint-disable-next-line no-null/no-null
        end = null;
      }
      return new DateRange<Moment>(start, end);
    }
    return this._createRange(date, selectedRange ?? 1);
  }

  createPreview(
    activeDate: Moment | null,
    currentRange: DateRange<Moment>
  ): DateRange<Moment> {
    const selectedRange = this._rangeService.selectedDayRange$.value;
    if (!selectedRange) {
      // eslint-disable-next-line no-null/no-null
      let start: Moment | null = null;
      // eslint-disable-next-line no-null/no-null
      let end: Moment | null = null;

      if (currentRange.start && !currentRange.end && activeDate) {
        start = currentRange.start;
        end = activeDate;
      }

      return new DateRange<Moment>(start, end);
    }
    return this._createRange(activeDate, selectedRange);
  }

  private _createRange(
    date: Moment | null,
    rangeInDays: number
  ): DateRange<Moment> {
    if (date) {
      const end = this._dateAdapter.addCalendarDays(date, rangeInDays - 1);
      return new DateRange<Moment>(date, end);
    }

    // eslint-disable-next-line no-null/no-null
    return new DateRange<Moment>(null, null);
  }
}
