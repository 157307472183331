import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { type IReferralSourceConfiguration } from '@principle-theorem/principle-core/interfaces';
import { ReferralSource } from '@principle-theorem/principle-core';
import { type WithRef } from '@principle-theorem/shared';

export interface IEditReferrerDialogData {
  referrer?: WithRef<IReferralSourceConfiguration>;
}

@Component({
    selector: 'pr-edit-referral-source-dialog',
    templateUrl: './edit-referral-source-dialog.component.html',
    styleUrls: ['./edit-referral-source-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditReferralSourceDialogComponent {
  title: string = 'Add Referral Source';
  form = new TypedFormGroup<Omit<IReferralSourceConfiguration, 'deleted'>>({
    name: new TypedFormControl<string>('', Validators.required),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEditReferrerDialogData,
    private _dialogRef: MatDialogRef<EditReferralSourceDialogComponent>
  ) {
    if (data.referrer) {
      this.title = 'Edit Referral Source';
      this.form.patchValue(data.referrer);
    }
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }
    this._dialogRef.close(ReferralSource.init(this.form.getRawValue()));
  }
}
