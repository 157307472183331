import {
  type ITimePeriod,
  type Timezone,
  toTimePeriod,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class TimezoneManager {
  constructor(public timezone$: Observable<Timezone>) {}

  applyToTimePeriod$({ from, to }: ITimePeriod): Observable<ITimePeriod> {
    return this.timezone$.pipe(
      map((timezone) => toTimePeriod(from, to, timezone))
    );
  }

  applyToMoment$(time: moment.Moment): Observable<moment.Moment> {
    return this.timezone$.pipe(map((timezone) => moment.tz(time, timezone)));
  }
}
