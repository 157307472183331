import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { type CollectionReference } from '@principle-theorem/shared';
import { getDoc$, type WithRef } from '@principle-theorem/shared';
import { AbstractResolver } from '@principle-theorem/ng-shared';
import { type ITeam } from '@principle-theorem/principle-core/interfaces';
import { type Observable, of } from 'rxjs';

@Injectable()
export class TeamResolverService extends AbstractResolver<WithRef<ITeam>> {
  constructor(router: Router) {
    super(router);
  }

  getItemById(
    id: string,
    route: ActivatedRouteSnapshot
  ): Observable<WithRef<ITeam> | undefined> {
    const collection: CollectionReference<ITeam> | undefined =
      this._findInheritedRouteData(route, 'teamCollection');
    if (!collection) {
      return of(undefined);
    }
    return getDoc$(collection, id);
  }
}
