import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { roundTo2Decimals } from '@principle-theorem/accounting';
import {
  TypedFormControl,
  validFormControlChanges$,
} from '@principle-theorem/ng-shared';
import { snapshot, WithRef } from '@principle-theorem/shared';
import {
  IFeeSchedule,
  ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { isEqual, isNil } from 'lodash';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { FeeSchedule } from '@principle-theorem/principle-core';

@Component({
    selector: 'pr-edit-base-price',
    templateUrl: './edit-base-price.component.html',
    styleUrls: ['./edit-base-price.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditBasePriceComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  feeSchedule$ = new ReplaySubject<WithRef<IFeeSchedule>>(1);
  scheduleBasePrice$: Observable<number>;
  config$ = new ReplaySubject<WithRef<ITreatmentConfiguration>>(1);
  price$ = new ReplaySubject<number>(1);
  canReset$: Observable<boolean>;
  priceCtrl = new TypedFormControl<number>(0, {
    updateOn: 'blur',
  });
  @Input() name = '';
  @Input() tooltip = '';
  @Input() disabled = false;
  @Output() priceChange = new EventEmitter<number>();
  @Output() basePriceDeleted = new EventEmitter<void>();

  @Input()
  set price(price: number) {
    if (!isNil(price)) {
      this.priceCtrl.setValue(price, { emitEvent: false });
      this.price$.next(price);
    }
  }

  @Input()
  set feeSchedule(feeSchedule: WithRef<IFeeSchedule>) {
    if (feeSchedule) {
      this.feeSchedule$.next(feeSchedule);
    }
  }

  @Input()
  set config(config: WithRef<ITreatmentConfiguration>) {
    if (config) {
      this.config$.next(config);
    }
  }

  constructor() {
    validFormControlChanges$(this.priceCtrl)
      .pipe(
        map((value) => roundTo2Decimals(value) ?? 0),
        takeUntil(this._onDestroy$)
      )
      .subscribe((price) => {
        this.price = price;
        this.priceChange.emit(price);
      });

    this.scheduleBasePrice$ = combineLatest([
      this.feeSchedule$,
      this.config$,
    ]).pipe(
      map(([feeSchedule, config]) =>
        FeeSchedule.getTreatmentFee(feeSchedule, config.ref)
      )
    );

    this.canReset$ = combineLatest([
      this.price$,
      validFormControlChanges$(this.priceCtrl).pipe(startWith(undefined)),
      this.scheduleBasePrice$,
    ]).pipe(
      map(
        ([initialPrice, formChanges, schedulePrice]) =>
          !isEqual(formChanges ?? initialPrice, schedulePrice)
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async resetPrice(): Promise<void> {
    const schedulePrice = await snapshot(this.scheduleBasePrice$);
    this.priceChange.emit(schedulePrice);
    this.priceCtrl.setValue(schedulePrice);
  }
}
