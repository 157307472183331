import { type IChartConfig } from '@principle-theorem/reporting';
import * as d3 from 'd3';
import { type IDCTransformResult } from '../../../../models/report/charts/data-transformers/dc-data-transformer';
import { type ID3ChartRenderer } from '../../d3-chart/d3-chart.component';
import { type ID3ChartBuilder } from './d3-chart-builder';
import {
  addAxisScales,
  addLegend,
  DEFAULT_D3_CHART_CONFIG,
  type ID3ChartConfig,
  setupChart,
  toCrossfilterGroupMap,
} from './d3-chart-shared';
import {
  formatCrossfilter,
  type GroupedD3ChartData,
} from './d3-grouped-crossfilter';

export class D3StackedRowChartBuilder implements ID3ChartBuilder {
  getLabel(config: IChartConfig): string {
    return `Stacked: ` + config.labels.title;
  }

  getRenderer(results: IDCTransformResult[]): ID3ChartRenderer | undefined {
    const groupMap = toCrossfilterGroupMap(results);
    const data = formatCrossfilter(groupMap);
    return {
      render: (elemSelector) =>
        renderStackedRowChart(elemSelector, data, DEFAULT_D3_CHART_CONFIG),
    };
  }
}

function renderStackedRowChart<T extends Record<string, unknown>>(
  elem: Element,
  items: GroupedD3ChartData<T>[],
  config: ID3ChartConfig
): void {
  const chart = setupChart(elem, items, config);

  const stackedItems = d3.stack().keys(chart.subgroups)(items);
  chart.inner
    .append('g')
    .selectAll('g')
    .data(stackedItems)
    .enter()
    .append('g')
    .attr('fill', (d) => chart.colourScale(d.key))
    .selectAll('rect')
    .data((d) => d)
    .enter()
    .append('rect')
    .attr('y', (_d) => {
      // TODO: This is a hack to get the correct y position for the stacked chart
      // return chart.yAxis(d.data.metadata.groupBy);
      return 0;
    })
    .attr('x', (d) => chart.xAxis(d[0]) ?? 0)
    .attr('width', (d) => (chart.xAxis(d[1]) ?? 0) - (chart.xAxis(d[0]) ?? 0))
    .attr('height', chart.yAxis.bandwidth());

  addAxisScales(chart.inner, chart.innerHeight, chart.xAxis, chart.yAxis);
  addLegend(
    chart.inner,
    chart.innerHeight,
    chart.innerWidth,
    chart.subgroups,
    chart.colourScale
  );
}
