<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="16px" class="wrapper">
  <pr-team-form #teamForm [team]="team$ | async" (submitted)="submit($event)" />

  <h2 class="mat-body-1">Staff</h2>
  <pr-team-staffer-assignment [team]="team$ | async" />

  <pt-buttons-container>
    <button mat-stroked-button color="warn" (click)="delete()">Delete</button>
    <button mat-flat-button color="primary" (click)="teamForm.submit()">
      Save
    </button>
  </pt-buttons-container>
</div>
