import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { Appointment, ChecklistItem } from '@principle-theorem/principle-core';
import {
  ChecklistType,
  type IAppointment,
  type IAppointmentChecklist,
  type IChecklistItem,
} from '@principle-theorem/principle-core/interfaces';
import {
  deleteDoc,
  isWithRef,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ChecklistFormStore } from './checklist-form.store';

@Component({
  selector: 'pr-checklist-form',
  templateUrl: './checklist-form.component.html',
  styleUrls: ['./checklist-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChecklistFormStore],
})
export class ChecklistFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  type = ChecklistType;
  trackByItem = TrackByFunctions.title<IChecklistItem>();
  appointmentChecklist$: Observable<IAppointmentChecklist>;
  preTitleCtrl = new TypedFormControl<string>('', Validators.required);
  postTitleCtrl = new TypedFormControl<string>('', Validators.required);
  @Output() checklistsUpdated = new EventEmitter<IChecklistItem[]>();

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.store.loadAppointment(appointment);
    }
  }

  @Input()
  set checklists(checklists: IChecklistItem[]) {
    if (checklists) {
      this.store.addChecklistItems(checklists);
    }
  }

  constructor(
    private _snackBar: MatSnackBar,
    public store: ChecklistFormStore
  ) {
    this.appointmentChecklist$ = this.store.checklists$.pipe(
      map((items) => ({
        pre: items.filter((item) => item.type === ChecklistType.Pre),
        post: items.filter((item) => item.type === ChecklistType.Post),
      }))
    );

    this.store.checklists$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((items) => this.checklistsUpdated.emit(items));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async addItem(
    titleCtrl: TypedFormControl<string>,
    type: ChecklistType
  ): Promise<void> {
    const title = titleCtrl.value.trim();
    const checklists = await snapshot(this.store.checklists$);
    const appointment = await snapshot(this.store.appointment$);

    if (!title) {
      return;
    }

    if (ChecklistItem.isDuplicateChecklistItem(title, type, checklists)) {
      this._snackBar.open(`${title} already exists`);
      return;
    }

    const item = ChecklistItem.init({ title: titleCtrl.value, type });

    appointment
      ? await Appointment.addChecklistItem(appointment, item)
      : this.store.addChecklistItem(item);

    titleCtrl.reset('');
  }

  async deleteItem(
    item: IChecklistItem | WithRef<IChecklistItem>
  ): Promise<void> {
    if (isWithRef(item)) {
      await deleteDoc(item.ref);
    }

    this.store.deleteChecklistItem(item);
    this._snackBar.open(`${item.title} deleted`);
  }
}
