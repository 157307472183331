import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { MixedSchema, initVersionedSchema } from '@principle-theorem/editor';
import {
  EditorPresetsService,
  renderVersionedSchema,
} from '@principle-theorem/ng-interactions';
import {
  TypedFormControl,
  validFormControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  type ConditionLogicConfigurationCollection,
  ConditionLogicId,
  type ITemplateContext,
  type ITemplateVariant,
  type TemplateType,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isChanged$,
  snapshot,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
} from 'rxjs';
import { debounceTime, map, skip, take, takeUntil } from 'rxjs/operators';
import { TemplateEditorFormStore } from '../template-editor-form-store.service';

@Component({
  selector: 'pr-edit-template-variant',
  templateUrl: './edit-template-variant.component.html',
  styleUrls: ['./edit-template-variant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditTemplateVariantComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  variant$ = new ReplaySubject<ITemplateVariant>(1);
  templateType$ = new ReplaySubject<TemplateType>(1);
  availableConditions$ = new ReplaySubject<ConditionLogicId[]>(1);
  context$ = new BehaviorSubject<ITemplateContext>({});
  isSystemTemplate$ = new ReplaySubject<boolean>(1);
  hasNoScope$ = new ReplaySubject<boolean>(1);
  showVariantError$: Observable<boolean>;
  contentCtrl = new TypedFormControl<MixedSchema>(initVersionedSchema());
  variantNameCtrl = new TypedFormControl<string>();
  defaultCondition = ConditionLogicId.Never;

  @Input()
  set variant(variant: ITemplateVariant) {
    if (variant) {
      this.variant$.next(variant);
    }
  }

  @Input()
  set context(context: ITemplateContext) {
    if (context) {
      this.context$.next(context);
    }
  }

  @Input()
  set templateType(templateType: TemplateType) {
    if (templateType) {
      this.templateType$.next(templateType);
    }
  }

  @Input()
  set hasNoScope(value: boolean) {
    this.hasNoScope$.next(coerceBooleanProperty(value));
  }

  @Input()
  set isSystemTemplate(value: boolean) {
    this.isSystemTemplate$.next(coerceBooleanProperty(value));
  }

  constructor(
    public store: TemplateEditorFormStore,
    private _editorPresets: EditorPresetsService
  ) {
    this.variant$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((variant) => {
        this.contentCtrl.setValue(variant.content, { emitEvent: false });
        this.variantNameCtrl.setValue(variant.name ?? '', { emitEvent: false });
      });

    validFormControlChanges$(this.contentCtrl)
      .pipe(
        skip(1),
        filterUndefined(),
        debounceTime(1000),
        isChanged$(),
        map((content) => ({
          content,
          renderedTemplate: renderVersionedSchema(
            content,
            this._editorPresets.defaultToHTMLExtensions()
          ),
        })),
        takeUntil(this._onDestroy$)
      )
      .subscribe(
        (templateUpdates) => void this._updateVariant(templateUpdates)
      );

    validFormControlChanges$(this.variantNameCtrl)
      .pipe(debounceTime(300), isChanged$(), takeUntil(this._onDestroy$))
      .subscribe((changes) => void this._updateVariant({ name: changes }));

    this.showVariantError$ = combineLatest([
      this.hasNoScope$,
      this.isSystemTemplate$,
    ]).pipe(
      map(([hasNoScope, isSystemTemplate]) => hasNoScope && !isSystemTemplate)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async updateConditions(
    conditions: ConditionLogicConfigurationCollection
  ): Promise<void> {
    await this._updateVariant({ conditions });
  }

  private async _updateVariant(
    updates: Partial<ITemplateVariant>
  ): Promise<void> {
    const variant = await snapshot(this.variant$);
    this.store.updateVariant({
      ...variant,
      ...updates,
    });
  }
}
