<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="16px" class="wrapper">
  <h1 class="mat-headline-4">System Templates</h1>

  <mat-list>
    <mat-list-item
      *ngFor="
        let template of systemTemplates$ | async;
        trackBy: trackByTemplate
      "
    >
      <span matListItemTitle>{{ template.name }}</span>
      <div matListItemMeta>
        <button mat-icon-button [routerLink]="template | map: templateLink">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </mat-list-item>
  </mat-list>

  <h1 class="mat-headline-4">Automation Templates</h1>
  <ng-container *ngIf="automationPairs$ | async as automationPairs">
    <ng-container *ngIf="automationPairs.length; else noTemplates">
      <mat-list>
        <mat-list-item
          *ngFor="let pair of automationPairs; trackBy: trackByAutomationPair"
        >
          <span matListItemTitle>{{ pair.template.name }}</span>
          <div matListItemMeta>
            <pr-automation-configuration-status
              dense
              [automation]="pair.automation"
            />

            <button
              mat-icon-button
              [routerLink]="pair.template | map: templateLink"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </mat-list-item>
      </mat-list>
    </ng-container>
  </ng-container>

  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
    <h1 class="mat-headline-4">Templates</h1>
    <pt-buttons-container>
      <button mat-flat-button color="primary" (click)="create()">
        Create Template
      </button>
    </pt-buttons-container>
  </div>
  <ng-container *ngIf="customTemplates$ | async as options">
    <ng-container *ngIf="(noTemplates$ | async) === false; else noTemplates">
      <ng-container
        *ngFor="let option of options.groups; trackBy: options.trackByGroup"
      >
        <h2 class="mat-subtitle-1">
          {{ option.group | splitCamel | titlecase }}
        </h2>
        <mat-list>
          <mat-list-item
            *ngFor="
              let template of option.items;
              trackBy: options.trackByOption
            "
          >
            <span matListItemTitle>{{ template.label }}</span>
            <div matListItemMeta>
              <button
                mat-icon-button
                [routerLink]="template.value | map: templateLink"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                mat-icon-button
                color="warn"
                (click)="delete(template.value)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-list-item>
        </mat-list>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #noTemplates>
    <pr-empty-state image="list" title="templates" />
  </ng-template>
</div>
