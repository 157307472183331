import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountingFunctionsService } from '@principle-theorem/ng-principle-accounting';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  AccountCredit,
  Invoice,
  Transaction,
  refundUsedCredits,
  Patient,
  getBaseTransaction,
} from '@principle-theorem/principle-core';
import {
  AccountCreditType,
  IAccountCredit,
  IAccountCreditExtendedData,
  IInvoice,
  type IRefundCreditResults,
  ITransaction,
  IUsedAccountCredit,
  InvoiceStatus,
  PatientRelationshipType,
  TransactionAction,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import {
  isSameRef,
  multiMap,
  snapshot,
  toTimestamp,
  type DocumentReference,
  type WithRef,
  Region,
} from '@principle-theorem/shared';
import { omit, sum } from 'lodash';
import { of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  RefundCreditTransactionDialogComponent,
  type IRefundCreditTransactionDialogData,
  type IRefundCreditTransactionDialogResult,
} from '../transaction-components/refund-credit-transaction-dialog/refund-credit-transaction-dialog.component';
import { RefundDepositPromptService } from '../transaction-components/refund-deposit-prompt/refund-deposit-prompt.service';
import {
  TransactionProviderType,
  type ITransactionProvider,
} from '../transaction-provider';
import {
  AccountCreditTransactionComponent,
  type IAccountCreditTransactionDialogData,
  type IAccountCreditTransactionReturnData,
} from './account-credit-transaction.component';

@Injectable()
export class AccountCreditTransactionProvider implements ITransactionProvider {
  providerId = TransactionProvider.AccountCredit;
  providerType = TransactionProviderType.Payment;
  providerRegions = [Region.Australia, Region.NewZealand];
  isEnabled$ = of(true);

  constructor(
    private _dialog: MatDialog,
    private _snackbar: MatSnackBar,
    private _refundDepositPrompt: RefundDepositPromptService,
    private _accountFunctions: AccountingFunctionsService
  ) {}

  getInfo$(invoice: WithRef<IInvoice>): Observable<string> {
    return this._getOwnerCredits$(invoice).pipe(
      multiMap((credit) => AccountCredit.remaining(credit)),
      map(sum),
      map((credit) => `$${credit.toFixed(2)} available`)
    );
  }

  canCapture$(invoice: WithRef<IInvoice>): Observable<boolean> {
    return this._getOwnerCredits$(invoice).pipe(
      multiMap((credit) => AccountCredit.remaining(credit)),
      map(sum),
      map(
        (creditAvailable) =>
          creditAvailable > 0 &&
          invoice.status !== InvoiceStatus.Paid &&
          Invoice.canAddTransactions(invoice)
      )
    );
  }

  async capture(
    invoice: WithRef<IInvoice>
  ): Promise<
    DocumentReference<ITransaction<IAccountCreditExtendedData>> | undefined
  > {
    const credits = await snapshot(this._getOwnerCredits$(invoice));
    const result = await this._getTransaction(invoice, credits);
    if (!result) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }
    return this._accountFunctions.addTransactionToInvoice(
      invoice,
      result.transaction,
      TransactionAction.Add
    );
  }

  async refundTransaction(
    invoice: WithRef<IInvoice>,
    transaction: WithRef<ITransaction<IAccountCreditExtendedData>>,
    fromCredit?: WithRef<IAccountCredit>
  ): Promise<
    DocumentReference<ITransaction<IAccountCreditExtendedData>> | undefined
  > {
    const acceptedRefundAlert =
      await this._refundDepositPrompt.showRefundDepositAlert(invoice);
    if (!acceptedRefundAlert) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }
    const credits = await Transaction.getAssociatedCredits(transaction);
    const result = await this._dialog
      .open<
        RefundCreditTransactionDialogComponent,
        IRefundCreditTransactionDialogData,
        IRefundCreditTransactionDialogResult
      >(
        RefundCreditTransactionDialogComponent,
        DialogPresets.medium({
          data: {
            invoice,
            credits,
            transaction,
            fromCredit,
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!result || result.amount <= 0) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }

    const patient = await snapshot(Invoice.patient$(invoice));

    const accountCreditsToRefund = refundUsedCredits(
      result.usedCredits,
      result.amount
    );

    const creditTransaction = this._getCreditTransaction(
      transaction,
      result,
      result.usedCredits,
      accountCreditsToRefund
    );

    const transactionRef = await this._accountFunctions.addTransactionToInvoice(
      invoice,
      creditTransaction,
      TransactionAction.Refund
    );

    if (accountCreditsToRefund.remaining > 0) {
      const credit = AccountCredit.init({
        type: AccountCreditType.Refund,
        amount: result.amount,
        description: 'Refund',
        practiceRef: transaction.practiceRef,
      });

      await Invoice.addAccountCredit(patient, credit);
    }
    return transactionRef;
  }

  private _getCreditTransaction(
    transaction: WithRef<ITransaction<IAccountCreditExtendedData>>,
    result: IRefundCreditTransactionDialogResult,
    accountCreditsUsed: IUsedAccountCredit[],
    accountCreditsToRefund: IRefundCreditResults
  ): ITransaction<IAccountCreditExtendedData> {
    return Transaction.init<IAccountCreditExtendedData>({
      ...omit(transaction, 'ref'),
      amount: result.amount,
      type: TransactionType.Outgoing,
      status: TransactionStatus.Complete,
      createdAt: toTimestamp(),
      updatedAt: toTimestamp(),
      extendedData: {
        accountCreditsUsed: accountCreditsUsed.map((accountCredit) => {
          const refundedCredit = accountCreditsToRefund.alteredCredits.find(
            (alteredCredit) =>
              isSameRef(alteredCredit.accountCreditRef, accountCredit)
          );
          if (!refundedCredit) {
            return accountCredit;
          }
          return {
            ...accountCredit,
            totalRefunded: accountCredit.totalRefunded + refundedCredit.amount,
            refunded: refundedCredit.amount,
          };
        }),
      },
    });
  }

  private async _getTransaction(
    invoice: WithRef<IInvoice>,
    credits: WithRef<IAccountCredit>[]
  ): Promise<IAccountCreditTransactionReturnData | undefined> {
    const transaction = await getBaseTransaction(invoice);
    return this._dialog
      .open<
        AccountCreditTransactionComponent,
        IAccountCreditTransactionDialogData,
        IAccountCreditTransactionReturnData
      >(
        AccountCreditTransactionComponent,
        DialogPresets.medium<IAccountCreditTransactionDialogData>({
          data: { transaction, credits, invoice },
        })
      )
      .afterClosed()
      .toPromise();
  }

  private _getOwnerCredits$(
    invoice: WithRef<IInvoice>
  ): Observable<WithRef<IAccountCredit>[]> {
    return Invoice.patient$(invoice).pipe(
      switchMap((patient) =>
        Patient.withPatientRelationships$(
          patient,
          [PatientRelationshipType.DuplicatePatient],
          AccountCredit.all$
        )
      )
    );
  }
}
