import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  type IContact,
  type IInteraction,
} from '@principle-theorem/principle-core/interfaces';
import {
  doc$,
  filterUndefined,
  findProp,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-contact-timeline',
  templateUrl: './contact-timeline.component.html',
  styleUrls: ['./contact-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactTimelineComponent {
  interactions$: Observable<IInteraction[]>;

  constructor(private _route: ActivatedRoute) {
    this.interactions$ = this._route.data.pipe(
      filter(() => !!this._route.parent),
      findProp<WithRef<IContact>>('contact'),
      filterUndefined(),
      switchMap((contact) => doc$(contact.ref)),
      map((contact) => contact.interactions)
    );
  }
}
