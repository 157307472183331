import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IPractice } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-practice-smartpay-settings',
    templateUrl: './practice-smartpay-settings.component.html',
    styleUrls: ['./practice-smartpay-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PracticeSmartpaySettingsComponent {
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);

  @Input()
  set practice(practice: WithRef<IPractice>) {
    if (practice) {
      this.practice$.next(practice);
    }
  }
}
