import { BlockNodes } from '@principle-theorem/editor';
import { Editor } from '@tiptap/core';

export class AIMenuButtonFilters {
  static disableWithoutTextSelection(editor: Editor): boolean | string {
    const isParagraph = editor.isActive(BlockNodes.Paragraph);
    const { from, to } = editor.view.state.selection;
    const text = editor.state.doc.textBetween(from, to, '\n');
    const hasContent = text.trim().length > 0;
    if (!isParagraph) {
      return true;
    }

    if (!hasContent) {
      return 'Select text to use this feature';
    }

    return false;
  }
}
