import {
  IHicapsConnectTerminal,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference, WithRef } from '@principle-theorem/shared';
import { compact, first, intersection } from 'lodash';

export interface IBillingEntityInfo {
  providerNumber?: string;
  merchantId?: string;
  terminalId?: string;
}

export interface IBillingMapping {
  billingInfo: IBillingEntityInfo;
  stafferRef: DocumentReference<IStaffer>;
}

export class BillingRegistry {
  byRef: Record<string, WithRef<IStaffer>> = {};
  byProviderNumber: Record<string, WithRef<IStaffer>[]> = {};
  byMerchantId: Record<string, WithRef<IStaffer>[]> = {};
  byTerminalId: Record<string, WithRef<IStaffer>[]> = {};

  constructor(
    staff: WithRef<IStaffer>[],
    customMappings: IBillingMapping[] = []
  ) {
    this.byRef = staff.reduce(
      (acc, staffer) => ({ ...acc, [staffer.ref.path]: staffer }),
      {}
    );

    this._applyBillingMappings(
      [BillingMappingsFactory.fromStaff(staff), customMappings].flat()
    );
  }

  findByRef(ref: DocumentReference<IStaffer>): WithRef<IStaffer> | undefined {
    return this.byRef[ref.path];
  }

  findByBillingInfo(
    billingInfo?: IBillingEntityInfo
  ): WithRef<IStaffer> | undefined {
    if (!billingInfo) {
      return;
    }
    const providerMatches = this._findMatches(
      this.byProviderNumber,
      billingInfo.providerNumber
    );
    const merchantMatches = this._findMatches(
      this.byMerchantId,
      billingInfo.merchantId
    );
    const terminalMatches = this._findMatches(
      this.byTerminalId,
      billingInfo.terminalId
    );

    // Attempt a strict match, use all billing info to find a match
    const strictMatchWith = compact([
      providerMatches,
      merchantMatches,
      terminalMatches,
    ]);
    const strictMatch = this._narrowMatches(billingInfo, strictMatchWith);
    if (strictMatch) {
      return strictMatch;
    }

    // Attempt a partial match, use only the data with matches to find a match
    const partialMatchWith = strictMatchWith.filter(
      (matches) => matches.length > 0
    );
    const partialMatch = this._narrowMatches(billingInfo, partialMatchWith);
    if (partialMatch) {
      return partialMatch;
    }

    // eslint-disable-next-line no-console
    console.log(`No match found for ${JSON.stringify(billingInfo)}`);
    return;
  }

  private _applyBillingMappings(billingMappings: IBillingMapping[]): void {
    billingMappings.map(({ billingInfo, stafferRef }) => {
      const staffer = this.findByRef(stafferRef);
      if (!staffer) {
        // eslint-disable-next-line no-console
        console.error(`Staffer not found for ref ${stafferRef.path}`);
        return;
      }
      if (billingInfo.providerNumber) {
        this._append(
          this.byProviderNumber,
          billingInfo.providerNumber,
          staffer
        );
      }
      if (billingInfo.merchantId) {
        this._append(this.byMerchantId, billingInfo.merchantId, staffer);
      }
      if (billingInfo.terminalId) {
        this._append(this.byTerminalId, billingInfo.terminalId, staffer);
      }
    });
  }

  private _append(
    record: Record<string, WithRef<IStaffer>[]>,
    key: string,
    staffer: WithRef<IStaffer>
  ): void {
    const existing = record[key] ?? [];
    record[key] = [...existing, staffer];
  }

  /**
   * Find matches in the record for the given key.
   * A result of undefined means we have no key to match by,
   * so we should not attempt to find a match.
   * A result of an empty array means we have a key to match by,
   * but there are no matches in the record.
   */
  private _findMatches(
    record: Record<string, WithRef<IStaffer>[]>,
    key?: string
  ): WithRef<IStaffer>[] | undefined {
    if (!key) {
      return undefined;
    }
    return record[key] ?? [];
  }

  private _narrowMatches(
    billingInfo: IBillingEntityInfo,
    matches: WithRef<IStaffer>[][]
  ): WithRef<IStaffer> | undefined {
    const strictMatches = intersection(...matches);
    if (strictMatches.length === 1) {
      return first(strictMatches);
    }
    if (strictMatches.length > 1) {
      // eslint-disable-next-line no-console
      console.error(
        `Multiple staffers found for ${JSON.stringify(billingInfo)}`
      );
      return;
    }
  }
}

export class BillingMappingsFactory {
  static fromStaff(staff: WithRef<IStaffer>[]): IBillingMapping[] {
    return staff.flatMap((staffer) => this.fromStaffer(staffer));
  }

  static fromStaffer(staffer: WithRef<IStaffer>): IBillingMapping[] {
    return staffer.providerDetails.map(
      (provider): IBillingMapping => ({
        stafferRef: staffer.ref,
        billingInfo: {
          providerNumber: provider.providerNumber,
        },
      })
    );
  }

  static fromHicapsTerminals(
    terminals: IHicapsConnectTerminal[]
  ): IBillingMapping[] {
    return compact(
      terminals.map((terminal) => this.fromHicapsTerminal(terminal))
    );
  }

  static fromHicapsTerminal(
    terminal: IHicapsConnectTerminal
  ): IBillingMapping | undefined {
    if (!terminal.practitionerRef) {
      return;
    }
    return {
      stafferRef: terminal.practitionerRef,
      billingInfo: {
        // providerNumber: terminal.providerNumber, // TODO: Need to grab and store this
        merchantId: terminal.merchantId,
        terminalId: terminal.terminalId,
      },
    };
  }
}
