import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  computed,
  signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { type Observable } from 'rxjs';

export interface IBreadcrumb {
  label: string;
  path?: string[] | string;
  feature?: string;
  alert$?: Observable<boolean>;
}

@Component({
  selector: 'pt-breadcrumbs',
  standalone: true,
  imports: [CommonModule, RouterModule, MatIconModule],
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  private _breadcrumbs = signal<IBreadcrumb[]>([]);

  currentValue = signal<string | undefined>(undefined);
  breadcrumbsValue = computed(() => {
    const current = this.currentValue();
    const breadcrumbs = this._breadcrumbs();
    return current ? [...breadcrumbs, { label: current }] : breadcrumbs;
  });

  @Input()
  set current(current: string) {
    if (current) {
      this.currentValue.set(current);
    }
  }

  @Input()
  set breadcrumbs(breadcrumbs: IBreadcrumb[]) {
    if (breadcrumbs) {
      this._breadcrumbs.set(breadcrumbs);
    }
  }
}
