import { CdkDropList } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChildren,
  type AfterViewInit,
  type OnDestroy,
  type QueryList,
} from '@angular/core';
import {
  ScrollContainer,
  ScrollContainerManagerService,
} from '@principle-theorem/ng-principle-shared';
import { queryListToObservable } from '@principle-theorem/ng-shared';
import { TreatmentStep } from '@principle-theorem/principle-core';
import {
  TreatmentStepStatus,
  isTreatmentPlan,
  isTreatmentStep,
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import { isReffable, type WithRef } from '@principle-theorem/shared';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  type Observable,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { type IEditChartableData } from '../../chartable-surface-updater';
import {
  StepDragDropGroup,
  type IStepDragDropNode,
  type StepItem,
} from '../../step-drag-drop-group';
import { TreatmentStepsDisplayService } from '../treatment-steps-editor/treatment-steps-display.service';

@Component({
    selector: 'pr-multi-step-treatment',
    templateUrl: './multi-step-treatment.component.html',
    styleUrls: ['./multi-step-treatment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MultiStepTreatmentComponent implements AfterViewInit, OnDestroy {
  private _cdKDropLists$: Observable<CdkDropList<StepItem>[]>;

  steps$ = new ReplaySubject<
    (IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>)[]
  >(1);
  plan$ = new ReplaySubject<
    IChartedMultiStepTreatment | WithRef<ITreatmentPlan>
  >(1);
  canAddStep$: Observable<boolean>;
  listData$: Observable<IStepDragDropNode>;
  selectingAssociatedPlan$ = new BehaviorSubject<boolean>(false);

  @Input() stepDragDrop = new StepDragDropGroup();
  @ViewChildren(CdkDropList) cdkDropLists: QueryList<CdkDropList<StepItem>>;
  scrollContainer$: Observable<HTMLElement>;
  @Input() disabled = false;
  @Output() stepChange = new EventEmitter<
    IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>
  >();
  @Output() stepAdd = new EventEmitter<void>();
  @Output() stepDelete = new EventEmitter<
    IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>
  >();
  @Output() selectChild = new EventEmitter<void>();
  @Output() updateChartable = new EventEmitter<
    IEditChartableData & {
      step: IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>;
    }
  >();

  @Input()
  set steps(
    steps: (IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>)[]
  ) {
    if (steps) {
      this.steps$.next(steps);
    }
  }

  @Input()
  set plan(plan: IChartedMultiStepTreatment | WithRef<ITreatmentPlan>) {
    if (plan) {
      this.plan$.next(plan);
    }
  }

  constructor(
    private _scrollContainerManager: ScrollContainerManagerService,
    public stepDisplay: TreatmentStepsDisplayService
  ) {
    this.scrollContainer$ = this._scrollContainerManager.getContainer(
      ScrollContainer.InAppointment
    );

    this.canAddStep$ = this.plan$.pipe(map(isTreatmentPlan));
    this.listData$ = combineLatest([this.plan$, this.steps$]).pipe(
      map(([parent, items]) => ({ parent, items }))
    );
  }

  ngAfterViewInit(): void {
    this._cdKDropLists$ = queryListToObservable(this.cdkDropLists);
    setTimeout(() => this.stepDragDrop.register(this._cdKDropLists$));
  }

  ngOnDestroy(): void {
    this.stepDragDrop.unregister(this._cdKDropLists$);
  }

  isVisible(step: ITreatmentStep): boolean {
    return TreatmentStep.isIncomplete(step);
  }

  updateStep(
    changes: Partial<IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>>,
    step: IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>
  ): void {
    this.stepChange.emit({
      ...step,
      ...changes,
    });
  }

  deleteStep(
    step: IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>
  ): void {
    this.stepDelete.next(step);
  }

  addStep(): void {
    if (this.stepDisplay.isUnscheduledHidden()) {
      this.stepDisplay.enableUnscheduledFilter();
    }
    return this.stepAdd.next();
  }

  dragDisabled$(step: ITreatmentStep): Observable<boolean> {
    return TreatmentStep.appointment$(step).pipe(
      map((appointment) =>
        this.disabled ||
        step.status === TreatmentStepStatus.Complete ||
        appointment?.event?.from
          ? true
          : false
      )
    );
  }

  trackByUuidFn(
    _index: number,
    item: IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>
  ): string {
    if (isTreatmentStep(item) && isReffable(item)) {
      return item.ref.id;
    }
    return item.uid;
  }

  generateChild(): void {
    this.selectingAssociatedPlan$.next(true);
    this.selectChild.emit();
  }

  updateSurfaces(
    data: IEditChartableData,
    plan: IChartedMultiStepTreatment | WithRef<ITreatmentPlan>,
    step: IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>
  ): void {
    return this.updateChartable.emit({ ...data, plan, step });
  }
}
