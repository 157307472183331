import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'pr-account-summary-datapoint',
    templateUrl: './account-summary-datapoint.component.html',
    styleUrls: ['./account-summary-datapoint.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AccountSummaryDatapointComponent {
  @Input() value: number;
  @Input() description: string;
  @Input() tooltip?: string;
}
