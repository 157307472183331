import {
  Component,
  EventEmitter,
  Output,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  ChartView,
  CHART_VIEWS,
} from '@principle-theorem/principle-core/interfaces';
import { TrackByFunctions } from '@principle-theorem/ng-shared';

@Component({
    selector: 'pr-chart-view-selector',
    templateUrl: './chart-view-selector.component.html',
    styleUrls: ['./chart-view-selector.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChartViewSelectorComponent {
  trackByView = TrackByFunctions.variable<ChartView>();
  viewGroups: ChartView[][] = [CHART_VIEWS];
  @Input() view: ChartView = ChartView.Adult;
  @Output()
  selectionChange: EventEmitter<ChartView> = new EventEmitter<ChartView>();

  select(view: ChartView): void {
    this.view = view;
    this.selectionChange.emit(this.view);
  }
}
