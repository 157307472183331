import { Injectable } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';
import { saveAs } from 'file-saver';
import { chain, first, get, isNumber } from 'lodash';

export interface ICSVExport<
  RecordFormat extends object,
  CSVFormat extends object,
> {
  headers: string[];
  defaultFileName: string;
  translate(records: RecordFormat[]): Promise<CSVFormat[]> | CSVFormat[];
}

export interface ICSVExportOptions {
  includeHeaders?: boolean;
  includeTotals?: boolean;
}

@Injectable()
export class CSVExporterService {
  async download<RecordFormat extends object, CSVFormat extends object>(
    fileName: string,
    records: RecordFormat[],
    csvTranslator: ICSVExport<RecordFormat, CSVFormat>,
    options: ICSVExportOptions = {}
  ): Promise<void> {
    const csvExporter = new ExportToCsv({
      filename: fileName,
      showLabels: true,
    });

    const rows = await csvTranslator.translate(records);
    const dataRows = rows.map((row) => Object.values(row) as object);
    if (options.includeTotals) {
      dataRows.push(this._getColumnTotals(rows));
    }

    const csvData = options.includeHeaders
      ? [csvTranslator.headers, ...dataRows]
      : dataRows;
    const generatedCsv = String(csvExporter.generateCsv(csvData, true));

    const fileBlob = new Blob([generatedCsv], {
      type: 'csv',
    });
    saveAs(fileBlob, decodeURI(`${fileName}.csv`));
  }

  private _getColumnTotals(rows: object[]): (number | string)[] {
    const firstRow = first(rows);
    if (!firstRow) {
      return [];
    }

    return Object.keys(firstRow).map((columnKey) => {
      const columnValues = rows.map((row) => get(row, columnKey) as unknown);

      if (!columnValues.some(isNumber)) {
        return '';
      }

      return chain(columnValues)
        .map((value) => (isNumber(value) ? value : 0))
        .sum()
        .round(2)
        .value();
    });
  }
}
