import { toTimeString } from '@principle-theorem/shared';
import { type IRecurringTaskFormData } from './components/recurring-task-configuration-form/recurring-task-configuration-form';
import { type ITaskFormData } from './components/task-form/task-form.formgroup';

export function getSummary(
  formData: IRecurringTaskFormData | ITaskFormData
): string {
  if (!formData.recurrenceTiming) {
    return '';
  }

  if (!formData.recurrenceTiming.unit || !formData.recurrenceTiming.amount) {
    return '';
  }

  const dueTime: string = formData.recurrenceTiming.time
    ? `at ${toTimeString(formData.recurrenceTiming.time)},`
    : '';
  const unit: string = formData.recurrenceTiming.unit
    ? `${formData.recurrenceTiming.unit}`
    : '';
  const amount: string = formData.recurrenceTiming.amount
    ? `${formData.recurrenceTiming.amount}`
    : '';

  if (!unit || !amount) {
    return '';
  }
  return `Future tasks will be due ${dueTime} ${amount} ${unit} after creation`;
}
