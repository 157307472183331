import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import {
  GlobalStoreService,
  NgPrincipleSharedModule,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import {
  AUTO_ALLOCATED_PLACEHOLDER,
  AUTO_ALLOCATED_TOOLTIP,
  Staffer,
} from '@principle-theorem/principle-core';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import {
  IInvoice,
  ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, snapshot } from '@principle-theorem/shared';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GeneralProviderActionsService } from '../transaction-providers/general-provider-actions.service';
import { TransactionProvidersModule } from '../transaction-providers/transaction-providers.module';

@Component({
    selector: 'pr-transaction-allocation-display',
    imports: [
        CommonModule,
        NgSharedModule,
        NgPrincipleSharedModule,
        TransactionProvidersModule,
    ],
    templateUrl: './transaction-allocation-display.component.html',
    styleUrl: './transaction-allocation-display.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionAllocationDisplayComponent {
  private _org = inject(OrganisationService);
  private _globalStore = inject(GlobalStoreService);
  private _actions = inject(GeneralProviderActionsService);
  invoice$ = new ReplaySubject<WithRef<IInvoice>>(1);
  transaction$ = new ReplaySubject<WithRef<ITransaction>>(1);
  attributedTo$: Observable<string>;
  attributedToTooltip$: Observable<string>;
  canChange$: Observable<boolean>;

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this.invoice$.next(invoice);
    }
  }

  @Input()
  set transaction(transaction: WithRef<ITransaction>) {
    if (transaction) {
      this.transaction$.next(transaction);
    }
  }

  constructor() {
    this.attributedTo$ = this.transaction$.pipe(
      switchMap((transaction) => this._getAttributedTo$(transaction))
    );

    this.attributedToTooltip$ = this.attributedTo$.pipe(
      map((attributedTo) => {
        if (attributedTo === AUTO_ALLOCATED_PLACEHOLDER) {
          return AUTO_ALLOCATED_TOOLTIP;
        }
        return `The transaction will be allocated to ${attributedTo}.`;
      })
    );

    this.canChange$ = this._org.staffer$.pipe(
      switchMap((staffer) =>
        staffer
          ? Staffer.hasPermission$(
              staffer,
              PatientPermissions.AccountInvoiceAdmin
            )
          : of(false)
      )
    );
  }

  async changeAttributedTo(): Promise<void> {
    const canChange = await snapshot(this.canChange$);
    const invoice = await snapshot(this.invoice$);
    const transaction = await snapshot(this.transaction$);
    if (!canChange || !invoice || !transaction) {
      return;
    }
    await this._actions.changeAttributedTo(invoice, transaction);
  }

  private _getAttributedTo$(
    transaction: WithRef<ITransaction>
  ): Observable<string> {
    return transaction.attributedTo
      ? this._globalStore.getStafferName$({ ref: transaction.attributedTo })
      : of(AUTO_ALLOCATED_PLACEHOLDER);
  }
}
