<mat-toolbar color="accent">Template Variables</mat-toolbar>

<div class="layout-padding">
  @if (hasContext$ | async) {
    <pr-context-preview [context]="data.context" />
  } @else {
    <span>
      No template variables available. Please select a Template Scope.
    </span>
  }
</div>

<div class="layout-padding" fxLayoutAlign="end center">
  <button type="button" mat-stroked-button mat-dialog-close>Close</button>
</div>
