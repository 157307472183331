import { Injector } from '@angular/core';
import { wrapAngularExtension } from '@principle-theorem/ng-prosemirror';
import {
  type ISnippetAutocompleteNodeConfig,
  SnippetAutocompleteNodeComponent,
} from './snippet-autocomplete-node/snippet-autocomplete-node.component';
import { createSnippetKeymap } from './snippet-keymap';
import { AnyExtension } from '@tiptap/core';

export function createSnippetAutocompleteExtension(
  injector: Injector,
  config: ISnippetAutocompleteNodeConfig
): AnyExtension {
  return wrapAngularExtension(
    SnippetAutocompleteNodeComponent,
    [createSnippetKeymap()],
    config
  )(injector);
}
