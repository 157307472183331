import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { getDoc$, type WithRef } from '@principle-theorem/shared';
import {
  type IBrand,
  type ILab,
} from '@principle-theorem/principle-core/interfaces';
import { Brand } from '@principle-theorem/principle-core';
import { type Observable } from 'rxjs';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import { AbstractResolver } from '@principle-theorem/ng-shared';

@Injectable()
export class LabResolverService extends AbstractResolver<WithRef<ILab>> {
  labs: WithRef<ILab>[];

  getItemById(
    uid: string,
    route: ActivatedRouteSnapshot
  ): Observable<WithRef<ILab>> {
    const brandScope: CurrentBrandScope = new CurrentBrandScope(this._router);
    const brand: WithRef<IBrand> | undefined = brandScope.findModel(route);
    if (!brand) {
      throw new Error('No Brand found');
    }
    return getDoc$(Brand.labCol(brand), uid);
  }
}
