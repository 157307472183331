import {
  DateRangeFilter,
  ICustomReportFilterValue,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  ISODateType,
  Timestamp,
  TypeGuard,
  isArray,
  serialise,
} from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { IQueryScope, isQueryScope } from './scoped-query';

export enum FilterOperation {
  Practice = 'practice.uid === uid',
  Practitioner = 'practitioner.uid === uid',
  DateRange = 'BETWEEN(from, to)',
}

export enum GroupBy {
  Ref = 'ref.referenceValue',
  Gap = 'gap.ref.referenceValue',
  Task = 'task.ref.referenceValue',
  LabJob = 'labJob.ref.referenceValue',
  Invoice = 'invoice.ref.referenceValue',
  Patient = 'patient.ref.referenceValue',
  Referral = 'referrer.ref.referenceValue',
  Practice = 'practice.ref.referenceValue',
  Treatment = 'treatment.ref.referenceValue',
  PatientAge = 'patient.age',
  Appointment = 'appointment.ref.referenceValue',
  Interaction = 'interaction.ref.referenceValue',
  PaymentPlan = 'paymentPlan.ref.referenceValue',
  Practitioner = 'practitioner.ref.referenceValue',
  PatientGender = 'patient.gender',
  TreatmentPlan = 'treatmentPlan.ref.referenceValue',
  TreatmentStep = 'treatmentStepRef.referenceValue',
  PatientDistance = 'patient.distance',
  Transaction = 'transaction.ref.referenceValue',
  AccountCredit = 'accountCredit.ref.referenceValue',
  ScheduleSummary = 'scheduleSummaryRef.referenceValue',
  SchedulingEvent = 'schedulingEventRef.referenceValue',
}

export enum WhereOperation {
  Or = 'OR',
  And = 'AND',
  Between = 'BETWEEN',
}

export enum AttributeOperation {
  Sum = 'SUM',
  Mean = 'AVG',
  Count = 'COUNT',
  Median = 'MEDIAN',
  Unnest = 'UNNEST',
}

export enum SortOrder {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export interface IOrderBy {
  attribute: string;
  sortOrder: SortOrder;
}

export type QueryFilter = string;

export interface IUnnestQuery {
  property: string;
  alias: string;
}

export const isUnnestQuery = TypeGuard.interface<IUnnestQuery>({
  property: isString,
  alias: isString,
});

export interface ITableJoin {
  table: string;
  alias: string;
  sourceJoinKey: string;
  destinationJoinKey: string;
  joinType: 'LEFT' | 'INNER';
  orderByProperty?: string;
}

export const isTableJoin = TypeGuard.interface<ITableJoin>({
  table: isString,
  alias: isString,
  sourceJoinKey: isString,
  destinationJoinKey: isString,
  joinType: isString,
  orderByProperty: TypeGuard.undefinedOr(isString),
});

export interface IScopedQuery {
  dataSet: string;
  query: IReportingQuery;
}

export interface IReportingQueryRequest {
  scope: IQueryScope;
  query: IReportingQuery;
}

export const isReportingQueryRequest =
  TypeGuard.interface<IReportingQueryRequest>({
    scope: isQueryScope,
    query: isReportingQuery,
  });

export interface IReportingQuery {
  table: string;
  attributes: string[];
  operation?: AttributeOperation;
  filters?: QueryFilter[];
  joins?: ITableJoin[];
  groupBy?: GroupBy[];
  orderBy?: IOrderBy[];
  subQuery?: IReportingQuery;
  unnest?: IUnnestQuery;
}

export function isReportingQuery(item: unknown): item is IReportingQuery {
  return TypeGuard.interface<IReportingQuery>({
    table: isString,
    attributes: TypeGuard.arrayOf(isString),
    operation: TypeGuard.undefinedOr(isString),
    filters: TypeGuard.undefinedOr(TypeGuard.arrayOf(isString)),
    joins: TypeGuard.undefinedOr(TypeGuard.arrayOf(isTableJoin)),
    groupBy: TypeGuard.undefinedOr(TypeGuard.arrayOf(isString)),
    orderBy: TypeGuard.undefinedOr(TypeGuard.arrayOf(isString)),
    subQuery: TypeGuard.undefinedOr(isReportingQuery),
    unnest: TypeGuard.undefinedOr(isUnnestQuery),
  })(item);
}

export class BigQuerySQL {
  static or(statements: string[]): string {
    return `(${statements.join(` ${WhereOperation.Or} `)})`;
  }

  static and(statements: string[]): string {
    return `(${statements.join(` ${WhereOperation.And} `)})`;
  }

  static filter(
    path: string,
    comparison: '=' | '>=' | '<=' | 'IS' | 'IS NOT',
    value: string | number | boolean | undefined
  ): string {
    if (value === undefined) {
      return `${path} ${comparison} NULL`;
    }
    if (isNumber(value)) {
      return `${path} ${comparison} ${value}`;
    }
    if (isString(value)) {
      return `${path} ${comparison} "${value}"`;
    }
    return `${path} ${comparison} ${value.toString()}`;
  }

  static docRef<T>(
    path: string,
    comparison: '=' | '>=' | '<=',
    value: DocumentReference<T>
  ): string {
    return this.filter(path, comparison, serialise(value).referenceValue);
  }

  static timestamp(
    path: string,
    comparison: '=' | '>=' | '<=',
    value: Timestamp
  ): string {
    return this.filter(path, comparison, serialise(value).timestampValue);
  }

  static dateRange(path: string, from: ISODateType, to: ISODateType): string {
    return this.and([
      this.filter(path, '>=', from),
      this.filter(path, '<=', to),
    ]);
  }

  static objectInArray(arrayPath: string, whereCondition: string): string {
    return `EXISTS(SELECT 1 FROM UNNEST(${arrayPath}) WHERE ${whereCondition})`;
  }
}

export function getCustomReportFilterSQL(
  path: string,
  filters: ICustomReportFilterValue[],
  convertValuesToNull: (string | number | DateRangeFilter)[] = []
): string {
  return BigQuerySQL.or(
    filters.map((filter) => {
      if (convertValuesToNull.includes(filter.value)) {
        return BigQuerySQL.filter(path, 'IS', undefined);
      }
      return isArray(filter.value)
        ? BigQuerySQL.dateRange(path, filter.value[0], filter.value[1])
        : BigQuerySQL.filter(path, '=', filter.value);
    })
  );
}
