import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TreatmentPlan } from '@principle-theorem/principle-core';
import {
  TreatmentStepStatus,
  type ITreatmentPlan,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TreatmentStepsDisplayService } from '../treatment-steps-editor/treatment-steps-display.service';

@Component({
    selector: 'pr-treatment-plan-header',
    templateUrl: './treatment-plan-header.component.html',
    styleUrls: ['./treatment-plan-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreatmentPlanHeaderComponent {
  treatmentPlan$ = new ReplaySubject<WithRef<ITreatmentPlan>>(1);
  incompleteSteps$: Observable<string>;
  @Input() canAddStep = false;
  @Output() addStep = new EventEmitter<void>();

  @Input()
  set treatmentPlan(treatmentPlan: WithRef<ITreatmentPlan>) {
    if (treatmentPlan) {
      this.treatmentPlan$.next(treatmentPlan);
    }
  }

  constructor(public stepDisplay: TreatmentStepsDisplayService) {
    this.incompleteSteps$ = this.treatmentPlan$.pipe(
      switchMap((treatmentPlan) => TreatmentPlan.orderedSteps$(treatmentPlan)),
      map((steps) => {
        const incompleteSteps = steps.filter(
          (step) => step.status === TreatmentStepStatus.Incomplete
        );
        return `${incompleteSteps.length}/${steps.length}`;
      })
    );
  }

  createNewStep(): void {
    if (this.stepDisplay.isUnscheduledHidden()) {
      this.stepDisplay.enableUnscheduledFilter();
    }
    this.addStep.emit();
  }
}
