import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import {
  AnyAutomationConfiguration,
  ITreatmentConfiguration,
  TemplateScope,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TreatmentStepAutomationConfigurationService } from './treatment-automation-configuration.service';
import { AutomationConfigurationSort } from '@principle-theorem/ng-automations';

@Component({
    selector: 'pr-treatment-automation-configuration',
    templateUrl: './treatment-automation-configuration.component.html',
    styleUrl: './treatment-automation-configuration.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreatmentAutomationConfigurationComponent {
  treatmentAutomation = inject(TreatmentStepAutomationConfigurationService);
  automationConfigs$: Observable<WithRef<AnyAutomationConfiguration>[]>;
  treatmentConfig$ = new ReplaySubject<WithRef<ITreatmentConfiguration>>(1);
  templateScope$ = new ReplaySubject<TemplateScope>(1);

  @Input()
  set treatmentConfig(treatmentConfig: WithRef<ITreatmentConfiguration>) {
    if (treatmentConfig) {
      this.treatmentConfig$.next(treatmentConfig);
    }
  }

  @Input()
  set templateScope(scope: TemplateScope) {
    if (scope) {
      this.templateScope$.next(scope);
    }
  }

  constructor() {
    this.automationConfigs$ = this.treatmentConfig$.pipe(
      switchMap((treatmentConfig) =>
        this.treatmentAutomation.getTreatmentAutomationConfigs$(treatmentConfig)
      ),
      map((automationConfigs) =>
        AutomationConfigurationSort.sort(automationConfigs)
      )
    );
  }
}
