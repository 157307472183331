import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { initVersionedSchema } from '@principle-theorem/editor';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TypedFormControl,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { FollowUp } from '@principle-theorem/principle-core';
import {
  filterUndefined,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FollowUpFormGroup } from './follow-up.formgroup';
import { FollowUpsService } from '../../follow-ups.service';
import moment from 'moment-timezone';
import { FOLLOW_UP_DATE_PRESETS, IFollowUpDatePreset } from '../../follow-up';
import { find } from 'lodash';

@Component({
    selector: 'pr-follow-up',
    styleUrls: ['./follow-up.component.scss'],
    templateUrl: './follow-up.component.html',
    providers: [...MOMENT_DATEPICKER_PROVIDERS],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FollowUpComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  followUpFormGroup = new FollowUpFormGroup();
  appointment: WithRef<IAppointment>;
  patient: WithRef<IPatient>;
  followUpReasonRequired$: Observable<boolean>;
  minDate = moment();
  datePresets = FOLLOW_UP_DATE_PRESETS;
  datePresetCtrl = new TypedFormControl<IFollowUpDatePreset>(
    find(this.datePresets, { label: '2 Weeks' })
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAppointmentFollowUpData,
    public dialogRef: MatDialogRef<FollowUpComponent>,
    private _followUpService: FollowUpsService
  ) {
    this.patient = data.patient;
    this.appointment = data.appointment;

    this.followUpReasonRequired$ =
      this.followUpFormGroup.controls.createFollowUp.valueChanges.pipe(
        map((createFollowUp) => !createFollowUp)
      );

    this.followUpFormGroup.controls.createFollowUp.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((createFollowUp) => {
        if (createFollowUp) {
          this.followUpFormGroup.controls.followUpDate.setValidators([
            Validators.required,
          ]);
          this.followUpFormGroup.controls.noFollowUpReason.clearValidators();
          this.followUpFormGroup.controls.noFollowUpReason.reset(
            initVersionedSchema()
          );
          return;
        }
        this.followUpFormGroup.controls.noFollowUpReason.setValidators([
          Validators.required,
        ]);
        this.followUpFormGroup.controls.followUpDate.clearValidators();
        this.followUpFormGroup.controls.followUpDate.reset();
      });

    formControlChanges$(this.datePresetCtrl)
      .pipe(filterUndefined(), takeUntil(this._onDestroy$))
      .subscribe((preset) =>
        this.followUpFormGroup.controls.followUpDate.setValue(
          moment().add(preset.amount, preset.unit)
        )
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async submitFollowUp(): Promise<void> {
    if (!this.followUpFormGroup.valid) {
      return;
    }
    const followUpData = this.followUpFormGroup.getRawValue();
    const followUp = FollowUp.init({
      createFollowUp: followUpData.createFollowUp,
      followUpDate: followUpData.createFollowUp
        ? toTimestamp(followUpData.followUpDate)
        : undefined,
      noFollowUpReason: !followUpData.createFollowUp
        ? followUpData.noFollowUpReason
        : undefined,
    });
    await this._followUpService.addToAppointment(this.appointment, followUp);
    this.dialogRef.close();
  }
}

export interface IAppointmentFollowUpData {
  appointment: WithRef<IAppointment>;
  patient: WithRef<IPatient>;
}
