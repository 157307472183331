<mat-form-field class="flex-1" [appearance]="appearance">
  <mat-label>Fee Schedule</mat-label>
  <mat-select
    [formControl]="selectedSchedule"
    [compareWith]="compareFn"
    [disabled]="disabled"
  >
    <mat-optgroup
      *ngFor="let group of groups$ | async; trackBy: trackByGroup"
      [label]="group.name"
    >
      <mat-option
        *ngFor="let schedule of group.schedules; trackBy: trackBySchedule"
        [value]="schedule"
      >
        {{ schedule.name }}
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>
