<ng-container *ngIf="summary$ | async as summary">
  <h3 class="layout-margin">{{ summary.type }}</h3>
  <mat-table [dataSource]="dataSource">
    <mat-header-row *matHeaderRowDef="displayedColumns" />
    <mat-row *matRowDef="let row; columns: displayedColumns" />
    <mat-footer-row *matFooterRowDef="displayedColumns" />
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>Source</mat-header-cell>
      <mat-cell *matCellDef="let source">
        <a
          *ngIf="summary.type !== totalType"
          href="javascript:void(0);"
          (click)="reportFacade.selectSummary(source)"
        >
          {{ source.name }}
        </a>
        <span *ngIf="summary.type === totalType">{{ source.name }}</span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef
        ><strong>Total</strong></mat-footer-cell
      >
    </ng-container>
    <ng-container matColumnDef="pending">
      <mat-header-cell *matHeaderCellDef>Pending</mat-header-cell>
      <mat-cell *matCellDef="let source">
        <div class="amount" [ngClass]="source.total.pending | map: colourClass">
          {{ source.total.pending | currency }}
        </div>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef>
        <strong
          class="amount"
          [ngClass]="summary.total.pending | map: colourClass"
          >{{ summary.total.pending | currency }}</strong
        >
      </mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="complete">
      <mat-header-cell *matHeaderCellDef>Complete</mat-header-cell>
      <mat-cell *matCellDef="let source">
        <div
          class="amount"
          [ngClass]="source.total.complete | map: colourClass"
        >
          {{ source.total.complete | currency }}
        </div>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef>
        <strong
          class="amount"
          [ngClass]="summary.total.complete | map: colourClass"
          >{{ summary.total.complete | currency }}</strong
        >
      </mat-footer-cell>
    </ng-container>
  </mat-table>
</ng-container>
