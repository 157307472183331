import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AbstractResolver } from '@principle-theorem/ng-shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PatientFormResolverService } from './patient-form-resolver.service';
import { PatientForm } from '@principle-theorem/principle-core';

@Injectable()
export class PatientFormNameResolverService extends AbstractResolver<string> {
  getItemById(
    uid: string,
    route: ActivatedRouteSnapshot
  ): Observable<string | undefined> {
    const formScope = new PatientFormResolverService(this._router);
    return formScope
      .getItemById(uid, route)
      .pipe(map((form) => (form ? PatientForm.getName(form) : undefined)));
  }
}
