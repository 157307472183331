import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  type AnyChartedItemConfiguration,
  type IChartedItem,
  type IChartedItemConfiguration,
  type IChartedRef,
  type IFeeSchedule,
  type IMultiTreatmentConfiguration,
  isMultiTreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { ChartDialogService } from '../../chart-dialog.service';
import {
  type IChartedSurfaceProvider,
  type IChartSurfaceEvent,
} from '../add-charted-surface-provider';

export class AddMultiTreatmentToProposalProvider
  implements IChartedSurfaceProvider
{
  constructor(
    private _chartStore: ChartFacade,
    private _chartDialogService: ChartDialogService,
    private _feeSchedule: INamedDocument<IFeeSchedule>
  ) {}

  canProvide(chartable: AnyChartedItemConfiguration): boolean {
    return isMultiTreatmentConfiguration(chartable);
  }

  async addChartedItem(
    _event: IChartSurfaceEvent,
    chartable: WithRef<IMultiTreatmentConfiguration>
  ): Promise<void> {
    const selectedSurfaces = await snapshot(
      this._chartStore.selectedSurfacesState$(ChartId.InAppointment)
    );
    const multiTreatment = await this._chartDialogService.chartMultiTreatment(
      chartable,
      selectedSurfaces
    );
    if (!multiTreatment) {
      return;
    }

    await this._chartStore.addChartedMultiTreatment(ChartId.InAppointment, {
      ...multiTreatment,
      feeSchedule: this._feeSchedule,
    });
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async removeSurfacesFromChartedItem(
    _surfaceRefs: Partial<IChartedRef>[],
    _item: IChartedItem<IChartedItemConfiguration>
  ): Promise<void> {
    // Not implemented
  }
}
