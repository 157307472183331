import {
  IAppointment,
  IBasePatient,
  IInvoice,
  IPatient,
  IPatientContactDetails,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  Timestamp,
  WithRef,
  addDoc,
  snapshot,
  toNamedDocument,
} from '@principle-theorem/shared';
import { Appointment } from '../appointment/appointment';
import { TreatmentStep } from '../clinical-charting/treatment/treatment-step';
import { stafferToNamedDoc } from '../common';
import { OrganisationCache } from '../organisation/organisation-cache';
import { Patient } from '../patient/patient';
import { toAccountDetails } from './create-invoice';
import { treatmentStepToLineItems } from './custom-line-items';
import { HealthcareClaimGenerator } from './healthcare-claim-operators';
import { Invoice } from './invoice';
import { TaxRate } from '@principle-theorem/accounting';

export async function buildInvoiceForAppointment(
  appointment: WithRef<IAppointment>,
  patientRef: DocumentReference<IPatient>,
  taxRate: TaxRate,
  createdAt?: Timestamp
): Promise<IInvoice> {
  if (!appointment.event) {
    throw new Error('Missing required data');
  }

  const practice = await OrganisationCache.practices.getDoc(
    appointment.event.practice.ref
  );
  const primaryContact = await Patient.resolvePrimaryContact(patientRef);
  const patient = await OrganisationCache.patients.getDoc(patientRef);
  const onBehalfOf = toAccountDetails(
    patient as IBasePatient & IPatientContactDetails
  );

  const treatmentPlan = await Appointment.treatmentPlan(appointment);
  const practitioner = await OrganisationCache.staff.get.getDoc(
    appointment.practitioner.ref
  );

  const treatmentStep = await snapshot(
    TreatmentStep.fromAppointment$(appointment)
  );

  const items = treatmentStep
    ? treatmentStepToLineItems(
        treatmentStep,
        treatmentPlan,
        stafferToNamedDoc(practitioner),
        taxRate
      )
    : [];

  const claims = await HealthcareClaimGenerator.createClaims(
    items,
    practitioner,
    practice.ref
  );
  return Invoice.init({
    from: toAccountDetails(practice),
    to: primaryContact
      ? {
          ...toAccountDetails(primaryContact),
          onBehalfOf,
        }
      : onBehalfOf,
    practice: toNamedDocument(practice),
    items,
    claims,
    createdAt,
  });
}

export async function createInvoiceForAppointment(
  appointmentRef: DocumentReference<IAppointment>,
  taxRate: TaxRate
): Promise<DocumentReference<IInvoice>> {
  const appointment = await Firestore.getDoc(appointmentRef);
  const patientRef = Appointment.patientRef(appointment);
  const invoice = await buildInvoiceForAppointment(
    appointment,
    patientRef,
    taxRate
  );
  const ref = await addDoc(Patient.invoiceCol({ ref: patientRef }), invoice);

  appointment.invoiceRef = ref;
  await Firestore.saveDoc(appointment);
  return ref;
}
