import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
  TaxRate,
  calculateTaxFromTotal,
  canChangeTaxAmount,
  roundTo2Decimals,
  type TaxStrategy,
} from '@principle-theorem/accounting';
import {
  GlobalStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  InvoiceLineItemType,
  isCustomLineItem,
  isDepositLineItem,
  type ICustomLineItem,
  type IStaffer,
  IDepositLineItem,
  ITreatmentCategory,
} from '@principle-theorem/principle-core/interfaces';
import {
  debounceUserInput,
  snapshot,
  type INamedDocument,
  WithRef,
  filterUndefined,
} from '@principle-theorem/shared';
import { isNil } from 'lodash';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  from,
  noop,
  type Observable,
  of,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

type LineItemFormData = Pick<
  ICustomLineItem,
  'description' | 'quantity' | 'amount' | 'tax'
>;

class LineItemFormGroup extends TypedFormGroup<LineItemFormData> {
  constructor() {
    super({
      description: new TypedFormControl<string>(undefined, [
        Validators.required,
      ]),
      quantity: new TypedFormControl<number>(undefined, [
        Validators.required,
        Validators.min(0),
      ]),
      tax: new TypedFormControl<number>(undefined, [
        Validators.required,
        Validators.min(0),
      ]),
      amount: new TypedFormControl<number>(undefined, [
        Validators.required,
        Validators.min(0),
      ]),
    });
  }
}

@Component({
    selector: 'pr-invoice-line-item-edit',
    templateUrl: './invoice-line-item-edit.component.html',
    styleUrls: ['./invoice-line-item-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InvoiceLineItemEditComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  lineItem$ = new ReplaySubject<ICustomLineItem>(1);
  isDisabled$ = new BehaviorSubject<boolean>(false);
  canEditPrice$: Observable<boolean>;
  canEditQuantity$: Observable<boolean>;
  canEditDescription$: Observable<boolean>;
  formGroup: LineItemFormGroup = new LineItemFormGroup();
  @Output() lineItemChanged = new EventEmitter<ICustomLineItem>();
  @Output() openDepositAssignment = new EventEmitter<IDepositLineItem>();

  @Input()
  set lineItem(lineItem: ICustomLineItem) {
    if (lineItem) {
      this.lineItem$.next(lineItem);
    }
  }

  @Input()
  set disabled(isDisabled: BooleanInput) {
    this.isDisabled$.next(coerceBooleanProperty(isDisabled));
  }

  constructor(
    private _globalStore: GlobalStoreService,
    private _organisation: OrganisationService
  ) {
    this.lineItem$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((lineItem) =>
        this.formGroup.patchValue(lineItem, { emitEvent: false })
      );

    this.canEditPrice$ = this.lineItem$.pipe(
      map((lineItem) => !this._isBasePriceOrServiceCode(lineItem)),
      switchMap((canEditField) => this._andIsEnabled$(canEditField))
    );

    this.canEditQuantity$ = this.lineItem$.pipe(
      map((lineItem) => !this._isBasePriceOrServiceCode(lineItem)),
      switchMap((canEditField) => this._andIsEnabled$(canEditField))
    );
    this.canEditDescription$ = this.canEditQuantity$.pipe(
      switchMap((canEditField) => this._andIsEnabled$(canEditField))
    );

    this.formGroup.valueChanges
      .pipe(
        debounceUserInput(),
        distinctUntilChanged(),
        withLatestFrom(this._organisation.taxRate$.pipe(filterUndefined())),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([change, taxRate]) => {
        const tax = this.maxTaxableAmount(change.amount, taxRate);
        if (isNil(change.tax)) {
          this.formGroup.controls.tax.setValue(0);
        }
        if (change.tax > tax) {
          this.formGroup.controls.tax.setValue(roundTo2Decimals(tax));
        }
        if (isNil(change.amount) || change.amount < 0) {
          this.formGroup.controls.amount.setValue(0);
        }
      });

    this.formGroup.valueChanges
      .pipe(
        debounceUserInput(500),
        distinctUntilChanged(),
        filter(() => this.formGroup.valid),
        tap((value) => from(this._update(value))),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  addQty(qtyToAdd: number): void {
    const item = this.formGroup.value;
    const quantity: number = item.quantity + qtyToAdd;
    this.formGroup.patchValue({
      ...item,
      quantity,
    });
  }

  maxTaxableAmount(amount: number, taxRate: TaxRate): number {
    return calculateTaxFromTotal(amount, taxRate);
  }

  canChangeTaxAmount$(taxStrategy: TaxStrategy): Observable<boolean> {
    return this._andIsEnabled$(canChangeTaxAmount(taxStrategy));
  }

  isDeposit(lineItem: ICustomLineItem): boolean {
    return isDepositLineItem(lineItem);
  }

  resolveProvider(
    lineItem: ICustomLineItem
  ): INamedDocument<IStaffer> | undefined {
    if (isDepositLineItem(lineItem)) {
      return lineItem.attributedTo;
    }
  }

  resolveTreatmentCategory$(
    lineItem: ICustomLineItem
  ): Observable<WithRef<ITreatmentCategory> | undefined> {
    if (!isDepositLineItem(lineItem) || !lineItem.forTreatmentCategoryRef) {
      return of(undefined);
    }
    return this._globalStore.getTreatmentCategory$(
      lineItem.forTreatmentCategoryRef
    );
  }

  triggerDepositAssignment(lineItem: ICustomLineItem): void {
    if (!isCustomLineItem(lineItem) || !isDepositLineItem(lineItem)) {
      return;
    }
    this.openDepositAssignment.emit(lineItem);
  }

  private _isBasePriceOrServiceCode(lineItem: ICustomLineItem): boolean {
    return [
      InvoiceLineItemType.ServiceCode,
      InvoiceLineItemType.TreatmentBasePrice,
    ].includes(lineItem.type);
  }

  private async _update(data: Partial<ICustomLineItem>): Promise<void> {
    const lineItem = await snapshot(this.lineItem$);
    this.lineItemChanged.emit({ ...lineItem, ...data });
  }

  private _andIsEnabled$(canEditField: boolean): Observable<boolean> {
    return this.isDisabled$.pipe(
      map((isDisabled) => !isDisabled && canEditField)
    );
  }
}
