import { type WithRef } from '@principle-theorem/shared';
import { D4WSourceHandler } from '../d4w/source';
import { ExactSourceHandler } from '../exact/source';
import { PraktikaSourceHandler } from '../praktika/source';
import {
  type IPracticeMigration,
  type ISourceEntity,
  type ISourceHandler,
  type ISourceEntityHandler,
} from '@principle-theorem/principle-core/interfaces';
import { DentrixSourceHandler } from '../dentrix/source';
import { CorePracticeSourceHandler } from '../core-practice/source';

export const SOURCE_HANDLERS: ISourceHandler[] = [
  new CorePracticeSourceHandler(),
  new D4WSourceHandler(),
  new PraktikaSourceHandler(),
  new ExactSourceHandler(),
  new DentrixSourceHandler(),
];

export function getSourceHandler(
  migration: WithRef<IPracticeMigration>
): ISourceHandler | undefined {
  return SOURCE_HANDLERS.find((sourceHandler) => {
    if (
      sourceHandler.migrationType !== migration.metadata.name ||
      sourceHandler.migrationVersion !== migration.metadata.version
    ) {
      return false;
    }
    return sourceHandler.canHandle(migration.source);
  });
}

export async function initialiseSourceEntities(
  migration: WithRef<IPracticeMigration>
): Promise<void> {
  const handler = getSourceHandler(migration);
  if (!handler) {
    return;
  }

  await handler.addEntities(migration);
}

export function getSourceEntityHandler(
  migration: WithRef<IPracticeMigration>,
  sourceEntity: ISourceEntity
): ISourceEntityHandler | undefined {
  const sourceHandler = getSourceHandler(migration);
  if (!sourceHandler) {
    return;
  }

  return sourceHandler.entityHandlers.find((entityHandler) =>
    entityHandler.canHandle(sourceEntity)
  );
}

export function getSourceEntityHandlerByKey(
  migration: WithRef<IPracticeMigration>,
  sourceEntityKey: string
): ISourceEntityHandler | undefined {
  const sourceHandler = getSourceHandler(migration);
  if (!sourceHandler) {
    return;
  }

  return sourceHandler.entityHandlers.find(
    (entityHandler) =>
      entityHandler.sourceEntity.metadata.idPrefix === sourceEntityKey
  );
}
