import { DialogPresets, TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  IPatient,
  PatientForm,
  type ISubmittedForm,
  ICustomFormConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  WithRef,
  reduce2DArray,
  snapshot,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { StateBasedNavigationService } from '@principle-theorem/ng-principle-shared';
import { resolveCustomFormData } from './form-data-resolver';
import {
  IMedicalHistoryDialogData,
  MedicalHistoryDialogComponent,
} from './components/medical-history-dialog/medical-history-dialog.component';
import { isEqual } from 'lodash';
import { buildCustomFormConfiguration } from '@principle-theorem/principle-core';

export class MedicalFormAlerts {
  trackByCondition = TrackByFunctions.variable<string>();
  trackByAllergies = TrackByFunctions.variable<string>();
  trackByHistory = TrackByFunctions.variable<string>();
  conditions$: Observable<string[]>;
  allergies$: Observable<string[]>;
  history$: Observable<string[]>;
  all$: Observable<string[]>;
  alertCount$: Observable<number>;
  hasAlerts$: Observable<boolean>;
  hasMedicalHistory$: Observable<boolean>;
  medicalHistoryLink$: Observable<string[]>;
  lastUpdated$: Observable<Timestamp | undefined>;
  isLoading$: Observable<boolean>;

  constructor(
    public patient$: Observable<WithRef<IPatient>>,
    public latestMedicalForm$: Observable<ISubmittedForm | undefined>,
    private _stateNav: StateBasedNavigationService,
    private _dialog: MatDialog
  ) {
    this.isLoading$ = this.latestMedicalForm$.pipe(
      map(() => false),
      startWith(true)
    );
    this.hasMedicalHistory$ = this.latestMedicalForm$.pipe(
      map((form) => !!form)
    );
    this.conditions$ = this.latestMedicalForm$.pipe(
      map((form) =>
        form
          ? resolveCustomFormData(
              form,
              'conditions',
              buildCustomFormConfiguration(form)?.dataResolverConfig
            )
          : []
      )
    );
    this.allergies$ = this.latestMedicalForm$.pipe(
      map((form) =>
        form
          ? resolveCustomFormData(
              form,
              'allergies',
              buildCustomFormConfiguration(form)?.dataResolverConfig
            )
          : []
      )
    );
    this.history$ = this.latestMedicalForm$.pipe(
      map((form) =>
        form
          ? resolveCustomFormData(
              form,
              'history',
              buildCustomFormConfiguration(form)?.dataResolverConfig
            )
          : []
      )
    );
    this.all$ = combineLatest([
      this.conditions$,
      this.allergies$,
      this.history$,
    ]).pipe(reduce2DArray());
    this.alertCount$ = this.all$.pipe(map((alerts) => alerts.length));
    this.hasAlerts$ = this.alertCount$.pipe(
      map((alertCount) => alertCount > 0)
    );

    this.medicalHistoryLink$ = this.patient$.pipe(
      switchMap((patient) => {
        if (!patient) {
          return [];
        }
        return this._stateNav.link.brand$(this._getFormLinkSegments(patient));
      })
    );

    this.lastUpdated$ = this.latestMedicalForm$.pipe(
      map((latestMedicalForm) => latestMedicalForm?.date)
    );
  }

  // TODO: use medicalHistoryLink$ instead
  async openMedicalHistoryForm(): Promise<void> {
    const patient = await snapshot(this.patient$);
    if (!patient) {
      return;
    }
    await this._stateNav.brand(this._getFormLinkSegments(patient));
  }

  async viewMedicalHistory(): Promise<void> {
    const form: ISubmittedForm | undefined = await snapshot(
      this.latestMedicalForm$
    );

    if (!form) {
      return;
    }

    const data: IMedicalHistoryDialogData = {
      form,
      readonly: true,
    };

    this._dialog.open(
      MedicalHistoryDialogComponent,
      DialogPresets.flex({ data })
    );
  }

  private _getFormLinkSegments(patient: WithRef<IPatient>): string[] {
    return [
      'patients',
      patient.ref.id,
      'forms',
      PatientForm.MedicalHistoryForm,
    ];
  }
}

export function mergeSubmittedFormWithConfig(
  patientForm?: ISubmittedForm,
  config?: ICustomFormConfiguration
): ISubmittedForm | undefined {
  if (!patientForm) {
    return;
  }

  const currentForm = buildCustomFormConfiguration(config);

  if (isEqual(patientForm.schema, currentForm?.schema)) {
    return {
      ...patientForm,
      ...currentForm,
      layout: currentForm?.layout ?? patientForm.layout,
      dataResolverConfig:
        currentForm?.dataResolverConfig ?? patientForm.dataResolverConfig,
    };
  }

  return patientForm;
}
