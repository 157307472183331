import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, toFloat, type Timezone } from '@principle-theorem/shared';
import { flow, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  convertValueFn,
} from '../../../source/source-helpers';

export const PATIENT_CLAIM_ITEM_RESOURCE_TYPE = 'patientClaimItems';

export const PATIENT_CLAIM_ITEM_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Claim Items',
      description: '',
      idPrefix: PATIENT_CLAIM_ITEM_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ICorePracticePatientClaimItem {
  id: number;
  fee: number; // 250.0000
  amount: number; // 92.4000
  reference: '00' | '01' | '02' | '03' | '04' | '05' | '06';
  treatmentId: number;
  claimId: number;
}

export function isCorePracticePatientClaimItem(
  item: unknown
): item is ICorePracticePatientClaimItem {
  return TypeGuard.interface<ICorePracticePatientClaimItem>({
    id: isNumber,
    fee: isNumber,
    amount: isNumber,
    reference: isString,
    treatmentId: isNumber,
    claimId: isNumber,
  })(item);
}

export interface ICorePracticePatientClaimItemTranslations {}

export interface ICorePracticePatientClaimItemFilters {
  treatmentId: number;
  claimId: number;
}

const PATIENT_CLAIM_ITEM_SOURCE_QUERY = `
SELECT
  ClaimItemId AS id,
  convert_to_decimal(Fee) AS fee,
  convert_to_decimal(Amount) AS amount,
  convert_to_text(Reference) AS reference,
  TreatmentId AS treatment_id,
  ClaimId AS claim_id
FROM tblClaimItem
`;

export class PatientClaimItemSourceEntity extends BaseSourceEntity<
  ICorePracticePatientClaimItem,
  ICorePracticePatientClaimItemTranslations,
  ICorePracticePatientClaimItemFilters
> {
  sourceEntity = PATIENT_CLAIM_ITEM_SOURCE_ENTITY;
  entityResourceType = PATIENT_CLAIM_ITEM_RESOURCE_TYPE;
  sourceQuery = PATIENT_CLAIM_ITEM_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientClaimItem;

  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertValueFn(toFloat, 'fee', 'amount'),
  ]);

  translate(
    _data: ICorePracticePatientClaimItem,
    _timezone: Timezone
  ): ICorePracticePatientClaimItemTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientClaimItem): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientClaimItem): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientClaimItem,
    _timezone: Timezone
  ): ICorePracticePatientClaimItemFilters {
    return {
      treatmentId: data.treatmentId,
      claimId: data.claimId,
    };
  }
}
