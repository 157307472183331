import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_PERIODONTAL_CHART_RESOURCE_TYPE =
  'patientPeriodontalCharts';

export const PATIENT_PERIODONTAL_CHART_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Periodontal Chart List',
      description: '',
      idPrefix: PATIENT_PERIODONTAL_CHART_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ICorePracticePatientPeriodontalChart {
  id: number;
  chartDate: string; // 2024-01-22 06:22:02.947
  chartName: string; // "Reevaluation"
  status: -1 | 0 | 1; // Need to work out what this is
  patientId: number;
}

export function isCorePracticePatientPeriodontalChart(
  item: unknown
): item is ICorePracticePatientPeriodontalChart {
  return TypeGuard.interface<ICorePracticePatientPeriodontalChart>({
    id: isNumber,
    chartDate: isString,
    chartName: isString,
    status: isNumber,
    patientId: isNumber,
  })(item);
}

export interface ICorePracticePatientPeriodontalChartTranslations {}

export interface ICorePracticePatientPeriodontalChartFilters {
  patientId: number;
}

const PATIENT_PERIODONTAL_CHART_SOURCE_QUERY = `
SELECT
  PerioChartId AS id,
  ChartDate AS chart_date,
  ChartName AS chart_name,
  Status AS status,
  PatientId AS patient_id
FROM tblPerioChart
`;

export class PatientPeriodontalChartSourceEntity extends BaseSourceEntity<
  ICorePracticePatientPeriodontalChart,
  ICorePracticePatientPeriodontalChartTranslations,
  ICorePracticePatientPeriodontalChartFilters
> {
  sourceEntity = PATIENT_PERIODONTAL_CHART_SOURCE_ENTITY;
  entityResourceType = PATIENT_PERIODONTAL_CHART_RESOURCE_TYPE;
  sourceQuery = PATIENT_PERIODONTAL_CHART_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientPeriodontalChart;

  translate(
    _data: ICorePracticePatientPeriodontalChart,
    _timezone: Timezone
  ): ICorePracticePatientPeriodontalChartTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientPeriodontalChart): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientPeriodontalChart): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientPeriodontalChart,
    _timezone: Timezone
  ): ICorePracticePatientPeriodontalChartFilters {
    return {
      patientId: data.patientId,
    };
  }
}
