import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  IPractice,
  IPrintPrescriptionSettings,
} from '@principle-theorem/principle-core/interfaces';
import { Firestore, WithRef, snapshot } from '@principle-theorem/shared';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';

export class PrescriptionPrintSettingsFormGroup extends TypedFormGroup<IPrintPrescriptionSettings> {
  constructor() {
    super({
      horizontalOffset: new TypedFormControl<number | undefined>(undefined),
      verticalOffset: new TypedFormControl<number | undefined>(undefined),
    });
  }
}

@Component({
  selector: 'pr-prescription-print-settings',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  templateUrl: './prescription-print-settings.component.html',
  styleUrl: './prescription-print-settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrescriptionPrintSettingsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  form = new PrescriptionPrintSettingsFormGroup();
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);

  @Input({ required: true })
  set practice(practice: WithRef<IPractice>) {
    if (practice) {
      this.practice$.next(practice);
    }
  }

  constructor() {
    this.practice$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((practice) => {
        const offset = practice.settings.print?.prescriptionMarginOffset;
        if (offset) {
          this.form.patchValue(offset, {
            emitEvent: false,
          });
        }
      });

    this.form.valueChanges
      .pipe(debounceTime(500), takeUntil(this._onDestroy$))
      .subscribe(() => {
        void this.savePrintSettings();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async savePrintSettings(): Promise<void> {
    if (!this.form.valid) {
      return;
    }
    const practice = await snapshot(this.practice$);
    await Firestore.patchDoc(practice.ref, {
      settings: {
        ...practice.settings,
        print: {
          ...practice.settings.print,
          prescriptionMarginOffset: this.form.value,
        },
      },
    });
  }
}
