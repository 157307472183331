import { type IReffable } from '@principle-theorem/shared';
import { type INotification } from './notification';

export interface INotificationContextRenderer<
  RenderOutput,
  NotificationType extends string = string,
  ResourcesType extends object = object
> {
  canRender(
    notificationType: INotification<NotificationType, ResourcesType>
  ): boolean;
  isResource(data: unknown): data is ResourcesType;
  render(
    resources: ResourcesType,
    currentUser: IReffable
  ): RenderOutput | undefined;
}

export interface IBaseEmailContext {
  subject: string;
  preheader: string;
  user: {
    name: string;
    email: string;
  };
}

export interface IEmailNotificationContext extends IBaseEmailContext {
  message: string;
  signOffMessage: string;
  action: {
    label: string;
    url: string;
  };
}
