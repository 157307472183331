import { Firestore, toMomentTz, WithRef } from '@principle-theorem/shared';
import {
  IBrand,
  ICandidateCalendarEvent,
  IOrganisation,
  IPatientConfirmGapOfferTokenData,
} from '@principle-theorem/principle-core/interfaces';
import { createCSRF } from '@principle-theorem/temporary-tokens';
import { TimezoneResolver } from '../../timezone';
import { Organisation } from '../organisation/organisation';

export async function generateGapOfferToken(
  org: WithRef<IOrganisation>,
  gapOffer: WithRef<ICandidateCalendarEvent>
): Promise<string> {
  const tokenData: IPatientConfirmGapOfferTokenData = {
    gapOffer: gapOffer.ref,
  };
  const timezone = await TimezoneResolver.fromEvent(gapOffer.event);

  return createCSRF(
    Organisation.temporaryTokenCol(org),
    tokenData,
    toMomentTz(gapOffer.event.from, timezone).add(7, 'day'),
    true
  );
}

export async function generateGapOfferUrl(
  gapOffer: WithRef<ICandidateCalendarEvent>,
  appUrl: string
): Promise<string> {
  const brandRef = Firestore.getParentDocRef<IBrand>(gapOffer.ref.path);
  const orgRef = Firestore.getParentDocRef<IOrganisation>(brandRef.path);
  const token = await generateGapOfferToken(
    await Firestore.getDoc(orgRef),
    gapOffer
  );

  return [appUrl, 'reschedule', token].join('/');
}
