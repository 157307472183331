<div class="flex flex-col gap-2 p-4">
  <h3 class="!m-0 !font-bold">Tracking Events</h3>

  <p class="!m-0">
    Principle's online booking workflow consists of two pages. When a user lands
    on one of these pages, we trigger PageView events.
  </p>

  <table class="table-auto border-2 border-solid border-slate-100">
    <tr class="odd:bg-slate-50 even:bg-slate-100">
      <td class="min-w-[150px] p-2">Online Booking Form</td>
      <td class="p-2">
        <code class="font-mono font-bold text-slate-500">{{
          bookingPageUrl
        }}</code>
      </td>
    </tr>
    <tr class="odd:bg-slate-50 even:bg-slate-100">
      <td class="min-w-[150px] p-2">Online Booking Submitted</td>
      <td class="p-2">
        <code class="font-mono font-bold text-slate-500"
          >{{ bookingPageUrl }}/submitted</code
        >
      </td>
    </tr>
  </table>

  <p class="!m-0">
    Principle will also track an event for a successful submission with
    additional properties:
  </p>

  <table class="table-auto border-2 border-solid border-slate-100">
    <tr class="odd:bg-slate-50 even:bg-slate-100">
      <td class="p-2">
        <code class="font-mono font-bold text-slate-500">event_uid</code>
      </td>
      <td class="p-2">A unique identifier for the event</td>
    </tr>
    <tr class="odd:bg-slate-50 even:bg-slate-100">
      <td class="p-2">
        <code class="font-mono font-bold text-slate-500">event_date</code>
      </td>
      <td class="p-2">
        The date this event was triggered. In the following format
        <code class="font-mono font-bold text-slate-500"
          >YYYY-MM-DD hh:mm:ss</code
        >
      </td>
    </tr>
    <tr class="odd:bg-slate-50 even:bg-slate-100">
      <td class="p-2">
        <code class="font-mono font-bold text-slate-500">appointment_date</code>
      </td>
      <td class="p-2">
        The date the requested date of the appointment. In the following format
        <code class="font-mono font-bold text-slate-500"
          >YYYY-MM-DD hh:mm:ss</code
        >
      </td>
    </tr>
    <tr class="odd:bg-slate-50 even:bg-slate-100">
      <td class="p-2">
        <code class="font-mono font-bold text-slate-500"
          >days_from_appointment</code
        >
      </td>
      <td class="p-2">
        The number of days before the requested appointment the booking was made
      </td>
    </tr>
    <tr class="odd:bg-slate-50 even:bg-slate-100">
      <td class="p-2">
        <code class="font-mono font-bold text-slate-500"
          >practitioner_name</code
        >
      </td>
      <td class="p-2">Name of the practitioner requested</td>
    </tr>
    <tr class="odd:bg-slate-50 even:bg-slate-100">
      <td class="p-2">
        <code class="font-mono font-bold text-slate-500">treatment_name</code>
      </td>
      <td class="p-2">Name of the treatment requested</td>
    </tr>
  </table>

  <h3 class="!m-0 !font-bold">UTM Parameters</h3>

  <p class="!m-0">
    <a href="https://en.wikipedia.org/wiki/UTM_parameters" target="_blank"
      >UTM parameters</a
    >
    for tracking campaigns and sources should be added to your online booking
    link. These will be added to page view events and the properties of our
    events. Any UTM properties, including the online booking form page, will be
    present when the user is forwarded to the booking submitted page.
  </p>

  <table class="table-auto border-2 border-solid border-slate-100">
    <tr class="odd:bg-slate-50 even:bg-slate-100">
      <td class="min-w-[150px] p-2">Example UTM Parameters</td>
      <td class="p-2">
        <code class="font-mono font-bold text-slate-500"
          >utm_source=google&utm_campaign=spring_sale</code
        >
      </td>
    </tr>
    <tr class="odd:bg-slate-50 even:bg-slate-100">
      <td class="min-w-[150px] p-2">Example Booking URL</td>
      <td class="p-2">
        <code class="font-mono font-bold text-slate-500"
          >{{ bookingPageUrl }}?utm_source=google&utm_campaign=spring_sale</code
        >
      </td>
    </tr>
  </table>
</div>
