import { CanBeChartedProperty, CanDoAllProperty } from '../measure-properties';
import { BaseDimensionMeasures } from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import { MeasurePropertyFactory } from '../measure-property-factory';

// export class LabDimension<Fact extends IHasLab> extends BaseDimension<Fact>
//   implements DimensionTransformMap<Fact, ComparableProperties<Reffable<ILab>>> {

//   get name(): Dimension<Fact, string> {
//     return this.ndx.dimension((facts: Fact): string => {
//       return facts.lab.name;
//     });
//   }

//   get email(): Dimension<Fact, string> {
//     return this.ndx.dimension((facts: Fact): string => {
//       return facts.lab.email;
//     });
//   }

//   get phone(): Dimension<Fact, string> {
//     return this.ndx.dimension((facts: Fact): string => {
//       return facts.lab.phone;
//     });
//   }

//   get address(): Dimension<Fact, string> {
//     return this.ndx.dimension((facts: Fact): string => {
//       return facts.lab.address;
//     });
//   }
// }

export class LabDimensionMeasures extends BaseDimensionMeasures {
  get ref(): CanBeChartedProperty {
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('ref'),
        label: 'Lab Ref',
        summary: '',
      },
      docRef,
      this.buildQuery().attributes([docRef.attributePath]).get()
    );
  }

  get name(): CanDoAllProperty {
    const name = this.measureRef('name');
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('name'),
        label: 'Lab Name',
        summary: '',
      },
      docRef,
      this.buildQuery()
        .attributes([docRef.attributePath, name.attributePath])
        .get(),
      name
    );
  }
}
