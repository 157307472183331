<mat-toolbar color="accent">Transaction Amendment History</mat-toolbar>

<mat-dialog-content fxLayout="column">
  <pr-transaction-amendment-history
    fxFill
    [amendmentHistory]="amendmentHistory$ | async"
    [current]="transaction"
  />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
