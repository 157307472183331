import { ManualTransactionType } from '@principle-theorem/principle-core';
import {
  CustomMappingAssociatedValueType,
  CustomMappingOption,
  CustomMappingType,
  ICustomMapping,
  ICustomMappingSourceOption,
  IManualTransactionType,
  IPracticeMigration,
  TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import {
  IBlobFilenamePair,
  INamedDocument,
  IReffable,
  WithRef,
  XSLXImporterExporter,
  asyncForEach,
  getEnumValues,
  hardDeleteDoc,
  multiMap,
  multiSwitchMap,
  snapshot,
  toNamedDocument,
} from '@principle-theorem/shared';
import { sortBy, upperFirst } from 'lodash';
import { Observable, from, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import { PaymentTypeSourceEntity } from '../../source/entities/payment-types';
import {
  IBasePaymentTypeOption,
  PaymentTypeToXSLX,
} from './payment-type-to-xlsx';
import { XSLXToPaymentType } from './xlsx-to-payment-type';

export const PAYMENT_TYPE_CUSTOM_MAPPING_TYPE = 'paymentTypeMapping';

export const PAYMENT_TYPE_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Payment Type Mapping',
    description: `Used to map Core Practice Payment Types to Principle Transaction Types. Any unmapped payment types will be assigned to a Manual Transaction type.`,
    type: PAYMENT_TYPE_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.SelectionList,
});

export class CorePracticePaymentTypeMappingHandler extends BaseCustomMappingHandler<
  IManualTransactionType,
  TransactionProvider,
  INamedDocument<IManualTransactionType>
> {
  customMapping = PAYMENT_TYPE_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const paymentTypes = new PaymentTypeSourceEntity();
    const records = await paymentTypes.getRecords$(migration, 1000).toPromise();
    return sortBy(
      records
        .map((record) => record.data.data)
        .map((record) => ({
          label: record.name,
          value: record.id.toString(),
        })),
      'label'
    );
  }

  async getSelectionListOptions(
    _migration: WithRef<IPracticeMigration>
  ): Promise<CustomMappingOption[]> {
    return of([
      {
        value: '',
        description: '',
        hasAssociatedValue: false,
        associatedValueType: CustomMappingAssociatedValueType.String,
        associatedValueDescription: '',
      },
      ...getEnumValues(TransactionProvider).map((provider) => ({
        value: provider,
        description: upperFirst(provider),
        hasAssociatedValue:
          provider === TransactionProvider.Manual ? true : false,
        associatedValueType: CustomMappingAssociatedValueType.NamedDocument,
        associatedValueDescription: 'Select an Manual Transaction Subtype',
      })),
    ]).toPromise();
  }

  getAssociatedValueOptions$(
    migration: IPracticeMigration,
    destinationValue: TransactionProvider
  ): Observable<INamedDocument<IManualTransactionType>[]> {
    if (destinationValue !== TransactionProvider.Manual) {
      return of([]);
    }

    return ManualTransactionType.all$(migration.configuration.brand).pipe(
      multiMap(toNamedDocument)
    );
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, paymentTypes, translators } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(
      fileName,
      paymentTypes,
      translators
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, paymentTypes, translators } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(
      fileName,
      paymentTypes,
      translators
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToPaymentType()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const records = await this.getRecords(translationMap);
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));

    const sourceOptions = await this.getSourceOptions(migration);
    const paymentTypes = await this._getPaymentOptions(migration);

    await asyncForEach(items, async (item) => {
      const mapTo = item.mapTo;
      const id = item.sourceId;
      const label = item.sourceName;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.value === id
      )?.value;
      if (!value || !item.mapTo) {
        return;
      }

      const paymentType = paymentTypes.find(
        (configuration) => configuration.label === mapTo
      );

      if (!paymentType) {
        // eslint-disable-next-line no-console
        console.error(
          `Mapping error: ${this.customMapping.metadata.label} - Couldn't find payment type for item`,
          item
        );
        return;
      }

      await this.upsertRecord(
        {
          destinationValue: paymentType.destinationValue,
          associatedValue: paymentType.associatedValue,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    paymentTypes: ICustomMappingSourceOption[];
    translators: PaymentTypeToXSLX;
  }> {
    const fileName = this.getFileName();
    const paymentTypes = await this.getSourceOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const translators = new PaymentTypeToXSLX(
      await this._getPaymentOptions(migration),
      await this.getRecords(translationMap)
    );
    return { fileName, paymentTypes, translators };
  }

  private async _getPaymentOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBasePaymentTypeOption[]> {
    return snapshot(
      from(this.getSelectionListOptions(migration)).pipe(
        multiSwitchMap((option) => {
          if (!option.hasAssociatedValue) {
            return of([
              {
                destinationValue: option.value as TransactionProvider,
                label: option.description,
              },
            ]);
          }

          return this.getAssociatedValueOptions$(
            migration,
            option.value as TransactionProvider
          ).pipe(
            map((associatedValues) => {
              return associatedValues.map((associatedValue) => ({
                destinationValue: option.value as TransactionProvider,
                associatedValue,
                label: `${option.description} - ${associatedValue.name}`,
              }));
            })
          );
        }),
        map((options) => options.flat())
      )
    );
  }
}
