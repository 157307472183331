import {
  getTaxRateByRegion,
  roundTo2Decimals,
  TaxStrategy,
} from '@principle-theorem/accounting';
import { initVersionedSchema } from '@principle-theorem/editor';
import {
  ADA_CODE_TREATMENT_CONFIG_ID,
  Brand,
  CalculateTreatmentWeight,
  ChartedItemScopeResolver,
  ChartedSurface,
  ChartedTreatment,
  ClinicalNote,
  determineTaxFromStrategy,
  FeeSchedule,
  getQuadrant,
  getQuadrantIndex,
  PricedServiceCodeEntry,
  stafferToNamedDoc,
  TreatmentConfiguration,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  ChartableSurface,
  IDestinationEntityJobRunOptions,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  type FailedDestinationEntityRecord,
  type IChartedRef,
  type IChartedTreatment,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IFeeSchedule,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type IStaffer,
  type IToothRef,
  type ITreatmentConfiguration,
  type ToothNumber,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  Firestore,
  getDoc$,
  getError,
  HISTORY_DATE_FORMAT,
  isINamedDocument,
  ISO_DATE_FORMAT,
  ISO_DATE_TIME_FORMAT,
  isSameRef,
  multiSwitchMap,
  snapshotCombineLatest,
  Timezone,
  toISODate,
  toMoment,
  toMomentTz,
  toNamedDocument,
  toTimestamp,
  type INamedDocument,
  type ISODateType,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first, groupBy, sortBy, uniqBy } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import {
  BasePatientTreatmentPlanDestinationEntity,
  IPatientTreatmentPlanJobData,
  IPatientTreatmentPlanMigrationData,
  ITreatmentPlanStepPair,
  PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY,
} from '../../../destination/entities/patient-treatment-plans';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { resolveFeeSchedule } from '../../../mappings/fee-schedules';
import { ItemCodeResourceMapType } from '../../../mappings/item-codes-to-xlsx';
import { PracticeMigration } from '../../../practice-migrations';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientAppointmentProcedureSourceEntity,
  type IPraktikaAppointmentProcedure,
  type IPraktikaAppointmentProcedureFilters,
  type IPraktikaAppointmentProcedureTranslations,
} from '../../source/entities/appointment-procedure';
import {
  PatientSourceEntity,
  type IPraktikaPatient,
  type IPraktikaPatientFilters,
  type IPraktikaPatientTranslations,
} from '../../source/entities/patient';
import {
  PatientAppointmentSourceEntity,
  PraktikaAppointmentStatus,
  PraktikaInAppointmentStatus,
  type IPraktikaAppointment,
  type IPraktikaAppointmentTranslations,
} from '../../source/entities/patient-appointment';
import {
  TOOTH_SURFACES_MAP,
  type PraktikaToothSurfaces,
} from '../../source/entities/patient-tooth-conditions';
import {
  PraktikaItemCodeMappingHandler,
  resolveMappedCode,
} from '../mappings/item-code';
import {
  PraktikaItemCodeToTreatmentMappingHandler,
  resolveMappedTreatmentConfiguration,
} from '../mappings/item-code-to-treatment';
import { PraktikaPracticeMappingHandler } from '../mappings/practices';
import { PraktikaStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';

const PRAKTIKA_DEFAULT_PLAN_NAME = 'Praktika - Migrated Treatments';

const patientTreatmentPlanDestinationEntity =
  DestinationEntity.withMetadataDescription(
    PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY,
    `This migrates the appointment procedures for each patient's appointment and creates a single step on a single plan for each appointment.`
  );

type JobData = IPatientTreatmentPlanJobData<
  IPraktikaPatient,
  IPraktikaPatientTranslations,
  IPraktikaPatientFilters
>;

export class PatientTreatmentPlanDestinationEntity extends BasePatientTreatmentPlanDestinationEntity<
  IPraktikaPatient,
  JobData
> {
  override destinationEntity = patientTreatmentPlanDestinationEntity;
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
    appointmentProcedures: new PatientAppointmentProcedureSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new PraktikaStafferMappingHandler(),
    practice: new PraktikaPracticeMappingHandler(),
    itemCodes: new PraktikaItemCodeMappingHandler(),
    itemCodeToTreatment: new PraktikaItemCodeToTreatmentMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<JobData[]> {
    const brand$ = PracticeMigration.brand$(migration);
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);
    const treatmentConfigurationMappings$ =
      this.customMappings.itemCodeToTreatment.getRecords$(translationMap);
    const defaultTreatmentConfiguration$ = brand$.pipe(
      switchMap((brand) =>
        getDoc$(TreatmentConfiguration.col(brand), ADA_CODE_TREATMENT_CONFIG_ID)
      )
    );
    const treatmentConfigurations$ = brand$.pipe(
      switchMap((brand) => TreatmentConfiguration.all$(brand))
    );
    const practitioners$ = staff$.pipe(
      multiSwitchMap((staffer) =>
        staffer.destinationIdentifier
          ? Firestore.getDoc(staffer.destinationIdentifier)
          : of(undefined)
      ),
      map(compact)
    );
    const treatmentCategories$ = brand$.pipe(
      switchMap((brand) => Brand.treatmentCategories$(brand))
    );
    const taxRate$ = PracticeMigration.organisation$(migration).pipe(
      map((org) => getTaxRateByRegion(org.region))
    );

    const requiredData$ = snapshotCombineLatest([
      staff$,
      practitioners$,
      defaultTreatmentConfiguration$,
      treatmentConfigurations$,
      sourceItemCodes$,
      treatmentConfigurationMappings$,
      treatmentCategories$,
      taxRate$,
    ]);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      requiredData$,
    ]).pipe(
      map(
        ([
          sourcePatients,
          [
            staff,
            practitioners,
            defaultTreatmentConfiguration,
            treatmentConfigurations,
            sourceItemCodes,
            treatmentConfigurationMappings,
            treatmentCategories,
            taxRate,
          ],
        ]) =>
          sourcePatients.map((sourcePatient) => ({
            sourcePatient,
            staff,
            practitioners,
            defaultTreatmentConfiguration,
            treatmentConfigurations,
            sourceItemCodes,
            treatmentConfigurationMappings,
            treatmentCategories,
            taxRate,
          }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: JobData
  ): Promise<
    | IPatientTreatmentPlanMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients
      .getSourceRecordId(data.sourcePatient.data.data)
      .toString();

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(data.sourcePatient, 'No patient');
    }

    const procedures =
      await this.sourceEntities.appointmentProcedures.filterRecords(
        migration,
        'patientId',
        data.sourcePatient.data.data.patient_id.toString()
      );

    const appointments = await this.sourceEntities.appointments.filterRecords(
      migration,
      'patientId',
      data.sourcePatient.data.data.patient_id.toString()
    );

    try {
      const { plan, steps } = await this._buildTreatmentPlanData(
        translationMap,
        data,
        procedures,
        appointments,
        migration,
        sourcePatientId
      );

      return {
        sourcePatientId,
        patientRef,
        planPairs: [
          {
            plan,
            steps: sortBy(steps, (step) => toMoment(step.createdAt).format()),
          },
        ],
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  private async _buildTreatmentPlanData(
    translationMap: TranslationMapHandler,
    data: JobData,
    procedures: IGetRecordResponse<
      IPraktikaAppointmentProcedure,
      IPraktikaAppointmentProcedureTranslations,
      IPraktikaAppointmentProcedureFilters
    >[],
    appointments: IGetRecordResponse<
      IPraktikaAppointment,
      IPraktikaAppointmentTranslations
    >[],
    migration: WithRef<IPracticeMigration>,
    sourcePatientId: string
  ): Promise<ITreatmentPlanStepPair> {
    const appointmentProcedures = procedures.filter(
      (procedure) => procedure.record.filters.isScheduled
    );

    const unscheduledStepProcedures = groupBy(
      procedures.filter(
        (procedure) => procedure.record.filters.isUnscheduledAcceptedTreatment
      ),
      (procedure) => procedure.data.data.visit_number
    );

    const defaultFeeSchedule = await FeeSchedule.getPreferredOrDefault(
      migration.configuration.organisation
    );

    const scheduledSteps = await asyncForEach(
      appointments,
      async (appointment) => {
        const stepName = appointment.data.translations.from
          ? toMomentTz(
              appointment.data.translations.from,
              migration.configuration.timezone
            ).format(HISTORY_DATE_FORMAT)
          : '';

        const practitionerMap = data.staff.find((staffer) => {
          if (!staffer.sourceIdentifier) {
            return false;
          }
          const comparisonStaffer =
            appointment.data.data.appointment_providerid?.toString()
              ? appointment.data.data.appointment_providerid.toString()
              : data.sourcePatient.data.data.patient_preferredproviderid?.toString();
          return staffer.sourceIdentifier === comparisonStaffer;
        });

        if (!practitionerMap?.destinationIdentifier) {
          throw new Error(
            `No practitioner with id ${
              appointment.data.data.appointment_providerid?.toString() ?? ''
            }`
          );
        }

        const practitioner = data.practitioners.find((practitionerSearch) =>
          isSameRef(practitionerSearch, practitionerMap.destinationIdentifier)
        );

        if (!practitioner) {
          throw new Error(
            `Can't find practitioner for ref ${practitionerMap.destinationIdentifier.id}`
          );
        }

        const filteredProcedures = appointmentProcedures
          .filter(
            (procedure) =>
              procedure.data.data.appointment_id ===
              appointment.data.data.appointment_id
          )
          .map((procedure) => procedure.data.data);

        const treatments = await getChartedTreatments(
          uniqBy(filteredProcedures, (procedure) => procedure.id),
          practitioner,
          resolveFeeSchedule(translationMap, defaultFeeSchedule),
          data,
          migration.configuration.timezone
        );

        const step = TreatmentStep.init({
          name: stepName,
          status: getTreatmentStepStatus(
            appointment.data.data,
            appointment.data.translations,
            migration.configuration.backupDate,
            migration.configuration.timezone
          ),
          treatments,
          schedulingRules: {
            duration: appointment.data.data.appointment_duration * 15,
          },
        });

        const treatmentCategory =
          await CalculateTreatmentWeight.getPrimaryCategory(
            step,
            data.treatmentCategories
          );

        const sourceIdentifier = getStepIdentifier(
          sourcePatientId,
          appointment,
          []
        );

        return {
          createdAt: appointment.data.translations.from ?? toTimestamp(),
          sourceIdentifier,
          ...step,
          display: {
            ...step.display,
            primaryTreatmentCategory: treatmentCategory?.ref,
          },
        };
      }
    );

    const unscheduledSteps = await asyncForEach(
      Object.values(unscheduledStepProcedures),
      async (stepProcedures) => {
        const stepName = `Visit ${
          first(stepProcedures)?.data.data.visit_number ?? ''
        }`;
        const createdAt =
          first(stepProcedures)?.data.translations.createdDate ?? toTimestamp();
        const providerId = first(stepProcedures)?.data.data.provider_id;

        const practitionerMap = data.staff.find((staffer) => {
          if (!staffer.sourceIdentifier) {
            return false;
          }
          const comparisonStaffer = providerId?.toString()
            ? providerId.toString()
            : data.sourcePatient.data.data.patient_preferredproviderid?.toString();
          return staffer.sourceIdentifier === comparisonStaffer;
        });

        if (!practitionerMap?.destinationIdentifier) {
          throw new Error(
            `No practitioner with id ${providerId?.toString() ?? ''}`
          );
        }

        const practitioner = data.practitioners.find((practitionerSearch) =>
          isSameRef(practitionerSearch, practitionerMap.destinationIdentifier)
        );

        if (!practitioner) {
          throw new Error(
            `Can't find practitioner for ref ${practitionerMap.destinationIdentifier.id}`
          );
        }

        const treatments = await getChartedTreatments(
          stepProcedures.map((procedure) => procedure.data.data),
          practitioner,
          resolveFeeSchedule(translationMap, defaultFeeSchedule),
          data,
          migration.configuration.timezone
        );

        if (!treatments.length) {
          return;
        }

        const step = TreatmentStep.init({
          name: stepName,
          status: TreatmentStepStatus.Incomplete,
          treatments,
        });

        const treatmentCategory =
          await CalculateTreatmentWeight.getPrimaryCategory(
            step,
            data.treatmentCategories
          );

        const sourceIdentifier = getStepIdentifier(
          sourcePatientId,
          undefined,
          stepProcedures
        );

        return {
          createdAt,
          sourceIdentifier,
          ...step,
          display: {
            ...step.display,
            primaryTreatmentCategory: treatmentCategory?.ref,
          },
        };
      }
    );

    const steps = [...scheduledSteps, ...compact(unscheduledSteps)];

    const status = getPlanStatus(scheduledSteps.map((step) => step.status));

    const plan = {
      sourceIdentifier: sourcePatientId,
      ...TreatmentPlan.init({
        name: PRAKTIKA_DEFAULT_PLAN_NAME,
        status,
      }),
    };

    return {
      plan,
      steps,
    };
  }
}

export function getPlanStatus(
  stepStatuses: TreatmentStepStatus[]
): TreatmentPlanStatus {
  if (!stepStatuses.length) {
    return TreatmentPlanStatus.Draft;
  }

  return stepStatuses.filter(
    (stepStatus) => stepStatus === TreatmentStepStatus.Complete
  ).length
    ? TreatmentPlanStatus.InProgress
    : TreatmentPlanStatus.Completed;
}

export async function getChartedTreatments(
  procedures: IPraktikaAppointmentProcedure[],
  practitioner: WithRef<IStaffer>,
  getPreferredOrDefaultRef: (
    feeSheduleId?: string
  ) => Promise<INamedDocument<IFeeSchedule>>,
  data: Pick<
    JobData,
    | 'defaultTreatmentConfiguration'
    | 'sourceItemCodes'
    | 'treatmentConfigurationMappings'
    | 'treatmentConfigurations'
    | 'taxRate'
  >,
  timezone: Timezone
): Promise<IChartedTreatment[]> {
  const treatments: IChartedTreatment[] = [];

  await asyncForEach(procedures, async (procedure) => {
    const code = resolveMappedCode(data.sourceItemCodes, procedure);

    if (!code) {
      throw new Error(
        `Code ${procedure.code || procedure.ada_code} couldn't be resolved`
      );
    }

    if (code === ItemCodeResourceMapType.Omit) {
      return;
    }

    const feeSchedule = await getPreferredOrDefaultRef(
      procedure.fee_schedule_id?.toString()
    );

    const taxStatus = procedure.has_gst
      ? TaxStrategy.GSTApplicable
      : TaxStrategy.GSTFree;
    const price = roundTo2Decimals(parseFloat(procedure.total_fee));

    const chartedSurfaces = getChartedRefs({
      ...procedure,
      toothNumber: procedure.tooth_number,
    }).map((chartedRef) =>
      ChartedSurface.init({
        chartedBy: stafferToNamedDoc(practitioner),
        chartedAt: getChartedAt(procedure, timezone),
        resolvedBy: getResolvedBy(procedure, practitioner),
        resolvedAt: getResolvedAt(procedure, timezone),
        chartedRef,
      })
    );

    const scopeResolver = new ChartedItemScopeResolver();

    const notes = procedure.note
      ? [
          ClinicalNote.init({
            owner: stafferToNamedDoc(practitioner),
            content: initVersionedSchema(procedure.note),
            createdAt: getChartedAt(procedure, timezone),
            recordDate: getRecordedAt(procedure, timezone),
          }),
        ]
      : [];

    if (isINamedDocument<ITreatmentConfiguration>(code)) {
      const treatmentConfiguration = data.treatmentConfigurations.find(
        (config) => isSameRef(config, code)
      );

      if (!treatmentConfiguration) {
        throw new Error(`Treatment ${code.name} not found in treatments list`);
      }

      const scopeRefs = scopeResolver.reduceChartedSurfacesToScope(
        treatmentConfiguration,
        chartedSurfaces
      );
      const scopeRef = first(scopeRefs)?.scopeRef ?? {
        scope: ChartableSurface.WholeMouth,
      };

      treatments.push(
        ChartedTreatment.init({
          basePrice: price,
          config: code,
          feeSchedule,
          serviceCodes: [],
          chartedSurfaces,
          notes,
          scopeRef,
          resolvedBy: stafferToNamedDoc(practitioner),
          resolvedAt: getResolvedAt(procedure, timezone),
        })
      );
      return;
    }

    // TODO: Need to attribute the correct provider to the treatment note
    // const owner = procedure.providerId

    const serviceCode = PricedServiceCodeEntry.init({
      code: code.code,
      type: code.type,
      taxStatus,
      price,
      tax: determineTaxFromStrategy(data.taxRate, {
        taxStatus: taxStatus,
        amount: price,
      }),
      chartedSurfaces,
    });

    const itemCodeId = procedure.code.toString();
    const treatmentConfiguration = resolveMappedTreatmentConfiguration(
      itemCodeId,
      data.treatmentConfigurationMappings,
      data.defaultTreatmentConfiguration,
      data.treatmentConfigurations
    );

    const scopeRefs = scopeResolver.reduceChartedSurfacesToScope(
      treatmentConfiguration,
      chartedSurfaces
    );
    const scopeRef = first(scopeRefs)?.scopeRef ?? {
      scope: ChartableSurface.WholeMouth,
    };

    treatments.push(
      ChartedTreatment.init({
        config: toNamedDocument(treatmentConfiguration),
        feeSchedule,
        serviceCodes: [serviceCode],
        chartedSurfaces,
        notes,
        scopeRef,
        resolvedBy: stafferToNamedDoc(practitioner),
        resolvedAt: getResolvedAt(procedure, timezone),
      })
    );
  });

  return treatments;
}

export function getChartedRefs(procedure: {
  toothNumber: ToothNumber | null;
  surfaces?: PraktikaToothSurfaces | null;
}): Partial<IChartedRef>[] {
  const quadrant = procedure.toothNumber
    ? getQuadrant(procedure.toothNumber)
    : undefined;
  const quadrantIndex = procedure.toothNumber
    ? getQuadrantIndex(procedure.toothNumber)
    : undefined;
  if (!procedure.toothNumber || !quadrant || !quadrantIndex) {
    return [
      {
        unscoped: true,
      },
    ];
  }

  const toothRef: IToothRef = {
    quadrant,
    quadrantIndex,
  };

  if (!procedure.surfaces) {
    return [
      {
        tooth: toothRef,
      },
    ];
  }

  return procedure.surfaces.split('').map((individualSurface) => {
    const surface =
      TOOTH_SURFACES_MAP[individualSurface as PraktikaToothSurfaces];

    return {
      tooth: {
        quadrant,
        quadrantIndex,
        surface,
      },
    };
  });
}

export function getChartedAt(
  procedure: IPraktikaAppointmentProcedure,
  timezone: Timezone
): Timestamp | undefined {
  return procedure.accepted_date
    ? toTimestamp(
        moment
          .tz(procedure.accepted_date, ISO_DATE_FORMAT, timezone)
          .startOf('day')
      )
    : undefined;
}

export function getRecordedAt(
  procedure: IPraktikaAppointmentProcedure,
  timezone: Timezone
): ISODateType {
  return procedure.accepted_date
    ? toISODate(moment.tz(procedure.accepted_date, ISO_DATE_FORMAT, timezone))
    : toISODate(
        moment.tz(procedure.created_date, ISO_DATE_TIME_FORMAT, timezone)
      );
}

export function getResolvedAt(
  procedure: IPraktikaAppointmentProcedure,
  timezone: Timezone
): Timestamp | undefined {
  return procedure.completed_date
    ? toTimestamp(
        moment
          .tz(procedure.completed_date, ISO_DATE_FORMAT, timezone)
          .startOf('day')
      )
    : undefined;
}

export function getResolvedBy(
  procedure: IPraktikaAppointmentProcedure,
  practitioner: WithRef<IStaffer>
): INamedDocument<IStaffer> | undefined {
  return procedure.completed_date ? stafferToNamedDoc(practitioner) : undefined;
}

export function getTreatmentStepStatus(
  appointment: IPraktikaAppointment,
  translations: IPraktikaAppointmentTranslations,
  backupDate: ISODateType,
  timezone: Timezone
): TreatmentStepStatus {
  const appointmentStatus = getAppointmentStatus(
    appointment,
    translations,
    backupDate,
    timezone
  );
  switch (appointmentStatus) {
    case AppointmentStatus.Arrived:
    case AppointmentStatus.CheckedIn:
    case AppointmentStatus.InProgress:
    case AppointmentStatus.CheckingOut:
      return TreatmentStepStatus.Current;
    case AppointmentStatus.Complete:
      return TreatmentStepStatus.Complete;
    default:
      return TreatmentStepStatus.Incomplete;
  }
}

export function getAppointmentStatus(
  appointment: IPraktikaAppointment,
  translations: IPraktikaAppointmentTranslations,
  backupDate: ISODateType,
  timezone: Timezone
): AppointmentStatus {
  const isAfterBackup =
    translations.from &&
    toMomentTz(translations.from, timezone).isAfter(
      toMomentTz(backupDate, timezone).endOf('day')
    );

  const isFta =
    appointment.appointment_arrivalstatusid === PraktikaInAppointmentStatus.Fta;
  if (isFta) {
    return AppointmentStatus.Cancelled;
  }

  if (isAfterBackup) {
    const isArrivedStatus =
      appointment.appointment_arrivalstatusid ===
        PraktikaInAppointmentStatus.Arrived &&
      [
        PraktikaAppointmentStatus.Scheduled,
        PraktikaAppointmentStatus.Modified,
      ].includes(appointment.appointment_statusid);

    if (isArrivedStatus) {
      return AppointmentStatus.Arrived;
    }

    const inProgressStatus =
      appointment.appointment_arrivalstatusid ===
        PraktikaInAppointmentStatus.AtSurgery &&
      [
        PraktikaAppointmentStatus.Scheduled,
        PraktikaAppointmentStatus.Modified,
      ].includes(appointment.appointment_statusid);

    if (inProgressStatus) {
      return AppointmentStatus.InProgress;
    }

    return AppointmentStatus.Scheduled;
  }

  const isCompleteStatus = [
    PraktikaAppointmentStatus.Completed,
    PraktikaAppointmentStatus.PaidInFull,
  ].includes(appointment.appointment_statusid);

  if (isCompleteStatus) {
    return AppointmentStatus.Complete;
  }

  return AppointmentStatus.Cancelled;
}

export function getStepIdentifier(
  sourcePatientId: string,
  appointment?: IGetRecordResponse<
    IPraktikaAppointment,
    IPraktikaAppointmentTranslations
  >,
  treatments: IGetRecordResponse<
    IPraktikaAppointmentProcedure,
    IPraktikaAppointmentProcedureTranslations,
    IPraktikaAppointmentProcedureFilters
  >[] = []
): string {
  if (appointment) {
    return `${sourcePatientId}-${appointment.data.data.appointment_id}`;
  }

  return `${sourcePatientId}-${
    first(treatments)?.data.data.visit_number ?? ''
  }`;
}
