import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { FileIconsModule } from 'ngx-file-icons';
import { EditMediaDialogComponent } from './edit-media-dialog/edit-media-dialog.component';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { MediaInfoComponent } from './media-info/media-info.component';
import { MediaManagerComponent } from './media-manager/media-manager.component';
import { MediaSelectorGridComponent } from './media-selector-grid/media-selector-grid.component';
import { MediaTileComponent } from './media-tile/media-tile.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgPrincipleBridgeCloudModule } from '@principle-theorem/ng-principle-bridge-cloud';
import { AngularPinturaModule } from '@pqina/angular-pintura';
import { MediaListItemComponent } from './media-list-item/media-list-item.component';
import { MediaSettingsComponent } from './media-settings/media-settings.component';
import { DicomViewerComponent } from './dicom-viewer/dicom-viewer.component';
import { MediaActionButtonsComponent } from './media-action-buttons/media-action-buttons.component';
import { MediaTagsDialogComponent } from './media-tags-dialog/media-tags-dialog.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [
    MediaManagerComponent,
    EditMediaDialogComponent,
    MediaTileComponent,
    MediaSelectorGridComponent,
    MediaInfoComponent,
    FilePreviewComponent,
    MediaListItemComponent,
    MediaSettingsComponent,
    DicomViewerComponent,
    MediaActionButtonsComponent,
    MediaTagsDialogComponent,
  ],
  imports: [
    CommonModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    NgMaterialModule,
    NgxDocViewerModule,
    NgPrincipleBridgeCloudModule,
    AngularPinturaModule,
    FileIconsModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [MediaManagerComponent],
})
export class ComponentsModule {}
