import { BlockNodes, type IMenuButton } from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../menu-bar/basic-menu-button/basic-menu-button.component';

export const PARAGRAPH_LEFT_ALIGN_KEYMAP = 'Shift-Mod-l';
export const PARAGRAPH_CENTER_ALIGN_KEYMAP = 'Shift-Mod-c';
export const PARAGRAPH_RIGHT_ALIGN_KEYMAP = 'Shift-Mod-r';
export const PARAGRAPH_JUSTIFY_ALIGN_KEYMAP = 'Shift-Mod-j';

export function createLeftAlignMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'submenu-detail',
      buttonText: 'Left Align',
      tooltip: 'Left Align',
      shortcut: PARAGRAPH_LEFT_ALIGN_KEYMAP,
      icon: 'format_align_left',
      command: (editor) => editor.chain().focus().setTextAlign('left').run(),
      isActiveFn: (editor) =>
        editor.isActive(BlockNodes.Paragraph, {
          align: 'left',
        }) ||
        editor.isActive(BlockNodes.Paragraph, {
          align: '',
        }) ||
        editor.isActive(BlockNodes.Heading, {
          align: 'left',
        }) ||
        editor.isActive(BlockNodes.Heading, {
          align: '',
        }),
      canActivateFn: (editor) =>
        editor.isActive(BlockNodes.Paragraph) ||
        editor.isActive(BlockNodes.Heading),
    },
  });
}

export function createRightAlignMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'submenu-detail',
      buttonText: 'Right Align',
      tooltip: 'Right Align',
      shortcut: PARAGRAPH_RIGHT_ALIGN_KEYMAP,
      icon: 'format_align_right',
      command: (editor) => editor.chain().focus().setTextAlign('right').run(),
      isActiveFn: (editor) =>
        editor.isActive(BlockNodes.Paragraph, {
          align: 'right',
        }) ||
        editor.isActive(BlockNodes.Heading, {
          align: 'right',
        }),
      canActivateFn: (editor) =>
        editor.isActive(BlockNodes.Paragraph) ||
        editor.isActive(BlockNodes.Heading),
    },
  });
}

export function createCenterAlignMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'submenu-detail',
      buttonText: 'Center Align',
      tooltip: 'Center Align',
      shortcut: PARAGRAPH_CENTER_ALIGN_KEYMAP,
      icon: 'format_align_center',
      command: (editor) => editor.chain().focus().setTextAlign('center').run(),
      isActiveFn: (editor) =>
        editor.isActive(BlockNodes.Paragraph, {
          align: 'center',
        }) ||
        editor.isActive(BlockNodes.Heading, {
          align: 'center',
        }),
      canActivateFn: (editor) =>
        editor.isActive(BlockNodes.Paragraph) ||
        editor.isActive(BlockNodes.Heading),
    },
  });
}

export function createJustifyAlignMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'submenu-detail',
      buttonText: 'Justify Align',
      tooltip: 'Justify Align',
      shortcut: PARAGRAPH_JUSTIFY_ALIGN_KEYMAP,
      icon: 'format_align_justify',
      command: (editor) => editor.chain().focus().setTextAlign('justify').run(),
      isActiveFn: (editor) =>
        editor.isActive(BlockNodes.Paragraph, {
          align: 'justify',
        }) ||
        editor.isActive(BlockNodes.Heading, {
          align: 'justify',
        }),
      canActivateFn: (editor) =>
        editor.isActive(BlockNodes.Paragraph) ||
        editor.isActive(BlockNodes.Heading),
    },
  });
}
