<mat-toolbar color="accent">Select a plan</mat-toolbar>
<div class="layout-margin">
  <pr-treatment-plan-list
    [treatmentPlans]="data.planPairs"
    [todaysPlan]="data.todaysPlan"
    [selectedPlan]="data.selectedPlan"
    (selectPlan)="selectPlan($event)"
    (createPlan)="createPlan()"
   />
</div>
<div class="layout-margin flex items-center justify-end">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
</div>
