<div class="toolbar flex items-center justify-between">
  <div>
    <button
      mat-icon-button
      [matTooltip]="
        (selectionList.isAllSelected$ | async) ? 'Deselect all' : 'Select all'
      "
      (click)="selectionList.toggleAllSelected()"
    >
      <ng-container
        *ngIf="selectionList.isSomeSelected$ | async; else determinateCheckbox"
      >
        <mat-icon>indeterminate_check_box</mat-icon>
      </ng-container>
      <ng-template #determinateCheckbox>
        <mat-icon>
          {{
            (selectionList.isAllSelected$ | async)
              ? 'check_box'
              : 'check_box_outline_blank'
          }}
        </mat-icon>
      </ng-template>
    </button>
  </div>
  <div class="flex grow justify-between">
    <div class="flex items-center">
      <ng-container *ngrxLet="selectionList.isNoneSelected$ as isNoneSelected">
        <ng-container *ngIf="canReopen$ | async; else archiveChat">
          <button
            mat-icon-button
            matTooltip="Re-open chat(s)"
            [disabled]="isNoneSelected"
            (click)="openChats()"
          >
            <mat-icon>unarchive</mat-icon>
          </button>
        </ng-container>
        <ng-template #archiveChat>
          <button
            mat-icon-button
            matTooltip="Archive chat(s)"
            [disabled]="isNoneSelected"
            (click)="archiveChats()"
          >
            <mat-icon>archive</mat-icon>
          </button>
        </ng-template>

        <ng-container *ngIf="canMarkAsUnread$ | async; else markRead">
          <button
            mat-icon-button
            matTooltip="Mark as unread"
            [disabled]="isNoneSelected"
            (click)="markAsUnread()"
          >
            <mat-icon>mark_chat_unread</mat-icon>
          </button>
        </ng-container>
        <ng-template #markRead>
          <button
            mat-icon-button
            matTooltip="Mark as read"
            [disabled]="isNoneSelected"
            (click)="markAsRead()"
          >
            <mat-icon>mark_chat_read</mat-icon>
          </button>
        </ng-template>
      </ng-container>
    </div>

    <button
      class="self-center"
      mat-icon-button
      matTooltip="Dismiss selected / ESC"
      (click)="closeToolbar.emit()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
