import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type IContactNumber } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-patient-phone',
    templateUrl: './patient-phone.component.html',
    styleUrls: ['./patient-phone.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientPhoneComponent {
  contactNumbers$ = new ReplaySubject<IContactNumber[]>(1);
  trackByIndex = TrackByFunctions.index<IContactNumber>();

  @Input()
  set contactNumbers(contactNumbers: IContactNumber[]) {
    if (contactNumbers) {
      this.contactNumbers$.next(contactNumbers);
    }
  }
}
