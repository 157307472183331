import { type IMenuButton } from '@principle-theorem/editor';
import {
  type EditorMenuItemComponent,
  type ISubmenuButton,
  type MenuButtonConfigureFn,
  type MenuButtonLoaderFn,
} from '@principle-theorem/ng-prosemirror';
import { type BasicMenuButtonComponent } from '../menu-bar/basic-menu-button/basic-menu-button.component';
import {
  createAlignSubmenuButton,
  createInsertSubmenuButton,
  createMediaSubmenuButton,
} from '../menu-bar/submenu-button/submenu-button';
import {
  createAIMenuButton,
  editorAISubmenuButtons,
} from './ai/ai-menu-button';
import { createTextPromptBlockButton } from './ai/buttons/text-prompt-button';
import { createBlockquoteMenuButton } from './blockquote/blockquote-menu-button';
import {
  createCodeBlockBlockMenuButton,
  createCodeBlockMenuButton,
} from './code/code-block-menu-button';
import { createCodeMenuButton } from './code/code-menu-button';
import {
  createLayoutColumnBlockMenuButton,
  createLayoutColumnMenuButton,
} from './document-formatting/column-menu-button';
import {
  createHorizontalRuleBlockMenuButton,
  createHorizontalRuleMenuButton,
} from './document-formatting/horizontal-rule-menu-button';
import { createHeadingMenuBlockButtons } from './heading/heading-block-menu-button';
import { createHeadingMenuButton } from './heading/heading-menu-button';
import { type HeadingMenuComponent } from './heading/heading-menu/heading-menu.component';
import {
  createRedoMenuButton,
  createUndoMenuButton,
} from './history/history-menu-button';
import { ImageUploadMenuComponent } from './image/image-upload-menu/image-upload-menu.component';
import { createLinkMenuButton } from './link/link-menu-button';
import {
  createBulletedListBlockMenuButton,
  createBulletedListMenuButton,
} from './lists/bulleted-list-menu-button';
import {
  createOrderedListBlockMenuButton,
  createOrderedListMenuButton,
} from './lists/ordered-list-menu-button';
import { createMentionMenuButton } from './mention/mention-menu-button';
import {
  createCenterAlignMenuButton,
  createJustifyAlignMenuButton,
  createLeftAlignMenuButton,
  createRightAlignMenuButton,
} from './paragraph/paragraph-menu-button';
import { createSnippetMenuButton } from './snippet/snippet-menu-button';
import { createTableOfContentsMenuButton } from './table-of-contents/table-of-contents-menu-button';
import {
  createTableBlockMenuButton,
  createTableMenuButton,
} from './table/table-menu-button';
import { createBoldMenuButton } from './text-formatting/bold-menu-button';
import { createItalicMenuButton } from './text-formatting/italic-menu-button';
import { createStrikeMenuButton } from './text-formatting/strike-menu-button';
import { createUnderlineMenuButton } from './text-formatting/underline-menu-button';
import { createTextBadgeMenuButton } from './text-styling/text-badge-menu-button';
import { TextBadgeMenuComponent } from './text-styling/text-badge-menu/text-badge-menu.component';
import { createTextStylingMenuButton } from './text-styling/text-styling-menu-button';
import { TextStylingMenuComponent } from './text-styling/text-styling-menu/text-styling-menu.component';
import { type IUploader } from './uploader';
import { VideoEmbedMenuComponent } from './video/video-embed-menu/video-embed-menu.component';
import { createVideoEmbedBlockMenuButton } from './video/video-menu-button';
import { VideoUploadMenuComponent } from './video/video-upload-menu/video-upload-menu.component';
import { createImagePromptBlockButton } from './ai/buttons/image-prompt-button';

export interface IHasUploader {
  uploader: IUploader;
}

export interface IEditorInsertSubmenuButtons {
  codeBlock: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  table: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  blockquote: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  layoutColumn: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
}

export interface IEditorMediaSubmenuButtons {
  imageUpload: MenuButtonConfigureFn<ImageUploadMenuComponent, IHasUploader>;
  videoUpload: MenuButtonConfigureFn<VideoUploadMenuComponent, IHasUploader>;
  videoEmbed: () => MenuButtonLoaderFn<VideoEmbedMenuComponent, IMenuButton>;
}

export interface IEditorAlignSubmenuButtons {
  leftAlign: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  centerAlign: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  rightAlign: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  justifyAlign: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
}

export interface IEditorMenuButtons {
  tableOfContents: () => MenuButtonLoaderFn<
    BasicMenuButtonComponent,
    IMenuButton
  >;
  link: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  bold: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  italic: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  underline: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  strike: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  bulletedList: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  orderedList: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  horizontalRule: () => MenuButtonLoaderFn<
    BasicMenuButtonComponent,
    IMenuButton
  >;
  heading: () => MenuButtonLoaderFn<HeadingMenuComponent, IMenuButton>;
  textBadge: () => MenuButtonLoaderFn<TextBadgeMenuComponent, IMenuButton>;
  textStyling: () => MenuButtonLoaderFn<TextStylingMenuComponent, IMenuButton>;
  ai: (
    submenuItems: MenuButtonLoaderFn[]
  ) => MenuButtonLoaderFn<BasicMenuButtonComponent, ISubmenuButton>;
  code: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  undo: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  redo: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  insert: (
    submenuItems: MenuButtonLoaderFn[]
  ) => MenuButtonLoaderFn<BasicMenuButtonComponent, ISubmenuButton>;
  media: (
    submenuItems: MenuButtonLoaderFn[]
  ) => MenuButtonLoaderFn<BasicMenuButtonComponent, ISubmenuButton>;
  align: (
    submenuItems: MenuButtonLoaderFn[]
  ) => MenuButtonLoaderFn<BasicMenuButtonComponent, ISubmenuButton>;
  mention: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  snippet: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
}

export const editorMenuButtons: IEditorMenuButtons = {
  tableOfContents: createTableOfContentsMenuButton,
  ai: createAIMenuButton,
  link: createLinkMenuButton,
  bold: createBoldMenuButton,
  italic: createItalicMenuButton,
  underline: createUnderlineMenuButton,
  strike: createStrikeMenuButton,
  bulletedList: createBulletedListMenuButton,
  orderedList: createOrderedListMenuButton,
  horizontalRule: createHorizontalRuleMenuButton,
  heading: createHeadingMenuButton,
  textBadge: createTextBadgeMenuButton,
  textStyling: createTextStylingMenuButton,
  code: createCodeMenuButton,
  undo: createUndoMenuButton,
  redo: createRedoMenuButton,
  insert: createInsertSubmenuButton,
  media: createMediaSubmenuButton,
  align: createAlignSubmenuButton,
  mention: createMentionMenuButton,
  snippet: createSnippetMenuButton,
};

export const editorInsertSubmenuButtons: IEditorInsertSubmenuButtons = {
  table: createTableMenuButton,
  blockquote: createBlockquoteMenuButton,
  codeBlock: createCodeBlockMenuButton,
  layoutColumn: createLayoutColumnMenuButton,
};

export const editorMediaSubmenuButtons: IEditorMediaSubmenuButtons = {
  imageUpload: (data: IHasUploader) => () => ({
    component: ImageUploadMenuComponent,
    data,
  }),
  videoUpload: (data: IHasUploader) => () => ({
    component: VideoUploadMenuComponent,
    data,
  }),
  videoEmbed: () => () => ({
    component: VideoEmbedMenuComponent,
  }),
};

export const editorAlignSubmenuButtons: IEditorAlignSubmenuButtons = {
  leftAlign: createLeftAlignMenuButton,
  centerAlign: createCenterAlignMenuButton,
  rightAlign: createRightAlignMenuButton,
  justifyAlign: createJustifyAlignMenuButton,
};

export const DEFAULT_MENU_BUTTONS: MenuButtonLoaderFn<
  EditorMenuItemComponent,
  IMenuButton
>[] = [
  editorMenuButtons.ai([
    editorAISubmenuButtons.adjustTone(),
    editorAISubmenuButtons.autocomplete(),
    editorAISubmenuButtons.emojify(),
    editorAISubmenuButtons.extend(),
    editorAISubmenuButtons.fixSpellingAndGrammar(),
    editorAISubmenuButtons.textPrompt(),
    editorAISubmenuButtons.rephrase(),
    editorAISubmenuButtons.shorten(),
    editorAISubmenuButtons.simplify(),
    editorAISubmenuButtons.summarize(),
    editorAISubmenuButtons.tldr(),
    editorAISubmenuButtons.imagePrompt(),
  ]),
  editorMenuButtons.heading(),
  editorMenuButtons.textBadge(),
  editorMenuButtons.textStyling(),
  editorMenuButtons.bold(),
  editorMenuButtons.italic(),
  editorMenuButtons.underline(),
  editorMenuButtons.strike(),
  editorMenuButtons.link(),
  editorMenuButtons.horizontalRule(),
  editorMenuButtons.bulletedList(),
  editorMenuButtons.orderedList(),
  editorMenuButtons.align([
    editorAlignSubmenuButtons.leftAlign(),
    editorAlignSubmenuButtons.centerAlign(),
    editorAlignSubmenuButtons.rightAlign(),
    editorAlignSubmenuButtons.justifyAlign(),
  ]),
  editorMenuButtons.code(),
  editorMenuButtons.insert([
    editorInsertSubmenuButtons.blockquote(),
    editorInsertSubmenuButtons.codeBlock(),
    editorInsertSubmenuButtons.table(),
    editorInsertSubmenuButtons.layoutColumn(),
  ]),
  editorMenuButtons.undo(),
  editorMenuButtons.redo(),
];

export const DEFAULT_BLOCK_MENU_BUTTONS: MenuButtonLoaderFn<BasicMenuButtonComponent>[] =
  [
    createTextPromptBlockButton(),
    createImagePromptBlockButton(),
    ...createHeadingMenuBlockButtons(),
    createBulletedListBlockMenuButton(),
    createOrderedListBlockMenuButton(),
    createHorizontalRuleBlockMenuButton(),
    createTableBlockMenuButton(),
    createVideoEmbedBlockMenuButton(),
    ...createLayoutColumnBlockMenuButton(),
    createCodeBlockBlockMenuButton(),
  ];
