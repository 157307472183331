import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IAccountSummary } from '@principle-theorem/principle-core/interfaces';

@Component({
    selector: 'pr-account-credit-summary',
    templateUrl: './account-credit-summary.component.html',
    styleUrls: ['./account-credit-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AccountCreditSummaryComponent {
  @Input() summary: IAccountSummary;
}
