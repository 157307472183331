import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pr-health-fund-card-preview',
  templateUrl: './health-fund-card-preview.component.html',
  styleUrls: ['./health-fund-card-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthFundCardPreviewComponent {
  @Input() hexColour: string;
}
