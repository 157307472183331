import { BehaviorSubject, Observable } from 'rxjs';
import { GeneralProviderActionsService } from '../../../general-provider-actions.service';
import {
  ITransactionAction,
  ITransactionActionsData,
} from '../../../transaction-action';
import { TransactionHelpers } from '../../../transaction-helpers';
import { EasyclaimTransactionExtendedData } from '../easyclaim-transaction';
import { isPatientEClaimTransactionExtendedData } from '@principle-theorem/principle-core/interfaces';

export class AmendEasyclaimTransaction
  implements ITransactionAction<EasyclaimTransactionExtendedData>
{
  icon = 'edit';
  label = 'Amend';
  tooltip = 'Amend if claim amount is incorrect';
  inProgress$ = new BehaviorSubject<boolean>(false);
  typeGuardFn = isPatientEClaimTransactionExtendedData;

  constructor(private _actions: GeneralProviderActionsService) {}

  canDo$(
    data: ITransactionActionsData<EasyclaimTransactionExtendedData>
  ): Observable<boolean> {
    return TransactionHelpers.canAmend$(data);
  }

  async do(
    data: ITransactionActionsData<EasyclaimTransactionExtendedData>
  ): Promise<void> {
    this.inProgress$.next(true);
    await this._actions.amend(
      data.invoice,
      data.latestTransaction,
      'Amend Medicare Amount'
    );
    this.inProgress$.next(false);
  }
}
