import { Directive, HostBinding, Input } from '@angular/core';
import {
  type IDentalChartViewSurface,
  isChartedItemDisplay,
} from '@principle-theorem/principle-core/interfaces';

@Directive({
    selector: '[prChartSurfacePathStroke]',
    standalone: false
})
export class ChartSurfacePathStrokeDirective {
  @HostBinding('style.stroke') stroke?: string;

  @Input('prChartSurfacePathStroke')
  set view(view: IDentalChartViewSurface) {
    if (!view) {
      this.stroke = undefined;
      return;
    }

    if (view.texture && isChartedItemDisplay(view.texture)) {
      this.stroke = view.texture.colour.value;
    }
  }
}
