import { BlockNodes, IMenuButton } from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { Editor } from '@tiptap/core';
import { TextBadgeMenuComponent } from './text-badge-menu/text-badge-menu.component';

export function createTextBadgeMenuButton(): MenuButtonLoaderFn<
  TextBadgeMenuComponent,
  IMenuButton
> {
  return () => ({
    component: TextBadgeMenuComponent,
    data: {
      buttonType: 'submenu-detail',
      canActivateFn: (editor: Editor) =>
        editor.isActive(BlockNodes.Heading) ||
        editor.isActive(BlockNodes.Paragraph),
      buttonText: '',
      icon: '',
      tooltip: '',
      command: () => {
        //
      },
    },
  });
}
