import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { CreateConfiguration } from '@principle-theorem/ng-principle-shared';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { ConditionConfiguration } from '@principle-theorem/principle-core';
import { type IConditionConfiguration } from '@principle-theorem/principle-core/interfaces';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IConditionFormData extends Pick<IConditionConfiguration, 'name'> {}

@Component({
  selector: 'pr-create-condition-configuration',
  templateUrl: './create-condition-configuration.component.html',
  styleUrls: ['./create-condition-configuration.component.sass'],
})
export class CreateConditionConfigurationComponent extends CreateConfiguration<
  CreateConditionConfigurationComponent,
  IConditionFormData,
  IConditionConfiguration
> {
  form: TypedFormGroup<IConditionFormData> =
    new TypedFormGroup<IConditionFormData>({
      name: new TypedFormControl('', Validators.required),
    });

  protected _transformData(
    formData: IConditionFormData
  ): IConditionConfiguration {
    return ConditionConfiguration.init(formData);
  }
}
