<pr-charted-condition-card
  *ngIf="condition$ | async as condition"
  [condition]="condition"
  [disabled]="disabled"
  [highlighted]="hasSelectedSurface$ | async"
  (conditionChange)="conditionChange.emit($event)"
  (conditionDelete)="conditionDelete.emit($event)"
  (updateConditionSurfaces)="updateConditionSurfaces.emit($event)"
>
  <pr-charted-item-notes-list
    [item]="condition"
    (itemChange)="conditionChange.emit($event)"
   />
</pr-charted-condition-card>
