<button
  mat-icon-button
  *ngIf="tourId"
  aria-label="Take a tour"
  matTooltip="Learn how to use this page."
  (click)="startTour(tourId)"
  ptIntercomTarget="product-tour-button"
>
  <mat-icon>tour</mat-icon>
</button>
