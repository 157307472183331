import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { type IFilterOption } from '@principle-theorem/ng-shared';
import {
  Automation,
  AutomationTiming,
} from '@principle-theorem/principle-core';
import {
  AutomationStatus,
  IPractice,
  TemplateScope,
  isEventable,
  type AutomationType,
  type IAppointmentScopeData,
  type IAutomatedNotification,
  type IAutomation,
  type IAutomationConfiguration,
  type IBrand,
  type IEvent,
  type IGeneratedTask,
  type IPatient,
  type ITreatmentStep,
  type TreatmentStepAutomation,
} from '@principle-theorem/principle-core/interfaces';
import {
  getDoc,
  isDocRef,
  isReffable,
  isSameRef,
  isWithRef,
  multiMap,
  multiSort,
  serialise,
  snapshot,
  sortTimestamp,
  toEntityModel,
  unserialise$,
  type DocumentReference,
  type EntityModel,
  type INamedDocument,
  type INamedTypeDocument,
  type Reffable,
  type SerialisedData,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { remove, uniqWith } from 'lodash';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutomationActions, AutomationListActions } from '../actions';
import { type IAutomationsPartialState } from '../automations.reducer';
import {
  getAutomationById,
  getAutomations,
  getAutomationsByCreator,
  getAutomationsLoaded,
  getFilteredAutomations,
  getIsAllSelected,
  getIsNoneSelected,
  getListSelectedEntities,
  getListSelectedIds,
  getReffableAutomations,
  getSelectedAutomation,
  getStatusFilter,
  getTypeFilter,
  getUnsavedAutomations,
} from '../automations.selectors';
import {
  getAutomationTypeFilter,
  isAutomatedNotificationAutomation,
  isGeneratedTaskAutomation,
  type AutomationEntity,
  type AutomationTypeFilter,
} from '../models';
import { type IAutomationListData } from '../models/automation-list-data';

@Injectable()
export class AutomationsFacade {
  private _store = inject(Store<IAutomationsPartialState>);
  automationsLoaded$: Observable<boolean>;
  automations$: Observable<AutomationEntity[]>;
  selectedAutomation$: Observable<AutomationEntity | undefined>;
  reffableAutomations$: Observable<Reffable<AutomationEntity>[]>;
  newAutomations$: Observable<AutomationEntity[]>;

  statusFilter$: Observable<AutomationStatus[]>;
  typeFilter$: Observable<AutomationType | undefined>;
  activeTypeFilter$: Observable<
    IFilterOption<AutomationTypeFilter, AutomationEntity>
  >;
  filteredAutomations$: Observable<AutomationEntity[]>;
  selectedAutomations$: Observable<AutomationEntity[]>;
  selectedAutomationIds$: Observable<string[]>;
  isAllSelected$: Observable<boolean>;
  isNoneSelected$: Observable<boolean>;

  constructor() {
    this.automationsLoaded$ = this._store.pipe(select(getAutomationsLoaded));
    this.automations$ = this._store.pipe(
      select(getAutomations),
      unserialise$(),
      multiSort((automationA, automationB) =>
        sortTimestamp(automationA.triggerDate, automationB.triggerDate)
      )
    );
    this.selectedAutomation$ = this._store.pipe(
      select(getSelectedAutomation),
      unserialise$()
    );
    this.reffableAutomations$ = this._store.pipe(
      select(getReffableAutomations),
      unserialise$()
    );
    this.newAutomations$ = this._store.pipe(
      select(getUnsavedAutomations),
      unserialise$()
    );

    this.statusFilter$ = this._store.pipe(select(getStatusFilter));
    this.typeFilter$ = this._store.pipe(select(getTypeFilter));
    this.activeTypeFilter$ = this.typeFilter$.pipe(
      map(getAutomationTypeFilter)
    );
    this.filteredAutomations$ = this._store.pipe(
      select(getFilteredAutomations),
      unserialise$()
    );
    this.selectedAutomations$ = this._store.pipe(
      select(getListSelectedEntities),
      unserialise$()
    );
    this.selectedAutomationIds$ = this._store.pipe(select(getListSelectedIds));
    this.isAllSelected$ = this._store.pipe(select(getIsAllSelected));
    this.isNoneSelected$ = this._store.pipe(select(getIsNoneSelected));
  }

  getFilteredListData$(): Observable<IAutomationListData[]> {
    return combineLatest([
      this.filteredAutomations$,
      this.selectedAutomationIds$,
    ]).pipe(
      map(([automations, ids]) => {
        return automations
          .sort((automationA, automationB) =>
            sortTimestamp(automationA.triggerDate, automationB.triggerDate)
          )
          .map((automation) => ({
            automation,
            isSelected: ids.includes(automation.uid),
          }));
      })
    );
  }

  automationIsSelected$(id: string): Observable<boolean> {
    return this.selectedAutomationIds$.pipe(map((ids) => ids.includes(id)));
  }

  selectMultiple(ids: string[]): void {
    return this._store.dispatch(
      AutomationListActions.selectMultipleAutomations({ ids })
    );
  }

  unselectAll(): void {
    return this._store.dispatch(AutomationListActions.unselectAll());
  }

  unselectOne(id: string): void {
    return this._store.dispatch(
      AutomationListActions.unselectAutomation({ id })
    );
  }

  selectOne(id: string): void {
    return this._store.dispatch(AutomationListActions.selectAutomation({ id }));
  }

  cancelSelected(): void {
    return this._store.dispatch(AutomationListActions.cancelSelected());
  }

  cancelOne(automation: AutomationEntity): void {
    this._store.dispatch(
      AutomationActions.cancelAutomation(serialise({ automation }))
    );
  }

  getAutomation$(id: string): Observable<AutomationEntity | undefined> {
    return this._store.pipe(select(getAutomationById(id)), unserialise$());
  }

  setStatusFilter(statusFilter: AutomationStatus[]): void {
    this._store.dispatch(
      AutomationListActions.setStatusFilter({ statusFilter })
    );
  }

  setTypeFilter(typeFilter: AutomationType): void {
    this._store.dispatch(AutomationListActions.setTypeFilter({ typeFilter }));
  }

  setPracticeFilter(practice?: INamedDocument<IPractice>): void {
    this._store.dispatch(
      AutomationListActions.setPracticeFilter({
        practiceFilter: practice ? practice.ref.path : undefined,
      })
    );
  }

  async loadTreatmentStepAutomations(
    step: WithRef<ITreatmentStep> | DocumentReference<ITreatmentStep>
  ): Promise<void> {
    if (isDocRef(step)) {
      step = await getDoc(step);
    }
    const treatmentStep = serialise(step);
    this._store.dispatch(
      AutomationActions.loadTreatmentStepAutomations({ treatmentStep })
    );
  }

  selectAutomation(id: string): void {
    this._store.dispatch(AutomationActions.selectAutomation({ id }));
  }

  addAutomations(newAutomations: IAutomation<TreatmentStepAutomation>[]): void {
    const automations = newAutomations.map(toEntityModel);
    this._store.dispatch(
      AutomationActions.addAutomations(serialise({ automations }))
    );
  }

  setAutomations(newAutomations: IAutomation<TreatmentStepAutomation>[]): void {
    const automations = newAutomations.map(toEntityModel);
    this._store.dispatch(
      AutomationActions.setAutomations(serialise({ automations }))
    );
  }

  getAutomationsCreators$(): Observable<INamedTypeDocument[]> {
    return this.automations$.pipe(
      multiMap((automation) => automation.creator),
      map((creators) => uniqWith(creators, isSameRef))
    );
  }

  getAutomationsByCreator$(
    creator: INamedTypeDocument
  ): Observable<AutomationEntity[]> {
    return this._store.pipe(
      select(getAutomationsByCreator(creator)),
      unserialise$()
    );
  }

  loadBrandAutomations(
    brand: WithRef<IBrand>,
    dateFrom: Timestamp,
    dateTo: Timestamp
  ): void {
    this._store.dispatch(
      AutomationActions.loadBrandAutomations(
        serialise({ brand, dateFrom, dateTo })
      )
    );
  }

  loadPatientAutomations(patient: WithRef<IPatient>): void {
    this._store.dispatch(
      AutomationActions.loadPatientAutomations(serialise({ patient }))
    );
  }

  loadConfigAutomations(
    config: WithRef<IAutomationConfiguration>,
    dateFrom: Timestamp,
    dateTo: Timestamp
  ): void {
    this._store.dispatch(
      AutomationActions.loadConfigAutomations(
        serialise({ config, dateFrom, dateTo })
      )
    );
  }

  saveAutomations(treatmentStep: WithRef<ITreatmentStep>): void {
    this._store.dispatch(
      AutomationActions.saveTreatmentStepAutomations(
        serialise({ treatmentStep })
      )
    );
  }

  updateAutomation(
    id: string,
    changes: Partial<SerialisedData<AutomationEntity>>,
    skipEffect: boolean = false
  ): void {
    const update = { id, changes };
    this._store.dispatch(
      AutomationActions.updateAutomation({ update, skipEffect })
    );
  }

  removeAutomation(id: string): void {
    this._store.dispatch(AutomationActions.removeAutomation({ id }));
  }

  async restoreAutomation(automation: AutomationEntity): Promise<void> {
    if (!isWithRef(automation)) {
      return;
    }
    const appointment = await snapshot(
      Automation.resolveAppointment$(automation)
    );

    if (!appointment || !isEventable(appointment)) {
      return;
    }

    const triggerDate = await AutomationTiming.resolveTriggerDateFromEvent(
      appointment.event,
      automation.data.timing
    );
    if (!triggerDate) {
      return;
    }
    this.updateAutomation(
      automation.uid,
      serialise({
        status: AutomationStatus.Pending,
        triggerDate,
      })
    );
  }

  resend(automation: AutomationEntity): void {
    if (!isReffable(automation)) {
      return;
    }
    this._store.dispatch(
      AutomationActions.forceResend(serialise({ automation }))
    );
  }

  resetState(): void {
    this._store.dispatch(AutomationActions.resetAutomationsState());
  }

  addTask(
    event: IEvent,
    stepRef?: DocumentReference<ITreatmentStep>,
    triggerAfterDate?: Timestamp
  ): void {
    this._store.dispatch(
      AutomationListActions.addGeneratedTask(
        serialise({ event, stepRef, triggerAfterDate })
      )
    );
  }

  editTask(
    automation: EntityModel<IAutomation<IGeneratedTask>>,
    event: IEvent,
    skipEffect: boolean = false,
    useRelativeTime: boolean = true
  ): void {
    const edit = serialise({
      id: automation.uid,
      generatedTask: automation.data,
      event,
      triggerDate: automation.triggerDate,
      triggerAfterDate: automation.triggerAfterDate,
      skipEffect,
      useRelativeTime,
    });
    this._store.dispatch(
      AutomationListActions.editGeneratedTask(serialise({ ...edit }))
    );
  }

  addNotification(
    scope: TemplateScope = TemplateScope.None,
    scopeData: IAppointmentScopeData,
    event: IEvent,
    stepRef?: DocumentReference<ITreatmentStep>,
    triggerAfterDate?: Timestamp
  ): void {
    this._store.dispatch(
      AutomationListActions.addNotification(
        serialise({
          scope,
          scopeData,
          event,
          stepRef,
          triggerAfterDate,
        })
      )
    );
  }

  addFromTemplate(
    scope: TemplateScope = TemplateScope.None,
    event: IEvent,
    stepRef?: DocumentReference<ITreatmentStep>
  ): void {
    this._store.dispatch(
      AutomationListActions.addFromTemplate(
        serialise({ scope, event, stepRef })
      )
    );
  }

  editNotification(
    automation: EntityModel<IAutomation<IAutomatedNotification>>,
    event: IEvent,
    skipEffect: boolean = false,
    useRelativeTime: boolean = true
  ): void {
    const edit = serialise({
      id: automation.uid,
      notification: automation.data,
      event,
      triggerDate: automation.triggerDate,
      triggerAfterDate: automation.triggerAfterDate,
      skipEffect,
      useRelativeTime,
      configRef: automation.configRef,
    });
    this._store.dispatch(AutomationListActions.editNotification(edit));
  }

  editAutomation(
    automation: AutomationEntity,
    event: IEvent,
    skipEffect: boolean = false,
    useRelativeTime: boolean = true
  ): void {
    if (isGeneratedTaskAutomation(automation)) {
      return this.editTask(automation, event, skipEffect, useRelativeTime);
    }
    if (isAutomatedNotificationAutomation(automation)) {
      return this.editNotification(
        automation,
        event,
        skipEffect,
        useRelativeTime
      );
    }
  }

  removeAutomations(ids: string[]): void {
    this._store.dispatch(AutomationActions.removeAutomations({ ids }));
  }

  async removeAutomationsByTreatmentRef(
    treatmentConfig: INamedDocument
  ): Promise<void> {
    const existingAutomations = await snapshot(
      this.automations$.pipe(
        map((automations) =>
          automations.filter((automation) =>
            isSameRef(automation.creator, treatmentConfig)
          )
        )
      )
    );

    if (!existingAutomations.length) {
      return;
    }

    const toRemove = remove(
      existingAutomations,
      (automation) => !isWithRef(automation)
    );

    if (toRemove.length) {
      const ids = toRemove.map((automation) => automation.uid);
      this.removeAutomations(ids);
    }

    if (!existingAutomations.length) {
      return;
    }

    const updates = existingAutomations.map((automation) => ({
      id: automation.uid,
      changes: {
        status: AutomationStatus.Cancelled,
      },
    }));

    this._store.dispatch(
      AutomationActions.updateAutomations(serialise({ updates }))
    );
  }
}
