import { Injectable } from '@angular/core';
import {
  type IFeeSchedule,
  type IOrganisation,
  type IRole,
  type IUser,
} from '@principle-theorem/principle-core/interfaces';
import {
  httpsCallable,
  snapshot,
  toFirestore,
} from '@principle-theorem/shared';

export interface ICreateOrganisationRequest {
  organisation: IOrganisation;
  user: Pick<IUser, 'name' | 'email'>;
  feeSchedule: IFeeSchedule;
  role: IRole;
}

@Injectable()
export class FirebaseFunctionsService {
  async createOrganisation(
    organisation: IOrganisation,
    user: Pick<IUser, 'name' | 'email'>,
    feeSchedule: IFeeSchedule,
    role: IRole
  ): Promise<boolean> {
    return snapshot(
      httpsCallable<ICreateOrganisationRequest, boolean>(
        'http-auth-createOrganisation'
      )(
        toFirestore({
          organisation,
          user,
          feeSchedule,
          role,
        })
      )
    );
  }
}
