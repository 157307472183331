<h2 class="text-xl font-bold">Seed Demo Data</h2>

<div class="flex flex-col gap-4">
  <form [formGroup]="form">
    <div class="mt-2 flex flex-col items-start gap-2">
      <mat-form-field required class="w-full">
        <mat-label>Select an organisation</mat-label>
        <mat-select formControlName="organisation">
          @for (
            organisation of organisations$ | async;
            track organisation.ref.path
          ) {
            <mat-option [value]="organisation">{{
              organisation.name
            }}</mat-option>
          }
        </mat-select>
        <mat-hint>Select an organisation to use as the source data.</mat-hint>
      </mat-form-field>

      <div class="flex w-full items-center gap-2">
        <mat-form-field class="w-full">
          <mat-label>Prefix File Name</mat-label>
          <input matInput formControlName="prefixFileName" />
          <mat-hint> e.g. New Zealand Space, Orthodontists Space </mat-hint>
        </mat-form-field>

        <mat-form-field class="w-full" required>
          <mat-label>First Event Date</mat-label>
          <input
            matInput
            formControlName="anchorDate"
            [matDatepicker]="date"
            (focus)="date.open()"
            (click)="date.open()"
          />
          <mat-datepicker-toggle matIconSuffix [for]="date" />
          <mat-datepicker #date />
          <mat-hint>
            Select the date when the first event occurs on the timeline.
          </mat-hint>
        </mat-form-field>
      </div>

      <button
        mat-flat-button
        color="primary"
        [disabled]="form.invalid"
        (click)="seedDemoSpace()"
      >
        Create
      </button>
    </div>
  </form>

  @if (fileName$ | async; as fileName) {
    <p class="mt-8"><span class="font-bold">File Name: </span>{{ fileName }}</p>
  }

  @if (demoSpaces$ | async; as demoSpaces) {
    @if (demoSpaces.fileNames.length) {
      <mat-divider />
      <p class="font-bold">Existing seed files</p>
      <mat-list>
        @for (name of demoSpaces.fileNames; track name) {
          <mat-list-item>
            <div class="flex w-full items-center justify-between gap-4">
              <div class="h-full flex-1">
                {{ name }}
              </div>

              <div class="flex items-center justify-end gap-4">
                <div>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="deleteDemoSpace(name)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </mat-list-item>
        }
      </mat-list>
    }
  }
</div>
