import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { type ThemePalette } from '@angular/material/core';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pt-button-with-menu',
  templateUrl: './button-with-menu.component.html',
  styleUrls: ['./button-with-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonWithMenuComponent {
  color$: ReplaySubject<ThemePalette | undefined> = new ReplaySubject(1);
  checked$ = new ReplaySubject<boolean>(1);
  @Input() label = '';
  @Input() type?: 'stroked' | 'flat' | 'raised' = 'stroked';
  @Output() buttonClick = new EventEmitter<void>();

  @Input()
  set checked(checked: BooleanInput) {
    this.checked$.next(coerceBooleanProperty(checked));
  }

  @Input()
  set color(color: ThemePalette | undefined) {
    this.color$.next(color);
  }
}
