import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import {
  OrganisationService,
  TimezoneService,
} from '@principle-theorem/ng-principle-shared';
import {
  ProfileImageService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { Event, getEventTitle } from '@principle-theorem/principle-core';
import {
  EVENT_TYPES_ICONS,
  IScheduleSummaryEventable,
  type IUser,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_TIME_WITH_YEAR_FORMAT,
  type ITimePeriod,
  timePeriodToHumanisedTime,
  TIME_FORMAT,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-generic-timeline-tooltip',
  templateUrl: './generic-timeline-tooltip.component.html',
  styleUrls: ['./generic-timeline-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericTimelineTooltipComponent {
  readonly dateFormat = DATE_TIME_WITH_YEAR_FORMAT;
  profileImage = inject(ProfileImageService);
  trackByParticipant = TrackByFunctions.ref<WithRef<IUser>>();
  trackBySummary = TrackByFunctions.index<string[]>();
  participants$: Observable<WithRef<IUser>[]>;
  eventable$ = new ReplaySubject<IScheduleSummaryEventable>(1);
  eventIcon$: Observable<string>;
  title$: Observable<string>;
  eventTimeSummary$: Observable<string[]>;

  @Input()
  set eventable(eventable: IScheduleSummaryEventable) {
    if (eventable) {
      this.eventable$.next(eventable);
    }
  }

  constructor(
    private _organisation: OrganisationService,
    private _timezone: TimezoneService
  ) {
    this.title$ = this.eventable$.pipe(
      map((eventable) => getEventTitle(eventable))
    );
    this.eventIcon$ = this.eventable$.pipe(
      map((eventable) => EVENT_TYPES_ICONS[eventable.event.type])
    );
    const staffParticipants$ = this.eventable$.pipe(
      map((eventable) => Event.staff(eventable.event))
    );

    this.participants$ = combineLatest([
      staffParticipants$,
      this._organisation.stafferMap$,
      this._organisation.userMap$,
    ]).pipe(
      map(([participants, staff, users]) => {
        return compact(
          participants
            .map((participant) => staff[participant.ref.path])
            .filter((staffer) => !!staffer)
            .map((staffer) => users[staffer.user.ref.path])
        );
      })
    );

    this.eventTimeSummary$ = this.eventable$.pipe(
      switchMap((eventable) => this._timezone.getEventRange$(eventable.event)),
      map((range: ITimePeriod) => {
        const isSameDay = range.from.isSame(range.to, 'day');

        if (isSameDay) {
          const duration = timePeriodToHumanisedTime(range);
          return [
            `${range.from.format(TIME_FORMAT)} - ${range.to.format(
              TIME_FORMAT
            )}`,
            `(${duration})`,
          ];
        }
        return [
          range.from.format(DATE_TIME_WITH_YEAR_FORMAT),
          range.to.format(DATE_TIME_WITH_YEAR_FORMAT),
        ];
      })
    );
  }
}
