import { Inject, Injectable, type OnDestroy } from '@angular/core';
import {
  getIntercomUserID,
  isTimestamp,
  toMoment,
} from '@principle-theorem/shared';
import { set } from 'lodash';
import * as moment from 'moment-timezone';
import { Intercom, type IntercomBootInput } from '@supy-io/ngx-intercom';
import { hmac } from 'node-forge';
import { Subject } from 'rxjs';
import {
  type IIntercomConfig,
  type IIntercomUser,
  IntercomCustomData,
  NG_INTERCOM_CONFIG,
} from './intercom-config';

@Injectable({
  providedIn: 'root',
})
export class IntercomService implements OnDestroy {
  private _onDestroy$ = new Subject<void>();

  constructor(
    @Inject(NG_INTERCOM_CONFIG) private _config: IIntercomConfig,
    private _intercom: Intercom
  ) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  boot(overrides?: IntercomBootInput): void {
    this._intercom.boot({
      app_id: this._config.appId,
      hide_default_launcher: true,
      custom_launcher_selector: '#intercom',
      ...overrides,
    });
  }

  startTour(tourId: number): void {
    this._intercom.startTour(tourId);
  }

  open(): void {
    this._intercom.showMessages();
  }

  update(user: IIntercomUser): void {
    const userId: string = getIntercomUserID(this._config.appName, user.email);
    this._intercom.update({
      name: user.name,
      email: user.email,
      company: user.company,
      created_at: moment(user.createdAt).unix(),
      user_id: userId,
      user_hash: this._hashUserId(userId),
      ...serialiseCustomData(user.customData),
    });
  }

  updateUser(user?: IIntercomUser, bootConfig?: IntercomBootInput): void {
    if (!user) {
      this._intercom.shutdown();
      this.boot(bootConfig);
      return;
    }
    this.update(user);
  }

  hideIcon(): void {
    this._intercom.update({
      hide_default_launcher: true,
    });
  }

  showIcon(): void {
    this._intercom.update({
      hide_default_launcher: false,
    });
  }

  showArticle(articleId: number): void {
    this._intercom.showArticle(articleId);
  }

  private _hashUserId(userId: string): string {
    const encoder: hmac.HMAC = hmac.create();
    encoder.start('sha256', this._config.secretKey);
    encoder.update(userId);
    return encoder.digest().toHex();
  }
}

function serialiseCustomData(data?: IntercomCustomData): object {
  const serialisedData: object = {
    ...data,
  };

  if (!data) {
    return {};
  }

  Object.keys(data).forEach((key: string) => {
    const value: unknown = data[key];

    if (isTimestamp(value)) {
      set(serialisedData, key, toMoment(value).unix());
    }

    if (value === undefined) {
      // eslint-disable-next-line no-null/no-null
      set(serialisedData, key, null);
    }
  });

  return serialisedData;
}
