<h3 class="mat-headline-6">Manual Transaction Types</h3>
<pr-information-box>
  These are selectable types when taking manual transactions. Manual
  Transactions are grouped by these labels in reconciliation reporting.
</pr-information-box>
<div fxLayout="column" fxLayoutGap="16px">
  <pt-buttons-container>
    <button mat-flat-button color="primary" (click)="addType()">
      Create Transaction Type
    </button>
  </pt-buttons-container>
  <ng-container *ngrxLet="activeTypes$; let types">
    <ng-container *ngIf="!types.length; else typesList">
      <pr-empty-state image="list" title="transaction types" />
    </ng-container>
    <ng-template #typesList>
      <mat-list class="active-types-list">
        <mat-list-item
          *ngFor="let type of types; trackBy: trackByTransactionType"
        >
          <div
            fxFlex
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="16px"
          >
            <div fxFlex>{{ type.name }}</div>

            <div>
              <button mat-icon-button (click)="addType(type)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button color="warn" (click)="delete(type)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </ng-template>
  </ng-container>

  <ng-container *ngrxLet="deletedTypes$; let deletedTypes">
    <mat-list *ngIf="deletedTypes.length">
      <h3
        mat-subheader
        class="collapsible-subheader"
        (click)="deletedContent.toggle()"
      >
        Deleted Types
        <mat-icon>
          {{ deletedContent.expanded ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </h3>
      <pt-collapsible #deletedContent>
        <ng-template ptCollapsibleContent>
          <mat-list-item
            *ngFor="let type of deletedTypes; trackBy: trackByTransactionType"
          >
            <div
              fxFlex
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="16px"
            >
              <div fxFlex>{{ type.name }}</div>

              <div matTooltip="Restore">
                <button mat-icon-button (click)="restore(type)">
                  <mat-icon>restore</mat-icon>
                </button>
              </div>
            </div>
          </mat-list-item>
        </ng-template>
      </pt-collapsible>
    </mat-list>
  </ng-container>
</div>
