<mat-toolbar color="accent">
  <ng-container *ngIf="update; else add">Update</ng-container>
  <ng-template #add>Add</ng-template>
  Treatment Template
</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content>
    <div class="flex flex-col gap-4">
      <pr-content-container>
        <mat-form-field class="w-full">
          <mat-label>Name</mat-label>
          <input matInput required formControlName="name" />
          @if (form.controls.name.errors?.required) {
            <mat-error>Name is required</mat-error>
          }
        </mat-form-field>

        <div class="grid grid-cols-1 gap-4 md:grid-cols-12">
          <div class="flex flex-col gap-4 md:col-span-9 md:flex-row">
            <pr-staffer-treatment-template-select
              class="w-full"
              label="Treatment"
              formControlName="treatment"
              [brand]="store.brand$ | async"
            />
          </div>

          <mat-form-field class="w-full md:col-span-3">
            <mat-label>Duration (mins)</mat-label>
            <input
              type="number"
              matInput
              required
              [min]="stepSize()"
              [step]="stepSize()"
              formControlName="duration"
            />
            <mat-hint>Minimum duration is {{ stepSize() }} minutes</mat-hint>
            @if (form.controls.duration.errors?.required) {
              <mat-error>Duration is required</mat-error>
            }
            @if (form.controls.duration.errors?.min) {
              <mat-error>
                Duration must be at least {{ stepSize() }} minutes
              </mat-error>
            }
          </mat-form-field>
        </div>
        <div class="mt-4 w-full">
          <mat-checkbox formControlName="isPublic">
            Available for booking online
          </mat-checkbox>
        </div>
      </pr-content-container>

      <pr-content-container>
        <div class="w-full" formGroupName="enabledPractices">
          <p class="text-sm font-bold text-black">
            Practices offering this treatment
          </p>

          @for (practice of store.practices$ | async; track practice.ref.path) {
            <div class="flex flex-col gap-2">
              <mat-checkbox
                [formControlName]="practice.ref.id"
                [value]="practice"
              >
                {{ practice.name }}
              </mat-checkbox>
            </div>
          }
        </div>
      </pr-content-container>

      <pr-content-container>
        <div class="flex flex-col gap-8">
          <div class="w-full" [formGroup]="implementedBy">
            <p class="text-sm font-bold text-black">
              Staff offering this treatment
            </p>
            <div class="flex flex-col gap-4">
              <mat-checkbox
                #selectAll
                class="w-full"
                (change)="toggleAllStaff(selectAll.checked)"
              >
                Select All
              </mat-checkbox>
              @for (staffer of store.staff$ | async; track staffer.ref.path) {
                @if (
                  staffer | map: getImplementationPair : this;
                  as implementationPair
                ) {
                  <div class="grid grid-cols-1 gap-4 md:grid-cols-12">
                    <div class="flex flex-col gap-4 md:col-span-3 md:flex-row">
                      <mat-checkbox
                        [formControl]="implementationPair.controls.enabled"
                        class="w-full"
                      >
                        {{ staffer.name }}
                      </mat-checkbox>
                    </div>

                    @if (implementationPair.controls.enabled.value) {
                      <div class="w-full md:col-span-6">
                        <pr-staffer-treatment-template-select
                          [formControl]="implementationPair.controls.treatment"
                          [required]="false"
                          label="Override Treatment"
                          [staffer]="staffer"
                          [brand]="store.brand$ | async"
                          class="w-full"
                        />
                      </div>

                      <mat-form-field
                        class="w-full md:col-span-3"
                        subscriptSizing="dynamic"
                      >
                        <mat-label>Override Duration (mins)</mat-label>
                        <input
                          [formControl]="implementationPair.controls.duration"
                          [placeholder]="form.controls.duration.value"
                          type="number"
                          matInput
                          [min]="stepSize()"
                          [step]="stepSize()"
                        />
                        @if (implementationPair.controls.duration.errors?.min) {
                          <mat-error>
                            Duration must be at least {{ stepSize() }} minutes
                          </mat-error>
                        }
                      </mat-form-field>
                    } @else {
                      <div
                        class="h-11 w-full rounded bg-slate-100 md:col-span-6"
                      ></div>
                      <div
                        class="h-11 w-full rounded bg-slate-100 md:col-span-3"
                      ></div>
                    }
                  </div>
                }
              }
            </div>
          </div>
        </div>
      </pr-content-container>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button mat-stroked-button type="button" mat-dialog-close>Cancel</button>
      <button
        mat-flat-button
        type="submit"
        [disabled]="form.invalid"
        color="primary"
      >
        Save
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
