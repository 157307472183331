import { getEnumValues, type ITimePeriod } from '@principle-theorem/shared';
import { isString } from 'lodash';

export enum TimelineDisplayOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export enum TimelineDisplayZoom {
  AutoFit = 'autoFit',
  Regular = 'regular',
  Large = 'large',
}

export function isTimelineDisplayZoom(
  data: unknown
): data is TimelineDisplayZoom {
  return (
    isString(data) &&
    getEnumValues(TimelineDisplayZoom).includes(data as TimelineDisplayZoom)
  );
}

export const DEFAULT_TIMELINE_ZOOM_SIZE = TimelineDisplayZoom.AutoFit;

export type PracticeTimelineHourIncrement = 10 | 15 | 20 | 30;

export type PracticeTimelineTooltipDelay = 'short' | 'long' | 'disabled';

export const DEFAULT_PRACTICE_TIMELINE_TOOLTIP_DELAY = 'short';
export const PRACTICE_TIMELINE_TOOLTIP_DELAY_OPTIONS: PracticeTimelineTooltipDelay[] =
  ['short', 'long', 'disabled'];

export function getTimelineTooltipInMs(
  delay: PracticeTimelineTooltipDelay
): number | undefined {
  switch (delay) {
    case 'short':
      return 0;
    case 'long':
      return 300;
    default:
      return undefined;
  }
}

export interface ITimelineDisplayRange {
  dateRange: ITimePeriod;
  timeRange: ITimePeriod;
}

export interface ITimelineDisplayOptions {
  orientation: TimelineDisplayOrientation;
  guidelineIncrementInMins: number;
  stepSizeInMins: number;
  stepSizeInPixels: number;
  trackSizeInPixels: number;
  minimumTracksPerGroup: number;
  hourIncrement: PracticeTimelineHourIncrement;
  tooltipDelay: PracticeTimelineTooltipDelay;
  showGridlines: boolean;
}

export interface ITimelineStafferDisplayOptions {
  orientation: TimelineDisplayOrientation;
  zoom: TimelineDisplayZoom;
  thickTracks: boolean;
  showGridlines: boolean;
  hideEmptyDays: boolean;
}

export function isSameOptions(
  optionsA: ITimelineDisplayOptions,
  optionsB: ITimelineDisplayOptions
): boolean {
  return (
    optionsA.orientation === optionsB.orientation &&
    optionsA.guidelineIncrementInMins === optionsB.guidelineIncrementInMins &&
    optionsA.stepSizeInMins === optionsB.stepSizeInMins &&
    optionsA.stepSizeInPixels === optionsB.stepSizeInPixels &&
    optionsA.trackSizeInPixels === optionsB.trackSizeInPixels &&
    optionsA.minimumTracksPerGroup === optionsB.minimumTracksPerGroup &&
    optionsA.hourIncrement === optionsB.hourIncrement &&
    optionsA.tooltipDelay === optionsB.tooltipDelay &&
    optionsA.showGridlines === optionsB.showGridlines
  );
}

export function isTimelineDisplayOrientation(
  data: unknown
): data is TimelineDisplayOrientation {
  return (
    isString(data) &&
    getEnumValues(TimelineDisplayOrientation).includes(
      data as TimelineDisplayOrientation
    )
  );
}
