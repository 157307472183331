import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogInput,
} from '@principle-theorem/ng-shared';
import { TreatmentConfigurationChecklists } from '@principle-theorem/principle-core';
import {
  ChecklistType,
  type IChecklistItem,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { patchDoc, type WithRef, snapshot } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddChecklistItemsComponent } from './add-checklist-items/add-checklist-items.component';

@Component({
  selector: 'pr-checklist-configuration',
  templateUrl: './checklist-configuration.component.html',
  styleUrls: ['./checklist-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistConfigurationComponent {
  treatmentConfig$ = new ReplaySubject<WithRef<ITreatmentConfiguration>>(1);
  preAppointmentChecklist$: Observable<IChecklistItem[]>;
  postAppointmentChecklist$: Observable<IChecklistItem[]>;

  @Input()
  set treatmentConfig(treatmentConfig: WithRef<ITreatmentConfiguration>) {
    if (treatmentConfig) {
      this.treatmentConfig$.next(treatmentConfig);
    }
  }

  constructor(private _dialog: MatDialog, private _snackBar: MatSnackBar) {
    this.preAppointmentChecklist$ = this.treatmentConfig$.pipe(
      map((treatmentConfig) =>
        TreatmentConfigurationChecklists.getChecklistByType(
          treatmentConfig.checklists,
          ChecklistType.Pre
        )
      )
    );
    this.postAppointmentChecklist$ = this.treatmentConfig$.pipe(
      map((treatmentConfig) =>
        TreatmentConfigurationChecklists.getChecklistByType(
          treatmentConfig.checklists,
          ChecklistType.Post
        )
      )
    );
  }

  async upsert(item?: IChecklistItem): Promise<void> {
    const treatmentConfig = await snapshot(this.treatmentConfig$);
    const checklistItem = await this._dialog
      .open<
        AddChecklistItemsComponent,
        undefined | IChecklistItem,
        IChecklistItem
      >(AddChecklistItemsComponent, DialogPresets.medium({ data: item }))
      .afterClosed()
      .toPromise();

    if (!checklistItem) {
      return;
    }

    await patchDoc(treatmentConfig.ref, {
      checklists: TreatmentConfigurationChecklists.upsertItem(
        treatmentConfig.checklists,
        checklistItem,
        item
      ),
    });
    this._snackBar.open('Treatment configuration saved');
  }

  async delete(item: IChecklistItem): Promise<void> {
    const treatmentConfig = await snapshot(this.treatmentConfig$);
    const data = confirmationDialogData({
      title: 'Delete Checklist Item',
      prompt: 'Are you sure you want to delete this checklist item?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!confirmed) {
      return;
    }

    await patchDoc(treatmentConfig.ref, {
      checklists: TreatmentConfigurationChecklists.updateItem(
        treatmentConfig.checklists,
        item,
        { ...item, deleted: true }
      ),
    });
    this._snackBar.open('Checklist Item Deleted');
  }
}
