import { Validators } from '@angular/forms';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { ConditionLogic } from '@principle-theorem/principle-core';
import {
  ConditionLogicConfigurationCollection,
  ICustomFormConfiguration,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference, isDocRef } from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { TimingFormGroup } from '../timing.formgroup';
import { type IAutomatedFormIssueFormData } from './automated-form-issue-dialog.component';

export class AutomatedFormIssueDialogForm extends TypedFormGroup<
  Omit<IAutomatedFormIssueFormData, 'deleted' | 'isActive' | 'treatmentRefs'>
> {
  constructor() {
    super({
      name: new TypedFormControl<string>(undefined, Validators.required),
      timing: new TimingFormGroup(),
      triggerDate: new TypedFormControl<Moment | undefined>({
        value: undefined,
        disabled: true,
      }),
      triggerTime: new TypedFormControl<string | undefined>({
        value: undefined,
        disabled: true,
      }),
      practiceRef: new TypedFormControl<
        DocumentReference<IPractice> | undefined
      >({
        value: undefined,
        disabled: true,
      }).withGuard(isDocRef),
      requiredConditions:
        new TypedFormControl<ConditionLogicConfigurationCollection>(
          ConditionLogic.collection([
            ConditionLogic.and([ConditionLogic.always()]),
          ])
        ),
      formConfigurationRef: new TypedFormControl<
        DocumentReference<ICustomFormConfiguration>
      >(undefined, Validators.required),
    });
  }

  enableTriggerControls(): void {
    this.controls.triggerDate.enable();
    this.controls.triggerTime.enable();
  }
}
