import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CurrentPatientScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type ICreateAction } from './create-action';

@Injectable()
export class CreateAppointmentActionService implements ICreateAction {
  icon = 'add';
  name = 'New Appointment';

  constructor(
    private _route: ActivatedRoute,
    private _patientScope: CurrentPatientScope,
    private _organisation: OrganisationService,
    private _router: Router
  ) {}

  async do(): Promise<void> {
    const brand = await snapshot(
      this._organisation.brand$.pipe(filterUndefined())
    );

    if (!brand) {
      throw new Error(`No Brand Selected`);
    }

    const patient: WithRef<IPatient> | undefined = this._patientScope.findModel(
      this._route.snapshot
    );

    const path = ['/', brand.slug, 'appointments', 'create'];
    const queryParams = {
      patientUid: patient?.ref.id,
    };
    await this._router.navigate(path, { queryParams });
  }
}
