import { isObject } from '@principle-theorem/shared';
import { isString } from 'lodash';

interface IBasePairTerminalResponse {
  status: string;
  message: string;
}

export function isBasePairTerminalResponse(
  data: unknown
): data is IBasePairTerminalResponse {
  return isObject(data) && isString(data.message) && isString(data.status);
}

export interface IPairTerminalSuccess extends IBasePairTerminalResponse {
  status: 'success';
  integrationKey: string;
}

export interface IPairTerminalFailure extends IBasePairTerminalResponse {
  status: 'failure';
}

export interface IPairTerminalInProgress extends IBasePairTerminalResponse {
  status: 'inProgress';
}

export function isPairTerminalSuccess(
  data: unknown
): data is IPairTerminalSuccess {
  return (
    isObject(data) &&
    isBasePairTerminalResponse(data) &&
    data.status === 'success' &&
    isString(data.integrationKey)
  );
}

export function isPairTerminalFail(
  data: unknown
): data is IPairTerminalSuccess {
  return (
    isObject(data) &&
    isBasePairTerminalResponse(data) &&
    data.status === 'failure'
  );
}

export type PairTerminalResponse =
  | IPairTerminalSuccess
  | IPairTerminalFailure
  | IPairTerminalInProgress;
