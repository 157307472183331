import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DialogPresets,
  InputSearchFilter,
  toSearchStream,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { type IRole } from '@principle-theorem/principle-core/interfaces';
import { type CollectionReference } from '@principle-theorem/shared';
import {
  addDoc,
  multiSortBy$,
  nameSorter,
  snapshot,
  undeletedQuery,
  watch$,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateRoleDialogComponent } from '../create-role-dialog/create-role-dialog.component';

@Component({
    selector: 'pr-roles',
    templateUrl: './roles.component.html',
    styleUrls: ['./roles.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RolesComponent {
  roleCollection$ = new ReplaySubject<CollectionReference<IRole>>(1);
  trackByRole = TrackByFunctions.ref<WithRef<IRole>>();
  searchCtrl: TypedFormControl<string> = new TypedFormControl<string>('');
  roleFilter: InputSearchFilter<WithRef<IRole>>;
  emptyState$: Observable<boolean>;

  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    const roles$ = watch$(
      this.roleCollection$.pipe(map((ref) => undeletedQuery(ref)))
    ).pipe(multiSortBy$(nameSorter()));

    this.roleFilter = new InputSearchFilter<WithRef<IRole>>(
      roles$,
      toSearchStream(this.searchCtrl),
      ['name']
    );
    this.emptyState$ = roles$.pipe(map((roles) => !roles.length));
  }

  @Input()
  set roleCollection(roleCollection: CollectionReference<IRole>) {
    if (roleCollection) {
      this.roleCollection$.next(roleCollection);
    }
  }

  async addRole(): Promise<void> {
    const data: Pick<IRole, 'name'> = { name: this.searchCtrl.value };
    const role = await this._dialog
      .open<CreateRoleDialogComponent, Pick<IRole, 'name'>, IRole>(
        CreateRoleDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!role) {
      return;
    }
    const roleCol = await snapshot(this.roleCollection$);
    const result = await addDoc(roleCol, role);
    await this._router.navigate(['./', result.id], { relativeTo: this._route });
  }
}
