import {
  initRawSchema,
  RawSchema,
  toMentionContent,
  toParagraphContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  INotification,
  INotificationContextRenderer,
} from '@principle-theorem/notifications';
import {
  NotificationType,
  IBookedAppointmentResources,
  isAppointmentResource,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  CALENDAR_TIME_FORMAT,
  IReffable,
  toMoment,
} from '@principle-theorem/shared';
import { Event } from '../../event/event';
import { toMention } from '../../mention/mention';
import { stafferIsOfRole } from './notification-helpers';

export class StafferBookedAppointmentContextRenderer
  implements
    INotificationContextRenderer<
      RawSchema,
      NotificationType,
      IBookedAppointmentResources
    >
{
  canRender(notification: INotification): boolean {
    return (
      notification.type === String(NotificationType.StafferBookedAppointment)
    );
  }

  isResource(data: unknown): data is IBookedAppointmentResources {
    return isAppointmentResource<IBookedAppointmentResources>(data);
  }

  render(
    data: IBookedAppointmentResources,
    currentStaffer?: IReffable
  ): RawSchema {
    const isActor: boolean = stafferIsOfRole(currentStaffer, data.interactor);
    const event = Event.init(data.appointment.event);
    const startTime: string = toMoment(event.from).calendar(
      undefined,
      CALENDAR_TIME_FORMAT
    );

    const patientMention = toMention(data.patient, MentionResourceType.Patient);
    const appointmentMention = toMention(
      { name: startTime, ref: data.appointment.ref },
      MentionResourceType.Appointment
    );

    if (isActor) {
      return initRawSchema([
        toParagraphContent([
          toTextContent(`You booked `),
          toMentionContent(patientMention),
          toTextContent(' in for '),
          toMentionContent(appointmentMention),
        ]),
      ]);
    }

    if (data.interactor) {
      const interactorMention = toMention(
        data.interactor,
        MentionResourceType.Staffer
      );
      return initRawSchema([
        toParagraphContent([
          toMentionContent(interactorMention),
          toTextContent(` booked `),
          toMentionContent(patientMention),
          toTextContent(' in for '),
          toMentionContent(appointmentMention),
        ]),
      ]);
    }

    return initRawSchema([
      toParagraphContent([
        toTextContent(`System booked `),
        toMentionContent(patientMention),
        toTextContent(' in for '),
        toMentionContent(appointmentMention),
      ]),
    ]);
  }
}
