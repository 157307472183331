import { rand } from '@ngneat/falso';
import { interval, type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

const messages = [
  'Reticulating splines...',
  'Generating witty dialog...',
  'Swapping time and space...',
  'Spinning violently around the y-axis...',
  'Tokenizing real life...',
  'Bending the spoon...',
  'Filtering morale...',
  `Don't think of purple hippos...`,
  'We need a new fuse...',
  'Last call for the data bus! All aboard!',
  '(Pay no attention to the man behind the curtain)',
  '...and enjoy the elevator music...',
  'The architects are still drafting',
  'The bits are breeding',
  `We're testing your patience`,
  'Checking the gravitational constant in your locale...',
  'Go ahead -- hold your breath!',
  `...at least you're not on hold...`,
  'My other loading screen is much faster.',
  `Testing on Timmy... We're going to need another Timmy.`,
  'Reconfoobling energymotron...',
  '(Insert quarter)',
  'Are we there yet?',
];

export function getFunnyLoadingMessages$(
  showForMs: number = 5000
): Observable<string> {
  return interval(showForMs).pipe(
    startWith(0),
    map(() => rand(messages))
  );
}
