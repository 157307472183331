import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import {
  Storage,
  UploadTask,
  UploadTaskSnapshot,
  ref,
  uploadBytesResumable,
} from '@angular/fire/storage';
import { multiMap, safeCombineLatest } from '@principle-theorem/shared';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { buildUploadFilePath } from '../media-manager';
import { TrackByFunctions } from '../track-by';

export interface IUploadStartedEvent {
  task: UploadTask;
  file: File;
  storagePath: string;
}

interface IFileData {
  path: string;
  file: File;
}

interface IUploadTask {
  upload: UploadTask;
  snapshot$: Observable<UploadTaskSnapshot | undefined>;
  percentage$: Observable<number | undefined>;
  fileData: IFileData;
}

@Component({
  selector: 'pt-file-upload-to-storage',
  templateUrl: './file-upload-to-storage.component.html',
  styleUrls: ['./file-upload-to-storage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadToStorageComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _fileUpload$ = new BehaviorSubject<IFileData[]>([]);
  @Input() path: string;
  @Output() uploadStarted = new EventEmitter<IUploadStartedEvent>();
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  uploaderId: string = 'uploader-' + uuid();
  trackByTask = TrackByFunctions.index<IUploadTask>();
  tasks$: Observable<IUploadTask[]>;
  isHovering: boolean;

  constructor(private _storage: Storage) {
    this.tasks$ = this._fileUpload$.pipe(
      multiMap((fileData) => {
        const upload = uploadBytesResumable(
          ref(this._storage, fileData.path),
          fileData.file
        );

        const percentage$ = new Observable<number>((observer) => {
          upload.on(
            'state_changed',
            (snapshot) => {
              const percentage =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              observer.next(percentage);
            },
            (error) => observer.error(error),
            () => observer.complete()
          );
        });

        const snapshot$ = new Observable<UploadTaskSnapshot>((observer) => {
          upload.on(
            'state_changed',
            (snapshot) => observer.next(snapshot),
            (error) => observer.error(error),
            () => observer.complete()
          );
        });

        return {
          upload,
          fileData,
          percentage$,
          snapshot$,
        };
      })
    );

    this.tasks$
      .pipe(
        filter((tasks) => tasks.length > 0),
        switchMap((tasks) =>
          safeCombineLatest(
            tasks.map((task) =>
              task.percentage$.pipe(filter((percentage) => percentage === 100))
            )
          )
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this._fileUpload$.next([]));

    this.tasks$.pipe(takeUntil(this._onDestroy$)).subscribe((tasks) =>
      tasks.map((task) =>
        this.uploadStarted.emit({
          task: task.upload,
          file: task.fileData.file,
          storagePath: task.fileData.path,
        })
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  toggleHover(event: boolean): void {
    this.isHovering = event;
  }

  startUpload(event: Event): void {
    const element = event.target as HTMLInputElement;
    if (!element.files) {
      return;
    }
    this.fileDropped(element.files);
    this.fileInput.nativeElement.value = '';
  }

  fileDropped(fileList: FileList): void {
    const files = Array.from(fileList).map((file) => ({
      file,
      path: buildUploadFilePath(this.path, file.name),
    }));

    this._fileUpload$.next(files);
  }

  isActive(snapshot: UploadTaskSnapshot): boolean {
    const isRunning: boolean = snapshot.state === 'running';
    const bytesRemaining: number =
      snapshot.totalBytes - snapshot.bytesTransferred;
    return isRunning && bytesRemaining > 0;
  }
}
