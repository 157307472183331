<ng-container *ngrxLet="suggestedPatients$ as suggestedPatients">
  <mat-form-field fxFlex [appearance]="appearance">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input
      matInput
      [placeholder]="placeholder"
      [formControl]="searchCtrl"
      [matAutocomplete]="auto"
      #autoComplete="matAutocompleteTrigger"
      type="text"
      name="patientSearch"
      autocomplete="off"
      (click)="autoComplete.openPanel()"
      (keyup.enter)="selectFirstOption()"
      [required]="isRequired$ | async"
    />
    <mat-hint *ngIf="showSuggestions$ | async">
      <span class="warn"
        >{{ suggestedPatients.length }} similar patients detected:
      </span>
      <a (click)="autoComplete.openPanel()">View suggestions</a>
    </mat-hint>
    <mat-hint *ngIf="!suggestedPatients.length && matHint">{{
      matHint
    }}</mat-hint>
    <button
      type="button"
      matIconSuffix
      mat-icon-button
      [disabled]="searchCtrl.disabled"
      (click)="clearUser()"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <ng-container *ngrxLet="filteredPatients$ as allPatients">
        <ng-container
          *ngIf="suggestedPatients.length; else noSuggestedPatients"
        >
          <mat-optgroup label="Suggested Patients">
            <mat-option
              *ngFor="let patient of suggestedPatients; trackBy: trackByPatient"
              [value]="patient"
              [disabled]="patient.status | map: isDisabledPatient"
            >
              <pr-patient-selector-item [patient]="patient" />
            </mat-option>
          </mat-optgroup>

          <mat-optgroup label="All Patients" *ngIf="allPatients.length">
            <mat-option
              *ngFor="let patient of allPatients; trackBy: trackByPatient"
              [value]="patient"
              [disabled]="patient.status | map: isDisabledPatient"
            >
              <pr-patient-selector-item [patient]="patient" />
            </mat-option>
          </mat-optgroup>
        </ng-container>

        <ng-template #noSuggestedPatients>
          @if (!allPatients.length && !disableAllPatients) {
            <mat-option disabled>
              <mat-icon>search</mat-icon>
              <span>Start typing to search patients...</span>
            </mat-option>
          }
          <mat-option
            *ngFor="let patient of allPatients; trackBy: trackByPatient"
            [value]="patient"
            [disabled]="patient.status | map: isDisabledPatient"
          >
            <pr-patient-selector-item [patient]="patient" />
          </mat-option>
        </ng-template>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</ng-container>
