import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { ProfileImageService } from '@principle-theorem/ng-shared';
import {
  Brand,
  ConditionConfiguration,
  FeeSchedule,
  OrganisationCache,
  Practice,
  Roster,
  Staffer,
} from '@principle-theorem/principle-core';
import {
  ICalendarEventSchedulesMap,
  IChartedItemConfiguration,
  IPatientMetadataDisplay,
  IPractice,
  isStaffer,
  type HasFeeSchedules,
  type IBrand,
  type ICalendarEventSchedule,
  type IFeeSchedule,
  type IStaffer,
  type ITag,
  type ITreatmentCategory,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  all$,
  filterUndefined,
  isChanged$,
  isRefChanged$,
  isSameRef,
  multiMap,
  multiSortBy$,
  multiSwitchMap,
  nameSorter,
  reduce2DArray,
  undeletedQuery,
  type DocumentReference,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { fromPairs } from 'lodash';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { OrganisationService } from '../organisation.service';

export enum TagType {
  Appointment = 'appointment',
  Event = 'event',
  Media = 'media',
  Patient = 'patient',
  PatientNote = 'patientNote',
}

interface ITypedTag extends WithRef<ITag> {
  type: TagType;
}

interface IStafferDetails {
  id: string;
  name: string;
  url?: string;
}

export interface IGlobalStoreState {
  stafferDetails: IStafferDetails[];
  managementStafferDetails: IStafferDetails[];
  staff: WithRef<IStaffer>[];
  principleStaff: WithRef<IStaffer>[];
  tags: ITypedTag[];
  treatmentCategories: WithRef<ITreatmentCategory>[];
  conditionConfigurations: WithRef<IChartedItemConfiguration>[];
  feeSchedules: WithRef<IFeeSchedule>[];
  rosterSchedules: ICalendarEventSchedulesMap;
  patientMetadataDisplays: WithRef<IPatientMetadataDisplay>[];
}

@Injectable({
  providedIn: 'root',
})
export class GlobalStoreService extends ComponentStore<IGlobalStoreState> {
  private _organisation = inject(OrganisationService);
  private _profileImage = inject(ProfileImageService);
  readonly stafferDetails$ = this.select((state) =>
    [state.stafferDetails, state.managementStafferDetails].flat()
  );
  readonly staff$ = this.select((state) => state.staff);
  readonly principleStaff$ = this.select((state) => state.principleStaff);
  readonly tags$ = this.select((state) => state.tags);
  readonly treatmentCategories$ = this.select(
    (state) => state.treatmentCategories
  );
  readonly feeSchedules$ = this.select((state) => state.feeSchedules);
  readonly rosterSchedules$ = this.select((state) => state.rosterSchedules);
  readonly patientMetadataDisplays$ = this.select(
    (state) => state.patientMetadataDisplays
  );
  readonly conditionConfigurations$ = this.select(
    (state) => state.conditionConfigurations
  );

  readonly loadStafferDetails = this.effect(
    (staff$: Observable<WithRef<IStaffer>[]>) => {
      return staff$.pipe(
        tapResponse(
          (staff) => this.patchState({ staff }),
          // eslint-disable-next-line no-console
          console.error
        ),
        multiSwitchMap((staffer) =>
          Staffer.user$(staffer).pipe(
            switchMap((user) =>
              this._profileImage.getUserProfileImage$(user).pipe(
                map((url) => ({
                  id: staffer.ref.id,
                  url,
                  name: user.name,
                }))
              )
            )
          )
        ),
        tapResponse(
          (stafferDetails) => this.patchState({ stafferDetails }),
          // eslint-disable-next-line no-console
          console.error
        )
      );
    }
  );

  readonly loadManagementStafferDetails = this.effect(
    (staff$: Observable<WithRef<IStaffer>[]>) => {
      return staff$.pipe(
        tapResponse(
          (staff) => this.patchState({ staff }),
          // eslint-disable-next-line no-console
          console.error
        ),
        multiSwitchMap((staffer) =>
          Staffer.user$(staffer).pipe(
            switchMap((user) =>
              this._profileImage.getUserProfileImage$(user).pipe(
                map((url) => ({
                  id: staffer.ref.id,
                  url,
                  name: user.name,
                }))
              )
            )
          )
        ),
        tapResponse(
          (managementStafferDetails) =>
            this.patchState({ managementStafferDetails }),
          // eslint-disable-next-line no-console
          console.error
        )
      );
    }
  );

  readonly loadStafferRosters = this.effect(
    (staff$: Observable<WithRef<IStaffer>[]>) => {
      return staff$.pipe(
        multiSwitchMap((staffer) =>
          all$(undeletedQuery(Roster.col({ ref: staffer.ref }))).pipe(
            map((schedules) => [staffer.ref.id, schedules])
          )
        ),
        map((rosterSchedules) => fromPairs(rosterSchedules)),
        tapResponse(
          (rosterSchedules) => this.patchState({ rosterSchedules }),
          // eslint-disable-next-line no-console
          console.error
        )
      );
    }
  );

  readonly loadTags = this.effect(
    (
      data$: Observable<{
        brand: WithRef<IBrand>;
        practice: WithRef<IPractice>;
      }>
    ) => {
      const brandTags$ = data$.pipe(
        map((records) => records.brand),
        isRefChanged$(),
        switchMap((brand) =>
          combineLatest([
            Brand.appointmentTags$(brand).pipe(
              multiMap((tag) => ({
                ...tag,
                type: TagType.Appointment,
              }))
            ),
            Brand.eventTags$(brand).pipe(
              multiMap((tag) => ({
                ...tag,
                type: TagType.Event,
              }))
            ),
            Brand.patientTags$(brand).pipe(
              multiMap((tag) => ({
                ...tag,
                type: TagType.Patient,
              }))
            ),
            Brand.patientNoteTags$(brand).pipe(
              multiMap((tag) => ({
                ...tag,
                type: TagType.PatientNote,
              }))
            ),
          ])
        ),
        reduce2DArray()
      );

      const practiceTags$ = data$.pipe(
        map((records) => records.practice),
        isRefChanged$(),
        switchMap((practice) =>
          Practice.mediaTags$(practice).pipe(
            multiMap((tag) => ({
              ...tag,
              type: TagType.Media,
            }))
          )
        )
      );

      return combineLatest([brandTags$, practiceTags$]).pipe(
        reduce2DArray(),
        multiSortBy$(nameSorter()),
        tapResponse(
          (tags) => this.patchState({ tags }),
          // eslint-disable-next-line no-console
          console.error
        )
      );
    }
  );

  readonly loadTreatmentCategories = this.effect(
    (brand$: Observable<WithRef<IBrand>>) => {
      return brand$.pipe(
        switchMap((brand) => Brand.treatmentCategories$(brand)),
        tapResponse(
          (treatmentCategories) => this.patchState({ treatmentCategories }),
          // eslint-disable-next-line no-console
          console.error
        )
      );
    }
  );

  readonly loadConditionConfigurations = this.effect(
    (brand$: Observable<WithRef<IBrand>>) => {
      return brand$.pipe(
        switchMap((brand) => ConditionConfiguration.all$(brand)),
        tapResponse(
          (conditionConfigurations) =>
            this.patchState({ conditionConfigurations }),
          // eslint-disable-next-line no-console
          console.error
        )
      );
    }
  );

  readonly loadFeeSchedules = this.effect(
    (sources$: Observable<HasFeeSchedules[]>) =>
      sources$.pipe(
        FeeSchedule.resolveSchedules$(),
        tapResponse(
          (feeSchedules) => this.patchState({ feeSchedules }),
          // eslint-disable-next-line no-console
          console.error
        )
      )
  );

  readonly loadPatientMetadataDisplays = this.effect(
    (brand$: Observable<WithRef<IBrand>>) => {
      return brand$.pipe(
        switchMap((brand) => Brand.patientMetadataDisplays$(brand)),
        tapResponse(
          (patientMetadataDisplays) =>
            this.patchState({ patientMetadataDisplays }),
          // eslint-disable-next-line no-console
          console.error
        )
      );
    }
  );

  constructor() {
    super({
      stafferDetails: [],
      managementStafferDetails: [],
      staff: [],
      principleStaff: [],
      tags: [],
      treatmentCategories: [],
      conditionConfigurations: [],
      feeSchedules: [],
      rosterSchedules: {},
      patientMetadataDisplays: [],
    });

    this.loadManagementStafferDetails(this._organisation.managementStaff$);
    this.loadStafferDetails(this._organisation.staff$);
    const allStaff$ = this._organisation.brand$.pipe(
      switchMap((brand) => (brand ? Brand.staff$(brand, true) : of([])))
    );
    this.loadStafferDetails(allStaff$);
    this.loadStafferRosters(
      this._organisation.practicePractitioners$.pipe(isChanged$(isSameRef))
    );
    this.loadTags(
      combineLatest([
        this._organisation.brand$.pipe(filterUndefined()),
        this._organisation.practice$.pipe(filterUndefined()),
      ]).pipe(map(([brand, practice]) => ({ brand, practice })))
    );
    this.loadTreatmentCategories(
      this._organisation.brand$.pipe(filterUndefined())
    );
    this.loadConditionConfigurations(
      this._organisation.brand$.pipe(filterUndefined())
    );
    this.loadFeeSchedules(
      combineLatest([
        this._organisation.staffer$.pipe(filterUndefined()),
        this._organisation.practice$.pipe(filterUndefined()),
        this._organisation.brand$.pipe(filterUndefined()),
        this._organisation.organisation$.pipe(filterUndefined()),
      ])
    );
    this.loadPatientMetadataDisplays(
      this._organisation.brand$.pipe(filterUndefined())
    );
  }

  getStaffer$(
    stafferRef: DocumentReference<IStaffer>
  ): Observable<WithRef<IStaffer>> {
    return this.select(this.staff$, (staff) =>
      staff.find((staffer) => isSameRef(staffer, stafferRef))
    ).pipe(
      switchMap((staffer) => {
        if (staffer) {
          return of(staffer);
        }
        return OrganisationCache.staff.get.doc$(stafferRef);
      })
    );
  }

  getStafferImage$(
    staffer: IReffable<IStaffer>
  ): Observable<string | undefined> {
    return this.select(
      this.stafferDetails$,
      (stafferDetails) =>
        stafferDetails.find(
          (stafferDetail) => stafferDetail.id === staffer.ref.id
        )?.url
    );
  }

  getStafferName$(
    staffer: IReffable<IStaffer> | WithRef<IStaffer>
  ): Observable<string> {
    return this.select(this.stafferDetails$, (stafferDetails) => {
      const stafferName = stafferDetails.find(
        (stafferDetail) => stafferDetail.id === staffer.ref.id
      )?.name;

      if (stafferName) {
        return stafferName;
      }

      if (isStaffer(staffer)) {
        return staffer.user.name;
      }

      return '';
    });
  }

  getStafferRosterSchedules$(
    staffer: IReffable<IStaffer>
  ): Observable<WithRef<ICalendarEventSchedule>[]> {
    return this.select(
      this.rosterSchedules$,
      (rosterSchedules) => rosterSchedules[staffer.ref.id] ?? []
    );
  }

  getTag$(
    tagRef: DocumentReference<ITag>
  ): Observable<WithRef<ITag> | undefined> {
    return this.select(this.tags$, (tags) =>
      tags.find((tag) => isSameRef(tag, tagRef))
    ).pipe(
      switchMap((tag) => {
        if (tag) {
          return of(tag);
        }
        return Firestore.getDoc(tagRef);
      })
    );
  }

  getTagsByType$(type: TagType): Observable<WithRef<ITag>[]> {
    return this.select(this.tags$, (tags) =>
      tags.filter((tag) => tag.type === type)
    );
  }

  getTreatmentCategory$(
    categoryRef: DocumentReference<ITreatmentCategory>
  ): Observable<WithRef<ITreatmentCategory> | undefined> {
    return this.select(this.treatmentCategories$, (treatmentCategories) =>
      treatmentCategories.find((category) => isSameRef(category, categoryRef))
    );
  }

  getConditionConfiguration$(
    configRef: DocumentReference<IChartedItemConfiguration>
  ): Observable<WithRef<IChartedItemConfiguration> | undefined> {
    return this.select(this.conditionConfigurations$, (configs) =>
      configs.find((config) => isSameRef(config, configRef))
    ).pipe(
      switchMap((config) => {
        if (config) {
          return of(config);
        }
        return Firestore.getDoc(configRef);
      })
    );
  }

  getFeeSchedule$(
    feeScheduleRef: DocumentReference<IFeeSchedule>
  ): Observable<WithRef<IFeeSchedule>> {
    return this.select(this.feeSchedules$, (feeSchedules) =>
      feeSchedules.find((schedule) => isSameRef(schedule, feeScheduleRef))
    ).pipe(
      switchMap((schedule) => {
        if (schedule) {
          return of(schedule);
        }
        return Firestore.getDoc(feeScheduleRef);
      })
    );
  }
}
