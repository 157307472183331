import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppointmentManager } from '@principle-theorem/ng-appointment/store';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  ChecklistItemAction,
  ChecklistType,
  FormStatus,
  type IAppointment,
  type IChecklistItem,
  type IPatient,
  type IStaffer,
  type ISubmittedFormHistory,
  PatientForm,
  PatientRelationshipType,
  AppointmentStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  Appointment,
  ChecklistItem,
  OrganisationCache,
  Patient,
} from '@principle-theorem/principle-core';
import {
  doc$,
  filterUndefined,
  multiFilter,
  patchDoc,
  snapshot,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export interface IInboundChecklistData {
  appointment: WithRef<IAppointment>;
  patient: WithRef<IPatient>;
}

@Component({
  selector: 'pr-inbound-checklist',
  templateUrl: './inbound-checklist.component.html',
  styleUrls: ['./inbound-checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboundChecklistComponent {
  trackByItem = TrackByFunctions.ref<IChecklistItem>();
  patient: WithRef<IPatient>;
  practitioner: INamedDocument<IStaffer>;
  appointment$: Observable<WithRef<IAppointment>>;
  checklist$: Observable<WithRef<IChecklistItem>[]>;
  submittedForms$: Observable<WithRef<ISubmittedFormHistory>[]>;
  allComplete$: Observable<boolean>;
  patientForms = PatientForm;

  constructor(
    private _dialogRef: MatDialogRef<
      InboundChecklistComponent,
      AppointmentStatus
    >,
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService,
    @Inject(MAT_DIALOG_DATA) public data: IInboundChecklistData
  ) {
    this.patient = this.data.patient;
    this.practitioner = this.data.appointment.practitioner;
    this.appointment$ = doc$(this.data.appointment.ref);
    this.checklist$ = this.appointment$.pipe(
      switchMap((appointment) =>
        Appointment.checklistItems$(appointment, ChecklistType.Pre)
      )
    );

    this.submittedForms$ = OrganisationCache.patients
      .doc$(this.patient.ref)
      .pipe(
        switchMap((patient) =>
          Patient.withPatientRelationships$(
            patient,
            [PatientRelationshipType.DuplicatePatient],
            Patient.forms$
          )
        ),
        multiFilter(({ status }) => status === FormStatus.Submitted)
      );

    this.allComplete$ = this.checklist$.pipe(
      map((items) => items.every((item) => item.done))
    );
  }

  isArrived(appointment: WithRef<IAppointment>): boolean {
    return Appointment.isArrived(appointment);
  }

  async markArrival(): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    const staffer: WithRef<IStaffer> = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    await AppointmentManager.markArrived(staffer, appointment);
    this._snackBar.open('Patient marked Arrived, checklist outstanding');
  }

  async checkIn(): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    const staffer: WithRef<IStaffer> = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    await AppointmentManager.markCheckedIn(staffer, appointment);
    this._snackBar.open('Patient checked in');
    this._dialogRef.close(AppointmentStatus.CheckedIn);
  }

  doAction($event: Event, item: IChecklistItem): void {
    $event.stopPropagation();
    switch (item.action) {
      case ChecklistItemAction.Print:
        ChecklistItem.print(item);
        break;
      default:
        break;
    }
  }

  async updateSelected(checklistItem: WithRef<IChecklistItem>): Promise<void> {
    await patchDoc(checklistItem.ref, { done: !checklistItem.done });
  }
}
