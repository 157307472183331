import { IDataChange } from '@principle-theorem/principle-core/interfaces';
import { isDocRef, IReffable, isSameRef } from '@principle-theorem/shared';
import { DocumentReference } from '@principle-theorem/shared';

/**
 * Takes a current and new list of items and returns which items in the
 * new list are not in the current items list.
 *
 * @export
 * @template T
 * @param {T[]} currentItems
 * @param {T[]} newItems
 * @param {IsSameFn<T>} [isSameFn=(currentItem: T, newItem: T): boolean => {
 *     return currentItem === newItem;
 *   }]
 * @returns {T[]}
 */
export function getUnique<T>(
  currentItems: T[],
  newItems: T[],
  isSameFn: IsSameFn<T> = (currentItem: T, newItem: T): boolean => {
    return currentItem === newItem;
  }
): T[] {
  return newItems.filter((currentItem: T): boolean => {
    const alreadyExists: boolean = currentItems.some(
      (comparisonItem: T): boolean => {
        return isSameFn(currentItem, comparisonItem);
      }
    );
    return !alreadyExists;
  });
}

/**
 * Takes a current and new list of items and returns which items are in both lists
 *
 * @export
 * @template T
 * @param {T[]} currentItems
 * @param {T[]} newItems
 * @param {IsSameFn<T>} [isSameFn=(currentItem: T, newItem: T): boolean => {
 *     return currentItem === newItem;
 *   }]
 * @returns {T[]}
 */
export function getCommon<T>(
  currentItems: T[],
  newItems: T[],
  isSameFn: IsSameFn<T> = (currentItem: T, newItem: T): boolean => {
    return currentItem === newItem;
  }
): T[] {
  return currentItems.filter((currentItem: T): boolean => {
    const alreadyExists: boolean = newItems.some(
      (comparisonItem: T): boolean => {
        return isSameFn(currentItem, comparisonItem);
      }
    );
    return alreadyExists;
  });
}

/**
 * Get a list of items where the current items are updated by the new items
 *
 * @export
 * @template T
 * @param {T[]} currentItems
 * @param {T[]} newItems
 * @param {IsSameFn<T>} [isSameFn=(currentItem: T, newItem: T): boolean => {
 *     return currentItem === newItem;
 *   }]
 * @returns {T[]}
 */
export function upsertCommon<T>(
  currentItems: T[],
  newItems: T[],
  isSameFn: IsSameFn<T> = (currentItem: T, newItem: T): boolean => {
    return currentItem === newItem;
  }
): T[] {
  return currentItems.map((currentItem: T): T => {
    const newItem = newItems.find((comparisonItem: T) =>
      isSameFn(currentItem, comparisonItem)
    );
    return newItem ? { ...currentItem, ...newItem } : currentItem;
  });
}

/**
 * Takes a current and new list of items and returns all common and new items,
 * essentially discarding any items which are not in the new list.
 *
 * @export
 * @template T
 * @param {T[]} currentItems
 * @param {T[]} newItems
 * @param {IsSameFn<T>} [isSameFn=(currentItem: T, newItem: T): boolean => {
 *     return currentItem === newItem;
 *   }]
 * @returns {T[]}
 */
export function upsertMerge<T>(
  currentItems: T[],
  newItems: T[],
  isSameFn: IsSameFn<T> = (currentItem: T, newItem: T): boolean => {
    return currentItem === newItem;
  }
): T[] {
  newItems = removeDuplicates<T>(newItems, isSameFn);
  const addedItems: T[] = getUnique<T>(currentItems, newItems, isSameFn);
  const remainingSummaries: T[] = getCommon<T>(
    currentItems,
    newItems,
    isSameFn
  );
  return [...remainingSummaries, ...addedItems];
}

/**
 * Same behaviour as upsertMerge but with a comparison function which works with
 * objects that are compared by their Firestore Document reference.
 *
 * @export
 * @template T
 * @param {T[]} currentItems
 * @param {T[]} newItems
 * @returns {T[]}
 */
export function upsertMergeDocRefs<T extends IReffable>(
  currentItems: T[],
  newItems: T[]
): T[] {
  const isSameFn: IsSameFn<T> = (currentItem: T, newItem: T): boolean => {
    return isSameRef(currentItem, newItem);
  };
  return upsertMerge<T>(currentItems, newItems, isSameFn);
}

/**
 * Removes duplicate items from an array using the given comparison function
 *
 * @export
 * @template T
 * @param {T[]} items
 * @param {IsSameFn<T>} [isSameFn=(currentItem: T, newItem: T): boolean => {
 *     return currentItem === newItem;
 *   }]
 * @returns {T[]}
 */
export function removeDuplicates<T>(
  items: T[],
  isSameFn: IsSameFn<T> = (currentItem: T, newItem: T): boolean => {
    return currentItem === newItem;
  }
): T[] {
  const filteredItems: T[] = [];

  items.map((item: T): void => {
    const inFilteredItems: boolean = filteredItems.some(
      (comparisonItem: T): boolean => {
        return isSameFn(item, comparisonItem);
      }
    );

    if (inFilteredItems) {
      return;
    }

    filteredItems.push(item);
  });

  return filteredItems;
}

export type IsSameFn<T> = (currentItem: T, newItem: T) => boolean;

export function getChangedItems<T>(
  itemsBefore: T[],
  itemsAfter: T[],
  isSameItem?: keyof T | IsSameFn<T>,
  isChanged?: keyof T | IsSameFn<T>
): IDataChange<T>[] {
  const sameFn: IsSameFn<T> = (before: T, after: T): boolean => {
    if (!isSameItem) {
      return before === after;
    }
    if (typeof isSameItem === 'function') {
      return isSameItem(before, after);
    }
    return before[isSameItem] === after[isSameItem];
  };

  const changedFn: IsSameFn<T> = (before: T, after: T): boolean => {
    if (!isChanged) {
      return before !== after;
    }
    if (typeof isChanged === 'function') {
      return isChanged(before, after);
    }
    return before[isChanged] === after[isChanged];
  };

  const changedItems: IDataChange<T>[] = [];
  itemsBefore.map((itemBefore: T) => {
    const changedItem: T | undefined = itemsAfter.find((itemAfter: T) => {
      if (!sameFn(itemBefore, itemAfter)) {
        return false;
      }

      if (!changedFn(itemBefore, itemAfter)) {
        return false;
      }

      return true;
    });

    if (!changedItem) {
      return;
    }

    changedItems.push({
      before: itemBefore,
      after: changedItem,
    });
  });

  return changedItems;
}

export function MockCompareFn<T>(
  compareKey?: keyof T,
  isEqualComparison: boolean = true
): IsSameFn<T> {
  if (!compareKey) {
    return (before: T, after: T): boolean => {
      if (isEqualComparison) {
        return before === after;
      }
      return before !== after;
    };
  }
  return (before: T, after: T): boolean => {
    if (isEqualComparison) {
      if (isDocRef(before[compareKey]) && isDocRef(after[compareKey])) {
        const beforeRef = before[compareKey] as unknown as DocumentReference;
        const afterRef = after[compareKey] as unknown as DocumentReference;
        return beforeRef.path === afterRef.path;
      }
      return before[compareKey] === after[compareKey];
    }
    if (isDocRef(before[compareKey]) && isDocRef(after[compareKey])) {
      const beforeRef = before[compareKey] as unknown as DocumentReference;
      const afterRef = after[compareKey] as unknown as DocumentReference;
      return beforeRef.path !== afterRef.path;
    }
    return before[compareKey] !== after[compareKey];
  };
}
