import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { RecurrenceDialogComponent } from './recurrence-dialog/recurrence-dialog.component';
import { RecurrenceFormComponent } from './recurrence-form/recurrence-form.component';
import { RecurringTaskConfigurationFormComponent } from './recurring-task-configuration-form/recurring-task-configuration-form.component';
import { RecurringTaskConfirmDeleteComponent } from './recurring-task-confirm-delete/recurring-task-confirm-delete.component';
import { RecurringTaskDueDateComponent } from './recurring-task-due-date/recurring-task-due-date.component';
import { RecurringTaskFormDialogComponent } from './recurring-task-form-dialog/recurring-task-form-dialog.component';
import { TaskAssigneeComponent } from './task-assignee/task-assignee.component';
import { TaskEditComponent } from './task-edit/task-edit.component';
import { TaskFormComponent } from './task-form/task-form.component';
import { TaskInteractionsDialogComponent } from './task-interactions-dialog/task-interactions-dialog.component';
import { TaskInteractionsComponent } from './task-interactions/task-interactions.component';
import { TaskListModule } from './task-list/task-list.module';
import { TaskPriorityComponent } from './task-priority/task-priority.component';

@NgModule({
  imports: [
    CommonModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    NgInteractionsModule,
    NgMaterialModule,
    ReactiveFormsModule,
    TaskListModule,
  ],
  declarations: [
    TaskFormComponent,
    TaskPriorityComponent,
    TaskInteractionsComponent,
    TaskInteractionsDialogComponent,
    RecurringTaskConfigurationFormComponent,
    RecurringTaskConfirmDeleteComponent,
    TaskAssigneeComponent,
    TaskEditComponent,
    RecurringTaskDueDateComponent,
    RecurringTaskFormDialogComponent,
    RecurrenceDialogComponent,
    RecurrenceFormComponent,
  ],
  exports: [
    TaskFormComponent,
    TaskPriorityComponent,
    TaskInteractionsComponent,
    TaskInteractionsDialogComponent,
    RecurrenceFormComponent,
    TaskListModule,
  ],
})
export class ComponentsModule {}
