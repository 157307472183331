export interface IPerioSettings {
  autoSwitchCells: boolean;
  switchPattern: SwitchPattern;
  switchDirection: SwitchDirection;
  tableStyle: TableStyle;
}

export enum PerioTableSide {
  Facial = 'facial',
  Palatal = 'palatal',
  Lingual = 'lingual', // Lingual is the same as Palatal and is only used as a display label
}

export enum SwitchDirection {
  Default = 'default',
  Reverse = 'reverse',
}

export enum SwitchPattern {
  Reset = 'reset', // Reset to the beginning of the row
  Zigzag = 'zigzag', // Reverse the direction when reaching the end of the row
  Quadrant = 'quadrant', // Switch to the next quadrant when reaching the end of the row
}

export enum TableStyle {
  Table = 'table',
  Borderless = 'borderless',
}

export const DEFAULT_PERIO_SETTINGS: IPerioSettings = {
  autoSwitchCells: true,
  switchPattern: SwitchPattern.Reset,
  switchDirection: SwitchDirection.Default,
  tableStyle: TableStyle.Table,
};
