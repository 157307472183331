import { InjectionToken } from '@angular/core';

export const MAPS_CONFIG: InjectionToken<IGoogleMapsConfig> =
  new InjectionToken('mapsConfig');

export interface IGoogleMapsConfig {
  apiKey: string;
  language: string;
  region: string;
}
