import { MeasureFormatter } from '@principle-theorem/principle-core/interfaces';
import { IAppointmentTreatmentEvent } from '@principle-theorem/reporting/interfaces';
import { BigQueryTable } from '../../big-query-tables';
import { GroupBy, IUnnestQuery } from '../../querying';
import { latestEventId } from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import { AppointmentDimensionMeasureFactory } from '../dimensions/appointment-dimension';
import {
  CanDoAllProperty,
  CanQueryByTimestampProperty,
  ComparableProperties,
  MeasureTransformMap,
} from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { QueryFactory } from '../query-factory';
import { BaseTreatmentStepEventFactMeasures } from './base-treatment-step-event';

export class AppointmentTreatmentFactMeasures
  extends BaseTreatmentStepEventFactMeasures
  implements
    MeasureTransformMap<
      ComparableProperties<
        Omit<IAppointmentTreatmentEvent, 'name' | 'status' | 'deleted'>
      >
    >
{
  id = 'appointmentTreatmentEvent';
  readonly name = 'Appointment Treatments';
  readonly table = BigQueryTable.TreatmentStepEvent;

  get latestEvent(): AppointmentTreatmentFactMeasures {
    const treatmentsUnnest: IUnnestQuery = {
      alias: 'treatment',
      property: 'treatments',
    };

    const measures = new AppointmentTreatmentFactMeasures();
    measures.id = latestEventId(measures);
    measures._query = QueryFactory.fromTable(measures.table)
      .override(measures._query)
      .latestEvent(measures.table, GroupBy.TreatmentStep)
      .mergeJoins(measures.treatmentStep.query.joins)
      .filterSoftDeleted(measures.treatmentStep.measureRef('deleted'))
      .unnest(treatmentsUnnest)
      .get();
    return measures;
  }

  get practitioner(): CanDoAllProperty {
    const propertyName = 'treatment.practitioner.name';
    const measure = this.measureRef(propertyName, true);
    return MeasurePropertyFactory.string(
      {
        id: propertyName,
        label: 'Practitioner Name',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'No Practitioner'
    );
  }

  get resolvedAt(): CanQueryByTimestampProperty {
    const measure = this.measureRef(
      MeasurePath.timestamp('treatment.resolvedAt'),
      true
    );
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('resolvedAt'),
        label: 'Treatment Performed Date',
        summary:
          'Date that the treatment was performed. This is useful for grouping treatments by day.',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      MeasureFormatter.Day
    );
  }

  get treatmentName(): CanDoAllProperty {
    const propertyName = 'treatment.config.name';
    const measure = this.measureRef(propertyName, true);
    return MeasurePropertyFactory.string(
      {
        id: propertyName,
        label: 'Treatment Name',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get price(): CanDoAllProperty {
    const propertyName = 'treatment.price';
    const measure = this.measureRef(propertyName, true);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Total Treatment Cost',
        summary: 'Total cost of the treatment',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get treatmentCategory(): CanDoAllProperty {
    const treatmentCategory = 'treatment.treatmentCategory';
    const labelMeasure = this.measureRef(`${treatmentCategory}.name`, true);
    const colourMeasure = this.measureRef(
      `${treatmentCategory}.colour.value`,
      true
    );
    const dataMeasure = this.measureRef(
      MeasurePath.docRef(`${treatmentCategory}.ref`),
      true
    );

    return AppointmentDimensionMeasureFactory.treatmentCategory(
      {
        id: this._pathWithPrefix('treatmentCategory'),
        label: 'Treatment Category',
        summary: 'The category of the treatment',
      },
      labelMeasure,
      colourMeasure,
      dataMeasure,
      this.buildQuery()
        .attributes([
          labelMeasure.attributePath,
          colourMeasure.attributePath,
          dataMeasure.attributePath,
        ])
        .get()
    );
  }

  get chartedSurfaces(): CanDoAllProperty {
    const propertyName = 'treatment.chartedSurfaceSummary';
    const measure = this.measureRef(propertyName, true);
    return MeasurePropertyFactory.array(
      {
        id: propertyName,
        label: 'Charted Surfaces',
        summary: 'The charted surfaces associated with the treatment',
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      measure.factPropertyPath,
      (fact) => fact as string[]
    );
  }

  get serviceCodes(): CanDoAllProperty {
    const propertyName = 'treatment.serviceCodeSummary';
    const measure = this.measureRef(propertyName, true);
    return MeasurePropertyFactory.array(
      {
        id: propertyName,
        label: 'Service Codes',
        summary: 'The service codes associated with the treatment',
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'code'
    );
  }
}
