import { Component } from '@angular/core';
import {
  type CanBeChartedProperty,
  FactTables,
  GreaterThanMeasureFilter,
  type ICanGroupMeasuresProperty,
  LessThanMeasureFilter,
} from '@principle-theorem/reporting';
import { BAR_CHART_OPTIONS } from '../../../models/report/charts/bar-chart';
import { type ChartBuilder } from '../../../models/report/charts/chart-builder';
import { type IChartCard } from '../../../models/report/charts/chart-card';
import { MeasureDataChartComponent } from '../../core/measure-data-chart-component';
import { CustomChartType } from '@principle-theorem/principle-core/interfaces';

@Component({
    selector: 'pr-practitioner-patients',
    templateUrl: './practitioner-patients.component.html',
    styleUrls: ['./practitioner-patients.component.sass'],
    standalone: false
})
export class PractitionerPatientsComponent extends MeasureDataChartComponent {
  tableChartCard: IChartCard;
  newAndExistingChartBuilder: ChartBuilder;
  ftaAndUtaChartBuilder: ChartBuilder;

  _redraw(): void {
    this._setNewAndExistingChartBuilder(
      [
        // 'existingPatients',
        FactTables.appointmentEvent.existingPatient.reduceByCount(),
        // 'newPatients',
        FactTables.appointmentEvent.newPatient.reduceByCount(),
      ],
      FactTables.appointmentEvent.practitioner.name
    );
    this._setFtaAndUtaChartBuilder(
      [
        FactTables.appointmentEvent
          .scopeBy(
            FactTables.appointmentEvent.timeUntilAppointment
              .filterBy(new LessThanMeasureFilter(60))
              .filterBy(new GreaterThanMeasureFilter(5))
          )
          .scopeBy(FactTables.appointmentEvent.cancelled)
          .count.setLabel('FTAs'),
        FactTables.appointmentEvent
          .scopeBy(
            FactTables.appointmentEvent.timeUntilAppointment.filterBy(
              new LessThanMeasureFilter(60)
            )
          )
          .scopeBy(FactTables.appointmentEvent.cancelled)
          .count.setLabel('UTAs'),
      ],
      FactTables.appointmentEvent.practitioner.name
    );
    this.tableChartCard = this._tableChartCard(
      [
        FactTables.appointmentEvent
          .scopeBy(
            FactTables.appointmentEvent.timeUntilAppointment.filterBy(
              new LessThanMeasureFilter(60)
            )
          )
          .scopeBy(FactTables.appointmentEvent.cancelled)
          .count.setLabel('FTAs'),
        FactTables.appointmentEvent
          .scopeBy(
            FactTables.appointmentEvent.timeUntilAppointment
              .filterBy(new LessThanMeasureFilter(720))
              .filterBy(new GreaterThanMeasureFilter(60, true))
          )
          .scopeBy(FactTables.appointmentEvent.cancelled)
          .count.setLabel('UTAs'),
        FactTables.appointmentEvent.newPatient.reduceByCount(),
        FactTables.appointmentEvent.existingPatient.reduceByCount(),
        FactTables.appointmentEvent.appointment.status
          .reduceByCount()
          .setLabel('Patients Seen'),
        FactTables.appointmentEvent.treatmentCost
          .reduceByAverage()
          .setLabel('Average Patient Spend'),
        FactTables.appointmentEvent.duration
          .reduceByAverage()
          .setLabel('Average Duration'),
        FactTables.appointmentEvent.waitTime
          .reduceByAverage()
          .setLabel('Average Wait Time'),
      ],
      FactTables.appointmentEvent.practitioner.name
    );
  }

  private _setNewAndExistingChartBuilder(
    measures: CanBeChartedProperty[],
    groupByDimension: ICanGroupMeasuresProperty
  ): void {
    const card: IChartCard = this.dataBuilder.toBarChart(
      this._chartMeasures(CustomChartType.Bar, measures, groupByDimension)
    );

    card.chartBuilder.addChartOptions(BAR_CHART_OPTIONS);
    card.chartBuilder.addChartOptions({
      bars: 'horizontal',
      isStacked: true,
      vAxis: { title: '' },
    });

    this.newAndExistingChartBuilder = card.chartBuilder;
  }

  private _setFtaAndUtaChartBuilder(
    measures: CanBeChartedProperty[],
    groupByDimension: ICanGroupMeasuresProperty
  ): void {
    const card: IChartCard = this.dataBuilder.toBarChart(
      this._chartMeasures(CustomChartType.Bar, measures, groupByDimension)
    );

    card.chartBuilder.addChartOptions(BAR_CHART_OPTIONS);
    card.chartBuilder.addChartOptions({
      bars: 'horizontal',
      vAxis: { title: '' },
    });

    this.ftaAndUtaChartBuilder = card.chartBuilder;
  }
}
