<div matTooltip="Day Settings" fxLayout="column" fxLayoutAlign="center center">
  <button
    class="menu-button !flex"
    mat-icon-button
    [matMenuTriggerFor]="moreMenu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
</div>

<mat-menu #moreMenu="matMenu">
  <ng-template matMenuContent>
    <button
      mat-menu-item
      *prHasPermission="rosterManagePermission"
      (click)="addToRoster()"
    >
      <mat-icon>event_available</mat-icon>
      <span>Add Practitioner to day</span>
    </button>
    <button mat-menu-item (click)="closePractice()">
      <mat-icon>event_busy</mat-icon>
      <span>Close Practice for day</span>
    </button>
  </ng-template>
</mat-menu>
