import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard } from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { CONTACT_DESTINATION_ENTITY } from '../../../destination/entities/contact';
import { convertNullToUndefinedFn } from '../../../source/source-helpers';

export const CONTACT_RESOURCE_TYPE = 'contacts';

export const CONTACT_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Contacts List',
    description: '',
    idPrefix: CONTACT_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface IExactContact {
  id: string;
  name: string;
  is_referral_source: boolean;
  is_specialist: boolean;
  is_doctor: boolean;
  is_gdp: boolean;
  address_1?: string;
  address_2?: string;
  suburb?: string;
  city?: string;
  state?: string;
  post_code?: number;
  homephone?: string;
  workphone?: string;
  mobile?: string;
  email?: string;
  note?: string;
}

function isExactContact(item: unknown): item is IExactContact {
  return TypeGuard.interface<IExactContact>({
    id: isString,
    name: isString,
    is_referral_source: isBoolean,
    is_specialist: isBoolean,
    is_doctor: isBoolean,
    is_gdp: isBoolean,
    address_1: TypeGuard.undefinedOr(isString),
    address_2: TypeGuard.undefinedOr(isString),
    suburb: TypeGuard.undefinedOr(isString),
    city: TypeGuard.undefinedOr(isString),
    state: TypeGuard.undefinedOr(isString),
    post_code: TypeGuard.undefinedOr(isNumber),
    homephone: TypeGuard.undefinedOr(isString),
    workphone: TypeGuard.undefinedOr(isString),
    mobile: TypeGuard.undefinedOr(isString),
    email: TypeGuard.undefinedOr(isString),
    note: TypeGuard.undefinedOr(isString),
  })(item);
}

export interface IExactContactFilters {
  id: string;
}

const CONTACT_SOURCE_QUERY = `
SELECT
  id::TEXT,
  NULLIF(name, '') AS name,
  isreferralsource AS is_referral_source,
  isspecialist AS is_specialist,
  isdoctor AS is_doctor,
  isgdp AS is_gdp,
  NULLIF(convaddress_address_address1, '') AS address_1,
  NULLIF(convaddress_address_address2, '') AS address_2,
  NULLIF(convaddress_address_suburb, '') AS suburb,
  NULLIF(convaddress_address_towncity, '') AS city,
  NULLIF(convaddress_address_areastateprovince, '') AS state,
  NULLIF(convaddress_address_postcodezipcode::TEXT, '') AS post_code,
  NULLIF(homephone::TEXT, '') AS homephone,
  NULLIF(workphone, '') AS workphone,
  NULLIF(mobile::TEXT, '') AS mobile,
  NULLIF(email, '') AS email,
  NULLIF(note, '') AS note
FROM
  convreferrer
WHERE name != ''
`;

export class ContactsSourceEntity extends BaseSourceEntity<
  IExactContact,
  object,
  IExactContactFilters
> {
  sourceEntity = CONTACT_SOURCE_ENTITY;
  entityResourceType = CONTACT_RESOURCE_TYPE;
  sourceQuery = CONTACT_SOURCE_QUERY;
  verifySourceFn = isExactContact;
  migrationDestinations = [CONTACT_DESTINATION_ENTITY.metadata.key];

  override transformDataFn = flow([
    convertNullToUndefinedFn(),
    (rows: IExactContact[]) =>
      rows.map((row) => ({
        ...row,
        id: row.id.replace(/\//g, '-'),
        post_code: row.post_code ? Number(row.post_code) : row.post_code,
      })),
  ]);

  translate(_data: IExactContact): object {
    return {};
  }

  getFilterData(data: IExactContact): IExactContactFilters {
    return {
      id: data.id,
    };
  }

  getSourceRecordId(data: IExactContact): string {
    return data.id;
  }

  getSourceLabel(data: IExactContact): string {
    return `${data.id} ${data.name}`;
  }
}
