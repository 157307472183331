import { MixedSchema } from '@principle-theorem/editor';
import { type DayOfWeek, type Timestamp } from '@principle-theorem/shared';

export enum WaitListUrgency {
  Low = 1,
  Medium = 2,
  High = 3,
}

export enum WaitListItemState {
  Approved = 'approved',
  Available = 'available',
  Unavailable = 'unavailable',
}

export interface IWaitListUrgency {
  name: string;
  value: WaitListUrgency;
}

export const WAIT_LIST_URGENCIES: IWaitListUrgency[] = [
  {
    name: WaitListUrgency[WaitListUrgency.Low],
    value: WaitListUrgency.Low,
  },
  {
    name: WaitListUrgency[WaitListUrgency.Medium],
    value: WaitListUrgency.Medium,
  },
  {
    name: WaitListUrgency[WaitListUrgency.High],
    value: WaitListUrgency.High,
  },
];

export interface IWaitListItem {
  uid?: string;
  urgency: WaitListUrgency;
  inShortList?: boolean;
  dateFrom: Timestamp;
  dateTo: Timestamp;
  timeFrom: string;
  timeTo: string;
  days: DayOfWeek[];
  notes?: MixedSchema;
  availableDates: string[];
}
