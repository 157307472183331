import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { type ITyroTerminal } from '@principle-theorem/principle-core/interfaces';

type EditTyroTerminalFormData = Pick<ITyroTerminal, 'name' | 'mid' | 'tid'>;

export interface IEditTyroTerminalRequest {
  title: string;
  formData?: Partial<EditTyroTerminalFormData>;
}

@Component({
  selector: 'pr-edit-tyro-terminal-dialog',
  templateUrl: './edit-tyro-terminal-dialog.component.html',
  styleUrls: ['./edit-tyro-terminal-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditTyroTerminalDialogComponent {
  form = new TypedFormGroup<EditTyroTerminalFormData>({
    name: new TypedFormControl<string>(''),
    mid: new TypedFormControl<number>(),
    tid: new TypedFormControl<number>(),
  });

  constructor(
    private _dialogRef: MatDialogRef<
      EditTyroTerminalDialogComponent,
      ITyroTerminal
    >,
    @Inject(MAT_DIALOG_DATA) public data: Partial<IEditTyroTerminalRequest>
  ) {
    if (data.formData) {
      this.form.patchValue(data.formData);
    }
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }
    this._dialogRef.close({ ...this.form.value, deleted: false });
  }
}
