import {
  IAppointment,
  IPatient,
  IPractice,
  ISchedulingEvent,
  ISchedulingEventReason,
  ISchedulingEventSnapshot,
  ISchedulingEventSnapshotSummary,
  ISchedulingEventSummary,
  IStaffer,
  ITreatmentStep,
  IUser,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  ISODateType,
  SystemActors,
  Timestamp,
  Transaction,
  WithRef,
  isSameRef,
  toISODate,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import {
  SCHEDULING_EVENT_ACTION_LABEL_MAP,
  SchedulingEvent,
} from './scheduling-event';
import { TreatmentStep } from '../clinical-charting/treatment/treatment-step';
import { OrganisationCache } from '../organisation/organisation-cache';
import { Appointment } from './appointment';
import { Observable } from 'rxjs';

export interface ISchedulingEventMetadata {
  reason?: WithRef<ISchedulingEventReason>;
  appointment: WithRef<IAppointment>;
  treatmentStep: WithRef<ITreatmentStep>;
  patient: WithRef<IPatient>;
  scheduledByPractice: WithRef<IPractice>;
  scheduledByStaffer?: WithRef<IStaffer>;
  scheduledByUser?: WithRef<IUser>;
  beforePractice?: WithRef<IPractice>;
  beforePractitionerStaffer?: WithRef<IStaffer>;
  beforePractitionerUser?: WithRef<IUser>;
  afterPractice?: WithRef<IPractice>;
  afterPractitionerStaffer?: WithRef<IStaffer>;
  afterPractitionerUser?: WithRef<IUser>;
}

export class SchedulingEventSummary {
  static getActionlabel(schedulingEvent: ISchedulingEventSummary): string {
    return SCHEDULING_EVENT_ACTION_LABEL_MAP[schedulingEvent.eventType];
  }

  static schedulingEvent$(
    schedulingEvent: ISchedulingEventSummary
  ): Observable<WithRef<ISchedulingEvent>> {
    return Firestore.doc$(schedulingEvent.schedulingEventRef);
  }

  static async buildEventSummary(
    practiceRef: DocumentReference<IPractice>,
    event: WithRef<ISchedulingEvent>,
    transaction?: Transaction
  ): Promise<ISchedulingEventSummary> {
    const metadata = await this.resolveSchedulingEventMetadata(
      event,
      transaction
    );
    const eventBefore = this.buildEventSnapshotSummary(
      event.eventBefore,
      metadata.beforePractice,
      metadata.beforePractitionerUser
    );
    const eventAfter = this.buildEventSnapshotSummary(
      event.eventAfter,
      metadata.afterPractice,
      metadata.afterPractitionerUser
    );
    const treatmentStepCategory = TreatmentStep.defaultDisplayRef(
      metadata.treatmentStep.display
    );

    const dateAggregates = this.buildSummaryDateAggregates(
      practiceRef,
      event,
      metadata
    );

    return {
      deleted: false,
      ...dateAggregates,
      schedulingEventRef: event.ref,
      eventType: event.eventType,
      filterValue: this.buildFilterValue(event, metadata),
      reasonName: metadata.reason?.name,
      scheduledByName: metadata.scheduledByUser?.name,
      scheduledAt: event.scheduledAt,
      patientRef: metadata.patient.ref,
      patientName: metadata.patient.name,
      treatmentStepName: metadata.treatmentStep.name,
      treatmentStepCategory,
      eventBefore,
      eventAfter,
    };
  }

  static async resolveSchedulingEventMetadata(
    event: WithRef<ISchedulingEvent>,
    transaction?: Transaction
  ): Promise<ISchedulingEventMetadata> {
    const reason = await SchedulingEvent.getReason(event, transaction);
    const appointment = await Firestore.getDoc(
      SchedulingEvent.appointmentRef(event),
      transaction
    );
    const treatmentStep = await Appointment.treatmentStep(
      appointment,
      transaction
    );
    const patient = await Appointment.patient(appointment, transaction);
    const scheduledByPractice = await Firestore.getDoc<IPractice>(
      event.scheduledByPractice,
      transaction
    );
    const scheduledByStaffer = event.scheduledByStaffer
      ? await Firestore.getDoc<IStaffer>(event.scheduledByStaffer, transaction)
      : undefined;
    const scheduledByUser = scheduledByStaffer
      ? await Firestore.getDoc<IUser>(scheduledByStaffer.user.ref, transaction)
      : undefined;

    const beforePractice = event.eventBefore
      ? await Firestore.getDoc<IPractice>(
          event.eventBefore?.practiceRef,
          transaction
        )
      : undefined;
    const beforePractitionerStaffer = event.eventBefore
      ? await Firestore.getDoc<IStaffer>(
          event.eventBefore.practitionerRef,
          transaction
        )
      : undefined;
    const beforePractitionerUser = beforePractitionerStaffer
      ? await Firestore.getDoc<IUser>(
          beforePractitionerStaffer.user.ref,
          transaction
        )
      : undefined;

    const afterPractice = event.eventAfter
      ? await Firestore.getDoc<IPractice>(
          event.eventAfter?.practiceRef,
          transaction
        )
      : undefined;
    const afterPractitionerStaffer = event.eventAfter
      ? await Firestore.getDoc<IStaffer>(
          event.eventAfter.practitionerRef,
          transaction
        )
      : undefined;
    const afterPractitionerUser = afterPractitionerStaffer
      ? await Firestore.getDoc<IUser>(
          afterPractitionerStaffer.user.ref,
          transaction
        )
      : undefined;

    return {
      reason,
      appointment,
      treatmentStep,
      patient,
      scheduledByPractice,
      scheduledByStaffer,
      scheduledByUser,
      beforePractice,
      beforePractitionerStaffer,
      beforePractitionerUser,
      afterPractice,
      afterPractitionerStaffer,
      afterPractitionerUser,
    };
  }

  static buildSummaryDateAggregates(
    practiceRef: DocumentReference<IPractice>,
    event: WithRef<ISchedulingEvent>,
    metadata: ISchedulingEventMetadata
  ): Pick<
    ISchedulingEventSummary,
    'scheduledByPracticeDate' | 'affectsPracticeDates'
  > {
    const scheduledByPracticeDate = getPracticeISODate(
      practiceRef,
      metadata.scheduledByPractice,
      event.scheduledAt
    );
    const affectsBeforeDate = getPracticeISODate(
      practiceRef,
      metadata.beforePractice,
      event.eventBefore?.from
    );
    const affectsAfterDate = getPracticeISODate(
      practiceRef,
      metadata.afterPractice,
      event.eventAfter?.from
    );
    return {
      scheduledByPracticeDate,
      affectsPracticeDates: compact([affectsBeforeDate, affectsAfterDate]),
    };
  }

  static buildEventSnapshotSummary(
    snapshot?: ISchedulingEventSnapshot,
    practice?: WithRef<IPractice>,
    user?: WithRef<IUser>
  ): ISchedulingEventSnapshotSummary | undefined {
    if (!snapshot || !practice || !user) {
      return;
    }
    return {
      date: toISODate(snapshot.from, practice.settings.timezone),
      practitionerName: user.name,
      practiceName: practice.name,
      from: snapshot.from,
      to: snapshot.to,
    };
  }

  static async resolveEventSnapshotSummary(
    snapshot?: ISchedulingEventSnapshot
  ): Promise<ISchedulingEventSnapshotSummary | undefined> {
    if (!snapshot) {
      return;
    }
    const practice = await OrganisationCache.practices.getDoc(
      snapshot.practiceRef
    );
    const practitioner = await OrganisationCache.staff.get.getDoc(
      snapshot.practitionerRef
    );
    const user = await OrganisationCache.users.get.getDoc(
      practitioner.user.ref
    );
    return this.buildEventSnapshotSummary(snapshot, practice, user);
  }

  static buildFilterValue(
    event: WithRef<ISchedulingEvent>,
    metadata: ISchedulingEventMetadata
  ): string {
    const reasonName =
      metadata.reason?.name ?? SchedulingEvent.getActionlabel(event);
    return compact([
      metadata.scheduledByUser?.name ?? SystemActors.Unknown,
      metadata.patient.name,
      metadata.treatmentStep.name,
      reasonName,
      metadata.beforePractice?.name,
      metadata.beforePractitionerUser?.name,
      metadata.afterPractitionerUser?.name,
      metadata.afterPractice?.name,
    ])
      .join(' ')
      .toLowerCase();
  }
}

function getPracticeISODate(
  practiceRef: DocumentReference<IPractice>,
  practice?: WithRef<IPractice>,
  timestamp?: Timestamp
): ISODateType | undefined {
  if (!practice || !timestamp || !isSameRef(practice.ref, practiceRef)) {
    return;
  }
  return toISODate(timestamp, practice.settings.timezone);
}
