<mat-toolbar color="accent">Call {{ data.contact.key }}</mat-toolbar>

<mat-dialog-content fxLayout="column" fxLayoutGap="16px">
  <div class="phone-number-display" *ngIf="data.contact.resource.ref">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
      *ngIf="phone$ | async as phone"
    >
      <mat-icon>phone</mat-icon>
      <h1 fxFlex>
        <a href="tel:{{ phone }}">{{ phone }}</a>
      </h1>
    </div>
  </div>

  <div class="note-options">
    <div *ngFor="let commonNote of commonNotes; trackBy: trackByNote">
      <mat-checkbox tabIndex="-1" [(ngModel)]="commonNote.selected">
        {{ commonNote.label }}
      </mat-checkbox>
    </div>
  </div>

  <mat-form-field>
    <mat-label>Call Notes</mat-label>
    <pr-content-editor
      placeholder="Call Notes"
      [formControl]="noteControl"
      [menuEnabled]="false"
      (metaKeydownEvent)="submit()"
     />
  </mat-form-field>
</mat-dialog-content>

<mat-divider />

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button (click)="dialogRef.close()">Close</button>
    <button
      mat-flat-button
      color="primary"
      (click)="submit()"
      [disabled]="!noteControl.dirty && !isCommonNoteSelected()"
    >
      Save Notes
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
