import {
  ALL_REDUCERS,
  MeasureReducer,
} from '@principle-theorem/principle-core/interfaces';
import {
  ICanGroupMeasuresProperty,
  type AccountCreditDimensionMeasures,
  type AppointmentDimensionMeasures,
  type InvoiceDimensionMeasures,
  type PatientDimensionMeasures,
  type PatientInteractionDimensionMeasures,
  type TransactionDimensionMeasures,
  type TreatmentPlanDimensionMeasures,
} from '@principle-theorem/reporting';
import {
  IGroupBySection,
  IGroupBySectionOption,
} from './report-builder-data-source';
import { SchedulingEventDimensionMeasures } from 'libs/reporting/src/lib/models/dimensions/scheduling-event-dimension';

export function groupBuilder<T>(
  name: string,
  getProperties: (dimension: T) => IGroupBySectionOption[]
): (dimension: T) => IGroupBySection {
  return (dimension: T) =>
    ReportBuilderGroups.toGroup(name, getProperties(dimension));
}

export class ReportBuilderGroups {
  static patient = groupBuilder(
    'Patient',
    (patient: PatientDimensionMeasures) => [
      ReportBuilderGroups.toOption(patient.name),
      ReportBuilderGroups.toOption(patient.gender),
      ReportBuilderGroups.toOption(patient.age),
      ReportBuilderGroups.toOption(patient.tags, [MeasureReducer.Count]),
      ReportBuilderGroups.toOption(patient.referrer),
      ReportBuilderGroups.toOption(patient.referrerType),
      ReportBuilderGroups.toOption(patient.patientReferrers, [
        MeasureReducer.Count,
      ]),
      ReportBuilderGroups.toOption(patient.preferredPractice),
      ReportBuilderGroups.toOption(patient.preferredDentist),
      ReportBuilderGroups.toOption(patient.preferredHygienist),
      ReportBuilderGroups.toOption(patient.lastVisitDate),
      ReportBuilderGroups.toOption(patient.lastVisitPractitioner),
      ReportBuilderGroups.toOption(patient.lastVisitTreatmentCategory),
      ReportBuilderGroups.toOption(patient.nextVisitDate),
      ReportBuilderGroups.toOption(patient.nextVisitPractitioner),
      ReportBuilderGroups.toOption(patient.nextVisitBooked),
      ReportBuilderGroups.toOption(patient.nextVisitTreatmentCategory),
      ReportBuilderGroups.toOption(patient.ageRange),
      ReportBuilderGroups.toOption(patient.status),
      ReportBuilderGroups.toOption(patient.hasMobileNumber),
      ReportBuilderGroups.toOption(patient.hasEmail),
      ReportBuilderGroups.toOption(patient.hasMedicareCard),
      ReportBuilderGroups.toOption(patient.hasDvaCard),
      ReportBuilderGroups.toOption(patient.hasHealthFundCard),
      ReportBuilderGroups.toOption(patient.healthFundType),
    ]
  );

  static patientInteraction = groupBuilder(
    'Patient Interaction',
    (patientInteraction: PatientInteractionDimensionMeasures) => [
      ReportBuilderGroups.toOption(patientInteraction.createdAt),
      ReportBuilderGroups.toOption(patientInteraction.owner),
      ReportBuilderGroups.toOption(patientInteraction.pinned),
      ReportBuilderGroups.toOption(patientInteraction.type),
      ReportBuilderGroups.toOption(patientInteraction.tags, [
        MeasureReducer.Count,
      ]),
    ]
  );

  static invoice = groupBuilder(
    'Invoice',
    (invoice: InvoiceDimensionMeasures) => [
      ReportBuilderGroups.toOption(invoice.status),
      ReportBuilderGroups.toOption(invoice.createdAt),
      ReportBuilderGroups.toOption(invoice.issuedAt),
      ReportBuilderGroups.toOption(invoice.paidAt),
      ReportBuilderGroups.toOption(invoice.products, [MeasureReducer.Count]),
      ReportBuilderGroups.toOption(invoice.treatments, [MeasureReducer.Count]),
      ReportBuilderGroups.toOption(invoice.serviceCodes, [
        MeasureReducer.Count,
      ]),
      ReportBuilderGroups.toOption(invoice.products, [MeasureReducer.Count]),
      ReportBuilderGroups.toOption(invoice.treatments, [MeasureReducer.Count]),
      ReportBuilderGroups.toOption(invoice.serviceCodes, [
        MeasureReducer.Count,
      ]),
    ]
  );

  static transaction = groupBuilder(
    'Transaction',
    (transaction: TransactionDimensionMeasures) => [
      ReportBuilderGroups.toOption(transaction.createdAt),
      ReportBuilderGroups.toOption(transaction.status),
      ReportBuilderGroups.toOption(transaction.type),
      ReportBuilderGroups.toOption(transaction.provider),
      ReportBuilderGroups.toOption(transaction.providerSubType),
    ]
  );

  static appointment = groupBuilder(
    'Appointment',
    (appointment: AppointmentDimensionMeasures) => [
      ReportBuilderGroups.toOption(appointment.date),
      ReportBuilderGroups.toOption(appointment.startTime),
      ReportBuilderGroups.toOption(appointment.endTime),
      ReportBuilderGroups.toOption(appointment.treatmentCategory),
      ReportBuilderGroups.toOption(appointment.status),
      ReportBuilderGroups.toOption(appointment.isBookedOnline),
      ReportBuilderGroups.toOption(appointment.dateBookedOnline),
      ReportBuilderGroups.toOption(appointment.tags, [MeasureReducer.Count]),
    ]
  );

  static treatmentPlan = groupBuilder(
    'Treatment Plan',
    (treatmentPlan: TreatmentPlanDimensionMeasures) => [
      ReportBuilderGroups.toOption(treatmentPlan.status),
    ]
  );

  static accountCredit = groupBuilder(
    'Account Credit',
    (accountCredit: AccountCreditDimensionMeasures) => [
      ReportBuilderGroups.toOption(accountCredit.createdAt),
      ReportBuilderGroups.toOption(accountCredit.reservedForPractitioner),
    ]
  );

  static schedulingEvent = groupBuilder(
    'Scheduling Event',
    (schedulingEvent: SchedulingEventDimensionMeasures) => [
      ReportBuilderGroups.toOption(schedulingEvent.scheduledAt),
      ReportBuilderGroups.toOption(schedulingEvent.eventType),
      ReportBuilderGroups.toOption(schedulingEvent.wasFillingGap),
      ReportBuilderGroups.toOption(schedulingEvent.wasMovedToSameDay),
    ]
  );

  static toOption(
    measure: ICanGroupMeasuresProperty,
    compatibleReducers: MeasureReducer[] = ALL_REDUCERS
  ): IGroupBySectionOption {
    return {
      measure,
      compatibleReducers,
    };
  }

  static toGroup(
    name: string,
    options: IGroupBySection['options']
  ): IGroupBySection {
    return {
      name,
      options,
    };
  }
}
