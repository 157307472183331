import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_ALLERGIES_RESOURCE_TYPE = 'patientAllergies';

export const PATIENT_ALLERGIES_SOURCE_ENTITY: ISourceEntity = SourceEntity.init(
  {
    metadata: {
      label: 'Patient Allergies List',
      description: '',
      idPrefix: PATIENT_ALLERGIES_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  }
);

export interface ICorePracticePatientAllergy {
  patientHistoryId: number;
  description: string; // "Codeine"
}

export function isCorePracticePatientAllergy(
  item: unknown
): item is ICorePracticePatientAllergy {
  return TypeGuard.interface<ICorePracticePatientAllergy>({
    patientHistoryId: isNumber,
    description: isString,
  })(item);
}

export interface ICorePracticePatientAllergyTranslations {}

export interface ICorePracticePatientAllergyFilters {
  patientHistoryId: number;
}

const PATIENT_ALLERGIES_SOURCE_QUERY = `
SELECT
  PatientHistoryId AS patient_history_id,
  Description AS description
FROM tblPatientAllergy
WHERE description IS NOT NULL
`;

export class PatientAllergySourceEntity extends BaseSourceEntity<
  ICorePracticePatientAllergy,
  ICorePracticePatientAllergyTranslations,
  ICorePracticePatientAllergyFilters
> {
  sourceEntity = PATIENT_ALLERGIES_SOURCE_ENTITY;
  entityResourceType = PATIENT_ALLERGIES_RESOURCE_TYPE;
  sourceQuery = PATIENT_ALLERGIES_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientAllergy;

  translate(
    _data: ICorePracticePatientAllergy,
    _timezone: Timezone
  ): ICorePracticePatientAllergyTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientAllergy): string {
    return `${data.patientHistoryId}-${data.description}`;
  }

  getSourceLabel(data: ICorePracticePatientAllergy): string {
    return `${data.patientHistoryId} - ${data.description}`;
  }

  getFilterData(
    data: ICorePracticePatientAllergy,
    _timezone: Timezone
  ): ICorePracticePatientAllergyFilters {
    return {
      patientHistoryId: data.patientHistoryId,
    };
  }
}
