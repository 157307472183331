<div class="chart-tab-group mat-elevation-z1">
  <div class="tabs-container" fxLayout="row" fxLayoutAlign="start start">
    <div
      *ngFor="let tab of tabs; trackBy: trackByTab"
      class="data-tab"
      [ngClass]="{ active: selected === tab }"
      (click)="selected = tab"
    >
      <div>{{ tab.title }}</div>

      <div fxLayout="row" fxLayoutAlign="start end">
        <span class="mat-headline-6">{{ tab.value }}</span>
        <span class="mat-caption">{{ tab.unit }}</span>
      </div>
    </div>
  </div>
  <div class="layout-padding" fxLayout="column" fxLayoutAlign="center center">
    <ng-container *ngIf="selected">
      <pr-chart fxFill [chartBuilder]="selected.chartBuilder" />
      <div class="chart-summary" [innerHTML]="selected.summary"></div>
    </ng-container>
  </div>
</div>
