<mat-nav-list>
  <a href="javascript:void(0);" mat-list-item (click)="download($event)">
    <span matListItemTitle>Download</span>
    <span matListItemLine>Download to a file</span>
  </a>

  <a href="javascript:void(0);" mat-list-item (click)="copy($event)">
    <span matListItemTitle>Copy</span>
    <span matListItemLine>Copy code to clipboard</span>
  </a>
</mat-nav-list>
