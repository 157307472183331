import {
  IOrganisation,
  IQueryScopeRequests,
  isQueryScopeRequests,
} from '@principle-theorem/principle-core/interfaces';
import { isDocRef, TypeGuard } from '@principle-theorem/shared';
import { DocumentReference } from '@principle-theorem/shared';

export interface IQueryScope {
  orgRef: DocumentReference<IOrganisation>;
  scopeRequests: IQueryScopeRequests;
}

export const isQueryScope = TypeGuard.interface<IQueryScope>({
  orgRef: isDocRef,
  scopeRequests: isQueryScopeRequests,
});
