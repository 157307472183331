<ng-container *ngIf="interaction$ | async as interaction">
  <ng-container *ngIf="canPin$ | async">
    <button
      mat-icon-button
      class="pinned-icon"
      (click)="togglePinned()"
      *ngrxLet="isPinned$ as isPinned"
    >
      <mat-icon *ngIf="isPinned" matTooltip="Unpin note">bookmark</mat-icon>
      <mat-icon *ngIf="!isPinned" matTooltip="Pin note"
        >bookmark_border</mat-icon
      >
    </button>
  </ng-container>

  <ng-container *ngIf="canEdit$ | async">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
      @if (hasHistory$ | async) {
        <button mat-menu-item (click)="viewHistory()">
          <mat-icon>history</mat-icon> History
        </button>
      }
      <button mat-menu-item (click)="edit()">
        <mat-icon>edit</mat-icon>Edit
      </button>
      @if (canDelete$ | async) {
        <button mat-menu-item color="warn" (click)="delete()">
          <mat-icon>delete</mat-icon> Delete
        </button>
      }
    </mat-menu>
  </ng-container>
</ng-container>
