import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import {
  CSVExporterService,
  ObservableDataSource,
} from '@principle-theorem/ng-shared';
import {
  type IPractitionerGrouping,
  type IPractitionerReportRecord,
  PRACTITIONER_INCOME_TOOLTIPS,
} from '@principle-theorem/reporting';
import {
  DAY_MONTH_YEAR_FORMAT,
  snapshot,
  toMoment,
} from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { InvoiceIssuedInspectorToCSV } from '../../../practitioner-reporting/practitioner-income/practitioner-income-inspector/invoice-issued-inspector-to-csv';
import { InvoicePaidInspectorToCSV } from '../../../practitioner-reporting/practitioner-income/practitioner-income-inspector/invoice-paid-inspector-to-csv';
import { getColumnsForReportType } from '../practitioner-invoice-display-columns';
import { PractitionerIncomeReportFacade } from '../store/practitioner-income.facade';
import { PractitionerIncomeReportType } from '../store/practitioner-income.reducers';

@Component({
    selector: 'pr-practitioner-income-inspector',
    templateUrl: './practitioner-income-inspector.component.html',
    styleUrls: ['./practitioner-income-inspector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PractitionerIncomeInspectorComponent implements OnDestroy {
  tooltips = PRACTITIONER_INCOME_TOOLTIPS;
  result$ = new ReplaySubject<IPractitionerGrouping>(1);
  dataSource: ObservableDataSource<IPractitionerReportRecord>;
  dateFormat = DAY_MONTH_YEAR_FORMAT;
  displayedColumns$: Observable<string[]>;

  @Input()
  set result(result: IPractitionerGrouping) {
    if (result) {
      this.result$.next(result);
    }
  }

  constructor(
    public reportFacade: PractitionerIncomeReportFacade,
    private _csvExporter: CSVExporterService
  ) {
    this.dataSource = new ObservableDataSource(
      this.result$.pipe(map((result) => result.records))
    );
    this.displayedColumns$ = reportFacade.reportType$.pipe(
      map((reportType) => [
        reportType === PractitionerIncomeReportType.ByInvoiceIssuedDate
          ? 'issuedAt'
          : 'paidAt',
        'status',
        'invoice',
        'patient',
        ...getColumnsForReportType(reportType),
      ])
    );
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  async downloadCSV(): Promise<void> {
    const query = await snapshot(this.reportFacade.query$);
    const reportType = await snapshot(this.reportFacade.reportType$);
    if (
      !query ||
      reportType === PractitionerIncomeReportType.ByTransactionDate
    ) {
      return;
    }
    const startDate = toMoment(query.startDate).format(DAY_MONTH_YEAR_FORMAT);
    const endDate = toMoment(query.endDate).format(DAY_MONTH_YEAR_FORMAT);
    const result = await snapshot(this.result$);

    if (reportType === PractitionerIncomeReportType.ByInvoiceIssuedDate) {
      return this._csvExporter.download(
        `invoice-issued-drilldown-${startDate}-${endDate}`,
        result.records,
        new InvoiceIssuedInspectorToCSV()
      );
    }
    return this._csvExporter.download(
      `invoice-paid-drilldown-${startDate}-${endDate}`,
      result.records,
      new InvoicePaidInspectorToCSV()
    );
  }
}
