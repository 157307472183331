import { IBrandDimension } from '@principle-theorem/reporting/interfaces';
import { BigQueryFirestoreModelMock } from './common.mock';
import { BrandMock } from '@principle-theorem/principle-core';
import {
  MockGenerator,
  toSerialisedTimestamp,
  toTimestamp,
} from '@principle-theorem/shared';

const brand = MockGenerator.generate(BrandMock);

export class BrandDimensionMock
  extends BigQueryFirestoreModelMock
  implements IBrandDimension
{
  timestamp = toSerialisedTimestamp(toTimestamp());
  name = brand.name;
  slug = brand.slug;
  settings = brand.settings;
}
