import { TaxRate, TaxStrategy } from '@principle-theorem/accounting';
import {
  IChartedTreatment,
  ICreditRefundLineItem,
  ICreditTransferLineItem,
  ICustomLineItem,
  IDepositLineItem,
  IFeeLineItem,
  IInvoiceLineItemGroup,
  IPatient,
  IPractice,
  IPricedServiceCodeEntry,
  IProduct,
  IProductLineItem,
  IProviderData,
  IServiceCodeLineItem,
  IStaffer,
  IToothRef,
  ITreatmentBasePriceLineItem,
  ITreatmentCategory,
  ITreatmentLineItem,
  ITreatmentPlan,
  ITreatmentRef,
  ITreatmentStep,
  InvoiceLineItemType,
  ServiceCodeGroupType,
  isInvoiceLineItem,
  isTreatmentLineItem,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  INamedDocument,
  IReffable,
  isObject,
} from '@principle-theorem/shared';
import { compact, first, isString, sumBy, uniq } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { AreaSummary } from '../clinical-charting/core/area-summary';
import { AreaSummaryFactory } from '../clinical-charting/core/area-summary-factory';
import { toothRefToLabel } from '../clinical-charting/core/tooth';
import { ChartedServiceExclusiveGroup } from '../clinical-charting/service-codes/charted-service-exclusive-group';
import { ChartedServiceSmartGroup } from '../clinical-charting/service-codes/charted-service-smart-group';
import { ServiceProviderHandler } from '../clinical-charting/service-codes/service-provider';
import { OrganisationCache } from '../organisation/organisation-cache';
import { Staffer } from '../staffer/staffer';
import { InvoiceLineItem, determineTaxFromStrategy } from './line-item';

export function productToLineItem(
  product: Pick<IProduct, 'name' | 'cost' | 'taxStatus'>,
  taxRate: TaxRate
): IProductLineItem {
  return {
    uid: uuid(),
    description: product.name,
    type: InvoiceLineItemType.Product,
    amount: product.cost,
    taxStatus: product.taxStatus,
    tax: determineTaxFromStrategy(taxRate, {
      taxStatus: product.taxStatus,
      amount: product.cost,
    }),
    quantity: 1,
  };
}

export function treatmentStepToLineItems(
  treatmentStep: ITreatmentStep,
  treatmentPlan: IReffable<ITreatmentPlan>,
  practitioner: INamedDocument<IStaffer>,
  taxRate: TaxRate
): ITreatmentLineItem[] {
  return treatmentStep.treatments.map((treatment) =>
    treatmentToLineItem(treatment, treatmentPlan, practitioner, taxRate)
  );
}

export function treatmentToLineItem(
  treatment: IChartedTreatment,
  treatmentPlan: IReffable<ITreatmentPlan>,
  practitioner: INamedDocument<IStaffer>,
  taxRate: TaxRate
): ITreatmentLineItem {
  const treatmentRef: ITreatmentRef = {
    planRef: treatmentPlan.ref,
    treatmentUuid: treatment.uuid,
    attributedTo: treatment.attributedTo ?? practitioner,
  };
  const basePriceItem: ITreatmentBasePriceLineItem = {
    ...InvoiceLineItem.init(),
    type: InvoiceLineItemType.TreatmentBasePrice,
    description: 'Base Price',
    amount: treatment.basePrice,
    treatmentRef,
  };

  if (treatment.taxStatus) {
    basePriceItem.taxStatus = treatment.taxStatus;
    basePriceItem.tax = determineTaxFromStrategy(taxRate, {
      taxStatus: treatment.taxStatus,
      amount: treatment.basePrice,
    });
  }

  const factory = new AreaSummaryFactory();
  const summaries = factory.create(treatment.chartedSurfaces);
  const surfaces: string[] = summaries.map((summary) =>
    AreaSummary.asCompact(summary)
  );
  const description = compact([
    treatment.config.name,
    surfaces.length ? `(${surfaces.join(', ')})` : undefined,
  ]).join(' - ');

  const serviceCodeItems = getServiceCodeItems(treatment, taxRate);
  const serviceCodeItemsTotal = sumBy(
    serviceCodeItems,
    (serviceCodeItem) => serviceCodeItem.amount * serviceCodeItem.quantity
  );

  return {
    ...InvoiceLineItem.init(),
    type: InvoiceLineItemType.Treatment,
    description,
    amount: basePriceItem.amount + serviceCodeItemsTotal,
    items: compact([
      basePriceItem.amount !== 0 ? basePriceItem : undefined,
      ...serviceCodeItems,
    ]),
    treatmentRef,
  };
}

export function getServiceCodeItems(
  treatment: IChartedTreatment,
  taxRate: TaxRate
): IServiceCodeLineItem[] {
  const smartGroups = treatment.serviceCodeSmartGroups.reduce(
    (all: IPricedServiceCodeEntry[], group) => {
      const selected = ChartedServiceSmartGroup.getSelected(group);
      return selected ? [...all, selected] : all;
    },
    []
  );

  const exclusiveCodes = treatment.serviceCodeGroups
    .filter((group) => group.type === ServiceCodeGroupType.Exclusive)
    .reduce((all: IPricedServiceCodeEntry[], group) => {
      const selected = ChartedServiceExclusiveGroup.getSelected(group);
      return selected ? [...all, selected] : all;
    }, []);

  return [...treatment.serviceCodes, ...smartGroups, ...exclusiveCodes]
    .filter((item) => item.quantity)
    .map((item) => toServiceCodeItem(item, taxRate));
}

function toServiceCodeItem(
  item: IPricedServiceCodeEntry,
  taxRate: TaxRate
): IServiceCodeLineItem {
  const amount = item.priceOverride ?? item.price;
  const code = ServiceProviderHandler.resolveServiceCode(item.type, item.code);
  return {
    uid: uuid(),
    description: code?.title ? `${item.code} - ${code.title}` : `${item.code}`,
    amount,
    quantity: item.quantity,
    type: InvoiceLineItemType.ServiceCode,
    tax: determineTaxFromStrategy(taxRate, {
      taxStatus: item.taxStatus,
      amount,
    }),
    taxStatus: item.taxStatus,
    code: code?.claimCode?.toString() ?? item.code.toString(),
    toothId: toServiceCodeItemToothId(item),
  };
}

export function isServiceCodeLineItem(
  item: unknown
): item is IServiceCodeLineItem {
  return (
    isObject(item) &&
    isInvoiceLineItem(item) &&
    'type' in item &&
    item.type === InvoiceLineItemType.ServiceCode &&
    isString(item.code)
  );
}

function toServiceCodeItemToothId(
  item: IPricedServiceCodeEntry
): string | undefined {
  const toothLabels = uniq(
    item.chartedSurfaces
      .map((chartedSurface) => chartedSurface.chartedRef.tooth)
      .filter((toothRef): toothRef is IToothRef => toothRef !== undefined)
      .map((toothRef) => toothRefToLabel(toothRef))
  );
  const label = first(toothLabels);
  if (label && toothLabels.length > 1) {
    // eslint-disable-next-line no-console
    console.warn(`Multiple surfaces found, assuming first value of: ${label}`);
  }
  return label;
}

export function depositToLineItem(
  description: string,
  amount: number = 0,
  attributedTo?: INamedDocument<IStaffer>,
  forTreatmentCategoryRef?: DocumentReference<ITreatmentCategory>,
  uid?: string
): IDepositLineItem {
  return {
    uid: uid ?? uuid(),
    description,
    type: InvoiceLineItemType.Deposit,
    amount,
    tax: 0,
    taxStatus: TaxStrategy.GSTFree,
    quantity: 1,
    max: 0,
    treatments: [],
    attributedTo,
    forTreatmentCategoryRef,
  };
}

export function creditTransferToLineItem(
  description: string,
  amount: number,
  patientFromRef: DocumentReference<IPatient>,
  patientToRef: DocumentReference<IPatient>,
  attributedTo?: INamedDocument<IStaffer>
): ICreditTransferLineItem {
  return {
    uid: uuid(),
    description,
    type: InvoiceLineItemType.CreditTransfer,
    amount,
    tax: 0,
    taxStatus: TaxStrategy.GSTFree,
    quantity: 1,
    patientTransferredRefs: {
      from: patientFromRef,
      to: patientToRef,
    },
    attributedTo,
  };
}

export function creditRefundToLineItem(
  description: string,
  amount: number,
  creditsUsed: ICreditRefundLineItem['creditsUsed']
): ICreditRefundLineItem {
  return {
    uid: uuid(),
    description,
    type: InvoiceLineItemType.CreditRefund,
    amount: -amount,
    tax: 0,
    taxStatus: TaxStrategy.GSTFree,
    quantity: 1,
    creditsUsed,
  };
}

export function feeToLineItem(
  description: string,
  amount: number = 0
): IFeeLineItem {
  return {
    uid: uuid(),
    description,
    type: InvoiceLineItemType.Fee,
    amount,
    tax: 0,
    taxStatus: TaxStrategy.GSTPossible,
    quantity: 1,
  };
}

export function resolveProvider$(
  group: IInvoiceLineItemGroup<ICustomLineItem>,
  practiceRef: DocumentReference<IPractice>
): Observable<(INamedDocument & Partial<IProviderData>) | undefined> {
  if (!isTreatmentLineItem(group) || !group.treatmentRef.attributedTo) {
    return of(undefined);
  }

  return OrganisationCache.staff.get
    .doc$(group.treatmentRef.attributedTo.ref)
    .pipe(
      map((staffer) => {
        const providerData = Staffer.getProviderData(staffer, practiceRef);
        return {
          ...group.treatmentRef.attributedTo,
          providerNumber: providerData?.providerNumber,
          providerModality: providerData?.providerModality,
        };
      })
    );
}
