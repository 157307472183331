import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgAutomationsModule } from '@principle-theorem/ng-automations';
import { NgClinicalChartingModule } from '@principle-theorem/ng-clinical-charting';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { CoreModule } from '../../../core/core.module';
import { AccountModule } from './account/account.module';
import { AccountingComponent } from './accounting/accounting.component';
import { EditManualTransactionTypeDialogComponent } from './accounting/edit-manual-transaction-type-dialog/edit-manual-transaction-type-dialog.component';
import { ManualTransactionTypesComponent } from './accounting/manual-transaction-types/manual-transaction-types.component';
import { BrandAutomationsComponent } from './brand-automations/brand-automations.component';
import { BrandConditionConfigurationCollectionResolverService } from './brand-condition-configuration-collection-resolver.service';
import { BrandFeeScheduleCollectionResolver } from './brand-fee-schedule-collection-resolver.service';
import { BrandMultiTreatmentConfigurationCollectionResolverService } from './brand-multi-treatment-configuration-collection-resolver.service';
import { BrandPracticeCollectionResolverService } from './brand-practice-collection-resolver.service';
import { BrandPrescriptionItemCollectionResolverService } from './brand-prescription-item-collection-resolver.service';
import { BrandProductCollectionResolverService } from './brand-product-collection-resolver.service';
import { BrandAppointmentTagsComponent } from './brand-tags/brand-appointment-tags/brand-appointment-tags.component';
import { BrandTagsComponent } from './brand-tags/brand-tags.component';
import { CalendarEventTagsComponent } from './brand-tags/calendar-event-tags/calendar-event-tags.component';
import { PatientNoteTagsComponent } from './brand-tags/patient-note-tags/patient-note-tags.component';
import { PatientTagsComponent } from './brand-tags/patient-tags/patient-tags.component';
import { BrandTemplateCollectionResolver } from './brand-template-collection-resolver.service';
import { BrandTreatmentConfigurationCollectionResolverService } from './brand-treatment-configuration-collection-resolver.service';
import { BrandTreatmentTemplatesComponent } from './brand-treatment-templates/brand-treatment-templates.component';
import { StafferTreatmentTemplateSelectComponent } from './brand-treatment-templates/staffer-treatment-template-select/staffer-treatment-template-select.component';
import { StafferTreatmentTemplateSelectStore } from './brand-treatment-templates/staffer-treatment-template-select/staffer-treatment-template-select.store';
import { TreatmentTemplateComponent } from './brand-treatment-templates/treatment-template/treatment-template.component';
import { UpsertTreatmentTemplateComponent } from './brand-treatment-templates/upsert-treatment-template/upsert-treatment-template.component';
import { UpsertTreatmentTemplateStore } from './brand-treatment-templates/upsert-treatment-template/upsert-treatment-template.store';
import { CancellationReasonsComponent } from './cancellation-reasons/cancellation-reasons.component';
import { SchedulingEventReasonStore } from './cancellation-reasons/cancellation-reasons.store';
import { EditCancellationReasonDialogComponent } from './cancellation-reasons/edit-cancellation-reason-dialog/edit-cancellation-reason-dialog.component';
import { SchedulingEventReasonService } from './cancellation-reasons/scheduling-event-reason.service';
import { CreateBrandComponent } from './create-brand/create-brand.component';
import { GeneralBrandComponent } from './general-brand/general-brand.component';
import { TrackingCodeHelpModule } from './general-brand/tracking-code-help/tracking-code-help.module';
import { MedicalHistoryModule } from './medical-history/medical-history.module';
import { PracticesModule } from './practices/practices.module';
import { ProductsModule } from './products/products.module';
import { EditReferralSourceDialogComponent } from './referral-sources/edit-referral-source-dialog/edit-referral-source-dialog.component';
import { ReferralSourcesComponent } from './referral-sources/referral-sources.component';
import { ReferralSourcesStore } from './referral-sources/referral-sources.store';
import { StaffModule } from './staff/staff.module';
import { TeamsModule } from './teams/teams.module';
import { TemplatingModule } from './templating/templating.module';
import { EditTreatmentCategoryComponent } from './treatment-categories/edit-treatment-category/edit-treatment-category.component';
import { TreatmentCategoriesListComponent } from './treatment-categories/treatment-categories-list/treatment-categories-list.component';

@NgModule({
  declarations: [
    CreateBrandComponent,
    GeneralBrandComponent,
    TreatmentTemplateComponent,
    UpsertTreatmentTemplateComponent,
    BrandTreatmentTemplatesComponent,
    CalendarEventTagsComponent,
    BrandAppointmentTagsComponent,
    PatientTagsComponent,
    StafferTreatmentTemplateSelectComponent,
    BrandTagsComponent,
    BrandAutomationsComponent,
    TreatmentCategoriesListComponent,
    EditTreatmentCategoryComponent,
    AccountingComponent,
    ManualTransactionTypesComponent,
    EditManualTransactionTypeDialogComponent,
    ReferralSourcesComponent,
    EditReferralSourceDialogComponent,
    EditCancellationReasonDialogComponent,
    CancellationReasonsComponent,
    PatientNoteTagsComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    NgSharedModule,
    NgMaterialModule,
    NgClinicalChartingModule,
    AccountModule,
    ReactiveFormsModule,
    TemplatingModule,
    TeamsModule,
    StaffModule,
    ProductsModule,
    PracticesModule,
    NgFeatureFlagsModule,
    NgPrincipleSharedModule,
    NgAutomationsModule,
    MedicalHistoryModule,
    TrackingCodeHelpModule,
  ],
  providers: [
    BrandConditionConfigurationCollectionResolverService,
    BrandTreatmentConfigurationCollectionResolverService,
    BrandMultiTreatmentConfigurationCollectionResolverService,
    BrandFeeScheduleCollectionResolver,
    BrandTemplateCollectionResolver,
    BrandPrescriptionItemCollectionResolverService,
    BrandProductCollectionResolverService,
    BrandPracticeCollectionResolverService,
    UpsertTreatmentTemplateStore,
    ReferralSourcesStore,
    SchedulingEventReasonStore,
    StafferTreatmentTemplateSelectStore,
    SchedulingEventReasonService,
  ],
})
export class BrandsModule {}
