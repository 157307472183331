import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  Timezone,
  TypeGuard,
  toTimestamp,
} from '@principle-theorem/shared';
import { flow, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { EXACT_DATE_TIME_FORMAT, convertExactId } from '../../util/helpers';
import { PatientSourceEntity } from './patient';

export const PATIENT_BALANCE_RESOURCE_TYPE = 'patientBalance';

export const PATIENT_BALANCE_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient Balance List',
    description: '',
    idPrefix: PATIENT_BALANCE_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface IPatientBalance {
  id: string;
  patient_id: string;
  balance: number;
  date: string;
}

function isPatientBalance(data: unknown): data is IPatientBalance {
  return TypeGuard.interface<IPatientBalance>({
    id: isString,
    patient_id: isString,
    balance: isNumber,
    date: isString,
  })(data);
}

export interface IPatientBalanceTranslations {
  date: Timestamp;
}

export interface IPatientBalanceFilters {
  patientId: string;
}

// TODO: https://app.clickup.com/t/86cua0e9z
const PATIENT_BALANCE_SOURCE_QUERY = `
SELECT
  sourceid::TEXT AS id,
  patientid::TEXT AS patient_id,
  balance,
  date
FROM
  convbalance
`;

export class PatientBalanceSourceEntity extends BaseSourceEntity<
  IPatientBalance,
  object,
  IPatientBalanceFilters
> {
  sourceEntity = PATIENT_BALANCE_SOURCE_ENTITY;
  entityResourceType = PATIENT_BALANCE_RESOURCE_TYPE;
  sourceQuery = PATIENT_BALANCE_SOURCE_QUERY;
  verifySourceFn = isPatientBalance;
  override defaultOffsetSize = 50000;

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  override transformDataFn = flow([transformPatientBalance]);

  translate(
    data: IPatientBalance,
    timezone: Timezone
  ): IPatientBalanceTranslations {
    const momentDate = moment.tz(data.date, EXACT_DATE_TIME_FORMAT, timezone);
    return {
      date: momentDate.year() !== 1 ? toTimestamp(momentDate) : toTimestamp(),
    };
  }

  getSourceRecordId(data: IPatientBalance): string {
    return data.patient_id;
  }

  getSourceLabel(record: IPatientBalance): string {
    return record.patient_id;
  }

  getFilterData(data: IPatientBalance): IPatientBalanceFilters {
    return {
      patientId: data.patient_id,
    };
  }
}

function transformPatientBalance(
  rows: (Omit<IPatientBalance, 'balance'> & { balance: string })[]
): IPatientBalance[] {
  return rows.map((row) => ({
    id: convertExactId(row.id),
    patient_id: convertExactId(row.patient_id),
    balance: parseFloat(row.balance),
    date: row.date,
  }));
}
