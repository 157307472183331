import {
  type AfterContentInit,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  type OnInit,
  Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { type IConditionConfiguration } from '@principle-theorem/principle-core/interfaces';
import {
  type ChartableSurface,
  type IChartedItemDisplay,
} from '@principle-theorem/principle-core/interfaces';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { type WithRef } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChartedItemConfiguration } from '@principle-theorem/principle-core';

type IConditionFormData = Pick<IConditionConfiguration, 'name'>;

@Component({
    selector: 'pr-general-condition-configuration',
    templateUrl: './general-condition-configuration.component.html',
    styleUrls: ['./general-condition-configuration.component.sass'],
    standalone: false
})
export class GeneralConditionConfigurationComponent
  implements OnInit, AfterContentInit, OnDestroy
{
  private _onDestroy$: Subject<void> = new Subject();
  @Input() conditionType: WithRef<IConditionConfiguration>;
  @Output()
  updateCondition = new EventEmitter<IConditionConfiguration>();

  form: TypedFormGroup<IConditionFormData> =
    new TypedFormGroup<IConditionFormData>({
      name: new TypedFormControl<string>('', Validators.required),
    });
  surfaces: ChartableSurface[] = [];
  display: IChartedItemDisplay;
  notes = '';

  ngOnInit(): void {
    this.form.controls.name.setValue(this.conditionType.name);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngAfterContentInit(): void {
    this.form.controls.name.statusChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => {
        if (this.form.controls.name.valid) {
          this.emitChanges();
        }
      });
  }

  selectedSurfaces(surfaces: ChartableSurface[]): void {
    this.surfaces = surfaces;
    this.emitChanges();
  }

  selectedDisplayOptions(display: IChartedItemDisplay): void {
    this.display = display;
    this.emitChanges();
  }

  emitChanges(): void {
    this.updateCondition.emit(
      ChartedItemConfiguration.init({
        name: this.form.controls.name.value ?? '',
        display: this.display ? this.display : this.conditionType.display,
        clinicalNotes: this.notes,
        availableSurfaces: this.surfaces.length
          ? this.surfaces
          : this.conditionType.availableSurfaces,
      })
    );
  }
}
