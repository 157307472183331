import { Injectable } from '@angular/core';
import { snapshot } from '@principle-theorem/shared';
import { AuthGuard, AuthService } from '@principle-theorem/ng-auth';
import { type IManagementUser } from '@principle-theorem/principle-core/interfaces';
import { ManagementService } from './management.service';

@Injectable()
export class IsManagementGuard extends AuthGuard {
  constructor(auth: AuthService, private _management: ManagementService) {
    super(auth);
  }

  async canActivate(): Promise<boolean> {
    const user: IManagementUser | undefined = await snapshot(
      this._management.user$
    );
    return user ? true : false;
  }
}
