import { createReducer, on } from '@ngrx/store';
import { type IPerioTable } from '@principle-theorem/ng-perio-charting';
import { type SerialisedData } from '@principle-theorem/shared';
import { loadPerioChartSuccess } from '../actions/perio-table';

export interface IPerioChartState {
  tables: IPerioTable[];
}

export const perioChartReducer = createReducer<
  SerialisedData<IPerioChartState>
>(
  { tables: [] },
  on(loadPerioChartSuccess, (state, action) => ({
    ...state,
    tables: action.tables,
  }))
);
