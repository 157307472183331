import {
  IStaffer,
  ITranslationMap,
} from '@principle-theorem/principle-core/interfaces';
import { Firestore, WithRef } from '@principle-theorem/shared';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { IBaseMigrationStaffer } from '../../../interfaces';
import { StafferMappingHandler } from '../../../mappings/staff';
import { TranslationMapHandler } from '../../../translation-map';
import {
  IExactStaffer,
  StafferSourceEntity,
} from '../../source/entities/staff';

export class ExactStafferMappingHandler extends StafferMappingHandler<
  IExactStaffer,
  StafferSourceEntity
> {
  override sourceEntity = new StafferSourceEntity();
  translateFn = (record: IExactStaffer): IBaseMigrationStaffer => ({
    id: record.id,
    name: record.name ?? '',
    providerNo: '',
  });
}

export async function resolveExactStaffer(
  stafferInitials: string,
  translationMap: TranslationMapHandler,
  staff: WithRef<ITranslationMap<IStaffer>>[]
): Promise<WithRef<IStaffer> | undefined> {
  const staffRef = await translationMap.getBySource<IStaffer>(
    stafferInitials.replace(/\//g, '-'),
    STAFFER_RESOURCE_TYPE
  );

  if (staffRef?.destinationIdentifier) {
    return Firestore.getDoc(staffRef.destinationIdentifier);
  }

  // Default provider is used from custom mapping with id: 0
  const defaultStaffer = staff.find(
    (staffer) => staffer.sourceIdentifier === '0'
  )?.destinationIdentifier;

  return defaultStaffer ? Firestore.getDoc(defaultStaffer) : undefined;
}
