import { Injectable } from '@angular/core';
import { FirebaseFunctionsService } from '@principle-theorem/ng-firebase';
import {
  type ICreatePaymentIntentRequest,
  type ICreatePaymentIntentResponse,
  type IStripeConnectRedirectRequest,
  type IStripeConnectRedirectResponse,
} from '@principle-theorem/stripe';

@Injectable()
export class StripeFunctions {
  constructor(private _functions: FirebaseFunctionsService) {}

  async createPaymentIntent(
    orgUid: string,
    amount: number
  ): Promise<ICreatePaymentIntentResponse> {
    return this._functions.call<
      ICreatePaymentIntentRequest,
      ICreatePaymentIntentResponse
    >('http-stripe-createPaymentIntent', { amount, orgUid });
  }

  async connectRedirect(
    orgUid: string
  ): Promise<IStripeConnectRedirectResponse> {
    return this._functions.call<
      IStripeConnectRedirectRequest,
      IStripeConnectRedirectResponse
    >('http-stripe-connectRedirect', { orgUid });
  }
}
