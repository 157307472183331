import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  ButtonsContainerComponent,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { ISterilisationCycleType } from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';

interface ISterilisationCycleTypeForm
  extends Omit<ISterilisationCycleType, 'deleted'> {}

export interface ISterilisationCycleTypeDialogData {
  cycleType?: WithRef<ISterilisationCycleType>;
}

@Component({
  selector: 'pr-sterilisation-cycle-type-dialog',
  standalone: true,
  imports: [
    CommonModule,
    NgMaterialModule,
    ButtonsContainerComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './sterilisation-cycle-type-dialog.component.html',
  styleUrl: './sterilisation-cycle-type-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SterilisationCycleTypeDialogComponent {
  private _dialogRef = inject(
    MatDialogRef<SterilisationCycleTypeDialogComponent, ISterilisationCycleType>
  );
  form = new TypedFormGroup<ISterilisationCycleTypeForm>({
    name: new TypedFormControl<string>('', Validators.required),
    description: new TypedFormControl<string>(''),
    isTestType: new TypedFormControl<boolean>(false),
  });
  data = inject<ISterilisationCycleTypeDialogData>(MAT_DIALOG_DATA);

  constructor() {
    if (this.data.cycleType) {
      this.form.patchValue(this.data.cycleType);
    }
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }

    this._dialogRef.close({
      deleted: false,
      ...this.form.value,
    });
  }
}
