import { NgModule } from '@angular/core';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ServiceGroupHeaderComponent } from './service-group-header/service-group-header.component';
import { ServiceGroupsEditorComponent } from './service-groups-editor/service-groups-editor.component';

@NgModule({
  declarations: [ServiceGroupsEditorComponent, ServiceGroupHeaderComponent],
  imports: [NgPrincipleSharedModule, NgSharedModule],
  exports: [ServiceGroupsEditorComponent],
})
export class ServiceGroupsModule {}
