import { IMenuButton } from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { textDropdownButtons } from './heading-menu/heading-menu.component';
import { BasicMenuButtonComponent } from '../../menu-bar/basic-menu-button/basic-menu-button.component';

export function createHeadingMenuBlockButtons(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
>[] {
  return textDropdownButtons()
    .slice(0, 4)
    .map((button) => () => ({
      component: BasicMenuButtonComponent,
      data: {
        ...button,
        buttonType: 'block',
      },
    }));
}
