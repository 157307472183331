import {
  IBrand,
  ICalendarEventSchedule,
  IPatient,
  IPractice,
  IRole,
  IStaffer,
  ITag,
  IUser,
} from '@principle-theorem/principle-core/interfaces';
import { FirestoreCache, FirestoreQueryCache } from '@principle-theorem/shared';

export class OrganisationCache {
  static practices = new FirestoreCache<IPractice>();
  static brands = new FirestoreCache<IBrand>();
  static roles = new FirestoreCache<IRole>();
  static staff = {
    get: new FirestoreCache<IStaffer>(),
    all: new FirestoreQueryCache<IStaffer>(),
  };
  static rosterSchedules = new FirestoreQueryCache<ICalendarEventSchedule>();
  static users = {
    get: new FirestoreCache<IUser>(),
    byOrganisation: new FirestoreQueryCache<IUser>(),
    byBrand: new FirestoreQueryCache<IUser>(),
    byPractice: new FirestoreQueryCache<IUser>(),
  };
  static appointmentTags = new FirestoreQueryCache<ITag>();
  static patients = new FirestoreCache<IPatient>(undefined, 20);

  static setCacheEnabled(cacheEnabled: boolean): void {
    this.practices.setCacheEnabled(cacheEnabled);
    this.brands.setCacheEnabled(cacheEnabled);
    this.roles.setCacheEnabled(cacheEnabled);
    this.staff.get.setCacheEnabled(cacheEnabled);
    this.staff.all.setCacheEnabled(cacheEnabled);
    this.rosterSchedules.setCacheEnabled(cacheEnabled);
    this.users.get.setCacheEnabled(cacheEnabled);
    this.users.byOrganisation.setCacheEnabled(cacheEnabled);
    this.users.byBrand.setCacheEnabled(cacheEnabled);
    this.users.byPractice.setCacheEnabled(cacheEnabled);
    this.appointmentTags.setCacheEnabled(cacheEnabled);
    this.patients.setCacheEnabled(cacheEnabled);
  }
}
