import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { ReplaySubject } from 'rxjs';
import { type IFilterOption } from '@principle-theorem/shared';
import {
  type AutomationTypeFilter,
  AUTOMATION_TYPE_FILTER_OPTIONS,
} from '@principle-theorem/principle-core';

@Component({
    selector: 'pr-automations-type-filter-group',
    templateUrl: './automations-type-filter-group.component.html',
    styleUrls: ['./automations-type-filter-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AutomationsTypeFilterGroupComponent {
  trackByFilter = TrackByFunctions.uniqueId<IFilterOption>();
  activeType$: ReplaySubject<AutomationTypeFilter> = new ReplaySubject(1);
  typeFilters = AUTOMATION_TYPE_FILTER_OPTIONS;
  @Output() typeFilterSelect = new EventEmitter<AutomationTypeFilter>();

  @Input()
  set activeType(activeType: AutomationTypeFilter) {
    if (activeType) {
      this.activeType$.next(activeType);
    }
  }
}
