<div class="flex flex-col">
  <div class="flex flex-col gap-2">
    @for (data of automations$ | async; track data.automation.uid) {
      <div class="flex flex-row items-center gap-2">
        <pr-automation-icon [automation]="data.automation.data" />

        <div class="flex grow flex-col">
          <div class="title flex flex-row">
            <pr-content-text [content]="data.automation | map: getTitle" />
            <mat-icon
              class="created-by-tooltip"
              matTooltip="Created by: {{ data.automation.creator.name }}"
            >
              info
            </mat-icon>
          </div>

          <pr-automation-reschedule-timing [automation]="data" />
        </div>

        <pr-automation-status [status]="data | map: getStatus" />

        <div class="flex flex-row items-center">
          <mat-slide-toggle
            [checked]="data.reschedule"
            (change)="handleToggle($event, data.automation)"
          />
          <button mat-icon-button (click)="edit(data.automation)">
            <mat-icon>edit</mat-icon>
          </button>
          @if (
            data.automation.data
              | map: missingContactDetailsHint$ : this
              | async;
            as hint
          ) {
            <mat-icon class="warn-tooltip" [matTooltip]="hint">
              warning
            </mat-icon>
          }
        </div>
      </div>
    } @empty {
      <div fxLayoutAlign="center stretch">
        <h2 class="no-automations mat-headline-5">No Automations</h2>
      </div>
    }
  </div>
</div>
