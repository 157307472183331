import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  type OnDestroy,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, type ControlValueAccessor } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  TypedFormControl,
  validFormControlChanges$,
} from '@principle-theorem/ng-shared';
import { Brand, FeeSchedule } from '@principle-theorem/principle-core';
import {
  FeeScheduleScope,
  type IFeeSchedule,
  type IFeeScheduleGroup,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isSameRef,
  multiSwitchMap,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { combineLatest, of, Subject, type Observable } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-patient-fee-schedule-selector',
    templateUrl: './patient-fee-schedule-selector.component.html',
    styleUrls: ['./patient-fee-schedule-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PatientFeeScheduleSelectorComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class PatientFeeScheduleSelectorComponent
  implements OnDestroy, ControlValueAccessor
{
  private _onDestroy$ = new Subject<void>();
  control = new TypedFormControl<WithRef<IFeeSchedule>>();
  feeScheduleGroups$: Observable<IFeeScheduleGroup[]>;
  unselectedLabel$: Observable<string>;

  @Input() appearance: MatFormFieldAppearance = 'outline';

  @Input()
  set schedule(schedule: WithRef<IFeeSchedule>) {
    if (schedule) {
      this.control.setValue(schedule, { emitEvent: false });
    }
  }

  constructor(
    private _organisation: OrganisationService,
    private _scope: CurrentScopeFacade
  ) {
    const defaultFeeSchedule$ = combineLatest([
      this._organisation.organisation$.pipe(filterUndefined()),
      this._scope.currentPractice$,
    ]).pipe(
      switchMap(([org, practice]) =>
        FeeSchedule.getPreferredOrDefault(
          org,
          practice?.settings.defaultFeeSchedule
        )
      )
    );
    this.unselectedLabel$ = defaultFeeSchedule$.pipe(
      map((feeSchedule) => `Auto: ${feeSchedule.name}`)
    );
    this.feeScheduleGroups$ = combineLatest([
      this._getOrganisationGroup$(),
      this._getCurrentScopedGroups$(),
    ]).pipe(
      map(([organisationGroup, scopedGroups]) => [
        organisationGroup,
        ...scopedGroups,
      ])
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  compareFn(
    value: WithRef<IFeeSchedule>,
    schedule: WithRef<IFeeSchedule>
  ): boolean {
    if (!value || !schedule) {
      return false;
    }
    return isSameRef(value, schedule);
  }

  writeValue(schedule: WithRef<IFeeSchedule>): void {
    this.control.setValue(schedule);
  }

  registerOnChange(fn: () => void): void {
    validFormControlChanges$(this.control)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(fn);
  }

  registerOnTouched(_fn: () => void): void {
    //
  }

  private _getOrganisationGroup$(): Observable<IFeeScheduleGroup> {
    return this._organisation.organisation$.pipe(
      filterUndefined(),
      switchMap(async (organisation) => {
        return {
          name: 'Organisation Schedules',
          scope: FeeScheduleScope.Organisation,
          schedules: await snapshot(FeeSchedule.all$(organisation)),
        };
      })
    );
  }

  private _getCurrentScopedGroups$(): Observable<IFeeScheduleGroup[]> {
    const practices$ = this._scope.currentBrand$.pipe(
      switchMap((brand) => (brand ? Brand.practices$(brand) : of([])))
    );
    return practices$.pipe(
      multiSwitchMap(async (practice) => {
        const schedules = await snapshot(FeeSchedule.all$(practice));
        if (!schedules) {
          return;
        }
        return {
          name: practice.name,
          scope: FeeScheduleScope.Practice,
          schedules,
        };
      }),
      map((groups) => compact(groups))
    );
  }
}
