import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  MultiTreatmentConfiguration,
  TreatmentConfiguration,
} from '@principle-theorem/principle-core';
import {
  type IBrand,
  type IStaffer,
  type TreatmentTemplateTreatments,
  TreatmentTemplateType,
} from '@principle-theorem/principle-core/interfaces';
import {
  type IReffable,
  multiMap,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

export interface IStafferTreatmentTemplateSelectState {
  brandTreatments: TreatmentTemplateTreatments[];
  stafferTreatments: TreatmentTemplateTreatments[];
  brandMultiTreatments: TreatmentTemplateTreatments[];
  stafferMultiTreatments: TreatmentTemplateTreatments[];
}

const initialState: IStafferTreatmentTemplateSelectState = {
  brandTreatments: [],
  stafferTreatments: [],
  brandMultiTreatments: [],
  stafferMultiTreatments: [],
};

@Injectable()
export class StafferTreatmentTemplateSelectStore extends ComponentStore<IStafferTreatmentTemplateSelectState> {
  readonly brandTreatments$ = this.select((state) => state.brandTreatments);
  readonly stafferTreatments$ = this.select((state) => state.stafferTreatments);
  readonly brandMultiTreatments$ = this.select(
    (state) => state.brandMultiTreatments
  );
  readonly stafferMultiTreatments$ = this.select(
    (state) => state.stafferMultiTreatments
  );

  readonly loadOptions = this.effect(
    (
      data$: Observable<{
        brand: WithRef<IBrand>;
        staffer: IReffable<IStaffer>;
      }>
    ) =>
      data$.pipe(
        switchMap((data) => {
          const brandTreatments$ = TreatmentConfiguration.all$(data.brand).pipe(
            multiMap((treatment) => ({
              ...toNamedDocument(treatment),
              type: TreatmentTemplateType.Treatment,
            }))
          );
          const stafferTreatments$ = TreatmentConfiguration.all$(
            data.staffer
          ).pipe(
            multiMap((treatment) => ({
              ...toNamedDocument(treatment),
              type: TreatmentTemplateType.Treatment,
            }))
          );
          const brandMultiTreatments$ = MultiTreatmentConfiguration.all$(
            data.brand
          ).pipe(
            multiMap((multiTreatment) => ({
              ...toNamedDocument(multiTreatment),
              type: TreatmentTemplateType.MultiTreatment,
            }))
          );
          const stafferMultiTreatments$ = MultiTreatmentConfiguration.all$(
            data.staffer
          ).pipe(
            multiMap((multiTreatment) => ({
              ...toNamedDocument(multiTreatment),
              type: TreatmentTemplateType.MultiTreatment,
            }))
          );

          return combineLatest([
            brandTreatments$,
            stafferTreatments$,
            brandMultiTreatments$,
            stafferMultiTreatments$,
          ]);
        }),
        tap(
          ([
            brandTreatments,
            stafferTreatments,
            brandMultiTreatments,
            stafferMultiTreatments,
          ]) =>
            this.setState({
              brandTreatments,
              stafferTreatments,
              brandMultiTreatments,
              stafferMultiTreatments,
            })
        )
      )
  );

  constructor() {
    super(initialState);
  }
}
