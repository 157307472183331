import {
  type ICustomLineItem,
  type IInvoiceLineItemGroup,
} from '@principle-theorem/principle-core/interfaces';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class LineItemsDisplay {
  withChildren$: Observable<IInvoiceLineItemGroup<ICustomLineItem>[]>;
  withoutChildren$: Observable<ICustomLineItem[]>;

  constructor(lineItems$: Observable<ICustomLineItem[]>) {
    this.withChildren$ = lineItems$.pipe(
      map((items) =>
        items.filter<IInvoiceLineItemGroup<ICustomLineItem>>(
          (item): item is IInvoiceLineItemGroup<ICustomLineItem> =>
            this._hasChildren(item)
        )
      )
    );
    this.withoutChildren$ = lineItems$.pipe(
      map((items) => items.filter((item) => !this._hasChildren(item)))
    );
  }

  private _hasChildren(
    item: ICustomLineItem
  ): item is IInvoiceLineItemGroup<ICustomLineItem> {
    return Object.keys(item).includes('items');
  }
}
