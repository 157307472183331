import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { type IBreadcrumb } from '@principle-theorem/ng-shared';
import {
  type IOrganisation,
  type IUser,
} from '@principle-theorem/principle-core/interfaces';
import { Organisation } from '@principle-theorem/principle-core';
import { type CollectionReference } from '@principle-theorem/shared';
import {
  filterUndefined,
  findProp,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserListComponent {
  userCollection$: Observable<CollectionReference<IUser>>;
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(private _route: ActivatedRoute) {
    const organisation$ = this._route.data.pipe(
      findProp<WithRef<IOrganisation>>('organisation'),
      filterUndefined()
    );

    this.userCollection$ = organisation$.pipe(
      map((organisation) => Organisation.userCol(organisation))
    );

    this.breadcrumbs$ = organisation$.pipe(
      map((organisation) => [
        { label: 'Organisations', path: '../../' },
        { label: organisation.name, path: '../' },
        { label: 'Users' },
      ])
    );
  }
}
