import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MixedSchema, getSchemaText } from '@principle-theorem/editor';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { DialogPresets, TypedFormControl } from '@principle-theorem/ng-shared';
import { ClinicalNote, Patient } from '@principle-theorem/principle-core';
import {
  PatientRelationshipType,
  type IClinicalNote,
  type IPatient,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  ArchivedDocument,
  filterUndefined,
  Firestore,
  isChanged$,
  isPathChanged$,
  isRefChanged$,
  isSameRef,
  shareReplayHot,
  snapshot,
  toISODate,
  toTimestamp,
  type Timestamp,
  type WithRef,
  snapshotDefined,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  noop,
  type Observable,
} from 'rxjs';
import {
  concatMap,
  debounceTime,
  filter,
  map,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { ClinicalNotesHistoryDialogComponent } from '../clinical-notes-history-dialog/clinical-notes-history-dialog.component';
import { AnyExtension } from '@tiptap/core';
import { EditorPresetsService } from '@principle-theorem/ng-interactions';
import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji';

@Component({
  selector: 'pr-clinical-notes',
  templateUrl: './clinical-notes.component.html',
  styleUrls: ['./clinical-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalNotesComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  date$ = new ReplaySubject<Timestamp>(1);
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  staffer$ = new ReplaySubject<WithRef<IStaffer>>(1);
  updateNote$ = new ReplaySubject<void>(1);
  isSynchronised$ = new ReplaySubject<boolean>(1);
  localNote$ = new ReplaySubject<MixedSchema>(1);
  disabled$ = new BehaviorSubject(false);
  displayStafferSelector$ = new BehaviorSubject(false);
  displayNoteHistory$ = new BehaviorSubject(false);
  dialogDisplay$ = new BehaviorSubject(false);
  lockNoteTooltip$: Observable<string>;
  note$: Observable<WithRef<IClinicalNote>>;
  noteHistory$: Observable<ArchivedDocument<WithRef<IClinicalNote>>[]>;
  canArchive$: Observable<boolean>;
  allNotes$: Observable<WithRef<IClinicalNote>[]>;
  canLockNote$: Observable<boolean>;
  noteControl = new TypedFormControl<MixedSchema>(
    undefined,
    Validators.required
  );
  extensions: AnyExtension[];

  @Input()
  set staffer(staffer: WithRef<IStaffer>) {
    if (staffer) {
      this.staffer$.next(staffer);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  @Input()
  set date(date: Timestamp) {
    date ? this.date$.next(date) : this.date$.next(toTimestamp());
  }

  @Input()
  set displayStafferSelector(displayStafferSelector: BooleanInput) {
    this.displayStafferSelector$.next(
      coerceBooleanProperty(displayStafferSelector)
    );
  }

  @Input()
  set displayNoteHistory(displayNoteHistory: BooleanInput) {
    this.displayNoteHistory$.next(coerceBooleanProperty(displayNoteHistory));
  }

  @Input()
  set dialogDisplay(dialogDisplay: BooleanInput) {
    this.dialogDisplay$.next(coerceBooleanProperty(dialogDisplay));
  }

  constructor(
    private _dialog: MatDialog,
    private _organisation: OrganisationService,
    private _editorPresets: EditorPresetsService
  ) {
    this.allNotes$ = this._getAllNotes$();
    this.note$ = this._getNote$();
    this.canLockNote$ = this._canLockNote$();
    this.lockNoteTooltip$ = this._lockNoteTooltip$();

    this.note$
      .pipe(isPathChanged$('uid'), takeUntil(this._onDestroy$))
      .subscribe((note) => {
        this._setControlValue(note);
        this.localNote$.next(note.content);
        this.disabled$.next(note.immutable);
      });

    this.note$
      .pipe(withLatestFrom(this.localNote$), takeUntil(this._onDestroy$))
      .subscribe(([savedNote, localNote]) =>
        this.isSynchronised$.next(
          getSchemaText(savedNote.content) === getSchemaText(localNote)
        )
      );

    this.updateNote$
      .pipe(withLatestFrom(this.note$), takeUntil(this._onDestroy$))
      .subscribe(([_, note]) => {
        this._setControlValue(note);
        this.localNote$.next(note.content);
        this.isSynchronised$.next(true);
      });

    this.noteHistory$ = this.note$.pipe(switchMap(ClinicalNote.history$));

    this.canArchive$ = combineLatest([this.note$, this.noteHistory$]).pipe(
      map(([note, history]) => ClinicalNote.canArchive(note, history))
    );

    this.noteControl.valueChanges
      .pipe(
        debounceTime(500),
        isChanged$(),
        withLatestFrom(
          this.note$,
          this.isSynchronised$,
          this.disabled$,
          this.canArchive$,
          this._organisation.staffer$.pipe(filterUndefined())
        ),
        filter(([_, __, isSync, disabled]) => isSync && !disabled),
        concatMap(async ([content, note, _, __, canArchive, staffer]) => {
          if (canArchive) {
            await ClinicalNote.archive(note, staffer.ref);
          }

          this.localNote$.next(content);
          await Firestore.patchDoc(note.ref, { content });
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);

    this.extensions = [
      ...this._editorPresets.defaultExtensions(),
      Emoji.configure({ emojis: gitHubEmojis, enableEmoticons: false }),
    ];
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async openNotesHistory(): Promise<void> {
    const patient = await snapshot(this.patient$);

    const config = DialogPresets.large({
      height: '80%',
      data: { patient },
    });

    this._dialog.closeAll();

    await this._dialog
      .open(ClinicalNotesHistoryDialogComponent, config)
      .afterClosed()
      .toPromise();

    this.updateNote$.next();
  }

  async toggleLock(): Promise<void> {
    const note = await snapshot(this.note$);
    this.disabled$.next(!note.immutable);

    const canArchive = await snapshot(this.canArchive$);
    if (canArchive) {
      const staffer = await snapshotDefined(this._organisation.staffer$);
      await ClinicalNote.archive(note, staffer.ref);
    }

    await Firestore.patchDoc(note.ref, { immutable: !note.immutable });
  }

  private _getNote$(): Observable<WithRef<IClinicalNote>> {
    return combineLatest([
      this.patient$.pipe(isRefChanged$()),
      this.staffer$.pipe(isRefChanged$()),
      this.date$.pipe(
        map((date) => toISODate(date)),
        isChanged$()
      ),
    ]).pipe(
      switchMap(([patient, staffer, date]) =>
        ClinicalNote.getEditableNote$(patient, staffer, date)
      ),
      filterUndefined(),
      shareReplayHot(this._onDestroy$)
    );
  }

  private _getAllNotes$(): Observable<WithRef<IClinicalNote>[]> {
    return this.patient$.pipe(
      switchMap((patient) =>
        Patient.withPatientRelationships$(
          patient,
          [PatientRelationshipType.DuplicatePatient],
          ClinicalNote.all$
        )
      )
    );
  }

  private _canLockNote$(): Observable<boolean> {
    return combineLatest([
      this._organisation.staffer$.pipe(filterUndefined()),
      this.note$,
    ]).pipe(map(([staffer, note]) => isSameRef(note.owner, staffer)));
  }

  private _lockNoteTooltip$(): Observable<string> {
    return this.note$.pipe(
      map(({ immutable }) => `${immutable ? 'Unlock' : 'Lock'} Note`)
    );
  }

  private _setControlValue(note: IClinicalNote): void {
    this.noteControl.setValue(note.content, { emitEvent: false });
  }
}
