import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateForPlatform',
})
export class TranslateForPlatformPipe implements PipeTransform {
  transform(tooltip: string): string {
    const isMac: boolean = /(Mac)/i.test(window.navigator.platform);
    if (isMac) {
      tooltip = tooltip.replace('Mod', 'Cmd');
    }
    tooltip = tooltip.replace('Mod', 'Ctrl');
    Object.entries(keyMap).forEach(([key, value]) => {
      tooltip = tooltip.replace(key, value);
    });
    return tooltip;
  }
}

const keyMap = {
  Shift: '⇧',
  ArrowUp: '↑',
  ArrowDown: '↓',
  ArrowLeft: '←',
  ArrowRight: '→',
  Enter: '↵',
  Backspace: '⌫',
  Delete: '⌦',
  Escape: '⎋',
  Tab: '⇥',
  Alt: '⎇',
  Control: '⌃',
  Ctrl: '⌃',
  Meta: '⌘',
  Cmd: '⌘',
  Command: '⌘',
};
