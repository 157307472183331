import {
  SourceEntityMigrationType,
  type ISourceEntity,
  type IPracticeMigration,
  type IExpectedSourceRecordSize,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  toTimestamp,
  type Timestamp,
  type Timezone,
  type WithRef,
} from '@principle-theorem/shared';
import { flow, groupBy, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { OFFSET_PLACEHOLDER } from '../../../source/source-helpers';
import { runQuery } from '../../../source/connection';

export const STERILISATION_RECORD_RESOURCE_TYPE = 'sterilisationRecord';

export const STERILISATION_RECORD_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Sterilisation Record List',
      description: '',
      idPrefix: STERILISATION_RECORD_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ID4WSterilisationRecord {
  id: number;
  pack_id: number;
  treatment_id: number;
  patient_id: number;
  practice_id: number;
  barcode: string;
  contents: string;
  date_used: string;
  date_sterilised: string;
}

export function isD4WSterilisationRecord(
  item: unknown
): item is ID4WSterilisationRecord {
  return TypeGuard.interface<ID4WSterilisationRecord>({
    id: isNumber,
    pack_id: isNumber,
    treatment_id: isNumber,
    patient_id: isNumber,
    practice_id: isNumber,
    barcode: isString,
    contents: isString,
    date_used: isString,
    date_sterilised: isString,
  })(item);
}

export interface ID4WSterilisationRecordTranslations {
  dateSterilised: Timestamp;
  dateUsed: Timestamp;
}

export interface ID4WSterilisationRecordFilters {
  patientId: string;
  dateSterilised: Timestamp;
  dateUsed: Timestamp;
}

const STERILISATION_ESTIMATE_QUERY = `
SELECT
  sterilisation_used.id
FROM (
  SELECT
    id_pack_treat AS id,
	id_treat AS treatment_id
  FROM ast_pack_treatment
) AS sterilisation_used
  INNER JOIN (
    SELECT
      treat_id,
      patient_id
    FROM treat
  ) AS treatment
ON sterilisation_used.treatment_id = treatment.treat_id
`;

const STERILISATION_RECORD_SOURCE_QUERY = `
SELECT
  sterilisation_used.*,
  sterilisation_pack.barcode,
  sterilisation_pack.contents,
  sterilisation_pack.date_sterilised,
  treatment.patient_id,
  steriliser.practice_id
FROM (
  SELECT
    id_pack_treat AS id,
    id_pack AS pack_id,
    id_treat AS treatment_id,
    datetime1 AS date_used
  FROM ast_pack_treatment
  ORDER BY id_pack_treat
  ${OFFSET_PLACEHOLDER}
  ) AS sterilisation_used
  INNER JOIN (
    SELECT
      id_pack,
      bar_code::TEXT AS barcode,
      contents,
      sync_date AS date_sterilised
    FROM ast_packs
  ) AS sterilisation_pack
  ON sterilisation_used.pack_id = sterilisation_pack.id_pack
  INNER JOIN (
    SELECT
      id_pack,
      id_cycle
    FROM ast_pack_cycle
  ) AS pack_cycle
  ON pack_cycle.id_pack = sterilisation_used.pack_id
  INNER JOIN (
    SELECT
      id_cycle,
      id_sterilizer
    FROM ast_cycles
  ) AS cycle
  ON cycle.id_cycle = pack_cycle.id_cycle
  INNER JOIN (
    SELECT
      id_practice AS practice_id,
      id_sterilizer
    FROM ast_sterilizers
  ) AS steriliser
  ON convert_to_text(steriliser.id_sterilizer) = convert_to_text(cycle.id_sterilizer)
  INNER JOIN (
    SELECT
      treat_id,
      patient_id
    FROM treat
  ) AS treatment
ON convert_to_text(sterilisation_used.treatment_id) = convert_to_text(treatment.treat_id)
`;

const STERILISATION_RECORD_SOURCE_QUERY_V2 = `
SELECT
  sterilisation_used.*,
  sterilisation_pack.barcode,
  sterilisation_pack.contents,
  sterilisation_pack.date_sterilised,
  treatment.patient_id,
  steriliser.practice_id
FROM (
  SELECT
    id_pack_treat AS id,
    id_pack AS pack_id,
    id_treat AS treatment_id,
    datetime1 AS date_used
  FROM ast_pack_treatment
  ORDER BY id_pack_treat
  ${OFFSET_PLACEHOLDER}
  ) AS sterilisation_used
  INNER JOIN (
    SELECT
      id_pack,
      bar_code::TEXT AS barcode,
      contents,
      datetime_ret AS date_sterilised
    FROM ast_packs
  ) AS sterilisation_pack
  ON sterilisation_used.pack_id = sterilisation_pack.id_pack
  INNER JOIN (
    SELECT
      id_pack,
      id_cycle
    FROM ast_pack_cycle
  ) AS pack_cycle
  ON pack_cycle.id_pack = sterilisation_used.pack_id
  INNER JOIN (
    SELECT
      id_cycle,
      id_sterilizer
    FROM ast_cycles
  ) AS cycle
  ON cycle.id_cycle = pack_cycle.id_cycle
  INNER JOIN (
    SELECT
      id_practice AS practice_id,
      id_sterilizer
    FROM ast_sterilizers
  ) AS steriliser
  ON convert_to_text(steriliser.id_sterilizer) = convert_to_text(cycle.id_sterilizer)
  INNER JOIN (
    SELECT
      treat_id,
      patient_id
    FROM treat
  ) AS treatment
ON convert_to_text(sterilisation_used.treatment_id) = convert_to_text(treatment.treat_id)
`;

export class SterilisationRecordSourceEntity extends BaseSourceEntity<
  ID4WSterilisationRecord,
  ID4WSterilisationRecordTranslations,
  ID4WSterilisationRecordFilters
> {
  sourceEntity = STERILISATION_RECORD_SOURCE_ENTITY;
  entityResourceType = STERILISATION_RECORD_RESOURCE_TYPE;
  sourceQuery = [
    STERILISATION_RECORD_SOURCE_QUERY,
    STERILISATION_RECORD_SOURCE_QUERY_V2,
  ];
  verifySourceFn = isD4WSterilisationRecord;
  override transformDataFn = flow([]);
  override defaultOffsetSize = 25000;
  override dateFilterField: keyof ID4WSterilisationRecordFilters =
    'dateSterilised';

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const response = await runQuery<{ id: number }>(
      migration,
      STERILISATION_ESTIMATE_QUERY
    );

    const expectedSize = Object.values(
      groupBy(response.rows, (item) => `${item.id}`)
    ).length;

    return {
      expectedSize,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }

  translate(
    data: ID4WSterilisationRecord,
    timezone: Timezone
  ): ID4WSterilisationRecordTranslations {
    return {
      dateUsed: toTimestamp(
        moment.tz(data.date_used, ISO_DATE_TIME_FORMAT, timezone)
      ),
      dateSterilised: toTimestamp(
        moment.tz(data.date_sterilised, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getSourceRecordId(data: ID4WSterilisationRecord): number {
    return data.id;
  }

  getSourceLabel(data: ID4WSterilisationRecord): string {
    return `${data.id}`;
  }

  getFilterData(
    data: ID4WSterilisationRecord,
    timezone: Timezone
  ): ID4WSterilisationRecordFilters {
    return {
      patientId: data.patient_id.toString(),
      dateUsed: toTimestamp(
        moment.tz(data.date_used, ISO_DATE_TIME_FORMAT, timezone)
      ),
      dateSterilised: toTimestamp(
        moment.tz(data.date_sterilised, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }
}
