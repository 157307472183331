<mat-toolbar color="accent">Sterilisation Record Edit History</mat-toolbar>

<mat-dialog-content class="p-4">
  @if (data.history.length) {
    <mat-table class="mb-4" [dataSource]="dataSource">
      <mat-header-row *matHeaderRowDef="displayedColumns" />
      <mat-row *matRowDef="let row; columns: displayedColumns" />

      <ng-container matColumnDef="cycle">
        <mat-header-cell *matHeaderCellDef>Cycle ID</mat-header-cell>
        <mat-cell *matCellDef="let record">{{ record.cycle?.name }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef>Record ID</mat-header-cell>
        <mat-cell *matCellDef="let record">
          {{ record.data }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="editedAt">
        <mat-header-cell *matHeaderCellDef>Edited At</mat-header-cell>
        <mat-cell *matCellDef="let record">
          {{ record.archivedAt | moment | amDateFormat: dateFormat }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="editedBy">
        <mat-header-cell *matHeaderCellDef>Edited By</mat-header-cell>
        <mat-cell *matCellDef="let record">
          @if (record.archivedBy | map: getEditedBy | async; as editedBy) {
            {{ editedBy.user.name }}
          }
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="patient">
        <mat-header-cell *matHeaderCellDef>Patient</mat-header-cell>
        <mat-cell *matCellDef="let record">
          {{ record.patient?.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="appointment">
        <mat-header-cell *matHeaderCellDef>Appointment</mat-header-cell>
        <mat-cell *matCellDef="let record">
          {{ record.appointment }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="scannedBy">
        <mat-header-cell *matHeaderCellDef>Scanned By</mat-header-cell>
        <mat-cell *matCellDef="let record">
          {{ record.scannedBy?.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="pack">
        <mat-header-cell *matHeaderCellDef>Pack</mat-header-cell>
        <mat-cell *matCellDef="let record">
          {{ record.pack?.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="notes">
        <mat-header-cell *matHeaderCellDef>Notes</mat-header-cell>
        <mat-cell *matCellDef="let record">
          <div [ptTruncateTooltip]="record.notes" class="truncate">
            {{ record.notes | map: getNoteContent }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
        <mat-cell *matCellDef="let record">
          <pr-status-label
            [label]="record.status"
            [dense]="true"
            [color]="record.status | map: getStatusColour"
          />
        </mat-cell>
      </ng-container>
    </mat-table>
  } @else {
    <pr-empty-state image="list" title="edit history" />
  }
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>Close</button>
</mat-dialog-actions>
