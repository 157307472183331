import { type INamedDocument } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { type IStaffer } from '../../staffer/staffer';
import { type IChartedRef } from './charted-item';

export interface IChartedSurface {
  uuid: string;
  chartedBy: INamedDocument<IStaffer>;
  chartedAt: Timestamp;
  resolvedBy?: INamedDocument<IStaffer>;
  resolvedAt?: Timestamp;
  chartedRef: Partial<IChartedRef>;
}

export enum ChartedSurfaceType {
  Condition = 'Condition',
  Treatment = 'Treatment',
  MultiTreatment = 'MultiTreatment',
}
