import {
  type CdkDropList,
  type CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { type Observable, Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

type ObservableDropLists<T> = Observable<CdkDropList<T>[]>;

export interface IDragDropNode<P, T> {
  parent: P;
  items: T[];
}

export interface IDropEvent<P, T> {
  previous: CdkDropList<IDragDropNode<P, T>>;
  current: CdkDropList<IDragDropNode<P, T>>;
}

export class DragDropGroup<P, T> {
  private _afterDrop$: Subject<IDropEvent<P, T>> = new Subject();
  private _componentLists$: BehaviorSubject<ObservableDropLists<T>[]> =
    new BehaviorSubject<ObservableDropLists<T>[]>([]);
  lists$: ObservableDropLists<T>;

  constructor() {
    this.lists$ = this._combineAllLists$();
  }

  get afterDrop$(): Observable<IDropEvent<P, T>> {
    return this._afterDrop$.asObservable();
  }

  register(componentLists$: ObservableDropLists<T>): void {
    const componentLists: ObservableDropLists<T>[] =
      this._componentLists$.value;
    componentLists.push(componentLists$);
    this._componentLists$.next(componentLists);
  }

  unregister(componentLists$: ObservableDropLists<T>): void {
    const componentLists: ObservableDropLists<T>[] =
      this._componentLists$.value.filter(
        (lists$: ObservableDropLists<T>) => lists$ !== componentLists$
      );
    this._componentLists$.next(componentLists);
  }

  drop(event: CdkDragDrop<IDragDropNode<P, T>>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data.items,
        event.previousIndex,
        event.currentIndex
      );
      this._afterDrop$.next({
        previous: event.previousContainer,
        current: event.container,
      });
      return;
    }
    transferArrayItem(
      event.previousContainer.data.items,
      event.container.data.items,
      event.previousIndex,
      event.currentIndex
    );
    this._afterDrop$.next({
      previous: event.previousContainer,
      current: event.container,
    });
  }

  private _combineAllLists$(): ObservableDropLists<T> {
    return this._componentLists$.pipe(
      map((data: ObservableDropLists<T>[]) => combineLatest([...data])),
      switchMap((data: Observable<CdkDropList<T>[][]>) => data),
      map((lists: CdkDropList<T>[][]) => this._combineAllLists(lists))
    );
  }

  private _combineAllLists(
    componentLists: CdkDropList<T>[][]
  ): CdkDropList<T>[] {
    return componentLists.reduce(
      (lists: CdkDropList<T>[], item: CdkDropList<T>[]) => [...lists, ...item],
      []
    );
  }
}
