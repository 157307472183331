import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Output,
} from '@angular/core';
import data from '@emoji-mart/data';
import { Picker } from 'emoji-mart';

export interface IEmoji {
  emoticons: string[];
  id: string;
  keywords: string[];
  name: string;
  native: string;
  shortcodes: string;
  unified: string;
}

@Component({
  selector: 'pt-emoji-selector',
  templateUrl: './emoji-selector.component.html',
  styleUrls: ['./emoji-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class EmojiSelectorComponent {
  @Output() emojiSelect = new EventEmitter<string>();

  constructor(elementRef: ElementRef<HTMLElement>) {
    const picker = new Picker({
      data,
      autoFocus: true,
      theme: 'light',
      onEmojiSelect: (event: IEmoji) => this.emojiSelect.emit(event.native),
    });

    elementRef.nativeElement.appendChild(picker as unknown as Node);
  }
}
