export enum SideBarMode {
  Push = 'push',
  Side = 'side',
  Over = 'over',
}

export interface ISidebarState {
  readonly mode: SideBarMode;
  readonly expanded: boolean;
  readonly opened: boolean;
}

export class MobileClosedState implements ISidebarState {
  readonly mode: SideBarMode = SideBarMode.Over;
  readonly expanded: boolean = true;
  readonly opened: boolean = false;
}

export class MobileOpenState implements ISidebarState {
  readonly mode: SideBarMode = SideBarMode.Over;
  readonly expanded: boolean = true;
  readonly opened: boolean = true;
}

export class DesktopMinimisedState implements ISidebarState {
  readonly mode: SideBarMode = SideBarMode.Side;
  readonly expanded: boolean = false;
  readonly opened: boolean = true;
}

export class DesktopExpandedState implements ISidebarState {
  readonly mode: SideBarMode = SideBarMode.Side;
  readonly expanded: boolean = true;
  readonly opened: boolean = true;
}
