import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { multiFilter } from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ITransactionAction,
  ITransactionActionsData,
  TransactionActionHelpers,
} from '../transaction-providers/transaction-action';
import { TransactionActionFactoryService } from '../transaction-providers/transaction-action-factory.service';

@Component({
    selector: 'pr-transaction-report-actions',
    templateUrl: './transaction-report-actions.component.html',
    styleUrls: ['./transaction-report-actions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TransactionReportActionsComponent {
  data$ = new ReplaySubject<ITransactionActionsData<unknown>>(1);
  trackByAction = TrackByFunctions.label<ITransactionAction<unknown>>();
  trackByInfo = TrackByFunctions.variable<string>();
  actions$: Observable<ITransactionAction<unknown>[]>;

  @Input()
  set data(actionsData: ITransactionActionsData<unknown>) {
    if (actionsData) {
      this.data$.next(actionsData);
    }
  }

  constructor(transactionActions: TransactionActionFactoryService) {
    this.actions$ = this.data$.pipe(
      map((data) => transactionActions.getActions(data.latestTransaction)),
      multiFilter((action) => !action.excludeFromReport)
    );
  }

  getInfo$(
    input: [ITransactionAction<unknown>, ITransactionActionsData<unknown>]
  ): Observable<string[]> {
    return TransactionActionHelpers.getInfo$(input[0], input[1]);
  }
}
