import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { type ILab } from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  findProp,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

@Component({
  selector: 'pr-lab-contacts',
  templateUrl: './lab-contacts.component.html',
  styleUrls: ['./lab-contacts.component.scss'],
})
export class LabContactsComponent {
  lab$: Observable<WithRef<ILab>>;

  constructor(private _route: ActivatedRoute) {
    this.lab$ = this._route.data.pipe(
      findProp<WithRef<ILab>>('lab'),
      filterUndefined()
    );
  }
}
