import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import {
  CurrentPatientScope,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { ButtonsContainerComponent } from '@principle-theorem/ng-shared';
import {
  IInvoice,
  ITooth,
  ITreatmentPlan,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  filterUndefined,
  snapshot,
} from '@principle-theorem/shared';
import { ITreatmentPlanStepPair } from '../../treatment-plans-consolidated/treatment-plans-consolidated.store';
import { ToothHistoryComponent } from '../tooth-history/tooth-history.component';

export interface IToothHistoryDialogData {
  tooth: ITooth;
  consolidatedPlan: ITreatmentPlanStepPair[];
}

@Component({
  selector: 'pr-tooth-history-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatToolbarModule,
    MatButtonModule,
    ToothHistoryComponent,
    MatIconModule,
    ButtonsContainerComponent,
  ],
  templateUrl: './tooth-history-dialog.component.html',
  styleUrls: ['./tooth-history-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToothHistoryDialogComponent {
  private _patientScope = inject(CurrentPatientScope);
  private _stateNav = inject(StateBasedNavigationService);

  private _data = inject<IToothHistoryDialogData>(MAT_DIALOG_DATA);
  private _router = inject(Router);
  selectedTeeth = signal([this._data.tooth]);
  patient = toSignal(this._patientScope.doc$.pipe(filterUndefined()));

  updateSelectedTeeth(teeth: ITooth[]): void {
    this.selectedTeeth.set(teeth);
  }

  async openPlan(ref: DocumentReference<ITreatmentPlan>): Promise<void> {
    return this._openDocument(ref, ['treatment-plans']);
  }

  async openInvoice(ref: DocumentReference<IInvoice>): Promise<void> {
    return this._openDocument(ref, ['account', 'invoices']);
  }

  private async _openDocument<T>(
    ref: DocumentReference<T>,
    pathSegments: string[]
  ): Promise<void> {
    const patient = this.patient();

    if (!patient) {
      return;
    }

    const path = ['patients', patient.ref.id, ...pathSegments, ref.id];
    const route = await snapshot(this._stateNav.link.brand$(path));
    const url = this._router.serializeUrl(this._router.createUrlTree(route));
    window.open(url, '_blank');
  }
}
