import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PrincipleEditorModule } from '@principle-theorem/ng-interactions';
import {
  PrescriptionItemFormComponent,
  PrescriptionItemFormGroup,
} from '@principle-theorem/ng-patient';
import { ButtonsContainerComponent } from '@principle-theorem/ng-shared';
import { PrescriptionItem } from '@principle-theorem/principle-core';
import { IPrescriptionItem } from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';

@Component({
    selector: 'pr-prescription-item-dialog',
    imports: [
        CommonModule,
        MatDialogModule,
        MatToolbarModule,
        MatButtonModule,
        ButtonsContainerComponent,
        PrincipleEditorModule,
        PrescriptionItemFormComponent,
    ],
    templateUrl: './prescription-item-dialog.component.html',
    styleUrl: './prescription-item-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrescriptionItemDialogComponent {
  private _dialogRef = inject(
    MatDialogRef<
      PrescriptionItemDialogComponent,
      WithRef<IPrescriptionItem> | IPrescriptionItem
    >
  );
  data = inject<WithRef<IPrescriptionItem> | undefined>(MAT_DIALOG_DATA);
  prescriptionItemFormGroup = new PrescriptionItemFormGroup();

  constructor() {
    if (this.data) {
      this.prescriptionItemFormGroup.patchValue(this.data, {
        emitEvent: false,
      });
    }
  }

  save(): void {
    if (!this.prescriptionItemFormGroup.valid) {
      return;
    }

    const value = this.prescriptionItemFormGroup.getRawValue();
    const item = this.data
      ? { ...this.data, ...value }
      : PrescriptionItem.init(value);

    this._dialogRef.close(item);
  }
}
