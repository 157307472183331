import { IBaseMigrationItemCode } from '../../../interfaces';
import { ItemCodesMappingHandler } from '../../../mappings/item-codes';
import {
  IOasisItemCode,
  ItemCodeSourceEntity,
} from '../../source/entities/item-codes';

export class OasisItemCodeMappingHandler extends ItemCodesMappingHandler<
  IOasisItemCode,
  ItemCodeSourceEntity
> {
  override sourceEntity = new ItemCodeSourceEntity();
  override translateFn = (record: IOasisItemCode): IBaseMigrationItemCode => ({
    ...record,
    itemCode: record.id,
    description: record.description,
  });
}
