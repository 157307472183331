import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { TreatmentStep } from '@principle-theorem/principle-core';
import {
  type ITreatmentCategory,
  type ITreatmentStepDisplay,
} from '@principle-theorem/principle-core/interfaces';
import { isSameRef, snapshot, type WithRef } from '@principle-theorem/shared';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { GlobalStoreService } from '../../store/global-store.service';

@Component({
  selector: 'pr-treatment-step-category-indicator',
  templateUrl: './treatment-step-category-indicator.component.html',
  styleUrls: ['./treatment-step-category-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentStepCategoryIndicatorComponent {
  trackByRef = TrackByFunctions.ref<WithRef<ITreatmentCategory>>();
  treatmentStepDisplay$ = new ReplaySubject<ITreatmentStepDisplay>(1);
  treatmentCategory$: Observable<WithRef<ITreatmentCategory> | undefined>;
  treatmentCategories$: Observable<WithRef<ITreatmentCategory>[]>;
  isOverride$ = new BehaviorSubject<boolean>(false);
  overrideTooltip$: Observable<string>;
  @Output() stepDisplayChange = new EventEmitter<ITreatmentStepDisplay>();

  @Input()
  set treatmentStepDisplay(treatmentStepDisplay: ITreatmentStepDisplay) {
    if (treatmentStepDisplay) {
      this.treatmentStepDisplay$.next(treatmentStepDisplay);
    }
  }

  constructor(private _globalStore: GlobalStoreService) {
    this.treatmentCategory$ = this.treatmentStepDisplay$.pipe(
      tap((display) =>
        display.overrideTreatmentCategory
          ? this.isOverride$.next(true)
          : this.isOverride$.next(false)
      ),
      switchMap((display) => this._resolveTreatmentCategory$(display))
    );

    this.treatmentCategories$ = combineLatest(
      this._globalStore.treatmentCategories$,
      this.treatmentCategory$
    ).pipe(
      map(([categories, currentCategory]) =>
        categories.filter((category) => !isSameRef(category, currentCategory))
      )
    );

    this.overrideTooltip$ = this.treatmentStepDisplay$.pipe(
      map((display) =>
        display.primaryTreatmentCategory ? `Reset to default` : 'Clear'
      )
    );
  }

  async upsertOverride(
    selectedTreatmentCategory: WithRef<ITreatmentCategory>
  ): Promise<void> {
    const treatmentStepDisplay = await snapshot(this.treatmentStepDisplay$);
    const treatmentCategory = await snapshot(this.treatmentCategory$);

    if (isSameRef(treatmentCategory, selectedTreatmentCategory)) {
      return;
    }

    this.stepDisplayChange.emit({
      ...treatmentStepDisplay,
      overrideTreatmentCategory: selectedTreatmentCategory.ref,
    });
  }

  async deleteOverride(): Promise<void> {
    const treatmentStepDisplay = await snapshot(this.treatmentStepDisplay$);

    this.stepDisplayChange.emit({
      ...treatmentStepDisplay,
      overrideTreatmentCategory: undefined,
    });
  }

  private _resolveTreatmentCategory$(
    display: ITreatmentStepDisplay
  ): Observable<WithRef<ITreatmentCategory> | undefined> {
    const defaultRef = TreatmentStep.defaultDisplayRef(display);
    return defaultRef
      ? this._globalStore.getTreatmentCategory$(defaultRef)
      : of(undefined);
  }
}
