<mat-toolbar color="accent">{{ data ? 'Edit Note' : 'Add Note' }}</mat-toolbar>

<mat-dialog-content>
  <mat-form-field class="w-full">
    <mat-label>Notes</mat-label>
    <pr-content-editor
      placeholder="Notes"
      [menuEnabled]="false"
      [formControl]="noteCtrl"
      (keydown.meta.Enter)="saveNote()"
     />
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button [mat-dialog-close]="false">Close</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="saveDisabled$ | async"
      (click)="saveNote()"
    >
      Save
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
