import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentPracticeScope } from '@principle-theorem/ng-principle-shared';
import { type IBreadcrumb } from '@principle-theorem/ng-shared';
import { type IPractice } from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  findProp,
  Region,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-practice-integrations',
    templateUrl: './practice-integrations.component.html',
    styleUrls: ['./practice-integrations.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PracticeIntegrationsComponent {
  practice$: Observable<WithRef<IPractice>>;
  breadcrumbs$: Observable<IBreadcrumb[]>;
  region = Region;

  constructor(
    private _route: ActivatedRoute,
    private _currentPractice: CurrentPracticeScope
  ) {
    this.practice$ = this._route.data.pipe(
      findProp<WithRef<IPractice>>('practice'),
      filterUndefined()
    );

    this.breadcrumbs$ = this._currentPractice.doc$.pipe(
      filterUndefined(),
      map((practice) => [
        { label: 'Settings', path: '../../../' },
        { label: practice.name },
        { label: 'Integrations' },
      ])
    );
  }
}
