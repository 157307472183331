<mat-toolbar color="accent">
  <h1>Medipass Integration</h1>
</mat-toolbar>

<form [formGroup]="medipassForm" (ngSubmit)="submit()">
  <mat-dialog-content>
    <mat-form-field fxFlex>
      <mat-label>API Key</mat-label>
      <input matInput formControlName="apiKey" autofocus />
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary">Submit</button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
