import {
  QueryConstraint,
  UpdateData,
  doc,
  getDoc as getDocFirestore,
  getDocs as getDocsFirestore,
  setDoc,
  updateDoc as updateDocFirestore,
  type CollectionReference,
  DocumentReference,
  type DocumentSnapshot,
  type Query,
  type QueryDocumentSnapshot,
  type QuerySnapshot,
  type SetOptions,
  type Timestamp,
  type Transaction,
  docExists,
  where,
} from './adaptor';
import { get, isString, isUndefined, omit } from 'lodash';
import { type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { isObject } from '../../common';
import { defaultOnError, filterUndefined, findProp } from '../../rxjs';
import { toFirestore, fromFirestore } from '../../serialisation-provider';
import { toTimestamp } from '../../time/time';
import {
  SystemActors,
  type IReffable,
  type Reffable,
  type UnwrapReffable,
  type WithId,
  type WithRef,
} from '../interfaces';
import { firstResult, getParentColRef, isColRef, toQuery } from './collection';
import { FirestoreScheduler } from './firestore-scheduler';
import { store } from './adaptor';
import { fromDocRef } from './from-ref';
import { type ISoftDelete } from './model';
import { DatabaseUsageTracker } from './usage-tracking';

export function isDocRef<T extends object>(
  item: unknown
): item is DocumentReference<T> {
  return (
    isObject(item) &&
    'path' in item &&
    'id' in item &&
    (item.type === 'document' || 'collection' in item)
  );
}

/**
 * @param path Must be a document path
 */
export function getParentDocPath(path: string | DocumentReference): string {
  if (isDocRef(path)) {
    path = path.path;
  }
  const docPath: string[] = path.split('/');
  docPath.pop();
  docPath.pop();
  return docPath.join('/');
}

/**
 * @deprecated
 * Use Firestore.getParentDocRef instead
 */
export function getParentDocRef<T extends object>(
  path: string | DocumentReference
): DocumentReference<T> {
  const parentPath = getParentDocPath(path);
  return getDocRef<T>(parentPath);
}

export function asDocRef<T extends object>(
  ref: Reffable<T> | DocumentReference<unknown> | string
): DocumentReference<T> {
  if (isString(ref)) {
    return getDocRef<T>(ref, FirestoreScheduler.appName);
  }
  if (isReffable(ref)) {
    return ref.ref;
  }
  return ref as DocumentReference<T>;
}

export interface IAction<T> {
  type: string;
  payload: T;
}

export function getDocRef<T>(
  path: string,
  appName?: string
): DocumentReference<T> {
  return doc(store(appName), path) as DocumentReference<T>;
}

/**
 * @deprecated
 */
export function doc$<T extends object>(
  ref: DocumentReference<T> | string
): Observable<WithRef<T>> {
  return fromDocRef(asDocRef<T>(ref)).pipe(
    map((docSnapshot) => snapshotToWithRef<T>(docSnapshot.payload))
  );
}

export function docSnapshot$<T extends object>(
  ref: DocumentReference<T> | string
): Observable<DocumentSnapshot<T>> {
  return fromDocRef(asDocRef<T>(ref)).pipe(
    map((docSnapshot) => docSnapshot.payload)
  );
}

export function snapshotToWithRef<T extends object>(
  docSnapshot: DocumentSnapshot<T> | QueryDocumentSnapshot<T>
): WithRef<T> {
  const data = docSnapshot.data();
  if (!data) {
    throw new Error(
      `Snapshot points to document that doesn't exist: ${docSnapshot.ref.path}`
    );
  }
  return {
    createdAt: toTimestamp(),
    updatedAt: toTimestamp(),
    ...fromFirestore(data),
    ref: docSnapshot.ref,
  };
}

export function safeSnapshotToWithRef<T extends object>(
  docSnapshot?: DocumentSnapshot<T> | QueryDocumentSnapshot<T>
): WithRef<T> | undefined {
  try {
    return docSnapshot && docSnapshot.exists
      ? snapshotToWithRef(docSnapshot)
      : undefined;
  } catch (e) {
    return;
  }
}

export function toWithRef<T>(ref: DocumentReference<T>, data: T): WithRef<T> {
  return {
    createdAt: toTimestamp(),
    updatedAt: toTimestamp(),
    ...data,
    ref,
  };
}

export async function getQuerySnapshot<T extends object>(
  col: CollectionReference<T> | Query<T>
): Promise<QuerySnapshot<T>> {
  try {
    const result = await getDocsFirestore(col);
    if (result.docs[0]) {
      DatabaseUsageTracker.track(
        result.docs[0].ref.parent.path,
        'get',
        'collection',
        result.docs.length
      );
    }
    return result;
  } catch (error) {
    const path: unknown = get(col, '_query.path.segments');
    // eslint-disable-next-line no-console
    console.error('getQuerySnapshot failed', path, error);
    throw error;
  }
}

/**
 * @deprecated
 * Use Firestore.getDocs instead
 */
export async function getDocs<T extends object>(
  col: CollectionReference<T> | Query<T>,
  ...queryConstraints: QueryConstraint[]
): Promise<WithRef<T>[]> {
  const query = toQuery(col, ...queryConstraints);
  const colSnapshot = await getQuerySnapshot(query);
  return colSnapshot.docs.map((docSnapshot) =>
    snapshotToWithRef<T>(docSnapshot)
  );
}

/**
 * @deprecated
 * Use Firestore.getDoc instead
 */
export async function getDoc<T extends object>(
  ref: DocumentReference<T>,
  transaction?: Transaction
): Promise<WithRef<T>> {
  try {
    const snapshot = await (transaction
      ? transaction.get(ref)
      : getDocFirestore(ref));
    DatabaseUsageTracker.track(snapshot.ref.parent.path, 'get', 'document');
    return snapshotToWithRef<T>(snapshot);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('getDoc failed', ref.path, error);
    throw error;
  }
}

export function isReffable<T>(item: unknown): item is IReffable<T> {
  return isObject(item) && 'ref' in item && isDocRef(item.ref);
}

export function isWithRef<T>(item: unknown): item is WithRef<T> {
  return (
    isObject(item) &&
    'ref' in item &&
    isDocRef(item.ref) &&
    'createdAt' in item &&
    'updatedAt' in item
  );
}

export function isWithId<T>(item: unknown): item is WithId<T> {
  return isObject(item) && 'uid' in item;
}

export interface IDocUpdateOptions {
  omitUpdateTimestamp: boolean;
}

/**
 * @deprecated
 */
export async function saveDoc<T extends object>(
  item: Reffable<T> | UnsavedDocument<T>,
  options?: SetOptions,
  transaction?: Transaction,
  updateOptions?: IDocUpdateOptions,
  updatedBy: SystemActors | string = SystemActors.Unknown
): Promise<DocumentReference<T>> {
  const reffable = !isReffable(item) ? unsavedDocumentToReffable(item) : item;
  if (!isObject(reffable)) {
    throw new Error(`Can't save a non object type`);
  }
  const timestamp: Timestamp = toTimestamp();
  const serialisedData = toFirestore(reffable);
  const baseData = updateOptions?.omitUpdateTimestamp
    ? {
        ...serialisedData,
        updatedBy,
      }
    : {
        ...serialisedData,
        updatedAt: timestamp,
        updatedBy,
      };
  const isCreateAction =
    (!options || ('merge' in options && !options?.merge)) &&
    !serialisedData.createdAt;
  const data = isCreateAction
    ? { ...baseData, createdAt: timestamp, createdBy: updatedBy }
    : baseData;

  if (transaction) {
    transaction.set(reffable.ref, data, options ?? {});
    return reffable.ref;
  }
  await setDoc(reffable.ref, data, options ?? {});
  return reffable.ref;
}

/**
 * @deprecated
 */
export async function patchDoc<T>(
  ref: DocumentReference<T>,
  data: Partial<T>,
  transaction?: Transaction,
  options?: IDocUpdateOptions,
  updatedBy: SystemActors | string = SystemActors.Unknown
): Promise<DocumentReference<Partial<T>>> {
  return saveDoc<Partial<T>>(
    {
      ...data,
      ref: ref as DocumentReference<Partial<T>>,
    },
    {
      merge: true,
    },
    transaction,
    options,
    updatedBy
  );
}

export async function updateDoc<T extends object>(
  ref: DocumentReference<T>,
  data: Partial<T>,
  transaction?: Transaction,
  updatedBy: SystemActors | string = SystemActors.Unknown
): Promise<void> {
  const serialisedData = {
    ...toFirestore(data),
    updatedAt: toTimestamp(),
    updatedBy,
  } as unknown as UpdateData<T>;

  if (transaction) {
    transaction.update(ref, serialisedData);
    return;
  }
  await updateDocFirestore(ref, serialisedData);
}

export async function addDocAsWithRef<T extends object>(
  collection: CollectionReference<T>,
  item: T,
  uid?: string,
  transaction?: Transaction,
  updatedBy: SystemActors | string = SystemActors.Unknown
): Promise<WithRef<T>> {
  const docRef = uid ? doc<T>(collection, uid) : doc<T>(collection);
  const timestamp = toTimestamp();
  const data = {
    createdAt: timestamp,
    updatedAt: timestamp,
    ...toFirestore(item),
    ref: docRef,
    createdBy: updatedBy,
    updatedBy,
  };

  if (transaction) {
    transaction.set(docRef, data);
  } else {
    await setDoc(docRef, data);
  }
  return toWithRef(docRef, data);
}

export async function addDoc<T extends object>(
  collection: CollectionReference<T>,
  item: T,
  uid?: string,
  transaction?: Transaction,
  updatedBy: SystemActors | string = SystemActors.Unknown
): Promise<DocumentReference<T>> {
  const docWithRef = await addDocAsWithRef(
    collection,
    item,
    uid,
    transaction,
    updatedBy
  );
  return docWithRef.ref;
}

export async function upsertDoc<T extends object>(
  collection: CollectionReference<T>,
  item: T,
  uid?: string,
  transaction?: Transaction,
  options?: IDocUpdateOptions,
  updatedBy: SystemActors | string = SystemActors.Unknown
): Promise<DocumentReference<T>> {
  if (uid) {
    const ref = doc<T>(collection, uid);
    if (await docExists(ref)) {
      return (await patchDoc(
        ref,
        item,
        transaction,
        options,
        updatedBy
      )) as DocumentReference<T>;
    }
  }
  return addDoc(collection, item, uid, transaction, updatedBy);
}

export async function upsertDocByProperty<T extends object>(
  collection: CollectionReference<T>,
  item: T,
  property: keyof T,
  uid: string,
  transaction?: Transaction,
  options?: IDocUpdateOptions,
  updatedBy: SystemActors | string = SystemActors.Unknown
): Promise<DocumentReference<T>> {
  const ref = await firstResult(
    collection,
    where(property.toString(), '==', uid)
  );
  return upsertDoc(
    collection,
    item,
    ref?.ref.id,
    transaction,
    options,
    updatedBy
  );
}

export async function deleteDoc<T>(
  ref: DocumentReference<T>,
  transaction?: Transaction,
  updatedBy: SystemActors | string = SystemActors.Unknown,
  data?: Partial<T>
): Promise<void> {
  await patchDoc(
    ref,
    {
      ...data,
      deleted: true,
    } as Partial<T & ISoftDelete>,
    transaction,
    undefined,
    updatedBy
  );
}

export async function undeleteDoc<T>(
  ref: DocumentReference<T>,
  transaction?: Transaction,
  updatedBy: SystemActors | string = SystemActors.Unknown
): Promise<void> {
  await patchDoc(
    ref,
    {
      deleted: false,
    } as Partial<T & ISoftDelete>,
    transaction,
    undefined,
    updatedBy
  );
}

export function resolveDocument<T extends object>(
  docSnapshot: DocumentSnapshot<T>
): T | undefined {
  if (!docSnapshot.exists) {
    return;
  }
  const data = docSnapshot.data();
  if (!data) {
    return;
  }
  return fromFirestore(data);
}

export interface IUnsavedDocumentPointer<T> {
  collectionRef: CollectionReference<T>;
  uid?: string;
}

export function isUnsavedDocumentPointer<T = unknown>(
  data: unknown
): data is IUnsavedDocumentPointer<T> {
  return (
    isObject(data) &&
    isColRef(data.collectionRef) &&
    (isUndefined(data.uid) || isString(data.uid))
  );
}

export function getRefFromUnsavedPointer<T extends object>(
  pointer: IUnsavedDocumentPointer<T>
): DocumentReference<T> {
  return pointer.uid
    ? doc<T>(pointer.collectionRef, pointer.uid)
    : doc<T>(pointer.collectionRef);
}

export type UnsavedDocument<T> = T & {
  unsavedDocPointer: IUnsavedDocumentPointer<T>;
};

export function isUnsavedDocument<T = unknown>(
  data: unknown
): data is UnsavedDocument<T> {
  return isObject(data) && isUnsavedDocumentPointer(data.unsavedDocPointer);
}

export function unsavedDocumentToReffable<T extends object>(
  unsaved: UnsavedDocument<T>
): Reffable<T> {
  const data: T = omit(unsaved, 'unsavedDocPointer') as unknown as T;
  return {
    ...data,
    ref: getRefFromUnsavedPointer(unsaved.unsavedDocPointer),
  };
}

export type WithRefOrUnsaved<T> = WithRef<T> | UnsavedDocument<T>;

export function docOrDefault$<T extends object>(
  ref: DocumentReference<T>,
  defaultValue: T
): Observable<WithRef<T> | UnsavedDocument<T>> {
  const unsavedDocPointer: IUnsavedDocumentPointer<T> = {
    collectionRef: getParentColRef(ref),
    uid: ref.id,
  };
  return doc$(ref).pipe(
    defaultOnError({
      unsavedDocPointer,
      ...defaultValue,
    })
  );
}

export function resolveProp$<T extends IReffable<UnwrapReffable<T>>>(
  data$: Observable<unknown>,
  field: string
): Observable<T> {
  return data$.pipe(
    findProp<T>(field),
    filterUndefined(),
    switchMap((data) => doc$<T>(data.ref as DocumentReference<T>))
  );
}
