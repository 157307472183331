import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type ITag } from '@principle-theorem/principle-core/interfaces';
import {
  INamedDocument,
  isSameRef,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { uniqBy, xorWith } from 'lodash';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  type Observable,
  combineLatest,
  of,
} from 'rxjs';
import { map, skip, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-tag-filter',
    templateUrl: './tag-filter.component.html',
    styleUrls: ['./tag-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TagFilterComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByTag = TrackByFunctions.ref<WithRef<ITag>>();
  filters$: Observable<WithRef<ITag>[]>;
  selectedFilters$ = new BehaviorSubject<WithRef<ITag>[]>([]);
  @Output() selectionChange = new EventEmitter<WithRef<ITag>[]>();
  tags$ = new ReplaySubject<WithRef<ITag>[]>(1);
  selectedTags$ = new ReplaySubject<DocumentReference<ITag>[]>(1);
  selectionList$ = new ReplaySubject<MatSelectionList>(1);
  showNotification$: Observable<boolean>;

  @Input()
  set selectedTags(selectedTags: DocumentReference<ITag>[]) {
    if (selectedTags) {
      this.selectedTags$.next(selectedTags);
    }
  }

  @Input()
  set tags(tags: WithRef<ITag>[]) {
    if (tags) {
      this.tags$.next(tags);
    }
  }

  @ViewChild(MatSelectionList)
  set selectionList(selectionList: MatSelectionList) {
    if (selectionList) {
      this.selectionList$.next(selectionList);
    }
  }

  constructor() {
    this.filters$ = this.tags$.pipe(
      map((tags) =>
        uniqBy(tags, 'name').sort((tagA, tagB) =>
          tagA.name.toLowerCase() > tagB.name.toLowerCase() ? 1 : -1
        )
      )
    );

    this.selectedFilters$
      .pipe(skip(1), takeUntil(this._onDestroy$))
      .subscribe((tags) => this.selectionChange.next(tags));

    this.selectedTags$
      .pipe(
        take(1),
        switchMap((selectedTags) =>
          combineLatest([of(selectedTags), this.selectionList$])
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([selectedTags, selectionList]) => {
        const selected = selectionList.options
          .filter((option) =>
            selectedTags.some((tag) =>
              isSameRef(option.value as WithRef<ITag>, tag)
            )
          )
          .map((option) => option.value as WithRef<ITag>);
        this.selectedFilters$.next(selected);
      });

    this.showNotification$ = this.selectedTags$.pipe(
      map((selectedTags) => selectedTags.length > 0)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  toggleSelected(tag: WithRef<ITag>): void {
    const selected = this.selectedFilters$.value;
    const newSelected = xorWith(selected, [tag], isSameRef);
    this.selectedFilters$.next(newSelected);
  }

  isSelectedNamedDocument(
    namedDocument: INamedDocument,
    selectedNamedDocument: INamedDocument
  ): boolean {
    return isSameRef(namedDocument, selectedNamedDocument);
  }

  isSelected$(tag: WithRef<ITag>): Observable<boolean> {
    return this.selectedFilters$.pipe(
      map((selectedTags) =>
        selectedTags.some((selectedTag) => isSameRef(tag, selectedTag))
      )
    );
  }
}
