import { InjectionToken } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

export const APP_CONFIG: InjectionToken<IAppConfig> =
  new InjectionToken<IAppConfig>('app.config');

export const PRINCIPLE_DI_CONFIG: IAppConfig = {
  snackbarConfig: {
    duration: 3000,
  },
};

export interface IAppConfig {
  snackbarConfig: MatSnackBarConfig;
}
