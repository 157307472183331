<mat-form-field fxFlex fxFill [appearance]="appearance">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    matInput
    [formControl]="dateControl"
    [placeholder]="placeholder"
    [matDatepicker]="datePicker"
    [max]="max"
    [required]="required"
    (blur)="blur()"
  />
  <mat-hint>DD/MM/YYYY</mat-hint>
  <mat-datepicker-toggle
    matIconSuffix
    [for]="datePicker"
   />
  <mat-datepicker #datePicker startView="year" />
</mat-form-field>
