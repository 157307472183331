<div
  class="section-drop-list flex min-h-[50px] flex-col rounded border-2 border-dashed border-transparent"
  cdkDropList
  cdkDropListOrientation="vertical"
  [ngClass]="{ dragging: formBuilder.dragging$ | async }"
  [id]="rootDropListId"
  [cdkDropListConnectedTo]="formBuilder.dropListIds$ | async"
  [cdkDropListData]="formBuilder.layout$ | async"
  [cdkDropListEnterPredicate]="allowOnlySections"
  (cdkDropListDropped)="dragDrop($event)"
>
  @for (element of formBuilder.layout$ | async; track element.uid) {
    <pr-form-builder-element
      [element]="element"
      (dragDrop)="dragDrop($event)"
      (dragStarted)="formBuilder.dragging$.next(true)"
      (dragEnded)="formBuilder.dragging$.next(false)"
    />
  } @empty {
    <div class="p-2 text-xs text-slate-400">
      Drag new Section elements into the layout here
    </div>
  }
</div>
