import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ChartedItemTotalCalculator } from '@principle-theorem/principle-core';
import {
  type IChartedItem,
  type IChartedItemConfiguration,
  type IChartedMultiStepTreatment,
  isChartedMultiStepTreatment,
  type ITreatmentPlan,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-charted-item-header',
  templateUrl: './charted-item-header.component.html',
  styleUrls: ['./charted-item-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartedItemHeaderComponent {
  item$: ReplaySubject<IChartedItem<IChartedItemConfiguration>> =
    new ReplaySubject(1);
  config$ = new ReplaySubject<WithRef<IChartedItemConfiguration>>(1);
  plan$ = new ReplaySubject<
    WithRef<ITreatmentPlan | IChartedMultiStepTreatment | undefined>
  >(1);
  price$: Observable<number>;
  noteTooltipText$: Observable<string>;
  @Output() editSurfaces = new EventEmitter<void>();
  @Input() highlighted = false;
  @Input() compact = false;

  @Input()
  set item(item: IChartedItem<IChartedItemConfiguration>) {
    if (item) {
      this.item$.next(item);
    }
  }

  @Input()
  set plan(
    plan: WithRef<ITreatmentPlan | IChartedMultiStepTreatment | undefined>
  ) {
    this.plan$.next(plan);
  }

  @Input()
  set config(config: WithRef<IChartedItemConfiguration>) {
    if (config) {
      this.config$.next(config);
    }
  }

  constructor() {
    this.price$ = this.item$.pipe(
      map((item) => new ChartedItemTotalCalculator().calc(item))
    );

    this.noteTooltipText$ = this.item$.pipe(
      map((item) => {
        const note = item.notes.length > 1 ? 'notes' : 'note';
        return `${item.notes.length} ${note}`;
      })
    );
  }

  isMultiTreatment(item: IChartedItem<IChartedItemConfiguration>): boolean {
    return isChartedMultiStepTreatment(item);
  }
}
