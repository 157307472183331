import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { type ITransaction } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  buildTransactionAmendmentData$,
  determineMaxAmendmentDate,
  determineMinAmendmentDate,
} from '../../transaction-helpers';

export interface IAmendTransactionDateState {
  minDate?: Moment;
  maxDate?: Moment;
}

const initialState: IAmendTransactionDateState = {
  minDate: undefined,
  maxDate: undefined,
};

@Injectable()
export class AmendTransactionDateStore extends ComponentStore<IAmendTransactionDateState> {
  readonly minDate$ = this.select((state) => state.minDate);
  readonly maxDate$ = this.select((state) => state.maxDate);

  readonly loadAmendDates = this.effect(
    (transaction$: Observable<WithRef<ITransaction>>) =>
      transaction$.pipe(
        switchMap((transaction) => buildTransactionAmendmentData$(transaction)),
        tapResponse(
          ({ currentIndex, transactions, timezone }) =>
            this.patchState({
              minDate: determineMinAmendmentDate(
                currentIndex,
                transactions,
                timezone
              ),
              maxDate: determineMaxAmendmentDate(
                currentIndex,
                transactions,
                timezone
              ),
            }),
          // eslint-disable-next-line no-console
          console.error
        )
      )
  );

  constructor() {
    super(initialState);
  }
}
