import {
  BlockNodes,
  isNodeSchema,
  type NodeSchema,
  type RawSchema,
  recursiveReplace,
  type SchemaDataProvider,
} from '@principle-theorem/editor';
import {
  type IMigration,
  type IMigrationLogger,
  Migration,
} from '@principle-theorem/migration-runner';
import { last, nth } from 'lodash';

@Migration({
  name: 'add-table-wrapper',
  uid: 'b9c77d9b-c708-44ec-a5a0-c070bb00e2bb',
})
export class AddDefaultUserGroupsMigration implements IMigration {
  async up(
    dryRun: boolean,
    _logger: IMigrationLogger,
    data: SchemaDataProvider
  ): Promise<void> {
    const schema = await data.get();
    const newSchema = recursiveReplace(schema, (item, parents) => {
      const parent = last(parents);
      if (
        !isTableSchema(item, parent) ||
        (parent && isTableWrapperSchema(parent, nth(parents, -2)))
      ) {
        return item;
      }
      return {
        type: BlockNodes.TableWrapper,
        content: [item],
      };
    });

    if (!dryRun) {
      await data.update(newSchema as RawSchema);
    }
  }

  async down(
    dryRun: boolean,
    _logger: IMigrationLogger,
    data: SchemaDataProvider
  ): Promise<void> {
    const schema = await data.get();
    const newSchema = recursiveReplace(schema, (item, parents) => {
      if (!isTableWrapperSchema(item, last(parents)) || !item.content) {
        return item;
      }
      return item.content[0];
    });

    if (!dryRun) {
      await data.update(newSchema as RawSchema);
    }
  }
}

function isTableWrapperSchema(
  item: object,
  parent?: object
): item is NodeSchema<BlockNodes.Table> {
  return (
    isNodeSchema(item, parent) &&
    String(item.type) === String(BlockNodes.TableWrapper)
  );
}

function isTableSchema(
  item: object,
  parent?: object
): item is NodeSchema<BlockNodes.Table> {
  return (
    isNodeSchema(item, parent) && String(item.type) === String(BlockNodes.Table)
  );
}
