<button mat-icon-button [matMenuTriggerFor]="moreMenu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #moreMenu="matMenu">
  <ng-container *ngFor="let button of actions; trackBy: trackByAction">
    <div #tooltipOrigin="cdkOverlayOrigin" cdkOverlayOrigin>
      <button
        mat-menu-item
        (click)="runAction(button.action)"
        [disabled]="button.isDisabled$ | ngrxPush"
      >
        <mat-icon>{{ button.action.icon }}</mat-icon>
        {{ button.action.name }}
      </button>
    </div>
    <pt-custom-tooltip
      [target]="tooltipOrigin"
      *ngIf="button.isDisabled$ | ngrxPush"
    >
      <button mat-menu-item (click)="openPatientEdit()">
        <mat-icon>manage_accounts</mat-icon>
        Update Patient Details
      </button>
    </pt-custom-tooltip>
  </ng-container>

  <mat-divider />

  <button mat-menu-item (click)="editClinicalNote()">
    <mat-icon>healing</mat-icon>
    Clinical Notes
  </button>

  <button mat-menu-item (click)="showSchedulingNotes()">
    <mat-icon>event_note</mat-icon>
    Scheduling Notes
  </button>

  <button mat-menu-item (click)="openWaitlistConfiguration()">
    <mat-icon>assignment</mat-icon>
    <span>Waitlist Settings</span>
  </button>

  @if (isUnscheduled$ | async) {
    <button mat-menu-item (click)="addToFollowUp()">
      <mat-icon>send_time_extension</mat-icon>
      @if (hasFollowUp$ | async) {
        <span>Edit Follow-Up</span>
      } @else {
        <span>Set Follow-Up</span>
      }
    </button>
  }

  @if (hasFollowUp$ | async) {
    <button mat-menu-item (click)="cancelFollowUp()">
      <mat-icon>cancel_schedule_send</mat-icon>
      Cancel Follow-Up
    </button>
  }

  <button mat-menu-item (click)="openChecklistForm()">
    <mat-icon>playlist_add</mat-icon>
    Add to Checklist
  </button>

  <button mat-menu-item (click)="manageAutomations()">
    <mat-icon>smart_toy</mat-icon>
    Automations
  </button>

  <button mat-menu-item (click)="managePatientForms()">
    <mat-icon>list_alt</mat-icon>
    Patient Forms
  </button>

  <mat-divider />

  @if (canConfirm$ | ngrxPush) {
    <button mat-menu-item (click)="confirmAppointment()">
      <mat-icon>done</mat-icon>
      Confirm
    </button>
  }

  @if (canRevertConfirmation$ | ngrxPush) {
    <button mat-menu-item (click)="revertConfirmedAppointment()">
      <mat-icon>undo</mat-icon>
      Revert Confirmation
    </button>
  }

  @if (canRevertCheckIn$ | ngrxPush) {
    <button mat-menu-item (click)="revertCheckedInAppointment()">
      <mat-icon>undo</mat-icon>
      Revert Check In
    </button>
  }
  @if (canRevertInProgress$ | ngrxPush) {
    <button mat-menu-item (click)="revertInProgressAppointment()">
      <mat-icon>undo</mat-icon>
      Revert Start Appointment
    </button>
  }

  <button
    mat-menu-item
    [disabled]="(canBeRescheduled$ | ngrxPush) === false"
    (click)="rescheduleAppointment()"
  >
    <mat-icon>keyboard_tab</mat-icon>
    Reschedule
  </button>

  <button
    mat-menu-item
    [disabled]="(canChangeDuration$ | ngrxPush) === false"
    (click)="updateDuration()"
  >
    <mat-icon>timelapse</mat-icon>
    Update Duration
  </button>

  <button
    mat-menu-item
    [disabled]="(canBeCancelled$ | ngrxPush) === false"
    (click)="cancelAppointment()"
  >
    <mat-icon>cancel</mat-icon>
    Cancel
  </button>
</mat-menu>
