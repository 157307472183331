import {
  ITimePeriod,
  Timezone,
  addToRange,
  getDayRange,
  getEnumValues,
  getMonthRange,
  getWeekRange,
  subtractFromRange,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';

export enum CalendarUnit {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export enum CalendarView {
  Dashboard = 'dashboard',
  Timeline = 'timeline',
  Calendar = 'calendar',
}

export const CALENDAR_UNITS: CalendarUnit[] = getEnumValues(CalendarUnit);

export function getRangeForDisplay(
  view: CalendarView,
  unit: CalendarUnit,
  range: ITimePeriod,
  timezone: Timezone
): ITimePeriod {
  if (view !== CalendarView.Calendar) {
    return {
      from: moment(range.from).tz(timezone).startOf('day'),
      to: moment(range.to).tz(timezone).endOf('day'),
    };
  }
  const displayRoundingUnit = unit === CalendarUnit.Month ? 'week' : 'day';
  return {
    from: moment(range.from)
      .tz(timezone)
      .startOf(unit)
      .startOf(displayRoundingUnit),
    to: moment(range.to).tz(timezone).endOf(unit).endOf(displayRoundingUnit),
  };
}

export function getUnitRange(
  unit: CalendarUnit,
  starting?: Moment
): ITimePeriod {
  switch (unit) {
    case CalendarUnit.Day:
      return getDayRange(starting);
    case CalendarUnit.Week:
      return getWeekRange(starting);
    default:
      return getMonthRange(starting);
  }
}

export function nextRange(unit: CalendarUnit, range: ITimePeriod): ITimePeriod {
  const duration: moment.Duration = moment.duration(1, unit);
  return addToRange(range, duration);
}

export function previousRange(
  unit: CalendarUnit,
  range: ITimePeriod
): ITimePeriod {
  const duration: moment.Duration = moment.duration(1, unit);
  return subtractFromRange(range, duration);
}
