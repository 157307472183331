import { isObject, isTimestamp } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';

export interface ICloudTask {
  name: string;
  createTime: Timestamp;
  scheduleTime: Timestamp;
  dispatchCount: number;
  responseCount: number;
}

export function isCloudTask(data: unknown): data is ICloudTask {
  return (
    isObject(data) &&
    isString(data.name) &&
    isTimestamp(data.createTime) &&
    isTimestamp(data.scheduleTime) &&
    isNumber(data.dispatchCount) &&
    isNumber(data.responseCount)
  );
}
