import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  filterUndefined,
  getItem,
  type WithRef,
} from '@principle-theorem/shared';
import { type IBrand } from '@principle-theorem/principle-core/interfaces';
import { type Observable } from 'rxjs';
import { AbstractResolver } from '@principle-theorem/ng-shared';
import { OrganisationService } from './organisation.service';

@Injectable()
export class BrandResolverService extends AbstractResolver<WithRef<IBrand>> {
  constructor(router: Router, private _organisation: OrganisationService) {
    super(router);
  }

  getItemById(uid: string): Observable<WithRef<IBrand>> {
    return this._organisation.brandCol$.pipe(filterUndefined(), getItem(uid));
  }
}
