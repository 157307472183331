import { toMentionContent, toTextContent } from '@principle-theorem/editor';
import {
  Practice,
  RecurringTaskConfiguration,
  Task,
  TimezoneResolver,
  stafferToNamedDoc,
  toMention,
} from '@principle-theorem/principle-core';
import {
  type IInteraction,
  type IPractice,
  type IRecurringTaskConfiguration,
  type IStaffer,
  type ITask,
  type ITeam,
  InteractionType,
  MentionResourceType,
  type TaskPriority,
  isStaffer,
  isTeam,
} from '@principle-theorem/principle-core/interfaces';
import {
  type AtLeast,
  type INamedDocument,
  ISO_DATE_FORMAT,
  ISO_DATE_TIME_FORMAT,
  ISO_TIME_FORMAT,
  TIME_FORMAT_24HR,
  type WithRef,
  addDoc,
  getDoc$,
  saveDoc,
  snapshot,
  toISODate,
  toMoment,
  toNamedDocument,
  toTimestamp,
} from '@principle-theorem/shared';
import {
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type IRecurringTaskFormData } from './components/recurring-task-configuration-form/recurring-task-configuration-form';
import { type ITaskFormData } from './components/task-form/task-form.formgroup';
import { isTaskFormData } from './task-form-conversion';
import { TaskUpdater } from './task-updater';

export class TaskManager {
  constructor(private _staffer: WithRef<IStaffer>) {}

  async add(
    practice: WithRef<IPractice>,
    data: ITaskFormData
  ): Promise<DocumentReference | undefined> {
    if (isTaskFormData(data) && !data.recurrencePattern) {
      return this._addTask(practice, data);
    }
    return this.addConfiguration(practice, data);
  }

  async open(task: WithRef<ITask>): Promise<void> {
    if (!Task.complete(task)) {
      return;
    }

    Task.open(task, this._staffer);
    await saveDoc(task);
  }

  async close(task: WithRef<ITask>): Promise<void> {
    if (Task.complete(task)) {
      return;
    }

    Task.close(task, this._staffer);
    await saveDoc(task);

    const configuration = await snapshot(Task.recurrenceConfiguration$(task));
    if (!configuration) {
      return;
    }

    await this.generateNewTask(configuration);
  }

  async delete(task: WithRef<ITask>): Promise<void> {
    if (task.deleted) {
      return;
    }

    const owner = stafferToNamedDoc(this._staffer);
    task.deleted = true;

    Task.addInteraction(task, {
      title: [
        toMentionContent(toMention(owner, MentionResourceType.Staffer)),
        toTextContent(` deleted the task`),
      ],
      type: InteractionType.TaskDelete,
      owner,
    });

    await saveDoc(task);
  }

  async undelete(task: WithRef<ITask>): Promise<void> {
    if (!task.deleted) {
      return;
    }

    const owner = stafferToNamedDoc(this._staffer);
    task.deleted = false;

    Task.addInteraction(task, {
      title: [
        toMentionContent(toMention(owner, MentionResourceType.Staffer)),
        toTextContent(` undeleted the task`),
      ],
      type: InteractionType.TaskDelete,
      owner,
    });

    await saveDoc(task);
  }

  async updateCompleted(
    task: WithRef<ITask> | WithRef<ITask>[],
    complete: boolean
  ): Promise<void> {
    if (Array.isArray(task)) {
      const promises: Promise<void>[] = task.map(
        async (innerTask): Promise<void> => {
          return this.updateCompleted(innerTask, complete);
        }
      );
      await Promise.all(promises);
      return;
    }

    if (complete) {
      return this.close(task);
    }
    return this.open(task);
  }

  async updateDueDate(
    task: WithRef<ITask> | WithRef<ITask>[],
    date?: moment.Moment
  ): Promise<void> {
    if (Array.isArray(task)) {
      const promises: Promise<void>[] = task.map(
        async (innerTask): Promise<void> => {
          return this.updateDueDate(innerTask, date);
        }
      );
      await Promise.all(promises);
      return;
    }

    const taskUpdater: TaskUpdater = new TaskUpdater(task);
    taskUpdater.updateDueDate(date);
    if (taskUpdater.hasUpdates()) {
      await taskUpdater.save(this._staffer);
    }
  }

  async updatePriority(
    task: WithRef<ITask> | WithRef<ITask>[],
    priority: TaskPriority
  ): Promise<void> {
    if (Array.isArray(task)) {
      const promises: Promise<void>[] = task.map(
        async (innerTask): Promise<void> => {
          return this.updatePriority(innerTask, priority);
        }
      );
      await Promise.all(promises);
      return;
    }

    const taskUpdater: TaskUpdater = new TaskUpdater(task);
    taskUpdater.updatePriority(priority);
    if (taskUpdater.hasUpdates()) {
      await taskUpdater.save(this._staffer);
    }
  }

  async updateAssignedUser(
    task: WithRef<ITask> | WithRef<ITask>[],
    assignee?: WithRef<ITeam> | WithRef<IStaffer>
  ): Promise<void> {
    if (Array.isArray(task)) {
      const promises: Promise<void>[] = task.map(
        async (innerTask): Promise<void> => {
          return this.updateAssignedUser(innerTask, assignee);
        }
      );
      await Promise.all(promises);
      return;
    }

    const taskUpdater: TaskUpdater = new TaskUpdater(task);
    taskUpdater.updateAssignee(assignee);
    if (taskUpdater.hasUpdates()) {
      await taskUpdater.save(this._staffer);
    }
  }

  async update(
    task: WithRef<ITask>,
    data: ITaskFormData,
    recurringTaskConfiguration?: WithRef<IRecurringTaskConfiguration>
  ): Promise<void> {
    const taskUpdater: TaskUpdater = new TaskUpdater(task);
    taskUpdater
      .updateTitle(data.title)
      .updatePriority(data.priority)
      .updateDueDate(data.dueDate ? toMoment(data.dueDate) : undefined)
      .updateDueTime(data.dueTime)
      .updateAssignee(data.assignee)
      .updateRecurrence(this, data, recurringTaskConfiguration);

    if (taskUpdater.hasUpdates()) {
      await taskUpdater.save(this._staffer);
    }
  }

  async deleteRecurringTask(
    task: WithRef<ITask>,
    deleteConfiguration: boolean
  ): Promise<void> {
    await this.delete(task);

    const recurrenceConfiguration = await snapshot(
      Task.recurrenceConfiguration$(task)
    );
    if (!recurrenceConfiguration) {
      return;
    }

    if (deleteConfiguration) {
      await this.deleteRecurringTaskConfiguration(recurrenceConfiguration);
      return;
    }

    await this.generateNewTask(recurrenceConfiguration);
  }

  async deleteRecurringTaskConfiguration(
    configuration: WithRef<IRecurringTaskConfiguration>
  ): Promise<void> {
    if (configuration.deleted) {
      return;
    }
    configuration.deleted = true;
    await saveDoc(configuration);
  }

  async undeleteRecurringTaskConfiguration(
    configuration: WithRef<IRecurringTaskConfiguration>
  ): Promise<void> {
    if (!configuration.deleted) {
      return;
    }
    configuration.deleted = false;
    await saveDoc(configuration);
    await this.generateNewTask(configuration);
  }

  getAssignee(
    assignee?: WithRef<IStaffer> | WithRef<ITeam>
  ): Pick<IRecurringTaskConfiguration, 'assignedTeam' | 'assignedUser'> {
    if (isStaffer(assignee)) {
      return {
        assignedUser: stafferToNamedDoc(assignee),
        assignedTeam: undefined,
      };
    }
    if (isTeam(assignee)) {
      return {
        assignedUser: undefined,
        assignedTeam: toNamedDocument(assignee),
      };
    }
    return {
      assignedUser: undefined,
      assignedTeam: undefined,
    };
  }

  convertTaskFormToTask(
    data: ITaskFormData
  ): AtLeast<ITask, 'title' | 'type' | 'priority' | 'owner' | 'dueDate'> {
    return {
      title: data.title,
      type: data.type,
      priority: data.priority,
      owner: stafferToNamedDoc(this._staffer),
      dueDate: this._getDueDate(data),
      ...this.getAssignee(data.assignee),
    };
  }

  convertConfigurationFormToConfiguration(
    data: ITaskFormData | IRecurringTaskFormData
  ): Partial<IRecurringTaskConfiguration> {
    const taskUpdates: Partial<IRecurringTaskConfiguration> = {
      title: data.title,
      description: data.description,
      priority: data.priority,
      recurrenceTiming: data.recurrenceTiming ?? undefined,
      ...this.getAssignee(data.assignee),
    };

    if (data.recurringFrequency && data.recurrencePattern) {
      taskUpdates.recurrencePattern = {
        ...data.recurrencePattern,
        startDate: data.recurrencePattern.startDate
          ? data.recurrencePattern.startDate
          : toISODate(moment()),
        frequencyType: data.recurringFrequency,
      };
    }

    return taskUpdates;
  }

  async convertConfigurationToConfigurationForm(
    configuration: IRecurringTaskConfiguration
  ): Promise<Partial<IRecurringTaskFormData>> {
    return {
      title: configuration.title,
      description: configuration.description,
      priority: configuration.priority,
      recurrencePattern: configuration.recurrencePattern,
      recurringFrequency: configuration.recurrencePattern.frequencyType,
      recurrenceTiming: configuration.recurrenceTiming,
      assignee: await Task.getAssignee(configuration),
    };
  }

  async generateNewTask(
    configuration: WithRef<IRecurringTaskConfiguration>
  ): Promise<DocumentReference | undefined> {
    const practice: WithRef<IPractice> = await snapshot(
      RecurringTaskConfiguration.practice$(configuration)
    );
    const timezone = await TimezoneResolver.fromPracticeRef(practice.ref);
    const taskRef = await RecurringTaskConfiguration.generateNewTask(
      configuration,
      Task.col(practice),
      timezone
    );
    if (!taskRef) {
      return;
    }
    await saveDoc(configuration);
    return taskRef;
  }

  async addConfiguration(
    practice: WithRef<IPractice>,
    data: ITaskFormData | IRecurringTaskFormData,
    initialTask?: WithRef<ITask>
  ): Promise<DocumentReference<IRecurringTaskConfiguration>> {
    const newConfiguration: IRecurringTaskConfiguration =
      RecurringTaskConfiguration.init({
        owner: stafferToNamedDoc(this._staffer),
        ...this.convertConfigurationFormToConfiguration(data),
      });

    if (initialTask) {
      newConfiguration.createdTasks.push({
        dateShown: initialTask.visibleFrom,
        ref: initialTask.ref,
      });
    }

    const configurationRef = await addDoc(
      Practice.recurringTaskConfigurationCol(practice),
      newConfiguration
    );

    const configuration = await snapshot(
      getDoc$(
        Practice.recurringTaskConfigurationCol(practice),
        configurationRef.id
      )
    );

    await this.generateNewTask(configuration);
    return configurationRef;
  }

  private _getDueDate(data: ITaskFormData): Timestamp | undefined {
    const dueTime = data.dueTime
      ? moment(data.dueTime, TIME_FORMAT_24HR)
      : undefined;
    const dueDate = data.dueDate ? toMoment(data.dueDate) : undefined;

    if (dueDate && dueTime) {
      return toTimestamp(
        moment(
          `${dueDate.format(ISO_DATE_FORMAT)} ${dueTime.format(
            ISO_TIME_FORMAT
          )}`,
          ISO_DATE_TIME_FORMAT
        )
      );
    }

    if (dueDate) {
      return toTimestamp(dueDate.endOf('day'));
    }

    if (dueTime) {
      return toTimestamp(dueTime);
    }
  }

  private async _addTask(
    practice: WithRef<IPractice>,
    data: ITaskFormData
  ): Promise<DocumentReference> {
    const task: ITask = Task.init(this.convertTaskFormToTask(data));

    const owner = stafferToNamedDoc(this._staffer);
    Task.addInteraction(task, this._getCreateInteraction(owner));
    if (data.description) {
      Task.addInteraction(task, this._getNoteInteraction(owner, data));
    }

    return addDoc(Task.col(practice), task);
  }

  private _getCreateInteraction(
    owner: INamedDocument<IStaffer>
  ): Partial<IInteraction> {
    return {
      title: [
        toMentionContent(toMention(owner, MentionResourceType.Staffer)),
        toTextContent(` created task`),
      ],
      type: InteractionType.TaskCreate,
      owner,
    };
  }

  private _getNoteInteraction(
    owner: INamedDocument<IStaffer>,
    data: ITaskFormData
  ): Partial<IInteraction> {
    return {
      owner,
      type: InteractionType.Note,
      title: [
        toMentionContent(toMention(owner, MentionResourceType.Staffer)),
        toTextContent(` added a note`),
      ],
      content: data.description,
    };
  }
}
