import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CurrentPracticeScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { type ISterilisationRecord } from '@principle-theorem/principle-core/interfaces';
import { Practice, stafferToNamedDoc } from '@principle-theorem/principle-core';
import { type CollectionReference } from '@principle-theorem/shared';
import {
  addDoc,
  asyncForEach,
  filterUndefined,
  snapshot,
  toNamedDocument,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  type ISterilisationDialogData,
  type ISterilisationRecordFormData,
  SterilisationRecordDialogComponent,
} from '../../components/sterilisation-record-dialog/sterilisation-record-dialog.component';

@Component({
  selector: 'pr-sterilisation-page',
  templateUrl: './sterilisation-page.component.html',
  styleUrls: ['./sterilisation-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SterilisationPageComponent {
  sterilisationRecordCol$: Observable<
    CollectionReference<ISterilisationRecord>
  >;

  constructor(
    private _currentPracticeScope: CurrentPracticeScope,
    private _dialog: MatDialog,
    private _organisation: OrganisationService
  ) {
    this.sterilisationRecordCol$ = this._currentPracticeScope.doc$.pipe(
      filterUndefined(),
      map((practice) => Practice.sterilisationRecordCol(practice))
    );
  }

  async addSteriRecord(): Promise<void> {
    const formData = await this._dialog
      .open<
        SterilisationRecordDialogComponent,
        ISterilisationDialogData,
        ISterilisationRecordFormData
      >(
        SterilisationRecordDialogComponent,
        DialogPresets.medium({ autoFocus: true })
      )
      .afterClosed()
      .toPromise();

    if (!formData) {
      return;
    }

    const scannedBy = await snapshot(
      this._organisation.staffer$.pipe(
        filterUndefined(),
        map(stafferToNamedDoc)
      )
    );
    const practice = await snapshot(
      this._currentPracticeScope.doc$.pipe(filterUndefined())
    );

    await asyncForEach(formData.data, (data) =>
      addDoc(Practice.sterilisationRecordCol(practice), {
        data,
        patient: toNamedDocument(formData.patient),
        practice: toNamedDocument(practice),
        scannedBy,
      })
    );
  }
}
