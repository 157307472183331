import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPatient } from '@principle-theorem/principle-core/interfaces';
import { snapshotDefined, type WithRef } from '@principle-theorem/shared';
import { PatientCustomFormsService } from '../../patient-custom-forms.service';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';

export interface IPatientFormsDialogRequest {
  patient: WithRef<IPatient>;
}

@Component({
    selector: 'pr-patient-forms-dialog',
    templateUrl: './patient-forms-dialog.component.html',
    styleUrls: ['./patient-forms-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientFormsDialogComponent {
  private _organisation = inject(OrganisationService);
  data = inject<IPatientFormsDialogRequest>(MAT_DIALOG_DATA);
  customForms = inject(PatientCustomFormsService);

  async issueForm(patient: WithRef<IPatient>): Promise<void> {
    const staffer = await snapshotDefined(this._organisation.staffer$);
    await this.customForms.issueForm(patient, staffer, false);
  }
}
