import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentPracticeScope } from '@principle-theorem/ng-principle-shared';
import { type IBreadcrumb } from '@principle-theorem/ng-shared';
import {
  type IPractice,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import { Practice } from '@principle-theorem/principle-core';
import { type CollectionReference, where } from '@principle-theorem/shared';
import {
  addDoc,
  filterUndefined,
  findProp,
  query$,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-practice-tag-management',
  templateUrl: './practice-tag-management.component.html',
  styleUrls: ['./practice-tag-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PracticeTagManagementComponent {
  private _tagCollection$: Observable<CollectionReference<ITag>>;
  tags$: Observable<WithRef<ITag>[]>;
  practice$: Observable<WithRef<IPractice>>;
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(
    private _route: ActivatedRoute,
    private _currentPractice: CurrentPracticeScope
  ) {
    this.practice$ = this._route.data.pipe(
      findProp<WithRef<IPractice>>('practice'),
      filterUndefined()
    );

    this.breadcrumbs$ = this._currentPractice.doc$.pipe(
      filterUndefined(),
      map((practice) => [
        { label: 'Settings', path: '../../../' },
        { label: practice.name },
        { label: 'Media Tags' },
      ])
    );

    this._tagCollection$ = this.practice$.pipe(
      map((practice) => Practice.mediaTagCol(practice))
    );
    this.tags$ = this._tagCollection$.pipe(
      switchMap((collection) =>
        query$(collection, where('deleted', '!=', true))
      )
    );
  }

  async addTag(tag: ITag): Promise<void> {
    const collection: CollectionReference<ITag> = await snapshot(
      this._tagCollection$
    );
    await addDoc(collection, tag);
  }
}
