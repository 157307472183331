<pr-profile-card [hasActions]="false" *ngIf="contact$ | async as contact">
  <pr-profile-card-title>
    {{ contact.name }}
  </pr-profile-card-title>

  <mat-list dense>
    <mat-list-item *ngIf="contact.phone">
      <mat-icon matListItemIcon>phone</mat-icon>
      <a matListItemTitle [routerLink]="[]" (click)="dialogService.openPhone()">
        {{ contact.phone }}
      </a>
    </mat-list-item>
    <mat-list-item *ngIf="contact.email">
      <mat-icon matListItemIcon>mail_outline</mat-icon>
      <a matListItemTitle [routerLink]="[]" (click)="dialogService.openEmail()">
        {{ contact.email }}
      </a>
    </mat-list-item>
    <mat-list-item *ngIf="contact.address">
      <mat-icon matListItemIcon>place</mat-icon>
      <span matListItemTitle>{{ contact.address }}</span>
    </mat-list-item>
  </mat-list>

  <mat-divider />

  <pr-map-preview [coordinates]="coordinates$ | async" />
</pr-profile-card>
