import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';

@Component({
  selector: 'pr-copy-patient-id-button',
  templateUrl: './copy-patient-id-button.component.html',
  styleUrls: ['./copy-patient-id-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyPatientIdButtonComponent {
  @Input() patient: WithRef<IPatient>;

  copyPatientID(): string {
    return this.patient.referenceId
      ? this.patient.referenceId
      : this.patient.ref.id;
  }
}
