<div fxLayout="column" fxLayoutAlign="start stretch">
  <div fxLayout="row" fxLayoutAlign="space-between stretch">
    <h3 *ngIf="label">{{ label }}</h3>
    <ng-container *ngIf="!hideReset">
      <a
        href="javascript:void(0);"
        *ngIf="canResetFilter$ | async"
        (click)="resetFilter()"
        >Reset</a
      >
    </ng-container>
  </div>
  <div
    class="dc-chart"
    [class]="chartClasses$ | async"
    #dcChart
    [ngStyle]="{ 'width.px': (chartWidth$ | async) }"
  ></div>
</div>
<div #dcLegend class="dc-html-legend-container"></div>
