import { Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type IChartCard } from '../../../models/report/charts/chart-card';
import { type ITimeRange, RangeSelectorCard } from '../range-selector-card';

@Component({
  selector: 'pr-overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.sass'],
})
export class OverviewCardComponent extends RangeSelectorCard {
  trackByChartCard = TrackByFunctions.title<IChartCard>();
  trackTimeRange = TrackByFunctions.title<ITimeRange>();
  @Input() chartCards: IChartCard[];
  @Input() title: string;
}
