import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAutomationConfiguration } from '@principle-theorem/principle-core/interfaces';
import { ITimePeriod, toTimestamp, WithRef } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AutomationsFacade } from '../../store/facades/automations.facade';

export interface IAutomationsByConfigRequest {
  config: WithRef<IAutomationConfiguration>;
}

@Component({
  selector: 'pr-automations-by-config-dialog',
  templateUrl: './automations-by-config-dialog.component.html',
  styleUrls: ['./automations-by-config-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomationsByConfigDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  dateRange$ = new BehaviorSubject<ITimePeriod>({
    from: moment().startOf('day'),
    to: moment().add(1, 'week').startOf('day'),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAutomationsByConfigRequest,
    private _automationsFacade: AutomationsFacade
  ) {
    this.dateRange$.pipe(takeUntil(this._onDestroy$)).subscribe((dateRange) => {
      this._automationsFacade.loadConfigAutomations(
        data.config,
        toTimestamp(dateRange.from),
        toTimestamp(dateRange.to)
      );
    });
  }

  ngOnDestroy(): void {
    this._automationsFacade.resetState();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
