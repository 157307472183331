import {
  DayNodesMap,
  DayRangeMap,
  IScheduleSummaryEventable,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  expandRangeToTimeUnit,
  ISO_DATE_FORMAT,
  type ITimePeriod,
  mergeDayAndTime,
  TimeBucket,
  type Timezone,
  toTimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, flattenDeep, keyBy, values } from 'lodash';

export function getNodeTimesFromDay(
  nodesMap: DayNodesMap<IScheduleSummaryEventable, WithRef<IStaffer>>
): ITimePeriod[] {
  return flattenDeep(
    compact(values(nodesMap)).map((nodePair) =>
      compact(values(nodePair)).map((groupNodeMap) =>
        groupNodeMap.map((node) => ({
          from: node.node.from,
          to: node.node.to,
        }))
      )
    )
  );
}

export function getDayViewRanges({
  nodeRanges,
  openingHours,
  staffRosteredOnTimes,
}: {
  nodeRanges: ITimePeriod[];
  openingHours: ITimePeriod[];
  staffRosteredOnTimes: ITimePeriod[];
}): DayRangeMap {
  const allTimePeriods = [
    ...openingHours,
    ...nodeRanges,
    ...staffRosteredOnTimes,
  ];

  return keyBy(
    new TimeBucket(allTimePeriods).mergeByDay().get(),
    (timePeriod) => timePeriod.from.format(ISO_DATE_FORMAT)
  );
}

export function determineViewRange({
  nodeRanges,
  openingHours,
  staffRosteredOnTimes,
  timezone,
}: {
  nodeRanges: ITimePeriod[];
  openingHours: ITimePeriod[];
  staffRosteredOnTimes: ITimePeriod[];
  timezone: Timezone;
}): ITimePeriod | undefined {
  const allTimePeriods = [
    ...openingHours,
    ...nodeRanges,
    ...staffRosteredOnTimes,
  ];

  const dayRanges = new TimeBucket(allTimePeriods).mergeByDay().get();
  const mergeDay = new TimeBucket(dayRanges).merge()?.from;

  if (!mergeDay) {
    return;
  }

  const allRanges = allTimePeriods.map((day) => ({
    from: mergeDayAndTime(mergeDay, day.from),
    to: mergeDayAndTime(mergeDay, day.to),
  }));

  const timeRange = new TimeBucket(allRanges).mergeOverlapping().merge();

  if (!timeRange) {
    return;
  }

  const expandedRange = expandRangeToTimeUnit(
    toTimePeriod(timeRange.from, timeRange.to, timezone),
    'hour'
  );

  if (!expandedRange.to.isSame(expandedRange.from, 'day')) {
    expandedRange.to.subtract(1, 'hour').endOf('day');
  }

  return expandedRange;
}
