import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { IScheduleSummaryEventable } from '@principle-theorem/principle-core/interfaces';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

type MatBadgeSize = 'small' | 'medium' | 'large';

@Component({
  selector: 'pr-timeline-pending-gap-badge',
  templateUrl: './timeline-pending-gap-badge.component.html',
  styleUrls: ['./timeline-pending-gap-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgMaterialModule],
})
export class TimelinePendingGapBadgeComponent {
  pendingGap$ = new ReplaySubject<IScheduleSummaryEventable>(1);
  candidateCount$: Observable<number>;
  showBadge$: Observable<boolean>;

  @Input() size: MatBadgeSize = 'medium';

  @Input()
  set pendingGap(pendingGap: IScheduleSummaryEventable) {
    if (pendingGap) {
      this.pendingGap$.next(pendingGap);
    }
  }

  constructor() {
    this.candidateCount$ = this.pendingGap$.pipe(
      map((gap) => gap.metadata.candidates ?? []),
      map((candidates) => candidates.length)
    );

    this.showBadge$ = this.candidateCount$.pipe(map((count) => !!count));
  }
}
