import { Subject } from 'rxjs';

export type FirestoreUsageType = 'snapshotListener' | 'get';
export type FirestoreResourceType = 'collection' | 'document';

export interface IDatabaseUsageTrackEvent {
  path: string;
  numberOfReads: number;
  usageType: FirestoreUsageType;
  resourceType: FirestoreResourceType;
}

export class DatabaseUsageTracker {
  static usageAdded$ = new Subject<IDatabaseUsageTrackEvent>();

  static track(
    path: string,
    usageType: FirestoreUsageType,
    resourceType: FirestoreResourceType,
    numberOfReads = 1
  ): void {
    this.usageAdded$.next({
      path,
      usageType,
      resourceType,
      numberOfReads,
    });
  }
}
