import { rand, randBoolean, randNumber } from '@ngneat/falso';
import {
  CustomChartType,
  Gender,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import { FactsCommon } from '@principle-theorem/reporting/interfaces';
import { getEnumValues } from '@principle-theorem/shared';
import { IChartConfig } from '../chart-config';
import { generateBuilderData } from '../models/base-measures';
import { FactTables } from '../models/fact-tables';
import { BooleanMeasureFilter } from '../models/measure-filters';
import { MockTimestampedFacts } from './common-mock-values';
import { toMeasureBuilderData } from '../models/measure-properties';

/**
 * New Patients
 */
export const newPatients: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `New Patients`,
  },
  builderData: generateBuilderData({
    plottedOverTime: true,
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.newPatient.reduceByCount()
      ),
    ],
  }),
};

export const newPatientsResults = [
  MockTimestampedFacts('newPatientsSeed', () => ({
    isFirstAppointment: true,
  })),
];

/**
 * Returning Patients Seen
 */
export const returningPatientsSeen: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Returning Patients Seen`,
  },
  builderData: generateBuilderData({
    plottedOverTime: true,
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.existingPatient.reduceByCount()
      ),
    ],
  }),
};

export const returningPatientsSeenResults = [
  MockTimestampedFacts('returningPatientsSeenSeed', () => ({
    isFirstAppointment: false,
  })),
];

/**
 * Patients Seen
 */
export const patientsSeen: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Patients Seen`,
  },
  builderData: generateBuilderData({
    plottedOverTime: true,
    measures: [
      toMeasureBuilderData(FactTables.appointmentEvent.count.reduceByCount()),
    ], // TOD]O: What if you see the same patient twice, this will double count them atm
  }),
};

export const patientsSeenResults = [
  MockTimestampedFacts('patientsSeenSeed', () => ({ count: 1 })),
];

/**
 * Average Appointment Duration
 */
export const averageAppointmentDuration: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Average Appointment Duration`,
  },
  builderData: generateBuilderData({
    plottedOverTime: true,
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.duration.reduceByAverage()
      ),
    ],
  }),
};

export const averageAppointmentDurationResults = [
  MockTimestampedFacts('averageAppointmentDurationSeed', () => ({
    duration: randNumber({ min: 5, max: 120 }),
  })),
];

/**
 * Average Wait Time
 */
export const averageWaitTime: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Average Wait Time`,
  },
  builderData: generateBuilderData({
    plottedOverTime: true,
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.waitTime.reduceByAverage()
      ),
    ],
  }),
};

export const averageWaitTimeResults = [
  MockTimestampedFacts('averageWaitTimeSeed', () => ({
    waitTime: randNumber({ min: 5, max: 120 }),
  })),
];

/**
 * Patients Leaving Without Scheduled Appointment
 */
export const patientsLeavingWithoutScheduledAppointment: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Patients Leaving Without Scheduled Appointment`,
  },
  builderData: generateBuilderData({
    plottedOverTime: true,
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.nextAppointmentBooked
          .filterBy(
            new BooleanMeasureFilter(false),
            FactTables.appointmentEvent.nextAppointmentBooked.measure
          )
          .reduceByCount()
      ),
    ],
  }),
};

export const patientsLeavingWithoutScheduledAppointmentResults = [
  MockTimestampedFacts(
    'patientsLeavingWithoutScheduledAppointmentSeed',
    () => ({
      nextAppointmentBooked: false,
    })
  ),
];

/**
 * Patients Leaving With Scheduled Appointment
 */
export const patientsLeavingWithScheduledAppointment: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Patients Leaving With Scheduled Appointment`,
  },
  builderData: generateBuilderData({
    plottedOverTime: true,
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.nextAppointmentBooked.reduceByCount()
      ),
    ],
  }),
};

export const patientsLeavingWithScheduledAppointmentResults = [
  MockTimestampedFacts('patientsLeavingWithScheduledAppointmentSeed', () => ({
    nextAppointmentBooked: true,
  })),
];

/**
 * Patient Bounce Rate
 * TODO: Unsure how to get reduce by ratio working here
 */
export const patientBounceRate: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Patient Bounce Rate`,
  },
  builderData: generateBuilderData({
    plottedOverTime: true,
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.nextAppointmentBooked
          // .filterDimension((booked) => !booked) // TODO: We dont have client side filtering yet
          .reduceByRatio()
          .setLabel('Patient Bounce Rate')
          .setFormatter(MeasureFormatter.Percentage)
      ),
    ],
  }),
};

interface IPatientBounceRate extends FactsCommon.ITimestampedFact {
  nextAppointmentBooked: boolean;
}
export const patientBounceRateResults = [
  MockTimestampedFacts<IPatientBounceRate>('patientBounceRateSeed', () => ({
    nextAppointmentBooked: randBoolean(),
  })),
];

/**
 * Average Patient Spend
 */
export const averagePatientSpend: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Average Patient Spend`,
  },
  builderData: generateBuilderData({
    plottedOverTime: true,
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency) // TODO: Feels like it should be getting this off an invocie dimensions or something.
      ),
    ],
  }),
};

export const averagePatientSpendResults = [
  MockTimestampedFacts('averagePatientSpendSeed', () => ({
    treatmentCost: randNumber({ min: 150, max: 1200 }),
  })),
];

/**
 * Patient Rebooking Rate
 */
export const patientRebookingRate: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Patient Rebooking Rate`,
  },
  builderData: generateBuilderData({
    plottedOverTime: true,
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.nextAppointmentBooked
          .reduceByRatio()
          .setLabel('Patient Bounce Rate')
          .setFormatter(MeasureFormatter.Percentage)
      ),
    ],
  }),
};

export const patientRebookingRateResults = [
  MockTimestampedFacts('patientRebookingRateSeed', () => ({
    nextAppointmentBooked: randBoolean(),
  })),
];

/**
 * Age
 */
export const age: IChartConfig = {
  type: CustomChartType.Row,
  labels: {
    title: `Age`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.patient.age.reduceByCount()
      ),
    ],
    // Group By?
  }),
};

export const ageResults = [
  MockTimestampedFacts('ageSeed', () => ({
    patient_age: randNumber({ min: 6, max: 85 }),
  })),
];

/**
 * Gender
 */
export const gender: IChartConfig = {
  type: CustomChartType.Pie,
  labels: {
    title: `Gender`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.patient.count
          .groupBy(FactTables.appointmentEvent.patient.gender)
          .reduceByCount()
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.patient.gender,
  }),
};

export const genderResults = [
  MockTimestampedFacts('genderSeed', () => ({
    patient_gender: rand(getEnumValues(Gender)),
  })),
];

/**
 * Distance
 */
export const distance: IChartConfig = {
  type: CustomChartType.Row,
  labels: {
    title: `New Patients`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.patient.distance.reduceByCount()
      ),
    ],
  }),
};

export const distanceResults = [
  MockTimestampedFacts('distanceSeed', () => ({
    patient_distance: randNumber(),
  })),
];

/**
 * New vs Existing
 */
export const newVsExisting: IChartConfig = {
  type: CustomChartType.Pie,
  labels: {
    title: `New vs Existing`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.count
          .groupBy(FactTables.appointmentEvent.isFirstAppointment)
          .reduceByCount()
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.isFirstAppointment,
  }),
};

export const newVsExistingResults = [
  MockTimestampedFacts('newVsExistingSeed', () => ({
    isFirstAppointment: randBoolean(),
  })),
];
