import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, type UrlTree } from '@angular/router';
import { AuthGuard } from './auth-guard';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class IsNotAuthenticatedGuard extends AuthGuard {
  constructor(private _router: Router, auth: AuthService) {
    super(auth);
  }

  async canActivate(next: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    if (!(await this.isLoggedIn())) {
      return true;
    }
    const queryRedirectTo: string | undefined = String(
      next.queryParams.redirectTo
    );
    return this._router.parseUrl(queryRedirectTo || '/');
  }
}
