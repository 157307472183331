import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  inject,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { FeatureInfoComponent } from '@principle-theorem/ng-feature-flags';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import { BreadcrumbsComponent } from '@principle-theorem/ng-shared';
import {
  SterilisationCycleTypesListComponent,
  SterilisationMachinesListComponent,
  SterilisationPacksListComponent,
  SterilisationEquipmentListComponent,
  SterilisationSettingsStore,
} from '@principle-theorem/ng-sterilisation';
import { Brand, toINamedDocuments } from '@principle-theorem/principle-core';
import {
  type ISterilisationPack,
  type ISterilisationMachine,
  ISterilisationCycleType,
  ISterilisationEquipment,
} from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, type WithRef } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-brand-sterilisation',
  standalone: true,
  imports: [
    NgMaterialModule,
    BreadcrumbsComponent,
    FeatureInfoComponent,
    MatButtonModule,
    SterilisationMachinesListComponent,
    SterilisationPacksListComponent,
    SterilisationCycleTypesListComponent,
    SterilisationEquipmentListComponent,
  ],
  templateUrl: './brand-sterilisation.component.html',
  styleUrl: './brand-sterilisation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SterilisationSettingsStore],
})
export class BrandSterilisationComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _currentBrand = inject(CurrentBrandScope);
  private _brand$ = this._currentBrand.doc$.pipe(filterUndefined());
  private _brand = toSignal(this._brand$);
  private _practices = toSignal(
    this._brand$.pipe(
      switchMap((brand) => Brand.practices$(brand).pipe(toINamedDocuments()))
    )
  );
  store = inject(SterilisationSettingsStore);

  breadcrumbs = computed(() => {
    const brand = this._brand();
    return brand
      ? [
          { label: 'Settings', path: '../../../' },
          { label: brand.name },
          { label: 'Prescriptions' },
        ]
      : [];
  });

  constructor() {
    this.store.load(this._brand$);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async addEditMachine(
    machine?: WithRef<ISterilisationMachine>
  ): Promise<void> {
    const practices = this._practices();
    const brand = this._brand();
    if (!practices || !brand) {
      return;
    }

    await this.store.addEditMachine({ practices, machine }, brand);
  }

  async addEditPack(pack?: WithRef<ISterilisationPack>): Promise<void> {
    await this.store.addEditPack(pack, this._brand());
  }

  async deletePack(pack: WithRef<ISterilisationPack>): Promise<void> {
    await this.store.deletePack(pack);
  }

  async addEditEquipment(
    equipment?: WithRef<ISterilisationEquipment>
  ): Promise<void> {
    await this.store.addEditEquipment(equipment, this._brand());
  }

  async deleteEquipment(item: WithRef<ISterilisationEquipment>): Promise<void> {
    await this.store.deleteEquipment(item);
  }

  async addEditCycleType(
    cycleType?: WithRef<ISterilisationCycleType>
  ): Promise<void> {
    await this.store.addEditCycleType(cycleType, this._brand());
  }
}
