import {
  ITranslationMap,
  type ICustomMappingSourceOption,
} from '@principle-theorem/principle-core/interfaces';
import {
  type AtLeast,
  type IXSLXExport,
  type XSLXCell,
} from '@principle-theorem/shared';
import type { Column } from 'exceljs';

export interface IExcludedAppointmentBookXSLX {
  sourceId: string;
  sourceName: string;
  exclude: string;
}

export const EXCLUDED_APPOINTMENT_BOOK_HEADERS: AtLeast<
  Column,
  'key' | 'header'
>[] = [
  {
    key: 'sourceId',
    header: 'Id',
  },
  {
    key: 'sourceName',
    header: 'Appointment Book',
    width: 45,
  },
  {
    key: 'exclude',
    header: 'Exclude',
    width: 30,
  },
];

export class ExcludedAppointmentBookToXSLX
  implements
    IXSLXExport<ICustomMappingSourceOption, IExcludedAppointmentBookXSLX>
{
  headers = EXCLUDED_APPOINTMENT_BOOK_HEADERS;

  constructor(private _existingMappings: ITranslationMap[]) {}

  translate(
    records: ICustomMappingSourceOption[]
  ): Record<keyof IExcludedAppointmentBookXSLX, XSLXCell>[] {
    return records.map((record) => {
      const existingRecord = this._existingMappings.find(
        (existingMapping) => existingMapping.sourceIdentifier === record.value
      );

      return {
        sourceId: {
          value: record.value,
        },
        sourceName: {
          value: record.label,
        },
        exclude: {
          value: existingRecord ? 'Yes' : 'No',
          dataValidation: {
            type: 'list',
            formulae: [
              `"${['Yes', 'No']
                .map((item) => item)
                .sort()
                .join(',')}"`,
            ],
          },
        },
      };
    });
  }
}
