<div class="loose-treatment-container">
  <pr-treatment-scope-list
    [treatments]="treatments$ | ngrxPush"
    [disabled]="disabled"
    [selectedSurfaces]="selectedSurfaces$ | ngrxPush"
    (treatmentsChange)="updateTreatments($event)"
    (treatmentDeleted)="deleteTreatment($event)"
    (updateChartable)="updateChartable.emit($event)"
  />
</div>

<ng-container
  *ngFor="
    let multiTreatment of multiTreatments$ | ngrxPush;
    trackBy: trackByMultiTreatment
  "
>
  <div class="multi-step">
    <pr-charted-item-card-v2
      #card="prChartedItemCardV2"
      [item]="multiTreatment"
      [selectedSurfaces]="selectedSurfaces$ | ngrxPush"
      [disabled]="disabled"
      (itemChange)="updateMultiTreatment($event)"
      (itemDelete)="deleteMultiTreatment($event)"
      (updateChartable)="updateChartable.emit($event)"
    >
      <ng-container ngProjectAs=".multi-step-buttons" *ngIf="!disabled">
        <ng-container *ngrxLet="canAcceptSteps$ as canAccept">
          <div
            class="accept-steps-button"
            [matTooltip]="acceptStepsTooltip$ | ngrxPush"
          >
            <button
              mat-icon-button
              [disabled]="!canAccept"
              (click)="acceptTreatment(multiTreatment)"
            >
              <mat-icon>playlist_add_check</mat-icon>
            </button>
          </div>
        </ng-container>
      </ng-container>
      <ng-container ngProjectAs=".change-fee-schedule">
        <pr-staff-fee-schedule-menu
          [plan]="multiTreatment"
          [feeSchedule]="
            multiTreatment | map : multiTreatmentFeeSchedule$ : this | async
          "
          [patient]="patient$ | async"
          (scheduleSelected)="updateFeeSchedule(multiTreatment, $event)"
        />
      </ng-container>
      <ng-container
        ngProjectAs=".change-treatment-package"
        *ngIf="
          multiTreatment | map : getMultiTreatmentPackages | async as packages
        "
      >
        <pr-treatment-package-menu
          [packages]="packages"
          [currentPackageId]="multiTreatment.treatmentPackageId"
          (packageSelected)="
            setTreatmentPackage($event, packages, multiTreatment)
          "
        />
      </ng-container>

      <ng-container *ngIf="card.collapsible?.expanded">
        <pr-charted-item-notes-list [item]="multiTreatment" />

        <pr-multi-step-treatment
          [stepDragDrop]="stepDragDrop"
          [steps]="multiTreatment.steps"
          [plan]="multiTreatment"
          [disabled]="disabled"
          (stepChange)="updateMultiTreatmentStep($event, multiTreatment)"
          (stepDelete)="removeMultiTreatmentStep($event, multiTreatment)"
          (stepAdd)="addMultiTreatmentStep(multiTreatment)"
          (updateChartable)="updateChartable.emit($event)"
        />
      </ng-container>
    </pr-charted-item-card-v2>
  </div>
</ng-container>
