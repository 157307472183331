import { type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { type IFeeSchedule } from './fee-schedule';

export interface IFeeScheduleCollection {
  default$: Observable<WithRef<IFeeSchedule>>;
  defaultSnapshot: () => Promise<WithRef<IFeeSchedule>>;
  getInheritedSchedule$(
    feeSchedule: IFeeSchedule
  ): Observable<WithRef<IFeeSchedule> | undefined>;
}
