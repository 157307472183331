import { FeatureVisibility, IFeature } from '@principle-theorem/feature-flags';

export enum BrandPermissions {
  BrandManage = 'brands.manage',
  BrandConfigure = 'brands.configure',
  BrandConfigureAutomations = 'brands.configure.automations',
  BrandConfigureScheduling = 'brands.configure.scheduling',
  BrandConfigureClinical = 'brands.configure.clinical',
  BrandConfigurePrescriptions = 'brands.configure.prescriptions',
  BrandConfigureProducts = 'brands.configure.products',
  BrandConfigureGeneral = 'brands.configure.general',
  BrandConfigureTeams = 'brands.configure.teams',
  BrandConfigureStaff = 'brands.configure.staff',
  AutomationManage = 'brands.automations.manage',
  TagsManage = 'brands.tags.manage',
}

export const BRANDS_FEATURE: IFeature = {
  label: 'brands',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: BrandPermissions.BrandManage,
      description: `User can add and remove brands`,
    },
    {
      value: BrandPermissions.BrandConfigure,
      description: `User can modify ALL settings for the brand`,
    },
    {
      value: BrandPermissions.BrandConfigureGeneral,
      description: `User can modify settings for the brand's general configurations`,
    },
    {
      value: BrandPermissions.BrandConfigureClinical,
      description: `User can modify settings for the brand's clinical configurations`,
    },
    {
      value: BrandPermissions.BrandConfigureAutomations,
      description: `User can modify settings for the brand's automations`,
    },
    {
      value: BrandPermissions.BrandConfigureScheduling,
      description: `User can modify settings for the brand's appointment scheduling`,
    },
    {
      value: BrandPermissions.BrandConfigurePrescriptions,
      description: `User can modify settings for the brand's prescriptions`,
    },
    {
      value: BrandPermissions.BrandConfigureProducts,
      description: `User can modify settings for the brand's products`,
    },
    {
      value: BrandPermissions.BrandConfigureTeams,
      description: `User can manage teams for the practice for use in task delegation`,
    },
    {
      value: BrandPermissions.BrandConfigureStaff,
      description: `User can manage practitioner provider details`,
    },
    {
      value: BrandPermissions.AutomationManage,
      description: `User can manage automations for the brand`,
    },
    {
      value: BrandPermissions.TagsManage,
      description: `User can manage tags for the brand`,
    },
  ],
};
