import { type IXSLXImport } from '@principle-theorem/shared';
import type { Row } from 'exceljs';
import {
  IAllergyToMedicalHistoryXSLX,
  ALLERGY_TO_MEDICAL_HISTORY_HEADERS,
} from './allergy-to-medical-history-to-xlsx';

export class XSLXToAllergyToMedicalHistory
  implements IXSLXImport<IAllergyToMedicalHistoryXSLX>
{
  headers = ALLERGY_TO_MEDICAL_HISTORY_HEADERS;

  translate(row: Row): IAllergyToMedicalHistoryXSLX {
    return {
      sourceId: row.getCell('sourceId').value?.toString() ?? '',
      sourceName: row.getCell('sourceName').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IAllergyToMedicalHistoryXSLX['mapTo'],
    };
  }
}
