import { DestinationEntity } from '../destination-entity';

export const PATIENT_CLINICAL_NOTE_CUSTOM_MAPPING_TYPE = 'patientClinicalNote';

export const PATIENT_CLINICAL_NOTE_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_CLINICAL_NOTE_CUSTOM_MAPPING_TYPE,
    label: 'Patient Clinical Notes',
    description: ``,
  },
});
