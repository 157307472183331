import {
  WithRef,
  type IReffable,
  IBlobFilenamePair,
  XSLXImporterExporter,
  asyncForEach,
  hardDeleteDoc,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import {
  CustomMappingType,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { PatientAppointmentBookSourceEntity } from '../../source/entities/appointment-book';
import { TranslationMapHandler } from '../../../translation-map';
import { PracticeMigration } from '../../../practice-migrations';
import { ExcludedAppointmentBookToXSLX } from '../../../mappings/excluded-appointment-book-to-xlsx';
import { XSLXToExcludedAppointmentBooks } from '../../../mappings/xlsx-to-excluded-appointment-books';

export const EXCLUDED_APPOINTMENT_BOOK_CUSTOM_MAPPING_TYPE =
  'excludedAppointmentBookMapping';

export const EXCLUDED_APPOINTMENT_BOOK_MAPPING: ICustomMapping =
  CustomMapping.init({
    metadata: {
      label: 'Exclude Appointment Books',
      description: `Used to filter out appointments that are a part of an appointment book that's not for legitimate appointments.`,
      type: EXCLUDED_APPOINTMENT_BOOK_CUSTOM_MAPPING_TYPE,
    },
    type: CustomMappingType.ExclusionList,
  });

export class D4WExcludedAppointmentBooksMappingHandler extends BaseCustomMappingHandler {
  customMapping = EXCLUDED_APPOINTMENT_BOOK_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const appointmentBooks = new PatientAppointmentBookSourceEntity();
    const records = await appointmentBooks
      .getRecords$(migration, 1000)
      .toPromise();
    return sortBy(
      records
        .map((record) => record.data.data)
        .map((record) => ({
          label: record.description,
          value: record.id.toString(),
        })),
      'value'
    );
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, appointmentBooks, translators } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(
      fileName,
      appointmentBooks,
      translators
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, appointmentBooks, translators } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(
      fileName,
      appointmentBooks,
      translators
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToExcludedAppointmentBooks()
    );
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const records = await this.getRecords(translationMap);
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));
    const sourceOptions = await this.getSourceOptions(migration);
    await asyncForEach(items, async (item) => {
      const exclude = item.exclude === 'Yes';
      const id = item.sourceId;
      const label = item.sourceName;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.value === id
      )?.value;
      if (!value || !exclude) {
        return;
      }

      await this.upsertRecord(
        {
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    appointmentBooks: ICustomMappingSourceOption[];
    translators: ExcludedAppointmentBookToXSLX;
  }> {
    const fileName = this.getFileName();
    const appointmentBooks = await this.getSourceOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const translators = new ExcludedAppointmentBookToXSLX(
      await this.getRecords(translationMap)
    );
    return { fileName, appointmentBooks, translators };
  }
}
