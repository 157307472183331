import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toMentionContent, toTextContent } from '@principle-theorem/editor';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  addInteractions,
  Interaction,
  stafferToNamedDoc,
  toMention,
} from '@principle-theorem/principle-core';
import {
  type IInteractionData,
  type IInteractionV2,
  InteractionType,
  type IStaffer,
  MentionResourceType,
  type WithTemplates,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject, Subject } from 'rxjs';
import { type IPopulatedTemplate } from '../contextual-actions/interaction-actions/template-context-resolvers/mention-to-templates';
import { type INoteFormData } from '../note-form/note-form';

@Component({
    selector: 'pr-note',
    templateUrl: './note.component.html',
    styleUrls: ['./note.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NoteComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByTemplate = TrackByFunctions.field<IPopulatedTemplate>('name');
  noteFormData$ = new ReplaySubject<INoteFormData>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WithTemplates<IInteractionData>,
    private _organisation: OrganisationService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NoteComponent>
  ) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async submit(): Promise<void> {
    const formData = await snapshot(this.noteFormData$);
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const interaction = this._getNoteInteraction(formData, staffer);
    await addInteractions(this.data, interaction);

    this._snackBar.open('Note Added Successfully');
    this.dialogRef.close();
  }

  private _getNoteInteraction(
    formData: INoteFormData,
    sender: WithRef<IStaffer>
  ): IInteractionV2 {
    return Interaction.init({
      type: InteractionType.Note,
      title: [
        toMentionContent(toMention(sender, MentionResourceType.Staffer)),
        toTextContent(` added a Note`),
      ],
      owner: stafferToNamedDoc(sender),
      content: formData.content,
      tags: formData.tags,
    });
  }
}
