import { Observable } from 'rxjs';
import { HicapsConnect, HicapsConnectExtended } from './interfaces';
import {
  HicapsConnectMethod,
  PrincipleHicapsConnectError,
  PrincipleHicapsConnectRequest,
  PrincipleHicapsConnectResponse,
} from './principle-hicaps-connect';

export interface IHicapsConnectApiCallOptions {
  processUid?: string;
}

type HicapsMethodCall<T extends HicapsConnectMethod> = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any[]
) => Observable<
  PrincipleHicapsConnectResponse<T> | PrincipleHicapsConnectError
>;

type HicapsFunctionMap = {
  [T in HicapsConnectMethod]: HicapsMethodCall<T>;
};

export interface IHicapsConnectApiAdaptor {
  call$<T extends HicapsConnectMethod>(
    methodName: T,
    request: PrincipleHicapsConnectRequest<T>['data'],
    extendedData: PrincipleHicapsConnectRequest<T>['extendedData'],
    options?: IHicapsConnectApiCallOptions
  ): Observable<PrincipleHicapsConnectResponse<T>>;

  generateProcessUid(): string;
}

export class HicapsApi implements HicapsFunctionMap {
  constructor(private _adaptor: IHicapsConnectApiAdaptor) {}

  call$<T extends HicapsConnectMethod>(
    methodName: T,
    request: PrincipleHicapsConnectRequest<T>['data'],
    extendedData: PrincipleHicapsConnectRequest<T>['extendedData'],
    options?: IHicapsConnectApiCallOptions
  ): Observable<PrincipleHicapsConnectResponse<T>> {
    return this._adaptor.call$(methodName, request, extendedData, options);
  }

  generateProcessUid(): string {
    return this._adaptor.generateProcessUid();
  }

  /**
   * Returns the contents of the current log file
   *
   * Example line: [15/08/2023 7:11:03 PM +10:00] DEBUG: Request: {"uid":"09eda00e-f3d7-44b3-b835-867100d4e2ca","methodName":"principleHicapsLogsReadAndDelete"}\r\n
   */
  principleHicapsLogsRead(
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.PrincipleHicapsLogs_Read>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.PrincipleHicapsLogs_Read,
      undefined,
      undefined,
      options
    );
  }

  /**
   * Deletes the the current log file
   */
  principleHicapsLogsDelete(
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.PrincipleHicapsLogs_Delete>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.PrincipleHicapsLogs_Delete,
      undefined,
      undefined,
      options
    );
  }

  /**
   * Returns the contents of the current log file before
   * deleting it
   *
   * Example line: [15/08/2023 7:11:03 PM +10:00] DEBUG: Request: {"uid":"09eda00e-f3d7-44b3-b835-867100d4e2ca","methodName":"principleHicapsLogsReadAndDelete"}\r\n
   */
  principleHicapsLogsReadAndDelete(
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.PrincipleHicapsLogs_ReadAndDelete>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.PrincipleHicapsLogs_ReadAndDelete,
      undefined,
      undefined,
      options
    );
  }

  /**
   * Gets the current hicaps connect version
   *
   * e.g. "1.0.3.32.R1"
   */
  getVersion(
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.GetVersion>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.GetVersion,
      undefined,
      undefined,
      options
    );
  }

  /**
   * Returns the default server. Uses a static method on hicaps
   * connect rather than asking for a specific servers config.
   *
   * e.g. "192.168.0.3:11000"
   */
  getDefaultServer(
    server: string,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.GetDefaultServer>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.GetDefaultServer,
      server,
      undefined,
      options
    );
  }

  /**
   * Returns the default server. Unsue how this differs from
   * getDefaultServer
   *
   * e.g. "192.168.0.3:11000"
   */
  getDefaultServerConfig(
    server: string,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.GetDefaultServerConfig>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.GetDefaultServerConfig,
      server,
      undefined,
      options
    );
  }

  /**
   * I believe this removes all terminals from a given hicaps
   * connect instance
   */
  ClearTerminalList(
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.ClearTerminalList>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.ClearTerminalList,
      undefined,
      undefined,
      options
    );
  }

  /**
   * ???
   */
  getStandAloneMode(
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.GetStandAloneMode>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.GetStandAloneMode,
      undefined,
      undefined,
      options
    );
  }

  /**
   * Returns an Array containing a list of ServerURL’s that has been
   * found on the network for use in the ServerURL property.
   *
   * e.g [ “192.168.0.3:11000:SE102A:COM5" ]
   */
  getTerminalList(
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.GetTerminalList>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.GetTerminalList,
      undefined,
      undefined,
      options
    );
  }

  /**
   * Returns an Array containing a list of ServerURL’s that has been
   * found on the network for use in the ServerURL property
   * This method simply returns an array of Terminal Id’s
   *
   * i.e { “SE101A”, “SE102A”}
   * You may use this instead of the full ServerURL string
   */
  getTerminalListById(
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.GetTerminalListById>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.GetTerminalListById,
      undefined,
      undefined,
      options
    );
  }

  /**
   * Used to turn on or off the status display window. By default the
   * status window will always be displayed.
   */
  DisplayStatusWindow(
    flag: boolean,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.DisplayStatusWindow>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.DisplayStatusWindow,
      flag,
      undefined,
      options
    );
  }

  /**
   * Returns a list of items stored inside the terminals Memory.
   * This information is used to set the Item Number fields for Claim
   * Transactions.
   *
   * Only Available HICAPS Connect 1.0.3.19+
   */
  sendAllItemList(
    myRequest: HicapsConnect.AllItemListRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendAllItemList>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendAllItemList,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Returns a list of all Item Response Codes stored inside the
   * terminals Memory.
   * This information is used to get the English Descriptions for Item
   * Level Response codes within Claim Transaction responses.
   *
   * Only Available HICAPS Connect 1.0.3.19+
   */
  sendAllItemResponseCodeList(
    myRequest: HicapsConnect.AllItemResponseCodeListRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendAllItemResponseCodeList>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendAllItemResponseCodeList,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Returns a list of all Merchants stored inside the terminals
   * Memory.
   * This information is used to set the MerchantId fields for Eftpos
   * and Claim Transactions.
   */
  sendAllMerchantList(
    myRequest: HicapsConnect.AllMerchantListRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendAllMerchantList>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendAllMerchantList,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Returns a list of items retrieved from the HICAPS Connect
   * WebAPI.
   *
   * This information is used to set the Item Number fields for Claim
   * Transactions.
   *
   * Only Available HICAPS Connect 1.0.3.19+
   *
   * If the WebAPI is not available, it will return an error code of
   * “NI” No Internet available, check with your internet availability,
   * if the last list retrieved has expired. If you attempt to send a
   * Pharmacy claim type, then it will also fail with this error.
   */
  sendAllPharmItemList(
    myRequest: HicapsConnect.AllPharmItemListRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendAllPharmItemList>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendAllPharmItemList,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Returns a list of all Providers stored inside the terminals
   * Memory.
   *
   * This information is used to set the ProviderNumberId
   * information for the processing of Claims.
   */
  sendAllProviderList(
    myRequest: HicapsConnect.AllProviderListRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendAllProviderList>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendAllProviderList,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Returns a list of all Transaction Response Codes stored inside
   * the terminals Memory.
   * This information is used to get the English Descriptions for
   * Transaction Level Response codes within Claim Transaction
   * responses.
   *
   * Only Available HICAPS Connect 1.0.3.19+
   */
  sendAllTransCodeListResponse(
    myRequest: HicapsConnect.AllTransCodeListRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendAllTransCodeListResponse>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendAllTransCodeListResponse,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Returns a list of all Health Fund Card Prefixes, and a identifier
   * which shows the maximum number of claim lines a particular
   * Fund allows.
   * This information is needed to ensure you do not exceed the
   * maximum number of lines.
   */
  sendCardList(
    myRequest: HicapsConnect.CardListRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendCardList>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendCardList,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Requests the Terminal to perform a test Card read function.
   * The CardReadResponse contains the information stored on the
   * card track.
   */
  sendCardRead(
    myRequest: HicapsConnect.CardReadRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendCardRead>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendCardRead,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Sends an Eftpos Cashout request to the terminal.
   * Note. Cashout Request on a Credit Card is NOT allowed.
   *
   * Object Mandatory Fields:
   *  1. MerchantId
   *  2. CashoutAmount > 0
   *
   * Optional Fields
   *  1. PrimaryAccountNumber
   *  2. CCV
   *  3. CCVReason
   *  4. CCVSource
   *  5. ExpiryDate
   */
  sendCashout(
    myRequest: HicapsConnect.CashoutRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendCashout>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendCashout,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Used to Cancel a Health Claim that was previously sent to the
   * Health Fund for Processing.
   *
   * Mandatory Fields:
   *  1. ProviderNumberId
   *  2. TransactionAmount >= 0
   *  3. RRN
   *
   * Optional Fields
   *  1. PrimaryAccountNumber
   *
   * Conditional Fields
   */
  sendClaimCancelRequest(
    myRequest: HicapsConnect.ClaimCancelRequest,
    extendedData: HicapsConnectExtended.SendClaimCancelRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendClaimCancelRequest>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendClaimCancelRequest,
      myRequest,
      extendedData,
      options
    );
  }

  /**
   * Used to submit a Health Pharmacy Claim to the Health Fund for
   * Processing.
   *
   * Object Mandatory Fields:
   *  1. ProviderNumberId
   *  2. At least one claim line must be added using the
   *    ClaimRequest.addClaimPharmLine Method.
   *
   * Optional Fields
   *  1. None
   *
   * Conditional Fields
   *  1. None
   *
   */
  sendClaimPharmRequest(
    myRequest: HicapsConnect.ClaimPharmRequest,
    extendedData: HicapsConnectExtended.SendClaimPharmRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendClaimPharmRequest>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendClaimPharmRequest,
      myRequest,
      extendedData,
      options
    );
  }

  /**
   * Used to submit a Health Claim to the Health Fund for
   * Processing.
   *
   * Object Mandatory Fields:
   *  1. ProviderNumberId
   *  2. At least one claim line must be added using the ClaimRequest.addClaimLine Method.
   *
   * Optional Fields
   *  1. PrimaryAccountNumber
   *
   * Conditional Fields
   *  1. None.
   */
  sendClaimRequest(
    myRequest: HicapsConnect.ClaimRequest,
    extendedData: HicapsConnectExtended.SendClaimRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendClaimRequest>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendClaimRequest,
      myRequest,
      extendedData,
      options
    );
  }

  /**
   * ???
   */
  sendEftposDeposit(
    myRequest: HicapsConnect.EftposDepositRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendEftposDeposit>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendEftposDeposit,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Returns a list of all Eftpos Transactions that have been
   * submitted for Today or Yesterday.
   *
   * Mandatory Fields:
   *  1. None
   *
   * Optional Fields
   *  1. MerchantId
   *
   * Conditional Fields
   *  1. None
   */
  sendEftposTransListing(
    myRequest: HicapsConnect.EftposTransListingRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendEftposTransListing>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendEftposTransListing,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * ???
   */
  sendGetTerminals(
    myRequest: HicapsConnect.GetTerminalRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendGetTerminals>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendGetTerminals,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Returns a list of the settlement Totals for all Eftpos and Hicaps
   * transactions that have been submitted.
   *
   * Mandatory Fields:
   *  1. None
   *
   * Optional Fields
   *  1. None
   *
   * Conditional Fields
   *  1. None
   */
  sendHicapsTotals(
    myRequest: HicapsConnect.HicapsTotalsRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendHicapsTotals>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendHicapsTotals,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Returns a list of all Hicaps Transactions submitted for
   * processing.
   *
   * Mandatory Fields:
   *  1. None
   *
   * Optional Fields
   *  1. None
   *
   * Conditional Fields
   *  1. None
   */
  sendHicapsTransListing(
    myRequest: HicapsConnect.HicapsTransListingRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendHicapsTransListing>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendHicapsTransListing,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Used to submit a Medicare Claim to Medicare.
   *
   * Mandatory Fields:
   *  1. ClaimType
   *  2. ServiceTypeCde
   *  3. ServicingProviderNum
   *
   * Optional Fields
   *  1. Refer to Medicare Easyclaim Guide
   *
   * Conditional Fields
   *  1. Refer to Medicare Easyclaim Guide
   */
  sendMedicareClaimRequest(
    myRequest: HicapsConnect.MedicareClaimRequest,
    extendedData: HicapsConnectExtended.SendMedicareClaimRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendMedicareClaimRequest>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendMedicareClaimRequest,
      myRequest,
      extendedData,
      options
    );
  }

  /**
   * ???
   */
  sendMedicareMerchantList(
    myRequest: HicapsConnect.MedicareMerchantListRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendMedicareMerchantList>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendMedicareMerchantList,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * ???
   */
  sendMerchantList(
    myRequest: HicapsConnect.MerchantListRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendMerchantList>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendMerchantList,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Re-Prints the last receipt that was printed from the terminal. If
   * the PrintReceiptOnTerminal property is set to False, the data
   * from the previous transaction is returned instead of printing.
   *
   * Mandatory Fields:
   *  1. None
   *
   * Optional Fields
   *  1. None
   *
   * Conditional Fields
   *  1. None
   */
  sendPrintLastReceipt(
    myRequest: HicapsConnect.PrintLastReceiptRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendPrintLastReceipt>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendPrintLastReceipt,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * ???
   */
  sendQuotePharmRequest(
    myRequest: HicapsConnect.QuotePharmRequest,
    extendedData: HicapsConnectExtended.SendClaimPharmRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendQuotePharmRequest>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendQuotePharmRequest,
      myRequest,
      extendedData,
      options
    );
  }

  /**
   * ???
   */
  sendQuoteRequest(
    myRequest: HicapsConnect.QuoteRequest,
    extendedData: HicapsConnectExtended.SendClaimRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendQuoteRequest>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendQuoteRequest,
      myRequest,
      extendedData,
      options
    );
  }

  /**
   * Used to send a Eftpos Refund to refund money back to the card
   * holder. A popup window will appear requiring the user to insert
   * in the Merchant Password.
   *
   * Object Mandatory Fields:
   *  1. MerchantId
   *  2. TransactionAmount > 0
   *
   * Optional Fields
   *  1. PrimaryAccountNumber
   *  2. CCV
   *  3. CCVReason
   *  4. CCVSource
   *  5. ExpiryDate
   *
   * Conditional Fields
   *  1. None.
   */
  sendRefund(
    myRequest: HicapsConnect.RefundRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendRefund>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendRefund,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Used to send a Eftpos Sale Transaction to the Terminal
   *
   * Object Mandatory Fields:
   *  1. MerchantId
   *  2. TransactionAmount > 0
   *
   * Optional Fields
   *  1. PrimaryAccountNumber
   *  2. CCV
   *  3. CCVReason
   *  4. CCVSource
   *  5. ExpiryDate
   *
   * Conditional Fields
   *  1. None.
   */
  sendSale(
    myRequest: HicapsConnect.SaleRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<PrincipleHicapsConnectResponse<HicapsConnectMethod.SendSale>> {
    return this._adaptor.call$(
      HicapsConnectMethod.SendSale,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Used to send a combined Sale and Cashout Transaction to the
   * Terminal
   *
   * Object Mandatory Fields:
   *  1. MerchantId
   *  2. TransactionAmount > 0
   *  3. CashoutAmount > 0
   *
   * Optional Fields
   *  1. PrimaryAccountNumber
   *  2. CCV
   *  3. CCVReason
   *  4. CCVSource
   *  5. ExpiryDate
   *
   * Conditional Fields
   *  1. None.
   */
  sendSaleCashout(
    myRequest: HicapsConnect.SaleCashoutRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendSaleCashout>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendSaleCashout,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Used to send a Settlement Transaction to the Terminal.
   *
   * Object Mandatory Fields:
   *  1. AcquirerId Normally set to 1
   *
   * Optional Fields
   *  1. None
   *
   * Conditional Fields
   *  1. None
   */
  sendSettlement(
    myRequest: HicapsConnect.SettlementRequest,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendSettlement>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendSettlement,
      myRequest,
      undefined,
      options
    );
  }

  /**
   * Used to Test Connectivity between the PMS and the Terminal
   */
  sendTerminalTest(
    terminal: string,
    options?: IHicapsConnectApiCallOptions
  ): Observable<
    PrincipleHicapsConnectResponse<HicapsConnectMethod.SendTerminalTest>
  > {
    return this._adaptor.call$(
      HicapsConnectMethod.SendTerminalTest,
      terminal,
      undefined,
      options
    );
  }
}
