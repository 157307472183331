import { ChartType } from '@principle-theorem/reporting';
import { BaseChart } from './base-chart';
import { type IChartData, ChartOptions } from './chart-builder';

export class ForecastChart extends BaseChart {
  data: IChartData = {
    type: ChartType.BasicLine,
    options: ChartOptions.init({
      legend: { position: 'none' },
      series: {
        0: { lineDashStyle: [4, 4] },
      },
      vAxis: {
        gridlines: { count: 3 },
      },
      hAxis: {
        gridlines: { count: 4 },
      },
    }),
  };
}
