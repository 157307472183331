import { createReducer, on } from '@ngrx/store';
import {
  type IAppointment,
  type IAppointmentRequest,
  type IChecklistItem,
  type IInteractionV2,
  type IPatient,
  type IPatientDetails,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  serialise,
  type INamedDocument,
  type SerialisedData,
  type WithRef,
} from '@principle-theorem/shared';
import {
  AppointmentDetailsActions,
  AppointmentRequestActions,
  AppointmentSelectorActions,
  PatientDetailsActions,
  SchedulingActions,
  WaitListActions,
} from '../actions';
import {
  getDefaultFilterOptions,
  initAppointmentDetails,
  initWaitListDetails,
  type IAppointmentDetails,
  type IAppointmentFilterOptions,
  type IWaitListDetails,
} from '../models';

export const SCHEDULING_FEATURE_KEY = 'appointmentScheduling';

export interface IAppointmentSchedulingState {
  selectedPatient?: SerialisedData<WithRef<IPatient>>;
  patientDetails?: SerialisedData<IPatientDetails>;
  patientDetailsError?: string;
  appointmentDetails: SerialisedData<IAppointmentDetails>;
  waitlistDetails: SerialisedData<IWaitListDetails>;
  filterOptions: SerialisedData<IAppointmentFilterOptions>;
  interactions: SerialisedData<IInteractionV2[]>;
  appointment?: SerialisedData<WithRef<IAppointment>>;
  appointmentRequest?: SerialisedData<WithRef<IAppointmentRequest>>;
  savingAppointment: boolean;
  tags: SerialisedData<INamedDocument<ITag>[]>;
  checklists: SerialisedData<IChecklistItem[]>;
}

export interface IAppointmentSchedulingPartialState {
  readonly [SCHEDULING_FEATURE_KEY]: IAppointmentSchedulingState;
}

export const initialAppointmentSchedulingState: IAppointmentSchedulingState =
  serialise({
    savingAppointment: false,
    appointmentDetails: initAppointmentDetails(),
    filterOptions: getDefaultFilterOptions(),
    waitlistDetails: initWaitListDetails(),
    interactions: [],
    tags: [],
    checklists: [],
  });

export const appointmentSchedulingReducer = createReducer(
  initialAppointmentSchedulingState,

  on(PatientDetailsActions.selectPatient, (state, { patient }) => ({
    ...state,
    selectedPatient: patient,
    patientDetails: undefined,
    appointmentDetails: {
      ...state.appointmentDetails,
    },
  })),

  on(PatientDetailsActions.clearPatient, (state) => ({
    ...state,
    selectedPatient: undefined,
    patientDetails: undefined,
    appointmentDetails: {
      ...state.appointmentDetails,
      overridePlan: undefined,
    },
  })),

  on(PatientDetailsActions.saveNewPatientSuccess, (state, { patient }) => ({
    ...state,
    selectedPatient: patient,
  })),

  on(PatientDetailsActions.setPatientDetails, (state, { patientDetails }) => ({
    ...state,
    patientDetails,
  })),

  on(PatientDetailsActions.clearPatientDetails, (state) => ({
    ...state,
    patientDetails: undefined,
  })),

  on(PatientDetailsActions.patchPatientDetails, (state, { patientDetails }) => {
    const details = {
      ...state.patientDetails,
      ...patientDetails,
    } as SerialisedData<IPatientDetails>;
    return {
      ...state,
      patientDetails: details,
    };
  }),

  on(PatientDetailsActions.updateExistingPatient, (state, action) => {
    if (state.selectedPatient) {
      return {
        ...state,
        selectedPatient: { ...state.selectedPatient, ...action.changes },
      };
    }
    return state;
  }),

  on(
    AppointmentDetailsActions.appointmentDetailsChange,
    (state, { appointmentDetails }) => ({
      ...state,
      appointmentDetails: {
        ...state.appointmentDetails,
        ...appointmentDetails,
      },
    })
  ),

  on(AppointmentSelectorActions.updateFilterOptions, (state, { change }) => ({
    ...state,
    filterOptions: { ...state.filterOptions, ...change },
  })),

  on(SchedulingActions.saveNewAppointment, (state) => ({
    ...state,
    savingAppointment: true,
  })),

  on(
    SchedulingActions.saveNewAppointmentSuccess,
    SchedulingActions.saveAppointmentFailure,
    (state) => ({
      ...state,
      savingAppointment: false,
    })
  ),

  on(SchedulingActions.updateInteractions, (state, { interactions }) => ({
    ...state,
    interactions,
  })),

  on(SchedulingActions.setExistingAppointment, (state, { appointment }) => ({
    ...state,
    appointment,
  })),

  on(
    SchedulingActions.setRequestedAppointmentOption,
    (state, { appointment }) => {
      let updates = {};
      if (appointment) {
        updates = {
          appointment,
        };
      }
      return {
        ...state,
        ...updates,
      };
    }
  ),

  on(WaitListActions.setWaitListDetails, (state, { waitlistDetails }) => ({
    ...state,
    waitlistDetails,
  })),

  on(WaitListActions.waitlistDetailsChange, (state, { update }) => ({
    ...state,
    waitlistDetails: { ...state.waitlistDetails, ...update },
  })),

  on(SchedulingActions.resetSchedulingState, (state) => ({
    ...initialAppointmentSchedulingState,
    appointmentRequest: state.appointmentRequest,
  })),

  on(SchedulingActions.updateAppointmentTags, (state, { tags }) => ({
    ...state,
    tags,
  })),

  on(
    SchedulingActions.updateAppointmentChecklists,
    (state, { checklists }) => ({
      ...state,
      checklists,
    })
  ),

  on(
    AppointmentRequestActions.setAppointmentRequest,
    (state, { appointmentRequest }) => ({
      ...state,
      appointmentRequest,
    })
  ),

  on(AppointmentRequestActions.clearAppointmentRequest, (state) => ({
    ...state,
    appointmentRequest: undefined,
  }))
);
