import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { type Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { loadEventablesRouteData } from '../actions';

@Injectable()
export class EventableRouterEffects {
  private _actions$ = inject(Actions);
  handleRoute$: Observable<Action> = createEffect(() =>
    this._handleRouteParams$()
  );

  private _handleRouteParams$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(routerNavigatedAction),
      filter(({ payload }) =>
        ['dashboard', 'timeline'].some((page) =>
          payload.event.url.includes(page)
        )
      ),
      map(() => loadEventablesRouteData())
    );
  }
}
