<div
  class="rounded-md bg-white"
  fxLayout="row"
  fxLayoutAlign="space-between start"
>
  <form [formGroup]="form" class="flex flex-col">
    <mat-form-field
      subscriptSizing="dynamic"
      appearance="outline"
      class="compact"
    >
      <mat-label>Link</mat-label>
      <input
        matInput
        type="url"
        placeholder="Enter the url"
        formControlName="href"
      />
      <span matTextSuffix fxLayout="row" fxLayoutAlign="start center">
        <a
          class="open-link"
          [href]="form.controls.href.value"
          mat-icon-button
          target="_blank"
          rel="noopener noreferrer nofollow"
          matTooltip="Open Link"
          fxLayout="row"
          fxLayoutAlign="space-around center"
        >
          <mat-icon>open_in_new</mat-icon>
        </a>
        <button mat-icon-button (click)="removeLink()" matTooltip="Remove Link">
          <mat-icon>link_off</mat-icon>
        </button>
      </span>
    </mat-form-field>
  </form>
</div>
