<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="32px" class="wrapper">
  <h1 class="mat-headline-4">Automation Configurations</h1>

  <mat-tab-group>
    <mat-tab label="Appointment">
      <ng-template matTabContent>
        <div class="flex flex-col gap-4 py-4">
          <div class="flex flex-row items-center gap-4">
            <div class="flex grow"></div>
            <button mat-flat-button color="primary" (click)="addTask()">
              Add Task
            </button>
            <button mat-flat-button color="primary" (click)="addFormIssue()">
              Add Form
            </button>
            <button mat-flat-button color="primary" (click)="addNotification()">
              Add Notification
            </button>
          </div>

          <pr-automation-configuration-list
            [brand]="brand$ | async"
            [configs]="appointmentConfigs$ | async"
            [templateScope]="automatedNotificationScope"
          />
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab label="Treatment">
      <ng-template matTabContent>
        <div class="flex flex-col gap-4 py-4">
          <pr-automation-configuration-list
            [brand]="brand$ | async"
            [configs]="treatmentConfigs$ | async"
            [templateScope]="automatedNotificationScope"
          />
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab label="Deleted">
      <ng-template matTabContent>
        <div class="flex flex-col gap-4 py-4">
          <pr-automation-configuration-list
            [brand]="brand$ | async"
            [configs]="deletedConfigs$ | async"
            [templateScope]="automatedNotificationScope"
          />
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
