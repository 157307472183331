import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { type IPractice } from '@principle-theorem/principle-core/interfaces';
import { patchDoc, snapshot, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { UpsertPracticeDialogComponent } from '../upsert-practice-dialog/upsert-practice-dialog.component';

@Component({
  selector: 'pr-practice-list-item',
  templateUrl: './practice-list-item.component.html',
  styleUrls: ['./practice-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PracticeListItemComponent {
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);

  constructor(private _dialog: MatDialog, private _snackBar: MatSnackBar) {}

  @Input()
  set practice(practice: WithRef<IPractice>) {
    if (practice) {
      this.practice$.next(practice);
    }
  }

  async edit(): Promise<void> {
    const practice = await snapshot(this.practice$);
    const dialogRef: MatDialogRef<UpsertPracticeDialogComponent> =
      this._dialog.open(
        UpsertPracticeDialogComponent,
        DialogPresets.medium({
          data: {
            practice,
          },
        })
      );

    const practiceChange: WithRef<IPractice> = await dialogRef
      .afterClosed()
      .toPromise<WithRef<IPractice>>();
    if (!practiceChange) {
      return;
    }
    await patchDoc(practice.ref, practiceChange);
    this._snackBar.open('Practice Updated');
  }
}
