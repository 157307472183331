import { initVersionedSchema } from '@principle-theorem/editor';
import { INote } from '@principle-theorem/principle-core/interfaces';
import { toTimestamp } from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';

export class Note {
  static init(overrides?: Partial<INote>): INote {
    return {
      uid: uuid(),
      content: initVersionedSchema(),
      pinned: false,
      createdAt: toTimestamp(),
      updatedAt: toTimestamp(),
      deleted: false,
      ...overrides,
    };
  }
}
