import { initVersionedSchema, toTextContent } from '@principle-theorem/editor';
import {
  Brand,
  Event,
  Interaction,
  TreatmentPlan,
  TreatmentStep,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  EventType,
  IAssociatedTreatment,
  IDestinationEntityJobRunOptions,
  ITag,
  ITranslationMap,
  ITreatmentCategory,
  ParticipantType,
  SkippedDestinationEntityRecord,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  isTag,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IEvent,
  type IGetRecordResponse,
  type IInteractionV2,
  type IPatient,
  type IPractice,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  HISTORY_DATE_FORMAT,
  INamedDocument,
  ITimePeriod,
  asyncForAll,
  getError,
  initFirestoreModel,
  isINamedDocument,
  multiMap,
  snapshotCombineLatest,
  toMoment,
  toMomentTz,
  toNamedDocument,
  toTimestamp,
  type DocumentReference,
  type ISODateType,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, from, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import {
  BasePatientAppointmentDestinationEntity,
  IPatientAppointmentBuildData,
  IPatientAppointmentJobData,
  IPatientAppointmentMigrationData,
  PATIENT_APPOINTMENT_DESTINATION_ENTITY,
} from '../../../destination/entities/patient-appointments';
import { findTreatmentStepForAppointment } from '../../../destination/entities/patient-treatment-plans';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { getPractitionerOrDefaultMapping } from '../../../mappings/staff';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  ICorePracticePatientAppointment,
  ICorePracticePatientAppointmentTranslations,
  PatientAppointmentSourceEntity,
} from '../../source/entities/patient-appointments';
import {
  ICorePracticePatientTreatment,
  ICorePracticePatientTreatmentFilters,
  ICorePracticePatientTreatmentTranslations,
  PatientTreatmentSourceEntity,
} from '../../source/entities/patient-treatments';
import {
  ICorePracticePatient,
  ICorePracticePatientTranslations,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { CorePracticeAppointmentBookToPractitionerMappingHandler } from '../mappings/appointment-book-to-practitioner';
import { CorePracticeEventClassToTreatmentCategoryMappingHandler } from '../mappings/event-class-to-treatment-category';
import { EventClassDestination } from '../mappings/event-class-to-treatment-category-to-xslx';
import { CorePracticeExcludedAppointmentBooksMappingHandler } from '../mappings/exclude-appointment-books';
import { CorePracticePracticeMappingHandler } from '../mappings/practices';
import { CorePracticeStafferMappingHandler } from '../mappings/staff';
import {
  CorePracticeTreatmentPlanDataBuilder,
  PatientTreatmentPlanDestinationEntity,
} from './patient-treatment-plans';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';

export const CORE_PRACTICE_MIGRATED_APPOINTMENTS_PLAN_NAME =
  'Core Practice - Migrated Appointments';

interface IJobData
  extends IPatientAppointmentJobData<
    ICorePracticePatient,
    ICorePracticePatientTranslations
  > {
  practices: WithRef<ITranslationMap<IPractice>>[];
  excludedAppointmentBookIds: string[];
  appointmentBookToPractitioner: WithRef<ITranslationMap<IStaffer>>[];
  eventClassToTreatmentCategory: WithRef<
    ITranslationMap<EventClassDestination>
  >[];
}

export class PatientAppointmentDestinationEntity extends BasePatientAppointmentDestinationEntity<
  ICorePracticePatient,
  ICorePracticePatientTranslations,
  IJobData
> {
  destinationEntity = PATIENT_APPOINTMENT_DESTINATION_ENTITY;
  patientSourceEntity = new PatientSourceEntity();
  treatmentPlanName = CORE_PRACTICE_MIGRATED_APPOINTMENTS_PLAN_NAME;

  override canMigrateByDateRange = true;
  override canMigrateByIdRange = true;

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
    treatments: new PatientTreatmentSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
    treatmentPlans: new PatientTreatmentPlanDestinationEntity(),
  };

  customMappings = {
    staff: new CorePracticeStafferMappingHandler(),
    practices: new CorePracticePracticeMappingHandler(),
    excludedAppointmentBooks:
      new CorePracticeExcludedAppointmentBooksMappingHandler(),
    appointmentBookToPractitioner:
      new CorePracticeAppointmentBookToPractitionerMappingHandler(),
    eventClassToTreatmentCategory:
      new CorePracticeEventClassToTreatmentCategoryMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IJobData[]> {
    const brand$ = Firestore.getDoc(migration.configuration.brand.ref);
    const practices$ =
      this.customMappings.practices.getRecords$(translationMap);
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const appointmentBookToPractitioner$ =
      this.customMappings.appointmentBookToPractitioner.getRecords$(
        translationMap
      );
    const practitioners$ = from(brand$).pipe(
      switchMap((brand) => Firestore.getDocs(Brand.stafferCol(brand)))
    );
    const excludedAppointmentBooks$ =
      this.customMappings.excludedAppointmentBooks.getRecords$(translationMap);
    const eventClassToTreatmentCategory$ =
      this.customMappings.eventClassToTreatmentCategory.getRecords$(
        translationMap
      );

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([
        staff$,
        practitioners$,
        brand$,
        practices$,
        excludedAppointmentBooks$.pipe(
          multiMap((book) => book.sourceIdentifier.toString())
        ),
        appointmentBookToPractitioner$,
        eventClassToTreatmentCategory$,
      ]),
    ]).pipe(
      map(
        ([
          sourcePatients,
          [
            staff,
            practitioners,
            brand,
            practices,
            excludedAppointmentBookIds,
            appointmentBookToPractitioner,
            eventClassToTreatmentCategory,
          ],
        ]) =>
          sourcePatients.map((sourcePatient) => ({
            sourcePatient,
            staff,
            practitioners,
            brand,
            practices,
            excludedAppointmentBookIds,
            appointmentBookToPractitioner,
            eventClassToTreatmentCategory,
          }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IJobData
  ): Promise<
    | IPatientAppointmentMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
    | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients.getSourceRecordId(
      data.sourcePatient.data.data
    );

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `No patient with id ${sourcePatientId}`
      );
    }

    const sourceAppointments =
      await this.sourceEntities.appointments.filterRecords(
        migration,
        'patientId',
        data.sourcePatient.data.data.id
      );

    try {
      const appointments: (IPatientAppointmentBuildData | undefined)[] =
        await asyncForAll(sourceAppointments, async (sourceAppointment) => {
          const appointmentUid = this.sourceEntities.appointments
            .getSourceRecordId(sourceAppointment.data.data)
            .toString();

          const appointmentBookId =
            sourceAppointment.data.data.calendarId.toString();

          const shouldExcludeAppointment =
            data.excludedAppointmentBookIds.includes(appointmentBookId);

          if (shouldExcludeAppointment) {
            return;
          }

          const providerId = sourceAppointment.data.data.providerId?.toString();

          const practitionerMap = this.resolvePractitioner(
            data,
            appointmentBookId,
            providerId
          );

          if (!practitionerMap) {
            throw new Error(
              `No practitioner with id: ${
                providerId ?? ''
              }, appointmentBookId: ${appointmentBookId}`
            );
          }

          const practiceSourceId =
            sourceAppointment.data.data.locationId.toString();
          const practiceMap = data.practices.find(
            (practice) => practice.sourceIdentifier === practiceSourceId
          );

          if (!practiceMap?.destinationIdentifier) {
            throw new Error(`No practice with id ${practiceSourceId}`);
          }

          const practice = await Firestore.getDoc(
            practiceMap.destinationIdentifier
          );

          const createdAt = sourceAppointment.data.translations.from;

          const [appointment, interactions] = await this._buildAppointmentData(
            migration,
            translationMap,
            sourcePatientId,
            patientRef,
            practitionerMap,
            sourceAppointment,
            practice,
            migration.configuration.backupDate
          );

          const planUid = `${sourcePatientId}-appointments`;
          const stepUid = `${sourcePatientId}-${appointmentUid}-appointments`;
          const plan = TreatmentPlan.init({
            name: CORE_PRACTICE_MIGRATED_APPOINTMENTS_PLAN_NAME,
            status: TreatmentPlanStatus.InProgress,
          });

          const timezone = migration.configuration.timezone;
          const appointmentDate = toMomentTz(
            sourceAppointment.data.translations.from,
            timezone
          ).format(HISTORY_DATE_FORMAT);

          const mappedCategoryDestination =
            data.eventClassToTreatmentCategory.find(
              (categoryMap) =>
                categoryMap.sourceIdentifier ===
                sourceAppointment.data.data.eventClassId?.toString()
            );
          const categoryDestinationIdentifier = isINamedDocument(
            mappedCategoryDestination?.associatedValue
          )
            ? mappedCategoryDestination?.associatedValue
            : undefined;

          const categoryDestination = categoryDestinationIdentifier
            ? await Firestore.getDoc(categoryDestinationIdentifier.ref)
            : undefined;

          const overrideTreatmentCategory = isTag(categoryDestination)
            ? undefined
            : (categoryDestination?.ref as DocumentReference<ITreatmentCategory>);

          const appointmentTag = isTag(categoryDestination)
            ? (categoryDestination as INamedDocument<ITag>)
            : undefined;
          if (appointmentTag) {
            appointment.tags.push(appointmentTag);
          }

          const step = TreatmentStep.init({
            name: appointmentDate,
            status:
              appointment.status === AppointmentStatus.Complete
                ? TreatmentStepStatus.Complete
                : TreatmentStepStatus.Incomplete,
            schedulingRules: {
              duration: appointment.event
                ? Event.duration(appointment.event)
                : 0,
            },
            display: {
              overrideTreatmentCategory,
            },
          });

          return {
            sourcePatientId: sourcePatientId.toString(),
            patientRef,
            appointmentUid,
            createdAt,
            appointment,
            planUid,
            plan,
            stepUid,
            step,
            interactions,
          };
        });

      return {
        patientRef,
        sourcePatientId: sourcePatientId.toString(),
        appointments: compact(appointments),
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  resolvePractitioner(
    data: IJobData,
    appointmentBookId: string,
    providerId?: string
  ): DocumentReference<IStaffer> | undefined {
    const appointmentBookPractitionerMap =
      data.appointmentBookToPractitioner.find(
        (practitionerMap) =>
          practitionerMap.sourceIdentifier === appointmentBookId
      );

    if (appointmentBookPractitionerMap?.sourceLink?.sourceIdentifier) {
      return getPractitionerOrDefaultMapping(
        appointmentBookPractitionerMap.sourceLink.sourceIdentifier,
        data.staff
      )?.destinationIdentifier;
    }

    return getPractitionerOrDefaultMapping(providerId, data.staff)
      ?.destinationIdentifier;
  }

  private async _buildAppointmentData(
    migration: WithRef<IPracticeMigration>,
    translationMap: TranslationMapHandler,
    sourcePatientId: number,
    patientRef: DocumentReference<IPatient>,
    practitionerRef: DocumentReference<IStaffer>,
    sourceAppointment: IGetRecordResponse<
      ICorePracticePatientAppointment,
      ICorePracticePatientAppointmentTranslations
    >,
    practice: WithRef<IPractice>,
    backupDate: ISODateType
  ): Promise<[IPatientAppointmentBuildData['appointment'], IInteractionV2[]]> {
    const patient = await Firestore.getDoc(patientRef);
    const practitioner = await Firestore.getDoc(practitionerRef);

    const interactions = compact([
      sourceAppointment.data.data.description,
      sourceAppointment.data.data.note,
    ]).map((note) => {
      const createdAt = toMoment(
        sourceAppointment.data.translations.from
      ).isAfter(moment())
        ? toTimestamp()
        : sourceAppointment.data.translations.from;

      return Interaction.init({
        uid: sourceAppointment.record.uid,
        title: [toTextContent(`Added a note`)],
        content: initVersionedSchema(note),
        createdAt,
        pinned: true,
      });
    });

    const appointmentStartTime = toMomentTz(
      sourceAppointment.data.translations.from,
      practice.settings.timezone
    );
    const appointmentEndTime = toMomentTz(
      sourceAppointment.data.translations.to,
      practice.settings.timezone
    );

    const treatments = await this.sourceEntities.treatments.filterRecords(
      migration,
      'patientId',
      sourcePatientId,
      undefined,
      undefined,
      (treatment) =>
        treatment.data.translations.completeDate
          ? toMomentTz(
              treatment.data.translations.completeDate,
              practice.settings.timezone
            ).isSame(appointmentStartTime, 'day')
          : false
    );

    const associatedPlan = await resolveAssociatedPlan(
      sourcePatientId,
      practice,
      translationMap,
      first(treatments)
    );

    const appointment: IPatientAppointmentBuildData['appointment'] = {
      event: getAppointmentEvent(
        {
          from: appointmentStartTime,
          to: appointmentEndTime,
        },
        patient,
        practice,
        practitioner
      ),
      eventHistory: [],
      status: sourceAppointment.data.data.cancelDate
        ? AppointmentStatus.Cancelled
        : appointmentStartTime.isAfter(toMoment(backupDate))
          ? AppointmentStatus.Scheduled
          : AppointmentStatus.Complete,
      statusHistory: [],
      practice: toNamedDocument(practice),
      practitioner: stafferToNamedDoc(practitioner),
      cancellationHistory: [],
      dependencies: [],
      tags: [],
      ...initFirestoreModel(),
      deleted: sourceAppointment.data.data.isDeleted,
      treatmentPlan: associatedPlan,
    };

    return [appointment, interactions];
  }
}

export function getAppointmentEvent(
  period: ITimePeriod,
  patient?: WithRef<IPatient>,
  practice?: WithRef<IPractice>,
  practitioner?: WithRef<IStaffer>
): IEvent | undefined {
  if (!practice || !patient || !practitioner) {
    return;
  }
  return Event.init({
    from: toTimestamp(period.from),
    to: toTimestamp(period.to),
    practice: toNamedDocument(practice),
    type: EventType.Appointment,
    participants: [
      {
        ...toNamedDocument(patient),
        type: ParticipantType.Patient,
      },
      {
        ...stafferToNamedDoc(practitioner),
        type: ParticipantType.Staffer,
      },
    ],
  });
}

async function resolveAssociatedPlan(
  sourcePatientId: number,
  practice: WithRef<IPractice>,
  translationMap: TranslationMapHandler,
  treatment?: IGetRecordResponse<
    ICorePracticePatientTreatment,
    ICorePracticePatientTreatmentTranslations,
    ICorePracticePatientTreatmentFilters
  >
): Promise<IAssociatedTreatment | undefined> {
  if (!treatment) {
    return;
  }

  const stepUid = CorePracticeTreatmentPlanDataBuilder.getStepIdentifier(
    sourcePatientId,
    practice.settings.timezone,
    treatment
  );

  if (!stepUid) {
    return;
  }

  const stepRef = await findTreatmentStepForAppointment(
    translationMap,
    stepUid
  );

  if (!stepRef) {
    return;
  }

  const step = await Firestore.getDoc(stepRef);
  const plan = await TreatmentStep.treatmentPlan(step);

  return TreatmentPlan.treatmentStepToAssociatedTreatment(plan, step);
}
