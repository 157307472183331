import {
  CustomMappingOption,
  CustomMappingType,
  ICustomMappingSourceOption,
  IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { CustomMapping } from '../custom-mapping';
import {
  ITEM_CODE_TO_NOTE_CUSTOM_MAPPING_TYPE,
  ItemCodeNoteType,
  ItemCodesToNotesXLSX,
} from './item-codes-to-notes-xlsx';
import { BaseCustomMappingHandler } from '../base-custom-mapping-handler';
import {
  IBlobFilenamePair,
  IReffable,
  WithRef,
  XSLXImporterExporter,
  asyncForEach,
  getEnumValues,
  hardDeleteDoc,
  snapshot,
} from '@principle-theorem/shared';
import { IBaseMigrationItemCode } from '../interfaces';
import { BaseSourceEntity } from '../source/base-source-entity';
import { sortBy, uniqBy } from 'lodash';
import { ServiceProviderHandler } from '@principle-theorem/principle-core';
import { TranslationMapHandler } from '../translation-map';
import { PracticeMigration } from '../practice-migrations';
import { ITEM_CODE_TO_NOTE_DEFAULT_MAPPINGS } from './ada-code-mappings';
import { XLSXToItemCodesToNotes } from './xlsx-to-item-code-to-notes';
import { of } from 'rxjs';

export const ITEM_CODE_TO_NOTE_MAPPING = CustomMapping.init({
  metadata: {
    label: 'Item Codes to Notes',
    description: `
      Use this to configure where the note content of Item Codes from the source PMS should be mapped to in Principle.
        - Sterilisation Record: This will become a sterilisation record in Principle. (Not done for Exact migrations).
    `,
    type: ITEM_CODE_TO_NOTE_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.SelectionList,
  labelOverrides: {
    sourceIdentifier: 'Id',
    sourceLabel: 'Item Code',
    destinationIdentifier: 'Note Type',
    associatedValue: '',
  },
});

export abstract class ItemCodeToNoteMappingHandler<
  ADAItem extends object,
  SourceEntity extends BaseSourceEntity<ADAItem>,
> extends BaseCustomMappingHandler<object, ItemCodeNoteType> {
  customMapping = ITEM_CODE_TO_NOTE_MAPPING;
  abstract sourceEntity: SourceEntity;
  abstract translateFn: (item: ADAItem) => IBaseMigrationItemCode;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const itemCodes = await this._getItemCodeOptions(migration);
    return itemCodes.map((record) => ({
      label: !record.description
        ? record.itemCode
        : `${record.itemCode} - ${record.description}`,
      value: record.itemCode,
    }));
  }

  async getSelectionListOptions(
    _migration: WithRef<IPracticeMigration>
  ): Promise<CustomMappingOption[]> {
    return snapshot(
      of(
        getEnumValues(ItemCodeNoteType).map((noteType) => ({
          value: noteType,
          description: noteType,
          hasAssociatedValue: false,
        }))
      )
    );
  }

  async getMappingBlob(
    migration: IReffable<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, itemCodes, translator } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(fileName, itemCodes, translator);
  }

  async downloadMapping(
    migration: IReffable<IPracticeMigration>
  ): Promise<void> {
    const { fileName, itemCodes, translator } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(fileName, itemCodes, translator);
  }

  async uploadMapping(
    migration: IReffable<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XLSXToItemCodesToNotes()
    );
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const records = await this.getRecords(translationMap);
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));

    const sourceOptions = await this.getSourceOptions(migration);

    await asyncForEach(items, async (item) => {
      const label = `${item.code} - ${item.description}`;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.label === label
      )?.value;
      if (!value) {
        return;
      }

      const mapTo = item.mapTo;
      if (mapTo && !getEnumValues(ItemCodeNoteType).includes(mapTo)) {
        // eslint-disable-next-line no-console
        console.error(
          `Mapping error: ${this.customMapping.metadata.label} - Couldn't find note type for item`,
          item
        );
        return;
      }

      await this.upsertRecord(
        {
          destinationValue: mapTo,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: IReffable<IPracticeMigration>
  ): Promise<{
    fileName: string;
    itemCodes: IBaseMigrationItemCode[];
    translator: ItemCodesToNotesXLSX;
  }> {
    const fileName = this.getFileName();
    const itemCodes = await this._getItemCodeOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const translator = new ItemCodesToNotesXLSX(
      await this.getRecords(translationMap),
      ITEM_CODE_TO_NOTE_DEFAULT_MAPPINGS
    );
    return { fileName, itemCodes, translator };
  }

  private async _getItemCodeOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<IBaseMigrationItemCode[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 10000)
      .toPromise();
    const options = records
      .map((record) => record.data.data)
      .map(this.translateFn)
      .filter(
        (record) => !ServiceProviderHandler.findServiceCode(record.itemCode)
      );
    return sortBy(
      uniqBy(options, (option) => option.itemCode),
      'itemCode'
    );
  }
}
