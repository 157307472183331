<div
  fxLayout="row"
  fxLayoutAlign="start center"
  class="positive"
  *ngIf="changeIsPositive"
>
  <mat-icon>arrow_upward</mat-icon>
  <div>{{ changePercentage | percent: '1.2-2' }}</div>
</div>

<div
  fxLayout="row"
  fxLayoutAlign="start center"
  class="negative"
  *ngIf="!changeIsPositive"
>
  <mat-icon>arrow_downward</mat-icon>
  <div>{{ changePercentage | percent: '1.2-2' }}</div>
</div>
