<ng-container *ngIf="treatmentCategories$ | async as treatmentCategories">
  <ng-container *ngIf="treatmentCategories.length">
    <ng-container
      *ngIf="
        treatmentCategory$ | async as treatmentCategory;
        else addTreatmentCategory
      "
    >
      <div class="flex items-center gap-2">
        <div
          class="display-colour large button"
          [matTooltip]="'Treatment Category: ' + treatmentCategory.name"
          [style.backgroundColor]="treatmentCategory.colour.value"
          [matMenuTriggerFor]="menu"
        ></div>

        <button
          *ngIf="isOverride$ | async"
          class="reset"
          mat-icon-button
          [matTooltip]="overrideTooltip$ | async"
          (click)="deleteOverride()"
        >
          <mat-icon>restart_alt</mat-icon>
        </button>
      </div>
    </ng-container>

    <ng-template #addTreatmentCategory>
      <button
        class="set-treatment-category"
        aria-label="Set Treatment Category"
        label="Set Treatment Category"
        mat-icon-button
        matTooltip="Set Treatment Category"
        [matMenuTriggerFor]="menu"
      >
        <mat-icon>palette</mat-icon>
      </button>
    </ng-template>

    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        *ngFor="let category of treatmentCategories; trackBy: trackByRef"
        (click)="upsertOverride(category)"
      >
        <mat-icon
          [style.backgroundColor]="category.colour.value"
          class="display-colour"
        />
        <span>{{ category.name }}</span>
      </button>
    </mat-menu>
  </ng-container>
</ng-container>
