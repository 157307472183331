import {
  EventType,
  IAppointment,
  ICalendarEvent,
  ICalendarEventSchedule,
  IEvent,
  IPractice,
  IScheduleSummary,
  IScheduleSummaryEvent,
  IScheduleSummaryTarget,
  IScheduleSummaryUpsertAction,
  IStaffer,
  ParticipantType,
  ROSTERED_OFF_EVENT_TYPES,
  ROSTER_SCHEDULE_EVENT_TYPES,
  isAppointment,
  isCalendarEvent,
} from '@principle-theorem/principle-core/interfaces';
import {
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  FirestoreTransactionHelper,
  ISODateType,
  ITimePeriod,
  ITimestampRange,
  TimeBucket,
  Timezone,
  Transaction,
  WithRef,
  asyncForEach,
  getDaysInPeriod,
  isSameRef,
  limit,
  safeSnapshotToWithRef,
  snapshot,
  toISODate,
  toMomentTz,
  toQuery,
  toTimePeriod,
  toTimestampRange,
  undeletedQuery,
  where,
} from '@principle-theorem/shared';
import { compact, first, flatten, uniqBy } from 'lodash';
import * as moment from 'moment-timezone';
import { TimezoneResolver } from '../../timezone';
import { getStafferEventables$ } from '../event/brand-event-option-finder';
import { CalendarEvent } from '../event/calendar-event';
import { CalendarEventSchedule } from '../event/calendar-event-schedule';
import { isSameEvent } from '../event/event';
import { Practice } from '../practice/practice';
import { Roster } from '../staffer/roster';
import { ScheduleSummary } from './schedule-summary';

export const SCHEDULE_SUMMARY_SEED_RANGE_IN_MONTHS = 3;
export class ScheduleSummaryHelpers {
  static getDaysInPeriod(
    timezone: Timezone,
    timePeriod?: ITimePeriod
  ): ISODateType[] {
    return getDaysInPeriod(timezone, timePeriod);
  }

  static getSnapshotData<T extends object>(
    docSnapshot: DocumentSnapshot<T>
  ): WithRef<T> | undefined {
    return safeSnapshotToWithRef(docSnapshot);
  }

  static isSameEvent(aEvent?: IEvent, bEvent?: IEvent): boolean {
    if (!aEvent && !bEvent) {
      return true;
    }
    if (!aEvent || !bEvent) {
      return false;
    }
    return aEvent && bEvent ? isSameEvent(aEvent, bEvent) : aEvent === bEvent;
  }

  static async getTargets(event?: IEvent): Promise<IScheduleSummaryTarget[]> {
    if (!event) {
      return [];
    }
    const eventPeriod = ScheduleSummaryHelpers.getEventPeriod(event);
    const timezone = await TimezoneResolver.fromPracticeRef(event.practice.ref);
    const days = ScheduleSummaryHelpers.getDaysInPeriod(timezone, eventPeriod);
    const staff = CalendarEvent.participantsByType<IStaffer>(
      event,
      ParticipantType.Staffer
    ).map((staffer) => staffer.ref);

    return flatten(
      days.map((day) => staff.map((staffer) => ({ staffer, day })))
    ).map(({ staffer, day }) => ({
      practice: event.practice.ref,
      staffer,
      day,
    }));
  }

  static getEventPeriod(event?: IEvent): ITimePeriod | undefined {
    return event ? toTimePeriod(event.from, event.to) : undefined;
  }

  static async findScheduleSummary(
    target: IScheduleSummaryTarget,
    transaction?: Transaction
  ): Promise<WithRef<IScheduleSummary> | undefined> {
    const query = toQuery(
      this.getCollection(target),
      where('staffer', '==', target.staffer),
      where('practice', '==', target.practice),
      where('day', '==', target.day),
      limit(1)
    );

    const docs = transaction
      ? await FirestoreTransactionHelper.getDocs(query, transaction)
      : await Firestore.getDocs(query);

    return first(docs);
  }

  static getCollection(
    target: IScheduleSummaryTarget
  ): CollectionReference<IScheduleSummary> {
    return Practice.scheduleSummaryCol({ ref: target.practice });
  }

  static getDayPeriod(day: ISODateType, timezone: Timezone): ITimePeriod {
    return {
      from: toMomentTz(day, timezone).startOf('day'),
      to: toMomentTz(day, timezone).endOf('day'),
    };
  }

  static uniqueTargets(
    items: IScheduleSummaryTarget[]
  ): IScheduleSummaryTarget[] {
    return uniqBy(
      items,
      (item) => `${item.staffer.path}-${item.practice.path}-${item.day}`
    );
  }

  static async isSummaryOnDay(
    target: IScheduleSummaryTarget,
    summary?: IScheduleSummaryEvent
  ): Promise<boolean> {
    if (!summary) {
      return false;
    }
    return ScheduleSummaryHelpers.getDaysInPeriod(
      await TimezoneResolver.fromPracticeRef(target.practice),
      toTimePeriod(summary.event.from, summary.event.to)
    ).includes(target.day);
  }

  static async getStafferRosterSchedules(
    stafferRef: DocumentReference<IStaffer>,
    practiceRef: DocumentReference<IPractice>,
    transaction: Transaction
  ): Promise<WithRef<ICalendarEventSchedule>[]> {
    const schedules = await FirestoreTransactionHelper.getDocs(
      undeletedQuery(Roster.col({ ref: stafferRef })),
      transaction
    );
    return schedules.filter((schedule) =>
      isSameRef(schedule.item.event.practice, practiceRef)
    );
  }

  static isSameEventable<T extends ICalendarEvent | IAppointment>(
    aEvent?: WithRef<T>,
    bEvent?: WithRef<T>
  ): boolean {
    return (
      ScheduleSummaryHelpers.isSameEvent(aEvent?.event, bEvent?.event) &&
      isSameRef(aEvent, bEvent)
    );
  }

  static async rebuildAggregate(
    day: moment.Moment,
    practice: WithRef<IPractice>,
    staffer: WithRef<IStaffer>,
    transaction: Transaction
  ): Promise<IScheduleSummaryUpsertAction> {
    const rosterSchedules = await Firestore.getDocs(
      undeletedQuery(Roster.col({ ref: staffer.ref }))
    );
    const schedulesByPractice = rosterSchedules.filter((schedule) =>
      isSameRef(schedule.item.event.practice, practice)
    );

    const date = toISODate(day);
    const dayRange = ScheduleSummaryHelpers.getDayPeriod(
      date,
      practice.settings.timezone
    );

    const existingRef = (
      await ScheduleSummaryHelpers.findScheduleSummary({
        staffer: staffer.ref,
        practice: practice.ref,
        day: date,
      })
    )?.ref;

    const existing = existingRef
      ? await Firestore.getDoc(existingRef, transaction)
      : undefined;

    const brandRef = Practice.brandDoc(practice);
    const eventables = await snapshot(
      getStafferEventables$<IAppointment | ICalendarEvent>(
        dayRange,
        [staffer],
        practice,
        { ref: brandRef }
      )
    );

    const events = compact(
      await asyncForEach(eventables, async (eventable) => {
        if (!eventable.ref) {
          return;
        }
        const isBlockingEvent =
          isAppointment(eventable) ||
          (isCalendarEvent(eventable) && eventable.isBlocking);
        return ScheduleSummary.toSummaryEvent(
          eventable.ref,
          eventable,
          isBlockingEvent
        );
      })
    ) as IScheduleSummaryEvent[];

    const gaps = await ScheduleSummaryHelpers.getGapTimes(
      { staffer: staffer.ref, practice: practice.ref, day: toISODate(day) },
      schedulesByPractice,
      events,
      practice.settings.timezone
    );

    const target: IScheduleSummaryTarget = {
      staffer: staffer.ref,
      practice: practice.ref,
      day: date,
    };

    return {
      target,
      data: {
        events,
        gaps,
        ...target,
      },
      existing,
    };
  }

  static async getScheduledEvents(
    rosterSchedules: WithRef<ICalendarEventSchedule>[],
    range: ITimePeriod,
    timezone: Timezone,
    filterPeriods?: ITimePeriod[]
  ): Promise<IScheduleSummaryEvent<ICalendarEventSchedule>[]> {
    const eventsPerSchedule = await asyncForEach(
      rosterSchedules.filter(({ item }) =>
        ROSTER_SCHEDULE_EVENT_TYPES.includes(item.event.type)
      ),
      (schedule) => {
        const events = CalendarEventSchedule.buildEventsWithTimezone(
          schedule,
          range,
          filterPeriods,
          timezone
        );
        return asyncForEach(events, (event) =>
          ScheduleSummary.toSummaryEvent<ICalendarEventSchedule>(
            schedule.ref,
            event,
            event.isBlocking
          )
        );
      }
    );
    return compact(flatten(eventsPerSchedule));
  }

  static async getBlockingScheduledEvents<T extends object>(
    rosterSchedules: WithRef<ICalendarEventSchedule>[],
    range: ITimePeriod,
    timezone: Timezone,
    events: IScheduleSummaryEvent<T>[]
  ): Promise<IScheduleSummaryEvent<ICalendarEventSchedule>[]> {
    const blockingEvents = events
      .filter((summary) => summary.isBlocking)
      .map((summary) => toTimePeriod(summary.event.from, summary.event.to));

    return ScheduleSummaryHelpers.getScheduledEvents(
      rosterSchedules,
      range,
      timezone,
      blockingEvents
    );
  }

  static async getGapTimes<T extends object>(
    target: IScheduleSummaryTarget,
    rosterSchedules: WithRef<ICalendarEventSchedule>[],
    events: IScheduleSummaryEvent<T>[],
    practiceTimezone?: Timezone
  ): Promise<ITimestampRange[]> {
    if (!rosterSchedules.length) {
      return [];
    }

    const timezone =
      practiceTimezone ??
      (await TimezoneResolver.fromPracticeRef(target.practice));

    const dayRange = ScheduleSummaryHelpers.getDayPeriod(target.day, timezone);

    const rosterTimes = Roster.getScheduleTimes(
      rosterSchedules.filter(
        (schedule) => schedule.item.event.type === EventType.RosteredOn
      ),
      dayRange,
      timezone
    );

    if (!rosterTimes.length) {
      return [];
    }

    const scheduledBreaks =
      await ScheduleSummaryHelpers.getBlockingScheduledEvents(
        rosterSchedules.filter(
          (schedule) => schedule.item.event.type === EventType.Break
        ),
        dayRange,
        timezone,
        events
      );

    const availableTimes = ScheduleSummaryHelpers.getAvailableTimes(
      dayRange,
      rosterTimes,
      events.map((event) => event.event)
    );

    const usedTimes = [...events, ...scheduledBreaks]
      .filter((summary) => summary.isBlocking)
      .map((summary) => toTimePeriod(summary.event.from, summary.event.to));

    return new TimeBucket(availableTimes)
      .mergeOverlapping()
      .subtract(usedTimes)
      .get()
      .map(toTimestampRange);
  }

  static getAvailableTimes(
    dayRange: ITimePeriod,
    rosterTimes: ITimePeriod[],
    events: IEvent[]
  ): ITimePeriod[] {
    const rosteredOff = events
      .filter((event) => ROSTERED_OFF_EVENT_TYPES.includes(event.type))
      .map((event) => toTimePeriod(event.from, event.to));

    const rosteredOn = events
      .filter((event) => event.type === EventType.RosteredOn)
      .map((event) => toTimePeriod(event.from, event.to));

    return new TimeBucket([...rosterTimes, ...rosteredOn])
      .mergeOverlapping()
      .trim(dayRange.from, dayRange.to)
      .subtract(rosteredOff)
      .get();
  }
}
