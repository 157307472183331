export enum BigQueryTable {
  // Dimensions
  AccountCredits = 'account_credits',
  Appointments = 'appointments',
  Brands = 'brands',
  Contacts = 'contacts',
  Invoices = 'invoices',
  LabJobs = 'lab_jobs',
  Labs = 'labs',
  Patients = 'patients',
  PatientInteractions = 'patient_interactions',
  PaymentPlans = 'payment_plans',
  Practices = 'practices',
  RecurringTasks = 'recurring_tasks',
  Staff = 'staff',
  Tasks = 'tasks',
  Teams = 'teams',
  Transactions = 'transactions',
  TreatmentPlans = 'treatment_plans',
  TreatmentSteps = 'treatment_steps',
  ScheduleSummary = 'schedule_summary',
  SchedulingEvents = 'scheduling_events',
  SchedulingEventReasons = 'scheduling_event_reasons',
  // Facts
  AccountCreditEvent = 'account_credit_event',
  AppointmentEvent = 'appointment_event',
  ScheduleSummaryEvent = 'schedule_summary_event',
  SchedulingEventFact = 'scheduling_event_fact',
  GapEvent = 'gap_event',
  GapFilled = 'gap_filled',
  InvoiceEvent = 'invoice_event',
  TransactionEvent = 'transaction_event',
  LabJobEvent = 'lab_job_event',
  LabJobReceived = 'lab_job_received',
  PatientEvent = 'patient_event',
  PatientInteractionEvent = 'patient_interaction_event',
  PaymentPlanEvent = 'payment_plant_event',
  TaskCompleted = 'task_completed',
  TaskReopened = 'task_reopened',
  TaskEvent = 'task_event',
  TreatmentPlanCompleted = 'treatment_plan_completed',
  TreatmentPlanEvent = 'treatment_plan_event',
  TreatmentStepEvent = 'treatment_step_event',
}
