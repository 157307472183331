import { ToothNumberConversion } from '@principle-theorem/principle-core';
import {
  IExpectedSourceRecordSize,
  IPracticeMigration,
  SourceEntityMigrationType,
  ToothNumber,
  isToothNumber,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  TypeGuard,
  WithRef,
  toFloat,
  toTimestamp,
  type Timezone,
  ISO_DATE_TIME_FORMAT,
} from '@principle-theorem/shared';
import { flow, groupBy, isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import {
  D4WToothSurface,
  convertToToothSurfaces,
} from '../../../d4w/source/entities/patient-treatment';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { runQuery } from '../../../source/connection';
import { SourceEntity } from '../../../source/source-entity';
import {
  OFFSET_PLACEHOLDER,
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  convertValueFn,
} from '../../../source/source-helpers';

export const PATIENT_TREATMENT_RESOURCE_TYPE = 'patientTreatments';

export const PATIENT_TREATMENT_SOURCE_ENTITY: ISourceEntity = SourceEntity.init(
  {
    metadata: {
      label: 'Patient Treatment List',
      description: '',
      idPrefix: PATIENT_TREATMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  }
);

export interface ICorePracticePatientTreatment {
  id: number;
  completeDate?: string; // 2023-03-21 04:53:14.293
  tooth?: ToothNumber; // "27-34" | "27" | "1" | "2" | "3" | "4"
  surface: D4WToothSurface[]; // "La"
  quantity: number;
  fee: number; // 60.0000
  // This is always set when completedDate is empty
  planDate?: string; // 2023-03-21 04:32:36.890
  planQuantity: number;
  planFee: number; // 60.0000
  planVisit?: number; // Visit number
  estimate?: number;
  isPaid: boolean;
  isVoided: boolean;
  isDeleted: boolean;
  patientId: number;
  accountId?: number;
  chartId?: number;
  providerId: number;
  locationId: number;
  itemId: number;
  itemCode: string;
  itemCodeName: string;
}

export interface ICorePracticePatientTreatmentQuery
  extends Omit<ICorePracticePatientTreatment, 'surface'> {
  surface?: string;
}

export function isCorePracticePatientTreatment(
  item: unknown
): item is ICorePracticePatientTreatment {
  return TypeGuard.interface<ICorePracticePatientTreatment>({
    id: isNumber,
    completeDate: TypeGuard.nilOr(isString),
    tooth: TypeGuard.nilOr(isToothNumber),
    surface: TypeGuard.arrayOf(TypeGuard.enumValue(D4WToothSurface)),
    quantity: isNumber,
    fee: isNumber,
    planDate: TypeGuard.nilOr(isString),
    planQuantity: isNumber,
    planFee: isNumber,
    planVisit: TypeGuard.nilOr(isNumber),
    estimate: TypeGuard.nilOr(isNumber),
    isPaid: isBoolean,
    isVoided: isBoolean,
    isDeleted: isBoolean,
    patientId: isNumber,
    accountId: TypeGuard.nilOr(isNumber),
    chartId: TypeGuard.nilOr(isNumber),
    providerId: isNumber,
    locationId: isNumber,
    itemId: isNumber,
    itemCode: isString,
    itemCodeName: isString,
  })(item);
}

export interface ICorePracticePatientTreatmentTranslations {
  isFlagged: boolean;
  isCompleted: boolean;
  isPending: boolean;
  completeDate?: Timestamp;
  planDate?: Timestamp;
}

export interface ICorePracticePatientTreatmentFilters {
  patientId: number;
  accountId?: number;
  chartId?: number;
  providerId: number;
  locationId: number;
  itemId: number;
}

const PATIENT_TREATMENT_SOURCE_QUERY = `
SELECT
	treatment.*,
	item_code.item_code,
	item_code.name AS item_code_name
FROM (
  SELECT
    TreatmentId AS id,
    CompleteDate AS complete_date,
    NULLIF(convert_to_text(Tooth), '') AS tooth,
    Surface AS surface,
    Quantity AS quantity,
    convert_to_decimal(Fee) AS fee,
    PlanDate AS plan_date,
    PlanQuantity AS plan_quantity,
    convert_to_decimal(PlanFee) AS plan_fee,
    PlanVisit AS plan_visit,
    convert_to_decimal(Estimate) AS estimate,
    convert_to_boolean(IsPaid) AS is_paid,
    convert_to_boolean(IsVoided) AS is_voided,
    convert_to_boolean(IsDeleted) AS is_deleted,
    PatientId AS patient_id,
    AccountId AS account_id,
    ChartId AS chart_id,
    ProviderId AS provider_id,
    LocationId AS location_id,
    ItemId AS item_id
  FROM tblTreatment
  ORDER BY TreatmentId
  ${OFFSET_PLACEHOLDER}
) AS treatment
LEFT JOIN (
  SELECT
    ItemId AS id,
    ItemCode AS item_code,
    Name AS name
  FROM tblItem
) AS item_code
ON item_code.id = treatment.item_id
`;

const PATIENT_TREATMENT_ESTIMATE_QUERY = `SELECT
  treatment.id
FROM (
  SELECT
    TreatmentId AS id,
    ItemId AS item_id
  FROM tblTreatment
  WHERE
    convert_to_boolean(IsDeleted) = false AND
    convert_to_boolean(IsVoided) = false
) AS treatment
INNER JOIN (
  SELECT
    ItemId AS id
    FROM tblItem
) AS item_code
ON item_code.id = treatment.item_id
`;

export class PatientTreatmentSourceEntity extends BaseSourceEntity<
  ICorePracticePatientTreatment,
  ICorePracticePatientTreatmentTranslations,
  ICorePracticePatientTreatmentFilters
> {
  sourceEntity = PATIENT_TREATMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_TREATMENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_TREATMENT_SOURCE_QUERY;
  allowOffsetJob = true;
  verifySourceFn = isCorePracticePatientTreatment;
  override defaultOffsetSize = 50000;
  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertValueFn(toFloat, 'fee', 'planFee', 'estimate'),
    transformPatientTreatmentResults,
    convertValueFn(
      (value) => ToothNumberConversion.convertToISONotation(String(value)),
      'tooth'
    ),
  ]);

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const response = await runQuery<{ id: number }>(
      migration,
      PATIENT_TREATMENT_ESTIMATE_QUERY
    );

    const expectedSize = Object.values(
      groupBy(response.rows, (item) => `${item.id}`)
    ).length;

    return {
      expectedSize,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }

  translate(
    data: ICorePracticePatientTreatment,
    _timezone: Timezone
  ): ICorePracticePatientTreatmentTranslations {
    return {
      isFlagged: !data.completeDate && !data.planVisit,
      isPending: !data.completeDate && !!data.planVisit,
      isCompleted: !!data.completeDate,
      completeDate: data.completeDate
        ? toTimestamp(moment.utc(data.completeDate, ISO_DATE_TIME_FORMAT))
        : undefined,
      planDate: data.planDate
        ? toTimestamp(moment.utc(data.planDate, ISO_DATE_TIME_FORMAT))
        : undefined,
    };
  }

  getSourceRecordId(data: ICorePracticePatientTreatment): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientTreatment): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientTreatment,
    _timezone: Timezone
  ): ICorePracticePatientTreatmentFilters {
    return {
      patientId: data.patientId,
      accountId: data.accountId,
      chartId: data.chartId,
      providerId: data.providerId,
      locationId: data.locationId,
      itemId: data.itemId,
    };
  }
}

function transformPatientTreatmentResults(
  rows: ICorePracticePatientTreatmentQuery[]
): ICorePracticePatientTreatment[] {
  return rows.map((row) => {
    try {
      if (row.surface) {
        return {
          ...row,
          surface: convertToToothSurfaces(row.surface),
        };
      }
    } catch (error) {
      //
    }
    return {
      ...row,
      surface: [],
    };
  });
}
