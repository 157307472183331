import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  ViewChild,
} from '@angular/core';
import { Storage } from '@angular/fire/storage';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CurrentPatientScope,
  StafferSettingsStoreService,
} from '@principle-theorem/ng-principle-shared';
import {
  BasicDialogService,
  FileDisplayType,
  IMediaManagerUpdateEvent,
  MediaManager,
  confirmationDialogData,
  getContentType$,
  getDownloadURL$,
  getFileDisplayType,
} from '@principle-theorem/ng-shared';
import { Media, Patient } from '@principle-theorem/principle-core';
import { type IMedia } from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  addDoc,
  filterUndefined,
  isSameRef,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { isUndefined } from 'lodash';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  type Observable,
} from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { FilePreviewComponent } from '../file-preview/file-preview.component';
import { isDicomFile } from '../media-manager/media-manager.component';

const TYPES_WITH_LOADERS = [FileDisplayType.IFrame, FileDisplayType.DocViewer];

@Component({
    selector: 'pr-edit-media-dialog',
    templateUrl: './edit-media-dialog.component.html',
    styleUrls: ['./edit-media-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditMediaDialogComponent {
  media$ = new ReplaySubject<WithRef<IMedia>>(1);
  mediaUpdated$ = new BehaviorSubject<boolean>(false);
  orderedMedia: WithRef<IMedia>[];
  fileUrl$: Observable<string>;
  contentType$: Observable<string | undefined>;
  saving$ = new BehaviorSubject<boolean>(false);
  isDicom$: Observable<boolean>;
  loaded$ = new BehaviorSubject<boolean>(false);
  hasEdits$ = new BehaviorSubject<boolean>(false);
  editMode$ = new ReplaySubject<boolean>(1);
  displayType$: Observable<FileDisplayType>;
  saveDisabled$: Observable<boolean>;
  fileName$: Observable<string>;

  @ViewChild('preview') preview: FilePreviewComponent;

  constructor(
    public dialogRef: MatDialogRef<
      EditMediaDialogComponent,
      IMediaManagerUpdateEvent | undefined
    >,
    @Inject(MAT_DIALOG_DATA) data: IEditMediaDialogData,
    private _storage: Storage,
    private _patientScope: CurrentPatientScope,
    private _settingsStore: StafferSettingsStoreService,
    private _basicDialog: BasicDialogService
  ) {
    this.fileUrl$ = this.media$.pipe(
      switchMap((media) => getDownloadURL$(this._storage, media))
    );
    this.contentType$ = this.media$.pipe(
      switchMap((media) => getContentType$(this._storage, media))
    );
    this.media$.next(data.media);
    this.orderedMedia = data.orderedMedia;
    this.editMode$.next(data.editMode ?? true);
    this.fileName$ = this.media$.pipe(map((media) => media.name));
    this.isDicom$ = this.media$.pipe(map((media) => isDicomFile(media.path)));
    this.displayType$ = this.contentType$.pipe(
      filterUndefined(),
      map((contentType) => getFileDisplayType(contentType)),
      tap((displayType) => {
        if (!TYPES_WITH_LOADERS.includes(displayType)) {
          this.loaded$.next(true);
        }
      })
    );
    this.saveDisabled$ = combineLatest([
      this.saving$,
      this.hasEdits$,
      this.mediaUpdated$,
    ]).pipe(
      map(
        ([saving, hasEdits, mediaUpdated]) =>
          saving || !(hasEdits || mediaUpdated)
      )
    );
  }

  @HostListener('window:keyup', ['$event'])
  async keyEvent(event: KeyboardEvent): Promise<void> {
    if (event.key === 'ArrowRight') {
      await this.goToNextImage();
    }
    if (event.key === 'ArrowLeft') {
      await this.goToPreviousImage();
    }
  }

  async save(): Promise<void> {
    this.saving$.next(true);

    const media = await snapshot(this.media$);
    const copyFileName = await this._saveAsCopyPrompt(media.name);
    const patient = await snapshot(
      this._patientScope.doc$.pipe(filterUndefined())
    );
    const manager = new MediaManager(
      this._storage,
      Patient.storagePath(patient)
    );
    const imageEdited = await snapshot(this.hasEdits$);
    const isDicom = await snapshot(this.isDicom$);
    const file =
      isDicom || !imageEdited ? undefined : await this.preview.saveImage();

    if (copyFileName) {
      const fileToCopy = file ?? (await this.preview.saveImage());
      const event: IMediaManagerUpdateEvent = { file: fileToCopy, media };
      const copy = await manager.saveAsCopy(event, copyFileName);
      if (!copy) {
        return;
      }
      const mediaRef = await addDoc(
        Patient.mediaCol(patient),
        Media.init({
          ...media,
          ...copy,
        })
      );
      const copiedMedia = await Firestore.getDoc(mediaRef);
      this.hasEdits$.next(false);
      this.mediaUpdated$.next(false);
      this.saving$.next(false);
      this.media$.next(copiedMedia);
      return;
    }

    await manager.updateMedia({ media, file });
    this.hasEdits$.next(false);
    this.mediaUpdated$.next(false);
    this.saving$.next(false);
  }

  async toggleEditMode(): Promise<void> {
    const editMode = await snapshot(this.editMode$);
    this.editMode$.next(!editMode);
    this._settingsStore.updateStafferSettings({
      patientMedia: {
        imageEditMode: !editMode,
      },
    });
  }

  async onClose(): Promise<void> {
    const unsavedConfirmation = await this._getUnsavedConfirmation();
    if (!unsavedConfirmation) {
      return;
    }
    this.dialogRef.close();
  }

  async goToNextImage(): Promise<void> {
    const unsavedConfirmation = await this._getUnsavedConfirmation();
    if (!unsavedConfirmation) {
      return;
    }
    const index = await this._getCurrentIndex();
    if (isUndefined(index)) {
      return;
    }
    if (index === this.orderedMedia.length - 1) {
      this.media$.next(this.orderedMedia[0]);
      return;
    }
    this.media$.next(this.orderedMedia[index + 1]);
  }

  async goToPreviousImage(): Promise<void> {
    const unsavedConfirmation = await this._getUnsavedConfirmation();
    if (!unsavedConfirmation) {
      return;
    }
    const index = await this._getCurrentIndex();
    if (isUndefined(index)) {
      return;
    }
    if (index === 0) {
      this.media$.next(this.orderedMedia[this.orderedMedia.length - 1]);
      return;
    }
    this.media$.next(this.orderedMedia[index - 1]);
  }

  private async _getCurrentIndex(): Promise<number | undefined> {
    const media = await snapshot(this.media$);
    const index = this.orderedMedia.findIndex((orderedMedia) =>
      isSameRef(orderedMedia, media)
    );
    return index === -1 ? undefined : index;
  }

  private async _saveAsCopyPrompt(
    originalFileName: string
  ): Promise<string | undefined> {
    const isDicom = await snapshot(this.isDicom$);
    if (isDicom) {
      return;
    }

    const confirmed = await this._basicDialog.confirm({
      title: 'Save As Copy',
      prompt: [
        'Would you like to save this image as a new copy?',
        'The original image will not be overwritten.',
      ],
      submitLabel: 'Save as Copy',
      submitColor: 'primary',
      cancelLabel: 'Overwrite Original',
    });
    if (!confirmed) {
      return;
    }

    const fileName = await this._basicDialog.prompt({
      prompt: 'New file name',
      title: 'Save As Copy',
      submitLabel: 'Save Copy',
      defaultValue: `Copy of ${originalFileName}`,
    });
    return fileName;
  }

  private async _getUnsavedConfirmation(): Promise<boolean | undefined> {
    const isDicom = await snapshot(this.isDicom$);
    if (!this.hasEdits$.value || isDicom) {
      return true;
    }

    const data = confirmationDialogData({
      title: 'Unsaved Changes',
      prompt: 'You have unsaved changes. Are you sure you want to continue?',
      submitLabel: 'Continue',
    });
    return this._basicDialog.confirm(data);
  }
}

export interface IEditMediaDialogData {
  media: WithRef<IMedia>;
  orderedMedia: WithRef<IMedia>[];
  editMode: boolean;
}
