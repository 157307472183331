import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type IRole } from '@principle-theorem/principle-core/interfaces';
import { TypedFormGroup, TypedFormControl } from '@principle-theorem/ng-shared';
import { Validators } from '@angular/forms';
import { initFirestoreModel } from '@principle-theorem/shared';

type IRoleFormData = Pick<IRole, 'name'>;

@Component({
  selector: 'pr-create-role-dialog',
  templateUrl: './create-role-dialog.component.html',
  styleUrls: ['./create-role-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateRoleDialogComponent {
  roleForm: TypedFormGroup<IRoleFormData> = new TypedFormGroup<IRoleFormData>({
    name: new TypedFormControl<string>('', [Validators.required]),
  });

  constructor(
    private _dialogRef: MatDialogRef<CreateRoleDialogComponent, IRole>,
    @Inject(MAT_DIALOG_DATA) data: Partial<IRole>
  ) {
    this.roleForm.patchValue(data);
  }

  submit(): void {
    this._dialogRef.close({
      permissions: [],
      ...initFirestoreModel(),
      ...this.roleForm.value,
    });
  }
}
