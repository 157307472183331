<ng-container *ngIf="groups$ | async as groups">
  <div class="items-container flex flex-col items-stretch gap-2">
    <ng-container *ngFor="let group of groups; trackBy: trackByGroup">
      <pr-service-item-exclusive-group-item
        *ngIf="group | map : isExclusive"
        [group]="group"
        [chartedSurfaces]="chartedSurfaces"
        [disabled]="disabled"
        [compact]="compact"
        (groupChange)="groupUpdate(groups, group, $event)"
      >
        <button
          *ngIf="!disabled"
          mat-icon-button
          class="delete icon-sm opacity-50"
          color="warn"
          (click)="delete(groups, group)"
          matTooltip="Delete Smart Group"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </pr-service-item-exclusive-group-item>
    </ng-container>
  </div>
</ng-container>
