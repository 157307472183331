import { NgModule } from '@angular/core';
import { ComponentsModule } from './components/components.module';
import { OfferAppointmentGapActionService } from './offer-appointment-gap-action.service';

@NgModule({
  imports: [ComponentsModule],
  exports: [ComponentsModule],
  providers: [OfferAppointmentGapActionService],
})
export class NgGapsModule {}
