import { InlineNodes } from '@principle-theorem/editor';
import { ISelectMenuData } from '../../menu-bar/select-menu/select-menu-view';
import { LinkHoverMenuComponent } from './link-hover-menu/link-hover-menu.component';

export function createLinkMenu(): ISelectMenuData<LinkHoverMenuComponent> {
  return {
    repositionDialogOnSelectionChange: true,
    shouldShow: (props) => props.editor.isActive(InlineNodes.Link),
    pluginKey: `${InlineNodes.Link}-select-menu`,
    nodeType: InlineNodes.Link,
    component: LinkHoverMenuComponent,
    data: {},
  };
}
