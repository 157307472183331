import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { BigQueryJobSelectorComponent } from './big-query-job-selector/big-query-job-selector.component';

@NgModule({
  declarations: [BigQueryJobSelectorComponent],
  imports: [NgSharedModule, ReactiveFormsModule],
  exports: [BigQueryJobSelectorComponent],
})
export class ManagementJobsModule {}
