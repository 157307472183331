<pr-page-splash>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="mat-headline-6">Unauthorised</h1>
    <button mat-stroked-button (click)="logout()">
      Return to Login
      <mat-icon>exit_to_app</mat-icon>
    </button>
  </div>
  <img
    src="https://thumbs.gfycat.com/HilariousDigitalAkitainu-size_restricted.gif"
  />
</pr-page-splash>
