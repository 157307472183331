<form [formGroup]="medicareForm">
  <div fxLayout="column">
    <mat-form-field fxFlex>
      <mat-label>Patient Card Number</mat-label>
      <input
        matInput
        formControlName="number"
        [required]="true"
        maxlength="10"
      />
      <mat-hint align="end">
        Medicare Card Number of the person submitting the claim - Max
        {{ medicareForm.value.number?.length || 0 }}/10
      </mat-hint>
      <mat-error *ngIf="medicareForm.controls.number.hasError('pattern')">
        Must contain only numbers
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>Patient IRN</mat-label>
      <input
        matInput
        formControlName="subNumerate"
        [required]="true"
        maxlength="2"
      />
      <mat-hint align="end">
        The IRN appears to the left of the patient's name on their Medicare card
        - Max {{ medicareForm.value.subNumerate?.length || 0 }}/2
      </mat-hint>
      <mat-error *ngIf="medicareForm.controls.subNumerate.hasError('pattern')">
        Must contain only numbers
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Expiry Date</mat-label>
      <input
        matInput
        formControlName="expiryDate"
        [matDatepicker]="expiryDatepicker"
        [min]="startOfMonth"
        (click)="expiryDatepicker.open()"
      />
      <mat-datepicker-toggle matIconSuffix [for]="expiryDatepicker" />
      <mat-datepicker
        #expiryDatepicker
        startView="multi-year"
        (monthSelected)="chosenDateHandler($event, expiryDatepicker)"
        panelClass="month-picker"
      />
      <mat-hint>Month and Year</mat-hint>
    </mat-form-field>
  </div>
</form>
