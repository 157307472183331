import {
  AssetType,
  IConsumableAsset,
  IEquipmentAsset,
  IInstrumentAsset,
} from '@principle-theorem/principle-core/interfaces';
import { initFirestoreModel } from '@principle-theorem/shared';

function MockInstrumentAsset(name: string): IInstrumentAsset {
  return {
    name,
    type: AssetType.Instrument,
    count: 1,
    ...initFirestoreModel(),
  };
}

function MockEquipmentAsset(name: string): IEquipmentAsset {
  return {
    name,
    type: AssetType.Equipment,
    count: 1,
    ...initFirestoreModel(),
  };
}

function MockConsumableAsset(name: string): IConsumableAsset {
  return {
    name,
    type: AssetType.Consumable,
    count: 1,
    ...initFirestoreModel(),
  };
}

export const INSTRUMENTS: IInstrumentAsset[] = [
  'High speed drill',
  'Low speed drill',
  'Retractor',
  'Scalpel',
  'Mandibular block',
  'Short syringe',
].map((name: string) => MockInstrumentAsset(name));

export const EQUIPMENT: IEquipmentAsset[] = [
  'Ceric Machine',
  'X-ray Machine',
  'Trios scanner',
].map((name: string) => MockEquipmentAsset(name));

export const CONSUMABLES: IConsumableAsset[] = [
  'Gloves',
  'Fluoride',
  'Disposable Syringe',
].map((name: string) => MockConsumableAsset(name));
