<div class="mx-auto my-4 flex max-w-5xl flex-col gap-4">
  @if (state.isLoading$ | async) {
    <div class="flex flex-col items-center justify-center">
      <mat-spinner />
      <p class="mat-caption">Loading Form</p>
    </div>
  }

  @if (state.isNotAvailable$ | async) {
    <div class="flex flex-col items-center justify-center">
      <pr-empty-state
        image="broken"
        title="form found"
        [descriptionEnabled]="false"
      />
    </div>
  }

  @if (state.isAlreadySubmitted$ | async) {
    <div class="flex flex-col items-center justify-center">
      <h1 class="mat-headline-4">Form already submitted</h1>
      <div>Thanks, we have already received your information.</div>
    </div>
  }

  @if (data$ | async; as data) {
    @if (state.isAwaitingConfirmation$ | async) {
      <div
        class="flex flex-col overflow-scroll rounded-md bg-white p-4 shadow-md"
      >
        <h2 class="!m-0 !font-bold">
          Patient Details: {{ data.tokenData?.patientName }}
        </h2>
        <pr-patient-details-form
          [healthCardTypes]="healthCardTypes$ | async"
          [referralSources]="referralSources$ | async"
          [requiredFields]="requiredFields$ | async"
          (patientDetailsChange)="patientDetailsFormData$.next($event)"
          class="layout-padding"
        />
      </div>

      <div
        class="flex flex-col overflow-scroll rounded-md bg-white p-4 shadow-md"
      >
        <h2 class="!m-0 !font-bold">
          Medical History: {{ data.tokenData?.patientName }}
        </h2>
        <pr-custom-form
          [form]="data.customForm"
          [hideSubmit]="true"
          (isValid)="customFormIsValid$.next($event)"
          (formChanges)="customFormState$.next($event)"
        />
        <div class="flex">
          <button
            mat-flat-button
            color="primary"
            [disabled]="isDisabled$ | async"
            (click)="submit()"
          >
            Submit
          </button>
        </div>
      </div>
    }
  }

  @if (state.isSubmitting$ | async) {
    <div class="flex flex-col items-center justify-center">
      <mat-spinner />
      <p class="mat-body-2">Saving...</p>
    </div>
  }

  @if (state.isConfirmed$ | async) {
    <div class="flex flex-col items-center justify-center">
      <h1 class="mat-headline-4">Save Successful!</h1>
      <div>Thank you</div>
    </div>
  }
</div>
