import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import {
  type INgSharedConfig,
  NG_SHARED_CONFIG,
} from '@principle-theorem/ng-shared';
import { type IAppointmentRequest } from '@principle-theorem/principle-core/interfaces';
import { Practice } from '@principle-theorem/principle-core';
import { filterUndefined, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

@Component({
  selector: 'pr-appointment-requests',
  templateUrl: './appointment-requests.component.html',
  styleUrls: ['./appointment-requests.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentRequestsComponent {
  private _sharedConfig: INgSharedConfig = inject(NG_SHARED_CONFIG);
  appointmentRequests$: Observable<WithRef<IAppointmentRequest>[]>;
  onlineBookingLink$: Observable<string>;

  constructor(currentScopeFacade: CurrentScopeFacade) {
    this.appointmentRequests$ = currentScopeFacade.currentPractice$.pipe(
      filterUndefined(),
      concatMap((practice) => Practice.getAppointmentRequests$(practice))
    );

    this.onlineBookingLink$ = this.onlineBookingLink$ =
      currentScopeFacade.currentPractice$.pipe(
        filterUndefined(),
        map(
          (practice) =>
            `${this._sharedConfig.appUrl}/portal/booking/${practice.ref.id}`
        )
      );
  }
}
