import { AnyExtension } from '@tiptap/core';
import { HardBreak } from '@tiptap/extension-hard-break';
import { InlineNodes } from '../../available-extensions';

export function createHardBreakExtension(): AnyExtension {
  const hardBreak = HardBreak.configure();

  return hardBreak.extend({
    name: InlineNodes.HardBreak,
  });
}
