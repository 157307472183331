@if (templateList(); as templates) {
  <div class="grid grid-cols-2 gap-4">
    @for (pair of templates; track index; let index = $index) {
      <div>
        <h2>
          {{ pair.template | map: getTemplateName | splitCamel | titlecase }}
        </h2>
        <mat-form-field class="w-full">
          <mat-label>
            Select
            {{ pair.template | map: getTemplateName | splitCamel | titlecase }}
          </mat-label>
          <mat-select
            #selectRef
            [compareWith]="compareFn"
            (selectionChange)="templateChanges()"
          >
            <mat-option [value]="undefined">None</mat-option>
            @if (pair.templateDoc.content | map: templateHasContent) {
              <mat-option
                [value]="{
                  template: pair.template,
                  content: pair.templateDoc.content
                }"
              >
                {{
                  pair.template | map: getTemplateName | splitCamel | titlecase
                }}
              </mat-option>
            }
            @if (pair.templateDoc.variants?.length) {
              @for (
                variant of pair.templateDoc.variants;
                track variant.uid;
                let index = $index
              ) {
                @if (variant.content | map: templateHasContent) {
                  <mat-option
                    [value]="{
                      template: pair.template,
                      content: variant.content
                    }"
                  >
                    {{ variant | pairMap: index : getVariantName : this }}
                  </mat-option>
                }
              }
            }
          </mat-select>
          @if (pair.template | map: getTemplateHint; as templateHint) {
            <mat-hint> This is displayed {{ templateHint }} </mat-hint>
          }
        </mat-form-field>
      </div>
    }
  </div>
}
