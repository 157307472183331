import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { SelectionListStore } from '@principle-theorem/ng-shared';
import {
  ChatStatus,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  filterUndefined,
  isSameRef,
  multiMap,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  type Observable,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  ChatFilter,
  ChatsDashboardStore,
  type IChatSummary,
} from '../../pages/chats-dashboard/chats-dashboard.store';

@Component({
  selector: 'pr-chat-multi-action-toolbar',
  templateUrl: './chat-multi-action-toolbar.component.html',
  styleUrls: ['./chat-multi-action-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMultiActionToolbarComponent implements OnDestroy {
  private _selectedSummaries$ = new ReplaySubject<IChatSummary[]>(1);
  private _onDestroy$ = new Subject<void>();
  readonly _staffer$: Observable<WithRef<IStaffer>>;
  readonly _practice$: Observable<WithRef<IPractice>>;
  staff$: Observable<WithRef<IStaffer>[]>;
  canReopen$: Observable<boolean>;
  canMarkAsUnread$ = new BehaviorSubject<boolean>(false);

  @Input() selectionList: SelectionListStore<IChatSummary>;
  @Output() closeToolbar = new EventEmitter<void>();

  @Input()
  set selected(selected: IChatSummary[]) {
    if (selected) {
      this._selectedSummaries$.next(selected);
    }
  }

  constructor(
    private _chatFacade: ChatsDashboardStore,
    private _organisation: OrganisationService,
    private _snackBar: MatSnackBar
  ) {
    this._staffer$ = this._organisation.staffer$.pipe(filterUndefined());
    this._practice$ = this._organisation.practice$.pipe(filterUndefined());
    this.staff$ = this._organisation.staff$;

    this.canReopen$ = this._chatFacade.filterBy$.pipe(
      map((filterBy) => filterBy === ChatFilter.Archived)
    );

    combineLatest([
      this._selectedSummaries$.pipe(
        multiMap((summaries) => summaries.lastMessage)
      ),
      this._staffer$,
    ])
      .pipe(
        map(([messages, staffer]) => {
          return compact(messages).every((message) =>
            message.readBy.some((readBy) => isSameRef(readBy, staffer))
          );
        })
      )
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((canMarkUnread) => this.canMarkAsUnread$.next(canMarkUnread));
  }

  @HostListener('document:keydown.escape')
  dismissSelected(): void {
    this.selectionList.resetSelected();
    this.closeToolbar.emit();
  }

  async archiveChats(): Promise<void> {
    const selectedSummaries = await snapshot(this._selectedSummaries$);
    await asyncForEach(selectedSummaries, (summary) =>
      patchDoc(summary.chat.ref, { status: ChatStatus.Archived })
    );
    this._snackBar
      .open('Chat(s) archived', 'Undo', { duration: 5000 })
      .onAction()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(
        () =>
          void asyncForEach(selectedSummaries, (summary) =>
            patchDoc(summary.chat.ref, {
              status: ChatStatus.Open,
            })
          )
      );
  }

  async openChats(): Promise<void> {
    const selectedSummaries = await snapshot(this._selectedSummaries$);
    await asyncForEach(selectedSummaries, (summary) =>
      patchDoc(summary.chat.ref, { status: ChatStatus.Open })
    );
    this._snackBar.open('Chat(s) marked as open');
  }

  async markAsUnread(): Promise<void> {
    const lastMessages = await snapshot(
      this._selectedSummaries$.pipe(multiMap((summary) => summary.lastMessage))
    );
    if (!lastMessages.length) {
      return;
    }
    const staffer = await snapshot(this._staffer$);

    await asyncForEach(compact(lastMessages), (message) =>
      patchDoc(message.ref, {
        readBy: message.readBy.filter((readBy) => !isSameRef(readBy, staffer)),
      })
    );
    this.canMarkAsUnread$.next(!this.canMarkAsUnread$.value);
    this._snackBar.open('Chat(s) marked as unread');
  }

  async markAsRead(): Promise<void> {
    const lastMessages = await snapshot(
      this._selectedSummaries$.pipe(multiMap((summary) => summary.lastMessage))
    );
    if (!lastMessages.length) {
      return;
    }
    const staffer = await snapshot(this._staffer$);
    await asyncForEach(compact(lastMessages), (message) =>
      patchDoc(message.ref, { readBy: [...message.readBy, staffer.ref] })
    );
    this.canMarkAsUnread$.next(!this.canMarkAsUnread$.value);
    this._snackBar.open('Chat(s) marked as read');
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
