import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
  inject,
} from '@angular/core';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import {
  OrganisationService,
  TimezoneService,
} from '@principle-theorem/ng-principle-shared';
import {
  ProfileImageService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  AppointmentSuggestion,
  buildEventTimePeriodFromEvent$,
  Event,
  TreatmentTemplate,
  type IAppointmentDetails,
  ANY_OPTION,
  type IDefaultOption,
  isDefaultOption,
} from '@principle-theorem/principle-core';
import {
  type IEvent,
  type IPatient,
  type IPractice,
  type IStaffer,
  isTreatmentTemplateWithStep,
  type IUser,
} from '@principle-theorem/principle-core/interfaces';
import {
  CASUAL_DATE_FORMAT,
  formatTimeFromTo,
  getDoc,
  type INamedDocument,
  isChanged$,
  isSameRef,
  MINIMUM_DURATION,
  snapshot,
  STEP_SIZE,
  timePeriodToHumanisedTime,
  TIME_FORMAT,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first, isEqual } from 'lodash';
import {
  combineLatest,
  type Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  map,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { AppointmentDetailsFormGroup } from '../appointment-details/appointment-details.formgroup';

@Component({
    selector: 'pr-appointment-details-sidebar',
    templateUrl: './appointment-details-sidebar.component.html',
    styleUrls: ['./appointment-details-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentDetailsSidebarComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  profileImage = inject(ProfileImageService);
  trackByPractice = TrackByFunctions.ref<WithRef<IPractice>>();
  trackByPractitioner = TrackByFunctions.ref<INamedDocument<IStaffer>>();
  trackByParticipant = TrackByFunctions.ref<WithRef<IUser>>();
  form: AppointmentDetailsFormGroup = new AppointmentDetailsFormGroup();
  errorMessage$: Observable<string> = this.form.errorMessage$();
  durationWarningMessage$: Observable<string>;
  minimumDuration: number = MINIMUM_DURATION;
  stepSize: number = STEP_SIZE;
  practices$: ReplaySubject<WithRef<IPractice>[]> = new ReplaySubject(1);
  practitioners$: ReplaySubject<INamedDocument<IStaffer>[]> = new ReplaySubject(
    1
  );
  anyPractice: IDefaultOption = ANY_OPTION;
  anyPractitioner: IDefaultOption = ANY_OPTION;
  durationPlaceholder$: Observable<string>;
  patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);
  filteredPractitioners$: Observable<INamedDocument<IStaffer>[]>;
  appointmentDetails$ = new ReplaySubject<Partial<IAppointmentDetails>>(1);
  selectedEvent$: Observable<IEvent | undefined>;
  selectedPractice$: Observable<WithRef<IPractice> | undefined>;
  selectedPractitioner$: Observable<INamedDocument<IStaffer> | IDefaultOption>;
  selectedDuration$: Observable<number>;
  participants$: Observable<WithRef<IUser>[]>;
  timeDisplay$: Observable<string | undefined>;
  score$: Observable<number | undefined>;

  readonly dateFormat = CASUAL_DATE_FORMAT;
  readonly timeFormat = TIME_FORMAT;

  @Output() showAppointmentOptions = new EventEmitter<void>();
  @Output()
  appointmentDetailsChange = new EventEmitter<Partial<IAppointmentDetails>>();

  @Input()
  set appointmentDetails(details: Partial<IAppointmentDetails>) {
    if (details) {
      this.appointmentDetails$.next(details);
    }
  }

  @Input()
  set practices(practices: WithRef<IPractice>[]) {
    if (practices) {
      this.practices$.next(practices);
      practices.length === 1
        ? this.form.controls.practice.disable({ emitEvent: false })
        : this.form.controls.practice.enable({ emitEvent: false });
    }
  }

  @Input()
  set practitioners(practitioners: INamedDocument<IStaffer>[]) {
    if (practitioners) {
      this.practitioners$.next(practitioners);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(
    private _appointmentSchedulingFacade: AppointmentSchedulingFacade,
    private _organisation: OrganisationService,
    private _timezone: TimezoneService
  ) {
    this.durationWarningMessage$ =
      this._appointmentSchedulingFacade.durationWarningMessage$;
    this.durationPlaceholder$ =
      this._appointmentSchedulingFacade.durationPlaceholder$.asObservable();
    this.selectedEvent$ = this._appointmentSchedulingFacade.selectedEvent$;
    this.selectedPractice$ =
      this._appointmentSchedulingFacade.selectedPractice$;
    this.selectedPractitioner$ =
      this._appointmentSchedulingFacade.selectedPractitioner$;
    this.selectedDuration$ =
      this._appointmentSchedulingFacade.selectedTreatmentDuration$;

    this.appointmentDetails$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((details) =>
        this.form.patchValue(details, { emitEvent: false })
      );

    this.filteredPractitioners$ = combineLatest([
      this.form.selectedTreatment$(),
      this.practitioners$,
    ]).pipe(
      map(([treatment, practitioners]) => {
        if (!isTreatmentTemplateWithStep(treatment)) {
          return practitioners;
        }
        return practitioners.filter((practitioner) =>
          TreatmentTemplate.hasImplementor(
            treatment.step.template,
            practitioner
          )
        );
      })
    );

    this._getPreferredPractitioner$()
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((practitioner) => {
        if (!isDefaultOption(this.form.controls.practitioner.value)) {
          return;
        }
        this.form.controls.practitioner.setValue(practitioner);
      });

    this._appointmentSchedulingFacade.selectedTreatmentDuration$
      .pipe(isChanged$(), takeUntil(this._onDestroy$))
      .subscribe((duration) =>
        this.appointmentDetailsChange.emit({
          duration,
        })
      );

    this.form.valueChanges
      .pipe(isChanged$(), takeUntil(this._onDestroy$))
      .subscribe(() =>
        this.appointmentDetailsChange.emit(this.form.getRawValue())
      );

    this.participants$ = combineLatest([
      this.selectedEvent$.pipe(
        map((event) => (event ? Event.staff(event) : []))
      ),
      this._organisation.stafferMap$,
      this._organisation.userMap$,
    ]).pipe(
      map(([participants, staff, users]) =>
        compact(
          participants
            .map((participant) => staff[participant.ref.path])
            .map((staffer) => users[staffer.user.ref.path])
        )
      )
    );

    this.timeDisplay$ = this.selectedEvent$.pipe(
      switchMap((event) =>
        event ? this._timezone.getEventRange$(event) : of(undefined)
      ),
      map((range) => {
        if (!range) {
          return;
        }

        const duration = timePeriodToHumanisedTime(range);
        return `${range.from.format(TIME_FORMAT)} - ${range.to.format(
          TIME_FORMAT
        )} (${duration})`;
      })
    );

    this.score$ = this.selectedEvent$.pipe(
      withLatestFrom(this._organisation.brand$),
      switchMap(async ([event, brand]) => {
        if (!event) {
          return undefined;
        }

        const range = {
          from: toMoment(event.from),
          to: toMoment(event.to),
        };

        const practice = event.practice;
        const staffParticipant = first(Event.staff(event));

        if (!brand || !practice || !staffParticipant) {
          return undefined;
        }

        const staffer = await getDoc<IStaffer>(staffParticipant.ref);

        const timePeriod = await snapshot(
          buildEventTimePeriodFromEvent$(brand, practice, staffer, range)
        );

        const suggestion =
          AppointmentSuggestion.fromEventTimePeriod(timePeriod);
        return AppointmentSuggestion.getMatchScore(
          suggestion,
          event,
          timePeriod,
          {
            duration: true,
            overlap: true,
          }
        );
      })
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  summariseTime(event: IEvent): string {
    return formatTimeFromTo(toMoment(event.from), toMoment(event.to));
  }

  isSelectedNamedDocument(
    namedDocument: INamedDocument,
    selectedNamedDocument: INamedDocument
  ): boolean {
    try {
      return isSameRef(namedDocument, selectedNamedDocument);
    } catch (error) {
      return isEqual(namedDocument, selectedNamedDocument);
    }
  }

  private _getPreferredPractitioner$(): Observable<
    INamedDocument<IStaffer> | IDefaultOption
  > {
    return combineLatest([
      this.practitioners$.pipe(isChanged$()),
      this.patient$.pipe(map((patient) => patient.preferredDentist)),
    ]).pipe(
      map(([practitioners, preferredPractitioner]) => {
        if (!preferredPractitioner) {
          return this.anyPractitioner;
        }
        return practitioners.some((practitioner) =>
          isSameRef(practitioner.ref, preferredPractitioner.ref)
        )
          ? preferredPractitioner
          : this.anyPractitioner;
      })
    );
  }
}
