import { getEnumValues } from '@principle-theorem/shared';
import { type IToothRef } from './core/tooth';

export enum PerioDataPoint {
  FacialDistal = 'facialDistal',
  PalatalDistal = 'palatalDistal',
  FacialCentral = 'facialCentral',
  PalatalCentral = 'palatalCentral',
  FacialMesial = 'facialMesial',
  PalatalMesial = 'palatalMesial',
}

export type IPerioDataPoints = Record<PerioDataPoint, number>;

export const PERIO_DATA_POINTS: PerioDataPoint[] =
  getEnumValues(PerioDataPoint);

export const FACIAL_PERIO_DATA_POINTS: PerioDataPoint[] = [
  PerioDataPoint.FacialMesial,
  PerioDataPoint.FacialCentral,
  PerioDataPoint.FacialDistal,
];

export const PALATAL_PERIO_DATA_POINTS: PerioDataPoint[] = [
  PerioDataPoint.PalatalMesial,
  PerioDataPoint.PalatalCentral,
  PerioDataPoint.PalatalDistal,
];

export enum PerioMeasurement {
  Recession = 'recession',
  MGJ = 'MGJ',
  Pocket = 'pocket',
  Furcation = 'furcation',
  Mobility = 'mobility',
  Bleeding = 'bleeding',
  Suppuration = 'suppuration',
}

export const PERIO_MEASUREMENTS: PerioMeasurement[] =
  getEnumValues(PerioMeasurement);

export interface IPerioData {
  [PerioMeasurement.Mobility]: number;
  [PerioMeasurement.Recession]: Partial<IPerioDataPoints>;
  [PerioMeasurement.MGJ]: Partial<IPerioDataPoints>;
  [PerioMeasurement.Pocket]: Partial<IPerioDataPoints>;
  [PerioMeasurement.Bleeding]: Partial<IPerioDataPoints>;
  [PerioMeasurement.Furcation]: Partial<IPerioDataPoints>;
  [PerioMeasurement.Suppuration]: Partial<IPerioDataPoints>;
}

export interface IPerioRecord {
  toothRef: IToothRef;
  data: Partial<IPerioData>;
}
