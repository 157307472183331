<pt-feature-info />

<pt-breadcrumbs [breadcrumbs]="breadcrumbs()" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="wrapper">
  <div class="flex flex-col gap-4">
    <pr-template-editor
      [contextResolver]="contextResolver"
      [template]="template$ | ngrxPush"
      [isSystemTemplate]="isSystemTemplate()"
      [isAutomationTemplate]="isAutomationTemplate()"
      (templateChange)="templateChange($event)"
    />


    @if (automationConfig(); as config) {
      <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
        <h3 class="!m-0 !font-bold">Used in Automations</h3>
        <mat-action-list class="!p-0">
          <button mat-list-item (click)="editAutomation(config)">
            <span matListItemTitle>{{ config.name }}</span>
          </button>
        </mat-action-list>
      </div>
    }

    <pt-buttons-container>
      <button mat-flat-button color="primary" (click)="save()">Save</button>
    </pt-buttons-container>
  </div>
</div>
