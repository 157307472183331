<mat-form-field class="flex grow">
  <mat-label>{{ options | map: getLabel }}</mat-label>
  <textarea
    #autosize="cdkTextareaAutosize"
    matInput
    cdkTextareaAutosize
    cdkAutosizeMinRows="2"
    [formControl]="formControl"
  ></textarea>
</mat-form-field>
