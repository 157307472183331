import {
  AnyAutomation,
  AutomationCreator,
  AutomationStatus,
  AutomationType,
  IAppointment,
  IAutomation,
  IAutomationConfiguration,
  IAutomationResource,
  IBrand,
  IChartedTreatment,
  IChecklistItem,
  IEvent,
  IFeeSchedule,
  IScopeRef,
  IStaffer,
  ITreatmentCategory,
  ITreatmentConfiguration,
  ITreatmentPlan,
  ITreatmentStep,
  ITreatmentStepConfiguration,
  ITreatmentStepDisplay,
  TreatmentStepAutomation,
  TreatmentStepCollection,
  TreatmentStepStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  CollectionReference,
  DocumentReference,
  Firestore,
  INamedDocument,
  IReffable,
  Timestamp,
  WithRef,
  addDoc,
  all$,
  asyncForEach,
  asyncReduce,
  isSameRef,
  multiFilter,
  multiMap,
  patchDoc,
  query$,
  reduce2DArray,
  safeCombineLatest,
  snapshot,
  subCollection,
  where,
} from '@principle-theorem/shared';
import { pick } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TimezoneResolver } from '../../../timezone';
import { CalculateTreatmentWeight } from '../../appointment/appointment-category';
import { Automation } from '../../automation/automation';
import { ChartedItem } from '../core/charted-item';
import {
  ChartedItemScopeResolver,
  ISurfaceScopeRefPair,
} from '../core/charted-item-scope-resolver';
import { FeeScheduleManager } from '../fees/fee-schedule/fee-schedule-manager';
import { ChartedItemTotalCalculator } from './charted-item-total-calculator';
import { ChartedTreatment } from './charted-treatment';
import { ChartedTreatmentUpdater } from './charted-treatment-updater';
import { getAutomationsFromConfigurations$ } from './treatment-configuration';
import { deduplicateChecklistItems } from './treatment-configuration-checklists';

/**
 * Treatment Step
 *
 * Associates treatment groups with an appointment
 * and a natural stage in a treatment plan.
 */
export class TreatmentStep {
  static init(overrides?: Partial<ITreatmentStep>): ITreatmentStep {
    return {
      deleted: false,
      name: 'New Step',
      status: TreatmentStepStatus.Incomplete,
      treatments: [],
      serviceCodes: [],
      schedulingRules: { duration: 0 },
      display: {},
      ...overrides,
    };
  }

  static treatmentPlanRef(
    step: IReffable<ITreatmentStep>
  ): DocumentReference<ITreatmentPlan> {
    return Firestore.getParentDocRef<ITreatmentPlan>(step.ref);
  }

  static clone(step: ITreatmentStep): ITreatmentStep {
    const clonedStep = TreatmentStep.init(
      pick(step, ['name', 'schedulingRules', 'display', 'practitionerRef'])
    );
    clonedStep.treatments = step.treatments.map((treatment) =>
      ChartedTreatment.init(
        pick(treatment, [
          'config',
          'chartedSurfaces',
          'notes',
          'scopeRef',
          'serviceCodeSmartGroups',
          'serviceCodeGroups',
          'serviceCodes',
          'basePrice',
          'feeSchedule',
          'attributedTo',
          'isGrouped',
          'treatmentPackageId',
        ])
      )
    );
    return clonedStep;
  }

  static summary(step: AtLeast<ITreatmentStep, 'treatments'>): string {
    return step.treatments
      .map((treatment: IChartedTreatment) => treatment.config.name)
      .join(', ');
  }

  static price(step: ITreatmentStep): number {
    return new ChartedItemTotalCalculator().step(step);
  }

  static deleteTreatment<T extends ITreatmentStep>(
    step: T,
    treatmentUid: string
  ): T {
    return {
      ...step,
      treatments: step.treatments.filter(
        (currentTreatment: IChartedTreatment) => {
          return currentTreatment.uuid !== treatmentUid;
        }
      ),
    };
  }

  static findTreatmentById<T extends ITreatmentStep>(
    step: T,
    treatmentUid: string
  ): IChartedTreatment | undefined {
    return step.treatments.find((treatment) => treatment.uuid === treatmentUid);
  }

  static schedulingRulesHasDayRanges(step: ITreatmentStep): boolean {
    if (step.schedulingRules.minDays || step.schedulingRules.maxDays) {
      return true;
    }
    return false;
  }

  static isComplete(step: ITreatmentStep): boolean {
    return step.status === TreatmentStepStatus.Complete;
  }

  static isCurrent(step: ITreatmentStep): boolean {
    return step.status === TreatmentStepStatus.Current;
  }

  static isIncomplete(step: ITreatmentStep): boolean {
    return step.status === TreatmentStepStatus.Incomplete;
  }

  static treatmentPlan$(
    step: IReffable<ITreatmentStep>
  ): Observable<WithRef<ITreatmentPlan>> {
    return Firestore.doc$<ITreatmentPlan>(Firestore.getParentDocRef(step.ref));
  }

  static treatmentPlan(
    step: IReffable<ITreatmentStep>
  ): Promise<WithRef<ITreatmentPlan>> {
    return Firestore.getDoc<ITreatmentPlan>(
      Firestore.getParentDocRef(step.ref)
    );
  }

  static appointment$(
    step: ITreatmentStep
  ): Observable<WithRef<IAppointment> | undefined> {
    return step.appointment
      ? Firestore.doc$<IAppointment>(step.appointment)
      : of(undefined);
  }

  static async appointment(
    step: ITreatmentStep
  ): Promise<WithRef<IAppointment> | undefined> {
    if (!step.appointment) {
      return;
    }
    return Firestore.getDoc(step.appointment);
  }

  static async updateStatus(
    step: WithRef<ITreatmentStep>,
    status: TreatmentStepStatus
  ): Promise<void> {
    if (step.status === status) {
      return;
    }
    await patchDoc(step.ref, { status });
  }

  static fromAppointment$(
    appointment: IAppointment
  ): Observable<WithRef<ITreatmentStep>> {
    return Firestore.doc$<ITreatmentStep>(
      appointment.treatmentPlan.treatmentStep.ref
    );
  }

  static automationCol<T extends IAutomationResource = TreatmentStepAutomation>(
    step: IReffable<ITreatmentStep>
  ): CollectionReference<IAutomation<T>> {
    return subCollection<IAutomation<T>>(
      step.ref,
      TreatmentStepCollection.Automations
    );
  }

  static automations$(
    step: IReffable<ITreatmentStep>
  ): Observable<WithRef<IAutomation<TreatmentStepAutomation>>[]> {
    return all$(TreatmentStep.automationCol(step));
  }

  static getTreatmentConfigurations$(
    step: ITreatmentStep
  ): Observable<WithRef<ITreatmentConfiguration>[]> {
    return safeCombineLatest(
      step.treatments.map((treatment) => Firestore.doc$(treatment.config.ref))
    );
  }

  static getTreatmentConfigurationChecklists$(
    step: ITreatmentStep
  ): Observable<IChecklistItem[]> {
    return TreatmentStep.getTreatmentConfigurations$(step).pipe(
      multiMap((treatmentConfigurations) => treatmentConfigurations.checklists),
      reduce2DArray(),
      map((checklists) => deduplicateChecklistItems(checklists)),
      multiFilter((checklist) => !checklist.deleted)
    );
  }

  static getTreatmentConfigurationAutomations$(
    step: ITreatmentStep,
    event: IEvent,
    brandRef: DocumentReference<IBrand>,
    triggerAfterDate?: Timestamp
  ): Observable<IAutomation<AnyAutomation>[]> {
    return TreatmentStep.getTreatmentConfigurations$(step).pipe(
      switchMap((treatmentConfigurations) =>
        treatmentConfigurations.length
          ? getAutomationsFromConfigurations$(
              treatmentConfigurations,
              event,
              brandRef,
              triggerAfterDate
            )
          : of([])
      )
    );
  }

  static automationsByType$<T extends IAutomationResource>(
    step: WithRef<ITreatmentStep>,
    type: AutomationType
  ): Observable<WithRef<IAutomation<T>>[]> {
    return query$(
      TreatmentStep.automationCol<T>(step),
      where('type', '==', type)
    );
  }

  static async fromConfig(
    stepConfig: ITreatmentStepConfiguration,
    feeSchedule: WithRef<IFeeSchedule>,
    scopeRef: IScopeRef,
    attributedTo?: INamedDocument<IStaffer>
  ): Promise<ITreatmentStep> {
    const step: ITreatmentStep = TreatmentStep.init({
      name: stepConfig.name,
      schedulingRules: stepConfig.schedulingRules,
    });
    const treatmentConfigs = await asyncForEach(
      stepConfig.treatments,
      (treatmentConfigRef) => {
        return Firestore.getDoc(treatmentConfigRef.ref);
      }
    );

    step.treatments = treatmentConfigs.map((config) =>
      ChartedTreatment.fromConfig(config, feeSchedule, scopeRef, attributedTo)
    );
    return step;
  }

  static getDuration$(step: ITreatmentStep): Observable<number> {
    if (step.schedulingRules.duration > 0) {
      return of(step.schedulingRules.duration);
    }
    return TreatmentStep.durationFromConfigurations$(step);
  }

  static durationFromConfigurations$(step: ITreatmentStep): Observable<number> {
    return TreatmentStep.getTreatmentConfigurations$(step).pipe(
      map((treatmentConfigurations) =>
        treatmentConfigurations.reduce(
          (total, treatmentConfiguration) =>
            total + treatmentConfiguration.duration,
          0
        )
      )
    );
  }

  static addTreatment<T extends ITreatmentStep>(
    step: T,
    treatment: IChartedTreatment
  ): T {
    return {
      ...step,
      treatments: [...step.treatments, treatment],
    };
  }

  static removeTreatment<T extends ITreatmentStep>(
    step: T,
    treatment: IChartedTreatment
  ): T {
    const treatments = step.treatments.filter(
      (currentTreatment) => currentTreatment.uuid !== treatment.uuid
    );
    return {
      ...step,
      treatments,
    };
  }

  static updateTreatment<T extends ITreatmentStep>(
    step: T,
    treatment: IChartedTreatment
  ): T {
    const treatments = step.treatments.map((currentTreatment) => {
      if (currentTreatment.uuid !== treatment.uuid) {
        return currentTreatment;
      }
      return {
        ...currentTreatment,
        ...treatment,
      };
    });
    return {
      ...step,
      treatments,
    };
  }

  static async updateDisplayPrimaryCategory<T extends ITreatmentStep>(
    step: T,
    categories: WithRef<ITreatmentCategory>[]
  ): Promise<T> {
    const primaryTreatmentCategory =
      await CalculateTreatmentWeight.getPrimaryCategory(step, categories);

    return {
      ...step,
      display: {
        ...step.display,
        primaryTreatmentCategory: primaryTreatmentCategory?.ref,
      },
    };
  }

  static updateDisplayOverrideCategory(
    step: ITreatmentStep,
    selectedOverrideRef: DocumentReference<ITreatmentCategory>
  ): ITreatmentStepDisplay | undefined {
    const primary = step.display.primaryTreatmentCategory;
    const override = step.display.overrideTreatmentCategory;

    if (isSameRef(override, selectedOverrideRef)) {
      return;
    }

    return isSameRef(primary, selectedOverrideRef)
      ? { ...step.display, overrideTreatmentCategory: undefined }
      : { ...step.display, overrideTreatmentCategory: selectedOverrideRef };
  }

  static defaultDisplayRef(
    display: ITreatmentStepDisplay
  ): DocumentReference<ITreatmentCategory> | undefined {
    if (!display) {
      return;
    }
    return (
      display.overrideTreatmentCategory ?? display.primaryTreatmentCategory
    );
  }

  static async resolveTreatmentCategory(
    display: ITreatmentStepDisplay
  ): Promise<WithRef<ITreatmentCategory> | undefined> {
    const category = TreatmentStep.defaultDisplayRef(display);
    if (!category) {
      return;
    }
    return Firestore.getDoc(category);
  }

  static async addMissingTreatmentAutomations(
    appointment: WithRef<IAppointment>,
    treatmentStep: WithRef<ITreatmentStep>,
    automations: IAutomation<TreatmentStepAutomation>[]
  ): Promise<void> {
    const currentAutomations = await snapshot(
      TreatmentStep.automations$(treatmentStep)
    );
    const missingAutomations = this.getTreatmentAutomationsToAdd(
      currentAutomations,
      automations
    );
    await asyncForEach(missingAutomations, async (automation) => {
      if (!appointment.event) {
        return;
      }
      const timezone = await TimezoneResolver.fromPracticeRef(
        appointment.practice.ref
      );
      return addDoc(
        TreatmentStep.automationCol(treatmentStep),
        Automation.adjustStatusAndTriggerDate(
          automation,
          appointment.event,
          timezone
        )
      );
    });
  }

  static async cancelAutomationsForRemovedTreatments(
    treatmentStep: WithRef<ITreatmentStep>,
    configRefs: DocumentReference<IAutomationConfiguration>[]
  ): Promise<void> {
    const currentAutomations = await snapshot(
      TreatmentStep.automations$(treatmentStep)
    );
    const automations = this.getTreatmentAutomationsToRemove(
      currentAutomations,
      configRefs
    );
    await asyncForEach(automations, async (automation) =>
      Firestore.patchDoc(automation.ref, { status: AutomationStatus.Cancelled })
    );
  }

  static getTreatmentAutomationsToAdd(
    currentAutomations: IAutomation<TreatmentStepAutomation>[],
    treatmentAutomations: IAutomation<TreatmentStepAutomation>[]
  ): IAutomation<TreatmentStepAutomation>[] {
    return treatmentAutomations.filter((automation) => {
      if (!automation.configRef) {
        return true;
      }
      const alreadyAdded = currentAutomations.some((currentAutomation) =>
        isSameRef(currentAutomation.configRef, automation.configRef)
      );
      return !alreadyAdded;
    });
  }

  static getTreatmentAutomationsToRemove<
    T extends IAutomation<TreatmentStepAutomation>,
  >(
    currentAutomations: T[],
    configRefs: DocumentReference<IAutomationConfiguration>[]
  ): T[] {
    return currentAutomations.filter((automation) => {
      if (
        !automation.configRef ||
        Automation.hasAlreadyRun(automation) ||
        automation.creator.type !== AutomationCreator.TreatmentConfiguration
      ) {
        return false;
      }
      const inConfigList = configRefs.some((configRef) =>
        isSameRef(configRef, automation.configRef)
      );
      return !inConfigList;
    });
  }
}

export class MultiStepTreatment {
  name: string = '';
  steps: TreatmentStep[] = [];
}

export async function buildUpsertTreatmentData(
  config: WithRef<ITreatmentConfiguration>,
  feeScheduleManager: FeeScheduleManager,
  feeSchedule: WithRef<IFeeSchedule>,
  surfaceScopeRefPair: ISurfaceScopeRefPair,
  currentTreatments: IChartedTreatment[],
  scopeResolver: ChartedItemScopeResolver,
  attributedTo?: INamedDocument<IStaffer>
): Promise<[existing: boolean, treatment: IChartedTreatment]> {
  const chartedTreatment = ChartedTreatment.fromConfig(
    config,
    feeSchedule,
    surfaceScopeRefPair.scopeRef,
    attributedTo
  );

  const existingTreatment = currentTreatments
    .filter((currentTreatment) => isSameRef(currentTreatment.config, config))
    .filter((currentTreatment) => !ChartedItem.isResolved(currentTreatment))
    .find((currentTreatment) =>
      surfaceScopeRefPair.surfaces.every((surface) =>
        scopeResolver.isInScope(currentTreatment, surface)
      )
    );

  const treatmentUpdater = new ChartedTreatmentUpdater();
  const defaultPackage = config.packages.find(
    (treatmentPackage) => treatmentPackage.isDefault
  );
  const isExisting = !!existingTreatment;

  const treatment = await treatmentUpdater.updatedTreatmentBySurface(
    existingTreatment ?? chartedTreatment,
    currentTreatments,
    surfaceScopeRefPair.surfaces,
    feeScheduleManager,
    isExisting ? undefined : defaultPackage
  );

  return [isExisting, treatment];
}

export async function upsertTreatmentsToStep<
  Step extends ITreatmentStep = ITreatmentStep,
>(
  treatmentStep: Step,
  config: WithRef<ITreatmentConfiguration>,
  surfaceScopeRefPairs: ISurfaceScopeRefPair[],
  scopeResolver: ChartedItemScopeResolver,
  feeSchedule: WithRef<IFeeSchedule>,
  attributedTo?: INamedDocument<IStaffer>
): Promise<Step> {
  const feeScheduleManager = new FeeScheduleManager(
    Firestore.doc$(feeSchedule.ref)
  );
  return asyncReduce(
    surfaceScopeRefPairs,
    async (step, surfaceScopeRefPair) => {
      const [existing, treatment] = await buildUpsertTreatmentData(
        config,
        feeScheduleManager,
        feeSchedule,
        surfaceScopeRefPair,
        step.treatments,
        scopeResolver,
        attributedTo
      );
      return existing
        ? TreatmentStep.updateTreatment(step, treatment)
        : TreatmentStep.addTreatment(step, treatment);
    },
    { ...treatmentStep }
  );
}
