import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IntercomService } from '@principle-theorem/ng-intercom';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { Subject } from 'rxjs';
import { PatientFormsListComponent } from '../../components/patient-forms-list/patient-forms-list.component';
import { PatientFormsStore } from '../patient-forms-page/patient-forms-store.service';
import { PatientPortalFunctionsService } from '../../patient-portal-functions.service';
import { PatientPortalLoaderComponent } from '../../components/patient-portal-loader/patient-portal-loader.component';
import { PatientPortalSplashComponent } from '../../components/patient-portal-splash/patient-portal-splash.component';
import { PatientPortalUI } from '../../lib/patient-portal-ui';
import { PatientConfirmAppointmentDetailsComponent } from '../../components/patient-confirm-appointment-details/patient-confirm-appointment-details.component';
import { PatientConfirmAppointmentLocationComponent } from '../../components/patient-confirm-appointment-location/patient-confirm-appointment-location.component';
import { PatientConfirmAppointmentStore } from './patient-confirm-appointment.store';
import { EmptyStateComponent } from '@principle-theorem/ng-principle-shared';

@Component({
    selector: 'pr-patient-confirm-appointment',
    templateUrl: './patient-confirm-appointment.component.html',
    styleUrls: ['./patient-confirm-appointment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        NgSharedModule,
        NgMaterialModule,
        EmptyStateComponent,
        PatientPortalLoaderComponent,
        PatientPortalSplashComponent,
        PatientConfirmAppointmentDetailsComponent,
        PatientConfirmAppointmentLocationComponent,
        PatientFormsListComponent,
    ],
    providers: [
        PatientPortalFunctionsService,
        PatientConfirmAppointmentStore,
        PatientFormsStore,
    ]
})
export class PatientConfirmAppointmentComponent implements OnInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _route = inject(ActivatedRoute);
  private _intercom = inject(IntercomService);
  confirmAppointment = inject(PatientConfirmAppointmentStore);
  patientForms = inject(PatientFormsStore);

  constructor() {
    const tokenUid$ = PatientPortalUI.getTokenUid$(this._route);
    this.confirmAppointment.loadTokenUid(tokenUid$);
    this.patientForms.loadTokenUid(tokenUid$);
  }

  ngOnInit(): void {
    this._intercom.hideIcon();
  }

  ngOnDestroy(): void {
    this._intercom.showIcon();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
