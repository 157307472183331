import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'pr-warning',
    templateUrl: './warning.component.html',
    styleUrls: ['./warning.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WarningComponent {
  @Input() warning = '';
}
