import { createAction, props } from '@ngrx/store';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';

export enum CurrentBrandScopeActions {
  SetBrandSlug = '[Current Brand Scope] Set Slug',
  ClearBrandSlug = '[Current Brand Scope] Clear Slug',
  LoadBrandSuccess = '[Current Brand Scope] Load Brand Success',
  LoadBrandFail = '[Current Brand Scope] Load Brand Fail',
  ClearBrand = '[Current Brand Scope] Clear Brand',
  LoadBrandFromResolver = '[Current Brand Scope] Set From Resolver',
}

export enum CurrentPracticeScopeActions {
  SetPracticeSlug = '[Current Practice Scope] Set Slug',
  ClearPracticeSlug = '[Current Practice Scope] Clear Slug',
  LoadPracticeSuccess = '[Current Practice Scope] Load Practice Success',
  LoadPracticeFail = '[Current Practice Scope] Load Practice Fail',
  ClearPractice = '[Current Practice Scope] Clear Practice',
  LoadPracticeFromResolver = '[Current Practice Scope] Set From Resolver',
}

export interface ISetBrand {
  brand: WithRef<IBrand>;
}

export interface ISetPractice {
  practice: WithRef<IPractice>;
}

export interface ISetSlug {
  slug: string;
}

export const setBrandSlug = createAction(
  CurrentBrandScopeActions.SetBrandSlug,
  props<ISetSlug>()
);

export const clearBrandSlug = createAction(
  CurrentBrandScopeActions.ClearBrandSlug
);

export const loadBrandFromResolver = createAction(
  CurrentBrandScopeActions.LoadBrandFromResolver,
  props<SerialisedData<ISetBrand>>()
);

export const loadBrandSuccess = createAction(
  CurrentBrandScopeActions.LoadBrandSuccess,
  props<SerialisedData<ISetBrand>>()
);

export const loadBrandFail = createAction(
  CurrentBrandScopeActions.LoadBrandFail,
  props<{ error: string }>()
);

export const clearBrand = createAction(CurrentBrandScopeActions.ClearBrand);

export const setPracticeSlug = createAction(
  CurrentPracticeScopeActions.SetPracticeSlug,
  props<ISetSlug>()
);

export const clearPracticeSlug = createAction(
  CurrentPracticeScopeActions.ClearPracticeSlug
);

export const loadPracticeFromResolver = createAction(
  CurrentPracticeScopeActions.LoadPracticeFromResolver,
  props<SerialisedData<ISetPractice>>()
);

export const loadPracticeSuccess = createAction(
  CurrentPracticeScopeActions.LoadPracticeSuccess,
  props<SerialisedData<ISetPractice>>()
);

export const loadPracticeFail = createAction(
  CurrentPracticeScopeActions.LoadPracticeFail,
  props<SerialisedData<{ error: string }>>()
);

export const clearPractice = createAction(
  CurrentPracticeScopeActions.ClearPractice
);
