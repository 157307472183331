import {
  BASIC_MEDICAL_HISTORY_FORM_SCHEMA,
  Brand,
  CustomFormConfiguration,
} from '@principle-theorem/principle-core';
import {
  CustomMappingAssociatedValueType,
  CustomMappingOption,
  CustomMappingType,
  ICustomMapping,
  ICustomMappingSourceOption,
  IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import {
  IBlobFilenamePair,
  IReffable,
  WithRef,
  XSLXImporterExporter,
  asyncForEach,
  hardDeleteDoc,
  multiSwitchMap,
  snapshot,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import { PatientMedicalConditionOptionSourceEntity } from '../../source/entities/patient-medical-condition-options';
import { getValidMedicalHistoryFormOptions } from './allergy-to-medical-history';
import { TranslationMapHandler } from '../../../translation-map';
import { PracticeMigration } from '../../../practice-migrations';
import { MedicalConditionToMedicalHistoryToXSLX } from './medical-condition-to-medical-history-to-xlsx';
import { XSLXToMedicalConditionToMedicalHistory } from './xlsx-to-medical-condition-to-medical-history';
import { IMedicalHistoryOption } from './allergy-to-medical-history-to-xlsx';

export const MEDICAL_CONDITION_CUSTOM_MAPPING_TYPE = 'medicalConditionMapping';

export const MEDICAL_CONDITION_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Medical Condition Mapping',
    description: `Used to map Core Practice medical conditions to Principle medical history.`,
    type: MEDICAL_CONDITION_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.SelectionList,
});

export class CorePracticeMedicalConditionMappingHandler extends BaseCustomMappingHandler<
  object,
  string
> {
  customMapping = MEDICAL_CONDITION_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const medicalConditionOptions =
      new PatientMedicalConditionOptionSourceEntity();
    const records = await medicalConditionOptions
      .getRecords$(migration, 1000)
      .toPromise();
    return sortBy(
      records
        .map((record) => record.data.data)
        .map((record) => ({
          label: record.description,
          value: record.description,
        })),
      'value'
    );
  }

  async getSelectionListOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<CustomMappingOption[]> {
    return snapshot(
      Brand.medicalHistoryFormConfig$(migration.configuration.brand).pipe(
        switchMap((config) =>
          config ? CustomFormConfiguration.getContent$(config) : of(undefined)
        ),
        map((content) => {
          const schema =
            content?.jsonSchemaForm.schema ?? BASIC_MEDICAL_HISTORY_FORM_SCHEMA;
          return [
            {
              value: '',
              description: 'Omit',
              hasAssociatedValue: false,
              associatedValueType: CustomMappingAssociatedValueType.String,
              associatedValueDescription: '',
            },
            ...getValidMedicalHistoryFormOptions(schema).map((option) => ({
              value: option.path,
              description: option.mapTitle ?? '',
              hasAssociatedValue: option.associatedValues ? true : false,
              associatedValueType: CustomMappingAssociatedValueType.String,
              associatedValueDescription: 'Select an option',
            })),
          ];
        })
      )
    );
  }

  getAssociatedValueOptions$(
    migration: IPracticeMigration,
    destinationValue: string
  ): Observable<{ name: string }[]> {
    return Brand.medicalHistoryFormConfig$(migration.configuration.brand).pipe(
      switchMap((config) =>
        config ? CustomFormConfiguration.getContent$(config) : of(undefined)
      ),
      map((content) => {
        const schema =
          content?.jsonSchemaForm.schema ?? BASIC_MEDICAL_HISTORY_FORM_SCHEMA;
        const selectedOption = getValidMedicalHistoryFormOptions(schema).find(
          (option) => option.path === destinationValue
        );
        return (selectedOption?.associatedValues ?? []).map((name) => ({
          name,
        }));
      })
    );
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, referralSources, translators } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(
      fileName,
      referralSources,
      translators
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, referralSources, translators } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(
      fileName,
      referralSources,
      translators
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToMedicalConditionToMedicalHistory()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const records = await this.getRecords(translationMap);
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));

    const sourceOptions = await this.getSourceOptions(migration);
    const medicalHistoryOptions =
      await this._getMedicalHistoryOptions(migration);

    await asyncForEach(items, async (item) => {
      const mapTo = item.mapTo;
      const id = item.sourceId;
      const label = item.sourceName;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.value === id
      )?.value;
      if (!value || !item.mapTo) {
        return;
      }

      const matchingOption = medicalHistoryOptions.find(
        (option) => option.label === mapTo
      );

      if (!matchingOption) {
        // eslint-disable-next-line no-console
        console.error(
          `Mapping error: ${this.customMapping.metadata.label} - Couldn't find matching medical history option for item`,
          item
        );
        return;
      }

      await this.upsertRecord(
        {
          associatedValue: matchingOption.associatedValue,
          destinationValue: matchingOption.destinationValue,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    referralSources: ICustomMappingSourceOption[];
    translators: MedicalConditionToMedicalHistoryToXSLX;
  }> {
    const fileName = this.getFileName();
    const referralSources = await this.getSourceOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const medicalHistoryOptions =
      await this._getMedicalHistoryOptions(migration);
    const translators = new MedicalConditionToMedicalHistoryToXSLX(
      medicalHistoryOptions,
      await this.getRecords(translationMap)
    );
    return { fileName, referralSources, translators };
  }

  private async _getMedicalHistoryOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<IMedicalHistoryOption[]> {
    return snapshot(
      from(this.getSelectionListOptions(migration)).pipe(
        multiSwitchMap((option) => {
          if (!option.hasAssociatedValue) {
            return of([
              {
                destinationValue: option.value,
                label: option.description,
              },
            ]);
          }

          return this.getAssociatedValueOptions$(migration, option.value).pipe(
            map((associatedValues) => {
              return associatedValues.map((associatedValue) => ({
                destinationValue: option.value,
                associatedValue: associatedValue.name,
                label: `${option.description} - ${associatedValue.name}`,
              }));
            })
          );
        }),
        map((options) => options.flat())
      )
    );
  }
}
