import {
  ConditionLogicId,
  IBrand,
  IConditionLogicImplementation,
  ICustomFormConfiguration,
  IDynamicForm,
  IPatientForm,
  IPatientScopeData,
  ITag,
  PatientFormStatus,
  isPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  INamedDocument,
  TypeGuard,
  WithRef,
  isSameRef,
  toMoment,
  toNamedDocument,
  undeletedQuery,
} from '@principle-theorem/shared';
import { first, isString, sortBy } from 'lodash';
import * as moment from 'moment-timezone';
import { Brand } from '../models/brand';
import { Patient } from '../models/patient/patient';
import { PatientForm } from '../models/patient/patient-form';
import { DynamicForm } from './dynamic-form';

const isPatientScopeData = TypeGuard.interface<IPatientScopeData>({
  patient: isPatient,
  medicalHistoryLink: isString,
  referrer: TypeGuard.noGuard(),
});

interface IPatientHasTagConditionConfig {
  tag: INamedDocument<ITag>;
}

export class PatientHasTagCondition
  implements
    IConditionLogicImplementation<
      IPatientScopeData,
      IPatientHasTagConditionConfig,
      INamedDocument<ITag>
    >
{
  conditionId = ConditionLogicId.PatientHasTag;
  description = 'Patient must have the selected tag';
  isValidContext = isPatientScopeData;

  isTrue(
    config: IPatientHasTagConditionConfig,
    context: IPatientScopeData
  ): boolean {
    return context.patient.tags.some((patientTag) =>
      isSameRef(patientTag.ref, config.tag.ref)
    );
  }

  async getForm(
    brand: WithRef<IBrand>,
    initialValue?: IPatientHasTagConditionConfig
  ): Promise<IDynamicForm<INamedDocument<ITag>>> {
    const tags = await Firestore.getDocs(Brand.patientTagCol(brand));
    const options = tags.map((tag) => ({
      label: tag.name,
      value: toNamedDocument(tag),
    }));
    const tag = DynamicForm.select<INamedDocument<ITag>>({
      label: 'Tag',
      options: sortBy(options, 'label'),
      initialValue: initialValue?.tag ?? first(options)?.value,
      compareWith: isSameRef,
    });
    return { tag };
  }
}

interface IPatientHasMobileNumberConditionConfig {
  hasMobileNumber: boolean;
}

export class PatientHasMobileNumberCondition
  implements
    IConditionLogicImplementation<
      IPatientScopeData,
      IPatientHasMobileNumberConditionConfig,
      boolean
    >
{
  conditionId = ConditionLogicId.PatientHasMobileNumber;
  description = 'Patient must have a mobile number';
  isValidContext = isPatientScopeData;

  async isTrue(
    config: IPatientHasMobileNumberConditionConfig,
    context: IPatientScopeData
  ): Promise<boolean> {
    const hasMobileNumber = !!(await Patient.getMobileNumber(context.patient));
    return hasMobileNumber === config.hasMobileNumber;
  }

  async getForm(
    _brand: WithRef<IBrand>,
    initialValue?: IPatientHasMobileNumberConditionConfig
  ): Promise<IDynamicForm<boolean>> {
    const hasMobileNumber = DynamicForm.select<boolean>({
      label: 'Has Mobile Number',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      initialValue: initialValue?.hasMobileNumber ?? true,
    });
    return Promise.resolve({ hasMobileNumber });
  }
}

interface IPatientHasEmailAddressConditionConfig {
  hasEmailAddress: boolean;
}

export class PatientHasEmailAddressCondition
  implements
    IConditionLogicImplementation<
      IPatientScopeData,
      IPatientHasEmailAddressConditionConfig,
      boolean
    >
{
  conditionId = ConditionLogicId.PatientHasEmailAddress;
  description = 'Patient must have the selected tag';
  isValidContext = isPatientScopeData;

  isTrue(
    config: IPatientHasEmailAddressConditionConfig,
    context: IPatientScopeData
  ): boolean {
    const hasEmailAddress = !!context.patient.email;
    return hasEmailAddress === config.hasEmailAddress;
  }

  async getForm(
    _brand: WithRef<IBrand>,
    initialValue?: IPatientHasEmailAddressConditionConfig
  ): Promise<IDynamicForm<boolean>> {
    const hasEmailAddress = DynamicForm.select<boolean>({
      label: 'Has Email Address',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      initialValue: initialValue?.hasEmailAddress ?? true,
    });
    return Promise.resolve({ hasEmailAddress });
  }
}

interface IPatientHasIssuedFormConditionConfig {
  formConfigRef?: DocumentReference<ICustomFormConfiguration>;
  hasIssuedForm: boolean;
}

export class PatientHasIssuedFormCondition
  implements
    IConditionLogicImplementation<
      IPatientScopeData,
      IPatientHasIssuedFormConditionConfig
    >
{
  conditionId = ConditionLogicId.PatientHasIssuedForm;
  description =
    'Patient must have the selected form issued and waiting completion';
  isValidContext = isPatientScopeData;

  isTrue(
    config: IPatientHasIssuedFormConditionConfig,
    context: IPatientScopeData
  ): boolean {
    const targetForms = getTargetForms(
      context.patientForms,
      config.formConfigRef
    );
    const issuedForms = targetForms.filter(
      (patientForm) => patientForm.status === PatientFormStatus.Issued
    );
    const hasAtLeastOneIssuedForm = issuedForms.length > 0;
    return config.hasIssuedForm
      ? hasAtLeastOneIssuedForm
      : !hasAtLeastOneIssuedForm;
  }

  async getForm(
    brand: WithRef<IBrand>,
    initialValue?: IPatientHasIssuedFormConditionConfig
  ): Promise<IDynamicForm> {
    const formConfigurations = await Firestore.getDocs(
      undeletedQuery(Brand.customFormConfigCol(brand))
    );
    const unsortedOptions = formConfigurations.map((form) => ({
      label: form.name,
      value: form.ref,
    }));
    const options = sortBy(unsortedOptions, (option) => option.label);
    const formConfigRef = DynamicForm.select<
      DocumentReference<ICustomFormConfiguration> | undefined
    >({
      label: 'Form Configuration',
      options: [{ label: 'Any', value: undefined }, ...options],
      initialValue: initialValue?.formConfigRef,
      isRequired: false,
      compareWith: isSameRef,
    });

    const hasIssuedForm = DynamicForm.select<boolean>({
      label: 'Has Issued Form',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      initialValue: initialValue?.hasIssuedForm ?? true,
    });

    return { formConfigRef, hasIssuedForm } as IDynamicForm;
  }
}

interface IPatientHasSubmittedFormConditionConfig {
  formConfigRef?: DocumentReference<ICustomFormConfiguration>;
  submittedWithinDays?: number;
  hasSubmittedForm: boolean;
}

export class PatientHasSubmittedFormCondition
  implements
    IConditionLogicImplementation<
      IPatientScopeData,
      IPatientHasSubmittedFormConditionConfig
    >
{
  conditionId = ConditionLogicId.PatientHasSubmittedForm;
  description =
    'Patient must have submitted the selected form within the given time frame';
  isValidContext = isPatientScopeData;

  isTrue(
    config: IPatientHasSubmittedFormConditionConfig,
    context: IPatientScopeData
  ): boolean {
    const targetForms = getTargetForms(
      context.patientForms,
      config.formConfigRef
    );

    const hasSubmittedWithinDays = this._hasSubmittedFormWithinDays(
      targetForms.filter((patientForm) => PatientForm.isSubmitted(patientForm)),
      config.submittedWithinDays
    );

    return config.hasSubmittedForm
      ? hasSubmittedWithinDays
      : !hasSubmittedWithinDays;
  }

  async getForm(
    brand: WithRef<IBrand>,
    initialValue?: IPatientHasSubmittedFormConditionConfig
  ): Promise<IDynamicForm> {
    const formConfigurations = await Firestore.getDocs(
      undeletedQuery(Brand.customFormConfigCol(brand))
    );
    const unsortedOptions = formConfigurations.map((form) => ({
      label: form.name,
      value: form.ref,
    }));
    const options = sortBy(unsortedOptions, (option) => option.label);

    const formConfigRef = DynamicForm.select<
      DocumentReference<ICustomFormConfiguration> | undefined
    >({
      label: 'Form Configuration',
      options: [{ label: 'Any', value: undefined }, ...options],
      initialValue: initialValue?.formConfigRef,
      compareWith: isSameRef,
    });

    const submittedWithinDays = DynamicForm.number<number | undefined>({
      label: 'Submitted within x days',
      initialValue: initialValue?.submittedWithinDays,
    });

    const hasSubmittedForm = DynamicForm.select<boolean>({
      label: 'Has Submitted Form Recently',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      initialValue: initialValue?.hasSubmittedForm ?? true,
      isRequired: true,
    });

    return {
      formConfigRef,
      submittedWithinDays,
      hasSubmittedForm,
    } as IDynamicForm;
  }

  private _hasSubmittedFormWithinDays(
    submittedForms: WithRef<IPatientForm<object>>[],
    submittedWithinDays?: number
  ): boolean {
    if (!submittedWithinDays) {
      return submittedForms.length > 0;
    }
    const formsSubmittedWithinDays = submittedForms.filter((patientForm) => {
      const submittedAt = PatientForm.submittedAt(patientForm);
      const cutoff = moment().subtract(submittedWithinDays, 'days');
      return submittedAt && toMoment(submittedAt).isSameOrAfter(cutoff);
    });
    return formsSubmittedWithinDays.length > 0;
  }
}

function getTargetForms(
  patientForms: WithRef<IPatientForm>[],
  formConfigRef?: DocumentReference<ICustomFormConfiguration>
): WithRef<IPatientForm>[] {
  return patientForms.filter((patientForm) => {
    return formConfigRef && patientForm.template?.ref
      ? isSameRef(patientForm.template.ref, formConfigRef)
      : true;
  });
}
