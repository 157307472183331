import {
  IBaseChartBuilderConfig,
  IMeasureMetadata,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import { type IChartConfig } from '@principle-theorem/reporting';
import * as d3 from 'd3';
import * as dc from 'dc';
import { first, get, isString } from 'lodash';
import { type IDCTransformResult } from '../../../../models/report/charts/data-transformers/dc-data-transformer';
import {
  ChartBuildHelpers,
  D3_CHART_ELEM_SELECTOR,
  DEFAULT_CHART_BUILDER_CONFIG,
  applyBaseConfigToChart,
  type IDCChartBuilder,
} from './dc-chart-builder';

type FormatterReturnType = (n: number | { valueOf(): number }) => string;

export function getD3NumberFormatter(
  formatter?: MeasureFormatter,
  formatterValue?: IMeasureMetadata['formatterValue']
): FormatterReturnType | undefined {
  switch (formatter) {
    case MeasureFormatter.Currency:
      return d3.format('($,.2f');
    case MeasureFormatter.Number:
      return d3.format(',.2f');
    case MeasureFormatter.Percentage:
      return (data) => `${d3.format('.2f')(data)}%`;
    case MeasureFormatter.Hours:
      return (data) => `${d3.format('.2f')(data)} hrs`;
    case MeasureFormatter.Minutes:
      return (data) => `${d3.format('.0f')(data)} mins`;
    case MeasureFormatter.Prefix:
      return formatterValue && isString(formatterValue)
        ? (data) => `${formatterValue ?? ''} ${d3.format('.2f')(data)}`
        : undefined;
    case MeasureFormatter.Suffix:
      return formatterValue && isString(formatterValue)
        ? (data) => `${d3.format('.2f')(data)} ${formatterValue ?? ''}`
        : undefined;
    case MeasureFormatter.Custom:
      return formatterValue && !isString(formatterValue)
        ? (data) => formatterValue(data.valueOf()).toString()
        : undefined;
    case MeasureFormatter.Boolean:
    case MeasureFormatter.Day:
    case MeasureFormatter.Link:
    case MeasureFormatter.Month:
    case MeasureFormatter.Time:
    case MeasureFormatter.Timestamp:
    default:
      return;
  }
}

export class DcSingleValueChartBuilder
  implements IDCChartBuilder<dc.NumberDisplayWidget>
{
  constructor(
    private _config: IBaseChartBuilderConfig = DEFAULT_CHART_BUILDER_CONFIG
  ) {}

  getLabel(config: IChartConfig): string {
    return config.labels.title;
  }

  getChart(
    results: IDCTransformResult[],
    config: IChartConfig
  ): dc.BaseMixin<dc.NumberDisplayWidget> | undefined {
    const result = first(results);
    if (!result) {
      return;
    }

    const chart = this._buildChart(config, result);
    applyBaseConfigToChart(this._config, chart);

    chart.label((data: unknown) =>
      String(get(data, 'key.label', get(data, 'key', '')))
    );

    return chart;
  }

  private _buildChart(
    config: IChartConfig,
    result: IDCTransformResult
  ): dc.NumberDisplayWidget {
    if (config.builderData.measures[0].filters?.length) {
      const { dimension } = ChartBuildHelpers.buildFilteredDimension(
        result,
        config,
        config.builderData.measures[0]
      );

      return dc
        .numberDisplay(D3_CHART_ELEM_SELECTOR)
        .formatNumber(d3.format('.0f'))
        .valueAccessor((data: unknown) => data)
        .dimension(dimension)
        .group(dimension.groupAll().reduceCount());
    }

    const chart = dc
      .numberDisplay(D3_CHART_ELEM_SELECTOR)
      .dimension(result.dimension)
      .group(result.group);

    const d3Formatter = getD3NumberFormatter(
      result.measure.metadata.formatter,
      result.measure.metadata.formatterValue
    );
    if (d3Formatter) {
      chart.formatNumber(d3Formatter);
    }
    return chart;
  }
}
