import { compact } from 'lodash';
import {
  type IChartedRef,
  type IToothRef,
} from '@principle-theorem/principle-core/interfaces';

export class SelectedSurfaceCollection {
  constructor(public surfaces: Partial<IChartedRef>[]) {}

  // TODO: Can we need to get rid of this now?
  toMultipleToothChartedRef(): Partial<IChartedRef> | undefined {
    const teeth: IToothRef[] = this.getTeeth();
    if (!teeth.length) {
      return;
    }
    const refs: IToothRef[] = teeth.map((tooth: IToothRef) => tooth);
    return {
      multipleTeeth: refs,
    };
  }

  getTeeth(): IToothRef[] {
    const teeth: (IToothRef | undefined)[] = this.surfaces.map(
      (surface: Partial<IChartedRef>) => {
        if (!surface.tooth) {
          return;
        }
        return {
          quadrant: surface.tooth.quadrant,
          quadrantIndex: surface.tooth.quadrantIndex,
        };
      }
    );
    return compact(teeth);
  }
}
