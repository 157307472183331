import { type DayOfWeek, type INamedDocument } from '@principle-theorem/shared';
import { type IPractice } from './practice/practice';
import { type IScheduleRange } from './schedule/schedule-time';

export type RosterBreak = IScheduleRange & { isBlocking?: boolean };

export interface IRosterShiftTimes {
  shift: IScheduleRange;
  break?: RosterBreak;
}

export interface IRosterTime extends IRosterShiftTimes {
  dayOfWeek: DayOfWeek;
}

export interface IRoster {
  default?: IRosterShiftTimes;
  days: IRosterTime[];
}

export interface IStafferSchedule extends IRoster, INamedDocument<IPractice> {}
