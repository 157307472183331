import { Injectable } from '@angular/core';
import {
  ISmartpayPurchaseRequestParams,
  type IInvoice,
  ISmartpayPurchaseTransactionExtendedData,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { ITransactionActionsData } from '../transaction-action';
import { type ITransactionAmountDialogResult } from '../transaction-components/transaction-amount-dialog/transaction-amount-dialog.component';
import { TransactionAmountDialog } from '../transaction-components/transaction-amount-dialog/transaction-amount-dialog.service';
import { type SmartpayTerminalFormData } from './select-smartpay-terminal/select-smartpay-terminal.component';

@Injectable()
export class SmartpayPurchaseBuilder {
  constructor(private _amountDialog: TransactionAmountDialog) {}

  buildTransaction(
    invoice: WithRef<IInvoice>,
    formData: ITransactionAmountDialogResult,
    terminalData: SmartpayTerminalFormData,
    requestType: ISmartpayPurchaseRequestParams['requestType']
  ): ISmartpayPurchaseRequestParams {
    const request: ISmartpayPurchaseRequestParams = {
      providerId: terminalData.uid,
      providerName: terminalData.name,
      practiceRef: invoice.practice.ref,
      amount: formData.amount,
      terminal: terminalData,
      requestType,
    };
    return request;
  }

  async openCardPurchaseForm(
    invoice: WithRef<IInvoice>
  ): Promise<ITransactionAmountDialogResult | undefined> {
    return this._amountDialog.openForInvoice(
      'Smartpay - EFTPOS Payment',
      invoice
    );
  }

  async openQRPurchaseForm(
    invoice: WithRef<IInvoice>
  ): Promise<ITransactionAmountDialogResult | undefined> {
    return this._amountDialog.openForInvoice('Smartpay - QR Payment', invoice);
  }

  async openCardRefundForm(
    actionsData: ITransactionActionsData<ISmartpayPurchaseTransactionExtendedData>
  ): Promise<ITransactionAmountDialogResult | undefined> {
    return this._amountDialog.openForRefund(
      'Smartpay - EFTPOS Refund',
      actionsData
    );
  }

  async openQRRefundForm(
    actionsData: ITransactionActionsData<ISmartpayPurchaseTransactionExtendedData>
  ): Promise<ITransactionAmountDialogResult | undefined> {
    return this._amountDialog.openForRefund(
      'Smartpay - QR Refund',
      actionsData
    );
  }
}
