import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  TypedAbstractControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { IDVACard } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-patient-details-dva-form',
    templateUrl: './patient-details-dva-form.component.html',
    styleUrl: './patient-details-dva-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientDetailsDvaFormComponent {
  dvaForm$ = new ReplaySubject<TypedFormGroup<IDVACard>>(1);

  @Input()
  set form(form: TypedAbstractControl<IDVACard>) {
    if (form) {
      this.dvaForm$.next(form as TypedFormGroup<IDVACard>);
    }
  }
}
