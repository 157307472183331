<div class="m-2 flex flex-col gap-2">
  <pr-account-summary-datapoint
    description="Treatments"
    [value]="summary.invoiceSummary.treatments"
    tooltip="Sum of Treatments on all Issued, Paid and Written Off Invoices"
  />
  <pr-account-summary-datapoint
    description="Products"
    [value]="summary.invoiceSummary.products"
    tooltip="Sum of Products on all Issued, Paid and Written Off Invoices"
  />
  <pr-account-summary-datapoint
    description="Fees (Cancellation)"
    [value]="summary.invoiceSummary.fees"
    tooltip="Sum of Fees (Cancellation) on all Issued, Paid and Written Off Invoices"
  />
  <pr-account-summary-datapoint
    description="Subtotal"
    [value]="summary.invoiceSummary.subtotal"
    tooltip="Treatment + Products + Fees (Cancellation)"
    class="subtotal"
  />

  <mat-divider />

  <pr-account-summary-datapoint
    description="Deposits"
    [value]="summary.invoiceSummary.deposits"
    tooltip="Deposits that have been invoiced"
  />
  <pr-account-summary-datapoint
    description="Total Invoiced"
    [value]="summary.invoiceSummary.totalInvoiced"
    tooltip="Subtotal + Deposits"
    class="subtotal"
  />

  <mat-divider />

  <pr-account-summary-datapoint
    description="Discounts"
    [value]="summary.paymentSummary.discounts"
    tooltip="Discounts applied to invoices after issue"
  />
  <pr-account-summary-datapoint
    description="Credit Used"
    [value]="summary.paymentSummary.creditUsed"
    tooltip="Credit used to pay for invoices"
  />
  <pr-account-summary-datapoint
    description="Written off"
    [value]="summary.paymentSummary.writtenOff"
    tooltip="Unpaid remainder of written off invoices"
  />
  <pr-account-summary-datapoint
    description="Total Receivable"
    [value]="summary.paymentSummary.totalReceivable"
    tooltip="Total Invoiced - Discounts - Written Off - Credits Used"
    class="subtotal"
  />

  <mat-divider />

  <pr-account-summary-datapoint
    description="Payments Recieved"
    [value]="summary.paymentSummary.paymentsReceived"
    tooltip="Sum of all payment methods. Excluding Discounts & Account Credits"
  />
  <pr-account-summary-datapoint
    description="Outstanding"
    [value]="summary.paymentSummary.outstanding"
    tooltip="Total Receivable - Payments Received"
    class="total red"
  />
</div>
