<mat-form-field [appearance]="appearance" subscriptSizing="dynamic">
  <mat-label>{{ label }}</mat-label>
  <mat-select [formControl]="rangeControl" #select="matSelect">
    <mat-option
      *ngFor="let range of ranges; trackBy: trackByRange"
      [value]="range"
    >
      {{ range.title }}
    </mat-option>
  </mat-select>
  <button
    mat-icon-button
    matIconSuffix
    (click)="$event.stopImmediatePropagation(); rangeReset.emit()"
    *ngIf="!select.empty"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-form-field>
