<div class="layout-margin">
  <div fxLayout="column" fxLayoutAlign="start start">
    <content-loader viewBox="0 0 500 18">
      <svg:rect x="2" y="6" rx="3" ry="3" width="10" height="10" />
      <svg:rect x="20" y="6" rx="3" ry="3" width="15" height="10" />
      <svg:rect x="82" y="6" rx="3" ry="3" width="20" height="10" />
      <svg:rect x="145" y="6" rx="3" ry="3" width="32" height="10" />
      <svg:rect x="205" y="6" rx="3" ry="3" width="28" height="10" />
      <svg:rect x="270" y="6" rx="3" ry="3" width="23" height="10" />
      <svg:rect x="332" y="6" rx="3" ry="3" width="35" height="10" />
    </content-loader>
    <mat-divider />
    <ng-container *ngFor="let row of rows; trackBy: trackByIndex">
      <content-loader viewBox="0 0 500 18">
        <svg:rect x="2" y="6" rx="3" ry="3" width="10" height="10" />
        <svg:rect x="20" y="6" rx="3" ry="3" width="40" height="10" />
        <svg:rect x="82" y="6" rx="3" ry="3" width="30" height="10" />
        <svg:rect x="145" y="6" rx="3" ry="3" width="40" height="10" />
        <svg:rect x="205" y="6" rx="3" ry="3" width="30" height="10" />
        <svg:rect x="270" y="6" rx="3" ry="3" width="25" height="10" />
        <svg:rect x="332" y="6" rx="3" ry="3" width="60" height="10" />
      </content-loader>
      <mat-divider />
    </ng-container>
  </div>
</div>
