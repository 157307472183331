import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditorPresetsService } from '@principle-theorem/ng-interactions';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogData,
  type IConfirmationDialogInput,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { type IOrganisation } from '@principle-theorem/principle-core/interfaces';
import { Organisation } from '@principle-theorem/principle-core';
import {
  shareReplayCold,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { BehaviorSubject, type Observable, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { Migrator } from '../migrations/migrator';
import { ManagementService } from '@principle-theorem/ng-principle-shared';

export class LogItem {
  time: moment.Moment = moment();
  constructor(public message: string) {}
}

@Component({
    selector: 'pr-migration-runner',
    templateUrl: './migration-runner.component.html',
    styleUrls: ['./migration-runner.component.scss'],
    exportAs: 'prMigrationRunner',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MigrationRunnerComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByOrganisation = TrackByFunctions.ref<WithRef<IOrganisation>>();
  trackByLog = TrackByFunctions.date<LogItem>('time');
  isMigrating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  log$: BehaviorSubject<LogItem[]> = new BehaviorSubject<LogItem[]>([]);
  organisations$: Observable<WithRef<IOrganisation>[]>;
  organisationsControl = new TypedFormControl<WithRef<IOrganisation>[]>([]);

  constructor(
    private _management: ManagementService,
    private _editorPresets: EditorPresetsService,
    private _dialog: MatDialog
  ) {
    this.isMigrating$
      .pipe(skip(1), takeUntil(this._onDestroy$))
      .subscribe((migrating: boolean) => this._logIsMigrating(migrating));

    this.organisations$ = Organisation.all$().pipe(shareReplayCold());
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async selectAll(): Promise<void> {
    this.organisationsControl.setValue(await snapshot(this.organisations$));
  }

  async migrate(): Promise<void> {
    const data: IConfirmationDialogData = confirmationDialogData({
      title: 'Run Migration as a dry run?',
      prompt:
        'Do you want to run a dry run to test the migration? No data will be saved.',
      submitLabel: 'Yes',
      cancelLabel: 'No, run the migration',
    });
    const dryRun = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (dryRun === undefined) {
      return;
    }

    const migrator: Migrator = new Migrator(
      this._management,
      this._editorPresets,
      dryRun
    );
    migrator.logger$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((event: string) => {
        this._logEvent(event);
      });
    this.isMigrating$.next(true);
    await migrator.migrateWithEvents();
    this.isMigrating$.next(false);
  }

  private _logEvent(message: string): void {
    this.log$.next([...this.log$.value, new LogItem(message)]);
  }

  private _logIsMigrating(isMigrating: boolean): void {
    const message: string = isMigrating
      ? 'Migration Started'
      : 'Migration Complete';
    this.log$.next([
      ...this.log$.value,
      new LogItem(`---------- ${message} ----------`),
    ]);
  }
}
