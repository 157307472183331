import { map } from 'rxjs/operators';
import { isWithId, isWithRef } from './firebase/firestore/document';
import { IReffable, ITimestamps, WithId, WithRef } from './firebase/interfaces';
import { v4 as uuid } from 'uuid';
import { OperatorFunction } from 'rxjs';
import { multiMap } from './rxjs';

export type EntityModel<T extends object> = WithId<T> &
  Partial<IReffable<T>> &
  Partial<ITimestamps>;

export function toEntityModel<T extends object>(
  model: WithRef<T> | T
): EntityModel<T> {
  if (isWithId(model)) {
    return model;
  }
  if (isWithRef(model)) {
    return { uid: model.ref.id, ...model };
  }
  return { uid: uuid(), ...model };
}

export function toEntityModel$<T extends object>(): OperatorFunction<
  T,
  EntityModel<T>
> {
  return map(toEntityModel);
}

export function toEntityModels$<T extends object>(): OperatorFunction<
  T[],
  EntityModel<T>[]
> {
  return multiMap(toEntityModel);
}
