import {
  AUTOMATION_GRACE_PERIOD,
  AutomationCreator,
  AutomationStatus,
  IAutomatedNotificationConfiguration,
  IAutomationConfiguration,
  IGeneratedTaskConfiguration,
  TimingDirection,
  TreatmentStepCollection,
  isAutomatedNotificationConfiguration,
  isGeneratedTaskConfiguration,
  type IAppointment,
  type IAutomatedNotification,
  type IAutomation,
  type IAutomationResource,
  type IBrand,
  type IEvent,
  type IGeneratedTask,
  type IPatient,
  type ITreatmentPlan,
  type ITreatmentStep,
  type TreatmentStepAutomation,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  RequireProps,
  asDocRef,
  collectionGroupQuery,
  isSameRef,
  multiConcatMap,
  multiFilter,
  query$,
  toMomentTz,
  toNamedDocument,
  where,
  type AtLeast,
  type INamedTypeDocument,
  type IReffable,
  type Timestamp,
  type Timezone,
  type WithRef,
} from '@principle-theorem/shared';
import { pick } from 'lodash';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TreatmentStep } from '../clinical-charting/treatment/treatment-step';
import { AutomatedNotification } from '../notification';
import { OrganisationCache } from '../organisation/organisation-cache';
import { GeneratedTask } from '../task/generated-task';
import { AutomationConfiguration } from './automation-configuration';
import { AutomationTiming } from './automation-timing';

export class Automation {
  static init<Resource extends IAutomationResource>(
    overrides: AtLeast<
      IAutomation<Resource>,
      'type' | 'data' | 'creator' | 'triggerDate' | 'brandRef'
    >
  ): IAutomation<Resource> {
    return {
      status: AutomationStatus.Pending,
      ...overrides,
      creator: pick(overrides.creator, ['name', 'ref', 'type']),
      cloudTaskHistory: [],
    };
  }

  static automations$(
    brand: IReffable<IBrand>,
    dateFrom: Timestamp,
    dateTo: Timestamp
  ): Observable<WithRef<IAutomation<TreatmentStepAutomation>>[]> {
    return query$(
      collectionGroupQuery(TreatmentStepCollection.Automations),
      where('triggerDate', '>=', dateFrom),
      where('triggerDate', '<=', dateTo),
      where('brandRef', '==', brand.ref)
    );
  }

  static getAutomations$(
    brand: WithRef<IBrand>,
    event: IEvent
  ): Observable<IAutomation<IAutomatedNotification | IGeneratedTask>[]> {
    return combineLatest([
      Automation.getNotificationAutomations$(brand, event),
      Automation.getGeneratedTaskAutomations$(brand, event),
    ]).pipe(map(([notifications, tasks]) => [...notifications, ...tasks]));
  }

  static async generateForAppointment(
    config: WithRef<
      | IAutomationConfiguration
      | IAutomatedNotificationConfiguration
      | IGeneratedTaskConfiguration
    >,
    appointment: WithRef<RequireProps<IAppointment, 'event'>>,
    treatmentStep: WithRef<ITreatmentStep>
  ): Promise<IAutomation<IAutomatedNotification | IGeneratedTask> | undefined> {
    if (!AutomationConfiguration.isActive(config)) {
      return;
    }
    const brand = await OrganisationCache.brands.getDoc(
      Firestore.getParentDocRef<IBrand>(config.ref)
    );
    const practice = config.practiceRef
      ? await OrganisationCache.practices.getDoc(config.practiceRef)
      : undefined;

    if (
      !AutomationConfiguration.isForTreatmentStep(config, treatmentStep) ||
      !AutomationConfiguration.isForPractice(config, appointment.practice.ref)
    ) {
      return;
    }

    const matchingTreatmentConfigRef = treatmentStep.treatments
      .map((treatment) => treatment.config.ref)
      .find((treatmentConfigRef) =>
        config.treatmentRefs.some((treatmentRef) =>
          isSameRef(treatmentConfigRef, treatmentRef)
        )
      );

    const treatmentConfig = matchingTreatmentConfigRef
      ? await Firestore.getDoc(matchingTreatmentConfigRef)
      : undefined;

    const creator: INamedTypeDocument =
      config.treatmentRefs.length && treatmentConfig
        ? {
            ...toNamedDocument(treatmentConfig),
            type: AutomationCreator.TreatmentConfiguration,
          }
        : practice
          ? {
              ...toNamedDocument(practice),
              type: AutomationCreator.Practice,
            }
          : {
              ...toNamedDocument(brand),
              type: AutomationCreator.Brand,
            };

    if (isAutomatedNotificationConfiguration(config)) {
      return AutomatedNotification.generateFromConfig(
        config as WithRef<IAutomatedNotificationConfiguration>,
        creator,
        appointment.event,
        brand.ref
      );
    }

    if (isGeneratedTaskConfiguration(config)) {
      return GeneratedTask.generateFromConfig(
        config as WithRef<IGeneratedTaskConfiguration>,
        creator,
        appointment.event,
        brand.ref
      );
    }
  }

  static getNotificationAutomations$(
    brand: WithRef<IBrand>,
    event: IEvent
  ): Observable<IAutomation<IAutomatedNotification>[]> {
    return AutomationConfiguration.getNotificationConfigurations$(brand).pipe(
      multiFilter(
        (config) =>
          config.isActive &&
          AutomationConfiguration.isGlobal(config) &&
          AutomationConfiguration.isForPractice(config, event.practice.ref)
      ),
      multiConcatMap((notification) => {
        let creator: INamedTypeDocument = {
          ...toNamedDocument(brand),
          type: AutomationCreator.Brand,
        };
        if (notification.practiceRef) {
          creator = {
            ...event.practice,
            type: AutomationCreator.Practice,
          };
        }

        return AutomatedNotification.generateFromConfig(
          notification,
          creator,
          event,
          brand.ref
        );
      })
    );
  }

  static getGeneratedTaskAutomations$(
    brand: WithRef<IBrand>,
    event: IEvent
  ): Observable<IAutomation<IGeneratedTask>[]> {
    return AutomationConfiguration.getGeneratedTaskConfigurations$(brand).pipe(
      multiFilter(
        (config) =>
          config.isActive &&
          AutomationConfiguration.isGlobal(config) &&
          AutomationConfiguration.isForPractice(config, event.practice.ref)
      ),
      multiConcatMap((task) => {
        let creator: INamedTypeDocument = {
          ...toNamedDocument(brand),
          type: AutomationCreator.Brand,
        };

        if (task.practiceRef) {
          creator = {
            ...event.practice,
            type: AutomationCreator.Practice,
          };
        }

        return GeneratedTask.generateFromConfig(
          task,
          creator,
          event,
          brand.ref
        );
      })
    );
  }

  static resolvePatient$<Resource extends IAutomationResource>(
    automation: WithRef<IAutomation<Resource>>
  ): Observable<WithRef<IPatient>> {
    const treatmentStepRef = Firestore.getParentDocRef<ITreatmentStep>(
      automation.ref
    );
    const treatmentPlanRef =
      Firestore.getParentDocRef<ITreatmentPlan>(treatmentStepRef);
    const patient = asDocRef<IPatient>(
      Firestore.getParentDocRef(treatmentPlanRef)
    );
    return OrganisationCache.patients.doc$(patient);
  }

  static resolveAppointment$<Resource extends IAutomationResource>(
    automation: WithRef<IAutomation<Resource>>
  ): Observable<WithRef<IAppointment> | undefined> {
    return Firestore.doc$(
      asDocRef<ITreatmentStep>(Firestore.getParentDocRef(automation.ref))
    ).pipe(switchMap((step) => TreatmentStep.appointment$(step)));
  }

  static async resolveAppointment<Resource extends IAutomationResource>(
    automation: WithRef<IAutomation<Resource>>
  ): Promise<WithRef<IAppointment> | undefined> {
    const treatmentStepRef = asDocRef<ITreatmentStep>(
      Firestore.getParentDocRef(automation.ref)
    );
    const treatmentStep = await Firestore.getDoc(treatmentStepRef);
    return TreatmentStep.appointment(treatmentStep);
  }

  static isGoingToRun<T extends IAutomationResource>(
    automation: AtLeast<IAutomation<T>, 'status'>
  ): boolean {
    const willNotRun = [AutomationStatus.Disabled, AutomationStatus.Cancelled];
    if (willNotRun.includes(automation.status)) {
      return false;
    }
    if (this.hasAlreadyRun(automation)) {
      return false;
    }
    return true;
  }

  static hasAlreadyRun<T extends IAutomationResource>(
    automation: AtLeast<IAutomation<T>, 'status'>
  ): boolean {
    const hasAlreadyRun = [
      AutomationStatus.Completed,
      AutomationStatus.Failed,
      AutomationStatus.Skipped,
    ];
    return hasAlreadyRun.includes(automation.status);
  }

  static isReadyForQueue<T extends IAutomationResource>(
    automation: WithRef<IAutomation<T>>
  ): boolean {
    if (automation.data.timing.direction === TimingDirection.After) {
      const hasTriggerAfterDate = !!automation.triggerAfterDate;
      return hasTriggerAfterDate;
    }
    return true;
  }

  static shouldUpsertCloudTask(
    automation: WithRef<IAutomation<TreatmentStepAutomation>>
  ): boolean {
    return !this.hasAlreadyRun(automation) && this.isReadyForQueue(automation);
  }

  static getExpectedTriggerDate(
    automation: IAutomation<IAutomationResource>,
    event: IEvent,
    timezone: Timezone
  ): Moment {
    return toMomentTz(
      AutomationTiming.getTriggerDateFromEvent(
        event,
        automation.data.timing,
        timezone
      ),
      timezone
    );
  }

  static applyGracePeriod(
    expectedTriggerDate: Moment,
    timezone: Timezone,
    currentDateTime: Moment = moment()
  ): Moment {
    const now = currentDateTime.tz(timezone);
    const runImmediately = now.clone().add(AUTOMATION_GRACE_PERIOD);
    return expectedTriggerDate.isSameOrBefore(runImmediately)
      ? runImmediately
      : expectedTriggerDate;
  }

  static async setTriggerAfterDate(
    automation: WithRef<IAutomation<IAutomationResource>>,
    completedAt?: Timestamp
  ): Promise<void> {
    const resetToPending = Automation.isGoingToRun(automation) && !completedAt;
    await Firestore.saveDoc({
      ...automation,
      status: resetToPending ? AutomationStatus.Pending : automation.status,
      triggerAfterDate: completedAt,
    });
  }
}
