import {
  ITranslationMap,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  type AtLeast,
  isSameRef,
  type IXSLXExport,
  type WithRef,
  type XSLXCell,
} from '@principle-theorem/shared';
import type { Column } from 'exceljs';
import { sortBy } from 'lodash';
import { ICorePracticeCalendar } from '../../source/entities/calendars';

export interface IAppointmentBookToPractitionerXSLX {
  id: string;
  name: string;
  mapTo: string;
}

export const APPOINTMENT_BOOK_TO_PRACTITIONER_HEADERS: AtLeast<
  Column,
  'key' | 'header'
>[] = [
  {
    key: 'id',
    header: 'Id',
  },
  {
    key: 'name',
    header: 'Book Name',
    width: 30,
  },
  {
    key: 'mapTo',
    header: 'Map To',
    width: 30,
  },
];

export class AppointmentBookToPractitionerToXSLX
  implements
    IXSLXExport<ICorePracticeCalendar, IAppointmentBookToPractitionerXSLX>
{
  headers = APPOINTMENT_BOOK_TO_PRACTITIONER_HEADERS;

  constructor(
    private _staff: WithRef<IStaffer>[],
    private _existingMappings: ITranslationMap<IStaffer>[]
  ) {}

  translate(
    records: ICorePracticeCalendar[]
  ): Record<keyof IAppointmentBookToPractitionerXSLX, XSLXCell>[] {
    const stafferOptions = sortBy(this._staff, 'user.name').map(
      (staffer) => staffer.user.name
    );
    return records.map((record) => {
      const existingRecord = this._existingMappings.find(
        (existingMapping) =>
          existingMapping.sourceIdentifier === record.id.toString()
      );

      const associatedValue = existingRecord?.destinationIdentifier;
      const mappedStaffer = associatedValue
        ? this._staff.find((staffer) => isSameRef(staffer, associatedValue))
        : undefined;

      const existingValue = mappedStaffer ? mappedStaffer.user.name : undefined;

      return {
        id: {
          value: record.id,
        },
        name: {
          value: record.description,
        },
        mapTo: {
          value: existingValue,
          dataValidation: {
            type: 'list',
            formulae: [`"${stafferOptions.join(',')}"`],
          },
        },
      };
    });
  }
}
