import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard } from '@principle-theorem/shared';
import { flow, isBoolean, isNull, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { cleanObjectStrings } from './lib/conversion-helpers';

export const PATIENT_HEALTH_FUND_RESOURCE_TYPE = 'patientHealthFund';

export const PATIENT_HEALTH_FUND_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Health Fund List',
      description:
        'Note that patients_hf.csv will need have the column name "default" changed to "is_default" as this is a reserved word.',
      idPrefix: PATIENT_HEALTH_FUND_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export enum D4WDVACardType {
  NONE = 0,
  GOLD = 1,
  WHITE = 2,
}

export interface ID4WPatientHealthFund {
  fund_id: number;
  patient_id: number;
  fund_name: string;
  fund_code: string;
  is_dva: boolean;
  membership_number: string;
  member_number: number | null;
  expiry_date: string;
  dva_card_type: D4WDVACardType | null;
  is_default: boolean | null;
}

function isD4WPatientHealthFund(item: unknown): item is ID4WPatientHealthFund {
  return TypeGuard.interface<ID4WPatientHealthFund>({
    fund_id: isNumber,
    fund_name: isString,
    fund_code: isString,
    patient_id: isNumber,
    is_dva: isBoolean,
    membership_number: isString,
    member_number: [isNumber, isNull],
    expiry_date: isString,
    dva_card_type: [TypeGuard.enumValue(D4WDVACardType), isNull],
    is_default: [isBoolean, isNull],
  })(item);
}

export interface ID4WPatientHealthFundFilters {
  patientId: string;
}

const PATIENT_HEALTH_FUND_SOURCE_QUERY = `
SELECT
  fund.fund_name,
  fund.fund_code,
  fund.is_dva,
  patient_fund_details.patient_id,
  patient_fund_details.fund_id,
  patient_fund_details.membership_number,
  patient_fund_details.member_number,
  patient_fund_details.expiry_date,
  patient_fund_details.dva_card_type,
  patient_fund_details.is_default
FROM (
  SELECT
    hf_plan_id AS fund_id,
    patient_id,
    hf_member_code AS membership_number,
    subscriber_id,
    prel_id,
    date_start,
    date_end AS expiry_date,
    priority,
    hf_plan_series AS member_number,
    convert_to_boolean(is_default) AS is_default,
    NULLIF(card_type, '0') AS dva_card_type,
    med_cond_id
  FROM patients_hf
) AS patient_fund_details
LEFT JOIN (
  SELECT
    hf_plan_id,
    hf_id,
    hf_plan_name AS fund_name,
    hf_plan_code AS fund_code,
    CASE
      WHEN hf_plan_code = 'VAA' THEN TRUE ELSE FALSE
    END AS is_dva
  FROM hf_plans
) as fund
ON patient_fund_details.fund_id = fund.hf_plan_id
WHERE patient_id IS NOT NULL
`;

export class PatientHealthFundSourceEntity extends BaseSourceEntity<
  ID4WPatientHealthFund,
  unknown,
  ID4WPatientHealthFundFilters
> {
  sourceEntity = PATIENT_HEALTH_FUND_SOURCE_ENTITY;
  entityResourceType = PATIENT_HEALTH_FUND_RESOURCE_TYPE;
  sourceQuery = PATIENT_HEALTH_FUND_SOURCE_QUERY;
  verifySourceFn = isD4WPatientHealthFund;

  override transformDataFn = flow([
    (rows: ID4WPatientHealthFund[]) => rows.map(cleanObjectStrings),
  ]);

  translate(_data: ID4WPatientHealthFund): object {
    return {};
  }

  getFilterData(data: ID4WPatientHealthFund): ID4WPatientHealthFundFilters {
    return {
      patientId: data.patient_id.toString(),
    };
  }

  getSourceRecordId(data: ID4WPatientHealthFund): string {
    return `${data.fund_id}-${data.patient_id}`;
  }

  getSourceLabel(data: ID4WPatientHealthFund): string {
    return `${data.fund_id} - ${data.patient_id}`;
  }
}
