import { BlockNodes, IMenuButton } from '@principle-theorem/editor';
import { MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { VideoEmbedMenuComponent } from './video-embed-menu/video-embed-menu.component';

export function createVideoEmbedBlockMenuButton(): MenuButtonLoaderFn<
  VideoEmbedMenuComponent,
  IMenuButton
> {
  return () => ({
    component: VideoEmbedMenuComponent,
    data: {
      buttonType: 'block',
      buttonText: 'Embed Video',
      tooltip: 'Embed Video',
      icon: 'movie',
      isActiveFn: (editor) => editor.isActive(BlockNodes.VideoEmbed),
      command: () => {
        //
      },
    },
  });
}
