<mat-toolbar color="accent" class="!flex flex-row items-center gap-4 !pb-0">
  <span class="flex-1">Select Surfaces for {{ data.label }}</span>

  <div *ngIf="packages$ | async as packages">
    <ng-container *ngIf="packages.length">
      <mat-button-toggle-group
        [hideSingleSelectionIndicator]="true"
        (change)="updateSelectedPackage($event.value)"
      >
        <mat-button-toggle
          matTooltip="Package Selected"
          #packageButton
          class="package-item"
          *ngFor="let package of packages; trackBy: trackByPackage"
          [value]="package"
          [checked]="package.uid | map: isSelectedPackage$ : this | async"
          tabindex="-1"
        >
          <mat-icon
            *ngIf="packageButton.checked"
            class="package-icon"
            [ngClass]="{
              completed: package.uid | map: isPackageCompleted$ : this | async
            }"
            >check_circle</mat-icon
          >
          <span>{{ package.name }}</span>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </ng-container>
  </div>
  <span class="price">{{ price$ | async | currency }}</span>
</mat-toolbar>

<mat-dialog-content>
  <div class="flex flex-row gap-4" *ngrxLet="steps$ as steps">
    <div class="flex-1 basis-3/5">
      <pr-clinical-chart [stacked]="isStacked$ | async" />
    </div>
    <div class="steps flex-1 basis-2/5 p-4" *ngIf="steps.length">
      <div class="flex-col">
        <ng-container *ngrxLet="selected$ as selected">
          <div
            *ngFor="
              let step of steps;
              let stepIndex = index;
              trackBy: trackByStep
            "
            class="step"
            [ngClass]="{
              'active-step': selected ? selected.stepIndex === stepIndex : false
            }"
          >
            <div class="flex items-center justify-between gap-2">
              <div class="step-name">{{ step.step.name }}</div>
            </div>
            <div class="flex flex-col gap-2">
              <mat-card
                appearance="outlined"
                *ngFor="
                  let treatment of step.treatments;
                  let treatmentIndex = index;
                  trackBy: trackByTreatment
                "
                class="treatment flex-1"
                [ngClass]="{
                  disabled: treatment.disabled,
                  'active-treatment': selected
                    ? selected.treatmentIndex === treatmentIndex
                    : false,
                  completed:
                    treatment.disabled ||
                    treatment.chartedSurfaces.length >= treatment.quantity
                }"
                (click)="
                  selectTreatment(treatment.disabled, stepIndex, treatmentIndex)
                "
              >
                <mat-card-content class="!p-0">
                  <div class="flex items-center">
                    <div class="w-7">
                      <mat-checkbox
                        #checkbox="matCheckbox"
                        color="primary"
                        *ngIf="!treatment.disabled"
                        [checked]="
                          stepIndex
                            | pairMap: treatmentIndex : isSelected$ : this
                            | async
                        "
                        (click)="$event.stopPropagation()"
                        (change)="
                          updateMultiSelect(
                            stepIndex,
                            treatmentIndex,
                            checkbox.checked
                          )
                        "
                      />
                    </div>

                    <mat-icon
                      *ngIf="treatment.treatment.display?.icon?.slug"
                      class="pr-1"
                      [ngStyle]="{
                        color: treatment.treatment.display.colour.value
                      }"
                      [svgIcon]="treatment.treatment.display.icon.slug"
                    />

                    <div class="flex flex-1 flex-col">
                      <div>
                        {{ treatment.quantity }} x
                        {{ treatment.treatment.name }}
                      </div>

                      <div class="flex items-center justify-between">
                        <div class="flex">
                          <pr-charted-items-surface-list
                            class="flex-1"
                            [chartedSurfaces]="treatment.chartedSurfaces"
                            [availableSurfaces]="
                              treatment.treatment.availableSurfaces
                            "
                          />
                          <button
                            *ngIf="!treatment.disabled"
                            mat-icon-button
                            [disabled]="!treatment.chartedSurfaces.length"
                            (click)="resetSurfaces(stepIndex, treatmentIndex)"
                            matTooltip="Clear Surfaces"
                          >
                            <mat-icon>restart_alt</mat-icon>
                          </button>
                        </div>
                        <span class="price">{{
                          treatment.price | currency
                        }}</span>
                      </div>

                      <pr-service-item
                        *ngFor="
                          let serviceCode of treatment | map: getServiceCodes;
                          trackBy: trackByCode
                        "
                        [serviceCode]="serviceCode"
                        [compact]="true"
                        (click)="$event.stopPropagation()"
                        (itemUpdate)="
                          updateServiceCode(
                            serviceCode.uuid,
                            $event,
                            stepIndex,
                            treatmentIndex
                          )
                        "
                      />
                    </div>

                    <mat-icon
                      *ngIf="treatment.treatment.display?.icon?.slug"
                      class="check-icon"
                      >check_circle
                    </mat-icon>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="save()"
      [disabled]="(allTreatmentsCharted$ | async) === false"
    >
      Chart
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
