import { Pipe, type PipeTransform } from '@angular/core';
import {
  SerialisedData,
  UnserialisedData,
  unserialise,
} from '@principle-theorem/shared';

@Pipe({
  name: 'unserialise',
})
export class UnserialisePipe implements PipeTransform {
  transform<T>(value: SerialisedData<T>): UnserialisedData<T> {
    return unserialise(value);
  }
}
