import type { default as HandsonTable } from 'handsontable';
import { isArray, last, max } from 'lodash';
import { type MonoTypeOperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export const HANDS_ON_TABLE_LICENSE = 'non-commercial-and-evaluation';

export const addLicense: MonoTypeOperatorFunction<HandsonTable.GridSettings> =
  map((settings) => ({ ...settings, licenseKey: HANDS_ON_TABLE_LICENSE }));

export function getNumberOfColumns(
  data?: HandsonTable.CellValue[][] | HandsonTable.RowObject[]
): number {
  const lengths = (data || []).map((row) =>
    isArray(row) ? row.length : Object.values(row).length
  );
  return max(lengths) || 0;
}

export function setColumnWidths(numColumns: number, width: number): number[] {
  return new Array<number>(numColumns).fill(width);
}

export function reduceCells<T, R>(
  isSameAsLastFn: SameAsLastFn<R>,
  incrementFn: IncrementFn<T>,
  newResultFn: NewResultFn<T, R>
): (results: T[], current: R, index: number, all: R[]) => T[] {
  return (results: T[], current: R, index: number, all: R[]) => {
    const previousResult = last(results);
    const previousValue = all[index - 1];
    if (previousResult && isSameAsLastFn(current, previousValue)) {
      incrementFn(previousResult);
      return results;
    }
    return [...results, newResultFn(current, index)];
  };
}

export type SameAsLastFn<T> = (a: T, b: T | undefined) => boolean;
export type IncrementFn<T> = (value: T) => void;
export type NewResultFn<T, R> = (current: R, index: number) => T;
