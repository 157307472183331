import { ScheduleModifierOption } from '@principle-theorem/principle-core/interfaces';
import { ITimePeriod, toMoment } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';

export class ScheduleModifier {
  static modifiesRange(
    modifier: ScheduleModifierOption,
    range: ITimePeriod
  ): boolean {
    return modifier.dates.some((date) =>
      toMoment(date).isBetween(range.from, range.to, undefined, '[]')
    );
  }

  static reduceModifiersByType(
    modifiers: ScheduleModifierOption[]
  ): ScheduleModifierOption[] {
    return modifiers.reduce((acc: ScheduleModifierOption[], current) => {
      const existing = acc.find((modifier) => modifier.type === current.type);
      if (!existing) {
        return [...acc, current];
      }
      existing.dates = [...existing.dates, ...current.dates];
      return acc;
    }, []);
  }

  static applyModifiers(
    dates: moment.Moment[],
    modifiers: ScheduleModifierOption[]
  ): moment.Moment[] {
    return dates.filter((date) => {
      const isWithinModifiers = modifiers.some((modifier) =>
        modifier.dates
          .map((modifierDate) => toMoment(modifierDate))
          .some((modifierDate) => date.isSame(modifierDate, 'day'))
      );
      return !isWithinModifiers;
    });
  }
}
