<mat-toolbar color="accent">Interaction Amendment History</mat-toolbar>

<mat-dialog-content>
  <pr-interaction-amendment-history
    fxFlex
    [amendmentHistory]="amendmentHistory$ | ngrxPush"
    [selectedInteraction]="selectedInteraction$ | ngrxPush"
   />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
