<div class="timeline flex flex-col">
  <div class="timeline-heading">
    <h2 class="mat-headline-4">Interaction Timeline</h2>
    <div class="timeline-filters flex items-center">
      <div class="mat-subtitle-1">Filters:</div>

      @for (eventType of eventTypes; track eventType) {
        <button
          mat-icon-button
          class="filter-button {{ eventType }}"
          [ngClass]="{
            selected: (eventType | map: isFilterSelected$ : this | ngrxPush)
          }"
          [matTooltip]="eventType"
          matTooltipPosition="below"
          (click)="toggleFilter(eventType)"
        >
          <mat-icon>{{ eventType | map: interactionType }}</mat-icon>
        </button>
      }

      <span class="flex-1"></span>

      <pr-tag-filter
        [tags]="tags$ | async"
        [selectedTags]="selectedTags$ | async"
        (selectionChange)="updateSelectedTags($event)"
      />
    </div>
  </div>

  <div class="timeline-content flex-1">
    @if (pinnedInteractions$ | async; as pinnedInteractions) {
      @if (pinnedInteractions.length) {
        <div class="timeline-heading">
          <div class="mat-headline-6">Pinned Patient Notes</div>
        </div>
        @for (interaction of pinnedInteractions; track interaction.uid) {
          <pr-interaction-timeline-event
            [interaction]="interaction"
            [fullDate]="true"
          />
        }
      }
    }

    @for (day of interactionsByDay$ | async; track day.group.seconds) {
      @defer (on viewport) {
        <div class="timeline-heading">
          <div class="mat-headline-6">
            {{ day.group | moment | amDateFormat: dateFormat }}
          </div>
          @for (interaction of day.items; track interaction.uid) {
            <pr-interaction-timeline-event [interaction]="interaction" />
          }
        </div>
      } @placeholder (minimum 500ms) {
        <ngx-skeleton-loader />
      }
    }
  </div>
</div>
