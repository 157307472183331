import { FeatureVisibility, IFeature } from '@principle-theorem/feature-flags';

export enum HicapsConnectPermissions {}

export const HICAPS_CONNECT_FEATURE: IFeature = {
  label: 'hicaps-connect',
  visibility: FeatureVisibility.Alpha,
  permissions: [],
};

export const HICAPS_CONNECT_TESTING_FEATURE: IFeature = {
  label: 'hicaps-connect-testing',
  visibility: FeatureVisibility.Dev,
  permissions: [],
};
