import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  type ITimePeriod,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AutomationsFacade } from '../../store/facades/automations.facade';

@Component({
  selector: 'pr-brand-automation-list',
  templateUrl: './brand-automation-list.component.html',
  styleUrls: ['./brand-automation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandAutomationListComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  dateRange$ = new BehaviorSubject<ITimePeriod>({
    from: moment().startOf('day'),
    to: moment().add(1, 'week').startOf('day'),
  });
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);
  brand$: Observable<WithRef<IBrand>>;

  constructor(
    private _currentScope: CurrentScopeFacade,
    private _automationsFacade: AutomationsFacade
  ) {
    this.brand$ = this._currentScope.currentBrand$.pipe(filterUndefined());
    this._currentScope.currentPractice$
      .pipe(filterUndefined(), take(1), takeUntil(this._onDestroy$))
      .subscribe((practice) => this.practice$.next(practice));

    combineLatest([this.brand$, this.dateRange$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([brand, dateRange]) => {
        this._automationsFacade.loadBrandAutomations(
          brand,
          toTimestamp(dateRange.from),
          toTimestamp(dateRange.to)
        );
      });

    this.practice$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((practice) =>
        this._automationsFacade.setPracticeFilter(practice)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
