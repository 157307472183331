import { get } from 'lodash';
import { isObject } from '../common';
import { DocumentData, type DocumentReference } from './firestore/adaptor';
import { isDocRef } from './firestore/document';
import { type IIdentifiable, type IReffable } from './interfaces';

export interface INamedDocument<T = DocumentData> {
  name: string;
  ref: DocumentReference<T>;
}

export function toNamedDocument<T>(data: INamedDocument<T>): INamedDocument<T> {
  return {
    name: data.name,
    ref: data.ref,
  };
}

export function isINamedDocument<T extends object = object>(
  doc: unknown
): doc is INamedDocument<T> {
  return isObject(doc) && 'name' in doc && 'ref' in doc && isDocRef<T>(doc.ref);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IStorageRef {}

export interface IMediaRef {
  name: string;
  ref: IStorageRef;
}

export interface INamedTypeDocument<
  T extends object = object,
  R extends string = string,
> extends INamedDocument<T> {
  type: R;
}

export function isNamedTypeDocument<T extends object = object>(
  doc: unknown
): doc is INamedTypeDocument<T> {
  return isINamedDocument(doc) && 'type' in doc;
}

export function isSameIdentifier(
  a?: IReffable | DocumentReference | IIdentifiable,
  b?: IReffable | DocumentReference | IIdentifiable
): boolean {
  const identifierA = String(get(a, 'ref.id') ?? get(a, 'id') ?? get(a, 'uid'));
  const identifierB = String(get(b, 'ref.id') ?? get(b, 'id') ?? get(b, 'uid'));
  return identifierA && identifierB ? identifierA === identifierB : false;
}

export function isSameRef<T extends object, R extends object>(
  a?: IReffable<T> | DocumentReference<T>,
  b?: IReffable<R> | DocumentReference<R>
): boolean {
  if (!a && !b) {
    return true;
  }

  if (!a || !b) {
    return false;
  }

  if (isDocRef<T>(a) && isDocRef<R>(b)) {
    return a.path === b.path;
  }

  if (isDocRef<T>(a) && !isDocRef<R>(b)) {
    return a.path === b.ref.path;
  }

  if (!isDocRef<T>(a) && isDocRef<R>(b)) {
    return a.ref.path === b.path;
  }

  if (!isDocRef<T>(a) && !isDocRef<R>(b)) {
    return a.ref.path === b.ref.path;
  }

  return false;
}
