import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  type AnyChartedItemConfiguration,
  type IChartedRef,
  isMultiTreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot } from '@principle-theorem/shared';
import { ChartDialogService } from '../../chart-dialog.service';
import {
  AddChartedTreatmentDialogComponent,
  type IAddChartedTreatmentDialogData,
} from '../add-charted-treatment-dialog/add-charted-treatment-dialog.component';
import {
  BasicDialogService,
  ConnectedDialogConfig,
} from '@principle-theorem/ng-shared';

export interface IAddChartableData {
  chartable: AnyChartedItemConfiguration;
  selectedSurfaces: Partial<IChartedRef>[];
}

@Component({
  selector: 'pr-add-charted-treatment-button',
  templateUrl: './add-charted-treatment-button.component.html',
  styleUrls: ['./add-charted-treatment-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddChartedTreatmentButtonComponent {
  @Input() connectedTo: ElementRef<HTMLElement>;
  @Input() offsetX = 0;
  @Input() offsetY = 0;
  @Output() addChartable = new EventEmitter<IAddChartableData>();
  @Input() inlcudeMultiTreatments = true;

  constructor(
    private _dialog: BasicDialogService,
    private _chartStore: ChartFacade,
    private _chartDialogService: ChartDialogService
  ) {}

  async addTreatment(): Promise<void> {
    const chartable = await this._dialog
      .connected<
        IAddChartedTreatmentDialogData,
        AnyChartedItemConfiguration,
        AddChartedTreatmentDialogComponent
      >(
        AddChartedTreatmentDialogComponent,
        this._getTreatmentSelectDialogConfig({
          includeMultiTreatments: this.inlcudeMultiTreatments,
        })
      )
      .closed.toPromise();
    if (!chartable) {
      return;
    }
    if (isMultiTreatmentConfiguration(chartable)) {
      this.addChartable.emit({ chartable, selectedSurfaces: [] });
      return;
    }

    const selectedSurfaces = await this._getSurfaces(chartable);
    if (!selectedSurfaces.length) {
      return;
    }

    this.addChartable.emit({ chartable, selectedSurfaces });
  }

  private async _getSurfaces(
    chartable: AnyChartedItemConfiguration
  ): Promise<Partial<IChartedRef>[]> {
    const selectedSurfaces = await snapshot(
      this._chartStore.selectedSurfacesState$(ChartId.InAppointment)
    );
    if (selectedSurfaces.length) {
      return selectedSurfaces;
    }

    const surfaces = await this._chartDialogService.getSurfaces(chartable);
    return surfaces ?? [];
  }

  private _getTreatmentSelectDialogConfig(
    data: IAddChartedTreatmentDialogData
  ): ConnectedDialogConfig<
    IAddChartedTreatmentDialogData,
    AnyChartedItemConfiguration,
    AddChartedTreatmentDialogComponent
  > {
    return {
      data,
      connectedTo: this.connectedTo,
      width: `${String(this.connectedTo.nativeElement.clientWidth)}px`,
      positions: [
        {
          panelClass: ['no-padding'],
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
          offsetX: this.offsetX,
          offsetY: this.offsetY,
        },
      ],
      autoFocus: true,
    };
  }
}
