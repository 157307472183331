import { NgModule } from '@angular/core';
import { type Routes, RouterModule } from '@angular/router';
import { LabJobPagesModule } from './lab-job-pages.module';
import { LabJobsTabsComponent } from './lab-jobs-tabs/lab-jobs-tabs.component';

const routes: Routes = [
  {
    path: '',
    component: LabJobsTabsComponent,
    data: {
      title: 'Lab Jobs',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), LabJobPagesModule],
  exports: [RouterModule],
})
export class LabJobPagesRoutingModule {}
