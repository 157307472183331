import {
  LabJobAction,
  LabJobStatus,
} from '@principle-theorem/principle-core/interfaces';
import { ILabJobEventFact } from '@principle-theorem/reporting/interfaces';
import {
  BaseMock,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
} from '@principle-theorem/shared';
import { AppointmentDimensionMock } from '../dimensions/appointment-dimension.mock';
import { BrandDimensionMock } from '../dimensions/brand-dimension.mock';
import { SerialisedDocumentReferenceMock } from '../dimensions/common.mock';
import { LabDimensionMock } from '../dimensions/lab-dimension.mock';
import { LabJobDimensionMock } from '../dimensions/lab-job-dimension.mock';
import { PatientDimensionMock } from '../dimensions/patient-dimension.mock';
import { PracticeDimensionMock } from '../dimensions/practice-dimension.mock';
import { StafferDimensionMock } from '../dimensions/staffer-dimension.mock';
import { TreatmentPlanDimensionMock } from '../dimensions/treatment-plan-dimension.mock';

export class LabJobEventFactMock extends BaseMock implements ILabJobEventFact {
  labRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  labJobRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  patientRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  appointmentRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  treatmentPlanRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practitionerRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practiceRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  brandRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  lab = MockGenerator.generate(LabDimensionMock);
  labJob = MockGenerator.generate(LabJobDimensionMock);
  brand = MockGenerator.generate(BrandDimensionMock);
  patient = MockGenerator.generate(PatientDimensionMock);
  referrer = this.patient.referrer;
  appointment = MockGenerator.generate(AppointmentDimensionMock);
  practitioner = MockGenerator.generate(StafferDimensionMock);
  treatmentPlan = MockGenerator.generate(TreatmentPlanDimensionMock);
  practice = MockGenerator.generate(PracticeDimensionMock);
  timestamp = toSerialisedTimestamp(MockTimestamp());
  event = {
    action: LabJobAction.Received,
    statusBefore: LabJobStatus.Sent,
    statusAfter: LabJobStatus.Received,
  };
}
