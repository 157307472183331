import { Money } from '@principle-theorem/accounting';
import { HICAPS_CONNECT_ADDITIONAL_ITEM_CODE_DESCRIPTIONS } from '@principle-theorem/hicaps-connect';
import {
  HicapsConnectResponseCodes,
  ServiceProviderHandler,
} from '@principle-theorem/principle-core';
import { toInt } from '@principle-theorem/shared';
import { trim } from 'lodash';
import { HicapsConnectHelpers } from '../hicaps-connect-helpers';

export interface IClaimResponseItem {
  patientId: string;
  itemNumber: string;
  bodyPartNumber: string;
  dateOfService: string;
  itemClaimAmount: number;
  itemBenefitAmount: number;
  itemResponseCode: string;
}

export class HicapsConnectHealthFundResponseTransformer {
  static getItemDescription(item: IClaimResponseItem): string {
    const serviceCode = ServiceProviderHandler.findServiceCode(item.itemNumber);
    if (serviceCode) {
      return `${serviceCode.code} - ${serviceCode.title}`;
    }
    const additionalItemCodeDescription =
      HICAPS_CONNECT_ADDITIONAL_ITEM_CODE_DESCRIPTIONS[item.itemNumber];
    if (additionalItemCodeDescription) {
      return additionalItemCodeDescription;
    }
    return `${item.itemNumber}`;
  }

  static getItemNote(item: IClaimResponseItem): string | undefined {
    if (item.itemResponseCode === '00') {
      return;
    }

    const response = HicapsConnectResponseCodes.findServiceItemResponseCode(
      item.itemResponseCode
    );
    if (!response) {
      return undefined;
    }
    return `${item.itemResponseCode} ${response.description}`;
  }

  static claimResponseItem(item: string): IClaimResponseItem {
    const result = HicapsConnectHelpers.objectFromSubstrings(item, {
      patientId: [0, 2],
      itemNumber: [2, 6],
      bodyPartNumber: [6, 8],
      dateOfService: [8, 12],
      itemClaimAmount: [12, 18],
      itemBenefitAmount: [18, 24],
      itemResponseCode: [24, 26],
    });

    const itemClaimAmount = Money.fromCents(toInt(result.itemClaimAmount));
    const itemBenefitAmount = Money.fromCents(toInt(result.itemBenefitAmount));

    return {
      ...result,
      itemNumber: trim(result.itemNumber),
      itemClaimAmount,
      itemBenefitAmount,
    };
  }
}
