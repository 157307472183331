import { ChartOptions, type IChartData } from './chart-builder';
import { ChartType } from '@principle-theorem/reporting';
import { BaseChart } from './base-chart';

export class LineChart extends BaseChart {
  data: IChartData = {
    type: ChartType.Line,
    options: ChartOptions.init({
      legend: { position: 'none' },
    }),
  };
}
