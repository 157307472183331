import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  SelectionListStore,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { ITypesenseTaskWithRef } from '@principle-theorem/principle-core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-task-list-display',
  templateUrl: './task-list-display.component.html',
  styleUrls: ['./task-list-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListDisplayComponent {
  tasks$ = new ReplaySubject<ITypesenseTaskWithRef[]>(1);
  trackByTask = TrackByFunctions.ref<ITypesenseTaskWithRef>();
  multiSelectDisabled$ = new BehaviorSubject<boolean>(false);
  @Input() selectionList: SelectionListStore<ITypesenseTaskWithRef>;
  @Input() selectedItem: ITypesenseTaskWithRef;
  @Output() selectedItemChange = new EventEmitter<ITypesenseTaskWithRef>();
  @Output() taskUpdated = new EventEmitter<void>();

  @Input()
  set tasks(tasks: ITypesenseTaskWithRef[]) {
    if (tasks) {
      this.tasks$.next(tasks);
    }
  }

  @Input()
  set multiSelectDisabled(disableMultiSelect: BooleanInput) {
    this.multiSelectDisabled$.next(coerceBooleanProperty(disableMultiSelect));
  }

  selectItem(item: ITypesenseTaskWithRef): void {
    this.selectedItemChange.emit(item);
  }

  isItemSelected(item: ITypesenseTaskWithRef): boolean {
    return this.selectedItem === item;
  }
}
