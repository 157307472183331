import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { AccountSummaryBloc } from '@principle-theorem/ng-principle-shared';

@Component({
  selector: 'pr-basic-account-summary',
  templateUrl: './basic-account-summary.component.html',
  styleUrls: ['./basic-account-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicAccountSummaryComponent {
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  bloc = new AccountSummaryBloc(this.patient$);

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }
}
