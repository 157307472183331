import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmDialogComponent,
  DialogPresets,
  IConfirmationDialogInput,
  ObservableDataSource,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import { Practice } from '@principle-theorem/principle-core';
import {
  IPractice,
  ITyroTerminal,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  HISTORY_DATE_FORMAT,
  WithRef,
  addDoc,
  deleteDoc,
  multiSort,
  snapshot,
  sortTimestamp,
} from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  EditTyroTerminalDialogComponent,
  IEditTyroTerminalRequest,
} from '../edit-tyro-terminal-dialog/edit-tyro-terminal-dialog.component';
import { PairTyroTerminalService } from '../pair-tyro-terminal-dialog/pair-tyro-terminal.service';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';

@Component({
    selector: 'pr-practice-tyro-terminals',
    templateUrl: './practice-tyro-terminals.component.html',
    styleUrls: ['./practice-tyro-terminals.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PracticeTyroTerminalsComponent {
  readonly dateFormat = HISTORY_DATE_FORMAT;
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);
  terminals$: Observable<WithRef<ITyroTerminal>[]>;
  dataSource: ObservableDataSource<WithRef<ITyroTerminal>>;
  displayedColumns: string[] = [
    'name',
    'practitioner',
    'lastActive',
    'actions',
  ];

  @Input()
  set practice(practice: WithRef<IPractice>) {
    if (practice) {
      this.practice$.next(practice);
    }
  }

  constructor(
    private _dialog: MatDialog,
    private _pairTyroTerminal: PairTyroTerminalService,
    public globalStore: GlobalStoreService
  ) {
    this.terminals$ = this.practice$.pipe(
      switchMap((practice) => Practice.tyroTerminals$(practice)),
      multiSort((a, b) => sortTimestamp(a.lastPaired, b.lastPaired))
    );
    this.dataSource = new ObservableDataSource(this.terminals$);
  }

  async pairTerminal(terminal: WithRef<ITyroTerminal>): Promise<void> {
    await this._pairTyroTerminal.pairTerminal(terminal);
  }

  async pairNewTerminal(): Promise<void> {
    const terminal = await this._openTerminalEdit({
      title: 'Add Tyro Terminal',
    });
    if (!terminal) {
      return;
    }
    const practice = await snapshot(this.practice$);
    const doc = await addDoc(Practice.tyroTerminalCol(practice), terminal);
    await this._pairTyroTerminal.pairTerminal(
      await Firestore.getDoc<ITyroTerminal>(doc)
    );
  }

  async editTerminalPair(existing: WithRef<ITyroTerminal>): Promise<void> {
    const terminal = await this._openTerminalEdit({
      title: 'Edit Tyro Terminal',
      formData: existing,
    });
    if (!terminal) {
      return;
    }
    await Firestore.patchDoc(existing.ref, terminal);
    await this._pairTyroTerminal.pairTerminal(
      await Firestore.getDoc<ITyroTerminal>(existing.ref)
    );
  }

  async deleteTerminalPair(terminal: WithRef<ITyroTerminal>): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Terminal Pair',
      prompt: `Are you sure you want to delete ${terminal.name}?`,
      submitLabel: 'Delete',
      submitColor: 'warn',
    });

    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!confirmed) {
      return;
    }
    await deleteDoc(terminal.ref);
  }

  private async _openTerminalEdit(
    data: IEditTyroTerminalRequest
  ): Promise<ITyroTerminal | undefined> {
    return this._dialog
      .open<
        EditTyroTerminalDialogComponent,
        IEditTyroTerminalRequest,
        ITyroTerminal | undefined
      >(EditTyroTerminalDialogComponent, DialogPresets.medium({ data }))
      .afterClosed()
      .toPromise();
  }
}
