import {
  type INamedDocument,
  type ISoftDelete,
} from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { type ITag } from '../tag/tag';

export interface IMedia extends ISoftDelete {
  name: string;
  path: string;
  tags: INamedDocument<ITag>[];
  expiresAt?: Timestamp;
}
