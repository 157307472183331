import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  type IBrand,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { Brand } from '@principle-theorem/principle-core';
import { type CollectionReference } from '@principle-theorem/shared';
import {
  getDoc,
  type ResolverResult,
  type WithRef,
} from '@principle-theorem/shared';
import { from, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

export abstract class StafferCollectionResolver {
  abstract resolve(
    route: ActivatedRouteSnapshot
  ): ResolverResult<CollectionReference<IStaffer> | undefined>;
}

@Injectable()
export class StafferCollectionResolverService extends StafferCollectionResolver {
  constructor(private _brandScope: CurrentBrandScope) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot
  ): ResolverResult<CollectionReference<IStaffer> | undefined> {
    const model: WithRef<IBrand> | undefined =
      this._brandScope.findModel(route);
    if (!model) {
      return of(undefined);
    }
    return from(getDoc(model.ref)).pipe(
      map((brand) => Brand.stafferCol(brand)),
      take(1)
    );
  }
}
