import {
  ICandidateCalendarEvent,
  IEvent,
  IScheduleSummary,
  IScheduleSummaryEventable,
  isGapCandidateEvent,
  isGapEventType,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timezone,
  WithRef,
  firstResult,
  isSameRef,
  timePeriodWithin,
  toISODate,
  toMomentTz,
  toTimePeriod,
  where,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Event, isSameEvent } from '../event/event';
import { first, intersectionWith } from 'lodash';
import { Practice } from '../practice/practice';

export class Gap {
  static isInFuture(event: IEvent, timezone: Timezone): boolean {
    return toMomentTz(event.to, timezone).isAfter(moment.tz(timezone));
  }

  static async scheduleSummaryFromGapCandidate(
    gapCandidate: WithRef<ICandidateCalendarEvent>
  ): Promise<WithRef<IScheduleSummary> | undefined> {
    const event = gapCandidate.event;
    const { practice, from } = event;
    const staff = Event.staff(event).map((staffer) => staffer.ref);

    return firstResult(
      Practice.scheduleSummaryCol(practice),
      where('day', '==', toISODate(from)),
      where('staffer', 'in', staff)
    );
  }

  static filterCandidateGaps(
    gaps: IScheduleSummaryEventable[],
    gapCandidate: WithRef<ICandidateCalendarEvent>,
    timezone: Timezone
  ): IScheduleSummaryEventable[] {
    const candidateFrom = toMomentTz(gapCandidate.event.from, timezone);
    const candidateTo = toMomentTz(gapCandidate.event.to, timezone);

    return gaps.filter((gap) => {
      const gapFrom = toMomentTz(gap.event.from, timezone);
      const gapTo = toMomentTz(gap.event.to, timezone);

      return (
        candidateFrom.isSameOrAfter(gapFrom) &&
        candidateTo.isSameOrBefore(gapTo) &&
        gapCandidate.event.participantRefs.some((ref) =>
          isSameRef(ref, Event.staff(gap.event)[0].ref)
        )
      );
    });
  }

  static findGapFromUid(
    gaps: IScheduleSummaryEventable[],
    gapId?: string
  ): IScheduleSummaryEventable | undefined {
    return gaps.find((gap) => gap.uid === gapId);
  }

  static findGapFromCandidateEvent(
    gaps: IScheduleSummaryEventable[],
    event: IEvent
  ): IScheduleSummaryEventable | undefined {
    return gaps.find(
      (gap) =>
        timePeriodWithin(
          toTimePeriod(event.from, event.to),
          toTimePeriod(gap.event.from, gap.event.to),
          true
        ) &&
        isSameRef(
          first(Event.staff(event))?.ref,
          first(Event.staff(gap.event))?.ref
        )
    );
  }

  static findPendingGapFromEvent(
    event: IEvent,
    pendingGaps: IScheduleSummaryEventable[]
  ): IScheduleSummaryEventable | undefined {
    return pendingGaps.find(({ event: pendingGapEvent }) => {
      if (isGapEventType(event.type)) {
        return isSameEvent(event, pendingGapEvent);
      }

      if (isGapCandidateEvent(event)) {
        return (
          timePeriodWithin(
            Event.toTimePeriod(event),
            Event.toTimePeriod(pendingGapEvent),
            true
          ) &&
          intersectionWith(
            event.participants,
            pendingGapEvent.participants,
            isSameRef
          ).length
        );
      }

      return false;
    });
  }
}
