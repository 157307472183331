import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { Appointment, FollowUp } from '@principle-theorem/principle-core';
import {
  filterUndefined,
  toTimestamp,
  unserialise$,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { concatMap, tap, withLatestFrom } from 'rxjs/operators';
import { CancellationActions } from '../actions';
import { AppointmentSchedulingFacade } from '../facades/appointment-scheduling.facade';

@Injectable()
export class CancelAppointmentEffects {
  private _actions$ = inject(Actions);
  private _schedulingFacade = inject(AppointmentSchedulingFacade);
  private _organisation = inject(OrganisationService);

  cancelAppointment$: Observable<void> = createEffect(
    () => this._cancelAppointment$(),
    { dispatch: false }
  );

  constructor(
    private _location: Location,
    private _snackBar: MatSnackBar
  ) {}

  private _cancelAppointment$(): Observable<void> {
    return this._actions$.pipe(
      ofType(CancellationActions.cancelAppointment),
      unserialise$(),
      withLatestFrom(
        this._schedulingFacade.currentAppointment$.pipe(filterUndefined()),
        this._organisation.staffer$.pipe(filterUndefined())
      ),
      concatMap(async ([action, appointment, staffer]) => {
        const followUpDate = action.followUpData.createFollowUp
          ? toTimestamp(action.followUpData.followUpDate)
          : undefined;
        const followUp = FollowUp.init({
          ...action.followUpData,
          followUpDate,
        });
        await Appointment.cancel(
          appointment,
          staffer,
          action.schedulingEventData,
          followUp
        );
      }),
      tap(() => {
        this._snackBar.open('Appointment cancelled');
        this._location.back();
      })
    );
  }
}
