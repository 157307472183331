import {
  type EventType,
  EVENT_TYPES,
  type IEventable,
  isEventable,
  IScheduleSummaryEventable,
} from '@principle-theorem/principle-core/interfaces';
import { orderEvents } from '@principle-theorem/principle-core';
import { BehaviorSubject, combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class EventTypeFilter {
  results$: Observable<IScheduleSummaryEventable[]>;
  filters$: BehaviorSubject<EventType[]> = new BehaviorSubject(EVENT_TYPES);

  constructor(items: Observable<IScheduleSummaryEventable[]>) {
    this.results$ = combineLatest([items, this.filters$]).pipe(
      map((value: [IScheduleSummaryEventable[], EventType[]]) =>
        this._filter(...value)
      )
    );
  }

  private _filter(
    events: IScheduleSummaryEventable[],
    filters: EventType[]
  ): IScheduleSummaryEventable[] {
    return events
      .filter(isEventable)
      .filter((eventable: IEventable) => filters.includes(eventable.event.type))
      .sort(orderEvents);
  }
}
