<div
  class="flex items-center gap-3 rounded-full bg-white px-1.5 pt-0.5 text-lg leading-normal shadow-md"
>
  @for (reaction of reactions$ | async; track reaction.emoji) {
    <span
      [matBadge]="reaction.count"
      [matBadgeHidden]="reaction.count < 2"
      matBadgeDisabled
      matBadgeSize="small"
      matBadgePosition="below after"
      [matTooltip]="reaction.staff"
      matTooltipDelay="300"
      >{{ reaction.emoji }}</span
    >
  }
</div>
