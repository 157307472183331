import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  CurrentBrandScope,
  CurrentPracticeScope,
  TimezoneService,
} from '@principle-theorem/ng-principle-shared';
import {
  Gap,
  type OptionFinderQuery,
  Practice,
  practiceStaffAvailableTimes,
} from '@principle-theorem/principle-core';
import {
  GAP_DAYS_INCREMENT,
  type IGap,
  InteractionType,
  type IPractice,
  type ISchedulingAlert,
  SchedulingAlertTargetType,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  initFirestoreModel,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { BehaviorSubject, combineLatest, type Observable, of } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'pr-scheduling-alerts',
  templateUrl: './scheduling-alerts.component.html',
  styleUrls: ['./scheduling-alerts.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchedulingAlertsComponent {
  schedulingAlerts$: Observable<ISchedulingAlert[]>;
  practice$: Observable<WithRef<IPractice>>;
  loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private _practiceScope: CurrentPracticeScope,
    private _brandScope: CurrentBrandScope,
    private _timezone: TimezoneService
  ) {
    this.practice$ = this._practiceScope.doc$.pipe(filterUndefined());
    this.schedulingAlerts$ = this.initSchedulingAlerts$();
  }

  initSchedulingAlerts$(): Observable<ISchedulingAlert[]> {
    return combineLatest([
      this.practice$.pipe(
        switchMap((practice) => Practice.activeSchedulingAlerts$(practice))
      ),
      this.getGapAlerts$(),
    ]).pipe(map(([alerts, gaps]) => [...alerts, ...gaps]));
  }

  getGapAlerts$(): Observable<ISchedulingAlert[]> {
    return combineLatest([
      this.practice$,
      this._brandScope.asObservable(),
      this._timezone.currentPractice.timezone$,
    ]).pipe(
      tap(() => this.loading$.next(true)),
      switchMap(([practice, brand, timezone]) => {
        const query: OptionFinderQuery = [
          moment.tz(timezone).startOf('day'),
          moment.tz(timezone).add(GAP_DAYS_INCREMENT, 'day').endOf('day'),
        ];
        return of(query).pipe(practiceStaffAvailableTimes(practice, brand));
      }),
      map((timePeriods) =>
        timePeriods.map((timePeriod) => {
          const gap = Gap.fromEventTimePeriod(timePeriod);
          return this._gapToAlert(gap);
        })
      ),
      startWith([]),
      tap(() => this.loading$.next(false))
    );
  }

  private _gapToAlert(gap: IGap): ISchedulingAlert {
    return {
      title: 'Gap',
      description: '',
      type: InteractionType.Gap,
      targetType: SchedulingAlertTargetType.Gap,
      deadline: gap.event.from,
      ...initFirestoreModel(),
      gap,
    };
  }
}
