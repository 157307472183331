import {
  AppointmentStatus,
  ConditionLogicId,
  DynamicFormType,
  IAppointmentScopeData,
  IBrand,
  IConditionLogicImplementation,
  IDynamicForm,
  isAppointment,
  isPatient,
  isStaffer,
  ITag,
  ITreatmentCategory,
  ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  getEnumValues,
  INamedDocument,
  isObject,
  isSameRef,
  snapshot,
  toNamedDocument,
  TypeGuard,
  WithRef,
} from '@principle-theorem/shared';
import { first, isEqual, isString, sortBy } from 'lodash';
import { Brand } from '../models/brand';
import { TreatmentStep } from '../models/clinical-charting/treatment/treatment-step';
import { Patient } from '../models/patient/patient';
import { TreatmentConfiguration } from '../models/clinical-charting/treatment/treatment-configuration';

const isAppointmentScopeData = TypeGuard.interface<IAppointmentScopeData>({
  patient: isPatient,
  medicalHistoryLink: isString,
  referrer: TypeGuard.noGuard(),
  appointment: isAppointment,
  practitioner: isStaffer,
  confirmLink: isString,
  practice: isObject,
});

export interface IAppointmentHasTagConditionConfig {
  tag: INamedDocument<ITag>;
}

export class AppointmentHasTagCondition
  implements
    IConditionLogicImplementation<
      IAppointmentScopeData,
      IAppointmentHasTagConditionConfig,
      INamedDocument<ITag>
    >
{
  conditionId = ConditionLogicId.AppointmentHasTag;
  description = 'Appointment must have the selected tag';
  isValidContext = isAppointmentScopeData;

  isTrue(
    config: IAppointmentHasTagConditionConfig,
    context: IAppointmentScopeData
  ): boolean {
    return context.appointment.tags.some((appointmentTag) =>
      isSameRef(appointmentTag.ref, config.tag.ref)
    );
  }

  async getForm(
    brand: WithRef<IBrand>,
    initialValue?: IAppointmentHasTagConditionConfig
  ): Promise<IDynamicForm<INamedDocument<ITag>>> {
    const tags = await Firestore.getDocs(Brand.appointmentTagCol(brand));
    const options = tags.map((tag) => ({
      label: tag.name,
      value: toNamedDocument(tag),
    }));

    const tag = {
      type: DynamicFormType.Select,
      label: 'Tag',
      options: sortBy(options, 'label'),
      initialValue: initialValue?.tag ?? first(options)?.value,
      compareWith: isSameRef,
    };
    return { tag };
  }
}

export interface IAppointmentIsStatusConditionConfig {
  status: AppointmentStatus;
}

export class AppointmentIsStatusCondition
  implements
    IConditionLogicImplementation<
      IAppointmentScopeData,
      IAppointmentIsStatusConditionConfig,
      AppointmentStatus
    >
{
  conditionId = ConditionLogicId.AppointmentIsStatus;
  description = 'Appointment must be the selected status';
  isValidContext = isAppointmentScopeData;

  isTrue(
    config: IAppointmentIsStatusConditionConfig,
    context: IAppointmentScopeData
  ): boolean {
    return context.appointment.status === config.status;
  }

  async getForm(
    _brand: WithRef<IBrand>,
    initialValue?: IAppointmentIsStatusConditionConfig
  ): Promise<IDynamicForm<AppointmentStatus>> {
    const options = getEnumValues(AppointmentStatus).map((status) => ({
      label: status,
      value: status,
    }));
    const status = {
      type: DynamicFormType.Select,
      label: 'Status',
      options,
      initialValue: initialValue?.status ?? AppointmentStatus.Confirmed,
      compareWith: isEqual,
    };
    return Promise.resolve({ status });
  }
}

export interface IAppointmentConfirmedConditionConfig {
  isConfirmed: boolean;
}

export class AppointmentConfirmedCondition
  implements
    IConditionLogicImplementation<
      IAppointmentScopeData,
      IAppointmentConfirmedConditionConfig,
      boolean
    >
{
  conditionId = ConditionLogicId.AppointmentConfirmed;
  description = 'Appointment must be Confirmed';
  isValidContext = isAppointmentScopeData;

  isTrue(
    config: IAppointmentConfirmedConditionConfig,
    context: IAppointmentScopeData
  ): boolean {
    const isConfirmed =
      context.appointment.status === AppointmentStatus.Confirmed;
    return isConfirmed === config.isConfirmed;
  }

  async getForm(
    _brand: WithRef<IBrand>,
    initialValue?: IAppointmentConfirmedConditionConfig
  ): Promise<IDynamicForm<boolean>> {
    const isConfirmed = {
      type: DynamicFormType.Select,
      label: 'Is Confirmed?',
      options: [
        { label: 'Confirmed', value: true },
        { label: 'Not Confirmed', value: false },
      ],
      initialValue: initialValue?.isConfirmed ?? true,
      compareWith: isEqual,
    };
    return Promise.resolve({ isConfirmed });
  }
}

interface IIsFirstAppointment {
  isFirst: boolean;
}

export class IsFirstAppointmentCondition
  implements
    IConditionLogicImplementation<
      IAppointmentScopeData,
      IIsFirstAppointment,
      boolean
    >
{
  conditionId = ConditionLogicId.IsFirstAppointment;
  description = `Must be the Patient's first Appointment`;
  isValidContext = isAppointmentScopeData;

  async isTrue(
    config: IIsFirstAppointment,
    context: IAppointmentScopeData
  ): Promise<boolean> {
    const isFirst = await snapshot(
      Patient.isNewPatient$(context.patient, context.appointment.ref)
    );
    return isFirst === config.isFirst;
  }

  async getForm(
    _brand: WithRef<IBrand>,
    initialValue?: IIsFirstAppointment
  ): Promise<IDynamicForm<boolean>> {
    const isFirst = {
      type: DynamicFormType.Select,
      label: 'Is First Appointment?',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      initialValue: initialValue?.isFirst ?? true,
      compareWith: isEqual,
    };
    return Promise.resolve({ isFirst });
  }
}

interface IIncludesTreatmentConfiguration {
  treatmentConfigurations: DocumentReference<ITreatmentConfiguration>[];
}

export class AppointmentIncludesTreatmentCondition
  implements
    IConditionLogicImplementation<
      IAppointmentScopeData,
      IIncludesTreatmentConfiguration,
      DocumentReference<ITreatmentConfiguration>
    >
{
  conditionId = ConditionLogicId.AppointmentIncludesTreatment;
  description = 'Appointment must have one of these Treatments';
  isValidContext = isAppointmentScopeData;

  async isTrue(
    config: IIncludesTreatmentConfiguration,
    context: IAppointmentScopeData
  ): Promise<boolean> {
    const treatmentStep = await Firestore.getDoc(
      context.appointment.treatmentPlan.treatmentStep.ref
    );
    const appointmentTreatmentConfigurations = await snapshot(
      TreatmentStep.getTreatmentConfigurations$(treatmentStep)
    );

    if (
      config.treatmentConfigurations.length &&
      !appointmentTreatmentConfigurations.length
    ) {
      return false;
    }

    return appointmentTreatmentConfigurations.some(
      (appointmentTreatmentConfiguration) =>
        config.treatmentConfigurations.some((treatmentConfiguration) =>
          isSameRef(treatmentConfiguration, appointmentTreatmentConfiguration)
        )
    );
  }

  async getForm(
    brand: WithRef<IBrand>,
    initialData?: IIncludesTreatmentConfiguration
  ): Promise<IDynamicForm<DocumentReference<ITreatmentConfiguration>>> {
    const treatmentConfigurations = {
      type: DynamicFormType.Select,
      label: 'Has one of these Treatments?',
      options: (await Firestore.getDocs(TreatmentConfiguration.col(brand))).map(
        (configuration) => ({
          label: configuration.name,
          value: configuration.ref,
        })
      ),
      initialValue: initialData?.treatmentConfigurations ?? [],
      compareWith: isEqual,
      isMultiple: true,
    };
    return { treatmentConfigurations };
  }
}

interface IExcludesTreatmentConfiguration {
  excludeTreatmentConfigurations: DocumentReference<ITreatmentConfiguration>[];
}

export class AppointmentExcludesTreatmentCondition
  implements
    IConditionLogicImplementation<
      IAppointmentScopeData,
      IExcludesTreatmentConfiguration,
      DocumentReference<ITreatmentConfiguration>
    >
{
  conditionId = ConditionLogicId.AppointmentExcludesTreatment;
  description = 'Appointment must not be one of these Treatments';
  isValidContext = isAppointmentScopeData;

  async isTrue(
    config: IExcludesTreatmentConfiguration,
    context: IAppointmentScopeData
  ): Promise<boolean> {
    const treatmentStep = await Firestore.getDoc(
      context.appointment.treatmentPlan.treatmentStep.ref
    );
    const appointmentTreatmentConfigurations = await snapshot(
      TreatmentStep.getTreatmentConfigurations$(treatmentStep)
    );

    if (
      config.excludeTreatmentConfigurations.length &&
      !appointmentTreatmentConfigurations.length
    ) {
      return false;
    }

    return appointmentTreatmentConfigurations.every(
      (appointmentTreatmentConfiguration) =>
        config.excludeTreatmentConfigurations.every(
          (treatmentConfiguration) =>
            !isSameRef(
              treatmentConfiguration,
              appointmentTreatmentConfiguration
            )
        )
    );
  }

  async getForm(
    brand: WithRef<IBrand>,
    initialData?: IExcludesTreatmentConfiguration
  ): Promise<IDynamicForm<DocumentReference<ITreatmentConfiguration>>> {
    const excludeTreatmentConfigurations = {
      type: DynamicFormType.Select,
      label: `Doesn't have one of these Treatments?`,
      options: (await Firestore.getDocs(TreatmentConfiguration.col(brand))).map(
        (configuration) => ({
          label: configuration.name,
          value: configuration.ref,
        })
      ),
      initialValue: initialData?.excludeTreatmentConfigurations ?? [],
      compareWith: isEqual,
      isMultiple: true,
    };
    return { excludeTreatmentConfigurations };
  }
}

interface IIncludesTreatmentCategory {
  treatmentCategories: DocumentReference<ITreatmentCategory>[];
}

export class AppointmentIncludesTreatmentCategoryCondition
  implements
    IConditionLogicImplementation<
      IAppointmentScopeData,
      IIncludesTreatmentCategory,
      DocumentReference<ITreatmentCategory>
    >
{
  conditionId = ConditionLogicId.AppointmentIsTreatmentCategory;
  description = 'Appointment must be one of these Treatment Categories';
  isValidContext = isAppointmentScopeData;

  isTrue(
    config: IIncludesTreatmentCategory,
    context: IAppointmentScopeData
  ): boolean {
    const appointmentPrimaryCategory = TreatmentStep.defaultDisplayRef(
      context.appointment.treatmentPlan.treatmentStep.display
    );

    return config.treatmentCategories.some((treatmentCategory) =>
      isSameRef(treatmentCategory, appointmentPrimaryCategory)
    );
  }

  async getForm(
    brand: WithRef<IBrand>,
    initialData?: IIncludesTreatmentCategory
  ): Promise<IDynamicForm<DocumentReference<ITreatmentCategory>>> {
    const treatmentCategories = {
      type: DynamicFormType.Select,
      label: 'Is one of these Treatment Categories?',
      options: (await Firestore.getDocs(Brand.treatmentCategoryCol(brand))).map(
        (category) => ({
          label: category.name,
          value: category.ref,
        })
      ),
      initialValue: initialData?.treatmentCategories ?? [],
      compareWith: isEqual,
      isMultiple: true,
    };
    return { treatmentCategories };
  }
}

interface IExcludesTreatmentCategory {
  excludeTreatmentCategories: DocumentReference<ITreatmentCategory>[];
}

export class AppointmentExcludesTreatmentCategoryCondition
  implements
    IConditionLogicImplementation<
      IAppointmentScopeData,
      IExcludesTreatmentCategory,
      DocumentReference<ITreatmentCategory>
    >
{
  conditionId = ConditionLogicId.AppointmentIsNotTreatmentCategory;
  description = 'Appointment must not be one of these Treatment Categories';
  isValidContext = isAppointmentScopeData;

  isTrue(
    config: IExcludesTreatmentCategory,
    context: IAppointmentScopeData
  ): boolean {
    const appointmentPrimaryCategory = TreatmentStep.defaultDisplayRef(
      context.appointment.treatmentPlan.treatmentStep.display
    );

    return config.excludeTreatmentCategories.every(
      (treatmentCategory) =>
        !isSameRef(treatmentCategory, appointmentPrimaryCategory)
    );
  }

  async getForm(
    brand: WithRef<IBrand>,
    initialData?: IExcludesTreatmentCategory
  ): Promise<IDynamicForm<DocumentReference<ITreatmentCategory>>> {
    const excludeTreatmentCategories = {
      type: DynamicFormType.Select,
      label: 'Is not one of these Treatment Categories?',
      options: (await Firestore.getDocs(Brand.treatmentCategoryCol(brand))).map(
        (category) => ({
          label: category.name,
          value: category.ref,
        })
      ),
      initialValue: initialData?.excludeTreatmentCategories ?? [],
      compareWith: isEqual,
      isMultiple: true,
    };
    return { excludeTreatmentCategories };
  }
}
