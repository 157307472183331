import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { type IPrincipleReconciliationReportData } from '@principle-theorem/reporting';
import { serialise$, toTimestamp } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ReportingFunctions } from '../../../core/reporting-functions';
import {
  loadReport,
  ReconciliationReportActions,
} from './actions/query.actions';
import { setPrincipleReport } from './actions/report.actions';
import { ReconciliationReportFacade } from './reconciliation-report.facade';
import { type IReconciliatonQueryState } from './reducers/query.reducers';

@Injectable()
export class ReconciliationReportEffects {
  private _actions$ = inject(Actions);
  private _reportFacade = inject(ReconciliationReportFacade);
  loadReport$: Observable<Action> = createEffect(() => this._loadReport$());
  setPrincipleReport$: Observable<Action> = createEffect(() =>
    this._setPrincipleReport$()
  );
  // setTyroReport$: Observable<Action> = createEffect(() =>
  //   this._setTyroReport$()
  // );
  // setHealthPointReport$: Observable<Action> = createEffect(() =>
  //   this._setHealthPointReport$()
  // );

  private _loadReport$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(
        ReconciliationReportActions.SetDateRange,
        ReconciliationReportActions.SetPractice
      ),
      map(() => loadReport())
    );
  }

  private _setPrincipleReport$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(ReconciliationReportActions.LoadReport),
      withLatestFrom(this._reportFacade.query$),
      tap(() => this._reportFacade.loading$.next(true)),
      switchMap(([_, query]) => this._getPrincipleReportWithFirestore(query)),
      tap(() => this._reportFacade.loading$.next(false)),
      serialise$(),
      map((report) => setPrincipleReport({ report }))
    );
  }

  // private _setTyroReport$(): Observable<Action> {
  //   return this._actions$.pipe(
  //     ofType(ReconciliationReportActions.LoadReport),
  //     withLatestFrom(this._reportFacade.query$),
  //     switchMap(([_, query]) => this._getTyroReport$(query)),
  //     map((report) => setTyroReport({ report }))
  //   );
  // }

  // private _setHealthPointReport$(): Observable<Action> {
  //   return this._actions$.pipe(
  //     ofType(ReconciliationReportActions.LoadReport),
  //     withLatestFrom(this._reportFacade.query$),
  //     switchMap(([_, query]) => this._getHealthPointReport$(query)),
  //     map((report) => setHealthPointReport({ report }))
  //   );
  // }

  // private _getPrincipleReportWithBigQuery$(
  //   query: IReconciliatonQueryState
  // ): Observable<IPrincipleReconciliationReportData | undefined> {
  //   if (!query.practice) {
  //     return of();
  //   }
  //   const dataSource = new BigQueryDataSource<ITransactionFact>(
  //     this._currentScope.currentBrand$.pipe(filterUndefined())
  //   );
  //   const startOfDay = moment(query.date).startOf('day');
  //   const endOfDay = moment(query.date).endOf('day');
  //   const bigQueryRequest: IReportingQuery = {
  //     table: BigQueryTable.TransactionAdd,
  //     attributes: ['*'],
  //     filters: [
  //       documentReferenceFilter('practice.ref', '=', query.practice.ref),
  //     ],
  //   };

  //   return from(dataSource.build(bigQueryRequest, startOfDay, endOfDay)).pipe(
  //     map((response) =>
  //       transactionsToReconiliationReport(
  //         response.map((fact) => fact.transaction)
  //       )
  //     )
  //   );
  // }

  private async _getPrincipleReportWithFirestore(
    query: IReconciliatonQueryState
  ): Promise<IPrincipleReconciliationReportData | undefined> {
    if (!query.practice) {
      return undefined;
    }

    return ReportingFunctions.getReconciliationReport({
      startDate: toTimestamp(query.range.from),
      endDate: toTimestamp(query.range.to),
      practiceRef: query.practice.ref,
    });
  }

  // private _getTyroReport$(
  //   query: IReconciliatonQueryState
  // ): Observable<ReconciliationReportResponse | undefined> {
  //   return this._getMid$().pipe(
  //     switchMap((mid) =>
  //       mid
  //         ? this._tyro.reconciliationReport$({
  //             format: 'txt',
  //             type: 'summary',
  //             terminalBusinessDay: toHealthPointDate(query.date),
  //             mid,
  //           })
  //         : of(undefined)
  //     )
  //   );
  // }

  // private _getHealthPointReport$(
  //   query: IReconciliatonQueryState
  // ): Observable<HealthPointReconciliationReportResponse | undefined> {
  //   const request: IHealthPointReconciliationReportRequestParams = {
  //     reconDate: toHealthPointDate(query.date),
  //     // reconDate: '20120401',
  //     reportType: 'claims',
  //   };
  //   return this._tyro.healthpointReconciliationReport$(request);
  // }

  // private _getMid$(): Observable<number | undefined> {
  //   // TODO: Figure out which MID to use/include
  //   // https://app.clickup.com/t/3aw8nt
  //   return of(undefined);
  // }
}
