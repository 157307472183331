import { isObject } from '@principle-theorem/shared';
import { isString } from 'lodash';
import {
  type IBaseTransactionCompleteCallbackData,
  type IIntegrationKeyRequestParams,
  isBaseTransactionCompleteCallbackData,
  type ITerminalRequestParams,
} from './tyro-callbacks';

export interface IWithTransactionCompleteCallback {
  transactionCompleteCallback: (
    data: IPurchaseTransactionCompleteCallbackData
  ) => unknown;
}

/**
 * Transaction Complete Callback
 * Invoked when the transaction has been completed on the terminal.
 */
export interface IPurchaseTransactionCompleteCallbackData
  extends IBaseTransactionCompleteCallbackData {
  /**
   * The scheme displayed on the card (Visa, Mastercard, etc).
   */
  cardType: string;

  /**
   * Also known as a STAN, this is Tyro's reference to this transaction. Quote
   * this to Tyro Customer Support if you run into any issues with a transaction.
   */
  transactionReference: string;

  /**
   * The Scheme's reference to the transaction. Quote this number to
   * Visa/Mastercard/etc if the issue is on their end.
   */
  authorisationCode: string;

  /**
   * The raw result code returned by the card issuer.
   */
  issuerActionCode: string;

  /**
   * The (elided) credit card number used for this transaction.
   */
  elidedPan?: string;

  /**
   * The Retrieval Reference Number is a unique number generated by the Payment
   * Service for a specific merchant. Guaranteed to be unique for a 7-day period.
   */
  rrn?: string;

  /**
   * If you are performing a Tip Completion, the tip component will be returned
   * here, in cents.
   */
  tipAmount?: string;

  /**
   * Tyro's reference to the Tip Completion.
   */
  tipCompletionReference?: string;

  /**
   * Tyro's reference to a Tab Completion.
   */
  tabCompletionReference?: string;

  /**
   * Tyro's reference to a PreAuth Completion.
   */
  preAuthCompletionReference?: string;

  /**
   * If you are requesting a Card Token, the Card Token will be returned here,
   * in 30 case insensitive alphanumeric characters.
   */
  cardToken?: string;

  /**
   * If you are requesting a Card Token, the expiry of the Card Token will be
   * returned here, in yyyy-MM-dd format.
   */
  cardTokenExpiryDate?: string;

  /**
   * If you are requesting a Card Token, the status code of the Card Token
   * request will be returned here, 00 indicates success.
   */
  cardTokenStatusCode?: string;

  /**
   * If you are requesting a Card Token and Tyro fails to generate one for you,
   * the reason for the failure will be returned here.
   */
  cardTokenErrorMessage?: string;

  /**
   * Text representation of the Tyro receipt intended for the customer. Only
   * included if integrated receipt printing is enabled. Formatted to be printed
   * in a monospaced font.
   */
  customerReceipt?: string;

  /**
   * Not documented officially but is present on APPROVED responses.
   * Will be the amount the customer pays, this can be misleading when
   * paying with a dfferent currency.
   * In dollars. eg "100.00"
   */
  transactionAmount: string;

  /**
   * Not documented officially but is present on APPROVED responses.
   * Will be the amount requested by the terminal.
   * In dollars. eg "100.00"
   */
  baseAmount: string;
}
interface IIntegratedReceiptParams {
  /**
   * indicate whether receipts will be printed on the POS (true) or on the
   * terminal (false).
   */
  integratedReceipt: boolean;
  integratedReceiptWidth?: string;
}
export interface IPurchaseRequestParams
  extends ITerminalRequestParams,
    IIntegrationKeyRequestParams,
    IIntegratedReceiptParams {
  /**
   * The purchase amount (amount to charge the customer) in cents.
   */
  amount: string;

  /**
   * Cash out amount in cents.
   */
  cashout?: string;

  /**
   * Supply a transaction Id to be used for the transaction.
   */
  transactionId?: string;

  /**
   * The integrated transaction ID of the original HealthPoint Claim (used
   * for gap payments).
   */
  healthpointTransactionId?: string;

  /**
   * Apply a surcharge to this transaction (if the card used attracts a
   * surcharge).
   */
  enableSurcharge?: boolean;

  /**
   * Request a token representing the card used for the current purchase.
   */
  requestCardToken?: boolean;
}

export interface IRefundRequestParams
  extends ITerminalRequestParams,
    IIntegrationKeyRequestParams,
    IIntegratedReceiptParams {
  /**
   * The purchase amount (amount to charge the customer) in cents.
   */
  amount: string;
  /**
   * Supply a transaction Id to be used for the transaction.
   */
  transactionId?: string;
}

export function isTransactionCompleteCallbackData(
  data: unknown
): data is IPurchaseTransactionCompleteCallbackData {
  return (
    isObject(data) &&
    isBaseTransactionCompleteCallbackData(data) &&
    isString(data.transactionReference) &&
    isString(data.issuerActionCode) &&
    isString(data.transactionAmount)
  );
}
