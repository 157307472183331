import {
  type ISourceEntity,
  SourceEntityMigrationType,
  IPracticeMigration,
  IExpectedSourceRecordSize,
} from '@principle-theorem/principle-core/interfaces';
import { SourceEntity } from '../../../source/source-entity';
import {
  Timezone,
  TypeGuard,
  WithRef,
  toInt,
  toTimestamp,
} from '@principle-theorem/shared';
import { flow, groupBy, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { runQuery } from '../../../source/connection';

export const PATIENT_APPOINTMENT_BOOK_RESOURCE_TYPE = 'appointmentBook';

export const PATIENT_APPOINTMENT_BOOK_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Appointment Book List',
      description: '',
      idPrefix: PATIENT_APPOINTMENT_BOOK_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IOasisAppointmentBook {
  id: number;
  description: string;
}

interface IOasisAppointmentBookResult {
  id: number;
  name: string;
}

export function isOasisAppointmentBook(
  item: unknown
): item is IOasisAppointmentBook {
  return TypeGuard.interface<IOasisAppointmentBook>({
    id: isNumber,
    description: isString,
  })(item);
}

const PATIENT_APPOINTMENT_BOOK_SOURCE_QUERY = `
SELECT
  convert_to_integer(f1) AS id,
  f2 AS name
FROM SYTBLENT
WHERE SKEY LIKE 'APPVIEWLE%'
`;

export class PatientAppointmentBookSourceEntity extends BaseSourceEntity<
  IOasisAppointmentBook,
  unknown,
  object
> {
  sourceEntity = PATIENT_APPOINTMENT_BOOK_SOURCE_ENTITY;
  entityResourceType = PATIENT_APPOINTMENT_BOOK_RESOURCE_TYPE;
  sourceQuery = PATIENT_APPOINTMENT_BOOK_SOURCE_QUERY;
  verifySourceFn = isOasisAppointmentBook;
  override transformDataFn = flow([transformQueryResults]);

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const response = await runQuery<IOasisAppointmentBookResult>(
      migration,
      this.sourceQuery
    );
    const records = this.transformDataFn(
      response.rows
    ) as IOasisAppointmentBook[];

    return {
      expectedSize: records.length,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }

  translate(_book: IOasisAppointmentBook, _timezone: Timezone): unknown {
    return {};
  }

  getSourceRecordId(data: IOasisAppointmentBook): number {
    return data.id;
  }

  getSourceLabel(data: IOasisAppointmentBook): string {
    return data.description;
  }
}

function transformQueryResults(
  queryResults: IOasisAppointmentBookResult[]
): IOasisAppointmentBook[] {
  const groupedResults = groupBy(queryResults, 'id');
  return Object.entries(groupedResults).map(([id, records]) => ({
    id: toInt(id),
    description: records.map((result) => result.name).join(', '),
  }));
}
