import { type IXSLXImport, toISODate } from '@principle-theorem/shared';
import type { Row } from 'exceljs';
import { isString } from 'lodash';
import * as moment from 'moment-timezone';
import { type IPatientXSLX, PATIENT_HEADERS } from './patients-to-xlsx';

export class XSLXToPatients implements IXSLXImport<IPatientXSLX> {
  headers = PATIENT_HEADERS;

  translate(row: Row): IPatientXSLX {
    const dateOfBirth = row.getCell('dateOfBirth').value;
    return {
      patientId: row.getCell('patientId').value?.toString() ?? '',
      dateOfBirth: isString(dateOfBirth)
        ? dateOfBirth
        : dateOfBirth instanceof Date
          ? toISODate(moment(dateOfBirth))
          : undefined,
      lastName: row.getCell('lastName').value?.toString() ?? '',
      firstName: row.getCell('firstName').value?.toString() ?? '',
    };
  }
}
