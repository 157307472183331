<mat-toolbar color="accent" class="flex items-center justify-between gap-2">
  <span>
    <button
      mat-icon-button
      *ngIf="sidebar.isMobile$ | async"
      (click)="sidebar.open()"
    >
      <mat-icon>menu</mat-icon>
    </button>
  </span>

  <ng-container *ngIf="(breakpoint.isMobile$ | async) === false">
    <span
      class="search-bar flex flex-1 items-center justify-center gap-2"
      (click)="search()"
    >
      <mat-icon>search</mat-icon>
      <span>Search & Quick Actions</span>
    </span>
  </ng-container>

  <ng-container>
    <pt-buttons-container>
      <button
        *ngIf="breakpoint.isMobile$ | async"
        mat-icon-button
        (click)="search()"
      >
        <mat-icon>search</mat-icon>
      </button>
      @if (helpArticleId$ | async; as id) {
        <button
          class="!mr-0"
          mat-icon-button
          matTooltip="Read Help Article"
          (click)="intercom.showArticle(id)"
        >
          <mat-icon>help</mat-icon>
        </button>
      }
      <button class="!mr-0" mat-icon-button (click)="showChats()">
        @if (chatCount) {
          <mat-icon
            aria-hidden="false"
            [matBadge]="chatCount >= 10 ? '10+' : chatCount"
            [matBadgeHidden]="!chatCount"
            matBadgeColor="warn"
          >
            chat
          </mat-icon>
        } @else {
          <mat-icon>chat</mat-icon>
        }
      </button>
      <button
        class="!mr-2"
        mat-icon-button
        (click)="notificationsToggle.emit()"
      >
        @if (notificationsCount) {
          <mat-icon
            aria-hidden="false"
            [matBadge]="notificationsCount >= 10 ? '10+' : notificationsCount"
            [matBadgeHidden]="!notificationsCount"
            matBadgeColor="warn"
          >
            notifications
          </mat-icon>
        } @else {
          <mat-icon>notifications</mat-icon>
        }
      </button>
      <pr-toolbar-dropdown />
      <button
        mat-mini-fab
        class="!min-h-[40px]"
        id="intercom-launcher"
        aria-label="Customer Support Button"
        matTooltip="Customer Support"
      >
        <mat-icon class="intercom-icon">question_answer</mat-icon>
      </button>
    </pt-buttons-container>
  </ng-container>
</mat-toolbar>
