<div fxLayout="row" fxFill>
  <pr-history-list
    [items]="historyItems$ | ngrxPush"
    [selected]="selectedHistoryItem$ | ngrxPush"
    (selectedChange)="selectedInvoice$.next($event.value)"
   />
  <div fxFlex>
    <pr-invoice-display
      [invoice]="selectedInvoice$ | ngrxPush"
     />
  </div>
</div>
