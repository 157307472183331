import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ITransaction,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  TIME_FORMAT,
  WithRef,
} from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-patient-view-invoice-transaction',
  templateUrl: './patient-view-invoice-transaction.component.html',
  styleUrl: './patient-view-invoice-transaction.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientViewInvoiceTransactionComponent {
  dateFormat = `${DAY_MONTH_YEAR_FORMAT} - ${TIME_FORMAT}`;
  transaction$ = new ReplaySubject<WithRef<ITransaction<unknown>>>(1);
  isOutgoing$: Observable<boolean>;
  hasDescription$: Observable<boolean>;

  @Input()
  set transaction(transaction: WithRef<ITransaction<unknown>>) {
    if (transaction) {
      this.transaction$.next(transaction);
    }
  }

  constructor() {
    this.isOutgoing$ = this.transaction$.pipe(
      map((transaction) => transaction.type === TransactionType.Outgoing)
    );
    this.hasDescription$ = this.transaction$.pipe(
      map((transaction) => !!transaction.description)
    );
  }
}
