import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  GlobalStoreService,
  TreatmentCategorySelectorBloc,
} from '@principle-theorem/ng-principle-shared';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { stafferToNamedDoc } from '@principle-theorem/principle-core';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import {
  IDepositLineItem,
  ITreatmentCategory,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';

export interface IAccountCreditReservedForSelectorDialogRequest {
  practice?: WithRef<IPractice>;
  lineItem?: IDepositLineItem;
}

export type IAccountCreditReservedForSelectorDialogResponse = Pick<
  IDepositLineItem,
  'attributedTo' | 'forTreatmentCategoryRef'
>;

interface IReservedForFormData {
  staffer: INamedDocument<IStaffer>;
  treatmentCategory: DocumentReference<ITreatmentCategory>;
}

@Component({
    selector: 'pr-account-credit-reserved-for-selector-dialog',
    templateUrl: './account-credit-reserved-for-selector-dialog.component.html',
    styleUrls: ['./account-credit-reserved-for-selector-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AccountCreditReservedForSelectorDialogComponent {
  permissions = [PatientPermissions.ReceiveDeposits];
  isEdit: boolean = false;
  reservedForForm = new TypedFormGroup<IReservedForFormData>({
    staffer: new TypedFormControl<INamedDocument<IStaffer> | undefined>(),
    treatmentCategory: new TypedFormControl<
      DocumentReference<ITreatmentCategory> | undefined
    >(),
  });
  treatmentCategoryBloc: TreatmentCategorySelectorBloc;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IAccountCreditReservedForSelectorDialogRequest,
    public dialogRef: MatDialogRef<
      AccountCreditReservedForSelectorDialogComponent,
      IAccountCreditReservedForSelectorDialogResponse
    >,
    private _globalStore: GlobalStoreService
  ) {
    if (data.lineItem) {
      this.isEdit = true;
      this.reservedForForm.patchValue({
        staffer: data.lineItem.attributedTo,
        treatmentCategory: data.lineItem.forTreatmentCategoryRef,
      });
    }
    this.treatmentCategoryBloc = new TreatmentCategorySelectorBloc(
      this._globalStore.treatmentCategories$,
      this.reservedForForm.controls.treatmentCategory
    );
  }

  submit(): void {
    if (!this.reservedForForm.valid) {
      return;
    }
    const formValue = this.reservedForForm.value;
    const attributedTo = formValue.staffer
      ? stafferToNamedDoc(formValue.staffer)
      : undefined;
    this.dialogRef.close({
      attributedTo,
      forTreatmentCategoryRef: formValue.treatmentCategory,
    });
  }
}
