import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import {
  ISchedulingEventDisplay,
  SchedulingEvent,
  SchedulingEventSummary,
} from '@principle-theorem/principle-core';
import {
  IAppointment,
  IPatient,
  ISchedulingEventSnapshotSummary,
  ISchedulingEventSummary,
  ITreatmentCategory,
  ITreatmentStep,
  SchedulingEventType,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  DocumentReference,
  ISODateType,
  SystemActors,
  TIME_FORMAT,
  WithRef,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationModule } from '../../../navigation/navigation.module';
import { TreatmentCategoryIndicatorComponent } from '../../treatment-category-indicator/treatment-category-indicator.component';
import { SchedulingEventSnapshotComponent } from '../scheduling-event-snapshot/scheduling-event-snapshot.component';

@Component({
    selector: 'pr-scheduling-event-display',
    templateUrl: './scheduling-event-display.component.html',
    styleUrls: ['./scheduling-event-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        NgSharedModule,
        NavigationModule,
        SchedulingEventSnapshotComponent,
        TreatmentCategoryIndicatorComponent,
    ]
})
export class SchedulingEventDisplayComponent {
  readonly dateFormat = DAY_MONTH_YEAR_FORMAT;
  readonly timeFormat = TIME_FORMAT;
  schedulingEventSummary$ = new ReplaySubject<WithRef<ISchedulingEventSummary>>(
    1
  );
  day$ = new BehaviorSubject<ISODateType | undefined>(undefined);
  display$: Observable<ISchedulingEventDisplay>;
  appointment$: Observable<WithRef<IAppointment>>;
  patient$: Observable<WithRef<IPatient>>;
  treatmentStep$: Observable<WithRef<ITreatmentStep>>;
  treatmentCategory$: Observable<
    DocumentReference<ITreatmentCategory> | undefined
  >;
  unknown = SystemActors.Unknown;

  @Input()
  set schedulingEventSummary(
    schedulingEventSummary: WithRef<ISchedulingEventSummary>
  ) {
    if (schedulingEventSummary) {
      this.schedulingEventSummary$.next(schedulingEventSummary);
    }
  }

  @Input()
  set day(day: ISODateType) {
    this.day$.next(day);
  }

  constructor() {
    this.display$ = this.schedulingEventSummary$.pipe(
      map((schedulingEventSummary) =>
        SchedulingEvent.getDisplay(schedulingEventSummary.eventType)
      )
    );
  }

  getActionLabel(
    schedulingEventSummary: WithRef<ISchedulingEventSummary>
  ): string {
    return SchedulingEventSummary.getActionlabel(schedulingEventSummary);
  }

  getReasonName(
    schedulingEventSummary: WithRef<ISchedulingEventSummary>
  ): string {
    return (
      schedulingEventSummary.reasonName ??
      SchedulingEventSummary.getActionlabel(schedulingEventSummary)
    );
  }

  eventTypeForDay$(
    snapshot?: ISchedulingEventSnapshotSummary
  ): Observable<SchedulingEventType | undefined> {
    return combineLatest([this.schedulingEventSummary$, this.day$]).pipe(
      map(([summary, day]) =>
        !day || snapshot?.date === day ? summary.eventType : undefined
      )
    );
  }
}
