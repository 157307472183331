import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IInvoice } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { TransactionProviders } from '../transaction-providers/transaction-providers.service';

@Component({
  selector: 'pr-add-transaction',
  templateUrl: './add-transaction.component.html',
  styleUrls: ['./add-transaction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddTransactionComponent {
  invoice$ = new ReplaySubject<WithRef<IInvoice>>(1);

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this.invoice$.next(invoice);
    }
  }

  constructor(public transactions: TransactionProviders) {}
}
