import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountingFunctionsService } from '@principle-theorem/ng-principle-accounting';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import {
  Invoice,
  Practice,
  RefundHelpers,
} from '@principle-theorem/principle-core';
import {
  IInvoice,
  IPractice,
  ITransaction,
  TransactionAction,
  TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  Region,
  snapshot,
  WithRef,
} from '@principle-theorem/shared';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RefundDepositPromptService } from '../../transaction-components/refund-deposit-prompt/refund-deposit-prompt.service';
import { TransactionAmountDialog } from '../../transaction-components/transaction-amount-dialog/transaction-amount-dialog.service';
import { refundAvailableInfo } from '../../transaction-helpers';
import {
  ITransactionProvider,
  TransactionProviderType,
} from '../../transaction-provider';
import { HicapsConnectService } from '../hicaps-connect.service';
import { HicapsConnectEftposRequestBuilder } from './hicaps-connect-eftpos-request-builder';
import { HicapsConnectEftposTransactionBuilder } from './hicaps-connect-eftpos-transaction-builder';
import { FeatureFlagsService } from '@principle-theorem/ng-feature-flags';
import { HICAPS_CONNECT_FEATURE } from '@principle-theorem/principle-core/features';

@Injectable()
export class HicapsConnectEftposTransactionProvider
  implements ITransactionProvider
{
  providerId = TransactionProvider.HicapsConnectEftpos;
  providerType = TransactionProviderType.Payment;
  providerRegions = [Region.Australia];
  isEnabled$: Observable<boolean>;

  constructor(
    private _hicapsConnect: HicapsConnectService,
    private _currentScopeFacade: CurrentScopeFacade,
    private _amountDialog: TransactionAmountDialog,
    private _accountFunctions: AccountingFunctionsService,
    private _refundDepositPrompt: RefundDepositPromptService,
    private _featureFlags: FeatureFlagsService,
    private _snackbar: MatSnackBar
  ) {
    this.isEnabled$ = this._currentScopeFacade.currentPractice$.pipe(
      map(
        (practice) =>
          this._featureFlags.isFeatureEnabled(HICAPS_CONNECT_FEATURE) &&
          Practice.isHicapsConnectEnabled(practice)
      )
    );
  }

  canCapture$(invoice: WithRef<IInvoice>): Observable<boolean> {
    const canCapture =
      !Invoice.isPaid(invoice) && Invoice.canAddTransactions(invoice);
    return of(canCapture);
  }

  async capture(
    invoice: WithRef<IInvoice>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const formData = await this._amountDialog.openForInvoice(
      'Hicaps - EFTPOS Payment',
      invoice
    );
    if (!formData || formData.amount <= 0) {
      return;
    }
    const terminal = await this._hicapsConnect.selectTerminal(invoice.practice);
    if (!terminal) {
      return;
    }
    const practiceRef = Firestore.getParentDocRef<IPractice>(terminal.ref);
    const settings = await this._hicapsConnect.getPracticeSettings(practiceRef);
    const merchantId = settings?.merchantId;
    if (!merchantId) {
      this._snackbar.open('Merchant Id not configured');
      return;
    }

    const request = HicapsConnectEftposRequestBuilder.buildSale(
      this._hicapsConnect.getConfig(),
      formData,
      terminal,
      merchantId
    );

    const api = this._hicapsConnect.getDeviceAPI(terminal.bridgeDevice);

    this._snackbar.open('Sending request to terminal');
    const response = await snapshot(api.sendSale(request));

    const transaction =
      await HicapsConnectEftposTransactionBuilder.toEftposTransaction(
        invoice,
        practiceRef,
        request,
        response
      );

    return this._accountFunctions.addTransactionToInvoice(
      invoice,
      transaction,
      TransactionAction.Add
    );
  }

  canRefund$(invoice: WithRef<IInvoice>): Observable<boolean> {
    return RefundHelpers.isRefundable$(invoice);
  }

  refundInfo$(invoice: WithRef<IInvoice>): Observable<string[]> {
    return Invoice.transactions$(invoice).pipe(
      map((transactions) =>
        RefundHelpers.getTotalRefundRemaining(transactions)
      ),
      map((refundRemaining) => [refundAvailableInfo(refundRemaining)])
    );
  }

  async refund(
    invoice: WithRef<IInvoice>,
    transaction?: WithRef<ITransaction<unknown>>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const acceptedRefundAlert =
      await this._refundDepositPrompt.showRefundDepositAlert(invoice);
    if (!acceptedRefundAlert) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }
    const formData = await this._amountDialog.openRefund(
      'Hicaps - EFTPOS Refund',
      invoice,
      transaction
    );
    if (!formData || formData.amount <= 0) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }

    const terminal = await this._hicapsConnect.selectTerminal(invoice.practice);
    if (!terminal) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }

    const practiceRef = Firestore.getParentDocRef<IPractice>(terminal.ref);
    const settings = await this._hicapsConnect.getPracticeSettings(practiceRef);
    const merchantId = settings?.merchantId;
    if (!merchantId) {
      this._snackbar.open('Merchant Id not configured');
      return;
    }

    const request = HicapsConnectEftposRequestBuilder.buildRefund(
      this._hicapsConnect.getConfig(),
      formData,
      terminal,
      merchantId
    );
    this._snackbar.open('Sending request to terminal');

    const api = this._hicapsConnect.getDeviceAPI(terminal.bridgeDevice);
    const response = await snapshot(api.sendRefund(request));

    const refundTransaction =
      await HicapsConnectEftposTransactionBuilder.toRefundTransaction(
        invoice,
        practiceRef,
        request,
        response
      );

    return this._accountFunctions.addTransactionToInvoice(
      invoice,
      refundTransaction,
      TransactionAction.Add
    );
  }
}
