<div class="mx-auto mb-16 mt-4 w-full max-w-5xl">
  <div class="flex flex-col gap-4 p-6">
    <div class="flex flex-row items-center gap-4">
      <h2 class="!m-0 flex grow !font-bold">{{ data.selectedForm.name }}</h2>
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>

    @if (submitting()) {
      <pr-patient-portal-loader> Submitting </pr-patient-portal-loader>
    } @else if (error()) {
      <pr-patient-portal-splash type="error">
        Error! {{ error() }}
      </pr-patient-portal-splash>
    } @else {
      @if (isPatientDetailsForm) {
        <pr-patient-details-form
          [healthCardTypes]="data.healthCardTypes"
          [referralSources]="data.referralSources"
          [requiredFields]="data.requiredFields"
          (patientDetailsChange)="patientDetailsFormData.set($event)"
        />
      } @else {
        <pr-custom-form
          [form]="data.selectedForm.data"
          [hideSubmit]="true"
          (isValid)="customFormIsValid.set($event)"
          (formChanges)="customFormState.set($event)"
        />
      }

      <div>
        <button
          mat-flat-button
          color="primary"
          [disabled]="submitDisabled()"
          (click)="submit()"
        >
          Submit
        </button>
      </div>
    }
  </div>
</div>
