export interface IDestinationJobFilter<T extends string = string> {
  key: T;
  value: string;
}

export type DataResolverFn<JobData extends object> = (
  jobData: JobData
) => string | undefined;

export interface IDestinationJobFilterHandler<JobData extends object> {
  key: string;
  label: string;
  description: string;
  canHandle(filter: IDestinationJobFilter): boolean;
  matches(jobData: JobData, filter: IDestinationJobFilter): boolean;
}
