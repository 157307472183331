import { type WithRef } from '@principle-theorem/shared';
import {
  type ITask,
  TaskPriority,
} from '@principle-theorem/principle-core/interfaces';
import { getSchemaText } from '@principle-theorem/editor';

export interface ITaskSortOption {
  id: string;
  icon: string;
  label: string;
  direction: 'asc' | 'desc';
  sortAccessor: (task: WithRef<ITask>) => number | string;
  sort?: (a: WithRef<ITask>, b: WithRef<ITask>) => number;
}

export const TASK_TITLE_SORT: ITaskSortOption = {
  id: 'title',
  icon: 'title',
  label: 'Title',
  direction: 'asc',
  sortAccessor: (task: WithRef<ITask>) => {
    return getSchemaText(task.title);
  },
};

export const TASK_CREATED_SORT: ITaskSortOption = {
  id: 'createdAt',
  icon: 'acesss_time',
  label: 'Newest',
  direction: 'desc',
  sortAccessor: (task: WithRef<ITask>) => {
    return task.createdAt.seconds;
  },
};

export const TASK_DUE_DATE_SORT: ITaskSortOption = {
  id: 'dueDate',
  icon: 'date_range',
  label: 'Due Date',
  direction: 'asc',
  sortAccessor: (task: WithRef<ITask>) => {
    if (!task.dueDate) {
      return Infinity;
    }
    return task.dueDate.seconds;
  },
};

export const TASK_PRIORITY_SORT: ITaskSortOption = {
  id: 'priorityIndex',
  icon: 'priority_high',
  label: 'Priority',
  direction: 'desc',
  sortAccessor: (task: WithRef<ITask>) => {
    switch (task.priority) {
      case TaskPriority.High:
        return 0;
      case TaskPriority.Medium:
        return 1;
      default:
        return 2;
    }
  },
};

export const TASK_COMPLETED_DATE_SORT: ITaskSortOption = {
  id: 'completedDate',
  icon: 'done',
  label: 'Completed Date',
  direction: 'desc',
  sortAccessor: (task: WithRef<ITask>) => {
    if (!task.completedDate) {
      return Infinity;
    }
    return task.completedDate.seconds;
  },
};

export const TASK_SORT_OPTIONS: ITaskSortOption[] = [
  TASK_DUE_DATE_SORT,
  TASK_CREATED_SORT,
  TASK_PRIORITY_SORT,
  TASK_TITLE_SORT,
  TASK_COMPLETED_DATE_SORT,
];

export function taskSortOptionById(
  sortId: string
): ITaskSortOption | undefined {
  return TASK_SORT_OPTIONS.find((sortOption) => sortOption.id === sortId);
}
