import { isArray, isObject } from './common';
import {
  serialise,
  unserialise,
} from './serialisers/primitive-object-serialiser';

export function toJSONL<T>(data: T | T[]): string {
  if (isArray(data)) {
    return data.map((item) => toJSON(item)).join('\n');
  }
  return toJSON(data);
}

export function toJSON<T>(data: T): string {
  return JSON.stringify(serialise(data));
}

export function fromJSON<T>(dataStr: string): T {
  try {
    const data = JSON.parse(dataStr) as unknown;
    if (!isObject(data)) {
      throw new Error('Falied to unseralise data');
    }
    return unserialise(data) as T;
  } catch (error) {
    throw new Error(`Failed to parse serialised data: ${String(error)}`);
  }
}
