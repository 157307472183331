import { AtLeast, Region } from '@principle-theorem/shared';
import {
  IServiceCode,
  IServiceProvider,
  ItemCodeAliases,
  ServiceCodeType,
} from '../service-item';
import { ServiceCodeScope } from './ada-code';
import { ChartableSurface } from '../../core/chartable-surface';
import { TaxStrategy } from '@principle-theorem/accounting';

// https://www.acc.co.nz/assets/provider/ACC1522-Dentists-costs.pdf

export const BASE_ACC_CODES: Record<
  string,
  AtLeast<IServiceCode, 'code' | 'title' | 'fee'>
> = {
  DE1: {
    code: 'DE1',
    title: 'Dental consultation, including examination',
    notes: '6 monthly or for a new treatment plan',
    fee: 59.86,
  },
  DE2: {
    code: 'DE2',
    title: 'Periodic oral examination/review',
    notes: 'not to be claimed the same day as treatment',
    fee: 38.48,
  },
  DE3: {
    code: 'DE3',
    title: 'Extended initial examination',
    notes:
      'Complex cases relating to dental implants, orthodontics, and advanced restorative work - including study models and photographs, and tomography',
    fee: 165.26,
  },
  DX1: {
    code: 'DX1',
    title: 'Periapical or bitewing film (each)',
    fee: 29.91,
  },
  DX2: {
    code: 'DX2',
    title: 'Occlusal (each)',
    fee: 29.91,
  },
  DX3: {
    code: 'DX3',
    title: 'Panorex',
    fee: 78.21,
  },
  DX4: {
    code: 'DX4',
    title: 'Other additional images',
    notes: 'per treatment episode',
    fee: 11.8,
  },
  DX7: {
    code: 'DX7',
    title: 'Acute sedation',
    notes: 'IV only - initial consultation only',
    fee: 206.55,
  },
  DT1: {
    code: 'DT1',
    title: 'Emergency temporary cover',
    notes: 'not to be used with a definitive treatment code',
    fee: 57.94,
  },
  DG1: {
    code: 'DG1',
    title: 'Extraction of permanent or deciduous tooth',
    notes:
      'Per first tooth (deciduous teeth must have sufficient root to be classified as an extraction - evidence of this is required)',
    fee: 154.57,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  DG2: {
    code: 'DG2',
    title: 'Surgical removal of tooth',
    notes: 'includes insertion and removal of sutures',
    fee: 247.87,
  },
  DG4: {
    code: 'DG4',
    title: 'Extraction of subsequent permanent or deciduous tooth',
    notes: 'In same quadrant as for DG1',
    fee: 84.46,
  },
  DG5: {
    code: 'DG5',
    title: 'Management of minor lacerations by suturing',
    notes:
      'Per operative site - initial examination or emergency use only, includes removal of sutures',
    fee: 161.13,
  },
  DG7: {
    code: 'DG7',
    title: 'Incision and drainage abscess cellulitis',
    fee: 181.76,
  },
  DG8: {
    code: 'DG8',
    title: 'Excision of traumatic mucous cyst',
    fee: 214.83,
  },
  DG10: {
    code: 'DG10',
    title: 'Splint application or removal',
    notes: 'For 3 splint units',
    fee: 123.92,
  },
  DG11: {
    code: 'DG11',
    title: 'Cleaning of wound and removal of debris',
    notes: 'Initial examination or emergency use only',
    fee: 56.65,
  },
  DG14: {
    code: 'DG14',
    title: 'Reduction of fractured alveolar process',
    fee: 120.66,
  },
  DG15: {
    code: 'DG15',
    title: 'Repositioning of displaced tooth (per tooth)',
    notes: 'Or replacing avulsed tooth',
    fee: 60.31,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  DG17: {
    code: 'DG17',
    title: 'Occlusal adjustment',
    notes:
      'Simple - for use on accident related teeth only and not to be used with restorative codes',
    fee: 40.18,
  },
  DG22: {
    code: 'DG22',
    title: 'Minor surgical operations',
    notes:
      'Not otherwise covered by this schedule - provide clinical reasoning at time of invoice',
    fee: 173.02,
  },
  DG23: {
    code: 'DG23',
    title: 'Provision of bite splints',
    fee: 247.87,
  },
  DR1: {
    code: 'DR1',
    title: 'Amalgam 1 surface filling',
    notes: 'Including 2 fillings on the one surface',
    fee: 109.77,
  },
  DR2: {
    code: 'DR2',
    title: 'Amalgam 2 surface filling',
    notes: 'Approximo-occlusal',
    fee: 143.58,
  },
  DR3: {
    code: 'DR3',
    title: 'Complex amalgam restoration',
    fee: 207.72,
  },
  DR6: {
    code: 'DR6',
    title: 'Non-metallic simple fillings',
    notes: 'Including 2 fillings on the one surface',
    fee: 126.64,
  },
  DR7: {
    code: 'DR7',
    title: 'Non-metallic filling',
    notes: '2 or more surfaces per tooth',
    fee: 168.9,
  },
  DR8: {
    code: 'DR8',
    title: 'Rebonding tooth fragment or coronal portion',
    notes: 'If used with a restorative code DR6 - must show justification',
    fee: 113.53,
  },
  DR9: {
    code: 'DR9',
    title: 'Complex reconstruction in composite resin',
    notes: 'Direct',
    fee: 225.5,
  },
  DP1: {
    code: 'DP1',
    title: 'Plastic denture',
    notes: '1 tooth - material of choice',
    fee: 632.24,
  },
  DP2: {
    code: 'DP2',
    title: 'Each additional tooth',
    notes: 'All dentures',
    fee: 28.32,
  },
  DP5: {
    code: 'DP5',
    title: 'Metal-framed partial denture',
    notes: '1 tooth',
    fee: 1447.84,
  },
  DP7: {
    code: 'DP7',
    title: 'Transitional denture replacing missing tooth or teeth',
    fee: 576,
  },
  DP8: {
    code: 'DP8',
    title: 'Full upper or lower denture',
    fee: 944.27,
  },
  DP11: {
    code: 'DP11',
    title: 'Reline or rebase denture',
    fee: 295.62,
  },
  DP13: {
    code: 'DP13',
    title: 'Repair',
    notes: 'All types',
    fee: 96.55,
  },
  DP14: {
    code: 'DP14',
    title: 'Addition of tooth to existing denture',
    notes: 'Includes additional tooth',
    fee: 177.34,
  },
  DC3: {
    code: 'DC3',
    title: 'Indirect inlay/onlay',
    fee: 360.51,
  },
  DC6: {
    code: 'DC6',
    title: 'Porcelain veneer',
    notes: 'Includes any temporary treatment',
    fee: 1061.36,
  },
  DC7: {
    code: 'DC7',
    title: 'Composite resin veneer',
    fee: 231.32,
  },
  DC8: {
    code: 'DC8',
    title: 'Post',
    notes: 'Wrought or pre-formed',
    fee: 123.92,
  },
  DC9: {
    code: 'DC9',
    title: 'Composite or amalgam core',
    fee: 148.73,
  },
  DC11: {
    code: 'DC11',
    title: 'Cast post and core',
    notes: 'Metal or ceramic',
    fee: 284.23,
  },
  DC15: {
    code: 'DC15',
    title: 'All ceramic crown',
    fee: 1254.93,
  },
  DC16: {
    code: 'DC16',
    title: 'Porcelain fused to metal crown',
    fee: 1206.75,
  },
  DC17: {
    code: 'DC17',
    title: 'Cast gold crown',
    notes: 'Full and three-quarters',
    fee: 1134.41,
  },
  DC19: {
    code: 'DC19',
    title: 'Maryland bridge',
    notes: 'Per unit',
    fee: 874.39,
  },
  DC20: {
    code: 'DC20',
    title: 'Composite bridge',
    notes: 'Per unit',
    fee: 289.16,
  },
  DC25: {
    code: 'DC25',
    title: 'Recementing crown/bridge/veneer/inlay',
    fee: 43.44,
  },
  DC26: {
    code: 'DC26',
    title: 'Non-composite bridge',
    notes: 'On injured teeth that meet the requirement for a crown',
    fee: 2955.53,
  },
  DC27: {
    code: 'DC27',
    title: 'Replacement of non-composite bridge',
    fee: 3536.25,
  },
  DN1: {
    code: 'DN1',
    title: 'Pulpotomy or pulpectomy',
    notes: 'Includes dressing',
    fee: 165.26,
  },
  DN2: {
    code: 'DN2',
    title: 'Irrigation and dressing of root canal system',
    notes: 'Includes all temporary dressings',
    fee: 167.74,
  },
  DN3: {
    code: 'DN3',
    title: 'Complete preparation and obturation of root canal',
    notes: 'Per canal - open or closed apex',
    fee: 371.81,
  },
  DN5: {
    code: 'DN5',
    title: 'Apicoectomy and retrograde filling',
    notes: 'Per canal',
    fee: 361.99,
  },
  DN6: {
    code: 'DN6',
    title: 'Removal of root filling',
    notes: 'Per canal',
    fee: 290.41,
  },
  DN7: {
    code: 'DN7',
    title: 'Removal of post or post crown, or crown',
    fee: 290.41,
  },
  DN8: {
    code: 'DN8',
    title: 'Bleaching, 1 non-vital tooth',
    notes: 'Per treatment - includes vital teeth',
    fee: 206.55,
  },
  DN9: {
    code: 'DN9',
    title: 'Pulp capping',
    fee: 48.27,
  },
  DN10: {
    code: 'DN10',
    title: 'Removal of a fractured post or instrument',
    fee: 290.41,
  },
  DN11: {
    code: 'DN11',
    title: 'Repair of perforation',
    fee: 290.41,
  },
  DN13: {
    code: 'DN13',
    title: 'Negotiation of a calcified canal',
    notes: 'Can be used with item DN3',
    fee: 290.41,
  },
  DD1: {
    code: 'DD1',
    title: 'Gingivectomy',
    notes: 'Per tooth',
    fee: 138.39,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  DD2: {
    code: 'DD2',
    title: 'Crown lengthening',
    notes: 'Per tooth',
    fee: 289.16,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  DD4: {
    code: 'DD4',
    title: 'Sub gingival curettage',
    notes: 'Per tooth',
    fee: 115.46,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  DD7: {
    code: 'DD7',
    title: 'Site preparation for dental implant',
    notes:
      'Bone grafting cannot be claimed with this code or with implant surgery',
    fee: 422.29,
  },
  DD8: {
    code: 'DD8',
    title: 'Placement of membrane',
    fee: 452.44,
  },
  DD9: {
    code: 'DD9',
    title: 'Substitute bone material',
    fee: 180.96,
  },
  DM1: {
    code: 'DM1',
    title: 'Resilient linings',
    notes:
      'Tooth or teeth - one per arch if required in cases where upper and lower implants are placed at the same time',
    fee: 86.88,
  },
  DM2: {
    code: 'DM2',
    title: 'Fixture head impressions and copings',
    notes: 'Per fixture',
    fee: 464.51,
  },
  DM3: {
    code: 'DM3',
    title: 'Dental implant crown',
    notes: 'Per single unit',
    fee: 1447.86,
  },
  DM4: {
    code: 'DM4',
    title: 'Dental stent and guide',
    notes: 'Per fixture',
    fee: 156.86,
  },
  DM5: {
    code: 'DM5',
    title: 'Definitive abutment',
    notes: 'Per fixture',
    fee: 464.51,
  },
  DM6: {
    code: 'DM6',
    title: 'Temporary abutment',
    notes: 'Per fixture',
    fee: 57.9,
  },
  DM7: {
    code: 'DM7',
    title: 'Repairs to abutments',
    notes: 'Per fixture',
    fee: 100.49,
  },
  DY1: {
    code: 'DY1',
    title: 'Dental consultation, including examination',
    notes: '6 monthly or for a new treatment plan',
    fee: 77.91,
  },
  DY14: {
    code: 'DY14',
    title: 'Temporary crown',
    fee: 144.8,
  },
  DY15: {
    code: 'DY15',
    title: 'Temporary bridge',
    notes: 'Per unit',
    fee: 144.8,
  },
  DY21: {
    code: 'DY21',
    title: 'Surgical decoronation',
    fee: 506.77,
  },
  DY22: {
    code: 'DY22',
    title: 'Removal of deciduous teeth',
    fee: 35.4,
  },
  DG18: {
    code: 'DG18',
    title: 'Removal of plates, wires and screws',
    fee: 502.77,
  },
  DX6: {
    code: 'DX6',
    title: 'Lateral or antero-posterior head films',
    fee: 79.43,
  },
};

export const ACC_CODE_MAP: ItemCodeAliases = Object.entries(
  BASE_ACC_CODES
).reduce(
  (codes, [key, code]) => ({
    ...codes,
    [key]: {
      scope: [ChartableSurface.Unscoped],
      rules: {},
      deleted: false,
      taxStatus: TaxStrategy.GSTApplicable,
      ...code,
      type: ServiceCodeType.ACC,
    },
  }),
  {}
);

export const ACC_SERVICE_CODES: IServiceCode[] = Object.values(ACC_CODE_MAP);

export const ACC_SERVICE: IServiceProvider = {
  label: 'ACC',
  description: 'New Zealand Accident Compensation Corporation Codes',
  type: ServiceCodeType.ACC,
  items: ACC_CODE_MAP,
  region: Region.NewZealand,
};
