import {
  IWaitListItem,
  WaitListUrgency,
} from '@principle-theorem/principle-core/interfaces';
import {
  DayOfWeek,
  DAY_OF_WEEK_FORMAT,
  ISO_DATE_FORMAT,
  ITimePeriod,
  Timezone,
  toMomentTz,
  toTimestamp,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';

export class WaitListItem {
  static init(overrides?: Partial<IWaitListItem>): IWaitListItem {
    return {
      uid: uuid(),
      urgency: WaitListUrgency.Low,
      inShortList: false,
      dateFrom: toTimestamp(),
      dateTo: toTimestamp(),
      timeFrom: '',
      timeTo: '',
      days: [],
      availableDates: [],
      ...overrides,
    };
  }

  static determineAvailableDates(
    range: ITimePeriod,
    days: DayOfWeek[],
    timezone: Timezone
  ): string[] {
    const availableDates = [];

    const dateFrom: moment.Moment = moment
      .tz(range.from.format(ISO_DATE_FORMAT), timezone)
      .startOf('day');
    const dateTo: moment.Moment = moment
      .tz(range.to.format(ISO_DATE_FORMAT), timezone)
      .startOf('day');

    while (dateFrom.isSameOrBefore(dateTo)) {
      const currentDay: moment.Moment = toMomentTz(dateFrom, timezone);
      const dayOfWeek: DayOfWeek =
        DayOfWeek[
          currentDay.format(DAY_OF_WEEK_FORMAT) as keyof typeof DayOfWeek
        ];
      dateFrom.add(1, 'day');

      if (days.indexOf(dayOfWeek) === -1) {
        continue;
      }

      availableDates.push(currentDay.format(ISO_DATE_FORMAT));
    }
    return availableDates;
  }
}
