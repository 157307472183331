import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ISterilisationEquipment } from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import {
  ButtonsContainerComponent,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgMaterialModule } from '@principle-theorem/ng-material';

export interface ISterilisationEquipmentDialogData {
  equipment?: WithRef<ISterilisationEquipment>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ISterilisationEquipmentForm
  extends Omit<ISterilisationEquipment, 'deleted'> {}

@Component({
  selector: 'pr-sterilisation-equipment-dialog',
  imports: [
    CommonModule,
    NgMaterialModule,
    ButtonsContainerComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './sterilisation-equipment-dialog.component.html',
  styleUrl: './sterilisation-equipment-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SterilisationEquipmentDialogComponent {
  private _dialogRef = inject(
    MatDialogRef<
      SterilisationEquipmentDialogComponent,
      ISterilisationEquipmentDialogData
    >
  );
  data = inject<ISterilisationEquipmentDialogData>(MAT_DIALOG_DATA);
  form = new TypedFormGroup<ISterilisationEquipmentForm>({
    name: new TypedFormControl<string>('', Validators.required),
    description: new TypedFormControl<string>(''),
  });

  constructor() {
    if (this.data.equipment) {
      this.form.patchValue(this.data.equipment);
    }
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }

    this._dialogRef.close({
      deleted: false,
      ...this.form.value,
    });
  }
}
