import { Injectable, inject } from '@angular/core';
import {
  FeaturesCollection,
  IFeature,
  PermissionGroup,
} from '@principle-theorem/feature-flags';
import { INgFeatureFlagsConfig, NG_FEATURE_FLAGS_CONFIG } from './config';

@Injectable()
export class FeatureFlagsService {
  features: FeaturesCollection;
  config: INgFeatureFlagsConfig = inject(NG_FEATURE_FLAGS_CONFIG);

  constructor() {
    this.features = new FeaturesCollection(
      this.config.features,
      this.config.production
    );
  }

  isFeatureEnabled(feature: IFeature): boolean {
    return this.features.isFeatureEnabled(feature);
  }

  isFeatureEnabledByLabel(label: string): boolean {
    return this.features.isFeatureEnabledByLabel(label);
  }

  getFeaturePermissions(): PermissionGroup[] {
    return this.features.getFeaturePermissions();
  }
}
