import { Injectable } from '@angular/core';
import { type IReffable } from '@principle-theorem/shared';
import {
  type IRoutable,
  StateBasedNavigationService,
  type IContextualAction,
} from '@principle-theorem/ng-principle-shared';

@Injectable()
export class LoadLabActionService implements IContextualAction {
  icon = 'open_in_new';
  name = 'View Lab';

  constructor(private _stateNav: StateBasedNavigationService) {}

  async do(lab: IRoutable | IReffable): Promise<void> {
    const uid: string = 'uid' in lab ? lab.uid : lab.ref.id;
    await this._stateNav.practice(['labs', uid]);
  }
}
