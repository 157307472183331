<mat-toolbar color="accent">Update Existing Automations</mat-toolbar>

<div class="flex flex-col gap-4 p-4">
  @if (updating()) {
    <div>Updating {{ queryTotal() }} existing automations...</div>
    <mat-progress-bar mode="determinate" [value]="progress()" />
  } @else if (complete()) {
    <div>Updated {{ current() }} existing automations.</div>
  } @else {
    <div>
      This configuration is used by {{ queryTotal() }} existing automations. Do
      you want to update existing automations?
    </div>
  }
</div>

<pt-page-footer>
  <pt-buttons-container padding="none">
    <button type="button" mat-stroked-button mat-dialog-close>
      @if (complete()) {
        Close
      } @else {
        Cancel
      }
    </button>
    @if (!complete()) {
      <button
        type="button"
        mat-raised-button
        color="warn"
        [disabled]="updating()"
        (click)="updateAutomations()"
      >
        Update Automations
      </button>
    }
  </pt-buttons-container>
</pt-page-footer>
