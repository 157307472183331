import { Brand, toINamedDocuments } from '@principle-theorem/principle-core';
import {
  CustomMappingAssociatedValueType,
  CustomMappingOption,
  CustomMappingType,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
  type ITag,
  type ITreatmentCategory,
} from '@principle-theorem/principle-core/interfaces';
import {
  IBlobFilenamePair,
  XSLXImporterExporter,
  asyncForEach,
  multiSortBy$,
  nameSorter,
  query$,
  snapshot,
  undeletedQuery,
  type INamedDocument,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import Fuse from 'fuse.js';
import { first, sortBy } from 'lodash';
import { Observable, from, of } from 'rxjs';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import {
  AppointmentCategorySourceEntity,
  type IExactAppointmentCategory,
} from '../../source/entities/appointment-category';
import {
  AppointmentCategoryResourceType,
  AppointmentToTreatmentCategoryToXSLX,
} from './appointment-to-treatment-category-to-xslx';
import { XSLXToAppointmentCategoryToTreatmentCategory } from './xslx-to-appointment-category-treatment-category';

export const APPOINTMENT_CATEGORY_TO_TREATMENT_CATEGORY_MAPPING_TYPE =
  'appointmentCategoryToTreatmentCategory';

export const APPOINTMENT_CATEGORY_TO_TREATMENT_CATEGORY_MAPPING =
  CustomMapping.init({
    metadata: {
      label: 'Appointment Category to Treatment Category',
      description: `
        Used to map appointment categories in Exact to treatment categories/tags in Principle.
      `,
      type: APPOINTMENT_CATEGORY_TO_TREATMENT_CATEGORY_MAPPING_TYPE,
    },
    type: CustomMappingType.SelectionList,
    labelOverrides: {
      sourceIdentifier: 'Id',
      sourceLabel: 'Appointment Category',
      destinationIdentifier: 'Map To',
      associatedValue: 'Pick Category/Tag',
    },
  });

export class AppointmentCategoryToTreatmentCategoryMappingHandler extends BaseCustomMappingHandler<
  ITreatmentCategory | ITag,
  AppointmentCategoryResourceType,
  INamedDocument<ITreatmentCategory | ITag>
> {
  customMapping = APPOINTMENT_CATEGORY_TO_TREATMENT_CATEGORY_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const records = await this._getSourceCategories(migration);
    return sortBy(
      records.map((record) => ({
        label: record.name,
        value: record.id.toString(),
      })),
      'label'
    );
  }

  async getSelectionListOptions(
    _migration: WithRef<IPracticeMigration>
  ): Promise<CustomMappingOption[]> {
    return snapshot(
      of([
        {
          value: AppointmentCategoryResourceType.TreatmentCategory,
          description: 'Map this to a Treatment Category',
          hasAssociatedValue: true,
          associatedValueType: CustomMappingAssociatedValueType.NamedDocument,
          associatedValueDescription:
            'Which Treatment Category should be used for this?',
        },
        {
          value: AppointmentCategoryResourceType.AppointmentTag,
          description: 'Map this to an Appointment Tag',
          hasAssociatedValue: true,
          associatedValueType: CustomMappingAssociatedValueType.NamedDocument,
          associatedValueDescription:
            'Which Appointment Tag should be used for this?',
        },
      ])
    );
  }

  getAssociatedValueOptions$(
    migration: WithRef<IPracticeMigration>,
    destinationValue: AppointmentCategoryResourceType
  ): Observable<INamedDocument<ITreatmentCategory | ITag>[]> {
    if (
      destinationValue === AppointmentCategoryResourceType.TreatmentCategory
    ) {
      return from(this.getTreatmentCategories(migration));
    }
    return from(this.getAppointmentTags(migration));
  }

  async getTreatmentCategories(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<ITreatmentCategory>[]> {
    return snapshot(
      query$(
        undeletedQuery(
          Brand.treatmentCategoryCol(migration.configuration.brand)
        )
      ).pipe(toINamedDocuments(), multiSortBy$(nameSorter()))
    );
  }

  async getAppointmentTags(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<ITag>[]> {
    return snapshot(
      query$(
        undeletedQuery(Brand.appointmentTagCol(migration.configuration.brand))
      ).pipe(toINamedDocuments(), multiSortBy$(nameSorter()))
    );
  }

  async autocompleteMappings(
    migration: WithRef<IPracticeMigration>
  ): Promise<void> {
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const sourceOptions = await this.getSourceOptions(migration);
    const records = await this.getRecords(translationMap);
    const mappedItemCodes = records
      .filter((record) => !!record.destinationIdentifier)
      .map((record) => record.sourceIdentifier);
    const unmappedItemCodes = sourceOptions.filter(
      (option) => !mappedItemCodes.includes(option.value)
    );

    const treatmentCategories = await this.getTreatmentCategories(migration);
    const appointmentTags = await this.getAppointmentTags(migration);

    const destinationOptions = [
      ...treatmentCategories.map((category) => ({
        name: category.name,
        associatedValue: category,
        destinationValue: AppointmentCategoryResourceType.TreatmentCategory,
      })),
      ...appointmentTags.map((tag) => ({
        name: tag.name,
        associatedValue: tag,
        destinationValue: AppointmentCategoryResourceType.AppointmentTag,
      })),
    ];

    await asyncForEach(unmappedItemCodes, async (item) => {
      const fuse = new Fuse(destinationOptions, {
        keys: ['name'],
        includeScore: true,
        threshold: 0.3,
      });
      const matches = fuse.search(item.label);
      const match = first(matches)?.item;

      if (!match) {
        return;
      }

      await this.upsertRecord(
        {
          associatedValue: match.associatedValue,
          destinationValue: match.destinationValue,
          sourceIdentifier: item.value,
          sourceLabel: item.label,
        },
        translationMap
      );
    });
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, appointmentCategories, translator } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(
      fileName,
      appointmentCategories,
      translator
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, appointmentCategories, translator } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(
      fileName,
      appointmentCategories,
      translator
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToAppointmentCategoryToTreatmentCategory()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const sourceOptions = await this.getSourceOptions(migration);
    const treatmentCategories = await this.getTreatmentCategories(migration);
    const appointmentTags = await this.getAppointmentTags(migration);

    await asyncForEach(items, async (item) => {
      const matchingOption = sourceOptions.find(
        (option) => option.label === item.label
      );
      if (!matchingOption) {
        return;
      }

      const matchingTreatmentCategory = treatmentCategories.find(
        (category) => category.name === item.mapTo
      );

      if (matchingTreatmentCategory) {
        await this.upsertRecord(
          {
            associatedValue: matchingTreatmentCategory,
            destinationValue: AppointmentCategoryResourceType.TreatmentCategory,
            sourceIdentifier: matchingOption.label,
            sourceLabel: matchingOption.label,
          },
          translationMap
        );
        return;
      }

      const matchingAppointmentTag = appointmentTags.find(
        (category) => category.name === item.mapTo
      );

      if (matchingAppointmentTag) {
        await this.upsertRecord(
          {
            associatedValue: matchingAppointmentTag,
            destinationValue: AppointmentCategoryResourceType.AppointmentTag,
            sourceIdentifier: matchingOption.label,
            sourceLabel: matchingOption.label,
          },
          translationMap
        );
        return;
      }

      // eslint-disable-next-line no-console
      console.error(
        `Mapping error: ${this.customMapping.metadata.label} - Couldn't find destination for item`,
        item
      );
      return;
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    appointmentCategories: IExactAppointmentCategory[];
    translator: AppointmentToTreatmentCategoryToXSLX;
  }> {
    const fileName = this.getFileName();
    const appointmentCategories = await this._getSourceCategories(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const translator = new AppointmentToTreatmentCategoryToXSLX(
      await this.getRecords(translationMap),
      await this.getTreatmentCategories(migration),
      await this.getAppointmentTags(migration)
    );
    return { fileName, appointmentCategories, translator };
  }

  private async _getSourceCategories(
    migration: IReffable<IPracticeMigration>
  ): Promise<IExactAppointmentCategory[]> {
    const appointmentCategories = new AppointmentCategorySourceEntity();
    const records = await appointmentCategories
      .getRecords$(migration, 1000)
      .toPromise();
    return sortBy(
      records.map((record) => record.data.data),
      'name'
    );
  }
}
