import {
  ConditionLogicId,
  IBrand,
  IConditionLogicImplementation,
  IDynamicForm,
  ITemplateScopeData,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';

export class NeverCondition
  implements IConditionLogicImplementation<ITemplateScopeData, undefined>
{
  conditionId = ConditionLogicId.Never;
  description = 'This condition will never be met';

  isValidContext(_data: unknown): _data is ITemplateScopeData {
    return true;
  }

  isTrue(_config: undefined, _context: ITemplateScopeData): boolean {
    return false;
  }

  async getForm(_brand: WithRef<IBrand>): Promise<IDynamicForm> {
    return Promise.resolve({});
  }
}

export class AlwaysCondition
  implements IConditionLogicImplementation<ITemplateScopeData, undefined>
{
  conditionId = ConditionLogicId.Always;
  description = 'This condition will always be met';

  isValidContext(_data: unknown): _data is ITemplateScopeData {
    return true;
  }

  isTrue(_config: undefined, _context: ITemplateScopeData): boolean {
    return true;
  }

  async getForm(_brand: WithRef<IBrand>): Promise<IDynamicForm> {
    return Promise.resolve({});
  }
}
