import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ChartFacade,
  ChartId,
  type IChartContextState,
} from '@principle-theorem/ng-clinical-charting/store';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  ChartableSurface,
  ChartItemDisplayType,
  type IChartedCondition,
  type IChartedRef,
  type IToothRef,
} from '@principle-theorem/principle-core/interfaces';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ChartableSurfaceUpdater,
  type IEditChartableData,
} from '../../../chartable-surface-updater';
import { AddConditionToChartProvider } from '../../../charted-surface/chart/add-condition-to-chart-provider';
import { SelectedSurfaceCollection } from '../../dental-chart-svg/chart-surface/selected-surface-collection';
import { ChartedItemsCollection } from '../../dental-chart-svg/models/charted-items-collection';

@Component({
    selector: 'pr-charted-conditions',
    templateUrl: './charted-conditions.component.html',
    styleUrls: ['./charted-conditions.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChartedConditionsComponent {
  trackByCondition = TrackByFunctions.uniqueId<IChartedCondition>();
  currentConditions$: Observable<IChartedCondition[]>;
  resolvedConditions$: Observable<IChartedCondition[]>;

  @Input() selectedSurfaces: Partial<IChartedRef>[] = [];
  @Input() disabled: boolean = false;

  constructor(
    private _snackBar: MatSnackBar,
    private _chartStore: ChartFacade
  ) {
    this.currentConditions$ = this._getByDisplayType$(
      ChartItemDisplayType.CurrentCondition
    );
    this.resolvedConditions$ = this._getByDisplayType$(
      ChartItemDisplayType.ResolvedCondition
    );
  }

  updateCondition(condition: IChartedCondition): void {
    this._chartStore.updateCondition(ChartId.InAppointment, condition);
  }

  deleteCondition(condition: IChartedCondition): void {
    this._chartStore.removeCondition(ChartId.InAppointment, condition.uuid);
    this._snackBar.open(`Condition removed`);
  }

  async updateConditionSurfaces(data: IEditChartableData): Promise<void> {
    return ChartableSurfaceUpdater.updateConditionSurfaces(data, [
      new AddConditionToChartProvider(this._chartStore),
    ]);
  }

  protected _getMultiToothItems(
    items: IChartedCondition[],
    type: ChartItemDisplayType,
    context: IChartContextState
  ): IChartedCondition[] {
    const collection: SelectedSurfaceCollection = new SelectedSurfaceCollection(
      this.selectedSurfaces
    );
    const teeth: IToothRef[] = collection.getTeeth();
    if (!context.filters.includes(type) || !teeth.length) {
      return [];
    }
    return new ChartedItemsCollection(items)
      .filterByType(type, context.chartingAs)
      .filterBySurface(ChartableSurface.MultipleTeeth)
      .filterByMultipleTeeth(teeth)
      .toArray();
  }

  protected _getByDisplayType$(
    type: ChartItemDisplayType
  ): Observable<IChartedCondition[]> {
    return this._chartStore.chartContextState$(ChartId.InAppointment).pipe(
      map((context) => {
        if (!context.filters.includes(type)) {
          return [];
        }
        const conditions = context.chart.conditions;
        return new ChartedItemsCollection(conditions)
          .filterByType(type, context.chartingAs)
          .toArray()
          .concat(this._getMultiToothItems(conditions, type, context));
      })
    );
  }
}
