import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import {
  AppointmentSchedulingFacade,
  AppointmentSuggestionSearchType,
} from '@principle-theorem/ng-appointment/store';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface ISearchTypeOption {
  label: string;
  icon: string;
  hint: string;
  value: AppointmentSuggestionSearchType;
}

@Component({
  selector: 'pr-appointment-search-type-select',
  templateUrl: './appointment-search-type-select.component.html',
  styleUrls: ['./appointment-search-type-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentSearchTypeSelectComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  selectedSearchOption$ = new BehaviorSubject<AppointmentSuggestionSearchType>(
    AppointmentSuggestionSearchType.Normal
  );
  trackBySearchType = TrackByFunctions.field<ISearchTypeOption>('value');
  searchTypeOptions: ISearchTypeOption[] = [
    {
      label: 'Smart Search',
      icon: 'grading',
      hint: 'Show appointment slots scored by best fit',
      value: AppointmentSuggestionSearchType.Normal,
    },
    {
      label: 'All Possible Options',
      icon: 'view_headline',
      hint: 'Show options for the given duration which may overlap other appointments and events',
      value: AppointmentSuggestionSearchType.Overlapping,
    },
  ];

  constructor(private _schedulingFacade: AppointmentSchedulingFacade) {
    this.selectedSearchOption$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((searchType) => {
        this._schedulingFacade.setSuggestionSearchType(searchType);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
