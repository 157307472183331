import { MeasureFormatter } from '@principle-theorem/principle-core/interfaces';
import { IScheduleSummaryEventFact } from '@principle-theorem/reporting/interfaces';
import { get, upperFirst } from 'lodash';
import { BigQueryTable } from '../../big-query-tables';
import {
  BrandQueryScopeConfig,
  DateRangeQueryScopeConfig,
  PracticeQueryScopeConfig,
} from '../../query-scope-config/query-scope-configs';
import { GroupBy, IReportingQuery } from '../../querying';
import { BaseFactMeasures, latestEventId } from '../base-measures';
import { MeasurePath, getProperty } from '../data-accessor-factory';
import { BrandDimensionMeasures } from '../dimensions/brand-dimension';
import { PracticeDimensionMeasures } from '../dimensions/practice-dimension';
import { SchedulingSummaryDimensionMeasures } from '../dimensions/scheduling-summary-dimension';
import { StafferDimensionMeasures } from '../dimensions/staffer-dimension';
import {
  CanBeChartedProperty,
  CanDoAllProperty,
  ComparableProperties,
  MeasureTransformMap,
  noFilterAccessor,
} from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { QueryFactory } from '../query-factory';

export class ScheduleSummaryEventFactMeasures
  extends BaseFactMeasures
  implements
    MeasureTransformMap<ComparableProperties<IScheduleSummaryEventFact>>
{
  id = 'scheduleSummaryEvent';
  readonly table = BigQueryTable.ScheduleSummaryEvent;
  readonly name = 'Schedules';
  scopes = [
    new BrandQueryScopeConfig(false, 'brandRef.referenceValue'),
    new PracticeQueryScopeConfig(false, 'practiceRef.referenceValue'),
    new DateRangeQueryScopeConfig(),
  ];

  get brand(): BrandDimensionMeasures {
    return new BrandDimensionMeasures(BigQueryTable.Brands, 'brand', {
      sourceJoinKey: MeasurePath.docRef('brand.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get practice(): PracticeDimensionMeasures {
    return new PracticeDimensionMeasures(BigQueryTable.Practices, 'practice', {
      sourceJoinKey: MeasurePath.docRef('practiceRef'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get practitioner(): StafferDimensionMeasures {
    return new StafferDimensionMeasures(BigQueryTable.Staff, 'staffer', {
      sourceJoinKey: MeasurePath.docRef('stafferRef'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get scheduleSummary(): SchedulingSummaryDimensionMeasures {
    return new SchedulingSummaryDimensionMeasures(
      BigQueryTable.ScheduleSummary,
      'scheduleSummary',
      {
        sourceJoinKey: MeasurePath.docRef('scheduleSummaryRef'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get latestEvent(): ScheduleSummaryEventFactMeasures {
    const measures = new ScheduleSummaryEventFactMeasures();
    measures.id = latestEventId(measures);
    measures._query = QueryFactory.fromTable(measures.table)
      .override(measures._query)
      .latestEvent(measures.table, GroupBy.ScheduleSummary)
      .filterSoftDeleted(measures.scheduleSummary.measureRef('deleted'))
      .get();
    return measures;
  }

  get eventTypes(): CanDoAllProperty {
    const measure = this.measureRef('eventableSummaries');
    return MeasurePropertyFactory.array(
      {
        id: this._pathWithPrefix('eventTypes'),
        label: 'Calendar Event Types',
        summary: '',
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'type',
      (facts) => {
        return facts.map((fact) =>
          upperFirst(String(get(fact, 'type') ?? 'Appointment'))
        );
      }
    );
  }

  get isBlockingEvent(): CanDoAllProperty {
    const measure = this.measureRef('eventableSummaries');
    return MeasurePropertyFactory.array(
      {
        id: this._pathWithPrefix('isBlockingEvent'),
        label: 'Is Blocking Calendar Event',
        summary: '',
        formatter: MeasureFormatter.Boolean,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'isBlocking',
      (facts) => facts.map((fact) => Boolean(get(fact, 'isBlocking', true)))
    );
  }

  get eventTimeInMins(): CanDoAllProperty {
    const measure = this.measureRef('eventableSummaries');
    return MeasurePropertyFactory.array(
      {
        id: this._pathWithPrefix('eventTimInMinsDumb'),
        label: 'Is Blocking Calendar Event',
        summary: '',
        formatter: MeasureFormatter.Boolean,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'duration'
    );
  }

  get appointmentTreatmentCategories(): CanDoAllProperty {
    const measure = this.measureRef('eventableSummaries');
    return MeasurePropertyFactory.array(
      {
        id: this._pathWithPrefix('appointmentTreatmentCategories'),
        label: 'Appointment Treatment Categories',
        summary: '',
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'treatmentCategory',
      (facts) =>
        facts.map((fact) => String(get(fact, 'treatmentCategory', 'Unknown')))
    );
  }

  get appointmentTreatmentAmount(): CanDoAllProperty {
    const measure = this.measureRef('eventableSummaries');
    return MeasurePropertyFactory.array(
      {
        id: this._pathWithPrefix('appointmentTreatmentAmount'),
        label: 'Appointment Treatment Amount',
        summary: '',
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'treatmentAmount',
      (facts) => facts.map((fact) => Number(get(fact, 'treatmentAmount', 0)))
    );
  }

  get appointmentTimeInMins(): CanDoAllProperty {
    const measure = this.measureRef('eventableSummaries');
    return MeasurePropertyFactory.array(
      {
        id: this._pathWithPrefix('appointmentTimeInMins'),
        label: 'Appointment In Minutes',
        summary:
          'The total time the practitioner was in appointments for the day',
        formatter: MeasureFormatter.Minutes,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'duration',
      (facts) => facts.map((fact) => Number(get(fact, 'duration', 0)))
    );
  }

  get appointmentDurationInHours(): CanBeChartedProperty {
    const propertyName = 'duration';
    const measure = this.measureRef(propertyName);
    return new CanBeChartedProperty({
      metadata: {
        id: 'durationInHours',
        label: 'Duration in Hours',
        summary: 'The total duration of the appointment in hours',
        formatter: MeasureFormatter.Hours,
      },
      measure: {
        propertyName,
        query: this.buildQuery().attributes([measure.attributePath]).get(),
        dataAccessor: (fact) =>
          getProperty<number>(fact, measure.factPropertyPath, 0) / 60 || 0,
        filterAccessor: noFilterAccessor(),
      },
    });
  }

  // TODO:Hook this up to the array
  get totalRevenuePerHour(): CanBeChartedProperty {
    const propertyName = 'totalRevenuePerHour';
    const valueMeasure = this.measureRef('treatmentCost');
    const durationMeasure = this.measureRef('duration');
    const measure: CanBeChartedProperty = new CanBeChartedProperty({
      metadata: {
        id: propertyName,
        label: 'Hourly Rate',
        summary:
          'The average hourly rate for the appointment, comparing the total cost of the appointment to the total duration.',
        formatter: MeasureFormatter.Currency,
      },
      measure: {
        propertyName,
        query: this.buildQuery()
          .attributes([
            valueMeasure.attributePath,
            durationMeasure.attributePath,
          ])
          .get(),
        dataAccessor: (fact) => {
          const value = getProperty<number>(
            fact,
            valueMeasure.factPropertyPath,
            0
          );
          const duration =
            get(fact, durationMeasure.factPropertyPath, 0) / 60 || 0;
          const totalRevenuePerHour = value / duration;
          return isNaN(totalRevenuePerHour) ? 0 : totalRevenuePerHour;
        },
        filterAccessor: noFilterAccessor(),
      },
    });

    measure.reduceByRatio(this.appointmentDurationInHours);
    return measure;
  }

  // TODO:Hook this up to the array
  get appointmentRevenuePerHour(): CanBeChartedProperty {
    const propertyName = 'appointmentRevenuePerHour';
    const valueMeasure = this.measureRef('treatmentCost');
    const durationMeasure = this.measureRef('duration');
    const measure: CanBeChartedProperty = new CanBeChartedProperty({
      metadata: {
        id: propertyName,
        label: 'Appointment Revenue Per Hour',
        summary:
          'The average hourly rate for the appointment, comparing the total cost of the appointment to the total duration.',
        formatter: MeasureFormatter.Currency,
      },
      measure: {
        propertyName,
        query: this.buildQuery()
          .attributes([
            valueMeasure.attributePath,
            durationMeasure.attributePath,
          ])
          .get(),
        dataAccessor: (fact) => {
          const value = getProperty<number>(
            fact,
            valueMeasure.factPropertyPath,
            0
          );
          const duration =
            get(fact, durationMeasure.factPropertyPath, 0) / 60 || 0;
          const hourlyRate = value / duration;
          return isNaN(hourlyRate) ? 0 : hourlyRate;
        },
        filterAccessor: noFilterAccessor(),
      },
    });

    measure.reduceByRatio(this.appointmentDurationInHours);
    return measure;
  }

  get rosterTimeInMins(): CanBeChartedProperty {
    const propertyName = 'rosterTimeInMins';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Roster Time',
        summary: 'The total time the pracitions was rostered on for the day',
        formatter: MeasureFormatter.Minutes,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  // TODO:Hook this up to the array
  get gapTimeInMins(): CanBeChartedProperty {
    const propertyName = 'gapTimeInMins';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Gap Time',
        summary:
          'The total time the practitioner was not in an appointment, or on break, for the day',
        formatter: MeasureFormatter.Minutes,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  // TODO:Hook this up to the array
  get revenueTotal(): CanBeChartedProperty {
    const propertyName = 'revenueTotal';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Revenue Total',
        summary: 'The total revenue for the day',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  // TODO:Hook this up to the array
  get revenueLostTotal(): CanBeChartedProperty {
    const propertyName = 'revenueLostTotal';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Revenue Lost Total',
        summary:
          'The total revenue for day, extrapolated across the gaps for the day.',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get utilisation(): CanBeChartedProperty {
    const propertyName = 'utilisation';
    return new CanBeChartedProperty({
      metadata: {
        id: propertyName,
        label: 'Utilisation',
        summary:
          'The percentage of roster time that was spent in appointments or breaks',
        formatter: MeasureFormatter.Percentage,
      },
      measure: {
        propertyName,
        query: this.buildQuery()
          .attributes([
            'rosterTimeInMins',
            'eventTimeInMins',
            'appointmentTimeInMins',
            'gapTimeInMins',
          ])
          .get(),
        dataAccessor: (fact) => {
          const rosterTimeInMins = getProperty<number>(
            fact,
            'rosterTimeInMins',
            0
          );
          const eventTimeInMins = getProperty<number>(
            fact,
            'eventTimeInMins',
            0
          );
          const appointmentTimeInMins = getProperty<number>(
            fact,
            'appointmentTimeInMins',
            0
          );
          const schedulableTime = rosterTimeInMins - eventTimeInMins;
          return schedulableTime > 0
            ? appointmentTimeInMins / schedulableTime
            : 0;
        },
        filterAccessor: noFilterAccessor(),
      },
    });
  }

  get revenuePerHour(): CanBeChartedProperty {
    const propertyName = 'propertyName';
    const measure = this.measureRef(propertyName);

    const query: IReportingQuery = {
      table: BigQueryTable.ScheduleSummaryEvent,
      attributes: ['appointmentRefs'],
      subQuery: {
        table: BigQueryTable.ScheduleSummaryEvent,
        attributes: ['appointment.treatmentCost'],
        // operation: AttributeOperation.Unnest,
        unnest: {
          property: 'appointmentRefs',
          alias: 'appointmentRef',
        },
        joins: [
          {
            table: BigQueryTable.AppointmentEvent,
            alias: 'appointment',
            sourceJoinKey: MeasurePath.docRef('appointmentRef'),
            destinationJoinKey: 'ref.referenceValue',
            joinType: 'LEFT',
            orderByProperty: MeasurePath.timestamp('updatedAt'),
          },
        ],
      },
    };

    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Revenue Per Hour',
        summary:
          'The total revenue for the day, divided by the rostered on time.',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().merge([query]).attributes([measure.attributePath]).get()
    );
  }
}
