import { NgIfContext } from '@angular/common';
import {
  ChangeDetectorRef,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  type EmbeddedViewRef,
  type OnDestroy,
} from '@angular/core';
import {
  Region,
  filterUndefined,
  isChanged$,
  isRefChanged$,
} from '@principle-theorem/shared';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { OrganisationService } from './organisation.service';

@Directive({
    selector: '[prIsRegion]',
    standalone: false
})
export class IsRegionDirective implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _context = new NgIfContext();
  private _elseTemplateRef$ = new ReplaySubject<
    TemplateRef<NgIfContext> | undefined
  >(1);
  private _elseViewRef: EmbeddedViewRef<NgIfContext>;
  private _regions$ = new ReplaySubject<string[]>(1);
  private _thenTemplateRef: TemplateRef<unknown>;

  @Input()
  set prIsRegion(regions: Region[]) {
    if (!regions) {
      this._regions$.next([]);
      return;
    }
    this._regions$.next(regions);
  }

  @Input()
  set prIsRegionElse(template: TemplateRef<NgIfContext>) {
    if (template) {
      this._elseTemplateRef$.next(template);
    }
  }

  constructor(
    private _viewContainer: ViewContainerRef,
    templateRef: TemplateRef<unknown>,
    private _organisation: OrganisationService,
    private _cdr: ChangeDetectorRef
  ) {
    this._thenTemplateRef = templateRef;
    this._viewContainer.clear();

    const isRegion$ = combineLatest([
      this._regions$.pipe(isChanged$()),
      this._organisation.organisation$.pipe(
        filterUndefined(),
        isRefChanged$(),
        map((organisation) => organisation.region)
      ),
    ]).pipe(map(([regions, region]) => regions.includes(region)));

    combineLatest([
      isRegion$,
      this._elseTemplateRef$.pipe(startWith(undefined)),
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([isRegion, elseTemplate]) => {
        this._updateView(isRegion, elseTemplate);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _updateView(
    isRegion: boolean,
    elseTemplateRef?: TemplateRef<NgIfContext>
  ): void {
    this._viewContainer.clear();
    if (isRegion) {
      this._viewContainer.createEmbeddedView(this._thenTemplateRef);
      this._cdr.detectChanges();
      return;
    }
    if (!this._elseViewRef && elseTemplateRef) {
      this._elseViewRef = this._viewContainer.createEmbeddedView(
        elseTemplateRef,
        this._context
      );
    }
    this._cdr.detectChanges();
  }
}
