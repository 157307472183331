import { Patient } from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  IDestinationEntity,
  IDestinationEntityRecord,
  IGetRecordResponse,
  IHasSourceIdentifier,
  IInteractionV2,
  IMigratedDataSummary,
  IPatient,
  IPracticeMigration,
  ISourceEntityHandler,
  ISourceEntityRecord,
  MergeConflictDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  WithRef,
  asDocRef,
  asyncForEach,
  getError,
  omitByKeys,
  safeCombineLatest,
  toTimestamp,
} from '@principle-theorem/shared';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationMapHandler } from '../../translation-map';
import { BaseDestinationEntity } from '../base-destination-entity';
import { FirestoreMigrate } from '../destination';
import { DestinationEntity } from '../destination-entity';
import { DestinationEntityRecord } from '../destination-entity-record';
import { PatientIdFilter } from '../filters/patient-id-filter';

export const PATIENT_INTERACTION_RESOURCE_TYPE = 'patientInteractions';

export const PATIENT_INTERACTION_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_INTERACTION_RESOURCE_TYPE,
    label: 'Patient Interactions',
    description: '',
  },
});

export interface IPatientInteractionDestinationRecord {
  patientRef: DocumentReference<IPatient>;
  interactionRefs: DocumentReference<IInteractionV2>[];
}

export interface IPatientInteractionMigrationData {
  patientRef: DocumentReference<IPatient>;
  interactions: (IInteractionV2 & { sourceIdentifier: string })[];
}

export interface IBasePatientInteractionJobData<PatientRecord extends object> {
  sourcePatient: IGetRecordResponse<PatientRecord>;
}

export abstract class BasePatientInteractionDestinationEntity<
  PatientRecord extends object,
  JobData extends IBasePatientInteractionJobData<PatientRecord>,
> extends BaseDestinationEntity<
  IPatientInteractionDestinationRecord,
  JobData,
  IPatientInteractionMigrationData
> {
  destinationEntity = PATIENT_INTERACTION_DESTINATION_ENTITY;

  abstract patientSourceEntity: ISourceEntityHandler<PatientRecord[]>;

  get sourceCountComparison(): ISourceEntityHandler<PatientRecord[]> {
    return this.patientSourceEntity;
  }

  override filters = [
    new PatientIdFilter<JobData>((data) =>
      this.patientSourceEntity
        .getSourceRecordId(data.sourcePatient.data.data)
        .toString()
    ),
  ];

  sourceCountDataAccessor(
    data: JobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getDestinationEntityRecordUid(data: JobData): string {
    return data.sourcePatient.record.uid;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IPatientInteractionDestinationRecord>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      Firestore.getDoc(record.data.patientRef),
      safeCombineLatest(
        record.data.interactionRefs.map((interactionRef) =>
          Firestore.getDoc(interactionRef)
        )
      ),
    ]).pipe(
      map(([patientRef, interactions]) => [
        {
          label: 'Patient',
          data: patientRef,
        },
        ...interactions.map((interaction) => ({
          label: 'Interaction',
          data: interaction,
        })),
      ])
    );
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IPatientInteractionMigrationData
  ): Promise<IPatientInteractionMigrationData | undefined> {
    const existingInteractions: (IInteractionV2 & IHasSourceIdentifier)[] = [];

    const interactionMergeConflicts = await asyncForEach(
      data.interactions,
      async (interaction) => {
        const interactionRef = await translationMap.getDestination(
          interaction.sourceIdentifier,
          PATIENT_INTERACTION_RESOURCE_TYPE
        );

        if (!interactionRef) {
          return false;
        }

        try {
          const existingInteraction = await Firestore.getDoc(
            asDocRef<IInteractionV2>(interactionRef)
          );
          existingInteractions.push({
            ...existingInteraction,
            sourceIdentifier: interaction.sourceIdentifier,
          });

          const withoutIds = omitByKeys(interaction, [
            'sourceIdentifier',
            'uid',
          ]);
          const existingInteractionWithoutIds = omitByKeys(
            existingInteraction,
            ['uid']
          );

          return DestinationEntityRecord.hasMergeConflicts(
            withoutIds,
            existingInteractionWithoutIds
          );
        } catch (error) {
          return false;
        }
      }
    );

    if (interactionMergeConflicts.some((mergeConflict) => mergeConflict)) {
      return {
        ...data,
        interactions: existingInteractions,
      };
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: JobData,
    _migrationData: IPatientInteractionMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    data: JobData,
    migrationData: IPatientInteractionMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const interactionRefs = await this._addInteractionsToPatient(
        migrationData,
        translationMapHandler
      );
      return this._buildSuccessResponse(
        data.sourcePatient.record,
        interactionRefs
      );
    } catch (error) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        getError(error)
      );
    }
  }

  protected _buildSuccessResponse(
    patient: JobData['sourcePatient']['record'],
    interactionRefs: DocumentReference<IInteractionV2>[]
  ): IDestinationEntityRecord<IPatientInteractionDestinationRecord> {
    return {
      uid: patient.uid,
      label: patient.label,
      data: {
        patientRef: patient.ref as unknown as DocumentReference<IPatient>,
        interactionRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  protected _buildErrorResponse(
    patient: JobData['sourcePatient']['record'],
    errorMessage: string
  ): IDestinationEntityRecord<IPatientInteractionDestinationRecord> &
    FailedDestinationEntityRecord {
    return {
      uid: patient.uid,
      label: patient.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage,
      failData: {
        patientRef: patient.ref,
      },
    };
  }

  private async _addInteractionsToPatient(
    migrationData: IPatientInteractionMigrationData,
    translationMap: TranslationMapHandler
  ): Promise<DocumentReference<IInteractionV2>[]> {
    return asyncForEach(migrationData.interactions, async (interaction) => {
      const interactionDestinationRef = await translationMap.getDestination(
        interaction.sourceIdentifier,
        PATIENT_INTERACTION_RESOURCE_TYPE
      );

      const interactionRef = await FirestoreMigrate.upsertDoc(
        Patient.historyCol({ ref: migrationData.patientRef }),
        interaction,
        interactionDestinationRef?.id
      );

      if (!interactionDestinationRef) {
        await translationMap.upsert({
          sourceIdentifier: interaction.sourceIdentifier,
          destinationIdentifier: interactionRef,
          resourceType: PATIENT_INTERACTION_RESOURCE_TYPE,
        });
      }

      return interactionRef;
    });
  }
}
