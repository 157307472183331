<mat-toolbar color="accent">
  <span fxFlex>Set Appointment Follow Up</span>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>

<form [formGroup]="followUpFormGroup">
  <pt-alert
    *ngIf="followUpReasonRequired$ | async"
    alert="In nearly all cases we want to ensure that a follow up is set."
  />

  <div
    class="layout-margin"
    fxLayout="column"
    fxLayoutAlign="space-between stretch"
  >
    <div class="checkbox">
      <mat-slide-toggle formControlName="createFollowUp">
        Add to Follow Up
      </mat-slide-toggle>
    </div>

    <mat-form-field class="flex-1">
      <mat-label>Predefined dates</mat-label>
      <mat-select [formControl]="datePresetCtrl">
        @for (preset of datePresets; track preset.label) {
          <mat-option [value]="preset">
            {{ preset.label }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label>Follow up date</mat-label>
      <input
        matInput
        placeholder="Follow up date"
        formControlName="followUpDate"
        [min]="minDate"
        [matDatepicker]="followUpDate"
        (focus)="followUpDate.open()"
        (click)="followUpDate.open()"
      />
      <mat-datepicker-toggle matIconSuffix [for]="followUpDate" />
      <mat-datepicker #followUpDate />
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      *ngIf="followUpReasonRequired$ | async"
    >
      <mat-label>Why isn't a follow up being set?</mat-label>
      <pr-content-editor
        autofocus
        formControlName="noFollowUpReason"
        [menuEnabled]="false"
        [required]="true"
      />
    </mat-form-field>
  </div>

  <pt-buttons-container padding="none" class="layout-margin">
    <button type="button" mat-stroked-button mat-dialog-close>Close</button>
    <button mat-flat-button color="primary" (click)="submitFollowUp()">
      Save
    </button>
  </pt-buttons-container>
</form>
