import {
  type CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CurrentScopeFacade,
  ScrollContainer,
  ScrollContainerManagerService,
} from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  InputSearchFilter,
  toSearchStream,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  TreatmentConfiguration,
  TreatmentStepConfiguration,
} from '@principle-theorem/principle-core';
import {
  type IMultiTreatmentConfiguration,
  type ITreatmentConfigurationRef,
  type ITreatmentStepConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  multiMap,
  patchDoc,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EditTreatmentStepConfigurationDialogComponent } from '../edit-treatment-step-configuration-dialog/edit-treatment-step-configuration-dialog.component';

@Component({
    selector: 'pr-treatment-steps-configuration',
    templateUrl: './treatment-steps-configuration.component.html',
    styleUrls: ['./treatment-steps-configuration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreatmentStepsConfigurationComponent {
  trackByStep = TrackByFunctions.field<ITreatmentStepConfiguration>('name');
  trackByTreatment = TrackByFunctions.ref<ITreatmentConfigurationRef>();
  treatmentOptionsId = 'treatmentOptions';
  treatmentOptions$: Observable<ITreatmentConfigurationRef[]>;
  scrollContainer$: Observable<HTMLElement>;
  searchCtrl: TypedFormControl<string> = new TypedFormControl('');
  @Input() multiTreatmentConfig: WithRef<IMultiTreatmentConfiguration>;

  constructor(
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _scrollContainerManager: ScrollContainerManagerService,
    private _currentScopeFacade: CurrentScopeFacade
  ) {
    this.scrollContainer$ = this._scrollContainerManager.getContainer(
      ScrollContainer.InAppointment
    );

    const treatmentConfigurations$: Observable<ITreatmentConfigurationRef[]> =
      this._currentScopeFacade.currentBrand$.pipe(filterUndefined()).pipe(
        switchMap((brand) => TreatmentConfiguration.all$(brand)),
        multiMap((treatmentConfiguration) => ({
          name: treatmentConfiguration.name,
          ref: treatmentConfiguration.ref,
          quantity: 1,
        }))
      );

    const searchFilter = new InputSearchFilter<ITreatmentConfigurationRef>(
      treatmentConfigurations$,
      toSearchStream(this.searchCtrl),
      ['name']
    );
    this.treatmentOptions$ = searchFilter.results$;
  }

  getFilterValues(treatmentOption: ITreatmentConfigurationRef): string[] {
    const values: string[] = [];
    values.push(treatmentOption.name);
    return values;
  }

  drop(event: CdkDragDrop<ITreatmentConfigurationRef[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      return;
    }

    if (event.previousContainer.id !== this.treatmentOptionsId) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      return;
    }

    const previousArray: ITreatmentConfigurationRef[] =
      event.previousContainer.data;
    const previousIndex: number = event.previousIndex;
    const targetArray: ITreatmentConfigurationRef[] = event.container.data;
    const targetIndex: number = event.currentIndex;
    targetArray.splice(targetIndex, 0, { ...previousArray[previousIndex] });
  }

  increaseQuantity(treatment: ITreatmentConfigurationRef): void {
    treatment.quantity += 1;
  }

  decreaseQuantity(
    treatment: ITreatmentConfigurationRef,
    treatmentStep: ITreatmentStepConfiguration
  ): void {
    treatment.quantity -= 1;
    if (treatment.quantity < 1) {
      this.removeTreatment(treatment, treatmentStep);
    }
  }

  removeTreatment(
    treatment: ITreatmentConfigurationRef,
    treatmentStep: ITreatmentStepConfiguration
  ): void {
    treatmentStep.treatments = treatmentStep.treatments.filter(
      (currentTreatment: ITreatmentConfigurationRef) => {
        return treatment !== currentTreatment;
      }
    );
  }

  editTreatmentStep(treatmentStep: ITreatmentStepConfiguration): void {
    const config: MatDialogConfig = DialogPresets.medium({
      data: {
        multiTreatmentConfig: this.multiTreatmentConfig,
        treatmentStep,
      },
    });
    this._dialog.open(EditTreatmentStepConfigurationDialogComponent, config);
  }

  removeTreatmentStep(treatmentStep: ITreatmentStepConfiguration): void {
    this.multiTreatmentConfig.steps = this.multiTreatmentConfig.steps.filter(
      (currentTreatmentStep: ITreatmentStepConfiguration) => {
        return currentTreatmentStep.name !== treatmentStep.name;
      }
    );
  }

  noReturnPredicate(): boolean {
    return false;
  }

  async save(): Promise<void> {
    await patchDoc(this.multiTreatmentConfig.ref, {
      steps: this.multiTreatmentConfig.steps,
    });
    this._snackBar.open('Configuration Updated');
  }

  addTreatmentStep(): void {
    const stepNumber: number = this.multiTreatmentConfig.steps.length + 1;
    this.multiTreatmentConfig.steps.push(
      TreatmentStepConfiguration.init({
        name: `Step ${stepNumber}`,
      })
    );
  }
}
