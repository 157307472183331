<ng-container *ngIf="viewType === 'sidebar'; else cardView">
  <h2>Tags</h2>
</ng-container>

<ng-template #cardView>
  <div class="text-base font-bold">Tags</div>
</ng-template>

<pr-tags-input
  [formControl]="tagsCtrl"
  [tagCol]="tagCol$ | ngrxPush"
  [tagType]="tagType"
/>
