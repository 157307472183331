import { type ValidatorFn, Validators } from '@angular/forms';
import {
  type TypedAbstractControl,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  type INoReferralData,
  type IReferralData,
  type IReferralFormData,
} from '@principle-theorem/principle-core/interfaces';
import {
  ALPHA_NUMERIC,
  toMoment,
  toTimestamp,
} from '@principle-theorem/shared';
import {
  type PeriodTypeCde,
  type ReferralOverrideTypeCde,
} from '@principle-theorem/tyro';
import * as moment from 'moment-timezone';

export const DEFAULT_NO_REFERRAL: IReferralFormData = {
  hasReferral: false,
  noReferral: {
    referralOverrideTypeCde: 'N',
  },
  referral: {
    periodTypeCde: 'S',
    dateOfIssue: toTimestamp(),
    providerNum: '',
  },
};

export interface IReferralMomentData
  extends Omit<IReferralData, 'dateOfIssue'> {
  dateOfIssue: moment.Moment;
}

export interface IClaimReferralFormData
  extends Omit<IReferralFormData, 'referral'> {
  referral: IReferralMomentData;
}

export class ClaimReferralFormGroup extends TypedFormGroup<IClaimReferralFormData> {
  constructor() {
    super({
      hasReferral: new TypedFormControl<boolean>(
        DEFAULT_NO_REFERRAL.hasReferral,
        Validators.required
      ),
      noReferral: new TypedFormGroup<INoReferralData>({
        referralOverrideTypeCde: new TypedFormControl<ReferralOverrideTypeCde>(
          DEFAULT_NO_REFERRAL.noReferral.referralOverrideTypeCde
        ),
      }),
      referral: new TypedFormGroup<IReferralMomentData>({
        periodTypeCde: new TypedFormControl<PeriodTypeCde>(
          DEFAULT_NO_REFERRAL.referral.periodTypeCde
        ),
        dateOfIssue: new TypedFormControl<moment.Moment>(
          toMoment(DEFAULT_NO_REFERRAL.referral.dateOfIssue ?? toTimestamp())
        ).withGuard(moment.isMoment),
        providerNum: new TypedFormControl<string>(
          DEFAULT_NO_REFERRAL.referral.providerNum
        ),
      }),
    });
    this.applyValidators();
  }

  applyValidators(): void {
    this._setReferralRequired(this.value.hasReferral);
    this._setNoReferralRequired(!this.value.hasReferral);
  }

  private _setReferralRequired(isRequired: boolean): void {
    const requiredValidator = isRequired ? [Validators.required] : [];
    const referralGroup = this.controls
      .referral as TypedFormGroup<IReferralMomentData>;

    this._setValidatorsAndUpdate(referralGroup, requiredValidator);
    this._setValidatorsAndUpdate(
      referralGroup.controls.periodTypeCde,
      requiredValidator
    );
    this._setValidatorsAndUpdate(referralGroup.controls.dateOfIssue, [
      ...requiredValidator,
    ]);
    this._setValidatorsAndUpdate(referralGroup.controls.providerNum, [
      Validators.pattern(ALPHA_NUMERIC),
      Validators.minLength(8),
      Validators.maxLength(8),
      ...requiredValidator,
    ]);
  }

  private _setNoReferralRequired(isRequired: boolean): void {
    const requiredValidator = isRequired ? [Validators.required] : [];
    const noReferralGroup = this.controls
      .noReferral as TypedFormGroup<INoReferralData>;

    this._setValidatorsAndUpdate(noReferralGroup, requiredValidator);
    this._setValidatorsAndUpdate(
      noReferralGroup.controls.referralOverrideTypeCde,
      requiredValidator
    );
  }

  private _setValidatorsAndUpdate<T>(
    control: TypedAbstractControl<T> | null,
    validators: ValidatorFn[]
  ): void {
    if (!control) {
      return;
    }
    control.setValidators(validators);
    control.updateValueAndValidity({ emitEvent: false });
  }
}
