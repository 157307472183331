@if (patient$ | async; as patient) {
  <div class="flex w-60 flex-col gap-4 bg-blue-50 p-4">
    <h3 class="!m-0 !font-bold">Current Details</h3>

    <div class="flex flex-col">
      <div class="text-xs opacity-50">Full Name</div>
      <div>{{ patient.name }}</div>
    </div>

    <div class="flex flex-col">
      <div class="text-xs opacity-50">Gender</div>
      <div>{{ patient.gender | titlecase }}</div>
    </div>

    <div class="flex flex-col">
      <div class="text-xs opacity-50">Date of birth</div>
      <div>
        {{ patient.dateOfBirth | moment | amDateFormat: dateOfBirthFormat }}
      </div>
    </div>

    <div class="flex flex-col">
      <div class="text-xs opacity-50">Address</div>
      <div>{{ patient.address }}</div>
    </div>

    <div class="flex flex-col">
      <div class="text-xs opacity-50">Email</div>
      <div>{{ patient.email }}</div>
    </div>

    @for (contactNumber of patient.contactNumbers; track contactNumber) {
      <div class="flex flex-col">
        <div class="text-xs opacity-50">
          {{ contactNumber.label }}
        </div>
        <div>{{ contactNumber.number }}</div>
      </div>
    }

    @if (healthCardEnabled$('healthFund') | async) {
      @if (patient.healthFundCard; as healthFundCard) {
        <div class="mt-6 text-sm font-bold">Health Fund Card</div>

        <div *ngIf="healthFundCard.fundCode as fundCode" class="flex flex-col">
          <div class="text-xs opacity-50">Health Fund Name</div>
          <div>{{ fundCode }}</div>
        </div>

        <div class="flex flex-col">
          <div class="text-xs opacity-50">Membership Number</div>
          <div>{{ healthFundCard.membershipNumber }}</div>
        </div>

        <div class="flex flex-col">
          <div class="text-xs opacity-50">Member Number</div>
          <div>{{ healthFundCard.memberNumber }}</div>
        </div>
      }
    }

    @if (healthCardEnabled$('medicare') | async) {
      @if (patient.medicareCard; as medicareCard) {
        <div class="mt-6 text-sm font-bold">Medicare Card</div>

        <div class="flex flex-col">
          <div class="text-xs opacity-50">Card Number</div>
          <div>{{ medicareCard.number }}</div>
        </div>

        <div class="flex flex-col">
          <div class="text-xs opacity-50">Patient IRN</div>
          <div>{{ medicareCard.subNumerate }}</div>
        </div>

        <div class="flex flex-col">
          <div class="text-xs opacity-50">Expiry Date</div>
          <div>
            {{ medicareCard.expiryDate | moment | amDateFormat: expiryFormat }}
          </div>
        </div>
      }
    }

    @if (healthCardEnabled$('nhi') | async) {
      @if (patient.nationalHealthIndexNumber; as nationalHealthIndexNumber) {
        <div class="mt-6 text-sm font-bold"></div>

        <div class="flex flex-col">
          <div class="text-xs opacity-50">National Health Index Number</div>
          <div>{{ nationalHealthIndexNumber }}</div>
        </div>
      }
    }
  </div>
}

@if (patientForm$ | async; as patientForm) {
  <div class="flex grow flex-col gap-4 p-4">
    @if (patientForm.form) {
      <h3 class="!m-0 !font-bold">Submitted Details</h3>
      <pr-patient-details-form-compare
        #patientFormDetails
        [ngClass]="{
          'pointer-events-none cursor-not-allowed': disabled$ | async
        }"
        [healthCardTypes]="healthCardTypes$ | async"
        [patient]="patient$ | async"
        [patientDetails]="patientForm.form.data"
        (patientDetailsChange)="patientDetailsChanged.emit($event)"
      />
    } @else {
      <pr-empty-state image="list" title="Submitted Details" />
    }
  </div>
}
