import { TaxStrategy } from '@principle-theorem/accounting';
import {
  type IFirestoreModel,
  isEnumValue,
  isObject,
} from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';

export interface IProduct extends IFirestoreModel {
  name: string;
  cost: number;
  taxStatus: TaxStrategy;
}

export function isProduct(item: unknown): item is IProduct {
  return (
    isObject(item) &&
    isString(item.name) &&
    isNumber(item.cost) &&
    isEnumValue(TaxStrategy, item.taxStatus)
  );
}
