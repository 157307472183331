<pr-history-list-item
  *ngFor="
    let item of sortedItems$ | ngrxPush;
    first as $first;
    last as $last;
    trackBy: trackByItem
  "
  [ngClass]="{ selected: item | map: isSelectedItem$:this | ngrxPush }"
  [selected]="item | map: isSelectedItem$:this | ngrxPush"
  [first]="$first"
  [last]="$last"
  [closed]="isClosed$ | ngrxPush"
  (click)="selectItem(item)"
>
  {{ item.label }}
</pr-history-list-item>
