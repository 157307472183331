import {
  SourceEntityMigrationType,
  type ISourceEntity,
  IPracticeMigration,
  IExpectedSourceRecordSize,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  Timezone,
  TypeGuard,
  WithRef,
  toTimestamp,
} from '@principle-theorem/shared';
import { flow, isNull, isNumber, isString, isUndefined } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { convertExactId, getExactSourceDate } from '../../util/helpers';
import { PatientSourceEntity } from './patient';
import { runQuery } from '../../../source/connection';
import { PATIENT_TRANSACTION_RESOURCE_TYPE } from '../../../destination/entities/patient-invoices';

export const PATIENT_TRANSACTIONS_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Transactions List',
      description: '',
      idPrefix: PATIENT_TRANSACTION_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export enum ExactTransactionType {
  Payment = 'payment',
  Invoice = 'invoice',
  Adjustment = 'adjustment',
  WriteOff = 'writeOff',
  TransferFrom = 'transferFrom',
  TransferTo = 'transferTo',
  Procedure = 'procedure',
}

interface ITransactionMetadata {
  type: ExactTransactionType;
  treatmentId: string;
  paymentType: string;
  openAmount: number;
}

export interface IExactTransactionResult {
  id: string;
  patient_id: string;
  amount: string;
  date: string;
  description: string;
  reference: string | null;
  user_code: string | null;
}

export interface IExactTransaction
  extends Omit<IExactTransactionResult, 'amount'>,
    Partial<ITransactionMetadata> {
  amount: number;
}

export interface IExactTransactionTranslations {
  date: Timestamp;
}

export interface IExactTransactionFilters {
  id: string;
  patientId: string;
  date: Timestamp;
}

function isExactTransaction(data: unknown): data is IExactTransaction {
  return TypeGuard.interface<IExactTransaction>({
    id: isString,
    patient_id: isString,
    amount: isNumber,
    date: isString,
    description: isString,
    type: TypeGuard.undefinedOr(TypeGuard.enumValue(ExactTransactionType)),
    treatmentId: [isString, isUndefined],
    paymentType: [isString, isUndefined],
    openAmount: [isNumber, isNull],
    reference: [isString, isNull],
    user_code: [isString, isNull],
  })(data);
}

const TRANSACTION_SOURCE_QUERY = `
SELECT
  patientid::TEXT AS patient_id,
  sourceid::TEXT AS id,
  amount,
  date,
  description,
  usercode::TEXT AS user_code,
  NULLIF(reference::TEXT, '') AS reference
FROM
  convtransaction
WHERE
  description NOT LIKE 'Type: Statement; Open: 0.00'
AND
  description NOT LIKE 'Type: Statement; Open: 0.00; Particulars: Statement printed'
GROUP BY patient_id, id, amount, date, description, user_code, reference
ORDER BY sourceid ASC
`;

export class PatientTransactionsSourceEntity extends BaseSourceEntity<
  IExactTransaction,
  IExactTransactionTranslations,
  IExactTransactionFilters
> {
  sourceEntity = PATIENT_TRANSACTIONS_SOURCE_ENTITY;
  entityResourceType = PATIENT_TRANSACTION_RESOURCE_TYPE;
  sourceQuery = TRANSACTION_SOURCE_QUERY;
  verifySourceFn = isExactTransaction;
  override defaultOffsetSize = 50000;
  allowOffsetJobs = true;

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  override transformDataFn = flow([transformTransactionResults]);

  getSourceRecordId(data: IExactTransaction): string {
    return data.id;
  }

  getSourceLabel(record: IExactTransaction): string {
    return `${record.patient_id} ${record.id}`;
  }

  translate(
    data: IExactTransaction,
    timezone: Timezone
  ): IExactTransactionTranslations {
    return {
      date: getExactSourceDate(data.date, timezone),
    };
  }

  getFilterData(
    data: IExactTransaction,
    timezone: Timezone
  ): IExactTransactionFilters {
    return {
      date: getExactSourceDate(data.date, timezone),
      id: data.id,
      patientId: data.patient_id,
    };
  }

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const response = await runQuery<IExactTransactionResult>(
      migration,
      this.sourceQuery
    );
    const expectedSize = transformTransactionResults(response.rows).length;
    return {
      expectedSize,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }
}

export function transformTransactionResults(
  rows: IExactTransactionResult[]
): IExactTransaction[] {
  return rows
    .map((row) => ({
      ...row,
      id: convertExactId(row.id),
      patient_id: convertExactId(row.patient_id),
      amount: parseFloat(row.amount),
      date: row.date,
      description: row.description,
      ...splitDescription(row.description),
    }))
    .filter((transaction) => {
      if (transaction.type === ExactTransactionType.Invoice) {
        return transaction.amount !== 0 || !!transaction.treatmentId;
      }
      return true;
    });
}

function splitDescription(description: string): Partial<ITransactionMetadata> {
  const parts = description.split(';');
  const typeDescription = parts.find((item) => item.includes('Type'));
  const treatmentDescription = parts.find((item) => item.includes('Course'));
  const paymentDescription = parts.find((item) => item.includes('Particulars'));
  const openDescription = parts.find((item) => item.includes('Open'));
  const type = typeDescription
    ? determineType(typeDescription.split(':')[1].trim().toLowerCase())
    : undefined;
  return {
    type,
    treatmentId: treatmentDescription
      ? treatmentDescription.split(':')[1].trim().toLowerCase()
      : undefined,
    paymentType: paymentDescription
      ? paymentDescription.split(':')[1].trim().toLowerCase()
      : undefined,
    openAmount: openDescription
      ? parseFloat(openDescription.split(':')[1].trim())
      : 0,
  };
}

function determineType(type: string): ExactTransactionType | undefined {
  if (type.includes(ExactTransactionType.Invoice)) {
    return ExactTransactionType.Invoice;
  }
  if (type.includes(ExactTransactionType.Adjustment)) {
    return ExactTransactionType.Adjustment;
  }
  if (type.includes(ExactTransactionType.Payment)) {
    return ExactTransactionType.Payment;
  }
  if (type.includes('transfer from')) {
    return ExactTransactionType.TransferFrom;
  }
  if (type.includes('transfer to')) {
    return ExactTransactionType.TransferTo;
  }
  if (type.includes('write off')) {
    return ExactTransactionType.WriteOff;
  }
  if (type.includes(ExactTransactionType.Procedure)) {
    return ExactTransactionType.Procedure;
  }
  return undefined;
}
