<pt-search-field icon="none">
  <input
    matInput
    placeholder="Add Treatment"
    autocomplete="off"
    #trigger="matAutocompleteTrigger"
    [formControl]="searchCtrl"
    [matAutocomplete]="autocomplete"
    cdkFocusInitial
  />
</pt-search-field>

<mat-autocomplete
  #autocomplete="matAutocomplete"
  [autoActiveFirstOption]="true"
  [displayWith]="displayFn"
  [disableRipple]="true"
  (optionSelected)="optionSelected($event)"
  (opened)="viewport.checkViewportSize()"
>
  <div
    (click)="
      $event.preventDefault();
      $event.stopImmediatePropagation();
      $event.stopPropagation()
    "
  >
    <cdk-virtual-scroll-viewport
      #viewport
      class="autocomplete-viewport"
      itemSize="48"
      minBufferPx="250"
      maxBufferPx="300"
    >
      <div
        *cdkVirtualFor="
          let treatment of searchFilter.results$ | async;
          trackBy: trackByTreatment
        "
      >
        <ng-container
          *ngIf="
            treatment | map : disableMultiTreatment : this;
            else defaultView
          "
        >
          <span matTooltip="Multi Treatments cannot be added here">
            <mat-option
              class="autocomplete-item"
              [value]="treatment"
              [disabled]="true"
            >
              <div fxFill>
                <pr-search-result-charted-item
                  class="flex-1"
                  [quickChartEnabled]="true"
                  [item]="treatment"
                 />
              </div>
            </mat-option>
          </span>
        </ng-container>
        <ng-template #defaultView>
          <mat-option class="autocomplete-item" [value]="treatment">
            <pr-search-result-charted-item
              class="flex-1"
              [quickChartEnabled]="true"
              [item]="treatment"
             />
          </mat-option>
        </ng-template>
      </div>
    </cdk-virtual-scroll-viewport>
    <mat-option
      class="autocomplete-actions"
      (onSelectionChange)="trigger.closePanel(); addNewTreatment($event)"
    >
      <button class="flex-1" mat-flat-button color="primary">
        New Treatment
      </button>
    </mat-option>
  </div>
</mat-autocomplete>
