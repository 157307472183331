<mat-toolbar color="accent">Create Brand</mat-toolbar>

<div class="layout-padding">
  <form [formGroup]="form" (ngSubmit)="submit()" fxLayout="column">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
    <button
      type="submit"
      mat-flat-button
      color="primary"
      [disabled]="form.invalid"
    >
      Create
    </button>
  </form>
</div>
