import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Firestore,
  type INamedDocument,
  isSameRef,
  type WithRef,
  multiSort,
  sortByCreatedAt,
  multiMap,
  toNamedDocument,
} from '@principle-theorem/shared';
import {
  type ISterilisationCycle,
  type IPatient,
  type IStaffer,
  type ISterilisationRecord,
  type ISterilisationPack,
  ISterilisationPackContent,
} from '@principle-theorem/principle-core/interfaces';
import {
  TypedFormControl,
  TypedFormGroup,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import { Validators } from '@angular/forms';
import { Observable, Subject, from, of } from 'rxjs';
import { Practice, stafferToNamedDoc } from '@principle-theorem/principle-core';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import {
  VersionedSchema,
  initVersionedSchema,
} from '@principle-theorem/editor';
import { CurrentPracticeScope } from '@principle-theorem/ng-principle-shared';
import { SterilisationStore } from '../../stores/sterilisation.store';

export interface IEditSterilisationRecordData {
  record: WithRef<ISterilisationRecord>;
}

export type IEditSterilisationRecordReturnData = Omit<
  ISterilisationRecord,
  'practice' | 'appointment' | 'deleted' | 'status'
>;

@Component({
  selector: 'pr-edit-sterilisation-record-dialog',
  templateUrl: './edit-sterilisation-record-dialog.component.html',
  styleUrls: ['./edit-sterilisation-record-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SterilisationStore],
})
export class EditSterilisationRecordDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  practiceScope = inject(CurrentPracticeScope);

  form = new TypedFormGroup<IEditSterilisationRecordReturnData>({
    data: new TypedFormControl<string>('', Validators.required),
    patient: new TypedFormControl<INamedDocument<IPatient>>(),
    scannedBy: new TypedFormControl<INamedDocument<IStaffer>>(),
    cycle: new TypedFormControl<INamedDocument<ISterilisationCycle>>(),
    pack: new TypedFormControl<INamedDocument<ISterilisationPack>>(),
    content: new TypedFormControl<ISterilisationPackContent[]>([]),
    notes: new TypedFormControl<VersionedSchema>(initVersionedSchema()),
  });

  availableCycles$: Observable<INamedDocument<ISterilisationCycle>[]>;
  packContent$: Observable<ISterilisationPackContent[] | undefined>;
  store = inject(SterilisationStore);

  constructor(
    private _dialogRef: MatDialogRef<
      EditSterilisationRecordDialogComponent,
      IEditSterilisationRecordReturnData
    >,
    @Inject(MAT_DIALOG_DATA) public data: IEditSterilisationRecordData
  ) {
    if (!data) {
      return;
    }
    this.form.patchValue(data.record);

    this.packContent$ = formControlChanges$(this.form.controls.pack).pipe(
      switchMap((pack) =>
        !pack
          ? of([])
          : isSameRef(pack, data.record.pack)
            ? of(data.record.content)
            : from(Firestore.getDoc(pack.ref)).pipe(
                map((resolvedPack) => resolvedPack.content)
              )
      )
    );

    this.packContent$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((content) => this.form.controls.content.setValue(content));

    this.availableCycles$ = Practice.sterilisationCycles$(
      data.record.practice
    ).pipe(multiSort(sortByCreatedAt), multiMap(toNamedDocument));

    this.store.loadAvailablePacks(
      Firestore.doc$(Practice.brandDoc(data.record.practice))
    );
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }
    const formData = this.form.getRawValue();
    this._dialogRef.close({
      ...formData,
      patient: formData.patient ? toNamedDocument(formData.patient) : undefined,
      scannedBy: formData.scannedBy
        ? stafferToNamedDoc(formData.scannedBy)
        : undefined,
    });
  }

  isSelectedNamedDocument(
    namedDocument: INamedDocument,
    selectedNamedDocument: INamedDocument
  ): boolean {
    return isSameRef(namedDocument, selectedNamedDocument);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
