import { Money } from '@principle-theorem/accounting';
import {
  AllocationTarget,
  AmountAllocation,
  OrganisationCache,
  Transaction,
  TransactionAllocation,
  isServiceCodeLineItem,
} from '@principle-theorem/principle-core';
import {
  CollectionGroup,
  ICustomLineItem,
  IInvoice,
  IPatient,
  IPractice,
  ITransaction,
  ITransactionAllocation,
  ITreatmentLineItem,
  InvoiceLineItemType,
  SpecialTransactionAllocationTarget,
  TransactionAllocationTarget,
  TransactionProvider,
  TransactionStatus,
  isTreatmentLineItem,
  type IBrand,
} from '@principle-theorem/principle-core/interfaces';
import {
  AnyDataTable,
  DataFormat,
  DocumentReference,
  Firestore,
  asyncForEach,
  collectionGroupQuery,
  query,
  splitCamel,
  titlecase,
  toMomentTz,
  toTimestamp,
  where,
  type IDataTable,
  type ITimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
import { flatten, groupBy, isNumber, sortBy, sum, toPairs, trim } from 'lodash';
import { Brand } from '../models/brand';
import { Invoice } from '../models/invoice/invoice';
import { dateRangeToPracticeTimezone } from './helpers';

const TREATMENT_GROUP = 'Treatment Summary';
const UNALLOCATED_GROUP = 'Unallocated';

interface ITransactionAllocationData {
  practice: WithRef<IPractice>;
  patient: WithRef<IPatient>;
  invoice: WithRef<IInvoice>;
  transaction: WithRef<ITransaction>;
  lineItemAllocationTargetName: string;
  lineItemAllocation: ILineItemTransactionAllocation;
}

interface ILineItemTransactionAllocation extends ITransactionAllocation {
  parentLabel?: string;
  label: string;
  lineItemUid: string;
  lineItemAmount: number;
  lineItemType: InvoiceLineItemType;
}

interface ITransactionAllocationRow {
  // Allocation Info
  allocatedTo: string;
  allocatedAmount: number;
  // Line Item Info
  lineItemGroup: string;
  treatmentGroup: string;
  lineItemLabel: string;
  lineItemAmount: number;
  // Transaction Info
  transactionReference: string;
  transactionDate: string;
  transactionProvider: string;
  transactionAmount: number;
  // Invoice Info
  patientName: string;
  invoice: string;
  invoiceLink: string;
  // Summary Info
  creditsUsed: number;
  discountsGiven: number;
  payments: number;
}

interface ISummaryRow {
  allocatedTo: string;
  lineItemGroup: string;
  treatmentGroup: string;
  lineItemLabel: string;
  creditsUsed: number | string;
  discountsGiven: number | string;
  payments: number | string;
}

function emptySummaryRow(overrides: Partial<ISummaryRow> = {}): ISummaryRow {
  return {
    allocatedTo: '',
    lineItemGroup: '',
    treatmentGroup: '',
    lineItemLabel: '',
    creditsUsed: '',
    discountsGiven: '',
    payments: '',
    ...overrides,
  };
}

export class TransactionAllocationsReport {
  async run(
    dateRange: ITimePeriod,
    brandRef: DocumentReference<IBrand>,
    appUrl: string
  ): Promise<IDataTable<ITransactionAllocationRow>[]> {
    const brand = await Firestore.getDoc(brandRef);

    const transactions = await this._getTransactions(brandRef, dateRange);
    const allocations = await asyncForEach(transactions, (transaction) =>
      this._getAllocationsForTransaction(transaction)
    );
    const rows = allocations
      .flat()
      .map((allocation) => this._toRow(allocation, brand, appUrl));

    const summary = this._toSummary(rows);
    const summaryByLineItemGroup = this._toSummaryByLineItemGroup(rows);
    const summaryByTreatment = this._toSummaryByTreatment(rows);

    return this._toDataTables(
      rows,
      summary,
      summaryByLineItemGroup,
      summaryByTreatment
    );
  }

  private async _getTransactions(
    brandRef: DocumentReference<IBrand>,
    dateRange: ITimePeriod
  ): Promise<WithRef<ITransaction>[]> {
    const practices = await query(
      Brand.practiceCol({ ref: brandRef }),
      where('deleted', '==', false)
    );
    const practiceTransactions = await asyncForEach(practices, (practice) => {
      const practiceDateRange = dateRangeToPracticeTimezone(
        practice,
        dateRange
      );
      return query(
        collectionGroupQuery<ITransaction>(CollectionGroup.Transactions),
        where('deleted', '==', false),
        where('status', '==', TransactionStatus.Complete),
        where('practiceRef', '==', practice.ref),
        where('createdAt', '>=', toTimestamp(practiceDateRange.from)),
        where('createdAt', '<=', toTimestamp(practiceDateRange.to))
      );
    });
    return flatten(practiceTransactions);
  }

  private async _getAllocationsForTransaction(
    transaction: WithRef<ITransaction>
  ): Promise<ITransactionAllocationData[]> {
    const invoice = await Firestore.getDoc(
      Transaction.invoiceDocRef(transaction)
    );
    const patient = await OrganisationCache.patients.getDoc(
      Invoice.patientDocRef(invoice)
    );
    const practice = await OrganisationCache.practices.getDoc(
      invoice.practice.ref
    );
    const transactionAllocations = Invoice.findTransactionAllocations(
      invoice,
      transaction.ref
    );

    const previousTransactionAllocations =
      Invoice.findTransactionAllocationsBefore(invoice, transaction.ref);

    const allocations = transactionAllocations.flatMap((allocation) =>
      this._toLineItemTransactionAllocations(
        invoice,
        allocation,
        previousTransactionAllocations
      )
    );

    return asyncForEach(allocations, async (allocation) => ({
      practice,
      invoice,
      patient,
      transaction,
      lineItemAllocation: allocation,
      lineItemAllocationTargetName: await this._resolveAllocationTargetName(
        allocation.allocatedTo
      ),
    }));
  }

  private async _resolveAllocationTargetName(
    allocatedTo: TransactionAllocationTarget
  ): Promise<string> {
    if (AllocationTarget.isUnallocated(allocatedTo)) {
      return titlecase(SpecialTransactionAllocationTarget.Unallocated);
    }
    const staffer = await OrganisationCache.staff.get.getDoc(allocatedTo);
    return staffer.user.name;
  }

  private _toRow(
    data: ITransactionAllocationData,
    brand: WithRef<IBrand>,
    appUrl: string
  ): ITransactionAllocationRow {
    const transactionDate = toMomentTz(
      data.transaction.createdAt,
      data.practice.settings.timezone
    ).format('YYYY-MM-DD HH:mm');

    const invoiceLink = [
      appUrl,
      brand.slug,
      'patients',
      data.patient.ref.id,
      'account/invoices',
      data.invoice.ref.id,
    ].join('/');

    const amount = data.lineItemAllocation.allocatedAmount;
    const isUsedCredit =
      data.transaction.provider === TransactionProvider.AccountCredit;
    const isDiscount =
      data.transaction.provider === TransactionProvider.Discount;
    const isPayment = !isUsedCredit && !isDiscount;
    return {
      // Allocation Info
      allocatedTo: data.lineItemAllocationTargetName,
      allocatedAmount: data.lineItemAllocation.allocatedAmount,
      // Line Item Info
      lineItemGroup: this._getLineItemGroup(data),
      treatmentGroup: data.lineItemAllocation.parentLabel ?? '',
      lineItemLabel: data.lineItemAllocation.label,
      lineItemAmount: data.lineItemAllocation.lineItemAmount,
      // Transaction Info
      transactionReference: data.transaction.reference,
      transactionDate,
      transactionProvider: titlecase(splitCamel(data.transaction.provider)),
      transactionAmount: data.transaction.amount,
      // Invoice Info
      patientName: data.patient.name,
      invoice: data.invoice.reference,
      invoiceLink,
      // Summary Info
      creditsUsed: isUsedCredit ? amount : 0,
      discountsGiven: isDiscount ? amount : 0,
      payments: isPayment ? amount : 0,
    };
  }

  private _toSummary(rows: ITransactionAllocationRow[]): ISummaryRow[] {
    const formattedRows = AllocationSummaryGrouping.groupComplexSummary(
      rows,
      (data) => data.allocatedTo,
      (allocatedTo) => ({ allocatedTo, lineItemLabel: 'Total' }),
      (allocationRows) => [
        ...AllocationSummaryGrouping.groupComplexSummary(
          allocationRows,
          (data) => data.lineItemGroup,
          (lineItemGroup) => ({ lineItemGroup, lineItemLabel: 'Total' })
        ),
        emptySummaryRow(),
      ]
    );

    return [emptySummaryRow(), ...formattedRows];
  }

  private _toSummaryByLineItemGroup(
    rows: ITransactionAllocationRow[]
  ): ISummaryRow[] {
    const formattedRows = AllocationSummaryGrouping.groupComplexSummary(
      rows,
      (data) => data.allocatedTo,
      (allocatedTo) => ({ allocatedTo, lineItemLabel: 'Total' }),
      (allocationRows) =>
        AllocationSummaryGrouping.groupComplexSummary(
          allocationRows,
          (data) => data.lineItemGroup,
          (lineItemGroup) => ({ lineItemGroup, lineItemLabel: 'Total' }),
          (lineItemGroupRows) => [
            ...AllocationSummaryGrouping.groupComplexSummary(
              lineItemGroupRows,
              (data) => data.lineItemLabel,
              (lineItemLabel) => ({ lineItemLabel })
            ),
            emptySummaryRow(),
          ]
        )
    );

    return [emptySummaryRow(), ...formattedRows];
  }

  private _toSummaryByTreatment(
    rows: ITransactionAllocationRow[]
  ): ISummaryRow[] {
    const formattedRows = AllocationSummaryGrouping.groupComplexSummary(
      rows,
      (data) => data.allocatedTo,
      (allocatedTo) => ({ allocatedTo, lineItemLabel: 'Total' }),
      (allocationRows) => [
        ...AllocationSummaryGrouping.groupComplexSummary(
          allocationRows,
          (data) => data.lineItemGroup,
          (lineItemGroup) => {
            return lineItemGroup !== TREATMENT_GROUP
              ? { lineItemGroup, lineItemLabel: 'Total' }
              : undefined;
          },
          (lineItemGroupRows, lineItemGroup) =>
            this._getLineItemGroupByTreatment(lineItemGroupRows, lineItemGroup)
        ),
        emptySummaryRow(),
      ]
    );

    return [emptySummaryRow(), ...formattedRows];
  }

  private _getLineItemGroupByTreatment(
    rows: ISummaryRow[],
    lineItemGroup: string
  ): ISummaryRow[] {
    const summary = AllocationSummaryGrouping.groupComplexSummary(
      rows,
      (data) => data.lineItemLabel,
      (lineItemLabel) => ({ lineItemLabel })
    );
    if (lineItemGroup !== TREATMENT_GROUP) {
      return [...summary, emptySummaryRow()];
    }

    return AllocationSummaryGrouping.groupComplexSummary(
      rows,
      (data) => data.treatmentGroup,
      (treatmentGroup) => ({
        lineItemGroup: treatmentGroup,
        lineItemLabel: 'Total',
      }),
      (treatmentGroupRows) => [
        ...AllocationSummaryGrouping.groupComplexSummary(
          treatmentGroupRows,
          (data) => data.lineItemLabel,
          (lineItemLabel) => ({ lineItemLabel })
        ),
        emptySummaryRow(),
      ]
    );
  }

  private _getLineItemGroup(data: ITransactionAllocationData): string {
    const treatmentTypes = [
      InvoiceLineItemType.ServiceCode,
      InvoiceLineItemType.TreatmentBasePrice,
    ];
    if (treatmentTypes.includes(data.lineItemAllocation.lineItemType)) {
      return TREATMENT_GROUP;
    }
    return titlecase(splitCamel(data.lineItemAllocation.lineItemType));
  }

  private _toDataTables(
    data: ISummaryRow[],
    summary: ISummaryRow[],
    byLineItemGroup: ISummaryRow[],
    summaryByTreatment: ISummaryRow[]
  ): AnyDataTable[] {
    const summaryColumns = [
      { key: 'allocatedTo', header: 'Allocated To' },
      { key: 'lineItemGroup', header: 'Line Item Group' },
      { key: 'lineItemLabel', header: 'Line Item Label' },
      {
        key: 'creditsUsed',
        header: 'Credits Used',
        format: DataFormat.Currency,
      },
      {
        key: 'discountsGiven',
        header: 'Discounts Given',
        format: DataFormat.Currency,
      },
      { key: 'payments', header: 'Payments', format: DataFormat.Currency },
    ];

    return [
      {
        name: 'Summary',
        data: summary,
        columns: summaryColumns,
      },
      {
        name: 'Allocations By Group',
        data: byLineItemGroup,
        columns: summaryColumns,
      },
      {
        name: 'Allocations By Treatment',
        data: summaryByTreatment,
        columns: summaryColumns,
      },
      {
        name: 'Allocations',
        data: sortBy(data, 'transactionDate'),
        columns: [
          { key: 'allocatedTo', header: 'Allocated To' },
          {
            key: 'allocatedAmount',
            header: 'Allocated Amount',
            format: DataFormat.Currency,
          },
          { key: 'lineItemGroup', header: 'Line Item Group' },
          { key: 'treatmentGroup', header: 'Treatment Group' },
          { key: 'lineItemLabel', header: 'Line Item Label' },
          { key: 'transactionReference', header: 'Transaction Reference' },
          { key: 'transactionDate', header: 'Transaction Date' },
          { key: 'transactionProvider', header: 'Transaction Provider' },
          {
            key: 'transactionAmount',
            header: 'Transaction Amount',
            format: DataFormat.Currency,
          },
          { key: 'patientName', header: 'Patient' },
          { key: 'invoice', header: 'Invoice Reference' },
          { key: 'invoiceLink', header: 'Invoice Link' },
          {
            key: 'creditsUsed',
            header: 'Credits Used',
            format: DataFormat.Currency,
          },
          {
            key: 'discountsGiven',
            header: 'Discounts Given',
            format: DataFormat.Currency,
          },
          { key: 'payments', header: 'Payments', format: DataFormat.Currency },
        ],
      },
    ];
  }

  private _toLineItemTransactionAllocations(
    invoice: IInvoice,
    transactionAllocation: ITransactionAllocation,
    previousTransactionAllocations: ITransactionAllocation[]
  ): ILineItemTransactionAllocation[] {
    const allocationToDivide = AmountAllocation.create(
      transactionAllocation.allocatedTo,
      transactionAllocation.allocatedAmount
    );
    const previousAllocations = TransactionAllocation.toAmountAllocations(
      previousTransactionAllocations
    );
    const lineItemAllocations =
      TransactionAllocation.allocateTransactionToLineItems(
        invoice,
        allocationToDivide,
        previousAllocations
      );
    return lineItemAllocations.map((allocation) => {
      const amount = Money.amount(allocation.amount);
      return {
        allocatedTo: allocation.allocatedTo,
        allocatedAmount: amount,
        allocatedProportion: amount / Money.amount(allocationToDivide.amount),
        label: this._getLineItemLabel(
          allocation.lineItem,
          allocation.lineItemParent
        ),
        parentLabel: allocation.lineItemParent
          ? this._getLineItemLabel(allocation.lineItemParent)
          : undefined,
        lineItemUid: allocation.lineItem.uid,
        lineItemType: allocation.lineItem.type,
        lineItemAmount: allocation.lineItem.amount,
      };
    });
  }

  private _getTreatmentLabel(lineItem: ITreatmentLineItem): string {
    const removeAfterLast = ' - (';
    const removeAfterIndex = lineItem.description.lastIndexOf(removeAfterLast);
    if (removeAfterIndex !== -1) {
      return lineItem.description.slice(0, removeAfterIndex);
    }
    return lineItem.description;
  }

  private _getLineItemLabel(
    lineItem: ICustomLineItem,
    parentLineItem?: ICustomLineItem
  ): string {
    if (isServiceCodeLineItem(lineItem)) {
      return lineItem.code;
    }
    if (isTreatmentLineItem(lineItem)) {
      return this._getTreatmentLabel(lineItem);
    }
    if (
      lineItem.type === InvoiceLineItemType.TreatmentBasePrice &&
      parentLineItem &&
      isTreatmentLineItem(parentLineItem)
    ) {
      const treatmentLabel = this._getTreatmentLabel(parentLineItem);
      return `${treatmentLabel} - Base Price`;
    }
    if (lineItem.type === InvoiceLineItemType.Deposit) {
      return trim(lineItem.description.toLowerCase());
    }
    return trim(lineItem.description);
  }
}

class AllocationSummaryGrouping {
  static groupComplexSummary(
    rows: ISummaryRow[],
    groupBySelector: (allocation: ISummaryRow) => string,
    getLabels: (groupName: string) => Partial<ISummaryRow> | undefined,
    getNestedRows: (
      groupRows: ISummaryRow[],
      groupName: string
    ) => ISummaryRow[] = () => []
  ): ISummaryRow[] {
    const groups = groupBy(rows, groupBySelector);

    const sorted = sortBy(toPairs(groups), ([groupName]) => {
      switch (groupName) {
        case UNALLOCATED_GROUP:
          return '00000';
        case TREATMENT_GROUP:
          return 'zzzzz';
        default:
          return groupName;
      }
    });

    return sorted.flatMap(([groupName, groupRows]) => {
      const nestesRows = getNestedRows(groupRows, groupName);
      const totalRowLabels = getLabels(groupName);
      if (!totalRowLabels) {
        return nestesRows;
      }
      const groupTotalRow = this.reduce(groupRows, totalRowLabels);
      return [groupTotalRow, ...getNestedRows(groupRows, groupName)];
    });
  }

  static reduce(
    rows: ISummaryRow[],
    labels: Partial<ISummaryRow>
  ): ISummaryRow {
    return emptySummaryRow({
      ...labels,
      creditsUsed: this.getAmount(rows, 'creditsUsed'),
      discountsGiven: this.getAmount(rows, 'discountsGiven'),
      payments: this.getAmount(rows, 'payments'),
    });
  }

  static getAmount(rows: ISummaryRow[], key: keyof ISummaryRow): number {
    const values = rows.map((row) => {
      const value = row[key];
      return !isNumber(value) ? 0 : value;
    });
    return Money.amount(sum(values));
  }
}
