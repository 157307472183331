import {
  IBrand,
  IOrganisation,
  Sender,
  SenderType,
} from '@principle-theorem/principle-core/interfaces';
import { getDoc, getParentDocRef, WithRef } from '@principle-theorem/shared';

export async function resolveOrganisationFromSender(
  sender: Sender
): Promise<WithRef<IOrganisation>> {
  switch (sender.type) {
    case SenderType.Staffer:
    case SenderType.Practice:
      const brandRef = getParentDocRef<IBrand>(sender.ref);
      const brandOrg = getParentDocRef<IOrganisation>(brandRef);
      return getDoc(brandOrg);
    case SenderType.Brand:
      const orgRef = getParentDocRef<IOrganisation>(sender.ref);
      return getDoc(orgRef);
    default:
      return getDoc(sender.ref);
  }
}
