import { createAction, props } from '@ngrx/store';
import { type IManageAppointmentQueryParams } from '@principle-theorem/ng-principle-shared';
import {
  IScheduleSummaryEventable,
  ISchedulingEventData,
  type IAppointment,
  type IChecklistItem,
  type IInteractionV2,
  type IPatient,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  type SerialisedData,
  type WithRef,
} from '@principle-theorem/shared';
import { getErrorAction } from '@principle-theorem/ng-shared';

enum SchedulingAction {
  SaveNewAppointment = '[Create Appointment] Save',
  SaveNewAppointmentSuccess = '[Create Appointment] Save Success',
  SaveAppointmentFailure = '[Appointment Scheduling] Save Failure',
  Reset = '[Appointment Scheduling] Reset State',
  UpdateInteractions = '[Appointment Scheduling] Update Interactions',
  SetExistingAppointment = '[Appointment Scheduling] Set Existing Appointment',
  RescheduleAppointment = '[Reschedule Appointment] Update',
  RescheduleAppointmentSuccess = '[Reschedule Appointment] Update Success',
  UpdateTags = '[Appointment Scheduling] Update Tags',
  UpdateChecklists = '[Appointment Scheduling] Update Checklists',
  SetAppointmentManageRouteData = '[Appointment Scheduling Router] Set Appointment & Patient',
  SetRequestedAppointmentOption = '[Appointment Scheduling Router] Set Requested Appointment Option',
  SetEventSelectedFromOptions = '[Appointment Scheduling] Set Event Selected From Options',
  SetFromGap = '[Appointment Scheduling] Set Details From Gap',
}

interface ISaveNewAppointment {
  schedulingEventData: ISchedulingEventData;
}

export const saveNewAppointment = createAction(
  SchedulingAction.SaveNewAppointment,
  props<SerialisedData<ISaveNewAppointment>>()
);

interface IWithAppointment {
  appointment: WithRef<IAppointment>;
}

export interface ISetExistingAppointment {
  appointment?: WithRef<IAppointment>;
  updateSelectedSuggestion: boolean;
}

export const saveNewAppointmentSuccess = createAction(
  SchedulingAction.SaveNewAppointmentSuccess,
  props<SerialisedData<IWithAppointment>>()
);

export const saveAppointmentFailure = getErrorAction(
  SchedulingAction.SaveAppointmentFailure
);

export const resetSchedulingState = createAction(SchedulingAction.Reset);

interface IUpdateInteractions {
  interactions: IInteractionV2[];
}

export const updateInteractions = createAction(
  SchedulingAction.UpdateInteractions,
  props<SerialisedData<IUpdateInteractions>>()
);

export const setExistingAppointment = createAction(
  SchedulingAction.SetExistingAppointment,
  props<SerialisedData<ISetExistingAppointment>>()
);

export interface IRescheduleAppointmentRequest {
  schedulingEventData: ISchedulingEventData;
}

export const rescheduleAppointment = createAction(
  SchedulingAction.RescheduleAppointment,
  props<SerialisedData<IRescheduleAppointmentRequest>>()
);

export const rescheduleAppointmentSuccess = createAction(
  SchedulingAction.RescheduleAppointmentSuccess,
  props<SerialisedData<IWithAppointment>>()
);

interface IUpdateTags {
  tags: INamedDocument<ITag>[];
}

export const updateAppointmentTags = createAction(
  SchedulingAction.UpdateTags,
  props<SerialisedData<IUpdateTags>>()
);

interface IUpdateChecklists {
  checklists: IChecklistItem[];
}

export const updateAppointmentChecklists = createAction(
  SchedulingAction.UpdateChecklists,
  props<SerialisedData<IUpdateChecklists>>()
);

interface IAppointmentWithPatient {
  appointment: WithRef<IAppointment>;
  patient: WithRef<IPatient>;
}

export const setAppointmentManageRouteData = createAction(
  SchedulingAction.SetAppointmentManageRouteData,
  props<SerialisedData<IAppointmentWithPatient>>()
);

export interface IRequestedAppointmentOption {
  params: IManageAppointmentQueryParams;
  appointment?: WithRef<IAppointment>;
}

export const setRequestedAppointmentOption = createAction(
  SchedulingAction.SetRequestedAppointmentOption,
  props<SerialisedData<IRequestedAppointmentOption>>()
);

export const setAppointmentDetailsFromGap = createAction(
  SchedulingAction.SetFromGap,
  props<SerialisedData<{ gap: IScheduleSummaryEventable }>>()
);
