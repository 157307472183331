import { createAction, props } from '@ngrx/store';
import {
  type IInvoiceReportRecord,
  type IInvoiceReportRequest,
} from '@principle-theorem/reporting';
import { type SerialisedData } from '@principle-theorem/shared';

export enum OutstandingInvoicesReportActions {
  SetQuery = '[Outstanding Invoices Report] Set Query',
  LoadReport = '[Outstanding Invoices Report] Load',
  LoadReportSuccess = '[Outstanding Invoices Report] Load Success',
  LoadReportFailure = '[Outstanding Invoices Report] Load Failure',
}

export const setQuery = createAction(
  OutstandingInvoicesReportActions.SetQuery,
  props<SerialisedData<IInvoiceReportRequest>>()
);

export const loadReport = createAction(
  OutstandingInvoicesReportActions.LoadReport
);

export const loadReportSuccess = createAction(
  OutstandingInvoicesReportActions.LoadReportSuccess,
  props<SerialisedData<{ results: IInvoiceReportRecord[] }>>()
);

export const loadReportFailure = createAction(
  OutstandingInvoicesReportActions.LoadReportFailure,
  props<{ errorMessage: string }>()
);
