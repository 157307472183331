import { CommonModule, CurrencyPipe, PercentPipe } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { type ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { ColorPickerModule } from 'ngx-color-picker';
import { MomentModule } from 'ngx-moment';
import { WebcamModule } from 'ngx-webcam';
import { AuthModule } from './auth/auth.module';
import { BrandResolverService } from './brand-resolver.service';
import { ComponentsModule } from './components/components.module';
import { DateService } from './date.service';
import { FirebaseFunctionsService } from './firebase/firebase-functions.service';
import { GeocodeService } from './maps/geocode.service';
import { NavigationModule } from './navigation/navigation.module';
import {
  type INgPrincipleSharedConfig,
  NG_PRINCIPLE_SHARED_CONFIG,
} from './ng-principle-shared-config';
import { PipesModule } from './pipes/pipes.module';
import { PracticeResolverService } from './practice-resolver.service';
import { ScrollContainerManagerService } from './scroll-detector/scroll-container.service';
import { ScrollDetectorDirective } from './scroll-detector/scroll-detector.directive';
import { StafferResolverService } from './staffer-resolver.service';
import { IsRegionDirective } from './is-region.directive';
import { AccountCreditActionsService } from './services/account-credit-actions.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgMaterialModule,
    WebcamModule,
    ColorPickerModule,
    MomentModule,
    NgFeatureFlagsModule,
    GoogleMapsModule,
    ComponentsModule,
    PipesModule,
    AuthModule,
    NavigationModule,
  ],
  providers: [
    DateService,
    CurrencyPipe,
    PercentPipe,
    BrandResolverService,
    PracticeResolverService,
    StafferResolverService,
    ScrollContainerManagerService,
    GeocodeService,
    FirebaseFunctionsService,
    AccountCreditActionsService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
  declarations: [ScrollDetectorDirective, IsRegionDirective],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgMaterialModule,
    WebcamModule,
    ColorPickerModule,
    MomentModule,
    NgFeatureFlagsModule,
    GoogleMapsModule,
    ComponentsModule,
    PipesModule,
    ScrollDetectorDirective,
    AuthModule,
    NavigationModule,
    IsRegionDirective,
  ],
})
export class NgPrincipleSharedModule {
  static forRoot(
    config: INgPrincipleSharedConfig
  ): ModuleWithProviders<NgPrincipleSharedModule> {
    return {
      ngModule: NgPrincipleSharedModule,
      providers: [
        {
          provide: NG_PRINCIPLE_SHARED_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
