import { type PracticeMigrationEssentials } from '@principle-theorem/principle-core/interfaces';
import { DENTRIX_DESTINATION } from './destination';
import { DENTRIX_MAPPINGS } from './mappings';
import { DENTRIX_SOURCE } from './source';

export const DENTRIX_MIGRATION: PracticeMigrationEssentials = {
  metadata: {
    name: 'Dentrix',
    version: 'v1',
    supportsMultiplePractices: false,
  },
  source: DENTRIX_SOURCE,
  destination: DENTRIX_DESTINATION,
  mappings: DENTRIX_MAPPINGS,
};
