import { type ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { getSchemaSize } from '@principle-theorem/editor';
import { ClinicalNotesHistoryDialogComponent } from '@principle-theorem/ng-clinical-charting';
import {
  type INotesDialogData,
  NotesDialogComponent,
} from '@principle-theorem/ng-interactions';
import { DialogPresets, TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  Appointment,
  ClinicalNote,
  Patient,
} from '@principle-theorem/principle-core';
import {
  PatientRelationshipType,
  type IAppointment,
  type IPatient,
  type IPinnedInteractionSummary,
} from '@principle-theorem/principle-core/interfaces';
import {
  count as countOp,
  multiFilter,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppointmentInteractionsDialogComponent } from '../../appointment-interactions-dialog/appointment-interactions-dialog.component';
import { AppointmentPinnedNotesBloc } from './appointment-pinned-notes-bloc';

@Component({
  selector: 'pr-appointment-all-notes',
  templateUrl: './appointment-all-notes.component.html',
  styleUrls: ['./appointment-all-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AppointmentAllNotesComponent {
  trackByNote = TrackByFunctions.field<INoteSummary>('name');
  trackByPinnedNote = TrackByFunctions.uniqueId<IPinnedInteractionSummary>();
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);
  patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);
  noteSummaries$: Observable<INoteSummary[]>;
  pinnedNotes: AppointmentPinnedNotesBloc;

  constructor(private _dialog: MatDialog) {
    this.pinnedNotes = new AppointmentPinnedNotesBloc(this.appointment$);
    this.noteSummaries$ = combineLatest([
      this._getClinicalSummary$(),
      this._getSchedulingSummary$(),
      this._getSocialSummary$(),
    ]);
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  private _getSocialSummary$(): Observable<INoteSummary> {
    return combineLatest([this.patient$, this.pinnedNotes.social$]).pipe(
      map(([patient, pinnedNotes]) => ({
        name: 'Social',
        open: () => this._openSocialNotes(patient),
        count: patient.notes.length,
        pinnedNotes,
      }))
    );
  }

  private _getSchedulingSummary$(): Observable<INoteSummary> {
    const interactions$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.interactions$(appointment))
    );

    const count$ = interactions$.pipe(
      multiFilter((interaction) => getSchemaSize(interaction.content) > 0),
      countOp()
    );

    return combineLatest([
      this.patient$,
      this.appointment$,
      count$,
      this.pinnedNotes.scheduling$,
    ]).pipe(
      map(([patient, appointment, count, pinnedNotes]) => ({
        name: 'Scheduling',
        open: () => this._openInteractions(patient, appointment),
        count,
        pinnedNotes,
      }))
    );
  }

  private _getClinicalSummary$(): Observable<INoteSummary> {
    const count$ = this.patient$.pipe(
      switchMap((patient) =>
        Patient.withPatientRelationships$(
          patient,
          [PatientRelationshipType.DuplicatePatient],
          ClinicalNote.all$
        )
      ),
      switchMap((clinicalNotes) => ClinicalNote.filterEmpty$(clinicalNotes)),
      countOp()
    );

    return combineLatest([
      this.patient$,
      count$,
      this.pinnedNotes.clinical$,
    ]).pipe(
      map(([patient, count, pinnedNotes]) => ({
        name: 'Clinical',
        open: () => this._openClinicalNotes(patient),
        count,
        pinnedNotes,
      }))
    );
  }

  private _openSocialNotes(patient: WithRef<IPatient>): void {
    const config: MatDialogConfig = DialogPresets.medium<INotesDialogData>({
      data: {
        title: 'Social notes',
        notable: {
          notes: patient.notes,
          ref: patient.ref,
        },
      },
    });
    this._openNotesDialog(NotesDialogComponent, config);
  }

  private _openInteractions(
    patient: WithRef<IPatient>,
    appointment: WithRef<IAppointment>
  ): void {
    const config: MatDialogConfig = DialogPresets.large({
      height: '80%',
      data: {
        appointment,
        patient,
      },
    });
    this._openNotesDialog(AppointmentInteractionsDialogComponent, config);
  }

  private _openClinicalNotes(patient: WithRef<IPatient>): void {
    const config: MatDialogConfig = DialogPresets.large({
      height: '80%',
      data: { patient },
    });
    this._dialog.closeAll();
    this._openNotesDialog(ClinicalNotesHistoryDialogComponent, config);
  }

  private _openNotesDialog<T>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config: MatDialogConfig
  ): void {
    this._dialog.open<T>(componentOrTemplateRef, config);
  }
}

export interface INoteSummary {
  count: number;
  name: string;
  pinnedNotes: IPinnedInteractionSummary[];
  open: () => void;
}
