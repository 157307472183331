import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import {
  type AppointmentSuggestionEntity,
  type IAppointmentFilterOptions,
} from '@principle-theorem/principle-core';
import { Moment } from 'moment-timezone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-appointment-selector',
    templateUrl: './appointment-selector.component.html',
    styleUrls: ['./appointment-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentSelectorComponent {
  openTime$: Observable<Moment>;
  closeTime$: Observable<Moment>;
  selectedEvent$: Observable<AppointmentSuggestionEntity | undefined>;
  loading$: Observable<boolean>;
  appointmentSuggestions$: Observable<AppointmentSuggestionEntity[]>;
  selectedTreatmentDuration$: Observable<number>;

  constructor(private _schedulingFacade: AppointmentSchedulingFacade) {
    this.openTime$ = this._schedulingFacade.openTime$;
    this.closeTime$ = this._schedulingFacade.closeTime$;
    this.selectedEvent$ = this._schedulingFacade.selectedSuggestion$;
    this.loading$ = this._schedulingFacade.loadingSuggestions$.pipe(
      map((loaded) => !loaded)
    );
    this.appointmentSuggestions$ =
      this._schedulingFacade.appointmentSuggestions$;
    this.selectedTreatmentDuration$ =
      this._schedulingFacade.selectedTreatmentDuration$;
  }

  filterOptionsChange(change: IAppointmentFilterOptions): void {
    this._schedulingFacade.appointmentFilterOptionsChange(change);
  }

  selectEvent(option?: AppointmentSuggestionEntity): void {
    this._schedulingFacade.toggleSuggestion(option?.uid);
  }
}
