import { TaxStrategy } from '@principle-theorem/accounting';
import { isArray, isEnumValue, isObject } from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { type ICustomLineItem } from './custom-line-items';
import { type ProviderLineItemDetails } from '../staffer/staffer';

export interface IInvoiceLineItem {
  uid: string;
  description: string;
  amount: number;
  taxStatus: TaxStrategy;
  tax: number;
  quantity: number;
}

export function isInvoiceLineItem(item: unknown): item is IInvoiceLineItem {
  return (
    isObject(item) &&
    isString(item.uid) &&
    isString(item.description) &&
    isNumber(item.amount) &&
    isNumber(item.tax) &&
    isNumber(item.quantity) &&
    isEnumValue(TaxStrategy, item.taxStatus)
  );
}

export interface IHasLineItems<T extends IInvoiceLineItem = IInvoiceLineItem> {
  items: T[];
}

export function isHasLineItems(item: unknown): item is IHasLineItems {
  return (
    isObject(item) &&
    isArray(item.items) &&
    item.items.every((lineItem) => isInvoiceLineItem(lineItem))
  );
}

export type IInvoiceLineItemGroup<
  T extends IInvoiceLineItem = IInvoiceLineItem
> = T & IHasLineItems<T>;

export type IInvoiceItemGroupWithProvider =
  IInvoiceLineItemGroup<ICustomLineItem> & ProviderLineItemDetails;
