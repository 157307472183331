import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  Appointment,
  AppointmentManager,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  AppointmentSummary,
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  IReffable,
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { AppointmentCardAction } from './appointment-card-action-interface';

@Injectable()
export class CompleteAppointmentActionService extends AppointmentCardAction {
  name = 'Ready for Checkout';

  constructor(
    private _organisation: OrganisationService,
    private _snackBar: MatSnackBar
  ) {
    super();
  }

  isVisible(appointment: WithRef<IAppointment> | AppointmentSummary): boolean {
    return Appointment.inProgress(appointment);
  }

  async run(
    appointment: WithRef<IAppointment>,
    _: IReffable<IPatient>
  ): Promise<void> {
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    await AppointmentManager.checkOut(staffer, appointment);
    this._snackBar.open('Appointment ready for checkout');
  }

  updateEventableSummary(
    eventable: AppointmentSummary,
    updateFn: (
      oldEvent: AppointmentSummary,
      newEvent: AppointmentSummary
    ) => void
  ): void {
    const metadata = {
      ...eventable.metadata,
      status: AppointmentStatus.Complete,
    };

    updateFn(eventable, { ...eventable, metadata });
  }
}
