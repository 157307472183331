import {
  ConditionLogicConfigurationCollection,
  ConditionLogicId,
  IAndConditions,
  IConditionLogicConfiguration,
  IOrConditions,
} from '@principle-theorem/principle-core/interfaces';
import { v4 as uuid } from 'uuid';

export class ConditionLogic {
  static collection(
    andConditions: IAndConditions[]
  ): ConditionLogicConfigurationCollection {
    return this.or(andConditions);
  }

  static or(andConditions: IAndConditions[]): IOrConditions {
    return {
      uid: uuid(),
      or: andConditions,
    };
  }

  static and(
    conditions: IConditionLogicConfiguration<unknown>[] = []
  ): IAndConditions {
    return {
      uid: uuid(),
      and: conditions,
    };
  }

  static never(): IConditionLogicConfiguration<unknown> {
    return {
      uid: uuid(),
      conditionId: ConditionLogicId.Never,
      config: {},
    };
  }

  static always(): IConditionLogicConfiguration<unknown> {
    return {
      uid: uuid(),
      conditionId: ConditionLogicId.Always,
      config: {},
    };
  }
}
