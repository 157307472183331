<div class="flex flex-col gap-2">
  @for (automation of automations$ | async; track trackByAutomation) {
    <div class="flex flex-row items-center gap-2">
      <pr-automation-icon [automation]="automation.data" />
      <div class="flex min-w-0 grow flex-col">
        <pr-content-text
          class="min-w-0 truncate"
          [content]="automation | map: getTitle"
          [ptTruncateTooltip]="automation | map: getTitle"
        />
        @if (automation | map: getTriggerDate : this | async; as triggerDate) {
          <div class="text-xs opacity-50">
            {{ triggerDate | moment | amDateFormat: dateFormat }}
          </div>
        }
      </div>

      <pr-automation-status
        class="w-16 text-right"
        [status]="automation.status"
      />
    </div>
  } @empty {
    <div class="text-xs opacity-50">
      There are no automations for this appointment
    </div>
  }
</div>
