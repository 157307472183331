import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type ICalendarEvent } from '@principle-theorem/principle-core/interfaces';
import { snapshot, toTimestamp, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-edit-calendar-event',
  templateUrl: './edit-calendar-event.component.html',
  styleUrls: ['./edit-calendar-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCalendarEventComponent {
  calendarEvent$ = new ReplaySubject<WithRef<ICalendarEvent> | ICalendarEvent>(
    1
  );

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { calendarEvent: WithRef<ICalendarEvent> | ICalendarEvent },
    public dialogRef: MatDialogRef<
      EditCalendarEventComponent,
      WithRef<ICalendarEvent> | ICalendarEvent
    >
  ) {
    this.calendarEvent$.next(data.calendarEvent);
  }

  async save(newEvent: ICalendarEvent): Promise<void> {
    const storedEvent = await snapshot(this.calendarEvent$);
    const isSame = storedEvent.event === newEvent.event;
    if (isSame) {
      return;
    }

    storedEvent.eventHistory.push({
      event: storedEvent.event,
      createdAt: toTimestamp(),
    });

    const { eventHistory } = newEvent;
    this.dialogRef.close({
      ...storedEvent,
      ...newEvent,
      eventHistory,
    });
  }
}
