<ng-container *ngrxLet="transaction$ as transaction">
  <div *ngrxLet="isOutgoing$ as isOutgoing" class="flex flex-col gap-1">
    <div
      class="flex items-center justify-between sm:items-start sm:justify-start sm:gap-4"
    >
      <div class="status sm:mt-1" [ngClass]="transaction.status">
        {{ transaction.status | uppercase }}
      </div>

      <div class="hidden flex-1 space-y-1 sm:block">
        <div class="flex gap-4">
          <div class="provider-id mat-caption">
            <div class="provider">
              {{ transaction.provider | splitCamel | titlecase }}
            </div>
            <div class="id">
              {{ transaction.reference }}
            </div>
          </div>

          <div class="date mat-caption flex-1">
            {{ transaction.createdAt | moment | amDateFormat: dateFormat }}
          </div>
        </div>

        @if (transaction.description) {
          <div class="rounded-md bg-gray-100 p-1 text-xs text-black/60">
            {{ transaction.description }}
          </div>
        }
      </div>

      <span class="amount" [class]="{ outgoing: isOutgoing }">
        <ng-container *ngIf="isOutgoing">-</ng-container>
        {{ transaction.amount | currency }}
      </span>
    </div>

    <div class="provider-id mat-caption sm:hidden">
      <div class="provider">
        {{ transaction.provider | splitCamel | titlecase }}
        <ng-container *ngIf="isOutgoing">Refund</ng-container>
      </div>
      <div class="id">
        {{ transaction.reference }}
      </div>
    </div>

    <div class="date mat-caption sm:hidden">
      {{ transaction.createdAt | moment | amDateFormat: dateFormat }}
    </div>

    @if (transaction.description) {
      <div class="rounded-md bg-gray-100 p-1 text-xs text-black/60 sm:hidden">
        {{ transaction.description }}
      </div>
    }
  </div>
</ng-container>
