import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import {
  AnyAutomation,
  isAutomatedFormIssue,
  isAutomatedNotification,
  isGeneratedTask,
} from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-automation-label',
  templateUrl: './automation-label.component.html',
  styleUrls: ['./automation-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NgSharedModule, NgInteractionsModule],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex max-w-full' },
})
export class AutomationLabelComponent {
  automation$ = new ReplaySubject<AnyAutomation>(1);
  isGeneratedTask = isGeneratedTask;
  isAutomatedNotification = isAutomatedNotification;
  isAutomatedFormIssue = isAutomatedFormIssue;

  @Input()
  set automation(automation: AnyAutomation) {
    if (automation) {
      this.automation$.next(automation);
    }
  }
}
