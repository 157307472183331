import {
  ISoftDelete,
  Timestamp,
  type DocumentReference,
  type INamedDocument,
  TypeGuard,
  isINamedDocument,
} from '@principle-theorem/shared';
import { type IAppointment } from '../appointment/appointment';
import { type IPatient } from '../patient/patient';
import { type IPractice } from '../practice/practice';
import { type IStaffer } from '../staffer/staffer';
import { MixedSchema } from '@principle-theorem/editor';
import { isBoolean, isNumber, isString } from 'lodash';

export interface ISterilisationMachine extends ISoftDelete {
  id: string;
  name: string;
  description: string;
  practice: INamedDocument<IPractice>;
}

export enum SterilisationStatus {
  Pending = 'pending',
  Passed = 'passed',
  Failed = 'failed',
}

export const STERILISATION_STATUS_COLOUR_MAP: {
  [key in SterilisationStatus]: string;
} = {
  [SterilisationStatus.Pending]: 'default',
  [SterilisationStatus.Passed]: 'success',
  [SterilisationStatus.Failed]: 'warn',
};

export interface ISterilisationCycle {
  id: string;
  machine: INamedDocument<ISterilisationMachine>;
  name: string;
  runDate: Timestamp;
  staffer: INamedDocument<IStaffer>;
  status: SterilisationStatus;
  cycleType?: INamedDocument<ISterilisationCycleType>;
  notes?: MixedSchema;
}

export interface ISterilisationPack extends ISoftDelete {
  name: string;
  description: string;
  content: ISterilisationPackContent[];
}

export interface ISterilisationPackContent {
  item: INamedDocument<ISterilisationEquipment>;
  quantity: number;
}

export interface ISterilisationEquipment extends ISoftDelete {
  name: string;
  description?: string;
}

export interface ISterilisationCycleType extends ISoftDelete {
  name: string;
  description: string;
  isTestType: boolean;
}

export type SterilisableItem = ISterilisationPack | ISterilisationEquipment;

export interface ISterilisationRecord extends ISoftDelete {
  data: string;
  practice: INamedDocument<IPractice>;
  scannedBy?: INamedDocument<IStaffer>;
  appointment?: DocumentReference<IAppointment>;
  patient?: INamedDocument<IPatient>;
  pack?: INamedDocument<SterilisableItem>;
  content?: ISterilisationPackContent[];
  cycle?: INamedDocument<ISterilisationCycle>;
  notes?: MixedSchema;
  status: SterilisationStatus;
}

export enum SterilisationRecordCollection {
  RecordHistory = 'recordHistory',
}

export enum SterilisationCycleCollection {
  Media = 'media',
  CycleHistory = 'cycleHistory',
}

export function isSterilisationPackContent(
  item: unknown
): item is ISterilisationPackContent {
  return TypeGuard.interface<ISterilisationPackContent>({
    item: isINamedDocument<ISterilisationEquipment>,
    quantity: isNumber,
  })(item);
}

export function isSterilisationPack(item: unknown): item is ISterilisationPack {
  return TypeGuard.interface<ISterilisationPack>({
    name: isString,
    description: isString,
    deleted: isBoolean,
    content: TypeGuard.arrayOf(isSterilisationPackContent),
  })(item);
}
