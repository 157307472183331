import { Validators } from '@angular/forms';
import {
  initVersionedSchema,
  type RawInlineNodes,
  type MixedSchema,
} from '@principle-theorem/editor';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  type IPractice,
  type IStaffer,
  type ITeam,
  TaskPriority,
  ConditionLogicConfigurationCollection,
} from '@principle-theorem/principle-core/interfaces';
import { type INamedDocument, isDocRef } from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { TimingFormGroup } from '../timing.formgroup';
import { type IGeneratedTaskFormData } from './generated-task-dialog.component';
import { ConditionLogic } from '@principle-theorem/principle-core';

export class GeneratedTaskDialogForm extends TypedFormGroup<
  Omit<IGeneratedTaskFormData, 'deleted' | 'isActive' | 'treatmentRefs'>
> {
  constructor() {
    super({
      title: new TypedFormControl<RawInlineNodes>([], Validators.required),
      assignee: new TypedFormControl<INamedDocument<IStaffer | ITeam>>(
        undefined,
        Validators.required
      ),
      timing: new TimingFormGroup(),
      priority: new TypedFormControl<TaskPriority>(TaskPriority.Low),
      comment: new TypedFormControl<MixedSchema>(initVersionedSchema()),
      isForTeam: new TypedFormControl<boolean>(false),
      triggerDate: new TypedFormControl<Moment | undefined>({
        value: undefined,
        disabled: true,
      }),
      triggerTime: new TypedFormControl<string | undefined>({
        value: undefined,
        disabled: true,
      }),
      practiceRef: new TypedFormControl<
        DocumentReference<IPractice> | undefined
      >({
        value: undefined,
        disabled: true,
      }).withGuard(isDocRef),
      requiredConditions:
        new TypedFormControl<ConditionLogicConfigurationCollection>(
          ConditionLogic.collection([
            ConditionLogic.and([ConditionLogic.always()]),
          ])
        ),
    });
  }

  enableTriggerControls(): void {
    this.controls.triggerDate.enable();
    this.controls.triggerTime.enable();
  }
}
