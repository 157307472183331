import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TeamCreateDialogComponent } from './team-create-dialog/team-create-dialog.component';
import { TeamEditComponent } from './team-edit/team-edit.component';
import { TeamFormComponent } from './team-form/team-form.component';
import { TeamStafferAssignmentComponent } from './team-staffer-assignment/team-staffer-assignment.component';
import { TeamsListComponent } from './teams-list/teams-list.component';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import { TeamCollectionResolverService } from './team-collection-resolver.service';
import { TeamResolverService } from './team-resolver.service';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';

@NgModule({
  declarations: [
    TeamsListComponent,
    TeamEditComponent,
    TeamFormComponent,
    TeamStafferAssignmentComponent,
    TeamCreateDialogComponent,
  ],
  imports: [
    CommonModule,
    NgSharedModule,
    RouterModule,
    ReactiveFormsModule,
    NgFeatureFlagsModule,
    NgPrincipleSharedModule,
  ],
  providers: [TeamCollectionResolverService, TeamResolverService],
})
export class TeamsModule {}
