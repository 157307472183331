import { createAction, props } from '@ngrx/store';
import { ChartActionTypes } from './action-types';
import { type ITooth } from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData } from '@principle-theorem/shared';
import { type IEntityAction } from '@principle-theorem/ng-shared';
import { type ChartId } from '../reducers/active-charts/chart-context-state';

export interface IChartToothAdd extends IEntityAction<ChartId> {
  tooth: ITooth;
}

export const addTooth = createAction(
  ChartActionTypes.AddTooth,
  props<SerialisedData<IChartToothAdd>>()
);

export type IChartToothRemove = Pick<ITooth, 'toothRef'> &
  IEntityAction<ChartId>;

export const removeTooth = createAction(
  ChartActionTypes.RemoveTooth,
  props<SerialisedData<IChartToothRemove>>()
);

export type IChartToothRootsChange = Pick<ITooth, 'toothRef' | 'roots'> &
  IEntityAction<ChartId>;

export const updateToothRoots = createAction(
  ChartActionTypes.UpdateToothRoots,
  props<SerialisedData<IChartToothRootsChange>>()
);
