import { type Properties } from './base-mock-types';
import { BaseMock } from './base-mock';
import { type IFirestoreModel } from '../firebase/firestore/model';
import { MockTimestamp } from './mock-helpers';

export class BaseFirestoreMock
  extends BaseMock
  implements Properties<IFirestoreModel>
{
  createdAt = MockTimestamp();
  updatedAt = MockTimestamp();
  deleted = false;
}

export function MockBaseFirestore(): IFirestoreModel {
  return {
    createdAt: MockTimestamp(),
    updatedAt: MockTimestamp(),
    deleted: false,
  };
}
