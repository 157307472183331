<!-- <div class="layout-margin">
  <h2>Gross Income</h2>
  <ng-container *ngIf="incomeOverTimeChartBuilder">
    <pr-chart [chartBuilder]="incomeOverTimeChartBuilder"></pr-chart>
  </ng-container>
</div> -->

<!-- <div fxLayout="column" fxLayout.gt-sm="row">
  <div fxFlex="50" class="layout-margin" *ngIf="incomeVsExpensesChartBuilder">
    <h2>Income vs Expenses</h2>
    <pr-chart [chartBuilder]="incomeVsExpensesChartBuilder"></pr-chart>
  </div>

  <div fxFlex="50" class="layout-margin" *ngIf="incomeDistributionChartBuilder">
    <h2>Income Disribution</h2>
    <pr-chart [chartBuilder]="incomeDistributionChartBuilder"></pr-chart>
  </div>
</div> -->

<!-- <div fxLayout="column" fxLayout.gt-sm="row">
  <div fxFlex="50" class="layout-margin" *ngIf="hourlyRateChartBuilder">
    <h2>Hourly Rate</h2>
    <pr-chart [chartBuilder]="hourlyRateChartBuilder"></pr-chart>
  </div>

  <div fxFlex="50" class="layout-margin" *ngIf="potentialIncomeChartBuilder">
    <h2>Potential Income</h2>
    <pr-chart [chartBuilder]="potentialIncomeChartBuilder"></pr-chart>
  </div>
</div> -->

<div class="layout-margin" fxLayout="column">
  <!-- <pr-practitioner-income-table
    [dataBuilder]="dataBuilder"
  ></pr-practitioner-income-table> -->

  <!-- <pr-table-chart
    fxFlex
    [chart]="tableChartCard"
    [replacementHeaders]="replacementHeaders"
  ></pr-table-chart> -->
</div>
