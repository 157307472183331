import { AtLeast, Region } from '@principle-theorem/shared';
import {
  IServiceCode,
  ServiceCodeType,
  ItemCodeAliases,
  IServiceProvider,
} from '../service-item';
import { ChartableSurface } from '../../core/chartable-surface';
import { TaxStrategy } from '@principle-theorem/accounting';

// https://www.tewhatuora.govt.nz/assets/For-health-providers/Claims-provider-payments-and-entitlements/Dental/Combined-Dental-Agreement.pdf

export const BASE_MOH_SERVICE_CODES: Record<
  string,
  AtLeast<IServiceCode, 'code' | 'title' | 'fee'>
> = {
  FIL2: {
    code: 'FIL2',
    title: 'Two surface (approximal-occlusal) restorations in posterior teeth',
    fee: 119.31,
  },
  FIL3: {
    code: 'FIL3',
    title:
      'Three surface (mesio-occlusal-distal) restorations in posterior teeth',
    fee: 146.84,
  },
  FIL4: {
    code: 'FIL4',
    title: 'Complex coronal reconstructions in amalgam',
    notes: 'Including restoration of one or more cusps',
    fee: 164.82,
  },
  FIL5: {
    code: 'FIL5',
    title: 'Simple restorations in anterior teeth',
    fee: 108.15,
  },
  FIL6: {
    code: 'FIL6',
    title: 'More than one surface restoration in anterior teeth',
    fee: 145.43,
  },
  CRN1: {
    code: 'CRN1',
    title: 'Performed metal crowns',
    fee: 93.51,
  },
  EXT1: {
    code: 'EXT1',
    title: 'Extractions',
    notes: 'Excluding extractions for orthodontic purposes',
    fee: 176.84,
  },
  RCM1: {
    code: 'RCM1',
    title: 'Re-cement inlay or crown',
    fee: 33.48,
  },
  RAD2: {
    code: 'RAD2',
    title: 'Panoramic radiographs',
    fee: 55.37,
  },
  RAD3: {
    code: 'RAD3',
    title: 'Occlusal radiographs',
    fee: 37.26,
  },
  RCT1: {
    code: 'RCT1',
    title: 'Root canal treatment and root filling',
    description: `Root canal treatment and root filling in permanent anterior or premolar teeth (per canal) including all necessary radiographs performed during treatment and mandatory post-operative radiology for patient's record`,
    notes: 'Per canal',
    fee: 369.4,
  },
  RCT2: {
    code: 'RCT2',
    title: 'Pulp removal and root filling in deciduous tooth',
    description: 'Maximum fees per deciduous tooth treated',
    fee: 202.65,
  },
  RCT3: {
    code: 'RCT3',
    title: 'Pulpotomy in deciduous teeth',
    fee: 129.97,
  },
  RCT4: {
    code: 'RCT4',
    title: 'Pulpotomy in permanent teeth',
    fee: 129.97,
  },
  EMD1: {
    code: 'EMD1',
    title: 'Emergency dressings',
    fee: 38.31,
  },
  CON3: {
    code: 'CON3',
    title: 'Initial oral consultation',
    description: `For school dental clinic patients referred for Special Dental Services or for school dental clinic patients or adolescents who are not able to access their regular health provider in an emergency during normal practice hours`,
    fee: 99.16,
  },
  CON4: {
    code: 'CON4',
    title: 'Emergency consultations outside normal hours',
    fee: 146.44,
  },
  MSO1: {
    code: 'MSO1',
    title: 'Minor surgical operation or other time-based procedures',
    notes: 'First half hour',
    fee: 114.97,
  },
  MSO2: {
    code: 'MSO2',
    title: 'Minor surgical operation or other time-based procedures',
    notes: 'Each additional quarter hour',
    fee: 57.45,
  },
  PDT1: {
    code: 'PDT1',
    title: 'Treatment of periodontal disease',
    fee: 90.31,
  },
  DENT1: {
    code: 'DENT1',
    title: 'Precision-cast metal partial denture',
    fee: 2074.8,
  },
  DENT2: {
    code: 'DENT2',
    title: 'Precision-cast metal partial denture',
    notes: 'Each extra tooth',
    fee: 69.86,
  },
  DENT3: {
    code: 'DENT3',
    title: 'Acrylic partial dentures',
    fee: 901.93,
  },
  DENT4: {
    code: 'DENT4',
    title: 'Acrylic partial dentures',
    notes: 'Each extra tooth',
    fee: 69.94,
  },
  DENT5: {
    code: 'DENT5',
    title: 'Acrylic partial dentures',
    notes: 'Each clasp',
    fee: 36.33,
  },
  ABMT: {
    code: 'ABMT',
    title: 'Adessive Bridges (Maryland Type)',
    fee: 1392.29,
  },
  CRN2: {
    code: 'CRN2',
    title: 'Ceramic to metal crowns',
    fee: 1206.75,
  },
  CRN3: {
    code: 'CRN3',
    title: 'All ceramic crowns',
    notes: 'Partial or full coverage, bonded or cemented',
    fee: 1203.65,
  },
  CRN4: {
    code: 'CRN4',
    title: 'Gold Crown',
    notes: 'Partial or full coverage',
    fee: 1144.15,
  },
  CRN5: {
    code: 'CRN5',
    title: 'Full coverage composite crowns',
    fee: 238.15,
  },
  SPLT: {
    code: 'SPLT',
    title: 'Bite Splints',
    fee: 605.24,
  },
  PST1: {
    code: 'PST1',
    title: 'Cast posts and cores',
    fee: 322.76,
  },
  PST2: {
    code: 'PST2',
    title: 'Preformed posts',
    notes: 'Para, flexi, etc',
    fee: 134,
  },
  VEN1: {
    code: 'VEN1',
    title: 'Porcelain veneer',
    fee: 1037.33,
  },
  VEN2: {
    code: 'VEN2',
    title: 'Labial composite veneer',
    fee: 160.16,
  },
  DEN6: {
    code: 'DEN6',
    title: 'Denture full upper or lower',
    fee: 1278.48,
  },
  DEN7: {
    code: 'DEN7',
    title: 'Denture upper and lower',
    fee: 2826.13,
  },
  APX1: {
    code: 'APX1',
    title: 'Apexification/root filling with an open apex',
    fee: 202.65,
  },
  RCT5: {
    code: 'RCT5',
    title: 'Root Canal treatment and root fillings in permanent molar teeth',
    description: `Per canal treated including all necessary radiographs performed during treatment and a mandatory post-operative radiograph for the patient's record`,
    fee: 369.4,
  },
  CON5: {
    code: 'CON5',
    title: 'Consultation',
    description:
      'Including examination and diagnosis, prophylaxis, advice on dental care and any special tests and bitewing radiographs considered necessary',
    fee: 90.23,
  },
  FIL1: {
    code: 'FIL1',
    title: 'One surface posterior fillings',
    notes: 'Molar and pre-molar',
    fee: 91,
  },
  RAD1: {
    code: 'RAD1',
    title: 'Periapical X-ray',
    fee: 14.24,
  },
  FIS1: {
    code: 'FIS1',
    title: 'Fissure sealant',
    fee: 31.52,
  },
  PBW1: {
    code: 'PBW1',
    title: 'Bitewing radiograph',
    fee: 14.24,
  },
};

export const MOH_CODE_MAP: ItemCodeAliases = Object.entries(
  BASE_MOH_SERVICE_CODES
).reduce(
  (codes, [key, code]) => ({
    ...codes,
    [key]: {
      scope: [ChartableSurface.Unscoped],
      rules: {},
      deleted: false,
      taxStatus: TaxStrategy.GSTApplicable,
      ...code,
      type: ServiceCodeType.MOH,
    },
  }),
  {}
);

export const MOH_SERVICE_CODES: IServiceCode[] = Object.values(MOH_CODE_MAP);

export const MOH_SERVICE: IServiceProvider = {
  label: 'MOH',
  description: 'New Zealand Ministry Of Health Codes',
  type: ServiceCodeType.MOH,
  items: MOH_CODE_MAP,
  region: Region.NewZealand,
};
