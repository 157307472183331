import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ReportingCoreModule } from '../core/reporting-core.module';
import { ReportingComponentsModule } from '../reporting-components/reporting-components.module';
import { PractitionerCollectionsComponent } from './practitioner-collections/practitioner-collections.component';
import { PractitionerIncomeComponent } from './practitioner-income/practitioner-income.component';
import { PractitionerPatientsComponent } from './practitioner-patients/practitioner-patients.component';
import { PractitionerProductivityComponent } from './practitioner-productivity/practitioner-productivity.component';
import { PractitionerReportingComponent } from './practitioner-reporting.component';
import { PractitionerTreatmentsComponent } from './practitioner-treatments/practitioner-treatments.component';

@NgModule({
  imports: [
    RouterModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    ReportingComponentsModule,
    ReportingCoreModule,
  ],
  declarations: [
    PractitionerReportingComponent,
    PractitionerIncomeComponent,
    PractitionerProductivityComponent,
    PractitionerPatientsComponent,
    PractitionerTreatmentsComponent,
    PractitionerCollectionsComponent,
  ],
  providers: [],
})
export class PractitionerReportingModule {}
