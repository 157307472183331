<ng-container *ngIf="lineItem$ | async as lineItem">
  <div class="header flex items-center gap-4" *ngIf="showItem$ | async">
    <div class="flex flex-col" fxFlex>
      <div class="flex items-center gap-2">
        <span>{{ lineItem.description }}</span>
        <div>
          <ng-content />
        </div>
        <div
          class="mat-caption tooltip flex"
          *ngIf="infoTooltip$ | async as infoTooltip"
        >
          <mat-icon [matTooltip]="infoTooltip">info_outline</mat-icon>
        </div>
      </div>

      @if (lineItem | map: isDeposit) {
        <div>
          <span class="text-xs opacity-50">
            @if (lineItem | map: resolveProvider; as provider) {
              Deposit for <pr-treatment-provider [name]="provider?.name" />
            } @else {
              Deposit Unassigned
            }
          </span>

          @if (
            lineItem | map: resolveTreatmentCategory$ : this | async;
            as treatmentCategory
          ) {
            <div
              [matTooltip]="treatmentCategory.name"
              class="inline-block h-3 w-3 rounded-full"
              [style.backgroundColor]="treatmentCategory.colour.value"
            ></div>
          }
        </div>
      }

      <div
        class="mat-caption flex gap-1"
        *ngIf="lineItem | map: isCreditRefund"
      >
        <span>Credit Refund</span>
      </div>
    </div>

    <ng-container *ngIf="showColumns$ | async">
      <div class="quantity">
        <span class="quantity-inset" *ngIf="showQuantity$ | async">
          {{ lineItem.quantity }}
        </span>
      </div>
      <div class="amount">
        <span class="amount-inset" *ngIf="showAmounts$ | async">
          {{ lineItem.tax | currency }}
        </span>
      </div>
      <div class="amount">
        <span class="amount-inset" *ngIf="showAmounts$ | async">
          {{ lineItem.amount | currency }}
        </span>
      </div>
    </ng-container>
  </div>
</ng-container>
