<ng-container *ngIf="weekDayHeader$ | async as headerData">
  <div
    [ngClass]="{
      'day-today': headerData.isToday,
      'day-past': headerData.isPast
    }"
    fxLayout="column"
  >
    <div class="week-day-of-week">
      {{ headerData.dayOfWeek }}
    </div>

    <a
      class="week-date"
      [routerLink]="[]"
      [queryParams]="headerData.queryParams"
      queryParamsHandling="merge"
    >
      {{ headerData.dayOfMonth }}
    </a>

    <ng-container *ngIf="events$ | async as events">
      <div
        class="calendar-events-wrapper"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="3px"
      >
        <pr-calendar-event
          *ngFor="let event of events; let i = index; trackBy: trackByEvent"
          [index]="i"
          [event]="event"
          [day]="headerData.day"
          fxFlex
         />
      </div>
    </ng-container>
  </div>
</ng-container>
