import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HicapsConnectService } from '@principle-theorem/ng-payments';
import { ObservableDataSource } from '@principle-theorem/ng-shared';
import { Practice } from '@principle-theorem/principle-core';
import {
  IHicapsConnectTerminal,
  IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { doc$, HISTORY_DATE_FORMAT, WithRef } from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-practice-hicaps-connect-terminals',
  templateUrl: './practice-hicaps-connect-terminals.component.html',
  styleUrls: ['./practice-hicaps-connect-terminals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PracticeHicapsConnectTerminalsComponent {
  readonly dateFormat = HISTORY_DATE_FORMAT;
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);
  terminals$: Observable<WithRef<IHicapsConnectTerminal>[]>;
  dataSource: ObservableDataSource<WithRef<IHicapsConnectTerminal>>;
  displayedColumns: string[] = [
    'name',
    'terminalId',
    'bridgeDevice',
    'lastActive',
    'actions',
  ];

  @Input()
  set practice(practice: WithRef<IPractice>) {
    if (practice) {
      this.practice$.next(practice);
    }
  }

  constructor(public hicapsConnect: HicapsConnectService) {
    this.terminals$ = this.practice$.pipe(
      switchMap((practice) => Practice.hicapsConnectTerminals$(practice))
    );
    this.dataSource = new ObservableDataSource(this.terminals$);
  }

  bridgeDeviceName$(
    terminal: WithRef<IHicapsConnectTerminal>
  ): Observable<string> {
    return doc$(terminal.bridgeDevice).pipe(
      map((bridgeDevice) => bridgeDevice.name)
    );
  }
}
