<mat-toolbar color="accent">Add Sterilisation Record</mat-toolbar>

<mat-dialog-content>
  <form fxLayout="column" [formGroup]="form" (ngSubmit)="save()">
    <div formArrayName="data" fxLayout="column" fxLayoutGap="16px">
      <mat-form-field
        *ngFor="
          let control of records.controls;
          trackBy: trackByIndex;
          index as index;
          first as isFirst;
          last as isLast
        "
      >
        <mat-label>Sterilisation Record ID</mat-label>
        <input #dataInput matInput [formControl]="control" />
        <button
          *ngIf="!isFirst"
          type="button"
          mat-icon-button
          matIconSuffix
          (click)="records.removeAt(index)"
        >
          <mat-icon>remove</mat-icon>
        </button>
        <button
          *ngIf="isLast"
          type="button"
          mat-icon-button
          matIconSuffix
          (click)="addRecord()"
        >
          <mat-icon>add</mat-icon>
        </button>
        <mat-hint *ngIf="isLast" align="start">
          Click this field before using barcode scanner or manually type in the
          ID
        </mat-hint>
      </mat-form-field>
    </div>

    <pr-patient-selector
      fxFlex
      [formControl]="searchCtrl"
     />
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="disabled$ | async"
      (click)="save()"
    >
      Add
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
