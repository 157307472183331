import { IBaseMigrationItemCode } from '../../../interfaces';
import { BaseItemCodeToTreatmentMappingHandler } from '../../../mappings/base-item-code-to-treatment';
import {
  ADAItemSourceEntity,
  IExactADAItem,
} from '../../source/entities/ada-item';
import { UsedItemCodeTreatmentSourceEntity } from '../../source/entities/used-item-code-treatments';

export class ExactItemCodeToTreatmentMappingHandler extends BaseItemCodeToTreatmentMappingHandler<
  IExactADAItem,
  ADAItemSourceEntity
> {
  sourceEntity = new UsedItemCodeTreatmentSourceEntity();
  translateFn = (record: IExactADAItem): IBaseMigrationItemCode => ({
    ...record,
  });
}
