<mat-toolbar color="accent">Update Health Cards</mat-toolbar>
<div class="flex flex-col gap-4 p-4">
  @if (data.medicareCard; as new) {
    <div class="medicare">
      <h3 class="!m-0 !font-bold">
        Do you want to update the patient's Medicare Card?
      </h3>
      <div class="flex flex-row gap-4">
        @if (data.patient.medicareCard; as old) {
          <pr-health-fund-card-preview>
            <div class="title font-bold uppercase">Current</div>
            <pr-health-fund-card-preview-item label="Membership Number">
              {{ old.number }}
            </pr-health-fund-card-preview-item>
            <pr-health-fund-card-preview-item label="Card Number">
              {{ old.subNumerate }}
            </pr-health-fund-card-preview-item>
            <pr-health-fund-card-preview-item label="Expiry Date">
              @if (old.expiryDate) {
                {{ old.expiryDate | moment | amDateFormat: dateFormat }}
              } @else {
                None
              }
            </pr-health-fund-card-preview-item>
          </pr-health-fund-card-preview>
        } @else {
          <ng-container *ngTemplateOutlet="noCardOnFile" />
        }

        <pr-health-fund-card-preview>
          <div class="title font-bold uppercase">New</div>
          <pr-health-fund-card-preview-item label="Membership Number">
            {{ new.number }}
          </pr-health-fund-card-preview-item>
          <pr-health-fund-card-preview-item label="Card Number">
            {{ new.subNumerate }}
          </pr-health-fund-card-preview-item>
          <pr-health-fund-card-preview-item label="Expiry Date">
            @if (new.expiryDate) {
              {{ new.expiryDate | moment | amDateFormat: dateFormat }}
            } @else {
              None
            }
          </pr-health-fund-card-preview-item>
        </pr-health-fund-card-preview>
      </div>
    </div>
  }

  @if (data.healthFundCard; as new) {
    <div class="healthfund">
      <h3 class="!m-0 !font-bold">
        Do you want to update the patient's Health Fund Card?
      </h3>
      <div class="flex flex-row gap-4">
        @if (data.patient.healthFundCard; as old) {
          <pr-health-fund-card-preview>
            <div class="title font-bold uppercase">Current</div>
            <pr-health-fund-card-preview-item label="Health Fund Name">
              {{ old.fundCode ? old.fundCode : 'None' }}
            </pr-health-fund-card-preview-item>
            <pr-health-fund-card-preview-item />
            <pr-health-fund-card-preview-item label="Membership Number">
              {{ old.membershipNumber }}
            </pr-health-fund-card-preview-item>
            <pr-health-fund-card-preview-item label="Member Number">
              {{ old.memberNumber }}
            </pr-health-fund-card-preview-item>
          </pr-health-fund-card-preview>
        } @else {
          <ng-container *ngTemplateOutlet="noCardOnFile" />
        }

        <pr-health-fund-card-preview>
          <div class="title font-bold uppercase">New</div>
          <pr-health-fund-card-preview-item label="Health Fund Name">
            {{ new.fundCode ? new.fundCode : 'None' }}
          </pr-health-fund-card-preview-item>
          <pr-health-fund-card-preview-item />
          <pr-health-fund-card-preview-item label="Membership Number">
            {{ new.membershipNumber }}
          </pr-health-fund-card-preview-item>
          <pr-health-fund-card-preview-item label="Member Number">
            {{ new.memberNumber }}
          </pr-health-fund-card-preview-item>
        </pr-health-fund-card-preview>
      </div>
    </div>
  }

  @if (data.dvaCard; as new) {
    <div class="dva">
      <h3 class="!m-0 !font-bold">
        Do you want to update the patient's DVA Card?
      </h3>
      <div class="flex flex-row gap-4">
        @if (data.patient.dvaCard; as old) {
          <pr-health-fund-card-preview>
            <div class="title font-bold uppercase">Current</div>
            <pr-health-fund-card-preview-item label="Membership Number">
              {{ old.number }}
            </pr-health-fund-card-preview-item>
            <pr-health-fund-card-preview-item />
            <pr-health-fund-card-preview-item label="Expiry Date">
              @if (old.expiryDate) {
                {{ old.expiryDate | moment | amDateFormat: dateFormat }}
              } @else {
                None
              }
            </pr-health-fund-card-preview-item>
          </pr-health-fund-card-preview>
        } @else {
          <ng-container *ngTemplateOutlet="noCardOnFile" />
        }

        <pr-health-fund-card-preview>
          <div class="title font-bold uppercase">New</div>
          <pr-health-fund-card-preview-item label="Membership Number">
            {{ new.number }}
          </pr-health-fund-card-preview-item>
          <pr-health-fund-card-preview-item />
          <pr-health-fund-card-preview-item label="Expiry Date">
            @if (new.expiryDate) {
              {{ new.expiryDate | moment | amDateFormat: dateFormat }}
            } @else {
              None
            }
          </pr-health-fund-card-preview-item>
        </pr-health-fund-card-preview>
      </div>
    </div>
  }
  <pt-buttons-container>
    <button mat-stroked-button mat-dialog-close>Skip</button>
    <button mat-flat-button color="primary" (click)="updateAll()">
      Update
    </button>
  </pt-buttons-container>
</div>

<ng-template #noCardOnFile>
  <pr-health-fund-card-preview>
    <div class="title font-bold uppercase">Current</div>
    <pr-health-fund-card-preview-item>
      No card on file
    </pr-health-fund-card-preview-item>
  </pr-health-fund-card-preview>
</ng-template>
