import { NgModule } from '@angular/core';
import { type Routes, RouterModule } from '@angular/router';
import { AuthPageComponent } from './auth-page/auth-page.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { UserActivateComponent } from './user-activate/user-activate.component';
import { AccountActionsComponent } from './account-actions/account-actions.component';
import {
  IsNotAuthenticatedGuard,
  IsAuthenticatedGuard,
} from '@principle-theorem/ng-auth';
import { WorkspacesComponent } from './workspaces/workspaces.component';
import { AuthPagesModule } from './auth-pages.module';
import { IsNotAuthorisedGuard } from '@principle-theorem/ng-principle-shared';
import { RegisterPageComponent } from './register-page/register-page.component';
import { UserRestrictedComponent } from './user-restricted/user-restricted.component';

const routes: Routes = [
  {
    path: 'login',
    data: { title: 'Login' },
    component: AuthPageComponent,
  },
  {
    path: 'register',
    data: { title: 'Register' },
    component: RegisterPageComponent,
  },
  {
    path: 'workspaces',
    canActivate: [IsAuthenticatedGuard],
    data: { title: 'Select a Workspace' },
    component: WorkspacesComponent,
  },
  {
    path: 'sign-up',
    data: { title: 'Sign Up' },
    canActivate: [IsNotAuthenticatedGuard],
    component: SignUpComponent,
  },
  {
    path: 'activate',
    data: { title: 'Activate' },
    canActivate: [IsNotAuthenticatedGuard],
    component: UserActivateComponent,
  },
  {
    path: 'unauthorised',
    canActivate: [IsAuthenticatedGuard, IsNotAuthorisedGuard],
    component: UnauthorisedComponent,
  },
  {
    path: 'user-restricted',
    canActivate: [IsAuthenticatedGuard],
    component: UserRestrictedComponent,
  },
  {
    path: 'account-action',
    component: AccountActionsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), AuthPagesModule],
  exports: [RouterModule],
})
export class AuthPagesRoutingModule {}
