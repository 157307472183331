import {
  IFeeSchedule,
  IFeeScheduleGroup,
  IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  CollectionReference,
  DocumentReference,
  Firestore,
  WithRef,
  doc,
  doc$,
  getDoc,
} from '@principle-theorem/shared';
import { first, last } from 'lodash';
import { Observable, of } from 'rxjs';

export const DEFAULT_SCHEDULE_ID = 'default';

export function defaultFeeSchedule$(
  collection: CollectionReference<IFeeSchedule>
): Observable<WithRef<IFeeSchedule>> {
  return doc$(doc(collection, DEFAULT_SCHEDULE_ID));
}

export async function safeDefaultFeeSchedule(
  collectionRef: CollectionReference<IFeeSchedule>
): Promise<WithRef<IFeeSchedule> | undefined> {
  const rawDocRef = doc<IFeeSchedule>(collectionRef, DEFAULT_SCHEDULE_ID);
  return Firestore.safeGetDoc(rawDocRef);
}

export function getInheritedSchedule$(
  feeSchedule: IFeeSchedule
): Observable<WithRef<IFeeSchedule> | undefined> {
  if (!feeSchedule.inheritedFrom) {
    return of(undefined);
  }
  return doc$<IFeeSchedule>(feeSchedule.inheritedFrom);
}

export async function getUndeletedScheduleFromInheritance(
  initialRef?: DocumentReference<IFeeSchedule>
): Promise<WithRef<IFeeSchedule> | undefined> {
  if (!initialRef) {
    return;
  }
  const initial = await getDoc(initialRef);
  if (!initial.deleted) {
    return initial;
  }
  if (initial.inheritedFrom) {
    return getUndeletedScheduleFromInheritance(initial.inheritedFrom);
  }
}

export async function getDefaultFeeSchedule(
  feeScheduleGroups: IFeeScheduleGroup[],
  patient?: WithRef<IPatient>
): Promise<WithRef<IFeeSchedule> | undefined> {
  const patientPreferred = await getUndeletedScheduleFromInheritance(
    patient?.preferredFeeSchedule?.ref
  );
  if (patientPreferred) {
    return patientPreferred;
  }
  const lastGroup = last(feeScheduleGroups);
  if (!lastGroup) {
    return;
  }
  const lastGroupDefault = lastGroup.schedules.find(
    (schedule) => schedule.ref.id === DEFAULT_SCHEDULE_ID
  );
  const lastGroupFallback = first(lastGroup?.schedules);
  return lastGroupDefault || lastGroupFallback;
}
