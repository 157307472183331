<mat-toolbar color="accent">Edit Condition</mat-toolbar>

<mat-dialog-content>
  <div class="flex flex-col gap-6">
    <pr-general-condition-configuration
      [conditionType]="data.condition"
      (updateCondition)="setCurrentCondition($event)"
     />
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-flat-button color="primary" (click)="save()">Save</button>
  </pt-buttons-container>
</mat-dialog-actions>
