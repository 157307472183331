import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  CurrentPatientScope,
  OrganisationService,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import {
  IPatientForm,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  shareReplayCold,
  snapshot,
  snapshotDefined,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { PatientCustomFormsService } from '../../patient-custom-forms.service';

@Component({
  selector: 'pr-forms-dashboard',
  templateUrl: './forms-dashboard.component.html',
  styleUrls: ['./forms-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FormsDashboardComponent {
  patient$: Observable<WithRef<IPatient>>;

  constructor(
    public customForms: PatientCustomFormsService,
    private _stateNav: StateBasedNavigationService,
    patientScope: CurrentPatientScope,
    private _organisation: OrganisationService
  ) {
    this.patient$ = patientScope.doc$.pipe(
      filterUndefined(),
      shareReplayCold()
    );
  }

  async openForm(form: WithRef<IPatientForm>): Promise<void> {
    const patient = await snapshot(this.patient$);
    await this._stateNav.brand([
      'patients',
      patient.ref.id,
      'forms',
      form.ref.id,
    ]);
  }

  async issueForm(patient: WithRef<IPatient>): Promise<void> {
    const staffer = await snapshotDefined(this._organisation.staffer$);
    await this.customForms.issueForm(patient, staffer, true);
  }
}
