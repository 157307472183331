import { type IXSLXImport } from '@principle-theorem/shared';
import type { Row } from 'exceljs';
import {
  APPOINTMENT_BOOK_TO_PRACTITIONER_HEADERS,
  type IAppointmentBookToPractitionerXSLX,
} from './appointment-book-to-practitioner-to-xlsx';

export class XSLXToAppointmentBookToPractitioner
  implements IXSLXImport<IAppointmentBookToPractitionerXSLX>
{
  headers = APPOINTMENT_BOOK_TO_PRACTITIONER_HEADERS;

  translate(row: Row): IAppointmentBookToPractitionerXSLX {
    return {
      id: row.getCell('id').value?.toString() ?? '',
      name: row.getCell('name').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IAppointmentBookToPractitionerXSLX['mapTo'],
    };
  }
}
