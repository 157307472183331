import { Component, Input, type OnInit } from '@angular/core';
import {
  type IInteractiveResource,
  type IPrincipleMention,
} from '@principle-theorem/principle-core/interfaces';
import { MENTION_TRIGGER } from '@principle-theorem/editor';
import { MentionActionResolverService } from '../mention-action-resolver.service';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type IContextualAction } from '@principle-theorem/ng-principle-shared';

@Component({
  selector: 'pr-mention-resource',
  templateUrl: './mention-resource.component.html',
  styleUrls: ['./mention-resource.component.scss'],
})
export class MentionResourceComponent implements OnInit {
  trackByAction = TrackByFunctions.field<IContextualAction>('name');
  @Input() resource: IPrincipleMention;
  @Input() interactiveResource: IInteractiveResource;
  actions: IContextualAction[] = [];
  mentionTrigger: string = MENTION_TRIGGER;

  constructor(private _mentionActionResolver: MentionActionResolverService) {}

  ngOnInit(): void {
    this._resolveMentionActions();
  }

  runAction(action: IContextualAction): void {
    action.do(this.resource.resource, this.interactiveResource);
  }

  /**
   * Injects the services for a mention's required actions
   *
   * @private
   * @memberof MentionResourceComponent
   */
  private _resolveMentionActions(): void {
    this.actions = this._mentionActionResolver.resolveActions(
      this.resource.resource.type
    );
  }
}
