import {
  InlineNodes,
  labelPlugin,
  type ExtensionRegisterReturnFn,
  toTextContent,
} from '@principle-theorem/editor';
import { Editor } from '@tiptap/core';
import { keymap } from '@tiptap/pm/keymap';
import { type Plugin } from '@tiptap/pm/state';
import { v4 as uuid } from 'uuid';

export const MENTION_KEYMAP = '@';

export function createMentionKeymap(): ExtensionRegisterReturnFn<Plugin> {
  return (editor: Editor) =>
    labelPlugin(
      `${InlineNodes.Mention}-keymap`,
      keymap({
        [MENTION_KEYMAP]: () => createMention(editor),
      })
    );
}

export function createMention(editor: Editor): boolean {
  if (editor.isActive(InlineNodes.MentionAutocomplete)) {
    return false;
  }

  return editor
    .chain()
    .focus()
    .insertContent({
      type: InlineNodes.MentionAutocomplete,
      attrs: {
        uid: uuid(),
      },
      content: [toTextContent(MENTION_KEYMAP)],
    })
    .run();
}
