<div class="flex flex-wrap items-center justify-between gap-4">
  <div class="flex flex-wrap items-center gap-4">
    <div class="items center flex gap-2">
      <mat-form-field class="!min-w-[315px]" subscriptSizing="dynamic">
        <mat-label>{{ searchInputLabel$ | async }}</mat-label>
        <input matInput [formControl]="searchInput" />
      </mat-form-field>
    </div>

    <pt-date-range-form
      [useRangePicker]="true"
      (changed)="updateDates($event)"
    />

    @if (recordsTabActive$ | async) {
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Filter Records</mat-label>
        <mat-select panelWidth="" multiple>
          <mat-option (onSelectionChange)="store.toggleHideWithCycle()"
            >Hide Allocated To Cycle</mat-option
          >
          <mat-option (onSelectionChange)="store.toggleHideAllocated()"
            >Hide Allocated To Patient/Appointment</mat-option
          >
        </mat-select>
      </mat-form-field>
    }
  </div>

  <ng-content />
</div>
