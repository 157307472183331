import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { IClaimEstimateSummary } from '../../claim-estimate/claim-estimate-provider';
import { ClaimEstimateSummaryDialogComponent } from './claim-estimate-summary-dialog.component';

@Injectable()
export class ClaimEstimateSummaryDialogService {
  constructor(private _dialog: MatDialog) {}

  async open(data: IClaimEstimateSummary): Promise<void> {
    return this._dialog
      .open<ClaimEstimateSummaryDialogComponent, IClaimEstimateSummary, void>(
        ClaimEstimateSummaryDialogComponent,
        DialogPresets.medium({ data })
      )
      .afterClosed()
      .toPromise();
  }
}
