import { getSchemaText } from '@principle-theorem/editor';
import {
  IAppointment,
  ICalendarEvent,
  IEventable,
  IPractice,
  IScheduleSummaryEvent,
  IScheduleSummaryEventable,
  isAppointment,
  isCalendarEvent,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  WithRef,
  asDocRef,
  isWithRef,
  sortTimestampAsc,
  toTimePeriod,
  undeletedQuery,
  where,
} from '@principle-theorem/shared';
import { Appointment } from '../appointment/appointment';
import { TreatmentStep } from '../clinical-charting/treatment/treatment-step';
import { CalendarEventTemplate } from '../event/calendar-event-template';

export class ScheduleSummary {
  static async toSummaryEvent<T extends object>(
    ref: DocumentReference<T>,
    eventable?: IEventable<T>,
    isBlocking: boolean = true
  ): Promise<IScheduleSummaryEvent<T> | undefined> {
    if (!eventable?.event) {
      return;
    }

    const label = await getEventLabel(eventable);
    const pinnedNotes = await getPinnedNotes(eventable);

    if (isAppointment(eventable) && isWithRef<IAppointment>(eventable)) {
      return {
        ref,
        event: eventable.event,
        isBlocking,
        metadata: {
          label,
          pinnedNotes,
          tags: eventable.tags,
          patientRef: Appointment.patientRef(eventable),
          status: eventable.status,
          dependencies: eventable.dependencies,
          categoryRef: TreatmentStep.defaultDisplayRef(
            eventable.treatmentPlan.treatmentStep.display
          ),
          treatmentPlanName: eventable.treatmentPlan.name,
          treatmentStepName: eventable.treatmentPlan.treatmentStep.name,
        },
      };
    }

    if (isCalendarEvent(eventable)) {
      return {
        ref,
        event: eventable.event,
        isBlocking,
        metadata: {
          label,
          tags: eventable.eventTags,
          pinnedNotes,
          scheduleRef: eventable.scheduleRef,
        },
      } as IScheduleSummaryEvent<T>;
    }
  }

  static async getCalendarEvent(
    event: IScheduleSummaryEventable,
    practice: WithRef<IPractice>
  ): Promise<WithRef<ICalendarEvent> | ICalendarEvent | undefined> {
    if (event.ref) {
      return Firestore.getDoc(asDocRef<ICalendarEvent>(event.ref));
    }
    const { from, to } = event.event;
    const timezone = practice.settings.timezone;
    const timePeriod = toTimePeriod(from, to, timezone);
    const scheduleRef = event.metadata.scheduleRef;
    const scheduleEvent = scheduleRef
      ? await Firestore.getDoc(scheduleRef)
      : undefined;
    return scheduleEvent
      ? CalendarEventTemplate.toCalendarEvent(
          scheduleEvent.item,
          timePeriod,
          scheduleRef
        )
      : undefined;
  }

  static async getSummaryFromEventable<T extends object>(
    eventable?: IEventable<T>,
    isBlocking = true
  ): Promise<IScheduleSummaryEventable<T> | undefined> {
    if (!eventable || !eventable.ref || !eventable.event) {
      return;
    }

    const summaryEvent = await ScheduleSummary.toSummaryEvent<T>(
      eventable.ref,
      eventable,
      isBlocking
    );

    if (!summaryEvent) {
      return;
    }

    return {
      ...summaryEvent,
      uid: summaryEvent.ref.id,
    };
  }
}

async function getEventLabel(eventable: IEventable): Promise<string> {
  if (isCalendarEvent(eventable)) {
    return getSchemaText(eventable.title);
  }
  if (!isWithRef<IAppointment>(eventable) || !isAppointment(eventable)) {
    return '';
  }
  return (await Appointment.patient(eventable)).name;
}

async function getPinnedNotes(eventable: IEventable): Promise<string[]> {
  if (isAppointment(eventable) && isWithRef<IAppointment>(eventable)) {
    const interactions = await Firestore.getDocs(
      undeletedQuery(Appointment.interactionCol(eventable)),
      where('pinned', '==', true)
    );

    interactions.sort((interactionA, interactionB) =>
      sortTimestampAsc(interactionA.createdAt, interactionB.createdAt)
    );
    return interactions.map((interaction) =>
      getSchemaText(interaction.content)
    );
  }
  if (!isWithRef(eventable)) {
    return [];
  }
  if (isCalendarEvent(eventable)) {
    return [getSchemaText(eventable.notes)];
  }

  return [];
}
