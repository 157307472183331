import { NgModule } from '@angular/core';
import { TagListComponent } from './tag-list/tag-list.component';
import { TagListItemComponent } from './tag-list/tag-list-item/tag-list-item.component';
import { EditTagDialogComponent } from './edit-tag-dialog/edit-tag-dialog.component';
import {
  ColourPickerModule,
  NgSharedModule,
  SearchFieldModule,
} from '@principle-theorem/ng-shared';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';

@NgModule({
  imports: [
    NgSharedModule,
    NgPrincipleSharedModule,
    SearchFieldModule,
    ColourPickerModule,
  ],
  declarations: [
    TagListComponent,
    TagListItemComponent,
    EditTagDialogComponent,
  ],
  exports: [TagListComponent],
})
export class TagsModule {}
