import { ActivatedRoute, type Params } from '@angular/router';
import { filterUndefined, guardFilter } from '@principle-theorem/shared';
import { get, isString } from 'lodash';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function queryParam$(
  route: ActivatedRoute,
  key: string
): Observable<string> {
  return route.queryParams.pipe(
    map((params: Params) => String(get(params, key, ''))),
    guardFilter(isString),
    filterUndefined()
  );
}

export function searchQueryParam$(route: ActivatedRoute): Observable<string> {
  return queryParam$(route, 'search');
}
