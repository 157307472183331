import { BehaviorSubject, type Observable } from 'rxjs';
import {
  type ISidebarState,
  DesktopExpandedState,
  MobileClosedState,
  MobileOpenState,
} from './sidebar-states';

export class SidebarBloc {
  private _state$: BehaviorSubject<ISidebarState> = new BehaviorSubject(
    new DesktopExpandedState()
  );

  get state$(): Observable<ISidebarState> {
    return this._state$.asObservable();
  }

  desktopActivate(): void {
    this._state$.next(new DesktopExpandedState());
  }

  mobileActivate(): void {
    this._state$.next(new MobileClosedState());
  }

  canMobileToggle(): boolean {
    return (
      this._state$.value instanceof MobileClosedState ||
      this._state$.value instanceof MobileOpenState
    );
  }

  mobileToggle(): void {
    if (this._state$.value instanceof MobileClosedState) {
      return this._state$.next(new MobileOpenState());
    }
    if (this._state$.value instanceof MobileOpenState) {
      return this._state$.next(new MobileClosedState());
    }
  }
}
