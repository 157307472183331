import { Injectable } from '@angular/core';
import { type Data, Router } from '@angular/router';
import { BaseCurrentFirestoreModel } from '@principle-theorem/ng-principle-shared';
import { type IStaffer } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';

@Injectable({
  providedIn: 'root',
  useFactory: (route: Router) => new CurrentStafferService(route),
  deps: [Router],
})
export class CurrentStafferService extends BaseCurrentFirestoreModel<
  WithRef<IStaffer>
> {
  protected _propertyAccessor(data: Data): WithRef<IStaffer> | undefined {
    return data.staffer as WithRef<IStaffer>;
  }
}
