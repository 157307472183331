import { type IContextualAction } from '@principle-theorem/ng-principle-shared';
import {
  type IContact,
  type IInteractiveResource,
  type IPrincipleMention,
} from '@principle-theorem/principle-core/interfaces';

export interface IInteractionAction extends IContextualAction {
  do(
    contact: IContact | IPrincipleMention,
    interactiveResource?: IInteractiveResource
  ): void;
}
