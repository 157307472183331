import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import {
  PATIENT_METADATA_DISPLAY_TARGET_INFO,
  PatientMetadataDisplayTarget,
} from '@principle-theorem/principle-core/interfaces';
import { toPairs } from 'lodash';

interface IPatientMetadataDisplayOutletHelp {
  value: PatientMetadataDisplayTarget;
  label: string;
  description: string;
  imageUrl: string;
}

@Component({
    selector: 'pr-patient-metadata-outlet-help',
    templateUrl: './patient-metadata-outlet-help.component.html',
    styleUrls: ['./patient-metadata-outlet-help.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, NgMaterialModule, NgSharedModule]
})
export class PatientMetadataOutletHelpComponent {
  @HostBinding('class') cssClasses = 'block w-[600px]';
  outlets: IPatientMetadataDisplayOutletHelp[] = toPairs(
    PATIENT_METADATA_DISPLAY_TARGET_INFO
  ).map(([value, info]) => ({
    value: value as PatientMetadataDisplayTarget,
    ...info,
  }));
}
