import type * as moment from 'moment-timezone';
import {
  MockIncompleteTreatmentsStatistic,
  MockTotalNumberOfAppointmentsStatistic,
  MockTotalNumberOfCancelledAppointmentsStatistic,
  MockTotalNumberOfRebookedAppointmentsStatistic,
  MockTotalValueOfTreatmentsAppliedStatistic,
  MockAgeStatistic,
  MockDistanceStatistic,
  MockExistingPatientsStatistic,
  MockNewPatientsStatistic,
  MockMalePatientsStatistics,
  MockFemalePatientsStatistics,
  MockTotalPatientSpend,
} from './base-statistics';
import {
  type IPatientStats,
  type IStatistics,
} from '@principle-theorem/reporting';

export class PatientStatistics implements IStatistics<IPatientStats> {
  name = '';
  date: moment.Moment;
  stats: IPatientStats = {
    totalNumberOfAppointments: MockTotalNumberOfAppointmentsStatistic(),
    totalNumberOfCancelledAppointments:
      MockTotalNumberOfCancelledAppointmentsStatistic(),
    totalNumberOfRebookedAppointments:
      MockTotalNumberOfRebookedAppointmentsStatistic(),
    totalValueOfTreatmentsApplied: MockTotalValueOfTreatmentsAppliedStatistic(),
    incompleteTreatments: MockIncompleteTreatmentsStatistic(),
    totalPatientSpend: MockTotalPatientSpend(),
    age: MockAgeStatistic(),
    distance: MockDistanceStatistic(),
    isMale: MockMalePatientsStatistics(),
    isFemale: MockFemalePatientsStatistics(),
    isNew: MockNewPatientsStatistic(),
    isExisting: MockExistingPatientsStatistic(),
  };
}
