import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  isObject,
  toInt,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { compact, flow, isNull, isNumber, isString, sortBy } from 'lodash';
import * as moment from 'moment-timezone';
import { PATIENT_APPOINTMENT_RESOURCE_TYPE } from '../../../destination/entities/patient-appointments';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { OFFSET_PLACEHOLDER } from '../../../source/source-helpers';
import { cleanObjectStrings } from './lib/conversion-helpers';
import { PatientSourceEntity } from './patient';

export const PATIENT_APPOINTMENT_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Appointment List',
      description: '',
      idPrefix: PATIENT_APPOINTMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

interface ID4WAppointmentQuery {
  id: number;
  appointment_block_id: number;
  appointment_book_id: number;
  date: string;
  start: string[];
  duration: string;
  description: string;
  notes: string[];
  patient_id: number;
  practice_id: number;
  practitioner_id: number | null;
  status: string;
  statuses: string[];
  created_at: string;
  deleted_at: string | null;
  deleted_by: string | null;
  arrived_at: string | null;
  checked_in_at: string | null;
  checked_out_at: string | null;
}

export interface ID4WAppointment {
  id: number;
  appointment_block_id: number;
  appointment_book_id: number;
  date: string;
  start: string;
  duration: number;
  description: string;
  notes: string;
  patient_id: number;
  practice_id: number;
  practitioner_id: number | null;
  status: string;
  statuses: string[];
  created_at: string;
  deleted_at: string | null;
  deleted_by: string | null;
  arrived_at: string | null;
  checked_in_at: string | null;
  checked_out_at: string | null;
}

export function isD4WAppointment(item: unknown): item is ID4WAppointment {
  return (
    isObject(item) &&
    isNumber(item.id) &&
    isNumber(item.appointment_block_id) &&
    isNumber(item.appointment_book_id) &&
    isString(item.date) &&
    isString(item.start) &&
    isNumber(item.duration) &&
    isString(item.description) &&
    isString(item.notes) &&
    isNumber(item.patient_id) &&
    isNumber(item.practice_id) &&
    (isNumber(item.practitioner_id) || isNull(item.practitioner_id)) &&
    isString(item.status) &&
    TypeGuard.arrayOf(isString)(item.statuses) &&
    (isString(item.created_at) || isNull(item.created_at)) &&
    (isString(item.deleted_at) || isNull(item.deleted_at)) &&
    (isString(item.deleted_by) || isNull(item.deleted_by)) &&
    (isString(item.arrived_at) || isNull(item.arrived_at)) &&
    (isString(item.checked_in_at) || isNull(item.checked_in_at)) &&
    (isString(item.checked_out_at) || isNull(item.checked_out_at))
  );
}

export interface ID4WAppointmentTranslations {
  from: Timestamp;
  to: Timestamp;
  createdAt?: Timestamp;
  deletedAt?: Timestamp;
  arrivedAt?: Timestamp;
  checkedInAt?: Timestamp;
  checkedOutAt?: Timestamp;
}

export interface ID4WAppointmentFilters {
  patientId: string;
  practiceId: string;
  from: Timestamp;
  createdAt?: Timestamp;
}

const PATIENT_APPOINTMENT_SOURCE_QUERY = `
SELECT
  appointments.*,
  appointment_books.practice_id,
  appointment_status_changes.arrived_at,
  appointment_status_changes.checked_in_at,
  appointment_status_changes.checked_out_at
FROM (
  SELECT
    convert_to_integer(appt_id) AS id,
    appoint_id AS appointment_block_id,
    app_book_id AS appointment_book_id,
    app_date AS date,
    ARRAY_AGG(start) as start,
    SUM(duration) AS duration,
    STRING_AGG(descrip, ',' ORDER BY start ASC) AS description,
    ARRAY_AGG(notes) AS notes,
    convert_to_integer(pat_id) AS patient_id,
    convert_to_integer(doct_id) AS practitioner_id,
    status,
    string_to_array(REGEXP_REPLACE(REGEXP_REPLACE(status, '(,+)', ',','g'), '(,$)', '','g'), ',', '') AS statuses,
    date_of_creation AS created_at,
    NULL AS deleted_at,
    NULL AS deleted_by
  FROM a_appointments
  WHERE convert_to_text(pat_id) != '' AND appt_id IS NOT NULL AND convert_to_text(appt_id) != ''
  GROUP BY date, appt_id, appoint_id, app_book_id, pat_id, doct_id, status, date_of_creation
  ORDER BY app_book_id::INTEGER ASC, date ASC, start ASC
  ${OFFSET_PLACEHOLDER}
  ) AS appointments
INNER JOIN (
  SELECT
app_book_number AS appointment_book_id,
    practice_id
  FROM app_books
) AS appointment_books
ON appointments.appointment_book_id = appointment_books.appointment_book_id
LEFT JOIN (
  SELECT
    event_id AS id,
    appoint_id AS appointment_id,
    patient_id AS patient_id,
    arrived_time AS arrived_at,
    checkin_time AS checked_in_at,
    checkout_time AS checked_out_at
  FROM patient_event_time
) AS appointment_status_changes
ON appointments.id = appointment_status_changes.appointment_id
`;

const PATIENT_APPOINTMENT_ESTIMATE_QUERY = `SELECT
  appointments.id
FROM (
  SELECT
    convert_to_integer(appt_id) AS id,
    app_book_id AS appointment_book_id,
	app_date AS date
  FROM a_appointments
  WHERE convert_to_text(pat_id) != '' AND appt_id IS NOT NULL AND convert_to_text(appt_id) != ''
  GROUP BY date, appt_id, appoint_id, app_book_id, pat_id, doct_id, status, date_of_creation
  ) AS appointments
INNER JOIN (
  SELECT
    app_book_number AS appointment_book_id,
    practice_id
  FROM app_books
) AS appointment_books
ON appointments.appointment_book_id = appointment_books.appointment_book_id
GROUP BY appointments.id
`;

export class PatientAppointmentSourceEntity extends BaseSourceEntity<
  ID4WAppointment,
  ID4WAppointmentTranslations,
  ID4WAppointmentFilters
> {
  sourceEntity = PATIENT_APPOINTMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_APPOINTMENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_APPOINTMENT_SOURCE_QUERY;
  allowOffsetJob = true;
  verifySourceFn = isD4WAppointment;
  override dateFilterField: keyof ID4WAppointmentFilters = 'from';

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  override transformDataFn = flow([transformAppointmentResults]);
  override estimateQuery = PATIENT_APPOINTMENT_ESTIMATE_QUERY;

  translate(
    appointment: ID4WAppointment,
    timezone: Timezone
  ): ID4WAppointmentTranslations {
    return {
      from: toTimestamp(
        moment.tz(
          `${appointment.date} ${appointment.start}`,
          ISO_DATE_TIME_FORMAT,
          timezone
        )
      ),
      to: toTimestamp(
        moment
          .tz(
            `${appointment.date} ${appointment.start}`,
            ISO_DATE_TIME_FORMAT,
            timezone
          )
          .add({ minutes: appointment.duration })
      ),
      createdAt: appointment.created_at
        ? toTimestamp(
            moment.tz(appointment.created_at, ISO_DATE_TIME_FORMAT, timezone)
          )
        : undefined,
      deletedAt: appointment.deleted_at
        ? toTimestamp(
            moment.tz(appointment.deleted_at, ISO_DATE_TIME_FORMAT, timezone)
          )
        : undefined,
      arrivedAt: appointment.arrived_at
        ? toTimestamp(
            moment.tz(appointment.arrived_at, ISO_DATE_TIME_FORMAT, timezone)
          )
        : undefined,
      checkedInAt: appointment.checked_in_at
        ? toTimestamp(
            moment.tz(appointment.checked_in_at, ISO_DATE_TIME_FORMAT, timezone)
          )
        : undefined,
      checkedOutAt: appointment.checked_out_at
        ? toTimestamp(
            moment.tz(
              appointment.checked_out_at,
              ISO_DATE_TIME_FORMAT,
              timezone
            )
          )
        : undefined,
    };
  }

  getSourceRecordId(data: ID4WAppointment): number {
    return data.id;
  }

  getSourceLabel(data: ID4WAppointment): string {
    return `${data.id} ${data.date} ${data.start}`;
  }

  getFilterData(
    data: ID4WAppointment,
    timezone: Timezone
  ): ID4WAppointmentFilters {
    return {
      patientId: data.patient_id.toString(),
      practiceId: data.practice_id.toString(),
      from: toTimestamp(
        moment.tz(`${data.date} ${data.start}`, ISO_DATE_TIME_FORMAT, timezone)
      ),
      createdAt: data.created_at
        ? toTimestamp(
            moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
          )
        : undefined,
    };
  }
}

function transformAppointmentResults(
  rows: ID4WAppointmentQuery[]
): ID4WAppointment[] {
  return rows
    .map((appointment) => ({
      id: appointment.id,
      appointment_block_id: appointment.appointment_block_id,
      appointment_book_id: appointment.appointment_book_id,
      date: appointment.date,
      start: sortBy(appointment.start)[0],
      duration: toInt(appointment.duration),
      description: compact(appointment.description.split(',')).join(','),
      notes: appointment.notes[0],
      patient_id: appointment.patient_id,
      practice_id: appointment.practice_id,
      practitioner_id: appointment.practitioner_id,
      status: appointment.status,
      statuses: appointment.statuses.filter(isString),
      created_at: appointment.created_at,
      deleted_at: appointment.deleted_at,
      deleted_by: appointment.deleted_by,
      arrived_at: appointment.arrived_at,
      checked_in_at: appointment.checked_in_at,
      checked_out_at: appointment.checked_out_at,
    }))
    .map(cleanObjectStrings);
}
