import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  type IBreadcrumb,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IPractice,
  type IProduct,
} from '@principle-theorem/principle-core/interfaces';
import { type CollectionReference } from '@principle-theorem/shared';
import {
  addDoc,
  all$,
  filterUndefined,
  findProp,
  multiFilter,
  multiSortBy$,
  nameSorter,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BrandProductCollectionResolverService } from '../../brand-product-collection-resolver.service';
import {
  type IProductData,
  UpsertProductDialogComponent,
} from '../upsert-product-dialog/upsert-product-dialog.component';

@Component({
    selector: 'pr-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProductListComponent {
  productScope$: ReplaySubject<WithRef<IBrand> | WithRef<IPractice>> =
    new ReplaySubject(1);
  trackByProduct = TrackByFunctions.ref<WithRef<IProduct>>();
  productCol$: Observable<CollectionReference<IProduct>>;
  products$: Observable<WithRef<IProduct>[]>;
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _currentBrand: CurrentBrandScope,
    private _route: ActivatedRoute
  ) {
    this.productCol$ = this._route.data.pipe(
      findProp<CollectionReference<IProduct>>(
        BrandProductCollectionResolverService.resolverKey
      ),
      filterUndefined()
    );

    this.products$ = this.productCol$.pipe(
      switchMap((productCol) => all$(productCol)),
      multiFilter((product) => !product.deleted),
      multiSortBy$(nameSorter())
    );

    this.breadcrumbs$ = this._currentBrand.doc$.pipe(
      filterUndefined(),
      map((brand) => [
        { label: 'Settings', path: '../../../' },
        { label: brand.name },
        { label: 'Products' },
      ])
    );
  }

  async add(): Promise<void> {
    const product = await this._dialog
      .open<UpsertProductDialogComponent, IProductData, IProduct>(
        UpsertProductDialogComponent,
        DialogPresets.medium({})
      )
      .afterClosed()
      .toPromise();

    if (!product) {
      return;
    }
    const productCol = await snapshot(this.productCol$);
    await addDoc(productCol, product);
    this._snackBar.open('Product Added');
  }
}
