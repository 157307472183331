<mat-toolbar color="accent">
  <ng-container *ngIf="update; else add">Update</ng-container>
  <ng-template #add>Add</ng-template>
  Practice
</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content>
    <mat-form-field fxFlex>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" required />
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary">Save</button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
