import {
  ITranslationMap,
  type IConditionConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  type AtLeast,
  type INamedDocument,
  isSameRef,
  type IXSLXExport,
  type XSLXCell,
} from '@principle-theorem/shared';
import type { Column } from 'exceljs';
import { IBaseMigrationItemCode } from '../interfaces';

export interface IItemCodeToConditionXSLX {
  code: string;
  description: string;
  mapTo: string;
}

export const ITEM_CODE_TO_CONDITION_HEADERS: AtLeast<
  Column,
  'key' | 'header'
>[] = [
  {
    key: 'code',
    header: 'code',
  },
  {
    key: 'description',
    header: 'Description',
    width: 60,
  },
  {
    key: 'mapTo',
    header: 'Map To',
    width: 30,
  },
];

export class ItemCodeToConditionToXSLX<
  T extends IBaseMigrationItemCode = IBaseMigrationItemCode,
> implements IXSLXExport<T, IItemCodeToConditionXSLX>
{
  headers = ITEM_CODE_TO_CONDITION_HEADERS;

  constructor(
    private _conditionConfigurations: INamedDocument<IConditionConfiguration>[],
    private _existingMappings: ITranslationMap<IConditionConfiguration>[],
    private _suggestedMappings: { [key: string]: string }
  ) {}

  translate(records: T[]): Record<keyof IItemCodeToConditionXSLX, XSLXCell>[] {
    return records.map((record) => {
      const existingRecord = this._existingMappings.find(
        (existingMapping) =>
          existingMapping.sourceIdentifier === record.id.toString()
      );

      const destinationIdentifier = existingRecord?.destinationIdentifier;
      const suggestedMappingConfigurationName =
        this._suggestedMappings[record.itemCode] ?? undefined;

      const mappedCondition = destinationIdentifier
        ? this._conditionConfigurations.find((configuration) =>
            isSameRef(configuration, destinationIdentifier)
          )
        : suggestedMappingConfigurationName
          ? this._conditionConfigurations.find(
              (configuration) =>
                configuration.name === suggestedMappingConfigurationName
            )
          : undefined;

      return {
        code: {
          value: record.itemCode,
        },
        description: {
          value: record.description,
        },
        mapTo: {
          value: mappedCondition?.name,
          dataValidation: {
            type: 'list',
            formulae: [
              `"${this._conditionConfigurations
                .map((configuration) => configuration.name)
                .join(',')}"`,
            ],
          },
        },
      };
    });
  }
}
