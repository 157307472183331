import { ContactResourceType } from '@principle-theorem/principle-core/interfaces';
import { IContactDimension } from '@principle-theorem/reporting/interfaces';
import { toSerialisedTimestamp, toTimestamp } from '@principle-theorem/shared';
import {
  BigQueryFirestoreModelMock,
  BigQueryInteractionMock,
  BigQueryNamedDocumentMock,
  BigQueryNoteMock,
} from './common.mock';
import { ContactMock } from '@principle-theorem/principle-core';
import { MockGenerator } from '@principle-theorem/shared';

const contact = MockGenerator.generate(ContactMock);

export class ContactDimensionMock
  extends BigQueryFirestoreModelMock
  implements IContactDimension
{
  timestamp = toSerialisedTimestamp(toTimestamp());
  name = contact.name;
  email = contact.email;
  address = contact.address;
  phone = contact.phone;
  mobileNumber = contact.mobileNumber;
  jobTitle = contact.jobTitle;
  notes = [
    MockGenerator.generate(BigQueryNoteMock),
    MockGenerator.generate(BigQueryNoteMock),
  ];
  interactions = [
    MockGenerator.generate(BigQueryInteractionMock),
    MockGenerator.generate(BigQueryInteractionMock),
    MockGenerator.generate(BigQueryInteractionMock),
  ];
  parentRef = {
    ...MockGenerator.generate(BigQueryNamedDocumentMock),
    type: ContactResourceType.Contact,
  };
}
