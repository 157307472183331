import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type IReffable } from '@principle-theorem/shared';
import { type INotable } from '@principle-theorem/principle-core/interfaces';

@Component({
    selector: 'pr-notes-dialog',
    templateUrl: './notes-dialog.component.html',
    styleUrls: ['./notes-dialog.component.sass'],
    standalone: false
})
export class NotesDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<NotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: INotesDialogData
  ) {}

  get title(): string {
    return this.data.title ? this.data.title : 'Notes';
  }
}

export interface INotesDialogData {
  notable: INotable & IReffable;
  title?: string;
}
