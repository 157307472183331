import {
  type ITimelineDisplayOptions,
  type ITimelineDisplayRange,
  type ITimelineDataGroup,
  type ITimelineNode,
  TimelineDisplayOrientation,
} from '@principle-theorem/principle-core/interfaces';
import {
  isInRange,
  type ITimePeriod,
  mergeDayAndTime,
  momentRound,
  toMomentRange,
} from '@principle-theorem/shared';
import { clamp } from 'lodash';
import * as moment from 'moment-timezone';
import {
  type IInteractableRect,
  InteractableRect,
} from './interactive-timeline-node/interactable-rect';

export class InteractiveTimelineDisplayCalculator {
  static isHorizontal(options: ITimelineDisplayOptions): boolean {
    return options.orientation === TimelineDisplayOrientation.Horizontal;
  }

  static pixelsPerMinute(options: ITimelineDisplayOptions): number {
    return options.stepSizeInPixels / options.stepSizeInMins;
  }

  static getTimelineSize(
    options: ITimelineDisplayOptions,
    timeRange: ITimePeriod
  ): number {
    const minutesInTimeline = timeRange.to.diff(timeRange.from, 'minutes');
    const stepsInTimeline = minutesInTimeline / options.stepSizeInMins;
    return stepsInTimeline * options.stepSizeInPixels;
  }

  static getGroupSize(
    options: ITimelineDisplayOptions,
    group: ITimelineDataGroup<unknown, unknown>
  ): number {
    const numTracks =
      group.nodes.length > options.minimumTracksPerGroup
        ? group.nodes.length
        : options.minimumTracksPerGroup;
    return options.trackSizeInPixels * numTracks;
  }

  static getNodeX(
    options: ITimelineDisplayOptions,
    timeRange: ITimePeriod,
    node: ITimelineNode<unknown>,
    day: moment.Moment
  ): number {
    return InteractiveTimelineDisplayCalculator.timeToGridPosition(
      options,
      timeRange,
      node.from,
      day
    );
  }

  static getNodeY(
    options: ITimelineDisplayOptions,
    trackIndex: number
  ): number {
    return options.trackSizeInPixels * trackIndex;
  }

  static getTrackIndex(
    options: ITimelineDisplayOptions,
    positionInPx: number
  ): number {
    return Math.floor(positionInPx / options.trackSizeInPixels);
  }

  static getNodeWidth(
    options: ITimelineDisplayOptions,
    node: ITimelineNode<unknown>
  ): number {
    const durationInMins = node.to.diff(node.from, 'minutes');
    return InteractiveTimelineDisplayCalculator.durationToPixels(
      options,
      durationInMins
    );
  }

  static getNodeHeight(options: ITimelineDisplayOptions): number {
    return options.trackSizeInPixels;
  }

  static getHourIncrements(
    options: Pick<ITimelineDisplayOptions, 'hourIncrement'>
  ): number[] {
    const increments = [];
    for (
      let minutes = options.hourIncrement;
      minutes < 60;
      minutes += options.hourIncrement
    ) {
      increments.push(minutes);
    }
    return increments;
  }

  static durationToPixels(
    options: ITimelineDisplayOptions,
    durationInMins: number
  ): number {
    const widthInSteps = durationInMins / options.stepSizeInMins;
    return options.stepSizeInPixels * widthInSteps;
  }

  static timeToGridPosition(
    options: ITimelineDisplayOptions,
    timeRange: ITimePeriod,
    time: moment.Moment,
    day: moment.Moment
  ): number {
    return InteractiveTimelineDisplayCalculator.roundPositionToGrid(
      options,
      timeRange,
      InteractiveTimelineDisplayCalculator.timeToPosition(
        options,
        timeRange,
        time,
        day
      )
    );
  }

  static roundDurationToGrid(
    options: ITimelineDisplayOptions,
    duration: number
  ): number {
    const steps = duration / options.stepSizeInPixels;
    return (
      Math.round(steps) * options.stepSizeInPixels || options.stepSizeInPixels
    );
  }

  static roundPositionToGrid(
    options: ITimelineDisplayOptions,
    timeRange: ITimePeriod,
    position: number,
    restrictToGrid: boolean = false
  ): number {
    const steps = position / options.stepSizeInPixels;

    if (!restrictToGrid) {
      return Math.round(steps) * options.stepSizeInPixels;
    }

    return Math.abs(
      clamp(
        Math.round(steps) * options.stepSizeInPixels,
        0,
        InteractiveTimelineDisplayCalculator.getMaxPosition(options, timeRange)
      )
    );
  }

  static timeToPosition(
    options: ITimelineDisplayOptions,
    timeRange: ITimePeriod,
    time: moment.Moment,
    day: moment.Moment
  ): number {
    const dayRange = InteractiveTimelineDisplayCalculator.getTimelineDayRange(
      timeRange,
      day
    );
    const minsFromStart = time.diff(dayRange.from, 'minutes');
    return (
      minsFromStart *
      InteractiveTimelineDisplayCalculator.pixelsPerMinute(options)
    );
  }

  static getMaxPosition(
    options: ITimelineDisplayOptions,
    timeRange: ITimePeriod
  ): number {
    const minsFromStart = timeRange.to.diff(timeRange.from, 'minutes');
    return (
      minsFromStart *
      InteractiveTimelineDisplayCalculator.pixelsPerMinute(options)
    );
  }

  static positionToTime(
    options: ITimelineDisplayOptions,
    timeRange: ITimePeriod,
    position: number,
    day: moment.Moment
  ): moment.Moment {
    const dayRange = InteractiveTimelineDisplayCalculator.getTimelineDayRange(
      timeRange,
      day
    );
    const minsFromStart =
      position / InteractiveTimelineDisplayCalculator.pixelsPerMinute(options);
    const time = moment(dayRange.from).add(minsFromStart, 'minutes');
    return momentRound(time, 'minutes');
  }

  static isWithinTimelineDay(
    range: ITimelineDisplayRange,
    currentTime: moment.Moment,
    day: moment.Moment
  ): boolean {
    const dayRange = InteractiveTimelineDisplayCalculator.getTimelineDayRange(
      range.timeRange,
      day
    );
    const dayInTimeline = isInRange(range.dateRange, dayRange.from);
    const timeIsInDay = isInRange(dayRange, currentTime);
    return dayInTimeline && timeIsInDay;
  }

  static getBackgroundSize(options: ITimelineDisplayOptions): string {
    const pixelMin =
      InteractiveTimelineDisplayCalculator.pixelsPerMinute(options);
    const gridIncrement = options.guidelineIncrementInMins * pixelMin;
    const trackSize = options.trackSizeInPixels;

    const isHorizontal =
      InteractiveTimelineDisplayCalculator.isHorizontal(options);
    const xSize = isHorizontal ? gridIncrement : trackSize;
    const ySize = isHorizontal ? trackSize : gridIncrement;
    return `${xSize}px ${ySize}px`;
  }

  static getGridlineSize(options: ITimelineDisplayOptions): string {
    if (!options.showGridlines) {
      return '0 0';
    }

    const pixelMin =
      InteractiveTimelineDisplayCalculator.pixelsPerMinute(options);
    const gridIncrement = options.hourIncrement * pixelMin;
    const trackSize = options.trackSizeInPixels;

    const isHorizontal =
      InteractiveTimelineDisplayCalculator.isHorizontal(options);
    const xSize = isHorizontal ? gridIncrement : trackSize;
    const ySize = isHorizontal ? trackSize : gridIncrement;
    return `${xSize}px ${ySize}px`;
  }

  static createOrientationAwareRect(
    options: ITimelineDisplayOptions,
    startPosition: number,
    durationSize: number,
    crossAxisSize: number,
    crossAxisPosition: number = 0,
    overrides: Partial<IInteractableRect> = {}
  ): IInteractableRect {
    const isHorizontal =
      InteractiveTimelineDisplayCalculator.isHorizontal(options);
    return InteractableRect.init({
      x: isHorizontal ? startPosition : crossAxisPosition,
      y: isHorizontal ? crossAxisPosition : startPosition,
      width: isHorizontal ? durationSize : crossAxisSize,
      height: isHorizontal ? crossAxisSize : durationSize,
      ...overrides,
    });
  }

  static getTimeFromRect(
    options: ITimelineDisplayOptions,
    timeRange: ITimePeriod,
    rect: IInteractableRect,
    day: moment.Moment
  ): ITimePeriod {
    const isHorizontal =
      options.orientation === TimelineDisplayOrientation.Horizontal;
    const start = isHorizontal ? rect.x : rect.y;
    const size = isHorizontal ? rect.width : rect.height;
    const from = InteractiveTimelineDisplayCalculator.positionToTime(
      options,
      timeRange,
      start,
      day
    );
    const to = InteractiveTimelineDisplayCalculator.positionToTime(
      options,
      timeRange,
      start + size,
      day
    );
    return { from, to };
  }

  static getTimelineDayRange(
    timeRange: ITimePeriod,
    day: moment.Moment
  ): ITimePeriod {
    return toMomentRange(
      mergeDayAndTime(day, timeRange.from),
      mergeDayAndTime(day, timeRange.to)
    );
  }
}

export function pixelPerMinuteForSpace(
  from: moment.Moment,
  to: moment.Moment,
  availablePixels: number
): number {
  const durationInMins = to.diff(from, 'minutes');
  const pixelsPerMin = availablePixels / durationInMins;
  return pixelsPerMin;
}
