import {
  AppointmentStatus,
  AppointmentSummary,
} from '@principle-theorem/principle-core/interfaces';

export abstract class AppointmentGroupSorter {
  abstract name: string;
  abstract isInGroup(appointment: AppointmentSummary): boolean;

  sort(
    appointmentA: AppointmentSummary,
    appointmentB: AppointmentSummary
  ): number {
    return appointmentA.event.from.seconds > appointmentB.event.from.seconds
      ? 1
      : -1;
  }
}

export class ArrivingAppointments extends AppointmentGroupSorter {
  name = 'Expected Arrivals';

  isInGroup(appointment: AppointmentSummary): boolean {
    return (
      appointment.metadata.status === AppointmentStatus.Scheduled ||
      appointment.metadata.status === AppointmentStatus.Confirmed
    );
  }
}

export class WaitingAppointments extends AppointmentGroupSorter {
  name = 'Waiting';

  isInGroup(appointment: AppointmentSummary): boolean {
    return (
      appointment.metadata.status === AppointmentStatus.Arrived ||
      appointment.metadata.status === AppointmentStatus.CheckedIn
    );
  }
}

export class DepartingAppointments extends AppointmentGroupSorter {
  name = 'Expected Departures';

  isInGroup(appointment: AppointmentSummary): boolean {
    return (
      appointment.metadata.status === AppointmentStatus.InProgress ||
      appointment.metadata.status === AppointmentStatus.CheckingOut
    );
  }

  override sort(
    appointmentA: AppointmentSummary,
    appointmentB: AppointmentSummary
  ): number {
    if (appointmentA.metadata.status === AppointmentStatus.CheckingOut) {
      return -1;
    }
    if (appointmentB.metadata.status === AppointmentStatus.CheckingOut) {
      return 1;
    }
    return appointmentA.event.to.seconds > appointmentB.event.to.seconds
      ? 1
      : -1;
  }
}

export class CompletedAppointments extends AppointmentGroupSorter {
  name = 'Done';

  isInGroup(appointment: AppointmentSummary): boolean {
    return (
      appointment.metadata.status === AppointmentStatus.Complete ||
      appointment.metadata.status === AppointmentStatus.Unscheduled
    );
  }
}
