import { Action, combineReducers } from '@ngrx/store';
import {
  appointmentSchedulingReducer,
  type IAppointmentSchedulingState,
  SCHEDULING_FEATURE_KEY,
} from './appointment-scheduling.reducer';
import {
  appointmentSuggestionsReducer,
  APPOINTMENT_SUGGESTIONS_FEATURE_KEY,
  type IAppointmentSuggestionsState,
} from './appointment-suggestions.reducer';

export const APPOINTMENT_SCHEDULING_MODULE_FEATURE_KEY =
  'appointmentSchedulingModule';

export interface IAppointmentSchedulingModuleState {
  [SCHEDULING_FEATURE_KEY]: IAppointmentSchedulingState;
  [APPOINTMENT_SUGGESTIONS_FEATURE_KEY]: IAppointmentSuggestionsState;
}

export function reducers(
  state: IAppointmentSchedulingModuleState | undefined,
  action: Action
): IAppointmentSchedulingModuleState {
  return combineReducers({
    [SCHEDULING_FEATURE_KEY]: appointmentSchedulingReducer,
    [APPOINTMENT_SUGGESTIONS_FEATURE_KEY]: appointmentSuggestionsReducer,
  })(state, action);
}
