import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NgTyroModule } from '@principle-theorem/ng-tyro';
import { ReconciliationReportControlsComponent } from './reconciliation-report-controls/reconciliation-report-controls.component';
import { ReconciliationReportComponent } from './reconciliation-report.component';
import { ReconciliationReportEffects } from './store/reconciliation-report.effects';
import { ReconciliationReportFacade } from './store/reconciliation-report.facade';
import {
  metaReducers,
  RECONCILIATON_REPORT_FEATURE_KEY,
  reducers,
} from './store/reducers/main';
import { TyroReconciliationReportComponent } from './tyro-reconciliation-report/tyro-reconciliation-report.component';
import { HealthpointReconciliationReportComponent } from './healthpoint-reconciliation-report/healthpoint-reconciliation-report.component';
import { PrincipleReconciliationReportComponent } from './principle-reconciliation-report/principle-reconciliation-report.component';
import { ReconciliationTransactionsSummaryComponent } from './principle-reconciliation-report/reconciliation-transactions-summary/reconciliation-transactions-summary.component';
import { ReconciliationTransactionsDrilldownComponent } from './principle-reconciliation-report/reconciliation-transactions-drilldown/reconciliation-transactions-drilldown.component';

@NgModule({
  imports: [
    NgSharedModule,
    NgPrincipleSharedModule,
    NgTyroModule,
    StoreModule.forFeature(RECONCILIATON_REPORT_FEATURE_KEY, reducers, {
      metaReducers,
    }),
    EffectsModule.forFeature([ReconciliationReportEffects]),
  ],
  declarations: [
    ReconciliationReportComponent,
    ReconciliationReportControlsComponent,
    TyroReconciliationReportComponent,
    HealthpointReconciliationReportComponent,
    PrincipleReconciliationReportComponent,
    ReconciliationTransactionsSummaryComponent,
    ReconciliationTransactionsDrilldownComponent,
  ],
  providers: [ReconciliationReportFacade, ReconciliationReportEffects],
})
export class ReconciliationReportModule {}
