import {
  IDestinationEntity,
  PracticeMigrationCollection,
  PracticeMigrationStatus,
  RootCollection,
  type IBrand,
  type ICustomMapping,
  type IInteraction,
  type IOrganisation,
  type IPractice,
  type IPracticeMigration,
  type ISourceEntity,
  type PracticeMigrationEssentials,
  ITranslationMap,
  PRACTICE_MIGRATION_DATABASE_BACKUP_FOLDER,
} from '@principle-theorem/principle-core/interfaces';
import {
  all$,
  asColRef,
  doc$,
  find$,
  safeCombineLatest,
  shareReplayCold,
  subCollection,
  where,
  type AtLeast,
  type CollectionReference,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class PracticeMigration {
  static col(): CollectionReference<IPracticeMigration> {
    return asColRef<IPracticeMigration>(RootCollection.PracticeMigrations);
  }

  static all$(): Observable<WithRef<IPracticeMigration>[]> {
    return all$(this.col()).pipe(
      map((migrations) => sortBy(migrations, ['name'])),
      shareReplayCold()
    );
  }

  static init(
    overrides: PracticeMigrationEssentials &
      AtLeast<IPracticeMigration, 'configuration'>
  ): IPracticeMigration {
    return {
      translationMaps: [],
      manualTranslationMap: [],
      status: PracticeMigrationStatus.Pending,
      ...overrides,
    };
  }

  static noteCol(
    migration: IReffable<IPracticeMigration>
  ): CollectionReference<IInteraction> {
    return subCollection<IInteraction>(
      migration.ref,
      PracticeMigrationCollection.Notes
    );
  }

  static notes$(
    migration: IReffable<IPracticeMigration>
  ): Observable<WithRef<IInteraction>[]> {
    return all$(PracticeMigration.noteCol(migration));
  }

  static translationMapCol(
    migration: IReffable<IPracticeMigration>
  ): CollectionReference<ITranslationMap> {
    return subCollection<ITranslationMap>(
      migration.ref,
      PracticeMigrationCollection.TranslationMaps
    );
  }

  static sourceEntityCol(
    migration: IReffable<IPracticeMigration>
  ): CollectionReference<ISourceEntity> {
    return subCollection<ISourceEntity>(
      migration.ref,
      PracticeMigrationCollection.SourceEntities
    );
  }

  static sourceEntities$(
    migration: IReffable<IPracticeMigration>
  ): Observable<WithRef<ISourceEntity>[]> {
    return all$(PracticeMigration.sourceEntityCol(migration)).pipe(
      map((sourceEntities) => sortBy(sourceEntities, ['metadata.label']))
    );
  }

  static findSourceEntity$(
    migration: IReffable<IPracticeMigration>,
    sourceEntity: ISourceEntity
  ): Observable<WithRef<ISourceEntity> | undefined> {
    return find$(
      PracticeMigration.sourceEntityCol(migration),
      where('metadata.idPrefix', '==', sourceEntity.metadata.idPrefix)
    );
  }

  static destinationEntityCol(
    migration: IReffable<IPracticeMigration>
  ): CollectionReference<IDestinationEntity> {
    return subCollection<IDestinationEntity>(
      migration.ref,
      PracticeMigrationCollection.DestinationEntities
    );
  }

  static destinationEntities$(
    migration: IReffable<IPracticeMigration>
  ): Observable<WithRef<IDestinationEntity>[]> {
    return all$(PracticeMigration.destinationEntityCol(migration));
  }

  static findDestinationEntity$(
    migration: IReffable<IPracticeMigration>,
    destinationEntity: IDestinationEntity
  ): Observable<WithRef<IDestinationEntity> | undefined> {
    return find$(
      PracticeMigration.destinationEntityCol(migration),
      where('metadata.label', '==', destinationEntity.metadata.label)
    );
  }

  static customMappingCol(
    migration: IReffable<IPracticeMigration>
  ): CollectionReference<ICustomMapping> {
    return subCollection<ICustomMapping>(
      migration.ref,
      PracticeMigrationCollection.CustomMappings
    );
  }

  static customMappings$(
    migration: IReffable<IPracticeMigration>
  ): Observable<WithRef<ICustomMapping>[]> {
    return all$(PracticeMigration.customMappingCol(migration));
  }

  static findCustomMapping$(
    migration: IReffable<IPracticeMigration>,
    customMapping: ICustomMapping
  ): Observable<WithRef<ICustomMapping> | undefined> {
    return find$(
      PracticeMigration.customMappingCol(migration),
      where('metadata.label', '==', customMapping.metadata.label)
    );
  }

  static organisation$(
    migration: IPracticeMigration
  ): Observable<WithRef<IOrganisation>> {
    return doc$(migration.configuration.organisation.ref);
  }

  static brand$(migration: IPracticeMigration): Observable<WithRef<IBrand>> {
    return doc$(migration.configuration.brand.ref);
  }

  static practices$(
    migration: IPracticeMigration
  ): Observable<WithRef<IPractice>[]> {
    return safeCombineLatest(
      migration.configuration.practices.map((practice) => doc$(practice.ref))
    );
  }

  static storagePath(migration: IReffable<IPracticeMigration>): string {
    return migration.ref.path;
  }

  static backupPath(migration: IReffable<IPracticeMigration>): string {
    return `${PracticeMigration.storagePath(
      migration
    )}/${PRACTICE_MIGRATION_DATABASE_BACKUP_FOLDER}`;
  }
}
