import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import { NgFirebaseModule } from '@principle-theorem/ng-firebase';
import { NgNotificationsModule } from '@principle-theorem/ng-notifications';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { TagsModule } from './tags/tags.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgPrincipleSharedModule,
    TagsModule,
    NgFeatureFlagsModule,
    NgFirebaseModule,
    NgSharedModule,
    NgNotificationsModule,
  ],
  exports: [TagsModule],
})
export class CoreModule {}
