import {
  AnyCustomFormElement,
  IJsonSchemaFormWithResolverConfig,
  type CustomFormDataResolverConfigMap,
  type IFormSchema,
  type IRootFormLayoutElement,
} from '@principle-theorem/principle-core/interfaces';

export const BASIC_MEDICAL_HISTORY_FORM_DATA_RESOLVER_CONFIG: CustomFormDataResolverConfigMap =
  {
    conditions: [
      {
        path: 'medicalHistory.conditions.asthma',
        mapTitle: 'Asthma',
      },
      {
        path: 'medicalHistory.conditions.hasGastrointestinalDisorder',
        mapTitle: 'Gastrointestinal Disorder',
      },
      {
        path: 'medicalHistory.conditions.hasJointReplacement',
        mapTitle: 'Joint Replacement',
      },
      {
        path: 'medicalHistory.conditions.cancerTreatmentChemotherapy',
        mapTitle: 'Chemotherapy',
      },
      {
        path: 'medicalHistory.conditions.cancerTreatmentRadiotherapy',
        mapTitle: 'Radiotherapy',
      },
      { path: 'medicalHistory.conditions.isPregnant', mapTitle: 'Pregnant' },
      {
        path: 'medicalHistory.conditions.hasPsychiatricDisorder',
        mapTitle: 'Psychiatric Disorder',
      },
      { path: 'medicalHistory.conditions.other', mapTitle: 'Other' },
    ],
    allergies: [
      { path: 'medicalHistory.allergies.penicillin', mapTitle: 'Penicillin' },
      { path: 'medicalHistory.allergies.latex', mapTitle: 'Latex' },
      { path: 'medicalHistory.allergies.other', mapTitle: 'Other' },
    ],
    history: [
      {
        path: 'dentalHistory.antibioticsBeforeTreatment',
        mapTitle: 'Antibiotics before treatment',
      },
      {
        path: 'dentalHistory.dentalAnxiety',
        mapTitle: 'Dental anxiety',
      },
    ],
  };

export const BASIC_MEDICAL_HISTORY_FORM_SCHEMA: IFormSchema = {
  properties: {
    medicalHistory: {
      properties: {
        conditions: {
          properties: {
            bleeding: {
              type: 'boolean',
              title: 'Abnormal / excessive bleeding',
            },
            heartCondition: {
              type: 'boolean',
              title: 'Artificial heart valve / valve defect',
            },
            cardiacSurgery: {
              type: 'boolean',
              title: 'Cardiac surgery / pacemaker',
            },
            hiv: { type: 'boolean', title: 'HIV' },
            rheumaticFever: { type: 'boolean', title: 'Rheumatic Fever' },
            stroke: { type: 'boolean', title: 'Stroke' },
            anaemia: { type: 'boolean', title: 'Anaemia' },
            asthma: { type: 'boolean', title: 'Asthma' },
            epilepsy: { type: 'boolean', title: 'Epilepsy' },
            kidneyDisease: { type: 'boolean', title: 'Kidney / Liver disease' },
            sleepApnoea: { type: 'boolean', title: 'Snoring or Sleep apnoea' },
            thyroidDisorder: { type: 'boolean', title: 'Thyroid disorder' },
            arthritis: { type: 'boolean', title: 'Arthritis' },
            biphosphonate: {
              type: 'boolean',
              title: 'Biphosphonate medication',
            },
            immuneDeficiency: { type: 'boolean', title: 'Immune deficiency' },
            heartBurn: { type: 'boolean', title: 'Reflux / Heart Burn' },
            steroidMedication: { type: 'boolean', title: 'Steroid medication' },
            hasGastrointestinalDisorder: {
              type: 'boolean',
              title: 'I have a gastrointestinal disorder',
            },
            gastrointestinalDisorder: {
              type: 'string',
              title: 'Gastrointestinal disorder',
              required: false,
            },
            isSmoker: { type: 'boolean', title: 'Past or Current smoker' },
            amountSmoked: {
              type: 'string',
              title: 'Approx. amount currently smoked',
              required: false,
            },
            hasJointReplacement: {
              type: 'boolean',
              title: 'I have had a joint replacement',
            },
            replacedJoint: {
              type: 'string',
              title: 'Joint replaced',
              required: false,
            },
            hasPsychiatricDisorder: {
              type: 'boolean',
              title: 'I have a psychiatric disorder',
            },
            psychiatricDisorderType: {
              type: 'string',
              title: 'Psychiatric disorder',
              required: false,
            },
            hasCancer: { type: 'boolean', title: 'I have cancer' },
            cancerTreatmentChemotherapy: {
              type: 'boolean',
              title: 'I have had/ will have Chemotherapy',
            },
            cancerType: {
              type: 'string',
              title: 'Cancer type',
              required: false,
            },
            cancerTreatmentRadiotherapy: {
              type: 'boolean',
              title: 'I have had/ will have Radiotherapy',
            },
            isPregnant: { type: 'boolean', title: 'I am pregnant' },
            pregnancyDueDate: {
              type: 'string',
              title: 'Due Date',
              required: false,
            },
            bloodPressure: {
              type: 'string',
              title: 'Blood pressure',
              enum: ['N/A', 'High', 'Low'],
              default: 'N/A',
              required: true,
            },
            hepatitis: {
              type: 'string',
              title: 'Hepatitis',
              enum: ['N/A', 'A', 'B', 'C', 'D'],
              default: 'N/A',
              required: true,
            },
            diabetes: {
              type: 'string',
              title: 'Diabetes',
              enum: ['N/A', 'Type I', 'Type II'],
              default: 'N/A',
              required: true,
            },
            other: {
              type: 'string',
              title: 'Any other medical conditions or major surgeries?',
              required: false,
            },
          },
          type: 'object',
        },
        medications: { type: 'string', title: 'Medications?', required: false },
        allergies: {
          properties: {
            penicillin: { type: 'boolean', title: 'Penicillin' },
            codeine: { type: 'boolean', title: 'Codeine' },
            iodine: { type: 'boolean', title: 'Iodine' },
            aspirin: { type: 'boolean', title: 'Aspirin' },
            chlorhexidine: { type: 'boolean', title: 'Chlorhexidine' },
            latex: { type: 'boolean', title: 'Latex' },
            other: { type: 'string', title: 'Other', required: false },
          },
          type: 'object',
        },
        emergencyContact: {
          properties: {
            name: { type: 'string', title: 'Name', required: false },
            phone: { type: 'string', title: 'Phone', required: false },
          },
          type: 'object',
        },
      },
      type: 'object',
    },
    dentalHistory: {
      properties: {
        reason: {
          type: 'string',
          title: 'Reason for attending or any specific concerns',
          required: false,
        },
        lastVisit: {
          type: 'string',
          title: 'Last Dental Visit',
          required: false,
        },
        antibioticsBeforeTreatment: {
          type: 'boolean',
          title: 'Do you need to take antibiotics before any dental treatment?',
        },
        dentalAnxiety: {
          type: 'boolean',
          title:
            'Do you generally feel anxious about visiting the dentist or hygienist?',
        },
        conditions: {
          properties: {
            toothache: { type: 'boolean', title: 'Toothache / pain' },
            crowding: { type: 'boolean', title: 'Crooked / crowded teeth' },
            brokenOrFilling: {
              type: 'boolean',
              title: 'Broken teeth or filling',
            },
            sensitivity: { type: 'boolean', title: 'Cold sensitivity' },
            halitosis: { type: 'boolean', title: 'Bad breath' },
            musclePain: { type: 'boolean', title: 'Jaw / muscle pain' },
            discolouring: {
              type: 'boolean',
              title: 'Dark or discoloured teeth',
            },
            decay: { type: 'boolean', title: 'Decay / cavities' },
            worn: { type: 'boolean', title: 'Worn down teeth' },
            bleeding: { type: 'boolean', title: 'Bleeding gums' },
            grinding: { type: 'boolean', title: 'Clenching or grinding' },
            ulcers: { type: 'boolean', title: 'Mouth Ulcers' },
          },
          type: 'object',
        },
        interestedIn: {
          properties: {
            whitening: { type: 'boolean', title: 'Teeth Whitening' },
            straightening: {
              type: 'boolean',
              title: 'Invisalign or teeth straightening',
            },
            wisdomRemoval: { type: 'boolean', title: 'Wisdom teeth removal' },
            wrinkleRelaxers: {
              type: 'boolean',
              title: 'Wrinkle relaxers (eg, botox, dysport)',
            },
            dentalAnxietyManagement: {
              type: 'boolean',
              title:
                'Dental anxiety management options (eg, sedation, happy gas, etc)',
            },
            implants: {
              type: 'boolean',
              title: 'Dental Implants for missing teeth',
            },
            veneers: {
              type: 'boolean',
              title: 'Veneers or cosmetic smile improvement',
            },
            sleepApnoea: {
              type: 'boolean',
              title: 'Anti-snoring and sleep apnoea treatment',
            },
            fillers: {
              type: 'boolean',
              title: 'Cosmetic dermal fillers (eg, lips, cheeks)',
            },
          },
          type: 'object',
        },
        more: {
          type: 'string',
          title: `Is there anything else about previous dental experiences you'd like us to know?`,
          required: false,
        },
      },
      type: 'object',
    },
    termsAndConditions: {
      properties: {
        agreed: {
          type: 'boolean',
          title: 'I agree to the terms and conditions',
        },
      },
      type: 'object',
    },
  },
  type: 'object',
} as IFormSchema;

export const BASIC_MEDICAL_HISTORY_FORM_LAYOUT: IRootFormLayoutElement[] = [
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.conditions.bleeding',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.heartCondition',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.cardiacSurgery',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.hiv',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.rheumaticFever',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.stroke',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.conditions.anaemia',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.asthma',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.epilepsy',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.kidneyDisease',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.sleepApnoea',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.thyroidDisorder',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.conditions.arthritis',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.biphosphonate',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.immuneDeficiency',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.heartBurn',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.steroidMedication',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.conditions.hasGastrointestinalDisorder',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.conditions.gastrointestinalDisorder',
                htmlClass: 'form-builder-text',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.conditions.isSmoker',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.conditions.amountSmoked',
                htmlClass: 'form-builder-text',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.conditions.hasJointReplacement',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.conditions.replacedJoint',
                htmlClass: 'form-builder-text',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.conditions.hasPsychiatricDisorder',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.conditions.psychiatricDisorderType',
                htmlClass: 'form-builder-text',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.conditions.hasCancer',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.cancerTreatmentChemotherapy',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.conditions.cancerType',
                htmlClass: 'form-builder-text',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.cancerTreatmentRadiotherapy',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.conditions.isPregnant',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                type: 'customDate',
                key: 'medicalHistory.conditions.pregnancyDueDate',
                htmlClass: 'form-builder-date',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.conditions.bloodPressure',
                htmlClass: 'form-builder-dropdown',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.hepatitis',
                htmlClass: 'form-builder-dropdown',
                items: [],
              },
            ],
          },
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.conditions.diabetes',
                htmlClass: 'form-builder-dropdown',
                items: [],
              },
              {
                key: 'medicalHistory.conditions.other',
                htmlClass: 'form-builder-text',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                type: 'customTextarea',
                key: 'medicalHistory.medications',
                htmlClass: 'form-builder-textarea',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'help',
        helpvalue: '<h3 class="!m-0 !font-bold pt-8">Allergies?</h3>',
      },
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.allergies.penicillin',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.allergies.codeine',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.allergies.iodine',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.allergies.aspirin',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.allergies.chlorhexidine',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'medicalHistory.allergies.latex',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.allergies.other',
                htmlClass: 'form-builder-text',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'help',
        helpvalue: '<h3 class="!m-0 !font-bold pt-8">Emergency Contact</h3>',
      },
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.emergencyContact.name',
                htmlClass: 'form-builder-text',
                items: [],
              },
            ],
          },
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'medicalHistory.emergencyContact.phone',
                htmlClass: 'form-builder-text',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'help',
        helpvalue:
          '<h3 class="!m-0 !font-bold pt-8">Dental History & Concerns</h3>',
      },
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                type: 'customTextarea',
                key: 'dentalHistory.reason',
                htmlClass: 'form-builder-textarea',
                items: [],
              },
              {
                type: 'customDate',
                key: 'dentalHistory.lastVisit',
                htmlClass: 'form-builder-date',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'dentalHistory.antibioticsBeforeTreatment',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'dentalHistory.dentalAnxiety',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'help',
        helpvalue:
          '<h3 class="!m-0 !font-bold pt-8">Please tick if any of the following apply to you</h3>',
      },
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'dentalHistory.conditions.toothache',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'dentalHistory.conditions.crowding',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'dentalHistory.conditions.brokenOrFilling',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'dentalHistory.conditions.sensitivity',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'dentalHistory.conditions.halitosis',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'dentalHistory.conditions.musclePain',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'dentalHistory.conditions.discolouring',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'dentalHistory.conditions.decay',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'dentalHistory.conditions.worn',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'dentalHistory.conditions.bleeding',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'dentalHistory.conditions.grinding',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'dentalHistory.conditions.ulcers',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'help',
        helpvalue:
          '<h3 class="!m-0 !font-bold pt-8">Would you like more information on the following services?</h3>',
      },
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'dentalHistory.interestedIn.whitening',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'dentalHistory.interestedIn.straightening',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'dentalHistory.interestedIn.wisdomRemoval',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'dentalHistory.interestedIn.wrinkleRelaxers',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'dentalHistory.interestedIn.dentalAnxietyManagement',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                key: 'dentalHistory.interestedIn.implants',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'dentalHistory.interestedIn.veneers',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'dentalHistory.interestedIn.sleepApnoea',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
              {
                key: 'dentalHistory.interestedIn.fillers',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                type: 'customTextarea',
                key: 'dentalHistory.more',
                htmlClass: 'form-builder-textarea',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'flex',
    htmlClass: 'form-builder-section form-builder-column',
    items: [
      {
        type: 'help',
        helpvalue: '<h3 class="!m-0 !font-bold pt-8">Consent & Signature</h3>',
      },
      {
        type: 'flex',
        htmlClass: 'form-builder-container form-builder-row',
        items: [
          {
            type: 'flex',
            htmlClass: 'form-builder-container form-builder-column',
            items: [
              {
                type: 'editorContent',
                content: {
                  content: {
                    type: 'doc',
                    content: [
                      {
                        attrs: { align: 'left' },
                        type: 'paragraph',
                        content: [
                          {
                            text: 'All personal information collected is confidential and handled in accordance to our privacy policy. See ',
                            type: 'text',
                          },
                          {
                            type: 'link',
                            attrs: {
                              href: 'https://principle.dental/legal/privacy-policy',
                              target: '_blank',
                              rel: 'noopener noreferrer nofollow',
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'principle.dental/legal/privacy-policy',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        content: [
                          {
                            text: 'By signing this form, you acknowledge and agree that: a) you have accurately completed this pre-clinical questionnaire to the best of your knowledge b) you consent to treatment agreed upon by you to be carried out by the dentist and their staff c) you assume full financial responsibility for treatment and d) payment is due in full at the time of service unless other arrangements have been made.',
                            type: 'text',
                          },
                        ],
                        attrs: { align: 'left' },
                        type: 'paragraph',
                      },
                      {
                        content: [
                          {
                            text: 'Cancellation policy: 48hrs notice is required for cancelling or rebooking appointments, otherwise it may incur a cancellation fee or refusal to reschedule.',
                            type: 'text',
                          },
                        ],
                        type: 'paragraph',
                        attrs: { align: 'left' },
                      },
                    ],
                  },
                  migrations: { migrations: [] },
                },
                htmlClass: 'form-builder-content',
                items: [],
              },
              {
                key: 'termsAndConditions.agreed',
                htmlClass: 'form-builder-checkbox',
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
] as unknown[] as IRootFormLayoutElement[];

export const BASIC_MEDICAL_HISTORY_CUSTOM_FORM_LAYOUT: AnyCustomFormElement[] =
  [
    {
      children: [
        {
          dropListCategory: 'field',
          type: 'container',
          children: [
            {
              uid: '21585598-aab1-436a-b1f1-a85bb3274609',
              children: [],
              options: {
                dataKey: 'medicalHistory.conditions.bleeding',
                required: false,
                label: 'Abnormal / excessive bleeding',
              },
              type: 'checkbox',
            },
            {
              type: 'checkbox',
              options: {
                required: false,
                label: 'Artificial heart valve / valve defect',
                dataKey: 'medicalHistory.conditions.heartCondition',
              },
              uid: '6d618696-d5f6-4c19-8da2-a93f2cf1d942',
              children: [],
            },
            {
              children: [],
              type: 'checkbox',
              uid: '3ef7f5b4-3145-481b-b2f4-bd11ecbe3417',
              options: {
                dataKey: 'medicalHistory.conditions.cardiacSurgery',
                required: false,
                label: 'Cardiac surgery / pacemaker',
              },
            },
            {
              uid: '1caefa14-ad9f-4d91-8bd2-aaab9384d7bc',
              options: {
                dataKey: 'medicalHistory.conditions.hiv',
                label: 'HIV',
                required: false,
              },
              children: [],
              type: 'checkbox',
            },
            {
              options: {
                label: 'Rheumatic Fever',
                required: false,
                dataKey: 'medicalHistory.conditions.rheumaticFever',
              },
              children: [],
              uid: '3ad2b9ec-32f7-41db-9fe8-91eefbf83a27',
              type: 'checkbox',
            },
            {
              type: 'checkbox',
              uid: '832cb56e-6b2d-475f-b103-c9d448ee8dc9',
              children: [],
              options: {
                label: 'Stroke',
                dataKey: 'medicalHistory.conditions.stroke',
                required: false,
              },
            },
          ],
          uid: 'a638230e-5acc-4286-84fa-55f76dab6c2f',
          options: { direction: 'column' },
        },
        {
          dropListCategory: 'field',
          type: 'container',
          options: { direction: 'column' },
          uid: '8b14f525-89f1-4bfb-987d-5c34d76dcfe8',
          children: [
            {
              children: [],
              options: {
                required: false,
                label: 'Anaemia',
                dataKey: 'medicalHistory.conditions.anaemia',
              },
              uid: '639557ca-a736-4028-b471-3e92d910099c',
              type: 'checkbox',
            },
            {
              options: {
                label: 'Asthma',
                dataKey: 'medicalHistory.conditions.asthma',
                required: false,
              },
              type: 'checkbox',
              children: [],
              uid: '80809af6-a8c5-44b7-9e16-2d6f79371ba1',
            },
            {
              children: [],
              options: {
                required: false,
                dataKey: 'medicalHistory.conditions.epilepsy',
                label: 'Epilepsy',
              },
              type: 'checkbox',
              uid: '02716b5c-9104-4234-829d-cf4a4965d33d',
            },
            {
              uid: '69e75d65-c6c8-4909-a73e-1ddc01a9f32e',
              options: {
                required: false,
                label: 'Kidney / Liver disease',
                dataKey: 'medicalHistory.conditions.kidneyDisease',
              },
              children: [],
              type: 'checkbox',
            },
            {
              type: 'checkbox',
              options: {
                required: false,
                dataKey: 'medicalHistory.conditions.sleepApnoea',
                label: 'Snoring or Sleep apnoea',
              },
              uid: '3f6b8eca-d07f-436c-ab02-842a95f9be1d',
              children: [],
            },
            {
              children: [],
              type: 'checkbox',
              options: {
                required: false,
                label: 'Thyroid disorder',
                dataKey: 'medicalHistory.conditions.thyroidDisorder',
              },
              uid: '1d078b5e-d143-4d8a-9aa5-33e3f45cd4b9',
            },
          ],
        },
        {
          dropListCategory: 'field',
          uid: '6cfff97f-d206-4b0b-b23f-be60d57e259c',
          type: 'container',
          children: [
            {
              type: 'checkbox',
              children: [],
              options: {
                label: 'Arthritis',
                dataKey: 'medicalHistory.conditions.arthritis',
                required: false,
              },
              uid: '40f4289b-397e-4e96-8518-d614ae7ab1a7',
            },
            {
              options: {
                label: 'Biphosphonate medication',
                dataKey: 'medicalHistory.conditions.biphosphonate',
                required: false,
              },
              children: [],
              uid: '54fdbac0-72fe-49d4-8b16-dc6b8cbb055a',
              type: 'checkbox',
            },
            {
              children: [],
              options: {
                label: 'Immune deficiency',
                dataKey: 'medicalHistory.conditions.immuneDeficiency',
                required: false,
              },
              uid: 'a809165a-2625-4774-95db-66718f23e679',
              type: 'checkbox',
            },
            {
              type: 'checkbox',
              uid: '00e60566-3d9c-48dd-857e-af217bcb2248',
              options: {
                label: 'Reflux / Heart Burn',
                required: false,
                dataKey: 'medicalHistory.conditions.heartBurn',
              },
              children: [],
            },
            {
              uid: '6811ca69-30a4-44e7-93f4-bdf54a42bee0',
              children: [],
              type: 'checkbox',
              options: {
                required: false,
                dataKey: 'medicalHistory.conditions.steroidMedication',
                label: 'Steroid medication',
              },
            },
          ],
          options: { direction: 'column' },
        },
      ],
      options: { numberOfColumns: 3 },
      uid: 'df967e63-67bc-41b2-8273-942a8b35bbd9',
      type: 'section',
    },
    {
      options: { numberOfColumns: 2 },
      children: [
        {
          dropListCategory: 'field',
          children: [
            {
              uid: 'f8d19458-a92a-43d7-adc7-ec9c377a8773',
              children: [],
              options: {
                required: false,
                label: 'I have a gastrointestinal disorder',
                dataKey:
                  'medicalHistory.conditions.hasGastrointestinalDisorder',
              },
              type: 'checkbox',
            },
          ],
          type: 'container',
          uid: '782016f3-842b-4d6c-98f7-d41477ef03cc',
          options: { direction: 'column' },
        },
        {
          uid: '0d3e3238-d5ef-456b-b076-986d23c2144d',
          options: { direction: 'column' },
          children: [
            {
              uid: 'a877e8f0-f676-444f-a590-819ca0a93685',
              children: [],
              options: {
                required: false,
                label: 'Gastrointestinal disorder',
                dataKey: 'medicalHistory.conditions.gastrointestinalDisorder',
              },
              type: 'text',
            },
          ],
          dropListCategory: 'field',
          type: 'container',
        },
      ],
      uid: '8dd1e914-9db3-498d-b386-540045b13fa7',
      type: 'section',
    },
    {
      uid: 'e0ccb86e-1fba-443c-aad1-e0bfd6f4a216',
      options: { numberOfColumns: 2 },
      children: [
        {
          options: { direction: 'column' },
          dropListCategory: 'field',
          type: 'container',
          children: [
            {
              type: 'checkbox',
              options: {
                dataKey: 'medicalHistory.conditions.isSmoker',
                label: 'Past or Current smoker',
                required: false,
              },
              uid: '2850fdfe-284f-43b9-bb66-35e9d9452f20',
              children: [],
            },
          ],
          uid: '9079196f-0d22-4524-9c50-2fb154e7ca06',
        },
        {
          uid: '4b3c8ead-e428-4717-b91c-c0b3da4dbf8f',
          dropListCategory: 'field',
          options: { direction: 'column' },
          children: [
            {
              type: 'text',
              uid: 'c85115d9-41fd-4162-b2d9-b09459f664ff',
              children: [],
              options: {
                label: 'Approx. amount currently smoked',
                required: false,
                dataKey: 'medicalHistory.conditions.amountSmoked',
              },
            },
          ],
          type: 'container',
        },
      ],
      type: 'section',
    },
    {
      uid: 'db5ce534-afbd-40d1-bc29-29f462e40592',
      type: 'section',
      children: [
        {
          type: 'container',
          options: { direction: 'column' },
          children: [
            {
              options: {
                label: 'I have had a joint replacement',
                required: false,
                dataKey: 'medicalHistory.conditions.hasJointReplacement',
              },
              children: [],
              type: 'checkbox',
              uid: 'e918d9e5-a6d1-45f1-96aa-46bb43fc6742',
            },
          ],
          dropListCategory: 'field',
          uid: 'a9996adf-9e6e-4c70-a6c5-34b1e9af2812',
        },
        {
          dropListCategory: 'field',
          children: [
            {
              options: {
                dataKey: 'medicalHistory.conditions.replacedJoint',
                required: false,
                label: 'Joint replaced',
              },
              uid: 'ffb4350e-eca6-4a06-9bb9-50fc017c680a',
              children: [],
              type: 'text',
            },
          ],
          uid: '703a3847-3582-4101-951e-87d587e04caf',
          type: 'container',
          options: { direction: 'column' },
        },
      ],
      options: { numberOfColumns: 2 },
    },
    {
      type: 'section',
      children: [
        {
          uid: '1fff9992-57b4-4664-9b2b-47ccaba4b51d',
          options: { direction: 'column' },
          children: [
            {
              children: [],
              uid: 'cd872fec-90a9-42a9-9731-e24b6ce59d71',
              options: {
                dataKey: 'medicalHistory.conditions.hasPsychiatricDisorder',
                label: 'I have a psychiatric disorder',
                required: false,
              },
              type: 'checkbox',
            },
          ],
          dropListCategory: 'field',
          type: 'container',
        },
        {
          children: [
            {
              uid: 'a6b0bbf9-3d89-4d67-b9e5-f4536b2595f0',
              options: {
                dataKey: 'medicalHistory.conditions.psychiatricDisorderType',
                required: false,
                label: 'Psychiatric disorder',
              },
              children: [],
              type: 'text',
            },
          ],
          uid: 'c9ff6f44-c463-4c07-a2cd-04fa5e581d77',
          type: 'container',
          dropListCategory: 'field',
          options: { direction: 'column' },
        },
      ],
      options: { numberOfColumns: 2 },
      uid: 'e1cef28f-afb0-4eba-8cb7-1bd35bf91ed5',
    },
    {
      options: { numberOfColumns: 2 },
      uid: '8879ca7e-1ac3-44a3-a06b-4a1b058af94a',
      children: [
        {
          uid: 'a79bcafa-f239-4744-8304-72e251559145',
          dropListCategory: 'field',
          type: 'container',
          options: { direction: 'column' },
          children: [
            {
              type: 'checkbox',
              options: {
                label: 'I have cancer',
                dataKey: 'medicalHistory.conditions.hasCancer',
                required: false,
              },
              children: [],
              uid: 'bc64ab91-ec46-423e-ac9f-a40aaeee41be',
            },
            {
              options: {
                label: 'I have had/ will have Chemotherapy',
                required: false,
                dataKey:
                  'medicalHistory.conditions.cancerTreatmentChemotherapy',
              },
              uid: 'c4e1e0bb-16a3-4b14-b596-25be201a4942',
              children: [],
              type: 'checkbox',
            },
          ],
        },
        {
          dropListCategory: 'field',
          options: { direction: 'column' },
          uid: 'a4327d3c-0fe2-40dd-a0bc-961e70be88ec',
          type: 'container',
          children: [
            {
              options: {
                required: false,
                label: 'Cancer type',
                dataKey: 'medicalHistory.conditions.cancerType',
              },
              type: 'text',
              uid: '8a288caa-6bb5-4ec3-a688-7237a534b8bc',
              children: [],
            },
            {
              options: {
                dataKey:
                  'medicalHistory.conditions.cancerTreatmentRadiotherapy',
                label: 'I have had/ will have Radiotherapy',
                required: false,
              },
              uid: 'bcdd5567-d6be-493a-a209-0dfcd760532c',
              type: 'checkbox',
              children: [],
            },
          ],
        },
      ],
      type: 'section',
    },
    {
      options: { numberOfColumns: 2 },
      type: 'section',
      children: [
        {
          type: 'container',
          children: [
            {
              type: 'checkbox',
              uid: '4b98acdf-4d5f-480b-a05a-67158bd628a3',
              children: [],
              options: {
                required: false,
                label: 'I am pregnant',
                dataKey: 'medicalHistory.conditions.isPregnant',
              },
            },
          ],
          dropListCategory: 'field',
          options: { direction: 'column' },
          uid: '684fe1c5-97b9-4c82-85d5-3642fae38846',
        },
        {
          options: { direction: 'column' },
          dropListCategory: 'field',
          children: [
            {
              children: [],
              uid: 'f1d4dc76-4187-4228-a1ea-6f7c784e9557',
              type: 'date',
              options: {
                label: 'Due Date',
                required: false,
                dataKey: 'medicalHistory.conditions.pregnancyDueDate',
              },
            },
          ],
          type: 'container',
          uid: 'f661e89b-9ac8-43a6-ba91-ec68399a344e',
        },
      ],
      uid: '2e4b70b2-1fdb-4488-aabe-577a830ef02b',
    },
    {
      children: [
        {
          type: 'container',
          options: { direction: 'column' },
          dropListCategory: 'field',
          children: [
            {
              options: {
                options: ['N/A', 'High', 'Low'],
                dataKey: 'medicalHistory.conditions.bloodPressure',
                label: 'Blood pressure',
                required: true,
              },
              children: [],
              uid: '19cf3f1e-3a58-443b-9e50-3995b8cfb516',
              type: 'dropdown',
            },
            {
              uid: '00c5feee-b221-4778-bd28-0d2b87329637',
              options: {
                label: 'Hepatitis',
                dataKey: 'medicalHistory.conditions.hepatitis',
                options: ['N/A', 'A', 'B', 'C', 'D'],
                required: true,
              },
              type: 'dropdown',
              children: [],
            },
          ],
          uid: '4ebb8796-cc1b-4e1b-8095-add37ede2dd0',
        },
        {
          children: [
            {
              uid: '64710a03-80f1-429c-bbb6-9382079f1a05',
              children: [],
              options: {
                dataKey: 'medicalHistory.conditions.diabetes',
                options: ['N/A', 'Type I', 'Type II'],
                required: true,
                label: 'Diabetes',
              },
              type: 'dropdown',
            },
            {
              options: {
                dataKey: 'medicalHistory.conditions.other',
                label: 'Any other medical conditions or major surgeries?',
                required: false,
              },
              uid: '7774d2a3-4022-4ae4-a07c-5e0b9bda6fb8',
              type: 'text',
              children: [],
            },
          ],
          type: 'container',
          uid: 'f3472b5f-153d-4972-854f-49125767dd43',
          dropListCategory: 'field',
          options: { direction: 'column' },
        },
      ],
      uid: '13c18065-174b-4d5a-8fc4-20d4468ec148',
      type: 'section',
      options: { numberOfColumns: 2 },
    },
    {
      type: 'section',
      children: [
        {
          options: { direction: 'column' },
          children: [
            {
              type: 'textarea',
              children: [],
              uid: '1a2fc966-6ee7-461b-a344-a28a036261a1',
              options: {
                label: 'Medications?',
                dataKey: 'medicalHistory.medications',
                required: false,
              },
            },
          ],
          uid: '5093e7ba-9232-43cb-8a6b-496dedfb4d0f',
          type: 'container',
          dropListCategory: 'field',
        },
      ],
      options: { numberOfColumns: 1 },
      uid: '35e3a944-5523-4dbc-84ff-1e7072c3ba2b',
    },
    {
      type: 'section',
      uid: 'e2b07879-5b0b-426f-be75-9800d2ad8c33',
      options: { title: 'Allergies?', numberOfColumns: 2 },
      children: [
        {
          children: [
            {
              type: 'checkbox',
              children: [],
              uid: '4a86d03b-25aa-4c78-9e61-4c43340ff121',
              options: {
                label: 'Penicillin',
                dataKey: 'medicalHistory.allergies.penicillin',
                required: false,
              },
            },
            {
              children: [],
              type: 'checkbox',
              uid: '49dfbd10-2ed4-44fa-a456-42a609863610',
              options: {
                required: false,
                label: 'Codeine',
                dataKey: 'medicalHistory.allergies.codeine',
              },
            },
            {
              children: [],
              options: {
                required: false,
                dataKey: 'medicalHistory.allergies.iodine',
                label: 'Iodine',
              },
              uid: '0639f91b-1975-4ffc-8679-6cf8327ca537',
              type: 'checkbox',
            },
          ],
          type: 'container',
          dropListCategory: 'field',
          options: { direction: 'column' },
          uid: '8c6fa13d-b315-4f10-b8be-f0d4301f6e29',
        },
        {
          type: 'container',
          dropListCategory: 'field',
          children: [
            {
              type: 'checkbox',
              uid: '51135010-05ec-4b39-8028-6fa8cc86d148',
              options: {
                label: 'Aspirin',
                required: false,
                dataKey: 'medicalHistory.allergies.aspirin',
              },
              children: [],
            },
            {
              options: {
                required: false,
                dataKey: 'medicalHistory.allergies.chlorhexidine',
                label: 'Chlorhexidine',
              },
              children: [],
              uid: 'caa4d011-0ef1-42c4-98f7-41501a07181b',
              type: 'checkbox',
            },
            {
              uid: 'bb9659ae-1edd-4819-a736-3946ea2f2b10',
              type: 'checkbox',
              children: [],
              options: {
                required: false,
                label: 'Latex',
                dataKey: 'medicalHistory.allergies.latex',
              },
            },
          ],
          options: { direction: 'column' },
          uid: '1c9fe518-56ee-4d39-ab76-ea20c7528ec0',
        },
      ],
    },
    {
      uid: 'addec53d-fff3-4500-9433-6da0729815df',
      type: 'section',
      children: [
        {
          dropListCategory: 'field',
          uid: '3949ddf7-1119-41b4-b847-c42769400b4e',
          type: 'container',
          options: { direction: 'column' },
          children: [
            {
              options: {
                label: 'Other',
                required: false,
                dataKey: 'medicalHistory.allergies.other',
              },
              children: [],
              type: 'text',
              uid: 'bcc543f3-55ef-477a-b946-4dc066d1cb84',
            },
          ],
        },
      ],
      options: { numberOfColumns: 1 },
    },
    {
      uid: 'd5cfbbb1-3720-46de-b820-ca5b40ce2fb2',
      children: [
        {
          type: 'container',
          uid: '212fb419-0eeb-4b7f-bdcf-d914904fab18',
          children: [
            {
              options: {
                required: false,
                dataKey: 'medicalHistory.emergencyContact.name',
                label: 'Name',
              },
              children: [],
              uid: '7d117cb3-09b6-417a-8407-03a41a8b8a12',
              type: 'text',
            },
          ],
          dropListCategory: 'field',
          options: { direction: 'column' },
        },
        {
          options: { direction: 'column' },
          uid: '7a49e084-55d3-4a1e-9dd0-7e2affed4cf0',
          type: 'container',
          dropListCategory: 'field',
          children: [
            {
              uid: '9a1eda1b-b5fd-4570-9f53-7b163d33a450',
              type: 'text',
              children: [],
              options: {
                label: 'Phone',
                dataKey: 'medicalHistory.emergencyContact.phone',
                required: false,
              },
            },
          ],
        },
      ],
      options: { numberOfColumns: 2, title: 'Emergency Contact' },
      type: 'section',
    },
    {
      options: { numberOfColumns: 1, title: 'Dental History & Concerns' },
      type: 'section',
      uid: 'bb970ea6-b61b-405a-847d-266aa7473217',
      children: [
        {
          children: [
            {
              options: {
                label: 'Reason for attending or any specific concerns',
                required: false,
                dataKey: 'dentalHistory.reason',
              },
              type: 'textarea',
              children: [],
              uid: '86bbdc3f-42d7-4fe6-8485-e4a0f8f577ef',
            },
            {
              uid: '42db18b9-4d71-412e-99e7-95608ffb65ab',
              type: 'date',
              children: [],
              options: {
                required: false,
                label: 'Last Dental Visit',
                dataKey: 'dentalHistory.lastVisit',
              },
            },
          ],
          type: 'container',
          uid: '07eda507-7241-477b-9f8b-a9f77dfaadc0',
          dropListCategory: 'field',
          options: { direction: 'column' },
        },
      ],
    },
    {
      type: 'section',
      options: { numberOfColumns: 2 },
      uid: '7bfee011-f637-4996-a09e-afb250391a2c',
      children: [
        {
          options: { direction: 'column' },
          type: 'container',
          dropListCategory: 'field',
          uid: '4552927b-c878-4744-808f-43a67ddcb0db',
          children: [
            {
              type: 'checkbox',
              uid: '649bcb71-b5c8-4044-a1d6-c1cff6dc62fd',
              options: {
                required: false,
                label:
                  'Do you need to take antibiotics before any dental treatment?',
                dataKey: 'dentalHistory.antibioticsBeforeTreatment',
              },
              children: [],
            },
          ],
        },
        {
          options: { direction: 'column' },
          children: [
            {
              uid: 'ad3a9bb7-033c-4d61-b170-9eec5a83e91a',
              children: [],
              options: {
                label:
                  'Do you generally feel anxious about visiting the dentist or hygienist?',
                dataKey: 'dentalHistory.dentalAnxiety',
                required: false,
              },
              type: 'checkbox',
            },
          ],
          dropListCategory: 'field',
          type: 'container',
          uid: 'f3e7ae76-7ea2-4840-9d81-de5e02792993',
        },
      ],
    },
    {
      uid: '279b0d38-cd89-4ce7-a854-eb6e2e0a472a',
      children: [
        {
          uid: '3741f638-ddd7-4206-873b-6c567a29ac37',
          type: 'container',
          children: [
            {
              type: 'checkbox',
              uid: 'f4b5e5b7-acaf-4f85-b882-594b883b34ba',
              options: {
                label: 'Toothache / pain',
                required: false,
                dataKey: 'dentalHistory.conditions.toothache',
              },
              children: [],
            },
            {
              uid: '79b10f79-abfb-41ec-9b83-f1bd680e668b',
              children: [],
              type: 'checkbox',
              options: {
                label: 'Crooked / crowded teeth',
                dataKey: 'dentalHistory.conditions.crowding',
                required: false,
              },
            },
            {
              type: 'checkbox',
              options: {
                required: false,
                dataKey: 'dentalHistory.conditions.brokenOrFilling',
                label: 'Broken teeth or filling',
              },
              uid: '6103fa0a-bcfc-4a8e-83ae-90e826a937d0',
              children: [],
            },
            {
              type: 'checkbox',
              children: [],
              uid: '4f6932f1-6bef-47a9-99df-2363c5bc3698',
              options: {
                label: 'Cold sensitivity',
                required: false,
                dataKey: 'dentalHistory.conditions.sensitivity',
              },
            },
            {
              type: 'checkbox',
              children: [],
              uid: 'af1ec545-1047-486a-b723-554ae0a8fd0a',
              options: {
                dataKey: 'dentalHistory.conditions.halitosis',
                label: 'Bad breath',
                required: false,
              },
            },
            {
              children: [],
              options: {
                label: 'Jaw / muscle pain',
                dataKey: 'dentalHistory.conditions.musclePain',
                required: false,
              },
              type: 'checkbox',
              uid: 'daa38687-2935-4255-adfb-352e6f97798b',
            },
          ],
          options: { direction: 'column' },
          dropListCategory: 'field',
        },
        {
          children: [
            {
              children: [],
              options: {
                dataKey: 'dentalHistory.conditions.discolouring',
                label: 'Dark or discoloured teeth',
                required: false,
              },
              type: 'checkbox',
              uid: '5f861fec-3799-4978-9caa-e31536ffe292',
            },
            {
              options: {
                required: false,
                dataKey: 'dentalHistory.conditions.decay',
                label: 'Decay / cavities',
              },
              uid: 'd55ba411-c0d7-4941-a3da-d8b6b7170d4b',
              children: [],
              type: 'checkbox',
            },
            {
              children: [],
              options: {
                required: false,
                dataKey: 'dentalHistory.conditions.worn',
                label: 'Worn down teeth',
              },
              type: 'checkbox',
              uid: '4abd805e-d13c-4763-a528-6a5a418f675c',
            },
            {
              options: {
                label: 'Bleeding gums',
                required: false,
                dataKey: 'dentalHistory.conditions.bleeding',
              },
              uid: 'ef4603f1-54d5-464e-8813-4b1a38b6bfa2',
              children: [],
              type: 'checkbox',
            },
            {
              children: [],
              uid: '6850ffa5-71b7-4de6-8674-a3a856c73978',
              options: {
                required: false,
                label: 'Clenching or grinding',
                dataKey: 'dentalHistory.conditions.grinding',
              },
              type: 'checkbox',
            },
            {
              children: [],
              uid: '20477d40-f42f-4112-99a9-e0b33bcdb7ff',
              options: {
                required: false,
                dataKey: 'dentalHistory.conditions.ulcers',
                label: 'Mouth Ulcers',
              },
              type: 'checkbox',
            },
          ],
          uid: 'd2a0e9cd-e328-4385-b0c2-576bef2fe0fb',
          type: 'container',
          options: { direction: 'column' },
          dropListCategory: 'field',
        },
      ],
      type: 'section',
      options: {
        numberOfColumns: 2,
        title: 'Please tick if any of the following apply to you',
      },
    },
    {
      type: 'section',
      uid: '2b02c99f-b833-40da-83e4-314ca011717e',
      children: [
        {
          type: 'container',
          children: [
            {
              options: {
                required: false,
                label: 'Teeth Whitening',
                dataKey: 'dentalHistory.interestedIn.whitening',
              },
              type: 'checkbox',
              children: [],
              uid: '1965ad4d-20dc-4351-b57c-654b62f97081',
            },
            {
              type: 'checkbox',
              uid: '567d82ae-ded0-4a36-9f3c-793d80565c70',
              options: {
                required: false,
                label: 'Invisalign or teeth straightening',
                dataKey: 'dentalHistory.interestedIn.straightening',
              },
              children: [],
            },
            {
              options: {
                label: 'Wisdom teeth removal',
                dataKey: 'dentalHistory.interestedIn.wisdomRemoval',
                required: false,
              },
              children: [],
              uid: '2965c63b-a52f-4c1f-8bb4-51c68bdf3e84',
              type: 'checkbox',
            },
            {
              children: [],
              options: {
                required: false,
                label: 'Wrinkle relaxers (eg, botox, dysport)',
                dataKey: 'dentalHistory.interestedIn.wrinkleRelaxers',
              },
              uid: '783394f0-730b-4b24-abae-fee2a3c6ceb8',
              type: 'checkbox',
            },
            {
              options: {
                label:
                  'Dental anxiety management options (eg, sedation, happy gas, etc)',
                required: false,
                dataKey: 'dentalHistory.interestedIn.dentalAnxietyManagement',
              },
              children: [],
              uid: '8df9c661-b0cd-4e74-bdbc-2c9519246353',
              type: 'checkbox',
            },
          ],
          uid: 'ad6f22bf-0026-4073-b78d-d4d9db1d2af9',
          dropListCategory: 'field',
          options: { direction: 'column' },
        },
        {
          dropListCategory: 'field',
          options: { direction: 'column' },
          uid: '955d3085-e613-4118-ad72-2057f18e95e1',
          type: 'container',
          children: [
            {
              uid: '7c5bc300-0aea-4282-b817-cd32fdeefdf4',
              type: 'checkbox',
              options: {
                required: false,
                label: 'Dental Implants for missing teeth',
                dataKey: 'dentalHistory.interestedIn.implants',
              },
              children: [],
            },
            {
              children: [],
              options: {
                dataKey: 'dentalHistory.interestedIn.veneers',
                required: false,
                label: 'Veneers or cosmetic smile improvement',
              },
              uid: 'd620420d-caf9-4c95-b078-3264bda3d19e',
              type: 'checkbox',
            },
            {
              children: [],
              options: {
                required: false,
                label: 'Anti-snoring and sleep apnoea treatment',
                dataKey: 'dentalHistory.interestedIn.sleepApnoea',
              },
              uid: '8fcb4339-d4c6-4c74-9f02-debaeff3dcf5',
              type: 'checkbox',
            },
            {
              uid: '4b5f664e-df47-4a8c-a3c1-c3f31285df66',
              options: {
                dataKey: 'dentalHistory.interestedIn.fillers',
                label: 'Cosmetic dermal fillers (eg, lips, cheeks)',
                required: false,
              },
              children: [],
              type: 'checkbox',
            },
          ],
        },
      ],
      options: {
        numberOfColumns: 2,
        title: 'Would you like more information on the following services?',
      },
    },
    {
      options: { numberOfColumns: 1 },
      type: 'section',
      uid: 'c5218965-a825-4838-9463-b76b826cb11e',
      children: [
        {
          children: [
            {
              uid: '78bc822a-ff98-409e-9e92-7b4122a86e6f',
              children: [],
              options: {
                dataKey: 'dentalHistory.more',
                label: `Is there anything else about previous dental experiences you'd like us to know?`,
                required: false,
              },
              type: 'textarea',
            },
          ],
          uid: '1ab1ccc9-57bd-4ff2-821a-396538678337',
          dropListCategory: 'field',
          options: { direction: 'column' },
          type: 'container',
        },
      ],
    },
    {
      type: 'section',
      options: { numberOfColumns: 1, title: 'Consent & Signature' },
      children: [
        {
          dropListCategory: 'field',
          options: { direction: 'column' },
          children: [
            {
              type: 'content',
              uid: 'f34ded24-9899-4f00-b667-312d42a8222c',
              options: {
                content: {
                  content: {
                    type: 'doc',
                    content: [
                      {
                        attrs: { align: 'left' },
                        type: 'paragraph',
                        content: [
                          {
                            text: 'All personal information collected is confidential and handled in accordance to our privacy policy. See ',
                            type: 'text',
                          },
                          {
                            type: 'link',
                            attrs: {
                              href: 'https://principle.dental/legal/privacy-policy',
                              target: '_blank',
                              rel: 'noopener noreferrer nofollow',
                            },
                            content: [
                              {
                                type: 'text',
                                text: 'principle.dental/legal/privacy-policy',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        content: [
                          {
                            text: 'By signing this form, you acknowledge and agree that: a) you have accurately completed this pre-clinical questionnaire to the best of your knowledge b) you consent to treatment agreed upon by you to be carried out by the dentist and their staff c) you assume full financial responsibility for treatment and d) payment is due in full at the time of service unless other arrangements have been made.',
                            type: 'text',
                          },
                        ],
                        attrs: { align: 'left' },
                        type: 'paragraph',
                      },
                      {
                        content: [
                          {
                            text: 'Cancellation policy: 48hrs notice is required for cancelling or rebooking appointments, otherwise it may incur a cancellation fee or refusal to reschedule.',
                            type: 'text',
                          },
                        ],
                        type: 'paragraph',
                        attrs: { align: 'left' },
                      },
                    ],
                  },
                  migrations: { migrations: [] },
                },
                editableOnIssue: false,
              },
              children: [],
            },
            {
              children: [],
              options: {
                label: 'I agree to the terms and conditions',
                required: false,
                dataKey: 'termsAndConditions.agreed',
              },
              uid: 'ed3e517c-5889-4808-9696-8dc413dc3b62',
              type: 'checkbox',
            },
          ],
          uid: 'a38bd7f1-d1a1-4087-9ae1-19dd2bb8958b',
          type: 'container',
        },
      ],
      uid: '8a40cc8a-5abc-4ed5-ac29-f942ffb4981a',
    },
  ] as AnyCustomFormElement[];

export function toMedicalHistoryForm(
  config?: Partial<IJsonSchemaFormWithResolverConfig>
): IJsonSchemaFormWithResolverConfig {
  return {
    schema: config?.schema ?? BASIC_MEDICAL_HISTORY_FORM_SCHEMA,
    layout: config?.layout ?? BASIC_MEDICAL_HISTORY_FORM_LAYOUT,
    dataResolverConfig:
      config?.dataResolverConfig ??
      BASIC_MEDICAL_HISTORY_FORM_DATA_RESOLVER_CONFIG,
  };
}
