import { InjectionToken } from '@angular/core';
import {
  type IPosProductData,
  type TyroIClientUrl,
} from '@principle-theorem/tyro';
import { type Observable } from 'rxjs';

export interface ITyroConfig {
  apiKey: string;
  posProductData: IPosProductData;
  defaultIClient: TyroIClientUrl;
}

export interface ITyroConfigProvider extends ITyroConfig {
  getiClientBaseUrl$(): Observable<TyroIClientUrl>;
  addLog(message: string, data: object): void;
}

export const NG_TYRO_CONFIG_PROVIDER: InjectionToken<ITyroConfigProvider> =
  new InjectionToken('ITyroConfigProvider');
