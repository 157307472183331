<div
  *ngIf="
    treatmentPlansStatusAggregate$ | async as treatmentPlansStatusAggregate
  "
  fxLayout="column"
  fxLayoutGap="16px"
>
  <div class="flex items-center justify-end">
    <div class="flex items-center gap-4">
      <button mat-stroked-button color="primary" (click)="createPlan.emit()">
        Create New Plan
      </button>
      <pt-multi-filter-group
        tooltip="Filter by Status"
        [statusFilters]="statusFilters"
        [activeStatuses]="activeStatuses$ | async"
        (statusFilterChange)="setActiveStatus($event)"
       />
    </div>
  </div>

  <ng-container *ngFor="let status of planStatuses; trackBy: trackByStatus">
    <div *ngIf="treatmentPlansStatusAggregate[status]">
      <h3>{{ status | splitCamel | titlecase }}</h3>
      <ng-container
        [ngTemplateOutlet]="planList"
        [ngTemplateOutletContext]="{
          treatmentPlans: treatmentPlansStatusAggregate[status]
        }"
       />
    </div>
  </ng-container>
</div>

<ng-template #planList let-treatmentPlans="treatmentPlans">
  <mat-list>
    <mat-list-item
      *ngFor="let treatmentPlan of treatmentPlans; trackBy: trackByPlan"
      lines="3"
      class="mat-mdc-list-item-interactive"
      (click)="selectPlan.emit(treatmentPlan.plan)"
    >
      <div class="flex flex-row">
        <div class="title flex basis-5/12 flex-col">
          <div class="flex gap-1">
            <span class="mat-caption">Treatment Plan</span>
            <mat-chip-listbox>
              <mat-chip-option
                *ngIf="treatmentPlan.plan | map : isTodaysPlan : this"
                class="todays-appointment"
              >
                Todays Plan
              </mat-chip-option>
              <mat-chip-option
                *ngIf="treatmentPlan.plan | map : isSelectedPlan : this"
              >
                Selected Plan
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
          <div class="plan-name truncate">{{ treatmentPlan.plan.name }}</div>
        </div>

        <div class="creation-info basis-7/12">
          <div class="flex items-center justify-between gap-4">
            <div class="flex basis-3/12 flex-col">
              <span class="mat-caption">Created On</span>
              <div>
                {{
                  treatmentPlan.plan.createdAt
                    | moment
                    | amDateFormat : dateFormat
                }}
              </div>
            </div>
            <div
              *ngIf="treatmentPlan.plan.practitioner"
              class="flex basis-3/12 flex-col"
            >
              <span class="mat-caption">Practitioner</span>
              <div>{{ treatmentPlan.plan.practitioner.name }}</div>
            </div>
            <div class="flex basis-3/12 flex-col">
              <span class="mat-caption">Steps Completed</span>
              <div>{{ treatmentPlan | map : progress$ | ngrxPush }}</div>
            </div>
            <div class="flex basis-3/12 flex-col">
              <span class="mat-caption">Plan Total</span>
              <div>
                {{ treatmentPlan | map : price$ | ngrxPush | currency }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-list-item>
  </mat-list>
</ng-template>
