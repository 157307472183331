import d3Tip from 'd3-tip';
import type * as dc from 'dc';
import { get } from 'lodash';

export interface IDCTooltip {
  render(chart: dc.BaseMixin<unknown>): void;
}

export class DCTooltip implements IDCTooltip {
  constructor(
    private _hoverElementTarget: string,
    private _labelFn: (data: unknown) => string,
    private _valueFn: (data: unknown) => string
  ) {}

  render(chart: dc.BaseMixin<unknown>): void {
    const tip = d3Tip()
      .attr('class', 'd3-tip')
      .offset([-10, 0])
      .html((event: MouseEvent) => {
        const data: unknown = get(event.target, '__data__', {});
        const label = this._labelFn(data);
        const val = this._valueFn(data);
        return `<div class="tooltip">
          <div class="label">${label}</div>
          <div class="value">${val}</div>
        </div>`;
      });

    chart.on('pretransition.add-tip', (event) => {
      (event as dc.BaseMixin<unknown>)
        .selectAll(this._hoverElementTarget)
        .call(tip)
        .on('mouseover', tip.show)
        .on('mouseout', tip.hide);
    });
  }
}
