import {
  MixedSchema,
  initVersionedSchema,
  toParagraphContent,
} from '@principle-theorem/editor';

export interface ITemplate {
  template: MixedSchema;
}

export class Template implements ITemplate {
  constructor(public template: MixedSchema) {}

  render(): MixedSchema {
    return this.template;
  }
}

const offerTemplate = initVersionedSchema([
  toParagraphContent(`Hi Jacqui,`),
  toParagraphContent(`An earlier appointment has become available for the Tuesday the 17th of February @ 10am.
  If you would like you move your appointment ahead please reply with 'yes', otherwise please reply with 'no'.`),
  toParagraphContent(`Thanks,
  Gemma Duffy
  NSDP Team`),
]);

export function MockTemplate(templateString: MixedSchema): Template {
  return new Template(templateString);
}

export const OFFER_TEMPLATE: Template = MockTemplate(offerTemplate);
