import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IConfirmAppointmentInfo } from '@principle-theorem/principle-core/interfaces';
import {
  DATE_FORMAT,
  TIME_FORMAT,
  toMomentTz,
} from '@principle-theorem/shared';
import type moment from 'moment-timezone';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-patient-confirm-appointment-details',
  templateUrl: './patient-confirm-appointment-details.component.html',
  styleUrls: ['./patient-confirm-appointment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientConfirmAppointmentDetailsComponent {
  info$ = new ReplaySubject<IConfirmAppointmentInfo>(1);
  dateTimeFormat = `${DATE_FORMAT} @ ${TIME_FORMAT}`;
  appointmentStartAt$: Observable<moment.Moment>;

  @Input()
  set info(info: IConfirmAppointmentInfo) {
    if (info) {
      this.info$.next(info);
    }
  }

  constructor() {
    this.appointmentStartAt$ = this.info$.pipe(
      map((info) => toMomentTz(info.startAt, info.practiceTimezone))
    );
  }
}
