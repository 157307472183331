<svg:g>
  <svg:rect
    [ngClass]="{ disabled: disabled$ | async }"
    [prSVGTransform]="selector$ | async"
    [ptRightClickMenu]="menu"
  />

  <svg:text class="area-label no-events" [prSVGTransform]="label$ | async">
    {{ labelText$ | async }}
  </svg:text>

  <ng-container *ngIf="badge$ | async as badge">
    <svg:g
      *ngIf="badge"
      prChartedItemsBadge
      [prSVGTransform]="indicator$ | async"
      [count]="badge"
    />
  </ng-container>

  <foreignObject>
    <pr-chart-quadrant-menu
      #menu="prChartQuadrantMenu"
      [quadrant]="(quadrant$ | async)?.quadrant"
     />
  </foreignObject>
</svg:g>
