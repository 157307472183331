import { roundTo2Decimals } from '@principle-theorem/accounting';
import {
  HicapsConnect,
  IPMSHicapsConnectConfig,
} from '@principle-theorem/hicaps-connect';
import { IHicapsConnectTerminal } from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { ITransactionAmountDialogResult } from '../../transaction-components/transaction-amount-dialog/transaction-amount-dialog.component';
import { HicapsConnectHelpers } from '../hicaps-connect-helpers';

export class HicapsConnectEftposRequestBuilder {
  static buildSale(
    config: IPMSHicapsConnectConfig,
    formData: ITransactionAmountDialogResult,
    terminal: WithRef<IHicapsConnectTerminal>
  ): HicapsConnect.SaleRequest {
    return {
      ...HicapsConnectHelpers.buildBaseRequest(config, terminal),
      MerchantId: terminal.merchantId,
      TransactionAmount: roundTo2Decimals(formData.amount),
    };
  }

  static buildRefund(
    config: IPMSHicapsConnectConfig,
    formData: ITransactionAmountDialogResult,
    terminal: WithRef<IHicapsConnectTerminal>
  ): HicapsConnect.RefundRequest {
    return {
      ...HicapsConnectHelpers.buildBaseRequest(config, terminal),
      MerchantId: terminal.merchantId,
      TransactionAmount: roundTo2Decimals(formData.amount),
    };
  }
}
