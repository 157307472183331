import {
  Component,
  ViewEncapsulation,
  ContentChild,
  Input,
} from '@angular/core';
import { ProfileCardTitleComponent } from './profile-card-title/profile-card-title.component';
import { ProfileCardHeaderComponent } from './profile-card-header/profile-card-header.component';
import { ProfilePicComponent } from '@principle-theorem/ng-shared';

@Component({
  selector: 'pr-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileCardComponent {
  @ContentChild(ProfileCardTitleComponent)
  title: ProfileCardTitleComponent;
  @ContentChild(ProfileCardHeaderComponent)
  header: ProfileCardHeaderComponent;
  @ContentChild(ProfilePicComponent)
  profileImageURL: ProfilePicComponent;
  @Input() hasActions = true;
}
