<div *ngIf="expanded">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h3>{{ practiceName$ | async }}</h3>
    <button
      mat-icon-button
      ptElementTarget="sidebar-practice-selector-button"
      *ngIf="organisation.hasMultiplePractices$ | async"
      (click)="openChangePractice()"
      matTooltip="Change Practice"
    >
      <mat-icon>read_more</mat-icon>
    </button>
  </div>
</div>
