<pt-buttons-container>
  <pt-button-with-menu
    color="primary"
    type="flat"
    label="Add From Template"
    (buttonClick)="addFromTemplate()"
  >
    <button mat-menu-item (click)="addTask()">Add Task</button>
    <button mat-menu-item (click)="addNotification()">Add Notification</button>
  </pt-button-with-menu>
</pt-buttons-container>
<ng-container *ngIf="automationGroups$ | async as automationGroups">
  <ng-container
    *ngIf="
      automationGroups.notifications.length || automationGroups.tasks.length;
      else noAutomations
    "
  >
    <ng-container *ngIf="automationGroups.tasks.length">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h3>Tasks</h3>
      </div>
      <ng-container
        [ngTemplateOutlet]="automationListTemplate"
        [ngTemplateOutletContext]="{ automations: automationGroups.tasks }"
       />
    </ng-container>
    <ng-container *ngIf="automationGroups.notifications.length">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h3>Notifications</h3>
      </div>
      <ng-container
        [ngTemplateOutlet]="automationListTemplate"
        [ngTemplateOutletContext]="{
          automations: automationGroups.notifications
        }"
       />
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #automationListTemplate let-automations="automations">
  <pr-automation-list-display
    [automations]="automations"
    (editAutomation)="edit($event)"
    (cancelAutomation)="cancel($event)"
    (restoreAutomation)="restore($event)"
    (resendAutomation)="resend($event)"
   />
</ng-template>

<ng-template #noAutomations>
  <div fxLayoutAlign="center stretch">
    <h2 class="no-automations mat-headline-5">No Automations</h2>
  </div>
</ng-template>
