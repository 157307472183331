import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  type IAccountCreditReportRecord,
  type IInvoiceReportRequest,
} from '@principle-theorem/reporting';
import { toTimePeriod, type ITimePeriod } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ReportingFunctions } from '../../core/reporting-functions';

export interface IAccountCreditsReportState {
  dateRange: ITimePeriod;
  records: IAccountCreditReportRecord[];
  loading: boolean;
}

@Injectable()
export class AccountCreditsReportStore extends ComponentStore<IAccountCreditsReportState> {
  readonly loading$ = this.select((data) => data.loading);
  readonly records$ = this.select((data) => data.records);
  readonly dateRange$ = this.select((data) => data.dateRange);

  readonly loadAccountCredits = this.effect(
    (query$: Observable<IInvoiceReportRequest>) =>
      query$.pipe(
        tap((request) =>
          this.setState({
            records: [],
            loading: true,
            dateRange: toTimePeriod(request.startDate, request.endDate),
          })
        ),
        switchMap((query) => ReportingFunctions.getAccountCredits(query)),
        tap((records) =>
          this.patchState({
            records,
            loading: false,
          })
        )
      )
  );
}
