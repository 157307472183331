import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ReportingCoreModule } from '../../core/reporting-core.module';
import { ReportingComponentsModule } from '../../reporting-components/reporting-components.module';
import { OutstandingInvoicesReportComponent } from './outstanding-invoices-report/outstanding-invoices-report.component';
import { OutstandingInvoicesReportingComponent } from './outstanding-invoices-reporting.component';
import { OutstandingInvoicesReportEffects } from './store/outstanding-invoices.effects';
import { OutstandingInvoicesReportFacade } from './store/outstanding-invoices.facade';
import {
  metaReducers,
  OUTSTANDING_INVOICES_REPORT_FEATURE_KEY,
  reducers,
} from './store/outstanding-invoices.reducers';
import { InvoiceReportTableComponent } from './invoice-report-table/invoice-report-table.component';

@NgModule({
  imports: [
    RouterModule,
    ReportingCoreModule,
    ReportingComponentsModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    StoreModule.forFeature(OUTSTANDING_INVOICES_REPORT_FEATURE_KEY, reducers, {
      metaReducers,
    }),
    EffectsModule.forFeature([OutstandingInvoicesReportEffects]),
  ],
  declarations: [
    OutstandingInvoicesReportingComponent,
    OutstandingInvoicesReportComponent,
    InvoiceReportTableComponent,
  ],
  providers: [OutstandingInvoicesReportFacade],
})
export class OutstandingInvoicesReportingModule {}
