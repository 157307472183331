import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IImageNodePreviewDialogData {
  url: string;
}

@Component({
  selector: 'pt-image-node-preview-dialog',
  templateUrl: './image-node-preview-dialog.component.html',
  styleUrl: './image-node-preview-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageNodePreviewDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IImageNodePreviewDialogData
  ) {}
}
