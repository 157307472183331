<div class="layout-padding">
  <div
    *ngIf="state.isLoading$ | async"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-spinner />
    <p class="mat-caption">Loading Form</p>
  </div>

  <div
    *ngIf="state.isNotAvailable$ | async"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <pr-empty-state
      image="broken"
      title="form found"
      [descriptionEnabled]="false"
    />
  </div>

  <div
    *ngIf="state.isAlreadySubmitted$ | async"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <h1 class="mat-headline-4">Form already submitted</h1>
    <div>Thanks, we have already received your information.</div>
  </div>

  <div *ngIf="data$ | async as data" fxLayout="column">
    <ng-container *ngIf="state.isAwaitingConfirmation$ | async">
      <div class="mat-toolbar mat-toolbar-single-row">
        Patient Details: {{ data.tokenData?.patientName }}
      </div>
      <pr-patient-details-form
        [referralSources]="referralSources$ | async"
        [requiredFields]="requiredFields$ | async"
        (patientDetailsChange)="patientDetailsFormData$.next($event)"
        class="layout-padding"
      />
      <div class="mat-toolbar mat-toolbar-single-row">
        Medical History: {{ data.tokenData?.patientName }}
      </div>
      <pr-custom-form
        [form]="data.customForm"
        [hideSubmit]="true"
        (isValid)="customFormIsValid$.next($event)"
        (formChanges)="customFormState$.next($event)"
      />
      <div class="flex">
        <button
          mat-flat-button
          color="primary"
          [disabled]="isDisabled$ | async"
          (click)="submit()"
        >
          Submit
        </button>
      </div>
    </ng-container>
  </div>

  <div
    *ngIf="state.isSubmitting$ | async"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <mat-spinner />
    <p class="mat-body-2">Saving...</p>
  </div>

  <div
    *ngIf="state.isConfirmed$ | async"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <h1 class="mat-headline-4">Save Successful!</h1>
    <div>Thank you</div>
  </div>
</div>
