<mat-toolbar color="accent">Paste your key from Principle Bridge</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field>
      <mat-label>Enter Public Key</mat-label>
      <textarea
        fxFlex
        matInput
        rows="10"
        formControlName="publicKey"
        placeholder="-----BEGIN CERTIFICATE-----"
      ></textarea>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button type="submit" mat-flat-button color="primary">
        Add Public Key
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
