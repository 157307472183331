import { IScheduleSummaryDimension } from '@principle-theorem/reporting/interfaces';
import {
  ISO_DATE_FORMAT,
  MockGenerator,
  toSerialisedTimestamp,
  toTimestamp,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import {
  BigQueryFirestoreModelMock,
  SerialisedDocumentReferenceMock,
} from './common.mock';

export class ScheduleSummaryDimensionMock
  extends BigQueryFirestoreModelMock
  implements IScheduleSummaryDimension
{
  events = [];
  timestamp = toSerialisedTimestamp(toTimestamp());
  staffer = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practice = MockGenerator.generate(SerialisedDocumentReferenceMock);
  day = moment().format(ISO_DATE_FORMAT);
}
