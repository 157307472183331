import {
  Arch,
  ChartableSurface,
  IChartableItem,
  IChartedItem,
  IChartedSurface,
  IChartedTooth,
  IScopeRef,
  IToothRef,
  Quadrant,
  ScopedSurface,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { ChartedSurface } from './charted-surface';
import { isUpperQuadrant } from './quadrant';
import { SurfaceHierarchy } from './surface-hierarchy/surface-hierarchy';
import { ToothHierarchy } from './surface-hierarchy/tooth-hierarchy';

export class ChartedItemScopeResolver {
  determineScope(
    chartableItem: IChartableItem,
    chartedSurfaces: IChartedSurface[]
  ): ScopedSurface {
    const hierarchy = new ToothHierarchy();
    return (
      this._determineScope(chartableItem, hierarchy, chartedSurfaces) ||
      ChartableSurface.WholeTooth
    );
  }

  getScopeRef(
    chartableItem: IChartableItem,
    chartedSurface: IChartedSurface
  ): IScopeRef {
    const scope: ScopedSurface = this.determineScope(chartableItem, [
      chartedSurface,
    ]);
    let ref: Arch | Quadrant | IToothRef | IChartedTooth | undefined;

    switch (scope) {
      case ChartableSurface.WholeTooth:
        ref = ChartedSurface.asWholeTooth(chartedSurface);
        break;
      case ChartableSurface.Quadrant:
        ref = ChartedSurface.asQuadrant(chartedSurface);
        break;
      case ChartableSurface.Arch:
        ref = ChartedSurface.asArch(chartedSurface);
        break;
      case ChartableSurface.Crown:
        const tooth = ChartedSurface.asWholeTooth(chartedSurface);
        if (!tooth) {
          ref = undefined;
          break;
        }
        ref = {
          ...tooth,
          surface: ChartableSurface.Crown,
        };
        break;
      default:
        break;
    }

    return {
      scope,
      ref,
    };
  }

  isInScope(chartedItem: IChartedItem, surface: IChartedSurface): boolean {
    if (
      chartedItem.scopeRef.scope === ChartableSurface.WholeTooth ||
      chartedItem.scopeRef.scope === ChartableSurface.Crown
    ) {
      return this._isInWholeTooth(chartedItem, surface);
    }
    if (chartedItem.scopeRef.scope === ChartableSurface.Quadrant) {
      return this._isInQuadrant(chartedItem, surface);
    }
    if (chartedItem.scopeRef.scope === ChartableSurface.Arch) {
      return this._isInArch(chartedItem, surface);
    }
    return true;
  }

  reduceChartedSurfacesToScope(
    config: WithRef<IChartableItem>,
    chartedSurfaces: IChartedSurface[]
  ): ISurfaceScopeRefPair[] {
    return chartedSurfaces
      .map((surface) => ({
        surfaces: [surface],
        scopeRef: this.getScopeRef(config, surface),
      }))
      .reduce(
        (combinedPairs: ISurfaceScopeRefPair[], pair: ISurfaceScopeRefPair) => {
          const hasMatch =
            combinedPairs.length > 0 &&
            combinedPairs.some((currentPair) =>
              isEqual(pair.scopeRef, currentPair.scopeRef)
            );

          if (!hasMatch) {
            const surfaces = this._updateChartedSurfacesRefScope(
              pair.surfaces,
              pair.scopeRef
            );
            return [...combinedPairs, { ...pair, surfaces }];
          }

          return combinedPairs.map((combinedPair) => {
            if (!isEqual(pair.scopeRef, combinedPair.scopeRef)) {
              const surfaces = this._updateChartedSurfacesRefScope(
                combinedPair.surfaces,
                pair.scopeRef
              );
              return {
                ...combinedPair,
                surfaces,
              };
            }

            const surfaces = this._updateChartedSurfacesRefScope(
              [...combinedPair.surfaces, ...pair.surfaces],
              pair.scopeRef
            );
            return {
              ...combinedPair,
              surfaces,
            };
          });
        },
        []
      );
  }

  private _updateChartedSurfacesRefScope(
    chartedSurfaces: IChartedSurface[],
    scopeRef: IScopeRef
  ): IChartedSurface[] {
    return chartedSurfaces.map((surface) => {
      if (!surface.chartedRef.tooth) {
        return surface;
      }
      if (
        scopeRef.scope === ChartableSurface.Crown &&
        ChartedSurface.isToothSurface(surface, scopeRef.scope)
      ) {
        return {
          ...surface,
          chartedRef: {
            ...surface.chartedRef,
            tooth: {
              ...surface.chartedRef.tooth,
              surface: scopeRef.scope,
            },
          },
        };
      }
      return surface;
    });
  }

  private _isInWholeTooth(
    chartedItem: IChartedItem,
    surface: IChartedSurface
  ): boolean {
    return ChartedSurface.isOnTooth(
      surface,
      chartedItem.scopeRef.ref as IToothRef
    );
  }

  private _isInQuadrant(
    chartedItem: IChartedItem,
    surface: IChartedSurface
  ): boolean {
    return (
      (surface.chartedRef.quadrant
        ? surface.chartedRef.quadrant === chartedItem.scopeRef.ref
        : false) ||
      (surface.chartedRef.tooth
        ? surface.chartedRef.tooth.quadrant === chartedItem.scopeRef.ref
        : false)
    );
  }

  private _isInArch(
    chartedItem: IChartedItem,
    surface: IChartedSurface
  ): boolean {
    if (chartedItem.scopeRef.ref === Arch.Upper) {
      return (
        (surface.chartedRef.arch
          ? surface.chartedRef.arch === chartedItem.scopeRef.ref
          : false) ||
        (surface.chartedRef.quadrant
          ? isUpperQuadrant(surface.chartedRef.quadrant)
          : false) ||
        (surface.chartedRef.tooth
          ? isUpperQuadrant(surface.chartedRef.tooth.quadrant)
          : false)
      );
    }

    return (
      (surface.chartedRef.arch
        ? surface.chartedRef.arch === chartedItem.scopeRef.ref
        : false) ||
      (surface.chartedRef.quadrant
        ? !isUpperQuadrant(surface.chartedRef.quadrant)
        : false) ||
      (surface.chartedRef.tooth
        ? !isUpperQuadrant(surface.chartedRef.tooth.quadrant)
        : false)
    );
  }

  private _determineScope(
    chartableItem: IChartableItem,
    hierarchy: SurfaceHierarchy,
    chartedSurfaces: IChartedSurface[]
  ): ScopedSurface | undefined {
    const compatibleSurface: ChartableSurface | undefined = chartableItem
      .availableSurfaces.length
      ? hierarchy.surfaces.find((surface: ChartableSurface) => {
          return chartableItem.availableSurfaces.includes(surface);
        })
      : ChartableSurface.Unscoped;

    if (compatibleSurface && compatibleSurface === ChartableSurface.Unscoped) {
      return compatibleSurface;
    }

    const canBeChartedOn = chartedSurfaces.find((surface) =>
      hierarchy.canBeChartedOn(surface.chartedRef)
    );

    if (compatibleSurface && canBeChartedOn) {
      if (
        ChartedSurface.isOnSurface(canBeChartedOn, compatibleSurface) ||
        ChartedSurface.isToothSurface(canBeChartedOn, compatibleSurface)
      ) {
        return compatibleSurface as ScopedSurface;
      }
    }

    for (let index = 0; index < hierarchy.parents.length; index++) {
      const compatibleParentSurface: ScopedSurface | undefined =
        this._determineScope(
          chartableItem,
          hierarchy.parents[index],
          chartedSurfaces
        );

      if (compatibleParentSurface) {
        return compatibleParentSurface;
      }
    }
  }
}

export interface ISurfaceScopeRefPair {
  surfaces: IChartedSurface[];
  scopeRef: IScopeRef;
}
