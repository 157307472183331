import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { LabJobsFacade } from '../../../store/lab-jobs.facade';

@Component({
  selector: 'pr-lab-jobs-tabs',
  templateUrl: './lab-jobs-tabs.component.html',
  styleUrls: ['./lab-jobs-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabJobsTabsComponent implements OnDestroy {
  constructor(private _labJobsFacade: LabJobsFacade) {
    this._labJobsFacade.loadPracticeLabJobs();
  }

  ngOnDestroy(): void {
    this._labJobsFacade.unsubscribeLabJobs();
  }
}
