import {
  type AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  type OnDestroy,
  Output,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { sampleTime, takeUntil } from 'rxjs/operators';
import {
  ScrollContainerManagerService,
  ScrollContainer,
} from './scroll-container.service';

@Directive({
  selector: '[prScrollDetector]',
})
export class ScrollDetectorDirective implements AfterViewInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();
  @Input() offsetTop = 0;
  @Input() offsetBottom = 0;
  @Output() reachedTop: EventEmitter<void> = new EventEmitter<void>();
  @Output() reachedBottom: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private _element: ElementRef<HTMLElement>,
    private _zone: NgZone,
    private _scrollContainerManager: ScrollContainerManagerService
  ) {
    this._scrollContainerManager.setContainer(
      ScrollContainer.InAppointment,
      this._element
    );
  }

  ngAfterViewInit(): void {
    this._zone.runOutsideAngular(() => {
      fromEvent(this._element.nativeElement, 'scroll', {
        passive: true,
      })
        .pipe(sampleTime(300), takeUntil(this._destroy$))
        .subscribe(() => {
          const scrollTop: number = this._element.nativeElement.scrollTop;
          const scrollHeight: number = this._element.nativeElement.scrollHeight;
          const clientHeight: number = this._element.nativeElement.clientHeight;

          if (scrollTop <= this.offsetTop) {
            this._zone.run(() => this.reachedTop.emit());
          }

          if (scrollHeight - (scrollTop + clientHeight) <= this.offsetBottom) {
            this._zone.run(() => this.reachedBottom.emit());
          }
        });
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._scrollContainerManager.removeContainer(ScrollContainer.InAppointment);
  }
}
