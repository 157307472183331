import {
  IntegrationStorage,
  IntegrationType,
} from '@principle-theorem/integrations';

export interface ISlackConfig {
  developer: {
    userAccessToken: string;
    botAccessToken: string;
  };
  signingSecret: string;
  clientId: string;
  clientSecret: string;
}

export interface ISlackIntegrationData {
  teamId: string;
  accessToken: string;
  incomingWebhook?: {
    url: string;
    channel: string;
    configurationUrl: string;
  };
  bot: {
    userId: string;
    accessToken: string;
  };
}

export interface ISlackLinkOptions {
  scopes: string[];
  oauthApiUrl: string;
  clientId: string;
  temporaryTokenUid: string;
}

export function createSlackLink(options: ISlackLinkOptions): string {
  const url: URL = new URL('https://slack.com/oauth/authorize');
  url.searchParams.set('redirect_uri', options.oauthApiUrl);
  url.searchParams.set('client_id', options.clientId);
  url.searchParams.set('state', options.temporaryTokenUid);
  url.searchParams.set('scope', options.scopes.join());
  return url.href;
}

export class SlackIntegrationStorage extends IntegrationStorage<ISlackIntegrationData> {
  constructor() {
    super(IntegrationType.Slack);
  }
}
