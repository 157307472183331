import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IEvent,
  type IParticipant,
} from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-event-participants',
    templateUrl: './event-participants.component.html',
    styleUrls: ['./event-participants.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EventParticipantsComponent {
  trackByParticipant = TrackByFunctions.ref<IParticipant>();
  event$ = new ReplaySubject<IEvent>(1);

  @Input()
  set event(event: IEvent) {
    if (event) {
      this.event$.next(event);
    }
  }
}
