import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { type ValidationErrors } from '@angular/forms';
import { MIN_PHONE_NUMBER_LENGTH } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IContact,
  type IContactDetails,
} from '@principle-theorem/principle-core/interfaces';
import { ContactFormGroup } from './contact.formgroup';

@Component({
    selector: 'pr-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContactFormComponent {
  trackByError = TrackByFunctions.field<ValidationErrors>('value');
  minPhoneLength: number = MIN_PHONE_NUMBER_LENGTH;
  form: ContactFormGroup = new ContactFormGroup();
  @Output()
  submitted = new EventEmitter<IContactDetails>();

  submit(): void {
    if (this.form.invalid) {
      return;
    }
    this.submitted.emit(this.form.value);
  }

  @Input()
  set contact(contact: IContact) {
    if (contact) {
      this.form.patchValue(contact, { emitEvent: false });
    }
  }
}
