import { IPatient } from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { IBaseCommand, IBridgeIntegration } from '../../send-command-to-device';

export const EZDENT_INTEGRATION: IBridgeIntegration = {
  label: 'Ezdent',
  description:
    'Two-way integration for taking and uploading radiographic images using Vatech EzDent-i',
};

export type EzdentTriggerImageCaptureCommand = IBaseCommand<
  EzdentToDeviceCommand.TriggerImageCapture,
  { patient: WithRef<IPatient> }
>;

export enum EzdentToDeviceCommand {
  TriggerImageCapture = 'ezdent.triggerImageCapture',
}
