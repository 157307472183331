import { FeaturesCollection } from '@principle-theorem/feature-flags';
import {
  HicapsConnectMethod,
  IHicapsConnectApiAdaptor,
  IHicapsConnectApiCallOptions,
  PrincipleHicapsConnect,
  PrincipleHicapsConnectRequest,
  PrincipleHicapsConnectResponse,
} from '@principle-theorem/hicaps-connect';
import {
  IBridgeDevice,
  IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  CollectionReference,
  DocumentReference,
  Firestore,
  WithRef,
  addDocAsWithRef,
  doc,
  doc$,
} from '@principle-theorem/shared';
import { Observable, from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Practice } from '../../practice/practice';
import { HicapsConnectProcessHandler } from './hicaps-connect-process-handler';

export enum HicapsConnectProcessStatus {
  Pending = 'pending',
  InProgress = 'inProgress',
  Success = 'success',
  Error = 'error',
}

export interface IHicapsConnectProcess<T extends HicapsConnectMethod> {
  bridgeDevice: DocumentReference<IBridgeDevice>;
  status: HicapsConnectProcessStatus;
  request: PrincipleHicapsConnectRequest<T>;
  response?: PrincipleHicapsConnectResponse<T>;
}

export interface IHicapsConnectApiHooks {
  afterProcessCreate<T extends HicapsConnectMethod>(
    process: WithRef<IHicapsConnectProcess<T>>
  ): Promise<void>;
}

export class HicapsConnectApiAdaptor implements IHicapsConnectApiAdaptor {
  private _practiceRef: DocumentReference<IPractice>;

  constructor(
    private _bridgeDeviceRef: DocumentReference<IBridgeDevice>,
    private _features: FeaturesCollection,
    private _hooks?: IHicapsConnectApiHooks
  ) {
    this._practiceRef = Firestore.getParentDocRef<IPractice>(
      this._bridgeDeviceRef
    );
  }

  call$<T extends HicapsConnectMethod>(
    methodName: T,
    request: PrincipleHicapsConnectRequest<T>['data'],
    extendedData: PrincipleHicapsConnectRequest<T>['extendedData'],
    options?: IHicapsConnectApiCallOptions
  ): Observable<PrincipleHicapsConnectResponse<T>> {
    const processUid = options?.processUid ?? this.generateProcessUid();
    const processRequest = PrincipleHicapsConnect.createRequest(
      processUid,
      methodName,
      request,
      extendedData
    );

    const process$ = from(this._createProcess(processRequest)).pipe(
      switchMap((processRef) => doc$(processRef))
    );

    return process$.pipe(
      filter((process) => process.response !== undefined),
      map((process) => process.response as PrincipleHicapsConnectResponse<T>)
    );
  }

  generateProcessUid(): string {
    return doc(this._processColRef()).id;
  }

  private async _createProcess<T extends HicapsConnectMethod>(
    request: PrincipleHicapsConnectRequest<T>
  ): Promise<DocumentReference<IHicapsConnectProcess<T>>> {
    const hicapsProcess = await this._getProcessData(request);

    const savedProcess = await addDocAsWithRef(
      this._processColRef<T>(),
      hicapsProcess,
      request.processUid
    );

    if (this._hooks?.afterProcessCreate) {
      await this._hooks.afterProcessCreate(savedProcess);
    }

    return savedProcess.ref;
  }

  private _processColRef<T extends HicapsConnectMethod>(): CollectionReference<
    IHicapsConnectProcess<T>
  > {
    return Practice.hicapsConnectProcessCol<T>({ ref: this._practiceRef });
  }

  private async _getProcessData<T extends HicapsConnectMethod>(
    request: PrincipleHicapsConnectRequest<T>
  ): Promise<IHicapsConnectProcess<T>> {
    const initialProcess: IHicapsConnectProcess<T> = {
      bridgeDevice: this._bridgeDeviceRef,
      status: HicapsConnectProcessStatus.Pending,
      request,
    };

    const isTestMode = await HicapsConnectProcessHandler.isTestModeEnabled(
      initialProcess,
      this._features
    );
    if (isTestMode) {
      return HicapsConnectProcessHandler.getTestResponse(initialProcess);
    }

    const preflightError =
      await HicapsConnectProcessHandler.getPreflightError(initialProcess);
    if (preflightError) {
      return HicapsConnectProcessHandler.getErrorResponse(
        initialProcess,
        preflightError
      );
    }

    return initialProcess;
  }
}
