<a
  *ngIf="user$ | async as user"
  mat-list-item
  [routerLink]="['./', user.ref.id]"
>
  <pt-user-icon
    matListItemAvatar
    [name]="user.name"
    [src]="user | profileImage$ | async"
    [diameter]="40"
   />
  <div matListItemTitle>{{ user.name }}</div>
  <span matListItemLine class="mat-caption">{{ user.email }}</span>
  <small matListItemLine class="last-login"
    >Last Active:
    <ng-container
      *ngIf="user | map : getLastActiveAt as lastActiveAt; else notActivated"
      >{{ lastActiveAt | moment | amDateFormat : dateFormat }}</ng-container
    >
    <ng-template #notActivated>Never</ng-template>
  </small>
  <div matListItemMeta>
    <div fxLayout="row">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
</a>
