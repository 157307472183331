import { initVersionedSchema } from '@principle-theorem/editor';
import { IFollowUp } from '@principle-theorem/principle-core/interfaces';
import { AtLeast, toTimestamp } from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';

export class FollowUp {
  static init(overrides: AtLeast<IFollowUp, 'followUpDate'>): IFollowUp {
    return {
      uid: uuid(),
      createFollowUp: false,
      noFollowUpReason: initVersionedSchema(),
      createdAt: toTimestamp(),
      ...overrides,
    };
  }
}
