import type { JSONContent } from '@tiptap/core';
import { BlockNodes } from '../../available-extensions';
import { toParagraphContent } from '../../raw-schema-helpers';

export function buildColumn({ content }: Partial<JSONContent>): JSONContent {
  return buildNode({ type: BlockNodes.Column, content });
}

export function buildColumnBlock({
  content,
}: Partial<JSONContent>): JSONContent {
  return buildNode({ type: BlockNodes.ColumnBlock, content });
}

export function buildColumns(numberOfColumns: number): JSONContent[] {
  const content = [toParagraphContent([])];
  const buildFn = (): JSONContent => buildColumn({ content });
  return Array.from({ length: numberOfColumns }, () => buildFn());
}

function buildNode({ type, content }: JSONContent): JSONContent {
  return content ? { type, content } : { type };
}
