<ng-container *ngIf="notes$ | async as notes">
  <div class="notes-container" *ngIf="notes.length">
    <h5 class="mat-caption">Notes</h5>

    <div class="flex flex-col justify-between gap-4">
      <div *ngFor="let note of notes; trackBy: trackByNote">
        <div class="flex items-start justify-between">
          <div class="flex flex-col justify-between">
            <pr-content-view
              class="note-content"
              [content]="note.content"
             />
            <span class="mat-caption note-footer">
              {{ note.owner.name }}
              {{ note.updatedAt | moment | amCalendar: calendarFormat }}
            </span>
          </div>

          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
            <button mat-menu-item (click)="edit(note)">
              <mat-icon>edit</mat-icon>
              Edit
            </button>
            <button mat-menu-item (click)="delete(note)">
              <mat-icon>delete</mat-icon>
              Delete
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</ng-container>
