import { SurfaceHierarchy } from './surface-hierarchy';
import { UnscopedHierarchy } from './unscoped-hierarchy';
import { isBoolean } from 'lodash';
import {
  ChartableSurface,
  IChartedRef,
} from '@principle-theorem/principle-core/interfaces';

export class MouthHierarchy extends SurfaceHierarchy<
  Pick<IChartedRef, 'wholeMouth'>
> {
  override surfaces: ChartableSurface[] = [ChartableSurface.WholeMouth];
  override parents = [new UnscopedHierarchy()];

  canBeChartedOn(
    surface: Partial<IChartedRef>
  ): surface is Pick<IChartedRef, 'wholeMouth'> {
    return isBoolean(surface.wholeMouth);
  }

  toChartedRef(): Pick<IChartedRef, 'wholeMouth'> {
    return {
      wholeMouth: true,
    };
  }
}
