import {
  Appointment,
  AppointmentContextBuilder,
  Patient,
  ScopeDataBuilder,
} from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IPatient,
  type IPrincipleMention,
  type ITemplateContextOption,
  MentionResourceType,
  TemplateScope,
  PatientRelationshipType,
} from '@principle-theorem/principle-core/interfaces';
import {
  asDocRef,
  asyncForEach,
  DATE_TIME_FORMAT,
  getDoc,
  type IProvider,
  snapshot,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first, orderBy, uniqBy } from 'lodash';
import { map } from 'rxjs/operators';

export class AppointmentMentionContextProvider
  implements IProvider<TemplateScope[], Promise<ITemplateContextOption[]>>
{
  constructor(
    private _mention: IPrincipleMention,
    private _appUrl: string
  ) {}

  canProvide(scopes: TemplateScope[]): boolean {
    const type = this._mention.resource.type;
    const isAppointmentScope = scopes.includes(TemplateScope.Appointment);
    const isAppointmentResource = type === MentionResourceType.Appointment;
    const isPatientResource = type === MentionResourceType.Patient;
    return isAppointmentScope && (isAppointmentResource || isPatientResource);
  }

  async execute(): Promise<ITemplateContextOption[]> {
    const type = this._mention.resource.type;
    const isPatientResource = type === MentionResourceType.Patient;

    if (isPatientResource) {
      return this._resolveAppointmentContextsFromPatient();
    }
    const appointment = await getDoc(
      asDocRef<IAppointment>(this._mention.resource.ref)
    );

    return [await this._resolveAppointmentContext(appointment)];
  }

  private async _resolveAppointmentContextsFromPatient(): Promise<
    ITemplateContextOption[]
  > {
    const patient = await getDoc(
      asDocRef<IPatient>(this._mention.resource.ref)
    );

    const futureAppointments = await snapshot(
      Patient.withPatientRelationships$(
        patient,
        [PatientRelationshipType.DuplicatePatient],
        (patientReffable) => Patient.getFutureAppointments$(patientReffable)
      )
    );

    const lastAppointment = await snapshot(
      Patient.withPatientRelationships$(
        patient,
        [PatientRelationshipType.DuplicatePatient],
        (patientReffable) =>
          Patient.lastAppointment$(patientReffable).pipe(
            map((appointment) => compact([appointment]))
          )
      ).pipe(
        map((appointments) =>
          first(orderBy(appointments, 'event.from', 'desc'))
        )
      )
    );

    const appointments = uniqBy(
      compact([lastAppointment, ...futureAppointments]),
      (appointment) => appointment?.ref.id
    );

    return asyncForEach(appointments, (appointment) =>
      this._resolveAppointmentContext(appointment)
    );
  }

  private async _resolveAppointmentContext(
    appointment: WithRef<IAppointment>
  ): Promise<ITemplateContextOption> {
    const practice = await snapshot(Appointment.practice$(appointment));

    if (!practice) {
      throw new Error('No practice found for appointment');
    }

    const scopeData = await ScopeDataBuilder.buildAppointmentScopeData(
      appointment,
      this._appUrl
    );
    const context = new AppointmentContextBuilder(scopeData).build();

    const startTime = appointment.event
      ? toMoment(appointment.event.from)
          .tz(practice.settings.timezone)
          .format(DATE_TIME_FORMAT)
      : 'Unscheduled';
    return {
      label: `${startTime} - ${appointment.treatmentPlan.treatmentStep.name}`,
      scope: TemplateScope.Appointment,
      context,
      scopeData,
    };
  }
}
