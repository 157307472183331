<button
  mat-button
  [matMenuTriggerFor]="textStyleMenu"
  [matMenuTriggerRestoreFocus]="false"
  [ngClass]="{ active: isActive$ | async }"
  matTooltip="Text Colour"
  matTooltipShowDelay="300"
>
  <mat-icon>format_color_text</mat-icon>
</button>

<mat-menu #textStyleMenu="matMenu">
  <ng-template matMenuContent>
    <div class="heading">Text Colour</div>
    <ng-container>
      <ng-container
        *ngFor="let textColour of textColours; trackBy: trackByItem"
      >
        <button
          mat-menu-item
          [ngClass]="{
            active: (activeTextColour$ | async) === textColour.colour
          }"
          (click)="runCommand(textColour)"
        >
          <mat-icon class="text-colour" [ngStyle]="{ color: textColour.colour }"
            >format_color_text</mat-icon
          >
          <span>{{ textColour.buttonText }}</span>
        </button>
      </ng-container>
    </ng-container>

    <div class="heading">Highlight Colour</div>
    <ng-container>
      <ng-container
        *ngFor="let highlightColour of highlightColours; trackBy: trackByItem"
      >
        <button
          mat-menu-item
          [ngClass]="{
            active: (activeHighlightColour$ | async) === highlightColour.colour
          }"
          (click)="runCommand(highlightColour)"
        >
          <mat-icon
            class="highlight-colour"
            [ngStyle]="{ 'background-color': highlightColour.colour }"
            >format_color_text</mat-icon
          >
          <span>{{ highlightColour.buttonText }}</span>
        </button>
      </ng-container>
    </ng-container>
  </ng-template>
</mat-menu>
