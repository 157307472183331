import { Directive, Inject, OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MixedSchema,
  getSchemaText,
  initVersionedSchema,
} from '@principle-theorem/editor';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { TypedFormControl } from '@principle-theorem/ng-shared';
import { Brand, SystemTemplates } from '@principle-theorem/principle-core';
import {
  IBrand,
  IPatient,
  IPractice,
  ITemplateContext,
  ITemplateScopeData,
  SystemTemplate,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, filterUndefined, snapshot } from '@principle-theorem/shared';
import { AnyExtension } from '@tiptap/core';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import {
  IPopulatedTemplate,
  populateTemplate,
} from '../contextual-actions/interaction-actions/template-context-resolvers/mention-to-templates';

export interface ISystemTemplateInteractionDialogData {
  patient: WithRef<IPatient>;
  practice: WithRef<IPractice>;
  systemTemplate: SystemTemplate;
  context: ITemplateContext;
  scopeData: ITemplateScopeData;
}

@Directive()
export abstract class SystemTemplateInteractionDialogComponent
  implements OnDestroy
{
  protected _onDestroy$ = new Subject<void>();
  protected _brand$: Observable<WithRef<IBrand>>;
  protected _practice$: Observable<WithRef<IPractice>>;
  protected readonly _populatedTemplate$: Observable<IPopulatedTemplate>;
  brands$: Observable<WithRef<IBrand>[]>;
  hasSinglePractice$: Observable<boolean>;
  canRevert$: Observable<boolean>;
  submitting$ = new BehaviorSubject<boolean>(false);
  noteCtrl = new TypedFormControl<MixedSchema>(
    initVersionedSchema(),
    Validators.required
  );
  extensions: AnyExtension[];

  protected constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISystemTemplateInteractionDialogData,
    private _currentScope: CurrentScopeFacade
  ) {
    this._brand$ = this._currentScope.currentBrand$.pipe(filterUndefined());
    this.brands$ = this._brand$.pipe(map((brand) => [brand]));
    this._practice$ =
      this._currentScope.currentPractice$.pipe(filterUndefined());
    this.hasSinglePractice$ = this._brand$.pipe(
      switchMap((brand) => Brand.practices$(brand)),
      map((practices) => practices.length === 1)
    );

    this._populatedTemplate$ = this._brand$.pipe(
      switchMap((brand) =>
        populateTemplate(
          SystemTemplates.getDefaultTemplate(
            this.data.systemTemplate,
            brand.ref
          ),
          this.data.context,
          this.data.scopeData
        )
      )
    );

    this._populatedTemplate$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((template) => {
        this.noteCtrl.setValue(template.content);
      });

    this.canRevert$ = combineLatest([
      this.noteCtrl.valueChanges,
      this._populatedTemplate$,
    ]).pipe(
      map(
        ([noteCtrl, populatedTemplate]) =>
          getSchemaText(noteCtrl) !== getSchemaText(populatedTemplate.content)
      ),
      startWith(false)
    );
  }

  async revertToSystemTemplate(): Promise<void> {
    const template = await snapshot(this._populatedTemplate$);
    this.noteCtrl.setValue(template.content);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
