import {
  ChartableSurface,
  IChartableItem,
  IChartedRef,
  isChartedToothWithSurface,
} from '@principle-theorem/principle-core/interfaces';
import { isObject } from '@principle-theorem/shared';
import { SurfaceHierarchy } from './surface-hierarchy';
import { ToothHierarchy } from './tooth-hierarchy';

export class CrownSurfaceHierarchy extends SurfaceHierarchy<
  Required<Pick<IChartedRef, 'tooth'>>
> {
  override surfaces = [
    ChartableSurface.Distal,
    ChartableSurface.Facial,
    ChartableSurface.Lingual,
    ChartableSurface.Mesial,
    ChartableSurface.Occlusal,
    ChartableSurface.Crown,
  ];
  override parents = [new ToothHierarchy()];

  override getCompatibleSurfaces(item: IChartableItem): ChartableSurface[] {
    if (item.availableSurfaces.includes(ChartableSurface.Crown)) {
      return this.surfaces;
    }
    return [];
  }

  canBeChartedOn(
    selectedSurface: Partial<IChartedRef>
  ): selectedSurface is Required<Pick<IChartedRef, 'tooth'>> {
    return (
      isObject(selectedSurface) &&
      'tooth' in selectedSurface &&
      isChartedToothWithSurface(selectedSurface)
    );
  }

  toChartedRef(
    currentRef: Required<Pick<IChartedRef, 'tooth'>>
  ): Required<Pick<IChartedRef, 'tooth'>> {
    return {
      tooth: {
        ...currentRef.tooth,
        surface: ChartableSurface.Crown,
      },
    };
  }
}
