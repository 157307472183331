import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  OrganisationService,
  StafferDisplayComponent,
} from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { Staffer } from '@principle-theorem/principle-core';
import { AppointmentPermissions } from '@principle-theorem/principle-core/features';
import { type IStaffer } from '@principle-theorem/principle-core/interfaces';
import { isSameRef, snapshot, type WithRef } from '@principle-theorem/shared';
import { isUndefined } from 'lodash';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  noop,
  type Observable,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

@Component({
    selector: 'pr-practitioner-selector',
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        ReactiveFormsModule,
        StafferDisplayComponent,
    ],
    templateUrl: './practitioner-selector.component.html',
    styleUrls: ['./practitioner-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PractitionerSelectorComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _staffOverride$ = new BehaviorSubject<
    WithRef<IStaffer>[] | undefined
  >(undefined);
  trackByStaffer = TrackByFunctions.ref<WithRef<IStaffer>>();
  staff$: Observable<WithRef<IStaffer>[]>;
  selected = new TypedFormControl<WithRef<IStaffer> | undefined>();
  displayName$ = new BehaviorSubject<string>('');
  @Output() stafferSelected = new EventEmitter<WithRef<IStaffer> | undefined>();
  @Input({ transform: coerceBooleanProperty }) allowEmpty = false;
  @Input({ transform: coerceBooleanProperty }) disabled = false;
  @Input({ transform: coerceBooleanProperty }) compact = false;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() label = 'Charting as';
  @Input() hint = '';

  @Input()
  set staffer(staffer: WithRef<IStaffer>) {
    if (staffer) {
      void this._setDefault(staffer);
    }
  }

  @Input()
  set staff(staff: WithRef<IStaffer>[]) {
    if (staff) {
      this._staffOverride$.next(staff);
    }
  }

  constructor(
    private _chartStore: ChartFacade,
    public organisation: OrganisationService
  ) {
    this.selected.valueChanges
      .pipe(
        filter((selection) => (this.allowEmpty ? true : !!selection)),
        distinctUntilChanged(isSameRef),
        takeUntil(this._onDestroy$)
      )
      .subscribe((selection) => {
        this.stafferSelected.emit(selection);
        this.displayName$.next(selection?.user.name ?? '');
      });

    this._chartStore
      .chartingAs$(ChartId.InAppointment)
      .pipe(
        switchMap((staffer) => this._setDefault(staffer)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);

    this.staff$ = combineLatest([
      this._staffOverride$,
      this.organisation.practicePractitioners$,
    ]).pipe(
      map(([staff, practicePractitioners]) => {
        if (isUndefined(staff)) {
          return practicePractitioners;
        }
        return staff;
      })
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  compareFn(value: WithRef<IStaffer>, staffer: WithRef<IStaffer>): boolean {
    if (!value || !staffer) {
      return false;
    }
    return value.ref && staffer.ref ? isSameRef(value, staffer) : false;
  }

  private async _setDefault(staffer?: WithRef<IStaffer>): Promise<void> {
    if (!staffer) {
      const staff = await snapshot(this.organisation.practicePractitioners$);
      this.selected.setValue(staff[0], { emitEvent: false });
      return;
    }
    const canConductAppointments: boolean = await snapshot(
      Staffer.hasPermission$(staffer, AppointmentPermissions.AppointmentConduct)
    );
    if (!canConductAppointments) {
      return;
    }
    this.selected.setValue(staffer, { emitEvent: false });
    this.displayName$.next(staffer.user.name);
  }
}
