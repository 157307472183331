import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ComponentsModule } from './components/components.module';
import { EventableRouterEffects } from './store/effects/eventable-router.effects';

@NgModule({
  imports: [EffectsModule.forFeature([EventableRouterEffects])],
  exports: [ComponentsModule],
})
export class NgEventableModule {}
