<mat-toolbar color="accent">Edit Sterilisation Record</mat-toolbar>

<mat-dialog-content>
  <form fxLayout="column" [formGroup]="form" (ngSubmit)="save()">
    <mat-form-field>
      <mat-label>Sterilisation Record ID</mat-label>
      <input matInput formControlName="data" />
    </mat-form-field>
    <pr-patient-selector label="Patient" formControlName="patient" />
    <pr-staff-selector
      label="Scanned By"
      formControlName="scannedBy"
      [practice]="practice"
     />
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" (click)="save()">Save</button>
  </pt-buttons-container>
</mat-dialog-actions>
