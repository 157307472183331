import { type INamedDocument } from '@principle-theorem/shared';
import { type ILabJob } from '../lab-job/lab-job';

export enum AppointmentDependencyType {
  LabJob = 'labjob',
  Equipment = 'equipment',
}

export interface IAppointmentDependency extends INamedDocument {
  type: AppointmentDependencyType;
}

export type ResolvedAppointmentDependency = ILabJob;
