<div fxLayout="column" fxLayout.gt-sm="row">
  <div fxFlex="50" class="layout-margin" *ngIf="typesChartBuilder">
    <h2>Treatment Types Performed</h2>
    <pr-chart [chartBuilder]="typesChartBuilder" />
  </div>

  <div fxFlex="50" class="layout-margin" *ngIf="incomeDistributionChartBuilder">
    <h2>Treatment Type Income Distribution</h2>
    <pr-chart [chartBuilder]="incomeDistributionChartBuilder" />
  </div>
</div>

<div class="layout-margin" *ngIf="treatmentValuesChart">
  <h2>Proposed Treatment Values</h2>
  <pr-chart [chartBuilder]="treatmentValuesChart" />
</div>

<div class="layout-margin" *ngIf="acceptanceChartBuilder">
  <h2>Accepted Treatment Plans</h2>
  <pr-chart [chartBuilder]="acceptanceChartBuilder" />
</div>

<div class="layout-margin">
  <pr-table-chart
    fxFlex
    [chart]="tableChartCard"
    [replacementHeaders]="[{ from: 'Owner', to: 'Practitioner' }]"
   />
</div>
