import { ChartViewFactory } from '@principle-theorem/ng-clinical-charting';
import {
  isSameToothRef,
  labelToToothRef,
} from '@principle-theorem/principle-core';
import {
  ChartSection,
  ChartView,
  FACIAL_PERIO_DATA_POINTS,
  ICellMetadata,
  IChartedCondition,
  IChartedConditionWithResolvedConfig,
  IPerioDataPoints,
  IPerioRecord,
  IPerioTable,
  IPerioTableCell,
  ITooth,
  IToothRef,
  MAX_PERIO_CELL_AMOUNT,
  MIN_FURCATION_ROOT_AMOUNT,
  PALATAL_PERIO_DATA_POINTS,
  PERIO_MEASUREMENT_MAX_VALUE,
  PerioDataPoint,
  PerioMeasurement,
  PerioMeasurementValue,
  PerioTableSide,
  ToothQuadrant,
} from '@principle-theorem/principle-core/interfaces';
import {
  compact,
  difference,
  first,
  groupBy,
  isNumber,
  uniqWith,
} from 'lodash';
import { PerioRecord } from './perio-record';
import { PerioTableByTooth } from './perio-table-by-tooth';

export const MAX_CELL_WIDTH = 66;
export const CELL_HEIGHT = 24;

export class PerioTable {
  static toPerioTables(
    teeth: ITooth[],
    conditions: IChartedConditionWithResolvedConfig[],
    immutable: boolean,
    view: ChartView,
    section: ChartSection,
    perioRecords: IPerioRecord[] = []
  ): IPerioTable[] {
    return new ChartViewFactory(teeth)
      .filterSection(section)
      .filterView(view)
      .byArch()
      .map((arch) => ({
        label: arch.label,
        records: PerioRecord.recordsForTeeth(perioRecords, arch.teeth),
      }))
      .filter((table) => table.records.length >= 1)
      .map((table) => {
        const missing = PerioTable.missingTeeth(table.records, conditions);
        const records = PerioTable.filterMissingTeethRecords(
          missing,
          table.records
        );

        return {
          label: table.label,
          disabled: immutable,
          missingTeeth: missing,
          implants: PerioTable.handleImplants(records, conditions),
          rows: PerioRecord.transformPerioRecords(records),
        };
      });
  }

  static missingTeeth(
    records: IPerioRecord[],
    conditions: IChartedConditionWithResolvedConfig[]
  ): IToothRef[] {
    const currentTeeth = groupBy(records, (record) => record.toothRef.quadrant);
    const removedTeeth = PerioTable.getRemovedTeeth(
      Object.entries(currentTeeth)
    );
    const toothRefs = records.map((record) => record.toothRef);
    const removedByConditions = PerioTable.getRemovedTeethByConditions(
      toothRefs,
      conditions
    );
    return uniqWith([...removedTeeth, ...removedByConditions], isSameToothRef);
  }

  static filterMissingTeethRecords(
    missing: IToothRef[],
    records: IPerioRecord[]
  ): IPerioRecord[] {
    return records.filter(
      (record) =>
        !missing.some((tooth) => isSameToothRef(tooth, record.toothRef))
    );
  }

  // TODO - Implement handleImplants when adding perio graph
  static handleImplants(
    _records: IPerioRecord[],
    _conditions: IChartedCondition[]
  ): IToothRef[] {
    return [];
  }

  static getRemovedTeethByConditions(
    currentTeeth: IToothRef[],
    conditions: IChartedConditionWithResolvedConfig[]
  ): IToothRef[] {
    const removedTeeth = conditions.reduce((missing, condition) => {
      if (condition.config.display.disablesSurface) {
        return [
          ...missing,
          ...compact(
            condition.chartedSurfaces.map((surface) => surface.chartedRef.tooth)
          ),
        ];
      }
      return missing;
    }, [] as IToothRef[]);

    return removedTeeth.filter((tooth) =>
      currentTeeth.some((currentTooth) => isSameToothRef(tooth, currentTooth))
    );
  }

  static getRemovedTeeth(
    currentTeeth: [string, IPerioRecord[]][]
  ): IToothRef[] {
    return currentTeeth.reduce((teethToRemove, [quadrantIndex, teeth]) => {
      const quadrant = parseInt(quadrantIndex, 10) as ToothQuadrant;
      const present = teeth.map((record) => record.toothRef.quadrantIndex);
      const expected = PerioTable.getExpectedTeethIndexes(quadrant);
      const missing = difference(expected, present);

      missing.forEach((tooth) =>
        teethToRemove.push({ quadrant, quadrantIndex: tooth })
      );

      return teethToRemove;
    }, [] as IToothRef[]);
  }

  static getExpectedTeethIndexes(quadrant: ToothQuadrant): number[] {
    const expectedQuadrantLength = [1, 2, 3, 4].includes(quadrant) ? 8 : 5;

    return Array.from(
      { length: expectedQuadrantLength },
      (_, index) => index + 1
    );
  }

  static isPerioMeasurementValue(
    value: unknown
  ): value is PerioMeasurementValue {
    return value === undefined || isNumber(value);
  }

  static resolveTableMetadata(
    perioTable: IPerioTable,
    row: number,
    column: number
  ): ICellMetadata {
    return perioTable.rows[row][column].metadata;
  }

  static filterBySide(
    side: PerioTableSide
  ): (cell: IPerioTableCell, index: number, all: IPerioTableCell[]) => boolean {
    const validPoints: PerioDataPoint[] =
      side === PerioTableSide.Facial
        ? FACIAL_PERIO_DATA_POINTS
        : PALATAL_PERIO_DATA_POINTS;
    return (cell: IPerioTableCell) =>
      validPoints.includes(cell.metadata.dataPoint);
  }

  static filterTableBySide(
    perioTable: IPerioTable,
    side: PerioTableSide
  ): IPerioTable {
    return {
      ...perioTable,
      label: `${perioTable.label} ${side}`,
      rows: perioTable.rows.map((row) =>
        row.filter(PerioTable.filterBySide(side))
      ),
    };
  }

  static labelForPerioTable(label: string): string {
    const lowerCaseLabel = label.toLowerCase();

    const isPalatalAndLower =
      lowerCaseLabel.includes(PerioTableSide.Palatal) &&
      lowerCaseLabel.includes('lower');

    if (isPalatalAndLower) {
      return lowerCaseLabel.replace(
        PerioTableSide.Palatal,
        PerioTableSide.Lingual
      );
    }

    return lowerCaseLabel;
  }

  static getColHeaders(
    perioTable: IPerioTable,
    transformFn: (cell: IPerioTableCell) => string
  ): string[] {
    const firstRow = first(perioTable.rows) || [];
    return firstRow.map((cell) => transformFn(cell));
  }

  static dcmColumnHeader(cell: IPerioTableCell): string {
    const PERIO_DATA_POINT_LABEL_MAP: Record<PerioDataPoint, string> = {
      [PerioDataPoint.FacialDistal]: 'D',
      [PerioDataPoint.PalatalDistal]: 'D',
      [PerioDataPoint.FacialCentral]: 'C',
      [PerioDataPoint.PalatalCentral]: 'C',
      [PerioDataPoint.FacialMesial]: 'M',
      [PerioDataPoint.PalatalMesial]: 'M',
    };
    return PERIO_DATA_POINT_LABEL_MAP[cell.metadata.dataPoint];
  }

  static limitMeasurementValue(cell: IPerioTableCell): IPerioTableCell {
    const maxValue = PERIO_MEASUREMENT_MAX_VALUE[cell.metadata.measurement];

    if (cell.value === undefined || !maxValue) {
      return cell;
    }

    const value = cell.value > maxValue ? maxValue : cell.value;
    return { ...cell, value };
  }

  static isMobilityCell(cell: IPerioTableCell): boolean {
    return cell.metadata.measurement === PerioMeasurement.Mobility;
  }

  static mergeMobilityCells(table: IPerioTable): IPerioTable {
    const mobilityIndex = PerioTable.getMeasurementRowIndex(
      table,
      PerioMeasurement.Mobility
    );

    const mobilityRow = table.label.includes(PerioTableSide.Palatal)
      ? PerioTable.markCellsAsReadOnly(table.rows[mobilityIndex])
      : table.rows[mobilityIndex].reduce<IPerioTableCell[]>((acc, cell) => {
          const existingIndex = acc.findIndex((cur) =>
            isSameToothRef(cur.metadata.toothRef, cell.metadata.toothRef)
          );

          if (existingIndex !== -1) {
            const existing = acc[existingIndex];

            acc[existingIndex] = {
              ...existing,
              value:
                existing.value !== undefined && cell.value !== undefined
                  ? Math.max(existing.value, cell.value)
                  : existing.value !== undefined
                    ? existing.value
                    : cell.value,
            };
          }

          if (existingIndex === -1) {
            acc.push({ ...cell });
          }

          return acc;
        }, []);

    const rows = table.rows.map((row, index) =>
      index === mobilityIndex ? mobilityRow : row
    );

    return { ...table, rows };
  }

  static handleFurcationCells(
    table: IPerioTable,
    teeth: ITooth[]
  ): IPerioTable {
    const furcationIndex = PerioTable.getMeasurementRowIndex(
      table,
      PerioMeasurement.Furcation
    );

    const furcationRow = PerioTable.getFurcationCells(
      table.label,
      table.rows[furcationIndex],
      teeth
    );

    const rows = table.rows.map((row, index) =>
      index === furcationIndex ? furcationRow : row
    );

    return { ...table, rows };
  }

  static getFurcationCells(
    label: string,
    cells: IPerioTableCell[],
    teeth: ITooth[]
  ): IPerioTableCell[] {
    const isFacial = label.includes(PerioTableSide.Facial);
    const isPalatalUpper =
      label.includes(PerioTableSide.Palatal) && label.includes('Upper');
    const isPalatalLower =
      label.includes(PerioTableSide.Palatal) && label.includes('Lower');

    const validQuadrantIndexes = isPalatalUpper ? [4, 6, 7, 8] : [6, 7, 8];

    const groupedCells = PerioTableByTooth.groupCellsByTooth(cells);

    const furcationCells = Object.entries(groupedCells).flatMap(
      ([toothLabel, toothCells]) => {
        const toothRef = labelToToothRef(toothLabel);
        const tooth = teeth.find((t) => isSameToothRef(t.toothRef, toothRef));

        if (!validQuadrantIndexes.includes(toothRef.quadrantIndex)) {
          return PerioTable.markCellsAsReadOnly(toothCells);
        }

        if (!tooth) {
          return toothCells;
        }

        const roots = Math.min(tooth.roots, MAX_PERIO_CELL_AMOUNT);

        if (
          roots < MIN_FURCATION_ROOT_AMOUNT ||
          roots > MAX_PERIO_CELL_AMOUNT
        ) {
          return PerioTable.markCellsAsReadOnly(toothCells);
        }

        return PerioTable.selectFurcationCells(
          toothCells,
          roots,
          isFacial,
          isPalatalUpper,
          isPalatalLower,
          toothRef
        );
      }
    );

    return furcationCells.sort((a, b) =>
      PerioRecord.sortByQuadrantDisplayOrder(
        a.metadata.toothRef,
        b.metadata.toothRef
      )
    );
  }

  static markCellsAsReadOnly(cells: IPerioTableCell[]): IPerioTableCell[] {
    return cells.map((cell) => ({
      ...cell,
      value: undefined,
      readOnly: true,
    }));
  }

  static selectFurcationCells(
    cells: IPerioTableCell[],
    roots: number,
    isFacial: boolean,
    isPalatalUpper: boolean,
    isPalatalLower: boolean,
    toothRef: IToothRef
  ): IPerioTableCell[] {
    if (isFacial) {
      return cells.slice(0, 1);
    }

    if (isPalatalUpper && toothRef.quadrantIndex === 4) {
      return cells.slice(0, 2);
    }

    if (isPalatalUpper || isPalatalLower) {
      return roots === 3 ? cells.slice(0, 2) : cells.slice(0, 1);
    }

    return cells;
  }

  static getMeasurementRowIndex(
    perioTable: IPerioTable,
    measurement: PerioMeasurement
  ): number {
    return perioTable.rows.findIndex(
      (row) => first(row)?.metadata.measurement === measurement
    );
  }

  static isReadOnlyCell(cell: IPerioTableCell): boolean {
    return [PerioMeasurement.CAL].includes(cell.metadata.measurement);
  }

  static addClinicalAttachmentLossMeasurements(
    records: IPerioRecord[] | undefined
  ): IPerioRecord[] | undefined {
    if (!records) {
      return undefined;
    }

    return records.map((record) => {
      const pocket = record.data[PerioMeasurement.Pocket] || {};
      const recession = record.data[PerioMeasurement.Recession] || {};
      const CAL: Partial<IPerioDataPoints> = {};

      const allPoints = new Set<keyof IPerioDataPoints>([
        ...(Object.keys(pocket) as (keyof IPerioDataPoints)[]),
        ...(Object.keys(recession) as (keyof IPerioDataPoints)[]),
      ]);

      for (const point of allPoints) {
        const pocketValue = pocket[point];
        const recessionValue = recession[point];

        if (isNumber(pocketValue) && isNumber(recessionValue)) {
          CAL[point] = pocketValue + recessionValue;
        }
      }

      return {
        ...record,
        data: {
          ...record.data,
          [PerioMeasurement.CAL]: CAL,
        },
      };
    });
  }
}
