import { _isNumberValue } from '@angular/cdk/coercion';
import {
  type AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  AppointmentPopoverComponent,
  type IWaitListConfigurationData,
  WaitlistConfigurationDialogComponent,
} from '@principle-theorem/ng-appointment';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import {
  DialogPresets,
  ObservableDataSource,
} from '@principle-theorem/ng-shared';
import {
  CalendarEvent,
  WaitListScore,
} from '@principle-theorem/principle-core';
import {
  EventType,
  type ICandidateCalendarEvent,
  IEvent,
  IScheduleSummaryEventable,
  ITypesenseWaitListWithRef,
  type IWaitListCandidate,
  WaitListUrgency,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  isSameRef,
  toMomentTz,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManageCalendarEventService } from '@principle-theorem/ng-calendar';
import { GapCandidateShortlistActionsService } from '../../../gap-candidate-shortlist-actions.service';

@Component({
  selector: 'pr-wait-list',
  templateUrl: './wait-list.component.html',
  styleUrls: ['./wait-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class WaitListComponent implements AfterViewInit {
  private _matSort: MatSort;
  gapCandidates$ = new ReplaySubject<WithRef<ICandidateCalendarEvent>[]>(1);
  dataSource: ObservableDataSource<IWaitListCandidate> =
    new ObservableDataSource<IWaitListCandidate>(of([]));
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageSize = 10;
  today = moment();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() gap: IScheduleSummaryEventable;

  @Input()
  set waitlist(candidates: IWaitListCandidate[]) {
    if (candidates) {
      this.dataSource.items$ = of(candidates);
    }
  }

  @Input()
  set gapCandidates(candidates: WithRef<ICandidateCalendarEvent>[]) {
    if (candidates) {
      this.gapCandidates$.next(candidates);
    }
  }

  columnsToDisplay: string[] = [
    'add',
    'edit',
    'patient',
    'practitioner',
    'duration',
    'appointment',
    'score',
    'urgency',
    'warnings',
  ];

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _dialog: MatDialog,
    private _schedulingFacade: AppointmentSchedulingFacade,
    private _calendarEvent: ManageCalendarEventService,
    private _candidateActions: GapCandidateShortlistActionsService,
    private _snackBar: MatSnackBar
  ) {}

  @ViewChild(MatSort, { static: true })
  set matSort(sort: MatSort) {
    this._matSort = sort;
    this._setSortingAttributes();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.setFilterPredicate();
  }

  hasEvent(appointment: ITypesenseWaitListWithRef): boolean {
    return !!appointment.appointmentDuration;
  }

  duration(appointment: ITypesenseWaitListWithRef): number {
    return Number(appointment.appointmentDuration);
  }

  getMatchScore(appointment: ITypesenseWaitListWithRef): number {
    return WaitListScore.getMatchScore(appointment, this.gap.event);
  }

  async getTreatment(appointment: ITypesenseWaitListWithRef): Promise<string> {
    const { treatmentPlan } = await Firestore.getDoc(appointment.ref);
    return `${treatmentPlan.name} - ${treatmentPlan.treatmentStep.name}`;
  }

  async viewAppointmentCard({ ref }: ITypesenseWaitListWithRef): Promise<void> {
    const appointment = await Firestore.getDoc(ref);
    this._dialog.open(
      AppointmentPopoverComponent,
      DialogPresets.flex({
        data: appointment,
      })
    );
  }

  getAppointmentFrom(
    appointment: ITypesenseWaitListWithRef
  ): moment.Moment | undefined {
    if (!appointment.appointmentFrom) {
      return;
    }

    return toMomentTz(
      moment.unix(appointment.appointmentFrom),
      appointment.appointmentTimezone
    );
  }

  async getAppointmentEvent({
    ref,
  }: ITypesenseWaitListWithRef): Promise<IEvent | undefined> {
    const appointment = await Firestore.getDoc(ref);
    return appointment.event;
  }

  async addToShortlist(
    candidate: IWaitListCandidate,
    gap: IScheduleSummaryEventable
  ): Promise<void> {
    await this._candidateActions.processWaitlistCandidate(
      candidate,
      gap,
      async () => {
        await this._candidateActions.addCandidateToShortlist(candidate, gap);
        this._snackBar.open(
          `${candidate.candidate.patient.name} added to shortlist`
        );
      }
    );
  }

  isGapCandidate$(candidate: IWaitListCandidate): Observable<boolean> {
    return this.gapCandidates$.pipe(
      map((gapCandidates) =>
        gapCandidates.some((event) =>
          isSameRef(event.candidate.patient, candidate.candidate.patient)
        )
      )
    );
  }

  async openWaitlistConfiguration(
    waitListCandidate: IWaitListCandidate
  ): Promise<void> {
    const data: IWaitListConfigurationData = {
      appointment: await Firestore.getDoc(waitListCandidate.appointment.ref),
    };
    const config: MatDialogConfig = DialogPresets.medium({ data });
    this._dialog.open<
      WaitlistConfigurationDialogComponent,
      IWaitListConfigurationData
    >(WaitlistConfigurationDialogComponent, config);
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue;
  }

  setFilterPredicate(): void {
    this.dataSource.filterPredicate = (
      waitListCandidate: IWaitListCandidate,
      filter: string
    ): boolean => {
      const patientName: string =
        waitListCandidate.candidate.patient.name.toLowerCase();
      const transformedFilter: string = filter.trim().toLowerCase();
      return patientName.indexOf(transformedFilter) !== -1;
    };
  }

  async appointmentFromGap(gap: IScheduleSummaryEventable): Promise<void> {
    this._schedulingFacade.setAppointmentDetailsFromGap(gap);
    await this._router.navigate(['../../appointments/create'], {
      relativeTo: this._route,
      queryParams: {
        preserveState: true,
      },
      queryParamsHandling: 'merge',
    });
  }

  blockTime(gap: IScheduleSummaryEventable): void {
    const calendarEvent = CalendarEvent.init({
      event: {
        ...gap.event,
        type: EventType.Misc,
      },
      isBlocking: true,
    });
    void this._calendarEvent.add(calendarEvent);
  }

  private _setSortingAttributes(): void {
    this.dataSource.sort = this._matSort;
    this.dataSource.sortingDataAccessor = (
      data: IWaitListCandidate,
      sortHeaderId: string
    ): string | number => {
      switch (sortHeaderId) {
        case 'patient':
          return data.candidate.patient.name;
        case 'appointment':
          return data.appointment.dateFrom;
        case 'urgency':
          return Number(data.appointment.urgency || WaitListUrgency.Low);
        case 'productivity':
          return Number(data.productivity);
        case 'duration':
          return (
            Number(data.appointment.appointmentDuration) +
            this.getMatchScore(data.appointment)
          );
        case 'score':
          return this.getMatchScore(data.appointment);
        default:
          const value = data[sortHeaderId as keyof IWaitListCandidate];
          return _isNumberValue(value) ? Number(value) : String(value);
      }
    };
  }
}
