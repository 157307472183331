<ng-container *ngIf="patient$ | async as patient">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h2>Patient Relationships</h2>
    <button mat-flat-button color="primary" (click)="addRelationship(patient)">
      Add Relationship
    </button>
  </div>
  <mat-list-item *ngIf="patient.primaryContact">
    <mat-icon matListItemIcon matTooltip="Primary Contact">
      manage_accounts
    </mat-icon>
    <div matListItemTitle>
      <a
        [prBrandRouterLink]="[
          'patients',
          patient.primaryContact.patient.ref.id
        ]"
      >
        {{ patient.primaryContact.patient.name }}
      </a>
    </div>
    <div matListItemLine>{{ patient.primaryContact.type | titlecase }}</div>
  </mat-list-item>
  <mat-list-item
    *ngFor="
      let relationship of relationships$ | async;
      trackBy: trackByRelationship
    "
  >
    <mat-icon matListItemIcon>people</mat-icon>
    <div matListItemTitle>
      <a [prBrandRouterLink]="['patients', relationship.patient.ref.id]">
        {{ relationship.patient.name }}
      </a>
    </div>
    <div matListItemLine>{{ relationship.type | titlecase }}</div>
  </mat-list-item>
</ng-container>
