import {
  DayOfWeek,
  DocumentReference,
  ISODateType,
  SerialisedData,
  Timezone,
} from '@principle-theorem/shared';
import { WaitListUrgency } from '../gap/wait-list-item';
import { MixedSchema } from '@principle-theorem/editor';
import { IAppointment } from '../appointment/appointment';

export interface IWaitListIndexedProperties {
  id: string;
  urgency: WaitListUrgency;
  urgencyIndex: number;
  inShortList?: boolean;
  dateFrom: number;
  dateTo: number;
  timeFrom?: string;
  timeTo?: string;
  days: DayOfWeek[];
  notes?: SerialisedData<MixedSchema>;
  availableDates: ISODateType[];
  ref: string;
  appointmentRef: string;
  patientRef: string;
  patientName: string;
  searchPatientNames: string[];
  practitionerName: string;
  practitionerRef: string;
  practiceName: string;
  practiceRef: string;
  brandRef: string;
  appointmentTitle: string;
  appointmentFrom?: number;
  appointmentTo?: number;
  appointmentDuration?: number;
  appointmentDurationHuman?: string;
  appointmentTimezone: Timezone;
  treatmentCategoryRef?: string;
  treatmentCategoryName?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITypesenseWaitList extends IWaitListIndexedProperties {}

export interface ITypesenseWaitListWithRef
  extends Omit<ITypesenseWaitList, 'ref'> {
  ref: DocumentReference<IAppointment>;
}
