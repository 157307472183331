import { protos } from '@google-cloud/tasks';
import { ICloudTask } from '@principle-theorem/principle-core/interfaces';
import { Timestamp } from '@principle-theorem/shared';
import { toTimestamp } from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import Long from 'long';
import * as moment from 'moment-timezone';

type ProtobufSeconds = string | number | Long | null | undefined;

export function toCloudTask(
  data: protos.google.cloud.tasks.v2.ITask
): ICloudTask | undefined {
  if (!data.name || !data.createTime || !data.scheduleTime) {
    return;
  }
  return {
    name: data.name,
    dispatchCount: data.dispatchCount ?? 0,
    responseCount: data.responseCount ?? 0,
    createTime: convertProtobufTimestamp(data.createTime),
    scheduleTime: convertProtobufTimestamp(data.scheduleTime),
  };
}

export function convertProtobufTimestamp(
  data?: protos.google.protobuf.ITimestamp
): Timestamp {
  if (!data) {
    return toTimestamp();
  }
  const asMoment = moment.unix(
    data.seconds ? getProtobufSeconds(data.seconds as ProtobufSeconds) : 0
  );
  return toTimestamp(asMoment);
}

function getProtobufSeconds(seconds: ProtobufSeconds): number {
  if (isNumber(seconds)) {
    return seconds;
  }
  if (isString(seconds)) {
    return parseInt(seconds, 10);
  }
  if (Long.isLong(seconds)) {
    return Long.fromValue(seconds).toInt();
  }
  return 0;
}
