@if (type === 'image') {
  <section class="flex flex-col items-center justify-center">
    <img *ngIf="imagePath$ | async as imagePath" [src]="imagePath" />
    <div class="flex flex-col items-center justify-center gap-1.5">
      @if (explicitTitle) {
        <h2>{{ explicitTitle }}</h2>
      } @else {
        <h2>No {{ title | titlecase }}</h2>
      }
      @if (isSearch$ | async) {
        <p class="mat-body">
          Sorry, no results found for
          <span class="mat-body-strong">{{ searchInputValue }}</span>
        </p>
      } @else {
        @if (description) {
          <p class="mat-body">{{ description }}</p>
        } @else if (descriptionEnabled) {
          <p class="mat-body">
            Any new {{ explicitTitle || title | lowercase }} will appear here
          </p>
        }
      }
      <ng-content />
    </div>
  </section>
} @else {
  <section class="flex flex-col items-center justify-center">
    <span class="mat-caption">No {{ title | titlecase }}</span>
  </section>
}
