import {
  DocumentReference,
  INamedDocument,
  ISoftDelete,
  Time24hrType,
  Timestamp,
  isObject,
  type IFirestoreModel,
  type Timezone,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { IBridgeDevice } from '../bridge-device/bridge-device';
import { type IGeoCoordinates } from '../geo-data';
import { type IRoster } from '../roster';
import {
  type PracticeTimelineHourIncrement,
  type PracticeTimelineTooltipDelay,
} from '../timeline/interactive-timeline-display-options';
import { EventType } from '../event/event';
import { IPrintPrescriptionSettings } from '../prescription/prescription';
import { IStaffer } from '../staffer/staffer';
import { IUser } from '../..';

export enum PracticeCollection {
  MediaTags = 'tags',
  Tasks = 'tasks',
  LabJobs = 'labJobs',
  Contacts = 'contacts',
  Products = 'products',
  Equipment = 'equipment',
  Instruments = 'instruments',
  Consumables = 'consumables',
  FeeSchedules = 'feeSchedules',
  SterilisationRecords = 'sterilisationRecords',
  SterilisationCycles = 'sterilisationCycles',
  SchedulingAlerts = 'schedulingAlerts',
  RecurringTaskConfigurations = 'recurringTaskConfigurations',
  TyroTerminals = 'tyroTerminals',
  SmartpayTerminals = 'smartpayTerminals',
  BridgeDevices = 'bridgeDevices',
  ScheduleSummaries = 'scheduleSummaries',
  HicapsConnectTerminals = 'hicapsConnectTerminals',
  HicapsConnectProcesses = 'hicapsConnectProcesses',
  SchedulingEventSummaries = 'schedulingEventSummaries',
}

export interface IPracticeSettings {
  timezone: Timezone;
  timeline?: {
    stepSizeInMins?: number;
    pixelsPerMinute?: number;
    hourIncrement?: PracticeTimelineHourIncrement;
    tooltipDelay?: PracticeTimelineTooltipDelay;
  };
  eventColourOverrides?: {
    [key in EventType]?: string;
  };
  deadzoneColourOverride?: string;
  patientPortal?: {
    staffOnlineOrder?: IStaffOnlineOrder;
    bookingWindow?: IBookingWindow;
  };
  print?: {
    prescriptionMarginOffset?: IPrintPrescriptionSettings;
  };
  sterilisation?: {
    barcodeModeEnabled?: boolean;
  };
}

export enum StaffOnlineOrderOption {
  Alphabetically = 'alphabetically',
  Availability = 'availability',
  Custom = 'custom',
}

export interface IStaffOnlineOrder {
  orderBy: StaffOnlineOrderOption;
  customOrder: DocumentReference<IStaffer>[];
}

export interface ITyroTerminal extends ISoftDelete {
  name: string;
  mid: number;
  tid: number;
  lastPaired?: Timestamp;
  integrationKey?: string;
}

export interface ISmartpayTerminal extends ISoftDelete {
  name: string;
  uid: string;
  lastPaired?: Timestamp;
}

export interface IPracticeTyroSettings {
  isEnabled: boolean;
  iClient: string; // TODO: use TyroIClientUrl (currently not available for import)
  addCDBSAsPending: boolean;
}

export interface IPracticeUserRestriction {
  user: INamedDocument<IUser>;
  allowedFrom: Time24hrType;
  allowedTo: Time24hrType;
}

export interface IPracticeUserRestrictions {
  isEnabled: boolean;
  restrictions: IPracticeUserRestriction[];
}

export interface IPracticeIpWhitelist {
  isEnabled: boolean;
  whitelist: string[];
}

export interface IPracticeRestrictions {
  ipWhitelist?: IPracticeIpWhitelist;
  userRestrictions?: IPracticeUserRestrictions;
}

export interface IPracticeSmartpaySettings {
  isEnabled: boolean;
}

export interface IBookingWindow {
  min?: IBookingWindowTiming;
  max?: IBookingWindowTiming;
}

export interface IBookingWindowTiming {
  unit: OnlineBookingTimingUnit;
  amount: number;
}

export enum BookingWindowType {
  Min = 'min',
  Max = 'max',
}

export enum OnlineBookingTimingUnit {
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
}

export interface IPractice extends IFirestoreModel {
  name: string;
  slug: string;
  address: string;
  phone: string;
  email: string;
  coordinates?: IGeoCoordinates;
  settings: IPracticeSettings;
  tyroSettings?: IPracticeTyroSettings;
  smartpaySettings?: IPracticeSmartpaySettings;
  hicapsConnectSettings?: IPracticeHicapsConnectSettings;
  schedule: IRoster;
  yearOpened: number;
  restrictions?: IPracticeRestrictions;
  enabledForSchedulingAggregateTrial?: boolean; // TODO: remove after trial
}

export function isPractice(data: unknown): data is IPractice {
  return (
    isObject(data) &&
    isString(data.name) &&
    isString(data.slug) &&
    isString(data.address) &&
    isString(data.phone) &&
    isString(data.email)
  );
}

export interface IPracticeHicapsConnectSettings {
  isEnabled?: boolean;
  testModeEnabled?: boolean;
  merchantId?: string;
  addCDBSAsPending?: boolean;
}

export interface IHicapsConnectTerminal extends ISoftDelete {
  name: string;
  terminalId: string;
  bridgeDevice: DocumentReference<IBridgeDevice>;
  lastActive?: Timestamp;
}
