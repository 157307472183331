<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="wrapper flex flex-col gap-4">
  <h1 class="mat-headline-4">Treatment Configurations</h1>

  <pr-treatment-configuration-list
    [categories]="globalStore.treatmentCategories$ | async"
    [collection]="treatmentConfigurationCol$ | async"
  />
</div>
