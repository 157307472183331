import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  isObject,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export interface IPraktikaAppointmentNote {
  id: number;
  appointment_id: number;
  date: string; // '2020-02-20 17:04:23'
  note: string;
  // typeId: number;
  // author: string;
  // authorId: number;
  // editable: boolean;
  // associatedSMSStatusId: number;
}

export function isPraktikaAppointmentNote(
  item: unknown
): item is IPraktikaAppointmentNote {
  return (
    isObject(item) &&
    isNumber(item.id) &&
    isNumber(item.appointment_id) &&
    isString(item.date) &&
    isString(item.note)
    // isNumber(item.typeId) &&
    // isString(item.author) &&
    // isNumber(item.authorId) &&
    // isBoolean(item.editable) &&
    // isNumber(item.associatedSMSStatusId)
  );
}

export const PATIENT_APPOINTMENT_NOTE_RESOURCE_TYPE = 'patientAppointmentNote';

export const PATIENT_APPOINTMENT_NOTE_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Appointment Note List',
      description: '',
      idPrefix: PATIENT_APPOINTMENT_NOTE_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IPraktikaAppointmentNoteTranslations {
  date: Timestamp;
}

export interface IPraktikaAppointmentNoteFilters {
  appointmentId: string;
  date: Timestamp;
}

const PATIENT_APPOINTMENT_NOTE_SOURCE_QUERY = `
SELECT
  iNoteId as id,
  iAppointmentId as appointment_id,
  dtEffFrom as date,
  vchNote as note
FROM appointment_notes
ORDER BY id DESC
`;

export class PatientAppointmentNoteSourceEntity extends BaseSourceEntity<
  IPraktikaAppointmentNote,
  IPraktikaAppointmentNoteTranslations,
  IPraktikaAppointmentNoteFilters
> {
  sourceEntity = PATIENT_APPOINTMENT_NOTE_SOURCE_ENTITY;
  entityResourceType = PATIENT_APPOINTMENT_NOTE_RESOURCE_TYPE;
  sourceQuery = PATIENT_APPOINTMENT_NOTE_SOURCE_QUERY;
  verifySourceFn = isPraktikaAppointmentNote;
  override transformDataFn = flow([]);
  override dateFilterField: keyof IPraktikaAppointmentNoteFilters = 'date';

  translate(
    note: IPraktikaAppointmentNote,
    timezone: Timezone
  ): IPraktikaAppointmentNoteTranslations {
    return {
      date: toTimestamp(moment.tz(note.date, ISO_DATE_TIME_FORMAT, timezone)),
    };
  }

  getSourceRecordId(data: IPraktikaAppointmentNote): number {
    return data.id;
  }

  getSourceLabel(data: IPraktikaAppointmentNote): string {
    return `${data.id} ${data.date}`;
  }

  getFilterData(
    data: IPraktikaAppointmentNote,
    timezone: Timezone
  ): IPraktikaAppointmentNoteFilters {
    return {
      appointmentId: data.appointment_id.toString(),
      date: toTimestamp(moment.tz(data.date, ISO_DATE_TIME_FORMAT, timezone)),
    };
  }
}
