import { getSchemaText } from '@principle-theorem/editor';
import {
  IInteraction,
  ITask,
  TASK_PRIORITIES,
  TASK_STATUSES,
  TaskPriority,
  TaskStatus,
  TaskType,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  SerialisedData,
  TypeGuard,
  getParentDocRef,
  serialise,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import { isBoolean, isNumber, isString, isUndefined } from 'lodash';
import { type CollectionCreateSchema } from 'typesense/lib/Typesense/Collections';
import { Task } from '../task/task';
import { PrincipleTypesenseCollection } from './typesense';

export const TASK_COLLECTION_SCHEMA: CollectionCreateSchema = {
  name: PrincipleTypesenseCollection.Tasks,
  fields: [
    {
      name: 'id',
      type: 'string',
    },
    {
      name: 'title',
      type: 'string',
      sort: true,
    },
    {
      name: 'description',
      type: 'string',
      optional: true,
    },
    {
      name: 'createInteraction',
      type: 'auto',
      optional: true,
      index: false,
    },
    {
      name: 'lastInteraction',
      type: 'auto',
      optional: true,
      index: false,
    },
    {
      name: 'createdAt',
      type: 'int64',
      sort: true,
    },
    {
      name: 'visibleFrom',
      type: 'int64',
      sort: true,
    },
    {
      name: 'dueDate',
      type: 'int64',
      optional: true,
      sort: true,
    },
    {
      name: 'completedDate',
      type: 'int32',
      optional: true,
      sort: true,
    },
    {
      name: 'assignedName',
      type: 'string',
      optional: true,
    },
    {
      name: 'assignedTeamName',
      type: 'auto',
      optional: true,
    },
    {
      name: 'assignedUserName',
      type: 'auto',
      optional: true,
    },
    {
      name: 'assignedRef',
      type: 'string',
      optional: true,
    },
    {
      name: 'ownerName',
      type: 'string',
      optional: true,
    },
    {
      name: 'ownerRef',
      type: 'string',
      optional: true,
    },
    {
      name: 'isRecurring',
      type: 'bool',
    },
    {
      name: 'status',
      type: 'string',
      facet: true,
    },
    {
      name: 'statusIndex',
      type: 'int32',
      sort: true,
    },
    {
      name: 'priority',
      type: 'string',
      facet: true,
    },
    {
      name: 'priorityIndex',
      type: 'int32',
      sort: true,
    },
    {
      name: 'type',
      type: 'string',
      facet: true,
    },
    {
      name: 'mentionRefs',
      type: 'string[]',
    },
    {
      name: 'ref',
      type: 'string',
    },
    {
      name: 'practiceRef',
      type: 'string',
    },
    {
      name: 'brandRef',
      type: 'string',
    },
    {
      name: 'deleted',
      type: 'bool',
      facet: true,
    },
    {
      name: 'embedding',
      type: 'float[]',
      embed: {
        from: ['title', 'description'],
        model_config: {
          model_name: 'ts/all-MiniLM-L12-v2',
        },
      },
    },
  ],
  default_sorting_field: 'createdAt',
};

export interface ITaskIndexedProperties {
  id: string;
  title: string;
  description?: string;
  createdAt: number;
  visibleFrom: number;
  dueDate?: number;
  completedDate?: number;
  assignedName?: string;
  assignedUserName?: string;
  assignedTeamName?: string;
  assignedRef?: string;
  ownerName?: string;
  ownerRef?: string;
  isRecurring: boolean;
  status: TaskStatus;
  statusIndex: number;
  priority: TaskPriority;
  priorityIndex: number;
  type: TaskType;
  mentionRefs: string[];
  ref: string;
  practiceRef: string;
  brandRef: string;
  deleted: boolean;
  createInteraction?: SerialisedData<IInteraction>;
  lastInteraction?: SerialisedData<IInteraction>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITypesenseTask extends ITaskIndexedProperties {}

export interface ITypesenseTaskWithRef extends Omit<ITypesenseTask, 'ref'> {
  ref: DocumentReference<ITask>;
}

export class TypesenseTask {
  static fromTask(task: WithRef<ITask>): ITypesenseTask {
    const practiceRef = getParentDocRef(task.ref);
    const brandRef = getParentDocRef(practiceRef);

    return {
      id: task.ref.id,
      title: getSchemaText(task.title),
      createInteraction: serialise(Task.createdInteraction(task)),
      lastInteraction: serialise(
        task.interactions[task.interactions.length - 1]
      ),
      description: task.description
        ? getSchemaText(task.description)
        : undefined,
      isRecurring: !!task.recurrenceConfiguration,
      createdAt: toMoment(task.createdAt).unix(),
      visibleFrom: toMoment(task.visibleFrom).unix(),
      dueDate: task.dueDate ? toMoment(task.dueDate).unix() : undefined,
      completedDate: task.completedDate
        ? toMoment(task.completedDate).unix()
        : undefined,
      assignedName: task.assignedUser
        ? task.assignedUser.name
        : task.assignedTeam
          ? task.assignedTeam.name
          : undefined,
      assignedUserName: task.assignedUser ? task.assignedUser.name : undefined,
      assignedTeamName: task.assignedTeam ? task.assignedTeam.name : undefined,
      assignedRef: task.assignedUser
        ? task.assignedUser.ref.path
        : task.assignedTeam
          ? task.assignedTeam.ref.path
          : undefined,
      ownerName: task.owner ? task.owner.name : undefined,
      ownerRef: task.owner ? task.owner.ref.path : undefined,
      status: task.status,
      statusIndex: TASK_STATUSES.indexOf(task.status),
      priority: task.priority,
      priorityIndex: TASK_PRIORITIES.indexOf(task.priority),
      type: task.type,
      mentionRefs: task.mentionRefs.map((mentionRef) => mentionRef.path),
      ref: task.ref.path,
      practiceRef: practiceRef.path,
      brandRef: brandRef.path,
      deleted: task.deleted,
    };
  }
}

export function isTypesenseTask(item: unknown): item is ITypesenseTask {
  return TypeGuard.interface<ITypesenseTask>({
    id: isString,
    title: isString,
    description: TypeGuard.undefinedOr(isString),
    isRecurring: isBoolean,
    createdAt: isNumber,
    visibleFrom: isNumber,
    dueDate: TypeGuard.undefinedOr(isNumber),
    completedDate: TypeGuard.undefinedOr(isNumber),
    assignedName: TypeGuard.undefinedOr(isString),
    assignedUserName: TypeGuard.undefinedOr(isString),
    assignedTeamName: TypeGuard.undefinedOr(isString),
    assignedRef: TypeGuard.undefinedOr(isString),
    ownerName: TypeGuard.undefinedOr(isString),
    ownerRef: TypeGuard.undefinedOr(isString),
    status: TypeGuard.enumValue(TaskStatus),
    statusIndex: isNumber,
    priority: TypeGuard.enumValue(TaskPriority),
    priorityIndex: isNumber,
    type: TypeGuard.enumValue(TaskType),
    mentionRefs: TypeGuard.arrayOf(isString),
    ref: isString,
    practiceRef: isString,
    brandRef: isString,
    deleted: isBoolean,
    lastInteraction: [TypeGuard.noGuard(), isUndefined],
    createInteraction: [TypeGuard.noGuard(), isUndefined],
  })(item);
}
