import {
  TypesenseIntegration,
  type IIntegration,
  type ITypesenseIntegrationData,
} from '@principle-theorem/integrations';
import {
  type CollectionReference,
  type DocumentReference,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Client as TypesenseClient,
  SearchClient as TypesenseSearchClient,
} from 'typesense';
import {
  type CollectionSchema,
  type CollectionUpdateSchema,
} from 'typesense/lib/Typesense/Collection';
import { type CollectionCreateSchema } from 'typesense/lib/Typesense/Collections';
import { type ConfigurationOptions } from 'typesense/lib/Typesense/Configuration';
import { type KeySchema } from 'typesense/lib/Typesense/Key';
import { type ITypesenseConfig } from './config';

export class Typesense {
  static getClient(options: ConfigurationOptions): TypesenseClient {
    return new TypesenseClient({
      numRetries: 5,
      connectionTimeoutSeconds: 20,
      ...options,
    });
  }

  static getSearchClient(options: ConfigurationOptions): TypesenseSearchClient {
    return new TypesenseSearchClient({
      numRetries: 5,
      connectionTimeoutSeconds: 20,
      ...options,
    });
  }

  static async getCollection(
    client: TypesenseClient,
    collectionName: string
  ): Promise<CollectionSchema | undefined> {
    try {
      const schema = await client.collections(collectionName).retrieve();
      return schema;
    } catch {
      // no collection found
      return;
    }
  }

  static async updateCollectionSchema(
    client: TypesenseClient,
    collectionName: string,
    schema: CollectionUpdateSchema
  ): Promise<void> {
    try {
      await client.collections(collectionName).update(schema);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        `Failed to update collection schema: ${collectionName}`,
        error
      );
      return;
    }
  }

  static async createCollection(
    client: TypesenseClient,
    schema: CollectionCreateSchema
  ): Promise<CollectionSchema> {
    return client.collections().create(schema);
  }

  static async findOrCreate(
    client: TypesenseClient,
    schema: CollectionCreateSchema
  ): Promise<CollectionSchema> {
    const found = await Typesense.getCollection(client, schema.name);
    if (!found) {
      return Typesense.createCollection(client, schema);
    }
    return found;
  }

  static getScopedCollectionName(
    scopes: DocumentReference[],
    collectionName: string
  ): string {
    return `${scopes.map((scope) => scope.id).join('_')}_${collectionName}`;
  }

  static getScopedCollectionSchema(
    scopes: DocumentReference[],
    schema: CollectionCreateSchema
  ): CollectionCreateSchema {
    return {
      ...schema,
      name: Typesense.getScopedCollectionName(scopes, schema.name),
    };
  }

  static async generateSearchKey(
    client: TypesenseClient,
    scope: DocumentReference,
    description?: string,
    additionalCollections: string[] = []
  ): Promise<KeySchema> {
    return client.keys().create({
      collections: [`${scope.id}_.*`, ...additionalCollections],
      actions: ['documents:search'],
      description,
    });
  }

  static getScopedSearchClient$(
    config: ITypesenseConfig,
    integrationCol: CollectionReference<IIntegration<ITypesenseIntegrationData>>
  ): Observable<TypesenseSearchClient> {
    const storage = new TypesenseIntegration();
    return storage.get$(integrationCol).pipe(
      map((integration) => {
        if (!integration) {
          throw new Error('Missing Required Typesense Integration Data');
        }
        const apiKey = TypesenseIntegration.getKey(
          integration.data,
          config.clusterName
        );

        if (!apiKey) {
          throw new Error('Missing API Key Integration Data');
        }

        return Typesense.getSearchClient({
          ...config,
          apiKey,
        });
      })
    );
  }
}
