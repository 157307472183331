import {
  Storage as FirebaseStorage,
  deleteObject,
  ref,
} from '@angular/fire/storage';
import {
  OrganisationService,
  TypesenseSearchService,
} from '@principle-theorem/ng-principle-shared';
import { NgFireMediaUploader } from '@principle-theorem/ng-shared';
import { Brand, BridgeDevice, Tag } from '@principle-theorem/principle-core';
import {
  addDoc,
  deleteDoc,
  filterUndefined,
  getError,
  httpsCallable,
  safeCombineLatest,
  snapshot,
} from '@principle-theorem/shared';
import { get, push, remove, set } from 'firebase/database';
import { isEqual } from 'lodash';
import { Observable, defer, from, of } from 'rxjs';
import { catchError, concatMap, map, startWith, take } from 'rxjs/operators';

export enum InfrastructureType {
  Firestore = 'firestore',
  FunctionsV1 = 'functionsV1',
  FunctionsV2 = 'functionsV2',
  RealtimeDatabase = 'realtimeDatabase',
  Storage = 'storage',
  Typesense = 'typesense',
}

export enum InfrastructureTestResultStatus {
  Pending = 'pending',
  Passed = 'passed',
  Failed = 'failed',
}

type InfrastructureTestFn = () => Observable<IInfrastructureTestResult>;

export interface IInfrastructureTestResult {
  type: InfrastructureType;
  status: InfrastructureTestResultStatus;
  message?: string;
}

export class TestInfrastructureServices {
  readonly infrastructureTypes: Record<
    InfrastructureType,
    InfrastructureTestFn
  > = {
    [InfrastructureType.Firestore]: this.runFirestoreTests$,
    [InfrastructureType.FunctionsV1]: this.runFunctionsV1Tests$,
    [InfrastructureType.FunctionsV2]: this.runFunctionsV2Tests$,
    [InfrastructureType.RealtimeDatabase]: this.runRealtimeDatabaseTests$,
    [InfrastructureType.Storage]: this.runStorageTests$,
    [InfrastructureType.Typesense]: this.runTypesenseTests$,
  };

  constructor(
    private _organisationService: OrganisationService,
    private _storage: FirebaseStorage,
    private _typesenseSearch: TypesenseSearchService
  ) {}

  getInitialTestResults(): IInfrastructureTestResult[] {
    return [
      {
        type: InfrastructureType.Firestore,
        status: InfrastructureTestResultStatus.Pending,
      },
      {
        type: InfrastructureType.RealtimeDatabase,
        status: InfrastructureTestResultStatus.Pending,
      },
      {
        type: InfrastructureType.FunctionsV1,
        status: InfrastructureTestResultStatus.Pending,
      },
      {
        type: InfrastructureType.FunctionsV2,
        status: InfrastructureTestResultStatus.Pending,
      },
      {
        type: InfrastructureType.Storage,
        status: InfrastructureTestResultStatus.Pending,
      },
      {
        type: InfrastructureType.Typesense,
        status: InfrastructureTestResultStatus.Pending,
      },
    ];
  }

  runTests$(): Observable<IInfrastructureTestResult[]> {
    return safeCombineLatest(
      Object.values(this.infrastructureTypes).map((testFn$) =>
        testFn$.bind(this)()
      )
    );
  }

  runFirestoreTests$(): Observable<IInfrastructureTestResult> {
    return from(this._organisationService.brand$).pipe(
      filterUndefined(),
      take(1),
      concatMap(async (brand) => {
        const tagCol = Brand.patientTagCol(brand);
        const testTag = Tag.init({
          name: 'test',
        });
        const tagRef = await addDoc(tagCol, testTag);
        await deleteDoc(tagRef);

        return {
          type: InfrastructureType.Firestore,
          status: InfrastructureTestResultStatus.Passed,
        };
      }),
      catchError((error) => {
        return of({
          type: InfrastructureType.Firestore,
          status: InfrastructureTestResultStatus.Failed,
          message: getError(error),
        });
      }),
      startWith({
        type: InfrastructureType.Firestore,
        status: InfrastructureTestResultStatus.Pending,
      })
    );
  }

  runRealtimeDatabaseTests$(): Observable<IInfrastructureTestResult> {
    return from(this._organisationService.organisation$).pipe(
      filterUndefined(),
      take(1),
      concatMap(async (organisation) => {
        const deviceDatabaseRef = BridgeDevice.getDeviceDatabaseRef(
          organisation.ref.id,
          'test-device',
          '/commands'
        );
        const testRef = await push(deviceDatabaseRef);
        const testData = { isTest: true };

        await set(testRef, testData);

        const response: unknown = (await get(testRef)).val();

        if (!isEqual(testData, response)) {
          throw new Error('Data mismatch');
        }

        await remove(testRef);

        return {
          type: InfrastructureType.RealtimeDatabase,
          status: InfrastructureTestResultStatus.Passed,
        };
      }),
      catchError((error) => {
        return of({
          type: InfrastructureType.RealtimeDatabase,
          status: InfrastructureTestResultStatus.Failed,
          message: getError(error),
        });
      }),
      startWith({
        type: InfrastructureType.RealtimeDatabase,
        status: InfrastructureTestResultStatus.Pending,
      })
    );
  }

  runFunctionsV1Tests$(): Observable<IInfrastructureTestResult> {
    return defer(httpsCallable<void, boolean>('http-tests-testV1')).pipe(
      take(1),
      map(() => ({
        type: InfrastructureType.FunctionsV1,
        status: InfrastructureTestResultStatus.Passed,
      })),
      catchError((error) => {
        return of({
          type: InfrastructureType.FunctionsV1,
          status: InfrastructureTestResultStatus.Failed,
          message: getError(error),
        });
      }),
      startWith({
        type: InfrastructureType.FunctionsV1,
        status: InfrastructureTestResultStatus.Pending,
      })
    );
  }

  runFunctionsV2Tests$(): Observable<IInfrastructureTestResult> {
    return defer(httpsCallable<void, boolean>('http-tests-testV2')).pipe(
      take(1),
      map(() => ({
        type: InfrastructureType.FunctionsV2,
        status: InfrastructureTestResultStatus.Passed,
      })),
      catchError((error) => {
        return of({
          type: InfrastructureType.FunctionsV2,
          status: InfrastructureTestResultStatus.Failed,
          message: getError(error),
        });
      }),
      startWith({
        type: InfrastructureType.FunctionsV2,
        status: InfrastructureTestResultStatus.Pending,
      })
    );
  }

  runStorageTests$(): Observable<IInfrastructureTestResult> {
    return from(this._organisationService.brand$).pipe(
      filterUndefined(),
      take(1),
      concatMap(async (brand) => {
        const storagePath = Brand.logoStoragePath(brand);
        const uploader = new NgFireMediaUploader(
          this._storage,
          of(storagePath)
        );

        const singlePixel = new File(
          [
            `iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAAAXRSTlMAQObYZgAAAApJREFUCNdjYAAAAAIAAeIhvDMAAAAASUVORK5CYII=`,
          ],
          'pixel.png',
          {
            type: 'image/png',
          }
        );
        const upload = uploader.upload(singlePixel);
        const uploadComplete = await snapshot(upload.isUploadComplete$);
        if (!uploadComplete) {
          throw new Error('Failed to upload image');
        }

        const attachment = await snapshot(upload.attachment$);

        await deleteObject(ref(this._storage, attachment.link));

        return {
          type: InfrastructureType.Storage,
          status: InfrastructureTestResultStatus.Passed,
        };
      }),
      catchError((error) => {
        return of({
          type: InfrastructureType.Storage,
          status: InfrastructureTestResultStatus.Failed,
          message: getError(error),
        });
      }),
      startWith({
        type: InfrastructureType.Storage,
        status: InfrastructureTestResultStatus.Pending,
      })
    );
  }

  runTypesenseTests$(): Observable<IInfrastructureTestResult> {
    return this._typesenseSearch.patientQuery$(of('Aaron Anderson')).pipe(
      take(1),
      map(() => ({
        type: InfrastructureType.Typesense,
        status: InfrastructureTestResultStatus.Passed,
      })),
      catchError((error) => {
        return of({
          type: InfrastructureType.Typesense,
          status: InfrastructureTestResultStatus.Failed,
          message: getError(error),
        });
      }),
      startWith({
        type: InfrastructureType.Typesense,
        status: InfrastructureTestResultStatus.Pending,
      })
    );
  }
}
