import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  BasicDialogService,
  ConnectedDialogConfig,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import { ChartableSurfaceResolver } from '@principle-theorem/principle-core';
import {
  type AnyChartedItemConfiguration,
  ChartableSurface,
  type IChartedItem,
  type IChartedItemConfiguration,
  type IChartedMultiStepTreatment,
  type IChartedRef,
  type IMultiTreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  getDoc,
  reduceToSingleArrayFn,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { tap } from 'rxjs/operators';
import {
  ChartSurfaceSelectorDialogComponent,
  type IChartSurfaceSelectorData,
} from './components/chart-surface-selector-dialog/chart-surface-selector-dialog.component';
import { MultiTreatmentBuilder } from './components/multi-treatment-surface-selector-dialog/multi-treatment-builder';
import { type IMultiTreatmentSurfaceSelectorData } from './components/multi-treatment-surface-selector-dialog/multi-treatment-selector.store';
import { MultiTreatmentSurfaceSelectorDialogComponent } from './components/multi-treatment-surface-selector-dialog/multi-treatment-surface-selector-dialog.component';

@Injectable()
export class ChartDialogService {
  constructor(
    private _connectedDialog: BasicDialogService,
    private _dialog: MatDialog,
    private _chartStore: ChartFacade
  ) {}

  async getSurfaces(
    chartable: AnyChartedItemConfiguration,
    dialogOverrides?: Partial<
      ConnectedDialogConfig<IChartSurfaceSelectorData, Partial<IChartedRef>[]>
    >,
    chartedItem?: IChartedItem<IChartedItemConfiguration>
  ): Promise<Partial<IChartedRef>[] | undefined> {
    const compatibleSurfaces =
      ChartableSurfaceResolver.getChartableSurfaces(chartable);

    if (isEqual(compatibleSurfaces, [ChartableSurface.WholeMouth])) {
      return [
        {
          wholeMouth: true,
        },
      ];
    }

    if (
      isEqual(compatibleSurfaces, [ChartableSurface.Unscoped]) ||
      !compatibleSurfaces.length
    ) {
      return [
        {
          unscoped: true,
        },
      ];
    }

    const selectedSurfaces = chartedItem
      ? chartedItem.chartedSurfaces.map((surface) => surface.chartedRef)
      : [];

    const config: ConnectedDialogConfig<
      IChartSurfaceSelectorData,
      Partial<IChartedRef>[]
    > = {
      width: '870px',
      maxWidth: '80%',
      data: {
        label: chartable.name,
        compatibleSurfaces,
        selectedSurfaces,
        config: chartedItem?.config,
      },
      restoreFocus: false,
      ...dialogOverrides,
    };

    return this._connectedDialog
      .connected(ChartSurfaceSelectorDialogComponent, config)
      .closed.pipe(
        tap(() => {
          this._chartStore.setDisabledSurfaces(ChartId.InAppointment, []);
          this._chartStore.setSelectedSurfaces(ChartId.InAppointment, []);
          this._chartStore.forceAllowInteractivity(
            ChartId.InAppointment,
            false
          );
        })
      )
      .toPromise();
  }

  async chartMultiTreatment(
    chartable: WithRef<IMultiTreatmentConfiguration>,
    selectedSurfaces: Partial<IChartedRef>[]
  ): Promise<IChartedMultiStepTreatment | undefined> {
    if (await this._canBeAutoCharted(chartable)) {
      return this._getChartedMultiTreatment(chartable, []);
    }

    const config = DialogPresets.large({
      width: '95%',
      maxWidth: '1400px',
      data: {
        label: chartable.name,
        chartable,
        selectedSurfaces,
      },
      restoreFocus: false,
    });

    return this._dialog
      .open<
        MultiTreatmentSurfaceSelectorDialogComponent,
        IMultiTreatmentSurfaceSelectorData,
        IChartedMultiStepTreatment
      >(MultiTreatmentSurfaceSelectorDialogComponent, config)
      .afterClosed()
      .toPromise();
  }

  private async _canBeAutoCharted(
    chartable: WithRef<IMultiTreatmentConfiguration>
  ): Promise<boolean> {
    if (!chartable.steps.length) {
      return false;
    }

    const treatmentRefs = chartable.steps
      .map((step) => step.treatments)
      .reduce(reduceToSingleArrayFn, []);

    if (!treatmentRefs.length) {
      return false;
    }

    const treatments = await asyncForEach(treatmentRefs, (ref) =>
      getDoc(ref.ref)
    );

    return treatments.every(
      (treatment) =>
        !treatment.availableSurfaces.length ||
        treatment.availableSurfaces.every(
          (available) =>
            available === ChartableSurface.WholeMouth ||
            available === ChartableSurface.Unscoped
        )
    );
  }

  private async _getChartedMultiTreatment(
    chartable: WithRef<IMultiTreatmentConfiguration>,
    selectedSurfaces: Partial<IChartedRef>[]
  ): Promise<IChartedMultiStepTreatment> {
    const feeSchedule = await snapshot(
      this._chartStore.getFeeScheduleManager().currentSchedule$
    );
    const builder = new MultiTreatmentBuilder(
      this._chartStore.getFeeScheduleManager(),
      this._chartStore.chartingAs$(ChartId.InAppointment)
    );
    let stepsWithSurfaces = await asyncForEach(chartable.steps, (step) => {
      return builder.initialiseTreatmentStepWithSurfaces(
        step,
        selectedSurfaces,
        feeSchedule
      );
    });

    const defaultPackage = chartable.packages.find(
      (multiTreatmentPackage) => multiTreatmentPackage.isDefault
    );
    if (defaultPackage) {
      stepsWithSurfaces = builder.applyPackage(
        defaultPackage,
        stepsWithSurfaces,
        feeSchedule
      );
    }

    return builder.getChartedMultiTreatment(
      chartable,
      stepsWithSurfaces,
      feeSchedule
    );
  }
}
