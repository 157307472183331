import {
  ChecklistItemAction,
  ChecklistType,
  IChecklistItem,
} from '@principle-theorem/principle-core/interfaces';
import { AtLeast } from '@principle-theorem/shared';

export class ChecklistItem {
  static init(overrides: AtLeast<IChecklistItem, 'type'>): IChecklistItem {
    return {
      title: '',
      action: ChecklistItemAction.None,
      data: '',
      done: false,
      deleted: false,
      ...overrides,
    };
  }

  static print(item: IChecklistItem): void {
    const popupWin: Window | null = window.open('', '_blank');
    if (!popupWin) {
      throw new Error('Print window could not be opened');
    }
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print thing</title>
        </head>
        <body onload="window.print();window.close()">
          ${item.data ?? ''}
        </body>
      </html>`);
    popupWin.document.close();
  }

  static allDone(items: IChecklistItem[]): boolean {
    return items.every((item: IChecklistItem) => item.done);
  }

  static isDuplicateChecklistItem(
    title: string,
    type: ChecklistType,
    checklists: IChecklistItem[]
  ): boolean {
    return checklists.some(
      (checklistItem) =>
        checklistItem.title === title && checklistItem.type === type
    );
  }
}
