@if (element$ | async; as element) {
  @if (isInteractive$ | async) {
    <div
      class="element-container interactive relative flex flex-row overflow-hidden rounded-md border-2 border-solid border-transparent"
      cdkDrag
      [cdkDragData]="element"
      [ngClass]="{
        selected: isSelected$ | async,
        dragging: formBuilder.dragging$ | async
      }"
      (cdkDragStarted)="dragStarted.emit($event)"
      (cdkDragEnded)="dragEnded.emit($event)"
    >
      <div
        *cdkDragPlaceholder
        class="border-primary-200 bg-primary-50 h-16 w-full rounded border border-solid"
      ></div>

      <div class="element-main flex grow flex-col">
        <pr-form-builder-element-preview
          class="pointer-events-none flex"
          [element]="element"
        />
        <ng-container
          *ngTemplateOutlet="elementChildren; context: { element: element }"
        />
      </div>

      <div
        cdkDragHandle
        class="element-toolbar bg-primary-500 absolute left-0 top-0 flex cursor-pointer flex-row items-center rounded-br text-slate-100"
      >
        <mat-icon>drag_handle</mat-icon>
        <div class="px-2 py-1 text-xs">{{ element.type | titlecase }}</div>
      </div>
    </div>
  } @else {
    <div class="element-container flex min-h-full min-w-full flex-col">
      <div class="element-main flex grow flex-col">
        <pr-form-builder-element-preview
          class="pointer-events-none flex"
          [element]="element"
        />
        <ng-container
          *ngTemplateOutlet="elementChildren; context: { element: element }"
        />
      </div>
    </div>
  }
}

<ng-template #elementChildren let-element="element">
  @if (dropListCategory$ | async; as dropListCategory) {
    <div
      class="element-children flex min-h-[50px] w-full grow flex-col gap-1 rounded border-2 border-dashed border-transparent bg-white p-2"
      cdkDropList
      cdkDropListOrientation="vertical"
      [id]="dropListId$ | async"
      [cdkDropListConnectedTo]="formBuilder.dropListIds$ | async"
      [cdkDropListData]="element.children"
      [cdkDropListEnterPredicate]="dropListEnterPredicate$ | async"
      (cdkDropListDropped)="dragDrop.emit($event)"
    >
      @for (child of element.children; track child.uid) {
        <pr-form-builder-element
          [element]="child"
          (dragDrop)="dragDrop.emit($event)"
          (dragStarted)="dragStarted.emit($event)"
          (dragEnded)="dragEnded.emit($event)"
        />
      } @empty {
        <div class="p-2 text-xs text-slate-400">
          Drag new {{ dropListCategory }} elements into the layout here
        </div>
      }
    </div>
  } @else {
    <div
      class="element-children flex h-full grow gap-1"
      [ngClass]="{
        'flex-col': (isHorizontal$ | async) === false,
        'flex-row flex-wrap': isHorizontal$ | async
      }"
    >
      @for (child of element.children; track child.uid) {
        <pr-form-builder-element
          class="flex-1"
          [element]="child"
          (dragDrop)="dragDrop.emit($event)"
          (dragStarted)="dragStarted.emit($event)"
          (dragEnded)="dragEnded.emit($event)"
        />
      }
    </div>
  }
</ng-template>
