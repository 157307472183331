import { ITag } from '@principle-theorem/principle-core/interfaces';
import {
  getTextOverlayColour,
  initFirestoreModel,
} from '@principle-theorem/shared';

export class Tag {
  static init(overrides?: Partial<ITag>): ITag {
    return {
      name: 'New Tag',
      hexColour: '#000000',
      ...initFirestoreModel(),
      ...overrides,
    };
  }

  static textColour(tag: ITag): string {
    return getTextOverlayColour(tag.hexColour);
  }
}
