import { JsonSchemaFormComponent } from '@ajsf/core';
import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { type FormGroup } from '@angular/forms';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import {
  FORM_FRAMEWORK,
  ISubmittedForm,
  type IBaseFormLayoutElement,
  type ICustomFormData,
  type IFormSchema,
} from '@principle-theorem/principle-core/interfaces';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

interface ICustomFormOptions {
  addSubmit: boolean | 'auto';
}

interface IJsonSchemaForm<T> {
  schema?: IFormSchema;
  layout?: IBaseFormLayoutElement[];
  data?: T;
  options?: ICustomFormOptions;
  framework?: string;
}

type CustomFormData<T> = ICustomFormData<T> | ISubmittedForm<T>;

@Component({
  selector: 'pr-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss'],
  providers: [{ provide: DateAdapter, useClass: NativeDateAdapter }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFormComponent<T> implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _schemaForm$ = new ReplaySubject<JsonSchemaFormComponent>(1);
  private _form$ = new ReplaySubject<CustomFormData<T>>(1);
  private _hideSubmit$ = new BehaviorSubject<boolean>(false);
  isDisabled$ = new BehaviorSubject<boolean>(false);
  jsonSchemaForm$: Observable<IJsonSchemaForm<T>>;
  @Output() submitted = new EventEmitter<object>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() formChanges = new EventEmitter<object>();

  @ViewChild(JsonSchemaFormComponent, { static: false })
  set schemaForm(schemaForm: JsonSchemaFormComponent) {
    this._schemaForm$.next(schemaForm);
  }

  @Input()
  set disabled(readonly: BooleanInput) {
    this.isDisabled$.next(coerceBooleanProperty(readonly));
  }

  @Input()
  set form(form: CustomFormData<T>) {
    if (form) {
      this._form$.next(form);
    }
  }

  @Input()
  set hideSubmit(hideSubmit: BooleanInput) {
    this._hideSubmit$.next(coerceBooleanProperty(hideSubmit));
  }

  constructor() {
    this.jsonSchemaForm$ = combineLatest([
      this._form$,
      this.isDisabled$,
      this._hideSubmit$,
    ]).pipe(
      map(
        ([form, isDisabled, hideSubmit]): IJsonSchemaForm<T> => ({
          ...form,
          framework: FORM_FRAMEWORK,
          options: { addSubmit: isDisabled || hideSubmit ? false : 'auto' },
        })
      )
    );
    combineLatest([this._schemaForm$, this.isDisabled$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([schemaForm, isDisabled]) =>
        this._setFormDisabled(schemaForm, isDisabled)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  submit($event: object): void {
    const wasDisabled = this.isDisabled$.value;
    if (wasDisabled) {
      return;
    }
    this.isDisabled$.next(true);
    this.submitted.emit($event);
    this.isDisabled$.next(wasDisabled);
  }

  private _setFormDisabled(
    form: JsonSchemaFormComponent,
    isDisabled: boolean
  ): void {
    if (!form || !form.jsf || !form.jsf.formGroup) {
      return;
    }
    form.jsf.setOptions({});
    const formGroup = form.jsf.formGroup as FormGroup;
    if (isDisabled) {
      formGroup.disable();
      return;
    }
    formGroup.enable();
  }
}
