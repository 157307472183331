import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BaseResolver } from '@principle-theorem/ng-shared';
import { Brand } from '@principle-theorem/principle-core';
import {
  ICustomFormConfiguration,
  type IBrand,
} from '@principle-theorem/principle-core/interfaces';
import {
  type CollectionReference,
  type WithRef,
} from '@principle-theorem/shared';

@Injectable()
export class BrandCustomFormCollectionResolverService extends BaseResolver<
  CollectionReference<ICustomFormConfiguration>
> {
  static readonly resolverKey: string = 'customFormCollection';

  resolve(
    route: ActivatedRouteSnapshot
  ): CollectionReference<ICustomFormConfiguration> {
    const brand = this._findInheritedRouteData<WithRef<IBrand>>(route, 'brand');
    if (!brand) {
      throw new Error('No Brand found');
    }
    return Brand.customFormConfigCol(brand);
  }
}
