import {
  EventType,
  type IBrand,
  type IEventTimePeriod,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  type DocumentReference,
  Firestore,
  ISO_DATE_FORMAT,
  type ITimePeriod,
  TimeBucket,
  type WithRef,
  asyncForEach,
  getEnumValues,
  getTimePeriodDuration,
  multiMap,
  query,
  reduce2DArray,
  snapshot,
  where,
  type IDataTable,
} from '@principle-theorem/shared';
import {
  type Dictionary,
  clamp,
  compact,
  difference,
  first,
  flatten,
  groupBy,
  sum,
  toPairs,
  uniq,
} from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { dateRangeToPracticeTimezone } from './helpers';
import { Brand } from '../models/brand';
import { OptionFinderQuery } from '../models/event/event-option-finder';
import { practiceStaffAvailableTimes } from '../models/event/brand-event-option-finder';

type RosteredOnMap = Dictionary<(ITimePeriod & IEventTimePeriod)[]>;
type GapsMap = Dictionary<IEventTimePeriod[]>;

interface ITimeUtilisationForDay {
  date: string;
  practitioner: string;
  practice: string;
  rosteredOnHours: number;
  gapHours: number;
}

interface IRecord {
  date: string;
  practitioner: string;
  practice: string;
  rosteredTime: number;
  gapTime: number;
  utilisationPct: number;
}

interface ISummary {
  practitioner: string;
  practice: string;
  rosteredTime: number;
  gapTime: number;
  utilisationPct: number;
}

export class PractitionerGaps {
  async run(
    dateRange: ITimePeriod,
    brandRef: DocumentReference<IBrand>,
    skipPractitioners: DocumentReference<IStaffer>[] = [],
    groupRecordsBy: 'day' | 'week' = 'day'
  ): Promise<(IDataTable<IRecord> | IDataTable<ISummary>)[]> {
    const brand = await Firestore.getDoc(brandRef);

    const practices = await query(
      Brand.practiceCol(brand),
      where('deleted', '==', false)
    );

    const rows = await asyncForEach(practices, async (practice) => {
      const practiceDateRange = dateRangeToPracticeTimezone(
        practice,
        dateRange
      );
      const optionFinderQuery: OptionFinderQuery = [
        practiceDateRange.from,
        practiceDateRange.to,
      ];
      const rosteredOn = await snapshot(
        this._getRosteredOn$(brand, practice, optionFinderQuery, groupRecordsBy)
      );
      const gaps: Dictionary<IEventTimePeriod[]> = await snapshot(
        this._getGaps$(brand, practice, optionFinderQuery, groupRecordsBy)
      );
      return this._getData(rosteredOn, gaps, skipPractitioners);
    });

    const data = flatten(rows);
    const records = this._getRecords(data);
    const summaries = this._getSummaries(data);
    return this._getDataTables(records, summaries);
  }

  private _getRosteredOn$(
    brand: WithRef<IBrand>,
    practice: WithRef<IPractice>,
    optionFinderQuery: OptionFinderQuery,
    groupRecordsBy: 'day' | 'week' = 'day'
  ): Observable<RosteredOnMap> {
    const includedTypes = difference(getEnumValues(EventType), [
      EventType.GapCandidate,
      EventType.PreBlock,
    ]);

    return of(optionFinderQuery).pipe(
      practiceStaffAvailableTimes(
        practice,
        brand,
        undefined,
        true,
        undefined,
        includedTypes
      ),
      multiMap((result) => {
        const timesToRemove = TimeBucket.fromEvents(
          result.overlappingEvents
            .filter(
              (eventable) =>
                ![
                  EventType.Appointment,
                  EventType.Misc,
                  EventType.PreBlock,
                ].includes(eventable.event.type)
            )
            .map((eventable) => eventable.event)
        ).get();

        const availableTimes = new TimeBucket([result])
          .subtract(timesToRemove)
          .sort()
          .get();

        return availableTimes.map((time) => ({
          ...result,
          ...time,
        }));
      }),
      reduce2DArray(),
      map((results) =>
        groupBy(
          results,
          (result) =>
            `${result.staffer.name}-${result.from
              .clone()
              .startOf(groupRecordsBy)
              .format(ISO_DATE_FORMAT)}`
        )
      )
    );
  }

  private _getGaps$(
    brand: WithRef<IBrand>,
    practice: WithRef<IPractice>,
    optionFinderQuery: OptionFinderQuery,
    groupRecordsBy: 'day' | 'week' = 'day'
  ): Observable<GapsMap> {
    const includedTypes = difference(getEnumValues(EventType), [
      EventType.GapCandidate,
      EventType.PreBlock,
    ]);

    return of(optionFinderQuery).pipe(
      practiceStaffAvailableTimes(
        practice,
        brand,
        undefined,
        false,
        undefined,
        includedTypes
      ),
      map((results) =>
        groupBy(
          results,
          (result) =>
            `${result.staffer.name}-${result.from
              .clone()
              .startOf(groupRecordsBy)
              .format(ISO_DATE_FORMAT)}`
        )
      )
    );
  }

  private _getData(
    rosteredOn: RosteredOnMap,
    gaps: GapsMap,
    skipPractitioners: DocumentReference<IStaffer>[]
  ): ITimeUtilisationForDay[] {
    const results = uniq([
      ...Object.keys(rosteredOn),
      ...Object.keys(gaps),
    ]).reduce(
      (combined, key) => ({
        ...combined,
        [key]: {
          rosteredOn: [],
          gaps: [],
        },
      }),
      {} as Record<
        string,
        {
          rosteredOn: IEventTimePeriod[];
          gaps: IEventTimePeriod[];
        }
      >
    );

    Object.entries(rosteredOn).map(([key, value]) => {
      results[key].rosteredOn.push(...value);
    });

    Object.entries(gaps).map(([key, value]) => {
      results[key].gaps.push(...value);
    });

    const transformed = Object.values(results).map((row) =>
      this._toTimeUtilisationForDay(row.rosteredOn, row.gaps, skipPractitioners)
    );
    return compact(transformed);
  }

  private _toTimeUtilisationForDay(
    rosteredOn: IEventTimePeriod[],
    gaps: IEventTimePeriod[],
    skipPractitioners: DocumentReference<IStaffer>[]
  ): ITimeUtilisationForDay | undefined {
    const data = first([...rosteredOn, ...gaps]);
    if (!data) {
      // eslint-disable-next-line no-console
      console.error('No data found in row');
      return;
    }

    const rosteredOnHours = rosteredOn.reduce(
      (acc, period) => acc + getTimePeriodDuration(period, 'hours', true),
      0
    );

    const gapHours = gaps.reduce(
      (acc, period) => acc + getTimePeriodDuration(period, 'hours', true),
      0
    );

    const utilisation = clamp(1 - gapHours / rosteredOnHours, 0, 1);

    if (rosteredOnHours < 0 || utilisation < 0) {
      return;
    }

    if (this._skipPractitioner(data, skipPractitioners)) {
      return;
    }

    return {
      date: data.from.format(DAY_MONTH_YEAR_FORMAT),
      practitioner: data.staffer.name,
      practice: data.practice.name,
      rosteredOnHours,
      gapHours,
    };
  }

  private _skipPractitioner(
    data: IEventTimePeriod,
    skipPractitioners: DocumentReference<IStaffer>[]
  ): boolean {
    return skipPractitioners
      .map((staffer) => staffer.path)
      .includes(data.staffer.ref.path);
  }

  private _getRecords(items: ITimeUtilisationForDay[]): IRecord[] {
    return items.map((data) => {
      const utilisation = this._calculateUtilisation(
        data.rosteredOnHours,
        data.gapHours
      );
      return {
        date: data.date,
        practice: data.practice,
        practitioner: data.practitioner,
        rosteredTime: this._formatNumber(data.rosteredOnHours),
        gapTime: this._formatNumber(data.gapHours),
        utilisationPct: this._formatNumber(utilisation),
      };
    });
  }

  private _getSummaries(records: ITimeUtilisationForDay[]): ISummary[] {
    const grouped = groupBy(
      records,
      (record) => `${record.practitioner}-${record.practice}`
    );
    const summaries = toPairs(grouped).map(
      ([_key, values]): ISummary | undefined => {
        const initial = first(values);
        if (!initial) {
          return;
        }
        const rosteredOnHours = sum(
          values.map((record) => record.rosteredOnHours)
        );
        const gapHours = sum(values.map((record) => record.gapHours));
        const utilisation = this._calculateUtilisation(
          rosteredOnHours,
          gapHours
        );
        return {
          practitioner: initial.practitioner,
          practice: initial.practice,
          rosteredTime: this._formatNumber(rosteredOnHours),
          gapTime: this._formatNumber(gapHours),
          utilisationPct: this._formatNumber(utilisation),
        };
      }
    );

    return compact(summaries);
  }

  private _calculateUtilisation(
    rosteredOnHours: number,
    gapHours: number
  ): number {
    return clamp(1 - gapHours / rosteredOnHours, 0, 1);
  }

  private _formatNumber(value: number): number {
    return parseFloat(value.toFixed(2));
  }

  private _getDataTables(
    records: IRecord[],
    summaries: ISummary[]
  ): (IDataTable<IRecord> | IDataTable<ISummary>)[] {
    return [
      {
        name: 'Practitioner Gaps',
        data: records,
        columns: [
          { key: 'date', header: 'Date' },
          { key: 'practitioner', header: 'Practitioner' },
          { key: 'practice', header: 'Practice' },
          { key: 'rosteredTime', header: 'Rostered Time' },
          { key: 'gapTime', header: 'Gap Time' },
          { key: 'utilisationPct', header: 'Utilisation (%)' },
        ],
      },
      {
        name: 'Time Utilisation Summary',
        data: summaries,
        columns: [
          { key: 'practitioner', header: 'Practitioner' },
          { key: 'practice', header: 'Practice' },
          { key: 'rosteredTime', header: 'Rostered Time' },
          { key: 'gapTime', header: 'Gap Time' },
          { key: 'utilisationPct', header: 'Utilisation (%)' },
        ],
      },
    ];
  }
}
