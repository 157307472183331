import {
  type CdkDrag,
  type CdkDragDrop,
  type CdkDropList,
} from '@angular/cdk/drag-drop';
import {
  type ITreatmentPlan,
  TreatmentStepStatus,
} from '@principle-theorem/principle-core/interfaces';
import { isSameRef, type WithRef } from '@principle-theorem/shared';
import { slice } from 'lodash';
import { type ITreatmentPlanStepPair } from './components/treatment-plans-consolidated/treatment-plans-consolidated.store';
import { DragDropGroup, type IDragDropNode } from './drag-drop-group';

export type IConsolidatedPlanDragDropNode = IDragDropNode<
  WithRef<ITreatmentPlan>,
  ITreatmentPlanStepPair
>;

export class ConsolidatedPlanDragDropGroup extends DragDropGroup<
  WithRef<ITreatmentPlan>,
  ITreatmentPlanStepPair
> {
  private _immovables: TreatmentStepStatus[] = [
    TreatmentStepStatus.Complete,
    TreatmentStepStatus.Current,
  ];

  override drop(event: CdkDragDrop<IConsolidatedPlanDragDropNode>): void {
    if (
      this._targetIsImmovable(event) ||
      this._targetLocationIsBeforeImmovable(event)
    ) {
      return;
    }
    super.drop(event);
  }

  enterPredicate(
    dragStepPair: CdkDrag<ITreatmentPlanStepPair>,
    dropNodeList: CdkDropList<IConsolidatedPlanDragDropNode>
  ): boolean {
    if (isSameRef(dropNodeList.data.parent, dragStepPair.data.treatmentPlan)) {
      return true;
    }
    return false;
  }

  private _targetLocationIsBeforeImmovable(
    event: CdkDragDrop<IConsolidatedPlanDragDropNode>
  ): boolean {
    return slice(event.container.data.items, event.currentIndex).some(
      (followingStep) =>
        this._immovables.includes(followingStep.treatmentStep.status)
    );
  }

  private _targetIsImmovable(
    event: CdkDragDrop<IConsolidatedPlanDragDropNode>
  ): boolean {
    const targetItem = event.item.data as ITreatmentPlanStepPair;
    return this._immovables.includes(targetItem.treatmentStep.status);
  }
}
