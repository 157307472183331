<mat-toolbar color="accent">
  {{ data.cycleType ? 'Edit' : 'Create' }} Sterilisation Cycle Type
</mat-toolbar>

<mat-dialog-content>
  <form [formGroup]="form" class="flex flex-col gap-4">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Description</mat-label>
      <input matInput formControlName="description" />
      <mat-hint>Include a brief description of the cycle type</mat-hint>
    </mat-form-field>

    <mat-checkbox formControlName="isTestType">Test Cycle</mat-checkbox>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!form.valid"
      (click)="save()"
    >
      {{ data.cycleType ? 'Update' : 'Create' }}
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
