import { AppointmentManager } from '@principle-theorem/ng-appointment/store';
import { AutomationsFacade } from '@principle-theorem/ng-automations';
import { TreatmentStepsFacade } from '@principle-theorem/ng-clinical-charting/store';
import {
  Appointment,
  ChartedItem,
  ResolveError,
} from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IChartedTreatment,
  type IStaffer,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export class AppointmentCompleter {
  constructor(
    private _appointment$: Observable<WithRef<IAppointment>>,
    private _staffer$: Observable<WithRef<IStaffer>>,
    private _treatmentStepsStore: TreatmentStepsFacade,
    private _automationsFacade: AutomationsFacade
  ) {}

  async completeInParallel(
    treatmentStep$: Observable<WithRef<ITreatmentStep>>
  ): Promise<void> {
    try {
      await Promise.all([
        this._resolveTreatments(treatmentStep$),
        this._saveAutomationsAndReset(treatmentStep$),
        this._completeWithConfig(),
      ]);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  private async _completeWithConfig(): Promise<void> {
    const staffer = await snapshot(this._staffer$);
    const appointment = await snapshot(this._appointment$);
    await AppointmentManager.markCheckingOut(staffer, appointment);
  }

  private async _resolveTreatments(
    treatmentStep$: Observable<WithRef<ITreatmentStep>>
  ): Promise<void> {
    const appointment = await snapshot(this._appointment$);
    const treatmentStep = await snapshot(treatmentStep$);
    if (!treatmentStep) {
      throw new ResolveError('treatmentStep', appointment.ref.path);
    }

    const practitioner = await snapshot(this._getPractitioner$());

    this._treatmentStepsStore.updateTreatmentStep(treatmentStep.ref.id, {
      treatments: this._resolveTreatmentsOnChart(
        treatmentStep.treatments,
        practitioner
      ),
    });
  }

  private async _saveAutomationsAndReset(
    treatmentStep$: Observable<WithRef<ITreatmentStep>>
  ): Promise<void> {
    const step = await snapshot(treatmentStep$);
    this._automationsFacade.saveAutomations(step);
    this._automationsFacade.resetState();
  }

  private _resolveTreatmentsOnChart(
    treatments: IChartedTreatment[],
    resolvedBy: WithRef<IStaffer>
  ): IChartedTreatment[] {
    return treatments.map((treatment: IChartedTreatment) =>
      ChartedItem.resolve(treatment, resolvedBy)
    );
  }

  private _getPractitioner$(): Observable<WithRef<IStaffer>> {
    return this._appointment$.pipe(
      switchMap((appointment) => Appointment.practitioner(appointment))
    );
  }
}
