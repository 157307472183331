import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  type OnDestroy,
} from '@angular/core';
import {
  CalendarEventsFacade,
  CalendarFacade,
} from '@principle-theorem/ng-calendar/store';
import { EventableTimelineStore } from '@principle-theorem/ng-eventable';
import {
  CurrentPracticeScope,
  GlobalStoreService,
  OrganisationService,
  StafferSettingsStoreService,
} from '@principle-theorem/ng-principle-shared';
import {
  DynamicSidebarService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  EventableQueries,
  Practice,
  ScheduleSummary,
} from '@principle-theorem/principle-core';
import {
  EventType,
  IPractice,
  IScheduleSummaryEventable,
  TimelineMode,
  isAppointmentEventType,
  isCalendarEventType,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isChanged$,
  isRefChanged$,
  isSameRange,
  isSameRef,
  shareReplayCold,
  snapshot,
  type DocumentReference,
  type ITimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, differenceWith, startCase, uniqWith } from 'lodash';
import { BehaviorSubject, Subject, combineLatest, type Observable } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { CalendarEventEditSidebarComponent } from '../../components/calendar-event-edit-sidebar/calendar-event-edit-sidebar.component';
import { CalendarEventSidebarStoreService } from '../../components/calendar-event-edit-sidebar/calendar-event-sidebar-store.service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AppointmentViewSidebarComponent } from 'libs/ng-appointment/src/lib/components/appointment-view-sidebar/appointment-view-sidebar.component';

interface ITimelineModeSelection {
  mode: TimelineMode;
  tooltip: string;
}

@Component({
  selector: 'pr-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EventableTimelineStore],
})
export class TimelineComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  staff$: Observable<WithRef<IStaffer>[]>;
  practice$: Observable<WithRef<IPractice>>;
  rosteredStaff$: Observable<WithRef<IStaffer>[]>;
  selectedStaff$: Observable<WithRef<IStaffer>[]>;
  dateRange$: Observable<ITimePeriod>;
  openingHours$: Observable<ITimePeriod[]>;
  timelineModes: ITimelineModeSelection[] = [
    {
      mode: TimelineMode.Create,
      tooltip: 'For creating new appointments',
    },
    {
      mode: TimelineMode.Default,
      tooltip: 'For all general edit, move and resizing',
    },
  ];
  mode$ = new BehaviorSubject<TimelineMode>(TimelineMode.Default);
  trackByMode = TrackByFunctions.field<ITimelineModeSelection>('mode');
  isCreateMode$: Observable<boolean>;
  createModeType = EventType;
  createModes = [
    EventType.Appointment,
    EventType.PreBlock,
    EventType.Meeting,
    EventType.Misc,
    EventType.Break,
    EventType.Leave,
    EventType.RosteredOn,
  ];
  trackByCreateMode = TrackByFunctions.variable<EventType>();
  createType$ = new BehaviorSubject<EventType>(EventType.Appointment);
  createButtonLabel$: Observable<string>;

  constructor(
    private _practiceScope: CurrentPracticeScope,
    private _calendarFacade: CalendarFacade,
    private _calendarEventSidebarStore: CalendarEventSidebarStoreService,
    private _eventableTimelineStore: EventableTimelineStore,
    private _sidebar: DynamicSidebarService,
    private _organisation: OrganisationService,
    public timelineStore: StafferSettingsStoreService,
    public calendarEventsFacade: CalendarEventsFacade,
    private _globalStore: GlobalStoreService
  ) {
    this.practice$ = this._practiceScope.doc$.pipe(filterUndefined());
    this.dateRange$ = this._calendarFacade.range$;

    this.isCreateMode$ = this.mode$.pipe(
      map((mode) => mode === TimelineMode.Create)
    );

    this.createButtonLabel$ = this.createType$.pipe(
      map((createType) => `Create ${startCase(createType)}`)
    );

    this.openingHours$ = combineLatest([this.practice$, this.dateRange$]).pipe(
      map(([practice, range]) => Practice.openingHours(practice, range)),
      isChanged$((rangeA, rangeB) => isSameRange(rangeA, rangeB))
    );

    const staffOrder$ = this.practice$.pipe(
      switchMap((practice) =>
        this.timelineStore.getOrderedStaffByPractice$(practice.ref)
      )
    );

    const filteredStaff$ = this.practice$.pipe(
      switchMap((practice) =>
        this.timelineStore.getStaffByPractice$(practice.ref)
      )
    );

    const practitioners$ = this._organisation.practicePractitioners$.pipe(
      isChanged$(isSameRef)
    );

    this.staff$ = combineLatest([practitioners$, staffOrder$]).pipe(
      map(([staff, staffOrder]) => this._sortStaff(staffOrder, staff))
    );

    this.selectedStaff$ = combineLatest([
      practitioners$,
      filteredStaff$,
      staffOrder$,
    ]).pipe(
      map(([practitioners, filteredStaff, staffOrder]) => {
        if (!filteredStaff.length) {
          return this._sortStaff(staffOrder, practitioners);
        }

        const filteredPractitioners = practitioners.filter((practitioner) =>
          filteredStaff.some((filteredStaffer) =>
            isSameRef(filteredStaffer, practitioner)
          )
        );

        return this._sortStaff(staffOrder, filteredPractitioners);
      }),
      shareReplayCold()
    );

    const events$ = combineLatest([
      this.dateRange$.pipe(distinctUntilChanged(isSameRange)),
      this.practice$.pipe(isRefChanged$()),
      practitioners$.pipe(isChanged$(isSameRef)),
      this._globalStore.rosterSchedules$,
      this._calendarFacade.unit$,
      this._calendarFacade.view$,
    ]).pipe(
      switchMap(
        ([dateRange, practice, practitioners, allStaffSchedules, unit, view]) =>
          EventableQueries.getScheduleSummaryEventablesWithFallback$(
            dateRange,
            practice,
            practitioners,
            allStaffSchedules,
            [EventType.GapCandidate, EventType.Gap, EventType.RosteredOn],
            unit,
            view
          )
      ),
      shareReplayCold()
    );

    combineLatest([
      events$,
      this.dateRange$.pipe(distinctUntilChanged(isSameRange)),
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([events, dateRange]) =>
        this._eventableTimelineStore.loadEvents({ events, dateRange })
      );

    this._sidebar.close$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this.cancelCreate());
  }

  @HostListener('document:keydown.escape')
  cancelCreate(): void {
    this.mode$.next(TimelineMode.Default);
  }

  ngOnDestroy(): void {
    this._sidebar.close();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async selectEvent(event: IScheduleSummaryEventable): Promise<void> {
    if (isAppointmentEventType(event.event.type) && event.ref) {
      return this._sidebar.open(AppointmentViewSidebarComponent, {
        data: {
          appointment: event,
          saveFn: async () => Promise.resolve(),
        },
      });
    }
    if (isCalendarEventType(event.event.type)) {
      const practice = await snapshot(this.practice$);
      const calendarEvent = await ScheduleSummary.getCalendarEvent(
        event,
        practice
      );
      this._calendarEventSidebarStore.setState({ calendarEvent });
      return this._sidebar.open(CalendarEventEditSidebarComponent, {
        data: {
          saveFn: async () => Promise.resolve(),
        },
      });
    }
    // eslint-disable-next-line no-console
    console.error('Event not supported', event);
  }

  toggleMode(): void {
    const mode = this.mode$.value;

    if (mode !== TimelineMode.Default) {
      this.cancelCreate();
      return;
    }

    this.mode$.next(TimelineMode.Create);
  }

  setCreateMode(mode: EventType): void {
    this.mode$.next(TimelineMode.Create);
    this.createType$.next(mode);
  }

  isSelectedCreateType$(mode: EventType): Observable<boolean> {
    return this.createType$.pipe(map((createType) => createType === mode));
  }

  toggleRosteredOffStaff(showRosteredOffStaff: boolean): void {
    this.timelineStore.updateStafferSettings({
      timeline: { showRosteredOffStaff },
    });
  }

  async selectStaff(staff: WithRef<IStaffer>[]): Promise<void> {
    const practice = await snapshot(this.practice$);
    const filteredStaffByPractice = [
      {
        practice: practice.ref,
        staff: staff.map((staffer) => staffer.ref),
      },
    ];
    this.timelineStore.updateStafferSettings({
      timeline: {
        filteredStaffByPractice,
      },
    });
  }

  async updateStaffOrder(staff: WithRef<IStaffer>[]): Promise<void> {
    const practice = await snapshot(this.practice$);
    const orderedStaffByPractice = [
      {
        practice: practice.ref,
        staff: uniqWith(
          staff.map((staffer) => staffer.ref),
          isSameRef
        ),
      },
    ];
    this.timelineStore.updateStafferSettings({
      timeline: {
        orderedStaffByPractice,
      },
    });
  }

  private _sortStaff(
    staffOrder: DocumentReference<IStaffer>[],
    filteredPractitioners: WithRef<IStaffer>[]
  ): WithRef<IStaffer>[] {
    const sortedStaff = compact(
      staffOrder.map((stafferRef) =>
        filteredPractitioners.find((staffer) => isSameRef(staffer, stafferRef))
      )
    );

    const unsortedStaff = differenceWith(
      filteredPractitioners,
      sortedStaff,
      isSameRef
    );

    return [...sortedStaff, ...unsortedStaff];
  }
}
