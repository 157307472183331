import { MatDialogConfig } from '@angular/material/dialog';

export enum DialogLayoutType {
  Material = 'material',
  Principle = 'principle',
}

const PRINCIPLE_DEFAULTS: Partial<MatDialogConfig> = {
  panelClass: 'toolbar-dialog',
  autoFocus: false,
  maxHeight: '80vh',
  enterAnimationDuration: 0,
  exitAnimationDuration: 0,
};

const MATERIAL_DEFAULTS: Partial<MatDialogConfig> = {
  autoFocus: false,
  enterAnimationDuration: 0,
  exitAnimationDuration: 0,
};

export class DialogPresets {
  static fullscreen<T>(
    override: Partial<MatDialogConfig<T>> = {},
    layoutType: DialogLayoutType = DialogLayoutType.Material
  ): MatDialogConfig<T> {
    return {
      ...getPresets(layoutType),
      height: '100%',
      width: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      minWidth: '100vw',
      minHeight: '100vh',
      ...override,
    };
  }
  static almostFullscreen<T>(
    override: Partial<MatDialogConfig<T>> = {},
    layoutType: DialogLayoutType = DialogLayoutType.Material
  ): MatDialogConfig<T> {
    return {
      ...getPresets(layoutType),
      maxHeight: '90vh',
      minHeight: '90vh',
      maxWidth: '95vw',
      minWidth: '95vw',
      ...override,
    };
  }

  static extraLarge<T>(
    override: Partial<MatDialogConfig<T>> = {},
    layoutType: DialogLayoutType = DialogLayoutType.Material
  ): MatDialogConfig<T> {
    return {
      ...getPresets(layoutType),
      width: '1080px',
      ...override,
    };
  }

  static large<T>(
    override: Partial<MatDialogConfig<T>> = {},
    layoutType: DialogLayoutType = DialogLayoutType.Material
  ): MatDialogConfig<T> {
    return {
      ...getPresets(layoutType),
      width: '800px',
      ...override,
    };
  }

  static medium<T>(
    override: Partial<MatDialogConfig<T>> = {},
    layoutType: DialogLayoutType = DialogLayoutType.Material
  ): MatDialogConfig<T> {
    return {
      ...getPresets(layoutType),
      width: '600px',
      ...override,
    };
  }

  static small<T>(
    override: Partial<MatDialogConfig<T>> = {},
    layoutType: DialogLayoutType = DialogLayoutType.Material
  ): MatDialogConfig<T> {
    return {
      ...getPresets(layoutType),
      autoFocus: true,
      width: '400px',
      ...override,
    };
  }

  static flex<T>(
    override: Partial<MatDialogConfig<T>> = {},
    layoutType: DialogLayoutType = DialogLayoutType.Material
  ): MatDialogConfig<T> {
    return { ...getPresets(layoutType), ...override };
  }
}

function getPresets(layoutType: DialogLayoutType): Partial<MatDialogConfig> {
  return layoutType === DialogLayoutType.Principle
    ? PRINCIPLE_DEFAULTS
    : MATERIAL_DEFAULTS;
}
