import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IIntegration } from '@principle-theorem/integrations';
import { type CollectionReference } from '@principle-theorem/shared';
import {
  type IStripeIntegrationData,
  StripeIntegrationStorage,
} from '@principle-theorem/stripe-integration';
import { type Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { StripeFunctions } from '../stripe-functions.service';

@Component({
  selector: 'pt-manage-stripe-integration',
  templateUrl: './manage-stripe-integration.component.html',
  styleUrls: ['./manage-stripe-integration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageStripeIntegrationComponent {
  private _integrationsCol$: ReplaySubject<
    CollectionReference<IIntegration<IStripeIntegrationData>>
  > = new ReplaySubject(1);
  private _stripeStorage: StripeIntegrationStorage =
    new StripeIntegrationStorage();
  hasStripeIntegration$: Observable<boolean>;
  loading = false;

  @Input() orgUid: string;

  @Input()
  set integrationsCol(
    integrationsCol: CollectionReference<IIntegration<IStripeIntegrationData>>
  ) {
    if (integrationsCol) {
      this._integrationsCol$.next(integrationsCol);
    }
  }

  constructor(private _stripeFunctions: StripeFunctions) {
    this.hasStripeIntegration$ = this._integrationsCol$.pipe(
      switchMap((collection) => this._stripeStorage.has$(collection))
    );
  }

  async openStripeConnect(): Promise<void> {
    this.loading = true;
    const stripeConnectUrl = await this._stripeFunctions.connectRedirect(
      this.orgUid
    );
    window.open(stripeConnectUrl, '_blank');
    this.loading = false;
  }
}
