import { ChartType } from '@principle-theorem/reporting';
import { BaseChart } from './base-chart';
import { type IChartData, ChartOptions } from './chart-builder';

export class Histogram extends BaseChart {
  data: IChartData = {
    type: ChartType.Histogram,
    options: ChartOptions.init({
      legend: { position: 'none' },
    }),
  };
}

export const HISTOGRAM_OPTIONS = ChartOptions.init({
  backgroundColor: 'none',
  chartArea: {
    top: 10,
    right: 10,
    bottom: 30,
    left: 30,
  },
});
