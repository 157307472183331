import { NgModule } from '@angular/core';
import { StartAppointmentActionService } from './actions/start-appointment-action.service';
import { CompleteAppointmentActionService } from './actions/complete-appointment-action.service';
import { CheckoutAppointmentActionService } from './actions/checkout-appointment-action.service';
import { CheckinAppointmentActionService } from './actions/checkin-appointment-action.service';
import { AppointmentCardActionsComponent } from './appointment-card-actions.component';
import { ConfirmAppointmentActionService } from './actions/confirm-appointment-action.service';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { AppointmentCardActionComponent } from './appointment-card-action/appointment-card-action.component';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, NgPrincipleSharedModule, NgSharedModule],
  declarations: [
    AppointmentCardActionsComponent,
    AppointmentCardActionComponent,
  ],
  providers: [
    StartAppointmentActionService,
    CheckoutAppointmentActionService,
    CompleteAppointmentActionService,
    CheckinAppointmentActionService,
    ConfirmAppointmentActionService,
  ],
  exports: [AppointmentCardActionsComponent],
})
export class AppointmentCardActionsModule {}
