import { Action, combineReducers } from '@ngrx/store';
import {
  type ITreatmentPlansState,
  treatmentPlansReducer,
} from './treatment-plans.reducer';
import {
  type ITreatmentStepsState,
  treatmentStepsReducer,
} from './treatment-steps.reducer';

export interface IChartTreatmentPlanningModuleState {
  treatmentPlans: ITreatmentPlansState;
  treatmentSteps: ITreatmentStepsState;
}

export function chartTreatmentPlanningReducer(
  state: IChartTreatmentPlanningModuleState | undefined,
  action: Action
): IChartTreatmentPlanningModuleState {
  return combineReducers({
    treatmentPlans: treatmentPlansReducer,
    treatmentSteps: treatmentStepsReducer,
  })(state, action);
}
