import { Pipe, type PipeTransform } from '@angular/core';
import { toMoment } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';

@Pipe({ name: 'toNow' })
export class ToNowPipe implements PipeTransform {
  transform(value: Timestamp | Moment, withoutPrefix?: boolean): string {
    return toMoment(value).toNow(withoutPrefix);
  }
}
