import { IPracticeMigrationMetadata } from './practice-migration';

export interface IMigrationWorkflowMetadata {
  label: string;
  description: string;
  path: string[];
}

export interface IMigrationWorkflow {
  compatibleMigrations: IPracticeMigrationMetadata[];
  metadata: IMigrationWorkflowMetadata;
}
