import { toMentionContent, toTextContent } from '@principle-theorem/editor';
import {
  Interaction,
  LabJob,
  LabJobUpdater,
  stafferToNamedDoc,
  toMention,
} from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IAppointmentDocument,
  type IInteractionV2,
  type ILabJob,
  InteractionType,
  type IPractice,
  type IStaffer,
  type LabJobStatus,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  getDoc$,
  patchDoc,
  snapshot,
  toMoment,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { type ILabJobFormData } from './components/lab-job-form/lab-job.formgroup';

export class LabJobManager {
  constructor(
    private _practice: WithRef<IPractice>,
    private _staffer: WithRef<IStaffer>
  ) {}

  async add(data: ILabJobFormData): Promise<WithRef<ILabJob>> {
    const labJob: ILabJob = this._convertFormDataToLabJob(data);
    labJob.interactions.push(this._getCreateInteraction());
    labJob.title = await LabJob.generateTitle(labJob);
    labJob.appointment = this._toAppointmentDocument(data.appointment);

    const labJobRef = await addDoc(LabJob.col(this._practice), labJob);
    const labJobObj: WithRef<ILabJob> = await snapshot(
      getDoc$(LabJob.col(this._practice), labJobRef.id)
    );
    return labJobObj;
  }

  async updateStatus(
    labJob: WithRef<ILabJob>,
    status: LabJobStatus
  ): Promise<void> {
    const updater: LabJobUpdater = new LabJobUpdater(labJob);
    updater.updateStatus(status);

    if (updater.hasUpdates()) {
      await updater.save(this._staffer);
    }
  }

  async update(labJob: WithRef<ILabJob>, data: ILabJobFormData): Promise<void> {
    const updater: LabJobUpdater = new LabJobUpdater(labJob);
    updater
      .updateDueDate(data.dueDate ? toMoment(data.dueDate) : undefined)
      .updateCost(data.cost)
      .updateLab(data.lab)
      .updateType(data.type)
      .updateAppointment(this._toAppointmentDocument(data.appointment));

    if (updater.hasUpdates()) {
      await updater.save(this._staffer);
    }

    const title = await LabJob.generateTitle(labJob);
    await patchDoc(labJob.ref, { title });
  }

  private _convertFormDataToLabJob(data: ILabJobFormData): ILabJob {
    const labJob: ILabJob = LabJob.init({
      lab: data.lab,
      patient: data.patient,
      dueDate: data.dueDate ? toTimestamp(data.dueDate) : undefined,
      type: data.type,
      cost: data.cost,
      description: data.description,
      status: data.status,
      appointment: this._toAppointmentDocument(data.appointment),
    });
    if (data.dueDate) {
      labJob.dueDate = toTimestamp(data.dueDate);
    }
    return labJob;
  }

  private _getCreateInteraction(): IInteractionV2 {
    const owner = stafferToNamedDoc(this._staffer);
    return Interaction.init({
      type: InteractionType.LabJob,
      title: [
        toMentionContent(toMention(owner, MentionResourceType.Staffer)),
        toTextContent(` created lab job`),
      ],
      owner,
    });
  }

  private _toAppointmentDocument(
    appointment: WithRef<IAppointment> | undefined
  ): IAppointmentDocument | undefined {
    if (!appointment) {
      return;
    }
    return {
      treatmentPlanName: appointment.treatmentPlan.name,
      ref: appointment.ref,
    };
  }
}
