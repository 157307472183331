<ng-template #loading>
  <mat-progress-bar mode="indeterminate" />
</ng-template>

<div class="flex grow flex-col lg:flex-row">
  <div
    class="flex min-w-0 flex-col border-solid border-slate-200 lg:basis-[40%] lg:border-r"
  >
    <div
      class="flex flex-col"
      *ngIf="schedulingConflicts$ | ngrxPush as schedulingConflicts"
    >
      <ng-container *ngIf="schedulingConflicts.length">
        <pt-alert
          *ngFor="let conflict of schedulingConflicts; trackBy: trackByConflict"
          [alert]="conflict"
        />
      </ng-container>
    </div>

    <div class="flex grow flex-col gap-8 p-4">
      <ng-container *ngIf="warnings$ | ngrxPush as warnings">
        <div *ngIf="warnings.length" class="flex flex-col gap-2">
          <div *ngFor="let warning of warnings; trackBy: trackByWarning">
            <div class="text-base font-bold">{{ warning.title }}</div>
            <div class="warning">{{ warning.message }}</div>
          </div>
        </div>
      </ng-container>

      <pr-appointment-history-card-treatments
        [appointment]="appointment$ | ngrxPush"
      />

      <pr-appointment-history-card-automations
        [appointment]="appointment$ | ngrxPush"
      />

      <ng-container *ngIf="checklistItems$ | ngrxPush as checklistItems">
        <pr-appointment-history-card-checklist
          *ngIf="checklistItems.length"
          [appointment]="appointment$ | ngrxPush"
        />
      </ng-container>

      <ng-container *ngIf="labJobs$ | ngrxPush as labJobs">
        <pr-appointment-lab-jobs
          *ngIf="labJobs.length"
          viewType="card"
          [labJobs]="labJobs"
        />
      </ng-container>
    </div>
  </div>
  <div class="flex flex-col gap-8 border-slate-200 p-4 lg:basis-[30%]">
    <pr-appointment-history-card-invoice
      [appointment]="appointment$ | ngrxPush"
    />
    <pr-appointment-all-notes
      [appointment]="appointment$ | ngrxPush"
      [patient]="patient$ | ngrxPush"
    />
    <pr-appointment-tags
      viewType="card"
      [appointment]="appointment$ | ngrxPush"
    />
  </div>

  <div
    class="order-first flex grow flex-col border-b border-solid border-slate-200 lg:order-last lg:w-96 lg:basis-[30%] lg:border-l lg:border-t-0"
  >
    <pr-patient-profile-summary
      *ngIf="patient$ | async as patient"
      [patient]="patient"
      [actions]="patientActions"
      [expandable]="false"
      class="block p-4"
    >
      <div>
        <pr-patient-last-visit [patient]="patient" />
      </div>
    </pr-patient-profile-summary>

    <mat-divider />

    <pr-patient-medical-alerts
      [patient]="patient$ | async"
      [expandable]="false"
      class="block p-4"
    />

    <mat-divider />

    <pr-event-details [event]="event$ | ngrxPush" />
  </div>
</div>

<pr-appointment-card-actions
  class="block border-t border-solid border-slate-200 p-4"
  [actions]="actions"
  [appointment]="appointment$ | ngrxPush"
  [patient]="patient$ | ngrxPush"
  (updateEventable)="updateEventable.emit($event)"
/>
