@if (typed.section$ | async; as section) {
  @if (section.options.title) {
    <div
      class="flex grow flex-col gap-2 border-2 border-solid border-transparent p-2"
    >
      <h3 class="!m-0 pt-8 !font-bold">{{ section.options.title }}</h3>
    </div>
  }
}

@if (typed.content$ | async; as content) {
  <pr-content-view class="flex grow" [content]="content.options.content" />
}

@if (typed.text$ | async; as text) {
  <mat-form-field appearance="outline" class="flex grow">
    <mat-label class="cursor-pointer select-none">
      {{ text.options.label }}
    </mat-label>
    <input
      matInput
      class="!pointer-events-none"
      [disabled]="true"
      [required]="text.options.required"
    />
  </mat-form-field>
}

@if (typed.textarea$ | async; as textarea) {
  <mat-form-field appearance="outline" class="flex grow">
    <mat-label class="cursor-pointer select-none">
      {{ textarea.options.label }}
    </mat-label>
    <textarea
      matInput
      class="!pointer-events-none !resize-none"
      [disabled]="true"
      [required]="textarea.options.required"
    ></textarea>
  </mat-form-field>
}

@if (typed.number$ | async; as number) {
  <mat-form-field appearance="outline" class="flex grow">
    <mat-label class="cursor-pointer select-none">
      {{ number.options.label }}
    </mat-label>
    <input
      matInput
      class="!pointer-events-none"
      type="number"
      [disabled]="true"
      [required]="number.options.required"
    />
  </mat-form-field>
}

@if (typed.checkbox$ | async; as checkbox) {
  <mat-checkbox
    class="flex grow"
    [disabled]="true"
    [required]="checkbox.options.required"
  >
    {{ checkbox.options.label }}{{ checkbox.options.required ? '*' : '' }}
  </mat-checkbox>
}

@if (typed.date$ | async; as date) {
  <mat-form-field appearance="outline" class="flex grow">
    <mat-label class="cursor-pointer select-none">
      {{ date.options.label }}
    </mat-label>
    <input
      matInput
      class="!pointer-events-none"
      [disabled]="true"
      [required]="date.options.required"
      [matDatepicker]="picker"
    />
    <mat-datepicker-toggle matIconSuffix [for]="picker" />
    <mat-datepicker #picker />
  </mat-form-field>
}

@if (typed.dropdown$ | async; as dropdown) {
  <mat-form-field appearance="outline" class="flex grow">
    <mat-label class="cursor-pointer select-none">
      {{ dropdown.options.label }}
    </mat-label>
    <mat-select
      class="!pointer-events-none"
      [disabled]="true"
      [required]="dropdown.options.required"
    >
      <mat-option>Option 1</mat-option>
    </mat-select>
  </mat-form-field>
}

@if (typed.signature$ | async; as signature) {
  <pt-signature-input
    [label]="signature.options.label"
    [disabled]="true"
    [required]="signature.options.required"
  />
}
