export function cliLog(
  obj: unknown,
  // eslint-disable-next-line no-null/no-null
  depth: number | null | undefined = null
): void {
  // eslint-disable-next-line no-console
  console.dir(obj, {
    showHidden: false,
    depth,
    color: true,
  });
}
