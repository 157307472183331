import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  ChartedServiceExclusiveGroup,
  ChartedServiceSmartGroup,
  ServiceCodeEntry,
} from '@principle-theorem/principle-core';
import {
  IChartedTreatment,
  IPricedServiceCodeEntry,
  ITreatmentCategory,
  ITreatmentConfiguration,
  ServiceCodeGroupType,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  WithRef,
  getDoc,
  shareReplayCold,
} from '@principle-theorem/shared';
import { flatten } from 'lodash';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-appointment-history-card-treatment',
    templateUrl: './appointment-history-card-treatment.component.html',
    styleUrls: ['./appointment-history-card-treatment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentHistoryCardTreatmentComponent {
  trackByServiceCode = TrackByFunctions.uniqueId<IPricedServiceCodeEntry>();
  treatment$ = new ReplaySubject<WithRef<IChartedTreatment>>(1);
  config$: Observable<WithRef<ITreatmentConfiguration>>;
  treatmentCategory$: Observable<
    DocumentReference<ITreatmentCategory> | undefined
  >;
  showBasePrice$: Observable<boolean>;
  serviceCodes$: Observable<IPricedServiceCodeEntry[]>;

  @Input()
  set treatment(treatment: WithRef<IChartedTreatment>) {
    if (treatment) {
      this.treatment$.next(treatment);
    }
  }

  constructor() {
    this.config$ = this.treatment$.pipe(
      switchMap((treatment) => getDoc(treatment.config.ref)),
      shareReplayCold()
    );
    this.treatmentCategory$ = this.config$.pipe(
      map(({ category }) => category)
    );
    this.serviceCodes$ = this.treatment$.pipe(
      map((treatment) => this._getServiceCodes(treatment))
    );
    this.showBasePrice$ = this.treatment$.pipe(
      map((treatment) => treatment.basePrice > 0)
    );
  }

  getCodeName(entry: IPricedServiceCodeEntry): string | undefined {
    return ServiceCodeEntry.getName(entry);
  }

  getCodeName$(entry: IPricedServiceCodeEntry): Observable<string | undefined> {
    return of(ServiceCodeEntry.getName(entry));
  }

  private _getServiceCodes(
    treatment: WithRef<IChartedTreatment>
  ): IPricedServiceCodeEntry[] {
    const smartGroupItems = treatment.serviceCodeSmartGroups.map((group) => {
      const selected = ChartedServiceSmartGroup.getSelected(group);
      return selected ? [selected] : group.serviceCodes;
    });
    const groupItems = treatment.serviceCodeGroups.map((group) => {
      if (group.type !== ServiceCodeGroupType.Exclusive) {
        return [];
      }
      const selected = ChartedServiceExclusiveGroup.getSelected(group);
      return selected ? [selected] : group.serviceCodes;
    });

    return [
      ...flatten(smartGroupItems),
      ...flatten(groupItems),
      ...treatment.serviceCodes,
    ].filter((serviceCode) => serviceCode.quantity > 0);
  }
}
