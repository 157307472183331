import { Component, Input } from '@angular/core';
import { type IChartCard } from '../../../models/report/charts/chart-card';

@Component({
  selector: 'pr-basic-chart',
  templateUrl: './basic-chart.component.html',
  styleUrls: ['./basic-chart.component.sass'],
})
export class BasicChartComponent {
  @Input() chart: IChartCard;
}
