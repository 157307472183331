import { ICustomMappings } from '@principle-theorem/principle-core/interfaces';
import { BaseCustomMappingsHandler } from '../mappings/base-custom-mappings-handler';
import { D4WAppointmentBookToPractitionerMappingHandler } from './destination/mappings/appointment-book-to-practitioner';
import { D4WAppointmentStatusMappingHandler } from './destination/mappings/appointment-statuses';
import { D4WExcludedAppointmentBooksMappingHandler } from './destination/mappings/exclude-appointment-books';
import { D4WFeeScheduleToPatientTagMappingHandler } from './destination/mappings/fee-schedule-to-patient-tag';
import { D4WFeeScheduleMappingHandler } from './destination/mappings/fee-schedules';
import { D4WFileCategoryMappingHandler } from './destination/mappings/file-categories';
import { D4WItemCodeToConditionMappingHandler } from './destination/mappings/item-code-to-condition';
import { D4WItemCodeToNoteMappingHandler } from './destination/mappings/item-code-to-note';
import { D4WItemCodeToTreatmentMappingHandler } from './destination/mappings/item-code-to-treatment';
import { D4WItemCodeMappingHandler } from './destination/mappings/item-codes';
import { D4WPatientStatusMappingHandler } from './destination/mappings/patient-statues';
import { D4WPatientMappingHandler } from './destination/mappings/patients';
import { D4WPracticeMappingHandler } from './destination/mappings/practices';
import { D4WReferralSourceMappingHandler } from './destination/mappings/referral-sources';
import { D4WStafferMappingHandler } from './destination/mappings/staff';
import { D4WStaffToUserMappingHandler } from './destination/mappings/staff-to-user';

export const D4W_MAPPINGS: ICustomMappings = {
  metadata: {
    label: 'D4W Custom Mappings',
    description: '',
  },
};

export class D4WCustomMappingsHandler extends BaseCustomMappingsHandler {
  migrationType = 'D4W';
  migrationVersion = 'v1';
  customMapping = D4W_MAPPINGS;

  mappingHandlers = [
    new D4WPracticeMappingHandler(),
    new D4WStafferMappingHandler(),
    new D4WStaffToUserMappingHandler(),
    new D4WItemCodeMappingHandler(),
    new D4WAppointmentStatusMappingHandler(),
    new D4WExcludedAppointmentBooksMappingHandler(),
    new D4WPatientMappingHandler(),
    new D4WPatientStatusMappingHandler(),
    new D4WAppointmentBookToPractitionerMappingHandler(),
    new D4WFeeScheduleMappingHandler(),
    new D4WFileCategoryMappingHandler(),
    new D4WFeeScheduleToPatientTagMappingHandler(),
    new D4WReferralSourceMappingHandler(),
    new D4WItemCodeToTreatmentMappingHandler(),
    new D4WItemCodeToConditionMappingHandler(),
    new D4WItemCodeToNoteMappingHandler(),
  ];
}
