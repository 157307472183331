import { type IXSLXImport } from '@principle-theorem/shared';
import type { Row } from 'exceljs';
import {
  APPOINTMENT_BOOK_TO_PRACTITIONER_HEADERS,
  type IStafferToPractitionerXSLX,
} from './staffer-to-practitioner-to-xlsx';

export class XSLXToStafferToPractitioner
  implements IXSLXImport<IStafferToPractitionerXSLX>
{
  headers = APPOINTMENT_BOOK_TO_PRACTITIONER_HEADERS;

  translate(row: Row): IStafferToPractitionerXSLX {
    return {
      id: row.getCell('id').value?.toString() ?? '',
      name: row.getCell('name').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IStafferToPractitionerXSLX['mapTo'],
    };
  }
}
