<ng-container [ngSwitch]="type">
  <button
    *ngSwitchCase="'stroked'"
    mat-stroked-button
    [color]="color$ | async"
    class="primary-button"
    [ngClass]="{ 'mat-button-toggle-checked': checked$ | async }"
    (click)="buttonClick.emit()"
  >
    {{ label }}
  </button>
  <button
    *ngSwitchCase="'flat'"
    mat-flat-button
    [color]="color$ | async"
    class="primary-button"
    [ngClass]="{ 'mat-button-toggle-checked': checked$ | async }"
    (click)="buttonClick.emit()"
  >
    {{ label }}
  </button>
  <button
    *ngSwitchCase="'raised'"
    mat-raised-button
    [color]="color$ | async"
    class="primary-button"
    [ngClass]="{ 'mat-button-toggle-checked': checked$ | async }"
    (click)="buttonClick.emit()"
  >
    {{ label }}
  </button>
  <button
    *ngSwitchDefault
    mat-button
    [color]="color$ | async"
    class="primary-button"
    [ngClass]="{ 'mat-button-toggle-checked': checked$ | async }"
    (click)="buttonClick.emit()"
  >
    {{ label }}
  </button>
</ng-container>

<ng-container [ngSwitch]="type">
  <button
    *ngSwitchCase="'stroked'"
    mat-stroked-button
    class="menu-button"
    [color]="color$ | async"
    [matMenuTriggerFor]="menu"
  >
    <mat-icon>expand_more</mat-icon>
  </button>
  <button
    *ngSwitchCase="'flat'"
    mat-flat-button
    class="menu-button with-divider"
    [color]="color$ | async"
    [matMenuTriggerFor]="menu"
  >
    <mat-icon>expand_more</mat-icon>
  </button>
  <button
    *ngSwitchCase="'raised'"
    mat-raised-button
    class="menu-button with-divider"
    [color]="color$ | async"
    [matMenuTriggerFor]="menu"
  >
    <mat-icon>expand_more</mat-icon>
  </button>
  <button
    *ngSwitchDefault
    mat-button
    class="menu-button with-divider"
    [color]="color$ | async"
    [matMenuTriggerFor]="menu"
  >
    <mat-icon>expand_more</mat-icon>
  </button>
</ng-container>

<mat-menu #menu="matMenu" xPosition="before" class="side-button-menu">
  <ng-content></ng-content>
</mat-menu>
