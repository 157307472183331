import {
  TypeGuard,
  type ISoftDelete,
  type ITimestampRange,
  isTimestampRange,
  isDocRef,
} from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { type IBrand } from './brand';
import { type IOrganisation } from './organisation/organisation';
import { type IPractice } from './practice/practice';
import { type IPracticeMigration } from './practice-migrations/practice-migration';
import { ISourceEntity } from './practice-migrations/source';
import { isString } from 'lodash';

export enum ManagementJobCollection {
  ManagementJobLogs = 'managementJobLogs',
}

export enum BigQueryManagementJob {
  BigQuerySchemaSync = 'big-query.schema.sync',
  BigQueryAccountCreditsSync = 'big-query.account-credits.sync',
  BigQueryAppointmentsSync = 'big-query.appointments.sync',
  BigQueryInvoicesSync = 'big-query.invoices.sync',
  BigQueryPatientsSync = 'big-query.patients.sync',
  BigQueryPatientInteractionsSync = 'big-query.patient-interactions.sync',
  BigQueryTransactionsSync = 'big-query.transactions.sync',
  BigQueryTreatmentStepSync = 'big-query.treatment-steps.sync',
  BigQuerySchedulingEventSync = 'big-query.scheduling-events.sync',
  BigQuerySchedulingEventReasonSync = 'big-query.scheduling-event-reasons.sync',
}

export enum PracticeMigrationManagementJobType {
  ProvisionMigrationDatabase = 'practice-migration.provision-migration-database',
  UploadBackupToDatabase = 'practice-migration.upload-backup-to-database',
  SyncExpectedSourceRecordSizes = 'practice-migration.source.sync-expected-record-sizes',
  SyncSourceRecordSizes = 'practice-migration.source.sync-record-sizes',
  SyncSourceMigrationRun = 'practice-migration.source.sync-source-migration',
  DestinationMigrationRun = 'practice-migration.destination.run-migration',
  SyncDatabaseBackup = 'practice-migration.destination.sync-database-backup',
  PostMigrationSeedPatientNumbers = 'practice-migration.post-migration.seed-patient-numbers',
  PostMigrationSeedAutomations = 'practice-migration.post-migration.seed-automations',
  PostMigrationUpsertScheduleSummary = 'practice-migration.post-migration.upsert-schedule-summary',
  PostMigrationSeedTypesensePatients = 'practice-migration.post-migration.seed-typesense-patients',
  PostMigrationSeedAccountAggregates = 'practice-migration.post-migration.seed-account-aggregates',
}

export enum ManagementJobStatus {
  Pending = 'Pending',
  InProgress = 'In Progress',
  Complete = 'Complete',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
}

export interface IBigQueryJob {
  range: ITimestampRange;
  organisationRef: DocumentReference<IOrganisation>;
  brandRef: DocumentReference<IBrand>;
  practiceRef: DocumentReference<IPractice>;
  type: BigQueryManagementJob;
}

export interface IPracticeMigrationTaskQueueData {
  managementJobRef: DocumentReference<IManagementJob>;
}

export interface IPracticeMigrationManagementJob {
  migrationRef: DocumentReference<IPracticeMigration>;
  type: PracticeMigrationManagementJobType;
  cloudTaskId?: string;
  continueToNextJob?: boolean;
}

export interface IPracticeMigrationJobSourceEntityBaseState {
  key: string;
  status: 'pending' | 'inProgress' | 'complete' | 'failed';
  ref: DocumentReference<ISourceEntity>;
}

export type IPostMigrationJob = PracticeMigrationManagementJob & {
  type:
    | PracticeMigrationManagementJobType.PostMigrationSeedPatientNumbers
    | PracticeMigrationManagementJobType.PostMigrationSeedAutomations
    | PracticeMigrationManagementJobType.PostMigrationUpsertScheduleSummary
    | PracticeMigrationManagementJobType.PostMigrationSeedTypesensePatients;
};

export type PracticeMigrationSourceHandlerJob =
  IPracticeMigrationManagementJob & {
    type: PracticeMigrationManagementJobType.SyncSourceMigrationRun;
  };

export type PracticeMigrationDatabaseProvisionJob =
  PracticeMigrationManagementJob & {
    type: PracticeMigrationManagementJobType.ProvisionMigrationDatabase;
    provisionState: 'pending' | 'inProgress' | 'complete' | 'failed';
  };

export type PracticeMigrationUploadBackupToDatabaseJob =
  PracticeMigrationManagementJob & {
    type: PracticeMigrationManagementJobType.ProvisionMigrationDatabase;
  };

export interface ISyncRecordSizeJobSourceEntityState
  extends IPracticeMigrationJobSourceEntityBaseState {
  syncCountAttempts: number;
}

export type PracticeMigrationSyncRecordSizeJob =
  PracticeMigrationManagementJob & {
    type: PracticeMigrationManagementJobType.SyncSourceRecordSizes;
    sourceEntities: ISyncRecordSizeJobSourceEntityState[];
  };

export type PracticeMigrationSyncExpectedRecordSizeJob =
  PracticeMigrationManagementJob & {
    type: PracticeMigrationManagementJobType.SyncExpectedSourceRecordSizes;
    sourceEntities: IPracticeMigrationJobSourceEntityBaseState[];
  };

export interface ISyncSourceRecordsJobSourceEntityState
  extends IPracticeMigrationJobSourceEntityBaseState {
  queryOffset: number;
}

export type PracticeMigrationSyncSourceRecordsJob =
  PracticeMigrationManagementJob & {
    type: PracticeMigrationManagementJobType.SyncSourceMigrationRun;
    sourceEntities: ISyncSourceRecordsJobSourceEntityState[];
  };

export type ManagemetJobType = IBigQueryJob | IPracticeMigrationManagementJob;
export type BaseManagementJob = ISoftDelete & {
  status: ManagementJobStatus;
};

export type IManagementJob = BaseManagementJob &
  (IBigQueryJob | IPracticeMigrationManagementJob);

export type IBigQueryManagementJob = BaseManagementJob & IBigQueryJob;

export type PracticeMigrationManagementJob = BaseManagementJob &
  IPracticeMigrationManagementJob;

export const isBigQueryJob = TypeGuard.interface<IBigQueryJob>({
  range: isTimestampRange,
  organisationRef: isDocRef,
  brandRef: isDocRef,
  practiceRef: isDocRef,
  type: isString,
});
