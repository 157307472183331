import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  BridgeDeviceStatus,
  BRIDGE_DEVICE_STATUS_COLOUR_MAP,
} from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-bridge-device-status',
  templateUrl: './bridge-device-status.component.html',
  styleUrls: ['./bridge-device-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BridgeDeviceStatusComponent {
  @Input() status: BridgeDeviceStatus;
  isDense = false;
  statusColourMap: {
    [key in BridgeDeviceStatus]: string;
  } = BRIDGE_DEVICE_STATUS_COLOUR_MAP;

  @Input()
  set dense(dense: BooleanInput) {
    this.isDense = coerceBooleanProperty(dense);
  }
}
