import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PRACTICE_LOCATION_RESOURCE_TYPE = 'practiceLocations';

export const PRACTICE_LOCATION_SOURCE_ENTITY: ISourceEntity = SourceEntity.init(
  {
    metadata: {
      label: 'Practice Location List',
      description: '',
      idPrefix: PRACTICE_LOCATION_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  }
);

export interface ICorePracticeLocation {
  id: number; //
  name: string; // "Five Dock"
  abn?: string; // 38620204775
  email: string; // hello@dockdental.com.au
  phone1: string; // 0272530333
  phone2?: string; //
  mobile?: string; //
  fax?: string; //
  addressLine1?: string; // "183 First Avenue"
  addressLine2?: string; //
  suburb?: string; // Five Dock
  postcode?: string; // 2046
  state?: string; // NSW
  country?: string; // AU
}

export function isCorePracticePracticeLocation(
  item: unknown
): item is ICorePracticeLocation {
  return TypeGuard.interface<ICorePracticeLocation>({
    id: isNumber,
    name: isString,
    abn: TypeGuard.nilOr(isString),
    email: isString,
    phone1: isString,
    phone2: TypeGuard.nilOr(isString),
    mobile: TypeGuard.nilOr(isString),
    fax: TypeGuard.nilOr(isString),
    addressLine1: TypeGuard.nilOr(isString),
    addressLine2: TypeGuard.nilOr(isString),
    suburb: TypeGuard.nilOr(isString),
    postcode: TypeGuard.nilOr(isString),
    state: TypeGuard.nilOr(isString),
    country: TypeGuard.nilOr(isString),
  })(item);
}

export interface ICorePracticeLocationTranslations {}

export interface ICorePracticeLocationFilters {}

const PRACTICE_LOCATION_SOURCE_QUERY = `
SELECT
  LocationId AS id,
  LocationName AS name,
  convert_to_text(ABN) AS abn,
  Email AS email,
  convert_to_text(Phone1) AS phone_1,
  convert_to_text(Phone2) AS phone_2,
  convert_to_text(Mobile) AS mobile,
  convert_to_text(Fax) AS fax,
  AddressLine1 AS address_line_1,
  AddressLine2 AS address_line_2,
  Suburb AS suburb,
  convert_to_text(Postcode) AS postcode,
  State AS state,
  Country AS country
FROM tblLocation
`;

export class PracticeLocationSourceEntity extends BaseSourceEntity<
  ICorePracticeLocation,
  ICorePracticeLocationTranslations,
  ICorePracticeLocationFilters
> {
  sourceEntity = PRACTICE_LOCATION_SOURCE_ENTITY;
  entityResourceType = PRACTICE_LOCATION_RESOURCE_TYPE;
  sourceQuery = PRACTICE_LOCATION_SOURCE_QUERY;
  verifySourceFn = isCorePracticePracticeLocation;

  translate(
    _data: ICorePracticeLocation,
    _timezone: Timezone
  ): ICorePracticeLocationTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticeLocation): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticeLocation): string {
    return `${data.id} - ${data.name}`;
  }

  getFilterData(
    _data: ICorePracticeLocation,
    _timezone: Timezone
  ): ICorePracticeLocationFilters {
    return {};
  }
}
