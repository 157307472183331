import {
  TaxStrategy,
  getTaxRateByRegion,
  roundTo2Decimals,
} from '@principle-theorem/accounting';
import {
  ADA_CODE_TREATMENT_CONFIG_ID,
  Brand,
  CalculateTreatmentWeight,
  ChartedItemScopeResolver,
  ChartedSurface,
  ChartedTreatment,
  FeeSchedule,
  PricedServiceCodeEntry,
  TreatmentConfiguration,
  TreatmentPlan,
  TreatmentStep,
  determineTaxFromStrategy,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  ChartableSurface,
  FailedDestinationEntityRecord,
  IDestinationEntityJobRunOptions,
  ITranslationMap,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  type IAppointment,
  type IChartedSurface,
  type IChartedTreatment,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IFeeSchedule,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  ISO_DATE_FORMAT,
  Timestamp,
  Timezone,
  asyncForEach,
  getDoc$,
  getError,
  reduceToSingleArrayFn,
  snapshotCombineLatest,
  sortByCreatedAt,
  toISODate,
  toInt,
  toMoment,
  toMomentTz,
  toNamedDocument,
  toTimestamp,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first, groupBy, sortBy } from 'lodash';
import * as moment from 'moment-timezone';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import {
  BasePatientTreatmentPlanDestinationEntity,
  IPatientTreatmentPlanJobData,
  IPatientTreatmentPlanMigrationData,
  IStepWithIdentifiers,
  ITreatmentPlanStepPair,
  PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY,
} from '../../../destination/entities/patient-treatment-plans';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { resolveFeeSchedule } from '../../../mappings/fee-schedules';
import { resolveMappedCode } from '../../../mappings/item-codes';
import { ItemCodeResourceMapType } from '../../../mappings/item-codes-to-xlsx';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import {
  PatientSourceEntity,
  type IExactPatient,
  type IExactPatientTranslations,
} from '../../source/entities/patient';
import {
  AppointmentSourceEntity,
  type IExactAppointment,
  type IExactAppointmentFilters,
  type IExactAppointmentTranslations,
} from '../../source/entities/patient-appointment';
import {
  ExactTreatmentType,
  PatientTreatmentSourceEntity,
  type IExactTreatment,
  type IExactTreatmentFilters,
  type IExactTreatmentTranslations,
} from '../../source/entities/patient-treatments';
import { getResolvedTreatmentData } from '../../util/helpers';
import { getExactChartedRefs } from '../../util/tooth';
import { ExactFeeScheduleMappingHandler } from '../mappings/fee-schedules';
import { ExactItemCodeToTreatmentMappingHandler } from '../mappings/item-code-to-treatment';
import { ExactItemCodeMappingHandler } from '../mappings/item-codes';
import {
  ExactStafferMappingHandler,
  resolveExactStaffer,
} from '../mappings/staff';
import { PatientDestinationEntity } from './patient';
import { PatientAppointmentDestinationEntity } from './patient-appointments';

const EXACT_DEFAULT_PLAN_NAME = 'Migrated Exact Treatment Plan';

const defaultPlanId = 'default-plan';

const patientTreatmentPlanDestinationEntity =
  DestinationEntity.withMetadataDescription(
    PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY,
    `This migrates the treatments for each patient. Exact does have a loose structure for treatment plans, so we are going to migrate in similar fashion.
      - Treatments with a plan id will be grouped into a treatment plan using the Id (as they do in Exact) - a description is also used for naming if present
      - Treatments with a visit_id will be grouped into steps under the plans and the visit_sequence will determine the order of the steps
      - Treatments without a plan id will be placed in a default plan with one step for all completed and one for incomplete

      Treatments all have a type in Exact:
      - 'Historic' treatments will be considered completed
      - 'Base' treatments are considered existing so should be treated like conditions?
      - 'Planned' are considered incomplete IF they have no appointment_id or the appointment is not complete
    `
  );

type JobData = IPatientTreatmentPlanJobData<
  IExactPatient,
  IExactPatientTranslations
>;

export class PatientTreatmentPlanDestinationEntity extends BasePatientTreatmentPlanDestinationEntity<
  IExactPatient,
  JobData
> {
  override destinationEntity = patientTreatmentPlanDestinationEntity;
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    treatments: new PatientTreatmentSourceEntity(),
    appointments: new AppointmentSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    patientAppointments: new PatientAppointmentDestinationEntity(),
  };

  customMappings = {
    staff: new ExactStafferMappingHandler(),
    feeSchedules: new ExactFeeScheduleMappingHandler(),
    itemCodes: new ExactItemCodeMappingHandler(),
    itemCodeToTreatment: new ExactItemCodeToTreatmentMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<JobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const brand$ = PracticeMigration.brand$(migration);
    const practitioners$ = of([]);
    const taxRate$ = PracticeMigration.organisation$(migration).pipe(
      map((org) => getTaxRateByRegion(org.region))
    );
    const defaultTreatmentConfiguration$ = brand$.pipe(
      switchMap((brand) =>
        getDoc$(TreatmentConfiguration.col(brand), ADA_CODE_TREATMENT_CONFIG_ID)
      )
    );
    const treatmentCategories$ = brand$.pipe(
      switchMap((brand) => Brand.treatmentCategories$(brand))
    );
    const treatmentConfigurations$ = brand$.pipe(
      switchMap((brand) => TreatmentConfiguration.all$(brand))
    );

    const requiredData$ = snapshotCombineLatest([
      staff$,
      practitioners$,
      defaultTreatmentConfiguration$,
      treatmentConfigurations$,
      this.customMappings.itemCodeToTreatment.getRecords$(translationMap),
      this.customMappings.itemCodes.getRecords$(translationMap),
      treatmentCategories$,
      taxRate$,
    ]);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      requiredData$,
    ]).pipe(
      map(
        ([
          sourcePatients,
          [
            staff,
            practitioners,
            defaultTreatmentConfiguration,
            treatmentConfigurations,
            treatmentConfigurationMappings,
            sourceItemCodes,
            treatmentCategories,
            taxRate,
          ],
        ]) =>
          sourcePatients.map((sourcePatient) => ({
            sourcePatient,
            staff,
            practitioners,
            defaultTreatmentConfiguration,
            treatmentConfigurations,
            treatmentConfigurationMappings,
            sourceItemCodes,
            treatmentCategories,
            taxRate,
          }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: JobData
  ): Promise<
    | IPatientTreatmentPlanMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients.getSourceRecordId(
      data.sourcePatient.data.data
    );

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(data.sourcePatient, 'No patient');
    }

    const treatments = await getExactPatientTreatments(
      this.sourceEntities.treatments,
      migration,
      sourcePatientId,
      data.sourceItemCodes
    );

    const appointments = await this.sourceEntities.appointments.filterRecords(
      migration,
      'patientId',
      sourcePatientId
    );

    try {
      const planPairs = await this._buildTreatmentPlanData(
        data,
        translationMap,
        treatments,
        migration,
        sourcePatientId,
        appointments
      );
      return {
        sourcePatientId,
        patientRef,
        planPairs,
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  private async _buildTreatmentPlanData(
    data: JobData,
    translationMap: TranslationMapHandler,
    sourceTreatments: IGetRecordResponse<
      IExactTreatment,
      IExactTreatmentTranslations,
      IExactTreatmentFilters
    >[],
    migration: WithRef<IPracticeMigration>,
    sourcePatientId: string,
    sourceAppointments: IGetRecordResponse<
      IExactAppointment,
      IExactAppointmentTranslations,
      IExactAppointmentFilters
    >[]
  ): Promise<ITreatmentPlanStepPair[]> {
    const planGroups = groupBy(
      sourceTreatments,
      (sourceTreatment) =>
        sourceTreatment.data.data.treatment_plan_id ?? defaultPlanId
    );

    const defaultFeeSchedule = await FeeSchedule.getPreferredOrDefault(
      migration.configuration.organisation
    );

    const availableAppointments = compact(
      await asyncForEach(sourceAppointments, async (sourceAppointment) => {
        const appointmentRef = await translationMap.getDestination<
          WithRef<IAppointment>
        >(
          this.sourceEntities.appointments.getSourceRecordId(
            sourceAppointment.data.data
          ),
          this.destinationEntities.patientAppointments.destinationEntity
            .metadata.key
        );
        if (!appointmentRef) {
          return;
        }
        return Firestore.getDoc(appointmentRef);
      })
    );

    const allPlans = await asyncForEach(
      Object.values(planGroups),
      async (treatments) => {
        treatments = sortBy(treatments, (treatment) =>
          getVisitNumberFromTreatment(treatment, treatments)
        );

        const planId = treatments[0].data.data.treatment_plan_id;
        const defaultPlanName = planId
          ? `Treatment Plan ${planId}`
          : EXACT_DEFAULT_PLAN_NAME;
        const planName =
          treatments[0].data.data.treatment_description ?? defaultPlanName;
        const createdAt = treatments[0].data.translations.plannedDate
          ? toTimestamp(
              toMomentTz(
                treatments[0].data.translations.plannedDate,
                migration.configuration.timezone
              )
            )
          : undefined;

        const practitioner = await resolveExactStaffer(
          treatments[0].data.data.provider_code,
          translationMap,
          data.staff
        );

        const plan = {
          ...TreatmentPlan.init({
            name: planName.trim(),
            practitioner: practitioner
              ? stafferToNamedDoc(practitioner)
              : undefined,
            createdAt,
          }),
          sourceIdentifier: `${sourcePatientId}-plan-${planId}`,
        };

        const steps = await this._buildTreatmentStepData(
          treatments,
          translationMap,
          data,
          migration,
          sourcePatientId,
          defaultFeeSchedule,
          availableAppointments
        );

        if (!steps.length) {
          return;
        }

        const earliestStepDate = first(steps.sort(sortByCreatedAt))?.createdAt;
        if (earliestStepDate) {
          plan.createdAt = earliestStepDate;
        }

        const status = this._determinePlanStatus(steps, plan.createdAt);
        return {
          plan: {
            ...plan,
            status,
          },
          steps,
        };
      }
    );

    return compact(allPlans);
  }

  private async _buildTreatmentStepData(
    treatments: IGetRecordResponse<
      IExactTreatment,
      IExactTreatmentTranslations,
      IExactTreatmentFilters
    >[],
    translationMap: TranslationMapHandler,
    data: JobData,
    migration: WithRef<IPracticeMigration>,
    sourcePatientId: string,
    defaultFeeSchedule: INamedDocument<IFeeSchedule>,
    availableAppointments: WithRef<IAppointment>[]
  ): Promise<IStepWithIdentifiers[]> {
    const stepGroups = groupBy(treatments, (treatment) =>
      getVisitNumberFromTreatment(treatment, treatments)
    );

    return asyncForEach(
      Object.entries(stepGroups),
      async ([key, stepGroup]) => {
        const sourceTreatments = stepGroup.map(
          (response) => response.data.data
        );
        const stepName = `Treatment Step ${key}`;
        const chartedTreatments = await this._getChartedTreatments(
          sourceTreatments,
          resolveFeeSchedule(translationMap, defaultFeeSchedule),
          data,
          translationMap,
          migration.configuration.timezone
        );

        const isComplete = !!stepGroup[0].data.translations.completedDate;

        const date = isComplete
          ? stepGroup[0].data.translations.completedDate
          : stepGroup[0].data.translations.plannedDate;

        const createdAt = date
          ? toTimestamp(toMomentTz(date, migration.configuration.timezone))
          : toTimestamp();

        const step = {
          ...TreatmentStep.init({
            name: stepName,
            treatments: chartedTreatments,
            status: isComplete
              ? TreatmentStepStatus.Complete
              : TreatmentStepStatus.Incomplete,
          }),
          createdAt,
        };

        const treatmentCategory =
          await CalculateTreatmentWeight.getPrimaryCategory(
            step,
            data.treatmentCategories
          );

        const stepDate = toMomentTz(
          createdAt,
          migration.configuration.timezone
        ).format(ISO_DATE_FORMAT);

        const matchingAppointmentIndex = availableAppointments.findIndex(
          (appointment) => {
            if (
              !appointment.event ||
              stepGroup[0].data.data.treatment_type ===
                ExactTreatmentType.Planned
            ) {
              return;
            }

            const appointmentDate = toISODate(
              toMomentTz(
                appointment.event.from,
                migration.configuration.timezone
              )
            );
            return stepDate === appointmentDate;
          }
        );

        const matchingAppointment =
          availableAppointments[matchingAppointmentIndex];
        if (matchingAppointment) {
          availableAppointments.splice(matchingAppointmentIndex, 1);
          step.status =
            matchingAppointment.status === AppointmentStatus.Complete
              ? TreatmentStepStatus.Complete
              : TreatmentStepStatus.Incomplete;
          step.appointment = matchingAppointment.ref;
        }

        const sourceIdentifier = getStepIdentifier(sourcePatientId, stepGroup);

        if (!sourceIdentifier) {
          throw new Error(
            `Couldn't determine source identifier for step ${stepName}`
          );
        }

        return {
          ...step,
          display: {
            ...step.display,
            primaryTreatmentCategory: treatmentCategory?.ref,
          },
          createdAt,
          date: stepDate,
          sourceIdentifier,
        };
      }
    );
  }

  private _determinePlanStatus(
    steps: IStepWithIdentifiers[],
    planCreatedAt: Timestamp
  ): TreatmentPlanStatus {
    const allComplete = steps.every(
      (step) => step.status === TreatmentStepStatus.Complete
    );
    if (allComplete && steps.length) {
      return TreatmentPlanStatus.Completed;
    }

    const planAgeInMonths = moment().diff(toMoment(planCreatedAt), 'months');

    const someComplete = steps.some(
      (step) => step.status === TreatmentStepStatus.Complete
    );
    if (someComplete) {
      return TreatmentPlanStatus.InProgress;
    }

    const allIncomplete = steps.every(
      (step) => step.status === TreatmentStepStatus.Incomplete
    );
    if (allIncomplete && planAgeInMonths > 12) {
      return TreatmentPlanStatus.Declined;
    }

    return TreatmentPlanStatus.Draft;
  }

  private async _getChartedTreatments(
    sourceTreatments: IExactTreatment[],
    getPreferredOrDefaultRef: (
      feeSheduleId?: string
    ) => Promise<INamedDocument<IFeeSchedule>>,
    data: JobData,
    translationMap: TranslationMapHandler,
    timezone: Timezone
  ): Promise<IChartedTreatment[]> {
    const treatments: IChartedTreatment[] = [];
    await asyncForEach(sourceTreatments, async (sourceTreatment) => {
      const practitioner = await resolveExactStaffer(
        sourceTreatment.provider_code,
        translationMap,
        data.staff
      );
      if (!practitioner) {
        throw new Error(
          `Couldn't resolve practitioner for treatment ${sourceTreatment.provider_code}`
        );
      }

      const code = resolveMappedCode(
        data.sourceItemCodes,
        sourceTreatment.service_code,
        sourceTreatment.service_code
      );
      if (!code) {
        // eslint-disable-next-line no-console
        console.log(
          `Couldn't resolve code for treatment ${sourceTreatment.service_code}`
        );
        return;
      }

      const mappedTreatment = data.treatmentConfigurationMappings.find(
        (mapping) => mapping.sourceIdentifier === sourceTreatment.service_code
      );
      const treatmentConfiguration = mappedTreatment?.destinationIdentifier
        ? await Firestore.getDoc(mappedTreatment?.destinationIdentifier)
        : data.defaultTreatmentConfiguration;

      const sourceFeeScheduleId = sourceTreatment.payment_plan_id ?? undefined;
      const feeSchedule = await getPreferredOrDefaultRef(sourceFeeScheduleId);
      const taxStatus = TaxStrategy.GSTFree;
      const price = roundTo2Decimals(sourceTreatment.total_amount);
      const chartedSurfaces = this._getChartedSurfaces(
        sourceTreatment,
        practitioner,
        timezone
      );

      const serviceCode = PricedServiceCodeEntry.init({
        code: code.code,
        type: code.type,
        taxStatus,
        price,
        tax: determineTaxFromStrategy(data.taxRate, {
          taxStatus: taxStatus,
          amount: price,
        }),
        chartedSurfaces,
      });

      const scopeResolver = new ChartedItemScopeResolver();
      const scopeRefs = scopeResolver.reduceChartedSurfacesToScope(
        treatmentConfiguration,
        serviceCode.chartedSurfaces
      );
      const scopeRef = first(scopeRefs)?.scopeRef ?? {
        scope: ChartableSurface.WholeMouth,
      };

      const resolvedTreatmentData = getResolvedTreatmentData(
        sourceTreatment,
        practitioner,
        timezone
      );

      treatments.push(
        ChartedTreatment.init({
          uuid: sourceTreatment.treatment_id,
          config: toNamedDocument(treatmentConfiguration),
          feeSchedule: toNamedDocument(feeSchedule),
          serviceCodes: [serviceCode],
          chartedSurfaces: serviceCode.chartedSurfaces,
          attributedTo: stafferToNamedDoc(practitioner),
          scopeRef,
          resolvedAt: resolvedTreatmentData?.resolvedAt,
          resolvedBy: resolvedTreatmentData?.resolvedBy,
        })
      );
    });

    return treatments;
  }

  private _getChartedSurfaces(
    sourceTreatment: IExactTreatment,
    practitioner: WithRef<IStaffer>,
    timezone: Timezone
  ): IChartedSurface[] {
    if (!sourceTreatment.tooth_range && !sourceTreatment.tooth) {
      return [];
    }

    const teeth =
      sourceTreatment.tooth_range ?? compact([sourceTreatment.tooth]);
    return teeth
      .map((tooth) =>
        getExactChartedRefs(tooth, sourceTreatment.tooth_surfaces).map(
          (chartedRef) => {
            const resolvedTreatmentData = getResolvedTreatmentData(
              sourceTreatment,
              practitioner,
              timezone
            );

            return ChartedSurface.init({
              chartedBy: stafferToNamedDoc(practitioner),
              resolvedAt: resolvedTreatmentData?.resolvedAt,
              resolvedBy: resolvedTreatmentData?.resolvedBy,
              chartedRef,
            });
          }
        )
      )
      .reduce(reduceToSingleArrayFn, []);
  }
}

export async function getExactPatientTreatments(
  treatmentSourceEntity: PatientTreatmentSourceEntity,
  migration: WithRef<IPracticeMigration>,
  sourcePatientId: string,
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[]
): Promise<
  IGetRecordResponse<
    IExactTreatment,
    IExactTreatmentTranslations,
    IExactTreatmentFilters
  >[]
> {
  return treatmentSourceEntity.filterRecords(
    migration,
    'patientId',
    sourcePatientId,
    undefined,
    undefined,
    (record) => {
      const hasServiceCode = resolveMappedCode(
        sourceItemCodes,
        record.data.data.service_code,
        record.data.data.service_code
      );
      return (
        !!hasServiceCode &&
        record.data.data.treatment_type !== ExactTreatmentType.Base
      );
    }
  );
}

function getVisitNumberFromTreatment(
  treatment: IGetRecordResponse<
    IExactTreatment,
    IExactTreatmentTranslations,
    IExactTreatmentFilters
  >,
  treatments: IGetRecordResponse<
    IExactTreatment,
    IExactTreatmentTranslations,
    IExactTreatmentFilters
  >[]
): string {
  const lastVisitSequence = determineLastVisitNumber(treatments);
  return (
    treatment.data.data.visit_sequence ?? (lastVisitSequence + 1).toString()
  );
}

function determineLastVisitNumber(
  treatments: IGetRecordResponse<
    IExactTreatment,
    IExactTreatmentTranslations,
    IExactTreatmentFilters
  >[]
): number {
  return Math.max(
    ...treatments.map((treatment) =>
      treatment.data.data.visit_sequence
        ? toInt(treatment.data.data.visit_sequence)
        : 0
    )
  );
}

export function getStepIdentifier(
  sourcePatientId: string,
  treatments: IGetRecordResponse<
    IExactTreatment,
    IExactTreatmentTranslations,
    IExactTreatmentFilters
  >[]
): string | undefined {
  const sortedTreatments = sortBy(treatments, (treatment) =>
    getVisitNumberFromTreatment(treatment, treatments)
  );

  const treatment = first(treatments);
  if (!treatment) {
    return;
  }

  const visitNumber = getVisitNumberFromTreatment(treatment, sortedTreatments);

  return `${sourcePatientId}-step-${
    treatment.data.data.visit_id ?? visitNumber
  }`;
}
