import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  type ICustomReport,
  type IQueryScopeRequests,
  type ReportingReference,
} from '@principle-theorem/principle-core/interfaces';
import {
  ITimePeriod,
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { type IReportBuilderDataSource } from '../../../../models/report-builder-data-sources/report-builder-data-source';
import { resolveDataSource } from '../../../../models/report-builder-data-sources/report-builder-data-sources';
import {
  RunCustomReportForm,
  RunReportFormBloc,
} from './run-custom-report-form';

@Component({
  selector: 'pr-run-custom-report-form',
  templateUrl: './run-custom-report-form.component.html',
  styleUrls: ['./run-custom-report-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RunCustomReportFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  formData$ = new ReplaySubject<IQueryScopeRequests>(1);
  customReport$ = new ReplaySubject<WithRef<ICustomReport>>(1);
  form = new RunCustomReportForm();
  formBloc: RunReportFormBloc;
  submitDisabled$: Observable<boolean>;
  queryableTimestamp$: Observable<ReportingReference | undefined>;
  dataSource$: Observable<IReportBuilderDataSource>;
  dateRange$ = new ReplaySubject<ITimePeriod>(1);

  @Output() dateRangeChange = new EventEmitter<ITimePeriod>();
  @Output() queryDataChange = new EventEmitter<IQueryScopeRequests>();
  @Output() runReport = new EventEmitter<IQueryScopeRequests>();

  @Input() editMode = false;
  @Input()
  set customReport(customReport: WithRef<ICustomReport>) {
    if (customReport) {
      this.customReport$.next(customReport);
    }
  }

  @Input()
  set dateRange(dateRange: ITimePeriod) {
    if (dateRange) {
      this.dateRange$.next(dateRange);
    }
  }

  constructor(organisation: OrganisationService) {
    this.formBloc = new RunReportFormBloc(organisation);
    this.formBloc.brands$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((brands) => {
        const brand = first(brands);
        if (!brand) {
          return;
        }
        return this.form.controls.brand.setValue(brand);
      });
    this.formBloc.practices$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((practices) =>
        this.form.controls.practices.setValue(practices)
      );

    this.submitDisabled$ = this.form.valueChanges.pipe(
      map(() => this.form.valid),
      startWith(false),
      map((isValid) => !isValid)
    );

    this.queryableTimestamp$ = this.customReport$.pipe(
      map(
        (customReport) =>
          customReport?.queryScopes.dateRange?.queryableTimestamp
      )
    );
    this.dataSource$ = this.customReport$.pipe(
      map((report) => resolveDataSource(report.dataSource)),
      filterUndefined()
    );

    this.formData$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((formData) => this.queryDataChange.emit(formData));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async submit(): Promise<void> {
    if (!this.form.valid) {
      return;
    }
    const queryScopes = await snapshot(this.formData$);
    this.runReport.emit(queryScopes);
  }
}
