import { Injectable } from '@angular/core';
import { Invoice } from '@principle-theorem/principle-core';
import {
  TransactionProvider,
  type IHealthcareClaim,
  type IInvoice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference, Region, WithRef } from '@principle-theorem/shared';
import { Observable, of } from 'rxjs';
import {
  TransactionProviderType,
  type ITransactionProvider,
} from '../transaction-provider';
import { MedipassProviderService } from './medipass-provider.service';

@Injectable()
export class MedipassDVATransactionProvider implements ITransactionProvider {
  providerId = TransactionProvider.MedipassDVA;
  providerType = TransactionProviderType.DVA;
  providerRegions = [Region.Australia];
  isEnabled$: Observable<boolean>;

  constructor(private _medipassProvider: MedipassProviderService) {
    this.isEnabled$ = this._medipassProvider.isEnabled$;
  }

  canCapture$(invoice: WithRef<IInvoice>): Observable<boolean> {
    return of(Invoice.canAddTransactions(invoice));
  }

  async capture(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim
  ): Promise<DocumentReference<ITransaction> | undefined> {
    return this._medipassProvider.openFunder(invoice, 'dva', claim);
  }
}
