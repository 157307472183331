<button mat-icon-button [matMenuTriggerFor]="moreMenu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #moreMenu="matMenu">
  <button
    mat-menu-item
    [disabled]="(canBeCancelled$ | ngrxPush) === false"
    (click)="cancelEvent()"
  >
    <mat-icon>cancel</mat-icon>
    Cancel
  </button>
</mat-menu>
