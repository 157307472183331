import {
  MixedSchema,
  isMixedSchema,
  isRawInlineNodes,
  type RawInlineNodes,
} from '@principle-theorem/editor';
import {
  isObject,
  type INamedDocument,
  WithRef,
  isWithRef,
} from '@principle-theorem/shared';
import {
  isAutomationConfiguration,
  type IAutomationConfiguration,
  type IAutomationResource,
  IAutomation,
  isAutomation,
} from '../automation/automation';
import { isAutomationTiming } from '../automation/automation-timing';
import { type IStaffer } from '../staffer/staffer';
import { type ITeam } from '../team/team';
import { type TaskPriority } from './task';

export interface IGeneratedTaskConfiguration
  extends IAutomationConfiguration,
    IGeneratedTask {}

export function getGeneratedTaskFromConfiguration(
  config: IGeneratedTaskConfiguration
): IGeneratedTask {
  return {
    title: config.title,
    assignee: config.assignee,
    priority: config.priority,
    comment: config.comment,
    isForTeam: config.isForTeam,
    timing: config.timing,
    requiredConditions: config.requiredConditions,
  };
}

export interface IGeneratedTask extends IAutomationResource {
  title: RawInlineNodes;
  assignee?: INamedDocument<IStaffer | ITeam>;
  priority: TaskPriority;
  comment: MixedSchema;
  isForTeam: boolean;
}

export function isGeneratedTask(data: unknown): data is IGeneratedTask {
  return (
    isObject(data) &&
    isObject(data.title) &&
    isRawInlineNodes(data.title) &&
    isMixedSchema(data.comment) &&
    isAutomationTiming(data.timing)
  );
}

export function isTaskAutomation(
  automation: unknown
): automation is WithRef<IAutomation<IGeneratedTask>> {
  return (
    isWithRef(automation) &&
    isAutomation<IGeneratedTask>(automation, isGeneratedTask)
  );
}

export function isGeneratedTaskConfiguration(
  data: unknown
): data is IGeneratedTaskConfiguration {
  return isGeneratedTask(data) && isAutomationConfiguration(data);
}
