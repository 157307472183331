import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Organisation } from '@principle-theorem/principle-core';
import {
  type IOrganisation,
  isOrganisation,
  type IUser,
} from '@principle-theorem/principle-core/interfaces';
import {
  getDoc,
  isWithRef,
  type ResolverResult,
  type WithRef,
} from '@principle-theorem/shared';
import { doc } from '@principle-theorem/shared';

@Injectable()
export class OrgUserResolver {
  resolve(
    activatedRoute: ActivatedRouteSnapshot
  ): ResolverResult<WithRef<IUser>> {
    const uid: string = activatedRoute.paramMap.get('uid') || '';
    if (!activatedRoute.parent || !activatedRoute.parent.data.organisation) {
      throw new Error(`Can't find parent route`);
    }

    const organisation = activatedRoute.parent.data.organisation as unknown;
    if (
      !isOrganisation(organisation) ||
      !isWithRef<IOrganisation>(organisation)
    ) {
      throw new Error(`Can't find organisation`);
    }

    return getDoc(doc(Organisation.userCol(organisation), uid));
  }
}
