<ng-container *ngIf="dataSource.loading$ | async; else search">
  <mat-progress-bar mode="indeterminate" />
</ng-container>

<ng-template #search>
  <pt-search-field class="layout-margin">
    <input
      matInput
      type="text"
      placeholder="Search Waitlist"
      #search
      (keyup)="applyFilter(search.value)"
    />
  </pt-search-field>
</ng-template>

<mat-table matSort matSortActive="score" [dataSource]="dataSource">
  <mat-header-row *matHeaderRowDef="columnsToDisplay" />
  <mat-row *matRowDef="let myRowData; columns: columnsToDisplay" />

  <ng-container matColumnDef="add">
    <mat-header-cell *matHeaderCellDef fxFlex="7">Add</mat-header-cell>
    <mat-cell
      *matCellDef="let waitListCandidate"
      class="mat-column-select"
      fxFlex="6"
    >
      <button
        mat-icon-button
        *ngIf="
          !(waitListCandidate | map: isGapCandidate : this);
          else isCandidateButton
        "
        matTooltip="Add candidate"
        (click)="addToShortlist(waitListCandidate, gap)"
      >
        <mat-icon>person_add</mat-icon>
      </button>
      <ng-template #isCandidateButton>
        <span matTooltip="Already a candidate">
          <button mat-icon-button disabled>
            <mat-icon>done</mat-icon>
          </button>
        </span>
      </ng-template>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="edit">
    <mat-header-cell *matHeaderCellDef fxFlex="6">Edit</mat-header-cell>
    <mat-cell
      *matCellDef="let waitListCandidate"
      class="mat-column-select"
      fxFlex="5"
    >
      <button
        mat-icon-button
        matTooltip="Edit Waitlist Settings"
        (click)="openWaitlistConfiguration(waitListCandidate)"
      >
        <mat-icon>assignment</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="patient">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      Patient
    </mat-header-cell>
    <mat-cell *matCellDef="let waitListCandidate">
      {{ waitListCandidate.candidate.patient.name }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="practitioner">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      Practitioner
    </mat-header-cell>
    <mat-cell *matCellDef="let waitListCandidate">
      {{ waitListCandidate.appointment.practitioner.name }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="duration">
    <mat-header-cell *matHeaderCellDef fxFlex="15" mat-sort-header>
      Duration
    </mat-header-cell>
    <mat-cell *matCellDef="let waitListCandidate" fxFlex="15">
      <span>{{ waitListCandidate.appointment | map: duration }} mins</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="appointment">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      Appointment
    </mat-header-cell>
    <mat-cell *matCellDef="let waitListCandidate">
      <a
        href="javascript:void(0);"
        [matTooltip]="waitListCandidate.appointment | map: getTreatment"
        (click)="viewAppointmentCard(waitListCandidate.appointment)"
      >
        <ng-template #noAppointment>Unscheduled</ng-template>
        <span
          *ngIf="
            waitListCandidate.appointment | map: hasEvent;
            else noAppointment
          "
        >
          <ng-container
            *ngIf="
              waitListCandidate.appointment.event.from
                | momentTimezone$
                  : waitListCandidate.appointment.event.practice.ref
                | ngrxPush as from
            "
          >
            {{ from | moment | amDifference: today : 'days' }}
          </ng-container>
          days &#64;
          <pr-formatted-event-field
            field="from"
            [event]="waitListCandidate.appointment.event"
            format="hh:mma"
          />
        </span>
      </a>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="score">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Score</mat-header-cell>
    <mat-cell *matCellDef="let waitListCandidate">
      <div class="rating-score">
        <pr-appointment-score
          [score]="waitListCandidate | map: getMatchScore : this"
        />
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="urgency">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      Urgency
    </mat-header-cell>
    <mat-cell *matCellDef="let waitListCandidate">
      <div class="urgency" *ngIf="waitListCandidate.appointment.waitListItem">
        <mat-icon
          color="primary"
          *ngIf="waitListCandidate.appointment.waitListItem.urgency >= 1"
          >local_hospital</mat-icon
        >
        <mat-icon
          color="primary"
          *ngIf="waitListCandidate.appointment.waitListItem.urgency >= 2"
          >local_hospital</mat-icon
        >
        <mat-icon
          color="primary"
          *ngIf="waitListCandidate.appointment.waitListItem.urgency >= 3"
          >local_hospital</mat-icon
        >
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="productivity">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      Productivity
    </mat-header-cell>
    <mat-cell *matCellDef="let waitListCandidate">
      <div class="productivity">
        <mat-icon color="accent" *ngIf="waitListCandidate.productivity >= 1">
          attach_money
        </mat-icon>
        <mat-icon color="accent" *ngIf="waitListCandidate.productivity >= 2">
          attach_money
        </mat-icon>
        <mat-icon color="accent" *ngIf="waitListCandidate.productivity >= 3">
          attach_money
        </mat-icon>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="warnings">
    <mat-header-cell *matHeaderCellDef>Warnings</mat-header-cell>
    <mat-cell *matCellDef="let waitListCandidate">
      <pr-wait-list-warnings
        [gap]="gap"
        [waitListCandidate]="waitListCandidate"
      />
    </mat-cell>
  </ng-container>
</mat-table>

<div fxLayoutAlign="space-between center">
  <div fxLayoutGap="16px">
    <button
      mat-stroked-button
      class="block-out-time"
      matTooltip="Create a blocking event to fill this gap"
      (click)="blockTime(gap)"
    >
      Block-Out Time
    </button>
    <button
      mat-flat-button
      color="primary"
      matTooltip="Create appointment with this gap as the filter criteria"
      (click)="appointmentFromGap(gap)"
    >
      Create Appointment
    </button>
  </div>
  <mat-paginator
    #paginator
    [length]="dataSource.data.length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    showFirstLastButtons
  />
</div>
