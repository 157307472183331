import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AbstractResolver } from '@principle-theorem/ng-shared';
import { SystemTemplates } from '@principle-theorem/principle-core';
import { type ITemplateDefinition } from '@principle-theorem/principle-core/interfaces';
import { doc, type CollectionReference } from '@principle-theorem/shared';
import { type Observable, of } from 'rxjs';
import { TemplateCollectionResolver } from './template-collection-resolver';

@Injectable()
export class TemplateResolver extends AbstractResolver<ITemplateDefinition> {
  static readonly resolverKey: string = 'template';

  constructor(router: Router) {
    super(router);
  }

  getItemById(
    id: string,
    route: ActivatedRouteSnapshot
  ): Observable<ITemplateDefinition | undefined> {
    const collection = this._findInheritedRouteData<
      CollectionReference<ITemplateDefinition>
    >(route, TemplateCollectionResolver.resolverKey);
    if (!collection) {
      return of(undefined);
    }
    return SystemTemplates.getSystemTemplate$(doc(collection, id));
  }
}
