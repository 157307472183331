import { Injectable } from '@angular/core';
import { BridgeCommandsService } from '../../../bridge-commands.service';
import { DocumentReference, WithRef } from '@principle-theorem/shared';
import {
  IBridgeDevice,
  IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  RomexisCaptureMode,
  RomexisOpenPatientCommand,
  RomexisToDeviceCommand,
} from '@principle-theorem/principle-bridge-core';

@Injectable()
export class RomexisFeatureService {
  constructor(private _bridgeCommands: BridgeCommandsService) {}

  async openPatient(
    patient: WithRef<IPatient>,
    mode: RomexisCaptureMode,
    deviceRef: DocumentReference<IBridgeDevice>
  ): Promise<void> {
    const command: RomexisOpenPatientCommand = {
      type: RomexisToDeviceCommand.OpenRomexis,
      data: {
        patient,
        mode,
      },
    };
    await this._bridgeCommands.sendCommand(command, deviceRef);
  }
}
