import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DateService } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { Appointment } from '@principle-theorem/principle-core';
import { query$, type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { orderBy } from '@principle-theorem/shared';

@Component({
  selector: 'pr-appointment-upcoming',
  templateUrl: './appointment-upcoming.component.html',
  styleUrls: ['./appointment-upcoming.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentUpcomingComponent {
  patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);
  appointments$: Observable<WithRef<IAppointment>[]>;
  trackByAppointment = TrackByFunctions.ref<WithRef<IAppointment>>();

  constructor(public dateService: DateService) {
    this.appointments$ = this._getAppointments$();
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  private _getAppointments$(): Observable<WithRef<IAppointment>[]> {
    return this.patient$.pipe(
      switchMap((patient) =>
        query$(Appointment.col(patient), orderBy('event.from')).pipe(
          map((appointments): WithRef<IAppointment>[] =>
            appointments.filter((appointment) =>
              Appointment.isFutureAppointment(appointment)
            )
          )
        )
      )
    );
  }
}
