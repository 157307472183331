<pt-breadcrumbs current="Tools" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="wrapper">
  <div fxLayout="column" fxLayoutGap="16px">
    <div class="test-services">
      <div class="flex flex-col">
        <h3 class="mat-headline-6">Test Services</h3>
        <pt-loader-button
          class="mb-4"
          color="primary"
          type="flat"
          [loading]="testingServices$ | async"
          (clicked)="testServices()"
        >
          Run Tests
        </pt-loader-button>

        <mat-list>
          @for (service of testServiceUpdates$ | async; track service.type) {
            <mat-list-item>
              <mat-icon
                matListItemIcon
                class="text-gray-500"
                [ngClass]="{
                  '!text-success-500': service.status === 'passed',
                  '!text-warn-500': service.status === 'failed'
                }"
                >check_circle</mat-icon
              >
              <span matListItemTitle>{{
                service.type | splitCamel | titlecase
              }}</span>
              @if (service.message) {
                <small matListItemLine class="mat-caption">{{
                  service.message
                }}</small>
              }

              <div
                matListItemMeta
                class="service-status"
                [ngClass]="{
                  '!text-success-500': service.status === 'passed',
                  '!text-warn-500': service.status === 'failed'
                }"
              >
                {{ service.status | titlecase }}
              </div>
            </mat-list-item>
          }
        </mat-list>
      </div>
    </div>
  </div>
</div>
