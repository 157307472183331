import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ChartSection } from '@principle-theorem/principle-core/interfaces';
import { TrackByFunctions } from '@principle-theorem/ng-shared';

@Component({
  selector: 'pr-chart-section-selector',
  templateUrl: './chart-section-selector.component.html',
  styleUrls: ['./chart-section-selector.component.sass'],
})
export class ChartSectionSelectorComponent {
  trackBySection = TrackByFunctions.variable<ChartSection>();
  sectionGroups: ChartSection[][] = [
    [ChartSection.BothArches, ChartSection.TopArch, ChartSection.BottomArch],
    [
      ChartSection.TopRight,
      ChartSection.TopLeft,
      ChartSection.BottomLeft,
      ChartSection.BottomRight,
    ],
  ];
  @Input() section: ChartSection = ChartSection.BothArches;
  @Output()
  selectionChange = new EventEmitter<ChartSection>();

  select(section: ChartSection): void {
    this.selectionChange.emit(section);
  }
}
