import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgCustomFormsModule } from '@principle-theorem/ng-custom-forms';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { EmptyStateComponent } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { PatientFormsStore } from '../../pages/patient-forms-page/patient-forms-store.service';

@Component({
  selector: 'pr-patient-forms-list',
  templateUrl: './patient-forms-list.component.html',
  styleUrls: ['./patient-forms-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    NgMaterialModule,
    NgSharedModule,
    NgCustomFormsModule,
    EmptyStateComponent,
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex flex-col gap-4' },
})
export class PatientFormsListComponent {
  store = inject(PatientFormsStore);
}
