import { type IHistoryItem } from '@principle-theorem/ng-principle-shared';
import { ClinicalChart } from '@principle-theorem/principle-core';
import {
  type IClinicalChart,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  HISTORY_DATE_TIME_FORMAT,
  sortTimestamp,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import { of, type OperatorFunction } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChartHistoryItem
  extends IHistoryItem<WithRef<IClinicalChart>> {}

export function chartHistoryItems$(): OperatorFunction<
  WithRef<IPatient> | undefined,
  IChartHistoryItem[]
> {
  return switchMap((patient) => {
    if (!patient) {
      return of([]);
    }
    return ClinicalChart.all$(patient).pipe(
      map((charts) =>
        charts
          .map((chart) => chartToHistoryItem(chart))
          .sort((a, b) => sortTimestamp(a.value.createdAt, b.value.createdAt))
      )
    );
  });
}

function chartToHistoryItem(chart: WithRef<IClinicalChart>): IChartHistoryItem {
  return {
    label: toMoment(chart.createdAt).format(HISTORY_DATE_TIME_FORMAT),
    timestamp: chart.createdAt,
    value: chart,
  };
}
