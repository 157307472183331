import { Action } from '@ngrx/store';
import {
  AppointmentSuggestionActions,
  type loadAppointmentSuggestionsSuccess,
  type loadAvailabilitySuccess,
} from './appointment-suggestions.actions';

function isLoadSuggestionSuccess(
  action: Action
): action is ReturnType<typeof loadAppointmentSuggestionsSuccess> {
  return (
    String(action.type) === String(AppointmentSuggestionActions.LoadSuccess)
  );
}

function isLoadAvailabilitySuccess(
  action: Action
): action is ReturnType<typeof loadAvailabilitySuccess> {
  return (
    String(action.type) ===
    String(AppointmentSuggestionActions.LoadAvailabilitySuccess)
  );
}

export function sanitise<A extends Action = Action>(
  action: A,
  _id: number
): Action {
  if (isLoadSuggestionSuccess(action)) {
    return {
      ...action,
      appointmentSuggestions: [],
    };
  }
  if (isLoadAvailabilitySuccess(action)) {
    return {
      ...action,
      availableTimes: [],
    };
  }
  return action;
}
