<div class="flex flex-col gap-2">
  <div class="flex items-center justify-between gap-2">
    <div class="flex flex-auto text-base font-bold">Invoice</div>
    <a
      *ngIf="invoiceLink$ | async as invoiceLink"
      [prBrandRouterLink]="invoiceLink"
    >
      View Invoice
    </a>
    <pr-invoice-status
      *ngIf="invoice$ | async as invoice"
      [dense]="true"
      [status]="invoice.status"
    />
  </div>

  <div
    class="flex flex-col gap-2 sm:flex-row lg:flex-col"
    *ngIf="summary$ | async as summary; else noInvoice"
  >
    <div class="flex flex-auto flex-col gap-1">
      <div class="flex gap-2">
        <div class="flex flex-auto">Health Fund Claims</div>
        <div>{{ summary.healthFundClaims | currency }}</div>
      </div>

      <div class="flex gap-2">
        <div class="flex flex-auto">Payments</div>
        <div>{{ summary.payments | currency }}</div>
      </div>

      <div class="flex gap-2">
        <div class="flex flex-auto">Account Credits</div>
        <div>{{ summary.accountCredits | currency }}</div>
      </div>

      <div class="flex gap-2">
        <div class="flex flex-auto">Discounts</div>
        <div>{{ summary.discounts | currency }}</div>
      </div>
    </div>

    <div class="flex flex-auto flex-col gap-1">
      <div class="flex gap-2">
        <div class="flex flex-auto">Refunds</div>
        <div>{{ summary.refunds | currency }}</div>
      </div>

      <div class="flex gap-2">
        <div class="flex flex-auto">Total Received</div>
        <div>{{ summary.totalReceived | currency }}</div>
      </div>

      <div class="flex gap-2 font-bold">
        <div class="flex flex-auto">Balance</div>
        <div>{{ summary.balance | currency }}</div>
      </div>
    </div>
  </div>
</div>

<ng-template #noInvoice>
  <div class="text-xs opacity-50">
    This appointment doesn't have an invoice yet
  </div>
</ng-template>
