<ng-container *ngIf="event$ | ngrxPush as event">
  <div class="info">
    <h3>
      <pr-formatted-event-field
        field="from"
        [event]="event"
        [format]="dateFormat"
       />
    </h3>
    <p>{{ timeDisplay$ | ngrxPush }}</p>
  </div>

  <div class="info">
    <h3>{{ event.practice.name }}</h3>
  </div>
</ng-container>

<div class="info">
  <h3>Participants</h3>

  <div
    *ngFor="
      let participant of participants$ | ngrxPush;
      trackBy: trackByParticipant
    "
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
    class="participant"
  >
    <pt-user-icon
      [diameter]="36"
      [src]="participant | profileImage$ | ngrxPush"
      [name]="participant.name"
     />

    <pr-event-participant-link [participant]="participant">
      {{ participant.name }}
    </pr-event-participant-link>
  </div>
</div>
