import {
  Gender,
  IContactNumber,
  IPatient,
  IPatientRelationship,
  isContactNumber,
} from '@principle-theorem/principle-core/interfaces';
import {
  TypeGuard,
  isEnumValue,
  isObject,
  isISODateType,
} from '@principle-theorem/shared';
import { isEmpty, isString } from 'lodash';

export interface IPatientDetails
  extends Pick<
    IPatient,
    | 'name'
    | 'gender'
    | 'referrer'
    | 'medicareCard'
    | 'dvaCard'
    | 'healthInsuranceDetails'
    | 'nationalHealthIndexNumber'
    | 'preferredFeeSchedule'
    | 'preferredHygienist'
    | 'preferredDentist'
    | 'relationships'
    | 'dateOfBirth'
  > {
  email: string;
  contactNumbers: IContactNumber[];
  primaryContact?: IPatientRelationship<IPatient>;
}

export function isPatientDetails(data: unknown): data is IPatientDetails {
  return (
    isObject(data) &&
    isString(data.name) &&
    !isEmpty(data.name) &&
    (isString(data.email) ||
      TypeGuard.arrayOf(isContactNumber)(data.contactNumbers)) &&
    TypeGuard.nilOr(isISODateType)(data.dateOfBirth) &&
    'gender' in data &&
    isEnumValue(Gender, data.gender)
  );
}

export function patientHasEmailOrContactNumber(
  patient: IPatientDetails
): boolean {
  return patient.email ||
    (patient.contactNumbers && patient.contactNumbers.length)
    ? true
    : false;
}
