<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="16px" class="wrapper">
  <h1 class="mat-headline-4">Opening Hours</h1>
  <div>
    <div
      class="day"
      *ngFor="let row of openingHoursByDay$ | async; trackBy: trackByDay"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
    >
      <h3 fxFlex="20">{{ row.dayOfWeek | titlecase }}</h3>
      <h3 fxFlex>
        <ng-container *ngIf="row.openingTimes; else closed">
          {{ row.openingTimes.from | timeFormat }} to
          {{ row.openingTimes.to | timeFormat }}
        </ng-container>
        <ng-template #closed>Closed</ng-template>
      </h3>
      <pt-buttons-container>
        <button
          mat-stroked-button
          (click)="delete(row.dayOfWeek)"
          [disabled]="row.openingTimes === undefined"
        >
          Set as Closed
        </button>
        <button mat-flat-button color="primary" (click)="edit(row.dayOfWeek)">
          Edit
        </button>
      </pt-buttons-container>
    </div>
  </div>
</div>
