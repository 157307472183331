import {
  ICustomFormConfiguration,
  isCustomFormConfiguration,
  ISerialisedCustomFormConfiguration,
  isSerialisedCustomFormConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  fromJSON,
  ITransformer,
  toJSON,
  TransformEvent,
} from '@principle-theorem/shared';

export class CustomFormConfigurationSerialiser
  implements
    ITransformer<ICustomFormConfiguration, ISerialisedCustomFormConfiguration>
{
  events = [TransformEvent.ToFirestore];

  guard(item: unknown): item is ICustomFormConfiguration {
    return isCustomFormConfiguration(item);
  }

  transform(
    item: ICustomFormConfiguration
  ): ISerialisedCustomFormConfiguration {
    return {
      ...item,
      layout: toJSON(item.layout),
      schema: toJSON(item.schema),
    };
  }
}

export class CustomFormConfigurationUnserialiser
  implements
    ITransformer<ISerialisedCustomFormConfiguration, ICustomFormConfiguration>
{
  events = [TransformEvent.FromFirestore];

  guard(item: unknown): item is ISerialisedCustomFormConfiguration {
    return isSerialisedCustomFormConfiguration(item);
  }

  transform(
    item: ISerialisedCustomFormConfiguration
  ): ICustomFormConfiguration {
    return {
      ...item,
      layout: fromJSON(item.layout),
      schema: fromJSON(item.schema),
    };
  }
}
