import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  Appointment,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  AppointmentSummary,
  IAppointment,
  IInvoice,
  InteractionType,
  InvoiceStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  asDocRef,
  filterUndefined,
  getDoc,
  saveDoc,
  shareReplayCold,
  snapshot,
} from '@principle-theorem/shared';
import { isString, startCase } from 'lodash';
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

const appointmentStatusActionLabelMap: {
  [key in AppointmentStatus]?: {
    nextLabel?: string;
    revertLabel?: string;
    canRevertTo(
      appointment: AppointmentSummary,
      invoice?: IInvoice
    ): boolean | string;
    canGoNext(appointment: AppointmentSummary): boolean | string;
  };
} = {
  [AppointmentStatus.Scheduled]: {
    revertLabel: 'Revert to Scheduled',
    canRevertTo: (appointment: AppointmentSummary) =>
      Appointment.canRevertToScheduled(appointment) ||
      `Appointment can't be reverted once started`,
    canGoNext: () => 'Must be booked to become Scheduled',
  },
  [AppointmentStatus.Confirmed]: {
    nextLabel: 'Mark as Confirmed',
    revertLabel: 'Revert to Confirmed',
    canRevertTo: (appointment: AppointmentSummary) =>
      Appointment.canRevertToConfirmed(appointment) ||
      `Appointment can't be reverted once In Progress`,
    canGoNext: (appointment: AppointmentSummary) =>
      Appointment.canConfirm(appointment) ||
      'Must first be Scheduled to be Confirmed',
  },
  [AppointmentStatus.Arrived]: {
    nextLabel: 'Mark as Arrived',
    revertLabel: 'Revert to Arrived',
    canRevertTo: (appointment: AppointmentSummary) =>
      Appointment.canRevertToCheckedIn(appointment),
    canGoNext: (appointment: AppointmentSummary) =>
      Appointment.canCheckIn(appointment),
  },
  [AppointmentStatus.CheckedIn]: {
    nextLabel: 'Mark as Checked In',
    revertLabel: 'Revert to Checked In',
    canRevertTo: (appointment: AppointmentSummary) =>
      Appointment.canRevertToCheckedIn(appointment) ||
      `Can't revert once appointment has been Checked Out`,
    canGoNext: (appointment: AppointmentSummary) =>
      Appointment.canCheckIn(appointment) ||
      `Can't set to Checked In unless on appointment date`,
  },
  [AppointmentStatus.InProgress]: {
    nextLabel: 'Start Appointment',
    revertLabel: 'Revert to In Progress',
    canRevertTo: (appointment: AppointmentSummary, invoice?: IInvoice) => {
      if (
        !Appointment.isCheckingOut(appointment) &&
        !Appointment.isComplete(appointment)
      ) {
        return false;
      }

      if (!invoice || invoice.status === InvoiceStatus.Draft) {
        return true;
      }

      return `Invoice has been issued. Revert the invoice to 'Draft' if you want to make changes.`;
    },
    canGoNext: (appointment: AppointmentSummary) =>
      Appointment.canStart(appointment),
  },
  [AppointmentStatus.CheckingOut]: {
    nextLabel: 'Ready for Checkout',
    revertLabel: 'Revert to Checkout Out',
    canRevertTo: (appointment: AppointmentSummary) =>
      Appointment.isComplete(appointment),
    canGoNext: (appointment: AppointmentSummary) =>
      Appointment.canCheckOut(appointment),
  },
  [AppointmentStatus.Complete]: {
    nextLabel: 'Mark as "Complete"',
    canRevertTo: () => false,
    canGoNext: (appointment: AppointmentSummary) =>
      Appointment.canComplete(appointment) ||
      `Appointment much first be checked out`,
  },
};

interface IStatusWithLabel {
  status: AppointmentStatus;
  label: string | undefined;
}

@Component({
  selector: 'pr-appointment-status-workflow',
  templateUrl: './appointment-status-workflow.component.html',
  styleUrls: ['./appointment-status-workflow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AppointmentStatusWorkflowComponent {
  appointment$ = new ReplaySubject<AppointmentSummary>(1);
  invoice$: Observable<WithRef<IInvoice> | undefined>;
  statuses = [
    AppointmentStatus.Scheduled,
    AppointmentStatus.Confirmed,
    AppointmentStatus.Arrived,
    AppointmentStatus.CheckedIn,
    AppointmentStatus.InProgress,
    AppointmentStatus.CheckingOut,
    AppointmentStatus.Complete,
  ];
  trackByStatus = TrackByFunctions.variable<AppointmentStatus>();
  currentStatus$ = this.appointment$.pipe(
    map((appointment) => ({
      status: appointment.metadata.status,
      label: startCase(appointment.metadata.status),
    }))
  );
  nextStatus$: Observable<IStatusWithLabel>;
  @Input() color: ThemePalette;
  @Input() type: 'stroked' | 'flat' = 'stroked';

  @Input()
  set appointment(appointment: AppointmentSummary) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor(
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService
  ) {
    this.nextStatus$ = this.currentStatus$.pipe(
      map((currentStatus) => {
        const currentIndex = this.statuses.indexOf(currentStatus.status);
        return this.buildStatusWithLabel(this.statuses[currentIndex + 1]);
      })
    );

    this.invoice$ = this.appointment$.pipe(
      switchMap((appointment) =>
        appointment.ref
          ? getDoc(asDocRef<IAppointment>(appointment.ref))
          : of(undefined)
      ),
      switchMap((appointment) =>
        appointment?.invoiceRef ? getDoc(appointment.invoiceRef) : of(undefined)
      ),
      shareReplayCold()
    );
  }

  isCurrentStatus$(status: AppointmentStatus): Observable<boolean> {
    return this.currentStatus$.pipe(
      map((currentStatus) => currentStatus.status === status)
    );
  }

  async updateStatus(status?: AppointmentStatus): Promise<void> {
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const appointmentSummary = await snapshot(this.appointment$);
    if (!status || !appointmentSummary.ref) {
      return;
    }
    const appointment = await getDoc(
      asDocRef<IAppointment>(appointmentSummary.ref)
    );
    await Appointment.updateStatus(
      appointment,
      status,
      InteractionType.Appointment,
      stafferToNamedDoc(staffer)
    );
    await saveDoc(appointment);
    this._snackBar.open('Status updated');
  }

  buildStatusWithLabel(status: AppointmentStatus): IStatusWithLabel {
    return {
      status,
      label:
        appointmentStatusActionLabelMap[status]?.nextLabel ?? startCase(status),
    };
  }

  buildStatusTooltip$(data: {
    status: AppointmentStatus;
    index: number;
  }): Observable<string | undefined> {
    return combineLatest([
      this.currentStatus$,
      this.appointment$,
      this.invoice$,
    ]).pipe(
      map(([currentStatus, appointment, invoice]) => {
        if (currentStatus.status === data.status) {
          return;
        }
        const currentStatusIndex = this.statuses.indexOf(currentStatus.status);

        if (currentStatusIndex > data.index) {
          const canRevert = appointmentStatusActionLabelMap[
            data.status
          ]?.canRevertTo(appointment, invoice);

          return isString(canRevert) ? canRevert : undefined;
        }
        const canGoNext =
          appointmentStatusActionLabelMap[data.status]?.canGoNext(appointment);
        return isString(canGoNext) ? canGoNext : undefined;
      })
    );
  }

  isDisabled$(data: {
    status: AppointmentStatus;
    index: number;
  }): Observable<boolean> {
    return combineLatest([
      this.currentStatus$,
      this.appointment$,
      this.invoice$,
    ]).pipe(
      map(([currentStatus, appointment, invoice]) => {
        if (currentStatus.status === data.status) {
          return true;
        }
        const currentStatusIndex = this.statuses.indexOf(currentStatus.status);

        if (currentStatusIndex > data.index) {
          const canRevert = appointmentStatusActionLabelMap[
            data.status
          ]?.canRevertTo(appointment, invoice);

          return isString(canRevert) ? true : !canRevert;
        }
        const canGoNext =
          appointmentStatusActionLabelMap[data.status]?.canGoNext(appointment);
        return isString(canGoNext) ? true : !canGoNext;
      })
    );
  }
}
