import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  GlobalStoreService,
  TagType,
} from '@principle-theorem/ng-principle-shared';
import { Brand } from '@principle-theorem/principle-core';
import {
  type IBrand,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import { addDoc, snapshot, type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-patient-note-tags',
    templateUrl: './patient-note-tags.component.html',
    styleUrls: ['./patient-note-tags.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientNoteTagsComponent {
  brand$ = new ReplaySubject<WithRef<IBrand>>(1);
  patientNoteTags$: Observable<WithRef<ITag>[]>;

  constructor(private _globalStore: GlobalStoreService) {
    this.patientNoteTags$ = this._globalStore.getTagsByType$(
      TagType.PatientNote
    );
  }

  @Input()
  set brand(brand: WithRef<IBrand>) {
    if (brand) {
      this.brand$.next(brand);
    }
  }

  async addTag(event: ITag): Promise<void> {
    const brand = await snapshot(this.brand$);
    await addDoc(Brand.patientNoteTagCol(brand), event);
  }
}
