<mat-toolbar color="accent">Patient Relationships</mat-toolbar>

<mat-dialog-content>
  <pr-patient-primary-contact-form [formData]="data" />
  <pr-patient-relationship-form
    [formData]="data"
   />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" (click)="submit()">Save</button>
  </pt-buttons-container>
</mat-dialog-actions>
