import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  type AutomationStatus,
  AUTOMATION_STATUS_COLOUR_MAP,
} from '@principle-theorem/principle-core/interfaces';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-automation-status',
    templateUrl: './automation-status.component.html',
    styleUrls: ['./automation-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AutomationStatusComponent {
  status$ = new ReplaySubject<AutomationStatus>(1);
  colour$: Observable<string>;

  @Input()
  set status(status: AutomationStatus) {
    if (status) {
      this.status$.next(status);
    }
  }

  constructor() {
    this.colour$ = this.status$.pipe(
      map((status) => AUTOMATION_STATUS_COLOUR_MAP[status])
    );
  }
}
