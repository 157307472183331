import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganisationFeeScheduleCollectionResolver } from './organisation-fee-schedule-collection-resolver.service';
import { ComponentsModule } from './components/components.module';

@NgModule({
  imports: [CommonModule, ComponentsModule],
  exports: [ComponentsModule],
  providers: [OrganisationFeeScheduleCollectionResolver],
})
export class NgClinicalFeesModule {}
