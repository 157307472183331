import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { CASUAL_DATE_WITH_YEAR } from '@principle-theorem/shared';
import { IGapDayPair } from '@principle-theorem/ng-principle-shared';

@Component({
    selector: 'pr-gap-day-header',
    templateUrl: './gap-day-header.component.html',
    styleUrls: ['./gap-day-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GapDayHeaderComponent {
  gapDayPair$ = new ReplaySubject<IGapDayPair>(1);
  dateFormat = CASUAL_DATE_WITH_YEAR;

  @Input()
  set gapDayPair(gapDayPair: IGapDayPair) {
    if (gapDayPair) {
      this.gapDayPair$.next(gapDayPair);
    }
  }
}
