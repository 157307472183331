<div class="flex min-h-[500px] min-w-[500px] flex-col items-center gap-2 p-2">
  <img [src]="currentImageURL()" class="object-contain" />

  @if (showNavigation()) {
    <div class="flex items-center gap-4">
      <button
        mat-icon-button
        matTooltip="Previous Image"
        (click)="previousImage()"
        [disabled]="isFirstImage()"
      >
        <mat-icon>chevron_left</mat-icon>
      </button>

      <span>{{ currentImage() }} / {{ totalImages() }}</span>

      <button
        mat-icon-button
        matTooltip="Next Image"
        (click)="nextImage()"
        [disabled]="isLastImage()"
      >
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  } @else {
    <div class="h-2"></div>
  }
</div>

<div class="-mt-6 flex items-center justify-end gap-4 p-4">
  <button mat-flat-button color="warn" (click)="deleteImage()">Delete</button>
  <button mat-stroked-button mat-dialog-close>Close</button>
</div>
