import { type IXSLXImport } from '@principle-theorem/shared';
import type { Row } from 'exceljs';
import {
  STAFFER_TO_USER_HEADERS,
  type IStafferToUserXSLX,
} from './staffer-to-user-to-xlsx';

export class XSLXToStafferToUser implements IXSLXImport<IStafferToUserXSLX> {
  headers = STAFFER_TO_USER_HEADERS;

  translate(row: Row): IStafferToUserXSLX {
    return {
      id: row.getCell('id').value?.toString() ?? '',
      name: row.getCell('name').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IStafferToUserXSLX['mapTo'],
    };
  }
}
