<div fxLayout="column" fxLayoutGap="16px" fxLayout.gt-lg="row">
  <div
    class="box-border flex h-full min-h-full w-full flex-col gap-3"
  >
    <ng-container *ngIf="currentConditions$ | async as currentConditions">
      <h5>Current Conditions</h5>
      <pr-charted-condition-summary
        *ngFor="let condition of currentConditions; trackBy: trackByCondition"
        [condition]="condition"
        [disabled]="disabled"
        [selectedSurfaces]="selectedSurfaces"
        (conditionChange)="updateCondition($event)"
        (conditionDelete)="deleteCondition($event)"
        (updateConditionSurfaces)="updateConditionSurfaces($event)"
       />
    </ng-container>
    <ng-container *ngIf="resolvedConditions$ | async as resolvedConditions">
      <h5>Resolved Conditions</h5>
      <pr-charted-condition-summary
        *ngFor="let condition of resolvedConditions; trackBy: trackByCondition"
        [condition]="condition"
        [disabled]="disabled"
        [selectedSurfaces]="selectedSurfaces"
        (conditionChange)="updateCondition($event)"
        (conditionDelete)="deleteCondition($event)"
        (updateConditionSurfaces)="updateConditionSurfaces($event)"
       />
    </ng-container>
  </div>
</div>
