<mat-toolbar [color]="data.toolbarColor">{{ data.title }}</mat-toolbar>

<form [formGroup]="formGroup" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field>
      <mat-label>{{ data.prompt }}</mat-label>
      <input matInput cdkFocusInitial formControlName="result" />
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button mat-stroked-button type="button" mat-dialog-close>Cancel</button>
      <button mat-flat-button [color]="data.submitColor">
        {{ data.submitLabel }}
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
