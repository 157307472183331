import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ComponentsModule } from '../../../../components/components.module';
import { StaffListComponent } from './staff-list/staff-list.component';
import { StafferCollectionResolverService } from './staffer-collection-resolver.service';
import { StafferEditComponent } from './staffer-edit/staffer-edit.component';
import { StafferResolverService } from './staffer-resolver.service';

@NgModule({
  declarations: [StaffListComponent, StafferEditComponent],
  imports: [
    NgMaterialModule,
    NgSharedModule,
    RouterModule,
    ReactiveFormsModule,
    NgFeatureFlagsModule,
    ComponentsModule,
    NgPrincipleSharedModule,
  ],
  providers: [StafferCollectionResolverService, StafferResolverService],
})
export class StaffModule {}
