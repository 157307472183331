import { IRecurringTaskDimension } from '@principle-theorem/reporting/interfaces';
import {
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
  toTimestamp,
} from '@principle-theorem/shared';
import {
  AvroSerialisedRawInlineNodesMock,
  AvroSerialisedVersionedSchemaMock,
  BigQueryFirestoreModelMock,
  BigQueryNamedDocumentMock,
  SerialisedDocumentReferenceMock,
} from './common.mock';
import { RecurringTaskMock } from '@principle-theorem/principle-core';

const recurringTask = MockGenerator.generate(RecurringTaskMock);

export class RecurringTaskDimensionMock
  extends BigQueryFirestoreModelMock
  implements IRecurringTaskDimension
{
  timestamp = toSerialisedTimestamp(toTimestamp());
  title = MockGenerator.generate(AvroSerialisedRawInlineNodesMock);
  description = MockGenerator.generate(AvroSerialisedVersionedSchemaMock);
  priority = recurringTask.priority;
  recurrencePattern = {
    ...recurringTask.recurrencePattern,
    startDate: recurringTask.recurrencePattern.startDate
      ? recurringTask.recurrencePattern.startDate
      : undefined,
    endingDate: recurringTask.recurrencePattern.endingDate
      ? recurringTask.recurrencePattern.endingDate
      : undefined,
  };
  assignedUser = MockGenerator.generate(BigQueryNamedDocumentMock);
  assignedTeam = MockGenerator.generate(BigQueryNamedDocumentMock);
  owner = MockGenerator.generate(BigQueryNamedDocumentMock);
  completedAt = toSerialisedTimestamp(recurringTask.completedAt);
  createdTasks = recurringTask.createdTasks.map(() => ({
    dateShown: toSerialisedTimestamp(MockTimestamp()),
    ref: MockGenerator.generate(SerialisedDocumentReferenceMock),
  }));
  recurrenceTiming = recurringTask.recurrenceTiming;
}
