import {
  type CrossfilterGroup,
  type CrossfilterGroupValue,
  type IChartConfig,
} from '@principle-theorem/reporting';
import { isObject } from '@principle-theorem/shared';
import { GenericDataTransformer } from './generic-data-transformer';

export class ScatterDataTransformer extends GenericDataTransformer {
  protected override _buildDataTable(
    config: IChartConfig,
    groups: CrossfilterGroup[],
    headers: string[]
  ): google.visualization.DataTable {
    if (
      config.builderData.plottedOverTime ||
      config.builderData.accumulateOverTime
    ) {
      return super._buildDataTable(config, groups, headers);
    }
    const keys: string[] = headers;
    keys.shift();
    const chartData = groups.map((group: CrossfilterGroup) => {
      return group
        .all()
        .map((groupData: CrossfilterGroupValue) =>
          isObject(groupData.value)
            ? groupData.value.valueOf()
            : groupData.value
        );
    });
    return google.visualization.arrayToDataTable([keys, ...chartData]);
  }
}
