<mat-chip-listbox fxFlex>
  <mat-chip-option
    (click)="edit()"
    [style.--mdc-chip-label-text-color]="tag | tagTextColour"
    [style.background-color]="tag.hexColour"
  >
    {{ tag.name }}
  </mat-chip-option>
</mat-chip-listbox>

<button mat-icon-button (click)="edit()">
  <mat-icon>edit</mat-icon>
</button>

<button mat-icon-button color="warn" (click)="delete(tag)">
  <mat-icon>delete</mat-icon>
</button>
