import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  OrganisationService,
  TimezoneService,
} from '@principle-theorem/ng-principle-shared';
import {
  AppointmentSuggestion,
  AppointmentSuggestionSearchType,
  Brand,
  DEFAULT_TIME_INCREMENT,
  TreatmentStep,
  allPractitionersAvailableTimes,
  compareScore,
  isTreatmentOption,
  practiceStaffAvailableTimes,
  stafferAvailableTimes,
  stafferToNamedDoc,
  type IAppointmentDetails,
} from '@principle-theorem/principle-core';
import {
  ParticipantType,
  isEventable,
  isTreatmentTemplateWithStep,
  type IAppointment,
  type IAppointmentSuggestion,
  type IEventTimePeriod,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  doc$,
  filterUndefined,
  isINamedDocument,
  isWithRef,
  multiMap,
  serialise$,
  sortTimestampNowUntilFuture,
  toEntityModel$,
  toEntityModels$,
  toTimePeriod,
  unserialise$,
  type INamedDocument,
  type RequireProps,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import * as moment from 'moment-timezone';
import { Duration } from 'moment-timezone';
import { combineLatest, of, type Observable } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { AppointmentSuggestionActions, SchedulingActions } from '../actions';
import { AppointmentSchedulingFacade } from '../facades/appointment-scheduling.facade';

@Injectable()
export class AppointmentSuggestionsEffects {
  private _actions$ = inject(Actions);
  private _schedulingFacade = inject(AppointmentSchedulingFacade);
  private _organisation = inject(OrganisationService);
  private _timezone = inject(TimezoneService);
  loadAvailableTimes$: Observable<Action> = createEffect(() =>
    this._loadAvailableTimes$()
  );
  loadGapCandidates$: Observable<Action> = createEffect(() =>
    this._loadGapCandidates$()
  );
  loadCalendarEvents$: Observable<Action> = createEffect(() =>
    this._loadCalendarEvents$()
  );
  buildSuggestions$: Observable<Action> = createEffect(() =>
    this._buildSuggestions$()
  );
  loadSuggestions$: Observable<Action> = createEffect(() =>
    this._loadSuggestions$()
  );
  setAppointment$: Observable<Action> = createEffect(() =>
    this._setAppointment$()
  );

  private _loadAvailableTimes$(): Observable<Action> {
    const detailsChange$ = this._schedulingFacade.appointmentDetails$.pipe(
      filter(
        (
          appointmentDetails
        ): appointmentDetails is RequireProps<
          IAppointmentDetails,
          'duration'
        > =>
          appointmentDetails.duration && appointmentDetails.duration > 0
            ? true
            : false
      )
    );

    const searchTypeChange$ = this._actions$.pipe(
      ofType(AppointmentSuggestionActions.setSearchType),
      unserialise$(),
      map((action) => action.searchType),
      startWith(AppointmentSuggestionSearchType.Normal)
    );

    const stepSizeSetting$ = this._organisation.practice$.pipe(
      filterUndefined(),
      map((practice) => practice.settings.timeline?.stepSizeInMins)
    );

    return this._actions$.pipe(
      ofType(AppointmentSuggestionActions.loadAppointmentAvailability),
      switchMap(() => searchTypeChange$),
      withLatestFrom(detailsChange$, stepSizeSetting$),
      switchMap(([searchType, appointmentDetails, stepSize]) => {
        const duration = moment.duration(
          appointmentDetails.duration,
          'minutes'
        );
        const practitioner =
          appointmentDetails.practitioner &&
          isINamedDocument<IStaffer>(appointmentDetails.practitioner)
            ? appointmentDetails.practitioner
            : undefined;
        const practice =
          appointmentDetails.practice && isWithRef(appointmentDetails.practice)
            ? appointmentDetails.practice
            : undefined;

        const implementors =
          appointmentDetails.treatment &&
          isTreatmentTemplateWithStep(appointmentDetails.treatment)
            ? appointmentDetails.treatment.step.template.implementedBy.map(
                (implementor) => implementor.staffer
              )
            : [];

        const allowOverlapping =
          searchType === AppointmentSuggestionSearchType.Overlapping;

        const timeIncrement = stepSize
          ? moment.duration(stepSize, 'minutes')
          : undefined;

        if (practitioner) {
          return this._getPractitionerAvailability$(
            practitioner,
            duration,
            allowOverlapping,
            practice,
            timeIncrement
          );
        }
        if (practice) {
          return this._getPracticeAvailability$(
            practice,
            duration,
            allowOverlapping,
            implementors,
            timeIncrement
          );
        }
        return this._getAllAvailability$(
          duration,
          allowOverlapping,
          timeIncrement
        );
      }),
      serialise$(),
      map((availableTimes) =>
        AppointmentSuggestionActions.loadAvailabilitySuccess({ availableTimes })
      )
    );
  }

  private _getPractitionerAvailability$(
    practitionerDoc: INamedDocument<IStaffer>,
    duration: moment.Duration,
    allowOverlapping: boolean = false,
    practice?: WithRef<IPractice>,
    timeIncrement?: Duration
  ): Observable<IEventTimePeriod[]> {
    return combineLatest([
      this._schedulingFacade.filterRange$,
      this._schedulingFacade.brand$,
    ]).pipe(
      switchMap(([filterRange, brand]) => {
        return doc$<IStaffer>(practitionerDoc.ref).pipe(
          switchMap((practitioner) =>
            of(filterRange).pipe(
              stafferAvailableTimes(
                practitioner,
                brand,
                practice,
                duration,
                allowOverlapping,
                timeIncrement
              )
            )
          )
        );
      })
    );
  }

  private _getPracticeAvailability$(
    practice: WithRef<IPractice>,
    duration: moment.Duration,
    allowOverlapping: boolean = false,
    implementors: INamedDocument<IStaffer>[] = [],
    timeIncrement = DEFAULT_TIME_INCREMENT
  ): Observable<IEventTimePeriod[]> {
    return combineLatest([
      this._schedulingFacade.filterRange$,
      this._schedulingFacade.brand$,
    ]).pipe(
      switchMap(([filterRange, brand]) =>
        of(filterRange).pipe(
          practiceStaffAvailableTimes(
            practice,
            brand,
            duration,
            allowOverlapping,
            implementors,
            undefined,
            timeIncrement
          )
        )
      )
    );
  }

  private _getAllAvailability$(
    duration: moment.Duration,
    allowOverlapping: boolean = false,
    timeIncrement?: Duration
  ): Observable<IEventTimePeriod[]> {
    return combineLatest([
      this._schedulingFacade.filterRange$,
      this._schedulingFacade.brand$,
    ]).pipe(
      switchMap(([filterRange, brand]) =>
        of(filterRange).pipe(
          allPractitionersAvailableTimes(
            brand,
            duration,
            allowOverlapping,
            timeIncrement
          )
        )
      )
    );
  }

  private _loadGapCandidates$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AppointmentSuggestionActions.loadAppointmentAvailability),
      withLatestFrom(
        this._schedulingFacade.appointmentDetails$,
        this._schedulingFacade.brand$,
        this._organisation.practices$.pipe(
          multiMap((practice) => practice.ref)
        ),
        this._schedulingFacade.filterRange$
      ),
      switchMap(([_, appointmentDetails, brand, practices, range]) => {
        const practitioner =
          appointmentDetails.practitioner &&
          isINamedDocument<IStaffer>(appointmentDetails.practitioner)
            ? appointmentDetails.practitioner
            : undefined;
        const practice =
          appointmentDetails.practice && isWithRef(appointmentDetails.practice)
            ? appointmentDetails.practice
            : undefined;

        let participant;
        if (practitioner) {
          participant = {
            ...stafferToNamedDoc(practitioner),
            type: ParticipantType.Staffer,
          };
        }
        return Brand.getGapCandidates$(
          brand,
          toTimePeriod(...range),
          practice ? [practice.ref] : practices,
          participant ? [participant] : []
        );
      }),
      serialise$(),
      map((gapCandidates) =>
        AppointmentSuggestionActions.gapCandidatesLoaded({ gapCandidates })
      )
    );
  }

  private _loadCalendarEvents$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AppointmentSuggestionActions.loadAppointmentAvailability),
      withLatestFrom(
        this._schedulingFacade.brand$,
        this._schedulingFacade.filterRange$,
        this._timezone.currentPractice.timezone$
      ),
      switchMap(([_, brand, range, timezone]) => {
        const timePeriod = toTimePeriod(...range, timezone);
        return Brand.findCalendarEventsIntersectingRange$(brand, {
          from: timePeriod.from.subtract(1, 'months'),
          to: timePeriod.to.add(3, 'months'),
        });
      }),
      serialise$(),
      map((calendarEvents) =>
        AppointmentSuggestionActions.loadCalendarEventsSuccess({
          calendarEvents,
        })
      )
    );
  }

  private _buildSuggestions$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(
        AppointmentSuggestionActions.loadCalendarEventsSuccess,
        AppointmentSuggestionActions.loadAvailabilitySuccess,
        AppointmentSuggestionActions.gapCandidatesLoaded
      ),
      debounceTime(500),
      map(() => AppointmentSuggestionActions.loadAppointmentSuggestions())
    );
  }

  private _loadSuggestions$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(AppointmentSuggestionActions.loadAppointmentSuggestions),
      withLatestFrom(
        this._schedulingFacade.availableTimes$,
        this._schedulingFacade.gapCandidates$,
        this._schedulingFacade.preferredEvent$,
        this._organisation.staffer$.pipe(filterUndefined()),
        this._schedulingFacade.calendarEvents$,
        this._schedulingFacade.appointmentDetails$.pipe(
          filter((details) => isTreatmentOption(details.treatment)),
          map((details) =>
            details.treatment
              ? TreatmentStep.defaultDisplayRef(details.treatment.step.display)
              : undefined
          )
        )
      ),
      map(
        ([
          _,
          availableTimes,
          gapCandidates,
          preferredEvent,
          staffer,
          calendarEvents,
          treatmentCategoryRef,
        ]) =>
          AppointmentSuggestion.convertOptionsToSuggestions(
            staffer,
            availableTimes,
            gapCandidates,
            preferredEvent,
            calendarEvents,
            undefined,
            compact([treatmentCategoryRef])
          )
            .sort(compareScore)
            .sort((a, b) =>
              sortTimestampNowUntilFuture(a.event.from, b.event.from)
            )
      ),
      toEntityModels$(),
      serialise$(),
      map((appointmentSuggestions) =>
        AppointmentSuggestionActions.loadAppointmentSuggestionsSuccess({
          appointmentSuggestions,
        })
      )
    );
  }

  private _setAppointment$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(SchedulingActions.setExistingAppointment),
      unserialise$(),
      filter((action) => action.updateSelectedSuggestion),
      // TODO: This withLatestFrom never dispatches its action. Could be causing a bug.
      withLatestFrom(
        this._schedulingFacade.selectedSuggestion$.pipe(
          map((suggestion) => {
            if (!suggestion) {
              return;
            }
            return AppointmentSuggestionActions.removeSuggestion({
              id: suggestion.uid,
            });
          })
        )
      ),
      map(([action]) =>
        action.appointment
          ? this._getExistingAppointmentSuggestion(action.appointment)
          : undefined
      ),
      filterUndefined(),
      toEntityModel$(),
      serialise$(),
      map((appointmentSuggestion) =>
        AppointmentSuggestionActions.addAppointmentSuggestion({
          appointmentSuggestion,
        })
      )
    );
  }

  private _getExistingAppointmentSuggestion(
    appointment: WithRef<IAppointment>
  ): IAppointmentSuggestion | undefined {
    if (!isEventable<IAppointment>(appointment)) {
      return;
    }
    return AppointmentSuggestion.init({
      event: appointment.event,
      practitioner: appointment.practitioner,
      score: 1,
    });
  }
}
