import { NgModule } from '@angular/core';
import { NgPrincipleAccountingModule } from '@principle-theorem/ng-principle-accounting';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ClaimEstimateSummaryDialogComponent } from './claim-estimate-summary-dialog/claim-estimate-summary-dialog.component';
import { ClaimEstimateSummaryDialogService } from './claim-estimate-summary-dialog/claim-estimate-summary-dialog.service';
import { HealthFundMemberNumberDialogComponent } from './health-fund-member-number-dialog/health-fund-member-number-dialog.component';
import { HealthFundMemberNumberDialogService } from './health-fund-member-number-dialog/health-fund-member-number-dialog.service';
import { BulkBillDialogComponent } from './medicare-components/bulk-bill-dialog/bulk-bill-dialog.component';
import { ClaimReferralFormComponent } from './medicare-components/claim-referral-form/claim-referral-form.component';
import { MedicareDialogService } from './medicare-components/medicare-dialog-service';
import { PatientClaimDialogComponent } from './medicare-components/patient-claim-dialog/patient-claim-dialog.component';
import { SelectClaimItemsComponent } from './medicare-components/select-claim-items/select-claim-items.component';
import { RefundCreditTransactionDialogComponent } from './refund-credit-transaction-dialog/refund-credit-transaction-dialog.component';
import { RefundDepositPromptComponent } from './refund-deposit-prompt/refund-deposit-prompt.component';
import { RefundDepositPromptService } from './refund-deposit-prompt/refund-deposit-prompt.service';
import { TransactionAmountDialogComponent } from './transaction-amount-dialog/transaction-amount-dialog.component';
import { TransactionAmountDialog } from './transaction-amount-dialog/transaction-amount-dialog.service';
import { TransactionEditDialogComponent } from './transaction-edit-dialog/transaction-edit-dialog.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { NgPatientModule } from 'libs/ng-patient/src/lib/ng-patient.module';
import { AttributedToSelectorComponent } from './attributed-to-selector/attributed-to-selector.component';
import { TransactionAttributeToDialogComponent } from './transaction-attribute-to-dialog/transaction-attribute-to-dialog.component';

@NgModule({
  imports: [
    NgSharedModule,
    NgPrincipleSharedModule,
    NgPrincipleAccountingModule,
    NgPatientModule,
    AttributedToSelectorComponent,
  ],
  declarations: [
    TransactionAmountDialogComponent,
    RefundDepositPromptComponent,
    TransactionEditDialogComponent,
    RefundCreditTransactionDialogComponent,
    HealthFundMemberNumberDialogComponent,
    BulkBillDialogComponent,
    ClaimReferralFormComponent,
    PatientClaimDialogComponent,
    SelectClaimItemsComponent,
    ClaimEstimateSummaryDialogComponent,
    TransactionAttributeToDialogComponent,
  ],
  providers: [
    TransactionAmountDialog,
    RefundDepositPromptService,
    HealthFundMemberNumberDialogService,
    ClaimEstimateSummaryDialogService,
    MedicareDialogService,
  ],
  exports: [AttributedToSelectorComponent],
})
export class TransactionComponentsModule {}
