<form [formGroup]="patientFormGroup">
  <div fxLayout="row" fxLayoutGap="16px">
    <pr-patient-selector
      fxFlex
      [formControl]="searchCtrl"
      (clear)="clearUser()"
      [suggestedPatientData]="suggestedPatientData$ | async"
      [selectedPatient]="selectedPatient$ | async"
    />
  </div>

  <div fxLayout="row" fxLayoutGap="16px">
    <pt-iso-date-selector
      fxFlex
      placeholder="Date of birth"
      formControlName="dateOfBirth"
      [required]="'dateOfBirth' | map: isRequired$ : this | async"
      [max]="maxDate"
    />

    <mat-form-field fxFlex>
      <mat-label>Email</mat-label>
      <input
        matInput
        type="email"
        formControlName="email"
        autocomplete="off"
        [required]="'email' | map: isRequired$ : this | async"
      />
    </mat-form-field>
  </div>

  <div formArrayName="contactNumbers" fxLayout="column">
    <pr-contact-number-input
      *ngFor="
        let control of contactNumbers.controls;
        trackBy: trackByIndex;
        index as index;
        first as isFirst
      "
      [form]="control"
      [isFirst]="isFirst"
      [required]="'contactNumbers' | map: isRequired$ : this | async"
    >
      <div class="flex flex-row">
        <button
          mat-icon-button
          (click)="contactNumberControls.addControl(index)"
        >
          <mat-icon>add</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="contactNumberControls.removeControl(index)"
        >
          <mat-icon>remove</mat-icon>
        </button>
      </div>
    </pr-contact-number-input>
  </div>

  <div fxLayout="row" fxLayoutGap="16px">
    <mat-form-field fxFlex>
      <mat-label>Gender</mat-label>
      <mat-select formControlName="gender" required>
        <mat-option
          *ngFor="let gender of genders; trackBy: trackByGender"
          [value]="gender"
        >
          {{ gender | splitCamel | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <pr-referrer-selector
      fxFlex
      formControlName="referrer"
      [required]="'referrer' | map: isRequired$ : this | async"
    />
  </div>

  <pr-patient-relationship-selector
    [form]="patientFormGroup.controls.primaryContact"
    [required]="(patientFormGroup.contactDetailsRequired$ | async) === false"
    [patient]="patient$ | async"
    label="Primary Contact"
    placeholder="Primary Contact"
  />

  <pr-patient-fee-schedule-selector formControlName="preferredFeeSchedule" />
</form>
