import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  Brand,
  CustomFormConfiguration,
  CustomFormContent,
  toMedicalHistoryForm,
} from '@principle-theorem/principle-core';
import {
  IBrand,
  IJsonSchemaFormWithResolverConfig,
  type IPatientFormSchema,
} from '@principle-theorem/principle-core/interfaces';
import { IReffable, Timestamp, reduce2DArray } from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { resolveCustomFormData } from './form-data-resolver';

export class MedicalFormAlerts {
  trackByCondition = TrackByFunctions.variable<string>();
  trackByAllergies = TrackByFunctions.variable<string>();
  trackByHistory = TrackByFunctions.variable<string>();
  latestMedicalForm$: Observable<IPatientFormSchema | undefined>;
  conditions$: Observable<string[]>;
  allergies$: Observable<string[]>;
  history$: Observable<string[]>;
  all$: Observable<string[]>;
  alertCount$: Observable<number>;
  hasAlerts$: Observable<boolean>;
  hasMedicalHistory$: Observable<boolean>;
  lastUpdated$: Observable<Timestamp | undefined>;
  isLoading$: Observable<boolean>;

  constructor(
    brand$: Observable<IReffable<IBrand>>,
    patientFormSchema$: Observable<IPatientFormSchema | undefined>
  ) {
    this.latestMedicalForm$ = combineLatest([brand$, patientFormSchema$]).pipe(
      switchMap(([brand, patientFormSchema]) =>
        this._getPatientFormSchema$(brand, patientFormSchema)
      )
    );
    this.isLoading$ = this.latestMedicalForm$.pipe(
      map(() => false),
      startWith(true)
    );
    this.hasMedicalHistory$ = this.latestMedicalForm$.pipe(
      map((form) => !!form)
    );
    this.conditions$ = this.latestMedicalForm$.pipe(
      map((form) =>
        form
          ? resolveCustomFormData(
              form,
              'conditions',
              toMedicalHistoryForm(form)?.dataResolverConfig
            )
          : []
      )
    );
    this.allergies$ = this.latestMedicalForm$.pipe(
      map((form) =>
        form
          ? resolveCustomFormData(
              form,
              'allergies',
              toMedicalHistoryForm(form)?.dataResolverConfig
            )
          : []
      )
    );
    this.history$ = this.latestMedicalForm$.pipe(
      map((form) =>
        form
          ? resolveCustomFormData(
              form,
              'history',
              toMedicalHistoryForm(form)?.dataResolverConfig
            )
          : []
      )
    );
    this.all$ = combineLatest([
      this.conditions$,
      this.allergies$,
      this.history$,
    ]).pipe(reduce2DArray());
    this.alertCount$ = this.all$.pipe(map((alerts) => alerts.length));
    this.hasAlerts$ = this.alertCount$.pipe(
      map((alertCount) => alertCount > 0)
    );

    this.lastUpdated$ = this.latestMedicalForm$.pipe(
      map((latestMedicalForm) => latestMedicalForm?.date)
    );
  }

  private _getPatientFormSchema$(
    brand: IReffable<IBrand>,
    patientFormSchema?: IPatientFormSchema<object> | undefined
  ): Observable<IPatientFormSchema | undefined> {
    return Brand.medicalHistoryFormConfig$(brand).pipe(
      switchMap((formConfig) =>
        formConfig
          ? CustomFormConfiguration.getContent$(formConfig)
          : of(undefined)
      ),
      map((content) =>
        content ? CustomFormContent.getJsonSchemaForm(content) : undefined
      ),
      map((schemaForm) =>
        mergeSubmittedFormWithConfig(patientFormSchema, schemaForm)
      )
    );
  }
}

export function mergeSubmittedFormWithConfig(
  patientForm?: IPatientFormSchema,
  config?: IJsonSchemaFormWithResolverConfig
): IPatientFormSchema | undefined {
  if (!patientForm) {
    return;
  }

  const currentForm = toMedicalHistoryForm(config);

  if (isEqual(patientForm.schema, currentForm?.schema)) {
    return {
      ...patientForm,
      ...currentForm,
      layout: currentForm?.layout ?? patientForm.layout,
      dataResolverConfig:
        currentForm?.dataResolverConfig ?? patientForm.dataResolverConfig,
    };
  }

  return patientForm;
}
