import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ManagementService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  BasicDialogService,
  DialogPresets,
  TrackByFunctions,
  type IBreadcrumb,
} from '@principle-theorem/ng-shared';
import { CustomReport } from '@principle-theorem/principle-core';
import { ReportingPermissions } from '@principle-theorem/principle-core/features';
import {
  IQueryScopeRequests,
  type ICustomReport,
  type RestrictAccessEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ITimePeriod,
  deleteDoc,
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { toCustomReportQuery } from '../../report-builder/report-builder-custom-report';
import { ReportBuilderStore } from '../../report-builder/report-builder.store';
import { CurrentCustomReportScope } from '../current-custom-report-scope';
import {
  EditCustomReportDialogComponent,
  type IEditCustomReportDialogData,
} from './edit-custom-report-dialog/edit-custom-report-dialog.component';
import { IntercomService } from '@principle-theorem/ng-intercom';

@Component({
    selector: 'pr-custom-report',
    templateUrl: './custom-report.component.html',
    styleUrls: ['./custom-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CustomReportComponent {
  customReport$: Observable<WithRef<ICustomReport>>;
  isRetired$: Observable<boolean>;
  editCustomReportPermissions = [
    ReportingPermissions.ReportingCustomReportEdit,
  ];
  breadcrumbs: IBreadcrumb[] = [
    { label: 'Reporting' },
    { label: 'Custom Reports', path: '../' },
  ];
  dateRange = signal<ITimePeriod | undefined>(undefined);
  trackByAccessEntity = TrackByFunctions.ref<RestrictAccessEntity>();

  constructor(
    public store: ReportBuilderStore,
    private _currentCustomReportScope: CurrentCustomReportScope,
    private _basicDialog: BasicDialogService,
    private _dialog: MatDialog,
    private _router: Router,
    private _route: ActivatedRoute,
    private _organisation: OrganisationService,
    private _management: ManagementService,
    public intercom: IntercomService
  ) {
    this.store.reset();
    this.customReport$ = this._currentCustomReportScope
      .asObservable$()
      .pipe(filterUndefined());
    this.isRetired$ = this.customReport$.pipe(
      map((report) => CustomReport.isRetired(report))
    );
  }

  async runReport(event: IQueryScopeRequests, editMode = false): Promise<void> {
    const customReport = await snapshot(this.customReport$);
    const isAllowed = await this._canRunReport(customReport);
    if (!isAllowed) {
      return;
    }
    const query = toCustomReportQuery(customReport, event, editMode);
    if (!query) {
      return;
    }
    this.store.loadQuery(query);
  }

  async edit(): Promise<void> {
    const customReport = await snapshot(this.customReport$);
    const queryScope = await this._dialog
      .open<
        EditCustomReportDialogComponent,
        IEditCustomReportDialogData,
        IQueryScopeRequests
      >(
        EditCustomReportDialogComponent,
        DialogPresets.large({
          data: {
            dateRange: this.dateRange(),
            customReport,
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!queryScope) {
      return;
    }

    await this.runReport(queryScope, true);
  }

  async delete(): Promise<void> {
    const customReport = await snapshot(this.customReport$);
    const isConfirmed = await this._basicDialog.confirm({
      title: 'Delete Custom Report',
      prompt: `Are you sure you want to delete the custom report "${customReport.name}"?`,
      submitLabel: 'Yes, Delete',
      submitColor: 'warn',
    });
    if (!isConfirmed) {
      return;
    }
    await deleteDoc(customReport.ref);
    await this._router.navigate(['../'], {
      relativeTo: this._route,
    });
  }

  private async _canRunReport(
    customReport: WithRef<ICustomReport>
  ): Promise<boolean> {
    const managementUser = await snapshot(this._management.user$);
    if (managementUser) {
      return true;
    }
    const staffer = await snapshot(this._organisation.staffer$);
    return CustomReport.userHasAccess(customReport, staffer);
  }
}
