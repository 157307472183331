import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AssigneeSelectorComponent } from './assignee-selector/assignee-selector.component';
import { OwnerSelectorComponent } from './owner-selector/owner-selector.component';
import { TaskItemComponent } from './task-item/task-item.component';
import { TaskListDisplayComponent } from './task-list-display/task-list-display.component';

@NgModule({
  imports: [
    CommonModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    NgInteractionsModule,
    NgMaterialModule,
    NgxSkeletonLoaderModule,
  ],
  declarations: [
    TaskItemComponent,
    AssigneeSelectorComponent,
    TaskListDisplayComponent,
    OwnerSelectorComponent,
  ],
  exports: [
    TaskItemComponent,
    AssigneeSelectorComponent,
    TaskListDisplayComponent,
    OwnerSelectorComponent,
  ],
})
export class TaskListModule {}
