import { createAction, props } from '@ngrx/store';
import {
  type IPractitionerGrouping,
  type IInvoiceReportRequest,
} from '@principle-theorem/reporting';
import { type SerialisedData } from '@principle-theorem/shared';
import { type PractitionerIncomeReportType } from './practitioner-income.reducers';

export enum PractitionerIncomeReportActions {
  SetQuery = '[Practitioner Income Report] Set Query',
  SetReportType = '[Practitioner Income Report] Set Report Type',
  LoadReport = '[PractitionerIncome Report] Load',
  LoadReportSuccess = '[Practitioner Income Report] Load Success',
  LoadReportFailure = '[Practitioner Income Report] Load Failure',
  InspectResult = '[Practitioner Income Report] Inspect Result',
  ClearSelectedResult = '[Practitioner Income Report] Clear Selected Result',
}

export const setQuery = createAction(
  PractitionerIncomeReportActions.SetQuery,
  props<SerialisedData<IInvoiceReportRequest>>()
);

export const setReportType = createAction(
  PractitionerIncomeReportActions.SetReportType,
  props<{ reportType: PractitionerIncomeReportType }>()
);

export const loadReport = createAction(
  PractitionerIncomeReportActions.LoadReport
);

export const loadReportSuccess = createAction(
  PractitionerIncomeReportActions.LoadReportSuccess,
  props<SerialisedData<{ results: IPractitionerGrouping[] }>>()
);

export const loadReportFailure = createAction(
  PractitionerIncomeReportActions.LoadReportFailure,
  props<{ errorMessage: string }>()
);

export const inspectResult = createAction(
  PractitionerIncomeReportActions.InspectResult,
  props<SerialisedData<IPractitionerGrouping>>()
);

export const clearSelectedResult = createAction(
  PractitionerIncomeReportActions.ClearSelectedResult
);
