import { type IWebhook, type WebhookEvent } from '@medipass/partner-sdk';
import {
  type InvoiceSessionKeyFunder,
  MedipassWebhookEvent,
} from '@principle-theorem/medipass';
import {
  type IHealthcareClaim,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import { type ObjectOfType, type WithRef } from '@principle-theorem/shared';

export interface IMedipassWebhookHeaders
  extends ObjectOfType<string | undefined> {
  funder: string;
  'staffer-ref': string;
  'practice-ref'?: string;
  'claim-uid'?: string;
}

const VIRTUAL_TERMINAL_EVENTS: WebhookEvent[] = [
  MedipassWebhookEvent.InvoiceCreated,
  MedipassWebhookEvent.InvoiceCompleted,
  MedipassWebhookEvent.InvoiceCancelled,
];

const FUNDER_EVENTS: WebhookEvent[] = [
  MedipassWebhookEvent.InvoiceBalancePaid,
  MedipassWebhookEvent.MemberApprovedInvoice,
  MedipassWebhookEvent.MemberRejectedInvoice,
  MedipassWebhookEvent.MedipassAutoCancelledInvoice,
  MedipassWebhookEvent.HealthFundApprovedInvoice,
  MedipassWebhookEvent.HealthFundRejectedInvoice,
  MedipassWebhookEvent.HealthFundPaidInvoice,
];

export function getWebhooks(
  url: string,
  funder: InvoiceSessionKeyFunder = 'hicaps',
  staffer: WithRef<IStaffer>,
  practiceRef: DocumentReference<IPractice>,
  claim?: IHealthcareClaim
): IWebhook[] {
  const headers: IMedipassWebhookHeaders = {
    funder,
    'practice-ref': practiceRef.path,
    'staffer-ref': staffer.ref.path,
    'claim-uid': claim?.uid,
  };
  const events: WebhookEvent[] = [...VIRTUAL_TERMINAL_EVENTS, ...FUNDER_EVENTS];
  return events.map((event) => getHook(event, url, headers));
}

function getHook(
  event: WebhookEvent,
  url: string,
  headers: IMedipassWebhookHeaders
): IWebhook<IMedipassWebhookHeaders> {
  return {
    url,
    event,
    method: 'POST',
    headers,
  };
}
