import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  type AfterViewInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import {
  FirebaseTable,
  type ICreateConfigurationData,
} from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  extendSortingDataAccessor,
} from '@principle-theorem/ng-shared';
import { type IConditionConfiguration } from '@principle-theorem/principle-core/interfaces';
import {
  all$,
  multiSortBy$,
  nameSorter,
  snapshot,
  undeletedQuery,
  type CollectionReference,
  type DocumentReference,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  type Observable,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CreateConditionConfigurationComponent } from '../create-condition-configuration/create-condition-configuration.component';

@Component({
  selector: 'pr-condition-configuration-list',
  templateUrl: './condition-configuration-list.component.html',
  styleUrls: ['./condition-configuration-list.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionConfigurationListComponent
  extends FirebaseTable<IConditionConfiguration>
  implements AfterViewInit
{
  private _collection$ = new ReplaySubject<
    CollectionReference<IConditionConfiguration>
  >(1);
  @Input() basePath?: string;
  override displayColumns: string[] = ['icon', 'name'];
  override pageSize = 50;
  override pageSizeOptions = [50, 100, 200];
  emptyState$: Observable<boolean>;
  emptySearch$ = new BehaviorSubject<boolean>(false);

  @ViewChild(MatPaginator)
  set tablePaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor(
    private _dialog: MatDialog,
    private _router: Router
  ) {
    super();

    this.emptyState$ = combineLatest([
      this._collection$.pipe(switchMap((collection) => all$(collection))),
      this.dataSource.loading$,
    ]).pipe(
      map(
        ([conditionConfigurations, loading]) =>
          !conditionConfigurations.length && !loading
      )
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, _sortHeaderId) => data.name.toLowerCase()
    );
  }

  @Input()
  set collection(collection: CollectionReference<IConditionConfiguration>) {
    if (collection) {
      this._collection$.next(collection);
      this.dataSource.items$ = all$(undeletedQuery(collection)).pipe(
        multiSortBy$(nameSorter())
      );
    }
  }

  async addCondition(): Promise<void> {
    const collection = await snapshot(this._collection$);
    if (!collection) {
      return;
    }

    const data: ICreateConfigurationData<IConditionConfiguration> = {
      collection,
    };

    const docRef = await this._dialog
      .open<
        CreateConditionConfigurationComponent,
        ICreateConfigurationData<IConditionConfiguration>,
        DocumentReference<IConditionConfiguration>
      >(CreateConditionConfigurationComponent, DialogPresets.small({ data }))
      .afterClosed()
      .toPromise();

    if (!docRef) {
      return;
    }

    await this._router.navigate(this.getRoute(docRef.id));
  }

  async searchConditions(value: string): Promise<void> {
    this.applyFilter(value);
    const emptyState = await snapshot(this.emptyState$);
    !emptyState && !this.dataSource.filteredData.length
      ? this.emptySearch$.next(true)
      : this.emptySearch$.next(false);
  }

  getRoute(uid: string): string[] {
    return [this.basePath || this._router.url, uid];
  }
}
