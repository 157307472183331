<mat-toolbar color="accent">Edit Today's Treatment</mat-toolbar>

<mat-dialog-content>
  <div class="flex flex-col">
    <div class="flex items-center justify-between gap-4">
      <pr-practitioner-selector (stafferSelected)="setChartingAs($event)" />

      <pr-staff-fee-schedule-selector
        [patient]="patient$ | async"
        (scheduleSelected)="setFeeSchedule($event)"
      />
    </div>

    <pr-todays-appointment
      [step]="step$ | async"
      [chart]="chart$ | async"
      [plan]="plan$ | async"
      [patient]="patient$ | async"
      [appointment]="appointment$ | async"
    />
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button type="button" mat-stroked-button mat-dialog-close>Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
