import { randNumber } from '@ngneat/falso';
import {
  ChartView,
  Gender,
  IConditionConfiguration,
  IMultiTreatmentConfiguration,
  IPractice,
  IProviderData,
  IQuickChartingConfigurations,
  IRecentlyViewed,
  IStaffer,
  IStafferOnlineProfile,
  IStafferSettings,
  IStafferTasks,
  ITeam,
  ITreatmentConfiguration,
  IUser,
  PatientMediaGroupBy,
  PatientMediaSize,
  PatientMediaViewType,
  ServiceTypeModality,
  TimelineDisplayOrientation,
  TimelineDisplayZoom,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  INamedDocument,
  Properties,
} from '@principle-theorem/shared';
import { MockDocRef, MockNamedDocument } from '@principle-theorem/testing';

export class PractitionerMock
  extends BaseFirestoreMock
  implements Properties<IStaffer>
{
  get tasks(): IStafferTasks {
    return {
      watching: randNumber({ min: 0, max: 10 }),
      assigned: randNumber({ min: 0, max: 10 }),
    };
  }

  get user(): INamedDocument<IUser> {
    return MockNamedDocument<IUser>();
  }

  get teams(): INamedDocument<ITeam>[] {
    const numTeams: number = randNumber({ min: 0, max: 3 });
    return [...Array<INamedDocument<ITeam>>(numTeams)].map(() =>
      MockNamedDocument<ITeam>()
    );
  }

  get quickChartingConfiguration(): IQuickChartingConfigurations {
    return {
      conditions: [{ ref: MockDocRef<IConditionConfiguration>() }],
      treatments: [{ ref: MockDocRef<ITreatmentConfiguration>() }],
      multiTreatments: [{ ref: MockDocRef<IMultiTreatmentConfiguration>() }],
    };
  }

  get providerDetails(): IProviderData[] {
    return [
      {
        practiceRef: MockDocRef<IPractice>(),
        providerNumber: '1234',
        providerModality: ServiceTypeModality.GeneralDentist,
      },
    ];
  }

  get prescriberNumber(): string {
    return '1234';
  }

  get qualifications(): string {
    return 'BMedSci MBBS DCH FRACGP';
  }

  get providerCommission(): number {
    return 0.1;
  }

  get settings(): IStafferSettings {
    return {
      charting: {
        view: ChartView.Adult,
      },
      display: {
        sidebarClosed: false,
      },
      interactions: {
        filters: [],
        tagFilters: [],
      },
      timeline: {
        filteredStaffByPractice: [],
        orientation: TimelineDisplayOrientation.Horizontal,
        zoom: TimelineDisplayZoom.AutoFit,
        thickTracks: false,
        showGridlines: false,
      },
      conversations: {
        showAllPractices: false,
      },
      chats: {
        showAllPractices: false,
      },
      treatmentPlan: {
        filters: [],
      },
      patientMedia: {
        viewType: PatientMediaViewType.Grid,
        size: PatientMediaSize.Medium,
        groupBy: PatientMediaGroupBy.None,
      },
    };
  }

  get recentlyViewed(): IRecentlyViewed[] {
    return [];
  }

  get onlineProfile(): IStafferOnlineProfile {
    return {
      name: 'Dr. John Doe',
      profileImageURL: 'https://example.com/profile.jpg',
      gender: Gender.NotSpecified,
      title: 'Dentist',
      bio: 'I am a dentist',
    };
  }
}

export const RECEPTIONISTS: string[] = [
  'Peggy Olson',
  'Pam Beesly',
  'Janine Melnitz',
  'Pepper Potts',
  'Kitty Sanchez',
  'Waylon Smithers',
  'Moneypenny',
];

export const PRACTITIONERS: string[] = [
  'Dr Pierre Fauchard',
  'Dr Edward Angle',
  'Dr Frank Abbott',
  'Dr William Rollins',
  'Dr Lucy Taylor',
];

export const STAFF: string[] = [...RECEPTIONISTS, ...PRACTITIONERS];
