import {
  ChartableSurface,
  IAreaSummary,
  IAreaSummaryValue,
  IChartedRef,
  IChartedSurface,
  IChartedTooth,
  IToothRef,
} from '@principle-theorem/principle-core/interfaces';
import { compact } from 'lodash';
import { ToothSummary } from './area-summary';
import { ChartedSurfacesCollection } from './charted-surfaces-collection';

export class AreaSummaryFactory {
  create(surfaces: IChartedSurface[]): IAreaSummary[] {
    const collection: ChartedSurfacesCollection = new ChartedSurfacesCollection(
      surfaces
    );
    return [
      ...this.loadToothSummaries(collection),
      ...this.loadAreaSummaries(collection),
    ];
  }

  loadToothSummaries(collection: ChartedSurfacesCollection): IAreaSummary[] {
    const summaries: IAreaSummary[] = collection
      .toUniqueTeeth()
      .map((tooth: IChartedTooth) => {
        const filtered: ChartedSurfacesCollection =
          collection.filterByTooth(tooth);
        return new ToothSummary({ tooth }, filtered.toArray());
      });
    return summaries;
  }

  loadAreaSummaries(collection: ChartedSurfacesCollection): IAreaSummary[] {
    const summaries: (IAreaSummary | undefined)[] = [
      ChartableSurface.Quadrant,
      ChartableSurface.Arch,
      ChartableSurface.WholeMouth,
    ].map((label: ChartableSurface) => {
      const values: IAreaSummaryValue[] = collection
        .filterBySurface(label)
        .toArray()
        .map((surface: IChartedSurface) => {
          if (
            label === ChartableSurface.MultipleTeeth &&
            surface.chartedRef.multipleTeeth
          ) {
            return {
              surface,
              label: surface.chartedRef.multipleTeeth
                .map((tooth: IToothRef) => {
                  return `${tooth.quadrant}${tooth.quadrantIndex}`;
                })
                .join(', '),
            };
          }
          return {
            surface,
            label: String(surface.chartedRef[label as keyof IChartedRef]),
          };
        });

      if (!values.length) {
        return;
      }

      return {
        label,
        values,
      };
    });
    return compact(summaries);
  }
}
