import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthFirebaseFunctionsService } from '@principle-theorem/ng-auth';
import {
  DialogPresets,
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  ConfigDocuments,
  IDemoSpace,
  RootCollection,
  type IOrganisation,
} from '@principle-theorem/principle-core/interfaces';
import { Organisation } from '@principle-theorem/principle-core';
import {
  addDoc,
  asColRef,
  getDoc$,
  httpsCallable,
  slugify,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { startWith } from 'rxjs/operators';
import {
  DemoOrganisationFormData,
  OrganisationAddDialogComponent,
  type OrganisationFormData,
} from '../organisation-add-dialog/organisation-add-dialog.component';
import {
  INgPrincipleConfig,
  NG_PRINCIPLE_CONFIG,
} from '../../../ng-principle-config';

@Component({
    selector: 'pr-organisations',
    templateUrl: './organisations.component.html',
    styleUrls: ['./organisations.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OrganisationsComponent {
  trackByOrganisation = TrackByFunctions.ref<WithRef<IOrganisation>>();
  search: TypedFormControl<string> = new TypedFormControl<string>('');
  searchFilter: InputSearchFilter<WithRef<IOrganisation>>;
  isProduction: boolean;

  constructor(
    private _authFunctions: AuthFirebaseFunctionsService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    @Inject(NG_PRINCIPLE_CONFIG) private _config: INgPrincipleConfig
  ) {
    this.searchFilter = new InputSearchFilter<WithRef<IOrganisation>>(
      Organisation.all$(),
      this.search.valueChanges.pipe(startWith('')),
      ['name']
    );

    this.isProduction = this._config.production;
  }

  async addOrganisation(): Promise<void> {
    const organisationData = await this._dialog
      .open<OrganisationAddDialogComponent, undefined, OrganisationFormData>(
        OrganisationAddDialogComponent,
        DialogPresets.small()
      )
      .afterClosed()
      .toPromise();

    if (
      !organisationData ||
      !organisationData.name ||
      !organisationData.region
    ) {
      return;
    }

    const organisation = Organisation.init(organisationData);

    const alreadyExists = await this._authFunctions.checkOrganisationExists(
      organisation.slug
    );

    if (alreadyExists) {
      this._snackBar.open('Organisation with this name already exists');
      return;
    }

    await addDoc(Organisation.col(), organisation);
    this._snackBar.open('Organisation Added');
  }

  async addDemoOrganisation(): Promise<void> {
    const data = await snapshot(
      getDoc$(
        asColRef<IDemoSpace>(RootCollection.Configuration),
        ConfigDocuments.DemoSpace
      )
    );
    const organisationData = await this._dialog
      .open<
        OrganisationAddDialogComponent,
        WithRef<IDemoSpace>,
        DemoOrganisationFormData
      >(OrganisationAddDialogComponent, DialogPresets.small({ data }))
      .afterClosed()
      .toPromise();

    if (
      !organisationData ||
      !organisationData.name ||
      !organisationData.region ||
      !organisationData.seedFileName
    ) {
      return;
    }

    const alreadyExists: boolean =
      await this._authFunctions.checkOrganisationExists(
        slugify(organisationData.name.toLowerCase())
      );

    if (alreadyExists) {
      this._snackBar.open('Organisation with this name already exists');
      return;
    }

    await snapshot(
      httpsCallable('http-demoWorkspace-create')(organisationData)
    );
  }
}
