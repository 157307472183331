import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  type OnDestroy,
} from '@angular/core';
import { MixedSchema, getSchemaSize } from '@principle-theorem/editor';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  Brand,
  Event,
  TreatmentStep,
  getSchedulingAlerts$,
  isRequiredAppointmentDetails,
  isTreatmentOption,
  type IAppointmentDetails,
  type IWaitListDetails,
} from '@principle-theorem/principle-core';
import { TagType } from '@principle-theorem/ng-principle-shared';
import {
  type IEvent,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_TIME_FORMAT,
  HISTORY_DATE_FORMAT,
  filterUndefined,
  guardFilter,
  type CollectionReference,
  type INamedDocument,
} from '@principle-theorem/shared';
import { Subject, combineLatest, iif, type Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-confirm-appointment',
    templateUrl: './confirm-appointment.component.html',
    styleUrls: ['./confirm-appointment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConfirmAppointmentComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByIndex = TrackByFunctions.index();
  trackByAlert = TrackByFunctions.variable<string>();
  treatmentStepSummary$: Observable<string>;
  appointmentTags = new TypedFormControl<INamedDocument<ITag>[]>();
  tagCol$: Observable<CollectionReference<ITag>>;
  readonly dateFormat = HISTORY_DATE_FORMAT;
  readonly dateTimeFormat = DATE_TIME_FORMAT;

  appointmentDetails$: Observable<IAppointmentDetails>;
  waitlistDetails$: Observable<IWaitListDetails>;
  selectedEvent$: Observable<IEvent | undefined>;
  eventDuration$: Observable<number>;
  selectedPractiioner$: Observable<string>;
  selectedPractice$: Observable<string>;
  schedulingAlerts$: Observable<string[]>;
  durationWarningMessage$: Observable<string>;
  treatmentPlanName$: Observable<string>;
  tagType = TagType.Appointment;

  @Output() tagsChange = new EventEmitter<INamedDocument<ITag>[]>();

  constructor(
    private _appointmentSchedulingFacade: AppointmentSchedulingFacade
  ) {
    this.appointmentDetails$ =
      this._appointmentSchedulingFacade.appointmentDetails$;
    this.waitlistDetails$ = this._appointmentSchedulingFacade.waitlistDetails$;
    this.selectedEvent$ = this._appointmentSchedulingFacade.selectedEvent$;
    this.eventDuration$ = combineLatest([
      this.selectedEvent$,
      this.appointmentDetails$,
    ]).pipe(
      map(([event, details]) => {
        if (event) {
          return Event.duration(event);
        }
        return details.duration ?? 0;
      })
    );
    this.selectedPractiioner$ =
      this._appointmentSchedulingFacade.selectedPractitioner$.pipe(
        map((practitioner) => practitioner.name)
      );
    this.selectedPractice$ =
      this._appointmentSchedulingFacade.selectedPractice$.pipe(
        filterUndefined(),
        map((practice) => practice.name)
      );

    this.durationWarningMessage$ = this.selectedEvent$.pipe(
      switchMap((event) =>
        iif(
          () => !event,
          this._appointmentSchedulingFacade.durationWarningMessage$,
          this._appointmentSchedulingFacade.selectedEventDurationWarning$
        )
      )
    );

    this.treatmentStepSummary$ = this.appointmentDetails$.pipe(
      guardFilter(isRequiredAppointmentDetails),
      map((details) => TreatmentStep.summary(details.treatment.step))
    );

    this.schedulingAlerts$ = combineLatest([
      this.appointmentDetails$.pipe(
        map((details) => details.treatment),
        filter(isTreatmentOption)
      ),
      this.selectedEvent$.pipe(filterUndefined()),
    ]).pipe(
      switchMap(([treatment, event]) =>
        getSchedulingAlerts$(treatment, event.from)
      )
    );

    this.tagCol$ = this._appointmentSchedulingFacade.brand$.pipe(
      map((brand) => Brand.appointmentTagCol(brand))
    );

    this.appointmentTags.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => this.tagsChange.emit(value));

    this._appointmentSchedulingFacade.currentAppointment$
      .pipe(
        filterUndefined(),
        map((appointment) => appointment.tags),
        takeUntil(this._onDestroy$)
      )
      .subscribe((tags) => {
        this.appointmentTags.setValue(tags, { emitEvent: false });
      });

    this.treatmentPlanName$ =
      this._appointmentSchedulingFacade.appointmentDetails$.pipe(
        map(
          (appointmentDetails) =>
            appointmentDetails.overridePlan?.name ??
            appointmentDetails.treatment?.plan?.name ??
            ''
        )
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  commentHasContent(content?: MixedSchema): boolean {
    return content ? !!getSchemaSize(content) : false;
  }
}
