<mat-toolbar class="!bg-white">
  <span fxFlex>Manage Appointment</span>
  <button mat-icon-button (click)="back()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<div class="border-b border-solid border-slate-300 bg-white"></div>

<div class="flex flex-auto flex-row overflow-hidden">
  <div class="flex flex-auto">
    <div class="h-full w-full overflow-scroll bg-white">
      <mat-vertical-stepper
        #stepper="matVerticalStepper"
        (selectionChange)="handleStepChange($event)"
      >
        <mat-step>
          <ng-template matStepLabel>Appointment Details</ng-template>

          <pr-appointment-details
            #appointmentDetails
            [appointmentDetails]="appointmentDetails$ | ngrxPush"
            [practitioners]="practitioners$ | ngrxPush"
            [patient]="patient$ | ngrxPush"
            [practices]="practices$ | ngrxPush"
            (appointmentDetailsChange)="updateAppointmentDetails($event)"
          />

          <div>
            <button
              mat-flat-button
              color="primary"
              [disabled]="appointmentDetails.form.invalid"
              matStepperNext
            >
              Next
            </button>
          </div>
        </mat-step>

        <mat-step
          #appointmentSelectStep
          [completed]="(selectedEvent$ | ngrxPush) ? true : false"
          [hasError]="hasError$ | ngrxPush"
          [errorMessage]="unscheduledErrorMessage"
        >
          <ng-template matStepLabel>Select a time</ng-template>
          <pr-appointment-selector />

          <div>
            <button
              mat-flat-button
              color="primary"
              matStepperNext
              [disabled]="hasError$ | ngrxPush"
            >
              Next
            </button>
          </div>
        </mat-step>

        <mat-step optional>
          <ng-template matStepLabel>Waitlist</ng-template>

          <pr-waitlist-configuration
            [waitlistDetails]="waitlistDetails$ | ngrxPush"
            [event]="selectedEvent$ | ngrxPush"
            [openTime]="openTime$ | ngrxPush"
            [closeTime]="closeTime$ | ngrxPush"
            (waitListChange)="updateWaitList($event)"
          />

          <div>
            <button mat-flat-button color="primary" matStepperNext>Next</button>
          </div>
        </mat-step>

        <mat-step label="Automations" optional>
          <pr-appointment-automations-reschedule
            class="mt-4 block"
            [treatmentStep]="treatmentStep$ | ngrxPush"
            [newEvent]="newEvent$ | ngrxPush"
            [patient]="patient$ | async"
          />
        </mat-step>
      </mat-vertical-stepper>
      <div class="px-8 py-4">
        <pr-confirm-appointment (tagsChange)="updateTags($event)" />
        <div class="flex flex-row justify-start gap-2">
          @if (requiresReschedulingReason$ | async) {
            <pr-scheduling-event-reason-selector
              [formControl]="reasonControl"
              [reasons]="reasons$ | async"
              [schedulingConditions]="schedulingConditions$ | async"
            />
          }

          <div
            [matTooltip]="disableSaveMessage$ | async"
            [matTooltipDisabled]="(disableSave$ | async) === false"
          >
            <pt-loader-button
              class="h-full"
              type="flat"
              [loading]="saving$ | async"
              [disabled]="disableSave$ | async"
              color="primary"
              (clicked)="submit()"
            >
              Update Appointment
            </pt-loader-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sidebar bg-white">
    <div class="h-full w-full overflow-scroll">
      <pr-appointment-sidebar
        class="!top-auto"
        [patient]="patient$ | ngrxPush"
        [appointment]="appointment$ | ngrxPush"
      />
    </div>
  </div>
</div>
