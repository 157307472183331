import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DYNAMIC_COMPONENT_DATA } from '@principle-theorem/ng-shared';
import { filterUndefined } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { type ID3ChartBuilder } from '../chart-builders/d3-chart-builders/d3-chart-builder';
import { type IDynamicChartData } from '../chart-builders/dynamic-chart-resolver';
import { type ID3ChartRenderer } from '../d3-chart/d3-chart.component';
import { loadDCDataResults$ } from '../dynamic-dc-chart/dc-chart-display';

interface ID3ChartDisplay {
  label: string;
  renderer: ID3ChartRenderer;
}

@Component({
  selector: 'pr-dynamic-d3-chart',
  templateUrl: './dynamic-d3-chart.component.html',
  styleUrls: ['./dynamic-d3-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicD3ChartComponent {
  chart$: Observable<ID3ChartDisplay>;

  constructor(
    @Inject(DYNAMIC_COMPONENT_DATA)
    data: IDynamicChartData<ID3ChartBuilder>
  ) {
    this.chart$ = loadDCDataResults$(data.config, data.dataBuilder).pipe(
      map((results) => data.chartBuilder.getRenderer(results)),
      filterUndefined(),
      map((renderer) => ({
        label: data.chartBuilder.getLabel(data.config),
        renderer,
      }))
    );
  }
}
