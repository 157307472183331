import { type ISourceEntityRecordData } from '@principle-theorem/principle-core/interfaces';
import {
  TransformEvent,
  fromJSON,
  isJSONSerialisableType,
  isObject,
  toJSON,
  type ITransformer,
} from '@principle-theorem/shared';
import { isString } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISerialisedSourceEntityRecordData
  extends ISourceEntityRecordData<string> {}

export class SourceEntityRecordDataSerialiser
  implements
    ITransformer<ISourceEntityRecordData, ISerialisedSourceEntityRecordData>
{
  events = [TransformEvent.ToFirestore];

  guard(item: unknown): item is ISourceEntityRecordData {
    return (
      isObject(item) && isJSONSerialisableType(item) && !isString(item.data)
    );
  }

  transform(item: ISourceEntityRecordData): ISerialisedSourceEntityRecordData {
    return {
      ...item,
      data: toJSON(item.data),
    };
  }
}

export class SourceEntityRecordDataUnserialiser
  implements
    ITransformer<ISerialisedSourceEntityRecordData, ISourceEntityRecordData>
{
  events = [TransformEvent.FromFirestore];

  guard(item: unknown): item is ISerialisedSourceEntityRecordData {
    return isJSONSerialisableType(item) && isString(item.data);
  }

  transform(item: ISerialisedSourceEntityRecordData): ISourceEntityRecordData {
    try {
      const data = fromJSON<unknown>(item.data);
      if (!isObject(data)) {
        throw new Error('Falied to unseralise data');
      }
      return {
        ...item,
        data,
      };
    } catch (error) {
      throw new Error(`Failed to parse serialised data: ${String(error)}`);
    }
  }
}
