import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  type ILab,
  type ILabJobType,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';

export interface IUpdateLabJobTypeDialogData {
  lab: WithRef<ILab>;
  labJobType: ILabJobType;
}

@Component({
  selector: 'pr-update-lab-job-type',
  templateUrl: './update-lab-job-type.component.html',
  styleUrls: ['./update-lab-job-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateLabJobTypeComponent {
  constructor(
    public dialogRef: MatDialogRef<UpdateLabJobTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IUpdateLabJobTypeDialogData
  ) {}

  submitted(labJobType: ILabJobType): void {
    this.dialogRef.close(labJobType);
  }
}
