import {
  TypedFormControl,
  TypedFormGroup,
  validFormControlChanges$,
} from '@principle-theorem/ng-shared';
import { editor as MonacoEditor } from 'monaco-editor';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  MOCK_SEND_QUOTE_REQUEST_DATA,
  MOCK_SEND_QUOTE_REQUEST_EXTENDED_DATA,
  MOCK_SEND_QUOTE_REQUEST_METHOD,
} from './mock-request-data';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IHicapsConnectJsonRequest {
  methodName: string;
  request: object | undefined;
  extendedData: object | undefined;
}

@Component({
    selector: 'pr-hicaps-custom-request',
    templateUrl: './hicaps-custom-request.component.html',
    styleUrls: ['./hicaps-custom-request.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HicapsCustomRequestComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  requestDataEditor$ = new BehaviorSubject<
    MonacoEditor.IStandaloneCodeEditor | undefined
  >(undefined);
  extendedDataEditor$ = new BehaviorSubject<
    MonacoEditor.IStandaloneCodeEditor | undefined
  >(undefined);
  editorOptions: MonacoEditor.IEditorOptions & Record<string, unknown> = {
    theme: 'vs-dark',
    language: 'json',
    formatOnPaste: true,
    validate: true,
    minimap: {
      enabled: false,
    },
  };
  form = new TypedFormGroup<IHicapsConnectJsonRequest>({
    methodName: new TypedFormControl<string>(MOCK_SEND_QUOTE_REQUEST_METHOD, [
      Validators.required,
    ]),
    request: new TypedFormControl<object | undefined>({}, [
      Validators.required,
    ]),
    extendedData: new TypedFormControl<object | undefined>({}, [
      Validators.required,
    ]),
  });
  requestDataFormControl = new TypedFormControl<string>(undefined, [
    Validators.required,
  ]);
  extendedDataFormControl = new TypedFormControl<string>(undefined, [
    Validators.required,
  ]);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: Partial<IHicapsConnectJsonRequest> | undefined,
    private _dialogRef: MatDialogRef<
      Partial<IHicapsConnectJsonRequest> | undefined,
      IHicapsConnectJsonRequest
    >
  ) {
    this.requestDataFormControl.patchValue(
      JSON.stringify({
        ...MOCK_SEND_QUOTE_REQUEST_DATA,
        ...data?.request,
      })
    );
    this.extendedDataFormControl.patchValue(
      JSON.stringify({
        ...MOCK_SEND_QUOTE_REQUEST_EXTENDED_DATA,
        ...data?.extendedData,
      })
    );

    this.form.patchValue({
      request: this._parseJson(this.requestDataFormControl.value),
    });
    this._formatEditor(this.requestDataEditor$.value);
    validFormControlChanges$(this.requestDataFormControl)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((requestData) => {
        this.form.patchValue({ request: this._parseJson(requestData) });
      });

    this.form.patchValue({
      extendedData: this._parseJson(this.extendedDataFormControl.value),
    });
    this._formatEditor(this.extendedDataEditor$.value);
    validFormControlChanges$(this.extendedDataFormControl)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((extendedData) => {
        this.form.patchValue({ extendedData: this._parseJson(extendedData) });
      });

    this.requestDataEditor$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((editor) => this._formatEditor(editor));
    this.extendedDataEditor$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((editor) => this._formatEditor(editor));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  submit(): void {
    const result = this.form.valid ? this.form.value : undefined;
    this._dialogRef.close(result);
  }

  private _parseJson(json: string): object | undefined {
    try {
      return JSON.parse(json) as object;
    } catch (e) {
      return undefined;
    }
  }

  private _formatEditor(editor?: MonacoEditor.IStandaloneCodeEditor): void {
    if (!editor) {
      return;
    }
    const formatDocument: () => void = () =>
      void editor.getAction('editor.action.formatDocument')?.run();

    editor.onDidChangeModelLanguageConfiguration(formatDocument);
    editor.onDidLayoutChange(formatDocument);
    formatDocument();
  }
}
