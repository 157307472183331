import {
  ICustomFormContent,
  IJsonSchemaFormWithResolverConfig,
} from '@principle-theorem/principle-core/interfaces';
import { initFirestoreModel } from '@principle-theorem/shared';

export class CustomFormContent {
  static init(overrides: Partial<ICustomFormContent> = {}): ICustomFormContent {
    return {
      ...initFirestoreModel(),
      jsonSchemaForm: {
        layout: [],
        schema: undefined,
      },
      ...overrides,
    };
  }

  static getJsonSchemaForm(
    customFormContent: ICustomFormContent
  ): IJsonSchemaFormWithResolverConfig {
    return {
      layout: customFormContent.jsonSchemaForm.layout,
      schema: customFormContent.jsonSchemaForm.schema,
      dataResolverConfig: customFormContent.dataResolverConfig,
    };
  }
}
