import {
  Money,
  TaxStrategy,
  roundTo2Decimals,
} from '@principle-theorem/accounting';
import {
  Invoice,
  Transaction,
  TransactionOperators,
  getTransactionAmount,
  stafferToNamedDoc,
  toAccountDetails,
} from '@principle-theorem/principle-core';
import {
  IDiscountExtendedData,
  IServiceCodeLineItem,
  InvoiceLineItemType,
  InvoiceStatus,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
  type IBasePatient,
  type ICustomLineItem,
  type IGetRecordResponse,
  type IInvoice,
  type IManualExtendedData,
  type IPatient,
  type IPatientContactDetails,
  type IPractice,
  type IPracticeMigration,
  type IStaffer,
  type ITransaction,
  type ITranslationMap,
  type ITreatmentLineItem,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  INamedDocument,
  asyncForAll,
  isSameRef,
  sortByCreatedAt,
  sortTimestampAsc,
  toMomentTz,
  toNamedDocument,
  toTimestamp,
  type IIdentifiable,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first, groupBy, sortBy, sum, uniq, uniqBy } from 'lodash';
import * as moment from 'moment-timezone';
import { PATIENT_RESOURCE_TYPE } from '../../../../destination/entities/patient';
import { resolveMappedCode } from '../../../../mappings/item-codes';
import { ItemCodeResourceMapType } from '../../../../mappings/item-codes-to-xlsx';
import { getPractitionerOrDefault } from '../../../../mappings/staff';
import { TranslationMapHandler } from '../../../../translation-map';
import {
  type IOasisPatientDiscount,
  type IOasisPatientDiscountFilters,
  type IOasisPatientDiscountTranslations,
} from '../../../source/entities/patient-discounts';
import {
  type IOasisPatientPaymentAdjustment,
  type IOasisPatientPaymentAdjustmentFilters,
  type IOasisPatientPaymentAdjustmentTranslations,
} from '../../../source/entities/patient-payment-adjustments';
import {
  type IOasisPatientPayment,
  type IOasisPatientPaymentFilters,
  type IOasisPatientPaymentTranslations,
} from '../../../source/entities/patient-payments';
import {
  type IOasisPatientWriteOff,
  type IOasisPatientWriteOffFilters,
  type IOasisPatientWriteOffTranslations,
} from '../../../source/entities/patient-treatment-write-offs';
import {
  type IOasisPatientTreatment,
  type IOasisPatientTreatmentFilters,
  type IOasisPatientTreatmentTranslations,
} from '../../../source/entities/patient-treatments';

type PractitionerTransaction = IIdentifiable &
  ITransaction & { practitionerRef: DocumentReference<IStaffer> };

interface IPatientAccountSummary {
  totalCharged: number;
  totalPaid: number;
  totalOwing: number;
  treatments: IGetRecordResponse<
    IOasisPatientTreatment,
    IOasisPatientTreatmentTranslations,
    IOasisPatientTreatmentFilters
  >[];
  payments: IGetRecordResponse<
    IOasisPatientPayment,
    IOasisPatientPaymentTranslations,
    IOasisPatientPaymentFilters
  >[];
  adjustments: IGetRecordResponse<
    IOasisPatientPaymentAdjustment,
    IOasisPatientPaymentAdjustmentTranslations,
    IOasisPatientPaymentAdjustmentFilters
  >[];
  discounts: IGetRecordResponse<
    IOasisPatientDiscount,
    IOasisPatientDiscountTranslations,
    IOasisPatientDiscountFilters
  >[];
  writeOffs: IGetRecordResponse<
    IOasisPatientWriteOff,
    IOasisPatientWriteOffTranslations,
    IOasisPatientWriteOffFilters
  >[];
  balance: number;
  paymentTransactions: PractitionerTransaction[];
  adjustmentTransactions: PractitionerTransaction[];
  discountTransactions: PractitionerTransaction[];
  writeOffTransactions: PractitionerTransaction[];
}

interface IInvoiceBuildData {
  invoice: IIdentifiable & IInvoice;
  transactions: PractitionerTransaction[];
  practitionerRef: DocumentReference<IStaffer>;
}

export class OasisInvoiceBuilder {
  accountSummary: IPatientAccountSummary = {
    totalCharged: 0,
    totalPaid: 0,
    totalOwing: 0,
    balance: 0,
    treatments: [],
    payments: [],
    adjustments: [],
    discounts: [],
    writeOffs: [],
    paymentTransactions: [],
    adjustmentTransactions: [],
    discountTransactions: [],
    writeOffTransactions: [],
  };

  constructor(
    public sourceTreatments: IGetRecordResponse<
      IOasisPatientTreatment,
      IOasisPatientTreatmentTranslations,
      IOasisPatientTreatmentFilters
    >[],
    public sourcePayments: IGetRecordResponse<
      IOasisPatientPayment,
      IOasisPatientPaymentTranslations,
      IOasisPatientPaymentFilters
    >[],
    public sourceDiscounts: IGetRecordResponse<
      IOasisPatientDiscount,
      IOasisPatientDiscountTranslations,
      IOasisPatientDiscountFilters
    >[],
    public sourcePaymentAdjustments: IGetRecordResponse<
      IOasisPatientPaymentAdjustment,
      IOasisPatientPaymentAdjustmentTranslations,
      IOasisPatientPaymentAdjustmentFilters
    >[],
    public sourceWriteOffs: IGetRecordResponse<
      IOasisPatientWriteOff,
      IOasisPatientWriteOffTranslations,
      IOasisPatientWriteOffFilters
    >[],
    public patientBalance: number,
    public staff: WithRef<ITranslationMap<IStaffer, unknown>>[],
    public practitioners: WithRef<IStaffer>[],
    public sourceItemCodes: WithRef<
      ITranslationMap<object, ItemCodeResourceMapType>
    >[],
    public translationMap: TranslationMapHandler,
    public migration: WithRef<IPracticeMigration>
  ) {}

  buildAccountSummary(practiceRef: DocumentReference<IPractice>): void {
    const treatments = this.sourceTreatments.filter(
      (item) => !item.data.data.isDeleted
    );
    const payments = this.sourcePayments.filter(
      (item) => !item.data.data.isDeleted
    );
    const adjustments = this.sourcePaymentAdjustments.filter(
      (item) => !item.data.data.isDeleted
    );
    const discounts = this.sourceDiscounts.filter(
      (item) => !item.data.data.isDeleted
    );
    const writeOffs = this.sourceWriteOffs.filter(
      (item) => !item.data.data.isDeleted
    );

    const totalCharged = [
      ...treatments,
      ...payments,
      ...adjustments,
      ...discounts,
      ...writeOffs,
    ].reduce((total, item) => total + (item.data.data.amount ?? 0), 0);
    const totalPaid = payments.reduce(
      (total, item) =>
        total + (item.data.data.amount ?? item.data.data.claimAmount ?? 0),
      0
    );

    const discountTransactions: PractitionerTransaction[] = this.sourceDiscounts
      .filter(
        (sourceDiscount) =>
          !sourceDiscount.data.data.isDeleted &&
          !sourceDiscount.data.data.deletedAt
      )
      .filter((sourceDiscount) => !!sourceDiscount.data.data.amount)
      .map((sourceDiscount) =>
        this.buildDiscountTransaction(sourceDiscount, practiceRef)
      );

    treatments.map((treatment) => {
      discountTransactions.push(
        ...compact([
          this.buildTreamentDiscountTransaction(treatment, practiceRef),
        ])
      );
    });

    const adjustmentTransactions: PractitionerTransaction[] =
      this.sourcePaymentAdjustments
        .filter(
          (sourceAdjustment) =>
            !sourceAdjustment.data.data.isDeleted &&
            !sourceAdjustment.data.data.deletedAt
        )
        .filter((sourceAdjustment) => !!sourceAdjustment.data.data.amount)
        .map((sourceAdjustment) =>
          this.buildAdjustmentDiscountTransaction(sourceAdjustment, practiceRef)
        );

    const writeOffTransactions: PractitionerTransaction[] = this.sourceWriteOffs
      .filter(
        (sourceWriteOff) =>
          !sourceWriteOff.data.data.isDeleted &&
          !sourceWriteOff.data.data.deletedAt
      )
      .filter((sourceWriteOff) => !!sourceWriteOff.data.data.amount)
      .map((sourceWriteOff) => {
        const staffer = getPractitionerOrDefault(
          sourceWriteOff.data.data.practitionerId.toString(),
          this.staff,
          this.practitioners
        );

        if (!staffer) {
          throw new Error(
            `Failed to resolve staffer: ${sourceWriteOff.data.data.practitionerId}`
          );
        }

        const extendedData: IDiscountExtendedData = {
          practitionerRef: staffer.ref,
        };
        const amount = sourceWriteOff.data.data.amount || 0;
        const transaction = {
          ...Transaction.init({
            provider: TransactionProvider.Discount,
            reference: sourceWriteOff.data.data.id.toString(),
            from: '',
            to: '',
            amount: Money.amount(Math.abs(amount)),
            type:
              amount < 0 ? TransactionType.Outgoing : TransactionType.Incoming,
            description: sourceWriteOff.data.data.description || 'Write Off',
            createdAt: toTimestamp(
              moment.tz(
                sourceWriteOff.data.translations.appointmentDate,
                this.migration.configuration.timezone
              )
            ),
            extendedData,
            practiceRef,
            status: TransactionStatus.Complete,
          }),
          practitionerRef: staffer.ref,
        };

        return {
          ...transaction,
          uid: sourceWriteOff.data.data.id.toString(),
        };
      });

    const paymentTransactions: PractitionerTransaction[] = this.sourcePayments
      .filter(
        (sourcePayment) =>
          !sourcePayment.data.data.isDeleted &&
          !sourcePayment.data.data.deletedAt
      )
      .filter(
        (sourcePayment) =>
          sourcePayment.data.data.amount !== undefined ||
          sourcePayment.data.data.thirdPartyAmount !== undefined
      )
      .map((sourcePayment) =>
        this.buildPaymentTransaction(sourcePayment, practiceRef)
      );

    this.accountSummary = {
      totalCharged,
      totalPaid,
      totalOwing: roundTo2Decimals(totalCharged - Math.abs(totalPaid)),
      balance: this.patientBalance,
      treatments,
      payments,
      adjustments,
      discounts,
      writeOffs,
      paymentTransactions,
      adjustmentTransactions,
      discountTransactions,
      writeOffTransactions,
    };
  }

  validateAccountSummary(): void {
    const balance = roundTo2Decimals(
      this.accountSummary.totalCharged - Math.abs(this.accountSummary.totalPaid)
    );
    if (balance !== this.accountSummary.totalOwing) {
      throw new Error(
        `Patient balance mismatch: ${balance} !== ${this.accountSummary.totalOwing}`
      );
    }
  }

  async buildInvoices(
    patient: IBasePatient & IPatientContactDetails,
    practice: WithRef<IPractice>
  ): Promise<IInvoiceBuildData[]> {
    const invoices = await this.buildTreatmentInvoices(patient, practice);
    const invoiceBuildData = invoices.sort((invoiceA, invoiceB) =>
      sortTimestampAsc(invoiceA.invoice.createdAt, invoiceB.invoice.createdAt)
    );

    const transactions = [
      ...this.accountSummary.adjustmentTransactions,
      ...this.accountSummary.discountTransactions,
      ...this.accountSummary.writeOffTransactions,
      ...this.accountSummary.paymentTransactions,
    ].sort((transactionA, transactionB) =>
      sortTimestampAsc(transactionA.createdAt, transactionB.createdAt)
    );

    const invoicePractitioners = uniqBy(
      invoices.map((invoice) => invoice.practitionerRef),
      (practitionerRef) => practitionerRef.path
    );

    const transactionsWithoutPractitionerInvoices = transactions.filter(
      (transaction) =>
        !invoicePractitioners.some((invoicePractitioner) =>
          isSameRef(invoicePractitioner, transaction.practitionerRef)
        )
    );

    let allResults = invoicePractitioners
      .map((practitionerRef) =>
        this._buildPractitionerInvoices(
          invoiceBuildData,
          practitionerRef,
          transactions
        )
      )
      .flat();

    if (transactionsWithoutPractitionerInvoices.length) {
      allResults = this._buildInvoiceData(
        allResults,
        transactionsWithoutPractitionerInvoices
      );
    }

    return allResults.map((invoiceData) => this.setInvoiceStatus(invoiceData));
  }

  buildFinalInvoice(
    invoiceData: IInvoiceBuildData,
    transactionsForInvoice: PractitionerTransaction[],
    remainingTransactions: PractitionerTransaction[]
  ): {
    transactions: PractitionerTransaction[];
    lineItems: ICustomLineItem[];
  } {
    const lineItems = this.buildFinalLineItems(
      remainingTransactions,
      invoiceData
    );

    return {
      lineItems,
      transactions: [
        ...invoiceData.transactions,
        ...transactionsForInvoice,
        ...remainingTransactions,
      ],
    };
  }

  buildFinalLineItems(
    remainingTransactions: PractitionerTransaction[],
    invoiceData: IInvoiceBuildData
  ): ICustomLineItem[] {
    const depositAmount = Money.amount(
      sum(remainingTransactions.map((payment) => getTransactionAmount(payment)))
    );

    if (depositAmount > 0) {
      return [
        {
          uid: `${invoiceData.invoice.uid}-deposit`,
          type: InvoiceLineItemType.Deposit,
          description: 'Overpayment Balance',
          amount: depositAmount,
          tax: 0,
          taxStatus: TaxStrategy.GSTApplicable,
          quantity: 1,
        },
      ];
    }

    return [];
  }

  setInvoiceStatus(invoiceData: IInvoiceBuildData): IInvoiceBuildData {
    const status = this.getInvoiceStatus(
      Invoice.balance(invoiceData.invoice, invoiceData.transactions)
    );
    const lastTransaction = new TransactionOperators(invoiceData.transactions)
      .completed()
      .sort(sortByCreatedAt)
      .reverse()
      .last();

    if (status === InvoiceStatus.Paid && lastTransaction) {
      invoiceData.invoice.paidAt = lastTransaction.createdAt;
    }
    Invoice.updateStatus(
      invoiceData.invoice,
      status,
      invoiceData.invoice.createdAt
    );

    return invoiceData;
  }

  getInvoiceStatus(amountRemaining: number): InvoiceStatus {
    if (amountRemaining <= 0) {
      return InvoiceStatus.Paid;
    }

    if (amountRemaining > 0) {
      return InvoiceStatus.Issued;
    }

    return InvoiceStatus.Draft;
  }

  async buildTreatmentInvoices(
    patient: IBasePatient & IPatientContactDetails,
    practice: WithRef<IPractice>
  ): Promise<IInvoiceBuildData[]> {
    const groupedTreatments = groupBy(
      this.sourceTreatments.filter(
        (sourceTreatment) =>
          !sourceTreatment.data.data.isDeleted &&
          !sourceTreatment.data.data.deletedAt
      ),
      (sourceTreatment) =>
        `${sourceTreatment.data.data.appointmentDate}-${sourceTreatment.data.data.practitionerId}`
    );

    const invoices: IInvoiceBuildData[] = await asyncForAll(
      Object.values(groupedTreatments),
      async (treatments) => {
        const sortedTreatments = sortBy(
          treatments,
          (treatment) => treatment.data.data.id
        );
        const sourceInvoice = sortedTreatments[0];
        const appointmentDate = toMomentTz(
          sourceInvoice.data.translations.appointmentDate,
          practice.settings.timezone
        );
        const dueDate = toTimestamp(appointmentDate.clone().add(1, 'week'));
        const practitionerRef = getPractitionerOrDefault(
          sourceInvoice.data.data.billedToPractitionerId.toString(),
          this.staff,
          this.practitioners
        )?.ref;

        if (!practitionerRef) {
          throw new Error(
            `Practitioner not found for treatment: ${sourceInvoice.data.data.id}, practitionerId: ${sourceInvoice.data.data.billedToPractitionerId}`
          );
        }

        const invoice = Invoice.init({
          due: dueDate,
          practice: toNamedDocument(practice),
          from: toAccountDetails(practice),
          to: toAccountDetails(patient),
          issuedAt: toTimestamp(appointmentDate),
          reference: sourceInvoice.data.data.id.toString(),
          createdAt: sourceInvoice.data.translations.createdAt,
        });

        const isOnBehalfOf = sortedTreatments.find(
          (treatment) =>
            treatment.data.data.patientId?.toString() !== patient.referenceId
        );
        if (isOnBehalfOf && isOnBehalfOf.data.data.patientId) {
          const fromRef = await this.translationMap.getDestination<IPatient>(
            isOnBehalfOf.data.data.patientId.toString(),
            PATIENT_RESOURCE_TYPE
          );

          if (fromRef) {
            const childPatient = await Firestore.getDoc(fromRef);
            const onBehalfOf = toAccountDetails(
              childPatient as IBasePatient & IPatientContactDetails
            );
            invoice.to = {
              ...invoice.to,
              onBehalfOf,
            };
          }
        }

        const lineItems: (ITreatmentLineItem | ICustomLineItem)[] =
          sortedTreatments
            .map((treatment) => {
              const staffer = getPractitionerOrDefault(
                treatment.data.data.billedToPractitionerId.toString(),
                this.staff,
                this.practitioners
              );

              if (!staffer) {
                throw new Error(
                  `Failed to resolve staffer: ${treatment.data.data.billedToPractitionerId}`
                );
              }

              const serviceCodeLineItems =
                this.getServiceCodeLineItem(treatment);
              if (!serviceCodeLineItems.length) {
                return this.getNonServiceCodeLineItem(treatment);
              }

              return this.buildTreatmentLineItem(
                treatment,
                staffer,
                serviceCodeLineItems
              );
            })
            .flat();

        const invoiceWithId = {
          ...invoice,
          items: lineItems.flat(),
          uid: sourceInvoice.data.data.id.toString(),
        };

        return {
          invoice: invoiceWithId,
          transactions: [],
          practitionerRef,
        };
      }
    );

    return invoices;
  }

  buildTreatmentLineItem(
    treatment: IGetRecordResponse<
      IOasisPatientTreatment,
      IOasisPatientTreatmentTranslations,
      IOasisPatientTreatmentFilters
    >,
    staffer: INamedDocument<IStaffer>,
    treatmentItems: IServiceCodeLineItem[]
  ): ITreatmentLineItem[] {
    // const quantity = treatment.data.data.quantity ?? 1;
    const amount = Money.amount(treatment.data.data.amount ?? 0);

    return [
      {
        uid: treatment.data.data.id.toString(),
        type: InvoiceLineItemType.Treatment,
        treatmentRef: {
          treatmentUuid: treatment.data.data.id.toString(),
          attributedTo: stafferToNamedDoc(staffer),
        },
        items: treatmentItems,
        description: treatment.data.data.itemCodeDescription,
        amount: amount < 0 ? 0 : amount,
        tax: 0,
        taxStatus: TaxStrategy.GSTApplicable,
        quantity: 1,
      },
    ];
  }

  getServiceCodeLineItem(
    treatment: IGetRecordResponse<
      IOasisPatientTreatment,
      IOasisPatientTreatmentTranslations,
      IOasisPatientTreatmentFilters
    >
  ): IServiceCodeLineItem[] {
    let treatmentCode = treatment.data.data.itemCode;
    if (treatmentCode.length === 2) {
      treatmentCode = `0${treatmentCode}`;
    }
    const code = resolveMappedCode(
      this.sourceItemCodes,
      treatmentCode,
      treatmentCode
    );
    if (!code) {
      return [];
    }

    const quantity =
      treatment.data.data.quantity && treatment.data.data.quantity > 0
        ? treatment.data.data.quantity
        : 1;
    const amounts = Money.allocate(
      treatment.data.data.amount ?? 0,
      Array.from(Array(quantity), () => 1)
    );

    const allAmountsTheSame = uniq(amounts).length === 1;

    if (allAmountsTheSame) {
      const amount = Money.amount(amounts[0]);

      return [
        {
          uid: treatment.data.data.id.toString(),
          type: InvoiceLineItemType.ServiceCode,
          code: code.code.toString(),
          toothId: treatment.data.data.toothNumber,
          description: `${code.code.toString()} - ${
            treatment.data.data.itemCodeDescription
          }`,
          amount: (amount ?? 0) < 0 ? 0 : amount ?? 0,
          tax: treatment.data.data.gstAmount ?? 0,
          taxStatus: TaxStrategy.GSTApplicable,
          quantity,
        },
      ];
    }

    return amounts.map((dividedAmount, index) => {
      const amount = Money.amount(dividedAmount);

      return {
        uid: `${treatment.data.data.id.toString()}-${index}`,
        type: InvoiceLineItemType.ServiceCode,
        code: code.code.toString(),
        toothId: treatment.data.data.toothNumber,
        description: `${code.code.toString()} - ${
          treatment.data.data.itemCodeDescription
        }`,
        amount: (amount ?? 0) < 0 ? 0 : amount ?? 0,
        tax: treatment.data.data.gstAmount ?? 0,
        taxStatus: TaxStrategy.GSTApplicable,
        quantity: 1,
      };
    });
  }

  getNonServiceCodeLineItem(
    treatment: IGetRecordResponse<
      IOasisPatientTreatment,
      IOasisPatientTreatmentTranslations,
      IOasisPatientTreatmentFilters
    >
  ): ICustomLineItem[] {
    const quantity =
      treatment.data.data.quantity && treatment.data.data.quantity > 0
        ? treatment.data.data.quantity
        : 1;
    const amount = Money.amount((treatment.data.data.amount ?? 0) / quantity);

    return [
      {
        uid: treatment.data.data.id.toString(),
        type: InvoiceLineItemType.Fee,
        description: `${treatment.data.data.itemCode} - ${treatment.data.data.itemCodeDescription}`,
        amount: (amount ?? 0) < 0 ? 0 : amount ?? 0,
        tax: treatment.data.data.gstAmount ?? 0,
        taxStatus: TaxStrategy.GSTApplicable,
        quantity,
      },
    ];
  }

  buildPaymentTransaction(
    sourcePayment: IGetRecordResponse<
      IOasisPatientPayment,
      IOasisPatientPaymentTranslations,
      IOasisPatientPaymentFilters
    >,
    practiceRef: DocumentReference<IPractice>
  ): PractitionerTransaction {
    const staffer = getPractitionerOrDefault(
      sourcePayment.data.data.practitionerId.toString(),
      this.staff,
      this.practitioners
    );
    if (!staffer) {
      throw new Error(
        `Failed to resolve staffer: ${sourcePayment.data.data.practitionerId}`
      );
    }

    const extendedData: IManualExtendedData = {};
    const amount =
      sourcePayment.data.data.amount ??
      sourcePayment.data.data.thirdPartyAmount ??
      0;
    const provider = sourcePayment.data.data.cash
      ? TransactionProvider.Cash
      : TransactionProvider.Manual;

    return {
      ...Transaction.init({
        uid: sourcePayment.data.data.id.toString(),
        provider,
        reference: sourcePayment.data.data.id.toString(),
        from: '',
        to: '',
        amount: Money.amount(Math.abs(amount)),
        type: amount < 0 ? TransactionType.Outgoing : TransactionType.Incoming,
        description: sourcePayment.data.data.paymentDescription || 'Payment',
        createdAt: toTimestamp(
          moment.tz(
            sourcePayment.data.translations.appointmentDate,
            this.migration.configuration.timezone
          )
        ),
        extendedData,
        practiceRef,
        status: TransactionStatus.Complete,
      }),
      practitionerRef: staffer.ref,
    };
  }

  buildDiscountTransaction(
    sourceDiscount: IGetRecordResponse<
      IOasisPatientDiscount,
      IOasisPatientDiscountTranslations,
      IOasisPatientDiscountFilters
    >,
    practiceRef: DocumentReference<IPractice>
  ): PractitionerTransaction {
    const staffer = getPractitionerOrDefault(
      sourceDiscount.data.data.practitionerId.toString(),
      this.staff,
      this.practitioners
    );

    if (!staffer) {
      throw new Error(
        `Failed to resolve staffer: ${sourceDiscount.data.data.practitionerId}`
      );
    }

    const extendedData: IDiscountExtendedData = {
      practitionerRef: staffer.ref,
    };
    const amount = sourceDiscount.data.data.amount || 0;
    return {
      ...Transaction.init({
        uid: sourceDiscount.data.data.id.toString(),
        provider: TransactionProvider.Discount,
        reference: sourceDiscount.data.data.id.toString(),
        from: '',
        to: '',
        amount: Money.amount(Math.abs(amount)),
        type: amount > 0 ? TransactionType.Incoming : TransactionType.Outgoing,
        description: sourceDiscount.data.data.description || 'Discount',
        createdAt: toTimestamp(
          moment.tz(
            sourceDiscount.data.translations.appointmentDate,
            this.migration.configuration.timezone
          )
        ),
        extendedData,
        practiceRef,
        status: TransactionStatus.Complete,
      }),
      practitionerRef: staffer.ref,
    };
  }

  buildAdjustmentDiscountTransaction(
    sourceAdjustment: IGetRecordResponse<
      IOasisPatientPaymentAdjustment,
      IOasisPatientPaymentAdjustmentTranslations,
      IOasisPatientPaymentAdjustmentFilters
    >,
    practiceRef: DocumentReference<IPractice>
  ): PractitionerTransaction {
    const staffer = getPractitionerOrDefault(
      sourceAdjustment.data.data.practitionerId.toString(),
      this.staff,
      this.practitioners
    );

    if (!staffer) {
      throw new Error(
        `Failed to resolve staffer: ${sourceAdjustment.data.data.practitionerId}`
      );
    }

    const extendedData: IDiscountExtendedData = {
      practitionerRef: staffer.ref,
    };
    const amount = sourceAdjustment.data.data.amount || 0;
    return {
      ...Transaction.init({
        uid: sourceAdjustment.data.data.id.toString(),
        provider: TransactionProvider.Discount,
        reference: sourceAdjustment.data.data.id.toString(),
        from: '',
        to: '',
        amount: Money.amount(Math.abs(amount)),
        type: amount < 0 ? TransactionType.Incoming : TransactionType.Outgoing,
        description: sourceAdjustment.data.data.description || 'Adjustment',
        createdAt: toTimestamp(
          moment.tz(
            sourceAdjustment.data.translations.appointmentDate,
            this.migration.configuration.timezone
          )
        ),
        extendedData,
        practiceRef,
        status: TransactionStatus.Complete,
      }),
      practitionerRef: staffer.ref,
    };
  }

  buildTreamentDiscountTransaction(
    sourceTreatment: IGetRecordResponse<
      IOasisPatientTreatment,
      IOasisPatientTreatmentTranslations,
      IOasisPatientTreatmentFilters
    >,
    practiceRef: DocumentReference<IPractice>
  ): PractitionerTransaction | undefined {
    const amount = Money.amount(sourceTreatment.data.data.amount ?? 0);
    const staffer = getPractitionerOrDefault(
      sourceTreatment.data.data.practitionerId.toString(),
      this.staff,
      this.practitioners
    );

    if (!staffer) {
      throw new Error(
        `Failed to resolve staffer: ${sourceTreatment.data.data.practitionerId}`
      );
    }

    if (amount >= 0) {
      return;
    }

    const extendedData: IDiscountExtendedData = {
      practitionerRef: staffer.ref,
    };
    return {
      ...Transaction.init({
        uid: sourceTreatment.data.data.id.toString(),
        provider: TransactionProvider.Discount,
        reference: sourceTreatment.data.data.id.toString(),
        from: '',
        to: '',
        amount: Money.amount(Math.abs(amount)),
        type: TransactionType.Incoming,
        description:
          sourceTreatment.data.data.itemCodeDescription || 'Discount',
        createdAt: toTimestamp(
          moment.tz(
            sourceTreatment.data.translations.appointmentDate,
            this.migration.configuration.timezone
          )
        ),
        extendedData,
        practiceRef,
        status: TransactionStatus.Complete,
      }),
      practitionerRef: staffer.ref,
    };
  }

  private _buildInvoiceData(
    invoiceBuildData: IInvoiceBuildData[],
    transactions: PractitionerTransaction[]
  ): IInvoiceBuildData[] {
    const results: IInvoiceBuildData[] = [];

    invoiceBuildData.map((invoiceData, index) => {
      const [transactionsForInvoice, remainingTransactions] =
        getTransactionsForBalance(transactions, invoiceData);
      transactions = remainingTransactions;

      const isLastInvoice = index === invoiceBuildData.length - 1;
      if (!isLastInvoice) {
        results.push({
          invoice: invoiceData.invoice,
          transactions: [
            ...invoiceData.transactions,
            ...transactionsForInvoice,
          ],
          practitionerRef: invoiceData.practitionerRef,
        });
        return;
      }

      const finalInvoiceData = this.buildFinalInvoice(
        invoiceData,
        transactionsForInvoice,
        remainingTransactions
      );
      invoiceData.invoice.items.push(...finalInvoiceData.lineItems);

      results.push({
        invoice: invoiceData.invoice,
        transactions: finalInvoiceData.transactions,
        practitionerRef: invoiceData.practitionerRef,
      });
    });

    return results;
  }

  private _buildPractitionerInvoices(
    invoiceBuildData: IInvoiceBuildData[],
    practitionerRef: DocumentReference<IStaffer>,
    transactions: PractitionerTransaction[]
  ): IInvoiceBuildData[] {
    const practitionerInvoices = invoiceBuildData.filter((invoiceData) =>
      isSameRef(invoiceData.practitionerRef, practitionerRef)
    );
    const practitionerTransactions = transactions.filter((transaction) =>
      isSameRef(transaction.practitionerRef, practitionerRef)
    );

    return this._buildInvoiceData(
      practitionerInvoices,
      practitionerTransactions
    );
  }
}

export function getTransactionsForBalance(
  transactions: PractitionerTransaction[],
  invoiceData: IInvoiceBuildData
): [PractitionerTransaction[], PractitionerTransaction[]] {
  const result: PractitionerTransaction[] = [];
  const additionalTransactions: PractitionerTransaction[] = [];

  let bestBalance: {
    balance: number;
    index: number;
  } = {
    balance: Invoice.balance(invoiceData.invoice, invoiceData.transactions),
    index: 0,
  };

  let remainingBalance = bestBalance.balance;
  let finalIndex = 0;

  if (bestBalance.balance <= 0) {
    return [result, transactions];
  }

  transactions.map((_transaction, index) => {
    const balance = Invoice.balance(invoiceData.invoice, [
      ...invoiceData.transactions,
      ...transactions.slice(0, index + 1),
    ]);

    if (
      (bestBalance.balance >= 0 && balance <= bestBalance.balance) ||
      (bestBalance.balance < 0 && balance >= bestBalance.balance)
    ) {
      bestBalance = {
        balance,
        index,
      };
    }
  });

  transactions.map((transaction, index) => {
    if (remainingBalance <= 0) {
      return;
    }

    const transactionAmount = getTransactionAmount(transaction);
    if (transactionAmount < remainingBalance) {
      result.push(transaction);
      remainingBalance -= transactionAmount;
      finalIndex = index;
      return;
    }
    if (transactionAmount > remainingBalance) {
      const balanceTransactions = splitTransaction(
        transaction,
        remainingBalance
      );
      result.push(...compact([first(balanceTransactions)]));
      finalIndex = index;
      additionalTransactions.push(...balanceTransactions.slice(1));
      remainingBalance = 0;
      return;
    }
    if (transactionAmount === remainingBalance) {
      result.push(transaction);
      remainingBalance = 0;
      finalIndex = index;
      return;
    }
  });
  transactions.splice(0, finalIndex + 1, ...additionalTransactions);
  return [result, transactions];
}

export function splitTransaction(
  transaction: PractitionerTransaction,
  requiredAmount: number
): PractitionerTransaction[] {
  const transactions: PractitionerTransaction[] = [];
  const transactionRemainder = transaction.amount - requiredAmount;

  if (transactionRemainder > 0) {
    transactions.push({
      ...transaction,
      amount: requiredAmount,
      reference: `${transaction.reference}-${transactions.length + 1}`,
      uid: `${transaction.uid}-${transactions.length + 1}`,
    });
    transactions.push({
      ...transaction,
      amount: transactionRemainder,
      reference: `${transaction.reference}-${transactions.length + 1}`,
      uid: `${transaction.uid}-${transactions.length + 1}`,
    });
    return transactions;
  }

  return [transaction];
}
