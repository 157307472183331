import {
  IChartedServiceSmartGroup,
  IChartedSurface,
  IPricedServiceCodeEntry,
  IScopeRef,
  IServiceCode,
  ServiceCode,
} from '@principle-theorem/principle-core/interfaces';
import { IMatchesADAScope } from './rule-handlers/base-rule-handler';
import { PerAnterior } from './rule-handlers/per-anterior';
import { PerAnteriorMultipleSurface } from './rule-handlers/per-anterior-multiple-surface';
import { PerMultipleSurface } from './rule-handlers/per-multiple-surface';
import { PerPosterior } from './rule-handlers/per-posterior';
import { PerPosteriorMultipleSurface } from './rule-handlers/per-posterior-multiple-surface';
import { PerTooth } from './rule-handlers/per-tooth';
import { ServiceProviderHandler } from './service-provider';

const RULE_HANDLERS: IMatchesADAScope[] = [
  new PerTooth(),
  new PerAnterior(),
  new PerPosterior(),
  new PerMultipleSurface(),
  new PerAnteriorMultipleSurface(),
  new PerPosteriorMultipleSurface(),
];

interface IADARuleMatchMap {
  code: IPricedServiceCodeEntry;
  matches: IMatchesADAScope[];
}

export class SmartGroupRuleHandler {
  evaluateSelected(
    group: IChartedServiceSmartGroup,
    treatmentScope: IScopeRef,
    surfaces: IChartedSurface[]
  ): ServiceCode | undefined {
    const valid: IADARuleMatchMap | undefined = this._getValidCode(
      group,
      treatmentScope,
      surfaces
    );

    if (valid) {
      return valid.code.code;
    }

    return group.selected;
  }

  private _getValidCode(
    group: IChartedServiceSmartGroup,
    treatmentScope: IScopeRef,
    surfaces: IChartedSurface[]
  ): IADARuleMatchMap | undefined {
    return group.serviceCodes
      .map((code) => {
        const codeInfo = ServiceProviderHandler.resolveServiceCode(
          group.serviceCodeType,
          code.code
        );

        if (!codeInfo) {
          return {
            code,
            matches: [],
          };
        }
        return {
          code,
          matches: this._getValidRules(codeInfo, treatmentScope, surfaces),
        };
      })
      .filter((code: IADARuleMatchMap) => {
        return code.matches.length > 0;
      })
      .reduce(
        (bestCode: IADARuleMatchMap | undefined, code: IADARuleMatchMap) => {
          if (!bestCode || code.matches.length > bestCode.matches.length) {
            bestCode = code;
          }
          return bestCode;
        },
        undefined
      );
  }

  private _getValidRules(
    code: IServiceCode,
    scope: IScopeRef,
    surfaces: IChartedSurface[]
  ): IMatchesADAScope[] {
    return RULE_HANDLERS.filter((matcher: IMatchesADAScope) => {
      return matcher.matches(code.rules, scope, surfaces);
    });
  }
}
