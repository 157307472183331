import { type Directionality } from '@angular/cdk/bidi';
import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  DOWN_ARROW,
  ENTER,
  ESCAPE,
  hasModifierKey,
  TAB,
  UP_ARROW,
} from '@angular/cdk/keycodes';
import {
  type ConnectedPosition,
  type FlexibleConnectedPositionStrategy,
  type Overlay,
  OverlayConfig,
  OverlayRef,
  type PositionStrategy,
  type ScrollStrategy,
  type ViewportRuler,
} from '@angular/cdk/overlay';
import { _getShadowRoot } from '@angular/cdk/platform';
import { TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  type AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  NgZone,
  type OnChanges,
  type OnDestroy,
  Optional,
  type SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import {
  getMatAutocompleteMissingPanelError,
  MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
  MatAutocomplete,
  MatAutocompleteOrigin,
} from '@angular/material/autocomplete';
import {
  type MatOption,
  MatOptionSelectionChange,
  _countGroupLabelsBeforeOption,
  _getOptionScrollPosition,
} from '@angular/material/core';
import { snapshot, toInt } from '@principle-theorem/shared';
import {
  defer,
  fromEvent,
  merge,
  type Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import {
  delay,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

/** Base class with all of the `MatAutocompleteTrigger` functionality. */
@Directive()
export abstract class EditorAutocompleteTriggerBaseDirective
  implements AfterViewInit, OnChanges, OnDestroy
{
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static ngAcceptInputType_autocompleteDisabled: BooleanInput;

  private _onDestroy$ = new Subject<void>();
  private _viewInitialised$: Subject<void> = new Subject();
  private _overlayRef: OverlayRef | undefined;
  private _portal: TemplatePortal;
  private _componentDestroyed = false;
  private _autocompleteDisabled = false;
  private _scrollStrategy: () => ScrollStrategy;

  /** Old value of the native input. Used to work around issues with the `input` event on IE. */
  private _previousValue: string | number | undefined;

  /** Strategy that is used to position the panel. */
  private _positionStrategy: FlexibleConnectedPositionStrategy;

  /** The subscription for closing actions (some are bound to document). */
  private _closingActionsSubscription: Subscription;

  /** Subscription to viewport size changes. */
  private _viewportSubscription: Subscription = Subscription.EMPTY;

  /**
   * Whether the autocomplete can open the next time it is focused. Used to prevent a focused,
   * closed autocomplete from being reopened if the user switches to another browser tab and then
   * comes back.
   */
  private _canOpenOnNextFocus = true;

  /** Whether the element is inside of a ShadowRoot component. */
  private _isInsideShadowRoot: boolean;

  /** Stream of keyboard events that can close the panel. */
  private readonly _closeKeyEventStream: Subject<void> = new Subject();

  private _overlayAttached = false;

  /** Class to apply to the panel when it's above the input. */
  protected abstract _aboveClass: string;

  /** Stream of autocomplete option selections. */
  readonly optionSelections: Observable<MatOptionSelectionChange> = defer(
    () => {
      if (this.autocomplete && this.autocomplete.options) {
        return merge(
          ...this.autocomplete.options.map((option) => option.onSelectionChange)
        );
      }

      // If there are any subscribers before `ngAfterViewInit`, the `autocomplete` will be undefined.
      // Return a stream that we'll replace with the real one once everything is in place.
      return this._zone.onStable.pipe(
        take(1),
        switchMap(() => this.optionSelections)
      );
    }
  );

  /** The autocomplete panel to be attached to this trigger. */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('ptEditorAutocomplete') autocomplete: MatAutocomplete;

  /**
   * Position of the autocomplete panel relative to the trigger element. A position of `auto`
   * will render the panel underneath the trigger if there is enough space for it to fit in
   * the viewport, otherwise the panel will be shown above it. If the position is set to
   * `above` or `below`, the panel will always be shown above or below the trigger. no matter
   * whether it fits completely in the viewport.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('ptEditorAutocompletePosition') position: 'auto' | 'above' | 'below' =
    'auto';

  /**
   * Reference relative to which to position the autocomplete panel.
   * Defaults to the autocomplete trigger element.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('ptEditorAutocompleteConnectedTo')
  connectedTo: MatAutocompleteOrigin;

  /**
   * `autocomplete` attribute to be set on the input element.
   * @docs-private
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('autocomplete') autocompleteAttribute = 'off';

  constructor(
    private _element: ElementRef<HTMLElement>,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _zone: NgZone,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_AUTOCOMPLETE_SCROLL_STRATEGY)
    scrollStrategy: () => ScrollStrategy,
    @Optional() private _dir: Directionality,
    @Optional() @Inject(DOCUMENT) private _document: Document,
    private _viewportRuler: ViewportRuler
  ) {
    this._scrollStrategy = scrollStrategy;
  }

  /** `View -> model callback called when value changes` */
  // eslint-disable-next-line no-empty,@typescript-eslint/no-empty-function
  _onChange: (value: unknown) => void = () => {};

  /** `View -> model callback called when autocomplete has been touched` */
  // eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
  _onTouched = (): void => {};

  @Input()
  set editorContentValue(value: string) {
    if (this._previousValue !== value) {
      this._previousValue = value;

      this._viewInitialised$.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
        this._onChange(value);
        if (this._canOpen()) {
          void this.openPanel();
        }
      });
    }
  }

  addKeyboardEvent(keyboardEvent: KeyboardEvent): void {
    this._handleKeydown(keyboardEvent);
  }

  addMouseEvent(mouseEvent: MouseEvent): void {
    if (mouseEvent.type === 'click') {
      this._handleFocus();
    }
  }

  /**
   * Whether the autocomplete is disabled. When disabled, the element will
   * act as a regular input and the user won't be able to open the panel.
   */
  @Input('matAutocompleteDisabled')
  get autocompleteDisabled(): boolean {
    return this._autocompleteDisabled;
  }
  set autocompleteDisabled(value: boolean) {
    this._autocompleteDisabled = coerceBooleanProperty(value);
  }

  ngAfterViewInit(): void {
    this._viewInitialised$.next();
    this._viewInitialised$.complete();

    const window = this._getWindow();

    if (typeof window !== 'undefined') {
      this._zone.runOutsideAngular(() =>
        window.addEventListener('blur', this._windowBlurHandler)
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['position' as keyof SimpleChanges] && this._positionStrategy) {
      this._setStrategyPositions(this._positionStrategy);

      if (this.panelOpen && this._overlayRef) {
        this._overlayRef.updatePosition();
      }
    }
  }

  ngOnDestroy(): void {
    const window = this._getWindow();

    if (typeof window !== 'undefined') {
      window.removeEventListener('blur', this._windowBlurHandler);
    }

    this._viewportSubscription.unsubscribe();
    this._componentDestroyed = true;
    this._destroyPanel();
    this._closeKeyEventStream.complete();

    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  /** Whether or not the autocomplete panel is open. */
  get panelOpen(): boolean {
    return this._overlayAttached && this.autocomplete.showPanel;
  }

  /** Opens the autocomplete suggestion panel. */
  async openPanel(): Promise<void> {
    await snapshot(this._viewInitialised$);
    this._attachOverlay();
  }

  /** Closes the autocomplete suggestion panel. */
  closePanel(): void {
    if (!this._overlayAttached) {
      return;
    }

    if (this.panelOpen) {
      // Only emit if the panel was visible.
      this.autocomplete.closed.emit();
    }

    this.autocomplete._isOpen = this._overlayAttached = false;

    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this._overlayRef.detach();
      this._closingActionsSubscription.unsubscribe();
    }

    // Note that in some cases this can end up being called after the component is destroyed.
    // Add a check to ensure that we don't try to run change detection on a destroyed view.
    if (!this._componentDestroyed) {
      // We need to trigger change detection manually, because
      // `fromEvent` doesn't seem to do it at the proper time.
      // This ensures that the label is reset when the
      // User clicks outside.
      this._changeDetectorRef.detectChanges();
    }
  }

  /**
   * Updates the position of the autocomplete suggestion panel to ensure that it fits all options
   * within the viewport.
   */
  updatePosition(): void {
    if (this._overlayAttached && this._overlayRef) {
      this._overlayRef.updatePosition();
    }
  }

  /**
   * A stream of actions that should close the autocomplete panel, including
   * when an option is selected, on blur, and when TAB is pressed.
   */
  get panelClosingActions(): Observable<MatOptionSelectionChange | undefined> {
    return merge(
      this.optionSelections,
      this.autocomplete._keyManager.tabOut.pipe(
        filter(() => this._overlayAttached)
      ),
      this._closeKeyEventStream,
      this._getOutsideClickStream(),
      this._overlayRef
        ? this._overlayRef
            .detachments()
            .pipe(filter(() => this._overlayAttached))
        : of()
    ).pipe(
      // Normalize the output so we return a consistent type.
      map((event) =>
        event instanceof MatOptionSelectionChange ? event : undefined
      )
    );
  }

  /** The currently active option, coerced to MatOption type. */
  get activeOption(): MatOption | undefined {
    if (this.autocomplete && this.autocomplete._keyManager) {
      return this.autocomplete._keyManager.activeItem || undefined;
    }

    return undefined;
  }

  _handleKeydown(event: KeyboardEvent): void {
    const keyCode = event.keyCode;

    // Prevent the default action on all escape key presses. This is here primarily to bring IE
    // in line with other browsers. By default, pressing escape on IE will cause it to revert
    // the input value to the one that it had on focus, however it won't dispatch any events
    // which means that the model value will be out of sync with the view.
    if (keyCode === ESCAPE && !hasModifierKey(event)) {
      event.preventDefault();
    }

    if (this.activeOption && keyCode === ENTER && this.panelOpen) {
      this.activeOption._selectViaInteraction();
      this._resetActiveItem();
      event.preventDefault();
    } else if (this.autocomplete) {
      const prevActiveItem = this.autocomplete._keyManager.activeItem;
      const isArrowKey = keyCode === UP_ARROW || keyCode === DOWN_ARROW;

      if (this.panelOpen || keyCode === TAB) {
        this.autocomplete._keyManager.onKeydown(event);
      } else if (isArrowKey && this._canOpen()) {
        void this.openPanel();
      }

      if (
        isArrowKey ||
        this.autocomplete._keyManager.activeItem !== prevActiveItem
      ) {
        this._scrollToOption(
          this.autocomplete._keyManager.activeItemIndex || 0
        );
      }
    }
  }

  _handleInput(event: KeyboardEvent): void {
    const target = event.target as HTMLInputElement;
    let value: number | string | undefined = target.value;

    // Based on `NumberValueAccessor` from forms.
    if (target.type === 'number') {
      value = value === '' ? undefined : parseFloat(value);
    }

    // If the input has a placeholder, IE will fire the `input` event on page load,
    // focus and blur, in addition to when the user actually changed the value. To
    // filter out all of the extra events, we save the value on focus and between
    // `input` events, and we check whether it changed.
    // See: https://connect.microsoft.com/IE/feedback/details/885747/
    if (this._previousValue !== value) {
      this._previousValue = value;
      this._onChange(value);

      if (this._canOpen() && this._document?.activeElement === event.target) {
        void this.openPanel();
      }
    }
  }

  _handleFocus(): void {
    if (!this._canOpenOnNextFocus) {
      this._canOpenOnNextFocus = true;
    } else if (this._canOpen()) {
      this._previousValue =
        this._element.nativeElement.textContent || undefined;
      this._attachOverlay();
    }
  }

  /** Stream of clicks outside of the autocomplete panel. */
  private _getOutsideClickStream(): Observable<MouseEvent | TouchEvent> {
    return merge(
      fromEvent(this._document, 'click') as Observable<MouseEvent>,
      fromEvent(this._document, 'touchend') as Observable<TouchEvent>
    ).pipe(
      filter((event) => {
        // If we're in the Shadow DOM, the event target will be the shadow root, so we have to
        // fall back to check the first element in the path of the click event.
        const clickTarget = (
          this._isInsideShadowRoot && event.composedPath
            ? event.composedPath()[0]
            : event.target
        ) as HTMLElement;
        const customOrigin = this.connectedTo
          ? this.connectedTo.elementRef.nativeElement
          : undefined;
        return (
          this._overlayAttached &&
          clickTarget !== this._element.nativeElement &&
          !this._element.nativeElement.contains(clickTarget) &&
          (!customOrigin || !customOrigin.contains(clickTarget)) &&
          !!this._overlayRef &&
          !this._overlayRef.overlayElement.contains(clickTarget)
        );
      })
    );
  }

  /**
   * This method listens to a stream of panel closing actions and resets the
   * stream every time the option list changes.
   */
  private _subscribeToClosingActions(): Subscription {
    const firstStable = this._zone.onStable.pipe(take(1));
    const optionChanges = this.autocomplete.options.changes.pipe(
      tap(() => this._positionStrategy.reapplyLastPosition()),
      // Defer emitting to the stream until the next tick, because changing
      // bindings in here will cause "changed after checked" errors.
      delay(0)
    );

    // When the zone is stable initially, and when the option list changes...
    return (
      merge(firstStable, optionChanges)
        .pipe(
          // create a new stream of panelClosingActions, replacing any previous streams
          // that were created, and flatten it so our stream only emits closing events...
          switchMap(() => {
            const wasOpen = this.panelOpen;
            this._resetActiveItem();
            this.autocomplete._setVisibility();

            if (this.panelOpen && this._overlayRef) {
              this._overlayRef.updatePosition();

              // If the `panelOpen` state changed, we need to make sure to emit the `opened`
              // event, because we may not have emitted it when the panel was attached. This
              // can happen if the users opens the panel and there are no options, but the
              // options come in slightly later or as a result of the value changing.
              if (wasOpen !== this.panelOpen) {
                this.autocomplete.opened.emit();
              }
            }

            return this.panelClosingActions;
          }),
          take(1),
          takeUntil(this._onDestroy$)
        )
        // Set the value, close the panel, and complete.
        .subscribe((event) => this._setValueAndClose(event))
    );
  }

  /** Destroys the autocomplete suggestion panel. */
  private _destroyPanel(): void {
    if (this._overlayRef) {
      this.closePanel();
      this._overlayRef.dispose();
      this._overlayRef = undefined;
    }
  }

  private _setTriggerValue(value: unknown): void {
    const toDisplay =
      this.autocomplete && this.autocomplete.displayWith
        ? this.autocomplete.displayWith(value)
        : value;

    // Simply falling back to an empty string if the display value is falsy does not work properly.
    // The display value can also be the number zero and shouldn't fall back to an empty string.
    const inputValue = toDisplay !== undefined ? String(toDisplay) : '';

    this._element.nativeElement.textContent = String(inputValue);
    this._previousValue = inputValue;
  }

  /**
   * This method closes the panel, and if a value is specified, also sets the associated
   * control to that value. It will also mark the control as dirty if this interaction
   * stemmed from the user.
   */
  private _setValueAndClose(event: MatOptionSelectionChange | undefined): void {
    if (event && event.source) {
      this._clearPreviousSelectedOption(event.source);
      this._setTriggerValue(event.source.value);
      this._onChange(event.source.value);
      this._element.nativeElement.focus();
      this.autocomplete._emitSelectEvent(event.source);
    }

    this.closePanel();
  }

  /**
   * Clear any previous selected option and emit a selection change event for this option
   */
  private _clearPreviousSelectedOption(skip: MatOption): void {
    this.autocomplete.options.forEach((option) => {
      if (option !== skip && option.selected) {
        option.deselect();
      }
    });
  }

  private _attachOverlay(): void {
    if (!this.autocomplete) {
      throw getMatAutocompleteMissingPanelError();
    }

    // We want to resolve this once, as late as possible so that we can be
    // sure that the element has been moved into its final place in the DOM.
    if (this._isInsideShadowRoot === undefined) {
      this._isInsideShadowRoot = !!_getShadowRoot(this._element.nativeElement);
    }

    let overlayRef = this._overlayRef;

    if (!overlayRef) {
      this._portal = new TemplatePortal(
        this.autocomplete.template,
        this._viewContainerRef
      );
      overlayRef = this._overlay.create(this._getOverlayConfig());
      this._overlayRef = overlayRef;

      // Use the `keydownEvents` in order to take advantage of
      // the overlay event targeting provided by the CDK overlay.
      overlayRef
        .keydownEvents()
        .pipe(takeUntil(this._onDestroy$))
        .subscribe((event) => {
          // Close when pressing ESCAPE or ALT + UP_ARROW, based on the a11y guidelines.
          // See: https://www.w3.org/TR/wai-aria-practices-1.1/#textbox-keyboard-interaction
          if (
            (event.keyCode === ESCAPE && !hasModifierKey(event)) ||
            (event.keyCode === UP_ARROW && hasModifierKey(event, 'altKey'))
          ) {
            this._resetActiveItem();
            this._closeKeyEventStream.next();

            // We need to stop propagation, otherwise the event will eventually
            // reach the input itself and cause the overlay to be reopened.
            event.stopPropagation();
            event.preventDefault();
          }
        });

      this._viewportSubscription = this._viewportRuler
        .change()
        .pipe(takeUntil(this._onDestroy$))
        .subscribe(() => {
          if (this.panelOpen && overlayRef) {
            overlayRef.updateSize({ width: this._getPanelWidth() });
          }
        });
    } else {
      // Update the trigger, panel width and direction, in case anything has changed.
      this._positionStrategy.setOrigin(this._getConnectedElement());
      overlayRef.updateSize({ width: this._getPanelWidth() });
    }

    if (overlayRef && !overlayRef.hasAttached()) {
      overlayRef.attach(this._portal);
      this._closingActionsSubscription = this._subscribeToClosingActions();
    }

    const wasOpen = this.panelOpen;

    this.autocomplete._setVisibility();
    this.autocomplete._isOpen = this._overlayAttached = true;

    // We need to do an extra `panelOpen` check in here, because the
    // autocomplete won't be shown if there are no options.
    if (this.panelOpen && wasOpen !== this.panelOpen) {
      this.autocomplete.opened.emit();
    }
  }

  private _getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this._getOverlayPosition(),
      scrollStrategy: this._scrollStrategy(),
      width: this._getPanelWidth(),
      direction: this._dir,
    });
  }

  private _getOverlayPosition(): PositionStrategy {
    const strategy = this._overlay
      .position()
      .flexibleConnectedTo(this._getConnectedElement())
      .withFlexibleDimensions(false)
      .withPush(false);

    this._setStrategyPositions(strategy);
    this._positionStrategy = strategy;
    return strategy;
  }

  /** Sets the positions on a position strategy based on the directive's input state. */
  private _setStrategyPositions(
    positionStrategy: FlexibleConnectedPositionStrategy
  ): void {
    // Note that we provide horizontal fallback positions, even though by default the dropdown
    // width matches the input, because consumers can override the width. See #18854.
    const belowPositions: ConnectedPosition[] = [
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      },
      { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
    ];

    // The overlay edge connected to the trigger should have squared corners, while
    // the opposite end has rounded corners. We apply a CSS class to swap the
    // border-radius based on the overlay position.
    const panelClass = this._aboveClass;
    const abovePositions: ConnectedPosition[] = [
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        panelClass,
      },
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom',
        panelClass,
      },
    ];

    let positions: ConnectedPosition[];

    if (this.position === 'above') {
      positions = abovePositions;
    } else if (this.position === 'below') {
      positions = belowPositions;
    } else {
      positions = [...belowPositions, ...abovePositions];
    }

    positionStrategy.withPositions(positions);
  }

  private _getConnectedElement(): ElementRef<HTMLElement> {
    if (this.connectedTo) {
      return this.connectedTo.elementRef;
    }

    return this._element;
  }

  private _getPanelWidth(): number | string {
    return this.autocomplete.panelWidth || this._getHostWidth();
  }

  /** Returns the width of the input element, so the panel width can match it. */
  private _getHostWidth(): number {
    return this._getConnectedElement().nativeElement.getBoundingClientRect()
      .width;
  }

  /**
   * Resets the active item to -1 so arrow events will activate the
   * correct options, or to 0 if the consumer opted into it.
   */
  private _resetActiveItem(): void {
    this.autocomplete._keyManager.setActiveItem(
      this.autocomplete.autoActiveFirstOption ? 0 : -1
    );
  }

  /** Determines whether the panel can be opened. */
  private _canOpen(): boolean {
    // const element = this._element.nativeElement;
    return !this._autocompleteDisabled;
    // !element.readOnly && !element.disabled && !this._autocompleteDisabled
  }

  /** Use defaultView of injected document if available or fallback to global window reference */
  private _getWindow(): Window {
    return this._document?.defaultView || window;
  }

  /** Scrolls to a particular option in the list. */
  private _scrollToOption(index: number): void {
    // Given that we are not actually focusing active options, we must manually adjust scroll
    // to reveal options below the fold. First, we find the offset of the option from the top
    // of the panel. If that offset is below the fold, the new scrollTop will be the offset -
    // the panel height + the option height, so the active option will be just visible at the
    // bottom of the panel. If that offset is above the top of the visible panel, the new scrollTop
    // will become the offset. If that offset is visible within the panel already, the scrollTop is
    // not adjusted.
    const autocomplete = this.autocomplete;
    const labelCount = _countGroupLabelsBeforeOption(
      index,
      autocomplete.options,
      autocomplete.optionGroups
    );

    if (index === 0 && labelCount === 1) {
      // If we've got one group label before the option and we're at the top option,
      // scroll the list to the top. This is better UX than scrolling the list to the
      // top of the option, because it allows the user to read the top group's label.
      autocomplete._setScrollTop(0);
    } else {
      const option = autocomplete.options.toArray()[index];

      if (option) {
        const element = option._getHostElement();
        const newScrollPosition = _getOptionScrollPosition(
          element.offsetTop,
          element.offsetHeight,
          autocomplete._getScrollTop(),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          toInt(autocomplete.panel.nativeElement.offsetHeight)
        );

        autocomplete._setScrollTop(newScrollPosition);
      }
    }
  }

  /**
   * Event handler for when the window is blurred. Needs to be an
   * arrow function in order to preserve the context.
   */
  private _windowBlurHandler(): void {
    // If the user blurred the window while the autocomplete is focused, it means that it'll be
    // refocused when they come back. In this case we want to skip the first focus event, if the
    // pane was closed, in order to avoid reopening it unintentionally.
    this._canOpenOnNextFocus =
      this._document?.activeElement !== this._element?.nativeElement ||
      this.panelOpen;
  }
}
