<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>General</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <pr-general-configuration
        [treatmentConfig]="treatmentConfig$ | async"
        [treatmentCategories]="globalStore.treatmentCategories$ | async"
        (treatmentConfigChange)="pendingGeneralConfig = $event"
      />
      <pt-buttons-container>
        <button mat-stroked-button color="warn" (click)="delete()">
          Delete
        </button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="!pendingGeneralConfig"
          (click)="save()"
        >
          Save
        </button>
      </pt-buttons-container>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Charting Options</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <pr-treatment-charting-options
        [treatmentConfig]="treatmentConfig$ | async"
      />
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Conditions</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <pr-condition-selector [treatmentConfig]="treatmentConfig$ | async" />
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Service Item Codes</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <pr-item-code-configuration
        [treatmentConfig]="treatmentConfig$ | async"
      />
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Packages</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <pr-treatment-packages [treatmentConfig]="treatmentConfig$ | async" />
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Automations</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <pr-treatment-automation-configuration
        [treatmentConfig]="treatmentConfig$ | async"
        [templateScope]="automatedNotificationScope"
      />
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Pre & Post Checklist Items</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <pr-checklist-configuration
        [treatmentConfig]="treatmentConfig$ | async"
      />
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel
    *ptFeatureEnabled="'staff.configurations.treatments.assets'"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>Equipment & Instruments</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <pr-treatment-assets [treatmentConfig]="treatmentConfig$ | async" />
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Educational Aids</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <pr-educational-aids [treatmentConfig]="treatmentConfig$ | async" />
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
