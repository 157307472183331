import { ElementRef } from '@angular/core';
import {
  IStaffer,
  isChatMessage,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, isSameRef, snapshot } from '@principle-theorem/shared';
import { last } from 'lodash';
import { BehaviorSubject, Observable, fromEvent, noop } from 'rxjs';
import {
  concatMap,
  debounceTime,
  filter,
  map,
  pairwise,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { ChatActions } from './chat-actions';

export class ChatMessagesBloc {
  private _scrollThreshold = 50;
  messagesLoaded$ = new BehaviorSubject<boolean>(false);
  messagesUpdated$ = new BehaviorSubject<boolean>(false);
  newMessagesAvailable$ = new BehaviorSubject<boolean>(false);
  scrollContainer: ElementRef<HTMLElement>;

  constructor(
    public actions: ChatActions,
    staffer$: Observable<WithRef<IStaffer>>,
    private _onDestroy$: Observable<void>
  ) {
    this.messagesLoaded$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((loaded) => {
        if (loaded) {
          this.scrollToBottom();
        }
      });

    this.actions.messages$
      .pipe(
        map((messages) => last(messages)),
        pairwise(),
        withLatestFrom(staffer$),
        filter(([[oldLastMessage, newLastMessage], staffer]) => {
          if (isSameRef(oldLastMessage, newLastMessage)) {
            return false;
          }

          if (isSameRef(newLastMessage?.authorRef, staffer)) {
            return false;
          }

          return true;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.newMessagesAvailable$.next(true));

    this.messagesUpdated$
      .pipe(
        filter((updated) => updated),
        withLatestFrom(this.actions.content$, staffer$),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([_, content, staffer]) => {
        const lastMessage = content[content.length - 1];

        const latestMessageIsFromStaffer =
          isChatMessage(lastMessage) &&
          isSameRef(lastMessage.authorRef, staffer);

        if (latestMessageIsFromStaffer) {
          this.scrollToBottom();
        }

        this.messagesUpdated$.next(false);
      });
  }

  viewNewMessages(): void {
    this.scrollToBottom();
    this.newMessagesAvailable$.next(false);
  }

  async loadMoreMessages(): Promise<void> {
    const currentScrollHeight = this.scrollContainer.nativeElement.scrollHeight;
    const currentScrollTop = this.scrollContainer.nativeElement.scrollTop;

    this.actions.loadMoreMessages();
    await snapshot(this.messagesUpdated$.pipe(filter((updated) => updated)));

    const newScrollHeight = this.scrollContainer.nativeElement.scrollHeight;
    const heightDifference = newScrollHeight - currentScrollHeight;

    this.scrollContainer.nativeElement.scrollTop =
      currentScrollTop + heightDifference;
  }

  loadMoreOnScroll(): void {
    fromEvent(this.scrollContainer.nativeElement, 'scroll')
      .pipe(
        debounceTime(150),
        map(() => this.scrollContainer.nativeElement.scrollTop),
        withLatestFrom(this.actions.canLoadMore$, this.actions.loading$),
        filter(
          ([current, canLoadMore, loading]) =>
            current === 0 && canLoadMore && !loading
        ),
        concatMap(() => this.loadMoreMessages()),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);
  }

  clearNewMessagesOnScroll(): void {
    fromEvent(this.scrollContainer.nativeElement, 'scroll')
      .pipe(
        debounceTime(150),
        filter(() => {
          const { scrollHeight, scrollTop, clientHeight } =
            this.scrollContainer.nativeElement;
          return (
            scrollHeight - scrollTop - clientHeight < this._scrollThreshold
          );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.newMessagesAvailable$.next(false));
  }

  scrollToBottom(): void {
    try {
      if (!this.scrollContainer) {
        return;
      }
      this.scrollContainer.nativeElement.scrollTop =
        this.scrollContainer.nativeElement.scrollHeight;
    } catch (err) {
      // Do nada
    }
  }
}
