import { Component } from '@angular/core';
import { type Data } from '@angular/router';
import { BaseTabsComponent } from '@principle-theorem/ng-principle-shared';
import {
  type IBreadcrumb,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { type IContact } from '@principle-theorem/principle-core/interfaces';
import { get } from 'lodash';

@Component({
    selector: 'pr-contact-tabs',
    templateUrl: './contact-tabs.component.html',
    styleUrls: ['./contact-tabs.component.sass'],
    standalone: false
})
export class ContactTabsComponent extends BaseTabsComponent<IContact> {
  trackByLink = TrackByFunctions.label<IBreadcrumb>();
  override breadcrumbs: IBreadcrumb[] = [{ label: 'Contacts', path: ['../'] }];
  override navLinks: IBreadcrumb[] = [
    { label: 'Profile', path: ['./profile'] },
    { label: 'Members', path: ['./members'] },
    { label: 'History', path: ['./history'] },
  ];

  setItem(data: Data): void {
    this.item = get(data, 'contact') as IContact;
  }
}
