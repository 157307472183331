import {
  Directive,
  ElementRef,
  Host,
  HostListener,
  type OnInit,
} from '@angular/core';
import { MatExpansionPanelHeader } from '@angular/material/expansion';
import { EXPANSION_DISABLE_CLASS } from './expansion-disable.directive';

@Directive({
  selector: '[prEnableExpansionPanelHeaderActions]',
})
export class EnableExpansionPanelHeaderActionsDirective implements OnInit {
  isClicked = false;
  shouldOpen = true;

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    @Host() private _expansionPanelHeader: MatExpansionPanelHeader
  ) {}

  ngOnInit(): void {
    this._expansionPanelHeader._toggle = (): void => {
      // Don't do nothin'
    };
  }

  @HostListener('click', ['$event.target'])
  onClick(targetElement: Element): void {
    let currentElement: Element | HTMLElement | null = targetElement;
    // eslint-disable-next-line no-null/no-null
    while (currentElement !== null) {
      if (currentElement.classList.contains(EXPANSION_DISABLE_CLASS)) {
        this.shouldOpen = false;
        break;
      }
      this.shouldOpen = true;
      currentElement = currentElement.parentElement;
    }

    const clickedInside: boolean =
      this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.isClicked = false;
      return;
    }
    this.isClicked = true;

    if (this.shouldOpen && this.isClicked) {
      this._expansionPanelHeader.panel.toggle();
    }
  }
}
