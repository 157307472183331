import {
  type IBrand,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  FirestoreTransactionRunner,
  asyncForEach,
  isSameRef,
  multiFilter,
  multiMergeMap,
  multiSwitchMap,
  overlappingChunkRange,
  toISODate,
  undeletedQuery,
  type DocumentReference,
  type ITimePeriod,
} from '@principle-theorem/shared';
import { compact, uniqWith } from 'lodash';
import { from, iif, of } from 'rxjs';
import { Brand } from '../models/brand';
import { OrganisationCache } from '../models/organisation/organisation-cache';
import { ScheduleSummaryEventUpdater } from '../models/schedule-summary/schedule-summary-event-updater';
import { ScheduleSummaryHelpers } from '../models/schedule-summary/schedule-summary-helpers';
import { Roster } from '../models/staffer/roster';
import { ILogger } from '@principle-theorem/developer-tools';

export class ResyncScheduleSummary {
  constructor(private _logger: ILogger) {}

  async run(
    brandRef: DocumentReference<IBrand>,
    range: ITimePeriod,
    stafferRefs?: DocumentReference<IStaffer>[]
  ): Promise<void> {
    const allStaff$ = from(
      Firestore.getDocs(Brand.stafferCol({ ref: brandRef }))
    );
    const filteredStaff$ = of(compact(stafferRefs)).pipe(
      multiSwitchMap((stafferRef) => Firestore.getDoc(stafferRef))
    );

    await iif(() => !!stafferRefs, filteredStaff$, allStaff$)
      .pipe(
        multiFilter((staffer) => !staffer.deleted),
        multiMergeMap(50, async (staffer) => {
          const schedules = await Firestore.getDocs(
            undeletedQuery(Roster.col({ ref: staffer.ref }))
          );
          if (!schedules.length) {
            return;
          }

          const practiceRefs = uniqWith(
            schedules.map((schedule) => schedule.item.event.practice.ref),
            isSameRef
          );

          await asyncForEach(practiceRefs, async (practiceRef) => {
            const practice =
              await OrganisationCache.practices.getDoc(practiceRef);

            await asyncForEach(
              overlappingChunkRange(range, 1, 'day'),
              async (day) =>
                FirestoreTransactionRunner.run(async ({ transaction }) => {
                  this._logger.info(
                    `Resyncing Staffer ${staffer.user.name} ${toISODate(
                      day.from
                    )}`
                  );

                  const aggregate =
                    await ScheduleSummaryHelpers.rebuildAggregate(
                      day.from,
                      practice,
                      staffer,
                      transaction
                    );

                  await ScheduleSummaryEventUpdater.upsertAggregate(
                    transaction,
                    aggregate
                  );
                })
            );
          });
        })
      )
      .toPromise();
  }
}
