<div class="flex flex-col gap-2 p-2">
  <div class="absolute left-0 top-0 h-1 w-full">
    @if (saving$ | async) {
      <mat-progress-bar mode="indeterminate" color="primary" />
    }
  </div>
  @if (cameraAccessDenied$ | async) {
    <div
      class="flex flex-row items-center gap-4 rounded bg-red-500 p-2 text-red-50"
    >
      <mat-icon>videocam_off</mat-icon>

      <div class="flex flex-col">
        <div class="text-sm font-bold">
          Principle cannot access your Camera.
        </div>
        <div class="text-xs">
          Access to your camera has been denied. Update the permissions in your
          browser, then refresh the page.
        </div>
      </div>
    </div>
  }

  <div class="bg-black">
    <webcam
      class="flex"
      #webcam
      [allowCameraSwitch]="true"
      [mirrorImage]="mirrorImage$ | async"
      (imageCapture)="imageCapture$.next($event)"
      (initError)="handleInitError($event)"
      (cameraSwitched)="videoInputCtrl.setValue($event)"
    />
  </div>

  <div class="flex flex-row gap-1">
    <mat-form-field subscriptSizing="dynamic" class="flex w-1/2">
      <mat-label>Select Camera</mat-label>
      <mat-select
        [formControl]="videoInputCtrl"
        [disabled]="cameraAccessDenied$ | async"
      >
        @for (option of videoInputs$ | async; track option.deviceId) {
          <mat-option [value]="option.deviceId">
            {{ option.label }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" class="flex w-1/2">
      <mat-label>Mirror Image</mat-label>
      <mat-select
        [formControl]="mirrorImageCtrl"
        [disabled]="cameraAccessDenied$ | async"
      >
        @for (option of mirrorImageOptions; track option.label) {
          <mat-option [value]="option.value"> {{ option.label }} </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <div class="flex flex-row gap-1">
    <button
      mat-stroked-button
      class="flex w-1/2"
      [disabled]="saving$ | async"
      (click)="close$.next()"
    >
      Close
    </button>

    <button
      class="flex w-1/2"
      mat-flat-button
      color="primary"
      [disabled]="captureDisabled$ | async"
      (click)="capture()"
    >
      Capture
    </button>
  </div>
</div>
