import {
  CanBeChartedProperty,
  IChartBuilderData,
} from '@principle-theorem/reporting';
import { type ISODateType, type ISoftDelete } from '@principle-theorem/shared';
import { type ComparableValue } from 'crossfilter2';
import { isString } from 'lodash';
import { type RestrictAccessEntity } from '../auth/restrict-access';
import { type MeasureReducer } from './measures';
import { type IQueryScopeRequests } from './query-scopes';

// TODO: Stronger Type Enforcement of these
export type ReportingReference = string;
export const isReportingReference = isString;

export interface ICustomReportColumn {
  uid: string;
  id: ReportingReference;
  label?: string;
}

export interface ICustomReportDefinedColumn extends ICustomReportColumn {
  measure: CanBeChartedProperty;
}

export type DateRangeFilter = [ISODateType, ISODateType];
export interface ICustomReportFilterValue {
  label: string;
  value: string | number | DateRangeFilter;
}

export interface ICustomReportFilter {
  id: ReportingReference;
  values: ICustomReportFilterValue[];
}

export interface IBaseChartBuilderConfig {
  height: number;
  width: number;
  disableControls?: boolean;
  numberOfTicks?: number;
  rotateXAxisLabels?: boolean;
}

export interface ICustomReportChart<
  T extends ICustomChartSettings = ICustomChartSettings,
  Config extends IBaseChartBuilderConfig = IBaseChartBuilderConfig,
> {
  uid: string;
  type: CustomChartType;
  label: string;
  groupBy: ReportingReference;
  settings: T[];
  builderData?: Partial<
    Omit<IChartBuilderData, 'measures' | 'groupByDimension'>
  >;
  builderConfig?: Partial<Config>;
}

export interface ICustomChartSettings {
  uid: string;
  label: string;
  dataPoint: ReportingReference;
  reducer: MeasureReducer;
  filters?: ComparableValue[];
}

export interface ICustomReportChartSection {
  uid: string;
  name: string;
  charts: ICustomReportChart<ICustomChartSettings>[];
}

export interface ICustomReportData {
  staticFilters?: ICustomReportFilter[];
  chartSections?: ICustomReportChartSection[];
  columns?: ICustomReportColumn[];
}

export interface ICustomReport
  extends Required<ICustomReportData>,
    ISoftDelete {
  name: string;
  description?: string;
  restrictAccessTo: RestrictAccessEntity[];
  dataSource: ReportBuilderDataSourceId;
  queryScopes: IQueryScopeRequests;
}

export enum CustomChartType {
  NumberSummary = 'numberSummary',
  Table = 'table',
  Line = 'line',
  Pie = 'pie',
  Bar = 'bar',
  Row = 'row',
  Column = 'column',
  SelectMenu = 'selectMenu',
  StackedArea = 'stackedArea',
  Histogram = 'histogram',
  StackedBar = 'stackedBar',
  SanKey = 'sanKey',
}

export const ENABLED_CHART_TYPES = [
  CustomChartType.NumberSummary,
  CustomChartType.Table,
  CustomChartType.Pie,
  CustomChartType.Row,
  CustomChartType.Column,
  CustomChartType.SelectMenu,
];

export enum ReportBuilderDataSourceId {
  AccountCredits = 'accountCredit',
  Appointments = 'appointments',
  Invoices = 'invoices',
  Patients = 'patients',
  PatientInteractions = 'patientInteractions',
  PractitionerIncome = 'practitionerIncome',
  Scheduling = 'scheduling',
  Transactions = 'transactions',
  TreatmentStepServiceCodes = 'treatmentStepServiceCodes',
  TreatmentStepTreatments = 'treatmentStepTreatments',
  SchedulingEvents = 'schedulingEvents',
}

export const RETIRED_REPORT_BUILDER_DATA_SOURCE_IDS: ReportBuilderDataSourceId[] =
  [ReportBuilderDataSourceId.PractitionerIncome];
