import { BlockNodes, type IMenuButton } from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../menu-bar/basic-menu-button/basic-menu-button.component';

const data: IMenuButton = {
  buttonType: 'block',
  buttonText: 'Layout Column',
  tooltip: 'Layout Column',
  icon: 'view_column',
  command: (editor) => editor.chain().focus().setColumns(3).run(),
  isActiveFn: (editor) => editor.isActive(BlockNodes.Column),
};

export function createLayoutColumnMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      ...data,
      buttonType: 'submenu',
      compact: false,
      submenuItems: createLayoutColumnSubmenuButtons(),
    },
  });
}

const columns = [
  {
    count: 2,
    icon: 'vertical_split',
  },
  {
    count: 3,
    icon: 'view_column',
  },
  {
    count: 4,
    icon: 'calendar_view_week',
  },
];

export function createLayoutColumnBlockMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
>[] {
  return columns.map((column) => () => ({
    component: BasicMenuButtonComponent,
    data: {
      ...data,
      buttonType: 'block',
      buttonText: `${column.count} Columns`,
      tooltip: `${column.count} Columns`,
      shortcut: `Mod-Ctrl-${column.count}`,
      icon: column.icon,
      command: (editor) =>
        editor.chain().focus().setColumns(column.count).focus().run(),
    },
  }));
}

export function createLayoutColumnSubmenuButtons(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
>[] {
  return columns.map((column) => () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'submenu-detail',
      textOnly: true,
      buttonText: `${column.count} Columns`,
      tooltip: `${column.count} Columns`,
      shortcut: `Mod-Ctrl-${column.count}`,
      icon: column.icon,
      command: (editor) =>
        editor.chain().focus().setColumns(column.count).focus().run(),
    },
  }));
}
