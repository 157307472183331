import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DISABLE_ENTER_EXTENSION,
  MixedSchema,
  getSchemaSize,
  initVersionedSchema,
} from '@principle-theorem/editor';
import { EditorPresetsService } from '@principle-theorem/ng-interactions';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { TypedFormControl } from '@principle-theorem/ng-shared';
import { Chat } from '@principle-theorem/principle-core';
import {
  IChat,
  IPractice,
  type IBrand,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  filterUndefined,
  patchDoc,
  shareReplayCold,
  snapshot,
  snapshotDefined,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { AnyExtension } from '@tiptap/core';
import { ReplaySubject, type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { type IChatSummary } from '../../pages/chats-dashboard/chats-dashboard.store';

@Component({
    selector: 'pr-chat-footer',
    templateUrl: './chat-footer.component.html',
    styleUrls: ['./chat-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChatFooterComponent {
  noteCtrl = new TypedFormControl<MixedSchema>(
    initVersionedSchema(),
    Validators.required
  );
  isDisabled$: Observable<boolean>;
  brand$: Observable<WithRef<IBrand>>;
  practice$: Observable<WithRef<IPractice>>;
  summary$ = new ReplaySubject<IChatSummary>(1);
  extensions: AnyExtension[];
  @Input() compact: boolean = false;

  @Input()
  set summary(summary: IChatSummary) {
    if (summary) {
      this.summary$.next(summary);
    }
  }

  constructor(
    private _organisation: OrganisationService,
    private _currentScope: CurrentScopeFacade,
    private _snackBar: MatSnackBar,
    private _editorPresets: EditorPresetsService
  ) {
    this.brand$ = this._currentScope.currentBrand$.pipe(filterUndefined());
    this.practice$ =
      this._currentScope.currentPractice$.pipe(filterUndefined());

    this.isDisabled$ = this.noteCtrl.statusChanges.pipe(
      startWith(undefined),
      map(
        () =>
          !this.noteCtrl.valid ||
          !this.noteCtrl.dirty ||
          !getSchemaSize(this.noteCtrl.value)
      ),
      shareReplayCold()
    );
    this.extensions = [
      DISABLE_ENTER_EXTENSION,
      ...this._editorPresets.defaultExtensions(),
    ];
  }

  async addNote(chat: WithRef<IChat>): Promise<void> {
    if (await snapshot(this.isDisabled$)) {
      return;
    }

    const content = this.noteCtrl.value;
    if (!content) {
      this.noteCtrl.reset(initVersionedSchema(), { emitEvent: false });
      return;
    }

    this.noteCtrl.reset(initVersionedSchema(), { emitEvent: false });
    this.noteCtrl.updateValueAndValidity();
    this._snackBar.open('Message Sent');

    const staffer = await snapshotDefined(this._organisation.staffer$);
    const practice = await snapshot(this.practice$);

    const lastMessageRef = await addDoc(Chat.chatMessageCol(chat), {
      content,
      authorRef: staffer.ref,
      practiceRef: practice.ref,
      readBy: [staffer.ref],
      reactions: [],
      deleted: false,
    });

    await patchDoc(chat.ref, {
      lastMessage: lastMessageRef,
      lastMessageAt: toTimestamp(),
    });
  }
}
