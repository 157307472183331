import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  type OnDestroy,
} from '@angular/core';
import { TimezoneService } from '@principle-theorem/ng-principle-shared';
import {
  type ITimelineDisplayOptions,
  type ITimelineDisplayRange,
  TimelineDisplayOrientation,
} from '@principle-theorem/principle-core/interfaces';
import {
  type ITimePeriod,
  MILLIS_IN_MINUTE,
  TIME_FORMAT,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import {
  combineLatest,
  type Observable,
  ReplaySubject,
  Subject,
  timer,
} from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { InteractiveTimelineDisplayCalculator } from '../interactive-timeline/interactive-timeline-display-calculator';

@Component({
    selector: 'pr-interactive-timeline-nowline',
    templateUrl: './interactive-timeline-nowline.component.html',
    styleUrls: ['./interactive-timeline-nowline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InteractiveTimelineNowlineComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _element: HTMLElement;
  private _options$ = new ReplaySubject<ITimelineDisplayOptions>(1);
  private _day$ = new ReplaySubject<ITimePeriod>(1);
  private _displayRange$ = new ReplaySubject<ITimelineDisplayRange>(1);
  readonly timeFormat = TIME_FORMAT;
  nowline$: Observable<{ right: string; height: string }>;
  currentTime$: Observable<moment.Moment>;
  @HostBinding('class.horizontal') isHorizontal = true;
  @HostBinding('class.vertical') isVertical = false;

  @Input()
  set options(options: ITimelineDisplayOptions) {
    if (options) {
      this._options$.next(options);
    }
  }

  @Input()
  set day(day: ITimePeriod) {
    if (day) {
      this._day$.next(day);
    }
  }

  @Input()
  set displayRange(displayRange: ITimelineDisplayRange) {
    if (displayRange) {
      this._displayRange$.next(displayRange);
    }
  }

  constructor(
    elementRef: ElementRef,
    private _timezoneService: TimezoneService
  ) {
    this._element = elementRef.nativeElement as HTMLElement;
    this.currentTime$ = this._getCurrentTime$();
    combineLatest([
      this._options$,
      this.currentTime$,
      this._day$,
      this._displayRange$,
    ])
      .pipe(
        map(([options, currentTime, day, displayRange]) => ({
          options,
          offset: this._getOffset(options, displayRange, currentTime, day),
        })),
        takeUntil(this._onDestroy$)
      )
      .subscribe(({ options, offset }) =>
        this._updatePosition(options, offset)
      );
    this._options$
      .pipe(
        map(InteractiveTimelineDisplayCalculator.isHorizontal),
        takeUntil(this._onDestroy$)
      )
      .subscribe((isHorizontal) => {
        this.isHorizontal = isHorizontal;
        this.isVertical = !isHorizontal;
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _getCurrentTime$(): Observable<moment.Moment> {
    return timer(moment().endOf('minute').toDate(), MILLIS_IN_MINUTE).pipe(
      map(() => moment()),
      startWith(moment()),
      switchMap((time) =>
        this._timezoneService.currentPractice.applyToMoment$(time)
      )
    );
  }

  private _updatePosition(
    options: ITimelineDisplayOptions,
    position?: number
  ): void {
    const isHorizontal =
      options.orientation === TimelineDisplayOrientation.Horizontal;
    const xPos = isHorizontal && position ? position : 0;
    const yPos = !isHorizontal && position ? position : 0;
    Object.assign(this._element.style, {
      display: position === undefined ? 'none' : 'block',
      transform: `translate(${xPos}px, ${yPos}px)`,
    });
  }

  private _getOffset(
    options: ITimelineDisplayOptions,
    range: ITimelineDisplayRange,
    currentTime: moment.Moment,
    day: ITimePeriod
  ): number | undefined {
    const isOnDay = InteractiveTimelineDisplayCalculator.isWithinTimelineDay(
      range,
      currentTime,
      day.from
    );
    if (!isOnDay) {
      return;
    }
    return InteractiveTimelineDisplayCalculator.timeToPosition(
      options,
      range.timeRange,
      currentTime,
      day.from
    );
  }
}
