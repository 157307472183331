import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  GapStoreService,
  IGapDayPair,
} from '@principle-theorem/ng-principle-shared';
import { IScheduleSummaryEventable } from '@principle-theorem/principle-core/interfaces';
import { DATE_WITH_YEAR, toMoment } from '@principle-theorem/shared';
import { sum } from 'lodash';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'pr-gap-item-list',
  templateUrl: './gap-item-list.component.html',
  styleUrls: ['./gap-item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GapItemListComponent {
  readonly dateFormat = DATE_WITH_YEAR;
  groupedGaps$ = new ReplaySubject<IGapDayPair[]>(1);
  gapCount$: Observable<number>;
  nextAvailable$: Observable<IScheduleSummaryEventable>;

  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  @Input()
  set groupedGaps(groupedGaps: IGapDayPair[]) {
    if (groupedGaps) {
      this.groupedGaps$.next(groupedGaps);
    }
  }

  constructor(public gapStore: GapStoreService) {
    this.nextAvailable$ = this.gapStore.gaps$.pipe(
      filter((gaps) => gaps.length > 0),
      map((gaps) =>
        gaps.reduce((acc, curr) =>
          toMoment(acc.event.from).isBefore(toMoment(curr.event.from))
            ? acc
            : curr
        )
      )
    );

    this.gapCount$ = this.groupedGaps$.pipe(
      map((groupedGaps) => sum(groupedGaps.map((group) => group.gaps.length)))
    );
  }
}
