import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import {
  CurrentBrandScope,
  DateService,
} from '@principle-theorem/ng-principle-shared';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { Appointment, Brand } from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type ILab,
  type ILabJob,
  type ILabJobType,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  type WithRef,
  filterNil,
  filterUndefined,
  getDoc,
  guardFilter,
  isINamedDocument,
  isSameRef,
  query$,
  toMoment,
  toNamedDocument,
} from '@principle-theorem/shared';
import { orderBy, startAt } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import {
  BehaviorSubject,
  type Observable,
  type OperatorFunction,
  ReplaySubject,
  Subject,
  of,
} from 'rxjs';
import {
  concatMap,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { getNewDueDate } from './lab-job-form-helpers';
import { type ILabJobFormData, LabJobFormGroup } from './lab-job.formgroup';

@Component({
    selector: 'pr-lab-job-form',
    templateUrl: './lab-job-form.component.html',
    styleUrls: ['./lab-job-form.component.scss'],
    exportAs: 'prLabJobForm',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [...MOMENT_DATEPICKER_PROVIDERS],
    standalone: false
})
export class LabJobFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  labJob$ = new ReplaySubject<WithRef<ILabJob>>(1);
  trackByPatient = TrackByFunctions.ref<WithRef<IPatient>>();
  trackByAppointment = TrackByFunctions.ref<WithRef<IAppointment>>();
  trackByLab = TrackByFunctions.ref<WithRef<ILab>>();
  trackByLabJobType = TrackByFunctions.field<ILabJobType>('name');
  labJobFormGroup: LabJobFormGroup = new LabJobFormGroup();
  labs$: Observable<WithRef<ILab>[]>;
  labJobTypes$ = new BehaviorSubject<ILabJobType[]>([]);
  patientAppointments$ = new ReplaySubject<WithRef<IAppointment>[]>(1);
  @Output() submitted = new EventEmitter<ILabJobFormData>();
  @Output() cancelled = new EventEmitter<void>();

  @Input() showActions = true;
  @Input() isEdit = false;

  @Input()
  set labJob(labJob: WithRef<ILabJob>) {
    if (labJob) {
      this.labJob$.next(labJob);
    }
  }

  @Input()
  set lab(lab: WithRef<ILab>) {
    if (lab) {
      this.labJobFormGroup.controls.lab.setValue(lab);
      this.labJobFormGroup.controls.type.setValue(lab.labJobTypes[0]);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.labJobFormGroup.controls.patient.setValue(toNamedDocument(patient));
    }
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.labJobFormGroup.controls.appointment.setValue(appointment);
    }
  }

  constructor(
    private _brandScope: CurrentBrandScope,
    public dateService: DateService
  ) {
    this.labs$ = this._brandScope.doc$.pipe(
      filterUndefined(),
      switchMap((brand) => Brand.labs$(brand))
    );

    this.labJob$
      .pipe(
        take(1),
        concatMap((labJob) => this._toLabJobFormData(labJob)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((labJob) => this.labJobFormGroup.patchFromLabJob(labJob));

    this.labJobFormGroup.controls.patient.valueChanges
      .pipe(
        this._getPatientAppointments(),
        tap((appointments) => {
          const control = this.labJobFormGroup.controls.appointment;
          appointments.length ? control.enable() : control.disable();
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((appointments) =>
        this.patientAppointments$.next(appointments)
      );

    this.labJobFormGroup.controls.lab.valueChanges
      .pipe(
        guardFilter(isINamedDocument),
        switchMap((labDoc) => {
          return this.labs$.pipe(
            map((labs) => labs.filter((lab) => isSameRef(lab, labDoc))),
            map((labs) => labs[0].labJobTypes)
          );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((types) => {
        this.labJobTypes$.next(types);
        const jobTypeControl = this.labJobFormGroup.controls.type;
        if (!jobTypeControl.value) {
          jobTypeControl.setValue(types[0]);
        }
      });

    this.labJobFormGroup.controls.type.valueChanges
      .pipe(filterNil(), takeUntil(this._onDestroy$))
      .subscribe((jobType) => {
        if (this.isEdit) {
          return;
        }
        this.labJobFormGroup.controls.cost.setValue(jobType.cost);
      });

    this.labJobFormGroup.controls.appointment.valueChanges
      .pipe(
        filterNil(),
        withLatestFrom(this.labJob$),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([appointment, labJob]) => {
        const newDueDate = getNewDueDate(appointment, labJob);
        if (newDueDate) {
          this.labJobFormGroup.controls.dueDate.setValue(newDueDate);
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  save(): void {
    if (this.labJobFormGroup.invalid) {
      return;
    }
    this.submitted.emit(this.labJobFormGroup.getRawValue());
    this.labJobFormGroup.markAsPristine();
  }

  clearDueDate(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.labJobFormGroup.controls.dueDate.reset();
  }

  displayFn(patient?: WithRef<IPatient>): string {
    return patient ? patient.name : '';
  }

  isSelectedAppointment(
    appointment?: WithRef<IAppointment>,
    selectedAppointment?: WithRef<IAppointment>
  ): boolean {
    return isSameRef(appointment, selectedAppointment);
  }

  isSelectedNamedDocument(
    namedDocument: INamedDocument,
    selectedNamedDocument: INamedDocument
  ): boolean {
    try {
      return isSameRef(namedDocument, selectedNamedDocument);
    } catch (error) {
      return namedDocument === selectedNamedDocument;
    }
  }

  isSelectedType(type: ILabJobType, selected: ILabJobType): boolean {
    return type.name === selected.name;
  }

  private _getPatientAppointments(): OperatorFunction<
    INamedDocument<IPatient> | undefined,
    WithRef<IAppointment>[]
  > {
    return switchMap((patient) => {
      if (!patient) {
        return of([]);
      }
      return query$(
        Appointment.col(patient),
        orderBy('event.from'),
        startAt(moment().startOf('day').toDate())
      );
    });
  }

  private async _toLabJobFormData(
    labJob: WithRef<ILabJob>
  ): Promise<ILabJobFormData> {
    const appointment = labJob.appointment
      ? await getDoc(labJob.appointment.ref)
      : undefined;
    const dueDate = labJob.dueDate ? toMoment(labJob.dueDate) : undefined;
    return { ...labJob, appointment, dueDate };
  }
}
