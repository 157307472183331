import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import {
  MultiChipInputComponent,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { CommonModule } from '@angular/common';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { RouterLink } from '@angular/router';
import { TrackingCodeHelpService } from '../tracking-code-help/tracking-code-help.service';
import { ReactiveFormsModule } from '@angular/forms';
import { ITrackingCodes } from '@principle-theorem/principle-core/interfaces';
import { VALID_URL } from '@principle-theorem/shared';

@Component({
  selector: 'pr-tracking-codes',
  imports: [
    CommonModule,
    NgMaterialModule,
    RouterLink,
    ReactiveFormsModule,
    MultiChipInputComponent,
  ],
  templateUrl: './tracking-codes.component.html',
  styleUrls: ['./tracking-codes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackingCodesComponent {
  trackingCodeHelp = inject(TrackingCodeHelpService);
  form = input.required<TypedFormGroup<ITrackingCodes>>();

  isValidURL(value: string): boolean {
    return VALID_URL.test(value);
  }

  isValidGA4Code(value: string): boolean {
    return value.startsWith('G-') || value.startsWith('GT-');
  }

  isValidGTMCode(value: string): boolean {
    return value.startsWith('GTM-');
  }
}
