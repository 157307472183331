import { DIALOG_SCROLL_STRATEGY_PROVIDER, Dialog } from '@angular/cdk/dialog';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/autocomplete';
import {
  MAT_DIALOG_SCROLL_STRATEGY_PROVIDER,
  MatDialog,
} from '@angular/material/dialog';
import { MAT_MENU_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/menu';
import { CONNECTED_DIALOG_SCROLL_STRATEGY_PROVIDER } from '@principle-theorem/ng-core';
import { applicationConfig, moduleMetadata } from '@storybook/angular';
import { AngularRenderer } from '@storybook/angular/dist/client/types';
import { DecoratorFunction } from '@storybook/csf';
import { NgxTiptapModule } from 'ngx-tiptap';
import { NG_EDITOR_CONFIG } from './editor-config';
import { HeadingMenuComponent } from './extensions/heading/heading-menu/heading-menu.component';
import { ImageUploadMenuComponent } from './extensions/image/image-upload-menu/image-upload-menu.component';
import { MentionNodeComponent } from './extensions/mention/mention-node/mention-node.component';
import { VideoEmbedMenuComponent } from './extensions/video/video-embed-menu/video-embed-menu.component';
import { VideoUploadMenuComponent } from './extensions/video/video-upload-menu/video-upload-menu.component';
import { BasicMenuButtonComponent } from './menu-bar/basic-menu-button/basic-menu-button.component';
import { MenuDividerComponent } from './menu-bar/menu-divider/menu-divider.component';
import { MenuSpacerComponent } from './menu-bar/menu-spacer/menu-spacer.component';
import { NgEditorModule } from './ng-editor.module';

export const DECORATE_NG_EDITOR: DecoratorFunction<AngularRenderer>[] = [
  applicationConfig({
    providers: [
      MatDialog,
      Dialog,
      {
        provide: NG_EDITOR_CONFIG,
        useValue: { migrations: [] },
      },
      CONNECTED_DIALOG_SCROLL_STRATEGY_PROVIDER,
      MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
      MAT_MENU_SCROLL_STRATEGY_FACTORY_PROVIDER,
      MAT_DIALOG_SCROLL_STRATEGY_PROVIDER,
      DIALOG_SCROLL_STRATEGY_PROVIDER,
    ],
  }),
  moduleMetadata({
    imports: [NgEditorModule, NgxTiptapModule],
    entryComponents: [
      HeadingMenuComponent,
      BasicMenuButtonComponent,
      MenuDividerComponent,
      BasicMenuButtonComponent,
      MenuSpacerComponent,
      ImageUploadMenuComponent,
      VideoUploadMenuComponent,
      VideoEmbedMenuComponent,
      MentionNodeComponent,
    ],
  }),
];
