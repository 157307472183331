export function toFirestore<T extends object>(data: T): T {
  return SerialisationProvider.serialise(data, TransformEvent.ToFirestore);
}

export function fromFirestore<T extends object>(data: T): T {
  return SerialisationProvider.serialise(data, TransformEvent.FromFirestore);
}

export interface ITransformer<T = unknown, R = T> {
  events: TransformEvent[];
  guard(item: unknown, eventType: TransformEvent): item is T;
  transform(item: T): R;
}

export enum TransformEvent {
  ToFirestore = 'toFirestore',
  FromFirestore = 'fromFirestore',
  ToRedux = 'toRedux',
  FromRedux = 'fromRedux',
}

export class SerialisationProvider {
  static serialisers: ITransformer[];

  static init(serialisers: ITransformer[]): void {
    this.serialisers = serialisers;
  }

  static serialise<T>(data: T, eventType: TransformEvent): T {
    const reducedData = SerialisationProvider.serialisers
      .filter((serialiser) => serialiser.events.includes(eventType))
      .reduce((previous, serialiser) => {
        if (!serialiser.guard(previous, eventType)) {
          return previous;
        }
        return serialiser.transform(previous) as T;
      }, data);
    return reducedData;
  }
}
