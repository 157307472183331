import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IPractice,
  type ITyroTerminal,
} from '@principle-theorem/principle-core/interfaces';
import { Practice } from '@principle-theorem/principle-core';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  isSameRef,
  multiFilter,
  sortTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { type Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export interface ITerminalOption {
  practiceRef: DocumentReference<IPractice>;
  terminal: WithRef<ITyroTerminal>;
  isCurrentlyPaired: boolean;
}

export type IntegrationKeyFormData = Required<
  Pick<ITyroTerminal, 'integrationKey' | 'mid' | 'tid'>
>;

export interface ISelectedTerminal {
  practiceRef: DocumentReference<IPractice>;
  terminalData: IntegrationKeyFormData;
}

@Component({
  selector: 'pr-select-tyro-terminal',
  templateUrl: './select-tyro-terminal.component.html',
  styleUrls: ['./select-tyro-terminal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectTyroTerminalComponent {
  options$: Observable<ITerminalOption[]>;
  terminals$: Observable<WithRef<ITyroTerminal>[]>;
  trackByOptions = TrackByFunctions.ref<ITerminalOption>('terminal.ref');

  constructor(
    private _currentScopeFacade: CurrentScopeFacade,
    private _dialogRef: MatDialogRef<
      SelectTyroTerminalComponent,
      ISelectedTerminal
    >
  ) {
    this.options$ = this._currentScopeFacade.currentPractice$.pipe(
      switchMap((practice) => this._getPracticeTerminals$(practice)),
      multiFilter((option) => !!option.terminal.integrationKey)
    );
  }

  select(option: ITerminalOption): void {
    this._dialogRef.close(this._toSelectedTerminal(option));
  }

  private _getPracticeTerminals$(
    practice: WithRef<IPractice> | undefined
  ): Observable<ITerminalOption[]> {
    if (!practice) {
      return of([]);
    }
    return Practice.tyroTerminals$(practice).pipe(
      map((terminals) => this._toTerminalOptions(practice.ref, terminals))
    );
  }

  private _toTerminalOptions(
    practiceRef: DocumentReference<IPractice>,
    terminals: WithRef<ITyroTerminal>[]
  ): ITerminalOption[] {
    const ordered = terminals.sort((a, b) =>
      sortTimestamp(a.lastPaired, b.lastPaired)
    );
    const current = first(ordered);
    return ordered.map((terminal) => ({
      terminal,
      isCurrentlyPaired: isSameRef(current, terminal),
      practiceRef,
    }));
  }

  private _toSelectedTerminal(
    option: ITerminalOption
  ): ISelectedTerminal | undefined {
    if (!option.terminal.integrationKey) {
      return;
    }
    return {
      practiceRef: option.practiceRef,
      terminalData: {
        integrationKey: option.terminal.integrationKey,
        mid: option.terminal.mid,
        tid: option.terminal.tid,
      },
    };
  }
}
