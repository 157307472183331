import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  NgModuleRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  type ComponentRef,
  type OnDestroy,
} from '@angular/core';
import {
  type EditorMenuItemComponent,
  type MenuButtonLoaderFn,
} from '@principle-theorem/ng-prosemirror';
import { Editor } from '@tiptap/core';
import { set } from 'lodash';
import { ReplaySubject, Subject, combineLatest, type Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pt-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuBarComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _menuContainer$: ReplaySubject<ViewContainerRef> = new ReplaySubject(
    1
  );
  private _menuItems$: ReplaySubject<MenuButtonLoaderFn[]> = new ReplaySubject(
    1
  );
  editor$ = new ReplaySubject<Editor>(1);

  @Input()
  set menuItems(menuItems: MenuButtonLoaderFn<EditorMenuItemComponent>[]) {
    if (menuItems) {
      this._menuItems$.next(menuItems);
    }
  }

  @ViewChild('menuContainer', { static: false, read: ViewContainerRef })
  set menuContainer(menuContainer: ViewContainerRef) {
    if (menuContainer) {
      this._menuContainer$.next(menuContainer);
    }
  }

  @Input()
  set editor(editor: Editor) {
    if (editor) {
      this.editor$.next(editor);
    }
  }

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _moduleRef: NgModuleRef<unknown>,
    private _cdr: ChangeDetectorRef
  ) {
    const buttons$: Observable<ComponentRef<EditorMenuItemComponent>[]> =
      combineLatest([
        this._menuContainer$,
        this._menuItems$,
        this.editor$,
      ]).pipe(
        map(([menuContainer, menuItems, editor]) => {
          menuContainer.clear();
          return menuItems.map((menuItemFn) => {
            const menuItem = menuItemFn(editor);
            const componentFactory =
              this._componentFactoryResolver.resolveComponentFactory(
                menuItem.component
              );
            const componentRef =
              menuContainer.createComponent<EditorMenuItemComponent>(
                componentFactory,
                undefined,
                undefined,
                undefined,
                this._moduleRef
              );
            Object.entries(menuItem.data || {}).map(([key, value]) => {
              set(componentRef.instance, key, value);
            });
            return componentRef;
          });
        })
      );

    combineLatest([buttons$, this.editor$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([buttons, editor]) => {
        buttons.map((button) => {
          button.instance.editor = editor;
        });
        this._cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
