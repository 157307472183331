import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {
  extendSortingDataAccessor,
  ObservableDataSource,
  timestampSortingAccessor,
} from '@principle-theorem/ng-shared';
import { Patient } from '@principle-theorem/principle-core';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  type ITimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { type IPatientVisitRecord } from '../patient-visits-report.store';
import { PatientVisitToCSV } from './patient-visits-to-csv';

@Component({
  selector: 'pr-patient-visits-report-table',
  templateUrl: './patient-visits-report-table.component.html',
  styleUrls: ['./patient-visits-report-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientVisitsReportTableComponent implements OnDestroy {
  records$ = new ReplaySubject<IPatientVisitRecord[]>(1);
  dateRange$ = new ReplaySubject<ITimePeriod>(1);
  csvTranslator = new PatientVisitToCSV();

  @Input()
  set dateRange(dateRange: ITimePeriod) {
    if (dateRange) {
      this.dateRange$.next(dateRange);
    }
  }

  dataSource: ObservableDataSource<IPatientVisitRecord>;
  dateFormat = DAY_MONTH_YEAR_FORMAT;
  displayedColumns = [
    'patientName',
    'mobileNumber',
    'email',
    'appointmentDate',
    'practitioner',
    'isFirstAppointment',
    'referredBy',
  ];

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @Input()
  set records(records: IPatientVisitRecord[]) {
    if (records) {
      this.records$.next(records);
    }
  }

  constructor() {
    this.dataSource = new ObservableDataSource(this.records$);
    this.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, sortHeaderId) => this._sortingDataAccessor(data, sortHeaderId)
    );
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  async getMobileNumber(
    patient: WithRef<IPatient>
  ): Promise<string | undefined> {
    return Patient.getMobileNumber(patient);
  }

  private _sortingDataAccessor(
    data: IPatientVisitRecord,
    sortHeaderId: string
  ): string | number | undefined {
    switch (sortHeaderId) {
      case 'patientName':
        return data.patient.name;
      case 'referredBy':
        return data.patient.referrer?.name;
      case 'isFirstAppointment':
        return data.isFirstAppointment ? 'Yes' : 'No';
      case 'appointmentDate':
        return timestampSortingAccessor(data.appointment.event.from);
      default:
        return;
    }
  }
}
