import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { type IMedipassConfig } from '@medipass/partner-sdk';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';

export type IMedipassFormData = Pick<IMedipassConfig, 'apiKey'>;

@Component({
  selector: 'pr-medipass-integration-form',
  templateUrl: './medipass-integration-form.component.html',
  styleUrls: ['./medipass-integration-form.component.scss'],
})
export class MedipassIntegrationFormComponent {
  medipassForm: TypedFormGroup<IMedipassFormData> =
    new TypedFormGroup<IMedipassFormData>({
      apiKey: new TypedFormControl<string>('', Validators.required),
    });

  constructor(
    private _dialogRef: MatDialogRef<MedipassIntegrationFormComponent>
  ) {}

  submit(): void {
    if (this.medipassForm.invalid) {
      return;
    }
    this._dialogRef.close(this.medipassForm.value);
  }
}
