<ng-container *ngIf="automations$ | ngrxPush as automations">
  <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="16px">
    <h2>Automations</h2>
    <button
      class="add-automation"
      matTooltip="Add Automation"
      mat-icon-button
      (click)="openAutomations.emit()"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <ng-container *ngIf="automations.length; else noAutomations">
    <pr-appointment-automations-summary [automations]="automations" />
  </ng-container>

  <ng-template #noAutomations>
    <pr-empty-state type="text" title="automations" />
  </ng-template>
</ng-container>
