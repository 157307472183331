import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IScheduleSummaryEventable,
  type IWaitListCandidate,
} from '@principle-theorem/principle-core/interfaces';
import { WaitListCandidate } from '@principle-theorem/principle-core';
import { first } from 'lodash';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Firestore } from '@principle-theorem/shared';

@Component({
    selector: 'pr-wait-list-warnings',
    templateUrl: './wait-list-warnings.component.html',
    styleUrls: ['./wait-list-warnings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WaitListWarningsComponent {
  gap$: ReplaySubject<IScheduleSummaryEventable> = new ReplaySubject(1);
  waitListCandidate$: ReplaySubject<IWaitListCandidate> = new ReplaySubject(1);
  warning$: Observable<string | undefined>;

  @Input()
  set gap(gap: IScheduleSummaryEventable) {
    if (gap) {
      this.gap$.next(gap);
    }
  }

  @Input()
  set waitListCandidate(waitListCandidate: IWaitListCandidate) {
    if (waitListCandidate) {
      this.waitListCandidate$.next(waitListCandidate);
    }
  }

  constructor() {
    this.warning$ = combineLatest([
      this.gap$,
      this.waitListCandidate$.pipe(
        switchMap((candidate) => Firestore.getDoc(candidate.appointment.ref))
      ),
    ]).pipe(
      switchMap(([gap, appointment]) =>
        WaitListCandidate.getWarnings$(appointment, gap.event)
      ),
      map(first)
    );
  }
}
