<form [formGroup]="labForm" (ngSubmit)="submit()">
  <mat-toolbar color="accent">{{ data.title }}</mat-toolbar>
  <div
    class="form-errors"
    *ngIf="
      (labForm.controls.email.dirty || labForm.controls.phone.dirty) &&
      labForm.errors
    "
  >
    <pt-alert
      *ngFor="let error of labForm.errors | keyvalue; trackBy: trackByError"
      [alert]="error.value"
     />
  </div>

  <div class="layout-margin">
    <div fxFill fxLayout="column">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" required />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Address</mat-label>
        <input matInput formControlName="address" />
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email" />
      </mat-form-field>

      <mat-form-field fxFlex hintLabel="Min {{ minPhoneLength }} characters">
        <mat-label>Phone</mat-label>
        <input type="phone" matInput formControlName="phone" />
        <mat-hint align="end">
          {{ labForm.value?.phone?.length || 0 }}/{{ minPhoneLength }}
        </mat-hint>
      </mat-form-field>
    </div>
    <pt-buttons-container>
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="labForm.invalid"
      >
        {{ data.submitLabel }}
      </button>
    </pt-buttons-container>
  </div>
</form>
