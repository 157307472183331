import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DYNAMIC_COMPONENT_DATA } from '@principle-theorem/ng-shared';
import { EZDENT_INTEGRATION } from '@principle-theorem/principle-bridge-core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IIntegrationActionData } from '../../../../integration-action-data';
import { BridgeDeviceSelectorService } from '../../../bridge-device-selector-dialog/bridge-device-selector.service';
import { EzdentFeatureService } from '../ezdent-feature.service';

@Component({
    selector: 'pr-ezdent-trigger-x-ray',
    templateUrl: './ezdent-trigger-x-ray.component.html',
    styleUrls: ['./ezdent-trigger-x-ray.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EzdentTriggerXRayComponent {
  isDisabled$: Observable<boolean>;

  constructor(
    @Inject(DYNAMIC_COMPONENT_DATA)
    private _data: IIntegrationActionData,
    private _ezdent: EzdentFeatureService,
    private _bridgeDeviceSelector: BridgeDeviceSelectorService
  ) {
    this.isDisabled$ = of(this._data.patient).pipe(
      map((patient) => !patient.dateOfBirth)
    );
  }

  async takeXRay(): Promise<void> {
    const deviceRef =
      await this._bridgeDeviceSelector.selectDevice(EZDENT_INTEGRATION);
    if (!deviceRef) {
      return;
    }

    await this._ezdent.triggerImageCapture(this._data.patient, deviceRef);
  }
}
