<ng-container *ngIf="item$ | async as item">
  <mat-checkbox
    *ngIf="(disabled$ | async) === false"
    #checkbox="matCheckbox"
    class="checkbox"
    [ngClass]="{ disabled: isCheckboxDisabled$ | async }"
    color="primary"
    [disabled]="isCheckboxDisabled$ | async"
    [checked]="isSelected$ | async"
    (change)="setSelected(item, checkbox.checked)"
   />
</ng-container>
