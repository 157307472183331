<div class="status step-container" [ngClass]="status$ | async">
  <div class="flex items-center" *ngrxLet="treatmentStep$ as treatmentStep">
    <ng-container *ngrxLet="appointment$ as appointment">
      <div class="header-wrapper" class="flex flex-1 items-center gap-6">
        <div class="flex basis-32 flex-col">
          <p class="mat-caption opaque">Treatment Step</p>
          <p class="mat-subtitle-2 w-32 truncate">
            {{ treatmentStep.name }}
          </p>
        </div>
        <pr-treatment-category-indicator
          *ngIf="treatmentCategory$ | async as category"
          [category]="category"
          displayOnly
         />
        <span class="flex-1"></span>
        <div class="flex flex-col">
          <button
            *ngIf="canSchedule$ | async"
            mat-flat-button
            color="primary"
            class="dense"
            (click)="generateAppointment(treatmentStep)"
            matTooltip="Allow Front Desk to book this in"
          >
            Enable Scheduling
          </button>
          <button
            *ngIf="canDisable$ | async"
            mat-stroked-button
            class="dense"
            (click)="removeAppointment(treatmentStep)"
            matTooltip="Don't allow Front Desk to book this in"
          >
            Disable Scheduling
          </button>
          <ng-container *ngIf="appointment">
            <div
              *ngIf="appointment | map: isUnscheduled"
              class="appointment-time unscheduled"
            >
              Unscheduled
            </div>

            <div
              *ngIf="(appointment | map: isUnscheduled) === false"
              class="appointment-time"
              [ngClass]="status$ | async"
            >
              {{ appointment | map: appointmentSummary }}
            </div>

            <button
              *ngIf="appointment | map: isBookable"
              mat-flat-button
              color="primary"
              class="dense appointment-action"
              (click)="selectAppointment(treatmentStep, appointment.ref)"
            >
              <ng-container
                *ngIf="appointment | map: isUnscheduled; else rescheduleMessage"
              >
                <mat-icon>add</mat-icon> Book Now
              </ng-container>
              <ng-template #rescheduleMessage>
                <mat-icon>keyboard_tab</mat-icon> reschedule
              </ng-template>
            </button>
          </ng-container>
        </div>
      </div>

      <pt-buttons-container>
        <div>
          <ng-content select=".header-buttons"></ng-content>
        </div>

        <button mat-icon-button (click)="content.toggle()">
          <mat-icon>{{
            content.expanded ? 'expand_less' : 'expand_more'
          }}</mat-icon>
        </button>
      </pt-buttons-container>
    </ng-container>
  </div>

  <pt-collapsible #content [expanded]="expanded$ | async">
    <ng-template ptCollapsibleContent>
      <ng-content></ng-content>
    </ng-template>
  </pt-collapsible>
</div>
