import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
  selector: '[ptElementTarget]',
})
export class ElementTargetDirective {
  @HostBinding('attr.data-element-target')
  @Input('ptElementTarget')
  target: string;
}
