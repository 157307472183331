import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { toMentionContent, toTextContent } from '@principle-theorem/editor';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import {
  BasicDialogService,
  ConfirmDialogComponent,
  DialogPresets,
  IConfirmationDialogInput,
  ISelectListDialogGroup,
  NG_SHARED_CONFIG,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import {
  Brand,
  CustomFormConfiguration,
  Interaction,
  Patient,
  PatientForm,
  generateMedicalHistoryUrl,
  generatePatientFormsUrl,
  toMention,
} from '@principle-theorem/principle-core';
import {
  IBrand,
  ICustomFormConfiguration,
  IPatient,
  IPatientForm,
  IPatientFormSchema,
  IPractice,
  IStaffer,
  InteractionType,
  MentionResourceType,
  PATIENT_FORM_NAME,
  PatientFormSpecialUid,
  PatientFormStatus,
  PatientFormType,
  ReservedCustomFormId,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  INamedDocument,
  IReffable,
  WithRef,
  addDoc,
  addDocAsWithRef,
  asReffable,
  deleteDoc,
  doc,
  snapshot,
  toNamedDocument,
  undeletedQuery,
} from '@principle-theorem/shared';
import { isString, omit, sortBy } from 'lodash';
import {
  IPreviewFormIssueDialogRequest,
  IPreviewFormIssueDialogResponse,
  PreviewFormIssueDialogComponent,
} from './components/custom-form/preview-form-issue-dialog/preview-form-issue-dialog.component';
import {
  IPatientFormDialogData,
  PatientFormDialogComponent,
} from './components/form-dialog/patient-form-dialog.component';

@Injectable()
export class PatientCustomFormsService {
  private _sharedConfig = inject(NG_SHARED_CONFIG);
  private _clipboard = inject(Clipboard);
  private _snackBar = inject(MatSnackBar);
  private _scope = inject(CurrentScopeFacade);
  private _basicDialog = inject(BasicDialogService);
  private _dialog = inject(MatDialog);
  private _router = inject(Router);
  private _route = inject(ActivatedRoute);

  async copyMedicalHistoryFormLink(patient: WithRef<IPatient>): Promise<void> {
    this._copyToClipboard(await this._getMedicalHistoryFormLink(patient));
  }

  async openMedicalHistoryFormLink(patient: WithRef<IPatient>): Promise<void> {
    this._openWindow(await this._getMedicalHistoryFormLink(patient));
  }

  async copyPatientFormsLink(patient: WithRef<IPatient>): Promise<void> {
    this._copyToClipboard(await this._getPatientFormsLink(patient));
  }

  async openPatientFormsLink(patient: WithRef<IPatient>): Promise<void> {
    this._openWindow(await this._getPatientFormsLink(patient));
  }

  async openFormDialog(
    form: WithRef<IPatientForm>,
    readonly = true
  ): Promise<void> {
    await this._dialog
      .open<PatientFormDialogComponent, IPatientFormDialogData, void>(
        PatientFormDialogComponent,
        DialogPresets.large({
          height: '80%',
          data: { form, readonly },
        })
      )
      .afterClosed()
      .toPromise();
  }

  async issueForm(
    patient: WithRef<IPatient>,
    staffer: WithRef<IStaffer>,
    redirect: boolean = false
  ): Promise<void> {
    const brandRef = Patient.brandRef(patient);
    const customFormConfigs = await Firestore.getDocs(
      undeletedQuery(Brand.customFormConfigCol({ ref: brandRef }))
    );

    const groups = this._getIssueOptions(customFormConfigs);
    const options = groups.map((group) => group.options).flat();

    const selected = await this._basicDialog.selectList<
      PatientFormSpecialUid | WithRef<ICustomFormConfiguration>
    >({
      title: 'Generate a Form',
      prompt: 'Choose a Form to Generate',
      groups,
      options,
    });
    if (!selected) {
      return;
    }

    const formType = this._getPatientFormType(selected);
    const template = this._getTemplateNamedDocument(brandRef, selected);
    if (formType !== PatientFormType.CustomForm || !template) {
      return this._issueForm(
        patient,
        staffer,
        formType,
        {},
        template,
        redirect
      );
    }

    const dialogConfig = DialogPresets.large<IPreviewFormIssueDialogRequest>({
      maxHeight: '90vh',
      data: { template, patientRef: patient.ref },
    });

    const response = await this._dialog
      .open<
        PreviewFormIssueDialogComponent,
        IPreviewFormIssueDialogRequest,
        IPreviewFormIssueDialogResponse
      >(PreviewFormIssueDialogComponent, dialogConfig)
      .afterClosed()
      .toPromise();

    if (!response) {
      return;
    }
    return this._issueForm(
      patient,
      staffer,
      formType,
      response.formSchema,
      template,
      redirect
    );
  }

  async reissueForm(
    form: WithRef<IPatientForm>,
    staffer: WithRef<IStaffer>,
    redirect: boolean = false
  ): Promise<DocumentReference<IPatientForm> | undefined> {
    if (form.status === PatientFormStatus.Issued) {
      return;
    }
    const formName = PatientForm.getName(form);
    const confirmed = await this._basicDialog.confirm({
      title: 'Generate New Form',
      prompt: `Are you sure you want to generate a new copy of ${formName}? `,
      submitColor: 'warn',
      submitLabel: 'Generate',
    });
    if (!confirmed) {
      return;
    }
    const formCopy = PatientForm.init({
      ...omit(form, ['ref', 'updatedAt', 'createdAt', 'status']),
      form: omit(form.form, ['data', 'date']),
    });

    const patient = asReffable(PatientForm.patientRef(form));
    const result = await addDoc(Patient.formCol(patient), formCopy);

    const interaction = Interaction.init({
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(' generated a new copy of the form'),
      ],
      type: InteractionType.FormReissue,
    });
    await PatientForm.addInteraction({ ref: result }, interaction);

    if (redirect) {
      const formUrl = await PatientForm.getPatientFormUrl(asReffable(result));
      await this._router.navigate(formUrl, { relativeTo: this._route });
    }
  }

  async deleteForm(
    form: WithRef<IPatientForm>,
    redirect: boolean = false
  ): Promise<boolean> {
    const formName = PatientForm.getName(form);
    const confirmed = await this._basicDialog.confirm({
      title: 'Delete Form',
      prompt: `Are you sure you want to delete ${formName}?`,
      submitColor: 'warn',
      cancelLabel: 'Delete',
    });
    if (!confirmed) {
      return false;
    }
    await deleteDoc(form.ref);
    this._snackBar.open('Form deleted');

    if (redirect) {
      const patient = asReffable(PatientForm.patientRef(form));
      const formsUrl = await PatientForm.getPatientFormsUrl(patient);
      await this._router.navigate(formsUrl, { relativeTo: this._route });
    }
    return true;
  }

  async confirmPatientDetails(
    patient: WithRef<IPatient>,
    form: WithRef<IPatientForm>,
    updatedPatientDetails: Partial<IPatient>,
    staffer: WithRef<IStaffer>
  ): Promise<void> {
    const data = confirmationDialogData({
      title: 'Update Patient Details',
      prompt: `Confirming will update ${patient.name} details in the system.`,
      submitLabel: 'Confirm',
      submitColor: 'primary',
    });

    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }

    const updatedPatient = { ...patient, ...updatedPatientDetails };
    await Patient.updatePatientDetails(updatedPatient, staffer.ref);
    await this.confirmForm(form, staffer);
  }

  async confirmForm(
    form: WithRef<IPatientForm>,
    staffer: WithRef<IStaffer>
  ): Promise<void> {
    await Firestore.patchDoc(form.ref, {
      ...PatientForm.updateStatus(form, PatientFormStatus.Confirmed),
    });

    const interaction = Interaction.init({
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(' confirmed the form'),
      ],
      type: InteractionType.FormConfirm,
    });
    await PatientForm.addInteraction(form, interaction);

    const name = PatientForm.getName(form);
    this._snackBar.open(`${name} confirmed`);
  }

  private async _issueForm(
    patient: IReffable<IPatient>,
    staffer: WithRef<IStaffer>,
    formType: PatientFormType,
    formSchema: IPatientFormSchema,
    template?: INamedDocument<ICustomFormConfiguration>,
    redirect: boolean = false
  ): Promise<void> {
    const patientForm = PatientForm.init({
      formType,
      template,
      form: formSchema,
    });
    const result = await addDocAsWithRef(Patient.formCol(patient), patientForm);

    const interaction = Interaction.init({
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(' generated the form'),
      ],
      type: InteractionType.FormIssue,
    });
    await PatientForm.addInteraction(result, interaction);

    this._snackBar.open(`Generated ${PatientForm.getName(result)}`);
    if (redirect) {
      const formUrl = await PatientForm.getPatientFormUrl(result);
      await this._router.navigate(formUrl, { relativeTo: this._route });
    }
  }

  private _getTemplateNamedDocument(
    brandRef: DocumentReference<IBrand>,
    selected:
      | PatientFormSpecialUid
      | WithRef<ICustomFormConfiguration>
      | undefined
  ): INamedDocument<ICustomFormConfiguration> | undefined {
    if (!selected) {
      return;
    }
    if (!isString(selected)) {
      return toNamedDocument(selected);
    }
    if (selected === PatientFormSpecialUid.MedicalHistoryForm) {
      return {
        name: PATIENT_FORM_NAME[selected],
        ref: doc(
          Brand.customFormConfigCol({ ref: brandRef }),
          ReservedCustomFormId.MedicalHistory
        ),
      };
    }
  }

  private _getPatientFormType(
    selected: PatientFormSpecialUid | WithRef<ICustomFormConfiguration>
  ): PatientFormType {
    if (!isString(selected)) {
      return PatientFormType.CustomForm;
    }
    if (selected === PatientFormSpecialUid.PatientDetailsForm) {
      return PatientFormType.PatientDetailsForm;
    }
    return PatientFormType.CustomForm;
  }

  private _getIssueOptions(
    configs: WithRef<ICustomFormConfiguration>[]
  ): ISelectListDialogGroup<
    PatientFormSpecialUid | WithRef<ICustomFormConfiguration>
  >[] {
    const defaultOptions = [
      PatientFormSpecialUid.MedicalHistoryForm,
      PatientFormSpecialUid.PatientDetailsForm,
    ].map((value) => ({ label: PATIENT_FORM_NAME[value], value }));

    const customFormOptions = sortBy(configs, (config) => config.name)
      .filter((config) => !CustomFormConfiguration.isSystemForm(config))
      .map((config) => ({ label: config.name, value: config }));

    return [
      { name: 'System Forms', options: defaultOptions },
      { name: 'Custom Forms', options: customFormOptions },
    ];
  }

  private async _getPractice(): Promise<WithRef<IPractice> | undefined> {
    const currentPractice = await snapshot(this._scope.currentPractice$);
    if (currentPractice) {
      return currentPractice;
    }
    const brand = await snapshot(this._scope.currentBrand$);
    const practices = brand ? await snapshot(Brand.practices$(brand)) : [];
    return this._basicDialog.select<WithRef<IPractice>>({
      title: 'Select a Practice',
      prompt: 'Choose a practice to generate the form from',
      options: practices.map((practice) => ({
        label: practice.name,
        value: practice,
      })),
    });
  }

  private async _getPatientFormsLink(
    patient: WithRef<IPatient>
  ): Promise<string | undefined> {
    const practice = await this._getPractice();
    if (!practice) {
      return;
    }
    return generatePatientFormsUrl(
      patient,
      practice,
      this._sharedConfig.appUrl
    );
  }

  private async _getMedicalHistoryFormLink(
    patient: WithRef<IPatient>
  ): Promise<string | undefined> {
    return generateMedicalHistoryUrl(patient, this._sharedConfig.appUrl);
  }

  private _copyToClipboard(value?: string): void {
    if (!value) {
      return;
    }
    this._clipboard.copy(value);
    this._snackBar.open('Link copied to clipboard');
  }

  private _openWindow(url?: string): void {
    if (!url) {
      return;
    }
    window.open(url, '_blank');
  }
}
