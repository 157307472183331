import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { Patient } from '@principle-theorem/principle-core';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';

export interface IUpdatePatientData {
  patient: WithRef<IPatient>;
}

@Component({
    selector: 'pr-update-patient',
    templateUrl: './update-patient.component.html',
    styleUrls: ['./update-patient.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UpdatePatientComponent {
  patient: WithRef<IPatient>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { patient: WithRef<IPatient> },
    private _dialogRef: MatDialogRef<UpdatePatientComponent>,
    private _organisation: OrganisationService
  ) {
    this.patient = data.patient;
  }

  async updatePatient(patientData?: Partial<IPatient>): Promise<void> {
    if (!patientData) {
      this._dialogRef.close();
      return;
    }

    const updatedPatient = {
      ...this.patient,
      ...patientData,
    };
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    await Patient.updatePatientDetails(updatedPatient, staffer.ref);
    this._dialogRef.close(updatedPatient);
  }
}
