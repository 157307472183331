import { rand, randBoolean, randSentence } from '@ngneat/falso';
import {
  initVersionedSchema,
  VersionedSchema,
} from '@principle-theorem/editor';
import {
  IWaitListItem,
  WaitListUrgency,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseMock,
  DayOfWeek,
  DAYS_OF_WEEK,
  ISO_DATE_FORMAT,
  MockTimestamp,
  Properties,
} from '@principle-theorem/shared';
import { Timestamp } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';

export class WaitListItemMock
  extends BaseMock
  implements Properties<IWaitListItem>
{
  get uid(): string {
    return uuid();
  }

  get urgency(): WaitListUrgency {
    return rand([
      WaitListUrgency.High,
      WaitListUrgency.Medium,
      WaitListUrgency.Low,
    ]);
  }

  get inShortList(): boolean {
    return randBoolean();
  }

  get dateFrom(): Timestamp {
    return MockTimestamp();
  }

  get dateTo(): Timestamp {
    return MockTimestamp();
  }

  get timeFrom(): string {
    return '08:00';
  }

  get timeTo(): string {
    return '18:00';
  }

  get days(): DayOfWeek[] {
    return [rand(DAYS_OF_WEEK)];
  }

  get notes(): VersionedSchema {
    return initVersionedSchema(randSentence());
  }

  get availableDates(): string[] {
    return [moment().format(ISO_DATE_FORMAT)];
  }
}
