@if (transaction$ | async; as transaction) {
  @if (canChange$ | async) {
    <div
      class="hover:text-primary-400 flex cursor-pointer flex-row items-center gap-1 pb-1 text-slate-400 hover:underline"
      matTooltipShowDelay="500"
      [matTooltip]="attributedToTooltip$ | async"
      (click)="changeAttributedTo()"
    >
      <mat-icon class="icon-xs opacity-50">account_balance_wallet</mat-icon>
      <div>{{ attributedTo$ | async }}</div>
    </div>
  } @else {
    <div class="flex flex-row items-center gap-1 pb-1 text-slate-400">
      <mat-icon class="icon-xs opacity-50">account_balance_wallet</mat-icon>
      <div>{{ attributedTo$ | async }}</div>
    </div>
  }
}
