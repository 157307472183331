import {
  IScopedServiceCode,
  ServiceCodeType,
} from '@principle-theorem/principle-core/interfaces';
import { ServiceProviderHandler } from '../service-codes/service-provider';

export class PricedServiceCodeTranslator {
  static convertToType(
    item: IScopedServiceCode,
    type?: ServiceCodeType
  ): IScopedServiceCode {
    const currentCode = ServiceProviderHandler.resolveServiceCode(
      item.type,
      item.code
    );

    if (!currentCode) {
      return item;
    }

    const convertedCode = ServiceProviderHandler.resolveServiceCode(
      type,
      currentCode?.rootCode ?? item.code
    );

    if (!convertedCode && currentCode?.rootCode) {
      const rootCode = ServiceProviderHandler.findServiceCode(
        currentCode.rootCode
      );
      if (rootCode) {
        return rootCode;
      }
    }

    if (!convertedCode) {
      return item;
    }

    return {
      code: convertedCode.code,
      type: convertedCode.type,
    };
  }
}
