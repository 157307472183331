import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IInvoice } from '@principle-theorem/principle-core/interfaces';
import { snapshot, type WithRef } from '@principle-theorem/shared';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  of,
  type Observable,
} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  IResolvedTransactionOption,
  hasGetInfo,
} from '../transaction-providers/transaction-provider';
import { TransactionProviders } from '../transaction-providers/transaction-providers.service';

@Component({
  selector: 'pr-add-transaction-button',
  templateUrl: './add-transaction-button.component.html',
  styleUrls: ['./add-transaction-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddTransactionButtonComponent {
  invoice$ = new ReplaySubject<WithRef<IInvoice>>(1);
  option$ = new ReplaySubject<WithRef<IResolvedTransactionOption>>(1);
  canCapture$: Observable<boolean>;
  adding$ = new BehaviorSubject<boolean>(false);
  info$: Observable<string | undefined>;

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this.invoice$.next(invoice);
    }
  }

  @Input()
  set option(option: WithRef<IResolvedTransactionOption>) {
    if (option) {
      this.option$.next(option);
    }
  }

  constructor(public transactions: TransactionProviders) {
    this.canCapture$ = combineLatest([this.invoice$, this.option$]).pipe(
      switchMap(([invoice, option]) => option.provider.canCapture$(invoice))
    );
    this.info$ = combineLatest([this.invoice$, this.option$]).pipe(
      switchMap(([invoice, option]) =>
        hasGetInfo(option.provider)
          ? option.provider.getInfo$(invoice)
          : of(undefined)
      )
    );
  }

  async capture(): Promise<void> {
    this.adding$.next(true);
    const option = await snapshot(this.option$);
    const invoice = await snapshot(this.invoice$);
    await this.transactions.capture(option.provider, invoice);
    this.adding$.next(false);
  }
}
