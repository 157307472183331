import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  ButtonsContainerComponent,
  NgSharedModule,
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
  validFormArrayChanges$,
} from '@principle-theorem/ng-shared';
import {
  ISterilisationEquipment,
  ISterilisationPack,
  ISterilisationPackContent,
} from '@principle-theorem/principle-core/interfaces';
import { INamedDocument, WithRef, multiMap } from '@principle-theorem/shared';
import { SterilisationPackContentInputComponent } from './sterilisation-pack-content-input/sterilisation-pack-content-input.component';
import {
  SterilisationPackContentControls,
  SterilisationPackContentFormGroup,
} from './sterilisation-pack-content-form';
import { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ISterilisationPackForm extends Omit<ISterilisationPack, 'deleted'> {}

export interface ISterilisationPackDialogData {
  items: INamedDocument<ISterilisationEquipment>[];
  pack?: WithRef<ISterilisationPack>;
}

@Component({
  selector: 'pr-sterilisation-pack-dialog',
  imports: [
    CommonModule,
    NgMaterialModule,
    ButtonsContainerComponent,
    ReactiveFormsModule,
    FormsModule,
    SterilisationPackContentInputComponent,
    NgSharedModule,
  ],
  templateUrl: './sterilisation-pack-dialog.component.html',
  styleUrl: './sterilisation-pack-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SterilisationPackDialogComponent {
  private _dialogRef = inject(
    MatDialogRef<SterilisationPackDialogComponent, ISterilisationPack>
  );
  form = new TypedFormGroup<ISterilisationPackForm>({
    name: new TypedFormControl<string>('', Validators.required),
    description: new TypedFormControl<string>(''),
    content: new TypedFormArray<ISterilisationPackContent>([
      new SterilisationPackContentFormGroup(),
    ]),
  });
  data = inject<ISterilisationPackDialogData>(MAT_DIALOG_DATA);
  packContentControls: SterilisationPackContentControls;
  selectedItems$: Observable<INamedDocument<ISterilisationEquipment>[]>;

  constructor() {
    this.packContentControls = new SterilisationPackContentControls(
      this.content
    );

    if (this.data.pack) {
      this.packContentControls.initContentControls(this.data.pack);
      this.form.patchValue(this.data.pack);
    }

    this.selectedItems$ = validFormArrayChanges$(this.content).pipe(
      multiMap((packContent) => packContent.item)
    );
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }

    const formValue = this.form.getRawValue();
    const filteredContent = formValue.content.filter(
      (content) => !!content.item
    );

    this._dialogRef.close({
      ...formValue,
      content: filteredContent,
      deleted: false,
    });
  }

  get content(): TypedFormArray<ISterilisationPackContent> {
    return this.form.controls
      .content as TypedFormArray<ISterilisationPackContent>;
  }
}
