import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  type OnDestroy,
} from '@angular/core';
import {
  type ITimelineDisplayOptions,
  type IDeadzone,
  type ITimelineDataGroup,
} from '@principle-theorem/principle-core/interfaces';
import { type ITimePeriod } from '@principle-theorem/shared';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { InteractiveTimelineDisplayCalculator } from '../interactive-timeline-display-calculator';
import {
  type IInteractableRect,
  InteractableRect,
} from '../interactive-timeline-node/interactable-rect';

@Component({
    selector: 'pr-interactive-timeline-deadzone',
    templateUrl: './interactive-timeline-deadzone.component.html',
    styleUrls: ['./interactive-timeline-deadzone.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InteractiveTimelineDeadzoneComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _options$ = new ReplaySubject<ITimelineDisplayOptions>(1);
  private _day$ = new ReplaySubject<ITimePeriod>(1);
  private _timeRange$ = new ReplaySubject<ITimePeriod>(1);
  private _group$ = new ReplaySubject<ITimelineDataGroup<unknown, unknown>>(1);
  private _deadzone$ = new ReplaySubject<IDeadzone>(1);
  private _element: HTMLElement;
  @HostBinding('class.is-blocking') isBlocking = false;
  @HostBinding('class.horizontal') isHorizontal = true;
  @HostBinding('class.vertical') isVertical = false;
  @HostBinding('style.background-color') backgroundColour: string;

  @Input()
  set options(options: ITimelineDisplayOptions) {
    if (options) {
      this._options$.next(options);
    }
  }

  @Input()
  set group(group: ITimelineDataGroup<unknown, unknown>) {
    if (group) {
      this._group$.next(group);
    }
  }

  @Input()
  set day(day: ITimePeriod) {
    if (day) {
      this._day$.next(day);
    }
  }

  @Input()
  set deadzone(deadzone: IDeadzone) {
    if (!deadzone) {
      return;
    }
    this._deadzone$.next(deadzone);
    if (deadzone.isBlocking) {
      this.isBlocking = true;
    }
  }

  @Input()
  set timeRange(timeRange: ITimePeriod) {
    if (timeRange) {
      this._timeRange$.next(timeRange);
    }
  }

  @Input()
  set colour(colour: string) {
    if (colour) {
      this.backgroundColour = colour;
    }
  }

  constructor(elementRef: ElementRef<HTMLElement>) {
    this._element = elementRef.nativeElement;
    combineLatest([
      this._options$,
      this._group$,
      this._day$,
      this._deadzone$,
      this._timeRange$,
    ])
      .pipe(
        map(([options, group, day, deadzone, timeRange]) =>
          this._getRect(options, group, day, deadzone, timeRange)
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((rect) => InteractableRect.render(rect, this._element));

    this._options$
      .pipe(
        map(InteractiveTimelineDisplayCalculator.isHorizontal),
        distinctUntilChanged(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((isHorizontal) => {
        this.isHorizontal = isHorizontal;
        this.isVertical = !isHorizontal;
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _getRect(
    options: ITimelineDisplayOptions,
    group: ITimelineDataGroup<unknown, unknown>,
    day: ITimePeriod,
    deadzone: ITimePeriod,
    timeRange: ITimePeriod
  ): IInteractableRect {
    const groupSize = InteractiveTimelineDisplayCalculator.getGroupSize(
      options,
      group
    );
    const position = InteractiveTimelineDisplayCalculator.timeToPosition(
      options,
      timeRange,
      deadzone.from,
      day.from
    );
    const durationInMins = deadzone.to.diff(deadzone.from, 'minutes');
    const durationSize = InteractiveTimelineDisplayCalculator.durationToPixels(
      options,
      durationInMins
    );
    return InteractiveTimelineDisplayCalculator.createOrientationAwareRect(
      options,
      position,
      durationSize,
      groupSize
    );
  }
}
