import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone, toFloat } from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  convertValueFn,
} from '../../../source/source-helpers';

export const PATIENT_CLAIM_RESOURCE_TYPE = 'patientClaims';

export const PATIENT_CLAIM_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient Claim List',
    description: '',
    idPrefix: PATIENT_CLAIM_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ICorePracticePatientClaim {
  id: number;
  claimDate: string; // 2021-11-25 22:27:17.217
  note?: string; // HICAPS payment have not been deleted. Claim paid amount have been cleared.
  total: number; // 3600.0000
  paid: number; // 880.0000
  claimType: 1 | 2;
  isDeleted: boolean;
  patientId: number;
  invoiceId: number;
}

export function isCorePracticePatientClaim(
  item: unknown
): item is ICorePracticePatientClaim {
  return TypeGuard.interface<ICorePracticePatientClaim>({
    id: isNumber,
    claimDate: isString,
    note: TypeGuard.nilOr(isString),
    total: isNumber,
    paid: isNumber,
    claimType: isNumber,
    isDeleted: isBoolean,
    patientId: isNumber,
    invoiceId: isNumber,
  })(item);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICorePracticePatientClaimTranslations {}

export interface ICorePracticePatientClaimFilters {
  patientId: number;
  invoiceId: number;
}

const PATIENT_CLAIM_SOURCE_QUERY = `
SELECT
  ClaimId AS id,
  ClaimDate AS claim_date,
  Note AS note,
  convert_to_decimal(Total) AS total,
  convert_to_decimal(Paid) AS paid,
  ClaimType AS claim_type,
  convert_to_boolean(IsDeleted) AS is_deleted,
  PatientId AS patient_id,
  InvoiceId AS invoice_id
FROM tblClaim
`;

export class PatientClaimSourceEntity extends BaseSourceEntity<
  ICorePracticePatientClaim,
  ICorePracticePatientClaimTranslations,
  ICorePracticePatientClaimFilters
> {
  sourceEntity = PATIENT_CLAIM_SOURCE_ENTITY;
  entityResourceType = PATIENT_CLAIM_RESOURCE_TYPE;
  sourceQuery = PATIENT_CLAIM_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientClaim;

  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertValueFn(toFloat, 'total', 'paid'),
  ]);

  translate(
    _data: ICorePracticePatientClaim,
    _timezone: Timezone
  ): ICorePracticePatientClaimTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientClaim): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientClaim): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientClaim,
    _timezone: Timezone
  ): ICorePracticePatientClaimFilters {
    return {
      patientId: data.patientId,
      invoiceId: data.invoiceId,
    };
  }
}
