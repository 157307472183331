<div class="flex gap-4">
  <div class="flex-1">
    <h3 mat-subtitle-1>Display Settings</h3>
    <pr-charting-options
      [chartingConfiguration]="treatmentConfig"
      (selectedDisplayOptions)="selectedDisplayOptions($event)"
     />
  </div>
  <div class="flex-1">
    <h3 mat-subtitle-1>Available Surfaces</h3>
    <pr-available-surfaces
      [surfaces]="treatmentConfig.availableSurfaces"
      (selectedSurfaces)="selectedSurfaces($event)"
     />
  </div>
</div>
<pt-buttons-container>
  <button mat-flat-button color="primary" (click)="save()">Save</button>
</pt-buttons-container>
