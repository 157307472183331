import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DomSanitizer, type SafeResourceUrl } from '@angular/platform-browser';
import {
  type ITyroConfigProvider,
  NG_TYRO_CONFIG_PROVIDER,
} from '@principle-theorem/ng-tyro';
import { getLogsUrl, type TyroIClientUrl } from '@principle-theorem/tyro';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-tyro-logs-dialog',
  templateUrl: './tyro-logs-dialog.component.html',
  styleUrls: ['./tyro-logs-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TyroLogsDialogComponent {
  private _config: ITyroConfigProvider = inject(NG_TYRO_CONFIG_PROVIDER);
  env$: Observable<TyroIClientUrl>;
  logsUrl$: Observable<SafeResourceUrl>;

  constructor(sanitizer: DomSanitizer) {
    this.env$ = this._config.getiClientBaseUrl$();
    this.logsUrl$ = this.env$.pipe(
      map((iClientEnv) => getLogsUrl(iClientEnv)),
      map((url) => sanitizer.bypassSecurityTrustResourceUrl(url))
    );
  }
}
