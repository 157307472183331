export enum InvoiceAction {
  Created = 'Created',
  Paid = 'Paid',
  Issued = 'Issued',
  WrittenOff = 'written-off',
  Cancelled = 'cancelled',
  TransactionAdded = 'TransactionAdded',
}

export class StatusChange<T extends string> {
  constructor(private _before?: T, private _after?: T) {}

  changedTo(status: T): boolean {
    return this._before !== status && this._after === status;
  }

  created(): boolean {
    return this._before === undefined;
  }

  deleted(): boolean {
    return this._after === undefined;
  }
}
