import { toChartedRef } from '@principle-theorem/principle-core';
import {
  Arch,
  ChartableSurface,
  type IChartedRef,
  type IDentalChartView,
  type IDentalChartViewMouth,
  type IDentalChartViewTooth,
  type ITooth,
  Quadrant,
} from '@principle-theorem/principle-core/interfaces';
import { compact } from 'lodash';
import { SurfaceState } from '../models/surface';
import { ChartArch, type IChartArch } from './chart-arch';
import { ChartElement, type IChartElement } from './chart-element';
import { ChartQuadrant, type IChartQuadrant } from './chart-quadrant';
import { ChartQuadrantMap } from './chart-quadrant-map';
import { ChartRow, type IChartRow } from './chart-row';
import { ChartTooth, type IChartTooth } from './chart-tooth';
import { getDentalViewForChartedSurface } from './helpers';

export const ROW_SPACING = 50;
export const CHART_MARGIN = 100;

export interface IChartRenderView {
  mouth: IDentalChartViewMouth;
  arches: IChartArch[];
  rows: IChartRow[];
  element: IChartElement;
  viewBox: string;
}

export class ChartSVGLayoutRenderer {
  render: IChartRenderView;

  constructor(teeth: ITooth[], view: IDentalChartView, stacked: boolean) {
    const chartTeeth = this._initTeeth(teeth, view);
    const quadrants = this._initQuadrants(chartTeeth, view);
    const rows = this._initRows(quadrants, stacked);
    const arches = this._initArches(rows, view);
    const mouth = getDentalViewForChartedSurface(
      { wholeMouth: true },
      view
    ) || {
      id: {
        wholeMouth: true,
      },
      badge: 0,
      state: SurfaceState.Default,
      chartedItems: [],
    };

    this._setRowAndQuadrantPositions(arches, rows, quadrants.toArray());
    const archHeight: number = this._getArchHeight(arches);
    const numColumns: number = this._getMaxNumberOfColumns(rows);
    const numRows: number = rows.length;
    const quadrantHeight: number = ChartElement.getLargestHeight(
      quadrants.toArray()
    );
    const quadrantWidth: number = ChartElement.getLargestWidth(
      quadrants.toArray()
    );

    const element: IChartElement = new ChartElement().toInterface();
    element.width = quadrantWidth * numColumns + CHART_MARGIN * 2;
    element.height =
      archHeight + (quadrantHeight + ROW_SPACING) * numRows - ROW_SPACING;
    const viewBox = `0 0 ${Math.abs(element.width)} ${Math.abs(
      element.height
    )}`;
    element.transform = ChartElement.calculateTransform(element);

    this._renderArches(arches, element);

    this.render = {
      mouth,
      element,
      viewBox,
      rows,
      arches,
    };
  }

  getVisibleQuadrants(rows: IChartRow[]): IChartQuadrant[] {
    return rows.reduce((all: IChartQuadrant[], row: IChartRow) => {
      return [...all, ...row.quadrants];
    }, []);
  }

  private _initTeeth(teeth: ITooth[], view: IDentalChartView): IChartTooth[] {
    return compact(
      teeth.map((tooth: ITooth) => {
        const chartedRef: Partial<IChartedRef> = toChartedRef({
          surface: ChartableSurface.WholeTooth,
          tooth,
        });
        try {
          return new ChartTooth(
            tooth,
            getDentalViewForChartedSurface(
              chartedRef,
              view
            ) as IDentalChartViewTooth
          ).toInterface();
        } catch (error) {
          return;
        }
      })
    );
  }

  private _initQuadrants(
    teeth: IChartTooth[],
    view: IDentalChartView
  ): ChartQuadrantMap {
    const quadrantMap: ChartQuadrantMap = new ChartQuadrantMap(view);
    quadrantMap.assignTeeth(teeth);
    quadrantMap
      .toArray()
      .map((quadrant: IChartQuadrant) => ChartQuadrant.setSize(quadrant));
    return this._standardiseQuadrantSizes(quadrantMap);
  }

  private _initRows(
    quadrants: ChartQuadrantMap,
    stacked: boolean
  ): IChartRow[] {
    const rows: IChartRow[] = [];
    if (stacked) {
      rows.push(...this._rowPerQuadrant(quadrants));
    } else {
      rows.push(...this._rowPerArch(quadrants));
    }
    return this._removeEmptyColumns(rows);
  }

  private _initArches(rows: IChartRow[], view: IDentalChartView): IChartArch[] {
    const arches: IChartArch[] = [];

    const hasUpperArch: boolean = rows.some((row: IChartRow) =>
      ChartRow.isUpperArch(row)
    );
    if (hasUpperArch) {
      const chartedRef: Partial<IChartedRef> = toChartedRef({
        surface: ChartableSurface.Arch,
        area: Arch.Upper,
      });

      try {
        const display = getDentalViewForChartedSurface(chartedRef, view);
        if (!display) {
          throw new Error(`Couldn't find view for charted ref`);
        }
        const upper: IChartArch = new ChartArch(
          Arch.Upper,
          display
        ).toInterface();
        arches.push(upper);
      } catch (error) {
        // Do nuzing!
      }
    }

    const hasLowerArch: boolean = rows.some(
      (row: IChartRow) => !ChartRow.isUpperArch(row)
    );
    if (hasLowerArch) {
      const chartedRef: Partial<IChartedRef> = toChartedRef({
        surface: ChartableSurface.Arch,
        area: Arch.Lower,
      });

      try {
        const display = getDentalViewForChartedSurface(chartedRef, view);
        if (!display) {
          throw new Error(`Couldn't find view for charted ref`);
        }
        const lower: IChartArch = new ChartArch(
          Arch.Lower,
          display
        ).toInterface();
        arches.push(lower);
      } catch (error) {
        // Do nuzing!
      }
    }
    return arches;
  }

  private _rowPerArch(quadrants: ChartQuadrantMap): IChartRow[] {
    return [
      new ChartRow(
        compact([
          quadrants.findQuadrant(Quadrant.AdultUpperRight),
          quadrants.findQuadrant(Quadrant.AdultUpperLeft),
        ])
      ),
      new ChartRow(
        compact([
          quadrants.findQuadrant(Quadrant.DeciduousUpperRight),
          quadrants.findQuadrant(Quadrant.DeciduousUpperLeft),
        ])
      ),
      new ChartRow(
        compact([
          quadrants.findQuadrant(Quadrant.DeciduousLowerRight),
          quadrants.findQuadrant(Quadrant.DeciduousLowerLeft),
        ])
      ),
      new ChartRow(
        compact([
          quadrants.findQuadrant(Quadrant.AdultLowerRight),
          quadrants.findQuadrant(Quadrant.AdultLowerLeft),
        ])
      ),
    ]
      .filter((row: IChartRow) => !ChartRow.isEmpty(row))
      .map((row: ChartRow) => row.toInterface());
  }

  private _rowPerQuadrant(quadrants: ChartQuadrantMap): IChartRow[] {
    return [
      new ChartRow(compact([quadrants.findQuadrant(Quadrant.AdultUpperRight)])),
      new ChartRow(
        compact([quadrants.findQuadrant(Quadrant.DeciduousUpperRight)])
      ),
      new ChartRow(compact([quadrants.findQuadrant(Quadrant.AdultUpperLeft)])),
      new ChartRow(
        compact([quadrants.findQuadrant(Quadrant.DeciduousUpperLeft)])
      ),
      new ChartRow(
        compact([quadrants.findQuadrant(Quadrant.DeciduousLowerLeft)])
      ),
      new ChartRow(compact([quadrants.findQuadrant(Quadrant.AdultLowerLeft)])),
      new ChartRow(
        compact([quadrants.findQuadrant(Quadrant.DeciduousLowerRight)])
      ),
      new ChartRow(compact([quadrants.findQuadrant(Quadrant.AdultLowerRight)])),
    ]
      .filter((row: ChartRow) => !ChartRow.isEmpty(row))
      .map((row: ChartRow) => row.toInterface());
  }

  private _standardiseQuadrantSizes(
    quadrantMap: ChartQuadrantMap
  ): ChartQuadrantMap {
    const quadrants: IChartQuadrant[] = quadrantMap.toArray();
    const quadrantHeight: number = ChartElement.getLargestHeight(quadrants);
    const quadrantWidth: number = ChartElement.getLargestWidth(quadrants);
    quadrants.map((quadrant: IChartQuadrant) => {
      quadrant.width = quadrantWidth;
      quadrant.height = quadrantHeight;
    });
    return quadrantMap;
  }

  private _removeEmptyColumns(rows: IChartRow[]): IChartRow[] {
    this._getEmptyColumnIndexes(rows).map((emptyColumn: number) => {
      rows.map((row: IChartRow) => {
        row.quadrants.splice(emptyColumn, 1);
        return row;
      });
    });
    return rows;
  }

  private _getEmptyColumnIndexes(rows: IChartRow[]): number[] {
    const highestRowLength: number = rows.reduce(
      (highest: number, row: IChartRow) => {
        return highest > row.quadrants.length ? highest : row.quadrants.length;
      },
      0
    );

    const emptyColumns: number[] = [];
    for (let index = 0; index < highestRowLength; index++) {
      if (this._isColumnEmpty(index, rows)) {
        emptyColumns.push(index);
      }
    }
    return emptyColumns;
  }

  private _isColumnEmpty(index: number, rows: IChartRow[]): boolean {
    return rows.every((row: IChartRow) => {
      const quadrant: IChartQuadrant | undefined = row.quadrants[index];
      return quadrant ? ChartQuadrant.isEmpty(quadrant) : true;
    });
  }

  private _setRowAndQuadrantPositions(
    arches: IChartArch[],
    rows: IChartRow[],
    quadrants: IChartQuadrant[]
  ): void {
    const upperArch: IChartArch | undefined = arches.find(
      (arch: IChartArch) => arch.arch === Arch.Upper
    );

    const yStart: number = upperArch ? upperArch.height : 0;
    const quadrantHeight: number = ChartElement.getLargestHeight(quadrants);
    const quadrantWidth: number = ChartElement.getLargestWidth(quadrants);
    rows.map((row: IChartRow, rowIndex: number) => {
      row.yPos = yStart + rowIndex * (quadrantHeight + ROW_SPACING);
      row.quadrants.map((quadrant: IChartQuadrant, columnIndex: number) => {
        quadrant.xPos = CHART_MARGIN + columnIndex * quadrantWidth;
        quadrant.transform = ChartElement.calculateTransform(quadrant);
      });
      row.transform = ChartElement.calculateTransform(row);
    });
  }

  private _getMaxNumberOfColumns(rows: IChartRow[]): number {
    return rows.reduce((largest: number, row: IChartRow) => {
      return largest > row.quadrants.length ? largest : row.quadrants.length;
    }, 0);
  }

  private _getArchHeight(arches: IChartArch[]): number {
    return arches
      .map((arch: IChartArch) => arch.height)
      .reduce((prev: number, current: number) => prev + current, 0);
  }

  private _renderArches(arches: IChartArch[], element: IChartElement): void {
    arches.map((arch: IChartArch) => {
      arch.width = element.width;
      if (arch.arch === Arch.Lower) {
        arch.yPos = element.height - arch.height;
      }
      arch.transform = ChartElement.calculateTransform(arch);
    });
  }
}
