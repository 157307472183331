import {
  IChartedItemConfiguration,
  IPricedServiceCodeEntry,
  IPricingRule,
  IServiceCodeEntry,
  PricingRuleType,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  reduceToSingleArrayFn,
  snapshot,
  toNamedDocument,
  WithRef,
} from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';
import { FeeScheduleManager } from '../fees/fee-schedule/fee-schedule-manager';
import { PricingRule } from '../fees/pricing-rules/pricing-rule';
import { PricedServiceCodeTranslator } from '../service-items/service-item';
import { ServiceProviderHandler } from './service-provider';

export class ServiceCodeEntry {
  static getName(entry: IServiceCodeEntry): string | undefined {
    const resolvedCode = ServiceProviderHandler.resolveServiceCode(
      entry.type,
      entry.code
    );
    if (!resolvedCode) {
      return;
    }
    return `${resolvedCode.code} - ${resolvedCode.title}`;
  }
}

export class PricedServiceCodeEntry {
  static init(
    overrides: AtLeast<IPricedServiceCodeEntry, 'type' | 'code' | 'taxStatus'>
  ): IPricedServiceCodeEntry {
    return {
      uuid: uuid(),
      price: 0,
      tax: 0,
      quantity: 1,
      chartedSurfaces: [],
      pricingRule: PricingRule.init(),
      ...overrides,
    };
  }

  static duplicateCodes(
    codes: IPricedServiceCodeEntry[]
  ): IPricedServiceCodeEntry[] {
    return codes.map((code) => ({
      ...code,
      uuid: uuid(),
    }));
  }

  static expandComplexPricedCodes(
    serviceCodes: IPricedServiceCodeEntry[]
  ): IPricedServiceCodeEntry[] {
    return serviceCodes
      .map((serviceCode) => {
        const existingCodes = serviceCodes.filter(
          (code) =>
            code.code === serviceCode.code && code.type === serviceCode.type
        );
        const isProcessed = existingCodes.length >= serviceCode.quantity;

        if (
          serviceCode.pricingRule.type === PricingRuleType.Flat ||
          isProcessed
        ) {
          return [serviceCode];
        }

        return Array.from(Array(serviceCode.quantity), () => {
          return {
            ...serviceCode,
            uuid: uuid(),
            quantity: 1,
          };
        });
      })
      .reduce(reduceToSingleArrayFn, []);
  }

  static fromServiceCodeEntry(
    entry: IServiceCodeEntry,
    origin: WithRef<IChartedItemConfiguration>
  ): IPricedServiceCodeEntry {
    const code = ServiceProviderHandler.resolveServiceCode(
      entry.type,
      entry.code
    );
    if (!code) {
      throw new Error(`No code found ${entry.code}`);
    }
    return PricedServiceCodeEntry.init({
      ...entry,
      taxStatus: code.taxStatus,
      chartedItemOrigin: toNamedDocument(origin),
    });
  }

  static async applyFeeSchedule(
    entry: IPricedServiceCodeEntry,
    stepServiceCodes: IPricedServiceCodeEntry[],
    manager: FeeScheduleManager,
    convertServiceCodes: boolean,
    changingFeeSchedule: boolean = false
  ): Promise<IPricedServiceCodeEntry> {
    const matchingServiceCodes = stepServiceCodes.filter(
      (stepServiceCode) => stepServiceCode.code === entry.code
    );

    const itemIndex = matchingServiceCodes.findIndex(
      (stepServiceCode) => stepServiceCode.uuid === entry.uuid
    );

    const currentSchedule = await snapshot(manager.currentSchedule$);
    if (currentSchedule.serviceCodeType !== entry.type) {
      convertServiceCodes = true;
    }
    const convertedCode = convertServiceCodes
      ? PricedServiceCodeTranslator.convertToType(
          entry,
          currentSchedule.serviceCodeType
        )
      : entry;

    const resolvedPricingRule = await manager.getFeeByCode(convertedCode);
    const pricingRule = determinePricingRule(
      resolvedPricingRule,
      entry.pricingRule
    );

    const price =
      PricingRule.calculatePrice(
        pricingRule,
        matchingServiceCodes.length,
        itemIndex
      ) ?? 0;

    const selectedPricingRule = {
      ...pricingRule,
      ruleItems: pricingRule.ruleItems.map((ruleItem) => {
        if (ruleItem.price === price) {
          return {
            ...ruleItem,
            selected: true,
          };
        }
        return ruleItem;
      }),
    };

    const priceOverride = !changingFeeSchedule
      ? PricedServiceCodeEntry.getPriceOverride(entry, pricingRule, price)
      : undefined;

    return {
      ...entry,
      ...convertedCode,
      pricingRule: selectedPricingRule,
      price,
      priceOverride,
    };
  }

  // Can this be deprecated?
  // https://app.clickup.com/t/860r06fkc
  static getPriceOverride(
    entry: IPricedServiceCodeEntry,
    pricingRule: IPricingRule,
    calculatedPrice: number
  ): number | undefined {
    if (pricingRule.type !== PricingRuleType.Flat) {
      return entry.priceOverride;
    }
    return entry.priceOverride !== undefined
      ? entry.priceOverride
      : !!entry.price && entry.price !== calculatedPrice
        ? entry.price
        : undefined;
  }
}

function determinePricingRule(
  newPricingRule: IPricingRule,
  oldPricingRule: IPricingRule
): IPricingRule {
  if (newPricingRule.type !== PricingRuleType.StairStep) {
    return newPricingRule;
  }
  if (oldPricingRule.type !== PricingRuleType.StairStep) {
    return newPricingRule;
  }
  return oldPricingRule;
}
