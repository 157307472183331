import { WithRef } from '@principle-theorem/shared';
import { type IContactDetails } from '../../contact/contact';
import {
  IBasePatient,
  IPatientContactDetails,
  type IPatient,
} from '../../patient/patient';
import { type ITemplateContext, type ITemplateScopeData } from '../templating';
import { type IBaseTemplateContext } from './base-context';

export interface IPatientScopeData extends ITemplateScopeData {
  patient: WithRef<IPatient>;
  medicalHistoryLink: string;
  primaryContact?: WithRef<IBasePatient & IPatientContactDetails>;
  referrer?: Partial<IContactDetails>;
}

export interface IPatientTemplateContext
  extends ITemplateContext,
    IBaseTemplateContext {
  patientEmail: string;
  patientFullName: string;
  patientFirstName: string;
  patientLastName: string;
  patientMobile: string;
  patientDateOfBirth: string;
  patientAddress: string;
  patientMedicareNumber: string;
  referrerName: string;
  referrerEmail: string;
  referrerAddress: string;
  referrerJobTitle: string;
  medicalHistoryLink: string;
}
