import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { type IOrganisation } from '@principle-theorem/principle-core/interfaces';
import { Organisation } from '@principle-theorem/principle-core';
import {
  getDoc$,
  type ResolverResult,
  snapshot,
} from '@principle-theorem/shared';

@Injectable()
export class OrganisationResolver {
  resolve(route: ActivatedRouteSnapshot): ResolverResult<IOrganisation> {
    const uid: string = route.paramMap.get('uid') || '';
    return snapshot(getDoc$(Organisation.col(), uid));
  }
}
