import {
  IContextBuilder,
  IPracticeScopeData,
  IPracticeTemplateContext,
} from '@principle-theorem/principle-core/interfaces';
import { BaseContextBuilder } from './base-context';

export class PracticeContextBuilder
  implements IContextBuilder<IPracticeTemplateContext>
{
  private _baseContextBuilder = new BaseContextBuilder();

  constructor(private _data: IPracticeScopeData) {}

  build(): IPracticeTemplateContext {
    return {
      ...this._baseContextBuilder.build(),
      practiceName: this._data.practice.name,
      practiceEmail: this._data.practice.email,
      practicePhoneNumber: this._data.practice.phone,
      practiceAddress: this._data.practice.address,
    };
  }
}
