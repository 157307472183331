import { Brand, toINamedDocuments } from '@principle-theorem/principle-core';
import {
  CustomMappingAssociatedValueType,
  CustomMappingOption,
  CustomMappingType,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
  type ITag,
  type ITreatmentCategory,
} from '@principle-theorem/principle-core/interfaces';
import {
  XSLXImporterExporter,
  asyncForEach,
  multiSortBy$,
  nameSorter,
  query$,
  snapshot,
  undeletedQuery,
  type INamedDocument,
  type IReffable,
  type WithRef,
  IBlobFilenamePair,
  toNamedDocument,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import {
  EventClassSourceEntity,
  type ICorePracticeEventClass,
} from '../../source/entities/event-classes';
import {
  EventClassToTreatmentCategoryToXSLX,
  EventClassResourceType,
  type EventClassDestination,
} from './event-class-to-treatment-category-to-xslx';
import { XSLXToEventClassToTreatmentCategory } from './xslx-to-event-class-treatment-category';
import { Observable, from, of } from 'rxjs';

export const EVENT_CLASS_TO_TREATMENT_CATEGORY_MAPPING_TYPE =
  'eventClassToTreatmentCategory';

export const EVENT_CLASS_TO_TREATMENT_CATEGORY_MAPPING = CustomMapping.init({
  metadata: {
    label: 'Event Class to Treatment Category',
    description: `
        Used to map event class in Core Practice to treatment categories in Principle.
      `,
    type: EVENT_CLASS_TO_TREATMENT_CATEGORY_MAPPING_TYPE,
  },
  type: CustomMappingType.SelectionList,
});

export class CorePracticeEventClassToTreatmentCategoryMappingHandler extends BaseCustomMappingHandler<EventClassDestination> {
  customMapping = EVENT_CLASS_TO_TREATMENT_CATEGORY_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const records = await this._getSourceCategories(migration);
    return sortBy(
      records.map((record) => ({
        label: record.description,
        value: record.id.toString(),
      })),
      'label'
    );
  }

  async getSelectionListOptions(
    _migration: WithRef<IPracticeMigration>
  ): Promise<CustomMappingOption[]> {
    return snapshot(
      of([
        {
          value: EventClassResourceType.TreatmentCategory,
          description: 'Map this to a treatment category',
          hasAssociatedValue: true,
          associatedValueType: CustomMappingAssociatedValueType.NamedDocument,
          associatedValueDescription:
            'Which treatment category should be used for this?',
        },
        {
          value: EventClassResourceType.AppointmentTag,
          description: 'Map this to an appointment tag',
          hasAssociatedValue: true,
          associatedValueType: CustomMappingAssociatedValueType.NamedDocument,
          associatedValueDescription:
            'Which appointment tag should be used for this?',
        },
      ])
    );
  }

  async getTreatmentCategories(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<ITreatmentCategory>[]> {
    return snapshot(
      query$(
        undeletedQuery(
          Brand.treatmentCategoryCol(migration.configuration.brand)
        )
      ).pipe(toINamedDocuments(), multiSortBy$(nameSorter()))
    );
  }

  async getAppointmentTags(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<ITag>[]> {
    return snapshot(
      query$(
        undeletedQuery(Brand.appointmentTagCol(migration.configuration.brand))
      ).pipe(toINamedDocuments(), multiSortBy$(nameSorter()))
    );
  }
  getAssociatedValueOptions$(
    migration: WithRef<IPracticeMigration>,
    destinationValue: EventClassResourceType
  ): Observable<INamedDocument[]> {
    if (destinationValue === EventClassResourceType.AppointmentTag) {
      return from(this.getAppointmentTags(migration));
    }
    return from(this.getTreatmentCategories(migration));
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, appointmentCategories, translator } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(
      fileName,
      appointmentCategories,
      translator
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, appointmentCategories, translator } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(
      fileName,
      appointmentCategories,
      translator
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToEventClassToTreatmentCategory()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const sourceOptions = await this.getSourceOptions(migration);
    const treatmentCategories = await this.getTreatmentCategories(migration);
    const appointmentTags = await this.getAppointmentTags(migration);

    await asyncForEach(items, async (item) => {
      const matchingOption = sourceOptions.find(
        (option) => option.label === item.label
      );
      if (!matchingOption) {
        return;
      }

      const destination = [
        ...treatmentCategories.map((category) => ({
          ...category,
          destinationValue: EventClassResourceType.TreatmentCategory,
        })),
        ...appointmentTags.map((tag) => ({
          ...tag,
          destinationValue: EventClassResourceType.AppointmentTag,
        })),
      ].find((category) => category.name === item.mapTo);

      if (!destination) {
        // eslint-disable-next-line no-console
        console.error(
          `Mapping error: ${this.customMapping.metadata.label} - Couldn't find destination for item`,
          item
        );
        return;
      }

      await this.upsertRecord(
        {
          associatedValue: toNamedDocument<EventClassDestination>(destination),
          destinationValue: destination.destinationValue,
          sourceIdentifier: matchingOption.value,
          sourceLabel: matchingOption.label,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    appointmentCategories: ICorePracticeEventClass[];
    translator: EventClassToTreatmentCategoryToXSLX;
  }> {
    const fileName = this.getFileName();
    const appointmentCategories = await this._getSourceCategories(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const translator = new EventClassToTreatmentCategoryToXSLX(
      await this.getRecords(translationMap),
      await this.getTreatmentCategories(migration),
      await this.getAppointmentTags(migration)
    );
    return { fileName, appointmentCategories, translator };
  }

  private async _getSourceCategories(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICorePracticeEventClass[]> {
    const appointmentCategories = new EventClassSourceEntity();
    const records = await appointmentCategories
      .getRecords$(migration, 1000)
      .toPromise();
    return sortBy(
      records.map((record) => record.data.data),
      'name'
    );
  }
}
