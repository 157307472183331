import { type IMedipassWebhookData } from '@medipass/partner-sdk';
import { isMedipassWebhookData } from '@principle-theorem/medipass';
import { type DocumentReference } from '@principle-theorem/shared';
import { isDocRef, TypeGuard, isObject } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type IPractice } from '../../practice/practice';
import {
  IHealthcareClaim,
  isHealthcareClaim,
} from '../../invoice/healthcare-claim';

export interface IMedipassInternalWebhookData extends IMedipassWebhookData {
  funder: string;
  claim?: IHealthcareClaim;
  practiceRef: DocumentReference<IPractice>;
}

export function isMedipassInternalWebhookData(
  data: unknown
): data is IMedipassInternalWebhookData {
  return (
    isObject(data) &&
    isMedipassWebhookData(data) &&
    isString(data.funder) &&
    isDocRef<IPractice>(data.practiceRef) &&
    TypeGuard.nilOr(isHealthcareClaim)(data.claim) &&
    TypeGuard.nilOr(isDocRef)(data.practiceRef)
  );
}
