import { NgModule } from '@angular/core';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ComponentsModule } from '../components/components.module';
import { GapsComponent } from './gaps/gaps.component';

@NgModule({
  declarations: [GapsComponent],
  imports: [
    NgMaterialModule,
    ComponentsModule,
    NgSharedModule,
    NgFeatureFlagsModule,
    NgPrincipleSharedModule,
  ],
})
export class PagesModule {}
