import { EditorPresetsService } from '@principle-theorem/ng-interactions';
import { ManagementService } from '@principle-theorem/ng-principle-shared';
import { Subject } from 'rxjs';
import { RenderedTemplateMigrator } from './rendered-template-migrator';

export interface IMigrator {
  migrate: (
    dryRun: boolean,
    logger: Subject<string>
  ) => Promise<void> | Promise<void[]>;
}

enum MigratorStatus {
  Started = 'Started ',
  Finished = 'Finished',
}

export class Migrator implements IMigrator {
  logger$: Subject<string> = new Subject<string>();

  constructor(
    protected _management: ManagementService,
    protected _editorPresets: EditorPresetsService,
    public dryRun: boolean = true
  ) {}

  async migrate(): Promise<void> {
    await this._migrateRenderedTemplates();
  }

  async migrateWithEvents(): Promise<void> {
    this._nextEvent(MigratorStatus.Started);
    await this.migrate();
    this._nextEvent(MigratorStatus.Finished);
  }

  _nextEvent(status: MigratorStatus): void {
    this.logger$.next(`${status} ${this.name}`);
  }

  get name(): string {
    return this.constructor.name.split(/(?=[A-Z])/).join(' ');
  }

  private async _migrateRenderedTemplates(): Promise<void> {
    const migrator: IMigrator = new RenderedTemplateMigrator(
      this._editorPresets.defaultToHTMLExtensions()
    );
    await migrator.migrate(this.dryRun, this.logger$);
  }
}
