import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScheduleSummaryEventActionsService } from '@principle-theorem/ng-principle-shared';
import { Appointment, Interaction } from '@principle-theorem/principle-core';
import {
  InteractionType,
  isInteractionV2,
  type IAppointment,
  type IInteraction,
  type IInteractionV2,
} from '@principle-theorem/principle-core/interfaces';
import {
  deleteDoc,
  isWithRef,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, ReplaySubject, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-appointment-interactions',
  templateUrl: './appointment-interactions.component.html',
  styleUrls: ['./appointment-interactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentInteractionsComponent {
  private _appointment$ = new ReplaySubject<WithRef<IAppointment>>(1);
  interactions$: Observable<WithRef<IInteractionV2>[]>;
  pinnedInteractions$: Observable<(IInteractionV2 | IInteraction)[]>;
  compact$ = new BehaviorSubject<boolean>(false);
  enableScroll$ = new BehaviorSubject<boolean>(false);
  readOnly$ = new BehaviorSubject<boolean>(false);
  pinnableTypes = [
    InteractionType.Note,
    InteractionType.Call,
    InteractionType.Appointment,
  ];

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this._appointment$.next(appointment);
    }
  }

  @Input()
  set compact(compact: BooleanInput) {
    this.compact$.next(coerceBooleanProperty(compact));
  }

  @Input()
  set enableScroll(enableScroll: BooleanInput) {
    this.enableScroll$.next(coerceBooleanProperty(enableScroll));
  }

  @Input()
  set readOnly(readOnly: BooleanInput) {
    this.readOnly$.next(coerceBooleanProperty(readOnly));
  }

  constructor(
    public snackBar: MatSnackBar,
    private _scheduleSummaryEventActions: ScheduleSummaryEventActionsService
  ) {
    this.interactions$ = this._appointment$.pipe(
      switchMap((appointment) => Appointment.interactions$(appointment))
    );
    this.pinnedInteractions$ = this.interactions$.pipe(
      map((interactions) =>
        Interaction.getPinnedInteractions(interactions).reverse()
      )
    );
  }

  async addInteraction(interaction: IInteractionV2): Promise<void> {
    const appointment = await snapshot(this._appointment$);
    await Appointment.addInteraction(appointment, interaction);
    await this._updateScheduleSummary();
  }

  async updateInteraction(
    interaction: IInteraction | WithRef<IInteractionV2>
  ): Promise<void> {
    if (!isInteractionV2(interaction) || !isWithRef(interaction)) {
      return;
    }
    await Interaction.amend(interaction);
    await this._updateScheduleSummary();
  }

  async deleteInteraction(
    interaction: IInteraction | WithRef<IInteractionV2>
  ): Promise<void> {
    if (!isInteractionV2(interaction) || !isWithRef(interaction)) {
      return;
    }
    await deleteDoc(interaction.ref);
    await this._updateScheduleSummary();
  }

  private async _updateScheduleSummary(): Promise<void> {
    const appointment = await snapshot(this._appointment$);
    await this._scheduleSummaryEventActions.updateAppointmentSummary(
      appointment,
      appointment
    );
  }
}
