import { CustomReport } from '@principle-theorem/principle-core';
import {
  IBaseChartBuilderConfig,
  ICustomReportChart,
  ICustomReportDefinedColumn,
  MeasureReducer,
  type ICustomChartSettings,
  type ICustomReportChartSection,
  type ICustomReportColumn,
  type ReportBuilderDataSourceId,
  type ReportingReference,
  RETIRED_REPORT_BUILDER_DATA_SOURCE_IDS,
} from '@principle-theorem/principle-core/interfaces';
import {
  ICanGroupMeasuresProperty,
  IChartBuilderData,
  type BaseMeasures,
  type ICanQueryByTimestampProperty,
  type IChartConfig,
  type IReportingProperty,
} from '@principle-theorem/reporting';
import { compact, groupBy, toPairs } from 'lodash';
import {
  ITableBuilder,
  type IDCChartBuilder,
} from '../../reporting/core/chart-builders/dc-chart-builders/dc-chart-builder';
import { type IResolvedColumnProperty } from '../../reporting/report-builder/report-builder-results-table/report-builder-column-selector-dialog/resolved-columns';
import { type IDataPoint } from '../report/charts/measure-data-interfaces';
import { ReportBuilderChartFilters } from './report-builder-chart-filters';
import { ReportBuilderHelpers } from './report-builder-helpers';

export interface IChartFilterResult<
  T extends IBaseChartBuilderConfig = IBaseChartBuilderConfig,
> {
  config: IChartConfig;
  builder: IDCChartBuilder<unknown> | ITableBuilder;
  builderConfig?: Partial<T>;
  builderData?: Partial<IChartBuilderData>;
}

export interface IChartFilter<
  T extends ICustomChartSettings = ICustomChartSettings,
> {
  id: ReportingReference;
  chart: ICustomReportChart<T>;
  groupBy: ICanGroupMeasuresProperty;
  build: (
    factTable: BaseMeasures,
    chart: ICustomReportChart
  ) => IChartFilterResult;
  availableInEditMode: boolean;
}

export interface IColumnSection {
  name: string;
  columns: ICustomReportDefinedColumn[];
}

export interface IGroupBySection {
  name: string;
  options: IGroupBySectionOption[];
}

export interface IGroupBySectionOption {
  measure: ICanGroupMeasuresProperty;
  compatibleReducers: MeasureReducer[];
}

export interface IQueryableTimestamp {
  property: ICanQueryByTimestampProperty;
}

export interface IReportBuilderDataSource {
  id: ReportBuilderDataSourceId;
  name: string;
  description: string;
  factTable: BaseMeasures;
  queryableTimestamps: IQueryableTimestamp[];
  groupByOptions: IGroupBySection[];
  availableCharts: IChartFilter<ICustomChartSettings>[];
  availableColumns: IColumnSection[];
  defaultColumns: ReportingReference[];
}

export class ReportBuilderDataSource {
  static isRetired(dataSource: IReportBuilderDataSource): boolean {
    return RETIRED_REPORT_BUILDER_DATA_SOURCE_IDS.includes(dataSource.id);
  }

  static buildChart(
    dataSource: IReportBuilderDataSource,
    chart: ICustomReportChart
  ): IChartFilterResult | undefined {
    const chartGroupBy = dataSource.groupByOptions
      .flatMap((section) => section.options)
      .find((item) => item.measure.metadata.id === chart.groupBy);
    if (!chartGroupBy) {
      // eslint-disable-next-line no-console
      console.error(
        `ReportBuilderDataSource.buildChart - no chartGroupBy for ${dataSource.id} - ${chart.groupBy}`
      );
      return;
    }

    const measures = compact(
      chart.settings.map((setting) => {
        const measure = this.findColumn(dataSource, setting.dataPoint)?.measure;
        if (!measure) {
          if (dataSource.factTable.count.metadata.id === setting.dataPoint) {
            return {
              uid: setting.uid,
              measure: CustomReport.definedColumn(
                dataSource.factTable.count,
                dataSource.factTable.count.metadata.id,
                dataSource.factTable.count.metadata.label
              ).measure,
              reducer: setting.reducer,
              filters: setting.filters,
              label: setting.label,
            };
          }
          return;
        }

        return {
          uid: setting.uid,
          measure,
          reducer: setting.reducer,
          filters: setting.filters,
          label: setting.label,
        };
      })
    ).flat();

    if (!measures.length) {
      // eslint-disable-next-line no-console
      console.error(
        `ReportBuilderDataSource.buildChart for ${dataSource.id} - measures`,
        measures,
        chart
      );
    }

    const chartFilter = ReportBuilderChartFilters.configureChart(
      chart,
      chartGroupBy.measure,
      measures
    );

    if (!chartFilter) {
      return;
    }

    return chartFilter.build(dataSource.factTable, chart);
  }

  static allColumns(
    dataSource?: IReportBuilderDataSource
  ): ICustomReportDefinedColumn[] {
    if (!dataSource) {
      return [];
    }
    return dataSource?.availableColumns
      .map((section) => section.columns)
      .flat();
  }

  static findColumn(
    dataSource: IReportBuilderDataSource | undefined,
    columnId: string
  ): ICustomReportDefinedColumn | undefined {
    return this.allColumns(dataSource).find((column) => column.id === columnId);
  }

  static buildColumnSections(
    dataSource?: IReportBuilderDataSource
  ): IColumnSection[] {
    return dataSource?.availableColumns ?? [];
  }

  static buildChartSections(
    dataSource?: IReportBuilderDataSource
  ): ICustomReportChartSection[] {
    if (!dataSource) {
      return [];
    }
    const availableCharts = dataSource.availableCharts.filter(
      (chart) => chart.availableInEditMode
    );

    return this.groupBySection(availableCharts, (chart) => chart.id).map(
      ({ section, items }) => {
        const charts = items.map((chart) => chart.chart);
        return CustomReport.chartSection(section, charts);
      }
    );
  }

  static groupBySection<T>(
    all: T[],
    getId: (item: T) => string,
    noSection: string = 'general'
  ): { section: string; items: T[] }[] {
    return toPairs(
      groupBy(all, (item) =>
        ReportBuilderHelpers.getSectionFromPrefix(getId(item), noSection)
      )
    ).map(([section, items]) => ({ section, items }));
  }

  static getDefaultColumns(
    dataSource?: IReportBuilderDataSource
  ): ICustomReportColumn[] {
    if (!dataSource) {
      return [];
    }
    const defaultColumns = dataSource.defaultColumns.map((column) =>
      this.findColumn(dataSource, column)
    );
    return compact(defaultColumns);
  }

  static getDataPoint(
    column: IResolvedColumnProperty,
    fact: Record<string, unknown>
  ): IDataPoint | undefined {
    return {
      label: this.getPropertyLabel(column.property),
      formatter: column.property.metadata.formatter,
      formatterValue: column.property.metadata.formatterValue,
      value: column.property.measure.dataAccessor(fact),
    };
  }

  static getPropertyLabel(property: IReportingProperty): string {
    return property.metadata.label;
  }
}
