import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { type ITreatmentConfiguration } from '@principle-theorem/principle-core/interfaces';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type IEducationalAid } from '@principle-theorem/principle-core/interfaces';
import { User } from '@principle-theorem/principle-core';
import {
  filterUndefined,
  patchDoc,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  getDownloadURL,
  UploadTask,
  UploadTaskSnapshot,
} from '@angular/fire/storage';

@Component({
    selector: 'pr-educational-aids',
    templateUrl: './educational-aids.component.html',
    styleUrls: ['./educational-aids.component.scss'],
    standalone: false
})
export class EducationalAidsComponent {
  trackByAid = TrackByFunctions.field<IEducationalAid>('path');
  uploadPath$: Observable<string>;
  @Input() treatmentConfig: WithRef<ITreatmentConfiguration>;

  constructor(
    private _organisation: OrganisationService,
    private _snackBar: MatSnackBar
  ) {
    this.uploadPath$ = this._organisation.user$.pipe(
      filterUndefined(),
      map((user) => `${User.storagePath(user)}/educational-aids`)
    );
  }

  async startedUpload(task: UploadTask): Promise<void> {
    const taskSnapshot = await task;
    const aid = await this._getAidFromSnapshot(taskSnapshot);
    await this.add(aid);
  }

  async add(aid: IEducationalAid): Promise<void> {
    this.treatmentConfig.educationalAids.push(aid);
    await this._save({ educationalAids: this.treatmentConfig.educationalAids });
  }

  async delete(aid: IEducationalAid): Promise<void> {
    const index: number | undefined =
      this.treatmentConfig.educationalAids.indexOf(aid);

    if (index < 0) {
      return;
    }
    this.treatmentConfig.educationalAids.splice(index, 1);
    await this._save({ educationalAids: this.treatmentConfig.educationalAids });
  }

  private async _getAidFromSnapshot(
    taskSnapshot: UploadTaskSnapshot
  ): Promise<IEducationalAid> {
    const url = String(await getDownloadURL(taskSnapshot.ref));
    return {
      name: taskSnapshot.ref.name,
      path: taskSnapshot.ref.fullPath,
      url,
    };
  }

  private async _save(
    changes: Partial<ITreatmentConfiguration>
  ): Promise<void> {
    await patchDoc(this.treatmentConfig.ref, changes);
    this._snackBar.open('Treatment configuration saved');
  }
}
