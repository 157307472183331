import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  signal,
} from '@angular/core';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import {
  Appointment,
  getAppointmentStepStatus,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IPatient,
  isEventable,
  type IStaffer,
  type ITreatmentCategory,
  type ITreatmentPlan,
  type ITreatmentStep,
  type PlanStepPairStatus,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  DATE_TIME_WITH_YEAR_FORMAT,
  Firestore,
  filterUndefined,
  getDoc,
  isPathChanged$,
  snapshot,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  type Observable,
  of,
  ReplaySubject,
  combineLatest,
  Subject,
} from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { TreatmentStepsEditorService } from '../treatment-steps-editor/treatment-steps-editor.service';
import { TreatmentStepsDisplayService } from '../treatment-steps-editor/treatment-steps-display.service';

@Component({
  selector: 'pr-expandable-treatment-step',
  templateUrl: './expandable-treatment-step.component.html',
  styleUrls: ['./expandable-treatment-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandableTreatmentStepComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  treatmentStep$ = new ReplaySubject<WithRef<ITreatmentStep>>(1);
  treatmentPlan$ = new ReplaySubject<WithRef<ITreatmentPlan>>(1);
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  currentPractitioner$ = new ReplaySubject<WithRef<IStaffer>>(1);
  treatmentCategory$: Observable<
    DocumentReference<ITreatmentCategory> | undefined
  >;
  appointment$: Observable<WithRef<IAppointment> | undefined>;
  expanded$ = new BehaviorSubject<boolean>(true);
  canSchedule$: Observable<boolean>;
  canDisable$: Observable<boolean>;
  status$: Observable<PlanStepPairStatus>;
  displayStep = signal(true);

  @Input()
  set currentPractitioner(currentPractitioner: WithRef<IStaffer>) {
    if (currentPractitioner) {
      this.currentPractitioner$.next(currentPractitioner);
    }
  }

  @Input()
  set expanded(expanded: BooleanInput) {
    this.expanded$.next(coerceBooleanProperty(expanded));
  }

  @Input()
  set treatmentPlan(treatmentPlan: WithRef<ITreatmentPlan>) {
    if (treatmentPlan) {
      this.treatmentPlan$.next(treatmentPlan);
    }
  }

  @Input()
  set treatmentStep(treatmentStep: WithRef<ITreatmentStep>) {
    if (treatmentStep) {
      this.treatmentStep$.next(treatmentStep);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(
    private _schedulingFacade: AppointmentSchedulingFacade,
    private _editorService: TreatmentStepsEditorService,
    private _currentScope: CurrentScopeFacade,
    private _treatmentStepsDisplay: TreatmentStepsDisplayService
  ) {
    this.appointment$ = this.treatmentStep$.pipe(
      isPathChanged$('appointment'),
      switchMap((treatmentStep) =>
        treatmentStep.appointment
          ? Firestore.doc$(treatmentStep.appointment)
          : of(undefined)
      )
    );
    this.canDisable$ = this.appointment$.pipe(
      map((appointment) => !!appointment && !isEventable(appointment))
    );
    this.canSchedule$ = combineLatest([
      this.treatmentStep$,
      this.appointment$,
    ]).pipe(
      map(
        ([treatmentStep, appointment]) =>
          !appointment && !TreatmentStep.isComplete(treatmentStep)
      )
    );
    this.treatmentCategory$ = this.treatmentStep$.pipe(
      map(({ display }) => TreatmentStep.defaultDisplayRef(display))
    );
    this.status$ = combineLatest([this.treatmentStep$, this.appointment$]).pipe(
      map(([treatmentStep, appointment]) =>
        getAppointmentStepStatus(treatmentStep, appointment)
      )
    );

    this.status$.pipe(takeUntil(this._onDestroy$)).subscribe((status) => {
      const activeFilters = this._treatmentStepsDisplay.activeStatusFilters();
      const shouldDisplay = !!activeFilters && activeFilters.includes(status);
      this.displayStep.set(shouldDisplay);
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async selectAppointment(
    step: WithRef<ITreatmentStep>,
    appointmentRef: DocumentReference<IAppointment>
  ): Promise<void> {
    const plan = await snapshot(TreatmentStep.treatmentPlan$(step));
    const appointment = await getDoc(appointmentRef);
    this._schedulingFacade.setAppointment(appointment, {
      updateSelectedSuggestion: false,
    });
    this._schedulingFacade.appointmentFormChange({
      treatment: {
        plan,
        step,
      },
    });
  }

  isUnscheduled(appointment: WithRef<IAppointment>): boolean {
    return Appointment.isUnscheduled(appointment);
  }

  isBookable(appointment: WithRef<IAppointment>): boolean {
    return (
      Appointment.isUnscheduled(appointment) ||
      Appointment.canBeCancelled(appointment)
    );
  }

  appointmentSummary(appointment: WithRef<IAppointment>): string | undefined {
    if (!appointment.event) {
      return;
    }
    return toMoment(appointment.event.from).format(DATE_TIME_WITH_YEAR_FORMAT);
  }

  async generateAppointment(
    treatmentStep: WithRef<ITreatmentStep>
  ): Promise<void> {
    const treatmentPlan = await snapshot(this.treatmentPlan$);
    const patient = await snapshot(this.patient$);
    const practitioner = await (treatmentStep.practitionerRef
      ? getDoc(treatmentStep.practitionerRef)
      : snapshot(this.currentPractitioner$));
    const practice = await snapshot(
      this._currentScope.currentPractice$.pipe(filterUndefined())
    );

    const appointment = await this._editorService.generateAppointmentForStep(
      patient,
      practitioner,
      practice,
      treatmentPlan,
      treatmentStep
    );
    await this.selectAppointment(treatmentStep, appointment.ref);
  }

  async removeAppointment(
    treatmentStep: WithRef<ITreatmentStep>
  ): Promise<void> {
    await this._editorService.removeAppointmentFromStep(treatmentStep);
  }
}
