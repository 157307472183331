<div
  *ngIf="plan$ | async as plan"
  cdkDropList
  [cdkDropListData]="listData$ | ngrxPush"
  [cdkDropListConnectedTo]="stepDragDrop.lists$ | ngrxPush"
  (cdkDropListDropped)="stepDragDrop.drop($event)"
>
  <ng-container *ngFor="let step of steps$ | ngrxPush; trackBy: trackByUuidFn">
    <pt-drag-box
      class="layout"
      cdkDrag
      cdkScrollable
      [cdkDragData]="step"
      [cdkDragDisabled]="step | map : dragDisabled$ : this | async"
    >
      <mat-icon cdkDragHandle class="drag-handle">drag_handle</mat-icon>

      <pr-treatment-step
        [step]="step"
        [plan]="plan"
        (stepChanged)="updateStep($event, step)"
        (stepDeleted)="deleteStep($event)"
        (updateChartable)="updateSurfaces($event, plan, step)"
       />
    </pt-drag-box>
  </ng-container>
</div>

<div class="add-step step-container layout" *ngIf="canAddStep$ | ngrxPush">
  <a href="javascript:void(0);" class="action-link" (click)="addStep()">
    <mat-icon>add</mat-icon>
    Create New Step
  </a>
</div>
