import { ObservableDataSource } from '@principle-theorem/ng-shared';
import {
  getMeasureResults,
  type IChartConfig,
  type IDataBuilder,
} from '@principle-theorem/reporting';
import type * as dc from 'dc';
import { from, Subject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DCDataTransformer,
  type IDCTransformResult,
} from '../../../models/report/charts/data-transformers/dc-data-transformer';
import {
  ITableBuilder,
  type IDCChartBuilder,
  type IDCChartEvent,
} from '../chart-builders/dc-chart-builders/dc-chart-builder';
import { type IDCTooltip } from '../chart-builders/dc-chart-builders/dc-tooltip';
import { ITableRow } from '../chart-builders/table-chart-builder';

export interface IDCChartDisplay<T> {
  label: string;
  chartClasses: string[];
  chart: dc.BaseMixin<T>;
  eventListener$: Subject<IDCChartEvent>;
  legend?: dc.HtmlLegend | dc.Legend;
  tooltip?: IDCTooltip;
}

export interface ITableDisplay {
  label: string;
  columns: { id: string; uid: string; label: string }[];
  chart: ObservableDataSource<ITableRow>;
  eventListener$: Subject<IDCChartEvent>;
}

export function loadDCDataResults$(
  config: IChartConfig,
  dataBuilder: IDataBuilder
): Observable<IDCTransformResult[]> {
  const transformer = new DCDataTransformer();
  return from(getMeasureResults(config, dataBuilder)).pipe(
    map((results) => transformer.transformMeasureResults(results))
  );
}

export function buildDCChartDisplay(
  chartBuilder: IDCChartBuilder<unknown>,
  config: IChartConfig,
  results: IDCTransformResult[]
): IDCChartDisplay<unknown> | undefined {
  const eventListener$ = new Subject<IDCChartEvent>();
  const chart = chartBuilder.getChart(results, config, eventListener$);
  if (!chart) {
    return;
  }
  const label = chartBuilder.getLabel(config);
  const tooltip = chartBuilder.getTooltip
    ? chartBuilder.getTooltip(config)
    : undefined;
  const legend = chartBuilder.getLegend ? chartBuilder.getLegend() : undefined;
  const chartClasses = chartBuilder.getChartClasses
    ? chartBuilder.getChartClasses(config)
    : [];
  return { label, chartClasses, legend, chart, tooltip, eventListener$ };
}

export function buildTableDisplay(
  chartBuilder: ITableBuilder,
  config: IChartConfig,
  results: IDCTransformResult[]
): ITableDisplay | undefined {
  const eventListener$ = new Subject<IDCChartEvent>();
  const chart = chartBuilder.getChart(results, config, eventListener$);
  if (!chart) {
    return;
  }
  const label = chartBuilder.getLabel(config);
  const columns = chartBuilder.getColumns(results, config);
  return { label, columns, chart, eventListener$ };
}
