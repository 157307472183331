import {
  type IGetRecordResponse,
  type IPatient,
  PatientStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseExistingPatientDestinationEntity,
  IExistingPatientMergeConflictSummary,
} from '../../../destination/entities/existing-patient';
import {
  IOasisPatient,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { WithRef } from '@principle-theorem/shared';
import { getName, getPhone } from './patients';

export class ExistingPatientDestinationEntity extends BaseExistingPatientDestinationEntity<IOasisPatient> {
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: this.patientSourceEntity,
  };

  getDateOfBirth(
    sourcePatient: IGetRecordResponse<IOasisPatient>
  ): string | undefined {
    return sourcePatient.data.data.dateOfBirth;
  }

  filterMatchingPatients(
    sourcePatient: IOasisPatient,
    matchingPatients: WithRef<IPatient>[]
  ): WithRef<IPatient>[] {
    return matchingPatients.filter(
      (patient) =>
        [sourcePatient.firstName, sourcePatient.lastName].every((name) =>
          patient.name.toLowerCase().includes(name.toLowerCase())
        ) && patient.status === PatientStatus.Active
    );
  }

  buildConflictSummary(
    sourcePatient: IOasisPatient
  ): Omit<IExistingPatientMergeConflictSummary, 'status' | 'patientRefs'> {
    const sourcePatientId = this.patientSourceEntity
      .getSourceRecordId(sourcePatient)
      .toString();

    return {
      sourcePatientId,
      patientName: getName(sourcePatient),
      dateOfBirth: sourcePatient.dateOfBirth ?? '',
      gender: sourcePatient.gender,
      phone: getPhone(sourcePatient),
      email: sourcePatient.email ?? '',
    };
  }
}
