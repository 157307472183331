import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IAutomationConfiguration } from '@principle-theorem/principle-core/interfaces';

@Component({
    selector: 'pr-automation-configuration-status',
    templateUrl: './automation-configuration-status.component.html',
    styleUrls: ['./automation-configuration-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AutomationConfigurationStatusComponent {
  label: string;
  colour: string;

  @Input({ transform: coerceBooleanProperty }) dense = false;

  @Input()
  set automation(automation: IAutomationConfiguration) {
    this.label = automation.isActive ? 'Enabled' : 'Disabled';
    this.colour = automation.isActive ? 'primary' : 'warn';
  }
}
