import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import {
  ChartFacade,
  type ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { surfaceFromRef } from '@principle-theorem/principle-core';
import { type IDentalChartViewSurface } from '@principle-theorem/principle-core/interfaces';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { CHART_ENTITY_ID } from '../chart-entity-id';
import {
  ToothSurfaceIndicatorsFactory,
  ToothSurfaceItemsIndicatorFactory,
} from '../chart-tooth/item-indicators';
import {
  type ChartElement,
  type IChartElement,
} from '../renderers/chart-element';
import { type IChartTooth } from '../renderers/chart-tooth';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[prChartToothSurface]',
  templateUrl: './chart-tooth-surface.component.html',
  styleUrls: ['./chart-tooth-surface.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartToothSurfaceComponent {
  private _chartId: ChartId = inject(CHART_ENTITY_ID);
  view$: ReplaySubject<IDentalChartViewSurface> = new ReplaySubject(1);
  tooth$: ReplaySubject<IChartTooth> = new ReplaySubject(1);
  path$: ReplaySubject<string> = new ReplaySubject(1);
  selector$: Observable<IChartElement>;
  indicator$: Observable<IChartElement>;
  disabled$: Observable<boolean>;
  badge$: Observable<number>;

  constructor(private _chartStore: ChartFacade) {
    this.disabled$ = this.view$.pipe(
      switchMap((view) =>
        this._chartStore.isDisabledSurface$(
          this._chartId,
          surfaceFromRef(view.id)
        )
      )
    );
    this.selector$ = combineLatest([this.view$, this.tooth$]).pipe(
      map(([view, tooth]: [IDentalChartViewSurface, IChartTooth]) =>
        this._buildSelector(view, tooth)
      )
    );
    this.indicator$ = combineLatest([this.view$, this.tooth$]).pipe(
      map(([view, tooth]: [IDentalChartViewSurface, IChartTooth]) =>
        this._buildIndicator(view, tooth)
      )
    );
    this.badge$ = this.view$.pipe(
      map((view: IDentalChartViewSurface) => view.badge),
      startWith(0)
    );
  }

  @Input()
  set view(view: IDentalChartViewSurface) {
    this.view$.next(view);
  }

  @Input()
  set tooth(tooth: IChartTooth) {
    this.tooth$.next(tooth);
  }

  @Input()
  set path(path: string) {
    this.path$.next(path);
  }

  private _buildSelector(
    view: IDentalChartViewSurface,
    tooth: IChartTooth
  ): ChartElement {
    const surface = view.id.tooth?.surface;
    if (!surface) {
      throw new Error(`Invalid view given`);
    }
    return new ToothSurfaceItemsIndicatorFactory(tooth).create(surface);
  }

  private _buildIndicator(
    view: IDentalChartViewSurface,
    tooth: IChartTooth
  ): IChartElement {
    const surface = view.id.tooth?.surface;
    if (!surface) {
      throw new Error(`Invalid view given`);
    }
    return new ToothSurfaceIndicatorsFactory(tooth)
      .create(surface)
      .toInterface();
  }
}
