import {
  HicapsConnectMethod,
  PrincipleHicapsConnectRequest,
} from '@principle-theorem/hicaps-connect';
import { HicapsConnectService } from '@principle-theorem/ng-payments';
import {
  DialogPresets,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { Practice } from '@principle-theorem/principle-core';
import {
  IHicapsConnectTerminal,
  IPractice,
  IPracticeHicapsConnectSettings,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, patchDoc, snapshot } from '@principle-theorem/shared';
import { HicapsConnectHelpers } from 'libs/ng-payments/src/lib/transactions/transaction-providers/hicaps-connect/hicaps-connect-helpers';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  HicapsCustomRequestComponent,
  IHicapsConnectJsonRequest,
} from './hicaps-custom-request/hicaps-custom-request.component';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'pr-practice-hicaps-connect-settings',
  templateUrl: './practice-hicaps-connect-settings.component.html',
  styleUrls: ['./practice-hicaps-connect-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PracticeHicapsConnectSettingsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);
  terminals$: Observable<WithRef<IHicapsConnectTerminal>[]>;
  settingsForm = new TypedFormGroup<IPracticeHicapsConnectSettings>({
    isEnabled: new TypedFormControl<boolean>(false),
    testModeEnabled: new TypedFormControl<boolean>(false),
    merchantId: new TypedFormControl<string>(),
    addCDBSAsPending: new TypedFormControl<boolean>(false),
  });

  @Input()
  set practice(practice: WithRef<IPractice>) {
    if (practice) {
      this.practice$.next(practice);
    }
  }

  constructor(
    private _hicapsConnect: HicapsConnectService,
    private _dialog: MatDialog
  ) {
    this.practice$
      .pipe(
        take(1),
        map((practice) => practice.hicapsConnectSettings),
        takeUntil(this._onDestroy$)
      )
      .subscribe((settings) => {
        if (settings) {
          this.settingsForm.patchValue(settings ?? {});
        }
      });

    this.terminals$ = this.practice$.pipe(
      switchMap((practice) => Practice.hicapsConnectTerminals$(practice))
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async submit(): Promise<void> {
    const practice = await snapshot(this.practice$);
    const hicapsConnectSettings = this.settingsForm.value;
    await patchDoc(practice.ref, { hicapsConnectSettings });
  }

  async customRequest(): Promise<void> {
    const practice = await snapshot(this.practice$);
    const terminal = await this._hicapsConnect.selectTerminal(practice);
    if (!terminal) {
      return;
    }
    const baseRequest = HicapsConnectHelpers.buildBaseRequest(
      this._hicapsConnect.getConfig(),
      terminal
    );
    const request = await this._dialog
      .open<
        HicapsCustomRequestComponent,
        Partial<IHicapsConnectJsonRequest> | undefined,
        IHicapsConnectJsonRequest | undefined
      >(
        HicapsCustomRequestComponent,
        DialogPresets.fullscreen({ data: { request: baseRequest } })
      )
      .afterClosed()
      .toPromise();

    // eslint-disable-next-line no-console
    console.log('Submitting customRequest', { request });
    if (!request) {
      return;
    }

    const api = this._hicapsConnect.getDeviceAPI(terminal.bridgeDevice);

    // Pretend to be a quote request, but this data can be whatever you want to test
    const response = await snapshot(
      api.call$<HicapsConnectMethod.SendQuoteRequest>(
        request.methodName as HicapsConnectMethod.SendQuoteRequest,
        request.request as unknown as PrincipleHicapsConnectRequest<HicapsConnectMethod.SendQuoteRequest>['data'],
        request.extendedData as unknown as PrincipleHicapsConnectRequest<HicapsConnectMethod.SendQuoteRequest>['extendedData']
      )
    );

    // eslint-disable-next-line no-console
    console.log('Received customRequest', { request, response });
  }
}
