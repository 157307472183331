<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="16px" class="wrapper">
  <h1 class="mat-headline-4">Teams</h1>

  <div fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutGap="16px">
      <pt-search-field fxFlex>
        <input
          matInput
          placeholder="Search"
          [formControl]="searchCtrl"
          #search
        />
      </pt-search-field>
      <button mat-raised-button color="primary" (click)="createTeam()">
        Create Team
      </button>
    </div>

    <pr-empty-state *ngIf="emptyState$ | async" image="list" title="teams" />

    <ng-container *ngIf="teamsFilter.results$ | async as teams">
      <mat-nav-list *ngIf="teams.length; else noSearchResults">
        <a
          *ngFor="let team of teams; trackBy: trackByTeam"
          mat-list-item
          [routerLink]="['./', team.ref.id]"
        >
          <span matListItemTitle>{{ team.name }}</span>
          <div matListItemMeta>
            <mat-icon>chevron_right</mat-icon>
          </div>
        </a>
      </mat-nav-list>
    </ng-container>
  </div>
</div>

<ng-template #noSearchResults>
  <pr-empty-state
    *ngIf="(emptyState$ | async) === false"
    image="search"
    title="search results"
    [searchInputValue]="search.value"
  />
</ng-template>
