import { Injectable, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import { Brand } from '@principle-theorem/principle-core';
import {
  IBrand,
  IPrescriptionItem,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  IReffable,
  WithRef,
  addDoc,
  deleteDoc,
  filterUndefined,
  isSameRef,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

interface IPrescriptionItemState {
  items: WithRef<IPrescriptionItem>[];
  loading: boolean;
}

@Injectable()
export class PrescriptionItemService {
  private _snackBar = inject(MatSnackBar);
  private _currentBrand = inject(CurrentBrandScope);
  private _brand = toSignal(this._currentBrand.doc$.pipe(filterUndefined()));

  // state
  private _state = signal<IPrescriptionItemState>({
    items: [],
    loading: false,
  });

  // selectors
  loading = computed(() => this._state().loading);
  items = computed(() =>
    sortBy(this._state().items, ({ medicationName }) =>
      medicationName.toLowerCase()
    )
  );

  // actions
  load$ = new Subject<IPrescriptionItemState>();
  loading$ = new Subject<boolean>();
  add$ = new Subject<IPrescriptionItem>();
  edit$ = new Subject<WithRef<IPrescriptionItem>>();
  delete$ = new Subject<WithRef<IPrescriptionItem>>();

  constructor() {
    this.add$
      .pipe(
        takeUntilDestroyed(),
        switchMap((item) => this._addItem(item))
      )
      .subscribe((item) => this._addItemToState(item));

    this.edit$
      .pipe(
        takeUntilDestroyed(),
        switchMap((item) => this._editItem(item))
      )
      .subscribe((item) => this._editItemInState(item));

    this.delete$
      .pipe(
        takeUntilDestroyed(),
        switchMap((item) => this._deleteItem(item))
      )
      .subscribe((item) => this._deleteItemFromState(item));

    this.load$
      .pipe(takeUntilDestroyed())
      .subscribe((state) => this._state.set(state));

    this.loading$
      .pipe(takeUntilDestroyed())
      .subscribe((isloading) =>
        this._state.update((state) => ({ ...state, loading: isloading }))
      );
  }

  configPrescriptionItems$(): Observable<WithRef<IPrescriptionItem>[]> {
    return this._currentBrand.doc$.pipe(
      filterUndefined(),
      switchMap((brand) => Brand.prescriptionItems$(brand))
    );
  }

  private _addItemToState(item: WithRef<IPrescriptionItem>): void {
    this._state.update((state) => ({
      ...state,
      items: [...state.items, item],
    }));
  }

  private _editItemInState(editItem: WithRef<IPrescriptionItem>): void {
    this._state.update((state) => ({
      ...state,
      items: state.items.map((item) =>
        isSameRef(item, editItem) ? editItem : item
      ),
    }));
  }

  private _deleteItemFromState(deleteItem: WithRef<IPrescriptionItem>): void {
    this._state.update((state) => ({
      ...state,
      items: state.items.filter((item) => !isSameRef(item, deleteItem)),
    }));
  }

  private async _addItem(
    item: IPrescriptionItem
  ): Promise<WithRef<IPrescriptionItem>> {
    const brand = this._brand() as IReffable<IBrand>;
    const ref = await addDoc(Brand.prescriptionItemCol(brand), item);
    this._snackBar.open('Prescription item added');
    return { ...item, ref };
  }

  private async _editItem(
    item: WithRef<IPrescriptionItem>
  ): Promise<WithRef<IPrescriptionItem>> {
    await Firestore.patchDoc(item.ref, item);
    this._snackBar.open('Prescription item updated');
    return item;
  }

  private async _deleteItem(
    item: WithRef<IPrescriptionItem>
  ): Promise<WithRef<IPrescriptionItem>> {
    await deleteDoc(item.ref);
    this._snackBar.open('Prescription item deleted');
    return item;
  }
}
