/**
 * Browser Firestore adaptor.
 */

import { getApps, initializeApp } from 'firebase/app';
import {
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  deleteDoc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';
import { FirestoreScheduler } from '../firestore-scheduler';
export {
  CollectionReference,
  DocumentChange,
  DocumentChangeType,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FieldPath,
  FieldValue,
  OrderByDirection,
  Query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  SetOptions,
  Timestamp,
  Transaction,
  UpdateData,
  WhereFilterOp,
  WriteBatch,
  collection,
  collectionGroup,
  doc,
  endBefore,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  setDoc,
  startAfter,
  startAt,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';

export function store(appName?: string): Firestore {
  const determinedAppName =
    appName ?? FirestoreScheduler.appName ?? '[DEFAULT]';
  const existingApp = getApps().find(
    (app) => app && app.name === determinedAppName
  );
  const firebaseApp =
    existingApp ?? initializeApp(FirestoreScheduler.options, determinedAppName);

  return getFirestore(firebaseApp);
}

export async function docExists<T extends object>(
  ref: DocumentReference<T> | DocumentSnapshot<T>
): Promise<boolean> {
  if (ref instanceof DocumentReference) {
    return (await getDoc(ref)).exists();
  }
  return ref.exists();
}

export async function hardDeleteDoc<T extends object>(
  ref: DocumentReference<T>
): Promise<void> {
  return deleteDoc(ref);
}

// export type Query<T> = FirestoreQuery<T>;
// export type QuerySnapshot<T> = FirestoreQuerySnapshot<T>;
// export type QueryDocumentSnapshot<T> = FirestoreQueryDocumentSnapshot<T>;
// export type DocumentSnapshot<T> = FirestoreDocumentSnapshot<T>;
// export type SetOptions = FirestoreSetOptions;
// export type DocumentReference<T = unknown> = FirestoreDocumentReference<T>;
// export type DocumentChangeType = FirestoreDocumentChangeType;
// export type DocumentChange = FirestoreDocumentChange;
// export type DocumentData = FirestoreDocumentData;
// export type Timestamp = FirestoreTimestamp;
// export type CollectionReference<T> = FirestoreCollectionReference<T>;
// export type OrderByDirection = FirestoreOrderByDirection;
// export type WhereFilterOp = FirestoreWhereFilterOp;
// export type Transaction = FirestoreTransaction;
// export type WriteBatch = FirestoreWriteBatch;
// export type FieldValue = FirestoreFieldValue;
// export type FieldPath = FirestoreFieldPath;

// eslint-disable-next-line no-redeclare, @typescript-eslint/naming-convention
// export const DocumentReference: typeof FirestoreDocumentReference =
//   FirestoreDocumentReference;

// eslint-disable-next-line no-redeclare, @typescript-eslint/naming-convention
// export const DocumentSnapshot: typeof FirestoreDocumentSnapshot =
//   FirestoreDocumentSnapshot;

// eslint-disable-next-line no-redeclare, @typescript-eslint/naming-convention
// export const Timestamp: typeof FirestoreTimestamp = FirestoreTimestamp;

// eslint-disable-next-line no-redeclare, @typescript-eslint/naming-convention
// export const FieldValue: typeof FirestoreFieldValue = FirestoreFieldValue;

// eslint-disable-next-line no-redeclare, @typescript-eslint/naming-convention
// export const FieldPath: typeof FirestoreFieldPath = FirestoreFieldPath;

// eslint-disable-next-line no-redeclare, @typescript-eslint/naming-convention
// export const Transaction: typeof FirestoreTransaction = FirestoreTransaction;

// interface ITransaction {
//   get<T>(documentRef: DocumentReference<T>): Promise<DocumentSnapshot<T>>;
//   set<T>(
//     documentRef: DocumentReference<T>,
//     data: Partial<T>,
//     options: SetOptions
//   ): ITransaction;
//   set<T>(documentRef: DocumentReference<T>, data: T): ITransaction;
//   update<T>(documentRef: DocumentReference<T>, data: Partial<T>): ITransaction;
//   delete<T>(documentRef: DocumentReference<T>): Transaction;
// }
