import { isObject, type ISoftDelete } from '@principle-theorem/shared';
import { isString } from 'lodash';

export interface IManualTransactionType extends ISoftDelete {
  name: string;
}

export function isManualTransactionType(
  data: unknown
): data is IManualTransactionType {
  return isObject(data) && isString(data.name);
}
