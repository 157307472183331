import { IStatisticMetrics, IStatistic } from './base-statistics';

export interface IPractitionerStats extends IStatisticMetrics {
  // Aggregate Facts
  costs: IStatistic;
  timeUsed: IStatistic;
  productivity: IStatistic;
  timeUnused: IStatistic;
  timeAvailable: IStatistic;
  totalRevenue: IStatistic;
  lostIncome: IStatistic;
  netIncome: IStatistic;

  // Lab Job Facts
  labFees: IStatistic;

  // Appointment Facts
  dentalDraw: IStatistic;
  dentalDrawPercent: IStatistic;
  treatmentsApplied: IStatistic;
  totalTreatmentsApplied: IStatistic;
  totalValueOfTreatmentsApplied: IStatistic;
  patientsSeen: IStatistic;
  hourlyRate: IStatistic;
  cancellations: IStatistic;
  newPatients: IStatistic;
  existingPatients: IStatistic;
  averageAppointmentDuration: IStatistic;
  averagePatientWaitTime: IStatistic;
  patientsLeavingWithoutAppointment: IStatistic;
  patientRebookingRate: IStatistic;
  patientsLeavingWithAppointment: IStatistic;
  averagePatientSpend: IStatistic;
  appointmentsDuration: IStatistic;
  ftas: IStatistic;
  utas: IStatistic;
  treatmentsDiagnostic: IStatistic;
  treatmentsPreventive: IStatistic;
  treatmentsPeriodontics: IStatistic;
  treatmentsOralSurgery: IStatistic;
  treatmentsEndodontics: IStatistic;
  treatmentsRestorative: IStatistic;
  treatmentsProsthodontics: IStatistic;
  treatmentsOrthodontics: IStatistic;
  treatmentsOther: IStatistic;
  treatmentsDiagnosticIncome: IStatistic;
  treatmentsPreventiveIncome: IStatistic;
  treatmentsPeriodonticsIncome: IStatistic;
  treatmentsOralSurgeryIncome: IStatistic;
  treatmentsEndodonticsIncome: IStatistic;
  treatmentsRestorativeIncome: IStatistic;
  treatmentsProsthodonticsIncome: IStatistic;
  treatmentsOrthodonticsIncome: IStatistic;
  treatmentsOtherIncome: IStatistic;
  totalReschedules: IStatistic;
  totalNewAppointments: IStatistic;

  // Gap Facts
  gaps: IStatistic;
  gapsDuration: IStatistic;
  averageGapDuration: IStatistic;

  // Referral Fact
  patientBounceRate: IStatistic;

  // Treatment Plan Facts
  presentedTreatmentPlans: IStatistic;
  acceptedTreatmentPlans: IStatistic;
  rejectedTreatmentPlans: IStatistic;
  treatmentPlanAcceptanceRate: IStatistic;
  totalValueOfTreatmentsUnaccepted: IStatistic;
  totalValueOfTreatmentsAccepted: IStatistic;

  // Invoice Facts
  overdueInvoicesCount: IStatistic;
  overdueInvoicesTotal: IStatistic;
  writtenOffInvoicesTotal: IStatistic;
  paidOnCheckoutRate: IStatistic;
  paidAfterCheckoutRate: IStatistic;
  neverPaidRate: IStatistic;

  // Payment Plan Facts
  paymentPlansCount: IStatistic;
  paymentPlansTotal: IStatistic;
}
