import {
  IFileData,
  ISQLSeedingTranslator,
} from '@principle-theorem/principle-core/interfaces';
import { IProvider } from '@principle-theorem/shared';

export class PraktikaPatientProceduresTranslator
  implements ISQLSeedingTranslator, IProvider<IFileData, string[]>
{
  matchingFilenames = ['patient_procedures.csv'];
  commands = [
    `sed -i -e 's/iStatusId/iStatusId2/2'`,
    `sed -i -e 's/dtCompleted/dtCompleted2/2'`,
    `sed -i -e 's/dtAppointed/dtAppointed2/2'`,
  ];

  canProvide(data: IFileData): boolean {
    return this.matchingFilenames.includes(data.fileName);
  }

  execute(data: IFileData): string[] {
    return this.commands.map((cmd) => `${cmd} ${data.filePath}`);
  }
}
