<ng-container *ngrxLet="filteredTreatmentPlans$ as treatmentPlans">
  <div class="flex flex-row items-center justify-between gap-2">
    <div class="flex-1">
      <ng-content />
    </div>
    @if (treatmentPlans.length) {
      <div class="flex flex-row items-center">
        <pt-multi-filter-group
          [compact]="true"
          tooltip="Filter by status"
          [statusFilters]="stepDisplay.treatmentStepStatusOptions"
          [activeStatuses]="stepDisplay.activeStatusFilters()"
          [displayBadge]="stepDisplay.hasActiveStatusFilters()"
          (statusFilterChange)="stepDisplay.updateUsersFilteredStatuses($event)"
        />

        <pr-treatment-step-sort-by-menu />
      </div>
    }
  </div>
  <div fxLayout="column" fxLayoutGap="16px">
    <div
      class="treatment-plan-container"
      *ngFor="
        let treatmentPlan of treatmentPlans;
        trackBy: trackByTreatmentPlan
      "
    >
      <pr-treatment-plan-header
        [treatmentPlan]="treatmentPlan"
        [canAddStep]="treatmentPlan | map: canAddStep"
        (addStep)="addBookableStep(treatmentPlan)"
      />
      <pr-treatment-plan-steps
        [treatmentPlan]="treatmentPlan"
        [currentPractitioner]="currentPractitioner$ | async"
        [patient]="patient$ | async"
        [canAddStep]="treatmentPlan | map: canAddStep"
        [filterStepStatuses]="filterStepStatuses$ | async"
        (addStep)="addBookableStep(treatmentPlan)"
      />
    </div>
  </div>
</ng-container>
