import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmDialogComponent,
  DialogPresets,
  IConfirmationDialogInput,
  ObservableDataSource,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import { Practice } from '@principle-theorem/principle-core';
import {
  IPractice,
  ISmartpayTerminal,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  HISTORY_DATE_FORMAT,
  WithRef,
  deleteDoc,
  multiSort,
  snapshot,
  sortTimestamp,
} from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  EditSmartpayTerminalDialogComponent,
  IEditSmartpayTerminalRequest,
} from '../edit-smartpay-terminal-dialog/edit-smartpay-terminal-dialog.component';
import {
  IPairSmartpayTerminalDialogRequest,
  PairSmartpayTerminalDialogComponent,
} from '../pair-smartpay-terminal-dialog/pair-smartpay-terminal-dialog.component';

@Component({
  selector: 'pr-practice-smartpay-terminals',
  templateUrl: './practice-smartpay-terminals.component.html',
  styleUrls: ['./practice-smartpay-terminals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PracticeSmartpayTerminalsComponent {
  readonly dateFormat = HISTORY_DATE_FORMAT;
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);
  terminals$: Observable<WithRef<ISmartpayTerminal>[]>;
  dataSource: ObservableDataSource<WithRef<ISmartpayTerminal>>;
  displayedColumns: string[] = ['name', 'lastActive', 'actions'];

  @Input()
  set practice(practice: WithRef<IPractice>) {
    if (practice) {
      this.practice$.next(practice);
    }
  }

  constructor(private _dialog: MatDialog) {
    this.terminals$ = this.practice$.pipe(
      switchMap((practice) => Practice.smartpayTerminals$(practice)),
      multiSort((a, b) => sortTimestamp(a.lastPaired, b.lastPaired))
    );
    this.dataSource = new ObservableDataSource(this.terminals$);
  }

  async pairNewTerminal(): Promise<void> {
    const practice = await snapshot(this.practice$);
    const data: IPairSmartpayTerminalDialogRequest = {
      terminalCol: Practice.smartpayTerminalCol(practice),
      practiceRef: practice.ref,
    };

    await this._dialog
      .open<
        PairSmartpayTerminalDialogComponent,
        IPairSmartpayTerminalDialogRequest,
        undefined
      >(PairSmartpayTerminalDialogComponent, DialogPresets.small({ data }))
      .afterClosed()
      .toPromise();
  }

  async editTerminalPair(existing: WithRef<ISmartpayTerminal>): Promise<void> {
    const terminal = await this._openTerminalEdit({
      title: 'Edit Smartpay Terminal',
      terminal: existing,
    });
    if (!terminal) {
      return;
    }
    await Firestore.patchDoc(existing.ref, terminal);
  }

  async deleteTerminalPair(
    terminal: WithRef<ISmartpayTerminal>
  ): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Terminal Pair',
      prompt: `Are you sure you want to delete ${terminal.name}?`,
      submitLabel: 'Delete',
      submitColor: 'warn',
    });

    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!confirmed) {
      return;
    }
    await deleteDoc(terminal.ref);
  }

  private async _openTerminalEdit(
    data: IEditSmartpayTerminalRequest
  ): Promise<Pick<ISmartpayTerminal, 'name'> | undefined> {
    return this._dialog
      .open<
        EditSmartpayTerminalDialogComponent,
        IEditSmartpayTerminalRequest,
        Pick<ISmartpayTerminal, 'name'>
      >(EditSmartpayTerminalDialogComponent, DialogPresets.medium({ data }))
      .afterClosed()
      .toPromise();
  }
}
