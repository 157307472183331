<mat-toolbar color="accent">Select Provider</mat-toolbar>

<mat-dialog-content>
  <mat-selection-list
    #staffList
    hideSingleSelectionIndicator
    [multiple]="false"
    (selectionChange)="
      dialogRef.close(staffList.selectedOptions.selected[0].value)
    "
  >
    <mat-list-option
      *ngFor="let practitoner of data.practitioners; trackBy: trackByStaffer"
      [value]="practitoner"
    >
      <pr-staffer-display [staffer]="practitoner" />
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
  </pt-buttons-container>
</mat-dialog-actions>
