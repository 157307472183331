import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pr-general-tracking-code-help',
  templateUrl: './general-tracking-code-help.component.html',
  styleUrls: ['./general-tracking-code-help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class GeneralTrackingCodeHelpComponent {
  @Input() bookingPageUrl: string =
    'https://app.principle.dental/portal/booking/{practice_identifier}';
}
