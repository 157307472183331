<div class="flex flex-col gap-2">
  <div class="text-base font-bold">Pinned Notes</div>

  <ng-container *ngIf="pinnedNotes.hasPinnedNotes$ | async; else noPinnedNotes">
    <ng-container *ngIf="pinnedNotes.clinical$ | async as notes">
      <ng-container
        *ngTemplateOutlet="
          pinnedNotesTemplate;
          context: { name: 'Clinical', notes }
        "
      />
    </ng-container>

    <ng-container *ngIf="pinnedNotes.scheduling$ | async as notes">
      <ng-container
        *ngTemplateOutlet="
          pinnedNotesTemplate;
          context: { name: 'Scheduling', notes }
        "
      />
    </ng-container>

    <ng-container *ngIf="pinnedNotes.social$ | async as notes">
      <ng-container
        *ngTemplateOutlet="
          pinnedNotesTemplate;
          context: { name: 'Social', notes }
        "
      />
    </ng-container>
  </ng-container>
</div>

<ng-template #pinnedNotesTemplate let-name="name" let-notes="notes">
  <div class="flex flex-col gap-1" *ngIf="notes.length">
    <div>{{ name }}</div>
    <div
      class="text-xs opacity-50"
      *ngFor="let note of notes; trackBy: trackByPinnedNote"
    >
      <pr-content-text [content]="note.content" />
    </div>
  </div>
</ng-template>

<ng-template #noPinnedNotes>
  <div class="text-xs opacity-50">This appointment has no pinned notes</div>
</ng-template>
