<div class="flex flex-col gap-2 p-4">
  <h3 class="!m-0 !font-bold">Meta Pixel</h3>

  <p class="!m-0">
    A Meta Pixel Id is a series of numbers, usually 16 characters long. To
    create a Meta Pixel follow the
    <a
      href="https://www.facebook.com/business/help/952192354843755?id=1205376682832142"
      target="_blank"
      >Official Documentation</a
    >. To find your Meta Pixel Id follow the steps below:
  </p>

  <ol class="list-inside list-decimal">
    <li>
      In Facebook navigate to the
      <a href="https://www.facebook.com/events_manager2/list" target="_blank"
        >Events Manager</a
      >
      from the "All Tools" menu.
    </li>
    <li>Navigate to Data Sources from the left sidebar menu</li>
    <li>
      Near the top left of the window, you will find your IDs formatted as
      <code
        class="rounded-lg bg-slate-100 px-2 py-1 font-mono font-bold text-slate-500"
        >XXXXXXXXXXXXXXXX</code
      >.
    </li>
  </ol>

  <div>
    <img
      src="/assets/help-images/tracking-codes/facebook-data-sources-pixel-id.png"
      alt="Facebook Pixel - Tracking Code"
    />
  </div>
</div>
