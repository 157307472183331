import {
  type CrossfilterGroup,
  type CrossfilterGroupValue,
  type ICanBeChartedProperty,
  type IChartConfig,
  type IDataBuilder,
} from '@principle-theorem/reporting';
import {
  type ComparableObject,
  type NaturallyOrderedValue,
} from 'crossfilter2';
import { sum } from 'lodash';
import { CrossfilterDataTransformer } from './crossfilter-data-transformer';
import { type IDataTransformer } from './interfaces';

export class SingleValueTransformer
  implements IDataTransformer<ComparableObject>
{
  transformer: CrossfilterDataTransformer = new CrossfilterDataTransformer();

  async transform(
    config: IChartConfig,
    dataBuilder: IDataBuilder,
    measure: ICanBeChartedProperty
  ): Promise<ComparableObject> {
    const singleValueConfig: IChartConfig = { ...config };
    singleValueConfig.builderData.accumulateOverTime = false;
    singleValueConfig.builderData.plottedOverTime = false;

    singleValueConfig.builderData.measures =
      singleValueConfig.builderData.measures.filter(
        (comparedMeasure) =>
          this._getMeasureCompareString(comparedMeasure.measure) ===
          this._getMeasureCompareString(measure)
      );

    const dataGroups: CrossfilterGroup[] = await this.transformer.transform(
      config,
      dataBuilder
    );
    if (!dataGroups.length) {
      return 0;
    }
    const values: NaturallyOrderedValue[] = dataGroups[0]
      .all()
      .map((groupData: CrossfilterGroupValue) => groupData.value);
    return sum(values);
  }

  private _getMeasureCompareString(measure: ICanBeChartedProperty): string {
    const formatter = measure.metadata.label ?? '';
    return `${measure.metadata.label}-${measure.metadata.summary}-${formatter}`;
  }
}
