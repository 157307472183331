import { randNumber } from '@ngneat/falso';
import { type IOrganisationStats } from '@principle-theorem/reporting';
import * as moment from 'moment-timezone';
import { BrandStatisticsMocker } from './brand-statistics.mock';
import { OrganisationStatistics } from './organisation-statistics';
import { StatisticsMocker } from './statistics-mocker';

export class OrganisationStatisticsMocker extends StatisticsMocker<
  IOrganisationStats,
  OrganisationStatistics
> {
  constructor() {
    super(ORGANISATIONS);
  }

  mockStatistic(name: string, date: moment.Moment): OrganisationStatistics {
    const metric: OrganisationStatistics = new OrganisationStatistics();
    metric.date = moment(date);
    metric.name = name;
    metric.stats.revenue.metric = randNumber({ max: 1000000 });
    metric.stats.costs.metric = randNumber({ max: 900000 });
    metric.stats.revenueKPI.metric = 1005000;
    metric.brands = new BrandStatisticsMocker().mock(date, date);
    return metric;
  }
}

export const ORGANISATIONS: string[] = ['Tooth Corporation'];
