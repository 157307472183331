import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
  OrganisationService,
  TagType,
} from '@principle-theorem/ng-principle-shared';
import {
  FileManagerService,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  type IMedia,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import { Media, Practice } from '@principle-theorem/principle-core';
import { type CollectionReference } from '@principle-theorem/shared';
import {
  filterUndefined,
  HISTORY_DATE_FORMAT,
  isChanged$,
  toDate,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

interface IMediaFormData extends Pick<WithRef<IMedia>, 'tags' | 'name'> {
  expiresAt: Date | undefined;
}

@Component({
    selector: 'pr-media-info',
    templateUrl: './media-info.component.html',
    styleUrls: ['./media-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MediaInfoComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _media: WithRef<IMedia>;
  tagsCol$: Observable<CollectionReference<ITag>>;
  mediaForm = new TypedFormGroup<IMediaFormData>({
    name: new TypedFormControl<string>(undefined, [Validators.required]),
    expiresAt: new TypedFormControl(),
    tags: new TypedFormControl([]),
  });
  readonly dateFormat = HISTORY_DATE_FORMAT;
  tagType = TagType.Media;
  @Output() mediaChange = new EventEmitter<WithRef<IMedia>>();

  constructor(
    organisation: OrganisationService,
    private _fileDownload: FileManagerService
  ) {
    this.mediaForm.valueChanges
      .pipe(isChanged$(), takeUntil(this._onDestroy$))
      .subscribe(() => this._handleUpdate());
    this.tagsCol$ = organisation.practice$.pipe(
      filterUndefined(),
      map((practice) => Practice.mediaTagCol(practice))
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @Input()
  set media(media: WithRef<IMedia>) {
    this._media = media;
    this.mediaForm.patchValue(this._toFormData(media));
  }

  get media(): WithRef<IMedia> {
    return this._media;
  }

  get hasExpired(): boolean {
    return Media.hasExpired(this.media);
  }

  async download(): Promise<void> {
    await this._fileDownload.downloadFile(this.media);
  }

  private _handleUpdate(): void {
    if (this.mediaForm.invalid || this.mediaForm.pristine) {
      return;
    }
    const updateData: Partial<IMedia> = this._toPartialMedia(
      this.mediaForm.getRawValue()
    );
    const updated: WithRef<IMedia> = { ...this._media, ...updateData };
    this.mediaChange.emit(updated);
  }

  private _toFormData(media: IMedia): IMediaFormData {
    return {
      name: media.name,
      expiresAt: media.expiresAt ? toDate(media.expiresAt) : undefined,
      tags: media.tags,
    };
  }

  private _toPartialMedia(formData: IMediaFormData): Partial<IMedia> {
    return {
      ...formData,
      expiresAt: formData.expiresAt
        ? toTimestamp(formData.expiresAt)
        : undefined,
    };
  }
}
