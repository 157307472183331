import {
  AppointmentStatus,
  IAbsoluteSchedulingRules,
  IAppointment,
  IAssociatedTreatment,
  IAutomation,
  IChartedTreatment,
  IPatient,
  IStaffer,
  ITreatmentConfiguration,
  ITreatmentPlan,
  ITreatmentStep,
  ITreatmentTemplate,
  ITreatmentTemplateWithStep,
  PatientCollection,
  SortReturnValue,
  SortStepOptions,
  TREATMENT_PLAN_STATUS_ORDER_MAP,
  TreatmentPlanCollection,
  TreatmentPlanStatus,
  TreatmentPlanType,
  TreatmentStepAutomation,
  isEventable,
} from '@principle-theorem/principle-core/interfaces';
import {
  CollectionReference,
  DocumentReference,
  Firestore,
  HISTORY_DATE_FORMAT,
  INamedDocument,
  IReffable,
  WithRef,
  addDoc,
  asyncForEach,
  doc$,
  find$,
  getDoc,
  getParentColRef,
  initFirestoreModel,
  isINamedDocument,
  isSameRef,
  multiFilter,
  multiFind,
  multiMap,
  multiSwitchMap,
  patchDoc,
  query$,
  reduceToSingleArray,
  resolveDocRefs,
  safeCombineLatest,
  saveDoc,
  snapshot,
  sortByCreatedAt,
  sortTimestampAsc,
  subCollection,
  toMoment,
  toNamedDocument,
  toTimestamp,
  undeletedQuery,
  where,
} from '@principle-theorem/shared';
import { compact, first, last, nth, pick } from 'lodash';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Appointment } from '../../appointment/appointment';
import { stafferToNamedDoc } from '../../common';
import { toAbsoluteSchedulingRules } from '../../scheduling-rules';
import { ChartedItemTotalCalculator } from './charted-item-total-calculator';
import { ChartedTreatment } from './charted-treatment';
import { TreatmentStep } from './treatment-step';

interface IStepAppointmentPair {
  step: WithRef<ITreatmentStep>;
  appointment?: WithRef<IAppointment>;
}

export class TreatmentPlan {
  static init(overrides?: Partial<ITreatmentPlan>): ITreatmentPlan {
    return {
      name: `Treatment Plan - ${moment().format(HISTORY_DATE_FORMAT)}`,
      status: TreatmentPlanStatus.Draft,
      statusHistory: [],
      steps: [],
      followUpHistory: [],
      type: TreatmentPlanType.PractitionerProposed,
      children: [],
      ...initFirestoreModel(),
      ...overrides,
    };
  }

  static col(
    patient: IReffable<IPatient>
  ): CollectionReference<ITreatmentPlan> {
    return subCollection<ITreatmentPlan>(
      patient.ref,
      PatientCollection.TreatmentPlans
    );
  }

  static all$(
    patient: IReffable<IPatient>
  ): Observable<WithRef<ITreatmentPlan>[]> {
    return query$(undeletedQuery(TreatmentPlan.col(patient)));
  }

  static patientRef(
    treatmentPlan: IReffable<ITreatmentPlan>
  ): DocumentReference<IPatient> {
    return Firestore.getParentDocRef<IPatient>(treatmentPlan.ref);
  }

  static patient$(
    config: WithRef<ITreatmentPlan>
  ): Observable<WithRef<IPatient>> {
    const patientRef = this.patientRef(config);
    if (!patientRef) {
      throw new Error(`Failed to resolve parent doc from treatment plan`);
    }
    return doc$(patientRef);
  }

  static canSchedule(plan: ITreatmentPlan): boolean {
    return [
      TreatmentPlanStatus.Draft,
      TreatmentPlanStatus.Offered,
      TreatmentPlanStatus.Accepted,
      TreatmentPlanStatus.InProgress,
    ].includes(plan.status);
  }

  static canDecline(plan: ITreatmentPlan): boolean {
    return [
      TreatmentPlanStatus.Draft,
      TreatmentPlanStatus.Offered,
      TreatmentPlanStatus.Accepted,
    ].includes(plan.status);
  }

  static canAccept(plan: ITreatmentPlan): boolean {
    return [
      TreatmentPlanStatus.Draft,
      TreatmentPlanStatus.Offered,
      TreatmentPlanStatus.Declined,
    ].includes(plan.status);
  }

  static canDelete$(plan: WithRef<ITreatmentPlan>): Observable<boolean> {
    return TreatmentPlan.treatmentSteps$(plan).pipe(
      map((steps) => !steps.length)
    );
  }

  static canComplete$(plan: WithRef<ITreatmentPlan>): Observable<boolean> {
    if (plan.status !== TreatmentPlanStatus.InProgress) {
      return of(false);
    }

    return combineLatest([
      TreatmentPlan.progress$(plan),
      TreatmentPlan.allAppointments$(plan),
    ]).pipe(
      map(
        ([progress, appointments]) =>
          progress === 1 ||
          compact(appointments).every(
            (appointment) =>
              Appointment.isComplete(appointment) ||
              Appointment.isCancelled(appointment)
          )
      )
    );
  }

  static isDisabled(plan: ITreatmentPlan): boolean {
    return [TreatmentPlanStatus.Completed].includes(plan.status);
  }

  static isComplete(plan: ITreatmentPlan): boolean {
    return [TreatmentPlanStatus.Completed].includes(plan.status);
  }

  static isActive(plan: ITreatmentPlan): boolean {
    return [
      TreatmentPlanStatus.Accepted,
      TreatmentPlanStatus.InProgress,
    ].includes(plan.status);
  }

  static treatmentStepCol(
    plan: IReffable<ITreatmentPlan>
  ): CollectionReference<ITreatmentStep> {
    return subCollection<ITreatmentStep>(
      plan.ref,
      TreatmentPlanCollection.TreatmentSteps
    );
  }

  static async addTreatmentStep(
    plan: WithRef<ITreatmentPlan>,
    step?: ITreatmentStep
  ): Promise<WithRef<ITreatmentStep>> {
    if (!step) {
      step = TreatmentStep.init();
    }
    const newStep = await addDoc<ITreatmentStep>(
      TreatmentPlan.treatmentStepCol(plan),
      step
    );
    await patchDoc(plan.ref, {
      steps: [...plan.steps, newStep],
    });
    return snapshot(TreatmentPlan.getTreatmentStep$(newStep));
  }

  static async addTreatmentSteps(
    plan: WithRef<ITreatmentPlan>,
    newSteps: ITreatmentStep[],
    index: number = -1
  ): Promise<void> {
    const newStepRefs = await asyncForEach(newSteps, (step) =>
      addDoc<ITreatmentStep>(TreatmentPlan.treatmentStepCol(plan), step)
    );

    const position = index === -1 ? plan.steps.length : index;
    const steps = plan.steps;
    steps.splice(position, 0, ...newStepRefs);

    await patchDoc(plan.ref, {
      steps,
    });
  }

  static treatmentSteps$(
    plan: IReffable<ITreatmentPlan>
  ): Observable<WithRef<ITreatmentStep>[]> {
    return query$(undeletedQuery(TreatmentPlan.treatmentStepCol(plan)));
  }

  static automations$(
    plan: WithRef<ITreatmentPlan>
  ): Observable<WithRef<IAutomation<TreatmentStepAutomation>>[]> {
    return TreatmentPlan.treatmentSteps$(plan).pipe(
      multiSwitchMap((step) => TreatmentStep.automations$(step)),
      map(reduceToSingleArray)
    );
  }

  static orderSteps$(
    plan: WithRef<ITreatmentPlan>,
    steps: WithRef<ITreatmentStep>[],
    sortBy?: SortStepOptions
  ): Observable<WithRef<ITreatmentStep>[]> {
    const stepPairs$: Observable<
      {
        step: WithRef<ITreatmentStep>;
        appointment?: WithRef<IAppointment>;
      }[]
    > = safeCombineLatest([
      ...steps.map((step) => {
        if (!step.appointment) {
          return of({ step });
        }
        return doc$(step.appointment).pipe(
          map((appointment) => ({ step, appointment }))
        );
      }),
    ]);

    return stepPairs$.pipe(
      map((stepPairs) => {
        const appointmentSteps = stepPairs
          .filter(
            (stepPair) =>
              (stepPair.appointment && stepPair.appointment?.event?.from) ||
              TreatmentStep.isComplete(stepPair.step)
          )
          .sort((stepA, stepB) => {
            const dateA =
              stepA.appointment?.event?.from || stepA.step.createdAt;
            const dateB =
              stepB.appointment?.event?.from || stepB.step.createdAt;
            return sortTimestampAsc(dateA, dateB);
          })
          .map((stepPair) => stepPair.step);

        const appointmentStepPaths = appointmentSteps.map(
          (step) => step.ref.path
        );

        const orderedSteps = compact(
          plan.steps
            .map((stepRef) =>
              stepPairs.find((stepPair) =>
                isSameRef(stepPair.step.ref, stepRef)
              )
            )
            .filter(
              (stepPair) =>
                stepPair &&
                !appointmentStepPaths.includes(stepPair.step.ref.path)
            )
        ).map((stepPair) => stepPair.step);

        const usedStepRefPaths = [
          ...appointmentStepPaths,
          ...orderedSteps.map((step) => step.ref.path),
        ];

        const unorderedSteps = stepPairs
          .filter(
            (stepPair) => !usedStepRefPaths.includes(stepPair.step.ref.path)
          )
          .map((stepPair) => stepPair.step)
          .sort(sortByCreatedAt)
          .reverse();

        const results = [
          ...appointmentSteps,
          ...orderedSteps,
          ...unorderedSteps,
        ];

        return sortBy === SortStepOptions.Ascending
          ? results
          : results.reverse();
      })
    );
  }

  static orderedSteps$(
    plan: WithRef<ITreatmentPlan>,
    sortBy?: SortStepOptions
  ): Observable<WithRef<ITreatmentStep>[]> {
    return TreatmentPlan.treatmentSteps$(plan).pipe(
      switchMap((steps) => TreatmentPlan.orderSteps$(plan, steps, sortBy))
    );
  }

  static getTreatmentStep$(
    step: INamedDocument<ITreatmentStep> | DocumentReference<ITreatmentStep>
  ): Observable<WithRef<ITreatmentStep>> {
    const ref = isINamedDocument(step) ? step.ref : step;
    return doc$<ITreatmentStep>(ref);
  }

  static async allAppointments(
    plan: WithRef<ITreatmentPlan>
  ): Promise<(WithRef<IAppointment> | undefined)[]> {
    return snapshot(this.allAppointments$(plan));
  }

  static allAppointments$(
    plan: WithRef<ITreatmentPlan>
  ): Observable<(WithRef<IAppointment> | undefined)[]> {
    return TreatmentPlan.treatmentSteps$(plan).pipe(
      multiSwitchMap((step) => TreatmentStep.appointment$(step))
    );
  }

  static orderedAppointments$(
    plan: WithRef<ITreatmentPlan>
  ): Observable<(WithRef<IAppointment> | undefined)[]> {
    return TreatmentPlan.orderedSteps$(plan).pipe(
      multiSwitchMap((step) => TreatmentStep.appointment$(step))
    );
  }

  static treatmentStepIndex(
    plan: ITreatmentPlan,
    treatmentStep: WithRef<ITreatmentStep>
  ): number {
    return plan.steps.findIndex((compareStep) => {
      return isSameRef(treatmentStep, compareStep);
    });
  }

  static getPreviousStep$(
    plan: ITreatmentPlan,
    treatmentStep: WithRef<ITreatmentStep>
  ): Observable<WithRef<ITreatmentStep> | undefined> {
    const groupIndex: number = TreatmentPlan.treatmentStepIndex(
      plan,
      treatmentStep
    );
    if (groupIndex <= 0) {
      return of(undefined);
    }
    const step = plan.steps[groupIndex - 1];
    return TreatmentPlan.getTreatmentStep$(step);
  }

  static async getPreviousStep(
    plan: WithRef<ITreatmentPlan>,
    treatmentStep: WithRef<ITreatmentStep>
  ): Promise<WithRef<ITreatmentStep> | undefined> {
    return snapshot(TreatmentPlan.getPreviousStep$(plan, treatmentStep));
  }

  static findStepByAppointment$(
    plan: IReffable<ITreatmentPlan>,
    appointmentRef: DocumentReference | undefined
  ): Observable<WithRef<ITreatmentStep> | undefined> {
    return find$<ITreatmentStep>(
      TreatmentPlan.treatmentStepCol(plan),
      where('appointment', '==', appointmentRef)
    );
  }

  static treatmentStepByRef(
    plan: Pick<ITreatmentPlan, 'steps'>,
    ref: DocumentReference<ITreatmentStep>
  ): DocumentReference<ITreatmentStep> | undefined {
    return plan.steps.find((treatmentStep) => isSameRef(treatmentStep, ref));
  }

  static allTreatments$(
    plan: WithRef<ITreatmentPlan>
  ): Observable<IChartedTreatment[]> {
    return TreatmentPlan.treatmentSteps$(plan).pipe(
      multiMap((step) => step.treatments),
      map(reduceToSingleArray)
    );
  }

  static treatmentById$(
    plan: WithRef<ITreatmentPlan>,
    uuid: string
  ): Observable<IChartedTreatment | undefined> {
    return TreatmentPlan.allTreatments$(plan).pipe(
      map((treatments) =>
        treatments.find(
          (treatment: IChartedTreatment): boolean => treatment.uuid === uuid
        )
      )
    );
  }

  static stepForTreatmentById$(
    plan: IReffable<ITreatmentPlan>,
    uuid: string
  ): Observable<WithRef<ITreatmentStep> | undefined> {
    return TreatmentPlan.treatmentSteps$(plan).pipe(
      multiFind((step) =>
        step.treatments.some(
          (treatment: IChartedTreatment): boolean => treatment.uuid === uuid
        )
      )
    );
  }

  static treatmentsForInvoice$(
    plan: WithRef<ITreatmentPlan>,
    invoiceRef: DocumentReference
  ): Observable<IChartedTreatment[]> {
    return TreatmentPlan.allTreatments$(plan).pipe(
      multiFilter((treatment) =>
        ChartedTreatment.includesInvoice(treatment, invoiceRef)
      )
    );
  }

  static updateStatus(plan: ITreatmentPlan, status: TreatmentPlanStatus): void {
    if (plan.status === status) {
      return;
    }
    plan.statusHistory.push({
      status: plan.status,
      updatedAt: toTimestamp(),
    });
    plan.status = status;
  }

  static async patchPlanStatus(
    plan: WithRef<ITreatmentPlan>,
    status: TreatmentPlanStatus
  ): Promise<void> {
    TreatmentPlan.updateStatus(plan, status);
    await patchDoc(plan.ref, pick(plan, ['status', 'statusHistory']));
  }

  static getTreatmentStepIndex(
    plan: WithRef<ITreatmentPlan>,
    step: WithRef<ITreatmentStep>
  ): number {
    return plan.steps.findIndex((stepDoc) => isSameRef(stepDoc, step));
  }

  static async nextBookableTreatmentStep(
    plan: WithRef<ITreatmentPlan>
  ): Promise<WithRef<ITreatmentStep> | undefined> {
    return this.findStepBy(
      plan,
      (appointment: WithRef<IAppointment> | undefined) => {
        if (!appointment) {
          return false;
        }
        return appointment.status === AppointmentStatus.Unscheduled;
      }
    );
  }

  static async findStepBy(
    plan: WithRef<ITreatmentPlan>,
    finder: (appointment: WithRef<IAppointment> | undefined) => boolean
  ): Promise<WithRef<ITreatmentStep> | undefined> {
    const steps = await snapshot(TreatmentPlan.orderedSteps$(plan));
    const appointments = await snapshot(
      TreatmentPlan.orderedAppointments$(plan)
    );

    const nextIndex: number = appointments
      ? appointments.findIndex((appointment) => finder(appointment))
      : 0;

    return nextIndex >= 0 ? nth(steps, nextIndex) : undefined;
  }

  static async findStepsBy(
    plan: WithRef<ITreatmentPlan>,
    finder: (step: ITreatmentStep, appointment?: IAppointment) => boolean
  ): Promise<WithRef<ITreatmentStep>[]> {
    const steps = await snapshot(TreatmentPlan.orderedSteps$(plan));
    const appointments = await snapshot(
      TreatmentPlan.orderedAppointments$(plan)
    );
    return steps.filter((step) => {
      if (!step.appointment) {
        return finder(step);
      }

      const appointment: WithRef<IAppointment> | undefined = appointments.find(
        (appointmentSearch) => {
          if (!step.appointment || !appointmentSearch) {
            return false;
          }
          return step.appointment.path === appointmentSearch.ref.path;
        }
      );
      return finder(step, appointment);
    });
  }

  static treatmentStepToAssociatedTreatment(
    plan: WithRef<ITreatmentPlan>,
    treatmentStep: WithRef<ITreatmentStep>
  ): IAssociatedTreatment {
    return {
      ...toNamedDocument(plan),
      treatmentStep: {
        ...toNamedDocument(treatmentStep),
        duration: treatmentStep.schedulingRules.duration,
        display: treatmentStep.display,
      },
    };
  }

  static getNextStep$(
    plan: WithRef<ITreatmentPlan>,
    treatmentStep: WithRef<ITreatmentStep>
  ): Observable<WithRef<ITreatmentStep> | undefined> {
    const groupIndex: number = TreatmentPlan.treatmentStepIndex(
      plan,
      treatmentStep
    );
    if (groupIndex === -1 || groupIndex === plan.steps.length - 1) {
      return of(undefined);
    }
    return TreatmentPlan.getTreatmentStep$(plan.steps[groupIndex + 1]);
  }

  static async getNextStep(
    plan: WithRef<ITreatmentPlan>,
    treatmentStep: WithRef<ITreatmentStep>
  ): Promise<WithRef<ITreatmentStep> | undefined> {
    return snapshot(TreatmentPlan.getNextStep$(plan, treatmentStep));
  }

  static getStepAbsoluteSchedulingRules$(
    plan: WithRef<ITreatmentPlan>,
    step: WithRef<ITreatmentStep>
  ): Observable<IAbsoluteSchedulingRules | undefined> {
    return TreatmentPlan.getPreviousStep$(plan, step).pipe(
      switchMap((previousStep) => {
        if (!previousStep) {
          return of(undefined);
        }
        return TreatmentStep.appointment$(previousStep);
      }),
      map((previousAppointment) => {
        if (!previousAppointment || !isEventable(previousAppointment)) {
          return;
        }
        const date: Moment = toMoment(previousAppointment.event.to);
        return toAbsoluteSchedulingRules(step.schedulingRules, date);
      })
    );
  }

  static async addChild(
    plan: WithRef<ITreatmentPlan>,
    childPlan: WithRef<ITreatmentPlan>
  ): Promise<void> {
    childPlan.parent = toNamedDocument(plan);
    plan.children.push(toNamedDocument(childPlan));
    await saveDoc(childPlan);
    await saveDoc(plan);
  }

  static async getLastChild(
    plan: ITreatmentPlan
  ): Promise<WithRef<ITreatmentPlan> | undefined> {
    const children: WithRef<ITreatmentPlan>[] = await snapshot(
      TreatmentPlan.getChildren(plan)
    );
    return last(children);
  }

  static getChildren(
    plan: ITreatmentPlan
  ): Observable<WithRef<ITreatmentPlan>[]> {
    return safeCombineLatest(
      plan.children.map((planDoc) => doc$<ITreatmentPlan>(planDoc.ref))
    );
  }

  static treatmentStepForAppointment$(
    appointment: WithRef<IAppointment>
  ): Observable<WithRef<ITreatmentStep> | undefined> {
    return doc$(appointment.treatmentPlan.ref).pipe(
      switchMap((treatmentPlan) =>
        TreatmentPlan.findStepByAppointment$(treatmentPlan, appointment.ref)
      )
    );
  }

  static async treatmentConfigurationsForAppointment(
    appointment: WithRef<IAppointment>
  ): Promise<WithRef<ITreatmentConfiguration>[]> {
    const step: ITreatmentStep | undefined = await snapshot(
      TreatmentPlan.treatmentStepForAppointment$(appointment)
    );
    if (!step) {
      return [];
    }
    const promises: Promise<WithRef<ITreatmentConfiguration>>[] =
      step.treatments.map((treatment) => {
        return getDoc(treatment.config.ref);
      });

    return Promise.all(promises);
  }

  static async saveNewPlan(
    colRef: CollectionReference<ITreatmentPlan>,
    plan: ITreatmentPlan,
    steps?: ITreatmentStep[]
  ): Promise<WithRef<ITreatmentPlan>> {
    const doc = await addDoc(colRef, plan);
    if (steps) {
      const storedPlan = await getDoc(doc);
      await TreatmentPlan.addTreatmentSteps(storedPlan, steps);
    }
    return getDoc(doc);
  }

  static progress$(plan: WithRef<ITreatmentPlan>): Observable<number> {
    const activeSteps$ = TreatmentPlan.orderedSteps$(plan).pipe(
      multiSwitchMap((step) => this.toStepAppointmentPair$(step)),
      map((pair) => this.removeStepsWithCancelledAppointments(pair)),
      multiMap((pair) => pair.step)
    );
    const completedSteps$ = activeSteps$.pipe(
      multiFilter((step) => TreatmentStep.isComplete(step))
    );
    return combineLatest([activeSteps$, completedSteps$]).pipe(
      map(
        ([activeSteps, completedSteps]) =>
          completedSteps.length / activeSteps.length
      )
    );
  }

  static removeStepsWithCancelledAppointments(
    pairs: IStepAppointmentPair[]
  ): IStepAppointmentPair[] {
    return pairs.filter((pair) => {
      if (pair.appointment && Appointment.isCancelled(pair.appointment)) {
        return false;
      }
      return true;
    });
  }

  static toStepAppointmentPair$(
    step: WithRef<ITreatmentStep>
  ): Observable<IStepAppointmentPair> {
    return TreatmentStep.appointment$(step).pipe(
      map((appointment) => ({ step, appointment }))
    );
  }

  static price$(plan: WithRef<ITreatmentPlan>): Observable<number> {
    return TreatmentPlan.orderedSteps$(plan).pipe(
      map((steps) => new ChartedItemTotalCalculator().multiStep({ steps }))
    );
  }

  static treatmentPlanCollection(
    plan: WithRef<ITreatmentPlan>
  ): CollectionReference<ITreatmentPlan> {
    return getParentColRef(plan.ref);
  }

  static async clone(
    plan: WithRef<ITreatmentPlan>
  ): Promise<WithRef<ITreatmentPlan>> {
    const clonedPlan = TreatmentPlan.init({
      name: `${plan.name} - Copy`,
      ...pick(plan, ['practitioner', 'type', 'parent', 'config', 'template']),
    });
    const steps = await resolveDocRefs(plan.steps);
    const clonedSteps = steps.map((step) => TreatmentStep.clone(step));
    return TreatmentPlan.saveNewPlan(
      TreatmentPlan.treatmentPlanCollection(plan),
      clonedPlan,
      clonedSteps
    );
  }

  static getTreatmentPlanFromTemplate(
    template: WithRef<ITreatmentTemplate>,
    practitioner: WithRef<IStaffer> | INamedDocument<IStaffer>
  ): ITreatmentTemplateWithStep {
    return {
      plan: {
        ...TreatmentPlan.init({
          name: template.name,
          practitioner: stafferToNamedDoc(practitioner),
          type: TreatmentPlanType.PractitionerProposed,
        }),
        isTemplate: true,
      },
      step: {
        name: template.name,
        template,
      },
    };
  }

  static async getMostRecentStep(
    plan: WithRef<ITreatmentPlan>
  ): Promise<WithRef<ITreatmentStep> | undefined> {
    return snapshot(
      TreatmentPlan.treatmentSteps$(plan).pipe(
        map((steps) => first(steps.sort(sortByCreatedAt)))
      )
    );
  }

  static async sortPlansByMostRecentStep(
    plans: WithRef<ITreatmentPlan>[]
  ): Promise<WithRef<ITreatmentPlan>[]> {
    const plansWithRecentStep = await asyncForEach(plans, async (plan) => {
      const step = await TreatmentPlan.getMostRecentStep(plan);
      return { plan, step };
    });
    plansWithRecentStep.sort((a, b) => {
      if (!a.step && !b.step) {
        return 0;
      }
      if (!a.step) {
        return 1;
      }
      if (!b.step) {
        return -1;
      }
      return sortByCreatedAt(a.step, b.step);
    });
    return plansWithRecentStep.map((item) => item.plan);
  }
}

export function sortByTreatmentPlanStatus(
  statusA: TreatmentPlanStatus,
  statusB: TreatmentPlanStatus,
  direction: 'asc' | 'desc' = 'asc'
): SortReturnValue {
  const valueA = TREATMENT_PLAN_STATUS_ORDER_MAP[statusA];
  const valueB = TREATMENT_PLAN_STATUS_ORDER_MAP[statusB];
  if (!valueA || !valueB) {
    return 0;
  }
  if (direction === 'desc') {
    return valueA > valueB ? -1 : 1;
  }
  return valueA > valueB ? 1 : -1;
}
