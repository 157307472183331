<button
  class="opacity-80"
  mat-icon-button
  matTooltip="Practice Settings"
  ptElementTarget="view-settings-button"
  [matMenuTriggerFor]="moreMenu"
>
  <mat-icon>settings</mat-icon>
</button>
<mat-menu class="view-settings" #moreMenu="matMenu">
  <form
    class="mb-4"
    (click)="$event.stopPropagation()"
    [formGroup]="practiceViewSettingsForm"
  >
    <h3 class="settings-heading">Practice Settings</h3>

    <pt-settings-menu-toggle-group-item
      label="Step Size (Mins)"
      formControlName="stepSizeInMins"
      ptElementTarget="step-size-button"
      [options]="stepSizesInMins"
      [disabled]="practiceSettingsDisabled$ | async"
    />

    <pt-settings-menu-toggle-group-item
      label="Hour Increments (Mins)"
      formControlName="hourIncrement"
      ptElementTarget="hour-increment-button"
      [options]="hourIncrements"
      [disabled]="practiceSettingsDisabled$ | async"
    />

    <pt-settings-menu-toggle-group-item
      label="Popover Delay"
      formControlName="tooltipDelay"
      ptElementTarget="popover-delay-button"
      [options]="tooltipDelays"
      [disabled]="practiceSettingsDisabled$ | async"
    />

    <mat-divider />

    <h3 class="settings-heading !my-2">Custom Colours</h3>
    <div class="flex flex-col gap-2 px-4" *ngrxLet="eventColourPairs$ as pairs">
      <div
        class="flex items-center justify-between"
        *ngFor="let pair of pairs; trackBy: trackByColourPair"
      >
        <span class="!text-xs">
          {{ pair.type | splitCamel | titlecase }}
        </span>
        <div class="flex items-center gap-4">
          @if (pair | map: hasColourOverride) {
            <button
              class="sync-button opacity-70"
              mat-icon-button
              matTooltip="Revert to default"
              (click)="saveEventColour(pair.type)"
            >
              <mat-icon>sync</mat-icon>
            </button>
          }
          <pt-event-colour-picker
            [colour]="pair.colour"
            [palette]="eventPalette"
            (colourSelected)="saveEventColour(pair.type, $event)"
          />
        </div>
      </div>

      <div class="flex items-center justify-between">
        <span class="!text-xs">Deadzone</span>
        <div class="flex items-center gap-4">
          @if (hasDeadzoneColourOverride$ | async) {
            <button
              class="sync-button opacity-70"
              mat-icon-button
              matTooltip="Revert to default"
              (click)="saveDeadzoneColour()"
            >
              <mat-icon>sync</mat-icon>
            </button>
          }
          <pt-event-colour-picker
            [colour]="deadzoneColour$ | async"
            [palette]="deadzonePalette"
            [disableOpacity]="true"
            (colourSelected)="saveDeadzoneColour($event)"
          />
        </div>
      </div>
    </div>
  </form>
</mat-menu>
