<div fxLayout="column" fxLayoutGap="16px">
  <div class="flex gap-4">
    <pt-search-field class="flex-1">
      <input
        matInput
        placeholder="Search"
        [formControl]="searchInput"
        #search
      />
    </pt-search-field>
    <button mat-raised-button color="primary" (click)="createTreatmentConfig()">
      Create Treatment Configuration
    </button>
  </div>

  <pr-empty-state
    *ngIf="emptyState$ | async"
    image="list"
    title="treatment configurations"
  />

  <div>
    <ng-container *ngIf="groups$ | async as groups">
      <ng-container *ngIf="groups.length; else noSearchResults">
        <div
          *ngFor="let group of groups$ | async; trackBy: trackByGroup"
          class="category-card flex"
        >
          <div
            class="colour-indicator"
            [style.backgroundColor]="group.category?.colour.value"
          ></div>
          <div class="flex-1">
            <h3 class="mat-headline-6">
              {{ group.category?.name ?? 'Uncategorised' }}
            </h3>
            <mat-list role="list">
              <mat-list-item
                *ngFor="
                  let treatment of group.treatments;
                  trackBy: trackByTreatment
                "
                role="listitem"
              >
                <mat-icon
                  *ngIf="treatment.display?.icon.slug"
                  matListItemIcon
                  [ngStyle]="{ color: treatment.display.colour.value }"
                  [svgIcon]="treatment.display.icon.slug"
                />
                <a
                  matListItemTitle
                  [routerLink]="treatment.ref.id | map : getRoute : this"
                >
                  {{ treatment.name }}
                </a>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </ng-container>
      <ng-template #noTreatmentConfigurations>
        <pr-empty-state image="list" title="treatment configurations" />
      </ng-template>
    </ng-container>
  </div>
</div>

<ng-template #noSearchResults>
  <pr-empty-state
    *ngIf="(emptyState$ | async) === false"
    image="search"
    title="search results"
    [searchInputValue]="search.value"
  />
</ng-template>
