<mat-list-item
  *ngFor="let item of checklist$ | async; trackBy: trackByChecklistItem"
>
  <div class="flex flex-1 items-center">
    <mat-icon *ngIf="item | map : useCustomIcon; else defaultIcon" class="icon">
      {{ item.action }}
    </mat-icon>
    <ng-template #defaultIcon>
      <mat-icon class="icon">check</mat-icon>
    </ng-template>
    <div class="listItem flex-1">
      <p>{{ item.title }}</p>
    </div>
    <pt-buttons-container>
      <button mat-icon-button (click)="updateItem.emit(item)">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button color="warn" (click)="deleteItem.emit(item)">
        <mat-icon>delete</mat-icon>
      </button>
    </pt-buttons-container>
  </div>
</mat-list-item>
