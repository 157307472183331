import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  IInvoiceReportRecord,
  type IInvoiceReportRequest,
} from '@principle-theorem/reporting';
import { serialise, unserialise$ } from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { setQuery } from './outstanding-invoices.actions';
import { type IOutstandingInvoicesReportState } from './outstanding-invoices.reducers';
import {
  getErrorMessage,
  getIsLoading,
  getOutstandingInvoicesReport,
  getQuery,
} from './outstanding-invoices.selectors';

@Injectable()
export class OutstandingInvoicesReportFacade {
  private _store = inject(Store<IOutstandingInvoicesReportState>);
  query$: Observable<IInvoiceReportRequest | undefined>;
  results$: Observable<IInvoiceReportRecord[]>;
  isLoading$: Observable<boolean>;
  errorMessage$: Observable<string | undefined>;

  constructor() {
    this.query$ = this._store.pipe(select(getQuery), unserialise$());
    this.results$ = this._store.pipe(
      select(getOutstandingInvoicesReport),
      unserialise$()
    );
    this.isLoading$ = this._store.pipe(select(getIsLoading), unserialise$());
    this.errorMessage$ = this._store.pipe(
      select(getErrorMessage),
      unserialise$()
    );
  }

  setQuery(query: IInvoiceReportRequest): void {
    this._store.dispatch(setQuery(serialise(query)));
  }
}
