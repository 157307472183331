<form>
  <mat-form-field
    class="no-style label-wrapper-prefix box-border flex h-full min-h-full w-full flex-col no-underline"
  >
    <mat-icon matIconPrefix>add</mat-icon>

    <input
      type="text"
      matInput
      placeholder="Add equipment, consumable or instrument"
      [formControl]="searchCtrl"
      [matAutocomplete]="autoGroup"
      #autoComplete="matAutocompleteTrigger"
      (click)="autoComplete.openPanel()"
    />

    <mat-autocomplete
      #autoGroup="matAutocomplete"
      autoActiveFirstOption
      [displayWith]="getSearchName"
      (optionSelected)="handleSelected($event)"
    >
      <mat-optgroup
        *ngFor="let group of filteredGroups$ | async; trackBy: trackByGroup"
        [label]="group.label"
      >
        <mat-option
          *ngFor="let item of group.filteredItems; trackBy: trackByAsset"
          [value]="item"
        >
          {{ item.name }}
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
  <mat-progress-bar
    *ngIf="loading$ | async"
    mode="indeterminate"
   />
</form>

<div class="assets">
  <ng-container *ngIf="hasAssets; else noAssetRequirements">
    <pr-treatment-asset-list
      title="Instruments"
      [treatmentConfig]="treatmentConfig"
      [requirements]="treatmentConfig.assetRequirements.instruments"
      [assets]="assets"
      (save)="handleSave()"
     />

    <pr-treatment-asset-list
      title="Equipment"
      [treatmentConfig]="treatmentConfig"
      [requirements]="treatmentConfig.assetRequirements.equipment"
      [assets]="assets"
      (save)="handleSave()"
     />

    <pr-treatment-asset-list
      title="Consumables"
      [treatmentConfig]="treatmentConfig"
      [requirements]="treatmentConfig.assetRequirements.consumables"
      [assets]="assets"
      (save)="handleSave()"
     />
  </ng-container>

  <ng-template #noAssetRequirements>
    <p class="mat-caption layout-padding">Nothing is required.</p>
  </ng-template>
</div>
