import {
  type DocumentData,
  type WithRef,
  type DocumentReference,
  type IReffable,
  type INamedDocument,
  IBlobFilenamePair,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { type IPracticeMigration } from './practice-migration';
import {
  ISourceLinkOption,
  type ITranslationMap,
  type ITranslationMapHandler,
} from './translation-mappings';
import {
  type ICustomMappings,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  CustomMappingOption,
} from './custom-mappings';

export interface ICustomMappingHandler<
  T extends object = DocumentData,
  R = unknown,
  AssociatedValueType = unknown,
> {
  customMapping: ICustomMapping;
  canHandle(customMapping: ICustomMapping): boolean;
  upsertRecord(
    record: Omit<ITranslationMap<T, R>, 'resourceType'>,
    translationMap: ITranslationMapHandler
  ): Promise<void>;
  getRecords$(
    translationMap: ITranslationMapHandler
  ): Observable<WithRef<ITranslationMap<T, R>>[]>;
  getBySource(
    sourceId: string,
    translationMap: ITranslationMapHandler
  ): Promise<WithRef<ITranslationMap<T, R>> | undefined>;
  getByDestination(
    destinationId: DocumentReference<T>,
    translationMap: ITranslationMapHandler
  ): Promise<WithRef<ITranslationMap<T, R>> | undefined>;
  getSourceOptions?(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]>;
  getSelectionListOptions?(
    migration: WithRef<IPracticeMigration>
  ): Promise<CustomMappingOption[]>;
  getDestinationOptions?(
    migration: IReffable<IPracticeMigration>
  ): Promise<INamedDocument[]>;
  selectedOptionRequiresValue?(value: R): boolean;
  getAssociatedValueOptions$?(
    migration: IPracticeMigration,
    destinationValue: R
  ): Observable<AssociatedValueType[]>;
  getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair>;
  downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void>;
  uploadMapping(
    migration: IReffable<IPracticeMigration>,
    file: Blob
  ): Promise<void>;
  autocompleteMappings?(
    migration: IReffable<IPracticeMigration>
  ): Promise<void>;
  getFileName(): string;
  getSourceLinkOptions?(
    migration: IReffable<IPracticeMigration>
  ): Promise<ISourceLinkOption[]>;
}

export interface ICustomMappingsHandler {
  migrationType: string;
  migrationVersion: string;
  mappings: ICustomMapping[];
  mappingHandlers: ICustomMappingHandler[];
  canHandle(destination: ICustomMappings): boolean;
  addEntities(migration: WithRef<IPracticeMigration>): Promise<void>;
}
