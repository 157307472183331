import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  type IInteraction,
  type ILab,
} from '@principle-theorem/principle-core/interfaces';
import {
  doc$,
  filterUndefined,
  findProp,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-lab-timeline',
  templateUrl: './lab-timeline.component.html',
  styleUrls: ['./lab-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabTimelineComponent {
  interactions$: Observable<IInteraction[]>;

  constructor(private _route: ActivatedRoute) {
    this.interactions$ = this._route.data.pipe(
      findProp<WithRef<ILab>>('lab'),
      filterUndefined(),
      switchMap((lab) => doc$(lab.ref)),
      map((lab) => lab.interactions)
    );
  }
}
