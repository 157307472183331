import {
  DEFAULT_DATA_UID,
  IDestinationEntity,
  MODIFIED_DATA_UID,
  SourceEntityRecordCollection,
  SourceEntityRecordMigrationStatus,
  SourceEntityRecordStatus,
  type ISourceEntity,
  type ISourceEntityHandler,
  type ISourceEntityRecord,
  type ISourceEntityRecordData,
  type ISourceEntityRecordFile,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  QueryConstraint,
  all$,
  errorNil,
  subCollection,
  toTimestamp,
  unserialise,
  where,
  type CollectionReference,
  type IReffable,
  type Timezone,
  type WithRef,
  doc,
  Firestore,
  Transaction,
} from '@principle-theorem/shared';
import { of, type Observable, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SourceEntity } from './source-entity';

export class SourceEntityRecord {
  static getLatestData$<
    T = unknown,
    R = unknown,
    Filters extends object = object,
  >(
    record: IReffable<ISourceEntityRecord<Filters>>
  ): Observable<WithRef<ISourceEntityRecordData<T, R>>> {
    return from(
      Firestore.getDoc(
        doc(SourceEntityRecord.dataCol<T, R>(record), MODIFIED_DATA_UID)
      )
    ).pipe(
      switchMap((result) =>
        result
          ? of(result)
          : from(
              Firestore.getDoc(
                doc(SourceEntityRecord.dataCol<T, R>(record), DEFAULT_DATA_UID)
              )
            )
      ),
      catchError(() =>
        from(
          Firestore.getDoc(
            doc(SourceEntityRecord.dataCol<T, R>(record), DEFAULT_DATA_UID)
          )
        )
      ),
      errorNil(
        `SourceEntityRecord.getLatestData$ - No data found for record ${record.ref.path}`
      ),
      switchMap(SourceEntityRecord.resolveRecordData)
    );
  }

  static dataCol<T = unknown, R = unknown>(
    record: IReffable<ISourceEntityRecord>
  ): CollectionReference<
    ISourceEntityRecordData<T, R> | ISourceEntityRecordFile<R>
  > {
    return subCollection<
      ISourceEntityRecordData<T, R> | ISourceEntityRecordFile<R>
    >(record.ref, SourceEntityRecordCollection.Data);
  }

  static data$<T = unknown, R = unknown>(
    record: IReffable<ISourceEntityRecord>
  ): Observable<
    WithRef<ISourceEntityRecordData<T, R> | ISourceEntityRecordFile<R>>[]
  > {
    return all$(SourceEntityRecord.dataCol(record));
  }

  static buildRecord(
    sourceEntityHandler: ISourceEntityHandler,
    record: object,
    sourceEntity: WithRef<ISourceEntity>,
    timezone: Timezone
  ): ISourceEntityRecord {
    const uid = SourceEntity.determineUidForRecord(
      sourceEntityHandler.getSourceRecordId(record),
      sourceEntity
    );
    return {
      uid,
      label: sourceEntityHandler.getSourceLabel(record),
      filters: sourceEntityHandler.getFilterData
        ? sourceEntityHandler.getFilterData(record, timezone)
        : {},
      lastSync: toTimestamp(),
      status: SourceEntityRecord.determineStatus(sourceEntityHandler, record),
      migrationDestinations: sourceEntityHandler.migrationDestinations?.reduce(
        (destinations, destinationKey) => ({
          ...destinations,
          [destinationKey]: SourceEntityRecordMigrationStatus.NotMigrated,
        }),
        {}
      ),
    };
  }

  static buildData(
    sourceEntityHandler: ISourceEntityHandler,
    uid: string,
    record: object,
    timezone: Timezone
  ): ISourceEntityRecordData {
    try {
      const translations = sourceEntityHandler.translate(record, timezone);
      return {
        uid,
        data: record,
        type: 'jsonSerialisable',
        translations,
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error translating record', record);
      throw error;
    }
  }

  static determineStatus(
    sourceEntityHandler: ISourceEntityHandler,
    data: unknown
  ): SourceEntityRecordStatus {
    return sourceEntityHandler.verifySource(data)
      ? SourceEntityRecordStatus.Valid
      : SourceEntityRecordStatus.Invalid;
  }

  static async resolveRecordData<T = unknown, R = unknown>(
    data: WithRef<ISourceEntityRecordData<T, R> | ISourceEntityRecordFile<R>>
  ): Promise<WithRef<ISourceEntityRecordData<T, R>>> {
    if (data.type === 'jsonSerialisable') {
      return data as WithRef<ISourceEntityRecordData<T, R>>;
    }

    const file = await fetch(data.url);
    const fileJson = (await file.json()) as unknown;
    return {
      ...data,
      data: unserialise(fileJson),
      type: 'jsonSerialisable',
    } as WithRef<ISourceEntityRecordData<T, R>>;
  }

  static async setMigratedByDestination(
    destinationKey: string,
    sourceRef: DocumentReference<ISourceEntityRecord>,
    transaction?: Transaction
  ): Promise<void> {
    const source = await Firestore.getDoc(sourceRef, transaction);
    await Firestore.patchDoc(
      sourceRef,
      {
        migrationDestinations: {
          ...source.migrationDestinations,
          [destinationKey]: SourceEntityRecordMigrationStatus.Migrated,
        },
      },
      transaction
    );
  }
}

export function buildFilterMigratedQuery(
  skipMigrated: boolean,
  retryMigrated: boolean,
  destinationEntity: IDestinationEntity
): QueryConstraint[] {
  if (skipMigrated) {
    return [
      where(
        `migrationDestinations.${destinationEntity.metadata.key}`,
        '==',
        SourceEntityRecordMigrationStatus.NotMigrated
      ),
    ];
  }

  if (retryMigrated) {
    return [
      where(
        `migrationDestinations.${destinationEntity.metadata.key}`,
        '==',
        SourceEntityRecordMigrationStatus.Migrated
      ),
    ];
  }

  return [];
}
