import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrentPatientScope } from '@principle-theorem/ng-principle-shared';
import { Patient } from '@principle-theorem/principle-core';
import {
  IMedia,
  PatientRelationshipType,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  query$,
  undeletedQuery,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-appointment-imaging',
    templateUrl: './appointment-imaging.component.html',
    styleUrls: ['./appointment-imaging.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentImagingComponent {
  media$: Observable<WithRef<IMedia>[]>;
  patient$: Observable<WithRef<IPatient>>;

  constructor(private _patientScope: CurrentPatientScope) {
    this.patient$ = this._patientScope.doc$.pipe(filterUndefined());
    this.media$ = this.patient$.pipe(
      switchMap((patient) =>
        Patient.withPatientRelationships$(
          patient,
          [PatientRelationshipType.DuplicatePatient],
          (patientReffable) =>
            query$(undeletedQuery(Patient.mediaCol(patientReffable)))
        )
      )
    );
  }
}
