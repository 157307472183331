import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  type OnDestroy,
  TemplateRef,
  ViewContainerRef,
  OnInit,
} from '@angular/core';
import { isPathChanged$ } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ManagementService } from './management.service';

@Directive({
  selector: '[prIsManagement]',
  standalone: false,
})
export class IsManagementDirective implements OnInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _thenTemplateRef: TemplateRef<unknown>;

  constructor(
    private _viewContainer: ViewContainerRef,
    templateRef: TemplateRef<unknown>,
    private _management: ManagementService,
    private _cdr: ChangeDetectorRef
  ) {
    this._thenTemplateRef = templateRef;
    this._viewContainer.clear();
  }

  ngOnInit(): void {
    this._management.user$
      .pipe(
        isPathChanged$('ref.path'),
        map((managementUser) => {
          if (managementUser) {
            return true;
          }
          return false;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((isManagement) => {
        this._viewContainer.clear();
        if (isManagement) {
          this._viewContainer.createEmbeddedView(this._thenTemplateRef);
          this._applyManagementClass();
          this._cdr.detectChanges();
          return;
        }
        this._cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _applyManagementClass(): void {
    const elementRef = this._viewContainer.injector.get(ElementRef);
    const childElement = (elementRef.nativeElement as Comment)
      .previousElementSibling as Element;
    childElement.classList.add('management-only');
  }
}
