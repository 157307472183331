import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import { CurrentPracticeScope } from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  type TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { Practice } from '@principle-theorem/principle-core';
import {
  type RecurringTaskDueTiming,
  TIMING_UNITS,
  TimingUnit,
} from '@principle-theorem/principle-core/interfaces';
import * as moment from 'moment-timezone';
import { type Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { type RecurringTaskConfigurationForm } from '../recurring-task-configuration-form/recurring-task-configuration-form';
import { type TaskFormGroup } from '../task-form/task-form.formgroup';

@Component({
  selector: 'pr-recurring-task-due-date',
  templateUrl: './recurring-task-due-date.component.html',
  styleUrls: ['./recurring-task-due-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecurringTaskDueDateComponent implements OnInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByTimingUnit = TrackByFunctions.variable<TimingUnit>();
  timingUnits: TimingUnit[] = TIMING_UNITS;
  openTime$: Observable<moment.Moment>;
  closeTime$: Observable<moment.Moment>;
  timeInterval: moment.Duration = moment.duration(30, 'minutes');
  @Input() form: TaskFormGroup | RecurringTaskConfigurationForm;

  constructor(protected _practiceScope: CurrentPracticeScope) {
    this.openTime$ = this._practiceScope
      .asObservable()
      .pipe(map((practice) => Practice.openTime(practice)));
    this.closeTime$ = this._practiceScope
      .asObservable()
      .pipe(map((practice) => Practice.closeTime(practice)));
  }

  ngOnInit(): void {
    const timingGroup = this.form.controls
      .recurrenceTiming as TypedFormGroup<RecurringTaskDueTiming>;

    timingGroup.controls.unit.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((timingUnit) => {
        if (!timingUnit) {
          return;
        }
        if (this._isMinutesOrHours(timingUnit)) {
          timingGroup.controls.time.disable();
          return;
        }
        timingGroup.controls.time.enable();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _isMinutesOrHours(unit: TimingUnit): boolean {
    return unit === TimingUnit.Minutes || unit === TimingUnit.Hours;
  }
}
