import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type HealthPointReconciliationReportResponse } from '@principle-theorem/tyro';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-healthpoint-reconciliation-report',
  templateUrl: './healthpoint-reconciliation-report.component.html',
  styleUrls: ['./healthpoint-reconciliation-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthpointReconciliationReportComponent {
  report$ = new ReplaySubject<
    HealthPointReconciliationReportResponse | undefined
  >(1);

  @Input()
  set report(report: HealthPointReconciliationReportResponse | undefined) {
    this.report$.next(report);
  }
}
