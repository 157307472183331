import { initVersionedSchema, toTextContent } from '@principle-theorem/editor';
import {
  Appointment,
  Event,
  Interaction,
  TreatmentPlan,
  hasMergeConflicts,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  EventType,
  IDestinationEntityJobRunOptions,
  IMigratedDataSummary,
  ParticipantType,
  SkippedDestinationEntityRecord,
  type FailedDestinationEntityRecord,
  type IAppointment,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IEvent,
  type IGetRecordResponse,
  type IInteractionV2,
  type IPatient,
  type IPractice,
  type IPracticeMigration,
  type ISourceEntityRecord,
  type IStaffer,
  type ITreatmentPlan,
  type ITreatmentStep,
  type MergeConflictDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  SystemActors,
  asDocRef,
  asyncForAll,
  asyncForEach,
  errorNil,
  getError,
  isObject,
  isSameRef,
  multiSwitchMap,
  resolveSequentially,
  safeCombineLatest,
  snapshotCombineLatest,
  toNamedDocument,
  toTimestamp,
  upsertBulk,
  type DocumentReference,
  type Timestamp,
  type WithRef,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { compact, first, isString } from 'lodash';
import { combineLatest, of, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import {
  IAppointmentSuccessData,
  IPatientAppointmentJobData,
  PATIENT_APPOINTMENT_RESOURCE_TYPE,
} from '../../../destination/entities/patient-appointments';
import {
  PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
  PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
} from '../../../destination/entities/patient-treatment-plans';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { getPractitionerOrDefaultMapping } from '../../../mappings/staff';
import { PracticeMigration } from '../../../practice-migrations';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  IPraktikaPatient,
  IPraktikaPatientTranslations,
  PatientSourceEntity,
} from '../../source/entities/patient';
import {
  PatientAppointmentSourceEntity,
  type IPraktikaAppointment,
  type IPraktikaAppointmentTranslations,
} from '../../source/entities/patient-appointment';
import {
  PatientAppointmentNoteSourceEntity,
  type IPraktikaAppointmentNote,
  type IPraktikaAppointmentNoteTranslations,
} from '../../source/entities/patient-appointment-notes';
import { PraktikaAppointmentStepSizeMappingHandler } from '../mappings/appointment-step-size';
import { PraktikaPracticeMappingHandler } from '../mappings/practices';
import { PraktikaStafferMappingHandler } from '../mappings/staff';
import {
  PatientTreatmentPlanDestinationEntity,
  getAppointmentStatus,
} from './patient-treatment-plans';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';

export const PATIENT_APPOINTMENT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_APPOINTMENT_RESOURCE_TYPE,
    label: 'Patient Appointments',
    description: '',
  },
});

interface IJobData
  extends IPatientAppointmentJobData<
    IPraktikaPatient,
    IPraktikaPatientTranslations
  > {
  practice: WithRef<IPractice>;
}

export interface IPatientAppointmentBuildData {
  sourceAppointmentId: string;
  appointment: IAppointment;
  interactions: IInteractionV2[];
  createdAt: Timestamp;
}

export interface IPatientAppointmentMigrationData {
  patientRef: DocumentReference<IPatient>;
  sourcePatientId: string;
  appointments: IPatientAppointmentBuildData[];
}

export class PatientAppointmentDestinationEntity extends BaseDestinationEntity<
  IAppointmentSuccessData,
  IJobData,
  IPatientAppointmentMigrationData
> {
  destinationEntity = PATIENT_APPOINTMENT_DESTINATION_ENTITY;
  override canMigrateByDateRange = true;

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
    appointmentNotes: new PatientAppointmentNoteSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    treatmentPlans: new PatientTreatmentPlanDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new PraktikaStafferMappingHandler(),
    practice: new PraktikaPracticeMappingHandler(),
    appointmentStepSize: new PraktikaAppointmentStepSizeMappingHandler(),
  };

  sourceCountDataAccessor(
    data: IJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IAppointmentSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      Firestore.getDoc(record.data.sourceRef),
      Firestore.getDoc(record.data.patientRef),
      safeCombineLatest(
        record.data.appointmentRefs.map((appointmentRef) =>
          Firestore.getDoc(appointmentRef)
        )
      ),
    ]).pipe(
      map(([sourcePatient, patient, appointments]) => {
        const data: IMigratedDataSummary[] = [
          {
            label: 'Source Patient',
            data: sourcePatient,
          },
          {
            label: 'Patient',
            data: patient,
          },
        ];

        data.push(
          ...appointments.map((appointment) => ({
            label: 'Appointments',
            data: appointment,
          }))
        );

        return data;
      })
    );
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IJobData[]> {
    const brand$ = Firestore.getDoc(migration.configuration.brand.ref);
    const practice$ = PracticeMigration.practices$(migration).pipe(
      map((practices) => first(practices)),
      errorNil('Practice not found')
    );
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMapHandler),
      translationMapHandler.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const practitioners$ = staff$.pipe(
      multiSwitchMap((staffer) =>
        staffer.destinationIdentifier
          ? Firestore.doc$(staffer.destinationIdentifier)
          : of(undefined)
      ),
      map(compact)
    );

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([brand$, staff$, practitioners$, practice$]),
    ]).pipe(
      map(([sourcePatients, [brand, staff, practitioners, practice]]) =>
        sourcePatients.map((sourcePatient) => ({
          sourcePatient,
          brand,
          staff,
          practitioners,
          practice,
        }))
      )
    );
  }

  getDestinationEntityRecordUid(data: IJobData): string {
    return data.sourcePatient.record.uid;
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IJobData
  ): Promise<
    | IPatientAppointmentMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
    | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients.getSourceRecordId(
      data.sourcePatient.data.data
    );

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `No patient with id ${sourcePatientId}`
      );
    }

    const patient = await Firestore.getDoc(patientRef);

    const sourceAppointments =
      await this.sourceEntities.appointments.filterRecords(
        migration,
        'patientId',
        data.sourcePatient.data.data.patient_id.toString()
      );

    try {
      const appointments: (IPatientAppointmentBuildData | undefined)[] =
        await asyncForAll(sourceAppointments, async (sourceAppointment) => {
          const sourceAppointmentId =
            sourceAppointment.data.data.appointment_id.toString();

          const practitionerRef = getPractitionerOrDefaultMapping(
            sourceAppointment.data.data.appointment_providerid ?? undefined,
            data.staff
          )?.destinationIdentifier;

          const planMap = await translationMap.getDestination<ITreatmentPlan>(
            sourcePatientId.toString(),
            PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE
          );
          const stepMap = await translationMap.getDestination<ITreatmentStep>(
            `${sourcePatientId}-${sourceAppointmentId}`,
            PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
          );

          if (
            !patientRef ||
            !practitionerRef ||
            !planMap ||
            !stepMap ||
            !data.practice
          ) {
            const message = [''];
            if (!practitionerRef) {
              message.push(
                `No practitioner with id ${
                  sourceAppointment.data.data.appointment_providerid?.toString() ??
                  ''
                }`
              );
            }

            if (!planMap?.id) {
              message.push('No plan');
            }

            if (!stepMap?.id) {
              message.push(`No step`);
            }

            throw new Error(message.join('; '));
          }

          const createdAt = sourceAppointment.data.translations.createdAt;

          const appointmentNotes =
            await this.sourceEntities.appointmentNotes.filterRecords(
              migration,
              'appointmentId',
              sourceAppointmentId
            );

          const [appointment, interactions] = await this._buildAppointmentData(
            patient,
            practitionerRef,
            planMap,
            stepMap,
            sourceAppointment,
            appointmentNotes,
            data.practitioners,
            data.practice,
            migration
          );

          return { sourceAppointmentId, createdAt, appointment, interactions };
        });

      return {
        sourcePatientId: sourcePatientId.toString(),
        patientRef,
        appointments: compact(appointments),
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IPatientAppointmentMigrationData
  ): Promise<IPatientAppointmentMigrationData | undefined> {
    const existingAppointments: IPatientAppointmentBuildData[] = [];

    const appointmentMergeConflicts = await asyncForEach(
      data.appointments,
      async (appointment) => {
        const appointmentRef = await translationMap.getDestination(
          appointment.sourceAppointmentId,
          PATIENT_APPOINTMENT_RESOURCE_TYPE
        );

        try {
          const existingAppointment = appointmentRef
            ? await Firestore.getDoc(asDocRef<IAppointment>(appointmentRef))
            : undefined;

          if (!existingAppointment) {
            return;
          }

          const builtData: IPatientAppointmentBuildData = {
            ...appointment,
            appointment: {
              ...existingAppointment,
            },
          };

          existingAppointments.push(builtData);

          return hasMergeConflicts(
            appointment.appointment,
            existingAppointment,
            ['dateFrom', 'dateTo', 'treatmentPlan', 'statusHistory', 'ref'],
            [
              {
                key: 'tags',
                typeGuardFn: (item): item is unknown =>
                  isObject(item) && isString(item.name),
                sortByPath: 'name',
              },
            ]
          );
        } catch (error) {
          return false;
        }
      }
    );

    if (appointmentMergeConflicts.some((mergeConflict) => mergeConflict)) {
      return {
        ...data,
        appointments: existingAppointments,
      };
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IJobData,
    _migrationData: IPatientAppointmentMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    jobData: IJobData,
    migrationData: IPatientAppointmentMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const appointmentRefs = await resolveSequentially(
        migrationData.appointments,
        async (appointmentData) => {
          const appointmentRef = await this._upsertAppointment(
            appointmentData.appointment,
            appointmentData.sourceAppointmentId,
            translationMapHandler,
            migrationData.patientRef,
            appointmentData.createdAt
          );

          await upsertBulk(
            Appointment.interactionCol({ ref: appointmentRef }),
            appointmentData.interactions,
            'uid',
            SystemActors.Migration
          );

          return appointmentRef;
        }
      );

      return this._buildSuccessResponse(
        jobData.sourcePatient,
        migrationData.patientRef,
        appointmentRefs
      );
    } catch (error) {
      return this._buildErrorResponse(jobData.sourcePatient, getError(error));
    }
  }

  private _buildSuccessResponse(
    patient: IGetRecordResponse<IPraktikaPatient, IPraktikaPatientTranslations>,
    patientRef: DocumentReference<IPatient>,
    appointmentRefs: DocumentReference<IAppointment>[]
  ): IDestinationEntityRecord<IAppointmentSuccessData> {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      data: {
        sourceRef: patient.record.ref,
        patientRef,
        appointmentRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: patient.record.ref,
      migratedAt: toTimestamp(),
    };
  }

  private async _upsertAppointment(
    appointment: IAppointment,
    appointmentUid: string,
    translationMap: TranslationMapHandler,
    patientRef: DocumentReference<IPatient>,
    createdAt: Timestamp
  ): Promise<DocumentReference<IAppointment>> {
    const patientAppointmentDestinationRef =
      await translationMap.getDestination(
        appointmentUid,
        PATIENT_APPOINTMENT_RESOURCE_TYPE
      );

    const appointmentRef = await FirestoreMigrate.upsertDoc(
      Appointment.col({
        ref: patientRef,
      }),
      { ...appointment, createdAt },
      patientAppointmentDestinationRef?.id
    );

    if (!patientAppointmentDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: appointmentUid,
        destinationIdentifier: appointmentRef,
        resourceType: PATIENT_APPOINTMENT_RESOURCE_TYPE,
      });
    }

    await FirestoreMigrate.patchDoc(
      appointment.treatmentPlan.treatmentStep.ref,
      {
        appointment: appointmentRef,
      }
    );

    return appointmentRef;
  }

  private async _buildAppointmentData(
    patient: WithRef<IPatient>,
    practitionerRef: DocumentReference<IStaffer>,
    planRef: DocumentReference<ITreatmentPlan>,
    stepRef: DocumentReference<ITreatmentStep>,
    appointment: IGetRecordResponse<
      IPraktikaAppointment,
      IPraktikaAppointmentTranslations
    >,
    appointmentNotes: IGetRecordResponse<
      IPraktikaAppointmentNote,
      IPraktikaAppointmentNoteTranslations
    >[],
    practitioners: WithRef<IStaffer>[],
    practice: WithRef<IPractice>,
    migration: WithRef<IPracticeMigration>
  ): Promise<[IAppointment, IInteractionV2[]]> {
    const treatmentPlan = await Firestore.getDoc(planRef);
    const treatmentStep = await Firestore.getDoc(stepRef);
    const practitioner = practitioners.find((practitionerSearch) =>
      isSameRef(practitionerSearch, practitionerRef)
    );
    if (!practitioner) {
      throw new Error(`Can't find practitioner for ref ${practitionerRef.id}`);
    }

    const interactions = appointmentNotes.map((note) =>
      Interaction.init({
        uid: note.record.uid,
        title: [toTextContent(`Added a note`)],
        content: initVersionedSchema(note.data.data.note),
        createdAt: note.data.translations.date,
        pinned: note.data.data.note.startsWith('[') ? false : true,
      })
    );

    const appointmentStartTime = appointment.data.translations.from;
    const appointmentEndTime =
      await this.customMappings.appointmentStepSize.getAppointmentEndTime(
        migration,
        appointment.data.data,
        practice.settings.timezone
      );

    const newAppointment = Appointment.init({
      status: getAppointmentStatus(
        appointment.data.data,
        appointment.data.translations,
        migration.configuration.backupDate,
        practice.settings.timezone
      ),
      event: getAppointmentEvent(
        appointmentStartTime,
        appointmentEndTime,
        patient,
        practice,
        practitioner
      ),
      practice: toNamedDocument(practice),
      practitioner: stafferToNamedDoc(practitioner),
      treatmentPlan: TreatmentPlan.treatmentStepToAssociatedTreatment(
        treatmentPlan,
        treatmentStep
      ),
    });

    return [newAppointment, interactions];
  }

  private _buildErrorResponse(
    patient: IGetRecordResponse<IPraktikaPatient, IPraktikaPatientTranslations>,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: patient.record.ref,
      errorMessage: errorMessage ?? 'Missing required properties for patient',
      failData: {
        patientRef: patient.record.ref,
      },
    };
  }
}

export function getAppointmentEvent(
  startTime?: Timestamp,
  endTime?: Timestamp,
  patient?: WithRef<IPatient>,
  practice?: WithRef<IPractice>,
  practitioner?: WithRef<IStaffer>
): IEvent | undefined {
  if (!startTime || !endTime || !practice || !patient || !practitioner) {
    return;
  }
  return Event.init({
    from: startTime,
    to: endTime,
    practice: toNamedDocument(practice),
    type: EventType.Appointment,
    participants: [
      {
        ...toNamedDocument(patient),
        type: ParticipantType.Patient,
      },
      {
        ...stafferToNamedDoc(practitioner),
        type: ParticipantType.Staffer,
      },
    ],
  });
}
