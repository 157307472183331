import {
  AtLeast,
  INamedDocument,
  IXSLXExport,
  XSLXCell,
  isSameRef,
} from '@principle-theorem/shared';
import { Column } from 'exceljs';
import {
  IPractice,
  ITranslationMap,
} from '@principle-theorem/principle-core/interfaces';
import { IBaseMigrationPractice } from './practices';

export interface IPracticeToXSLX {
  id: string | number;
  label: string;
  mapTo: string;
}

export const PRACTICE_HEADERS: AtLeast<Column, 'key' | 'header'>[] = [
  {
    key: 'id',
    header: 'Practice Identifier',
  },
  {
    key: 'label',
    header: 'Practice Name',
  },
  {
    key: 'mapTo',
    header: 'Map To',
  },
];

export class PracticesToXSLX<
  T extends IBaseMigrationPractice = IBaseMigrationPractice,
> implements IXSLXExport<T, IPracticeToXSLX>
{
  headers = PRACTICE_HEADERS;

  constructor(
    private _practices: INamedDocument<IPractice>[],
    private _existingMappings: ITranslationMap<IPractice>[]
  ) {}

  translate(records: T[]): Record<keyof IPracticeToXSLX, XSLXCell>[] {
    return records.map((record) => {
      const existingRecord = this._existingMappings.find(
        (existingMapping) =>
          existingMapping.sourceIdentifier === record.id.toString()
      );
      const destinationIdentifier = existingRecord?.destinationIdentifier;
      const mappedPractice = destinationIdentifier
        ? this._practices.find((practice) =>
            isSameRef(practice.ref, destinationIdentifier)
          )
        : undefined;

      return {
        id: { value: record.id },
        label: { value: record.name },
        mapTo: {
          value: mappedPractice?.name || '',
          dataValidation: {
            type: 'list',
            formulae: [
              `"${this._practices.map((practice) => practice.name).join(',')}"`,
            ],
          },
        },
      };
    });
  }
}
