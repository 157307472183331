import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
  TrackByFunctions,
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
  validFormArrayChanges$,
} from '@principle-theorem/ng-shared';
import {
  resolveClaimItems,
  toClaimableItems,
  toSingleClaimItems,
} from '@principle-theorem/principle-core';
import {
  IHealthcareClaim,
  IInvoice,
  type IADACodeClaim,
  type IServiceCodeLineItem,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  isChanged$,
  shareReplayCold,
} from '@principle-theorem/shared';
import { sum } from 'lodash';
import { Subject, type Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export function totalClaimAmount(items: IADACodeClaim[]): number {
  return sum(items.filter((item) => item.claim).map((item) => item.amount));
}

@Component({
    selector: 'pr-select-claim-items',
    templateUrl: './select-claim-items.component.html',
    styleUrls: ['./select-claim-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'prSelectClaimItems',
    standalone: false
})
export class SelectClaimItemsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  itemsForm = new TypedFormArray<IADACodeClaim>([]);
  trackByItems =
    TrackByFunctions.nestedField<TypedFormGroup<IADACodeClaim>>(
      'value.lineItem.uid'
    );
  claimTotal$: Observable<number>;
  @Output() itemsChange = new EventEmitter<IADACodeClaim[]>();

  @Input() isPartPaid: boolean = false;

  constructor() {
    const changes$ = validFormArrayChanges$(this.itemsForm);
    this.claimTotal$ = changes$.pipe(
      map((items) => totalClaimAmount(items)),
      shareReplayCold()
    );
    changes$
      .pipe(isChanged$(), takeUntil(this._onDestroy$))
      .subscribe((value) => this.itemsChange.emit(value));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @Input()
  set items(items: IADACodeClaim[]) {
    if (!items) {
      return;
    }
    this.itemsForm.clear();
    items.map((item) => this.itemsForm.push(this._getClaimGroup(item)));
  }

  getItemMax(item: IADACodeClaim): number {
    return this.isPartPaid ? item.lineItem.amount - 0.01 : item.lineItem.amount;
  }

  private _getClaimGroup(item: IADACodeClaim): TypedFormGroup<IADACodeClaim> {
    return new TypedFormGroup<IADACodeClaim>({
      lineItem: new TypedFormControl<IServiceCodeLineItem>(
        item.lineItem,
        Validators.required
      ),
      claim: new TypedFormControl<boolean>(item.claim, Validators.required),
      amount: new TypedFormControl<number>(item.amount, [
        Validators.required,
        Validators.min(this.isPartPaid ? 1 : 0),
        Validators.max(this.getItemMax(item)),
      ]),
    });
  }
}

export function lineItemToClaim(lineItem: IServiceCodeLineItem): IADACodeClaim {
  return {
    lineItem,
    amount: lineItem.amount,
    claim: true,
  };
}

export function getServiceItems(
  invoice: WithRef<IInvoice>,
  claim: IHealthcareClaim
): IADACodeClaim[] {
  return toSingleClaimItems(
    toClaimableItems(resolveClaimItems(invoice, claim))
  ).map((single) => lineItemToClaim(single.serviceCode));
}

export function filterClaimableItems(
  itemClaims: IADACodeClaim[]
): IADACodeClaim[] {
  return itemClaims
    .filter((itemClaim) => itemClaim.claim)
    .filter((itemClaim) => itemClaim.amount > 0);
}
