<div fxLayout="column">
  <div class="week-header">
    <div
      *ngFor="let day of daysOfWeek; trackBy: trackByDay"
      class="week-header-day"
      [ngClass]="{ 'week-header-today': isTodayHeader(day) }"
      fxFlex
    >
      {{ day | titlecase }}
    </div>
  </div>
  <ng-container *ngIf="monthMap$ | async as monthMap">
    <pr-week-row
      *ngFor="let row of monthMap; trackBy: trackByMonth"
      [days]="row.days"
      fxFlex
     />
  </ng-container>
</div>
