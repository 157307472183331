import { Details } from '@tiptap-pro/extension-details';
import { DetailsContent } from '@tiptap-pro/extension-details-content';
import { DetailsSummary } from '@tiptap-pro/extension-details-summary';
import { Emoji, gitHubEmojis } from '@tiptap-pro/extension-emoji';
import { AnyExtension } from '@tiptap/core';
import { BubbleMenu } from '@tiptap/extension-bubble-menu';
import { Color } from '@tiptap/extension-color';
import { Highlight } from '@tiptap/extension-highlight';
import { Placeholder } from '@tiptap/extension-placeholder';
import { TableOptions } from '@tiptap/extension-table';
import { TaskItem } from '@tiptap/extension-task-item';
import { TaskList } from '@tiptap/extension-task-list';
import { TextStyle } from '@tiptap/extension-text-style';
import { Typography } from '@tiptap/extension-typography';
import { Underline } from '@tiptap/extension-underline';
import { StarterKit } from '@tiptap/starter-kit';
import { createSelectAllExtension } from '../../extensions/select-all';
import { BlockNodes } from '../available-extensions';
import { createCodeBlockExtension } from './code/code-block';
import { disableSlashMenuMark } from './disable-slash-menu-mark';
import { createHardBreakExtension } from './document-formatting/hard-break';
import { createHorizontalRuleExtension } from './document-formatting/horizontal-rule';
import { createTextAlignExtension } from './document-formatting/text-align';
import { createDragAndDropExtension } from './drag-and-drop';
import { createLinkExtension } from './link/link';
import { createBulletedListExtension } from './lists/bulleted-list';
import { createListItemExtension } from './lists/list-item';
import { createOrderedListExtension } from './lists/ordered-list';
import { createTableExtension } from './table-extension';
import { createColumnExtension } from './column/column-extension';
import { badgeExtension } from './document-formatting/badge';
import { createTrailingNodeExtension } from './trailing-node';

export interface ICoreEditorExtensions {
  bulletedList: () => AnyExtension;
  column: () => AnyExtension;
  dragAndDrop: () => AnyExtension;
  listItem: () => AnyExtension;
  codeBlock: () => AnyExtension;
  hardBreak: () => AnyExtension;
  horizontalRule: () => AnyExtension;
  orderedList: () => AnyExtension;
  selectAll: () => AnyExtension;
  textAlign: () => AnyExtension;
  link: () => AnyExtension;
  trailingNode: () => AnyExtension;
  table: (options?: Partial<TableOptions>) => AnyExtension[];
}

export const coreEditorExtensions: ICoreEditorExtensions = {
  bulletedList: createBulletedListExtension,
  column: createColumnExtension,
  dragAndDrop: createDragAndDropExtension,
  orderedList: createOrderedListExtension,
  listItem: createListItemExtension,
  horizontalRule: createHorizontalRuleExtension,
  hardBreak: createHardBreakExtension,
  codeBlock: createCodeBlockExtension,
  selectAll: createSelectAllExtension,
  textAlign: createTextAlignExtension,
  link: createLinkExtension,
  table: createTableExtension,
  trailingNode: createTrailingNodeExtension,
};

export const DEFAULT_EXTENSIONS: AnyExtension[] = [
  coreEditorExtensions.column(),
  disableSlashMenuMark.configure(),
  coreEditorExtensions.bulletedList(),
  coreEditorExtensions.orderedList(),
  coreEditorExtensions.listItem(),
  coreEditorExtensions.horizontalRule(),
  coreEditorExtensions.hardBreak(),
  coreEditorExtensions.codeBlock(),
  coreEditorExtensions.textAlign(),
  coreEditorExtensions.link(),
  ...coreEditorExtensions.table({ resizable: true }),
  StarterKit.configure({
    bulletList: false,
    orderedList: false,
    codeBlock: false,
  }),
  Details.configure({
    persist: true,
    HTMLAttributes: {
      class: 'details',
    },
  }),
  DetailsSummary,
  DetailsContent,
  Placeholder.configure({
    placeholder: ({ editor }) => {
      if (editor.isActive(BlockNodes.Heading)) {
        return `What's the title?`;
      }

      if (editor.isActive(BlockNodes.CodeBlock)) {
        return `Start typing...`;
      }

      const isDisabledParagraph = editor.isActive(BlockNodes.Paragraph, {
        'data-disable-slash-menu': true,
      });

      if (isDisabledParagraph) {
        return '';
      }

      return `Type '/' for commands`;
    },
  }),
  Emoji.configure({
    emojis: gitHubEmojis,
    enableEmoticons: true,
  }),
  TaskList,
  TaskItem.configure({
    nested: true,
  }),
  Highlight.configure({
    multicolor: true,
    HTMLAttributes: {
      class: 'highlight',
    },
  }),
  badgeExtension.configure({
    multicolor: true,
    HTMLAttributes: {
      class: 'badge',
    },
  }),
  Underline,
  BubbleMenu,
  Color,
  TextStyle,
  Typography,
];

export const DEFAULT_INLINE_EXTENSIONS: AnyExtension[] = [
  StarterKit.configure({
    blockquote: false,
    bulletList: false,
    codeBlock: false,
    hardBreak: false,
    heading: false,
    horizontalRule: false,
    listItem: false,
    orderedList: false,
  }),
  Underline,
  coreEditorExtensions.selectAll(),
];
