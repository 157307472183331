<div
  *ngIf="tooltips$ | async as tooltips"
  fxLayout="row"
  fxLayoutAlign="start center"
>
  <a
    *ngIf="previousRange$ | async as previousRange"
    mat-icon-button
    [matTooltip]="tooltips.previous | titlecase"
    (click)="rangeChange.emit(previousRange)"
  >
    <mat-icon>chevron_left</mat-icon>
  </a>
  <a
    *ngIf="nextRange$ | async as nextRange"
    mat-icon-button
    [matTooltip]="tooltips.next | titlecase"
    (click)="rangeChange.emit(nextRange)"
  >
    <mat-icon>chevron_right</mat-icon>
  </a>
</div>
