import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { type ITransactionSourceSummary } from '@principle-theorem/reporting';
import { type SerialisedData } from '@principle-theorem/shared';
import { clearSelectedSummary, selectSummary } from '../actions/query.actions';

export const selectedSummaryReducer: ActionReducer<
  SerialisedData<ITransactionSourceSummary | undefined>,
  Action
> = createReducer<SerialisedData<ITransactionSourceSummary> | undefined>(
  undefined,
  on(selectSummary, (_state, action) => action),
  on(clearSelectedSummary, (_state, _action) => undefined)
);
