import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { BasicDialogService } from '@principle-theorem/ng-shared';
import { snapshot } from '@principle-theorem/shared';
import { VideoCommandsService } from '../video-commands.service';
import {
  IVideoEmbedOutput,
  VideoEmbedDialogComponent,
} from '../video-embed-dialog/video-embed-dialog.component';
import {
  BasicMenuButtonComponent,
  cleanUpBlockCommand,
} from '../../../menu-bar/basic-menu-button/basic-menu-button.component';

@Component({
  selector: 'pt-video-embed-menu',
  templateUrl: './video-embed-menu.component.html',
  styleUrls: ['./video-embed-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoEmbedMenuComponent extends BasicMenuButtonComponent {
  override tooltip = 'Embed Video';
  override buttonText = 'Embed Video';
  override icon = 'movie';

  constructor(
    private _dialog: BasicDialogService,
    private _videoCommands: VideoCommandsService
  ) {
    super();
  }

  async showLinkDialog(): Promise<void> {
    const embedLink = await this._dialog
      .open<VideoEmbedDialogComponent, void, IVideoEmbedOutput>(
        VideoEmbedDialogComponent,
        this._getDialogConfig()
      )
      .afterClosed()
      .toPromise();

    const editor = await snapshot(this.editor$);
    const buttonType = await snapshot(this.buttonType$);
    if (buttonType === 'block') {
      cleanUpBlockCommand(editor);
    }

    if (embedLink) {
      this._videoCommands.embed(embedLink.href, editor);
    }
  }

  override async runCommand(): Promise<void> {
    const editor = await snapshot(this.editor$);
    await this.showLinkDialog();
    editor.view.focus();
  }

  private _getDialogConfig(): MatDialogConfig<void> {
    return {
      width: `auto`,
      restoreFocus: false,
      hasBackdrop: true,
      autoFocus: true,
    };
  }
}
