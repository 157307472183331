<h1 class="mt-2">Explore your data</h1>

<ng-container *ngrxLet="store.currentReport$ | async as currentReport">
  <ng-template #availableCharts>
    @if (store.editMode$ | async) {
      <div *ngrxLet="showAvailableCharts$ | async as showAvailableCharts">
        <div
          class="sticky top-0 z-50 rounded-sm border border-solid border-gray-200 bg-white px-4 py-2"
        >
          <div class="flex items-center gap-4">
            <div class="text-lg font-bold">
              {{
                !currentReport ? 'Available Charts' : 'Add Additional Charts'
              }}
            </div>
            <button
              mat-stroked-button
              (click)="showAvailableCharts$.next(!showAvailableCharts)"
            >
              {{ showAvailableCharts ? 'Hide' : 'Show' }}
            </button>
          </div>
        </div>

        <div class="section" *ngIf="showAvailableCharts">
          <pr-report-builder-available-charts />
        </div>
      </div>
    }
  </ng-template>

  <ng-template #previewCustomReport>
    <div>
      <div
        class="sticky top-0 z-50 rounded-sm border border-solid border-gray-200 bg-white px-4 py-2"
        *ngIf="!currentReport"
      >
        <div class="flex items-center gap-4">
          <div class="flex gap-4">
            <div class="text-lg font-bold">Preview Custom Report</div>
            <button mat-stroked-button (click)="customReportView.toggle()">
              {{ customReportView.isShown ? 'Hide' : 'Show' }}
            </button>
          </div>
        </div>
      </div>

      <div class="section flex flex-col gap-2" *ngIf="customReportView.isShown">
        <h3>Active Filters</h3>
        <ng-container *ngIf="dynamicFilters$ | async as dynamicFilters">
          <pr-custom-report-filters
            *ngIf="dynamicFilters.length; else noFilters"
            [factTable]="factTable$ | async"
            [filters]="dynamicFilters"
          />

          <ng-template #noFilters>
            <div class="empty-placeholder mat-caption">
              No filters have been selected
            </div>
          </ng-template>
        </ng-container>

        <h3>Charts</h3>

        <ng-container *ngIf="hasCharts$ | async; else noCharts">
          <pr-report-builder-chart-section
            *ngFor="
              let section of chartSections$ | async;
              trackBy: trackByChartSection
            "
            [section]="section"
            (addChartAtIndex)="addChartAtIndex(section, $event.index)"
          />
        </ng-container>
        <ng-template #noCharts>
          <div class="empty-placeholder mat-caption">
            No charts have been added
          </div>
          @if (store.editMode$ | async) {
            <div
              class="border-primary-400 flex h-[312px] w-[294px] cursor-pointer flex-col items-center justify-center self-stretch rounded-lg border-2 border-dashed p-4 opacity-50 hover:opacity-100"
              (click)="addCustomChart()"
            >
              <button mat-button color="primary">
                <mat-icon>add_chart</mat-icon> Add New Chart
              </button>
            </div>
          }
        </ng-template>

        <div *ngIf="!currentReport" class="action-buttons">
          <button
            *prHasPermission="[createCustomReportsPermission]"
            mat-flat-button
            color="primary"
            (click)="store.saveAsCustomReport()"
          >
            Save as Custom Report
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="flex flex-col gap-4">
    <ng-container
      *ngTemplateOutlet="currentReport ? previewCustomReport : availableCharts"
    />
    <ng-container
      *ngTemplateOutlet="currentReport ? availableCharts : previewCustomReport"
    />
  </div>
</ng-container>
