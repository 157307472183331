import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  type CalendarEventEntity,
  CalendarEventsFacade,
} from '@principle-theorem/ng-calendar/store';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { CalendarUnit } from '@principle-theorem/principle-core/interfaces';
import {
  DAY_OF_WEEK_FORMAT,
  getCurrentTime,
  ISO_DATE_FORMAT,
} from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

interface IWeekDayHeaderData {
  day: Moment;
  dayOfWeek: string;
  dayOfMonth: number;
  isToday: boolean;
  isPast: boolean;
  queryParams: Record<string, string>;
}

@Component({
    selector: 'pr-week-day-header',
    templateUrl: './week-day-header.component.html',
    styleUrls: ['./week-day-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WeekDayHeaderComponent {
  trackByEvent = TrackByFunctions.ref<CalendarEventEntity>();
  day$ = new ReplaySubject<Moment>(1);
  events$: Observable<CalendarEventEntity[]>;
  weekDayHeader$: Observable<IWeekDayHeaderData>;
  currentTime: Moment = getCurrentTime();

  @Input()
  set day(day: Moment) {
    if (!day) {
      return;
    }
    this.day$.next(day);
  }

  constructor(private _calendarEventsFacade: CalendarEventsFacade) {
    this.events$ = this.day$.pipe(
      switchMap((day) => this._calendarEventsFacade.getDayHeaderEvents$(day))
    );
    this.weekDayHeader$ = this.day$.pipe(
      map((day) => {
        return {
          day,
          dayOfWeek: day.format(DAY_OF_WEEK_FORMAT),
          dayOfMonth: day.date(),
          isToday: day.isSame(this.currentTime, 'day'),
          isPast: day.isBefore(this.currentTime, 'day'),
          queryParams: {
            from: day.format(ISO_DATE_FORMAT),
            to: day.format(ISO_DATE_FORMAT),
            calendarUnit: CalendarUnit.Day,
          },
        };
      })
    );
  }
}
