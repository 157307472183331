<ng-container *ngIf="editable$ | async; else video">
  <pt-drag-resize
    [disabled]="(selected$ | async) === false"
    (resizeUpdate)="updateSize($event)"
    (resizeEnd)="resizeEnd()"
    #resize
  >
    <video
      class="cursor-pointer"
      controls
      controlsList="nodownload"
      oncontextmenu="return false;"
      [attr.storage-path]="storagePath"
      [src]="videoUrl$ | async"
      [style.width]="width"
    ></video>
  </pt-drag-resize>
</ng-container>

<ng-template #video>
  <video
    [attr.storage-path]="storagePath"
    [src]="videoUrl$ | async"
    controls
    controlsList="nodownload"
    oncontextmenu="return false;"
    [style.width]="width"
  ></video>
</ng-template>
