import {
  rand,
  randBoolean,
  randEmail,
  randFloat,
  randJobTitle,
  randPastDate,
  randPhoneNumber,
  randStreetAddress,
  randWord,
} from '@ngneat/falso';
import {
  VersionedSchema,
  initVersionedSchema,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  ContactResourceType,
  IAppointment,
  IContactRef,
  IInteraction,
  ILab,
  ILabJob,
  ILabJobType,
  INote,
  IPatient,
  IStaffer,
  InteractionType,
  LabJobStatus,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  INamedDocument,
  MockGenerator,
  Properties,
  WithRef,
  snapshot,
  toMoment,
  toNamedDocument,
  toTimestamp,
} from '@principle-theorem/shared';
import { MockNamedDocument } from '@principle-theorem/testing';
import * as moment from 'moment-timezone';
import { Appointment } from '../appointment/appointment';
import { LabJob, LabJobType } from '../lab-job/lab-job';
import { Lab } from './lab';
import { stafferToNamedDoc } from '../common';
import { Interaction } from '../interaction/interaction';
import { InteractionMock } from '../interaction/interaction.mock';
import { toMention } from '../mention/mention';
import { NoteMock } from '../note/note.mock';

export class LabMock extends BaseFirestoreMock implements Properties<ILab> {
  get name(): string {
    return randWord();
  }

  get address(): string {
    return randStreetAddress();
  }

  get phone(): string {
    return randPhoneNumber();
  }

  get email(): string {
    return randEmail();
  }

  get labJobTypes(): ILabJobType[] {
    return [
      MockLabJobType(rand(LAB_JOB_TYPES)),
      MockLabJobType(rand(LAB_JOB_TYPES)),
    ];
  }

  get notes(): INote[] {
    return [MockGenerator.generate(NoteMock)];
  }

  get interactions(): IInteraction[] {
    return [MockGenerator.generate(InteractionMock)];
  }

  get parentRef(): IContactRef {
    return {
      type: ContactResourceType.Lab,
      ...MockNamedDocument('Parent Lab'),
    };
  }

  get mobileNumber(): string {
    return randPhoneNumber();
  }

  get jobTitle(): string {
    return randJobTitle();
  }
}

export function MockLabJobInteractions(
  staffer: INamedDocument<IStaffer>,
  lab: INamedDocument<ILab>,
  _: INamedDocument,
  notes?: VersionedSchema[]
): IInteraction[] {
  const lastJobInteractions: Partial<IInteraction>[] = [
    {
      type: InteractionType.LabJob,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` created lab job`),
      ],
      owner: staffer,
      createdAt: toTimestamp(
        moment().subtract({ hours: 5 }).subtract({ minutes: 15 })
      ),
    },
  ];

  const optionalInteractions: Partial<IInteraction>[] = [
    {
      type: InteractionType.Call,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` called `),
        toMentionContent(toMention(lab, MentionResourceType.Lab)),
      ],
      owner: staffer,
      createdAt: toTimestamp(moment().subtract({ hours: 4 })),
      content: initVersionedSchema(
        'Lab needs to confirm with technician if they can travel back in time...'
      ),
    },
    {
      type: InteractionType.Sms,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` sent an sms to `),
        toMentionContent(toMention(lab, MentionResourceType.Lab)),
      ],
      owner: staffer,
      createdAt: toTimestamp(
        moment().subtract({ hours: 3 }).subtract({ minutes: 15 })
      ),
      content: initVersionedSchema(
        'Lab manager wanted an sms confirmation option'
      ),
    },
    {
      type: InteractionType.CallReceived,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` called `),
        toMentionContent(toMention(lab, MentionResourceType.Lab)),
      ],
      owner: staffer,
      createdAt: toTimestamp(moment().subtract({ hours: 2 })),
      content: initVersionedSchema(
        'Talked to some guy, still waiting to hear back'
      ),
    },
    {
      type: InteractionType.Note,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` added note`),
      ],
      owner: staffer,
      createdAt: toTimestamp(moment().subtract({ minutes: 56 })),
      content: initVersionedSchema('Highly likely that this job is done.'),
    },
    {
      type: InteractionType.Email,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` sent email to `),
        toMentionContent(toMention(lab, MentionResourceType.Lab)),
      ],
      owner: staffer,
      createdAt: toTimestamp(moment().subtract({ minutes: 40 })),
      content: initVersionedSchema('Sent an email follow up'),
    },
    {
      type: InteractionType.CallReceived,
      title: [
        toMentionContent(toMention(lab, MentionResourceType.Lab)),
        toTextContent(` called`),
      ],
      owner: staffer,
      createdAt: toTimestamp(moment().subtract({ minutes: 10 })),
      content: initVersionedSchema('Lab called to say its on the way'),
    },
  ];

  lastJobInteractions.push(...optionalInteractions.filter(() => randBoolean()));

  if (notes && notes.length) {
    notes.forEach((content) => {
      lastJobInteractions.push({
        type: InteractionType.Note,

        title: [
          toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
          toTextContent(` added comment`),
        ],
        owner: staffer,
        createdAt: toTimestamp(),
        content,
      });
    });
  }

  return lastJobInteractions.map((interaction) =>
    Interaction.init(interaction)
  );
}

export class LabJobMocker {
  protected _staff: WithRef<IStaffer>[];
  protected _labs: WithRef<ILab>[];
  protected _lab: WithRef<ILab>;
  protected _patients: WithRef<IPatient>[];
  protected _patient: WithRef<IPatient>;

  public creator: INamedDocument<IStaffer>;

  defaults: Partial<ILabJob> = {
    title: [],
    description: initVersionedSchema(),
    status: LabJobStatus.Sending,

    lab: undefined,
    type: undefined,
    cost: 0,

    patient: undefined,
    appointment: undefined,

    interactions: [],

    dueDate: toTimestamp(),
    completedDate: undefined,
    createdAt: toTimestamp(),
    updatedAt: toTimestamp(),
    deleted: false,
  };

  constructor(
    staff: WithRef<IStaffer>[],
    labs: WithRef<ILab>[],
    patients: WithRef<IPatient>[]
  ) {
    this._staff = staff;
    this._labs = labs;
    this._patients = patients;

    const randomLab: WithRef<ILab> = rand(this._labs);
    this._lab = randomLab;

    const randomLabJobType: ILabJobType = rand(randomLab.labJobTypes);

    const randomPatient: WithRef<IPatient> = rand(this._patients);
    this._patient = randomPatient;

    const owningStaffer: WithRef<IStaffer> = rand(this._staff);
    this.creator = stafferToNamedDoc(owningStaffer);

    this.defaults.lab = toNamedDocument(randomLab);
    this.defaults.type = randomLabJobType;
    this.defaults.cost = randomLabJobType.cost;
    this.defaults.patient = toNamedDocument(randomPatient);
    this.defaults.interactions = this.addInteractions();
  }

  getMock(): ILabJob {
    return {} as ILabJob;
  }

  async generate(): Promise<ILabJob> {
    const appointment = await this.getLabJobAppointment(this._patient);

    if (appointment) {
      this.defaults.appointment = {
        treatmentPlanName: appointment.treatmentPlan.name,
        ref: appointment.ref,
      };

      const from = appointment.event?.from;
      this.defaults.dueDate = toTimestamp(
        toMoment(from ?? moment()).subtract(1, 'day')
      );
    }

    const labJob: ILabJob = LabJob.init({
      ...this.defaults,
      ...this.getMock(),
    });
    labJob.title = await LabJob.generateTitle(labJob);
    return labJob;
  }

  addInteractions(interactions: VersionedSchema[] = []): IInteraction[] {
    return MockLabJobInteractions(
      this.creator,
      this._lab,
      this._patient,
      interactions
    );
  }

  async getLabJobAppointment(
    patient: WithRef<IPatient>
  ): Promise<WithRef<IAppointment> | undefined> {
    const appointments = await snapshot(Appointment.all$(patient));
    const appointmentsWithEvents = appointments.filter((appointment) =>
      appointment.event ? true : false
    );

    return rand(appointmentsWithEvents);
  }
}

export function MockLabJobs(
  staff: WithRef<IStaffer>[],
  labs: WithRef<ILab>[],
  patients: WithRef<IPatient>[]
): LabJobMocker[] {
  return Array.from(Array(12)).map(
    () => new LabJobMocker(staff, labs, patients)
  );
}

export const LAB_JOB_TYPES: string[] = [
  'Zirconia Crown',
  'Bite Block',
  'Splint',
];

export function MockLabJobType(
  name: string = rand(LAB_JOB_TYPES)
): ILabJobType {
  return LabJobType.init({
    name,
    cost: randFloat(),
    createdAt: toTimestamp(randPastDate()),
  });
}

export function MockLab(data?: Partial<ILab>): ILab {
  const lab: ILab = Lab.init(data);
  lab.labJobTypes = LAB_JOB_TYPES.map((name: string) => MockLabJobType(name));
  return lab;
}

export const LABS: ILab[] = [
  {
    name: 'Lab Radoor',
    address: '123 Fake St',
    phone: randPhoneNumber(),
    email: randEmail(),
  },
  {
    name: 'Lab Ho',
    address: '456 Fake St',
    phone: randPhoneNumber(),
    email: randEmail(),
  },
  {
    name: 'The Whole Tooth and Nothing but the Tooth',
    address: '666 Fake St',
    phone: randPhoneNumber(),
    email: randEmail(),
  },
  {
    name: 'Lab Coates',
    address: '789 Electric Ave',
    phone: randPhoneNumber(),
    email: randEmail(),
  },
].map((lab: Partial<ILab>) => MockLab(lab));
