import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { AbstractResolver } from '@principle-theorem/ng-shared';
import { TreatmentConfiguration } from '@principle-theorem/principle-core';
import { type ITreatmentConfiguration } from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, getDoc$ } from '@principle-theorem/shared';
import { merge, type Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

@Injectable()
export class TreatmentConfigResolverService extends AbstractResolver<ITreatmentConfiguration> {
  constructor(
    router: Router,
    private _organisation: OrganisationService,
    private _currentScopeFacade: CurrentScopeFacade
  ) {
    super(router);
  }

  getItemById(uid: string): Observable<ITreatmentConfiguration> {
    const brandTreatment$ = this._currentScopeFacade.currentBrand$.pipe(
      filterUndefined(),
      switchMap((brand) => getDoc$(TreatmentConfiguration.col(brand), uid)),
      catchError(() => of(undefined)),
      filterUndefined()
    );

    const stafferTreatment$ = this._organisation.staffer$.pipe(
      filterUndefined(),
      switchMap((staffer) => getDoc$(TreatmentConfiguration.col(staffer), uid)),
      catchError(() => of(undefined)),
      filterUndefined()
    );

    return merge(brandTreatment$, stafferTreatment$).pipe(take(1));
  }
}
