import { NgModule } from '@angular/core';
import { ConditionConfigEditComponent } from './condition-config-edit/condition-config-edit.component';
import { ConditionConfigListComponent } from './condition-config-list/condition-config-list.component';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ConditionConfigResolverService } from './condition-config-resolver.service';
import { ComponentsModule } from '../../components/components.module';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';

@NgModule({
  imports: [NgSharedModule, ComponentsModule, NgFeatureFlagsModule],
  declarations: [ConditionConfigEditComponent, ConditionConfigListComponent],
  providers: [ConditionConfigResolverService],
})
export class ConditionConfigModule {}
