import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  CurrentScopeFacade,
  TimezoneService,
} from '@principle-theorem/ng-principle-shared';
import {
  Gap,
  type OptionFinderQuery,
  practiceStaffAvailableTimes,
} from '@principle-theorem/principle-core';
import {
  GAP_DAYS_INCREMENT,
  type IGap,
} from '@principle-theorem/principle-core/interfaces';
import { filterUndefined } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { combineLatest, type Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-gaps',
  templateUrl: './gaps.component.html',
  styleUrls: ['./gaps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GapsComponent {
  gaps$: Observable<IGap[]>;

  constructor(
    private _currentScope: CurrentScopeFacade,
    private _timezone: TimezoneService
  ) {
    this.gaps$ = combineLatest([
      this._currentScope.currentPractice$.pipe(filterUndefined()),
      this._currentScope.currentBrand$.pipe(filterUndefined()),
      this._timezone.currentPractice.timezone$,
    ]).pipe(
      switchMap(([practice, brand, timezone]) => {
        const query: OptionFinderQuery = [
          moment.tz(timezone).startOf('day'),
          moment.tz(timezone).add(GAP_DAYS_INCREMENT, 'day').endOf('day'),
        ];
        return of(query).pipe(practiceStaffAvailableTimes(practice, brand));
      }),
      map((timePeriods) =>
        timePeriods.map((timePeriod) => Gap.fromEventTimePeriod(timePeriod))
      )
    );
  }
}
