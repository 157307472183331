import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DYNAMIC_COMPONENT_DATA } from '@principle-theorem/ng-shared';
import { IIntegrationActionData } from '../../../../integration-action-data';
import { VistaSoftFeatureService } from '../vistasoft-feature.service';
import { VISTASOFT_INTEGRATION } from '@principle-theorem/principle-bridge-core';
import { BridgeDeviceSelectorService } from '../../../bridge-device-selector-dialog/bridge-device-selector.service';

@Component({
  selector: 'pr-vistasoft-open-patient',
  templateUrl: './vistasoft-open-patient.component.html',
  styleUrl: './vistasoft-open-patient.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VistaSoftOpenPatientComponent {
  constructor(
    @Inject(DYNAMIC_COMPONENT_DATA)
    private _data: IIntegrationActionData,
    private _vistaSoft: VistaSoftFeatureService,
    private _bridgeDeviceSelector: BridgeDeviceSelectorService
  ) {}

  async openPatient(): Promise<void> {
    const deviceRef = await this._bridgeDeviceSelector.selectDevice(
      VISTASOFT_INTEGRATION
    );
    if (!deviceRef) {
      return;
    }
    await this._vistaSoft.openPatient(this._data.patient, deviceRef);
  }
}
