<mat-toolbar color="accent">
  <span fxFlex
    >Manage {{ data.staffer.user.name }}'s roster for
    {{ data.day | moment | amDateFormat : dateFormat }}</span
  >
  <div>
    <button mat-flat-button color="primary" (click)="add()">Add</button>
  </div>
</mat-toolbar>

<mat-dialog-content>
  <ng-container *ngrxLet="data.events$ as events">
    <div *ngIf="events.length; else noEvents">
      <div
        class="!flex !justify-between !justify-items-start !gap-2"
        *ngFor="let event of events; trackBy: trackByEvent"
      >
        <pr-edit-staffer-roster-day-line-item
          #form
          [from]="event.event | pairMap : 'from' : eventTime | async"
          [to]="event.event | pairMap : 'to' : eventTime | async"
          (submitted)="updateEvent(event, form.form)"
        />

        <div class="!flex">
          <button
            mat-icon-button
            color="primary"
            [disabled]="form.form | map : isDisabled$ | async"
            (click)="updateEvent(event, form.form)"
          >
            <mat-icon>save</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="delete(event)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <ng-template #noEvents>
      <pr-empty-state image="list" title="rosters for day" />
    </ng-template>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
