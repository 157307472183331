import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { MentionActionResolverService } from './mention-action-resolver.service';
import { MentionActionsComponent } from './mention-actions/mention-actions.component';
import { MentionButtonsComponent } from './mention-buttons/mention-buttons.component';
import { MentionResourceComponent } from './mention-resource/mention-resource.component';
import { MentionToolbarComponent } from './mention-toolbar/mention-toolbar.component';

@NgModule({
  imports: [CommonModule, NgPrincipleSharedModule],
  declarations: [
    MentionResourceComponent,
    MentionActionsComponent,
    MentionToolbarComponent,
    MentionButtonsComponent,
  ],
  exports: [
    MentionResourceComponent,
    MentionActionsComponent,
    MentionToolbarComponent,
    MentionButtonsComponent,
  ],
  providers: [MentionActionResolverService],
})
export class MentionModule {}
