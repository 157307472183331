<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="wrapper flex flex-col gap-8">
  <div
    class="m-0 box-border flex w-full flex-col gap-4 overflow-auto rounded-md bg-white p-4 shadow-md shadow-slate-500/10"
  >
    <div class="flex justify-between">
      <h2 class="!m-0 !font-bold">Rescheduling & Cancellation Reasons</h2>
      <button
        mat-flat-button
        color="primary"
        (click)="schedulingReason.create()"
      >
        Create Reason
      </button>
    </div>
    <pr-information-box>
      Create custom Cancellation and Reschedule 'reasons'. Further specify
      whether a reason is used for rescheduling and/or cancelling appointments,
      and optionally set defaults for when you'd like the reason to display.
    </pr-information-box>

    @if (activeCancellationReasons$ | async; as activeCancellationReasons) {
      <div
        cdkDropList
        [cdkDropListData]="activeCancellationReasons"
        class="flex flex-col gap-1"
        (cdkDropListDropped)="drop($event)"
      >
        @for (reason of activeCancellationReasons; track reason.ref.path) {
          <div
            class="flex flex-row items-center gap-4 rounded bg-slate-100"
            cdkDrag
            [cdkDragData]="reason"
            cdkDropListLockAxis="y"
          >
            <div
              class="flex cursor-grab items-center p-2 align-middle"
              cdkDragHandle
            >
              <mat-icon>drag_handle</mat-icon>
            </div>

            <div class="flex w-48 font-bold">{{ reason.name }}</div>
            <div class="flex w-48">
              {{ reason.eventTypes | map: eventTypeDisplay }}
            </div>
            <div class="flex flex-auto">
              {{ reason | map: conditionsDisplay }}
            </div>

            @if (!reason.isSystemReason) {
              <button mat-icon-button [matMenuTriggerFor]="moreMenu">
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #moreMenu="matMenu">
                <button mat-menu-item (click)="schedulingReason.edit(reason)">
                  Edit
                </button>
                <button
                  mat-menu-item
                  color="warn"
                  (click)="schedulingReason.delete(reason)"
                >
                  Delete
                </button>
              </mat-menu>
            } @else {
              <div
                matTooltip="This is a system reason and cannot be edited or deleted"
              >
                <button mat-icon-button [disabled]="true">
                  <mat-icon>lock</mat-icon>
                </button>
              </div>
            }
          </div>
        } @empty {
          <pr-empty-state
            image="list"
            title="rescheduling & cancellation reasons"
          />
        }
      </div>
    }

    @if (deletedCancellationReasons$ | async; as deletedCancellationReasons) {
      @if (deletedCancellationReasons.length) {
        <div>
          <div
            class="flex cursor-pointer flex-row items-center gap-1"
            (click)="deletedContent.toggle()"
          >
            <div>Deleted Rescheduling and Cancellation Reasons</div>
            <mat-icon>
              {{ deletedContent.expanded ? 'expand_less' : 'expand_more' }}
            </mat-icon>
          </div>

          <pt-collapsible #deletedContent>
            <ng-template ptCollapsibleContent>
              <div class="flex flex-col gap-1">
                @for (
                  reason of deletedCancellationReasons;
                  track reason.ref.path
                ) {
                  <div
                    class="flex flex-row items-center gap-4 rounded bg-slate-100 pl-2"
                  >
                    <div class="flex flex-auto">{{ reason.name }}</div>

                    <button
                      mat-icon-button
                      (click)="schedulingReason.restore(reason)"
                      matTooltip="Restore"
                    >
                      <mat-icon>restore</mat-icon>
                    </button>
                  </div>
                }
              </div>
            </ng-template>
          </pt-collapsible>
        </div>
      }
    }
  </div>
</div>
