import { MixedSchema, recursiveReplace } from '@principle-theorem/editor';
import { ITemplateContext } from '@principle-theorem/principle-core/interfaces';
import { cloneDeep, get, isString, trim } from 'lodash';
import { mustache } from './mustache';

/**
 * Populate a template with the given context
 */
export function compileTemplate(
  template: string,
  context: ITemplateContext
): string {
  const safeTemplate = sanitiseTemplate(template);
  try {
    return mustache.render(safeTemplate, context, undefined, {
      escape: (text) => text as string,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
    throw e;
  }
}

/**
 * Remove htmlEntities within mustache variables.
 */
export function sanitiseTemplate(unsafe: string): string {
  const varRegex = new RegExp(/\{{(.*?)\}}/g);
  const htmlEntityRegex = new RegExp(/(&.+;)/g);
  return unsafe.replace(varRegex, (match) => {
    const varName = trim(match, '{}').replace(htmlEntityRegex, '').trim();
    return `{{ ${varName} }}`;
  });
}

/**
 * Populate a template with the given context
 */
export function compileTemplateSchema(
  template: MixedSchema,
  context: ITemplateContext
): MixedSchema {
  return recursiveReplace(cloneDeep(template), (schemaItem) => {
    const replacementKeys = ['text', 'href'];

    return replacementKeys.reduce(
      (data, key) => replaceProperty(data, key, context),
      schemaItem
    );
  }) as MixedSchema;
}

function replaceProperty<T>(
  data: object,
  key: keyof T,
  context: ITemplateContext
): object {
  const text: unknown = get(data, key);
  if (!text || !isString(text)) {
    return data;
  }
  return {
    ...data,
    [key]: compileTemplate(text, context),
  };
}
