import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  PositionStrategy,
  type ComponentType,
} from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { snapshot } from '@principle-theorem/shared';
import {
  AlertDialogComponent,
  type IAlertDialogInput,
} from './alert-dialog/alert-dialog.component';
import { BreakpointService } from './breakpoint.service';
import {
  ConfirmDialogComponent,
  type IConfirmationDialogInput,
} from './confirm-dialog/confirm-dialog.component';
import { DialogPresets } from './dialog-presets';
import {
  SelectDialogComponent,
  type ISelectDialogInput,
} from './select-dialog/select-dialog.component';
import {
  SelectListDialogComponent,
  type ISelectListDialogInput,
} from './select-list-dialog/select-list-dialog.component';
import {
  ITextPromptDialogInput,
  TextPromptDialogComponent,
} from './text-prompt-dialog/text-prompt-dialog.component';

@Injectable()
export class BasicDialogService {
  constructor(
    private _dialog: MatDialog,
    private _breakpoint: BreakpointService,
    private _cdkDialog: Dialog,
    private _overlay: Overlay
  ) {}

  open<T, D = unknown, R = unknown>(
    component: ComponentType<T>,
    config?: MatDialogConfig<D>
  ): MatDialogRef<T, R> {
    return this._dialog.open<T, D, R>(component, config);
  }

  confirm(data: IConfirmationDialogInput): Promise<boolean | undefined> {
    return this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small<IConfirmationDialogInput>({ data })
      )
      .afterClosed()
      .toPromise();
  }

  alert(data: IAlertDialogInput): Promise<undefined> {
    return this._dialog
      .open<AlertDialogComponent, IAlertDialogInput, undefined>(
        AlertDialogComponent,
        DialogPresets.small<IAlertDialogInput>({ data })
      )
      .afterClosed()
      .toPromise();
  }

  prompt(data: ITextPromptDialogInput): Promise<string | undefined> {
    return this._dialog
      .open<TextPromptDialogComponent, ITextPromptDialogInput, string>(
        TextPromptDialogComponent,
        DialogPresets.small<ITextPromptDialogInput>({ data })
      )
      .afterClosed()
      .toPromise();
  }

  select<T>(data: ISelectDialogInput<T>): Promise<T | undefined> {
    return this._dialog
      .open<SelectDialogComponent, ISelectDialogInput<T>, T | undefined>(
        SelectDialogComponent,
        DialogPresets.small<ISelectDialogInput<T>>({ data })
      )
      .afterClosed()
      .toPromise();
  }

  selectList<T>(data: ISelectListDialogInput<T>): Promise<T | undefined> {
    return this._dialog
      .open<
        SelectListDialogComponent,
        ISelectListDialogInput<T>,
        T | undefined
      >(
        SelectListDialogComponent,
        DialogPresets.small<ISelectListDialogInput<T>>({ data })
      )
      .afterClosed()
      .toPromise();
  }

  async mobileFullscreen<Component, Data, ReturnValue>(
    component: ComponentType<Component>,
    config?: MatDialogConfig<Data>,
    dialogFn?: (dialogRef: MatDialogRef<Component>) => void
  ): Promise<ReturnValue | undefined> {
    const isMobile = await snapshot(this._breakpoint.isMobile$);
    if (isMobile) {
      config = DialogPresets.fullscreen(config);
    }
    const dialogRef = this._dialog.open<Component, Data, ReturnValue>(
      component,
      config
    );

    dialogFn?.(dialogRef);

    return dialogRef.afterClosed().toPromise();
  }

  connected<Data, ReturnValue, Component>(
    component: ComponentType<Component>,
    config?: ConnectedDialogConfig<Data, ReturnValue, Component>
  ): DialogRef<ReturnValue, Component> {
    return this._cdkDialog.open<ReturnValue, Data, Component>(
      component,
      this._getDialogConfig(config)
    );
  }

  private _getDialogConfig<Data, ReturnValue, Component>(
    config?: ConnectedDialogConfig<Data, ReturnValue, Component>
  ): DialogConfig<Data, DialogRef<ReturnValue, Component>> {
    const positionStrategy = this._getOverlayPosition(
      config?.connectedTo,
      config?.positions
    );

    return {
      positionStrategy,
      panelClass: 'pt-connected-dialog-container',
      ...config,
    } as DialogConfig<Data, DialogRef<ReturnValue, Component>>;
  }

  private _getOverlayPosition(
    connectedTo?: FlexibleConnectedPositionStrategyOrigin,
    positions?: ConnectedPosition[]
  ): PositionStrategy {
    if (!connectedTo) {
      return this._overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically();
    }
    return this._overlay
      .position()
      .flexibleConnectedTo(connectedTo)
      .withFlexibleDimensions(false)
      .withPush(false)
      .withPositions(positions || DEFAULT_POSITIONS);
  }
}

const DEFAULT_POSITIONS: ConnectedPosition[] = [
  {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
  },
  {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',

    // The overlay edge connected to the trigger should have squared corners, while
    // the opposite end has rounded corners. We apply a CSS class to swap the
    // border-radius based on the overlay position.
    panelClass: 'mat-autocomplete-panel-above',
  },
];

export class ConnectedDialogConfig<
  Data = unknown,
  ReturnValue = unknown,
  Component = unknown
> extends DialogConfig<Data, DialogRef<ReturnValue, Component>> {
  /**
   * The ElementRef that the dialog will be positioned against
   */
  connectedTo?: FlexibleConnectedPositionStrategyOrigin;

  /**
   * Use these layout positions for the dialog
   */
  positions?: ConnectedPosition[];
}
