<mat-form-field subscriptSizing="dynamic">
  <mat-label>Select Teeth</mat-label>
  <mat-select
    multiple
    [formControl]="toothSelectCtrl"
    [compareWith]="compareFn"
  >
    <mat-checkbox
      class="w-full p-2"
      [checked]="allTeethSelected()"
      [indeterminate]="someTeethSelected() && !allTeethSelected()"
      (change)="toggleAllSelection($event.checked)"
    >
      Select All
    </mat-checkbox>
    @for (tooth of teethOptions(); track $index) {
      <mat-option [value]="tooth">
        {{ tooth.toothRef.quadrant }}{{ tooth.toothRef.quadrantIndex }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
