import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Brand } from '@principle-theorem/principle-core';
import {
  IBrand,
  ISchedulingEventReason,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

interface ISchedulingEventReasonsState {
  brand: WithRef<IBrand>;
  reasons: WithRef<ISchedulingEventReason>[];
}

@Injectable()
export class SchedulingEventReasonStore extends ComponentStore<ISchedulingEventReasonsState> {
  readonly brand$ = this.select((store) => store.brand);
  readonly reasons$ = this.select((store) => store.reasons);

  readonly loadBrand = this.effect((brand$: Observable<WithRef<IBrand>>) => {
    return brand$.pipe(
      tap((brand) => {
        this.setState({ brand, reasons: [] });
        this.loadReasons(Brand.cancellationReasons$(brand, true));
      })
    );
  });

  readonly loadReasons = this.effect(
    (reasons$: Observable<WithRef<ISchedulingEventReason>[]>) =>
      reasons$.pipe(tap((reasons) => this.patchState({ reasons: reasons })))
  );
}
