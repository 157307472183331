import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  inject,
  Output,
} from '@angular/core';
import { ProfileImageService } from '@principle-theorem/ng-shared';
import { type IStaffer } from '@principle-theorem/principle-core/interfaces';
import { isPathChanged$, type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, type Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GlobalStoreService } from '../../../store/global-store.service';

@Component({
  selector: 'pr-selected-staffer',
  templateUrl: './selected-staffer.component.html',
  styleUrls: ['./selected-staffer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedStafferComponent {
  profileImage = inject(ProfileImageService);
  staffer$ = new ReplaySubject<WithRef<IStaffer>>(1);
  profileImage$: Observable<string | undefined>;
  stafferIsHovered$ = new BehaviorSubject<boolean>(false);
  @Output() clearStaffer = new EventEmitter<WithRef<IStaffer>>();

  @Input()
  set staffer(staffer: WithRef<IStaffer>) {
    if (staffer) {
      this.staffer$.next(staffer);
    }
  }

  constructor(private _global: GlobalStoreService) {
    this.profileImage$ = this.staffer$.pipe(
      isPathChanged$('ref.path'),
      switchMap((staffer) => this._global.getStafferImage$(staffer))
    );
  }
}
