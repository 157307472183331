import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { type RawInlineNodes } from '@principle-theorem/editor';
import { Interaction } from '@principle-theorem/principle-core';
import {
  ISchedulingEvent,
  InteractionType,
  type IInteraction,
  type IInteractionV2,
} from '@principle-theorem/principle-core/interfaces';
import {
  HISTORY_DATE_TIME_FORMAT,
  type WithRef,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  type Observable,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-interaction-summary',
    templateUrl: './interaction-summary.component.html',
    styleUrls: ['./interaction-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InteractionSummaryComponent {
  readonly dateFormat = HISTORY_DATE_TIME_FORMAT;
  interaction$ = new ReplaySubject<IInteraction | WithRef<IInteractionV2>>(1);
  isCompact$ = new BehaviorSubject<boolean>(false);
  editable$ = new BehaviorSubject<boolean>(false);
  canEdit$: Observable<boolean>;
  title$: Observable<RawInlineNodes>;
  pinnableTypes$ = new BehaviorSubject<InteractionType[]>([
    InteractionType.Note,
    InteractionType.Call,
  ]);
  schedulingEvent$: Observable<WithRef<ISchedulingEvent> | undefined>;
  @Input() hideIcon: boolean = false;

  @Input()
  set pinnableTypes(pinnableTypes: InteractionType[]) {
    if (pinnableTypes) {
      this.pinnableTypes$.next(pinnableTypes);
    }
  }

  @Output() interactionDeleted = new EventEmitter<void>();
  @Output() interactionUpdated = new EventEmitter<
    Partial<IInteraction | WithRef<IInteractionV2>>
  >();

  @Input()
  set interaction(interaction: IInteraction | WithRef<IInteractionV2>) {
    if (interaction) {
      this.interaction$.next(interaction);
    }
  }

  @Input()
  set editable(editable: BooleanInput) {
    this.editable$.next(coerceBooleanProperty(editable));
  }

  @Input()
  set compact(compact: BooleanInput) {
    this.isCompact$.next(coerceBooleanProperty(compact));
  }

  constructor() {
    this.title$ = this.interaction$.pipe(
      map((interaction) => Interaction.getTitle(interaction))
    );

    this.canEdit$ = combineLatest([this.editable$, this.interaction$]).pipe(
      map(
        ([editEnabled, interaction]) =>
          editEnabled && Interaction.canEdit(interaction)
      )
    );

    this.schedulingEvent$ = this.interaction$.pipe(
      switchMap((interaction) => Interaction.getSchedulingEvent$(interaction))
    );
  }

  getInteractionTypeIcon(interaction: IInteraction): string {
    return Interaction.getInteractionTypeIcon(interaction);
  }

  getInteractionTypeColourClass(interaction: IInteraction): string {
    return Interaction.getInteractionTypeColourClass(interaction);
  }
}
