import { InjectionToken } from '@angular/core';
import { type IFeature } from '@principle-theorem/feature-flags';

export interface INgFeatureFlagsConfig {
  features: IFeature[];
  production: boolean;
}

export const NG_FEATURE_FLAGS_CONFIG: InjectionToken<INgFeatureFlagsConfig> =
  new InjectionToken('INgFeatureFlagsConfig');
