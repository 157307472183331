import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  ReplaySubject,
} from 'rxjs';
import { map } from 'rxjs/operators';

interface IPackageSummary {
  uid: string;
  name: string;
}

@Component({
  selector: 'pr-treatment-package-menu',
  templateUrl: './treatment-package-menu.component.html',
  styleUrls: ['./treatment-package-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentPackageMenuComponent {
  trackByPackageId = TrackByFunctions.uniqueId();
  packages$ = new ReplaySubject<IPackageSummary[]>(1);
  currentPackageId$ = new BehaviorSubject<string | undefined>(undefined);
  currentPackageName$: Observable<string>;
  @Output() packageSelected = new EventEmitter<string>();

  @Input()
  set currentPackageId(currentPackageId: string | undefined) {
    this.currentPackageId$.next(currentPackageId);
  }

  @Input()
  set packages(packages: IPackageSummary[]) {
    if (packages) {
      this.packages$.next(packages);
    }
  }

  constructor() {
    this.currentPackageName$ = combineLatest([
      this.currentPackageId$,
      this.packages$,
    ]).pipe(
      map(([currentPackageId, packages]) => {
        if (!currentPackageId) {
          return 'Set Package';
        }

        const found = packages.find(
          (treatmentPackage) => treatmentPackage.uid === currentPackageId
        )?.name;
        if (!found) {
          return 'Set Package';
        }

        return `Package: ${found}`;
      })
    );
  }
}
