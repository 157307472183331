import { Component, ChangeDetectionStrategy } from '@angular/core';
import { type ComponentType } from '@angular/cdk/portal';
import { type IWorkspaceSelector } from '@principle-theorem/ng-auth';
import { OrganisationWorkspaceSelectorComponent } from '../../../components/organisation-workspace-selector/organisation-workspace-selector.component';

@Component({
    selector: 'pr-workspaces',
    templateUrl: './workspaces.component.html',
    styleUrls: ['./workspaces.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WorkspacesComponent {
  workspaceSelectors: ComponentType<IWorkspaceSelector>[] = [
    OrganisationWorkspaceSelectorComponent,
  ];
}
