import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { SystemTemplateSelectorsComponent } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import {
  IResolvedTemplateWithName,
  ITemplateDefinitionWithName,
} from '@principle-theorem/principle-core/interfaces';
import { compact } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'pr-print-variant-selector-dialog',
    templateUrl: './print-variant-selector-dialog.component.html',
    styleUrls: ['./print-variant-selector-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        NgMaterialModule,
        NgSharedModule,
        SystemTemplateSelectorsComponent,
    ]
})
export class PrintVariantSelectorDialogComponent {
  selectedTemplates$ = new BehaviorSubject<IResolvedTemplateWithName[]>([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ITemplateDefinitionWithName[],
    private _dialogRef: MatDialogRef<
      PrintVariantSelectorDialogComponent,
      IResolvedTemplateWithName[]
    >
  ) {}

  close(): void {
    this._dialogRef.close();
  }

  print(): void {
    this._dialogRef.close(compact(this.selectedTemplates$.value));
  }
}
