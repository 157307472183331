import {
  ApplicationRef,
  ChangeDetectorRef,
  Injector,
  Type,
} from '@angular/core';
import {
  DecorationWithType,
  Editor,
  NodeView,
  NodeViewProps,
  NodeViewRendererOptions,
  NodeViewRendererProps,
  isiOS,
} from '@tiptap/core';
import type { NodeSpec, Node as ProseMirrorNode } from '@tiptap/pm/model';
import { EditorState, NodeSelection, Transaction } from '@tiptap/pm/state';
import type { Decoration } from '@tiptap/pm/view';
import { isUndefined, set } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { AngularRenderer } from './angular-renderer';
import { detectNodeIsSelected } from './component-wrapper/selection';
import { IEditorNodeComponent } from './components/editor-node.component';
import { filter, takeUntil } from 'rxjs/operators';

export interface IEditorTransaction {
  transactions: readonly Transaction[];
  oldState: EditorState;
  newState: EditorState;
}

interface IRendererUpdateProps {
  oldNode: ProseMirrorNode;
  oldDecorations: Decoration[];
  newNode: ProseMirrorNode;
  newDecorations: Decoration[];
  updateProps: () => void;
}

export interface IAngularNodeViewRendererOptions
  extends NodeViewRendererOptions {
  update?: ((props: IRendererUpdateProps) => boolean) | null;
  injector: Injector;
  applicationRef: ApplicationRef;
  transaction$: Observable<IEditorTransaction>;
}

export class AngularNodeView extends NodeView<
  Type<IEditorNodeComponent>,
  Editor,
  IAngularNodeViewRendererOptions
> {
  private _onDestroy$ = new Subject<void>();
  renderer: AngularRenderer<IEditorNodeComponent, NodeViewProps>;
  contentDOMElement!: HTMLElement | null;

  constructor(
    component: Type<IEditorNodeComponent>,
    props: NodeViewRendererProps,
    private _event$: Observable<Event>,
    private _schema: NodeSpec,
    transaction$: Observable<IEditorTransaction>,
    options?: Partial<IAngularNodeViewRendererOptions>,
    private _initialData?: object
  ) {
    super(component, props, { ...options, transaction$ });
    this.bootstrap();
  }

  bootstrap(): void {
    const props: NodeViewProps = {
      editor: this.editor,
      node: this.node,
      decorations: this.decorations,
      selected: false,
      extension: this.extension,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      getPos: () => this.getPos() as number,
      updateAttributes: (attributes = {}) => this.updateAttributes(attributes),
      deleteNode: () => this.deleteNode(),
    };

    this.handleSelectionUpdate = this.handleSelectionUpdate.bind(this);
    this.editor.on('selectionUpdate', this.handleSelectionUpdate);

    // create renderer
    this.renderer = new AngularRenderer(
      this.component,
      this.options.applicationRef,
      this._event$,
      props,
      this._initialData
    );

    this.renderer.instance.editor = this.editor;
    this.renderer.instance.node = this.node;
    this.renderer.instance.schema = this._schema;
    this._registerInstanceUpdateHandler(
      this.renderer.instance,
      this.getPos as (() => number) | boolean
    );

    for (const key in this.node.attrs) {
      if (this.node.attrs[key] !== undefined) {
        set(this.renderer.instance, key, this.node.attrs[key]);
      }
    }
    this.renderer.componentRef.injector.get(ChangeDetectorRef).markForCheck();

    // Register drag handler
    if (this.extension.config.draggable) {
      this.renderer.elementRef.nativeElement.ondragstart = (
        event: DragEvent
      ) => {
        this.onDragStart(event);
      };
    }

    this.contentDOMElement = this.node.isLeaf
      ? // eslint-disable-next-line no-null/no-null
        null
      : document.createElement(this.node.isInline ? 'span' : 'div');

    if (this.contentDOMElement) {
      // For some reason the whiteSpace prop is not inherited properly in Chrome and Safari
      // With this fix it seems to work fine
      // See: https://github.com/ueberdosis/tiptap/issues/1197
      this.contentDOMElement.style.whiteSpace = 'inherit';

      // Required for editable node views
      // The content won't be rendered if `editable` is set to `false`
      this.renderer.detectChanges();
    }

    this._appendContendDom();
  }

  override get dom(): HTMLElement {
    return this.renderer?.dom;
  }

  override get contentDOM(): HTMLElement | null {
    if (this.node.isLeaf) {
      // eslint-disable-next-line no-null/no-null
      return null;
    }

    return this.contentDOMElement;
  }

  handleSelectionUpdate(): void {
    const selected = detectNodeIsSelected(
      this.renderer.instance,
      this.dom.ownerDocument,
      this.editor.state,
      this.node,
      this.getPos as (() => number) | boolean
    );

    if (selected) {
      this.selectNode();
      return;
    }

    this.deselectNode();
  }

  handleTransaction(data: IEditorTransaction): void {
    const selected = detectNodeIsSelected(
      this.renderer.instance,
      this.dom.ownerDocument,
      data.newState,
      this.node,
      this.getPos as (() => number) | boolean
    );

    if (selected) {
      this.selectNode();
      return;
    }

    this.deselectNode();
  }

  update(node: ProseMirrorNode, decorations: DecorationWithType[]): boolean {
    const updateProps = (): void => {
      this.renderer.updateProps({ node, decorations });
    };

    if (this.options.update) {
      const oldNode = this.node;
      const oldDecorations = this.decorations;

      this.node = node;
      this.decorations = decorations;

      return this.options.update({
        oldNode,
        oldDecorations,
        newNode: node,
        newDecorations: decorations,
        updateProps: () => updateProps(),
      });
    }

    if (node.type !== this.node.type) {
      return false;
    }

    if (node === this.node && this.decorations === decorations) {
      return true;
    }

    this.node = node;
    this.decorations = decorations;
    updateProps();

    return true;
  }

  selectNode(): void {
    this.renderer.updateProps({ selected: true });
  }

  deselectNode(): void {
    this.renderer.updateProps({ selected: false });
  }

  override ignoreMutation(
    mutation: MutationRecord | { type: 'selection'; target: Element }
  ): boolean {
    if (!this.dom || !this.contentDOM) {
      return true;
    }

    if (typeof this.options.ignoreMutation === 'function') {
      return this.options.ignoreMutation({ mutation });
    }

    // a leaf/atom node is like a black box for ProseMirror
    // and should be fully handled by the node view
    if (this.node.isLeaf || this.node.isAtom) {
      return true;
    }

    // ProseMirror should handle any selections
    if (mutation.type === 'selection') {
      return false;
    }

    // try to prevent a bug on iOS and Android that will break node views on enter
    // this is because ProseMirror can’t preventDispatch on enter
    // this will lead to a re-render of the node view on enter
    // see: https://github.com/ueberdosis/tiptap/issues/1214
    // see: https://github.com/ueberdosis/tiptap/issues/2534
    if (
      this.dom.contains(mutation.target) &&
      mutation.type === 'childList' &&
      (isiOS() || isAndroid()) &&
      this.editor.isFocused
    ) {
      const changedNodes = [
        ...Array.from(mutation.addedNodes),
        ...Array.from(mutation.removedNodes),
      ] as HTMLElement[];

      // we’ll check if every changed node is contentEditable
      // to make sure it’s probably mutated by ProseMirror
      if (changedNodes.every((node) => node.isContentEditable)) {
        return false;
      }
    }

    // we will allow mutation contentDOM with attributes
    // so we can for example adding classes within our node view
    if (this.contentDOM === mutation.target && mutation.type === 'attributes') {
      return true;
    }

    // if the this.dom itself was the target
    // do not ignore it. This is important for schema where
    // content: 'inline*' and you end up deleting all the content with backspace
    // PM needs to step in and create an empty node for us.
    // if (mutation.target === this.dom) {
    //   console.log(7);
    //   return false;
    // }

    // ProseMirror should handle any changes within contentDOM
    if (this.contentDOM.contains(mutation.target)) {
      return false;
    }

    return true;
  }

  override stopEvent(event: Event): boolean {
    if (!this.dom) {
      return false;
    }

    if (typeof this.options.stopEvent === 'function') {
      return this.options.stopEvent({ event });
    }

    const target = event.target as HTMLElement;
    const isInElement =
      this.dom.contains(target) && !this.contentDOM?.contains(target);

    // any event from child nodes should be handled by ProseMirror
    if (!isInElement) {
      return false;
    }

    const isDragEvent = event.type.startsWith('drag');
    const isDropEvent = event.type === 'drop';
    const isInput =
      ['INPUT', 'BUTTON', 'SELECT', 'TEXTAREA'].includes(target.tagName) ||
      target.isContentEditable;

    // any input event within node views should be ignored by ProseMirror
    if (isInput && !isDropEvent && !isDragEvent) {
      return true;
    }

    const { isEditable } = this.editor;
    const { isDragging } = this;
    const isDraggable = !!this.node.type.spec.draggable;
    const isSelectable = NodeSelection.isSelectable(this.node);
    const isCopyEvent = event.type === 'copy';
    const isPasteEvent = event.type === 'paste';
    const isCutEvent = event.type === 'cut';
    const isClickEvent = event.type === 'mousedown';

    // ProseMirror tries to drag selectable nodes
    // even if `draggable` is set to `false`
    // this fix prevents that
    if (!isDraggable && isSelectable && isDragEvent) {
      event.preventDefault();
    }

    if (isDraggable && isDragEvent && !isDragging) {
      event.preventDefault();
      return false;
    }

    // we have to store that dragging started
    if (isDraggable && isEditable && !isDragging && isClickEvent) {
      const dragHandle = target.closest('[data-drag-handle]');
      const isValidDragHandle =
        dragHandle &&
        (this.dom === dragHandle || this.dom.contains(dragHandle));

      if (isValidDragHandle) {
        this.isDragging = true;

        document.addEventListener(
          'dragend',
          () => {
            this.isDragging = false;
          },
          { once: true }
        );

        document.addEventListener(
          'drop',
          () => {
            this.isDragging = false;
          },
          { once: true }
        );

        document.addEventListener(
          'mouseup',
          () => {
            this.isDragging = false;
          },
          { once: true }
        );
      }
    }

    // these events are handled by prosemirror
    if (
      isDragging ||
      isDropEvent ||
      isCopyEvent ||
      isPasteEvent ||
      isCutEvent ||
      (isClickEvent && isSelectable)
    ) {
      return false;
    }

    return true;
  }

  destroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.renderer.destroy();
    this.editor.off('selectionUpdate', this.handleSelectionUpdate);
    // this.editor.off('transaction', this.handleTransaction);
    // eslint-disable-next-line no-null/no-null
    this.contentDOMElement = null;
  }

  private _appendContendDom(): void {
    const contentElement = this.dom.querySelector('[data-node-view-content]');

    if (
      this.contentDOMElement &&
      contentElement &&
      !contentElement.contains(this.contentDOMElement)
    ) {
      contentElement.appendChild(this.contentDOMElement);
    }
  }

  /**
   * Subscribe to component data changes and update the Prosemirror Model
   * so that the data models are in sync.
   */
  private _registerInstanceUpdateHandler(
    instance: IEditorNodeComponent,
    getPos: (() => number) | boolean
  ): void {
    instance.update
      .pipe(
        filter(() => this.editor.view.editable),
        takeUntil(this._onDestroy$)
      )
      .subscribe((data) => {
        const transaction = this.editor.state.tr;
        const pos = typeof getPos === 'boolean' ? 0 : getPos();
        if (isUndefined(pos)) {
          return;
        }
        transaction.setNodeMarkup(pos, undefined, data);
        this.editor.view.dispatch(transaction);
      });
  }
}

function isAndroid(): boolean {
  return (
    navigator.platform === 'Android' || /android/i.test(navigator.userAgent)
  );
}
