import {
  type ISubmenuButton,
  type MenuButtonLoaderFn,
} from '@principle-theorem/ng-prosemirror';
import { BlockNodes } from '@principle-theorem/editor';
import { BasicMenuButtonComponent } from '../basic-menu-button/basic-menu-button.component';

export function createInsertSubmenuButton(
  submenuItems: MenuButtonLoaderFn[] = []
): MenuButtonLoaderFn<BasicMenuButtonComponent, ISubmenuButton> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'submenu',
      command: () => {
        //
      },
      submenuItems,
      buttonText: 'Add/Insert',
      tooltip: 'Add/Insert',
      icon: 'library_add',
    },
  });
}

export function createMediaSubmenuButton(
  submenuItems: MenuButtonLoaderFn[] = []
): MenuButtonLoaderFn<BasicMenuButtonComponent, ISubmenuButton> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'submenu',
      command: () => {
        //
      },
      submenuItems,
      buttonText: 'Add Media',
      tooltip: 'Add Media',
      icon: 'insert_photo',
    },
  });
}

export function createAlignSubmenuButton(
  submenuItems: MenuButtonLoaderFn[] = []
): MenuButtonLoaderFn<BasicMenuButtonComponent, ISubmenuButton> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'submenu',
      canActivateFn: (editor) =>
        editor.isActive(BlockNodes.Paragraph) ||
        editor.isActive(BlockNodes.Heading),
      command: () => {
        //
      },
      submenuItems,
      buttonText: 'Text Alignment',
      tooltip: 'Text Alignment',
      icon: 'notes',
    },
  });
}
