import { Patient } from '@principle-theorem/principle-core';
import {
  Gender,
  PatientStatus,
  SourceEntityRecordStatus,
  type FailedDestinationEntityRecord,
  type IBrand,
  type IContactNumber,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IMedicareCard,
  type IPracticeMigration,
  type IStaffer,
  IHealthFundCard,
  ITranslationMap,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_FORMAT,
  getError,
  toISODate,
  type WithRef,
} from '@principle-theorem/shared';
import * as he from 'he';
import { first, sortBy } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, type Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  BasePatientDestinationEntity,
  IPatientMigrationData,
} from '../../../destination/entities/patient';
import { PracticeMigration } from '../../../practice-migrations';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  ICorePracticePatientInsuranceDetail,
  PatientInsuranceDetailSourceEntity,
} from '../../source/entities/patient-insurance-details';
import {
  PatientSourceEntity,
  type ICorePracticePatient,
  type ICorePracticePatientFilters,
  type ICorePracticePatientTranslations,
} from '../../source/entities/patients';
import { PROVIDER_RESOURCE_TYPE } from '../../source/entities/providers';
import { CorePracticeStafferMappingHandler } from '../mappings/staff';
import { StafferDestinationEntity } from './staff';

export const PATIENT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: 'patients',
    label: 'Patients',
    description: '',
  },
});

export interface IPatientJobData {
  sourcePatient: IGetRecordResponse<
    ICorePracticePatient,
    ICorePracticePatientTranslations,
    ICorePracticePatientFilters
  >;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  brand: WithRef<IBrand>;
}

export class PatientDestinationEntity extends BasePatientDestinationEntity<ICorePracticePatient> {
  destinationEntity = PATIENT_DESTINATION_ENTITY;
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    patientInsuranceDetails: new PatientInsuranceDetailSourceEntity(),
  };

  override destinationEntities = {
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new CorePracticeStafferMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    skipMigrated: boolean
  ): Observable<IPatientJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMapHandler),
      translationMapHandler.getByType$<IStaffer>(PROVIDER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const brand$ = PracticeMigration.brand$(migration);
    return this.sourceEntities.patients
      .getRecords$(
        migration,
        1000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        withLatestFrom(staff$, brand$),
        map(([patientRecords, staff, brand]) =>
          patientRecords.map((sourcePatient) => ({
            sourcePatient,
            staff,
            brand,
          }))
        )
      );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IPatientJobData
  ): Promise<
    | IPatientMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const errorResponseData = {
      label: data.sourcePatient.record.label,
      uid: data.sourcePatient.record.uid,
      ref: data.sourcePatient.record.ref,
    };
    if (data.sourcePatient.record.status === SourceEntityRecordStatus.Invalid) {
      return this._buildErrorResponse(
        errorResponseData,
        'Source patient is invalid'
      );
    }

    const patient = data.sourcePatient.data.data;
    const sourcePatientId =
      this.sourceEntities.patients.getSourceRecordId(patient);

    const insuranceDetails =
      await this.sourceEntities.patientInsuranceDetails.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

    try {
      return {
        sourcePatientId: sourcePatientId.toString(),
        createdAt: data.sourcePatient.data.translations.joinDate,
        patient: Patient.init({
          name: getName(patient),
          email: patient.email,
          contactNumbers: getPhone(patient),
          address: getAddress(patient),
          dateOfBirth: patient.dateOfBirth
            ? toISODate(moment.utc(patient.dateOfBirth, ISO_DATE_FORMAT))
            : undefined,
          gender: getGender(patient),
          status: getStatus(patient),
          medicareCard: getMedicareCard(patient),
          referenceId: sourcePatientId.toString(),
          healthFundCard: getHealthcareCard(insuranceDetails),
        }),
      };
    } catch (error) {
      return this._buildErrorResponse(errorResponseData, getError(error));
    }
  }
}

function getGender(patient: ICorePracticePatient): Gender {
  switch (patient.sex) {
    case 'F':
      return Gender.Female;
    case 'M':
      return Gender.Male;
    case 'O':
      return Gender.Other;
    default:
      return Gender.NotSpecified;
  }
}

function getStatus(patient: ICorePracticePatient): PatientStatus {
  if (!patient.isActive || patient.isDeleted) {
    return PatientStatus.Inactive;
  }
  return PatientStatus.Active;
}

function getAddress(patient: ICorePracticePatient): string {
  return `${patient.addressLine1 ?? ''} ${patient.addressLine2 ?? ''}, ${
    patient.suburb ?? ''
  }, ${patient.state ?? ''}, ${patient.country ?? ''}, ${
    patient.postcode ?? ''
  }`;
}

function getPhone(patient: ICorePracticePatient): IContactNumber[] {
  const contactNumbers: IContactNumber[] = [
    { label: 'mobile', number: patient.mobile ?? '' },
    { label: 'home', number: patient.homePhone ?? '' },
    { label: 'work', number: patient.workPhone ?? '' },
  ];
  return contactNumbers.filter((contactNumber) => !!contactNumber.number);
}

export function getName(patient: ICorePracticePatient): string {
  const preferredName = patient.preferredName?.trim() ?? '';

  if (!preferredName || preferredName === patient.firstName) {
    return he.decode(`${patient.firstName} ${patient.lastName}`.trim());
  }
  return he.decode(
    `${patient.firstName} (${preferredName}) ${patient.lastName}`.trim()
  );
}

function getMedicareCard(patient: ICorePracticePatient): IMedicareCard {
  return {
    number: patient.medicareNo ?? '',
    subNumerate: '',
  };
}

function getHealthcareCard(
  cards: IGetRecordResponse<ICorePracticePatientInsuranceDetail>[]
): IHealthFundCard {
  const healthFundCard = first(
    sortBy(
      cards.filter((insuranceDetail) => !!insuranceDetail.data.data.memberCode),
      'id',
      'desc'
    )
  );
  return {
    fundCode: healthFundCard?.data.data.insuranceName ?? '',
    membershipNumber: healthFundCard?.data.data.memberCode ?? '',
    memberNumber: '',
  };
}
