import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import {
  BasicDialogService,
  isDisabled$,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  MultiTreatmentPackage,
  TreatmentConfiguration,
} from '@principle-theorem/principle-core';
import {
  type IMultiTreatmentConfiguration,
  type IMultiTreatmentPackage,
  type IMultiTreatmentPackageStep,
  type IMultiTreatmentPackageTreatment,
  type IPricedServiceCodeEntry,
  type ITreatmentConfiguration,
  type ITreatmentPackagePriceOverride,
  ServiceCodeGroupType,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import { getDoc, isSameRef, type WithRef } from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

export type MultiTreatmentPackageForm = Omit<IMultiTreatmentPackage, 'uid'>;

@Component({
  selector: 'pr-edit-multi-treatment-package',
  templateUrl: './edit-multi-treatment-package.component.html',
  styleUrls: ['./edit-multi-treatment-package.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMultiTreatmentPackageComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  multiTreatmentPackage$ = new ReplaySubject<IMultiTreatmentPackage>(1);
  multiTreatmentConfig$ = new ReplaySubject<
    WithRef<IMultiTreatmentConfiguration>
  >(1);
  trackByIndex = TrackByFunctions.index();
  trackByStep = TrackByFunctions.uniqueId<IMultiTreatmentPackageStep>();
  trackByTreatment =
    TrackByFunctions.ref<IMultiTreatmentPackageTreatment>('treatmentRef');
  form = new TypedFormGroup<MultiTreatmentPackageForm>({
    name: new TypedFormControl(''),
    description: new TypedFormControl(''),
    isDefault: new TypedFormControl(false),
    steps: new TypedFormControl<IMultiTreatmentPackageStep[]>([]),
  });
  totalPrice$ = new ReplaySubject<number>(1);
  isDisabled$ = isDisabled$(this.form);
  steps$: Observable<IMultiTreatmentPackageStep[]>;
  @Output() updatePackage = new EventEmitter<MultiTreatmentPackageForm>();
  @Output() deletePackage = new EventEmitter<void>();

  @Input()
  set multiTreatmentPackage(multiTreatmentPackage: IMultiTreatmentPackage) {
    if (multiTreatmentPackage) {
      this.multiTreatmentPackage$.next(multiTreatmentPackage);
    }
  }

  @Input()
  set multiTreatmentConfig(
    multiTreatmentConfig: WithRef<IMultiTreatmentConfiguration>
  ) {
    if (multiTreatmentConfig) {
      this.multiTreatmentConfig$.next(multiTreatmentConfig);
    }
  }

  constructor(private _dialog: BasicDialogService) {
    const multiTreatmentPackage$ = combineLatest([
      this.multiTreatmentConfig$,
      this.multiTreatmentPackage$,
    ]).pipe(
      switchMap(([config, multiTreatmentPackage]) =>
        MultiTreatmentPackage.mergeWithConfig(config, multiTreatmentPackage)
      )
    );

    this.steps$ = multiTreatmentPackage$.pipe(
      map((multiTreatmentPackage) => multiTreatmentPackage.steps)
    );

    multiTreatmentPackage$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((multiTreatmentPackage) =>
        this.form.patchValue(multiTreatmentPackage)
      );

    combineLatest([this.form.valueChanges, this.multiTreatmentConfig$])
      .pipe(
        switchMap(async ([form, multiTreatmentConfig]) =>
          MultiTreatmentPackage.total(multiTreatmentConfig, form)
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((total) => this.totalPrice$.next(total));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }
    const data = this.form.getRawValue();

    this.updatePackage.emit({
      name: data.name,
      description: data.description,
      isDefault: data.isDefault ?? false,
      steps: data.steps,
    });
  }

  async delete(): Promise<void> {
    const confirmed = await this._dialog.confirm({
      prompt: 'Are you sure you want to delete this package?',
      title: 'Delete',
      submitLabel: 'Yes, Delete',
      submitColor: 'warn',
      cancelLabel: 'Cancel',
    });

    if (!confirmed) {
      return;
    }

    this.deletePackage.emit();
  }

  resolvePriceOverrides(
    treatmentConfig: WithRef<ITreatmentConfiguration>,
    stepUid: string
  ): ITreatmentPackagePriceOverride[] {
    const multiTreatmentPackage = this.form.getRawValue();
    const step = multiTreatmentPackage.steps.find(
      (searchStep) => searchStep.uid === stepUid
    );

    if (!step) {
      return [];
    }

    const priceOverrides =
      step.treatments.find((treatment) =>
        isSameRef(treatment.treatmentRef, treatmentConfig)
      )?.priceOverrides ?? [];

    return priceOverrides;
  }

  async resolveTreatmentConfig(
    treatmentConfigRef: DocumentReference<ITreatmentConfiguration>
  ): Promise<WithRef<ITreatmentConfiguration>> {
    return getDoc(treatmentConfigRef);
  }

  updateServiceItems(
    priceOverrides: ITreatmentPackagePriceOverride[],
    treatmentConfig: WithRef<ITreatmentConfiguration>,
    stepUid: string
  ): void {
    const form = this.form.getRawValue();

    const steps = form.steps.map((step) => {
      if (step.uid !== stepUid) {
        return step;
      }

      return {
        ...step,
        treatments: step.treatments.map((treatment) => {
          if (!isSameRef(treatment.treatmentRef, treatmentConfig)) {
            return treatment;
          }

          return {
            ...treatment,
            priceOverrides,
          };
        }),
      };
    });

    this.form.patchValue({
      steps,
    });

    this.form.markAsDirty();
  }

  getServiceCodes(
    treatmentConfig: WithRef<ITreatmentConfiguration>
  ): IPricedServiceCodeEntry[] {
    return TreatmentConfiguration.getCombinedServiceCodes(treatmentConfig, [
      ServiceCodeGroupType.Required,
      ServiceCodeGroupType.Exclusive,
    ]);
  }
}
