import { Injectable } from '@angular/core';
import { FirebaseFunctionsService } from '@principle-theorem/ng-firebase';
import {
  getMentionContactDetails,
  type IInteractionError,
} from '@principle-theorem/principle-core';
import {
  type IPractice,
  type IPrincipleMention,
  type ISendEmailRequest,
} from '@principle-theorem/principle-core/interfaces';
import { serialise, type WithRef } from '@principle-theorem/shared';

export interface ISendEmailData {
  subject: string;
  content: string;
}

@Injectable()
export class SendEmailService {
  constructor(private _functions: FirebaseFunctionsService) {}

  async send(
    mention: IPrincipleMention,
    emailData: ISendEmailData,
    sender: WithRef<IPractice>
  ): Promise<IInteractionError | undefined> {
    const email = await this._getMentionEmail(mention);
    if (!email) {
      return { message: 'Could not resolve email address' };
    }

    await this._sendEmailRequest({
      to: email,
      subject: emailData.subject,
      text: emailData.content,
      html: emailData.content,
      senderRef: sender.ref,
    });
  }

  private async _getMentionEmail(
    mention: IPrincipleMention
  ): Promise<string | undefined> {
    const details = await getMentionContactDetails(mention);
    return details?.email;
  }

  private async _sendEmailRequest(request: ISendEmailRequest): Promise<void> {
    const data = serialise(request);
    await this._functions.call('http-sendgrid-sendEmail', data);
  }
}
