import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { type IMultiTreatmentConfiguration } from '@principle-theorem/principle-core/interfaces';
import {
  CurrentBrandScope,
  CurrentStafferScope,
} from '@principle-theorem/ng-principle-shared';
import { type IBreadcrumb } from '@principle-theorem/ng-shared';
import { type CollectionReference } from '@principle-theorem/shared';
import { filterUndefined, findProp } from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-multi-treatment-config-list',
    templateUrl: './multi-treatment-config-list.component.html',
    styleUrls: ['./multi-treatment-config-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MultiTreatmentConfigListComponent {
  multiTreatmentConfigurationCol$: Observable<
    CollectionReference<IMultiTreatmentConfiguration>
  >;
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(
    private _route: ActivatedRoute,
    private _currentBrand: CurrentBrandScope,
    private _currentStaffer: CurrentStafferScope
  ) {
    this.multiTreatmentConfigurationCol$ = this._route.data.pipe(
      findProp<CollectionReference<IMultiTreatmentConfiguration>>(
        'multiTreatmentConfigurationCol'
      ),
      filterUndefined()
    );

    this.breadcrumbs$ = combineLatest([
      this._currentBrand.doc$.pipe(filterUndefined()),
      this._currentStaffer.doc$,
    ]).pipe(
      map(([brand, staffer]) => {
        if (staffer) {
          return [
            { label: 'Settings', path: '../../../../' },
            { label: brand.name },
            { label: 'My Account' },
            { label: 'Multi Treatment Configurations' },
          ];
        }
        return [
          { label: 'Settings', path: '../../../../' },
          { label: brand.name },
          { label: 'Multi Treatment Configurations' },
        ];
      })
    );
  }
}
