import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import { isManualExtendedData } from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeneralProviderActionsService } from '../../general-provider-actions.service';
import {
  ITransactionAction,
  ITransactionActionsData,
} from '../../transaction-action';

export class DeleteManualTransaction implements ITransactionAction<unknown> {
  icon = 'delete';
  label = 'Delete';
  inProgress$ = new BehaviorSubject<boolean>(false);
  typeGuardFn = isManualExtendedData;

  constructor(
    private _actions: GeneralProviderActionsService,
    private _organisation: OrganisationService
  ) {}

  canDo$(_data: ITransactionActionsData<unknown>): Observable<boolean> {
    return this._organisation.userPermissions$.pipe(
      map((permissions) =>
        permissions.includes(PatientPermissions.AccountInvoiceAdmin)
      )
    );
  }

  async do(data: ITransactionActionsData<unknown>): Promise<void> {
    this.inProgress$.next(true);
    await this._actions.delete(data.invoice, data.transaction);
    this.inProgress$.next(false);
  }
}
