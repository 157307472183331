<div
  class="h-6 w-6 cursor-pointer rounded-sm"
  [style.backgroundColor]="hexColour$ | async"
  [matMenuTriggerFor]="colourPicker"
></div>

<mat-menu #colourPicker (closed)="save()">
  <div
    (click)="$event.stopPropagation()"
    class="layout-padding flex flex-col gap-4"
  >
    <div class="checkerboard relative h-6 w-full rounded-sm">
      <span
        [style.backgroundColor]="hexColour$ | async"
        class="absolute right-0 top-0 h-full w-full rounded-sm"
      ></span>
    </div>

    <div class="grid grid-cols-6 gap-1" *ngrxLet="palette$ as palette">
      <div
        *ngFor="let colour of palette; trackBy: trackByColour"
        class="h-6 w-6 cursor-pointer rounded-sm"
        [style.backgroundColor]="colour"
        (click)="selectColour(colour, $event)"
      ></div>
    </div>

    <div [ngClass]="{ hidden: disableOpacity }" class="flex flex-col">
      <div class="mat-caption flex items-center justify-between opacity-70">
        <span>Opacity:</span>
        <span>
          {{ opacityCtrl.value | map: formatPercentage : this }}
        </span>
      </div>

      <mat-slider [min]="opacityMin" [max]="opacityMax" step="0.01">
        <input matSliderThumb [formControl]="opacityCtrl" />
      </mat-slider>
    </div>

    <button
      #applyButton
      mat-flat-button
      color="primary"
      (click)="save(); matMenuTrigger.closeMenu()"
    >
      Apply
    </button>
  </div>
</mat-menu>
