import {
  ISourceLinkOption,
  IStaffer,
  ITranslationMap,
} from '@principle-theorem/principle-core/interfaces';
import {
  XSLXCell,
  type AtLeast,
  type IXSLXExport,
} from '@principle-theorem/shared';
import type { Column } from 'exceljs';
import { sortBy } from 'lodash';
import { IBaseMigrationAppointmentBook } from '../interfaces';

export interface IAppointmentBookToPractitionerXSLX {
  id: string;
  name: string;
  mapTo: string;
}

export const APPOINTMENT_BOOK_TO_PRACTITIONER_HEADERS: AtLeast<
  Column,
  'key' | 'header'
>[] = [
  {
    key: 'id',
    header: 'Id',
  },
  {
    key: 'name',
    header: 'Book Name',
    width: 30,
  },
  {
    key: 'mapTo',
    header: 'Map To',
    width: 30,
  },
];

export class AppointmentBookToPractitionerToXSLX<
  T extends IBaseMigrationAppointmentBook = IBaseMigrationAppointmentBook,
> implements IXSLXExport<T, IAppointmentBookToPractitionerXSLX>
{
  headers = APPOINTMENT_BOOK_TO_PRACTITIONER_HEADERS;

  constructor(
    private _staff: ISourceLinkOption[],
    private _existingMappings: ITranslationMap<IStaffer>[]
  ) {}

  translate(
    records: T[]
  ): Record<keyof IAppointmentBookToPractitionerXSLX, XSLXCell>[] {
    const stafferOptions = sortBy(this._staff, 'sourceLabel').map(
      (staffer) => staffer.sourceLabel
    );

    const mappedRecords: Record<
      keyof IAppointmentBookToPractitionerXSLX,
      XSLXCell
    >[] = records.map((record) => {
      const existingRecord = this._existingMappings.find(
        (existingMapping) =>
          existingMapping.sourceIdentifier === record.id.toString()
      );

      const associatedValue = existingRecord?.sourceLink;
      const mappedStaffer = associatedValue
        ? this._staff.find(
            (staffer) =>
              staffer.sourceIdentifier === associatedValue.sourceIdentifier
          )
        : undefined;

      const existingValue = mappedStaffer
        ? mappedStaffer.sourceLabel
        : undefined;

      return {
        id: {
          value: record.id,
        },
        name: {
          value: record.description,
        },
        mapTo: {
          value: existingValue,
          dataValidation: {
            type: 'list',
            formulae: [`"${stafferOptions.join(',')}"`],
          },
        },
      };
    });

    const customRecords: Record<
      keyof IAppointmentBookToPractitionerXSLX,
      XSLXCell
    >[] = this._existingMappings
      .filter(
        (existingMapping) =>
          !records
            .map((record) => record.id.toString())
            .includes(existingMapping.sourceIdentifier)
      )
      .map((existingMapping) => {
        const associatedValue = existingMapping?.sourceLink;
        const mappedStaffer = associatedValue
          ? this._staff.find(
              (staffer) =>
                staffer.sourceIdentifier === associatedValue.sourceIdentifier
            )
          : undefined;

        const existingValue = mappedStaffer
          ? mappedStaffer.sourceLabel
          : undefined;

        return {
          id: {
            value: existingMapping.sourceIdentifier,
          },
          name: {
            value: existingMapping.sourceLabel ?? '',
          },
          mapTo: {
            value: existingValue,
            dataValidation: {
              type: 'list',
              formulae: [`"${stafferOptions.join(',')}"`],
            },
          },
        };
      });

    return [...mappedRecords, ...customRecords];
  }
}
