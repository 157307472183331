<form class="flex flex-row gap-4" [formGroup]="form" (ngSubmit)="submit()">
  <div class="flex flex-auto flex-col gap-4">
    <mat-slide-toggle formControlName="isEnabled">
      Enable Smartpay
    </mat-slide-toggle>

    <div>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        [disabled]="form.invalid"
      >
        Save
      </button>
    </div>
  </div>
</form>
