<mat-form-field>
  <mat-label>Owner</mat-label>
  <mat-select [formControl]="control">
    <ng-container *ngIf="staff$ | async as staff">
      <mat-option
        *ngFor="let staffer of staff; trackBy: trackByStaffer"
        [value]="staffer"
      >
        {{ staffer.user.name }}
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
