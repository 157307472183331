import { IPatient } from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { IBaseCommand, IBridgeIntegration } from '../../send-command-to-device';

export const VISTASOFT_INTEGRATION: IBridgeIntegration = {
  label: 'VistaSoft',
  description: 'Two-way integration for Durr Dental VistaSoft Imaging Software',
};

export type VistaSoftOpenPatientCommand = IBaseCommand<
  VistaSoftToDeviceCommand.OpenVistaSoft,
  { patient: WithRef<IPatient> }
>;

export enum VistaSoftToDeviceCommand {
  OpenVistaSoft = 'vistaSoft.openVistaSoft',
}
