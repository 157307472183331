<mat-action-list fxLayout="column">
  <mat-list-item
    *ngFor="
      let userSession of switchUser.otherUserSessions$ | async;
      trackBy: trackBySession
    "
    fxLayout="row"
    fxLayoutGap="16px"
    class="auth-user-card"
    (click)="switchUser.requestSwitchUser(userSession)"
  >
    <pt-user-icon
      matListItemIcon
      [name]="userSession.name"
      [diameter]="40"
     />
    <span matListItemTitle>{{ userSession.name }}</span>
    <div matListItemLine class="email">{{ userSession.email }}</div>
  </mat-list-item>
</mat-action-list>
