import {
  type IBrand,
  type IStaffer,
  type TreatmentTypeConfiguration as ITreatmentTypeConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { type IReffable } from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MultiTreatmentConfiguration } from './multi-treatment-configuration';
import { TreatmentConfiguration } from './treatment-configuration';

export class TreatmentTypeConfiguration {
  static chartableItems$(
    parent: IReffable<IBrand> | IReffable<IStaffer>
  ): Observable<ITreatmentTypeConfiguration[]> {
    return combineLatest([
      MultiTreatmentConfiguration.all$(parent),
      TreatmentConfiguration.all$(parent),
    ]).pipe(
      map(([multiTreatmentConfigurations, treatmentConfigurations]) => [
        ...multiTreatmentConfigurations,
        ...treatmentConfigurations,
      ])
    );
  }
}
