import { IBaseChartBuilderConfig } from '@principle-theorem/principle-core/interfaces';
import {
  IMeasureBuilderData,
  type CanBeChartedProperty,
  type IChartConfig,
} from '@principle-theorem/reporting';
import {
  UNIVERSAL_USER_INPUT_DEBOUNCE,
  isObject,
  toInt,
} from '@principle-theorem/shared';
import * as d3 from 'd3';
import * as dc from 'dc';
import { compact, debounce, first, get, isString } from 'lodash';
import { type Subject } from 'rxjs';
import { type IDCTransformResult } from '../../../../models/report/charts/data-transformers/dc-data-transformer';
import {
  DataPointValueFormatter,
  type DataPointValueFormatterFn,
} from '../../data-point-value-formatter';
import {
  D3_CHART_ELEM_SELECTOR,
  DEFAULT_CHART_BUILDER_CONFIG,
  applyBaseConfigToChart,
  ChartBuildHelpers,
  type ICanBuildForCompositeChart,
  type IDCChartBuilder,
  type IDCChartEvent,
} from './dc-chart-builder';
import { DCTooltip, type IDCTooltip } from './dc-tooltip';
import { TimePlottedChart } from './time-plotted-chart';

export interface IColumnChartBuilderConfig extends IBaseChartBuilderConfig {
  rotateXAxisLabels?: boolean;
}

export const DEFAULT_COLUMN_CHART_BUILDER_CONFIG: IColumnChartBuilderConfig = {
  ...DEFAULT_CHART_BUILDER_CONFIG,
  rotateXAxisLabels: false,
};

export class DcColumnChartBuilder
  implements
    ICanBuildForCompositeChart<dc.BarChart>,
    IDCChartBuilder<dc.BarChart>
{
  constructor(
    private _config: IColumnChartBuilderConfig = DEFAULT_COLUMN_CHART_BUILDER_CONFIG
  ) {}

  getLabel(config: IChartConfig): string {
    return config.labels.title;
  }

  getChartClasses(_config: IChartConfig): string[] {
    return compact([
      this._config.rotateXAxisLabels ? 'rotate-x-axis-label' : undefined,
    ]);
  }

  getChart(
    results: IDCTransformResult[],
    config: IChartConfig,
    eventListener$?: Subject<IDCChartEvent>
  ): dc.BaseMixin<dc.BarChart> | undefined {
    const result = first(results);
    const measure = first(config.builderData.measures);
    if (!result || !measure) {
      return;
    }

    const chart = this.build(
      D3_CHART_ELEM_SELECTOR,
      result,
      config,
      config.builderData.measures[0]
    );
    applyBaseConfigToChart(this._config, chart);

    this._buildXAxis(chart, config, measure.measure);
    this._buildYAxis(chart, config, measure.measure);

    chart.gap(1);
    chart.margins({
      top: 0,
      right: 0,
      bottom: this._config.rotateXAxisLabels ? 80 : 20,
      left: 80,
    });

    if (config.builderData.colourOverride) {
      chart.colorCalculator((data: unknown) =>
        isObject(data)
          ? String(get(data, 'key.colour', get(data, 'key', undefined)))
          : String(data)
      );
    }

    // if (config.builderData.groupLimit) {
    //   chart.cap(config.builderData.groupLimit);
    // }

    // if (config.builderData.otherGroupLabel) {
    //   chart.othersLabel(config.builderData.otherGroupLabel);
    // }

    // if (!config.builderData.plottedOverTime) {
    //   chart.colorAccessor((_data, index) => index ?? -1);
    // }

    const updateEventHandler = (chartData: dc.BarChart): void => {
      if (
        config.builderData.plottedOverTime &&
        config.builderData.groupByDimension
      ) {
        TimePlottedChart.preHookHandler(
          chartData,
          result,
          config.builderData.groupByDimension
        );
      }
    };
    chart.on('preRender', updateEventHandler);
    chart.on('preRedraw', updateEventHandler);

    if (eventListener$) {
      chart.on(
        'filtered',
        debounce(
          (eventChart) =>
            ChartBuildHelpers.onFilteredEvent(
              eventChart as dc.BaseMixin<unknown>,
              config,
              eventListener$
            ),
          UNIVERSAL_USER_INPUT_DEBOUNCE,
          { leading: true }
        )
      );
    }

    return chart;
  }

  // getLegend(): dc.HtmlLegend {
  //   const legend = dc.htmlLegend().horizontal(false).highlightSelected(true);
  //   // TODO: Implement this. Currently can't see any useful data coming through.
  //   // legend.legendText((data: unknown) => {
  //   //   const label = isObject(data)
  //   //     ? String(get(data, 'key.label', get(data, 'key', '')))
  //   //     : String(data);

  //   //   console.log({ data, label });
  //   //   return label;
  //   // });
  //   return legend;
  // }

  getTooltip(config: IChartConfig): IDCTooltip {
    const measure = first(config.builderData.measures);
    return new DCTooltip(
      '.bar',
      (data: unknown) => String(get(data, 'data.key.label', '')),
      (data: unknown) => this._formatter(measure?.measure)(this._getValue(data))
    );
  }

  build(
    parent: string | dc.CompositeChart,
    result: IDCTransformResult,
    config: IChartConfig,
    measure: IMeasureBuilderData
  ): dc.BarChart {
    if (measure.filters?.length) {
      const { dimension, group } = ChartBuildHelpers.buildFilteredDimension(
        result,
        config,
        measure
      );

      return dc.barChart(parent).dimension(dimension).group(group);
    }

    return dc.barChart(parent).dimension(result.dimension).group(result.group);
  }

  private _getValue(data: unknown): string {
    const value = get(data, 'y', '') as unknown;
    if (isObject(value)) {
      return String(value.valueOf());
    }
    return String(value);
  }

  private _buildYAxis(
    chart: dc.BarChart,
    _config: IChartConfig,
    measure: CanBeChartedProperty
  ): void {
    chart.elasticY(true).yAxis().tickFormat(this._formatter(measure));
  }

  private _buildXAxis(
    chart: dc.BarChart,
    config: IChartConfig,
    _measure: CanBeChartedProperty
  ): void {
    if (config.builderData.plottedOverTime) {
      // TimePlottedChart.xAxisSetup(chart, dateRange);
      const xScale = d3.scaleTime();
      const xUnits: dc.UnitFunction = d3.timeDays as dc.UnitFunction;
      chart.x(xScale).xUnits(xUnits);
      chart.centerBar(true);
      return;
    }

    const xScale = d3.scaleBand();
    const xUnits: dc.UnitFunction = dc.units.ordinal;
    chart
      .x(xScale)
      .xUnits(xUnits)
      .ordering((data) => toInt(get(data, 'value', '0')));

    chart.xAxis().tickFormat((data) => {
      const label = isString(data) ? data : String(get(data, 'label', ''));
      return label;
    });

    if (this._config.numberOfTicks) {
      chart.xAxis().ticks(this._config.numberOfTicks);
    }
  }

  private _formatter(
    measure?: CanBeChartedProperty
  ): DataPointValueFormatterFn {
    return DataPointValueFormatter.getFormatter(
      measure?.metadata.formatter,
      measure?.metadata.formatterValue
    );
  }
}
