import { Component } from '@angular/core';
import {
  CustomChartType,
  TreatmentPlanStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  type CanBeChartedProperty,
  FactTables,
  type ICanGroupMeasuresProperty,
  ValueEqualsMeasureFilter,
} from '@principle-theorem/reporting';
import { BAR_CHART_OPTIONS } from '../../../models/report/charts/bar-chart';
import { type ChartBuilder } from '../../../models/report/charts/chart-builder';
import { type IChartCard } from '../../../models/report/charts/chart-card';
import { MeasureDataChartComponent } from '../../core/measure-data-chart-component';

@Component({
    selector: 'pr-practitioner-treatments',
    templateUrl: './practitioner-treatments.component.html',
    styleUrls: ['./practitioner-treatments.component.sass'],
    standalone: false
})
export class PractitionerTreatmentsComponent extends MeasureDataChartComponent {
  tableChartCard: IChartCard;
  acceptanceChartBuilder: ChartBuilder;
  typesChartBuilder: ChartBuilder;
  incomeDistributionChartBuilder: ChartBuilder;
  treatmentValuesChart: ChartBuilder;

  _redraw(): void {
    this.acceptanceChartBuilder = this._sanKeyChartBuilder(
      [
        // 'acceptedTreatmentPlans',
        FactTables.treatmentPlanEvent.latestEvent.treatmentPlan.status
          .filterBy(new ValueEqualsMeasureFilter(TreatmentPlanStatus.Accepted))
          .reduceByCount(),
        // 'rejectedTreatmentPlans',
        FactTables.treatmentPlanEvent.latestEvent.treatmentPlan.status
          .filterBy(new ValueEqualsMeasureFilter(TreatmentPlanStatus.Declined))
          .reduceByCount(),
      ],
      FactTables.appointmentEvent.practitioner.name
    );
    this.typesChartBuilder = this._stackedChartBuilder(
      [
        // 'treatmentsDiagnostic',
        // 'treatmentsPreventive',
        // 'treatmentsPeriodontics',
        // 'treatmentsOralSurgery',
        // 'treatmentsEndodontics',
        // 'treatmentsRestorative',
        // 'treatmentsProsthodontics',
        // 'treatmentsOrthodontics',
        // 'treatmentsOther',
      ],
      FactTables.appointmentEvent.practitioner.name
    );
    this.incomeDistributionChartBuilder = this._stackedChartBuilder(
      [
        // 'treatmentsDiagnosticIncome',
        // 'treatmentsPreventiveIncome',
        // 'treatmentsPeriodonticsIncome',
        // 'treatmentsOralSurgeryIncome',
        // 'treatmentsEndodonticsIncome',
        // 'treatmentsRestorativeIncome',
        // 'treatmentsProsthodonticsIncome',
        // 'treatmentsOrthodonticsIncome',
        // 'treatmentsOtherIncome',
      ],
      FactTables.appointmentEvent.practitioner.name
    );
    this.tableChartCard = this._tableChartCard(
      [
        FactTables.treatmentPlanEvent
          .scopeBy(FactTables.treatmentPlanEvent.offered)
          .count.setLabel('Presented'),
        FactTables.treatmentPlanEvent
          .scopeBy(FactTables.treatmentPlanEvent.accepted)
          .count.setLabel('Accepted'),
        FactTables.treatmentPlanEvent
          .scopeBy(FactTables.treatmentPlanEvent.rejected)
          .count.setLabel('Rejected'),
        // 'treatmentPlanAcceptanceRate',
        // 'treatmentsApplied',
        // 'totalValueOfTreatmentsApplied',
        FactTables.appointmentEvent.treatmentCost.reduceBySum(),
      ],
      FactTables.appointmentEvent.practitioner.name
    );

    this.treatmentValuesChart = this._stackedChartBuilder(
      [
        // 'totalValueOfTreatmentsUnaccepted',
        // 'totalValueOfTreatmentsAccepted',
      ],
      FactTables.appointmentEvent.practitioner.name
    );
  }

  private _sanKeyChartBuilder(
    measures: CanBeChartedProperty[],
    groupByDimension: ICanGroupMeasuresProperty
  ): ChartBuilder {
    return this.dataBuilder.toSanKeyDiagram(
      this._chartMeasures(CustomChartType.SanKey, measures, groupByDimension)
    ).chartBuilder;
  }

  private _stackedChartBuilder(
    measures: CanBeChartedProperty[],
    groupByDimension: ICanGroupMeasuresProperty
  ): ChartBuilder {
    const card: IChartCard = this.dataBuilder.toBarChart(
      this._chartMeasures(
        CustomChartType.StackedBar,
        measures,
        groupByDimension
      )
    );

    card.chartBuilder.addChartOptions(BAR_CHART_OPTIONS);
    card.chartBuilder.addChartOptions({
      bars: 'horizontal',
      isStacked: true,
      vAxis: { title: '' },
    });

    return card.chartBuilder;
  }
}
