import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { type MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  InlineNodes,
  NodeGroup,
  SNIPPET_TRIGGER,
  findNodePosition,
  findNodeRange,
  fromEditorEvents,
  getMixedContent,
  isMixedSchema,
  type ISnippet,
} from '@principle-theorem/editor';
import {
  EditorNode,
  EditorNodeComponent,
  IRenderHTMLArguments,
  NodeAttribute,
  type IConfigurable,
  type IHasUid,
} from '@principle-theorem/ng-prosemirror';
import {
  OptionGroupSearchFilter,
  TrackByFunctions,
  type IOptionGroup,
} from '@principle-theorem/ng-shared';
import { shareReplayCold } from '@principle-theorem/shared';
import {
  DOMOutputSpec,
  type Fragment,
  type Node as ProsemirrorNode,
} from '@tiptap/pm/model';
import { isString } from 'lodash';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  type Observable,
} from 'rxjs';
import {
  filter,
  map,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { EditorAutocompleteTriggerDirective } from '../../../editor-autocomplete/editor-autocomplete-trigger.directive';

export type ISnippetOptionGroup = IOptionGroup<ISnippet>;

export interface ISnippetAutocompleteNodeConfig {
  snippets$: Observable<ISnippetOptionGroup[]>;
  suggestionWidth?: string | number;
}

@EditorNode({
  name: InlineNodes.SnippetAutocomplete,
  content: `${InlineNodes.Text}*`,
  group: NodeGroup.Inline,
  inline: true,
  defining: true,
})
@Component({
  selector: 'pt-snippet-autocomplete-node',
  templateUrl: './snippet-autocomplete-node.component.html',
  styleUrls: ['./snippet-autocomplete-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnippetAutocompleteNodeComponent
  extends EditorNodeComponent<IHasUid>
  implements IHasUid, OnDestroy, IConfigurable<ISnippetAutocompleteNodeConfig>
{
  private _onDestroy$: Subject<void> = new Subject();
  trackByGroup = TrackByFunctions.field<IOptionGroup<ISnippet>>('name');
  trackBySnippet = TrackByFunctions.field<ISnippet>('name');
  override selected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  disabled$: Observable<boolean>;
  content$: Observable<string>;
  autocompleteTrigger$: ReplaySubject<EditorAutocompleteTriggerDirective> =
    new ReplaySubject(1);
  searchFilter: OptionGroupSearchFilter<ISnippet>;
  suggestionWidth: string | number = '240px';
  snippets$: ReplaySubject<ISnippetOptionGroup[]> = new ReplaySubject(1);
  @NodeAttribute() @Input() uid: string = uuid();

  constructor(elementRef: ElementRef) {
    super(elementRef);

    this.content$ = this.editor$.pipe(
      switchMap(fromEditorEvents),
      switchMap(() =>
        this.node$.pipe(
          map((node) => node.textContent),
          map((content) =>
            content.startsWith(SNIPPET_TRIGGER) ? content.substring(1) : content
          )
        )
      ),
      shareReplayCold()
    );
    this.searchFilter = new OptionGroupSearchFilter<ISnippet>(
      this.snippets$,
      this.content$,
      ['name', 'keyword']
    );

    this.disabled$ = combineLatest([
      this.editor$.pipe(map((editor) => editor.isEditable)),
      this.selected$,
    ]).pipe(map(([isEditable, selected]) => !isEditable || !selected));

    this.event$
      .pipe(
        filter((event): event is MouseEvent => event instanceof MouseEvent),
        withLatestFrom(this.autocompleteTrigger$, this.selected$),
        filter(([_event, _autocompleteTrigger, selected]) => selected),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([event, autocompleteTrigger, _selected]) => {
        autocompleteTrigger.addMouseEvent(event);
      });

    this.event$
      .pipe(
        filter(
          (event): event is KeyboardEvent => event instanceof KeyboardEvent
        ),
        withLatestFrom(this.autocompleteTrigger$, this.selected$),
        filter(([_event, _autocompleteTrigger, selected]) => selected),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([event, autocompleteTrigger, selected]) => {
        if (
          event.key === 'Enter' &&
          selected &&
          !autocompleteTrigger.activeOption
        ) {
          return this.removeSnippet();
        }
        autocompleteTrigger.addKeyboardEvent(event);
      });

    this.selected$
      .pipe(
        withLatestFrom(this.autocompleteTrigger$),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([selected, autocompleteTrigger]) =>
        setTimeout(() =>
          selected
            ? void autocompleteTrigger.openPanel()
            : autocompleteTrigger.closePanel()
        )
      );
  }

  configure(config: ISnippetAutocompleteNodeConfig): void {
    config.snippets$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((snippets) => this.snippets$.next(snippets));
    if (config.suggestionWidth) {
      this.suggestionWidth = config.suggestionWidth;
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @ViewChild(EditorAutocompleteTriggerDirective, { static: false })
  set autocompleteTrigger(
    autocompleteTrigger: EditorAutocompleteTriggerDirective
  ) {
    if (autocompleteTrigger) {
      this.autocompleteTrigger$.next(autocompleteTrigger);
    }
  }

  selectSnippet($event: MatAutocompleteSelectedEvent): void {
    const nodePosition = findNodePosition(this.editor.state.doc, this.node);
    if (!nodePosition) {
      return;
    }
    const from = nodePosition.pos;
    const to = nodePosition.pos + this.node.nodeSize;
    const snippet = $event.option.value as ISnippet;
    if (isString(snippet.body)) {
      return this.editor.view.dispatch(
        this.editor.state.tr
          .replace(from, to, undefined)
          .insertText(snippet.body)
      );
    }

    let snippetNode: ProsemirrorNode;
    if (isMixedSchema(snippet.body)) {
      snippetNode = this.editor.state.schema.nodeFromJSON(
        getMixedContent(snippet.body)
      );
    } else {
      snippetNode = this.editor.state.schema.nodeFromJSON(snippet.body);
    }

    const { tr } = this.editor.state;
    this.editor.view.dispatch(
      tr.replace(from, to, undefined).replaceSelectionWith(snippetNode, false)
    );
  }

  removeSnippet(): void {
    const nodePosition = findNodeRange(this.editor.state.doc, this.node);
    if (!nodePosition) {
      return;
    }

    const childNodes: Fragment = this.node.content.cut(
      0,
      this.node.content.size
    );

    this.editor.view.dispatch(
      this.editor.state.tr.replaceWith(
        nodePosition.$from.before(),
        nodePosition.$to.after(),
        childNodes
      )
    );
  }

  renderHTML(data: IRenderHTMLArguments): DOMOutputSpec {
    return ['span', data.HTMLAttributes];
  }
}
