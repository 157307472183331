import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { TimelineNavigationService } from '@principle-theorem/ng-principle-shared';
import {
  ICandidateCalendarEvent,
  IPatient,
  ITypesenseWaitListWithRef,
  IWaitListCandidate,
  WaitListUrgency,
} from '@principle-theorem/principle-core/interfaces';
import {
  asDocRef,
  DocumentReference,
  Firestore,
  snapshot,
  WithRef,
} from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

const URGENCY_MAP = {
  [WaitListUrgency.High]: 'high',
  [WaitListUrgency.Medium]: 'medium',
  [WaitListUrgency.Low]: 'low',
};

@Component({
  selector: 'pr-gap-shortlist-item-header',
  templateUrl: './gap-shortlist-item-header.component.html',
  styleUrls: ['./gap-shortlist-item-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GapShortlistItemHeaderComponent {
  private _timelineNav = inject(TimelineNavigationService);
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  waitListPatientRef$: Observable<DocumentReference<IPatient>>;
  gapCandidate$ = new ReplaySubject<WithRef<ICandidateCalendarEvent>>(1);
  candidate$ = new ReplaySubject<IWaitListCandidate>(1);
  waitListItem$ = new ReplaySubject<ITypesenseWaitListWithRef>(1);
  urgency$: Observable<string>;
  @Input() isShortlisted: boolean;

  @Input()
  set waitListItem(waitListItem: ITypesenseWaitListWithRef) {
    if (waitListItem) {
      this.waitListItem$.next(waitListItem);
    }
  }

  @Input()
  set gapCandidate(gapCandidate: WithRef<ICandidateCalendarEvent>) {
    if (gapCandidate) {
      this.gapCandidate$.next(gapCandidate);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  @Output() openWaitlistConfiguration = new EventEmitter<void>();
  @Output() showSchedulingNotes = new EventEmitter<void>();
  @Output() addToShortlist = new EventEmitter<void>();
  @Output() removeFromShortlist = new EventEmitter<void>();
  @Output() confirmForGap = new EventEmitter<void>();

  constructor() {
    this.waitListPatientRef$ = this.waitListItem$.pipe(
      map((patient) => asDocRef<IPatient>(patient.patientRef))
    );

    this.urgency$ = this.waitListItem$.pipe(
      map(({ urgency }) => URGENCY_MAP[urgency])
    );
  }

  async viewAppointmentOnTimeline(): Promise<void> {
    const waitListItem = await snapshot(this.waitListItem$);
    const appointment = await Firestore.getDoc(waitListItem.ref);
    await this._timelineNav.showOnTimeline(
      await Firestore.getDoc(appointment.ref)
    );
  }
}
