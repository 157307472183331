import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  Appointment,
  Invoice,
  TransactionOperators,
} from '@principle-theorem/principle-core';
import {
  CLAIM_PROVIDERS,
  IAppointment,
  IInvoice,
  ITransaction,
  TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-appointment-history-card-invoice',
    templateUrl: './appointment-history-card-invoice.component.html',
    styleUrls: ['./appointment-history-card-invoice.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentHistoryCardInvoiceComponent {
  appointment$ = new ReplaySubject<WithRef<IAppointment>>(1);
  invoice$: Observable<WithRef<IInvoice> | undefined>;
  invoiceLink$: Observable<string[] | undefined>;
  summary$: Observable<IInvoicePaymentSummary | undefined>;

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor() {
    this.invoice$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.invoice$(appointment))
    );
    const transacations$ = this.invoice$.pipe(
      switchMap((invoice) => (invoice ? Invoice.transactions$(invoice) : []))
    );

    this.summary$ = combineLatest([this.invoice$, transacations$]).pipe(
      map(([invoice, transactions]) =>
        invoice ? invoicePaymentSummary(invoice, transactions) : undefined
      )
    );

    this.invoiceLink$ = this.invoice$.pipe(
      map((invoice) => {
        if (!invoice) {
          return;
        }
        const patientRef = Invoice.patientDocRef(invoice);
        return [
          'patients',
          patientRef.id,
          'account',
          'invoices',
          invoice.ref.id,
        ];
      })
    );
  }
}

interface IInvoicePaymentSummary {
  totalInvoiced: number;
  healthFundClaims: number;
  payments: number;
  accountCredits: number;
  discounts: number;
  refunds: number;
  totalReceived: number;
  balance: number;
}

function invoicePaymentSummary(
  invoice: WithRef<IInvoice>,
  rawTransactions: WithRef<ITransaction>[]
): IInvoicePaymentSummary {
  const transactions = new TransactionOperators(rawTransactions);

  const healthFundClaims = transactions
    .byProvider(CLAIM_PROVIDERS)
    .paidToDate();
  const accountCredits = transactions
    .byProvider(TransactionProvider.AccountCredit)
    .paidToDate();
  const discounts = transactions.discounts();
  const payments =
    transactions.paidToDate() - healthFundClaims - accountCredits - discounts;

  const refunds = transactions.outgoing().sum();
  const totalReceived = transactions.paymentsReceived();

  return {
    totalInvoiced: Invoice.total(invoice),
    healthFundClaims,
    payments,
    accountCredits,
    discounts,
    refunds,
    totalReceived,
    balance: Invoice.balance(invoice, rawTransactions),
  };
}
