import {
  CustomMappingType,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { multiMap, type IReffable } from '@principle-theorem/shared';
import { sortBy, uniqBy } from 'lodash';
import { scan } from 'rxjs/operators';
import { CustomMapping } from '../../../custom-mapping';
import {
  BaseReferralSourceMappingHandler,
  IBaseMigrationReferralSource,
} from '../../../mappings/base-referral-source';
import {
  ContactSourceEntity,
  type ICorePracticeContact,
} from '../../source/entities/contacts';

export const CONTACT_SOURCE_CUSTOM_RESOURCE_TYPE = 'contacts';

export const CONTACT_SOURCES_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Contacts Mapping',
    description: `This allows us to map Core Practice's contacts to patient referral source`,
    type: CONTACT_SOURCE_CUSTOM_RESOURCE_TYPE,
  },
  type: CustomMappingType.DocumentReference,
  labelOverrides: {
    sourceIdentifier: 'Id',
    sourceLabel: 'Contact Name',
    destinationIdentifier: 'Referral Source',
  },
});

export class CorePracticeContactMappingHandler extends BaseReferralSourceMappingHandler<
  ICorePracticeContact,
  ContactSourceEntity
> {
  override customMapping = CONTACT_SOURCES_MAPPING;
  sourceEntity = new ContactSourceEntity();

  translateFn = (
    record: ICorePracticeContact
  ): IBaseMigrationReferralSource => {
    return {
      ...record,
      id: record.id.toString(),
    };
  };

  override async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 1000)
      .pipe(
        multiMap((record) => record.data.data),
        scan(
          (sources, newSources) => [...sources, ...newSources],
          [] as ICorePracticeContact[]
        )
      )
      .toPromise();

    const options = uniqBy(
      records.map((source) => ({
        label: this.sourceEntity.getSourceLabel(source),
        value: source.id.toString(),
      })),
      (option) => option.value
    );

    return sortBy(options, 'label');
  }
}
