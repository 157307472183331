import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pr-task-priority',
  templateUrl: './task-priority.component.html',
  styleUrls: ['./task-priority.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskPriorityComponent {
  @Input() priority: string;
}
