import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { type IPractice } from '@principle-theorem/principle-core/interfaces';
import {
  serialise,
  type ITimePeriod,
  type SerialisedData,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { setDateRange, setPractice } from '../actions/query.actions';

export interface IReconciliatonQueryState {
  range: ITimePeriod;
  practice?: WithRef<IPractice>;
}

const initialState: IReconciliatonQueryState = {
  range: {
    from: moment().startOf('day'),
    to: moment().endOf('day'),
  },
};

export const queryReducer: ActionReducer<
  SerialisedData<IReconciliatonQueryState>,
  Action
> = createReducer(
  serialise(initialState),

  on(setDateRange, (state, action) => ({
    ...state,
    range: action.range,
  })),

  on(setPractice, (state, action) => ({
    ...state,
    practice: action.practice,
  }))
);
