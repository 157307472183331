import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ChartFacade,
  ChartId,
  FeeScheduleFacade,
  TreatmentPlanFacade,
  TreatmentStepsFacade,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  CurrentAppointmentScope,
  CurrentPatientScope,
} from '@principle-theorem/ng-principle-shared';
import {
  type IAppointment,
  type IClinicalChart,
  type IFeeSchedule,
  type IPatient,
  type IStaffer,
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

@Component({
    selector: 'pr-edit-todays-appointment-dialog',
    templateUrl: './edit-todays-appointment-dialog.component.html',
    styleUrls: ['./edit-todays-appointment-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditTodaysAppointmentDialogComponent {
  patient$: Observable<WithRef<IPatient>>;
  appointment$: Observable<WithRef<IAppointment>>;
  chart$: Observable<WithRef<IClinicalChart> | IClinicalChart>;
  plan$: Observable<WithRef<ITreatmentPlan>>;
  step$: Observable<WithRef<ITreatmentStep>>;

  constructor(
    private _patientScope: CurrentPatientScope,
    private _chartState: ChartFacade,
    private _treatmentPlanFacade: TreatmentPlanFacade,
    private _treatmentStepsFacade: TreatmentStepsFacade,
    private _feeScheduleStore: FeeScheduleFacade,
    private _currentAppointment: CurrentAppointmentScope
  ) {
    this.patient$ = this._patientScope.asObservable();
    this.appointment$ = this._currentAppointment.doc$.pipe(filterUndefined());
    this.chart$ = this._chartState.clinicalChartState$(ChartId.InAppointment);
    this.plan$ = this._treatmentPlanFacade.selectedTreatmentPlan$.pipe(
      filterUndefined()
    );
    this.step$ = this._treatmentStepsFacade.selectedTreatmentStep$.pipe(
      filterUndefined()
    );
  }

  setChartingAs(chartingAs: WithRef<IStaffer>): void {
    if (chartingAs) {
      this._chartState.setChartingAs(ChartId.InAppointment, chartingAs);
    }
  }

  setFeeSchedule(feeSchedule: WithRef<IFeeSchedule>): void {
    this._feeScheduleStore.selectFeeSchedule(feeSchedule.ref);
  }
}
