import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Signal,
  signal,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { NgCustomFormsModule } from '@principle-theorem/ng-custom-forms';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { getCustomSubmittedForm } from '@principle-theorem/principle-core';
import {
  IPatient,
  IPatientPortalForm,
  IReferralSourceConfiguration,
  PatientFormType,
  PatientHealthCardType,
  PatientOptionalField,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { PatientPortalFunctionsService } from '../../patient-portal-functions.service';
import { PatientPortalLoaderComponent } from '../patient-portal-loader/patient-portal-loader.component';
import { PatientPortalSplashComponent } from '../patient-portal-splash/patient-portal-splash.component';
import { PatientPortalUI } from '../../lib/patient-portal-ui';

export interface IPatientFormEditDialogRequest {
  tokenUid: string;
  selectedForm: IPatientPortalForm;
  referralSources: WithRef<IReferralSourceConfiguration>[];
  requiredFields: PatientOptionalField[];
  healthCardTypes: PatientHealthCardType[];
}

export interface IPatientFormEditDialogResponse {
  submittedFormUid: string;
}

@Component({
    selector: 'pr-patient-form-edit-dialog',
    templateUrl: './patient-form-edit-dialog.component.html',
    styleUrls: ['./patient-form-edit-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        RouterModule,
        NgMaterialModule,
        NgSharedModule,
        NgCustomFormsModule,
        PatientPortalLoaderComponent,
        PatientPortalSplashComponent,
    ],
    providers: [PatientPortalFunctionsService]
})
export class PatientFormEditDialogComponent {
  private _functions = inject(PatientPortalFunctionsService);
  private _dialogRef = inject(
    MatDialogRef<
      PatientFormEditDialogComponent,
      IPatientFormEditDialogResponse | undefined
    >
  );
  data = inject<IPatientFormEditDialogRequest>(MAT_DIALOG_DATA);
  isPatientDetailsForm: boolean;
  customFormState = signal<object | undefined>(undefined);
  customFormIsValid = signal<boolean>(false);
  patientDetailsFormData = signal<Partial<IPatient> | undefined>(undefined);
  submitting = signal<boolean>(false);
  error = signal<string | undefined>(undefined);
  submitDisabled: Signal<boolean>;

  constructor() {
    this.isPatientDetailsForm =
      this.data.selectedForm.type === PatientFormType.PatientDetailsForm;

    this.submitDisabled = computed(() =>
      this.isPatientDetailsForm
        ? !this.patientDetailsFormData()
        : !this.customFormIsValid()
    );
  }

  async submit(): Promise<void> {
    this._submitting();
    try {
      const submitted = this.isPatientDetailsForm
        ? await this._submitPatientDetails()
        : await this._submitCustomForm();
      return submitted ? this._success() : this._reset();
    } catch (error) {
      return this._error(PatientPortalUI.getErrorMessage(error));
    }
  }

  private async _submitPatientDetails(): Promise<boolean> {
    const formData = this.patientDetailsFormData();
    if (!formData) {
      return false;
    }
    const data = getCustomSubmittedForm({}, formData);
    await this._functions.submitPatientDetailsForm(this.data.tokenUid, data);
    return true;
  }

  private async _submitCustomForm(): Promise<boolean> {
    const formData = this.customFormState();
    if (!this.customFormIsValid() || !formData) {
      return false;
    }
    const data = getCustomSubmittedForm(
      this.data.selectedForm.data ?? {},
      formData
    );
    await this._functions.submitPatientForm(
      this.data.tokenUid,
      this.data.selectedForm.uid,
      data
    );
    return true;
  }

  private _reset(): void {
    this.submitting.set(false);
    this.error.set(undefined);
  }

  private _submitting(): void {
    this.submitting.set(true);
    this.error.set(undefined);
  }

  private _success(): void {
    this.submitting.set(false);
    this._dialogRef.close({ submittedFormUid: this.data.selectedForm.uid });
  }

  private _error(message: string): void {
    this.submitting.set(false);
    this.error.set(message);
  }
}
