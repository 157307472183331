<div class="rounded border border-solid border-slate-200 bg-white px-4 py-2">
  <pr-appointment-history-card-header [appointment]="appointment$ | async" />

  <div class="mb-2 grid grid-cols-12">
    <div
      class="col-span-full auto-cols-max border-r border-gray-200 md:col-span-6 xl:col-span-4"
    >
      <div
        class="flex h-full flex-col items-stretch gap-8 overflow-hidden border-solid border-slate-300 pt-4 md:border-r md:pr-4"
      >
        <div class="flex">
          <ng-container *ngTemplateOutlet="treatments" />
        </div>
        <div class="flex md:hidden">
          <ng-container *ngTemplateOutlet="invoice" />
        </div>
        <div class="flex xl:hidden">
          <ng-container *ngTemplateOutlet="automations" />
        </div>
        <div class="flex xl:hidden">
          <ng-container *ngTemplateOutlet="checklist" />
        </div>
        <div class="flex flex-auto md:hidden">
          <ng-container *ngTemplateOutlet="notes" />
        </div>
      </div>
    </div>

    <div
      class="hidden auto-cols-max border-solid border-slate-300 md:col-span-6 md:flex md:p-4 xl:col-span-3 xl:border-r"
    >
      <div
        class="flex h-full flex-auto flex-col items-stretch gap-8 overflow-hidden"
      >
        <div class="flex">
          <ng-container *ngTemplateOutlet="invoice" />
        </div>
        <div class="hidden xl:flex">
          <ng-container *ngTemplateOutlet="automations" />
        </div>
        <div class="hidden xl:flex">
          <ng-container *ngTemplateOutlet="checklist" />
        </div>
        <div class="flex flex-auto xl:hidden">
          <ng-container *ngTemplateOutlet="notes" />
        </div>
      </div>
    </div>

    <div class="hidden auto-cols-max md:p-4 xl:col-span-5 xl:flex">
      <div
        class="flex h-full flex-auto flex-col items-stretch gap-8 overflow-hidden"
      >
        <div class="flex flex-auto">
          <ng-container *ngTemplateOutlet="notes" />
        </div>
      </div>
    </div>
  </div>

  <ng-template #treatments>
    <pr-appointment-history-card-treatments
      [appointment]="appointment$ | async"
      class="w-full"
    />
  </ng-template>

  <ng-template #automations>
    <pr-appointment-history-card-automations
      [appointment]="appointment$ | async"
      class="w-full"
    />
  </ng-template>

  <ng-template #checklist>
    <pr-appointment-history-card-checklist
      [appointment]="appointment$ | async"
      class="w-full"
    />
  </ng-template>

  <ng-template #invoice>
    <pr-appointment-history-card-invoice
      [appointment]="appointment$ | async"
      class="w-full"
    />
  </ng-template>

  <ng-template #notes>
    <pr-appointment-history-card-notes
      [appointment]="appointment$ | async"
      class="w-full"
    />
  </ng-template>
</div>
