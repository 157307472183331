import { Media, Patient } from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  MergeConflictDestinationEntityRecord,
  SkippedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityJobRunOptions,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IMedia,
  type IPatient,
  type IPracticeMigration,
  type ISourceEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  asyncForEach,
  getError,
  toTimestamp,
  type DocumentReference,
  type WithRef,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { CopyFiles, StorageFile, getBucketStoragePath } from '../../../storage';
import { TranslationMapHandler } from '../../../translation-map';
import {
  PatientSourceEntity,
  type IOasisPatient,
  type IOasisPatientFilters,
  type IOasisPatientTranslations,
} from '../../source/entities/patients';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import { PatientDestinationEntity } from './patients';

export const PATIENT_DOCUMENTS_RESOURCE_TYPE = 'patientDocuments';

export const PATIENT_DOCUMENTS_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_DOCUMENTS_RESOURCE_TYPE,
    label: 'Patient Documents',
    description: ``,
  },
});

export interface IPatientFileDestinationRecord {
  fileRefs: DocumentReference<IMedia>[];
}

export interface IPatientDocumentJobData {
  sourcePatient: IGetRecordResponse<
    IOasisPatient,
    IOasisPatientTranslations,
    IOasisPatientFilters
  >;
}

export interface IPatientFileMigrationData {
  sourcePatientId: string;
  patientRef: DocumentReference<IPatient>;
  documents: IPatientFileData[];
}

export interface IPatientFileData {
  name: string;
  filePath: string;
  createdAt: Timestamp;
}

export class PatientDocumentDestinationEntity extends BaseDestinationEntity<
  IPatientFileDestinationRecord,
  IPatientDocumentJobData,
  IPatientFileMigrationData
> {
  destinationEntity = PATIENT_DOCUMENTS_DESTINATION_ENTITY;
  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  override filters = [
    new PatientIdFilter<IPatientDocumentJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  sourceCountDataAccessor(
    data: IPatientDocumentJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getDestinationEntityRecordUid(data: IPatientDocumentJobData): string {
    return data.sourcePatient.record.uid;
  }

  hasMergeConflict(
    _translationMap: TranslationMapHandler,
    _data: IPatientFileMigrationData
  ): IPatientFileMigrationData | undefined {
    return;
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientDocumentJobData,
    _migrationData: IPatientFileMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientDocumentJobData[]> {
    return this.buildSourceRecordQuery$(
      migration,
      this.sourceEntities.patients,
      runOptions
    ).pipe(
      map((sourcePatients) =>
        sourcePatients.map((sourcePatient) => ({
          sourcePatient,
          destinationEntity,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientDocumentJobData
  ): Promise<
    | IPatientFileMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
    | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.id.toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `Couldn't resolve patient`
      );
    }

    const documents = await this.getPatientFiles(migration, sourcePatientId);
    if (!documents.length) {
      return this._buildSkippedResponse(data.sourcePatient);
    }

    return {
      sourcePatientId,
      patientRef,
      documents,
    };
  }

  async getPatientFiles(
    migration: WithRef<IPracticeMigration>,
    patientId: string
  ): Promise<IPatientFileData[]> {
    const splitId = patientId.split('');
    while (splitId.length < 6) {
      splitId.unshift('0');
    }
    const patientFolderPath = splitId.join('/');

    const projectId = migration.configuration.projectId;
    const bucketName = getBucketStoragePath(migration.source);
    const bucketPath = `files/${patientFolderPath}`;

    const storage = new CopyFiles(bucketName, bucketPath, '', '', projectId)
      .storage;

    const [files] = await storage.bucket(bucketName).getFiles({
      prefix: bucketPath,
    });

    return files.map((file) => ({
      name: file.name.split('/').pop() ?? file.name,
      filePath: file.name,
      createdAt: toTimestamp(),
    }));
  }

  async runJob(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    jobData: IPatientDocumentJobData,
    migrationData: IPatientFileMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const fileRefs = await this.addMediaToPatient(
        migration,
        translationMapHandler,
        migrationData
      );
      return this._buildSuccessResponse(jobData.sourcePatient, fileRefs);
    } catch (error) {
      return this._buildErrorResponse(jobData.sourcePatient, getError(error));
    }
  }

  async addMediaToPatient(
    migration: WithRef<IPracticeMigration>,
    translationMap: TranslationMapHandler,
    migrationData: IPatientFileMigrationData
  ): Promise<DocumentReference<IMedia>[]> {
    const copyStorage = this.getCopyStorage(
      getBucketStoragePath(migration.source),
      migrationData.patientRef,
      migration.configuration.projectId,
      migration.configuration.destinationBucket
    );

    return asyncForEach(migrationData.documents, async (document) => {
      const filePath = await copyStorage.copy(document.filePath);
      if (!filePath) {
        throw new Error(`Failed to copy file: ${document.filePath}`);
      }

      const fileDestinationRef = await translationMap.getDestination(
        document.filePath,
        PATIENT_DOCUMENTS_RESOURCE_TYPE
      );

      const mediaRef = await FirestoreMigrate.upsertDoc(
        Patient.mediaCol({ ref: migrationData.patientRef }),
        {
          ...Media.init({
            name: document.name,
            path: filePath,
          }),
          createdAt: document.createdAt,
          deleted: false,
        },
        fileDestinationRef?.id
      );

      if (!fileDestinationRef) {
        await translationMap.upsert({
          sourceIdentifier: document.filePath,
          destinationIdentifier: mediaRef,
          resourceType: PATIENT_DOCUMENTS_RESOURCE_TYPE,
        });
      }

      return mediaRef;
    });
  }

  getCopyStorage(
    sourceBucket: string,
    patientRef: DocumentReference<IPatient>,
    projectId: string,
    destinationBucket: string
  ): StorageFile {
    return new StorageFile(
      sourceBucket,
      destinationBucket,
      Patient.storagePath({ ref: patientRef }),
      projectId
    );
  }

  private _buildErrorResponse(
    sourcePatient: IGetRecordResponse<IOasisPatient, IOasisPatientTranslations>,
    errorMessage: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: sourcePatient.record.ref,
      errorMessage,
      failData: {
        patientRef: sourcePatient.record.ref,
      },
    };
  }

  private _buildSkippedResponse(
    sourcePatient: IGetRecordResponse<IOasisPatient, IOasisPatientTranslations>
  ): IDestinationEntityRecord & SkippedDestinationEntityRecord {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      status: DestinationEntityRecordStatus.Skipped,
      sourceRef: sourcePatient.record.ref,
    };
  }

  private _buildSuccessResponse(
    sourcePatient: IGetRecordResponse<IOasisPatient, IOasisPatientTranslations>,
    fileRefs: DocumentReference<IMedia>[]
  ): IDestinationEntityRecord<IPatientFileDestinationRecord> {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      data: {
        fileRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: sourcePatient.record.ref,
      migratedAt: toTimestamp(),
    };
  }
}
