<form
  [formGroup]="noteForm"
  novalidate
  (ngSubmit)="onSubmit()"
  fxLayout="column"
>
  <mat-form-field fxFlex>
    <mat-label>Add Note</mat-label>
    <pr-content-editor
      [menuEnabled]="false"
      formControlName="content"
      (keydown.meta.Enter)="onSubmit()"
     />
  </mat-form-field>
</form>

<pt-buttons-container>
  <button
    mat-flat-button
    color="primary"
    [disabled]="buttonDisabled$ | async"
    (click)="onSubmit()"
  >
    Add Note
  </button>
</pt-buttons-container>
