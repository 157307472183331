import {
  DEFAULT_DATA_UID,
  DestinationEntityRecordCollection,
  IDestinationEntityRecord,
  IDestinationEntityRecordData,
  IDestinationEntityRecordFile,
  JOB_DATA_UID,
  MODIFIED_DATA_UID,
  PRINCIPLE_DIFF_DATA_UID,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  all$,
  doc,
  errorNil,
  subCollection,
  unserialise,
  type CollectionReference,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { from, of, type Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export class DestinationEntityRecord {
  static getLatestData$<T extends object = object>(
    record: IReffable<IDestinationEntityRecord<T>>
  ): Observable<WithRef<IDestinationEntityRecordData<T>>> {
    return from(
      Firestore.getDoc(
        doc(DestinationEntityRecord.dataCol<T>(record), MODIFIED_DATA_UID)
      )
    ).pipe(
      switchMap((result) =>
        result
          ? of(result)
          : Firestore.getDoc(
              doc(DestinationEntityRecord.dataCol<T>(record), DEFAULT_DATA_UID)
            )
      ),
      catchError(() =>
        from(
          Firestore.getDoc(
            doc(DestinationEntityRecord.dataCol<T>(record), DEFAULT_DATA_UID)
          )
        )
      ),
      errorNil(
        `DestinationEntityRecord.getLatestData$ - No data found for record ${record.ref.path}`
      ),
      switchMap(DestinationEntityRecord.resolveRecordData)
    );
  }

  static getDiffData$<T extends object = object>(
    record: IReffable<IDestinationEntityRecord<T>>
  ): Observable<WithRef<IDestinationEntityRecordData<T>> | undefined> {
    return from(
      Firestore.getDoc(
        doc(DestinationEntityRecord.dataCol<T>(record), PRINCIPLE_DIFF_DATA_UID)
      )
    ).pipe(
      errorNil(
        `DestinationEntityRecord.getDiffData$ - No data found for record ${record.ref.path}`
      ),
      switchMap(DestinationEntityRecord.resolveRecordData),
      catchError(() => of(undefined))
    );
  }

  static getJobLatestData$<T extends object = object>(
    record: IReffable<IDestinationEntityRecord>
  ): Observable<WithRef<IDestinationEntityRecordData<T>>> {
    return from(
      Firestore.getDoc(
        doc(DestinationEntityRecord.dataCol<T>(record), JOB_DATA_UID)
      )
    ).pipe(
      errorNil(
        `DestinationEntityRecord.getJobLatestData$ - No data found for record ${record.ref.path}`
      ),
      switchMap(DestinationEntityRecord.resolveRecordData)
    );
  }

  static dataCol<T extends object = object>(
    record: IReffable<IDestinationEntityRecord>
  ): CollectionReference<
    IDestinationEntityRecordData<T> | IDestinationEntityRecordFile
  > {
    return subCollection<
      IDestinationEntityRecordData<T> | IDestinationEntityRecordFile
    >(record.ref, DestinationEntityRecordCollection.Data);
  }

  static data$<T extends object = object>(
    record: IReffable<IDestinationEntityRecord>
  ): Observable<
    WithRef<IDestinationEntityRecordData<T> | IDestinationEntityRecordFile>[]
  > {
    return all$(DestinationEntityRecord.dataCol(record));
  }

  static buildData(record: object, uid: string): IDestinationEntityRecordData {
    return {
      uid,
      data: record,
      type: 'jsonSerialisable',
    };
  }

  static async resolveRecordData<T extends object>(
    data: WithRef<
      IDestinationEntityRecordData<T> | IDestinationEntityRecordFile
    >
  ): Promise<WithRef<IDestinationEntityRecordData<T>>> {
    if (data.type === 'jsonSerialisable') {
      return data as WithRef<IDestinationEntityRecordData<T>>;
    }

    const file = await fetch(data.url);
    const fileJson = (await file.json()) as unknown;
    return {
      ...data,
      data: unserialise(fileJson),
      type: 'jsonSerialisable',
    } as WithRef<IDestinationEntityRecordData<T>>;
  }
}
