import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import { FormsDashboardComponent } from './components/forms-dashboard/forms-dashboard.component';
import { MedicalHistoryFormPrintComponent } from './components/medical-history-form-print/medical-history-form-print.component';
import { MedicalHistoryFormComponent } from './components/medical-history-form/medical-history-form.component';
import { MedicalHistoryListComponent } from './components/medical-history-list/medical-history-list.component';
import { PatientDetailsHistoryListComponent } from './components/patient-details-history-list/patient-details-history-list.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: FormsDashboardComponent,
        data: { titleSuffix: 'Submitted Forms' },
      },
      {
        path: 'medical-history-form',
        data: {
          permissions: [PatientPermissions.MedicalHistoryManage],
        },
        children: [
          {
            path: '',
            component: MedicalHistoryListComponent,
            data: { titleSuffix: 'Medical History' },
          },
          {
            path: 'update',
            component: MedicalHistoryFormComponent,
            data: { titleSuffix: 'Update Medical History' },
          },
          {
            path: 'print',
            component: MedicalHistoryFormPrintComponent,
          },
        ],
      },
      {
        path: 'patient-details-form',
        data: {
          permissions: [PatientPermissions.PatientManage],
        },
        children: [
          {
            path: '',
            component: PatientDetailsHistoryListComponent,
            data: { titleSuffix: 'Patient Details' },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PatientFormsRoutingModule {}
