import { TypeGuard, isEnumValue, isObject } from '@principle-theorem/shared';
import { isNumber } from 'lodash';
import {
  type IPraktikaToothCondition,
  isPraktikaToothCondition,
} from '../patient-tooth-conditions';

export interface IPraktikaPatientClinicalChartResponse {
  patient_dentition: IPraktikaPatientClinicalChart[];
}

export interface IPraktikaPatientClinicalChart {
  id: number;
  typeId: PraktikaToothType;
  toothNumber: number;
  conditions: IPraktikaToothCondition[];
}

export enum PraktikaToothType {
  Permanent = 1,
  Primary = 2,
  Missing = 3,
  Implant = 4,
  Pontic = 5,
  Denture = 6,
  ImplantDenture = 7,
  Supernumerary = 8,
}

export function isPraktikaClinicalChart(
  item: unknown
): item is IPraktikaPatientClinicalChart {
  return (
    isObject(item) &&
    isEnumValue(PraktikaToothType, item.typeId) &&
    isNumber(item.toothNumber) &&
    TypeGuard.arrayOf(isPraktikaToothCondition)(item.conditions)
  );
}
