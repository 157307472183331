import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Appointment, WaitListScore } from '@principle-theorem/principle-core';
import {
  IPatient,
  IScheduleSummaryEventable,
  ITypesenseWaitListWithRef,
  IWaitListCandidate,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, snapshot } from '@principle-theorem/shared';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GapCandidateShortlistActionsService } from '../../gap-candidate-shortlist-actions.service';

@Component({
  selector: 'pr-gap-waitlist-item',
  templateUrl: './gap-waitlist-item.component.html',
  styleUrls: ['./gap-waitlist-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GapWaitlistItemComponent {
  candidate$ = new ReplaySubject<IWaitListCandidate>(1);
  gap$ = new ReplaySubject<IScheduleSummaryEventable>(1);
  patient$: Observable<WithRef<IPatient>>;
  appointment$: Observable<ITypesenseWaitListWithRef>;
  score$: Observable<number>;

  @Input()
  set waitListCandidate(candidate: IWaitListCandidate) {
    if (candidate) {
      this.candidate$.next(candidate);
    }
  }

  @Input()
  set gap(gap: IScheduleSummaryEventable) {
    if (gap) {
      this.gap$.next(gap);
    }
  }

  constructor(
    private _candidateActions: GapCandidateShortlistActionsService,
    private _snackBar: MatSnackBar
  ) {
    this.appointment$ = this.candidate$.pipe(
      map((candidate) => candidate.appointment)
    );
    this.patient$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.patient(appointment))
    );
    this.score$ = combineLatest([this.candidate$, this.gap$]).pipe(
      map(([candidate, gap]) =>
        WaitListScore.getMatchScore(candidate.appointment, gap.event)
      )
    );
  }

  async openWaitlistConfiguration(): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    await this._candidateActions.openWaitlistConfiguration(appointment);
  }

  async showSchedulingNotes(): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    const patient = await snapshot(this.patient$);
    this._candidateActions.showSchedulingNotes(appointment, patient);
  }

  async addToShortlist(): Promise<void> {
    const candidate = await snapshot(this.candidate$);
    const gap = await snapshot(this.gap$);
    await this._candidateActions.processWaitlistCandidate(
      candidate,
      gap,
      async () => {
        await this._candidateActions.addCandidateToShortlist(candidate, gap);
        this._snackBar.open(
          `${candidate.candidate.patient.name} added to shortlist`
        );
      }
    );
  }

  async confirmForGap(): Promise<void> {
    const candidate = await snapshot(this.candidate$);
    const gap = await snapshot(this.gap$);
    await this._candidateActions.processWaitlistCandidate(
      candidate,
      gap,
      async () => {
        const candidateEvent =
          await this._candidateActions.addCandidateToShortlist(candidate, gap);
        await this._candidateActions.openMoveAppointment(candidateEvent, gap);
      }
    );
  }
}
