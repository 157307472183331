import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { IResolvedClaimItem } from '@principle-theorem/principle-core';
import { ReplaySubject } from 'rxjs';
import { ClaimItemsInfoBloc } from './claim-items-info-bloc';

@Component({
    selector: 'pr-healthcare-claim-items-summary',
    templateUrl: './healthcare-claim-items-summary.component.html',
    styleUrls: ['./healthcare-claim-items-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HealthcareClaimItemsSummaryComponent {
  private _items$ = new ReplaySubject<IResolvedClaimItem[]>(1);
  bloc = new ClaimItemsInfoBloc(this._items$);
  trackByClaimItem = TrackByFunctions.nestedField<IResolvedClaimItem>(
    'claimItem.serviceCode.uid'
  );

  @Input()
  set items(items: IResolvedClaimItem[]) {
    this._items$.next(items ?? []);
  }
}
