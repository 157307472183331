import { switchMapNew } from '@principle-theorem/shared';
import {
  type StripeCardElement,
  type StripeElementChangeEvent,
  type StripeError,
} from '@stripe/stripe-js';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class StripeCardEvents {
  onReady$: Observable<void>;
  onFocus$: Observable<void>;
  onBlur$: Observable<void>;
  onEscape$: Observable<void>;
  onChange$: Observable<StripeElementChangeEvent>;
  onError$: Observable<StripeError | undefined>;

  constructor(card$: Observable<StripeCardElement>) {
    this.onReady$ = card$.pipe(
      switchMapNew((card, sub) => card.on('ready', () => sub.next()))
    );
    this.onFocus$ = card$.pipe(
      switchMapNew((card, sub) => card.on('focus', () => sub.next()))
    );
    this.onBlur$ = card$.pipe(
      switchMapNew((card, sub) => card.on('blur', () => sub.next()))
    );
    this.onEscape$ = card$.pipe(
      switchMapNew((card, sub) => card.on('escape', () => sub.next()))
    );
    this.onChange$ = card$.pipe(
      switchMapNew((card, sub) => card.on('change', (event) => sub.next(event)))
    );
    this.onError$ = this.onChange$.pipe(map((event) => event.error));
  }
}
