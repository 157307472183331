import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import {
  OrganisationService,
  StafferSettingsStoreService,
} from '@principle-theorem/ng-principle-shared';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  type ITimelineStafferDisplayOptions,
  type PracticeTimelineTooltipDelay,
  PRACTICE_TIMELINE_TOOLTIP_DELAY_OPTIONS,
  TimelineDisplayOrientation,
  TimelineDisplayZoom,
} from '@principle-theorem/principle-core/interfaces';
import { getEnumValues, isChanged$ } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-timeline-user-settings',
    templateUrl: './timeline-user-settings.component.html',
    styleUrls: ['./timeline-user-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TimelineUserSettingsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  stepSizesInMins: number[] = [5, 10, 15, 20];
  tooltipDelays: PracticeTimelineTooltipDelay[] =
    PRACTICE_TIMELINE_TOOLTIP_DELAY_OPTIONS;
  timelineOrientations = getEnumValues(TimelineDisplayOrientation);
  zoomOptions = getEnumValues(TimelineDisplayZoom);

  stafferViewSettingsForm = new TypedFormGroup<ITimelineStafferDisplayOptions>({
    orientation: new TypedFormControl<TimelineDisplayOrientation>(),
    zoom: new TypedFormControl<TimelineDisplayZoom>(),
    thickTracks: new TypedFormControl<boolean>(),
    showGridlines: new TypedFormControl<boolean>(),
    hideEmptyDays: new TypedFormControl<boolean>(),
    hideCancelledAppointments: new TypedFormControl<boolean>(),
  });

  constructor(
    private _organisation: OrganisationService,
    public timelineStore: StafferSettingsStoreService
  ) {
    this._organisation.stafferSettings$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((settings) => {
        if (!settings?.timeline) {
          return;
        }
        this.stafferViewSettingsForm.patchValue(settings.timeline, {
          emitEvent: false,
        });
      });

    this.stafferViewSettingsForm.valueChanges
      .pipe(isChanged$(), takeUntil(this._onDestroy$))
      .subscribe((settings) =>
        this.timelineStore.updateStafferSettings({
          timeline: settings,
        })
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
