import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { CurrentPatientScope } from '@principle-theorem/ng-principle-shared';
import { AbstractResolver } from '@principle-theorem/ng-shared';
import { Appointment } from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { getDoc$, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

@Injectable()
export class AppointmentResolverService extends AbstractResolver<
  WithRef<IAppointment>
> {
  constructor(router: Router) {
    super(router);
  }

  getItemById(
    uid: string,
    route: ActivatedRouteSnapshot
  ): Observable<WithRef<IAppointment>> {
    const patientScope: CurrentPatientScope = new CurrentPatientScope(
      this._router
    );
    const patient: WithRef<IPatient> | undefined =
      patientScope.findModel(route);

    if (!patient) {
      throw new Error('No Patient found');
    }

    return getDoc$(Appointment.col(patient), uid);
  }
}
