<mat-toolbar color="accent">Clinical Note Edit History</mat-toolbar>

<mat-dialog-content class="overflow-y-scroll">
  @if (data.history; as history) {
    <div class="space-y-4 p-4">
      @for (note of history; track note.ref.id; let isLast = $last) {
        <div class="mb-4 flex justify-between gap-4">
          <div>
            <h3 class="!mb-0 !font-bold">{{ note.owner.name }}</h3>

            <p class="mat-caption">
              Edited At
              <span class="opacity-50">
                {{ note.archivedAt | moment | amDateFormat: dateFormat }}
              </span>
            </p>
          </div>
        </div>
        <pr-content-view class="whitespace-pre" [content]="note.content" />
        <mat-divider *ngIf="!isLast" />
      }
    </div>
  }
</mat-dialog-content>

<mat-dialog-actions class="sticky bottom-0 z-10 bg-white">
  <mat-divider />
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close cdkFocusInitial>Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
