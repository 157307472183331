import { ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { getSchemaText } from '@principle-theorem/editor';
import { ClinicalNotesHistoryDialogComponent } from '@principle-theorem/ng-clinical-charting';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { Appointment, ClinicalNote } from '@principle-theorem/principle-core';
import {
  IAppointment,
  IClinicalNote,
  IPatient,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  WithRef,
  firstValueFrom,
  toISODate,
} from '@principle-theorem/shared';
import { trim } from 'lodash';
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppointmentInteractionsDialogComponent } from '../../appointment-interactions-dialog/appointment-interactions-dialog.component';

@Component({
  selector: 'pr-appointment-history-card-notes',
  templateUrl: './appointment-history-card-notes.component.html',
  styleUrls: ['./appointment-history-card-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentHistoryCardNotesComponent {
  appointment$ = new ReplaySubject<WithRef<IAppointment>>(1);
  patient$: Observable<WithRef<IPatient>>;
  appointmentDate$: Observable<Timestamp | undefined>;
  practitioner$: Observable<WithRef<IStaffer> | undefined>;
  clinicalNote$: Observable<WithRef<IClinicalNote> | undefined>;
  hasClinicalNote$: Observable<boolean>;

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor(private _dialog: MatDialog) {
    this.patient$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.patient$(appointment))
    );
    this.practitioner$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.practitioner$(appointment))
    );
    this.clinicalNote$ = this._getClinicalNote$(
      this.patient$,
      this.practitioner$,
      this.appointment$
    );
    this.hasClinicalNote$ = this.clinicalNote$.pipe(
      map((clinicalNote) => {
        if (!clinicalNote) {
          return false;
        }
        const content = trim(getSchemaText(clinicalNote.content));
        return content.length > 0;
      })
    );
  }

  async openSchedulingNotes(): Promise<void> {
    const patient = await firstValueFrom(this.patient$);
    const appointment = await firstValueFrom(this.appointment$);
    if (!patient || !appointment) {
      return;
    }
    const config = DialogPresets.large({
      height: '80%',
      data: { appointment, patient },
    });
    this._openNotesDialog(AppointmentInteractionsDialogComponent, config);
  }

  async openClinicalNotes(): Promise<void> {
    const patient = await firstValueFrom(this.patient$);
    if (!patient) {
      return;
    }
    const config: MatDialogConfig = DialogPresets.large({
      height: '80%',
      data: { patient },
    });
    this._dialog.closeAll();
    this._openNotesDialog(ClinicalNotesHistoryDialogComponent, config);
  }

  private _openNotesDialog<T>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config: MatDialogConfig
  ): void {
    this._dialog.open<T>(componentOrTemplateRef, config);
  }

  private _getClinicalNote$(
    patient$: Observable<WithRef<IPatient>>,
    chartingAs$: Observable<WithRef<IStaffer> | undefined>,
    appointment$: Observable<WithRef<IAppointment>>
  ): Observable<WithRef<IClinicalNote> | undefined> {
    const appointmentDate$ = appointment$.pipe(
      map((appointment) => appointment?.event?.from)
    );
    return combineLatest([patient$, chartingAs$, appointmentDate$]).pipe(
      switchMap(([patient, staffer, date]) => {
        if (!staffer || !date) {
          return of(undefined);
        }
        return ClinicalNote.getEditableNote$(patient, staffer, toISODate(date));
      })
    );
  }
}
