<form class="flex flex-row gap-4" [formGroup]="form" (ngSubmit)="submit()">
  <div class="flex flex-auto flex-col gap-4">
    <mat-slide-toggle formControlName="isEnabled">
      Enable Tyro
    </mat-slide-toggle>

    <div class="flex flex-row gap-1">
      <mat-checkbox formControlName="addCDBSAsPending"
        >Add CDBS transactions as "pending" status</mat-checkbox
      >
      <mat-icon
        class="cdbs-tooltip"
        matTooltip="This will require CDBS claims to be reconciled from the pending transactions workflow."
        >info</mat-icon
      >
    </div>
    <div>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        [disabled]="form.invalid"
      >
        Save
      </button>
    </div>
  </div>

  <div class="flex flex-col" *ptFeatureEnabled="'tyro-testing'">
    <mat-form-field mat-form-field subscriptSizing="dynamic" class="flex">
      <mat-label>Tyro Endpoint</mat-label>
      <mat-select formControlName="iClient">
        <mat-option
          *ngFor="let client of clients; trackBy: trackByIClient"
          [value]="client"
        >
          {{ client | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
