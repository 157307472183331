import { isObject, Time24hrType } from '@principle-theorem/shared';
import { EventType, isEvent, type IEvent } from '../event/event';

export enum GapAction {
  Created = 'created',
  Filling = 'filling',
  Filled = 'filled',
}

export enum GapStatus {
  Available = 'available',
  Filling = 'filling',
  Filled = 'filled',
}

export interface IGapTimeRange {
  from?: Time24hrType;
  to?: Time24hrType;
}

export interface IGapDuration {
  hours?: number;
  minutes?: number;
}

export function isGapEvent(item: unknown): item is IEvent {
  return isObject(item) && isEvent(item) && item.type === EventType.Gap;
}

export function isGapEventType(eventType: EventType): boolean {
  return [EventType.Gap].includes(eventType);
}

export function isGapCandidateEvent(item: unknown): item is IEvent {
  return (
    isObject(item) && isEvent(item) && item.type === EventType.GapCandidate
  );
}
