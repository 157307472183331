import {
  ChartableSurface,
  IChartableItem,
  IChartedRef,
} from '@principle-theorem/principle-core/interfaces';

export abstract class SurfaceHierarchy<
  T extends Partial<IChartedRef> = Partial<IChartedRef>
> {
  surfaces: ChartableSurface[] = [];
  parents: SurfaceHierarchy[] = [];

  getCompatibleSurfaces(item: IChartableItem): ChartableSurface[] {
    return this.surfaces.filter((surface) =>
      item.availableSurfaces.includes(surface)
    );
  }

  hasCompatibleSurface(item: IChartableItem): boolean {
    return item.availableSurfaces.some((surface) =>
      this.surfaces.includes(surface)
    );
  }

  abstract canBeChartedOn(
    selectedSurface: Partial<IChartedRef>
  ): selectedSurface is T;

  abstract toChartedRef(currentRef: Partial<IChartedRef>): T;
}
