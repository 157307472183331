import {
  IPatient,
  IPaymentPlan,
  PatientCollection,
  PaymentFrequency,
  PaymentPlanStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  IReffable,
  WithRef,
  all$,
  initFirestoreModel,
  subCollection,
} from '@principle-theorem/shared';
import { CollectionReference } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';

export class PaymentPlan {
  static init(
    overrides: AtLeast<IPaymentPlan, 'durationType' | 'paymentFrequency'>
  ): IPaymentPlan {
    return {
      name: '',
      durationLength: 0,
      paymentSchedule: [],
      invoices: [],
      total: 0,
      status: PaymentPlanStatus.Draft,
      statusHistory: [],
      ...initFirestoreModel(),
      ...overrides,
    };
  }

  static col(patient: IReffable<IPatient>): CollectionReference<IPaymentPlan> {
    return subCollection<IPaymentPlan>(
      patient.ref,
      PatientCollection.PaymentPlans
    );
  }

  static all$(
    patient: IReffable<IPatient>
  ): Observable<WithRef<IPaymentPlan>[]> {
    return all$(this.col(patient));
  }

  static duration(plan: IPaymentPlan): moment.Duration {
    return moment.duration({ [plan.durationType]: plan.durationLength });
  }

  /**
   * Translates the PaymentFrequency into number based on duration
   * Note: May be worth changing the type enum into something else
   * to handle this upstream
   * @returns number
   */
  static numberOfPayments(plan: IPaymentPlan): number {
    let numberOfPayments: number = Math.floor(this.duration(plan).asWeeks());
    if (plan.paymentFrequency === PaymentFrequency.Fortnightly) {
      numberOfPayments = numberOfPayments / 2;
    }
    if (plan.paymentFrequency === PaymentFrequency.Monthly) {
      numberOfPayments = this.duration(plan).asMonths();
    }
    return numberOfPayments;
  }

  /**
   * Get repayment amount
   * @returns number
   */
  static repaymentAmount(plan: IPaymentPlan): number {
    return plan.total / this.numberOfPayments(plan);
  }

  /**
   * Generate payment schedule based on duration and frequency
   * @param startDate moment.Moment
   * @returns void
   */
  // generateSchedule(startDate: moment.Moment): void {
  //   this.paymentSchedule = [];
  //   let payment: Payment = new Payment({
  //     date: startDate,
  //     amount: this.repaymentAmount,
  //   });
  //   this.paymentSchedule.push(payment);

  //   let newDate: moment.Moment = startDate.clone();

  //   for (let i: number = 0; i < this.numberOfPayments - 1; i++) {
  //     if (i > 0) {
  //       newDate = newDate.clone();
  //     }
  //     let amount: any = 1;
  //     let unit: string = 'week';
  //     if (this.paymentFrequency === PaymentFrequency.Monthly) {
  //       unit = 'month';
  //     }
  //     if (this.paymentFrequency === PaymentFrequency.Fortnightly) {
  //       amount = 2;
  //     }

  //     payment = new Payment({
  //       date: newDate.add(amount, unit),
  //       amount: this.repaymentAmount
  //     });
  //     this.paymentSchedule.push(payment);
  //   }
  // }
}
