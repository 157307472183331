import { AppointmentSummary } from '../event/schedule-summary';

export interface IAppointmentGroup {
  name: string;
  appointments: AppointmentSummary[];
}

export interface IAppointmentDateGroup {
  id: string;
  name: string;
  groups: IAppointmentGroup[];
}
