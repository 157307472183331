import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  type IPatient,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';

export interface IClinicalNoteEditData {
  patient: WithRef<IPatient>;
  date: Timestamp;
  staffer: WithRef<IStaffer>;
  displayStafferSelector: boolean;
  displayNoteHistory: boolean;
}

@Component({
  selector: 'pr-clinical-note-edit-dialog',
  templateUrl: './clinical-note-edit-dialog.component.html',
  styleUrls: ['./clinical-note-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalNoteEditDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IClinicalNoteEditData) {}
}
