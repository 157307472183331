<button
  mat-icon-button
  [matMenuTriggerFor]="moreMenu"
  matTooltip="View Settings"
  ptElementTarget="view-settings-button"
>
  <mat-icon>tune</mat-icon>
</button>
<mat-menu class="view-settings" #moreMenu="matMenu">
  <form [formGroup]="form">
    <h3 class="settings-heading">View Settings</h3>

    <pt-settings-menu-toggle-group-item
      label="Layout Type"
      formControlName="viewType"
      ptElementTarget="layout-button"
      [options]="viewTypeOptions"
     />

    <pt-settings-menu-toggle-group-item
      label="Size"
      formControlName="size"
      ptElementTarget="size-button"
      [options]="sizeOptions"
     />

    <pt-settings-menu-toggle-group-item
      label="Group By"
      formControlName="groupBy"
      ptElementTarget="group-by-button"
      [options]="groupByOptions"
     />
  </form>
</mat-menu>
