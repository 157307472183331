import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { type IGeneralTreatmentConfiguration } from '@principle-theorem/principle-core/interfaces';
import {
  type ISetOptionParams,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import { type ITreatmentCategory } from '@principle-theorem/principle-core/interfaces';
import { getDoc, Region, type WithRef } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface IConfigFormData
  extends Omit<IGeneralTreatmentConfiguration, 'category'> {
  category: WithRef<ITreatmentCategory> | undefined;
}
@Component({
    selector: 'pr-general-configuration',
    templateUrl: './general-configuration.component.html',
    styleUrls: ['./general-configuration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class GeneralConfigurationComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByRef = TrackByFunctions.ref<ITreatmentCategory>();
  configForm = new TypedFormGroup<IConfigFormData>({
    name: new TypedFormControl<string>('', Validators.required),
    duration: new TypedFormControl<number>(1, Validators.required),
    description: new TypedFormControl<string>('', Validators.required),
    category: new TypedFormControl<WithRef<ITreatmentCategory> | undefined>(),
  });
  region = Region;

  @Input() treatmentCategories: WithRef<ITreatmentCategory>[] = [];

  @Output()
  treatmentConfigChange = new EventEmitter<IGeneralTreatmentConfiguration>();

  @Input()
  set treatmentConfig(treatmentConfig: IGeneralTreatmentConfiguration) {
    if (treatmentConfig) {
      void this._updateFormValue(treatmentConfig, { emitEvent: false });
    }
  }

  constructor() {
    validFormGroupChanges$(this.configForm)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) =>
        this.treatmentConfigChange.emit({
          ...value,
          category: value.category?.ref,
        })
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  displayCategoryFn(
    category: WithRef<ITreatmentCategory> | undefined
  ): string | undefined {
    return category?.name;
  }

  clearCategory(): void {
    this.configForm.controls.category.setValue(undefined);
  }

  private async _updateFormValue(
    treatmentConfig: IGeneralTreatmentConfiguration,
    options?: ISetOptionParams
  ): Promise<void> {
    this.configForm.patchValue(
      {
        name: treatmentConfig.name,
        description: treatmentConfig.description,
        duration: treatmentConfig.duration,
      },
      options
    );
    if (!treatmentConfig.category) {
      return;
    }
    const category = await getDoc(treatmentConfig.category);
    this.configForm.patchValue({ category }, options);
  }
}
