import { AnyExtension } from '@tiptap/core';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { BlockNodes, ListNodes } from '../../available-extensions';

export function createOrderedListExtension(): AnyExtension {
  return OrderedList.extend({
    name: BlockNodes.OrderedList,
  }).configure({
    itemTypeName: ListNodes.ListItem,
  });
}
