import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  MeasureReducer,
  type ICustomReportColumn,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot, uid, type WithId } from '@principle-theorem/shared';
import { map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { ReportBuilderStore } from '../../report-builder.store';
import {
  IReportBuildColumnSelectorFormData,
  ReportBuilderColumnSelectorDialogComponent,
  type IReportBuilderColumnSelectorDialogData,
} from '../report-builder-column-selector-dialog/report-builder-column-selector-dialog.component';

@Injectable()
export class ReportBuilderColumnManager {
  constructor(
    private _store: ReportBuilderStore,
    private _dialog: MatDialog
  ) {}

  async edit(oldColumn: WithId<ICustomReportColumn>): Promise<void> {
    const newColumn = await this._openEditDialog(oldColumn);
    if (!newColumn) {
      return;
    }
    const current = await snapshot(this._store.display$);
    if (!current) {
      return;
    }

    const columns = current.columns.map((column) =>
      column.uid === oldColumn.uid
        ? {
            uid: oldColumn.uid,
            id: newColumn.measure.metadata.id,
            label:
              newColumn.label ??
              oldColumn.label ??
              newColumn.measure.metadata.label,
          }
        : column
    );
    this._store.setDisplay({ ...current, columns });
  }

  async remove(column: WithId<ICustomReportColumn>): Promise<void> {
    const current = await snapshot(this._store.display$);
    if (!current) {
      return;
    }
    const targetIndex = await this._getColumnIndex(column);
    const columns = [...current.columns];
    columns.splice(targetIndex, 1);
    this._store.setDisplay({ ...current, columns });
  }

  async addLeft(column: WithId<ICustomReportColumn>): Promise<void> {
    const targetIndex = await this._getColumnIndex(column);
    const newColumn = await this._openEditDialog();
    if (!newColumn) {
      return;
    }
    await this._addAtIndex(targetIndex, {
      uid: uid(),
      id: newColumn.measure.metadata.id,
      label: newColumn.label ?? newColumn.measure.metadata.label,
    });
  }

  async addRight(column: WithId<ICustomReportColumn>): Promise<void> {
    const targetIndex = await this._getColumnIndex(column);
    const newColumn = await this._openEditDialog();
    if (!newColumn) {
      return;
    }
    await this._addAtIndex(targetIndex + 1, {
      uid: uid(),
      id: newColumn.measure.metadata.id,
      label: newColumn.label ?? newColumn.measure.metadata.label,
    });
  }

  async moveLeft(column: WithId<ICustomReportColumn>): Promise<void> {
    const targetIndex = await this._getColumnIndex(column);
    await this.moveColumn(targetIndex, targetIndex - 1);
  }

  async moveRight(column: WithId<ICustomReportColumn>): Promise<void> {
    const targetIndex = await this._getColumnIndex(column);
    await this.moveColumn(targetIndex, targetIndex + 1);
  }

  async moveColumn(fromIndex: number, toIndex: number): Promise<void> {
    const current = await snapshot(this._store.display$);
    if (!current) {
      return;
    }
    const columns = [...current.columns];
    moveItemInArray(columns, fromIndex, toIndex);
    this._store.setDisplay({ ...current, columns });
  }

  private async _openEditDialog(
    existing?: ICustomReportColumn
  ): Promise<IReportBuildColumnSelectorFormData | undefined> {
    const filteredData = await snapshot(
      this._store.results$.pipe(map((results) => results?.filtered ?? []))
    );
    const data: IReportBuilderColumnSelectorDialogData = {
      existing: existing
        ? {
            uid: existing.uid,
            label: existing.label ?? '',
            dataPoint: existing.id,
            reducer: MeasureReducer.Sum,
          }
        : undefined,
      filteredData,
      excludeDataPointFormatters: [],
    };
    return this._dialog
      .open<
        ReportBuilderColumnSelectorDialogComponent,
        IReportBuilderColumnSelectorDialogData,
        IReportBuildColumnSelectorFormData
      >(
        ReportBuilderColumnSelectorDialogComponent,
        DialogPresets.medium({ data })
      )
      .afterClosed()
      .toPromise();
  }

  private async _addAtIndex(
    index: number,
    column: WithId<ICustomReportColumn>
  ): Promise<void> {
    const current = await snapshot(this._store.display$);
    if (!current) {
      return;
    }
    const columns = [...current.columns];
    columns.splice(index, 0, { ...column, uid: uuid() });
    this._store.setDisplay({ ...current, columns });
  }

  private async _getColumnIndex(
    column: WithId<ICustomReportColumn>
  ): Promise<number> {
    const current = await snapshot(this._store.display$);
    if (!current) {
      return -1;
    }
    return current.columns.findIndex((item) => item.uid === column.uid);
  }
}
