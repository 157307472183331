import {
  ChartableSurface,
  IChartedRef,
  isChartedTooth,
} from '@principle-theorem/principle-core/interfaces';
import { QuadrantHierarchy } from './quadrant-hierarchy';
import { SurfaceHierarchy } from './surface-hierarchy';

export class ToothHierarchy extends SurfaceHierarchy<
  Pick<IChartedRef, 'tooth'>
> {
  override surfaces = [
    ChartableSurface.Crown,
    ChartableSurface.WholeTooth,
    ChartableSurface.MultipleTeeth,
  ];
  override parents = [new QuadrantHierarchy()];

  canBeChartedOn(
    selectedSurface: Partial<IChartedRef>
  ): selectedSurface is Pick<IChartedRef, 'tooth'> {
    return isChartedTooth(selectedSurface);
  }

  toChartedRef(
    currentRef: Pick<IChartedRef, 'tooth'>
  ): Pick<IChartedRef, 'tooth'> {
    return {
      tooth: {
        quadrant: currentRef.tooth.quadrant,
        quadrantIndex: currentRef.tooth.quadrantIndex,
      },
    };
  }
}
