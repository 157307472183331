import { PatientRelationship } from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  IDestinationEntityJobRunOptions,
  IMigratedDataSummary,
  PatientRelationshipType,
  type FailedDestinationEntityRecord,
  type IBasePatient,
  type IBrand,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IHasPrimaryContact,
  type IPatient,
  type IPracticeMigration,
  type ISourceEntityRecord,
  type IsPrimaryContact,
  type MergeConflictDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  asDocRef,
  asyncForEach,
  doc$,
  getDoc,
  getError,
  isSameRef,
  multiMap,
  resolveSequentially,
  safeCombineLatest,
  snapshotCombineLatest,
  toNamedDocument,
  toTimestamp,
  type DocumentReference,
  type WithRef,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { combineLatest, of, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import { PracticeMigration } from '../../../practice-migrations';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientSourceEntity,
  type ID4WPatient,
  type ID4WPatientFilters,
  type ID4WPatientTranslations,
} from '../../source/entities/patient';
import { PatientDestinationEntity } from './patients';

export const PATIENT_RELATIONSHIP_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: 'patientRelationships',
    label: 'Patient Relationships',
    description: '',
  },
});

export interface IPatientRelationshipDestinationRecord {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  patientRefs: DocumentReference<IPatient>[];
}

export interface IPatientRelationshipJobData {
  brand: WithRef<IBrand>;
  sourcePatient: IGetRecordResponse<
    ID4WPatient,
    ID4WPatientTranslations,
    ID4WPatientFilters
  >;
}

export interface IPatientMigrationData {
  sourcePatientId: string;
  patientRef: DocumentReference<IPatient>;
  primaryContacts: (IHasPrimaryContact & {
    patientRef: DocumentReference<IPatient>;
  })[];
}

export class PatientRelationshipDestinationEntity extends BaseDestinationEntity<
  IPatientRelationshipDestinationRecord,
  IPatientRelationshipJobData,
  IPatientMigrationData
> {
  destinationEntity = PATIENT_RELATIONSHIP_DESTINATION_ENTITY;

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  sourceCountDataAccessor(
    data: IPatientRelationshipJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IPatientRelationshipDestinationRecord>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return safeCombineLatest(
      record.data.patientRefs.map((patientRef) => doc$(patientRef))
    ).pipe(
      multiMap((patient) => ({
        label: 'Patient',
        data: patient,
      }))
    );
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMapHandler: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientRelationshipJobData[]> {
    const brand$ = PracticeMigration.brand$(migration);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([brand$]),
    ]).pipe(
      map(([sourcePatients, [brand]]) =>
        sourcePatients.map((sourcePatient) => ({
          sourcePatient,
          brand,
        }))
      )
    );
  }

  getDestinationEntityRecordUid(data: IPatientRelationshipJobData): string {
    return data.sourcePatient.record.uid;
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientRelationshipJobData
  ): Promise<
    | IPatientMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients
      .getSourceRecordId(data.sourcePatient.data.data)
      .toString();
    const sourcePatientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    try {
      if (!sourcePatientRef) {
        throw new Error(
          `Patient relation can't be found for id ${sourcePatientId}`
        );
      }

      const relationshipRecords =
        await this.sourceEntities.patients.filterRecords(
          migration,
          'familyHeadId',
          sourcePatientId
        );

      const primaryContacts = compact(
        await asyncForEach(relationshipRecords, async (relationship) => {
          const relationshipPatientId = this.sourceEntities.patients
            .getSourceRecordId(relationship.data.data)
            .toString();
          const relationshipPatientRef =
            await translationMap.getDestination<IPatient>(
              relationshipPatientId,
              PATIENT_RESOURCE_TYPE
            );

          if (!relationshipPatientRef) {
            throw new Error(
              `Patient relation can't be found for id ${relationshipPatientId}`
            );
          }

          if (isSameRef(sourcePatientRef, relationshipPatientRef)) {
            return;
          }

          return {
            patientRef: relationshipPatientRef,
            primaryContact: {
              patient: toNamedDocument(
                await getDoc(
                  asDocRef<IBasePatient & IsPrimaryContact>(sourcePatientRef)
                )
              ),
              type: PatientRelationshipType.Unknown,
            },
          };
        })
      );

      return {
        sourcePatientId,
        patientRef: sourcePatientRef,
        primaryContacts,
      };
    } catch (error) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        getError(error)
      );
    }
  }

  hasMergeConflict(
    _translationMap: TranslationMapHandler,
    _data: IPatientMigrationData
  ): IPatientMigrationData | undefined {
    return;
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientRelationshipJobData,
    _migrationData: IPatientMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      label: jobData.sourcePatient.record.label,
      uid: jobData.sourcePatient.record.uid,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMapHandler: TranslationMapHandler,
    jobData: IPatientRelationshipJobData,
    migrationData: IPatientMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const patientRefs = await resolveSequentially(
        migrationData.primaryContacts,
        async (relationship) => {
          await FirestoreMigrate.patchDoc(relationship.patientRef, {
            primaryContact: relationship.primaryContact,
          });

          await PatientRelationship.addPrimaryRelationship(
            await Firestore.getDoc(relationship.patientRef)
          );
          return relationship.patientRef;
        }
      );
      return this._buildSuccessResponse(jobData.sourcePatient.record, [
        migrationData.patientRef,
        ...patientRefs,
      ]);
    } catch (error) {
      return this._buildErrorResponse(
        jobData.sourcePatient.record,
        getError(error)
      );
    }
  }

  private _buildSuccessResponse(
    sourcePatient: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>,
    patientRefs: DocumentReference<IPatient>[]
  ): IDestinationEntityRecord<IPatientRelationshipDestinationRecord> {
    return {
      uid: sourcePatient.uid,
      label: sourcePatient.label,
      data: {
        sourceRef: sourcePatient.ref,
        patientRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: sourcePatient.ref,
      migratedAt: toTimestamp(),
    };
  }

  private _buildErrorResponse(
    patient: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>,
    errorMessage: string
  ): IDestinationEntityRecord<IPatientRelationshipDestinationRecord> &
    FailedDestinationEntityRecord {
    return {
      uid: patient.uid,
      label: patient.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: patient.ref,
      errorMessage,
      failData: {
        patientRef: patient.ref,
      },
    };
  }
}
