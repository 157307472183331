<div class="layout-margin" fxLayout="column">
  <h1>Income</h1>

  <div
    class="mat-elevation-z1 forecasting"
    fxLayout="column"
    fxLayoutAlign="space-between stretch"
    *ngIf="selectedForecast"
  >
    <h2>Accumulative Revenue vs KPI</h2>
    <pr-chart fxFlex [chartBuilder]="selectedForecast.chartBuilder" />
    <div class="menu">
      <mat-form-field>
        <mat-label>Practice</mat-label>
        <mat-select [(value)]="selectedForecastName">
          <mat-option
            *ngFor="
              let name of practiceNames;
              let i = index;
              trackBy: trackByPractice
            "
            [value]="name"
            (click)="selectedForecast = forecastCharts[i]"
          >
            {{ name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
    <div class="chart" fxFlex>
      <h2>Gross Income vs Expenses</h2>
      <pr-chart [chartBuilder]="incomeVsExpensesChart" />
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
    <div class="chart" fxFlex>
      <h2>Gross Income vs Potential Income</h2>
      <pr-chart [chartBuilder]="potentialIncomeChart" />
    </div>

    <div class="chart" fxFlex>
      <h2>Revenue Distribution</h2>
      <pr-chart fxFlex [chartBuilder]="incomePieChart" />
    </div>
  </div>

  <pr-table-chart
    fxFlex
    [chart]="tableData"
    [replacementHeaders]="replacementHeaders"
   />
</div>
