<div class="info-table" *ngrxLet="appointmentDetails$ as appointmentDetails">
  <div *ngrxLet="schedulingAlerts$ as alerts">
    <div
      *ngIf="alerts.length"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
    >
      <mat-icon color="accent">warning</mat-icon>
      <h4 *ngFor="let alert of alerts; trackBy: trackByAlert" class="alert">
        {{ alert }}
      </h4>
    </div>
  </div>

  <ng-container *ngrxLet="durationWarningMessage$ as warning">
    <div
      *ngIf="warning.length"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
      class="duration-warning-message"
    >
      <mat-icon>warning</mat-icon>
      <span>{{ warning }}</span>
    </div>
  </ng-container>

  <ng-container *ngrxLet="selectedEvent$ as event">
    <div
      *ngIf="event; else unscheduled"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <h3 class="mat-headline-6">
        <pr-formatted-event-field
          field="from"
          [event]="event"
          [format]="dateTimeFormat"
        />
      </h3>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      ptElementTarget="appointment-deatil-row"
    >
      <div fxFlex="25">Duration</div>
      <div fxFlex>{{ eventDuration$ | ngrxPush }} mins</div>
    </div>
    <ng-template #unscheduled>
      <h3 class="mat-headline-6" ptElementTarget="no-appointment-title">
        No Appointment Time Selected
      </h3>
    </ng-template>
  </ng-container>

  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    ptElementTarget="appointment-deatil-row"
  >
    <div fxFlex="25">Practice</div>
    <div fxFlex>{{ selectedPractice$ | ngrxPush }}</div>
  </div>

  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    ptElementTarget="appointment-deatil-row"
  >
    <div fxFlex="25">Practitioner</div>
    <div fxFlex>{{ selectedPractiioner$ | ngrxPush }}</div>
  </div>

  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    ptElementTarget="appointment-deatil-row"
  >
    <div fxFlex="25">Treatment Plan</div>
    <div fxFlex>{{ treatmentPlanName$ | ngrxPush }}</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex="25">Treatment Details</div>
    <div fxFlex>{{ treatmentStepSummary$ | ngrxPush }}</div>
  </div>
</div>

<ng-container *ngrxLet="waitlistDetails$ as waitlist">
  <div class="info-table" *ngIf="waitlist.addToWaitlist; else notOnWaitlist">
    <h3 class="mat-headline-6">On Waitlist</h3>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="25">Between Dates</div>
      <div fxFlex>
        {{ waitlist.dateFrom | moment | amDateFormat: dateFormat }} -
        <span *ngIf="waitlist.dateTo">
          {{ waitlist.dateTo | moment | amDateFormat: dateFormat }}
        </span>
        <span *ngIf="!waitlist.dateTo">Now</span>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="25">Between Times</div>
      <div fxFlex>
        <span *ngIf="!waitlist.timeFrom">Any</span>
        <span *ngIf="waitlist.timeFrom">
          {{ waitlist.timeFrom }}
        </span>
        -
        <span *ngIf="!waitlist.timeTo">Any</span>
        <span *ngIf="waitlist.timeTo">
          {{ waitlist.timeTo }}
        </span>
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      *ngIf="waitlist.notes | map: commentHasContent"
    >
      <div fxFlex="25">Comments</div>
      <div fxFlex>
        <pr-content-view [content]="waitlist.notes" />
      </div>
    </div>
  </div>
  <ng-template #notOnWaitlist>
    <h3 class="mat-headline-6">Not on Waitlist</h3>
  </ng-template>
</ng-container>

<pr-tags-input
  class="pt-4"
  [formControl]="appointmentTags"
  [tagCol]="tagCol$ | ngrxPush"
  [tagType]="tagType"
/>
