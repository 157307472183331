import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import {
  GapStoreService,
  WaitListStore,
} from '@principle-theorem/ng-principle-shared';
import {
  IScheduleSummaryEventable,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-gap-card',
    templateUrl: './gap-card.component.html',
    styleUrls: ['./gap-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GapCardComponent {
  gapStore = inject(GapStoreService);
  waitListStore = inject(WaitListStore);

  practitioner$: Observable<WithRef<IStaffer>>;
  practice$: Observable<WithRef<IPractice>>;
  gap$ = new ReplaySubject<IScheduleSummaryEventable>(1);

  @Input()
  set gap(gap: IScheduleSummaryEventable) {
    if (gap) {
      this.gap$.next(gap);
    }
  }
}
