import {
  HicapsConnectExtendedDataTypeGuard,
  IHicapsConnectHealthFundTransactionExtendedData,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeneralProviderActionsService } from '../../../general-provider-actions.service';
import {
  ITransactionAction,
  ITransactionActionsData,
} from '../../../transaction-action';
import { TransactionHelpers } from '../../../transaction-helpers';

export class VoidHicapsConnectHealthfundTransaction
  implements
    ITransactionAction<IHicapsConnectHealthFundTransactionExtendedData>
{
  icon = 'block';
  label = 'Void';
  tooltip = 'Void if claim has been manually cancelled via health fund';
  inProgress$ = new BehaviorSubject<boolean>(false);
  typeGuardFn = HicapsConnectExtendedDataTypeGuard.isHealthFund;

  constructor(private _actions: GeneralProviderActionsService) {}

  canDo$(
    data: ITransactionActionsData<IHicapsConnectHealthFundTransactionExtendedData>
  ): Observable<boolean> {
    return TransactionHelpers.lastCompletedTransaction$(
      data,
      HicapsConnectExtendedDataTypeGuard.isHealthFund
    ).pipe(
      map((transaction) => {
        if (!transaction) {
          return false;
        }
        return transaction.type === TransactionType.Incoming;
      })
    );
  }

  async do(
    data: ITransactionActionsData<IHicapsConnectHealthFundTransactionExtendedData>
  ): Promise<void> {
    this.inProgress$.next(true);
    await this._actions.cancel(
      data.invoice,
      data.latestTransaction,
      'Void Heath Claim'
    );
    this.inProgress$.next(false);
  }
}
