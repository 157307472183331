<div
  [cpToggle]="true"
  cpDialogDisplay="inline"
  [cpOutputFormat]="$any(format)"
  [cpAlphaChannel]="alphaEnabled ? 'enabled' : 'disabled'"
  [cpDisableInput]="inputHidden"
  [(colorPicker)]="colour"
></div>

<div *ngIf="isDisabled" class="disabled-overlay"></div>
