<form
  [formGroup]="form"
  class="form flex flex-col items-stretch justify-around"
>
  <mat-form-field class="flex-1">
    <mat-icon
      matIconPrefix
      [svgIcon]="form.controls.icon.value?.slug"
     />
    <mat-label>Icon</mat-label>
    <input
      type="text"
      matInput
      [required]="true"
      formControlName="icon"
      [matAutocomplete]="autoIcons"
    />
    <mat-autocomplete
      #autoIcons="matAutocomplete"
      [displayWith]="displayFn"
      (optionSelected)="emitChanges()"
    >
      <mat-option
        *ngFor="let icon of filteredIcons$ | async; trackBy: trackByIcon"
        [value]="icon"
      >
        <mat-icon matIconPrefix [svgIcon]="icon.slug" />
        {{ icon.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <pt-display-colour-picker
    class="flex-1"
    label="Icon Colour"
    formControlName="colour"
    [options]="colours"
   />

  <mat-checkbox
    color="primary"
    formControlName="hideWhenResolved"
    (change)="emitChanges()"
  >
    Hide when resolved
  </mat-checkbox>
  <mat-checkbox
    color="primary"
    formControlName="disablesSurface"
    (change)="emitChanges()"
  >
    Disables surface when charted
  </mat-checkbox>
  <mat-checkbox
    color="primary"
    formControlName="hasFill"
    (change)="emitChanges()"
  >
    Repeat icon across tooth surface
  </mat-checkbox>
</form>
