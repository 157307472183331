import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ChatsDashboardStore } from '@principle-theorem/ng-chats';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { SidebarManagerService } from '@principle-theorem/ng-shared';
import { type INotification } from '@principle-theorem/notifications';
import { Staffer } from '@principle-theorem/principle-core';
import {
  count,
  filterUndefined,
  isPathChanged$,
  query$,
  type WithRef,
} from '@principle-theorem/shared';
import { limit, orderBy, where } from '@principle-theorem/shared';
import { BehaviorSubject, type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent {
  notifications$: Observable<WithRef<INotification>[]>;
  notificationsCount$: Observable<number>;
  chatCount$: Observable<number>;
  notificationsOpen$ = new BehaviorSubject<boolean>(false);

  constructor(
    public sidebar: SidebarManagerService,
    private _organisation: OrganisationService,
    chats: ChatsDashboardStore
  ) {
    this.notifications$ = this._organisation.staffer$.pipe(
      isPathChanged$('ref.path'),
      filterUndefined(),
      switchMap((staffer) =>
        query$(
          Staffer.notificationCol(staffer),
          where('viewed', '==', false),
          orderBy('createdAt', 'desc'),
          limit(30)
        )
      )
    );

    this.notificationsCount$ = this.notifications$.pipe(count());
    this.chatCount$ = chats.unreadChats$.pipe(count());
  }
}
