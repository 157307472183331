<mat-toolbar color="accent">Create Document</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()" fxLayout="column">
  <mat-dialog-content fxLayout="column" fxLayoutGap="16px">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" required />
    </mat-form-field>

    <pr-template-selector
      [templates]="(templates$ | async)?.templates"
      [contextOptions]="(templates$ | async)?.contextOptions"
      (templateSelected)="form.patchValue({ template: $event })"
     />
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary">Create</button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
