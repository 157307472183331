import { TransactionType } from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ITransactionAction,
  ITransactionActionsData,
} from '../../transaction-action';
import {
  getRefundInfo$,
  getRefundLabel$,
  getRefundRemaining$,
} from '../../transaction-helpers';
import { CashTransactionProvider } from '../cash-transaction-provider.service';
import { isCashExtendedData } from './amend-cash-transaction';

export class RefundCashTransaction implements ITransactionAction<unknown> {
  icon = 'undo';
  label = 'Refund';
  inProgress$ = new BehaviorSubject<boolean>(false);
  typeGuardFn = isCashExtendedData;

  constructor(private _provider: CashTransactionProvider) {}

  canDo$(data: ITransactionActionsData<unknown>): Observable<boolean> {
    return getRefundRemaining$(data).pipe(
      map((remaining) => {
        const isIncoming = data.transaction.type === TransactionType.Incoming;
        const hasRemaining = remaining > 0;
        return isIncoming && hasRemaining;
      })
    );
  }

  label$(data: ITransactionActionsData<unknown>): Observable<string> {
    return getRefundLabel$(data);
  }

  info$(data: ITransactionActionsData<unknown>): Observable<string[]> {
    return getRefundInfo$(data);
  }

  async do(data: ITransactionActionsData<unknown>): Promise<void> {
    this.inProgress$.next(true);
    await this._provider.refundTransaction(
      data.invoice,
      data.latestTransaction
    );
    this.inProgress$.next(false);
  }
}
