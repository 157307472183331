<mat-toolbar class="flex justify-between gap-4">
  <div class="flex min-w-0 gap-4">
    <div
      *ngIf="participants$ | async as participants"
      class="flex items-center pl-2"
    >
      @if (!participants.length) {
        <pt-user-icon
          *ngIf="staffer$ | async as staffer"
          [src]="staffer.ref | map: stafferImage$ : this | async"
          [name]="staffer.ref | map: stafferName$ : this | async"
          [diameter]="32"
        />
      } @else {
        @for (
          participant of participants$ | async;
          track participant.id;
          let index = $index
        ) {
          <pt-user-icon
            class="-ml-2"
            *ngIf="index < 2"
            [diameter]="32"
            [src]="participant | map: stafferImage$ : this | async"
            [name]="participant | map: stafferName$ : this | async"
          />
        }
      }
    </div>

    <div class="group/item flex min-w-0 shrink items-center gap-4">
      <h3 class="mat-subtitle-1 chat-name min-w-0 truncate">
        {{ chatLabel$ | async }}
      </h3>

      @if (!compact) {
        <button
          mat-icon-button
          class="group/edit invisible mr-auto opacity-50 group-hover/item:visible"
          *ngIf="isGroupChat$ | async"
          matTooltip="Rename Chat"
          (click)="renameChat()"
        >
          <mat-icon>edit</mat-icon>
        </button>
      }
    </div>
  </div>

  <div class="flex">
    <button
      mat-icon-button
      *ngIf="(isGroupChat$ | async) && (participants$ | async)?.length === 0"
      matTooltip="Add Members"
      (click)="manageMembers()"
    >
      <mat-icon>group_add</mat-icon>
    </button>

    <button mat-icon-button [matMenuTriggerFor]="moreMenu">
      <mat-icon>more_vert</mat-icon>
    </button>

    @if (compact) {
      <button
        mat-icon-button
        matTooltip="Close chat"
        (click)="closeChat.emit()"
      >
        <mat-icon>close</mat-icon>
      </button>
    }

    <mat-menu #moreMenu="matMenu">
      <button mat-menu-item *ngIf="isGroupChat$ | async" (click)="renameChat()">
        <mat-icon>edit</mat-icon>
        <span>Rename Chat</span>
      </button>
      <button
        mat-menu-item
        *ngIf="isGroupChat$ | async"
        (click)="manageMembers()"
      >
        <mat-icon>groups</mat-icon>
        <span>Manage Members</span>
      </button>
      <button
        mat-menu-item
        *ngIf="actions.canMarkUnread$ | async"
        (click)="actions.markUnread()"
      >
        <mat-icon>mark_chat_unread</mat-icon>
        <span>Mark as Unread</span>
      </button>
      <button
        mat-menu-item
        *ngIf="actions.isOpen$ | async"
        (click)="archiveChat()"
      >
        <mat-icon>archive</mat-icon>
        <span>Archive Chat</span>
      </button>
      <button
        mat-menu-item
        *ngIf="(actions.isOpen$ | async) === false"
        (click)="actions.reopenChat()"
      >
        <mat-icon>unarchive</mat-icon>
        <span>Re-open Chat</span>
      </button>
      <button
        mat-menu-item
        *ngIf="(actions.isMuted$ | async) === false"
        (click)="actions.muteChat()"
      >
        <mat-icon>volume_off</mat-icon>
        <span>Mute Chat</span>
      </button>
      <button
        mat-menu-item
        *ngIf="actions.isMuted$ | async"
        (click)="actions.unmuteChat()"
      >
        <mat-icon>volume_up</mat-icon>
        <span>Unmute Chat</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
