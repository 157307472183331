import {
  BASIC_MEDICAL_HISTORY_FORM_SCHEMA,
  Brand,
  CustomFormConfiguration,
  CustomFormContent,
  Patient,
  PatientForm,
  toMedicalHistoryForm,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FormSchemaPropertyType,
  ISourceEntityRecord,
  ITranslationMap,
  PatientFormType,
  type FailedDestinationEntityRecord,
  type IBrand,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type MergeConflictDestinationEntityRecord,
  IDestinationEntityJobRunOptions,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  getError,
  snapshot,
  toTimestamp,
  type DocumentReference,
  type WithRef,
  snapshotCombineLatest,
} from '@principle-theorem/shared';
import { cloneDeep, first, get, set, sortBy } from 'lodash';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PracticeMigration } from '../../../practice-migrations';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  ICorePracticePatientAllergy,
  ICorePracticePatientAllergyTranslations,
  PatientAllergySourceEntity,
} from '../../source/entities/patient-allergies';
import {
  ICorePracticePatientMedicalCondition,
  ICorePracticePatientMedicalConditionTranslations,
  PatientMedicalConditionSourceEntity,
} from '../../source/entities/patient-medical-conditions';
import {
  CORE_PRACTICE_PATIENT_MEDICAL_HISTORY_MAPPING,
  ICorePracticePatientHistory,
  ICorePracticePatientHistoryTranslations,
  PatientMedicalHistorySourceEntity,
} from '../../source/entities/patient-medical-histories';
import {
  ICorePracticePatient,
  ICorePracticePatientFilters,
  ICorePracticePatientTranslations,
  PatientSourceEntity,
} from '../../source/entities/patients';
import {
  CorePracticeAllergyMappingHandler,
  ICustomFormDataResolverConfigExtended,
  getValidMedicalHistoryFormOptions,
} from '../mappings/allergy-to-medical-history';
import { CorePracticeMedicalConditionMappingHandler } from '../mappings/medical-condition-to-medical-history';
import { PatientDestinationEntity } from './patients';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';

export const PATIENT_MEDICAL_HISTORY_RESOURCE = 'patientMedicalHistory';

export const PATIENT_MEDICAL_HISTORY_DESTINATION_ENTITY =
  DestinationEntity.init({
    metadata: {
      key: PATIENT_MEDICAL_HISTORY_RESOURCE,
      label: 'Patient Medical Histories',
      description: '',
    },
  });

export interface IPatientMedicalHistoryDestinationRecord {
  patientRef: DocumentReference;
}

export interface IPatientMedicalHistoryJobData {
  brand: WithRef<IBrand>;
  sourcePatient: IGetRecordResponse<
    ICorePracticePatient,
    ICorePracticePatientTranslations,
    ICorePracticePatientFilters
  >;
  medicalConditions: ITranslationMap<object, string>[];
  allergies: ITranslationMap<object, string>[];
}

export class PatientMedicalHistoryDestinationEntity extends BaseDestinationEntity<
  IPatientMedicalHistoryDestinationRecord,
  IPatientMedicalHistoryJobData,
  IPatientMedicalHistoryJobData
> {
  destinationEntity = PATIENT_MEDICAL_HISTORY_DESTINATION_ENTITY;

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    medicalHistories: new PatientMedicalHistorySourceEntity(),
    medicalConditions: new PatientMedicalConditionSourceEntity(),
    allergies: new PatientAllergySourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  customMappings = {
    medicalConditions: new CorePracticeMedicalConditionMappingHandler(),
    allergies: new CorePracticeAllergyMappingHandler(),
  };

  sourceCountDataAccessor(
    data: IPatientMedicalHistoryJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientMedicalHistoryJobData[]> {
    const brand$ = PracticeMigration.brand$(migration);
    const medicalConditions$ =
      this.customMappings.medicalConditions.getRecords(translationMap);
    const allergies$ = this.customMappings.allergies.getRecords(translationMap);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([brand$, medicalConditions$, allergies$]),
    ]).pipe(
      map(([sourcePatients, [brand, medicalConditions, allergies]]) =>
        sourcePatients.map((sourcePatient) => ({
          sourcePatient,
          brand,
          medicalConditions,
          allergies,
        }))
      )
    );
  }

  getDestinationEntityRecordUid(data: IPatientMedicalHistoryJobData): string {
    return data.sourcePatient.record.uid;
  }

  buildMigrationData(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IPatientMedicalHistoryJobData
  ):
    | IPatientMedicalHistoryJobData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord) {
    return data;
  }

  hasMergeConflict(
    _translationMap: TranslationMapHandler,
    _data: IPatientMedicalHistoryJobData
  ): IPatientMedicalHistoryJobData | undefined {
    return;
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientMedicalHistoryJobData,
    migrationData: IPatientMedicalHistoryJobData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      label: migrationData.sourcePatient.record.label,
      uid: migrationData.sourcePatient.record.uid,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  async runJob(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientMedicalHistoryJobData
  ): Promise<IDestinationEntityRecord> {
    const sourcePatientId = data.sourcePatient.data.data.id;
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        `Couldn't resolve patient`
      );
    }

    const medicalHistories =
      await this.sourceEntities.medicalHistories.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

    const medicalHistory = sortBy(
      medicalHistories.filter((history) => !history.data.data.isDeleted),
      (history) => history.data.data.dateAttend
    ).pop();

    const medicalConditions = medicalHistory
      ? await this.sourceEntities.medicalConditions.filterRecords(
          migration,
          'patientHistoryId',
          medicalHistory.data.data.id
        )
      : [];

    const allergies = medicalHistory
      ? await this.sourceEntities.allergies.filterRecords(
          migration,
          'patientHistoryId',
          sourcePatientId
        )
      : [];

    try {
      await this._addMedicalHistoryToPatient(
        translationMap,
        data,
        patientRef,
        medicalConditions,
        allergies,
        medicalHistory
      );
      return this._buildSuccessResponse(data.sourcePatient.record);
    } catch (error) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        getError(error)
      );
    }
  }

  private _buildSuccessResponse(
    patient: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>
  ): IDestinationEntityRecord<IPatientMedicalHistoryDestinationRecord> {
    return {
      uid: patient.uid,
      label: patient.label,
      data: {
        patientRef: patient.ref,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: patient.ref,
      migratedAt: toTimestamp(),
    };
  }

  private _buildErrorResponse(
    patient: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>,
    errorMessage: string
  ): IDestinationEntityRecord<IPatientMedicalHistoryDestinationRecord> {
    return {
      uid: patient.uid,
      label: patient.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: patient.ref,
      errorMessage,
      failData: {
        patientRef: patient.ref,
      },
    };
  }

  private async _addMedicalHistoryToPatient(
    translationMap: TranslationMapHandler,
    data: IPatientMedicalHistoryJobData,
    patientRef: DocumentReference<IPatient>,
    sourceMedicalConditions: IGetRecordResponse<
      ICorePracticePatientMedicalCondition,
      ICorePracticePatientMedicalConditionTranslations
    >[],
    sourceAllergies: IGetRecordResponse<
      ICorePracticePatientAllergy,
      ICorePracticePatientAllergyTranslations
    >[],
    medicalHistory?: IGetRecordResponse<
      ICorePracticePatientHistory,
      ICorePracticePatientHistoryTranslations
    >
  ): Promise<void> {
    const config = await snapshot(Brand.medicalHistoryFormConfig$(data.brand));
    const content = config
      ? await CustomFormConfiguration.getContent(config)
      : undefined;
    const jsonSchemaForm = content
      ? CustomFormContent.getJsonSchemaForm(content)
      : {};
    const form = toMedicalHistoryForm(jsonSchemaForm);
    const schema = form?.schema ?? BASIC_MEDICAL_HISTORY_FORM_SCHEMA;
    let formData = {};

    getValidMedicalHistoryFormOptions(schema).map((formOption) => {
      const mappedAllergy = data.allergies.find(
        (allergy) => allergy.destinationValue === formOption.path
      );

      const patientHasAllergy = sourceAllergies.find(
        (sourceAllergy) =>
          mappedAllergy?.sourceIdentifier ===
          sourceAllergy.data.data.description
      );

      if (patientHasAllergy && mappedAllergy?.destinationValue) {
        formData = setFormValue(
          formOption,
          formData,
          true,
          mappedAllergy.associatedValue?.toString(),
          patientHasAllergy.data.data.description
        );
        return;
      }

      const mappedMedicalCondition = data.medicalConditions.find(
        (medicalCondition) =>
          medicalCondition.destinationValue === formOption.path
      );

      const patientHasMedicalCondition = sourceMedicalConditions.find(
        (sourceMedicalCondition) =>
          mappedMedicalCondition?.sourceIdentifier ===
          sourceMedicalCondition.data.data.description
      );

      if (
        patientHasMedicalCondition &&
        mappedMedicalCondition?.destinationValue
      ) {
        formData = setFormValue(
          formOption,
          formData,
          true,
          mappedMedicalCondition.associatedValue?.toString(),
          patientHasMedicalCondition.data.data.description
        );
        return;
      }

      if (medicalHistory) {
        Object.entries(CORE_PRACTICE_PATIENT_MEDICAL_HISTORY_MAPPING)
          .filter(([_, value]) => value === formOption.path)
          .map(([key]) => {
            formData = setFormValue(
              formOption,
              formData,
              true,
              undefined,
              get(medicalHistory.data.data, key) as string
            );
          });
      }

      if (get(formData, formOption.path, undefined)) {
        return;
      }

      formData = setFormValue(formOption, formData);
    });

    const formSubmission = {
      ...form,
      data: formData,
      date: medicalHistory?.data.translations.dateAttend || toTimestamp(),
      disableChanges: true,
    };

    const medicalHistoryRef = await translationMap.getDestination(
      data.sourcePatient.record.uid,
      PATIENT_MEDICAL_HISTORY_RESOURCE
    );

    const patientReffable = { ref: patientRef };
    const formConfigRef = Brand.medicalHistoryFormRef({
      ref: Patient.brandRef(patientReffable),
    });

    const patientForm = PatientForm.markConfirmed(
      PatientForm.init({
        form: formSubmission,
        formType: PatientFormType.CustomForm,
        template: { name: 'Medical History Form', ref: formConfigRef },
      }),
      formSubmission.date
    );
    await addDoc(Patient.formCol(patientReffable), patientForm);

    if (!medicalHistoryRef) {
      await translationMap.upsert({
        sourceIdentifier: data.sourcePatient.record.uid,
        destinationIdentifier: medicalHistoryRef,
        resourceType: PATIENT_MEDICAL_HISTORY_RESOURCE,
      });
    }
  }
}

function setFormValue(
  formOption: ICustomFormDataResolverConfigExtended,
  formData: object,
  booleanValue: boolean = false,
  associatedValue: string | undefined = first(formOption.associatedValues),
  stringValue: string = ''
): object {
  const updatedData = cloneDeep(formData);
  if (formOption.associatedValues) {
    set(updatedData, formOption.path, associatedValue);
    return updatedData;
  }

  if (formOption.type === FormSchemaPropertyType.Boolean) {
    set(updatedData, formOption.path, booleanValue);
    return updatedData;
  }

  set(
    updatedData,
    formOption.path,
    `${get(updatedData, formOption.path, '')} ${stringValue}`.trim()
  );
  return updatedData;
}
