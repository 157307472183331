import {
  ConditionLogicConfigurationCollection,
  ConditionLogicId,
  IConditionLogicConfiguration,
  IConditionLogicImplementation,
  ITemplateScopeData,
  TemplateScope,
} from '@principle-theorem/principle-core/interfaces';
import { asyncForEach } from '@principle-theorem/shared';
import {
  AppointmentConfirmedCondition,
  AppointmentExcludesPractitionerCondition,
  AppointmentExcludesTreatmentCategoryCondition,
  AppointmentExcludesTreatmentCondition,
  AppointmentHasTagCondition,
  AppointmentIncludesPractitionerCondition,
  AppointmentIncludesTreatmentCategoryCondition,
  AppointmentIncludesTreatmentCondition,
  AppointmentIsStatusCondition,
  IsFirstAppointmentCondition,
} from './appointment-conditions';
import { AlwaysCondition, NeverCondition } from './generic-conditions';
import {
  PatientHasEmailAddressCondition,
  PatientHasMobileNumberCondition,
  PatientHasTagCondition,
} from './patient-conditions';
export class ConditionHandler<Context> {
  constructor(
    private _implementations: IConditionLogicImplementation<Context, unknown>[]
  ) {}

  async passes(
    collection: ConditionLogicConfigurationCollection,
    context: unknown
  ): Promise<boolean> {
    return (
      await asyncForEach(collection.or, async (inner) =>
        (
          await asyncForEach(inner.and, (condition) =>
            this._conditionPasses(condition, context)
          )
        ).every((passes) => passes)
      )
    ).some((passes) => passes);
  }

  private async _conditionPasses<Config>(
    config: IConditionLogicConfiguration<Config>,
    context: unknown
  ): Promise<boolean> {
    const condition = this._implementations.find(
      (implementation) => implementation.conditionId === config.conditionId
    );
    if (!condition) {
      // eslint-disable-next-line no-console
      console.warn(`Unknown conditionId: ${config.conditionId}`);
      return false;
    }
    if (!condition.isValidContext(context)) {
      // eslint-disable-next-line no-console
      console.warn(
        `Invalid context for conditionId: ${config.conditionId}`,
        context
      );
      return false;
    }
    return condition.isTrue(config.config, context);
  }
}

export const TEMPLATE_SCOPE_CONDITION_ID_MAP: Record<
  TemplateScope,
  ConditionLogicId[]
> = {
  [TemplateScope.None]: [ConditionLogicId.Never, ConditionLogicId.Always],
  [TemplateScope.Patient]: [
    ConditionLogicId.Never,
    ConditionLogicId.Always,
    ConditionLogicId.PatientHasTag,
    ConditionLogicId.PatientHasMobileNumber,
    ConditionLogicId.PatientHasEmailAddress,
  ],
  [TemplateScope.FormRequest]: [
    ConditionLogicId.Never,
    ConditionLogicId.Always,
    ConditionLogicId.PatientHasTag,
    ConditionLogicId.PatientHasMobileNumber,
    ConditionLogicId.PatientHasEmailAddress,
  ],
  [TemplateScope.Appointment]: [
    ConditionLogicId.Never,
    ConditionLogicId.Always,
    ConditionLogicId.PatientHasTag,
    ConditionLogicId.PatientHasMobileNumber,
    ConditionLogicId.PatientHasEmailAddress,
    ConditionLogicId.AppointmentHasTag,
    ConditionLogicId.AppointmentIsStatus,
    ConditionLogicId.AppointmentConfirmed,
    ConditionLogicId.IsFirstAppointment,
    ConditionLogicId.AppointmentIncludesTreatment,
    ConditionLogicId.AppointmentExcludesTreatment,
    ConditionLogicId.AppointmentIsTreatmentCategory,
    ConditionLogicId.AppointmentIsNotTreatmentCategory,
    ConditionLogicId.AppointmentIncludesPractitioner,
    ConditionLogicId.AppointmentExcludesPractitioner,
  ],
  [TemplateScope.Invoice]: [
    ConditionLogicId.Never,
    ConditionLogicId.Always,
    ConditionLogicId.PatientHasTag,
    ConditionLogicId.PatientHasMobileNumber,
    ConditionLogicId.PatientHasEmailAddress,
  ],
  [TemplateScope.TreatmentPlan]: [
    ConditionLogicId.Never,
    ConditionLogicId.Always,
    ConditionLogicId.PatientHasTag,
    ConditionLogicId.PatientHasMobileNumber,
    ConditionLogicId.PatientHasEmailAddress,
  ],
};

export function getConditionImplementations(): IConditionLogicImplementation<
  ITemplateScopeData,
  unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>[] {
  return [
    new NeverCondition(),
    new AlwaysCondition(),
    new PatientHasTagCondition(),
    new PatientHasMobileNumberCondition(),
    new PatientHasEmailAddressCondition(),
    new AppointmentHasTagCondition(),
    new AppointmentIsStatusCondition(),
    new AppointmentConfirmedCondition(),
    new IsFirstAppointmentCondition(),
    new AppointmentIncludesTreatmentCondition(),
    new AppointmentExcludesTreatmentCondition(),
    new AppointmentIncludesTreatmentCategoryCondition(),
    new AppointmentExcludesTreatmentCategoryCondition(),
    new AppointmentIncludesPractitionerCondition(),
    new AppointmentExcludesPractitionerCondition(),
  ];
}

export function getScopedConditions(scope: TemplateScope): ConditionLogicId[] {
  return TEMPLATE_SCOPE_CONDITION_ID_MAP[scope];
}

export function getConditionImplementation(
  conditionId: ConditionLogicId
): IConditionLogicImplementation<ITemplateScopeData, unknown> {
  const result = getConditionImplementations().find(
    (c) => c.conditionId === conditionId
  );
  if (!result) {
    throw new Error(`Condition Not Implemented: ${conditionId}`);
  }
  return result;
}

export function resolveConditionImplementations(
  conditionIds: ConditionLogicId[]
): IConditionLogicImplementation<ITemplateScopeData, unknown>[] {
  return conditionIds.map((conditionId) =>
    getConditionImplementation(conditionId)
  );
}
