import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  type OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  type DraggableOptions,
  type Interactable,
  type InteractEvent,
  type PointerEvent,
} from '@interactjs/types';
import {
  type ITimelineDisplayOptions,
  type ITimelineDataGroup,
} from '@principle-theorem/principle-core/interfaces';
import {
  durationToHumanisedTime,
  floorToNearestMinuteInterval,
  type ITimePeriod,
} from '@principle-theorem/shared';
import interact from 'interactjs';
import { get, isNumber, min } from 'lodash';
import { duration, type Moment } from 'moment-timezone';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  filter,
  map,
  repeat,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { InteractiveTimelineDisplayCalculator } from '../interactive-timeline-display-calculator';
import { type ITimelineNodeSummary } from '../interactive-timeline-drag-tooltip/interactive-timeline-drag-tooltip.component';
import { InteractiveTimelineDragareaComponent } from '../interactive-timeline-dragarea/interactive-timeline-dragarea.component';
import {
  type IInteractableRect,
  InteractableRect,
} from '../interactive-timeline-node/interactable-rect';

export interface ITimelineDropzoneEvent<T> {
  event: T;
  time: ITimePeriod;
  rect: IInteractableRect;
}

export interface ITimelineTapEvent {
  event: PointerEvent;
  time: Moment;
  nodePosition: number;
}

@Component({
    selector: 'pr-interactive-timeline-dropzone',
    templateUrl: './interactive-timeline-dropzone.component.html',
    styleUrls: ['./interactive-timeline-dropzone.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InteractiveTimelineDropzoneComponent<T, G> implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _interactable?: Interactable;
  private _disableDrag$ = new BehaviorSubject<boolean>(false);
  private _disableTap$ = new BehaviorSubject<boolean>(true);
  private _dragArea$ = new BehaviorSubject<string | HTMLElement>('parent');
  private _options$ = new ReplaySubject<ITimelineDisplayOptions>(1);
  private _createBlock$ = new ReplaySubject<ElementRef<HTMLElement>>(1);

  private _cancelDrag$ = new Subject<void>();
  private _rect$ = new BehaviorSubject<IInteractableRect>(
    InteractableRect.init()
  );
  private _timeRange$ = new ReplaySubject<ITimePeriod>(1);
  element: HTMLElement;
  summary$: Observable<ITimelineNodeSummary>;
  moving$ = new BehaviorSubject<boolean>(false);
  @Input() group: ITimelineDataGroup<T, G>;
  @Input() day: ITimePeriod;
  @Output() dragEnd = new EventEmitter<ITimelineDropzoneEvent<InteractEvent>>();
  @Output() tapEnd = new EventEmitter<ITimelineTapEvent>();
  @HostBinding('class.active') isActive: boolean;

  @ViewChild('createBlock')
  set createBlock(createBlock: ElementRef<HTMLElement>) {
    if (createBlock) {
      this._createBlock$.next(createBlock);
    }
  }

  constructor(elementRef: ElementRef<HTMLElement>) {
    this.element = elementRef.nativeElement;

    combineLatest([
      this._getDraggableOptions$(),
      this._options$,
      this._timeRange$,
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([draggable, options, timeRange]) => {
        if (this._interactable) {
          this._interactable.unset();
        }
        this._interactable = interact(this.element).dropzone({});
        if (draggable) {
          this._interactable.draggable(draggable);
        }
        this._interactable.on('tap', (event: PointerEvent) =>
          this._handleTap(options, timeRange, event)
        );
      });

    this.summary$ = this._getChangeSummary$();

    this.moving$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((isActive) => (this.isActive = isActive));
  }

  @Input()
  set options(options: ITimelineDisplayOptions) {
    if (options) {
      this._options$.next(options);
    }
  }

  @Input()
  set timeRange(timeRange: ITimePeriod) {
    if (timeRange) {
      this._timeRange$.next(timeRange);
    }
  }

  @Input()
  set disableDrag(disableDrag: BooleanInput) {
    this._disableDrag$.next(coerceBooleanProperty(disableDrag));
  }

  @Input()
  set disableTap(disableTap: BooleanInput) {
    this._disableTap$.next(coerceBooleanProperty(disableTap));
  }

  @Input()
  set dragArea(
    dragArea: string | HTMLElement | InteractiveTimelineDragareaComponent
  ) {
    if (dragArea instanceof InteractiveTimelineDragareaComponent) {
      this._dragArea$.next(dragArea.elementRef.nativeElement);
      return;
    }
    this._dragArea$.next(dragArea || 'parent');
  }

  ngOnDestroy(): void {
    this._interactable?.unset();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @HostListener('document:keydown.escape')
  cancelDrag(): void {
    this._cancelDrag$.next();
  }

  private _getDraggableOptions$(): Observable<DraggableOptions | undefined> {
    return combineLatest([
      this._options$,
      this._dragArea$,
      this._disableDrag$,
      this._createBlock$,
      this._timeRange$,
    ]).pipe(
      map(([options, dragArea, isDisabled, createBlock, timeRange]) => {
        if (isDisabled) {
          return undefined;
        }
        return {
          origin: dragArea,
          listeners: {
            start: (event: InteractEvent) => this._startDrag(event, options),
            move: (event: InteractEvent) =>
              this._drag(event, createBlock.nativeElement, options, timeRange),
            end: (event: InteractEvent) =>
              this._endDrag(createBlock, event, options, timeRange),
          },
        };
      }),
      // eslint-disable-next-line rxjs/no-unsafe-takeuntil
      takeUntil(
        this._cancelDrag$.pipe(
          withLatestFrom(this._createBlock$),
          tap(([_, createBlock]) => {
            this.moving$.next(false);
            this._rect$.next({
              ...this._rect$.value,
              hidden: true,
            });
            this._render(createBlock.nativeElement);
          })
        )
      ),
      repeat()
    );
  }

  private _endDrag(
    createBlock: ElementRef<HTMLElement>,
    event: InteractEvent,
    options: ITimelineDisplayOptions,
    timeRange: ITimePeriod
  ): void {
    this.moving$.next(false);
    this._rect$.next({
      ...this._rect$.value,
      hidden: true,
    });
    this._render(createBlock.nativeElement);
    this.dragEnd.emit({
      event,
      time: this._getNodeTime(options, timeRange),
      rect: this._rect$.value,
    });
  }

  private _startDrag(
    event: InteractEvent,
    options: ITimelineDisplayOptions
  ): void {
    this.moving$.next(true);
    this._rect$.next(this._getCreateDragRect(options, event));
  }

  private _drag(
    event: InteractEvent,
    createBlock: HTMLElement,
    options: ITimelineDisplayOptions,
    timeRange: ITimePeriod
  ): void {
    this._rect$.next(this._getUpdateDragRect(options, timeRange, event));
    this._render(createBlock);
  }

  private _render(createBlock: HTMLElement): void {
    InteractableRect.render(this._rect$.value, createBlock);
  }

  private _getNodeTime(
    options: ITimelineDisplayOptions,
    timeRange: ITimePeriod
  ): ITimePeriod {
    const rect = this._rect$.value;
    const isHorizontal =
      InteractiveTimelineDisplayCalculator.isHorizontal(options);
    return {
      from: InteractiveTimelineDisplayCalculator.positionToTime(
        options,
        timeRange,
        isHorizontal ? rect.x : rect.y,
        this.day.from
      ),
      to: InteractiveTimelineDisplayCalculator.positionToTime(
        options,
        timeRange,
        isHorizontal ? rect.x + rect.width : rect.y + rect.height,
        this.day.from
      ),
    };
  }

  private _getChangeSummary$(): Observable<ITimelineNodeSummary> {
    return combineLatest([
      this._options$,
      this.moving$,
      this._rect$,
      this._timeRange$,
    ]).pipe(
      filter(([_options, moving, _rect, _timeRange]) => moving),
      map(([options, _moving, rect, timeRange]) => {
        const range = InteractiveTimelineDisplayCalculator.getTimeFromRect(
          options,
          timeRange,
          rect,
          this.day.from
        );
        const nodeDuration = duration(range.to.diff(range.from));
        return {
          ...range,
          duration: durationToHumanisedTime(nodeDuration),
        };
      })
    );
  }

  private _getCreateDragRect(
    options: ITimelineDisplayOptions,
    event: InteractEvent
  ): IInteractableRect {
    const dropzone = event.currentTarget as HTMLElement;
    const trackIndex = this._getTrackIndex(options, event, dropzone);
    const crossAxisPosition = trackIndex * options.trackSizeInPixels;

    const position = 0;
    const durationSize = 0;

    return InteractiveTimelineDisplayCalculator.createOrientationAwareRect(
      options,
      position,
      durationSize,
      options.trackSizeInPixels,
      crossAxisPosition
    );
  }

  private _getTrackIndex(
    options: ITimelineDisplayOptions,
    event: InteractEvent,
    dropzone: HTMLElement
  ): number {
    const isHorizontal =
      InteractiveTimelineDisplayCalculator.isHorizontal(options);

    const rawTrackPosition = isHorizontal ? event.clientY0 : event.clientX0;
    const dropzoneTrackOffset = isHorizontal
      ? dropzone.offsetTop
      : getInheritedOffsetLeft(dropzone);

    const groupYOffset = rawTrackPosition - dropzoneTrackOffset;
    if (isHorizontal) {
      return groupYOffset <= options.trackSizeInPixels
        ? 0
        : Math.floor(dropzone.clientHeight / groupYOffset);
    }
    return Math.floor(groupYOffset / options.trackSizeInPixels);
  }

  private _getUpdateDragRect(
    options: ITimelineDisplayOptions,
    timeRange: ITimePeriod,
    event: InteractEvent
  ): IInteractableRect {
    const isHorizontal =
      InteractiveTimelineDisplayCalculator.isHorizontal(options);

    const axisPos = isHorizontal ? event.clientX : event.clientY;
    const axisPos0 = isHorizontal ? event.clientX0 : event.clientY0;

    const dragDuration = Math.abs(axisPos0 - axisPos);
    const snappedDuration =
      InteractiveTimelineDisplayCalculator.roundDurationToGrid(
        options,
        dragDuration
      );

    const position = min([axisPos0, axisPos]) ?? 0;
    const unboundedPosition =
      InteractiveTimelineDisplayCalculator.roundPositionToGrid(
        options,
        timeRange,
        position,
        false
      );
    const snappedPosition =
      InteractiveTimelineDisplayCalculator.roundPositionToGrid(
        options,
        timeRange,
        position,
        true
      );
    const positionDiff = snappedPosition - unboundedPosition;
    const crossAxisPosition = isHorizontal
      ? this._rect$.value.y
      : this._rect$.value.x;

    return InteractiveTimelineDisplayCalculator.createOrientationAwareRect(
      options,
      snappedPosition,
      snappedDuration - positionDiff,
      options.trackSizeInPixels,
      crossAxisPosition
    );
  }

  private _handleTap(
    options: ITimelineDisplayOptions,
    timeRange: ITimePeriod,
    event: PointerEvent
  ): void {
    const eventTargetOutOfRange = event.target !== this.element;
    if (eventTargetOutOfRange) {
      return;
    }
    const isHorizontal =
      InteractiveTimelineDisplayCalculator.isHorizontal(options);
    const nodePosition = isHorizontal
      ? (event.layerX as number)
      : (event.layerY as number);
    const positionTime = InteractiveTimelineDisplayCalculator.positionToTime(
      options,
      timeRange,
      nodePosition,
      this.day.from
    );
    const time = floorToNearestMinuteInterval(
      positionTime,
      options.stepSizeInMins
    );
    this.tapEnd.emit({ event, time, nodePosition });
  }
}

function getInheritedOffsetLeft(elem: HTMLElement): number {
  const parent = elem.offsetParent;
  if (!parent) {
    return elem.offsetLeft;
  }
  const rawParentOffset: unknown = get(elem.offsetParent, 'offsetLeft');
  const parentOffset = isNumber(rawParentOffset) ? rawParentOffset : 0;
  return elem.offsetLeft + parentOffset;
}
