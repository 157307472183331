import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets, IActionButton } from '@principle-theorem/ng-shared';
import { Patient, toMention } from '@principle-theorem/principle-core';
import {
  IInteractiveResource,
  IPatient,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, snapshot } from '@principle-theorem/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  IUpdatePatientData,
  UpdatePatientComponent,
} from 'libs/ng-patient/src/lib/components/update-patient/update-patient.component';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PatientContextualActionButtons } from './contextual-actions/contextual-actions-buttons';
import { CreateAppointmentActionService } from './contextual-actions/create-actions/create-appointment-action.service';
import { InteractionDialogsService } from './interaction-dialogs.service';

@Injectable({
  providedIn: 'root',
})
export class PatientActionsFactoryService {
  constructor(
    public _dialogService: InteractionDialogsService,
    private _dialog: MatDialog,
    private _createAppointmentAction: CreateAppointmentActionService
  ) {}

  edit(patient$: Observable<WithRef<IPatient>>): IActionButton {
    return {
      name: 'Edit',
      icon: 'edit',
      run: () => this._editPatient(patient$),
      isDisabled$: of(false),
    };
  }

  call(
    patient$: Observable<WithRef<IPatient>>,
    resource$?: Observable<IInteractiveResource>
  ): IActionButton {
    const actionButtons = new PatientContextualActionButtons(patient$);
    return {
      name: 'Call',
      icon: 'phone',
      run: async () => {
        await this._setPatientInDialogService(patient$, resource$);
        this._dialogService.openPhone();
      },
      isDisabled$: actionButtons.disableActionButton$('Call'),
    };
  }

  sms(
    patient$: Observable<WithRef<IPatient>>,
    resource$?: Observable<IInteractiveResource>
  ): IActionButton {
    const actionButtons = new PatientContextualActionButtons(patient$);
    return {
      name: 'SMS',
      icon: 'sms',
      run: async () => {
        await this._setPatientInDialogService(patient$, resource$);
        this._dialogService.openSMS();
      },
      isDisabled$: actionButtons.disableActionButton$('SMS'),
    };
  }

  email(
    patient$: Observable<WithRef<IPatient>>,
    resource$?: Observable<IInteractiveResource>
  ): IActionButton {
    const actionButtons = new PatientContextualActionButtons(patient$);
    return {
      name: 'Email',
      icon: 'email',
      run: async () => {
        await this._setPatientInDialogService(patient$, resource$);
        this._dialogService.openEmail();
      },
      isDisabled$: actionButtons.disableActionButton$('Email'),
    };
  }

  note(
    patient$: Observable<WithRef<IPatient>>,
    resource$?: Observable<IInteractiveResource>
  ): IActionButton {
    const actionButtons = new PatientContextualActionButtons(patient$);
    return {
      name: 'Note',
      icon: 'text_snippet',
      run: async () => {
        await this._setPatientInDialogService(patient$, resource$);
        this._dialogService.openNote();
      },
      isDisabled$: actionButtons.disableActionButton$('Note'),
    };
  }

  newAppointment(patient$: Observable<WithRef<IPatient>>): IActionButton {
    return {
      name: 'New Appointment',
      icon: 'add',
      run: () => this._openNewAppointment(),
      isDisabled$: patient$.pipe(
        map((patient) => Patient.isDisabledPatient(patient.status))
      ),
    };
  }

  private async _editPatient(
    patient$: Observable<WithRef<IPatient>>
  ): Promise<void> {
    const patient = await snapshot(patient$);
    if (!patient) {
      return;
    }
    await this._dialog
      .open<UpdatePatientComponent, IUpdatePatientData, WithRef<IPatient>>(
        UpdatePatientComponent,
        DialogPresets.large({ data: { patient } })
      )
      .afterClosed()
      .toPromise();
  }

  private async _openNewAppointment(): Promise<void> {
    await this._createAppointmentAction.do();
  }

  private async _setPatientInDialogService(
    patient$: Observable<WithRef<IPatient>>,
    resource$?: Observable<IInteractiveResource>
  ): Promise<void> {
    const patient = await snapshot(patient$);
    const resource = resource$ && (await snapshot(resource$));
    const contact = toMention(patient, MentionResourceType.Patient);
    this._dialogService.dialogData = { contact, resource };
  }
}
