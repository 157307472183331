import { ITreatmentPackage } from '@principle-theorem/principle-core/interfaces';
import { v4 as uuid } from 'uuid';

export class TreatmentPackage {
  static init(overrides: Partial<ITreatmentPackage> = {}): ITreatmentPackage {
    return {
      uid: uuid(),
      name: 'New Package',
      description: '',
      isDefault: false,
      priceOverrides: [],
      ...overrides,
    };
  }
}
