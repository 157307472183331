import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  type CdkOverlayOrigin,
  type ConnectedPosition,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  type AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  type OnDestroy,
  type OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { shareReplayCold } from '@principle-theorem/shared';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  startWith,
  switchMap,
  switchMapTo,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { CustomTooltipContentDirective } from './custom-tooltip-content.directive';

export type CustomTooltipDelay = 'short' | 'long' | 'disabled';

export function getCustomTooltipInMs(
  delay: CustomTooltipDelay
): number | undefined {
  switch (delay) {
    case 'short':
      return 0;
    case 'long':
      return 300;
    default:
      return undefined;
  }
}

@Component({
  selector: 'pt-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTooltipComponent
  implements OnDestroy, OnInit, AfterContentInit
{
  private _onDestroy$ = new Subject<void>();
  private _closeDelayMs: number = 0;
  private _openDelayMs$ = new BehaviorSubject<number | undefined>(0);
  isOpened: boolean = false;
  disabled$ = new BehaviorSubject<boolean>(false);
  portal: TemplatePortal;

  @ContentChild(CustomTooltipContentDirective)
  _lazyContent: CustomTooltipContentDirective;
  @ViewChild('tooltip') dialog: ElementRef<HTMLElement>;
  @Input() target: CdkOverlayOrigin;
  positions$ = new BehaviorSubject<ConnectedPosition[]>([
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
    },
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
    },
    {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
    },
    {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
    },
  ]);

  @Input()
  set tooltipDelay(tooltipDelay: CustomTooltipDelay) {
    if (tooltipDelay) {
      this._openDelayMs$.next(getCustomTooltipInMs(tooltipDelay));
    }
  }

  @Input()
  set positions(positions: ConnectedPosition[]) {
    if (positions) {
      this.positions$.next(positions);
    }
  }

  @Input()
  set disabled(disabled: BooleanInput) {
    this.disabled$.next(coerceBooleanProperty(disabled));
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private _viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    const targetElement = this.target.elementRef.nativeElement as HTMLElement;
    const open$ = fromEvent(targetElement, 'mouseenter').pipe(
      filter(() => !this.isOpened),
      withLatestFrom(this._openDelayMs$),
      switchMap(([enterEvent, openDelayMs]) =>
        fromEvent(document, 'mousemove').pipe(
          startWith(enterEvent),
          filter(() => openDelayMs !== undefined),
          debounceTime(openDelayMs ?? 0),
          filter((event) => targetElement === event.target)
        )
      ),
      shareReplayCold()
    );

    open$
      .pipe(withLatestFrom(this.disabled$), takeUntil(this._onDestroy$))
      .subscribe(([_, disabled]) => this.changeState(!disabled));

    const close$ = fromEvent(document, 'mousemove').pipe(
      debounceTime(this._closeDelayMs),
      filter(() => this.isOpened),
      filter((event) => this.isMovedOutside(targetElement, this.dialog, event))
    );

    open$
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      .pipe(switchMapTo(close$), takeUntil(this._onDestroy$))
      .subscribe(() => this.changeState(false));
  }

  ngAfterContentInit(): void {
    if (!this._lazyContent) {
      return;
    }
    this.portal = new TemplatePortal(
      this._lazyContent.template,
      this._viewContainerRef
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  connectedOverlayDetach(): void {
    this.changeState(false);
  }

  private changeState(isOpened: boolean): void {
    this.isOpened = isOpened;
    this.changeDetectorRef.markForCheck();
  }

  private isMovedOutside(
    targetElement: HTMLElement,
    dialog: ElementRef<HTMLElement>,
    event: Event
  ): boolean {
    const eventTarget = event.target as Node | null;
    const eventTargetFound =
      targetElement.contains(eventTarget) ||
      dialog.nativeElement.contains(eventTarget);
    return !eventTargetFound;
  }
}
