import {
  type IExpectedSourceRecordSize,
  type IGetRecordResponse,
  type IPracticeMigration,
  type ISourceEntity,
  type ISourceEntityHandler,
  type ISourceEntityRecord,
  type ISourceSyncErrorData,
} from '@principle-theorem/principle-core/interfaces';
import {
  ComparableValue,
  Query,
  QueryConstraint,
  Timestamp,
  bufferedQuery$,
  errorNil,
  find$,
  multiConcatMap,
  query$,
  snapshot,
  toQuery,
  toTimestamp,
  where,
  type CollectionReference,
  type IReffable,
  type Timezone,
  type WithRef,
} from '@principle-theorem/shared';
import { flow, isEqual } from 'lodash';
import { of, type Observable } from 'rxjs';
import { catchError, concatMap, map, take } from 'rxjs/operators';
import { PracticeMigration } from '../practice-migrations';
import { runQuery } from './connection';
import { SourceEntity } from './source-entity';
import { SourceEntityRecord } from './source-entity-record';
import {
  convertDateToTimestampFn,
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  runSourceQuery$,
} from './source-helpers';

export abstract class BaseSourceEntity<
  In extends object,
  Translations = unknown,
  Filters extends object = object,
> implements ISourceEntityHandler<In[], Translations, Filters>
{
  abstract sourceEntity: ISourceEntity;
  abstract entityResourceType: string;
  abstract sourceQuery: string | string[];
  abstract verifySourceFn: (data: unknown) => data is In;
  estimateQuery?: string | string[];
  requiredEntities = {};
  dateFilterField?: keyof Filters | string;
  defaultOffsetSize?: number;

  transformDataFn: (records: In[]) => In[] = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertDateToTimestampFn(),
  ]);

  getFromSource$(
    migration: WithRef<IPracticeMigration>,
    initialOffset: number = 0,
    endOffset?: number
  ): Observable<In[] | ISourceSyncErrorData> {
    return runSourceQuery$<In, In>(
      migration,
      this.sourceQuery,
      this.transformDataFn,
      this.defaultOffsetSize,
      initialOffset,
      endOffset
    );
  }

  abstract getSourceRecordId(data: In): string | number;
  abstract getSourceLabel(record: In): string;
  abstract translate(initialValue: In, timezone: Timezone): Translations;

  verifySource(data: unknown): data is In {
    return this.verifySourceFn(data);
  }

  getEntity$(
    migration: IReffable<IPracticeMigration>
  ): Observable<ISourceEntity | undefined> {
    return find$(
      PracticeMigration.sourceEntityCol(migration),
      where('metadata', '==', this.sourceEntity.metadata)
    );
  }

  getMapResourceType(): string {
    return this.entityResourceType;
  }

  canHandle(source: ISourceEntity): boolean {
    return isEqual(
      source.metadata.idPrefix,
      this.sourceEntity.metadata.idPrefix
    );
  }

  getCollection$(
    migration: IReffable<IPracticeMigration>
  ): Observable<CollectionReference<ISourceEntityRecord<Filters>>> {
    return PracticeMigration.findSourceEntity$(
      migration,
      this.sourceEntity
    ).pipe(
      take(1),
      errorNil(
        `Source entity not found for ${this.sourceEntity.metadata.label}`
      ),
      map((sourceEntity) =>
        SourceEntity.recordCol({
          ref: sourceEntity.ref,
        })
      )
    );
  }

  combineRecordWithData$(
    record: WithRef<ISourceEntityRecord<Filters>>
  ): Observable<IGetRecordResponse<In, Translations, Filters>> {
    return SourceEntityRecord.getLatestData$<In, Translations>(record).pipe(
      take(1),
      map((data) => ({
        record,
        data,
      }))
    );
  }

  getRecord(
    migration: IReffable<IPracticeMigration>,
    recordId: string | number
  ): Promise<IGetRecordResponse<In, Translations, Filters> | undefined> {
    return snapshot(
      this.getCollection$(migration).pipe(
        concatMap((collection) =>
          find$(
            collection,
            where(
              'uid',
              '==',
              SourceEntity.determineUidForRecord(recordId, this.sourceEntity)
            )
          )
        ),
        errorNil(
          `Record not found for ${this.sourceEntity.metadata.label} recordId: ${recordId}`
        ),
        concatMap((record) => this.combineRecordWithData$(record)),
        catchError(() => of(undefined))
      )
    );
  }

  getRecords$(
    migration: IReffable<IPracticeMigration>,
    bufferSize: number = 50,
    limit?: number,
    queryConstraints: QueryConstraint[] = [],
    initialRecord?: WithRef<ISourceEntityRecord<Filters>>,
    fromDate?: Timestamp,
    toDate?: Timestamp
  ): Observable<IGetRecordResponse<In, Translations, Filters>[]> {
    return this.getCollection$(migration).pipe(
      concatMap((collection) => {
        const queryFn = (): Query<ISourceEntityRecord<Filters>> => {
          if (!fromDate || !toDate || !this.dateFilterField) {
            return toQuery(collection, ...queryConstraints);
          }
          return toQuery(
            collection,
            where(`filters.${String(this.dateFilterField)}`, '>=', fromDate),
            where(`filters.${String(this.dateFilterField)}`, '<=', toDate),
            ...queryConstraints
          );
        };

        return bufferedQuery$(
          queryFn(),
          bufferSize,
          fromDate && toDate && this.dateFilterField
            ? `filters.${String(this.dateFilterField)}`
            : 'ref',
          'asc',
          initialRecord,
          limit
        );
      }),
      multiConcatMap((record) => this.combineRecordWithData$(record))
    );
  }

  async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const queries = this._getEstimateQuery();

    const response = await runQuery<{ count: number }>(
      migration,
      queries.map((query) => `SELECT COUNT(*) FROM (${query}) src`)
    );
    return {
      expectedSize: response.rows[0].count,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }

  filterRecords<T extends ComparableValue>(
    migration: IReffable<IPracticeMigration>,
    filterKey: keyof Filters,
    value: T,
    fromDate?: Timestamp,
    toDate?: Timestamp,
    filterFn?: (
      record: IGetRecordResponse<In, Translations, Filters>
    ) => boolean
  ): Promise<IGetRecordResponse<In, Translations, Filters>[]> {
    return snapshot(
      this.getCollection$(migration).pipe(
        concatMap((collection) => {
          if (filterKey === this.dateFilterField || !fromDate || !toDate) {
            return query$(
              collection,
              where(`filters.${String(filterKey)}`, '==', value)
            );
          }
          return query$(
            collection,
            where(`filters.${String(filterKey)}`, '==', value),
            where(`filters.${String(this.dateFilterField)}`, '>=', fromDate),
            where(`filters.${String(this.dateFilterField)}`, '<=', toDate)
          );
        }),
        errorNil(
          `No records found for ${
            this.sourceEntity.metadata.label
          } ${filterKey.toString()}: ${JSON.stringify(value)}`
        ),
        multiConcatMap((record) => this.combineRecordWithData$(record)),
        map((records) => (filterFn ? records.filter(filterFn) : records))
      )
    );
  }

  private _getEstimateQuery(): string[] {
    if (!this.estimateQuery) {
      return Array.isArray(this.sourceQuery)
        ? this.sourceQuery
        : [this.sourceQuery];
    }
    return Array.isArray(this.estimateQuery)
      ? this.estimateQuery
      : [this.estimateQuery];
  }
}
