import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import { type ConnectedPosition } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  CurrentScopeFacade,
  GlobalStoreService,
} from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  AppointmentSummary,
  CalendarEventSummary,
  EVENT_TYPE_COLOUR_MAP,
  IEventStyle,
  IPracticeSettings,
  IScheduleSummaryEventable,
  PreBlockEvent,
  UNCATEGORISED_CATEGORY,
  isAppointmentEventType,
  isAppointmentSummary,
  isPreBlockEvent,
  type ITimelineDisplayOptions,
  type PracticeTimelineTooltipDelay,
  isCalendarEventSummary,
} from '@principle-theorem/principle-core/interfaces';
import {
  exceedsLuminanceThreshold,
  filterUndefined,
  isWithRef,
  type ITimePeriod,
} from '@principle-theorem/shared';
import { colord } from 'colord';
import { isEqual, startCase } from 'lodash';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  of,
  type Observable,
} from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import {
  isManageEventable,
  type IManageEventable,
} from '../interactive-timeline/interactive-timeline-logic';

const verticalPositions: ConnectedPosition[] = [
  {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
  },
  {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
  },
  {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
  },
  {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
  },
];

const horizontalPositions: ConnectedPosition[] = [
  {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
  },
  {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
  },
  {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
  },
  {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
  },
];

@Component({
  selector: 'pr-timeline-event-display',
  templateUrl: './timeline-event-display.component.html',
  styleUrls: ['./timeline-event-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineEventDisplayComponent {
  private _options$ = new ReplaySubject<ITimelineDisplayOptions>(1);
  event$ = new ReplaySubject<IScheduleSummaryEventable | IManageEventable>(1);
  eventStyle$: Observable<IEventStyle>;
  range$ = new ReplaySubject<ITimePeriod>(1);
  disabled$ = new BehaviorSubject<boolean>(false);
  positions$: Observable<ConnectedPosition[]>;
  eventLabel$: Observable<string>;
  hasTags$: Observable<boolean>;
  tooltipDelay$: Observable<PracticeTimelineTooltipDelay>;
  calendarEvent$: Observable<CalendarEventSummary>;
  isPublicPreBlock$: Observable<boolean>;
  appointment$: Observable<AppointmentSummary>;
  pinnedNotes$: Observable<string[]>;
  trackByPinnedNote = TrackByFunctions.variable<string>();

  @Input()
  set options(options: ITimelineDisplayOptions) {
    if (options) {
      this._options$.next(options);
    }
  }

  @Input()
  set event(event: IScheduleSummaryEventable | IManageEventable) {
    if (event) {
      this.event$.next(event);
    }
  }

  @Input()
  set range(range: ITimePeriod) {
    if (range) {
      this.range$.next(range);
    }
  }

  @Input()
  set disabled(disabled: BooleanInput) {
    this.disabled$.next(coerceBooleanProperty(disabled));
  }

  constructor(
    private _global: GlobalStoreService,
    private _currentScope: CurrentScopeFacade
  ) {
    const colourOverrides$ = this._currentScope.currentPractice$.pipe(
      filterUndefined(),
      map((practice) => practice.settings.eventColourOverrides),
      distinctUntilChanged<IPracticeSettings['eventColourOverrides']>(isEqual)
    );

    this.eventStyle$ = combineLatest([colourOverrides$, this.event$]).pipe(
      switchMap(([colourOverrides, event]) => {
        if (isManageEventable(event)) {
          return of(this._getManageEventColour(event));
        }
        return this._getEventColour$(event, colourOverrides);
      })
    );

    this.eventLabel$ = this.event$.pipe(
      map((event) => this._getEventLabel(event))
    );

    this.positions$ = this._options$.pipe(
      map((options) => options.orientation),
      distinctUntilChanged(),
      map((orientation) =>
        String(orientation) === 'vertical'
          ? verticalPositions
          : horizontalPositions
      )
    );

    this.tooltipDelay$ = this._options$.pipe(
      map((options) => options.tooltipDelay),
      distinctUntilChanged()
    );

    this.appointment$ = this.event$.pipe(
      filter((event) => isAppointmentSummary(event)),
      map((appointment) => appointment as AppointmentSummary)
    );

    this.calendarEvent$ = this.event$.pipe(
      filter((event) => isCalendarEventSummary(event)),
      filter(isWithRef),
      map((calendarEvent) => calendarEvent as CalendarEventSummary)
    );

    this.isPublicPreBlock$ = this.event$.pipe(
      map(
        (event) =>
          !isManageEventable(event) &&
          isPreBlockEvent(event.event) &&
          event.event.isPublic
      )
    );

    this.hasTags$ = this.event$.pipe(
      map((eventable) => {
        if (isManageEventable(eventable)) {
          return [];
        }
        return eventable.metadata.tags;
      }),
      map((tags) => tags.length > 0)
    );

    this.pinnedNotes$ = this.event$.pipe(
      map((eventable) =>
        isManageEventable(eventable) ? [] : eventable.metadata.pinnedNotes
      )
    );
  }

  private _getEventLabel(
    event: IScheduleSummaryEventable | IManageEventable
  ): string {
    if (isManageEventable(event)) {
      return `New ${startCase(event.event.type)}`;
    }
    return event.metadata.label;
  }

  private _getManageEventColour(event: IManageEventable): IEventStyle {
    const eventColour = EVENT_TYPE_COLOUR_MAP[event.event.type];
    return {
      backgroundColor: eventColour,
      color: this._getOverlayColor(eventColour),
    };
  }

  private _getOverlayColor(backgroundColor: string): string {
    const darkOverlay = 'rgba(0, 0, 0, .6)';
    const lightOverlay = 'rgba(255, 255, 255, .9)';
    return exceedsLuminanceThreshold(backgroundColor, 0.5)
      ? darkOverlay
      : lightOverlay;
  }

  private _getEventColour$(
    event: IScheduleSummaryEventable,
    colourOverrides?: IPracticeSettings['eventColourOverrides']
  ): Observable<IEventStyle> {
    const eventColour =
      colourOverrides?.[event.event.type] ??
      EVENT_TYPE_COLOUR_MAP[event.event.type];

    if (isPreBlockEvent(event.event)) {
      return this._getPreBlockEventColour$(eventColour, event.event);
    }

    if (!isAppointmentEventType(event.event.type)) {
      return of(this._createEventStyle(eventColour));
    }

    const appointmentSummary = event as AppointmentSummary;

    if (!appointmentSummary.metadata.categoryRef) {
      return of(this._createEventStyle(UNCATEGORISED_CATEGORY.colour.value));
    }

    return this._global
      .getTreatmentCategory$(appointmentSummary.metadata.categoryRef)
      .pipe(
        map((category) =>
          category ? category.colour.value : UNCATEGORISED_CATEGORY.colour.value
        ),
        map((backgroundColor) => this._createEventStyle(backgroundColor))
      );
  }

  private _getPreBlockEventColour$(
    defaultColour: string,
    event: PreBlockEvent
  ): Observable<IEventStyle> {
    const defaultOpacity = colord(defaultColour).alpha();
    if (event.colourOverride) {
      return of(
        this._createEventStyle(
          colord(event.colourOverride).alpha(defaultOpacity).toHex()
        )
      );
    }

    if (event.allowedTreatmentCategories.length === 1) {
      return this._global
        .getTreatmentCategory$(event.allowedTreatmentCategories[0])
        .pipe(
          map((category) => {
            const backgroundColor = category
              ? colord(category.colour.value).alpha(defaultOpacity).toHex()
              : defaultColour;
            return this._createEventStyle(backgroundColor);
          })
        );
    }
    return of(this._createEventStyle(defaultColour));
  }

  private _createEventStyle(backgroundColor: string): IEventStyle {
    const darkOverlay = 'rgba(0, 0, 0, .6)';
    const lightOverlay = 'rgba(255, 255, 255, .9)';

    return {
      backgroundColor,
      color: exceedsLuminanceThreshold(backgroundColor, 0.5)
        ? darkOverlay
        : lightOverlay,
    };
  }
}
