import { MeasureFormatter } from '@principle-theorem/principle-core/interfaces';
import { IAccountCreditDimension } from '@principle-theorem/reporting/interfaces';
import { BaseDimensionMeasures } from '../base-measures';
import { DataAccessorFactory, MeasurePath } from '../data-accessor-factory';
import {
  basicFilterAccessor,
  MeasureTransformMap,
} from '../measure-properties';
import {
  CanBeChartedProperty,
  CanDoAllProperty,
  CanQueryByTimestampProperty,
} from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';

export class AccountCreditDimensionMeasures
  extends BaseDimensionMeasures
  implements
    MeasureTransformMap<
      Pick<
        IAccountCreditDimension,
        'amount' | 'used' | 'createdAt' | 'description'
      >
    >
{
  get description(): CanBeChartedProperty {
    const propertyName = 'description';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Account Credit Description',
        summary:
          'The description that has been added to the account credit to outline its purpose',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get amount(): CanBeChartedProperty {
    const propertyName = 'amount';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Account Credit Amount',
        summary:
          'The total value of the account credit, regardless of how much has been used.',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get used(): CanBeChartedProperty {
    const propertyName = 'used';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Account Credit Amount Used',
        summary: 'The amount of the account credit that has been used',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get createdAt(): CanQueryByTimestampProperty {
    const propertyName = 'createdAt';
    const measure = this.measureRef(MeasurePath.timestamp(propertyName));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Account Credit Created Date',
        summary:
          'Date that the account credit was created. This is useful for grouping account credits by day.',
        formatter: MeasureFormatter.Day,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get updatedAt(): CanQueryByTimestampProperty {
    const propertyName = 'updatedAt';
    const measure = this.measureRef(MeasurePath.timestamp(propertyName));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Account Credit Last Updated Date',
        summary: 'Date that the account credit was last updated',
        formatter: MeasureFormatter.Day,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  // We never use this in the app, so I'm commenting it out for now
  // get reference(): CanBeChartedProperty {
  //   const propertyName = 'reference';
  //   const measure = this.measureRef(propertyName);
  //   return MeasurePropertyFactory.string(
  //     {
  //       id: this._pathWithPrefix(propertyName),
  //       label: 'Account Credit Reference',
  //       summary: '',
  //     },
  //     measure,
  //     this.buildQuery().attributes([measure.attributePath]).get()
  //   );
  // }

  get type(): CanBeChartedProperty {
    const propertyName = 'type';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Account Credit Type',
        summary:
          'How the account credit was created. This can be from either taking a deposit, refunding account credit, or overpayment on an invoice.',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get depositUid(): CanBeChartedProperty {
    const propertyName = 'depositUid';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Account Credit Deposit UID',
        summary:
          'The corresponding line item from the invoice that took the deposit for the account credit',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get reservedForPractitioner(): CanDoAllProperty {
    const propertyName = 'reservedFor.practitioner';
    const measure = this.measureRef(propertyName);
    const labelPath = `${propertyName}.name`;
    const labelMeasure = this.measureRef(labelPath);
    const dataPath = MeasurePath.docRef(`${propertyName}.ref`);
    const dataMeasure = this.measureRef(dataPath);
    return new CanDoAllProperty({
      metadata: {
        id: this._pathWithPrefix('reservedForPractitioner'),
        label: 'Reserved For',
        summary:
          'Which practitioner (if any) the account credit has been reserved for',
      },
      measure: {
        propertyName: measure.attributePath,
        query: this.buildQuery().attributes([measure.attributePath]).get(),
        dataAccessor: DataAccessorFactory.property<string>(
          labelMeasure.factPropertyPath,
          ''
        ),
        filterAccessor: basicFilterAccessor(dataMeasure.factPropertyPath),
      },
      groupMeasure: {
        queryAttributes: [
          measure.attributePath,
          dataMeasure.attributePath,
          labelMeasure.attributePath,
        ],
        dataAccessor: DataAccessorFactory.property<string>(
          dataMeasure.factPropertyPath,
          ''
        ),
        labelAccessor: DataAccessorFactory.property<string>(
          labelMeasure.factPropertyPath,
          ''
        ),
      },
    });
  }

  get isUsed(): CanDoAllProperty {
    const measure = this.measureRef('used');
    return MeasurePropertyFactory.boolean(
      {
        id: this._pathWithPrefix('isUsed'),
        label: 'Account Credit Is Used',
        summary: 'Whether the account credit has been used completely or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }
}
