import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { type Moment } from 'moment-timezone';
import * as moment from 'moment-timezone';
import {
  getCurrentTime,
  type DayOfWeek,
  CALENDAR_DAYS_OF_WEEK,
  type ITimePeriod,
  buildMomentsRange,
  DAYS_IN_WEEK,
  DAY_OF_WEEK_FORMAT,
} from '@principle-theorem/shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';

interface IRowMap {
  index: number;
  days: Moment[];
}

@Component({
    selector: 'pr-month-view',
    templateUrl: './month-view.component.html',
    styleUrls: ['./month-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MonthViewComponent {
  private _currentTime: Moment = getCurrentTime();
  trackByDay = TrackByFunctions.variable<DayOfWeek>();
  trackByMonth = TrackByFunctions.field<IRowMap>('index');
  daysOfWeek: DayOfWeek[] = CALENDAR_DAYS_OF_WEEK;
  monthMap$: Observable<IRowMap[]>;
  range$: ReplaySubject<ITimePeriod> = new ReplaySubject<ITimePeriod>(1);

  @Input()
  set range(range: ITimePeriod) {
    if (!range) {
      return;
    }
    this.range$.next(range);
  }

  constructor() {
    this.monthMap$ = this.range$.pipe(
      map((range) => {
        return buildMomentsRange(
          {
            from: range.from.clone().startOf('month').day(0),
            to: range.from.clone().endOf('month').day(6),
          },
          moment.duration(1, 'day')
        );
      }),
      map((days) => {
        const rowMap = [];
        const rowCount = Math.floor(days.length / DAYS_IN_WEEK);
        for (let index = 0; index < rowCount; index++) {
          const start = index * DAYS_IN_WEEK;
          rowMap.push({
            index,
            days: days.slice(start, start + DAYS_IN_WEEK),
          });
        }
        return rowMap;
      })
    );
  }

  isTodayHeader(dayOfWeek: DayOfWeek): boolean {
    return (
      this._currentTime.format(DAY_OF_WEEK_FORMAT).toLowerCase() ===
      String(dayOfWeek)
    );
  }
}
