import { createFeatureSelector, createSelector } from '@ngrx/store';
import { type SerialisedData } from '@principle-theorem/shared';
import { conditionConfigurationAdapter } from './reducers/chartable-configurations/charted-configurations.reducer';
import { multiTreatmentConfigurationAdapter } from './reducers/chartable-configurations/multi-treatment-configurations.reducer';
import { treatmentConfigurationAdapter } from './reducers/chartable-configurations/treatment-configurations.reducer';
import { type IChartState } from './reducers/reducers';

export const CHART_FEATURE_KEY = 'chart';

export const getChartModuleState =
  createFeatureSelector<SerialisedData<IChartState>>(CHART_FEATURE_KEY);

export const getTreatmentConfigurations = createSelector(
  getChartModuleState,
  treatmentConfigurationAdapter.getSelectors(
    (state: SerialisedData<IChartState>) =>
      state.chartableConfigurations.treatments
  ).selectAll
);

export const getMultiTreatmentConfigurations = createSelector(
  getChartModuleState,
  multiTreatmentConfigurationAdapter.getSelectors(
    (state: SerialisedData<IChartState>) =>
      state.chartableConfigurations.multiTreatments
  ).selectAll
);

export const getConditionConfigurations = createSelector(
  getChartModuleState,
  conditionConfigurationAdapter.getSelectors(
    (state: SerialisedData<IChartState>) =>
      state.chartableConfigurations.conditions
  ).selectAll
);

export const getConditionConfigurationEntities = createSelector(
  getChartModuleState,
  conditionConfigurationAdapter.getSelectors(
    (state: SerialisedData<IChartState>) =>
      state.chartableConfigurations.conditions
  ).selectEntities
);

export const getTreatmentConfigurationEntities = createSelector(
  getChartModuleState,
  treatmentConfigurationAdapter.getSelectors(
    (state: SerialisedData<IChartState>) =>
      state.chartableConfigurations.treatments
  ).selectEntities
);

export const getMultiTreatmentConfigurationEntities = createSelector(
  getChartModuleState,
  multiTreatmentConfigurationAdapter.getSelectors(
    (state: SerialisedData<IChartState>) =>
      state.chartableConfigurations.multiTreatments
  ).selectEntities
);
