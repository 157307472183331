import { Injectable } from '@angular/core';
import { StateBasedNavigationService } from '@principle-theorem/ng-principle-shared';
import { Appointment } from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  AppointmentSummary,
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { IReffable, type WithRef } from '@principle-theorem/shared';
import { AppointmentCardAction } from './appointment-card-action-interface';

@Injectable()
export class CheckoutAppointmentActionService extends AppointmentCardAction {
  name = 'Check Out Patient';

  constructor(private _stateNav: StateBasedNavigationService) {
    super();
  }

  isVisible(appointment: WithRef<IAppointment> | AppointmentSummary): boolean {
    return Appointment.isCheckingOut(appointment);
  }

  async run(
    appointment: WithRef<IAppointment>,
    patient: IReffable<IPatient>
  ): Promise<void> {
    await this._stateNav.brand([
      'patients',
      patient.ref.id,
      'appointments',
      appointment.ref.id,
      'outgoing',
    ]);
  }

  updateEventableSummary(
    eventable: AppointmentSummary,
    updateFn: (
      oldEvent: AppointmentSummary,
      newEvent: AppointmentSummary
    ) => void
  ): void {
    const metadata = {
      ...eventable.metadata,
      status: AppointmentStatus.CheckingOut,
    };

    updateFn(eventable, { ...eventable, metadata });
  }
}
