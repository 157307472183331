<ng-container *ngIf="sections$ | async as sections">
  @for (section of sections; track section.date) {
    <div class="gap-group">
      <h4>{{ section.date | moment | amDateFormat: dateFormat }}</h4>
      <div class="timeline">
        <mat-accordion class="popout timeline-content">
          <ng-container *ngIf="section.gaps$ | async as gaps">
            @for (gap of gaps; track gap.uid) {
              <mat-expansion-panel
                [hideToggle]="true"
                [expanded]="gap | map: isExpanded : this"
                (opened)="setExpanded(gap)"
              >
                <mat-expansion-panel-header>
                  <pr-gap-header
                    fxFlex
                    [gap]="gap"
                    [showPractitionerIcon]="true"
                  />
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                  <div class="timeline-card">
                    <mat-divider />
                    <pr-gap-card [gap]="gap" />
                  </div>
                </ng-template>
              </mat-expansion-panel>
            }
          </ng-container>
        </mat-accordion>
      </div>
    </div>
  }
</ng-container>
