import { type INamedDocument } from '@principle-theorem/shared';
import {
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import { type IAppointment } from '../appointment/appointment';
import { type IPatient } from '../patient/patient';
import { type GapOfferStatus } from './wait-list-item';

export interface ICandidate {
  appointment: DocumentReference<IAppointment>;
  patient: INamedDocument<IPatient>;
  status: GapOfferStatus;
  offerTimeFrom: Timestamp;
  offerTimeTo: Timestamp;
}
