import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  NgSharedModule,
  TableOfContentsComponent,
  TableOfContentsHeadingDirective,
} from '@principle-theorem/ng-shared';
import { BehaviorSubject, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { debounceTime, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-toc-page',
    templateUrl: './toc-page.component.html',
    styleUrls: ['./toc-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, NgSharedModule]
})
export class TocPageComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  tableOfContents$ = new ReplaySubject<QueryList<TableOfContentsComponent>>(1);
  headings$ = new ReplaySubject<QueryList<TableOfContentsHeadingDirective>>(1);
  mainPage$ = new ReplaySubject<ElementRef<HTMLElement>>(1);
  title$ = new BehaviorSubject<string>('');
  @Input() sidebarClass: string = '';

  @Input()
  set sidebarTitle(title: string) {
    if (title) {
      this.title$.next(title);
    }
  }

  @ViewChildren(TableOfContentsComponent)
  set tableOfContents(tableOfContents: QueryList<TableOfContentsComponent>) {
    if (tableOfContents) {
      this.tableOfContents$.next(tableOfContents);
    }
  }

  @ContentChildren(TableOfContentsHeadingDirective, { descendants: true })
  set headings(headings: QueryList<TableOfContentsHeadingDirective>) {
    if (headings) {
      this.headings$.next(headings);
    }
  }

  @ViewChild('mainPage', { read: ElementRef })
  set mainPage(mainPage: ElementRef<HTMLElement>) {
    if (mainPage) {
      this.mainPage$.next(mainPage);
    }
  }

  constructor(private _changeDetectorRef: ChangeDetectorRef) {
    const headingChanges$ = this.headings$.pipe(
      switchMap((headings) => headings.changes.pipe(startWith(headings)))
    );
    combineLatest([
      this.title$,
      this.tableOfContents$,
      this.mainPage$,
      headingChanges$,
    ])
      .pipe(debounceTime(50), takeUntil(this._onDestroy$))
      .subscribe(([title, tableOfContentsList, mainPage]) =>
        this._updateTableOfContents(title, tableOfContentsList, mainPage)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _updateTableOfContents(
    title: string,
    tableOfContentsList: QueryList<TableOfContentsComponent>,
    mainPage: ElementRef<HTMLElement>
  ): void {
    tableOfContentsList.map((tableOfContents) =>
      tableOfContents.addHeaders(title, mainPage.nativeElement)
    );
    this._changeDetectorRef.detectChanges();
  }
}
