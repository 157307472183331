<div class="select-appointment">
  <mat-table
    matSort
    matSortDisableClear
    matSortActive="score"
    [dataSource]="dataSource"
  >
    <mat-header-row *matHeaderRowDef="displayedColumns$ | async" />
    <mat-row *matRowDef="let row; columns: displayedColumns$ | async" />

    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <pr-appointment-search-type-select *ngIf="canDoubleBook$ | async" />
      </mat-header-cell>
      <mat-cell *matCellDef="let suggestion">
        <mat-checkbox
          class="-ml-2"
          color="primary"
          [disabled]="suggestion | map: isBlocked"
          (click)="$event.stopPropagation()"
          (change)="$event ? toggle(suggestion, true, $event) : null"
          [checked]="selected$ | pairMap$: suggestion : isSelected | async"
        />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Date
      </mat-header-cell>
      <mat-cell *matCellDef="let suggestion">
        <pr-formatted-event-field
          class="truncate"
          field="from"
          [event]="suggestion.event"
          [format]="dateFormat"
        />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="time">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Time
      </mat-header-cell>
      <mat-cell *matCellDef="let suggestion">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div>
            <pr-formatted-event-field
              class="truncate"
              field="from"
              [event]="suggestion.event"
              [format]="timeFormat"
            />
          </div>
          <ng-container
            *ngIf="
              suggestion.overlappingEvents.length ||
              suggestion.adjacentEvents.length
            "
          >
            <mat-icon
              matTooltip="View Appointment Context"
              (click)="showContext(suggestion)"
              >visibility</mat-icon
            >
          </ng-container>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="duration">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Duration
      </mat-header-cell>
      <mat-cell *matCellDef="let suggestion">
        <div class="rating-score">
          <span
            class="truncate"
            [ngClass]="suggestion | map: getDurationClass : this"
          >
            {{ suggestion.event | duration }} mins
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="distance">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        From Now
      </mat-header-cell>
      <mat-cell *matCellDef="let suggestion">
        <span class="truncate">{{ suggestion | map: distance }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="score">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Score
      </mat-header-cell>
      <mat-cell *matCellDef="let suggestion">
        <div fxLayout="row" fxLayoutAlign="start center">
          <pr-appointment-score [score]="suggestion.score" />

          <ng-container *ngIf="suggestion | map: showScoreIcon">
            <mat-icon
              class="score-icon"
              [ngClass]="{
                hasConflicts: suggestion.schedulingConflicts.length,
                hasTags: suggestion.intersectingTags.length
              }"
              #tooltipOrigin="cdkOverlayOrigin"
              cdkOverlayOrigin
            >
              info_outline
            </mat-icon>

            <pt-custom-tooltip [target]="tooltipOrigin">
              <pr-appointment-suggestion-tooltip [suggestion]="suggestion" />
            </pt-custom-tooltip>
          </ng-container>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="brand">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Brand
      </mat-header-cell>
      <mat-cell *matCellDef="let suggestion">
        <span class="cell-padding truncate">{{
          suggestion.event.practice.name
        }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="practitioner">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Practitioner
      </mat-header-cell>
      <mat-cell *matCellDef="let suggestion">
        <span class="truncate">{{ suggestion.practitioner.name }}</span>
      </mat-cell>
    </ng-container>
  </mat-table>

  <div class="table-footer" fxLayout="row" fxLayoutAlign="space-between center">
    <div *ngIf="dataSource.data.length">
      <p *ngIf="selected$ | async as selected; else noneSelected">
        Appointment Selected:
        <pr-formatted-event-field
          field="from"
          [event]="selected.event"
          [format]="dateService.format.dateWithTimes"
        />
      </p>
      <ng-template #noneSelected>
        <p>No Appointment Selected</p>
      </ng-template>
    </div>
    <mat-paginator
      #paginator
      [length]="dataSource.data.length"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      showFirstLastButtons
    />
  </div>
</div>
