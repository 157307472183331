@if (forms$ | async; as forms) {
  <mat-form-field subscriptSizing="dynamic" class="w-full">
    <mat-label>{{ label }}</mat-label>
    <mat-select [formControl]="formSelectCtrl" [compareWith]="compareFn">
      <mat-select-trigger>
        @if (formSelectCtrl.value) {
          <div class="mr-6">
            <ng-container
              [ngTemplateOutlet]="formSelectorLabel"
              [ngTemplateOutletContext]="{ form: formSelectCtrl.value }"
            />
          </div>
        } @else {
          <span class="opacity-50">No form selected</span>
        }
      </mat-select-trigger>

      @for (form of forms; track form.ref.path) {
        <mat-option [value]="form">
          <ng-container
            [ngTemplateOutlet]="formSelectorLabel"
            [ngTemplateOutletContext]="{ form: form }"
          />
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  @if (helpTooltip) {
    <mat-icon class="icon-sm opacity-50" [matTooltip]="helpTooltip"
      >help</mat-icon
    >
  }
}

<ng-template #formSelectorLabel let-form="form">
  <div class="flex grow flex-row items-center gap-4">
    <div class="flex grow">
      {{ form.updatedAt | moment | amDateFormat: dateFormat }}
    </div>
    <pr-form-status [status]="form.status" />
  </div>
</ng-template>
