import { Validators } from '@angular/forms';
import {
  initVersionedSchema,
  type VersionedSchema,
} from '@principle-theorem/editor';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  type ITemplateDefinition,
  TemplateScope,
  TemplateType,
} from '@principle-theorem/principle-core/interfaces';

export type ITemplateFormData = Omit<
  ITemplateDefinition,
  | 'ownerScope'
  | 'deleted'
  | 'renderedTemplate'
  | 'variants'
  | 'automatedConfigurationRef'
>;

export type TemplateChangeData = ITemplateFormData &
  Pick<ITemplateDefinition, 'variants' | 'renderedTemplate'>;

export class TemplateForm extends TypedFormGroup<ITemplateFormData> {
  constructor() {
    super({
      name: new TypedFormControl<string>(undefined, Validators.required),
      type: new TypedFormControl<TemplateType>(
        TemplateType.PlainText,
        Validators.required
      ),
      scope: new TypedFormControl<TemplateScope>(
        TemplateScope.None,
        Validators.required
      ),
      content: new TypedFormControl<VersionedSchema>(
        initVersionedSchema(),
        Validators.required
      ),
    });
  }
}
