import {
  type DocumentReference,
  type Timestamp,
  type Timezone,
  isDocRef,
  isObject,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type IAppointment } from './appointment';
import { IGeoCoordinates } from '../geo-data';

export interface IPatientConfirmAppointmentTokenData {
  appointment: DocumentReference<IAppointment>;
}

export function isPatientConfirmAppointmentTokenData(
  data: unknown
): data is IPatientConfirmAppointmentTokenData {
  return isObject(data) && isDocRef(data.appointment);
}

export interface IConfirmAppointmentRequest {
  tokenUid: string;
}

export function isConfirmAppointmentRequest(
  data: unknown
): data is IConfirmAppointmentRequest {
  return isObject(data) && isString(data.tokenUid);
}

export interface IConfirmAppointmentInfo {
  practice: string;
  practiceTimezone: Timezone;
  practiceAddress: string;
  practiceCoordinates?: IGeoCoordinates;
  startAt: Timestamp;
  endAt: Timestamp;
  alreadyConfirmed: boolean;
  practitioner?: string;
  treatment?: string;
  duration?: string;
}
