import { IBaseMigrationStaffer } from '../../../interfaces';
import { StafferMappingHandler } from '../../../mappings/staff';
import {
  IOasisProvider,
  ProviderSourceEntity,
} from '../../source/entities/providers';

export class OasisStafferMappingHandler extends StafferMappingHandler<
  IOasisProvider,
  ProviderSourceEntity
> {
  override sourceEntity = new ProviderSourceEntity();
  translateFn = (record: IOasisProvider): IBaseMigrationStaffer => {
    return {
      id: record.id,
      name: record.name,
      providerNo: record.providerNumber,
    };
  };
}
