import {
  IAppointment,
  IInteractionTypeMap,
  ILabJob,
  interactionTypeDisplayMap,
  IScheduleSummaryEventable,
  ISchedulingAlert,
  SchedulingAlertTargetType,
} from '@principle-theorem/principle-core/interfaces';
import { asDocRef, doc$, WithRef } from '@principle-theorem/shared';
import { Observable, of } from 'rxjs';

export class SchedulingAlert {
  static getIcon(alert: ISchedulingAlert): string {
    return interactionTypeDisplayMap[alert.type as keyof IInteractionTypeMap];
  }

  static target$(
    alert: ISchedulingAlert
  ): Observable<
    | WithRef<IAppointment>
    | WithRef<ILabJob>
    | IScheduleSummaryEventable
    | undefined
  > {
    if (!alert.target) {
      return of(undefined);
    }
    switch (alert.targetType) {
      case SchedulingAlertTargetType.Appointment:
        return doc$(asDocRef<IAppointment>(alert.target));
      case SchedulingAlertTargetType.LabJob:
        return doc$(asDocRef<ILabJob>(alert.target));
      case SchedulingAlertTargetType.Gap:
        return of(alert.gap);
      default:
        return of(undefined);
    }
  }
}
