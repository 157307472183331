import { IStatusHistory } from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  sortByUpdatedAt,
  toTimestamp,
} from '@principle-theorem/shared';
import { first } from 'lodash';

export class StatusHistory {
  static add<T>(
    status: T,
    statusHistory: IStatusHistory<T>[] = [],
    updatedAt?: Timestamp
  ): IStatusHistory<T>[] {
    const current = this.currentStatus(statusHistory);
    if (current?.status === status) {
      return statusHistory;
    }
    return this.sort([
      ...statusHistory,
      {
        status,
        updatedAt: updatedAt ?? toTimestamp(),
      },
    ]);
  }

  static sort<T>(statusHistory: IStatusHistory<T>[]): IStatusHistory<T>[] {
    return statusHistory.sort(sortByUpdatedAt);
  }

  static currentStatus<T>(
    statusHistory: IStatusHistory<T>[]
  ): IStatusHistory<T> | undefined {
    return first(this.sort(statusHistory));
  }

  static lastLogForStatus<T>(
    statusHistory: IStatusHistory<T>[],
    status: T
  ): IStatusHistory<T> | undefined {
    return this.sort(statusHistory).find((log) => log.status === status);
  }

  static firstLogForStatus<T>(
    statusHistory: IStatusHistory<T>[],
    status: T
  ): IStatusHistory<T> | undefined {
    return this.sort(statusHistory)
      .reverse()
      .find((log) => log.status === status);
  }

  static firstEnteredStatus<T>(
    statusHistory: IStatusHistory<T>[],
    status: T
  ): Timestamp | undefined {
    const event = this.firstLogForStatus(statusHistory, status);
    return event ? event.updatedAt : undefined;
  }

  static lastEnteredStatus<T>(
    statusHistory: IStatusHistory<T>[],
    status: T
  ): Timestamp | undefined {
    const event = this.lastLogForStatus(statusHistory, status);
    return event ? event.updatedAt : undefined;
  }

  static previousStatus<T>(
    statusHistory: IStatusHistory<T>[]
  ): IStatusHistory<T> | undefined {
    return this.sort(statusHistory)[1];
  }
}
