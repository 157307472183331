import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  type OnDestroy,
} from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNil } from 'lodash';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TypedFormControl } from '../forms/typed-form-group';
import { TrackByFunctions } from '../track-by';

@Component({
  selector: 'pt-settings-menu-multi-select',
  templateUrl: './settings-menu-multi-select.component.html',
  styleUrls: ['./settings-menu-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SettingsMenuMultiSelectComponent),
      multi: true,
    },
  ],
})
export class SettingsMenuMultiSelectComponent
  implements ControlValueAccessor, OnDestroy
{
  private _onDestroy$: Subject<void> = new Subject();
  private _onChange: (value?: (string | number)[]) => void;
  disabled$ = new BehaviorSubject<boolean>(false);
  emitEvent$ = new BehaviorSubject<boolean>(true);
  options$ = new ReplaySubject<(string | number)[]>(1);
  trackByOption = TrackByFunctions.variable<string | number>();
  @Input() label: string;
  @Input() tooltip?: string;
  formCtrl = new TypedFormControl<(string | number)[]>([]);

  @Input()
  set disabled(disabled: BooleanInput) {
    this.disabled$.next(coerceBooleanProperty(disabled));
  }

  @Input()
  set options(options: (string | number)[]) {
    if (options) {
      this.options$.next(options);
    }
  }

  @Input()
  set emitEvent(emitEvent: boolean) {
    if (!isNil(emitEvent)) {
      this.emitEvent$.next(emitEvent);
    }
  }

  constructor() {
    this.formCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((newValue) => {
        if (this._onChange) {
          this._onChange(newValue);
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  writeValue(value: string[]): void {
    this.formCtrl.setValue(value ?? '', { emitEvent: this.emitEvent$.value });
  }

  registerOnChange(fn: () => void): void {
    this._onChange = fn;
  }

  registerOnTouched(_fn: () => void): void {
    //
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formCtrl.disable();
      return;
    }
    this.formCtrl.enable();
  }

  optionToLabel(option: string | number): string {
    if (option === 'contactNumbers') {
      return 'Primary Contact Number';
    }

    return option.toString();
  }
}
