import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISODateType,
  Timezone,
  TypeGuard,
  isISODateType,
} from '@principle-theorem/shared';
import { isNumber } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_BALANCE_RESOURCE_TYPE = 'patientBalance';

export const PATIENT_BALANCE_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient Balance List',
    description: '',
    idPrefix: PATIENT_BALANCE_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface IPatientBalance {
  id: number;
  patientId: number;
  practitionerId: number;
  invoiceBalance: number;
  gapBalance: number;
  creditBalance: number;
  balanceForward: number;
  nextStateDate?: ISODateType;
  cycleNumber: number;
  lastStateNumber: number;
  lastRegNumber: number;
}

function isPatientBalance(data: unknown): data is IPatientBalance {
  return TypeGuard.interface<IPatientBalance>({
    id: isNumber,
    patientId: isNumber,
    practitionerId: isNumber,
    invoiceBalance: isNumber,
    gapBalance: isNumber,
    creditBalance: isNumber,
    balanceForward: isNumber,
    nextStateDate: TypeGuard.nilOr(isISODateType),
    cycleNumber: isNumber,
    lastStateNumber: isNumber,
    lastRegNumber: isNumber,
  })(data);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPatientBalanceTranslations {}

export interface IPatientBalanceFilters {
  patientId: number;
}

const PATIENT_BALANCE_SOURCE_QUERY = `
SELECT
  convert_to_integer(SKEY) as id,
  convert_to_integer(ACCNUMBER) AS patient_id,
  DRNUMBER AS practitioner_id,
  CASE
    WHEN INVOICES IS NULL
      THEN 0
    ELSE convert_to_integer(INVOICES)
  END invoice_balance,
  CASE
    WHEN GAP IS NULL
      THEN 0
    ELSE convert_to_integer(GAP)
  END gap_balance,
  CASE
    WHEN CREDITS IS NULL
      THEN 0
    ELSE convert_to_integer(CREDITS)
  END credit_balance,
  CASE
    WHEN BALANCEFWD IS NULL
      THEN 0
    ELSE convert_to_integer(BALANCEFWD)
  END balance_forward,
  CASE
    WHEN strpos(NEXTSTATEDATE, '/') > 0
      THEN to_char(
        convert_to_date(NEXTSTATEDATE, 'DD/MM/YYYY'),
        'YYYY-MM-DD'
      )
    ELSE NULLIF(NEXTSTATEDATE, '')
  END AS next_state_date,
  CYCLENUMBER AS cycle_number,
  LASTSTATENUMBER AS last_state_number,
  LASTREGNUMBER AS last_reg_number
FROM
  PBACCBAL
WHERE
 convert_to_integer(SKEY) IS NOT NULL
`;

export class PatientBalanceSourceEntity extends BaseSourceEntity<
  IPatientBalance,
  object,
  IPatientBalanceFilters
> {
  sourceEntity = PATIENT_BALANCE_SOURCE_ENTITY;
  entityResourceType = PATIENT_BALANCE_RESOURCE_TYPE;
  sourceQuery = PATIENT_BALANCE_SOURCE_QUERY;
  verifySourceFn = isPatientBalance;
  override defaultOffsetSize = 50000;

  translate(
    _data: IPatientBalance,
    _timezone: Timezone
  ): IPatientBalanceTranslations {
    return {};
  }

  getSourceRecordId(data: IPatientBalance): string {
    return `${data.id}-${data.patientId}-${data.practitionerId}`;
  }

  getSourceLabel(record: IPatientBalance): string {
    return `${record.id}-${record.patientId}-${record.practitionerId}`;
  }

  getFilterData(data: IPatientBalance): IPatientBalanceFilters {
    return {
      patientId: data.patientId,
    };
  }
}
