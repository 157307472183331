import { Pipe, type PipeTransform } from '@angular/core';
import {
  type IAppointment,
  type IEventable,
  isAppointment,
  isEventable,
} from '@principle-theorem/principle-core/interfaces';
import { Event } from '@principle-theorem/principle-core';
import {
  isTimestampRange,
  type ITimestampRange,
} from '@principle-theorem/shared';

@Pipe({
    name: 'duration',
    standalone: false
})
export class DurationPipe implements PipeTransform {
  transform(event: ITimestampRange | IEventable | IAppointment): number {
    if (isTimestampRange(event)) {
      return Event.duration(event);
    }
    if (isEventable(event)) {
      return Event.duration(event.event);
    }
    if (isAppointment(event) && event.treatmentPlan) {
      return event.treatmentPlan.treatmentStep.duration;
    }
    return 0;
  }
}
