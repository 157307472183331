import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  createInvoiceForAppointment,
  Patient,
} from '@principle-theorem/principle-core';
import {
  PatientRelationshipType,
  type IAppointment,
  type IInvoice,
} from '@principle-theorem/principle-core/interfaces';
import {
  asDocRef,
  doc$,
  getDoc,
  isWithRef,
  serialise$,
  type SerialisedData,
  unserialise$,
  type WithRef,
} from '@principle-theorem/shared';
import { from, type Observable, of } from 'rxjs';
import { concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { InvoiceActions } from '../actions';
import { loadDraftInvoiceSuccess } from '../actions/invoices.actions';
import { PatientsFacade } from '../facades/patients.facade';

@Injectable()
export class InvoicesEffects {
  private _actions$ = inject(Actions);
  private _patientsFacade = inject(PatientsFacade);
  loadInvoices$: Observable<Action> = createEffect(() => this._loadInvoices$());
  loadDraftInvoice$: Observable<Action> = createEffect(() =>
    this._loadDraftInvoice$()
  );

  _loadInvoices$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(InvoiceActions.loadInvoices),
      withLatestFrom(this._patientsFacade.currentPatient$),
      switchMap(([_action, patient]) => {
        if (!patient || !isWithRef(patient)) {
          return [];
        }
        return Patient.withPatientRelationships$(
          patient,
          [PatientRelationshipType.DuplicatePatient],
          Patient.invoices$
        );
      }),
      serialise$(),
      map((invoices: SerialisedData<WithRef<IInvoice>[]>) =>
        InvoiceActions.loadInvoiceSuccess({ invoices })
      )
    );
  }

  _loadDraftInvoice$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(InvoiceActions.loadDraftInvoice),
      map((action) => ({
        appointment: action.appointment,
        taxRate: action.taxRate,
      })),
      unserialise$(),
      concatMap(({ appointment, taxRate }) =>
        from(getDoc(asDocRef<IAppointment>(appointment.ref))).pipe(
          concatMap((latestAppointment) =>
            latestAppointment.invoiceRef
              ? of(latestAppointment.invoiceRef)
              : from(
                  createInvoiceForAppointment(latestAppointment.ref, taxRate)
                )
          )
        )
      ),
      switchMap((invoiceRef) => doc$<IInvoice>(invoiceRef)),
      map((invoice) => invoice.reference),
      map((invoiceRef) => loadDraftInvoiceSuccess({ invoiceRef }))
    );
  }
}
