<ng-container *ngrxLet="event$ as event">
  <mat-list-item>
    <mat-icon matListItemIcon>schedule</mat-icon>
    <span matListItemTitle>
      <pr-formatted-event-field
        field="from"
        [event]="event"
        [format]="timeFormat"
       />
      -
      <pr-formatted-event-field
        field="to"
        [event]="event"
        [format]="timeFormat"
       />
    </span>
    <span matListItemLine>
      <pr-formatted-event-field
        field="from"
        [event]="event"
        [format]="timeFormat"
       />
    </span>
  </mat-list-item>
</ng-container>
