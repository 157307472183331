@if (variant$ | async; as variant) {
  <div *ngrxLet="hasNoScope$ as noScope" class="flex flex-col gap-4">
    @if (showVariantError$ | async) {
      <div class="warn text-xs">
        Conditional variants require a template scope to be set
      </div>
    }

    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label>Name (optional)</mat-label>
      <input matInput [formControl]="variantNameCtrl" />
    </mat-form-field>

    @if (variant && !noScope) {
      <pr-conditional-logic-form
        [availableConditions]="store.availableConditions$ | async"
        [defaultCondition]="defaultCondition"
        [conditions]="variant.conditions"
        (conditionsUpdated)="updateConditions($event)"
      />
    }

    <pr-template-editor-textarea
      [formControl]="contentCtrl"
      [required]="true"
      [templateType]="templateType$ | async"
      [context]="context$ | async"
    />
  </div>
}
