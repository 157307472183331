import { Injectable } from '@angular/core';
import {
  IPerioSettings,
  SwitchDirection,
  SwitchPattern,
} from '@principle-theorem/principle-core/interfaces';
import type { default as HandsonTable } from 'handsontable/base';
import { BehaviorSubject } from 'rxjs';

type SelectedCell = { row: number; col: number };

@Injectable({
  providedIn: 'root',
})
export class PerioSettingsService {
  tableID = new BehaviorSubject<string | undefined>(undefined);
  autoSwitchCells$ = new BehaviorSubject<boolean>(false);
  switchPattern$ = new BehaviorSubject<SwitchPattern>(SwitchPattern.Zigzag);
  direction$ = new BehaviorSubject<SwitchDirection>(SwitchDirection.Right);
  initalDirection$ = new BehaviorSubject<SwitchDirection>(
    SwitchDirection.Right
  );

  applyStafferSettings(settings: IPerioSettings | undefined): void {
    if (!settings) {
      return;
    }
    this.autoSwitchCells$.next(settings.autoSwitchCells);
    this.switchPattern$.next(settings.switchPattern);
    this.direction$.next(settings.switchDirection);
    this.initalDirection$.next(settings.switchDirection);
  }

  handleCellNavigation(hotInstance: HandsonTable, tableId: string): void {
    this._resetDirectionIfTableChanged(tableId);
    const selectedCell = this.getSelectedCell(hotInstance);
    const { row, col } = this._calculateNextPosition(selectedCell, hotInstance);
    hotInstance.selectCell(row, col);
  }

  isValidKeyEvent(event: KeyboardEvent): boolean {
    const isNumberKey = event.key >= '0' && event.key <= '9';
    return isNumberKey;
  }

  getSelectedCell(hotInstance: HandsonTable): SelectedCell {
    const selected = hotInstance.getSelected() || [];
    const [row = 0, col = 0] = selected[0] || [];
    return { row, col };
  }

  private _resetDirectionIfTableChanged(tableId: string): void {
    if (this.tableID.getValue() === tableId) {
      return;
    }
    this.tableID.next(tableId);
    const pattern = this.switchPattern$.getValue();
    if (pattern === SwitchPattern.Zigzag) {
      this.direction$.next(this.initalDirection$.getValue());
    }
  }

  private _calculateNextPosition(
    selectedCell: SelectedCell,
    hotInstance: HandsonTable
  ): SelectedCell {
    let col = selectedCell.col;
    const row = selectedCell.row;
    const direction = this.direction$.getValue();
    const colAmount = hotInstance.countCols();
    const colSpan = this._findColumnSpan(hotInstance.getCellMeta(row, col));

    direction === SwitchDirection.Right ? (col += colSpan) : (col -= colSpan);
    return this._adjustPositionBasedOnDirectionPattern({ row, col }, colAmount);
  }

  private _findColumnSpan(cellMeta: HandsonTable.CellProperties): number {
    if (cellMeta && cellMeta.colspan) {
      return cellMeta.colspan as number;
    }
    return 1;
  }

  private _adjustPositionBasedOnDirectionPattern(
    position: SelectedCell,
    colAmount: number
  ): SelectedCell {
    let { row, col } = position;
    const pattern = this.switchPattern$.getValue();

    if (col >= colAmount || col < 0) {
      row++;
      col = this._adjustColumnForOverflow(col, colAmount, pattern);
    }

    row = this._adjustRowIfOutOfBounds(row, colAmount);

    return { row, col };
  }

  private _adjustColumnForOverflow(
    col: number,
    colAmount: number,
    pattern: SwitchPattern
  ): number {
    if (col >= colAmount) {
      col = this._adjustColForRightOverflow(colAmount, pattern);
    }
    if (col < 0) {
      col = this._adjustColForLeftOverflow(colAmount, pattern);
    }
    return col;
  }

  private _adjustColForRightOverflow(
    colAmount: number,
    pattern: SwitchPattern
  ): number {
    if (pattern === SwitchPattern.Zigzag) {
      this.direction$.next(SwitchDirection.Left);
      return colAmount - 1;
    }
    return 0;
  }

  private _adjustColForLeftOverflow(
    colAmount: number,
    pattern: SwitchPattern
  ): number {
    if (pattern === SwitchPattern.Zigzag) {
      this.direction$.next(SwitchDirection.Right);
      return 0;
    }
    return colAmount - 1;
  }

  private _adjustRowIfOutOfBounds(row: number, colAmount: number): number {
    if (row >= colAmount) {
      return 0;
    }
    if (row < 0) {
      return colAmount - 1;
    }
    return row;
  }
}
