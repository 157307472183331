import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { doc$, type WithRef } from '@principle-theorem/shared';
import { type ITask } from '@principle-theorem/principle-core/interfaces';
import { type Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

export interface ITaskInteractionDialogData {
  task: WithRef<ITask>;
}

@Component({
    selector: 'pr-task-interactions-dialog',
    templateUrl: './task-interactions-dialog.component.html',
    styleUrls: ['./task-interactions-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TaskInteractionsDialogComponent {
  task$: Observable<WithRef<ITask>>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ITaskInteractionDialogData,
    private _dialogRef: MatDialogRef<TaskInteractionsDialogComponent>
  ) {
    this.task$ = doc$(data.task.ref).pipe(startWith(data.task));
  }

  onComplete(): void {
    this._dialogRef.close();
  }
}
