import {
  ChartableSurface,
  IChartedRef,
  IChartedSurface,
  IChartedTooth,
  IToothRef,
} from '@principle-theorem/principle-core/interfaces';
import { uniqWith, compact } from 'lodash';
import { ChartedSurface } from './charted-surface';
import { isSameToothRef } from './tooth';

export class ChartedSurfacesCollection {
  constructor(private _items: IChartedSurface[] = []) {}

  filterBySelected(
    selectedSurfaces: Partial<IChartedRef>[]
  ): ChartedSurfacesCollection {
    return this._toCollection(
      this._items.filter((item: IChartedSurface) => {
        return ChartedSurface.isOnASelectedSurface(item, selectedSurfaces);
      })
    );
  }

  filterByResolved(resolved: boolean): ChartedSurfacesCollection {
    return this._toCollection(
      this._items.filter(
        (item: IChartedSurface) => ChartedSurface.isResolved(item) === resolved
      )
    );
  }

  filterByTooth(tooth: IToothRef): ChartedSurfacesCollection {
    return this._toCollection(
      this._items.filter((item: IChartedSurface) =>
        ChartedSurface.isOnTooth(item, tooth)
      )
    );
  }

  filterBySurface(surface?: ChartableSurface): ChartedSurfacesCollection {
    return this._toCollection(
      this._items.filter((item: IChartedSurface) =>
        ChartedSurface.isOnSurface(item, surface)
      )
    );
  }

  filterByMultipleTeeth(teeth: IToothRef[]): ChartedSurfacesCollection {
    return this._toCollection(
      this._items.filter((item: IChartedSurface) => {
        return teeth.some((tooth: IToothRef) =>
          ChartedSurface.isOneOfTeeth(item, tooth)
        );
      })
    );
  }

  filterByChartedRef(
    chartedRef: Partial<IChartedRef>
  ): ChartedSurfacesCollection {
    return this._toCollection(
      this._items.filter((item: IChartedSurface) =>
        ChartedSurface.isSameChartedRef(item, chartedRef)
      )
    );
  }

  filterByUuids(uuids: string[]): ChartedSurfacesCollection {
    return this._toCollection(
      this._items.filter((item: IChartedSurface) => uuids.includes(item.uuid))
    );
  }

  toUniqueTeeth(): IChartedTooth[] {
    const teeth: (IChartedTooth | undefined)[] = uniqWith(
      this._items,
      this._areSameTeeth
    ).map((item: IChartedSurface) => item.chartedRef.tooth);
    return compact(teeth);
  }

  toUniqueChartedRefs(): Partial<IChartedRef>[] {
    return this._items.reduce(
      (accumulator: Partial<IChartedRef>[], item: IChartedSurface) => {
        if (!ChartedSurface.isOnASelectedSurface(item, accumulator)) {
          accumulator.push(item.chartedRef);
        }
        return accumulator;
      },
      []
    );
  }

  findByUuid(uuid: string): IChartedSurface | undefined {
    return this._items.find((item: IChartedSurface) => item.uuid === uuid);
  }

  toArray(): IChartedSurface[] {
    return this._items;
  }

  private _toCollection(items: IChartedSurface[]): ChartedSurfacesCollection {
    return new ChartedSurfacesCollection(items);
  }

  private _areSameTeeth(a: IChartedSurface, b: IChartedSurface): boolean {
    if (!a.chartedRef.tooth || !b.chartedRef.tooth) {
      return false;
    }
    return isSameToothRef(a.chartedRef.tooth, b.chartedRef.tooth);
  }
}
