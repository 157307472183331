import { Auth } from '@angular/fire/auth';
import { Storage } from '@angular/fire/storage';
import { type DecoratorFunction } from '@storybook/csf';
import { type User } from 'firebase/auth';
// import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { of } from 'rxjs';
import {
  // provideFirebaseApp,
  type FirebaseOptions,
  // initializeApp,
} from '@angular/fire/app';
import { moduleMetadata, type AngularRenderer } from '@storybook/angular';

const user: Partial<User> = {
  emailVerified: true,
  displayName: 'derpa man',
  email: 'derpa.man@derpa.stan',
};

export function decorateAngularFire(
  _override: Partial<FirebaseOptions> = {}
): DecoratorFunction<AngularRenderer> {
  // TODO: stub the services that require initializing rather than a broken init
  // const config: FirebaseOptions = {
  //   projectId: 'principle-theorem-test',
  //   ...override,
  // };
  return moduleMetadata({
    imports: [
      // provideFirebaseApp(() => initializeApp(config)),
      // NgxAuthFirebaseUIModule.forRoot(config),
    ],
    providers: [
      {
        provide: Auth,
        useValue: {
          onIdTokenChanged: () => of(user),
          user: of(user),
          idTokenResult: of({
            claims: {
              isManagement: true,
            },
          }),
          authState: of({
            photoURL:
              'https://www.gravatar.com/avatar/d4c74594d841139328695756648b6bd6?d=identicon&r=PG',
          }),
          // eslint-disable-next-line no-console
          signOut: () => console.log('signOut'),
        },
      },
      {
        provide: Storage,
        useValue: {
          ref: (src: string) => ({
            getDownloadURL: () => of(src),
          }),
        },
      },
    ],
  });
}
