<ng-container *ngrxLet="labJobs$ as labJobs">
  <ng-container *ngIf="labJobs.length">
    <ng-container *ngIf="viewType === 'sidebar'">
      <h2>Lab Jobs</h2>
    </ng-container>

    <div class="flex flex-col gap-2" *ngIf="viewType === 'card'; else sidebar">
      <div class="text-base font-bold">Lab Jobs</div>
      <div class="flex flex-col gap-1">
        <div
          *ngFor="let labJob of labJobs; trackBy: trackByLabJob"
          class="flex flex-row items-center gap-2"
        >
          <div class="flex flex-auto">
            <pr-mention-resource [resource]="labJob | map : toMention">
              {{ labJob.type.name }}
            </pr-mention-resource>
          </div>

          <small
            *ngIf="labJob.dueDate"
            class="due-date w-24"
            [ngClass]="{ overdue: labJob | map : isOverdue }"
          >
            {{ labJob.dueDate | moment | amDateFormat : dateFormat }}
          </small>

          <pr-lab-job-status
            class="w-16"
            [status]="labJob.status"
            [dense]="true"
          />
        </div>
      </div>
    </div>

    <ng-template #sidebar>
      <div
        *ngFor="let labJob of labJobs; trackBy: trackByLabJob"
        class="flex flex-row items-center justify-between"
      >
        <pr-mention-resource [resource]="labJob | map : toMention" class="flex">
          {{ labJob.type.name }}
        </pr-mention-resource>

        <div class="flex flex-row gap-4">
          <small
            *ngIf="labJob.dueDate"
            class="due-date flex w-24"
            [ngClass]="{ overdue: labJob | map : isOverdue }"
          >
            {{ labJob.dueDate | moment | amDateFormat : dateFormat }}
          </small>

          <div class="flex w-20 justify-end">
            <pr-lab-job-status [status]="labJob.status" [dense]="true" />
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
