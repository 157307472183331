import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Storage } from '@angular/fire/storage';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IMatSelectOption,
  MediaManager,
  TypedFormControl,
  fileFromDataUrl,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import { Media } from '@principle-theorem/principle-core';
import { IMedia } from '@principle-theorem/principle-core/interfaces';
import {
  CollectionReference,
  WithRef,
  addDocAsWithRef,
  filterUndefined,
  shareReplayHot,
  snapshot,
} from '@principle-theorem/shared';
import { get } from 'lodash';
import * as LogRocket from 'logrocket';
import {
  WebcamComponent,
  WebcamInitError,
  WebcamMirrorProperties,
  WebcamUtil,
  type WebcamImage,
} from 'ngx-webcam';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  from,
} from 'rxjs';
import {
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  scan,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

export interface ICaptureMediaDialogRequest {
  mediaCollectionRef: CollectionReference<IMedia>;
  storagePath: string;
  multiCapture?: boolean;
}

export interface ICaptureMediaDialogResponse {
  savedMedia: WithRef<IMedia>[];
}

enum MirrorImageSetting {
  Default = 'default',
  Yes = 'Yes',
  No = 'No',
}

@Component({
  selector: 'pr-capture-media-dialog',
  templateUrl: './capture-media-dialog.component.html',
  styleUrls: ['./capture-media-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaptureMediaDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _webcam$ = new ReplaySubject<WebcamComponent>(1);
  private _savedMedia$: Observable<WithRef<IMedia>[]>;
  private _addMediaStream$: Observable<WithRef<IMedia>>;
  close$ = new Subject<void>();
  imageCapture$ = new ReplaySubject<WebcamImage>(1);
  saving$ = new BehaviorSubject<boolean>(false);
  mirrorImage$: Observable<WebcamMirrorProperties | undefined>;
  cameraAccessDenied$ = new BehaviorSubject<boolean>(false);
  videoInputs$: Observable<MediaDeviceInfo[]>;
  videoInputCtrl = new TypedFormControl<string>();
  mirrorImageCtrl = new TypedFormControl<MirrorImageSetting>(
    MirrorImageSetting.Default
  );
  mirrorImageOptions: IMatSelectOption<MirrorImageSetting>[] = [
    { label: 'Default', value: MirrorImageSetting.Default },
    { label: 'Yes', value: MirrorImageSetting.Yes },
    { label: 'No', value: MirrorImageSetting.No },
  ];
  captureDisabled$: Observable<boolean>;

  @ViewChild(WebcamComponent)
  set webcam(webcam: WebcamComponent) {
    if (webcam) {
      this._webcam$.next(webcam);
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICaptureMediaDialogRequest,
    private _dialogRef: MatDialogRef<
      CaptureMediaDialogComponent,
      ICaptureMediaDialogResponse | undefined
    >,
    private _storage: Storage
  ) {
    this.videoInputs$ = from(WebcamUtil.getAvailableVideoInputs());
    this.mirrorImage$ = formControlChanges$(this.mirrorImageCtrl).pipe(
      map((setting) => this._toMirrorImage(setting))
    );
    this.captureDisabled$ = combineLatest([
      this.saving$,
      this.cameraAccessDenied$,
    ]).pipe(
      map(
        ([saving, cameraAccessDenied]) =>
          (!this.data.multiCapture && saving) || cameraAccessDenied
      )
    );

    this._addMediaStream$ = this.imageCapture$.pipe(
      concatMap((image: WebcamImage) => this.addMedia(image)),
      shareReplayHot(this._onDestroy$)
    );
    this._savedMedia$ = this._addMediaStream$.pipe(
      scan((acc, media) => [...acc, media], [] as WithRef<IMedia>[]),
      startWith([])
    );

    this._addMediaStream$.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
      if (!this.data.multiCapture) {
        this.close$.next();
      }
    });

    formControlChanges$(this.videoInputCtrl)
      .pipe(
        distinctUntilChanged(),
        filterUndefined(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((deviceId) => void this.switchToVideoInput(deviceId));

    this.close$
      .pipe(
        switchMap(() => this.saving$.pipe(filter((saving) => !saving))),
        switchMap(() => this._savedMedia$),
        takeUntil(this._onDestroy$)
      )
      .subscribe((savedMedia) => this._dialogRef.close({ savedMedia }));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async addMedia(image: WebcamImage): Promise<WithRef<IMedia>> {
    this.saving$.next(true);
    const file = fileFromDataUrl(image.imageAsDataUrl);

    const manager = new MediaManager(this._storage, this.data.storagePath);
    const mediaPath = await manager.addMedia({ file });
    const media = await addDocAsWithRef(
      this.data.mediaCollectionRef,
      Media.init(mediaPath)
    );

    this.saving$.next(false);
    return media;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    LogRocket.debug('capture key pressed', event.code);
  }

  @HostListener('document:keydown.Enter')
  @HostListener('document:keydown.Space')
  async capture(): Promise<void> {
    const webcam = await snapshot(this._webcam$);
    webcam.takeSnapshot();
  }

  async switchToVideoInput(deviceId: string): Promise<void> {
    const webcam = await snapshot(this._webcam$);
    const activeDeviceId: unknown = get(webcam, 'activeVideoSettings.deviceId');
    if (activeDeviceId !== deviceId) {
      webcam.switchToVideoInput(deviceId);
    }
  }

  handleInitError(error: WebcamInitError): void {
    if (
      error.mediaStreamError &&
      error.mediaStreamError.name === 'NotAllowedError'
    ) {
      this.cameraAccessDenied$.next(true);
    }
  }

  private _toMirrorImage(
    setting?: MirrorImageSetting
  ): WebcamMirrorProperties | undefined {
    switch (setting) {
      case MirrorImageSetting.Yes:
        return { x: 'always' };
      case MirrorImageSetting.No:
        return { x: 'never' };
      default:
        return undefined;
    }
  }
}
