import {
  randFullName,
  randNumber,
  randPhoneNumber,
  randSentence,
} from '@ngneat/falso';
import {
  VersionedSchema,
  initVersionedSchema,
} from '@principle-theorem/editor';
import {
  IPatient,
  IPatientPrescriptionDetails,
  IPractice,
  IPracticePrescriptionDetails,
  IPrescirptionStatusHistory,
  IPrescriberPrescriptionDetails,
  IPrescription,
  IPrescriptionItem,
  IStaffer,
  PrescriptionStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  Properties,
  Timestamp,
  toTimestamp,
} from '@principle-theorem/shared';
import { MockDocRef } from '@principle-theorem/testing';
import { PrescriptionItemMock } from '../prescription-item/prescription-item.mock';

export class PrescriptionMock
  extends BaseFirestoreMock
  implements Properties<IPrescription>
{
  get items(): IPrescriptionItem[] {
    return [...Array<IPrescriptionItem>(randNumber({ min: 1, max: 3 }))].map(
      () => new PrescriptionItemMock()
    );
  }

  get patient(): IPatientPrescriptionDetails {
    return {
      ref: MockDocRef<IPatient>(),
      name: randFullName(),
    };
  }

  get prescriber(): IPrescriberPrescriptionDetails {
    return {
      ref: MockDocRef<IStaffer>(),
      name: randFullName(),
      number: randNumber({ min: 100000, max: 999999 }).toString(),
    };
  }

  get practice(): IPracticePrescriptionDetails {
    return {
      name: randSentence(),
      address: randSentence(),
      phone: randPhoneNumber(),
      ref: MockDocRef<IPractice>(),
    };
  }

  get notes(): VersionedSchema {
    return initVersionedSchema(randSentence());
  }

  get status(): PrescriptionStatus {
    return PrescriptionStatus.Draft;
  }

  get statusHistory(): IPrescirptionStatusHistory[] {
    return [
      {
        status: PrescriptionStatus.Draft,
        actionedByRef: MockDocRef<IStaffer>(),
        updatedAt: toTimestamp(),
      },
    ];
  }

  get pbsSafetyNet(): boolean {
    return true;
  }

  get concessionalOrDependentRPBS(): boolean {
    return true;
  }

  get pbs(): boolean {
    return true;
  }

  get rpbs(): boolean {
    return true;
  }

  get brandSubstitutionNotPermitted(): boolean {
    return true;
  }

  get prescribedAt(): Timestamp {
    return toTimestamp();
  }
}
