import { TransactionStatus } from '@principle-theorem/principle-core/interfaces';
import { ONLY_NUMBERS } from '@principle-theorem/shared';
import { isEmpty } from 'lodash';

export enum MedicareClaimType {
  BulkBill = 'bulkBill',
  FullyPaid = 'fullyPaid',
  PartPaid = 'partPaid',
  Unpaid = 'unpaid',
}

export class MedicareValidation {
  static hasSpecialCharacters(value: string): boolean {
    const specialCharacters = ['&', '^', '%', '$', ' '];
    return specialCharacters.some((char) => value.includes(char));
  }

  static isValidMemberNumber(memberNumber: string): boolean {
    return (
      !isEmpty(memberNumber) &&
      ONLY_NUMBERS.test(memberNumber) &&
      !this.hasSpecialCharacters(memberNumber)
    );
  }

  static isValidADACodeNumber(adaCode: string): boolean {
    return (
      !isEmpty(adaCode) &&
      ONLY_NUMBERS.test(adaCode) &&
      !this.hasSpecialCharacters(adaCode)
    );
  }

  static coerceTransactionStatus(
    status: TransactionStatus,
    shouldDefaultToPending: boolean
  ): TransactionStatus {
    if (status === TransactionStatus.Complete) {
      return shouldDefaultToPending ? TransactionStatus.Pending : status;
    }
    return status;
  }
}
