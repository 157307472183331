import {
  createEntityAdapter,
  type EntityAdapter,
  type EntityState,
} from '@ngrx/entity';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import {
  type IFeeSchedule,
  type IFeeScheduleGroup,
} from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import {
  addFeeSchedule,
  clearFeeSchedules,
  deleteFeeSchedule,
  loadFeeSchedulesSuccess,
  selectFeeSchedule,
  updateFeeSchedule,
} from '../actions/fee-schedule';

export interface IFeeScheduleState
  extends EntityState<SerialisedData<WithRef<IFeeSchedule>>> {
  selectedFeeScheduleId: string | undefined;
  inherited: [];
  groups: SerialisedData<IFeeScheduleGroup>[];
}

export const feeScheduleAdapter: EntityAdapter<
  SerialisedData<WithRef<IFeeSchedule>>
> = createEntityAdapter<SerialisedData<WithRef<IFeeSchedule>>>({
  selectId: (config) => config.ref.id,
  sortComparer: false,
});

export const initialFeeScheduleState: IFeeScheduleState =
  feeScheduleAdapter.getInitialState({
    selectedFeeScheduleId: undefined,
    inherited: [],
    groups: [],
  });

export const feeScheduleReducer: ActionReducer<IFeeScheduleState, Action> =
  createReducer(
    initialFeeScheduleState,

    on(selectFeeSchedule, (state, { ref }) => ({
      ...state,
      selectedFeeScheduleId: ref.id,
    })),

    on(addFeeSchedule, (state, { feeSchedule }) => {
      return feeScheduleAdapter.addOne(feeSchedule, state);
    }),

    on(updateFeeSchedule, (state, { feeSchedule }) => {
      return feeScheduleAdapter.updateOne(
        { id: feeSchedule.ref.id, changes: feeSchedule },
        state
      );
    }),

    on(loadFeeSchedulesSuccess, (state, { feeSchedules, groups }) =>
      feeScheduleAdapter.upsertMany(feeSchedules, {
        ...state,
        groups,
      })
    ),

    on(deleteFeeSchedule, (state, { ref }) => {
      return feeScheduleAdapter.removeOne(ref.id, state);
    }),

    on(clearFeeSchedules, (state) => {
      return feeScheduleAdapter.removeAll({
        ...state,
        selectedFeeScheduleId: undefined,
      });
    })
  );
