import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { getDoc$, type WithRef } from '@principle-theorem/shared';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  type IBrand,
  type IContact,
} from '@principle-theorem/principle-core/interfaces';
import { Brand } from '@principle-theorem/principle-core';
import { type Observable } from 'rxjs';
import { AbstractResolver } from '@principle-theorem/ng-shared';

@Injectable()
export class ContactResolverService extends AbstractResolver<
  WithRef<IContact>
> {
  contacts: WithRef<IContact>[];

  getItemById(
    uid: string,
    route: ActivatedRouteSnapshot
  ): Observable<WithRef<IContact>> {
    const brandScope: CurrentBrandScope = new CurrentBrandScope(this._router);
    const brand: WithRef<IBrand> | undefined = brandScope.findModel(route);

    if (!brand) {
      throw new Error('No Brand found');
    }
    return getDoc$(Brand.contactCol(brand), uid);
  }
}
