import { recursiveReplace } from '@principle-theorem/editor';
import {
  DEFAULT_DATA_UID,
  DestinationEntityRecordCollection,
  IDestinationEntityRecord,
  IDestinationEntityRecordData,
  IDestinationEntityRecordFile,
  JOB_DATA_UID,
  MODIFIED_DATA_UID,
  PRINCIPLE_DIFF_DATA_UID,
} from '@principle-theorem/principle-core/interfaces';
import {
  all$,
  isArray,
  serialise,
  subCollection,
  unserialise,
  type CollectionReference,
  type IReffable,
  type SerialisedData,
  type TypeGuardFn,
  type WithRef,
  where,
  firstResult$,
  errorNil,
} from '@principle-theorem/shared';
import * as stringify from 'json-stable-stringify';
import { cloneDeep, first, omit, sortBy } from 'lodash';
import { of, type Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export class DestinationEntityRecord {
  static getLatestData$<T extends object = object>(
    record: IReffable<IDestinationEntityRecord<T>>
  ): Observable<WithRef<IDestinationEntityRecordData<T>>> {
    return firstResult$(
      DestinationEntityRecord.dataCol<T>(record),
      where('uid', '==', MODIFIED_DATA_UID)
    ).pipe(
      switchMap((result) =>
        result
          ? of(result)
          : firstResult$(
              DestinationEntityRecord.dataCol<T>(record),
              where('uid', '==', DEFAULT_DATA_UID)
            )
      ),
      catchError(() =>
        firstResult$(
          DestinationEntityRecord.dataCol<T>(record),
          where('uid', '==', DEFAULT_DATA_UID)
        )
      ),
      errorNil(
        `DestinationEntityRecord.getLatestData$ - No data found for record ${record.ref.path}`
      ),
      switchMap(DestinationEntityRecord.resolveRecordData)
    );
  }

  static getDiffData$<T extends object = object>(
    record: IReffable<IDestinationEntityRecord<T>>
  ): Observable<WithRef<IDestinationEntityRecordData<T>> | undefined> {
    return firstResult$(
      DestinationEntityRecord.dataCol<T>(record),
      where('uid', '==', PRINCIPLE_DIFF_DATA_UID)
    ).pipe(
      errorNil(
        `DestinationEntityRecord.getDiffData$ - No data found for record ${record.ref.path}`
      ),
      switchMap(DestinationEntityRecord.resolveRecordData),
      catchError(() => of(undefined))
    );
  }

  static getJobLatestData$<T extends object = object>(
    record: IReffable<IDestinationEntityRecord>
  ): Observable<WithRef<IDestinationEntityRecordData<T>>> {
    return firstResult$(
      DestinationEntityRecord.dataCol<T>(record),
      where('uid', '==', JOB_DATA_UID)
    ).pipe(
      errorNil(
        `DestinationEntityRecord.getJobLatestData$ - No data found for record ${record.ref.path}`
      ),
      switchMap(DestinationEntityRecord.resolveRecordData)
    );
  }

  static dataCol<T extends object = object>(
    record: IReffable<IDestinationEntityRecord>
  ): CollectionReference<
    IDestinationEntityRecordData<T> | IDestinationEntityRecordFile
  > {
    return subCollection<
      IDestinationEntityRecordData<T> | IDestinationEntityRecordFile
    >(record.ref, DestinationEntityRecordCollection.Data);
  }

  static data$<T extends object = object>(
    record: IReffable<IDestinationEntityRecord>
  ): Observable<
    WithRef<IDestinationEntityRecordData<T> | IDestinationEntityRecordFile>[]
  > {
    return all$(DestinationEntityRecord.dataCol(record));
  }

  static buildData(record: object, uid: string): IDestinationEntityRecordData {
    return {
      uid,
      data: record,
      type: 'jsonSerialisable',
    };
  }

  static hasMergeConflicts<T extends object, R extends object>(
    migrationData: T,
    principleData: R,
    additionalKeysToOmit: string[] = [],
    arraySorters: IArraySorter[] = []
  ): boolean {
    const keysToOmit = [
      'uid',
      'uuid',
      'createdAt',
      'createdBy',
      'updatedAt',
      'updatedBy',
      'deletedAt',
      'deleted',
      ...additionalKeysToOmit,
    ];

    const serialisedMigrationData = recursiveReplace(
      cloneDeep(serialise(migrationData)),
      (item) => {
        item = omit(item, keysToOmit);
        item = sortSerialisedNestedArray(item, arraySorters);
        return item;
      }
    );
    const serialisedPrincipleData = recursiveReplace(
      cloneDeep(omit(serialise(principleData), 'ref')),
      (item) => {
        item = omit(item, keysToOmit);
        item = sortSerialisedNestedArray(item, arraySorters);
        return item;
      }
    );

    return (
      stringify(serialisedMigrationData) !== stringify(serialisedPrincipleData)
    );
  }

  static async resolveRecordData<T extends object>(
    data: WithRef<
      IDestinationEntityRecordData<T> | IDestinationEntityRecordFile
    >
  ): Promise<WithRef<IDestinationEntityRecordData<T>>> {
    if (data.type === 'jsonSerialisable') {
      return data as WithRef<IDestinationEntityRecordData<T>>;
    }

    const file = await fetch(data.url);
    const fileJson = (await file.json()) as unknown;
    return {
      ...data,
      data: unserialise(fileJson),
      type: 'jsonSerialisable',
    } as WithRef<IDestinationEntityRecordData<T>>;
  }
}

export function sortNestedArray<T extends object>(
  data: T,
  arraySorters: IArraySorter[] = []
): T {
  const serialisedData = serialise(data);
  const sorted = recursiveReplace(cloneDeep(serialisedData), (item) =>
    sortSerialisedNestedArray(item, arraySorters)
  );
  return unserialise(sorted) as T;
}

function sortSerialisedNestedArray<T extends SerialisedData<object>>(
  serialisedData: T,
  arraySorters: IArraySorter[] = []
): T {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const item = cloneDeep(serialisedData) as Record<string, any>;
  for (const [key] of Object.entries(item)) {
    arraySorters.map((arraySorter) => {
      if (key !== arraySorter.key) {
        return;
      }

      if (isArray(item[key])) {
        const matchesSorter = arraySorter.typeGuardFn
          ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            arraySorter.typeGuardFn(first(item[key]))
          : true;
        if (matchesSorter) {
          item[key] = sortBy(item[key], arraySorter.sortByPath);
        }
      }
    });
  }
  return item as T;
}

export interface IArraySorter {
  key: string;
  typeGuardFn?: TypeGuardFn<unknown>;
  sortByPath: string | string[] | ((data: unknown) => string | number);
}
