import { Component, type OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { type IUser } from '@principle-theorem/principle-core/interfaces';
import { TypedFormGroup, TypedFormControl } from '@principle-theorem/ng-shared';
import { isError } from 'lodash';

type UserFormData = Pick<IUser, 'email'>;

@Component({
  selector: 'pr-user-activate',
  templateUrl: './user-activate.component.html',
  styleUrls: ['./user-activate.component.scss'],
})
export class UserActivateComponent implements OnInit {
  activating = false;

  form: TypedFormGroup<UserFormData> = new TypedFormGroup<UserFormData>({
    email: new TypedFormControl<string>('', [
      Validators.email,
      Validators.required,
    ]),
  });

  constructor(private _auth: Auth, private _router: Router) {}

  async ngOnInit(): Promise<void> {
    const email: string = localStorage.getItem('emailForSignIn') || '';
    if (!email) {
      return;
    }
    await this.confirmSignIn(email);
  }

  async save(): Promise<void> {
    const data: Pick<IUser, 'email'> = this.form.getRawValue();
    await this.confirmSignIn(data.email);
  }

  async confirmSignIn(email: string): Promise<void> {
    const url: string = this._router.url;

    try {
      if (isSignInWithEmailLink(this._auth, url)) {
        if (!email) {
          return;
        }
        this.activating = true;

        await signInWithEmailLink(this._auth, email, url);
        localStorage.removeItem('emailForSignIn');
        await this._router.navigate(['/']);
      }
    } catch (error) {
      if (isError(error)) {
        // eslint-disable-next-line no-console
        console.error(error.message);
      }
    }
  }
}
