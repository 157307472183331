import { Inject, Injectable } from '@angular/core';
import { FeatureFlagsService } from '@principle-theorem/ng-feature-flags';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { LoggerService } from '@principle-theorem/ng-shared';
import { type ITyroConfigProvider } from '@principle-theorem/ng-tyro';
import { TYRO_TESTING_FEATURE } from '@principle-theorem/principle-core/features';
import { isEnumValue } from '@principle-theorem/shared';
import { IPosProductData, TyroIClientUrl } from '@principle-theorem/tyro';
import { type Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  type INgPrincipleConfig,
  NG_PRINCIPLE_CONFIG,
} from '../../../ng-principle-config';

@Injectable()
export class TyroOrgConfigProvider implements ITyroConfigProvider {
  apiKey: string;
  posProductData: IPosProductData;
  defaultIClient: TyroIClientUrl;

  constructor(
    private _scope: CurrentScopeFacade,
    @Inject(NG_PRINCIPLE_CONFIG) private _config: INgPrincipleConfig,
    private _featureFlags: FeatureFlagsService,
    private _logger: LoggerService
  ) {
    this.apiKey = this._config.tyro.apiKey;
    this.posProductData = this._config.tyro.posProductData;
    this.defaultIClient = this._config.tyro.defaultIClient;
  }

  getiClientBaseUrl$(): Observable<TyroIClientUrl> {
    return of(this._featureFlags.isFeatureEnabled(TYRO_TESTING_FEATURE)).pipe(
      switchMap((tyroTestingEnabled) =>
        tyroTestingEnabled
          ? this._resolveFromPracticeSettings$()
          : of(TyroIClientUrl.Production)
      ),
      map((iClientUrl) => iClientUrl ?? this.defaultIClient)
    );
  }

  addLog(message: string, data: object): void {
    void this._logger.log('DEBUG', message, data);
  }

  private _resolveFromPracticeSettings$(): Observable<
    TyroIClientUrl | undefined
  > {
    return this._scope.currentPractice$.pipe(
      map((practice) => practice?.tyroSettings?.iClient),
      map((practiceConfig) => {
        return practiceConfig && isEnumValue(TyroIClientUrl, practiceConfig)
          ? (practiceConfig as TyroIClientUrl)
          : undefined;
      })
    );
  }
}
