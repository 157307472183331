import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard } from '@principle-theorem/shared';
import { flow, isBoolean, isNull, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { CONTACT_DESTINATION_ENTITY } from '../../destination/entities/contact';

export const CONTACT_RESOURCE_TYPE = 'contacts';

export const CONTACT_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Contacts List',
    description: '',
    idPrefix: CONTACT_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface IExactContact {
  id: string;
  name: string;
  is_referral_source: boolean;
  is_specialist: boolean;
  is_doctor: boolean;
  is_gdp: boolean;
  address_1: string | null;
  address_2: string | null;
  suburb: string | null;
  city: string | null;
  state: string | null;
  post_code: number | null;
  homephone: string | null;
  workphone: string | null;
  mobile: string | null;
  email: string | null;
  note: string | null;
}

function isExactContact(item: unknown): item is IExactContact {
  return TypeGuard.interface<IExactContact>(
    {
      id: isString,
      name: isString,
      is_referral_source: isBoolean,
      is_specialist: isBoolean,
      is_doctor: isBoolean,
      is_gdp: isBoolean,
      address_1: [isString, isNull],
      address_2: [isString, isNull],
      suburb: [isString, isNull],
      city: [isString, isNull],
      state: [isString, isNull],
      post_code: [isNumber, isNull],
      homephone: [isString, isNull],
      workphone: [isString, isNull],
      mobile: [isString, isNull],
      email: [isString, isNull],
      note: [isString, isNull],
    },
    true
  )(item);
}

export interface IExactContactFilters {
  id: string;
}

const CONTACT_SOURCE_QUERY = `
SELECT
  id::text,
  NULLIF(name, '') AS name,
  isreferralsource AS is_referral_source,
  isspecialist AS is_specialist,
  isdoctor AS is_doctor,
  isgdp AS is_gdp,
  NULLIF(address_address1, '') AS address_1,
  NULLIF(address_address2, '') AS address_2,
  NULLIF(address_suburb, '') AS suburb,
  NULLIF(address_towncity, '') AS city,
  NULLIF(address_areastateprovince, '') AS state,
  NULLIF(address_postcodezipcode::text, '') AS post_code,
  NULLIF(homephone, '') AS homephone,
  NULLIF(workphone, '') AS workphone,
  NULLIF(mobile, '') AS mobile,
  NULLIF(email, '') AS email,
  NULLIF(note, '') AS note
FROM
  convreferrer
WHERE name != ''
`;

export class ContactsSourceEntity extends BaseSourceEntity<
  IExactContact,
  object,
  IExactContactFilters
> {
  sourceEntity = CONTACT_SOURCE_ENTITY;
  entityResourceType = CONTACT_RESOURCE_TYPE;
  sourceQuery = CONTACT_SOURCE_QUERY;
  verifySourceFn = isExactContact;
  migrationDestinations = [CONTACT_DESTINATION_ENTITY.metadata.key];

  override transformDataFn = flow([
    (rows: IExactContact[]) =>
      rows.map((row) => ({
        ...row,
        id: row.id.replace(/\//g, '-'),
        post_code: row.post_code ? Number(row.post_code) : row.post_code,
      })),
  ]);

  translate(_data: IExactContact): object {
    return {};
  }

  getFilterData(data: IExactContact): IExactContactFilters {
    return {
      id: data.id,
    };
  }

  getSourceRecordId(data: IExactContact): string {
    return data.id;
  }

  getSourceLabel(data: IExactContact): string {
    return `${data.id} ${data.name}`;
  }
}
