<div fxFill fxLayout="column">
  <pr-mention-toolbar
    *ngrxLet="candidate$ as candidate"
    [label]="candidate.patient.name"
    [resource]="mentionResource$ | async"
    [interactiveResource]="interactiveResource$ | async"
   />

  <pr-gap-warnings
    [appointment]="appointment$ | async"
    [gap]="data.gap"
   />

  <div>
    <mat-divider />
    <mat-grid-list
      *ngrxLet="appointment$ as appointment"
      cols="3"
      rowHeight="70px"
      class="patient-info"
    >
      <mat-grid-tile>
        <div fxLayout="column" fxLayoutAlign="start center">
          <mat-icon>date_range</mat-icon>
          <span class="description">
            <span *ngIf="appointment.event; else noEvent">
              <pr-formatted-event-field
                field="from"
                [event]="appointment.event"
                [format]="dateService.format.dateWithTimes"
               />
              <br />
              with {{ appointment.practitioner.name }}
            </span>
            <ng-template #noEvent>
              <span>No Appointment</span>
            </ng-template>
          </span>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div fxLayout="column" fxLayoutAlign="start center">
          <mat-icon>format_list_bulleted</mat-icon>
          <span class="description">
            {{ appointment.treatmentPlan.name }}
          </span>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div fxLayout="column" fxLayoutAlign="start center">
          <mat-icon>timelapse</mat-icon>
          <span class="description">
            {{ appointment | map: duration }} mins
          </span>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-divider />
  </div>

  <div fxFill fxFlex class="interactions">
    <pr-appointment-interactions
      fxFill
      enableScroll
      [appointment]="appointment$ | async"
     />
  </div>

  <mat-divider />

  <div class="completed-action">
    <pt-buttons-container alignment="start">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        mat-stroked-button
        *ngIf="(isUnavailable$ | async) === false"
        [disabled]="isApproved$ | async"
        matTooltip="Candidate is unavailable for gap"
        matTooltipShowDelay="500"
        (click)="updateAvailability()"
      >
        Not Available
      </button>
      <button
        mat-stroked-button
        *ngIf="isUnavailable$ | async"
        matTooltip="Set candidate available before approving"
        matTooltipShowDelay="500"
        [disabled]="isApproved$ | async"
        (click)="updateAvailability()"
      >
        Available
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="(isApproved$ | async) || (isUnavailable$ | async)"
        matTooltip="Fill gap with candidate appointment"
        matTooltipShowDelay="500"
        (click)="openMoveAppointment()"
        mat-dialog-close
      >
        Approve
      </button>
    </pt-buttons-container>
  </div>
</div>
