import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  type OnDestroy,
} from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  TrackByFunctions,
  type TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  type IPatient,
  type IPatientRelationship,
  isPatientRelationship,
  type PatientRelationshipType,
  PATIENT_RELATIONSHIP_TYPES,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { isNil } from 'lodash';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

@Component({
  selector: 'pr-patient-relationship-selector',
  templateUrl: './patient-relationship-selector.component.html',
  styleUrls: ['./patient-relationship-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PatientRelationshipSelectorComponent),
      multi: true,
    },
  ],
})
export class PatientRelationshipSelectorComponent
  implements ControlValueAccessor, OnDestroy
{
  private _onDestroy$: Subject<void> = new Subject();
  onChange: (_: unknown) => void;
  onTouched: (_: unknown) => void;
  isRequired$ = new BehaviorSubject<boolean>(false);
  relationshipTypes = PATIENT_RELATIONSHIP_TYPES;
  trackByRelationshipType =
    TrackByFunctions.variable<PatientRelationshipType>();
  patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);

  @Input() placeholder: string = 'Patient';
  @Input() form: TypedFormGroup<IPatientRelationship>;
  @Input() label?: string;

  @Input()
  set required(required: boolean) {
    if (!isNil(required)) {
      this.isRequired$.next(required);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  writeValue(value: IPatientRelationship): void {
    if (isPatientRelationship(value)) {
      this.form.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  clear(): void {
    this.form.reset();
  }
}
