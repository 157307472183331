import { getActiveNode, nodeIsActive } from '@principle-theorem/editor';
import { type Node } from '@tiptap/pm/model';
import { EditorState } from '@tiptap/pm/state';
import { type IEditorNodeComponent } from '../components/editor-node.component';
import { hasFloatingDialog, hasUid } from '../decorators/editor-node';

/**
 * Makes a best effort attempt to determine if this specific instance of a node is selected.
 * As Prosemirror can reuse nodes throughout the document if they are idenntical, we need to
 * check against the current position of the node instance against the current selection.
 *
 * As we're also allowing for angular nodes to have connected dialogs, we need to ensure
 * that the dialog isn't also active before deselecting a node.
 *
 * `getPos` (as given by the nodeViews) will update itself to return the current position of
 * the start of the node so can be used reliably for node position after transactions have run.
 */
export function detectNodeIsSelected<T extends IEditorNodeComponent>(
  instance: T,
  document: Document,
  state: EditorState,
  node: Node,
  getPos: (() => number) | boolean
): boolean | undefined {
  const { from, to } = state.selection;
  const nodeFrom = typeof getPos === 'boolean' ? 0 : getPos();

  if (from >= nodeFrom && to <= nodeFrom + node.nodeSize) {
    return true;
  }

  if (nodeIsActive(state, node)) {
    const selectionFrom = state.selection.$from.pos;
    const selectionTo = state.selection.$to.pos;
    const nodeTo = nodeFrom + node.nodeSize;

    if (selectionFrom >= nodeFrom && selectionTo <= nodeTo) {
      return true;
    }
  }

  if (hasUid(instance)) {
    const activeNode = getActiveNode(state, node.type);
    return activeNode?.attrs.uid === instance.uid;
  }

  return detectSelectedOutsideEditor(instance, document) ?? false;
}

export function detectSelectedOutsideEditor<T extends IEditorNodeComponent>(
  instance: T,
  document: Document
): boolean | undefined {
  if (hasFloatingDialog(instance) && instance.dialogRef) {
    const selection = document.getSelection() || undefined;
    if (!selection) {
      return;
    }

    const overlayElement = instance.dialogRef?.overlayRef.overlayElement;
    const activeElement = selection.anchorNode
      ? (selection.anchorNode as HTMLElement)
      : undefined;

    if (!activeElement || !overlayElement) {
      return;
    }

    const dialogContainsFocus: boolean =
      overlayElement === activeElement ||
      overlayElement.contains(activeElement);

    const componentElement: HTMLElement = instance.elementRef
      .nativeElement as HTMLElement;

    const componentContainsFocus: boolean =
      componentElement === activeElement ||
      componentElement.contains(activeElement);

    if (!dialogContainsFocus && !componentContainsFocus) {
      return false;
    }
  }
}
