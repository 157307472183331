<div
  fxFlex
  fxLayout="row"
  fxLayoutAlign="center center"
  *ngIf="reportFacade.isLoading$ | async; else showPage"
>
  <mat-spinner mode="indeterminate" />
</div>

<ng-template #showPage>
  <div class="error" *ngIf="reportFacade.errorMessage$ | async as errorMessage">
    {{ errorMessage }}
  </div>

  <pr-invoice-report-table />

  <div class="layout-margin mat-caption">
    * List of unpaid invoices <strong>created</strong> within the selected date
    range.
  </div>
</ng-template>
