import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  ButtonsContainerComponent,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  type IPractice,
  type ISterilisationMachine,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  type INamedDocument,
  isSameRef,
} from '@principle-theorem/shared';
import { kebabCase } from 'lodash';

interface ISterilisationMachineForm
  extends Omit<ISterilisationMachine, 'deleted'> {}

export interface ISterilisationMachineDialogData {
  machine?: WithRef<ISterilisationMachine>;
  practices: INamedDocument<IPractice>[];
}

@Component({
  selector: 'pr-sterilisation-machine-dialog',
  standalone: true,
  imports: [
    CommonModule,
    NgMaterialModule,
    ButtonsContainerComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './sterilisation-machine-dialog.component.html',
  styleUrl: './sterilisation-machine-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SterilisationMachineDialogComponent {
  private _dialogRef = inject(
    MatDialogRef<SterilisationMachineDialogComponent, ISterilisationMachine>
  );
  form = new TypedFormGroup<ISterilisationMachineForm>({
    id: new TypedFormControl(undefined),
    name: new TypedFormControl('', Validators.required),
    description: new TypedFormControl(''),
    practice: new TypedFormControl<INamedDocument<IPractice>>(
      undefined,
      Validators.required
    ),
  });
  data = inject<ISterilisationMachineDialogData>(MAT_DIALOG_DATA);

  constructor() {
    if (this.data.machine) {
      this.form.patchValue(this.data.machine);
      return;
    }

    if (this.data.practices.length === 1) {
      this.form.controls.practice.patchValue(this.data.practices[0]);
    }
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }
    const formValue = this.form.getRawValue();
    if (!formValue.id) {
      formValue.id = kebabCase(formValue.name);
    }

    this._dialogRef.close({
      deleted: false,
      ...formValue,
    });
  }

  comparePractice(
    aPractice: INamedDocument<IPractice>,
    bPractice: INamedDocument<IPractice>
  ): boolean {
    return isSameRef(aPractice.ref, bPractice.ref);
  }
}
