import { Injectable, inject } from '@angular/core';
import {
  GlobalStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { BasicDialogService } from '@principle-theorem/ng-shared';
import {
  Event,
  EventTimePeriod,
  getStafferEventables$,
  TimezoneResolver,
} from '@principle-theorem/principle-core';
import { AppointmentPermissions } from '@principle-theorem/principle-core/features';
import {
  EventType,
  type IBrand,
  type IEventable,
  isAppointment,
  isAppointmentRequest,
  isCalendarEvent,
  isPreBlockEvent,
  type ITreatmentCategory,
  type PreBlockEvent,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  filterUndefined,
  type IReffable,
  isSameRef,
  multiMap,
  snapshot,
  toMomentTz,
} from '@principle-theorem/shared';
import { compact, first, flatten, uniqBy } from 'lodash';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SchedulingScenarioService {
  private _globalStore = inject(GlobalStoreService);

  constructor(
    private _organisation: OrganisationService,
    private _basicDialog: BasicDialogService
  ) {}

  async isBlockedByDoubleBooking(
    currentEventable: IEventable,
    treatmentCategory?: DocumentReference<ITreatmentCategory>
  ): Promise<boolean> {
    const canDoubleBook = await snapshot(
      this._organisation.userHasPermission$(
        AppointmentPermissions.SchedulingDoubleBook
      )
    );

    const events = await getOverlappingEvents(
      currentEventable,
      await snapshot(this._organisation.brand$.pipe(filterUndefined()))
    );

    const conflictingPreBlocks = findConflictingPreBlocks(
      events,
      currentEventable,
      treatmentCategory
    );

    if (conflictingPreBlocks.length) {
      const allowedTreatmentCategories =
        await this._getAllowedTreatmentCategories(conflictingPreBlocks);

      if (!canDoubleBook) {
        await this._basicDialog.alert({
          title: `Pre-block Conflict`,
          prompt: [
            `The event doesn't match the required treatment category for the pre-block. The following are allowed:`,
            ...allowedTreatmentCategories.map((category) => `- ${category}`),
          ],
          submitLabel: 'Close',
        });
        return true;
      }

      const approveBook = await this._basicDialog.confirm({
        prompt: [
          `Are you sure you want to book over this pre-block? The event doesn't match the required treatment category for the pre-block. The following are allowed:`,
          ...allowedTreatmentCategories.map((category) => `- ${category}`),
        ],
        title: `Pre-block Conflict`,
        submitLabel: 'Yes, book over pre-block',
        submitColor: 'warn',
        cancelLabel: 'Cancel',
      });

      if (!approveBook) {
        return true;
      }
    }

    if (eventableOverlapsBlockingEvent(currentEventable, events)) {
      if (!canDoubleBook) {
        await this._basicDialog.alert({
          title: `No permission to double-book`,
          prompt: `You don't have permission to double-book appointments. Please change the appointment time to continue.`,
          submitLabel: 'Close',
        });
        return true;
      }

      const approveBook = await this._basicDialog.confirm({
        prompt: `Are you sure you want to double-book this appointment? There's currently a blocking event that overlaps this time.`,
        title: 'Are you sure you want to double-book?',
        submitLabel: 'Yes, double-book',
        submitColor: 'warn',
        cancelLabel: 'Cancel',
      });

      if (!approveBook) {
        return true;
      }
    }

    return false;
  }

  private _getAllowedTreatmentCategories(
    preBlocks: PreBlockEvent[]
  ): Promise<string[]> {
    const categories = uniqBy(
      flatten(preBlocks.map((preBlock) => preBlock.allowedTreatmentCategories)),
      (categoryRef) => categoryRef.path
    );

    return snapshot(
      combineLatest(
        categories.map((category) =>
          this._globalStore.getTreatmentCategory$(category)
        )
      ).pipe(
        map(compact),
        multiMap((category) => category.name),
        map((names) => names.sort())
      )
    );
  }
}

export function findConflictingPreBlocks(
  eventables: IEventable[],
  currentEventable: IEventable,
  treatmentCategory?: DocumentReference<ITreatmentCategory>
): PreBlockEvent[] {
  const filteredEventables = eventables.filter((eventable) => {
    const isSameEventable =
      eventable.ref &&
      currentEventable.ref &&
      isSameRef(eventable.ref, currentEventable.ref);

    if (isSameEventable || !isPreBlockEvent(eventable.event)) {
      return false;
    }
    return true;
  });

  const overlappingPreBlocks = EventTimePeriod.findOverlappingEventables(
    filteredEventables,
    Event.toTimePeriod(currentEventable.event)
  )
    .map((eventable) => eventable.event)
    .filter((event): event is PreBlockEvent => isPreBlockEvent(event));

  return overlappingPreBlocks.filter((preBlock) => {
    if (!preBlock.allowedTreatmentCategories.length) {
      return false;
    }

    if (!treatmentCategory) {
      return true;
    }

    return preBlock.allowedTreatmentCategories.every(
      (category) => !isSameRef(category, treatmentCategory)
    );
  });
}

export function eventableOverlapsBlockingEvent(
  currentEventable: IEventable,
  eventables: IEventable[]
): boolean {
  return EventTimePeriod.hasOverlappingEventables(
    eventables.filter((eventable) => {
      const isRequest = isAppointmentRequest(eventable);
      const isSameEventable = isSameRef(eventable.ref, currentEventable.ref);
      const isRosterEvent = eventable.event.type === EventType.RosteredOn;
      const isBlockingEvent =
        isAppointment(eventable) ||
        (isCalendarEvent(eventable) && eventable.isBlocking);
      return (
        !isSameEventable && isBlockingEvent && !isRequest && !isRosterEvent
      );
    }),
    Event.toTimePeriod(currentEventable.event)
  );
}

async function getOverlappingEvents(
  currentEventable: IEventable,
  brand: IReffable<IBrand>
): Promise<IEventable[]> {
  if (currentEventable.event.type === EventType.RosteredOn) {
    return [];
  }

  const staffer = first(Event.staff(currentEventable.event));
  if (!staffer) {
    return [];
  }
  const timezone = await TimezoneResolver.fromEvent(currentEventable);
  const timePeriod = Event.toTimePeriod(currentEventable.event, timezone);
  const queryPeriod = {
    from: toMomentTz(timePeriod.from.clone(), timezone).startOf('day'),
    to: timePeriod.to.clone(),
  };

  const practice = currentEventable.event.practice;
  return snapshot(
    getStafferEventables$(queryPeriod, [staffer], practice, brand)
  );
}
