<pt-loader-button
  type="flat"
  color="primary"
  [cta]="true"
  [ngClass]="{ adding: adding$ | async, disabled: isDisabled$ | async }"
  [disabled]="isDisabled$ | async"
  [loading]="adding$ | async"
  [matMenuTriggerFor]="paymentMenu"
>
  <div
    class="flex"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
  >
    <mat-icon *ngIf="(adding$ | async) === false"> credit_card </mat-icon>

    <div fxLayout="column" fxLayoutAlign="center start">
      <div class="label">Payment</div>
      <div *ngIf="info$ | async as subtext" class="subtext mat-caption">
        {{ subtext }}
      </div>
    </div>
  </div>
</pt-loader-button>

<mat-menu #paymentMenu="matMenu" class="no-padding">
  <mat-action-list class="no-padding">
    <pr-add-transaction-menu-button
      *ngFor="
        let option of transactions.paymentProviderOptions$ | async;
        trackBy: transactions.trackByOption
      "
      [option]="option"
      [invoice]="invoice$ | async"
      [refund]="false"
    />
  </mat-action-list>
</mat-menu>
