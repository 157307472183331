import { PatientRelationship } from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  IBasePatient,
  IDestinationEntityJobRunOptions,
  ISourceEntityRecord,
  IsPrimaryContact,
  PatientRelationshipType,
  type FailedDestinationEntityRecord,
  type IBrand,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type MergeConflictDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  getError,
  snapshotCombineLatest,
  toNamedDocument,
  toTimestamp,
  type DocumentReference,
  type WithRef,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import { PracticeMigration } from '../../../practice-migrations';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  ICorePracticePatient,
  ICorePracticePatientFilters,
  ICorePracticePatientTranslations,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { PatientDestinationEntity } from './patients';

export const PATIENT_RELATIONSHIP_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: 'patientRelationships',
    label: 'Patient Relationships',
    description: '',
  },
});

export interface IPatientRelationshipDestinationRecord {
  patientRef: DocumentReference;
}

export interface IPatientRelationshipJobData {
  brand: WithRef<IBrand>;
  sourcePatient: IGetRecordResponse<
    ICorePracticePatient,
    ICorePracticePatientTranslations,
    ICorePracticePatientFilters
  >;
}

export class PatientRelationshipDestinationEntity extends BaseDestinationEntity<
  IPatientRelationshipDestinationRecord,
  IPatientRelationshipJobData,
  IPatientRelationshipJobData
> {
  destinationEntity = PATIENT_RELATIONSHIP_DESTINATION_ENTITY;

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  sourceCountDataAccessor(
    data: IPatientRelationshipJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMapHandler: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientRelationshipJobData[]> {
    const brand$ = PracticeMigration.brand$(migration);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([brand$]),
    ]).pipe(
      map(([sourcePatients, [brand]]) =>
        sourcePatients.map((sourcePatient) => ({
          sourcePatient,
          brand,
        }))
      )
    );
  }

  getDestinationEntityRecordUid(data: IPatientRelationshipJobData): string {
    return data.sourcePatient.record.uid;
  }

  buildMigrationData(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IPatientRelationshipJobData
  ):
    | IPatientRelationshipJobData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord) {
    return data;
  }

  hasMergeConflict(
    _translationMap: TranslationMapHandler,
    _data: IPatientRelationshipJobData
  ): IPatientRelationshipJobData | undefined {
    return;
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientRelationshipJobData,
    migrationData: IPatientRelationshipJobData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      label: migrationData.sourcePatient.record.label,
      uid: migrationData.sourcePatient.record.uid,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    data: IPatientRelationshipJobData
  ): Promise<IDestinationEntityRecord> {
    const sourcePatientId = data.sourcePatient.data.data.id.toString();
    const patientRef = await translationMapHandler.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        `Couldn't resolve patient`
      );
    }

    try {
      await this._addRelationshipsToPatient(
        translationMapHandler,
        data.sourcePatient.data.data,
        patientRef
      );
      return this._buildSuccessResponse(data.sourcePatient.record);
    } catch (error) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        getError(error)
      );
    }
  }

  private _buildSuccessResponse(
    patient: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>
  ): IDestinationEntityRecord<IPatientRelationshipDestinationRecord> {
    return {
      uid: patient.uid,
      label: patient.label,
      data: {
        patientRef: patient.ref,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: patient.ref,
      migratedAt: toTimestamp(),
    };
  }

  private _buildErrorResponse(
    patient: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>,
    errorMessage: string
  ): IDestinationEntityRecord<IPatientRelationshipDestinationRecord> {
    return {
      uid: patient.uid,
      label: patient.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: patient.ref,
      errorMessage,
      failData: {
        patientRef: patient.ref,
      },
    };
  }

  private async _addRelationshipsToPatient(
    translationMap: TranslationMapHandler,
    sourcePatient: ICorePracticePatient,
    patientRef: DocumentReference<IPatient>
  ): Promise<void> {
    if (!sourcePatient.familyPatientId) {
      return;
    }

    const familyHeadRef = await translationMap.getDestination<
      IBasePatient & IsPrimaryContact
    >(sourcePatient.familyPatientId.toString(), PATIENT_RESOURCE_TYPE);

    if (!familyHeadRef) {
      return;
    }

    const familyHead = await Firestore.getDoc(familyHeadRef);

    await FirestoreMigrate.patchDoc(patientRef, {
      primaryContact: {
        patient: toNamedDocument(familyHead),
        type: PatientRelationshipType.Unknown,
      },
    });

    await PatientRelationship.addPrimaryRelationship(
      await Firestore.getDoc(patientRef)
    );
  }
}
