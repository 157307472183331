// eslint-disable-next-line
/// <reference types="google.maps" />

import {
  ADDRESS_COMPONENT_TYPE_MAP,
  AddressComponentType,
  AddressComponents,
  IAddressMetadata,
  IManualAddressInput,
} from '@principle-theorem/principle-core/interfaces';
import { camelCase, compact } from 'lodash';

export class Address {
  static metadata(result: google.maps.places.PlaceResult): IAddressMetadata {
    const address = result.formatted_address;
    const location = result.geometry?.location;

    const coordinates = location
      ? { latitude: location.lat(), longitude: location.lng() }
      : undefined;

    const components = Address.transformAddressComponents(
      result.address_components ?? []
    );

    return { address, coordinates, components, isVerified: !!coordinates };
  }

  static transformAddressComponents(
    data: google.maps.GeocoderAddressComponent[]
  ): AddressComponents {
    const transformedData: AddressComponents = {};

    data.forEach((component) => {
      const componentType = component.types[0];
      const type = ADDRESS_COMPONENT_TYPE_MAP[componentType] || componentType;
      transformedData[camelCase(type)] = component.long_name;
    });

    return transformedData;
  }

  static format(address: IManualAddressInput): string {
    const orderedKeys: (keyof IManualAddressInput)[] = [
      AddressComponentType.StreetName,
      AddressComponentType.Subpremise,
      AddressComponentType.City,
      AddressComponentType.State,
      AddressComponentType.PostalCode,
      AddressComponentType.Country,
    ];

    const orderedValues = orderedKeys.map((key) => address[key]);
    return compact(orderedValues).join(', ');
  }

  static streetAddressFromComponents(components: AddressComponents): string {
    const streetDetails = [
      components?.[AddressComponentType.StreetNumber],
      components?.[AddressComponentType.StreetName],
    ];
    return compact(streetDetails).join(' ');
  }
}
