/* eslint-disable @typescript-eslint/naming-convention */

export interface AddMediClaimLine {
  itemNum: string;
  /**
   * Required for Full & Part Paid
   */
  chargeAmount: number;
  dateOfService: string;
  itemOverrideCde?: 'AO' | 'AP';
  lspNum?: string;
  equipmentId?: string;
  selfDeemedCde?: string;
  /**
   * Required for Part Paid
   */
  contribPatientAmount: number;
  spcId?: string;
}

export interface AddClaimLine {
  patientId: string;
  itemNumber: string;
  bodyPart: string;
  serviceDate: string;
  fee: number;
}

export interface AddClaimPharmLine {
  patientId: string;
  itemNumber: string;
  scriptNumber: string;
  compoundDrugs: boolean;
  serviceDate: string;
  fee: number;
}

export interface AddPatientName {
  patientId: string;
  patientName: string;
}

export interface SendClaimCancelRequest {
  addPatientName: AddPatientName[];
}

export interface SendClaimPharmRequest {
  addClaimPharmLine: AddClaimPharmLine[]; // Required
  addPatientName: AddPatientName[];
}

export interface SendClaimRequest {
  addClaimLine: AddClaimLine[]; // Required
  addPatientName: AddPatientName[];
}

export interface SendMedicareClaimRequest {
  addMediClaimLine: AddMediClaimLine[]; // Required
}
