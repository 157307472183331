<ng-container *ngIf="hasStripeIntegration$ | async; else showConnect">
  <p>You are connected with Stripe!</p>
</ng-container>

<ng-template #showConnect>
  <a *ngIf="!loading; else isLoading" (click)="openStripeConnect()">
    <pt-stripe-connect-button />
  </a>
</ng-template>

<ng-template #isLoading>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <mat-spinner diameter="24" />
    <span>Connecting with Stripe. A new window will open soon.</span>
  </div>
</ng-template>
