import {
  IBaseChartBuilderConfig,
  type ICustomReportFilterValue,
} from '@principle-theorem/principle-core/interfaces';
import {
  CrossfilterGroup,
  IMeasureBuilderData,
  type ICanGroupMeasuresProperty,
  type IChartConfig,
} from '@principle-theorem/reporting';
import {
  isDate,
  isObject,
  toISODate,
  TypeGuard,
  WithId,
} from '@principle-theorem/shared';
import type * as dc from 'dc';
import { isString } from 'lodash';
import { type Subject } from 'rxjs';
import {
  DCDataTransformer,
  type IDCTransformResult,
} from '../../../../models/report/charts/data-transformers/dc-data-transformer';
import { type IDCTooltip } from './dc-tooltip';
import { ObservableDataSource } from '@principle-theorem/ng-shared';
import crossfilter, { Dimension, NaturallyOrderedValue } from 'crossfilter2';
import { ITableRow } from '../table-chart-builder';

export const D3_CHART_ELEM_SELECTOR = 'd3-chart';

export interface IDCChartFilter {
  label: string;
  valueOf(): string | number;
}

export interface IDCChartEvent {
  config: IChartConfig;
  groupByDimension: ICanGroupMeasuresProperty;
  filterValues: ICustomReportFilterValue[];
}

export interface ITableBuilder {
  getLabel(config: IChartConfig): string;
  getChart(
    results: IDCTransformResult[],
    config: IChartConfig,
    eventListener$?: Subject<IDCChartEvent>
  ): ObservableDataSource<ITableRow> | undefined;
  getColumns(
    results: IDCTransformResult[],
    config: IChartConfig
  ): WithId<{ id: string; label: string }>[];
}

export interface IDCChartBuilder<T> {
  getLabel(config: IChartConfig): string;
  getChart(
    results: IDCTransformResult[],
    config: IChartConfig,
    eventListener$?: Subject<IDCChartEvent>
  ): dc.BaseMixin<T> | undefined;
  getLegend?(): dc.HtmlLegend | dc.Legend;
  getTooltip?(config: IChartConfig): IDCTooltip;
  getChartClasses?(config: IChartConfig): string[];
}

export interface ICanBuildForCompositeChart<T> {
  build(
    parent: string | dc.CompositeChart,
    result: IDCTransformResult,
    config: IChartConfig,
    measure: IMeasureBuilderData
  ): dc.BaseMixin<T>;
}

export const DEFAULT_CHART_BUILDER_CONFIG: IBaseChartBuilderConfig = {
  height: 200,
  width: 200,
};

export function applyBaseConfigToChart<T>(
  config: IBaseChartBuilderConfig,
  chart: dc.BaseMixin<T>
): void {
  chart.height(config.height);
  chart.width(config.width);
  chart.title(() => '');
  if (!config.disableControls) {
    chart.turnOnControls();
  }
}

type DCFilter = string | [Date, Date] | IDCChartFilter;

export class ChartBuildHelpers {
  static onFilteredEvent(
    chart: dc.BaseMixin<unknown>,
    config: IChartConfig,
    eventListener$: Subject<IDCChartEvent>
  ): void {
    const filters = chart.filters() as DCFilter[];
    const filterValues = filters.map((filter) =>
      ChartBuildHelpers.getFilterValue(filter)
    );
    const groupByDimension = config.builderData.groupByDimension;
    if (!groupByDimension) {
      // eslint-disable-next-line no-console
      console.error('No groupByDimension found for chart', config);
      return;
    }
    eventListener$.next({ config, filterValues, groupByDimension });
  }

  static buildFilteredDimension(
    result: IDCTransformResult,
    config: IChartConfig,
    measure?: IMeasureBuilderData
  ): {
    dimension: Dimension<unknown, NaturallyOrderedValue>;
    group?: CrossfilterGroup;
  } {
    const transformer = new DCDataTransformer();
    const filters = measure?.filters ?? [];
    const filteredData = ChartBuildHelpers.buildFilteredData(result, filters);

    const ndx = crossfilter(filteredData);

    result.factTable.onChange(() => {
      ndx.remove();
      ndx.add(filteredData);
    });

    const dimension = transformer.getDimension(ndx, config, result.measure);
    const group = transformer.getGroup(result.measure, dimension);
    return { dimension, group };
  }

  static buildFilteredData(
    result: IDCTransformResult,
    filters: crossfilter.ComparableValue[]
  ): unknown[] {
    return result.factTable.allFiltered().filter((fact) => {
      const dataResults = [result.measure.measure.dataAccessor(fact)].flat();
      return dataResults.some((dataResult) =>
        this.matchesFilters(dataResult, filters)
      );
    });
  }

  static matchesFilters(
    dataResult: crossfilter.ComparableValue,
    filters: crossfilter.ComparableValue[]
  ): boolean {
    if (!filters.length) {
      return true;
    }
    return filters.some((filter) => {
      if (isObject(dataResult)) {
        return dataResult.valueOf() === filter;
      }
      return filter === dataResult;
    });
  }

  static getFilterValue(filter: DCFilter): ICustomReportFilterValue {
    if (isString(filter)) {
      return {
        label: filter,
        value: filter,
      };
    }
    if (ChartBuildHelpers.isDateRangeFilter(filter)) {
      const from = toISODate(filter[0]);
      const to = toISODate(filter[1]);
      return {
        label: `${from} - ${to}`,
        value: [from, to],
      };
    }
    return {
      label: filter.label,
      value: filter.valueOf() ?? undefined,
    };
  }

  static isDateRangeFilter(data: unknown): data is [Date, Date] {
    return TypeGuard.arrayOf(isDate)(data) && data.length === 2;
  }
}
