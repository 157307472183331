<div
  class="timeline-container drag-scroll-container h-full w-full"
  ptResizeSensor
  (resizeEvent)="view.containerDimensions$.next($event)"
>
  <pr-interactive-timeline
    [timelineMode]="timelineMode$ | ngrxPush"
    [createType]="createType$ | ngrxPush"
    [view]="view"
    (dragEvent)="eventDragged.emit($event)"
    (resizeEvent)="eventResized.emit($event)"
    (selectEvent)="selectEvent($event)"
    (updateEvent)="updateEvent($event)"
  />
</div>
