import { toTextContent } from '@principle-theorem/editor';
import {
  IAppointmentDocument,
  ILab,
  ILabJob,
  ILabJobType,
  InteractionType,
  LabJobStatus,
} from '@principle-theorem/principle-core/interfaces';
import { INamedDocument, isSameRef, WithRef } from '@principle-theorem/shared';
import { InteractionProperty } from '../interaction/interaction-title-builder';
import { WorkflowItemUpdater } from '../workflow-item/workflow-item-updater';
import { LabJob } from './lab-job';

export class LabJobUpdater extends WorkflowItemUpdater<WithRef<ILabJob>> {
  updateStatus(status: LabJobStatus): this {
    if (this._item.status === status) {
      return this;
    }
    LabJob.updateStatus(this._item, status);
    this._transaction.add(InteractionType.TaskEdit, [
      toTextContent(`updated status to `),
      new InteractionProperty(status).toNodeSchema(),
    ]);
    return this;
  }

  updateCost(cost: number): this {
    if (this._item.cost === cost) {
      return this;
    }
    this._item.cost = cost;

    const formatted: string = '$' + cost.toFixed(2);
    this._transaction.add(InteractionType.TaskEdit, [
      toTextContent(`updated cost to `),
      new InteractionProperty(formatted).toNodeSchema(),
    ]);
    return this;
  }

  updateLab(lab: INamedDocument<ILab>): this {
    if (isSameRef(this._item.lab, lab)) {
      return this;
    }
    this._item.lab = lab;
    this._transaction.add(InteractionType.TaskEdit, [
      toTextContent(`updated lab to `),
      new InteractionProperty(lab.name).toNodeSchema(),
    ]);
    return this;
  }

  updateType(type: ILabJobType): this {
    if (this._item.type.name === type.name) {
      return this;
    }
    this._item.type = type;
    this._transaction.add(InteractionType.TaskEdit, [
      toTextContent(`updated lab type to `),
      new InteractionProperty(type.name).toNodeSchema(),
    ]);
    return this;
  }

  updateAppointment(appointment: IAppointmentDocument | undefined): this {
    if (!appointment) {
      return this;
    }

    if (isSameRef(this._item.appointment, appointment)) {
      return this;
    }
    this._item.appointment = appointment;
    const treatmentPlanName = appointment.treatmentPlanName ?? '';
    this._transaction.add(InteractionType.AppointmentReschedule, [
      toTextContent(`updated appointment to `),
      new InteractionProperty(treatmentPlanName).toNodeSchema(),
    ]);
    return this;
  }
}
