import { Contact } from '@principle-theorem/principle-core';
import {
  FailedDestinationEntityRecord,
  IDestinationEntityJobRunOptions,
  SourceEntityRecordStatus,
  isContactDetails,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { compact } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  BaseContactDestinationEntity,
  IContactJobData,
  IContactMigrationData,
} from '../../../destination/entities/contact';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import {
  ContactsSourceEntity,
  type IExactContact,
} from '../../source/entities/contacts';

export class ContactDestinationEntity extends BaseContactDestinationEntity<
  IExactContact,
  IContactJobData<IExactContact>
> {
  contactSourceEntity = new ContactsSourceEntity();

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _transaltionMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IContactJobData<IExactContact>[]> {
    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.contactSourceEntity,
        runOptions
      ),
      PracticeMigration.brand$(migration).pipe(take(1)),
    ]).pipe(
      map(([sourceContacts, brand]) =>
        sourceContacts.map((sourceContact) => ({ sourceContact, brand }))
      )
    );
  }

  buildMigrationData(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IContactJobData<IExactContact>
  ):
    | IContactMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord) {
    if (data.sourceContact.record.status === SourceEntityRecordStatus.Invalid) {
      return this._buildErrorResponse(
        data.sourceContact,
        'Source contact is invalid'
      );
    }

    const contactData = data.sourceContact.data.data;
    const sourceContactId =
      this.contactSourceEntity.getSourceRecordId(contactData);

    const contactDetails = {
      name: contactData.name,
      email: contactData.email ?? undefined,
      phone: contactData.workphone ?? contactData.homephone ?? undefined,
      address: getContactAddress(contactData),
      mobileNumber: contactData.mobile ?? undefined,
    };

    if (!isContactDetails(contactDetails)) {
      return this._buildErrorResponse(
        data.sourceContact,
        'Missing Required contact properties'
      );
    }

    return {
      sourceContactId,
      contact: Contact.init(contactDetails),
    };
  }
}

function getContactAddress(contact: IExactContact): string | undefined {
  const parts = compact([
    contact.address_1,
    contact.address_2,
    contact.city,
    contact.suburb,
    contact.state,
    contact.post_code,
  ]);
  return parts.length > 0 ? parts.join(' ') : undefined;
}
