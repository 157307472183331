import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  type OnDestroy,
  ViewChild,
} from '@angular/core';
import { snapshot } from '@principle-theorem/shared';
import { ResizeSensor } from 'css-element-queries';
import { noop } from 'lodash';
import { combineLatest, type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export interface ID3ChartRenderer {
  render(elemSelector: Element): void;
}

@Component({
  selector: 'pr-d3-chart',
  templateUrl: './d3-chart.component.html',
  styleUrls: ['./d3-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class D3ChartComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _chartDiv$ = new ReplaySubject<ElementRef<SVGSVGElement>>(1);
  private _renderer$ = new ReplaySubject<ID3ChartRenderer>(1);
  private _resizeSensor$: Observable<ResizeSensor>;
  @Input() label: string;

  @ViewChild('d3Chart', { static: true })
  set chartDiv(chartDiv: ElementRef<SVGSVGElement>) {
    if (chartDiv) {
      this._chartDiv$.next(chartDiv);
    }
  }

  @Input()
  set renderer(renderer: ID3ChartRenderer) {
    if (renderer) {
      this._renderer$.next(renderer);
    }
  }

  constructor() {
    combineLatest([this._chartDiv$, this._renderer$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => void this._render());

    this._resizeSensor$ = this._chartDiv$.pipe(
      map(
        (chartDiv) =>
          new ResizeSensor(chartDiv.nativeElement, () => void this._render())
      )
    );
  }

  async ngOnDestroy(): Promise<void> {
    const resizeSensor = await snapshot(this._resizeSensor$);
    resizeSensor.detach(noop);
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private async _render(): Promise<void> {
    const renderer = await snapshot(this._renderer$);
    const chartDiv = await snapshot(this._chartDiv$);
    renderer.render(chartDiv.nativeElement);
  }
}
