<div class="info">
  <h2>Participants</h2>
  <div
    *ngFor="
      let participant of participants$ | ngrxPush;
      trackBy: trackByParticipant
    "
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
    class="participant"
  >
    <pt-user-icon
      [diameter]="32"
      [src]="participant | profileImage$ | ngrxPush"
      [name]="participant.name"
    />

    <pr-event-participant-link [participant]="participant">
      {{ participant.name }}
    </pr-event-participant-link>
  </div>
</div>
