import { Injectable, type OnDestroy } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { type Permission } from '@principle-theorem/feature-flags';
import { type IIntegration } from '@principle-theorem/integrations';
import { AuthService, WorkspaceService } from '@principle-theorem/ng-auth';
import {
  Brand,
  FeeSchedule,
  ManagementStaffer,
  Organisation,
  Staffer,
  User,
  resolveToStaffer,
} from '@principle-theorem/principle-core';
import {
  type IBrand,
  type IFeeSchedule,
  type IOrganisation,
  type IPractice,
  type IRole,
  type IStaffer,
  type IStafferSettings,
  type IUser,
  type IUserMetadata,
} from '@principle-theorem/principle-core/interfaces';
import {
  LAST_ACTIVE_SYNC_INTERVAL,
  type WithRef,
  all$,
  filterUndefined,
  find$,
  firstResult$,
  getDoc$,
  isChanged$,
  isPathChanged$,
  patchDoc,
  runTransaction,
  shareReplayCold,
  shareReplayHot,
  snapshot,
  toTimestamp,
  multiFilter,
  undeletedQuery,
} from '@principle-theorem/shared';
import { type ITemporaryOrgToken } from '@principle-theorem/temporary-tokens';
import { type CollectionReference, where } from '@principle-theorem/shared';
import { keyBy, noop, sortBy } from 'lodash';
import * as moment from 'moment-timezone';
import {
  type Observable,
  Subject,
  combineLatest,
  interval,
  merge,
  of,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { LocalStorage } from './models/local-storage';
import { UserSessionsBloc } from './models/user/user-sessions-bloc';
import { type IRoutable } from './navigation/routable';
import { CurrentScopeFacade } from './store';
import { getTaxRateByRegion, TaxRate } from '@principle-theorem/accounting';
import { ManagementService } from './auth/management.service';

export type AppScopeBrand = Pick<WithRef<IBrand>, 'slug' | 'name'> & IRoutable;
export type AppScopePractice = Pick<WithRef<IPractice>, 'slug' | 'name'> &
  IRoutable;

export interface IAppScope {
  brand?: AppScopeBrand;
  practice?: AppScopePractice;
}

export interface IStateStorage<T> {
  value$: Observable<T | undefined>;
  update(data: T): Promise<void> | void;
}

@Injectable({
  providedIn: 'root',
})
export class OrganisationService implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _settingStorage: IStateStorage<IAppScope> =
    new LocalStorage<IAppScope>('appScope');
  private _settingStorage$: Observable<IAppScope>;
  private _brand$: Observable<WithRef<IBrand> | undefined>;
  private _practice$: Observable<WithRef<IPractice> | undefined>;

  brand$: Observable<WithRef<IBrand> | undefined>;
  practice$: Observable<WithRef<IPractice> | undefined>;
  practices$: Observable<WithRef<IPractice>[]>;

  userBrands$: Observable<WithRef<IBrand>[]>;
  userPractices$: Observable<WithRef<IPractice>[]>;

  organisation$: Observable<WithRef<IOrganisation> | undefined>;
  storagePath$: Observable<string | undefined>;
  userCol$: Observable<CollectionReference<IUser> | undefined>;
  user$: Observable<WithRef<IUser> | undefined>;
  users$: Observable<WithRef<IUser>[]>;
  userMap$: Observable<Record<string, WithRef<IUser>>>;
  userPermissions$: Observable<Permission[]>;
  staffer$: Observable<WithRef<IStaffer> | undefined>;
  stafferSettings$: Observable<Partial<IStafferSettings> | undefined>;
  staff$: Observable<WithRef<IStaffer>[]>;
  managementStaff$: Observable<WithRef<IStaffer>[]>;
  stafferMap$: Observable<Record<string, WithRef<IStaffer>>>;
  brandPractitioners$: Observable<WithRef<IStaffer>[]>;
  practicePractitioners$: Observable<WithRef<IStaffer>[]>;
  prescribers$: Observable<WithRef<IStaffer>[]>;
  hasMultiplePractices$: Observable<boolean>;
  taxRate$: Observable<TaxRate | undefined>;

  isSingleBrand$: Observable<boolean>;

  constructor(
    private _auth: AuthService,
    private _localStorage: StorageMap,
    private _workspace: WorkspaceService,
    private _currentScopeFacade: CurrentScopeFacade,
    private _management: ManagementService
  ) {
    this.organisation$ = combineLatest([
      this._auth.authUser$,
      this._workspace.value$,
    ]).pipe(
      switchMap(([user, workspace]) => {
        if (!user || !workspace || !workspace.uid) {
          return of(undefined);
        }
        return getDoc$(Organisation.col(), workspace.uid).pipe(
          catchError(() => of(undefined))
        );
      }),
      isChanged$(),
      shareReplayHot(this._onDestroy$)
    );

    this._settingStorage$ = this._settingStorage.value$.pipe(
      map((appScope: IAppScope | undefined) => appScope || {})
    );
    this._brand$ = this._loadBrand();
    this._practice$ = this._loadPractice();

    this.brand$ = this._brand$;
    this.practice$ = this._practice$;
    this.practices$ = this.brand$.pipe(
      switchMap((brand) => (brand ? Brand.practices$(brand) : of([]))),
      shareReplayCold()
    );

    this.userCol$ = this.organisation$.pipe(
      map((organisation) =>
        organisation ? Organisation.userCol(organisation) : undefined
      ),
      shareReplayCold()
    );

    this.user$ = this._auth.authUser$.pipe(
      switchMap((authUser) => {
        if (!authUser || !authUser.email) {
          return of(undefined);
        }
        return this.resolveUser$(authUser.email);
      }),
      shareReplayCold()
    );

    this.users$ = this.userCol$.pipe(
      switchMap((userCol) => (userCol ? all$(userCol) : of([]))),
      shareReplayHot(this._onDestroy$)
    );

    this.userMap$ = this.users$.pipe(
      map((users) => keyBy(users, 'ref.path')),
      shareReplayHot(this._onDestroy$)
    );

    this.userPermissions$ = this.user$.pipe(
      isPathChanged$('ref.path'),
      switchMap((user) => (user ? User.permissions$(user) : of([]))),
      shareReplayHot(this._onDestroy$)
    );

    this.staffer$ = this._toStaffer$(this.user$).pipe(
      distinctUntilChanged((stafferA, stafferB) => {
        if (!stafferA && !stafferB) {
          return true;
        }
        if (!stafferA || !stafferB) {
          return false;
        }
        return stafferA.ref.id === stafferB.ref.id;
      }),
      shareReplayHot(this._onDestroy$)
    );

    this.stafferSettings$ = this._toStaffer$(this.user$).pipe(
      map((staffer) => (staffer ? staffer.settings : undefined)),
      shareReplayHot(this._onDestroy$)
    );

    this.staff$ = this.brand$.pipe(
      switchMap((brand) => (brand ? Brand.staff$(brand) : of([]))),
      shareReplayHot(this._onDestroy$)
    );

    this.managementStaff$ = this.brand$.pipe(
      filterUndefined(),
      switchMap(() => ManagementStaffer.all$()),
      shareReplayHot(this._onDestroy$)
    );

    this.stafferMap$ = this.staff$.pipe(
      map((staff) => keyBy(staff, 'ref.path')),
      shareReplayHot(this._onDestroy$)
    );

    this.brandPractitioners$ = this.brand$.pipe(
      switchMap((brand) =>
        brand ? Staffer.practitionersByBrand$(brand) : of([])
      ),
      map((staff) => sortBy(staff, ['user.name'])),
      shareReplayHot(this._onDestroy$)
    );

    this.practicePractitioners$ = this.practice$.pipe(
      switchMap((practice) =>
        practice ? Staffer.practitionersByPractice$(practice) : of([])
      ),
      map((staff) => sortBy(staff, ['user.name'])),
      shareReplayHot(this._onDestroy$)
    );

    this.prescribers$ = this.practicePractitioners$.pipe(
      multiFilter((practitioners) => !!practitioners.prescriberNumber),
      shareReplayHot(this._onDestroy$)
    );

    this.storagePath$ = this.organisation$.pipe(
      map((organisation) => (organisation ? organisation.ref.path : undefined)),
      shareReplayCold()
    );

    this.userBrands$ = combineLatest([
      this.organisation$,
      this.user$,
      this._management.user$,
    ]).pipe(
      switchMap(([organisation, user, managementUser]) => {
        if (!organisation) {
          return of([]);
        }

        if (managementUser) {
          return Organisation.brands$(organisation);
        }

        if (user) {
          return User.brandsInOrganisation$(organisation, user);
        }

        return of([]);
      }),
      shareReplayCold()
    );

    this.userPractices$ = combineLatest([
      this.brand$,
      this.user$,
      this._management.user$,
    ]).pipe(
      switchMap(([brand, user, managementUser]) => {
        if (!brand) {
          return of([]);
        }

        if (managementUser) {
          return Brand.practices$(brand);
        }

        if (user) {
          return User.practicesInBrand$(brand, user);
        }

        return of([]);
      }),
      shareReplayCold()
    );

    this.isSingleBrand$ = this.organisation$.pipe(
      switchMap((org) => (org ? Organisation.brands$(org) : of([]))),
      map((brands) => brands.filter((brand) => !brand.deleted).length === 1),
      shareReplayCold()
    );

    this.hasMultiplePractices$ = this.practices$.pipe(
      map((practices) => practices.length > 1),
      shareReplayCold()
    );

    this.taxRate$ = this.organisation$.pipe(
      map(
        (organisation) =>
          organisation && getTaxRateByRegion(organisation.region)
      ),
      shareReplayCold()
    );

    this._handleUserMetadataSync();
    this._handleRecentSessionsSync();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
  }

  get roleCol$(): Observable<CollectionReference<IRole> | undefined> {
    return this.organisation$.pipe(
      map((organisation) =>
        organisation ? Organisation.roleCol(organisation) : undefined
      )
    );
  }

  get integrationCol$(): Observable<
    CollectionReference<IIntegration> | undefined
  > {
    return this.organisation$.pipe(
      map((organisation) =>
        organisation ? Organisation.integrationCol(organisation) : undefined
      )
    );
  }

  get temporaryTokensCol$(): Observable<
    CollectionReference<ITemporaryOrgToken> | undefined
  > {
    return this.organisation$.pipe(
      map((organisation) =>
        organisation ? Organisation.temporaryTokenCol(organisation) : undefined
      )
    );
  }

  get brandCol$(): Observable<CollectionReference<IBrand> | undefined> {
    return this.organisation$.pipe(
      map((organisation) =>
        organisation ? Organisation.brandCol(organisation) : undefined
      )
    );
  }

  get feeScheduleCol$(): Observable<
    CollectionReference<IFeeSchedule> | undefined
  > {
    return this.organisation$.pipe(
      map((organisation) =>
        organisation ? FeeSchedule.col(organisation) : undefined
      )
    );
  }

  resolveUser$(email: string): Observable<WithRef<IUser> | undefined> {
    return this._management.user$.pipe(
      switchMap((managementUser) => {
        if (managementUser) {
          return of(managementUser);
        }
        return this.userCol$.pipe(
          switchMap((userCol) =>
            userCol
              ? find$(userCol, where('email', '==', email.toLowerCase()))
              : of(undefined)
          ),
          map((user) => (user && user.isEnabled ? user : undefined))
        );
      })
    );
  }

  async selectBrand(brand: WithRef<IBrand>): Promise<void> {
    const updatedScopeState: Partial<IAppScope> = {
      brand: {
        uid: brand.ref.id,
        name: brand.name,
        slug: brand.slug,
      },
    };
    await this._patchCurrentScope(updatedScopeState);
    this._currentScopeFacade.setBrandFromResolver(brand);
  }

  async selectPractice(practice: WithRef<IPractice>): Promise<void> {
    const updatedScopeState: Partial<IAppScope> = {
      practice: {
        uid: practice.ref.id,
        name: practice.name,
        slug: practice.slug,
      },
    };
    await this._patchCurrentScope(updatedScopeState);
    this._currentScopeFacade.setPracticeFromResolver(practice);
  }

  async switchUser(email: string, password: string): Promise<void> {
    await this._auth.signIn(email, password);
  }

  userHasPermission$(permission: string): Observable<boolean> {
    return this.userPermissions$.pipe(
      map((permissions) => permissions.includes(permission))
    );
  }

  private _loadBrand(): Observable<WithRef<IBrand>> {
    return this._settingStorage$.pipe(
      switchMap((appScope: IAppScope) => {
        if (!appScope.brand) {
          return this.brandCol$.pipe(
            filterUndefined(),
            switchMap((brandCol) => firstResult$(brandCol))
          );
        }
        const brand: AppScopeBrand = appScope.brand;
        return this.brandCol$.pipe(
          filterUndefined(),
          switchMap((brandCol) => getDoc$(brandCol, brand.uid)),
          catchError(() => of(undefined)),
          filterUndefined()
        );
      }),
      filterUndefined(),
      tap((brand) => this._currentScopeFacade.setBrandFromResolver(brand)),
      shareReplayHot(this._onDestroy$)
    );
  }

  private _loadPractice(): Observable<WithRef<IPractice>> {
    return combineLatest([this._brand$, this._settingStorage$]).pipe(
      switchMap(([brand, appScope]) => {
        if (!brand) {
          return of(undefined);
        }
        const practiceCol = Brand.practiceCol(brand);
        return appScope.practice
          ? getDoc$<IPractice>(practiceCol, appScope.practice.uid).pipe(
              catchError(() => of(undefined))
            )
          : firstResult$(practiceCol);
      }),
      filterUndefined(),
      tap((practice) =>
        this._currentScopeFacade.setPracticeFromResolver(practice)
      ),
      shareReplayHot(this._onDestroy$)
    );
  }

  private async _patchCurrentScope(
    partialScopeUpdate: Partial<IAppScope>
  ): Promise<void> {
    const currentScope: IAppScope | undefined = await snapshot(
      this._settingStorage.value$
    );
    const updatedScope: IAppScope = { ...currentScope, ...partialScopeUpdate };
    await this._settingStorage.update(updatedScope);
  }

  private _toStaffer$(
    user$: Observable<WithRef<IUser> | undefined>
  ): Observable<WithRef<IStaffer> | undefined> {
    return this._management.user$.pipe(
      switchMap((managementUser) => {
        if (managementUser) {
          return find$(
            undeletedQuery(ManagementStaffer.col()),
            where('user.ref', '==', managementUser.ref)
          );
        }
        return combineLatest([this.brand$, user$]).pipe(
          switchMap(([brand, user]) => {
            if (!brand || !user) {
              return of(undefined);
            }
            return resolveToStaffer(brand)(of(user));
          })
        );
      })
    );
  }

  private async _syncMetadata(
    user: WithRef<IUser>,
    metadata: IUserMetadata
  ): Promise<void> {
    await runTransaction((transaction) =>
      patchDoc(user.ref, metadata, transaction)
    );
  }

  private _handleUserMetadataSync(): void {
    combineLatest([
      this.user$.pipe(
        filterUndefined(),
        filter((user) => !user.firstSignedInAt)
      ),
      this._auth.authUser$.pipe(filterUndefined()),
    ])
      .pipe(
        filter(([user, authUser]) => user.email === authUser.email),
        switchMap(([user, authUser]) => {
          if (!authUser || !authUser.metadata.creationTime) {
            return of(undefined);
          }
          return this._syncMetadata(user, {
            firstSignedInAt: toTimestamp(
              moment(authUser.metadata.creationTime)
            ),
          });
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => noop());

    this.user$
      .pipe(
        isPathChanged$('ref.path'),
        switchMap((user) => {
          if (!user) {
            return of(undefined);
          }
          return merge(of(undefined), interval(LAST_ACTIVE_SYNC_INTERVAL)).pipe(
            switchMap(() =>
              this._syncMetadata(user, {
                lastActiveAt: toTimestamp(),
              })
            )
          );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => noop());
  }

  private _handleRecentSessionsSync(): void {
    this.user$
      .pipe(
        filterUndefined(),
        withLatestFrom(this._workspace.workspace$.pipe(filterUndefined())),
        switchMap(([user, workspace]) => {
          return new UserSessionsBloc(this._localStorage).addUser(
            user,
            workspace
          );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);
  }
}
