import { isObject, isReffable, type WithRef } from '@principle-theorem/shared';
import { type ITreatmentTemplate } from './treatment-template';

export interface ITreatmentStepFromTemplate {
  name: string;
  template: WithRef<ITreatmentTemplate>;
}

export function isTreatmentStepFromTemplate(
  item: unknown
): item is ITreatmentStepFromTemplate {
  return isObject(item) && 'template' in item && isReffable(item.template);
}
