import {
  IManualExtendedData,
  isManualExtendedData,
} from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { GeneralProviderActionsService } from '../../general-provider-actions.service';
import {
  ITransactionAction,
  ITransactionActionsData,
} from '../../transaction-action';
import { TransactionHelpers } from '../../transaction-helpers';

export class AmendManualTransaction
  implements ITransactionAction<IManualExtendedData>
{
  icon = 'edit';
  label = 'Amend';
  inProgress$ = new BehaviorSubject<boolean>(false);
  typeGuardFn = isManualExtendedData;

  constructor(private _actions: GeneralProviderActionsService) {}

  canDo$(
    data: ITransactionActionsData<IManualExtendedData>
  ): Observable<boolean> {
    return TransactionHelpers.canAmend$(data);
  }

  async do(data: ITransactionActionsData<IManualExtendedData>): Promise<void> {
    this.inProgress$.next(true);
    await this._actions.amend(
      data.invoice,
      data.latestTransaction,
      'Amend Transaction',
      true
    );
    this.inProgress$.next(false);
  }
}
