import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'pr-hub-spot-tracking-code-help',
    imports: [CommonModule],
    templateUrl: './hub-spot-tracking-code-help.component.html',
    styleUrl: './hub-spot-tracking-code-help.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HubSpotTrackingCodeHelpComponent {}
