import {
  IPatient,
  ITwainDevice,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { IBaseCommand, IBridgeIntegration } from '../../send-command-to-device';

export const TWAIN_INTEGRATION: IBridgeIntegration = {
  label: 'TWAIN',
  description: 'General scanner integration using the TWAIN specification',
};

export type TwainSyncDevicesCommand = IBaseCommand<
  TwainToDeviceCommand.SyncDevices,
  Record<string, never>
>;

export type TwainTriggerScanCommand = IBaseCommand<
  TwainToDeviceCommand.TriggerScan,
  { patient: WithRef<IPatient>; device: ITwainDevice }
>;

export type TwainTriggerScanCommandLegacy = IBaseCommand<
  TwainToDeviceCommand.TriggerScan,
  { patient: WithRef<IPatient> }
>;

export enum TwainToDeviceCommand {
  TriggerScan = 'twain.triggerScan',
  SyncDevices = 'twain.syncDevices',
}
