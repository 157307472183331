import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  TreatmentPlanFacade,
  TreatmentStepsFacade,
} from '@principle-theorem/ng-clinical-charting/store';
import { StafferSettingsStoreService } from '@principle-theorem/ng-principle-shared';
import {
  type IFilterOption,
  type IMatSelectGroupOptions,
  type IMatSelectOption,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  type IAppointment,
  type IChartedMultiStepTreatmentStep,
  type IPatient,
  type IStafferSettings,
  type ITreatmentPlan,
  type ITreatmentStep,
  PlanStepPairStatus,
} from '@principle-theorem/principle-core/interfaces';
import { getAppointmentStepStatus } from '@principle-theorem/principle-core';
import {
  getDoc,
  getEnumValues,
  type IGroup,
  isRefChanged$,
  isSameRef,
  isTimestamp,
  multiConcatMap,
  multiSwitchMap,
  reduce2DArray,
  sortTimestampAsc,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { last } from 'lodash';

export interface ITreatmentPlanStepPair {
  treatmentPlan: WithRef<ITreatmentPlan>;
  treatmentStep: WithRef<ITreatmentStep>;
  appointment: WithRef<IAppointment> | undefined;
  status: PlanStepPairStatus;
}

export interface ITreatmentPlansConsolidatedState {
  loading: boolean;
  patient: WithRef<IPatient> | undefined;
  consolidatedPlan: ITreatmentPlanStepPair[];
  filteredConsolidatedPlan: IMatSelectGroupOptions<
    WithRef<ITreatmentPlan>,
    ITreatmentPlanStepPair
  >;
  isFiltered: boolean;
  activeStatuses: PlanStepPairStatus[];
  statusFilters: IFilterOption<PlanStepPairStatus, ITreatmentPlanStepPair>[];
}

function initFilteredConsolidatedPlan(): IMatSelectGroupOptions<
  WithRef<ITreatmentPlan>,
  ITreatmentPlanStepPair
> {
  return {
    groups: [],
    trackByGroup:
      TrackByFunctions.ref<
        IGroup<
          IMatSelectOption<ITreatmentPlanStepPair>,
          WithRef<ITreatmentPlan>
        >
      >('group.ref'),
    trackByOption:
      TrackByFunctions.ref<IMatSelectOption<ITreatmentPlanStepPair>>(
        'treatmentStep.ref'
      ),
  };
}

const initialState: ITreatmentPlansConsolidatedState = {
  loading: false,
  patient: undefined,
  consolidatedPlan: [],
  filteredConsolidatedPlan: initFilteredConsolidatedPlan(),
  isFiltered: false,
  activeStatuses: [],
  statusFilters: getEnumValues(PlanStepPairStatus).map((status) => ({
    id: status,
    label: status,
    filter: (step) => step.status === status,
  })),
};

@Injectable()
export class TreatmentPlansConsolidatedStore extends ComponentStore<ITreatmentPlansConsolidatedState> {
  private _treatmentPlanFacade = inject(TreatmentPlanFacade);
  private _treatmentStepsFacade = inject(TreatmentStepsFacade);
  private _stafferSettings = inject(StafferSettingsStoreService);
  readonly loading$ = this.select((store) => store.loading);
  readonly patient$ = this.select((store) => store.patient);
  readonly isFiltered$ = this.select((store) => store.isFiltered);
  readonly activeStatuses$ = this.select((store) => store.activeStatuses);
  readonly statusFilters$ = this.select((store) => store.statusFilters);
  readonly consolidatedPlan$ = this.select((store) => store.consolidatedPlan);
  readonly filteredConsolidatedPlan$ = this.select(
    (store) => store.filteredConsolidatedPlan
  );

  readonly initialiseStore = this.effect(
    (patient$: Observable<WithRef<IPatient>>) => {
      return patient$.pipe(
        isRefChanged$(),
        tap((patient) => {
          this.patchState({
            patient,
            loading: true,
          });
          this.loadConsolidatedPlan(this._treatmentPlanFacade.treatmentPlans$);
        })
      );
    }
  );

  readonly loadConsolidatedPlan = this.effect(
    (plans$: Observable<WithRef<ITreatmentPlan>[]>) => {
      return plans$.pipe(
        multiSwitchMap((plan) =>
          this._treatmentPlanFacade
            .getTreatmentSteps$(plan.ref)
            .pipe(
              multiConcatMap(async (step) =>
                this._buildConsolidatedPlan(plan, step)
              )
            )
        ),
        reduce2DArray(),
        map((consolidatedPlan) =>
          this._sortByAppointmentDateOrPlan(consolidatedPlan)
        ),
        tap((consolidatedPlan) => {
          this.patchState({
            consolidatedPlan,
            loading: false,
          });
        })
      );
    }
  );

  readonly loadActiveStatuses = this.effect(
    (settings$: Observable<IStafferSettings['treatmentPlan']>) => {
      return settings$.pipe(
        tap((settings) => {
          const userSettings = settings.filters;
          const hasUserSettings = userSettings?.length;
          const statuses = getEnumValues(PlanStepPairStatus);
          this.patchState({
            activeStatuses: hasUserSettings ? userSettings : statuses,
            isFiltered: hasUserSettings !== statuses.length,
          });
        })
      );
    }
  );

  readonly updateFilteredSteps = this.effect(
    (consolidatedPlan$: Observable<ITreatmentPlanStepPair[]>) => {
      return combineLatest([consolidatedPlan$, this.activeStatuses$]).pipe(
        map(([consolidatedPlan, activeStatuses]) =>
          consolidatedPlan.filter(({ status }) =>
            activeStatuses.includes(status)
          )
        ),
        map((consolidatedPlan) => {
          return consolidatedPlan.reduce(
            (
              groupOptions: IMatSelectGroupOptions<
                WithRef<ITreatmentPlan>,
                ITreatmentPlanStepPair
              >,
              pair
            ) => {
              const isSameAsLastPlan = isSameRef(
                pair.treatmentPlan,
                last(groupOptions.groups)?.group
              );

              const item = {
                label: pair.treatmentStep.name,
                value: pair,
              };
              if (isSameAsLastPlan) {
                groupOptions.groups[groupOptions.groups.length - 1].items.push(
                  item
                );
                return groupOptions;
              }

              return {
                ...groupOptions,
                groups: [
                  ...groupOptions.groups,
                  {
                    group: pair.treatmentPlan,
                    items: [item],
                  },
                ],
              };
            },
            initFilteredConsolidatedPlan()
          );
        }),
        tap((filteredConsolidatedPlan) =>
          this.patchState({ filteredConsolidatedPlan })
        )
      );
    }
  );

  constructor() {
    super(initialState);
    this.loadActiveStatuses(this._stafferSettings.treatmentPlan$);
    this.updateFilteredSteps(this.consolidatedPlan$);
  }

  updateStatuses(filters: PlanStepPairStatus[]): void {
    this._stafferSettings.updateStafferSettings({
      treatmentPlan: {
        filters,
      },
    });
  }

  async addStep(plan: WithRef<ITreatmentPlan>): Promise<void> {
    await this._treatmentPlanFacade.addStep(plan);
  }

  deleteStep(
    plan: WithRef<ITreatmentPlan>,
    step: WithRef<ITreatmentStep>
  ): void {
    this._treatmentPlanFacade.removeStep(plan, step);
  }

  updateStep(
    step: WithRef<ITreatmentStep>,
    change: Partial<IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>>
  ): void {
    this._treatmentStepsFacade.updateTreatmentStep(step.ref.id, change);
  }

  private async _buildConsolidatedPlan(
    treatmentPlan: WithRef<ITreatmentPlan>,
    treatmentStep: WithRef<ITreatmentStep>
  ): Promise<ITreatmentPlanStepPair> {
    const appointment = treatmentStep.appointment
      ? await getDoc(treatmentStep.appointment)
      : undefined;
    return {
      treatmentPlan,
      treatmentStep,
      appointment,
      status: getAppointmentStepStatus(appointment),
    };
  }

  private _sortByAppointmentDateOrPlan(
    consolidatedPlan: ITreatmentPlanStepPair[]
  ): ITreatmentPlanStepPair[] {
    return consolidatedPlan.sort((a, b) => {
      const aValue =
        a.appointment?.event?.from ??
        a.treatmentPlan.steps.indexOf(a.treatmentStep.ref);
      const bValue =
        b.appointment?.event?.from ??
        b.treatmentPlan.steps.indexOf(b.treatmentStep.ref);

      if (isTimestamp(aValue) && isTimestamp(bValue)) {
        return sortTimestampAsc(aValue, bValue);
      }

      return aValue <= bValue ? 1 : -1;
    });
  }
}
