import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  type ICalendarEvent,
  isCalendarEvent,
} from '@principle-theorem/principle-core/interfaces';
import {
  isWithRef,
  saveDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, from, noop, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'pr-eventable-menu',
    templateUrl: './eventable-menu.component.html',
    styleUrls: ['./eventable-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EventableMenuComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  event$: BehaviorSubject<WithRef<ICalendarEvent> | undefined> =
    new BehaviorSubject<WithRef<ICalendarEvent> | undefined>(undefined);
  canBeCancelled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(private _snackBar: MatSnackBar) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @Input()
  set event(event: WithRef<ICalendarEvent>) {
    if (!event || !isWithRef(event) || !isCalendarEvent(event)) {
      return;
    }
    this.event$.next(event);
    this.canBeCancelled$.next(!event.locked);
  }

  async cancelEvent(): Promise<void> {
    const event = await snapshot(this.event$);
    if (!event) {
      return;
    }

    event.deleted = true;
    await saveDoc(event);

    this._snackBar
      .open('Event Cancelled', 'UNDO')
      .onAction()
      .pipe(
        tap(() => {
          event.deleted = false;
          return from(saveDoc(event));
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);
  }
}
