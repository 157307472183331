import { Injectable, inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  serialise,
  unserialise$,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import {
  loadBrandFromResolver,
  loadPracticeFromResolver,
} from './actions/current-scope.actions';
import { type IPrincipleState } from './reducers/principle-core.reducers';
import {
  getBrandSlug,
  getCurrentBrand,
  getCurrentPractice,
  getPracticeSlug,
} from './selectors/current-scope.selectors';

@Injectable()
export class CurrentScopeFacade {
  private _store = inject(Store<IPrincipleState>);
  brandSlug$: Observable<string | undefined>;
  practiceSlug$: Observable<string | undefined>;
  currentBrand$: Observable<WithRef<IBrand> | undefined>;
  currentPractice$: Observable<WithRef<IPractice> | undefined>;

  constructor() {
    this.brandSlug$ = this._store.pipe(select(getBrandSlug));
    this.practiceSlug$ = this._store.pipe(select(getPracticeSlug));
    this.currentBrand$ = this._store.pipe(
      select(getCurrentBrand),
      unserialise$()
    );
    this.currentPractice$ = this._store.pipe(
      select(getCurrentPractice),
      unserialise$()
    );
  }

  setBrandFromResolver(brand: WithRef<IBrand>): void {
    this._dispatch(
      loadBrandFromResolver({
        brand: serialise(brand),
      })
    );
  }

  setPracticeFromResolver(practice: WithRef<IPractice>): void {
    this._dispatch(
      loadPracticeFromResolver({
        practice: serialise(practice),
      })
    );
  }

  private _dispatch(action: Action): void {
    this._store.dispatch(action);
  }
}
