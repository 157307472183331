import { IMenuButton } from '@principle-theorem/editor';
import { MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../../menu-bar/basic-menu-button/basic-menu-button.component';
import { AIMenuButtonFilters } from '../ai-menu-button-filters';

export function createFixSpellingAndGrammarButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'submenu-detail',
      buttonText: 'Fix Spelling & Grammar',
      tooltip: `Fixes spelling & grammar`,
      icon: 'spellcheck',
      command: (editor) =>
        editor.chain().focus().aiFixSpellingAndGrammar().focus().run(),
      isDisabledFn: (editor) =>
        AIMenuButtonFilters.disableWithoutTextSelection(editor),
    },
  });
}
