import { randNumber } from '@ngneat/falso';
import {
  GapAction,
  GapStatus,
} from '@principle-theorem/principle-core/interfaces';
import { IGapEventFact } from '@principle-theorem/reporting/interfaces';
import {
  BaseMock,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
} from '@principle-theorem/shared';
import { BigQueryGapMock } from './common.mock';
import { BrandDimensionMock } from '../dimensions/brand-dimension.mock';
import { SerialisedDocumentReferenceMock } from '../dimensions/common.mock';
import { PracticeDimensionMock } from '../dimensions/practice-dimension.mock';
import { StafferDimensionMock } from '../dimensions/staffer-dimension.mock';

export class GapEventFactMock extends BaseMock implements IGapEventFact {
  brandRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practiceRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practitionerRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  brand = MockGenerator.generate(BrandDimensionMock);
  practice = MockGenerator.generate(PracticeDimensionMock);
  practitioner = MockGenerator.generate(StafferDimensionMock);
  gap = MockGenerator.generate(BigQueryGapMock);
  timestamp = toSerialisedTimestamp(MockTimestamp());
  event = {
    action: GapAction.Filling,
    statusBefore: GapStatus.Available,
    statusAfter: GapStatus.Filling,
  };
  duration = randNumber({
    min: 15,
    max: 180,
  });
}
