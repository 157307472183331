import {
  isEnumValue,
  isObject,
  type ArchivedDocument,
  type DocumentReference,
  type IFirestoreModel,
} from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { type IPractice } from '../practice/practice';

export enum TransactionType {
  Outgoing = 'outgoing',
  Incoming = 'incoming',
  Claim = 'claim',
}

export enum TransactionStatus {
  Pending = 'pending',
  Complete = 'complete',
  Failed = 'failed',
}

export enum TransactionCollection {
  TransactionHistory = 'transactionHistory',
}

export enum TransactionAction {
  Add = 'add',
  Approve = 'approve',
  Cancel = 'cancel',
  Update = 'update',
  Refund = 'refund',
  Delete = 'delete',
}

export const TRANSACTION_ACTION_MAP: { [key in TransactionAction]: string } = {
  [TransactionAction.Add]: 'Adding',
  [TransactionAction.Approve]: 'Approving',
  [TransactionAction.Cancel]: 'Cancelling',
  [TransactionAction.Update]: 'Updating',
  [TransactionAction.Refund]: 'Refunding',
  [TransactionAction.Delete]: 'Deleting',
};

export interface ITransaction<T = unknown> extends IFirestoreModel {
  uid: string;
  provider: TransactionProvider;
  reference: string; // Associate with external systems (and internally)
  type: TransactionType;
  status: TransactionStatus;
  from: string;
  to: string;
  amount: number;
  description?: string;
  practiceRef: DocumentReference<IPractice>;
  extendedData?: T;
  amendmentOf?: DocumentReference<ArchivedDocument<ITransaction>>;
}

export function isTransaction(data: unknown): data is ITransaction {
  return (
    isObject(data) &&
    isString(data.uid) &&
    isString(data.provider) &&
    isString(data.reference) &&
    isString(data.type) &&
    isEnumValue(TransactionStatus, data.status) &&
    isString(data.from) &&
    isString(data.to) &&
    isNumber(data.amount)
  );
}

export enum TransactionProvider {
  Stripe = 'stripe',
  Cash = 'cash',
  Manual = 'manual',
  Discount = 'discount',
  AccountCredit = 'accountCredit',
  AccountCreditTransfer = 'accountCreditTransfer',
  PaymentPlan = 'paymentPlan',

  TyroEftpos = 'tyroEftpos',
  TyroHealthPoint = 'tyroHealthPoint',
  TyroEasyClaimBulkBill = 'tyroEasyClaimBulkBill',
  TyroEasyClaimPartPaid = 'tyroEasyClaimPartPaid',
  TyroEasyClaimFullyPaid = 'tyroEasyClaimFullyPaid',

  MedipassHicaps = 'medipassHicaps',
  MedipassMedicare = 'medipassMedicare',
  MedipassDVA = 'medipassDVA',
  MedipassPatientFunded = 'medipassPatientFunded',
  MedipassVirtualTerminal = 'medipassVirtualTerminal',
  MedipassGapPayment = 'medipassGapPayment',

  HicapsConnectEftpos = 'hicapsConnectEftpos',
  HicapsConnectHealthFund = 'hicapsConnectHealthFund',
  HicapsConnectMedicare = 'hicapsConnectMedicare',

  SmartpayCard = 'smartpayCard',
  SmartpayQR = 'smartpayQR',
}

export const CLAIM_PROVIDERS = [
  TransactionProvider.TyroHealthPoint,
  TransactionProvider.TyroEasyClaimBulkBill,
  TransactionProvider.TyroEasyClaimPartPaid,
  TransactionProvider.TyroEasyClaimFullyPaid,
  TransactionProvider.MedipassHicaps,
  TransactionProvider.MedipassMedicare,
  TransactionProvider.MedipassDVA,
  TransactionProvider.HicapsConnectHealthFund,
  TransactionProvider.HicapsConnectMedicare,
];

/**
 * Transactions from these providers do not count towards an
 * invoice's refundable amount
 */
export const NON_REFUNDABLE_PROVIDERS = [
  TransactionProvider.HicapsConnectHealthFund,
  TransactionProvider.HicapsConnectMedicare,
  TransactionProvider.AccountCreditTransfer,
];
