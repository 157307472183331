import { initVersionedSchema } from '@principle-theorem/editor';
import {
  AnyCustomFormElement,
  CustomFormContainerElement,
  CustomFormContentElement,
  CustomFormDropdownElement,
  CustomFormElementCategory,
  CustomFormElementType,
  CustomFormFieldElement,
  CustomFormInputFieldElement,
  CustomFormSectionElement,
  ICustomFormContentOptions,
  ICustomFormDropdownOptions,
  ICustomFormFieldOptions,
  ICustomFormSectionOptions,
} from '@principle-theorem/principle-core/interfaces';
import { clamp, min } from 'lodash';
import { v4 as uuid } from 'uuid';

export class CustomFormElementBlueprint {
  static create(elementType: CustomFormElementType): AnyCustomFormElement {
    switch (elementType) {
      case CustomFormElementType.Section:
        return this.createSection();
      case CustomFormElementType.Content:
        return this.createContent({
          content: initVersionedSchema('Edit your content here'),
        });
      case CustomFormElementType.Signature:
        return this.createSignature();
      case CustomFormElementType.Text:
        return this.createText();
      case CustomFormElementType.Textarea:
        return this.createTextarea();
      case CustomFormElementType.Number:
        return this.createNumber();
      case CustomFormElementType.Checkbox:
        return this.createCheckbox();
      case CustomFormElementType.Date:
        return this.createDate();
      case CustomFormElementType.Dropdown:
        return this.createDropdown();
      default:
        return this.createContainer();
    }
  }

  static createContainer(
    direction: 'row' | 'column' = 'column',
    children: AnyCustomFormElement[] = [],
    dropListCategory?: CustomFormElementCategory
  ): CustomFormContainerElement {
    return {
      uid: uuid(),
      type: CustomFormElementType.Container,
      options: {
        direction,
      },
      children,
      dropListCategory,
    };
  }

  static createSection(
    options: Partial<ICustomFormSectionOptions> = {}
  ): CustomFormSectionElement {
    const numberOfColumns = options.numberOfColumns ?? 2;
    const columns: CustomFormContainerElement[] = Array.from({
      length: numberOfColumns,
    }).map(() =>
      this.createContainer('column', [], CustomFormElementCategory.Field)
    );

    return {
      uid: uuid(),
      type: CustomFormElementType.Section,
      options: {
        numberOfColumns,
        ...options,
      },
      children: columns,
    };
  }

  static appendElementToSection(
    section: CustomFormSectionElement,
    field: CustomFormInputFieldElement,
    columnIndex: number = -1
  ): void {
    const useColumnIndex =
      columnIndex < 0 ? this.getLeastFullColumnIndex(section) : columnIndex;
    const safeColumnIndex = clamp(
      useColumnIndex,
      0,
      section.children.length - 1
    );
    const column = section.children[safeColumnIndex];
    if (!column) {
      return;
    }
    column.children.push(field);
  }

  static getLeastFullColumnIndex(section: CustomFormSectionElement): number {
    const itemsPerColumn = section.children.map(
      (column) => column.children.length
    );
    const minValue = min(itemsPerColumn);
    const columnIndex =
      minValue !== undefined ? itemsPerColumn.indexOf(minValue) : 0;
    return columnIndex;
  }

  static createContent(
    options: Partial<ICustomFormContentOptions> = {}
  ): CustomFormContentElement {
    return {
      uid: uuid(),
      type: CustomFormElementType.Content,
      options: {
        content: initVersionedSchema(),
        editableOnIssue: false,
        ...options,
      },
      children: [],
    };
  }

  static createText(
    options: Partial<ICustomFormFieldOptions> = {}
  ): CustomFormFieldElement {
    const uid = uuid();
    return {
      uid,
      type: CustomFormElementType.Text,
      options: {
        dataKey: uid,
        label: 'Text',
        required: false,
        ...options,
      },
      children: [],
    };
  }

  static createTextarea(
    options: Partial<ICustomFormFieldOptions> = {}
  ): CustomFormFieldElement {
    const uid = uuid();
    return {
      uid,
      type: CustomFormElementType.Textarea,
      options: {
        dataKey: uid,
        label: 'Textarea',
        required: false,
        ...options,
      },
      children: [],
    };
  }

  static createNumber(
    options: Partial<ICustomFormFieldOptions> = {}
  ): CustomFormFieldElement {
    const uid = uuid();
    return {
      uid,
      type: CustomFormElementType.Number,
      options: {
        dataKey: uid,
        label: 'Number',
        required: false,
        ...options,
      },
      children: [],
    };
  }

  static createCheckbox(
    options: Partial<ICustomFormFieldOptions> = {}
  ): CustomFormFieldElement {
    const uid = uuid();
    return {
      uid,
      type: CustomFormElementType.Checkbox,
      options: {
        dataKey: uid,
        label: 'Checkbox',
        required: false,
        ...options,
      },
      children: [],
    };
  }

  static createDate(
    options: Partial<ICustomFormFieldOptions> = {}
  ): CustomFormFieldElement {
    const uid = uuid();
    return {
      uid,
      type: CustomFormElementType.Date,
      options: {
        dataKey: uid,
        label: 'Date',
        required: false,
        ...options,
      },
      children: [],
    };
  }

  static createDropdown(
    options: Partial<ICustomFormDropdownOptions> = {}
  ): CustomFormDropdownElement {
    const uid = uuid();
    return {
      uid,
      type: CustomFormElementType.Dropdown,
      options: {
        dataKey: uid,
        label: 'Dropdown',
        required: false,
        options: ['Option 1', 'Option 2', 'Option 3'],
        ...options,
      },
      children: [],
    };
  }

  static createSignature(
    options: Partial<ICustomFormFieldOptions> = {}
  ): CustomFormFieldElement {
    const uid = uuid();
    return {
      uid,
      type: CustomFormElementType.Signature,
      options: {
        dataKey: uid,
        label: 'Signature',
        required: true,
        ...options,
      },
      children: [],
    };
  }
}
