<h2 class="text-xl font-bold">Migrations Runner</h2>
<p class="mat-body-2">Run migrations on the database that rely on Angular.</p>

<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
  <mat-form-field *ngIf="organisations$ | async as organisations">
    <mat-label>Organisations</mat-label>
    <mat-select [formControl]="organisationsControl" multiple>
      <mat-option
        *ngFor="let organisation of organisations; trackBy: trackByOrganisation"
        [value]="organisation"
        >{{ organisation.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <button mat-stroked-button (click)="selectAll()">Select All</button>
</div>

<button
  mat-flat-button
  color="primary"
  [disabled]="isMigrating$ | async"
  (click)="migrate()"
>
  Migrate
</button>

<ng-container *ngIf="log$ | async as log">
  <div class="log-area" *ngIf="log.length">
    <div *ngFor="let item of log; trackBy: trackByLog">
      &#91;{{ item.time }}&#93; {{ item.message }}
    </div>
  </div>
</ng-container>
