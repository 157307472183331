export interface IFollowUpDatePreset {
  label: string;
  amount: number;
  unit: moment.unitOfTime.Base;
}

export const FOLLOW_UP_DATE_PRESETS: IFollowUpDatePreset[] = [
  { label: 'Tomorrow', amount: 1, unit: 'day' },
  { label: '3 Days', amount: 3, unit: 'days' },
  { label: '1 Week', amount: 1, unit: 'week' },
  { label: '2 Weeks', amount: 2, unit: 'weeks' },
  { label: '1 Month', amount: 1, unit: 'month' },
  { label: '3 Months', amount: 3, unit: 'months' },
  { label: '6 Months', amount: 6, unit: 'months' },
];
