<div fxLayout="column">
  <pr-sidebar-practice-selector
    [expanded]="expanded"
    (practiceSelected)="loadPractice($event)"
  />

  @if (hasMigrationPermissions$ | async) {
    <ng-container *ngIf="migrationRoutes$ | async as migration">
      <pr-sidebar-group
        [label]="migration.label"
        [links]="migration.routes"
        [expanded]="expanded"
      />
    </ng-container>
  }

  <ng-container *ngIf="schedulingRoutes$ | async as scheduling">
    <pr-sidebar-group
      [label]="scheduling.label"
      [links]="scheduling.routes"
      [expanded]="expanded"
    />
  </ng-container>

  <ng-container *ngIf="operationRoutes$ | async as operation">
    <pr-sidebar-group
      [label]="operation.label"
      [links]="operation.routes"
      [expanded]="expanded"
    />
  </ng-container>

  @if (hasReportingPermissions$ | async) {
    <ng-container *ngIf="reportingRoutes$ | async as reporting">
      <pr-sidebar-group
        [label]="reporting.label"
        [links]="reporting.routes"
        [expanded]="expanded"
      />
    </ng-container>
  }

  <ng-container
    *ngFor="let management of managementRoutes$ | async; trackBy: trackByRoute"
  >
    <pr-sidebar-group
      *ptFeatureEnabled="management.feature?.label"
      [label]="management.label"
      [links]="management.children"
      [expanded]="expanded"
    />
  </ng-container>
</div>
