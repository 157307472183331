import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { type RawInlineNodes } from '@principle-theorem/editor';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  type IPractice,
  type IRecurringTaskConfiguration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  saveDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type IRecurringTaskFormData } from '../recurring-task-configuration-form/recurring-task-configuration-form';
import { RecurringTaskConfigurationFormComponent } from '../recurring-task-configuration-form/recurring-task-configuration-form.component';
import { TaskManager } from '../../task-manager';

export interface IRecurringTaskFormDialogData {
  title: RawInlineNodes;
  submitLabel: string;
  configuration?: WithRef<IRecurringTaskConfiguration>;
}

@Component({
    selector: 'pr-recurring-task-form-dialog',
    templateUrl: './recurring-task-form-dialog.component.html',
    styleUrls: ['./recurring-task-form-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RecurringTaskFormDialogComponent {
  @ViewChild(RecurringTaskConfigurationFormComponent, { static: true })
  form: RecurringTaskConfigurationFormComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IRecurringTaskFormDialogData,
    private _dialogRef: MatDialogRef<RecurringTaskFormDialogComponent>,
    private _organisation: OrganisationService,
    private _snackBar: MatSnackBar
  ) {}

  async submit(): Promise<void> {
    if (this.form.configurationFormGroup.invalid) {
      return;
    }
    if (this.data.configuration) {
      await this._update(
        this.data.configuration,
        this.form.configurationFormGroup.getRawValue()
      );
    } else {
      await this._create(this.form.configurationFormGroup.getRawValue());
    }
    this._dialogRef.close(this.form.configurationFormGroup);
  }

  private async _update(
    configuration: WithRef<IRecurringTaskConfiguration>,
    data: IRecurringTaskFormData
  ): Promise<void> {
    const taskManager = await this._taskManager();
    await saveDoc({
      ...configuration,
      ...taskManager.convertConfigurationFormToConfiguration(data),
    });
    this._snackBar.open('Updated configuration');
  }

  private async _create(data: IRecurringTaskFormData): Promise<void> {
    const taskManager: TaskManager = await this._taskManager();
    const practice: WithRef<IPractice> = await snapshot(
      this._organisation.practice$.pipe(filterUndefined())
    );
    await taskManager.addConfiguration(practice, data);
    this._snackBar.open('Created Recurring Task');
    this._dialogRef.close();
  }

  private async _taskManager(): Promise<TaskManager> {
    const staffer: WithRef<IStaffer> = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    return new TaskManager(staffer);
  }
}
