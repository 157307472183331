import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  type OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CurrentScopeFacade,
  GlobalStoreService,
} from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { Roster } from '@principle-theorem/principle-core';
import { StafferPermissions } from '@principle-theorem/principle-core/features';
import {
  type IStaffer,
  type ITimelineDisplayOptions,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isRefChanged$,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { type Observable, ReplaySubject, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {
  EditStafferRosterDayDialogComponent,
  type IEditStafferRosterDayData,
} from '../eventable-header/edit-staffer-roster-day-dialog/edit-staffer-roster-day-dialog.component';
import { InteractiveTimelineDisplayCalculator } from '../interactive-timeline/interactive-timeline-display-calculator';

@Component({
    selector: 'pr-interactive-timeline-header',
    templateUrl: './interactive-timeline-header.component.html',
    styleUrls: ['./interactive-timeline-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InteractiveTimelineHeaderComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _options$ = new ReplaySubject<ITimelineDisplayOptions>(1);
  group$ = new ReplaySubject<WithRef<IStaffer>>(1);
  day$ = new ReplaySubject<Moment>(1);
  url$: Observable<string | undefined>;
  name$: Observable<string>;
  trackSizeInPixels$: Observable<number>;
  @HostBinding('class.horizontal') isHorizontal = true;
  @HostBinding('class.vertical') isVertical = false;
  rosterManagePermission = [StafferPermissions.RosterManage];

  @Input()
  set day(day: Moment) {
    if (day) {
      this.day$.next(day);
    }
  }

  @Input()
  set group(group: WithRef<IStaffer>) {
    if (group) {
      this.group$.next(group);
    }
  }

  @Input()
  set options(options: ITimelineDisplayOptions) {
    if (options) {
      this._options$.next(options);
    }
  }

  constructor(
    private _global: GlobalStoreService,
    private _dialog: MatDialog,
    private _currentScope: CurrentScopeFacade
  ) {
    this.url$ = this.group$.pipe(
      isRefChanged$(),
      switchMap((staffer) => this._global.getStafferImage$(staffer))
    );

    this.name$ = this.group$.pipe(
      isRefChanged$(),
      switchMap((staffer) => this._global.getStafferName$(staffer))
    );

    this._options$
      .pipe(
        map(InteractiveTimelineDisplayCalculator.isHorizontal),
        distinctUntilChanged(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((isHorizontal) => {
        this.isHorizontal = isHorizontal;
        this.isVertical = !isHorizontal;
      });

    this.trackSizeInPixels$ = this._options$.pipe(
      map((options) => options.trackSizeInPixels)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async editRoster(staffer: WithRef<IStaffer>): Promise<void> {
    const brand = await snapshot(
      this._currentScope.currentBrand$.pipe(filterUndefined())
    );
    const practice = await snapshot(
      this._currentScope.currentPractice$.pipe(filterUndefined())
    );
    const day = await snapshot(this.day$);
    const range = {
      from: day.clone().startOf('day'),
      to: day.clone().endOf('day'),
    };

    const data: IEditStafferRosterDayData = {
      day,
      staffer,
      practice,
      events$: Roster.getRosterEvents$(staffer, range, brand, practice),
    };
    await this._dialog
      .open<EditStafferRosterDayDialogComponent, IEditStafferRosterDayData>(
        EditStafferRosterDayDialogComponent,
        DialogPresets.flex({ data })
      )
      .afterClosed()
      .toPromise();
  }
}
