<mat-toolbar color="accent">Add New Treatment</mat-toolbar>
<mat-stepper orientation="vertical" class="content flex-1">
  <mat-step>
    <ng-template matStepLabel>General Configuration</ng-template>
    <div>
      <pr-general-configuration
        [treatmentConfig]="treatmentConfig"
        (treatmentConfigChange)="updateGeneralTreatmentConfig($event)"
       />
      <button mat-flat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Charting Options</ng-template>
    <div>
      <div class="flex gap-4">
        <div class="flex-1">
          <pr-charting-options
            [chartingConfiguration]="treatmentConfig"
            (selectedDisplayOptions)="updateDisplayOptions($event)"
           />
        </div>
        <div class="flex-1">
          <pr-available-surfaces
            [surfaces]="treatmentConfig.availableSurfaces"
            (selectedSurfaces)="updateSelectedSurfaces($event)"
           />
        </div>
      </div>
      <button mat-stroked-button matStepperPrevious>Back</button>
      <button mat-flat-button color="primary" matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Service Codes</ng-template>
    <div>
      <pr-item-code-configuration [treatmentConfig]="treatmentConfig" />
      <button mat-stroked-button matStepperPrevious>Back</button>
    </div>
  </mat-step>
</mat-stepper>

<pt-buttons-container>
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="(isValid$ | async) === false"
    (click)="add()"
  >
    Add
  </button>
</pt-buttons-container>
