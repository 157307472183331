import { IBaseMigrationStaffer } from '../../../interfaces';
import { StaffToUserMappingHandler } from '../../../mappings/staff-to-user';
import {
  IExactStaffer,
  StafferSourceEntity,
} from '../../source/entities/staff';

export class ExactStaffToUserMappingHandler extends StaffToUserMappingHandler<
  IExactStaffer,
  StafferSourceEntity
> {
  override sourceEntity = new StafferSourceEntity();
  translateFn = (record: IExactStaffer): IBaseMigrationStaffer => ({
    id: record.id,
    name: `${record.first_name} ${record.surname}`,
    providerNo: '',
  });
}
