import { createAction, props } from '@ngrx/store';
import {
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import { getErrorAction, getSelectAction } from '@principle-theorem/ng-shared';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';

export enum TreatmentPlanActionTypes {
  LoadTreatmentPlans = '[TreatmentPlans] Load',
  LoadTreatmentPlansSuccess = '[TreatmentPlans] Load Success',
  LoadTreatmentPlansFailure = '[TreatmentPlans] Load Failure',
  SelectTreatmentPlan = '[TreatmentPlans] Select Treatment Plan',
  AddTreatmentPlan = '[TreatmentPlans] Add Treatment Plan',
  UpdateTreatmentPlan = '[TreatmentPlans] Update Treatment Plan',
  DeleteTreatmentPlan = '[TreatmentPlans] Delete Treatment Plan',
  ClearTreatmentPlan = '[TreatmentPlans] Clear Treatment Plan',
  AddTreatmentSteps = '[TreatmentPlans] Add Treatment Steps',
  RemoveTreatmentStep = '[TreatmentPlans] Remove Treatment Step',
}

export interface ILoadTreatmentPlans {
  patient: WithRef<IPatient>;
}

export const loadTreatmentPlans = createAction(
  TreatmentPlanActionTypes.LoadTreatmentPlans,
  props<SerialisedData<ILoadTreatmentPlans>>()
);

export interface ILoadTreatmentPlansSuccess {
  treatmentPlans: WithRef<ITreatmentPlan>[];
}

export const loadTreatmentPlansSuccess = createAction(
  TreatmentPlanActionTypes.LoadTreatmentPlansSuccess,
  props<SerialisedData<ILoadTreatmentPlansSuccess>>()
);

export const loadTreatmentPlansFailure = getErrorAction(
  TreatmentPlanActionTypes.LoadTreatmentPlansFailure
);

export interface IAddTreatmentPlan {
  treatmentPlan: WithRef<ITreatmentPlan>;
}

export const addTreatmentPlan = createAction(
  TreatmentPlanActionTypes.AddTreatmentPlan,
  props<SerialisedData<IAddTreatmentPlan>>()
);

export interface IUpdateTreatmentPlan {
  planRef: DocumentReference<ITreatmentPlan>;
  changes: Partial<ITreatmentPlan>;
}

export const updateTreatmentPlan = createAction(
  TreatmentPlanActionTypes.UpdateTreatmentPlan,
  props<SerialisedData<IUpdateTreatmentPlan>>()
);

export interface IDeleteTreatmentPlan {
  ref: DocumentReference<ITreatmentPlan>;
}

export const deleteTreatmentPlan = createAction(
  TreatmentPlanActionTypes.DeleteTreatmentPlan,
  props<SerialisedData<IDeleteTreatmentPlan>>()
);

export const selectTreatmentPlan = getSelectAction(
  TreatmentPlanActionTypes.SelectTreatmentPlan
);

export const clearTreatmentPlans = createAction(
  TreatmentPlanActionTypes.ClearTreatmentPlan
);

export interface IRemoveTreatmentStep {
  planRef: DocumentReference<ITreatmentPlan>;
  ref: DocumentReference<ITreatmentStep>;
}

export const removeTreatmentStep = createAction(
  TreatmentPlanActionTypes.RemoveTreatmentStep,
  props<SerialisedData<IRemoveTreatmentStep>>()
);

export interface IAddTreatmentSteps {
  planRef: DocumentReference<ITreatmentPlan>;
  stepRefs: DocumentReference<ITreatmentStep>[];
  index?: number;
}

export const addTreatmentSteps = createAction(
  TreatmentPlanActionTypes.AddTreatmentSteps,
  props<SerialisedData<IAddTreatmentSteps>>()
);
