import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FeeScheduleFacade } from '@principle-theorem/ng-clinical-charting/store';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { getDefaultFeeSchedule } from '@principle-theorem/principle-core';
import {
  type IChartedMultiStepTreatment,
  type IFeeSchedule,
  type IFeeScheduleGroup,
  type IPatient,
  isTreatmentPlan,
  type ITreatmentPlan,
} from '@principle-theorem/principle-core/interfaces';
import {
  getDoc,
  isSameRef,
  shareReplayCold,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, of, ReplaySubject } from 'rxjs';
import { debounceTime, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { type ThemePalette } from '@angular/material/core';

@Component({
    selector: 'pr-staff-fee-schedule-menu',
    templateUrl: './staff-fee-schedule-menu.component.html',
    styleUrls: ['./staff-fee-schedule-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StaffFeeScheduleMenuComponent {
  private _feeSchedule$ = new ReplaySubject<WithRef<IFeeSchedule>>(1);
  private _planFeeSchedule$: Observable<WithRef<IFeeSchedule> | undefined>;
  trackByGroup = TrackByFunctions.field<IFeeScheduleGroup>('name');
  trackBySchedule = TrackByFunctions.ref<WithRef<IFeeSchedule>>();
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  groups$: Observable<IFeeScheduleGroup[]>;
  isDifferentSchedule$: Observable<boolean>;
  iconColour$: Observable<ThemePalette>;
  tooltip$: Observable<string>;
  defaultFeeSchedule$: Observable<WithRef<IFeeSchedule> | undefined>;
  treatmentPlan$ = new ReplaySubject<
    WithRef<ITreatmentPlan> | IChartedMultiStepTreatment
  >(1);

  @Input() disabled = false;
  @Input() multiSelect = false;
  @Output() scheduleSelected = new EventEmitter<WithRef<IFeeSchedule>>();

  @Input()
  set feeSchedule(schedule: WithRef<IFeeSchedule>) {
    if (schedule) {
      this._feeSchedule$.next(schedule);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }
  @Input()
  set plan(plan: WithRef<ITreatmentPlan> | IChartedMultiStepTreatment) {
    if (plan) {
      this.treatmentPlan$.next(plan);
    }
  }

  constructor(private _feeScheduleStore: FeeScheduleFacade) {
    this.groups$ = this._feeScheduleStore.feeScheduleGroups$.pipe(
      shareReplayCold()
    );

    this.defaultFeeSchedule$ = this._feeSchedule$.pipe(
      switchMap((schedule) =>
        schedule ? of(schedule) : this._getDefaultFeeSchedule$()
      ),
      shareReplayCold()
    );

    this._planFeeSchedule$ = this.treatmentPlan$.pipe(
      switchMap((plan) =>
        plan.feeSchedule
          ? getDoc(plan.feeSchedule.ref)
          : this._getDefaultFeeSchedule$()
      )
    );

    this.isDifferentSchedule$ = combineLatest([
      this._feeSchedule$,
      this._planFeeSchedule$,
    ]).pipe(
      map(
        ([selectedSchedule, planSchedule]) =>
          !isSameRef(selectedSchedule, planSchedule)
      )
    );

    this.iconColour$ = this.isDifferentSchedule$.pipe(
      map((isDifferent) => (isDifferent ? 'warn' : undefined)),
      debounceTime(500)
    );

    this.tooltip$ = this.isDifferentSchedule$.pipe(
      withLatestFrom(
        this._feeSchedule$,
        this._planFeeSchedule$,
        this.treatmentPlan$
      ),
      map(([isDifferent, feeSchedule, planSchedule, plan]) => {
        const feeScheduleName = `Fee Schedule: ${feeSchedule.name}`;
        if (!planSchedule || !isDifferent) {
          return feeScheduleName;
        }
        const planType = isTreatmentPlan(plan)
          ? 'treatment plan'
          : 'multi treatment';
        const message = ` (Differs from ${planType}'s ${planSchedule?.name})`;
        return feeScheduleName + message;
      })
    );
  }

  compareFn(
    value: WithRef<IFeeSchedule>,
    schedule: WithRef<IFeeSchedule>
  ): boolean {
    if (!value || !schedule) {
      return false;
    }
    return isSameRef(value, schedule);
  }

  isCurrentFeeSchedule$(schedule: WithRef<IFeeSchedule>): Observable<boolean> {
    return this.multiSelect
      ? of(false)
      : this.defaultFeeSchedule$.pipe(
          map((current) => isSameRef(current, schedule))
        );
  }

  private _getDefaultFeeSchedule$(): Observable<
    WithRef<IFeeSchedule> | undefined
  > {
    return combineLatest([this.patient$, this.groups$]).pipe(
      switchMap(([patient, groups]) => getDefaultFeeSchedule(groups, patient))
    );
  }
}
