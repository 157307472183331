import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { ProfileImageService } from '@principle-theorem/ng-shared';
import { type IUser } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-auth-user-card',
    templateUrl: './auth-user-card.component.html',
    styleUrls: ['./auth-user-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AuthUserCardComponent {
  profileImage = inject(ProfileImageService);
  iconSize = 50;
  user$ = new ReplaySubject<IUser>(1);

  @Input()
  set user(user: IUser) {
    if (user) {
      this.user$.next(user);
    }
  }
}
