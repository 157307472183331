<div class="flex flex-row gap-4">
  <div class="flex w-72 flex-col">
    <mat-form-field class="flex" subscriptSizing="dynamic">
      <mat-label>Select Condition</mat-label>
      <mat-select
        [formControl]="conditionCtrl"
        [compareWith]="compareConditionId"
        required
      >
        <mat-option
          *ngFor="
            let conditionId of store.availableConditions$ | async;
            trackBy: trackByConditionId
          "
          [value]="conditionId"
        >
          {{ conditionId | map: formatLabel }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  @if (hasDynamicForm$ | async) {
    <pr-dynamic-form
      class="flex grow"
      [dynamicForm]="dynamicForm$ | async"
      (formChange)="formValue$.next($event)"
    />
  }

  <div fxFlex>
    <ng-content></ng-content>
  </div>
</div>
