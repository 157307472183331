<form class="flex flex-col gap-2" [formGroup]="form()">
  <h3 class="!m-0 !font-bold">Track Your Page Views</h3>
  <p class="!m-0 px-4">
    Enter the tracking code for your analytics providers below to see how many
    visitors are coming to your booking page, as well as completing a booking.
    <a [routerLink]="[]" (click)="trackingCodeHelp.openGeneral()"
      >Find out how Principle tracks events.</a
    >
  </p>

  <div
    class="m-2 flex flex-col gap-4 rounded-md border border-solid border-gray-300 px-4 py-2"
  >
    <span class="mb-1 font-bold">Google</span>
    <pt-multi-chip-input
      formControlName="googleAnalytics4"
      itemName="GA4 Code"
      label="Google Analytics 4 (GA4)"
      [validatorFn]="isValidGA4Code"
    >
      <a
        matHintContent
        [routerLink]="[]"
        (click)="trackingCodeHelp.openGoogleAnalytics()"
        >Where do I find my Google Analytics tracking code?</a
      >
    </pt-multi-chip-input>
    <pt-multi-chip-input
      formControlName="googleTagManager"
      itemName="GTM Code"
      label="Google Tag Manager (GTM)"
      [validatorFn]="isValidGTMCode"
    >
      <a
        matHintContent
        [routerLink]="[]"
        (click)="trackingCodeHelp.openGoogleTagManager()"
        >Where do I find my Google Tag Manager tracking code?</a
      >
    </pt-multi-chip-input>
    <pt-multi-chip-input
      formControlName="crossDomainUrls"
      itemName="URL"
      label="Cross-Domain Tracking URLs"
      [validatorFn]="isValidURL"
    />
  </div>

  <div class="grid gap-2 px-4 md:grid-cols-2">
    <mat-form-field class="pt-2">
      <mat-label>Meta Pixel</mat-label>
      <input matInput formControlName="pixel" placeholder="XXXXXXXXXXXXXXXX" />
      <mat-hint>
        <a [routerLink]="[]" (click)="trackingCodeHelp.openMetaPixel()"
          >Where do I find my Meta Pixel tracking code?</a
        >
      </mat-hint>
    </mat-form-field>

    <mat-form-field class="!flex pt-2">
      <mat-label>HubSpot</mat-label>
      <input matInput formControlName="hubSpot" placeholder="XXXXXXXX" />
      <mat-hint>
        <a [routerLink]="[]" (click)="trackingCodeHelp.openHubSpot()"
          >Where do I find my HubSpot tracking code?</a
        >
      </mat-hint>
    </mat-form-field>
  </div>
</form>
