import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { type IHealthFundCard } from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-healthfund-card-display',
  templateUrl: './healthfund-card-display.component.html',
  styleUrls: ['./healthfund-card-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthFundCardDisplayComponent {
  @Input() healthFundCard?: IHealthFundCard;
  @Output() remove = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();
}
