import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  PatientRelationshipType,
  type IClinicalNote,
  type IPatient,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { ClinicalNote, Patient } from '@principle-theorem/principle-core';
import {
  type WithRef,
  sortMoment,
  toISODate,
  toMoment,
  toTimestamp,
  filterUndefined,
  snapshot,
  isSameRef,
  multiSort,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { ClinicalNoteEditDialogComponent } from '../clinical-note-edit-dialog/clinical-note-edit-dialog.component';

export interface IClinicalNotesDialogData {
  patient: WithRef<IPatient>;
}

@Component({
  selector: 'pr-clinical-notes-history-dialog',
  templateUrl: './clinical-notes-history-dialog.component.html',
  styleUrls: ['./clinical-notes-history-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ClinicalNotesHistoryDialogComponent {
  private _staffer$: Observable<WithRef<IStaffer>>;
  clinicalNotes$: Observable<WithRef<IClinicalNote>[]>;
  addNoteDisabled$: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IClinicalNotesDialogData,
    private _dialog: MatDialog,
    private _organisation: OrganisationService
  ) {
    this._staffer$ = this._organisation.staffer$.pipe(filterUndefined());
    this.clinicalNotes$ = Patient.withPatientRelationships$(
      data.patient,
      [PatientRelationshipType.DuplicatePatient],
      ClinicalNote.all$
    ).pipe(
      switchMap((notes) =>
        ClinicalNote.filterEmpty$(notes).pipe(
          multiSort((a, b) =>
            sortMoment(toMoment(a.recordDate), toMoment(b.recordDate))
          )
        )
      )
    );

    this.addNoteDisabled$ = combineLatest([
      this.clinicalNotes$,
      this._staffer$,
    ]).pipe(
      map(([notes, staffer]) =>
        notes.some(
          (note) =>
            note.recordDate === toISODate(moment()) &&
            isSameRef(note.owner, staffer)
        )
      )
    );
  }

  async addClinicalNote(): Promise<void> {
    const staffer = await snapshot(this._staffer$);
    const config = DialogPresets.large({
      height: '80%',
      data: {
        patient: this.data.patient,
        date: toTimestamp(),
        staffer,
        displayStafferSelector: true,
        displayNoteHistory: false,
      },
    });
    this._dialog.open(ClinicalNoteEditDialogComponent, config);
  }
}
