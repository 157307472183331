import { type ICSVExport } from '@principle-theorem/ng-shared';
import { type IReferralSourcesGrouping } from '../referral-sources-report.store';

export interface IReferralSourcesSummaryCSV {
  referralSource: string;
  patientCount: number;
  treatmentTotal: number;
}

export class ReferralSourcesSummaryToCSV
  implements ICSVExport<IReferralSourcesGrouping, IReferralSourcesSummaryCSV>
{
  defaultFileName = 'referral-sources-summary';
  headers = ['Referral Source', 'Patient Count', 'Treatment Total'];

  translate(records: IReferralSourcesGrouping[]): IReferralSourcesSummaryCSV[] {
    return records.map((record) => ({
      referralSource: record.referralSource,
      patientCount: record.records.length,
      treatmentTotal: record.treatmentTotal,
    }));
  }
}
