import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable, of, Subject, timer } from 'rxjs';
import { concatMap, delay, filter, takeUntil } from 'rxjs/operators';
import {
  AlertDialogComponent,
  IAlertDialogInput,
} from '../alert-dialog/alert-dialog.component';
import { BasicDialogService } from '../basic-dialog.service';
import { DialogPresets } from '../dialog-presets';
import { APP_VERSION } from './app-version';
import { MismatchType, VersionBloc } from './version-bloc';

enum AppRefreshState {
  WaitingForUpdate = 'waitingForUpdate',
  MinorVersionPopup = 'initialPopup',
  MajorVersionPopup = 'logoutPopup',
  GracePeriodInProgress = 'gracePeriodInProgress',
  GracePeriodExpired = 'gracePeriodPopup',
}

@Injectable({
  providedIn: 'root',
})
export class AppVersionService implements OnDestroy {
  private _forceRefreshTimeout = moment.duration(1, 'hour').asMilliseconds();
  private _gracePeriodTimeout = moment.duration(5, 'minutes').asMilliseconds();
  private _cancelTimer$ = new Subject<void>();
  private _onDestroy$ = new Subject<void>();
  private _logoutFn?: () => Promise<void>;
  state$ = new BehaviorSubject<AppRefreshState>(
    AppRefreshState.WaitingForUpdate
  );

  constructor(
    @Inject(APP_VERSION) private _localVersion: string,
    private _basicDialog: BasicDialogService,
    private _dialog: MatDialog
  ) {
    this.state$
      .pipe(
        filter((state) => state === AppRefreshState.GracePeriodInProgress),
        delay(this._gracePeriodTimeout),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.state$.next(AppRefreshState.GracePeriodExpired));

    this.state$.pipe(takeUntil(this._onDestroy$)).subscribe((state) => {
      switch (state) {
        case AppRefreshState.MinorVersionPopup:
          return void this._handleMinorVersionMismatch();
        case AppRefreshState.MajorVersionPopup:
          return void this._handleMajorVersionMismatch();
        case AppRefreshState.GracePeriodExpired:
          return void this._handleGracePeriodExpired();
        default:
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  watchForUpdates(
    publicVersion$: Observable<string>,
    logoutFn: () => Promise<void>
  ): void {
    this._logoutFn = logoutFn;
    this.remote(publicVersion$)
      .onMismatch$.pipe(takeUntil(this._onDestroy$))
      .subscribe((mismatchType) => {
        if (mismatchType === MismatchType.Major) {
          this.state$.next(AppRefreshState.MajorVersionPopup);
          return;
        }
        this.state$.next(AppRefreshState.MinorVersionPopup);
      });
  }

  remote(remote$: Observable<string>): VersionBloc {
    return new VersionBloc(of(this._localVersion), remote$);
  }

  private async _handleMinorVersionMismatch(): Promise<void> {
    timer(this._forceRefreshTimeout)
      .pipe(takeUntil(this._cancelTimer$))
      .subscribe(() => window.location.reload());

    const refreshNow = await this._basicDialog.confirm({
      title: 'New Version Available',
      prompt:
        'A new version is available. Please click "Update Now" for the latest version.',
      note: 'If unattended, the browser will automatically be refreshed in 1 hour.',
      cancelLabel: 'I need 5 more minutes',
      submitLabel: 'Update Now',
      submitColor: 'primary',
    });

    if (refreshNow) {
      window.location.reload();
    }

    this._cancelTimer$.next();
    this.state$.next(AppRefreshState.GracePeriodInProgress);
  }

  private async _handleMajorVersionMismatch(): Promise<void> {
    timer(this._forceRefreshTimeout)
      .pipe(
        concatMap(() => {
          if (this._logoutFn) {
            return this._logoutFn();
          }
          return of(undefined);
        }),
        takeUntil(this._cancelTimer$)
      )
      .subscribe(() => window.location.reload());

    await this._dialog
      .open<AlertDialogComponent, IAlertDialogInput, undefined>(
        AlertDialogComponent,
        DialogPresets.small<IAlertDialogInput>({
          data: {
            title: 'New Version Available',
            prompt:
              'A new version is available that requires you to sign in again.',
            note: 'If unattended, the browser will automatically be refreshed in 1 hour.',
            submitLabel: 'Sign In',
            submitColor: 'primary',
          },
          disableClose: true,
        })
      )
      .afterClosed()
      .toPromise();

    if (this._logoutFn) {
      await this._logoutFn();
    }
    window.location.reload();
  }

  private async _handleGracePeriodExpired(): Promise<void> {
    timer(this._forceRefreshTimeout)
      .pipe(takeUntil(this._cancelTimer$))
      .subscribe(() => window.location.reload());

    await this._dialog
      .open<AlertDialogComponent, IAlertDialogInput, undefined>(
        AlertDialogComponent,
        DialogPresets.small<IAlertDialogInput>({
          data: {
            title: 'New Version Available',
            prompt:
              'Time to update to the latest version. Please click "Update Now" to continue.',
            note: 'If unattended, the browser will automatically be refreshed in 1 hour.',
            submitLabel: 'Update Now',
            submitColor: 'primary',
          },
          disableClose: true,
        })
      )
      .afterClosed()
      .toPromise();

    window.location.reload();
  }
}
