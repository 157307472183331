import { Patient } from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  ISourceEntityRecord,
  SkippedDestinationEntityRecord,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityJobRunOptions,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type ISource,
  type MergeConflictDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  getError,
  multiMap,
  toTimestamp,
  type DocumentReference,
  type WithRef,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { compact, first } from 'lodash';
import { type Observable } from 'rxjs';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { PracticeIdFilter } from '../../../destination/filters/practice-id-filter';
import { CopyFile, CopyFiles, getBucketStoragePath } from '../../../storage';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientSourceEntity,
  type ID4WPatient,
  type ID4WPatientFilters,
  type ID4WPatientTranslations,
} from '../../source/entities/patient';
import { PatientProfilePhotoSourceEntity } from '../../source/entities/patient-profile-photos.ts';
import { PatientDestinationEntity } from './patients';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';

export const PATIENT_PROFILE_PHOTO_RESOURCE_TYPE = 'patientProfilePhotos';

export const PATIENT_PROFILE_PHOTO_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_PROFILE_PHOTO_RESOURCE_TYPE,
    label: 'Patient Profile Photos',
    description: '',
  },
});

export interface IPatientProfilePhotoJobData {
  sourcePatient: IGetRecordResponse<
    ID4WPatient,
    ID4WPatientTranslations,
    ID4WPatientFilters
  >;
}

interface IFileSummary {
  patientId: string;
  fileName: string;
  folderPath: string;
}

export interface IPatientProfilePhotoMigrationData {
  sourcePatientId: string;
  patientRef: DocumentReference<IPatient>;
  file?: IFileSummary;
}

export class PatientProfilePhotoDestinationEntity extends BaseDestinationEntity<
  object,
  IPatientProfilePhotoJobData,
  IPatientProfilePhotoMigrationData
> {
  destinationEntity = PATIENT_PROFILE_PHOTO_DESTINATION_ENTITY;

  override filters = [
    new PracticeIdFilter<IPatientProfilePhotoJobData>((jobData) =>
      jobData.sourcePatient.data.data.practice_id.toString()
    ),
    new PatientIdFilter<IPatientProfilePhotoJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    patientProfilePhotos: new PatientProfilePhotoSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  sourceCountDataAccessor(
    data: IPatientProfilePhotoJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientProfilePhotoJobData[]> {
    return this.buildSourceRecordQuery$(
      migration,
      this.sourceEntities.patients,
      runOptions
    ).pipe(
      multiMap((sourcePatient) => ({
        sourcePatient,
      }))
    );
  }

  getDestinationEntityRecordUid(data: IPatientProfilePhotoJobData): string {
    return data.sourcePatient.record.uid;
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientProfilePhotoJobData
  ): Promise<
    | IPatientProfilePhotoMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
    | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `Couldn't resolve patient`
      );
    }

    const files = await this.sourceEntities.patientProfilePhotos.filterRecords(
      migration,
      'patientId',
      sourcePatientId
    );

    const file = first(files)?.data.data;

    if (!file) {
      return this._buildSkippedResponse(data.sourcePatient);
    }

    return {
      sourcePatientId,
      patientRef,
      file,
    };
  }

  async hasMergeConflict(
    _translationMap: TranslationMapHandler,
    data: IPatientProfilePhotoMigrationData
  ): Promise<IPatientProfilePhotoMigrationData | undefined> {
    const patient = await Firestore.getDoc(data.patientRef);
    if (patient.profileImageURL) {
      return data;
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientProfilePhotoJobData,
    _migrationData: IPatientProfilePhotoMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  async runJob(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IPatientProfilePhotoJobData,
    migrationData: IPatientProfilePhotoMigrationData
  ): Promise<IDestinationEntityRecord> {
    if (!migrationData.file) {
      return this._buildSuccessResponse(data.sourcePatient);
    }
    try {
      await this._addProfilePhotoToPatient(
        migrationData.patientRef,
        migrationData.file,
        migration.source,
        migration.configuration.projectId,
        migration.configuration.destinationBucket
      );
      return this._buildSuccessResponse(data.sourcePatient);
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  private _buildSuccessResponse(
    sourcePatient: IGetRecordResponse<ID4WPatient, ID4WPatientTranslations>
  ): IDestinationEntityRecord<object> {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      data: {},
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: sourcePatient.record.ref,
      migratedAt: toTimestamp(),
    };
  }

  private _buildErrorResponse(
    sourcePatient: IGetRecordResponse<ID4WPatient, ID4WPatientTranslations>,
    errorMessage: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: sourcePatient.record.ref,
      errorMessage,
      failData: {
        patientRef: sourcePatient.record.ref,
      },
    };
  }

  private _buildSkippedResponse(
    patient: IGetRecordResponse<ID4WPatient, ID4WPatientTranslations>
  ): IDestinationEntityRecord & SkippedDestinationEntityRecord {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      status: DestinationEntityRecordStatus.Skipped,
      sourceRef: patient.record.ref,
    };
  }

  private async _addProfilePhotoToPatient(
    patientRef: DocumentReference<IPatient>,
    file: IFileSummary,
    source: ISource,
    projectId: string,
    destinationBucket: string
  ): Promise<void> {
    const path = await this._copySourceFileToDestination(
      getBucketStoragePath(source),
      `${file.folderPath}/${file.fileName}`,
      patientRef,
      projectId,
      destinationBucket
    );

    await FirestoreMigrate.patchDoc(patientRef, {
      profileImageURL: path,
    });
  }

  private async _copySourceFileToDestination(
    sourceBucket: string,
    sourcePath: string,
    patientRef: DocumentReference<IPatient>,
    projectId: string,
    destinationBucket: string
  ): Promise<string> {
    const copyStorage = new CopyFile(
      sourceBucket,
      sourcePath,
      destinationBucket,
      Patient.storagePath({ ref: patientRef }),
      projectId
    );

    return copyStorage.copy();
  }
}

export async function groupImagesByPatient(
  projectId: string,
  bucketName: string,
  bucketPath: string
): Promise<IFileSummary[]> {
  const storage = new CopyFiles(bucketName, bucketPath, '', '', projectId)
    .storage;

  const [files] = await storage.bucket(bucketName).getFiles({
    prefix: bucketPath,
  });

  const missingFields: string[] = [];

  return compact(
    files.map((file) => {
      const folderPath = file.name.split('/');

      const fileSummary: IFileSummary = {
        patientId: '',
        fileName: '',
        folderPath: '',
      };

      const patientIdMatch = new RegExp(/([0-9]+).*$/).exec(folderPath[1]);
      fileSummary.patientId = patientIdMatch?.[1] ?? '';

      fileSummary.folderPath = folderPath.slice(0, 1).join('/') ?? '';
      fileSummary.fileName = folderPath.slice(1).join('_');

      if (!fileSummary.patientId || !fileSummary.fileName) {
        missingFields.push(file.name);
      }

      return fileSummary;
    })
  );
}
