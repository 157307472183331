import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IBalance } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-balance-display',
  templateUrl: './balance-display.component.html',
  styleUrls: ['./balance-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BalanceDisplayComponent {
  balance$ = new ReplaySubject<IBalance>(1);

  @Input()
  set balance(balance: IBalance) {
    if (balance) {
      this.balance$.next(balance);
    }
  }
}
