<button
  mat-icon-button
  [matTooltip]="tooltipText"
  [matMenuTriggerFor]="filterMenu"
>
  <mat-icon
    [class.text-primary-500]="(allSelected$ | async) === false"
    class="opacity-80"
  >
    person_add
  </mat-icon>
</button>

<mat-menu #filterMenu="matMenu">
  <mat-list (click)="$event.stopPropagation()">
    <mat-list-item>
      <button
        mat-stroked-button
        class="mat-small w-full"
        (click)="toggleSelectAll()"
      >
        {{ (allSelected$ | async) ? 'Deselect All' : 'Select All' }}
      </button>
    </mat-list-item>
    @for (practitioner of practitioners$ | async; track practitioner.ref.path) {
      <mat-list-item
        class="!cursor-pointer hover:bg-gray-50"
        (click)="$event.stopPropagation(); togglePractitioner(practitioner)"
      >
        <div class="flex items-center gap-2">
          <mat-checkbox
            [checked]="practitioner | map: practitionerSelected$ : this | async"
            (click)="$event.stopPropagation(); togglePractitioner(practitioner)"
          />
          <pt-user-icon
            [src]="practitioner | map: getPractitionerImage$ : this | async"
            [name]="practitioner.user.name"
            [diameter]="24"
          />
          {{ practitioner.user.name }}
        </div>
      </mat-list-item>
    }
  </mat-list>
</mat-menu>
