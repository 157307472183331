import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DYNAMIC_COMPONENT_DATA } from '@principle-theorem/ng-shared';
import { Observable } from 'rxjs';
import { IIntegrationActionData } from '../../../../integration-action-data';
import { VixWinFeatureService } from '../vixwin-feature.service';
import { BridgeDeviceSelectorService } from '../../../bridge-device-selector-dialog/bridge-device-selector.service';
import { VIXWIN_INTEGRATION } from '@principle-theorem/principle-bridge-core';

@Component({
    selector: 'pr-vixwin-open-patient',
    templateUrl: './vixwin-open-patient.component.html',
    styleUrl: './vixwin-open-patient.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class VixWinOpenPatientComponent {
  isDisabled$: Observable<boolean>;

  constructor(
    @Inject(DYNAMIC_COMPONENT_DATA) private _data: IIntegrationActionData,
    private _vixWin: VixWinFeatureService,
    private _bridgeDeviceSelector: BridgeDeviceSelectorService
  ) {}

  async openPatient(): Promise<void> {
    const deviceRef =
      await this._bridgeDeviceSelector.selectDevice(VIXWIN_INTEGRATION);

    if (!deviceRef) {
      return;
    }

    await this._vixWin.openPatient(this._data.patient, deviceRef);
  }
}
