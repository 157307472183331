<mat-toolbar color="accent">
  Arrival Checklist - {{ patient.name }}
</mat-toolbar>

<mat-dialog-content>
  <div class="flex flex-col gap-2">
    <h3 class="!m-0 !font-bold text-black">Forms</h3>

    <pr-patient-submitted-forms-list
      [patient]="patient"
      [loadingState]="false"
      [hideConfirmedForms]="true"
      (formSelected)="patientCustomForms.openFormDialog($event)"
    />

    <h3 class="!m-0 !font-bold text-black">Checklist</h3>

    <mat-list class="!p-0">
      <mat-list-item
        class="mat-list-option"
        *ngFor="let item of checklist$ | ngrxPush; trackBy: trackByItem"
        (click)="updateSelected(item)"
      >
        <div fxFlex="80">
          <mat-checkbox color="primary" [checked]="item.done">
            <div class="title" fxFlex="70" fxLayout="column">
              <span class="title">{{ item.title }}</span>
            </div>
          </mat-checkbox>
        </div>
        <ng-container *ptFeatureEnabled="'appointments.checklists.actions'">
          <div
            fxFlex
            fxLayout="row"
            fxLayoutAlign="end center"
            *ngIf="item.action !== 'none'"
          >
            <button mat-icon-button (click)="doAction($event, item)">
              <mat-icon>{{ item.action }}</mat-icon>
            </button>
          </div>
        </ng-container>
      </mat-list-item>
    </mat-list>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-stroked-button
      (click)="markArrival()"
      [disabled]="data.appointment | map: isArrived"
    >
      Notify {{ practitioner.name }}
    </button>
    <button
      mat-stroked-button
      (click)="checkIn()"
      [disabled]="(allComplete$ | ngrxPush) === false"
    >
      Complete Check In
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
