import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  Appointment,
  deduplicateChecklistItems,
} from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IChecklistItem,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface IChecklistFormState {
  appointment: WithRef<IAppointment> | undefined;
  checklists: IChecklistItem[];
}

const initialState: IChecklistFormState = {
  appointment: undefined,
  checklists: [],
};

@Injectable()
export class ChecklistFormStore extends ComponentStore<IChecklistFormState> {
  readonly appointment$ = this.select((store) => store.appointment);
  readonly checklists$ = this.select((store) => store.checklists);

  readonly loadAppointment = this.effect(
    (appointment$: Observable<WithRef<IAppointment>>) => {
      return appointment$.pipe(
        tap((appointment) => {
          this.patchState({ appointment });
          this.loadChecklists(Appointment.checklistItems$(appointment));
        })
      );
    }
  );

  readonly loadChecklists = this.effect(
    (checklists$: Observable<IChecklistItem[]>) => {
      return checklists$.pipe(
        tap((checklists) => {
          this.patchState({ checklists });
        })
      );
    }
  );

  readonly addChecklistItem = this.updater((state, item: IChecklistItem) => ({
    ...state,
    checklists: [...state.checklists, item],
  }));

  readonly addChecklistItems = this.updater(
    (state, items: IChecklistItem[]) => ({
      ...state,
      checklists: deduplicateChecklistItems([...state.checklists, ...items]),
    })
  );

  readonly deleteChecklistItem = this.updater(
    (state, item: IChecklistItem) => ({
      ...state,
      checklists: [...state.checklists].filter(
        (checklistItem) => checklistItem.title !== item.title
      ),
    })
  );

  constructor() {
    super(initialState);
  }
}
