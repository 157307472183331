import {
  randAccount,
  randCompanyName,
  randCountry,
  randIban,
  randMask,
  randStateAbbr,
  randStreetAddress,
  randZipCode,
} from '@ngneat/falso';
import { IAccountDetails } from '@principle-theorem/principle-core/interfaces';

function MockFullAddress(): string {
  const street: string = randStreetAddress();
  const state: string = randStateAbbr();
  const postcode: string = randZipCode();
  const country: string = randCountry();
  return `${street}, ${state}, ${postcode} ${country}`;
}

export class MockAccount {
  static mockAccountDetails(): IAccountDetails {
    return {
      name: randCompanyName(),
      address: MockFullAddress(),
      taxId: randIban(),
      bsb: randMask(),
      accNo: randAccount(),
    };
  }
}
