import {
  CustomMappingType,
  IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, getEnumValues } from '@principle-theorem/shared';
import { CustomMapping } from '../../../custom-mapping';
import { CorePracticePatientAttachmentTag } from '../../source/entities/patient-attachments';
import { CorePracticeMediaTagMappingHandler } from './media-category-to-media-tag';

const PATIENT_ATTACHMENT_CUSTOM_MAPPING_TYPE = 'patientAttachmentCustomMapping';

export const ATTACHMENT_TAG_MAPPING = CustomMapping.init({
  metadata: {
    label: 'Attachment Tags',
    description:
      'This allows us to map the attachment tags of Core to the media tags configured in Principle',
    type: PATIENT_ATTACHMENT_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.DocumentReference,
});

export class CorePracticeAttachmentTagMappingHandler extends CorePracticeMediaTagMappingHandler {
  override customMapping = ATTACHMENT_TAG_MAPPING;

  override async getMediaCategoryOptions(
    _migration: WithRef<IPracticeMigration>
  ): Promise<string[]> {
    return Promise.resolve(getEnumValues(CorePracticePatientAttachmentTag));
  }
}
