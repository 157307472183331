<pt-feature-info />
<pt-breadcrumbs class="bg-white" [breadcrumbs]="breadcrumbs" />
<div class="border-b border-solid border-slate-300"></div>

<div class="wrapper flex flex-col gap-4">
  <h1>Security</h1>

  <pr-information-box>
    Workspace Security settings allow you to enable IP blocking and/or block
    individual staff member access outside specified hours. Be cautious when
    configuring these settings to avoid accidentally blocking yourself or other
    essential users.
  </pr-information-box>

  <pr-practice-selector
    class="w-fit"
    subscriptSizing="dynamic"
    [practice]="organisation.practice$ | async"
    [brands]="organisation.userBrands$ | async"
    (practiceSelected)="practiceSelected($event)"
  />

  <mat-card appearance="outlined">
    <form [formGroup]="ipWhitelistForm">
      <mat-card-header class="mb-6 mt-2 flex justify-between">
        <mat-card-title>IP Whitelist</mat-card-title>
        <mat-slide-toggle formControlName="isEnabled">
          Enable
        </mat-slide-toggle>
      </mat-card-header>

      <mat-card-content>
        <div class="p-2">
          <pt-multi-chip-input
            formControlName="whitelist"
            itemName="IP"
            label="Whitelisted IPs"
            [validatorFn]="isIpValidatorFn"
          >
            <span matHintContent class="text-red-500">
              Any IP addresses <strong>not</strong> listed will lose access to
              the Practice
            </span>
          </pt-multi-chip-input>

          @if (userIp$ | async; as ipAddress) {
            <div class="mat-caption mt-4">
              <span>Your public IP</span>
              <div class="flex items-center gap-2">
                <div class="font-bold">{{ ipAddress }}</div>
                <a
                  href="javascript:void(0);"
                  class="copy-icon"
                  (click)="copyIpToClipboard(ipAddress)"
                >
                  <mat-icon
                    matTooltip="Copy public IP to clipboard"
                    color="accent"
                  >
                    content_copy
                  </mat-icon>
                </a>
              </div>
            </div>
          }
        </div>
      </mat-card-content>
    </form>
  </mat-card>

  <mat-card appearance="outlined">
    <form [formGroup]="userRestrictionsForm">
      <mat-card-header class="mb-6 mt-2 flex justify-between">
        <mat-card-title>Staff Restrictions</mat-card-title>
        <mat-slide-toggle formControlName="isEnabled">
          Enable
        </mat-slide-toggle>
      </mat-card-header>

      <mat-card-content class="mt-2">
        <span>New Staff Restriction</span>
        <div class="flex gap-2 p-2">
          <mat-form-field subscriptSizing="dynamic">
            <mat-label>Select Staff</mat-label>
            <mat-select multiple [formControl]="staffSelectorCtrl">
              @for (staffer of staffOptions$ | async; track staffer.ref.id) {
                <mat-option [value]="staffer">
                  {{ staffer.user.name }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <pt-iso-time-selector
            class="flex-1"
            label="Allow access from"
            formControlName="allowedFrom"
          />
          <pt-iso-time-selector
            class="flex-1"
            label="Allow access to"
            formControlName="allowedTo"
          />
          <button
            mat-flat-button
            color="primary"
            (click)="addUserRestriction()"
          >
            Add
          </button>
        </div>
      </mat-card-content>
    </form>

    @if (userRestrictions().length) {
      <mat-divider />
      <mat-list>
        @for (
          restriction of userRestrictions();
          track restriction.user.ref.id
        ) {
          <mat-list-item>
            <div class="flex items-center justify-between">
              <div>{{ restriction.user.name }}</div>
              <div class="flex items-center gap-1">
                <span>{{ restriction.allowedFrom | timeFormat }}</span>
                <span> to </span>
                <span>{{ restriction.allowedTo | timeFormat }}</span>
                <button
                  mat-icon-button
                  class="ml-2"
                  (click)="deleteRestriction(restriction)"
                >
                  <mat-icon class="text-warn-500">delete</mat-icon>
                </button>
              </div>
            </div>
          </mat-list-item>
        }
      </mat-list>
    }
  </mat-card>

  <pt-buttons-container>
    <button
      mat-raised-button
      color="primary"
      [disabled]="saveDisabled$ | async"
      (click)="save()"
    >
      Save
    </button>
  </pt-buttons-container>
</div>
