import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { PatientConfirmAppointmentComponent } from './components/patient-confirm-appointment/patient-confirm-appointment.component';
import { PatientMedicalHistoryComponent } from './components/patient-medical-history/patient-medical-history.component';
import { NgPatientModule } from './ng-patient.module';
import { PatientViewInvoiceComponent } from './components/patient-view-invoice/patient-view-invoice.component';

const routes: Routes = [
  {
    path: 'confirm-appointment',
    data: { title: 'Confirm Appointment' },
    component: PatientConfirmAppointmentComponent,
  },
  {
    path: 'confirm-appointment/:token',
    data: { title: 'Confirm Appointment' },
    component: PatientConfirmAppointmentComponent,
  },
  {
    path: 'medical-history',
    data: { title: 'Medical History' },
    component: PatientMedicalHistoryComponent,
  },
  {
    path: 'medical-history/:token',
    data: { title: 'Medical History' },
    component: PatientMedicalHistoryComponent,
  },
  {
    path: 'view-invoice',
    data: { title: 'View Invoice' },
    component: PatientViewInvoiceComponent,
  },
  {
    path: 'view-invoice/:token',
    data: { title: 'View Invoice' },
    component: PatientViewInvoiceComponent,
  },
];

@NgModule({
  imports: [NgPatientModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NgPatientActionsRoutingModule {}
