import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  inject,
} from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  TypedFormControl,
  TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import {
  CustomFormFieldElement,
  ICustomFormFieldOptions,
} from '@principle-theorem/principle-core/interfaces';
import { debounceUserInput, firstValueFrom } from '@principle-theorem/shared';
import { ReplaySubject, Subject } from 'rxjs';
import { distinctUntilKeyChanged, takeUntil } from 'rxjs/operators';
import { FormBuilderService } from '../../form-builder.service';

@Component({
    selector: 'pr-form-builder-text-options',
    templateUrl: './form-builder-text-options.component.html',
    styleUrl: './form-builder-text-options.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, ReactiveFormsModule, NgMaterialModule]
})
export class FormBuilderTextOptionsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _formBuilder = inject(FormBuilderService);
  element$ = new ReplaySubject<CustomFormFieldElement>(1);
  form = new TypedFormGroup<ICustomFormFieldOptions>({
    dataKey: new TypedFormControl<string>('', [Validators.required]),
    label: new TypedFormControl<string>('', [Validators.required]),
    required: new TypedFormControl<boolean>(false, []),
  });

  @ViewChild('autoFocusElement', { static: true })
  autoFocusElementRef: ElementRef<HTMLElement>;

  @Input()
  set element(element: CustomFormFieldElement) {
    this.element$.next(element);
  }

  constructor() {
    this.element$
      .pipe(distinctUntilKeyChanged('uid'), takeUntil(this._onDestroy$))
      .subscribe((element) => {
        this.form.patchValue(element.options, { emitEvent: false });
        this._focus();
      });
    validFormGroupChanges$(this.form)
      .pipe(debounceUserInput(), takeUntil(this._onDestroy$))
      .subscribe((options) => void this._updateOptions(options));
  }

  ngOnDestroy(): void {
    if (this.form.valid) {
      void this._updateOptions(this.form.value);
    }
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private async _updateOptions(
    options: ICustomFormFieldOptions
  ): Promise<void> {
    const element = await firstValueFrom(this.element$);
    if (element) {
      this._formBuilder.updateElement({ ...element, options });
    }
  }

  private _focus(): void {
    this.autoFocusElementRef?.nativeElement.focus();
  }
}
