import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  Patient,
  ITypesensePatientWithRef,
} from '@principle-theorem/principle-core';
import { DAY_MONTH_YEAR_FORMAT } from '@principle-theorem/shared';
import { ReplaySubject, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PatientStatus } from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-patient-selector-item',
  templateUrl: './patient-selector-item.component.html',
  styleUrls: ['./patient-selector-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientSelectorItemComponent {
  patient$ = new ReplaySubject<ITypesensePatientWithRef>(1);
  age$: Observable<number | undefined>;
  phone$: Observable<string | undefined>;
  dateOfBirthFormat = DAY_MONTH_YEAR_FORMAT;

  @Input()
  set patient(patient: ITypesensePatientWithRef) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor() {
    this.age$ = this.patient$.pipe(map(Patient.age));
    this.phone$ = this.patient$.pipe(
      map((patient) => ({
        ...patient,
        contactNumbers: patient.contactNumbers.map((contactNumber) => ({
          label: '',
          number: contactNumber,
        })),
      })),
      switchMap(Patient.resolveFirstContactNumber)
    );
  }

  isDisabledPatient(status: PatientStatus): boolean {
    return Patient.isDisabledPatient(status);
  }
}
