import { Injectable } from '@angular/core';
import { type Data, Router } from '@angular/router';
import { type IFeeSchedule } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { BaseCurrentFirestoreModel } from '@principle-theorem/ng-principle-shared';
import { get, has } from 'lodash';
import { FeeScheduleResolver } from './fee-schedule-resolver.service';

@Injectable({
  providedIn: 'root',
  useFactory: (route: Router) => new CurrentFeeScheduleScope(route),
  deps: [Router],
})
export class CurrentFeeScheduleScope extends BaseCurrentFirestoreModel<
  WithRef<IFeeSchedule>
> {
  protected _propertyAccessor(data: Data): WithRef<IFeeSchedule> | undefined {
    return has(data, FeeScheduleResolver.resolverKey)
      ? (get(data, FeeScheduleResolver.resolverKey) as WithRef<IFeeSchedule>)
      : undefined;
  }
}
