<table class="print-table limit-width w-full">
  <thead>
    <tr>
      <th>
        <div class="limit-height">
          <pr-content-view
            *ngIf="header$ | async as header"
            [content]="header"
          />
        </div>
      </th>
    </tr>
  </thead>

  <tbody>
    <tr>
      <td>
        <ng-content />
      </td>
    </tr>
  </tbody>

  <tfoot>
    <tr>
      <td>
        <div class="footer limit-height">
          <pr-content-view
            *ngIf="footer$ | async as footer"
            [content]="footer"
          />
        </div>
      </td>
    </tr>
  </tfoot>
</table>

<div class="last-footer limit-width limit-height fixed bottom-0 left-0 w-full">
  <pr-content-view *ngIf="footer$ | async as footer" [content]="footer" />
</div>
