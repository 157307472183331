<mat-form-field subscriptSizing="dynamic" class="w-full">
  <mat-label>{{ label }}</mat-label>
  @if (formCtrl.value) {
    <button mat-icon-button matSuffix (click)="clearInput()" aria-label="Clear">
      <mat-icon>clear</mat-icon>
    </button>
  }
  <mat-select
    [formControl]="formCtrl"
    [required]="required"
    [compareWith]="compareWithFn"
  >
    <ng-container *ngIf="store.brandTreatments$ | async as brandTreatments">
      <mat-optgroup label="Brand Treatments" *ngIf="brandTreatments.length">
        <mat-option
          *ngFor="
            let configuration of brandTreatments;
            trackBy: trackByConfiguration
          "
          [value]="configuration"
        >
          {{ configuration.name }}
        </mat-option>
      </mat-optgroup>
    </ng-container>

    <ng-container *ngIf="store.stafferTreatments$ | async as stafferTreatments">
      <mat-optgroup label="Staffer Treatments" *ngIf="stafferTreatments.length">
        <mat-option
          *ngFor="
            let configuration of stafferTreatments;
            trackBy: trackByConfiguration
          "
          [value]="configuration"
        >
          {{ configuration.name }}
        </mat-option>
      </mat-optgroup>
    </ng-container>

    <ng-container
      *ngIf="store.brandMultiTreatments$ | async as brandMultiTreatments"
    >
      <mat-optgroup
        label="Brand Multi Treatments"
        *ngIf="brandMultiTreatments.length"
      >
        <mat-option
          *ngFor="
            let configuration of brandMultiTreatments;
            trackBy: trackByConfiguration
          "
          [value]="configuration"
        >
          {{ configuration.name }}
        </mat-option>
      </mat-optgroup>
    </ng-container>

    <ng-container
      *ngIf="store.stafferMultiTreatments$ | async as stafferMultiTreatments"
    >
      <mat-optgroup
        label="Staffer Multi Treatments"
        *ngIf="stafferMultiTreatments.length"
      >
        <mat-option
          *ngFor="
            let configuration of stafferMultiTreatments;
            trackBy: trackByConfiguration
          "
          [value]="configuration"
        >
          {{ configuration.name }}
        </mat-option>
      </mat-optgroup>
    </ng-container>
  </mat-select>
</mat-form-field>
