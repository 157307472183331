import { FormLayoutElementType } from '@principle-theorem/principle-core/interfaces';
import { CustomFormEditorContentWidgetComponent } from './editor/custom-form-editor-content-widget.component';
import { CustomFormSignatureWidgetComponent } from './signature/custom-form-signature-widget.component';
import { CustomFormTextareaWidgetComponent } from './textarea/custom-form-textarea-widget.component';
import { CustomFormDatePickerWidgetComponent } from './date-picker/custom-form-date-picker-widget.component';

export const PRINCIPLE_CUSTOM_FORM_WIDGETS = {
  [FormLayoutElementType.Signature]: CustomFormSignatureWidgetComponent,
  [FormLayoutElementType.EditorContent]: CustomFormEditorContentWidgetComponent,
  [FormLayoutElementType.CustomTextArea]: CustomFormTextareaWidgetComponent,
  [FormLayoutElementType.CustomDate]: CustomFormDatePickerWidgetComponent,
};
