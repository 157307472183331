import { type WithRef } from '@principle-theorem/shared';
import { type IFeeSchedule } from './fee-schedule';

export interface IFeeScheduleGroup {
  name: string;
  scope: FeeScheduleScope;
  schedules: WithRef<IFeeSchedule>[];
}

export enum FeeScheduleScope {
  Organisation = 'organisation',
  Brand = 'brand',
  Practice = 'practice',
  Practitioner = 'practitioner',
}
