import { rand, randBoolean, randSentence } from '@ngneat/falso';
import { initVersionedSchema, toTextContent } from '@principle-theorem/editor';
import {
  IInteractionV2,
  InteractionType,
  ISchedulingEvent,
  IStaffer,
  ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  ArchivedDocument,
  BaseMock,
  MockGenerator,
  MockTimestamp,
  Properties,
} from '@principle-theorem/shared';
import { MockDocRef, MockNamedDocument } from '@principle-theorem/testing';
import { v4 as uuid } from 'uuid';
import { MentionMock } from '../mention/mention.mock';

export class InteractionMock
  extends BaseMock
  implements Properties<IInteractionV2>
{
  uid = uuid();
  type = rand([
    InteractionType.Sms,
    InteractionType.Email,
    InteractionType.Call,
    InteractionType.TaskAssign,
  ]);
  owner = MockNamedDocument<IStaffer>();
  title = [toTextContent(randSentence())];
  action = randSentence();
  createdAt = MockTimestamp();
  content = initVersionedSchema(randSentence());
  mentions = [MockGenerator.generate(MentionMock)];
  pinned = randBoolean();
  deleted = false;
  amendmentOf = MockDocRef<ArchivedDocument<IInteractionV2>>();
  tags = [MockNamedDocument<ITag>(), MockNamedDocument<ITag>()];
  schedulingEvent = MockDocRef<ISchedulingEvent>();
}
