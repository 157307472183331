import { ReportBuilderDataSourceId } from '@principle-theorem/principle-core/interfaces';
import { FactTables, toMeasureBuilderData } from '@principle-theorem/reporting';
import { ReportBuilderChartFilters } from './report-builder-chart-filters';
import { ReportBuilderColumns } from './report-builder-columns';
import { type IReportBuilderDataSource } from './report-builder-data-source';
import { ChartFilters } from './chart-filters';
import { ReportBuilderGroups } from './report-builder-groups';

const factTable = FactTables.patientEvent.latestEvent;

export const PATIENTS_REPORT_BUILDER_DATA_SOURCE: IReportBuilderDataSource = {
  id: ReportBuilderDataSourceId.Patients,
  name: 'Patients',
  description: `One result per patient within Principle. This includes information about the patient's finances.`,
  factTable,
  queryableTimestamps: [
    { property: factTable.patient.createdAt },
    { property: factTable.patient.lastVisitDate },
    { property: factTable.patient.nextVisitDate },
  ],
  groupByOptions: [
    ReportBuilderGroups.toGroup('Common', [
      ReportBuilderGroups.toOption(factTable.brand.name),
      ReportBuilderGroups.toOption(factTable.count),
    ]),
    ReportBuilderGroups.patient(factTable.patient),
  ],
  availableColumns: [
    ReportBuilderColumns.toColumnGroup('Patient', [
      ...ReportBuilderColumns.patient(factTable.patient),
      factTable.patientLink,
    ]),
  ],
  defaultColumns: ReportBuilderColumns.toReferences([
    factTable.patient.name,
    factTable.patient.mobileNumber,
    factTable.patient.email,
    factTable.patient.age,
    factTable.patient.gender,
    factTable.patient.referrer,
    factTable.patient.tags,
    factTable.patient.creditRemaining,
    factTable.patient.paymentsReceived,
    factTable.patient.outstanding,
  ]),
  availableCharts: [
    ReportBuilderChartFilters.predefined(
      'overview.patient.count',
      factTable.patient.name,
      ChartFilters.numberChart('Total Patients', factTable.patient.name, [
        toMeasureBuilderData(factTable.count),
      ])
    ),
    ...ReportBuilderChartFilters.patient(factTable.patient, factTable.count),
  ],
};
