<div class="h-0.5">
  @if (scheduleHistory.loading$ | async) {
    <mat-progress-bar mode="indeterminate" />
  }
</div>
<pr-toc-page
  sidebarTitle="Dates"
  [sidebarClass]="(scheduleHistory.hasMultipleDays$ | async) ? '' : '!hidden'"
>
  <div class="flex flex-col gap-4 p-4">
    <h2 class="!m-0 !font-bold">Scheduling History</h2>

    <pr-information-box>
      Use this page to keep track or find any scheduling events including FTA's
      and UTA's that have affected the selected date range. You can manage the
      available reasons given for such scheduling events from &quot;Settings
      &gt; Rescheduling & Cancellation Reasons&quot;
    </pr-information-box>

    <pr-scheduling-event-query-form
      [formControl]="filterControl"
      class="flex"
    />

    @for (day of scheduleHistory.days$ | async; track day.date) {
      <div class="flex flex-col gap-2">
        @if (scheduleHistory.hasMultipleDays$ | async) {
          <h3 class="!m-0 !font-bold" ptTableOfContentsHeading [id]="day.date">
            {{ day.date | moment | amDateFormat: dateFormat }}
          </h3>
        }

        <div class="flex flex-col gap-2">
          @for (
            eventSummary of day.events;
            track eventSummary.schedulingEventRef.path
          ) {
            <pr-scheduling-event-display
              [schedulingEventSummary]="eventSummary"
              [day]="day.date | map: colourCodeDate$ : this | async"
            >
              <pr-scheduling-event-actions
                [schedulingEventSummary]="eventSummary"
              />
            </pr-scheduling-event-display>
          } @empty {
            <div class="flex opacity-50">No scheduling events</div>
          }
        </div>
      </div>
    }
  </div>
</pr-toc-page>
