import { BehaviorSubject, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export enum PatientGapOfferState {
  Loading = 'loading',
  AlreadyFilled = 'alreadyFilled',
  NotAvailable = 'notAvailable',
  Confirmed = 'confirmed',
}

export class PatientGapOfferStateBloc {
  state$ = new BehaviorSubject<PatientGapOfferState>(
    PatientGapOfferState.Loading
  );
  isLoading$ = this.state$.pipe(isState(PatientGapOfferState.Loading));
  isAlreadyFilled$ = this.state$.pipe(
    isState(PatientGapOfferState.AlreadyFilled)
  );
  isNotAvailable$ = this.state$.pipe(
    isState(PatientGapOfferState.NotAvailable)
  );
  isConfirmed$ = this.state$.pipe(isState(PatientGapOfferState.Confirmed));
}

function isState(
  match: PatientGapOfferState
): OperatorFunction<PatientGapOfferState, boolean> {
  return map((state) => state === match);
}
