import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PatientStatus } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-patient-status',
  templateUrl: './patient-status.component.html',
  styleUrls: ['./patient-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientStatusComponent {
  status$ = new ReplaySubject<PatientStatus>(1);
  patientStatus = PatientStatus;

  @Input()
  set status(status: PatientStatus) {
    if (status) {
      this.status$.next(status);
    }
  }
}
