import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgAutomationsModule } from '@principle-theorem/ng-automations';
import { NgClinicalChartingModule } from '@principle-theorem/ng-clinical-charting';
import { NgCoreModule } from '@principle-theorem/ng-core';
import { NgCustomFormsModule } from '@principle-theorem/ng-custom-forms';
import { NgEventableModule } from '@principle-theorem/ng-eventable';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgIntercomComponentsModule } from '@principle-theorem/ng-intercom';
import { NgLabsModule } from '@principle-theorem/ng-labs';
import { NgMediaManagerModule } from '@principle-theorem/ng-media-manager';
import { NgPatientModule } from '@principle-theorem/ng-patient';
import {
  PerioChartSettingsComponent,
  PerioChartUiComponent,
} from '@principle-theorem/ng-perio-charting';
import { NgPrincipleAccountingModule } from '@principle-theorem/ng-principle-accounting';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { AppointmentAllNotesComponent } from './appointment-card/appointment-all-notes/appointment-all-notes.component';
import { AppointmentAutomationsSummaryComponent } from './appointment-card/appointment-automations-summary/appointment-automations-summary.component';
import { AppointmentCardActionsModule } from './appointment-card/appointment-card-actions/appointment-card-actions.module';
import { AppointmentCardComponent } from './appointment-card/appointment-card.component';
import { AppointmentChecklistItemsComponent } from './appointment-card/appointment-checklist-items/appointment-checklist-items.component';
import { AppointmentInvoiceComponent } from './appointment-card/appointment-invoice/appointment-invoice.component';
import { AppointmentLabJobsComponent } from './appointment-card/appointment-lab-jobs/appointment-lab-jobs.component';
import { AppointmentMenuComponent } from './appointment-card/appointment-menu/appointment-menu.component';
import { AppointmentPatientNotesComponent } from './appointment-card/appointment-patient-notes/appointment-patient-notes.component';
import { AppointmentPracticeComponent } from './appointment-card/appointment-practice/appointment-practice.component';
import { AppointmentPractitionerComponent } from './appointment-card/appointment-practitioner/appointment-practitioner.component';
import { AppointmentSchedulingNotesComponent } from './appointment-card/appointment-scheduling-notes/appointment-scheduling-notes.component';
import { AppointmentStatusComponent } from './appointment-card/appointment-status/appointment-status.component';
import { AppointmentUpcomingComponent } from './appointment-card/appointment-upcoming/appointment-upcoming.component';
import { EventDetailsComponent } from './appointment-card/event-details/event-details.component';
import { AppointmentChartingComponent } from './appointment-charting/appointment-charting.component';
import { AppointmentSchedulingHeaderComponent } from './appointment-complete/configure-scheduling/appointment-scheduling-header/appointment-scheduling-header.component';
import { AppointmentHeaderComponent } from './appointment-header/appointment-header.component';
import { AppointmentHistoryCardAutomationsComponent } from './appointment-history-card/appointment-history-card-automations/appointment-history-card-automations.component';
import { AppointmentHistoryCardChecklistComponent } from './appointment-history-card/appointment-history-card-checklist/appointment-history-card-checklist.component';
import { AppointmentHistoryCardHeaderComponent } from './appointment-history-card/appointment-history-card-header/appointment-history-card-header.component';
import { AppointmentHistoryCardInvoiceComponent } from './appointment-history-card/appointment-history-card-invoice/appointment-history-card-invoice.component';
import { AppointmentHistoryCardNotesComponent } from './appointment-history-card/appointment-history-card-notes/appointment-history-card-notes.component';
import { AppointmentHistoryCardPinnedNotesComponent } from './appointment-history-card/appointment-history-card-pinned-notes/appointment-history-card-pinned-notes.component';
import { AppointmentHistoryCardTreatmentComponent } from './appointment-history-card/appointment-history-card-treatments/appointment-history-card-treatment/appointment-history-card-treatment.component';
import { AppointmentHistoryCardTreatmentsComponent } from './appointment-history-card/appointment-history-card-treatments/appointment-history-card-treatments.component';
import { AppointmentHistoryCardComponent } from './appointment-history-card/appointment-history-card.component';
import { AppointmentImagingComponent } from './appointment-imaging/appointment-imaging.component';
import { AppointmentInteractionsDialogComponent } from './appointment-interactions-dialog/appointment-interactions-dialog.component';
import { AppointmentInteractionsComponent } from './appointment-interactions/appointment-interactions.component';
import { AppointmentPopoverComponent } from './appointment-popover/appointment-popover.component';
import { AppointmentStatusWorkflowComponent } from './appointment-status-workflow/appointment-status-workflow.component';
import { AppointmentAlertsComponent } from './appointment-view-sidebar/appointment-alerts/appointment-alerts.component';
import { AppointmentAllNotesSidebarComponent } from './appointment-view-sidebar/appointment-all-notes-sidebar/appointment-all-notes-sidebar.component';
import { AppointmentAutomationsSummarySidebarComponent } from './appointment-view-sidebar/appointment-automations-summary-sidebar/appointment-automations-summary-sidebar.component';
import { AppointmentChecklistStatusSidebarComponent } from './appointment-view-sidebar/appointment-checklist-status-sidebar/appointment-checklist-status-sidebar.component';
import { AppointmentConflictsSidebarComponent } from './appointment-view-sidebar/appointment-conflicts-sidebar/appointment-conflicts-sidebar.component';
import { AppointmentDurationWarningSidebarComponent } from './appointment-view-sidebar/appointment-duration-warning-sidebar/appointment-duration-warning-sidebar.component';
import { AppointmentQuickActionsComponent } from './appointment-view-sidebar/appointment-quick-actions/appointment-quick-actions.component';
import { AppointmentSchedulingRulesConflictSidebarComponent } from './appointment-view-sidebar/appointment-scheduling-rules-conflict-sidebar/appointment-scheduling-rules-conflict-sidebar.component';
import { AppointmentTreatmentSidebarComponent } from './appointment-view-sidebar/appointment-treatment-sidebar/appointment-treatment-sidebar.component';
import { AppointmentViewSidebarComponent } from './appointment-view-sidebar/appointment-view-sidebar.component';
import { EventDetailsSidebarComponent } from './appointment-view-sidebar/event-details-sidebar/event-details-sidebar.component';
import { EventParticipantsDisplayComponent } from './appointment-view-sidebar/event-participants-display/event-participants-display.component';
import { ScheduleAppointmentComponent } from './schedule-appointment/schedule-appointment.component';
import { AppointmentAutomationsDialogComponent } from './scheduling/appointment-automations-dialog/appointment-automations-dialog.component';
import { AppointmentAutomationsRescheduleComponent } from './scheduling/appointment-automations-reschedule/appointment-automations-reschedule.component';
import { AppointmentAutomationsComponent } from './scheduling/appointment-automations/appointment-automations.component';
import { AppointmentCreateSidebarComponent } from './scheduling/appointment-create-sidebar/appointment-create-sidebar.component';
import { SchedulingNotesTabComponent } from './scheduling/appointment-create-sidebar/scheduling-notes-tab/scheduling-notes-tab.component';
import { SchedulingPatientTabComponent } from './scheduling/appointment-create-sidebar/scheduling-patient-tab/scheduling-patient-tab.component';
import { AppointmentDetailsSidebarComponent } from './scheduling/appointment-details-sidebar/appointment-details-sidebar.component';
import { AppointmentDetailsComponent } from './scheduling/appointment-details/appointment-details.component';
import { AppointmentOptionsPlaceholderComponent } from './scheduling/appointment-options-placeholder/appointment-options-placeholder.component';
import { AppointmentOptionsComponent } from './scheduling/appointment-options/appointment-options.component';
import { AppointmentRequestsListComponent } from './scheduling/appointment-requests-list/appointment-requests-list.component';
import { AppointmentScoreComponent } from './scheduling/appointment-score/appointment-score.component';
import { AppointmentSearchTypeSelectComponent } from './scheduling/appointment-search-type-select/appointment-search-type-select.component';
import { AppointmentSelectorDialogComponent } from './scheduling/appointment-selector-dialog/appointment-selector-dialog.component';
import { AppointmentSelectorFormComponent } from './scheduling/appointment-selector-form/appointment-selector-form.component';
import { AppointmentSelectorComponent } from './scheduling/appointment-selector/appointment-selector.component';
import { AppointmentSidebarComponent } from './scheduling/appointment-sidebar/appointment-sidebar.component';
import { AppointmentSuggestionTooltipComponent } from './scheduling/appointment-suggestion-tooltip/appointment-suggestion-tooltip.component';
import { AppointmentTagsSidebarListComponent } from './scheduling/appointment-tags-sidebar-list/appointment-tags-sidebar-list.component';
import { AppointmentTreatmentSelectorComponent } from './scheduling/appointment-treatment-selector/appointment-treatment-selector.component';
import { ChecklistFormDialogComponent } from './scheduling/checklist-form-dialog/checklist-form-dialog.component';
import { ChecklistFormComponent } from './scheduling/checklist-form/checklist-form.component';
import { ChecklistItemStatusComponent } from './scheduling/checklist-item-status/checklist-item-status.component';
import { ConfirmAppointmentComponent } from './scheduling/confirm-appointment/confirm-appointment.component';
import { ConfirmPatientDialogComponent } from './scheduling/confirm-patient-dialog/confirm-patient-dialog.component';
import { ContextDisplayComponent } from './scheduling/context-display/context-display.component';
import { InboundChecklistComponent } from './scheduling/inbound-checklist/inbound-checklist.component';
import { OutboundChecklistComponent } from './scheduling/outbound-checklist/outbound-checklist.component';
import { PatientDetailsComponent } from './scheduling/patient-details/patient-details.component';
import { PatientFeeScheduleSelectorComponent } from './scheduling/patient-fee-schedule-selector/patient-fee-schedule-selector.component';
import { PatientSummaryComponent } from './scheduling/patient-summary/patient-summary.component';
import { UpdateAppointmentDurationComponent } from './scheduling/update-appointment-duration/update-appointment-duration.component';
import { WaitlistConfigurationDialogComponent } from './scheduling/waitlist-configuration-dialog/waitlist-configuration-dialog.component';
import { WaitlistConfigurationComponent } from './scheduling/waitlist-configuration/waitlist-configuration.component';

@NgModule({
  imports: [
    CommonModule,
    NgSharedModule,
    NgClinicalChartingModule,
    NgAutomationsModule,
    NgInteractionsModule,
    NgCustomFormsModule,
    NgFeatureFlagsModule,
    AppointmentCardActionsModule,
    NgPatientModule,
    NgMediaManagerModule,
    NgEventableModule,
    NgPrincipleSharedModule,
    NgPrincipleAccountingModule,
    NgLabsModule,
    NgIntercomComponentsModule,
    NgCoreModule,
    PerioChartUiComponent,
    PerioChartSettingsComponent,
    AppointmentScoreComponent,
  ],
  declarations: [
    AppointmentInteractionsComponent,
    AppointmentInteractionsDialogComponent,
    AppointmentChartingComponent,
    AppointmentImagingComponent,
    AppointmentSchedulingHeaderComponent,
    AppointmentHeaderComponent,
    AppointmentCardComponent,
    AppointmentLabJobsComponent,
    AppointmentConflictsSidebarComponent,
    AppointmentTreatmentSidebarComponent,
    AppointmentStatusComponent,
    AppointmentSchedulingNotesComponent,
    AppointmentPracticeComponent,
    AppointmentPatientNotesComponent,
    AppointmentUpcomingComponent,
    AppointmentChecklistStatusSidebarComponent,
    AppointmentMenuComponent,
    AppointmentPractitionerComponent,
    AppointmentAllNotesSidebarComponent,
    AppointmentAutomationsSummarySidebarComponent,
    ScheduleAppointmentComponent,
    AppointmentInvoiceComponent,
    AppointmentPopoverComponent,
    AppointmentViewSidebarComponent,
    AppointmentQuickActionsComponent,
    EventParticipantsDisplayComponent,
    AppointmentAlertsComponent,
    AppointmentSchedulingRulesConflictSidebarComponent,
    AppointmentDurationWarningSidebarComponent,
    EventDetailsSidebarComponent,
    EventDetailsComponent,
    AppointmentChecklistItemsComponent,
    AppointmentAllNotesComponent,
    AppointmentAutomationsSummaryComponent,
    AppointmentSelectorFormComponent,
    PatientDetailsComponent,
    ConfirmAppointmentComponent,
    AppointmentOptionsComponent,
    AppointmentSelectorComponent,
    AppointmentSidebarComponent,
    AppointmentDetailsComponent,
    WaitlistConfigurationComponent,
    PatientSummaryComponent,
    WaitlistConfigurationDialogComponent,
    AppointmentAutomationsComponent,
    AppointmentSuggestionTooltipComponent,
    AppointmentSearchTypeSelectComponent,
    UpdateAppointmentDurationComponent,
    AppointmentRequestsListComponent,
    ConfirmPatientDialogComponent,
    ContextDisplayComponent,
    AppointmentTagsSidebarListComponent,
    AppointmentOptionsPlaceholderComponent,
    PatientFeeScheduleSelectorComponent,
    AppointmentCreateSidebarComponent,
    AppointmentSelectorDialogComponent,
    SchedulingNotesTabComponent,
    SchedulingPatientTabComponent,
    AppointmentTreatmentSelectorComponent,
    AppointmentDetailsSidebarComponent,
    InboundChecklistComponent,
    OutboundChecklistComponent,
    ChecklistFormComponent,
    ChecklistFormDialogComponent,
    AppointmentAutomationsDialogComponent,
    ChecklistItemStatusComponent,
    AppointmentAutomationsRescheduleComponent,
    AppointmentStatusWorkflowComponent,
    AppointmentHistoryCardComponent,
    AppointmentHistoryCardHeaderComponent,
    AppointmentHistoryCardAutomationsComponent,
    AppointmentHistoryCardChecklistComponent,
    AppointmentHistoryCardInvoiceComponent,
    AppointmentHistoryCardTreatmentsComponent,
    AppointmentHistoryCardTreatmentComponent,
    AppointmentHistoryCardNotesComponent,
    AppointmentHistoryCardPinnedNotesComponent,
  ],
  exports: [
    AppointmentSchedulingHeaderComponent,
    AppointmentHeaderComponent,
    AppointmentCardActionsModule,
    AppointmentCardComponent,
    AppointmentConflictsSidebarComponent,
    AppointmentMenuComponent,
    AppointmentPractitionerComponent,
    AppointmentUpcomingComponent,
    AppointmentPatientNotesComponent,
    ScheduleAppointmentComponent,
    AppointmentViewSidebarComponent,
    AppointmentAllNotesComponent,
    AppointmentSelectorFormComponent,
    PatientDetailsComponent,
    ConfirmAppointmentComponent,
    AppointmentOptionsComponent,
    AppointmentSelectorComponent,
    AppointmentSidebarComponent,
    AppointmentDetailsComponent,
    WaitlistConfigurationComponent,
    PatientSummaryComponent,
    WaitlistConfigurationDialogComponent,
    AppointmentAutomationsComponent,
    UpdateAppointmentDurationComponent,
    AppointmentRequestsListComponent,
    AppointmentCreateSidebarComponent,
    AppointmentSelectorDialogComponent,
    AppointmentScoreComponent,
    AppointmentTagsSidebarListComponent,
    SchedulingPatientTabComponent,
    AppointmentDetailsSidebarComponent,
    InboundChecklistComponent,
    OutboundChecklistComponent,
    ChecklistFormComponent,
    ChecklistFormDialogComponent,
    ChecklistItemStatusComponent,
    AppointmentAutomationsRescheduleComponent,
    AppointmentQuickActionsComponent,
    AppointmentInteractionsComponent,
    AppointmentStatusWorkflowComponent,
    AppointmentHistoryCardComponent,
    AppointmentHistoryCardHeaderComponent,
    AppointmentHistoryCardAutomationsComponent,
    AppointmentHistoryCardChecklistComponent,
    AppointmentHistoryCardInvoiceComponent,
    AppointmentHistoryCardTreatmentsComponent,
    AppointmentHistoryCardTreatmentComponent,
    AppointmentHistoryCardNotesComponent,
    AppointmentHistoryCardPinnedNotesComponent,
  ],
})
export class ComponentsModule {}
