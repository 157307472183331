<div
  fxLayout="row"
  fxLayoutAlign="start center"
  *ngIf="patient$ | async as patient"
>
  <span
    class="name"
    [ngClass]="{
      'text-red-500': patient.status | map: isDisabledPatient
    }"
    >{{ patient.name }}</span
  >

  @if (patient.status | map: isDisabledPatient) {
    <div>
      <strong>Status: </strong>
      <pr-patient-status [status]="patient.status" />
    </div>
  } @else {
    <span
      fxFlex
      class="details mat-caption truncate"
      *ngIf="age$ | async as age"
    >
      {{ patient.dateOfBirth | moment | amDateFormat: dateOfBirthFormat }}
      ({{ age }} yrs old)
    </span>
    <span class="details mat-caption" *ngIf="phone$ | async as phone">
      {{ phone }}
    </span>
  }
</div>
