import { initVersionedSchema } from '@principle-theorem/editor';
import {
  ClinicalNote,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  FailedDestinationEntityRecord,
  IHasSourceIdentifier,
  ITranslationMap,
  type IClinicalNote,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  INamedDocument,
  ISODateType,
  Timestamp,
  Timezone,
  getError,
  multiFilter,
  reduceToSingleArrayFn,
  resolveSequentially,
  sortTimestamp,
  toISODate,
  toInt,
  toMomentTz,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { groupBy } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import {
  BasePatientClinicalNoteDestinationEntity,
  IClinicalNoteJobData,
  IClinicalNoteMigrationData,
} from '../../../destination/entities/patient-clinical-notes';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { TranslationMapHandler } from '../../../translation-map';
import {
  ICorePracticePatientTreatmentNote,
  ICorePracticePatientTreatmentNoteFilters,
  ICorePracticePatientTreatmentNoteTranslations,
  PatientTreatmentNoteSourceEntity,
} from '../../source/entities/patient-treatment-notes';
import {
  ICorePracticePatient,
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { PROVIDER_RESOURCE_TYPE } from '../../source/entities/providers';
import { CorePracticeItemCodeMappingHandler } from '../mappings/item-codes';
import { CorePracticeStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';

export class PatientClinicalNoteDestinationEntity extends BasePatientClinicalNoteDestinationEntity<ICorePracticePatient> {
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    treatments: new PatientTreatmentNoteSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  customMappings = {
    staff: new CorePracticeStafferMappingHandler(),
    itemCodes: new CorePracticeItemCodeMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean,
    _fromDate?: Timestamp,
    _toDate?: Timestamp,
    fromId?: string,
    toId?: string
  ): Observable<IClinicalNoteJobData<ICorePracticePatient>[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(PROVIDER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);

    return this.sourceEntities.patients
      .getRecords$(
        migration,
        500,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        multiFilter((patient) => {
          if (!fromId || !toId) {
            return true;
          }

          return (
            patient.data.data.id >= toInt(fromId) &&
            patient.data.data.id <= toInt(toId)
          );
        }),
        withLatestFrom(staff$, sourceItemCodes$),
        map(([sourcePatients, staff, sourceItemCodes]) =>
          sourcePatients.map((sourcePatient) => ({
            staff,
            sourceItemCodes,
            sourcePatient,
            destinationEntity,
          }))
        )
      );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IClinicalNoteJobData<ICorePracticePatient>
  ): Promise<
    | IClinicalNoteMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const errorResponseData = {
      label: data.sourcePatient.record.label,
      uid: data.sourcePatient.record.uid,
      ref: data.sourcePatient.record.ref,
    };

    const sourcePatientId = this.sourceEntities.patients
      .getSourceRecordId(data.sourcePatient.data.data)
      .toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        errorResponseData,
        `Couldn't resolve patient`
      );
    }

    try {
      const clinicalNotes = await this._buildClinicalNotes(
        migration,
        translationMap,
        data
      );

      return {
        sourcePatientId,
        patientRef,
        clinicalNotes,
      };
    } catch (error) {
      return this._buildErrorResponse(errorResponseData, getError(error));
    }
  }

  private async _buildClinicalNotes(
    migration: WithRef<IPracticeMigration>,
    translationMap: TranslationMapHandler,
    data: IClinicalNoteJobData<ICorePracticePatient>
  ): Promise<(IClinicalNote & IHasSourceIdentifier)[]> {
    const timezone = migration.configuration.timezone;
    const patientUid = this.sourceEntities.patients.getSourceRecordId(
      data.sourcePatient.data.data
    );
    const clinicalNoteTreatments =
      await this.sourceEntities.treatments.filterRecords(
        migration,
        'patientId',
        patientUid,
        undefined,
        undefined,
        (record) => !!record.data.data.note
      );

    const sorted = clinicalNoteTreatments.sort((treatmentA, treatmentB) =>
      sortTimestamp(
        this._getNoteTimestamp(treatmentA, timezone),
        this._getNoteTimestamp(treatmentB, timezone)
      )
    );
    const userGroupedNotes = groupBy(
      sorted,
      (record) => record.data.data.providerId ?? '0'
    );

    const clinicalNotes = await resolveSequentially(
      Object.entries(userGroupedNotes),
      async ([providerId, userNotes]) => {
        const staffer = await getClinicalNotePractitioner(
          providerId,
          data.staff,
          translationMap
        );
        if (!staffer) {
          throw new Error(`Failed to resolve practitioner code: ${providerId}`);
        }
        const dateGroupedNotes = groupBy(userNotes, (note) =>
          this._getNoteDate(note)
        );

        return Object.values(dateGroupedNotes).map((notes) => {
          const content = notes
            .reduce(
              (
                results: IGetRecordResponse<
                  ICorePracticePatientTreatmentNote,
                  ICorePracticePatientTreatmentNoteTranslations,
                  ICorePracticePatientTreatmentNoteFilters
                >[],
                note
              ) => {
                const existing = results.find(
                  (result) => result.data.data.note === note.data.data.note
                );
                if (!existing) {
                  results.push(note);
                }
                return results;
              },
              []
            )
            .map((note) => note.data.data.note ?? '');

          const noteDate = this._getNoteDate(notes[0]);
          const noteTimestamp = this._getNoteTimestamp(notes[0], timezone);

          return {
            sourceIdentifier: `${notes[0].data.data.patientId}-${noteDate}`,
            ...ClinicalNote.init({
              owner: stafferToNamedDoc(staffer),
              content: initVersionedSchema(content),
              recordDate: noteDate ?? toISODate(toTimestamp()),
              createdAt: noteTimestamp,
              updatedAt: noteTimestamp,
              immutable: true,
            }),
          };
        });
      }
    );
    return clinicalNotes.reduce(reduceToSingleArrayFn, []);
  }

  private _getNoteTimestamp(
    sourceNote: IGetRecordResponse<
      ICorePracticePatientTreatmentNote,
      ICorePracticePatientTreatmentNoteTranslations,
      ICorePracticePatientTreatmentNoteFilters
    >,
    timezone: Timezone
  ): Timestamp {
    const noteDate = this._getNoteDate(sourceNote);
    return noteDate
      ? toTimestamp(toMomentTz(noteDate, timezone))
      : toTimestamp();
  }

  private _getNoteDate(
    sourceNote: IGetRecordResponse<
      ICorePracticePatientTreatmentNote,
      ICorePracticePatientTreatmentNoteTranslations,
      ICorePracticePatientTreatmentNoteFilters
    >
  ): ISODateType | undefined {
    return (
      sourceNote.data.translations.completeDate ??
      sourceNote.data.translations.planDate
    );
  }
}

async function getClinicalNotePractitioner(
  practitionerId: string,
  staff: WithRef<ITranslationMap<IStaffer>>[],
  translationMap: TranslationMapHandler
): Promise<INamedDocument<IStaffer>> {
  const practitionerMap =
    staff.find((staffer) => staffer.sourceIdentifier === practitionerId)
      ?.destinationIdentifier ||
    ((await translationMap.getBySource(practitionerId, PROVIDER_RESOURCE_TYPE))
      ?.destinationIdentifier as DocumentReference<IStaffer> | undefined);

  if (!practitionerMap) {
    throw new Error(`Couldn't find practitioner with id ${practitionerId}`);
  }

  const creator = await Firestore.getDoc(practitionerMap);
  return stafferToNamedDoc(creator);
}
