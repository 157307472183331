<mat-toolbar color="accent">Apply a discount</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column" fxLayoutGap="16px">
    <mat-form-field>
      <span matTextPrefix>$&nbsp;</span>
      <mat-label>Amount</mat-label>
      <input
        matInput
        type="number"
        [min]="0"
        [max]="maxAmount$ | async"
        formControlName="amount"
        [required]="true"
      />
      <mat-hint align="end"
        >Maximum {{ maxAmount$ | async | currency }}</mat-hint
      >
    </mat-form-field>

    <mat-form-field>
      <mat-label>Description</mat-label>
      <input matInput type="text" formControlName="description" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Practitioner</mat-label>
      <mat-select
        formControlName="practitionerRef"
        [compareWith]="comparePractitionerRefFn"
      >
        <mat-option
          *ngFor="
            let practitioner of invoicePractitioners;
            trackBy: trackByPractitionerRef
          "
          [value]="practitioner.practitioner.ref"
        >
          {{ practitioner.practitioner.name }}
        </mat-option>
      </mat-select>
      <mat-hint>Limit the discount to a single practitioner</mat-hint>
    </mat-form-field>

    <ng-container *ngIf="practiceOptions.options$ | async as practices">
      <mat-form-field *ngIf="practices.length > 1">
        <mat-label>Practice</mat-label>
        <mat-select
          [required]="true"
          formControlName="practiceRef"
          [compareWith]="practiceOptions.compareFn"
        >
          <mat-option
            *ngFor="
              let practice of practices;
              trackBy: practiceOptions.trackByFn
            "
            [value]="practice.ref"
          >
            {{ practice.name }}
          </mat-option>
        </mat-select>
        <mat-hint>{{ practiceOptions.hint }}</mat-hint>
      </mat-form-field>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" [disabled]="isDisabled$ | async">
        Submit
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
