<div
  class="container flex flex-col items-center gap-4"
  *ngrxLet="hideToolbar$ as hideToolbar"
>
  <ng-container *ngrxLet="loading$ | async as loading">
    @if (!hideToolbar) {
      <mat-progress-bar *ngIf="loading" mode="indeterminate" />

      <div
        *ngIf="!loading"
        class="mt-2 flex flex-col items-center justify-center"
      >
        <mat-button-toggle-group
          [value]="selectedTool$ | async"
          [hideSingleSelectionIndicator]="true"
          (change)="selectTool($event)"
        >
          @for (tool of tools; track tool) {
            <mat-button-toggle
              [value]="tool"
              [matTooltip]="tool | map: getTooltip$ : this | async"
              [disabled]="tool | map: isDisabledTool$ : this | async"
            >
              <mat-icon>
                {{ tool | map: getIcon : this }}
              </mat-icon>
            </mat-button-toggle>
          }
        </mat-button-toggle-group>
      </div>
    }
  </ng-container>

  <div id="dwv" [ngStyle]="{ cursor: cursorStyle$ | async }">
    <div #dwvDiv [id]="divId$ | async" class="layerGroup"></div>
  </div>
</div>
