<div class="flex">
  <button
    mat-button
    matTooltip="Float Left"
    (click)="imageCommands.setFloat(data.editor, 'left')"
  >
    <mat-icon fontSet="material-symbols">format_image_left</mat-icon>
  </button>
  <button
    mat-button
    matTooltip="Float Right"
    (click)="imageCommands.setFloat(data.editor, 'right')"
  >
    <mat-icon fontSet="material-symbols">format_image_right</mat-icon>
  </button>
  <button
    mat-button
    matTooltip="Align Left"
    (click)="imageCommands.setAlign(data.editor, 'left')"
  >
    <mat-icon fontSet="material-symbols">align_justify_flex_start</mat-icon>
  </button>
  <button
    mat-button
    matTooltip="Align Center"
    (click)="imageCommands.setAlign(data.editor, 'center')"
  >
    <mat-icon fontSet="material-symbols">align_justify_center</mat-icon>
  </button>
  <button
    mat-button
    matTooltip="Align Right"
    (click)="imageCommands.setAlign(data.editor, 'right')"
  >
    <mat-icon fontSet="material-symbols">align_justify_flex_end</mat-icon>
  </button>
  <button
    mat-button
    matTooltip="Reset Layout"
    (click)="imageCommands.resetLayout(data.editor)"
  >
    <mat-icon>restart_alt</mat-icon>
  </button>
  <button
    mat-button
    matTooltip="Fit to width"
    (click)="imageCommands.fitToWidth(data.editor)"
  >
    <mat-icon svgIcon="pt-fit-width" />
  </button>
</div>
