import { type JSONSchema, StorageMap } from '@ngx-pwa/local-storage';
import { snapshot, filterUndefined } from '@principle-theorem/shared';
import { isEqual, uniqWith, xorWith } from 'lodash';
import { type Observable, of, Subject, merge } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { type IUser } from '@principle-theorem/principle-core/interfaces';

export interface IUserSession {
  name: string;
  email: string;
  orgUid: string;
}

const storageSchema: JSONSchema = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
      },
      email: {
        type: 'string',
      },
      orgUid: {
        type: 'string',
      },
    },
  },
};

export class UserSessionsBloc {
  private _cacheChanged$: Subject<void> = new Subject();
  private _cacheKey = 'userSessions';
  userSessions$: Observable<IUserSession[]>;

  constructor(private _storage: StorageMap) {
    this.userSessions$ = this._initUserSessions$();
  }

  async addUser(user: IUser, orgUid: string): Promise<void> {
    const userData: IUserSession = {
      name: user.name,
      email: user.email,
      orgUid,
    };
    return snapshot(
      this.userSessions$.pipe(
        map((sessions: IUserSession[]) => [...sessions, userData]),
        switchMap((sessions: IUserSession[]) =>
          this._storage
            .set(this._cacheKey, uniqWith(sessions, isEqual), storageSchema)
            .toPromise()
        ),
        tap(() => this._cacheChanged$.next())
      )
    );
  }

  async removeUser(user: IUser, orgUid: string): Promise<void> {
    const userData: IUserSession = {
      name: user.name,
      email: user.email,
      orgUid,
    };
    return snapshot(
      this.userSessions$.pipe(
        map((sessions: IUserSession[]) =>
          xorWith(sessions, [userData], isEqual)
        ),
        switchMap((sessions: IUserSession[]) =>
          this._storage.set(this._cacheKey, sessions, storageSchema).toPromise()
        ),
        tap(() => this._cacheChanged$.next())
      )
    );
  }

  async clearCache(): Promise<void> {
    return snapshot(
      this._storage
        .delete(this._cacheKey)
        .pipe(tap(() => this._cacheChanged$.next()))
    );
  }

  private _initUserSessions$(): Observable<IUserSession[]> {
    return merge(this._cacheChanged$, of(undefined)).pipe(
      switchMap(() =>
        this._storage.has(this._cacheKey).pipe(
          switchMap((hasKey: boolean) => {
            if (!hasKey) {
              return of([]);
            }
            return this._storage
              .get<IUserSession[]>(this._cacheKey, storageSchema)
              .toPromise();
          })
        )
      ),
      filterUndefined()
    );
  }
}
