import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { type ThemePalette } from '@angular/material/core';
import {
  FeatureVisibility,
  type IFeature,
} from '@principle-theorem/feature-flags';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-sidebar-link',
    templateUrl: './sidebar-link.component.html',
    styleUrls: ['./sidebar-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SidebarLinkComponent {
  private _visibility: FeatureVisibility;
  badgeColor: ThemePalette = 'warn';
  badge$ = new ReplaySubject<number | string>(1);

  @Input() expanded = false;
  @Input() label = 'label';
  @Input() icon = 'person';

  @Input()
  set badge(badge: number) {
    const amount = coerceNumberProperty(badge);
    if (amount >= 10) {
      this.badge$.next(`${amount}+`);
      return;
    }
    this.badge$.next(amount);
  }

  @Input()
  set feature(feature: IFeature) {
    if (!feature) {
      return;
    }
    this._visibility = feature.visibility;
  }

  get isAlpha(): boolean {
    return this._visibility === FeatureVisibility.Alpha;
  }

  get isDev(): boolean {
    return this._visibility === FeatureVisibility.Dev;
  }
}
