import { User } from '@principle-theorem/principle-core';
import {
  FailedDestinationEntityRecord,
  ServiceTypeModality,
  SourceEntityRecordStatus,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IPracticeMigration,
  IDestinationEntityJobRunOptions,
  IGetRecordResponse,
  ITranslationMap,
  IStaffer,
  IOrganisation,
  IBrand,
  IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { snapshotCombineLatest, type WithRef } from '@principle-theorem/shared';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  BaseStafferDestinationEntity,
  IStafferMigrationData,
} from '../../../destination/entities/staff';
import { TranslationMapHandler } from '../../../translation-map';
import {
  StafferSourceEntity,
  type IExactStaffer,
} from '../../source/entities/staff';
import { ExactStafferMappingHandler } from '../mappings/staff';
import { ExactStaffToUserMappingHandler } from '../mappings/staff-to-user';
import {
  StaffToPracticeMapping,
  resolveExactStaffLocation,
} from '../mappings/practitioner-to-practice-mapping';
import { Observable, combineLatest } from 'rxjs';
import { PracticeMigration } from '../../../practice-migrations';
import { map } from 'rxjs/operators';

export const STAFF_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: 'staff',
    label: 'Staff',
    description: `
      Exact exported data does not include any provider information except for the occasional 'Dr' title. Due to this, we cannot set any provider data for them.
    `,
  },
});

interface IStafferJobData {
  stafferRecord: IGetRecordResponse<IExactStaffer>;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  organisation: WithRef<IOrganisation>;
  brand: WithRef<IBrand>;
  staffToPractice: WithRef<ITranslationMap<IPractice>>[];
}

export class StafferDestinationEntity extends BaseStafferDestinationEntity<IExactStaffer> {
  destinationEntity = STAFF_DESTINATION_ENTITY;
  stafferSourceEntity = new StafferSourceEntity();
  stafferCustomMapping = new ExactStafferMappingHandler();
  staffToUserCustomMapping = new ExactStaffToUserMappingHandler();

  override sourceEntities = {
    staff: new StafferSourceEntity(),
  };

  customMappings = {
    staff: new ExactStafferMappingHandler(),
    staffToUser: new ExactStaffToUserMappingHandler(),
    staffToPractice: new StaffToPracticeMapping(),
  };

  override buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IStafferJobData[]> {
    const staff$ = this.stafferCustomMapping.getRecords$(translationMapHandler);
    const organisation$ = PracticeMigration.organisation$(migration);
    const brand$ = PracticeMigration.brand$(migration);
    const staffToPractice$ = this.customMappings.staffToPractice.getRecords$(
      translationMapHandler
    );

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.stafferSourceEntity,
        runOptions
      ),
      snapshotCombineLatest([organisation$, brand$, staff$, staffToPractice$]),
    ]).pipe(
      map(([staffRecords, [organisation, brand, staff, staffToPractice]]) =>
        staffRecords.map((stafferRecord) => ({
          stafferRecord,
          organisation,
          brand,
          staff,
          staffToPractice,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IStafferJobData
  ): Promise<
    | IStafferMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    if (data.stafferRecord.record.status === SourceEntityRecordStatus.Invalid) {
      return this._buildErrorResponse(
        data.stafferRecord.record,
        'Source staffer is invalid'
      );
    }

    const sourceStafferId = this.sourceEntities.staff.getSourceRecordId(
      data.stafferRecord.data.data
    );
    const practice = await resolveExactStaffLocation(
      sourceStafferId,
      translationMap,
      data.staffToPractice,
      migration.configuration.practices
    );
    if (!practice) {
      return this._buildErrorResponse(
        data.stafferRecord.record,
        'Staffer location could not be resolved'
      );
    }

    const user = User.init({
      name: this.getName(data.stafferRecord.data.data),
      email: data.stafferRecord.data.data.email,
      practices: [practice.ref],
      brands: [migration.configuration.brand.ref],
      isEnabled: false,
    });

    return {
      sourceStafferId,
      stafferDetails: {
        providerNumber: '',
        providerModality: ServiceTypeModality.GeneralDentist,
      },
      // Need to find out why we were using initials
      // sourceStafferInitials: this.getStafferInitials(
      //   data.stafferRecord.data.data
      // ),
      user,
    };
  }

  getName(data: IExactStaffer): string {
    const name = `${data.title ?? ''} ${data.first_name ?? ''} ${
      data.middle_name ?? ''
    } ${data.surname ?? ''}`;

    if (name.length === 0) {
      return data.id;
    }
    return name;
  }

  getStafferInitials(data: IExactStaffer): string {
    const firstInitial = data.first_name
      ? data.first_name[0].toUpperCase()
      : '';
    const middleInitial = data.middle_name
      ? data.middle_name[0].toUpperCase()
      : '';
    const lastInitial = data.surname ? data.surname[0].toUpperCase() : '';

    return `${firstInitial}${middleInitial}${lastInitial}`;
  }
}
