<mat-toolbar color="accent">Edit Treatment Plan Step</mat-toolbar>

<form [formGroup]="form">
  <mat-dialog-content>
    <div class="flex flex-col items-stretch">
      <div class="flex gap-4">
        <mat-form-field class="flex-1">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>

        <mat-form-field formGroupName="schedulingRules" floatLabel="always">
          <mat-label>Duration</mat-label>
          <input
            matInput
            type="number"
            min="0"
            maxlength="3"
            [placeholder]="stepDuration$ | async"
            formControlName="duration"
          />
          <span matTextSuffix>mins</span>
        </mat-form-field>
      </div>

      <div
        *ngIf="canSetSchedulingRequirements"
        formGroupName="schedulingRules"
        class="flex flex-col"
      >
        <h3>Scheduling Requirements</h3>

        <div class="mat-caption">
          <p>
            Here you can specify if the step needs to be booked a certain time
            after the previous step. A step can be booked after the previous in
            several ways:
          </p>
          <ul class="list-inside list-disc">
            <li>At least X days after</li>
            <li>At most X days after</li>
            <li>Or both</li>
          </ul>
        </div>

        <div class="mt-4 flex gap-4">
          <mat-form-field class="flex-1">
            <mat-label>Min Days</mat-label>
            <input
              matInput
              type="number"
              min="0"
              maxlength="3"
              formControlName="minDays"
            />
          </mat-form-field>

          <mat-form-field class="flex-1">
            <mat-label>Max Days</mat-label>
            <input
              matInput
              type="number"
              [min]="maxDaysLowerLimit"
              maxlength="3"
              formControlName="maxDays"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</form>

<mat-dialog-actions>
  <pt-buttons-container padding="none" *ngIf="data$ | async as data">
    <button mat-stroked-button mat-dialog-close>Close</button>
    <button
      *ngIf="canSchedule$ | async"
      mat-stroked-button
      (click)="generateAppointment(data)"
      matTooltip="Allow Front Desk to book this in"
    >
      Enable Scheduling
    </button>
    <button
      *ngIf="canDisable$ | async"
      mat-stroked-button
      (click)="removeAppointment(data)"
      matTooltip="Don't allow Front Desk to book this in"
    >
      Disable Scheduling
    </button>
    <button mat-flat-button color="primary" (click)="submit(data)">Save</button>
  </pt-buttons-container>
</mat-dialog-actions>
