import { Injectable, type OnDestroy } from '@angular/core';
import { AuthService } from '@principle-theorem/ng-auth';
import {
  type IManagementUser,
  RootCollection,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  asColRef,
  find$,
  shareReplayHot,
} from '@principle-theorem/shared';
import { type CollectionReference, where } from '@principle-theorem/shared';
import { type Observable, Subject, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class ManagementService implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  user$: Observable<WithRef<IManagementUser> | undefined>;
  usersCol: CollectionReference<IManagementUser>;

  constructor(private _auth: AuthService) {
    this.usersCol = asColRef<IManagementUser>(RootCollection.ManagementUsers);
    this.user$ = this._auth.claims$.pipe(
      switchMap((claims) =>
        claims && claims.isManagement
          ? this._toManagementUser$()
          : of(undefined)
      ),
      shareReplayHot(this._onDestroy$)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _toManagementUser$(): Observable<
    WithRef<IManagementUser> | undefined
  > {
    return this._authUserEmail$().pipe(
      switchMap((email) => {
        if (!email) {
          return of(undefined);
        }
        return find$(this.usersCol, where('email', '==', email));
      })
    );
  }

  private _authUserEmail$(): Observable<string | undefined> {
    return this._auth.authUser$.pipe(
      map((authUser) =>
        authUser && authUser.email ? authUser.email : undefined
      )
    );
  }
}
