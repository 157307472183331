/* eslint-disable no-null/no-null */
import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_FORMAT,
  TypeGuard,
  isEnumValue,
  isISODateType,
  toTimestamp,
  type ISODateType,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNull, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { PATIENT_CLINICAL_NOTE_DESTINATION_ENTITY } from '../../../destination/entities/consts';
import { PATIENT_CLINICAL_CHART_RESOURCE_TYPE } from '../../../destination/entities/patient-clinical-charts';
import { PATIENT_DEPOSIT_RESOURCE_TYPE } from '../../../destination/entities/patient-deposits';
import { PATIENT_INTERACTION_DESTINATION_ENTITY } from '../../../destination/entities/patient-interactions';
import { PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY } from '../../../destination/entities/patient-treatment-plans';
import { STERILISATION_RECORD_DESTINATION_ENTITY } from '../../../destination/entities/sterilisation-records';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { PATIENT_FILE_RESOURCE_TYPE } from '../../destination/entities/patient-files';
import { PATIENT_MEDIA_SUITE_FILE_RESOURCE_TYPE } from '../../destination/entities/patient-media-suite-files';
import { PATIENT_PROFILE_PHOTO_DESTINATION_ENTITY } from '../../destination/entities/patient-profile-photos';
import { PATIENT_RELATIONSHIP_DESTINATION_ENTITY } from '../../destination/entities/patient-relationships';
import { PATIENT_DESTINATION_ENTITY } from '../../destination/entities/patients';
import { cleanObjectStrings } from './lib/conversion-helpers';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import { PATIENT_APPOINTMENT_RESOURCE_TYPE } from '../../../destination/entities/patient-appointments';
import { EXISTING_PATIENT_DESTINATION_ENTITY } from '../../../destination/entities/existing-patient';

export const PATIENT_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient List',
    description: '',
    idPrefix: PATIENT_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ID4WPatientTranslations {
  dob?: Timestamp;
  first_presented_date?: Timestamp;
}

export interface ID4WPatientFilters {
  familyHeadId: string;
  firstPresentedDate?: Timestamp;
}

export enum D4WPatientGender {
  Male = 'M',
  Female = 'F',
  Other = '',
}

export interface ID4WPatient {
  patient_id: number;
  patients_cart_num: string | null; // alternate patient Id in the case of a merged database
  surname: string;
  firstname: string;
  middlename: string | null;
  preferred_name: string | null;
  dob: ISODateType | null; // YYYY-MM-DD
  address_1: string | null;
  suburb: string | null;
  state: string | null;
  postcode: string | number | null;
  address_2: string | null;
  phone_home: string | null;
  phone_work: string | null;
  mobile: string | null;
  occupation: string | null;
  company_name: string | null;

  // A third of the records don't have a first presented date.
  first_presented_date: string | null; // YYYY-MM-DD

  // Only 9 records have a Y with 181 blank.
  accept_fund_only: boolean; // Y | N
  family_head_id: number; // patient_id of head family member
  provider_id: number | null; // ! Not sure what table this links to
  send_acc_to_pat_id: number | null;
  send_recall_to_pat_id: number | null;
  practice_fee_level_id: number | null;

  // This should be added to patient notes.
  marketing_details: string;

  // Need to clarify what this would be used for.
  provider_other_id: string | number | null;

  // 3424 patients don't have an email
  email: string | null;

  // 70 uses of this with the value 'D'. Not sure what it's used for.
  ref_status: string | null; // D | ?

  // 826 undefined gender values
  patient_sex: 'M' | 'F' | null;

  // 1 at 50%, 2 at 10%, the rest are 0%.
  discount_def: string; // 0.0000

  // Need to find the mapping for this.
  title_id: number; // 0 - 10

  // reminder_type_id: number | null; // 2 | 1 | 0 | ''
  address_1_extent: string | null;
  practice_id: number; // 1 | 4

  // Referral source from patients_referral_sources table
  source_code: string | null;
  // This is just a manual field. Need to look whether we can map them to a referral source.
  referred_by: string | null;
  // This may be an id ref to the referring patient_id
  refsource_id: string | number | null;
  patient_referrer_id: number | null;
  staff_referrer_id: number | null;
  third_party_referrer_id: string | number | null;

  // 100 medicare records stored as a string with some text. Would need a manual mapping to our medicare.
  medicare_id: string | null;

  // 193 used. Not sure what it maps to.
  ob_id: number | string | null;

  // 185 used. Not sure what this mobile is for.
  ob_mobile: string | null;
  // send_query_to_pat_id: number | null;
  // Looks like it's the preferred contact number
  // correct_phone: string | null;
  // send_appt_to_pat_id: number | null;
}

export interface ID4WPatientAdditionalFields {
  medical_history: string | null; // Not used
  dental_history: string | null; // Not used
  hepb_hiv: string | null; // Not used. Only 5 records

  // 24 of these have an N, the rest are blank so all are a "no".
  tutorial_patient: string | null; // ?

  // 301 records with a true value.
  treatment_completed: boolean; // 1 as true, blank as false

  // Never used.
  send_acc_to_third_party_id: number | null;

  // All accounts are '1'
  deafault_address_account: boolean; // 1 as true, not sure for false

  // All accounts are '1'
  deafault_address_recalls: boolean; // 1 as true, not sure for false

  // Not sure what this is used for
  post_id_1: number | null;

  // This isn't used.
  post_id_2: number | null;

  // This isn't used.
  print_rebates: string | null;

  // Only 15 uses of this.
  account_name: string | null;

  // Only 15 uses of this.
  bank_name: string | null;

  // Only 15 uses of this.
  branch_name: string | null;

  // This isn't used.
  other_notes: string | null;

  // 70 uses of this. Not sure what it's used for.
  ref_number: number | null;

  // This isn't used.
  occlusion_id: string | null;
  tax_file_no: string | null;
  contract_num: string | null;
  contract_start: string | null;
  contract_end: string | null;
  ethnic_id: string | null; // null | ''
  mb_provider_id: string | null;
  address_2_extent: string | null;
  guardian_only: boolean; // N | Y
  transport: string | null;
  card_lock: string | null;

  // Only 0 values.
  inactive: boolean; // 0
  number: null;
  serial: null;
  name_org: null;
  date_give_out: null;
  patients_hist_num: string;
  send_recall_to_third_party_id: null;
  patient_region_id: null;
  vip: number; // 0 | 1
  school_id: null;
  jrn_id: null;
  phone_home_extent: string | null;
  phone_work_extent: string | null;
  mobile_extent: string | null;
  email_extent: string | null;
  contact_name: string | null;
  email_property_id: number;
  auto_message: boolean; // N | Y
  is_overtime_work: boolean; // 0 | 1

  // hl7 is a health standard
  hl7_update: string; // YYYY-MM-DD HH:MM:SS.SSS

  // Not used
  hl7_patient_id: null;

  // Only 30 or so using this.
  visit_category: null;
  card_data_sent: boolean; // 0 | 1
  x_mas: null;
  emirates_id_number: null;
  cc_copy: boolean; // 0 | 1
  send_query_to_third_party_id: number | null;
  default_address_queries: boolean; // 1 | ''
  bc_id: null;
  bc_valid_till: null;
  idt_id: null;
  snils: null;
  disability_group: boolean; // 0 | 1
  disgroup_first: boolean; // 0 | 1
  dissince_child: boolean; // 0 | 1
  fsd_check: boolean; // 0 | 1
  curator_id: string; // line break
}

export function isD4WPatient(item: unknown): item is ID4WPatient {
  return TypeGuard.interface<ID4WPatient>({
    patient_id: isNumber,
    patients_cart_num: [isString, isNull],
    surname: isString,
    firstname: isString,
    middlename: [isString, isNull],
    preferred_name: [isString, isNull],
    dob: [isISODateType, isNull],
    address_1: [isString, isNull],
    suburb: [isString, isNull],
    state: [isString, isNull],
    postcode: [isNumber, isString, isNull],
    address_2: [isString, isNull],
    phone_home: [isString, isNull],
    phone_work: [isString, isNull],
    mobile: [isString, isNull],
    occupation: [isString, isNull],
    company_name: [isString, isNull],
    referred_by: [isString, isNull],
    first_presented_date: [isString, isNull],
    accept_fund_only: [isBoolean, isNull],
    family_head_id: isNumber,
    provider_id: [isNumber, isNull],
    send_acc_to_pat_id: [isNumber, isNull],
    send_recall_to_pat_id: [isNumber, isNull],
    practice_fee_level_id: [isNumber, isNull],
    marketing_details: isString,
    provider_other_id: [isNumber, isString, isNull],
    email: [isString, isNull],
    ref_status: [isString, isNull],
    discount_def: isString,
    title_id: isNumber,
    refsource_id: [isString, isNumber, isNull],
    // reminder_type_id: [isNumber, isNull],
    address_1_extent: [isString, isNull],
    practice_id: isNumber,
    source_code: [isString, isNull],
    patient_referrer_id: [isNumber, isNull],
    staff_referrer_id: [isNumber, isNull],
    third_party_referrer_id: [isString, isNumber, isNull],
    medicare_id: [isString, isNull],
    ob_id: [isNumber, isString, isNull],
    ob_mobile: [isString, isNull],
    // send_query_to_pat_id: [isNumber, isNull],
    // correct_phone: [isString, isNull],
    // send_appt_to_pat_id: [isNumber, isNull],
    patient_sex: [
      isNull,
      (value): value is D4WPatientGender =>
        isEnumValue(D4WPatientGender, value),
    ],
  })(item);
}

const PATIENT_SOURCE_QUERY = `
SELECT
  patient.*,
  post.suburb,
  post.state,
  post.postcode,
  referral_source.patient_referrer_id,
  referral_source.staff_referrer_id,
  referral_source.third_party_referrer_id
FROM (
  SELECT
    patient_id,
    convert_to_text(patients_cart_num) AS patients_cart_num,
    surname,
    firstname,
    NULLIF(middlename, '') AS middlename,
    NULLIF(preferred_name, '') AS preferred_name,
    NULLIF(dob, '') AS dob,
    NULLIF(address_1, '') AS address_1,
    NULLIF(address_2, '') AS address_2,
    NULLIF(phone_home, '') AS phone_home,
    NULLIF(phone_work, '') AS phone_work,
    NULLIF(mobile, '') AS mobile,
    NULLIF(occupation, '') AS occupation,
    NULLIF(company_name, '') AS company_name,
    NULLIF(referred_by, '') AS referred_by,
    NULLIF(first_presented_date, '') AS first_presented_date,
    convert_to_boolean(accept_fund_only) AS accept_fund_only,
    family_head_id,
    provider_id,
    send_acc_to_pat_id,
    send_recall_to_pat_id,
    practice_fee_level_id,
    marketing_details,
    provider_other_id,
    NULLIF(email, '') AS email,
    NULLIF(ref_status, '') AS ref_status,
    NULLIF(UPPER(patient_sex), '') AS patient_sex,
    discount_def,
    title_id,
    refsource_id,
    -- NULLIF(reminder_type_id, '') AS reminder_type_id,
    NULLIF(address_1_extent, '') AS address_1_extent,
    practice_id,
    NULLIF(source_code, '') AS source_code,
    medicare_id::TEXT as medicare_id,
    ob_id,
    NULLIF(ob_mobile::TEXT, '') AS ob_mobile,
    -- send_query_to_pat_id,
    -- NULLIF(correct_phone::TEXT, '') AS correct_phone,
    -- send_appt_to_pat_id AS send_appt_to_pat_id,
    post_id_1
  FROM patients
  WHERE
    patient_id IS NOT NULL AND
    ref_status != 'D'
) AS patient
LEFT JOIN (
  SELECT
    id AS post_id,
    suburb,
    state,
    code AS postcode
  FROM post
) as post
ON patient.post_id_1 = post.post_id
LEFT JOIN (
  SELECT
    refsource_id,
    reftype_code,
    patient_id AS patient_referrer_id,
    convert_to_integer(staff_id) AS staff_referrer_id,
    th_party_id AS third_party_referrer_id
  FROM patients_referral_sources
) as referral_source
ON NULLIF(patient.refsource_id::TEXT, '') = referral_source.refsource_id::TEXT
ORDER BY patient_id DESC
`;

export class PatientSourceEntity extends BaseSourceEntity<
  ID4WPatient,
  ID4WPatientTranslations,
  ID4WPatientFilters
> {
  sourceEntity = PATIENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_SOURCE_QUERY;
  verifySourceFn = isD4WPatient;

  migrationDestinations = [
    PATIENT_CLINICAL_CHART_RESOURCE_TYPE,
    PATIENT_DEPOSIT_RESOURCE_TYPE,
    PATIENT_FILE_RESOURCE_TYPE,
    PATIENT_INTERACTION_DESTINATION_ENTITY.metadata.key,
    PATIENT_MEDIA_SUITE_FILE_RESOURCE_TYPE,
    PATIENT_RELATIONSHIP_DESTINATION_ENTITY.metadata.key,
    PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY.metadata.key,
    PATIENT_DESTINATION_ENTITY.metadata.key,
    STERILISATION_RECORD_DESTINATION_ENTITY.metadata.key,
    PATIENT_CLINICAL_NOTE_DESTINATION_ENTITY.metadata.key,
    PATIENT_PROFILE_PHOTO_DESTINATION_ENTITY.metadata.key,
    EXISTING_PATIENT_DESTINATION_ENTITY.metadata.key,
    PATIENT_APPOINTMENT_RESOURCE_TYPE,
  ];

  override transformDataFn = flow([
    (rows: ID4WPatient[]) => rows.map(cleanObjectStrings),
  ]);

  translate(patient: ID4WPatient, timezone: Timezone): ID4WPatientTranslations {
    return {
      dob:
        patient.dob && patient.dob.toString() !== '0001-01-01'
          ? toTimestamp(
              moment.tz(patient.dob, ISO_DATE_FORMAT, timezone).startOf('day')
            )
          : undefined,
      first_presented_date: patient.first_presented_date
        ? toTimestamp(
            moment
              .tz(patient.first_presented_date, ISO_DATE_FORMAT, timezone)
              .startOf('day')
          )
        : undefined,
    };
  }

  getFilterData(patient: ID4WPatient, timezone: Timezone): ID4WPatientFilters {
    return {
      familyHeadId: patient.family_head_id.toString(),
      firstPresentedDate: patient.first_presented_date
        ? toTimestamp(
            moment
              .tz(patient.first_presented_date, ISO_DATE_FORMAT, timezone)
              .startOf('day')
          )
        : undefined,
    };
  }

  getSourceRecordId(data: ID4WPatient): string | number {
    return data.patient_id;
  }

  getSourceLabel(data: ID4WPatient): string {
    return `${data.firstname} ${data.surname}`;
  }
}
