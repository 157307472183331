import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  FollowUpComponent,
  type IAppointmentFollowUpData,
} from '@principle-theorem/ng-follow-ups';
import {
  CurrentBrandScope,
  DateService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  Appointment,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IPatient,
  isEventable,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import { type Timestamp } from '@principle-theorem/shared';
import {
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  combineLatest,
  from,
  type Observable,
  ReplaySubject,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppointmentInteractionsDialogComponent } from '../appointment-interactions-dialog/appointment-interactions-dialog.component';
import { ChecklistFormDialogComponent } from '../scheduling/checklist-form-dialog/checklist-form-dialog.component';
import {
  type IWaitListConfigurationData,
  WaitlistConfigurationDialogComponent,
} from '../scheduling/waitlist-configuration-dialog/waitlist-configuration-dialog.component';

@Component({
    selector: 'pr-schedule-appointment',
    templateUrl: './schedule-appointment.component.html',
    styleUrls: ['./schedule-appointment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ScheduleAppointmentComponent {
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);
  patient$: Observable<WithRef<IPatient>>;
  required$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  treatmentStep$: Observable<WithRef<ITreatmentStep> | undefined>;
  isRequired$: Observable<boolean>;
  isUnscheduled$: Observable<boolean>;
  followUpDate$: Observable<Timestamp | undefined>;
  hasCancelledFollowUp$: Observable<boolean>;
  hasFollowUp$: Observable<boolean>;
  duration$: Observable<number>;
  @Input() showButtons = false;

  constructor(
    public dateService: DateService,
    public organisation: OrganisationService,
    private _dialog: MatDialog,
    private _brandScope: CurrentBrandScope,
    private _router: Router
  ) {
    this.patient$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.patient$(appointment))
    );

    this.hasFollowUp$ = this.appointment$.pipe(
      map((appointment) => Appointment.hasFollowUp(appointment))
    );

    this.followUpDate$ = this.appointment$.pipe(
      map((appointment) =>
        appointment.activeFollowUp?.createFollowUp
          ? appointment.activeFollowUp.followUpDate
          : undefined
      )
    );

    this.hasCancelledFollowUp$ = this.appointment$.pipe(
      map((appointment) => {
        if (!appointment.activeFollowUp) {
          return false;
        }
        return !appointment.event && !appointment.activeFollowUp.createFollowUp;
      })
    );

    this.isRequired$ = combineLatest([
      this.hasFollowUp$,
      this.hasCancelledFollowUp$,
      this.required$,
    ]).pipe(
      map(
        ([hasFollowup, hasCancelledFollowUp, required]) =>
          required && !hasFollowup && !hasCancelledFollowUp
      )
    );

    this.treatmentStep$ = this.appointment$.pipe(
      switchMap((appointment) =>
        from(TreatmentPlan.treatmentStepForAppointment$(appointment))
      )
    );
    this.isUnscheduled$ = this.appointment$.pipe(
      map((appointment) => Appointment.isUnscheduled(appointment))
    );

    this.duration$ = combineLatest([
      this.appointment$,
      this.treatmentStep$.pipe(
        filterUndefined(),
        switchMap((step) => TreatmentStep.getDuration$(step))
      ),
    ]).pipe(
      map(([appointment, stepDuration]) =>
        isEventable(appointment)
          ? Appointment.duration(appointment)
          : stepDuration
      )
    );
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    this.appointment$.next(appointment);
  }

  @Input()
  set required(required: boolean) {
    this.required$.next(required);
  }

  async cancel(): Promise<void> {
    const path = await this._getAppointmentPath();
    await this._router.navigate([...path, 'manage', 'cancel']);
  }

  async schedule(): Promise<void> {
    const path = await this._getAppointmentPath();
    await this._router.navigate([...path, 'manage', 'reschedule']);
  }

  async followUp(): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    const data: IAppointmentFollowUpData = {
      appointment,
      patient: await snapshot(this.patient$),
    };
    this._dialog.open(FollowUpComponent, DialogPresets.fullscreen({ data }));
  }

  async showNotes(): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    const config: MatDialogConfig = DialogPresets.large({
      height: '80%',
      data: {
        appointment,
        patient: await snapshot(this.patient$),
      },
    });
    this._dialog.open(AppointmentInteractionsDialogComponent, config);
  }

  async openChecklistForm(): Promise<void> {
    const appointment = await snapshot(this.appointment$);

    const config: MatDialogConfig = DialogPresets.medium({
      data: { appointment },
    });
    this._dialog.open(ChecklistFormDialogComponent, config);
  }

  async openWaitlistConfiguration(): Promise<void> {
    const data: IWaitListConfigurationData = {
      appointment: await snapshot(this.appointment$),
    };
    const config: MatDialogConfig = DialogPresets.medium({ data });
    this._dialog.open<
      WaitlistConfigurationDialogComponent,
      IWaitListConfigurationData
    >(WaitlistConfigurationDialogComponent, config);
  }

  private async _getAppointmentPath(): Promise<string[]> {
    const brand = await this._brandScope.toPromise();
    const appointment = await snapshot(this.appointment$);
    const patient = await snapshot(this.patient$);
    return [
      '/',
      brand.slug,
      'patients',
      patient.ref.id,
      'appointments',
      appointment.ref.id,
    ];
  }
}
