import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgAppointmentModule } from '@principle-theorem/ng-appointment';
import { NgAppointmentStoreModule } from '@principle-theorem/ng-appointment/store';
import { NgEventableModule } from '@principle-theorem/ng-eventable';
import { NgGapsModule } from '@principle-theorem/ng-gaps';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { AddCalendarEventComponent } from './add-calendar-event/add-calendar-event.component';
import { CalendarEventEditSidebarComponent } from './calendar-event-edit-sidebar/calendar-event-edit-sidebar.component';
import { CalendarEventFormComponent } from './calendar-event-form/calendar-event-form.component';
import { CalendarEventTimeComponent } from './calendar-event-time/calendar-event-time.component';
import { CalendarEventComponent } from './calendar-event/calendar-event.component';
import { CalendarRangeHeaderComponent } from './calendar-range-header/calendar-range-header.component';
import { CalendarRangeSelectorComponent } from './calendar-range-selector/calendar-range-selector.component';
import { CalendarToolbarComponent } from './calendar-toolbar/calendar-toolbar.component';
import { CalendarViewSelectorComponent } from './calendar-view-selector/calendar-view-selector.component';
import { DashboardAppointmentsComponent } from './dashboard-appointments/dashboard-appointments.component';
import { DashboardTimelineComponent } from './dashboard-timeline/dashboard-timeline.component';
import { EventTypeSelectorComponent } from './dashboard-timeline/event-type-selector/event-type-selector.component';
import { DayTimesComponent } from './day-times/day-times.component';
import { DayComponent } from './day/day.component';
import { EditCalendarEventComponent } from './edit-calendar-event/edit-calendar-event.component';
import { MonthViewComponent } from './month-view/month-view.component';
import { NowLineDirective } from './now-line.directive';
import { ReceptionistDashboardComponent } from './receptionist-dashboard/receptionist-dashboard.component';
import { WeekDayHeaderComponent } from './week-day-header/week-day-header.component';
import { WeekHeaderComponent } from './week-header/week-header.component';
import { WeekRowComponent } from './week-row/week-row.component';
import { WeekViewComponent } from './week-view/week-view.component';
import { TimelineUserSettingsComponent } from './timeline-settings/timeline-user-settings/timeline-user-settings.component';
import { TimelinePracticeSettingsComponent } from './timeline-settings/timeline-practice-settings/timeline-practice-settings.component';

@NgModule({
  declarations: [
    WeekViewComponent,
    DayTimesComponent,
    WeekHeaderComponent,
    WeekDayHeaderComponent,
    MonthViewComponent,
    WeekRowComponent,
    DayComponent,
    CalendarViewSelectorComponent,
    CalendarRangeHeaderComponent,
    CalendarRangeSelectorComponent,
    CalendarEventTimeComponent,
    CalendarEventComponent,
    AddCalendarEventComponent,
    CalendarEventFormComponent,
    EditCalendarEventComponent,
    CalendarToolbarComponent,
    NowLineDirective,
    DashboardAppointmentsComponent,
    DashboardTimelineComponent,
    ReceptionistDashboardComponent,
    EventTypeSelectorComponent,
    CalendarEventEditSidebarComponent,
    TimelineUserSettingsComponent,
    TimelinePracticeSettingsComponent,
  ],
  exports: [
    WeekViewComponent,
    DayTimesComponent,
    WeekHeaderComponent,
    WeekDayHeaderComponent,
    MonthViewComponent,
    WeekRowComponent,
    DayComponent,
    CalendarViewSelectorComponent,
    CalendarRangeHeaderComponent,
    CalendarRangeSelectorComponent,
    CalendarEventTimeComponent,
    CalendarToolbarComponent,
    DashboardAppointmentsComponent,
    DashboardTimelineComponent,
    ReceptionistDashboardComponent,
    EventTypeSelectorComponent,
    TimelineUserSettingsComponent,
    TimelinePracticeSettingsComponent,
  ],
  imports: [
    CommonModule,
    NgMaterialModule,
    NgSharedModule,
    NgInteractionsModule,
    NgPrincipleSharedModule,
    NgAppointmentModule,
    NgAppointmentStoreModule,
    NgEventableModule,
    NgGapsModule,
  ],
})
export class ComponentsModule {}
