import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { type ITreatmentCategory } from '@principle-theorem/principle-core/interfaces';
import {
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, ReplaySubject, type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GlobalStoreService } from '../../store/global-store.service';
import { CommonModule } from '@angular/common';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';

@Component({
  selector: 'pr-treatment-category-indicator',
  templateUrl: './treatment-category-indicator.component.html',
  styleUrls: ['./treatment-category-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NgMaterialModule, NgSharedModule],
})
export class TreatmentCategoryIndicatorComponent {
  private _globalStore = inject(GlobalStoreService);
  category$ = new ReplaySubject<DocumentReference<ITreatmentCategory>>(1);
  treatmentCategory$: Observable<WithRef<ITreatmentCategory> | undefined>;
  displayOnly$ = new BehaviorSubject<boolean>(false);
  @Output() categoryChanged = new EventEmitter<WithRef<ITreatmentCategory>>();

  @Input()
  set category(category: DocumentReference<ITreatmentCategory>) {
    if (category) {
      this.category$.next(category);
    }
  }

  @Input()
  set displayOnly(displayOnly: BooleanInput) {
    this.displayOnly$.next(coerceBooleanProperty(displayOnly));
  }

  constructor() {
    this.treatmentCategory$ = this.category$.pipe(
      switchMap((category) => this._globalStore.getTreatmentCategory$(category))
    );
  }
}
