import { type ICustomFormConfiguration } from './custom-form-config';

export const FORM_FRAMEWORK = 'material-design';

export enum CustomFormType {
  MedicalHistory = 'medical-history-form-config',
}

export interface ICustomFormDataResolverConfig {
  path: string;
  mapValue?: string;
  mapTitle?: string;
}

export type CustomFormDataResolverConfigMap = Record<
  string,
  ICustomFormDataResolverConfig[]
>;

export interface ICustomFormData<T> extends ICustomFormConfiguration {
  data?: T;
}
