import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

interface IMediaActionButton {
  label: string;
  icon: string;
  action: () => void;
  disabled?: boolean;
}

@Component({
  selector: 'pr-media-action-buttons',
  templateUrl: './media-action-buttons.component.html',
  styleUrl: './media-action-buttons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaActionButtonsComponent {
  fileName$ = new ReplaySubject<string>(1);
  isDesktop$: Observable<boolean>;

  @Input() editMode: boolean;
  @Input() saveDisabled: boolean;
  @Input() isDicom: boolean;

  @Input() set fileName(fileName: string) {
    if (fileName) {
      this.fileName$.next(fileName);
    }
  }

  @Output() closeMedia = new EventEmitter<void>();
  @Output() toggleSidenav = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();
  @Output() previous = new EventEmitter<void>();
  @Output() next = new EventEmitter<void>();
  @Output() toggleEditMode = new EventEmitter<void>();

  constructor(private _breakpoint: BreakpointObserver) {
    this.isDesktop$ = this._breakpoint
      .observe(['(min-width: 1080px)'])
      .pipe(map((state) => state.matches));
  }

  get closeButton(): IMediaActionButton {
    return {
      label: 'Close',
      icon: 'close',
      action: () => this.closeMedia.emit(),
    };
  }

  get actionButtons(): IMediaActionButton[] {
    return [
      {
        label: this.editMode ? 'View Mode' : 'Edit Mode',
        icon: this.editMode ? 'visibility' : 'edit',
        disabled: this.isDicom,
        action: () => this.toggleEditMode.emit(),
      },
      {
        label: 'Image Info',
        icon: 'info',
        action: () => this.toggleSidenav.emit(),
      },
      {
        label: 'Save',
        icon: 'save',
        disabled: this.saveDisabled,
        action: () => this.save.emit(),
      },
      {
        label: 'Previous',
        icon: 'skip_previous',
        action: () => this.previous.emit(),
      },
      {
        label: 'Next',
        icon: 'skip_next',
        action: () => this.next.emit(),
      },
    ];
  }
}
