<ng-container *ngrxLet="checklistItems$ as checklistItems">
  <ng-container *ngIf="checklistItems.length">
    <h2>Checklist Items</h2>
    <div fxLayout="column" fxLayoutGap="8px">
      <div *ngFor="let item of checklistItems; trackBy: trackByItem">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="8px"
        >
          <span>{{ item.title }}</span>
          <pr-checklist-item-status [isDone]="item.done" />
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
