<mat-sidenav-container
  #container
  class="!bg-transparent"
  ngClass.gt-xs="large-toolbar"
  fxFill
>
  <mat-sidenav
    [class.!-z-10]="(sidebar.isMobile$ | async) === false"
    [fixedInViewport]="true"
    [mode]="sidebar.mode$ | ngrxPush"
    [opened]="sidebar.opened$ | ngrxPush"
    (closedStart)="sidebar.close()"
  >
    <pr-sidebar
      #sidebarContainer
      (widthChange)="container.updateContentMargins()"
    >
      <pr-sidebar-items [expanded]="sidebar.expanded$ | ngrxPush" />
    </pr-sidebar>
  </mat-sidenav>

  <mat-sidenav-content class="flex-col overflow-x-hidden bg-transparent">
    <pr-toolbar
      *ngrxLet="notificationsOpen$ as notificationsOpen"
      class="fixed inset-x-0 -z-10 mt-0 bg-transparent"
      [class.is-mobile]="sidebar.isMobile$ | ngrxPush"
      [style.padding-left]="sidebarContainer.width$ | async"
      [chatCount]="chatCount$ | ngrxPush"
      [notificationsCount]="notificationsCount$ | ngrxPush"
      (notificationsToggle)="notificationsOpen$.next(!notificationsOpen)"
    />
    <div class="toolbar-spacer -z-50"></div>
    <div
      fxFlex
      class="overflow-hidden rounded-t-lg bg-[#fafafa] print:overflow-visible md:mx-1 md:mb-1 md:rounded-b-lg"
    >
      <pt-dynamic-sidebar>
        <router-outlet />
      </pt-dynamic-sidebar>
    </div>
  </mat-sidenav-content>

  <mat-sidenav
    #sidenav="matSidenav"
    mode="over"
    position="end"
    [opened]="notificationsOpen$ | ngrxPush"
    (openedChange)="notificationsOpen$.next($event)"
    class="!bg-[#fafafa]"
  >
    <ng-container *ngrxLet="notifications$ as notifications">
      <pr-notifications-sidebar
        [notifications]="notifications"
        (closed)="sidenav.close()"
      />
    </ng-container>
  </mat-sidenav>
</mat-sidenav-container>

<pr-popup-notifications />
