import {
  IGetRecordResponse,
  TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import { INamedDocument } from '@principle-theorem/shared';

export const PATIENT_TRANSACTION_RESOURCE_TYPE = 'patientTransaction';

export function buildExtendedData(
  payment: IGetRecordResponse<object>,
  provider: TransactionProvider,
  providerSubType?: INamedDocument
): object {
  const extendedData = {
    payment: payment.data.data,
    providerSubType: providerSubType,
  };

  if (provider === TransactionProvider.AccountCredit) {
    return {
      ...extendedData,
      accountCreditsUsed: [],
    };
  }

  return extendedData;
}
