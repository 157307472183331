import { Injectable } from '@angular/core';
import { IPopupNotification } from '@principle-theorem/notifications';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupNotificationsService {
  private _timeoutMs = 5000;
  private _maxStackSize = 8;
  notifications$ = new BehaviorSubject<IPopupNotification[]>([]);

  addNotification(notification: IPopupNotification): void {
    const currentNotifications = this.notifications$.value.filter(
      (existingNotification) =>
        existingNotification.groupId !== notification.groupId
    );

    if (currentNotifications.length >= this._maxStackSize) {
      currentNotifications.shift();
    }

    currentNotifications.push(notification);
    this.notifications$.next(currentNotifications);

    if (!notification.hasTimeout) {
      return;
    }

    setTimeout(
      () => this.removeNotification(notification.uid),
      this._timeoutMs
    );
  }

  removeNotification(uid: string): void {
    const currentNotifications = this.notifications$.value;
    const index = currentNotifications.findIndex(
      (notification) => notification.uid === uid
    );
    if (index === -1) {
      return;
    }
    currentNotifications.splice(index, 1);
    this.notifications$.next(currentNotifications);
  }
}
