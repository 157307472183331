import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  type IBreadcrumb,
  StorageResponseAPI,
} from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  type ITimePeriod,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { DateRangeDataBuilder } from '../../models/report/data-builders/date-range-data-builder';
import { PractitionerIncomeReportFacade } from '../legacy-reports/practitioner-income/store/practitioner-income.facade';
import { PractitionerIncomeReportType } from '../legacy-reports/practitioner-income/store/practitioner-income.reducers';

@Component({
  selector: 'pr-practitioner-reporting',
  templateUrl: './practitioner-reporting.component.html',
  styleUrls: ['./practitioner-reporting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PractitionerReportingComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  // TODO: Build with timezone - CU-251edxw
  from: moment.Moment = moment().startOf('day').subtract({ months: 1 });
  to: moment.Moment = moment().endOf('day');
  dataBuilder: DateRangeDataBuilder;
  breadcrumbs: IBreadcrumb[] = [{ label: 'Reporting' }];
  brands$: Observable<WithRef<IBrand>[]>;
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);
  isTransactionReport$: Observable<boolean>;

  constructor(
    private _organisation: OrganisationService,
    reportFacade: PractitionerIncomeReportFacade,
    scope: CurrentScopeFacade,
    api: StorageResponseAPI
  ) {
    this.dataBuilder = new DateRangeDataBuilder(
      api,
      this.from,
      this.to,
      this._organisation.brand$.pipe(filterUndefined()),
      this.practice$.asObservable().pipe(map((practice) => [practice]))
    );
    this.brands$ = this._organisation.userBrands$;
    this.isTransactionReport$ = reportFacade.reportType$.pipe(
      map(
        (reportType) =>
          reportType === PractitionerIncomeReportType.ByTransactionDate
      )
    );

    scope.currentPractice$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((practice) => this.practice$.next(practice));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  updateDateRange(dateRange: ITimePeriod): void {
    this.dataBuilder.updateRange(
      toMoment(dateRange.from),
      toMoment(dateRange.to)
    );
  }
}
