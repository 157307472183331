import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ManagementService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { Brand, User } from '@principle-theorem/principle-core';
import {
  IManagementUser,
  IUser,
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  safeCombineLatest,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

interface IBrandPracticeOptions {
  brand: WithRef<IBrand>;
  practices: WithRef<IPractice>[];
}

@Component({
    selector: 'pr-sidebar-practice-selector-dialog',
    templateUrl: './sidebar-practice-selector-dialog.component.html',
    styleUrls: ['./sidebar-practice-selector-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SidebarPracticeSelectorDialogComponent {
  trackByBrand = TrackByFunctions.ref<IBrandPracticeOptions>('brand.ref');
  trackByPractice = TrackByFunctions.ref<WithRef<IPractice>>();
  options$: Observable<IBrandPracticeOptions[]>;
  currentBrand$: Observable<WithRef<IBrand> | undefined>;
  currentPractice$: Observable<WithRef<IPractice> | undefined>;

  constructor(
    private _organisation: OrganisationService,
    private _dialogRef: MatDialogRef<
      SidebarPracticeSelectorDialogComponent,
      WithRef<IPractice>
    >,
    private _management: ManagementService
  ) {
    this.currentBrand$ = this._organisation.brand$;
    this.currentPractice$ = this._organisation.practice$;

    this.options$ = combineLatest([
      this._organisation.userBrands$,
      this._organisation.user$.pipe(filterUndefined()),
      this._management.user$,
    ]).pipe(
      switchMap(([brands, user, managementUser]) =>
        safeCombineLatest(
          brands.map((brand) =>
            this._getBrandPracticeOptions$(brand, user, managementUser)
          )
        )
      )
    );
  }

  updatePractice(brand: WithRef<IBrand>, practice: WithRef<IPractice>): void {
    this._organisation.selectBrand(brand);
    this._organisation.selectPractice(practice);
    this._dialogRef.close(practice);
  }

  private _getBrandPracticeOptions$(
    brand: WithRef<IBrand>,
    user: WithRef<IUser>,
    managementUser?: WithRef<IManagementUser>
  ): Observable<IBrandPracticeOptions> {
    return managementUser
      ? Brand.practices$(brand).pipe(map((practices) => ({ brand, practices })))
      : User.practicesInBrand$(brand, user).pipe(
          map((practices) => ({ brand, practices }))
        );
  }
}
