import { type ICSVExport } from '@principle-theorem/ng-shared';
import { Patient } from '@principle-theorem/principle-core';
import {
  DAY_MONTH_YEAR_FORMAT,
  asyncForEach,
  toMoment,
} from '@principle-theorem/shared';
import { type IReferralSourcesReportRecord } from '../referral-sources-report.store';

export interface IReferralSourcesDrilldownCSV {
  appointmentDate: string;
  patientName: string;
  mobileNumber: string;
  referredBy: string;
  treatmentAmount: number;
}

export class ReferralSourcesDrilldownToCSV
  implements
    ICSVExport<IReferralSourcesReportRecord, IReferralSourcesDrilldownCSV>
{
  defaultFileName = 'referral-sources-drilldown';
  headers = [
    'Appointment Date',
    'Patient',
    'Mobile Number',
    'Referred By',
    'Treatment Amount',
  ];

  async translate(
    records: IReferralSourcesReportRecord[]
  ): Promise<IReferralSourcesDrilldownCSV[]> {
    return asyncForEach(records, async (record) => ({
      appointmentDate: toMoment(record.appointment.event.from).format(
        DAY_MONTH_YEAR_FORMAT
      ),
      patientName: record.patient.name,
      mobileNumber: (await Patient.getMobileNumber(record.patient)) ?? '',
      referredBy: record.patient.referrer?.name ?? '',
      treatmentAmount: record.treatmentAmount,
    }));
  }
}
