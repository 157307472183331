import { Injectable } from '@angular/core';
import {
  httpsCallable,
  type ICheckOrganisationExists,
  type IUserWorkspace,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { UserRecord } from 'firebase-admin/auth';

interface IInviteUserData {
  orgUid: string;
  userEmail: string;
}

interface IHasEmail {
  email: string;
}

interface IUpdateUserEmail {
  oldEmail: string;
  newEmail: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthFirebaseFunctionsService {
  async sendOrganisationInviteEmail<T>(
    organisation: WithRef<T>,
    user: IHasEmail
  ): Promise<undefined> {
    return this._call<IInviteUserData, undefined>('http-auth-inviteUser', {
      orgUid: organisation.ref.id,
      userEmail: user.email,
    });
  }

  async setAuthClaims(): Promise<void> {
    return this._call<undefined, void>('http-auth-setAuthClaimsV2', undefined);
  }

  async checkOrganisationExists(workspace: string): Promise<boolean> {
    return this._call<ICheckOrganisationExists, boolean>(
      'http-auth-checkOrganisationExists',
      {
        workspace,
      }
    );
  }

  async updateUserEmail(
    oldEmail: string,
    newEmail: string
  ): Promise<UserRecord | undefined> {
    return this._call<IUpdateUserEmail, UserRecord | undefined>(
      'http-auth-updateUserEmail',
      {
        oldEmail,
        newEmail,
      }
    );
  }

  async getUserWorkspaces(): Promise<IUserWorkspace[]> {
    try {
      return await this._call<void, IUserWorkspace[]>(
        'http-auth-getUserWorkspacesV2',
        undefined
      );
    } catch (error) {
      return [];
    }
  }

  async revokeAuthSessions(): Promise<void> {
    return this._call<undefined, void>(
      'http-auth-revokeAuthSessions',
      undefined
    );
  }

  private async _call<T, R>(name: string, requestData: T): Promise<R> {
    return snapshot(httpsCallable<T, R>(name)(requestData));
  }
}
