import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  isEnumValue,
  isObject,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { cleanObjectStrings } from './lib/conversion-helpers';
import { ID4WCommunicationType } from './patient-communication';

export const PATIENT_COMMUNICATION_REPLY_RESOURCE_TYPE =
  'patientCommunicationReply';

export const PATIENT_COMMUNICATION_REPLY_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Communication Reply List',
      description: '',
      idPrefix: PATIENT_COMMUNICATION_REPLY_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ID4WPatientCommunicationReply {
  id: number;
  server_id: number;
  patient_id: number;
  practice_id: number;
  mobile_number: string;
  content: string;
  created_at: string;
  type: ID4WCommunicationType;
}

export function isD4WPatientCommunicationReply(
  item: unknown
): item is ID4WPatientCommunicationReply {
  return (
    isObject(item) &&
    isNumber(item.id) &&
    isNumber(item.server_id) &&
    isNumber(item.patient_id) &&
    isNumber(item.practice_id) &&
    isString(item.mobile_number) &&
    isString(item.content) &&
    isString(item.type) &&
    isEnumValue(ID4WCommunicationType, item.type) &&
    isString(item.created_at)
  );
}

export interface ID4WPatientCommunicationReplyTranslations {
  createdAt: Timestamp;
}

export interface ID4WPatientCommunicationReplyFilters {
  practiceId: string;
  patientId: string;
  createdAt: Timestamp;
}

const COMMUNICATION_REPLY_SOURCE_QUERY = `
SELECT
  reply_id AS id,
  server_id,
  patient_id,
  practice_id,
  patient_phone::TEXT AS mobile_number,
  text_reply AS content,
  date_reply AS created_at,
  type_message AS type
FROM patients_replies
WHERE patient_id IS NOT NULL
`;

export class PatientCommunicationReplySourceEntity extends BaseSourceEntity<
  ID4WPatientCommunicationReply,
  ID4WPatientCommunicationReplyTranslations,
  ID4WPatientCommunicationReplyFilters
> {
  sourceEntity = PATIENT_COMMUNICATION_REPLY_SOURCE_ENTITY;
  entityResourceType = PATIENT_COMMUNICATION_REPLY_RESOURCE_TYPE;
  sourceQuery = COMMUNICATION_REPLY_SOURCE_QUERY;
  verifySourceFn = isD4WPatientCommunicationReply;
  override dateFilterField: keyof ID4WPatientCommunicationReplyFilters =
    'createdAt';

  override transformDataFn = flow([
    (rows: ID4WPatientCommunicationReply[]) => rows.map(cleanObjectStrings),
  ]);

  translate(
    appointment: ID4WPatientCommunicationReply,
    timezone: Timezone
  ): ID4WPatientCommunicationReplyTranslations {
    return {
      createdAt: toTimestamp(
        moment.tz(appointment.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getSourceRecordId(data: ID4WPatientCommunicationReply): number {
    return data.id;
  }

  getSourceLabel(data: ID4WPatientCommunicationReply): string {
    return `${data.id} ${data.patient_id}`;
  }

  getFilterData(
    data: ID4WPatientCommunicationReply,
    timezone: Timezone
  ): ID4WPatientCommunicationReplyFilters {
    return {
      patientId: data.patient_id.toString(),
      practiceId: data.practice_id.toString(),
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }
}
