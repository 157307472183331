import { Injectable, Injector } from '@angular/core';
import { type ISnippet } from '@principle-theorem/editor';
import {
  BasicMenuButtonComponent,
  angularEditorExtensions,
  createBulletedListBlockMenuButton,
  createHeadingMenuBlockButtons,
  createHorizontalRuleBlockMenuButton,
  createMenuDivider,
  createMenuSpacer,
  createOrderedListBlockMenuButton,
  createSnippetBlockMenuButton,
  createTableBlockMenuButton,
  createVideoEmbedBlockMenuButton,
  editorAlignSubmenuButtons,
  editorInsertSubmenuButtons,
  editorMediaSubmenuButtons,
  editorMenuButtons,
} from '@principle-theorem/ng-editor';
import {
  CurrentScopeFacade,
  OrganisationService,
  TypesenseSearchService,
} from '@principle-theorem/ng-principle-shared';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import {
  type IOptionGroup,
  type IUploader,
} from '@principle-theorem/ng-shared';
import { Brand, Staffer, toMention } from '@principle-theorem/principle-core';
import {
  MentionResourceType,
  type IPrincipleMention,
  TaskStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  multiFilter,
  multiMap,
  shareReplayCold,
} from '@principle-theorem/shared';
import { AnyExtension } from '@tiptap/core';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MENTION_BUTTON_PROVIDER } from '../mention/mention-buttons/mention-buttons.component';

@Injectable()
export class EditorPresetsService {
  constructor(
    private _injector: Injector,
    private _currentScope: CurrentScopeFacade,
    private _organisation: OrganisationService,
    private _typesenseSearch: TypesenseSearchService
  ) {}

  defaultExtensions(): AnyExtension[] {
    return [
      angularEditorExtensions.image(this._injector),
      angularEditorExtensions.imageUploading(this._injector),
      angularEditorExtensions.video(this._injector),
      angularEditorExtensions.videoUploading(this._injector),
      angularEditorExtensions.videoEmbed(this._injector),
      angularEditorExtensions.mention(this._injector, {
        providers: [MENTION_BUTTON_PROVIDER],
      }),
      angularEditorExtensions.mentionAutocomplete(this._injector, {
        mentions$: (search$) => this._loadMentionOptions$(search$),
      }),
      angularEditorExtensions.snippetAutocomplete(this._injector, {
        snippets$: this._loadSnippetOptions$(),
      }),
    ];
  }

  defaultToHTMLExtensions(): AnyExtension[] {
    return [
      angularEditorExtensions.image(this._injector),
      angularEditorExtensions.imageUploading(this._injector),
      angularEditorExtensions.video(this._injector),
      angularEditorExtensions.videoUploading(this._injector),
    ];
  }

  defaultToTextExtensions(): AnyExtension[] {
    return [
      angularEditorExtensions.image(this._injector),
      angularEditorExtensions.imageUploading(this._injector),
      angularEditorExtensions.video(this._injector),
      angularEditorExtensions.videoUploading(this._injector),
      angularEditorExtensions.mention(this._injector, {
        providers: [MENTION_BUTTON_PROVIDER],
      }),
      angularEditorExtensions.mentionAutocomplete(this._injector, {
        mentions$: (search$) => this._loadMentionOptions$(search$),
      }),
      angularEditorExtensions.snippetAutocomplete(this._injector, {
        snippets$: this._loadSnippetOptions$(),
      }),
    ];
  }

  defaultInlineExtensions(): AnyExtension[] {
    return [
      angularEditorExtensions.mention(this._injector, {
        providers: [MENTION_BUTTON_PROVIDER],
      }),
      angularEditorExtensions.mentionAutocomplete(this._injector, {
        mentions$: (search$: Observable<string>) =>
          this._loadMentionOptions$(search$),
      }),
    ];
  }

  defaultMenuItems(uploader: IUploader): MenuButtonLoaderFn[] {
    return [
      editorMenuButtons.textBadge(),
      editorMenuButtons.textStyling(),
      editorMenuButtons.bold(),
      editorMenuButtons.italic(),
      editorMenuButtons.underline(),
      editorMenuButtons.heading(),
      editorMenuButtons.align([
        editorAlignSubmenuButtons.leftAlign(),
        editorAlignSubmenuButtons.centerAlign(),
        editorAlignSubmenuButtons.rightAlign(),
        editorAlignSubmenuButtons.justifyAlign(),
      ]),
      createMenuDivider(),
      editorMenuButtons.bulletedList(),
      editorMenuButtons.orderedList(),
      editorMenuButtons.link(),
      editorMenuButtons.snippet(),
      createMenuDivider(),
      editorMenuButtons.media([
        editorMediaSubmenuButtons.imageUpload({ uploader }),
        editorMediaSubmenuButtons.videoUpload({ uploader }),
        editorMediaSubmenuButtons.videoEmbed(),
      ]),
      editorMenuButtons.insert([
        editorInsertSubmenuButtons.table(),
        editorInsertSubmenuButtons.blockquote(),
      ]),
      createMenuSpacer(),
      editorMenuButtons.undo(),
      editorMenuButtons.redo(),
    ];
  }

  blockMenuItems(
    uploader: IUploader
  ): MenuButtonLoaderFn<BasicMenuButtonComponent>[] {
    return [
      createSnippetBlockMenuButton(),
      ...createHeadingMenuBlockButtons(),
      createBulletedListBlockMenuButton(),
      createOrderedListBlockMenuButton(),
      createHorizontalRuleBlockMenuButton(),
      createTableBlockMenuButton(),
      createVideoEmbedBlockMenuButton(),
      editorMediaSubmenuButtons.imageUpload({
        uploader,
      }),
      editorMediaSubmenuButtons.videoUpload({
        uploader,
      }),
    ];
  }

  toTextMenuItems(
    uploader: IUploader
  ): MenuButtonLoaderFn<BasicMenuButtonComponent>[] {
    return [
      createSnippetBlockMenuButton(),
      createBulletedListBlockMenuButton(),
      createOrderedListBlockMenuButton(),
      editorMediaSubmenuButtons.imageUpload({
        uploader,
      }),
      editorMediaSubmenuButtons.videoUpload({
        uploader,
      }),
    ];
  }

  private _loadMentionOptions$(
    search$: Observable<string>
  ): Observable<IOptionGroup<IPrincipleMention>[]> {
    const patients$ = this._typesenseSearch
      .patientQuery$(search$)
      .pipe(map((response) => response.results));

    const tasks$ = this._typesenseSearch
      .taskQuery$(
        search$,
        of({
          statuses: [TaskStatus.Open],
          showDeleted: false,
        }),
        of('title:asc')
      )
      .pipe(map((response) => response.results));

    return this._currentScope.currentBrand$.pipe(
      filterUndefined(),
      switchMap((brand) =>
        combineLatest([
          patients$.pipe(
            multiMap((patient) =>
              toMention(patient, MentionResourceType.Patient)
            )
          ),
          tasks$.pipe(
            multiMap((task) =>
              toMention(
                { name: task.title, ref: task.ref },
                MentionResourceType.Task
              )
            )
          ),
          this._organisation.staff$.pipe(
            multiMap((staffer) =>
              toMention(staffer, MentionResourceType.Staffer)
            )
          ),
          Brand.labs$(brand).pipe(
            multiMap((lab) => toMention(lab, MentionResourceType.Lab))
          ),
          Brand.contacts$(brand).pipe(
            multiMap((contact) =>
              toMention(contact, MentionResourceType.Contact)
            )
          ),
        ])
      ),
      map(([patients, tasks, staff, labs, contacts]) => [
        {
          name: 'Patients',
          options: patients,
          skipFilter: true,
        },
        {
          name: 'Staff',
          options: staff,
          skipFilter: false,
        },
        {
          name: 'Labs',
          options: labs,
          skipFilter: false,
        },
        {
          name: 'Contacts',
          options: contacts,
          skipFilter: false,
        },
        {
          name: 'Tasks',
          options: tasks,
          skipFilter: true,
        },
      ]),
      shareReplayCold()
    );
  }

  private _loadSnippetOptions$(): Observable<IOptionGroup<ISnippet>[]> {
    return combineLatest([
      this._currentScope.currentBrand$.pipe(
        filterUndefined(),
        switchMap((brand) => Brand.snippets$(brand)),
        multiFilter((snippets) => !snippets.deleted)
      ),
      this._organisation.staffer$.pipe(
        filterUndefined(),
        switchMap((staffer) => Staffer.snippets$(staffer)),
        multiFilter((snippets) => !snippets.deleted)
      ),
    ]).pipe(
      map(([brandSnippets, stafferSnippets]) => [
        {
          name: 'Shared Snippets',
          options: brandSnippets,
          skipFilter: false,
        },
        {
          name: 'My Snippets',
          options: stafferSnippets,
          skipFilter: false,
        },
      ]),
      shareReplayCold()
    );
  }
}

export interface IEditorPresets {
  extensions: AnyExtension[];
  menuItems: MenuButtonLoaderFn[];
}
