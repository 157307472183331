import { rand, randColor, randHex } from '@ngneat/falso';
import { ITreatmentCategory } from '@principle-theorem/principle-core/interfaces';
import { BaseFirestoreMock, Properties } from '@principle-theorem/shared';

export class TreatmentCategoryMock
  extends BaseFirestoreMock
  implements Properties<ITreatmentCategory>
{
  colour = {
    value: randHex(),
    name: randColor(),
  };
  name = rand([
    'Hygiene',
    'Therapy',
    'Prosthetics',
    'Technology',
    'Nursing',
    'Radiography',
  ]);
}
