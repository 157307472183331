import { type INamedDocument } from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { type IAppointment } from '../appointment/appointment';
import { type IPatient } from '../patient/patient';
import { type IPractice } from '../practice/practice';
import { type IStaffer } from '../staffer/staffer';

export interface ISterilisationRecord {
  data: string;
  practice: INamedDocument<IPractice>;
  scannedBy?: INamedDocument<IStaffer>;
  appointment?: DocumentReference<IAppointment>;
  patient?: INamedDocument<IPatient>;
}
