import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { type MatSlideToggleChange } from '@angular/material/slide-toggle';
import {
  matSelectOptions,
  MOMENT_DATEPICKER_PROVIDERS,
  type TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import { type IReferralFormData } from '@principle-theorem/principle-core/interfaces';
import { toTimestamp } from '@principle-theorem/shared';
import {
  type PeriodTypeCde,
  type ReferralOverrideTypeCde,
} from '@principle-theorem/tyro';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ClaimReferralFormGroup,
  type IClaimReferralFormData,
  type IReferralMomentData,
} from './claim-referral-form';

@Component({
  selector: 'pr-claim-referral-form',
  templateUrl: './claim-referral-form.component.html',
  styleUrls: ['./claim-referral-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
  exportAs: 'prClaimReferralForm',
})
export class ClaimReferralFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  overrideSelect = matSelectOptions<ReferralOverrideTypeCde>([
    { value: 'L', label: 'Lost' },
    { value: 'E', label: 'Emergency' },
    { value: 'N', label: 'Not Required' },
  ]);
  periodSelect = matSelectOptions<PeriodTypeCde>([
    { value: 'S', label: 'Standard' },
    { value: 'I', label: 'Indefinite' },
  ]);
  referralForm = new ClaimReferralFormGroup();
  @Input() maxDate = moment().endOf('day');
  @Output() referralChange = new EventEmitter<IReferralFormData>();

  constructor() {
    this.referralForm.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this.referralForm.applyValidators());
    validFormGroupChanges$(this.referralForm)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((formData) =>
        this.referralChange.emit(this._transformValue(formData))
      );
  }

  hasProviderError(): boolean {
    const referral = this.referralForm.controls
      .referral as TypedFormGroup<IReferralMomentData>;
    return referral.controls.providerNum.hasError('pattern') ?? false;
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  hasReferralChange(event: MatSlideToggleChange): void {
    this.referralForm.controls.hasReferral.setValue(event.checked);
  }

  private _transformValue(formData: IClaimReferralFormData): IReferralFormData {
    return {
      ...formData,
      referral: {
        ...formData.referral,
        dateOfIssue: toTimestamp(formData.referral.dateOfIssue),
      },
    };
  }
}
