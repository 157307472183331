import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { type IPractice } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { TyroLogsDialogComponent } from './tyro-logs-dialog/tyro-logs-dialog.component';

@Component({
  selector: 'pr-practice-tyro-settings',
  templateUrl: './practice-tyro-settings.component.html',
  styleUrls: ['./practice-tyro-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PracticeTyroSettingsComponent {
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);

  @Input()
  set practice(practice: WithRef<IPractice>) {
    if (practice) {
      this.practice$.next(practice);
    }
  }

  constructor(private _dialog: MatDialog) {}

  async openTyroLogs(): Promise<void> {
    return this._dialog
      .open<TyroLogsDialogComponent, undefined, undefined>(
        TyroLogsDialogComponent,
        DialogPresets.large({ height: '80%' })
      )
      .afterClosed()
      .toPromise();
  }
}
