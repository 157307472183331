import {
  ServiceProviderHandler,
  TreatmentConfiguration,
} from '@principle-theorem/principle-core';
import {
  ITranslationMap,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  XSLXImporterExporter,
  asyncForEach,
  hardDeleteDoc,
  isSameRef,
  multiMap,
  multiSortBy$,
  nameSorter,
  snapshot,
  toNamedDocument,
  type INamedDocument,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, sortBy, uniqBy } from 'lodash';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { ITEM_CODE_TO_TREATMENT_DEFAULT_MAPPINGS } from '../../../mappings/ada-code-mappings';
import { ITEM_CODE_TO_TREATMENT_MAPPING } from '../../../mappings/base-item-code-to-treatment';
import { ItemCodeToTreatmentToXSLX } from '../../../mappings/item-code-to-treatment-to-xlsx';
import { XLSXToItemCodeToTreatment } from '../../../mappings/xlsx-to-item-code-to-treatment';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import { FeeScheduleSourceEntity } from '../../source/entities/fee-schedule';
import { IBaseMigrationItemCode } from '../../../interfaces';

export class PraktikaItemCodeToTreatmentMappingHandler extends BaseCustomMappingHandler<ITreatmentConfiguration> {
  customMapping = ITEM_CODE_TO_TREATMENT_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const itemCodes = await this._getItemCodeOptions(migration);
    return itemCodes.map((record) => ({
      label: compact([record.itemCode, record.description]).join(' - '),
      value: record.id.toString(),
    }));
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<ITreatmentConfiguration>[]> {
    return snapshot(
      TreatmentConfiguration.all$(migration.configuration.brand).pipe(
        multiMap(toNamedDocument),
        multiSortBy$(nameSorter())
      )
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const fileName = `item-code-to-treatment-mapping`;
    const itemCodes = await this._getItemCodeOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    await new XSLXImporterExporter().download(
      fileName,
      itemCodes,
      new ItemCodeToTreatmentToXSLX(
        await this.getDestinationOptions(migration),
        await snapshot(this.getRecords$(translationMap)),
        ITEM_CODE_TO_TREATMENT_DEFAULT_MAPPINGS
      )
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XLSXToItemCodeToTreatment()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const records = await snapshot(this.getRecords$(translationMap));
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));

    const sourceOptions = await this.getSourceOptions(migration);
    const treatmentConfigurations = await this.getDestinationOptions(migration);

    await asyncForEach(items, async (item) => {
      const mapTo = item.mapTo;
      const label = `${item.code} - ${item.description}`;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.label === label
      )?.value;
      if (!value || !item.mapTo) {
        return;
      }

      const treatmentConfiguration = treatmentConfigurations.find(
        (configuration) => configuration.name === mapTo
      );

      if (!treatmentConfiguration) {
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: treatmentConfiguration.ref,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getItemCodeOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<IBaseMigrationItemCode[]> {
    const feeScheduleSource = new FeeScheduleSourceEntity();
    const records = await feeScheduleSource
      .getRecords$(migration, 10000)
      .toPromise();
    const itemCodes = records
      .map((record) => {
        return record.data.data.items;
      })
      .flat()
      .map((item) => ({
        id: item.custom_code || item.code,
        itemCode: item.custom_code || item.code,
        description:
          item.custom_description ||
          ServiceProviderHandler.findServiceCode(item.code)?.description ||
          '',
      }));
    return uniqBy(
      sortBy(
        itemCodes.filter((record) =>
          ServiceProviderHandler.findServiceCode(record.itemCode)
        ),
        'itemCode'
      ),
      'itemCode'
    );
  }
}

export function resolveMappedTreatmentConfiguration(
  itemCodeId: string,
  treatmentConfigurationMappings: WithRef<
    ITranslationMap<ITreatmentConfiguration>
  >[],
  defaultTreatmentConfiguration: WithRef<ITreatmentConfiguration>,
  treatmentConfigurations: WithRef<ITreatmentConfiguration>[]
): WithRef<ITreatmentConfiguration> {
  const mappedTreatment = treatmentConfigurationMappings.find(
    (mapping) => mapping.sourceIdentifier === itemCodeId
  );
  return mappedTreatment?.destinationIdentifier
    ? treatmentConfigurations.find((treatmentConfiguration) =>
        isSameRef(treatmentConfiguration, mappedTreatment.destinationIdentifier)
      ) ?? defaultTreatmentConfiguration
    : defaultTreatmentConfiguration;
}
