import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import {
  TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
  type IPatientPrimaryContact,
  isValidPatientRelationshipFormData,
  RelationshipFormGroup,
  transformRelationshipData,
} from '../patient-relationship-form/patient-relationship-form';
import { IPatientRelationship } from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-patient-primary-contact-form',
  templateUrl: './patient-primary-contact-form.component.html',
  styleUrls: ['./patient-primary-contact-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientPrimaryContactFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  autoSave$ = new BehaviorSubject<boolean>(false);
  form = new TypedFormGroup<IPatientPrimaryContact>({
    primaryContact: new RelationshipFormGroup(),
  });

  @Output() primaryContactChange = new EventEmitter<IPatientPrimaryContact>();

  @Input()
  set autoSave(autoSave: boolean) {
    if (autoSave) {
      this.autoSave$.next(autoSave);
    }
  }

  @Input()
  set formData(data: IPatientPrimaryContact) {
    if (data.primaryContact) {
      this.form.patchValue(data, { emitEvent: false });
    }
  }

  constructor() {
    this._validateAutoSaveData();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getPrimaryContactCtrl(
    form: TypedFormGroup<IPatientPrimaryContact>
  ): TypedFormGroup<IPatientRelationship> {
    return form.controls.primaryContact as TypedFormGroup<IPatientRelationship>;
  }

  private _validateAutoSaveData(): void {
    combineLatest([
      this.autoSave$.pipe(filter((autoSave) => autoSave)),
      validFormGroupChanges$(this.form),
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([_, { primaryContact }]) => {
        if (!isValidPatientRelationshipFormData(primaryContact)) {
          return;
        }
        this.primaryContactChange.emit({
          primaryContact: transformRelationshipData(primaryContact),
        });
      });
  }
}
