import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  NgModuleRef,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  BlockNodes,
  findNodeRange,
  fromEditorEvents,
  getActiveNode,
} from '@principle-theorem/editor';
import { MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { shareReplayCold, snapshot } from '@principle-theorem/shared';
import { Editor } from '@tiptap/core';
import { compact, set } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
} from 'rxjs';
import { map, pairwise, takeUntil, tap } from 'rxjs/operators';
import { BasicMenuButtonComponent } from '../basic-menu-button/basic-menu-button.component';
import { IMenuInfo } from '../select-menu/select-menu-view';

export interface ISlashMenuData {
  menuItems: MenuButtonLoaderFn<BasicMenuButtonComponent>[];
  editor: Editor;
}

@Component({
  selector: 'pt-slash-menu',
  templateUrl: './slash-menu.component.html',
  styleUrls: ['./slash-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlashMenuComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _menuContainer$ = new ReplaySubject<ViewContainerRef>(1);
  private _selectedIndex$ = new BehaviorSubject(0);
  private _keydownListener: EventListener;
  buttons$: Observable<ComponentRef<BasicMenuButtonComponent>[]>;
  searchInput$ = new BehaviorSubject('');

  @ViewChild('menuContainer', { static: false, read: ViewContainerRef })
  set menuContainer(menuContainer: ViewContainerRef) {
    if (menuContainer) {
      this._menuContainer$.next(menuContainer);
    }
  }

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _moduleRef: NgModuleRef<unknown>,
    private _cdr: ChangeDetectorRef,
    @Inject(DIALOG_DATA) private _data: ISlashMenuData & IMenuInfo,
    private _dialogRef: DialogRef
  ) {
    this._keydownListener = (event) =>
      this.keydownHandler(event as KeyboardEvent);
    this._data.editor.view.dom.addEventListener(
      'keydown',
      this._keydownListener,
      {
        capture: true,
      }
    );

    this.buttons$ = this._menuContainer$.pipe(
      tap((menuContainer) => menuContainer.clear()),
      map((menuContainer) => {
        return compact(
          this._data.menuItems.map((menuItemFn, index) => {
            const menuItem = menuItemFn(this._data.editor);
            const componentFactory =
              this._componentFactoryResolver.resolveComponentFactory(
                menuItem.component
              );
            const componentRef =
              menuContainer.createComponent<BasicMenuButtonComponent>(
                componentFactory,
                undefined,
                undefined,
                undefined,
                this._moduleRef
              );

            if (index === 0) {
              componentRef.instance.selected = true;
            }
            componentRef.instance.buttonType = 'block';
            Object.entries(menuItem.data || {}).map(([key, value]) => {
              set(componentRef.instance, key, value);
            });
            return componentRef;
          })
        );
      }),
      shareReplayCold()
    );

    this.buttons$.pipe(takeUntil(this._onDestroy$)).subscribe((buttons) => {
      buttons.map((button) => {
        button.instance.editor = this._data.editor;
      });
      this._cdr.detectChanges();
    });

    combineLatest([this.buttons$, this._selectedIndex$.pipe(pairwise())])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([buttons, [oldIndex, newIndex]]) => {
        const oldButton = buttons[oldIndex];
        if (oldButton) {
          oldButton.instance.selected = false;
        }

        const activeButtons = buttons.filter(
          (button) => !button.instance.disabled
        );
        const newButton = activeButtons[newIndex];
        if (newButton) {
          newButton.instance.selected = true;
        }
      });

    combineLatest([this.searchInput$, this.buttons$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([input, buttons]) => {
        buttons.map((button) => {
          const matches = !button.instance.buttonText
            .toLowerCase()
            .includes(input.toLowerCase());
          button.instance.disabled = matches;
        });
        this._selectedIndex$.next(0);
        this._cdr.detectChanges();
      });

    fromEditorEvents(this._data.editor)
      .pipe(
        map(
          () =>
            getActiveNode(this._data.editor.state, BlockNodes.Paragraph)
              ?.textContent
        ),
        map((content) =>
          content?.startsWith('/') ? content.substring(1) : content
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((content) => this.searchInput$.next(content ?? ''));
  }

  keydownHandler(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        return void this.handleUpArrow();
      case 'ArrowDown':
        event.preventDefault();
        return void this.handleDownArrow();
      case 'Enter':
        event.preventDefault();
        event.stopPropagation();
        return void this.handleEnter();
      case 'Escape':
        event.preventDefault();
        return void this.handleEscape();
      default:
        break;
    }
  }

  async handleDownArrow(): Promise<void> {
    const buttons = await snapshot(this.buttons$);
    const length = buttons.length;
    const currentIndex = this._selectedIndex$.value;
    if (length - 1 === currentIndex) {
      this._selectedIndex$.next(0);
      return;
    }
    this._selectedIndex$.next(this._selectedIndex$.value + 1);
  }

  async handleUpArrow(): Promise<void> {
    const buttons = await snapshot(this.buttons$);
    const length = buttons.length;
    const currentIndex = this._selectedIndex$.value;
    if (currentIndex === 0) {
      this._selectedIndex$.next(length - 1);
      return;
    }
    this._selectedIndex$.next(this._selectedIndex$.value - 1);
  }

  async handleEnter(): Promise<void> {
    const buttons = await snapshot(this.buttons$);
    const activeButtons = buttons.filter((button) => !button.instance.disabled);
    const button = activeButtons[this._selectedIndex$.value];
    await button.instance.runCommand();
    this._dialogRef.close();
  }

  handleEscape(): void {
    this._dialogRef.close();
    const currentParagraph = getActiveNode(
      this._data.editor.state,
      BlockNodes.Paragraph
    );
    if (!currentParagraph) {
      return;
    }
    const nodeRange = findNodeRange(
      this._data.editor.state.doc,
      currentParagraph
    );
    if (!nodeRange) {
      return;
    }

    const originalSelection = this._data.editor.state.selection.$from.pos;

    this._data.editor
      .chain()
      .focus()
      .selectParentNode()
      .disableSlashMenu()
      .setTextSelection(originalSelection)
      .focus()
      .run();
  }

  ngOnDestroy(): void {
    this._data.editor.view.dom.removeEventListener(
      'keydown',
      this._keydownListener,
      {
        capture: true,
      }
    );
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
