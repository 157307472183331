import {
  FormLayoutElementType,
  FormSchemaPropertyType,
  type IChildFlexLayoutElement,
  type IFormLayoutElement,
  type IFormSchema,
  type IParentFlexLayout,
} from '@principle-theorem/principle-core/interfaces';
import { formFlexRow, prefixLayoutItems } from '../../custom-form-helpers';

/**
 * Dental Background
 */

export const DENTAL_BACKGROUND_LAYOUT: IChildFlexLayoutElement[] = [
  {
    type: FormLayoutElementType.Flex,
    items: [
      formFlexRow([{ key: 'sleepThroughNight' }, { key: 'snores' }]),
      formFlexRow([{ key: 'grinding' }, { type: FormLayoutElementType.Div }]),
      formFlexRow([
        { key: 'hasFeedingDifficulties' },
        { key: 'feedingDifficulties' },
      ]),
      formFlexRow([{ key: 'eatingHabits' }, { key: 'eatingHabitDetails' }]),
      formFlexRow([{ key: 'oralHabits' }, { key: 'oralHabitDetails' }]),
      formFlexRow([{ key: 'hasInjuredTeeth' }, { key: 'injuredTeeth' }]),
      formFlexRow([{ key: 'brushingHabits' }, { key: 'brushingHabitDetails' }]),
      formFlexRow([{ key: 'brushingActor' }, { key: 'toothpaste' }]),
      formFlexRow([{ key: 'flossingHabits' }, { key: 'fluoridatedWater' }]),
      { key: 'firstCity' },
      { key: 'concerns' },
      formFlexRow([
        { key: 'hasPreviousTreatment' },
        { key: 'previousTreatment' },
      ]),
      formFlexRow([{ key: 'hasBadExperience' }, { key: 'badExperience' }]),
      formFlexRow([{ key: 'willReactWell' }, { key: 'wontReactWell' }]),
      { key: 'extraInfo' },
    ],
  },
];

export const DENTAL_BACKGROUND_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    sleepThroughNight: {
      type: FormSchemaPropertyType.String,
      title: `Does your child sleep through the night?`,
      enum: ['Yes', 'No'],
    },
    snores: {
      type: FormSchemaPropertyType.String,
      title: `Does your child snore?`,
      enum: ['Yes', 'No'],
    },
    grinding: {
      type: FormSchemaPropertyType.String,
      title: 'Does your child grind their teeth during sleep?',
      enum: ['Yes', 'No'],
    },
    hasFeedingDifficulties: {
      type: FormSchemaPropertyType.String,
      title: 'Does your child have any feeding difficulties or food aversions?',
      enum: ['Yes', 'No'],
    },
    feedingDifficulties: {
      type: FormSchemaPropertyType.String,
      title: 'If Yes, please provide details',
    },
    eatingHabits: {
      type: FormSchemaPropertyType.String,
      title: `How would you describe your child's eating habits?`,
      enum: ['Good', 'Average', 'Fussy'],
    },
    eatingHabitDetails: {
      type: FormSchemaPropertyType.String,
      title: 'If Yes, please provide details',
    },
    oralHabits: {
      type: FormSchemaPropertyType.String,
      title: 'Does your child currently have / ever had any oral habits?',
      enum: ['Dummy Sucking', 'Thumb sucking', 'Nail Biting', 'Other'],
    },
    oralHabitDetails: {
      type: FormSchemaPropertyType.String,
      title: 'If Yes, please provide details',
    },
    hasInjuredTeeth: {
      type: FormSchemaPropertyType.String,
      title: 'Has your child ever injured their teeth?',
      enum: ['Yes', 'No'],
    },
    injuredTeeth: {
      type: FormSchemaPropertyType.String,
      title: 'If Yes, please provide details',
    },
    brushingHabits: {
      type: FormSchemaPropertyType.String,
      title: `How often are your child's teeth brushed?`,
      enum: ['Once Daily', 'Twice Daily', 'Irregular', 'Never'],
    },
    brushingHabitDetails: {
      type: FormSchemaPropertyType.String,
      title: 'If Yes, please provide details',
    },
    brushingActor: {
      type: FormSchemaPropertyType.String,
      title: `Who brushes your child's teeth?`,
      enum: [
        'Child only',
        'Child only with parental supervision',
        'Child and parent',
        'Parent only',
      ],
    },
    toothpaste: {
      type: FormSchemaPropertyType.String,
      title: 'Which toothpaste is your child currently using?',
    },
    flossingHabits: {
      type: FormSchemaPropertyType.String,
      title: `Does your child regularly floss their teeth?`,
      enum: ['Yes', 'No'],
    },
    fluoridatedWater: {
      type: FormSchemaPropertyType.String,
      title: `Does your child drink fluoridated water?`,
      enum: ['Yes', 'No'],
    },
    firstCity: {
      type: FormSchemaPropertyType.String,
      title:
        'Which city did your child live in for the first three years of their life?',
    },
    concerns: {
      type: FormSchemaPropertyType.String,
      title: `Please list any concerns you have about your child's teeth`,
    },
    hasPreviousTreatment: {
      type: FormSchemaPropertyType.String,
      title: 'Has your child ever had any previous dental treatment?',
      enum: ['Yes', 'No'],
    },
    previousTreatment: {
      type: FormSchemaPropertyType.String,
      title: 'If Yes, please provide details',
    },
    hasBadExperience: {
      type: FormSchemaPropertyType.String,
      title:
        'Has your child ever had any unpleasant / traumatic dental or medical experiences?',
      enum: ['Yes', 'No'],
    },
    badExperience: {
      type: FormSchemaPropertyType.String,
      title: 'If Yes, please provide details',
    },
    willReactWell: {
      type: FormSchemaPropertyType.String,
      title: `Do you think you child will react well to today's visit?`,
      enum: ['Yes', 'No'],
    },
    wontReactWell: {
      type: FormSchemaPropertyType.String,
      title: 'If No, please provide details',
    },
    extraInfo: {
      type: FormSchemaPropertyType.String,
      title: 'Anything else you would like us to know?',
    },
  },
};

/**
 * Dental Background Form
 */

export const PAEDIATRIC_DENTAL_BACKGROUND_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    dentalHistory: DENTAL_BACKGROUND_SCHEMA,
  },
};

export const PAEDIATRIC_DENTAL_BACKGROUND_FORM_LAYOUT: (
  | IParentFlexLayout
  | IFormLayoutElement
)[] = [
  {
    key: 'dentalHistory',
    notitle: true,
    type: FormLayoutElementType.Flex,
    options: { flex: '1 1 100%' },
    items: prefixLayoutItems(DENTAL_BACKGROUND_LAYOUT, 'dentalHistory'),
  },
];
