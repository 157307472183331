/* eslint-disable no-null/no-null */
import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_FORMAT,
  isObject,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { type IPraktikaAppointmentInvoiceAdjustment } from './appointment-invoice-adjustment';
import { PatientSourceEntity } from './patient';

export const PATIENT_DEPOSIT_ADJUSTMENT_RESOURCE_TYPE =
  'patientDepositAdjustment';

export const PATIENT_DEPOSIT_ADJUSTMENT_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Deposit Adjustment List',
      description: '',
      idPrefix: PATIENT_DEPOSIT_ADJUSTMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IPraktikaDepositAdjustment
  extends Omit<IPraktikaAppointmentInvoiceAdjustment, 'appointment_id'> {
  deposit_id: number;
  deposit_number: number;
  deposit_description: string;
  patient_id: number;
}

function isPraktikaDepositAdjustment(
  item: unknown
): item is IPraktikaDepositAdjustment {
  return (
    isObject(item) &&
    isNumber(item.deposit_id) &&
    isNumber(item.deposit_number) &&
    isString(item.deposit_description) &&
    isNumber(item.patient_id)
  );
}

export interface IPraktikaDepositAdjustmentFilters {
  patientId: string;
  effectiveDate: Timestamp;
}

export interface IPraktikaDepositAdjustmentTranslations {
  effectiveDate: Timestamp;
}

const PATIENT_DEPOSIT_ADJUSTMENT_SOURCE_QUERY = `SELECT
  iPatientNumber as patient_id,
  iDepositId as deposit_id,
  iDepositNumber as deposit_number,
  vchDepositDesc as deposit_description,
  vchNotes as notes,
  iAdjustmentId as id,
  iAdjustmentType as type_id,
  vchEffect as effect,
  vchDesc as description,
  dtEffectiveDate as effective_date,
  bPercentFromTotal as is_percent_from_total,
  iPercentValue as percent_value,
  iAmount as amount,
  iDepositTransferSourceId as deposit_transfer_source_id
FROM patient_deposit_adjustments
`;

export class PatientDepositAdjustmentSourceEntity extends BaseSourceEntity<
  IPraktikaDepositAdjustment,
  IPraktikaDepositAdjustmentTranslations,
  IPraktikaDepositAdjustmentFilters
> {
  sourceEntity = PATIENT_DEPOSIT_ADJUSTMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_DEPOSIT_ADJUSTMENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_DEPOSIT_ADJUSTMENT_SOURCE_QUERY;
  verifySourceFn = isPraktikaDepositAdjustment;
  override transformDataFn = flow([]);
  override dateFilterField: keyof IPraktikaDepositAdjustmentFilters =
    'effectiveDate';

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  translate(
    adjustment: IPraktikaDepositAdjustment,
    timezone: Timezone
  ): IPraktikaDepositAdjustmentTranslations {
    return {
      effectiveDate: toTimestamp(
        moment
          .tz(adjustment.effective_date, ISO_DATE_FORMAT, timezone)
          .startOf('day')
      ),
    };
  }

  getSourceRecordId(data: IPraktikaDepositAdjustment): string {
    return data.id.toString();
  }

  getSourceLabel(data: IPraktikaDepositAdjustment): string {
    return this.getSourceRecordId(data);
  }

  getFilterData(
    data: IPraktikaDepositAdjustment,
    timezone: Timezone
  ): IPraktikaDepositAdjustmentFilters {
    return {
      patientId: data.patient_id.toString(),
      effectiveDate: toTimestamp(
        moment.tz(data.effective_date, ISO_DATE_FORMAT, timezone).startOf('day')
      ),
    };
  }
}
