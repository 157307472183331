import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_FORMAT,
  Timestamp,
  TypeGuard,
  toTimestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { PATIENT_CLINICAL_NOTE_DESTINATION_ENTITY } from '../../../destination/entities/consts';
import { PATIENT_DEPOSIT_DESTINATION_ENTITY } from '../../../destination/entities/patient-deposits';
import { PATIENT_INTERACTION_DESTINATION_ENTITY } from '../../../destination/entities/patient-interactions';
import { PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY } from '../../../destination/entities/patient-treatment-plans';
import { STERILISATION_RECORD_DESTINATION_ENTITY } from '../../../destination/entities/sterilisation-records';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { PATIENT_FILE_DESTINATION_ENTITY } from '../../destination/entities/patient-files';
import { PATIENT_INVOICE_DESTINATION_ENTITY } from '../../destination/entities/patient-invoices';
import { PATIENT_MEDICAL_HISTORY_DESTINATION_ENTITY } from '../../destination/entities/patient-medical-histories';
import { PATIENT_RELATIONSHIP_DESTINATION_ENTITY } from '../../destination/entities/patient-relationships';
import { PATIENT_DESTINATION_ENTITY } from '../../destination/entities/patients';
import { PATIENT_CLINICAL_CHART_RESOURCE_TYPE } from '../../../destination/entities/patient-clinical-charts';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import { PATIENT_APPOINTMENT_DESTINATION_ENTITY } from '../../../destination/entities/patient-appointments';

export const PATIENT_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient List',
    description: '',
    idPrefix: PATIENT_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ICorePracticePatient {
  id: number;
  patientNumber: string; // 1-19029
  firstName: string; // "Michelle"
  lastName: string; // "Ridolfo"
  middleName?: string;
  preferredName?: string;
  dateOfBirth: string; // 1997-09-09 00:00:00.000
  title?: string;
  sex?: 'M' | 'F' | 'O';
  email?: string;
  homePhone?: string;
  mobile?: string;
  workPhone?: string;
  fax?: string;
  occupation?: string;
  companyName?: string;
  joinDate?: string; // 2022-01-20 00:00:00.000
  medicareNo?: string;
  contactName?: string;
  contactRelationship?: string; // See if there's a list of relationships
  contactPhone?: string;
  contactMobile?: string;
  addressLine1?: string;
  addressLine2?: string;
  suburb?: string;
  postcode?: string;
  state?: string;
  country?: string;
  referralSourceId?: number;
  referralNotes?: string;
  isActive: boolean;
  isDeleted: boolean;
  familyPatientId?: number;
}

export function isCorePracticePatient(
  item: unknown
): item is ICorePracticePatient {
  return TypeGuard.interface<ICorePracticePatient>({
    id: isNumber,
    patientNumber: isString,
    firstName: isString,
    lastName: isString,
    middleName: TypeGuard.nilOr(isString),
    preferredName: TypeGuard.nilOr(isString),
    dateOfBirth: isString,
    title: TypeGuard.nilOr(isString),
    sex: TypeGuard.nilOr(isString),
    email: TypeGuard.nilOr(isString),
    homePhone: TypeGuard.nilOr(isString),
    mobile: TypeGuard.nilOr(isString),
    workPhone: TypeGuard.nilOr(isString),
    fax: TypeGuard.nilOr(isString),
    occupation: TypeGuard.nilOr(isString),
    companyName: TypeGuard.nilOr(isString),
    joinDate: TypeGuard.nilOr(isString),
    medicareNo: TypeGuard.nilOr(isString),
    contactName: TypeGuard.nilOr(isString),
    contactRelationship: TypeGuard.nilOr(isString),
    contactPhone: TypeGuard.nilOr(isString),
    contactMobile: TypeGuard.nilOr(isString),
    addressLine1: TypeGuard.nilOr(isString),
    addressLine2: TypeGuard.nilOr(isString),
    suburb: TypeGuard.nilOr(isString),
    postcode: TypeGuard.nilOr(isString),
    state: TypeGuard.nilOr(isString),
    country: TypeGuard.nilOr(isString),
    referralSourceId: TypeGuard.nilOr(isNumber),
    referralNotes: TypeGuard.nilOr(isString),
    isActive: isBoolean,
    isDeleted: isBoolean,
    familyPatientId: TypeGuard.nilOr(isNumber),
  })(item);
}

export interface ICorePracticePatientTranslations {
  joinDate?: Timestamp;
}

export interface ICorePracticePatientFilters {
  familyPatientId?: number;
}

const PATIENT_SOURCE_QUERY = `
SELECT
  PatientId AS id,
  convert_to_text(PatientNo) AS patient_number,
  Firstname AS first_name,
  Lastname AS last_name,
  Middlename AS middle_name,
  PreferredName AS preferred_name,
  DateOfBirth AS date_of_birth,
  Title AS title,
  Sex AS sex,
  Email AS email,
  HomePhone AS home_phone,
  Mobile AS mobile,
  WorkPhone AS work_phone,
  Fax AS fax,
  Occupation AS occupation,
  CompanyName AS company_name,
  JoinDate AS join_date,
  MedicareNo AS medicare_no,
  ContactName AS contact_name,
  ContactRelationship AS contact_relationship,
  ContactPhone AS contact_phone,
  ContactMobile AS contact_mobile,
  AddressLine1 AS address_line_1,
  AddressLine2 AS address_line_2,
  Suburb AS suburb,
  convert_to_text(Postcode) AS postcode,
  State AS state,
  Country AS country,
  ReferralSourceId AS referral_source_id,
  ReferralNotes AS referral_notes,
  convert_to_boolean(IsActive) AS is_active,
  convert_to_boolean(IsDeleted) AS is_deleted,
  FamilyPatientId AS family_patient_id
FROM tblPatient
`;

export class PatientSourceEntity extends BaseSourceEntity<
  ICorePracticePatient,
  ICorePracticePatientTranslations,
  ICorePracticePatientFilters
> {
  sourceEntity = PATIENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatient;

  migrationDestinations = [
    PATIENT_CLINICAL_CHART_RESOURCE_TYPE,
    PATIENT_CLINICAL_NOTE_DESTINATION_ENTITY.metadata.key,
    PATIENT_DEPOSIT_DESTINATION_ENTITY.metadata.key,
    PATIENT_FILE_DESTINATION_ENTITY.metadata.key,
    PATIENT_INTERACTION_DESTINATION_ENTITY.metadata.key,
    PATIENT_RELATIONSHIP_DESTINATION_ENTITY.metadata.key,
    PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY.metadata.key,
    PATIENT_DESTINATION_ENTITY.metadata.key,
    STERILISATION_RECORD_DESTINATION_ENTITY.metadata.key,
    PATIENT_MEDICAL_HISTORY_DESTINATION_ENTITY.metadata.key,
    PATIENT_INVOICE_DESTINATION_ENTITY.metadata.key,
    PATIENT_APPOINTMENT_DESTINATION_ENTITY.metadata.key,
  ];

  translate(
    data: ICorePracticePatient,
    _timezone: Timezone
  ): ICorePracticePatientTranslations {
    return {
      joinDate: data.joinDate
        ? toTimestamp(moment.utc(data.joinDate, ISO_DATE_FORMAT))
        : undefined,
    };
  }

  getSourceRecordId(data: ICorePracticePatient): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatient): string {
    return `${data.id} - ${data.firstName} ${data.lastName}`;
  }

  getFilterData(
    data: ICorePracticePatient,
    _timezone: Timezone
  ): ICorePracticePatientFilters {
    return {
      familyPatientId: data.familyPatientId,
    };
  }
}
