import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  type IMatSelectGroupOptions,
  type IMatSelectOption,
  matSelectGroupOptions,
  SelectionListStore,
} from '@principle-theorem/ng-shared';
import {
  type IMedia,
  type IStafferSettings,
  PatientMediaGroupBy,
  PatientMediaSize,
  PatientMediaViewType,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  getDoc,
  HISTORY_DATE_FORMAT,
  type IGroup,
  isSameRef,
  reduceToSingleArray,
  shareReplayCold,
  sortByCreatedAt,
  toISODate,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, flatten, sortBy, uniq, uniqWith } from 'lodash';
import { combineLatest, of, type Observable, ReplaySubject, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { type IEditMediaDialogData } from '../edit-media-dialog/edit-media-dialog.component';

@Component({
  selector: 'pr-media-selector-grid',
  templateUrl: './media-selector-grid.component.html',
  styleUrls: ['./media-selector-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaSelectorGridComponent {
  media$ = new ReplaySubject<WithRef<IMedia>[]>(1);
  groups$: Observable<IMatSelectGroupOptions<string, WithRef<IMedia>>>;
  patientMediaSettings$ = new ReplaySubject<IStafferSettings['patientMedia']>(
    1
  );
  @Input() selectionList: SelectionListStore<WithRef<IMedia>>;
  @Output() openMedia = new EventEmitter<
    Omit<IEditMediaDialogData, 'editMode'>
  >();
  showGrid$: Observable<boolean>;
  columnSize$: Observable<number>;

  @Input()
  set patientMediaSettings(
    patientMediaSettings: IStafferSettings['patientMedia']
  ) {
    if (patientMediaSettings) {
      this.patientMediaSettings$.next(patientMediaSettings);
    }
  }

  @Input()
  set media(media: WithRef<IMedia>[]) {
    if (media) {
      this.media$.next(media);
    }
  }

  constructor() {
    this.showGrid$ = this.patientMediaSettings$.pipe(
      map((settings) => settings.viewType === PatientMediaViewType.Grid)
    );

    this.columnSize$ = this.getColumnSize$();

    this.groups$ = combineLatest([
      this.media$,
      this.patientMediaSettings$.pipe(map((settings) => settings.groupBy)),
    ]).pipe(
      switchMap(([media, groupBy]) => {
        switch (groupBy) {
          case PatientMediaGroupBy.Date:
            return of(this._groupByDate(media));
          case PatientMediaGroupBy.Tags:
            return from(this._groupByTags(media));
          default:
            return of(this._groupByDefault(media));
        }
      }),
      shareReplayCold()
    );
  }

  toggle(media: WithRef<IMedia>, event?: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
    this.selectionList.toggleSelected(media);
  }

  getColumnSize$(): Observable<number> {
    return this.patientMediaSettings$.pipe(
      map(({ size }) => {
        switch (size) {
          case PatientMediaSize.Small:
            return 6;
          case PatientMediaSize.Medium:
            return 4;
          case PatientMediaSize.Large:
            return 3;
          default:
            return 4;
        }
      })
    );
  }

  groupsToOrderedMedia(
    groups: IGroup<IMatSelectOption<WithRef<IMedia>>, string>[]
  ): WithRef<IMedia>[] {
    return flatten(
      groups.map((group) => group.items.map((item) => item.value))
    );
  }

  private _groupByDefault(
    media: WithRef<IMedia>[]
  ): IMatSelectGroupOptions<string, WithRef<IMedia>> {
    return matSelectGroupOptions([
      {
        group: '',
        items: media.sort(sortByCreatedAt).map(toMediaOption),
      },
    ]);
  }

  private async _groupByTags(
    media: WithRef<IMedia>[]
  ): Promise<IMatSelectGroupOptions<string, WithRef<IMedia>>> {
    const tags = sortBy(
      await asyncForEach(
        uniqWith(
          reduceToSingleArray(media.map((mediaItem) => mediaItem.tags)).map(
            (tag) => tag.ref
          ),
          isSameRef
        ),
        (tagRef) => getDoc(tagRef)
      ),
      'name'
    );

    return matSelectGroupOptions([
      {
        group: 'No Tags',
        items: media
          .filter((mediaItem) => !mediaItem.tags.length)
          .sort(sortByCreatedAt)
          .map(toMediaOption),
      },
      ...tags.map((tag) => ({
        group: tag.name,
        items: media
          .filter((mediaItem) =>
            mediaItem.tags.some((mediaTag) => isSameRef(mediaTag, tag))
          )
          .sort(sortByCreatedAt)
          .map(toMediaOption),
      })),
    ]);
  }

  private _groupByDate(
    media: WithRef<IMedia>[]
  ): IMatSelectGroupOptions<string, WithRef<IMedia>> {
    const dates = uniq(
      compact(media.map((mediaItem) => toISODate(mediaItem.createdAt)))
    )
      .sort()
      .reverse();
    return matSelectGroupOptions(
      dates.map((date) => ({
        group: toMoment(date).format(HISTORY_DATE_FORMAT),
        items: media
          .filter((mediaItem) => date === toISODate(mediaItem.createdAt))
          .sort(sortByCreatedAt)
          .map(toMediaOption),
      }))
    );
  }
}

function toMediaOption(
  mediaItem: WithRef<IMedia>
): IMatSelectOption<WithRef<IMedia>> {
  return {
    label: mediaItem.name,
    value: mediaItem,
  };
}
