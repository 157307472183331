import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const APPOINTMENT_CATEGORY_RESOURCE_TYPE = 'appointmentCategory';

export const APPOINTMENT_CATEGORY_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Appointment Category List',
      description: '',
      idPrefix: APPOINTMENT_CATEGORY_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IOasisAppointmentCategory {
  id: string;
  label: string;
}

export function isOasisAppointmentCategory(
  item: unknown
): item is IOasisAppointmentCategory {
  return TypeGuard.interface<IOasisAppointmentCategory>({
    id: isString,
    label: isString,
  })(item);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOasisAppointmentCategoryTranslations {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOasisAppointmentCategoryFilters {}

const APPOINTMENT_CATEGORY_SOURCE_QUERY = `
SELECT
	REGEXP_REPLACE(REGEXP_REPLACE(SKEY, 'APPSTATCE', ''), '/', '-') AS id,
	F1 AS label
FROM SYTBLENT
WHERE SKEY LIKE 'APPSTATCE%'
`;

export class AppointmentCategorySourceEntity extends BaseSourceEntity<
  IOasisAppointmentCategory,
  IOasisAppointmentCategoryTranslations,
  IOasisAppointmentCategoryFilters
> {
  sourceEntity = APPOINTMENT_CATEGORY_SOURCE_ENTITY;
  entityResourceType = APPOINTMENT_CATEGORY_RESOURCE_TYPE;
  sourceQuery = APPOINTMENT_CATEGORY_SOURCE_QUERY;
  verifySourceFn = isOasisAppointmentCategory;

  translate(
    _data: IOasisAppointmentCategory,
    _timezone: Timezone
  ): IOasisAppointmentCategoryTranslations {
    return {};
  }

  getSourceRecordId(data: IOasisAppointmentCategory): string {
    return data.id;
  }

  sanitiseSourceId(data: IOasisAppointmentCategory): string {
    return data.id;
  }

  getSourceLabel(data: IOasisAppointmentCategory): string {
    return `${data.id} - ${data.label}`;
  }

  getFilterData(
    _data: IOasisAppointmentCategory,
    _timezone: Timezone
  ): IOasisAppointmentCategoryFilters {
    return {};
  }
}
