<span class="provider"
  >{{ name$ | ngrxPush }}
  <span class="mat-caption" *ngrxLet="providerNumber$ as providerNumber">
    <ng-container *ngIf="providerNumber; else noProviderData">
      ({{ providerNumber }})
    </ng-container>
    <ng-template #noProviderData>
      <span class="mat-error">Missing Provider Data</span>
    </ng-template>
  </span>
</span>
