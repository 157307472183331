import { Component, EventEmitter, Input, Output } from '@angular/core';
import { type ITreatmentConfiguration } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import {
  type IAsset,
  type IAssetRequirement,
  isConsumableAsset,
} from '@principle-theorem/principle-core/interfaces';
import { AssetRequirements } from '@principle-theorem/principle-core';

@Component({
  selector: 'pr-treatment-asset-line-item',
  templateUrl: './treatment-asset-line-item.component.html',
  styleUrls: ['./treatment-asset-line-item.component.sass'],
})
export class TreatmentAssetLineItemComponent {
  @Input() treatmentConfig: WithRef<ITreatmentConfiguration>;
  @Input() requirement: IAssetRequirement;
  @Input() asset: IAsset;
  @Output() save: EventEmitter<void> = new EventEmitter<void>();

  showDuration(): boolean {
    return isConsumableAsset(this.asset) ? false : true;
  }

  delete(requirement: IAssetRequirement): void {
    AssetRequirements.removeRequirement(
      this.treatmentConfig.assetRequirements,
      requirement
    );
    this.save.emit();
  }

  increaseQty(item: IAssetRequirement): void {
    item.quantity += 1;
    this.save.emit();
  }

  decreaseQty(item: IAssetRequirement): void {
    item.quantity -= 1;
    this.save.emit();
  }

  increaseDuration(item: IAssetRequirement): void {
    item.duration = item.duration ? (item.duration += 5) : 5;
    this.save.emit();
  }

  decreaseDuration(item: IAssetRequirement): void {
    item.duration = item.duration ? (item.duration -= 5) : 0;
    this.save.emit();
  }
}
