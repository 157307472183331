import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_FORMAT,
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { PatientSourceEntity } from './patient';

export const PATIENT_PERIODONTAL_RESOURCE_TYPE = 'patientPeriodontal';

export const PATIENT_PERIODONTAL_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Periodontal List',
      description: '',
      idPrefix: PATIENT_PERIODONTAL_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IPraktikaPeriodontalChart {
  perioexam_id: number;
  perioexam_patientid: number;
  perioexam_providerid: number;
  perioexam_date: string;
  perioexam_notes: string | null;
  perioexam_psrsextant1: string;
  perioexam_psrsextant2: string;
  perioexam_psrsextant3: string;
  perioexam_psrsextant4: string;
  perioexam_psrsextant5: string;
  perioexam_psrsextant6: string;
  perioexam_cptinsextant1: number | null;
  perioexam_cptinsextant2: number | null;
  perioexam_cptinsextant3: number | null;
  perioexam_cptinsextant4: number | null;
  perioexam_cptinsextant5: number | null;
  perioexam_cptinsextant6: number | null;
  perioexam_boneloss: number | null;
  perioexam_systemicfactors: false | null;
  date_created: string;
  date_updated: string | null;

  // This needs a query
  // perioexam_toothdata: IPraktikaPerioToothData[];

  // perioexam_diagnosis: null | '2' | 'P';
}

export function isPraktikaPeriodontalChart(
  item: unknown
): item is IPraktikaPeriodontalChart {
  return TypeGuard.interface<IPraktikaPeriodontalChart>({
    perioexam_id: isNumber,
    perioexam_patientid: isNumber,
    perioexam_providerid: isNumber,
    perioexam_date: isString,
    perioexam_notes: TypeGuard.nilOr(isString),
    perioexam_psrsextant1: isString,
    perioexam_psrsextant2: isString,
    perioexam_psrsextant3: isString,
    perioexam_psrsextant4: isString,
    perioexam_psrsextant5: isString,
    perioexam_psrsextant6: isString,
    perioexam_cptinsextant1: TypeGuard.nilOr(isNumber),
    perioexam_cptinsextant2: TypeGuard.nilOr(isNumber),
    perioexam_cptinsextant3: TypeGuard.nilOr(isNumber),
    perioexam_cptinsextant4: TypeGuard.nilOr(isNumber),
    perioexam_cptinsextant5: TypeGuard.nilOr(isNumber),
    perioexam_cptinsextant6: TypeGuard.nilOr(isNumber),
    perioexam_boneloss: TypeGuard.nilOr(isNumber),
    perioexam_systemicfactors: TypeGuard.nilOr(isBoolean),
    date_created: isString,
    date_updated: TypeGuard.nilOr(isString),
    // perioexam_diagnosis: TypeGuard.nilOr(
    //   (data: unknown): data is null | '2' | 'P' =>
    //     isString(data) && ['2', 'P'].includes(item.perioexam_diagnosis)
    // ),
    // perioexam_toothdata: TypeGuard.arrayOf(isPerioToothData),
  })(item);
}

export interface IPraktikaPeriodontalChartFilters {
  patientId: string;
  stafferId: string;
  date: Timestamp;
}

export interface IPraktikaPeriodontalChartTranslations {
  date: Timestamp;
}

const PATIENT_PERIODONTAL_CHART_SOURCE_QUERY = `
SELECT
  iPatientNumber as perioexam_patientid,
  iPerioExamId as perioexam_id,
  iProviderId as perioexam_providerid,
  dtExamDate as perioexam_date,
  vchExamNote as perioexam_notes,
  vchPSR_sextant1 as perioexam_psrsextant1,
  vchPSR_sextant2 as perioexam_psrsextant2,
  vchPSR_sextant3 as perioexam_psrsextant3,
  vchPSR_sextant4 as perioexam_psrsextant4,
  vchPSR_sextant5 as perioexam_psrsextant5,
  vchPSR_sextant6 as perioexam_psrsextant6,
  NULLIF(vchCPITN_sextant1, '') as perioexam_cptinsextant1,
  NULLIF(vchCPITN_sextant2, '') as perioexam_cptinsextant2,
  NULLIF(vchCPITN_sextant3, '') as perioexam_cptinsextant3,
  NULLIF(vchCPITN_sextant4, '') as perioexam_cptinsextant4,
  NULLIF(vchCPITN_sextant5, '') as perioexam_cptinsextant5,
  NULLIF(vchCPITN_sextant6, '') as perioexam_cptinsextant6,
  iAlveolarBoneLoss AS perioexam_boneloss,
  bSystemicFactors AS perioexam_systemicfactors,
  dtcreated as date_created,
  dtupdated as date_updated
FROM patient_perio_exams
ORDER BY perioexam_id DESC
`;

export class PatientPeriodontalPatientChartSourceEntity extends BaseSourceEntity<
  IPraktikaPeriodontalChart,
  IPraktikaPeriodontalChartTranslations,
  IPraktikaPeriodontalChartFilters
> {
  sourceEntity = PATIENT_PERIODONTAL_SOURCE_ENTITY;
  entityResourceType = PATIENT_PERIODONTAL_RESOURCE_TYPE;
  sourceQuery = PATIENT_PERIODONTAL_CHART_SOURCE_QUERY;
  verifySourceFn = isPraktikaPeriodontalChart;
  override transformDataFn = flow([]);

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  translate(
    chart: IPraktikaPeriodontalChart,
    timezone: Timezone
  ): IPraktikaPeriodontalChartTranslations {
    return {
      date: toTimestamp(
        moment
          .tz(chart.perioexam_date, ISO_DATE_FORMAT, timezone)
          .startOf('day')
      ),
    };
  }

  getSourceRecordId(data: IPraktikaPeriodontalChart): number {
    return data.perioexam_id;
  }

  getSourceLabel(data: IPraktikaPeriodontalChart): string {
    return `${data.perioexam_id} - ${data.perioexam_date}`;
  }

  getFilterData(
    data: IPraktikaPeriodontalChart,
    timezone: Timezone
  ): IPraktikaPeriodontalChartFilters {
    return {
      patientId: data.perioexam_patientid.toString(),
      stafferId: data.perioexam_providerid.toString(),
      date: toTimestamp(
        moment.tz(data.date_created, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }
}
