<mat-toolbar color="accent">Invite User</mat-toolbar>

<form [formGroup]="inviteForm" (ngSubmit)="submit()">
  <mat-dialog-content>
    <div fxLayout="column">
      <mat-form-field class="flex-1">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" cdkFocusInitial />
      </mat-form-field>

      <mat-form-field class="flex-1">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" class="!lowercase" />
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary">Invite</button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
