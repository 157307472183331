<pr-patient-summary
  [patient]="patient"
  [appointment]="appointment$ | async"
 />

<mat-divider />

<mat-tab-group fxFlex mat-stretch-tabs class="full-height">
  <mat-tab label="Scheduling">
    <ng-template matTabContent>
      <pr-appointment-interactions
        *ngIf="
          appointment$ | async as appointment;
          else newAppointmentInteractions
        "
        fxFlex
        compact
        enableScroll
        [appointment]="appointment"
       />

      <ng-template #newAppointmentInteractions>
        <pr-interactions
          fxFlex
          editable
          compact
          [enableScroll]="true"
          [interactions]="interactions$ | async"
          (interactionAdded)="addInteraction($event)"
          (interactionUpdated)="updateInteraction($event)"
          (interactionDeleted)="deleteInteraction($event)"
         />
      </ng-template>
    </ng-template>
  </mat-tab>

  <mat-tab label="Social" [disabled]="!patient">
    <ng-template matTabContent>
      <pr-notes
        *ngIf="patient"
        fxFlex
        [resource]="patient"
        [scroll]="true"
        [addNoteBottom]="true"
       />
    </ng-template>
  </mat-tab>

  <mat-tab [disabled]="!patient">
    <ng-template matTabLabel>
      <div class="mat-tab-label-content">Patient</div>
    </ng-template>
    <ng-template matTabContent>
      <pr-patient-interactions
        fxFlex
        compact
        enableScroll
        [patient]="patient"
       />
    </ng-template>
  </mat-tab>
</mat-tab-group>
