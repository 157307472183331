import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import { type AppointmentCardAction } from '../../appointment-card/appointment-card-actions/actions/appointment-card-action-interface';
import { CheckinAppointmentActionService } from '../../appointment-card/appointment-card-actions/actions/checkin-appointment-action.service';
import { CheckoutAppointmentActionService } from '../../appointment-card/appointment-card-actions/actions/checkout-appointment-action.service';
import { CompleteAppointmentActionService } from '../../appointment-card/appointment-card-actions/actions/complete-appointment-action.service';
import { ConfirmAppointmentActionService } from '../../appointment-card/appointment-card-actions/actions/confirm-appointment-action.service';
import { OpenAppointmentActionService } from '../../appointment-card/appointment-card-actions/actions/open-appointment-action.service';
import { StartAppointmentActionService } from '../../appointment-card/appointment-card-actions/actions/start-appointment-action.service';

@Component({
    selector: 'pr-appointment-quick-actions',
    templateUrl: './appointment-quick-actions.component.html',
    styleUrls: ['./appointment-quick-actions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentQuickActionsComponent {
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);
  patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);
  actions: AppointmentCardAction[];
  @Input() showMenu: boolean = true;

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(
    confirmAppointment: ConfirmAppointmentActionService,
    checkoutAppointment: CheckoutAppointmentActionService,
    checkinAppointment: CheckinAppointmentActionService,
    startAppointment: StartAppointmentActionService,
    openAppointment: OpenAppointmentActionService,
    finishAppointment: CompleteAppointmentActionService
  ) {
    this.actions = [
      confirmAppointment,
      checkoutAppointment,
      checkinAppointment,
      startAppointment,
      openAppointment,
      finishAppointment,
    ];
  }
}
