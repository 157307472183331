<div class="flex items-center justify-end">
  <pt-multi-filter-group
    tooltip="Filter by status"
    [statusFilters]="stepDisplay.treatmentStepStatusOptions"
    [activeStatuses]="stepDisplay.activeStatusFilters()"
    [displayBadge]="stepDisplay.hasActiveStatusFilters()"
    (statusFilterChange)="stepDisplay.updateUsersFilteredStatuses($event)"
  />
</div>
<ng-container *ngIf="store.filteredConsolidatedPlan$ | async as plans">
  <ng-container *ngIf="plans.groups.length; else noPlans">
    <div cdkDropListGroup class="drag-group">
      <div fxLayout="column" fxLayoutGap="16px">
        <ng-container
          *ngFor="let group of plans.groups; trackBy: plans.trackByGroup"
        >
          <div class="group-label">
            <a
              matTooltip="View Treatment Plan"
              (click)="selectedTreatmentPlan.emit(group.group)"
              class="action-link plan-name"
              href="javascript:void(0);"
            >
              {{ group.group.name }}
            </a>
          </div>

          <div
            fxLayout="column"
            fxLayoutGap="16px"
            cdkDropList
            [cdkDropListData]="group | map: toDragNode"
            [cdkDropListConnectedTo]="dragDrop.lists$ | ngrxPush"
            [cdkDropListEnterPredicate]="dragDrop.enterPredicate"
            (cdkDropListDropped)="dragDrop.drop($event)"
          >
            <ng-container
              *ngFor="
                let pair of group.items;
                trackBy: plans.trackByOption;
                let isLast = last
              "
            >
              <pt-drag-box
                class="layout"
                cdkDrag
                cdkScrollable
                [cdkDragData]="pair.value"
                [cdkDragDisabled]="pair.value | map: dragDisabled : this"
              >
                <mat-icon cdkDragHandle class="drag-handle"
                  >drag_handle</mat-icon
                >

                <pr-treatment-step
                  [step]="pair.value.treatmentStep"
                  [plan]="pair.value.treatmentPlan"
                  (stepChanged)="
                    store.updateStep(pair.value.treatmentStep, $event)
                  "
                  (stepDeleted)="
                    store.deleteStep(pair.value.treatmentPlan, $event)
                  "
                  (updateChartable)="
                    updateSurfaces($event, pair.value.treatmentStep)
                  "
                />
              </pt-drag-box>
            </ng-container>
          </div>

          <div
            class="add-step step-container layout"
            *ngIf="group | map: canAddStep"
            [matTooltip]="stepDisplay.unscheduledHiddenTooltip()"
          >
            <a
              href="javascript:void(0);"
              class="action-link"
              (click)="addStep(group.group)"
            >
              <mat-icon>add</mat-icon> Create New Step
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="store.loading$ | async">
  <div fxLayout="column" fxLayoutGap="16px">
    <pr-treatment-step-skeleton />
    <pr-treatment-step-skeleton />
    <pr-treatment-step-skeleton />
  </div>
</ng-container>

<ng-template #noPlans>
  <pr-empty-state
    *ngIf="(store.loading$ | async) === false"
    image="list"
    title="treatment steps"
  />
</ng-template>
