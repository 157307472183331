import {
  Firestore,
  IDocUpdateOptions,
  SystemActors,
  addDoc,
  deleteDoc,
  updateDoc,
  upsertBulk,
  upsertDoc,
  type CollectionReference,
  type DocumentReference,
  type Reffable,
  type Timestamp,
} from '@principle-theorem/shared';

export class FirestoreMigrate {
  static addDoc<T extends object>(
    collection: CollectionReference<T>,
    item: T & Partial<ICreatedAt>,
    uid?: string
  ): Promise<DocumentReference<T>> {
    return addDoc(collection, item, uid, undefined, SystemActors.Migration);
  }

  static upsertDoc<T extends object>(
    collection: CollectionReference<T>,
    item: T & Partial<ICreatedAt>,
    uid?: string
  ): Promise<DocumentReference<T>> {
    return upsertDoc(
      collection,
      item,
      uid,
      undefined,
      undefined,
      SystemActors.Migration
    );
  }

  static patchDoc<T extends object>(
    ref: DocumentReference<T>,
    data: Partial<T>,
    options?: IDocUpdateOptions
  ): Promise<DocumentReference<Partial<T>>> {
    return Firestore.patchDoc(
      ref,
      data,
      undefined,
      options,
      SystemActors.Migration
    );
  }

  static updateDoc<T extends object>(
    ref: DocumentReference<T>,
    data: Partial<T>
  ): Promise<void> {
    return updateDoc(ref, data, undefined, SystemActors.Migration);
  }

  static saveDoc<T extends object>(
    item: Reffable<T>
  ): Promise<DocumentReference<T>> {
    return Firestore.saveDoc(
      item,
      undefined,
      undefined,
      undefined,
      SystemActors.Migration
    );
  }

  static deleteDoc<T>(itemRef: DocumentReference<T>): Promise<void> {
    return deleteDoc(itemRef, undefined, SystemActors.Migration);
  }

  static upsertBulk<T extends object>(
    collection: CollectionReference<T>,
    data: T[],
    uidPath: keyof T
  ): Promise<void> {
    return upsertBulk(collection, data, uidPath, SystemActors.Migration);
  }
}

interface ICreatedAt {
  createdAt: Timestamp;
}
