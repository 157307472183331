import { type IReffable } from '@principle-theorem/shared';
import { type INotification } from './notification';
import { type INotificationContextRenderer } from './context-renderer';

export function renderNotification<RenderOutput>(
  notification: INotification,
  providers: INotificationContextRenderer<RenderOutput>[],
  currentUser: IReffable
): RenderOutput | undefined {
  const renderer = providers.find((provider) =>
    provider.canRender(notification)
  );

  if (!renderer || !renderer.isResource(notification.resources)) {
    return;
  }

  return renderer.render(notification.resources, currentUser);
}
