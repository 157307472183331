import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { type IsSameFn } from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { type Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface ISelectionListState<T> {
  options: T[];
  selected: T[];
  isAllSelected: boolean;
  isSomeSelected: boolean;
  isNoneSelected: boolean;
}

const initialState: ISelectionListState<unknown> = {
  options: [],
  selected: [],
  isAllSelected: false,
  isSomeSelected: false,
  isNoneSelected: true,
};

@Injectable()
export class SelectionListStore<T> extends ComponentStore<
  ISelectionListState<T>
> {
  private _compareFn: IsSameFn<T> = isEqual;
  selected$ = this.select((state) => state.selected);
  isAllSelected$ = this.select((state) => state.isAllSelected);
  isSomeSelected$ = this.select((state) => state.isSomeSelected);
  isNoneSelected$ = this.select((state) => state.isNoneSelected);

  readonly loadOptions = this.effect((options$: Observable<T[]>) => {
    return options$.pipe(
      tap((options) => {
        this.patchState({ options });
      })
    );
  });

  constructor() {
    super(initialState as ISelectionListState<T>);
  }

  setCompareFn(compareFn: IsSameFn<T>): void {
    this._compareFn = compareFn;
  }

  toggleSelected(option: T): void {
    const state = this.get();
    const isSelected = state.selected.some((item) =>
      this._compareFn(item, option)
    );
    const updatedSelected = isSelected
      ? state.selected.filter((item) => !this._compareFn(item, option))
      : [...state.selected, option];

    this._updateSelected(updatedSelected, state);
  }

  setSelected(option: T, setSelected: boolean): void {
    const state = this.get();
    const isSelected = state.selected.some((item) =>
      this._compareFn(item, option)
    );
    if (isSelected === setSelected) {
      return;
    }

    const updatedSelected = setSelected
      ? [...state.selected, option]
      : state.selected.filter((item) => !this._compareFn(item, option));

    this._updateSelected(updatedSelected, state);
  }

  toggleAllSelected(): void {
    const state = this.get();
    if (state.isAllSelected) {
      this.resetSelected();
      return;
    }

    this.patchState({
      selected: [...state.options],
      isAllSelected: true,
      isSomeSelected: false,
      isNoneSelected: false,
    });
  }

  resetSelected(): void {
    this.patchState({
      selected: [],
      isAllSelected: false,
      isSomeSelected: false,
      isNoneSelected: true,
    });
  }

  isSelected$(option: T): Observable<boolean> {
    return this.select((state) =>
      state.selected.some((item) => this._compareFn(item, option))
    );
  }

  private _updateSelected(
    updatedSelected: T[],
    state: ISelectionListState<T>
  ): void {
    this.patchState({
      selected: updatedSelected,
      isSomeSelected:
        updatedSelected.length > 0 &&
        updatedSelected.length !== state.options.length,
      isAllSelected:
        updatedSelected.length > 0 &&
        updatedSelected.length === state.options.length,
      isNoneSelected: updatedSelected.length === 0,
    });
  }
}
