<div fxLayout="row" fxLayoutAlign="center center" class="container">
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="label mat-caption"
  >
    <ng-content select="label"></ng-content>
  </div>

  <button
    type="button"
    mat-icon-button
    (click)="decrease.emit()"
    [disabled]="decreaseDisabled"
  >
    <mat-icon>remove</mat-icon>
  </button>

  <div class="content">
    <ng-content></ng-content>
  </div>

  <button
    type="button"
    mat-icon-button
    (click)="increase.emit()"
    [disabled]="increaseDisabled"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>
