import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type IMedicareCard } from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-edit-medicare-card',
  templateUrl: './edit-medicare-card.component.html',
  styleUrls: ['./edit-medicare-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMedicareCardComponent {
  updatedMedicareCard: IMedicareCard | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public medicareCard: IMedicareCard | undefined,
    private _dialogRef: MatDialogRef<
      EditMedicareCardComponent,
      IMedicareCard | undefined
    >
  ) {}

  updateMedicareCard(cardUpdates: IMedicareCard): IMedicareCard {
    return (this.updatedMedicareCard = cardUpdates);
  }

  submit(): void {
    this._dialogRef.close(this.updatedMedicareCard);
  }
}
