import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { calendarReducer, CALENDAR_FEATURE_KEY } from './calendar.reducer';
import { CalendarEventsCollectionEffects } from './effects/calendar-events-collection.effects';
import { CalendarRouterEffects } from './effects/calendar-router.effects';
import { CalendarEffects } from './effects/calendar.effects';
import { CalendarEventsFacade } from './facades/calendar-events.facade';
import { CalendarFacade } from './facades/calendar.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CALENDAR_FEATURE_KEY, calendarReducer),
    EffectsModule.forFeature([
      CalendarEffects,
      CalendarEventsCollectionEffects,
      CalendarRouterEffects,
    ]),
  ],
  providers: [CalendarFacade, CalendarEventsFacade],
})
export class NgCalendarStoreModule {}
