import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'pr-pricing-scope-help',
    templateUrl: './pricing-scope-help.component.html',
    styleUrls: ['./pricing-scope-help.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PricingScopeHelpComponent {}
