import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  GapStoreService,
  IGapSearch,
} from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { IStaffer } from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  WithRef,
  isSameRef,
  snapshot,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-gap-search-filters',
  templateUrl: './gap-search-filters.component.html',
  styleUrls: ['./gap-search-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GapSearchFiltersComponent {
  trackByStaff = TrackByFunctions.ref<WithRef<IStaffer>>();
  dateRangeDisplay$: Observable<string | undefined>;

  constructor(public gapStore: GapStoreService) {
    this.dateRangeDisplay$ = this.gapStore.searchFilters$.pipe(
      map((filters) => {
        if (!filters?.dateRange) {
          return;
        }
        return `${filters.dateRange.from.format(
          DAY_MONTH_YEAR_FORMAT
        )} - ${filters.dateRange.to.format(DAY_MONTH_YEAR_FORMAT)}`;
      })
    );
  }

  async removeFilter(value: Partial<IGapSearch>): Promise<void> {
    const searchFilters = await snapshot(this.gapStore.searchFilters$);
    if (!searchFilters) {
      return;
    }
    const filterData: IGapSearch = { ...searchFilters, ...value };
    await this.gapStore.getSearchResults(filterData);
  }

  async removeDateRangeFilter(): Promise<void> {
    const searchFilters = await snapshot(this.gapStore.searchFilters$);
    const filterData: IGapSearch = { ...searchFilters, dateRange: undefined };
    await this.gapStore.getSearchResults(filterData);
  }

  async removePractitionerFilter(
    filters: IGapSearch,
    staffer: WithRef<IStaffer>
  ): Promise<void> {
    const searchFilters = await snapshot(this.gapStore.searchFilters$);
    const practitioners = searchFilters?.practitioners?.filter(
      (practitioner) => !isSameRef(practitioner, staffer)
    );
    const filterData: IGapSearch = { ...filters, practitioners };
    await this.gapStore.getSearchResults(filterData);
  }
}
