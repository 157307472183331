import {
  BaseFirestoreMock,
  getEnumValues,
  Properties,
} from '@principle-theorem/shared';
import {
  ADA_SERVICE_CODES,
  ChartableSurface,
  IChartedItemConfiguration,
  IPricedServiceCodeEntry,
  IScopedServiceCode,
  IServiceCode,
  IServiceCodeEntry,
  ServiceCodeType,
} from '@principle-theorem/principle-core/interfaces';
import { rand, randFloat, randNumber, randSentence } from '@ngneat/falso';
import { calculateTaxFromTotal, TaxRate } from '@principle-theorem/accounting';
import { v4 as uuid } from 'uuid';
import { MockNamedDocument } from '@principle-theorem/testing';
import { PricingRule } from '../fees/pricing-rules/pricing-rule';

export function randomAdaCode(): IServiceCode {
  return ADA_SERVICE_CODES[
    Math.floor(Math.random() * ADA_SERVICE_CODES.length)
  ];
}

export class ServiceCodeMock
  extends BaseFirestoreMock
  implements Properties<IServiceCode>
{
  private static adaCode = randomAdaCode();
  claimCode = ServiceCodeMock.adaCode.code;
  rootCode = ServiceCodeMock.adaCode.code;
  fee = randFloat({ min: 1, max: 1000 });
  notes = randSentence();
  title = ServiceCodeMock.adaCode.title;
  description = ServiceCodeMock.adaCode.description;
  rules = {};
  scope = [rand(getEnumValues(ChartableSurface))];
  taxStatus = ServiceCodeMock.adaCode.taxStatus;
  code = ServiceCodeMock.adaCode.code;
  type = rand(getEnumValues(ServiceCodeType));
}

export class ScopedServiceCodeMock
  extends ServiceCodeMock
  implements Properties<IScopedServiceCode>
{
  private static scopedAdaCode = randomAdaCode();
  override code = ScopedServiceCodeMock.scopedAdaCode.code;
  override type = rand(getEnumValues(ServiceCodeType));
}

export class ServiceCodeEntryMock
  extends ScopedServiceCodeMock
  implements Properties<IServiceCodeEntry>
{
  quantity = randNumber({ min: 1, max: 3 });
}

export class PricedServiceCodeEntryMock
  extends ServiceCodeEntryMock
  implements Properties<IPricedServiceCodeEntry>
{
  uuid = uuid();
  pricingRule = PricingRule.init();
  price = randFloat({ min: 1, max: 1000 });
  priceOverride = randFloat({ min: 1, max: 1000 });
  tax = calculateTaxFromTotal(this.priceOverride, TaxRate.GST);
  chartedItemOrigin = MockNamedDocument<IChartedItemConfiguration>();
  chartedSurfaces = [];
}
