import { Media, Patient } from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  ISourceEntityRecord,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IMedia,
  type IPatient,
  type IPracticeMigration,
  type ISource,
  type MergeConflictDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  asyncForEach,
  getError,
  toTimestamp,
  type DocumentReference,
  type Timezone,
  type WithRef,
  Timestamp,
} from '@principle-theorem/shared';
import { compact, groupBy, uniq } from 'lodash';
import * as moment from 'moment-timezone';
import { from, type Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { FirestoreMigrate } from '../../../destination/destination';
import { DestinationEntity } from '../../../destination/destination-entity';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import {
  CopyFiles,
  getBucketStoragePath,
  type IStorageSyncResponse,
} from '../../../storage';
import { type TranslationMapHandler } from '../../../translation-map';
import { PatientDestinationEntity } from './patients';
import {
  ICorePracticePatient,
  ICorePracticePatientFilters,
  ICorePracticePatientTranslations,
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
} from '../../source/entities/patients';
import {
  ICorePracticePatientMedia,
  ICorePracticePatientMediaFilters,
  ICorePracticePatientMediaTranslations,
  PatientMediaSourceEntity,
} from '../../source/entities/patient-media';
import {
  ICorePracticePatientAttachment,
  ICorePracticePatientAttachmentFilters,
  ICorePracticePatientAttachmentTranslations,
  PatientAttachmentSourceEntity,
} from '../../source/entities/patient-attachments';

export const PATIENT_FILE_RESOURCE_TYPE = 'patientFiles';

export const PATIENT_FILE_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_FILE_RESOURCE_TYPE,
    label: 'Patient Files',
    description: '',
  },
});

export interface IPatientFileDestinationRecord {
  fileRefs: DocumentReference<IMedia>[];
}

export interface IPatientFileJobData {
  sourcePatient: IGetRecordResponse<
    ICorePracticePatient,
    ICorePracticePatientTranslations,
    ICorePracticePatientFilters
  >;
  files: IFileSummary[];
}

interface IFileSummary {
  patientId: string;
  fileName: string;
  folderPath: string;
}

export interface IPatientFileMigrationData {
  sourcePatientId: string;
  patientRef: DocumentReference<IPatient>;
  images: IGetRecordResponse<
    ICorePracticePatientMedia,
    ICorePracticePatientMediaTranslations,
    ICorePracticePatientMediaFilters
  >[];
  attachments: IGetRecordResponse<
    ICorePracticePatientAttachment,
    ICorePracticePatientAttachmentTranslations,
    ICorePracticePatientAttachmentFilters
  >[];
  files: IFileSummary[];
}

export class PatientFileDestinationEntity extends BaseDestinationEntity<
  IPatientFileDestinationRecord,
  IPatientFileJobData,
  IPatientFileMigrationData
> {
  destinationEntity = PATIENT_FILE_DESTINATION_ENTITY;

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    patientMedia: new PatientMediaSourceEntity(),
    patientAttachments: new PatientAttachmentSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  sourceCountDataAccessor(
    data: IPatientFileJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    skipMigrated: boolean
  ): Observable<IPatientFileJobData[]> {
    const patientFiles$ = from(
      groupImagesByPatient(
        migration.configuration.projectId,
        getBucketStoragePath(migration.source),
        ''
      )
    );

    return patientFiles$.pipe(
      concatMap((patientFiles) =>
        this.sourceEntities.patients
          .getRecords$(
            migration,
            1000,
            buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
          )
          .pipe(
            map((sourcePatients) =>
              sourcePatients.map((sourcePatient) => ({
                sourcePatient,
                files:
                  patientFiles[sourcePatient.data.data.id.toString()] || [],
              }))
            )
          )
      )
    );
  }

  getDestinationEntityRecordUid(data: IPatientFileJobData): string {
    return data.sourcePatient.record.uid;
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientFileJobData
  ): Promise<
    | IPatientFileMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.id;
    const images = await this.sourceEntities.patientMedia.filterRecords(
      migration,
      'patientId',
      sourcePatientId
    );

    const attachments =
      await this.sourceEntities.patientAttachments.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `Couldn't resolve patient`
      );
    }

    return {
      sourcePatientId: sourcePatientId.toString(),
      patientRef,
      images,
      attachments,
      files: data.files,
    };
  }

  hasMergeConflict(
    _translationMap: TranslationMapHandler,
    _data: IPatientFileMigrationData
  ): IPatientFileMigrationData | undefined {
    return;
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientFileJobData,
    _migrationData: IPatientFileMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    data: IPatientFileJobData,
    migrationData: IPatientFileMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const fileRefs = await this._addMediaToPatient(
        migrationData.sourcePatientId,
        migrationData.patientRef,
        migrationData.images,
        migrationData.attachments,
        migrationData.files,
        translationMapHandler,
        migration.source,
        migration.configuration.projectId,
        migration.configuration.destinationBucket,
        migration.configuration.timezone
      );
      return this._buildSuccessResponse(data.sourcePatient, fileRefs);
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  private _buildSuccessResponse(
    sourceFile: IGetRecordResponse<
      ICorePracticePatient,
      ICorePracticePatientTranslations
    >,
    fileRefs: DocumentReference<IMedia>[]
  ): IDestinationEntityRecord<IPatientFileDestinationRecord> {
    return {
      uid: sourceFile.record.uid,
      label: sourceFile.record.label,
      data: {
        fileRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  private _buildErrorResponse(
    sourcePatient: IGetRecordResponse<
      ICorePracticePatient,
      ICorePracticePatientTranslations
    >,
    errorMessage: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage,
      failData: {
        patientRef: sourcePatient.record.ref,
      },
    };
  }

  private async _addMediaToPatient(
    sourcePatientId: string,
    patientRef: DocumentReference<IPatient>,
    images: IGetRecordResponse<
      ICorePracticePatientMedia,
      ICorePracticePatientMediaTranslations,
      ICorePracticePatientMediaFilters
    >[],
    attachments: IGetRecordResponse<
      ICorePracticePatientAttachment,
      ICorePracticePatientAttachmentTranslations,
      ICorePracticePatientAttachmentFilters
    >[],
    files: IFileSummary[],
    translationMap: TranslationMapHandler,
    source: ISource,
    projectId: string,
    destinationBucket: string,
    _timezone: Timezone
  ): Promise<DocumentReference<IMedia>[]> {
    // eslint-disable-next-line no-console
    console.log(
      `Found ${files.length} files for patient ${sourcePatientId} in the bucket`
    );

    const folderPath = files[0]?.folderPath;

    if (!files.length || !folderPath) {
      return [];
    }

    const copyFolderResponse = await this._copySourceFilesToDestination(
      getBucketStoragePath(source),
      `${folderPath}/`,
      patientRef,
      projectId,
      destinationBucket
    );

    return asyncForEach(copyFolderResponse.storageFiles, async (file) => {
      const fileDestinationRef = await translationMap.getDestination(
        file.path,
        PATIENT_FILE_RESOURCE_TYPE
      );

      const fileName = file.path.split('/').pop();
      const matchingImage = images.find(
        (image) => image.data.data.fileName === fileName
      );

      const matchingAttachment = attachments.find(
        (image) => image.data.data.fileName === fileName
      );

      const createdAt = this._determineCreatedAt(
        matchingImage,
        matchingAttachment
      );

      // Map the tag/category to the media
      // CorePracticePatientMediaCategory
      // CorePracticePatientAttachmentTag

      const mediaRef = await FirestoreMigrate.upsertDoc(
        Patient.mediaCol({ ref: patientRef }),
        {
          ...Media.init({
            name: fileName,
            path: file.path,
            tags: [],
          }),
          createdAt,
        },
        fileDestinationRef?.id
      );

      if (!fileDestinationRef) {
        await translationMap.upsert({
          sourceIdentifier: file.path,
          destinationIdentifier: mediaRef,
          resourceType: PATIENT_FILE_RESOURCE_TYPE,
        });
      }

      return mediaRef;
    });
  }

  private _determineCreatedAt(
    matchingImage?: IGetRecordResponse<ICorePracticePatientMedia>,
    matchingAttachment?: IGetRecordResponse<ICorePracticePatientAttachment>
  ): Timestamp {
    if (matchingImage) {
      return toTimestamp(
        moment.utc(matchingImage.data.data.dateTaken, ISO_DATE_TIME_FORMAT)
      );
    }

    if (matchingAttachment) {
      return toTimestamp(
        moment.utc(
          matchingAttachment.data.data.attachmentDate,
          ISO_DATE_TIME_FORMAT
        )
      );
    }

    return toTimestamp();
  }

  private async _copySourceFilesToDestination(
    sourceBucket: string,
    sourcePath: string,
    patientRef: DocumentReference<IPatient>,
    projectId: string,
    destinationBucket: string
  ): Promise<IStorageSyncResponse> {
    const copyStorage = new CopyFiles(
      sourceBucket,
      sourcePath,
      destinationBucket,
      Patient.storagePath({ ref: patientRef }),
      projectId
    );

    return {
      storageFiles: await copyStorage.copyAll([/.*.db/], [], true),
    };
  }
}

export async function groupImagesByPatient(
  projectId: string,
  bucketName: string,
  bucketPath: string
): Promise<Record<string, IFileSummary[]>> {
  const storage = new CopyFiles(bucketName, bucketPath, '', '', projectId)
    .storage;

  const [files] = await storage.bucket(bucketName).getFiles({
    prefix: bucketPath,
  });

  const missingFields: string[] = [];

  const bucketFiles = uniq(
    compact(
      files.map((file) => {
        const folderPath = file.name.split('/');

        const fileSummary: IFileSummary = {
          patientId: '',
          fileName: '',
          folderPath: '',
        };

        const matches = new RegExp(/([0-9]+)\(/).exec(folderPath[0]);
        fileSummary.patientId = matches?.[1] ?? '';
        fileSummary.folderPath = folderPath[0] ?? '';
        fileSummary.fileName = folderPath[1] ?? '';

        if (!fileSummary.patientId || !fileSummary.fileName) {
          missingFields.push(file.name);
        }

        return fileSummary;
      })
    )
  );

  return groupBy(bucketFiles, 'patientId');
}
