import {
  BridgeDeviceFeatureStatus,
  BridgeDeviceStatus,
  IBridgeDeviceFeature,
  IPrincipleBridgeSettings,
} from '@principle-theorem/principle-core/interfaces';
import { IProvider, patchDoc } from '@principle-theorem/shared';
import { BridgeToCloudCommand } from './integrations/commands';
import { IBridgeIntegration, IDeviceCommand } from './send-command-to-device';
import { EZDENT_INTEGRATION } from './integrations/ezdent/ezdent';
import { HICAPS_CONNECT_INTEGRATION } from './integrations/hicaps-connect/hicaps-connect';
import { ROMEXIS_INTEGRATION } from './integrations/romexis/romexis';
import { SIDEXIS_INTEGRATION } from './integrations/sidexis/sidexis';
import { TWAIN_INTEGRATION } from './integrations/twain/twain';
import { VIXWIN_INTEGRATION } from './integrations/vixwin/vixwin';
import { CLINIVIEW_INTEGRATION } from './integrations/cliniview/cliniview';
import { IDIXEL_INTEGRATION } from './integrations/idixel/idixel';
import { VISTASOFT_INTEGRATION } from './integrations/vistasoft/vistasoft';
import { compact, isUndefined } from 'lodash';

export type PrincipleSettingsUpdate = Omit<
  IPrincipleBridgeSettings,
  'version' | 'deviceId' | 'integrationKey'
>;

export interface IPrincipleMethods {
  setSettings: (settings: Partial<IPrincipleBridgeSettings>) => Promise<void>;
  getSettings: () => Promise<IPrincipleBridgeSettings>;
  openLog: () => Promise<string>;
  statusChange: (listener: (status: BridgeDeviceStatus) => void) => void;
  openDirectoryPicker: () => Promise<string[]>;
  openFilePicker: () => Promise<string[]>;
}

export class SettingsCommandProvider
  implements IProvider<IDeviceCommand, Promise<void>>
{
  canProvide(data: IDeviceCommand): boolean {
    return data.command.type === String(BridgeToCloudCommand.Settings);
  }

  async execute(data: IDeviceCommand): Promise<void> {
    const settings = data.command.data as IPrincipleBridgeSettings;
    const features: IBridgeDeviceFeature[] = compact([
      this._getFeature(SIDEXIS_INTEGRATION, settings.sidexis?.enabled),
      this._getFeature(EZDENT_INTEGRATION, settings.ezdent?.enabled),
      this._getFeature(TWAIN_INTEGRATION, settings.twain?.enabled),
      this._getFeature(ROMEXIS_INTEGRATION, settings.romexis?.enabled),
      this._getFeature(
        HICAPS_CONNECT_INTEGRATION,
        settings.hicapsConnect?.enabled
      ),
      this._getFeature(VIXWIN_INTEGRATION, settings.vixWin?.enabled),
      this._getFeature(CLINIVIEW_INTEGRATION, settings.cliniview?.enabled),
      this._getFeature(IDIXEL_INTEGRATION, settings.iDixel?.enabled),
      this._getFeature(VISTASOFT_INTEGRATION, settings.vistaSoft?.enabled),
    ]);

    await patchDoc(data.deviceRef, {
      features,
      settings,
    });
  }

  private _getFeature(
    integration: IBridgeIntegration,
    enabled?: boolean
  ): IBridgeDeviceFeature | undefined {
    if (isUndefined(enabled)) {
      return;
    }
    return {
      ...integration,
      status: enabled
        ? BridgeDeviceFeatureStatus.Enabled
        : BridgeDeviceFeatureStatus.Disabled,
    };
  }
}
