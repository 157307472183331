<mat-toolbar color="accent">Update DVA Card</mat-toolbar>

<mat-dialog-content>
  <pr-dva-card-form [(dvaCard)]="dvaCard" />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close type="button">Cancel</button>
    <button mat-flat-button color="primary" (click)="submit()">Save</button>
  </pt-buttons-container>
</mat-dialog-actions>
