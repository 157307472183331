import {
  Component,
  ViewChild,
  HostBinding,
  ChangeDetectionStrategy,
} from '@angular/core';
import { type MatMenu, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'pt-menu-trigger-placeholder',
  templateUrl: './menu-trigger-placeholder.component.html',
  styleUrls: ['./menu-trigger-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'prMenuTriggerPlaceholder',
})
export class MenuTriggerPlaceholderComponent {
  @HostBinding('style.left') x: string;
  @HostBinding('style.top') y: string;
  menu: MatMenu;

  @ViewChild(MatMenuTrigger, { static: true })
  menuTrigger: MatMenuTrigger;
}
