import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IInvoice,
  type IPractice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  filterUndefined,
  getDoc,
  type INamedDocument,
  isSameRef,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first, uniqWith } from 'lodash';
import { type Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

export class TransactionPracticeOptions {
  trackByFn = TrackByFunctions.ref<WithRef<IPractice>>();
  options$: Observable<INamedDocument<IPractice>[]>;
  initialValue$: Observable<INamedDocument<IPractice>>;
  hint =
    'You are logged into a different practice than expected. Select which practice to track this transaction against.';

  constructor(
    currentPractice$: Observable<INamedDocument<IPractice> | undefined>,
    intendedPractice: INamedDocument<IPractice>,
    enableCurrentPractice: boolean = false
  ) {
    this.options$ = this._getOptions$(
      currentPractice$,
      intendedPractice,
      enableCurrentPractice
    );
    this.initialValue$ = this.options$.pipe(
      take(1),
      map((options) => first(options)),
      filterUndefined()
    );
  }

  compareFn(
    a: DocumentReference<IPractice>,
    b: DocumentReference<IPractice>
  ): boolean {
    if (!a || !b) {
      return false;
    }
    return isSameRef(a, b);
  }

  private _getOptions$(
    currentPractice$: Observable<INamedDocument<IPractice> | undefined>,
    intendedPractice: INamedDocument<IPractice>,
    enableCurrentPractice: boolean
  ): Observable<INamedDocument<IPractice>[]> {
    if (!enableCurrentPractice) {
      return of([intendedPractice]);
    }
    return currentPractice$.pipe(
      take(1),
      map((currentPractice) =>
        currentPractice
          ? TransactionPracticeOptionMethods.toPracticeOption(
              'Current',
              currentPractice
            )
          : undefined
      ),
      map((currentPractice) => {
        const practices = [intendedPractice, currentPractice];
        return uniqWith(compact(practices), isSameRef);
      })
    );
  }
}

export class TransactionPracticeOptionMethods {
  static toInvoicePracticeOption(invoice: IInvoice): INamedDocument<IPractice> {
    return this.toPracticeOption('Invoice', invoice.practice);
  }

  static async toTransactionPracticeOption(
    transaction: ITransaction
  ): Promise<INamedDocument<IPractice>> {
    const practice = await getDoc(transaction.practiceRef);
    return this.toPracticeOption('Transaction', practice);
  }

  static toPracticeOption(
    label: string,
    practice: INamedDocument<IPractice>
  ): INamedDocument<IPractice> {
    return {
      name: `${practice.name} (${label} Practice)`,
      ref: practice.ref,
    };
  }
}
