/* eslint-disable no-null/no-null */
import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_FORMAT,
  isObject,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { type IPraktikaAppointmentInvoicePayment } from './appointment-invoice-payment';
import { PatientSourceEntity } from './patient';

export const PATIENT_DEPOSIT_PAYMENT_RESOURCE_TYPE = 'patientDepositPayment';

export const PATIENT_DEPOSIT_PAYMENT_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Deposit Payment List',
      description: '',
      idPrefix: PATIENT_DEPOSIT_PAYMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IPraktikaDepositPayment
  extends IPraktikaAppointmentInvoicePayment {
  deposit_id: number;
  deposit_number: number;
  deposit_description: string;
  patient_id: number;
}

function isPraktikaDepositPayment(
  item: unknown
): item is IPraktikaDepositPayment {
  return (
    isObject(item) &&
    isNumber(item.deposit_id) &&
    isNumber(item.deposit_number) &&
    isString(item.deposit_description) &&
    isNumber(item.patient_id)
  );
}

export interface IPraktikaDepositPaymentFilters {
  patientId: string;
  effectiveDate: Timestamp;
}

export interface IPraktikaDepositPaymentTranslations {
  effectiveDate: Timestamp;
}

const PATIENT_DEPOSIT_PAYMENT_SOURCE_QUERY = `
SELECT
  iPatientNumber as patient_id,
  iDepositId as deposit_id,
  iDepositNumber as deposit_number,
  vchDepositDesc as deposit_description,
  vchNotes as notes,
  iPaymentId as id,
  iPaymentType as type_id,
  vchEffect as effect,
  iCreditCardType as card_type_id,
  vchDesc as description,
  vchCCDesc as card_type,
  dtEffectiveDate as effective_date,
  iAmount as amount,
  bReversal as is_reversal,
  iReversedPaymentId as reversed_payment_id,
  iCustomerPaymentId as customer_payment_id,
  iSurchargeAmount as surcharge_amount,
  iSurchargeAdjustmentId as surcharge_adjustment_id,
  vchMerchantId as merchant_id,
  vchTerminalId as terminal_id,
  vchProviderNumber as provider_number,
  vchProviderName as provider_name
FROM patient_deposit_payments
ORDER BY id DESC
`;

export class PatientDepositPaymentSourceEntity extends BaseSourceEntity<
  IPraktikaDepositPayment,
  IPraktikaDepositPaymentTranslations,
  IPraktikaDepositPaymentFilters
> {
  sourceEntity = PATIENT_DEPOSIT_PAYMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_DEPOSIT_PAYMENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_DEPOSIT_PAYMENT_SOURCE_QUERY;
  verifySourceFn = isPraktikaDepositPayment;
  override transformDataFn = flow([]);
  override dateFilterField: keyof IPraktikaDepositPaymentFilters =
    'effectiveDate';

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  translate(
    adjustment: IPraktikaDepositPayment,
    timezone: Timezone
  ): IPraktikaDepositPaymentTranslations {
    return {
      effectiveDate: toTimestamp(
        moment
          .tz(adjustment.effective_date, ISO_DATE_FORMAT, timezone)
          .startOf('day')
      ),
    };
  }

  getSourceRecordId(data: IPraktikaDepositPayment): string {
    return data.id.toString();
  }

  getSourceLabel(data: IPraktikaDepositPayment): string {
    return this.getSourceRecordId(data);
  }

  getFilterData(
    data: IPraktikaDepositPayment,
    timezone: Timezone
  ): IPraktikaDepositPaymentFilters {
    return {
      patientId: data.patient_id.toString(),
      effectiveDate: toTimestamp(
        moment.tz(data.effective_date, ISO_DATE_FORMAT, timezone).startOf('day')
      ),
    };
  }
}
