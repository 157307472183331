import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PROVIDER_RESOURCE_TYPE = 'providers';

export const PROVIDER_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Provider List',
    description: '',
    idPrefix: PROVIDER_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export enum CorePracticeProviderStatus {
  Active = 'Active',
  Archived = 'Archived',
}

export interface ICorePracticeProvider {
  id: number;
  title?: string;
  providerName: string; // Tregeagle D4W
  firstName: string; // "Philip"
  providerNumber: string; // 554381W
  status: CorePracticeProviderStatus;
  merchantId?: string; // 23038870
  taxFileNumber?: string;
  gstNumber?: string; // 38620204775
  isDeleted: boolean;
  locationId: number;
}

export function isCorePracticeProvider(
  item: unknown
): item is ICorePracticeProvider {
  return TypeGuard.interface<ICorePracticeProvider>({
    id: isNumber,
    title: TypeGuard.nilOr(isString),
    providerName: isString,
    firstName: isString,
    providerNumber: isString,
    status: TypeGuard.enumValue(CorePracticeProviderStatus),
    merchantId: TypeGuard.nilOr(isString),
    taxFileNumber: TypeGuard.nilOr(isString),
    gstNumber: TypeGuard.nilOr(isString),
    isDeleted: isBoolean,
    locationId: isNumber,
  })(item);
}

export interface ICorePracticeProviderTranslations {}

export interface ICorePracticeProviderFilters {
  locationId: number;
}

const PROVIDER_SOURCE_QUERY = `
SELECT
  ProviderId AS id,
  Title AS title,
  ProviderName AS provider_name,
  Firstname AS first_name,
  ProviderNumber AS provider_number,
  Status AS status,
  convert_to_text(MerchantId) AS merchant_id,
  TaxFileNumber AS tax_file_number,
  convert_to_text(GstNumber) AS gst_number,
  convert_to_boolean(IsDeleted) AS is_deleted,
  LocationId AS location_id
FROM tblProvider
`;

export class ProviderSourceEntity extends BaseSourceEntity<
  ICorePracticeProvider,
  ICorePracticeProviderTranslations,
  ICorePracticeProviderFilters
> {
  sourceEntity = PROVIDER_SOURCE_ENTITY;
  entityResourceType = PROVIDER_RESOURCE_TYPE;
  sourceQuery = PROVIDER_SOURCE_QUERY;
  verifySourceFn = isCorePracticeProvider;

  translate(
    _data: ICorePracticeProvider,
    _timezone: Timezone
  ): ICorePracticeProviderTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticeProvider): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticeProvider): string {
    return `${data.firstName} ${data.providerName}`;
  }

  getFilterData(
    data: ICorePracticeProvider,
    _timezone: Timezone
  ): ICorePracticeProviderFilters {
    return {
      locationId: data.locationId,
    };
  }
}
