import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { type IClinicalChart } from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import { isSameRef, isWithRef, type WithRef } from '@principle-theorem/shared';
import { CurrentPatientScope } from '@principle-theorem/ng-principle-shared';
import {
  TypedFormControl,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { type Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  chartHistoryItems$,
  type IChartHistoryItem,
} from './chart-history-list';

@Component({
    selector: 'pr-charting-history-selector',
    templateUrl: './charting-history-selector.component.html',
    styleUrls: ['./charting-history-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChartingHistorySelectorComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByItem = TrackByFunctions.label<IChartHistoryItem>();
  chartCtrl: TypedFormControl<WithRef<IClinicalChart>> = new TypedFormControl();
  items$: Observable<IChartHistoryItem[]>;

  @Output()
  selectedChange: EventEmitter<WithRef<IClinicalChart>> = new EventEmitter<
    WithRef<IClinicalChart>
  >();

  @Input()
  set selected(selected: WithRef<IClinicalChart>) {
    if (selected) {
      this.chartCtrl.setValue(selected, { emitEvent: false });
    }
  }

  constructor(private _patientScope: CurrentPatientScope) {
    this.items$ = this._patientScope.doc$.pipe(chartHistoryItems$());
    this.chartCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((selected) => this.selectedChange.emit(selected));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  compareFn(
    a: WithRef<IClinicalChart> | IChartHistoryItem | undefined,
    b: WithRef<IClinicalChart> | IChartHistoryItem | undefined
  ): boolean {
    const aRef = getRef(a);
    const bRef = getRef(b);
    return aRef && bRef ? isSameRef(aRef, bRef) : false;
  }
}

function getRef(
  item: WithRef<IClinicalChart> | IChartHistoryItem | undefined
): DocumentReference<IClinicalChart> | undefined {
  return isWithRef(item) ? item.ref : item?.value.ref;
}
