import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  forwardRef,
  type OnDestroy,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, type ControlValueAccessor } from '@angular/forms';
import { isObject } from '@principle-theorem/shared';
import { isNil, isNumber, isString } from 'lodash';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TypedFormControl } from '../forms/typed-form-group';
import { IconInputOption, OptionInput } from '../forms/mat-select-options';

@Component({
  selector: 'pt-settings-menu-toggle-group-item',
  templateUrl: './settings-menu-toggle-group-item.component.html',
  styleUrls: ['./settings-menu-toggle-group-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SettingsMenuToggleGroupItemComponent),
      multi: true,
    },
  ],
})
export class SettingsMenuToggleGroupItemComponent
  implements ControlValueAccessor, OnDestroy
{
  private _onDestroy$: Subject<void> = new Subject();
  private _onChange: (value?: OptionInput) => void;
  disabled$ = new BehaviorSubject<boolean>(false);
  emitEvent$ = new BehaviorSubject<boolean>(true);
  isIconOption$ = new BehaviorSubject<boolean>(false);
  options$ = new ReplaySubject<OptionInput[]>(1);
  @Input() label: string;
  @Input() tooltip?: string;
  formCtrl = new TypedFormControl<OptionInput>();

  @Input()
  set disabled(disabled: BooleanInput) {
    this.disabled$.next(coerceBooleanProperty(disabled));
  }

  @Input()
  set options(options: OptionInput[]) {
    if (options) {
      this.options$.next(options);
    }
  }

  @Input()
  set emitEvent(emitEvent: boolean) {
    if (!isNil(emitEvent)) {
      this.emitEvent$.next(emitEvent);
    }
  }

  constructor() {
    this.formCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((newValue) => {
        if (this._onChange) {
          this._onChange(newValue);
        }
      });

    this.options$.pipe(takeUntil(this._onDestroy$)).subscribe((options) => {
      this.isIconOption$.next(options.every(this.isIconOption));
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  writeValue(value: string): void {
    this.formCtrl.setValue(value ?? '', { emitEvent: this.emitEvent$.value });
  }

  registerOnChange(fn: () => void): void {
    this._onChange = fn;
  }

  registerOnTouched(_fn: () => void): void {
    //
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formCtrl.disable();
      return;
    }
    this.formCtrl.enable();
  }

  asString(item?: OptionInput): string {
    if (isString(item)) {
      return item;
    }
    if (isNumber(item)) {
      return item.toString();
    }
    if (this.isIconOption(item)) {
      return item.icon;
    }
    return '';
  }

  getValue(item?: OptionInput): string | number | undefined {
    return isString(item) || isNumber(item) ? item : item?.value;
  }

  isIconOption(item: unknown): item is IconInputOption {
    return (
      isObject(item) &&
      'icon' in item &&
      isString(item.icon) &&
      'value' in item &&
      (isString(item.value) || Number.isFinite(item.value))
    );
  }
}
