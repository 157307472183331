import { BlockNodes, type IMenuButton } from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../menu-bar/basic-menu-button/basic-menu-button.component';

export interface ITableAttributes {
  rowsCount: number;
  colsCount: number;
  withHeaderRow: boolean;
}

const CREATE_TABLE_DEFAULTS: ITableAttributes = {
  rowsCount: 3,
  colsCount: 3,
  withHeaderRow: false,
};

const data: IMenuButton = {
  buttonType: 'submenu-detail',
  buttonText: 'Table',
  tooltip: 'Table',
  icon: 'table_chart',
  isActiveFn: (editor) => editor.isActive(BlockNodes.TableWrapper),
  command: (editor) => {
    editor
      .chain()
      .focus()
      .insertTable({
        rows: CREATE_TABLE_DEFAULTS.rowsCount,
        cols: CREATE_TABLE_DEFAULTS.colsCount,
        withHeaderRow: CREATE_TABLE_DEFAULTS.withHeaderRow,
      })
      .scrollIntoView()
      .run();
    return true;
  },
};

export function createTableMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data,
  });
}

export function createTableBlockMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      ...data,
      buttonType: 'block',
    },
  });
}
