import { type IResizeSensorEvent } from '@principle-theorem/ng-shared';
import {
  isSameOptions,
  type IStaffer,
  type ITimelineDisplayOptions,
  TimelineDisplayOrientation,
  TimelineDisplayZoom,
  IScheduleSummaryEventable,
  ITimelineDataGroup,
  ITimelineDay,
} from '@principle-theorem/principle-core/interfaces';
import {
  type ITimePeriod,
  shareReplayCold,
  type WithRef,
} from '@principle-theorem/shared';
import { sum } from 'lodash';
import { combineLatest, type Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import {
  InteractiveTimelineDisplayCalculator,
  pixelPerMinuteForSpace,
} from './interactive-timeline-display-calculator';

export class TimelineViewHandler {
  containerDimensions$ = new ReplaySubject<IResizeSensorEvent>(1);
  options$: Observable<ITimelineDisplayOptions>;
  dragareaStyle$: Observable<Record<string, string>>;
  unitsHeaderStyle$: Observable<Record<string, string>>;
  spacerHeaderStyle$: Observable<Record<string, string>>;
  trackSize$: Observable<number>;
  isVertical$: Observable<boolean>;
  isHorizontal$: Observable<boolean>;

  constructor(
    options$: Observable<ITimelineDisplayOptions>,
    timeRange$: Observable<ITimePeriod>,
    zoom$: Observable<TimelineDisplayZoom>
  ) {
    this.isVertical$ = options$.pipe(
      map(
        (options) => options.orientation === TimelineDisplayOrientation.Vertical
      )
    );
    this.isHorizontal$ = this.isVertical$.pipe(
      map((isVertical) => !isVertical)
    );

    this.options$ = combineLatest([
      options$,
      zoom$.pipe(distinctUntilChanged()),
      timeRange$,
    ]).pipe(
      switchMap(([options, zoom, timeRange]) => {
        if (zoom !== TimelineDisplayZoom.AutoFit) {
          return of(options);
        }
        return this.containerDimensions$.pipe(
          map((limits) =>
            this._syncOptionsAsFullWidthView(options, timeRange, limits)
          )
        );
      }),
      distinctUntilChanged(isSameOptions),
      shareReplayCold()
    );

    this.dragareaStyle$ = combineLatest([this.options$, timeRange$]).pipe(
      map(([options, timeRange]) =>
        this._orientationAwareWidth(
          options,
          InteractiveTimelineDisplayCalculator.getTimelineSize(
            options,
            timeRange
          )
        )
      )
    );

    this.unitsHeaderStyle$ = combineLatest([this.options$, timeRange$]).pipe(
      map(([options, timeRange]) =>
        this._orientationAwareSize(
          options,
          InteractiveTimelineDisplayCalculator.getTimelineSize(
            options,
            timeRange
          ),
          this._unitsSize(options)
        )
      )
    );

    this.spacerHeaderStyle$ = this.options$.pipe(
      map((options) => {
        return this._orientationAwareSize(
          options,
          this._headerSize(options),
          this._unitsSize(options)
        );
      })
    );

    this.trackSize$ = this.options$.pipe(
      map((options) =>
        InteractiveTimelineDisplayCalculator.getNodeHeight(options)
      )
    );
  }

  getDaySize(
    options: ITimelineDisplayOptions,
    day: ITimelineDay<IScheduleSummaryEventable, WithRef<IStaffer>>
  ): number {
    return sum(
      day.groups.map((group) =>
        InteractiveTimelineDisplayCalculator.getGroupSize(options, group)
      )
    );
  }

  groupHeaderStyle(
    options: ITimelineDisplayOptions,
    group: ITimelineDataGroup<unknown, unknown>
  ): Record<symbol, string> {
    const groupSize = InteractiveTimelineDisplayCalculator.getGroupSize(
      options,
      group
    );
    return this._orientationAwareHeight(options, groupSize);
  }

  dayHeaderStyle(
    options: ITimelineDisplayOptions,
    day: ITimelineDay<IScheduleSummaryEventable, WithRef<IStaffer>>
  ): Record<symbol, string> {
    const groupSizes = day.groups.map((group) =>
      InteractiveTimelineDisplayCalculator.getGroupSize(options, group)
    );
    return this._orientationAwareSize(
      options,
      this._headerSize(options),
      sum(groupSizes)
    );
  }

  dropzoneStyle(
    options: ITimelineDisplayOptions,
    group: ITimelineDataGroup<unknown, unknown>
  ): Record<symbol, string> {
    return this.groupHeaderStyle(options, group);
  }

  dayTimelineStyle(
    options: ITimelineDisplayOptions,
    day: ITimelineDay<IScheduleSummaryEventable, WithRef<IStaffer>>
  ): Record<symbol, string> {
    const groupSizes = day.groups.map((group) =>
      InteractiveTimelineDisplayCalculator.getGroupSize(options, group)
    );
    const daySize = sum(groupSizes);
    return this._orientationAwareHeight(options, daySize);
  }

  private _syncOptionsAsFullWidthView(
    options: ITimelineDisplayOptions,
    timeRange: ITimePeriod,
    limits: IResizeSensorEvent
  ): ITimelineDisplayOptions {
    const containerSize = InteractiveTimelineDisplayCalculator.isHorizontal(
      options
    )
      ? limits.inner.width
      : limits.inner.height;
    const timelineSize = containerSize - this._headerSize(options);

    const scaledPixelsPerMin = pixelPerMinuteForSpace(
      timeRange.from,
      timeRange.to,
      timelineSize
    );
    return {
      ...options,
      stepSizeInPixels: options.stepSizeInMins * scaledPixelsPerMin,
    };
  }

  /**
   * Set width and height when horizontal, will flip dimentions when vertical
   */
  private _orientationAwareSize(
    options: ITimelineDisplayOptions,
    width: number,
    height: number
  ): Record<string, string> {
    const isHorizontal =
      options.orientation === TimelineDisplayOrientation.Horizontal;
    return {
      width: isHorizontal ? `${width}px` : `${height}px`,
      'min-width': isHorizontal ? `${width}px` : `${height}px`,
      height: isHorizontal ? `${height}px` : `${width}px`,
      'min-height': isHorizontal ? `${height}px` : `${width}px`,
    };
  }

  private _orientationAwareWidth(
    options: ITimelineDisplayOptions,
    width: number
  ): Record<string, string> {
    const property =
      options.orientation === TimelineDisplayOrientation.Horizontal
        ? 'width'
        : 'height';
    return { [property]: `${width}px` };
  }

  private _orientationAwareHeight(
    options: ITimelineDisplayOptions,
    width: number
  ): Record<string, string> {
    const property = InteractiveTimelineDisplayCalculator.isHorizontal(options)
      ? 'height'
      : 'width';
    return { [property]: `${width}px` };
  }

  private _headerSize(options: ITimelineDisplayOptions): number {
    return InteractiveTimelineDisplayCalculator.isHorizontal(options)
      ? 275
      : 85;
  }

  private _unitsSize(options: ITimelineDisplayOptions): number {
    return InteractiveTimelineDisplayCalculator.isHorizontal(options) ? 42 : 90;
  }
}
