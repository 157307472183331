import { Directive, EventEmitter, Output } from '@angular/core';
import { Storage } from '@angular/fire/storage';
import {
  GlobalStoreService,
  NamedDocsToTags,
} from '@principle-theorem/ng-principle-shared';
import {
  getFileExtension$,
  getPreviewImage$,
  type IMediaManagerUpdateEvent,
} from '@principle-theorem/ng-shared';
import { Media } from '@principle-theorem/principle-core';
import {
  type IMedia,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  HISTORY_DATE_FORMAT,
  shareReplayCold,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject, combineLatest, type Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { isDicomFile } from './components/media-manager/media-manager.component';

@Directive()
export abstract class MediaDisplayItem {
  media$ = new ReplaySubject<WithRef<IMedia>>(1);
  namedDocsToTags: NamedDocsToTags;
  fileUrl$: Observable<string | undefined>;
  isImage$: Observable<boolean>;
  isDicom$: Observable<boolean>;
  loaded$: Observable<boolean>;
  fileExtension$: Observable<string | undefined>;
  tags: WithRef<ITag>[] = [];
  readonly dateFormat = HISTORY_DATE_FORMAT;

  @Output()
  updated = new EventEmitter<IMediaManagerUpdateEvent>();

  constructor(private _storage: Storage, private _global: GlobalStoreService) {
    this.namedDocsToTags = new NamedDocsToTags(this._global);
    this.fileUrl$ = this.media$.pipe(
      switchMap((media) => getPreviewImage$(this._storage, media)),
      shareReplayCold()
    );
    this.isImage$ = this.fileUrl$.pipe(map((url) => !!url));
    this.fileExtension$ = this.media$.pipe(
      switchMap((media) => getFileExtension$(this._storage, media)),
      shareReplayCold()
    );
    this.loaded$ = combineLatest([this.fileExtension$, this.fileUrl$]).pipe(
      map(() => true),
      startWith(false)
    );
    this.isDicom$ = this.media$.pipe(map((media) => isDicomFile(media.path)));
  }

  hasExpired(media: IMedia): boolean {
    return Media.hasExpired(media);
  }
}
