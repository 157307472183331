<div class="border-b border-solid border-neutral-600 bg-neutral-900 p-4">
  @if (isDesktop$ | async) {
    <div class="mx-auto flex max-w-[1280px] items-center justify-between gap-2">
      <div class="w-[200px] text-left">
        <button
          mat-stroked-button
          [ngClass]="{
            '!border-neutral-100 !text-neutral-100': !closeButton.disabled,
            '!border-neutral-400 !text-neutral-400': closeButton.disabled
          }"
          [disabled]="closeButton.disabled"
          (click)="closeButton.action()"
        >
          <mat-icon>{{ closeButton.icon }}</mat-icon>
          {{ closeButton.label }}
        </button>
      </div>
      <div class="flex flex-wrap items-center justify-center gap-4">
        @for (button of actionButtons; track button.label) {
          <button
            mat-stroked-button
            [ngClass]="{
              '!border-neutral-100 !text-neutral-100': !button.disabled,
              '!border-neutral-400 !text-neutral-400': button.disabled
            }"
            [disabled]="button.disabled"
            (click)="button.action()"
          >
            <mat-icon>{{ button.icon }}</mat-icon>
            {{ button.label }}
          </button>
        }
      </div>
      <div
        *ngIf="fileName$ | async as fileName"
        class="w-[200px] truncate text-right text-neutral-100"
        [ptTruncateTooltip]="fileName"
      >
        {{ fileName }}
      </div>
    </div>
  } @else {
    <div class="flex justify-center gap-2">
      <button
        mat-icon-button
        [ngClass]="{
          '!text-neutral-100': !closeButton.disabled,
          '!text-neutral-400': closeButton.disabled
        }"
        [disabled]="closeButton.disabled"
        [matTooltip]="closeButton.label"
        (click)="closeButton.action()"
      >
        <mat-icon>{{ closeButton.icon }}</mat-icon>
      </button>
      @for (button of actionButtons; track button.label) {
        <button
          mat-icon-button
          [ngClass]="{
            '!border-neutral-100 !text-neutral-100': !button.disabled,
            '!border-neutral-400 !text-neutral-400': button.disabled
          }"
          [disabled]="button.disabled"
          [matTooltip]="button.label"
          (click)="button.action()"
        >
          <mat-icon>{{ button.icon }}</mat-icon>
        </button>
      }
    </div>
  }
</div>
