<form
  class="flex"
  [formGroup]="stepFormGroup"
  (ngSubmit)="dialogRef.close(stepFormGroup.value)"
>
  <input
    matInput
    class="flex-1"
    placeholder="Step Name"
    aria-label="Step Name"
    formControlName="name"
  />

  <button mat-icon-button type="submit">
    <mat-icon>save</mat-icon>
  </button>

  <button mat-icon-button (click)="dialogRef.close()">
    <mat-icon>cancel</mat-icon>
  </button>
</form>
