import { randBetweenDate, seed } from '@ngneat/falso';
import { type Timestamp } from '../firebase/firestore/adaptor';
import { toISODate, toTimestamp } from '../time/time';
import { type ISODateType } from '../time/timezone';

export function MockTimestamp(): Timestamp {
  return toTimestamp();
}

export function MockISODate(): ISODateType {
  return toISODate(randBetweenDate({ from: '1960', to: '2017' }));
}

export function wrapWithSeed<T>(specificSeed: string, callback: () => T): T {
  seed(specificSeed);
  const value = callback();
  seed();
  return value;
}
