import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'pairMap',
})
export class PairMapPipe implements PipeTransform {
  transform<T, R, Q, S>(
    valueA: T,
    valueB: R,
    mapFn: (valueA: T, valueB: R) => Q,
    thisArg?: S
  ): Q {
    if (thisArg) {
      mapFn = mapFn.bind(thisArg);
    }
    return mapFn(valueA, valueB);
  }
}
