import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  GlobalStoreService,
  StafferSettingsStoreService,
} from '@principle-theorem/ng-principle-shared';
import {
  isSameChartedRef,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import { type ITreatmentPlan } from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  deleteDoc,
  filterUndefined,
  isChanged$,
  isSameRef,
  multiSwitchMap,
  reduce2DArray,
  saveDoc,
  serialise$,
  snapshot,
  unserialise$,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, of } from 'rxjs';
import {
  concatMap,
  switchMap,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  removeChartedSurface,
  removeChartedSurfaces,
  TreatmentPlanActions,
  TreatmentStepActions,
} from '../../actions';
import { removeTreatmentStep } from '../../actions/treatment-plans/treatment-plans';
import {
  addTreatment,
  updateTreatment,
  updateTreatmentStep,
} from '../../actions/treatment-plans/treatment-steps';

import { TreatmentStepsFacade } from '../../facades/treatment-plans/treatment-steps.facade';

@Injectable()
export class TreatmentStepsEffects {
  private _actions$ = inject(Actions);
  private _stafferSettings = inject(StafferSettingsStoreService);
  private _treatmentStepsFacade = inject(TreatmentStepsFacade);
  loadTreatmentSteps$: Observable<Action> = createEffect(() =>
    this._loadTreatmentSteps$()
  );
  saveTreatmentStep$: Observable<DocumentReference | void> = createEffect(
    () => this._saveTreatmentStep$(),
    { dispatch: false }
  );
  removeChartedSurface$: Observable<void> = createEffect(
    () => this._removeChartedSurface$(),
    { dispatch: false }
  );
  removeChartedSurfaces$: Observable<void> = createEffect(
    () => this._removeChartedSurfaces$(),
    { dispatch: false }
  );
  removeTreatmentStep$: Observable<void> = createEffect(
    () => this._removeTreatmentStep$(),
    { dispatch: false }
  );

  constructor(private _global: GlobalStoreService) {}

  private _loadTreatmentSteps$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(TreatmentPlanActions.loadTreatmentPlansSuccess),
      unserialise$(),
      map((action) => action.treatmentPlans),
      isChanged$<WithRef<ITreatmentPlan>[]>((planA, planB) =>
        isSameRef(planA, planB)
      ),
      multiSwitchMap((plan) =>
        this._stafferSettings.treatmentPlan$.pipe(
          switchMap((settings) =>
            TreatmentPlan.orderedSteps$(plan, settings.sortBy)
          )
        )
      ),
      reduce2DArray(),
      serialise$(),
      map((treatmentSteps) =>
        TreatmentStepActions.loadTreatmentStepsSuccess({ treatmentSteps })
      )
    );
  }

  private _saveTreatmentStep$(): Observable<DocumentReference | void> {
    return this._actions$.pipe(
      ofType(updateTreatmentStep, addTreatment, updateTreatment),
      mergeMap(async (action) => {
        const stepId = 'id' in action ? action.id : action.update.id;
        const step = await snapshot(
          this._treatmentStepsFacade.getTreatmentStep$(stepId)
        );
        if (!step) {
          return undefined;
        }
        return TreatmentStep.updateDisplayPrimaryCategory(
          step,
          await snapshot(this._global.treatmentCategories$)
        );
      }),
      filterUndefined(),
      concatMap((step) => saveDoc(step))
    );
  }

  private _removeChartedSurface$(): Observable<void> {
    return this._actions$.pipe(
      ofType(removeChartedSurface),
      unserialise$(),
      withLatestFrom(this._treatmentStepsFacade.selectedTreatmentStep$),
      concatMap(([action, step]) => {
        if (!step) {
          return of(undefined);
        }

        const treatment = step.treatments.find(
          (stepTreatment) => stepTreatment.uuid === action.item.uuid
        );

        if (!treatment) {
          return of(undefined);
        }

        const chartedSurfaces = treatment.chartedSurfaces.filter(
          (chartedSurface) =>
            !isSameChartedRef(chartedSurface.chartedRef, action.chartedSurface)
        );

        if (treatment.chartedSurfaces.length === chartedSurfaces.length) {
          return of(undefined);
        }

        if (!chartedSurfaces.length) {
          return this._treatmentStepsFacade.removeTreatmentFromStep(
            treatment.uuid,
            step.ref
          );
        }

        return this._treatmentStepsFacade.updateTreatmentFromStep(
          {
            uuid: treatment.uuid,
            chartedSurfaces,
          },
          step.ref
        );
      })
    );
  }

  private _removeChartedSurfaces$(): Observable<void> {
    return this._actions$.pipe(
      ofType(removeChartedSurfaces),
      unserialise$(),
      withLatestFrom(this._treatmentStepsFacade.selectedTreatmentStep$),
      concatMap(([action, step]) => {
        if (!step) {
          return of(undefined);
        }

        const treatment = step.treatments.find(
          (stepTreatment) => stepTreatment.uuid === action.item.uuid
        );

        if (!treatment) {
          return of(undefined);
        }

        const chartedSurfaces = treatment.chartedSurfaces.filter(
          (treatmentSurface) =>
            !action.chartedSurfaces.some((chartedSurface) =>
              isSameChartedRef(treatmentSurface.chartedRef, chartedSurface)
            )
        );

        if (treatment.chartedSurfaces.length === chartedSurfaces.length) {
          return of(undefined);
        }

        if (!chartedSurfaces.length) {
          return this._treatmentStepsFacade.removeTreatmentFromStep(
            treatment.uuid,
            step.ref
          );
        }

        return this._treatmentStepsFacade.updateTreatmentFromStep(
          {
            uuid: treatment.uuid,
            chartedSurfaces,
          },
          step.ref
        );
      })
    );
  }

  private _removeTreatmentStep$(): Observable<void> {
    return this._actions$.pipe(
      ofType(removeTreatmentStep),
      unserialise$(),
      concatMap((action) => deleteDoc(action.ref))
    );
  }
}
