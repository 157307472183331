import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IEvent,
  type IParticipantAvatar,
  type IParticipant,
} from '@principle-theorem/principle-core/interfaces';
import { ParticipantAvatarFactory } from '@principle-theorem/principle-core';
import { ReplaySubject, type Observable, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-event-participants-display',
    templateUrl: './event-participants-display.component.html',
    styleUrls: ['./event-participants-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EventParticipantsDisplayComponent {
  event$: ReplaySubject<IEvent> = new ReplaySubject(1);
  trackByParticipant = TrackByFunctions.field<IParticipantAvatar>('name');
  participants$: Observable<IParticipantAvatar[]>;

  @Input()
  set event(event: IEvent) {
    if (event) {
      this.event$.next(event);
    }
  }

  constructor() {
    this.participants$ = this._getParticipants$();
  }

  private _getParticipants$(): Observable<IParticipant[]> {
    const factory: ParticipantAvatarFactory = new ParticipantAvatarFactory();

    return this.event$.pipe(
      switchMap((event) =>
        combineLatest(
          event.participants.map((participant) => factory.create(participant))
        )
      )
    );
  }
}
