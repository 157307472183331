import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { invoiceToBalance } from '@principle-theorem/ng-principle-accounting';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import { Appointment, Invoice } from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IBalance,
  type IBrand,
  type IInvoice,
  type IInvoiceStatusLog,
} from '@principle-theorem/principle-core/interfaces';
import { HISTORY_DATE_FORMAT, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-appointment-invoice',
  templateUrl: './appointment-invoice.component.html',
  styleUrls: ['./appointment-invoice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentInvoiceComponent {
  readonly dateFormat = HISTORY_DATE_FORMAT;
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);
  invoice$: Observable<WithRef<IInvoice> | undefined>;
  status$: Observable<IInvoiceStatusLog | undefined>;
  link$: Observable<string[]>;
  balance$: Observable<IBalance | undefined>;

  constructor(private _brandScope: CurrentBrandScope) {
    this.invoice$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.invoice$(appointment))
    );
    this.status$ = this.invoice$.pipe(
      map((invoice) =>
        invoice ? Invoice.lastLogForStatus(invoice, invoice.status) : undefined
      )
    );
    this.link$ = combineLatest([this._brandScope.doc$, this.invoice$]).pipe(
      map(([brand, invoice]) => this._getInvoiceLink(brand, invoice))
    );

    this.balance$ = this.invoice$.pipe(
      switchMap((invoice) =>
        invoice ? of(invoice).pipe(invoiceToBalance()) : of(undefined)
      )
    );
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  private _getInvoiceLink(
    brand: WithRef<IBrand> | undefined,
    invoice: WithRef<IInvoice> | undefined
  ): string[] {
    if (!brand || !invoice) {
      return [];
    }
    const patientRef = Invoice.patientDocRef(invoice);
    return [
      '/',
      brand.slug,
      'patients',
      patientRef.id,
      'account',
      'invoices',
      invoice.ref.id,
    ];
  }
}
