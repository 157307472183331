import {
  type IFirestoreModel,
  type INamedDocument,
} from '@principle-theorem/shared';
import { type IStaffer } from '../staffer/staffer';
import { type IChartedCondition } from './conditions/charted-condition';
import { type ITooth } from './core/tooth';
import { type IPerioRecord } from './perio-data';
import { type ITreatmentPlanProposal } from './treatment/treatment-plan-proposal';

export interface IClinicalChart extends IFirestoreModel {
  immutable: boolean;
  teeth: ITooth[];
  conditions: IChartedCondition[];
  flaggedTreatment: ITreatmentPlanProposal;
  perioRecords?: IPerioRecord[];
  createdBy?: INamedDocument<IStaffer>;
}
