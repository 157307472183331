import { type ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BaseInteractionService } from '@principle-theorem/ng-interactions';
import { GapOfferComponent } from './components/gap-offer/gap-offer.component';

@Injectable()
export class OfferAppointmentGapActionService extends BaseInteractionService<GapOfferComponent> {
  override icon = 'send';
  override name = 'Offer Appointment';
  override dialogComponent: ComponentType<GapOfferComponent> =
    GapOfferComponent;
}
