<div class="layout-padding" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <button mat-stroked-button (click)="reportFacade.clearInspect()">
      Back
    </button>
    <h2 *ngIf="result$ | async as result">{{ result.practitioner.name }}</h2>
  </div>
  <button mat-stroked-button (click)="downloadCSV()">Download CSV</button>
</div>

<table mat-table [dataSource]="dataSource" fxFlex>
  <ng-container matColumnDef="invoice">
    <th mat-header-cell *matHeaderCellDef>Invoice</th>
    <td mat-cell *matCellDef="let record">
      <a [routerLink]="record.invoice | invoiceUrl$ | async" target="_blank">
        {{ record.invoice.reference }}
      </a>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let record">
      <pr-invoice-status [dense]="true" [status]="record.invoice.status" />
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="patient">
    <th mat-header-cell *matHeaderCellDef>Patient</th>
    <td mat-cell *matCellDef="let record">
      <a [routerLink]="record.patient | patientUrl$ | async" target="_blank">
        {{ record.patient.name }}
      </a>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="issuedAt">
    <th mat-header-cell *matHeaderCellDef>Issued At</th>
    <td mat-cell *matCellDef="let record">
      {{ record.invoice.issuedAt | moment | amDateFormat: dateFormat }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="paidAt">
    <th mat-header-cell *matHeaderCellDef>Paid At</th>
    <td mat-cell *matCellDef="let record">
      {{ record.invoice.paidAt | moment | amDateFormat: dateFormat }}
    </td>
    <td mat-footer-cell *matFooterCellDef>Total</td>
  </ng-container>

  <ng-container matColumnDef="depositsTakenAmount">
    <th mat-header-cell *matHeaderCellDef>
      <pr-value-with-icon>Deposits</pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record">
      <pr-value-with-icon>
        {{ record.summary.depositsTakenAmount | currency }}
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon *ngIf="result$ | async as record">
        {{ record.total.depositsTakenAmount | currency }}
      </pr-value-with-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="creditsAppliedAmount">
    <th mat-header-cell *matHeaderCellDef>
      <pr-value-with-icon>Credits</pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record">
      <pr-value-with-icon>
        {{ record.summary.creditsAppliedAmount | currency }}
        <mat-icon
          *ngIf="record.summary.creditsAppliedWarning"
          [matTooltip]="tooltips.creditsAppliedWarning"
        >
          info
        </mat-icon>
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon *ngIf="result$ | async as record">
        {{ record.total.creditsAppliedAmount | currency }}
        <mat-icon
          *ngIf="record.total.creditsAppliedWarning"
          [matTooltip]="tooltips.creditsAppliedWarning"
        >
          info
        </mat-icon>
      </pr-value-with-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="discountsAppliedAmount">
    <th mat-header-cell *matHeaderCellDef>
      <pr-value-with-icon>Discounts</pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record">
      <pr-value-with-icon>
        {{ record.summary.discountsAppliedAmount | currency }}
        <mat-icon
          *ngIf="record.summary.discountsAppliedWarning"
          [matTooltip]="tooltips.discountsAppliedWarning"
        >
          info
        </mat-icon>
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon *ngIf="result$ | async as record">
        {{ record.total.discountsAppliedAmount | currency }}
        <mat-icon
          *ngIf="record.total.discountsAppliedWarning"
          [matTooltip]="tooltips.discountsAppliedWarning"
        >
          info
        </mat-icon>
      </pr-value-with-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="treatmentsInvoicedAmount">
    <th mat-header-cell *matHeaderCellDef>
      <pr-value-with-icon>
        Treatment Invoiced
        <mat-icon [matTooltip]="tooltips.treatmentsInvoicedHelp">
          help
        </mat-icon>
      </pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record">
      <pr-value-with-icon>
        {{ record.summary.treatmentsInvoicedAmount | currency }}
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon *ngIf="result$ | async as record">
        {{ record.total.treatmentsInvoicedAmount | currency }}
      </pr-value-with-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="invoicedCommissionAmount">
    <th mat-header-cell *matHeaderCellDef>
      <pr-value-with-icon>
        Recievable Amount
        <mat-icon [matTooltip]="tooltips.invoicedCommissionHelp">
          help
        </mat-icon>
      </pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record">
      <pr-value-with-icon>
        {{ record.summary.invoicedCommissionAmount | currency }}

        <mat-icon
          *ngIf="record.summary.invoicedCommissionWarning"
          [matTooltip]="tooltips.invoicedCommissionWarning"
        >
          info
        </mat-icon>
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon *ngIf="result$ | async as record">
        {{ record.total.invoicedCommissionAmount | currency }}
        <mat-icon
          *ngIf="record.total.invoicedCommissionWarning"
          [matTooltip]="tooltips.invoicedCommissionWarning"
        >
          info
        </mat-icon>
      </pr-value-with-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="receiptedCommissionAmount">
    <th mat-header-cell *matHeaderCellDef>
      <pr-value-with-icon>
        Receipted Amount
        <mat-icon [matTooltip]="tooltips.receiptedCommissionHelp">
          help
        </mat-icon>
      </pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record">
      <pr-value-with-icon>
        {{ record.summary.receiptedCommissionAmount | currency }}

        <mat-icon
          *ngIf="record.summary.receiptedCommissionWarning"
          [matTooltip]="tooltips.receiptedCommissionWarning"
        >
          info
        </mat-icon>
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon *ngIf="result$ | async as record">
        {{ record.total.receiptedCommissionAmount | currency }}
        <mat-icon
          *ngIf="record.total.receiptedCommissionWarning"
          [matTooltip]="tooltips.receiptedCommissionWarning"
        >
          info
        </mat-icon>
      </pr-value-with-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns$ | async"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns$ | async"></tr>
</table>
