import { MeasureFormatter } from '@principle-theorem/principle-core/interfaces';
import { IScheduleSummaryDimension } from '@principle-theorem/reporting/interfaces';
import { BaseDimensionMeasures } from '../base-measures';
import {
  CanBeChartedProperty,
  MeasureTransformMap,
} from '../measure-properties';
import { CanQueryByTimestampProperty } from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { MeasurePath } from '../data-accessor-factory';

export class SchedulingSummaryDimensionMeasures
  extends BaseDimensionMeasures
  implements MeasureTransformMap<Pick<IScheduleSummaryDimension, 'day'>>
{
  get ref(): CanBeChartedProperty {
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('ref'),
        label: 'Schedule Summary Ref',
        summary: '',
      },
      docRef,
      this.buildQuery().attributes([docRef.attributePath]).get()
    );
  }

  get day(): CanQueryByTimestampProperty {
    const measure = this.measureRef('day');
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('day'),
        label: 'Day',
        summary:
          'Date that the data is for. This is useful for grouping gap and utlisation data by day.',
        formatter: MeasureFormatter.Day,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }
}
