<mat-form-field class="w-full flex-1">
  <mat-label>Referrer</mat-label>
  <input
    matInput
    [formControl]="searchCtrl"
    [matAutocomplete]="referrerSelect"
    [required]="isRequired$ | async"
    #autoComplete="matAutocompleteTrigger"
    (click)="autoComplete.openPanel()"
    (blur)="blur()"
  />

  <mat-autocomplete
    #referrerSelect="matAutocomplete"
    [displayWith]="displaySelected"
    (optionSelected)="optionSelected($event)"
  >
    <ng-container
      *ngIf="referrerSearchFilter.results$ | async as referralSources"
    >
      <mat-option
        *ngFor="let referrer of referralSources; trackBy: trackByReferrer"
        [value]="referrer"
      >
        {{ referrer.name }}
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
