<mat-accordion>
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header>
      <mat-panel-title>General</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <pr-general-condition-configuration
        [conditionType]="conditionType"
        (updateCondition)="setCurrentCondition($event)"
       />
      <pt-buttons-container>
        <button mat-stroked-button color="warn" (click)="delete()">
          Delete
        </button>
        <button mat-flat-button color="primary" (click)="save()">Save</button>
      </pt-buttons-container>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
