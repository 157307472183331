import { Validators } from '@angular/forms';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  type MixedSchema,
  initVersionedSchema,
} from '@principle-theorem/editor';
import { type ISnippet } from '@principle-theorem/editor';

export class SnippetFormGroup extends TypedFormGroup<ISnippet> {
  constructor() {
    super({
      name: new TypedFormControl<string>('', Validators.required),
      description: new TypedFormControl<string>(''),
      keyword: new TypedFormControl<string>(''),
      body: new TypedFormControl<MixedSchema>(initVersionedSchema()),
      deleted: new TypedFormControl<boolean>(false),
    });
  }
}
