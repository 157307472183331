import { IPracticeDimension } from '@principle-theorem/reporting/interfaces';
import {
  BaseDimensionMeasures,
  BaseDimensionTableJoin,
} from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import { MeasureTransformMap } from '../measure-properties';
import { CanBeChartedProperty, CanDoAllProperty } from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { BigQueryTable } from '../../big-query-tables';

export class PracticeDimensionMeasures
  extends BaseDimensionMeasures
  implements MeasureTransformMap<Pick<IPracticeDimension, 'yearOpened'>>
{
  name: CanDoAllProperty;

  constructor(
    table: BigQueryTable,
    id: string,
    tableJoin?: BaseDimensionTableJoin
  ) {
    super(table, id, tableJoin);
    this.name = this._getNameProperty();
  }

  get ref(): CanBeChartedProperty {
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('ref'),
        label: 'Practice Ref',
        summary: '',
      },
      docRef,
      this.buildQuery().attributes([docRef.attributePath]).get()
    );
  }

  get yearOpened(): CanBeChartedProperty {
    const propertyName = 'yearOpened';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Practice Year Opened',
        summary: '',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  private _getNameProperty(): CanDoAllProperty {
    const name = this.measureRef('name');
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('name'),
        label: 'Practice Name',
        summary: '',
      },
      docRef,
      this.buildQuery()
        .attributes([name.attributePath, docRef.attributePath])
        .get(),
      name
    );
  }
}
