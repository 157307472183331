import {
  FailedDestinationEntityRecord,
  IDestinationEntity,
  IDestinationEntityRecord,
  IGetRecordResponse,
  IPatient,
  SkippedDestinationEntityRecord,
  type IPracticeMigration,
  DestinationEntityRecordStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  WithRef,
  asyncForEach,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import {
  PatientDocumentDestinationEntity as ExactPatientDocumentsDestinationEntity,
  IPatientDocumentJobData,
  IPatientFileMigrationData,
  IPatientFileSourceFileData,
} from '../../../exact/destination/entities/patient-documents';
import { getBucketStoragePath } from '../../../storage';
import {
  IExactPatientDocument,
  IExactPatientDocumentFilters,
  IExactPatientDocumentTranslations,
} from '../../../exact/source/entities/patient-documents';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import { TranslationMapHandler } from '../../../translation-map';
import {
  IExactPatient,
  IExactPatientTranslations,
} from '../../../exact/source/entities/patient';

enum DentrixDocumentType {
  Document = 'Document',
  Xray = 'XRay',
}

const DENTRIX_PATH_MAP: { [key in DentrixDocumentType]: string } = {
  [DentrixDocumentType.Document]: 'documents',
  [DentrixDocumentType.Xray]: 'images',
};

export class PatientDocumentDestinationEntity extends ExactPatientDocumentsDestinationEntity {
  override async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientDocumentJobData
  ): Promise<
    | IPatientFileMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
    | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return super._buildErrorResponse(
        data.sourcePatient,
        `Couldn't resolve patient`
      );
    }

    const sourceDocuments =
      await this.sourceEntities.patientDocuments.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

    const documents = await this.getPatientFiles(
      migration,
      patientRef,
      sourceDocuments
    );

    if (documents.missingFiles.length) {
      return this._buildSkippedResponse(data.sourcePatient);
    }

    return {
      sourcePatientId,
      patientRef,
      documents: documents.files,
    };
  }

  async getPatientFiles(
    migration: WithRef<IPracticeMigration>,
    patientRef: DocumentReference<IPatient>,
    documents: IGetRecordResponse<
      IExactPatientDocument,
      IExactPatientDocumentTranslations,
      IExactPatientDocumentFilters
    >[]
  ): Promise<{
    files: IPatientFileSourceFileData[];
    missingFiles: IPatientFileSourceFileData[];
  }> {
    const copyStorage = this.getCopyStorage(
      getBucketStoragePath(migration.source),
      patientRef,
      migration.configuration.projectId,
      migration.configuration.destinationBucket
    );

    const missingFiles: IPatientFileSourceFileData[] = [];
    const files = await asyncForEach(documents, async (document) => {
      const location =
        DENTRIX_PATH_MAP[document.data.data.type as DentrixDocumentType];
      const filePath = [location, document.data.data.source_path].join('/');

      const sourceFileExists = await copyStorage.getSourceFile(filePath);

      if (sourceFileExists) {
        return {
          sourceIdentifier: document.record.uid,
          name: document.data.data.title,
          sourceFilePath: filePath,
          createdAt: document.data.translations.date,
        };
      }

      missingFiles.push({
        sourceIdentifier: document.record.uid,
        name: document.data.data.title,
        sourceFilePath: filePath,
        createdAt: document.data.translations.date,
      });
    });

    return { files: compact(files), missingFiles };
  }

  private _buildSkippedResponse(
    patient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>
  ): IDestinationEntityRecord & SkippedDestinationEntityRecord {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      status: DestinationEntityRecordStatus.Skipped,
      sourceRef: patient.record.ref,
    };
  }
}
