import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import { type MatSelectChange } from '@angular/material/select';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { ordinalSuffix } from '@principle-theorem/principle-core';
import {
  CustomRecurrenceFrequency,
  DAY_OF_WEEK_FORMAT,
  RECURRENCE_FREQUENCY_DISPLAY_MAP,
  snapshot,
  toMoment,
  type IFrequencyDisplay,
  type IRecurrencePattern,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { ReplaySubject, Subject, type Observable } from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { RecurrenceFormGroup } from './recurrence-form.formgroup';

@Component({
    selector: 'pr-recurrence-form',
    templateUrl: './recurrence-form.component.html',
    styleUrls: ['./recurrence-form.component.sass'],
    providers: [...MOMENT_DATEPICKER_PROVIDERS],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RecurrenceFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  taskDate$: Observable<Moment>;
  trackByFrequency = TrackByFunctions.field<IFrequencyDisplay>('value');
  trackByMonth = TrackByFunctions.variable<string>();
  recurrenceFormGroup = new RecurrenceFormGroup(this._onDestroy$);
  recurrencePattern$ = new ReplaySubject<Partial<IRecurrencePattern>>(1);

  @Input()
  set recurrencePattern(recurrencePattern: Partial<IRecurrencePattern>) {
    if (recurrencePattern) {
      this.recurrencePattern$.next(recurrencePattern);
    }
  }

  frequencies: IFrequencyDisplay[] = RECURRENCE_FREQUENCY_DISPLAY_MAP;
  hasCustomFrequency$: Observable<boolean>;
  monthOptions: string[];
  @Input() showButtons = true;
  @Output()
  submitted = new EventEmitter<IRecurrencePattern>();

  constructor() {
    this.taskDate$ = this.recurrencePattern$.pipe(
      map((recurrencePattern) =>
        recurrencePattern.startDate
          ? toMoment(recurrencePattern.startDate)
          : moment()
      ),
      startWith(moment())
    );

    this.recurrencePattern$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((recurrencePattern) =>
        this.recurrenceFormGroup.patchRecurrencePattern(recurrencePattern)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  /**
   * Set the month frequency dropdown options
   * @param {event} MatSelectChange
   * @returns {void}
   */
  async updateMonthOptions(event: MatSelectChange): Promise<void> {
    this.monthOptions = [];
    if (event.value === CustomRecurrenceFrequency.Monthly) {
      const taskDate = await snapshot(this.taskDate$);
      const date = taskDate.date();
      this.monthOptions.push(`Monthly on day ${date}`);

      const nthOfMonth: number = Math.ceil(date / 7);
      const dayOfWeek: string = taskDate.format(DAY_OF_WEEK_FORMAT);
      const option = `Monthly on the ${ordinalSuffix(nthOfMonth)} ${dayOfWeek}`;
      this.monthOptions.push(option);
    }

    this.recurrenceFormGroup.controls.monthlyOption.setValue(
      this.monthOptions[0]
    );
  }

  submit(): void {
    this.submitted.emit(this.recurrenceFormGroup.generateRecurrencePattern());
  }
}
