import {
  SourceEntityMigrationType,
  TreatmentPlanStatus,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNull, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { cleanObjectStrings } from './lib/conversion-helpers';

export const PATIENT_CHART_RESOURCE_TYPE = 'chart';

export const PATIENT_CHART_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Chart List',
    description: '',
    idPrefix: PATIENT_CHART_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export enum D4WPatientTreatmentPlanStatus {
  None = 0,
  Presented = 1,
  Accepted = 2,
  Rejected = 3,
  Completed = 4,
}

export const TREATMENT_PLAN_MAP: Partial<
  Record<D4WPatientTreatmentPlanStatus, TreatmentPlanStatus>
> = {
  [D4WPatientTreatmentPlanStatus.None]: TreatmentPlanStatus.Draft,
  [D4WPatientTreatmentPlanStatus.Presented]: TreatmentPlanStatus.Offered,
  [D4WPatientTreatmentPlanStatus.Accepted]: TreatmentPlanStatus.Accepted,
  [D4WPatientTreatmentPlanStatus.Rejected]: TreatmentPlanStatus.Declined,
  [D4WPatientTreatmentPlanStatus.Completed]: TreatmentPlanStatus.Completed,
};

export interface ID4WPatientChart {
  id: number;
  patient_id: number;
  // status_id: D4WPatientTreatmentPlanStatus;
  created_at: string;
  provider_id: number | null;
  notes: string | null;
  ref_number: number | string | null;
  ref_status: string | null;
  active: boolean;
  hcf_id: number;
  treatment_plan_printed_at: string | null;
  // updated_at: string | null;
  // status_updated_at: string;
}

function isD4WChartItem(item: unknown): item is ID4WPatientChart {
  return TypeGuard.interface<ID4WPatientChart>({
    id: isNumber,
    patient_id: isNumber,
    // status_id: TypeGuard.enumValue(D4WPatientTreatmentPlanStatus),
    created_at: isString,
    provider_id: [isNumber, isNull],
    notes: [isString, isNull],
    ref_number: [isNumber, isString, isNull],
    ref_status: [isString, isNull],
    active: isBoolean,
    hcf_id: isNumber,
    treatment_plan_printed_at: [isString, isNull],
    // updated_at: [isString, isNull],
    // status_updated_at: isString,
  })(item);
}

export interface ID4WPatientChartTranslations {
  createdAt: Timestamp;
  treatmentPlanPrintedAt?: Timestamp;
  // statusUpdatedAt: Timestamp;
  updatedAt?: Timestamp;
}

export interface ID4WPatientChartFilters {
  createdAt: Timestamp;
  // statusUpdatedAt: Timestamp;
  patientId: string;
  providerId?: string;
  hcfId: string;
}

const PATIENT_CHART_SOURCE_QUERY = `
SELECT
  chart_id AS id,
  patient_id,
  chart_date_time as created_at,
  provider_id,
  NULLIF(notes, '') AS notes,
  ref_number,
  NULLIF(ref_status, '') AS ref_status,
  convert_to_boolean(active) AS active,
  hcf_id,
  NULLIF(tr_plan_printed_date, '') AS treatment_plan_printed_at
  -- NULLIF(last_change_date, '') AS updated_at,
  -- status_id,
  -- changed_status_date AS status_updated_at
FROM charting
WHERE
  ref_status != 'D'
ORDER BY id
`;

export class PatientChartSourceEntity extends BaseSourceEntity<
  ID4WPatientChart,
  ID4WPatientChartTranslations,
  ID4WPatientChartFilters
> {
  sourceEntity = PATIENT_CHART_SOURCE_ENTITY;
  entityResourceType = PATIENT_CHART_RESOURCE_TYPE;
  sourceQuery = PATIENT_CHART_SOURCE_QUERY;
  verifySourceFn = isD4WChartItem;
  override transformDataFn = flow([transformChartQueryResults]);

  translate(
    data: ID4WPatientChart,
    timezone: Timezone
  ): ID4WPatientChartTranslations {
    return {
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
      // statusUpdatedAt: toTimestamp(
      //   moment.tz(data.status_updated_at, ISO_DATE_TIME_FORMAT, timezone)
      // ),
      treatmentPlanPrintedAt: data.treatment_plan_printed_at
        ? toTimestamp(
            moment.tz(
              data.treatment_plan_printed_at,
              ISO_DATE_TIME_FORMAT,
              timezone
            )
          )
        : undefined,
      // updatedAt: data.updated_at
      //   ? toTimestamp(moment.tz(data.updated_at, ISO_DATE_FORMAT, timezone))
      //   : undefined,
    };
  }

  getFilterData(
    data: ID4WPatientChart,
    timezone: Timezone
  ): ID4WPatientChartFilters {
    return {
      patientId: data.patient_id.toString(),
      providerId: data.provider_id?.toString(),
      hcfId: data.hcf_id.toString(),
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),

      // statusUpdatedAt: toTimestamp(
      //   moment.tz(data.status_updated_at, ISO_DATE_TIME_FORMAT, timezone)
      // ),
    };
  }

  getSourceRecordId(data: ID4WPatientChart): number {
    return data.id;
  }

  getSourceLabel(data: ID4WPatientChart): string {
    return `${data.id} ${data.patient_id}`;
  }
}

function transformChartQueryResults(
  rows: ID4WPatientChart[]
): ID4WPatientChart[] {
  return rows.map(cleanObjectStrings);
}
