@if (bloc$ | async; as bloc) {
  <mat-form-field [subscriptSizing]="subscriptSizing" class="w-full">
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      #autoComplete="matAutocompleteTrigger"
      [matAutocomplete]="auto"
      [formControl]="bloc.dataPointSearchCtrl"
      (click)="autoComplete.openPanel()"
      (blur)="bloc.blurMeasure()"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="bloc.displayFn"
      (optionSelected)="handleSelect($event)"
    >
      @for (
        section of bloc.dataPointSearch.results$ | async;
        track section.name
      ) {
        @if (section.options.length) {
          <mat-optgroup [label]="section.name">
            @for (
              option of section.options;
              track option.property.metadata.id
            ) {
              <mat-option
                [value]="option.property"
                [matTooltip]="option.property.metadata.summary"
                [matTooltipDisabled]="!option.property.metadata.summary"
                matTooltipPosition="right"
                matTooltipShowDelay="300"
              >
                <div class="flex flex-col leading-tight">
                  <pr-report-builder-resolved-column-label
                    class="block"
                    [column]="option"
                  />
                  <div class="text-xs opacity-50">
                    Format:
                    {{
                      option.property.metadata.formatter ?? 'Text'
                        | splitCamel
                        | titlecase
                    }}
                  </div>
                </div>
              </mat-option>
            }
          </mat-optgroup>
        }
      }
    </mat-autocomplete>
  </mat-form-field>
}
