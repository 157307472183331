<div class="flex items-center gap-0.5">
  <mat-form-field class="flex-1" subscriptSizing="dynamic">
    <mat-label>Pack/Equipment</mat-label>
    <input
      type="text"
      matInput
      [formControl]="packGroupSearchCtrl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      (optionSelected)="optionSelected.emit($event.option.value)"
    >
      @if (filteredPackGroups$ | async; as groups) {
        <mat-optgroup
          *ngIf="groups.packs.items.length"
          [label]="groups.packs.label"
        >
          @for (pack of groups.packs.items; track pack.ref.path) {
            <mat-option [value]="pack">
              {{ pack.name }}
            </mat-option>
          }
        </mat-optgroup>
        <mat-optgroup
          *ngIf="groups.equipment.items.length"
          [label]="groups.equipment.label"
        >
          @for (equipment of groups.equipment.items; track equipment.ref.path) {
            <mat-option [value]="equipment">
              {{ equipment.name }}
            </mat-option>
          }
        </mat-optgroup>
      }
    </mat-autocomplete>
  </mat-form-field>

  <pr-pack-contents-editor
    [content]="content$ | async"
    (contentChange)="updateContent.emit($event)"
  />
</div>
