import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  selectQueryParam,
  selectQueryParams,
} from '@principle-theorem/ng-principle-shared';
import {
  IScheduleSummaryEvent,
  type IAppointment,
  type IStaffer,
  isAppointmentEventType,
  CalendarUnit,
} from '@principle-theorem/principle-core/interfaces';
import {
  type DocumentReference,
  type EntityModel,
} from '@principle-theorem/shared';
import { isObject, type SerialisedData } from '@principle-theorem/shared';
import {
  calendarAdapter,
  CALENDAR_FEATURE_KEY,
  type ICalendarState,
} from './calendar.reducer';

const { selectAll, selectEntities } = calendarAdapter.getSelectors();

export const getCalendarState =
  createFeatureSelector<ICalendarState>(CALENDAR_FEATURE_KEY);

export const getCalendarLoaded = createSelector(
  getCalendarState,
  (state) => state.loaded
);

export const getCalendarUnitParam = selectQueryParam('calendarUnit');
export const getCalendarDateParam = selectQueryParam('date');
export const getCalendarQueryParams = createSelector(
  selectQueryParams,
  (params) => {
    if (!params || !isObject(params)) {
      return {};
    }
    return {
      calendarUnit: params.calendarUnit
        ? (params.calendarUnit as CalendarUnit)
        : undefined,
      from: params.from ? String(params.from) : undefined,
      to: params.to ? String(params.to) : undefined,
    };
  }
);

export const getCalendarUnit = createSelector(
  getCalendarState,
  (state) => state.unit
);

export const getCalendarView = createSelector(
  getCalendarState,
  (state) => state.view
);

export const getCalendarRange = createSelector(
  getCalendarState,
  (state) => state.range
);

export const getCalendarEventsLoaded = createSelector(
  getCalendarState,
  (state) => state.loaded
);

export const getAllCalendarEvents = createSelector(getCalendarState, (state) =>
  selectAll(state)
);

export const getCalendarEventEntities = createSelector(
  getCalendarState,
  (state) => selectEntities(state)
);

export const getSelectedCalendarEventId = createSelector(
  getCalendarState,
  (state) => state.selectedId
);

export const getSelectedCalendarEvent = createSelector(
  getCalendarEventEntities,
  getSelectedCalendarEventId,
  (entities, selectedId) => {
    if (!selectedId) {
      return;
    }
    return entities[selectedId];
  }
);

export const getAppointmentEvents = createSelector(
  getAllCalendarEvents,
  (events) => {
    return events.filter(
      (
        event
      ): event is SerialisedData<
        EntityModel<IScheduleSummaryEvent<IAppointment>>
      > => isAppointmentEventType(event.event.type)
    );
  }
);

export const getCalendarEvents = createSelector(
  getAllCalendarEvents,
  (events) => {
    return events.filter((event) => !isAppointmentEventType(event.event.type));
  }
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getEventsByParticipant = (search: DocumentReference<IStaffer>) =>
  createSelector(getAllCalendarEvents, (calendarEvents) =>
    calendarEvents.filter((calendarEvent) =>
      calendarEvent.event.participants.some(
        (participant) => participant.ref.referenceValue === search.path
      )
    )
  );
