import { Validators } from '@angular/forms';
import {
  MixedSchema,
  VersionedSchema,
  initVersionedSchema,
} from '@principle-theorem/editor';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { stafferToNamedDoc } from '@principle-theorem/principle-core';
import {
  type IStaffer,
  type ISterilisationCycle,
  type ISterilisationMachine,
  ISterilisationCycleType,
  SterilisationStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  type ISODateType,
  ISO_DATE_TIME_FORMAT,
  type Time24hrType,
  to24hrTime,
  toISODate,
  toNamedDocument,
  toTimestamp,
  WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';

export interface ISterilisationForm {
  id: string;
  machine: INamedDocument<ISterilisationMachine>;
  cycleRunDate: ISODateType;
  cycleRunTime: Time24hrType;
  staffer: INamedDocument<IStaffer>;
  notes: MixedSchema;
  cycleType?: INamedDocument<ISterilisationCycleType>;
}

export class SterilisationCycleForm extends TypedFormGroup<ISterilisationForm> {
  constructor() {
    const now = moment();
    super({
      id: new TypedFormControl<string>('', Validators.required),
      machine: new TypedFormControl<INamedDocument<ISterilisationMachine>>(
        undefined,
        Validators.required
      ),
      cycleRunDate: new TypedFormControl<ISODateType>(
        toISODate(now),
        Validators.required
      ),
      cycleRunTime: new TypedFormControl<Time24hrType>(
        to24hrTime(now),
        Validators.required
      ),
      staffer: new TypedFormControl<INamedDocument<IStaffer>>(
        undefined,
        Validators.required
      ),
      cycleType: new TypedFormControl<
        INamedDocument<ISterilisationCycleType> | undefined
      >(undefined),
      notes: new TypedFormControl<VersionedSchema>(initVersionedSchema()),
    });
  }

  toSterilisationCycle(
    cycle?: WithRef<ISterilisationCycle>
  ): ISterilisationCycle {
    const values = this.getRawValue();
    return {
      ...values,
      runDate: toTimestamp(
        moment(
          `${values.cycleRunDate} ${values.cycleRunTime}`,
          ISO_DATE_TIME_FORMAT
        )
      ),
      machine: toNamedDocument(values.machine),
      name: `${values.id} - ${values.machine.name}`,
      staffer: stafferToNamedDoc(values.staffer),
      status: cycle?.status ?? SterilisationStatus.Pending,
      cycleType: values.cycleType
        ? toNamedDocument(values.cycleType)
        : undefined,
    };
  }
}
