import { Component, type OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { InteractionDialogsService } from '@principle-theorem/ng-interactions';
import { StateBasedNavigationService } from '@principle-theorem/ng-principle-shared';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogData,
  type IConfirmationDialogInput,
} from '@principle-theorem/ng-shared';
import {
  type IContact,
  type IContactDetails,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import { toMention } from '@principle-theorem/principle-core';
import {
  deleteDoc,
  doc$,
  filterUndefined,
  findProp,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  ContactFormDialogComponent,
  type IContactFormData,
} from '../../../components/contact-form-dialog/contact-form-dialog.component';

@Component({
  selector: 'pr-contact-profile',
  templateUrl: './contact-profile.component.html',
  styleUrls: ['./contact-profile.component.sass'],
})
export class ContactProfileComponent implements OnInit {
  contact$: Observable<WithRef<IContact>>;

  constructor(
    private _route: ActivatedRoute,
    private _dialog: MatDialog,
    private _stateNav: StateBasedNavigationService,
    public dialogService: InteractionDialogsService
  ) {
    this.contact$ = this._route.data.pipe(
      findProp<WithRef<IContact>>('contact'),
      filterUndefined(),
      switchMap((data) => doc$(data.ref))
    );
  }

  async ngOnInit(): Promise<void> {
    const contact = await snapshot(this.contact$);
    this.dialogService.dialogData = {
      contact: toMention(contact, MentionResourceType.Contact),
    };
  }

  async openEdit(): Promise<void> {
    const contact = await snapshot(this.contact$);
    const data: IContactFormData = {
      title: `Edit ${contact.name}`,
      submitLabel: 'Save',
      contact,
    };
    const updatedContact = await this._dialog
      .open<
        ContactFormDialogComponent,
        IContactFormData,
        Partial<IContactDetails>
      >(
        ContactFormDialogComponent,
        DialogPresets.medium({ data, autoFocus: true })
      )
      .afterClosed()
      .toPromise();

    if (!updatedContact) {
      return;
    }

    await patchDoc(contact.ref, updatedContact);
  }

  async delete(): Promise<void> {
    const data: IConfirmationDialogData = confirmationDialogData({
      title: 'Delete Contact',
      prompt: 'Are you sure you want to delete this contact?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }
    const contact = await snapshot(this.contact$);
    await deleteDoc(contact.ref);
    await this._stateNav.practice(['contacts']);
  }
}
