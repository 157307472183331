import {
  Appointment,
  TimezoneResolver,
} from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IClinicalChart,
} from '@principle-theorem/principle-core/interfaces';
import { isWithRef, type WithRef } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';

export enum MissingChartReason {
  SubsequentAppointmentExists = 'subsequentAppointmentExists',
  AppointmentInFuture = 'appointmentInFuture',
  AppointmentInPast = 'appointmentInPast',
  AppointmentNotScheduled = 'appointmentNotScheduled',
  NoChartGenerated = 'noChartGenerated',
}

export async function getMissingChartReason(
  currentAppointment: IAppointment,
  chart?: WithRef<IClinicalChart>,
  subsequentAppointment?: IAppointment
): Promise<MissingChartReason | undefined> {
  const currentChartExists =
    currentAppointment.clinicalChart || isWithRef(chart);
  if (currentChartExists) {
    return;
  }

  const newerChartExists = subsequentAppointment?.clinicalChart;
  if (newerChartExists) {
    return MissingChartReason.SubsequentAppointmentExists;
  }

  if (!currentAppointment.event) {
    return MissingChartReason.AppointmentNotScheduled;
  }

  const timezone = await TimezoneResolver.fromEvent(currentAppointment.event);

  const isInFuture = Appointment.isAfter(
    currentAppointment,
    moment.tz(timezone).endOf('day')
  );
  if (isInFuture) {
    return MissingChartReason.AppointmentInFuture;
  }

  const isInPast = Appointment.isAfter(
    currentAppointment,
    moment.tz(timezone).startOf('day')
  );
  if (isInPast) {
    return MissingChartReason.AppointmentInPast;
  }

  return MissingChartReason.NoChartGenerated;
}
