import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { type IChartedServiceSmartGroup } from '@principle-theorem/principle-core/interfaces';
import { type IChartedSurface } from '@principle-theorem/principle-core/interfaces';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-service-item-smart-group-list',
    templateUrl: './service-item-smart-group-list.component.html',
    styleUrls: ['./service-item-smart-group-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ServiceItemSmartGroupListComponent {
  trackByGroup = TrackByFunctions.uniqueId<IChartedServiceSmartGroup>();
  groups$: ReplaySubject<IChartedServiceSmartGroup[]> = new ReplaySubject(1);
  @Input() chartedSurfaces: IChartedSurface[] = [];
  @Input() disabled = false;
  @Input() compact = false;
  @Output() groupsChange: EventEmitter<IChartedServiceSmartGroup[]> =
    new EventEmitter<IChartedServiceSmartGroup[]>();

  @Input()
  set groups(groups: IChartedServiceSmartGroup[]) {
    if (groups) {
      this.groups$.next(groups);
    }
  }

  groupUpdate(
    groups: IChartedServiceSmartGroup[],
    current: IChartedServiceSmartGroup,
    changes: Partial<IChartedServiceSmartGroup>
  ): void {
    const change = groups.map((group) => {
      if (group.uid !== current.uid) {
        return group;
      }
      return {
        ...group,
        ...changes,
      };
    });
    this.groupsChange.next(change);
  }

  delete(
    groups: IChartedServiceSmartGroup[],
    group: IChartedServiceSmartGroup
  ): void {
    this.groupsChange.emit(
      groups.filter((item: IChartedServiceSmartGroup) => item.uid !== group.uid)
    );
  }
}
