<pt-breadcrumbs
  [breadcrumbs]="breadcrumbs"
  current="Practitioner Reports"
  class="bg-white"
/>
<div class="border-b border-solid border-slate-300"></div>

<div class="layout-padding">
  <h2 class="layout-margin">Practitioner Income Report</h2>
  <div fxLayout="row wrap" fxLayoutGap="16px" class="layout-margin">
    <pt-date-range-form
      [useRangePicker]="true"
      [from]="from"
      [to]="to"
      (changed)="updateDateRange($event)"
    />
    <pr-practice-selector
      [brands]="brands$ | async"
      [practice]="practice$ | async"
      (practiceSelected)="practice$.next($event)"
    />
    <pr-practitioner-income-report-type-selector />
    <button
      mat-flat-button
      class="submit-button"
      color="primary"
      (click)="runReport$.next()"
    >
      Run
    </button>
  </div>

  <div class="layout-margin" fxLayout="column">
    <pr-practitioner-transactions-report
      *ngIf="isTransactionReport$ | async; else invoiceReport"
      [request]="request$ | async"
    />

    <ng-template #invoiceReport>
      <pr-practitioner-income-table [request]="request$ | async" />
    </ng-template>
  </div>
</div>
