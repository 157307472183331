import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnDestroy,
} from '@angular/core';
import { PatientMetadataDisplay } from '@principle-theorem/principle-core';
import {
  IPatientMetadataDisplay,
  PatientMetadataValue,
} from '@principle-theorem/principle-core/interfaces';
import { getTextOverlayColour } from '@principle-theorem/shared';
import { Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-patient-metadata-display',
  templateUrl: './patient-metadata-display.component.html',
  styleUrls: ['./patient-metadata-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class PatientMetadataDisplayComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _value$ = new ReplaySubject<PatientMetadataValue | undefined>(1);
  private _display$ = new ReplaySubject<IPatientMetadataDisplay>(1);
  formattedDisplay$: Observable<string | undefined>;
  @HostBinding('style.background-color') bgColour: string;
  @HostBinding('style.color') textColour: string;
  @HostBinding('class.hidden') hidden: boolean = true;
  @HostBinding('class') cssClasses =
    'block rounded-md py-1 px-2 text-xs flex-none';

  @Input()
  set value(value: PatientMetadataValue | undefined) {
    this._value$.next(value);
  }

  @Input()
  set display(display: IPatientMetadataDisplay) {
    if (display) {
      this._display$.next(display);
    }
  }

  constructor() {
    this.formattedDisplay$ = combineLatest([this._value$, this._display$]).pipe(
      map(([value, display]) =>
        PatientMetadataDisplay.buildFormattedValue(display, value)
      )
    );

    this.formattedDisplay$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((display) => {
        this.hidden = !display;
      });

    this._display$.pipe(takeUntil(this._onDestroy$)).subscribe((display) => {
      this.bgColour = display.hexColour;
      this.textColour = getTextOverlayColour(display.hexColour);
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
