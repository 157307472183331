import { isRightQuadrant } from '@principle-theorem/principle-core';
import {
  type IDentalChartViewSurface,
  type Quadrant,
} from '@principle-theorem/principle-core/interfaces';
import { ChartElement, type IChartElement } from './chart-element';
import { type IChartTooth } from './chart-tooth';

export interface IChartQuadrant extends IChartElement {
  quadrant: Quadrant;
  teeth: IChartTooth[];
  display: IDentalChartViewSurface;
}

export class ChartQuadrant extends ChartElement {
  teeth: IChartTooth[] = [];

  constructor(
    public quadrant: Quadrant,
    public display: IDentalChartViewSurface
  ) {
    super();
    this.xFlipped = isRightQuadrant(this.quadrant);
  }

  static isEmpty(quadrant: IChartQuadrant): boolean {
    return quadrant.teeth.length < 1;
  }

  static setSize(quadrant: IChartQuadrant): void {
    quadrant.height = ChartElement.getLargestHeight(quadrant.teeth);
    const lastTooth: IChartTooth | undefined =
      ChartQuadrant.findLastTooth(quadrant);
    if (!lastTooth) {
      return;
    }
    quadrant.width = lastTooth.xPos + lastTooth.width;
  }

  static findLastTooth(quadrant: IChartQuadrant): IChartTooth | undefined {
    return quadrant.teeth.reduce(
      (last: IChartTooth | undefined, current: IChartTooth) => {
        if (
          last &&
          last.tooth.toothRef.quadrantIndex >
            current.tooth.toothRef.quadrantIndex
        ) {
          return last;
        }
        return current;
      },
      undefined
    );
  }

  override toInterface(): IChartQuadrant {
    return {
      ...super.toInterface(),
      quadrant: this.quadrant,
      teeth: this.teeth,
      display: this.display,
    };
  }
}
