import { createAction, props } from '@ngrx/store';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import { type IPatientDetails } from '@principle-theorem/principle-core';

enum PatientDetailsActions {
  SelectPatient = '[Patient Details] Select Patient',
  ClearPatient = '[Patient Details] Clear Patient',
  ClearPatientDetails = '[Patient Details] Clear Patient Details',
  SavePatient = '[Patient Details] Save Patient',
  SetPatientDetails = '[Patient Details] Set Form Value',
  PatchPatientDetails = '[Patient Details] Patch Form Value',
  UpdateExistingPatient = '[Patient Details] Update Existing Patient',
  UpdateExistingPatientSuccess = '[Patient Details] Update Existing Patient Success',
  SaveNewPatient = '[Patient Details] Save New Patient',
  SaveNewPatientSuccess = '[Patient Details] Save New Patient Success',
}

interface ISelectPatient {
  patient: WithRef<IPatient>;
}

export const selectPatient = createAction(
  PatientDetailsActions.SelectPatient,
  props<SerialisedData<ISelectPatient>>()
);

export const clearPatientDetails = createAction(
  PatientDetailsActions.ClearPatientDetails
);

export const clearPatient = createAction(PatientDetailsActions.ClearPatient);
export const savePatient = createAction(PatientDetailsActions.SavePatient);

export const saveNewPatientSuccess = createAction(
  PatientDetailsActions.SaveNewPatientSuccess,
  props<SerialisedData<ISelectPatient>>()
);

interface ISetPatientDetails {
  patientDetails: IPatientDetails;
}

export const setPatientDetails = createAction(
  PatientDetailsActions.SetPatientDetails,
  props<SerialisedData<ISetPatientDetails>>()
);

interface IPatchPatientDetails {
  patientDetails: Partial<IPatientDetails>;
  save: boolean;
}

export const patchPatientDetails = createAction(
  PatientDetailsActions.PatchPatientDetails,
  props<SerialisedData<IPatchPatientDetails>>()
);

interface IUpdateExistingPatient {
  patient: WithRef<IPatient>;
  changes: Partial<IPatient>;
}

export const updateExistingPatient = createAction(
  PatientDetailsActions.UpdateExistingPatient,
  props<SerialisedData<IUpdateExistingPatient>>()
);

export const updateExistingPatientSuccess = createAction(
  PatientDetailsActions.UpdateExistingPatientSuccess
);

interface ISaveNewPatient {
  patient: IPatient;
}

export const saveNewPatient = createAction(
  PatientDetailsActions.SaveNewPatient,
  props<SerialisedData<ISaveNewPatient>>()
);
