import { compact, groupBy, values } from 'lodash';
import * as moment from 'moment-timezone';
import { DAY_OF_WEEK_FORMAT, ISO_DATE_FORMAT } from '../date-time-formatting';
import { type DayOfWeek } from '../days';
import { getEarlierTime, getLaterTime } from '../time';
import { type Timezone } from '../timezone';
import { SubtractTimeFilter } from './time-filters/subtract';
import { type ITimestampRange, type ITimePeriod } from './interfaces';
import { toTimePeriod } from './time-period';

export interface ITimeBucketDistributeOptions {
  durationIncrement: moment.Duration;
  maxDuration: moment.Duration;
  minDuration: moment.Duration;
}

export interface ITimeBucketExhaustOptions {
  timeIncrement: moment.Duration;
  duration: moment.Duration;
  keepSmallOptions?: boolean;
}

export class TimeBucket {
  times: ITimePeriod[] = [];

  constructor(times?: ITimePeriod[]) {
    if (!times) {
      return;
    }
    this.times = times;
  }

  /* --------------------------------------------------------------*/
  /* -------------------------- Creation --------------------------*/
  /* --------------------------------------------------------------*/

  /**
   * Returns a new instance of TimeBucket with the given events
   * converted to ITimePeriods.
   *
   * @static
   * @param {IEvent[]} events
   * @returns {TimeBucket}
   * @memberof TimeBucket
   */
  static fromEvents(
    events: ITimestampRange[],
    timezone?: Timezone
  ): TimeBucket {
    const timeBucket: TimeBucket = new TimeBucket();
    events.map((event) => {
      timeBucket.times.push(toTimePeriod(event.from, event.to, timezone));
    });
    timeBucket.sort();
    return timeBucket;
  }

  /* --------------------------------------------------------------*/
  /* --------------------- Getters & Setters ----------------------*/
  /* --------------------------------------------------------------*/

  /**
   * Returns the array of ITimePeriods
   *
   * @returns {ITimePeriod[]}
   * @memberof TimeBucket
   */
  get(): ITimePeriod[] {
    return this.times.map((time: ITimePeriod): ITimePeriod => {
      return toTimePeriod(time.from, time.to);
    });
  }

  /**
   * Returns an ITimePeriod with the merged result of all times in the time bucket.
   * The time with the earliest start time will be used as the start time.
   * The time with the latest end time will be used as the end time.
   */
  merge(): ITimePeriod | undefined {
    if (!this.times.length) {
      return;
    }
    return this.times.reduce((prev: ITimePeriod, curr: ITimePeriod) => {
      return toTimePeriod(
        getEarlierTime(prev.from, curr.from),
        getLaterTime(prev.to, curr.to)
      );
    });
  }

  /**
   * Returns a deep copy of the instance of and its times
   *
   * @returns {TimeBucket}
   * @memberof TimeBucket
   */
  clone(): TimeBucket {
    return new TimeBucket(this.get());
  }

  /* --------------------------------------------------------------*/
  /* ----------------------- Transformation -----------------------*/
  /* --------------------------------------------------------------*/

  /**
   * Combines any times which overlap each other into a larger time duration
   *
   * @returns {TimeBucket}
   * @memberof TimeBucket
   */
  mergeOverlapping(): TimeBucket {
    if (!this.times.length) {
      return this;
    }

    let lastTimeLength = 0;

    while (lastTimeLength !== this.times.length && this.times.length > 0) {
      this.sort();
      this.times = this._mergeOverlapping(this.times);
      lastTimeLength = this.times.length;
    }

    return this;
  }

  /**
   * Returns a new array of times which is the gaps in time of the current array of times.
   * The given start and end times set the boundary for inverting the time range.
   *
   * @param {moment.Moment} start
   * @param {moment.Moment} end
   * @returns {TimeBucket}
   * @memberof TimeBucket
   */
  invertByRange(start: moment.Moment, end: moment.Moment): TimeBucket {
    const invertedTimes: ITimePeriod[] = [];
    const pointer: ITimePeriod = toTimePeriod(start, start);

    this._mergeOverlapping(this.times).map((event: ITimePeriod) => {
      if (event.from.isAfter(pointer.to)) {
        // Event starts after pointer, update & add last pointer before
        // moving it to where this event ends
        pointer.to = getLaterTime(event.from, start);
        invertedTimes.push(toTimePeriod(pointer.from, pointer.to));
      }

      // Move pointer to end of event
      pointer.from = event.to;
      pointer.to = event.to;
    });

    if (pointer.from.isBefore(end)) {
      pointer.to = end;
      invertedTimes.push(toTimePeriod(pointer.from, pointer.to));
    }

    this.times = invertedTimes;
    return this;
  }

  /**
   * Trims times by the given start and end dates by comparing the start and end time of each time period.
   *
   * @param {moment.Moment} start
   * @param {moment.Moment} end
   * @param {boolean} [byDay=false]
   * @returns {TimeBucket}
   * @memberof TimeBucket
   */
  trim(
    start: moment.Moment,
    end: moment.Moment,
    byDay: boolean = false
  ): TimeBucket {
    if (byDay) {
      return this._trimDay(start, end);
    }
    return this._trim(start, end);
  }

  /**
   * Subtracts the given times from the list of times.
   * - If a time is completely overlapped by a given time it will be removed
   * - If a time is partially overlapped at one end it will be shortedned
   * - If a time is partially overlapped in the middle of its time it will
   *   be split into two times.
   *
   * @param {ITimePeriod[]} timesToSubtract
   * @returns {TimeBucket}
   * @memberof TimeBucket
   */
  subtract(timesToSubtract: ITimePeriod[]): TimeBucket {
    const timesToSubtractMerged: ITimePeriod[] = new TimeBucket(timesToSubtract)
      .clone()
      .mergeOverlapping()
      .get();

    if (!timesToSubtract.length) {
      return this;
    }

    let lengthChange = -1;

    while (lengthChange !== 0 && this.times.length > 0) {
      const originalLength: number = this.times.length;
      this.times = this._subtract(this.times, timesToSubtractMerged);
      this.sort();
      lengthChange = this.times.length - originalLength;
    }

    return this;
  }

  /**
   * Breaks up the individual times into sizes which are between the given maximum and
   * minimum durations.
   * - If a time is too large it will be broken up into maxDuration blocks.
   * - If a time is too small it will take time from the previous time block.
   * - If a time is too small and no surrounding time blocks exist, it will be returned as is.
   *
   * @param {ITimeBucketDistributeOptions} options
   * @returns {TimeBucket}
   * @memberof TimeBucket
   */
  distributeByRange(options: ITimeBucketDistributeOptions): TimeBucket {
    const distributedTimes: ITimePeriod[] = [];
    this.times.map((time: ITimePeriod) => {
      distributedTimes.push(...this._split(time, options));
    });
    this.times = distributedTimes;
    return this;
  }

  /**
   * Breaks up the individual times by walking through each time and generating times with
   * the given duration.
   * - Times options will be incremented by the timeIncrement
   * - Times which cannot fit the duration will be discarded
   *
   * @param {ITimeBucketExhaustOptions} options
   * @returns {TimeBucket}
   * @memberof TimeBucket
   */
  exhaustTimeOptions(options: ITimeBucketExhaustOptions): TimeBucket {
    const exhaustedTimes: ITimePeriod[] = [];
    this.times.map((time: ITimePeriod) => {
      exhaustedTimes.push(...this._exhaust(time, options));
    });
    this.times = exhaustedTimes;
    return this;
  }

  /**
   * Groups and merges times by day.
   *
   * @returns {TimeBucket}
   */
  mergeByDay(): TimeBucket {
    this.sort();
    const mergedTimes = compact(
      values(
        groupBy(this.times, (time) => time.from.format(ISO_DATE_FORMAT))
      ).map((time) => new TimeBucket(time).merge())
    );
    return new TimeBucket(mergedTimes);
  }

  /* --------------------------------------------------------------*/
  /* ------------------------- Filtering --------------------------*/
  /* --------------------------------------------------------------*/

  /**
   * Returns a new bucket with the matching time removed
   *
   * @param {ITimePeriod} time
   * @returns {TimeBucket}
   * @memberof TimeBucket
   */
  except(time: ITimePeriod): TimeBucket {
    this.times = this.times.filter((comparisonTime: ITimePeriod): boolean => {
      return (
        !comparisonTime.from.isSame(time.from) ||
        !comparisonTime.to.isSame(time.to)
      );
    });
    return this;
  }

  /**
   * Creates a subset of the times by a percentage of the size of the array.
   * Percentage is given as a float between 0 and 1.
   *
   * @param {number} limitPercent
   * @returns {TimeBucket}
   * @memberof TimeBucket
   */
  limitByPercent(limitPercent: number): TimeBucket {
    const size: number = this.times.length;
    const sliceSize: number = Math.ceil(size * limitPercent);
    return this.limit(sliceSize);
  }

  /**
   * Creates a subset of the times to the length of the given number. If the given
   * value is greater than the length of the array, all results will be kept.
   *
   * @param {number} limitAmount
   * @returns {TimeBucket}
   * @memberof TimeBucket
   */
  limit(limitAmount: number): TimeBucket {
    const timesLength: number = this.times.length;
    if (limitAmount > timesLength) {
      limitAmount = timesLength;
    }
    this.times = this.times.slice(0, limitAmount);
    return this;
  }

  /**
   * Filters data by minimum duration or date
   *
   * @param {(moment.Duration | moment.Moment)} min
   * @returns {TimeBucket}
   * @memberof TimeBucket
   */
  min(min: moment.Duration | moment.Moment): TimeBucket {
    if (moment.isDuration(min)) {
      return this._minDuration(min);
    }
    if (moment.isMoment(min)) {
      return this._minDate(min);
    }
    return this;
  }

  /**
   * Filters data by maximum duration or date
   *
   * @param {(moment.Duration | moment.Moment)} max
   * @returns {TimeBucket}
   * @memberof TimeBucket
   */
  max(max: moment.Duration | moment.Moment): TimeBucket {
    if (moment.isDuration(max)) {
      return this._maxDuration(max);
    }
    if (moment.isMoment(max)) {
      return this._maxDate(max);
    }
    return this;
  }

  /**
   * Get times within the specifed days of the week
   *
   * @param {DayOfWeek[]} days
   * @returns {TimeBucket}
   * @memberof TimeBucket
   */
  days(days: DayOfWeek[], transform: boolean = false): TimeBucket {
    if (!transform) {
      return this._filterByDays(days);
    }

    const newTimes: ITimePeriod[] = [];
    this.times.map((time: ITimePeriod): void => {
      // If we are transforming we need to break any times up into separate days
      const currentTime: moment.Moment = moment(time.from).startOf('day');

      while (currentTime.isSameOrBefore(time.to)) {
        if (!this._timeIncludesDay(currentTime, days)) {
          currentTime.add(1, 'day');
          continue;
        }

        if (currentTime.isSame(moment(time.to).startOf('day'))) {
          newTimes.push(toTimePeriod(currentTime, time.to));
        } else {
          newTimes.push(
            toTimePeriod(currentTime, moment(currentTime).endOf('day'))
          );
        }

        currentTime.add(1, 'day');
      }
    });
    this.times = newTimes;
    return this;
  }

  /**
   * Filters the times based on the min and max times given
   *
   * @param {moment.Moment} minTime
   * @param {moment.Moment} maxTime
   * @returns {TimeBucket}
   * @memberof TimeBucket
   */
  betweenTime(
    minTime: moment.Moment,
    maxTime: moment.Moment,
    transform: boolean = false
  ): TimeBucket {
    if (!transform) {
      return this._filterBetweenTimes(minTime, maxTime);
    }

    const minTimeInMinutes = this._minutesInDay(minTime);
    const minMaxDiff = maxTime
      .clone()
      .startOf('day')
      .diff(minTime.clone().startOf('day'), 'days');
    const appendMaxTime =
      minMaxDiff > 0
        ? this._minutesInDay(moment().endOf('day')) * minMaxDiff
        : 0;
    const maxTimeInMinutes = this._minutesInDay(maxTime) + appendMaxTime;

    const newTimes: ITimePeriod[] = [];
    this.times.map((time: ITimePeriod): void => {
      let from: moment.Moment = time.from;
      let to: moment.Moment = time.to;

      const dayDiff = to
        .clone()
        .startOf('day')
        .diff(from.clone().startOf('day'), 'days');
      const appendDayMinutes =
        dayDiff > 0 ? this._minutesInDay(moment().endOf('day')) * dayDiff : 0;

      const fromInMinutes = this._minutesInDay(from);
      const toInMinutes = this._minutesInDay(to) + appendDayMinutes;

      if (fromInMinutes > maxTimeInMinutes || toInMinutes < minTimeInMinutes) {
        return;
      }

      if (fromInMinutes < minTimeInMinutes) {
        from = moment(from)
          .hours(minTime.hours())
          .minutes(minTime.minutes())
          .seconds(minTime.seconds());
      }

      if (toInMinutes > maxTimeInMinutes) {
        to = moment(to)
          .hours(maxTime.hours())
          .minutes(maxTime.minutes())
          .seconds(maxTime.seconds());
      }

      newTimes.push(toTimePeriod(from, to));
    });
    this.times = newTimes;
    return this;
  }

  /* --------------------------------------------------------------*/
  /* --------------------------- Ordering -------------------------*/
  /* --------------------------------------------------------------*/

  /**
   * Randomises the order of the times array
   *
   * @returns {TimeBucket}
   * @memberof TimeBucket
   */
  shuffle(): TimeBucket {
    const oldTimes: ITimePeriod[] = [...this.times];
    let newTimes: ITimePeriod[] = [];

    while (oldTimes.length) {
      const index: number = Math.floor(Math.random() * oldTimes.length);
      newTimes = newTimes.concat(oldTimes.splice(index, 1));
    }

    this.times = newTimes;
    return this;
  }

  /**
   * Sorts the times chronologically from their start time
   *
   * @returns {TimeBucket}
   * @memberof TimeBucket
   */
  sort(): TimeBucket {
    this.times = this.times.sort(
      (timeA: ITimePeriod, timeB: ITimePeriod): number => {
        if (timeA.from.isBefore(timeB.from)) {
          return -1;
        }
        if (timeA.from.isAfter(timeB.from)) {
          return 1;
        }
        return 0;
      }
    );
    return this;
  }

  /* --------------------------------------------------------------*/
  /* ------------------------- My Privates ------------------------*/
  /* --------------------------------------------------------------*/

  private _filterByDays(days: DayOfWeek[]): TimeBucket {
    this.times = this.times.filter((time: ITimePeriod) => {
      return this._timeIncludesDay(time.from, days);
    });

    return this;
  }

  private _filterBetweenTimes(
    minTime: moment.Moment,
    maxTime: moment.Moment
  ): TimeBucket {
    this.times = this.times.filter((time: ITimePeriod): boolean => {
      return (
        this._minutesInDay(time.from) >= this._minutesInDay(minTime) &&
        this._minutesInDay(time.from) <= this._minutesInDay(maxTime)
      );
    });

    return this;
  }

  private _timeIncludesDay(time: moment.Moment, days: DayOfWeek[]): boolean {
    return days.includes(
      time.format(DAY_OF_WEEK_FORMAT).toLowerCase() as DayOfWeek
    );
  }

  /**
   * Returns the minutes the given day
   *
   * @private
   * @param {moment.Moment} date
   * @returns {number}
   * @memberof TimeBucket
   */
  private _minutesInDay(date: moment.Moment): number {
    const minutesInHour = 60;
    return date.hours() * minutesInHour + date.minutes();
  }

  /**
   * Takes a given ITimePeriod and breaks it up into pieces dictated by the minDuration and maxDuration.
   * Times will be incremented by the durationIncrement to fit the min and max.
   *
   * @private
   * @param {ITimePeriod} time
   * @param {ITimeBucketDistributeOptions} options
   * @returns {ITimePeriod[]}
   * @memberof TimeBucket
   */
  private _split(
    time: ITimePeriod,
    options: ITimeBucketDistributeOptions
  ): ITimePeriod[] {
    let startTime: moment.Moment = moment(time.from);
    const endTime: moment.Moment = moment(time.to);
    const duration: number = endTime.diff(startTime, 'minutes');
    const durationModulus: number = duration % options.maxDuration.asMinutes();

    const times: ITimePeriod[] = [];

    if (
      duration <= options.maxDuration.asMinutes() &&
      duration >= options.minDuration.asMinutes()
    ) {
      return [time];
    }

    // If the modulus is less than the minimum time we'll need to combine
    // It with the last full time period.
    if (durationModulus < options.minDuration.asMinutes()) {
      const biggerTime: ITimePeriod = toTimePeriod(
        startTime,
        moment(startTime).add(options.durationIncrement)
      );
      times.push(...this._split(biggerTime, options));
      startTime = moment(startTime).subtract(options.durationIncrement);
    }

    while (!startTime.isSame(endTime)) {
      let toTime: moment.Moment = moment(startTime).add(options.maxDuration);
      if (toTime.isAfter(endTime)) {
        toTime = moment(endTime);
      }
      const newTime: ITimePeriod = toTimePeriod(startTime, toTime);
      startTime = moment(toTime);
      times.push(newTime);
    }

    return times;
  }

  /**
   * Takes a given ITimePeriod and walks through the time generating times with the given duration.
   *
   * @private
   * @param {ITimePeriod} time
   * @param {ITimeBucketExhaustOptions} options
   * @returns {ITimePeriod[]}
   * @memberof TimeBucket
   */
  private _exhaust(
    time: ITimePeriod,
    options: ITimeBucketExhaustOptions
  ): ITimePeriod[] {
    let startTime: moment.Moment = moment(time.from);
    const endTime: moment.Moment = moment(time.to);
    const times: ITimePeriod[] = [];

    const endTimeDiff: number = endTime.diff(startTime, 'minutes');
    const optionDuration: number = options.duration.asMinutes();

    if (options.keepSmallOptions && endTimeDiff <= optionDuration) {
      const toTime: moment.Moment = moment(startTime).add(
        endTimeDiff,
        'minutes'
      );
      times.push(toTimePeriod(startTime, toTime));
      return times;
    }

    while (endTimeDiff >= optionDuration) {
      const toTime: moment.Moment = moment(startTime).add(options.duration);

      // Discard if its too small
      if (toTime.isAfter(endTime)) {
        return times;
      }

      times.push(toTimePeriod(startTime, toTime));
      startTime = moment(startTime).add(options.timeIncrement);
    }

    return times;
  }

  private _minDuration(min: moment.Duration): TimeBucket {
    this.times = this.times.filter((time: ITimePeriod): boolean => {
      const duration: number = time.to.diff(time.from, 'minutes');
      return min.asMinutes() <= duration;
    });
    return this;
  }

  private _minDate(min: moment.Moment): TimeBucket {
    this.times = this.times.filter((time: ITimePeriod): boolean => {
      return time.from.isSameOrAfter(min);
    });
    return this;
  }

  private _maxDuration(max: moment.Duration): TimeBucket {
    this.times = this.times.filter((time: ITimePeriod): boolean => {
      const duration: number = time.to.diff(time.from, 'minutes');
      return max.asMinutes() >= duration;
    });
    return this;
  }

  private _maxDate(max: moment.Moment): TimeBucket {
    this.times = this.times.filter((time: ITimePeriod): boolean => {
      return time.from.isSameOrBefore(max);
    });
    return this;
  }

  private _trimDay(start: moment.Moment, end: moment.Moment): TimeBucket {
    let newTimes: ITimePeriod[] = this.times;
    newTimes = this._leftTrimDay(newTimes, start);
    newTimes = this._rightTrimDay(newTimes, end);
    this.times = newTimes;
    return this;
  }

  private _trim(start: moment.Moment, end: moment.Moment): TimeBucket {
    let newTimes: ITimePeriod[] = this.times;
    newTimes = this._leftTrim(newTimes, start);
    newTimes = this._rightTrim(newTimes, end);
    this.times = newTimes;
    return this;
  }

  private _leftTrim(times: ITimePeriod[], start: moment.Moment): ITimePeriod[] {
    const newTimes: ITimePeriod[] = [];
    times.map((time: ITimePeriod): void => {
      let from: moment.Moment = time.from;
      const to: moment.Moment = time.to;

      if (time.to.isBefore(start)) {
        return;
      }

      if (time.from.isBefore(start)) {
        from = start;
      }

      newTimes.push(toTimePeriod(from, to));
    });
    return newTimes;
  }

  private _rightTrim(times: ITimePeriod[], end: moment.Moment): ITimePeriod[] {
    const newTimes: ITimePeriod[] = [];
    times.map((time: ITimePeriod): void => {
      const from: moment.Moment = time.from;
      let to: moment.Moment = time.to;

      if (time.from.isAfter(end)) {
        return;
      }

      if (time.to.isAfter(end)) {
        to = end;
      }

      newTimes.push(toTimePeriod(from, to));
    });
    return newTimes;
  }

  private _leftTrimDay(
    times: ITimePeriod[],
    start: moment.Moment
  ): ITimePeriod[] {
    const newTimes: ITimePeriod[] = [];
    times.map((time: ITimePeriod): void => {
      let from: moment.Moment = time.from;
      const to: moment.Moment = time.to;

      if (this._minutesInDay(time.to) < this._minutesInDay(start)) {
        return;
      }

      if (this._minutesInDay(time.from) < this._minutesInDay(start)) {
        from = start;
      }

      newTimes.push(toTimePeriod(from, to));
    });
    return newTimes;
  }

  private _rightTrimDay(
    times: ITimePeriod[],
    end: moment.Moment
  ): ITimePeriod[] {
    const newTimes: ITimePeriod[] = [];
    times.map((time: ITimePeriod): void => {
      const from: moment.Moment = time.from;
      let to: moment.Moment = time.to;

      if (this._minutesInDay(time.from) > this._minutesInDay(end)) {
        return;
      }

      if (this._minutesInDay(time.to) > this._minutesInDay(end)) {
        to = end;
      }

      newTimes.push(toTimePeriod(from, to));
    });
    return newTimes;
  }

  private _mergeOverlapping(times: ITimePeriod[]): ITimePeriod[] {
    const mergedTimes: ITimePeriod[] = [];
    if (!this.times.length) {
      return [];
    }
    let lastEvent: ITimePeriod = toTimePeriod(
      this.times[0].from,
      this.times[0].to
    );

    times.map((time: ITimePeriod) => {
      if (lastEvent.to.isSameOrAfter(time.from, 'minutes')) {
        const endTime = getLaterTime(time.to, lastEvent.to);
        const mergedTime: ITimePeriod = toTimePeriod(lastEvent.from, endTime);
        mergedTimes.pop();
        mergedTimes.push(mergedTime);
        lastEvent = mergedTime;
        return;
      }

      if (lastEvent.from.isSame(time.from, 'minutes')) {
        const from: moment.Moment = lastEvent.from;
        const to: moment.Moment = getLaterTime(time.to, lastEvent.to);
        const mergedTime: ITimePeriod = toTimePeriod(from, to);
        mergedTimes.pop();
        mergedTimes.push(mergedTime);
        lastEvent = mergedTime;
        return;
      }

      mergedTimes.push(time);
      lastEvent = toTimePeriod(time.from, time.to);
    });

    return mergedTimes;
  }

  private _subtract(
    times: ITimePeriod[],
    timesToSubtract: ITimePeriod[]
  ): ITimePeriod[] {
    const filter: SubtractTimeFilter = new SubtractTimeFilter();
    return new TimeBucket([...filter.filter(times, timesToSubtract)])
      .clone()
      .mergeOverlapping()
      .get();
  }
}
