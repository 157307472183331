import { isObject } from '@principle-theorem/shared';
import {
  type IBaseTransactionCompleteCallbackData,
  type IHealthPointClaimRequestParams,
  type IHealthPointTransactionCompleteCallbackData,
  type IHealthPointTransactionSuccessBaseCallbackData,
  isHealthPointTransactionCompleteCallbackData,
  isHealthPointTransactionSuccessBaseCallbackData,
} from '@principle-theorem/tyro';
import { type ITyroBaseExtendedData } from './tyro-base-extended-data';

export interface IHealthPointTransactionExtendedData
  extends ITyroBaseExtendedData {
  request: IHealthPointClaimRequestParams;
  response:
    | IBaseTransactionCompleteCallbackData
    | IHealthPointTransactionSuccessBaseCallbackData
    | IHealthPointTransactionCompleteCallbackData;
}

export function isHealthPointTransactionExtendedData(
  data: unknown
): data is IHealthPointTransactionExtendedData {
  if (!isObject(data)) {
    return false;
  }
  const validResponse =
    isHealthPointTransactionSuccessBaseCallbackData(data.response) ||
    isHealthPointTransactionCompleteCallbackData(data.response);
  // TODO: #3ax3vq Check request property?
  return validResponse;
}
