export interface IPerioSettings {
  autoSwitchCells: boolean;
  switchPattern: SwitchPattern;
  switchDirection: SwitchDirection;
}

export enum SwitchDirection {
  Right = 'right',
  Left = 'left',
}

export enum SwitchPattern {
  Zigzag = 'zigzag',
  Reset = 'reset',
}

export const DEFAULT_PERIO_SETTINGS: IPerioSettings = {
  autoSwitchCells: true,
  switchPattern: SwitchPattern.Reset,
  switchDirection: SwitchDirection.Right,
};
