import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

@Component({
  selector: 'pt-loader-message-snack-bar',
  templateUrl: './loader-message-snack-bar.component.html',
  styleUrls: ['./loader-message-snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderMessageSnackBarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public message: string,
    @Inject(MatSnackBarRef)
    public snackBarRef: MatSnackBarRef<LoaderMessageSnackBarComponent>
  ) {}
}
