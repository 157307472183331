import { AtLeast } from '@principle-theorem/shared';
import { uniq, uniqWith } from 'lodash';
import { BigQueryTable } from '../big-query-tables';
import { getRowNumberAttribute } from '../helpers';
import {
  BigQuerySQL,
  GroupBy,
  IReportingQuery,
  ITableJoin,
  IUnnestQuery,
  SortOrder,
} from '../querying';
import { IMeasureRef } from './measure-properties';

export class QueryFactory {
  constructor(private _query: IReportingQuery) {}

  static fromQuery(query: AtLeast<IReportingQuery, 'table'>): QueryFactory {
    return new QueryFactory({ attributes: [], ...query });
  }

  static fromTable(table: BigQueryTable): QueryFactory {
    return new QueryFactory({ attributes: [], table });
  }

  static fromMeasures(
    table: BigQueryTable,
    measure: string[] | string,
    unnest?: IUnnestQuery
  ): QueryFactory {
    if (!Array.isArray(measure)) {
      measure = [measure];
    }
    const query = this.fromTable(table).attributes(measure);
    if (unnest) {
      query.unnest(unnest);
    }
    return query;
  }

  get(): IReportingQuery {
    return this._query;
  }

  attributes(measures: string[]): this {
    this._query.attributes = measures;
    return this;
  }

  unnest(unnest: IUnnestQuery): this {
    this._query.unnest = unnest;
    return this;
  }

  mergeJoins(joins?: ITableJoin[]): this {
    this._query.joins = [...(this._query.joins ?? []), ...(joins ?? [])];
    return this;
  }

  override(query: Partial<IReportingQuery>): this {
    this._query = { ...this._query, ...query };
    return this;
  }

  merge(queries: Partial<IReportingQuery>[]): this {
    this._query = queries.reduce((acc: IReportingQuery, query) => {
      const hasJoin = query.joins?.length || acc.joins?.length;
      if (query.table && query.table !== acc.table && !hasJoin) {
        throw new Error(`Cannot merge queries with different tables`);
      }

      if (acc.unnest && query.unnest) {
        if (!isSameUnnest(acc.unnest, query.unnest)) {
          throw new Error(`Cannot merge queries with multiple unnests`);
        }
      }

      const joins = uniqWith(
        [...(acc.joins ?? []), ...(query.joins ?? [])],
        isSameJoin
      );

      const base = {
        ...acc,
        attributes: uniq([...acc.attributes, ...(query.attributes ?? [])]),
        filters: uniq([...(acc.filters ?? []), ...(query.filters ?? [])]),
        joins,
      };
      if (query.unnest) {
        base.unnest = query.unnest;
      }
      if (query.subQuery) {
        base.subQuery = query.subQuery;
      }
      return base;
    }, this._query);

    return this;
  }

  latestEvent(
    table: BigQueryTable,
    groupBy: GroupBy,
    orderBy?: string,
    sortOrder: SortOrder = SortOrder.Descending
  ): this {
    const rowNumberAlias = 'row_number';
    this._query.subQuery = {
      table,
      attributes: [
        '*',
        getRowNumberAttribute(rowNumberAlias, groupBy, orderBy, sortOrder),
      ],
      filters: [`${rowNumberAlias} = 1`],
    };
    return this;
  }

  filterSoftDeleted(measure: IMeasureRef): this {
    this._query.attributes = [measure.attributePath];
    return this.filter(
      BigQuerySQL.or([
        BigQuerySQL.filter(measure.factPropertyPath, '=', false),
        BigQuerySQL.filter(measure.factPropertyPath, 'IS', undefined),
      ])
    );
  }

  filter(filter: string): this {
    this._query.filters = [...(this._query.filters ?? []), filter];
    return this;
  }
}

function isSameUnnest(aUnnest: IUnnestQuery, bUnnest: IUnnestQuery): boolean {
  return (
    aUnnest.property === bUnnest.property && aUnnest.alias === bUnnest.alias
  );
}

function isSameJoin(aJoin: ITableJoin, bJoin: ITableJoin): boolean {
  return aJoin.alias === bJoin.alias && aJoin.table === bJoin.table;
}
