<form [formGroup]="form">
  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
    <mat-form-field fxFlex="50">
      <mat-label>Practice</mat-label>
      <mat-select
        formControlName="practiceRef"
        [compareWith]="isSelectedPracticeRef"
        [required]="true"
      >
        <mat-option
          *ngFor="let practice of practices$ | async; trackBy: trackByPractice"
          [value]="practice.ref"
          >{{ practice.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="50">
      <mat-label>Modality</mat-label>
      <mat-select formControlName="providerModality">
        <mat-option
          *ngFor="let item of modality.options; trackBy: modality.trackBy"
          [value]="item.value"
        >
          {{ item.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="50">
      <mat-label>Provider Number</mat-label>
      <input
        matInput
        formControlName="providerNumber"
        minlength="8"
        maxlength="8"
      />
      <mat-hint align="end">
        {{ form.value.providerNumber?.length || 0 }}/8 Characters
      </mat-hint>
      <mat-error
        *ngIf="
          form.controls.providerNumber | formControlError$: 'pattern' | async
        "
      >
        Must not contain special characters
      </mat-error>
    </mat-form-field>

    <div matTextSuffix (click)="$event.stopImmediatePropagation()">
      <ng-content></ng-content>
    </div>
  </div>
</form>
