import { _isNumberValue } from '@angular/cdk/coercion';
import { type MatSort } from '@angular/material/sort';
import { getSchemaSize, getSchemaText } from '@principle-theorem/editor';
import { ObservableDataSource } from '@principle-theorem/ng-shared';
import { type ILabJob } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { labJobSortOptionById } from '../../lab-job-sort-options';

export class LabJobListDataSource extends ObservableDataSource<
  WithRef<ILabJob>
> {
  override _filterData(data: WithRef<ILabJob>[]): WithRef<ILabJob>[] {
    this.filteredData = data;

    if (this.filter) {
      this.filteredData = data.filter((labJob) =>
        this.filterPredicate(labJob, this.filter)
      );
    }

    if (this.paginator) {
      this._updatePaginator(this.filterPredicate.length);
    }
    return this.filteredData;
  }

  override filterPredicate = (
    labJob: WithRef<ILabJob>,
    filter: string
  ): boolean => {
    const filterValues: string[] = [
      getSchemaSize(labJob.title) ? getSchemaText(labJob.title) : '',
      labJob.lab.name,
      labJob.patient.name,
      labJob.status.toString(),
    ];

    const labJobFilterString: string = filterValues.join(' ').toLowerCase();
    const transformedFilter: string = filter.trim().toLowerCase();

    return labJobFilterString.indexOf(transformedFilter) !== -1;
    // eslint-disable-next-line @typescript-eslint/semi,@typescript-eslint/member-delimiter-style
  };

  override sortingDataAccessor = (
    labJob: WithRef<ILabJob>,
    sortId: string
  ): string | number => {
    const sortHandler = labJobSortOptionById(sortId);
    if (!sortHandler) {
      const value = labJob[sortId as keyof WithRef<ILabJob>];
      return _isNumberValue(value) ? Number(value) : String(value);
    }

    if (this.sort) {
      this.sort.direction = sortHandler.direction;
    }
    return sortHandler.sortAccessor(labJob);
    // eslint-disable-next-line @typescript-eslint/semi,@typescript-eslint/member-delimiter-style
  };

  override sortData: (
    data: WithRef<ILabJob>[],
    sort: MatSort
  ) => WithRef<ILabJob>[] = (
    data: WithRef<ILabJob>[],
    sort: MatSort
  ): WithRef<ILabJob>[] => {
    const sortId = sort.active;
    const sortHandler = labJobSortOptionById(sortId);
    if (!sortId || !sortHandler) {
      return data;
    }

    return data.sort((a, b) => {
      const valueA = this.sortingDataAccessor(a, sortId);
      const valueB = this.sortingDataAccessor(b, sortId);

      let sortValue = 0;

      // eslint-disable-next-line no-null/no-null
      if (valueA === null && valueB === null) {
        return 0;
      }
      // eslint-disable-next-line no-null/no-null
      if (valueA !== null) {
        sortValue = 1;
      }
      // eslint-disable-next-line no-null/no-null
      if (valueB !== null) {
        sortValue = -1;
      }

      if (sortHandler.sort) {
        return sortHandler.sort(a, b);
      }

      if (valueA > valueB) {
        sortValue = 1;
      }
      if (valueA < valueB) {
        sortValue = -1;
      }
      return sortValue * (sortHandler.direction === 'asc' ? 1 : -1);
    });
    // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
  };
}
