import { type IXSLXImport } from '@principle-theorem/shared';
import type { Row } from 'exceljs';
import {
  EXCLUDED_APPOINTMENT_BOOK_HEADERS,
  IExcludedAppointmentBookXSLX,
} from './excluded-appointment-book-to-xlsx';

export class XSLXToExcludedAppointmentBooks
  implements IXSLXImport<IExcludedAppointmentBookXSLX>
{
  headers = EXCLUDED_APPOINTMENT_BOOK_HEADERS;

  translate(row: Row): IExcludedAppointmentBookXSLX {
    return {
      sourceId: row.getCell('sourceId').value?.toString() ?? '',
      sourceName: row.getCell('sourceName').value?.toString() ?? '',
      exclude: row
        .getCell('exclude')
        .value?.toString() as IExcludedAppointmentBookXSLX['exclude'],
    };
  }
}
