import {
  type CanBeChartedProperty,
  type CrossfilterGroup,
  type DataAccessor,
  getMeasureResults,
  type IChartConfig,
  type IDataBuilder,
  type IMeasureResult,
} from '@principle-theorem/reporting';
import { Timezone } from '@principle-theorem/shared';
import crossfilter2, {
  type ComparableObject,
  type ComparableValue,
  type Crossfilter,
  type Dimension,
  type NaturallyOrderedValue,
} from 'crossfilter2';
import * as d3 from 'd3';
import { get, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { type IDataTransformer, type ISyncDataTransformer } from './interfaces';

export interface IDCTransformResult {
  measureUid: string;
  measure: CanBeChartedProperty;
  factTable: Crossfilter<unknown>;
  dimension: Dimension<unknown, NaturallyOrderedValue>;
  group?: CrossfilterGroup;
}

export class DCDataTransformer
  implements
    IDataTransformer<IDCTransformResult[]>,
    ISyncDataTransformer<IDCTransformResult[]>
{
  timezone: Timezone = Timezone.AustraliaSydney; // TODO: Set this at some point

  transformMeasureResults(results: IMeasureResult[]): IDCTransformResult[] {
    return results.map((result) => {
      const factTable = crossfilter2(result.data);
      const dimension = this.getDimension(
        factTable,
        result.config,
        result.measure.measure
      );
      const group = this.getGroup(result.measure.measure, dimension);
      return {
        measureUid: result.measure.uid,
        measure: result.measure.measure,
        factTable,
        dimension,
        group,
      };
    });
  }

  async transform(
    config: IChartConfig,
    dataBuilder: IDataBuilder
  ): Promise<IDCTransformResult[]> {
    const results = await getMeasureResults(config, dataBuilder);
    return this.transformMeasureResults(results);
  }

  getGroup(
    measure: CanBeChartedProperty,
    dimension: Dimension<unknown, NaturallyOrderedValue>
  ): CrossfilterGroup | undefined {
    if (!measure.measure.reduceBy) {
      // eslint-disable-next-line no-console
      console.error('Measure does not have reduceBy', measure);
      return;
    }
    return measure.measure.reduceBy(dimension);
  }

  getDimension<T = unknown>(
    factTable: Crossfilter<T>,
    config: IChartConfig,
    measure: CanBeChartedProperty
  ): Dimension<unknown, NaturallyOrderedValue> {
    if (config.builderData.plottedOverTime) {
      return factTable.dimension((fact) =>
        this._dimensionPlottedOverTime<T>(fact, config)
      );
    }

    if (config.builderData.groupByDimension) {
      if (
        config.builderData.groupByDimension.groupMeasure.isArrayDataAccessor
      ) {
        return factTable.dimension(
          (fact) => this._getDimensionValue(config, fact),
          true
        );
      }
      return factTable.dimension(
        (fact): IGroupedDimension => ({
          label: this._getLabel(config, fact),
          colour: this._getColour(config, fact),
          valueOf: () => this._getDimensionValue(config, fact),
        })
      );
    }

    return factTable.dimension((_fact): IGroupedDimension => {
      const label = measure.metadata.label;
      return { label, valueOf: () => label };
    });
  }

  protected _dimensionPlottedOverTime<T>(
    fact: T,
    config: IChartConfig
  ): IGroupedDimension {
    // TODO: Implement accumulator type reporting referenced at
    // https://stackoverflow.com/questions/40619760/dc-js-crossfilter-add-running-cumulative-sum/40620196#40620196
    const dataAccessor =
      config.builderData.groupByDimension?.groupMeasure.dataAccessor;
    const timestamp = this._getFactTimestamp(fact, dataAccessor);
    const timestampValueOf = d3.timeDay(timestamp.toDate()).valueOf();

    return {
      timestamp,
      label: config.builderData.groupByDimension
        ? this._getLabel(config, fact)
        : timestamp.format(),
      valueOf: () => timestampValueOf,
    };
  }

  protected _getDimensionValue(
    config: IChartConfig,
    fact: unknown
  ): ComparableValue {
    if (!config.builderData.groupByDimension) {
      throw new DimensionNotFoundError();
    }
    try {
      return config.builderData.groupByDimension.groupMeasure.dataAccessor(
        fact
      );
    } catch (error) {
      throw new DimensionNotFoundError();
    }
  }

  protected _getLabel(config: IChartConfig, fact: unknown): string {
    if (
      config.builderData.groupByDimension &&
      config.builderData.groupByDimension.groupMeasure.labelAccessor
    ) {
      return config.builderData.groupByDimension.groupMeasure.labelAccessor(
        fact
      );
    }
    return '';
  }

  protected _getColour(
    config: IChartConfig,
    fact: unknown
  ): string | undefined {
    if (
      config.builderData.groupByDimension &&
      config.builderData.groupByDimension.groupMeasure.colourAccessor
    ) {
      return config.builderData.groupByDimension.groupMeasure.colourAccessor(
        fact
      );
    }
    return undefined;
  }

  // TODO: Unit test this
  private _getFactTimestamp(
    fact: unknown,
    dataAccessor?: DataAccessor
  ): moment.Moment {
    const rawValue: unknown = dataAccessor
      ? dataAccessor(fact)
      : get(fact, 'timestamp');
    const stringValue = isString(rawValue) ? rawValue : undefined;
    const value = moment.tz(stringValue, this.timezone);
    return value.isValid() ? value : moment.tz(this.timezone);
  }
}

export interface IGroupedDimension extends ComparableObject {
  timestamp?: Moment;
  dimension?: ComparableValue;
  colour?: string;
  label: string;
}

export class DimensionNotGivenError extends Error {}

export class DimensionNotFoundError extends Error {}
