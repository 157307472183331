import {
  CustomFrequency,
  CustomRecurrenceFrequency,
  CustomWeeklyPattern,
  DAYS_OF_WEEK,
  DAY_OF_WEEK_FORMAT,
  DayOfWeek,
  EndingType,
  Frequency,
  IRecurrencePattern,
  ITimePeriod,
  RecurrencePatternOption,
  Timezone,
  isEnumValue,
  titlecase,
  toDayOfWeek,
  toISODate,
  toMoment,
  toMomentTz,
} from '@principle-theorem/shared';
import { isArray, isEqual, isNumber } from 'lodash';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { ordinalSuffix } from '../common';

export enum RecurrenceDirection {
  Forward = 'forward',
  Backward = 'backward',
}

function isCustomWeeklyPattern(
  pattern: IRecurrencePattern
): pattern is IRecurrencePattern & CustomWeeklyPattern {
  return (
    pattern.frequencyType === Frequency.Custom &&
    pattern.customFrequencyType === CustomRecurrenceFrequency.Weekly &&
    isArray(pattern.daysOfWeek) &&
    isNumber(pattern.seperationCount)
  );
}

function isCustomPattern(
  pattern: IRecurrencePattern
): pattern is IRecurrencePattern & CustomFrequency {
  return (
    pattern.frequencyType === Frequency.Custom &&
    isEnumValue(CustomRecurrenceFrequency, pattern.customFrequencyType)
  );
}

export class RecurrencePattern {
  static init(overrides?: Partial<IRecurrencePattern>): IRecurrencePattern {
    return {
      frequencyType: Frequency.Daily,
      endingType: EndingType.Never,
      seperationCount: 1,
      daysOfWeek: [],
      daysOfMonth: [],
      weeksOfMonth: [],
      monthsOfYear: [],
      ...overrides,
    };
  }

  static updateEndAfter(
    pattern: IRecurrencePattern,
    count: number
  ): IRecurrencePattern {
    return {
      ...pattern,
      endingType: EndingType.Count,
      occurrenceCount: count,
    };
  }

  static updateEndOn(
    pattern: IRecurrencePattern,
    date: moment.Moment
  ): IRecurrencePattern {
    return {
      ...pattern,
      endingType: EndingType.Date,
      endingDate: toISODate(date),
    };
  }

  static matches(
    current: IRecurrencePattern,
    other?: IRecurrencePattern
  ): boolean {
    if (!other) {
      return false;
    }

    return Object.keys(other).every((key: string): boolean => {
      const value = other[key as keyof IRecurrencePattern];
      return value === current[key as keyof IRecurrencePattern];
    });
  }

  static resetEnding(pattern: IRecurrencePattern): IRecurrencePattern {
    return {
      ...pattern,
      endingType: EndingType.Never,
      occurrenceCount: 0,
      endingDate: undefined,
    };
  }

  static getPatternSummary(pattern: RecurrencePatternOption): string {
    if (pattern.frequencyType === Frequency.Custom) {
      switch (pattern.customFrequencyType) {
        case CustomRecurrenceFrequency.Daily:
          return `Every ${ordinalSuffix(pattern.seperationCount)} day`;
        case CustomRecurrenceFrequency.Weekly:
          return `Every ${ordinalSuffix(
            pattern.seperationCount
          )} week on ${pattern.daysOfWeek.join(', ')}`;
        case CustomRecurrenceFrequency.Monthly:
          return `Every ${ordinalSuffix(pattern.seperationCount)} month`;
        case CustomRecurrenceFrequency.Yearly:
          return (
            `Every ${ordinalSuffix(pattern.seperationCount)} year` +
            ` on the ${toMoment(pattern.startDate).format('Do')} of ${toMoment(
              pattern.startDate
            ).format('MMMM')} `
          );
        default:
          break;
      }
      return 'Never';
    }

    switch (pattern.frequencyType) {
      case Frequency.Daily:
        if (
          pattern.daysOfWeek &&
          !isEqual(pattern.daysOfWeek, DAYS_OF_WEEK) &&
          pattern.daysOfWeek.length
        ) {
          return `Every ${pattern.daysOfWeek
            .map((day) => titlecase(day))
            .join(', ')}`;
        }
        return 'Every day';
      case Frequency.Weekly:
        return `Every ${toMoment(pattern.startDate).format(
          DAY_OF_WEEK_FORMAT
        )}`;
      case Frequency.Monthly:
        return `${toMoment(pattern.startDate).format('Do')} of each month`;
      case Frequency.Yearly:
        return `${toMoment(pattern.startDate).format('Do')} of ${toMoment(
          pattern.startDate
        ).format('MMMM')} each year`;
      default:
        return 'Never';
    }
  }

  static getNextOccurrenceDate(
    pattern: IRecurrencePattern,
    timezone: Timezone,
    previousDate?: moment.Moment
  ): moment.Moment {
    if (isCustomPattern(pattern)) {
      return RecurrencePattern.determineNextCustomOccurence(
        pattern,
        timezone,
        previousDate
      );
    }

    return RecurrencePattern.determineNextBasicOccurence(
      pattern,
      timezone,
      previousDate
    );
  }

  static adjustNextOccurrenceToValidDay(
    initialDate: Moment,
    daysOfWeek: DayOfWeek[] = [],
    direction: RecurrenceDirection,
    timezone: Timezone
  ): Moment {
    if (
      !daysOfWeek.length ||
      isEqual(daysOfWeek, DAYS_OF_WEEK) ||
      daysOfWeek.includes(toDayOfWeek(initialDate, timezone))
    ) {
      return initialDate;
    }

    const nextDate = initialDate.clone();
    while (!daysOfWeek.includes(toDayOfWeek(nextDate, timezone))) {
      direction === RecurrenceDirection.Forward
        ? nextDate.add(1, 'day')
        : nextDate.subtract(1, 'day');
    }

    return nextDate;
  }

  static getNextTaskOccurrence(
    pattern: IRecurrencePattern,
    timezone: Timezone,
    lastTaskDate?: moment.Moment
  ): moment.Moment {
    if (isCustomPattern(pattern)) {
      return RecurrencePattern.determineNextCustomOccurence(
        pattern,
        timezone,
        lastTaskDate
      );
    }

    if (!lastTaskDate) {
      const initialDate = toMomentTz(pattern.startDate ?? moment(), timezone);
      return RecurrencePattern.adjustNextOccurrenceToValidDay(
        initialDate,
        pattern.daysOfWeek,
        RecurrenceDirection.Forward,
        timezone
      );
    }

    return RecurrencePattern.adjustNextOccurrenceToValidDay(
      getAdjustedDate(lastTaskDate, pattern),
      pattern.daysOfWeek,
      RecurrenceDirection.Forward,
      timezone
    );
  }

  static determineNextBasicOccurence(
    pattern: IRecurrencePattern,
    timezone: Timezone,
    previousDate?: moment.Moment
  ): moment.Moment {
    if (!previousDate) {
      return toMomentTz(pattern.startDate ?? moment(), timezone);
    }

    return getAdjustedDate(previousDate, pattern);
  }

  static determineNextCustomOccurence(
    pattern: IRecurrencePattern & CustomFrequency,
    timezone: Timezone,
    previousDate?: moment.Moment
  ): moment.Moment {
    const startDate =
      previousDate ?? toMomentTz(pattern.startDate ?? moment(), timezone);

    const nextDateAdjusted: moment.Moment = moment(startDate);
    let separationType: moment.DurationInputArg2;
    switch (pattern.customFrequencyType) {
      case CustomRecurrenceFrequency.Yearly:
        separationType = 'year';
        break;
      case CustomRecurrenceFrequency.Monthly:
        separationType = 'month';
        break;
      case CustomRecurrenceFrequency.Weekly:
        separationType = 'week';
        break;
      case CustomRecurrenceFrequency.Daily:
      default:
        separationType = 'day';
        break;
    }

    if (!isCustomWeeklyPattern(pattern)) {
      if (!previousDate && startDate) {
        return startDate;
      }

      while (nextDateAdjusted.isSameOrBefore(startDate)) {
        nextDateAdjusted.add(pattern.seperationCount, separationType);
      }
      return nextDateAdjusted;
    }

    return RecurrencePattern.determineNextDayOfWeek(
      pattern,
      nextDateAdjusted,
      timezone
    );
  }

  static getFirstOccurrenceDate(
    pattern: IRecurrencePattern,
    timezone: Timezone,
    startOfPattern: moment.Moment
  ): moment.Moment {
    if (!isCustomWeeklyPattern(pattern)) {
      return startOfPattern;
    }
    return RecurrencePattern.determineNextDayOfWeek(
      pattern,
      startOfPattern,
      timezone,
      true
    );
  }

  static getNearestOccurrenceDateToRange(
    pattern: IRecurrencePattern,
    startOfPattern: moment.Moment,
    range: ITimePeriod,
    timezone: Timezone
  ): moment.Moment {
    if (!isCustomWeeklyPattern(pattern)) {
      return startOfPattern;
    }

    const startOfRange = toMomentTz(range.from, timezone)
      .subtract(pattern.seperationCount - 1, 'week')
      .startOf('week');

    return RecurrencePattern.determineNextDayOfWeek(
      pattern,
      startOfRange.isBefore(startOfPattern) ? startOfPattern : startOfRange,
      timezone,
      true
    );
  }

  static determineNextDayOfWeek(
    pattern: IRecurrencePattern & CustomWeeklyPattern,
    startOfPattern: moment.Moment,
    timezone: Timezone,
    includeStart: boolean = false
  ): moment.Moment {
    const nextDateAdjusted = toMomentTz(startOfPattern, timezone);

    if (!includeStart) {
      while (nextDateAdjusted.isSameOrBefore(startOfPattern)) {
        nextDateAdjusted.add(1, 'day');
      }
    }

    if (!pattern.daysOfWeek.length) {
      pattern.daysOfWeek = DAYS_OF_WEEK;
    }

    const startDate = pattern.startDate
      ? toMomentTz(pattern.startDate, timezone)
      : toMomentTz(startOfPattern, timezone);

    let dateFound = false;
    while (!dateFound) {
      const modulus = matchesWeekPattern(
        pattern,
        startDate,
        nextDateAdjusted,
        timezone
      );
      if (modulus !== 0) {
        nextDateAdjusted
          .add(pattern.seperationCount - 1, 'week')
          .startOf('week');
      }

      if (
        pattern.daysOfWeek.includes(toDayOfWeek(nextDateAdjusted, timezone))
      ) {
        dateFound = true;
        break;
      }

      nextDateAdjusted.add(1, 'day');
    }

    return nextDateAdjusted;
  }
}

function getSeparationType(frequencyType: Frequency): moment.DurationInputArg2 {
  switch (frequencyType) {
    case Frequency.Daily:
      return 'day';
    case Frequency.Weekly:
      return 'week';
    case Frequency.Monthly:
      return 'month';
    case Frequency.Yearly:
      return 'year';
    default:
      return 'day';
  }
}

export function getAdjustedDate(
  previousDate: moment.Moment,
  pattern: IRecurrencePattern
): moment.Moment {
  const nextDateAdjusted = moment(previousDate);
  const separationType = getSeparationType(pattern.frequencyType);

  while (nextDateAdjusted.isSameOrBefore(previousDate)) {
    nextDateAdjusted.add(pattern.seperationCount, separationType);
  }

  return nextDateAdjusted;
}

function matchesWeekPattern(
  pattern: IRecurrencePattern & CustomWeeklyPattern,
  startDate: Moment,
  nextDateAdjusted: Moment,
  timezone: Timezone
): number {
  if (pattern.seperationCount <= 1) {
    return 0;
  }
  const diffInWeeks = toMomentTz(startDate, timezone)
    .startOf('week')
    .diff(toMomentTz(nextDateAdjusted, timezone).startOf('week'), 'week');

  return Math.abs(diffInWeeks) % pattern.seperationCount;
}
