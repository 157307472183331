import {
  IIntegration,
  IntegrationStorage,
  IntegrationType,
} from '@principle-theorem/integrations';
import {
  IPractice,
  SenderType,
} from '@principle-theorem/principle-core/interfaces';
import {
  CollectionReference,
  DocumentReference,
} from '@principle-theorem/shared';
import { resolveOrganisationFromSender } from '../interaction/send-sms-request';
import { Organisation } from '../organisation/organisation';

export interface ITNZIntegrationData {
  enabled: boolean;
  testModeEnabled: boolean;
  outboundCost?: number;
  inboundCost?: number;
}

export class TNZIntegrationStorage extends IntegrationStorage<ITNZIntegrationData> {
  constructor() {
    super(IntegrationType.TNZ);
  }

  static init(data: Partial<ITNZIntegrationData>): ITNZIntegrationData {
    return {
      enabled: false,
      testModeEnabled: false,
      ...data,
    };
  }

  override async upsert(
    integrationsCollection: CollectionReference<
      IIntegration<ITNZIntegrationData>
    >,
    data: Partial<ITNZIntegrationData>
  ): Promise<void> {
    const current = await this.get(integrationsCollection);
    return super.upsert(
      integrationsCollection,
      TNZIntegrationStorage.init({ ...current?.data, ...data })
    );
  }
}

export async function resolveTNZIntegrationSettings(
  practiceRef: DocumentReference<IPractice>
): Promise<ITNZIntegrationData | undefined> {
  const org = await resolveOrganisationFromSender({
    type: SenderType.Practice,
    ref: practiceRef,
  });
  const integrationsCol = Organisation.integrationCol<ITNZIntegrationData>(org);
  const storage = new TNZIntegrationStorage();
  const data = await storage.get(integrationsCol);
  return data?.data;
}
