import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StateBasedNavigationService } from '@principle-theorem/ng-principle-shared';
import {
  type IParticipant,
  ParticipantType,
} from '@principle-theorem/principle-core/interfaces';
import { type Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-event-participant-link',
  templateUrl: './event-participant-link.component.html',
  styleUrls: ['./event-participant-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventParticipantLinkComponent {
  participant$ = new ReplaySubject<IParticipant>(1);
  participantRoute$: Observable<string[]>;

  @Input()
  set participant(participant: IParticipant) {
    if (participant) {
      this.participant$.next(participant);
    }
  }

  constructor(private _stateNav: StateBasedNavigationService) {
    this.participantRoute$ = this.participant$.pipe(
      switchMap((participant) => {
        if (participant.type === ParticipantType.Patient) {
          return this._stateNav.link.brand$(['patients', participant.ref.id]);
        }
        return this._stateNav.link.brand$(['staff', participant.ref.id]);
      })
    );
  }
}
