<div fxLayout="column" fxLayoutGap="16px">
  <div class="flex gap-4">
    <pt-search-field class="flex-1">
      <input
        matInput
        placeholder="Search"
        #search
        (keyup)="searchMultiTreatmentConfigurations(search.value)"
      />
    </pt-search-field>
    <button
      mat-raised-button
      color="primary"
      (click)="createMultiTreatmentConfig()"
    >
      Create Multi Treatment Configuration
    </button>
  </div>

  <pr-empty-state
    *ngIf="emptyState$ | async"
    image="list"
    title="multi treatment configurations"
  />

  <div class="mat-elevation-z1" *ngIf="dataSource">
    <mat-progress-bar
      *ngIf="dataSource.loading$ | async"
      mode="indeterminate"
    />

    <div class="flex h-full flex-col" *ngIf="dataSource.filteredData.length">
      <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="icon">
          <mat-header-cell *matHeaderCellDef />
          <mat-cell
            *matCellDef="let multiTreatmentConfig"
            class="grow-0 basis-16"
          >
            <mat-icon
              *ngIf="multiTreatmentConfig.display?.icon.slug"
              [ngStyle]="{ color: multiTreatmentConfig.display.colour.value }"
              [svgIcon]="multiTreatmentConfig.display.icon.slug"
            />
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
          <mat-cell *matCellDef="let multiTreatmentConfig">
            <a
              [routerLink]="multiTreatmentConfig.ref.id | map: getRoute : this"
              >{{ multiTreatmentConfig.name }}</a
            >
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayColumns" />
        <mat-row *matRowDef="let row; columns: displayColumns" />
      </mat-table>

      <mat-paginator
        #paginator
        [length]="dataSource.data.length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        class="sticky bottom-0 z-50"
        showFirstLastButtons
      />
    </div>
    <pr-empty-state
      *ngIf="emptySearch$ | async"
      image="search"
      title="search results"
      [searchInputValue]="search.value"
    />
  </div>
</div>
