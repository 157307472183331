<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="wrapper" fxLayout="column" fxLayoutGap="16px">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
    <h1 class="mat-headline-4">Products</h1>
    <pt-buttons-container>
      <button mat-flat-button color="primary" (click)="add()">
        Create Product
      </button>
    </pt-buttons-container>
  </div>

  <ng-container *ngIf="products$ | async as products">
    <mat-list *ngIf="products.length; else noProducts">
      <pr-product-list-item
        *ngFor="let product of products; trackBy: trackByProduct"
        [product]="product"
      />
    </mat-list>
    <ng-template #noProducts>
      <pr-empty-state image="list" title="products" />
    </ng-template>
  </ng-container>
</div>
