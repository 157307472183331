import { type ICanGroupMeasuresProperty } from '@principle-theorem/reporting';
import { type ITimePeriod, sortTimestampAsc } from '@principle-theorem/shared';
import * as d3 from 'd3';
import type * as dc from 'dc';
import { compact, first, last } from 'lodash';
import * as moment from 'moment-timezone';
import { type IDCTransformResult } from '../../../../models/report/charts/data-transformers/dc-data-transformer';

export class TimePlottedChart {
  static getTicksValue(numberOfDays: number): d3.TimeInterval | null {
    const tickSpacing = TimePlottedChart.getTickSpacing(numberOfDays);
    return d3.timeDay.every(tickSpacing);
  }

  static getTicksFormat(): (date: Date) => string {
    const tickFormat = '%b %d';
    return d3.timeFormat(tickFormat);
  }

  static getTimeScale(dateRange: ITimePeriod): d3.ScaleTime<number, number> {
    const offsetMin = d3.timeDay.offset(dateRange.from.toDate(), -0.5);
    const offsetMax = d3.timeDay.offset(dateRange.to.toDate(), 1);
    return d3.scaleTime().domain([offsetMin, offsetMax]).nice();
  }

  static getTickSpacing(numberOfDays: number): number {
    if (numberOfDays > 72) {
      return 0;
    }
    if (numberOfDays > 28) {
      return 3;
    }
    if (numberOfDays > 14) {
      return 2;
    }
    return 1;
  }

  static xAxisSetup(
    chart: dc.BarChart | dc.LineChart | dc.CompositeChart,
    dateRange: ITimePeriod
  ): void {
    const numberOfDays = dateRange.to.diff(moment(dateRange.from), 'days');
    chart.xAxis().ticks(TimePlottedChart.getTicksValue(numberOfDays));
    chart.xAxis().tickFormat(TimePlottedChart.getTicksFormat());
    chart.x(TimePlottedChart.getTimeScale(dateRange).range([0, chart.width()]));
  }

  static preHookHandler(
    chart: dc.BarChart | dc.LineChart | dc.CompositeChart,
    result: IDCTransformResult,
    groupByDimension: ICanGroupMeasuresProperty
  ): void {
    const dateRange = TimePlottedChart.inferDateRange(result, groupByDimension);
    TimePlottedChart.xAxisSetup(chart, dateRange);
  }

  static inferDateRange(
    result: IDCTransformResult,
    groupByDimension: ICanGroupMeasuresProperty
  ): ITimePeriod {
    const rawValues = result.factTable
      .all()
      .map((row) => groupByDimension.groupMeasure.dataAccessor(row));
    const values = rawValues.map((value) => {
      const date = moment(String(value));
      return date.isValid() ? date : undefined;
    });
    const sortedDates = compact(values).sort(sortTimestampAsc);
    const from = first(sortedDates);
    const to = last(sortedDates);
    if (!from || !to) {
      const now = moment();
      // eslint-disable-next-line no-console
      console.warn('Could not infer date range', { values: rawValues });
      return {
        from: now.clone().startOf('week'),
        to: now.clone().endOf('week'),
      };
    }
    return { from, to };
  }
}
