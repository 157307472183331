<form [formGroup]="timingForm" class="flex flex-col gap-4">
  <div class="flex flex-row gap-4">
    <mat-button-toggle-group
      (change)="setImmediately($event.value)"
      [value]="isImmediate()"
    >
      <mat-button-toggle
        [value]="false"
        matTooltip="This will run relative to when the appointment is scheduled for."
      >
        Based on a schedule
      </mat-button-toggle>
      <mat-button-toggle
        [value]="true"
        matTooltip="This will run when the appointment is created/booked or as soon as this automation is added to an existing appointment."
      >
        Immediately
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  @if (!isImmediate()) {
    <div class="flex flex-row gap-4">
      <mat-form-field class="w-32" subscriptSizing="dynamic">
        <mat-label>Amount</mat-label>
        <input
          matInput
          formControlName="amount"
          type="number"
          min="1"
          required
        />
      </mat-form-field>

      <mat-form-field class="w-32" subscriptSizing="dynamic">
        <mat-label>Unit</mat-label>
        <mat-select formControlName="unit" required>
          @for (unit of timingUnits; track unit) {
            <mat-option [value]="unit"> {{ unit }} </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field class="w-72" subscriptSizing="dynamic">
        <mat-label>Direction</mat-label>
        <mat-select formControlName="direction" required>
          @for (direction of timingDirections; track direction.value) {
            <mat-option [value]="direction.value">
              {{ direction.label }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    @if (showTriggerTime()) {
      <div class="flex flex-row items-center gap-4">
        <mat-form-field
          class="w-80"
          subscriptSizing="dynamic"
          matTooltip="Select the days of the week you want this automation to run. An automation due to run on a day that is not selected will be run on the nearest selected day, working backwards."
        >
          <mat-label>On these days</mat-label>
          <mat-select formControlName="daysOfWeek" multiple>
            @for (day of daysOfWeek; track day) {
              <mat-option [value]="day">{{ day | titlecase }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <div class="text-xl">&commat;</div>
        <pt-iso-time-selector
          class="w-48"
          label="Time of day"
          formControlName="time"
        />
      </div>
    }
  }
</form>
