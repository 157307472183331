import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  Signal,
  signal,
} from '@angular/core';
import {
  AnyAutomationConfiguration,
  isGeneratedTaskConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { AutomationIconComponent } from '../automation-icon/automation-icon.component';
import { AutomationLabelComponent } from '../automation-label/automation-label.component';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { map } from 'rxjs/operators';

interface IAssignment {
  isForTeam: boolean;
  assignee: string;
}

@Component({
  selector: 'pr-automation-configuration-list-item',
  templateUrl: './automation-configuration-list-item.component.html',
  styleUrls: ['./automation-configuration-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NgSharedModule,
    NgMaterialModule,
    NgPrincipleSharedModule,
    AutomationIconComponent,
    AutomationLabelComponent,
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex flex-row items-center gap-4' },
})
export class AutomationConfigurationListItemComponent {
  automation = signal<WithRef<AnyAutomationConfiguration> | undefined>(
    undefined
  );
  assignment: Signal<IAssignment | undefined>;
  automation$ = new ReplaySubject<WithRef<AnyAutomationConfiguration>>(1);
  assignment$: Observable<IAssignment | undefined>;

  @Input()
  set config(automation: WithRef<AnyAutomationConfiguration>) {
    this.automation.set(automation);
    if (automation) {
      this.automation$.next(automation);
    }
  }

  constructor() {
    this.assignment$ = this.automation$.pipe(
      map((config) => this._getAssignment(config))
    );
    this.assignment = computed(() => this._getAssignment(this.automation()));
  }

  private _getAssignment(
    config?: WithRef<AnyAutomationConfiguration>
  ): IAssignment | undefined {
    if (!isGeneratedTaskConfiguration(config)) {
      return;
    }
    if (!config.assignee) {
      return;
    }
    return {
      isForTeam: config.isForTeam,
      assignee: config.assignee?.name,
    };
  }
}
