import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  type RouterStateSnapshot,
  type UrlTree,
} from '@angular/router';
import { type IFeature } from '@principle-theorem/feature-flags';
import { findInheritedRouteData } from '@principle-theorem/ng-shared';
import { User } from '@principle-theorem/principle-core';
import { type Observable, of, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { OrganisationService } from '../organisation.service';
import { ManagementService } from './management.service';

@Injectable()
export class HasPermissionGuard {
  constructor(
    private _router: Router,
    private _organisation: OrganisationService,
    private _management: ManagementService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const feature: IFeature | undefined = findInheritedRouteData(
      next,
      'feature'
    );
    let permissions: string[] =
      findInheritedRouteData(next, 'permissions') ?? [];

    if (!permissions.length && !feature) {
      return true;
    }

    if (!permissions.length && feature) {
      permissions = feature.permissions.map((permission) => permission.value);
    }

    if (!permissions.length) {
      return true;
    }

    return combineLatest([
      this._organisation.user$,
      this._management.user$,
    ]).pipe(
      switchMap(([user, managementUser]) => {
        if (!user) {
          return of(false);
        }
        if (managementUser) {
          return of(true);
        }
        return User.hasPermissions$(user, permissions);
      }),
      map((hasPermission) => {
        if (!hasPermission) {
          return this._router.createUrlTree(['/unauthorised']);
        }
        return true;
      })
    );
  }
}
