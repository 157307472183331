<pt-search-field icon="none">
  <input
    matInput
    placeholder="Add Condition"
    autocomplete="off"
    #trigger="matAutocompleteTrigger"
    [formControl]="searchCtrl"
    [matAutocomplete]="autocomplete"
    #autoComplete="matAutocompleteTrigger"
    (click)="autoComplete.openPanel()"
  />
</pt-search-field>

<mat-autocomplete
  #autocomplete="matAutocomplete"
  [autoActiveFirstOption]="true"
  [displayWith]="displayFn"
  [disableRipple]="true"
  (optionSelected)="optionSelected($event)"
  (opened)="viewport.checkViewportSize()"
>
  <div
    (click)="
      $event.preventDefault();
      $event.stopImmediatePropagation();
      $event.stopPropagation()
    "
  >
    <cdk-virtual-scroll-viewport
      #viewport
      class="autocomplete-viewport"
      itemSize="48"
      minBufferPx="250"
      maxBufferPx="300"
    >
      <mat-option
        class="autocomplete-item"
        *cdkVirtualFor="
          let condition of searchFilter.results$ | async;
          trackBy: trackByCondition
        "
        [value]="condition"
      >
        <pr-search-result-charted-item
          [quickChartEnabled]="true"
          [item]="condition"
        />
      </mat-option>
    </cdk-virtual-scroll-viewport>
    <mat-option
      class="autocomplete-actions"
      (onSelectionChange)="trigger.closePanel(); addNewCondition($event)"
    >
      <button class="flex-1" mat-stroked-button>New Condition</button>
    </mat-option>
  </div>
</mat-autocomplete>
