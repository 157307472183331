<pt-workflow-update-button
  *ngrxLet="nextStatus$ as nextStatus"
  [color]="color"
  [type]="type"
  [currentState]="currentStatus$ | async | titlecase"
  [nextState]="nextStatus | titlecase"
  (buttonClick)="updateStatus(nextStatus)"
>
  <button
    mat-menu-item
    *ngFor="let status of statuses; trackBy: trackByStatus"
    [disabled]="status | map: isCurrentStatus$:this | async"
    (click)="updateStatus(status)"
  >
    {{ status | titlecase }}
  </button>
</pt-workflow-update-button>
