import { WithRef } from '@principle-theorem/shared';
import { IFeeSchedule } from './clinical-charting/fees/fee-schedule/fee-schedule';
import { IOrganisation } from './organisation/organisation';
import { IRole } from './auth/role';
import { IUser } from './user';
import { ITag } from './tag/tag';
import { IAutomation, IAutomationConfiguration } from './automation/automation';
import { IBrand } from './brand';
import { ICalendarEvent, ICalendarEventSchedule } from './event/calendar-event';
import { ISchedulingEventReason } from './appointment/cancellation';
import { IConditionConfiguration } from './clinical-charting/conditions/condition-configuration';
import { IContact } from './contact/contact';
import { ILab } from './lab/lab';
import { IManualTransactionType } from './transaction/manual-transaction-type';
import { IMultiTreatmentConfiguration } from './clinical-charting/treatment/multi-treatment-configuration';
import { IPatientPhoneNumber } from './patient-phone-number';
import { IProduct } from './product/product';
import { IReferralSourceConfiguration } from './patient/referral-source';
import { ISnippet } from '@principle-theorem/editor';
import { ITeam } from './team/team';
import { ITemplateDefinition } from './templating/template-definition';
import { ITreatmentCategory } from './treatment-category';
import { ITreatmentConfiguration } from './clinical-charting/treatment/treatment-configuration';
import { ITreatmentTemplate } from './clinical-charting/treatment/treatment-template';
import { ITreatmentPlan } from './clinical-charting/treatment/treatment-plan';
import {
  ITreatmentStep,
  TreatmentStepAutomation,
} from './clinical-charting/treatment/treatment-step';
import { ISubmittedFormHistory } from './custom-forms/submitted-form-history';
import { IClinicalChart } from './clinical-charting/clinical-chart';
import { IPatientDocument } from './patient/patient-document';
import { IPatient } from './patient/patient';
import { IInvoice } from './invoice/invoice';
import { IMedia } from './media/media';
import { IAccountCredit } from './account-credit/account-credit';
import { IInteractionV2 } from './interaction/interaction';
import { IClinicalNote } from './clinical-charting/core/clinical-note';
import { ILabJob } from './lab-job/lab-job';
import { IPractice } from './practice/practice';
import { IRecurringTaskConfiguration } from './task/recurring-task';
import { IScheduleSummary } from './event/schedule-summary';
import { ISterilisationRecord } from './sterilisation/sterilisation-record';
import { ITask } from './task/task';
import { INotification } from '@principle-theorem/notifications';
import { IStaffer } from './staffer/staffer';
import { IAppointment } from './appointment/appointment';
import { IChecklistItem } from './appointment/checklist-item';
import { IFollowUp } from './appointment/follow-up';
import { IPatientMetadataDisplay } from './patient/patient-metadata';
import { IPrescriptionItem } from './prescription/prescription';

export interface IExportedDemoOrganisation {
  brands: IExportedDemoBrand[];
  feeSchedules: WithRef<IFeeSchedule>[];
  organisation: WithRef<IOrganisation>;
  roles: WithRef<IRole>[];
  users: WithRef<IUser>[];
}

export interface IExportedDemoBrand {
  appointmentTags: WithRef<ITag>[];
  automationConfigurations: WithRef<IAutomationConfiguration>[];
  brand: WithRef<IBrand>;
  calendarEvents: WithRef<ICalendarEvent>[];
  cancellationReasons: WithRef<ISchedulingEventReason>[];
  conditionConfigurations: WithRef<IConditionConfiguration>[];
  contacts: WithRef<IContact>[];
  eventTags: WithRef<ITag>[];
  feeSchedules: WithRef<IFeeSchedule>[];
  labs: WithRef<ILab>[];
  manualTransactionTypes: WithRef<IManualTransactionType>[];
  multiTreatmentConfigurations: WithRef<IMultiTreatmentConfiguration>[];
  patientMetadataDisplays: WithRef<IPatientMetadataDisplay>[];
  patientNoteTags: WithRef<ITag>[];
  patientPhoneNumbers: WithRef<IPatientPhoneNumber>[];
  patientTags: WithRef<ITag>[];
  patients: IExportedDemoPatient[];
  practices: IExportedDemoPractice[];
  prescriptionItems?: WithRef<IPrescriptionItem>[];
  products: WithRef<IProduct>[];
  referralSources: WithRef<IReferralSourceConfiguration>[];
  snippets: WithRef<ISnippet>[];
  staff: IExportedDemoStaffer[];
  teams: WithRef<ITeam>[];
  templates: WithRef<ITemplateDefinition>[];
  treatmentCategories: WithRef<ITreatmentCategory>[];
  treatmentConfigurations: WithRef<ITreatmentConfiguration>[];
  treatmentTemplates: WithRef<ITreatmentTemplate>[];
}

export interface IExportedDemoTreatmentPlan {
  treatmentPlan: WithRef<ITreatmentPlan>;
  treatmentSteps: IExportedDemoTreatmentStep[];
}

export interface IExportedDemoTreatmentStep {
  automations: WithRef<IAutomation<TreatmentStepAutomation>>[];
  treatmentStep: WithRef<ITreatmentStep>;
}

export interface IExportedDemoPatient {
  appointments: IExportedDemoAppointment[];
  clinicalCharts: WithRef<IClinicalChart>[];
  clinicalNotes: WithRef<IClinicalNote>[];
  credits: WithRef<IAccountCredit>[];
  history: WithRef<IInteractionV2>[];
  invoices: WithRef<IInvoice>[];
  media: WithRef<IMedia>[];
  patient: WithRef<IPatient>;
  patientDocuments: WithRef<IPatientDocument>[];
  submittedForms: WithRef<ISubmittedFormHistory>[];
  treatmentPlans: IExportedDemoTreatmentPlan[];
}

export interface IExportedDemoPractice {
  contacts: WithRef<IContact>[];
  feeSchedules: WithRef<IFeeSchedule>[];
  labJobs: WithRef<ILabJob>[];
  practice: WithRef<IPractice>;
  products: WithRef<IProduct>[];
  recurringTaskConfigurations: WithRef<IRecurringTaskConfiguration>[];
  scheduleSummaries: WithRef<IScheduleSummary>[];
  sterilisationRecords: WithRef<ISterilisationRecord>[];
  tags: WithRef<ITag>[];
  tasks: WithRef<ITask>[];
}

export interface IExportedDemoStaffer {
  conditionConfigurations: WithRef<IConditionConfiguration>[];
  feeSchedules: WithRef<IFeeSchedule>[];
  multiTreatmentConfigurations: WithRef<IMultiTreatmentConfiguration>[];
  notifications: WithRef<INotification>[];
  rosterSchedules: WithRef<ICalendarEventSchedule>[];
  snippets: WithRef<ISnippet>[];
  staffer: WithRef<IStaffer>;
  treatmentConfigurations: WithRef<ITreatmentConfiguration>[];
}

export interface IExportedDemoAppointment {
  appointment: WithRef<IAppointment>;
  appointmentInteractions: WithRef<IInteractionV2>[];
  checklists: WithRef<IChecklistItem>[];
  followUps: WithRef<IFollowUp>[];
}
