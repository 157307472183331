<div
  fxFlex
  fxLayout="row"
  fxLayoutAlign="center center"
  *ngIf="store.isLoading$ | async; else showPage"
>
  <mat-spinner mode="indeterminate" />
</div>

<ng-template #showPage>
  <pr-patient-visits-report-table
    [records]="store.records$ | async"
    [dateRange]="store.dateRange$ | async"
   />
</ng-template>
