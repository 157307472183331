import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
} from '@angular/core';
import { saveDoc, type WithRef } from '@principle-theorem/shared';
import { angularEditorExtensions } from '@principle-theorem/ng-editor';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type INotification } from '@principle-theorem/notifications';
import { from, ReplaySubject } from 'rxjs';
import { take, zip } from 'rxjs/operators';
import { MENTION_BUTTON_PROVIDER } from '@principle-theorem/ng-interactions';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { AnyExtension } from '@tiptap/core';

@Component({
  selector: 'pr-notifications-sidebar',
  templateUrl: './notifications-sidebar.component.html',
  styleUrls: ['./notifications-sidebar.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsSidebarComponent {
  trackByNotification = TrackByFunctions.ref<WithRef<INotification>>();
  notifications$ = new ReplaySubject<WithRef<INotification>[]>(1);
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  notificationExtensions: AnyExtension[];

  @Input()
  set notifications(notifications: WithRef<INotification>[]) {
    if (notifications) {
      this.notifications$.next(notifications);
    }
  }

  constructor(
    private _injector: Injector,
    public organisation: OrganisationService
  ) {
    this.notificationExtensions = [
      angularEditorExtensions.mention(this._injector, {
        providers: [MENTION_BUTTON_PROVIDER],
      }),
    ];
  }

  async markAllRead(notifications: WithRef<INotification>[]): Promise<void> {
    await from(notifications)
      .pipe(
        zip((notification: WithRef<INotification>) =>
          this.markRead(notification)
        ),
        take(1)
      )
      .toPromise();
  }

  async markRead(notification: WithRef<INotification>): Promise<void> {
    notification.viewed = true;
    await saveDoc(notification);
  }
}
