import { TaxStrategy } from '@principle-theorem/accounting';
import { Region } from '@principle-theorem/shared';
import { ChartableSurface } from '../../core/chartable-surface';
import {
  ServiceCodeType,
  type IServiceCode,
  type IServiceProvider,
  type ItemCodeAliases,
} from '../service-item';
import { ADA_CODE_MAP } from './ada';
import { ServiceCodeScope } from './ada-code';
import { PROSTHETIC_DENTAL_DVA_CODES } from './dva-prosthetics';

export enum DVAVariance {
  Initial = 'Initial',
  Subsequent = 'Subsequent',
}

export const GENERAL_DENTAL_DVA_CODES: ItemCodeAliases = Object.entries({
  D011: {
    ...ADA_CODE_MAP['011'],
    code: 'D011',
    rootCode: '011',
    notes:
      'Limit of one (1) per provider every two years after previous 011 or 012. Limit applies to the same provider.',
    fee: 61.9,
  },
  D012: {
    ...ADA_CODE_MAP['012'],
    code: 'D012',
    rootCode: '012',
    notes:
      'Limit of one (1) per provider every 6 months. Limit applies to the same provider.',
    fee: 51.4,
  },
  D013: {
    ...ADA_CODE_MAP['013'],
    code: 'D013',
    rootCode: '013',
    notes: 'Limit of three (3) per three month period.',
    fee: 32.35,
  },
  D016: {
    ...ADA_CODE_MAP['016'],
    code: 'D016',
    rootCode: '016',
    notes:
      'Payable only when specifically requested by DVA. Includes report to DVA. Subject to GST.',
    fee: 120.8,
  },
  D018: {
    ...ADA_CODE_MAP['018'],
    code: 'D018',
    rootCode: '018',
    notes: `Claimable only when specifically requested by DVA. Report must be kept on patient's file. Subject to GST.`,
    fee: 55.4,
  },
  D019: {
    ...ADA_CODE_MAP['019'],
    code: 'D019',
    rootCode: '019',
    notes:
      'Limit of one (1) per provider per 12 month period. A copy of this referral must be retained by provider.',
    fee: 13.1,
  },
  'D022 Initial': {
    ...ADA_CODE_MAP['022'],
    code: 'D022 Initial',
    claimCode: 'D022',
    rootCode: '022',
    notes:
      'Limit of six (6) per day – one initial and five subsequent exposures.',
    fee: 43.6,
  },
  'D022 Subsequent': {
    ...ADA_CODE_MAP['022'],
    code: 'D022 Subsequent',
    claimCode: 'D022',
    rootCode: '022',
    notes:
      'Limit of six (6) per day – one initial and five subsequent exposures.',
    fee: 35.85,
  },
  D025: {
    ...ADA_CODE_MAP['025'],
    code: 'D025',
    rootCode: '025',
    fee: 72.35,
    deleted: false,
  },
  D031: {
    ...ADA_CODE_MAP['031'],
    code: 'D031',
    rootCode: '031',
    fee: 82.45,
  },
  D037: {
    ...ADA_CODE_MAP['037'],
    code: 'D037',
    rootCode: '037',
    fee: 110.8,
  },
  D039: {
    ...ADA_CODE_MAP['039'],
    code: 'D039',
    rootCode: '039',
    notes: 'Limit of one (1) per 12 month period.',
    fee: 174.85,
  },
  D047: {
    ...ADA_CODE_MAP['047'],
    code: 'D047',
    rootCode: '047',
    notes: 'Limit of one (1) per 12 month period.',
    fee: 47.7,
  },
  D051: {
    ...ADA_CODE_MAP['051'],
    code: 'D051',
    rootCode: '051',
    fee: 145.75,
  },
  D061: {
    ...ADA_CODE_MAP['061'],
    code: 'D061',
    rootCode: '061',
    notes: 'No fee payable - part of examination.',
    fee: 0,
  },
  D071: {
    ...ADA_CODE_MAP['071'],
    code: 'D071',
    rootCode: '071',
    notes:
      'Limit of two (2) models per appointment (that is, one upper and one lower). The preparation of a model, from an impression.  The model is used for examination and treatment planning procedures. This item should not be used to describe a working model.',
    fee: 71.1,
  },
  D072: {
    ...ADA_CODE_MAP['072'],
    code: 'D072',
    rootCode: '072',
    notes:
      'Limit of one (1) per 12 month period. Fee to include all photographs taken, not per photograph.',
    fee: 38.25,
  },
  D073: {
    ...ADA_CODE_MAP['073'],
    code: 'D073',
    rootCode: '073',
    notes:
      'Limit of one (1) per 12 month period. Fee to include all photographs taken, not per photograph.',
    fee: 38.25,
  },
  D074: {
    ...ADA_CODE_MAP['074'],
    code: 'D074',
    rootCode: '074',
    notes: 'For use in complex prosthodontic cases only.',
    fee: 187.1,
  },
  D111: {
    ...ADA_CODE_MAP['111'],
    code: 'D111',
    rootCode: '111',
    notes: 'Limit of one (1) per six month period.',
    fee: 63.25,
  },
  D113: {
    ...ADA_CODE_MAP['113'],
    code: 'D113',
    rootCode: '113',
    fee: 24,
  },
  D114: {
    ...ADA_CODE_MAP['114'],
    code: 'D114',
    rootCode: '114',
    notes: 'Limit of one (1) per six month period.',
    fee: 105.5,
  },
  D115: {
    ...ADA_CODE_MAP['115'],
    code: 'D115',
    rootCode: '115',
    notes: 'Limit of two (2) per 12 month period.',
    fee: 68.65,
  },
  D117: {
    ...ADA_CODE_MAP['117'],
    code: 'D117',
    rootCode: '117',
    notes:
      'For non-vital discoloured tooth. Limit of two (2) teeth per 12 month period.',
    fee: 225.65,
  },
  D121: {
    ...ADA_CODE_MAP['121'],
    code: 'D121',
    rootCode: '121',
    notes: 'Limit of one (1) per six month period.',
    fee: 40.7,
  },
  D123: {
    ...ADA_CODE_MAP['123'],
    code: 'D123',
    rootCode: '123',
    fee: 31.85,
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
  },
  D131: {
    ...ADA_CODE_MAP['131'],
    code: 'D131',
    rootCode: '131',
    notes:
      'Where a full appointment of at least 15 minutes is used. Limit of one (1) per 12 month period.',
    fee: 42.8,
  },
  D141: {
    ...ADA_CODE_MAP['141'],
    code: 'D141',
    rootCode: '141',
    notes:
      'Where a full appointment of at least 15 minutes is used. Limit of one (1) per 12 month period.',
    fee: 58.2,
  },
  D151: {
    ...ADA_CODE_MAP['151'],
    code: 'D151',
    rootCode: '151',
    fee: 176.75,
  },
  D161: {
    ...ADA_CODE_MAP['161'],
    code: 'D161',
    rootCode: '161',
    fee: 54.2,
  },
  D165: {
    ...ADA_CODE_MAP['165'],
    code: 'D165',
    rootCode: '165',
    fee: 31.85,
  },
  D171: {
    ...ADA_CODE_MAP['171'],
    code: 'D171',
    rootCode: '171',
    fee: 59.75,
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
  },
  D213: {
    ...ADA_CODE_MAP['213'],
    code: 'D213',
    rootCode: '213',
    notes: 'Limit of two (2) appointments per 12 month period.',
    fee: 82,
  },
  D221: {
    ...ADA_CODE_MAP['221'],
    code: 'D221',
    rootCode: '221',
    notes: 'Limit of one (1) per 12 month period.',
    fee: 62.3,
  },
  D222: {
    ...ADA_CODE_MAP['222'],
    code: 'D222',
    rootCode: '222',
    notes: 'Limit of 10 per appointment, maximum 20 per 12 month period.',
    fee: 30.65,
  },
  D223: {
    ...ADA_CODE_MAP['223'],
    code: 'D223',
    rootCode: '223',
    notes: 'Limit of five (5) per appointment, maximum 10 per 12 month period.',
    fee: 30.65,
  },
  D231: {
    ...ADA_CODE_MAP['231'],
    code: 'D231',
    rootCode: '231',
    notes: 'Limit of 10 per appointment, 20 per 12 month period.',
    fee: 0,
  },
  D232: {
    ...ADA_CODE_MAP['232'],
    code: 'D232',
    rootCode: '232',
    notes: 'Limit of 10 per appointment, 20 per 12 month period.',
    fee: 0,
  },
  D238: {
    ...ADA_CODE_MAP['238'],
    code: 'D238',
    rootCode: '238',
    fee: 444.55,
  },
  D241: {
    ...ADA_CODE_MAP['241'],
    code: 'D241',
    rootCode: '241',
    fee: 254.65,
  },
  D242: {
    ...ADA_CODE_MAP['242'],
    code: 'D242',
    rootCode: '242',
    fee: 0,
  },
  D243: {
    ...ADA_CODE_MAP['243'],
    code: 'D243',
    rootCode: '243',
    fee: 0,
  },
  D245: {
    ...ADA_CODE_MAP['245'],
    code: 'D245',
    rootCode: '245',
    notes: 'Limit one (1) per 12 month period.',
    fee: 93.35,
  },
  D250: {
    ...ADA_CODE_MAP['250'],
    code: 'D250',
    rootCode: '250',
    notes:
      'Limit of four (4) per 12 month period. Only claim as per quadrants of teeth treated.',
    fee: 173.5,
  },
  D251: {
    ...ADA_CODE_MAP['251'],
    code: 'D251',
    rootCode: '251',
    notes: 'Limit of three (3) per 12 month period.',
    fee: 186.45,
  },
  'D311 Initial': {
    ...ADA_CODE_MAP['311'],
    code: 'D311 Initial',
    claimCode: 'D311',
    rootCode: '311',
    fee: 154.4,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'D311 Subsequent': {
    ...ADA_CODE_MAP['311'],
    code: 'D311 Subsequent',
    claimCode: 'D311',
    rootCode: '311',
    fee: 97.3,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'D314 Initial': {
    ...ADA_CODE_MAP['314'],
    code: 'D314 Initial',
    claimCode: 'D314',
    rootCode: '314',
    fee: 197.35,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'D314 Subsequent': {
    ...ADA_CODE_MAP['314'],
    code: 'D314 Subsequent',
    claimCode: 'D314',
    rootCode: '314',
    fee: 130.4,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'D322 Initial': {
    ...ADA_CODE_MAP['322'],
    code: 'D322 Initial',
    claimCode: 'D322',
    rootCode: '322',
    fee: 250.6,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'D322 Subsequent': {
    ...ADA_CODE_MAP['322'],
    code: 'D322 Subsequent',
    claimCode: 'D322',
    rootCode: '322',
    fee: 166.75,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'D323 Initial': {
    ...ADA_CODE_MAP['323'],
    code: 'D323 Initial',
    claimCode: 'D323',
    rootCode: '323',
    fee: 286.25,
    deleted: false,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'D323 Subsequent': {
    ...ADA_CODE_MAP['323'],
    code: 'D323 Subsequent',
    claimCode: 'D323',
    rootCode: '323',
    fee: 205.05,
    deleted: false,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'D324 Initial': {
    ...ADA_CODE_MAP['324'],
    code: 'D324 Initial',
    claimCode: 'D324',
    rootCode: '324',
    fee: 385.05,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'D324 Subsequent': {
    ...ADA_CODE_MAP['324'],
    code: 'D324 Subsequent',
    claimCode: 'D324',
    rootCode: '324',
    fee: 253.8,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  D331: {
    ...ADA_CODE_MAP['331'],
    code: 'D331',
    rootCode: '331',
    fee: 156.25,
  },
  D337: {
    ...ADA_CODE_MAP['337'],
    code: 'D337',
    rootCode: '337',
    fee: 219.7,
  },
  D338: {
    ...ADA_CODE_MAP['338'],
    code: 'D338',
    rootCode: '338',
    notes: 'Limit of one (1) per 12 month period.',
    fee: 124.4,
  },
  D341: {
    ...ADA_CODE_MAP['341'],
    code: 'D341',
    rootCode: '341',
    notes:
      'Limit of one (1) per 12 month period. Not for tooth-associated soft tissue treatment.',
    fee: 199.15,
  },
  D351: {
    ...ADA_CODE_MAP['351'],
    code: 'D351',
    rootCode: '351',
    fee: 188.2,
  },
  D377: {
    ...ADA_CODE_MAP['377'],
    code: 'D377',
    rootCode: '377',
    fee: 238.35,
  },
  D378: {
    ...ADA_CODE_MAP['378'],
    code: 'D378',
    rootCode: '378',
    fee: 134.9,
  },
  D381: {
    ...ADA_CODE_MAP['381'],
    code: 'D381',
    rootCode: '381',
    fee: 0,
  },
  D384: {
    ...ADA_CODE_MAP['384'],
    code: 'D384',
    rootCode: '384',
    fee: 224.5,
  },
  D386: {
    ...ADA_CODE_MAP['386'],
    code: 'D386',
    rootCode: '386',
    fee: 231.65,
  },
  D387: {
    ...ADA_CODE_MAP['387'],
    code: 'D387',
    rootCode: '387',
    fee: 453.45,
  },
  D391: {
    ...ADA_CODE_MAP['391'],
    code: 'D391',
    rootCode: '391',
    fee: 208,
  },
  D392: {
    ...ADA_CODE_MAP['392'],
    code: 'D392',
    rootCode: '392',
    fee: 113.95,
  },
  D411: {
    ...ADA_CODE_MAP['411'],
    code: 'D411',
    rootCode: '411',
    fee: 41.05,
  },
  D412: {
    ...ADA_CODE_MAP['412'],
    code: 'D412',
    rootCode: '412',
    fee: 140.45,
  },
  D414: {
    ...ADA_CODE_MAP['414'],
    code: 'D414',
    rootCode: '414',
    fee: 89.5,
  },
  D415: {
    ...ADA_CODE_MAP['415'],
    code: 'D415',
    rootCode: '415',
    fee: 251.9,
  },
  D416: {
    ...ADA_CODE_MAP['416'],
    code: 'D416',
    rootCode: '416',
    fee: 120.05,
  },
  D417: {
    ...ADA_CODE_MAP['417'],
    code: 'D417',
    rootCode: '417',
    fee: 245.4,
  },
  D418: {
    ...ADA_CODE_MAP['418'],
    code: 'D418',
    rootCode: '418',
    fee: 114.85,
  },
  D419: {
    ...ADA_CODE_MAP['419'],
    code: 'D419',
    rootCode: '419',
    fee: 162.15,
  },
  D421: {
    ...ADA_CODE_MAP['421'],
    code: 'D421',
    rootCode: '421',
    fee: 140.45,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  D431: {
    ...ADA_CODE_MAP['431'],
    code: 'D431',
    rootCode: '431',
    notes: 'Item cannot be claimed with 432 and 434.',
    fee: 355.75,
  },
  D432: {
    ...ADA_CODE_MAP['432'],
    code: 'D432',
    rootCode: '432',
    fee: 355.75,
  },
  D433: {
    ...ADA_CODE_MAP['433'],
    code: 'D433',
    rootCode: '433',
    notes:
      'Limit of one (1) per 12 month period. Not claimable with items 431, 432, 434, 436, 437 and 438.',
    fee: 149.65,
  },
  D434: {
    ...ADA_CODE_MAP['434'],
    code: 'D434',
    rootCode: '434',
    fee: 426.8,
  },
  D436: {
    ...ADA_CODE_MAP['436'],
    code: 'D436',
    rootCode: '436',
    notes: 'Limit of one (1) per 12 month period.',
    fee: 224,
  },
  D437: {
    ...ADA_CODE_MAP['437'],
    code: 'D437',
    rootCode: '437',
    notes: 'Limit of one (1) per 12 month period.',
    fee: 311.15,
  },
  D438: {
    ...ADA_CODE_MAP['438'],
    code: 'D438',
    rootCode: '438',
    fee: 286.25,
  },
  D445: {
    ...ADA_CODE_MAP['445'],
    code: 'D445',
    rootCode: '445',
    fee: 124.3,
  },
  D451: {
    ...ADA_CODE_MAP['451'],
    code: 'D451',
    rootCode: '451',
    fee: 124.3,
  },
  D452: {
    ...ADA_CODE_MAP['452'],
    code: 'D452',
    rootCode: '452',
    fee: 124.3,
  },
  D453: {
    ...ADA_CODE_MAP['453'],
    code: 'D453',
    rootCode: '453',
    fee: 103.7,
  },
  D455: {
    ...ADA_CODE_MAP['455'],
    code: 'D455',
    rootCode: '455',
    notes:
      'Within three months of items 415 or 416. Appointment for irrigation only – cannot be paid with any other item.',
    fee: 124.3,
  },
  D457: {
    ...ADA_CODE_MAP['457'],
    code: 'D457',
    rootCode: '457',
    fee: 124.3,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  D458: {
    ...ADA_CODE_MAP['458'],
    code: 'D458',
    rootCode: '458',
    notes:
      'No other endodontic treatment on the same tooth within three months. Limit of three (3) in a 12 month period.',
    fee: 165.8,
    deleted: false,
  },
  D511: {
    ...ADA_CODE_MAP['511'],
    code: 'D511',
    rootCode: '511',
    fee: 122.6,
  },
  D512: {
    ...ADA_CODE_MAP['512'],
    code: 'D512',
    rootCode: '512',
    fee: 150.25,
  },
  D513: {
    ...ADA_CODE_MAP['513'],
    code: 'D513',
    rootCode: '513',
    fee: 179.4,
  },
  D514: {
    ...ADA_CODE_MAP['514'],
    code: 'D514',
    rootCode: '514',
    fee: 204.5,
  },
  D515: {
    ...ADA_CODE_MAP['515'],
    code: 'D515',
    rootCode: '515',
    fee: 233.4,
  },
  D521: {
    ...ADA_CODE_MAP['521'],
    code: 'D521',
    rootCode: '521',
    fee: 135.8,
  },
  D522: {
    ...ADA_CODE_MAP['522'],
    code: 'D522',
    rootCode: '522',
    fee: 164.85,
  },
  D523: {
    ...ADA_CODE_MAP['523'],
    code: 'D523',
    rootCode: '523',
    fee: 195.25,
  },
  D524: {
    ...ADA_CODE_MAP['524'],
    code: 'D524',
    rootCode: '524',
    fee: 225.65,
  },
  D525: {
    ...ADA_CODE_MAP['525'],
    code: 'D525',
    rootCode: '525',
    fee: 265.15,
  },
  D526: {
    ...ADA_CODE_MAP['526'],
    code: 'D526',
    rootCode: '526',
    fee: 265.15,
  },
  D531: {
    ...ADA_CODE_MAP['531'],
    code: 'D531',
    rootCode: '531',
    fee: 145.1,
  },
  D532: {
    ...ADA_CODE_MAP['532'],
    code: 'D532',
    rootCode: '532',
    fee: 182.15,
  },
  D533: {
    ...ADA_CODE_MAP['533'],
    code: 'D533',
    rootCode: '533',
    fee: 218.95,
  },
  D534: {
    ...ADA_CODE_MAP['534'],
    code: 'D534',
    rootCode: '534',
    fee: 246.6,
  },
  D535: {
    ...ADA_CODE_MAP['535'],
    code: 'D535',
    rootCode: '535',
    fee: 284.8,
  },
  D536: {
    ...ADA_CODE_MAP['536'],
    code: 'D536',
    rootCode: '536',
    fee: 265.15,
  },
  D541: {
    ...ADA_CODE_MAP['541'],
    code: 'D541',
    rootCode: '541',
    fee: 640.05,
  },
  D542: {
    ...ADA_CODE_MAP['542'],
    code: 'D542',
    rootCode: '542',
    fee: 817.95,
  },
  D543: {
    ...ADA_CODE_MAP['543'],
    code: 'D543',
    rootCode: '543',
    fee: 1066.95,
  },
  D544: {
    ...ADA_CODE_MAP['544'],
    code: 'D544',
    rootCode: '544',
    fee: 1191.45,
  },
  D545: {
    ...ADA_CODE_MAP['545'],
    code: 'D545',
    rootCode: '545',
    fee: 1333.6,
  },
  D551: {
    ...ADA_CODE_MAP['551'],
    code: 'D551',
    rootCode: '551',
    fee: 800.25,
  },
  D552: {
    ...ADA_CODE_MAP['552'],
    code: 'D552',
    rootCode: '552',
    fee: 924.6,
  },
  D553: {
    ...ADA_CODE_MAP['553'],
    code: 'D553',
    rootCode: '553',
    fee: 1137.95,
  },
  D554: {
    ...ADA_CODE_MAP['554'],
    code: 'D554',
    rootCode: '554',
    fee: 1369.25,
  },
  D555: {
    ...ADA_CODE_MAP['555'],
    code: 'D555',
    rootCode: '555',
    fee: 1467.9,
  },
  D556: {
    ...ADA_CODE_MAP['556'],
    code: 'D556',
    rootCode: '556',
    fee: 978.5,
  },
  D572: {
    ...ADA_CODE_MAP['572'],
    code: 'D572',
    rootCode: '572',
    notes:
      'Not claimable with endodontic items except 419. Limit of three (3) per three month period.',
    fee: 57.35,
  },
  D574: {
    ...ADA_CODE_MAP['574'],
    code: 'D574',
    rootCode: '574',
    fee: 48.35,
  },
  D575: {
    ...ADA_CODE_MAP['575'],
    code: 'D575',
    rootCode: '575',
    notes: 'Limit of three (3) per tooth. Limit of six (6) pins payable.',
    fee: 33.1,
    rules: {
      scope: ServiceCodeScope.PerTooth,
      scopeValue: 3,
    },
  },
  D577: {
    ...ADA_CODE_MAP['577'],
    code: 'D577',
    rootCode: '577',
    notes: 'Limit of two (2) cusps per tooth.',
    fee: 35.7,
    rules: {
      scope: ServiceCodeScope.PerTooth,
      scopeValue: 2,
    },
  },
  D578: {
    ...ADA_CODE_MAP['578'],
    code: 'D578',
    rootCode: '578',
    notes: 'Limit of two (2) per tooth.',
    fee: 35.7,
    rules: {
      scope: ServiceCodeScope.PerTooth,
      scopeValue: 2,
    },
  },
  D579: {
    ...ADA_CODE_MAP['579'],
    code: 'D579',
    rootCode: '579',
    fee: 113.95,
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
  },
  D586: {
    ...ADA_CODE_MAP['586'],
    code: 'D586',
    rootCode: '586',
    notes:
      'No other crown item number to be claimed on the same tooth within six (6) months.',
    fee: 302.3,
  },
  D587: {
    ...ADA_CODE_MAP['587'],
    code: 'D587',
    rootCode: '587',
    notes:
      'No other crown item number to be claimed on the same tooth within six (6) months.',
    fee: 179.4,
  },
  D588: {
    ...ADA_CODE_MAP['588'],
    code: 'D588',
    rootCode: '588',
    notes:
      'No other crown item number to be claimed on the same tooth within six (6) months.',
    fee: 302.3,
  },
  D595: {
    ...ADA_CODE_MAP['595'],
    code: 'D595',
    rootCode: '595',
    fee: 113.95,
  },
  D596: {
    ...ADA_CODE_MAP['596'],
    code: 'D596',
    rootCode: '596',
    fee: 93.1,
  },
  'D597 Initial': {
    ...ADA_CODE_MAP['597'],
    code: 'D597 Initial',
    claimCode: 'D597',
    rootCode: '597',
    notes: 'Limit of two (2) posts per tooth.',
    fee: 176.2,
  },
  'D597 Subsequent': {
    ...ADA_CODE_MAP['597'],
    code: 'D597 Subsequent',
    claimCode: 'D597',
    rootCode: '597',
    notes: 'Limit of two (2) posts per tooth.',
    fee: 103.7,
  },
  D611: {
    ...ADA_CODE_MAP['611'],
    code: 'D611',
    rootCode: '611',
    fee: 1086.25,
  },
  D613: {
    ...ADA_CODE_MAP['613'],
    code: 'D613',
    rootCode: '613',
    fee: 1579.75,
  },
  D615: {
    ...ADA_CODE_MAP['615'],
    code: 'D615',
    rootCode: '615',
    fee: 1486.15,
  },
  D618: {
    ...ADA_CODE_MAP['618'],
    code: 'D618',
    rootCode: '618',
    fee: 1392.55,
  },
  D625: {
    ...ADA_CODE_MAP['625'],
    code: 'D625',
    rootCode: '625',
    fee: 376.05,
  },
  D627: {
    ...ADA_CODE_MAP['627'],
    code: 'D627',
    rootCode: '627',
    fee: 155.4,
  },
  D629: {
    ...ADA_CODE_MAP['629'],
    code: 'D629',
    rootCode: '629',
    fee: 393.8,
  },
  D631: {
    ...ADA_CODE_MAP['631'],
    code: 'D631',
    rootCode: '631',
    notes:
      'No other crown item number to be claimed on same tooth within six (6) months.',
    fee: 179.3,
  },
  D632: {
    ...ADA_CODE_MAP['632'],
    code: 'D632',
    rootCode: '632',
    notes:
      'No other crown item number to be claimed on same tooth within six (6) months.',
    fee: 355.75,
  },
  D633: {
    ...ADA_CODE_MAP['633'],
    code: 'D633',
    rootCode: '633',
    notes:
      'No other crown item number to be claimed on same tooth within six (6) months.',
    fee: 179.3,
  },
  D642: {
    ...ADA_CODE_MAP['642'],
    code: 'D642',
    rootCode: '642',
    fee: 1137.95,
  },
  D643: {
    ...ADA_CODE_MAP['643'],
    code: 'D643',
    rootCode: '643',
    fee: 1213.35,
  },
  D644: {
    ...ADA_CODE_MAP['644'],
    code: 'D644',
    rootCode: '644',
    fee: 273.85,
  },
  D645: {
    ...ADA_CODE_MAP['645'],
    code: 'D645',
    rootCode: '645',
    fee: 348.45,
  },
  D649: {
    ...ADA_CODE_MAP['649'],
    code: 'D649',
    rootCode: '649',
    fee: 462.4,
  },
  D651: {
    ...ADA_CODE_MAP['651'],
    code: 'D651',
    rootCode: '651',
    fee: 121.3,
  },
  D652: {
    ...ADA_CODE_MAP['652'],
    code: 'D652',
    rootCode: '652',
    fee: 118.5,
  },
  D653: {
    ...ADA_CODE_MAP['653'],
    code: 'D653',
    rootCode: '653',
    fee: 107.75,
  },
  D655: {
    ...ADA_CODE_MAP['655'],
    code: 'D655',
    rootCode: '655',
    fee: 72.45,
  },
  D656: {
    ...ADA_CODE_MAP['656'],
    code: 'D656',
    rootCode: '656',
    fee: 217.55,
  },
  D658: {
    ...ADA_CODE_MAP['658'],
    code: 'D658',
    rootCode: '658',
    notes:
      '658 to be claimed for GST-free component of service. 472 (labour, lab costs) to be claimed for GST-able component of service.',
    fee: 273.85,
    rules: {
      inConjunctionWith: {
        required: [472],
      },
    },
  },
  D472: {
    ...ADA_CODE_MAP['658'],
    code: 'D472',
    rootCode: '472',
    notes:
      '658 to be claimed for GST-free component of service. 472 (labour, lab costs) to be claimed for GST-able component of service.',
    fee: 219.1,
    taxStatus: TaxStrategy.GSTApplicable,
    rules: {
      inConjunctionWith: {
        required: [658],
      },
    },
  },
  D659: {
    ...ADA_CODE_MAP['659'],
    code: 'D659',
    rootCode: '659',
    fee: 348.45,
  },
  D661: {
    ...ADA_CODE_MAP['661'],
    code: 'D661',
    rootCode: '661',
    fee: 0,
  },
  D668: {
    ...ADA_CODE_MAP['668'],
    code: 'D668',
    rootCode: '668',
    fee: 0,
  },
  D669: {
    ...ADA_CODE_MAP['669'],
    code: 'D669',
    rootCode: '669',
    fee: 0,
  },
  D671: {
    ...ADA_CODE_MAP['671'],
    code: 'D671',
    rootCode: '671',
    fee: 1579.75,
  },
  D672: {
    ...ADA_CODE_MAP['672'],
    code: 'D672',
    rootCode: '672',
    fee: 1789.6,
  },
  D673: {
    ...ADA_CODE_MAP['673'],
    code: 'D673',
    rootCode: '673',
    fee: 1394.55,
  },
  D711: {
    ...ADA_CODE_MAP['711'],
    code: 'D711',
    rootCode: '711',
    fee: 1122.15,
  },
  D712: {
    ...ADA_CODE_MAP['712'],
    code: 'D712',
    rootCode: '712',
    fee: 1122.15,
  },
  D713: {
    ...ADA_CODE_MAP['713'],
    code: 'D713',
    rootCode: '713',
    notes:
      'This item allows for provisional denture to be relined or replaced within 12 months.',
    fee: 841.6,
  },
  D714: {
    ...ADA_CODE_MAP['714'],
    code: 'D714',
    rootCode: '714',
    notes:
      'This item allows for provisional denture to be relined or replaced within 12 months.',
    fee: 841.6,
  },
  D715: {
    ...ADA_CODE_MAP['715'],
    code: 'D715',
    rootCode: '715',
    notes:
      'This item allows for provisional denture to be relined or replaced within 12 months.',
    fee: 1492.4,
  },
  D716: {
    ...ADA_CODE_MAP['716'],
    code: 'D716',
    rootCode: '716',
    notes:
      'Additional to item 711, 712 or 719. Laboratory casting invoice required. Maximum amount payable $444.00.',
    fee: 0,
  },
  D719: {
    ...ADA_CODE_MAP['719'],
    code: 'D719',
    rootCode: '719',
    fee: 1989.85,
  },
  D721: {
    ...ADA_CODE_MAP['721'],
    code: 'D721',
    rootCode: '721',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733.',
    fee: 513.45,
  },
  D722: {
    ...ADA_CODE_MAP['722'],
    code: 'D722',
    rootCode: '722',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733.',
    fee: 513.45,
  },
  D723: {
    ...ADA_CODE_MAP['723'],
    code: 'D723',
    rootCode: '723',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733. This item allows for provisional denture to be relined or replaced within 12 months.',
    fee: 385.15,
  },
  D724: {
    ...ADA_CODE_MAP['724'],
    code: 'D724',
    rootCode: '724',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733. This item allows for provisional denture to be relined or replaced within 12 months.',
    fee: 385.15,
  },
  D727: {
    ...ADA_CODE_MAP['727'],
    code: 'D727',
    rootCode: '727',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733.',
    fee: 1503.2,
  },
  D728: {
    ...ADA_CODE_MAP['728'],
    code: 'D728',
    rootCode: '728',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733.',
    fee: 1503.2,
  },
  D731: {
    ...ADA_CODE_MAP['731'],
    code: 'D731',
    rootCode: '731',
    fee: 51.85,
  },
  D732: {
    ...ADA_CODE_MAP['732'],
    code: 'D732',
    rootCode: '732',
    fee: 25.25,
  },
  D733: {
    ...ADA_CODE_MAP['733'],
    code: 'D733',
    rootCode: '733',
    notes:
      'Maximum of 12 teeth per denture base (with partial denture items 721, 722, 723, 724, 727, 728).',
    fee: 42.5,
  },
  D734: {
    ...ADA_CODE_MAP['734'],
    code: 'D734',
    rootCode: '734',
    notes: 'Can only be claimed with items 727 or 728.',
    fee: 51.85,
  },
  D735: {
    ...ADA_CODE_MAP['735'],
    code: 'D735',
    rootCode: '735',
    notes: 'Limit of two (2) items per 12 month period.',
    fee: 311.15,
  },
  D736: {
    ...ADA_CODE_MAP['736'],
    code: 'D736',
    rootCode: '736',
    fee: 10.8,
  },
  D737: {
    ...ADA_CODE_MAP['737'],
    code: 'D737',
    rootCode: '737',
    notes:
      'DVA will pay for item 737 with a new denture or items 737 and 743 together for an existing complete denture; and items 737 and 744 for an existing partial denture.',
    fee: 222.5,
  },
  D738: {
    ...ADA_CODE_MAP['738'],
    code: 'D738',
    rootCode: '738',
    fee: 207.3,
  },
  D739: {
    ...ADA_CODE_MAP['739'],
    code: 'D739',
    rootCode: '739',
    notes:
      'Can only be claimed with items 716, 727 or 728. Only claimable where a denture tooth has its entire occlusal contact with teeth of opposing arch covered by metal.',
    fee: 10.8,
  },
  D741: {
    ...ADA_CODE_MAP['741'],
    code: 'D741',
    rootCode: '741',
    notes:
      'Adjustment(s) to full or partial dentures within twelve (12) months following provision or relining by the same provider.',
    fee: 61.4,
  },
  D743: {
    ...ADA_CODE_MAP['743'],
    code: 'D743',
    rootCode: '743',
    notes: 'For soft relines, use items 743 and 737.',
    fee: 391.65,
  },
  D744: {
    ...ADA_CODE_MAP['744'],
    code: 'D744',
    rootCode: '744',
    notes: 'For soft relines, use items 743 and 737.',
    fee: 333.9,
  },
  D745: {
    ...ADA_CODE_MAP['745'],
    code: 'D745',
    rootCode: '745',
    fee: 0,
  },
  D746: {
    ...ADA_CODE_MAP['746'],
    code: 'D746',
    rootCode: '746',
    fee: 0,
  },
  D751: {
    ...ADA_CODE_MAP['751'],
    code: 'D751',
    rootCode: '751',
    notes:
      'Limit of one (1) per denture every 2 years. Chair-side only. Either hard or soft material. Not to be used for temporary materials i.e. tissue conditioners.',
    fee: 213.45,
  },
  D752: {
    ...ADA_CODE_MAP['752'],
    code: 'D752',
    rootCode: '752',
    notes:
      'Limit of one (1) per denture every 2 years. Not to be used for temporary materials i.e. tissue conditioners.',
    fee: 177.85,
  },
  D753: {
    ...ADA_CODE_MAP['753'],
    code: 'D753',
    rootCode: '753',
    notes: 'Limit of one (1) per denture every 2 years.',
    fee: 49.8,
  },
  D761: {
    ...ADA_CODE_MAP['761'],
    code: 'D761',
    rootCode: '761',
    notes:
      '761 to be claimed for GST-free component of service. 482 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 44.8,
    rules: {
      inConjunctionWith: {
        required: [482],
      },
    },
  },
  D482: {
    ...ADA_CODE_MAP['761'],
    code: 'D482',
    rootCode: '482',
    notes:
      '761 to be claimed for GST-free component of service. 482 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 124.8,
    taxStatus: TaxStrategy.GSTApplicable,
    rules: {
      inConjunctionWith: {
        required: [761],
      },
    },
  },
  D762: {
    ...ADA_CODE_MAP['762'],
    code: 'D762',
    rootCode: '762',
    notes: 'Limit of one (1) per day per denture.',
    fee: 177.2,
  },
  D763: {
    ...ADA_CODE_MAP['763'],
    code: 'D763',
    rootCode: '763',
    notes:
      '763 to be claimed for GST-free component of service. 484 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 44.8,
    rules: {
      inConjunctionWith: {
        required: [484],
      },
    },
  },
  D484: {
    ...ADA_CODE_MAP['763'],
    code: 'D484',
    rootCode: '484',
    notes:
      '763 to be claimed for GST-free component of service. 484 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 124.8,
    taxStatus: TaxStrategy.GSTApplicable,
    rules: {
      inConjunctionWith: {
        required: [763],
      },
    },
  },
  D764: {
    ...ADA_CODE_MAP['764'],
    code: 'D764',
    rootCode: '764',
    notes:
      '764 to be claimed for GST-free component of service. 485 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 44.8,
    rules: {
      inConjunctionWith: {
        required: [485],
      },
    },
  },
  D485: {
    ...ADA_CODE_MAP['764'],
    code: 'D485',
    rootCode: '485',
    notes:
      '764 to be claimed for GST-free component of service. 485 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 124.8,
    taxStatus: TaxStrategy.GSTApplicable,
    rules: {
      inConjunctionWith: {
        required: [764],
      },
    },
  },
  D765: {
    ...ADA_CODE_MAP['765'],
    code: 'D765',
    rootCode: '765',
    notes: 'Limit of one (1) per day per denture.',
    fee: 177.2,
  },
  D767: {
    ...ADA_CODE_MAP['767'],
    code: 'D767',
    rootCode: '767',
    notes:
      '767 to be claimed for GST-free component of service. 488 (labour, laboratory costs) to be claimed for GST-able component of service.',
    fee: 22.1,
    rules: {
      inConjunctionWith: {
        required: [488],
      },
    },
  },
  D488: {
    ...ADA_CODE_MAP['767'],
    code: 'D488',
    rootCode: '488',
    notes:
      '767 to be claimed for GST-free component of service. 488 (labour, laboratory costs) to be claimed for GST-able component of service.',
    fee: 48,
    taxStatus: TaxStrategy.GSTApplicable,
    rules: {
      inConjunctionWith: {
        required: [767],
      },
    },
  },
  D768: {
    ...ADA_CODE_MAP['768'],
    code: 'D768',
    rootCode: '768',
    notes: 'Limit of one (1) per day per denture.',
    fee: 179.4,
  },
  D769: {
    ...ADA_CODE_MAP['769'],
    code: 'D769',
    rootCode: '769',
    notes:
      'Limit of one (1) per day per denture. Laboratory casting invoice required. Maximum amount payable $317.20.',
    fee: 0,
  },
  D770: {
    code: 'D770',
    title: 'Special case provision of denture',
    description:
      'For provision of dentures in difficult cases including all component associated with the prosthesis',
    notes:
      'Non ADA item number. To be used in exceptional cases only – contact DVA.',
    fee: 0,
    taxStatus: TaxStrategy.GSTPossible,
    rules: {},
    scope: [ChartableSurface.Unscoped],
    deleted: false,
  },
  D771: {
    ...ADA_CODE_MAP['771'],
    code: 'D771',
    rootCode: '771',
    notes:
      'Limit of one (1) per denture per appointment. Limit of five (5) per three month period. UPR or LWR must be specified.',
    fee: 81.5,
  },
  D772: {
    ...ADA_CODE_MAP['772'],
    code: 'D772',
    rootCode: '772',
    notes:
      'A laboratory fabricated resin splint that is used to stabilise mobile or displaced teeth.',
    fee: 408.95,
  },
  D773: {
    ...ADA_CODE_MAP['773'],
    code: 'D773',
    rootCode: '773',
    notes:
      'A metal splint that is used to stabilise mobile or displaced teeth.',
    fee: 408.95,
  },
  D774: {
    ...ADA_CODE_MAP['774'],
    code: 'D774',
    rootCode: '774',
    fee: 0,
  },
  D776: {
    ...ADA_CODE_MAP['776'],
    code: 'D776',
    rootCode: '776',
    fee: 54.2,
  },
  D777: {
    ...ADA_CODE_MAP['777'],
    code: 'D777',
    rootCode: '777',
    notes: 'Limit of one (1) per denture.',
    fee: 43.45,
  },
  D811: {
    ...ADA_CODE_MAP['811'],
    code: 'D811',
    rootCode: '811',
    fee: 0,
    rules: {
      scope: ServiceCodeScope.PerJaw,
    },
  },
  D821: {
    ...ADA_CODE_MAP['821'],
    code: 'D821',
    rootCode: '821',
    fee: 0,
    rules: {
      scope: ServiceCodeScope.PerJaw,
    },
  },
  D823: {
    ...ADA_CODE_MAP['823'],
    code: 'D823',
    rootCode: '823',
    fee: 0,
    rules: {
      scope: ServiceCodeScope.PerJaw,
    },
  },
  D829: {
    ...ADA_CODE_MAP['829'],
    code: 'D829',
    rootCode: '829',
    fee: 0,
    rules: {
      scope: ServiceCodeScope.PerJaw,
    },
  },
  D831: {
    ...ADA_CODE_MAP['831'],
    code: 'D831',
    rootCode: '831',
    fee: 0,
    rules: {
      scope: ServiceCodeScope.PerJaw,
    },
  },
  D881: {
    ...ADA_CODE_MAP['881'],
    code: 'D881',
    rootCode: '881',
    fee: 0,
  },
  D911: {
    ...ADA_CODE_MAP['911'],
    code: 'D911',
    rootCode: '911',
    notes:
      'Not to be claimed with an extraction, endodontic or restorative treatment on same tooth.',
    fee: 80.5,
  },
  D915: {
    ...ADA_CODE_MAP['915'],
    code: 'D915',
    rootCode: '915',
    notes:
      'Flat fee is claimable as an emergency loading for services provided after hours. Limit of 3 per 3 month period.',
    fee: 108.1,
  },
  D916: {
    ...ADA_CODE_MAP['916'],
    code: 'D916',
    rootCode: '916',
    notes:
      'One per client per day. One per location per day. For example, only pay once per day for travel to retirement home regardless of how many patients are seen. Note: a provider operating a mobile dental clinic is not entitled to this item. Can be claimed without a dental item if it is part of non-billable dental treatment such as adjustments or repairs to dentures. Reasons for the travel should be provided.',
    fee: 78.6,
  },
  D926: {
    ...ADA_CODE_MAP['926'],
    code: 'D926',
    rootCode: '926',
    notes:
      'Limit of one (1) per arch per 12 month period. Not to be claimed for bleaching.',
    fee: 186.45,
  },
  D927: {
    ...ADA_CODE_MAP['927'],
    code: 'D927',
    rootCode: '927',
    notes:
      'For non-prescribable (non-RPBS) items – Fluoride & Chlorhexidine. Limit of one (1) per three month period.',
    fee: 32.35,
  },
  D949: {
    ...ADA_CODE_MAP['949'],
    code: 'D949',
    rootCode: '949',
    notes:
      'Items D949 and S949 can be claimed to cover the additional costs a dental provider, who does not have regular theatre times at a hospital or day procedure center, may incur when leaving their usual place of practice to undertake a procedure which requires the administration of a general anaesthesia.',
    fee: 0,
  },
  D961: {
    ...ADA_CODE_MAP['961'],
    code: 'D961',
    rootCode: '961',
    notes: 'Not related to any other procedure.',
    fee: 0,
  },
  D963: {
    ...ADA_CODE_MAP['963'],
    code: 'D963',
    rootCode: '963',
    notes: 'Limit of one (1) per three year period.',
    fee: 103.7,
  },
  D964: {
    ...ADA_CODE_MAP['964'],
    code: 'D964',
    rootCode: '964',
    notes:
      'Limit of one (1) per three year period. Cannot be claimed with items 500-899 inclusive.',
    fee: 88.9,
  },
  D965: {
    ...ADA_CODE_MAP['965'],
    code: 'D965',
    rootCode: '965',
    fee: 626.5,
  },
  D966: {
    ...ADA_CODE_MAP['966'],
    code: 'D966',
    rootCode: '966',
    notes: 'Limit of four (4) per 12 months.',
    fee: 88.9,
  },
  D968: {
    ...ADA_CODE_MAP['968'],
    code: 'D968',
    rootCode: '968',
    notes:
      'Can only be claimed following D/S963 and/or D/S964. Limit of four (4) per year',
    fee: 124.4,
  },
  D971: {
    ...ADA_CODE_MAP['971'],
    code: 'D971',
    rootCode: '971',
    notes: 'Limit of four (4) per 12 month period.',
    fee: 88.9,
  },
  D972: {
    ...ADA_CODE_MAP['972'],
    code: 'D972',
    rootCode: '972',
    fee: 337.9,
  },
  D981: {
    ...ADA_CODE_MAP['981'],
    code: 'D981',
    rootCode: '981',
    fee: 113.95,
  },
  D982: {
    ...ADA_CODE_MAP['982'],
    code: 'D982',
    rootCode: '982',
    fee: 112,
  },
  D983: {
    ...ADA_CODE_MAP['983'],
    code: 'D983',
    rootCode: '983',
    notes:
      'Only on diagnosis of sleep apnoea and prescription from a respiratory or ENT physician and consideration of treatment with CPAP.',
    fee: 0,
  },
  D984: {
    ...ADA_CODE_MAP['984'],
    code: 'D984',
    rootCode: '984',
    notes:
      'Only on diagnosis of sleep apnoea and prescription from a respiratory or ENT physician and consideration of treatment with CPAP.',
    fee: 0,
  },
  D985: {
    ...ADA_CODE_MAP['985'],
    code: 'D985',
    rootCode: '985',
    fee: 337.9,
  },
  D986: {
    ...ADA_CODE_MAP['986'],
    code: 'D986',
    rootCode: '986',
    notes: 'Limit of two (2) per 12 month period.',
    fee: 82.95,
  },
  D990: {
    ...ADA_CODE_MAP['990'],
    code: 'D990',
    rootCode: '990',
    notes: 'Exceptional use item only – contact DVA',
    fee: 0,
  },
}).reduce(
  (codes, [key, code]) => ({
    ...codes,
    [key]: {
      ...code,
      type: ServiceCodeType.DVA,
    },
  }),
  {}
);

const SPECIALIST_DVA_CODES: ItemCodeAliases = Object.entries({
  S012: {
    ...ADA_CODE_MAP['012'],
    code: 'S012',
    rootCode: '012',
    notes:
      'Limit of one (1) per provider every 6 months. Limit applies to the same provider.',
    fee: 51.4,
  },
  S013: {
    ...ADA_CODE_MAP['013'],
    code: 'S013',
    rootCode: '013',
    notes: 'Limit of three (3) per three month period.',
    fee: 32.35,
  },
  S014: {
    ...ADA_CODE_MAP['014'],
    code: 'S014',
    rootCode: '014',
    notes: 'Not claimable by general dentists.',
    fee: 74.7,
  },
  S015: {
    ...ADA_CODE_MAP['015'],
    code: 'S015',
    rootCode: '015',
    notes: 'Limit of one (1) per provider per 12 month period.',
    fee: 122.2,
  },
  S016: {
    ...ADA_CODE_MAP['016'],
    code: 'S016',
    rootCode: '016',
    notes:
      'Payable only when specifically requested by DVA. Includes report to DVA. Subject to GST.',
    fee: 177.55,
  },
  S017: {
    ...ADA_CODE_MAP['017'],
    code: 'S017',
    rootCode: '017',
    notes:
      'May only be claimed by oral medicine and special needs dentistry specialists.',
    fee: 241.9,
  },
  S018: {
    ...ADA_CODE_MAP['018'],
    code: 'S018',
    rootCode: '018',
    notes: `Claimable only when specifically requested by DVA. Report must be kept on patient's file. Subject to GST.`,
    fee: 55.4,
  },
  S019: {
    ...ADA_CODE_MAP['019'],
    code: 'S019',
    rootCode: '019',
    notes:
      'Limit of one (1) per provider per 12 month period. A copy of this referral must be retained by provider.',
    fee: 13.1,
  },
  'S022 Initial': {
    ...ADA_CODE_MAP['022'],
    code: 'S022 Initial',
    claimCode: 'S022',
    rootCode: '022',
    notes:
      'Limit of six (6) per day – one initial and five subsequent exposures.',
    fee: 43.6,
  },
  'S022 Subsequent': {
    ...ADA_CODE_MAP['022'],
    code: 'S022 Subsequent',
    claimCode: 'S022',
    rootCode: '022',
    notes:
      'Limit of six (6) per day – one initial and five subsequent exposures.',
    fee: 35.85,
  },
  S025: {
    ...ADA_CODE_MAP['025'],
    code: 'S025',
    rootCode: '025',
    fee: 72.35,
  },
  S031: {
    ...ADA_CODE_MAP['031'],
    code: 'S031',
    rootCode: '031',
    fee: 82.45,
  },
  S033: {
    ...ADA_CODE_MAP['033'],
    code: 'S033',
    rootCode: '033',
    notes: 'Limit of one (1) per 12 month period.',
    fee: 154.75,
  },
  S035: {
    ...ADA_CODE_MAP['035'],
    code: 'S035',
    rootCode: '035',
    fee: 118.95,
  },
  S036: {
    ...ADA_CODE_MAP['036'],
    code: 'S036',
    rootCode: '036',
    notes: 'Limit of one (1) per 12 month period.',
    fee: 174.75,
  },
  S037: {
    ...ADA_CODE_MAP['037'],
    code: 'S037',
    rootCode: '037',
    fee: 110.8,
  },
  S038: {
    ...ADA_CODE_MAP['038'],
    code: 'S038',
    rootCode: '038',
    notes:
      'Age limit applies - 18 years or under. Limit of one (1) per 12 month period per provider.',
    fee: 103.7,
  },
  S039: {
    ...ADA_CODE_MAP['039'],
    code: 'S039',
    rootCode: '039',
    notes: 'Limit of one (1) per 12 month period.',
    fee: 174.85,
  },
  S047: {
    ...ADA_CODE_MAP['047'],
    code: 'S047',
    rootCode: '047',
    notes: 'Limit of one (1) per 12 month period.',
    fee: 47.7,
  },
  S051: {
    ...ADA_CODE_MAP['051'],
    code: 'S051',
    rootCode: '051',
    fee: 145.75,
  },
  S061: {
    ...ADA_CODE_MAP['061'],
    code: 'S061',
    rootCode: '061',
    notes: 'No fee payable - part of examination.',
    fee: 0,
  },
  S071: {
    ...ADA_CODE_MAP['071'],
    code: 'S071',
    rootCode: '071',
    notes:
      'Limit of two (2) models per appointment (that is, one upper and one lower). The preparation of a model, from an impression.  The model is used for examination and treatment planning procedures. This item should not be used to describe a working model.',
    fee: 71.05,
  },
  S072: {
    ...ADA_CODE_MAP['072'],
    code: 'S072',
    rootCode: '072',
    notes:
      'Limit of one (1) per 12 month period. Fee to include all photographs taken, not per photograph.',
    fee: 38.25,
  },
  S073: {
    ...ADA_CODE_MAP['073'],
    code: 'S073',
    rootCode: '073',
    notes:
      'Limit of one (1) per 12 month period. Fee to include all photographs taken, not per photograph.',
    fee: 38.25,
  },
  S074: {
    ...ADA_CODE_MAP['074'],
    code: 'S074',
    rootCode: '074',
    notes: 'For use in complex prosthodontic cases only.',
    fee: 280.6,
  },
  S081: {
    ...ADA_CODE_MAP['081'],
    code: 'S081',
    rootCode: '081',
    notes: 'May only be claimed with item 881.',
    fee: 76.35,
  },
  S082: {
    ...ADA_CODE_MAP['082'],
    code: 'S082',
    rootCode: '082',
    notes:
      'Age limit applies 18 years or under. Limit of one (1) per 12 month period per provider.',
    fee: 124.3,
  },
  S111: {
    ...ADA_CODE_MAP['111'],
    code: 'S111',
    rootCode: '111',
    fee: 63.25,
  },
  S113: {
    ...ADA_CODE_MAP['113'],
    code: 'S113',
    rootCode: '113',
    fee: 24,
  },
  S114: {
    ...ADA_CODE_MAP['114'],
    code: 'S114',
    rootCode: '114',
    notes: 'Limit of one (1) per six month period.',
    fee: 105.5,
  },
  S115: {
    ...ADA_CODE_MAP['115'],
    code: 'S115',
    rootCode: '115',
    notes: 'Limit of two (2) per 12 month period.',
    fee: 68.65,
  },
  S117: {
    ...ADA_CODE_MAP['117'],
    code: 'S117',
    rootCode: '117',
    notes:
      'For non-vital discoloured tooth. Limit of two (2) teeth per 12 month period.',
    fee: 225.65,
  },
  S121: {
    ...ADA_CODE_MAP['121'],
    code: 'S121',
    rootCode: '121',
    notes: 'Limit of one (1) per six month period.',
    fee: 40.7,
  },
  S123: {
    ...ADA_CODE_MAP['123'],
    code: 'S123',
    rootCode: '123',
    fee: 31.85,
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
  },
  S131: {
    ...ADA_CODE_MAP['131'],
    code: 'S131',
    rootCode: '131',
    notes:
      'Where a full appointment of at least 15 minutes is used. Limit of one (1) per 12 month period.',
    fee: 42.8,
  },
  S141: {
    ...ADA_CODE_MAP['141'],
    code: 'S141',
    rootCode: '141',
    notes:
      'Where a full appointment of at least 15 minutes is used. Limit of one (1) per 12 month period.',
    fee: 58.2,
  },
  S151: {
    ...ADA_CODE_MAP['151'],
    code: 'S151',
    rootCode: '151',
    fee: 176.75,
  },
  S161: {
    ...ADA_CODE_MAP['161'],
    code: 'S161',
    rootCode: '161',
    fee: 54.2,
  },
  S165: {
    ...ADA_CODE_MAP['165'],
    code: 'S165',
    rootCode: '165',
    fee: 31.85,
  },
  S171: {
    ...ADA_CODE_MAP['171'],
    code: 'S171',
    rootCode: '171',
    fee: 59.75,
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
  },
  S213: {
    ...ADA_CODE_MAP['213'],
    code: 'S213',
    rootCode: '213',
    notes: 'Limit of two (2) appointments per 12 month period.',
    fee: 82,
  },
  S221: {
    ...ADA_CODE_MAP['221'],
    code: 'S221',
    rootCode: '221',
    notes: 'Limit of one (1) per 12 month period.',
    fee: 165.8,
  },
  S222: {
    ...ADA_CODE_MAP['222'],
    code: 'S222',
    rootCode: '222',
    notes: 'Limit of 10 per appointment, maximum 20 per 12 month period.',
    fee: 42.3,
  },
  S223: {
    ...ADA_CODE_MAP['223'],
    code: 'S223',
    rootCode: '223',
    notes: 'Limit of five (5) per appointment, maximum 10 per 12 month period.',
    fee: 42.3,
  },
  S231: {
    ...ADA_CODE_MAP['231'],
    code: 'S231',
    rootCode: '231',
    notes: 'Limit of 10 per appointment, 20 per 12 month period.',
    fee: 0,
  },
  S232: {
    ...ADA_CODE_MAP['232'],
    code: 'S232',
    rootCode: '232',
    notes: 'Limit of 10 per appointment, 20 per 12 month period.',
    fee: 0,
  },
  S233: {
    ...ADA_CODE_MAP['233'],
    code: 'S233',
    rootCode: '233',
    fee: 463.05,
  },
  S234: {
    ...ADA_CODE_MAP['234'],
    code: 'S234',
    rootCode: '234',
    fee: 509.15,
  },
  S235: {
    ...ADA_CODE_MAP['235'],
    code: 'S235',
    rootCode: '235',
    notes: 'Limit of two (2) per 12 month period.',
    fee: 622.4,
  },
  S236: {
    ...ADA_CODE_MAP['236'],
    code: 'S236',
    rootCode: '236',
    fee: 622.4,
  },
  S237: {
    ...ADA_CODE_MAP['237'],
    code: 'S237',
    rootCode: '237',
    fee: 320.25,
  },
  S238: {
    ...ADA_CODE_MAP['238'],
    code: 'S238',
    rootCode: '238',
    fee: 657.95,
  },
  S241: {
    ...ADA_CODE_MAP['241'],
    code: 'S241',
    rootCode: '241',
    fee: 318.3,
  },
  S242: {
    ...ADA_CODE_MAP['242'],
    code: 'S242',
    rootCode: '242',
    fee: 0,
  },
  S243: {
    ...ADA_CODE_MAP['243'],
    code: 'S243',
    rootCode: '243',
    fee: 0,
  },
  S244: {
    ...ADA_CODE_MAP['244'],
    code: 'S244',
    rootCode: '244',
    notes: 'Limit one (1) per 12 month period.',
    fee: 0,
  },
  S245: {
    ...ADA_CODE_MAP['245'],
    code: 'S245',
    rootCode: '245',
    notes: 'Limit one (1) per 12 month period.',
    fee: 186.45,
  },
  S246: {
    ...ADA_CODE_MAP['246'],
    code: 'S246',
    rootCode: '246',
    notes:
      'Will only be approved where applicable as part of an entire treatment plan that includes implants.',
    fee: 926.6,
  },
  S247: {
    ...ADA_CODE_MAP['247'],
    code: 'S247',
    rootCode: '247',
    notes:
      'Will only be approved where applicable as part of an entire treatment plan that includes implants.',
    fee: 926.6,
  },
  S250: {
    ...ADA_CODE_MAP['250'],
    code: 'S250',
    rootCode: '250',
    notes:
      'Limit of four (4) per 12 month period. Only claim as per quadrants of teeth treated.',
    fee: 346.95,
  },
  S251: {
    ...ADA_CODE_MAP['251'],
    code: 'S251',
    rootCode: '251',
    notes: 'Limit of three (3) per 12 month period.',
    fee: 323.7,
  },
  'S311 Initial': {
    ...ADA_CODE_MAP['311'],
    code: 'S311 Initial',
    claimCode: 'S311',
    rootCode: '311',
    fee: 191.8,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'S311 Subsequent': {
    ...ADA_CODE_MAP['311'],
    code: 'S311 Subsequent',
    claimCode: 'S311',
    rootCode: '311',
    fee: 124.15,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'S314 Initial': {
    ...ADA_CODE_MAP['314'],
    code: 'S314 Initial',
    claimCode: 'S314',
    rootCode: '314',
    fee: 262.2,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'S314 Subsequent': {
    ...ADA_CODE_MAP['314'],
    code: 'S314 Subsequent',
    claimCode: 'S314',
    rootCode: '314',
    fee: 173.35,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'S322 Initial': {
    ...ADA_CODE_MAP['322'],
    code: 'S322 Initial',
    claimCode: 'S322',
    rootCode: '322',
    fee: 333.2,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'S322 Subsequent': {
    ...ADA_CODE_MAP['322'],
    code: 'S322 Subsequent',
    claimCode: 'S322',
    rootCode: '322',
    fee: 207.3,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'S323 Initial': {
    ...ADA_CODE_MAP['323'],
    code: 'S323 Initial',
    claimCode: 'S323',
    rootCode: '323',
    fee: 413.6,
    deleted: false,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'S323 Subsequent': {
    ...ADA_CODE_MAP['323'],
    code: 'S323 Subsequent',
    claimCode: 'S323',
    rootCode: '323',
    fee: 271.45,
    deleted: false,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'S324 Initial': {
    ...ADA_CODE_MAP['324'],
    code: 'S324 Initial',
    claimCode: 'S324',
    rootCode: '324',
    fee: 512.15,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  'S324 Subsequent': {
    ...ADA_CODE_MAP['324'],
    code: 'S324 Subsequent',
    claimCode: 'S324',
    rootCode: '324',
    fee: 337.9,
    rules: {
      scope: ServiceCodeScope.PerQuadrant,
    },
  },
  S331: {
    ...ADA_CODE_MAP['331'],
    code: 'S331',
    rootCode: '331',
    fee: 196.75,
  },
  S332: {
    ...ADA_CODE_MAP['332'],
    code: 'S332',
    rootCode: '332',
    fee: 522.7,
  },
  S337: {
    ...ADA_CODE_MAP['337'],
    code: 'S337',
    rootCode: '337',
    fee: 292.1,
  },
  S338: {
    ...ADA_CODE_MAP['338'],
    code: 'S338',
    rootCode: '338',
    notes: 'Limit of one (1) per 12 month period.',
    fee: 177.85,
  },
  S341: {
    ...ADA_CODE_MAP['341'],
    code: 'S341',
    rootCode: '341',
    notes:
      'Limit of one (1) per 12 month period. Not for tooth-associated soft tissue treatment.',
    fee: 426.8,
  },
  S343: {
    ...ADA_CODE_MAP['343'],
    code: 'S343',
    rootCode: '343',
    fee: 480.2,
  },
  S344: {
    ...ADA_CODE_MAP['344'],
    code: 'S344',
    rootCode: '344',
    fee: 509.15,
  },
  S345: {
    ...ADA_CODE_MAP['345'],
    code: 'S345',
    rootCode: '345',
    fee: 468,
  },
  S351: {
    ...ADA_CODE_MAP['351'],
    code: 'S351',
    rootCode: '351',
    fee: 250.3,
  },
  S352: {
    ...ADA_CODE_MAP['352'],
    code: 'S352',
    rootCode: '352',
    fee: 219.1,
  },
  S353: {
    ...ADA_CODE_MAP['353'],
    code: 'S353',
    rootCode: '353',
    fee: 690.3,
  },
  S354: {
    ...ADA_CODE_MAP['354'],
    code: 'S354',
    rootCode: '354',
    fee: 690.3,
  },
  S355: {
    ...ADA_CODE_MAP['355'],
    code: 'S355',
    rootCode: '355',
    fee: 917.8,
  },
  S359: {
    ...ADA_CODE_MAP['359'],
    code: 'S359',
    rootCode: '359',
    fee: 741.6,
  },
  S361: {
    ...ADA_CODE_MAP['361'],
    code: 'S361',
    rootCode: '361',
    fee: 69.85,
  },
  S363: {
    ...ADA_CODE_MAP['363'],
    code: 'S363',
    rootCode: '363',
    fee: 201.95,
  },
  S365: {
    ...ADA_CODE_MAP['365'],
    code: 'S365',
    rootCode: '365',
    fee: 1641.95,
  },
  S366: {
    ...ADA_CODE_MAP['366'],
    code: 'S366',
    rootCode: '366',
    fee: 1641.95,
  },
  S371: {
    ...ADA_CODE_MAP['371'],
    code: 'S371',
    rootCode: '371',
    fee: 241.7,
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
  },
  S373: {
    ...ADA_CODE_MAP['373'],
    code: 'S373',
    rootCode: '373',
    fee: 856.75,
  },
  S375: {
    ...ADA_CODE_MAP['375'],
    code: 'S375',
    rootCode: '375',
    fee: 754.35,
  },
  S376: {
    ...ADA_CODE_MAP['376'],
    code: 'S376',
    rootCode: '376',
    fee: 255.7,
  },
  S377: {
    ...ADA_CODE_MAP['377'],
    code: 'S377',
    rootCode: '377',
    fee: 317.3,
  },
  S378: {
    ...ADA_CODE_MAP['378'],
    code: 'S378',
    rootCode: '378',
    fee: 179.3,
  },
  S379: {
    ...ADA_CODE_MAP['379'],
    code: 'S379',
    rootCode: '379',
    fee: 462.4,
  },
  S381: {
    ...ADA_CODE_MAP['381'],
    code: 'S381',
    rootCode: '381',
    fee: 408.95,
  },
  S382: {
    ...ADA_CODE_MAP['382'],
    code: 'S382',
    rootCode: '382',
    fee: 463.85,
  },
  S384: {
    ...ADA_CODE_MAP['384'],
    code: 'S384',
    rootCode: '384',
    fee: 299.3,
  },
  S385: {
    ...ADA_CODE_MAP['385'],
    code: 'S385',
    rootCode: '385',
    fee: 463.85,
  },
  S386: {
    ...ADA_CODE_MAP['386'],
    code: 'S386',
    rootCode: '386',
    fee: 311.9,
  },
  S387: {
    ...ADA_CODE_MAP['387'],
    code: 'S387',
    rootCode: '387',
    fee: 603.1,
  },
  S388: {
    ...ADA_CODE_MAP['388'],
    code: 'S388',
    rootCode: '388',
    fee: 692.4,
  },
  S389: {
    ...ADA_CODE_MAP['389'],
    code: 'S389',
    rootCode: '389',
    fee: 221.2,
  },
  S391: {
    ...ADA_CODE_MAP['391'],
    code: 'S391',
    rootCode: '391',
    fee: 276.7,
  },
  S392: {
    ...ADA_CODE_MAP['392'],
    code: 'S392',
    rootCode: '392',
    fee: 145.15,
  },
  S393: {
    ...ADA_CODE_MAP['393'],
    code: 'S393',
    rootCode: '393',
    fee: 926.6,
  },
  S394: {
    ...ADA_CODE_MAP['394'],
    code: 'S394',
    rootCode: '394',
    fee: 605.05,
  },
  S395: {
    ...ADA_CODE_MAP['395'],
    code: 'S395',
    rootCode: '395',
    fee: 1214.55,
  },
  S411: {
    ...ADA_CODE_MAP['411'],
    code: 'S411',
    rootCode: '411',
    fee: 54.45,
  },
  S412: {
    ...ADA_CODE_MAP['412'],
    code: 'S412',
    rootCode: '412',
    fee: 224.5,
  },
  S414: {
    ...ADA_CODE_MAP['414'],
    code: 'S414',
    rootCode: '414',
    fee: 103.7,
  },
  S415: {
    ...ADA_CODE_MAP['415'],
    code: 'S415',
    rootCode: '415',
    fee: 466.3,
  },
  S416: {
    ...ADA_CODE_MAP['416'],
    code: 'S416',
    rootCode: '416',
    fee: 238.35,
  },
  S417: {
    ...ADA_CODE_MAP['417'],
    code: 'S417',
    rootCode: '417',
    fee: 466.3,
  },
  S418: {
    ...ADA_CODE_MAP['418'],
    code: 'S418',
    rootCode: '418',
    fee: 238.35,
  },
  S419: {
    ...ADA_CODE_MAP['419'],
    code: 'S419',
    rootCode: '419',
    fee: 194.75,
  },
  S421: {
    ...ADA_CODE_MAP['421'],
    code: 'S421',
    rootCode: '421',
    fee: 224.5,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  S431: {
    ...ADA_CODE_MAP['431'],
    code: 'S431',
    rootCode: '431',
    notes: 'Item cannot be claimed with 432 and 434.',
    fee: 480.2,
  },
  S432: {
    ...ADA_CODE_MAP['432'],
    code: 'S432',
    rootCode: '432',
    fee: 480.2,
  },
  S433: {
    ...ADA_CODE_MAP['433'],
    code: 'S433',
    rootCode: '433',
    notes:
      'Limit of one (1) per 12 month period. Not claimable with items 431, 432, 434, 436, 437 and 438.',
    fee: 187.1,
  },
  S434: {
    ...ADA_CODE_MAP['434'],
    code: 'S434',
    rootCode: '434',
    fee: 622.4,
  },
  S436: {
    ...ADA_CODE_MAP['436'],
    code: 'S436',
    rootCode: '436',
    notes: 'Limit of one (1) per 12 month period.',
    fee: 444.55,
  },
  S437: {
    ...ADA_CODE_MAP['437'],
    code: 'S437',
    rootCode: '437',
    notes: 'Limit of one (1) per 12 month period.',
    fee: 435.55,
  },
  S438: {
    ...ADA_CODE_MAP['438'],
    code: 'S438',
    rootCode: '438',
    fee: 413.6,
  },
  S445: {
    ...ADA_CODE_MAP['445'],
    code: 'S445',
    rootCode: '445',
    fee: 165.8,
  },
  S451: {
    ...ADA_CODE_MAP['451'],
    code: 'S451',
    rootCode: '451',
    fee: 165.8,
  },
  S452: {
    ...ADA_CODE_MAP['452'],
    code: 'S452',
    rootCode: '452',
    fee: 155.4,
  },
  S453: {
    ...ADA_CODE_MAP['453'],
    code: 'S453',
    rootCode: '453',
    fee: 145.15,
  },
  S455: {
    ...ADA_CODE_MAP['455'],
    code: 'S455',
    rootCode: '455',
    notes:
      'Within three months of items 415 or 416. Appointment for irrigation only – cannot be paid with any other item.',
    fee: 165.8,
  },
  S457: {
    ...ADA_CODE_MAP['457'],
    code: 'S457',
    rootCode: '457',
    fee: 165.8,
    rules: {
      scope: ServiceCodeScope.PerTooth,
    },
  },
  S458: {
    ...ADA_CODE_MAP['458'],
    code: 'S458',
    rootCode: '458',
    notes:
      'No other endodontic treatment on the same tooth within three months. Limit of three (3) in a 12 month period.',
    fee: 186.45,
    deleted: false,
  },
  S511: {
    ...ADA_CODE_MAP['511'],
    code: 'S511',
    rootCode: '511',
    fee: 122.6,
  },
  S512: {
    ...ADA_CODE_MAP['512'],
    code: 'S512',
    rootCode: '512',
    fee: 150.25,
  },
  S513: {
    ...ADA_CODE_MAP['513'],
    code: 'S513',
    rootCode: '513',
    fee: 179.4,
  },
  S514: {
    ...ADA_CODE_MAP['514'],
    code: 'S514',
    rootCode: '514',
    fee: 204.5,
  },
  S515: {
    ...ADA_CODE_MAP['515'],
    code: 'S515',
    rootCode: '515',
    fee: 233.4,
  },
  S521: {
    ...ADA_CODE_MAP['521'],
    code: 'S521',
    rootCode: '521',
    fee: 135.8,
  },
  S522: {
    ...ADA_CODE_MAP['522'],
    code: 'S522',
    rootCode: '522',
    fee: 164.85,
  },
  S523: {
    ...ADA_CODE_MAP['523'],
    code: 'S523',
    rootCode: '523',
    fee: 195.25,
  },
  S524: {
    ...ADA_CODE_MAP['524'],
    code: 'S524',
    rootCode: '524',
    fee: 225.65,
  },
  S525: {
    ...ADA_CODE_MAP['525'],
    code: 'S525',
    rootCode: '525',
    fee: 315.25,
  },
  S526: {
    ...ADA_CODE_MAP['526'],
    code: 'S526',
    rootCode: '526',
    fee: 315.25,
  },
  S531: {
    ...ADA_CODE_MAP['531'],
    code: 'S531',
    rootCode: '531',
    fee: 145.1,
  },
  S532: {
    ...ADA_CODE_MAP['532'],
    code: 'S532',
    rootCode: '532',
    fee: 182.15,
  },
  S533: {
    ...ADA_CODE_MAP['533'],
    code: 'S533',
    rootCode: '533',
    fee: 218.95,
  },
  S534: {
    ...ADA_CODE_MAP['534'],
    code: 'S534',
    rootCode: '534',
    fee: 246.6,
  },
  S535: {
    ...ADA_CODE_MAP['535'],
    code: 'S535',
    rootCode: '535',
    fee: 369.2,
  },
  S536: {
    ...ADA_CODE_MAP['536'],
    code: 'S536',
    rootCode: '536',
    fee: 315.25,
  },
  S541: {
    ...ADA_CODE_MAP['541'],
    code: 'S541',
    rootCode: '541',
    fee: 640.05,
  },
  S542: {
    ...ADA_CODE_MAP['542'],
    code: 'S542',
    rootCode: '542',
    fee: 817.95,
  },
  S543: {
    ...ADA_CODE_MAP['543'],
    code: 'S543',
    rootCode: '543',
    fee: 1066.95,
  },
  S544: {
    ...ADA_CODE_MAP['544'],
    code: 'S544',
    rootCode: '544',
    fee: 1191.45,
  },
  S545: {
    ...ADA_CODE_MAP['545'],
    code: 'S545',
    rootCode: '545',
    fee: 1760.25,
  },
  S551: {
    ...ADA_CODE_MAP['551'],
    code: 'S551',
    rootCode: '551',
    fee: 1066.95,
  },
  S552: {
    ...ADA_CODE_MAP['552'],
    code: 'S552',
    rootCode: '552',
    fee: 1209.2,
  },
  S553: {
    ...ADA_CODE_MAP['553'],
    code: 'S553',
    rootCode: '553',
    fee: 1529.2,
  },
  S554: {
    ...ADA_CODE_MAP['554'],
    code: 'S554',
    rootCode: '554',
    fee: 1653.5,
  },
  S555: {
    ...ADA_CODE_MAP['555'],
    code: 'S555',
    rootCode: '555',
    fee: 1760.25,
  },
  S556: {
    ...ADA_CODE_MAP['556'],
    code: 'S556',
    rootCode: '556',
    fee: 1066.95,
  },
  S572: {
    ...ADA_CODE_MAP['572'],
    code: 'S572',
    rootCode: '572',
    notes:
      'Not claimable with endodontic items except 419. Limit of three (3) per three month period.',
    fee: 57.35,
  },
  S574: {
    ...ADA_CODE_MAP['574'],
    code: 'S574',
    rootCode: '574',
    fee: 48.35,
  },
  S575: {
    ...ADA_CODE_MAP['575'],
    code: 'S575',
    rootCode: '575',
    notes: 'Limit of six (6) pins payable.',
    fee: 33.1,
    rules: {
      scope: ServiceCodeScope.PerTooth,
      scopeValue: 3,
    },
  },
  S577: {
    ...ADA_CODE_MAP['577'],
    code: 'S577',
    rootCode: '577',
    fee: 35.7,
    rules: {
      scope: ServiceCodeScope.PerTooth,
      scopeValue: 2,
    },
  },
  S578: {
    ...ADA_CODE_MAP['578'],
    code: 'S578',
    rootCode: '578',
    fee: 35.7,
    rules: {
      scope: ServiceCodeScope.PerTooth,
      scopeValue: 2,
    },
  },
  S579: {
    ...ADA_CODE_MAP['579'],
    code: 'S579',
    rootCode: '579',
    fee: 145.15,
    rules: {
      scope: ServiceCodeScope.PerAppointment,
    },
  },
  S586: {
    ...ADA_CODE_MAP['586'],
    code: 'S586',
    rootCode: '586',
    notes:
      'No other crown item number to be claimed on the same tooth within six (6) months.',
    fee: 408.95,
  },
  S587: {
    ...ADA_CODE_MAP['587'],
    code: 'S587',
    rootCode: '587',
    notes:
      'No other crown item number to be claimed on the same tooth within six (6) months.',
    fee: 179.4,
  },
  S588: {
    ...ADA_CODE_MAP['588'],
    code: 'S588',
    rootCode: '588',
    notes:
      'No other crown item number to be claimed on the same tooth within six (6) months.',
    fee: 408.95,
  },
  S595: {
    ...ADA_CODE_MAP['595'],
    code: 'S595',
    rootCode: '595',
    fee: 165.8,
  },
  S596: {
    ...ADA_CODE_MAP['596'],
    code: 'S596',
    rootCode: '596',
    fee: 93.1,
  },
  'S597 Initial': {
    ...ADA_CODE_MAP['597'],
    code: 'S597 Initial',
    claimCode: 'S597',
    rootCode: '597',
    notes: 'Limit of two (2) posts per tooth.',
    fee: 227.85,
  },
  'S597 Subsequent': {
    ...ADA_CODE_MAP['597'],
    code: 'S597 Subsequent',
    claimCode: 'S597',
    rootCode: '597',
    notes: 'Limit of two (2) posts per tooth.',
    fee: 124.3,
  },
  S611: {
    ...ADA_CODE_MAP['611'],
    code: 'S611',
    rootCode: '611',
    fee: 1444.8,
  },
  S613: {
    ...ADA_CODE_MAP['613'],
    code: 'S613',
    rootCode: '613',
    fee: 2101.15,
  },
  S615: {
    ...ADA_CODE_MAP['615'],
    code: 'S615',
    rootCode: '615',
    fee: 2318.55,
  },
  S618: {
    ...ADA_CODE_MAP['618'],
    code: 'S618',
    rootCode: '618',
    fee: 1854.7,
  },
  S625: {
    ...ADA_CODE_MAP['625'],
    code: 'S625',
    rootCode: '625',
    fee: 500.05,
  },
  S627: {
    ...ADA_CODE_MAP['627'],
    code: 'S627',
    rootCode: '627',
    fee: 207.3,
  },
  S629: {
    ...ADA_CODE_MAP['629'],
    code: 'S629',
    rootCode: '629',
    fee: 507.7,
  },
  S631: {
    ...ADA_CODE_MAP['631'],
    code: 'S631',
    rootCode: '631',
    notes:
      'No other crown item number to be claimed on same tooth within six (6) months.',
    fee: 179.3,
  },
  S632: {
    ...ADA_CODE_MAP['632'],
    code: 'S632',
    rootCode: '632',
    notes:
      'No other crown item number to be claimed on same tooth within six (6) months.',
    fee: 462.4,
  },
  S633: {
    ...ADA_CODE_MAP['633'],
    code: 'S633',
    rootCode: '633',
    notes:
      'No other crown item number to be claimed on same tooth within six (6) months.',
    fee: 179.3,
  },
  S642: {
    ...ADA_CODE_MAP['642'],
    code: 'S642',
    rootCode: '642',
    fee: 1529.2,
  },
  S643: {
    ...ADA_CODE_MAP['643'],
    code: 'S643',
    rootCode: '643',
    fee: 1529.2,
  },
  S644: {
    ...ADA_CODE_MAP['644'],
    code: 'S644',
    rootCode: '644',
    fee: 497.75,
  },
  S645: {
    ...ADA_CODE_MAP['645'],
    code: 'S645',
    rootCode: '645',
    fee: 448.1,
  },
  S649: {
    ...ADA_CODE_MAP['649'],
    code: 'S649',
    rootCode: '649',
    fee: 622.4,
  },
  S651: {
    ...ADA_CODE_MAP['651'],
    code: 'S651',
    rootCode: '651',
    fee: 138.05,
  },
  S652: {
    ...ADA_CODE_MAP['652'],
    code: 'S652',
    rootCode: '652',
    fee: 157.65,
  },
  S653: {
    ...ADA_CODE_MAP['653'],
    code: 'S653',
    rootCode: '653',
    fee: 147.2,
  },
  S655: {
    ...ADA_CODE_MAP['655'],
    code: 'S655',
    rootCode: '655',
    fee: 93.35,
  },
  S656: {
    ...ADA_CODE_MAP['656'],
    code: 'S656',
    rootCode: '656',
    fee: 217.55,
  },
  S658: {
    ...ADA_CODE_MAP['658'],
    code: 'S658',
    rootCode: '658',
    notes:
      '658 to be claimed for GST-free component of service. 472 (labour, lab costs) to be claimed for GST-able component of service.',
    fee: 273.85,
    rules: {
      inConjunctionWith: {
        required: [472],
      },
    },
  },
  S472: {
    ...ADA_CODE_MAP['658'],
    code: 'S472',
    rootCode: '472',
    notes:
      '658 to be claimed for GST-free component of service. 472 (labour, lab costs) to be claimed for GST-able component of service.',
    fee: 219.1,
    taxStatus: TaxStrategy.GSTApplicable,
    rules: {
      inConjunctionWith: {
        required: [658],
      },
    },
  },
  S659: {
    ...ADA_CODE_MAP['659'],
    code: 'S659',
    rootCode: '659',
    fee: 522.7,
  },
  S661: {
    ...ADA_CODE_MAP['661'],
    code: 'S661',
    rootCode: '661',
    fee: 0,
  },
  S663: {
    ...ADA_CODE_MAP['663'],
    code: 'S663',
    rootCode: '663',
    fee: 0,
  },
  S664: {
    ...ADA_CODE_MAP['664'],
    code: 'S664',
    rootCode: '664',
    fee: 0,
  },
  S666: {
    ...ADA_CODE_MAP['666'],
    code: 'S666',
    rootCode: '666',
    fee: 0,
  },
  S668: {
    ...ADA_CODE_MAP['668'],
    code: 'S668',
    rootCode: '668',
    fee: 0,
  },
  S669: {
    ...ADA_CODE_MAP['669'],
    code: 'S669',
    rootCode: '669',
    fee: 0,
  },
  S671: {
    ...ADA_CODE_MAP['671'],
    code: 'S671',
    rootCode: '671',
    fee: 2101.15,
  },
  S672: {
    ...ADA_CODE_MAP['672'],
    code: 'S672',
    rootCode: '672',
    fee: 2318.55,
  },
  S673: {
    ...ADA_CODE_MAP['673'],
    code: 'S673',
    rootCode: '673',
    fee: 1854.7,
  },
  S678: {
    ...ADA_CODE_MAP['678'],
    code: 'S678',
    rootCode: '678',
    notes: 'Limit one (1) per 12 months.',
    fee: 0,
  },
  S679: {
    ...ADA_CODE_MAP['679'],
    code: 'S679',
    rootCode: '679',
    fee: 0,
  },
  S684: {
    ...ADA_CODE_MAP['684'],
    code: 'S684',
    rootCode: '684',
    fee: 0,
  },
  S688: {
    ...ADA_CODE_MAP['688'],
    code: 'S688',
    rootCode: '688',
    fee: 0,
  },
  S690: {
    ...ADA_CODE_MAP['690'],
    code: 'S690',
    rootCode: '690',
    notes: 'Maximum two (2) per course of treatment. For use with 881 only.',
    fee: 0,
  },
  S691: {
    ...ADA_CODE_MAP['691'],
    code: 'S691',
    rootCode: '691',
    fee: 0,
  },
  S711: {
    ...ADA_CODE_MAP['711'],
    code: 'S711',
    rootCode: '711',
    fee: 1122.15,
  },
  S712: {
    ...ADA_CODE_MAP['712'],
    code: 'S712',
    rootCode: '712',
    fee: 1122.15,
  },
  S713: {
    ...ADA_CODE_MAP['713'],
    code: 'S713',
    rootCode: '713',
    notes:
      'This item allows for provisional denture to be relined or replaced within 12 months.',
    fee: 841.6,
  },
  S714: {
    ...ADA_CODE_MAP['714'],
    code: 'S714',
    rootCode: '714',
    notes:
      'This item allows for provisional denture to be relined or replaced within 12 months.',
    fee: 841.6,
  },
  S715: {
    ...ADA_CODE_MAP['715'],
    code: 'S715',
    rootCode: '715',
    notes:
      'This item allows for provisional denture to be relined or replaced within 12 months.',
    fee: 1492.4,
  },
  S716: {
    ...ADA_CODE_MAP['716'],
    code: 'S716',
    rootCode: '716',
    notes:
      'Additional to item 711, 712 or 719. Laboratory casting invoice required. Maximum amount payable $444.00.',
    fee: 0,
  },
  S719: {
    ...ADA_CODE_MAP['719'],
    code: 'S719',
    rootCode: '719',
    fee: 1989.85,
  },
  S721: {
    ...ADA_CODE_MAP['721'],
    code: 'S721',
    rootCode: '721',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733.',
    fee: 513.45,
  },
  S722: {
    ...ADA_CODE_MAP['722'],
    code: 'S722',
    rootCode: '722',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733.',
    fee: 513.45,
  },
  S723: {
    ...ADA_CODE_MAP['723'],
    code: 'S723',
    rootCode: '723',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733. This item allows for provisional denture to be relined or replaced within 12 months.',
    fee: 385.15,
  },
  S724: {
    ...ADA_CODE_MAP['724'],
    code: 'S724',
    rootCode: '724',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733. This item allows for provisional denture to be relined or replaced within 12 months.',
    fee: 385.15,
  },
  S727: {
    ...ADA_CODE_MAP['727'],
    code: 'S727',
    rootCode: '727',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733.',
    fee: 1503.2,
  },
  S728: {
    ...ADA_CODE_MAP['728'],
    code: 'S728',
    rootCode: '728',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733.',
    fee: 1503.2,
  },
  S731: {
    ...ADA_CODE_MAP['731'],
    code: 'S731',
    rootCode: '731',
    fee: 51.85,
  },
  S732: {
    ...ADA_CODE_MAP['732'],
    code: 'S732',
    rootCode: '732',
    fee: 25.25,
  },
  S733: {
    ...ADA_CODE_MAP['733'],
    code: 'S733',
    rootCode: '733',
    notes:
      'Maximum of 12 teeth per denture base (with partial denture items 721, 722, 723, 724, 727, 728).',
    fee: 42.5,
  },
  S734: {
    ...ADA_CODE_MAP['734'],
    code: 'S734',
    rootCode: '734',
    notes: 'Can only be claimed with items 727 or 728.',
    fee: 51.85,
  },
  S735: {
    ...ADA_CODE_MAP['735'],
    code: 'S735',
    rootCode: '735',
    notes: 'Limit of two (2) items per 12 month period.',
    fee: 311.15,
  },
  S736: {
    ...ADA_CODE_MAP['736'],
    code: 'S736',
    rootCode: '736',
    fee: 10.8,
  },
  S737: {
    ...ADA_CODE_MAP['737'],
    code: 'S737',
    rootCode: '737',
    notes:
      'DVA will pay for item 737 with a new denture or items 737 and 743 together for an existing complete denture; and items 737 and 744 for an existing partial denture.',
    fee: 222.5,
  },
  S738: {
    ...ADA_CODE_MAP['738'],
    code: 'S738',
    rootCode: '738',
    fee: 207.3,
  },
  S739: {
    ...ADA_CODE_MAP['739'],
    code: 'S739',
    rootCode: '739',
    notes:
      'Can only be claimed with items 716, 727 or 728. Only claimable where a denture tooth has its entire occlusal contact with teeth of opposing arch covered by metal.',
    fee: 10.8,
  },
  S741: {
    ...ADA_CODE_MAP['741'],
    code: 'S741',
    rootCode: '741',
    notes:
      'Adjustment(s) to full or partial dentures within twelve (12) months following provision or relining by the same provider.',
    fee: 61.4,
  },
  S743: {
    ...ADA_CODE_MAP['743'],
    code: 'S743',
    rootCode: '743',
    notes: 'For soft relines, use items 743 and 737.',
    fee: 568.35,
  },
  S744: {
    ...ADA_CODE_MAP['744'],
    code: 'S744',
    rootCode: '744',
    notes: 'For soft relines, use items 743 and 737.',
    fee: 441.85,
  },
  S745: {
    ...ADA_CODE_MAP['745'],
    code: 'S745',
    rootCode: '745',
    fee: 0,
  },
  S746: {
    ...ADA_CODE_MAP['746'],
    code: 'S746',
    rootCode: '746',
    fee: 0,
  },
  S751: {
    ...ADA_CODE_MAP['751'],
    code: 'S751',
    rootCode: '751',
    notes:
      'Limit of one (1) per denture every 2 years. Chair-side only. Either hard or soft material. Not to be used for temporary materials i.e. tissue conditioners.',
    fee: 320.25,
  },
  S752: {
    ...ADA_CODE_MAP['752'],
    code: 'S752',
    rootCode: '752',
    notes:
      'Limit of one (1) per denture every 2 years. Not to be used for temporary materials i.e. tissue conditioners.',
    fee: 195.7,
  },
  S753: {
    ...ADA_CODE_MAP['753'],
    code: 'S753',
    rootCode: '753',
    notes: 'Limit of one (1) per denture every 2 years.',
    fee: 66.3,
  },
  S761: {
    ...ADA_CODE_MAP['761'],
    code: 'S761',
    rootCode: '761',
    notes:
      '761 to be claimed for GST-free component of service. 482 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 44.8,
    rules: {
      inConjunctionWith: {
        required: [482],
      },
    },
  },
  S482: {
    ...ADA_CODE_MAP['761'],
    code: 'S482',
    rootCode: '482',
    notes:
      '761 to be claimed for GST-free component of service. 482 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 124.8,
    taxStatus: TaxStrategy.GSTApplicable,
    rules: {
      inConjunctionWith: {
        required: [761],
      },
    },
  },
  S762: {
    ...ADA_CODE_MAP['762'],
    code: 'S762',
    rootCode: '762',
    notes: 'Limit of one (1) per day per denture.',
    fee: 177.2,
  },
  S763: {
    ...ADA_CODE_MAP['763'],
    code: 'S763',
    rootCode: '763',
    notes:
      '763 to be claimed for GST-free component of service. 484 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 44.8,
    rules: {
      inConjunctionWith: {
        required: [484],
      },
    },
  },
  S484: {
    ...ADA_CODE_MAP['763'],
    code: 'S484',
    rootCode: '484',
    notes:
      '763 to be claimed for GST-free component of service. 484 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 124.8,
    taxStatus: TaxStrategy.GSTApplicable,
    rules: {
      inConjunctionWith: {
        required: [763],
      },
    },
  },
  S764: {
    ...ADA_CODE_MAP['764'],
    code: 'S764',
    rootCode: '764',
    notes:
      '764 to be claimed for GST-free component of service. 485 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 44.8,
    rules: {
      inConjunctionWith: {
        required: [485],
      },
    },
  },
  S485: {
    ...ADA_CODE_MAP['764'],
    code: 'S485',
    rootCode: '485',
    notes:
      '764 to be claimed for GST-free component of service. 485 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 124.8,
    taxStatus: TaxStrategy.GSTApplicable,
    rules: {
      inConjunctionWith: {
        required: [764],
      },
    },
  },
  S765: {
    ...ADA_CODE_MAP['765'],
    code: 'S765',
    rootCode: '765',
    notes: 'Limit of one (1) per day per denture.',
    fee: 177.2,
  },
  S767: {
    ...ADA_CODE_MAP['767'],
    code: 'S767',
    rootCode: '767',
    notes:
      '767 to be claimed for GST-free component of service. 488 (labour, laboratory costs) to be claimed for GST-able component of service.',
    fee: 22.1,
    rules: {
      inConjunctionWith: {
        required: [488],
      },
    },
  },
  S488: {
    ...ADA_CODE_MAP['767'],
    code: 'S488',
    rootCode: '488',
    notes:
      '767 to be claimed for GST-free component of service. 488 (labour, laboratory costs) to be claimed for GST-able component of service.',
    fee: 48,
    taxStatus: TaxStrategy.GSTApplicable,
    rules: {
      inConjunctionWith: {
        required: [767],
      },
    },
  },
  S768: {
    ...ADA_CODE_MAP['768'],
    code: 'S768',
    rootCode: '768',
    notes: 'Limit of one (1) per day per denture.',
    fee: 179.4,
  },
  S769: {
    ...ADA_CODE_MAP['769'],
    code: 'S769',
    rootCode: '769',
    notes:
      'Limit of one (1) per day per denture. Laboratory casting invoice required. Maximum amount payable $317.20.',
    fee: 0,
  },
  S770: {
    code: 'S770',
    title: 'Special case provision of denture',
    description:
      'For provision of dentures in difficult cases including all component associated with the prosthesis',
    notes:
      'Non ADA item number. To be used in exceptional cases only – contact DVA.',
    fee: 0,
    taxStatus: TaxStrategy.GSTPossible,
    rules: {},
    scope: [ChartableSurface.Unscoped],
    deleted: false,
  },
  S771: {
    ...ADA_CODE_MAP['771'],
    code: 'S771',
    rootCode: '771',
    notes:
      'Limit of one (1) per denture per appointment. Limit of five (5) per three month period. UPR or LWR must be specified.',
    fee: 81.5,
  },
  S772: {
    ...ADA_CODE_MAP['772'],
    code: 'S772',
    rootCode: '772',
    notes:
      'A laboratory fabricated resin splint that is used to stabilise mobile or displaced teeth.',
    fee: 533.4,
  },
  S773: {
    ...ADA_CODE_MAP['773'],
    code: 'S773',
    rootCode: '773',
    notes:
      'A metal splint that is used to stabilise mobile or displaced teeth.',
    fee: 533.4,
  },
  S774: {
    ...ADA_CODE_MAP['774'],
    code: 'S774',
    rootCode: '774',
    fee: 0,
  },
  S776: {
    ...ADA_CODE_MAP['776'],
    code: 'S776',
    rootCode: '776',
    fee: 54.2,
  },
  S777: {
    ...ADA_CODE_MAP['777'],
    code: 'S777',
    rootCode: '777',
    notes: 'Limit of one (1) per denture.',
    fee: 43.45,
  },
  S811: {
    ...ADA_CODE_MAP['811'],
    code: 'S811',
    rootCode: '811',
    fee: 0,
    rules: {
      scope: ServiceCodeScope.PerJaw,
    },
  },
  S821: {
    ...ADA_CODE_MAP['821'],
    code: 'S821',
    rootCode: '821',
    fee: 0,
    rules: {
      scope: ServiceCodeScope.PerJaw,
    },
  },
  S823: {
    ...ADA_CODE_MAP['823'],
    code: 'S823',
    rootCode: '823',
    fee: 0,
    rules: {
      scope: ServiceCodeScope.PerJaw,
    },
  },
  S829: {
    ...ADA_CODE_MAP['829'],
    code: 'S829',
    rootCode: '829',
    fee: 0,
    rules: {
      scope: ServiceCodeScope.PerJaw,
    },
  },
  S831: {
    ...ADA_CODE_MAP['831'],
    code: 'S831',
    rootCode: '831',
    fee: 0,
    rules: {
      scope: ServiceCodeScope.PerJaw,
    },
  },
  S881: {
    ...ADA_CODE_MAP['881'],
    code: 'S881',
    rootCode: '881',
    fee: 0,
  },
  S911: {
    ...ADA_CODE_MAP['911'],
    code: 'S911',
    rootCode: '911',
    notes:
      'Not to be claimed with an extraction, endodontic or restorative treatment on same tooth.',
    fee: 107.05,
  },
  S915: {
    ...ADA_CODE_MAP['915'],
    code: 'S915',
    rootCode: '915',
    notes:
      'Flat fee is claimable as an emergency loading for services provided after hours. Limit of 3 per 3 month period.',
    fee: 108.1,
  },
  S916: {
    ...ADA_CODE_MAP['916'],
    code: 'S916',
    rootCode: '916',
    notes:
      'One per client per day. One per location per day. For example, only pay once per day for travel to retirement home regardless of how many patients are seen. Note: a provider operating a mobile dental clinic is not entitled to this item. Can be claimed without a dental item if it is part of non-billable dental treatment such as adjustments or repairs to dentures. Reasons for the travel should be provided.',
    fee: 78.6,
  },
  S926: {
    ...ADA_CODE_MAP['926'],
    code: 'S926',
    rootCode: '926',
    notes:
      'Limit of one (1) per arch per 12 month period. Not to be claimed for bleaching.',
    fee: 186.45,
  },
  S927: {
    ...ADA_CODE_MAP['927'],
    code: 'S927',
    rootCode: '927',
    notes:
      'For non-prescribable (non-RPBS) items – Fluoride & Chlorhexidine. Limit of one (1) per three month period.',
    fee: 32.35,
  },
  S949: {
    ...ADA_CODE_MAP['949'],
    code: 'S949',
    rootCode: '949',
    notes:
      'Items D949 and S949 can be claimed to cover the additional costs a dental provider, who does not have regular theatre times at a hospital or day procedure center, may incur when leaving their usual place of practice to undertake a procedure which requires the administration of a general anaesthesia.',
    fee: 0,
  },
  S961: {
    ...ADA_CODE_MAP['961'],
    code: 'S961',
    rootCode: '961',
    notes: 'Not related to any other procedure.',
    fee: 0,
  },
  S963: {
    ...ADA_CODE_MAP['963'],
    code: 'S963',
    rootCode: '963',
    notes: 'Limit of one (1) per three year period.',
    fee: 145.15,
  },
  S964: {
    ...ADA_CODE_MAP['964'],
    code: 'S964',
    rootCode: '964',
    notes:
      'Limit of one (1) per three year period. Cannot be claimed with items 500-899 inclusive.',
    fee: 106.85,
  },
  S965: {
    ...ADA_CODE_MAP['965'],
    code: 'S965',
    rootCode: '965',
    fee: 1049.15,
  },
  S966: {
    ...ADA_CODE_MAP['966'],
    code: 'S966',
    rootCode: '966',
    notes: 'Limit of four (4) per 12 months.',
    fee: 106.2,
  },
  S968: {
    ...ADA_CODE_MAP['968'],
    code: 'S968',
    rootCode: '968',
    notes:
      'Can only be claimed following D/S963 and/or D/S964. Limit of four (4) per year',
    fee: 160.15,
  },
  S971: {
    ...ADA_CODE_MAP['971'],
    code: 'S971',
    rootCode: '971',
    notes: 'Limit of four (4) per 12 month period.',
    fee: 106.85,
  },
  S972: {
    ...ADA_CODE_MAP['972'],
    code: 'S972',
    rootCode: '972',
    fee: 337.9,
  },
  S981: {
    ...ADA_CODE_MAP['981'],
    code: 'S981',
    rootCode: '981',
    fee: 145.15,
  },
  S982: {
    ...ADA_CODE_MAP['982'],
    code: 'S982',
    rootCode: '982',
    fee: 112,
  },
  S983: {
    ...ADA_CODE_MAP['983'],
    code: 'S983',
    rootCode: '983',
    notes:
      'Only on diagnosis of sleep apnoea and prescription from a respiratory or ENT physician and consideration of treatment with CPAP.',
    fee: 0,
  },
  S984: {
    ...ADA_CODE_MAP['984'],
    code: 'S984',
    rootCode: '984',
    notes:
      'Only on diagnosis of sleep apnoea and prescription from a respiratory or ENT physician and consideration of treatment with CPAP.',
    fee: 0,
  },
  S985: {
    ...ADA_CODE_MAP['985'],
    code: 'S985',
    rootCode: '985',
    fee: 337.9,
  },
  S986: {
    ...ADA_CODE_MAP['986'],
    code: 'S986',
    rootCode: '986',
    notes: 'Limit of two (2) per 12 month period.',
    fee: 103.7,
  },
  S990: {
    ...ADA_CODE_MAP['990'],
    code: 'S990',
    rootCode: '990',
    notes: 'Exceptional use item only – contact DVA',
    fee: 0,
  },
}).reduce(
  (codes, [key, code]) => ({
    ...codes,
    [key]: {
      ...code,
      type: ServiceCodeType.DVA,
    },
  }),
  {}
);

const convertedProstheticCodes = Object.entries(
  PROSTHETIC_DENTAL_DVA_CODES
).reduce(
  (codes, [key, code]) => ({
    ...codes,
    [key]: {
      ...code,
      type: ServiceCodeType.DVA,
    },
  }),
  {}
);

export const DVA_CODE_MAP: ItemCodeAliases = {
  ...GENERAL_DENTAL_DVA_CODES,
  ...SPECIALIST_DVA_CODES,
  ...convertedProstheticCodes,
};

export const DVA_SERVICE_CODES: IServiceCode[] = Object.values(DVA_CODE_MAP);

export const DVA_SERVICE: IServiceProvider = {
  label: 'DVA',
  description: 'DVA Scheme',
  type: ServiceCodeType.DVA,
  parentType: ServiceCodeType.ADA,
  items: DVA_CODE_MAP,
  region: Region.Australia,
};
