import { type DocumentReference } from '@principle-theorem/shared';
import { isDocRef, isObject } from '@principle-theorem/shared';
import { type IInvoice } from './invoice';

export interface IPatientViewInvoiceTokenData {
  invoice: DocumentReference<IInvoice>;
}

export interface IPatientViewInvoiceRequest {
  tokenUid: string;
}

export function isPatientViewInvoiceTokenData(
  data: unknown
): data is IPatientViewInvoiceTokenData {
  return isObject(data) && isDocRef(data.invoice);
}
