import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@principle-theorem/ng-auth';
import {
  isEnumValue,
  TIME_FORMAT,
  TIME_FORMAT_24HR,
} from '@principle-theorem/shared';
import { queryParam$ } from '@principle-theorem/ng-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRestrictedReason } from '@principle-theorem/ng-principle-shared';
import * as moment from 'moment-timezone';

@Component({
  selector: 'pr-user-restricted',
  templateUrl: './user-restricted.component.html',
  styleUrl: './user-restricted.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRestrictedComponent {
  restrictionReason$: Observable<UserRestrictedReason | undefined>;
  restrictedUntil$: Observable<moment.Moment>;
  timeRestriction = UserRestrictedReason.Time;
  ipRestriction = UserRestrictedReason.IP;
  timeFormat = TIME_FORMAT;

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute
  ) {
    this.restrictionReason$ = queryParam$(this._route, 'reason').pipe(
      map((reason) =>
        isEnumValue(UserRestrictedReason, reason) ? reason : undefined
      )
    );
    this.restrictedUntil$ = queryParam$(this._route, 'restrictedUntil').pipe(
      map((time) => moment(time, TIME_FORMAT_24HR))
    );
  }

  async logout(): Promise<void> {
    return this._auth.logout();
  }
}
