<div class="p-4">
  <div class="mb-4 flex items-center justify-between">
    <h2 class="text-xl font-bold">Prescriptions</h2>
    <div class="flex items-center justify-start gap-4">
      <pr-practitioner-selector
        label="Create As"
        [staffer]="prescribingAs()"
        [staff]="organisation.prescribers$ | async"
        (stafferSelected)="prescribingAs.set($event)"
      />
      <div [matTooltip]="feedbackMessage()">
        <button
          mat-flat-button
          color="primary"
          [disabled]="!hasPermissions() || !prescribingAs()"
          (click)="create()"
        >
          Create Prescription
        </button>
      </div>
    </div>
  </div>

  @if (prescription.prescriptions().length) {
    <pr-content-container padding="p-0">
      <div class="mt-4 flex h-full flex-col">
        <pr-patient-prescriptions-list
          [prescriptions]="prescription.prescriptions()"
          (prescriptionSelected)="navigate($event)"
        />
      </div>
    </pr-content-container>
  } @else {
    @if (!prescription.loading()) {
      <pr-empty-state image="list" title="prescriptions" />
    }
  }
</div>
