import {
  EventType,
  IBrand,
  ICalendarEvent,
  ICalendarEventSchedule,
  IPractice,
  IRoster,
  IRosterTime,
  IScheduleRange,
  IStaffer,
  StafferCollection,
} from '@principle-theorem/principle-core/interfaces';
import {
  DayOfWeek,
  DAYS_OF_WEEK,
  INamedDocument,
  IReffable,
  ITimePeriod,
  multiSwitchMap,
  reduceToSingleArray,
  subCollection,
  Timezone,
  toMomentTz,
  WithRef,
  undeletedQuery,
  isSameRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { combineLatest, from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TimezoneResolver } from '../../timezone';
import { Brand } from '../brand';
import { CalendarEventSchedule } from '../event/calendar-event-schedule';
import { Schedule } from '../schedule/schedule';
import { scheduleTimeToTimePeriod } from '../schedule/schedule-time';
import { CollectionReference, where } from '@principle-theorem/shared';
import { compact } from 'lodash';
import { OrganisationCache } from '../organisation/organisation-cache';

export class Roster {
  static init(): IRoster {
    const operatingDays: DayOfWeek[] = DAYS_OF_WEEK;

    const schedule: IRoster = {
      days: [],
    };

    operatingDays.map((day: DayOfWeek) => {
      schedule.days.push({
        dayOfWeek: day,
        shift: { from: '08:00', to: '18:00' },
      });
    });

    return schedule;
  }

  static col(
    staffer: IReffable<IStaffer>
  ): CollectionReference<ICalendarEventSchedule> {
    return subCollection<ICalendarEventSchedule>(
      staffer.ref,
      StafferCollection.RosterSchedules
    );
  }

  static all$(
    staffer: IReffable<IStaffer>,
    eventType?: EventType,
    practice?: INamedDocument<IPractice>
  ): Observable<WithRef<ICalendarEventSchedule>[]> {
    return OrganisationCache.rosterSchedules
      .query$(
        { staffer, eventType, practice },
        undeletedQuery(Roster.col(staffer)),
        ...compact([
          eventType ? where('item.event.type', '==', eventType) : undefined,
        ])
      )
      .pipe(
        map((schedules) =>
          schedules.filter((schedule) =>
            practice ? isSameRef(schedule.item.event.practice, practice) : true
          )
        )
      );
  }

  static hasPracticeRoster$(
    staffer: IReffable<IStaffer>,
    practice: INamedDocument<IPractice>
  ): Observable<boolean> {
    return Roster.all$(staffer, EventType.RosteredOn, practice).pipe(
      map((schedules) => !!schedules.length)
    );
  }

  static getByDay(schedule: IRoster, dayOfWeek: DayOfWeek): IRosterTime[] {
    return schedule.days.filter(
      (scheduleDay: IRosterTime) => scheduleDay.dayOfWeek === dayOfWeek
    );
  }

  static getOpeningTimes(
    schedule: IRoster,
    dayOfWeek: DayOfWeek
  ): IScheduleRange | undefined {
    const days: IRosterTime[] = this.getByDay(schedule, dayOfWeek);
    return days.length ? days[0].shift : undefined;
  }

  static getScheduleTimes(
    schedules: ICalendarEventSchedule[],
    range: ITimePeriod,
    timezone: Timezone
  ): ITimePeriod[] {
    return reduceToSingleArray(
      schedules.map((schedule) =>
        Schedule.forecast(schedule, range, timezone).map((date) =>
          scheduleTimeToTimePeriod(
            schedule.scheduleTime,
            toMomentTz(date, timezone)
          )
        )
      )
    );
  }

  static isRosteredOn(schedules: ITimePeriod[], day: moment.Moment): boolean {
    if (!schedules.length) {
      return false;
    }

    return schedules.some((schedule) => schedule.from.isSame(day, 'day'));
  }

  static rosteredTimes$(
    staffer: IReffable<IStaffer>,
    range: ITimePeriod,
    brand: INamedDocument<IBrand>,
    practice: INamedDocument<IPractice>
  ): Observable<ITimePeriod[]> {
    return from(TimezoneResolver.fromPracticeRef(practice.ref)).pipe(
      switchMap((timezone) => {
        const timezoneAwareRange = {
          from: toMomentTz(range.from, timezone),
          to: toMomentTz(range.to, timezone),
        };

        return Roster.getRosterEvents$(
          staffer,
          timezoneAwareRange,
          brand,
          practice
        ).pipe(
          map((events) =>
            events.map((event) => ({
              from: toMomentTz(event.event.from, timezone),
              to: toMomentTz(event.event.to, timezone),
            }))
          )
        );
      })
    );
  }

  static getRosterEvents$(
    staffer: IReffable<IStaffer>,
    range: ITimePeriod,
    brand: INamedDocument<IBrand>,
    practice: INamedDocument<IPractice>
  ): Observable<(WithRef<ICalendarEvent> | ICalendarEvent)[]> {
    const rosteredOnEvents$ = Brand.eventQueryV2$(
      brand,
      range,
      'event.from',
      [staffer.ref],
      EventType.RosteredOn,
      practice.ref
    );

    const rosterSchedules$ = Roster.all$(
      staffer,
      EventType.RosteredOn,
      practice
    ).pipe(
      multiSwitchMap((schedule) =>
        CalendarEventSchedule.buildEvents(schedule, range)
      ),
      map(reduceToSingleArray)
    );

    return combineLatest([rosteredOnEvents$, rosterSchedules$]).pipe(
      map(([rosteredOnEvents, rosterSchedules]) => [
        ...rosteredOnEvents,
        ...rosterSchedules,
      ])
    );
  }
}
