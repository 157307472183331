import { type IFirestoreModel } from '@principle-theorem/shared';
import {
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import { type IGap } from '../gap/gap';
import { type InteractionType } from '../interaction/interaction';

export enum SchedulingAlertTargetType {
  Appointment = 'appointment',
  Gap = 'gap',
  LabJob = 'labjob',
}

export interface ISchedulingAlert extends IFirestoreModel {
  title: string;
  description: string;
  type: InteractionType;
  targetType: SchedulingAlertTargetType;
  deadline: Timestamp;
  target?: DocumentReference;
  gap?: IGap;
}
