import {
  randEmail,
  randJobTitle,
  randPhoneNumber,
  randStreetAddress,
  randWord,
} from '@ngneat/falso';
import {
  ContactResourceType,
  IContact,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  MockGenerator,
  Properties,
} from '@principle-theorem/shared';
import { MockNamedDocument } from '@principle-theorem/testing';
import { NoteMock } from '../note/note.mock';
import { InteractionMock } from '../interaction/interaction.mock';

export class ContactMock
  extends BaseFirestoreMock
  implements Properties<IContact>
{
  notes = [MockGenerator.generate(NoteMock)];
  interactions = [MockGenerator.generate(InteractionMock)];
  parentRef = {
    type: ContactResourceType.Contact,
    ...MockNamedDocument('Parent Contact'),
  };
  name = randWord();
  email = randEmail();
  address = randStreetAddress();
  phone = randPhoneNumber();
  mobileNumber = randPhoneNumber();
  jobTitle = randJobTitle();
}
