import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IAccountCredit,
  type IAccountCreditExtendedData,
  type IInvoice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  filterUndefined,
  isSameRef,
  multiFilter,
  multiSwitchMap,
  reduce2DArray,
  resolveSequentially,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import {
  combineLatest,
  type Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { type IInvoiceCreditTransactionsPair } from '../amend-invoice-confirm-dialog/amend-invoice-confirm-dialog.component';
import { map, switchMap } from 'rxjs/operators';
import {
  getRefundRemaining,
  Patient,
  Transaction,
} from '@principle-theorem/principle-core';
import { CurrentPatientScope } from '@principle-theorem/ng-principle-shared';
import { flatMap, groupBy, mapValues, sum, uniqBy, values } from 'lodash';
import { AccountCreditTransactionProvider } from '@principle-theorem/ng-payments';

@Component({
  selector: 'pr-invoice-credit-summary',
  exportAs: 'prInvoiceCreditSummary',
  templateUrl: './invoice-credit-summary.component.html',
  styleUrls: ['./invoice-credit-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceCreditSummaryComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _transactions$: Observable<
    WithRef<ITransaction<IAccountCreditExtendedData>>[]
  >;
  credits$ = new ReplaySubject<WithRef<IAccountCredit>[]>(1);
  unusedCredits$: Observable<WithRef<IAccountCredit>[]>;
  usedCredits$: Observable<WithRef<IAccountCredit>[]>;
  pairs$: Observable<IInvoiceCreditTransactionsPair[]>;
  canAmend$: Observable<boolean>;
  trackByCredit = TrackByFunctions.ref<WithRef<IAccountCredit>>();
  trackByInvoice = TrackByFunctions.ref<IInvoice>('invoice.ref');

  @Input()
  set credits(credits: WithRef<IAccountCredit>[]) {
    if (credits) {
      this.credits$.next(credits);
    }
  }

  constructor(
    private _patientScope: CurrentPatientScope,
    private _provider: AccountCreditTransactionProvider
  ) {
    this.unusedCredits$ = this.credits$.pipe(
      multiFilter((credit) => credit.used === 0)
    );
    this.usedCredits$ = this.credits$.pipe(
      multiFilter((credit) => credit.used > 0)
    );

    this._transactions$ = this._patientScope.doc$.pipe(
      filterUndefined(),
      switchMap((patient) => Patient.creditTransactions$(patient))
    );

    this.pairs$ = this.usedCredits$.pipe(
      multiSwitchMap((accountCredit) =>
        combineLatest([of(accountCredit), this._transactions$]).pipe(
          map(([credit, transactions]) =>
            getRefundableTransactions(transactions, credit)
          )
        )
      ),
      reduce2DArray(),
      multiSwitchMap(async (transaction) => {
        return {
          invoice: await Transaction.getInvoice(transaction),
          transactions: [transaction],
        };
      }),
      map((pairs) => combineInvoiceTransactionPairs(pairs))
    );

    this.canAmend$ = this.usedCredits$.pipe(
      map((usedCredits) => !usedCredits.length)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async sumTransactions(
    transactions: ITransaction<IAccountCreditExtendedData>[]
  ): Promise<number> {
    const creditsOnInvoice = await snapshot(this.usedCredits$);

    const filteredTransactions = flatMap(
      transactions,
      (transaction) => transaction.extendedData?.accountCreditsUsed ?? []
    ).filter((usedCredits) =>
      creditsOnInvoice.some((credit) => isSameRef(credit, usedCredits))
    );

    return sum(filteredTransactions.map((transaction) => transaction?.amount));
  }

  async refundCredit(pair: IInvoiceCreditTransactionsPair): Promise<void> {
    const creditsOnInvoice = await snapshot(this.usedCredits$);
    await resolveSequentially(pair.transactions, async (transaction) =>
      asyncForEach(creditsOnInvoice, async (credit) =>
        this._provider.refundTransaction(pair.invoice, transaction, credit)
      )
    );
  }
}

export function combineInvoiceTransactionPairs(
  pairs: IInvoiceCreditTransactionsPair[]
): IInvoiceCreditTransactionsPair[] {
  const groupedByInvoice = groupBy(pairs, (pair) => pair.invoice.ref.id);

  const withCombinedTransactions = mapValues(
    groupedByInvoice,
    (groupedPairs) => {
      const transactions = flatMap(
        groupedPairs,
        (groupedPair) => groupedPair.transactions
      );
      return {
        invoice: groupedPairs[0].invoice,
        transactions: uniqBy(transactions, (transaction) => transaction.ref.id),
      };
    }
  );

  return values(withCombinedTransactions);
}

export function getRefundableTransactions(
  transactions: WithRef<ITransaction<IAccountCreditExtendedData>>[],
  credit: WithRef<IAccountCredit>
): WithRef<ITransaction<IAccountCreditExtendedData>>[] {
  return transactions.filter(
    (transaction) =>
      (transaction.extendedData?.accountCreditsUsed ?? []).some((creditUsed) =>
        isSameRef(creditUsed, credit)
      ) && getRefundRemaining(transaction, transactions)
  );
}
