import { TaxStrategy } from '@principle-theorem/accounting';
import {
  CollectionGroup,
  ICustomLineItem,
  IInvoice,
  InvoiceLineItemType,
  IPatient,
  IPractice,
  isTreatmentLineItem,
  ITreatmentLineItem,
  type IBrand,
} from '@principle-theorem/principle-core/interfaces';
import {
  AnyDataTable,
  asyncForEach,
  collectionGroupQuery,
  DataFormat,
  DocumentReference,
  Firestore,
  query,
  toMomentTz,
  toTimestamp,
  where,
  type IDataTable,
  type ITimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
import { flatten, padStart, sortBy, trim } from 'lodash';
import { Brand } from '../models/brand';
import { isServiceCodeLineItem } from '../models/invoice/custom-line-items';
import { Invoice } from '../models/invoice/invoice';
import { dateRangeToPracticeTimezone } from './helpers';
interface ILineItemInheritance {
  lineItem: ICustomLineItem;
  lineItemParent?: ICustomLineItem;
}

interface IInvoiceLineItemData extends ILineItemInheritance {
  practice: WithRef<IPractice>;
  patient: WithRef<IPatient>;
  invoice: WithRef<IInvoice>;
}

interface IInvoiceLineItemRow {
  lineItemGroup: string;
  practitionerName: string;
  lineItemLabel: string;
  lineItemQuantity: number;
  lineItemAmount: number;
  lineItemTax: number;
  lineItemTaxStatus: TaxStrategy;
  lineItemTotal: number;
  lineItemTotalTax: number;
  patientName: string;
  invoice: string;
  invoiceLink: string;
  invoiceDate: string;
}

export class InvoiceLineItemTaxReport {
  async run(
    dateRange: ITimePeriod,
    brandRef: DocumentReference<IBrand>,
    appUrl: string
  ): Promise<IDataTable<IInvoiceLineItemRow>[]> {
    const brand = await Firestore.getDoc(brandRef);

    const invoices = await this._getInvoices(brandRef, dateRange);
    const lineItems = await asyncForEach(invoices, (invoice) =>
      this._getLineItemData(invoice)
    );
    const rows = lineItems
      .flat()
      .map((lineItem) => this._toRow(lineItem, brand, appUrl));
    return this._toDataTables(rows);
  }

  private async _getInvoices(
    brandRef: DocumentReference<IBrand>,
    dateRange: ITimePeriod
  ): Promise<WithRef<IInvoice>[]> {
    const practices = await query(
      Brand.practiceCol({ ref: brandRef }),
      where('deleted', '==', false)
    );
    const practiceInvoices = await asyncForEach(practices, (practice) => {
      const practiceDateRange = dateRangeToPracticeTimezone(
        practice,
        dateRange
      );
      return query(
        collectionGroupQuery<IInvoice>(CollectionGroup.Invoices),
        where('deleted', '==', false),
        where('practice.ref', '==', practice.ref),
        where('createdAt', '>=', toTimestamp(practiceDateRange.from)),
        where('createdAt', '<=', toTimestamp(practiceDateRange.to))
      );
    });
    return flatten(practiceInvoices);
  }

  private async _getLineItemData(
    invoice: WithRef<IInvoice>
  ): Promise<IInvoiceLineItemData[]> {
    const patient = await Firestore.getDoc(Invoice.patientDocRef(invoice));
    const practice = await Firestore.getDoc(invoice.practice.ref);
    return invoice.items
      .map((lineItem) => this._getLineItemsRecursive(lineItem))
      .flat()
      .map((lineItem) => {
        return {
          ...lineItem,
          invoice,
          patient,
          practice,
        };
      });
  }

  private _getLineItemsRecursive(
    lineItem: ICustomLineItem,
    lineItemParent?: ICustomLineItem
  ): ILineItemInheritance[] {
    const mainItem = { lineItem, lineItemParent };
    if (!isTreatmentLineItem(lineItem)) {
      return [mainItem];
    }
    return lineItem.items
      .map((childLineItem) =>
        this._getLineItemsRecursive(childLineItem, lineItem)
      )
      .flat();
  }

  private _toRow(
    data: IInvoiceLineItemData,
    brand: WithRef<IBrand>,
    appUrl: string
  ): IInvoiceLineItemRow {
    const invoiceDate = toMomentTz(
      data.invoice.createdAt,
      data.practice.settings.timezone
    ).format('YYYY-MM-DD HH:mm');

    const invoiceLink = [
      appUrl,
      brand.slug,
      'patients',
      data.patient.ref.id,
      'account/invoices',
      data.invoice.ref.id,
    ].join('/');

    return {
      lineItemGroup: data.lineItemParent
        ? this._getLineItemLabel(data.lineItemParent, undefined)
        : '',
      practitionerName: this._getPractitionerName(data.lineItemParent),
      lineItemLabel: this._getLineItemLabel(data.lineItem, data.lineItemParent),
      lineItemQuantity: data.lineItem.quantity,
      lineItemAmount: data.lineItem.amount,
      lineItemTaxStatus: data.lineItem.taxStatus,
      lineItemTax: data.lineItem.tax,
      lineItemTotal: data.lineItem.amount * data.lineItem.quantity,
      lineItemTotalTax: data.lineItem.tax * data.lineItem.quantity,
      patientName: data.patient.name,
      invoiceDate: invoiceDate,
      invoice: data.invoice.reference,
      invoiceLink,
    };
  }

  private _getPractitionerName(lineItem?: ICustomLineItem): string {
    if (!lineItem || !isTreatmentLineItem(lineItem)) {
      return '';
    }
    return lineItem.treatmentRef.attributedTo.name;
  }

  private _toDataTables(data: IInvoiceLineItemRow[]): AnyDataTable[] {
    return [
      {
        name: 'line item tax',
        data: sortBy(data, 'invoiceDate'),
        columns: [
          { key: 'invoiceDate', header: 'Invoice Date' },
          { key: 'invoice', header: 'Invoice Reference' },
          { key: 'patientName', header: 'Patient' },
          { key: 'invoiceLink', header: 'Invoice Link' },
          { key: 'lineItemGroup', header: 'Treatment Name' },
          { key: 'practitionerName', header: 'Practitioner' },
          { key: 'lineItemLabel', header: 'Line Item Name' },
          { key: 'lineItemQuantity', header: 'Line Item Quantity' },
          {
            key: 'lineItemTax',
            header: 'Line Item Tax',
            format: DataFormat.Currency,
          },
          {
            key: 'lineItemTaxStatus',
            header: 'Tax Status',
          },
          {
            key: 'lineItemAmount',
            header: 'Line Item Amount',
            format: DataFormat.Currency,
          },
          {
            key: 'lineItemTotal',
            header: 'Line Item Total',
            format: DataFormat.Currency,
          },
          {
            key: 'lineItemTotalTax',
            header: 'Line Item Tax Total',
            format: DataFormat.Currency,
          },
        ],
      },
    ];
  }

  private _getTreatmentLabel(lineItem: ITreatmentLineItem): string {
    const removeAfterLast = ' - (';
    const removeAfterIndex = lineItem.description.lastIndexOf(removeAfterLast);
    if (removeAfterIndex !== -1) {
      return lineItem.description.slice(0, removeAfterIndex);
    }
    return lineItem.description;
  }

  private _getLineItemLabel(
    lineItem: ICustomLineItem,
    parentLineItem?: ICustomLineItem
  ): string {
    if (isServiceCodeLineItem(lineItem)) {
      return padStart(lineItem.code, 3, '0');
    }
    if (isTreatmentLineItem(lineItem)) {
      return this._getTreatmentLabel(lineItem);
    }
    if (
      lineItem.type === InvoiceLineItemType.TreatmentBasePrice &&
      parentLineItem &&
      isTreatmentLineItem(parentLineItem)
    ) {
      const treatmentLabel = this._getTreatmentLabel(parentLineItem);
      return `${treatmentLabel} - Base Price`;
    }
    if (lineItem.type === InvoiceLineItemType.Deposit) {
      return trim(lineItem.description.toLowerCase());
    }
    return trim(lineItem.description);
  }
}
