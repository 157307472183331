import { IStatisticMetrics, IStatistic } from './base-statistics';

export interface IReferrerStats extends IStatisticMetrics {
  newPatients: IStatistic;
  activePatients: IStatistic;
  inactivePatients: IStatistic;
  caseAcceptanceRate: IStatistic;
  totalRevenue: IStatistic;
  nextVisitsBooked: IStatistic;
  noNextAppointment: IStatistic;
  cancellations: IStatistic;
  advertisingCosts: IStatistic;
  costPerConversion: IStatistic;
  meanTreatmentPlanValue: IStatistic;
}
