import { User } from '@principle-theorem/principle-core';
import {
  FailedDestinationEntityRecord,
  ServiceTypeModality,
  SourceEntityRecordStatus,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  BaseStafferDestinationEntity,
  IStafferJobData,
  IStafferMigrationData,
} from '../../../destination/entities/staff';
import { TranslationMapHandler } from '../../../translation-map';
import {
  StafferSourceEntity,
  type IExactStaffer,
} from '../../source/entities/staff';
import { ExactStafferMappingHandler } from '../mappings/staff';
import { ExactStaffToUserMappingHandler } from '../mappings/staff-to-user';

export const STAFF_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: 'staff',
    label: 'Staff',
    description: `
      Exact exported data does not include any provider information except for the occasional 'Dr' title. Due to this, we cannot set any provider data for them.
    `,
  },
});

export class StafferDestinationEntity extends BaseStafferDestinationEntity<IExactStaffer> {
  destinationEntity = STAFF_DESTINATION_ENTITY;
  stafferSourceEntity = new StafferSourceEntity();
  stafferCustomMapping = new ExactStafferMappingHandler();
  staffToUserCustomMapping = new ExactStaffToUserMappingHandler();

  override sourceEntities = {
    staff: new StafferSourceEntity(),
  };

  customMappings = {
    staff: new ExactStafferMappingHandler(),
    staffToUser: new ExactStaffToUserMappingHandler(),
  };

  buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IStafferJobData<IExactStaffer>
  ):
    | IStafferMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord) {
    if (data.stafferRecord.record.status === SourceEntityRecordStatus.Invalid) {
      const errorResponseData = {
        label: data.stafferRecord.record.label,
        uid: data.stafferRecord.record.uid,
        ref: data.stafferRecord.record.ref,
      };
      return this._buildErrorResponse(
        errorResponseData,
        'Source staffer is invalid'
      );
    }

    const sourceStafferId = this.sourceEntities.staff.getSourceRecordId(
      data.stafferRecord.data.data
    );

    const user = User.init({
      name: this.getName(data.stafferRecord.data.data),
      email: data.stafferRecord.data.data.email,
      practices: migration.configuration.practices.map(
        (practice) => practice.ref
      ),
      brands: [migration.configuration.brand.ref],
      isEnabled: false,
    });

    return {
      sourceStafferId,
      stafferDetails: {
        providerNumber: '',
        providerModality: ServiceTypeModality.GeneralDentist,
      },
      // Need to find out why we were using initials
      // sourceStafferInitials: this.getStafferInitials(
      //   data.stafferRecord.data.data
      // ),
      user,
    };
  }

  getName(data: IExactStaffer): string {
    const name = `${data.title ?? ''} ${data.first_name ?? ''} ${
      data.middle_name ?? ''
    } ${data.surname ?? ''}`;

    if (name.length === 0) {
      return data.id;
    }
    return name;
  }

  getStafferInitials(data: IExactStaffer): string {
    const firstInitial = data.first_name
      ? data.first_name[0].toUpperCase()
      : '';
    const middleInitial = data.middle_name
      ? data.middle_name[0].toUpperCase()
      : '';
    const lastInitial = data.surname ? data.surname[0].toUpperCase() : '';

    return `${firstInitial}${middleInitial}${lastInitial}`;
  }
}
