import { BlockNodes } from '@principle-theorem/editor';
import { Extension } from '@tiptap/core';
import { AILoader } from './ai-submenu-button/ai-submenu-button.component';

export const aiTabShortcutExtension = Extension.create({
  name: 'ai-tab-shortcut',

  addKeyboardShortcuts() {
    return {
      Tab: (props) => {
        if (props.editor.isActive(BlockNodes.Paragraph)) {
          AILoader.isRunning$.next(true);
        }
        return false;
      },
    };
  },
});
