import { createEntityAdapter, type EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { type ISerialisedEntityState } from '@principle-theorem/ng-shared';
import {
  type ICalendarEvent,
  type ICandidateCalendarEvent,
  type IEventTimePeriod,
} from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import { AppointmentSuggestionActions } from '../actions';
import { AppointmentSuggestionSearchType } from '../models/appointment-search';
import { type AppointmentSuggestionEntity } from '../models/appointment-suggestion';

export const APPOINTMENT_SUGGESTIONS_FEATURE_KEY = 'appointmentSuggestions';

export interface IAppointmentSuggestionsState
  extends ISerialisedEntityState<AppointmentSuggestionEntity> {
  searchType: AppointmentSuggestionSearchType;
  selectedSuggestion?: SerialisedData<AppointmentSuggestionEntity>;
  availableTimes: SerialisedData<IEventTimePeriod[]>;
  gapCandidates: SerialisedData<WithRef<ICandidateCalendarEvent>[]>;
  calendarEvents: SerialisedData<WithRef<ICalendarEvent>[]>;
}

export interface IAppointmentSuggestionsPartialState {
  readonly [APPOINTMENT_SUGGESTIONS_FEATURE_KEY]: IAppointmentSuggestionsState;
}

export const appointmentSuggestionsAdapter: EntityAdapter<
  SerialisedData<AppointmentSuggestionEntity>
> = createEntityAdapter({
  selectId: (suggestion) => suggestion.uid,
  sortComparer: false,
});

const initialSuggestionsState: IAppointmentSuggestionsState =
  appointmentSuggestionsAdapter.getInitialState({
    loaded: false,
    searchType: AppointmentSuggestionSearchType.Normal,
    availableTimes: [],
    gapCandidates: [],
    calendarEvents: [],
  });

export const appointmentSuggestionsReducer = createReducer(
  initialSuggestionsState,

  on(
    AppointmentSuggestionActions.loadAppointmentSuggestions,
    AppointmentSuggestionActions.resetAppointmentSuggestions,
    (state) => ({
      ...state,
      loaded: false,
    })
  ),

  on(
    AppointmentSuggestionActions.loadAppointmentSuggestionsSuccess,
    (state, { appointmentSuggestions }) => {
      if (state.selectedSuggestion) {
        appointmentSuggestions = [
          state.selectedSuggestion,
          ...appointmentSuggestions,
        ];
      }
      return appointmentSuggestionsAdapter.setAll(appointmentSuggestions, {
        ...state,
        loaded: true,
      });
    }
  ),

  on(AppointmentSuggestionActions.selectSuggestion, (state, { id }) => ({
    ...state,
    selectedId: id,
    selectedSuggestion: state.entities[id],
  })),

  on(AppointmentSuggestionActions.unselectSuggestion, (state) => ({
    ...state,
    selectedId: undefined,
    selectedSuggestion: undefined,
  })),

  on(
    AppointmentSuggestionActions.loadAvailabilitySuccess,
    (state, { availableTimes }) => ({
      ...state,
      availableTimes,
    })
  ),

  on(AppointmentSuggestionActions.setSearchType, (state, { searchType }) => ({
    ...state,
    searchType,
  })),

  on(
    AppointmentSuggestionActions.gapCandidatesLoaded,
    (state, { gapCandidates }) => ({
      ...state,
      gapCandidates,
    })
  ),

  on(
    AppointmentSuggestionActions.loadCalendarEventsSuccess,
    (state, { calendarEvents }) => ({
      ...state,
      calendarEvents,
    })
  ),

  on(
    AppointmentSuggestionActions.addAppointmentSuggestion,
    (state, { appointmentSuggestion }) =>
      appointmentSuggestionsAdapter.addOne(appointmentSuggestion, {
        ...state,
        selectedId: appointmentSuggestion.uid,
        selectedSuggestion: appointmentSuggestion,
      })
  ),

  on(AppointmentSuggestionActions.removeSuggestion, (state, { id }) =>
    appointmentSuggestionsAdapter.removeOne(id, state)
  ),

  on(AppointmentSuggestionActions.reset, () => ({
    ...initialSuggestionsState,
  }))
);
