import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  CALENDAR_UNITS,
  CalendarUnit,
} from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-calendar-view-selector',
  templateUrl: './calendar-view-selector.component.html',
  styleUrls: ['./calendar-view-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarViewSelectorComponent {
  trackByType = TrackByFunctions.variable<CalendarUnit>();
  unit$ = new ReplaySubject<CalendarUnit>(1);
  viewTypes: CalendarUnit[] = CALENDAR_UNITS;

  @Input()
  set unit(unit: CalendarUnit) {
    if (unit) {
      this.unit$.next(unit);
    }
  }

  constructor(
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  async selectUnit(calendarUnit: CalendarUnit): Promise<void> {
    await this._router.navigate([], {
      relativeTo: this._route,
      queryParamsHandling: 'merge',
      queryParams: {
        calendarUnit,
      },
    });
  }
}
