<div class="flex items-center justify-end gap-4">
  <div
    *ngIf="name"
    class="mat-caption flex-1 truncate"
    [matTooltip]="tooltip"
    [matTooltipDisabled]="!tooltip"
  >
    {{ name }}
  </div>

  <div
    class="mat-caption flex"
    [ngClass]="disabled ? 'price-disabled' : 'price'"
  >
    {{ price | currency }}
  </div>
  <button
    *ngIf="!disabled"
    mat-icon-button
    color="warn"
    (click)="basePriceDeleted.emit()"
    [matTooltip]="'Delete ' + name"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
