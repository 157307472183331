<ng-container *ngIf="item$ | async as item">
  <pt-colour-sliver
    [colour]="item | map: priorityColour"
    matTooltip="{{ item.priority + ' priority' | titlecase }}"
  />

  <mat-list-item
    class="mat-mdc-list-item-interactive task-list-item"
    [ngClass]="{
      completed: (item | map: isComplete) || item.deleted,
      selected: item === selectedItem
    }"
    (click)="selectItem(item)"
  >
    <div class="flex items-center">
      <div
        class="checkbox"
        *ngIf="selectEnabled$ | async"
        (click)="$event.stopPropagation()"
      >
        <mat-checkbox
          color="primary"
          [checked]="
            item | map: selectionList.isSelected$ : selectionList | async
          "
          (change)="selectionList.toggleSelected(item)"
        />
      </div>

      <div class="initials-icon">
        <ng-container *ngIf="item.assignedName; else noAssignee">
          <pt-user-icon
            [src]="assignedUserPic$ | async"
            [name]="item.assignedName"
          />
        </ng-container>

        <ng-template #noAssignee>
          <pt-initials-icon name="N/A" matTooltip="No Assignee" />
        </ng-template>
      </div>

      <div fxFlex class="details">
        <div class="flex flex-col">
          <div class="flex items-center gap-2">
            <div class="mat-subtitle-1 title truncate">{{ item.title }}</div>
            <mat-icon class="recurringIcon" *ngIf="item.isRecurring"
              >repeat</mat-icon
            >
          </div>
          <div class="flex justify-between">
            <div
              *ngIf="item.createInteraction"
              class="info"
              fxHide
              fxShow.gt-xs
            >
              <pr-interaction-summary
                [interaction]="item.createInteraction | unserialise"
                [hideIcon]="true"
              />
            </div>
            <div fxHide.gt-xs fxShow>
              <ng-container *ngTemplateOutlet="dueDate" />
            </div>
          </div>
        </div>
      </div>

      <div class="flex" fxLayoutAlign="end center">
        <div fxHide fxShow.gt-xs>
          <ng-container *ngTemplateOutlet="dueDate" />
        </div>

        <ng-template #dueDate>
          <div>
            <ng-container *ngIf="item.dueDate">
              <span
                *ngIf="item | map: hasDueTime : this; else noDueTime"
                class="due-date"
                [ngClass]="{ overdue: item | map: isLate : this }"
              >
                {{
                  item.dueDate
                    | map: toMoment
                    | moment
                    | amDateFormat: dateTimeFormat
                }}
              </span>
              <ng-template #noDueTime>
                <span
                  class="due-date"
                  [ngClass]="{ overdue: item | map: isLate : this }"
                >
                  {{
                    item.dueDate
                      | map: toMoment
                      | moment
                      | amDateFormat: dateFormat
                  }}
                </span>
              </ng-template>
            </ng-container>
          </div>
        </ng-template>

        <div class="flex" fxLayoutAlign="space-around center">
          <div class="complete-button" matTooltip>
            <button
              *ngIf="!(item | map: isComplete); else isIncomplete"
              mat-icon-button
              (click)="$event.stopPropagation(); markComplete()"
              matTooltip="Mark Complete"
            >
              <mat-icon>done</mat-icon>
            </button>
            <ng-template #isIncomplete>
              <button
                mat-icon-button
                (click)="$event.stopPropagation(); markIncomplete()"
                matTooltip="Mark Incomplete"
              >
                <mat-icon>undo</mat-icon>
              </button>
            </ng-template>
          </div>

          <button
            mat-icon-button
            [matMenuTriggerFor]="moreMenu"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_vertical</mat-icon>
          </button>
        </div>
      </div>

      <mat-menu #moreMenu="matMenu">
        <button mat-menu-item *ngIf="!item.deleted" (click)="editTask()">
          <mat-icon>edit</mat-icon>
          Edit
        </button>

        <button
          mat-menu-item
          *ngIf="!item.deleted && item.isRecurring"
          (click)="editRecurringTask()"
        >
          <mat-icon>repeat</mat-icon>
          Edit Recurrence
        </button>

        <button mat-menu-item *ngIf="!item.deleted" (click)="deleteTask()">
          <mat-icon color="warn">delete</mat-icon>
          Delete
        </button>

        <button mat-menu-item *ngIf="item.deleted" (click)="undeleteTask()">
          <mat-icon>undo</mat-icon>
          Undo Delete
        </button>
      </mat-menu>
    </div>
  </mat-list-item>
</ng-container>
