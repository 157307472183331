import { getAllPermissions } from '@principle-theorem/feature-flags';
import { difference } from 'lodash';
import {
  ALL_FEATURES,
  AppointmentPermissions,
  APPOINTMENTS_FEATURE,
  BrandPermissions,
  BRANDS_FEATURE,
  CALENDAR_FEATURE,
  CONTACTS_FEATURE,
  LABS_FEATURE,
  ORGANISATION_FEATURE,
  PATIENTS_FEATURE,
  PracticePermissions,
  PRACTICES_FEATURE,
  REPORTING_FEATURE,
  ReportingPermissions,
  STAFF_FEATURE,
  StafferPermissions,
} from '@principle-theorem/principle-core/features';
import { IRole } from '@principle-theorem/principle-core/interfaces';
import { initFirestoreModel } from '@principle-theorem/shared';

const PRINCIPLE_STAFF_PERMISSIONS = getAllPermissions(ALL_FEATURES).map(
  (permission) => permission.value
);

export const DENTAL_ASSISTANT_PERMISSIONS = difference(
  [
    ...getAllPermissions([
      APPOINTMENTS_FEATURE,
      CALENDAR_FEATURE,
      CONTACTS_FEATURE,
      LABS_FEATURE,
      PATIENTS_FEATURE,
      PRACTICES_FEATURE,
      REPORTING_FEATURE,
    ]).map((permission) => permission.value),
    StafferPermissions.RosterView,
    ReportingPermissions.ReportingReportOutstandingInvoices,
    ReportingPermissions.ReportingReportReconciliation,
    ReportingPermissions.ReportingReportAccountCredits,
    ReportingPermissions.ReportingReportPendingPayments,
  ],
  [
    AppointmentPermissions.AppointmentConduct,
    PracticePermissions.PracticeManage,
    PracticePermissions.PracticeConfigure,
  ]
);

export const PRACTITIONER_PERMISSIONS = [
  ...DENTAL_ASSISTANT_PERMISSIONS,
  AppointmentPermissions.AppointmentConduct,
  BrandPermissions.BrandConfigure,
  BrandPermissions.BrandConfigureGeneral,
  BrandPermissions.BrandConfigureClinical,
  BrandPermissions.BrandConfigureScheduling,
  BrandPermissions.BrandConfigureStaff,
  BrandPermissions.TagsManage,
  PracticePermissions.PracticeConfigure,
  ReportingPermissions.ReportingCustomReportView,
  ReportingPermissions.ReportingReportPatients,
];

export const ADMINISTRATOR_PERMISSIONS = difference(
  getAllPermissions([
    APPOINTMENTS_FEATURE,
    BRANDS_FEATURE,
    CALENDAR_FEATURE,
    CONTACTS_FEATURE,
    LABS_FEATURE,
    PATIENTS_FEATURE,
    PRACTICES_FEATURE,
    REPORTING_FEATURE,
    STAFF_FEATURE,
    ORGANISATION_FEATURE,
  ]).map((permission) => permission.value),
  [
    AppointmentPermissions.AppointmentConduct,
    ReportingPermissions.ReportingReportAcquisitions,
    ReportingPermissions.ReportingReportPatientFlow,
    ReportingPermissions.ReportingReportPractitioners,
    ReportingPermissions.ReportingReportPractitionersV2,
    ReportingPermissions.ReportingReportPractices,
    ReportingPermissions.ReportingReportDailyDashboard,
    ReportingPermissions.ReportingReportDemos,
  ]
);

export const ROLES: IRole[] = [
  { name: 'Principle Staff', permissions: PRINCIPLE_STAFF_PERMISSIONS },
  { name: 'Dental Assistant/FOC', permissions: DENTAL_ASSISTANT_PERMISSIONS },
  { name: 'Practitioner', permissions: PRACTITIONER_PERMISSIONS },
  { name: 'Administrator', permissions: ADMINISTRATOR_PERMISSIONS },
].map((role) => ({
  ...role,
  ...initFirestoreModel(),
}));
