import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgAuthModule } from '@principle-theorem/ng-auth';
import { NgEditorModule } from '@principle-theorem/ng-editor';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ComponentsModule } from '../../components/components.module';
import { RolesModule } from '../../components/roles/roles.module';
import { UsersModule } from '../../components/users/users.module';
import { CoreModule } from '../../core/core.module';
import { MigrationRunnerComponent } from './migration-runner/migration-runner.component';
import { OrgRoleResolver } from './org-role-resolver.service';
import { OrgUserEditComponent } from './org-user-edit/org-user-edit.component';
import { OrgUserResolver } from './org-user-resolver.service';
import { OrganisationAddDialogComponent } from './organisation-add-dialog/organisation-add-dialog.component';
import { OrganisationEditComponent } from './organisation-edit/organisation-edit.component';
import { OrganisationResolver } from './organisation-resolver.service';
import { OrganisationsComponent } from './organisations/organisations.component';
import { RoleEditComponent } from './role-edit/role-edit.component';
import { RoleListComponent } from './role-list/role-list.component';
import { ToolCardComponent } from './tools/tool-card/tool-card.component';
import { ToolsComponent } from './tools/tools.component';
import { UserListComponent } from './user-list/user-list.component';
import { SeedDemoSpaceComponent } from './seed-demo-space/seed-demo-space.component';

@NgModule({
  declarations: [
    OrganisationsComponent,
    OrganisationAddDialogComponent,
    OrganisationEditComponent,
    OrgUserEditComponent,
    ToolsComponent,
    ToolCardComponent,
    MigrationRunnerComponent,
    RoleEditComponent,
    RoleListComponent,
    UserListComponent,
  ],
  imports: [
    NgSharedModule,
    NgMaterialModule,
    ComponentsModule,
    ReactiveFormsModule,
    NgAuthModule,
    NgMaterialModule,
    CoreModule,
    NgEditorModule,
    NgPrincipleSharedModule,
    UsersModule,
    RolesModule,
    RouterModule,
    SeedDemoSpaceComponent,
  ],
  providers: [OrganisationResolver, OrgUserResolver, OrgRoleResolver],
})
export class ManagementPagesModule {}
