import { ChartElement, type IChartElement } from './chart-element';
import { ChartQuadrant, type IChartQuadrant } from './chart-quadrant';
import { isUpperQuadrant } from '@principle-theorem/principle-core';

export interface IChartRow extends IChartElement {
  quadrants: IChartQuadrant[];
}

export class ChartRow extends ChartElement {
  constructor(public quadrants: IChartQuadrant[] = []) {
    super();
    this.width = ChartElement.getTotalWidth(quadrants);
    this.height = ChartElement.getLargestHeight(quadrants);
    this.yFlipped = ChartRow.isUpperArch(this);
  }

  static isEmpty(row: IChartRow): boolean {
    return row.quadrants.every((quadrant: IChartQuadrant) =>
      ChartQuadrant.isEmpty(quadrant)
    );
  }

  static isUpperArch(row: IChartRow): boolean {
    return row.quadrants.every((quadrant: IChartQuadrant): boolean =>
      isUpperQuadrant(quadrant.quadrant)
    );
  }

  override toInterface(): IChartRow {
    return {
      ...super.toInterface(),
      quadrants: this.quadrants,
    };
  }
}
