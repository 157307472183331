<ng-container *ngrxLet="patient$ as patient">
  <h2>Patient Details</h2>
  <div fxLayout="column" fxLayoutGap="6px">
    <div><strong>Name: </strong>{{ patient.name }}</div>
    <pr-patient-age [patient]="patient" />
    <pr-patient-date-of-birth
      [dateOfBirth]="patient.dateOfBirth"
     />
    <pr-patient-gender [gender]="patient.gender" />
    <pr-patient-phone
      [contactNumbers]="patient.contactNumbers"
     />
    <pr-patient-address [address]="patient.address" />
    <pr-patient-last-visit [patient]="patient" />
  </div>
</ng-container>
