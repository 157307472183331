import { IBaseChartBuilderConfig } from '@principle-theorem/principle-core/interfaces';
import { type IChartConfig } from '@principle-theorem/reporting';
import { isObject } from '@principle-theorem/shared';
import * as dc from 'dc';
import { first, get } from 'lodash';
import { type Subject } from 'rxjs';
import { type IDCTransformResult } from '../../../../models/report/charts/data-transformers/dc-data-transformer';
import { DataPointValueFormatter } from '../../data-point-value-formatter';
import {
  D3_CHART_ELEM_SELECTOR,
  DEFAULT_CHART_BUILDER_CONFIG,
  applyBaseConfigToChart,
  ChartBuildHelpers,
  type IDCChartBuilder,
  type IDCChartEvent,
} from './dc-chart-builder';
import { DCTooltip, type IDCTooltip } from './dc-tooltip';

export class DcPieChartBuilder implements IDCChartBuilder<dc.PieChart> {
  constructor(
    private _config: IBaseChartBuilderConfig = DEFAULT_CHART_BUILDER_CONFIG
  ) {}

  getLabel(config: IChartConfig): string {
    return config.labels.title;
  }

  getChart(
    results: IDCTransformResult[],
    config: IChartConfig,
    eventListener$?: Subject<IDCChartEvent>
  ): dc.BaseMixin<dc.PieChart> | undefined {
    const result = first(results);
    const measure = first(config.builderData.measures);
    if (!result || !measure) {
      return;
    }

    const chart = this.build(D3_CHART_ELEM_SELECTOR, result, config);

    applyBaseConfigToChart(this._config, chart);

    chart.label((data: unknown) =>
      String(get(data, 'key.label', get(data, 'key', '')))
    );

    if (config.builderData.groupLimit) {
      chart.cap(config.builderData.groupLimit);
    }

    if (config.builderData.otherGroupLabel) {
      chart.othersLabel(config.builderData.otherGroupLabel);
    }

    if (eventListener$) {
      chart.on('filtered', (eventChart) =>
        ChartBuildHelpers.onFilteredEvent(eventChart, config, eventListener$)
      );
    }

    return chart;
  }

  build(
    parent: string,
    result: IDCTransformResult,
    config: IChartConfig
  ): dc.PieChart {
    if (config.builderData.measures[0].filters?.length) {
      const { dimension, group } = ChartBuildHelpers.buildFilteredDimension(
        result,
        config,
        config.builderData.measures[0]
      );

      return dc.pieChart(parent).dimension(dimension).group(group);
    }

    return dc.pieChart(parent).dimension(result.dimension).group(result.group);
  }

  getLegend(): dc.HtmlLegend {
    const legend = dc.htmlLegend().highlightSelected('true');

    // const legend = dc.legend().horizontal(false).x(100).y(100);

    // .highlightSelected('true');
    legend.legendText((data: unknown) => String(get(data, 'name.label', '')));
    return legend;
  }

  getTooltip(config: IChartConfig): IDCTooltip {
    const measure = first(config.builderData.measures);
    const format = DataPointValueFormatter.getFormatter(
      measure?.measure.metadata.formatter,
      measure?.measure.metadata.formatterValue
    );
    return new DCTooltip(
      '.pie-slice',
      (data: unknown) => String(get(data, 'data.key.label', '')),
      (data: unknown) => format(this._getValue(data))
    );
  }

  private _getValue(data: unknown): string {
    const value = get(data, 'value', '') as unknown;
    if (isObject(value)) {
      return String(value.valueOf());
    }
    return String(value);
  }
}
