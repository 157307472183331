import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostListener,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ConfirmDialogComponent,
  DialogPresets,
  IConfirmationDialogInput,
  NgSharedModule,
  confirmationDialogData,
  getImageURL$,
} from '@principle-theorem/ng-shared';
import { Storage } from '@angular/fire/storage';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { map, switchMap } from 'rxjs/operators';
import {
  WithRef,
  deleteDoc,
  filterUndefined,
  isSameRef,
} from '@principle-theorem/shared';
import { IMedia } from '@principle-theorem/principle-core/interfaces';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

export interface IViewImageDialogData {
  media: WithRef<IMedia>[];
  initialIndex?: number;
}

export interface IViewImageDialogResponse {
  deletedMedia: WithRef<IMedia>[];
}

@Component({
    selector: 'pr-view-image-dialog',
    imports: [CommonModule, NgMaterialModule, NgSharedModule],
    templateUrl: './view-image-dialog.component.html',
    styleUrl: './view-image-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewImageDialogComponent {
  private _storage = inject(Storage);
  private _dialog = inject(MatDialog);
  private _data = inject<IViewImageDialogData>(MAT_DIALOG_DATA);
  private _currentIndex = signal<number>(this._data.initialIndex ?? 0);
  private _media = signal<WithRef<IMedia>[]>(this._data.media);
  private _deletedMedia = signal<WithRef<IMedia>[]>([]);
  private _dialogRef = inject(MatDialogRef);
  totalImages = computed(() => this._media().length);
  currentImage = computed(() => this._currentIndex() + 1);
  isFirstImage = computed(() => this._currentIndex() === 0);
  isLastImage = computed(() => this._currentIndex() === this.totalImages() - 1);
  showNavigation = computed(() => this.totalImages() > 1);
  currentImageURL = toSignal(
    combineLatest([
      toObservable(this._media),
      toObservable(this._currentIndex),
    ]).pipe(
      map(([media, index]) => media[index]),
      filterUndefined(),
      switchMap((media) => getImageURL$(this._storage, media))
    )
  );

  nextImage(): void {
    if (this._currentIndex() < this.totalImages() - 1) {
      this._currentIndex.update((index) => index + 1);
    }
  }

  previousImage(): void {
    if (this._currentIndex() > 0) {
      this._currentIndex.update((index) => index - 1);
    }
  }

  async deleteImage(): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Image',
      prompt: 'Are you sure you want to delete this image?',
      submitLabel: 'Delete Image',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }

    const currentMedia = this._media();
    const currentIndex = this._currentIndex();
    const mediaToDelete = currentMedia[currentIndex];
    if (!mediaToDelete) {
      return;
    }

    const updatedMedia = currentMedia.filter(
      (media) => !isSameRef(media, mediaToDelete)
    );
    const newIndex = Math.min(currentIndex, updatedMedia.length - 1);

    this._currentIndex.set(newIndex);
    this._media.set(updatedMedia);
    this._deletedMedia.update((deletedMedia) => [
      ...deletedMedia,
      mediaToDelete,
    ]);

    await deleteDoc(mediaToDelete.ref);

    if (!updatedMedia.length) {
      this._dialogRef.close({ deletedMedia: this._deletedMedia() });
    }
  }

  @HostListener('document:keydown.ArrowRight')
  handleRightArrow(): void {
    this.nextImage();
  }

  @HostListener('document:keydown.ArrowLeft')
  handleLeftArrow(): void {
    this.previousImage();
  }
}
