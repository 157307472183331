import { Injectable } from '@angular/core';
import { type IReffable } from '@principle-theorem/shared';
import {
  type IRoutable,
  StateBasedNavigationService,
  type IContextualAction,
} from '@principle-theorem/ng-principle-shared';

@Injectable({
  providedIn: 'root',
})
export class LoadTaskActionService implements IContextualAction {
  icon = 'open_in_new';
  name = 'View Task';

  constructor(private _stateNav: StateBasedNavigationService) {}

  async do(task: IRoutable | IReffable): Promise<void> {
    const uid: string = 'uid' in task ? task.uid : task.ref.id;
    await this._stateNav.practice(['tasks', 'list'], {
      queryParams: { task: uid },
    });
  }
}
