import { union } from 'lodash';

export interface IChartableItem {
  availableSurfaces: ChartableSurface[];
}

export enum ChartableSurface {
  Facial = 'facial',
  Lingual = 'lingual',
  Mesial = 'mesial',
  Distal = 'distal',
  Occlusal = 'occlusal',
  WholeTooth = 'wholeTooth',
  WholeMouth = 'wholeMouth',
  Arch = 'arch',
  Quadrant = 'quadrant',
  MultipleTeeth = 'multipleTeeth',
  Unscoped = 'unscoped',
  Crown = 'crown',
}

export const AREA_SURFACES: ChartableSurface[] = [
  ChartableSurface.Crown,
  ChartableSurface.WholeTooth,
  ChartableSurface.WholeMouth,
  ChartableSurface.Arch,
  ChartableSurface.Quadrant,
  ChartableSurface.MultipleTeeth,
];

export function isAreaSurface(surface: ChartableSurface): boolean {
  return AREA_SURFACES.includes(surface);
}

export const TOOTH_SURFACES: ChartableSurface[] = [
  ChartableSurface.Facial,
  ChartableSurface.Mesial,
  ChartableSurface.Occlusal,
  ChartableSurface.Distal,
  ChartableSurface.Lingual,
];

export function isToothSurface(
  surface: ChartableSurface
): surface is ToothSurface {
  return TOOTH_SURFACES.includes(surface);
}

export const CHARTABLE_SURFACES: ChartableSurface[] = [
  ...TOOTH_SURFACES,
  ...AREA_SURFACES,
];

export function combineCompatibleToothSurfaces(
  surfaces: ChartableSurface[]
): ChartableSurface[] {
  if (surfaces.includes(ChartableSurface.WholeTooth)) {
    return union(surfaces, [...TOOTH_SURFACES, ChartableSurface.Crown]);
  }
  if (surfaces.includes(ChartableSurface.Crown)) {
    return union(surfaces, [ChartableSurface.WholeTooth]);
  }
  return surfaces;
}

export type ToothSurface =
  | ChartableSurface.Facial
  | ChartableSurface.Mesial
  | ChartableSurface.Occlusal
  | ChartableSurface.Distal
  | ChartableSurface.Lingual;

export const TOOTH_SURFACE_SORT_MAP: Record<ToothSurface, number> = {
  [ChartableSurface.Facial]: 1,
  [ChartableSurface.Mesial]: 2,
  [ChartableSurface.Occlusal]: 3,
  [ChartableSurface.Distal]: 4,
  [ChartableSurface.Lingual]: 5,
};

export function toothSurfaceSort(
  surfaceA: ChartableSurface,
  surfaceB: ChartableSurface
): number {
  if (
    !TOOTH_SURFACE_SORT_MAP[surfaceA as ToothSurface] ||
    !TOOTH_SURFACE_SORT_MAP[surfaceB as ToothSurface]
  ) {
    return 0;
  }
  const surfaceMapA: number = TOOTH_SURFACE_SORT_MAP[surfaceA as ToothSurface];
  const surfaceMapB: number = TOOTH_SURFACE_SORT_MAP[surfaceB as ToothSurface];

  return surfaceMapA > surfaceMapB ? 1 : -1;
}
