import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FeeScheduleFacade,
  TreatmentPlanFacade,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  OrganisationService,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import {
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogData,
  type IConfirmationDialogInput,
  TypedFormControl,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import {
  ChartedItemTotalCalculator,
  ChartedTreatment,
  FeeScheduleManager,
  TreatmentPlan,
  TreatmentStep,
  getDefaultFeeSchedule,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  type IFeeSchedule,
  type IFeeScheduleGroup,
  type IPatient,
  type IStaffer,
  type ITreatmentPlan,
  TreatmentPlanStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  type WithRef,
  asyncForEach,
  deleteDoc,
  filterNil,
  filterUndefined,
  getDoc,
  isSameRef,
  multiFilter,
  patchDoc,
  shareReplayCold,
  snapshot,
  toNamedDocument,
} from '@principle-theorem/shared';
import { Observable, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { UpdateChartable } from '../../../add-chartable';
import { TreatmentProviderService } from '../../../treatment-provider.service';
import { Validators } from '@angular/forms';
import { type TreatmentUpdateFn } from '../../../charted-surface/add-charted-surface-provider';
import { TreatmentStepsDisplayService } from '../treatment-steps-display.service';

@Component({
  selector: 'pr-treatment-plan-editor-header',
  templateUrl: './treatment-plan-editor-header.component.html',
  styleUrls: ['./treatment-plan-editor-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentPlanEditorHeaderComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  treatmentPlan$ = new ReplaySubject<WithRef<ITreatmentPlan>>(1);
  price$: Observable<number>;
  groups$: Observable<IFeeScheduleGroup[]>;
  patient$: Observable<WithRef<IPatient>>;
  duplicating$ = new ReplaySubject<boolean>(1);
  nameControl = new TypedFormControl<string>(undefined, {
    updateOn: 'blur',
    validators: Validators.required,
  });
  defaultSchedule$ = new Observable<WithRef<IFeeSchedule>>();

  @Output() setFeeSchedule = new EventEmitter<WithRef<IFeeSchedule>>();

  @Input()
  set plan(plan: WithRef<ITreatmentPlan>) {
    if (plan) {
      this.treatmentPlan$.next(plan);
    }
  }

  constructor(
    private _treatmentPlanFacade: TreatmentPlanFacade,
    private _feeScheduleFacade: FeeScheduleFacade,
    private _treatmentProvider: TreatmentProviderService,
    private _stateNav: StateBasedNavigationService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _route: ActivatedRoute,
    public stepDisplay: TreatmentStepsDisplayService,
    public organisation: OrganisationService
  ) {
    this.groups$ =
      this._feeScheduleFacade.feeScheduleGroups$.pipe(shareReplayCold());
    this.patient$ = this.treatmentPlan$.pipe(
      switchMap((plan) => TreatmentPlan.patient$(plan))
    );

    this.defaultSchedule$ = this.treatmentPlan$.pipe(
      switchMap((plan) =>
        plan.feeSchedule
          ? getDoc(plan.feeSchedule.ref)
          : combineLatest([this.patient$, this.groups$]).pipe(
              switchMap(([patient, groups]) =>
                getDefaultFeeSchedule(groups, patient)
              ),
              filterUndefined()
            )
      )
    );

    this.defaultSchedule$
      .pipe(distinctUntilChanged(), takeUntil(this._onDestroy$))
      .subscribe((schedule) => this.setFeeSchedule.emit(schedule));

    this.treatmentPlan$
      .pipe(distinctUntilKeyChanged('name'), takeUntil(this._onDestroy$))
      .subscribe((plan) =>
        this.nameControl.patchValue(plan.name, { emitEvent: false })
      );

    this.price$ = this.treatmentPlan$.pipe(
      switchMap((plan) =>
        this._treatmentPlanFacade.getTreatmentSteps$(plan.ref)
      ),
      map((steps) => new ChartedItemTotalCalculator().multiStep({ steps }))
    );

    this.nameControl.valueChanges
      .pipe(
        filter((name) => name !== ''),
        filterNil(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((name) => void this.patchPlanName(name));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async patchPlanPractitioner(staffer?: WithRef<IStaffer>): Promise<void> {
    const plan = await snapshot(this.treatmentPlan$);
    await patchDoc(plan.ref, {
      practitioner: staffer ? stafferToNamedDoc(staffer) : undefined,
    });
  }

  async patchPlanName(name: string): Promise<void> {
    const plan = await snapshot(this.treatmentPlan$);
    await patchDoc(plan.ref, { name });
  }

  async duplicate(plan: WithRef<ITreatmentPlan>): Promise<void> {
    this.duplicating$.next(true);
    const clonedPlan = await TreatmentPlan.clone(plan);
    this._treatmentPlanFacade.selectTreatmentPlan(clonedPlan.ref);
    this.duplicating$.next(false);
  }

  canDecline(plan: ITreatmentPlan): boolean {
    return TreatmentPlan.canDecline(plan);
  }

  canAccept(plan: ITreatmentPlan): boolean {
    return TreatmentPlan.canAccept(plan);
  }

  canDelete$(plan: WithRef<ITreatmentPlan>): Observable<boolean> {
    return TreatmentPlan.canDelete$(plan);
  }

  async acceptPlan(plan: WithRef<ITreatmentPlan>): Promise<void> {
    await TreatmentPlan.patchPlanStatus(plan, TreatmentPlanStatus.Accepted);
  }

  async declinePlan(plan: WithRef<ITreatmentPlan>): Promise<void> {
    await TreatmentPlan.patchPlanStatus(plan, TreatmentPlanStatus.Declined);
  }

  async deletePlan(plan: WithRef<ITreatmentPlan>): Promise<void> {
    const data: IConfirmationDialogData = confirmationDialogData({
      title: 'Delete Treatment Plan',
      prompt: `Are you sure you want to delete the treatment plan "${plan.name}"?`,
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const deleteConfirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!deleteConfirmed) {
      return;
    }

    await deleteDoc(plan.ref);
    await this._router.navigate(['../'], { relativeTo: this._route });
    this._snackBar.open('Treatment Plan Deleted');
  }

  async print(plan: WithRef<ITreatmentPlan>): Promise<void> {
    const patient = await snapshot(this.patient$);

    await this._stateNav.brand([
      'patients',
      patient.ref.id,
      'treatment-plans',
      plan.ref.id,
      'print',
    ]);
  }

  async changeSchedule(
    schedule: WithRef<IFeeSchedule>,
    plan: WithRef<ITreatmentPlan>
  ): Promise<void> {
    const data = confirmationDialogData({
      title: 'Change Fee Schedule',
      prompt:
        'Apply selected fee schedule to treatment plan and all associated treatments?',
      submitLabel: 'Apply to All',
      submitColor: 'primary',
    });

    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!confirmed) {
      return;
    }

    const incompleteSteps = await snapshot(
      TreatmentPlan.treatmentSteps$(plan).pipe(
        multiFilter((step) => !TreatmentStep.isComplete(step))
      )
    );

    const updateFn: TreatmentUpdateFn = async (treatment, stepTreatments) =>
      ChartedTreatment.applyFeeSchedule(
        treatment,
        stepTreatments,
        new FeeScheduleManager(of(schedule)),
        true,
        true
      );

    await asyncForEach(incompleteSteps, async (step) => {
      const updateProvider = await this._treatmentProvider.getProvider(
        plan,
        step
      );
      await new UpdateChartable().update(
        step.treatments.filter(
          (treatment) => !isSameRef(treatment.feeSchedule, schedule)
        ),
        step.treatments,
        updateFn,
        updateProvider
      );
    });

    this.setFeeSchedule.emit(schedule);
    await patchDoc<ITreatmentPlan>(plan.ref, {
      feeSchedule: toNamedDocument(schedule),
    });
    this._snackBar.open('Fee Schedule Applied');
  }
}
