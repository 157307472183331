import { randBoolean, randSentence } from '@ngneat/falso';
import {
  initVersionedSchema,
  VersionedSchema,
} from '@principle-theorem/editor';
import { IFollowUp } from '@principle-theorem/principle-core/interfaces';
import { BaseMock, MockTimestamp, Properties } from '@principle-theorem/shared';
import { Timestamp } from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';

export class FollowUpMock extends BaseMock implements Properties<IFollowUp> {
  get uid(): string {
    return uuid();
  }

  get createFollowUp(): boolean {
    return randBoolean();
  }

  get followUpDate(): Timestamp {
    return MockTimestamp();
  }

  get noFollowUpReason(): VersionedSchema {
    return initVersionedSchema(randSentence());
  }

  get createdAt(): Timestamp {
    return MockTimestamp();
  }
}
