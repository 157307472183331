import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import {
  IChatMessage,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  WithRef,
  asyncForEach,
  snapshot,
} from '@principle-theorem/shared';
import { groupBy } from 'lodash';
import { Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

interface IEmojiSummary {
  emoji: string;
  count: number;
  staff: string;
}

@Component({
    selector: 'pr-chat-message-reactions',
    templateUrl: './chat-message-reactions.component.html',
    styleUrl: './chat-message-reactions.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChatMessageReactionsComponent {
  private _reactions$ = new ReplaySubject<WithRef<IChatMessage['reactions']>>(
    1
  );
  reactions$: Observable<IEmojiSummary[]>;

  @Input()
  set reactions(reactions: WithRef<IChatMessage['reactions']>) {
    if (reactions) {
      this._reactions$.next(reactions);
    }
  }

  constructor(private _globalStore: GlobalStoreService) {
    this.reactions$ = this._reactions$.pipe(
      switchMap(async (reactions) =>
        getGroupedReactions(reactions, (ref) =>
          snapshot(this._globalStore.getStafferName$({ ref }))
        )
      )
    );
  }
}

async function getGroupedReactions(
  reactions: WithRef<IChatMessage['reactions']>,
  getStafferNameFn: (ref: DocumentReference<IStaffer>) => Promise<string>
): Promise<IEmojiSummary[]> {
  const groupedReactions = groupBy(reactions, 'emoji');
  return asyncForEach(
    Object.entries(groupedReactions),
    async ([emoji, groupReactions]) => {
      const staffNames = await asyncForEach(groupReactions, (reaction) =>
        getStafferNameFn(reaction.authorRef)
      );
      return {
        emoji,
        count: staffNames.length,
        staff: staffNames.join(', '),
      };
    }
  );
}
