import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Editor } from '@tiptap/core';
import { ImageCommandsService } from '../image-commands.service';

export interface IImageEditMenuData {
  editor: Editor;
}

@Component({
  selector: 'pt-image-edit-menu',
  templateUrl: './image-edit-menu.component.html',
  styleUrls: ['./image-edit-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageEditMenuComponent {
  constructor(
    @Inject(DIALOG_DATA) public data: IImageEditMenuData,
    public imageCommands: ImageCommandsService
  ) {}
}
