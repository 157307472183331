import {
  CustomFormContainerElement,
  CustomFormElementType,
  CustomFormSectionElement,
  CustomFormContentElement,
  CustomFormFieldElement,
  AnyCustomFormElement,
} from '@principle-theorem/principle-core/interfaces';
import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export class TypeNarrowedCustomFormElement {
  container$: Observable<CustomFormContainerElement | undefined>;
  section$: Observable<CustomFormSectionElement | undefined>;
  content$: Observable<CustomFormContentElement | undefined>;
  text$: Observable<CustomFormFieldElement | undefined>;
  textarea$: Observable<CustomFormFieldElement | undefined>;
  number$: Observable<CustomFormFieldElement | undefined>;
  checkbox$: Observable<CustomFormFieldElement | undefined>;
  date$: Observable<CustomFormFieldElement | undefined>;
  dropdown$: Observable<CustomFormFieldElement | undefined>;
  signature$: Observable<CustomFormFieldElement | undefined>;

  constructor(private _element$: Observable<AnyCustomFormElement>) {
    this.container$ = this._element$.pipe(
      this._asType<CustomFormContainerElement>(CustomFormElementType.Container)
    );
    this.section$ = this._element$.pipe(
      this._asType<CustomFormSectionElement>(CustomFormElementType.Section)
    );
    this.content$ = this._element$.pipe(
      this._asType<CustomFormContentElement>(CustomFormElementType.Content)
    );
    this.text$ = this._element$.pipe(
      this._asType<CustomFormFieldElement>(CustomFormElementType.Text)
    );
    this.textarea$ = this._element$.pipe(
      this._asType<CustomFormFieldElement>(CustomFormElementType.Textarea)
    );
    this.number$ = this._element$.pipe(
      this._asType<CustomFormFieldElement>(CustomFormElementType.Number)
    );
    this.checkbox$ = this._element$.pipe(
      this._asType<CustomFormFieldElement>(CustomFormElementType.Checkbox)
    );
    this.date$ = this._element$.pipe(
      this._asType<CustomFormFieldElement>(CustomFormElementType.Date)
    );
    this.dropdown$ = this._element$.pipe(
      this._asType<CustomFormFieldElement>(CustomFormElementType.Dropdown)
    );
    this.signature$ = this._element$.pipe(
      this._asType<CustomFormFieldElement>(CustomFormElementType.Signature)
    );
  }

  private _asType<T>(
    type: CustomFormElementType
  ): OperatorFunction<AnyCustomFormElement, T | undefined> {
    return map((element) =>
      element.type === type ? (element as T) : undefined
    );
  }
}
