import { IMeasure } from './measure-properties';

export interface IMeasureFilter {
  value(measure: Pick<IMeasure, 'propertyName'>): string;
}

export class BooleanMeasureFilter implements IMeasureFilter {
  constructor(public filterValue: boolean) {}

  value(measure: Pick<IMeasure, 'propertyName'>): string {
    return `${measure.propertyName} IS ${
      this.filterValue === true ? 'TRUE' : 'FALSE'
    }`;
  }
}

export class NullMeasureFilter implements IMeasureFilter {
  constructor(public filterValue: boolean) {}

  value(measure: Pick<IMeasure, 'propertyName'>): string {
    return `${measure.propertyName} IS ${
      this.filterValue === true ? 'NULL' : 'NOT NULL'
    }`;
  }
}

export class LessThanMeasureFilter implements IMeasureFilter {
  private _comparitor: string;
  private _quote = '';

  constructor(public filterValue: number | string, orEqualTo: boolean = false) {
    if (typeof this.filterValue === 'string') {
      this._quote = '"';
    }
    this._comparitor = orEqualTo ? '<=' : '<';
  }

  value(measure: Pick<IMeasure, 'propertyName'>): string {
    return `${this._quote}${measure.propertyName}${this._quote} ${this._comparitor} ${this._quote}${this.filterValue}${this._quote}`;
  }
}

export class GreaterThanMeasureFilter implements IMeasureFilter {
  private _comparitor: string;
  private _quote = '';

  constructor(public filterValue: number | string, orEqualTo: boolean = false) {
    if (typeof this.filterValue === 'string') {
      this._quote = '"';
    }
    this._comparitor = orEqualTo ? '>=' : '>';
  }

  value(measure: Pick<IMeasure, 'propertyName'>): string {
    return `${this._quote}${measure.propertyName}${this._quote} ${this._comparitor} ${this._quote}${this.filterValue}${this._quote}`;
  }
}

export class ValueEqualsMeasureFilter implements IMeasureFilter {
  public filterValue: string[];
  constructor(filterValue: string | string[]) {
    if (!Array.isArray(filterValue)) {
      filterValue = [filterValue];
    }
    this.filterValue = filterValue;
  }

  value(measure: Pick<IMeasure, 'propertyName'>): string {
    // TODO: Fix SQL injection attack risk
    const filters: string[] = this.filterValue.map((filterValue: string) => {
      return `UPPER(${measure.propertyName}) = UPPER("${filterValue}")`;
    });
    return `(${filters.join(' OR ')})`;
  }
}

export class ValueDoesNotEqualMeasureFilter implements IMeasureFilter {
  public filterValue: string[];
  constructor(filterValue: string | string[]) {
    if (!Array.isArray(filterValue)) {
      filterValue = [filterValue];
    }
    this.filterValue = filterValue;
  }

  value(measure: Pick<IMeasure, 'propertyName'>): string {
    // TODO: Fix SQL injection attack risk
    const filters: string[] = this.filterValue.map((filterValue: string) => {
      return `UPPER(${measure.propertyName}) != UPPER("${filterValue}")`;
    });
    return `(${filters.join(' OR ')})`;
  }
}
