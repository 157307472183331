import {
  getSchemaText,
  initVersionedSchema,
  toTextContent,
} from '@principle-theorem/editor';
import {
  IGeneratedTaskConfiguration,
  TaskPriority,
  TimingDirection,
  TimingUnit,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference } from '@principle-theorem/shared';
import {
  AtLeast,
  initFirestoreModel,
  WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';

export const enum DefaultGeneratedTaskConfiguration {
  NextDayCourtesyCall = 'Next day courtesy call and/or SMS',
  NextDayCourtesyCallPostOperativeDiscomfort = 'Next day courtesy call and/or SMS (Post-operative discomfort)',
  NextDayCourtesyCallSensitivity = 'Next day courtesy call and/or SMS (Sensitivity)',
  NextDayCourtesyCallImplant = 'Next day courtesy call and/or SMS (Implant)',
  NextDayCourtesyCallInvisalign = 'Next day courtesy call and/or SMS (Invisalign)',
  NextDayCourtesyCallCrownPrep = 'Next day courtesy call and/or SMS (Crown prep)',
  NextDayCourtesyCallExtraction = 'Next day courtesy call and/or SMS (Extraction)',
  NextDayCourtesyCallPeriodontal = 'Next day courtesy call and/or SMS (Periodontal)',
  NextDayCourtesyCallBleaching = 'Next day courtesy call and/or SMS (Bleaching)',
  NextDayCourtesyCallTeethPrep = 'Next day courtesy call and/or SMS (TeethPrep)',
  NextDayCourtesyCallSoftTissueGraft = 'Next day courtesy call and/or SMS (SoftTissueGraft)',
  TwoDayCheckUpAligner = 'Follow up courtesy call and/or SMS 2 days after issuing aligners',
  OneWeekCheckUpMouthGuard = 'Courtesy call 1 week after issuing mouthguard',
  OneWeekCheckUpSplit = 'Courtesy call 1 week after splint issue',
  OneWeekCheckUpWhitening = 'Courtesy call 1 weeks post teeth whitening kit issue',

  SendBeforeAndAfterPhotos = 'Send pt before and after photos',
  SendLabJobAndPrescription = 'Send lab job and prescription',
  SendLabJobPrescriptionAndPhotos = 'Send lab job, prescription and photos',
  SendDigitalIOScanToLab = 'Ensure digital IO scan is sent/saved to correct location/lab',
  SendLabJobAndPrescriptionForSplit = 'Send lab job and prescription for splint',
  SendFollowUpEmailForWorkup = 'Send follow up email if not pt booked for work up',

  OrderImplantComponents = 'Implant components ordered/reserved for patient',
  OrderImplantComponents2 = 'Select, order and reserve implant componentry for patient case',
  OrderImplantComponents3 = 'Ensure implant components/healing abutment is ordered/reserved for patient',
  OrderRetainers = 'Order retainers',

  MandibularAdvancementSplintOneWeekCheckUp = 'Courtesy call 1 week after issuing mandibular advancement splint',
  InvisalignSubmitPrescription = 'Submit prescription on Invisalign Doctor Site',
  EnsureTreatmentPlanProvided = 'Ensure treatment plan is provided to patient',
  ApproveClincheck = 'Approve Clincheck if treatment accepted, consent form signed and payment arranged',
  FabricateWhiteningTrays = 'Fabricate whitening trays from impressions',
  ContactPatientForWhiteningKitPickUp = 'Contact patient when whitening kit is ready for pick up',
  GenerateTreatmentPlan = 'Generate treatment plan for patient from work up',
}

const DEFAULT_GENERATED_TASKS: AtLeast<
  IGeneratedTaskConfiguration,
  'title' | 'comment' | 'timing'
>[] = [
  {
    title: [
      toTextContent(DefaultGeneratedTaskConfiguration.NextDayCourtesyCall),
    ],
    comment: initVersionedSchema('Check clinical notes for details.'),
    timing: {
      unit: TimingUnit.Days,
      direction: TimingDirection.After,
      amount: 1,
    },
  },
  {
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallPostOperativeDiscomfort
      ),
    ],
    comment: initVersionedSchema(
      'Check clinical notes for details. Post operative discomfort is normal after GTR/RCT. Ensure patient is following post operative instructions and taking medications as prescribed. Sutures may cause irritation.'
    ),
    timing: {
      unit: TimingUnit.Days,
      direction: TimingDirection.After,
      amount: 1,
    },
  },
  {
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.EnsureTreatmentPlanProvided
      ),
    ],
    comment: initVersionedSchema(),
    timing: {
      direction: TimingDirection.After,
      amount: 1,
      unit: TimingUnit.Days,
    },
  },
  {
    title: [
      toTextContent(DefaultGeneratedTaskConfiguration.TwoDayCheckUpAligner),
    ],
    comment: initVersionedSchema(
      'Aligners can cause temporary discomfort in the first few days. Teeth may feel tender, gums irritated, ulcers may develop and lisping is common. Reassure the patient that most symptoms pass over the next few days. Encourage use of chewies and adherence to the 22hr/day protocol.'
    ),
    timing: {
      unit: TimingUnit.Days,
      amount: 2,
      direction: TimingDirection.After,
    },
  },
  {
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.MandibularAdvancementSplintOneWeekCheckUp
      ),
    ],
    comment: initVersionedSchema(
      'Normal take a few weeks to get used to the MAS. The bite may feel different in the morning after use. Reassure that it resolves throughout the day. Is the fit ok? Are they still snoring? Is a review required? Check if any upcoming appointments.'
    ),
    timing: {
      unit: TimingUnit.Weeks,
      amount: 1,
      direction: TimingDirection.After,
    },
  },
  {
    title: [
      toTextContent(DefaultGeneratedTaskConfiguration.OneWeekCheckUpMouthGuard),
    ],
    comment: initVersionedSchema(
      'How is the comfort and fit of the mouthguard?'
    ),
    timing: {
      direction: TimingDirection.After,
      unit: TimingUnit.Weeks,
      amount: 1,
    },
  },
  {
    title: [
      toTextContent(DefaultGeneratedTaskConfiguration.SendBeforeAndAfterPhotos),
    ],
    comment: initVersionedSchema(),
    timing: {
      amount: 1,
      direction: TimingDirection.After,
      unit: TimingUnit.Days,
    },
  },
  {
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.InvisalignSubmitPrescription
      ),
    ],
    comment: initVersionedSchema(),
    timing: {
      direction: TimingDirection.Immediately,
      amount: 1,
      unit: TimingUnit.Days,
    },
    priority: TaskPriority.Medium,
  },
  {
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.SendLabJobPrescriptionAndPhotos
      ),
    ],
    comment: initVersionedSchema(),
    timing: {
      direction: TimingDirection.Immediately,
      amount: 1,
      unit: TimingUnit.Days,
    },
  },
  {
    title: [toTextContent(DefaultGeneratedTaskConfiguration.ApproveClincheck)],
    comment: initVersionedSchema(),
    timing: {
      amount: 1,
      unit: TimingUnit.Days,
      direction: TimingDirection.Immediately,
    },
  },
  {
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallSensitivity
      ),
    ],
    comment: initVersionedSchema('Has the sensitivity reduced?'),
    timing: {
      direction: TimingDirection.After,
      unit: TimingUnit.Days,
      amount: 1,
    },
  },
  {
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallImplant
      ),
    ],
    comment: initVersionedSchema(
      'Check clinical notes for details. Post operative discomfort is normal after implant placement. Ensure patient is following post operative instructions and taking medications as prescribed. Sutures may cause irritation. Advise caution around surgical site and use of a soft toothbrush.'
    ),
    timing: {
      direction: TimingDirection.After,
      amount: 1,
      unit: TimingUnit.Days,
    },
  },
  {
    title: [
      toTextContent(DefaultGeneratedTaskConfiguration.OrderImplantComponents),
    ],
    comment: initVersionedSchema(
      'Check components: 1) implant fixture, 2) cover screw, 3) healing abutment, 4) surgical kit, 5) surgical guide if nec 6) immediate temporary crown'
    ),
    timing: {
      direction: TimingDirection.Before,
      unit: TimingUnit.Days,
      amount: 5,
    },
  },
  {
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallInvisalign
      ),
    ],
    comment: initVersionedSchema(
      'Discomfort and tightness of aligners can intensify after initial bonding of attachments'
    ),
    timing: {
      direction: TimingDirection.After,
      unit: TimingUnit.Days,
      amount: 1,
    },
  },
  {
    title: [
      toTextContent(DefaultGeneratedTaskConfiguration.OneWeekCheckUpSplit),
    ],
    comment: initVersionedSchema(
      'Has the patient been able to wear the splint? Does it fit well? Any symptoms?'
    ),
    timing: {
      amount: 1,
      unit: TimingUnit.Weeks,
      direction: TimingDirection.After,
    },
  },

  {
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.SendLabJobAndPrescription
      ),
    ],
    comment: initVersionedSchema(),
    timing: {
      direction: TimingDirection.Immediately,
      amount: 1,
      unit: TimingUnit.Minutes,
    },
  },
  {
    title: [
      toTextContent(DefaultGeneratedTaskConfiguration.FabricateWhiteningTrays),
    ],
    comment: initVersionedSchema(),
    timing: {
      unit: TimingUnit.Minutes,
      direction: TimingDirection.Immediately,
      amount: 1,
    },
    priority: TaskPriority.Medium,
  },
  {
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.ContactPatientForWhiteningKitPickUp
      ),
    ],
    comment: initVersionedSchema(
      'Check if there are any upcoming appointments.'
    ),
    timing: {
      direction: TimingDirection.Immediately,
      unit: TimingUnit.Days,
      amount: 1,
    },
  },

  {
    priority: TaskPriority.Low,
    comment: initVersionedSchema(
      'Check clinical notes for details. Some sensitivity is common after crown preparations. Advise caution with temporary crowns.'
    ),
    timing: {
      unit: TimingUnit.Days,
      direction: TimingDirection.After,
      amount: 1,
    },
    isForTeam: true,
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallCrownPrep
      ),
    ],
  },
  {
    title: [
      toTextContent(DefaultGeneratedTaskConfiguration.GenerateTreatmentPlan),
    ],
    comment: initVersionedSchema(),
    timing: {
      direction: TimingDirection.Immediately,
      unit: TimingUnit.Days,
      amount: 1,
    },
  },
  {
    title: [
      toTextContent(DefaultGeneratedTaskConfiguration.SendDigitalIOScanToLab),
    ],
    comment: initVersionedSchema(),
    timing: {
      direction: TimingDirection.Immediately,
      amount: 1,
      unit: TimingUnit.Days,
    },
  },
  {
    title: [
      toTextContent(DefaultGeneratedTaskConfiguration.OrderImplantComponents2),
    ],
    comment: initVersionedSchema(
      'Implant fixture (Brand, diameter, length, connection), healing abutment, cover screw, surgical guide, etc'
    ),
    timing: {
      unit: TimingUnit.Minutes,
      amount: 1,
      direction: TimingDirection.Immediately,
    },
  },
  {
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallPeriodontal
      ),
    ],
    comment: initVersionedSchema(
      'Check clinical notes for details. After periodontal treatment, teeth may become sensitive and gums may feel tender. Injection sites may also feel sore. Advise sensitive toothpaste, a gentle brushing technique with a soft bristled toothbrush and pain management if necessary.'
    ),
    timing: {
      unit: TimingUnit.Days,
      amount: 1,
      direction: TimingDirection.After,
    },
  },
  {
    title: [
      toTextContent(DefaultGeneratedTaskConfiguration.OrderImplantComponents3),
    ],
    comment: initVersionedSchema(),
    timing: {
      direction: TimingDirection.Before,
      amount: 5,
      unit: TimingUnit.Days,
    },
  },
  {
    isForTeam: true,
    priority: TaskPriority.Low,
    comment: initVersionedSchema(),
    title: [toTextContent(DefaultGeneratedTaskConfiguration.OrderRetainers)],
    timing: {
      unit: TimingUnit.Minutes,
      amount: 1,
      direction: TimingDirection.After,
    },
  },
  {
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.SendLabJobAndPrescriptionForSplit
      ),
    ],
    comment: initVersionedSchema(),
    timing: {
      amount: 1,
      unit: TimingUnit.Days,
      direction: TimingDirection.Immediately,
    },
    priority: TaskPriority.Medium,
  },

  {
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallBleaching
      ),
    ],
    comment: initVersionedSchema(
      'Check clinical notes for details. Internal bleaching can often cause discomfort for the first 24-28 hours and may require analgesics if necessary. There is usually an improvement in the colour of the tooth within 24hrs.'
    ),
    timing: {
      amount: 1,
      direction: TimingDirection.After,
      unit: TimingUnit.Days,
    },
  },
  {
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.SendFollowUpEmailForWorkup
      ),
    ],
    comment: initVersionedSchema(),
    timing: {
      amount: 1,
      unit: TimingUnit.Days,
      direction: TimingDirection.After,
    },
  },
  {
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallTeethPrep
      ),
    ],
    comment: initVersionedSchema(
      'Check clinical notes for details. Sensitivity and discomfort are common after preparation of teeth. Advise caution with temporary restorations which may come away easily.'
    ),
    timing: {
      direction: TimingDirection.After,
      amount: 1,
      unit: TimingUnit.Days,
    },
  },
  {
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallExtraction
      ),
    ],
    comment: initVersionedSchema(
      'Check clinical notes for details. Post operative discomfort is normal after extraction. Ensure patient is following post operative instructions and taking medications as prescribed. Check for excessive bleeding and/or symptoms of dry socket.'
    ),
    timing: {
      direction: TimingDirection.After,
      amount: 1,
      unit: TimingUnit.Days,
    },
  },

  {
    title: [
      toTextContent(
        DefaultGeneratedTaskConfiguration.NextDayCourtesyCallSoftTissueGraft
      ),
    ],
    comment: initVersionedSchema(
      'Check clinical notes for details. Post operative discomfort is normal at both the donor site and graft site. Ensure patient is following post operative instructions and taking medications as prescribed. Sutures may cause irritation.'
    ),
    timing: {
      unit: TimingUnit.Days,
      direction: TimingDirection.After,
      amount: 1,
    },
  },
  {
    title: [
      toTextContent(DefaultGeneratedTaskConfiguration.OneWeekCheckUpWhitening),
    ],
    comment: initVersionedSchema(
      'Sensitivity is common during take home teeth whitening. Reassure patient and ask if any results are apparent yet.'
    ),
    timing: {
      amount: 1,
      direction: TimingDirection.After,
      unit: TimingUnit.Weeks,
    },
  },
];

export function getDefaultGeneratedTaskConfigurations(): IGeneratedTaskConfiguration[] {
  return DEFAULT_GENERATED_TASKS.map((config) => ({
    ...initFirestoreModel(),
    priority: TaskPriority.Low,
    isActive: true,
    isForTeam: true,
    treatmentRefs: [],
    ...config,
  }));
}

export function findDefaultGeneratedTaskConfigurationRef(
  configs: WithRef<IGeneratedTaskConfiguration>[],
  title: DefaultGeneratedTaskConfiguration
): DocumentReference<IGeneratedTaskConfiguration> | undefined {
  const result = configs.find((config) => {
    const configTitle = getSchemaText(config.title);
    return configTitle === (title as string);
  });
  if (!result) {
    // eslint-disable-next-line no-console
    console.error(
      `Could not find default generated task configuration: ${title}`
    );
    return;
  }
  return result.ref;
}

export function findGeneratedTaskConfigRefs(
  configs: WithRef<IGeneratedTaskConfiguration>[],
  titles: DefaultGeneratedTaskConfiguration[]
): DocumentReference<IGeneratedTaskConfiguration>[] {
  return compact(
    titles.map((title) =>
      findDefaultGeneratedTaskConfigurationRef(configs, title)
    )
  );
}
