import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  DYNAMIC_COMPONENT_DATA,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  AnyHicapsConnectExtendedData,
  HicapsConnectExtendedDataTypeGuard,
  ITransaction,
  TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { compact, get, isString } from 'lodash';
import { ITransactionExtendedDataDisplayData } from '../../../transactions-display/transaction-display/transaction-display-line/transaction-display-line.component';
import { HicapsConnectReceipt } from '../hicaps-connect-receipt/hicaps-connect-receipt';
import { HicapsConnectResult } from '@principle-theorem/hicaps-connect';

export function isHicapsConnectTransaction(
  transaction: WithRef<ITransaction<unknown>>
): boolean {
  return [
    TransactionProvider.HicapsConnectEftpos,
    TransactionProvider.HicapsConnectHealthFund,
    TransactionProvider.HicapsConnectMedicare,
  ].includes(transaction.provider);
}

@Component({
    selector: 'pr-hicaps-connect-extended-data',
    templateUrl: './hicaps-connect-extended-data.component.html',
    styleUrls: ['./hicaps-connect-extended-data.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HicapsConnectExtendedDataComponent {
  trackBySegment = TrackByFunctions.variable<string>();
  segments: string[] = [];
  extendedData: AnyHicapsConnectExtendedData;
  showReceipt: boolean;

  constructor(
    @Inject(DYNAMIC_COMPONENT_DATA)
    public data: ITransactionExtendedDataDisplayData<unknown>
  ) {
    this.segments = this._getSegments(data.transaction.extendedData);

    if (
      HicapsConnectExtendedDataTypeGuard.isAny(data.transaction.extendedData)
    ) {
      this.extendedData = data.transaction.extendedData;
      this.showReceipt = HicapsConnectReceipt.hasExtendedDataToShowReceipt(
        data.transaction.extendedData
      );
    }
  }

  private _getSegments(extendedData: unknown): string[] {
    const response: unknown = get(extendedData, 'response');
    return compact([...this._basicMessage(response), this._getRRN(response)]);
  }

  private _basicMessage(response: unknown): string[] {
    const result: unknown = get(response, 'result');
    if (result === HicapsConnectResult.Error) {
      const errorMessage = get(response, 'message') as string | undefined;
      return isString(errorMessage) ? [errorMessage] : [];
    }

    const responseCode = get(response, 'data.ResponseCode') as
      | string
      | undefined;
    const responseMessage = get(response, 'data.ResponseText') as
      | string
      | undefined;
    return compact([responseCode, responseMessage]);
  }

  private _getRRN(response: unknown): string | undefined {
    const rrn = get(response, 'data.RrnNumber') as string;
    return rrn ? `RRN: ${rrn}` : undefined;
  }
}
