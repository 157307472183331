import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toTextContent } from '@principle-theorem/editor';
import {
  CurrentPracticeScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  ConfirmDialogComponent,
  DialogPresets,
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
  confirmationDialogData,
  toSearchStream,
  type IConfirmationDialogData,
  type IConfirmationDialogInput,
} from '@principle-theorem/ng-shared';
import {
  Practice,
  RecurrencePattern,
  sortRecurringTaskConfigByGroup,
  type IRecurringTaskConfigurationGroup,
} from '@principle-theorem/principle-core';
import {
  type IRecurringTaskConfiguration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  snapshot,
  type RecurrencePatternOption,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, combineLatest, type Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  RecurringTaskFormDialogComponent,
  type IRecurringTaskFormDialogData,
} from '../../components/recurring-task-form-dialog/recurring-task-form-dialog.component';
import { TaskManager } from '../../task-manager';

@Component({
    selector: 'pr-recurring-task-configuration',
    templateUrl: './recurring-task-configuration.component.html',
    styleUrls: ['./recurring-task-configuration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RecurringTaskConfigurationComponent {
  trackByGroup =
    TrackByFunctions.field<IRecurringTaskConfigurationGroup>('name');
  trackByConfiguration =
    TrackByFunctions.ref<WithRef<IRecurringTaskConfiguration>>();
  configurationGroups$: Observable<IRecurringTaskConfigurationGroup[]>;
  searchCtrl: TypedFormControl<string> = new TypedFormControl<string>('');
  loading$ = new BehaviorSubject<boolean>(true);
  emptyState$: Observable<boolean>;

  constructor(
    private _organisation: OrganisationService,
    private _practiceScope: CurrentPracticeScope,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog
  ) {
    const searchFilter: InputSearchFilter<
      WithRef<IRecurringTaskConfiguration>
    > = new InputSearchFilter(
      this._allRecurringTasks$(),
      toSearchStream(this.searchCtrl),
      ['title', 'description']
    );
    this.configurationGroups$ = searchFilter.results$.pipe(
      sortRecurringTaskConfigByGroup(),
      map((configGroups) =>
        configGroups.filter((group) => group.name !== 'Deleted Tasks')
      )
    );
    this.emptyState$ = combineLatest([
      this._allRecurringTasks$(),
      this.loading$,
    ]).pipe(map(([tasks, loading]) => !tasks.length && !loading));
  }

  getPatternSummary(recurrencePattern: RecurrencePatternOption): string {
    return RecurrencePattern.getPatternSummary(recurrencePattern);
  }

  addNewTask(): void {
    const data: IRecurringTaskFormDialogData = {
      title: [toTextContent(`Create Recurring Task`)],
      submitLabel: 'Create',
    };
    this._dialog.open(
      RecurringTaskFormDialogComponent,
      DialogPresets.medium({ data, autoFocus: true })
    );
  }

  edit(configuration: WithRef<IRecurringTaskConfiguration>): void {
    const data: IRecurringTaskFormDialogData = {
      title: [toTextContent('Edit '), ...configuration.title],
      submitLabel: 'Save',
      configuration,
    };
    this._dialog.open(
      RecurringTaskFormDialogComponent,
      DialogPresets.medium({ data, autoFocus: true })
    );
  }

  async delete(
    configuration: WithRef<IRecurringTaskConfiguration>
  ): Promise<void> {
    const data: IConfirmationDialogData = confirmationDialogData({
      title: 'Delete Recurring Task Configuration',
      prompt:
        'This will prevent future tasks from being created. Are you sure?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!confirmed) {
      return;
    }
    const taskManager: TaskManager = await this._taskManager();
    await taskManager.deleteRecurringTaskConfiguration(configuration);
    this._snackBar.open('Configuration deleted');
  }

  async undoDelete(
    configuration: WithRef<IRecurringTaskConfiguration>
  ): Promise<void> {
    const taskManager: TaskManager = await this._taskManager();
    await taskManager.undeleteRecurringTaskConfiguration(configuration);
    this._snackBar.open('Configuration restored');
  }

  private _allRecurringTasks$(): Observable<
    WithRef<IRecurringTaskConfiguration>[]
  > {
    return this._practiceScope.doc$.pipe(
      filterUndefined(),
      tap((_) => this.loading$.next(true)),
      switchMap((practice) => Practice.recurringTaskConfigurations$(practice)),
      tap((_) => this.loading$.next(false))
    );
  }

  private async _taskManager(): Promise<TaskManager> {
    const staffer: WithRef<IStaffer> = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    return new TaskManager(staffer);
  }
}
