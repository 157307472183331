<mat-toolbar color="accent">{{ title }}</mat-toolbar>

<form [formGroup]="tagForm" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>

    <pt-colour-picker formControlName="hexColour" [inputHidden]="true" />
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="isDisabled$ | ngrxPush"
      >
        Save
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
