import {
  calculateTaxFromTotal,
  TaxRate,
  TaxStrategy,
} from '@principle-theorem/accounting';
import {
  IHasLineItems,
  IInvoiceLineItem,
  isHasLineItems,
} from '@principle-theorem/principle-core/interfaces';
import { AtLeast } from '@principle-theorem/shared';
import { sum } from 'lodash';
import { v4 as uuid } from 'uuid';

export class InvoiceLineItem {
  static init(overrides: Partial<IInvoiceLineItem> = {}): IInvoiceLineItem {
    return {
      description: '',
      amount: 0,
      uid: uuid(),
      taxStatus: TaxStrategy.GSTPossible,
      tax: 0,
      quantity: 1,
      ...overrides,
    };
  }
}

export function determineTaxFromStrategy(
  taxRate: TaxRate,
  lineItem: AtLeast<IInvoiceLineItem, 'amount' | 'taxStatus'> &
    Partial<IHasLineItems>
): number {
  if (isHasLineItems(lineItem)) {
    return sum(lineItem.items.map((item) => item.tax * item.quantity));
  }
  if (lineItem.taxStatus === TaxStrategy.GSTApplicable) {
    return calculateTaxFromTotal(lineItem.amount, taxRate);
  }
  if (lineItem.taxStatus === TaxStrategy.GSTPossible) {
    return lineItem.tax ?? 0;
  }
  return 0;
}
