import { getEnumValues } from '@principle-theorem/shared';
import { type IToothRef } from './core/tooth';

export type SelectedCell = { row: number; col: number };

export interface IPerioTable {
  label: string;
  rows: IPerioTableCell[][];
  disabled: boolean;
  missingTeeth: IToothRef[];
  implants: IToothRef[];
}

export interface ICellMetadata {
  toothRef: IToothRef;
  dataPath: string;
  measurement: PerioMeasurement;
  dataPoint: PerioDataPoint;
}

export interface IPerioTableCell {
  metadata: ICellMetadata;
  value: PerioMeasurementValue;
  navigationIndex?: number;
  readOnly?: boolean;
}

export enum PerioDataPoint {
  FacialDistal = 'facialDistal',
  PalatalDistal = 'palatalDistal',
  FacialCentral = 'facialCentral',
  PalatalCentral = 'palatalCentral',
  FacialMesial = 'facialMesial',
  PalatalMesial = 'palatalMesial',
}

export type PerioMeasurementValue = number | undefined;
export type IPerioDataPoints = Record<PerioDataPoint, number>;
export type IPerioToothMap = Record<string, IPerioTableCell[]>;

export const PERIO_DATA_POINTS: PerioDataPoint[] =
  getEnumValues(PerioDataPoint);

export const FACIAL_PERIO_DATA_POINTS: PerioDataPoint[] = [
  PerioDataPoint.FacialMesial,
  PerioDataPoint.FacialCentral,
  PerioDataPoint.FacialDistal,
];

export const PALATAL_PERIO_DATA_POINTS: PerioDataPoint[] = [
  PerioDataPoint.PalatalMesial,
  PerioDataPoint.PalatalCentral,
  PerioDataPoint.PalatalDistal,
];

export enum PerioMeasurement {
  Pocket = 'pocket',
  Recession = 'recession',
  CAL = 'CAL',
  MGJ = 'MGJ',
  Bleeding = 'bleeding',
  Suppuration = 'suppuration',
  Plaque = 'plaque',
  Calculus = 'calculus',
  Mobility = 'mobility',
  Furcation = 'furcation',
}

export const PERIO_COLOUR_MAP: Record<PerioMeasurement, string> = {
  [PerioMeasurement.Pocket]: 'rgb(99 102 241)', // tailwind indigo-500
  [PerioMeasurement.Recession]: 'rgb(239 68 68)', // tailwind red-500
  [PerioMeasurement.CAL]: 'rgb(0 0 0)', // black
  [PerioMeasurement.MGJ]: 'rgb(0 0 0)', // black
  [PerioMeasurement.Bleeding]: 'rgb(0 0 0)', // black
  [PerioMeasurement.Furcation]: 'rgb(0 0 0)', // black
  [PerioMeasurement.Suppuration]: 'rgb(0 0 0)', // black
  [PerioMeasurement.Plaque]: 'rgb(0 0 0)', // black
  [PerioMeasurement.Calculus]: 'rgb(0 0 0)', // black
  [PerioMeasurement.Mobility]: 'rgb(0 0 0)', // black
};

export enum PerioIconClasses {
  BleedingLevelOne = 'bleeding-level-one',
  BleedingLevelTwo = 'bleeding-level-two',
  BleedingLevelThree = 'bleeding-level-three',
  FurcationLevelOne = 'furcation-level-one',
  FurcationLevelTwo = 'furcation-level-two',
  FurcationLevelThree = 'furcation-level-three',
  Suppuration = 'suppuration',
  Plaque = 'plaque',
  Calculus = 'calculus',
}

export const PERIO_ICON_MAP: Record<string, string> = {
  [`${PerioMeasurement.Bleeding}-1`]: PerioIconClasses.BleedingLevelOne,
  [`${PerioMeasurement.Bleeding}-2`]: PerioIconClasses.BleedingLevelTwo,
  [`${PerioMeasurement.Bleeding}-3`]: PerioIconClasses.BleedingLevelThree,
  [`${PerioMeasurement.Furcation}-1`]: PerioIconClasses.FurcationLevelOne,
  [`${PerioMeasurement.Furcation}-2`]: PerioIconClasses.FurcationLevelTwo,
  [`${PerioMeasurement.Furcation}-3`]: PerioIconClasses.FurcationLevelThree,
  [`${PerioMeasurement.Suppuration}-1`]: PerioIconClasses.Suppuration,
  [`${PerioMeasurement.Plaque}-1`]: PerioIconClasses.Plaque,
  [`${PerioMeasurement.Calculus}-1`]: PerioIconClasses.Calculus,
};

export const MAX_PERIO_CELL_AMOUNT = 3;
export const MIN_PERIO_CELL_AMOUNT = 1;
export const MIN_FURCATION_ROOT_AMOUNT = 2;

export const PERIO_ICON_MEASUREMENTS = [
  PerioMeasurement.Bleeding,
  PerioMeasurement.Furcation,
  PerioMeasurement.Suppuration,
  PerioMeasurement.Plaque,
  PerioMeasurement.Calculus,
];

const MAX_BINARY_MEASUREMENT_AMOUNT = 1;
const MAX_SEVERITY_MEASUREMENT_AMOUNT = 3;

export const PERIO_MEASUREMENT_MAX_VALUE: Partial<
  Record<PerioMeasurement, number>
> = {
  [PerioMeasurement.Bleeding]: MAX_SEVERITY_MEASUREMENT_AMOUNT,
  [PerioMeasurement.Suppuration]: MAX_BINARY_MEASUREMENT_AMOUNT,
  [PerioMeasurement.Plaque]: MAX_BINARY_MEASUREMENT_AMOUNT,
  [PerioMeasurement.Calculus]: MAX_BINARY_MEASUREMENT_AMOUNT,
  [PerioMeasurement.Mobility]: MAX_SEVERITY_MEASUREMENT_AMOUNT,
  [PerioMeasurement.Furcation]: MAX_SEVERITY_MEASUREMENT_AMOUNT,
};

export const PERIO_MEASUREMENTS: PerioMeasurement[] =
  getEnumValues(PerioMeasurement);

export interface IPerioData {
  [PerioMeasurement.Mobility]: number;
  [PerioMeasurement.Recession]: Partial<IPerioDataPoints>;
  [PerioMeasurement.CAL]: Partial<IPerioDataPoints>;
  [PerioMeasurement.MGJ]: Partial<IPerioDataPoints>;
  [PerioMeasurement.Pocket]: Partial<IPerioDataPoints>;
  [PerioMeasurement.Bleeding]: Partial<IPerioDataPoints>;
  [PerioMeasurement.Furcation]: Partial<IPerioDataPoints>;
  [PerioMeasurement.Suppuration]: Partial<IPerioDataPoints>;
  [PerioMeasurement.Plaque]: Partial<IPerioDataPoints>;
  [PerioMeasurement.Calculus]: Partial<IPerioDataPoints>;
}

export interface IPerioRecord {
  toothRef: IToothRef;
  data: Partial<IPerioData>;
}
