import { Inject, Injectable } from '@angular/core';
import type {
  Funder,
  IMedipassConfig,
  IMedipassPartnerSDK,
  IMemberQuery,
  IMemberQueryResult,
  ITransactionOptions,
  ITransactionResult,
  TransactionPayload,
} from '@medipass/partner-sdk';
import { MedipassWebhookEvent, getMedipass } from '@principle-theorem/medipass';
import { LoggerService } from '@principle-theorem/ng-shared';
import {
  type IHealthcareClaim,
  type IMedipassInternalWebhookData,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  httpsCallable,
  serialise,
  snapshot,
  type DocumentReference,
  type SerialisedData,
} from '@principle-theorem/shared';
import {
  NG_MEDIPASS_CONFIG_PROVIDER,
  type IMedipassConfigProvider,
} from './ng-medipass-config';

@Injectable()
export class MedipassService {
  private _medipass: IMedipassPartnerSDK = getMedipass();

  constructor(
    @Inject(NG_MEDIPASS_CONFIG_PROVIDER) provider: IMedipassConfigProvider,
    private _logger: LoggerService
  ) {
    provider
      .getConfig$()
      .subscribe((config) => this._setMedipassConfig(config));
  }

  renderCreateTransaction(
    practiceRef: DocumentReference<IPractice>,
    payload: Partial<TransactionPayload>,
    funder: Funder,
    optionOverrides?: ITransactionOptions,
    claim?: IHealthcareClaim
  ): Promise<ITransactionResult | undefined> {
    return new Promise((resolve, reject) => {
      const options: Partial<ITransactionOptions> = {
        version: '3',
        hideChatBubble: true,
        disableModifyServiceItems: true,
        ...optionOverrides,
        onSuccess: (transaction) => resolve(transaction),
        onError: (error) => reject(error),
        onCancel: () => resolve(undefined),
        onTransactionUpdated: (transaction) => {
          const requestData: IMedipassInternalWebhookData = {
            funder,
            claim,
            event: MedipassWebhookEvent.TransactionUpdated,
            transactionId: transaction._id,
            transaction,
            practiceRef,
          };
          const data = serialise(requestData);
          void snapshot(
            httpsCallable<SerialisedData<IMedipassInternalWebhookData>, void>(
              'http-medipass-internalWebhookHandler'
            )(data)
          );
        },
      };
      void this._logger.log('DEBUG', 'Medipass renderCreateTransaction', {
        payload,
        options,
      });
      this._medipass.renderCreateTransaction(payload, options);
    });
  }

  async discoverMember(
    query: Partial<IMemberQuery>
  ): Promise<IMemberQueryResult | undefined> {
    try {
      return await this._medipass.discoverMember(query);
    } catch (error) {
      return undefined;
    }
  }

  private _setMedipassConfig(config?: IMedipassConfig): void {
    if (!config) {
      return;
    }
    this._medipass.setConfig(config);
  }
}
