import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  type OnDestroy,
  Output,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CurrentScopeFacade,
  DateService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { SidebarManagerService } from '@principle-theorem/ng-shared';
import { stafferToNamedDoc, Task } from '@principle-theorem/principle-core';
import {
  type IInteraction,
  type IStaffer,
  type ITask,
  type ITeam,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  saveDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { noop, type Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { RecurringTaskConfirmDeleteComponent } from '../recurring-task-confirm-delete/recurring-task-confirm-delete.component';
import { TaskEditComponent } from '../task-edit/task-edit.component';

@Component({
  selector: 'pr-task-interactions',
  templateUrl: './task-interactions.component.html',
  styleUrls: ['./task-interactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskInteractionsComponent
  extends TaskEditComponent
  implements OnDestroy
{
  protected override _onDestroy$: Subject<void> = new Subject();
  @Output() closeTrigger: EventEmitter<void> = new EventEmitter<void>();
  isComplete$: Observable<boolean>;
  staff: WithRef<IStaffer>[];
  teams: WithRef<ITeam>[];
  sidebar: SidebarManagerService;

  constructor(
    dialog: MatDialog,
    organisation: OrganisationService,
    currentScope: CurrentScopeFacade,
    snackBar: MatSnackBar,
    public dateService: DateService,
    mediaMatcher: MediaMatcher,
    mediaObserver: MediaObserver
  ) {
    super(dialog, organisation, currentScope, snackBar);
    this.isComplete$ = this.task$.pipe(map((task) => Task.complete(task)));
    this.sidebar = new SidebarManagerService(mediaMatcher, mediaObserver);
  }

  override ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async addInteraction(
    task: WithRef<ITask>,
    interaction: IInteraction
  ): Promise<void> {
    const staffer: WithRef<IStaffer> | undefined = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    interaction.owner = stafferToNamedDoc(staffer);
    Task.addInteraction(task, interaction);
    await saveDoc(task);
  }

  async setComplete(task: WithRef<ITask>): Promise<void> {
    await this._taskManager.close(task);
    this._snackBar.open('Task completed');
    this.closeTrigger.emit();
  }

  async setIncomplete(task: WithRef<ITask>): Promise<void> {
    await this._taskManager.open(task);
    this._snackBar.open('Task re-opened');
    this.closeTrigger.emit();
  }

  async delete(task: WithRef<ITask>): Promise<void> {
    if (!Task.recurring(task)) {
      await this._taskManager.delete(task);
      this._snackBar
        .open('Task deleted', 'UNDO')
        .onAction()
        .pipe(
          switchMap(() => this.undelete(task)),
          takeUntil(this._onDestroy$)
        )
        .subscribe(noop);
      this.closeTrigger.emit();
      return;
    }

    const deleteConfiguration = await this._dialog
      .open<RecurringTaskConfirmDeleteComponent, undefined, boolean>(
        RecurringTaskConfirmDeleteComponent
      )
      .afterClosed()
      .toPromise();

    if (deleteConfiguration === undefined) {
      return;
    }
    await this._taskManager.deleteRecurringTask(task, deleteConfiguration);
    this._snackBar
      .open('Task deleted', 'UNDO')
      .onAction()
      .pipe(
        switchMap(() => this.undelete(task)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);
    this.closeTrigger.emit();
  }

  async undelete(task: WithRef<ITask>): Promise<void> {
    await this._taskManager.undelete(task);
    this._snackBar.open('Task Restored');
    this.closeTrigger.emit();
  }
}
