<form [formGroup]="form">
  <div class="flex flex-row items-center gap-4">
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Equipment</mat-label>
      <mat-select formControlName="item" [compareWith]="isSelectedItem">
        @for (item of items$ | async; track item.ref.path) {
          <mat-option [value]="item">
            {{ item.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Default quantity per pack</mat-label>
      <input matInput formControlName="quantity" type="number" />
    </mat-form-field>

    <div matTextSuffix (click)="$event.stopImmediatePropagation()">
      <ng-content />
    </div>
  </div>
</form>
