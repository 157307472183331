import {
  type DocumentReference,
  type IFirestoreModel,
  type Timestamp,
} from '@principle-theorem/shared';
import { IScheduleSummaryEventable } from '../event/schedule-summary';
import { type InteractionType } from '../interaction/interaction';

export enum SchedulingAlertTargetType {
  Appointment = 'appointment',
  Gap = 'gap',
  LabJob = 'labjob',
}

export interface ISchedulingAlert extends IFirestoreModel {
  title: string;
  description: string;
  type: InteractionType;
  targetType: SchedulingAlertTargetType;
  deadline: Timestamp;
  target?: DocumentReference;
  gap?: IScheduleSummaryEventable;
}
