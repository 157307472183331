import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitKebab',
})
export class SplitKebabPipe implements PipeTransform {
  transform(value: string): string {
    return value.split('-').join(' ').trim();
  }
}
