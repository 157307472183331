import {
  FeeSchedule,
  serviceCodeToFee,
  ServiceProviderHandler,
} from '@principle-theorem/principle-core';
import {
  ServiceCodeType,
  SourceEntityRecordStatus,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { isSameRef, type WithRef } from '@principle-theorem/shared';
import { compact } from 'lodash';
import {
  BaseFeeScheduleDestination,
  IFeeScheduleJobData,
  IFeeScheduleMigrationData,
} from '../../../destination/entities/fee-schedules';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  FeeScheduleSourceEntity,
  type IOasisFeeSchedule,
} from '../../source/entities/fee-schedule';
import { OasisFeeScheduleMappingHandler } from '../mappings/fee-schedules';
import { OasisPracticeMappingHandler } from '../mappings/practices';

export class FeeScheduleDestinationEntity extends BaseFeeScheduleDestination<IOasisFeeSchedule> {
  feeScheduleSourceEntity = new FeeScheduleSourceEntity();
  practiceCustomMapping = new OasisPracticeMappingHandler();
  feeScheduleCustomMapping = new OasisFeeScheduleMappingHandler();

  buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IFeeScheduleJobData<IOasisFeeSchedule>
  ):
    | IFeeScheduleMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord) {
    const errorResponseData = {
      label: data.feeScheduleRecord.record.label,
      uid: data.feeScheduleRecord.record.uid,
      ref: data.feeScheduleRecord.record.ref,
    };

    if (
      data.feeScheduleRecord.record.status === SourceEntityRecordStatus.Invalid
    ) {
      return this._buildErrorResponse(
        errorResponseData,
        'Source fee schedule is invalid'
      );
    }

    const practice = data.practices.find((searchPractice) =>
      isSameRef(
        searchPractice.destinationIdentifier,
        migration.configuration.practices[0].ref
      )
    );

    if (!practice || !practice.destinationIdentifier) {
      return this._buildErrorResponse(
        errorResponseData,
        `Can't find practice. Make sure the practice ids have been mapped.`
      );
    }

    return {
      isDefault: false,
      practiceRef: practice.destinationIdentifier,
      organisationRef: migration.configuration.organisation.ref,
      sourceFeeScheduleId: this.feeScheduleSourceEntity
        .getSourceRecordId(data.feeScheduleRecord.data.data)
        .toString(),
      feeSchedule: FeeSchedule.init({
        name: data.feeScheduleRecord.data.data.name,
        serviceCodeType: ServiceCodeType.ADA,
        serviceCodes: compact(
          data.feeScheduleRecord.data.data.items.map((item) => {
            const foundServiceCode = ServiceProviderHandler.findServiceCode(
              item.code
            );

            if (!foundServiceCode) {
              // eslint-disable-next-line no-console
              console.error(
                `Can't find service code info for code ${item.itemId}`
              );
              return;
            }

            return serviceCodeToFee(foundServiceCode, item.price);
          })
        ),
      }),
    };
  }
}
