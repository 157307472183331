import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ScopedFeeScheduleCollectionResolver } from '@principle-theorem/ng-clinical-fees';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import { FeeSchedule } from '@principle-theorem/principle-core';
import {
  type IBrand,
  type IFeeSchedule,
} from '@principle-theorem/principle-core/interfaces';
import { type CollectionReference } from '@principle-theorem/shared';
import {
  getDoc,
  type ResolverResult,
  type WithRef,
} from '@principle-theorem/shared';
import { from, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class BrandFeeScheduleCollectionResolver extends ScopedFeeScheduleCollectionResolver<
  WithRef<IBrand>
> {
  constructor(router: Router) {
    super(new CurrentBrandScope(router));
  }

  resolve(
    route: ActivatedRouteSnapshot
  ): ResolverResult<CollectionReference<IFeeSchedule> | undefined> {
    const model: WithRef<IBrand> | undefined =
      this._currentScope.findModel(route);
    if (!model) {
      return of(undefined);
    }
    return from(getDoc(model.ref)).pipe(
      map((brand) => FeeSchedule.col(brand)),
      take(1)
    );
  }
}
