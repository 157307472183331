import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  TypedFormControl,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import { PatientMetadataDisplay } from '@principle-theorem/principle-core';
import {
  IPatientMetadataDisplay,
  PatientMetadataValue,
} from '@principle-theorem/principle-core/interfaces';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PatientMetadataDisplayComponent } from '../patient-metadata-display/patient-metadata-display.component';

@Component({
  selector: 'pr-preview-patient-metadata-display',
  templateUrl: './preview-patient-metadata-display.component.html',
  styleUrls: ['./preview-patient-metadata-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgMaterialModule,
    PatientMetadataDisplayComponent,
  ],
})
export class PreviewPatientMetadataDisplayComponent {
  display$ = new ReplaySubject<IPatientMetadataDisplay>(1);
  valueCtrl = new TypedFormControl<string>('3');
  coercedValue$: Observable<PatientMetadataValue | undefined>;

  @Input()
  set display(display: IPatientMetadataDisplay) {
    if (display) {
      this.display$.next(display);
    }
  }

  @Input()
  set value(value: string) {
    if (value) {
      this.valueCtrl.setValue(value);
    }
  }

  constructor() {
    this.coercedValue$ = formControlChanges$(this.valueCtrl).pipe(
      map((value) => PatientMetadataDisplay.coerceStringValue(value))
    );
  }
}
