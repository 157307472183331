<mat-toolbar color="accent">Hicaps Custom Request</mat-toolbar>

<form class="flex flex-auto flex-col" [formGroup]="form" (submit)="submit()">
  <mat-dialog-content class="!max-h-screen">
    <div class="flex h-full flex-col gap-2">
      <mat-form-field appearance="outline">
        <mat-label>Method Name</mat-label>
        <input matInput formControlName="methodName" />
      </mat-form-field>

      <div class="flex flex-auto flex-col">
        <div class="text-base font-bold">Request Data</div>
        <ngx-monaco-editor
          class="flex flex-auto"
          [options]="editorOptions"
          [formControl]="requestDataFormControl"
          (onInit)="requestDataEditor$.next($event)"
        />
      </div>

      <div class="flex flex-auto flex-col">
        <div class="text-base font-bold">Extended Data</div>
        <ngx-monaco-editor
          class="flex flex-auto"
          [options]="editorOptions"
          [formControl]="extendedDataFormControl"
          (onInit)="extendedDataEditor$.next($event)"
        />
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" [disabled]="form.invalid">
        Submit
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
