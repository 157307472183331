import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  ICustomChartSettings,
  ICustomReportChart,
  MeasureFormatter,
  MeasureReducer,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot, uid } from '@principle-theorem/shared';
import { map } from 'rxjs/operators';
import {
  IReportBuildColumnSelectorFormData,
  IReportBuilderColumnSelectorDialogData,
  ReportBuilderColumnSelectorDialogComponent,
} from '../../../report-builder-results-table/report-builder-column-selector-dialog/report-builder-column-selector-dialog.component';
import { IResolvedColumnProperty } from '../../../report-builder-results-table/report-builder-column-selector-dialog/resolved-columns';
import { ReportBuilderStore } from '../../../report-builder.store';
import {
  IReportBuilderEditGroupByRequest,
  IReportBuilderEditGroupByResponse,
  ReportBuilderEditGroupByDialogComponent,
} from '../../../report-builder-results-table/report-builder-edit-group-by-dialog/report-builder-edit-group-by-dialog.component';
import { TABLE_GROUP_KEY } from '../../../../core/chart-builders/table-chart-builder';

@Injectable()
export class ReportBuilderChartColumnManager {
  constructor(
    private _store: ReportBuilderStore,
    private _dialog: MatDialog
  ) {}

  async editGroup(
    existingColumn: IResolvedColumnProperty,
    chart: ICustomReportChart,
    sectionName: string
  ): Promise<void> {
    if (existingColumn.definition.id !== chart.groupBy) {
      return;
    }

    const updatedColumn = await this._openEditGroupDialog(chart);
    if (!updatedColumn) {
      return;
    }

    const updatedChart = {
      ...chart,
      groupBy: updatedColumn.groupBy.metadata.id,
    };

    await this._store.patchChart(updatedChart, sectionName);
  }

  async edit(
    existingColumn: IResolvedColumnProperty,
    chart: ICustomReportChart,
    sectionName: string
  ): Promise<void> {
    const setting = chart.settings.find(
      (item) => item.uid === existingColumn.definition.uid
    );
    if (!setting) {
      return;
    }

    const updatedColumn = await this._openEditDialog(setting);
    if (!updatedColumn) {
      return;
    }

    const columns = chart.settings.map((column) => {
      if (column.uid !== existingColumn.definition.uid) {
        return column;
      }
      return {
        uid: existingColumn.definition.uid,
        label: updatedColumn.label ?? updatedColumn.measure.metadata.label,
        dataPoint: updatedColumn.measure.metadata.id,
        reducer: updatedColumn.reducer ?? MeasureReducer.Sum,
        filters: updatedColumn.filters,
      };
    });

    const updatedChart = {
      ...chart,
      settings: columns,
    };

    await this._store.patchChart(updatedChart, sectionName);
  }

  async remove(
    existingColumn: IResolvedColumnProperty,
    chart: ICustomReportChart,
    sectionName: string
  ): Promise<void> {
    const columns = chart.settings.filter(
      (column) => column.uid !== existingColumn.definition.uid
    );

    const updatedChart = {
      ...chart,
      settings: columns,
    };

    await this._store.patchChart(updatedChart, sectionName);
  }

  async addLeft(
    column: IResolvedColumnProperty,
    chart: ICustomReportChart,
    sectionName: string
  ): Promise<void> {
    const isGroupColumn = column.definition.label === TABLE_GROUP_KEY;
    const setting = chart.settings.find(
      (item) => item.uid === column.definition.uid
    );

    if (!isGroupColumn && !setting) {
      return;
    }

    const targetIndex = isGroupColumn
      ? -1
      : this._getColumnIndex(chart, setting);

    const newColumn = await this._openEditDialog();
    if (!newColumn) {
      return;
    }

    await this._addAtIndex(
      targetIndex,
      {
        uid: uid(),
        label: newColumn.label ?? newColumn.measure.metadata.label,
        dataPoint: newColumn.measure.metadata.id,
        reducer: newColumn.reducer ?? MeasureReducer.Sum,
        filters: newColumn.filters,
      },
      chart,
      sectionName
    );
  }

  async addRight(
    column: IResolvedColumnProperty,
    chart: ICustomReportChart,
    sectionName: string
  ): Promise<void> {
    const isGroupColumn = column.definition.label === TABLE_GROUP_KEY;
    const setting = chart.settings.find(
      (item) => item.uid === column.definition.uid
    );

    if (!isGroupColumn && !setting) {
      return;
    }

    const targetIndex = isGroupColumn
      ? -1
      : this._getColumnIndex(chart, setting);

    const newColumn = await this._openEditDialog();
    if (!newColumn) {
      return;
    }

    await this._addAtIndex(
      targetIndex + 1,
      {
        uid: uid(),
        label: newColumn.label ?? newColumn.measure.metadata.label,
        dataPoint: newColumn.measure.metadata.id,
        reducer: newColumn.reducer ?? MeasureReducer.Sum,
        filters: newColumn.filters,
      },
      chart,
      sectionName
    );
  }

  async moveLeft(
    column: IResolvedColumnProperty,
    chart: ICustomReportChart,
    sectionName: string
  ): Promise<void> {
    const setting = chart.settings.find(
      (item) => item.uid === column.definition.uid
    );
    if (!setting) {
      return;
    }

    const targetIndex = this._getColumnIndex(chart, setting);
    await this.moveColumn(targetIndex, targetIndex - 1, chart, sectionName);
  }

  async moveRight(
    column: IResolvedColumnProperty,
    chart: ICustomReportChart,
    sectionName: string
  ): Promise<void> {
    const setting = chart.settings.find(
      (item) => item.uid === column.definition.uid
    );
    if (!setting) {
      return;
    }

    const targetIndex = this._getColumnIndex(chart, setting);
    await this.moveColumn(targetIndex, targetIndex + 1, chart, sectionName);
  }

  async moveColumn(
    fromIndex: number,
    toIndex: number,
    chart: ICustomReportChart,
    sectionName: string
  ): Promise<void> {
    const columns = [...chart.settings];
    moveItemInArray(columns, fromIndex, toIndex);
    const updatedChart = {
      ...chart,
      settings: columns,
    };
    await this._store.patchChart(updatedChart, sectionName);
  }

  private async _openEditGroupDialog(
    chart: ICustomReportChart
  ): Promise<IReportBuilderEditGroupByResponse | undefined> {
    const dataSource = await snapshot(this._store.dataSource$);
    const data: IReportBuilderEditGroupByRequest = {
      chart,
      groupBys: dataSource?.groupByOptions ?? [],
    };
    return this._dialog
      .open<
        ReportBuilderEditGroupByDialogComponent,
        IReportBuilderEditGroupByRequest,
        IReportBuilderEditGroupByResponse
      >(ReportBuilderEditGroupByDialogComponent, DialogPresets.medium({ data }))
      .afterClosed()
      .toPromise();
  }

  private async _openEditDialog(
    existing?: ICustomChartSettings
  ): Promise<IReportBuildColumnSelectorFormData | undefined> {
    const filteredData = await snapshot(
      this._store.results$.pipe(map((results) => results?.filtered ?? []))
    );
    const data: IReportBuilderColumnSelectorDialogData = {
      existing,
      filteredData,
      excludeDataPointFormatters: [MeasureFormatter.Link],
    };
    return this._dialog
      .open<
        ReportBuilderColumnSelectorDialogComponent,
        IReportBuilderColumnSelectorDialogData,
        IReportBuildColumnSelectorFormData
      >(
        ReportBuilderColumnSelectorDialogComponent,
        DialogPresets.medium({ data })
      )
      .afterClosed()
      .toPromise();
  }

  private async _addAtIndex(
    index: number,
    column: ICustomChartSettings,
    chart: ICustomReportChart,
    sectionName: string
  ): Promise<void> {
    const columns = [...chart.settings];
    columns.splice(index, 0, column);
    const updatedChart = {
      ...chart,
      settings: columns,
    };
    await this._store.patchChart(updatedChart, sectionName);
  }

  private _getColumnIndex(
    chart: ICustomReportChart,
    column?: ICustomChartSettings
  ): number {
    if (!column) {
      return chart.settings.length - 1;
    }
    return chart.settings.findIndex((item) => item.uid === column.uid);
  }
}
