@if (formGroup$ | async; as formGroup) {
  <form [formGroup]="formGroup">
    <div class="flex flex-col gap-4">
      <mat-form-field class="w-full">
        <mat-label>Medication Name</mat-label>
        @if (configPrescriptionItems$ | async; as configPrescriptionItems) {
          @if (configPrescriptionItems.length) {
            <input
              matInput
              formControlName="medicationName"
              required
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="setSelectedItem($event)"
            >
              @for (option of filteredItems$ | async; track option.ref.path) {
                <mat-option [value]="option">
                  {{ option.medicationName }}
                </mat-option>
              }
            </mat-autocomplete>
          } @else {
            <input matInput formControlName="medicationName" required />
          }
        }
        <mat-hint>Name of the medication (e.g. Amoxicillin 500mg)</mat-hint>
      </mat-form-field>

      <div [ngClass]="compact ? 'flex-row' : 'flex-col'" class="flex gap-4">
        <mat-form-field class="w-full">
          <mat-label>Quantity</mat-label>
          <input matInput formControlName="quantity" />
          <mat-hint>Total amount prescribed (e.g. 20)</mat-hint>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>Repeat</mat-label>
          <input matInput type="number" min="0" formControlName="repeat" />
          <mat-hint>Number of repeats allowed</mat-hint>
        </mat-form-field>
      </div>

      <mat-form-field class="w-full">
        <mat-label>Instructions</mat-label>
        <input matInput formControlName="instructions" />
        <mat-hint>
          How to take the medication (e.g. take 1 every 4 hours)
        </mat-hint>
      </mat-form-field>

      <mat-form-field class="w-full">
        <mat-label>Safety Information</mat-label>
        <input matInput formControlName="safetyInformation" />
        <mat-hint>
          Important safety information (e.g. do not take with alcohol)
        </mat-hint>
      </mat-form-field>
    </div>
  </form>
}
