<h1>Results</h1>

<ng-content />

<div
  fxLayout="row"
  fxLayoutGap="16px"
  *ngIf="store.results$ | async as results"
>
  <button
    mat-stroked-button
    *ngIf="store.editMode$ | async"
    (click)="editColumns()"
  >
    Edit Columns
  </button>
  <pt-download-report
    [data]="csvData$ | async"
    [csvTranslator]="csvTranslator$ | async"
  />
  <span fxFlex></span>

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <div fxLayout="column" fxLayoutAlign="start end">
      <div>
        <span class="mat-caption">
          {{ results.filteredCount }} selected out of
          {{ results.allCount }} records
        </span>
      </div>
    </div>
    <button mat-stroked-button (click)="resetFilters()">
      Reset All Filters
    </button>
  </div>
</div>

<div class="results-table">
  <pr-report-builder-table />
</div>
