<mat-toolbar color="accent"> Delete Configuration? </mat-toolbar>
<mat-dialog-content>
  <p class="prompt">
    You're deleting a recurring task. Do you want to delete all future tasks?
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close (click)="deleteConfiguration()">
    Yes
  </button>
  <button mat-stroked-button mat-dialog-close (click)="keepConfiguration()">
    No
  </button>
</mat-dialog-actions>
