import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TimezoneService } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { ParticipantAvatarFactory } from '@principle-theorem/principle-core';
import {
  type IEvent,
  type IParticipant,
  type IParticipantAvatar,
} from '@principle-theorem/principle-core/interfaces';
import {
  CASUAL_DATE_WITH_YEAR,
  formatTimeFromTo,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-event-details',
    templateUrl: './event-details.component.html',
    styleUrls: ['./event-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EventDetailsComponent {
  trackByParticipant = TrackByFunctions.field<IParticipantAvatar>('name');
  dateFormat: string = CASUAL_DATE_WITH_YEAR;
  timeDisplay$: Observable<string>;
  participants$: Observable<IParticipantAvatar[]>;
  event$: ReplaySubject<IEvent> = new ReplaySubject(1);

  constructor(private _timezone: TimezoneService) {
    this.participants$ = this._getParticipants$();
    this.timeDisplay$ = this.event$.pipe(
      switchMap((event) => this._timezone.getEventRange$(event)),
      map((range) => formatTimeFromTo(range.from, range.to))
    );
  }

  @Input()
  set event(event: IEvent) {
    if (event) {
      this.event$.next(event);
    }
  }

  private _getParticipants$(): Observable<IParticipant[]> {
    const factory: ParticipantAvatarFactory = new ParticipantAvatarFactory();

    return this.event$.pipe(
      switchMap((event) =>
        combineLatest(
          event.participants.map((participant) => factory.create(participant))
        )
      )
    );
  }
}
