import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  type IInteractiveResource,
  type IPrincipleMention,
} from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-mention-toolbar',
  templateUrl: './mention-toolbar.component.html',
  styleUrls: ['./mention-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentionToolbarComponent {
  resource$ = new ReplaySubject<IPrincipleMention>(1);
  templateResource$ = new ReplaySubject<IPrincipleMention>(1);
  interactiveResource$ = new ReplaySubject<IInteractiveResource>(1);
  @Input() label: string;

  @Input()
  set interactiveResource(interactiveResource: IInteractiveResource) {
    if (interactiveResource) {
      this.interactiveResource$.next(interactiveResource);
    }
  }

  @Input()
  set resource(resource: IPrincipleMention) {
    if (resource) {
      this.resource$.next(resource);
    }
  }

  @Input()
  set templateResource(templateResource: IPrincipleMention) {
    if (templateResource) {
      this.templateResource$.next(templateResource);
    }
  }
}
