import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pr-medical-alerts-preview',
  templateUrl: './medical-alerts-preview.component.html',
  styleUrls: ['./medical-alerts-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicalAlertsPreviewComponent {
  @Input() alertCount = 0;
}
