import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { LabJobInteractionsDialogComponent } from '@principle-theorem/ng-labs';
import {
  DialogPresets,
  ObservableDataSource,
  SelectionListStore,
} from '@principle-theorem/ng-shared';
import {
  type ILabJob,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { isSameRef, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, Subject } from 'rxjs';
import { PatientLabJobsStore } from './patient-lab-jobs.store';

@Component({
    selector: 'pr-patient-lab-jobs',
    templateUrl: './patient-lab-jobs.component.html',
    styleUrls: ['./patient-lab-jobs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SelectionListStore, PatientLabJobsStore],
    standalone: false
})
export class PatientLabJobsComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);
  dataSource: ObservableDataSource<WithRef<ILabJob>>;
  sort: MatSort = new MatSort();

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this._patient$.next(patient);
    }
  }

  constructor(
    private _labJobStore: PatientLabJobsStore,
    private _dialog: MatDialog,
    public selectionList: SelectionListStore<WithRef<ILabJob>>
  ) {
    this.dataSource = new ObservableDataSource<WithRef<ILabJob>>(
      this._labJobStore.labJobs$
    );
    this._labJobStore.loadLabJobs(this._patient$);

    this.selectionList.setCompareFn(isSameRef);
    this.selectionList.loadOptions(this.dataSource.filteredData$);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.dataSource.disconnect();
  }

  editLabJob(labJob: WithRef<ILabJob>): void {
    this._dialog.open(
      LabJobInteractionsDialogComponent,
      DialogPresets.large({
        height: '80%',
        autoFocus: false,
        data: { labJob },
      })
    );
  }
}
