import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IAppointmentSuggestion,
  type ISchedulingConflict,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import { multiSwitchMap, type WithRef } from '@principle-theorem/shared';
import { compact } from 'lodash';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-appointment-suggestion-tooltip',
  templateUrl: './appointment-suggestion-tooltip.component.html',
  styleUrls: ['./appointment-suggestion-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentSuggestionTooltipComponent {
  private _suggestion$ = new ReplaySubject<IAppointmentSuggestion>(1);
  private _global = inject(GlobalStoreService);
  trackByReason = TrackByFunctions.field<ISchedulingConflict>('reason');
  tags$: Observable<WithRef<ITag>[]>;
  conflicts$: Observable<ISchedulingConflict[]>;

  @Input()
  set suggestion(suggestion: IAppointmentSuggestion) {
    if (suggestion) {
      this._suggestion$.next(suggestion);
    }
  }

  constructor() {
    this.conflicts$ = this._suggestion$.pipe(
      map((suggestion) => suggestion.schedulingConflicts)
    );
    this.tags$ = this._suggestion$.pipe(
      map((suggestion) => suggestion.intersectingTags),
      multiSwitchMap((namedDoc) => this._global.getTag$(namedDoc.ref)),
      map(compact)
    );
  }
}
