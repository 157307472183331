import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { MOMENT_DATEPICKER_PROVIDERS } from '@principle-theorem/ng-shared';
import { Brand, getScopedConditions } from '@principle-theorem/principle-core';
import {
  ConditionLogicConfigurationCollection,
  ConditionLogicId,
  IAutomatedFormIssue,
  IAutomatedFormIssueConfiguration,
  IAutomationTiming,
  ICustomFormConfiguration,
  TemplateScope,
  isAutomatedFormIssueConfiguration,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  TIME_FORMAT_24HR,
  all$,
  isSameRef,
  mergeDayAndTime,
  toMoment,
  toTimestamp,
  undeletedQuery,
  type INamedDocument,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import * as moment from 'moment-timezone';
import { duration, type Moment } from 'moment-timezone';
import { Subject, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AUTOMATION_CONDITIONS_HELP_TEXT } from '../automated-notification-configuration-edit/automated-notification-configuration-form';
import { AutomatedFormIssueDialogForm } from './automated-form-issue-dialog-form';

export interface IAutomatedFormIssueDialogData {
  formIssue?: IAutomatedFormIssue;
  useRelativeTime: boolean;
  triggerDate?: Timestamp;
  practices?: INamedDocument<IPractice>[];
}

export interface IAutomatedFormIssueFormData
  extends IAutomatedFormIssueConfiguration {
  triggerDate?: Moment;
  triggerTime?: string;
}

export interface IAutomatedFormIssueReturnData extends IAutomatedFormIssue {
  triggerDate?: Timestamp;
  configRef?: DocumentReference<IAutomatedFormIssueConfiguration>;
}

@Component({
  selector: 'pr-automated-form-issue-dialog',
  templateUrl: './automated-form-issue-dialog.component.html',
  styleUrls: ['./automated-form-issue-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
  standalone: false,
})
export class AutomatedFormIssueDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  form = new AutomatedFormIssueDialogForm();
  timeInterval = duration(15, 'minutes');
  formIssueConfig?: IAutomatedFormIssueConfiguration | undefined;
  noScope: boolean;
  availableConditions: ConditionLogicId[];
  defaultCondition = ConditionLogicId.Always;
  automationConditionsHelp = AUTOMATION_CONDITIONS_HELP_TEXT;
  formConfigurations$: Observable<WithRef<ICustomFormConfiguration>[]>;

  constructor(
    private _dialogRef: MatDialogRef<
      AutomatedFormIssueDialogComponent,
      IAutomatedFormIssueReturnData
    >,
    private _organisation: OrganisationService,
    @Inject(MAT_DIALOG_DATA) public data?: IAutomatedFormIssueDialogData
  ) {
    if (data?.formIssue) {
      this.form.patchValue(data.formIssue);
      this.formIssueConfig = isAutomatedFormIssueConfiguration(data.formIssue)
        ? data.formIssue
        : undefined;
    }

    if (data?.triggerDate && !data.useRelativeTime) {
      this.form.enableTriggerControls();
      this.form.controls.triggerDate.setValue(toMoment(data.triggerDate));
      this.form.controls.triggerTime.setValue(
        toMoment(data.triggerDate).format(TIME_FORMAT_24HR)
      );
    }

    if (data?.practices) {
      this.form.controls.practiceRef.enable();
    }

    this.formConfigurations$ = this._organisation.brand$.pipe(
      switchMap((brand) => {
        if (!brand) {
          return of([]);
        }
        return all$(undeletedQuery(Brand.customFormConfigCol(brand)));
      }),
      map((configs) => sortBy(configs, (config) => config.name))
    );

    this.availableConditions = getScopedConditions(
      TemplateScope.Appointment
    ).filter((condition) => condition !== ConditionLogicId.Never);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  clearTriggerDate(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.form.controls.triggerDate.reset();
  }

  requiredConditionsChange(event: ConditionLogicConfigurationCollection): void {
    this.form.controls.requiredConditions.setValue(event);
  }

  timingChange(event: IAutomationTiming): void {
    this.form.controls.timing.setValue({
      ...event,
      // eslint-disable-next-line no-null/no-null
      time: event.time ?? null,
    });
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }
    const formValue = this.form.value;

    this._dialogRef.close({
      ...formValue,
      triggerDate:
        formValue.triggerDate && formValue.triggerTime
          ? toTimestamp(
              mergeDayAndTime(
                toMoment(formValue.triggerDate),
                moment(formValue.triggerTime, TIME_FORMAT_24HR)
              )
            )
          : undefined,
    });
  }

  compareFn(
    value: INamedDocument | DocumentReference,
    assignee: INamedDocument | DocumentReference
  ): boolean {
    if (!value || !assignee) {
      return false;
    }

    return isSameRef(value, assignee);
  }
}
