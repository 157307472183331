import {
  CancellationReason,
  IPatientMetadataDisplay,
  ISchedulingEventReason,
  PatientMetadataDisplayFormat,
  PatientMetadataDisplayTarget,
  SchedulingEventType,
} from '@principle-theorem/principle-core/interfaces';
import { PatientMetadataDisplay } from './patient/patient-metadata';
import { SchedulingEventReason } from './scheduling-event-reason';
import { isUndefined, omitBy } from 'lodash';

type ReasonTemplate = Omit<ISchedulingEventReason, 'metadataDisplayRef'>;

type MetadataDisplayTemplate = Pick<
  IPatientMetadataDisplay,
  'label' | 'displayTargets' | 'hexColour'
>;

export interface ISchedulingEventReasonDefault {
  key: CancellationReason;
  reasonTemplate: ReasonTemplate;
  metadataDisplay: IPatientMetadataDisplay;
}

function createPatientMetadataDisplay(
  label: string,
  metadataKey: string,
  displayTargets: PatientMetadataDisplayTarget[],
  hexColour: string,
  overrides: Partial<IPatientMetadataDisplay> = {}
): IPatientMetadataDisplay {
  return PatientMetadataDisplay.init({
    label,
    metadataKey,
    format: PatientMetadataDisplayFormat.LabelValue,
    displayTargets,
    hexColour,
    onlyShowIfHasValue: true,
    ...overrides,
  });
}

const DEFAULT_CANCELLATION_REASONS = [
  CancellationReason.PracticeRescheduled,
  CancellationReason.FailedToAttend,
  CancellationReason.UnableToAttend,
  CancellationReason.PracticeCancelled,
  CancellationReason.Other,
];

const SCHEDULING_EVENT_REASON_TEMPLATES: Partial<
  Record<CancellationReason, ReasonTemplate>
> = {
  [CancellationReason.Other]: {
    name: CancellationReason.Other,
    description: 'Appointment has been rescheduled or cancelled',
    eventTypes: [SchedulingEventType.Cancel, SchedulingEventType.Reschedule],
    metadataKey: SchedulingEventReason.getMetadataKey(CancellationReason.Other),
    isSystemReason: true,
    deleted: false,
    moveToSameDayDefault: false,
    hrsBeforeAppointment: undefined,
    fillGapDefault: false,
  },
  [CancellationReason.FailedToAttend]: {
    name: 'Failed to Attend (FTA)',
    description:
      'A Patient failed to attend their appointment or cancelled within 24 hours of their appointment start time',
    eventTypes: [SchedulingEventType.Cancel, SchedulingEventType.Reschedule],
    metadataKey: SchedulingEventReason.getMetadataKey(
      CancellationReason.FailedToAttend
    ),
    isSystemReason: false,
    deleted: false,
    moveToSameDayDefault: false,
    hrsBeforeAppointment: 24,
    fillGapDefault: false,
  },
  [CancellationReason.UnableToAttend]: {
    name: 'Unable to Attend (UTA)',
    description:
      'Use when a patient cancels or reschedules their appointment within 48 hours of the appointment start time',
    eventTypes: [SchedulingEventType.Cancel, SchedulingEventType.Reschedule],
    metadataKey: SchedulingEventReason.getMetadataKey(
      CancellationReason.UnableToAttend
    ),
    isSystemReason: false,
    deleted: false,
    moveToSameDayDefault: false,
    hrsBeforeAppointment: 48,
    fillGapDefault: false,
  },
  [CancellationReason.PracticeCancelled]: {
    name: CancellationReason.PracticeCancelled,
    description:
      'Use when the practice cancels an appointment without rescheduling',
    eventTypes: [SchedulingEventType.Cancel],
    metadataKey: SchedulingEventReason.getMetadataKey(
      CancellationReason.PracticeCancelled
    ),
    isSystemReason: false,
    deleted: false,
    moveToSameDayDefault: false,
    hrsBeforeAppointment: undefined,
    fillGapDefault: false,
  },
  [CancellationReason.PracticeRescheduled]: {
    name: CancellationReason.PracticeRescheduled,
    description:
      'Use when an appointment is moved or rescheduled by the practice for internal reasons or gap filling',
    eventTypes: [SchedulingEventType.Reschedule],
    metadataKey: SchedulingEventReason.getMetadataKey(
      CancellationReason.PracticeRescheduled
    ),
    isSystemReason: false,
    deleted: false,
    moveToSameDayDefault: true,
    hrsBeforeAppointment: undefined,
    fillGapDefault: true,
  },
};

const defaultHexColour = '#94A3B8';
const SCHEDULING_EVENT_METADATA_DISPLAY_TEMPLATES: Partial<
  Record<CancellationReason, MetadataDisplayTemplate>
> = {
  [CancellationReason.PracticeRescheduled]: {
    label: 'Rescheduled by Practice',
    displayTargets: [],
    hexColour: defaultHexColour,
  },
  [CancellationReason.FailedToAttend]: {
    label: 'FTA',
    displayTargets: [
      PatientMetadataDisplayTarget.AppointmentSidebarUnderTags,
      PatientMetadataDisplayTarget.PatientProfilePatientDetailsUnderTags,
    ],
    hexColour: '#BE185D',
  },
  [CancellationReason.UnableToAttend]: {
    label: 'UTA',
    displayTargets: [
      PatientMetadataDisplayTarget.AppointmentSidebarUnderTags,
      PatientMetadataDisplayTarget.PatientProfilePatientDetailsUnderTags,
    ],
    hexColour: '#A16207',
  },
  [CancellationReason.Other]: {
    label: 'Other',
    displayTargets: [],
    hexColour: defaultHexColour,
  },
  [CancellationReason.PracticeCancelled]: {
    label: 'Cancelled By Practice',
    displayTargets: [],
    hexColour: defaultHexColour,
  },
};

export function getSchedulingEventReasonDefault(
  key: CancellationReason
): ISchedulingEventReasonDefault {
  const reasonTemplate = omitBy(SCHEDULING_EVENT_REASON_TEMPLATES, isUndefined)[
    key
  ];
  const metadataDisplayTemplate = omitBy(
    SCHEDULING_EVENT_METADATA_DISPLAY_TEMPLATES,
    isUndefined
  )[key];
  const metadataDisplay = createPatientMetadataDisplay(
    metadataDisplayTemplate.label,
    reasonTemplate.metadataKey,
    metadataDisplayTemplate.displayTargets,
    metadataDisplayTemplate.hexColour
  );
  return {
    key,
    reasonTemplate,
    metadataDisplay,
  };
}

export const SCHEDULING_EVENT_REASON_DEFAULTS =
  DEFAULT_CANCELLATION_REASONS.map((key) =>
    getSchedulingEventReasonDefault(key)
  ).sort(
    (a, b) =>
      DEFAULT_CANCELLATION_REASONS.indexOf(a.key) -
      DEFAULT_CANCELLATION_REASONS.indexOf(b.key)
  );
