import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { ICSVExport, ObservableDataSource } from '@principle-theorem/ng-shared';
import {
  type IPractitionerGrouping,
  PRACTITIONER_INCOME_TOOLTIPS,
} from '@principle-theorem/reporting';
import {
  type FieldsOfType,
  multiMap,
  ITimePeriod,
  toTimePeriod,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PractitionerIncomeReportFacade } from '../store/practitioner-income.facade';
import { PractitionerIncomeReportType } from '../store/practitioner-income.reducers';
import { getColumnsForReportType } from '../practitioner-invoice-display-columns';
import { InvoiceIssuedSelectorToCSV } from '../../../practitioner-reporting/practitioner-income/practitioner-income-selector/invoice-issued-selector-to-csv';
import { InvoicePaidSelectorToCSV } from '../../../practitioner-reporting/practitioner-income/practitioner-income-selector/invoice-paid-selector-to-csv';
import { sum } from 'lodash';

@Component({
    selector: 'pr-practitioner-income-selector',
    templateUrl: './practitioner-income-selector.component.html',
    styleUrls: ['./practitioner-income-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PractitionerIncomeSelectorComponent implements OnDestroy {
  tooltips = PRACTITIONER_INCOME_TOOLTIPS;
  dataSource: ObservableDataSource<IPractitionerGrouping>;
  displayedColumns$: Observable<string[]>;
  csvTranslator$: Observable<ICSVExport<object, object>>;
  dateRange$: Observable<ITimePeriod | undefined>;

  constructor(public reportFacade: PractitionerIncomeReportFacade) {
    this.dataSource = new ObservableDataSource(reportFacade.results$);
    this.displayedColumns$ = reportFacade.reportType$.pipe(
      map((reportType) => [
        'practitioner',
        ...getColumnsForReportType(reportType),
      ])
    );
    this.csvTranslator$ = this.reportFacade.reportType$.pipe(
      map((reportType) =>
        reportType === PractitionerIncomeReportType.ByInvoiceIssuedDate
          ? new InvoiceIssuedSelectorToCSV()
          : new InvoicePaidSelectorToCSV()
      )
    );
    this.dateRange$ = this.reportFacade.query$.pipe(
      map((query) =>
        query ? toTimePeriod(query.startDate, query.endDate) : undefined
      )
    );
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  sumAmount$(
    key: FieldsOfType<IPractitionerGrouping['total'], number>
  ): Observable<number> {
    return this.dataSource.filteredData$.pipe(
      multiMap(
        (item) => item.total[key as keyof IPractitionerGrouping['total']]
      ),
      map(sum)
    );
  }
}
