import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IMultiTreatmentConfiguration } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-edit-multi-treatment-configuration',
  templateUrl: './edit-multi-treatment-configuration.component.html',
  styleUrls: ['./edit-multi-treatment-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMultiTreatmentConfigurationComponent {
  multiTreatmentConfig$: ReplaySubject<WithRef<IMultiTreatmentConfiguration>> =
    new ReplaySubject(1);

  @Input()
  set multiTreatmentConfig(
    multiTreatmentConfig: WithRef<IMultiTreatmentConfiguration>
  ) {
    if (multiTreatmentConfig) {
      this.multiTreatmentConfig$.next(multiTreatmentConfig);
    }
  }
}
