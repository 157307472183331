<form [formGroup]="form" class="flex max-w-lg flex-col">
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput formControlName="name" required cdkFocusInitial />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Description</mat-label>
    <textarea matInput formControlName="description"></textarea>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Restrict Access</mat-label>
    <mat-select
      formControlName="restrictAccessTo"
      multiple
      [compareWith]="compareAccessFn"
    >
      <mat-optgroup label="Roles">
        @for (role of roles$ | async; track role.ref.id) {
          <mat-option [value]="role">
            {{ role.name }}
          </mat-option>
        }
      </mat-optgroup>

      <mat-optgroup label="Staff">
        @for (staffer of staff$ | async; track staffer.ref.id) {
          <mat-option [value]="staffer">
            {{ staffer.name }}
          </mat-option>
        }
      </mat-optgroup>
    </mat-select>
  </mat-form-field>

  <pt-buttons-container
    *ngIf="(customReport$ | async) === undefined"
    padding="none"
  >
    <button type="button" mat-stroked-button (click)="closeDialog.emit()">
      Cancel
    </button>
    <button
      type="submit"
      mat-flat-button
      color="primary"
      (click)="saveCustomReport.emit(this.form.value)"
    >
      Save
    </button>
  </pt-buttons-container>
</form>
