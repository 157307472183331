import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
} from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  AutomationDisplay,
  IAutomationIconDisplay,
  UNKNOWN_ICON,
} from '@principle-theorem/principle-core';
import { AnyAutomation } from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-automation-icon',
  templateUrl: './automation-icon.component.html',
  styleUrls: ['./automation-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex' },
  imports: [CommonModule, NgMaterialModule],
})
export class AutomationIconComponent {
  display = signal<IAutomationIconDisplay>(UNKNOWN_ICON);

  @Input()
  set automation(automation: AnyAutomation) {
    const display = this._getIconDisplay(automation);
    this.display.set(display);
  }

  private _getIconDisplay(config?: AnyAutomation): IAutomationIconDisplay {
    return config ? AutomationDisplay.getIcon(config) : UNKNOWN_ICON;
  }
}
