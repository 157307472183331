<mat-toolbar [color]="data.toolbarColor">{{ data.title }}</mat-toolbar>

<mat-dialog-content>
  <mat-action-list>
    <mat-list-item
      *ngFor="let option of data.options; trackBy: trackByOption"
      [disabled]="option.isDisabled"
      [matTooltip]="option.disabledReason"
      [matTooltipDisabled]="!option.isDisabled || !option.disabledReason"
      [lines]="data.lines"
      (click)="submit(option.value)"
    >
      <h3 matListItemTitle>{{ option.label }}</h3>
      <ng-container *ngIf="option.info">{{ option.info }}</ng-container>
    </mat-list-item>
  </mat-action-list>

  <button mat-stroked-button type="button" class="w-full" mat-dialog-close>
    Cancel
  </button>
</mat-dialog-content>
