import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IAppointment,
  IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-appointment-history-card',
    templateUrl: './appointment-history-card.component.html',
    styleUrls: ['./appointment-history-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentHistoryCardComponent {
  appointment$ = new ReplaySubject<WithRef<IAppointment>>(1);
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }
}
