import { ISO_DATE_FORMAT, toMoment } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { type ElementCompact, js2xml, type Options, xml2js } from 'xml-js';
import {
  ECLAIMING_REQUEST_ATTRS,
  type IEasyclaimBulkBillServiceAttrs,
  type IEasyclaimFullyPaidServiceAttrs,
  type IEasyclaimPartPaidServiceAttrs,
  type IEasyclaimPatientAttrs,
  type IEasyclaimReferralAttrs,
  type IEasyclaimReferralProviderAttrs,
  type IEasyclaimServicingFullyPaidClaimAttrs,
  type IEasyclaimServicingPartlyPaidClaimAttrs,
  type IEasyclaimServicingProviderAttrs,
  type IEasyclaimServicingVoucherAttrs,
} from './xml-attrs';

function toXMLPayload(data: ElementCompact): string {
  const options: Options.JS2XML = { compact: true, fullTagEmptyElement: true };
  const xmlDeclaration = {
    _declaration: {
      _attributes: { version: '1.0', encoding: 'UTF-8' },
    },
  };
  return js2xml({ ...xmlDeclaration, ...data }, options);
}

export function fromXMLPayload(data: string): ElementCompact {
  const options: Options.XML2JS = { compact: true };
  return xml2js(data, options);
}

export function fullyPaidPayload(
  voucherAttrs: IEasyclaimServicingVoucherAttrs,
  servicesAttrs: IEasyclaimFullyPaidServiceAttrs[],
  patientAttrs: IEasyclaimPatientAttrs,
  servicingProviderAttrs: IEasyclaimServicingProviderAttrs,
  referralData?: IReferralDataAttrs
): string {
  const claimAttrs: IEasyclaimServicingFullyPaidClaimAttrs = {
    accountPaidInd: 'Y',
  };
  const services = servicesAttrs.map((serviceAttrs) => ({
    _attributes: serviceAttrs,
  }));
  if (!voucherAttrs.referralOverrideTypeCde && !referralData) {
    throw new Error(
      'Must include either referralOverrideTypeCde or referralData'
    );
  }
  const voucher: ElementCompact = {
    _attributes: voucherAttrs,
    service: services,
  };
  if (referralData) {
    voucher.referral = referralDataToObj(referralData);
  }
  return toXMLPayload({
    'ns1:patienteClaimingRequest': {
      _attributes: ECLAIMING_REQUEST_ATTRS,
      claim: {
        _attributes: claimAttrs,
        voucher,
        patient: { _attributes: patientAttrs },
        servicingProvider: { _attributes: servicingProviderAttrs },
      },
    },
  });
}

export function partlyPaidPayload(
  voucherAttrs: IEasyclaimServicingVoucherAttrs,
  servicesAttrs: IEasyclaimPartPaidServiceAttrs[],
  patientAttrs: IEasyclaimPatientAttrs,
  servicingProviderAttrs: IEasyclaimServicingProviderAttrs,
  referralData?: IReferralDataAttrs
): string {
  const claimAttrs: IEasyclaimServicingPartlyPaidClaimAttrs = {
    accountPaidInd: 'N',
  };
  const services = servicesAttrs.map((serviceAttrs) => ({
    _attributes: serviceAttrs,
  }));
  if (!voucherAttrs.referralOverrideTypeCde && !referralData) {
    throw new Error(
      'Must include either referralOverrideTypeCde or referralData'
    );
  }
  const voucher: ElementCompact = {
    _attributes: voucherAttrs,
    service: services,
  };
  if (referralData) {
    voucher.referral = referralDataToObj(referralData);
  }
  return toXMLPayload({
    'ns1:patienteClaimingRequest': {
      _attributes: ECLAIMING_REQUEST_ATTRS,
      claim: {
        _attributes: claimAttrs,
        voucher,
        patient: { _attributes: patientAttrs },
        servicingProvider: { _attributes: servicingProviderAttrs },
      },
    },
  });
}

export function bulkBillPayload(
  voucherAttrs: IEasyclaimServicingVoucherAttrs,
  servicesAttrs: IEasyclaimBulkBillServiceAttrs[],
  patientAttrs: IEasyclaimPatientAttrs,
  servicingProviderAttrs: IEasyclaimServicingProviderAttrs,
  referralData?: IReferralDataAttrs
): string {
  const services = servicesAttrs.map((serviceAttrs) => ({
    _attributes: serviceAttrs,
  }));

  if (!voucherAttrs.referralOverrideTypeCde && !referralData) {
    throw new Error(
      'Must include either referralOverrideTypeCde or referralData'
    );
  }
  const voucher: ElementCompact = {
    _attributes: voucherAttrs,
    service: services,
  };
  if (referralData) {
    voucher.referral = referralDataToObj(referralData);
  }

  const payload = {
    'ns1:bulkBilleClaimingRequest': {
      _attributes: ECLAIMING_REQUEST_ATTRS,
      claim: {
        voucher,
        patient: { _attributes: patientAttrs },
        servicingProvider: { _attributes: servicingProviderAttrs },
      },
    },
  };
  return toXMLPayload(payload);
}

export const EASYCLAIM_DATE_FORMAT = `${ISO_DATE_FORMAT}Z`;
export function toEasyclaimDate(date: Timestamp | Moment | Date): string {
  return toMoment(date).format(EASYCLAIM_DATE_FORMAT);
}

export interface IReferralDataAttrs {
  referralAttrs: IEasyclaimReferralAttrs;
  providerAttrs: IEasyclaimReferralProviderAttrs;
}

function referralDataToObj(
  data: IReferralDataAttrs
): ElementCompact | undefined {
  return {
    _attributes: data.referralAttrs,
    provider: {
      _attributes: data.providerAttrs,
    },
  };
}
