import {
  MeasureFormatter,
  PaymentPlanStatus,
} from '@principle-theorem/principle-core/interfaces';
import { IPaymentPlanDimension } from '@principle-theorem/reporting/interfaces';
import { BaseDimensionMeasures } from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import { MeasureTransformMap } from '../measure-properties';
import { CanBeChartedProperty, CanDoAllProperty } from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';

export class PaymentPlanDimensionMeasures
  extends BaseDimensionMeasures
  implements
    MeasureTransformMap<Pick<IPaymentPlanDimension, 'status' | 'name'>>
{
  get ref(): CanBeChartedProperty {
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('ref'),
        label: 'Payment Plan Ref',
        summary: '',
      },
      docRef,
      this.buildQuery().attributes([docRef.attributePath]).get()
    );
  }

  get total(): CanBeChartedProperty {
    const propertyName = 'total';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Payment Plan Total',
        summary: '',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery()
        .attributes([this._pathWithPrefix(propertyName)])
        .get()
    );
  }

  get name(): CanDoAllProperty {
    const name = this.measureRef('name');
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('name'),
        label: 'Payment Plan Name',
        summary: '',
      },
      docRef,
      this.buildQuery()
        .attributes([docRef.attributePath, name.attributePath])
        .get(),
      name
    );
  }

  get status(): CanDoAllProperty {
    const propertyName = 'status';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.enum<PaymentPlanStatus>(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Payment Plan Status',
        summary: '',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      PaymentPlanStatus.Draft
    );
  }
}
