import { type ISource } from '@principle-theorem/principle-core/interfaces';
import { BaseSourceHandler } from '../source/base-source-handler';
import { PatientAppointmentInvoiceAdjustmentSourceEntity } from './source/entities/appointment-invoice-adjustment';
import { PatientAppointmentInvoicePaymentSourceEntity } from './source/entities/appointment-invoice-payment';
import { PatientAppointmentProcedureSourceEntity } from './source/entities/appointment-procedure';
import { FeeScheduleSourceEntity } from './source/entities/fee-schedule';
import { PatientSourceEntity } from './source/entities/patient';
import { PatientAppointmentSourceEntity } from './source/entities/patient-appointment';
import { PatientAppointmentNoteSourceEntity } from './source/entities/patient-appointment-notes';
import { PatientAutoclaveSourceEntity } from './source/entities/patient-autoclave';
import { PatientClinicalNoteSourceEntity } from './source/entities/patient-clinical-notes';
import { PatientCommunicationSourceEntity } from './source/entities/patient-communication';
import { PatientToothDentitionSourceEntity } from './source/entities/patient-dentitions';
import { PatientDepositAdjustmentSourceEntity } from './source/entities/patient-deposit-adjustments';
import { PatientDepositPaymentSourceEntity } from './source/entities/patient-deposit-payments';
import { PatientImageSourceEntity } from './source/entities/patient-image';
import { PatientPeriodontalPatientChartSourceEntity } from './source/entities/patient-periodontal-chart';
import { PatientPeriodontalDataSourceEntity } from './source/entities/patient-periodontal-chart-data';
import { PatientRelationshipSourceEntity } from './source/entities/patient-relationship';
import { PatientToothConditionSourceEntity } from './source/entities/patient-tooth-conditions';
import { StafferSourceEntity } from './source/entities/staff';

export const PRAKTIKA_SOURCE: ISource = {
  metadata: {
    name: 'Praktika',
    version: 'v2',
  },
  configuration: [
    {
      name: 'Practice Id',
      description: 'Praktika Practice Id',
      value: '',
    },
    {
      name: 'Database Username',
      description: 'Database Username',
      value: '',
    },
    {
      name: 'Database Password',
      description: 'Database Password',
      value: '',
    },
    {
      name: 'Database Connection Name',
      description:
        'Fully Qualified Name of Database. Eg. "practice-migrations:australia-southeast1:[instance_name]"',
      value: '',
    },
    {
      name: 'Source Bucket Storage Path',
      description: `Bucket storage path that patient files live under Eg. "gs://practice-name'`,
      value: '',
    },
  ],
};

export class PraktikaSourceHandler extends BaseSourceHandler {
  migrationType = 'Praktika';
  migrationVersion = 'v2';
  source = PRAKTIKA_SOURCE;

  entityHandlers = [
    new PatientAppointmentSourceEntity(),
    new PatientAutoclaveSourceEntity(),
    new PatientClinicalNoteSourceEntity(),
    new PatientPeriodontalPatientChartSourceEntity(),
    new PatientSourceEntity(),
    new PatientDepositAdjustmentSourceEntity(),
    new PatientDepositPaymentSourceEntity(),
    new PatientAppointmentNoteSourceEntity(),
    new PatientAppointmentProcedureSourceEntity(),
    new PatientAppointmentInvoiceAdjustmentSourceEntity(),
    new PatientAppointmentInvoicePaymentSourceEntity(),
    new PatientCommunicationSourceEntity(),
    new PatientToothConditionSourceEntity(),
    new PatientToothDentitionSourceEntity(),
    new PatientRelationshipSourceEntity(),
    new PatientPeriodontalDataSourceEntity(),
    new PatientImageSourceEntity(),
    new FeeScheduleSourceEntity(),
    new StafferSourceEntity(),
  ];
}
