import {
  Arch,
  IClinicalChart,
  IPatient,
  ITooth,
  IToothRef,
  PatientCollection,
  Quadrant,
} from '@principle-theorem/principle-core/interfaces';
import {
  IReffable,
  WithRef,
  addDoc,
  all$,
  firstResult$,
  initFirestoreModel,
  saveDoc,
  snapshot,
  subCollection,
} from '@principle-theorem/shared';
import {
  CollectionReference,
  DocumentReference,
  orderBy,
} from '@principle-theorem/shared';
import { omit, uniq } from 'lodash';
import { Observable } from 'rxjs';
import { isUpperQuadrant } from './core/quadrant';
import { isSameToothRef } from './core/tooth';
import { MockAllTeeth } from './core/tooth.mock';
import { TreatmentPlanProposal } from './treatment/treatment-plan-proposal';

export class ClinicalChart {
  static init(overrides?: Partial<IClinicalChart>): IClinicalChart {
    return {
      conditions: [],
      immutable: false,
      teeth: MockAllTeeth(),
      flaggedTreatment: TreatmentPlanProposal.init(),
      perioRecords: [],
      ...initFirestoreModel(),
      ...overrides,
    };
  }

  static col(
    patient: IReffable<IPatient>
  ): CollectionReference<IClinicalChart> {
    return subCollection<IClinicalChart>(
      patient.ref,
      PatientCollection.ClinicalCharts
    );
  }

  static all$(
    patient: IReffable<IPatient>
  ): Observable<WithRef<IClinicalChart>[]> {
    return all$(ClinicalChart.col(patient));
  }

  static async cloneFromLatest(
    patient: IReffable<IPatient>
  ): Promise<DocumentReference<IClinicalChart>> {
    await ClinicalChart.lockClinicalCharts(patient);
    const latestChart = await snapshot(ClinicalChart.getLatestChart$(patient));
    const newChart = ClinicalChart.init({
      ...omit(latestChart, 'createdAt', 'updatedAt'),
      immutable: false,
    });
    return addDoc(ClinicalChart.col(patient), newChart);
  }

  static async lockClinicalCharts(patient: IReffable<IPatient>): Promise<void> {
    const charts: WithRef<IClinicalChart>[] = await snapshot(
      ClinicalChart.all$(patient)
    );
    const promises: Promise<void>[] = charts.map(async (chart) => {
      ClinicalChart.lock(chart);
      await saveDoc(chart);
    });

    await Promise.all(promises);
  }

  static getLatestChart$(
    patient: IReffable<IPatient>
  ): Observable<WithRef<IClinicalChart> | undefined> {
    return firstResult$(
      ClinicalChart.col(patient),
      orderBy('createdAt', 'desc')
    );
  }

  static lock(chart: IClinicalChart): void {
    if (chart.immutable) {
      return;
    }
    chart.immutable = true;
  }

  static clone(chart: IClinicalChart): IClinicalChart {
    return ClinicalChart.init({
      conditions: chart.conditions,
      flaggedTreatment: chart.flaggedTreatment,
      immutable: chart.immutable,
      teeth: chart.teeth,
      perioRecords: [],
    });
  }

  static getTooth(
    chart: IClinicalChart,
    toothRef: IToothRef
  ): ITooth | undefined {
    return chart.teeth.find((tooth: ITooth) =>
      isSameToothRef(tooth.toothRef, toothRef)
    );
  }

  static addTooth(chart: IClinicalChart, tooth: ITooth): void {
    chart.teeth.push(tooth);
  }

  static hasToothIndex(
    chart: IClinicalChart,
    quadrant: Quadrant,
    quadrantIndex: number
  ): boolean {
    return chart.teeth.some(
      (tooth: ITooth) =>
        tooth.toothRef.quadrantIndex === quadrantIndex &&
        tooth.toothRef.quadrant === quadrant
    );
  }

  static getNextToothIndex(chart: IClinicalChart, quadrant: Quadrant): number {
    return chart.teeth
      .filter((tooth: ITooth) => tooth.toothRef.quadrant === quadrant)
      .map((tooth: ITooth) => tooth.toothRef.quadrantIndex)
      .reduce((max: number, current: number) => {
        return max > current ? max : current + 1;
      }, 1);
  }

  static changeRoots(
    chart: IClinicalChart,
    roots: number,
    tooth: IToothRef
  ): IClinicalChart {
    const currentTooth: ITooth | undefined = ClinicalChart.getTooth(
      chart,
      tooth
    );
    if (!currentTooth) {
      return chart;
    }
    currentTooth.roots = roots;
    return chart;
  }

  static getArches(chart: IClinicalChart): Arch[] {
    return uniq(
      chart.teeth.map((tooth) =>
        isUpperQuadrant(tooth.toothRef.quadrant) ? Arch.Upper : Arch.Lower
      )
    );
  }

  static getQuadrants(chart: IClinicalChart): Quadrant[] {
    return uniq(chart.teeth.map((tooth) => tooth.toothRef.quadrant));
  }
}
