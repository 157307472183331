import {
  SterilisationRecord,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  FailedDestinationEntityRecord,
  IDestinationEntityRecord,
  ITranslationMap,
  type IAppointment,
  type IDestinationEntity,
  type IGetRecordResponse,
  type IPatient,
  type IPractice,
  type IPracticeMigration,
  type IStaffer,
  type ISterilisationRecord,
  IDestinationEntityJobRunOptions,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  asyncForEach,
  getError,
  toNamedDocument,
  type INamedDocument,
  type WithRef,
  snapshotCombineLatest,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PATIENT_APPOINTMENT_RESOURCE_TYPE } from '../../../destination/entities/patient-appointments';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import {
  BasePatientSterilisationDestinationEntity,
  ISterilisationRecordBaseJobData,
  ISterilisationRecordMigrationData,
} from '../../../destination/entities/sterilisation-records';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  IPraktikaPatient,
  PatientSourceEntity,
} from '../../source/entities/patient';
import { PatientAppointmentSourceEntity } from '../../source/entities/patient-appointment';
import {
  PatientAutoclaveSourceEntity,
  type IPraktikaAutoclaveRecord,
  type IPraktikaPatientAutoclaveTranslations,
} from '../../source/entities/patient-autoclave';
import { PraktikaStafferMappingHandler } from '../mappings/staff';
import { PatientAppointmentDestinationEntity } from './patient-appointments';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';

interface IPatientSterilisationJobData
  extends ISterilisationRecordBaseJobData<IPraktikaPatient> {
  staff: WithRef<ITranslationMap<IStaffer>>[];
}

export class PatientSterilisationDestinationEntity extends BasePatientSterilisationDestinationEntity<
  IPraktikaPatient,
  IPatientSterilisationJobData
> {
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
    sterilisationRecords: new PatientAutoclaveSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    appointments: new PatientAppointmentDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new PraktikaStafferMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientSterilisationJobData[]> {
    const staff$ = snapshotCombineLatest([
      this.customMappings.staff.getRecords(translationMapHandler),
      translationMapHandler.getByType<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(
      take(1),
      map(([staff, mappedStaff]) => [...staff, ...mappedStaff])
    );

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      staff$,
    ]).pipe(
      map(([sourcePatients, staff]) =>
        sourcePatients.map((sourcePatient) => ({
          sourcePatient,
          staff,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientSterilisationJobData
  ): Promise<
    | ISterilisationRecordMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sterilisationRecords =
      await this.sourceEntities.sterilisationRecords.filterRecords(
        migration,
        'patientId',
        data.sourcePatient.data.data.patient_id.toString()
      );

    const patientRef = await translationMap.getDestination<IPatient>(
      data.sourcePatient.data.data.patient_id.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `No patient for id ${data.sourcePatient.data.data.patient_id}`
      );
    }

    const patient = await Firestore.getDoc(patientRef);

    try {
      const records = await asyncForEach(
        sterilisationRecords,
        async (sterilisationRecord) => {
          const appointmentId =
            sterilisationRecord.data.data.appointment_id.toString();
          const appointmentMap =
            await translationMap.getDestination<IAppointment>(
              appointmentId,
              PATIENT_APPOINTMENT_RESOURCE_TYPE
            );

          if (!appointmentMap) {
            throw new Error(`No appointment for id ${appointmentId}`);
          }

          const stafferMap = data.staff.find(
            (staffer) =>
              staffer.sourceIdentifier ===
              sterilisationRecord.data.data.created_by.toString()
          );

          const appointment = await Firestore.getDoc(appointmentMap);
          const staffer = stafferMap?.destinationIdentifier
            ? await Firestore.getDoc(stafferMap.destinationIdentifier)
            : undefined;

          const sterilisationData = this._buildSterilisationData(
            sterilisationRecord,
            appointment,
            patient,
            appointment.practice,
            staffer
          );

          return {
            ...sterilisationData,
            createdAt: sterilisationRecord.data.translations.date,
            sourceIdentifier: sterilisationRecord.record.uid,
          };
        }
      );

      return {
        sterilisationRecords: records,
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  private _buildSterilisationData(
    autoclaveRecord: IGetRecordResponse<
      IPraktikaAutoclaveRecord,
      IPraktikaPatientAutoclaveTranslations
    >,
    appointment: WithRef<IAppointment>,
    patient: WithRef<IPatient>,
    practice: INamedDocument<IPractice>,
    staffer?: WithRef<IStaffer>
  ): ISterilisationRecord {
    return SterilisationRecord.init({
      data: autoclaveRecord.data.data.label,
      practice: toNamedDocument(practice),
      appointment: appointment.ref,
      patient: toNamedDocument(patient),
      scannedBy: staffer ? stafferToNamedDoc(staffer) : undefined,
    });
  }
}
