import {
  EventType,
  IBrand,
  IEventable,
  IEventTimePeriod,
  IPractice,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  getEnumValues,
  INamedDocument,
  IReffable,
  isSameRef,
  ITimePeriod,
  multiFilter,
  multiSwitchMap,
  reduceToSingleArray,
  TimeBucket,
  toNamedDocument,
  toTimePeriod,
  WithRef,
} from '@principle-theorem/shared';
import { difference, get, remove } from 'lodash';
import { Duration } from 'moment-timezone';
import { combineLatest, Observable, OperatorFunction } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { stafferToNamedDoc } from '../common';
import { EventableQueries } from '../eventable-queries';
import { Roster } from '../staffer/roster';
import { Staffer } from '../staffer/staffer';
import {
  DEFAULT_TIME_INCREMENT,
  OptionFinderQuery,
} from './event-option-finder';
import { EventTimePeriod } from './event-time-period';

export interface IStafferTimePeriods {
  staffer: WithRef<IStaffer>;
  times: ITimePeriod[];
}

export function getStafferEventables$<T extends object>(
  timePeriod: ITimePeriod,
  staff: IReffable<IStaffer>[],
  practice: INamedDocument<IPractice>,
  brand: IReffable<IBrand>,
  includedTypes?: EventType[]
): Observable<IEventable<T>[]> {
  const participants = staff.map((staffer) => staffer.ref);
  const excludedTypes = includedTypes
    ? difference(getEnumValues(EventType), includedTypes)
    : [EventType.GapCandidate];

  return EventableQueries.getEventables$<T>(
    brand,
    timePeriod,
    excludedTypes,
    participants,
    practice.ref
  ).pipe(
    switchMap((events) =>
      Staffer.buildEventsFromForecast$(
        staff,
        timePeriod,
        events,
        practice
      ).pipe(map((breaks) => [...events, ...breaks]))
    ),
    map((stafferEvents) =>
      stafferEvents
        .filter((eventable): boolean =>
          eventable && eventable.event ? true : false
        )
        .filter((eventable): boolean =>
          eventable.event.practice
            ? isSameRef(eventable.event.practice, practice)
            : false
        )
    )
  );
}

export function stafferAvailableTimes(
  staffer: WithRef<IStaffer>,
  brand: WithRef<IBrand>,
  practice?: INamedDocument<IPractice>,
  duration?: Duration,
  allowOverlapping: boolean = false,
  timeIncrement: Duration = DEFAULT_TIME_INCREMENT,
  includedTypes?: EventType[]
): OperatorFunction<OptionFinderQuery, IEventTimePeriod[]> {
  return (source) => {
    if (!practice) {
      return source.pipe(stafferAllAvailableTimes(staffer, brand, duration));
    }

    return combineLatest([
      source.pipe(
        switchMap(([from, to]) =>
          getStafferEventables$(
            { from, to },
            [staffer],
            practice,
            brand,
            includedTypes
          )
        )
      ),
      source.pipe(
        switchMap(([from, to]) =>
          Roster.rosteredTimes$(staffer, { from, to }, brand, practice)
        )
      ),
    ]).pipe(
      map(([usedTimes, rosteredTimes]) => {
        const extraRosters = remove(
          usedTimes,
          (usedTime) => usedTime.event.type === EventType.RosteredOn
        ).map((rosterEvent) =>
          toTimePeriod(rosterEvent.event.from, rosterEvent.event.to)
        );
        const fullRoster = [...rosteredTimes, ...extraRosters];

        if (!allowOverlapping) {
          return buildStafferAvailableTimes(
            fullRoster,
            usedTimes,
            timeIncrement,
            practice,
            staffer,
            duration
          );
        }

        return buildStafferAllTimes(
          fullRoster,
          timeIncrement,
          usedTimes,
          practice,
          staffer,
          duration
        );
      })
    );
  };
}

function buildStafferAllTimes(
  rosteredTimes: ITimePeriod[],
  timeIncrement: Duration,
  usedTimes: IEventable[],
  practice: INamedDocument<IPractice>,
  staffer: WithRef<IStaffer>,
  duration?: Duration
): IEventTimePeriod[] {
  const allPossibleTimes = getAllPossibleTimes(
    duration,
    rosteredTimes,
    timeIncrement
  );

  return allPossibleTimes.map((time) => {
    const overlappingEvents = EventTimePeriod.findOverlappingEventables(
      usedTimes,
      time
    );

    const overlapDuration = EventTimePeriod.calculateOverlapDuration(
      overlappingEvents,
      time
    );

    return EventTimePeriod.init({
      ...time,
      practice: toNamedDocument(practice),
      staffer: stafferToNamedDoc(staffer),
      overlappingEvents,
      overlapDuration,
    });
  });
}

function getAllPossibleTimes(
  duration: Duration | undefined,
  rosteredTimes: ITimePeriod[],
  timeIncrement: Duration
): ITimePeriod[] {
  if (duration) {
    return new TimeBucket(rosteredTimes)
      .clone()
      .exhaustTimeOptions({
        duration,
        timeIncrement,
        keepSmallOptions: true,
      })
      .get();
  }
  return new TimeBucket(rosteredTimes).clone().mergeOverlapping().sort().get();
}

export function buildStafferAvailableTimes(
  rosteredTimes: ITimePeriod[],
  usedTimes: IEventable[],
  timeIncrement: Duration,
  practice: INamedDocument<IPractice>,
  staffer: WithRef<IStaffer>,
  duration?: Duration
): IEventTimePeriod[] {
  const usedEvents = TimeBucket.fromEvents(
    usedTimes
      .filter(
        (usedTime) =>
          get(usedTime, 'isBlocking', true) &&
          usedTime.event.type !== EventType.RosteredOn
      )
      .map((usedTime) => usedTime.event)
  )
    .mergeOverlapping()
    .sort()
    .get();

  const timeBucket = new TimeBucket(rosteredTimes).clone().subtract(usedEvents);

  if (duration) {
    timeBucket.exhaustTimeOptions({
      duration,
      timeIncrement,
      keepSmallOptions: true,
    });
  }

  return timeBucket
    .sort()
    .get()
    .map((time) => {
      const overlappingEvents = EventTimePeriod.findOverlappingEventables(
        usedTimes,
        time
      );
      const adjacentEvents = EventTimePeriod.findAdjacentEventables(
        usedTimes,
        time
      );
      const overlapDuration = EventTimePeriod.calculateOverlapDuration(
        overlappingEvents,
        time
      );
      return EventTimePeriod.init({
        ...time,
        practice,
        staffer: stafferToNamedDoc(staffer),
        overlappingEvents,
        overlapDuration,
        adjacentEvents,
      });
    });
}

export function stafferAllAvailableTimes(
  staffer: WithRef<IStaffer>,
  brand: WithRef<IBrand>,
  duration?: Duration,
  allowOverlapping: boolean = false,
  timeIncrement?: Duration
): OperatorFunction<OptionFinderQuery, IEventTimePeriod[]> {
  return (source) => {
    return Staffer.practices$(staffer).pipe(
      multiSwitchMap((practice) =>
        source.pipe(
          stafferAvailableTimes(
            staffer,
            brand,
            practice,
            duration,
            allowOverlapping,
            timeIncrement
          )
        )
      ),
      map(reduceToSingleArray)
    );
  };
}

export function practiceStaffAvailableTimes(
  practice: WithRef<IPractice>,
  brand: WithRef<IBrand>,
  duration?: Duration,
  allowOverlapping: boolean = false,
  implementors: INamedDocument<IStaffer>[] = [],
  includedTypes?: EventType[],
  timeIncrement = DEFAULT_TIME_INCREMENT
): OperatorFunction<OptionFinderQuery, IEventTimePeriod[]> {
  return (source) => {
    return Staffer.practitionersByPractice$(practice).pipe(
      multiFilter((staffer) =>
        implementors.length
          ? implementors.some((exclusion) =>
              isSameRef(exclusion.ref, staffer.ref)
            )
          : true
      ),
      multiSwitchMap((staffer) =>
        source.pipe(
          stafferAvailableTimes(
            staffer,
            brand,
            practice,
            duration,
            allowOverlapping,
            timeIncrement,
            includedTypes
          )
        )
      ),
      map(reduceToSingleArray)
    );
  };
}

export function allPractitionersAvailableTimes(
  brand: WithRef<IBrand>,
  duration?: Duration,
  allowOverlapping: boolean = false,
  timeIncrement?: Duration
): OperatorFunction<OptionFinderQuery, IEventTimePeriod[]> {
  return (source) => {
    return Staffer.practitionersByBrand$(brand).pipe(
      multiSwitchMap((practitioner) =>
        source.pipe(
          stafferAllAvailableTimes(
            practitioner,
            brand,
            duration,
            allowOverlapping,
            timeIncrement
          )
        )
      ),
      map(reduceToSingleArray)
    );
  };
}

export function buildEventTimePeriodFromEvent$(
  brand: WithRef<IBrand>,
  practice: INamedDocument<IPractice>,
  staffer: WithRef<IStaffer>,
  timePeriod: ITimePeriod
): Observable<IEventTimePeriod> {
  return getStafferEventables$(timePeriod, [staffer], practice, brand).pipe(
    map((usedTimes) => {
      const overlappingEvents = EventTimePeriod.findOverlappingEventables(
        usedTimes,
        timePeriod
      );
      const adjacentEvents = EventTimePeriod.findAdjacentEventables(
        usedTimes,
        timePeriod
      );
      const overlapDuration = EventTimePeriod.calculateOverlapDuration(
        overlappingEvents,
        timePeriod
      );
      return EventTimePeriod.init({
        ...timePeriod,
        practice,
        staffer: stafferToNamedDoc(staffer),
        overlappingEvents,
        overlapDuration,
        adjacentEvents,
      });
    })
  );
}
