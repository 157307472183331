import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { type IPricingRuleItem } from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { type PricingRuleFormGroup } from './pricing-rule.formgroup';

@Component({
    selector: 'pr-pricing-rule',
    templateUrl: './pricing-rule.component.html',
    styleUrls: ['./pricing-rule.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PricingRuleComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  form$ = new ReplaySubject<PricingRuleFormGroup>(1);
  min$ = new ReplaySubject<number>(1);
  disabled$ = new BehaviorSubject<boolean>(false);
  rangeEnabled$ = new ReplaySubject<boolean>(1);
  defaultRuleItem$ = new ReplaySubject<IPricingRuleItem | undefined>(1);

  constructor() {
    combineLatest([this.form$, this.disabled$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([form, disabled]) => {
        if (disabled) {
          form.controls.endUnit.disable();
          return;
        }
        form.controls.endUnit.enable();
      });

    combineLatest([this.form$, this.min$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([form, min]) =>
        form.controls.endUnit.setValidators(Validators.min(min))
      );
  }

  @Input()
  set defaultRuleItem(defaultRuleItem: IPricingRuleItem | undefined) {
    if (defaultRuleItem) {
      this.defaultRuleItem$.next(defaultRuleItem);
    }
  }

  @Input()
  set rangeEnabled(rangeEnabled: boolean) {
    this.rangeEnabled$.next(rangeEnabled);
  }

  @Input()
  set disabled(disabled: boolean) {
    this.disabled$.next(disabled);
  }

  @Input()
  set min(min: number) {
    if (min) {
      this.min$.next(min);
    }
  }

  @Input()
  set form(form: PricingRuleFormGroup) {
    if (form) {
      this.form$.next(form);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
