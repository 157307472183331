import { type ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { NoteComponent } from '../../note/note.component';
import { BaseInteractionService } from './base-interaction.service';

@Injectable()
export class NoteService extends BaseInteractionService<NoteComponent> {
  override icon = 'note';
  override name = 'Note';
  override dialogComponent: ComponentType<NoteComponent> = NoteComponent;
}
