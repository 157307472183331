import {
  getMentions,
  IMention,
  initVersionedSchema,
  MixedSchema,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  IInteraction,
  InteractionType,
  IPractice,
  IRecurringTaskConfiguration,
  IStaffer,
  ITask,
  ITeam,
  MentionResourceType,
  PracticeCollection,
  TaskPriority,
  TaskStatus,
  TaskType,
} from '@principle-theorem/principle-core/interfaces';
import {
  all$,
  AtLeast,
  CollectionReference,
  doc$,
  DocumentReference,
  getParentDocRef,
  initFirestoreModel,
  IReffable,
  isSameRef,
  snapshot,
  subCollection,
  toMoment,
  toTimestamp,
  WithRef,
} from '@principle-theorem/shared';
import { flatten, uniqWith } from 'lodash';
import * as moment from 'moment-timezone';
import { Observable, of } from 'rxjs';
import { stafferToNamedDoc } from '../common';
import { Interaction } from '../interaction/interaction';
import { toMention } from '../mention/mention';
import { WorkflowItem } from '../workflow-item';
import { Moment } from 'moment-timezone';

export class Task extends WorkflowItem {
  static init(overrides: AtLeast<ITask, 'title'>): ITask {
    const content = {
      description: initVersionedSchema(),
      interactions: [],
      title: overrides.title,
    };
    return {
      priority: TaskPriority.Low,
      status: TaskStatus.Open,
      type: TaskType.Misc,
      mentionRefs: Task.getAllMentionRefs(content),
      statusHistory: [],
      visibleFrom: toTimestamp(moment().startOf('day')),
      ...content,
      ...initFirestoreModel(),
      ...overrides,
    };
  }

  static col(practice: IReffable<IPractice>): CollectionReference<ITask> {
    return subCollection<ITask>(practice.ref, PracticeCollection.Tasks);
  }

  static all$(practice: WithRef<IPractice>): Observable<WithRef<ITask>[]> {
    return all$(Task.col(practice));
  }

  static owner$(
    task: WithRef<ITask>
  ): Observable<WithRef<IStaffer> | undefined> {
    if (!task.owner) {
      return of(undefined);
    }
    return doc$<IStaffer>(task.owner.ref);
  }

  static practice$(task: WithRef<ITask>): Observable<WithRef<IPractice>> {
    return doc$(getParentDocRef<IPractice>(task.ref));
  }

  static assignedUser$(
    task: ITask | IRecurringTaskConfiguration
  ): Observable<WithRef<IStaffer> | undefined> {
    if (!task.assignedUser) {
      return of(undefined);
    }
    return doc$<IStaffer>(task.assignedUser.ref);
  }

  static assignedTeam$(
    task: ITask | IRecurringTaskConfiguration
  ): Observable<WithRef<ITeam> | undefined> {
    if (!task.assignedTeam) {
      return of(undefined);
    }
    return doc$<ITeam>(task.assignedTeam.ref);
  }

  static recurrenceConfiguration$(
    task: WithRef<ITask>
  ): Observable<WithRef<IRecurringTaskConfiguration> | undefined> {
    if (!task.recurrenceConfiguration) {
      return of(undefined);
    }
    return doc$<IRecurringTaskConfiguration>(task.recurrenceConfiguration);
  }

  static late(item: Pick<ITask, 'dueDate' | 'status'>): boolean {
    if (!item.dueDate || Task.complete(item)) {
      return false;
    }

    return moment().isAfter(toMoment(item.dueDate));
  }

  static complete(task: Pick<ITask, 'status'>): boolean {
    return task.status === TaskStatus.Complete;
  }

  static recurring(task: ITask): boolean {
    return task.recurrenceConfiguration ? true : false;
  }

  static addInteraction(task: ITask, data: Partial<IInteraction>): void {
    const interaction = Interaction.init(data);
    task.interactions.push(interaction);
    task.mentionRefs = Task.getAllMentionRefs(task);
  }

  static open(task: WithRef<ITask>, staffer?: WithRef<IStaffer>): void {
    Task.updateStatus(task, TaskStatus.Open);
    task.completedDate = undefined;
    const owner = staffer ? stafferToNamedDoc(staffer) : undefined;

    if (!owner) {
      Task.addInteraction(task, {
        title: [toTextContent(`System marked task as incomplete`)],
        type: InteractionType.TaskReopen,
      });
      return;
    }

    Task.addInteraction(task, {
      title: [
        toMentionContent(toMention(owner, MentionResourceType.Staffer)),
        toTextContent(` reopened the task`),
      ],
      type: InteractionType.TaskReopen,
      owner,
    });
  }

  static close(task: WithRef<ITask>, staffer?: WithRef<IStaffer>): void {
    Task.updateStatus(task, TaskStatus.Complete);
    task.completedDate = toTimestamp();
    const owner = staffer ? stafferToNamedDoc(staffer) : undefined;

    if (!owner) {
      Task.addInteraction(task, {
        title: [toTextContent(`System marked task as complete`)],
        type: InteractionType.TaskComplete,
      });
      return;
    }

    Task.addInteraction(task, {
      title: [
        toMentionContent(toMention(owner, MentionResourceType.Staffer)),
        toTextContent(` completed the task`),
      ],
      type: InteractionType.TaskComplete,
      owner,
    });
  }

  static updateStatus(task: WithRef<ITask>, status: TaskStatus): void {
    if (task.status === status) {
      return;
    }
    task.statusHistory.push({
      status: task.status,
      updatedAt: toTimestamp(),
    });
    task.status = status;
  }

  static createdInteraction(task: WithRef<ITask>): IInteraction | undefined {
    return task.interactions.find(
      (interaction) => interaction.type === InteractionType.TaskCreate
    );
  }

  static createdInteractionSummary(task: WithRef<ITask>): MixedSchema {
    const interaction: IInteraction | undefined = Task.createdInteraction(task);
    return interaction
      ? Task.summariseInteraction(interaction)
      : initVersionedSchema();
  }

  static async getAssignee(
    task: ITask | IRecurringTaskConfiguration
  ): Promise<WithRef<IStaffer> | WithRef<ITeam> | undefined> {
    const assignedUser = await snapshot(Task.assignedUser$(task));
    if (assignedUser) {
      return assignedUser;
    }
    const assignedTeam = await snapshot(Task.assignedTeam$(task));
    if (assignedTeam) {
      return assignedTeam;
    }
  }

  static getAllMentions(
    task: AtLeast<ITask, 'title' | 'description' | 'interactions'>
  ): IMention<string>[] {
    const titleMentions = getMentions(task.title);
    const descriptionMentions = task.description
      ? getMentions(task.description)
      : [];
    const interactionMentions = flatten(
      task.interactions.map((interaction) => getMentions(interaction.content))
    );
    return [...titleMentions, ...descriptionMentions, ...interactionMentions];
  }

  static getAllMentionRefs(
    task: AtLeast<ITask, 'title' | 'description' | 'interactions'>
  ): DocumentReference<unknown>[] {
    const mentions = Task.getAllMentions(task);
    return uniqWith(
      mentions.map((mention) => mention.resource.ref),
      isSameRef
    );
  }

  static getDeletedExpiry(currentTime?: Moment): Moment {
    return moment(currentTime).subtract(1, 'week');
  }
}
