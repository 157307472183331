import { type RawInlineNodes } from '@principle-theorem/editor';
import {
  type INamedDocument,
  isINamedDocument,
  isObject,
} from '@principle-theorem/shared';
import {
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import { type IPrincipleMention } from '../../mention/mention';
import { type ITask } from '../../task/task';

export interface ITaskResources {
  brand: INamedDocument;
  practice: INamedDocument;
  owner?: INamedDocument;
  assignee?: INamedDocument;
  interactor?: INamedDocument;
  task: {
    title: RawInlineNodes;
    ref: DocumentReference<ITask>;
    dueDate?: Timestamp;
  };
}

export interface ITaskMentionedUserResources extends ITaskResources {
  mention: IPrincipleMention;
  comment: string;
}
export type IAddedTaskResources = ITaskResources;
export type ICompletedTaskResources = ITaskResources;
export type IReopenedTaskResources = ITaskResources;

export function isTaskResource(data: unknown): data is ITaskResources {
  return (
    isObject(data) &&
    isINamedDocument(data.brand) &&
    isINamedDocument(data.practice) &&
    'task' in data &&
    isObject(data.task) &&
    'title' in data.task &&
    'ref' in data.task
  );
}
