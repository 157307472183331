import { type DocumentReference } from '@principle-theorem/shared';
import {
  type INamedDocument,
  isArray,
  isDocRef,
  isObject,
  TypeGuard,
  type WithRef,
} from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';
import { type IChecklistItem } from '../../appointment/checklist-item';
import { type IAssetRequirements } from '../../assets/asset-requirements';
import { type IEducationalAid } from '../../assets/educational-aid';
import { type IAutomatedNotificationConfiguration } from '../../notification/automated-notification';
import { type IGeneratedTaskConfiguration } from '../../task/generated-task';
import { type ITreatmentCategory } from '../../treatment-category';
import { type IChartedItemConfiguration } from '../charted-item-configuration';
import {
  type IServiceCodeGroup,
  type IServiceSmartGroup,
} from '../service-provider/providers/ada-code';
import { type IScopedServiceCode } from '../service-provider/service-item';

export interface ITreatmentConfigurationRef
  extends INamedDocument<ITreatmentConfiguration> {
  quantity: number;
}

export interface ITreatmentConfiguration extends IChartedItemConfiguration {
  duration: number;
  description: string;
  checklists: IChecklistItem[];
  educationalAids: IEducationalAid[];
  assetRequirements: IAssetRequirements;
  conditions: DocumentReference[];
  serviceCodeGroups: IServiceCodeGroup[];
  serviceCodeSmartGroups: IServiceSmartGroup[];
  packages: ITreatmentPackage[];
  category?: DocumentReference<ITreatmentCategory>;
  automatedNotificationRefs: DocumentReference<IAutomatedNotificationConfiguration>[];
  generatedTaskRefs: DocumentReference<IGeneratedTaskConfiguration>[];
}

export const isTreatmentConfiguration =
  TypeGuard.interface<ITreatmentConfiguration>({
    deleted: isBoolean,
    availableSurfaces: isArray,
    clinicalNotes: TypeGuard.noGuard(),
    display: TypeGuard.noGuard(),
    name: isString,
    duration: isNumber,
    description: isString,
    checklists: isArray,
    educationalAids: TypeGuard.noGuard(),
    assetRequirements: isObject,
    conditions: isArray,
    serviceCodeGroups: isArray,
    serviceCodeSmartGroups: isArray,
    packages: isArray,
    category: TypeGuard.undefinedOr(isDocRef),
    automatedNotificationRefs: TypeGuard.arrayOf(isDocRef),
    generatedTaskRefs: TypeGuard.arrayOf(isDocRef),
  });

export interface IGeneralTreatmentConfiguration {
  name: string;
  duration: number;
  description: string;
  category?: DocumentReference<ITreatmentCategory>;
}

export interface ITreatmentSumData {
  quantity: number;
  treatment: WithRef<ITreatmentConfiguration>;
}

export interface ITreatmentCategoryGroup {
  category?: WithRef<ITreatmentCategory>;
  treatments: WithRef<ITreatmentConfiguration>[];
}

export interface ITreatmentPackage {
  uid: string;
  name: string;
  description: string;
  isDefault: boolean;
  priceOverrides: ITreatmentPackagePriceOverride[];
}

export interface ITreatmentPackagePriceOverride extends IScopedServiceCode {
  price?: number;
}
