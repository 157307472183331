import { ITimePeriod } from '@principle-theorem/shared';
import type * as moment from 'moment-timezone';

export interface ITimelineNode<T> {
  from: moment.Moment;
  to: moment.Moment;
  dragEnabled: boolean;
  resizeEnabled: boolean;
  data: T;
}

export interface ITimelineDataGroup<T, G> {
  group: G;
  nodes: ITimelineNode<T>[][];
  deadzones: IDeadzone[];
}

export interface IDeadzone extends ITimePeriod {
  isBlocking: boolean;
}

export type GroupDeadzoneMap = Partial<Record<string, IDeadzone[]>>;
export type GroupNodesMap<T, G> = Partial<
  Record<string, INodeWithGroup<T, G>[]>
>;
export type GroupMap<G> = Partial<Record<string, G>>;

export type DayEventMap<T> = Partial<Record<string, T[]>>;
export type DayGroupMap<G> = Partial<Record<string, G[]>>;
export type DayDeadzoneMap = Partial<Record<string, GroupDeadzoneMap>>;
export type DayNodesMap<T, G> = Partial<Record<string, GroupNodesMap<T, G>>>;
export type DayRangeMap = Partial<Record<string, ITimePeriod>>;

export interface INodeWithGroup<T, G> {
  node: ITimelineNode<T>;
  group: G;
}

export interface ITimelineDay<T, G> {
  day: ITimePeriod;
  initialTrackIndex: number;
  isClosed: boolean;
  groups: ITimelineDataGroup<T, G>[];
}
