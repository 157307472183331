import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { CreateConfiguration } from '@principle-theorem/ng-principle-shared';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { type IBrand } from '@principle-theorem/principle-core/interfaces';
import { Brand } from '@principle-theorem/principle-core';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IBrandFormData extends Pick<IBrand, 'name'> {}

@Component({
  selector: 'pr-create-brand',
  templateUrl: './create-brand.component.html',
  styleUrls: ['./create-brand.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateBrandComponent extends CreateConfiguration<
  CreateBrandComponent,
  IBrandFormData,
  IBrand
> {
  form: TypedFormGroup<IBrandFormData> = new TypedFormGroup<IBrandFormData>({
    name: new TypedFormControl('', Validators.required),
  });

  protected _transformData(formData: IBrandFormData): IBrand {
    return Brand.init(formData);
  }
}
