import { IIntegration, IntegrationType } from '@principle-theorem/integrations';

export interface ITNZIntegrationData {
  enabled: boolean;
  testModeEnabled: boolean;
  outboundCost?: number;
  inboundCost?: number;
}

export function isTNZIntegration(
  item: IIntegration
): item is IIntegration<ITNZIntegrationData> {
  return item.type === IntegrationType.TNZ;
}
