<button
  mat-icon-button
  matTooltip="Sort by"
  [matMenuTriggerFor]="sortMenu"
  class="compact"
>
  <mat-icon>sort</mat-icon>
</button>

<mat-menu #sortMenu="matMenu">
  @for (option of stepDisplay.treatmentStepSortOptions; track option) {
    <button
      mat-menu-item
      (click)="stepDisplay.updateUserSortPreference(option)"
    >
      @if (option === stepDisplay.activeSortOption()) {
        <span class="text-primary-500">{{ option | titlecase }}</span>
      } @else {
        <span>{{ option | titlecase }}</span>
      }
    </button>
  }
</mat-menu>
