import {
  Directive,
  ElementRef,
  HostBinding,
  type OnDestroy,
} from '@angular/core';
import { NavigationFocusService } from './navigation-focus.service';

let uid = 0;

@Directive({
  selector: '[ptFocusOnNavigation]',
})
export class NavigationFocusDirective implements OnDestroy {
  @HostBinding('tabindex') readonly tabindex = '-1';
  @HostBinding('style.outline') readonly outline = 'none';

  constructor(
    private element: ElementRef<HTMLElement>,
    private navigationFocusService: NavigationFocusService
  ) {
    if (!element.nativeElement.id) {
      element.nativeElement.id = `skip-link-target-${uid++}`;
    }
    this.navigationFocusService.requestFocusOnNavigation(element.nativeElement);
    this.navigationFocusService.requestSkipLinkFocus(element.nativeElement);
  }

  ngOnDestroy(): void {
    this.navigationFocusService.relinquishFocusOnNavigation(
      this.element.nativeElement
    );
    this.navigationFocusService.relinquishSkipLinkFocus(
      this.element.nativeElement
    );
  }
}
