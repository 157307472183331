import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { type Moment } from 'moment-timezone';
import { TrackByFunctions } from '@principle-theorem/ng-shared';

@Component({
  selector: 'pr-week-row',
  templateUrl: './week-row.component.html',
  styleUrls: ['./week-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeekRowComponent {
  trackByDay = TrackByFunctions.date<Moment>();
  days$: ReplaySubject<Moment[]> = new ReplaySubject<Moment[]>(1);

  @Input()
  set days(days: Moment[]) {
    if (!days) {
      return;
    }
    this.days$.next(days);
  }
}
