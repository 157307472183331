import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  type IBrand,
  type ITreatmentTemplate,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-treatment-template',
    templateUrl: './treatment-template.component.html',
    styleUrls: ['./treatment-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreatmentTemplateComponent {
  brand$ = new ReplaySubject<WithRef<IBrand>>(1);
  treatmentTemplate$ = new ReplaySubject<WithRef<ITreatmentTemplate>>(1);
  @Output() deleteTemplate = new EventEmitter<WithRef<ITreatmentTemplate>>();
  @Output() editTemplate = new EventEmitter<WithRef<ITreatmentTemplate>>();

  @Input()
  set brand(brand: WithRef<IBrand>) {
    if (brand) {
      this.brand$.next(brand);
    }
  }

  @Input()
  set treatmentTemplate(treatmentTemplate: WithRef<ITreatmentTemplate>) {
    if (treatmentTemplate) {
      this.treatmentTemplate$.next(treatmentTemplate);
    }
  }
}
