import { Component, type OnInit } from '@angular/core';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  type IBreadcrumb,
  StorageResponseAPI,
} from '@principle-theorem/ng-shared';
import {
  CustomChartType,
  InvoiceAction,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import {
  BooleanMeasureFilter,
  FactTables,
  generateBuilderData,
  type IChartConfig,
  ValueEqualsMeasureFilter,
  toMeasureBuilderData,
} from '@principle-theorem/reporting';
import {
  filterUndefined,
  type ITimePeriod,
  toMoment,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type IChartCard } from '../../models/report/charts/chart-card';
import { DateRangeDataBuilder } from '../../models/report/data-builders/date-range-data-builder';
import { type ITableHeaderReplacement } from '../reporting-components/table-chart/table-chart.component';

@Component({
    selector: 'pr-practice-reporting',
    templateUrl: './practice-reporting.component.html',
    styleUrls: ['./practice-reporting.component.sass'],
    standalone: false
})
export class PracticeReportingComponent implements OnInit {
  from: moment.Moment = moment().subtract({ months: 1 });
  to: moment.Moment = moment();
  dataBuilder: DateRangeDataBuilder;
  collectionsChart: IChartCard;
  collectionsTable: IChartCard;
  breadcrumbs: IBreadcrumb[] = [{ label: 'Reporting' }];

  tableData: IChartCard;
  replacementHeaders: ITableHeaderReplacement[] = [
    { from: 'Owner', to: 'Practice' },
  ];

  constructor(
    private _organisation: OrganisationService,
    private _api: StorageResponseAPI
  ) {}

  ngOnInit(): void {
    this.dataBuilder = new DateRangeDataBuilder(
      this._api,
      this.from,
      this.to,
      this._organisation.brand$.pipe(filterUndefined()),
      this._organisation.userPractices$
    );
    this.loadCharts();
  }

  updateDates(event: ITimePeriod): void {
    if (!this.dataBuilder) {
      return;
    }
    this.dataBuilder.updateRange(toMoment(event.from), toMoment(event.to));
    this.loadCharts();
  }

  loadCharts(): void {
    this._buildCollectionsChart();
    this._buildCollectionsTable();
  }

  private _buildCollectionsChart(): void {
    this.collectionsChart = this.dataBuilder.toBarChart(COLLECTIONS);
  }

  private _buildCollectionsTable(): void {
    this.collectionsTable = this.dataBuilder.toLineChart(COLLECTIONS_COUNTS);
  }
}

const COLLECTIONS: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: 'Debt Collections',
  },
  builderData: generateBuilderData({
    measures: [
      // 'writtenOffInvoicesTotal',
      toMeasureBuilderData(
        FactTables.invoiceEvent
          .scopeBy(FactTables.invoiceEvent.writtenOff)
          .amountRemaining.reduceBySum()
      ),
      // 'overdueInvoicesTotal',
      toMeasureBuilderData(
        FactTables.invoiceEvent
          .scopeBy(FactTables.invoiceEvent.overdue)
          .amountRemaining.reduceBySum()
      ),
      // 'paymentPlansTotal',
      toMeasureBuilderData(
        FactTables.paymentPlanEvent
          .scopeBy(FactTables.paymentPlanEvent.active)
          .paymentPlan.total.reduceBySum()
      ),
    ],
    groupByDimension: FactTables.invoiceEvent.practice.name, // TODO: https://app.clickup.com/t/2x03mtk
  }),
};

const COLLECTIONS_COUNTS: IChartConfig = {
  type: CustomChartType.Line,
  labels: {
    title: 'Collection Table',
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.invoiceEvent
          .scopeBy(FactTables.invoiceEvent.overdue)
          .amountRemaining.reduceByCount()
          .setLabel('Overdue Invoices')
      ),
      toMeasureBuilderData(
        FactTables.paymentPlanEvent
          .scopeBy(FactTables.paymentPlanEvent.active)
          .count.setLabel('Active Payment Plans')
      ),
      toMeasureBuilderData(
        FactTables.invoiceEvent // TODO: https://app.clickup.com/t/2x03mtk
          .scopeBy(
            FactTables.invoiceEvent.paidOnCheckout.filterBy(
              // TODO: https://app.clickup.com/t/2x03mtk
              new BooleanMeasureFilter(true)
            )
          )
          .count.reduceByRatio(
            FactTables.invoiceEvent
              .scopeBy(
                FactTables.invoiceEvent.event.action.filterBy(
                  new ValueEqualsMeasureFilter(InvoiceAction.Issued)
                )
              )
              .count.setLabel('Paid On Checkout Rate')
              .setFormatter(MeasureFormatter.Percentage)
          )
      ),
      toMeasureBuilderData(
        FactTables.invoiceEvent
          .scopeBy(
            FactTables.invoiceEvent.paidOnCheckout.filterBy(
              // TODO: https://app.clickup.com/t/2x03mtk
              new BooleanMeasureFilter(false)
            )
          )
          .count.reduceByRatio(FactTables.invoiceEvent.latestEvent.count)
          .setLabel('Paid After Checkout Rate')
          .setFormatter(MeasureFormatter.Percentage)
      ),
      toMeasureBuilderData(
        FactTables.invoiceEvent
          .scopeBy(FactTables.invoiceEvent.writtenOff)
          .count.reduceByRatio(FactTables.invoiceEvent.latestEvent.count)
          .setLabel('Written Off Rate')
          .setFormatter(MeasureFormatter.Percentage)
      ),
    ],
    groupByDimension: FactTables.invoiceEvent.practice.name, // TODO: https://app.clickup.com/t/2x03mtk
  }),
};
