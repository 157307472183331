import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Signal,
  computed,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import {
  IMatSelectOption,
  NgSharedModule,
  formControlChanges$,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import {
  IAutomationTiming,
  TIMING_UNITS,
  TimingDirection,
  TimingUnit,
} from '@principle-theorem/principle-core/interfaces';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, skip, takeUntil } from 'rxjs/operators';
import { AutomatedNotificationFormHelpers } from '../notification-dialog/notification-dialog-form';
import { TimingFormGroup } from '../timing.formgroup';
import { DAYS_OF_WEEK, filterUndefined } from '@principle-theorem/shared';

@Component({
  selector: 'pr-automation-timing-form',
  standalone: true,
  imports: [
    CommonModule,
    NgMaterialModule,
    NgSharedModule,
    NgPrincipleSharedModule,
  ],
  templateUrl: './automation-timing-form.component.html',
  styleUrl: './automation-timing-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomationTimingFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _scheduleSettings?: IAutomationTiming;
  formHelpers = AutomatedNotificationFormHelpers;
  timingDirections: IMatSelectOption<TimingDirection>[] = [
    { label: 'Before appointment starts', value: TimingDirection.Before },
    { label: 'After appointment ends', value: TimingDirection.After },
  ];
  timingUnits = TIMING_UNITS;
  timingForm = new TimingFormGroup();
  isImmediate: Signal<boolean>;
  showTriggerTime: Signal<boolean>;
  daysOfWeek = DAYS_OF_WEEK;
  @Output() formChange = new EventEmitter<IAutomationTiming>();

  @Input()
  set value(value: IAutomationTiming) {
    this.timingForm.patchValue(value);
  }

  constructor() {
    validFormGroupChanges$(this.timingForm)
      .pipe(skip(1), takeUntil(this._onDestroy$))
      .subscribe((value) => this.formChange.emit(value));

    formControlChanges$(this.timingForm.controls.unit)
      .pipe(distinctUntilChanged(), takeUntil(this._onDestroy$))
      .subscribe((selectedUnit) => this._handleTimeField(selectedUnit));

    formControlChanges$(this.timingForm.controls.daysOfWeek)
      .pipe(
        filter((days) => !days?.length),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() =>
        this.timingForm.controls.daysOfWeek.setValue(this.daysOfWeek)
      );

    const directionValue = toSignal(
      this.timingForm.controls.direction.valueChanges
    );

    this.isImmediate = computed(
      () => directionValue() === TimingDirection.Immediately
    );

    const unitValue = toSignal(this.timingForm.controls.unit.valueChanges);
    this.showTriggerTime = computed(() => {
      const value = unitValue();
      if (!value) {
        return false;
      }
      const lessThanADay = [TimingUnit.Hours, TimingUnit.Minutes];
      return !lessThanADay.includes(value);
    });

    toObservable(unitValue)
      .pipe(
        filterUndefined(),
        filter((unit) => [TimingUnit.Hours, TimingUnit.Minutes].includes(unit)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.timingForm.controls.daysOfWeek.reset());
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  setImmediately(runImmediately: boolean): void {
    if (runImmediately) {
      this._scheduleSettings = this.timingForm.value;
      this.timingForm.controls.direction.setValue(TimingDirection.Immediately);
      return;
    }
    this.timingForm.patchValue({
      amount: 1,
      unit: TimingUnit.Days,
      direction: TimingDirection.Before,
      ...this._scheduleSettings,
      daysOfWeek: this.daysOfWeek,
    });
  }

  private _handleTimeField(selectedUnit: TimingUnit | undefined): void {
    const disableTime = selectedUnit
      ? [TimingUnit.Hours, TimingUnit.Minutes].includes(selectedUnit)
      : false;
    if (disableTime) {
      this.timingForm.controls.time.reset();
      this.timingForm.controls.time.disable();
      return;
    }
    this.timingForm.controls.time.enable();
  }
}
