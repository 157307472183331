import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { IntercomService } from '@principle-theorem/ng-intercom';

@Component({
  selector: 'pr-portal-page',
  templateUrl: './portal-page.component.html',
  styleUrls: ['./portal-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalPageComponent implements OnDestroy {
  constructor(private _intercom: IntercomService) {
    this._intercom.hideIcon();
  }

  ngOnDestroy(): void {
    this._intercom.showIcon();
  }
}
