<div class="flex w-full flex-col gap-2">
  <p class="text-md !mb-0 font-bold">{{ label }}</p>
  <form [formGroup]="form" class="flex flex-row gap-2">
    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label>Amount</mat-label>
      <input matInput formControlName="amount" type="number" min="1" />
    </mat-form-field>

    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label>Unit</mat-label>
      <mat-select formControlName="unit">
        @for (unit of timingUnits; track unit) {
          <mat-option [value]="unit"> {{ unit | titlecase }} </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <button
      mat-icon-button
      matTooltip="Clear Restriction"
      (click)="clearForm()"
      aria-label="Clear"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </form>
  <p class="text-xs">{{ hint }}</p>
</div>
