import {
  RawInlineNodes,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  IInteraction,
  InteractionType,
  IStaffer,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import { INamedDocument, WithRef } from '@principle-theorem/shared';
import { stafferToNamedDoc } from '../common';
import { toMention } from '../mention/mention';
import { Interaction } from './interaction';

export class InteractionTransaction {
  private _interactions: IInteractionTemplate[] = [];
  private _onCommitCallbacks: (() => Promise<void> | void)[] = [];

  hasUpdates(): boolean {
    return this._interactions.length > 0;
  }

  /**
   * Stores the interaction for retrieval with `getInteractions()`
   * Will later be prepended with the name of whoever performed this action.
   * @param type: InteractionType
   * @param title: string
   */
  add(type: InteractionType, title: RawInlineNodes): void {
    this._interactions.push({ title, type });
  }

  reset(): void {
    this._interactions = [];
    this._onCommitCallbacks = [];
  }

  /**
   * Add a callback to be executed when `commit()` is called
   */
  onCommit(callback: () => Promise<void> | void): void {
    this._onCommitCallbacks.push(callback);
  }

  /**
   * Retrieves all stored interactions, prepending them with
   * @param staffer Staffer
   */
  getInteractions(staffer: WithRef<IStaffer>): IInteraction[] {
    const owner = stafferToNamedDoc(staffer);
    return this._interactions
      .reduce(
        (accumulator: IInteractionTemplate[], current: IInteractionTemplate) =>
          this._mergeSameTypes(accumulator, current),
        []
      )
      .map((template: IInteractionTemplate) => {
        const interaction = Interaction.init({
          ...template,
          owner,
        });
        return this._prependOwner(owner, interaction);
      });
  }

  /**
   * Executes all pending onCommit callbacks and clears the array
   */
  async commit(): Promise<void> {
    const results: Promise<void>[] = this._onCommitCallbacks.map(
      async (callback: () => Promise<void> | void) => callback()
    );

    await Promise.all(results);
    this._onCommitCallbacks = [];
  }

  private _prependOwner(
    owner: INamedDocument,
    interaction: IInteraction
  ): IInteraction {
    return {
      ...interaction,
      title: [
        toMentionContent(toMention(owner, MentionResourceType.Staffer)),
        toTextContent(' '),
        ...interaction.title,
      ],
    };
  }

  private _mergeSameTypes(
    templates: IInteractionTemplate[],
    newTemplate: IInteractionTemplate
  ): IInteractionTemplate[] {
    const existing: IInteractionTemplate | undefined = templates.find(
      (check: IInteractionTemplate) => check.type === newTemplate.type
    );

    if (!existing) {
      templates.push(newTemplate);
      return templates;
    }
    existing.title = [
      ...existing.title,
      toTextContent(' & '),
      ...newTemplate.title,
    ];
    return templates;
  }
}

interface IInteractionTemplate {
  type: InteractionType;
  title: RawInlineNodes;
}
