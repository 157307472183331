import {
  CustomMappingOption,
  CustomMappingType,
  ICustomMappingSourceOption,
  IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import {
  IBlobFilenamePair,
  IReffable,
  WithRef,
  XSLXImporterExporter,
  asyncForEach,
  getEnumValues,
  hardDeleteDoc,
  snapshot,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { of } from 'rxjs';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import { ItemCodeNoteType } from '../../../mappings/item-codes-to-notes-xlsx';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import { PatientNoteCategorySourceEntity } from '../../source/entities/patient-note-categories';
import { PatientNoteCategoryToNoteToXSLX } from './patient-note-categories-to-notes-to-xlsx';
import { XSLXToPatientNoteCategoriesToNotes } from './xlsx-to-patient-note-categories-to-notes';

export const PATIENT_NOTE_CATEGORY_TO_NOTE_CUSTOM_MAPPING_TYPE =
  'patientNoteCategoryToNoteMapping';

export const PATIENT_NOTE_CATEGORY_TO_NOTE_MAPPING = CustomMapping.init({
  metadata: {
    label: 'Patient Note Category to Notes',
    description: ``,
    type: PATIENT_NOTE_CATEGORY_TO_NOTE_CUSTOM_MAPPING_TYPE,
    allowManualInput: true,
  },
  type: CustomMappingType.SelectionList,
  labelOverrides: {
    sourceIdentifier: 'Id',
    sourceLabel: 'Item Code',
    destinationIdentifier: 'Note Type',
    associatedValue: '',
  },
});

export class OasisPatientNoteCategoryToNoteMappingHandler extends BaseCustomMappingHandler<
  object,
  ItemCodeNoteType
> {
  customMapping = PATIENT_NOTE_CATEGORY_TO_NOTE_MAPPING;
  sourceEntity = new PatientNoteCategorySourceEntity();

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 10000)
      .toPromise();
    const options = records.map((record) => record.data.data);
    return sortBy(
      options.map((option) => ({
        label: option.name,
        value: option.id.toString(),
      })),
      'label'
    );
  }

  async getSelectionListOptions(
    _migration: WithRef<IPracticeMigration>
  ): Promise<CustomMappingOption[]> {
    return snapshot(
      of(
        getEnumValues(ItemCodeNoteType).map((noteType) => ({
          value: noteType,
          description: noteType,
          hasAssociatedValue: false,
        }))
      )
    );
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, noteCategories, translators } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(
      fileName,
      noteCategories,
      translators
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, noteCategories, translators } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(
      fileName,
      noteCategories,
      translators
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToPatientNoteCategoriesToNotes()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const records = await this.getRecords(translationMap);
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));

    const sourceOptions = await this.getSourceOptions(migration);
    const itemCodeNoteTypes = await this.getSelectionListOptions(migration);

    await asyncForEach(items, async (item) => {
      const mapTo = item.mapTo;
      const id = item.sourceId;
      const label = item.sourceName;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.value === id
      )?.value;
      if (!value || !item.mapTo) {
        return;
      }

      const noteType = itemCodeNoteTypes.find(
        (itemCodeNoteType) => itemCodeNoteType.description === mapTo
      );

      if (!noteType) {
        // eslint-disable-next-line no-console
        console.error(
          `Mapping error: ${this.customMapping.metadata.label} - Couldn't find note type for item`,
          item
        );
        return;
      }

      await this.upsertRecord(
        {
          destinationValue: noteType.value as ItemCodeNoteType,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    noteCategories: ICustomMappingSourceOption[];
    translators: PatientNoteCategoryToNoteToXSLX;
  }> {
    const fileName = this.getFileName();
    const noteCategories = await this.getSourceOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const translators = new PatientNoteCategoryToNoteToXSLX(
      await this.getSelectionListOptions(migration),
      await this.getRecords(translationMap)
    );
    return { fileName, noteCategories, translators };
  }
}
