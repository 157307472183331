import { rand, randFloat, randNumber, randSentence } from '@ngneat/falso';
import {
  calculateTaxFromTotal,
  TaxRate,
  TaxStrategy,
} from '@principle-theorem/accounting';
import {
  IServiceCodeLineItem,
  IDepositLineItem,
  IFeeLineItem,
  IInvoice,
  IInvoiceLineItem,
  InvoiceLineItemType,
  IPatient,
  IProductLineItem,
  IRefundLineItem,
  IStaffer,
  ITreatmentBasePriceLineItem,
  ITreatmentLineItem,
  ITreatmentPlan,
  RefundType,
  ICreditRefundLineItem,
  ICreditTransferLineItem,
  IAccountCredit,
  ITreatmentCategory,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  getEnumValues,
  Properties,
} from '@principle-theorem/shared';
import { MockDocRef, MockNamedDocument } from '@principle-theorem/testing';
import { v4 as uuid } from 'uuid';

export class InvoiceLineItemMock
  extends BaseFirestoreMock
  implements Properties<IInvoiceLineItem>
{
  uid = uuid();
  description = randSentence();
  amount = randFloat({ min: 1, max: 1000 });
  tax = calculateTaxFromTotal(this.amount, TaxRate.GST);
  taxStatus = rand(getEnumValues(TaxStrategy));
  quantity = randNumber({ min: 1, max: 3 });
}

export class TreatmentLineItemMock
  extends InvoiceLineItemMock
  implements Properties<ITreatmentLineItem>
{
  type = InvoiceLineItemType.Treatment;
  items = [new ServiceLineItemMock(), new TreatmentBasePriceLineItemMock()];
  treatmentRef = {
    planRef: MockDocRef<ITreatmentPlan>(),
    treatmentUuid: uuid(),
    attributedTo: MockNamedDocument<IStaffer>(),
  };
  patientRef = MockDocRef<IPatient>();
}

export class ServiceLineItemMock
  extends InvoiceLineItemMock
  implements Properties<IServiceCodeLineItem>
{
  toothId = '12';
  type = InvoiceLineItemType.ServiceCode;
  code = '071';
}

export class DepositLineItemMock
  extends InvoiceLineItemMock
  implements Properties<IDepositLineItem>
{
  type = InvoiceLineItemType.Deposit;
  max = randFloat({ min: 20, max: 200 });
  treatments = [MockNamedDocument()];
  attributedTo = MockNamedDocument<IStaffer>();
  forTreatmentCategoryRef = MockDocRef<ITreatmentCategory>();
}

export class RefundLineItemMock
  extends InvoiceLineItemMock
  implements Properties<IRefundLineItem>
{
  type = InvoiceLineItemType.Refund;
  refundType = RefundType.Card;
  sourceInvoice = MockDocRef<IInvoice>();
}

export class TreatmentBasePriceLineItemMock
  extends InvoiceLineItemMock
  implements Properties<ITreatmentBasePriceLineItem>
{
  type = InvoiceLineItemType.TreatmentBasePrice;
  treatmentRef = {
    planRef: MockDocRef<ITreatmentPlan>(),
    treatmentUuid: uuid(),
    attributedTo: MockNamedDocument<IStaffer>(),
  };
}

export class ProductLineItemMock
  extends InvoiceLineItemMock
  implements Properties<IProductLineItem>
{
  type = InvoiceLineItemType.Product;
}

export class FeeLineItemMock
  extends InvoiceLineItemMock
  implements Properties<IFeeLineItem>
{
  type = InvoiceLineItemType.Fee;
}

export class CreditRefundLineItemMock
  extends InvoiceLineItemMock
  implements Properties<ICreditRefundLineItem>
{
  type = InvoiceLineItemType.CreditRefund;
  creditsUsed = [
    {
      accountCreditRef: MockDocRef<IAccountCredit>(),
      amount: randFloat({ min: 1, max: 1000 }),
    },
  ];
}

export class CreditTransferLineItemMock
  extends InvoiceLineItemMock
  implements Properties<ICreditTransferLineItem>
{
  type = InvoiceLineItemType.CreditTransfer;
  patientTransferredRefs = {
    from: MockDocRef<IPatient>(),
    to: MockDocRef<IPatient>(),
  };
  attributedTo = MockNamedDocument<IStaffer>();
}
