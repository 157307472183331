import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import {
  MixedSchema,
  RawInlineNodes,
  getSchemaText,
  isMixedSchema,
  isRawInlineNodes,
} from '@principle-theorem/editor';
import { fromEvent, timer } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

@Directive({
  selector: '[ptTruncateTooltip]',
  providers: [MatTooltip],
})
// eslint-disable-next-line rxjs-angular/prefer-takeuntil
export class TruncateTooltipDirective {
  @Input('ptTruncateTooltip') tooltipText:
    | string
    | RawInlineNodes
    | MixedSchema;

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _tooltip: MatTooltip
  ) {}

  @HostListener('mouseover') mouseover(): void {
    const element = this._elementRef.nativeElement;
    const isOverflowing = element.scrollWidth > element.clientWidth;
    const mouseout$ = fromEvent(element, 'mouseout');

    timer(500)
      .pipe(
        filter(() => isOverflowing),
        takeUntil(mouseout$)
      )
      .subscribe(() => {
        this._tooltip.message =
          isRawInlineNodes(this.tooltipText) || isMixedSchema(this.tooltipText)
            ? getSchemaText(this.tooltipText)
            : this.tooltipText;
        this._tooltip.show();
      });
  }

  @HostListener('mouseout') mouseout(): void {
    this._tooltip.hide();
  }
}
