import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type CalendarEventEntity } from '@principle-theorem/ng-calendar/store';
import { EventableTimelineStore } from '@principle-theorem/ng-eventable';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  CalendarUnit,
  IScheduleSummaryEventable,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_NUMBER_OF_MONTH_FORMAT,
  ISO_DATE_FORMAT,
  MONTH_FORMAT,
  filterUndefined,
  isToday,
  toISODate,
} from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { ReplaySubject, type Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

interface IDayData {
  day: Moment;
  isToday: boolean;
  firstOfMonth: string | undefined;
  dayOfMonth: string;
  queryParams: Record<string, string>;
}

@Component({
  selector: 'pr-day',
  templateUrl: './day.component.html',
  styleUrls: ['./day.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayComponent {
  trackByEvent = TrackByFunctions.uniqueId<CalendarEventEntity>();
  day$ = new ReplaySubject<Moment>(1);
  events$: Observable<IScheduleSummaryEventable[]>;
  dayData$: Observable<IDayData>;

  @Input()
  set day(day: Moment) {
    if (!day) {
      return;
    }
    this.day$.next(day);
  }

  constructor(public eventableTimelineStore: EventableTimelineStore) {
    this.events$ = combineLatest([
      this.eventableTimelineStore.events$,
      this.day$,
    ]).pipe(
      map(([events, day]) => events[toISODate(day)]),
      filterUndefined()
    );

    this.dayData$ = this.day$.pipe(
      map((day) => {
        return {
          day,
          isToday: isToday(day),
          firstOfMonth: day.date() === 1 ? day.format(MONTH_FORMAT) : undefined,
          dayOfMonth: day.format(DAY_NUMBER_OF_MONTH_FORMAT),
          queryParams: {
            from: day.format(ISO_DATE_FORMAT),
            to: day.format(ISO_DATE_FORMAT),
            calendarUnit: CalendarUnit.Day,
          },
        };
      })
    );
  }
}
