import { NgModule } from '@angular/core';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { SidexisFeatureService } from './sidexis-feature.service';
import { SidexisTriggerXRayComponent } from './sidexis-trigger-x-ray/sidexis-trigger-x-ray.component';

@NgModule({
  declarations: [SidexisTriggerXRayComponent],
  imports: [NgSharedModule],
  exports: [SidexisTriggerXRayComponent],
  providers: [SidexisFeatureService],
})
export class SidexisModule {}
