<div fxLayout="column">
  <div class="layout-padding">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="16px"
    >
      <pt-search-field fxFlex>
        <input
          matInput
          placeholder="Search by any fields below"
          #search
          (keyup)="filterResults(search.value)"
        />
      </pt-search-field>
      <pt-download-report
        [data]="records$ | async"
        [dateRange]="dateRange$ | async"
        [csvTranslator]="csvTranslator"
      />
    </div>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    fxFlex
    matSort
    matSortActive="transactionDate"
    matSortDirection="asc"
  >
    <ng-container matColumnDef="patientName">
      <th mat-header-cell *matHeaderCellDef>Patient</th>
      <td mat-cell *matCellDef="let record">
        <a [routerLink]="record.patient | patientUrl$ | async" target="_blank">
          {{ record.patient.name }}
        </a>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="transactionDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Transaction Date
      </th>
      <td mat-cell *matCellDef="let record">
        {{ record.latest.createdAt | moment | amDateFormat: dateFormat }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="invoice">
      <th mat-header-cell *matHeaderCellDef>Invoice/Transaction Reference</th>
      <td mat-cell *matCellDef="let record">
        <div fxLayout="column">
          <a
            [routerLink]="record.invoice | invoiceUrl$ | async"
            target="_blank"
          >
            {{ record.invoice.reference }}
          </a>
          <small class="mat-caption">{{ record.latest.reference }}</small>
          <small
            class="mat-caption"
            *ngIf="record.latest.extendedData?.description"
          >
            Description: {{ record.latest.extendedData.description }}
          </small>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="transactionType">
      <th mat-header-cell *matHeaderCellDef>Transaction Type</th>
      <td mat-cell *matCellDef="let record">
        {{ record.latest.type | splitCamel | titlecase }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="transactionProvider">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let record">
        {{ record.latest.provider | splitCamel | titlecase }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="transactionProviderType">
      <th mat-header-cell *matHeaderCellDef>Manual Type</th>
      <td mat-cell *matCellDef="let record">
        <ng-container *ngIf="record.latest.extendedData?.transactionType?.name">
          {{ record.latest.extendedData.transactionType.name }}
        </ng-container>
        <ng-container *ngIf="record.latest.extendedData?.response?.cardType">
          {{ record.latest.extendedData.response.cardType }}
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="transactionDescription">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let record">
        <ng-container *ngIf="record.latest.description">
          {{ record.latest.description }}
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>
        <pr-value-with-icon>Amount</pr-value-with-icon>
      </th>
      <td mat-cell *matCellDef="let record">
        <pr-value-with-icon [ngClass]="{ refund: record.latest.amount < 0 }">
          {{ record.latest.amount | currency }}
        </pr-value-with-icon>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <pr-value-with-icon>
          <strong>{{ totalAmount$ | async | currency }}</strong>
        </pr-value-with-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let record">
        <pr-transaction-report-actions [data]="record | map: getActionsData" />
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>
</div>
