<mat-toolbar color="accent">Add Sterilisation Record</mat-toolbar>

<mat-dialog-content>
  <form class="flex flex-col" [formGroup]="form" (ngSubmit)="save()">
    <div
      *ngrxLet="barcodeMode$ as barcodeMode"
      formArrayName="data"
      class="flex flex-col gap-4"
    >
      @for (
        control of records.controls;
        track control;
        let index = $index, isFirst = $first, isLast = $last
      ) {
        <div class="flex flex-1 gap-0.5">
          <mat-form-field class="flex-1">
            <mat-label>Sterilisation Record ID</mat-label>
            <input #dataInput matInput [formControl]="control" />
            @if (control.value) {
              @if (
                control.value | map: getExistingRecord : this | async;
                as existingRecord
              ) {
                @switch (existingRecord.status) {
                  @case ('failed') {
                    <mat-icon
                      color="warn"
                      class="mr-2 opacity-90"
                      matSuffix
                      matTooltip="Record found in a Failed Cycle"
                    >
                      warning
                    </mat-icon>
                  }
                  @case ('pending') {
                    <mat-icon
                      class="mr-2 opacity-90"
                      matSuffix
                      matTooltip="Record found in a Pending Cycle"
                    >
                      report
                    </mat-icon>
                  }
                  @default {
                    <mat-icon
                      color="primary"
                      class="mr-2 opacity-90"
                      matSuffix
                      matTooltip="Record found in a Passed Cycle"
                    >
                      check
                    </mat-icon>
                  }
                }
              } @else {
                <mat-icon
                  color="warn"
                  class="mr-2 opacity-90"
                  matSuffix
                  matTooltip="No record found, a new one will be created"
                >
                  library_add
                </mat-icon>
              }
            }
          </mat-form-field>

          @if (isLast) {
            <button
              mat-icon-button
              [matTooltip]="
                barcodeMode ? 'Manual Entry Mode' : 'Barcode Scanner Mode'
              "
              [color]="barcodeMode ? 'primary' : 'default'"
              (click)="$event.preventDefault(); barcodeMode$.next(!barcodeMode)"
            >
              <mat-icon fontSet="material-symbols-outlined" class="opacity-80">
                barcode_reader
              </mat-icon>
            </button>

            @if (!barcodeMode) {
              <button type="button" mat-icon-button (click)="addRecord()">
                <mat-icon>add</mat-icon>
              </button>
            }
          }
        </div>
      }
    </div>

    <pr-patient-selector class="flex" [formControl]="searchCtrl" />

    <pr-staff-selector
      formControlName="staffer"
      [practice]="data.practice"
      [showClear]="false"
    />
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="disabled$ | async"
      (click)="save()"
    >
      Add
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
