import {
  IPatient,
  type IHealthcareClaim,
  type IInvoice,
  type ITransaction,
  type TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference, WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { TransactionProviderType } from '../transaction-provider';

export interface IClaimEstimateSummaryItem {
  code: string;
  description: string;
  gap: number;
  benefit: number;
}

export interface IClaimEstimateSummary {
  providerName: string;
  items: IClaimEstimateSummaryItem[];
  totalBenefit: number;
  totalGap: number;
}

export interface IClaimEstimateProvider {
  label: string;
  providerId: TransactionProvider;
  providerType: TransactionProviderType;
  imageUrl?: string;
  icon: string;
  isEnabled$: Observable<boolean>;

  capture(
    invoice: IInvoice,
    claim: IHealthcareClaim,
    patient: WithRef<IPatient>
  ): Promise<DocumentReference<ITransaction> | undefined>;
}
