import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'pr-principle-logo',
    templateUrl: './principle-logo.component.html',
    styleUrls: ['./principle-logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PrincipleLogoComponent {}
