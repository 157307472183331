import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone, toFloat } from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  convertValueFn,
} from '../../../source/source-helpers';

export const PATIENT_PREPAYMENT_LINE_ITEM_RESOURCE_TYPE =
  'patientPrepaymentLineItems';

export const PATIENT_PREPAYMENT_LINE_ITEM_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Prepayment Line Item List',
      description: '',
      idPrefix: PATIENT_PREPAYMENT_LINE_ITEM_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ICorePracticePatientPrepaymentLineItem {
  id: number;
  amount: number; // 1000.0000
  isVoided: boolean;
  treatmentId?: number;
  itemId?: number;
  itemCodeName?: string;
  prepaymentId: number;
  patientId: number;
}

export function isCorePracticePatientPrepaymentLineItem(
  item: unknown
): item is ICorePracticePatientPrepaymentLineItem {
  return TypeGuard.interface<ICorePracticePatientPrepaymentLineItem>({
    id: isNumber,
    amount: isNumber,
    isVoided: isBoolean,
    treatmentId: TypeGuard.nilOr(isNumber),
    itemId: TypeGuard.nilOr(isNumber),
    itemCodeName: TypeGuard.nilOr(isString),
    prepaymentId: isNumber,
    patientId: isNumber,
  })(item);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICorePracticePatientPrepaymentLineItemTranslations {}

export interface ICorePracticePatientPrepaymentLineItemFilters {
  treatmentId?: number;
  itemId?: number;
  prepaymentId: number;
  patientId: number;
}

const PATIENT_PREPAYMENT_LINE_ITEM_SOURCE_QUERY = `
SELECT
  line_item.*,
  prepayment.patient_id,
  item_code.name AS item_code_name
FROM
(
  SELECT
    PrepaymentLineItemId AS id,
    convert_to_decimal(Amount) AS amount,
    convert_to_boolean(IsVoided) AS is_voided,
    convert_to_integer(TreatmentId) AS treatment_id,
    ItemId AS item_id,
    PrepaymentId AS prepayment_id
  FROM tblPrepaymentLineItem
) AS line_item
LEFT JOIN (
  SELECT
    PrepaymentId AS id,
    PatientId AS patient_id
  FROM tblPrepayment
) AS prepayment
ON line_item.prepayment_id = prepayment.id
LEFT JOIN (
  SELECT
    ItemId AS id,
    Name AS name
FROM tblItem
) AS item_code
ON item_code.id = line_item.item_id
`;

export class PatientPrepaymentLineItemSourceEntity extends BaseSourceEntity<
  ICorePracticePatientPrepaymentLineItem,
  ICorePracticePatientPrepaymentLineItemTranslations,
  ICorePracticePatientPrepaymentLineItemFilters
> {
  sourceEntity = PATIENT_PREPAYMENT_LINE_ITEM_SOURCE_ENTITY;
  entityResourceType = PATIENT_PREPAYMENT_LINE_ITEM_RESOURCE_TYPE;
  sourceQuery = PATIENT_PREPAYMENT_LINE_ITEM_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientPrepaymentLineItem;

  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertValueFn(toFloat, 'amount'),
  ]);

  translate(
    _data: ICorePracticePatientPrepaymentLineItem,
    _timezone: Timezone
  ): ICorePracticePatientPrepaymentLineItemTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientPrepaymentLineItem): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientPrepaymentLineItem): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientPrepaymentLineItem,
    _timezone: Timezone
  ): ICorePracticePatientPrepaymentLineItemFilters {
    return {
      treatmentId: data.treatmentId,
      itemId: data.itemId,
      prepaymentId: data.prepaymentId,
      patientId: data.patientId,
    };
  }
}
