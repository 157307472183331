import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  ChartFacade,
  ChartId,
  TreatmentPlanFacade,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  CurrentPatientScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { doc, orderBy } from '@principle-theorem/shared';
import { AbstractResolver } from '@principle-theorem/ng-shared';
import {
  ClinicalChart,
  Patient,
  TreatmentPlan,
} from '@principle-theorem/principle-core';
import {
  PatientRelationshipType,
  type ITreatmentPlan,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  firstResult$,
  getDoc,
  getDoc$,
} from '@principle-theorem/shared';
import { combineLatest, of, type Observable } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { first } from 'lodash';

@Injectable()
export class TreatmentPlanResolverService extends AbstractResolver<ITreatmentPlan> {
  constructor(
    router: Router,
    private _organisation: OrganisationService,
    private _chartState: ChartFacade,
    private _treatmentPlanFacade: TreatmentPlanFacade
  ) {
    super(router);
  }

  getItemById(
    uid: string,
    route: ActivatedRouteSnapshot
  ): Observable<ITreatmentPlan | undefined> {
    const patientScope: CurrentPatientScope = new CurrentPatientScope(
      this._router
    );
    const patient = patientScope.findModel(route);

    if (!patient) {
      throw new Error('Patient could not be resolved');
    }

    const setChartingAs$ = this._organisation.staffer$.pipe(
      filterUndefined(),
      tap((staffer) =>
        this._chartState.setChartingAs(ChartId.InAppointment, staffer)
      )
    );

    const loadChart$ = firstResult$(
      ClinicalChart.col(patient),
      orderBy('createdAt', 'desc')
    ).pipe(
      concatMap(async (chart) => {
        if (!chart) {
          const docRef = await ClinicalChart.cloneFromLatest(patient);
          return getDoc(docRef);
        }
        return chart;
      }),
      tap((chart) => this._chartState.setChart(ChartId.InAppointment, chart))
    );

    this._treatmentPlanFacade.selectTreatmentPlan(
      doc(TreatmentPlan.col(patient), uid)
    );

    return combineLatest([setChartingAs$, loadChart$]).pipe(
      switchMap(() =>
        Patient.withPatientRelationships$(
          patient,
          [PatientRelationshipType.DuplicatePatient],
          (patientReffable) =>
            getDoc$(TreatmentPlan.col(patientReffable), uid).pipe(
              map((plan) => [plan]),
              catchError(() => of([]))
            )
        ).pipe(map((plans) => first(plans)))
      )
    );
  }
}
