import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  CurrentPracticeScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import { PracticePermissions } from '@principle-theorem/principle-core/features';
import {
  DEFAULT_HOUR_INCREMENT,
  DEFAULT_PRACTICE_TIMELINE_TOOLTIP_DELAY,
  DEFAULT_STEP_SIZE_IN_MINS,
  EVENT_TYPES,
  EVENT_TYPE_COLOUR_MAP,
  EventType,
  IPractice,
  IPracticeSettings,
  PRACTICE_TIMELINE_TOOLTIP_DELAY_OPTIONS,
  PracticeTimelineHourIncrement,
  PracticeTimelineTooltipDelay,
} from '@principle-theorem/principle-core/interfaces';
import {
  DEADZONE_DEFAULT_COLOUR,
  DEADZONE_PALETTE,
  EVENT_PALETTE,
  WithRef,
  filterUndefined,
  patchDoc,
  snapshot,
} from '@principle-theorem/shared';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import {
  startWith,
  map,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';

type EventColourPair = {
  type: EventType;
  colour: string;
};

@Component({
    selector: 'pr-timeline-practice-settings',
    templateUrl: './timeline-practice-settings.component.html',
    styleUrls: ['./timeline-practice-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TimelinePracticeSettingsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _practice$: Observable<WithRef<IPractice>>;
  practiceViewSettings$: Observable<IPracticeSettings['timeline']>;
  deadzoneColour$: Observable<string>;
  hasDeadzoneColourOverride$: Observable<boolean>;
  stepSizesInMins: number[] = [5, 10, 15, 20];
  hourIncrements: PracticeTimelineHourIncrement[] = [10, 15, 20, 30];
  tooltipDelays: PracticeTimelineTooltipDelay[] =
    PRACTICE_TIMELINE_TOOLTIP_DELAY_OPTIONS;
  practiceSettingsDisabled$: Observable<boolean>;
  eventColourPairs$: Observable<EventColourPair[]>;
  eventTypes = EVENT_TYPES.filter((eventType) => {
    const excludedTypes = [
      EventType.RosteredOn,
      EventType.Appointment,
      EventType.PracticeClosed,
    ];
    return !excludedTypes.includes(eventType);
  });
  eventPalette = EVENT_PALETTE;
  deadzonePalette = DEADZONE_PALETTE;
  trackByColourPair = TrackByFunctions.field<EventColourPair>('type');

  practiceViewSettingsForm = new TypedFormGroup<
    Required<IPracticeSettings>['timeline']
  >({
    stepSizeInMins: new TypedFormControl(DEFAULT_STEP_SIZE_IN_MINS),
    pixelsPerMinute: new TypedFormControl(),
    hourIncrement: new TypedFormControl<PracticeTimelineHourIncrement>(
      DEFAULT_HOUR_INCREMENT
    ),
    tooltipDelay: new TypedFormControl<PracticeTimelineTooltipDelay>(
      DEFAULT_PRACTICE_TIMELINE_TOOLTIP_DELAY
    ),
  });

  @Output() refreshTimeline = new EventEmitter<void>();

  constructor(
    private _organisation: OrganisationService,
    private _practiceScope: CurrentPracticeScope
  ) {
    this.practiceViewSettings$ = validFormGroupChanges$(
      this.practiceViewSettingsForm
    ).pipe(
      startWith(this.practiceViewSettingsForm.value),
      map((settings) => settings ?? {})
    );

    this._practice$ = this._practiceScope.doc$.pipe(filterUndefined());

    this._practice$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((practice) =>
        this.practiceViewSettingsForm.patchValue(
          practice.settings.timeline ?? {},
          {
            emitEvent: false,
          }
        )
      );

    this.deadzoneColour$ = this._practice$.pipe(
      map(
        (practice) =>
          practice.settings.deadzoneColourOverride ?? DEADZONE_DEFAULT_COLOUR
      )
    );

    this.hasDeadzoneColourOverride$ = this.deadzoneColour$.pipe(
      map((colour) => colour !== DEADZONE_DEFAULT_COLOUR)
    );

    this.eventColourPairs$ = combineLatest([
      of(this.eventTypes),
      this._practice$,
    ]).pipe(
      map(([eventTypes, practice]) =>
        eventTypes.map((type) => {
          const colour =
            practice.settings.eventColourOverrides?.[type] ??
            EVENT_TYPE_COLOUR_MAP[type];

          return {
            type,
            colour,
          };
        })
      )
    );

    this.practiceViewSettings$
      .pipe(withLatestFrom(this._practice$), takeUntil(this._onDestroy$))
      .subscribe(([settings, practice]) => {
        void patchDoc(practice.ref, {
          settings: {
            ...practice.settings,
            timeline: settings,
          },
        });
      });

    this.practiceSettingsDisabled$ = this._organisation.userPermissions$.pipe(
      map(
        (userPermissions) =>
          !userPermissions.includes(PracticePermissions.PracticeConfigure)
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async saveDeadzoneColour(colour?: string): Promise<void> {
    const practice = await snapshot(this._practice$);
    await patchDoc(practice.ref, {
      settings: {
        ...practice.settings,
        deadzoneColourOverride: colour ?? DEADZONE_DEFAULT_COLOUR,
      },
    });
    this.refreshTimeline.emit();
  }

  async saveEventColour(eventType: EventType, colour?: string): Promise<void> {
    const practice = await snapshot(this._practice$);
    await patchDoc(practice.ref, {
      settings: {
        ...practice.settings,
        eventColourOverrides: {
          ...practice.settings.eventColourOverrides,
          [eventType]: colour ?? EVENT_TYPE_COLOUR_MAP[eventType],
        },
      },
    });
    this.refreshTimeline.emit();
  }

  hasColourOverride(eventColour: EventColourPair): boolean {
    return eventColour.colour !== EVENT_TYPE_COLOUR_MAP[eventColour.type];
  }
}
