<button
  *ngrxLet="isDisabled$ as disabled"
  mat-raised-button
  class="image !h-full"
  matTooltip="Sidexis integration requires patient date of birth"
  [matTooltipDisabled]="!disabled"
  [disabled]="disabled"
  (click)="takeXRay()"
>
  Launch Sidexis
</button>
