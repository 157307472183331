import { _isNumberValue } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
  type OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { isObject } from '@principle-theorem/shared';
import { get } from 'lodash';
import { type IChartCard } from '../../../models/report/charts/chart-card';
import { TableTransformer } from '../../../models/report/charts/data-transformers/table-transformer';

export interface ITableHeaderReplacement {
  from: string;
  to: string;
}

@Component({
    selector: 'pr-table-chart',
    templateUrl: './table-chart.component.html',
    styleUrls: ['./table-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TableChartComponent implements OnInit, OnDestroy {
  private _chart: IChartCard;
  trackByHeader = TrackByFunctions.variable<string>();
  @Input() replacementHeaders: ITableHeaderReplacement[];
  headers: string[] = [];
  data: unknown[];
  dataSource: MatTableDataSource<unknown> = new MatTableDataSource<unknown>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  set chart(chart: IChartCard) {
    if (!chart) {
      return;
    }
    this._chart = chart;
    void this.buildTableDataSet();
  }

  get chart(): IChartCard {
    return this._chart;
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
    this.chart.chartBuilder.clear();
  }

  async buildTableDataSet(): Promise<void> {
    const headers =
      this.chart.chartBuilder.chartDataHandler.config.builderData.measures.map(
        (measure) => measure.measure.metadata.label
      );
    this.headers = ['id', ...headers];

    if (this.replacementHeaders) {
      this.replacementHeaders.map(
        (replacement: { from: string; to: string }) => {
          const index: number = this.headers.findIndex(
            (header: string): boolean => header === replacement.from
          );
          if (index !== -1) {
            this.headers[index] = replacement.to;
          }
        }
      );
    }

    const transformer = new TableTransformer();
    this.data = await transformer.transform(
      this.chart.chartBuilder.chartDataHandler.config,
      this.chart.chartBuilder.chartDataHandler.dataBuilder
    );
    this.dataSource.data = this.data;
    this.dataSource.sortingDataAccessor = (
      item: unknown,
      sortId: string
    ): string | number => {
      const value: unknown = isObject(item)
        ? (get(item, sortId) as IChartCard).value
        : undefined;
      return _isNumberValue(value) ? Number(value) : String(value);
    };
  }
}
