<div
  class="layout-padding"
  fxLayout="column"
  fxLayout.gt-sm="row"
  fxLayoutGap="16px"
  *ngIf="lab$ | async as lab"
>
  <div fxFlex="none" fxFlex.gt-sm>
    <pr-lab-card [lab]="lab" />
  </div>
  <div fxFlex="none" fxFlex.gt-sm fxLayout="column" fxLayoutGap="16px">
    <div class="actions-card">
      <div fxLayout="row" fxLayoutGap="16px" class="layout-margin">
        <button
          fxFlex="50"
          mat-stroked-button
          [disabled]="!lab.phone"
          (click)="dialogService.openPhone()"
        >
          <mat-icon>phone</mat-icon>
          Call
        </button>
        <button
          fxFlex="50"
          mat-stroked-button
          [disabled]="!lab.email"
          (click)="dialogService.openEmail()"
        >
          <mat-icon>email</mat-icon>
          Email
        </button>
        <button fxFlex="50" mat-stroked-button color="warn" (click)="delete()">
          <mat-icon>delete</mat-icon>
          Delete
        </button>
        <button
          fxFlex="50"
          mat-flat-button
          color="primary"
          (click)="openEdit()"
        >
          <mat-icon>edit</mat-icon>
          Edit
        </button>
      </div>
    </div>
    <mat-card appearance="outlined" class="no-padding">
      <mat-card-header>
        <mat-card-title>Note</mat-card-title>
      </mat-card-header>
      <mat-card-content class="resource-notes">
        <pr-notes [resource]="lab" />
      </mat-card-content>
    </mat-card>
  </div>
</div>
