import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  type IInteractionV2,
  type ILabJob,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { patchDoc, snapshot, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-lab-job-interactions',
  templateUrl: './lab-job-interactions.component.html',
  styleUrls: ['./lab-job-interactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabJobInteractionsComponent {
  labJob$: ReplaySubject<WithRef<ILabJob>> = new ReplaySubject(1);
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);
  @Output() cancelled = new EventEmitter<void>();

  @Input()
  set practice(practice: WithRef<IPractice>) {
    if (practice) {
      this.practice$.next(practice);
    }
  }

  @Input()
  set labJob(labJob: WithRef<ILabJob>) {
    if (labJob) {
      this.labJob$.next(labJob);
    }
  }

  async addInteraction(interaction: IInteractionV2): Promise<void> {
    const labJob = await snapshot(this.labJob$);
    await patchDoc(labJob.ref, {
      interactions: [...labJob.interactions, interaction],
    });
  }
}
