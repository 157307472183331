import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StateBasedNavigationService } from '@principle-theorem/ng-principle-shared';
import { DialogPresets, TrackByFunctions } from '@principle-theorem/ng-shared';
import { PatientRelationship } from '@principle-theorem/principle-core';
import {
  PATIENT_RELATIONSHIP_TYPES,
  isPatientWithPrimaryContact,
  type IPatient,
  type IPatientRelationship,
} from '@principle-theorem/principle-core/interfaces';
import {
  getDoc,
  multiFilter,
  patchDoc,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PatientRelationshipDialogComponent } from '../patient-relationship-dialog/patient-relationship-dialog.component';
import { type PrimaryContactWithRelationships } from '../patient-relationship-form/patient-relationship-form';

@Component({
    selector: 'pr-patient-relationship-list',
    templateUrl: './patient-relationship-list.component.html',
    styleUrls: ['./patient-relationship-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientRelationshipListComponent {
  trackByRelationship =
    TrackByFunctions.ref<IPatientRelationship>('patient.ref');
  patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);
  relationships$: Observable<IPatientRelationship[]>;

  constructor(
    public stateNav: StateBasedNavigationService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.relationships$ = this.patient$.pipe(
      map((patient) => patient.relationships),
      multiFilter((relationship) =>
        PATIENT_RELATIONSHIP_TYPES.includes(relationship.type)
      )
    );
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  async addRelationship(patient: WithRef<IPatient>): Promise<void> {
    const data: PrimaryContactWithRelationships = {
      relationships: patient.relationships,
    };
    if (isPatientWithPrimaryContact(patient)) {
      data.primaryContact = patient.primaryContact;
    }
    const relationships = await this._dialog
      .open<
        PatientRelationshipDialogComponent,
        PrimaryContactWithRelationships,
        PrimaryContactWithRelationships
      >(PatientRelationshipDialogComponent, DialogPresets.large({ data }))
      .afterClosed()
      .toPromise();
    if (!relationships) {
      return;
    }
    if (!relationships.primaryContact && isPatientWithPrimaryContact(patient)) {
      try {
        await PatientRelationship.removePrimaryContact(patient);
      } catch (error) {
        this._snackBar.open(
          `Cannot remove primary contact until contact details are added to the patient.`,
          `Close`,
          { duration: 15000, panelClass: 'actionSnackBar' }
        );
      }
    }

    await patchDoc(patient.ref, { ...relationships });
    await this._updateBackReferences(patient.ref);
  }

  private async _updateBackReferences(
    ref: DocumentReference<IPatient>
  ): Promise<void> {
    const patient = await getDoc(ref);
    await PatientRelationship.addPrimaryRelationship(patient);
    await PatientRelationship.addRelationshipBackReferences(patient);
  }
}
