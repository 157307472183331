import { get } from 'lodash';
import { type Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { type CollectionReference } from './adaptor';
import { doc$, getDocRef } from './document';

export class Counters {
  static length$<T>(
    col: CollectionReference<T>,
    countField: string = 'count'
  ): Observable<number> {
    return doc$(getDocRef<{ count: number }>(`_counters/${col.path}`)).pipe(
      map((counter) => get(counter, countField, 0) as number),
      catchError(() => of(0))
    );
  }
}
