import {
  combineLatest,
  fromEvent,
  merge,
  type Observable,
  Subject,
} from 'rxjs';
import {
  filter,
  map,
  repeat,
  skipUntil,
  switchMap,
  take,
  takeUntil,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';
import { MouseInteractionsService } from './mouse-interactions.service';

export class DragTracker {
  private _destroy$: Subject<void> = new Subject<void>();
  click$: Observable<MouseEvent>;
  drag$: Observable<MouseEvent>;
  events$: Observable<MouseEvent>;

  constructor(
    private _element: Element,
    private _mouseInteractions: MouseInteractionsService
  ) {
    const elementMove$: Observable<MouseEvent> = fromEvent<MouseEvent>(
      this._element,
      'mousemove'
    ).pipe(throttleTime(100), takeUntil(this._destroy$));

    const elementUp$: Observable<MouseEvent> = fromEvent<MouseEvent>(
      this._element,
      'mouseup'
    ).pipe(takeUntil(this._destroy$));

    this.click$ = elementUp$.pipe(
      withLatestFrom(this._mouseInteractions.isDragMode$),
      filter(([_, isDragMode]: [MouseEvent, boolean]) => !isDragMode),
      map(([event, _]: [MouseEvent, boolean]) => event),
      skipUntil(elementUp$),
      take(1),
      repeat(),
      takeUntil(this._destroy$)
    );

    this.drag$ = this._mouseInteractions.down$.pipe(
      switchMap(() =>
        combineLatest([
          elementMove$,
          this._mouseInteractions.isDragMode$.pipe(
            filter((isDragMode: boolean) => isDragMode)
          ),
        ])
      ),
      take(1),
      map(([event, _]: [MouseEvent, boolean]) => event),
      // eslint-disable-next-line rxjs/no-unsafe-takeuntil
      takeUntil(this._mouseInteractions.up$),
      repeat(),
      takeUntil(this._destroy$)
    );

    this.events$ = merge(this.click$, this.drag$);
  }

  destroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
