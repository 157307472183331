import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { type IResolvedColumnProperty } from '../report-builder-column-selector-dialog/resolved-columns';
import { TABLE_GROUP_KEY } from '../../../core/chart-builders/table-chart-builder';

interface ILabel {
  title: string;
  subtitle?: string;
}

@Component({
    selector: 'pr-report-builder-resolved-column-label',
    templateUrl: './report-builder-resolved-column-label.component.html',
    styleUrls: ['./report-builder-resolved-column-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ReportBuilderResolvedColumnLabelComponent {
  private _column$ = new ReplaySubject<IResolvedColumnProperty>(1);
  label$: Observable<ILabel>;
  showSubtitle$ = new BehaviorSubject<boolean>(true);

  @Input()
  set column(column: IResolvedColumnProperty) {
    if (column) {
      this._column$.next(column);
    }
  }

  @Input()
  set hideSubtitle(hideSubtitle: boolean) {
    this.showSubtitle$.next(!coerceBooleanProperty(hideSubtitle));
  }

  constructor() {
    this.label$ = this._column$.pipe(
      map((column) => {
        const overrideLabel =
          column.definition.label !== TABLE_GROUP_KEY
            ? column.definition.label
            : undefined;
        const propertyLabel = column.property.metadata.label;
        return overrideLabel && overrideLabel !== propertyLabel
          ? { title: overrideLabel, subtitle: propertyLabel }
          : { title: propertyLabel };
      })
    );
  }
}
