import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Interaction, Patient } from '@principle-theorem/principle-core';
import {
  type IInteraction,
  type IInteractionV2,
  type IPatient,
  isInteractionV2,
  PatientRelationshipType,
} from '@principle-theorem/principle-core/interfaces';
import {
  deleteDoc,
  isWithRef,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-patient-interactions',
  templateUrl: './patient-interactions.component.html',
  styleUrls: ['./patient-interactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientInteractionsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  interactions$: Observable<WithRef<IInteractionV2>[]>;
  pinnedInteractions$: Observable<(IInteractionV2 | IInteraction)[]>;
  compact$ = new BehaviorSubject<boolean>(false);
  enableScroll$ = new BehaviorSubject<boolean>(false);
  @Output() notifications = new EventEmitter<boolean>();

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this._patient$.next(patient);
    }
  }

  @Input()
  set compact(compact: BooleanInput) {
    this.compact$.next(coerceBooleanProperty(compact));
  }

  @Input()
  set enableScroll(enableScroll: BooleanInput) {
    this.enableScroll$.next(coerceBooleanProperty(enableScroll));
  }

  constructor(public snackBar: MatSnackBar) {
    this.interactions$ = this._patient$.pipe(
      switchMap((patient) =>
        Patient.withPatientRelationships$(
          patient,
          [PatientRelationshipType.DuplicatePatient],
          Patient.history$
        )
      )
    );
    this.pinnedInteractions$ = this.interactions$.pipe(
      map((interactions) =>
        Interaction.getPinnedInteractions(interactions).reverse()
      )
    );
    this.pinnedInteractions$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((interactions) =>
        this.notifications.emit(interactions.length > 0)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async addInteraction(interaction: IInteractionV2): Promise<void> {
    const patient = await snapshot(this._patient$);
    await Patient.addInteraction(patient, interaction);
  }

  async updateInteraction(
    interaction: IInteraction | WithRef<IInteractionV2>
  ): Promise<void> {
    if (!isInteractionV2(interaction) || !isWithRef(interaction)) {
      return;
    }
    await Interaction.amend(interaction);
  }

  async deleteInteraction(
    interaction: IInteraction | WithRef<IInteractionV2>
  ): Promise<void> {
    if (!isInteractionV2(interaction) || !isWithRef(interaction)) {
      return;
    }
    await deleteDoc(interaction.ref);
  }
}
