import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  initVersionedSchema,
  type ISnippet,
  toCodeBlockContent,
  type VersionedSchema,
} from '@principle-theorem/editor';
import {
  CurrentBrandScope,
  CurrentStafferScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  type IBreadcrumb,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { type IStaffer } from '@principle-theorem/principle-core/interfaces';
import { Brand, Staffer } from '@principle-theorem/principle-core';
import { type CollectionReference } from '@principle-theorem/shared';
import {
  addDoc,
  all$,
  deleteDoc,
  doc$,
  filterUndefined,
  multiSortBy$,
  nameSorter,
  patchDoc,
  saveDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  combineLatest,
  noop,
  type Observable,
  Subject,
} from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  type ISnippetFormData,
  SnippetFormComponent,
} from '../../../../../components/snippet/snippet-form/snippet-form.component';

@Component({
  selector: 'pr-account-snippets-list',
  templateUrl: './account-snippets-list.component.html',
  styleUrls: ['./account-snippets-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSnippetsListComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _snippetSelected$ = new Subject<WithRef<ISnippet>>();
  selectedSnippet$: Observable<WithRef<ISnippet>>;
  trackBySnippet = TrackByFunctions.ref<WithRef<ISnippet>>();
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  showDeleted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  snippets$: Observable<WithRef<ISnippet>[]>;
  snippetCol$: Observable<CollectionReference<ISnippet>>;
  snippetsLabel$: Observable<string>;
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService,
    private _currentBrand: CurrentBrandScope,
    private _currentStaffer: CurrentStafferScope
  ) {
    this.snippetCol$ = combineLatest([
      this._currentBrand.doc$.pipe(filterUndefined()),
      this._currentStaffer.doc$,
    ]).pipe(
      map(([brand, staffer]) =>
        staffer ? Staffer.snippetCol(staffer) : Brand.snippetCol(brand)
      )
    );

    this.snippets$ = this.snippetCol$.pipe(
      switchMap((snippetCol) => all$(snippetCol)),
      multiSortBy$(nameSorter()),
      tap(() => this.loading$.next(false))
    );

    this.snippetsLabel$ = this._currentStaffer.doc$.pipe(
      map((staffer) => (staffer ? 'Personal Snippets' : 'Shared Snippets'))
    );

    this.breadcrumbs$ = combineLatest([
      this._currentBrand.doc$.pipe(filterUndefined()),
      this._currentStaffer.doc$,
    ]).pipe(
      map(([brand, staffer]) => {
        if (staffer) {
          return [
            { label: 'Settings', path: '../../../../' },
            { label: brand.name },
            { label: 'My Account', path: '' },
            { label: 'Personal Snippets' },
          ];
        }
        return [
          { label: 'Settings', path: '../../../' },
          { label: brand.name },
          { label: 'Shared Snippets' },
        ];
      })
    );

    this.selectedSnippet$ = this._snippetSelected$.pipe(
      switchMap((snippet) => doc$(snippet.ref))
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  setSelectedSnippet(snippet: WithRef<ISnippet>): void {
    this._snippetSelected$.next(snippet);
  }

  getSnippetKeyword(snippet: ISnippet): VersionedSchema | undefined {
    if (!snippet.keyword) {
      return;
    }
    initVersionedSchema([toCodeBlockContent(snippet.keyword)]);
  }

  async openSnippetForm(snippet?: WithRef<ISnippet>): Promise<void> {
    const staffer: WithRef<IStaffer> = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const data: ISnippetFormData = {
      staffer,
      snippet,
    };

    const updatedSnippet = await this._dialog
      .open<SnippetFormComponent, ISnippetFormData, ISnippet>(
        SnippetFormComponent,
        DialogPresets.large({
          data,
        })
      )
      .afterClosed()
      .toPromise();

    if (!updatedSnippet) {
      return;
    }

    if (snippet) {
      await patchDoc(snippet.ref, updatedSnippet);
      this._snackBar.open('Snippet Updated');
      return;
    }

    await addDoc(await snapshot(this.snippetCol$), updatedSnippet);
    this._snackBar.open('Snippet Saved');
  }

  toggleDeleted(): void {
    const showDeleted = this.showDeleted$.value;
    if (!showDeleted) {
      this.showDeleted$.next(true);
      return;
    }
    this.showDeleted$.next(false);
  }

  async deleteSnippet(snippet: WithRef<ISnippet>): Promise<void> {
    if (snippet.deleted) {
      return;
    }
    await deleteDoc(snippet.ref);

    this._snackBar
      .open('Snippet Deleted', 'UNDO')
      .onAction()
      .pipe(
        switchMap(() => patchDoc(snippet.ref, { deleted: false })),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);
  }

  async undoDelete(snippet: WithRef<ISnippet>): Promise<void> {
    if (!snippet.deleted) {
      return;
    }
    snippet.deleted = false;
    await saveDoc(snippet);
    this._snackBar.open('Snippet Restored');
  }
}
