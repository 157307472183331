<table
  mat-table
  [dataSource]="dataSource"
  matSort
  fxFlex
  recycleRows
  [trackBy]="trackByData"
>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let data">
      <pr-automation-status [status]="data.automation.status" />
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
    <td mat-cell *matCellDef="let data">
      {{ data.automation.type | map: getTypeLabel }}
    </td>
  </ng-container>

  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let data">
      <div fxLayout="row">
        <div class="title">
          <pr-content-text [content]="data.automation | map: getTitle" />
          <mat-icon
            class="created-by-tooltip"
            matTooltip="Created by: {{ data.automation.creator.name }}"
          >
            info
          </mat-icon>
        </div>
      </div>
      <div class="trigger-date" *ngIf="data | map: getStatus as status">
        <span
          *ngIf="status === 'completed'; else automationFailed"
          class="completed-date"
        >
          Completed On:
          <ng-container
            *ngIf="
              data.automation.triggerDate
                | momentTimezone$: data.automation.practiceRef
                | ngrxPush as triggerDate
            "
          >
            {{ triggerDate | moment | amDateFormat: dateFormat }}
          </ng-container>
        </span>

        <ng-template #automationFailed>
          <span
            *ngIf="status === 'failed'; else automationCancelled"
            class="failed-date"
          >
            Failed On:
            <ng-container
              *ngIf="
                data.automation.triggerDate
                  | momentTimezone$: data.automation.practiceRef
                  | ngrxPush as triggerDate
              "
            >
              {{ triggerDate | moment | amDateFormat: dateFormat }}
            </ng-container>
          </span>
        </ng-template>

        <ng-template #automationCancelled>
          <ng-container
            *ngIf="status === 'cancelled'; else automationIncomplete"
          />
        </ng-template>

        <ng-template #automationIncomplete>
          <ng-container *ngIf="data.newDate; else noRescheduleDate">
            <ng-container
              *ngIf="data.newDate | map: isBeforeNow; else dateSummary"
            >
              @if (data | map: willWaitForAppointmentComplete) {
                <span
                  matTooltip="This automation would normally run earlier than currently scheduled."
                >
                  Will run when appointment is complete
                </span>
              } @else {
                <span
                  matTooltip="This automation would normally run earlier than currently scheduled, or is scheduled to run immediately"
                >
                  Will run immediately
                </span>
              }
            </ng-container>
          </ng-container>
        </ng-template>

        <ng-template #dateSummary>
          <span matTooltip="New trigger date calculated from new event time">
            <ng-container *ngIf="data | map: getPracticeRef as practiceRef">
              <ng-container
                *ngIf="
                  data.newDate
                    | momentTimezone$: practiceRef
                    | ngrxPush as newDate
                "
              >
                {{ newDate | moment | amDateFormat: dateFormat }}
              </ng-container>
            </ng-container>
          </span>
        </ng-template>

        <ng-template #noRescheduleDate>Missing New Event Date</ng-template>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Off/On</th>
    <td mat-cell *matCellDef="let data">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-slide-toggle
          [checked]="data.reschedule"
          (change)="handleToggle($event, data.automation)"
        />
        <button mat-icon-button (click)="edit(data.automation)">
          <mat-icon>edit</mat-icon>
        </button>
        @if (
          data.automation.data | map: missingContactDetailsHint$ : this | async;
          as hint
        ) {
          <mat-icon class="warn-tooltip" [matTooltip]="hint">
            warning
          </mat-icon>
        }
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
