import { getEnumValues } from '../common';

export enum Month {
  January = 'january',
  Febraury = 'febraury',
  March = 'march',
  April = 'april',
  May = 'may',
  June = 'june',
  July = 'july',
  August = 'august',
  September = 'september',
  October = 'october',
  November = 'november',
  December = 'december',
}

export const MONTHS = getEnumValues(Month);
