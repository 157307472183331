import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  CalendarUnit,
  nextRange,
  previousRange,
} from '@principle-theorem/principle-core/interfaces';
import { type ITimePeriod } from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

interface IRangeToolTips {
  previous: string;
  next: string;
}

@Component({
    selector: 'pr-calendar-range-selector',
    templateUrl: './calendar-range-selector.component.html',
    styleUrls: ['./calendar-range-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CalendarRangeSelectorComponent {
  unit$ = new ReplaySubject<CalendarUnit>(1);
  currentRange$ = new ReplaySubject<ITimePeriod>(1);
  nextRange$: Observable<ITimePeriod>;
  previousRange$: Observable<ITimePeriod>;
  tooltips$: Observable<IRangeToolTips>;
  @Output() rangeChange = new EventEmitter<ITimePeriod>();

  @Input()
  set unit(unit: CalendarUnit) {
    if (!unit) {
      return;
    }
    this.unit$.next(unit);
  }

  @Input()
  set range(range: ITimePeriod) {
    if (!range) {
      return;
    }
    this.currentRange$.next(range);
  }

  constructor() {
    this.tooltips$ = this.unit$.pipe(
      map((unit) => ({
        previous: `Previous ${unit}`,
        next: `Next ${unit}`,
      }))
    );

    this.nextRange$ = combineLatest([this.unit$, this.currentRange$]).pipe(
      map(([unit, range]) => nextRange(unit, range))
    );
    this.previousRange$ = combineLatest([this.unit$, this.currentRange$]).pipe(
      map(([unit, range]) => previousRange(unit, range))
    );
  }
}
