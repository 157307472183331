import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { AutomationStatus } from '@principle-theorem/principle-core/interfaces';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { type AutomationEntity } from '@principle-theorem/principle-core';

@Component({
    selector: 'pr-automation-list-item-menu',
    templateUrl: './automation-list-item-menu.component.html',
    styleUrls: ['./automation-list-item-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AutomationListItemMenuComponent {
  automation$: ReplaySubject<AutomationEntity> = new ReplaySubject(1);
  canModify$: Observable<boolean>;
  canResend$: Observable<boolean>;
  isCancelled$: Observable<boolean>;

  @Output()
  editAutomation = new EventEmitter<AutomationEntity>();
  @Output()
  cancelAutomation = new EventEmitter<AutomationEntity>();
  @Output()
  restoreAutomation = new EventEmitter<AutomationEntity>();
  @Output()
  resendAutomation = new EventEmitter<AutomationEntity>();

  @Input()
  set automation(automation: AutomationEntity) {
    if (automation) {
      this.automation$.next(automation);
    }
  }

  constructor() {
    this.canModify$ = this.automation$.pipe(
      map((automation) =>
        [
          AutomationStatus.Pending,
          AutomationStatus.Queued,
          AutomationStatus.Failed,
        ].includes(automation.status)
      )
    );
    this.canResend$ = this.automation$.pipe(
      map((automation) =>
        [
          AutomationStatus.Skipped,
          AutomationStatus.Completed,
          AutomationStatus.Failed,
        ].includes(automation.status)
      )
    );
    this.isCancelled$ = this.automation$.pipe(
      map((automation) => automation.status === AutomationStatus.Cancelled)
    );
  }
}
