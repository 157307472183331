import { Injectable } from '@angular/core';
import { Editor } from '@tiptap/core';

@Injectable()
export class TableCommandsService {
  addColumnBefore(editor: Editor): void {
    editor.chain().focus().addColumnBefore().run();
  }

  addColumnAfter(editor: Editor): void {
    editor.chain().focus().addColumnAfter().run();
  }

  deleteColumn(editor: Editor): void {
    editor.chain().focus().deleteColumn().run();
  }

  deleteTable(editor: Editor): void {
    editor.chain().focus().deleteTable().run();
  }

  toggleHeaderColumn(editor: Editor): void {
    editor.chain().focus().toggleHeaderColumn().run();
  }

  toggleHeaderRow(editor: Editor): void {
    editor.chain().focus().toggleHeaderRow().run();
  }

  addRowBefore(editor: Editor): void {
    editor.chain().focus().addRowBefore().run();
  }

  addRowAfter(editor: Editor): void {
    editor.chain().focus().addRowAfter().run();
  }

  deleteRow(editor: Editor): void {
    editor.chain().focus().deleteRow().run();
  }

  mergeCells(editor: Editor): void {
    editor.chain().focus().mergeCells().run();
  }

  canMergeCells(editor: Editor): boolean {
    return editor.can().mergeCells();
  }

  splitCell(editor: Editor): void {
    editor.chain().focus().splitCell().run();
  }

  canSplitCell(editor: Editor): boolean {
    return editor.can().splitCell();
  }

  toggleCellMerge(editor: Editor): void {
    editor.chain().focus().mergeOrSplit().run();
  }
}
