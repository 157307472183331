import {
  ITag,
  ITranslationMap,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  INamedDocument,
  IXSLXExport,
  XSLXCell,
  isSameRef,
} from '@principle-theorem/shared';
import { Column } from 'exceljs';
import { IBaseMigrationFeeSchedule } from '../../../mappings/fee-schedules';

export interface IFeeScheduleToPatientTagToXSLX {
  sourceId: string;
  sourceName: string;
  mapTo: string;
}

export const FEE_SCHEDULE_TO_PATIENT_TAG_HEADERS: AtLeast<
  Column,
  'key' | 'header'
>[] = [
  {
    key: 'label',
    header: 'Fee Schedule',
  },
  {
    key: 'mapTo',
    header: 'Map To',
  },
];

export class FeeSchedulesToPatientTagXSLX
  implements
    IXSLXExport<IBaseMigrationFeeSchedule, IFeeScheduleToPatientTagToXSLX>
{
  headers = FEE_SCHEDULE_TO_PATIENT_TAG_HEADERS;

  constructor(
    private _patientTags: INamedDocument<ITag>[],
    private _existingMappings: ITranslationMap<ITag>[]
  ) {}

  translate(
    records: IBaseMigrationFeeSchedule[]
  ): Record<keyof IFeeScheduleToPatientTagToXSLX, XSLXCell>[] {
    return records.map((record) => {
      const existingRecord = this._existingMappings.find(
        (existingMapping) =>
          existingMapping.sourceIdentifier === record.id.toString()
      );
      const destinationIdentifier = existingRecord?.destinationIdentifier;
      const mappedCategory = destinationIdentifier
        ? this._patientTags.find((patientTag) =>
            isSameRef(patientTag.ref, destinationIdentifier)
          )
        : undefined;

      return {
        sourceId: { value: record.id },
        sourceName: { value: record.name },
        mapTo: {
          value: mappedCategory?.name || '',
          dataValidation: {
            type: 'list',
            formulae: [
              `"${this._patientTags
                .map((category) => category.name)
                .join(',')}"`,
            ],
          },
        },
      };
    });
  }
}
