import { Brand } from '@principle-theorem/principle-core';
import {
  type IPractice,
  type ICustomMapping,
  type IPracticeMigration,
  CustomMappingType,
} from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  multiMap,
  multiSortBy$,
  nameSorter,
  snapshot,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';

export const PRACTICE_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Practices',
    description: 'DocumentReference paths for practices',
    type: 'practice',
  },
  type: CustomMappingType.DocumentReference,
});

export class PraktikaPracticeMappingHandler extends BaseCustomMappingHandler<IPractice> {
  customMapping = PRACTICE_MAPPING;

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<IPractice>[]> {
    return snapshot(
      Brand.practices$(migration.configuration.brand).pipe(
        multiMap(toNamedDocument),
        multiSortBy$(nameSorter())
      )
    );
  }
}
