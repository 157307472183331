import {
  DestinationEntityCollection,
  DestinationEntityStatus,
  IDestinationEntity,
  type IDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  all$,
  subCollection,
  type CollectionReference,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

export class DestinationEntity {
  static init(
    overrides: Pick<IDestinationEntity, 'metadata'>
  ): IDestinationEntity {
    return {
      uid: overrides.metadata.key,
      status: DestinationEntityStatus.Uninitialised,
      ...overrides,
    };
  }

  static recordCol<T extends object>(
    destinationEntity: IReffable<IDestinationEntity>
  ): CollectionReference<IDestinationEntityRecord<T>> {
    return subCollection<IDestinationEntityRecord<T>>(
      destinationEntity.ref,
      DestinationEntityCollection.Records
    );
  }

  static records$<T extends object>(
    destinationEntity: IReffable<IDestinationEntity>
  ): Observable<WithRef<IDestinationEntityRecord<T>>[]> {
    return all$(DestinationEntity.recordCol(destinationEntity));
  }
}
