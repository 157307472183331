import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type AtLeast } from '@principle-theorem/shared';
import {
  confirmationDialogData,
  type IConfirmationDialogData,
} from '../confirm-dialog/confirm-dialog.component';
import { TrackByFunctions } from '../track-by';
import { ISelectDialogOption } from '../select-dialog/select-dialog.component';

export interface ISelectListDialogOption<T> extends ISelectDialogOption<T> {
  label: string;
  value: T;
  isDisabled?: boolean;
  disabledReason?: string;
  info?: string;
}

export interface ISelectListDialogData<T> extends IConfirmationDialogData {
  options: ISelectListDialogOption<T>[];
  lines?: number;
}

export type ISelectListDialogInput<T> = AtLeast<
  ISelectListDialogData<T>,
  'title' | 'prompt' | 'options'
>;

@Component({
  selector: 'pt-select-list-dialog',
  templateUrl: './select-list-dialog.component.html',
  styleUrls: ['./select-list-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectListDialogComponent {
  data: ISelectListDialogData<unknown>;
  hasInfo: boolean;
  trackByOption = TrackByFunctions.index<ISelectListDialogInput<unknown>>();
  trackByInfo = TrackByFunctions.variable<string>();

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ISelectListDialogInput<unknown>,
    public dialogRef: MatDialogRef<SelectListDialogComponent, unknown>
  ) {
    this.hasInfo = data.options.some((option) => !!option.info);
    this.data = {
      lines: this.hasInfo ? 2 : 1,
      ...confirmationDialogData(data),
      options: data.options,
    };
  }

  submit(value: unknown): void {
    this.dialogRef.close(value);
  }
}
