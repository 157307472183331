import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { Appointment } from '@principle-theorem/principle-core';
import {
  ChecklistType,
  IAppointment,
  IChecklistItem,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, snapshot } from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ChecklistFormDialogComponent } from '../../scheduling/checklist-form-dialog/checklist-form-dialog.component';

@Component({
  selector: 'pr-appointment-history-card-checklist',
  templateUrl: './appointment-history-card-checklist.component.html',
  styleUrls: ['./appointment-history-card-checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentHistoryCardChecklistComponent {
  checklistItems$: Observable<IChecklistItem[]>;
  appointment$ = new ReplaySubject<WithRef<IAppointment>>(1);
  checklistType$ = new BehaviorSubject<ChecklistType | undefined>(undefined);

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  @Input()
  set checklistType(checklistType: ChecklistType | undefined) {
    this.checklistType$.next(checklistType);
  }

  constructor(private _dialog: MatDialog) {
    this.checklistItems$ = combineLatest([
      this.appointment$,
      this.checklistType$,
    ]).pipe(
      switchMap(([appointment, checklistType]) =>
        Appointment.checklistItems$(appointment, checklistType)
      ),
      map((checklistItems) => sortBy(checklistItems, 'type').reverse())
    );
  }

  async openChecklistForm(): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    this._dialog.open(
      ChecklistFormDialogComponent,
      DialogPresets.medium({
        data: { appointment },
      })
    );
  }
}
