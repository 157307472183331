import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import {
  TrackByFunctions,
  TypedFormArray,
  TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import { type IPatientRelationship } from '@principle-theorem/principle-core/interfaces';
import { compact } from 'lodash';
import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import {
  type IPatientRelationships,
  isValidPatientRelationshipFormData,
  RelationshipFormGroup,
  transformRelationshipData,
} from './patient-relationship-form';

@Component({
    selector: 'pr-patient-relationship-form',
    templateUrl: './patient-relationship-form.component.html',
    styleUrls: ['./patient-relationship-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientRelationshipFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByIndex = TrackByFunctions.index();
  formData$ = new ReplaySubject<IPatientRelationships>(1);
  autoSave$ = new BehaviorSubject<boolean>(false);
  addingControl$ = new BehaviorSubject<boolean>(false);
  form = new TypedFormGroup<IPatientRelationships>({
    relationships: new TypedFormArray<IPatientRelationship>([
      new RelationshipFormGroup(),
    ]),
  });
  @Output() relationshipChange = new EventEmitter<IPatientRelationships>();

  @Input()
  set autoSave(autoSave: boolean) {
    if (autoSave) {
      this.autoSave$.next(autoSave);
    }
  }

  @Input()
  set formData(formData: IPatientRelationships) {
    if (formData) {
      this.formData$.next(formData);
    }
  }

  constructor() {
    this._initialiseControls();
    this._validateAutoSaveData();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  get relationships(): TypedFormArray<IPatientRelationship> {
    return this.form.controls
      .relationships as TypedFormArray<IPatientRelationship>;
  }

  addControl(quantity: number = 1): void {
    this.addingControl$.next(true);
    const relationships = this.relationships;
    for (let index = 0; index < quantity; index++) {
      relationships.push(new RelationshipFormGroup());
    }
    this.addingControl$.next(false);
  }

  private _initialiseControls(): void {
    this.formData$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((data) => {
        if (data.relationships.length > 0) {
          this.addControl(data.relationships.length);
          this.form.patchValue(data, { emitEvent: false });
        }
      });
  }

  private _validateAutoSaveData(): void {
    combineLatest([
      this.autoSave$.pipe(filter((autoSave) => autoSave)),
      validFormGroupChanges$(this.form),
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([_autoSave, data]) => {
        if (
          this.addingControl$.value ||
          !data.relationships.every(isValidPatientRelationshipFormData)
        ) {
          return;
        }
        const relationships = compact(
          data.relationships.map(transformRelationshipData)
        );
        this.relationshipChange.emit({ relationships });
      });
  }
}
