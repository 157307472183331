import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { isObject } from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { IBaseMigrationItemCode } from '../../../interfaces';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const ADA_ITEM_RESOURCE_TYPE = 'adaItem';

export const ADA_ITEM_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'ADA Item List',
    description: '',
    idPrefix: ADA_ITEM_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ID4WADAItem extends IBaseMigrationItemCode {
  id: number;
}

function isD4WADAItemListItem(item: unknown): item is ID4WADAItem {
  return (
    isObject(item) &&
    isNumber(item.id) &&
    isString(item.item_code) &&
    isString(item.description)
  );
}

const ADA_ITEM_SOURCE_QUERY = `
SELECT
  item_id AS id,
  item AS item_code,
  description
FROM procedures
WHERE item_id IS NOT NULL
ORDER BY id
`;

export class ADAItemSourceEntity extends BaseSourceEntity<ID4WADAItem> {
  sourceEntity = ADA_ITEM_SOURCE_ENTITY;
  entityResourceType = ADA_ITEM_RESOURCE_TYPE;
  sourceQuery = ADA_ITEM_SOURCE_QUERY;
  verifySourceFn = isD4WADAItemListItem;

  translate(_staffer: ID4WADAItem): object {
    return {};
  }

  getSourceRecordId(data: Pick<ID4WADAItem, 'id'>): number {
    return data.id;
  }

  getSourceLabel(data: ID4WADAItem): string {
    return `${data.id} ${data.itemCode}`;
  }
}
