<ng-container *ngIf="tasks$ | async as tasks">
  <mat-list *ngIf="tasks.length" class="grow">
    @for (task of tasks; track task.ref.id) {
      @defer (on viewport) {
        <pr-task-item
          [item]="task"
          [selectedItem]="selectedItem"
          [selectDisabled]="multiSelectDisabled$ | async"
          (selectedItemChange)="selectItem($event)"
          [selectionList]="selectionList"
          (taskUpdated)="taskUpdated.emit()"
        />
      } @placeholder (minimum 200ms) {
        <div class="flex grow px-1">
          <ngx-skeleton-loader
            appearance="circle"
            [theme]="{
              height: '40px',
              width: '40px',
              margin: '16px 0 0 80px'
            }"
          />

          <ngx-skeleton-loader
            class="mr-8 grow"
            [theme]="{
              height: '50px',
              margin: '8px 0 8px 16px'
            }"
          />
        </div>
      }
    }
    <ng-content />
  </mat-list>
</ng-container>
