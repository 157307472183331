import { ChartOptions, type IChartData } from './chart-builder';
import { ChartType } from '@principle-theorem/reporting';
import { BaseChart } from './base-chart';
import { ScatterDataTransformer } from './data-transformers/google-charts/scatter-data-transformer';

export class ScatterChart extends BaseChart {
  override dataTransformer = new ScatterDataTransformer();
  data: IChartData = {
    type: ChartType.Scatter,
    options: ChartOptions.init({
      legend: { position: 'none' },
    }),
  };
}
