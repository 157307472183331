import type { IHicaps, MedicareFunderData } from '@medipass/partner-sdk';
import { Patient } from '@principle-theorem/principle-core';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import {
  omitUndefined,
  splitName,
  type WithRef,
} from '@principle-theorem/shared';

export function hicaps(_patient: IPatient): IHicaps {
  return {
    transactionMethod: 'phone',
    patient: {
      // TODO: https://app.clickup.com/t/34rmup
      healthFundCode: '',
    },
  };
}

export async function medicare(
  patient: WithRef<IPatient>
): Promise<Partial<MedicareFunderData>> {
  const [firstName, lastName] = splitName(patient.name);
  const extraClaimantData = omitUndefined({
    mobile: await Patient.resolveMobileNumber(patient),
    accountNumber: patient.medicareCard?.number,
    reference: patient.medicareCard?.subNumerate,
  });

  const funderData: MedicareFunderData = {
    claimant: {
      firstName,
      lastName,
      mobile: (await Patient.resolveMobileNumber(patient)) ?? '',
      dob: patient.dateOfBirth ?? '',
      ...extraClaimantData,
    },
    nonReferral: {
      description: '',
      reason: 'not-required',
    },
  };
  // TODO: CU-1znt5v5- This needs to prepopulate
  // eslint-disable-next-line no-console
  console.log('this is not being sent', { funderData });

  return {};
}
