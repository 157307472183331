import { Injectable } from '@angular/core';
import {
  type IRoutable,
  StateBasedNavigationService,
  type IContextualAction,
} from '@principle-theorem/ng-principle-shared';

@Injectable()
export class LoadLabJobActionService implements IContextualAction {
  icon = 'open_in_new';
  name = 'View Lab Job';

  constructor(private _stateNav: StateBasedNavigationService) {}

  async do(labJob: IRoutable): Promise<void> {
    await this._stateNav.practice(['lab-jobs'], {
      queryParams: { labJob: labJob.uid },
    });
  }
}
