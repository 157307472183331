import { Injectable } from '@angular/core';
import { StafferSettingsStoreService } from '../staffer-settings-store.service';
import { StateBasedNavigationService } from './state-based-navigation.service';
import {
  Firestore,
  WithRef,
  snapshot,
  toISODate,
  toMomentTz,
} from '@principle-theorem/shared';
import { uniqBy } from 'lodash';
import { IAppointment } from '@principle-theorem/principle-core/interfaces';
import { TimezoneResolver } from '@principle-theorem/principle-core';

export const HIGHLIGHTED_NODE_QUERY_PARAM = 'highlightedNode';

@Injectable()
export class TimelineNavigationService {
  constructor(
    private _stateNav: StateBasedNavigationService,
    private _settingsStore: StafferSettingsStoreService
  ) {}

  async showOnTimeline(appointment: WithRef<IAppointment>): Promise<void> {
    if (!appointment.event) {
      return;
    }
    const practice = await Firestore.getDoc(appointment.practice.ref);
    const date = toISODate(
      toMomentTz(
        appointment.event.from,
        await TimezoneResolver.fromEvent(appointment)
      )
    );
    await this._updateStafferSettings(appointment);
    await this._stateNav.practice(
      ['schedule', 'timeline'],
      {
        queryParams: {
          from: date,
          to: date,
          [HIGHLIGHTED_NODE_QUERY_PARAM]: appointment.ref.id,
        },
      },
      practice
    );
  }

  private async _updateStafferSettings(
    appointment: WithRef<IAppointment>
  ): Promise<void> {
    const selectedStaff = await snapshot(
      this._settingsStore.filteredStaffByPractice$
    );
    if (!selectedStaff.length) {
      return;
    }

    const currentStaff = await snapshot(
      this._settingsStore.getStaffByPractice$(appointment.practice.ref)
    );

    const staffByPractice = {
      practice: appointment.practice.ref,
      staff: uniqBy(
        [...currentStaff, appointment.practitioner.ref],
        (staffer) => staffer.path
      ),
    };

    this._settingsStore.updateStafferSettings({
      timeline: {
        filteredStaffByPractice: [staffByPractice],
      },
    });
  }
}
