import {
  ServiceProviderHandler,
  TreatmentConfiguration,
  toINamedDocuments,
} from '@principle-theorem/principle-core';
import {
  ALL_SERVICE_CODES,
  CustomMappingAssociatedValueType,
  CustomMappingOption,
  CustomMappingType,
  IGetRecordResponse,
  ITranslationMap,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
  type IServiceCode,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  XSLXImporterExporter,
  asyncForEach,
  hardDeleteDoc,
  isINamedDocument,
  multiSortBy$,
  nameSorter,
  query$,
  snapshot,
  undeletedQuery,
  type INamedDocument,
  type IReffable,
  type WithRef,
  Firestore,
  IBlobFilenamePair,
} from '@principle-theorem/shared';
import { sortBy, uniqBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { BaseCustomMappingHandler } from '../base-custom-mapping-handler';
import { CustomMapping } from '../custom-mapping';
import { type IBaseMigrationItemCode } from '../interfaces';
import { PracticeMigration } from '../practice-migrations';
import { BaseSourceEntity } from '../source/base-source-entity';
import { TranslationMapHandler } from '../translation-map';
import { ITEM_CODE_TO_SERVICE_CODE_MAPPINGS } from './ada-code-mappings';
import { ItemCodeResourceMapType, ItemCodesToXLSX } from './item-codes-to-xlsx';
import { XLSXToItemCodes } from './xlsx-to-item-codes';

export const ITEM_CODE_CUSTOM_MAPPING_TYPE = 'itemCodeMapping';

export const ITEM_CODE_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Item Codes',
    description: `
      Define where non-ADA Item Codes from the source PMS should be mapped to in Principle. The different types and their effects are as follows:
        - Service Code: Map this to a valid Service Code in Principle.
        - Treatment: Map this service to a Treatment in Principle. eg. 'BOTOX' could be mapped to a 'Botox' Treatment.
        - Omit: This won't be migrated to a Treatment Plan, but will still be added to an Invoice if it has been billed for.
    `,
    type: ITEM_CODE_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.SelectionList,
  labelOverrides: {
    sourceIdentifier: 'Id',
    sourceLabel: 'Non-ADA Code',
    destinationIdentifier: 'Map To',
    associatedValue: 'Service Code/Treatment',
  },
});

export abstract class ItemCodesMappingHandler<
  ADAItem extends object,
  SourceEntity extends BaseSourceEntity<ADAItem>,
> extends BaseCustomMappingHandler<object, ItemCodeResourceMapType> {
  customMapping = ITEM_CODE_MAPPING;
  abstract translateFn: (record: ADAItem) => IBaseMigrationItemCode;
  abstract sourceEntity: SourceEntity;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const itemCodes = await this.getItemCodeOptions(migration);
    return itemCodes.map((record) => ({
      label: !record.description
        ? record.itemCode
        : `${record.itemCode} - ${record.description}`,
      value: record.id.toString(),
    }));
  }

  selectedOptionRequiresValue(
    destinationValue: ItemCodeResourceMapType
  ): boolean {
    return [
      ItemCodeResourceMapType.TreatmentConfiguration,
      ItemCodeResourceMapType.ServiceCode,
    ].includes(destinationValue);
  }

  async getSelectionListOptions(
    _migration: WithRef<IPracticeMigration>
  ): Promise<CustomMappingOption[]> {
    return snapshot(
      of([
        {
          value: ItemCodeResourceMapType.ServiceCode,
          description: 'Map this to a Service Code',
          hasAssociatedValue: true,
          associatedValueType: CustomMappingAssociatedValueType.String,
          associatedValueDescription:
            'Which Service Code should be used for this?',
        },
        {
          value: ItemCodeResourceMapType.TreatmentConfiguration,
          description: 'Map this to a Treatment',
          hasAssociatedValue: true,
          associatedValueType: CustomMappingAssociatedValueType.NamedDocument,
          associatedValueDescription:
            'Which Treatment should be used in place of this?',
        },
        {
          value: ItemCodeResourceMapType.Omit,
          description: 'Omit this Item Code from the migration',
          hasAssociatedValue: false,
        },
      ])
    );
  }

  getAssociatedValueOptions$(
    migration: IPracticeMigration,
    destinationValue: ItemCodeResourceMapType
  ): Observable<
    ({ name: string } | INamedDocument<ITreatmentConfiguration>)[]
  > {
    if (destinationValue === ItemCodeResourceMapType.ServiceCode) {
      return of(
        sortBy(
          ALL_SERVICE_CODES.map((code) => ({ name: String(code.code) })),
          'name'
        )
      );
    }

    if (destinationValue === ItemCodeResourceMapType.TreatmentConfiguration) {
      return TreatmentConfiguration.all$(migration.configuration.brand).pipe(
        toINamedDocuments(),
        multiSortBy$(nameSorter())
      );
    }

    return of([]);
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, itemCodes, translator } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(fileName, itemCodes, translator);
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, itemCodes, translator } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(fileName, itemCodes, translator);
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XLSXToItemCodes()
    );
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const records = await this.getRecords(translationMap);
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));

    const sourceOptions = await this.getSourceOptions(migration);
    const treatmentConfigurations =
      await this._getTreatmentConfigurations(migration);

    await asyncForEach(items, async (item) => {
      const label = item.description
        ? `${item.code} - ${item.description}`
        : item.code;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.label === label
      )?.value;

      if (!value || !item.associatedValue) {
        return;
      }

      const treatmentConfiguration = treatmentConfigurations.find(
        (config) => config.name === item.associatedValue
      );
      if (treatmentConfiguration) {
        await this.upsertRecord(
          {
            associatedValue: treatmentConfiguration,
            destinationValue: ItemCodeResourceMapType.TreatmentConfiguration,
            sourceIdentifier: value,
            sourceLabel: label,
          },
          translationMap
        );
        return;
      }

      const serviceCode = this.getServiceCode(item.associatedValue);
      if (serviceCode) {
        await this.upsertRecord(
          {
            associatedValue: serviceCode.code.toString(),
            destinationValue: ItemCodeResourceMapType.ServiceCode,
            sourceIdentifier: value,
            sourceLabel: label,
          },
          translationMap
        );
        return;
      }

      // eslint-disable-next-line no-console
      console.error(
        `Mapping error: ${this.customMapping.metadata.label} - Couldn't find value for item`,
        item
      );
    });
  }

  getServiceCode(code?: string): IServiceCode | undefined {
    if (!code) {
      return;
    }
    return ServiceProviderHandler.findServiceCode(code);
  }

  async getItemCodeOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<IBaseMigrationItemCode[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 10000)
      .toPromise();
    const options = records
      .map((record) => record.data.data)
      .map(this.translateFn)
      .filter(
        (record) => !ServiceProviderHandler.findServiceCode(record.itemCode)
      );
    return sortBy(
      uniqBy(options, (option) => option.itemCode),
      'itemCode'
    );
  }

  async autocompleteMappings(
    migration: WithRef<IPracticeMigration>
  ): Promise<void> {
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const itemCodes = await this.getItemCodeOptions(migration);
    const records = await this.getRecords(translationMap);
    const mappedItemCodes = records
      .filter((record) => !!record.destinationIdentifier)
      .map((record) => record.sourceIdentifier);
    const unmappedItemCodes = itemCodes.filter(
      (option) => !mappedItemCodes.includes(option.id.toString())
    );

    await asyncForEach(unmappedItemCodes, async (item) => {
      const formattedCode = item.itemCode
        .replace(/[.*+-]*/g, '')
        .replace(/[\sA-Za-z]*$/g, '');
      const codeMatch = ServiceProviderHandler.findServiceCode(formattedCode);

      if (!codeMatch) {
        return;
      }

      await this.upsertRecord(
        {
          associatedValue: codeMatch.code.toString(),
          destinationValue: ItemCodeResourceMapType.ServiceCode,
          sourceIdentifier: item.id.toString(),
          sourceLabel: item.itemCode,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    itemCodes: IBaseMigrationItemCode[];
    translator: ItemCodesToXLSX;
  }> {
    const fileName = this.getFileName();
    const itemCodes = await this.getItemCodeOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const translator = new ItemCodesToXLSX(
      await this.getRecords(translationMap),
      await this._getTreatmentConfigurations(migration),
      ITEM_CODE_TO_SERVICE_CODE_MAPPINGS
    );
    return { fileName, itemCodes, translator };
  }

  private async _getTreatmentConfigurations(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<ITreatmentConfiguration>[]> {
    return snapshot(
      query$(
        undeletedQuery(
          TreatmentConfiguration.col(migration.configuration.brand)
        )
      ).pipe(toINamedDocuments(), multiSortBy$(nameSorter()))
    );
  }
}

export function resolveMappedCode(
  itemCodeMappings: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[],
  sourceIdentifier: string,
  serviceCode: string
): IServiceCode | undefined {
  const code = ServiceProviderHandler.findServiceCode(serviceCode);
  if (code) {
    return code;
  }

  const mappedCode = itemCodeMappings.find(
    (itemCode) =>
      itemCode.sourceIdentifier === sourceIdentifier &&
      itemCode.destinationValue === ItemCodeResourceMapType.ServiceCode
  );
  if (!mappedCode?.associatedValue) {
    return;
  }

  if (isINamedDocument(mappedCode.associatedValue)) {
    return ServiceProviderHandler.findServiceCode(
      mappedCode.associatedValue.ref.id
    );
  }
  return ServiceProviderHandler.findServiceCode(mappedCode.associatedValue);
}

export async function resolveMappedCodeTreatment(
  sourceItemCodes: IGetRecordResponse<IBaseMigrationItemCode>[],
  _itemCodeMappings: WithRef<
    ITranslationMap<object, ItemCodeResourceMapType>
  >[],
  treatmentConfigurationMappings: WithRef<
    ITranslationMap<ITreatmentConfiguration>
  >[],
  defaultTreatmentConfiguration: WithRef<ITreatmentConfiguration>,
  _sourceIdentifier: string,
  serviceCode: IServiceCode
): Promise<WithRef<ITreatmentConfiguration> | undefined> {
  const sourceCode = sourceItemCodes.find(
    (code) => code.data.data.itemCode === serviceCode.code.toString()
  );
  if (!sourceCode) {
    return;
  }

  const mappedTreatment = treatmentConfigurationMappings.find(
    (mapping) => mapping.sourceIdentifier === sourceCode?.data.data.id
  );

  return mappedTreatment?.destinationIdentifier
    ? Firestore.getDoc(mappedTreatment.destinationIdentifier)
    : defaultTreatmentConfiguration;
}
