import { Injectable } from '@angular/core';
import {
  type IContextualAction,
  type IRoutable,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { isPrincipleMention } from '@principle-theorem/principle-core/interfaces';
import { type IReffable } from '@principle-theorem/shared';

@Injectable()
export class LoadPatientActionService implements IContextualAction {
  icon = 'open_in_new';
  name = 'Patient Profile';

  constructor(private _stateNav: StateBasedNavigationService) {}

  async do(patient: IRoutable | IReffable): Promise<void> {
    if (isPrincipleMention(patient)) {
      patient = patient.resource;
    }
    const uid: string = 'uid' in patient ? patient.uid : patient.ref.id;
    await this._stateNav.brand(['patients', uid]);
  }
}
