import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  MixedSchema,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { TypedFormControl } from '@principle-theorem/ng-shared';
import {
  Interaction,
  OFFER_TEMPLATE,
  Patient,
  addInteractions,
  stafferToNamedDoc,
  toMention,
} from '@principle-theorem/principle-core';
import {
  InteractionType,
  MentionResourceType,
  isPatient,
  type IInteractionData,
  type IInteractionV2,
  type IPatient,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  asDocRef,
  doc$,
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-gap-offer',
    templateUrl: './gap-offer.component.html',
    styleUrls: ['./gap-offer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GapOfferComponent {
  patient$: Observable<WithRef<IPatient>>;
  phone$: Observable<string | undefined>;
  sendEmail = false;
  sendSms = true;
  noteControl = new TypedFormControl<MixedSchema>(OFFER_TEMPLATE.render());

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IInteractionData,
    public dialogRef: MatDialogRef<GapOfferComponent>,
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService
  ) {
    this.patient$ = doc$(
      asDocRef<IPatient>(this.data.contact.resource.ref)
    ).pipe(filter((docData) => isPatient(docData)));

    this.phone$ = this.patient$.pipe(
      switchMap((patient) => Patient.resolveMobileNumber(patient))
    );
  }

  async send(): Promise<void> {
    if (!this.sendEmail && !this.sendSms) {
      return;
    }
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const contactType = this._getContactType();
    const interaction = this._getGapOfferInteraction(
      staffer,
      contactType,
      this.noteControl.value
    );

    await addInteractions(this.data, interaction);

    this._snackBar.open(`Appointment offer sent via ${contactType}`);
    this.dialogRef.close(interaction);
  }

  private _getContactType(): string {
    if (this.sendEmail && this.sendSms) {
      return 'Email and SMS';
    }
    if (this.sendEmail) {
      return 'Email';
    }
    return 'SMS';
  }

  private _getGapOfferInteraction(
    staffer: WithRef<IStaffer>,
    contactType: string,
    notes: MixedSchema
  ): IInteractionV2 {
    return Interaction.init({
      type: InteractionType.AppointmentOffer,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` sent appointment offer via ${contactType} to `),
        toMentionContent(this.data.contact),
      ],
      owner: stafferToNamedDoc(staffer),
      content: notes,
    });
  }
}
