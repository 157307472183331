import { WithRef } from '@principle-theorem/shared';
import { type IChartedItemConfiguration } from '../charted-item-configuration';
import {
  ChartedItemType,
  type IChartedItem,
  isChartedItem,
} from '../core/charted-item';

export interface IChartedCondition
  extends IChartedItem<IChartedItemConfiguration> {
  type: ChartedItemType.ChartedCondition;
}

export interface IChartedConditionWithResolvedConfig extends IChartedCondition {
  config: WithRef<IChartedItemConfiguration>;
}

export function isChartedCondition(
  chartedItem: unknown
): chartedItem is IChartedCondition {
  return (
    isChartedItem(chartedItem) &&
    'type' in chartedItem &&
    chartedItem.type === ChartedItemType.ChartedCondition
  );
}
