import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Appointment,
  Practice,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  IAppointment,
  IFollowUp,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  INamedDocument,
  WithRef,
  filterUndefined,
  multiSwitchMap,
  snapshot,
} from '@principle-theorem/shared';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  OrganisationService,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { switchMap } from 'rxjs/operators';
import {
  IPendingFollowUp,
  appointmentToPendingFollowUp$,
} from './pending-follow-up';
import {
  FollowUpComponent,
  IAppointmentFollowUpData,
} from './components/follow-up/follow-up.component';
import { CancelFollowUpComponent } from './components/cancel-follow-up/cancel-follow-up.component';

@Injectable()
export class FollowUpsService {
  existingFollowUps$: Observable<IPendingFollowUp[]>;
  upcomingFollowUps$: Observable<IPendingFollowUp[]>;

  constructor(
    private _dialog: MatDialog,
    private _organisation: OrganisationService,
    private _snackBar: MatSnackBar,
    private _stateNav: StateBasedNavigationService
  ) {
    const practice$ = this._organisation.practice$.pipe(filterUndefined());

    this.upcomingFollowUps$ = practice$.pipe(
      switchMap((practice) =>
        Practice.getAppointmentsWithUpcomingFollowUps$(practice)
      ),
      multiSwitchMap((appointment) =>
        appointmentToPendingFollowUp$(appointment)
      )
    );

    this.existingFollowUps$ = practice$.pipe(
      switchMap((practice) =>
        Practice.getAppointmentsWithExistingFollowUps$(practice)
      ),
      multiSwitchMap((appointment) =>
        appointmentToPendingFollowUp$(appointment)
      )
    );
  }

  async reschedule(appointment: WithRef<IAppointment>): Promise<void> {
    const patient = await Appointment.patient(appointment);
    await this._stateNav.brand([
      'patients',
      patient.ref.id,
      'appointments',
      appointment.ref.id,
      'manage',
      'reschedule',
    ]);
  }

  async postpone(appointment: WithRef<IAppointment>): Promise<void> {
    const data = await this._getAppointmentFollowUpDate(appointment);
    this._dialog.open(FollowUpComponent, DialogPresets.medium({ data }));
  }

  async cancel(appointment: WithRef<IAppointment>): Promise<void> {
    const data = await this._getAppointmentFollowUpDate(appointment);
    this._dialog.open(CancelFollowUpComponent, DialogPresets.medium({ data }));
  }

  async addToAppointment(
    appointment: WithRef<IAppointment>,
    followUp: IFollowUp,
    message = 'Follow Up Set'
  ): Promise<void> {
    const owner = await this._getInteractionOwner();
    await Appointment.addFollowUp(appointment, followUp, owner);
    this._snackBar.open(message);
  }

  private async _getAppointmentFollowUpDate(
    appointment: WithRef<IAppointment>
  ): Promise<IAppointmentFollowUpData> {
    return {
      appointment,
      patient: await Appointment.patient(appointment),
    };
  }

  private async _getInteractionOwner(): Promise<INamedDocument<IStaffer>> {
    const staffer = await snapshot(this._organisation.staffer$);
    if (!staffer) {
      throw new Error(`Couldn't resolve staffer`);
    }
    return stafferToNamedDoc(staffer);
  }
}
