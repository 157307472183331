import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard } from '@principle-theorem/shared';
import { flow, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const APPOINTMENT_CATEGORY_RESOURCE_TYPE = 'appointmentCategory';

export const APPOINTMENT_CATEGORY_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Appointment Category List',
      description: `
      The appointment table includes a category field, by mapping across to Treatment Categories in Principle we can display the category for any appointments
      that do not link to treatment steps or do not have explicit ADA codes in the service column (e.g. Recalls).
    `,
      idPrefix: APPOINTMENT_CATEGORY_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IExactAppointmentCategory {
  id: number;
  name: string;
}

function isExactAppointmentCategory(
  item: unknown
): item is IExactAppointmentCategory {
  return TypeGuard.interface<IExactAppointmentCategory>({
    id: isNumber,
    name: isString,
  })(item);
}

const APPOINTMENT_CATEGORY_QUERY = `
SELECT
	DISTINCT(apptcategory) as name,
	DENSE_RANK() OVER (results) AS id
FROM convappointment
WHERE
  apptcategory IS NOT NULL AND
  apptcategory != ''
WINDOW results AS (ORDER BY apptcategory)
ORDER BY apptcategory, id
`;

export class AppointmentCategorySourceEntity extends BaseSourceEntity<
  IExactAppointmentCategory,
  object
> {
  sourceEntity = APPOINTMENT_CATEGORY_SOURCE_ENTITY;
  entityResourceType = APPOINTMENT_CATEGORY_RESOURCE_TYPE;
  sourceQuery = APPOINTMENT_CATEGORY_QUERY;
  verifySourceFn = isExactAppointmentCategory;
  override transformDataFn = flow([]);

  translate(): Record<symbol, unknown> {
    return {};
  }

  getSourceRecordId(data: IExactAppointmentCategory): number {
    return data.id;
  }

  getSourceLabel(data: IExactAppointmentCategory): string {
    return data.name;
  }
}
