<mat-toolbar color="accent" class="logo-toolbar">
  <a [routerLink]="['/']">
    <ng-template #expandedLogo>
      <img
        src="/assets/icons/principle-row-white.svg"
        onerror="this.src='/assets/icons/principle-row-white.png'"
      />
    </ng-template>

    <ng-container
      *ngIf="(sidebar.expanded$ | async) === false; else expandedLogo"
    >
      <img
        src="/assets/icons/principle-logo.svg"
        onerror="this.src='/assets/icons/principle-logo.png'"
      />
    </ng-container>
  </a>
</mat-toolbar>

<div fxFlex class="content scrollable">
  <ng-content></ng-content>
</div>

<mat-toolbar
  *ngIf="(sidebar.isMobile$ | async) === false"
  color="accent"
  fxLayout="row"
  fxLayoutAlign="start center"
  class="expand-button"
  matRipple
  (click)="toggleExpanded()"
>
  <pt-app-version class="opacity-30" *ngIf="sidebar.expanded$ | async" />
  <div fxFlex></div>
  <mat-icon>{{
    (sidebar.expanded$ | async) ? 'chevron_left' : 'chevron_right'
  }}</mat-icon>
</mat-toolbar>
