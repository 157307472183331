import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  type IChartedTreatment,
} from '@principle-theorem/principle-core/interfaces';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-charted-treatment-plan',
    templateUrl: './charted-treatment-plan.component.html',
    styleUrls: ['./charted-treatment-plan.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChartedTreatmentPlanComponent {
  trackByStep = TrackByFunctions.uniqueId<IChartedMultiStepTreatmentStep>();
  trackByTreatment = TrackByFunctions.uniqueId<IChartedTreatment>();
  steps$: Observable<IChartedMultiStepTreatmentStep[]>;
  chartedMultiStepTreatment$: ReplaySubject<IChartedMultiStepTreatment> =
    new ReplaySubject(1);

  constructor() {
    this.steps$ = this.chartedMultiStepTreatment$.pipe(
      map((multiStep) => multiStep.steps)
    );
  }

  @Input()
  set chartedMultiStepTreatment(
    chartedMultiStepTreatment: IChartedMultiStepTreatment
  ) {
    if (chartedMultiStepTreatment) {
      this.chartedMultiStepTreatment$.next(chartedMultiStepTreatment);
    }
  }
}
