import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  type RouterStateSnapshot,
  type UrlTree,
} from '@angular/router';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthGuard, AuthService } from '@principle-theorem/ng-auth';
import { OrganisationService } from '../organisation.service';

@Injectable()
export class IsNotAuthorisedGuard extends AuthGuard {
  constructor(
    auth: AuthService,
    private _organisation: OrganisationService
  ) {
    super(auth);
  }

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._organisation.user$.pipe(
      map((user) => {
        if (user) {
          return false;
        }
        return true;
      })
    );
  }
}
