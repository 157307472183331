import { roundTo2Decimals } from '@principle-theorem/accounting';
import {
  IChartedItem,
  IChartedTreatment,
  IMultiStepTreatment,
  isChartedMultiStepTreatment,
  isChartedTreatment,
  isChartedTreatmentGroup,
  ITreatmentStep,
  ServiceCodeGroupType,
} from '@principle-theorem/principle-core/interfaces';
import { compact, sum } from 'lodash';
import { ChartedServiceExclusiveGroup } from '../service-codes/charted-service-exclusive-group';
import { ChartedServiceSmartGroup } from '../service-codes/charted-service-smart-group';

export class ChartedItemTotalCalculator {
  calc(item: IChartedItem): number {
    if (isChartedTreatment(item)) {
      return this.treatment(item);
    }

    if (isChartedMultiStepTreatment(item)) {
      return this.multiStep(item);
    }

    if (isChartedTreatmentGroup(item)) {
      return sum(item.treatments.map((treatment) => this.treatment(treatment)));
    }

    throw new Error(`Couldn't price item: ${item.config.name}`);
  }

  treatment(treatment: IChartedTreatment): number {
    const smartCodes = treatment.serviceCodeSmartGroups.map((group) =>
      ChartedServiceSmartGroup.getSelected(group)
    );

    const exclusiveCodes = treatment.serviceCodeGroups
      .filter((group) => group.type === ServiceCodeGroupType.Exclusive)
      .map((group) => ChartedServiceExclusiveGroup.getSelected(group));

    const treatmentTotal = [
      ...compact(smartCodes),
      ...compact(exclusiveCodes),
      ...treatment.serviceCodes,
    ].reduce((total, entry) => {
      const price = entry.priceOverride ?? entry.price;
      return total + price * entry.quantity;
    }, treatment.basePrice);

    return roundTo2Decimals(treatmentTotal);
  }

  treatmentTax(treatment: IChartedTreatment): number {
    const smartCodes = treatment.serviceCodeSmartGroups.map((group) =>
      ChartedServiceSmartGroup.getSelected(group)
    );

    const exclusiveCodes = treatment.serviceCodeGroups
      .filter((group) => group.type === ServiceCodeGroupType.Exclusive)
      .map((group) => ChartedServiceExclusiveGroup.getSelected(group));

    const taxTotal = [
      ...compact(smartCodes),
      ...compact(exclusiveCodes),
      ...treatment.serviceCodes,
    ].reduce((total, entry) => total + entry.tax, 0);

    return roundTo2Decimals(taxTotal);
  }

  step(step: ITreatmentStep): number {
    return step.treatments.reduce(
      (total, treatment) => total + this.treatment(treatment),
      0
    );
  }

  stepTax(step: ITreatmentStep): number {
    return step.treatments.reduce(
      (total, treatment) => total + this.treatmentTax(treatment),
      0
    );
  }

  multiStep(multiStep: Pick<IMultiStepTreatment, 'steps'>): number {
    return multiStep.steps.reduce((total, step) => total + this.step(step), 0);
  }
}
