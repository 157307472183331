import {
  BrandCollection,
  IBrand,
  IConditionConfiguration,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  IReffable,
  WithRef,
  query$,
  subCollection,
  undeletedQuery,
} from '@principle-theorem/shared';
import { CollectionReference } from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChartedItemConfiguration } from '../charted-item-configuration';

export class ConditionConfiguration {
  static init(
    overrides?: Partial<IConditionConfiguration>
  ): IConditionConfiguration {
    return {
      ...ChartedItemConfiguration.init(),
      ...overrides,
    };
  }

  static col(
    parent: IReffable<IBrand> | IReffable<IStaffer>
  ): CollectionReference<IConditionConfiguration> {
    return subCollection<IConditionConfiguration>(
      parent.ref,
      BrandCollection.ConditionConfigurations
    );
  }

  static all$(
    parent: IReffable<IBrand> | IReffable<IStaffer>
  ): Observable<WithRef<IConditionConfiguration>[]> {
    return query$(undeletedQuery(this.col(parent))).pipe(
      map((configuration) => sortBy(configuration, ['name']))
    );
  }
}
