<mat-form-field fxFlex [appearance]="appearance">
  <mat-label>Patient Fee Schedule</mat-label>
  <mat-select [formControl]="control" [compareWith]="compareFn">
    <mat-optgroup
      *ngFor="let group of groups$ | ngrxPush; trackBy: trackByGroup"
      [label]="group.name"
    >
      <mat-option
        *ngFor="let schedule of group.schedules; trackBy: trackBySchedule"
        [value]="schedule"
      >
        {{ schedule.name }}
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>
