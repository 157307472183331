import {
  type IMedipassClaim,
  type IMedipassPayment,
  type IMedipassWebhookData,
} from '@medipass/partner-sdk';
import { isObject } from '@principle-theorem/shared';
import { isClaim, isMedipassWebhookData, isPayment } from './webhook-data';

export interface IMedipassBaseExtendedData {
  webhookData: IMedipassWebhookData;
}

export interface IMedipassPaymentExtendedData
  extends IMedipassBaseExtendedData {
  payment: IMedipassPayment;
}

export interface IMedipassClaimExtendedData extends IMedipassBaseExtendedData {
  claim: IMedipassClaim;
}

export function isMedipassBaseExtendedData(
  item: unknown
): item is IMedipassBaseExtendedData {
  return isObject(item) && isMedipassWebhookData(item.webhookData);
}

export function isMedipassClaimExtendedData(
  item: unknown
): item is IMedipassClaimExtendedData {
  return (
    isObject(item) &&
    isMedipassWebhookData(item.webhookData) &&
    isClaim(item.claim)
  );
}

export function isMedipassPaymentExtendedData(
  item: unknown
): item is IMedipassPaymentExtendedData {
  return (
    isObject(item) &&
    isMedipassWebhookData(item.webhookData) &&
    isPayment(item.payment)
  );
}
