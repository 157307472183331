<div class="editor-content">
  <ng-container *ngIf="editMode; else preview">
    <pr-content-editor
      [required]="required"
      [menuEnabled]="isHTMLTemplate$ | async"
      [ngClass]="{ 'editor-padding': (isHTMLTemplate$ | async) === false }"
      [formControl]="formControl"
    />
  </ng-container>

  <ng-template #preview>
    <pr-template-preview
      [template]="formControl.value"
      [context]="context$ | async"
      [type]="templateType$ | async"
    />
  </ng-template>
</div>
<div fxLayout="row" fxLayoutGap="16px">
  <ng-content></ng-content>
  <div fxFlex>
    <a
      class="text-sm opacity-50"
      (click)="openContextPreviewDialog()"
      [matTooltip]="templateVariablesTooltip"
    >
      Personalise your message with template variables
    </a>
  </div>
  <div class="text-sm opacity-50">
    <a *ngIf="!editMode" (click)="editMode = true">edit</a>
    <a *ngIf="editMode" (click)="editMode = false">preview</a>
  </div>
</div>
