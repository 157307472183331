import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  ArchivedDocument,
  SHORT_DATE_TIME_FORMAT,
  DocumentReference,
  WithRef,
  DocumentArchive,
} from '@principle-theorem/shared';
import {
  IStaffer,
  ISterilisationCycle,
  STERILISATION_STATUS_COLOUR_MAP,
  SterilisationStatus,
} from '@principle-theorem/principle-core/interfaces';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { MatTableDataSource } from '@angular/material/table';
import { OrganisationCache } from '@principle-theorem/principle-core';
import { getSchemaText } from '@principle-theorem/editor';
import { NgSharedModule } from '@principle-theorem/ng-shared';

export interface ISterilisationCycleHistoryDialogData {
  history: WithRef<ArchivedDocument<ISterilisationCycle>>[];
}

@Component({
    selector: 'pr-sterilisation-cycle-history-dialog',
    imports: [NgPrincipleSharedModule, NgSharedModule],
    templateUrl: './sterilisation-cycle-history-dialog.component.html',
    styleUrl: './sterilisation-cycle-history-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SterilisationCycleHistoryDialogComponent {
  readonly dateFormat = SHORT_DATE_TIME_FORMAT;
  data = inject<ISterilisationCycleHistoryDialogData>(MAT_DIALOG_DATA);
  dataSource = new MatTableDataSource(
    this.data.history.sort((aCycle, bCycle) =>
      DocumentArchive.sortByArchivedAt(aCycle, bCycle)
    )
  );
  displayedColumns = [
    'id',
    'machine',
    'editedAt',
    'editedBy',
    'runDate',
    'status',
    'cycleType',
    'notes',
  ];

  getStatusColour(status: SterilisationStatus): string {
    return STERILISATION_STATUS_COLOUR_MAP[status];
  }

  async getEditedBy(
    cycle?: WithRef<ArchivedDocument<ISterilisationCycle>>
  ): Promise<WithRef<IStaffer> | undefined> {
    if (!cycle?.archivedBy) {
      return;
    }
    return OrganisationCache.staff.get.getDoc(
      cycle.archivedBy as DocumentReference<IStaffer>
    );
  }

  getNoteContent(
    cycle?: WithRef<ArchivedDocument<ISterilisationCycle>>
  ): string {
    return cycle?.notes ? getSchemaText(cycle.notes) : '';
  }
}
