import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { ITimePeriod } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';

interface IGapDateRange {
  title: string;
  value: number;
  unit: moment.unitOfTime.DurationConstructor;
}

@Component({
  selector: 'pr-gap-date-range-selector',
  templateUrl: './gap-date-range-selector.component.html',
  styleUrls: ['./gap-date-range-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GapDateRangeSelectorComponent {
  form = new TypedFormGroup<{ range: string }>({
    range: new TypedFormControl<string>(''),
  });

  readonly dateFormat = 'MMM Do';
  readonly gapDateRanges: IGapDateRange[] = [
    {
      title: 'Next 3 days',
      value: 3,
      unit: 'days',
    },
    {
      title: 'Next week',
      value: 1,
      unit: 'week',
    },
    {
      title: 'Next 2 weeks',
      value: 2,
      unit: 'weeks',
    },
    {
      title: 'Next month',
      value: 1,
      unit: 'month',
    },
    {
      title: 'Next 3 months',
      value: 3,
      unit: 'months',
    },
  ];
  @Output() dateRangeChange = new EventEmitter<ITimePeriod>();

  clear(): void {
    this.form.reset();
  }

  setDateRange(dateRange: IGapDateRange): void {
    this.dateRangeChange.emit({
      from: moment(),
      to: moment().add(dateRange.value, dateRange.unit),
    });
  }
}
