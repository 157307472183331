import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  IMatSelectOption,
  NgSharedModule,
  TypedFormControl,
  TypedFormGroup,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import { SCHEDULING_EVENT_ACTION_LABEL_MAP } from '@principle-theorem/principle-core';
import { SchedulingEventType } from '@principle-theorem/principle-core/interfaces';
import { getEnumValues } from '@principle-theorem/shared';
import { noop } from 'lodash';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

export enum ScheduleHistoryQueryByDate {
  AffectsDate = 'affectsDate',
  EventDate = 'eventDate',
}

export interface ISchedulingEventFilterFormData {
  byDate: ScheduleHistoryQueryByDate;
  eventTypes: SchedulingEventType[];
  filterValue: string;
}

export const SCHEDULING_EVENT_FILTER_FORM_DEFAULT_DATA: ISchedulingEventFilterFormData =
  {
    byDate: ScheduleHistoryQueryByDate.AffectsDate,
    eventTypes: [
      SchedulingEventType.Schedule,
      SchedulingEventType.Reschedule,
      SchedulingEventType.Cancel,
    ],
    filterValue: '',
  };

const BY_DATE_OPTIONS: IMatSelectOption<ScheduleHistoryQueryByDate>[] = [
  {
    label: 'Affected these dates',
    value: ScheduleHistoryQueryByDate.AffectsDate,
  },
  {
    label: 'Happened on these dates',
    value: ScheduleHistoryQueryByDate.EventDate,
  },
];

const EVENT_TYPE_OPTIONS: IMatSelectOption<SchedulingEventType>[] =
  getEnumValues(SchedulingEventType).map((eventType) => ({
    label: SCHEDULING_EVENT_ACTION_LABEL_MAP[eventType],
    value: eventType,
  }));

@Component({
  selector: 'pr-scheduling-event-query-form',
  templateUrl: './scheduling-event-query-form.component.html',
  styleUrls: ['./scheduling-event-query-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgMaterialModule,
    NgSharedModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SchedulingEventQueryFormComponent),
    },
  ],
})
export class SchedulingEventQueryFormComponent
  implements ControlValueAccessor, OnDestroy
{
  private _onDestroy$ = new Subject<void>();
  onChange: (_: unknown) => void = noop;
  onTouched: (_: unknown) => void = noop;
  byDateOptions = BY_DATE_OPTIONS;
  eventTypes = EVENT_TYPE_OPTIONS;
  queryForm = new TypedFormGroup<ISchedulingEventFilterFormData>({
    byDate: new TypedFormControl<ScheduleHistoryQueryByDate>(
      ScheduleHistoryQueryByDate.AffectsDate,
      Validators.required
    ),
    eventTypes: new TypedFormControl<SchedulingEventType[]>(
      [
        SchedulingEventType.Schedule,
        SchedulingEventType.Reschedule,
        SchedulingEventType.Cancel,
      ],
      Validators.required
    ),
    filterValue: new TypedFormControl<string>(''),
  });

  constructor() {
    formControlChanges$<ISchedulingEventFilterFormData>(this.queryForm)
      .pipe(distinctUntilChanged(), takeUntil(this._onDestroy$))
      .subscribe((formData) => this.onChange(formData));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  writeValue(data: ISchedulingEventFilterFormData): void {
    this.queryForm.setValue(data, { emitEvent: false });
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.queryForm.disable();
      return;
    }
    this.queryForm.enable();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
