<div fxLayout="column" fxLayoutGap="4px">
  <ng-container
    *ngFor="let contactNumber of contactNumbers$ | async; trackBy: trackByIndex"
  >
    <div *ngIf="contactNumber.number.length">
      @if (contactNumber.label) {
        <strong>{{ contactNumber.label | titlecase }}: </strong>
      } @else {
        <strong>Phone: </strong>
      }
      <a href="tel:{{ contactNumber.number }}">{{ contactNumber.number }}</a>
    </div>
  </ng-container>
</div>
