<div
  fxFlex
  #scrollMe
  class="notes-list"
  [ngClass]="{ scroll: enableScroll !== undefined }"
>
  <ng-container *ngIf="interactions$ | async as interactions">
    <ng-container *ngIf="interactions.length; else noInteractions">
      <pr-interaction
        *ngFor="let interaction of interactions; trackBy: trackByInteraction"
        [interaction]="interaction"
        [pinnableTypes]="pinnableTypes$ | async"
        [compact]="isCompact$ | ngrxPush"
        [editable]="editable$ | async"
        (interactionUpdated)="interactionUpdated.emit($event)"
        (interactionDeleted)="interactionDeleted.emit(interaction)"
      />
    </ng-container>
    <ng-template #noInteractions>
      <pr-empty-state image="list" title="notes" />
    </ng-template>
  </ng-container>
  <ng-container *ngIf="pinnedInteractions$ | async as pinnedInteractions">
    <ng-container *ngIf="pinnedInteractions.length">
      <mat-divider />
      <h3 class="layout-margin">Pinned Notes</h3>
      <pr-interaction
        *ngFor="
          let interaction of pinnedInteractions;
          trackBy: trackByInteraction
        "
        [interaction]="interaction"
        [pinnableTypes]="pinnableTypes$ | async"
        [compact]="isCompact$ | ngrxPush"
        [editable]="editable$ | async"
        (interactionUpdated)="interactionUpdated.emit($event)"
        (interactionDeleted)="interactionDeleted.emit(interaction)"
      />
    </ng-container>
  </ng-container>
</div>

<ng-container *ngIf="(readOnly$ | async) === false">
  <div *ngIf="useEditor$ | async; else noteForm">
    <div class="add-note">
      <pr-content-editor
        placeholder="Add Note"
        [formControl]="noteControl"
        (keydown.meta.enter)="addNote$.next()"
      />
    </div>

    <pt-buttons-container padding="all-round">
      <button
        mat-flat-button
        color="primary"
        [disabled]="buttonDisabled$ | async"
        (click)="addNote$.next()"
      >
        Add Note
      </button>
    </pt-buttons-container>
  </div>
</ng-container>

<ng-template #noteForm>
  <mat-divider />
  <pr-note-form #patientNoteForm placeholder="Add a patient note" />
  <pt-buttons-container padding="all-round">
    <button
      mat-flat-button
      color="primary"
      [disabled]="patientNoteForm.isDisabled$ | async"
      (click)="addPatientNote(patientNoteForm.noteForm)"
    >
      Add Note
    </button>
  </pt-buttons-container>
</ng-template>
