import { Task } from './task';
import { WithRef, isSameRef } from '@principle-theorem/shared';
import { IStaffer, ITask } from '@principle-theorem/principle-core/interfaces';

export class TaskGrouper {
  groups: ITaskGroupSorter[] = [
    {
      name: 'My Tasks',
      isInGroup: (task: WithRef<ITask>, staffer: WithRef<IStaffer>) => {
        return (
          !Task.complete(task) &&
          !task.deleted &&
          task.assignedUser !== undefined &&
          isSameRef(task.assignedUser, staffer)
        );
      },
      sort: (taskA: WithRef<ITask>, taskB: WithRef<ITask>): number => {
        if (!taskA.dueDate || !taskB.dueDate) {
          return -1;
        }
        return taskA.dueDate.seconds > taskB.dueDate.seconds ? 1 : -1;
      },
    },
    {
      name: 'Delegated Tasks',
      isInGroup: (task: WithRef<ITask>, staffer: WithRef<IStaffer>) => {
        return (
          !Task.complete(task) &&
          !task.deleted &&
          task.owner !== undefined &&
          task.owner.ref.path === staffer.ref.path &&
          (!task.assignedUser ||
            task.assignedUser.ref.path !== staffer.ref.path)
        );
      },
      sort: (taskA: WithRef<ITask>, taskB: WithRef<ITask>): number => {
        if (!taskA.dueDate || !taskB.dueDate) {
          return -1;
        }
        return taskA.dueDate.seconds > taskB.dueDate.seconds ? 1 : -1;
      },
    },
    {
      name: 'Completed Tasks',
      isInGroup: (task: WithRef<ITask>, _staffer: WithRef<IStaffer>) => {
        return Task.complete(task) && !task.deleted;
      },
      sort: (taskA: WithRef<ITask>, taskB: WithRef<ITask>): number => {
        return taskA.updatedAt.seconds > taskB.updatedAt.seconds ? 1 : -1;
      },
    },
    {
      name: 'Deleted Tasks',
      isInGroup: (task: WithRef<ITask>, _staffer: WithRef<IStaffer>) => {
        return task.deleted;
      },
      sort: (taskA: WithRef<ITask>, taskB: WithRef<ITask>): number => {
        return taskA.updatedAt.seconds > taskB.updatedAt.seconds ? 1 : -1;
      },
    },
  ];

  getByGroup(
    tasks: WithRef<ITask>[],
    staffer: WithRef<IStaffer>
  ): ITaskGroup[] {
    return this.groups.map((sorter: ITaskGroupSorter) => {
      const filtered: WithRef<ITask>[] = tasks
        .filter((task: WithRef<ITask>) => sorter.isInGroup(task, staffer))
        .sort(sorter.sort);

      return {
        name: sorter.name,
        tasks: filtered,
      };
    });
  }
}

export interface ITaskGroup {
  name: string;
  tasks: WithRef<ITask>[];
}

interface ITaskGroupSorter {
  name: string;
  isInGroup: (task: WithRef<ITask>, staffer: WithRef<IStaffer>) => boolean;
  sort: (taskA: WithRef<ITask>, taskB: WithRef<ITask>) => number;
}
