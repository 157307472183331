import {
  IIntegration,
  IntegrationStorage,
  IntegrationType,
} from '@principle-theorem/integrations';
import {
  IOrganisation,
  IPractice,
  SenderType,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, isDocRef, isSameRef } from '@principle-theorem/shared';
import {
  CollectionReference,
  DocumentReference,
} from '@principle-theorem/shared';
import { isString, isUndefined } from 'lodash';
import { resolveOrganisationFromSender } from '../interaction/send-sms-request';
import { Organisation } from '../organisation/organisation';

export function isTwilioIntegration(
  item: IIntegration
): item is IIntegration<ITwilioIntegrationData> {
  return item.type === IntegrationType.Twilio;
}

export interface ITwilioPracticeNumber {
  phoneNumber: string;
  practiceRef: DocumentReference<IPractice>;
  disabled?: boolean;
}

export function isTwilioPracticeNumber(
  item: unknown
): item is ITwilioPracticeNumber {
  return TypeGuard.interface<ITwilioPracticeNumber>({
    phoneNumber: isString,
    practiceRef: isDocRef,
    disabled: [isString, isUndefined],
  })(item);
}

export interface ITwilioConfig {
  authToken?: string;
  accountSid?: string;
  phoneNumbers?: ITwilioPracticeNumber[];
  outboundCost?: number;
  inboundCost?: number;
}

export interface ITwilioIntegrationData extends ITwilioConfig {
  enabled: boolean;
  testModeEnabled: boolean;
}

export class TwilioIntegrationStorage extends IntegrationStorage<ITwilioIntegrationData> {
  constructor() {
    super(IntegrationType.Twilio);
  }

  static init(data: Partial<ITwilioIntegrationData>): ITwilioIntegrationData {
    return {
      enabled: false,
      testModeEnabled: false,
      authToken: '',
      accountSid: '',
      phoneNumbers: [],
      ...data,
    };
  }

  static isEnabled(data?: ITwilioIntegrationData): boolean {
    return data?.enabled &&
      data.accountSid &&
      data.authToken &&
      data.phoneNumbers?.filter((phoneNumber) => !phoneNumber.disabled).length
      ? true
      : false;
  }

  override async upsert(
    integrationsCollection: CollectionReference<
      IIntegration<ITwilioIntegrationData>
    >,
    data: Partial<ITwilioIntegrationData>
  ): Promise<void> {
    const current = await this.get(integrationsCollection);
    return super.upsert(
      integrationsCollection,
      TwilioIntegrationStorage.init({ ...current?.data, ...data })
    );
  }
}

export async function resolvePracticeMobileNumber(
  practiceRef: DocumentReference<IPractice>
): Promise<string | undefined> {
  const settings = await resolveTwilioIntegrationSettings(practiceRef);
  return settings?.phoneNumbers?.find(
    (phoneNumber) =>
      isSameRef(phoneNumber.practiceRef, practiceRef) && !phoneNumber.disabled
  )?.phoneNumber;
}

export async function resolveTwilioIntegrationSettings(
  practiceRef: DocumentReference<IPractice>
): Promise<ITwilioIntegrationData | undefined> {
  const org = await resolveOrganisationFromSender({
    type: SenderType.Practice,
    ref: practiceRef,
  });
  const integrationsCol =
    Organisation.integrationCol<ITwilioIntegrationData>(org);
  const storage = new TwilioIntegrationStorage();
  const data = await storage.get(integrationsCol);
  return data?.data;
}

export async function resolveIntegrationPracticeFromNumber(
  organisationRef: DocumentReference<IOrganisation>,
  phoneNumber: string
): Promise<DocumentReference<IPractice> | undefined> {
  const data = await resolveTwilioOrgIntegrationSettings(organisationRef);
  if (!data) {
    return;
  }

  return data.phoneNumbers?.find(
    (number) =>
      number.phoneNumber.replace(/ /g, '') === phoneNumber.replace(/ /g, '')
  )?.practiceRef;
}

export async function resolveTwilioOrgIntegrationSettings(
  organisationRef: DocumentReference<IOrganisation>
): Promise<ITwilioIntegrationData | undefined> {
  const integrationsCol = Organisation.integrationCol<ITwilioIntegrationData>({
    ref: organisationRef,
  });
  const storage = new TwilioIntegrationStorage();
  const data = await storage.get(integrationsCol);
  return data?.data;
}
