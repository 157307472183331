import {
  IBrand,
  IOrganisation,
  IPractice,
  IPracticeMigration,
  PracticeMigrationStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  Properties,
  Timezone,
} from '@principle-theorem/shared';
import { MockNamedDocument } from '@principle-theorem/testing';

export class PracticeMigrationMock
  extends BaseFirestoreMock
  implements Properties<IPracticeMigration>
{
  metadata = {
    name: 'test',
    version: '1',
    supportsMultiplePractices: false,
  };
  source = {
    metadata: {
      name: 'test',
      version: '1',
    },
    configuration: [],
  };
  destination = {
    metadata: {
      name: 'test',
      description: 'test',
    },
    configuration: [],
  };
  mappings = {
    metadata: {
      label: 'test',
      description: 'test',
    },
  };
  status = PracticeMigrationStatus.Pending;
  translationMaps = [];
  manualTranslationMap = [];
  configuration = {
    organisation: MockNamedDocument<IOrganisation>('organisation'),
    brand: MockNamedDocument<IBrand>('brand'),
    practices: [MockNamedDocument<IPractice>('practice')],
    projectId: '1',
    destinationBucket: 'test',
    timezone: Timezone.AustraliaSydney,
    backupDate: 'test',
  };
}
