import { ChartableSurface } from './chartable-surface';
import { type Arch, type Quadrant } from './quadrant';
import { type IToothRef } from './tooth';

export type ScopedSurface =
  | ChartableSurface.Arch
  | ChartableSurface.Quadrant
  | ChartableSurface.WholeMouth
  | ChartableSurface.Crown
  | ChartableSurface.WholeTooth
  | ChartableSurface.MultipleTeeth
  | ChartableSurface.Unscoped;

export interface IScopeRef {
  scope: ScopedSurface;
  ref?: Arch | Quadrant | IToothRef;
}

export const SCOPED_SURFACES: ScopedSurface[] = [
  ChartableSurface.Arch,
  ChartableSurface.Quadrant,
  ChartableSurface.WholeMouth,
  ChartableSurface.WholeTooth,
];
