import { type ChartBuilder } from './chart-builder';
import { type IDataPoint } from './measure-data-interfaces';

export interface IChartCard {
  title: string;
  chartBuilder: ChartBuilder;
  unit?: string;
  value?: IDataPoint;
  summary?: string;
}
