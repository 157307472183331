import { IPatient } from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { IBaseCommand, IBridgeIntegration } from '../../send-command-to-device';

export const ROMEXIS_INTEGRATION: IBridgeIntegration = {
  label: 'Romexis',
  description:
    'One-way integration for opening patient files within Planmeca Romexis',
};

export type RomexisOpenPatientCommand = IBaseCommand<
  RomexisToDeviceCommand.OpenRomexis,
  { patient: WithRef<IPatient>; mode: RomexisCaptureMode }
>;

export enum RomexisCaptureMode {
  None,
  Panoramic,
  Cephalometric,
  Intraoral,
  Intracam,
  Twain,
  CrScanner,
  CBCT3D,
}

export const ROMEXIS_CAPTURE_MODES = [
  {
    mode: RomexisCaptureMode.None,
    label: 'Open Patient',
  },
  {
    mode: RomexisCaptureMode.Panoramic,
    label: 'Panoramic',
  },
  {
    mode: RomexisCaptureMode.Cephalometric,
    label: 'Cephalometric',
  },
  {
    mode: RomexisCaptureMode.Intraoral,
    label: 'Intraoral',
  },
  {
    mode: RomexisCaptureMode.Intracam,
    label: 'Intracam',
  },
  {
    mode: RomexisCaptureMode.Twain,
    label: 'TWAIN',
  },
  {
    mode: RomexisCaptureMode.CrScanner,
    label: 'CR Scanner',
  },
  {
    mode: RomexisCaptureMode.CBCT3D,
    label: 'CBCT 3D',
  },
];

export enum RomexisToDeviceCommand {
  OpenRomexis = 'romexis.openRomexis',
}
