import { randFloat, randNumber } from '@ngneat/falso';
import { type IPracticeStats } from '@principle-theorem/reporting';
import type * as moment from 'moment-timezone';
import { PracticeStatistics } from './practice-statistics';
import { StatisticsMocker } from './statistics-mocker';

export class PracticeStatisticsMocker extends StatisticsMocker<
  IPracticeStats,
  PracticeStatistics
> {
  constructor() {
    super(MOCK_PRACTICES);
  }

  mockStatistic(name: string, date: moment.Moment): PracticeStatistics {
    const metric: PracticeStatistics = new PracticeStatistics();
    const brandHours = 8;
    const numberOfChairs: number = randNumber({ min: 2, max: 5 });
    const numberOfStaff: number = randNumber({
      min: numberOfChairs * 2,
      max: numberOfChairs * 5,
    });
    const chairHoursAvailable: number = brandHours * numberOfChairs;
    const staffHoursAvailable: number = brandHours * numberOfStaff;
    const fullTimeEquivalentRatio: number =
      staffHoursAvailable / chairHoursAvailable;
    const timeUsed: number = randNumber({
      min: chairHoursAvailable / 2,
      max: chairHoursAvailable,
    });
    const timeUnused: number = chairHoursAvailable - timeUsed;

    const grossIncome: number = randNumber({ min: 9600, max: 32000 });
    const hourlyRate: number = timeUsed > 0 ? grossIncome / timeUsed : 0;
    const lostIncome: number = hourlyRate * timeUnused;
    const caseAcceptanceRate: number = randFloat({
      min: 0.15,
      max: 0.9,
      fraction: 2,
    });
    const patientsSeen: number = randNumber({ min: 5, max: 12 });
    const newPatients: number = randNumber({ max: 3 });
    const existingPatients: number = patientsSeen - newPatients;
    const cancellations: number = randNumber({ max: 3 });
    const ftas: number = randNumber({ max: cancellations });
    const utas: number = cancellations - ftas;
    const overdueInvoiceCount: number = randNumber({ max: 2 });
    const overdueAmount: number =
      overdueInvoiceCount * randNumber({ max: 1500 });
    const writtenOffInvoiceCount: number = Math.random() > 0.9 ? 1 : 0;
    const writtenOffInvoicesTotal: number = !writtenOffInvoiceCount
      ? 0
      : writtenOffInvoiceCount * randNumber({ max: 1500 });
    const paymentPlanCount: number = randNumber({ max: 4 });
    const paymentPlanAmount: number =
      paymentPlanCount * randNumber({ max: 10000 });
    const labExpenses: number = randNumber({ max: 1000 });
    const staffExpenses: number = randNumber({ min: 1200, max: 1500 });
    const consumables: number = randNumber({ min: 200, max: 400 });
    const facilityCosts: number = randNumber({ min: 100, max: 200 });
    const dentalDraw: number = randNumber({ min: 5000, max: 10000 });
    const totalExpenses: number =
      labExpenses + staffExpenses + consumables + facilityCosts + dentalDraw;

    metric.name = name;
    metric.date = date;
    metric.stats.grossIncome.metric = grossIncome;
    metric.stats.netIncome.metric = grossIncome - totalExpenses;
    metric.stats.appointments.metric = randNumber({ max: 12 });
    metric.stats.completedAppointments.metric = randNumber({ max: 12 });
    metric.stats.scheduledAppointments.metric = randNumber({ max: 16 });
    metric.stats.unscheduledAppointments.metric = randNumber({ max: 12 });
    metric.stats.revenueFromCompletedAppointments.metric = randNumber({
      max: 18000,
    });
    metric.stats.cancelledAppointments.metric = randNumber({ max: 12 });
    metric.stats.labExpenses.metric = labExpenses;
    metric.stats.staffExpenses.metric = staffExpenses;
    metric.stats.consumables.metric = consumables;
    metric.stats.facilityCosts.metric = facilityCosts;
    metric.stats.completedLabJobs.metric = randNumber({ max: 4 });
    metric.stats.completedTasks.metric = randNumber({ max: 20 });
    metric.stats.revenueFromProductsSold.metric = randNumber({ max: 1000 });
    metric.stats.patientsInAppointment.metric = 500;
    metric.stats.newPatients.metric = newPatients;
    metric.stats.existingPatients.metric = existingPatients;
    metric.stats.activePatients.metric = randNumber({ max: 30 });
    metric.stats.inactivePatients.metric = randNumber({ max: 2 });
    metric.stats.staffOnAnnualLeave.metric = randNumber({ max: 2 });
    metric.stats.staffOnSickLeave.metric = randNumber({ max: 2 });
    metric.stats.staffOnRosteredDaysOff.metric = randNumber({ max: 2 });
    metric.stats.revenueKPI.metric = 10000;
    metric.stats.emptyChairTime.metric = randNumber({ max: 2 });
    metric.stats.dentalDraw.metric = dentalDraw;
    metric.stats.totalExpenses.metric = totalExpenses;
    metric.stats.rescheduleRate.metric = randFloat({
      min: 0.15,
      max: 0.9,
      fraction: 2,
    });
    metric.stats.cancellationRate.metric = randFloat({
      min: 0.15,
      max: 0.9,
      fraction: 2,
    });
    metric.stats.conversionRate.metric = randFloat({
      min: 0.15,
      max: 0.9,
      fraction: 2,
    });
    metric.stats.appointmentDuration.metric = randNumber({
      min: 15,
      max: 120,
    });
    metric.stats.overdueInvoicesCount.metric = overdueInvoiceCount;
    metric.stats.overdueInvoicesTotal.metric = overdueAmount;
    metric.stats.writtenOffInvoicesTotal.metric = writtenOffInvoicesTotal;
    metric.stats.paymentPlansCount.metric = paymentPlanCount;
    metric.stats.paymentPlansTotal.metric = paymentPlanAmount;
    metric.stats.paidOnCheckoutRate.metric = randFloat({
      min: 0.85,
      max: 0.92,
      fraction: 2,
    });
    metric.stats.paidAfterCheckoutRate.metric = randFloat({
      min: 0.01,
      max: 0.05,
      fraction: 2,
    });
    metric.stats.neverPaidRate.metric = randFloat({
      min: 0.01,
      max: 0.03,
      fraction: 2,
    });
    metric.stats.lostIncome.metric = lostIncome;
    metric.stats.caseAcceptanceRate.metric = caseAcceptanceRate;
    metric.stats.averagePatientSpend.metric = grossIncome / patientsSeen;
    metric.stats.averagePatientWaitTime.metric = randNumber({
      min: 3,
      max: 30,
    });
    metric.stats.patientsSeen.metric = patientsSeen;
    metric.stats.ftas.metric = ftas;
    metric.stats.utas.metric = utas;
    metric.stats.numberOfChairs.metric = numberOfChairs;
    metric.stats.numberOfStaff.metric = numberOfStaff;
    metric.stats.chairHoursAvailable.metric = chairHoursAvailable;
    metric.stats.staffHoursAvailable.metric = staffHoursAvailable;
    metric.stats.fullTimeEquivalentRatio.metric = fullTimeEquivalentRatio;
    metric.stats.timeUsed.metric = timeUsed;
    metric.stats.timeUnused.metric = timeUnused;

    return metric;
  }
}

export const MOCK_PRACTICES: string[] = [
  'North Sydney',
  'Abbotsbury',
  // 'Abbotsford',
  // 'Acacia Gardens',
  // 'Agnes Banks',
  // 'Airds',
  // 'Alexandria',
  // 'Alfords Point',
  // 'Allambie Heights',
  // 'Allawah',
  // 'Ambarvale',
  // 'Annandale',
  // 'Annangrove',
  // 'Arcadia',
  // 'Arncliffe',
  // 'Arndell Park',
  // 'Artarmon',
  // 'Ashbury',
  // 'Ashcroft',
  // 'Ashfield',
  // 'Asquith',
  // 'Auburn',
  // 'Austral',
  // 'Avalon Beach',
  // 'Chippendale',
  // 'Chipping Norton',
  // 'Chiswick',
  // 'Chullora',
  // 'Kingswood',
  // 'Kingswood Park',
  // 'Kirkham',
  // 'Kirrawee',
  // 'Kirribilli',
  // 'Kogarah',
  // 'Kogarah Bay',
  // 'Kuringgai Chase',
  // 'Kurnell',
  // 'Kurraba Point',
  // 'Kyeemagh',
  // 'Kyle Bay'
];
