<div class="flex flex-col gap-2 p-4">
  <h3 class="!m-0 !font-bold">HubSpot</h3>

  <p class="!m-0">
    A Hub Id is a series of numbers, usually 8 characters long. To find your Hub
    Id follow the
    <a
      href="https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code#copy-your-hubspot-tracking-code"
      target="_blank"
      >Official Documentation</a
    >
    or the steps below:
  </p>

  <ol class="list-inside list-decimal">
    <li>
      In
      <a href="https://app.hubspot.com/settings/" target="_blank">HubSpot</a>,
      open your settings from the gear icon in the top toolbar.
    </li>
    <li>
      Navigate to <strong>Tracking Code</strong> from the sidemenu found under
      "Account management > Tracking & Analytics"
    </li>
    <li>
      Near the bottom of this page, you will find your tracking code here under
      <strong>WordPress installation</strong>. It will be formatted as
      <code
        class="rounded-lg bg-slate-100 px-2 py-1 font-mono font-bold text-slate-500"
        >XXXXXXXX</code
      >
    </li>
  </ol>

  <div>
    <img
      src="/assets/help-images/tracking-codes/hubspot-tracking-code.png"
      alt="Hubspot - Tracking Code"
    />
  </div>
</div>
