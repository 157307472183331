import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pr-health-fund-card-preview-item',
  templateUrl: './health-fund-card-preview-item.component.html',
  styleUrls: ['./health-fund-card-preview-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'block min-w-[100px]' },
})
export class HealthFundCardPreviewItemComponent {
  @Input() label: string;
}
