import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { PipesModule, UserIconComponent } from '@principle-theorem/ng-shared';
import { IStaffer } from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  filterUndefined,
  isSameRef,
  snapshot,
} from '@principle-theorem/shared';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { OrganisationService } from '../../organisation.service';
import { GlobalStoreService } from '../../store/global-store.service';

@Component({
  selector: 'pr-multi-practitioner-select-button',
  imports: [NgMaterialModule, UserIconComponent, PipesModule],
  templateUrl: './multi-practitioner-select-button.component.html',
  styleUrl: './multi-practitioner-select-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiPractitionerSelectButtonComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  practitioners$: Observable<WithRef<IStaffer>[]>;
  selectedPractitioners$ = new BehaviorSubject<WithRef<IStaffer>[]>([]);
  preselectedPractitioners$ = new BehaviorSubject<
    WithRef<IStaffer>[] | undefined
  >(undefined);
  allSelected$: Observable<boolean>;

  @Input() tooltipText = 'Select Practitioners';
  @Input()
  set preselectedPractitioners(practitioners: WithRef<IStaffer>[] | undefined) {
    if (practitioners) {
      this.preselectedPractitioners$.next(practitioners);
    }
  }

  @Output() selectionChange = new EventEmitter<WithRef<IStaffer>[]>();

  constructor(
    private _organisation: OrganisationService,
    private _globalStore: GlobalStoreService
  ) {
    this.practitioners$ = this._organisation.practicePractitioners$;

    this.selectedPractitioners$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((practitioners) => this.selectionChange.emit(practitioners));

    this.allSelected$ = combineLatest([
      this.selectedPractitioners$,
      this.practitioners$,
    ]).pipe(
      map(
        ([selected, allPractitioners]) =>
          selected.length === allPractitioners.length
      )
    );

    this.preselectedPractitioners$
      .pipe(filterUndefined(), take(1), takeUntil(this._onDestroy$))
      .subscribe((practitioners) =>
        this.selectedPractitioners$.next(practitioners)
      );

    this.practitioners$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((practitioners) => {
        this.selectionChange.emit(practitioners);
        if (!this.selectedPractitioners$.value.length) {
          this.selectedPractitioners$.next(practitioners);
        }
      });
  }

  getPractitionerImage$(
    practitioner: WithRef<IStaffer>
  ): Observable<string | undefined> {
    return this._globalStore.getStafferImage$(practitioner);
  }

  practitionerSelected$(practitioner: WithRef<IStaffer>): Observable<boolean> {
    return this.selectedPractitioners$.pipe(
      map((selected) =>
        selected.some((selectedPractitioner) =>
          isSameRef(selectedPractitioner, practitioner)
        )
      )
    );
  }

  togglePractitioner(practitioner: WithRef<IStaffer>): void {
    const selected = this.selectedPractitioners$.value;
    const isSelected = selected.find((selectedPractitioner) =>
      isSameRef(selectedPractitioner, practitioner)
    );
    const updatedSelection = isSelected
      ? selected.filter(
          (selectedPractitioner) =>
            !isSameRef(selectedPractitioner, practitioner)
        )
      : [...selected, practitioner];

    this.selectedPractitioners$.next(updatedSelection);
  }

  async toggleSelectAll(): Promise<void> {
    const allSelected = await snapshot(this.allSelected$);
    this.selectedPractitioners$.next(
      allSelected ? [] : await snapshot(this.practitioners$)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
