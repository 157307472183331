<mat-toolbar color="accent">Select Automation Configuration</mat-toolbar>

<mat-dialog-content>
  <ng-container *ngIf="hasNoAutomations; else automations">
    <pr-empty-state image="list" title="automations" />
  </ng-container>

  <ng-template #automations>
    <ng-container *ngIf="data.notificationConfigs as notifications">
      <ng-container *ngIf="notifications.length">
        <h3>Appointment Notifications</h3>

        <div>
          <pr-automated-notification-configuration-list-item
            *ngFor="
              let notification of notifications;
              trackBy: trackByNotification
            "
            [notification]="notification"
          >
            <div fxLayout="row" fxLayoutGap="8px">
              <button
                mat-flat-button
                color="primary"
                (click)="selectNotification(notification)"
              >
                Use Configuration
              </button>
            </div>
          </pr-automated-notification-configuration-list-item>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="data.taskConfigs as tasks">
      <ng-container *ngIf="tasks.length">
        <h3>Appointment Tasks</h3>

        <div>
          <pr-generated-task-configuration-list-item
            *ngFor="let task of tasks; trackBy: trackByTask"
            [task]="task"
          >
            <div fxLayout="row" fxLayoutAlign="end center">
              <button
                mat-flat-button
                color="primary"
                (click)="selectTask(task)"
              >
                Use Configuration
              </button>
            </div>
          </pr-generated-task-configuration-list-item>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
  </pt-buttons-container>
</mat-dialog-actions>
