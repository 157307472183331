export interface IResponseCodeInfo {
  code: string;
  receiptPrintOut: string;
  description: string;
  nextStep: string;
}

export const CLAIM_LEVEL_HICAPS_CONNECT_RESPONSE_SUCCESS_CODES: string[] = [
  '00',
  '76',
  '08', // APPROVED WITH SIGNATURE
  '09',
];

export const CLAIM_LEVEL_HICAPS_CONNECT_RESPONSE_CODE_MAP: Record<
  string,
  IResponseCodeInfo
> = {
  '10': {
    code: '10',
    receiptPrintOut: 'Rebate to MBR Pendg',
    description: 'Rebate to member pending',
    nextStep: 'Patient to follow up with fund',
  },
  '12': {
    code: '12',
    receiptPrintOut: 'Declined (8)',
    description: 'Declined',
    nextStep: 'Patient to follow up with fun',
  },
  '14': {
    code: '14',
    receiptPrintOut: 'Invalid Card Nbr',
    description: 'Invalid card number (no such number)',
    nextStep:
      'Seek alternate method of payment - card holder to follow up with fund',
  },
  '19': {
    code: '19',
    receiptPrintOut: 'No Items Entered',
    description: 'No items entered',
    nextStep: 'Retry claim',
  },
  '21': {
    code: '21',
    receiptPrintOut: 'Call Help Desk - note 1',
    description: 'No action taken',
    nextStep: 'Practitioner to call help desk',
  },
  '40': {
    code: '40',
    receiptPrintOut: 'Mship Not Covered',
    description: `Membership doesn't cover service`,
    nextStep:
      'Seek alternate method of payment - card holder to follow up with fund',
  },
  '42': {
    code: '42',
    receiptPrintOut: 'Mship Ended at DOS',
    description: 'Membership ceased/suspended at date of service',
    nextStep:
      'Seek alternate method of payment - card holder to follow up with fund',
  },
  '54': {
    code: '54',
    receiptPrintOut: 'Expired card',
    description: 'Expired card',
    nextStep:
      'Seek alternate method of payment - card holder to follow up with fund',
  },
  '56': {
    code: '56',
    receiptPrintOut: 'Invalid Mship Nbf',
    description: 'Invalid membership numbers',
    nextStep:
      'Seek alternate method of payment - card holder to follow up with fund',
  },
  '58': {
    code: '58',
    receiptPrintOut: 'Txn Not Permited',
    description: 'Transaction not permitted on terminal',
    nextStep: 'Practitioner to call help desk',
  },
  '60': {
    code: '60',
    receiptPrintOut: 'Provider Not Recogd',
    description: 'Provider not recognised with fund',
    nextStep: 'Practitioner to call help desk',
  },
  '61': {
    code: '61',
    receiptPrintOut: 'Benefit Limit Excee',
    description: 'Benefit limit exceeded',
    nextStep:
      'Seek alternate method of payment - card holder to follow up with fund',
  },
  '69': {
    code: '69',
    receiptPrintOut: 'No benefit payable',
    description: 'No Benefit payable',
    nextStep:
      'Seek alternate method of payment - card holder to follow up with fund',
  },
  '76': {
    code: '76',
    receiptPrintOut: 'Approved',
    description: 'Approved (terminal will request new key)',
    nextStep: 'No further action required',
  },
  '80': {
    code: '80',
    receiptPrintOut: 'Refer Employer',
    description: `Payment should be made by Claimant's Employer`,
    nextStep: 'Card holder to follow up directly with employer',
  },
  '81': {
    code: '81',
    receiptPrintOut: 'Unmatched Cancel',
    description: 'Unable to match cancel to previous claim',
    nextStep: 'Practitioner to call help desk',
  },
  '82': {
    code: '82',
    receiptPrintOut: 'Unsupported Cancel',
    description: 'Claim being cancelled has already been settled',
    nextStep: 'Follow up with fund next business day',
  },
  '83': {
    code: '83',
    receiptPrintOut: 'Too Many patients',
    description: 'Too many patients for this transaction',
    nextStep: 'Retry claim reducing the number of patients',
  },
  '91': {
    code: '91',
    receiptPrintOut: 'System Inoperative',
    description: 'Health fund system currently unavailable',
    nextStep: 'Retry claim, if still failing seek alternate method of payment',
  },
  '94': {
    code: '94',
    receiptPrintOut: 'Duplicate Tax',
    description: 'Duplicate transmission',
    nextStep: 'Practitioner to call help desk',
  },
  '96': {
    code: '96',
    receiptPrintOut: 'System Malfunction',
    description: 'System malfunction',
    nextStep: 'Practitioner to call help desk',
  },
  '97': {
    code: '97',
    receiptPrintOut: 'Reconc Totals Reset',
    description: 'Reconciliation totals have been reset',
    nextStep: 'No further action required',
  },
  '98': {
    code: '98',
    receiptPrintOut: 'MAC ERROR',
    description: 'Mac error',
    nextStep: 'Practitioner to call help desk',
  },
  '00': {
    code: '00',
    receiptPrintOut: 'Approved(8)',
    description: 'Approved or completed succesfully',
    nextStep: 'No further action required',
  },
  '01': {
    code: '01',
    receiptPrintOut: 'Prov Not Approv (15)',
    description: 'Provider not approved by fund',
    nextStep: 'Practitioner to call help desk',
  },
  '02': {
    code: '02',
    receiptPrintOut: 'Mship Under Invest (19)',
    description: 'Membership under investigation',
    nextStep:
      'Seek alternate method of payment - card holder to follow up with fund',
  },
  '03': {
    code: '03',
    receiptPrintOut: 'Invalid Prov Nbr (16)',
    description: 'Invalid provider number',
    nextStep: 'Practitioner to call help desk',
  },
  '04': {
    code: '04',
    receiptPrintOut: 'Retain Card (11)',
    description:
      'Retain card. Seek alternate method of payment - card holder to follow up with fund',
    nextStep:
      'Seek alternate method of payment - card holder to follow up with fund',
  },
  '09': {
    code: '09',
    receiptPrintOut: 'Submitted',
    description: 'Transactiom submitted from processing',
    nextStep:
      'Seek alternate method of payment - card holder to follow up with fund',
  },
  'P0 - PZ': {
    code: 'P0 - PZ',
    receiptPrintOut: 'Refer Health Fund',
    description: 'note 2',
    nextStep: 'Refer to health fund',
  },
  H1: {
    code: 'H1',
    receiptPrintOut: '',
    description: 'Time out waiting for ACK from terminal',
    nextStep: 'Practitioner to call help desk',
  },
  H2: {
    code: 'H2',
    receiptPrintOut: '',
    description: 'Maximum number of ACK time outs reached',
    nextStep: 'Practitioner to call help desk',
  },
  H3: {
    code: 'H3',
    receiptPrintOut: '',
    description: 'Timed Out waiting form response message from terminal.',
    nextStep: 'Practitioner to call help desk',
  },
  H4: {
    code: 'H4',
    receiptPrintOut: '',
    description: 'LRC Error in response message',
    nextStep: 'Practitioner to call help desk',
  },
  H5: {
    code: 'H5',
    receiptPrintOut: '',
    description: 'Unable to send, terminal not connected',
    nextStep: 'Practitioner to call help desk',
  },
  H6: {
    code: 'H6',
    receiptPrintOut: '',
    description: 'Terminal busy',
    nextStep: 'Retry claim, if still failing, call help desk',
  },
  HX: {
    code: 'HX',
    receiptPrintOut: '',
    description: 'ActiveX internal error',
    nextStep: 'Practitioner to call help desk',
  },
  HM: {
    code: 'HM',
    receiptPrintOut: '',
    description: 'Hardware error',
    nextStep: 'Practitioner to call help desk',
  },
  HW: {
    code: 'HW',
    receiptPrintOut: '',
    description: 'Hardware error',
    nextStep: 'Practitioner to call help desk',
  },
  LC: {
    code: 'LC',
    receiptPrintOut: '',
    description: 'Unexpected loss of carrier',
    nextStep: 'Retry claim, if still failing, call help desk',
  },
  LR: {
    code: 'LR',
    receiptPrintOut: '',
    description: 'Terminal logon required',
    nextStep: 'Signon to terminal (Func 5 enter, NAB, enter)',
  },
  LP: {
    code: 'LP',
    receiptPrintOut: '',
    description: 'Locked provider',
    nextStep: 'Practitioner to call help desk',
  },
  IL: {
    code: 'IL',
    receiptPrintOut: '',
    description: 'Number of items exceeded limit',
    nextStep: 'Retry claim reducing the number of items',
  },
  TP: {
    code: 'TP',
    receiptPrintOut: '',
    description: 'The transaction password is protected',
    nextStep: 'Practitioner to call help desk',
  },
  II: {
    code: 'II',
    receiptPrintOut: '',
    description: 'Invalid max item setup',
    nextStep: 'Practitioner to call help desk',
  },
  FL: {
    code: 'FL',
    receiptPrintOut: '',
    description:
      'An (empty) claim initiated from Practice Management System.(i.e. only provider in the EA field)',
    nextStep: 'Retry claim ensuring all required fields are entered',
  },
  FC: {
    code: 'FC',
    receiptPrintOut: '',
    description: `Field content error - (a) check non-numeric fields such as patient ID, body part number, service date and amount for non-numeric data. e.g. anything other than accept '0' to '9' (b) alpha numeric field such as item number contains symbols. e.g. anything other than 'A' to 'Z', 'a' to 'z', '0' to '9' and spaces)`,
    nextStep: 'Practitioner to call help desk',
  },
};

export const ITEM_LEVEL_HICAPS_CONNECT_RESPONSE_CODE_MAP: Record<
  string,
  IResponseCodeInfo
> = {
  '00': {
    code: '00',
    receiptPrintOut: 'Approved (8)',
    description: 'Approved or completed successfully',
    nextStep: 'Patient to follow up with fund',
  },
  '01': {
    code: '01',
    receiptPrintOut: 'Prov Not Approv (15)',
    description: 'Provider not approved by fund',
    nextStep: 'Patient to follow up with fund',
  },
  '02': {
    code: '02',
    receiptPrintOut: 'Mship Under Invest (19)',
    description: 'Membership under investigation',
    nextStep: 'Patient to follow up with fund',
  },
  '03': {
    code: '03',
    receiptPrintOut: 'Invalid Prov Nbr (16)',
    description: 'Invalid provider number',
    nextStep: 'Patient to follow up with fund',
  },
  '04': {
    code: '04',
    receiptPrintOut: 'Previously paid',
    description: 'Nil benefit - benefit previously paid',
    nextStep: 'Patient to follow up with fund',
  },
  '05': {
    code: '05',
    receiptPrintOut: 'Limit reached',
    description: 'Nil benefit - limit reached',
    nextStep: 'Patient to follow up with fund',
  },
  '06': {
    code: '06',
    receiptPrintOut: 'Limit reached',
    description: 'Reduced benefit - limit reached',
    nextStep: 'Patient to follow up with fund',
  },
  '07': {
    code: '07',
    receiptPrintOut: 'Waiting Period',
    description: 'Nil benefit - qualifying period applies',
    nextStep: 'Patient to follow up with fund',
  },
  '08': {
    code: '08',
    receiptPrintOut: 'PreReq Ser Reqd',
    description: 'Nil benefit - pre-requisite service required',
    nextStep: 'Provider to follow up with fund',
  },
  '09': {
    code: '09',
    receiptPrintOut: 'Pre Existing',
    description: 'Nil benefit - pre-existing ailment',
    nextStep: 'Patient to follow up with fund',
  },
  '10': {
    code: '10',
    receiptPrintOut: 'Item Nbr Invalid',
    description: 'Item number invalid',
    nextStep: `You haven't entered a correct number. Please check the HICAPS item guide`,
  },
  '11': {
    code: '11',
    receiptPrintOut: 'Item Nbr Ceased',
    description: 'Item number ceased',
    nextStep: 'Provider to check item guide',
  },
  '12': {
    code: '12',
    receiptPrintOut: 'Item Not Valid',
    description: 'Item not valid for provider',
    nextStep: 'Provider to check item guide',
  },
  '13': {
    code: '13',
    receiptPrintOut: 'Item Not Apprvd',
    description: 'Item not approved - not payable by HICAPS',
    nextStep: 'Patient to follow up with fund',
  },
  '14': {
    code: '14',
    receiptPrintOut: 'Max Nbr Ser Exc',
    description: 'Service exceeds number of times permitted',
    nextStep: 'Patient to follow up with fund',
  },
  '15': {
    code: '15',
    receiptPrintOut: 'Cost Missing',
    description: 'Cost must be entered',
    nextStep: 'Value needs to be entered in 0.05c increments',
  },
  '16': {
    code: '16',
    receiptPrintOut: 'DOS Not Valid',
    description: 'Date of service must be before today',
    nextStep: 'Provider to follow up with Help Desk',
  },
  '17': {
    code: '17',
    receiptPrintOut: 'DOS too Old',
    description: 'Date of service too old',
    nextStep: 'Patient to claim directly with fund',
  },
  '18': {
    code: '18',
    receiptPrintOut: 'Clincl Code Rqd',
    description: 'Clinical code required',
    nextStep: 'Provider to check item guide',
  },
  '19': {
    code: '19',
    receiptPrintOut: 'Pat Not Covered',
    description: 'Patient not covered for this type of service',
    nextStep: 'Patient to follow up with fund',
  },
  '20': {
    code: '20',
    receiptPrintOut: 'Depd Status End',
    description: 'Dependent status ceased',
    nextStep: 'Patient to follow up with fund',
  },
  '21': {
    code: '21',
    receiptPrintOut: 'Invalid PAT Code',
    description: 'Invalid patient code',
    nextStep: 'Provider to check patient details on card',
  },
  '22': {
    code: '22',
    receiptPrintOut: 'Mship Not Covere',
    description: 'The member is not covered for the service',
    nextStep: 'Patient to follow up with fund',
  },
  '23': {
    code: '23',
    receiptPrintOut: 'Mship Ended DOS',
    description: 'Membership has ceased or is suspended at the date of service',
    nextStep: 'Patient to follow up with fund',
  },
  '24': {
    code: '24',
    receiptPrintOut: 'Mship Unfin DOS',
    description: 'Membership not current at date of service',
    nextStep: 'Patient to follow up with fund',
  },
  '25': {
    code: '25',
    receiptPrintOut: 'No Ancill Cover',
    description: 'The patient has no ancillary cover',
    nextStep: 'Patient to follow up with fund',
  },
  '26': {
    code: '26',
    receiptPrintOut: 'Item Not Coverd',
    description: 'The patient is not covered for this service',
    nextStep: 'Patient to follow up with fund',
  },
  '27': {
    code: '27',
    receiptPrintOut: 'Duplicate Serv',
    description: 'Duplicate service already processed',
    nextStep: 'Patient to follow up with fund',
  },
  '28': {
    code: '28',
    receiptPrintOut: 'Excess on Serv',
    description: 'Nil benefit - excess on service applied',
    nextStep: 'Patient to follow up with fund',
  },
  '29': {
    code: '29',
    receiptPrintOut: 'Excess on Serv',
    description: 'Reduced benefit - excess on service applied',
    nextStep: 'Patient to follow up with fund',
  },
  '30': {
    code: '30',
    receiptPrintOut: 'Quote Required',
    description: 'Nil benefit - excess on service applied',
    nextStep: 'Patient to follow up with fund',
  },
  '31': {
    code: '31',
    receiptPrintOut: 'Quote Required',
    description: 'Reduced benefit - quote required prior to service',
    nextStep: 'Patient to follow up with fund',
  },
  '32': {
    code: '32',
    receiptPrintOut: 'Exceeds Quote',
    description: 'Nil benefit - claim exceeds quote',
    nextStep: 'Patient to follow up with fund',
  },
  '33': {
    code: '33',
    receiptPrintOut: 'Exceeds Quote',
    description: 'Reduced benefit - claim exceeds quote',
    nextStep: 'Patient to follow up with fund',
  },
  '34': {
    code: '34',
    receiptPrintOut: 'Age Restriction',
    description: 'Nil benefit - age restriction applies',
    nextStep: 'Patient to follow up with fund',
  },
  '35': {
    code: '35',
    receiptPrintOut: 'Age Restriction',
    description: 'Reduced benefit - age restriction applies',
    nextStep: 'Patient to follow up with fund',
  },
  '36': {
    code: '36',
    receiptPrintOut: 'Gender Restrict',
    description: 'Nil benefit - gender restriction applies',
    nextStep: 'Patient to follow up with fund',
  },
  '37': {
    code: '37',
    receiptPrintOut: 'Gender Restrict',
    description: 'Reduced benefit - gender restriction applies',
    nextStep: 'Patient to follow up with fund',
  },
  '38': {
    code: '38',
    receiptPrintOut: 'Invalid Charge',
    description: 'Nil benefit - amount charged is invalid',
    nextStep: 'Provider to follow up with fund',
  },
  '39': {
    code: '39',
    receiptPrintOut: 'Notional Charge',
    description: 'Notional charge',
    nextStep: '',
  },
  '40': {
    code: '40',
    receiptPrintOut: 'Converted Item',
    description: 'Converted item',
    nextStep: '',
  },
  '41': {
    code: '41',
    receiptPrintOut: 'Refer to Health Fund',
    description: 'Refer to fund',
    nextStep: 'Patient to follow up with fund',
  },
  '42': {
    code: '42',
    receiptPrintOut: 'System Problem',
    description: 'System problem',
    nextStep: 'Provider to follow up with Help Desk',
  },
  '43': {
    code: '43',
    receiptPrintOut: 'Tran Declined',
    description: 'Transaction declined - refer tran to resp code',
    nextStep: 'Refer to transaction response code',
  },
  '50': {
    code: '50',
    receiptPrintOut: 'Prov Not Appro',
    description: 'Provider not approved by fund (at date of service)',
    nextStep: 'Provider to follow up with Help Desk',
  },
  '51': {
    code: '51',
    receiptPrintOut: 'Prov Not Appro',
    description: 'Provider not approved by fund for this service at DOS',
    nextStep: 'Provider to follow up with Help Desk',
  },
  '52': {
    code: '52',
    receiptPrintOut: 'Tooth id Reqd',
    description: 'Tooth ID number required',
    nextStep: 'Provider to check item guide',
  },
  '53': {
    code: '53',
    receiptPrintOut: 'DOS before Injury',
    description: 'Service date before injury date',
    nextStep: 'Patient to follow up with fund',
  },
  '54': {
    code: '54',
    receiptPrintOut: 'Submitted',
    description: 'Transaction submitted for processing',
    nextStep: 'No Further action required',
  },
  'Po-PZ': {
    code: 'Po-PZ',
    receiptPrintOut: 'Refer to Fund',
    description: 'Refer health fund - Not 1',
    nextStep: 'Patient to follow up with fund',
  },
};

export const EFTPOS_HICAPS_CONNECT_RESPONSE_CODE_MAP: Record<
  string,
  IResponseCodeInfo
> = {
  '10': {
    code: '10',
    receiptPrintOut: 'Item Nbr Invalid',
    description: 'Item number invalid',
    nextStep: `You haven't entered a correct number. Please check the HICAPS item guide`,
  },
  '11': {
    code: '11',
    receiptPrintOut: 'Item Nbr Ceased',
    description: 'Item number ceased',
    nextStep: 'Provider to check item guide',
  },
  '12': {
    code: '12',
    receiptPrintOut: 'Item Not Valid',
    description: 'Item not valid for provider',
    nextStep: 'Provider to check item guide',
  },
  '13': {
    code: '13',
    receiptPrintOut: 'Item Not Apprvd',
    description: 'Item not approved - not payable by HICAPS',
    nextStep: 'Patient to follow up with fund',
  },
  '14': {
    code: '14',
    receiptPrintOut: 'Max Nbr Ser Exc',
    description: 'Service exceeds number of times permitted',
    nextStep: 'Patient to follow up with fund',
  },
  '15': {
    code: '15',
    receiptPrintOut: 'Cost Missing',
    description: 'Cost must be entered',
    nextStep: 'Value needs to be entered in 0.05c increments',
  },
  '16': {
    code: '16',
    receiptPrintOut: 'DOS Not Valid',
    description: 'Date of service must be before today',
    nextStep: 'Provider to follow up with Help Desk',
  },
  '17': {
    code: '17',
    receiptPrintOut: 'DOS too Old',
    description: 'Date of service too old',
    nextStep: 'Patient to claim directly with fund',
  },
  '18': {
    code: '18',
    receiptPrintOut: 'Clincl Code Rqd',
    description: 'Clinical code required',
    nextStep: 'Provider to check item guide',
  },
  '19': {
    code: '19',
    receiptPrintOut: 'Pat Not Covered',
    description: 'Patient not covered for this type of service',
    nextStep: 'Patient to follow up with fund',
  },
  '20': {
    code: '20',
    receiptPrintOut: 'Depd Status End',
    description: 'Dependent status ceased',
    nextStep: 'Patient to follow up with fund',
  },
  '21': {
    code: '21',
    receiptPrintOut: 'Invalid PAT Code',
    description: 'Invalid patient code',
    nextStep: 'Provider to check patient details on card',
  },
  '22': {
    code: '22',
    receiptPrintOut: 'Mship Not Covere',
    description: 'The member is not covered for the service',
    nextStep: 'Patient to follow up with fund',
  },
  '23': {
    code: '23',
    receiptPrintOut: 'Mship Ended DOS',
    description: 'Membership has ceased or is suspended at the date of service',
    nextStep: 'Patient to follow up with fund',
  },
  '24': {
    code: '24',
    receiptPrintOut: 'Mship Unfin DOS',
    description: 'Membership not current at date of service',
    nextStep: 'Patient to follow up with fund',
  },
  '25': {
    code: '25',
    receiptPrintOut: 'No Ancill Cover',
    description: 'The patient has no ancillary cover',
    nextStep: 'Patient to follow up with fund',
  },
  '26': {
    code: '26',
    receiptPrintOut: 'Item Not Coverd',
    description: 'The patient is not covered for this service',
    nextStep: 'Patient to follow up with fund',
  },
  '27': {
    code: '27',
    receiptPrintOut: 'Duplicate Serv',
    description: 'Duplicate service already processed',
    nextStep: 'Patient to follow up with fund',
  },
  '28': {
    code: '28',
    receiptPrintOut: 'Excess on Serv',
    description: 'Nil benefit - excess on service applied',
    nextStep: 'Patient to follow up with fund',
  },
  '29': {
    code: '29',
    receiptPrintOut: 'Excess on Serv',
    description: 'Reduced benefit - excess on service applied',
    nextStep: 'Patient to follow up with fund',
  },
  '30': {
    code: '30',
    receiptPrintOut: 'Quote Required',
    description: 'Nil benefit - excess on service applied',
    nextStep: 'Patient to follow up with fund',
  },
  '31': {
    code: '31',
    receiptPrintOut: 'Quote Required',
    description: 'Reduced benefit - quote required prior to service',
    nextStep: 'Patient to follow up with fund',
  },
  '32': {
    code: '32',
    receiptPrintOut: 'Exceeds Quote',
    description: 'Nil benefit - claim exceeds quote',
    nextStep: 'Patient to follow up with fund',
  },
  '33': {
    code: '33',
    receiptPrintOut: 'Exceeds Quote',
    description: 'Reduced benefit - claim exceeds quote',
    nextStep: 'Patient to follow up with fund',
  },
  '34': {
    code: '34',
    receiptPrintOut: 'Age Restriction',
    description: 'Nil benefit - age restriction applies',
    nextStep: 'Patient to follow up with fund',
  },
  '35': {
    code: '35',
    receiptPrintOut: 'Age Restriction',
    description: 'Reduced benefit - age restriction applies',
    nextStep: 'Patient to follow up with fund',
  },
  '36': {
    code: '36',
    receiptPrintOut: 'Gender Restrict',
    description: 'Nil benefit - gender restriction applies',
    nextStep: 'Patient to follow up with fund',
  },
  '37': {
    code: '37',
    receiptPrintOut: 'Gender Restrict',
    description: 'Reduced benefit - gender restriction applies',
    nextStep: 'Patient to follow up with fund',
  },
  '38': {
    code: '38',
    receiptPrintOut: 'Invalid Charge',
    description: 'Nil benefit - amount charged is invalid',
    nextStep: 'Provider to follow up with fund',
  },
  '39': {
    code: '39',
    receiptPrintOut: 'Notional Charge',
    description: 'Notional charge',
    nextStep: '',
  },
  '40': {
    code: '40',
    receiptPrintOut: 'Converted Item',
    description: 'Converted item',
    nextStep: '',
  },
  '41': {
    code: '41',
    receiptPrintOut: 'Refer to Health Fund',
    description: 'Refer to fund',
    nextStep: 'Patient to follow up with fund',
  },
  '42': {
    code: '42',
    receiptPrintOut: 'System Problem',
    description: 'System problem',
    nextStep: 'Provider to follow up with Help Desk',
  },
  '43': {
    code: '43',
    receiptPrintOut: 'Tran Declined',
    description: 'Transaction declined - refer tran to resp code',
    nextStep: 'Refer to transaction response code',
  },
  '44': {
    code: '44',
    receiptPrintOut: 'Medical Certificate',
    description: 'Required Medical',
    nextStep: 'Crt Req',
  },
  '45': {
    code: '45',
    receiptPrintOut: 'Invalid Tooth ID',
    description: 'Invalid Tooth ID',
    nextStep: '',
  },
  '50': {
    code: '50',
    receiptPrintOut: 'Prov Not Appro',
    description: 'Provider not approved by fund (at date of service)',
    nextStep: 'Provider to follow up with Help Desk',
  },
  '51': {
    code: '51',
    receiptPrintOut: 'Prov Not Appro',
    description: 'Provider not approved by fund for this service at DOS',
    nextStep: 'Provider to follow up with Help Desk',
  },
  '52': {
    code: '52',
    receiptPrintOut: 'Tooth id Reqd',
    description: 'Tooth ID number required',
    nextStep: 'Provider to check item guide',
  },
  '53': {
    code: '53',
    receiptPrintOut: 'DOS before Injury',
    description: 'Service date before injury date',
    nextStep: 'Patient to follow up with fund',
  },
  '54': {
    code: '54',
    receiptPrintOut: 'Submitted',
    description: 'Transaction submitted for processing',
    nextStep: 'No Further action required',
  },
  '55': {
    code: '55',
    receiptPrintOut: 'ICD 10 Required ',
    description: 'ICD 10 Required ',
    nextStep: '',
  },
  '56': {
    code: '56',
    receiptPrintOut: 'Charge Exceeds Agreed Maximum',
    description: 'Chg Over Agreed',
    nextStep: '',
  },
  '57': {
    code: '57',
    receiptPrintOut: 'Approved -item',
    description: 'requested amount has been adjusted',
    nextStep: 'Approved-Amt Adj',
  },
  '58': {
    code: '58',
    receiptPrintOut: 'Non Telehealth Item Number Used',
    description: 'Non TeleH Item',
    nextStep: '',
  },
  '00': {
    code: '00',
    receiptPrintOut: 'Approved (8)',
    description: 'Approved or completed successfully',
    nextStep: 'Patient to follow up with fund',
  },
  '01': {
    code: '01',
    receiptPrintOut: 'Prov Not Approv (15)',
    description: 'Provider not approved by fund',
    nextStep: 'Patient to follow up with fund',
  },
  '02': {
    code: '02',
    receiptPrintOut: 'Mship Under Invest (19)',
    description: 'Membership under investigation',
    nextStep: 'Patient to follow up with fund',
  },
  '03': {
    code: '03',
    receiptPrintOut:
      'Invalid Prov Nbr (16)Invalid provider numberPatient to follow up with fund',
    description: 'Invalid provider number',
    nextStep: 'Patient to follow up with fund',
  },
  '04': {
    code: '04',
    receiptPrintOut: 'Previously paid',
    description: 'Nil benefit - benefit previously paid',
    nextStep: 'Patient to follow up with fund',
  },
  '05': {
    code: '05',
    receiptPrintOut: 'Limit reached',
    description: 'Nil benefit - limit reached',
    nextStep: 'Patient to follow up with fund',
  },
  '06': {
    code: '06',
    receiptPrintOut: 'Limit reached',
    description: 'Reduced benefit - limit reached',
    nextStep: 'Patient to follow up with fund',
  },
  '07': {
    code: '07',
    receiptPrintOut: 'Waiting Period',
    description: 'Nil benefit - qualifying period applies',
    nextStep: 'Patient to follow up with fund',
  },
  '08': {
    code: '08',
    receiptPrintOut: 'PreReq Ser Reqd',
    description: 'Nil benefit - pre-requisite service required',
    nextStep: 'Provider to follow up with fund',
  },
  '09': {
    code: '09',
    receiptPrintOut: 'Pre Existing',
    description: 'Nil benefit - pre-existing ailment',
    nextStep: 'Patient to follow up with fund',
  },
  'Po-PZ': {
    code: 'Po-PZ',
    receiptPrintOut: 'Refer to Fund',
    description: 'Refer health fund - Not 1',
    nextStep: 'Patient to follow up with fund',
  },
};

export const HICAPS_CONNECT_ADDITIONAL_ITEM_CODE_DESCRIPTIONS: Record<
  string,
  string
> = {
  X999: 'NIB Extras Voucher',
  X998: 'Connect Rewards',
  X997: 'CUAHealth Bonus',
  X996: 'Package Bonus',
  X995: 'GapSaver',
  X994: 'Member Bonus',
  X993: 'Active Bonus',
  X992: 'Limit Boost',
  X991: 'BupaTop-UpBonus',
  X990: 'Top-Up',
  X989: 'Benefit Bonus',
  X900: 'ITEMS AMT ADJUSTED*',
};
