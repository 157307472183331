import { type ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { isNumber } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'pr-account-card',
    templateUrl: './account-card.component.html',
    styleUrls: ['./account-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AccountCardComponent {
  amount$ = new BehaviorSubject<number>(0);
  @Input() name: string;
  @Input() isLoading: boolean;
  @Input() disableTooltip: boolean = false;

  @Input()
  set amount(amount: number) {
    if (!isNumber(amount) || isNaN(amount)) {
      return;
    }
    this.amount$.next(amount);
  }

  @Input()
  @HostBinding('class')
  colour: 'red' | 'green';

  @Input()
  @HostBinding('class.mini')
  mini: boolean = false;

  positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  isPositive(value: unknown): boolean {
    return isNumber(value) && value >= 0;
  }
}
