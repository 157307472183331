import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  type OnDestroy,
  type OnInit,
  ViewChild,
} from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { snapshot } from '@principle-theorem/shared';
import {
  type CreateTokenCardData,
  type StripeCardElement,
  type StripeError,
  type Token,
} from '@stripe/stripe-js';
import { merge, type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { StripeService } from '../stripe.service';
import { StripeCardEvents } from './card-events';

@Component({
  selector: 'pt-card-input',
  templateUrl: './card-input.html',
  styleUrls: ['./card-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CardInputComponent),
      multi: true,
    },
  ],
})
export class CardInputComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  private _onDestroy$: Subject<void> = new Subject();
  private _tokenError$: ReplaySubject<StripeError | undefined> =
    new ReplaySubject(1);
  errorMessage$: Observable<string | undefined>;
  token$: Subject<Token> = new Subject<Token>();
  card$: Subject<StripeCardElement> = new ReplaySubject<StripeCardElement>(1);
  events: StripeCardEvents;
  initError = false;
  @ViewChild('stripeContainer', { static: true })
  container: ElementRef<HTMLElement>;

  constructor(private _stripe: StripeService) {
    this.events = new StripeCardEvents(this.card$);
    this.errorMessage$ = merge(this._tokenError$, this.events.onError$).pipe(
      map((error) => this._getErrorMessage(error))
    );
  }

  async ngOnInit(): Promise<void> {
    const stripe = await this._stripe.stripe;
    if (!stripe) {
      this.initError = true;
      return;
    }
    const card = stripe.elements().create('card', {
      hidePostalCode: true,
    });
    card.mount(this.container.nativeElement);
    this.card$.next(card);
  }

  async ngOnDestroy(): Promise<void> {
    const card = await snapshot(this.card$);
    card.unmount();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  /**
   * A token is not something that persists anywhere so it would
   * never be available to call writeValue in the first place.
   */
  writeValue(_token: Token): void {
    // Do nothing
  }

  registerOnChange(callback: (value: Token) => unknown): void {
    this.token$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => callback(value));
  }

  registerOnTouched(callback: () => void): void {
    this.events.onBlur$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => callback());
  }

  async update(data: CreateTokenCardData = {}): Promise<void> {
    const stripe = await this._stripe.stripe;
    if (!stripe) {
      // eslint-disable-next-line no-console
      console.error('Unable to load stripe');
      return;
    }
    const card = await snapshot(this.card$);
    const { token, error } = await stripe.createToken(card, data);
    if (error) {
      this._tokenError$.next(error);
    }
    if (token) {
      this.token$.next(token);
    }
  }

  private _getErrorMessage(error?: StripeError): string | undefined {
    if (!error) {
      return;
    }
    return error.message || 'Invalid Payment Token';
  }
}
