import { inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { IConfirmAppointmentInfo } from '@principle-theorem/principle-core/interfaces';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  InvalidTokenError,
  PatientPortalUI,
} from '../../lib/patient-portal-ui';
import { PatientPortalFunctionsService } from '../../patient-portal-functions.service';

interface IPatientConfirmAppointmentState {
  loading: boolean;
  errorMessage?: string;
  tokenUid?: string;
  tokenData?: IConfirmAppointmentInfo;
}

const INITIAL_STATE: IPatientConfirmAppointmentState = {
  loading: false,
};

@Injectable()
export class PatientConfirmAppointmentStore extends ComponentStore<IPatientConfirmAppointmentState> {
  private _functions = inject(PatientPortalFunctionsService);
  loading = this.selectSignal((state) => state.loading);
  errorMessage = this.selectSignal((state) => state.errorMessage);
  tokenData = this.selectSignal((state) => state.tokenData);

  readonly loadTokenUid = this.effect(
    (tokenUid$: Observable<string | undefined>) =>
      tokenUid$.pipe(
        tap((tokenUid) => this.patchState({ tokenUid, loading: true })),
        switchMap((tokenUid) =>
          this._getPatientConfirmAppointmentInfo(tokenUid)
        ),
        tapResponse(
          (tokenData) => this.patchState({ tokenData, loading: false }),
          (e) =>
            this.patchState({
              errorMessage: PatientPortalUI.getErrorMessage(e),
              loading: false,
            })
        )
      )
  );

  constructor() {
    super(INITIAL_STATE);
  }

  private _getPatientConfirmAppointmentInfo(
    tokenUid?: string
  ): Promise<IConfirmAppointmentInfo> {
    if (!tokenUid) {
      throw new InvalidTokenError();
    }
    return this._functions.getPatientConfirmAppointmentInfo(tokenUid);
  }
}
