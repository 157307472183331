import { NgModule } from '@angular/core';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { LabJobCreateComponent } from './lab-job-create/lab-job-create.component';
import { LabJobEditComponent } from './lab-job-edit/lab-job-edit.component';
import { LabJobFormComponent } from './lab-job-form/lab-job-form.component';
import { LabJobInteractionsDialogComponent } from './lab-job-interactions-dialog/lab-job-interactions-dialog.component';
import { LabJobInteractionsComponent } from './lab-job-interactions/lab-job-interactions.component';
import { LabJobItemComponent } from './lab-job-list/lab-job-item/lab-job-item.component';
import { LabJobListDisplayComponent } from './lab-job-list/lab-job-list-display/lab-job-list-display.component';
import { LabJobListComponent } from './lab-job-list/lab-job-list.component';
import { LabJobStatusComponent } from './lab-job-status/lab-job-status.component';
import { LabJobStatusWorkflowComponent } from './lab-job-status-workflow/lab-job-status-workflow.component';

@NgModule({
  imports: [
    NgSharedModule,
    NgPrincipleSharedModule,
    NgInteractionsModule,
    NgMaterialModule,
  ],
  declarations: [
    LabJobCreateComponent,
    LabJobInteractionsComponent,
    LabJobInteractionsDialogComponent,
    LabJobListComponent,
    LabJobItemComponent,
    LabJobEditComponent,
    LabJobFormComponent,
    LabJobListDisplayComponent,
    LabJobStatusComponent,
    LabJobStatusWorkflowComponent,
  ],
  exports: [
    LabJobCreateComponent,
    LabJobInteractionsComponent,
    LabJobInteractionsDialogComponent,
    LabJobListComponent,
    LabJobItemComponent,
    LabJobEditComponent,
    LabJobFormComponent,
    LabJobListDisplayComponent,
    LabJobStatusComponent,
  ],
})
export class ComponentsModule {}
