import { type InteractionProxy } from '@interactjs/core/Interaction';
import { ActionName } from '@interactjs/core/scope';
import { type Modifier } from '@interactjs/modifiers/base';
import { type Offset } from '@interactjs/modifiers/snap/pointer';
import { type DraggableOptions, type Listeners } from '@interactjs/types';
import { type EdgeOptions } from '@interactjs/types/index';
import { type ITimelineDisplayOptions } from '@principle-theorem/principle-core/interfaces';
import interact from 'interactjs';
import { type BehaviorSubject } from 'rxjs';
import { InteractiveTimelineDisplayCalculator } from '../interactive-timeline-display-calculator';

export interface IDragStartPosition {
  left: number;
  top: number;
}

export function getSnapGridModifier(
  options: ITimelineDisplayOptions,
  dragArea: string | HTMLElement | SVGElement,
  dragStartPosition$: BehaviorSubject<IDragStartPosition | undefined>
): Modifier {
  const xGrid = InteractiveTimelineDisplayCalculator.isHorizontal(options)
    ? options.stepSizeInPixels
    : options.trackSizeInPixels;
  const yGrid = InteractiveTimelineDisplayCalculator.isHorizontal(options)
    ? options.trackSizeInPixels
    : options.stepSizeInPixels;

  return interact.modifiers.snap({
    targets: [
      (
        x: number,
        y: number,
        _interaction: InteractionProxy<ActionName>,
        _pageOffset: Offset,
        _index: number
      ) => {
        const scrollOffset =
          dragArea instanceof HTMLElement
            ? {
                x: dragArea.offsetParent?.scrollLeft ?? 0,
                y: dragArea.offsetParent?.scrollTop ?? 0,
              }
            : { x: 0, y: 0 };

        const limits = {
          left: -Infinity,
          right: Infinity,
          top: -Infinity,
          bottom: Infinity,
        };

        const xModulus = scrollOffset.x % xGrid;
        const yModulus = scrollOffset.y % yGrid;

        const inintialiScrollAmount = {
          x: dragStartPosition$.value?.left ?? 0,
          y: dragStartPosition$.value?.top ?? 0,
        };

        const gridIndex = {
          x: Math.round((x + xModulus - inintialiScrollAmount.x) / xGrid),
          y: Math.round((y + yModulus - inintialiScrollAmount.y) / yGrid),
        };
        const xPosition = Math.max(
          limits.left,
          Math.min(
            limits.right,
            gridIndex.x * xGrid - xModulus + inintialiScrollAmount.x
          )
        );
        const yPosition = Math.max(
          limits.top,
          Math.min(
            limits.bottom,
            gridIndex.y * yGrid - yModulus + inintialiScrollAmount.y
          )
        );
        return {
          grid: { x: xGrid, y: yGrid },
          x: xPosition,
          y: yPosition,
        };
      },
    ],
    range: Infinity,
    relativePoints: [{ x: 0, y: 0 }],
  });
}

export function getDragRestrictModifier(
  dragArea: string | HTMLElement | SVGElement
): Modifier {
  return interact.modifiers.restrict({
    restriction: dragArea,
    elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
  });
}

export function getResizeRestrictModifier(
  dragArea: string | HTMLElement | SVGElement
): Modifier {
  return interact.modifiers.restrictEdges({
    outer: dragArea,
  });
}

export function getMinSizeModifier(options: ITimelineDisplayOptions): Modifier {
  const xGrid = InteractiveTimelineDisplayCalculator.isHorizontal(options)
    ? options.stepSizeInPixels
    : options.trackSizeInPixels;
  const yGrid = InteractiveTimelineDisplayCalculator.isHorizontal(options)
    ? options.trackSizeInPixels
    : options.stepSizeInPixels;
  return interact.modifiers.restrictSize({
    min: { width: xGrid, height: yGrid },
  });
}

export function getDragOptions(
  options: ITimelineDisplayOptions,
  dragArea: string | HTMLElement | SVGElement,
  dragStartPosition$: BehaviorSubject<IDragStartPosition | undefined>,
  listeners: Listeners
): DraggableOptions {
  return {
    origin: dragArea,
    modifiers: [
      getSnapGridModifier(options, dragArea, dragStartPosition$),
      getDragRestrictModifier(dragArea),
      getMinSizeModifier(options),
    ],
    listeners,
  };
}

export function getDragEdges(
  options: ITimelineDisplayOptions,
  startHandle: HTMLElement,
  endHandle: HTMLElement
): EdgeOptions {
  const isHorizontal =
    InteractiveTimelineDisplayCalculator.isHorizontal(options);
  return {
    top: isHorizontal ? false : startHandle,
    left: isHorizontal ? startHandle : false,
    bottom: isHorizontal ? false : endHandle,
    right: isHorizontal ? endHandle : false,
  };
}
