import { isSameToothRef } from '@principle-theorem/principle-core';
import { type IPerioRecord } from '@principle-theorem/principle-core/interfaces';
import { cloneDeep, set } from 'lodash';
import { type IPerioTableCell } from './perio-table/perio-table';

export function upsertPerioValue(
  records: IPerioRecord[],
  event: IPerioTableCell
): IPerioRecord[] {
  const existingRecord = records.find((record) =>
    isSameToothRef(record.toothRef, event.metadata.toothRef)
  );
  const oldData = { ...existingRecord?.data };
  return upsertRecord(records, {
    toothRef: event.metadata.toothRef,
    data: set(cloneDeep(oldData), event.metadata.dataPath, event.value),
  });
}

function upsertRecord(
  records: IPerioRecord[],
  upsertValue: IPerioRecord
): IPerioRecord[] {
  const filtered = records.filter(
    (record) => !isSameToothRef(record.toothRef, upsertValue.toothRef)
  );
  return [...filtered, upsertValue];
}
