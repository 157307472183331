import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { type INotification } from '@principle-theorem/notifications';
import { Staffer } from '@principle-theorem/principle-core';
import {
  filterUndefined,
  isPathChanged$,
  query$,
  type WithRef,
} from '@principle-theorem/shared';
import { limit, orderBy } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-account-notifications',
    templateUrl: './account-notifications.component.html',
    styleUrls: ['./account-notifications.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AccountNotificationsComponent {
  notifications$: Observable<WithRef<INotification>[]>;

  constructor(private _organisation: OrganisationService) {
    this.notifications$ = this._organisation.staffer$.pipe(
      isPathChanged$('ref.path'),
      filterUndefined(),
      switchMap((staffer) =>
        query$(
          Staffer.notificationCol(staffer),
          orderBy('createdAt', 'desc'),
          limit(100)
        )
      )
    );
  }
}
