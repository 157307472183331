import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TypedFormControl,
  TypedFormGroup,
  isDisabled$,
} from '@principle-theorem/ng-shared';
import {
  AccountCredit,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import {
  IBrand,
  ITreatmentCategory,
  type IAccountCredit,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  DocumentReference,
  toMoment,
  toTimestamp,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { from, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalStoreService } from '../../store/global-store.service';
import { TreatmentCategorySelectorBloc } from './treatment-category-selector-bloc';

export interface IEditAccountCreditDialogData {
  credit: WithRef<IAccountCredit>;
  brand: WithRef<IBrand>;
  practice?: WithRef<IPractice>;
}

type EditAccountCreditFormData = Pick<IAccountCredit, 'description'> & {
  createdAt: moment.Moment;
  reservedFor: Pick<
    IAccountCredit['reservedFor'],
    'practitioner' | 'treatmentCategory'
  >;
};

@Component({
    selector: 'pr-edit-account-credit-dialog',
    templateUrl: './edit-account-credit-dialog.component.html',
    styleUrls: ['./edit-account-credit-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [...MOMENT_DATEPICKER_PROVIDERS],
    standalone: false
})
export class EditAccountCreditDialogComponent {
  dateFormat = DAY_MONTH_YEAR_FORMAT;
  isDisabled$: Observable<boolean>;
  brand$: Observable<WithRef<IBrand>>;
  treatmentCategoryCtrl = new TypedFormControl<
    DocumentReference<ITreatmentCategory>
  >();
  form: TypedFormGroup<EditAccountCreditFormData>;
  receiveDepositsPermissions = [PatientPermissions.ReceiveDeposits];
  accountAdminPermission = PatientPermissions.AccountInvoiceAdmin;
  treatmentCategoryBloc: TreatmentCategorySelectorBloc;
  depositPaidDate$: Observable<Moment | undefined>;
  today = moment().endOf('day');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEditAccountCreditDialogData,
    public _dialogRef: MatDialogRef<
      EditAccountCreditDialogComponent,
      Partial<WithRef<IAccountCredit>>
    >,
    private _globalStore: GlobalStoreService
  ) {
    this.form = new TypedFormGroup<EditAccountCreditFormData>({
      description: new TypedFormControl<string>(),
      createdAt: new TypedFormControl<Moment>(),
      reservedFor: new TypedFormGroup<EditAccountCreditFormData['reservedFor']>(
        {
          practitioner: new TypedFormControl<INamedDocument<IStaffer>>(),
          treatmentCategory: this.treatmentCategoryCtrl,
        }
      ),
    });
    this.form.patchValue({
      ...data.credit,
      createdAt: toMoment(data.credit.createdAt),
    });

    this.depositPaidDate$ = from(
      AccountCredit.getDepositPaidDate(data.credit)
    ).pipe(
      map((depositPaidAt) =>
        depositPaidAt ? toMoment(depositPaidAt) : undefined
      )
    );
    this.isDisabled$ = isDisabled$(this.form);

    this.treatmentCategoryBloc = new TreatmentCategorySelectorBloc(
      this._globalStore.treatmentCategories$,
      this.treatmentCategoryCtrl
    );
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }
    const data = this.form.getRawValue();
    const practitioner = data.reservedFor?.practitioner
      ? stafferToNamedDoc(data.reservedFor.practitioner)
      : undefined;
    this._dialogRef.close({
      ...data,
      createdAt: toTimestamp(data.createdAt),
      issued: toTimestamp(data.createdAt),
      reservedFor: {
        ...data.reservedFor,
        practitioner,
      },
    });
  }
}
