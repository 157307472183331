import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HIGHLIGHTED_NODE_QUERY_PARAM } from '@principle-theorem/ng-principle-shared';
import {
  IScheduleSummaryEventable,
  TimelineDisplayOptionsPresets,
  TimelineDisplayOrientation,
  getPixelSizeFromDisplayZoom,
  isAppointmentRequest,
  type EventType,
  type IEventable,
  type IPracticeSettings,
  type IStaffer,
  type TimelineDisplayZoom,
  type TimelineMode,
} from '@principle-theorem/principle-core/interfaces';
import {
  isChanged$,
  isSameRange,
  isSameRef,
  type ITimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
import { Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import {
  type ITimelineDragEvent,
  type ITimelineResizeEvent,
} from '../interactive-timeline/interactive-timeline.component';
import { TimelineViewHandler } from '../interactive-timeline/timeline-view-handler';
import { EventableTimelineStore } from './eventable-timeline.store';

@Component({
  selector: 'pr-eventable-timeline',
  templateUrl: './eventable-timeline.component.html',
  styleUrls: ['./eventable-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class EventableTimelineComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _staff$ = new ReplaySubject<WithRef<IStaffer>[]>(1);
  private _dateRange$ = new ReplaySubject<ITimePeriod>(1);
  private _openingHours$ = new ReplaySubject<ITimePeriod[]>(1);
  private _orientation$ = new ReplaySubject<TimelineDisplayOrientation>(1);
  private _thickTracks$ = new ReplaySubject<boolean>(1);
  private _practiceViewSettings$ = new ReplaySubject<
    IPracticeSettings['timeline']
  >(1);
  private _showGridlines$ = new ReplaySubject<boolean>(1);
  private _showRosteredOffStaff$ = new ReplaySubject<boolean>(1);
  private _hideEmptyDays$ = new ReplaySubject<boolean>(1);

  timelineMode$ = new ReplaySubject<TimelineMode>(1);
  createType$ = new ReplaySubject<EventType>(1);
  view: TimelineViewHandler;
  highlightedNode$: Observable<string | undefined>;

  @HostBinding('class.disabled') isDisabled: boolean = false;
  zoom$ = new ReplaySubject<TimelineDisplayZoom>(1);

  @Output() eventSelected = new EventEmitter<IScheduleSummaryEventable>();
  @Output() eventDragged = new EventEmitter<
    ITimelineDragEvent<WithRef<IEventable>, WithRef<IStaffer>>
  >();
  @Output() eventResized = new EventEmitter<
    ITimelineResizeEvent<WithRef<IEventable>>
  >();

  @Input()
  set showRosteredOffStaff(showRosteredOffStaff: BooleanInput) {
    this._showRosteredOffStaff$.next(
      coerceBooleanProperty(showRosteredOffStaff)
    );
  }

  @Input()
  set hideEmptyDays(hideEmptyDays: BooleanInput) {
    this._hideEmptyDays$.next(coerceBooleanProperty(hideEmptyDays));
  }

  @Input()
  set timelineMode(timelineMode: TimelineMode) {
    if (timelineMode) {
      this.timelineMode$.next(timelineMode);
    }
  }

  @Input()
  set createType(createType: EventType) {
    if (createType) {
      this.createType$.next(createType);
    }
  }

  @Input()
  set staff(staff: WithRef<IStaffer>[]) {
    if (staff) {
      this._staff$.next(staff);
    }
  }

  @Input()
  set dateRange(dateRange: ITimePeriod) {
    if (dateRange) {
      this._dateRange$.next(dateRange);
    }
  }

  @Input()
  set openingHours(openingHours: ITimePeriod[]) {
    if (openingHours) {
      this._openingHours$.next(openingHours);
    }
  }

  @Input()
  set zoom(zoom: TimelineDisplayZoom) {
    if (zoom) {
      this.zoom$.next(zoom);
    }
  }

  @Input()
  set showGridlines(showGridlines: BooleanInput) {
    this._showGridlines$.next(coerceBooleanProperty(showGridlines));
  }

  @Input()
  set disabled(disabled: BooleanInput) {
    this.isDisabled = coerceBooleanProperty(disabled);
  }

  @Input()
  set practiceViewSettings(
    practiceViewSettings: IPracticeSettings['timeline']
  ) {
    if (practiceViewSettings) {
      this._practiceViewSettings$.next(practiceViewSettings);
    }
  }

  @Input()
  @HostBinding('class.disable-scroll')
  disableScroll: boolean = false;

  @Input()
  set orientation(orientation: TimelineDisplayOrientation) {
    if (orientation) {
      this._orientation$.next(orientation);
    }
  }

  @Input()
  set thickTracks(thickTracks: BooleanInput) {
    this._thickTracks$.next(coerceBooleanProperty(thickTracks));
  }

  constructor(
    private _eventableTimelineStore: EventableTimelineStore,
    private _route: ActivatedRoute
  ) {
    const timelineData$ = combineLatest([
      this._staff$.pipe(isChanged$(isSameRef)),
      this._openingHours$.pipe(
        isChanged$((rangeA, rangeB) => isSameRange(rangeA, rangeB))
      ),
      this._dateRange$.pipe(isChanged$(isSameRange)),
      this._showRosteredOffStaff$.pipe(distinctUntilChanged()),
      this._hideEmptyDays$.pipe(distinctUntilChanged()),
    ]).pipe(
      map(
        ([
          staff,
          openingHours,
          dateRange,
          showRosteredOffStaff,
          hideEmptyDays,
        ]) => ({
          staff,
          openingHours,
          dateRange,
          showRosteredOffStaff,
          hideEmptyDays,
        })
      )
    );

    this._eventableTimelineStore.load(timelineData$);

    const personalViewSettings$ = combineLatest([
      this._orientation$,
      this._thickTracks$,
      this._showGridlines$,
      this.zoom$,
    ]).pipe(
      map(([orientation, thickTracks, showGridlines, zoom]) => ({
        orientation,
        thickTracks,
        showGridlines,
        zoom,
      }))
    );

    const options$ = combineLatest([
      this._practiceViewSettings$.pipe(isChanged$()),
      personalViewSettings$.pipe(isChanged$()),
    ]).pipe(
      map(([practiceViewSettings, personalViewSettings]) => {
        const settings = {
          ...practiceViewSettings,
          showGridlines: personalViewSettings.showGridlines,
          stepSizeInPixels: getPixelSizeFromDisplayZoom(
            personalViewSettings.zoom,
            personalViewSettings.orientation,
            practiceViewSettings?.stepSizeInMins
          ),
        };
        if (
          personalViewSettings.orientation ===
          TimelineDisplayOrientation.Horizontal
        ) {
          return TimelineDisplayOptionsPresets.horizontal(settings);
        }
        return personalViewSettings.thickTracks
          ? TimelineDisplayOptionsPresets.verticalLarge(settings)
          : TimelineDisplayOptionsPresets.vertical(settings);
      })
    );

    this.view = new TimelineViewHandler(
      options$,
      this._eventableTimelineStore.timeRange$,
      this.zoom$
    );

    this.highlightedNode$ = this._route.queryParamMap.pipe(
      map(
        (params) =>
          params.get(HIGHLIGHTED_NODE_QUERY_PARAM)?.trim() || undefined
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  handleDrag(
    event: ITimelineDragEvent<WithRef<IEventable>, WithRef<IStaffer>>
  ): void {
    this.eventDragged.emit(event);
  }

  handleResize(event: ITimelineResizeEvent<WithRef<IEventable>>): void {
    this.eventResized.emit(event);
  }

  selectEvent(event: IScheduleSummaryEventable): void {
    if (isAppointmentRequest(event)) {
      return;
    }
    this.eventSelected.emit(event);
  }

  updateEvent(data: {
    oldEvent: IScheduleSummaryEventable;
    newEvent: IScheduleSummaryEventable;
  }): void {
    this._eventableTimelineStore.updateEvent(data.oldEvent, data.newEvent);
  }
}
