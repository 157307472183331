import { Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type IChartCard } from '../../../models/report/charts/chart-card';
import { RangeSelectorCard, type ITimeRange } from '../range-selector-card';

@Component({
  selector: 'pr-forecasts-card',
  templateUrl: './forecasts-card.component.html',
  styleUrls: ['./forecasts-card.component.sass'],
})
export class ForecastsCardComponent extends RangeSelectorCard {
  trackByRange = TrackByFunctions.title<ITimeRange>();
  @Input() chart: IChartCard;
  override ranges: ITimeRange[] = [
    { title: 'Next Month', from: { months: -1 }, to: { months: 1 } },
    { title: 'Next Year', from: { months: -6 }, to: { years: 1 } },
  ];
}
