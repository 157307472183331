import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { type IScheduleRange } from '@principle-theorem/principle-core/interfaces';
import { isTime24hrType, type Time24hrType } from '@principle-theorem/shared';

@Component({
  selector: 'pr-edit-staffer-roster-day-line-item',
  templateUrl: './edit-staffer-roster-day-line-item.component.html',
  styleUrls: ['./edit-staffer-roster-day-line-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditStafferRosterDayLineItemComponent {
  form = new TypedFormGroup<IScheduleRange>({
    from: new TypedFormControl<Time24hrType>().withGuard(isTime24hrType),
    to: new TypedFormControl<Time24hrType>().withGuard(isTime24hrType),
  });
  @Output() submitted = new EventEmitter<void>();

  @Input()
  set from(from: Time24hrType) {
    if (from) {
      this.form.patchValue({ from }, { emitEvent: false });
    }
  }

  @Input()
  set to(to: Time24hrType) {
    if (to) {
      this.form.patchValue({ to }, { emitEvent: false });
    }
  }
}
