<div class="day" [ngClass]="{ today: isToday$ | async }" fxFlex>
  <div class="hour" *ngFor="let hour of hours; trackBy: trackByHour"></div>

  <ng-container *ngIf="groupedEvents$ | async as groupedEvents">
    <div fxLayout="column">
      <ng-container *ngFor="let group of groupedEvents; trackBy: trackByIndex">
        <pr-calendar-event-time
          *ngFor="let event of group; let i = index; trackBy: trackByEvent"
          [event]="event"
          [day]="day$ | async"
          [index]="i"
        />
      </ng-container>
    </div>
  </ng-container>
</div>
