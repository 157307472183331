import { CommonModule } from '@angular/common';
import { type ModuleWithProviders, NgModule } from '@angular/core';
import { NgEditorModule } from '@principle-theorem/ng-editor';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import {
  type INotificationConfig,
  NG_NOTIFICATION_CONFIG,
} from './notification-config';
import { NotificationComponent } from './notification/notification.component';
import { NotificationsComponent } from './notifications/notifications.component';

@NgModule({
  imports: [CommonModule, NgSharedModule, NgEditorModule],
  declarations: [NotificationComponent, NotificationsComponent],
  exports: [NotificationsComponent, NotificationComponent],
})
export class NgNotificationsModule {
  static forRoot(
    config: INotificationConfig
  ): ModuleWithProviders<NgNotificationsModule> {
    return {
      ngModule: NgNotificationsModule,
      providers: [
        {
          provide: NG_NOTIFICATION_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
