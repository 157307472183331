import { Injectable, OnDestroy, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  GlobalStoreService,
  PerioSettingsService,
} from '@principle-theorem/ng-principle-shared';
import {
  IChartedCondition,
  IChartedConditionWithResolvedConfig,
  IPerioRecord,
  IPerioTable,
  PerioTableSide,
  SwitchDirection,
  SwitchPattern,
  type ITooth,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  filterUndefined,
  snapshot,
} from '@principle-theorem/shared';
import { isEqual, last } from 'lodash';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { PerioTableNavigation } from '../perio/perio-navigation';
import { PerioTable } from '../perio/perio-table';

interface INextCell {
  cellIndex?: number;
  key?: KeyboardEvent;
}

export interface IPerioChartState {
  perioTables: IPerioTable[];
  navByTable: (number | undefined)[][];
  navBySwitchPattern: (number | undefined)[][];
  nextCell: INextCell;
  direction: SwitchDirection;
  navHistory: number[];
  canEdit: boolean;
}

const initialState: IPerioChartState = {
  perioTables: [],
  navByTable: [],
  navBySwitchPattern: [],
  nextCell: { cellIndex: undefined, key: undefined },
  direction: SwitchDirection.Default,
  navHistory: [],
  canEdit: false,
};

@Injectable()
export class PerioChartStore
  extends ComponentStore<IPerioChartState>
  implements OnDestroy
{
  private _settings = inject(PerioSettingsService);
  private _chart = inject(ChartFacade);
  private _globalStore = inject(GlobalStoreService);
  private _defaultPerioTable$ = new Subject<IPerioTable[]>();

  readonly perioTables$ = this.select((state) => state.perioTables);
  readonly navByTable$ = this.select((state) => state.navByTable);
  readonly navBySwitchPattern$ = this.select(
    (state) => state.navBySwitchPattern
  );
  readonly navHistory$ = this.select((state) => state.navHistory);
  readonly canEdit$ = this.select((state) => state.canEdit);
  readonly nextCell$ = this.select((state) => state.nextCell);
  readonly direction$ = this.select((state) => state.direction);

  readonly setPerioTables = this.updater(
    (state, perioTables: IPerioTable[]) => ({
      ...state,
      perioTables,
    })
  );

  readonly setNavBySwitchPattern = this.updater(
    (state, navBySwitchPattern: (number | undefined)[][]) => ({
      ...state,
      navBySwitchPattern,
    })
  );

  readonly setNextCell = this.updater((state, nextCell: INextCell) => ({
    ...state,
    nextCell,
  }));

  readonly setDirection = this.updater((state, direction: SwitchDirection) => ({
    ...state,
    direction,
  }));

  readonly clearNextCell = this.updater((state) => ({
    ...state,
    nextCell: { cellIndex: undefined, key: undefined },
  }));

  readonly addToNavHistory = this.updater((state, index: number) => {
    return {
      ...state,
      navHistory: [...state.navHistory, index],
    };
  });

  readonly removeFromNavHistory = this.updater((state) => ({
    ...state,
    navHistory: state.navHistory.slice(0, -1),
  }));

  constructor() {
    super(initialState);
    this._initialiseDefaultPerioTables$();
    this._buildModifiedPerioTables$();
    this._initialiseCanEdit$();
  }

  previousIndex(): number | undefined {
    const lastIndex = last(this.get().navHistory);
    this.removeFromNavHistory();
    return lastIndex;
  }

  private _initialiseCanEdit$(): void {
    this.effect(() =>
      this._chart.clinicalChartState$(ChartId.InAppointment).pipe(
        filterUndefined(),
        map((chart) => !chart.immutable),
        distinctUntilChanged<boolean>(isEqual),
        tap((canEdit) => this.patchState({ canEdit }))
      )
    );
  }

  private _initialiseDefaultPerioTables$(): void {
    this.effect(() =>
      combineLatest([
        this.getTeeth$(),
        this.getResolvedConditions$(),
        this.getImmutableState$(),
        this._chart.chartView$(ChartId.InAppointment),
        this._chart.chartSection$(ChartId.InAppointment),
        this.getPerioRecords$(),
      ]).pipe(
        filter((params) => !params.some((param) => param === undefined)),
        map((params) => PerioTable.toPerioTables(...params)),
        map(this._splitTablesBySide),
        tap((perioTables) => this._defaultPerioTable$.next(perioTables)),
        catchError((error) => {
          // eslint-disable-next-line no-console
          console.error('Failed to load perio tables:', error);
          return of([]);
        })
      )
    );
  }

  private _buildModifiedPerioTables$(): void {
    this.effect(() =>
      combineLatest([
        this._defaultPerioTable$.pipe(
          distinctUntilChanged<IPerioTable[]>(isEqual)
        ),
        this.getTeeth$(),
        this._settings.switchPattern$.pipe(
          distinctUntilChanged<SwitchPattern>(isEqual)
        ),
      ]).pipe(
        map(([tables, teeth, switchPattern]) => {
          const modifiedTables = tables
            .map(PerioTable.mergeMobilityCells)
            .map((table) => PerioTable.handleFurcationCells(table, teeth));
          return {
            perioTables:
              PerioTableNavigation.assignCellProperties(modifiedTables),
            switchPattern,
          };
        }),
        tap(({ perioTables, switchPattern }) =>
          this.patchState({
            perioTables: PerioTableNavigation.removeEmptyCells(perioTables),
            navByTable: PerioTableNavigation.buildNavByTable(perioTables),
            navBySwitchPattern:
              switchPattern === SwitchPattern.Quadrant
                ? PerioTableNavigation.buildNavByRow(perioTables)
                : PerioTableNavigation.buildNavByMeasurement(perioTables),
          })
        )
      )
    );
  }

  private getTeeth$(): Observable<ITooth[]> {
    return this._chart.clinicalChartState$(ChartId.InAppointment).pipe(
      filterUndefined(),
      map((chart) => chart.teeth),
      distinctUntilChanged<ITooth[]>(isEqual)
    );
  }

  private getResolvedConditions$(): Observable<
    IChartedConditionWithResolvedConfig[]
  > {
    return this._chart.clinicalChartState$(ChartId.InAppointment).pipe(
      filterUndefined(),
      switchMap((chart) => this._resolveConditions(chart.conditions)),
      distinctUntilChanged<IChartedConditionWithResolvedConfig[]>(isEqual)
    );
  }

  private getImmutableState$(): Observable<boolean> {
    return this._chart.clinicalChartState$(ChartId.InAppointment).pipe(
      filterUndefined(),
      map((chart) => chart.immutable),
      distinctUntilChanged<boolean>(isEqual)
    );
  }

  private getPerioRecords$(): Observable<IPerioRecord[]> {
    return this._chart.clinicalChartState$(ChartId.InAppointment).pipe(
      filterUndefined(),
      map((chart) => chart.perioRecords),
      map((records) =>
        PerioTable.addClinicalAttachmentLossMeasurements(records)
      ),
      filterUndefined(),
      distinctUntilChanged<IPerioRecord[]>(isEqual)
    );
  }

  private _splitTablesBySide(tables: IPerioTable[]): IPerioTable[] {
    return tables.flatMap((table) => [
      PerioTable.filterTableBySide(table, PerioTableSide.Facial),
      PerioTable.filterTableBySide(table, PerioTableSide.Palatal),
    ]);
  }

  private async _resolveConditions(
    conditions: IChartedCondition[]
  ): Promise<IChartedConditionWithResolvedConfig[]> {
    if (!conditions) {
      return [];
    }
    try {
      const resolvedConditions = await asyncForEach(
        conditions,
        async (condition) => ({
          ...condition,
          config: await snapshot(
            this._globalStore.getConditionConfiguration$(condition.config.ref)
          ),
        })
      );
      return resolvedConditions.filter(
        (condition) => condition.config !== undefined
      ) as IChartedConditionWithResolvedConfig[];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error resolving conditions:', error);
      return [];
    }
  }
}
