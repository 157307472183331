import {
  ChartableSurface,
  ChartedItemType,
  IChartedItem,
  IChartedRef,
  IChartedSurface,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { AtLeast, toTimestamp, WithRef } from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { stafferToNamedDoc } from '../../common';

export class ChartedItem {
  static init(overrides: AtLeast<IChartedItem, 'config'>): IChartedItem {
    return {
      uuid: uuid(),
      type: ChartedItemType.Generic,
      chartedSurfaces: [],
      notes: [],
      scopeRef: {
        scope: ChartableSurface.WholeMouth,
      },
      ...overrides,
    };
  }

  static allChartedSurfaces(item: IChartedItem): IChartedSurface[] {
    return item.chartedSurfaces;
  }

  // TODO: Implement seraialising the details of the charted item for use
  // in auditing all clinical notes of the chart.
  static asClinicalNote(_: IChartedItem): string {
    return '';
  }

  static isResolved(item: IChartedItem): boolean {
    return item.resolvedAt ? true : false;
  }

  static resolve<T extends IChartedItem>(
    item: T,
    resolvedBy?: WithRef<IStaffer>
  ): T {
    if (this.isResolved(item)) {
      return item;
    }
    return {
      ...item,
      resolvedAt: toTimestamp(),
      resolvedBy: resolvedBy ? stafferToNamedDoc(resolvedBy) : undefined,
    };
  }

  static hasSelectedSurface$(
    item$: Observable<IChartedItem>,
    selectedSurfaces$: Observable<Partial<IChartedRef>[]>
  ): Observable<boolean> {
    return combineLatest([item$, selectedSurfaces$]).pipe(
      map(([item, selectedSurfaces]) =>
        selectedSurfaces.some((selectedSurface: Partial<IChartedRef>) =>
          ChartedItem.allChartedSurfaces(item).some(
            (chartedSurface: IChartedSurface) =>
              isEqual(chartedSurface.chartedRef, selectedSurface)
          )
        )
      )
    );
  }
}
