import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SmartpayService } from '@principle-theorem/ng-payments';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  IPractice,
  type ISmartpayTerminal,
} from '@principle-theorem/principle-core/interfaces';
import {
  CollectionReference,
  DocumentReference,
  addDoc,
  getError,
  toTimestamp,
  uid,
} from '@principle-theorem/shared';

interface IAddSmartpayTerminalFormData extends Pick<ISmartpayTerminal, 'name'> {
  pairingCode: string;
}

export interface IPairSmartpayTerminalDialogRequest {
  terminalCol: CollectionReference<ISmartpayTerminal>;
  practiceRef: DocumentReference<IPractice>;
}

@Component({
  selector: 'pr-pair-smartpay-terminal-dialog',
  templateUrl: './pair-smartpay-terminal-dialog.component.html',
  styleUrls: ['./pair-smartpay-terminal-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PairSmartpayTerminalDialogComponent {
  loading: boolean = false;

  form = new TypedFormGroup<IAddSmartpayTerminalFormData>({
    name: new TypedFormControl<string>(''),
    pairingCode: new TypedFormControl<string>(''),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: IPairSmartpayTerminalDialogRequest,
    private _smartpay: SmartpayService,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<PairSmartpayTerminalDialogComponent>
  ) {}

  async submit(): Promise<void> {
    this.loading = true;
    const data = this.form.getRawValue();
    try {
      const terminal: ISmartpayTerminal = {
        name: data.name,
        uid: uid(true),
        lastPaired: toTimestamp(),
        deleted: false,
      };

      await this._smartpay.pairTerminal({
        pairingCode: data.pairingCode,
        practiceRef: this._data.practiceRef,
        terminal,
      });

      await addDoc(this._data.terminalCol, terminal);
      this._snackBar.open('Terminal paired successfully');
      this._dialogRef.close();
    } catch (error) {
      this.loading = false;
      this._snackBar.open(getError(error), 'Dismiss', { duration: 5000 });
    }
  }
}
