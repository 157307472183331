import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  type ICanBeChartedProperty,
  type IChartConfig,
  type IDataBuilder,
} from '@principle-theorem/reporting';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { type ChartBuilder } from '../../../models/report/charts/chart-builder';
import { SingleValueTransformer } from '../../../models/report/charts/data-transformers/single-value-transformer';
import { type IDataPoint } from '../../../models/report/charts/measure-data-interfaces';

@Component({
    selector: 'pr-data-points-summary',
    templateUrl: './data-points-summary.component.html',
    styleUrls: ['./data-points-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DataPointsSummaryComponent {
  private _measure$ = new ReplaySubject<ICanBeChartedProperty>(1);
  config$ = new ReplaySubject<IChartConfig>(1);
  dataBuilder$ = new ReplaySubject<IDataBuilder>(1);
  total$: Observable<IDataPoint>;
  @Input() showHistory = true;
  @Input() size = 44;

  @Input()
  set dataBuilder(dataBuilder: IDataBuilder) {
    if (dataBuilder) {
      this.dataBuilder$.next(dataBuilder);
    }
  }

  @Input()
  set config(config: IChartConfig) {
    if (config) {
      this.config$.next(config);
    }
  }

  @Input()
  set chart(chartBuilder: ChartBuilder) {
    const cloned = chartBuilder.clone();
    cloned.chartDataHandler.config.builderData.accumulateOverTime = false;
    cloned.chartDataHandler.config.builderData.plottedOverTime = false;
    this.config$.next(cloned.chartDataHandler.config);
    this.dataBuilder$.next(cloned.chartDataHandler.dataBuilder);
  }

  @Input()
  set measure(measure: ICanBeChartedProperty) {
    this._measure$.next(measure);
  }

  constructor() {
    this.total$ = combineLatest([
      this._measure$,
      this.config$,
      this.dataBuilder$,
    ]).pipe(
      switchMap(([measure, config, dataBuilder]) =>
        this._calculateTotal(measure, config, dataBuilder)
      )
    );
  }

  private async _calculateTotal(
    measure: ICanBeChartedProperty,
    config: IChartConfig,
    dataBuilder: IDataBuilder
  ): Promise<IDataPoint> {
    const transformer = new SingleValueTransformer();
    const value = await transformer.transform(config, dataBuilder, measure);
    return {
      value: parseFloat(String(value)),
      label: measure.metadata.label,
      formatter: measure.metadata.formatter,
    };
  }
}
