import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  HicapsConnectReceiptType,
  PrincipleHicapsConnect,
} from '@principle-theorem/hicaps-connect';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  HicapsConnectResponseCodes,
  findHealthFundByCardNumber,
} from '@principle-theorem/principle-core';
import {
  AnyHicapsConnectExtendedData,
  HicapsConnectExtendedDataTypeGuard,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  HISTORY_DATE_FORMAT,
  filterUndefined,
} from '@principle-theorem/shared';
import { sum } from 'lodash';
import * as moment from 'moment-timezone';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { HICAPS_CONNECT_IMAGE_URL } from '../../transaction-provider-options/hicaps-connect-transaction-provider-options';
import { HicapsConnectHealthFundResponseTransformer } from '../health-fund/hicaps-connect-health-fund-response-transformer';

interface IClaimSummary {
  providerNumber: string;
  merchantNumber: string;
  healthFund: string;
  cardNumber: string;
  membershipNumber: string;
  rrn: string;
  dateTime: moment.Moment;
  terminalNumber: string;
  responseCode: string;
  totalClaim: number;
  totalBenefit: number;
  totalGap: number;
}

interface IServiceItem {
  id: string;
  pt: string;
  serviceItem: string;
  note?: string;
  dateOfService: string;
  claim: number;
  benefit: number;
  gap: number;
}

interface IServiceItemSummary {
  items: IServiceItem[];
  totalClaim: number;
  totalBenefit: number;
  totalGap: number;
}

@Component({
    selector: 'pr-hicaps-connect-receipt',
    templateUrl: './hicaps-connect-receipt.component.html',
    styleUrls: ['./hicaps-connect-receipt.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HicapsConnectReceiptComponent {
  readonly dateFormat = HISTORY_DATE_FORMAT;
  readonly yearFormat = DAY_MONTH_YEAR_FORMAT;
  trackByItems = TrackByFunctions.field<IServiceItem>('id');
  hicapsLogoUrl = HICAPS_CONNECT_IMAGE_URL;
  today: moment.Moment = moment();
  extendedData$ = new ReplaySubject<AnyHicapsConnectExtendedData>(1);
  receiptType$ = new ReplaySubject<HicapsConnectReceiptType>(1);
  isProviderReceipt$: Observable<boolean>;
  claimSummary$: Observable<IClaimSummary>;
  serviceItemSummary$: Observable<IServiceItemSummary>;
  isClaimCancel$: Observable<boolean>;

  @Input()
  set receiptType(receiptType: HicapsConnectReceiptType) {
    if (receiptType) {
      this.receiptType$.next(receiptType);
    }
  }

  @Input()
  set extendedData(extendedData: AnyHicapsConnectExtendedData) {
    if (extendedData) {
      this.extendedData$.next(extendedData);
    }
  }

  constructor() {
    this.isProviderReceipt$ = this.receiptType$.pipe(
      map((receiptType) => receiptType === HicapsConnectReceiptType.Provider)
    );
    this.serviceItemSummary$ = this.extendedData$.pipe(
      map((extendedData) => this._toServiceItemSummary(extendedData)),
      filterUndefined()
    );
    this.claimSummary$ = combineLatest([
      this.extendedData$,
      this.serviceItemSummary$,
    ]).pipe(
      map(([extendedData, serviceItemSummary]) =>
        this._toClaimSummary(extendedData, serviceItemSummary)
      ),
      filterUndefined()
    );
    this.isClaimCancel$ = this.extendedData$.pipe(
      map((extendedData) =>
        HicapsConnectExtendedDataTypeGuard.isHealthFundCancel(extendedData)
      )
    );
  }

  private _toClaimSummary(
    extendedData: AnyHicapsConnectExtendedData,
    serviceItemSummary: IServiceItemSummary
  ): IClaimSummary | undefined {
    const isError = PrincipleHicapsConnect.isErrorResponse();
    if (!extendedData.response || isError(extendedData.response)) {
      return;
    }

    if (HicapsConnectExtendedDataTypeGuard.isHealthFund(extendedData)) {
      const data = extendedData.response.data;
      if (!HicapsConnectResponseCodes.isClaimSuccessful(data.ResponseCode)) {
        return;
      }
      const healthFund = findHealthFundByCardNumber(data.PrimaryAccountNumber);
      return {
        providerNumber: data.ProviderNumberId,
        merchantNumber: data.MerchantId,
        healthFund: healthFund?.name ?? '',
        cardNumber: data.PrimaryAccountNumber,
        membershipNumber: data.MembershipId,
        rrn: data.RrnNumber,
        dateTime: moment(data.TransactionDate),
        terminalNumber: data.TerminalId,
        responseCode: `${data.ResponseCode} - ${data.ResponseText}`,
        totalClaim: data.TransactionAmount,
        totalBenefit: data.BenefitAmount,
        totalGap: data.TransactionAmount - data.BenefitAmount,
      };
    }

    if (HicapsConnectExtendedDataTypeGuard.isHealthFundCancel(extendedData)) {
      const data = extendedData.response.data;
      if (!HicapsConnectResponseCodes.isClaimSuccessful(data.ResponseCode)) {
        return;
      }
      const healthFund = findHealthFundByCardNumber(data.PrimaryAccountNumber);
      return {
        providerNumber: data.ProviderNumberId,
        merchantNumber: data.MerchantId,
        healthFund: healthFund?.name ?? '',
        cardNumber: data.PrimaryAccountNumber,
        membershipNumber: data.MembershipId,
        rrn: data.RrnNumber,
        dateTime: moment(data.TransactionDate),
        terminalNumber: data.TerminalId,
        responseCode: `${data.ResponseCode} - ${data.ResponseText}`,
        totalClaim: serviceItemSummary.totalClaim,
        totalBenefit: data.BenefitAmount,
        totalGap: serviceItemSummary.totalClaim - data.BenefitAmount,
      };
    }
  }

  private _toServiceItemSummary(
    extendedData: AnyHicapsConnectExtendedData
  ): IServiceItemSummary | undefined {
    const isError = PrincipleHicapsConnect.isErrorResponse();
    if (!extendedData.response || isError(extendedData.response)) {
      return;
    }
    if (
      HicapsConnectExtendedDataTypeGuard.isHealthFund(extendedData) ||
      HicapsConnectExtendedDataTypeGuard.isHealthFundCancel(extendedData)
    ) {
      const items = this._getServiceItems(
        extendedData.response.data.ClaimDetails
      );
      return {
        items,
        totalClaim: sum(items.map((item) => item.claim)),
        totalBenefit: sum(items.map((item) => item.benefit)),
        totalGap: sum(items.map((item) => item.gap)),
      };
    }
  }

  private _getServiceItems(claimDetails: string[]): IServiceItem[] {
    return claimDetails.map((itemData, index) => {
      const item =
        HicapsConnectHealthFundResponseTransformer.claimResponseItem(itemData);
      const serviceItem = {
        id: `${index}`,
        pt: item.patientId,
        serviceItem:
          HicapsConnectHealthFundResponseTransformer.getItemDescription(item),
        note: HicapsConnectHealthFundResponseTransformer.getItemNote(item),
        dateOfService: moment(item.dateOfService, 'DDMM').format('DD/MM'),
        claim: item.itemClaimAmount,
        benefit: item.itemBenefitAmount,
        gap: item.itemClaimAmount - item.itemBenefitAmount,
      };
      return serviceItem;
    });
  }
}
