<mat-toolbar color="accent">Pricing Rule Type</mat-toolbar>
<div class="layout-margin">
  <p>
    <strong>Flat</strong><br />
    Each item code is charged per use. This is the standard way of charging
    fees.
  </p>

  <p>
    <strong>Tiered</strong><br />
    Each item code will be charged based on how many item codes are performed
    overall. This is useful when the initial treatment is a certain price, but
    proceeding uses of the item code are at a discounted rate.<br />
    <small class="mat-caption"
      >Eg. A 311 where there is a step down fee for each subsequent tooth
      removal or part(s) thereof could be expressed as:</small
    >
  </p>
  <ul class="mat-caption list-inside list-disc">
    <li>1 unit: $120</li>
    <li>2 & above: $90</li>
  </ul>
  <small class="mat-caption">
    In this example, if 3x 311s where performed, the total would be $120 + $90 +
    $90 = $300.
  </small>

  <p>
    <strong>Stair-step</strong><br />
    The price of the item code is dependent on how many teeth are affected in
    the treatment, not the number of times the item code is used.<br />
    <small class="mat-caption"
      >Eg. 721 is priced based on the number of teeth involved in the treatment.
      In this case there may be pricing brackets for 1, 2, 3, 4, 5 - 9, and 10 -
      12 teeth. This could be expressed as:</small
    >
  </p>
  <ul class="mat-caption list-inside list-disc">
    <li>1 tooth: $300</li>
    <li>2 teeth: $400</li>
    <li>3 teeth: $450</li>
    <li>4 teeth: $500</li>
    <li>5 - 9 teeth: $600</li>
    <li>10 - 12 teeth: $700</li>
  </ul>
  <small class="mat-caption"
    >In this example, if 6 teeth where involved, the total would be $600.</small
  >
</div>
