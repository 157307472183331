@if (sterilisationPacks$ | async; as packs) {
  @if (!packs.length) {
    <pr-empty-state image="list" title="packs configured" />
  } @else {
    <mat-list>
      @for (pack of packs; track pack.ref.path) {
        <div class="flex w-full items-center justify-between gap-4">
          <div class="h-full flex-1">
            <p class="mat-headline-6 !m-0">
              {{ pack.name }}
            </p>
            <p class="mat-caption !m-0">
              {{ pack.description }}
            </p>
          </div>

          <div class="flex items-center justify-end gap-4">
            <div>
              <button mat-icon-button (click)="edit.emit(pack)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button color="warn" (click)="delete.emit(pack)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      }
    </mat-list>
  }
}
