import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SterilisationPageComponent } from './sterilisation-page/sterilisation-page.component';
import { ComponentsModule } from '../components/components.module';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { SterilisationTableFiltersComponent } from '../components/sterilisation-table-filters/sterilisation-table-filters.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    NgMaterialModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    SterilisationTableFiltersComponent,
  ],
  declarations: [SterilisationPageComponent],
  exports: [SterilisationPageComponent],
})
export class PagesModule {}
