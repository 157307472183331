import { ISO_DATE_TIME_FORMAT, toMoment } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { get, isNumber, isString } from 'lodash';

export function extendSortingDataAccessor<T>(
  accessorFn: (data: T, sortHeaderId: string) => string | number | undefined
): (data: T, sortHeaderId: string) => string | number {
  return (data: T, sortHeaderId: string) => {
    const value = accessorFn(data, sortHeaderId);
    if (value) {
      return value;
    }
    const defaultValue: unknown = get(data, sortHeaderId);
    if (!isString(defaultValue) && !isNumber(defaultValue)) {
      return '';
    }
    return defaultValue;
  };
}

export function timestampSortingAccessor(data?: Timestamp): string {
  return data ? toMoment(data).format(ISO_DATE_TIME_FORMAT) : '';
}
