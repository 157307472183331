import {
  AutomationStatus,
  AutomationType,
} from '@principle-theorem/principle-core/interfaces';
import { getEnumValues, type IFilterOption } from '@principle-theorem/shared';
import { type AutomationEntity } from './automation-entity';

export const AUTOMATION_STATUS_FILTER_OPTIONS: IFilterOption<
  AutomationStatus,
  AutomationEntity
>[] = getEnumValues(AutomationStatus).map((status) => ({
  id: status,
  label: status,
  filter: (automation) => automation.status === status,
}));

export type AutomationTypeFilter = AutomationType | 'all';

export const AUTOMATION_TYPE_FILTER_OPTIONS: IFilterOption<
  AutomationTypeFilter,
  AutomationEntity
>[] = [
  {
    id: 'all',
    label: 'All',
    filter: () => true,
  },
  {
    id: AutomationType.GeneratedTask,
    label: 'Task',
    filter: (automation) => automation.type === AutomationType.GeneratedTask,
  },
  {
    id: AutomationType.AutomatedNotification,
    label: 'Notification',
    filter: (automation) =>
      automation.type === AutomationType.AutomatedNotification,
  },
  {
    id: AutomationType.AutomatedFormIssue,
    label: 'Form',
    filter: (automation) =>
      automation.type === AutomationType.AutomatedFormIssue,
  },
];

export function getAutomationTypeFilter(
  type?: AutomationType
): IFilterOption<AutomationTypeFilter, AutomationEntity> {
  const found = AUTOMATION_TYPE_FILTER_OPTIONS.find(
    (option) => option.id === type
  );
  if (!found) {
    return AUTOMATION_TYPE_FILTER_OPTIONS[0];
  }
  return found;
}
