import {
  DocumentReference,
  IFirestoreModel,
  TypeGuard,
  isObject,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { AnyCustomFormElement } from './custom-form-element';
import { type CustomFormDataResolverConfigMap } from './custom-form-helpers';
import { AnyFormLayout } from './layout-interfaces';
import { isFormSchema, type IFormSchema } from './schema-interfaces';

export interface IJsonSchemaForm {
  schema?: IFormSchema;
  layout?: AnyFormLayout[];
}

export interface IJsonSchemaFormWithResolverConfig extends IJsonSchemaForm {
  dataResolverConfig?: CustomFormDataResolverConfigMap;
}

/**
 * TODO: Move away from this interface
 * @deprecated use ICustomFormConfiguration, ICustomFormContent or IJsonSchemaFormWithResolverConfig
 */
export interface IOldCustomFormConfiguration
  extends IJsonSchemaFormWithResolverConfig {
  name: string;
}

export function isOldCustomFormConfiguration(
  data: unknown
): data is IOldCustomFormConfiguration {
  return (
    isObject(data) &&
    isString(data.name) &&
    isFormSchema(data.schema) &&
    TypeGuard.arrayOf(isObject)(data.layout) &&
    TypeGuard.undefinedOr(isObject)(data.dataResolverConfig)
  );
}

export enum CustomFormConfigurationCollection {
  ContentHistory = 'contentHistory',
}

export interface ICustomFormConfiguration extends IFirestoreModel {
  name: string;
  description: string;
  prefillFromPreviousSubmission?: boolean;
  autoConfirm?: boolean;
  activeContent?: DocumentReference<ICustomFormContent>;
}

export interface ICustomFormContent extends IFirestoreModel {
  jsonSchemaForm: IJsonSchemaForm;
  dataResolverConfig?: CustomFormDataResolverConfigMap;
  layout?: AnyCustomFormElement[];
}

export function isCustomFormContent(data: unknown): data is ICustomFormContent {
  return (
    isObject(data) &&
    isObject(data.jsonSchemaForm) &&
    TypeGuard.undefinedOr(isObject)(data.dataResolverConfig) &&
    TypeGuard.undefinedOr(TypeGuard.arrayOf(isObject))(data.layout)
  );
}

export const FORM_CONFIG_TOOLTIPS = {
  prefill:
    'If a patient has submitted this form previously, use that information to prefill future forms',
  autoConfirm: 'Automatically confirm this form when a patient submits it',
};
