import {
  ISourceEntity,
  SourceEntityMigrationType,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISODateTimeType,
  ISODateType,
  ISOTimeType,
  Timestamp,
  Timezone,
  TypeGuard,
  isISODateTimeType,
  isISODateType,
  isISOTimeType,
  toFloat,
  toISODate,
  toTimestamp,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertDateToTimestampFn,
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  convertValueFn,
} from '../../../source/source-helpers';

export const PATIENT_WRITE_OFF_RESOURCE_TYPE = 'patientWriteOffs';

export const PATIENT_WRITE_OFF_SOURCE_ENTITY: ISourceEntity = SourceEntity.init(
  {
    metadata: {
      label: 'Patient Write Off List',
      description: '',
      idPrefix: PATIENT_WRITE_OFF_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  }
);

export interface IOasisPatientWriteOff {
  id: number;
  transactionGroupId?: string;
  appointmentDate: ISODateType;
  createdAt: ISODateType;
  description?: string;
  comment?: string;
  amount?: number;
  showInGst: boolean;

  accountPatientId?: number;
  writeOffPatientId?: number;
  patientName?: string;

  isThirdPartyPayment: boolean; // Correlated to the accountThirdPartyId field being set
  accountThirdPartyId?: number;

  practitionerId: number;
  billedToPractitionerId: number;

  statementNumber?: number;
  showInBanking: boolean;
  isDeleted: boolean;
  deletedAt?: ISODateTimeType;
  locationId?: number;
  userId: number;
  userTime: ISOTimeType;
}

export function isOasisPatientWriteOff(
  item: unknown
): item is IOasisPatientWriteOff {
  return TypeGuard.interface<IOasisPatientWriteOff>({
    id: isNumber,
    transactionGroupId: TypeGuard.nilOr(isString),
    appointmentDate: isISODateType,
    createdAt: isISODateType,
    description: TypeGuard.nilOr(isString),
    accountPatientId: TypeGuard.nilOr(isNumber),
    patientName: TypeGuard.nilOr(isString),
    isThirdPartyPayment: isBoolean,
    accountThirdPartyId: TypeGuard.nilOr(isNumber),
    practitionerId: isNumber,
    billedToPractitionerId: isNumber,
    writeOffPatientId: TypeGuard.nilOr(isNumber),
    statementNumber: TypeGuard.nilOr(isNumber),
    showInBanking: isBoolean,
    isDeleted: isBoolean,
    deletedAt: TypeGuard.nilOr(isISODateTimeType),
    locationId: TypeGuard.nilOr(isNumber),
    userId: isNumber,
    userTime: isISOTimeType,
    comment: TypeGuard.nilOr(isString),
    amount: TypeGuard.nilOr(isNumber),
    showInGst: isBoolean,
  })(item);
}

export interface IOasisPatientWriteOffTranslations {
  createdAt: Timestamp;
  appointmentDate: ISODateType;
  deletedAt?: Timestamp;
}

export interface IOasisPatientWriteOffFilters {
  accountPatientId?: number;
}

const PATIENT_WRITE_OFF_SOURCE_QUERY = `
SELECT
  SEQNUMBER AS id,
  NULLIF(RUNNUMBER::TEXT, 'NULL') AS transaction_group_id,

  CASE
    WHEN convert_to_integer(ACCNUMBER) < 99000
      THEN convert_to_integer(ACCNUMBER)
    ELSE NULL
  END AS account_patient_id,

  CASE
    WHEN convert_to_integer(ACCNUMBER) >= 99000
      THEN convert_to_integer(ACCNUMBER)
    ELSE NULL
  END AS account_third_party_id,
  PATIENTNAME AS patient_name,

  CASE
    WHEN strpos (CONSULTDATE, '/') > 0
      THEN to_char(convert_to_date(CONSULTDATE, 'DD/MM/YYYY'), 'YYYY-MM-DD')
    ELSE CONSULTDATE
  END AS appointment_date,

  CASE
    WHEN strpos(ENTRYDATE, '/') > 0
      THEN to_char(convert_to_date(ENTRYDATE, 'DD/MM/YYYY'), 'YYYY-MM-DD')
    ELSE ENTRYDATE
  END AS created_at,
  convert_to_decimal(AMOUNT) AS amount,

  NULLIF(DESCRIPTION1, '') AS description,
  NULLIF(COMMENT, '') AS comment,

  CASE WHEN TRANSTYPE = 4
    THEN convert_to_integer(NULLIF(NULLIF(REFERENCE::TEXT, ''), '0'))
    ELSE NULL
  END AS write_off_patient_id,

  NULLIF(STATEMENTNUMBER, 0) AS statement_number,

  CONSULTDRNUMBER AS practitioner_id,
  BILLDRNUMBER AS billed_to_practitioner_id,

  CASE WHEN DELETED = -1
    THEN TRUE
    ELSE FALSE
  END AS is_deleted,
  CASE
    WHEN strpos(DELETEDATE, '-') > 0
      THEN to_char(convert_to_date(DELETEDATE, 'YYYY-MM-DD')
      +
      CASE
		    WHEN DELETETIME ~ '^\\d{1,2}:\\d{2}:\\d{2} (AM|PM)$'
		      THEN NULLIF(TRIM(DELETETIME), '')::TIME
		    ELSE
		      NULL
		  END,
      'YYYY-MM-DD HH:MI:SS')
    ELSE to_char(
      convert_to_date(DELETEDATE, 'DD/MM/YYYY')
      +
      CASE
		    WHEN DELETETIME ~ '^\\d{1,2}:\\d{2}:\\d{2} (AM|PM)$'
		      THEN NULLIF(TRIM(DELETETIME), '')::TIME
		    ELSE
		      NULL
		  END,
      'YYYY-MM-DD HH:MI:SS'
    )
  END AS deleted_at,

  CASE WHEN SHOWINBANKING = -1
    THEN TRUE
    ELSE FALSE
  END AS show_in_banking,

  USERNUMBER AS user_id,
  NULLIF(NULLIF(USERTIME, ''), 'NULL')::TIME AS user_time,
  convert_to_integer(LOCNO) AS location_id,

  CASE WHEN THIRDPARTYPAYMENT = 0
    THEN FALSE
    ELSE TRUE
  END AS is_third_party_payment,
  convert_to_boolean(SHOWINGST) AS show_in_gst
FROM PBARCMAS
WHERE
  convert_to_integer(TRANSTYPE) = 4
`;

export class PatientWriteOffSourceEntity extends BaseSourceEntity<
  IOasisPatientWriteOff,
  IOasisPatientWriteOffTranslations,
  IOasisPatientWriteOffFilters
> {
  sourceEntity = PATIENT_WRITE_OFF_SOURCE_ENTITY;
  entityResourceType = PATIENT_WRITE_OFF_RESOURCE_TYPE;
  sourceQuery = PATIENT_WRITE_OFF_SOURCE_QUERY;
  verifySourceFn = isOasisPatientWriteOff;
  override defaultOffsetSize = 50000;
  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertDateToTimestampFn(),
    convertValueFn(toFloat, 'amount'),
  ]);

  translate(
    data: IOasisPatientWriteOff,
    timezone: Timezone
  ): IOasisPatientWriteOffTranslations {
    return {
      createdAt: toTimestamp(moment.tz(data.createdAt, timezone)),
      appointmentDate: toISODate(moment.tz(data.appointmentDate, timezone)),
      deletedAt: data.deletedAt
        ? toTimestamp(moment.tz(data.deletedAt, timezone))
        : undefined,
    };
  }

  getSourceRecordId(data: IOasisPatientWriteOff): number {
    return data.id;
  }

  getSourceLabel(data: IOasisPatientWriteOff): string {
    return data.id.toString();
  }

  getFilterData(
    data: IOasisPatientWriteOff,
    _timezone: Timezone
  ): IOasisPatientWriteOffFilters {
    return {
      accountPatientId: data.accountPatientId,
    };
  }
}
