import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { PrincipleSettingsUpdate } from '@principle-theorem/principle-bridge-core';
import { isObject } from '@principle-theorem/shared';
import { JsonEditorOptions } from 'ang-jsoneditor';

export interface IEditBridgeSettingsRequest {
  settings: PrincipleSettingsUpdate;
}

@Component({
    selector: 'pr-edit-bridge-settings-dialog',
    templateUrl: './edit-bridge-settings-dialog.component.html',
    styleUrls: ['./edit-bridge-settings-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditBridgeSettingsDialogComponent {
  form = new TypedFormGroup<IEditBridgeSettingsRequest>({
    settings: new TypedFormControl<PrincipleSettingsUpdate>(),
  });
  settingsCtrl = new TypedFormControl<object>().withGuard(isObject);
  editorOptions = new JsonEditorOptions();

  constructor(
    private _dialogRef: MatDialogRef<
      EditBridgeSettingsDialogComponent,
      IEditBridgeSettingsRequest
    >,
    @Inject(MAT_DIALOG_DATA) public data: Partial<IEditBridgeSettingsRequest>
  ) {
    this.editorOptions.expandAll = true;
    if (data.settings) {
      this.form.patchValue(data);
    }
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }
    this._dialogRef.close(this.form.getRawValue());
  }
}
