import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TagTextColourPipe } from './tag-text-colour.pipe';
import { TimeDisplayPipe } from './time-display.pipe';
import { InvoiceUrlPipe } from './invoice-url.pipe';
import { PatientUrlPipe } from './patient-url.pipe';
import { EventTypeIconPipe } from './event-type-icon.pipe';
import { DurationPipe } from './duration.pipe';
import { MomentTimezonePipe } from './moment-timezone.pipe';
import { TimingDisplayPipe } from './timing-display.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    TagTextColourPipe,
    TimeDisplayPipe,
    InvoiceUrlPipe,
    PatientUrlPipe,
    EventTypeIconPipe,
    DurationPipe,
    MomentTimezonePipe,
    TimingDisplayPipe,
  ],
  exports: [
    TagTextColourPipe,
    TimeDisplayPipe,
    InvoiceUrlPipe,
    PatientUrlPipe,
    EventTypeIconPipe,
    DurationPipe,
    MomentTimezonePipe,
    TimingDisplayPipe,
  ],
})
export class PipesModule {}
