import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { type IConditionConfiguration } from '@principle-theorem/principle-core/interfaces';
import {
  CurrentBrandScope,
  CurrentStafferScope,
} from '@principle-theorem/ng-principle-shared';
import { type IBreadcrumb } from '@principle-theorem/ng-shared';
import {
  filterUndefined,
  findProp,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-condition-config-edit',
    templateUrl: './condition-config-edit.component.html',
    styleUrls: ['./condition-config-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConditionConfigEditComponent {
  conditionConfig$: Observable<WithRef<IConditionConfiguration>>;
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(
    private _route: ActivatedRoute,
    private _currentBrand: CurrentBrandScope,
    private _currentStaffer: CurrentStafferScope
  ) {
    this.conditionConfig$ = this._route.data.pipe(
      findProp<WithRef<IConditionConfiguration>>('conditionConfig'),
      filterUndefined()
    );

    this.breadcrumbs$ = combineLatest([
      this._currentBrand.doc$.pipe(filterUndefined()),
      this._currentStaffer.doc$,
      this.conditionConfig$,
    ]).pipe(
      map(([brand, staffer, conditionConfig]) => {
        if (staffer) {
          return [
            { label: 'Settings', path: '../../../../../' },
            { label: brand.name },
            { label: 'My Account' },
            { label: 'Condition Configurations', path: '../' },
            { label: conditionConfig.name },
          ];
        }
        return [
          { label: 'Settings', path: '../../../../../' },
          { label: brand.name },
          { label: 'Condition Configurations', path: '../' },
          { label: conditionConfig.name },
        ];
      })
    );
  }
}
