import { type Timestamp } from './adaptor';
import { initTimestamps } from '../timestamp';

export interface IFirestoreModel extends ISoftDelete {
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface ISoftDelete {
  deleted: boolean;
}

export function initFirestoreModel(): IFirestoreModel {
  return {
    ...initTimestamps(),
    deleted: false,
  };
}

export type FirestoreModelConstructor<T> = { new (): T };
