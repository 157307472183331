import { Pipe, type PipeTransform } from '@angular/core';
import { splitCamel } from '@principle-theorem/shared';

@Pipe({
  name: 'splitCamel',
})
export class SplitCamelPipe implements PipeTransform {
  transform(value: string): string {
    return splitCamel(value);
  }
}
