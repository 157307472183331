@if (breakpoint.isMobile$ | async) {
  <mat-toolbar class="accent min-h-[70px]">
    <div class="accent flex h-full w-full items-center justify-between">
      <div class="relative h-full w-[115px]">
        <img ngSrc="/assets/icons/principle-row-white.svg" priority fill />
      </div>
      <button mat-icon-button class="!text-white" (click)="toggleMenu()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  @if (showMobileMenu$ | async) {
    <mat-list>
      <mat-list-item>
        <a routerLinkActive="active-link" routerLink="/login">Sign In</a>
      </mat-list-item>
      <mat-list-item>
        <a routerLinkActive="active-link" routerLink="/register">Register</a>
      </mat-list-item>
    </mat-list>
  }
} @else {
  <mat-toolbar *ngrxLet="org.user$ | async as user" class="accent min-h-[90px]">
    <div
      class="accent container mx-auto flex h-full max-w-7xl justify-between px-8 xl:px-0"
    >
      <div class="relative h-full w-[175px]" [ngClass]="{ 'mx-auto': user }">
        <img ngSrc="/assets/icons/principle-row-white.svg" priority fill />
      </div>
      <div *ngIf="!user" class="desktop-menu flex items-center gap-10">
        <a routerLinkActive="active-link" routerLink="/login">Sign In</a>
        <a routerLinkActive="active-link" routerLink="/register">Register</a>
      </div>
    </div>
  </mat-toolbar>
}
