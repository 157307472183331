import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  type CalendarEventEntity,
  filterToPartialDayEvents$,
  filterEventsStartEndOnDay$,
} from '@principle-theorem/ng-calendar/store';
import { EventableTimelineStore } from '@principle-theorem/ng-eventable';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { IScheduleSummaryEventable } from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  getCurrentTime,
  getHoursInDay,
  TimeBucket,
  timePeriodsIntersect,
  toISODate,
  toTimePeriod,
} from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-day-times',
  templateUrl: './day-times.component.html',
  styleUrls: ['./day-times.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayTimesComponent {
  trackByHour = TrackByFunctions.variable<string>();
  trackByEvent = TrackByFunctions.uniqueId<CalendarEventEntity>();
  trackByIndex = TrackByFunctions.index();
  day$ = new ReplaySubject<Moment>(1);
  groupedEvents$: Observable<IScheduleSummaryEventable[][]>;
  currentTime: Moment = getCurrentTime();
  hours: string[] = getHoursInDay();
  isToday$: Observable<boolean>;
  currentTime$: Observable<Moment>;

  @Input()
  set day(day: Moment) {
    if (!day) {
      return;
    }
    this.day$.next(day);
  }

  constructor(public eventableTimelineStore: EventableTimelineStore) {
    this.groupedEvents$ = this.day$.pipe(
      switchMap((day) =>
        this.eventableTimelineStore.events$.pipe(
          map((events) => events[toISODate(day)]),
          filterUndefined(),
          filterToPartialDayEvents$(),
          filterEventsStartEndOnDay$(day)
        )
      ),
      map(this._groupOverlappingEvents)
    );
    this.isToday$ = this.day$.pipe(
      map((day) => day.isSame(this.currentTime, 'day'))
    );
  }

  private _groupOverlappingEvents(
    eventables: IScheduleSummaryEventable[]
  ): IScheduleSummaryEventable[][] {
    const events = eventables.map((eventable) => eventable.event);
    const overlappingTimes = TimeBucket.fromEvents(events)
      .mergeOverlapping()
      .get();
    return overlappingTimes.map((timePeriod) =>
      eventables.filter((eventable) =>
        timePeriodsIntersect(
          timePeriod,
          toTimePeriod(eventable.event.from, eventable.event.to),
          false,
          'minutes'
        )
      )
    );
  }
}
