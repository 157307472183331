import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { type ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MIN_PHONE_NUMBER_LENGTH } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type ILab } from '@principle-theorem/principle-core/interfaces';
import { type Lab } from '@principle-theorem/principle-core';
import { LabFormGroup } from './lab-form-group';

export interface ILabCreateData {
  title: string;
  submitLabel: string;
  lab?: Lab;
}

@Component({
    selector: 'pr-lab-create',
    templateUrl: './lab-create.component.html',
    styleUrls: ['./lab-create.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LabCreateComponent {
  trackByError = TrackByFunctions.field<ValidationErrors>('value');
  minPhoneLength: number = MIN_PHONE_NUMBER_LENGTH;
  labForm: LabFormGroup = new LabFormGroup();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ILabCreateData,
    private _dialogRef: MatDialogRef<LabCreateComponent>
  ) {
    this.labForm.patchValue(this.data.lab ?? {});
  }

  submit(): void {
    if (this.labForm.invalid) {
      return;
    }
    const data: Partial<ILab> = this.labForm.getRawValue();
    this._dialogRef.close(data);
  }
}
