import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import {
  EventType,
  EVENT_TYPES_ICONS,
  IScheduleSummaryEventable,
} from '@principle-theorem/principle-core/interfaces';
import { first } from 'lodash';
import { type Observable, ReplaySubject } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Event } from '@principle-theorem/principle-core';
import { filterUndefined } from '@principle-theorem/shared';
@Component({
  selector: 'pr-gap-header',
  templateUrl: './gap-header.component.html',
  styleUrls: ['./gap-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GapHeaderComponent {
  readonly mediaDiameter: number = 45;
  gap$: ReplaySubject<IScheduleSummaryEventable> = new ReplaySubject(1);
  gapIcon: string = EVENT_TYPES_ICONS[EventType.Gap];
  stafferName$: Observable<string>;
  stafferImage$: Observable<string | undefined>;
  @Input() showPractitionerIcon = false;

  @Input()
  set gap(gap: IScheduleSummaryEventable) {
    if (gap) {
      this.gap$.next(gap);
    }
  }

  constructor(globalStore: GlobalStoreService) {
    const practitioner$ = this.gap$.pipe(
      map((gap) => first(Event.staff(gap.event))),
      filterUndefined()
    );

    this.stafferImage$ = practitioner$.pipe(
      switchMap((practitioner) => globalStore.getStafferImage$(practitioner))
    );
    this.stafferName$ = practitioner$.pipe(
      map((practitioner) => practitioner.name)
    );
  }
}
