<div *ngFor="let chartedItem of data.chartedItems; trackBy: trackByChartedItem">
  <div class="flex items-center">
    <mat-icon
      *ngIf="chartedItem.config.display?.icon.slug"
      class="item-icon"
      [ngStyle]="{ color: chartedItem.config.display.colour.value }"
      [svgIcon]="chartedItem.config.display.icon.slug"
     />

    <div class="label-wrapper flex-1">
      <h4>{{ chartedItem.config.name }}</h4>
      <div class="flex" *ngIf="chartedItem.item | map : getPrice as price">
        <span class="mat-caption">{{ price | currency }}</span>
      </div>
    </div>
  </div>
</div>
