import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
  forwardRef,
} from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {
  type ISODateType,
  toISODate,
  toMoment,
} from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { type Moment, isMoment } from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TypedFormControl } from '../forms/typed-form-group';
import { MOMENT_DATEPICKER_PROVIDERS } from '../moment-datepicker-providers';

@Component({
  selector: 'pt-iso-date-selector',
  templateUrl: './iso-date-selector.component.html',
  styleUrls: ['./iso-date-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IsoDateSelectorComponent),
      multi: true,
    },
    ...MOMENT_DATEPICKER_PROVIDERS,
  ],
})
export class IsoDateSelectorComponent
  implements ControlValueAccessor, OnDestroy
{
  private _onDestroy$ = new Subject<void>();
  private _changed$ = new Subject<ISODateType>();
  private _touched$ = new Subject<void>();

  dateControl = new TypedFormControl<Moment>();

  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() placeholder: string;
  @Input() required = false;
  @Input() max?: Moment;

  constructor() {
    this.dateControl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((date) => {
        this._changed$.next(date ? toISODate(date) : undefined);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  blur(): void {
    const value = this.dateControl.value;
    if (!isMoment(value)) {
      this.dateControl.reset(undefined, { emitEvent: false });
      return;
    }
  }

  setValue(time?: Timestamp | Moment | Date | ISODateType): void {
    if (!time) {
      this.dateControl.reset();
    }
    this._changed$.next(time ? toISODate(time) : undefined);
  }

  writeValue(value?: Timestamp | Moment | Date | ISODateType): void {
    if (!value) {
      this.dateControl.reset(undefined, { emitEvent: false });
      return;
    }
    this.dateControl.setValue(toMoment(value), {
      emitEvent: false,
    });
    this.dateControl.updateValueAndValidity();
  }

  registerOnChange(fn: () => void): void {
    this._changed$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this._touched$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.dateControl.disable();
      return;
    }
    this.dateControl.enable();
  }
}
