import {
  AppointmentContextBuilder,
  FormRequestContextBuilder,
  type IContextResolver,
  InvoiceContextBuilder,
  PatientContextBuilder,
  ScopeContextProvider,
  ScopeContextResolver,
  TreatmentPlanContextBuilder,
  TreatmentPlanMock,
} from '@principle-theorem/principle-core';
import {
  type ITreatmentPlan,
  type ITreatmentStep,
  ServiceTypeModality,
  TemplateScope,
} from '@principle-theorem/principle-core/interfaces';
import {
  AppointmentMock,
  EventMock,
  InvoiceMock,
  PatientMock,
  PracticeMock,
  PractitionerMock,
  UserMock,
} from '@principle-theorem/principle-core';
import {
  ISO_TIME_FORMAT,
  MockGenerator,
  toTimestamp,
} from '@principle-theorem/shared';
import { MockDocRef, MockWithRef } from '@principle-theorem/testing';
import * as moment from 'moment-timezone';

const mockUser = MockGenerator.generate(UserMock);
const mockPatient = MockWithRef(
  MockGenerator.generate(PatientMock, {
    name: 'Allan Sutherland',
    email: 'allan.sutherland@gmail.com',
    address: '11 York St, Berrydale, NSW 2535',
    dateOfBirth: '1980-01-16',
  })
);
const mockPractice = MockWithRef(
  MockGenerator.generate(PracticeMock, {
    name: 'North Sydney Smiles',
    email: 'info@northsydneysmiles.au',
    phone: '02 1234 5678',
  })
);
const mockAppointment = MockWithRef(
  MockGenerator.generate(AppointmentMock, {
    event: MockGenerator.generate(EventMock, {
      from: toTimestamp(
        moment
          .tz('10:00:00', ISO_TIME_FORMAT, mockPractice.settings.timezone)
          .add(1, 'week')
      ),
      to: toTimestamp(
        moment
          .tz('12:00:00', ISO_TIME_FORMAT, mockPractice.settings.timezone)
          .add(1, 'week')
      ),
    }),
    treatmentPlan: {
      name: 'Crown - PFM / Veneered',
      ref: MockDocRef<ITreatmentPlan>(),
      treatmentStep: {
        name: 'Crown Preparation',
        duration: 120,
        ref: MockDocRef<ITreatmentStep>(),
        display: {},
      },
    },
  })
);
const mockPractitioner = MockGenerator.generate(PractitionerMock, {
  providerDetails: [
    {
      practiceRef: mockPractice.ref,
      providerNumber: '3222411A',
      providerModality: ServiceTypeModality.GeneralDentist,
    },
  ],
});
const mockInvoice = MockGenerator.generate(InvoiceMock);
const mockViewInvoiceLink = 'https://app.principle.dental/invoice/9vbv20';
const mockMedicalHistoryLink = 'https://app.principle.dental/history/29cg82';
const mockConfirmationLink = 'https://app.principle.dental/confirm/a9g2jj';
const mockFormsLink =
  'https://app.principle.dental/portal/forms/115f27d8-824a-4a7e-8c3b';
const mockReferrer = {
  name: 'Melanie Green',
  email: 'melanie.green@gmail.com',
  address: '742 Evergreen Terrace, Springfield, NSW',
  jobTitle: 'Solicitor',
};
const mockTreatmentPlan = MockGenerator.generate(TreatmentPlanMock);

export const MOCK_PATIENT_RESOLVER = new ScopeContextProvider(
  TemplateScope.Patient,
  new PatientContextBuilder({
    patient: mockPatient,
    medicalHistoryLink: mockMedicalHistoryLink,
    referrer: mockReferrer,
  })
);

export const MOCK_APPOINTMENT_RESOLVER = new ScopeContextProvider(
  TemplateScope.Appointment,
  new AppointmentContextBuilder({
    appointment: mockAppointment,
    practitioner: mockPractitioner,
    practice: mockPractice,
    patient: mockPatient,
    confirmLink: mockConfirmationLink,
    medicalHistoryLink: mockMedicalHistoryLink,
    referrer: mockReferrer,
  })
);

export const MOCK_INVOICE_RESOLVER = new ScopeContextProvider(
  TemplateScope.Invoice,
  new InvoiceContextBuilder({
    invoice: mockInvoice,
    invoiceLink: mockViewInvoiceLink,
    patient: mockPatient,
    medicalHistoryLink: mockMedicalHistoryLink,
  })
);

export const MOCK_TREATMENT_PLAN_RESOLVER = new ScopeContextProvider(
  TemplateScope.TreatmentPlan,
  new TreatmentPlanContextBuilder({
    treatmentPlan: mockTreatmentPlan,
    patient: mockPatient,
    medicalHistoryLink: mockMedicalHistoryLink,
  })
);

export const MOCK_FORM_REQUEST_RESOLVER = new ScopeContextProvider(
  TemplateScope.FormRequest,
  new FormRequestContextBuilder(mockUser, mockFormsLink)
);

export const MOCK_EMPTY_CONTEXT_PROVIDER = new ScopeContextProvider(
  TemplateScope.None,
  {
    build: () => ({}),
  }
);

export const MOCK_ALL_RESOLVER: IContextResolver = new ScopeContextResolver([
  MOCK_PATIENT_RESOLVER,
  MOCK_APPOINTMENT_RESOLVER,
  MOCK_INVOICE_RESOLVER,
  MOCK_FORM_REQUEST_RESOLVER,
  MOCK_TREATMENT_PLAN_RESOLVER,
  MOCK_EMPTY_CONTEXT_PROVIDER,
]);
