import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'pt-loader-placeholder',
  templateUrl: './loader-placeholder.component.html',
  styleUrls: ['./loader-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LoaderPlaceholderComponent {
  @Input() placeholder: string;
  @Input() loadingPlaceholder = 'Loading...';
  @Input() loading = false;
}
