import { Timestamp } from '@principle-theorem/shared';

export enum DestinationEntityStatus {
  Uninitialised = 'uninitialised',
  Failed = 'failed',
  Migrating = 'migrating',
  Migrated = 'migrated',
  Locked = 'locked',
}

export const DESTINATION_ENTITY_STATUS_MAP: {
  [key in DestinationEntityStatus]: string;
} = {
  [DestinationEntityStatus.Uninitialised]: 'default',
  [DestinationEntityStatus.Migrating]: 'accent',
  [DestinationEntityStatus.Migrated]: 'accent',
  [DestinationEntityStatus.Locked]: 'primary',
  [DestinationEntityStatus.Failed]: 'warn',
};

export type IDestinationEntity = {
  uid: string;
  metadata: IDestinationEntityMetadata;
  size?: number;
  sizeLastCalculatedAt?: Timestamp;
} & (
  | {
      status:
        | DestinationEntityStatus.Uninitialised
        | DestinationEntityStatus.Migrating;
    }
  | {
      status: DestinationEntityStatus.Failed;
      errorMessage: string;
      resumeData?: object;
    }
  | {
      status: DestinationEntityStatus.Migrated | DestinationEntityStatus.Locked;
      lastSync: Timestamp;
    }
);

export interface IDestinationEntityMetadata {
  label: string;
  description: string;
  key: string;
}

export enum DestinationEntityCollection {
  Records = 'destinationRecords',
}
