import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MatFormFieldAppearance,
  SubscriptSizing,
} from '@angular/material/form-field';
import {
  TypedFormControl,
  validFormControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { first } from 'lodash';
import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  type IBrandPractices,
  PracticeSelectorBloc,
} from './practice-selector.bloc';

@Component({
  selector: 'pr-practice-selector',
  templateUrl: './practice-selector.component.html',
  styleUrls: ['./practice-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PracticeSelectorComponent),
      multi: true,
    },
  ],
})
export class PracticeSelectorComponent
  implements OnDestroy, ControlValueAccessor
{
  private _onDestroy$ = new Subject<void>();
  private _brands$ = new ReplaySubject<WithRef<IBrand>[]>(1);
  private _practice$ = new BehaviorSubject<WithRef<IPractice> | undefined>(
    undefined
  );
  bloc = new PracticeSelectorBloc(this._brands$);
  ctrl = new TypedFormControl<WithRef<IPractice>>();
  @Output() practiceSelected = new EventEmitter<WithRef<IPractice>>();
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() label: string = 'Practice';
  @Input() subscriptSizing: SubscriptSizing = 'fixed';

  @Input()
  set practice(practice: WithRef<IPractice>) {
    if (practice) {
      this._practice$.next(practice);
    }
  }

  @Input()
  set brands(brands: WithRef<IBrand>[]) {
    this._brands$.next(brands ?? []);
  }

  constructor() {
    validFormControlChanges$(this.ctrl)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => {
        this.practiceSelected.emit(value);
      });

    combineLatest([this.bloc.groups$, this._practice$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([groups, practice]) =>
        !practice
          ? this._selectDefaultPractice(groups)
          : this.ctrl.setValue(practice)
      );
  }

  writeValue(practice: WithRef<IPractice>): void {
    this.ctrl.setValue(practice);
  }

  registerOnChange(fn: () => void): void {
    validFormControlChanges$(this.ctrl)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(fn);
  }

  registerOnTouched(_fn: () => void): void {
    //
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _selectDefaultPractice(groups: IBrandPractices[]): void {
    const group = first(groups);
    const practice = first(group?.practices);
    if (practice) {
      this.ctrl.setValue(practice);
    }
  }
}
