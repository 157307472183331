import { MixedSchema } from '@principle-theorem/editor';
import {
  DocumentReference,
  ISODateType,
  ISoftDelete,
  Timestamp,
  TypeGuard,
  getEnumValues,
  isDocRef,
} from '@principle-theorem/shared';
import { IPatientMetadataDisplay } from '../patient/patient-metadata';
import { IPractice } from '../practice/practice';
import { IStaffer } from '../staffer/staffer';
import { IPatient } from '../patient/patient';
import { ITreatmentCategory } from '../treatment-category';
import { isBoolean, isNumber, isString } from 'lodash';

export interface ICancellation {
  reason: string;
  comments: MixedSchema;
}

export enum CancellationReason {
  FailedToConfirm = 'Failed to confirm',
  FailedToAttend = 'Failed to attend',
  UnableToAttend = 'Unable to attend',
  FamilyEmergency = 'Family Emergency',
  Sick = 'Sick',
  DoubleBooked = 'Double-booked',
  PracticeCancelled = 'Cancelled by Practice',
  PracticeRescheduled = 'Rescheduled by Practice',
  Other = 'Other',
}

export const CANCELLATION_REASONS = getEnumValues(CancellationReason);

export interface ISchedulingEventReason extends ISoftDelete {
  name: string;
  description: string;
  eventTypes: (SchedulingEventType.Reschedule | SchedulingEventType.Cancel)[];
  hrsBeforeAppointment?: number;
  moveToSameDayDefault: boolean;
  fillGapDefault: boolean;
  metadataKey: string;
  isSystemReason: boolean;
  metadataDisplayRef: DocumentReference<IPatientMetadataDisplay>;
}

export enum SchedulingEventType {
  Schedule = 'schedule',
  Reschedule = 'reschedule',
  Cancel = 'cancel',
}

export interface ISchedulingEventConditions {
  eventType: SchedulingEventType;
  moveToSameDayDefault: boolean;
  fillGapDefault: boolean;
  /**
   * How many hours before the appointment was originally scheduled,
   * will this scheduling event occur?
   */
  hrsBeforeAppointmentWasScheduled?: number;
  /**
   * How many hours before the appointment is being scheduled to,
   * will this scheduling event occur?
   */
  hrsBeforeAppointmentScheduledFor?: number;
}

export interface ISchedulingEventSnapshot {
  practitionerRef: DocumentReference<IStaffer>;
  practiceRef: DocumentReference<IPractice>;
  from: Timestamp;
  to: Timestamp;
}

export interface ISchedulingEvent extends ISoftDelete {
  scheduledAt: Timestamp;
  scheduledByStaffer?: DocumentReference<IStaffer>;
  scheduledByPractice: DocumentReference<IPractice>;
  eventType: SchedulingEventType;
  reason?: DocumentReference<ISchedulingEventReason>;
  reasonSetManually: boolean;
  schedulingConditions: ISchedulingEventConditions;
  eventBefore?: ISchedulingEventSnapshot;
  eventAfter?: ISchedulingEventSnapshot;
}

export interface ISchedulingEventSnapshotSummary {
  date: ISODateType;
  practitionerName: string;
  practiceName: string;
  from: Timestamp;
  to: Timestamp;
}

export interface ISchedulingEventSummary extends ISoftDelete {
  scheduledByPracticeDate?: ISODateType;
  affectsPracticeDates: ISODateType[];

  schedulingEventRef: DocumentReference<ISchedulingEvent>;
  eventType: SchedulingEventType;
  filterValue: string;

  reasonName?: string;
  scheduledByName?: string;
  scheduledAt: Timestamp;
  patientRef: DocumentReference<IPatient>;
  patientName: string;
  treatmentStepName: string;
  treatmentStepCategory?: DocumentReference<ITreatmentCategory>;
  eventBefore?: ISchedulingEventSnapshotSummary;
  eventAfter?: ISchedulingEventSnapshotSummary;
}

export interface ISchedulingEventData
  extends Pick<
    ISchedulingEvent,
    | 'scheduledByPractice'
    | 'reason'
    | 'reasonSetManually'
    | 'schedulingConditions'
  > {
  comments?: MixedSchema;
}

export const isSchedulingEventReason =
  TypeGuard.interface<ISchedulingEventReason>({
    deleted: isBoolean,
    description: isString,
    eventTypes: TypeGuard.arrayOf(TypeGuard.enumValue(SchedulingEventType)),
    hrsBeforeAppointment: TypeGuard.undefinedOr(isNumber),
    isSystemReason: isBoolean,
    metadataDisplayRef: isDocRef,
    metadataKey: isString,
    moveToSameDayDefault: isBoolean,
    name: isString,
    fillGapDefault: isBoolean,
  });
