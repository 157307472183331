import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { IPatientForm } from '@principle-theorem/principle-core/interfaces';
import {
  HISTORY_DATE_TIME_FORMAT,
  WithRef,
  filterUndefined,
  isSameRef,
  sortByUpdatedAt,
} from '@principle-theorem/shared';
import { map, takeUntil } from 'rxjs/operators';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import {
  NgSharedModule,
  TypedFormControl,
  validFormControlChanges$,
} from '@principle-theorem/ng-shared';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { PatientForm } from '@principle-theorem/principle-core';
import { FormStatusComponent } from '../form-status/form-status.component';

@Component({
  selector: 'pr-patient-form-selector',
  imports: [
    CommonModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    NgMaterialModule,
    FormStatusComponent,
  ],
  templateUrl: './patient-form-selector.component.html',
  styleUrl: './patient-form-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex flex-row gap-2 items-center justify-center' },
})
export class PatientFormSelectorComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _latest$: Observable<WithRef<IPatientForm>>;
  readonly dateFormat = HISTORY_DATE_TIME_FORMAT;
  forms$ = new ReplaySubject<WithRef<IPatientForm>[]>(1);
  selected$ = new ReplaySubject<WithRef<IPatientForm>>(1);
  formSelectCtrl = new TypedFormControl<WithRef<IPatientForm>>();
  @Input() label = 'Related Forms';
  @Input() helpTooltip: string =
    'Other forms issued from the same template. Previously submitted Medical History and Patient details forms can be found here.';
  @Output() formSelected = new EventEmitter<WithRef<IPatientForm>>();

  @Input()
  set forms(forms: WithRef<IPatientForm>[]) {
    this.forms$.next((forms ?? []).sort(sortByUpdatedAt));
  }

  @Input()
  set selected(form: WithRef<IPatientForm>) {
    if (form) {
      this.selected$.next(form);
      this.formSelectCtrl.setValue(form, { emitEvent: false });
    }
  }

  constructor() {
    this._latest$ = this.forms$.pipe(
      map((forms) => PatientForm.latest(forms)),
      filterUndefined()
    );

    validFormControlChanges$(this.formSelectCtrl)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((form) => this.formSelected.emit(form));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  compareFn(a: WithRef<IPatientForm>, b: WithRef<IPatientForm>): boolean {
    return a && b ? isSameRef(a, b) : false;
  }

  isLatest$(form: WithRef<IPatientForm>): Observable<boolean> {
    return this._latest$.pipe(map((latest) => isSameRef(form, latest)));
  }
}
