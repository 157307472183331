import { randBoolean, randNumber } from '@ngneat/falso';
import { ITaskCompletedFact } from '@principle-theorem/reporting/interfaces';
import {
  BaseMock,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
} from '@principle-theorem/shared';
import { BrandDimensionMock } from '../dimensions/brand-dimension.mock';
import { SerialisedDocumentReferenceMock } from '../dimensions/common.mock';
import { PracticeDimensionMock } from '../dimensions/practice-dimension.mock';
import { RecurringTaskDimensionMock } from '../dimensions/recurring-task-dimension.mock';
import { StafferDimensionMock } from '../dimensions/staffer-dimension.mock';
import { TaskDimensionMock } from '../dimensions/task-dimension.mock';
import { TeamDimensionMock } from '../dimensions/team-dimension.mock';

export class TaskCompletedFactMock
  extends BaseMock
  implements ITaskCompletedFact
{
  taskRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  ownerRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  assignedUserRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  assignedTeamRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practiceRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  brandRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  task = MockGenerator.generate(TaskDimensionMock);
  recurringConfiguration = MockGenerator.generate(RecurringTaskDimensionMock);
  owner = MockGenerator.generate(StafferDimensionMock);
  assignedUser = MockGenerator.generate(StafferDimensionMock);
  assignedTeam = MockGenerator.generate(TeamDimensionMock);
  brand = MockGenerator.generate(BrandDimensionMock);
  practice = MockGenerator.generate(PracticeDimensionMock);
  timestamp = toSerialisedTimestamp(MockTimestamp());
  isOverdue = randBoolean();
  overdueBy = randNumber({
    min: 1,
    max: 60,
  });
}
