<mat-form-field>
  <mat-label>Assignee</mat-label>
  <mat-select [formControl]="control">
    <mat-optgroup *ngIf="staff$ | async as staff" label="Staff">
      <mat-option
        *ngFor="let staffer of staff; trackBy: trackByStaffer"
        [value]="staffer"
      >
        {{ staffer.user.name }}
      </mat-option>
    </mat-optgroup>
    <mat-optgroup *ngIf="teams$ | async as teams" label="Teams">
      <mat-option
        *ngFor="let team of teams; trackBy: trackByTeam"
        [value]="team"
      >
        {{ team.name }}
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>
