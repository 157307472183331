import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  filterTableBySide,
  type IPerioTable,
  type IPerioTableCell,
  PerioTableSide,
} from '../perio-table/perio-table';

@Component({
  selector: 'pr-perio-chart-display',
  templateUrl: './perio-chart-display.component.html',
  styleUrls: ['./perio-chart-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerioChartDisplayComponent {
  private _perioTable$: ReplaySubject<IPerioTable> = new ReplaySubject(1);
  facial$: Observable<IPerioTable>;
  palatal$: Observable<IPerioTable>;
  label$: Observable<string>;
  @Output()
  perioCellChange = new EventEmitter<IPerioTableCell>();

  constructor() {
    this.label$ = this._perioTable$.pipe(map((table) => table.label || ''));
    this.facial$ = this._perioTable$.pipe(
      filterTableBySide(PerioTableSide.Facial)
    );
    this.palatal$ = this._perioTable$.pipe(
      filterTableBySide(PerioTableSide.Palatal)
    );
  }

  @Input()
  set perioTable(perioTable: IPerioTable) {
    if (perioTable) {
      this._perioTable$.next(perioTable);
    }
  }
}
