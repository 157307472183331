import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import { ContactNumberControls } from '@principle-theorem/ng-principle-shared';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TrackByFunctions,
  TypedFormArray,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { ReferralSource } from '@principle-theorem/principle-core';
import {
  GENDERS,
  IContactNumber,
  IHealthFundCard,
  IPatient,
  IReferralSource,
  IReferralSourceConfiguration,
  PatientOptionalField,
  type Gender,
  IMedicareCard,
  IDVACard,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { PatientDetailsFormDataGroup } from './patient-details-form-group';
import { isEmpty } from 'lodash';

@Component({
  selector: 'pr-patient-details-form',
  templateUrl: './patient-details-form.component.html',
  styleUrls: ['./patient-details-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
})
export class PatientDetailsFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByIndex = TrackByFunctions.index<TypedFormGroup<IContactNumber>>();
  trackByGender = TrackByFunctions.variable<Gender>();
  trackByReferralSource =
    TrackByFunctions.ref<WithRef<IReferralSourceConfiguration>>();
  patientDetails$ = new ReplaySubject<IPatient>(1);
  referralSources$ = new ReplaySubject<WithRef<IReferralSourceConfiguration>[]>(
    1
  );
  requiredFields$ = new ReplaySubject<PatientOptionalField[]>(1);
  form = new PatientDetailsFormDataGroup();
  genders = GENDERS;
  today = moment();
  contactNumberControls: ContactNumberControls;

  @Input()
  set referralSources(
    referralSources: WithRef<IReferralSourceConfiguration>[]
  ) {
    if (referralSources) {
      this.referralSources$.next(referralSources);
    }
  }

  @Input()
  set requiredFields(requiredFields: PatientOptionalField[]) {
    if (requiredFields) {
      this.requiredFields$.next(requiredFields);
    }
  }

  @Input()
  set patientDetails(patientDetails: IPatient) {
    if (patientDetails) {
      this.patientDetails$.next(patientDetails);
    }
  }

  @Output() patientDetailsChange = new EventEmitter<Partial<IPatient>>();

  constructor() {
    this.contactNumberControls = new ContactNumberControls(
      this.form.controls.contactNumbers as TypedFormArray<IContactNumber>
    );
    this.patientDetails$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((patient) => {
        this.contactNumberControls.initContactNumberControls(patient, {
          emitEvent: false,
        });

        const address = !isEmpty(patient.metadata?.address)
          ? patient.metadata?.address
          : { address: patient.address };

        this.form.patchValue(
          {
            ...patient,
            address,
          },
          { emitEvent: false }
        );
      });

    this.form.valueChanges
      .pipe(debounceTime(500), takeUntil(this._onDestroy$))
      .subscribe(() => this.submit());
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  get healthFundCard(): TypedFormGroup<IHealthFundCard> {
    return this.form.controls.healthFundCard as TypedFormGroup<IHealthFundCard>;
  }

  get medicareCard(): TypedFormGroup<IMedicareCard> {
    return this.form.controls.medicareCard as TypedFormGroup<IMedicareCard>;
  }

  get dvaCard(): TypedFormGroup<IDVACard> {
    return this.form.controls.dvaCard as TypedFormGroup<IDVACard>;
  }

  submit(): void {
    if (this.form.valid) {
      const data = this.form.value;
      this.patientDetailsChange.emit({
        ...this.form.value,
        address: data.address?.address ?? '',
        metadata: {
          address: data.address,
        },
      });
      return;
    }
    this.patientDetailsChange.emit(undefined);
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
  }

  toReferralSource(
    referrer: WithRef<IReferralSourceConfiguration>
  ): IReferralSource {
    return ReferralSource.toReferrer(referrer);
  }

  isRequired$(field: PatientOptionalField): Observable<boolean> {
    return this.requiredFields$.pipe(
      map((requiredFields) => requiredFields.includes(field))
    );
  }
}
