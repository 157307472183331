import {
  MixedSchema,
  isMixedSchema,
  type RawInlineNodes,
  isRawInlineNodes,
} from '@principle-theorem/editor';
import {
  TypeGuard,
  isINamedDocument,
  isObject,
  type DocumentReference,
  type IFirestoreModel,
  type INamedDocument,
  type RequireProps,
  WithRef,
} from '@principle-theorem/shared';
import { isBoolean } from 'lodash';
import { type ICandidate } from '../gap/candidate';
import { type ISchedule } from '../schedule/schedule';
import { type ScheduleTime } from '../schedule/schedule-time';
import { type ITag } from '../tag/tag';
import {
  isEvent,
  type EventTemplate,
  type IEvent,
  type IEventHistory,
  EventType,
} from './event';

export interface ILockableEventable {
  locked: boolean;
}

export function isLockableEvent(item: unknown): item is ILockableEventable {
  return isObject(item) && 'locked' in item && typeof item.locked === 'boolean';
}

export interface ICalendarEvent extends IFirestoreModel, ILockableEventable {
  event: IEvent;
  title: RawInlineNodes;
  notes: MixedSchema;
  eventHistory: IEventHistory[];
  eventTags: INamedDocument<ITag>[];
  isBlocking: boolean;
  scheduleRef?: DocumentReference<ICalendarEventSchedule>;
}

export function isCalendarEventType(eventType: EventType): boolean {
  return [
    EventType.Break,
    EventType.Leave,
    EventType.Meeting,
    EventType.Misc,
    EventType.PracticeClosed,
    EventType.PreBlock,
    EventType.RosteredOn,
  ].includes(eventType);
}

export function isCalendarEvent(item: unknown): item is ICalendarEvent {
  return (
    isObject(item) &&
    'event' in item &&
    isEvent(item.event) &&
    'title' in item &&
    item.title !== undefined &&
    isObject(item.title) &&
    isRawInlineNodes(item.title) &&
    'notes' in item &&
    item.notes !== undefined &&
    isMixedSchema(item.notes) &&
    isLockableEvent(item) &&
    'eventTags' in item &&
    TypeGuard.arrayOf(isINamedDocument)(item.eventTags) &&
    'isBlocking' in item &&
    isBoolean(item.isBlocking)
  );
}

export interface ICandidateCalendarEvent extends ICalendarEvent {
  offerMade: boolean;
  candidate: ICandidate;
}

export function isCandidateCalendarEvent(
  item: unknown
): item is ICandidateCalendarEvent {
  return (
    isObject(item) &&
    'offerMade' in item &&
    typeof item.offerMade === 'boolean' &&
    'title' in item &&
    typeof item.title === 'string' &&
    'candidate' in item &&
    isCalendarEvent(item)
  );
}

export interface ICalendarEventSchedule
  extends Omit<ISchedule<ICalendarEventTemplate>, 'scheduleTime'> {
  scheduleTime: ScheduleTime;
}

export interface ICalendarEventTemplate extends Omit<ICalendarEvent, 'event'> {
  event: EventTemplate;
}

export type CalendarEventFromSchedule = RequireProps<
  ICalendarEvent,
  'scheduleRef'
>;

export function isCalendarEventFromSchedule(
  event: ICalendarEvent
): event is CalendarEventFromSchedule {
  return !!event.scheduleRef;
}

export interface ICalendarEventSchedulesMap {
  [stafferId: string]: WithRef<ICalendarEventSchedule>[] | undefined;
}
