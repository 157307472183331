import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CurrentBrandScope,
  GlobalStoreService,
} from '@principle-theorem/ng-principle-shared';
import { BasicDialogService } from '@principle-theorem/ng-shared';
import { DeleteConfigurationManager } from '@principle-theorem/principle-core';
import {
  type IBrand,
  type IGeneralTreatmentConfiguration,
  type ITreatmentCategory,
  type ITreatmentConfiguration,
  TemplateScope,
} from '@principle-theorem/principle-core/interfaces';
import { saveDoc, snapshot, type WithRef } from '@principle-theorem/shared';
import { isError } from 'lodash';
import { type Observable, ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-edit-treatment-configuration',
  templateUrl: './edit-treatment-configuration.component.html',
  styleUrls: ['./edit-treatment-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditTreatmentConfigurationComponent {
  treatmentConfig$ = new ReplaySubject<WithRef<ITreatmentConfiguration>>(1);
  brand$ = new ReplaySubject<WithRef<IBrand>>(1);
  pendingGeneralConfig?: IGeneralTreatmentConfiguration;
  automatedNotificationScope = TemplateScope.Appointment;
  treatmentCategories$: Observable<WithRef<ITreatmentCategory>[]>;

  @Input()
  set brand(brand: WithRef<IBrand>) {
    if (brand) {
      this.brand$.next(brand);
    }
  }

  @Input()
  set treatmentConfig(treatmentConfig: WithRef<ITreatmentConfiguration>) {
    if (treatmentConfig) {
      this.treatmentConfig$.next(treatmentConfig);
    }
  }

  constructor(
    private _snackBar: MatSnackBar,
    private _brandScope: CurrentBrandScope,
    private _dialog: BasicDialogService,
    private _route: ActivatedRoute,
    private _router: Router,
    public globalStore: GlobalStoreService
  ) {}

  async save(): Promise<void> {
    if (!this.pendingGeneralConfig) {
      return;
    }
    const treatmentConfig = await snapshot(this.treatmentConfig$);
    await saveDoc({ ...treatmentConfig, ...this.pendingGeneralConfig });
    this._snackBar.open('Treatment configuration saved');
  }

  async delete(): Promise<void> {
    const confirmed = await this._dialog.confirm({
      prompt:
        'Are you sure you want to delete this treatment configuration? This will no longer be available for clinical charting and cannot be undone.',
      title: 'Delete',
      submitLabel: 'Yes, Delete',
      submitColor: 'warn',
      cancelLabel: 'Cancel',
    });

    if (!confirmed) {
      return;
    }
    const config = await snapshot(this.treatmentConfig$);
    const brand = await this._brandScope.toPromise();
    const manager = new DeleteConfigurationManager(brand);
    try {
      await manager.delete(config);
    } catch (error) {
      if (isError(error)) {
        this._snackBar.open(error.message);
        return;
      }
      this._snackBar.open('Error deleting configuration');
      return;
    }
    this._snackBar.open('Configuration Deleted');
    await this._router.navigate(['../'], {
      relativeTo: this._route,
    });
  }
}
