import { NgModule } from '@angular/core';
import { CoreModule } from 'ng-flex-layout';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NgTyroModule } from '@principle-theorem/ng-tyro';
import { TransactionComponentsModule } from '../transaction-components/transaction-components.module';
import { EasyclaimBuilder } from './easyclaim/easyclaim-builder.service';
import { TyroEasyclaimBulkBillTransactionProvider } from './easyclaim/tyro-easyclaim-bulk-bill-transaction-provider.service';
import { TyroEasyclaimFullyPaidTransactionProvider } from './easyclaim/tyro-easyclaim-fully-paid-transaction-provider.service';
import { TyroEasyclaimPartPaidTransactionProvider } from './easyclaim/tyro-easyclaim-part-paid-transaction-provider.service';
import { HealthPointBuilder } from './health-point/health-point-builder.service';
import { TyroHealthPointTransactionProvider } from './health-point/tyro-health-point-transaction-provider.service';
import { PurchaseBuilder } from './purchase/purchase-builder.service';
import { TyroPurchaseTransactionProvider } from './purchase/tyro-purchase-transaction-provider.service';
import { SelectTyroTerminalComponent } from './select-tyro-terminal/select-tyro-terminal.component';
import { SelectTyroTerminalService } from './select-tyro-terminal/select-tyro-terminal.service';
import { TyroExtendedDataComponent } from './tyro-extended-data/tyro-extended-data.component';

@NgModule({
  imports: [
    TransactionComponentsModule,
    NgPrincipleSharedModule,
    CoreModule,
    NgTyroModule,
    NgSharedModule,
  ],
  providers: [
    EasyclaimBuilder,
    HealthPointBuilder,
    PurchaseBuilder,
    SelectTyroTerminalService,
    TyroHealthPointTransactionProvider,
    TyroPurchaseTransactionProvider,
    TyroEasyclaimBulkBillTransactionProvider,
    TyroEasyclaimFullyPaidTransactionProvider,
    TyroEasyclaimPartPaidTransactionProvider,
  ],
  declarations: [SelectTyroTerminalComponent, TyroExtendedDataComponent],
})
export class TyroTransactionModule {}
