/**
 * Represents what context is required for a template.
 */
export enum TemplateScope {
  None = 'none',
  Patient = 'patient',
  Appointment = 'appointment',
  Invoice = 'invoice',
  GapOffer = 'gap-offer',
  TreatmentPlan = 'treatment-plan',
}

export const TEMPLATE_SCOPES: TemplateScope[] = [
  TemplateScope.None,
  TemplateScope.Patient,
  TemplateScope.Appointment,
  TemplateScope.Invoice,
  TemplateScope.GapOffer,
  TemplateScope.TreatmentPlan,
];
