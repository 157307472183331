import { Pipe, type PipeTransform } from '@angular/core';
import {
  type EventIconMap,
  EVENT_TYPES_ICONS,
} from '@principle-theorem/principle-core/interfaces';

@Pipe({
  name: 'eventTypeIcon',
})
export class EventTypeIconPipe implements PipeTransform {
  readonly eventIcons: EventIconMap = EVENT_TYPES_ICONS;

  transform(type: keyof EventIconMap): string {
    return this.eventIcons[type];
  }
}
