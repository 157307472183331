<button
  mat-icon-button
  matTooltip="Sort Wait List"
  [matMenuTriggerFor]="sortByMenu"
>
  <mat-icon>sort</mat-icon>
</button>

<mat-menu #sortByMenu yPosition="above">
  <mat-list>
    @for (option of sortOptions; track option) {
      <mat-list-item
        *ngrxLet="option | map: isSelected$ : this as isSelected"
        class="!cursor-pointer hover:bg-gray-100"
        (click)="$event.stopPropagation(); selectOption(option)"
      >
        <div class="flex items-center gap-1">
          <mat-icon
            class="!text-primary-500 text-sm font-bold"
            [class.invisible]="!isSelected"
          >
            check
          </mat-icon>
          <div [class.text-primary-500]="isSelected">
            {{ option | splitCamel | titlecase }}
          </div>
        </div>
      </mat-list-item>
    }
  </mat-list>
</mat-menu>
