import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  OptionGroupSearchFilter,
  TypedFormControl,
  TypedFormGroup,
  toSearchStream,
} from '@principle-theorem/ng-shared';
import { ICustomReportChart } from '@principle-theorem/principle-core/interfaces';
import {
  CanBeChartedProperty,
  ICanGroupMeasuresProperty,
} from '@principle-theorem/reporting';
import { multiMap } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IGroupBySection,
  IGroupBySectionOption,
} from '../../../../models/report-builder-data-sources/report-builder-data-source';
import { ReportBuilderStore } from '../../report-builder.store';

export interface IReportBuilderEditGroupByRequest {
  groupBys: IGroupBySection[];
  chart: ICustomReportChart;
}

export interface IReportBuilderEditGroupByResponse {
  groupBy: ICanGroupMeasuresProperty;
}

@Component({
  selector: 'pr-report-builder-edit-group-by-dialog',
  templateUrl: './report-builder-edit-group-by-dialog.component.html',
  styleUrls: ['./report-builder-edit-group-by-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportBuilderEditGroupByDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  form = new TypedFormGroup<IReportBuilderEditGroupByResponse>({
    groupBy: new TypedFormControl(undefined, Validators.required),
  });
  groupBySearchCtrl = new TypedFormControl<string>();
  groupBySearch: OptionGroupSearchFilter<IGroupBySectionOption>;
  resolvedGroupBySections$: Observable<IGroupBySection[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IReportBuilderEditGroupByRequest,
    public _dialogRef: MatDialogRef<
      ReportBuilderEditGroupByDialogComponent,
      IReportBuilderEditGroupByResponse
    >,
    private _store: ReportBuilderStore,
    public store: ReportBuilderStore
  ) {
    this.resolvedGroupBySections$ = this._store.dataSource$.pipe(
      map((dataSource) => dataSource?.groupByOptions ?? [])
    );

    this.groupBySearch = new OptionGroupSearchFilter<IGroupBySectionOption>(
      this.resolvedGroupBySections$.pipe(
        multiMap((section) => ({
          ...section,
          skipFilter: false,
        }))
      ),
      toSearchStream(this.groupBySearchCtrl),
      ['property.metadata.label', 'property.metadata.summary']
    );

    const chart = data.chart;
    const groupBy = data.groupBys
      .flatMap((section) => section.options)
      .find(
        (availableGroupBy) =>
          availableGroupBy.measure.metadata.id === chart.groupBy
      )?.measure;
    this.form.patchValue({
      groupBy,
    });

    this.groupBySearchCtrl.setValue(groupBy?.metadata.label ?? '');
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }
    this._dialogRef.close(this.form.value);
  }

  compareFn(
    columnA: CanBeChartedProperty,
    columnB: CanBeChartedProperty
  ): boolean {
    if (!columnA || !columnB) {
      return false;
    }
    return columnA.metadata.id === columnB.metadata.id;
  }

  displayFn(value?: CanBeChartedProperty | string): string {
    if (!value) {
      return '';
    }

    if (isString(value)) {
      return value;
    }

    return value.metadata.label;
  }

  groupBySelected(event: MatAutocompleteSelectedEvent): void {
    if (!event.option.value) {
      return;
    }
    this.form.controls.groupBy.setValue(event.option.value);
  }

  blurGroupBy(): void {
    if (isString(this.groupBySearchCtrl.value)) {
      this.groupBySearchCtrl.setValue('', { emitEvent: false });
      this.form.controls.groupBy.setValue(undefined);
    }
  }
}
