import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DateService } from '@principle-theorem/ng-principle-shared';
import { Interaction } from '@principle-theorem/principle-core';
import {
  ISchedulingEvent,
  InteractionType,
  isInteractionV2,
  type IInteraction,
  type IInteractionV2,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  of,
  type Observable,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-interaction',
    templateUrl: './interaction.component.html',
    styleUrls: ['./interaction.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InteractionComponent {
  pinnableTypes$ = new BehaviorSubject<InteractionType[]>([
    InteractionType.Note,
    InteractionType.Call,
  ]);
  isCompact$ = new BehaviorSubject<boolean>(false);
  editable$ = new BehaviorSubject<boolean>(false);
  interaction$ = new ReplaySubject<IInteraction | WithRef<IInteractionV2>>(1);
  tags$: Observable<WithRef<ITag>[]>;
  @Output() interactionUpdated = new EventEmitter<
    IInteraction | WithRef<IInteractionV2>
  >();
  @Output() interactionDeleted = new EventEmitter<void>();
  schedulingEvent$: Observable<WithRef<ISchedulingEvent> | undefined>;
  hasComments$: Observable<boolean>;
  hasContent$: Observable<boolean>;

  @Input()
  set pinnableTypes(pinnableTypes: InteractionType[]) {
    if (pinnableTypes) {
      this.pinnableTypes$.next(pinnableTypes);
    }
  }

  @Input()
  set editable(editable: BooleanInput) {
    this.editable$.next(coerceBooleanProperty(editable));
  }

  @Input()
  set compact(compact: BooleanInput) {
    this.isCompact$.next(coerceBooleanProperty(compact));
  }

  @Input()
  set interaction(interaction: IInteraction | WithRef<IInteractionV2>) {
    if (interaction) {
      this.interaction$.next(interaction);
    }
  }

  constructor(public dateService: DateService) {
    this.hasComments$ = this.interaction$.pipe(
      map((interaction) => Interaction.hasContent(interaction))
    );

    this.tags$ = this.interaction$.pipe(
      switchMap((interaction) =>
        isInteractionV2(interaction) ? Interaction.tags$(interaction) : of([])
      )
    );
    this.schedulingEvent$ = this.interaction$.pipe(
      switchMap((interaction) => Interaction.getSchedulingEvent$(interaction))
    );

    this.hasContent$ = combineLatest([
      this.hasComments$,
      this.tags$,
      this.schedulingEvent$,
    ]).pipe(
      map(
        ([hasComments, tags, schedulingEventReason]) =>
          hasComments || tags.length > 0 || !!schedulingEventReason
      )
    );
  }

  update(
    interaction: IInteraction | WithRef<IInteractionV2>,
    changes: Partial<IInteraction | WithRef<IInteractionV2>>
  ): void {
    this.interactionUpdated.emit({
      ...interaction,
      ...changes,
    });
  }
}
