import {
  IScheduleSummaryUpsertAction,
  type ICalendarEventSchedule,
  type IPractice,
  type IScheduleSummary,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  FirestoreTransactionHelper,
  Transaction,
  WithRef,
  asyncForEach,
  isSameRef,
  orderBy,
  overlappingChunkRange,
  serialise,
  toISODate,
  toMomentTz,
  toQuery,
  where,
} from '@principle-theorem/shared';
import { compact, flatten, isEqual, uniqBy } from 'lodash';
import moment from 'moment-timezone';
import { OrganisationCache } from '../organisation/organisation-cache';
import { Practice } from '../practice/practice';
import {
  SCHEDULE_SUMMARY_SEED_RANGE_IN_MONTHS,
  ScheduleSummaryHelpers,
} from './schedule-summary-helpers';

export class ScheduleSummaryRosterUpdater {
  static async update(
    from: moment.Moment,
    stafferRef: DocumentReference<IStaffer>,
    practiceRef: DocumentReference<IPractice>,
    transaction: Transaction,
    before?: WithRef<ICalendarEventSchedule>,
    after?: WithRef<ICalendarEventSchedule>
  ): Promise<IScheduleSummaryUpsertAction[]> {
    const practice = await Firestore.getDoc(practiceRef);
    const staffer = await OrganisationCache.staff.get.getDoc(stafferRef);
    const timezone = practice.settings.timezone;
    const seedToDay = toMomentTz(from, timezone).add(
      SCHEDULE_SUMMARY_SEED_RANGE_IN_MONTHS,
      'months'
    );
    const seedRange = {
      from: toMomentTz(from, timezone),
      to: seedToDay,
    };

    const aggregatesOutsideSeedRange =
      await ScheduleSummaryRosterUpdater.getAffectedAggregatesOutsideSeedRange(
        seedToDay.clone().tz(timezone).add(1, 'day'),
        stafferRef,
        transaction,
        before,
        after
      );
    const range = [
      ...overlappingChunkRange(seedRange, 1, 'day'),
      ...aggregatesOutsideSeedRange.map((aggregate) => ({
        from: toMomentTz(aggregate.day, timezone).startOf('day'),
        to: toMomentTz(aggregate.day, timezone).endOf('day'),
      })),
    ];

    return asyncForEach(range, async (day) =>
      ScheduleSummaryHelpers.rebuildAggregate(
        day.from,
        practice,
        staffer,
        transaction
      )
    );
  }

  static async getAffectedAggregatesOutsideSeedRange(
    from: moment.Moment,
    stafferRef: DocumentReference<IStaffer>,
    transaction: Transaction,
    before?: WithRef<ICalendarEventSchedule>,
    after?: WithRef<ICalendarEventSchedule>
  ): Promise<WithRef<IScheduleSummary>[]> {
    const practiceRefs = compact([
      before?.item.event.practice.ref,
      after?.item.event.practice.ref,
    ]);
    return ScheduleSummaryRosterUpdater.getAllPracticeSchedules(
      from,
      stafferRef,
      practiceRefs,
      transaction
    );
  }

  static async getPracticeScheduleSummaries(
    from: moment.Moment,
    stafferRef: DocumentReference<IStaffer>,
    practiceRef: DocumentReference<IPractice>,
    transaction: Transaction
  ): Promise<WithRef<IScheduleSummary>[]> {
    const fromDay = toISODate(from);
    const query = toQuery(
      Practice.scheduleSummaryCol({ ref: practiceRef }),
      where('staffer', '==', stafferRef),
      where('day', '>=', fromDay),
      orderBy('day', 'desc')
    );

    return FirestoreTransactionHelper.getDocs(query, transaction);
  }

  static async getAllPracticeSchedules(
    from: moment.Moment,
    stafferRef: DocumentReference<IStaffer>,
    practiceRefs: DocumentReference<IPractice>[],
    transaction: Transaction
  ): Promise<WithRef<IScheduleSummary>[]> {
    const practiceScheduleSummaries = await asyncForEach(
      uniqBy(practiceRefs, isSameRef),
      (practiceRef) =>
        ScheduleSummaryRosterUpdater.getPracticeScheduleSummaries(
          from,
          stafferRef,
          practiceRef,
          transaction
        )
    );
    return flatten(practiceScheduleSummaries);
  }

  static validUpdateChanges(
    before: WithRef<ICalendarEventSchedule> | undefined,
    after: WithRef<ICalendarEventSchedule> | undefined
  ): boolean {
    const isNew = !before && after;
    const isDeleted = !before?.deleted && after?.deleted;
    const changedModifiers = !isEqual(
      serialise(before?.modifiers),
      serialise(after?.modifiers)
    );

    if (isNew || isDeleted || changedModifiers) {
      return true;
    }

    return (
      !isEqual(before?.scheduleTime, after?.scheduleTime) ||
      !isEqual(before?.pattern, after?.pattern)
    );
  }
}
