<form [formGroup]="form">
  <div class="flex gap-4">
    <pr-patient-selector
      formControlName="patient"
      [placeholder]="placeholder"
      [filterPatient]="patient$ | async"
      fxFlex="66"
      (clear)="clear()"
      [required]="isRequired$ | async"
      [emitEvent]="false"
      [label]="label"
    />

    <mat-form-field fxFlex>
      <mat-label>Relationship</mat-label>
      <mat-select formControlName="type" [required]="isRequired$ | async">
        <mat-option
          *ngFor="
            let type of relationshipTypes;
            trackBy: trackByRelationshipType
          "
          [value]="type"
        >
          {{ type | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
