<ng-container *ngIf="invoice$ | ngrxPush as invoice">
  <h2>Invoice</h2>

  <a [routerLink]="link$ | ngrxPush">{{ invoice.reference }}</a>
  <div *ngIf="status$ | ngrxPush as log">
    <span class="capitalize">{{ log.status }}:</span>
    {{ log.updatedAt | moment | amDateFormat : dateFormat }}
  </div>
  <div *ngIf="balance$ | ngrxPush as summary">
    <span class="mat-caption">Total: {{ summary.total | currency }}</span>
  </div>
  <div *ngIf="balance$ | ngrxPush as summary">
    <span class="mat-caption"
      >Paid to Date: {{ summary.paidToDate | currency }}</span
    >
  </div>
  <div *ngIf="balance$ | ngrxPush as summary">
    Balance: {{ summary.balance | currency }}
  </div>
</ng-container>
