import { Media, Patient } from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  ISourceEntityRecord,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IMedia,
  type IPatient,
  type IPracticeMigration,
  type ISource,
  type MergeConflictDestinationEntityRecord,
  IDestinationEntityJobRunOptions,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_FORMAT,
  asyncForEach,
  getError,
  toTimestamp,
  type DocumentReference,
  type WithRef,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { compact, groupBy, uniq } from 'lodash';
import * as moment from 'moment-timezone';
import { from, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { PracticeIdFilter } from '../../../destination/filters/practice-id-filter';
import { getConfigurationItem } from '../../../source/source';
import {
  CopyFiles,
  getBucketStoragePath,
  type IStorageSyncResponse,
} from '../../../storage';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientSourceEntity,
  type ID4WPatient,
  type ID4WPatientFilters,
  type ID4WPatientTranslations,
} from '../../source/entities/patient';
import {
  CLOUD_STORAGE_CREATED_AT_METADATA_KEY,
  type IPatientFileDestinationRecord,
} from './patient-files';
import { PatientDestinationEntity } from './patients';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';

export const PATIENT_MEDIA_SUITE_FILE_RESOURCE_TYPE = 'patientMediaSuiteFiles';

export const PATIENT_MEDIA_SUITE_FILE_DESTINATION_ENTITY =
  DestinationEntity.init({
    metadata: {
      key: PATIENT_MEDIA_SUITE_FILE_RESOURCE_TYPE,
      label: 'Patient MediaSuite Files',
      description: '',
    },
  });

export interface IPatientFileJobData {
  sourcePatient: IGetRecordResponse<
    ID4WPatient,
    ID4WPatientTranslations,
    ID4WPatientFilters
  >;
  files: IFileSummary[];
}

export interface IFileSummary {
  patientId: string;
  fileDate: string;
  fileName: string;
  folderPath: string;
}

export class PatientMediaSuiteFileDestinationEntity extends BaseDestinationEntity<
  IPatientFileDestinationRecord,
  IPatientFileJobData,
  IPatientFileJobData
> {
  destinationEntity = PATIENT_MEDIA_SUITE_FILE_DESTINATION_ENTITY;

  override filters = [
    new PracticeIdFilter<IPatientFileJobData>((jobData) =>
      jobData.sourcePatient.data.data.practice_id.toString()
    ),
    new PatientIdFilter<IPatientFileJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  sourceCountDataAccessor(
    data: IPatientFileJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMapHandler: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientFileJobData[]> {
    const patientFiles$ = from(
      groupImagesByPatient(
        migration.configuration.projectId,
        getBucketStoragePath(migration.source),
        getBucketMediaSuiteStoragePath(migration.source)
      )
    );

    return patientFiles$.pipe(
      switchMap((patientFiles) => {
        return this.buildSourceRecordQuery$(
          migration,
          this.sourceEntities.patients,
          runOptions
        ).pipe(
          map((sourcePatients) =>
            sourcePatients.map((sourcePatient) => ({
              sourcePatient,
              files:
                patientFiles[sourcePatient.data.data.patient_id.toString()] ||
                [],
            }))
          )
        );
      })
    );
  }

  getDestinationEntityRecordUid(data: IPatientFileJobData): string {
    return data.sourcePatient.record.uid;
  }

  buildMigrationData(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IPatientFileJobData
  ):
    | IPatientFileJobData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord) {
    return data;
  }

  hasMergeConflict(
    _translationMap: TranslationMapHandler,
    _data: IPatientFileJobData
  ): IPatientFileJobData | undefined {
    return;
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientFileJobData,
    migrationData: IPatientFileJobData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: migrationData.sourcePatient.record.uid,
      label: migrationData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  async runJob(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    data: IPatientFileJobData
  ): Promise<IDestinationEntityRecord> {
    try {
      const fileRefs = await this._addMediaToPatient(
        migration,
        data,
        translationMapHandler,
        migration.configuration.projectId,
        migration.configuration.destinationBucket
      );
      return this._buildSuccessResponse(data.sourcePatient, fileRefs);
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  private _buildSuccessResponse(
    sourcePatient: IGetRecordResponse<ID4WPatient, ID4WPatientTranslations>,
    fileRefs: DocumentReference<IMedia>[]
  ): IDestinationEntityRecord<IPatientFileDestinationRecord> {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      data: {
        fileRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: sourcePatient.record.ref,
      migratedAt: toTimestamp(),
    };
  }

  private _buildErrorResponse(
    sourcePatient: IGetRecordResponse<ID4WPatient, ID4WPatientTranslations>,
    errorMessage: string
  ): IDestinationEntityRecord<IPatientFileDestinationRecord> {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: sourcePatient.record.ref,
      errorMessage,
      failData: {
        patientRef: sourcePatient.record.ref,
      },
    };
  }

  private async _addMediaToPatient(
    migration: WithRef<IPracticeMigration>,
    data: IPatientFileJobData,
    translationMap: TranslationMapHandler,
    projectId: string,
    destinationBucket: string
  ): Promise<DocumentReference<IMedia>[]> {
    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();

    // eslint-disable-next-line no-console
    console.log(
      `Found ${data.files.length} files for patient ${sourcePatientId} in the bucket`
    );

    const folderPath = data.files[0]?.folderPath;

    if (!data.files.length || !folderPath) {
      return [];
    }

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );
    if (!patientRef) {
      throw new Error(`No ref for Patient Id ${sourcePatientId}`);
    }

    const copyFolderResponse = await this._copySourceFilesToDestination(
      getBucketStoragePath(migration.source),
      `${folderPath}/`,
      patientRef,
      projectId,
      destinationBucket
    );

    return compact(
      await asyncForEach(copyFolderResponse.storageFiles, async (file) => {
        const fileDestinationRef = await translationMap.getDestination(
          file.path,
          PATIENT_MEDIA_SUITE_FILE_RESOURCE_TYPE
        );

        const name = file.path.split('/').pop() ?? '';
        const matchingFile = data.files.find((dataFile) =>
          name.endsWith(dataFile.fileName)
        );

        if (!matchingFile) {
          return;
        }

        const createdAt = matchingFile?.fileDate;

        const mediaRef = await FirestoreMigrate.upsertDoc(
          Patient.mediaCol({ ref: patientRef }),
          {
            ...Media.init({
              name,
              path: file.path,
            }),
            createdAt: createdAt
              ? toTimestamp(moment(createdAt, ISO_DATE_FORMAT))
              : toTimestamp(),
          },
          fileDestinationRef?.id
        );

        if (!fileDestinationRef) {
          await translationMap.upsert({
            sourceIdentifier: file.path,
            destinationIdentifier: mediaRef,
            resourceType: PATIENT_MEDIA_SUITE_FILE_RESOURCE_TYPE,
          });
        }

        return mediaRef;
      })
    );
  }

  private async _copySourceFilesToDestination(
    sourceBucket: string,
    sourcePath: string,
    patientRef: DocumentReference<IPatient>,
    projectId: string,
    destinationBucket: string
  ): Promise<IStorageSyncResponse> {
    const copyStorage = new CopyFiles(
      sourceBucket,
      sourcePath,
      destinationBucket,
      Patient.storagePath({ ref: patientRef }),
      projectId
    );

    return {
      storageFiles: await copyStorage.copyAll(
        [/.*.db/],
        [CLOUD_STORAGE_CREATED_AT_METADATA_KEY],
        true
      ),
    };
  }
}

export function getBucketMediaSuiteStoragePath(source: ISource): string {
  return getConfigurationItem(
    source,
    'source bucket mediasuite storage path',
    ''
  );
}

export async function groupImagesByPatient(
  projectId: string,
  bucketName: string,
  bucketPath: string
): Promise<Record<string, IFileSummary[]>> {
  const storage = new CopyFiles(bucketName, bucketPath, '', '', projectId)
    .storage;

  const [files] = await storage.bucket(bucketName).getFiles({
    prefix: bucketPath,
  });

  const bucketFiles = uniq(
    compact(
      files.map((file) => {
        const matchesAlternative = new RegExp(
          // eslint-disable-next-line no-useless-escape
          /mediasuite\/([0-9]+)\/(.*)/
        ).exec(file.name);
        const patientId = matchesAlternative?.[1] ?? '';
        const folderPath = file.name.split('/');
        const fileName = folderPath.pop() ?? '';

        const matches = new RegExp(
          // eslint-disable-next-line no-useless-escape
          /mediasuite\/([0-9]+)\/([0-9\-]+)\/(.*)/
        ).exec(file.name);
        const fileDate = matches?.[2] ?? '';

        if (fileDate) {
          folderPath.pop();
        }

        return {
          patientId,
          fileDate,
          fileName,
          folderPath: folderPath.join('/'),
        };
      })
    )
  );

  return groupBy(bucketFiles, 'patientId');
}
