import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ICustomReport,
  IQueryScopeRequests,
  RestrictAccessEntity,
} from '@principle-theorem/principle-core/interfaces';
import { ITimePeriod, WithRef } from '@principle-theorem/shared';

export interface IEditCustomReportDialogData {
  dateRange?: ITimePeriod;
  customReport: WithRef<ICustomReport>;
}

export interface ICustomReportForm {
  name: string;
  description?: string;
  restrictAccessTo: RestrictAccessEntity[];
}

@Component({
    selector: 'pr-edit-custom-report-dialog',
    templateUrl: './edit-custom-report-dialog.component.html',
    styleUrl: './edit-custom-report-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditCustomReportDialogComponent {
  dateRange = signal<ITimePeriod | undefined>(undefined);
  customReport = signal<WithRef<ICustomReport> | undefined>(undefined);
  queryData = signal<IQueryScopeRequests | undefined>(undefined);

  constructor(
    public dialogRef: MatDialogRef<
      EditCustomReportDialogComponent,
      IQueryScopeRequests
    >,
    @Inject(MAT_DIALOG_DATA) private _data?: IEditCustomReportDialogData
  ) {
    this.dateRange.set(this._data?.dateRange);
    this.customReport.set(this._data?.customReport);
  }
}
