import { Directive, HostListener, Input } from '@angular/core';
import { Editor } from '@tiptap/core';
import type { IUploader } from '../uploader';
import { VideoCommandsService } from '../video/video-commands.service';

@Directive({
  selector: '[ptVideoUploadDropzone]',
})
export class VideoUploadDropzoneDirective {
  @Input()
  editor: Editor;

  @Input()
  uploader: IUploader;

  constructor(private _videoCommands: VideoCommandsService) {}

  @HostListener('drop', ['$event'])
  async onDrop($event: MouseEvent): Promise<void> {
    if (!($event instanceof DragEvent) || !$event.dataTransfer?.files.length) {
      return;
    }
    $event.preventDefault();

    const coordinates = this.editor.view.posAtCoords({
      left: $event.clientX,
      top: $event.clientY,
    });

    if (!coordinates || !$event.dataTransfer) {
      return;
    }

    await this._videoCommands.upload(
      this.uploader,
      this.editor,
      coordinates.pos,
      $event.dataTransfer.files
    );
  }
}
