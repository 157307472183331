import { NgModule } from '@angular/core';
import { NgPrincipleModule } from '@principle-theorem/ng-principle';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { MainComponent } from './main/main.component';
import { PortalPageComponent } from './portal-page/portal-page.component';
import { PracticeSelectorCardComponent } from './practice-selector/practice-selector-card/practice-selector-card.component';
import { PracticeSelectorComponent } from './practice-selector/practice-selector.component';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';

@NgModule({
  imports: [
    NgPrincipleModule,
    NgPrincipleSharedModule,
    NgSharedModule,
    NgInteractionsModule,
  ],
  declarations: [
    MainComponent,
    PracticeSelectorComponent,
    PracticeSelectorCardComponent,
    PortalPageComponent,
  ],
  exports: [MainComponent],
})
export class LayoutModule {}
