import { initVersionedSchema, toTextContent } from '@principle-theorem/editor';
import {
  ClinicalNote,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  FailedDestinationEntityRecord,
  IDestinationEntityJobRunOptions,
  IHasSourceIdentifier,
  ITranslationMap,
  type IClinicalNote,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISODateType,
  Timestamp,
  Timezone,
  getError,
  reduceToSingleArrayFn,
  resolveSequentially,
  snapshotCombineLatest,
  sortTimestamp,
  toISODate,
  toMomentTz,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { groupBy, isNull } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  BasePatientClinicalNoteDestinationEntity,
  IClinicalNoteJobData,
  IClinicalNoteMigrationData,
} from '../../../destination/entities/patient-clinical-notes';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { isClinicalNoteCode } from '../../../mappings/item-code-mapping-helpers';
import { ItemCodeNoteType } from '../../../mappings/item-codes-to-notes-xlsx';
import { TranslationMapHandler } from '../../../translation-map';
import { ADAItemSourceEntity } from '../../source/entities/ada-item';
import {
  PatientSourceEntity,
  type IExactPatient,
} from '../../source/entities/patient';
import {
  PatientTreatmentSourceEntity,
  type IExactTreatment,
  type IExactTreatmentFilters,
  type IExactTreatmentTranslations,
} from '../../source/entities/patient-treatments';
import { ExactItemCodeToNoteMappingHandler } from '../mappings/item-code-to-notes';
import { ExactItemCodeMappingHandler } from '../mappings/item-codes';
import {
  ExactStafferMappingHandler,
  resolveExactStaffer,
} from '../mappings/staff';
import { PatientDestinationEntity } from './patient';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';

export interface IExactClinicalNoteJobData
  extends IClinicalNoteJobData<IExactPatient> {
  itemCodesToNotes: WithRef<ITranslationMap<object, ItemCodeNoteType>>[];
}

export const EXACT_CLINICAL_NOTE_DESCRIPTION = `
Clinical Notes are stored in Exact's treatment table so we must retrieve all treatments that contain notes and based on a combination of the user id and treatment date
we will create a clinical note for that user on that date.

Exact replicates the same clinical note where treatments are subsqeuent codes of the same treatment. We will reduce this sequence based on matching strings to create a single clinical note.
`;

export class PatientClinicalNoteDestinationEntity extends BasePatientClinicalNoteDestinationEntity<IExactPatient> {
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    treatments: new PatientTreatmentSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  customMappings = {
    staff: new ExactStafferMappingHandler(),
    itemCodes: new ExactItemCodeMappingHandler(),
    itemCodesToNotes: new ExactItemCodeToNoteMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IExactClinicalNoteJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const itemCodesToNotes$ =
      this.customMappings.itemCodesToNotes.getRecords$(translationMap);
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([staff$, sourceItemCodes$, itemCodesToNotes$]),
    ]).pipe(
      map(([sourcePatients, [staff, sourceItemCodes, itemCodesToNotes]]) =>
        sourcePatients.map((sourcePatient) => ({
          staff,
          sourceItemCodes,
          itemCodesToNotes,
          sourcePatient,
          destinationEntity,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IExactClinicalNoteJobData
  ): Promise<
    | IClinicalNoteMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients.getSourceRecordId(
      data.sourcePatient.data.data
    );
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        `Couldn't resolve patient`
      );
    }

    try {
      const clinicalNotes = await this._buildClinicalNotesData(
        migration,
        translationMap,
        data
      );

      return {
        sourcePatientId,
        patientRef,
        clinicalNotes,
      };
    } catch (error) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        getError(error)
      );
    }
  }

  private _getNoteDate(
    sourceNote: IGetRecordResponse<
      IExactTreatment,
      IExactTreatmentTranslations,
      IExactTreatmentFilters
    >
  ): ISODateType | undefined {
    return (
      sourceNote.data.translations.completedDate ??
      sourceNote.data.translations.plannedDate
    );
  }

  private _getNoteTimestamp(
    sourceNote: IGetRecordResponse<
      IExactTreatment,
      IExactTreatmentTranslations,
      IExactTreatmentFilters
    >,
    timezone: Timezone
  ): Timestamp {
    const noteDate = this._getNoteDate(sourceNote);
    return noteDate
      ? toTimestamp(toMomentTz(noteDate, timezone))
      : toTimestamp();
  }

  private async _buildClinicalNotesData(
    migration: WithRef<IPracticeMigration>,
    translationMap: TranslationMapHandler,
    data: IExactClinicalNoteJobData
  ): Promise<(IClinicalNote & IHasSourceIdentifier)[]> {
    const timezone = migration.configuration.timezone;
    const patientUid = this.sourceEntities.patients.getSourceRecordId(
      data.sourcePatient.data.data
    );
    const clinicalNoteTreatments =
      await this.sourceEntities.treatments.filterRecords(
        migration,
        'patientId',
        patientUid,
        undefined,
        undefined,
        (record) =>
          !isNull(record.data.data.treatment_notes) &&
          record.data.data.treatment_notes !==
            record.data.data.service_description
      );

    const sourceEntity = new ADAItemSourceEntity();
    const sorted = clinicalNoteTreatments
      .filter((record) => {
        const sourceId = sourceEntity.getSourceRecordId({
          itemCode: record.data.data.service_code,
        });
        return isClinicalNoteCode(
          record.data.data.service_code,
          sourceId,
          data.sourceItemCodes,
          data.itemCodesToNotes
        );
      })
      .sort((treatmentA, treatmentB) =>
        sortTimestamp(
          this._getNoteTimestamp(treatmentA, timezone),
          this._getNoteTimestamp(treatmentB, timezone)
        )
      );
    const userGroupedNotes = groupBy(
      sorted,
      (record) => record.data.data.provider_code ?? '0'
    );

    const clinicalNotes = await resolveSequentially(
      Object.entries(userGroupedNotes),
      async ([providerId, userNotes]) => {
        const staffer = await resolveExactStaffer(
          providerId,
          translationMap,
          data.staff
        );
        if (!staffer) {
          throw new Error(`Failed to resolve practitioner code: ${providerId}`);
        }
        const dateGroupedNotes = groupBy(userNotes, (note) =>
          this._getNoteDate(note)
        );

        return Object.values(dateGroupedNotes).map((notes) => {
          const content = notes
            .reduce(
              (
                results: IGetRecordResponse<
                  IExactTreatment,
                  IExactTreatmentTranslations,
                  IExactTreatmentFilters
                >[],
                note
              ) => {
                const existing = results.find(
                  (result) =>
                    result.data.data.treatment_notes ===
                    note.data.data.treatment_notes
                );
                if (!existing) {
                  results.push(note);
                }
                return results;
              },
              []
            )
            .map((note) => [
              toTextContent(
                `From ${note.data.data.service_code} ${
                  note.data.data.tooth ?? ''
                } ${
                  note.data.data.tooth_surfaces.length
                    ? note.data.data.tooth_surfaces.join(', ')
                    : ''
                } `
              ),
              toTextContent(note.data.data.treatment_notes ?? ''),
            ])
            .flat();

          const noteDate = this._getNoteDate(notes[0]);
          const noteTimestamp = this._getNoteTimestamp(notes[0], timezone);

          return {
            sourceIdentifier: `${notes[0].data.data.patient_id}-${noteDate}`,
            ...ClinicalNote.init({
              owner: stafferToNamedDoc(staffer),
              content: initVersionedSchema(content),
              recordDate: noteDate ?? toISODate(toTimestamp()),
              createdAt: noteTimestamp,
              updatedAt: noteTimestamp,
              immutable: true,
            }),
          };
        });
      }
    );
    return clinicalNotes.reduce(reduceToSingleArrayFn, []);
  }
}
