import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  inject,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  ContentContainerComponent,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  IPatient,
  IStaffer,
  PrescriptionStatus,
} from '@principle-theorem/principle-core/interfaces';
import { PatientPrescriptionsListComponent } from '../patient-prescriptions-list/patient-prescriptions-list';
import {
  DeletePrescriptonData,
  PrescriptionService,
} from '../prescription.service';
import { MatIconModule } from '@angular/material/icon';
import { PatientPrescriptionFormComponent } from '../patient-prescription-form/patient-prescription-form.component';
import {
  WithRef,
  filterUndefined,
  isSameRef,
  snapshot,
} from '@principle-theorem/shared';
import { PractitionerSelectorComponent } from '@principle-theorem/ng-clinical-charting';
import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { MatTooltipModule } from '@angular/material/tooltip';

export interface IPatientPrescriptionDialogData {
  patient: WithRef<IPatient>;
  prescriber: WithRef<IStaffer>;
  actionedBy: WithRef<IStaffer>;
}

@Component({
    selector: 'pr-patient-prescription-dialog',
    imports: [
        CommonModule,
        MatDialogModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        PatientPrescriptionsListComponent,
        ContentContainerComponent,
        PatientPrescriptionFormComponent,
        PractitionerSelectorComponent,
    ],
    templateUrl: './patient-prescription-dialog.component.html',
    styleUrl: './patient-prescription-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [PrescriptionService]
})
export class PatientPrescriptionDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  readonly dialogRef = inject(MatDialogRef<PatientPrescriptionDialogComponent>);
  prescription = inject(PrescriptionService);
  organisation = inject(OrganisationService);
  data = inject<IPatientPrescriptionDialogData>(MAT_DIALOG_DATA);
  prescribingAs = signal<WithRef<IStaffer> | undefined>(undefined);

  feedbackMessage = computed(() => {
    if (!this.prescribingAs()) {
      return 'Select a prescriber to create a prescription.';
    }
    return '';
  });

  constructor() {
    this.prescription.loadAll$.next(PrescriptionStatus.Draft);

    this._getPrescribingAs$()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((prescriber) => this.prescribingAs.set(prescriber));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async create(): Promise<void> {
    const prescriber = this.prescribingAs();
    const currentStaffer$ = this.organisation.staffer$.pipe(filterUndefined());
    const actionedBy = await snapshot(currentStaffer$);
    if (!prescriber) {
      return;
    }
    this.prescription.create(prescriber, actionedBy);
  }

  delete(data: DeletePrescriptonData): void {
    this.prescription.delete$.next(data);
    this.dialogRef.close();
  }

  private _getPrescribingAs$(): Observable<WithRef<IStaffer> | undefined> {
    const staffer$ = this.organisation.staffer$.pipe(filterUndefined());
    const prescribers$ = this.organisation.prescribers$.pipe(filterUndefined());
    return combineLatest([staffer$, prescribers$]).pipe(
      map(([staffer, prescribers]) =>
        prescribers.find((prescriber) => isSameRef(staffer, prescriber))
      )
    );
  }
}
