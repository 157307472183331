import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { type Funder, type TransactionPayload } from '@medipass/partner-sdk';
import {
  MedipassIntegrationStorage,
  MedipassService,
} from '@principle-theorem/ng-medipass';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { Invoice, OrganisationCache } from '@principle-theorem/principle-core';
import {
  type IHealthcareClaim,
  type IInvoice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import { snapshot, type WithRef } from '@principle-theorem/shared';
import { type Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { type ITransactionAmountDialogResult } from '../transaction-components/transaction-amount-dialog/transaction-amount-dialog.component';
import { resolveTransactionPracticeRef } from '../transaction-helpers';
import { MedipassBuilder } from './medipass-builder.service';

@Injectable()
export class MedipassProviderService {
  isEnabled$: Observable<boolean>;

  constructor(
    private _snackbar: MatSnackBar,
    private _medipass: MedipassService,
    private _medipassBuilder: MedipassBuilder,
    private _organisationService: OrganisationService,
    private _currentScope: CurrentScopeFacade
  ) {
    const storage = new MedipassIntegrationStorage();
    this.isEnabled$ = this._organisationService.integrationCol$.pipe(
      switchMap((integrationsCol) =>
        integrationsCol
          ? storage.get$(storage.col(integrationsCol))
          : of(undefined)
      ),
      map((data) => !!data)
    );
  }

  async openVirtualTerminal(
    invoice: WithRef<IInvoice>,
    formData: ITransactionAmountDialogResult,
    claim?: IHealthcareClaim
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const patient = await OrganisationCache.patients.getDoc(
      Invoice.patientDocRef(invoice)
    );
    const staffer = await snapshot(this._organisationService.staffer$);
    if (!patient || !staffer) {
      return;
    }
    const payload = await this._medipassBuilder.getVirtualTerminalPayload(
      patient,
      invoice,
      formData,
      staffer,
      claim
    );
    await this._openMedipass(invoice, payload, 'patient-funded', claim);
  }

  async openFunder(
    invoice: WithRef<IInvoice>,
    funder: Funder,
    claim: IHealthcareClaim
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const patient = await OrganisationCache.patients.getDoc(
      Invoice.patientDocRef(invoice)
    );
    const staffer = await snapshot(this._organisationService.staffer$);
    if (!patient || !staffer) {
      return;
    }
    const payload = await this._medipassBuilder.getFunderPayload(
      patient,
      invoice,
      funder,
      staffer,
      claim
    );
    await this._openMedipass(invoice, payload, funder, claim);
  }

  private async _openMedipass(
    invoice: IInvoice,
    payload: TransactionPayload,
    funder: Funder,
    claim?: IHealthcareClaim
  ): Promise<void> {
    const practiceRef = await resolveTransactionPracticeRef(
      this._currentScope.currentPractice$,
      invoice.practice
    );
    const result = await this._medipass.renderCreateTransaction(
      practiceRef,
      payload,
      funder,
      undefined,
      claim
    );
    if (!result) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }
  }
}
