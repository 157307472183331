<ng-container *ngrxLet="action$ as action">
  <pt-loader-button
    [loading]="action.loading$ | ngrxPush"
    [matBadge]="badge$ | ngrxPush"
    [matBadgeHidden]="hideBadge$ | ngrxPush"
    (click)="run(action)"
    type="stroked"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <span>{{ action.name }}</span>
      <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
    </div>
  </pt-loader-button>
</ng-container>
