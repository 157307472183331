import {
  EventType,
  IAppointment,
  IBrand,
  ICalendarEventSchedulesMap,
  ICandidate,
  ICandidateCalendarEvent,
  IEvent,
  ILabJob,
  IPractice,
  IScheduleSummaryEventable,
  IStaffer,
  IWaitListCandidate,
  ParticipantType,
  ResolvedAppointmentDependency,
  isLabJob,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  Firestore,
  INamedDocument,
  IReffable,
  WithRef,
  asDocRef,
  asyncForEach,
  deleteDoc,
  getEnumValues,
  multiFilter,
  multiSwitchMap,
  snapshot,
  timePeriodsIntersect,
  toMoment,
  toNamedDocument,
  toTimePeriod,
  undeletedQuery,
  where,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Appointment } from '../appointment/appointment';
import { CandidateCalendarEvent } from '../event/calendar-event';
import { Event } from '../event/event';
import { Brand } from '../brand';
import { difference, compact, first } from 'lodash';
import { EventableQueries } from '../eventable-queries';
import { Roster } from '../staffer/roster';

export async function waitListCandidateToEvent(
  gap: IScheduleSummaryEventable,
  waitlistCandidate: IWaitListCandidate,
  user: INamedDocument<IStaffer>
): Promise<ICandidateCalendarEvent> {
  const candidate: ICandidate = waitlistCandidate.candidate;
  const waitlistParticipant = await Appointment.patient(
    waitlistCandidate.appointment
  );
  return CandidateCalendarEvent.init({
    candidate: candidate,
    event: Event.init({
      from: candidate.offerTimeFrom,
      to: candidate.offerTimeTo,
      type: EventType.GapCandidate,
      practice: {
        name: waitlistCandidate.appointment.practiceName,
        ref: asDocRef<IPractice>(waitlistCandidate.appointment.practiceRef),
      },
      participants: [
        ...gap.event.participants,
        {
          ...toNamedDocument(waitlistParticipant),
          type: ParticipantType.Patient,
        },
      ],
      creator: toNamedDocument(user),
    }),
  });
}

export async function removeCandidateFromAllShortlists(
  candidate: WithRef<ICandidateCalendarEvent>,
  brand: IReffable<IBrand>
): Promise<void> {
  const candidateEvents = (await Firestore.getDocs(
    undeletedQuery(Brand.calendarEventCol(brand)),
    where('candidate.appointment', '==', candidate.candidate.appointment)
  )) as WithRef<ICandidateCalendarEvent>[];

  await asyncForEach(candidateEvents, ({ ref }) => deleteDoc(ref));
}

export async function removeConflictingCandidates(
  candidate: WithRef<ICandidateCalendarEvent>
): Promise<void> {
  const candidates = await getConflictingCandidates(candidate);
  await asyncForEach(candidates, ({ ref }) => deleteDoc(ref));
}

export async function getConflictingCandidates(
  candidate: WithRef<ICandidateCalendarEvent>
): Promise<WithRef<ICandidateCalendarEvent>[]> {
  const stafferRef = first(Event.staff(candidate.event))?.ref;
  if (!stafferRef) {
    return [];
  }

  const practice = await Firestore.getDoc(candidate.event.practice.ref);
  const staffer = await Firestore.getDoc(stafferRef);
  const stafferRoster: ICalendarEventSchedulesMap = {
    [stafferRef.id]: await snapshot(
      Roster.all$(staffer, EventType.RosteredOn, practice)
    ),
  };
  const range = toTimePeriod(
    toMoment(candidate.event.from).startOf('day'),
    toMoment(candidate.event.from).endOf('day')
  );

  return snapshot(
    EventableQueries.getScheduleSummaryEventablesWithFallback$(
      range,
      practice,
      [staffer],
      stafferRoster,
      difference(getEnumValues(EventType), [EventType.GapCandidate])
    ).pipe(
      multiFilter(({ event }) =>
        timePeriodsIntersect(
          Event.toTimePeriod(event),
          Event.toTimePeriod(candidate.event),
          true,
          'minute'
        )
      ),
      multiSwitchMap((event) =>
        event.ref
          ? Firestore.getDoc(asDocRef<ICandidateCalendarEvent>(event.ref))
          : of(undefined)
      ),
      map(compact)
    )
  );
}

export class WaitListCandidate {
  static init(
    overrides: AtLeast<IWaitListCandidate, 'candidate' | 'appointment'>
  ): IWaitListCandidate {
    return {
      productivity: 1,
      ...overrides,
    };
  }

  static getWarnings$(
    appointment: WithRef<IAppointment>,
    event: IEvent
  ): Observable<string[]> {
    return Appointment.dependencies$(appointment).pipe(
      startWith([]),
      map((dependencies) => {
        return this.getDependencyWarnings(dependencies, event);
      })
    );
  }

  static getDependencyWarnings(
    dependencies: ResolvedAppointmentDependency[],
    event: IEvent
  ): string[] {
    const labJobWarnings: string[] = dependencies
      .filter((dependency) => isLabJob(dependency))
      .map((labJob) => this.getLabJobWarning(labJob, event));

    return [...labJobWarnings].filter((warning: string) => warning !== '');
  }

  static getLabJobWarning(labJob: ILabJob, event: IEvent): string {
    const labJobDue: Moment = labJob.dueDate
      ? toMoment(labJob.dueDate)
      : moment();
    const eventStart: Moment = toMoment(event.from);
    if (labJobDue.isAfter(eventStart)) {
      return `Required Lab Job is due before this appointment`;
    }
    return '';
  }
}
