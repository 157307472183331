import { IReferralSource } from '@principle-theorem/principle-core/interfaces';
import { BaseDimensionMeasures } from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import { MeasureTransformMap } from '../measure-properties';
import { CanDoAllProperty } from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';

export class ReferrerDimensionMeasures
  extends BaseDimensionMeasures
  implements MeasureTransformMap<Pick<IReferralSource, 'name'>>
{
  get name(): CanDoAllProperty {
    const name = this.measureRef('name');
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('name'),
        label: 'Referrer Name',
        summary: 'The name of the referrer that the patient was referred by',
      },
      docRef,
      this.buildQuery()
        .attributes([docRef.attributePath, name.attributePath])
        .get(),
      name,
      'None'
    );
  }
}
