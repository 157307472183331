@if (scoreClass$ | async; as scoreClass) {
  <div
    *ngrxLet="tooltips$ as tooltips"
    class="flex gap-0.5"
    [class.flex-col]="vertical$ | async"
  >
    <div
      class="h-3.5 w-3.5 rounded-full"
      [ngClass]="scoreClass !== 'high' ? 'bg-gray-300' : 'bg-green-600'"
      [matTooltip]="tooltips['high']"
      [matTooltipDisabled]="scoreClass !== 'high'"
    ></div>
    <div
      class="h-3.5 w-3.5 rounded-full"
      [ngClass]="scoreClass !== 'medium' ? 'bg-gray-300' : 'bg-orange-600'"
      [matTooltip]="tooltips['medium']"
      [matTooltipDisabled]="scoreClass !== 'medium'"
    ></div>
    <div
      class="h-3.5 w-3.5 rounded-full"
      [ngClass]="scoreClass !== 'low' ? 'bg-gray-300' : 'bg-red-600'"
      [matTooltip]="tooltips['low']"
      [matTooltipDisabled]="scoreClass !== 'low'"
    ></div>
  </div>
}
