import { type StepperSelectionEvent } from '@angular/cdk/stepper';
import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStep } from '@angular/material/stepper';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import {
  IReasonSelectorValue,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  TypedFormControl,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  Appointment,
  Brand,
  IAppointmentDetails,
  IWaitListDetails,
  SchedulingEvent,
  SchedulingEventReason,
  Staffer,
  TimezoneResolver,
  TreatmentStep,
  staffToNamedDocs,
} from '@principle-theorem/principle-core';
import {
  ISchedulingEventConditions,
  ISchedulingEventData,
  ISchedulingEventReason,
  ITreatmentStep,
  SchedulingEventType,
  type IAppointment,
  type IEvent,
  type IPatient,
  type IPractice,
  type IStaffer,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  snapshot,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { isNil, omitBy } from 'lodash';
import { type Moment } from 'moment-timezone';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  type Observable,
} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { AppointmentAutomationsRescheduleComponent } from '../../components/scheduling/appointment-automations-reschedule/appointment-automations-reschedule.component';
import { type AppointmentDetailsComponent } from '../../components/scheduling/appointment-details/appointment-details.component';
import { WaitlistConfigurationComponent } from '../../components/scheduling/waitlist-configuration/waitlist-configuration.component';
import { SchedulingScenarioService } from '../../scheduling-scenario.service';

@Component({
    selector: 'pr-manage-appointment',
    templateUrl: './manage-appointment.component.html',
    styleUrls: ['./manage-appointment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ManageAppointmentComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _appointmentDetailsComponent$ =
    new ReplaySubject<AppointmentDetailsComponent>(1);
  reasonControl = new TypedFormControl<IReasonSelectorValue | undefined>(
    undefined,
    Validators.required
  );
  unscheduledErrorMessage = `Appointment cannot be left unscheduled`;
  practices$: Observable<WithRef<IPractice>[]>;
  practitioners$: Observable<INamedDocument<IStaffer>[]>;
  openTime$: Observable<Moment>;
  closeTime$: Observable<Moment>;
  disableSave$ = new BehaviorSubject<boolean>(false);
  saving$ = new BehaviorSubject<boolean>(false);
  disableSaveMessage$ = new BehaviorSubject<string>('');
  automationReschedule$ =
    new ReplaySubject<AppointmentAutomationsRescheduleComponent>(1);

  appointment$: Observable<WithRef<IAppointment>>;
  patient$: Observable<WithRef<IPatient>>;

  appointmentDetails$: Observable<IAppointmentDetails>;
  waitlistDetails$: Observable<IWaitListDetails>;
  selectedEvent$: Observable<IEvent | undefined>;
  hasError$: Observable<boolean>;
  treatmentStep$: Observable<WithRef<ITreatmentStep>>;
  newEvent$: Observable<IEvent | undefined>;
  reasons$: Observable<WithRef<ISchedulingEventReason>[]>;
  schedulingConditions$: Observable<ISchedulingEventConditions>;

  @ViewChild('appointmentSelectStep', { read: MatStep, static: true })
  _appointmentSelectStep: MatStep;

  @ViewChild(WaitlistConfigurationComponent, { static: true })
  waitlistDetails: WaitlistConfigurationComponent;
  requiresReschedulingReason$: Observable<boolean>;

  @ViewChild('appointmentDetails', { static: true })
  set appointmentDetailsComponent(
    appointmentDetails: AppointmentDetailsComponent
  ) {
    if (appointmentDetails) {
      this._appointmentDetailsComponent$.next(appointmentDetails);
    }
  }

  @ViewChild(AppointmentAutomationsRescheduleComponent, { static: false })
  set automationReschedule(
    component: AppointmentAutomationsRescheduleComponent
  ) {
    if (component) {
      this.automationReschedule$.next(component);
    }
  }

  constructor(
    private _schedulingFacade: AppointmentSchedulingFacade,
    private _snackBar: MatSnackBar,
    private _location: Location,
    private _schedulingScenario: SchedulingScenarioService,
    private _organisation: OrganisationService
  ) {
    this.openTime$ = this._schedulingFacade.openTime$;
    this.closeTime$ = this._schedulingFacade.closeTime$;
    this.appointment$ =
      this._schedulingFacade.currentAppointment$.pipe(filterUndefined());
    this.patient$ =
      this._schedulingFacade.selectedPatient$.pipe(filterUndefined());

    this.appointmentDetails$ = this._schedulingFacade.appointmentDetails$;
    this.waitlistDetails$ = this._schedulingFacade.waitlistDetails$;
    this.selectedEvent$ = this._schedulingFacade.selectedEvent$;
    this.hasError$ = this.selectedEvent$.pipe(
      map((event) => (event ? false : true))
    );
    this.treatmentStep$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.treatmentStep$(appointment))
    );

    this.practices$ = this._organisation.practices$;

    this.practitioners$ = combineLatest([
      this.appointmentDetails$.pipe(map((details) => details.practice)),
      this._schedulingFacade.brand$,
    ]).pipe(
      switchMap(([practice, brand]) =>
        practice
          ? Staffer.practitionersByPractice$(practice)
          : Staffer.practitionersByBrand$(brand)
      ),
      staffToNamedDocs()
    );

    this._appointmentDetailsComponent$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((component) => component.form.controls.treatment.disable());

    this.newEvent$ = combineLatest([
      this.appointment$,
      this.selectedEvent$.pipe(filterUndefined()),
    ]).pipe(
      map(([appointment, event]) => {
        return !appointment.event?.from.isEqual(event.from) ? event : undefined;
      })
    );

    this.schedulingConditions$ = combineLatest([
      this.appointment$,
      this.selectedEvent$,
    ]).pipe(
      switchMap(async ([appointment, selectedEvent]) =>
        SchedulingEvent.getSchedulingConditions(
          await TimezoneResolver.fromPracticeRef(appointment.practice.ref),
          appointment.event?.from,
          selectedEvent?.from
        )
      )
    );
    this.requiresReschedulingReason$ = this.schedulingConditions$.pipe(
      map((conditions) => conditions.eventType !== SchedulingEventType.Schedule)
    );
    const reasons$ = this._schedulingFacade.brand$.pipe(
      switchMap((brand) => Brand.cancellationReasons$(brand))
    );
    this.reasons$ = combineLatest([reasons$, this.schedulingConditions$]).pipe(
      map(([reasons, conditions]) =>
        SchedulingEventReason.filterByEventType(reasons, conditions.eventType)
      )
    );

    const reasonValid$ = combineLatest([
      this.requiresReschedulingReason$,
      formControlChanges$(this.reasonControl),
    ]).pipe(
      map(([requiresReschedulingReason]) =>
        requiresReschedulingReason ? this.reasonControl.valid : true
      ),
      distinctUntilChanged()
    );
    combineLatest([this.appointment$, this.selectedEvent$, reasonValid$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([appointment, event, reasonValid]) =>
        this._disableSave(appointment, event, reasonValid)
      );
  }

  ngOnDestroy(): void {
    this._schedulingFacade.reset();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  updateAppointmentDetails(details: Partial<IAppointmentDetails>): void {
    details = omitBy(details, isNil);
    this._schedulingFacade.appointmentFormChange(details);
  }

  updateWaitList(details: IWaitListDetails): void {
    this._schedulingFacade.updateWaitListDetails(details);
  }

  updateTags(tags: INamedDocument<ITag>[]): void {
    this._schedulingFacade.updateTags(tags);
  }

  handleStepChange(event: StepperSelectionEvent): void {
    if (event.selectedStep === this._appointmentSelectStep) {
      this._schedulingFacade.loadAppointmentAvailability();
    }
  }

  async submit(): Promise<void> {
    const event = await snapshot(this.selectedEvent$);
    if (!event) {
      this._openUnscheduledMessage();
      return;
    }

    this.saving$.next(true);

    const appointment = await snapshot(this.appointment$);
    const selectedEvent = await snapshot(this._schedulingFacade.selectedEvent$);
    const treatmentCategory = TreatmentStep.defaultDisplayRef(
      appointment.treatmentPlan.treatmentStep.display
    );
    if (selectedEvent) {
      const isBlockedByDoubleBooking =
        await this._schedulingScenario.isBlockedByDoubleBooking(
          {
            event: selectedEvent,
            ref: appointment.ref,
          },
          treatmentCategory
        );
      if (isBlockedByDoubleBooking) {
        this.saving$.next(false);
        return;
      }
    }

    const automationRescheduleComponent = await snapshot(
      this.automationReschedule$
    );
    await automationRescheduleComponent.update();

    const reasonData = this.reasonControl.value;
    const practice = await snapshot(
      this._organisation.practice$.pipe(filterUndefined())
    );
    const schedulingEventData: ISchedulingEventData = {
      scheduledByPractice: practice.ref,
      reason: reasonData?.reason ?? undefined,
      reasonSetManually: reasonData?.reasonSetManually ?? false,
      schedulingConditions: await snapshot(this.schedulingConditions$),
    };
    this._schedulingFacade.rescheduleAppointment(schedulingEventData);
    this.back();
  }

  back(): void {
    this._location.back();
  }

  private _openUnscheduledMessage(): void {
    this._snackBar.open(this.unscheduledErrorMessage, '', {
      duration: 5000,
    });
  }

  private _disableSave(
    appointment: WithRef<IAppointment>,
    event: IEvent | undefined,
    reasonValid: boolean
  ): void {
    if (!event) {
      this.disableSave$.next(true);
      this.disableSaveMessage$.next('No appointment time selected');
      return;
    }
    if (
      appointment.event?.from.isEqual(event.from) &&
      appointment.event?.to.isEqual(event.to)
    ) {
      this.disableSave$.next(true);
      this.disableSaveMessage$.next('Select a new appointment time');
      return;
    }
    if (!reasonValid) {
      this.disableSave$.next(true);
      this.disableSaveMessage$.next('Select a reschedule reason');
      return;
    }
    this.disableSave$.next(false);
  }
}
