<pr-display-colour-bar
  direction="horizontal"
  [category]="category$ | ngrxPush"
/>

<div fxLayout="column" fxLayoutGap="8px" class="event-details layout-padding">
  <div *ngIf="patient$ | ngrxPush as patient; else loadingPatient">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <pt-user-icon
        [name]="patient.name"
        [src]="patient | profileImage$ | ngrxPush"
        [diameter]="45"
      />
      <div fxFlex>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <a [prBrandRouterLink]="['patients', patient.ref.id]">
            <h3 class="mat-headline-6">{{ patient.name }}</h3>
          </a>
          <pr-new-patient-icon
            [appointment]="appointment$ | ngrxPush"
            [patient]="patient"
          />
        </div>
        <pr-patient-date-of-birth [dateOfBirth]="patient.dateOfBirth" />
        <pr-patient-phone [contactNumbers]="patient.contactNumbers" />
      </div>
      <div fxLayout="column">
        <pr-timeline-appointment-notifications
          *ngIf="appointment$ | async as appointment"
          [tooltipsEnabled]="true"
          [appointment]="appointment"
        />
      </div>
    </div>
  </div>

  <ng-template #loadingPatient>
    <content-loader viewBox="0 0 240 60">
      <svg:rect x="0" y="8" rx="22.5" ry="22.5" width="45" height="45" />
      <svg:rect x="56" y="0" rx="3" ry="3" width="150" height="26" />
      <svg:rect x="56" y="30" rx="3" ry="3" width="140" height="14" />
      <svg:rect x="56" y="48" rx="3" ry="3" width="140" height="14" />
    </content-loader>
  </ng-template>

  <mat-divider />

  <pr-generic-timeline-tooltip [eventable]="appointment$ | ngrxPush" />

  <mat-divider />

  <div fxLayout="column" fxLayoutGap="8px">
    <div class="flex flex-row gap-4">
      <mat-icon matTooltip="Treatment Categories">timelapse</mat-icon>
      <div *ngIf="status$ | ngrxPush as status">
        {{ status | splitCamel | titlecase }}
      </div>
    </div>

    <div class="flex flex-row gap-4">
      <mat-icon matTooltip="Treatment Categories">category</mat-icon>
      <div>
        <span *ngIf="category$ | async as category">
          {{ category.name || 'Unknown' }}
        </span>
      </div>
    </div>
  </div>
</div>
