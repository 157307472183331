import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { invoiceToBalance } from '@principle-theorem/ng-principle-accounting';
import {
  InvoiceStatus,
  type IBalance,
  type IInvoice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-pay-invoice',
  exportAs: 'prPayInvoice',
  templateUrl: './pay-invoice.component.html',
  styleUrls: ['./pay-invoice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayInvoiceComponent {
  invoice$: ReplaySubject<WithRef<IInvoice>> = new ReplaySubject(1);
  balance$: Observable<IBalance>;
  isPaid$: Observable<boolean>;
  transactions$: Observable<ITransaction[]>;

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this.invoice$.next(invoice);
    }
  }

  constructor() {
    this.balance$ = this.invoice$.pipe(invoiceToBalance());
    this.isPaid$ = this.invoice$.pipe(
      map((invoice) => invoice.status === InvoiceStatus.Paid)
    );
  }
}
