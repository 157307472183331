import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  type IInvoiceReportRequest,
  type IPractitionerGrouping,
} from '@principle-theorem/reporting';
import { serialise, unserialise$ } from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import {
  clearSelectedResult,
  inspectResult,
  setQuery,
  setReportType,
} from './practitioner-income.actions';
import {
  type IPractitionerIncomeReportState,
  type PractitionerIncomeReportType,
} from './practitioner-income.reducers';
import {
  getErrorMessage,
  getIncomeReport,
  getInspected,
  getIsLoading,
  getQuery,
  getReportType,
} from './practitioner-income.selectors';

@Injectable()
export class PractitionerIncomeReportFacade {
  private _store = inject(Store<IPractitionerIncomeReportState>);
  query$: Observable<IInvoiceReportRequest | undefined>;
  reportType$: Observable<PractitionerIncomeReportType>;
  results$: Observable<IPractitionerGrouping[]>;
  isLoading$: Observable<boolean>;
  errorMessage$: Observable<string | undefined>;
  inspected$: Observable<IPractitionerGrouping | undefined>;

  constructor() {
    this.query$ = this._store.pipe(select(getQuery), unserialise$());
    this.reportType$ = this._store.pipe(select(getReportType), unserialise$());
    this.results$ = this._store.pipe(select(getIncomeReport), unserialise$());
    this.isLoading$ = this._store.pipe(select(getIsLoading), unserialise$());
    this.errorMessage$ = this._store.pipe(
      select(getErrorMessage),
      unserialise$()
    );
    this.inspected$ = this._store.pipe(select(getInspected), unserialise$());
  }

  setQuery(query: IInvoiceReportRequest): void {
    this._store.dispatch(setQuery(serialise(query)));
  }

  setReportType(reportType: PractitionerIncomeReportType): void {
    this._store.dispatch(setReportType({ reportType }));
  }

  inspect(practitioner: IPractitionerGrouping): void {
    this._store.dispatch(inspectResult(serialise(practitioner)));
  }

  clearInspect(): void {
    this._store.dispatch(clearSelectedResult());
  }
}
