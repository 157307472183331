import {
  ITranslationMap,
  type ICustomMappingSourceOption,
} from '@principle-theorem/principle-core/interfaces';
import {
  type AtLeast,
  type IXSLXExport,
  type XSLXCell,
} from '@principle-theorem/shared';
import type { Column } from 'exceljs';

export interface IAllergyToMedicalHistoryXSLX {
  sourceId: string;
  sourceName: string;
  mapTo: string;
}

export interface IMedicalHistoryOption {
  label: string;
  destinationValue: string;
  associatedValue?: string;
}

export const ALLERGY_TO_MEDICAL_HISTORY_HEADERS: AtLeast<
  Column,
  'key' | 'header'
>[] = [
  {
    key: 'sourceId',
    header: 'Id',
  },
  {
    key: 'sourceName',
    header: 'Patient Note Category',
    width: 45,
  },
  {
    key: 'mapTo',
    header: 'Map To',
    width: 30,
  },
];

export class AllergyToMedicalHistoryToXSLX
  implements
    IXSLXExport<ICustomMappingSourceOption, IAllergyToMedicalHistoryXSLX>
{
  headers = ALLERGY_TO_MEDICAL_HISTORY_HEADERS;

  constructor(
    private _medicalHistoryOptions: IMedicalHistoryOption[],
    private _existingMappings: ITranslationMap<object, string>[]
  ) {}

  translate(
    records: ICustomMappingSourceOption[]
  ): Record<keyof IAllergyToMedicalHistoryXSLX, XSLXCell>[] {
    return records.map((record) => {
      const existingRecord = this._existingMappings.find(
        (existingMapping) => existingMapping.sourceLabel === record.label
      );

      const destinationValue = existingRecord?.destinationValue;
      const mappedValue = destinationValue
        ? this._medicalHistoryOptions.find(
            (medicalHistoryOption) =>
              medicalHistoryOption.destinationValue ===
                existingRecord.destinationValue &&
              medicalHistoryOption.associatedValue ===
                existingRecord.associatedValue
          )
        : undefined;

      return {
        sourceId: {
          value: record.value,
        },
        sourceName: {
          value: record.label,
        },
        mapTo: {
          value: mappedValue?.label,
          dataValidation: {
            type: 'list',
            formulae: [
              `"${this._medicalHistoryOptions
                .map((medicalHistoryOption) => medicalHistoryOption.label)
                .join(',')}"`,
            ],
          },
        },
      };
    });
  }
}
