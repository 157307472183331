import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';
import { toMentionContent, toTextContent } from '@principle-theorem/editor';
import { DateService } from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  Appointment,
  Candidate,
  type IMoveAppointmentData,
  Interaction,
  toMention,
} from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type ICandidate,
  type ICandidateCalendarEvent,
  type IInteractiveResource,
  InteractionType,
  type IPrincipleMention,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  doc$,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MoveAppointmentComponent } from '../move-appointment/move-appointment.component';

@Component({
    selector: 'pr-candidate-detail',
    templateUrl: './candidate-detail.component.html',
    styleUrls: ['./candidate-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CandidateDetailComponent {
  interactiveResource$: Observable<IInteractiveResource>;
  gapCandidate$: Observable<WithRef<ICandidateCalendarEvent>>;
  candidate$: Observable<ICandidate>;
  appointment$: Observable<WithRef<IAppointment>>;
  mentionResource$ = new ReplaySubject<IPrincipleMention>(1);

  isApproved$: Observable<boolean>;
  isUnavailable$: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IMoveAppointmentData,
    private _dialog: MatDialog,
    public dateService: DateService
  ) {
    this.gapCandidate$ = doc$(data.gapCandidate.ref);
    this.candidate$ = this.gapCandidate$.pipe(
      map((gapCandidate) => gapCandidate.candidate)
    );

    this.isApproved$ = this.gapCandidate$.pipe(
      map((gapCandidate) => Candidate.isApproved(gapCandidate.candidate))
    );
    this.isUnavailable$ = this.gapCandidate$.pipe(
      map((gapCandidate) => Candidate.isUnavailable(gapCandidate.candidate))
    );

    this.appointment$ = doc$(data.appointment.ref);
    this.mentionResource$.next(
      toMention(
        data.gapCandidate.candidate.patient,
        MentionResourceType.Candidate
      )
    );
    this.interactiveResource$ = this.appointment$.pipe(
      map((appointment) => ({
        interactions$: Appointment.interactions$(appointment),
        add: async (interaction) => {
          await Appointment.addInteraction(appointment, interaction);
        },
      }))
    );
  }

  duration(appointment: WithRef<IAppointment>): number {
    return Appointment.duration(appointment);
  }

  async updateAvailability(): Promise<void> {
    const gapCandidate = await snapshot(this.gapCandidate$);
    const availability = Candidate.isUnavailable(gapCandidate.candidate)
      ? `marked available`
      : `marked unavailable`;

    await patchDoc(gapCandidate.ref, {
      candidate: Candidate.toggleAvailable(gapCandidate.candidate),
    });

    const appointment = await snapshot(this.appointment$);
    const interaction = Interaction.init({
      type: InteractionType.Gap,
      title: [
        toMentionContent(
          toMention(this.data.patient, MentionResourceType.Patient)
        ),
        toTextContent(` ${availability}`),
      ],
    });

    await Appointment.addInteraction(appointment, interaction);
  }

  openMoveAppointment(): void {
    const config: MatDialogConfig = DialogPresets.fullscreen({
      data: this.data,
    });
    this._dialog.open<MoveAppointmentComponent, IMoveAppointmentData>(
      MoveAppointmentComponent,
      config
    );
  }
}
