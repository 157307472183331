<ng-container *ngIf="buttonType$ | async as buttonType">
  <button
    *ngIf="buttonType === 'submenu'"
    class="flex grow !justify-start"
    mat-menu-item
    (click)="runCommand()"
  >
    <div class="flex items-center p-1">
      <mat-icon>camera_enhance</mat-icon>
      <span>Generate an Image</span>
    </div>
  </button>

  <div class="block" *ngIf="buttonType === 'block'">
    <button
      fxFill
      mat-button
      [ngClass]="{ active: isActive$ | async, disabled: isDisabled$ | async }"
      (click)="runCommand()"
    >
      <div class="flex items-center p-1">
        <div class="icon">
          <mat-icon>{{ icon }}</mat-icon>
        </div>
        <span>{{ buttonText }}</span>
        <span fxFlex></span>
        <span class="shortcut" *ngIf="shortcut">{{
          shortcut | translateForPlatform
        }}</span>
      </div>
    </button>
  </div>
</ng-container>
