<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="16px"
  fxFill
>
  <div
    class="type-icon {{ schedulingAlert.type }}"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <mat-icon>{{ icon }}</mat-icon>
  </div>
  <p fxFlex="25" class="mat-header-title">{{ schedulingAlert.title }}</p>
  <p fxFlex="25" class="truncate">
    {{
      schedulingAlert.deadline
        | moment
        | amCalendar: dateService.format.dateWithTimes
    }}
    <span *ngIf="duration">({{ duration }} mins)</span>
  </p>
  <p fxFlex class="truncate" [ptTruncateTooltip]="schedulingAlert.description">
    {{ schedulingAlert.description }}
  </p>

  <div *ngIf="isAppointment">
    <pr-appointment-menu [appointment]="target" [patient]="patient" />
  </div>
</div>
