import * as moment from 'moment-timezone';
import {
  Component,
  Output,
  EventEmitter,
  type OnDestroy,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { type MatSelectChange } from '@angular/material/select';
import { takeUntil } from 'rxjs/operators';
import { MOMENT_DATEPICKER_PROVIDERS } from '../moment-datepicker-providers';
import { TrackByFunctions } from '../track-by';
import { TypedFormControl, TypedFormGroup } from '../forms/typed-form-group';
import { type Moment } from 'moment-timezone';
import { type ITimePeriod } from '@principle-theorem/shared';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'pt-date-range-form',
  templateUrl: './date-range-form.component.html',
  styleUrls: ['./date-range-form.component.scss'],
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeFormComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByDate = TrackByFunctions.label<IPredefinedDate>();
  dateRange: TypedFormGroup<IDateRangeFormData> =
    new TypedFormGroup<IDateRangeFormData>({
      from: new TypedFormControl(
        moment().subtract({ days: 7 }),
        Validators.required
      ),
      to: new TypedFormControl(moment(), Validators.required),
      predefinedDate: new TypedFormControl(),
    });
  predefinedDates: IPredefinedDate[] = PREDEFINED_DATES;
  @Output()
  changed: EventEmitter<ITimePeriod> = new EventEmitter<ITimePeriod>();
  @Output()
  fromChange = new EventEmitter<Moment>();
  @Output()
  toChange = new EventEmitter<Moment>();

  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() useRangePicker: boolean = false;

  constructor() {
    this.dateRange.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this.submit());
    this.dateRange.controls.from.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => this.fromChange.emit(value?.startOf('day')));
    this.dateRange.controls.to.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => this.toChange.emit(value?.endOf('day')));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  submit(): void {
    if (
      this.dateRange.invalid ||
      !this.dateRange.value.from ||
      !this.dateRange.value.to
    ) {
      return;
    }

    this.changed.emit({
      from: this.dateRange.value.from.startOf('day'),
      to: this.dateRange.value.to.endOf('day'),
    });
  }

  @Input()
  set from(from: moment.Moment) {
    this.dateRange.controls.from.setValue(from);
  }

  @Input()
  set to(to: moment.Moment) {
    this.dateRange.controls.to.setValue(to);
  }

  @Input()
  set range(range: ITimePeriod) {
    this.dateRange.patchValue(range);
  }

  updatePredefinedDate(event: MatSelectChange): void {
    const predefinedDate: IPredefinedDate = event.value as IPredefinedDate;
    this.dateRange.patchValue(predefinedDate.getDates());
  }
}

interface IDateRangeFormData extends ITimePeriod {
  predefinedDate: IPredefinedDate;
}

export interface IPredefinedDate {
  label: string;
  getDates: () => ITimePeriod;
}

// TODO: Build moments with timezone - CU-251edxw
const PREDEFINED_DATES: IPredefinedDate[] = [
  {
    label: 'Today',
    getDates: (): ITimePeriod => {
      const dateRange: ITimePeriod = {
        from: moment().startOf('day'),
        to: moment().endOf('day'),
      };
      return dateRange;
    },
  },
  {
    label: 'Yesterday',
    getDates: (): ITimePeriod => {
      const dateRange: ITimePeriod = {
        from: moment().subtract(1, 'day').startOf('day'),
        to: moment().subtract(1, 'day').endOf('day'),
      };
      return dateRange;
    },
  },
  {
    label: 'This Week',
    getDates: (): ITimePeriod => {
      const dateRange: ITimePeriod = {
        from: moment().startOf('week'),
        to: moment().endOf('week'),
      };
      return dateRange;
    },
  },
  {
    label: 'This Month',
    getDates: (): ITimePeriod => {
      const dateRange: ITimePeriod = {
        from: moment().startOf('month'),
        to: moment().endOf('month'),
      };
      return dateRange;
    },
  },
  {
    label: 'This Year',
    getDates: (): ITimePeriod => {
      const dateRange: ITimePeriod = {
        from: moment().startOf('year'),
        to: moment().endOf('year'),
      };
      return dateRange;
    },
  },
  {
    label: 'Last Week',
    getDates: (): ITimePeriod => {
      const dateRange: ITimePeriod = {
        from: moment().subtract(1, 'week').startOf('week'),
        to: moment().subtract(1, 'week').endOf('week'),
      };
      return dateRange;
    },
  },
  {
    label: 'Last Month',
    getDates: (): ITimePeriod => {
      const dateRange: ITimePeriod = {
        from: moment().subtract(1, 'month').startOf('month'),
        to: moment().subtract(1, 'month').endOf('month'),
      };
      return dateRange;
    },
  },
  {
    label: 'Last 3 Months',
    getDates: (): ITimePeriod => {
      const dateRange: ITimePeriod = {
        from: moment().subtract(3, 'months').startOf('month'),
        to: moment().subtract(1, 'month').endOf('month'),
      };
      return dateRange;
    },
  },
  {
    label: 'Last 7 Days',
    getDates: (): ITimePeriod => {
      const dateRange: ITimePeriod = {
        from: moment().subtract(8, 'days').startOf('day'),
        to: moment().subtract(1, 'day').endOf('day'),
      };
      return dateRange;
    },
  },
  {
    label: 'Last 30 Days',
    getDates: (): ITimePeriod => {
      const dateRange: ITimePeriod = {
        from: moment().subtract(31, 'day').startOf('day'),
        to: moment().subtract(1, 'day').endOf('day'),
      };
      return dateRange;
    },
  },
  {
    label: 'All Time',
    getDates: (): ITimePeriod => {
      const dateRange: ITimePeriod = {
        from: moment().startOf('year').year(1900),
        to: moment().endOf('year').year(2100),
      };
      return dateRange;
    },
  },
];
