import { type Attributes } from 'xml-js';

export interface IEasyclaimBulkBillServiceAttrs extends Attributes {
  /**
   * Medicare Benefits Scheme Item Number
   * eg 23
   */
  mbsItemNum: string;

  /**
   * YYYY-MM-DD
   * eg. $$DATE$$
   */
  dateOfService: string;

  /**
   * Index of line item
   * eg. 0001
   */
  serviceId: string;

  /**
   *  AP = Not duplicate service
   * AO = Not normal aftercare
   */
  itemOverrideCde?: 'AP' | 'AO';

  /**
   * Location Specific Practice Number
   * eg. 1
   */
  lspnNum?: string;
}

export interface IEasyclaimFullyPaidServiceAttrs
  extends IEasyclaimBulkBillServiceAttrs {
  /**
   * In cents
   * eg. 5000
   */
  chargeAmount: string;
}

export interface IEasyclaimPartPaidServiceAttrs
  extends IEasyclaimFullyPaidServiceAttrs {
  /**
   * Should be rounded to the nearest five cents
   * eg. 1000
   */
  patientContribAmt: string;
}

/**
 * Used for both bulk bill & gap payments
 */
export interface IEasyclaimPatientAttrs extends Attributes {
  /**
   * eg. 2950774911
   */
  memberNum: string;

  /**
   * eg. 1
   */
  memberRefNum: string;
}

/**
 * Used for both bulk bill & gap payments
 */
export interface IEasyclaimServicingProviderAttrs extends Attributes {
  /**
   * eg. 2147661H
   */
  providerNum: string;
}

/**
 * Valid values:
 *  L = Lost
 *  E = Emergency
 *  N = Not required [9201]
 */
export type ReferralOverrideTypeCde = 'L' | 'E' | 'N';

/**
 * Valid values
 *  O = General Practitioner
 *  S = Specialist
 *  D = Diagnostic Imaging
 */
export type ServiceTypeCode = 'O' | 'S' | 'D';

/**
 * Used for both bulk bill & gap payments
 */
export interface IEasyclaimServicingVoucherAttrs extends Attributes {
  /**
   * eg. 01
   */
  voucherId: string;

  /**
   * When O, no request elements and no referral elements can be set [2030]
   * When S, referral elements should be populated and only provided
   * (no request elements)[2030] OR only referralOverrideTypeCde [2030]
   * When D, request elements should be populated and only provided (no
   * referral elements) [2030] OR only requestOverrideTypeCde [2030] OR only
   * SelfDeemed ='SD' [9207].
   * When not set [9325]
   * eg. 0
   */
  serviceTypeCde: ServiceTypeCode;
  referralOverrideTypeCde?: ReferralOverrideTypeCde;
}

export interface IEasyclaimServicingFullyPaidClaimAttrs extends Attributes {
  accountPaidInd: 'Y';
}

export interface IEasyclaimServicingPartlyPaidClaimAttrs extends Attributes {
  accountPaidInd: 'N';
  /**
   * Used for unpaid accounts
   */
  accountReferenceNum?: string;
}

/**
 * Valid values:
 *  S = Standard
 *  N = Non standard
 *  I = Indefinite
 */
export type PeriodTypeCde = 'S' | 'N' | 'I';

export interface IEasyclaimReferralAttrs extends Attributes {
  periodTypeCde: PeriodTypeCde;

  /**
   * YYYY-MM-DD
   */
  dateOfIssue: string;
}

export interface IEasyclaimReferralProviderAttrs extends Attributes {
  providerNum: string;
}

export const ECLAIMING_REQUEST_ATTRS: Record<string, string> = {
  'xmlns:ns1': 'http://medicareaustralia.gov.au/eclaiming/version-2',
};
