import {
  AtLeast,
  INamedDocument,
  IXSLXExport,
  XSLXCell,
  isSameRef,
} from '@principle-theorem/shared';
import { Column } from 'exceljs';
import {
  IFeeSchedule,
  ITranslationMap,
} from '@principle-theorem/principle-core/interfaces';
import { IBaseMigrationFeeSchedule } from './fee-schedules';

export interface IFeeScheduleToXSLX {
  label: string;
  mapTo: string;
}

export const FEE_SCHEDULE_HEADERS: AtLeast<Column, 'key' | 'header'>[] = [
  {
    key: 'label',
    header: 'Fee Schedule',
  },
  {
    key: 'mapTo',
    header: 'Map To',
  },
];

export class FeeSchedulesToXSLX
  implements IXSLXExport<IBaseMigrationFeeSchedule, IFeeScheduleToXSLX>
{
  headers = FEE_SCHEDULE_HEADERS;

  constructor(
    private _feeSchedules: INamedDocument<IFeeSchedule>[],
    private _existingMappings: ITranslationMap<IFeeSchedule>[]
  ) {}

  translate(
    records: IBaseMigrationFeeSchedule[]
  ): Record<keyof IFeeScheduleToXSLX, XSLXCell>[] {
    return records.map((record) => {
      const existingRecord = this._existingMappings.find(
        (existingMapping) => existingMapping.sourceIdentifier === record.name
      );
      const destinationIdentifier = existingRecord?.destinationIdentifier;
      const mappedCategory = destinationIdentifier
        ? this._feeSchedules.find((category) =>
            isSameRef(category.ref, destinationIdentifier)
          )
        : undefined;

      return {
        label: { value: record.name },
        mapTo: {
          value: mappedCategory?.name || '',
          dataValidation: {
            type: 'list',
            formulae: [
              `"${this._feeSchedules
                .map((category) => category.name)
                .join(',')}"`,
            ],
          },
        },
      };
    });
  }
}
