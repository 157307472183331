import { randFullName } from '@ngneat/falso';
import {
  AppointmentDependencyType,
  IAppointment,
  IAppointmentDependency,
} from '@principle-theorem/principle-core/interfaces';
import { BaseMock, Properties } from '@principle-theorem/shared';
import { DocumentReference } from '@principle-theorem/shared';
import { MockDocRef } from '@principle-theorem/testing';

export class AppointmentDependencyMock
  extends BaseMock
  implements Properties<IAppointmentDependency>
{
  get name(): string {
    return `${randFullName()}`;
  }

  get ref(): DocumentReference<IAppointment> {
    return MockDocRef<IAppointment>();
  }

  get type(): AppointmentDependencyType {
    return AppointmentDependencyType.LabJob;
  }
}
