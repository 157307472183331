import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './components/components.module';
import { AccountingFunctionsService } from './accounting-functions.service';

@NgModule({
  imports: [CommonModule, ComponentsModule],
  exports: [ComponentsModule],
  providers: [AccountingFunctionsService],
})
export class NgPrincipleAccountingModule {}
