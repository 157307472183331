<ng-container *ngrxLet="content$ as content">
  <button
    class="opacity-90"
    mat-icon-button
    matTooltip="Edit Pack Quantities"
    [disabled]="!content.length"
    (click)="$event.preventDefault(); editPackContent()"
  >
    <mat-icon fontSet="material-symbols-outlined">inventory</mat-icon>
  </button>
</ng-container>
