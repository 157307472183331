import { initVersionedSchema, MixedSchema } from '@principle-theorem/editor';
import { WaitListItem } from '@principle-theorem/principle-core';
import {
  ADD_TO_WAITLIST_BY_DEFAULT,
  WaitListUrgency,
  type IAppointment,
  type IWaitListItem,
} from '@principle-theorem/principle-core/interfaces';
import {
  toMoment,
  toTimestamp,
  WEEK_DAYS,
  WEEKEND_DAYS,
  type DayOfWeek,
  type Timezone,
} from '@principle-theorem/shared';
import { zipObject } from 'lodash';
import * as moment from 'moment-timezone';

export interface IWaitListDays {
  [key: string]: boolean;
}

export interface IWaitListDetails {
  addToWaitlist: boolean;
  urgency: WaitListUrgency;
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
  timeFrom?: string;
  timeTo?: string;
  days: IWaitListDays;
  notes: MixedSchema;
}

function buildWaitlistDays(): IWaitListDays {
  const week = zipObject(
    WEEK_DAYS,
    WEEK_DAYS.map(() => true)
  );
  const weekend = zipObject(
    WEEKEND_DAYS,
    WEEKEND_DAYS.map(() => false)
  );
  return {
    ...week,
    ...weekend,
  };
}

export function initWaitListDetails(
  overrides?: Partial<IWaitListDetails>
): IWaitListDetails {
  return {
    addToWaitlist: ADD_TO_WAITLIST_BY_DEFAULT,
    urgency: WaitListUrgency.Low,
    dateFrom: moment().startOf('day'),
    dateTo: moment().add(1, 'week'),
    days: buildWaitlistDays(),
    notes: initVersionedSchema(),
    ...overrides,
  };
}

export function getWaitListDetailsFromAppointment(
  appointment: IAppointment
): IWaitListDetails {
  const waitListItem = appointment.waitListItem;
  if (!waitListItem) {
    return initWaitListDetails({ addToWaitlist: false });
  }

  return initWaitListDetails({
    timeFrom: waitListItem.timeFrom,
    timeTo: waitListItem.timeTo,
    urgency: waitListItem.urgency,
    notes: waitListItem.notes ?? initVersionedSchema(),
    dateFrom: toMoment(waitListItem.dateFrom),
    dateTo: toMoment(waitListItem.dateTo),
    days: zipObject(
      WEEK_DAYS,
      waitListItem.days.map(() => true)
    ),
  });
}

export function convertWaitListDetailsToItem(
  data: IWaitListDetails,
  timezone: Timezone
): IWaitListItem | undefined {
  if (!data || !data.addToWaitlist) {
    return;
  }

  const days = Object.keys(data.days).reduce(
    (lastKey: DayOfWeek[], key: string): DayOfWeek[] => {
      return data.days[key] ? [...lastKey, key as DayOfWeek] : lastKey;
    },
    []
  );

  const availableDates = WaitListItem.determineAvailableDates(
    {
      from: data.dateFrom,
      to: data.dateTo,
    },
    days,
    timezone
  );

  return WaitListItem.init({
    dateFrom: toTimestamp(data.dateFrom),
    dateTo: toTimestamp(data.dateTo),
    timeFrom: data.timeFrom,
    timeTo: data.timeTo,
    urgency: data.urgency,
    notes: data.notes,
    availableDates,
    days,
  });
}

export function convertWaitListItemToWaitListDetails(
  data: IWaitListItem
): Partial<IWaitListDetails> {
  if (!data) {
    return {
      addToWaitlist: false,
    };
  }

  const days: IWaitListDays = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };

  data.days.map((day: string) => {
    days[day as keyof IWaitListDays] = true;
  });

  return {
    timeFrom: data.timeFrom,
    timeTo: data.timeTo,
    urgency: data.urgency,
    notes: data.notes,
    dateFrom: toMoment(data.dateFrom),
    dateTo: toMoment(data.dateTo),
    days,
  };
}
