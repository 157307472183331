import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { FeeScheduleFacade } from '@principle-theorem/ng-clinical-charting/store';
import {
  TrackByFunctions,
  TypedFormControl,
  validFormControlChanges$,
} from '@principle-theorem/ng-shared';
import { getDefaultFeeSchedule } from '@principle-theorem/principle-core';
import {
  type IFeeSchedule,
  type IFeeScheduleGroup,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isChanged$,
  isSameRef,
  shareReplayCold,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-staff-fee-schedule-selector',
    templateUrl: './staff-fee-schedule-selector.component.html',
    styleUrls: ['./staff-fee-schedule-selector.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StaffFeeScheduleSelectorComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  groups$: Observable<IFeeScheduleGroup[]>;
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  trackByGroup = TrackByFunctions.field<IFeeScheduleGroup>('name');
  trackBySchedule = TrackByFunctions.ref<WithRef<IFeeSchedule>>();
  selectedSchedule = new TypedFormControl<WithRef<IFeeSchedule>>();
  @Input() disabled = false;
  @Output() scheduleSelected = new EventEmitter<WithRef<IFeeSchedule>>();
  @Input() appearance: MatFormFieldAppearance = 'outline';

  @Input()
  set schedule(schedule: WithRef<IFeeSchedule>) {
    if (schedule) {
      this.selectedSchedule.setValue(schedule, { emitEvent: false });
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(private _feeScheduleStore: FeeScheduleFacade) {
    this.groups$ = this._feeScheduleStore.feeScheduleGroups$.pipe(
      shareReplayCold()
    );

    validFormControlChanges$(this.selectedSchedule)
      .pipe(isChanged$(), takeUntil(this._onDestroy$))
      .subscribe((value) => this.scheduleSelected.emit(value));

    this._getDefaultFeeSchedule$()
      .pipe(filterUndefined(), takeUntil(this._onDestroy$))
      .subscribe((defaultSchedule) => {
        if (!this.selectedSchedule.value) {
          this.selectedSchedule.setValue(defaultSchedule);
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  compareFn(
    value: WithRef<IFeeSchedule>,
    schedule: WithRef<IFeeSchedule>
  ): boolean {
    if (!value || !schedule) {
      return false;
    }
    return isSameRef(value, schedule);
  }

  private _getDefaultFeeSchedule$(): Observable<
    WithRef<IFeeSchedule> | undefined
  > {
    return combineLatest([this.patient$, this.groups$]).pipe(
      switchMap(([patient, groups]) => getDefaultFeeSchedule(groups, patient))
    );
  }
}
