import { Directive, HostBinding } from '@angular/core';
import {
  timeFromStartOfDay,
  getCurrentTime,
  MINUTES_IN_DAY,
} from '@principle-theorem/shared';

@Directive({
    selector: '[prNowLine]',
    standalone: false
})
export class NowLineDirective {
  @HostBinding('style.top')
  get top(): string {
    const now = timeFromStartOfDay(getCurrentTime(), 'minutes');
    const delta = now / MINUTES_IN_DAY;
    const top = delta * 960;
    return `${top - 1}px`;
  }
}
