import { Injectable } from '@angular/core';
import {
  CALENDAR_FORMAT,
  CALENDAR_TIME_FORMAT,
} from '@principle-theorem/shared';

interface IDateFormatOptions {
  calendar: Record<string, string>;
  dateWithTimes: Record<string, string>;
}

@Injectable()
export class DateService {
  format: IDateFormatOptions = {
    calendar: CALENDAR_FORMAT,
    dateWithTimes: CALENDAR_TIME_FORMAT,
  };
}
