import {
  IBrand,
  IInvoice,
  IOrganisation,
  IPatient,
  IPatientViewInvoiceTokenData,
} from '@principle-theorem/principle-core/interfaces';
import { getDoc, getParentDocRef, WithRef } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { createCSRF } from '@principle-theorem/temporary-tokens';
import { Organisation } from '../organisation/organisation';

export async function generateViewInvoiceToken(
  org: WithRef<IOrganisation>,
  invoice: WithRef<IInvoice>
): Promise<string> {
  const tokenExpiry = moment().add(30, 'day');
  const tokenData: IPatientViewInvoiceTokenData = {
    invoice: invoice.ref,
  };
  return createCSRF(
    Organisation.temporaryTokenCol(org),
    tokenData,
    tokenExpiry,
    true
  );
}

export async function generateViewInvoiceUrl(
  invoice: WithRef<IInvoice>,
  appUrl: string
): Promise<string> {
  const patientRef = getParentDocRef<IPatient>(invoice.ref.path);
  const brandRef = getParentDocRef<IBrand>(patientRef.path);
  const orgRef = getParentDocRef<IOrganisation>(brandRef.path);
  const token = await generateViewInvoiceToken(await getDoc(orgRef), invoice);

  return [appUrl, 'invoice', token].join('/');
}
