import { ITransaction } from '@principle-theorem/principle-core/interfaces';
import {
  DocumentArchive,
  getDoc,
  patchDoc,
  Reffable,
} from '@principle-theorem/shared';
import { Transaction as FirebaseTransaction } from '@principle-theorem/shared';
import { Transaction } from './transaction';

export async function amendTransaction(
  transaction: Reffable<ITransaction>,
  atomicTransaction?: FirebaseTransaction
): Promise<void> {
  const historyRef = await DocumentArchive.snapshotToArchive(
    await getDoc(transaction.ref),
    Transaction.amendmentHistoryCol(transaction),
    undefined,
    undefined,
    atomicTransaction
  );
  await patchDoc(
    transaction.ref,
    {
      amendmentOf: historyRef,
    },
    atomicTransaction
  );
}
