import { MixedSchema } from '@principle-theorem/editor';
import {
  TypeGuard,
  isDocRef,
  isEnumValue,
  isObject,
  type DocumentReference,
  isWithRef,
  WithRef,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import {
  isAutomationConfiguration,
  type IAutomationConfiguration,
  type IAutomationResource,
  isAutomation,
  IAutomation,
} from '../automation/automation';
import { isAutomationTiming } from '../automation/automation-timing';
import { type ITemplateDefinition } from '../templating/template-definition';
import { type TemplateScope } from '../templating/template-scope';

export interface IAutomatedNotificationConfiguration
  extends IAutomationConfiguration,
    Omit<IAutomatedNotification, 'customTemplate'> {
  templateRef: DocumentReference<ITemplateDefinition>;
}

export function isAutomatedNotificationConfiguration(
  data: unknown
): data is IAutomatedNotificationConfiguration {
  const extraPropertiesGuard = TypeGuard.interface<
    Pick<IAutomatedNotificationConfiguration, 'templateRef'>
  >({
    templateRef: isDocRef,
  });
  return (
    isAutomationConfiguration(data) &&
    isAutomatedNotification(data) &&
    extraPropertiesGuard(data)
  );
}

export function getAutomatedNotificationFromConfig(
  config: IAutomatedNotificationConfiguration
): IAutomatedNotification {
  return {
    name: config.name,
    subject: config.subject,
    type: config.type,
    timing: config.timing,
    requiredConditions: config.requiredConditions,
  };
}

export interface IAutomatedNotificationCustomTemplate {
  content: MixedSchema;
  scope?: TemplateScope;
  renderedTemplate?: IRenderedTemplate;
}

export interface IAutomatedNotification extends IAutomationResource {
  name: string;
  subject: string;
  type: AutomatedNotificationType;
  customTemplate?: IAutomatedNotificationCustomTemplate;
}

export interface IRenderedTemplate {
  text: string;
  html: string;
}

export const isRenderedTemplate = TypeGuard.interface<IRenderedTemplate>({
  text: isString,
  html: isString,
});

export const EMPTY_RENDERED_TEMPLATE: IRenderedTemplate = {
  text: '',
  html: '',
};

export enum AutomatedNotificationType {
  SMS = 'sms',
  EMAIL = 'email',
}

export const AUTOMATED_NOTIFICATION_TYPES: AutomatedNotificationType[] = [
  AutomatedNotificationType.EMAIL,
  AutomatedNotificationType.SMS,
];

export function isAutomatedNotification(
  data: unknown
): data is IAutomatedNotification {
  return (
    isObject(data) &&
    isString(data.name) &&
    isAutomationTiming(data.timing) &&
    'type' in data &&
    isEnumValue(AutomatedNotificationType, data.type)
  );
}

export function isNotificationAutomation(
  automation: unknown
): automation is WithRef<IAutomation<IAutomatedNotification>> {
  return (
    isWithRef(automation) &&
    isAutomation<IAutomatedNotification>(automation, isAutomatedNotification)
  );
}
