<ng-container *ngIf="resource$ | async as resource">
  <pt-buttons-container>
    <button mat-flat-button color="primary" (click)="useExistingConfig()">
      Use Existing Task
    </button>
    <button mat-flat-button color="primary" (click)="createAutomationConfig()">
      Create Task
    </button>
  </pt-buttons-container>

  <ng-container *ngIf="generatedTasks$ | async as generatedTasks">
    <ng-container *ngIf="generatedTasks.length; else noGeneratedTasks">
      <ng-container *ngFor="let task of generatedTasks; trackBy: trackByTask">
        <div class="flex items-center">
          <div class="task-list flex-1">
            <h3><pr-content-view [content]="task.title" /></h3>
            <p *ngIf="!task.isForTeam" class="mat-caption">
              {{ task.assignee?.name }}
            </p>
            <p *ngIf="task.isForTeam" class="mat-caption">
              {{ task.assignee?.name }} Team
            </p>
            <p class="mat-caption">
              {{ task.timing | prTimingDisplay }}
            </p>
          </div>
          <div class="flex items-center justify-end">
            <button mat-icon-button (click)="edit(task)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="delete(task)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #noGeneratedTasks>
    <pr-empty-state image="task" title="generated tasks" />
  </ng-template>
</ng-container>
