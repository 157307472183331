import { NgModule } from '@angular/core';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ComponentsModule } from '../components/components.module';
import { ContactListComponent } from './contact-list/contact-list.component';
import { ContactResolverService } from './contact-resolver.service';
import { ContactCardComponent } from './contact-tabs/contact-profile/contact-card/contact-card.component';
import { ContactProfileComponent } from './contact-tabs/contact-profile/contact-profile.component';
import { ContactTabsComponent } from './contact-tabs/contact-tabs.component';
import { ContactTimelineComponent } from './contact-tabs/contact-timeline/contact-timeline.component';
import { SubContactListComponent } from './contact-tabs/sub-contact-list/sub-contact-list.component';

@NgModule({
  imports: [
    NgSharedModule,
    NgPrincipleSharedModule,
    NgInteractionsModule,
    ComponentsModule,
  ],
  declarations: [
    ContactListComponent,
    ContactProfileComponent,
    ContactCardComponent,
    SubContactListComponent,
    ContactTimelineComponent,
    ContactTabsComponent,
  ],
  exports: [SubContactListComponent],
  providers: [ContactResolverService],
})
export class ContactPagesModule {}
