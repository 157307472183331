<div class="inner flex flex-col gap-2" *ngIf="display$ | async as display">
  <div class="header flex">
    <div class="flex-1">
      <div class="mat-caption">{{ sectionName }}</div>
      <div>
        <h3>{{ display.chart | map: getLabel }}</h3>
      </div>
    </div>
    <div class="flex items-center">
      <button
        mat-icon-button
        color="primary"
        *ngIf="canResetFilter$ | async"
        matTooltip="Reset Filters"
        (click)="resetFilter()"
      >
        <mat-icon>restart_alt</mat-icon>
      </button>
      <ng-content select="report-builder-chart-action" />
    </div>
  </div>

  @if (display.chartDisplay) {
    <pr-dc-chart
      #dcChart
      [chartClasses]="display.chartDisplay.chartClasses"
      [chart]="display.chartDisplay.chart"
      [legend]="display.chartDisplay.legend"
      [tooltip]="display.chartDisplay.tooltip"
      [autoResize]="false"
      [crossfilter]="store.ndx$ | async"
      [hideReset]="true"
    />
  }

  @if (display.tableDisplay) {
    <pr-report-builder-table-chart
      [sectionName]="sectionName"
      [display]="display"
      [crossfilter]="store.ndx$ | async"
      [readOnly]="readOnly"
    />
  }

  <span fxFlex></span>

  <ng-content select="report-builder-chart-bottom" />
</div>
