import { MeasureFormatter } from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  DAY_MONTH_YEAR_FORMAT,
  ISO_DATE_FORMAT,
} from '@principle-theorem/shared';
import { camelCase, capitalize, endsWith, get } from 'lodash';
import { BigQueryTable } from '../big-query-tables';
import { IQueryScopeConfig } from '../query-scope-config/query-scope-config';
import { IReportingQuery, ITableJoin } from '../querying';
import { DataAccessorFactory, MeasurePath } from './data-accessor-factory';
import {
  ComparableProperties,
  IChartBuilderData,
  MeasureTransformMap,
  noFilterAccessor,
} from './measure-properties';
import {
  CanBeChartedProperty,
  CanDoAllProperty,
  CanQueryByTimestampProperty,
  IMeasureRef,
} from './measure-properties';
import { MeasurePropertyFactory } from './measure-property-factory';
import { QueryFactory } from './query-factory';

export const FACT_TABLE_PREFIX = 'fact';

export type FactTableInterface<T> = BaseFactMeasures &
  MeasureTransformMap<ComparableProperties<T>>;

export abstract class BaseMeasures {
  protected _query: Partial<IReportingQuery> = {
    attributes: [],
    filters: [],
    groupBy: [],
    orderBy: [],
  };
  abstract table: BigQueryTable;
  abstract id: string;
  scopes: IQueryScopeConfig<unknown>[] = [];

  get query(): Partial<IReportingQuery> {
    return this._query;
  }

  get timestamp(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('timestamp'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._factPropertyPath('timestamp'),
        label: 'Timestamp',
        summary: 'Date and time that the even occurred',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get count(): CanDoAllProperty {
    const propertyName = 'count';
    return new CanDoAllProperty({
      metadata: {
        id: `${this.id}.count`, // TODO: https://app.clickup.com/t/860pphajt
        label: `The Number of Unique ${capitalize(
          camelCase(this.table.replace('_event', 's').replace('_fact', ''))
        )}`,
        summary: '',
        formatter: MeasureFormatter.Number,
      },
      measure: {
        propertyName,
        query: this.buildQuery().get(),
        dataAccessor: (_fact) => 1,
        filterAccessor: noFilterAccessor(),
      },
      groupMeasure: {
        queryAttributes: [],
        dataAccessor: (_fact) => 1,
      },
    }).reduceByCount();
  }

  buildQuery(): QueryFactory {
    return QueryFactory.fromTable(this.table).override(this._query);
  }

  scopeBy(chartedProperty: CanBeChartedProperty): this {
    this._query = this.buildQuery()
      .override(chartedProperty.measure.query)
      .get();
    return this;
  }

  measureRef(path: string, isUnnestProperty: boolean = false): IMeasureRef {
    return {
      attributePath: this._getAttributePath(path),
      factPropertyPath: toFactPropertyName(
        this._factPropertyPath(path, isUnnestProperty)
      ),
    };
  }

  protected _pathWithPrefix(path: string): string {
    return [this.id, path].join('.');
  }

  protected _getAttributePath(path: string): string {
    const isLatestEvent = endsWith(this.id, `.${LATEST_EVENT_ID}`);
    if (isLatestEvent) {
      return path;
    }
    return this._pathWithPrefix(path);
  }

  protected _factPropertyPath(
    path: string,
    isUnnestProperty: boolean = false
  ): string {
    const prefixedPath = this._getAttributePath(path);
    const hasJoin = !!this.query?.joins?.length;
    if (hasJoin && !isUnnestProperty) {
      return prefixedPath;
    }
    return `${FACT_TABLE_PREFIX}.${prefixedPath}`;
  }
}

export abstract class BaseFactMeasures extends BaseMeasures {
  abstract name: string;
  abstract override scopes: IQueryScopeConfig<unknown>[];

  get timestampDay(): CanDoAllProperty {
    const propertyName = MeasurePath.timestamp('timestamp');
    const measure = this.measureRef(propertyName);
    return new CanDoAllProperty({
      metadata: {
        id: 'timestampDay',
        label: 'Timestamp',
        summary: '',
      },
      measure: {
        propertyName,
        query: this.buildQuery().attributes([measure.attributePath]).get(),
        dataAccessor: DataAccessorFactory.timestamp(
          this._factPropertyPath(propertyName),
          '',
          ISO_DATE_FORMAT
        ),
        filterAccessor: noFilterAccessor(),
      },
      groupMeasure: {
        queryAttributes: [measure.attributePath],
        dataAccessor: DataAccessorFactory.timestamp(
          this._factPropertyPath(propertyName),
          '',
          ISO_DATE_FORMAT
        ),
        labelAccessor: DataAccessorFactory.timestamp(
          this._factPropertyPath(propertyName),
          '',
          DAY_MONTH_YEAR_FORMAT
        ),
      },
    });
  }

  protected _getProperty<PropertyType>(
    fact: unknown,
    path: string,
    defaultValue: PropertyType
  ): PropertyType {
    return getProperty(fact, `${FACT_TABLE_PREFIX}.${path}`, defaultValue);
  }
}

export type BaseDimensionTableJoin = AtLeast<
  Omit<ITableJoin, 'table' | 'alias'>,
  'sourceJoinKey'
>;

export abstract class BaseDimensionMeasures extends BaseMeasures {
  constructor(
    public table: BigQueryTable,
    public id: string,
    tableJoin?: BaseDimensionTableJoin
  ) {
    super();

    if (tableJoin) {
      if (!this.query.joins) {
        this.query.joins = [];
      }
      this.query.joins.push({
        joinType: 'LEFT',
        destinationJoinKey: 'ref.referenceValue',
        ...tableJoin,
        table: this.table,
        alias: this.id,
      });
    }
  }

  /**
   * This path is no longer valid
   * @deprecated
   */
  protected _getProperty<PropertyType>(
    fact: unknown,
    path: string,
    defaultValue: PropertyType
  ): PropertyType {
    return getProperty(
      fact,
      this._factPropertyPath(this._pathWithPrefix(path)),
      defaultValue
    );
  }
}

function getProperty<PropertyType>(
  fact: unknown,
  path: string,
  defaultValue: PropertyType
): PropertyType {
  const factPropertyPath = toFactPropertyName(path);
  return (get(fact, factPropertyPath, defaultValue) ??
    defaultValue) as PropertyType;
}

export function generateBuilderData(
  builderData: Partial<IChartBuilderData>
): IChartBuilderData {
  return {
    plottedOverTime: false,
    accumulateOverTime: false,
    colourOverride: false,
    measures: [],
    ...builderData,
  };
}

export function toFactPropertyName(path: string, prefix?: string): string {
  const nestedPropertySeperator = '_';
  if (path.includes(nestedPropertySeperator)) {
    throw new Error(`Property contains nested property seperator: ${path}`);
  }
  const propertyPath = prefix ? `${prefix}.${path}` : path;
  return propertyPath.replace(/\./g, nestedPropertySeperator);
}

export function toBigQueryAlias(path: string, prefix?: string): string {
  const aliasSeperator = ' AS ';
  const isAlreadyAliased = path.toUpperCase().includes(aliasSeperator);
  if (isAlreadyAliased) {
    return path;
  }
  const alias = toFactPropertyName(path, prefix);
  return `${path}${aliasSeperator}${alias}`;
}

const LATEST_EVENT_ID = 'latestEvent';

export function latestEventId(measures: BaseMeasures): string {
  return `${measures.id}.${LATEST_EVENT_ID}`;
}
