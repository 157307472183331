import { LocationStrategy } from '@angular/common';
import {
  Attribute,
  Directive,
  ElementRef,
  Input,
  Renderer2,
  type OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { StateBasedNavigationService } from './state-based-navigation.service';

@Directive({
    selector: 'a[prBrandRouterLink], area[prBrandRouterLink]',
    standalone: false
})
export class BrandRouterLinkDirective extends RouterLink implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _segments$ = new Subject<string[]>();
  protected onChanges = new Subject<RouterLink>();

  constructor(
    private _stateNav: StateBasedNavigationService,
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _locationStrategy: LocationStrategy,
    _renderer: Renderer2,
    _el: ElementRef,
    // eslint-disable-next-line @angular-eslint/no-attribute-decorator
    @Attribute('tabindex') tabIndexAttribute: string | null | undefined
  ) {
    super(
      _router,
      _route,
      tabIndexAttribute,
      _renderer,
      _el,
      _locationStrategy
    );
    this._segments$
      .pipe(
        switchMap((segments) => this._stateNav.link.brand$(segments)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((segments) => {
        this.routerLink = segments;
      });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @Input()
  set prBrandRouterLink(segments: string[]) {
    if (segments) {
      this._segments$.next(segments);
    }
  }
}
