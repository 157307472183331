<mat-form-field>
  <mat-label>Select Practice</mat-label>
  <mat-select [formControl]="ctrl" [compareWith]="compareFn">
    <mat-option
      *ngFor="let practice of practices$ | async; trackBy: trackByPractice"
      [value]="practice"
    >
      {{ practice.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
