import {
  ITranslationMap,
  type ICustomMappingSourceOption,
  IManualTransactionType,
  TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import {
  isINamedDocument,
  type AtLeast,
  type IXSLXExport,
  type XSLXCell,
  INamedDocument,
  isSameRef,
} from '@principle-theorem/shared';
import type { Column } from 'exceljs';

export interface IPaymentTypeXSLX {
  sourceId: string;
  sourceName: string;
  mapTo: string;
}

export interface IBasePaymentTypeOption {
  label: string;
  destinationValue: TransactionProvider;
  associatedValue?: INamedDocument<IManualTransactionType>;
}

export const PAYMENT_TYPE_HEADERS: AtLeast<Column, 'key' | 'header'>[] = [
  {
    key: 'sourceId',
    header: 'Id',
  },
  {
    key: 'sourceName',
    header: 'Payment Type',
    width: 45,
  },
  {
    key: 'mapTo',
    header: 'Map To',
    width: 30,
  },
];

export class PaymentTypeToXSLX
  implements IXSLXExport<ICustomMappingSourceOption, IPaymentTypeXSLX>
{
  headers = PAYMENT_TYPE_HEADERS;

  constructor(
    private _paymentTypeOptions: IBasePaymentTypeOption[],
    private _existingMappings: ITranslationMap<
      IManualTransactionType,
      TransactionProvider
    >[]
  ) {}

  translate(
    records: ICustomMappingSourceOption[]
  ): Record<keyof IPaymentTypeXSLX, XSLXCell>[] {
    return records.map((record) => {
      const existingRecord = this._existingMappings.find(
        (existingMapping) => existingMapping.sourceLabel === record.label
      );

      const destinationValue = existingRecord?.destinationValue;
      const mappedValue = destinationValue
        ? this._paymentTypeOptions.find((medicalHistoryOption) => {
            const isDestinationValue =
              medicalHistoryOption.destinationValue ===
              existingRecord.destinationValue;

            if (!existingRecord.associatedValue) {
              return isDestinationValue;
            }

            const isAssociatedValue =
              isINamedDocument(existingRecord.associatedValue) &&
              isSameRef(
                medicalHistoryOption.associatedValue,
                existingRecord.associatedValue
              );

            return isDestinationValue && isAssociatedValue;
          })
        : undefined;

      return {
        sourceId: {
          value: record.value,
        },
        sourceName: {
          value: record.label,
        },
        mapTo: {
          value: mappedValue?.label,
          dataValidation: {
            type: 'list',
            formulae: [
              `"${this._paymentTypeOptions
                .map((paymentTypeOption) => paymentTypeOption.label)
                .join(',')}"`,
            ],
          },
        },
      };
    });
  }
}
