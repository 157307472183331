<mat-form-field
  class="compact"
  floatLabel="never"
  subscriptSizing="dynamic"
  fxFlex
>
  <mat-select [formControl]="templateCtrl" panelWidth>
    <ng-container *ngIf="options$ | async as options">
      <ng-container
        *ngFor="let option of options.groups; trackBy: options.trackByGroup"
      >
        <mat-optgroup [label]="option.group | splitCamel | titlecase">
          <mat-option
            *ngFor="
              let template of option.items;
              trackBy: options.trackByOption
            "
            [value]="template.value"
          >
            {{ template.label }}
          </mat-option>
        </mat-optgroup>
      </ng-container>
    </ng-container>
  </mat-select>

  <mat-label>
    <pt-loader-placeholder
      [loading]="loading$ | async"
      placeholder="Apply Template"
      loadingPlaceholder="Loading Templates..."
     />
  </mat-label>
</mat-form-field>
