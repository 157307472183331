import { type PracticeMigrationEssentials } from '@principle-theorem/principle-core/interfaces';
import { CORE_PRACTICE_DESTINATION } from './destination';
import { CORE_PRACTICE_MAPPINGS } from './mappings';
import { CORE_PRACTICE_SOURCE } from './source';

export const CORE_PRACTICE_MIGRATION: PracticeMigrationEssentials = {
  metadata: {
    name: 'Core Practice',
    version: 'v1',
    supportsMultiplePractices: false,
    description: ``,
  },
  source: CORE_PRACTICE_SOURCE,
  destination: CORE_PRACTICE_DESTINATION,
  mappings: CORE_PRACTICE_MAPPINGS,
};
