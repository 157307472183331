import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  DATE_FORMAT,
  isISODateType,
  splitCamel,
  titlecase,
} from '@principle-theorem/shared';
import moment from 'moment-timezone';
import { ReplaySubject } from 'rxjs';
import { IGroupedFollowUp } from '../follow-up-list-view/follow-up-list-view.component';

@Component({
  selector: 'pr-follow-up-list-grouped-items',
  templateUrl: './follow-up-list-grouped-items.component.html',
  styleUrl: './follow-up-list-grouped-items.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FollowUpListGroupedItemsComponent {
  groups$ = new ReplaySubject<IGroupedFollowUp[]>(1);
  hideDate$ = new ReplaySubject<boolean>(1);

  @Input()
  set groups(groups: IGroupedFollowUp[]) {
    if (groups) {
      this.groups$.next(groups);
    }
  }

  @Input()
  set hideDate(hideDate: BooleanInput) {
    this.hideDate$.next(coerceBooleanProperty(hideDate));
  }

  getGroupTitle(key: string): string {
    if (isISODateType(key)) {
      return moment(key).format(DATE_FORMAT);
    }

    return titlecase(splitCamel(key));
  }
}
