import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { type IPractice } from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  type ITimePeriod,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PractitionerTransactionsReportStore } from './practitioner-transactions-report.store';

export interface IReportRequest {
  dateRange: ITimePeriod;
  practices: WithRef<IPractice>[];
}

@Component({
  selector: 'pr-practitioner-transactions-report',
  templateUrl: './practitioner-transactions-report.component.html',
  styleUrls: ['./practitioner-transactions-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PractitionerTransactionsReportComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  request$ = new ReplaySubject<IReportRequest>(1);

  @Input()
  set request(request: IReportRequest) {
    if (request) {
      this.request$.next(request);
    }
  }

  constructor(public store: PractitionerTransactionsReportStore) {
    const query$ = this.request$.pipe(
      map(({ practices, dateRange }) => {
        const practice = first(practices);
        if (!practice) {
          return;
        }

        return {
          startDate: toTimestamp(dateRange.from),
          endDate: toTimestamp(dateRange.to),
          practiceRef: practice.ref,
        };
      }),
      filterUndefined()
    );

    this.store.loadTransactions(query$);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
