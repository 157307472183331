import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  type OnDestroy,
} from '@angular/core';
import {
  AppointmentSchedulingFacade,
  getSchedulingAlerts$,
  isDefaultOption,
  isTreatmentOption,
  type IAppointmentDetails,
  type IDefaultOption,
  type TreatmentPlanStepPair,
} from '@principle-theorem/ng-appointment/store';
import {
  AddChartable,
  AddTreatmentToTreatmentTemplateProvider,
  ChartableSurfaceUpdater,
  type IEditChartableData,
} from '@principle-theorem/ng-clinical-charting';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
  toSearchStream,
  validFormControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  Brand,
  ChartedTreatmentUpdater,
  FeeSchedule,
  Patient,
  TreatmentPlan,
  TreatmentStep,
  TreatmentTemplate,
} from '@principle-theorem/principle-core';
import {
  IAppointment,
  PatientRelationshipType,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  isTreatmentPlanWithBookableStep,
  isTreatmentTemplateWithStep,
  type AnyChartedItemConfiguration,
  type IChartedRef,
  type IChartedTreatment,
  type IEvent,
  type IFeeSchedule,
  type IPatient,
  type IPractice,
  type IStaffer,
  type ITreatmentCategory,
  type ITreatmentPlan,
  type ITreatmentPlanWithBookableStep,
  type ITreatmentStep,
  type ITreatmentStepDisplay,
  type ITreatmentTemplateWithStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  Firestore,
  isChanged$,
  isSameRef,
  isWithRef,
  multiMap,
  shareReplayCold,
  snapshot,
  type INamedDocument,
  type WithRef,
  distinctUntilPathsChange,
  debounceUserInput,
} from '@principle-theorem/shared';
import { get, isEqual, isNil, isString, sortBy } from 'lodash';
import {
  ReplaySubject,
  Subject,
  combineLatest,
  iif,
  of,
  type Observable,
  BehaviorSubject,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'pr-appointment-treatment-selector',
  templateUrl: './appointment-treatment-selector.component.html',
  styleUrls: ['./appointment-treatment-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentTreatmentSelectorComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByAlert = TrackByFunctions.variable<string>();
  schedulingAlerts$: Observable<string[]>;
  durationWarningMessage$: Observable<string>;
  treatmentStep$ = new ReplaySubject<
    WithRef<ITreatmentStep> | ITreatmentStep | undefined
  >(1);
  treatmentPlan$ = new ReplaySubject<
    WithRef<ITreatmentPlan> | ITreatmentPlan | undefined
  >(1);
  appointment$: Observable<WithRef<IAppointment> | undefined>;
  treatmentCtrl = new TypedFormControl<ITreatmentTemplateWithStep | string>();
  stepNameCtrl = new TypedFormControl<string>('');
  appointmentDetails$: Observable<IAppointmentDetails>;
  treatmentDisabled$: Observable<boolean>;
  selectedTreatment$: Observable<TreatmentPlanStepPair | undefined>;
  trackByTemplatePlanPair =
    TrackByFunctions.nestedField<ITreatmentTemplateWithStep>('step.name');
  treatmentTemplateSearchFilter: InputSearchFilter<ITreatmentTemplateWithStep>;
  treatmentPlanLink$: Observable<string[]>;
  filteredTemplates$: Observable<ITreatmentTemplateWithStep[]>;
  treatmentStepDisplay$: Observable<ITreatmentStepDisplay>;
  currentPractitioner$: Observable<WithRef<IStaffer>>;
  patient$: Observable<WithRef<IPatient> | undefined>;
  isTreatmentTemplate$: Observable<boolean>;
  selectedEvent$: Observable<IEvent | undefined>;
  treatmentStatuses = [
    TreatmentPlanStatus.Draft,
    TreatmentPlanStatus.Offered,
    TreatmentPlanStatus.Accepted,
    TreatmentPlanStatus.InProgress,
  ];
  treatmentStepStatuses = [
    TreatmentStepStatus.Current,
    TreatmentStepStatus.Incomplete,
  ];
  planTemplates$: Observable<ITreatmentTemplateWithStep[]>;
  allPatientPlans$: Observable<WithRef<ITreatmentPlan>[]>;
  hasActivePlans$ = new BehaviorSubject<boolean>(false);
  selectPlanControl = new TypedFormControl<WithRef<ITreatmentPlan>>();
  displayPlanControl$: Observable<boolean>;
  isMostRecentPlan$: Observable<boolean>;
  trackByPlan = TrackByFunctions.ref<WithRef<ITreatmentPlan>>();

  @Input()
  set treatmentStep(
    treatmentStep: WithRef<ITreatmentStep> | ITreatmentStep | undefined
  ) {
    this.treatmentStep$.next(treatmentStep ?? undefined);
  }

  @Input()
  set treatmentPlan(
    treatmentPlan: WithRef<ITreatmentPlan> | ITreatmentPlan | undefined
  ) {
    this.treatmentPlan$.next(treatmentPlan ?? undefined);
  }

  constructor(
    private _organisation: OrganisationService,
    private _schedulingFacade: AppointmentSchedulingFacade,
    private _chart: ChartFacade,
    public elementRef: ElementRef
  ) {
    this._schedulingFacade.brand$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((brand) => {
        if (
          brand.settings.treatmentPlanning
            ?.includeCompletedTreatmentPlansInTimeline
        ) {
          this.treatmentStatuses.push(TreatmentPlanStatus.Completed);
        }
      });

    this.appointment$ = this._schedulingFacade.currentAppointment$;
    this.appointmentDetails$ = this._schedulingFacade.appointmentDetails$;
    this.treatmentDisabled$ = this.treatmentCtrl.statusChanges.pipe(
      startWith(this.treatmentCtrl.status),
      map((status) => status === 'DISABLED')
    );
    this.currentPractitioner$ =
      this._schedulingFacade.practitioner$.pipe(filterUndefined());
    this.patient$ = this._schedulingFacade.selectedPatient$;
    this.selectedEvent$ = this._schedulingFacade.selectedEvent$;

    this.allPatientPlans$ = this.patient$.pipe(
      distinctUntilChanged<WithRef<IPatient> | undefined>(
        (before, after) => get(before, 'ref.path') === get(after, 'ref.path')
      ),
      switchMap((patient) =>
        patient
          ? Patient.withPatientRelationships$(
              patient,
              [PatientRelationshipType.DuplicatePatient],
              TreatmentPlan.all$
            ).pipe(take(1))
          : of([])
      ),
      switchMap((plans) => TreatmentPlan.sortPlansByMostRecentStep(plans)),
      tap((allPlans) => {
        this.hasActivePlans$.next(
          allPlans.some((plan) => TreatmentPlan.canSchedule(plan))
        );

        if (!allPlans.length) {
          this.selectPlanControl.reset();
          return;
        }

        const firstActive = allPlans.find((plan) =>
          TreatmentPlan.canSchedule(plan)
        );
        this.selectPlanControl.setValue(firstActive ?? allPlans[0]);
      })
    );

    this.planTemplates$ = combineLatest([
      this._schedulingFacade.brand$,
      this._organisation.staffer$.pipe(filterUndefined()),
    ]).pipe(
      switchMap(([brand, staffer]) =>
        Brand.treatmentTemplates$(brand).pipe(
          map((templates) =>
            templates.filter(
              (template) =>
                template.implementedBy.length &&
                template.enabledPractices.length
            )
          ),
          multiMap((template) =>
            TreatmentPlan.getTreatmentPlanFromTemplate(template, staffer)
          )
        )
      )
    );

    this.selectPlanControl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((overridePlan) => {
        this._schedulingFacade.appointmentFormChange({
          overridePlan,
        });
      });

    this.filteredTemplates$ = combineLatest([
      this.planTemplates$.pipe(
        map((plans) =>
          plans.filter((plan): plan is ITreatmentTemplateWithStep =>
            isTreatmentTemplateWithStep(plan)
          )
        ),
        map((plans) => sortBy(plans, 'plan.name'))
      ),
      this.appointmentDetails$,
    ]).pipe(
      map(([templates, { practice, practitioner }]) =>
        templates.filter((template) =>
          treatmentTemplateHasImplementor(template, practice, practitioner)
        )
      ),
      shareReplayCold()
    );

    const treatmentSearchStream = toSearchStream(this.treatmentCtrl);

    this.treatmentTemplateSearchFilter = new InputSearchFilter(
      this.filteredTemplates$,
      treatmentSearchStream,
      ['plan.name', 'step.name']
    );

    this.isTreatmentTemplate$ = this.treatmentCtrl.valueChanges.pipe(
      map((treatment) =>
        treatment ? isTreatmentTemplateWithStep(treatment) : false
      )
    );

    this.appointment$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((appointment) => {
        if (appointment) {
          return this.treatmentCtrl.disable();
        }
        this.treatmentCtrl.enable();
      });

    this.selectedTreatment$ = this._schedulingFacade.appointmentDetails$.pipe(
      map((details) => details.treatment)
    );

    this.treatmentCtrl.valueChanges
      .pipe(
        map((treatment) =>
          isString(treatment) || isNil(treatment) ? undefined : treatment
        ),
        filter(() => this.treatmentCtrl.enabled),
        takeUntil(this._onDestroy$)
      )
      .subscribe((template) => void this._handleTemplateChange(template));

    this.treatmentStep$
      .pipe(
        filterUndefined(),
        distinctUntilPathsChange('name'),
        takeUntil(this._onDestroy$)
      )
      .subscribe((step) =>
        this.stepNameCtrl.patchValue(step.name, { emitEvent: false })
      );

    validFormControlChanges$(this.stepNameCtrl)
      .pipe(isChanged$(), debounceUserInput(), takeUntil(this._onDestroy$))
      .subscribe(
        (name) => void this._schedulingFacade.updateTreatmentStep({ name })
      );

    this.durationWarningMessage$ = this.selectedEvent$.pipe(
      switchMap((event) =>
        iif(
          () => !event,
          this._schedulingFacade.durationWarningMessage$,
          this._schedulingFacade.selectedEventDurationWarning$
        )
      )
    );

    this.schedulingAlerts$ = combineLatest([
      this.appointmentDetails$.pipe(
        map((details) => details.treatment),
        filter(isTreatmentOption)
      ),
      this.selectedEvent$.pipe(filterUndefined()),
    ]).pipe(
      switchMap(([treatment, event]) =>
        getSchedulingAlerts$(treatment, event.from)
      )
    );

    this.currentPractitioner$
      .pipe(filterUndefined(), takeUntil(this._onDestroy$))
      .subscribe((practitioner) =>
        this._chart.setChartingAs(ChartId.InAppointment, practitioner)
      );

    this.treatmentPlanLink$ = combineLatest([
      this.patient$.pipe(filterUndefined()),
      this.treatmentPlan$.pipe(
        filterUndefined(),
        filter((plan): plan is WithRef<ITreatmentPlan> => isWithRef(plan))
      ),
    ]).pipe(
      map(([patient, plan]) => [
        'patients',
        patient.ref.id,
        'treatment-plans',
        plan.ref.id,
      ])
    );

    this.isMostRecentPlan$ = combineLatest([
      this.allPatientPlans$.pipe(startWith([]), map(this.filterPlans)),
      this.selectPlanControl.valueChanges.pipe(startWith(undefined)),
    ]).pipe(map(([plans, selectedPlan]) => isSameRef(plans[0], selectedPlan)));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  canChangePlan(plan?: TreatmentPlanStepPair): boolean {
    return !!plan && !isTreatmentPlanWithBookableStep(plan);
  }

  isTreatmentPlan(plan?: TreatmentPlanStepPair): boolean {
    return !!plan && isTreatmentPlanWithBookableStep(plan);
  }

  setPlan(plan?: WithRef<ITreatmentPlan>): void {
    plan
      ? this.selectPlanControl.setValue(plan)
      : this.selectPlanControl.reset();
  }

  displayFn(value: string | ITreatmentPlanWithBookableStep): string {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      return value;
    }
    return value.step.name;
  }

  isIncomplete(step: ITreatmentStep): boolean {
    return TreatmentStep.isIncomplete(step);
  }

  isComplete(step: ITreatmentStep): boolean {
    return TreatmentStep.isComplete(step);
  }

  clearTreatment(): void {
    this._schedulingFacade.appointmentFormChange({
      treatment: undefined,
    });
  }

  resetSelectedTreatment(): void {
    this.clearTreatment();
    this.treatmentCtrl.reset();
    this.treatmentCtrl.enable();
  }

  filterPlans(plans: WithRef<ITreatmentPlan>[]): WithRef<ITreatmentPlan>[] {
    const filterBy = this.hasActivePlans$.value
      ? TreatmentPlan.canSchedule
      : TreatmentPlan.isComplete;
    return plans.filter((plan) => filterBy(plan));
  }

  async updateTreatments(treatments: IChartedTreatment[]): Promise<void> {
    await this._schedulingFacade.updateTreatmentStep({
      treatments: await ChartedTreatmentUpdater.syncPricingRules(treatments),
    });
  }

  async deleteTreatment(treatment: IChartedTreatment): Promise<void> {
    await this._schedulingFacade.removeTreatmentFromStep(treatment.uuid);
  }

  async updateStepDisplay(display: ITreatmentStepDisplay): Promise<void> {
    await this._schedulingFacade.updateTreatmentStep({ display });
  }

  async updateStepDisplayOverride(
    treatmentStep: ITreatmentStep,
    selectedOverride: WithRef<ITreatmentCategory>
  ): Promise<void> {
    const display = TreatmentStep.updateDisplayOverrideCategory(
      treatmentStep,
      selectedOverride.ref
    );

    if (!display) {
      return;
    }

    await this.updateStepDisplay(display);
  }

  async addChartable(
    chartable: AnyChartedItemConfiguration,
    selectedSurfaces: Partial<IChartedRef>[]
  ): Promise<void> {
    await new AddChartable([
      new AddTreatmentToTreatmentTemplateProvider(
        this._schedulingFacade,
        await this._getFeeSchedule()
      ),
    ]).add({
      selectedSurfaces,
      chartable,
      chartingAs: await snapshot(
        this._organisation.staffer$.pipe(filterUndefined())
      ),
    });
  }

  async updateChartable(data: IEditChartableData): Promise<void> {
    return ChartableSurfaceUpdater.updateTreatmentSurfaces(data, [
      new AddTreatmentToTreatmentTemplateProvider(
        this._schedulingFacade,
        await this._getFeeSchedule()
      ),
    ]);
  }

  isSelectedNamedDocument(
    namedDocument: INamedDocument,
    selectedNamedDocument: INamedDocument
  ): boolean {
    try {
      return isSameRef(namedDocument, selectedNamedDocument);
    } catch (error) {
      return isEqual(namedDocument, selectedNamedDocument);
    }
  }

  async selectStep(treatment: ITreatmentPlanWithBookableStep): Promise<void> {
    const appointment = await TreatmentStep.appointment(treatment.step);
    if (!appointment) {
      return;
    }

    this._schedulingFacade.setAppointment(appointment, {
      updateSelectedSuggestion: false,
    });
    this._schedulingFacade.appointmentFormChange({
      treatment,
    });
  }

  private async _handleTemplateChange(
    template?: ITreatmentTemplateWithStep
  ): Promise<void> {
    if (template) {
      template.plan.feeSchedule = await this._getFeeSchedule();
      return this._schedulingFacade.selectTreatmentTemplate(template);
    }
    this.clearTreatment();
  }

  private async _getFeeSchedule(): Promise<WithRef<IFeeSchedule>> {
    const patient = await snapshot(this.patient$);
    const practitioner = await snapshot(this.currentPractitioner$);
    const treatmentPlan = await snapshot(this.treatmentPlan$);
    const feeSchedule = treatmentPlan?.feeSchedule
      ? await Firestore.getDoc(treatmentPlan.feeSchedule.ref)
      : await FeeSchedule.getPreferredOrDefault(
          practitioner,
          patient?.preferredFeeSchedule
        );
    return feeSchedule;
  }
}

function treatmentTemplateHasImplementor(
  template: ITreatmentTemplateWithStep,
  practice?: WithRef<IPractice>,
  practitioner?: INamedDocument<IStaffer> | IDefaultOption
): boolean {
  if ((!practice && !practitioner) || isDefaultOption(practitioner)) {
    return true;
  }

  if (practice && practitioner) {
    return (
      TreatmentTemplate.practiceIsEnabled(template.step.template, practice) &&
      TreatmentTemplate.hasImplementor(template.step.template, practitioner)
    );
  }

  if (practitioner) {
    return TreatmentTemplate.hasImplementor(
      template.step.template,
      practitioner
    );
  }

  if (practice) {
    return TreatmentTemplate.practiceIsEnabled(
      template.step.template,
      practice
    );
  }
  return false;
}
