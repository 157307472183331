import { rand, randSentence } from '@ngneat/falso';
import {
  VersionedSchema,
  initVersionedSchema,
} from '@principle-theorem/editor';
import {
  CANCELLATION_REASONS,
  CancellationReason,
  ICancellation,
} from '@principle-theorem/principle-core/interfaces';
import { BaseMock, Properties } from '@principle-theorem/shared';

export class CancellationMock
  extends BaseMock
  implements Properties<ICancellation>
{
  get reason(): CancellationReason {
    return rand(CANCELLATION_REASONS);
  }

  get comments(): VersionedSchema {
    return initVersionedSchema(randSentence());
  }
}
