import { JsonSchemaFormService } from '@ajsf/core';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ILayoutNode, IOptions } from '../custom-form-widget-interfaces';

@Component({
    selector: 'pr-custom-form-editor-content-widget',
    templateUrl: './custom-form-editor-content-widget.component.html',
    styleUrls: ['./custom-form-editor-content-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CustomFormEditorContentWidgetComponent implements OnInit {
  options: IOptions;
  @Input() layoutNode: ILayoutNode;
  @Input() layoutIndex: number[];
  @Input() dataIndex: number[];

  constructor(private _jsf: JsonSchemaFormService) {}

  ngOnInit(): void {
    this.options = this.layoutNode.options || {};
    this._jsf.initializeControl(this);
  }
}
