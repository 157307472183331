import { type ICSVExport } from '@principle-theorem/ng-shared';
import {
  DataPointValue,
  ICanBeChartedProperty,
} from '@principle-theorem/reporting';
import { get } from 'lodash';
import { DataPointValueFormatter } from '../../../../core/data-point-value-formatter';
import { getD3NumberFormatter } from '../../../../core/chart-builders/dc-chart-builders/dc-single-value-chart-builder';
import { IGroupBySectionOption } from '../../../../../models/report-builder-data-sources/report-builder-data-source';
import { TABLE_GROUP_KEY } from '../../../../core/chart-builders/table-chart-builder';

export interface ICSVColumn {
  id: string;
  label: string;
  metadata: ICanBeChartedProperty['metadata'];
}

export class ReportBuilderTableChartCSV
  implements ICSVExport<Record<string, unknown>, Record<string, unknown>>
{
  defaultFileName = 'report-builder-table-chart';
  headers: string[] = [];
  columns: ICSVColumn[];

  constructor(
    private _selectedColumns: ICSVColumn[],
    private _groupBy?: IGroupBySectionOption
  ) {
    this.columns = this._selectedColumns;
    this.headers = this.columns.map((column) => column.label);
  }

  translate(
    records: Record<string, unknown>[]
  ): Record<string, unknown>[] | Promise<Record<string, unknown>[]> {
    return records.map((record) => this._translateRecord(record));
  }

  private _translateRecord(
    record: Record<string, unknown>
  ): Record<string, unknown> {
    return this.columns.reduce(
      (acc: Record<string, unknown>, column) => ({
        ...acc,
        [column.id]: this._getValue(record, column),
      }),
      {}
    );
  }

  private _getValue(
    record: Record<string, unknown>,
    column: ICSVColumn
  ): string {
    const propertyKey =
      column.label === this._groupBy?.measure.metadata.label
        ? TABLE_GROUP_KEY
        : column.label;
    const dataPoint = get(record, propertyKey) as DataPointValue;

    if (dataPoint === undefined) {
      return '';
    }

    const canFilterDataPoint = !getD3NumberFormatter(
      column.metadata.formatter,
      column.metadata.formatterValue
    );

    if (canFilterDataPoint) {
      return dataPoint.toString();
    }

    return DataPointValueFormatter.format(
      dataPoint,
      column.metadata.formatter,
      column.metadata.formatterValue
    );
  }
}
