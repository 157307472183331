import {
  IFeeSchedule,
  IPatient,
  IStaffer,
  ITreatmentPlan,
} from '@principle-theorem/principle-core/interfaces';
import { INamedDocument, WithRef } from '@principle-theorem/shared';
import { TreatmentPlan } from './treatment-plan';
import { TreatmentStep } from './treatment-step';

export class AssociatedPlanCreator {
  constructor(private _patient: WithRef<IPatient>) {}

  canCreateChild(plan: WithRef<ITreatmentPlan>): boolean {
    const hasChildren = plan.children.length >= 1;
    return !hasChildren;
  }

  async getPlanFromChildren(
    plan: WithRef<ITreatmentPlan>
  ): Promise<WithRef<ITreatmentPlan> | undefined> {
    const treatmentPlan = await TreatmentPlan.getLastChild(plan);
    return treatmentPlan ? treatmentPlan : this.createChildPlan(plan);
  }

  async createNewPlan(
    practitioner?: INamedDocument<IStaffer>,
    feeSchedule?: INamedDocument<IFeeSchedule>
  ): Promise<WithRef<ITreatmentPlan>> {
    const treatmentPlan: ITreatmentPlan = TreatmentPlan.init({
      practitioner,
      feeSchedule,
    });
    const step = TreatmentStep.init({
      name: `Step 1`,
    });
    return TreatmentPlan.saveNewPlan(
      TreatmentPlan.col(this._patient),
      treatmentPlan,
      [step]
    );
  }

  async createChildPlan(
    plan: WithRef<ITreatmentPlan>
  ): Promise<WithRef<ITreatmentPlan> | undefined> {
    if (!this.canCreateChild(plan)) {
      return;
    }

    const treatmentPlan: WithRef<ITreatmentPlan> = await this.createNewPlan(
      plan.practitioner,
      plan.feeSchedule
    );
    await TreatmentPlan.addChild(plan, treatmentPlan);
    return treatmentPlan;
  }
}
