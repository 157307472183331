import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  ChartedServiceSmartGroup,
  getServiceCodeNumberString,
  ServiceProviderHandler,
} from '@principle-theorem/principle-core';
import {
  type IChartedServiceSmartGroup,
  type IChartedSurface,
  type IPricedServiceCodeEntry,
} from '@principle-theorem/principle-core/interfaces';
import { isChanged$ } from '@principle-theorem/shared';
import { omit } from 'lodash';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-service-item-smart-group-item',
  templateUrl: './service-item-smart-group-item.component.html',
  styleUrls: ['./service-item-smart-group-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceItemSmartGroupItemComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _chartedSurfaces$ = new BehaviorSubject<IChartedSurface[]>([]);
  trackByCode = TrackByFunctions.ref<IPricedServiceCodeEntry>('code.ref');
  group$ = new ReplaySubject<IChartedServiceSmartGroup>(1);
  selectedCode = new TypedFormControl<IPricedServiceCodeEntry | undefined>();
  @Input() disabled = false;
  @Input() compact = false;
  @Output() groupChange = new EventEmitter<
    Partial<IChartedServiceSmartGroup>
  >();

  constructor() {
    this.selectedCode.valueChanges
      .pipe(isChanged$(), takeUntil(this._onDestroy$))
      .subscribe((selected?: IPricedServiceCodeEntry) => {
        this.groupChange.emit({
          selected: selected ? Number(selected.code) : undefined,
        });
      });

    this.group$
      .pipe(
        isChanged$(),
        map((group) => ChartedServiceSmartGroup.getSelected(group)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((group) => {
        this.selectedCode.setValue(group, {
          emitEvent: false,
        });
      });
  }

  @Input()
  set group(group: IChartedServiceSmartGroup) {
    if (group) {
      this.group$.next(group);
    }
  }

  @Input()
  set chartedSurfaces(chartedSurfaces: IChartedSurface[]) {
    this._chartedSurfaces$.next(chartedSurfaces);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  resetPrice(
    group: IChartedServiceSmartGroup,
    selected: IPricedServiceCodeEntry
  ): void {
    this.groupChange.next({
      ...group,
      serviceCodes: group.serviceCodes.map((item) => {
        if (item.uuid !== selected.uuid) {
          return item;
        }
        return omit(item, 'priceOverride');
      }),
    });
  }

  itemUpdate(
    group: IChartedServiceSmartGroup,
    current: IPricedServiceCodeEntry,
    changes: Partial<IPricedServiceCodeEntry>
  ): void {
    group.serviceCodes = group.serviceCodes.map((code) => {
      if (code.uuid !== current.uuid) {
        return code;
      }
      return {
        ...code,
        ...changes,
      };
    });
    this.groupChange.next(group);
  }

  compareWithFn(
    aCode?: IPricedServiceCodeEntry,
    bCode?: IPricedServiceCodeEntry
  ): boolean {
    return aCode && bCode
      ? getServiceCodeNumberString(aCode.code) ===
          getServiceCodeNumberString(bCode.code)
      : false;
  }

  getCodeName({ type, code }: IPricedServiceCodeEntry): string | undefined {
    const resolvedCode = ServiceProviderHandler.resolveServiceCode(type, code);
    if (!resolvedCode) {
      return;
    }
    return `${resolvedCode.code} - ${resolvedCode.title}`;
  }
}
