import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DATE_TIME_FORMAT } from '@principle-theorem/shared';
import { IConfirmGapOfferData } from '@principle-theorem/principle-core/interfaces';

@Component({
    selector: 'pr-patient-gap-offer-details',
    templateUrl: './patient-gap-offer-details.component.html',
    styleUrls: ['./patient-gap-offer-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientGapOfferDetailsComponent {
  readonly dateFormat = DATE_TIME_FORMAT;
  readonly timeFormat = 'h:mm A';
  @Input() data: IConfirmGapOfferData;
}
