import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  type OnDestroy,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, type ControlValueAccessor } from '@angular/forms';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IMultiTreatmentConfiguration,
  type IStaffer,
  type TreatmentTemplateTreatments,
} from '@principle-theorem/principle-core/interfaces';
import {
  isSameRef,
  type INamedDocument,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { StafferTreatmentTemplateSelectStore } from './staffer-treatment-template-select.store';

@Component({
  selector: 'pr-staffer-treatment-template-select',
  templateUrl: './staffer-treatment-template-select.component.html',
  styleUrls: ['./staffer-treatment-template-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StafferTreatmentTemplateSelectComponent),
      multi: true,
    },
  ],
})
export class StafferTreatmentTemplateSelectComponent
  implements ControlValueAccessor, OnDestroy
{
  private _onDestroy$ = new Subject<void>();
  formCtrl = new TypedFormControl<
    INamedDocument<IMultiTreatmentConfiguration> | undefined
  >();
  staffer$ = new ReplaySubject<IReffable<IStaffer>>(1);
  brand$ = new ReplaySubject<WithRef<IBrand>>(1);
  trackByConfiguration = TrackByFunctions.ref<TreatmentTemplateTreatments>();

  @Input() label = 'Treatment';
  @Input() required = true;

  constructor(public store: StafferTreatmentTemplateSelectStore) {
    this.store.loadOptions(
      combineLatest([this.staffer$, this.brand$]).pipe(
        map(([staffer, brand]) => ({
          staffer,
          brand,
        }))
      )
    );
  }

  @Input()
  set staffer(staffer: IReffable<IStaffer>) {
    if (staffer) {
      this.staffer$.next(staffer);
    }
  }

  @Input()
  set brand(brand: WithRef<IBrand>) {
    if (brand) {
      this.brand$.next(brand);
    }
  }

  // eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
  onTouched = (): void => {};

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  writeValue(
    configuration: INamedDocument<IMultiTreatmentConfiguration>
  ): void {
    if (configuration) {
      this.formCtrl.setValue(configuration, { emitEvent: false });
    }
  }

  registerOnChange(fn: () => void): void {
    this.formCtrl.valueChanges.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  clearInput(): void {
    this.formCtrl.setValue(undefined);
  }

  compareWithFn(
    aRef?: INamedDocument<IMultiTreatmentConfiguration>,
    bRef?: INamedDocument<IMultiTreatmentConfiguration>
  ): boolean {
    return isSameRef(aRef, bRef);
  }
}
