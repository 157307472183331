import { NgModule } from '@angular/core';
import { type Routes, RouterModule } from '@angular/router';
import { NgSterilisationModule } from './ng-sterilisation.module';
import { SterilisationPageComponent } from './pages/sterilisation-page/sterilisation-page.component';
import { SterilisationTableFilterStore } from './stores/sterilisation-table-filters.store';

const routes: Routes = [
  {
    path: '',
    data: { title: 'Sterilisation Tracking' },
    component: SterilisationPageComponent,
    children: [
      {
        path: '',
        redirectTo: 'cycles',
        pathMatch: 'full',
      },
      {
        path: 'cycles',
        loadComponent: () =>
          import(
            './components/sterilisation-cycle-list/sterilisation-cycle-list.component'
          ).then((mod) => mod.SterilisationCycleListComponent),
      },
      {
        path: 'records',
        loadComponent: () =>
          import(
            './components/sterilisation-list/sterilisation-list.component'
          ).then((mod) => mod.SterilisationListComponent),
      },
    ],
  },
  {
    path: ':sterilisationCycleIdRouteParam',
    data: { title: 'Sterilisation Cycle' },
    loadComponent: () =>
      import('./pages/sterilisation-cycle/sterilisation-cycle.component').then(
        (mod) => mod.SterilisationCycleComponent
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), NgSterilisationModule],
  exports: [RouterModule],
  providers: [SterilisationTableFilterStore],
})
export class NgSterilisationRoutingModule {}
