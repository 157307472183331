import {
  IBrand,
  IStaffer,
  IUser,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  WithRef,
  addDoc,
  asyncForEach,
  find$,
  getParentDocRef,
  patchDoc,
  snapshot,
  toNamedDocument,
  where,
} from '@principle-theorem/shared';
import { map } from 'rxjs/operators';
import { Brand } from '../brand';
import { Staffer } from './staffer';

function uniqDocRefs<T>(refs: DocumentReference<T>[]): DocumentReference<T>[] {
  return refs.reduce(
    (unique: DocumentReference<T>[], current: DocumentReference<T>) => {
      const isUnique = !unique.map((ref) => ref.path).includes(current.path);
      return isUnique ? [...unique, current] : unique;
    },
    []
  );
}

export async function ensureStafferDocsExist(
  user: WithRef<IUser>
): Promise<void> {
  const practiceBrandRefs = user.practices.map((practiceRef) =>
    getParentDocRef<IBrand>(practiceRef)
  );
  const brandRefs = uniqDocRefs([...user.brands, ...practiceBrandRefs]);

  const stafferData: Pick<IStaffer, 'user'> = {
    user: toNamedDocument(user),
  };

  const promises: Promise<void>[] = brandRefs.map(async (brandRef) => {
    const staffCol = Brand.stafferCol({ ref: brandRef });

    const stafferExists = await snapshot(
      find$(staffCol, where('user.ref', '==', user.ref)).pipe(
        map((staffer?) => {
          return staffer !== undefined;
        })
      )
    );

    if (stafferExists) {
      return;
    }
    await addDoc(staffCol, Staffer.init(stafferData));
  });
  await Promise.all(promises);
}

export async function updateStafferNames(user: WithRef<IUser>): Promise<void> {
  const practiceBrandRefs = user.practices.map((practiceRef) =>
    getParentDocRef<IBrand>(practiceRef)
  );
  const brandRefs = uniqDocRefs([...user.brands, ...practiceBrandRefs]);

  await asyncForEach(brandRefs, async (brandRef) => {
    const staffCol = Brand.stafferCol({ ref: brandRef });
    const staffer = await snapshot(
      find$(staffCol, where('user.ref', '==', user.ref))
    );

    if (!staffer) {
      return;
    }

    await patchDoc(staffer.ref, {
      user: { ...staffer.user, name: user.name },
    });
  });
}
