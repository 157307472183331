import { Injectable } from '@angular/core';
import { type Data, Router } from '@angular/router';
import { FeeScheduleCollectionResolver } from './fee-schedule-collection-resolver.service';
import { BaseCurrentModel } from '@principle-theorem/ng-principle-shared';
import { get, has } from 'lodash';
import { type CollectionReference } from '@principle-theorem/shared';
import { type IFeeSchedule } from '@principle-theorem/principle-core/interfaces';

@Injectable({
  providedIn: 'root',
  useFactory: (route: Router) => new CurrentFeeScheduleCollectionScope(route),
  deps: [Router],
})
export class CurrentFeeScheduleCollectionScope extends BaseCurrentModel<
  CollectionReference<IFeeSchedule>
> {
  protected _propertyAccessor(
    data: Data
  ): CollectionReference<IFeeSchedule> | undefined {
    return has(data, FeeScheduleCollectionResolver.resolverKey)
      ? (get(
          data,
          FeeScheduleCollectionResolver.resolverKey
        ) as CollectionReference<IFeeSchedule>)
      : undefined;
  }
}
