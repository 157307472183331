import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import {
  BasicDialogService,
  confirmationDialogData,
  DialogPresets,
  type IConfirmationDialogInput,
} from '@principle-theorem/ng-shared';
import {
  AutomationConfiguration,
  Brand,
  TemplateDefinition,
} from '@principle-theorem/principle-core';
import {
  IAutomatedFormIssue,
  IAutomatedFormIssueConfiguration,
  IGeneratedTaskConfiguration,
  isAutomatedFormIssueConfiguration,
  isAutomatedNotificationConfiguration,
  isGeneratedTaskConfiguration,
  type IAutomatedNotificationConfiguration,
  type IAutomationConfiguration,
  type IGeneratedTask,
  type TemplateScope,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  deleteDoc,
  filterUndefined,
  Firestore,
  isWithRef,
  snapshot,
  undeleteDoc,
  type WithRef,
} from '@principle-theorem/shared';
import {
  AutomatedNotificationConfigurationEditComponent,
  type IAutomatedNotificationConfigurationEditRequest,
  type IAutomatedNotificationConfigurationEditResponse,
} from '../components/automated-notification-configuration-edit/automated-notification-configuration-edit.component';
import {
  AutomationsByConfigDialogComponent,
  IAutomationsByConfigRequest,
} from '../components/automations-by-config-dialog/automations-by-config-dialog.component';
import {
  IUpdateExistingAutomationsRequest,
  UpdateExistingAutomationsDialogComponent,
} from '../components/update-existing-automations-dialog/update-existing-automations-dialog.component';
import { AutomationDialogService } from './automation-dialog.service';

@Injectable()
export class AutomationConfigurationService {
  constructor(
    private _dialog: MatDialog,
    private _basicDialog: BasicDialogService,
    private _snackBar: MatSnackBar,
    private _currentScope: CurrentScopeFacade,
    private _automationDialog: AutomationDialogService
  ) {}

  async openEditDialog(
    request: IAutomatedNotificationConfigurationEditRequest
  ): Promise<IAutomatedNotificationConfigurationEditResponse | undefined> {
    return this._dialog
      .open<
        AutomatedNotificationConfigurationEditComponent,
        IAutomatedNotificationConfigurationEditRequest,
        IAutomatedNotificationConfigurationEditResponse
      >(
        AutomatedNotificationConfigurationEditComponent,
        DialogPresets.large({ height: '80%', data: request })
      )
      .afterClosed()
      .toPromise();
  }

  async editNotification(
    config: WithRef<IAutomatedNotificationConfiguration>,
    scope: TemplateScope
  ): Promise<void> {
    const response = await this.openEditDialog({
      config,
      scope,
    });
    if (!response) {
      return;
    }

    const brand = await snapshot(
      this._currentScope.currentBrand$.pipe(filterUndefined())
    );
    const templateRef =
      config.templateRef ??
      (await addDoc(
        Brand.templateCol(brand),
        TemplateDefinition.init({
          ...response.template,
          ownerScope: brand.ref,
        })
      ));
    const updatedAutomationConfig = {
      ...config,
      ...response.config,
      templateRef,
    };
    await Firestore.saveDoc(updatedAutomationConfig);

    const templateChanges = {
      ...response.template,
      automatedConfigurationRef: config.ref,
    };
    await Firestore.patchDoc(templateRef, templateChanges);
    this._snackBar.open('Notification configuration saved');

    await this.openUpdateExistingAutomations(updatedAutomationConfig);
  }

  async editTask(task: WithRef<IGeneratedTask>): Promise<void> {
    const updatedTask = await this._automationDialog.openTaskDialog({
      task,
      useRelativeTime: true,
    });
    if (!updatedTask) {
      return;
    }

    await Firestore.saveDoc({ ...task, ...updatedTask });
    this._snackBar.open('Task configuration saved');
  }

  async editFormIssue(formIssue: WithRef<IAutomatedFormIssue>): Promise<void> {
    const updatedTask =
      await this._automationDialog.openAutomatedFormIssueDialog({
        formIssue,
        useRelativeTime: true,
      });
    if (!updatedTask) {
      return;
    }

    await Firestore.saveDoc({ ...formIssue, ...updatedTask });
    this._snackBar.open('Generate Form configuration saved');
  }

  async editConfig(
    automation: WithRef<IAutomationConfiguration>,
    scope: TemplateScope
  ): Promise<void> {
    if (
      isAutomatedNotificationConfiguration(automation) &&
      isWithRef<IAutomatedNotificationConfiguration>(automation)
    ) {
      return this.editNotification(automation, scope);
    }
    if (
      isGeneratedTaskConfiguration(automation) &&
      isWithRef<IGeneratedTaskConfiguration>(automation)
    ) {
      return this.editTask(automation);
    }
    if (
      isAutomatedFormIssueConfiguration(automation) &&
      isWithRef<IAutomatedFormIssueConfiguration>(automation)
    ) {
      return this.editFormIssue(automation);
    }

    throw new Error('Unsupported automation configuration type');
  }

  async deleteConfig(
    automation: WithRef<IAutomationConfiguration>
  ): Promise<void> {
    const confirmed = await this._basicDialog.confirm(
      confirmationDialogData({
        title: 'Delete Automation Configuration',
        prompt: [
          'Are you sure you want to delete this automation configuration?',
          'Automations using this configuration will no longer run when scheduled.',
        ],
        submitLabel: 'Delete',
        submitColor: 'warn',
      })
    );
    if (!confirmed) {
      return;
    }
    await deleteDoc(automation.ref, undefined, undefined, {
      isActive: false,
    });
    if (isAutomatedNotificationConfiguration(automation)) {
      await deleteDoc(automation.templateRef);
    }
    this._snackBar.open('Deleted Automation Configuration');
  }

  async restoreConfig(
    automation: WithRef<IAutomationConfiguration>
  ): Promise<void> {
    const confirmed = await this._basicDialog.confirm(
      confirmationDialogData({
        title: 'Restore Automation Configuration',
        prompt: [
          'Are you sure you want to restore this automation configuration?',
          'Once restored this configuration will remain disabled until you enable it.',
          'Automations using this configuration will not run until you enable it.',
        ],
        submitLabel: 'Restore',
      })
    );

    if (!confirmed) {
      return;
    }
    await undeleteDoc(automation.ref);
    if (isAutomatedNotificationConfiguration(automation)) {
      await undeleteDoc(automation.templateRef);
    }
    this._snackBar.open('Restored Automation Configuration');
  }

  async setActive(
    automation: WithRef<IAutomationConfiguration>,
    isActive: boolean
  ): Promise<void> {
    const enableDialogData: IConfirmationDialogInput = {
      title: 'Enable Automation Configuration',
      prompt: [
        'Are you sure you want to enable this automation configuration?',
        'The automation will be added to all relevant future appointments.',
      ],
      submitLabel: 'Enable',
    };

    const disableDialogData: IConfirmationDialogInput = {
      title: 'Disable Automation Configuration',
      prompt: [
        'Are you sure you want to disable this automation configuration?',
        'Automations using this configuration will no longer run when scheduled.',
      ],
      submitLabel: 'Disable',
    };

    const confirmed = await this._basicDialog.confirm(
      confirmationDialogData(isActive ? enableDialogData : disableDialogData)
    );
    if (!confirmed) {
      return;
    }
    await Firestore.patchDoc(automation.ref, { isActive });

    const actionLabel = isActive ? 'Enabled' : 'Disabled';
    this._snackBar.open(`${actionLabel} Automation Configuration`);
  }

  async openAutomations(
    config: WithRef<IAutomationConfiguration>
  ): Promise<void> {
    const data: IAutomationsByConfigRequest = { config };
    await this._dialog
      .open<
        AutomationsByConfigDialogComponent,
        IAutomationsByConfigRequest,
        void
      >(
        AutomationsByConfigDialogComponent,
        DialogPresets.large({ height: '80%', data })
      )
      .afterClosed()
      .toPromise();
  }

  async openUpdateExistingAutomations(
    config: WithRef<IAutomatedNotificationConfiguration>
  ): Promise<void> {
    const count = await AutomationConfiguration.getAutomationCount(config.ref);
    if (count <= 0) {
      return;
    }

    await this._dialog
      .open<
        UpdateExistingAutomationsDialogComponent,
        IUpdateExistingAutomationsRequest,
        void
      >(
        UpdateExistingAutomationsDialogComponent,
        DialogPresets.small({
          data: { config },
        })
      )
      .afterClosed()
      .toPromise();
  }
}
