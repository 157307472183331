import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import { TreatmentPlan } from '@principle-theorem/principle-core';
import {
  ITreatmentCategory,
  isCreditRefundLineItem,
  isCustomLineItem,
  isDepositLineItem,
  isTreatmentBasePriceLineItem,
  isTreatmentLineItem,
  type ICustomLineItem,
  type IInvoiceLineItem,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  getDoc,
  snapshot,
  type INamedDocument,
} from '@principle-theorem/shared';
import { ReplaySubject, combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-invoice-line-item-display',
    templateUrl: './invoice-line-item-display.component.html',
    styleUrls: ['./invoice-line-item-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InvoiceLineItemDisplayComponent {
  lineItem$ = new ReplaySubject<IInvoiceLineItem>(1);
  infoTooltip$: Observable<string>;
  showAmounts$: Observable<boolean>;
  showItem$: Observable<boolean>;
  showQuantity$: Observable<boolean>;
  showColumns$: Observable<boolean>;

  constructor(private _globalStore: GlobalStoreService) {
    this.infoTooltip$ = this.lineItem$.pipe(
      switchMap((lineItem) => this._getInfoTooltip(lineItem))
    );
    this.showAmounts$ = this.lineItem$.pipe(
      map((lineItem) => this._showAmounts(lineItem))
    );
    this.showItem$ = this.lineItem$.pipe(
      map((lineItem) => this._showItem(lineItem))
    );
    this.showQuantity$ = this.lineItem$.pipe(
      map((lineItem) => this._showQuantity(lineItem))
    );
    this.showColumns$ = combineLatest([
      this.showAmounts$,
      this.showQuantity$,
    ]).pipe(map((items) => items.some((item) => item)));
  }

  @Input()
  set lineItem(lineItem: IInvoiceLineItem) {
    if (lineItem) {
      this.lineItem$.next(lineItem);
    }
  }

  isDeposit(lineItem: ICustomLineItem): boolean {
    return isDepositLineItem(lineItem);
  }

  isCreditRefund(lineItem: ICustomLineItem): boolean {
    return isCreditRefundLineItem(lineItem);
  }

  resolveProvider(
    lineItem: ICustomLineItem
  ): INamedDocument<IStaffer> | undefined {
    if (isDepositLineItem(lineItem)) {
      return lineItem.attributedTo;
    }
  }

  resolveTreatmentCategory$(
    lineItem: ICustomLineItem
  ): Observable<WithRef<ITreatmentCategory> | undefined> {
    if (!isDepositLineItem(lineItem) || !lineItem.forTreatmentCategoryRef) {
      return of(undefined);
    }
    return this._globalStore.getTreatmentCategory$(
      lineItem.forTreatmentCategoryRef
    );
  }

  private async _getInfoTooltip(lineItem: IInvoiceLineItem): Promise<string> {
    if (
      !isCustomLineItem(lineItem) ||
      !isTreatmentLineItem(lineItem) ||
      !lineItem.treatmentRef.planRef
    ) {
      return '';
    }
    const plan = await getDoc(lineItem.treatmentRef.planRef);
    const treatment = await snapshot(
      TreatmentPlan.treatmentById$(plan, lineItem.treatmentRef.treatmentUuid)
    );
    if (!treatment) {
      return '';
    }
    return `Fee Schedule: ${treatment.feeSchedule.name}`;
  }

  private _showAmounts(lineItem: IInvoiceLineItem): boolean {
    if (isCustomLineItem(lineItem) && isTreatmentLineItem(lineItem)) {
      return false;
    }
    return true;
  }

  private _showQuantity(lineItem: IInvoiceLineItem): boolean {
    if (isCustomLineItem(lineItem) && isTreatmentLineItem(lineItem)) {
      return false;
    }
    return true;
  }

  private _showItem(lineItem: IInvoiceLineItem): boolean {
    if (isCustomLineItem(lineItem) && isTreatmentBasePriceLineItem(lineItem)) {
      return lineItem.amount > 0;
    }
    return true;
  }
}
