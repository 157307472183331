import { InputRule } from '@tiptap/pm/inputrules';
import { type NodeType } from '@tiptap/pm/model';
import { GetAttrsFn } from './extension';

export function nodeInputRule(
  regexp: RegExp,
  type: NodeType,
  getAttrs?: GetAttrsFn<string[]>
): InputRule {
  return new InputRule(regexp, (state, match, start, end) => {
    const attrs = getAttrs ? getAttrs(match) : {};
    const { tr } = state;

    const node = type.create(attrs);
    if (match[0]) {
      tr.replaceWith(start - 1, end, node);
    }

    return tr;
  });
}
