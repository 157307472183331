import {
  getSchemaText,
  isMixedSchema,
  isRawInlineNodes,
  isRawSchema,
} from '@principle-theorem/editor';
import { shareReplayCold } from '@principle-theorem/shared';
import { get, isString } from 'lodash';
import { combineLatest, type Observable, type OperatorFunction } from 'rxjs';
import {
  auditTime,
  debounceTime,
  filter,
  map,
  startWith,
} from 'rxjs/operators';
import { formControlChanges$ } from '../forms/form';
import {
  type TypedAbstractControl,
  type TypedFormControl,
} from '../forms/typed-form-group';

export function toSearchStream<T>(
  formControl: TypedAbstractControl<T>,
  msDelay: number = 200
): Observable<T | string> {
  return formControlChanges$(formControl).pipe(
    startWith(''),
    map((search) => (search ? search : '')),
    debounceTime(msDelay)
  );
}

export function streamFilter<T>(
  filterFn: (item: T, input: string) => boolean
): OperatorFunction<[T[], string], T[]> {
  return map(([data, input]: [T[], string]) =>
    data.filter((item: T) => filterFn(item, input))
  );
}

export function flattenFields<T>(
  fields: (string | ((item: T) => string))[] | ((item: T) => string)
): (item: T) => string {
  return (item: T) => {
    if (typeof fields === 'function') {
      return fields(item);
    }
    return fields
      .map((field) => {
        if (typeof field === 'function') {
          return field(item);
        }
        const data: unknown = get(item, field);
        if (!data) {
          return '';
        }
        if (isString(data)) {
          return data;
        }
        if (
          isRawInlineNodes(data) ||
          isRawSchema(data) ||
          isMixedSchema(data)
        ) {
          return getSchemaText(data);
        }
        return String(data);
      })
      .join(' ');
  };
}

export function toSearchInput$(
  ctrl: TypedFormControl<string>,
  debounce: number = 300
): Observable<string> {
  return ctrl.valueChanges.pipe(
    startWith(ctrl.value),
    map(() => ctrl.value),
    auditTime(debounce)
  );
}

export class InputSearchFilter<T> {
  private _flatten: (item: T) => string;
  results$: Observable<T[]>;

  constructor(
    items: Observable<T[]>,
    searchInput: Observable<string | T>,
    comparisonFields: (string | ((item: T) => string))[] | ((item: T) => string)
  ) {
    this._flatten = flattenFields<T>(comparisonFields);
    this.results$ = combineLatest([
      items,
      searchInput.pipe(
        filter((search: string | T): search is string => isString(search)),
        startWith('')
      ),
    ]).pipe(
      streamFilter((item: T, search: string) => this._isMatch(item, search)),
      shareReplayCold()
    );
  }

  private _isMatch(item: T, search: string): boolean {
    if (typeof search !== 'string') {
      return false;
    }
    const searchWords = search.split(' ');
    const flattenedItem = this._flatten(item).toLowerCase();
    return searchWords.every((word) =>
      flattenedItem.includes(word.toLowerCase())
    );
  }
}
