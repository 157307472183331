import {
  type INamedDocument,
  type ISoftDelete,
} from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import { type IStatusHistory } from '../common';
import { type ICustomFormConfiguration } from './custom-form-config';

export interface ISubmittedFormHistory<T = unknown> extends ISoftDelete {
  form: ISubmittedForm<T>;
  status: FormStatus;
  statusHistory: IStatusHistory<FormStatus>[];
  template?: INamedDocument;
}

export interface ISubmittedForm<T = unknown>
  extends Omit<ICustomFormConfiguration, 'name'> {
  date: Timestamp;
  disableChanges: boolean;
  data: T;
}

export enum FormStatus {
  Submitted = 'submitted',
  Confirmed = 'confirmed',
}

export enum SubmittedFormCollection {
  SubmittedFormHistory = 'submittedFormHistory',
}
