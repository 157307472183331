import { Pipe, type PipeTransform } from '@angular/core';
import { type IReffable, isSameRef } from '@principle-theorem/shared';

@Pipe({
  name: 'isSameRef',
})
export class IsSameRefPipe implements PipeTransform {
  transform(reffableA: IReffable, reffableB: IReffable): boolean {
    return isSameRef(reffableA, reffableB);
  }
}
