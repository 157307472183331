import {
  TextFormatting,
  type IMenuButton,
  BlockNodes,
} from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../menu-bar/basic-menu-button/basic-menu-button.component';
import { isMacOS } from '@tiptap/core';

export const STRIKE_KEYMAP = isMacOS() ? 'Mod-Shift-s' : 'Ctrl-Shift-s';

export function createStrikeMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'basic',
      buttonText: 'Strikethrough',
      tooltip: 'Strikethrough',
      shortcut: STRIKE_KEYMAP,
      icon: 'format_strikethrough',
      command: (editor) => editor.chain().focus().toggleStrike().run(),
      isActiveFn: (editor) => editor.isActive(TextFormatting.Strike),
      canActivateFn: (editor) => editor.isActive(BlockNodes.Paragraph),
    },
  });
}
