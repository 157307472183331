<div
  *ngIf="followUp$ | async as followUp"
  class="duration-50 relative flex cursor-pointer items-center justify-between overflow-hidden rounded-md border border-solid border-slate-300 bg-white px-4 py-2 transition-colors ease-in-out hover:bg-gray-100"
  (click)="openFollowUp()"
>
  @if (treatmentCategory$ | async; as category) {
    <div
      class="absolute bottom-0 left-0 top-0 w-3"
      [matTooltip]="'Treatment Category: ' + category.name"
      [style.backgroundColor]="category.colour.value"
    ></div>
  }
  <div class="flex items-center gap-4 pl-3">
    <pt-user-icon
      [name]="followUp.patient.name"
      [src]="followUp.patient | profileImage$ | async"
      [diameter]="36"
    />
    <div class="flex flex-col gap-1">
      <div class="flex items-center gap-3">
        <span class="font-bold">{{ followUp.patient.name }}</span>
        @if (!hideDate) {
          <span
            *ngIf="followUpDate$ | async as followUpDate"
            class="rounded-full px-2 py-0.5 text-xs"
            [ngClass]="
              (followUpIsDue$ | async) ? 'bg-orange-100' : 'bg-blue-100'
            "
          >
            {{ followUpDate | moment | amDateFormat: dateFormat }}
          </span>
        }
      </div>
      <span>
        {{ followUp.appointment.treatmentPlan.name }} -
        {{ followUp.appointment.treatmentPlan.treatmentStep.name }}
      </span>
    </div>
  </div>

  <div class="flex flex-col items-end gap-1">
    <div class="flex items-center gap-1">
      <div class="hidden sm:block">
        @for (action of patientActions; track action.name) {
          <button
            mat-icon-button
            matTooltip="{{ action.name }}"
            [disabled]="action.isDisabled$ | async"
            (click)="$event.stopPropagation(); action.run()"
          >
            <mat-icon>{{ action.icon }}</mat-icon>
          </button>
        }
      </div>
      <button
        mat-icon-button
        [matMenuTriggerFor]="moreMenu"
        (click)="$event.stopPropagation()"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>

    <mat-menu #moreMenu="matMenu">
      <a mat-menu-item [prBrandRouterLink]="patientUrl$ | async">
        <mat-icon>person</mat-icon>
        Patient Profile
      </a>
      <button
        mat-menu-item
        (click)="followUpService.reschedule(followUp.appointment)"
      >
        <mat-icon>keyboard_tab</mat-icon>
        Reschedule
      </button>
      <button
        mat-menu-item
        (click)="followUpService.postpone(followUp.appointment)"
      >
        <mat-icon>watch_later</mat-icon>
        Postpone
      </button>
      <button
        mat-menu-item
        (click)="followUpService.cancel(followUp.appointment)"
      >
        <mat-icon color="warn">cancel</mat-icon>
        <span class="warn">Cancel Follow Up</span>
      </button>
    </mat-menu>

    <span
      class="text-xs"
      [ngClass]="(isDueContact$ | async) ? 'text-red-500' : undefined"
    >
      @if (followUp.lastContactedAt) {
        Last contacted {{ followUp.lastContactedAt | moment | amTimeAgo }}
      } @else {
        Not contacted
      }
    </span>
  </div>
</div>
