<div
  *ngFor="let linkSection of linkSections$ | ngrxPush; trackBy: trackBySection"
  class="container"
>
  <div class="heading">{{ linkSection.name }}</div>
  <nav [attr.aria-label]="linkSection.name + ' Table of Contents'">
    <a
      *ngFor="
        let link of linkSection.links;
        trackBy: trackByLink;
        let i = index
      "
      class="level-{{ link.type }} link"
      [href]="rootUrl + '#' + link.id"
      [ngClass]="{ active: link.active }"
    >
      {{ link.name }}
    </a>
  </nav>
</div>
