import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  type IStaffer,
  type IUser,
} from '@principle-theorem/principle-core/interfaces';
import { shareReplayCold, type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GlobalStoreService } from '../../../store/global-store.service';
import { UserIconComponent } from '@principle-theorem/ng-shared';

@Component({
    selector: 'pr-staffer-display',
    imports: [CommonModule, MatProgressSpinnerModule, UserIconComponent],
    templateUrl: './staffer-display.component.html',
    styleUrls: ['./staffer-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StafferDisplayComponent {
  staffer$ = new ReplaySubject<WithRef<IStaffer>>(1);
  user$: Observable<WithRef<IUser>>;
  stafferName$: Observable<string>;
  stafferImage$: Observable<string | undefined>;

  @Input()
  set staffer(staffer: WithRef<IStaffer>) {
    if (staffer) {
      this.staffer$.next(staffer);
    }
  }

  constructor(globalStore: GlobalStoreService) {
    this.stafferImage$ = this.staffer$.pipe(
      switchMap((staffer) => globalStore.getStafferImage$(staffer))
    );
    this.stafferName$ = this.staffer$.pipe(
      switchMap((staffer) => globalStore.getStafferName$(staffer)),
      shareReplayCold()
    );
  }
}
