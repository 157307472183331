import { AutomationsFacade } from '@principle-theorem/ng-automations';
import { ChecklistItem } from '@principle-theorem/principle-core';
import {
  IPrescription,
  InvoiceStatus,
  type IChecklistItem,
  type IInvoice,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, combineLatest, of, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IOutgoingPatientStep {
  completed$: Observable<boolean>;
  hasError$: Observable<boolean>;
}

export class AppointmentsStep implements IOutgoingPatientStep {
  completed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  hasError$: Observable<boolean>;
  constructor() {
    this.hasError$ = this.completed$.pipe(map((result) => !result));
  }
}

export class InvoiceStep implements IOutgoingPatientStep {
  approved$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  completed$: Observable<boolean>;
  isIssued$: Observable<boolean>;
  hasError$: Observable<boolean>;

  constructor(public invoice$: Observable<WithRef<IInvoice>>) {
    this.isIssued$ = invoice$.pipe(
      map((invoice) => invoice.status !== InvoiceStatus.Draft)
    );
    this.completed$ = combineLatest([this.isIssued$, this.approved$]).pipe(
      map(([isIssued, isApproved]) => isIssued || isApproved)
    );
    this.hasError$ = this.completed$.pipe(map((result) => !result));
  }
}

export class ChecklistItemsStep implements IOutgoingPatientStep {
  completed$: Observable<boolean>;
  hasItems$: Observable<boolean>;
  hasError$: Observable<boolean>;

  constructor(
    checklist$: Observable<IChecklistItem[]>,
    issuedPrescriptions$: Observable<WithRef<IPrescription>[]>
  ) {
    this.hasItems$ = combineLatest([checklist$, issuedPrescriptions$]).pipe(
      map(
        ([checklist, prescriptions]) =>
          checklist.length > 0 || prescriptions.length > 0
      )
    );
    this.completed$ = combineLatest([checklist$, issuedPrescriptions$]).pipe(
      map(
        ([checklist, prescriptions]) =>
          ChecklistItem.allDone(checklist) && !prescriptions.length
      )
    );
    this.hasError$ = issuedPrescriptions$.pipe(
      map((prescriptions) => !!prescriptions.length)
    );
  }
}

export class AutomationsStep implements IOutgoingPatientStep {
  completed$: Observable<boolean>;
  hasItems$: Observable<boolean>;
  hasError$: Observable<boolean>;

  constructor(automationsFacade: AutomationsFacade) {
    this.hasItems$ = automationsFacade.automations$.pipe(
      map((automations) => automations.length > 0)
    );
    this.completed$ = of(true);
    this.hasError$ = of(false);
  }
}

export class MetaStep implements IOutgoingPatientStep {
  completed$: Observable<boolean>;
  hasError$: Observable<boolean>;

  constructor(steps: IOutgoingPatientStep[]) {
    this.completed$ = combineLatest(steps.map((step) => step.completed$)).pipe(
      map((results: boolean[]) => results.every((result) => result))
    );
    this.hasError$ = this.completed$.pipe(map((result) => !result));
  }
}
