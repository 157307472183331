import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MixedSchema } from '@principle-theorem/editor';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { SystemTemplates } from '@principle-theorem/principle-core';
import {
  IBrand,
  IResolvedTemplateWithName,
  ITemplateDefinitionWithName,
  SystemTemplate,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, filterUndefined, snapshot } from '@principle-theorem/shared';
import { BehaviorSubject } from 'rxjs';
import { PrintVariantSelectorDialogComponent } from '../print-variant-selector-dialog/print-variant-selector-dialog.component';

@Component({
  selector: 'pr-print-container',
  templateUrl: './print-container.component.html',
  styleUrls: ['./print-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintContainerComponent implements OnInit {
  header$ = new BehaviorSubject<MixedSchema | undefined>(undefined);
  footer$ = new BehaviorSubject<MixedSchema | undefined>(undefined);
  @Input() templatesResolvedExternally = false;

  @Input()
  set header(header: MixedSchema) {
    if (header) {
      this.header$.next(header);
    }
  }

  @Input()
  set footer(footer: MixedSchema) {
    if (footer) {
      this.footer$.next(footer);
    }
  }

  constructor(
    private _brandScope: CurrentBrandScope,
    private _location: Location,
    private _dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    if (
      this.header$.value ||
      this.footer$.value ||
      this.templatesResolvedExternally
    ) {
      this._triggerPrint();
      return;
    }

    const brand = await snapshot(this._brandScope.doc$.pipe(filterUndefined()));
    const selectedTemplates = await this._selectedSystemTemplates(brand);

    if (!selectedTemplates) {
      this._location.back();
      return;
    }

    this._setTemplates(selectedTemplates);
    this._triggerPrint();
  }

  private async _selectedSystemTemplates(
    brand: WithRef<IBrand>
  ): Promise<IResolvedTemplateWithName[] | undefined> {
    const templates = await SystemTemplates.resolveSystemTemplates(brand, [
      SystemTemplate.PrintHeader,
      SystemTemplate.PrintFooter,
    ]);

    if (!templates.length) {
      return;
    }

    if (SystemTemplates.hasTemplateVariants(templates)) {
      return this._openDialog(templates);
    }

    return templates.map(({ template, templateDoc }) => ({
      template,
      content: templateDoc.content,
    }));
  }

  private _triggerPrint(): void {
    setTimeout(() => {
      window.print();
      this._location.back();
    }, 3000);
  }

  private _setTemplates(templates: IResolvedTemplateWithName[]): void {
    this.header$.next(
      SystemTemplates.findTemplate(templates, SystemTemplate.PrintHeader)
    );
    this.footer$.next(
      SystemTemplates.findTemplate(templates, SystemTemplate.PrintFooter)
    );
  }

  private async _openDialog(
    data: ITemplateDefinitionWithName[]
  ): Promise<IResolvedTemplateWithName[] | undefined> {
    return this._dialog
      .open<
        PrintVariantSelectorDialogComponent,
        ITemplateDefinitionWithName[],
        IResolvedTemplateWithName[]
      >(PrintVariantSelectorDialogComponent, DialogPresets.large({ data }))
      .afterClosed()
      .toPromise();
  }
}
