import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  queryParam$,
  TypedFormControl,
  validFormControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  isChanged$,
  ISO_DATE_FORMAT,
  toMoment,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { type Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-date-pagination',
    templateUrl: './date-pagination.component.html',
    styleUrls: ['./date-pagination.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [...MOMENT_DATEPICKER_PROVIDERS],
    standalone: false
})
export class DatePaginationComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  isToday$: Observable<boolean>;
  dateCtrl = new TypedFormControl<moment.Moment>(moment()).withGuard(
    moment.isMoment
  );
  @Input()
  @HostBinding('class.isColoured')
  isColoured: boolean = false;
  @Output()
  dateChange: EventEmitter<Date> = new EventEmitter<Date>();

  constructor(private _router: Router, private _route: ActivatedRoute) {
    this.isToday$ = validFormControlChanges$(this.dateCtrl).pipe(
      map((value) => moment().isSame(value, 'day'))
    );
    validFormControlChanges$(this.dateCtrl)
      .pipe(
        map(() => this.dateCtrl.value.toDate()),
        isChanged$(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((date) => {
        void this._updateQueryParam(date);
        this.dateChange.emit(date);
      });
  }

  @Input()
  set date(date: Date | Moment) {
    this.dateCtrl.setValue(toMoment(date), { emitEvent: false });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  previousDay(): void {
    const date = moment(this.dateCtrl.value).subtract(1, 'day');
    this.dateCtrl.setValue(date);
  }

  nextDay(): void {
    const date = moment(this.dateCtrl.value).add(1, 'day');
    this.dateCtrl.setValue(date);
  }

  today(): void {
    const today = moment();
    this.dateCtrl.setValue(today);
  }

  private async _updateQueryParam(date: Date): Promise<void> {
    await this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        date: moment(date).format(ISO_DATE_FORMAT),
      },
    });
  }
}

export function datePaginationQueryParam$(
  route: ActivatedRoute
): Observable<moment.Moment> {
  return queryParam$(route, 'date').pipe(
    filter((date) => !!date),
    distinctUntilChanged(),
    map((date) => moment(date))
  );
}
