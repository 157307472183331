import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { CardInputComponent } from '@principle-theorem/ng-stripe';
import {
  type IInvoice,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import type stripe from 'stripe';
import {
  TransactionPracticeOptionMethods,
  TransactionPracticeOptions,
} from '../transaction-components/transaction-practice-options';

export interface IStripeTransactionInput {
  amount: number;
  invoice: WithRef<IInvoice>;
}

export interface IStripeFormData {
  amount: number;
  token: stripe.Token;
  practiceRef: DocumentReference<IPractice>;
}

@Component({
    selector: 'pr-stripe-transaction',
    templateUrl: './stripe-transaction.component.html',
    styleUrls: ['./stripe-transaction.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StripeTransactionComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  limit = 0;
  practiceOptions: TransactionPracticeOptions;
  form = new TypedFormGroup<IStripeFormData>({
    amount: new TypedFormControl<number>(0, Validators.required),
    token: new TypedFormControl<stripe.Token>(undefined, Validators.required),
    practiceRef: new TypedFormControl<DocumentReference<IPractice>>(undefined, [
      Validators.required,
    ]),
  });

  @ViewChild(CardInputComponent, { static: true })
  cardInput: CardInputComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IStripeTransactionInput,
    private _dialogRef: MatDialogRef<
      StripeTransactionComponent,
      IStripeFormData | undefined
    >,
    private _currentScope: CurrentScopeFacade
  ) {
    this.limit = data.amount;
    this.form.patchValue({ amount: data.amount });
    this.practiceOptions = new TransactionPracticeOptions(
      this._currentScope.currentPractice$,
      TransactionPracticeOptionMethods.toInvoicePracticeOption(
        this.data.invoice
      ),
      true
    );
    this.practiceOptions.initialValue$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((practice) =>
        this.form.controls.practiceRef.setValue(practice.ref)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async submit(): Promise<void> {
    await this.cardInput.update();
    if (!this.form.valid) {
      return;
    }
    this._dialogRef.close(this.form.getRawValue());
  }
}
