import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  CurrentPatientScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  Appointment,
  ClinicalChart,
  FeeSchedule,
  getDefaultFeeSchedule,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  FeeScheduleScope,
  type IClinicalChart,
} from '@principle-theorem/principle-core/interfaces';
import {
  auditUserInput,
  doc$,
  filterUndefined,
  getDoc,
  isWithRef,
  patchDoc,
  saveDoc,
  serialise,
  serialise$,
  toNamedDocument,
  unserialise$,
  type DocumentReference,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { combineLatest, iif, of, type Observable } from 'rxjs';
import {
  concatMap,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { setChartingAs } from '../actions/chart-context';
import { setChartView } from '../actions/chart-view';
import {
  loadFeeSchedules,
  loadFeeSchedulesSuccess,
  selectDefaultFeeSchedule,
  selectFeeSchedule,
} from '../actions/fee-schedule';
import {
  loadChart,
  loadChartFromAppointment,
  loadChartSuccess,
  removeChart,
  saveChart,
  setChart,
} from '../actions/load-chart';
import { upsertPerioValue } from '../actions/perio-table';
import {
  addCondition,
  removeCondition,
  updateCondition,
} from '../actions/selected-conditions';
import {
  addMultiTreatment,
  addTreatment,
  removeMultiTreatment,
  removeTreatment,
  updateMultiTreatment,
  updatePlanProposal,
  updateTreatment,
  updateTreatments,
} from '../actions/selected-treatments';
import {
  addTooth,
  removeTooth,
  updateToothRoots,
} from '../actions/tooth-change';
import { ChartFacade } from '../facades/chart.facade';
import { FeeScheduleFacade } from '../facades/fee-schedule.facade';
import { ChartId } from '../reducers/active-charts/chart-context-state';

@Injectable()
export class ChartEffects {
  private _actions$ = inject(Actions);
  private _organisation = inject(OrganisationService);
  private _patientScope = inject(CurrentPatientScope);
  saveChart$: Observable<void> = createEffect(() => this._saveChart$(), {
    dispatch: false,
  });
  loadChartFromAppointment$: Observable<Action> = createEffect(() =>
    this._loadChartFromAppointment$()
  );
  loadChart$: Observable<Action> = createEffect(() => this._loadChart$());
  loadChartView$: Observable<Action> = createEffect(() =>
    this._loadChartView$()
  );
  loadFeeSchedules$: Observable<Action> = createEffect(() =>
    this._loadFeeSchedules$()
  );
  selectDefaultFeeSchedule$: Observable<Action> = createEffect(() =>
    this._selectDefaultFeeSchedule$()
  );
  refreshFeeSchedules$: Observable<Action> = createEffect(() =>
    this._refreshFeeSchedules$()
  );
  triggerSave$: Observable<Action> = createEffect(() =>
    this._triggerSaveChart$()
  );

  constructor(
    private _chartStore: ChartFacade,
    private _feeScheduleStore: FeeScheduleFacade
  ) {}

  private _triggerSaveChart$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(
        addCondition,
        updateCondition,
        removeCondition,
        addTreatment,
        addMultiTreatment,
        updateTreatment,
        updateTreatments,
        updateMultiTreatment,
        removeTreatment,
        removeMultiTreatment,
        addTooth,
        removeTooth,
        updateToothRoots,
        upsertPerioValue,
        updatePlanProposal
      ),
      map((action) => saveChart({ id: action.id }))
    );
  }

  private _saveChart$(): Observable<void> {
    return this._actions$.pipe(
      ofType(saveChart),
      filter((action) => action.id === ChartId.InAppointment),
      switchMap((action) => this._chartStore.clinicalChartState$(action.id)),
      auditUserInput(),
      concatMap(async (chart) => {
        if (isWithRef(chart)) {
          await saveDoc(chart);
          return;
        }
        // eslint-disable-next-line no-console
        console.error('Cannot save chart without a ref', { chart });
      })
    );
  }

  private _loadChartFromAppointment$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(loadChartFromAppointment),
      unserialise$(),
      concatMap(async ({ patient, appointment, id }) => {
        if (appointment.clinicalChart) {
          const chart = await getDoc(
            appointment.clinicalChart as DocumentReference<IClinicalChart>
          );
          return { id, chart };
        }

        if (!Appointment.inProgress(appointment)) {
          return { id, chart: undefined };
        }

        const clinicalChart = await ClinicalChart.cloneFromLatest(patient);
        await patchDoc(appointment.ref, { clinicalChart });
        const chart = await getDoc(clinicalChart);
        return { id, chart };
      }),
      map((data) => {
        return data.chart
          ? loadChartSuccess(
              serialise({
                ...data,
              })
            )
          : removeChart({ ...data });
      })
    );
  }

  private _loadChart$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(loadChart),
      unserialise$(),
      switchMap((action) =>
        combineLatest([doc$<IClinicalChart>(action.chartRef), of(action)])
      ),
      map(([chart, action]) =>
        loadChartSuccess(
          serialise({
            id: action.id,
            chart,
          })
        )
      )
    );
  }

  private _loadChartView$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(loadChart, setChart),
      unserialise$(),
      withLatestFrom(
        this._organisation.staffer$.pipe(
          map((staffer) => staffer?.settings?.charting?.view),
          filterUndefined()
        ),
        this._patientScope.doc$.pipe(
          map((patient) => patient?.settings?.charting?.view)
        )
      ),
      map(([action, stafferView, patientView]) =>
        setChartView(
          serialise({
            id: action.id,
            view: patientView ?? stafferView,
          })
        )
      )
    );
  }

  private _refreshFeeSchedules$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(setChartingAs),
      unserialise$(),
      concatMap(({ staffer }) =>
        combineLatest([
          this._organisation.organisation$.pipe(filterUndefined()),
          this._organisation.brand$,
          this._organisation.practice$,
          of(staffer),
        ])
      ),
      map(([organisation, brand, practice, staffer]) =>
        loadFeeSchedules(
          serialise({
            organisation,
            brand,
            practice,
            staffer,
          })
        )
      )
    );
  }

  private _loadFeeSchedules$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(loadFeeSchedules),
      unserialise$(),
      switchMap((schedule) => {
        const organisationFees = !schedule.organisation
          ? of([])
          : FeeSchedule.all$(schedule.organisation);
        const brandFees = !schedule.brand
          ? of([])
          : FeeSchedule.all$(schedule.brand);
        const practiceFees = !schedule.practice
          ? of([])
          : FeeSchedule.all$(schedule.practice);
        const stafferFees = !schedule.staffer
          ? of([])
          : FeeSchedule.all$(schedule.staffer);
        const combinedFees = combineLatest([
          organisationFees,
          brandFees,
          practiceFees,
          stafferFees,
        ]).pipe(
          map(([organisation, brand, practice, staffer]) => [
            ...organisation,
            ...brand,
            ...practice,
            ...staffer,
          ])
        );
        return combineLatest([
          combinedFees,
          organisationFees.pipe(
            switchMap((schedules) =>
              iif(
                () =>
                  !schedule.organisation || !schedules.length ? false : true,
                of({
                  name: 'Organisation Schedules',
                  scope: FeeScheduleScope.Organisation,
                  schedules,
                }),
                of(undefined)
              )
            )
          ),
          brandFees.pipe(
            switchMap((schedules) =>
              iif(
                () => (!schedule.brand || !schedules.length ? false : true),
                of({
                  name: schedule.brand
                    ? toNamedDocument(schedule.brand).name
                    : '',
                  scope: FeeScheduleScope.Brand,
                  schedules,
                }),
                of(undefined)
              )
            )
          ),
          practiceFees.pipe(
            switchMap((schedules) =>
              iif(
                () => (!schedule.practice || !schedules.length ? false : true),
                of({
                  name: schedule.practice
                    ? toNamedDocument(schedule.practice).name
                    : '',
                  scope: FeeScheduleScope.Practice,
                  schedules,
                }),
                of(undefined)
              )
            )
          ),
          stafferFees.pipe(
            switchMap((schedules) =>
              iif(
                () => (!schedule.staffer || !schedules.length ? false : true),
                of({
                  name: schedule.staffer
                    ? stafferToNamedDoc(schedule.staffer).name
                    : '',
                  scope: FeeScheduleScope.Practitioner,
                  schedules,
                }),
                of(undefined)
              )
            )
          ),
        ]);
      }),
      map(
        ([
          combinedFees,
          organisationFees,
          brandFees,
          practiceFees,
          stafferFees,
        ]) => ({
          feeSchedules: combinedFees,
          groups: compact([
            organisationFees,
            brandFees,
            practiceFees,
            stafferFees,
          ]),
        })
      ),
      map(({ feeSchedules, groups }) =>
        loadFeeSchedulesSuccess(
          serialise({
            feeSchedules,
            groups,
          })
        )
      )
    );
  }

  private _selectDefaultFeeSchedule$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(selectDefaultFeeSchedule),
      unserialise$(),
      concatMap((action) =>
        this._feeScheduleStore.feeScheduleGroups$.pipe(
          filter((groups) => !!groups.length),
          switchMap((feeScheduleGroups) =>
            getDefaultFeeSchedule(feeScheduleGroups, action.patient)
          ),
          take(1)
        )
      ),
      tap((feeSchedule) => {
        if (!feeSchedule) {
          // eslint-disable-next-line no-console
          console.error('No default fee schedule found');
        }
      }),
      filterUndefined(),
      serialise$(),
      map(({ ref }) => selectFeeSchedule({ ref }))
    );
  }
}
