<div class="container">
  <ng-template #showLoader>
    <div class="loader" [ngClass]="size$ | async" fxLayoutAlign="center center">
      <mat-spinner [diameter]="16" />
    </div>
  </ng-template>

  <div
    fxFlex
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="media$ | async as media"
  >
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
      <ng-container *ngIf="loaded$ | async; else showLoader">
        <ng-container *ngIf="fileUrl$ | async as url; else fileIcon">
          <div
            fxLayoutAlign="center center"
            class="thumbnail"
            [ngClass]="size$ | async"
          >
            <img fxFill [src]="url" />
          </div>
        </ng-container>
        <ng-template #fileIcon>
          <div
            class="thumbnail"
            [ngClass]="size$ | async"
            *ngIf="fileExtension$ | async as extension"
            fxLayoutAlign="center center"
          >
            <file-icon
              set="vivid"
              [size]="fileIconSize$ | async"
              [type]="extension"
            />
          </div>
        </ng-template>
      </ng-container>
      <div fxLayout="column" fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <div>{{ media.name | removeStorageUid }}</div>
          <pr-inline-tags-display
            size="sm"
            [tags]="namedDocsToTags.tags$ | async"
          />
        </div>
        <span class="mat-caption">
          {{ media.createdAt | moment | amDateFormat : dateFormat }}
        </span>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="24px">
      <div
        *ngIf="media | map : hasExpired; else hasExpiry"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="8px"
      >
        <mat-icon color="warn">warning</mat-icon>
        <div class="mat-caption expired">This media has expired</div>
      </div>

      <ng-template #hasExpiry>
        <div *ngIf="media.expiresAt" class="expiry mat-caption">
          Expires: {{ media.expiresAt | moment | amDateFormat : dateFormat }}
        </div>
      </ng-template>
    </div>
  </div>
</div>
