import { type ComponentType } from '@angular/cdk/portal';
import { type ComponentLoader } from '@principle-theorem/ng-shared';
import {
  ChartType,
  type IChartConfig,
  type IDataBuilder,
} from '@principle-theorem/reporting';
import { DcPieChartBuilder } from '../chart-builders/dc-chart-builders/dc-pie-chart-builder';
import { DynamicD3ChartComponent } from '../dynamic-d3-chart/dynamic-d3-chart.component';
import { DynamicDcChartComponent } from '../dynamic-dc-chart/dynamic-dc-chart.component';
import { D3GroupedRowChartBuilder } from './d3-chart-builders/d3-grouped-row-chart';
import { D3StackedRowChartBuilder } from './d3-chart-builders/d3-stacked-row-chart';
import { DcRowChartBuilder } from './dc-chart-builders/dc-row-chart-builder';

export interface IDynamicChartData<T> {
  config: IChartConfig;
  dataBuilder: IDataBuilder;
  chartBuilder: T;
}

type DynamicChartComponentLoader<Q, T extends IDynamicChartData<unknown>> = {
  component: ComponentType<Q>;
  resolveData: (config: IChartConfig, dataBuilder: IDataBuilder) => T;
};

function toDynamicChartBuilder<T>(
  chartBuilder: T
): (config: IChartConfig, dataBuilder: IDataBuilder) => IDynamicChartData<T> {
  return (config: IChartConfig, dataBuilder: IDataBuilder) => ({
    config,
    chartBuilder,
    dataBuilder,
  });
}

type DynamicChartMap = Record<
  ChartType,
  DynamicChartComponentLoader<unknown, IDynamicChartData<unknown>>
>;

const DYNAMIC_CHART_MAP: Partial<DynamicChartMap> = {
  [ChartType.Pie]: {
    component: DynamicDcChartComponent,
    resolveData: toDynamicChartBuilder(new DcPieChartBuilder()),
  },
  [ChartType.Row]: {
    component: DynamicDcChartComponent,
    resolveData: toDynamicChartBuilder(new DcRowChartBuilder()),
  },
  [ChartType.RowStacked]: {
    component: DynamicD3ChartComponent,
    resolveData: toDynamicChartBuilder(new D3StackedRowChartBuilder()),
  },
  [ChartType.RowGrouped]: {
    component: DynamicD3ChartComponent,
    resolveData: toDynamicChartBuilder(new D3GroupedRowChartBuilder()),
  },
};

export type ChartComponentLoader = ComponentLoader<
  unknown,
  IDynamicChartData<unknown>
>;

export function resolveDynamicChartDefinition(
  dataBuilder: IDataBuilder,
  chartConfig: IChartConfig,
  chartType: ChartType
): ChartComponentLoader {
  const mapData = DYNAMIC_CHART_MAP[chartType];
  if (!mapData) {
    throw new Error(`Couldn't resolve component for chart: ${chartType}`);
  }
  return {
    component: mapData.component,
    data: mapData.resolveData(chartConfig, dataBuilder),
  };
}
