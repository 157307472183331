import {
  createEntityAdapter,
  type Dictionary,
  type EntityAdapter,
} from '@ngrx/entity';
import {
  Action,
  ActionReducer,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { type IInvoice } from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import { InvoiceActions } from '../actions';
import {
  getPatientModuleState,
  type IInvoicesState,
  type IPatientModuleState,
} from './states';
export { IInvoicesState } from './states';

export const INVOICES_FEATURE_KEY = 'invoices';

export const invoicesAdapter: EntityAdapter<SerialisedData<WithRef<IInvoice>>> =
  createEntityAdapter<SerialisedData<WithRef<IInvoice>>>({
    selectId: (invoice) => invoice.reference,
    sortComparer: false,
  });

export const initialInvoiceState: IInvoicesState =
  invoicesAdapter.getInitialState({
    draftId: undefined,
    loaded: false,
  });

export const reducer: ActionReducer<IInvoicesState, Action> = createReducer(
  initialInvoiceState,
  on(InvoiceActions.loadInvoices, (state) => ({
    ...state,
    loaded: false,
  })),
  on(InvoiceActions.loadInvoiceSuccess, (state, action) =>
    invoicesAdapter.upsertMany(action.invoices, { ...state, loaded: true })
  ),
  on(InvoiceActions.loadDraftInvoiceSuccess, (state, action) => ({
    ...state,
    draftId: action.invoiceRef,
  })),
  on(InvoiceActions.reset, () => initialInvoiceState)
);

export const getInvoiceState = createSelector(
  getPatientModuleState,
  (state: IPatientModuleState) => state.invoices
);

export const {
  selectAll: selectAllInvoices,
  selectEntities: selectInvoiceEntities,
} = invoicesAdapter.getSelectors(getInvoiceState);

export const getDraftInvoiceId = createSelector(
  getInvoiceState,
  (state: IInvoicesState) => state.draftId
);

export const getDraftInvoice = createSelector(
  selectInvoiceEntities,
  getDraftInvoiceId,
  (invoices: Dictionary<SerialisedData<WithRef<IInvoice>>>, draftId?: string) =>
    draftId ? invoices[draftId] : undefined
);
