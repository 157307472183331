import { roundTo2Decimals } from '@principle-theorem/accounting';
import {
  AccountCreditType,
  IAccountCredit,
  IAccountCreditSummary,
  IAccountInvoiceSummary,
  IAccountPaymentSummary,
  IAccountSummary,
  IInvoice,
  IInvoiceSummary,
  IPatient,
  ITransaction,
  InvoiceStatus,
  PatientRelationshipType,
  TransactionProvider,
  isDepositLineItem,
  isFeeLineItem,
  isProductLineItem,
  isTreatmentLineItem,
} from '@principle-theorem/principle-core/interfaces';
import {
  FirestoreTransactionHelper,
  WithRef,
  asyncForEach,
  undeletedQuery,
} from '@principle-theorem/shared';
import { flatten, sum } from 'lodash';
import { AccountCredit } from '../account-credit/account-credit';
import { Invoice, withTransactions } from '../invoice/invoice';
import { LineItemActions } from '../invoice/line-item-actions';
import { TransactionAllocation } from '../invoice/transaction-allocation';
import { TransactionOperators } from '../transaction/transaction-operators';
import { Patient } from './patient';

const reportableInvoiceStatuses: InvoiceStatus[] = [
  InvoiceStatus.Paid,
  InvoiceStatus.WrittenOff,
  InvoiceStatus.Issued,
];

export async function buildAccountSummary(
  invoices: WithRef<IInvoice>[],
  credits: WithRef<IAccountCredit>[]
): Promise<IAccountSummary> {
  const reportInvoices = invoices.filter((invoice) =>
    reportableInvoiceStatuses.includes(invoice.status)
  );
  const invoicesWithTransactions = await asyncForEach(
    reportInvoices,
    (invoice) => withTransactions(invoice)
  );

  return {
    creditSummary: getCreditSummary(credits),
    ...getInvoiceSummaries(invoicesWithTransactions),
  };
}

function getCreditSummary(
  credits: WithRef<IAccountCredit>[]
): IAccountCreditSummary {
  const freeCredit = sum(
    credits
      .filter((credit) => credit.type === AccountCreditType.Manual)
      .map((credit) => credit.amount)
  );
  const depositsPaid = sum(
    credits
      .filter((credit) => credit.type !== AccountCreditType.Manual)
      .map((credit) => credit.amount)
  );

  const creditTotal = sum(credits.map((credit) => credit.amount));
  const creditUsed = sum(credits.map((credit) => -credit.used));
  const creditRemaining = sum([creditTotal, creditUsed]);

  return { freeCredit, depositsPaid, creditTotal, creditUsed, creditRemaining };
}

export function getInvoiceSummaries(
  invoicesWithTransactions: [
    WithRef<IInvoice>,
    WithRef<ITransaction<unknown>>[],
  ][]
): IInvoiceSummary {
  return reduceInvoiceSummaries(
    invoicesWithTransactions.map(([invoice, transactions]) =>
      getInvoicePaymentSummary(invoice, transactions)
    )
  );
}

export function getInvoicePaymentSummary(
  invoice: WithRef<IInvoice>,
  transactions: WithRef<ITransaction<unknown>>[]
): IInvoiceSummary {
  const invoiceSummary = getInvoiceSummary(invoice);
  const paymentSummary = getPaymentSummary(
    invoice,
    transactions,
    invoiceSummary.totalInvoiced
  );
  const transactionAllocations = TransactionAllocation.getInvoiceAllocations(
    invoice,
    transactions
  );
  const allocationSummary = TransactionAllocation.getAllocationsSummaries(
    transactionAllocations
  );
  return {
    invoiceSummary,
    paymentSummary,
    transactionAllocations,
    allocationSummary,
  };
}

function getInvoiceSummary(invoice: WithRef<IInvoice>): IAccountInvoiceSummary {
  const treatments = LineItemActions.sum(
    invoice.items.filter(isTreatmentLineItem)
  );
  const products = LineItemActions.sum(invoice.items.filter(isProductLineItem));
  const fees = LineItemActions.sum(invoice.items.filter(isFeeLineItem));
  const deposits = LineItemActions.sum(invoice.items.filter(isDepositLineItem));

  const subtotal = sum([treatments, products, fees]);
  const totalInvoiced = Invoice.total(invoice);
  return {
    treatments: roundTo2Decimals(treatments),
    products: roundTo2Decimals(products),
    fees: roundTo2Decimals(fees),
    subtotal: roundTo2Decimals(subtotal),
    deposits: roundTo2Decimals(deposits),
    totalInvoiced: roundTo2Decimals(totalInvoiced),
  };
}

function getPaymentSummary(
  invoice: WithRef<IInvoice>,
  rawTransactions: ITransaction[],
  totalInvoiced: number
): IAccountPaymentSummary {
  const transactions = new TransactionOperators(rawTransactions);
  const discounts = -transactions.discounts();

  const writtenOff =
    invoice.status === InvoiceStatus.WrittenOff
      ? -(totalInvoiced - transactions.paidToDate())
      : 0;
  const creditUsed = -transactions
    .byProvider(TransactionProvider.AccountCredit)
    .paidToDate();
  const totalReceivable = sum([
    totalInvoiced,
    discounts,
    writtenOff,
    creditUsed,
  ]);
  const paymentsReceived = sum([
    transactions.paidToDate(),
    discounts,
    creditUsed,
  ]);
  const outstanding = totalReceivable - paymentsReceived;
  return {
    discounts: roundTo2Decimals(discounts),
    writtenOff: roundTo2Decimals(writtenOff),
    creditUsed: roundTo2Decimals(creditUsed),
    totalReceivable: roundTo2Decimals(totalReceivable),
    paymentsReceived: roundTo2Decimals(paymentsReceived),
    outstanding: roundTo2Decimals(outstanding),
  };
}

function reduceInvoiceSummaries(summaries: IInvoiceSummary[]): IInvoiceSummary {
  const invoiceSummaries = summaries.map((summary) => summary.invoiceSummary);
  const invoiceSummary: IAccountInvoiceSummary = {
    treatments: sum(invoiceSummaries.map((summary) => summary.treatments)),
    products: sum(invoiceSummaries.map((summary) => summary.products)),
    fees: sum(invoiceSummaries.map((summary) => summary.fees)),
    subtotal: sum(invoiceSummaries.map((summary) => summary.subtotal)),
    deposits: sum(invoiceSummaries.map((summary) => summary.deposits)),
    totalInvoiced: sum(
      invoiceSummaries.map((summary) => summary.totalInvoiced)
    ),
  };
  const paymentSummaries = summaries.map((summary) => summary.paymentSummary);
  const paymentSummary: IAccountPaymentSummary = {
    discounts: sum(paymentSummaries.map((summary) => summary.discounts)),
    writtenOff: sum(paymentSummaries.map((summary) => summary.writtenOff)),
    creditUsed: sum(paymentSummaries.map((summary) => summary.creditUsed)),
    totalReceivable: sum(
      paymentSummaries.map((summary) => summary.totalReceivable)
    ),
    paymentsReceived: sum(
      paymentSummaries.map((summary) => summary.paymentsReceived)
    ),
    outstanding: sum(paymentSummaries.map((summary) => summary.outstanding)),
  };
  const transactionAllocations = flatten(
    summaries.map((summary) => summary.transactionAllocations)
  );
  const allocationSummary = TransactionAllocation.getAllocationsSummaries(
    transactionAllocations
  );
  return {
    invoiceSummary,
    paymentSummary,
    transactionAllocations,
    allocationSummary,
  };
}

export async function getPatientAccountSummaryUpdates(
  patient: WithRef<IPatient>,
  atomicTransaction: FirebaseFirestore.Transaction
): Promise<IAccountSummary> {
  const patientRefs = Patient.relationshipRefs(patient, [
    PatientRelationshipType.DuplicatePatient,
  ]);

  const invoices = await asyncForEach(patientRefs, async (patientRef) =>
    FirestoreTransactionHelper.getDocs(
      undeletedQuery(Patient.invoiceCol({ ref: patientRef })),
      atomicTransaction
    )
  );

  const credits = await asyncForEach(patientRefs, async (patientRef) =>
    FirestoreTransactionHelper.getDocs(
      undeletedQuery(AccountCredit.col({ ref: patientRef })),
      atomicTransaction
    )
  );

  return buildAccountSummary(invoices.flat(), credits.flat());
}
