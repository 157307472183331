import { TaxStrategy } from '@principle-theorem/accounting';
import {
  IServiceCode,
  IServiceProvider,
  ServiceCodeType,
  type ItemCodeAliases,
} from '../service-item';
import { ADA_CODE_MAP } from './ada';
import { ServiceCodeScope } from './ada-code';
import { Region } from '@principle-theorem/shared';

const note1 =
  'DVA will pay for dentures every six (6) years and a reline every two (2) years. DVA will not pay for a new denture if provided within twelve months of a reline of an existing denture. The number of teeth for each individual partial denture should be specified for each claim. If a patient has been assessed as requiring new dentures/relines outside of the above limits, providers are no longer required to contact DVA for prior financial authorisation. If treatment is provided outside of the above limits, providers must provide clinical justification to DVA if requested.';

const note2 = `A fee will not be paid for:
  1. adjustment(s) to full or partial dentures within twelve (12) months following provision or relining; or
  2. reline(s) or remodel(s) to each upper or lower denture within two (2) years following provision or relining (except for immediate dentures which can be relined once within two years of their provision – please specify immediate denture reline on the claim form).
  Upper or lower denture must be specified for each claim.
  If a patient has been assessed as requiring adjustments or relines outside of the above limits, providers are no longer required to contact DVA for prior financial authorisation.
  If treatment is provided outside of the above limits, providers must provide clinical justification to DVA if requested.`;

export const PROSTHETIC_DENTAL_DVA_CODES: ItemCodeAliases = Object.entries({
  T011: {
    ...ADA_CODE_MAP['011'],
    code: 'T011',
    rootCode: '011',
    notes:
      'Limit of one (1) per provider every two years after previous 011. Limit applies to the same provider.',
    fee: 55.75,
    rules: {
      scope: ServiceCodeScope.PerPeriodMonths,
      scopeValue: 24,
    },
  },
  T016: {
    ...ADA_CODE_MAP['016'],
    code: 'T016',
    rootCode: '016',
    notes:
      'Payable only when specifically requested by DVA. Includes report to DVA. Subject to GST.',
    fee: 108.8,
  },
  T018: {
    ...ADA_CODE_MAP['018'],
    code: 'T018',
    rootCode: '01',
    notes:
      'Claimable only when specifically requested by DVA. Must be kept on patient’s file. Subject to GST.',
    fee: 49.75,
  },
  T019: {
    ...ADA_CODE_MAP['019'],
    code: 'T019',
    rootCode: '019',
    notes:
      'Limit of one (1) per provider per 12 month period. A copy of this referral must be retained by provider.',
    fee: 11.7,
    rules: {
      scope: ServiceCodeScope.PerPeriodMonths,
      scopeValue: 12,
    },
  },
  T071: {
    ...ADA_CODE_MAP['071'],
    code: 'T071',
    rootCode: '071',
    notes:
      'On request from DVA only. Subject to GST. Limit of two (2) models per appointment (that is, one upper and one lower).',
    fee: 63.95,
    rules: {
      scope: ServiceCodeScope.PerAppointment,
      scopeValue: 2,
    },
  },
  T711: {
    ...ADA_CODE_MAP['711'],
    code: 'T711',
    rootCode: '711',
    notes: note1,
    fee: 1010.1,
  },
  T712: {
    ...ADA_CODE_MAP['712'],
    code: 'T712',
    rootCode: '712',
    notes: note1,
    fee: 1010.1,
  },
  T713: {
    ...ADA_CODE_MAP['713'],
    code: 'T713',
    rootCode: '713',
    notes:
      'This item allows for provisional denture to be relined or replaced within 12 months.',
    fee: 757.55,
  },
  T714: {
    ...ADA_CODE_MAP['714'],
    code: 'T714',
    rootCode: '714',
    notes:
      'This item allows for provisional denture to be relined or replaced within 12 months.',
    fee: 757.55,
  },
  T715: {
    ...ADA_CODE_MAP['715'],
    code: 'T715',
    rootCode: '715',
    notes:
      'This item allows for provisional denture to be relined or replaced within 12 months',
    fee: 1343.2,
  },
  T716: {
    ...ADA_CODE_MAP['716'],
    code: 'T716',
    rootCode: '716',
    notes:
      'Additional to items 711, 712 or 719. Laboratory casting invoice required. Maximum amount payable $481.05.',
  },
  T719: {
    ...ADA_CODE_MAP['719'],
    code: 'T719',
    rootCode: '719',
    notes:
      'DVA will pay for dentures every six (6) years and a reline every two (2) years. DVA will not pay for a new denture if provided within twelve months of a reline of an existing denture. The number of teeth for each individual partial denture should be specified for each claim. If a patient has been assessed as requiring new dentures/relines outside of the above limits, providers are no longer required to contact DVA for prior financial authorisation. If treatment is provided outside of the above limits, providers must provide clinical justification to DVA if requested.',
    fee: 1791,
  },
  T721: {
    ...ADA_CODE_MAP['721'],
    code: 'T721',
    rootCode: '721',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733.',
    fee: 462.15,
  },
  T722: {
    ...ADA_CODE_MAP['722'],
    code: 'T722',
    rootCode: '722',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733.',
    fee: 462.15,
  },
  T723: {
    ...ADA_CODE_MAP['723'],
    code: 'T723',
    rootCode: '723',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733. This item allows for provisional denture to be relined or replaced within 12 months',
    fee: 346.6,
  },
  T724: {
    ...ADA_CODE_MAP['724'],
    code: 'T724',
    rootCode: '724',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733. This item allows for provisional denture to be relined or replaced within 12 months',
    fee: 346.6,
  },
  T727: {
    ...ADA_CODE_MAP['727'],
    code: 'T727',
    rootCode: '727',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733.',
    fee: 1352.95,
  },
  T728: {
    ...ADA_CODE_MAP['728'],
    code: 'T728',
    rootCode: '728',
    notes:
      'This item refers to denture base only. The number of teeth are specified in item 733.',
    fee: 1352.95,
  },
  T731: {
    ...ADA_CODE_MAP['731'],
    code: 'T731',
    rootCode: '731',
    notes: '',
    fee: 46.6,
  },
  T732: {
    ...ADA_CODE_MAP['732'],
    code: 'T732',
    rootCode: '732',
    notes: '',
    fee: 22.8,
  },
  T733: {
    ...ADA_CODE_MAP['733'],
    code: 'T733',
    rootCode: '733',
    notes:
      'Maximum of 12 teeth per denture base (with partial denture items 721, 722, 723, 724, 727, 728).',
    fee: 38.3,
  },
  T734: {
    ...ADA_CODE_MAP['734'],
    code: 'T734',
    rootCode: '734',
    notes: 'Can only be claimed with items 727 or 728.',
    fee: 45.65,
  },
  T736: {
    ...ADA_CODE_MAP['736'],
    code: 'T736',
    rootCode: '736',
    notes: '',
    fee: 9.6,
  },
  T737: {
    ...ADA_CODE_MAP['737'],
    code: 'T737',
    rootCode: '737',
    notes:
      'DVA will pay for item 737 with a new denture or items 737 and 743 together for an existing complete denture; and items 737 and 744 for an existing partial denture.',
    fee: 200.3,
  },
  T738: {
    ...ADA_CODE_MAP['738'],
    code: 'T738',
    rootCode: '738',
    notes: '',
    fee: 186.5,
  },
  T739: {
    ...ADA_CODE_MAP['739'],
    code: 'T739',
    rootCode: '739',
    notes:
      'Can only be claimed with items 716, 727 or 728. Only claimable where a denture tooth has its entire occlusal contact with teeth of opposing arch covered by metal.',
    fee: 9.8,
  },
  T741: {
    ...ADA_CODE_MAP['741'],
    code: 'T741',
    rootCode: '741',
    notes: note2,
    fee: 55.35,
  },
  T743: {
    ...ADA_CODE_MAP['743'],
    code: 'T743',
    rootCode: '743',
    notes: `${note2} For soft relines, use items 743 and 737.`,
    fee: 352.45,
  },
  T744: {
    ...ADA_CODE_MAP['744'],
    code: 'T744',
    rootCode: '744',
    notes: `${note2} For soft relines, use items 744 and 737.`,
    fee: 300.45,
  },
  T745: {
    ...ADA_CODE_MAP['745'],
    code: 'T745',
    rootCode: '745',
    notes: note2,
  },
  T746: {
    ...ADA_CODE_MAP['746'],
    code: 'T746',
    rootCode: '746',
    notes: note2,
  },
  T751: {
    ...ADA_CODE_MAP['751'],
    code: 'T751',
    rootCode: '751',
    notes: `${note2} Limit of one (1) per denture every two years. Chair-side only. Either hard or soft material. Not to be used for temporary materials i.e. tissue conditioners`,
    fee: 192,
    rules: {
      scope: ServiceCodeScope.PerPeriodMonths,
      scopeValue: 24,
    },
  },
  T752: {
    ...ADA_CODE_MAP['752'],
    code: 'T752',
    rootCode: '752',
    notes: `${note2} Not to be used for temporary materials i.e. tissue conditioners.`,
    fee: 160.1,
  },
  T753: {
    ...ADA_CODE_MAP['753'],
    code: 'T753',
    rootCode: '753',
    notes: `${note2} Limit of one (1) per two year period per denture. Subject to GST.`,
    taxStatus: TaxStrategy.GSTPossible,
    fee: 44.95,
    rules: {
      scope: ServiceCodeScope.PerPeriodMonths,
      scopeValue: 24,
    },
  },
  T761: {
    ...ADA_CODE_MAP['761'],
    code: 'T761',
    rootCode: '761',
    notes:
      'Both items must be claimed. 761 to be claimed for GST-free component of service. 482 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 40.3,
    rules: {
      scope: ServiceCodeScope.PerDenture,
      inConjunctionWith: {
        required: [482],
      },
    },
  },
  T482: {
    ...ADA_CODE_MAP['761'],
    code: 'T482',
    rootCode: '482',
    notes:
      'Both items must be claimed. 761 to be claimed for GST-free component of service. 482 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 112.6,
    taxStatus: TaxStrategy.GSTApplicable,
    rules: {
      scope: ServiceCodeScope.PerDenture,
      inConjunctionWith: {
        required: [761],
      },
    },
  },
  T762: {
    ...ADA_CODE_MAP['762'],
    code: 'T762',
    rootCode: '762',
    notes: 'Limit of one (1) per day per denture. GST free.',
    fee: 159.55,
    rules: {
      scope: ServiceCodeScope.PerDenture,
    },
  },
  T763: {
    ...ADA_CODE_MAP['763'],
    code: 'T763',
    rootCode: '763',
    notes:
      'Both items must be claimed. 763 to be claimed for GST-free component of service. 484 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 40.3,
    rules: {
      scope: ServiceCodeScope.PerDenture,
      inConjunctionWith: {
        required: [484],
      },
    },
  },
  T484: {
    ...ADA_CODE_MAP['763'],
    code: 'T484',
    rootCode: '484',
    notes:
      'Both items must be claimed. 763 to be claimed for GST-free component of service. 484 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 112.6,
    taxStatus: TaxStrategy.GSTApplicable,
    rules: {
      scope: ServiceCodeScope.PerDenture,
      inConjunctionWith: {
        required: [763],
      },
    },
  },
  T764: {
    ...ADA_CODE_MAP['764'],
    code: 'T764',
    rootCode: '764',
    notes:
      'Both items must be claimed. 764 to be claimed for GST-free component of service. 485 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 40.3,
    rules: {
      scope: ServiceCodeScope.PerDenture,
      inConjunctionWith: {
        required: [485],
      },
    },
  },
  T485: {
    ...ADA_CODE_MAP['764'],
    code: 'T485',
    rootCode: '485',
    notes:
      'Both items must be claimed. 764 to be claimed for GST-free component of service. 485 (labour, laboratory costs) to be claimed for GST-able component of service. Limit of one (1) per day per denture.',
    fee: 112.6,
    taxStatus: TaxStrategy.GSTApplicable,
    rules: {
      scope: ServiceCodeScope.PerDenture,
      inConjunctionWith: {
        required: [764],
      },
    },
  },
  T765: {
    ...ADA_CODE_MAP['765'],
    code: 'T765',
    rootCode: '765',
    notes: 'Limit of one (1) per day per denture.',
    fee: 159.55,
    rules: {
      scope: ServiceCodeScope.PerDenture,
    },
  },
  T767: {
    ...ADA_CODE_MAP['767'],
    code: 'T767',
    rootCode: '767',
    notes:
      'Both items must be claimed. 767 to be claimed for GST-free component of service. 488 (labour, laboratory costs) to be claimed for GST-able component of service.',
    fee: 38,
    rules: {
      inConjunctionWith: {
        required: [488],
      },
    },
  },
  T488: {
    ...ADA_CODE_MAP['767'],
    code: 'T488',
    rootCode: '488',
    notes:
      'Both items must be claimed. 767 to be claimed for GST-free component of service. 488 (labour, laboratory costs) to be claimed for GST-able component of service.',
    fee: 24.95,
    taxStatus: TaxStrategy.GSTApplicable,
    rules: {
      inConjunctionWith: {
        required: [767],
      },
    },
  },
  T768: {
    ...ADA_CODE_MAP['768'],
    code: 'T768',
    rootCode: '768',
    notes: 'Limit of one (1) per day per denture.',
    fee: 161.4,
    rules: {
      scope: ServiceCodeScope.PerDenture,
    },
  },
  T769: {
    ...ADA_CODE_MAP['769'],
    code: 'T769',
    rootCode: '769',
    notes:
      'Limit of one (1) per day per denture. Laboratory casting invoice required. Maximum amount payable $309.40. Subject to GST.',
    rules: {
      scope: ServiceCodeScope.PerDenture,
    },
  },
  T771: {
    ...ADA_CODE_MAP['771'],
    code: 'T771',
    rootCode: '771',
    notes:
      'Limit of one (1) per denture per appointment. Limit of five (5) per three month period. UPR or LWR must be specified.',
    fee: 73.3,
    rules: {
      scope: ServiceCodeScope.PerDenture,
    },
  },
  T776: {
    ...ADA_CODE_MAP['776'],
    code: 'T776',
    rootCode: '776',
    notes: '',
    fee: 48.7,
  },
  T777: {
    ...ADA_CODE_MAP['777'],
    code: 'T777',
    rootCode: '777',
    notes: 'Limit of one (1) per denture.',
    fee: 38.95,
    rules: {
      scope: ServiceCodeScope.PerDenture,
    },
  },
  T916: {
    ...ADA_CODE_MAP['916'],
    code: 'T916',
    rootCode: '916',
    notes:
      'One per client per day. One per location per day. For example, only pay once per day for travel to retirement home regardless of how many patients are seen. Note: a provider operating a mobile dental clinic is not entitled to this item. Can be claimed without a dental item if it is part of non-billable dental treatment such as adjustments and repairs to dentures. Reasons for the travel should be provided.',
    fee: 70.65,
    rules: {
      scope: ServiceCodeScope.PerDay,
    },
  },
}).reduce(
  (codes, [key, code]) => ({
    ...codes,
    [key]: {
      ...code,
      type: ServiceCodeType.DVAProsthetic,
    },
  }),
  {}
);

export const DVA_PROSTHETIC_CODE_MAP: ItemCodeAliases = {
  ...PROSTHETIC_DENTAL_DVA_CODES,
};

export const DVA_PROSTHETIC_SERVICE_CODES: IServiceCode[] = Object.values(
  DVA_PROSTHETIC_CODE_MAP
);

export const DVA_PROSTHETIC_SERVICE: IServiceProvider = {
  label: 'DVA Prosthetics',
  description: 'DVA Prosthetic Scheme',
  type: ServiceCodeType.DVAProsthetic,
  parentType: ServiceCodeType.ADA,
  items: DVA_PROSTHETIC_CODE_MAP,
  region: Region.Australia,
};
