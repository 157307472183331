import {
  Component,
  ChangeDetectionStrategy,
  HostBinding,
  Input,
  type OnDestroy,
} from '@angular/core';
import { type ITimelineDisplayOptions } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { InteractiveTimelineDisplayCalculator } from '../interactive-timeline/interactive-timeline-display-calculator';

@Component({
  selector: 'pr-interactive-timeline-day-line',
  templateUrl: './interactive-timeline-day-line.component.html',
  styleUrls: ['./interactive-timeline-day-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InteractiveTimelineDayLineComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _options$ = new ReplaySubject<ITimelineDisplayOptions>(1);
  @HostBinding('class.horizontal') isHorizontal = true;
  @HostBinding('class.vertical') isVertical = false;

  @Input()
  set options(options: ITimelineDisplayOptions) {
    if (options) {
      this._options$.next(options);
    }
  }

  constructor() {
    this._options$
      .pipe(
        map(InteractiveTimelineDisplayCalculator.isHorizontal),
        takeUntil(this._onDestroy$)
      )
      .subscribe((isHorizontal) => {
        this.isHorizontal = isHorizontal;
        this.isVertical = !isHorizontal;
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
