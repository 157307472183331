import { type IFirestoreModel } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import {
  type ITreatmentPlan,
  type TreatmentPlanStatus,
} from './treatment-plan';

export interface ITreatmentPlanAggregate extends IFirestoreModel {
  date?: Timestamp;
  status: TreatmentPlanStatus;
  treatmentPlans: ITreatmentPlan[];
}
