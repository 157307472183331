import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  inject,
} from '@angular/core';
import { CurrentPatientScope } from '@principle-theorem/ng-principle-shared';
import { filterUndefined } from '@principle-theorem/shared';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PatientPrescriptionFormComponent } from '../patient-prescription-form/patient-prescription-form.component';
import {
  DeletePrescriptonData,
  PrescriptionService,
} from '../prescription.service';

@Component({
  selector: 'pr-patient-prescription',
  standalone: true,
  imports: [CommonModule, PatientPrescriptionFormComponent],
  providers: [PrescriptionService],
  templateUrl: './patient-prescription.component.html',
  styleUrl: './patient-prescription.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientPrescriptionComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _currentPatient = inject(CurrentPatientScope);
  prescription = inject(PrescriptionService);
  prescriptionIdRouteParam$ = new ReplaySubject<string>(1);

  @Input({ required: true })
  set prescriptionIdRouteParam(prescriptionIdRouteParam: string) {
    if (prescriptionIdRouteParam) {
      this.prescriptionIdRouteParam$.next(prescriptionIdRouteParam);
    }
  }

  constructor() {
    combineLatest([
      this._currentPatient.doc$.pipe(filterUndefined()),
      this.prescriptionIdRouteParam$,
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([patient, prescriptionIdRouteParam]) =>
        this.prescription.load$.next({
          uid: prescriptionIdRouteParam,
          patient,
        })
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async delete(data: DeletePrescriptonData): Promise<void> {
    this.prescription.delete$.next(data);
    await this.prescription.navigate();
  }
}
