import {
  FormSchemaPropertyType,
  type IFormLayoutElement,
  type IFormSchema,
} from '@principle-theorem/principle-core/interfaces';
import { formHtml } from '../custom-form-helpers';

export const TERMS_AND_CONDITIONS_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    agreed: {
      type: FormSchemaPropertyType.Boolean,
      title: 'I agree to the terms and conditions',
      const: true,
    },
  },
  required: ['agreed'],
};

export const TERMS_AND_CONDITIONS_FORM_LAYOUT: IFormLayoutElement[] = [
  formHtml(`
    <p class="mat-caption">
      All personal information collected is confidential and handled in
      accordance to our privacy policy. See
      <a href="https://principle.dental/legal/privacy-policy" target="_blank">principle.dental/legal/privacy-policy</a>
    </p>
    <p class="mat-caption">
      By signing this form, you acknowledge and agree that: a) you have
      accurately completed this pre-clinical questionnaire to the best of your
      knowledge b) you consent to treatment agreed upon by you to be carried
      out by the dentist and their staff c) you assume full financial
      responsibility for treatment and d) payment is due in full at the time
      of service unless other arrangements have been made.
    </p>
    <p class="mat-caption">
      Cancellation policy: 48hrs notice is required for cancelling or
      rebooking appointments, otherwise it may incur a cancellation fee or
      refusal to reschedule.
    </p>
  `),
  { key: 'agreed' },
];
