import { isEnumValue, isObject } from '@principle-theorem/shared';

export const PATIENT_PORTAL_DISPATCHER =
  'http-patientPortal-patientPortalDispatcher';

export enum OnlineBookingAction {
  GetBookingSuggestions = 'onlineBooking.getBookingSuggestions',
  GetOnlineBookingData = 'onlineBooking.getOnlineBookingData',
  SubmitBookingRequest = 'onlineBooking.submitBookingRequest',
}

export enum PatientAction {
  GetPatientConfirmAppointmentInfo = 'patient.getPatientConfirmAppointmentInfo',
  GetPatientInvoice = 'patient.getPatientInvoice',
  GetPatientMedicalHistoryFormData = 'patient.getPatientMedicalHistoryFormData',
  SubmitPatientDetailsForm = 'patient.submitPatientDetailsForm',
  SubmitPatientMedicalForm = 'patient.submitPatientMedicalForm',
}

export type OnlineBookingActionRequest<T> = {
  action: OnlineBookingAction;
  data: T;
};

export type PatientActionRequest<T> = {
  action: PatientAction;
  data: T;
};

export function isOnlineBookingActionRequest<T>(
  request: unknown
): request is OnlineBookingActionRequest<T> {
  return (
    isObject(request) &&
    'action' in request &&
    isEnumValue(OnlineBookingAction, request.action) &&
    'data' in request &&
    isObject(request.data)
  );
}

export function isPatientActionRequest<T>(
  request: unknown
): request is PatientActionRequest<T> {
  return (
    isObject(request) &&
    'action' in request &&
    isEnumValue(PatientAction, request.action) &&
    'data' in request &&
    isObject(request.data)
  );
}
