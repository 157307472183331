import {
  TypeGuard,
  getEnumValues,
  isEnumValue,
  isISODateType,
  isObject,
  type INamedDocument,
  type ISODateType,
  type ISoftDelete,
} from '@principle-theorem/shared';
import { isArray, isString } from 'lodash';
import { IFeeSchedule } from '../clinical-charting/fees/fee-schedule/fee-schedule';
import { IStatusHistory } from '../common';
import { INote } from '../note/note';
import { IPractice } from '../practice/practice';
import { IChartSettings, IStaffer } from '../staffer/staffer';
import { ITag } from '../tag/tag';
import { IDVACard, IHealthFundCard, IMedicareCard } from './healthfund-cards';
import {
  isPatientRelationship,
  type IPatientRelationship,
} from './patient-relationship';
import { type IReferralSource } from './referral-source';
import { IAccountSummary } from './account-summary';
import { IPatientMetadata } from './patient-metadata';

export const NICKNAME_REGEX = new RegExp(/\(.*?\)/g);

export class PatientContactDetailsError extends Error {}

export enum Gender {
  Male = 'male',
  Female = 'female',
  Other = 'other',
  NotSpecified = 'notSpecified',
}

export const GENDERS: Gender[] = getEnumValues(Gender);

export enum PatientEventAction {
  Activated = 'activated',
  Blocked = 'blocked',
  Deactivated = 'deactivated',
}

export enum PatientStatus {
  Lead = 'lead',
  Active = 'active',
  Blocked = 'blocked',
  Inactive = 'inactive',
  Deceased = 'deceased',
}

export const PATIENT_STATUSES: PatientStatus[] = getEnumValues(PatientStatus);

export interface IPatientSettings {
  charting: Partial<IChartSettings>;
}

export enum PatientForm {
  MedicalHistoryForm = 'medical-history-form',
  PatientDetailsForm = 'patient-details-form',
}

export interface IHasPrimaryContact {
  primaryContact: IPatientRelationship<IBasePatient & IsPrimaryContact>;
}

export enum ContactNumberLabel {
  Mobile = 'Mobile',
  Home = 'Home',
  Work = 'Work',
}

export interface IContactNumber {
  label?: string;
  number: string;
}

export function isContactNumber(data: unknown): data is IContactNumber {
  return isObject(data) && isString(data.label) && isString(data.number);
}

interface IBasePatientContactDetails {
  address: string;
}

interface IPatientEmailContactDetails extends IBasePatientContactDetails {
  email: string;
  contactNumbers?: IContactNumber[];
}

interface IPatientPhoneContactDetails extends IBasePatientContactDetails {
  contactNumbers: IContactNumber[];
  email?: string;
}

export type IPatientContactDetails =
  | IPatientEmailContactDetails
  | IPatientPhoneContactDetails;

export function isPatientContactDetails(
  data: unknown
): data is IPatientContactDetails {
  return (
    isObject(data) &&
    isString(data.address) &&
    (isString(data.email) ||
      TypeGuard.arrayOf(isContactNumber)(data.contactNumbers))
  );
}

export type WithPrimaryContact = Partial<IPatientContactDetails> &
  IHasPrimaryContact;
export type WithContactDetails = IPatientContactDetails &
  Partial<IHasPrimaryContact>;
export type IsPrimaryContact = IPatientContactDetails & {
  isPrimaryContact: true;
};

export interface IBasePatient extends ISoftDelete {
  name: string;
  referrer?: IReferralSource;
  relationships: IPatientRelationship[];
  dateOfBirth?: ISODateType;
  gender: Gender;
  status: PatientStatus;
  statusHistory: IStatusHistory<PatientStatus>[];
  preferredDentist?: INamedDocument<IStaffer>;
  preferredHygienist?: INamedDocument<IStaffer>;
  preferredPractice?: INamedDocument<IPractice>;
  preferredFeeSchedule?: INamedDocument<IFeeSchedule>;
  notes: INote[];
  tags: INamedDocument<ITag>[];
  accountSummary: IAccountSummary;
  profileImageURL?: string;
  medicareCard?: IMedicareCard;
  healthFundCard?: IHealthFundCard;
  dvaCard?: IDVACard;
  referenceId?: string;
  settings?: Partial<IPatientSettings>;
  metadata?: IPatientMetadata;
}

export type IPatient = IBasePatient &
  (WithPrimaryContact | WithContactDetails | IsPrimaryContact);

export function isPatient(data: unknown): data is IPatient {
  const canContact = isWithPrimaryContact(data) || isWithContactDetails(data);
  return (
    isObject(data) &&
    'name' in data &&
    isString(data.name) &&
    TypeGuard.undefinedOr(isISODateType)(data.dateOfBirth) &&
    'gender' in data &&
    isEnumValue(Gender, data.gender) &&
    'status' in data &&
    isEnumValue(PatientStatus, data.status) &&
    'tags' in data &&
    isArray(data.tags) &&
    canContact
  );
}

export function isWithPrimaryContact(
  data: unknown
): data is WithPrimaryContact {
  return (
    isObject(data) &&
    'primaryContact' in data &&
    isPatientRelationship(data.primaryContact)
  );
}

export function isPatientWithPrimaryContact(
  data: unknown
): data is IPatient & WithPrimaryContact {
  return isPatient(data) && isWithPrimaryContact(data);
}

export function isWithContactDetails(
  data: unknown
): data is WithContactDetails {
  return (
    isObject(data) &&
    isString(data.address) &&
    (isString(data.email) ||
      (isArray(data.contactNumbers) &&
        data.contactNumbers.every(isContactNumber)))
  );
}

export function isPatientWithContactDetails(
  data: unknown
): data is IPatient & WithContactDetails {
  return isPatient(data) && isWithContactDetails(data);
}

export function isPrimaryContactPatient(
  data: unknown
): data is IBasePatient & IsPrimaryContact {
  return (
    isPatientWithContactDetails(data) &&
    'isPrimaryContact' in data &&
    data.isPrimaryContact === true
  );
}

export enum PatientCollection {
  Appointments = 'appointments',
  TreatmentPlans = 'treatmentPlans',
  History = 'history',
  Invoices = 'invoices',
  Credits = 'credits',
  PaymentPlans = 'paymentPlans',
  SubmittedForms = 'submittedForms',
  ClinicalCharts = 'clinicalCharts',
  ClinicalNotes = 'clinicalNotes',
  Media = 'media',
  PatientDocuments = 'patientDocuments',
  Prescriptions = 'prescriptions',
  PatientDetailsHistory = 'patientDetailsHistory',
}
