import {
  ISchedule,
  ScheduleModifierOption,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  initFirestoreModel,
  endsOnCount,
  endsOnDate,
  IRecurrencePattern,
  ITimePeriod,
  Timezone,
  toISODate,
  toMomentTz,
} from '@principle-theorem/shared';
import { last } from 'lodash';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { RecurrencePattern } from './recurrence-pattern';
import { ScheduleModifier } from './schedule-modifier';

export class Schedule {
  static init<T>(overrides: AtLeast<ISchedule<T>, 'item'>): ISchedule<T> {
    const firestoreModel = initFirestoreModel();
    return {
      pattern: RecurrencePattern.init({
        startDate: toISODate(firestoreModel.createdAt),
      }),
      modifiers: [],
      ...firestoreModel,
      ...overrides,
    };
  }

  static getFirstOccurence(schedule: ISchedule, timezone: Timezone): Moment {
    const startDate = toMomentTz(
      schedule.pattern.startDate ?? schedule.createdAt,
      timezone
    );
    return RecurrencePattern.getFirstOccurrenceDate(
      schedule.pattern,
      timezone,
      startDate
    );
  }

  static getNearestOccurenceToRange(
    schedule: ISchedule,
    range: ITimePeriod,
    timezone: Timezone
  ): Moment {
    const startDate = toMomentTz(
      schedule.pattern.startDate ?? schedule.createdAt,
      timezone
    );

    return RecurrencePattern.getNearestOccurrenceDateToRange(
      schedule.pattern,
      startDate,
      range,
      timezone
    );
  }

  static forecast(
    schedule: ISchedule,
    range: ITimePeriod,
    timezone: Timezone
  ): Moment[] {
    if (Schedule.endsBeforeDate(schedule, range.from, timezone)) {
      return [];
    }

    const nearest = Schedule.getNearestOccurenceToRange(
      schedule,
      range,
      timezone
    );

    if (endsOnCount(schedule.pattern)) {
      const forecast = Schedule.forecaster(
        schedule.pattern,
        nearest,
        range.to,
        timezone,
        (occurrences, pattern) => occurrences.length < pattern.occurrenceCount
      );
      return Schedule.modify(forecast, schedule, range);
    }

    if (endsOnDate(schedule.pattern)) {
      const forecast = Schedule.forecaster(
        schedule.pattern,
        nearest,
        range.to,
        timezone,
        (occurrences, pattern) => {
          const previous = last(occurrences);
          return previous
            ? previous.isBefore(toMomentTz(pattern.endingDate, timezone))
            : false;
        }
      ).filter((occurrence) =>
        occurrence.isBetween(range.from, range.to, undefined, '[]')
      );
      return Schedule.modify(forecast, schedule, range);
    }

    const forecast = Schedule.forecaster(
      schedule.pattern,
      nearest,
      range.to,
      timezone
    ).filter((occurrence) =>
      occurrence.isBetween(range.from, range.to, undefined, '[]')
    );
    return Schedule.modify(forecast, schedule, range);
  }

  static forecaster<T extends IRecurrencePattern>(
    pattern: T,
    next: Moment,
    end: Moment,
    timezone: Timezone,
    canContinue: (occurrences: Moment[], pattern: T) => boolean = () => true
  ): Moment[] {
    const occurrences = [next];
    while (next.isBefore(end) && canContinue(occurrences, pattern)) {
      next = RecurrencePattern.getNextOccurrenceDate(
        pattern,
        timezone,
        moment(next)
      );
      occurrences.push(next);
    }
    return occurrences;
  }

  static endsBeforeDate(
    schedule: ISchedule,
    date: Moment,
    timezone: Timezone
  ): boolean {
    if (endsOnDate(schedule.pattern)) {
      return toMomentTz(schedule.pattern.endingDate, timezone).isBefore(date);
    }
    if (endsOnCount(schedule.pattern)) {
      const first = Schedule.getFirstOccurence(schedule, timezone);
      const results = Schedule.forecaster(
        schedule.pattern,
        first,
        date,
        timezone,
        (occurrences, pattern) => occurrences.length < pattern.occurrenceCount
      );
      const lastOccurrence = last(results);
      return lastOccurrence ? lastOccurrence.isBefore(date) : false;
    }
    return false;
  }

  static addModifier<T>(
    schedule: ISchedule<T>,
    modifier: ScheduleModifierOption
  ): ISchedule<T> {
    return {
      ...schedule,
      modifiers: ScheduleModifier.reduceModifiersByType([
        ...schedule.modifiers,
        modifier,
      ]),
    };
  }

  static modify(
    forecast: Moment[],
    schedule: ISchedule,
    range: ITimePeriod
  ): Moment[] {
    if (!schedule.modifiers.length) {
      return forecast;
    }

    const modifiers = schedule.modifiers.filter((modifier) =>
      ScheduleModifier.modifiesRange(modifier, range)
    );
    return ScheduleModifier.applyModifiers(forecast, modifiers);
  }
}
