import { type IChartedMultiStepTreatment } from './charted-multi-step-treatment';

/**
 * Treatment Fork
 *
 * Groups treatments/treatment groups? and multi-stage treatments to select
 * which one to add to the treatment plan. An optional can contain treatments
 * and multi-stage treatments however multi-stage treatments can't be
 * configured with options.
 */
export interface ITreatmentFork {
  options: IChartedMultiStepTreatment[];
}
