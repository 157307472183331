<h2>Tyro Reconciliation Report</h2>
<div class="container">
  <ng-container *ngIf="report$ | async as report; else loading">
    <pre *ngIf="report.error as error">{{ error }}</pre>
    <pre *ngIf="report.data as data">{{ data }}</pre>
  </ng-container>
  <ng-template #loading>
    <mat-progress-bar mode="indeterminate" />
    <pre>Loading...</pre>
  </ng-template>
</div>
