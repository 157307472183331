import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  inject,
  Input,
  type OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ChartedConfigurationFacade,
  ChartFacade,
  type ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  type IChartedItem,
  type ITooth,
} from '@principle-theorem/principle-core/interfaces';
import {
  isChanged$,
  shareReplayCold,
  snapshot,
} from '@principle-theorem/shared';
import { isBoolean } from 'lodash';
import { BehaviorSubject, combineLatest, type Observable, Subject } from 'rxjs';
import { auditTime, map } from 'rxjs/operators';
import { CHART_ENTITY_ID } from '../dental-chart-svg/chart-entity-id';
import {
  AddToothDialogComponent,
  type IAddToothDialogData,
} from '../dental-chart-svg/chart-quadrant/add-tooth-dialog/add-tooth-dialog.component';
import { DentalChartSvgComponent } from '../dental-chart-svg/dental-chart-svg.component';
import { ChartViewFactoryV2 } from '../dental-chart-svg/models/chart-view-factory-v2';
import {
  ChartSVGLayoutRenderer,
  type IChartRenderView,
} from '../dental-chart-svg/renderers/chart-svg-layout-renderer';
import { ChartViewRenderer } from '../dental-chart-svg/renderers/chart-view-renderer';

@Component({
  selector: 'pr-clinical-chart',
  templateUrl: './clinical-chart.component.html',
  styleUrls: ['./clinical-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'prClinicalChart',
})
export class ClinicalChartComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _chartedItems$: BehaviorSubject<IChartedItem[]> = new BehaviorSubject<
    IChartedItem[]
  >([]);
  private _chartId: ChartId = inject(CHART_ENTITY_ID);
  renderer$: Observable<IChartRenderView>;
  hasNoTeeth$: Observable<boolean>;

  @HostBinding('class.disabled')
  @Input()
  disabled = false;

  @ViewChild(DentalChartSvgComponent, { static: true })
  dentalChartSvg: DentalChartSvgComponent;

  constructor(
    private _chartStore: ChartFacade,
    private _dialog: MatDialog,
    private _chartedConfigStore: ChartedConfigurationFacade
  ) {
    const teeth$: Observable<ITooth[]> = this._chartStore
      .clinicalChartState$(this._chartId)
      .pipe(
        map((chart) => chart.teeth),
        isChanged$(),
        shareReplayCold()
      );
    this.hasNoTeeth$ = teeth$.pipe(map((teeth) => !teeth.length));

    this.renderer$ = combineLatest([
      teeth$,
      this._chartedItems$,
      this._chartedConfigStore.combinedConfigurations$,
      this._chartStore.chartContextState$(this._chartId).pipe(
        map(({ chartView, chartSection, isStacked, chart }) => ({
          chartView,
          chartSection,
          isStacked,
          chart,
        })),
        isChanged$()
      ),
    ]).pipe(
      auditTime(100),
      map(
        ([
          teeth,
          chartedItems,
          chartedItemConfigurations,
          { chartView, chartSection, isStacked, chart },
        ]) => {
          const filteredTeeth = new ChartViewFactoryV2(
            teeth,
            chartView,
            chartSection
          ).filteredTeeth;

          const viewRenderer = new ChartViewRenderer(
            filteredTeeth,
            chartedItems,
            chartedItemConfigurations,
            chart
          );
          const svgLayoutRenderer = new ChartSVGLayoutRenderer(
            filteredTeeth,
            viewRenderer.render,
            isStacked
          );
          return svgLayoutRenderer.render;
        }
      ),
      shareReplayCold()
    );
  }

  @HostListener('mousedown', ['$event'])
  @HostListener('click', ['$event'])
  @HostListener('drag-start', ['$event'])
  handleDisable($event: MouseEvent): void {
    if (this.disabled) {
      $event.stopImmediatePropagation();
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @Input()
  set stacked(stackedRows: boolean) {
    if (isBoolean(stackedRows)) {
      this._chartStore.setIsStacked(this._chartId, stackedRows);
    }
  }

  @Input()
  set chartedItems(chartedItems: IChartedItem[]) {
    if (chartedItems) {
      this._chartedItems$.next(chartedItems);
    }
  }

  async openAddTooth(): Promise<void> {
    const chart = await snapshot(
      this._chartStore.clinicalChartState$(this._chartId)
    );

    this._dialog.open<AddToothDialogComponent, IAddToothDialogData>(
      AddToothDialogComponent,
      DialogPresets.medium({
        data: { chart },
      })
    );
  }
}
