import { type TrackByFunction } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IBrandQueryRequest,
  type IDateRangeQueryRequest,
  type IPractice,
  type IPracticeQueryRequest,
  QueryScopeConfigId,
  type ReportingReference,
} from '@principle-theorem/principle-core/interfaces';
import {
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import {
  filterUndefined,
  type INamedDocument,
  snapshot,
  toTimestamp,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import * as moment from 'moment-timezone';
import { type Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { type IQueryableTimestamp } from '../../../models/report-builder-data-sources/report-builder-data-source';

export interface IQueryScopeForm<T, QueryScopeRequest extends object> {
  scopeId: QueryScopeConfigId;
  formGroup: TypedFormGroup<QueryScopeRequest>;
  options$: Observable<T[]>;
  trackBy: TrackByFunction<T>;
  isShown$: Observable<boolean>;
  getDefaultValue(
    overrides?: Partial<QueryScopeRequest>
  ): Promise<QueryScopeRequest>;
}

export class BrandQueryScopeForm
  implements IQueryScopeForm<INamedDocument<IBrand>, IBrandQueryRequest>
{
  scopeId = QueryScopeConfigId.Brand;
  trackBy = TrackByFunctions.ref<INamedDocument<IBrand>>();
  isShown$: Observable<boolean>;
  formGroup = new TypedFormGroup<IBrandQueryRequest>({
    brandRef: new TypedFormControl<DocumentReference<IBrand>>(
      undefined,
      Validators.required
    ),
  });

  constructor(public options$: Observable<INamedDocument<IBrand>[]>) {
    this.isShown$ = options$.pipe(map((options) => options.length > 1));
  }

  async getDefaultValue(
    overrides?: Partial<IBrandQueryRequest>
  ): Promise<IBrandQueryRequest> {
    const brand$ = this.options$.pipe(
      map((brands) => first(brands)),
      filterUndefined()
    );
    const brand = await snapshot(brand$);
    return {
      brandRef: brand.ref,
      ...overrides,
    };
  }
}

export class PracticeQueryScopeForm
  implements IQueryScopeForm<INamedDocument<IPractice>, IPracticeQueryRequest>
{
  scopeId = QueryScopeConfigId.Practice;
  trackBy = TrackByFunctions.ref<INamedDocument<IPractice>>();
  isShown$: Observable<boolean>;
  formGroup = new TypedFormGroup<IPracticeQueryRequest>({
    practiceRefs: new TypedFormControl<DocumentReference<IPractice>[]>(
      undefined,
      Validators.required
    ),
  });

  constructor(public options$: Observable<INamedDocument<IPractice>[]>) {
    this.isShown$ = options$.pipe(map((options) => options.length > 1));
  }

  async getDefaultValue(
    overrides?: Partial<IPracticeQueryRequest>
  ): Promise<IPracticeQueryRequest> {
    const practices = await snapshot(this.options$);

    return {
      practiceRefs: practices.map((practice) => practice.ref),
      ...overrides,
    };
  }
}

export class DateRangeQueryScopeForm
  implements IQueryScopeForm<IQueryableTimestamp, IDateRangeQueryRequest>
{
  scopeId = QueryScopeConfigId.DateRange;
  trackBy = TrackByFunctions.nestedField<IQueryableTimestamp>(
    'property.metadata.id,'
  );
  isShown$: Observable<boolean>;
  formGroup = new TypedFormGroup<IDateRangeQueryRequest>({
    from: new TypedFormControl<Timestamp>(undefined, Validators.required),
    to: new TypedFormControl<Timestamp>(undefined, Validators.required),
    queryableTimestamp: new TypedFormControl<ReportingReference>(
      undefined,
      Validators.required
    ),
  });

  constructor(public options$: Observable<IQueryableTimestamp[]>) {
    this.isShown$ = of(true);
  }

  async getDefaultValue(
    overrides?: Partial<IDateRangeQueryRequest>
  ): Promise<IDateRangeQueryRequest> {
    const queryableTimestamp$ = this.options$.pipe(
      map((queryableTimestamps) => first(queryableTimestamps)),
      filterUndefined()
    );
    const queryableTimestamp = await snapshot(queryableTimestamp$);
    return {
      from: toTimestamp(moment().startOf('day')),
      to: toTimestamp(moment().endOf('day')),
      queryableTimestamp: queryableTimestamp.property.metadata.id,
      ...overrides,
    };
  }
}

export interface IQueryScopeForms {
  [QueryScopeConfigId.Brand]: BrandQueryScopeForm;
  [QueryScopeConfigId.Practice]: PracticeQueryScopeForm;
  [QueryScopeConfigId.DateRange]: DateRangeQueryScopeForm;
}
