<mat-tab-group
  [animationDuration]="0"
  mat-stretch-tabs
  class="full-height h-full"
>
  <mat-tab label="Clinical">
    <ng-template matTabContent>
      <div class="flex h-full flex-col">
        <div class="min-height flex flex-auto p-2">
          <ng-container *ngIf="hasClinicalNote$ | async; else noClinicalNote">
            <pr-content-view
              *ngIf="clinicalNote$ | async as note"
              [content]="note.content"
            />
          </ng-container>
        </div>

        <div class="flex flex-row border-t border-solid border-slate-300 p-2">
          <a
            class="flex flex-auto"
            href="javascript:void(0);"
            (click)="openClinicalNotes()"
          >
            View Clinical Notes
          </a>

          <div *ngIf="practitioner$ | async as practitioner">
            {{ practitioner.user.name }}
          </div>
        </div>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab label="Scheduling">
    <ng-template matTabContent>
      <div class="flex h-full flex-col">
        <div class="min-height flex flex-auto">
          <pr-appointment-interactions
            enableScroll
            [readOnly]="true"
            [compact]="true"
            [appointment]="appointment$ | async"
          />
        </div>

        <div class="border-t border-solid border-slate-300 p-2">
          <a href="javascript:void(0);" (click)="openSchedulingNotes()">
            View Scheduling Notes
          </a>
        </div>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<ng-template #noClinicalNote>
  <div class="flex flex-auto items-center justify-center align-middle">
    <pr-empty-state image="list" title="Clinical Notes" />
  </div>
</ng-template>
