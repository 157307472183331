import { Injectable } from '@angular/core';
import { Money } from '@principle-theorem/accounting';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { BasicDialogService } from '@principle-theorem/ng-shared';
import { TyroService } from '@principle-theorem/ng-tyro';
import { Practice } from '@principle-theorem/principle-core';
import {
  TransactionProvider,
  type IHealthcareClaim,
  type IInvoice,
  type IPatient,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  guardFilter,
  snapshot,
  toInt,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import {
  isHealthPointClaimEstimateCompleteCallbackData,
  isHealthPointClaimEstimateErrorCallback,
  isNotStarted,
  isTyroResponse,
  type IHealthPointClaimEstimateCompleteCallbackData,
} from '@principle-theorem/tyro';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IClaimEstimateProvider,
  IClaimEstimateSummary,
} from '../../claim-estimate/claim-estimate-provider';
import { ClaimEstimateSummaryDialogService } from '../../transaction-components/claim-estimate-summary-dialog/claim-estimate-summary-dialog.service';
import { TransactionProviderType } from '../../transaction-provider';
import { TYRO_IMAGE_URL } from '../../transaction-provider-options/tyro-transaction-provider-options';
import { TYRO_ERROR_DIALOG_DATA } from '../base-transaction';
import { SelectTyroTerminalService } from '../select-tyro-terminal/select-tyro-terminal.service';
import { HealthPointBuilder } from './health-point-builder.service';

@Injectable({ providedIn: 'root' })
export class TyroHealthPointClaimEstimateProvider
  implements IClaimEstimateProvider
{
  label = 'Tyro';
  providerId = TransactionProvider.TyroHealthPoint;
  providerType = TransactionProviderType.HealthFund;
  isEnabled$: Observable<boolean>;
  icon = 'health_and_safety';
  imageUrl = TYRO_IMAGE_URL;

  constructor(
    private _tyro: TyroService,
    private _currentScopeFacade: CurrentScopeFacade,
    private _healthPointBuilder: HealthPointBuilder,
    private _selectTerminal: SelectTyroTerminalService,
    private _basicDialog: BasicDialogService,
    private _claimEstimateSummary: ClaimEstimateSummaryDialogService
  ) {
    this.isEnabled$ = this._currentScopeFacade.currentPractice$.pipe(
      map((practice) => Practice.isTyroEnabled(practice))
    );
  }

  async capture(
    invoice: IInvoice,
    claim: IHealthcareClaim,
    patient: WithRef<IPatient>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const formData = await this._healthPointBuilder.openHealthPointForm(
      invoice,
      patient
    );
    if (!formData) {
      return;
    }
    const terminal = await this._selectTerminal.getTerminalData();
    if (!terminal) {
      return;
    }
    const request = this._healthPointBuilder.buildHealthPointClaimEstimate(
      invoice,
      claim,
      formData,
      terminal.terminalData
    );

    const response = await snapshot(
      this._tyro
        .initiateHealthPointRebateEstimate$(request)
        .pipe(guardFilter(isTyroResponse))
    );

    if (isNotStarted(response) || !response) {
      return;
    }
    if (isHealthPointClaimEstimateErrorCallback(response)) {
      await this._basicDialog.alert({
        ...TYRO_ERROR_DIALOG_DATA,
        title: 'Notice',
        prompt: [`Error: ${response.result}`],
      });
      return;
    }
    if (!isHealthPointClaimEstimateCompleteCallbackData(response)) {
      // eslint-disable-next-line no-console
      console.error('Invaid data for healthpoint response', response);
      await this._basicDialog.alert(TYRO_ERROR_DIALOG_DATA);
      return;
    }

    const claimEstimate = this._toClaimEstimateSummary(response);
    await this._claimEstimateSummary.open(claimEstimate);
  }

  private _toClaimEstimateSummary(
    data: IHealthPointClaimEstimateCompleteCallbackData
  ): IClaimEstimateSummary {
    const items = data.healthpointClaimItems.map((item) => ({
      code: item.serviceCode,
      description: item.description,
      gap: Money.fromCents(toInt(item.claimAmount) - toInt(item.rebateAmount)),
      benefit: Money.fromCents(toInt(item.rebateAmount)),
    }));

    return {
      providerName: 'HealthPoint',
      items,
      totalBenefit: this._centsToDollars(data.healthpointTotalBenefitAmount),
      totalGap: this._centsToDollars(data.healthpointGapAmount),
    };
  }

  private _centsToDollars(cents?: string): number {
    if (!cents) {
      return 0;
    }
    return Money.fromCents(toInt(cents));
  }
}
