import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_INSURANCE_DETAIL_RESOURCE_TYPE = 'patientInsuranceDetails';

export const PATIENT_INSURANCE_DETAIL_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Insurance Details List',
      description: '',
      idPrefix: PATIENT_INSURANCE_DETAIL_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ICorePracticePatientInsuranceDetail {
  id: number;
  memberCode?: string; // "90484653"
  series?: string; // 04
  startDate?: string; // 2004-08-09 00:00:00.000
  endDate?: string; // 3000-01-01 00:00:00.000
  patientId: number;
  insuranceName: string;
  insuranceId: number;
  isDefault: boolean;
}

export function isCorePracticePatientInsuranceDetail(
  item: unknown
): item is ICorePracticePatientInsuranceDetail {
  return TypeGuard.interface<ICorePracticePatientInsuranceDetail>({
    id: isNumber,
    memberCode: TypeGuard.nilOr(isString),
    series: TypeGuard.nilOr(isString),
    startDate: TypeGuard.nilOr(isString),
    endDate: TypeGuard.nilOr(isString),
    patientId: isNumber,
    insuranceName: isString,
    insuranceId: isNumber,
    isDefault: isBoolean,
  })(item);
}

export interface ICorePracticePatientInsuranceDetailTranslations {}

export interface ICorePracticePatientInsuranceDetailFilters {
  patientId: number;
  insuranceId: number;
}

const PATIENT_INSURANCE_DETAIL_SOURCE_QUERY = `
SELECT * FROM (
  SELECT
  PatientInsuranceId AS id,
  MemberCode AS member_code,
  convert_to_text(Series) AS series,
  StartDate AS start_date,
  EndDate AS end_date,
  PatientId AS patient_id,
  InsuranceId AS insurance_id,
  convert_to_boolean(IsDefault) AS is_default
FROM tblPatientInsurance) as patient_insurance
LEFT JOIN (
  SELECT
    InsuranceId AS insurance_id,
    Name AS insurance_name
  FROM tblInsurance
  WHERE name IS NOT NULL
) AS insurance_option
ON insurance_option.insurance_id = patient_insurance.insurance_id
`;

export class PatientInsuranceDetailSourceEntity extends BaseSourceEntity<
  ICorePracticePatientInsuranceDetail,
  ICorePracticePatientInsuranceDetailTranslations,
  ICorePracticePatientInsuranceDetailFilters
> {
  sourceEntity = PATIENT_INSURANCE_DETAIL_SOURCE_ENTITY;
  entityResourceType = PATIENT_INSURANCE_DETAIL_RESOURCE_TYPE;
  sourceQuery = PATIENT_INSURANCE_DETAIL_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientInsuranceDetail;

  translate(
    _data: ICorePracticePatientInsuranceDetail,
    _timezone: Timezone
  ): ICorePracticePatientInsuranceDetailTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientInsuranceDetail): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientInsuranceDetail): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientInsuranceDetail,
    _timezone: Timezone
  ): ICorePracticePatientInsuranceDetailFilters {
    return {
      patientId: data.patientId,
      insuranceId: data.insuranceId,
    };
  }
}
