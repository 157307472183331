import { isNil } from 'lodash';

export interface IDestinationJobFilter<T extends string = string> {
  key: T;
  value: string;
}

export type DataResolverFn<JobData extends object> = (
  jobData: JobData
) => string | undefined;

export interface IDestinationJobFilterHandler<JobData extends object> {
  key: string;
  label: string;
  description: string;
  canHandle(filter: IDestinationJobFilter): boolean;
  matches(jobData: JobData, filter: IDestinationJobFilter): boolean;
}

export abstract class BaseDestinationJobFilterHandler<JobData extends object> {
  abstract key: string;
  abstract label: string;
  abstract description: string;

  constructor(private _dataResolverFn: DataResolverFn<JobData>) {}

  canHandle(filter: IDestinationJobFilter): boolean {
    return filter.key === this.key;
  }

  matches(jobData: JobData, filter: IDestinationJobFilter): boolean {
    if (isNil(filter.value)) {
      return false;
    }
    const data = this._dataResolverFn(jobData);
    return !isNil(data) && data === filter.value;
  }
}
