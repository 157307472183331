import { type ISource } from '@principle-theorem/principle-core/interfaces';
import { ADAItemSourceEntity } from '../exact/source/entities/ada-item';
import { AppointmentRoomSourceEntity } from '../exact/source/entities/appointment-room';
import { ContactsSourceEntity } from '../exact/source/entities/contacts';
import { FeeScheduleSourceEntity } from '../exact/source/entities/fee-schedule';
import { PatientSourceEntity } from '../exact/source/entities/patient';
import { AppointmentSourceEntity } from '../exact/source/entities/patient-appointment';
import { PatientBalanceSourceEntity } from '../exact/source/entities/patient-balance';
import { PatientDocumentsSourceEntity } from '../exact/source/entities/patient-documents';
import { PatientNotesSourceEntity } from '../exact/source/entities/patient-notes';
import { PatientPerioExamsSourceEntity } from '../exact/source/entities/patient-perio-exams';
import { PatientTreatmentSourceEntity } from '../exact/source/entities/patient-treatments';
import { StafferSourceEntity } from '../exact/source/entities/staff';
import { BaseSourceHandler } from '../source/base-source-handler';
import { DentrixTransactionSourceEntity } from './source/entities/patient-transactions';

export const DENTRIX_SOURCE: ISource = {
  metadata: {
    name: 'Dentrix',
    version: 'v1',
  },
  configuration: [
    {
      name: 'Practice Id',
      description: 'Dentrix Practice Id',
      value: '',
    },
    {
      name: 'Database Username',
      description: 'Database Username',
      value: '',
    },
    {
      name: 'Database Password',
      description: 'Database Password',
      value: '',
    },
    {
      name: 'Database Connection Name',
      description:
        'Fully Qualified Name of Database. Eg. "practice-migrations:australia-southeast1:[instance_name]"',
      value: '',
    },
    {
      name: 'Source Bucket Storage Path',
      description: `Folder name that patient files live under on`,
      value: '',
    },
  ],
};

export class DentrixSourceHandler extends BaseSourceHandler {
  migrationType = 'Dentrix';
  migrationVersion = 'v1';
  source = DENTRIX_SOURCE;

  entityHandlers = [
    new StafferSourceEntity(),
    new PatientSourceEntity(),
    new ADAItemSourceEntity(),
    new ContactsSourceEntity(),
    new AppointmentSourceEntity(),
    new PatientTreatmentSourceEntity(),
    new FeeScheduleSourceEntity(),
    new PatientNotesSourceEntity(),
    new PatientPerioExamsSourceEntity(),
    new DentrixTransactionSourceEntity(),
    new PatientBalanceSourceEntity(),
    new PatientDocumentsSourceEntity(),
    new AppointmentRoomSourceEntity(),
  ];
}
