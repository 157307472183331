import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderMessageSnackBarComponent } from './loader-message-snack-bar/loader-message-snack-bar.component';

@Injectable()
export class BasicSnackBarService {
  constructor(private _snackBar: MatSnackBar) {}

  open(message: string): void {
    this._snackBar.open(message);
  }

  loading(message: string): void {
    this._snackBar.openFromComponent(LoaderMessageSnackBarComponent, {
      data: message,
      duration: 0,
    });
  }
}
