<form
  [formGroup]="configForm"
  class="flex flex-col items-stretch justify-around"
>
  <mat-form-field class="flex-1">
    <mat-label>Name</mat-label>
    <input type="text" matInput [required]="true" formControlName="name" />
  </mat-form-field>

  <div class="flex items-center gap-3">
    <mat-form-field class="flex-1">
      <mat-label>Treatment Category</mat-label>
      <input
        type="text"
        matInput
        formControlName="category"
        [matAutocomplete]="categoryAutoComplete"
      />
      <mat-autocomplete
        #categoryAutoComplete="matAutocomplete"
        [displayWith]="displayCategoryFn"
        class="category-select-panel"
      >
        <mat-option
          *ngFor="let category of treatmentCategories; trackBy: trackByRef"
          [value]="category"
        >
          <div class="flex items-center gap-2">
            <span
              class="h-3 w-3 rounded-full"
              [style.backgroundColor]="category.colour.value"
            >
            </span>
            {{ category.name }}
          </div>
        </mat-option>
      </mat-autocomplete>
      <button
        matIconSuffix
        mat-icon-button
        *ngIf="configForm.value.category"
        (click)="clearCategory()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label>Duration (minutes)</mat-label>
      <input
        type="number"
        matInput
        [required]="true"
        formControlName="duration"
      />
    </mat-form-field>
  </div>

  <mat-form-field class="flex-1">
    <mat-label>Description</mat-label>
    <input
      type="text"
      matInput
      [required]="true"
      formControlName="description"
    />
  </mat-form-field>
</form>
