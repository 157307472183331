import { type IReffable } from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import {
  CustomMappingType,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { CalendarSourceEntity } from '../../source/entities/calendars';

export const EXCLUDED_APPOINTMENT_BOOK_CUSTOM_MAPPING_TYPE =
  'excludedAppointmentBookMapping';

export const EXCLUDED_APPOINTMENT_BOOK_MAPPING: ICustomMapping =
  CustomMapping.init({
    metadata: {
      label: 'Exclude Appointment Books',
      description: `Used to filter out appointments that are a part of an appointment book that's not for legitimate appointments.`,
      type: EXCLUDED_APPOINTMENT_BOOK_CUSTOM_MAPPING_TYPE,
    },
    type: CustomMappingType.ExclusionList,
  });

export class CorePracticeExcludedAppointmentBooksMappingHandler extends BaseCustomMappingHandler {
  customMapping = EXCLUDED_APPOINTMENT_BOOK_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const appointmentBooks = new CalendarSourceEntity();
    const records = await appointmentBooks
      .getRecords$(migration, 1000)
      .toPromise();
    return sortBy(
      records
        .map((record) => record.data.data)
        .map((record) => ({
          label: record.description,
          value: record.id.toString(),
        })),
      'value'
    );
  }
}
