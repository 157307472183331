import { rand } from '@ngneat/falso';
import {
  FactsCommon,
  ITimestampedFact,
} from '@principle-theorem/reporting/interfaces';
import { toSerialisedTimestamp, toTimestamp } from '@principle-theorem/shared';
import {
  CommonMockValues,
  IChartConfigTest,
  MockTimestampedFacts,
} from './common-mock-values';
import * as DailyDashboardChartConfigs from './daily-dashboard-chart-configs';

export function buildMockData<T extends FactsCommon.ITimestampedFact>(
  test: IChartConfigTest<T>
): T[][] {
  return [MockTimestampedFacts(test.seed, () => test.mockFact())];
}

export const newPatientDistribution: IChartConfigTest<ITimestampedFact> = {
  seed: 'newPatientDistributionSeed',
  config: DailyDashboardChartConfigs.newPatientDistribution,
  mockFact: () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      isFirstAppointment: true,
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      timestamp: toSerialisedTimestamp(toTimestamp()),
    };
  },
};

// export const revenueDistribution: IChartConfigTest = {
//   seed: 'revenueDistributionSeed',
//   config: DailyDashboardChartConfigs.revenueDistribution,
//   results: DailyDashboardChartConfigs.revenueDistributionResults,
// };

// export const patientsLeavingWithScheduledAppointment: IChartConfigTest = {
//   seed: 'patientsLeavingWithScheduledAppointmentSeed',
//   config: DailyDashboardChartConfigs.patientsLeavingWithScheduledAppointment,
//   results:
//     DailyDashboardChartConfigs.patientsLeavingWithScheduledAppointmentResults,
// };

// export const patientsLeavingWithoutScheduledAppointment: IChartConfigTest = {
//   seed: 'patientsLeavingWithoutScheduledAppointmentSeed',
//   config: DailyDashboardChartConfigs.patientsLeavingWithoutScheduledAppointment,
//   results:
//     DailyDashboardChartConfigs.patientsLeavingWithoutScheduledAppointmentResults,
// };

// export const appointmentCancellations: IChartConfigTest = {
//   seed: 'appointmentCancellationsSeed',
//   config: DailyDashboardChartConfigs.appointmentCancellations,
//   results: DailyDashboardChartConfigs.appointmentCancellationsResults,
// };

// export const appointmentFTAs: IChartConfigTest = {
//   seed: 'appointmentFTAsSeed',
//   config: DailyDashboardChartConfigs.appointmentFTAs,
//   results: DailyDashboardChartConfigs.appointmentFTAsResults,
// };

// export const appointmentUTAs: IChartConfigTest = {
//   seed: 'appointmentUTAsSeed',
//   config: DailyDashboardChartConfigs.appointmentUTAs,
//   results: DailyDashboardChartConfigs.appointmentUTAsResults,
// };

// export const appointmentDurations: IChartConfigTest = {
//   seed: 'appointmentDurationsSeed',
//   config: DailyDashboardChartConfigs.appointmentDurations,
//   results: DailyDashboardChartConfigs.appointmentDurationsResults,
// };

// export const appointmentGaps: IChartConfigTest = {
//   seed: 'appointmentGapsSeed',
//   config: DailyDashboardChartConfigs.appointmentGaps,
//   results: DailyDashboardChartConfigs.appointmentGapsResults,
// };
