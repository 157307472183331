import { TypesenseSearchService } from '@principle-theorem/ng-shared';
import {
  ITypesenseTask,
  Organisation,
  PrincipleTypesenseCollection,
} from '@principle-theorem/principle-core';
import {
  IBrand,
  IOrganisation,
  IPractice,
  IStaffer,
  ITask,
  ITeam,
  TaskStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  ITimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
import { ITypesenseConfig, Typesense } from '@principle-theorem/typesense';
import { compact } from 'lodash';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  type SearchParams,
  type SearchResponse,
} from 'typesense/lib/Typesense/Documents';
import * as moment from 'moment-timezone';

export interface ITaskBlockFilters {
  statuses: TaskStatus[];
  showDeleted: boolean;
  practiceRef?: DocumentReference<IPractice>;
  dateRange?: ITimePeriod;
  mentionRefs?: DocumentReference[];
  taskRefs?: DocumentReference<ITask>[];
  ownerRef?: DocumentReference<IStaffer>;
  assigneeRefs?: DocumentReference<IStaffer | ITeam>[];
}

export class TypesenseTaskListBloc {
  results$: Observable<SearchResponse<ITypesenseTask>>;

  constructor(
    private _config: ITypesenseConfig,
    private _typesense: TypesenseSearchService,
    organisation$: Observable<WithRef<IOrganisation>>,
    brand$: Observable<WithRef<IBrand>>,
    search$: Observable<string>,
    filters$: Observable<ITaskBlockFilters>,
    page$: Observable<number> = of(1),
    numberOfResults$: Observable<number> = of(50),
    sortBy$: Observable<string | string[]>
  ) {
    const pagination$ = combineLatest([page$, numberOfResults$]).pipe(
      map(([page, numberOfResults]) => ({ page, numberOfResults }))
    );

    const searchParams$: Observable<SearchParams> = combineLatest([
      search$,
      filters$.pipe(
        map((filters) =>
          compact([
            statusesFilter(filters.statuses),
            taskRefsFilter(filters.taskRefs),
            mentionRefsFilter(filters.mentionRefs),
            assigneeRefsFilter(filters.assigneeRefs),
            deletedFilter(filters.showDeleted),
            ownerRefFilter(filters.ownerRef),
            dateRangeFilter(
              filters.showDeleted,
              filters.statuses,
              filters.dateRange
            ),
            practiceRefFilter(filters.practiceRef),
            visibleFromFilter(),
          ])
        )
      ),
      pagination$,
      sortBy$,
    ]).pipe(
      map(([search, filters, pagination, sortBy]) => ({
        q: search,
        query_by: ['title', 'assignedName', 'ownerName'].join(','),
        per_page: pagination.numberOfResults,
        page: pagination.page,
        filter_by: filters.join(' && '),
        sort_by: sortBy,
      }))
    );

    this.results$ = combineLatest([organisation$, brand$]).pipe(
      switchMap(([organisation, brand]) =>
        this._typesense.query$<ITypesenseTask>(
          Organisation.integrationCol(organisation),
          Typesense.getScopedCollectionName(
            [organisation.ref, brand.ref],
            PrincipleTypesenseCollection.Tasks
          ),
          searchParams$,
          this._config,
          {
            cacheSearchResultsForSeconds: 0,
          }
        )
      )
    );
  }
}

function taskRefsFilter(
  taskRefs: DocumentReference<ITask>[] = []
): string | undefined {
  return taskRefs.length
    ? `ref:[${taskRefs.map((taskRef) => taskRef.path).join(',')}]`
    : undefined;
}

function mentionRefsFilter(
  mentionRefs: DocumentReference[] = []
): string | undefined {
  return mentionRefs.length
    ? `mentionRefs:[${mentionRefs
        .map((mentionRef) => mentionRef.path)
        .join(',')}]`
    : undefined;
}

function assigneeRefsFilter(
  assigneeRefs: DocumentReference[] = []
): string | undefined {
  return assigneeRefs.length
    ? `assignedRef:[${assigneeRefs
        .map((assigneeRef) => assigneeRef.path)
        .join(',')}]`
    : undefined;
}

function practiceRefFilter(
  practiceRef?: DocumentReference<IPractice>
): string | undefined {
  return practiceRef ? `practiceRef:=${practiceRef.path}` : undefined;
}

function ownerRefFilter(
  ownerRef?: DocumentReference<IStaffer>
): string | undefined {
  return ownerRef ? `ownerRef:=${ownerRef.path}` : undefined;
}

function statusesFilter(statuses: TaskStatus[]): string | undefined {
  return statuses.length ? `status:[${statuses.join(',')}]` : undefined;
}

function deletedFilter(showDeleted: boolean): string | undefined {
  return showDeleted ? `deleted:=true` : `deleted:=false`;
}

function visibleFromFilter(): string {
  return `visibleFrom:<=${moment().unix()}`;
}

function dateRangeFilter(
  showDeleted: boolean,
  statuses: TaskStatus[],
  dateRange?: ITimePeriod
): string | undefined {
  if (!dateRange || showDeleted) {
    return;
  }

  if (statuses.includes(TaskStatus.Open)) {
    return `dueDate:>=${dateRange.from.unix()} && dueDate:<=${dateRange.to.unix()}`;
  }

  if (statuses.includes(TaskStatus.Complete)) {
    return `completedDate:>=${dateRange.from.unix()} && completedDate:<=${dateRange.to.unix()}`;
  }
}
