import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type ITag } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { TagLimitBloc } from './tag-limit-bloc';

@Component({
    selector: 'pr-tag-limit-list',
    templateUrl: './tag-limit-list.component.html',
    styleUrls: ['./tag-limit-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TagLimitListComponent {
  tags$ = new ReplaySubject<WithRef<ITag>[]>(1);
  limit$ = new BehaviorSubject<number>(45);
  bloc: TagLimitBloc;

  @Input()
  set tags(tags: WithRef<ITag>[]) {
    if (tags) {
      this.tags$.next(tags);
    }
  }

  @Input()
  set limit(limit: number) {
    if (limit) {
      this.limit$.next(limit);
    }
  }

  constructor() {
    this.bloc = new TagLimitBloc(this.tags$, this.limit$);
  }
}
