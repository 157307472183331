import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  type RouterStateSnapshot,
  type UrlTree,
} from '@angular/router';
import { type IFeature } from '@principle-theorem/feature-flags';
import { findInheritedRouteData } from '@principle-theorem/ng-shared';
import { type Observable, of } from 'rxjs';
import { FeatureFlagsService } from './feature-flags.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureEnabledGuard {
  constructor(private _featureFlags: FeatureFlagsService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    _: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const feature: IFeature | undefined = findInheritedRouteData(
      next,
      'feature'
    );
    const isEnabled: boolean =
      !feature || this._featureFlags.isFeatureEnabled(feature);
    return of(isEnabled);
  }
}
