import {
  CustomMappingType,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import {
  multiFilter,
  multiMap,
  type IReffable,
} from '@principle-theorem/shared';
import { sortBy, uniqBy } from 'lodash';
import { scan } from 'rxjs/operators';
import { CustomMapping } from '../../../custom-mapping';
import {
  BaseReferralSourceMappingHandler,
  IBaseMigrationReferralSource,
} from '../../../mappings/base-referral-source';
import {
  PatientReferralSourceEntity,
  type ID4WPatientReferral,
} from '../../source/entities/patient-referral-sources';

export const REFERRAL_SOURCE_CUSTOM_RESOURCE_TYPE = 'referralSource';

export const REFERRAL_SOURCES_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Referral Sources',
    description: `This allows us to map D4W's referral sources to Principle's.`,
    type: REFERRAL_SOURCE_CUSTOM_RESOURCE_TYPE,
  },
  type: CustomMappingType.DocumentReference,
});

export class D4WReferralSourceMappingHandler extends BaseReferralSourceMappingHandler<
  ID4WPatientReferral,
  PatientReferralSourceEntity
> {
  override customMapping = REFERRAL_SOURCES_MAPPING;
  sourceEntity = new PatientReferralSourceEntity();

  translateFn = (record: ID4WPatientReferral): IBaseMigrationReferralSource => {
    return {
      id: record.id.toString(),
      name: `${record.category_name ?? ''} - ${record.third_party_name ?? ''}`,
    };
  };

  override async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 1000)
      .pipe(
        multiFilter((record) => !!record.data.data.third_party_referrer_id),
        multiMap((record) => record.data.data),
        scan(
          (sources, newSources) => [...sources, ...newSources],
          [] as ID4WPatientReferral[]
        )
      )
      .toPromise();
    const options = uniqBy(
      records.map((source) => {
        const translated = this.translateFn(source);
        return {
          label: translated.name,
          value: translated.id,
        };
      }),
      (option) => option.value
    );

    return sortBy(options, 'label');
  }
}
