<mat-form-field fxFlex floatLabel="always" [appearance]="appearance">
  <mat-label>Patient Fee Schedule</mat-label>
  <mat-select
    [formControl]="control"
    [compareWith]="compareFn"
    [placeholder]="unselectedLabel$ | async"
  >
    <mat-option [value]="undefined">{{ unselectedLabel$ | async }}</mat-option>

    @for (group of feeScheduleGroups$ | async; track group.name) {
      <div class="p-2 text-xs font-bold opacity-75">
        {{ group.name | titlecase }}
        <mat-icon
          class="icon-3 opacity-50"
          matTooltip="Fee Schedules from {{ group.scope | titlecase }}"
          >info</mat-icon
        >
      </div>

      @for (feeSchedule of group.schedules; track feeSchedule.ref.path) {
        <mat-option [value]="feeSchedule">
          {{ feeSchedule.name }}
        </mat-option>
      }
    }
  </mat-select>
</mat-form-field>
