import { Component, type OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, type AuthUser } from '@principle-theorem/ng-auth';
import { queryParam$, TypedFormControl } from '@principle-theorem/ng-shared';
import { filterUndefined, snapshot } from '@principle-theorem/shared';
import {
  combineLatest,
  concat,
  defer,
  from,
  noop,
  type Observable,
  Subject,
} from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { type IPasswordSet } from '../../../components/auth/password-form/password-forms';
import { updatePassword } from 'firebase/auth';

enum ActionMode {
  ResetPassword = 'resetPassword',
  RecoverEmail = 'recoverEmail',
  VerifyEmail = 'verifyEmail',
  SignInEmailLink = 'signIn',
}

@Component({
  selector: 'pr-account-actions',
  templateUrl: './account-actions.component.html',
  styleUrls: ['./account-actions.component.scss'],
})
export class AccountActionsComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  isSubmitting = false;
  mode$: Observable<string>;
  actionCode$: Observable<string>;
  userEmail$: Observable<string | undefined>;
  organisationSlug$: Observable<string | undefined>;
  isNewUser = false;

  emailControl: TypedFormControl<string> = new TypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);

  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    route: ActivatedRoute
  ) {
    this.mode$ = queryParam$(route, 'mode');
    this.actionCode$ = queryParam$(route, 'oobCode');

    this.mode$
      .pipe(
        switchMap(async (mode) => {
          if (!mode) {
            await this._router.navigate(['/login']);
          }

          switch (mode) {
            case ActionMode.ResetPassword:
              this.verifyPasswordReset();
              break;
            case ActionMode.VerifyEmail:
              await this.verifyEmail();
              break;
            case ActionMode.SignInEmailLink:
              await this.verifySignInLink();
              break;
            default:
            // Do nothing
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  verifyPasswordReset(): void {
    this.userEmail$ = this.actionCode$.pipe(
      switchMap((actionCode: string) => {
        return this._auth.verifyPasswordCode(actionCode);
      }),
      catchError((_: Error) => {
        this._snackBar.open(
          'An error occurred with password reset link. Please Try Again.'
        );
        return from(this._router.navigate(['/login'])).pipe(
          map(() => undefined)
        );
      })
    );
  }

  async resetPassword(formValue: IPasswordSet): Promise<void> {
    const newPassword: string = formValue.password;
    if (!newPassword) {
      return;
    }

    await combineLatest([
      this.actionCode$,
      this.userEmail$.pipe(filterUndefined()),
    ])
      .pipe(
        tap(() => (this.isSubmitting = true)),
        switchMap(([code, email]: [string, string]) => {
          return concat(
            defer(() => this._auth.confirmPasswordReset(code, newPassword)),
            defer(() => this._auth.signIn(email, newPassword))
          ).pipe(
            catchError(() => {
              this._snackBar.open(
                'An error occurred in password reset. Please Try again'
              );
              return this._router.navigate(['/login']);
            })
          );
        }),
        map(() => this._router.navigate(['/'])),
        take(1)
      )
      .toPromise();
  }

  async verifyEmail(): Promise<void> {
    try {
      const code = await snapshot(this.actionCode$);
      await this._auth.applyActionCode(code);
      await this._auth.updateClaims();
      this._snackBar.open('Email verified successfully');
      await this._router.navigate(['/']);
    } catch (error) {
      this._snackBar.open('Could not verify Email. Please Try again');
      await this._router.navigate(['/login']);
    }
  }

  async verifySignInLink(): Promise<void> {
    const url: string = this._router.url;
    const isSignInLink = this._auth.isSignInLink(url);
    if (!isSignInLink) {
      this._snackBar.open('Something went wrong. Please Try again');
      await this._router.navigate(['/login']);
      return;
    }

    const email: string = localStorage.getItem('emailForSignIn') || '';
    if (!email) {
      return;
    }
    await this.signInWithEmailLink(email);
  }

  async signInWithEmailLink(email: string): Promise<void> {
    this.isSubmitting = true;
    const url: string = this._router.url;

    try {
      const credential = await this._auth.signInWithEmailLink(email, url);
      await this._auth.updateClaims();

      localStorage.removeItem('emailForSignIn');
      if (credential) {
        this.isNewUser = credential.isNewUser;
      }

      if (!this.isNewUser) {
        await this._router.navigate(['/']);
      }

      this.isSubmitting = false;
    } catch (err) {
      this._snackBar.open('Something went wrong. Please Try again');
      await this._router.navigate(['/login']);
    }
  }

  async setPassword(formValue: IPasswordSet): Promise<void> {
    this.isSubmitting = true;

    await this._auth.authUser$
      .pipe(
        filterUndefined(),
        switchMap((user: AuthUser) => updatePassword(user, formValue.password)),
        tap(() => from(this._router.navigate(['/']))),
        take(1)
      )
      .toPromise();
  }
}
