<pt-breadcrumbs
  [breadcrumbs]="breadcrumbs"
  current="Practice Reports"
 />

<div class="layout-padding">
  <div fxLayout="row" fxLayoutAlign="start center" class="layout-margin">
    <h2 fxFlex>Select Date Range</h2>
    <pt-date-range-form [from]="from" [to]="to" (changed)="updateDates($event)" />
  </div>

  <div class="chart-group">
    <pr-practice-income [dataBuilder]="dataBuilder" />
  </div>

  <div class="chart-group">
    <pr-practice-patients [dataBuilder]="dataBuilder" />
  </div>

  <div class="chart-group">
    <pr-practice-productivity
      [dataBuilder]="dataBuilder"
     />
  </div>

  <div class="chart-group">
    <pr-practice-operations
      [dataBuilder]="dataBuilder"
     />
  </div>

  <div class="layout-margin">
    <pr-basic-chart [chart]="collectionsChart" />
  </div>

  <div class="layout-margin">
    <pr-table-chart
      fxFlex
      [chart]="collectionsTable"
      [replacementHeaders]="replacementHeaders"
     />
  </div>
</div>
