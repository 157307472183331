import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CreateBrandComponent } from '@principle-theorem/ng-principle';
import {
  type ICreateConfigurationData,
  ManagementService,
  OrganisationService,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { DialogPresets, TrackByFunctions } from '@principle-theorem/ng-shared';
import { Brand, User } from '@principle-theorem/principle-core';
import { BrandPermissions } from '@principle-theorem/principle-core/features';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  filterUndefined,
  getDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, of, type Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-practice-selector',
    templateUrl: './practice-selector.component.html',
    styleUrls: ['./practice-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PracticeSelectorComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByBrand = TrackByFunctions.ref<WithRef<IBrand>>();
  brands$: Observable<WithRef<IBrand>[]>;
  isManagementUser$: Observable<boolean>;
  fixedInViewport = true;
  canCreateBrand$: Observable<boolean>;

  constructor(
    private _organisation: OrganisationService,
    private _dialog: MatDialog,
    private _router: Router,
    private _stateNav: StateBasedNavigationService,
    management: ManagementService
  ) {
    this.isManagementUser$ = management.user$.pipe(map((user) => !!user));
    this.brands$ = this._organisation.userBrands$;

    this.canCreateBrand$ = combineLatest([
      this.isManagementUser$,
      this._organisation.user$.pipe(
        switchMap((user) =>
          user
            ? User.hasPermission$(user, BrandPermissions.BrandManage)
            : of(false)
        )
      ),
    ]).pipe(
      map(
        ([isManagement, userHasPermission]) => isManagement || userHasPermission
      )
    );

    combineLatest([
      this.brands$.pipe(
        filter((brands) => brands.length === 1),
        map((brands) => brands[0]),
        switchMap((brand) =>
          Brand.practices$(brand).pipe(
            filter((practices) => practices.length === 1),
            map((practices) => practices[0]),
            map((practice) => ({
              brand,
              practice,
            }))
          )
        )
      ),
      this.isManagementUser$.pipe(
        filter((isManagementUser) => !isManagementUser)
      ),
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(
        ([{ brand, practice }, _]) =>
          void this._selectWorkspace(brand, practice)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async addBrand(): Promise<void> {
    const collection = await snapshot(
      this._organisation.brandCol$.pipe(filterUndefined())
    );
    const data: ICreateConfigurationData<IBrand> = {
      collection,
    };

    const docRef = await this._dialog
      .open<
        CreateBrandComponent,
        ICreateConfigurationData<IBrand>,
        DocumentReference<IBrand>
      >(CreateBrandComponent, DialogPresets.small({ data }))
      .afterClosed()
      .toPromise();

    if (!docRef) {
      return;
    }

    const brand = await getDoc(docRef);
    this._organisation.selectBrand(brand);
    await this._router.navigate(['/', 'settings']);
  }

  private async _selectWorkspace(
    brand: WithRef<IBrand>,
    practice: WithRef<IPractice>
  ): Promise<void> {
    this._organisation.selectBrand(brand);
    this._organisation.selectPractice(practice);
    await this._stateNav.practice(['schedule', 'timeline'], {}, practice);
  }
}
