import {
  IMeasureMetadata,
  type MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import { type DataPointValue } from '@principle-theorem/reporting';

// TODO: Remove use of this as scope is determinant on RBAC and user-defined grouping
export enum MeasureType {
  Organisation = 'Organisation',
  Practice = 'Practice',
  Brand = 'Brand',
  Patient = 'Patient',
  Practitioner = 'Practitioner',
  Referrer = 'Referrer',
  Time = 'Date',
}

export interface IChartContents {
  dataHeaders: string[];
  dataPoints: IChartDataPoint[];
}

export interface IChartDataPoint {
  label: string | Date; // label is generally the date (won't be the case for bar graphs)
  values: IDataPoint[];
}

export interface IDataPoint {
  label: string;
  value: DataPointValue;
  formatter?: MeasureFormatter;
  formatterValue?: IMeasureMetadata['formatterValue'];
}
