<ng-container *ngIf="treatment$ | async as treatment">
  <pr-charted-item-card-v2
    #card="prChartedItemCardV2"
    [expanded]="expanded"
    [item]="treatment"
    [selectedSurfaces]="selectedSurfaces$ | async"
    [disabled]="disabled"
    [compact]="compact"
    [groupedTreatment]="treatment | map: isTreatmentGroup"
    [plan]="plan$ | async"
    (itemChange)="treatmentChange($event)"
    (itemDelete)="deleteTreatment($event)"
    (updateChartable)="updateChartable.emit($event)"
  >
    <ng-container
      ngProjectAs=".header-info"
      *ngIf="serviceCodeSummary$ | async as serviceCodeSummary"
    >
      <small class="mat-caption">{{ serviceCodeSummary }}</small>
    </ng-container>

    <ng-container ngProjectAs=".menu-buttons">
      <button
        mat-menu-item
        [disabled]="disabled"
        (click)="addBasePrice(treatment)"
      >
        <mat-icon>price_check</mat-icon>
        Set Base Treatment Price
      </button>
    </ng-container>

    <ng-container ngProjectAs=".drag-handle">
      <ng-content select=".drag-handle"></ng-content>
    </ng-container>

    <pr-treatment-category-indicator
      *ngIf="treatmentCategory$ | async as category"
      [category]="category"
      (categoryChanged)="categoryChanged.emit($event)"
    />

    <ng-container ngProjectAs=".attributed-to">
      <pr-assignee-profile-selector
        [disabled]="disabled"
        selectTooltip="Override Treatment Provider"
        [clearTooltip]="clearTooltip$ | async"
        [staff]="organisation.brandPractitioners$ | async"
        [selectedStaffer]="treatment.attributedTo?.ref"
        (stafferSelected)="setAttributedTo($event)"
      />
    </ng-container>

    <ng-container ngProjectAs=".change-fee-schedule">
      <pr-staff-fee-schedule-menu
        [disabled]="disabled"
        [feeSchedule]="feeSchedule$ | async"
        [plan]="plan$ | async"
        [patient]="patient$ | async"
        (scheduleSelected)="setFeeSchedule($event)"
      />
    </ng-container>

    <ng-container
      ngProjectAs=".change-treatment-package"
      *ngrxLet="treatmentPackages$ as packages"
    >
      <pr-treatment-package-menu
        [packages]="packages"
        [currentPackageId]="treatment.treatmentPackageId"
        (packageSelected)="setTreatmentPackage($event, packages, treatment)"
      />
    </ng-container>

    <ng-container ngProjectAs=".checkbox">
      <pr-charted-item-toolbar
        [disabled]="disabled"
        [item]="treatment"
        [step]="step$ | async"
        [plan]="plan$ | async"
      />
    </ng-container>

    <ng-container *ngIf="card.collapsible?.expanded">
      <ng-container
        *ngIf="treatment | map: isTreatmentGroup; else singleTreatment"
      >
        <div class="grouped-treatment flex flex-col gap-1">
          <div
            *ngFor="
              let subTreatment of treatment.treatments;
              trackBy: trackByTreatments
            "
          >
            <div
              class="grouped-treatment-header flex gap-2"
              [ngClass]="{
                active: subTreatment | map: isSelected$ : this | async
              }"
            >
              <pr-charted-items-surface-list
                [chartedSurfaces]="subTreatment.chartedSurfaces"
                [availableSurfaces]="availableSurfaces$ | async"
              />
              <div *ngIf="!disabled" class="menu flex items-center gap-2">
                <mat-icon cdkDragHandle class="drag-handle">
                  drag_handle
                </mat-icon>
                <mat-checkbox
                  *ngIf="!disabled && !!selectionList"
                  #checkbox="matCheckbox"
                  class="checkbox"
                  color="primary"
                  [disabled]="
                    subTreatment | map: isCheckboxDisabled$ : this | async
                  "
                  [checked]="subTreatment | map: isSelected$ : this | async"
                  (change)="setSelected(subTreatment, checkbox.checked)"
                />
                <button mat-icon-button matTooltip="Remove from group">
                  <mat-icon>remove_circle_outline</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Add item">
                  <mat-icon>add_circle_outline</mat-icon>
                </button>
              </div>
            </div>
            <ng-container
              [ngTemplateOutlet]="treatmentTemplate"
              [ngTemplateOutletContext]="{
                treatment: subTreatment,
                searchDisabled: true
              }"
            />
          </div>
        </div>
      </ng-container>

      <ng-template #singleTreatment>
        <ng-container
          [ngTemplateOutlet]="treatmentTemplate"
          [ngTemplateOutletContext]="{ treatment, searchDisabled: false }"
        />
      </ng-template>

      <ng-template
        #treatmentTemplate
        let-treatment="treatment"
        let-searchDisabled="searchDisabled"
      >
        <div class="body-content">
          <pr-edit-base-price
            *ngIf="treatment.basePrice > 0"
            name="Treatment Base Price"
            [price]="treatment.basePrice"
            [disabled]="disabled"
            [feeSchedule]="feeSchedule$ | async"
            [config]="config$ | async"
            (priceChange)="updateBasePrice($event)"
            (basePriceDeleted)="deleteBasePrice(treatment)"
          />

          <pr-service-item-smart-group-list
            [chartedSurfaces]="treatment.chartedSurfaces"
            [groups]="treatment.serviceCodeSmartGroups"
            [disabled]="disabled"
            [compact]="compact"
            (groupsChange)="updateSmartGroups(treatment, $event)"
          />

          <pr-service-item-exclusive-group-list
            [scope]="treatment.scopeRef"
            [chartedSurfaces]="treatment.chartedSurfaces"
            [groups]="treatment.serviceCodeGroups"
            [disabled]="disabled"
            (groupsChange)="updateExclusiveGroups($event, treatment)"
          />

          <pr-service-item-list
            [stepTreatments]="step$ | map: getStepTreatments$ | async"
            [chartedItem]="treatment"
            [serviceCodes]="treatment.serviceCodes"
            [disabled]="disabled"
            [feeSchedule]="treatment.feeSchedule"
            [compact]="compact"
            [searchDisabled]="searchDisabled"
            [treatmentConfig]="config$ | async"
            (itemsChange)="updateServiceCodes(treatment, $event)"
          />

          <pr-charted-item-notes-list
            [item]="treatment"
            (itemChange)="treatmentChange($event)"
          />
        </div>
      </ng-template>
    </ng-container>
  </pr-charted-item-card-v2>
</ng-container>
