<mat-progress-bar mode="indeterminate" *ngIf="(loaded$ | async) === false" />

<div
  *ngIf="displayType$ | async as displayType"
  fxFill
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <div class="file-preview-container" *ngrxLet="error$ as error">
    <div *ngIf="error" class="error-message">{{ error }}</div>

    <img *ngIf="displayType === 'unsupported'" [src]="fallbackImage" />

    <pintura-editor
      *ngIf="displayType === 'image'"
      [src]="fileUrl$ | async"
      [options]="pinturaOptions$ | async"
      (update)="onUpdate()"
    />

    <video *ngIf="displayType === 'video'" controls>
      <source
        *ngIf="safeUrl$ | async as url"
        [src]="url"
        [type]="contentType$ | async"
      />
      Sorry, your browser doesn't support embedded videos.
    </video>

    <audio *ngIf="displayType === 'audio'" controls>
      <source
        *ngIf="safeUrl$ | async as url"
        [src]="url"
        [type]="contentType$ | async"
      />
      Your browser does not support the audio element.
    </audio>

    <ng-container *ngIf="displayType === 'iframe'">
      <iframe
        *ngIf="safeUrl$ | async as url"
        fxFill
        [src]="url"
        (load)="loaded$.next(true)"
        (error)="handleError()"
      ></iframe>
    </ng-container>

    <ng-container *ngIf="displayType === 'docViewer'">
      <ngx-doc-viewer
        *ngIf="fileUrl$ | async as url"
        fxFill
        [url]="url"
        viewer="google"
        [googleCheckContentLoaded]="true"
        [googleCheckInterval]="3000"
        [googleMaxChecks]="5"
        (loaded)="loaded$.next(true)"
      />
    </ng-container>
  </div>
</div>
