import { MixedSchema } from '@principle-theorem/editor';
import { ITemplateDefinition } from '../templating/template-definition';
import { SystemTemplate } from '../templating/templating';

export interface ITemplateDefinitionWithName {
  templateDoc: ITemplateDefinition;
  template: SystemTemplate;
}

export interface IResolvedTemplateWithName {
  content: MixedSchema;
  template: SystemTemplate;
}
