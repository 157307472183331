import { Validators } from '@angular/forms';
import { CustomValidators } from '@principle-theorem/ng-principle-shared';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { type IContactDetails } from '@principle-theorem/principle-core/interfaces';

export class ContactFormGroup extends TypedFormGroup<IContactDetails> {
  constructor() {
    super(
      {
        name: new TypedFormControl('', Validators.required),
        email: new TypedFormControl('', Validators.email),
        address: new TypedFormControl(''),
        phone: new TypedFormControl('', [CustomValidators.phoneNumber]),
        jobTitle: new TypedFormControl(''),
        mobileNumber: new TypedFormControl('', [CustomValidators.phoneNumber]),
      },
      CustomValidators.requireOneOf(['email', 'address', 'phone'])
    );
  }
}
