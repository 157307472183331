import { NgModule } from '@angular/core';
import { TwainTriggerScanComponent } from './twain-trigger-scan/twain-trigger-scan.component';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { TwainFeatureService } from './twain-feature.service';
import { TwainDeviceSelectDialogComponent } from './twain-trigger-scan/twain-device-select-dialog/twain-device-select-dialog.component';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { TwainDeviceSettingsComponent } from './twain-trigger-scan/twain-device-settings/twain-device-settings.component';

@NgModule({
  declarations: [
    TwainTriggerScanComponent,
    TwainDeviceSelectDialogComponent,
    TwainDeviceSettingsComponent,
  ],
  imports: [NgSharedModule, NgPrincipleSharedModule],
  providers: [TwainFeatureService],
})
export class TwainModule {}
