import { Injectable } from '@angular/core';
import { shareReplayCold } from '@principle-theorem/shared';
import { fromEvent, merge, type Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  repeat,
  share,
  startWith,
  switchMap,
  takeUntil,
  throttleTime,
} from 'rxjs/operators';

export interface IMoveDelta {
  dx: number;
  dy: number;
}

const DRAG_MOVE_BUFFER = 5;
const LEFT_CLICK = 1;

@Injectable()
export class MouseInteractionsService {
  click$: Observable<MouseEvent>;
  up$: Observable<MouseEvent>;
  down$: Observable<MouseEvent>;
  move$: Observable<MouseEvent>;
  isDragMode$: Observable<boolean>;

  constructor() {
    this.click$ = fromEvent<MouseEvent>(document, 'click').pipe(share());
    this.up$ = fromEvent<MouseEvent>(document, 'mouseup').pipe(
      filter((event: MouseEvent) => event.which === LEFT_CLICK),
      share()
    );
    this.down$ = fromEvent<MouseEvent>(document, 'mousedown').pipe(
      filter((event: MouseEvent) => event.which === LEFT_CLICK),
      share()
    );
    this.move$ = fromEvent<MouseEvent>(document, 'mousemove').pipe(
      throttleTime(100)
    );

    this.isDragMode$ = this.down$.pipe(
      switchMap((downEvent: MouseEvent) => {
        let prevX: number = downEvent.clientX;
        let prevY: number = downEvent.clientY;
        const delta: IMoveDelta = {
          dx: 0,
          dy: 0,
        };

        return merge(
          this.move$.pipe(
            map((moveEvent: MouseEvent) => {
              delta.dx += Math.abs(moveEvent.clientX - prevX);
              delta.dy += Math.abs(moveEvent.clientY - prevY);
              prevX = moveEvent.clientX;
              prevY = moveEvent.clientY;
              return (
                delta.dx >= DRAG_MOVE_BUFFER || delta.dy >= DRAG_MOVE_BUFFER
              );
            })
          ),
          this.up$.pipe(map(() => false))
        ).pipe(startWith(false));
      }),
      distinctUntilChanged(),
      shareReplayCold(),
      // eslint-disable-next-line rxjs/no-unsafe-takeuntil
      takeUntil(this.up$),
      repeat()
    );
  }
}
