import { Injectable, type OnDestroy } from '@angular/core';
import {
  type IPatient,
  type IRecentlyViewed,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isSameRef,
  patchDoc,
  shareReplayHot,
  sortTimestamp,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { noop, uniqWith } from 'lodash';
import { from, merge, type Observable, Subject } from 'rxjs';
import {
  auditTime,
  concatMap,
  map,
  mergeMap,
  scan,
  startWith,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { OrganisationService } from './organisation.service';

@Injectable({
  providedIn: 'root',
})
export class LastViewedService implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _recentlyViewedAdded$ = new Subject<IRecentlyViewed>();
  recentlyViewed$: Observable<IRecentlyViewed[]>;

  constructor(private _organisation: OrganisationService) {
    const savedRecentlyViewed$ = this._organisation.staffer$.pipe(
      take(1),
      filterUndefined(),
      map((staffer) => staffer.recentlyViewed ?? []),
      mergeMap((recentlyViewed) => from(recentlyViewed), 1)
    );

    this.recentlyViewed$ = merge(
      savedRecentlyViewed$,
      this._recentlyViewedAdded$
    ).pipe(
      scan(
        (allViewed, newViewed) =>
          this._mergeRecentlyViewed(newViewed, allViewed),
        [] as IRecentlyViewed[]
      ),
      startWith([]),
      shareReplayHot(this._onDestroy$)
    );

    this.recentlyViewed$
      .pipe(
        auditTime(1000),
        withLatestFrom(this._organisation.staffer$.pipe(filterUndefined())),
        concatMap(([recentlyViewed, staffer]) =>
          patchDoc(staffer.ref, {
            recentlyViewed,
          })
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  trackPatientVisit(
    patient: Pick<WithRef<IPatient>, 'ref' | 'name' | 'deleted'>
  ): void {
    if (patient.deleted) {
      return;
    }
    this._recentlyViewedAdded$.next({
      title: patient.name,
      ref: patient.ref,
      type: MentionResourceType.Patient,
      viewedAt: toTimestamp(),
    });
  }

  private _mergeRecentlyViewed(
    newViewed: IRecentlyViewed,
    allViewed: IRecentlyViewed[]
  ): IRecentlyViewed<object>[] {
    return uniqWith([newViewed, ...allViewed], isSameRef)
      .sort((pageA, pageB) => sortTimestamp(pageA.viewedAt, pageB.viewedAt))
      .slice(0, 20);
  }
}
