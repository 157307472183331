import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  ViewChildren,
  type AfterViewInit,
  type OnDestroy,
  type QueryList,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  TrackByFunctions,
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
  isDisabled$,
} from '@principle-theorem/ng-shared';
import { Practice } from '@principle-theorem/principle-core';
import {
  type IPatient,
  type IPractice,
  type IStaffer,
  type ISterilisationRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  multiFilter,
  snapshot,
  toTimestamp,
  type WithRef,
  isSameRef,
  debounceUserInput,
} from '@principle-theorem/shared';
import { compact, isEqual, isString, last } from 'lodash';
import * as moment from 'moment-timezone';
import { Subject, of, Observable, BehaviorSubject, noop } from 'rxjs';
import {
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';

export interface ISterilisationRecordDialogRequest {
  patient?: WithRef<IPatient>;
  practice: WithRef<IPractice>;
  staffer: WithRef<IStaffer>;
}

export interface ISterilisationRecordDialogResponse {
  data: (string | WithRef<ISterilisationRecord>)[];
  patient: WithRef<IPatient>;
  staffer: WithRef<IStaffer>;
}

export interface ISterilisationRecordFormData {
  recordId: string[];
  patient: WithRef<IPatient>;
  staffer: WithRef<IStaffer>;
}

@Component({
    selector: 'pr-sterilisation-record-dialog',
    templateUrl: './sterilisation-record-dialog.component.html',
    styleUrls: ['./sterilisation-record-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SterilisationRecordDialogComponent
  implements OnDestroy, AfterViewInit
{
  private _onDestroy$ = new Subject<void>();
  private _claimedRecords$ = new BehaviorSubject<
    WithRef<ISterilisationRecord>[]
  >([]);
  searchCtrl: TypedFormControl<string | IPatient> = new TypedFormControl();
  trackByIndex = TrackByFunctions.index<TypedFormControl<string>>();
  form = new TypedFormGroup<ISterilisationRecordFormData>({
    recordId: new TypedFormArray([
      new TypedFormControl('', Validators.required),
    ]),
    patient: new TypedFormControl<WithRef<IPatient>>(
      undefined,
      Validators.required
    ),
    staffer: new TypedFormControl<WithRef<IStaffer>>(undefined),
  });
  disabled$: Observable<boolean>;
  barcodeModeEnabled$ = new BehaviorSubject<boolean>(true);
  availableRecords$: Observable<WithRef<ISterilisationRecord>[]>;

  @ViewChildren('dataInput') dataInputs: QueryList<
    ElementRef<HTMLInputElement>
  >;

  constructor(
    private _dialogRef: MatDialogRef<
      SterilisationRecordDialogComponent,
      ISterilisationRecordDialogResponse
    >,
    @Inject(MAT_DIALOG_DATA) public data: ISterilisationRecordDialogRequest
  ) {
    this._initialiseForm();
    this.disabled$ = isDisabled$(this.form);
    this.availableRecords$ = this._getAvailableRecords$();

    this.barcodeModeEnabled$.next(
      !!this.data.practice.settings.sterilisation?.barcodeModeEnabled
    );
    this.barcodeModeEnabled$
      .pipe(
        distinctUntilChanged(),
        concatMap((barcodeMode) => this._patchBarcodeModeSettings(barcodeMode)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);

    this.searchCtrl.valueChanges
      .pipe(
        filter((value) => !isString(value)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((patient) => this.form.controls.patient.setValue(patient));

    const recordChanges$ = this.records.valueChanges.pipe(
      debounceUserInput(),
      startWith([]),
      pairwise()
    );

    recordChanges$
      .pipe(
        withLatestFrom(this.barcodeModeEnabled$),
        filter(
          ([[previous, current], barcodeModeEnabled]) =>
            !!barcodeModeEnabled &&
            this._hasValidRecordChange(previous, current)
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.addRecord());

    recordChanges$
      .pipe(withLatestFrom(this._claimedRecords$), takeUntil(this._onDestroy$))
      .subscribe(([[previous, current], claimedRecords]) =>
        this._unclaimRecord(previous, current, claimedRecords)
      );
  }

  get records(): TypedFormArray<string> {
    return this.form.controls.recordId as TypedFormArray<string>;
  }

  ngAfterViewInit(): void {
    this.dataInputs.changes
      .pipe(
        map((inputs: QueryList<ElementRef<HTMLInputElement>>) =>
          inputs.toArray()
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((records) =>
        setTimeout(() => last(records)?.nativeElement.focus())
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async save(): Promise<void> {
    if (!this.form.valid) {
      return;
    }
    const formData = this.form.getRawValue();

    this._dialogRef.close({
      data: await this._getUniqueRecords(formData.recordId),
      patient: formData.patient,
      staffer: formData.staffer,
    });
  }

  onEnter($event: Event): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.addRecord();
  }

  addRecord(): void {
    this.records.push(new TypedFormControl(''));
  }

  async getUnclaimedRecord(
    value?: string | null
  ): Promise<WithRef<ISterilisationRecord> | undefined> {
    if (!value) {
      return;
    }

    const searchValue = value.trim();
    const availableRecords = await snapshot(this.availableRecords$);
    const claimedRecords = await snapshot(this._claimedRecords$);

    const matchingRecords = availableRecords.filter(
      (record) => record.data === searchValue
    );

    const unclaimedRecord = matchingRecords.find(
      (record) => !claimedRecords.some((claimed) => isSameRef(record, claimed))
    );

    if (unclaimedRecord) {
      this._claimedRecords$.next([...claimedRecords, unclaimedRecord]);
      return unclaimedRecord;
    }
  }

  private _initialiseForm(): void {
    if (this.data.patient) {
      this.searchCtrl.setValue(this.data.patient);
      this.searchCtrl.disable();
      this.form.patchValue(this.data);
      this.form.controls.patient.disable();
    }

    this.form.controls.staffer.setValue(this.data.staffer);
  }

  private async _patchBarcodeModeSettings(barcodeMode: boolean): Promise<void> {
    await Firestore.patchDoc(this.data.practice.ref, {
      settings: {
        ...this.data.practice.settings,
        sterilisation: {
          ...this.data.practice.settings.sterilisation,
          barcodeModeEnabled: barcodeMode,
        },
      },
    });
  }

  private _unclaimRecord(
    previous: string[],
    current: string[],
    claimedRecords: WithRef<ISterilisationRecord>[]
  ): void {
    const changedValues = previous.filter(
      (previousValue, index) => current[index] !== previousValue
    );
    if (!changedValues.length) {
      return;
    }

    changedValues.map((value) => {
      const recordToUnclaim = claimedRecords.find(
        (record) => record.data === value
      );

      if (recordToUnclaim) {
        this._claimedRecords$.next(
          claimedRecords.filter((record) => !isSameRef(record, recordToUnclaim))
        );
      }
    });
  }

  private async _getUniqueRecords(
    data: string[]
  ): Promise<(string | WithRef<ISterilisationRecord>)[]> {
    const claimedRecords = await snapshot(this._claimedRecords$);
    const recordIds = compact(data.map((value) => value.trim()));
    const initialValue = {
      uniqueRecords: [] as (string | WithRef<ISterilisationRecord>)[],
      remainingClaims: claimedRecords,
    };

    const { uniqueRecords } = recordIds.reduce((result, recordId) => {
      const foundClaim = result.remainingClaims.find(
        (record) => record.data === recordId
      );

      if (!foundClaim) {
        return {
          ...result,
          uniqueRecords: [...result.uniqueRecords, recordId],
        };
      }

      return {
        uniqueRecords: [...result.uniqueRecords, foundClaim],
        remainingClaims: result.remainingClaims.filter(
          (record) => !isSameRef(record, foundClaim)
        ),
      };
    }, initialValue);

    return uniqueRecords;
  }

  private _getAvailableRecords$(): Observable<WithRef<ISterilisationRecord>[]> {
    if (!this.data.practice) {
      return of([]);
    }

    const dateRange = {
      from: toTimestamp(moment().startOf('day').subtract(7, 'day')),
      to: toTimestamp(moment().endOf('day')),
    };

    return Practice.sterilisationRecords$(this.data.practice, dateRange).pipe(
      multiFilter((record) => !record.patient && !record.appointment)
    );
  }

  private _hasValidRecordChange(
    previous: string[],
    current: string[]
  ): boolean {
    const decreaseInArraySize = previous.length > current.length;
    const noChangeToPrevious =
      previous.length === current.length &&
      isEqual(last(previous), last(current));
    const lastValue = last(current);
    const emptyLastIndex = lastValue ? lastValue.trim() === '' : true;

    return !(decreaseInArraySize || noChangeToPrevious || emptyLastIndex);
  }
}
