<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  *ngIf="user$ | async as user"
>
  <div fxLayout="row" fxLayoutGap="16px" class="auth-user-card">
    <pt-user-icon
      [src]="profileImage.getUserProfileImage$(user) | async"
      [diameter]="iconSize"
      [name]="user.name"
     />

    <div fxFlex class="truncate">
      <div fxLayout="column">
        <div class="mat-subtitle-2 truncate">
          {{ user.name }}
        </div>
        <div class="truncate">{{ user.email }}</div>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
