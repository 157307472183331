<ng-template #selector let-type="type">
  <mat-form-field class="flex flex-1 justify-stretch">
    <input
      matInput
      placeholder="Select a form field to add to alerts list"
      [formControl]="alertSearchCtrl"
      [matAutocomplete]="selectAutoComplete"
    />

    <mat-autocomplete
      #selectAutoComplete="matAutocomplete"
      [autoActiveFirstOption]="true"
      [displayWith]="displayFn"
      (optionSelected)="addAlert($event.option.value, type)"
    >
      @for (option of alertsSearchFilter.results$ | async; track option.path) {
        <mat-option [value]="option">{{ option.mapTitle }}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</ng-template>

<ng-template
  #alertInput
  let-formGroup="formGroup"
  let-type="type"
  let-index="index"
>
  <div [formGroup]="formGroup">
    <mat-form-field class="w-full">
      <input matInput formControlName="mapTitle" />
      <button
        matIconSuffix
        mat-icon-button
        type="button"
        (click)="$event.stopPropagation(); removeAlert(index, type)"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-label>Set the label for the alert</mat-label>
    </mat-form-field>
  </div>
</ng-template>

<form [formGroup]="form" class="flex flex-col gap-4">
  <mat-card>
    <mat-card-content>
      <div formArrayName="conditions" class="flex flex-col">
        <h2 class="mat-headline-6">Condition Alerts</h2>

        <ng-container
          [ngTemplateOutlet]="selector"
          [ngTemplateOutletContext]="{ type: alertType.Conditions }"
        />

        @for (
          condition of conditionControls;
          track condition.controls.path.value;
          let index = $index
        ) {
          <ng-container
            [ngTemplateOutlet]="alertInput"
            [ngTemplateOutletContext]="{
              formGroup: condition,
              type: alertType.Conditions,
              index
            }"
          />
        }
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content>
      <div formArrayName="allergies" class="flex flex-col">
        <h2 class="mat-headline-6">Allergy Alerts</h2>

        <ng-container
          [ngTemplateOutlet]="selector"
          [ngTemplateOutletContext]="{ type: alertType.Allergies }"
        />

        @for (
          allergy of allergyControls;
          track allergy.controls.path.value;
          let index = $index
        ) {
          <ng-container
            [ngTemplateOutlet]="alertInput"
            [ngTemplateOutletContext]="{
              formGroup: allergy,
              type: alertType.Allergies,
              index
            }"
          />
        }
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content>
      <div formArrayName="history" class="flex flex-col">
        <h2 class="mat-headline-6">Medical History Alerts</h2>

        <ng-container
          [ngTemplateOutlet]="selector"
          [ngTemplateOutletContext]="{ type: alertType.History }"
        />

        @for (
          historyItem of historyControls;
          track historyItem.controls.path.value;
          let index = $index
        ) {
          <ng-container
            [ngTemplateOutlet]="alertInput"
            [ngTemplateOutletContext]="{
              formGroup: historyItem,
              type: alertType.History,
              index
            }"
          />
        }
      </div>
    </mat-card-content>
  </mat-card>
</form>
