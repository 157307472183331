import {
  TypeGuard,
  type ISoftDelete,
  type ITimestampRange,
  isTimestampRange,
  isDocRef,
  Timestamp,
} from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { type IBrand } from './brand';
import { type IOrganisation } from './organisation/organisation';
import { type IPractice } from './practice/practice';
import { type IPracticeMigration } from './practice-migrations/practice-migration';
import { ISourceEntity } from './practice-migrations/source';
import { isString } from 'lodash';
import { IDestinationEntity } from './practice-migrations/destination-entity';

export enum ManagementJobCollection {
  ManagementJobLogs = 'managementJobLogs',
}

export enum BigQueryManagementJob {
  BigQuerySchemaSync = 'big-query.schema.sync',
  BigQueryAccountCreditsSync = 'big-query.account-credits.sync',
  BigQueryAppointmentsSync = 'big-query.appointments.sync',
  BigQueryInvoicesSync = 'big-query.invoices.sync',
  BigQueryPatientsSync = 'big-query.patients.sync',
  BigQueryPatientInteractionsSync = 'big-query.patient-interactions.sync',
  BigQueryTransactionsSync = 'big-query.transactions.sync',
  BigQueryTreatmentStepSync = 'big-query.treatment-steps.sync',
  BigQuerySchedulingEventSync = 'big-query.scheduling-events.sync',
  BigQuerySchedulingEventReasonSync = 'big-query.scheduling-event-reasons.sync',
}

export enum PracticeMigrationManagementJobType {
  DestinationMigrationRun = 'practice-migration.destination.run-migration',
  PostMigrationDeduplicateAppointmentInteractions = 'practice-migration.post-migration.deduplicate-appointment-interactions',
  PostMigrationSeedAccountAggregates = 'practice-migration.post-migration.seed-account-aggregates',
  PostMigrationSeedAutomations = 'practice-migration.post-migration.seed-automations',
  PostMigrationSeedPatientNumbers = 'practice-migration.post-migration.seed-patient-numbers',
  PostMigrationSeedTypesensePatients = 'practice-migration.post-migration.seed-typesense-patients',
  PostMigrationUpsertScheduleSummary = 'practice-migration.post-migration.upsert-schedule-summary',
  AddMigrationDatabase = 'practice-migration.add-migration-database',
  ProvisionMigrationDatabase = 'practice-migration.provision-migration-database',
  ProvisionMigrationServiceAccount = 'practice-migration.provision-migration-service-account',
  ProvisionStorageBucket = 'practice-migration.provision-storage-bucket',
  SyncDatabaseBackup = 'practice-migration.destination.sync-database-backup',
  SyncExpectedSourceRecordSizes = 'practice-migration.source.sync-expected-record-sizes',
  SyncSourceMigrationRun = 'practice-migration.source.sync-source-migration',
  SyncSourceRecordSizes = 'practice-migration.source.sync-record-sizes',
  TranslateSeedingFiles = 'practice-migration.translate-seeding-files',
  UploadBackupToDatabase = 'practice-migration.upload-backup-to-database',
}

export const SOURCE_MIGRATION_JOB_TYPES = [
  PracticeMigrationManagementJobType.TranslateSeedingFiles,
  PracticeMigrationManagementJobType.AddMigrationDatabase,
  PracticeMigrationManagementJobType.ProvisionMigrationDatabase,
  PracticeMigrationManagementJobType.ProvisionMigrationServiceAccount,
  PracticeMigrationManagementJobType.ProvisionStorageBucket,
  PracticeMigrationManagementJobType.UploadBackupToDatabase,
  PracticeMigrationManagementJobType.SyncExpectedSourceRecordSizes,
  PracticeMigrationManagementJobType.SyncSourceRecordSizes,
  PracticeMigrationManagementJobType.SyncSourceMigrationRun,
  PracticeMigrationManagementJobType.SyncDatabaseBackup,
];

export const DESTINATION_MIGRATION_JOB_TYPES = [
  PracticeMigrationManagementJobType.DestinationMigrationRun,
  PracticeMigrationManagementJobType.PostMigrationDeduplicateAppointmentInteractions,
  PracticeMigrationManagementJobType.PostMigrationSeedPatientNumbers,
  PracticeMigrationManagementJobType.PostMigrationSeedAutomations,
  PracticeMigrationManagementJobType.PostMigrationUpsertScheduleSummary,
  PracticeMigrationManagementJobType.PostMigrationSeedTypesensePatients,
  PracticeMigrationManagementJobType.PostMigrationSeedAccountAggregates,
];

export enum ManagementJobStatus {
  Pending = 'Pending',
  InProgress = 'In Progress',
  Complete = 'Complete',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
}

export interface IBigQueryJob {
  range: ITimestampRange;
  organisationRef: DocumentReference<IOrganisation>;
  brandRef: DocumentReference<IBrand>;
  practiceRef: DocumentReference<IPractice>;
  type: BigQueryManagementJob;
}

export interface IPracticeMigrationTaskQueueData {
  managementJobRef: DocumentReference<IManagementJob>;
}

export interface IPracticeMigrationManagementJob {
  migrationRef: DocumentReference<IPracticeMigration>;
  type: PracticeMigrationManagementJobType;
  cloudTaskId?: string;
  continueToNextJob?: boolean;
}

export interface IPracticeMigrationJobSourceEntityBaseState {
  key: string;
  status: 'pending' | 'inProgress' | 'complete' | 'failed';
  ref: DocumentReference<ISourceEntity>;
}

export interface IPracticeMigrationJobDestinationEntityBaseState {
  key: string;
  status: 'pending' | 'inProgress' | 'complete' | 'failed';
  ref: DocumentReference<IDestinationEntity>;
}

export type IPostMigrationJob = PracticeMigrationManagementJob & {
  type:
    | PracticeMigrationManagementJobType.PostMigrationDeduplicateAppointmentInteractions
    | PracticeMigrationManagementJobType.PostMigrationSeedPatientNumbers
    | PracticeMigrationManagementJobType.PostMigrationSeedAutomations
    | PracticeMigrationManagementJobType.PostMigrationUpsertScheduleSummary
    | PracticeMigrationManagementJobType.PostMigrationSeedTypesensePatients;
};

export type PracticeMigrationSourceHandlerJob =
  IPracticeMigrationManagementJob & {
    type: PracticeMigrationManagementJobType.SyncSourceMigrationRun;
  };

export type PracticeMigrationDatabaseAddJob = PracticeMigrationManagementJob & {
  type: PracticeMigrationManagementJobType.AddMigrationDatabase;
};

export type PracticeMigrationDatabaseProvisionJob =
  PracticeMigrationManagementJob & {
    type: PracticeMigrationManagementJobType.ProvisionMigrationDatabase;
    provisionState: 'pending' | 'inProgress' | 'complete' | 'failed';
  };

export type PracticeMigrationProvisionStorageBucketJob =
  PracticeMigrationManagementJob & {
    type: PracticeMigrationManagementJobType.ProvisionStorageBucket;
  };

export type PracticeMigrationProvisionServiceAccountJob =
  PracticeMigrationManagementJob & {
    type: PracticeMigrationManagementJobType.ProvisionMigrationServiceAccount;
  };

export type PracticeMigrationUploadBackupToDatabaseJob =
  PracticeMigrationManagementJob & {
    type: PracticeMigrationManagementJobType.UploadBackupToDatabase;
    filePath: string;
  };

export interface ISyncRecordSizeJobSourceEntityState
  extends IPracticeMigrationJobSourceEntityBaseState {
  syncCountAttempts: number;
}

export type PracticeMigrationSyncRecordSizeJob =
  PracticeMigrationManagementJob & {
    type: PracticeMigrationManagementJobType.SyncSourceRecordSizes;
    sourceEntities: ISyncRecordSizeJobSourceEntityState[];
  };

export type PracticeMigrationSyncExpectedRecordSizeJob =
  PracticeMigrationManagementJob & {
    type: PracticeMigrationManagementJobType.SyncExpectedSourceRecordSizes;
    sourceEntities: IPracticeMigrationJobSourceEntityBaseState[];
  };

export interface ISyncSourceRecordsJobSourceEntityState
  extends IPracticeMigrationJobSourceEntityBaseState {
  queryOffset: number;
}

export type PracticeMigrationSyncSourceRecordsJob =
  PracticeMigrationManagementJob & {
    type: PracticeMigrationManagementJobType.SyncSourceMigrationRun;
    sourceEntities: ISyncSourceRecordsJobSourceEntityState[];
    skipMigrated: boolean;
    skipAfter?: Timestamp;
  };

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDestinationMigrationJobState
  extends IPracticeMigrationJobDestinationEntityBaseState {}

export type PracticeMigrationDestinationMigrateJob =
  PracticeMigrationManagementJob & {
    type: PracticeMigrationManagementJobType.DestinationMigrationRun;
    destinationEntities: IDestinationMigrationJobState[];
    skipMigrated: boolean;
    retryMigrated: boolean;
    retryFailed: boolean;
  };

export type ManagemetJobType = IBigQueryJob | IPracticeMigrationManagementJob;
export type BaseManagementJob = ISoftDelete & {
  status: ManagementJobStatus;
};

export type IManagementJob = BaseManagementJob &
  (IBigQueryJob | IPracticeMigrationManagementJob);

export type IBigQueryManagementJob = BaseManagementJob & IBigQueryJob;

export type PracticeMigrationManagementJob = BaseManagementJob &
  IPracticeMigrationManagementJob;

export const isBigQueryJob = TypeGuard.interface<IBigQueryJob>({
  range: isTimestampRange,
  organisationRef: isDocRef,
  brandRef: isDocRef,
  practiceRef: isDocRef,
  type: isString,
});
