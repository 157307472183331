import { TaskFilter } from './task-filters';
import { WithRef } from '@principle-theorem/shared';
import { IStaffer, ITask } from '@principle-theorem/principle-core/interfaces';

export class MyTeamsTasksFilter extends TaskFilter {
  name = `My team's tasks`;
  icon = 'people';
  slug = 'team';

  filter(task: WithRef<ITask>, staffer: WithRef<IStaffer>): boolean {
    return (
      this._isAssignedUser(task, staffer) || this._isAssignedTeam(task, staffer)
    );
  }
}
