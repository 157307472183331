import { Injectable } from '@angular/core';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  Brand,
  findMobileNumber,
  getPatientContactDetails,
  type IInteractionError,
  Practice,
  SMSIntegration,
} from '@principle-theorem/principle-core';
import {
  CommunicationDirection,
  type IPatient,
  type IPractice,
  type IStaffer,
  type SMSConversation,
  SMSDeliveryStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';

@Injectable()
export class SendSMSService {
  constructor(private _organisation: OrganisationService) {}

  async sendMessage(
    body: string,
    patient: WithRef<IPatient>,
    authorRef: DocumentReference<IStaffer>,
    practiceRef: DocumentReference<IPractice>,
    conversationRef?: DocumentReference<SMSConversation>
  ): Promise<IInteractionError | undefined> {
    const details = await getPatientContactDetails(patient);
    const patientPhoneNumber = findMobileNumber(details?.contactNumbers);

    if (!patientPhoneNumber) {
      return { message: 'Could not resolve mobile number' };
    }

    const organisation = await snapshot(
      this._organisation.organisation$.pipe(filterUndefined())
    );
    const practicePhoneNumber = await SMSIntegration.resolvePracticeNumber(
      practiceRef,
      organisation
    );

    if (!practicePhoneNumber) {
      return { message: `Couldn't resolve practice's phone number` };
    }

    const brandRef = Practice.brandDoc({ ref: practiceRef });
    await addDoc(Brand.smsMessageCol({ ref: brandRef }), {
      readBy: [authorRef],
      body,
      status: SMSDeliveryStatus.Pending,
      patientRef: patient.ref,
      authorRef,
      practiceRef,
      conversationRef,
      direction: CommunicationDirection.Outbound,
      practicePhoneNumber,
      patientPhoneNumber,
      deleted: false,
    });
  }
}
