<div fxLayout="column" fxLayout.gt-sm="row">
  <div fxFlex="50" class="layout-margin" *ngIf="timeSpentChartBuilder">
    <h2>Time Spent</h2>
    <pr-chart [chartBuilder]="timeSpentChartBuilder" />
  </div>

  <div fxFlex="50" class="layout-margin" *ngIf="patientSpendChartBuilder">
    <h2>Patient Spend &amp; Appointment Duration</h2>
    <pr-chart [chartBuilder]="patientSpendChartBuilder" />
  </div>
</div>

<div class="layout-margin">
  <pr-table-chart
    fxFlex
    [chart]="tableChartCard"
    [replacementHeaders]="[{ from: 'Owner', to: 'Practitioner' }]"
   />
</div>
