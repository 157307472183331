<mat-toolbar color="accent">Treatment Plan Print Settings</mat-toolbar>

<mat-dialog-content>
  <div class="flex flex-col gap-4">
    <pr-system-template-selectors
      [templates]="data.templates"
      (templateChange)="selectedTemplates$.next($event)"
    />

    <mat-divider />

    <div>
      <h2>Treatment Plan Name</h2>
      <div class="flex items-center justify-between">
        <p>
          Would you like to include the
          <span class="font-bold">treatment plan name</span> in the printout?
        </p>
        <mat-checkbox
          [(ngModel)]="displayTreatmentPlanName"
          aria-label="display treatment plan name"
        />
      </div>
    </div>

    <mat-divider />

    <div>
      <h2>Select Treatment Steps</h2>
      <mat-selection-list
        #treatmentSteps
        cdkDropList
        (cdkDropListDropped)="drop($event)"
      >
        @for (step of data.treatmentSteps; track step.treatmentStep.ref.id) {
          <div class="flex" cdkDrag>
            <div
              class="flex cursor-move items-center justify-center"
              cdkDragHandle
            >
              <mat-icon>drag_handle</mat-icon>
            </div>
            <mat-list-option [value]="step" [selected]="true">
              <div
                class="status-indicator grid grid-cols-4 gap-4 border-l-2 border-solid pl-4"
                [ngClass]="step.stepAppointmentStatus"
              >
                <p class="col-span-2 truncate">{{ step.treatmentStep.name }}</p>
                <p class="status-colour" [ngClass]="step.stepAppointmentStatus">
                  {{ step.stepAppointmentStatus | splitCamel | titlecase }}
                </p>
                <p>{{ step.summary.total | currency }}</p>
              </div>
            </mat-list-option>
          </div>
        }
      </mat-selection-list>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button (click)="close()">Cancel</button>
    <button mat-flat-button color="primary" (click)="print()">Print</button>
  </pt-buttons-container>
</mat-dialog-actions>
