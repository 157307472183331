import {
  FeeScheduleMappingHandler,
  IBaseMigrationFeeSchedule,
} from '../../../mappings/fee-schedules';
import {
  FeeScheduleSourceEntity,
  IOasisFeeSchedule,
} from '../../source/entities/fee-schedule';

export class OasisFeeScheduleMappingHandler extends FeeScheduleMappingHandler<
  IOasisFeeSchedule,
  FeeScheduleSourceEntity
> {
  override sourceEntity = new FeeScheduleSourceEntity();
  translateFn = (record: IOasisFeeSchedule): IBaseMigrationFeeSchedule => ({
    ...record,
  });
}
