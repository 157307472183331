import { type IDisplayColour, isObject } from '@principle-theorem/shared';

export interface IChartedItemDisplay {
  hideWhenResolved: boolean;
  colour: IDisplayColour;
  icon: IChartedItemIcon;
  hasFill: boolean;
  disablesSurface: boolean;
}

export function isChartedItemDisplay(
  item: unknown
): item is IChartedItemDisplay {
  return (
    isObject(item) &&
    'hideWhenResolved' in item &&
    typeof item.hideWhenResolved === 'boolean' &&
    'hasFill' in item &&
    typeof item.hasFill === 'boolean' &&
    'disablesSurface' in item &&
    typeof item.disablesSurface === 'boolean' &&
    'colour' in item &&
    'icon' in item
  );
}

export interface IChartedItemIcon {
  slug: string;
  name: string;
}
