import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  inject,
  type OnDestroy,
} from '@angular/core';
import {
  ChartFacade,
  type ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  DragTracker,
  MouseInteractionsService,
} from '@principle-theorem/ng-shared';
import { surfaceFromRef } from '@principle-theorem/principle-core';
import { type IDentalChartViewSurface } from '@principle-theorem/principle-core/interfaces';
import { shareReplayCold } from '@principle-theorem/shared';
import {
  combineLatest,
  type Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { CHART_ENTITY_ID } from '../chart-entity-id';
import {
  type ClientPosition,
  getClientBoundingBox,
} from '../models/svg-helpers';

@Directive({
  selector: '[prChartSurface]',
  exportAs: 'prChartSurface',
})
export class ChartSurfaceDirective implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _highlightDisabled = false;
  private _interactionDisabled = false;
  private _tracker: DragTracker;
  private _chartId: ChartId = inject(CHART_ENTITY_ID);
  view$: ReplaySubject<IDentalChartViewSurface> = new ReplaySubject(1);
  drag$: Observable<MouseEvent>;
  click$: Observable<MouseEvent>;
  element: SVGSVGElement;
  selected$: Observable<boolean>;
  disabled$: Observable<boolean>;
  @HostBinding('class.surface') surfaceClass = true;
  @HostBinding('class.selected') selectedClass = false;
  @HostBinding('class.disabled') disabledClass = false;
  @HostBinding('style.cursor')
  get cursor(): string {
    return this._interactionDisabled ? 'default' : 'pointer';
  }

  @Input()
  set view(view: IDentalChartViewSurface) {
    if (view) {
      this.view$.next(view);
    }
  }

  constructor(
    elementRef: ElementRef<SVGSVGElement>,
    mouseInteractions: MouseInteractionsService,
    private _chartStore: ChartFacade
  ) {
    this.element = elementRef.nativeElement;
    this._tracker = new DragTracker(this.element, mouseInteractions);

    this._chartStore
      .canEdit$(this._chartId)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((canEdit) => (this._interactionDisabled = !canEdit));

    this.disabled$ = this.view$.pipe(
      switchMap((view) =>
        this._chartStore.isDisabledSurface$(
          this._chartId,
          surfaceFromRef(view.id)
        )
      ),
      tap((disabled) => (this.disabledClass = disabled)),
      shareReplayCold()
    );

    this.drag$ = this._tracker.drag$.pipe(
      withLatestFrom(this.disabled$),
      filter(([_event, disabled]) => !disabled),
      map(([event, _disabled]) => event)
    );

    this.click$ = this._tracker.click$.pipe(
      withLatestFrom(this.disabled$),
      filter(([_event, disabled]) => !disabled && !this._interactionDisabled),
      map(([event, _disabled]) => event)
    );

    this.selected$ = this.view$.pipe(
      switchMap((view) =>
        this._chartStore.isSelectedSurface$(this._chartId, view.id)
      ),
      tap((selected) => (this.selectedClass = selected)),
      shareReplayCold()
    );

    this.view$
      .pipe(
        switchMap((view) => combineLatest([of(view.id), this.drag$])),
        withLatestFrom(this.selected$),
        filter(([_, selected]) => !selected && !this.highlightDisabled),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([[surfaceRef]]) =>
        this._chartStore.selectSurface(this._chartId, surfaceRef)
      );

    this.view$
      .pipe(
        switchMap((view) => combineLatest([of(view.id), this.click$])),
        withLatestFrom(this.selected$),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([[surfaceRef], selected]) => {
        if (selected) {
          this._chartStore.deselectSurface(this._chartId, surfaceRef);
          return;
        }
        this._chartStore.selectSurface(this._chartId, surfaceRef);
      });
  }

  @Input()
  set highlightDisabled(_: boolean) {
    this._highlightDisabled = true;
  }

  get highlightDisabled(): boolean {
    return this._highlightDisabled;
  }

  ngOnDestroy(): void {
    this._tracker.destroy();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  get center(): ClientPosition {
    return getClientBoundingBox(this.element).center;
  }
}
