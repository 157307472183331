import { rand, randNumber } from '@ngneat/falso';
import {
  TransactionProvider,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import {
  DimensionsCommon,
  ITransactionDimension,
} from '@principle-theorem/reporting/interfaces';
import {
  getEnumValues,
  toSerialisedTimestamp,
  toTimestamp,
} from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';
import { BigQueryFirestoreModelMock } from './common.mock';
import { MockAccount } from '@principle-theorem/principle-core';

function MockKeyPair(value: string): DimensionsCommon.IKeyValuePair {
  return { value, key: value };
}

function MockTransactionProvider(type: TransactionType): TransactionProvider {
  if (type === TransactionType.Claim) {
    return rand([
      // TransactionProvider.MedipassDVA,
      // TransactionProvider.MedipassPatientFunded,
      TransactionProvider.MedipassHicaps,
      TransactionProvider.MedipassMedicare,
      TransactionProvider.TyroEasyClaimBulkBill,
      // TransactionProvider.TyroEasyClaimPartPaid,
      // TransactionProvider.TyroEasyClaimFullyPaid,
      TransactionProvider.TyroHealthPoint,
    ]);
  }
  const roll = randNumber({ min: 0, max: 100 });
  if (roll < 75) {
    return rand([TransactionProvider.TyroEftpos, TransactionProvider.Manual]);
  }
  return rand([
    TransactionProvider.MedipassVirtualTerminal,
    TransactionProvider.AccountCredit,
    TransactionProvider.Discount,
    TransactionProvider.Cash,
  ]);
}

function MockProviderSubType(
  provider: TransactionProvider
): DimensionsCommon.IKeyValuePair | undefined {
  switch (provider) {
    case TransactionProvider.TyroEftpos:
      return rand([MockKeyPair('Visa'), MockKeyPair('Mastercard')]);
    case TransactionProvider.Manual:
      return rand([
        MockKeyPair('After Pay'),
        MockKeyPair('Cash'),
        MockKeyPair('CDBS'),
        MockKeyPair('Cheque'),
        MockKeyPair('Direct Deposit'),
        MockKeyPair('DVA'),
        MockKeyPair('Eftpos'),
        MockKeyPair('Health Fund Claim'),
        MockKeyPair('Open Pay'),
        MockKeyPair('QH Voucher'),
        MockKeyPair('Zip Pay'),
      ]);
    default:
      return;
  }
}

function MockTransactionType(): TransactionType {
  const roll = randNumber({ min: 0, max: 100 });
  if (roll < 5) {
    return TransactionType.Outgoing;
  }
  if (roll < 25) {
    return TransactionType.Claim;
  }
  return TransactionType.Incoming;
}

function MockTransactionStatus(): TransactionStatus {
  const roll = randNumber({ min: 0, max: 100 });
  if (roll < 75) {
    return TransactionStatus.Complete;
  }
  return rand(getEnumValues(TransactionStatus));
}

export class TransactionDimensionMock
  extends BigQueryFirestoreModelMock
  implements ITransactionDimension
{
  timestamp = toSerialisedTimestamp(toTimestamp());
  uid = uuid();
  reference = uuid();
  type = MockTransactionType();
  status = MockTransactionStatus();
  provider = MockTransactionProvider(this.type);
  providerSubType = MockProviderSubType(this.provider);
  from = MockAccount.mockAccountDetails().name;
  to = MockAccount.mockAccountDetails().name;
  amount = randNumber({ min: 150, max: 300 });
}
