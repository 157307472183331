import { InjectionToken } from '@angular/core';

export interface INgPaymentsConfig {
  medipass: {
    webhookEndpoint: string;
  };
  hicapsConnect: {
    PmsName: string;
    PmsKey: string;
  }
}

export const NG_PAYMENTS_CONFIG: InjectionToken<INgPaymentsConfig> =
  new InjectionToken('INgPaymentsConfig');
