import { ChartElement } from './chart-element';
import { type IChartTooth } from './chart-tooth';

export class ChartToothSurfaceTexture extends ChartElement {
  constructor(public parent: IChartTooth) {
    super();
    this.width = 60;
    this.height = 60;
  }
}
