/* eslint-disable @typescript-eslint/no-explicit-any */
import { Overlay, type ScrollStrategy } from '@angular/cdk/overlay';
import { InjectionToken, type Provider } from '@angular/core';

export const CONNECTED_DIALOG_SCROLL_STRATEGY: InjectionToken<
  () => ScrollStrategy
> = new InjectionToken<() => ScrollStrategy>(
  'connected-dialog-scroll-strategy'
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function CONNECTED_DIALOG_SCROLL_STRATEGY_FACTORY(
  overlay: Overlay
): () => ScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function CONNECTED_DIALOG_SCROLL_STRATEGY_PROVIDER_FACTORY(
  overlay: Overlay
): () => ScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

export const CONNECTED_DIALOG_SCROLL_STRATEGY_PROVIDER: Provider = {
  provide: CONNECTED_DIALOG_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: CONNECTED_DIALOG_SCROLL_STRATEGY_PROVIDER_FACTORY,
};
