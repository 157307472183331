import { type ITreatmentCategory } from '../treatment-category';

export const UNCATEGORISED_CATEGORY: ITreatmentCategory = {
  name: 'Uncategorised',
  colour: {
    name: 'Default Segment Colour',
    value: '#4285f4',
    // TODO: Fix circular dependencies causing test to fail. Should be:
    // `EVENT_TYPE_COLOUR_MAP[EventType.Appointment]`
    // https://app.clickup.com/t/axnxqc
  },
  deleted: false,
};
