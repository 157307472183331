import { type IXSLXImport } from '@principle-theorem/shared';
import type { Row } from 'exceljs';
import {
  type IItemCodeToNoteXLSX,
  ITEM_CODE_TO_NOTE_HEADERS,
} from './item-codes-to-notes-xlsx';

export class XLSXToItemCodesToNotes
  implements IXSLXImport<IItemCodeToNoteXLSX>
{
  headers = ITEM_CODE_TO_NOTE_HEADERS;

  translate(row: Row): IItemCodeToNoteXLSX {
    return {
      code: row.getCell('code').value?.toString() ?? '',
      description: row.getCell('description').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IItemCodeToNoteXLSX['mapTo'],
    };
  }
}
