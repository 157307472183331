import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CurrentBrandScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { TreatmentTemplate } from '@principle-theorem/principle-core';
import {
  IImplementsTreatmentTemplate,
  type IPractice,
  type IStaffer,
  type ITreatmentTemplate,
  type TreatmentTemplateTreatments,
} from '@principle-theorem/principle-core/interfaces';
import {
  STEP_SIZE,
  filterUndefined,
  snapshot,
  type INamedDocument,
  type WithRef,
  isSameRef,
} from '@principle-theorem/shared';
import { get, omit } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UpsertTreatmentTemplateStore } from './upsert-treatment-template.store';

export interface ITreatmentTemplateData {
  treatmentTemplate?: WithRef<ITreatmentTemplate>;
}

interface ITreatmentTemplateFormData {
  name: string;
  duration: number;
  isPublic: boolean;
  treatment: TreatmentTemplateTreatments;
  enabledPractices: EnabledPracticesFormData;
  implementedBy: ImplementedByFormData;
}

interface IImplementorTreatmentPair {
  enabled: boolean;
  treatment?: TreatmentTemplateTreatments;
  duration?: number;
}

type EnabledPracticesFormData = Record<string, boolean>;
type ImplementedByFormData = Record<string, IImplementorTreatmentPair>;

@Component({
  selector: 'pr-upsert-treatment-template',
  templateUrl: './upsert-treatment-template.component.html',
  styleUrls: ['./upsert-treatment-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsertTreatmentTemplateComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByPractice = TrackByFunctions.ref<INamedDocument<IPractice>>();
  trackByStaffer = TrackByFunctions.ref<INamedDocument<IStaffer>>();
  stepSize = signal(STEP_SIZE);
  staffer$: Observable<WithRef<IStaffer>>;
  form: TypedFormGroup<ITreatmentTemplateFormData> =
    new TypedFormGroup<ITreatmentTemplateFormData>({
      name: new TypedFormControl('', Validators.required),
      duration: new TypedFormControl(undefined, [
        Validators.required,
        Validators.min(this.stepSize()),
      ]),
      treatment: new TypedFormControl<TreatmentTemplateTreatments | undefined>(
        undefined,
        Validators.required
      ),
      isPublic: new TypedFormControl(false),
      enabledPractices: new TypedFormGroup<EnabledPracticesFormData>({}),
      implementedBy: new TypedFormGroup<ImplementedByFormData>({}),
    });
  update = false;

  constructor(
    private _dialogRef: MatDialogRef<
      UpsertTreatmentTemplateComponent,
      ITreatmentTemplate
    >,
    public store: UpsertTreatmentTemplateStore,
    private _brandScope: CurrentBrandScope,
    private _organisation: OrganisationService,

    @Inject(MAT_DIALOG_DATA) public data?: ITreatmentTemplateData
  ) {
    this.staffer$ = this._organisation.staffer$.pipe(filterUndefined());

    if (this.data?.treatmentTemplate) {
      this.update = true;
      this.form.patchValue({
        ...omit(this.data.treatmentTemplate, [
          'enabledPractices',
          'implementedBy',
        ]),
        duration: TreatmentTemplate.getDuration(this.data.treatmentTemplate),
        treatment: TreatmentTemplate.getTreatment(this.data.treatmentTemplate),
      });
    }

    this.store.loadBrand(this._brandScope.doc$.pipe(filterUndefined()));

    this.store.practices$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((practices) => {
        practices.map((practice) => {
          let practiceIsEnabled = false;
          if (
            this.data?.treatmentTemplate &&
            TreatmentTemplate.practiceIsEnabled(
              this.data.treatmentTemplate,
              practice
            )
          ) {
            practiceIsEnabled = true;
          }
          this.enabledPractices.addControl(
            practice.ref.id,
            new TypedFormControl<boolean>(practiceIsEnabled)
          );
        });
      });

    this.store.staff$.pipe(takeUntil(this._onDestroy$)).subscribe((staff) => {
      staff.map((staffer) => {
        if (!this.data?.treatmentTemplate) {
          this.implementedBy.addControl(
            staffer.ref.id,
            new TypedFormGroup<IImplementorTreatmentPair>({
              enabled: new TypedFormControl<boolean>(false),
              treatment: new TypedFormControl<TreatmentTemplateTreatments>(),
              duration: new TypedFormControl(
                undefined,
                Validators.min(this.stepSize())
              ),
            })
          );
          return;
        }

        const template = this.data.treatmentTemplate;
        const implementor = TreatmentTemplate.getImplementor(template, staffer);

        this.implementedBy.addControl(
          staffer.ref.id,
          new TypedFormGroup<IImplementorTreatmentPair>({
            enabled: new TypedFormControl<boolean>(!!implementor),
            treatment: new TypedFormControl<TreatmentTemplateTreatments>(
              this._implementorTreatment(template, implementor)
            ),
            duration: new TypedFormControl(
              this._implementorDuration(template, implementor),
              Validators.min(this.stepSize())
            ),
          })
        );
      });
    });

    this._organisation.practice$
      .pipe(filterUndefined(), takeUntil(this._onDestroy$))
      .subscribe((practice) => {
        const path = `settings.timeline.stepSizeInMins`;
        const stepSize = get(practice, path, STEP_SIZE) as number;
        this.stepSize.set(stepSize);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  get enabledPractices(): TypedFormGroup<EnabledPracticesFormData> {
    return this.form.controls
      .enabledPractices as TypedFormGroup<EnabledPracticesFormData>;
  }

  get implementedBy(): TypedFormGroup<ImplementedByFormData> {
    return this.form.controls
      .implementedBy as TypedFormGroup<ImplementedByFormData>;
  }

  async toggleAllStaff(checked: boolean): Promise<void> {
    const staff = await snapshot(this.store.staff$.pipe(filterUndefined()));
    staff.map((staffer) => {
      this.getImplementationPair(staffer).controls.enabled.setValue(checked);
    });
  }

  getImplementationPair(
    staffer: INamedDocument<IStaffer>
  ): TypedFormGroup<IImplementorTreatmentPair> {
    return this.implementedBy.controls[
      staffer.ref.id
    ] as TypedFormGroup<IImplementorTreatmentPair>;
  }

  async save(): Promise<void> {
    if (!this.form.valid) {
      return;
    }

    const data: ITreatmentTemplateFormData = this.form.getRawValue();

    const treatmentTemplate: ITreatmentTemplate = TreatmentTemplate.init({
      ...this.data?.treatmentTemplate,
      name: data.name,
      isPublic: data.isPublic,
      defaltDuration: data.duration,
      defaultTreatment: data.treatment,
    });

    TreatmentTemplate.resetPractices(treatmentTemplate);

    const practices = await snapshot(this.store.practices$);
    practices.map((practice) => {
      if (data.enabledPractices[practice.ref.id]) {
        TreatmentTemplate.addPractice(treatmentTemplate, practice);
      }
    });

    TreatmentTemplate.resetImplementors(treatmentTemplate);
    const staff = await snapshot(this.store.staff$);
    staff.map((staffer) => {
      const implementor: IImplementorTreatmentPair | undefined =
        data.implementedBy[staffer.ref.id];
      if (implementor && implementor.enabled) {
        const treatment = implementor.treatment ?? data.treatment;
        const duration = implementor.duration ?? data.duration;
        TreatmentTemplate.updateImplementor(
          treatmentTemplate,
          staffer,
          treatment,
          duration
        );
      }
    });

    this._dialogRef.close(treatmentTemplate);
  }

  private _implementorDuration(
    template: WithRef<ITreatmentTemplate>,
    implementor: IImplementsTreatmentTemplate | undefined
  ): number | undefined {
    return implementor?.duration === TreatmentTemplate.getDuration(template)
      ? undefined
      : implementor?.duration;
  }

  private _implementorTreatment(
    template: WithRef<ITreatmentTemplate>,
    implementor: IImplementsTreatmentTemplate | undefined
  ): TreatmentTemplateTreatments | undefined {
    return isSameRef(
      implementor?.treatment,
      TreatmentTemplate.getTreatment(template)
    )
      ? undefined
      : implementor?.treatment;
  }
}
