<mat-icon
  aria-hidden="false"
  [matBadge]="badge"
  [matBadgeHidden]="expanded || !badge"
  [matBadgeColor]="badgeColor"
  [matTooltip]="label"
  [matTooltipDisabled]="expanded"
>
  {{ icon }}
</mat-icon>

<ng-container *ngIf="expanded">
  <div fxLayout="row" fxLayoutAlign="start start">
    <h4>{{ label }}</h4>
    <small *ngIf="isDev" matTooltip="This feature is dev status">Dev</small>
    <small *ngIf="isAlpha" matTooltip="This feature is alpha status">
      Alpha
    </small>
  </div>
  <span
    fxFlex
    class="trailing-badge"
    [matBadge]="badge$ | async"
    [matBadgeHidden]="(badge$ | async) === 0"
    [matBadgeColor]="badgeColor"
  >
  </span>
</ng-container>
