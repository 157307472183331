<!-- *ngIf="(editable$ | async) && (selected$ | async); else readOnly" -->
<span
  *ngIf="editable$ | async; else readOnly"
  [ptEditorAutocomplete]="autoMention"
  #autoComplete="ptEditorAutocompleteTrigger"
  [editorContentValue]="content$ | async"
  [matAutocompleteDisabled]="disabled$ | async"
>
  <span class="angular-component-with-content">
    <span tiptapNodeViewContent></span>
  </span>
</span>
<mat-autocomplete
  #autoMention="matAutocomplete"
  [autoActiveFirstOption]="true"
  [panelWidth]="suggestionWidth"
  (optionSelected)="selectMention($event)"
>
  <ng-container *ngIf="searchFilter.results$ | async as groups">
    <ng-container *ngFor="let group of groups; trackBy: trackByGroup">
      <mat-optgroup *ngIf="group.options.length" [label]="group.name">
        <mat-option
          *ngFor="let option of group.options; trackBy: trackByMention"
          [value]="option"
        >
          {{ option.key }}
        </mat-option>
      </mat-optgroup>
    </ng-container>
  </ng-container>
</mat-autocomplete>

<ng-template #readOnly>
  <span class="angular-component-with-content">
    <span tiptapNodeViewContent></span>
  </span>
</ng-template>
