import {
  IOrganisation,
  IPractice,
  ISMSIntegrationData,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference, WithRef } from '@principle-theorem/shared';
import {
  Organisation,
  isPodiumSMSProvider,
  isTNZSMSProvider,
} from '../organisation/organisation';
import {
  TwilioIntegrationStorage,
  resolvePracticeMobileNumber,
} from './twilio-integration';
import { IntegrationStorage } from '@principle-theorem/integrations';
import { PodiumIntegrationStorage } from './podium-integration';
import { TNZIntegrationStorage } from './tnz-integration';

export class SMSIntegration {
  static async resolvePracticeNumber(
    practiceRef: DocumentReference<IPractice>,
    organisation: IOrganisation
  ): Promise<string | undefined> {
    if (isPodiumSMSProvider(organisation)) {
      return 'Podium';
    }

    if (isTNZSMSProvider(organisation)) {
      return 'TNZ';
    }

    return resolvePracticeMobileNumber(practiceRef);
  }

  static async hasEnabledSMSProvider(
    organisation: WithRef<IOrganisation>
  ): Promise<boolean> {
    if (isPodiumSMSProvider(organisation)) {
      return SMSIntegration.integrationEnabled(
        organisation,
        new PodiumIntegrationStorage(),
        PodiumIntegrationStorage.isEnabled
      );
    }

    if (isTNZSMSProvider(organisation)) {
      return SMSIntegration.integrationEnabled(
        organisation,
        new TNZIntegrationStorage(),
        TNZIntegrationStorage.isEnabled
      );
    }

    return SMSIntegration.integrationEnabled(
      organisation,
      new TwilioIntegrationStorage(),
      TwilioIntegrationStorage.isEnabled
    );
  }

  static async integrationEnabled<T extends ISMSIntegrationData>(
    organisation: WithRef<IOrganisation>,
    storage: IntegrationStorage<T>,
    isEnabled: (data?: T) => boolean
  ): Promise<boolean> {
    const data = await storage.get(Organisation.integrationCol(organisation));
    return isEnabled(data?.data);
  }
}
