import { AnyExtension, Node, mergeAttributes } from '@tiptap/core';
import { Fragment } from '@tiptap/pm/model';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { registerCustomProtocol, reset } from 'linkifyjs';
import {
  findNodesOfType,
  getActiveNode,
  nodeTypeIsActive,
} from '../../../core/helpers';
import { InlineNodes, NodeGroup } from '../../available-extensions';
import { removeLink } from './link-keymap';
import { createLinkPasteRulesKeymap } from './link-paste-rule';

export interface ILinkProtocolOptions {
  scheme: string;
  optionalSlashes?: boolean;
}

export interface ILinkOptions {
  /**
   * If enabled, it adds links as you type.
   */
  autolink: boolean;
  /**
   * An array of custom protocols to be registered with linkifyjs.
   */
  protocols: (ILinkProtocolOptions | string)[];
  /**
   * If enabled, links will be opened on click.
   */
  openOnClick: boolean;
  /**
   * Adds a link to the current selection if the pasted content only contains an url.
   */
  linkOnPaste: boolean;
  /**
   * A list of HTML attributes to be rendered.
   */
  HTMLAttributes: ILinkAttributes;
  /**
   * A validation function that modifies link verification for the auto linker.
   * @param url - The url to be validated.
   * @returns - True if the url is valid, false otherwise.
   */
  validate?: (url: string) => boolean;
}

export interface ILinkAttributes {
  href?: string;
  target?: string;
  rel?: string;
  class?: string;
}

declare module '@tiptap/core' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Commands<ReturnType> {
    [InlineNodes.Link]: {
      toggleLink: (attributes: {
        href?: string;
        target?: string | null;
        rel?: string | null;
        class?: string | null;
      }) => ReturnType;
    };
  }
}

export function createLinkExtension(): AnyExtension {
  return Node.create<ILinkOptions>({
    name: InlineNodes.Link,
    content: `${InlineNodes.Text}*`,
    group: NodeGroup.Inline,
    inline: true,
    priority: 1000,

    onCreate() {
      this.options.protocols.forEach((protocol) => {
        if (typeof protocol === 'string') {
          registerCustomProtocol(protocol);
          return;
        }
        registerCustomProtocol(protocol.scheme, protocol.optionalSlashes);
      });
    },

    onDestroy() {
      reset();
    },

    addOptions() {
      return {
        openOnClick: true,
        linkOnPaste: true,
        autolink: true,
        protocols: [],
        HTMLAttributes: {
          target: '_blank',
          rel: 'noopener noreferrer nofollow',
          class: undefined,
        },
        validate: undefined,
      };
    },

    addAttributes() {
      return {
        href: {
          default: undefined,
        },
        target: {
          default: this.options.HTMLAttributes.target,
        },
        rel: {
          default: this.options.HTMLAttributes.rel,
        },
        class: {
          default: this.options.HTMLAttributes.class,
        },
      };
    },

    parseHTML() {
      return [{ tag: 'a[href]:not([href *= "javascript:" i])' }];
    },

    renderHTML({ HTMLAttributes }) {
      return [
        'a',
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
        0,
      ];
    },

    addCommands() {
      return {
        toggleLink:
          (attributes) =>
          ({ tr, state, dispatch }) => {
            if (!dispatch) {
              return false;
            }
            const type = state.schema.nodes[InlineNodes.Link];
            if (nodeTypeIsActive(state, type)) {
              const node = getActiveNode(state, type);
              if (!node) {
                return false;
              }
              removeLink(state, dispatch, node);
              return false;
            }

            const textNodes = findNodesOfType(
              tr.selection.content().content,
              state.schema.nodes[InlineNodes.Text]
            );

            dispatch(
              tr.replaceSelectionWith(
                type.create(attributes, Fragment.fromArray(textNodes))
              )
            );
            return true;
          },
      };
    },

    addProseMirrorPlugins() {
      const editor = this.editor;
      const plugins: Plugin[] = [
        createLinkPasteRulesKeymap()(editor),
        new Plugin({
          key: new PluginKey(`${InlineNodes.Link}-node-view`),
          props: {
            handleKeyDown(this, _view, event: KeyboardEvent) {
              if (event.key === 'Enter' && editor.isActive(InlineNodes.Link)) {
                editor.chain().splitBlock({ keepMarks: true }).run();
                event.preventDefault();
                return false;
              }
            },
          },
        }),
      ];

      return plugins;
    },
  });
}
