import {
  Gender,
  ISourceEntity,
  SourceEntityMigrationType,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISODateType,
  Timezone,
  TypeGuard,
  isISODateType,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import { PATIENT_CLINICAL_NOTE_DESTINATION_ENTITY } from '../../../destination/entities/consts';
import { PATIENT_INTERACTION_DESTINATION_ENTITY } from '../../../destination/entities/patient-interactions';
import { PATIENT_INVOICE_DESTINATION_ENTITY } from '../../../destination/entities/patient-invoices';
import { PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY } from '../../../destination/entities/patient-treatment-plans';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
} from '../../../source/source-helpers';
import { PATIENT_CLINICAL_CHART_DESTINATION_ENTITY } from '../../destination/entities/patient-clinical-charts';
import { PATIENT_DOCUMENTS_DESTINATION_ENTITY } from '../../destination/entities/patient-documents';
import { PATIENT_XRAYS_DESTINATION_ENTITY } from '../../destination/entities/patient-xrays';
import { PATIENT_DESTINATION_ENTITY } from '../../destination/entities/patients';
import { PATIENT_RELATIONSHIP_DESTINATION_ENTITY } from '../../destination/entities/patient-relationships';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import { PATIENT_APPOINTMENT_DESTINATION_ENTITY } from '../../destination/entities/patient-appointments';
import { EXISTING_PATIENT_DESTINATION_ENTITY } from '../../../destination/entities/existing-patient';

export const PATIENT_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient List',
    description: '',
    idPrefix: PATIENT_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface IOasisPatient {
  id: number; // Patient ID; IDs from 99000 and above seem to be contacts
  accountPatientId: number; // Can sometimes differ from the patient number which must reference a patient that is in charge of their accounts
  lastName: string; // Surname
  title: string; // Title
  firstName: string; // Given Name
  nickname?: string;
  dateOfBirth?: ISODateType; // Date of Birth 17/06/1940
  gender: Gender;
  addressLine1?: string; // Address 1
  addressLine2?: string; // Address 2
  suburb?: string; // Suburb
  postCode?: number; // Postcode
  practitionerId?: number; // Practitioner ID
  hygienistId?: number; // Hygienist ID
  practiceId?: number; // Practice ID
  homePhone?: string;
  workPhone?: string;
  fax?: string;
  mobile?: string;
  mainPhone?: string;
  email?: string;
  healthFundName?: string;
  medicareNumber?: string;
  medicareSubNumerate?: string;
  referralPatientId?: number;
  referralPatientName?: string;
  preferredFeeSchedule?: string;
  userCode2?: string; // A mix of patient tags and referral sources
  userCode3?: string; // A mix of patient tags and referral sources
  userCode4?: string; // Boolean string?
  userCode5?: string; // Empty
  userCode6?: string; // Empty
  userCode7?: string; // Empty
  userCode8?: string; // Empty
  userCode9?: string; // Empty
  workCare1?: string; // Full name of someone
  workCare2?: string; // Phone number
  workCare3?: string; // Either phone number or name of someone
  userMemo1?: string; // Not many records
  userMemo2?: string; // Not many records
  userMemo3?: string; // Not many records
  userMemo4?: string; // Not many records
  userMemo5?: string; // Names of people
  userMemo6?: string; // Phone numbers of people
  healthWarnings?: string; // MEDICAL HISTORY - Asthma / Respiratory problems, Sinus problemsışMEDICATIONS - Edecrin, ParietışALLERGIES - Scloine, Local Anaesthetic, Codeine
  treatmentReminderNotes?: string; // General pinned notes
  notes?: string; // Empty
  contactId?: number; // This references a patientNumber that starts with 999999x which seems to be a contact
  financialWarnings?: string; // Add as a pinned note/appointment tag? Would suit post-migration workflow to clean up.
  createdAt?: ISODateType; // 20/05/2014
  lastVisitAt?: ISODateType; // 20/05/2014
  totalInvoiced?: number; // Sum of all invoice
  totalPaid?: number; // Sum of all payments
  updatedAt?: ISODateType; // 20/05/2014
  lastInvoiceDate?: ISODateType; // 20/05/2014
  lastPaymentDate?: ISODateType; // 20/05/2014
  isInactive: boolean; // -1 is inactive
  communicationPreference?: string; // Appointment contact preference
  newPatientDate?: ISODateType; // 19/05/2022
  dvaCardNumber?: string; // DVA card number
}

interface IOasisPatientResult extends Omit<IOasisPatient, 'gender'> {
  gender: 'F' | 'M' | 'f' | '2' | '1' | '0' | '8';
}

export interface IOasisUnusedPatient {
  APPENDTIME: unknown; //
  APPFLAGS: unknown; //
  APPSTARTTIME: unknown; //
  BANK1: unknown; //
  BANK2: unknown; //
  BANK3: unknown; //
  BILL1: unknown; //
  BILL2?: string; // Address field 1
  BILL3?: string; // Address field 2
  BILL4?: string; // Address field 3
  CONTRACTDATE: unknown; //
  CONTRACTPRICE: unknown; //
  CONTRACTTERMAMOUNT: unknown; //
  CONTRACTDEPOSIT: unknown; //
  CONTRACTDURATION: unknown; //
  CONTRACTBILLED: unknown; //
  CREDITCARD1: unknown; //
  CREDITCARD2: unknown; //
  CREDITCARD3: unknown; //
  CURRENTDIAGNOSIS: unknown; //
  CYCLEDAYS?: 28 | 0 | 9; // Invoice due in days?
  DEBITCARD1: unknown; //
  DEBITCARD2: unknown; //
  DEBITCARD3: unknown; //
  DVACARD: unknown; //
  EMAILOVERDUESFLAG: -1 | 0; //
  EMPLOYER?: string; //
  FILENO?: string; // File Number
  HFUND2: unknown; //
  HFUNDCODE: unknown; //
  HOSPITALCODE: unknown; //
  ICONNUMBER: unknown; //
  INCTREATMENTFLAG: -1 | 0; //
  INDEFINITEREFFLAG: -1 | 0; //
  INITIAL: unknown; //
  LASTSTATERUN: unknown; //
  MEDICAREDATE: unknown; //
  MISC: unknown; //
  MISCDATE1: unknown; //
  MISCDATE2: unknown; //
  MISCDATE3: unknown; //
  MISCDATE4: unknown; //
  ONHOLDFLAG: -1 | 0; // Unsure what this is for
  OWNRECALLFLAG: -1 | 0 | 1; // Unsure what this is for
  PHOTOFLAG: 2 | 3; //
  RECALL1?: string; // Recall full name with title
  RECALL2?: string; // Recall address 1
  RECALL3?: string; // Recall address 2
  RECALL4?: string; // Recall address 3
  RECALLNAME?: unknown; // Recall first name
  RECALLUSEBILLFLAG: -1 | 0; //
  REFERDRCODE?: string; // Unsure what this is for
  REFERDATE: unknown; //
  REFERINTERVAL: unknown; //
  REFER1: unknown; //
  REFER2: unknown; //
  REMINDERNO?: 0 | 99; //
  STANDBYFLAG?: 0; //
  SUBBILLCODE: unknown; //
  SUBDATE1: unknown; //
  SUBDATE2: unknown; //
  SUBDATE3: unknown; //
  SUBDATE4: unknown; //
  SUBDR1?: string; // Some kind of referral reference
  SUBDR2?: string; //
  SUBDR3: unknown; //
  SUBDR4: unknown; //
  TELCODE1?: string; // Home phone
  TELCODE2?: string; // Work phone
  TELCODE3?: string; // Mobile phone
  TELCODE4?: string; // Main phone
  UGIVENNAME: string; //
  UPREFERSNAME?: string; // Preferred name
  USECOUPONFLAG: 0; //
  USERINTEGER1: unknown; //
  USERINTEGER2: unknown; //
  USERINTEGER3: unknown; //
  USERINTEGER4: unknown; //
  USERINTEGER5: unknown; //
  USERINTEGER6: unknown; //
  USERINTEGER7: unknown; //
  USERINTEGER8: unknown; //
  USERINTEGER9: unknown; //
  USERMEMO7: unknown; //
  USERMEMO8: unknown; //
  USERMEMO9: unknown; //
  USERTRUEFALSE1: unknown; //
  USERTRUEFALSE2: unknown; //
  USERTRUEFALSE3: unknown; //
  USERTRUEFALSE4: unknown; //
  USERTRUEFALSE5: unknown; //
  USERTRUEFALSE6: unknown; //
  USERTRUEFALSE7: unknown; //
  USERTRUEFALSE8: unknown; //
  USERTRUEFALSE9: unknown; //
  USURNAME: string; //
  WPNAME: string; //
}

export function isOasisPatient(item: unknown): item is IOasisPatient {
  return TypeGuard.interface<IOasisPatient>({
    id: isNumber,
    accountPatientId: isNumber,
    lastName: isString,
    title: isString,
    firstName: isString,
    nickname: TypeGuard.nilOr(isString),
    dateOfBirth: TypeGuard.nilOr(isISODateType),
    gender: TypeGuard.enumValue(Gender),
    addressLine1: TypeGuard.nilOr(isString),
    addressLine2: TypeGuard.nilOr(isString),
    suburb: TypeGuard.nilOr(isString),
    postCode: TypeGuard.nilOr(isNumber),
    practitionerId: TypeGuard.nilOr(isNumber),
    hygienistId: TypeGuard.nilOr(isNumber),
    practiceId: TypeGuard.nilOr(isNumber),
    homePhone: TypeGuard.nilOr(isString),
    workPhone: TypeGuard.nilOr(isString),
    fax: TypeGuard.nilOr(isString),
    mobile: TypeGuard.nilOr(isString),
    mainPhone: TypeGuard.nilOr(isString),
    email: TypeGuard.nilOr(isString),
    healthFundName: TypeGuard.nilOr(isString),
    medicareNumber: TypeGuard.nilOr(isString),
    medicareSubNumerate: TypeGuard.nilOr(isString),
    referralPatientId: TypeGuard.nilOr(isNumber),
    referralPatientName: TypeGuard.nilOr(isString),
    preferredFeeSchedule: TypeGuard.nilOr(isString),
    userCode2: TypeGuard.nilOr(isString),
    userCode3: TypeGuard.nilOr(isString),
    userCode4: TypeGuard.nilOr(isString),
    userCode5: TypeGuard.nilOr(isString),
    userCode6: TypeGuard.nilOr(isString),
    userCode7: TypeGuard.nilOr(isString),
    userCode8: TypeGuard.nilOr(isString),
    userCode9: TypeGuard.nilOr(isString),
    workCare1: TypeGuard.nilOr(isString),
    workCare2: TypeGuard.nilOr(isString),
    workCare3: TypeGuard.nilOr(isString),
    userMemo1: TypeGuard.nilOr(isString),
    userMemo2: TypeGuard.nilOr(isString),
    userMemo3: TypeGuard.nilOr(isString),
    userMemo4: TypeGuard.nilOr(isString),
    userMemo5: TypeGuard.nilOr(isString),
    userMemo6: TypeGuard.nilOr(isString),
    healthWarnings: TypeGuard.nilOr(isString),
    treatmentReminderNotes: TypeGuard.nilOr(isString),
    notes: TypeGuard.nilOr(isString),
    contactId: TypeGuard.nilOr(isNumber),
    financialWarnings: TypeGuard.nilOr(isString),
    createdAt: TypeGuard.nilOr(isISODateType),
    lastVisitAt: TypeGuard.nilOr(isISODateType),
    totalInvoiced: TypeGuard.nilOr(isNumber),
    totalPaid: TypeGuard.nilOr(isNumber),
    updatedAt: TypeGuard.nilOr(isISODateType),
    lastInvoiceDate: TypeGuard.nilOr(isISODateType),
    lastPaymentDate: TypeGuard.nilOr(isISODateType),
    isInactive: isBoolean,
    communicationPreference: TypeGuard.nilOr(isString),
    newPatientDate: TypeGuard.nilOr(isISODateType),
    dvaCardNumber: TypeGuard.nilOr(isString),
  })(item);
}

export interface IOasisPatientTranslations {
  createdAt?: ISODateType;
  updatedAt?: ISODateType;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOasisPatientFilters {}

const PATIENT_SOURCE_QUERY = `
SELECT
  convert_to_integer(PATNUMBER) AS id,
  convert_to_integer(ACCNUMBER) AS account_patient_id,
  SURNAME AS last_name,
  TITLE AS title,
  GIVENNAME AS first_name,
  NUllIF(NUllIF(PREFERSNAME, GIVENNAME), '') AS nickname,
  CASE
    WHEN strpos(DATEBIRTH, '/') > 0
      THEN to_char(
        convert_to_date(DATEBIRTH, 'DD/MM/YYYY'),
        'YYYY-MM-DD'
      )
    ELSE NULLIF(NULLIF(DATEBIRTH, ''), 'NULL')
  END AS date_of_birth,
  NULLIF(SEX, '') AS gender,
  NULLIF(STREET1, '') AS address_line_1,
  NULLIF(STREET2, '') AS address_line_2,
  NULLIF(SUBURB, '') AS suburb,
  NULLIF(convert_to_integer(POSTCODE), 0) AS post_code,
  NULLIF(DOCTORNUMBER, 0) AS practitioner_id,
  NULLIF(HYGIENISTNUMBER, 0) AS hygienist_id,
  NULLIF(BRANCHNUMBER, 0) AS practice_id,
  NULLIF(convert_to_text(HOMETEL), '') AS home_phone,
  NULLIF(convert_to_text(WORKTEL), '') AS work_phone,
  NULLIF(convert_to_text(FAX), '') AS fax,
  NULLIF(convert_to_text(MOBILETEL), '') AS mobile,
  NULLIF(convert_to_text(SEARCHTEL), '') AS main_phone,
  NULLIF(EMAIL, '') AS email,
  NULLIF(HFUND1, '') AS health_fund_name,
  NULLIF(TRIM(MEDICARE1), '') AS medicare_number,
  NULLIF(TRIM(MEDICARE2), '') AS medicare_sub_numerate,
  convert_to_integer(REFERPATNUMBER) AS referral_patient_id,
  NULLIF(REFERPATNAME, '') AS referral_patient_name,
  NULLIF(USERCODES1, '') AS preferred_fee_schedule,
  NULLIF(USERCODES2, '') AS user_code_2,
  NULLIF(USERCODES3, '') AS user_code_3,
  NULLIF(USERCODES4, '') AS user_code_4,
  NULLIF(USERCODES5, '') AS user_code_5,
  NULLIF(USERCODES6, '') AS user_code_6,
  NULLIF(USERCODES7, '') AS user_code_7,
  NULLIF(USERCODES8, '') AS user_code_8,
  NULLIF(USERCODES9, '') AS user_code_9,
  NULLIF(WORKCARE1, '') AS work_care_1,
  NULLIF(WORKCARE2, '') AS work_care_2,
  NULLIF(WORKCARE3, '') AS work_care_3,
  NULLIF(USERMEMO1, '') AS user_memo_1,
  NULLIF(USERMEMO2, '') AS user_memo_2,
  NULLIF(USERMEMO3, '') AS user_memo_3,
  NULLIF(USERMEMO4, '') AS user_memo_4,
  NULLIF(USERMEMO5, '') AS user_memo_5,
  NULLIF(USERMEMO6, '') AS user_memo_6,
  NULLIF(REPLACE(HEALTHWARNINGS, '��', '\r\n'), '') AS health_warnings,
  NULLIF(TREATMENTREMINDER, '') AS treatment_reminder_notes,
  NULLIF(NOTES, '') AS notes,
  NUllIF(convert_to_integer(THIRDPARTYNUMBER), 0) AS contact_id,
  NULLIF(FINANCIALWARNINGS, '') AS financial_warnings,

  CASE
    WHEN strpos(FILECREATEDATE, '/') > 0
      THEN to_char(
        convert_to_date(FILECREATEDATE, 'DD/MM/YYYY'),
        'YYYY-MM-DD'
      )
    ELSE NULLIF(NULLIF(FILECREATEDATE, ''), 'NULL')
  END AS created_at,

  CASE
    WHEN strpos(LASTVISITDATE, '/') > 0
      THEN to_char(
        convert_to_date(LASTVISITDATE, 'DD/MM/YYYY'),
        'YYYY-MM-DD'
      )
    ELSE NULLIF(NULLIF(LASTVISITDATE, ''), 'NULL')
  END AS last_visit_at,
  convert_to_integer(TOTALBILLED) AS total_invoiced,
  convert_to_integer(TOTALPAID) AS total_paid,

  CASE
    WHEN strpos(LASTUPDATEDATE, '/') > 0
      THEN to_char(
        convert_to_date(LASTUPDATEDATE, 'DD/MM/YYYY'),
        'YYYY-MM-DD'
      )
    ELSE NULLIF(NULLIF(LASTUPDATEDATE, ''), 'NULL')
  END AS updated_at,

  CASE
    WHEN strpos(LASTINVOICEDATE, '/') > 0
      THEN to_char(
        convert_to_date(LASTINVOICEDATE, 'DD/MM/YYYY'),
        'YYYY-MM-DD'
      )
    ELSE NULLIF(NULLIF(LASTINVOICEDATE, ''), 'NULL')
  END AS last_invoice_date,

  CASE
    WHEN strpos(LASTPAYMENTDATE, '/') > 0
      THEN to_char(
        convert_to_date(LASTPAYMENTDATE, 'DD/MM/YYYY'),
        'YYYY-MM-DD'
      )
    ELSE NULLIF(NULLIF(LASTPAYMENTDATE, ''), 'NULL')
  END AS last_payment_date,
  CASE WHEN INACTIVEFLAG = -1
    THEN TRUE
    ELSE FALSE
  END AS is_inactive,
  NULLIF(APPCODE, '') AS communication_preference,
  CASE
    WHEN strpos(NEWPATIENTDATE, '/') > 0
      THEN to_char(
        convert_to_date(NEWPATIENTDATE, 'DD/MM/YYYY'),
        'YYYY-MM-DD'
      )
    ELSE NULLIF(NULLIF(NEWPATIENTDATE, ''), 'NULL')
  END AS new_patient_date,
  NULLIF(DVAFILENO, '') AS dva_card_number
FROM PBPATMAS
WHERE convert_to_integer(PATNUMBER) < 99000
`;

export class PatientSourceEntity extends BaseSourceEntity<
  IOasisPatient,
  IOasisPatientTranslations,
  IOasisPatientFilters
> {
  sourceEntity = PATIENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_SOURCE_QUERY;
  verifySourceFn = isOasisPatient;

  migrationDestinations = [
    PATIENT_CLINICAL_NOTE_DESTINATION_ENTITY.metadata.key,
    PATIENT_INTERACTION_DESTINATION_ENTITY.metadata.key,
    PATIENT_DESTINATION_ENTITY.metadata.key,
    PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY.metadata.key,
    PATIENT_INVOICE_DESTINATION_ENTITY.metadata.key,
    PATIENT_CLINICAL_CHART_DESTINATION_ENTITY.metadata.key,
    PATIENT_DOCUMENTS_DESTINATION_ENTITY.metadata.key,
    PATIENT_XRAYS_DESTINATION_ENTITY.metadata.key,
    PATIENT_RELATIONSHIP_DESTINATION_ENTITY.metadata.key,
    PATIENT_APPOINTMENT_DESTINATION_ENTITY.metadata.key,
    EXISTING_PATIENT_DESTINATION_ENTITY.metadata.key,
  ];

  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    transformPatientResults,
  ]);

  translate(
    data: IOasisPatient,
    _timezone: Timezone
  ): IOasisPatientTranslations {
    return {
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    };
  }

  getSourceRecordId(data: IOasisPatient): number {
    return data.id;
  }

  getSourceLabel(data: IOasisPatient): string {
    return `${data.id} - ${data.firstName} ${data.lastName}`;
  }

  getFilterData(
    _data: IOasisPatient,
    _timezone: Timezone
  ): IOasisPatientFilters {
    return {};
  }
}

function transformPatientResults(rows: IOasisPatientResult[]): IOasisPatient[] {
  return rows.map((row) => {
    let gender = Gender.NotSpecified;
    switch (row.gender) {
      case 'F':
      case 'f':
        gender = Gender.Female;
        break;
      case 'M':
        gender = Gender.Male;
        break;
      default:
        break;
    }

    return {
      ...row,
      gender,
    };
  });
}
