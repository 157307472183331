import { BigQueryTable } from '../../big-query-tables';
import {
  BrandQueryScopeConfig,
  DateRangeQueryScopeConfig,
  PracticeQueryScopeConfig,
} from '../../query-scope-config/query-scope-configs';
import { BaseFactMeasures } from '../base-measures';
import { MeasureLinkFactory, MeasurePath } from '../data-accessor-factory';
import { AppointmentDimensionMeasures } from '../dimensions/appointment-dimension';
import { BrandDimensionMeasures } from '../dimensions/brand-dimension';
import { InvoiceDimensionMeasures } from '../dimensions/invoice-dimension';
import { PatientDimensionMeasures } from '../dimensions/patient-dimension';
import { PracticeDimensionMeasures } from '../dimensions/practice-dimension';
import { TreatmentPlanDimensionMeasures } from '../dimensions/treatment-plan-dimension';
import { TreatmentStepDimensionMeasures } from '../dimensions/treatment-step-dimension';
import { CanBeChartedProperty } from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';

export abstract class BaseTreatmentStepEventFactMeasures extends BaseFactMeasures {
  scopes = [
    new BrandQueryScopeConfig(false, 'brandRef.referenceValue'),
    new PracticeQueryScopeConfig(false, 'practiceRef.referenceValue'),
    new DateRangeQueryScopeConfig(),
  ];

  get appointment(): AppointmentDimensionMeasures {
    return new AppointmentDimensionMeasures(
      BigQueryTable.Appointments,
      'appointment',
      {
        sourceJoinKey: MeasurePath.docRef('appointmentRef'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get treatmentPlan(): TreatmentPlanDimensionMeasures {
    return new TreatmentPlanDimensionMeasures(
      BigQueryTable.TreatmentPlans,
      'treatmentPlan',
      {
        sourceJoinKey: MeasurePath.docRef('treatmentPlanRef'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get treatmentStep(): TreatmentStepDimensionMeasures {
    return new TreatmentStepDimensionMeasures(
      BigQueryTable.TreatmentSteps,
      'treatmentStep',
      {
        sourceJoinKey: MeasurePath.docRef('treatmentStepRef'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get brand(): BrandDimensionMeasures {
    return new BrandDimensionMeasures(BigQueryTable.Brands, 'brand', {
      sourceJoinKey: MeasurePath.docRef('brandRef'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get invoice(): InvoiceDimensionMeasures {
    return new InvoiceDimensionMeasures(BigQueryTable.Invoices, 'invoice', {
      sourceJoinKey: MeasurePath.docRef('invoiceRef'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get practice(): PracticeDimensionMeasures {
    return new PracticeDimensionMeasures(BigQueryTable.Practices, 'practice', {
      sourceJoinKey: MeasurePath.docRef('practiceRef'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get patient(): PatientDimensionMeasures {
    return new PatientDimensionMeasures(BigQueryTable.Patients, 'patient', {
      sourceJoinKey: MeasurePath.docRef('patientRef'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get patientLink(): CanBeChartedProperty {
    const brand = this.brand.measureRef('slug');
    const patient = this.patient.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.link(
      {
        id: 'patient.link',
        label: 'Patient Link',
        summary: 'Principle link for the given patient',
      },
      patient,
      this.buildQuery()
        .mergeJoins(this.brand.query.joins)
        .mergeJoins(this.patient.query.joins)
        .attributes([brand.attributePath, patient.attributePath])
        .get(),
      brand,
      MeasureLinkFactory.patient
    );
  }

  get appointmentLink(): CanBeChartedProperty {
    const brand = this.brand.measureRef('slug');
    const patient = this.patient.measureRef(MeasurePath.docRef('ref'));
    const appointment = this.appointment.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.link(
      {
        id: 'appointment.link',
        label: 'Appointment Link',
        summary: 'Principle link for the given appointment',
      },
      appointment,
      this.buildQuery()
        .mergeJoins(this.brand.query.joins)
        .mergeJoins(this.patient.query.joins)
        .mergeJoins(this.appointment.query.joins)
        .attributes([
          brand.attributePath,
          patient.attributePath,
          appointment.attributePath,
        ])
        .get(),
      brand,
      MeasureLinkFactory.appointment
    );
  }

  get treatmentPlanLink(): CanBeChartedProperty {
    const brand = this.brand.measureRef('slug');
    const patient = this.patient.measureRef(MeasurePath.docRef('ref'));
    const treatmentPlan = this.treatmentPlan.measureRef(
      MeasurePath.docRef('ref')
    );
    return MeasurePropertyFactory.link(
      {
        id: 'treatmentPlan.link',
        label: 'Treatment Plan Link',
        summary: 'Principle link for the given treatment plan',
      },
      treatmentPlan,
      this.buildQuery()
        .mergeJoins(this.brand.query.joins)
        .mergeJoins(this.patient.query.joins)
        .mergeJoins(this.treatmentPlan.query.joins)
        .attributes([
          brand.attributePath,
          patient.attributePath,
          treatmentPlan.attributePath,
        ])
        .get(),
      brand,
      MeasureLinkFactory.treatmentPlan
    );
  }

  get invoiceLink(): CanBeChartedProperty {
    const brand = this.brand.measureRef('slug');
    const patient = this.patient.measureRef(MeasurePath.docRef('ref'));
    const invoice = this.invoice.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.link(
      {
        id: 'invoice.link',
        label: 'Invoice Link',
        summary: 'Principle link for the given invoice',
      },
      invoice,
      this.buildQuery()
        .mergeJoins(this.brand.query.joins)
        .mergeJoins(this.patient.query.joins)
        .mergeJoins(this.invoice.query.joins)
        .attributes([
          brand.attributePath,
          patient.attributePath,
          invoice.attributePath,
        ])
        .get(),
      brand,
      MeasureLinkFactory.invoice
    );
  }
}
