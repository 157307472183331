import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  CurrentPracticeScope,
  GapStoreService,
} from '@principle-theorem/ng-principle-shared';
import { Practice } from '@principle-theorem/principle-core';
import {
  InteractionType,
  SchedulingAlertTargetType,
  type IPractice,
  type ISchedulingAlert,
  IScheduleSummaryEventable,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  initFirestoreModel,
  multiMap,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-scheduling-alerts',
    templateUrl: './scheduling-alerts.component.html',
    styleUrls: ['./scheduling-alerts.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SchedulingAlertsComponent {
  schedulingAlerts$: Observable<ISchedulingAlert[]>;
  practice$: Observable<WithRef<IPractice>>;

  constructor(
    public gapsStore: GapStoreService,
    private _practiceScope: CurrentPracticeScope
  ) {
    this.practice$ = this._practiceScope.doc$.pipe(filterUndefined());
    this.schedulingAlerts$ = this.initSchedulingAlerts$();
  }

  initSchedulingAlerts$(): Observable<ISchedulingAlert[]> {
    return combineLatest([
      this.practice$.pipe(
        switchMap((practice) => Practice.activeSchedulingAlerts$(practice))
      ),
      this.gapsStore.filteredGaps$.pipe(
        multiMap((gap) => this._gapToAlert(gap))
      ),
    ]).pipe(map(([alerts, gaps]) => [...alerts, ...gaps]));
  }

  private _gapToAlert(gap: IScheduleSummaryEventable): ISchedulingAlert {
    return {
      title: 'Gap',
      description: '',
      type: InteractionType.Gap,
      targetType: SchedulingAlertTargetType.Gap,
      deadline: gap.event.from,
      ...initFirestoreModel(),
      gap,
    };
  }
}
