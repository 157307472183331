import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  AccountCreditReservedForSelectorDialogComponent,
  IAccountCreditReservedForSelectorDialogRequest,
  IAccountCreditReservedForSelectorDialogResponse,
} from './account-credit-reserved-for-selector-dialog.component';

@Injectable()
export class AccountCreditReservedForSelectorDialogService {
  constructor(private _dialog: MatDialog) {}

  async open(
    data: IAccountCreditReservedForSelectorDialogRequest
  ): Promise<IAccountCreditReservedForSelectorDialogResponse | undefined> {
    return this._dialog
      .open<
        AccountCreditReservedForSelectorDialogComponent,
        IAccountCreditReservedForSelectorDialogRequest,
        IAccountCreditReservedForSelectorDialogResponse
      >(
        AccountCreditReservedForSelectorDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
  }
}
