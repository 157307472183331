import { initVersionedSchema, toTextContent } from '@principle-theorem/editor';
import { Interaction } from '@principle-theorem/principle-core';
import {
  FailedDestinationEntityRecord,
  IHasSourceIdentifier,
  ITranslationMap,
  InteractionType,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IInteractionV2,
  type IPatient,
  type IPracticeMigration,
  type IStaffer,
  IDestinationEntityJobRunOptions,
} from '@principle-theorem/principle-core/interfaces';
import { snapshotCombineLatest, type WithRef } from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  BasePatientInteractionDestinationEntity,
  IBasePatientInteractionJobData,
  IPatientInteractionMigrationData,
} from '../../../destination/entities/patient-interactions';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  IPraktikaPatient,
  PatientSourceEntity,
} from '../../source/entities/patient';
import {
  PatientClinicalNoteSourceEntity,
  PraktikaClinicalNoteType,
} from '../../source/entities/patient-clinical-notes';
import {
  PatientCommunicationSourceEntity,
  PraktikaTemplateType,
  type IPraktikaPatientCommunication,
} from '../../source/entities/patient-communication';
import { PraktikaStafferMappingHandler } from '../mappings/staff';
import { buildClinicalNotes } from './patient-clinical-notes';
import { PatientDestinationEntity } from './patients';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';

interface IPatientInteractionJobData
  extends IBasePatientInteractionJobData<IPraktikaPatient> {
  staff: WithRef<ITranslationMap<IStaffer>>[];
}

export class PatientInteractionDestinationEntity extends BasePatientInteractionDestinationEntity<
  IPraktikaPatient,
  IPatientInteractionJobData
> {
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    clinicalNotes: new PatientClinicalNoteSourceEntity(),
    patients: new PatientSourceEntity(),
    patientCommunications: new PatientCommunicationSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  customMappings = {
    staff: new PraktikaStafferMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientInteractionJobData[]> {
    const staff$ = snapshotCombineLatest([
      this.customMappings.staff.getRecords(translationMapHandler),
      translationMapHandler.getByType<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      staff$,
    ]).pipe(
      map(([sourcePatients, staff]) =>
        sourcePatients.map((sourcePatient) => ({
          sourcePatient,
          staff,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientInteractionJobData
  ): Promise<
    | IPatientInteractionMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        `Couldn't resolve patient`
      );
    }

    const sourceInteractions =
      await this.sourceEntities.patientCommunications.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

    const sourceClinicalNotes =
      await this.sourceEntities.clinicalNotes.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

    const generalNotes = await buildClinicalNotes(
      migration,
      sourceClinicalNotes,
      data.staff,
      [PraktikaClinicalNoteType.General]
    );

    const interactions: (IInteractionV2 & IHasSourceIdentifier)[] = [
      ...sourceInteractions.map((interaction) => ({
        sourceIdentifier: interaction.record.uid,
        ...Interaction.init({
          type: getInteractionType(interaction.data.data),
          title: interaction.data.data.summary
            ? [toTextContent(interaction.data.data.summary)]
            : [],
          createdAt: interaction.data.translations.created,
          content: initVersionedSchema(
            interaction.data.data.notes || undefined
          ),
        }),
      })),

      ...generalNotes.map((generalNote) => ({
        sourceIdentifier: generalNote.sourceIdentifier,
        ...Interaction.init({
          type: InteractionType.Note,
          title: [],
          createdAt: generalNote.createdAt,
          content: generalNote.content,
          owner: generalNote.owner,
        }),
      })),
    ];

    return {
      interactions,
      patientRef,
    };
  }
}

function getInteractionType(
  praktikaInteractions: IPraktikaPatientCommunication
): InteractionType {
  switch (praktikaInteractions.type_id) {
    case PraktikaTemplateType.Sms:
      return InteractionType.Sms;
    case PraktikaTemplateType.PhoneCall:
      return InteractionType.Call;
    default:
      return InteractionType.Note;
  }
}
