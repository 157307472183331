import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ReplaySubject } from 'rxjs';

const ICON_PATH = `assets/icons/symbol-defs.svg`;
const ICON_PREFIX = ``;

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'g [prSvgIcon], pattern [prSvgIcon]',
    styleUrls: ['./svg-icon.component.sass'],
    templateUrl: './svg-icon.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SvgIconComponent {
  width$: ReplaySubject<number> = new ReplaySubject(1);
  height$: ReplaySubject<number> = new ReplaySubject(1);
  @Input() icon: string;

  @Input()
  set width(width: number) {
    this.width$.next(width);
  }

  @Input()
  set height(height: number) {
    this.height$.next(height);
  }

  get iconPath(): string {
    if (!ICON_PATH) {
      return ``;
    }
    return `${ICON_PATH}#${ICON_PREFIX}${this.icon}`;
  }
}
