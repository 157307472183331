<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>
<mat-progress-bar *ngIf="loading$ | async" mode="indeterminate" />

<div fxLayout="column" fxLayoutGap="16px" class="wrapper">
  <div
    class="layout-margin"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <h1 class="mat-headline-4">{{ snippetsLabel$ | async }}</h1>
      <div
        class="subheader-row"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <button
          mat-icon-button
          matTooltip="Show / Hide Deleted"
          (click)="toggleDeleted()"
        >
          <mat-icon *ngIf="showDeleted$ | async; else deletedHidden"
            >visibility_off</mat-icon
          >
          <ng-template #deletedHidden>
            <mat-icon>visibility</mat-icon>
          </ng-template>
        </button>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center stretch">
      <button mat-flat-button color="primary" (click)="openSnippetForm()">
        Create Snippet
      </button>
    </div>
  </div>

  <div
    class="snippet-container mat-elevation-z1 layout-margin bg-white"
    fxLayout="row"
  >
    <mat-nav-list
      fxFlex="25"
      class="snippet-nav"
      fxLayout="column"
      fxLayoutAlign="start stretch"
    >
      <ng-container *ngIf="snippets$ | async as snippets">
        <ng-container *ngIf="snippets.length; else addSnippet">
          <ng-container
            *ngFor="let snippet of snippets; trackBy: trackBySnippet"
          >
            <mat-list-item
              *ngIf="!snippet.deleted || (showDeleted$ | async)"
              [ngClass]="{
                selected: (selectedSnippet$ | async) === snippet,
                deleted: snippet.deleted
              }"
              (click)="setSelectedSnippet(snippet)"
              ptIntercomTarget="snippet.link"
            >
              <a matListItemTitle>
                {{ snippet.name }}
              </a>
            </mat-list-item>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #addSnippet>
        <mat-list-item>
          <mat-icon matListItemIcon>add</mat-icon>
          <a matListItemTitle (click)="openSnippetForm()">Add a Snippet</a>
        </mat-list-item>
      </ng-template>
    </mat-nav-list>

    <mat-divider [vertical]="true" />

    <div
      *ngIf="selectedSnippet$ | async as selectedSnippet"
      class="snippet-body layout-padding"
      fxFlex
    >
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h1>{{ selectedSnippet.name }}</h1>
        <pr-content-view
          *ngIf="selectedSnippet.keyword"
          [content]="getSnippetKeyword(selectedSnippet)"
        />

        <div fxLayout="column" fxLayoutAlign="center stretch">
          <button mat-icon-button [matMenuTriggerFor]="snippetMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #snippetMenu="matMenu">
            <ng-container *ngIf="!selectedSnippet.deleted; else deletedMenu">
              <button
                mat-menu-item
                (click)="openSnippetForm(selectedSnippet)"
                ptIntercomTarget="snippet.edit"
              >
                <mat-icon>edit</mat-icon>
                Edit
              </button>
              <button
                mat-menu-item
                color="warn"
                (click)="deleteSnippet(selectedSnippet)"
                ptIntercomTarget="snippet.delete"
              >
                <mat-icon>delete</mat-icon>
                Delete
              </button>
            </ng-container>
            <ng-template #deletedMenu>
              <button
                mat-menu-item
                (click)="undoDelete(selectedSnippet)"
                ptIntercomTarget="snippet.undo-delete"
              >
                <mat-icon>undo</mat-icon>
                Undo Delete
              </button>
            </ng-template>
          </mat-menu>
        </div>
      </div>
      <p class="description">{{ selectedSnippet.description }}</p>
      <pr-content-view [content]="selectedSnippet.body" />
    </div>
  </div>
</div>
