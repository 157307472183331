<pr-eventable-timeline
  [staff]="staff$ | async"
  [dateRange]="range$ | ngrxPush"
  [openingHours]="openingHours$ | ngrxPush"
  [disabled]="true"
  [disableScroll]="true"
  [showRosteredOffStaff]="true"
  [practiceViewSettings]="practiceViewSettings$ | ngrxPush"
  [showGridlines]="true"
  [zoom]="zoom"
  [thickTracks]="false"
  [orientation]="orientation"
/>
