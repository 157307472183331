import { Money } from '@principle-theorem/accounting';
import {
  Transaction,
  TransactionOperators,
} from '@principle-theorem/principle-core';
import {
  TransactionProvider,
  TransactionType,
  isHealthPointTransactionExtendedData,
  type IHealthPointTransactionExtendedData,
  type IInvoice,
  type IPractice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import {
  isHealthPointTransactionCompleteCallbackData,
  type IBaseTransactionCompleteCallbackData,
  type IHealthPointClaimRequestParams,
  type IHealthPointTransactionSuccessBaseCallbackData,
} from '@principle-theorem/tyro';
import { differenceBy, first, sum } from 'lodash';
import { toTyroTransaction } from '../base-transaction';

export async function toHealthPointTransaction(
  invoice: WithRef<IInvoice>,
  practiceRef: DocumentReference<IPractice>,
  request: IHealthPointClaimRequestParams,
  response: IBaseTransactionCompleteCallbackData
): Promise<ITransaction<IHealthPointTransactionExtendedData>> {
  const baseTyroTransaction = await toTyroTransaction(
    TransactionProvider.TyroHealthPoint,
    invoice,
    practiceRef,
    response
  );
  return Transaction.init({
    ...baseTyroTransaction,
    amount: Money.fromCents(getBenefitAmount(response)),
    extendedData: {
      request,
      response,
    },
  });
}

function getBenefitAmount(
  response: IBaseTransactionCompleteCallbackData
): number {
  if (!isHealthPointTransactionCompleteCallbackData(response)) {
    return 0;
  }
  return response.healthpointTotalBenefitAmount
    ? parseInt(response.healthpointTotalBenefitAmount, 10)
    : assumeBenefitAmount(response);
}

export async function toHealthPointCancelTransaction(
  invoice: WithRef<IInvoice>,
  practiceRef: DocumentReference<IPractice>,
  request: IHealthPointClaimRequestParams,
  response: IHealthPointTransactionSuccessBaseCallbackData,
  initialTransaction: WithRef<ITransaction<IHealthPointTransactionExtendedData>>
): Promise<ITransaction<IHealthPointTransactionExtendedData>> {
  const benefitAmount = response.healthpointTotalBenefitAmount
    ? parseInt(response.healthpointTotalBenefitAmount, 10)
    : assumeBenefitAmount(response);
  const base = await toTyroTransaction(
    TransactionProvider.TyroHealthPoint,
    invoice,
    practiceRef,
    response,
    TransactionType.Outgoing
  );
  return Transaction.init({
    ...base,
    reference: initialTransaction.reference,
    amount: Money.fromCents(benefitAmount),
    extendedData: {
      request,
      response,
    },
  });
}

function assumeBenefitAmount(
  data: IHealthPointTransactionSuccessBaseCallbackData
): number {
  const rebateAmounts = data.healthpointClaimItems.map((item) =>
    parseInt(item.rebateAmount, 10)
  );
  return sum(rebateAmounts);
}

function filterCompleteHealthPointClaim(
  transactions: WithRef<ITransaction>[]
): ITransaction<IHealthPointTransactionExtendedData>[] {
  return new TransactionOperators(transactions)
    .extendedDataGuard(isHealthPointTransactionExtendedData)
    .completed()
    .incoming()
    .result();
}

function filterCompleteHealthPointClaimCancels(
  transactions: WithRef<ITransaction>[]
): ITransaction<IHealthPointTransactionExtendedData>[] {
  return new TransactionOperators(transactions)
    .extendedDataGuard(isHealthPointTransactionExtendedData)
    .completed()
    .outgoing()
    .result();
}

export function findActiveHealthPointClaim(
  transactions: WithRef<ITransaction>[]
): ITransaction<IHealthPointTransactionExtendedData> | undefined {
  const cancelTransactions =
    filterCompleteHealthPointClaimCancels(transactions);
  const claimTransactions = filterCompleteHealthPointClaim(transactions);
  const activeClaims = differenceBy(
    claimTransactions,
    cancelTransactions,
    'transactionId'
  );
  return first(activeClaims);
}
