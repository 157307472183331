import {
  LabJobAction,
  LabJobStatus,
} from '@principle-theorem/principle-core/interfaces';
import { ILabJobEventFact } from '@principle-theorem/reporting/interfaces';
import { BigQueryTable } from '../../big-query-tables';
import {
  BrandQueryScopeConfig,
  DateRangeQueryScopeConfig,
  PracticeQueryScopeConfig,
} from '../../query-scope-config/query-scope-configs';
import { GroupBy } from '../../querying';
import { BaseFactMeasures, latestEventId } from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import { AppointmentDimensionMeasures } from '../dimensions/appointment-dimension';
import { BrandDimensionMeasures } from '../dimensions/brand-dimension';
import { LabDimensionMeasures } from '../dimensions/lab-dimension';
import { LabJobDimensionMeasures } from '../dimensions/lab-job-dimension';
import { ModelEventDimensionMeasures } from '../dimensions/model-event-dimension';
import { PatientDimensionMeasures } from '../dimensions/patient-dimension';
import { PracticeDimensionMeasures } from '../dimensions/practice-dimension';
import { ReferrerDimensionMeasures } from '../dimensions/referrer-dimension';
import { StafferDimensionMeasures } from '../dimensions/staffer-dimension';
import { TreatmentPlanDimensionMeasures } from '../dimensions/treatment-plan-dimension';
import {
  ComparableProperties,
  MeasureTransformMap,
} from '../measure-properties';
import { QueryFactory } from '../query-factory';

export class LabJobEventFactMeasures
  extends BaseFactMeasures
  implements MeasureTransformMap<ComparableProperties<ILabJobEventFact>>
{
  id = 'labJobEvent';
  readonly table = BigQueryTable.LabJobEvent;
  readonly name = 'Lab Jobs';
  scopes = [
    new BrandQueryScopeConfig(),
    new PracticeQueryScopeConfig(),
    new DateRangeQueryScopeConfig(),
  ];

  get lab(): LabDimensionMeasures {
    return new LabDimensionMeasures(BigQueryTable.Labs, 'lab', {
      sourceJoinKey: MeasurePath.docRef('lab.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get labJob(): LabJobDimensionMeasures {
    return new LabJobDimensionMeasures(BigQueryTable.LabJobs, 'labJob', {
      sourceJoinKey: MeasurePath.docRef('labJob.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get appointment(): AppointmentDimensionMeasures {
    return new AppointmentDimensionMeasures(
      BigQueryTable.Appointments,
      'appointment',
      {
        sourceJoinKey: MeasurePath.docRef('appointment.ref'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get practitioner(): StafferDimensionMeasures {
    return new StafferDimensionMeasures(BigQueryTable.Staff, 'practitioner', {
      sourceJoinKey: MeasurePath.docRef('practitioner.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get treatmentPlan(): TreatmentPlanDimensionMeasures {
    return new TreatmentPlanDimensionMeasures(
      BigQueryTable.TreatmentPlans,
      'treatmentPlan',
      {
        sourceJoinKey: MeasurePath.docRef('treatmentPlan.ref'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get practice(): PracticeDimensionMeasures {
    return new PracticeDimensionMeasures(BigQueryTable.Practices, 'practice', {
      sourceJoinKey: MeasurePath.docRef('practice.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get patient(): PatientDimensionMeasures {
    return new PatientDimensionMeasures(BigQueryTable.Patients, 'patient', {
      sourceJoinKey: MeasurePath.docRef('patient.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get referrer(): ReferrerDimensionMeasures {
    return new ReferrerDimensionMeasures(this.table, 'referrer');
  }

  get brand(): BrandDimensionMeasures {
    return new BrandDimensionMeasures(BigQueryTable.Brands, 'brand', {
      sourceJoinKey: MeasurePath.docRef('brand.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get event(): ModelEventDimensionMeasures<LabJobAction, LabJobStatus> {
    return new ModelEventDimensionMeasures(this.table, 'event', 'Lab Job');
  }

  get latestEvent(): LabJobEventFactMeasures {
    const measures = new LabJobEventFactMeasures();
    measures.id = latestEventId(measures);
    measures._query = QueryFactory.fromTable(measures.table)
      .override(measures._query)
      .latestEvent(measures.table, GroupBy.LabJob)
      .filterSoftDeleted(measures.labJob.measureRef('deleted'))
      .get();
    return measures;
  }
}
