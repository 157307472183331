<pt-alert *ngIf="hasExpired" alert="This media has expired" />

<div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="8px">
  <mat-list class="details" fxFlex>
    <mat-list-item>
      <mat-icon matListItemIcon matTooltip="Created">event</mat-icon>
      <span matListItemTitle>{{
        media.createdAt | moment | amDateFormat: dateFormat
      }}</span>
    </mat-list-item>
    <mat-list-item>
      <mat-icon matListItemIcon matTooltip="Updated">update</mat-icon>
      <span matListItemTitle>{{
        media.updatedAt | moment | amDateFormat: dateFormat
      }}</span>
    </mat-list-item>
  </mat-list>

  <div>
    <button class="download-button" fxFlex mat-icon-button (click)="download()">
      <mat-icon>cloud_download</mat-icon>
    </button>
  </div>
</div>

<mat-divider />

<form [formGroup]="mediaForm" class="layout-padding" fxLayout="column">
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput formControlName="name" [required]="true" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Expiry</mat-label>
    <input
      matInput
      formControlName="expiresAt"
      [matDatepicker]="expiryPicker"
    />
    <mat-datepicker-toggle matIconSuffix [for]="expiryPicker" />
    <mat-datepicker #expiryPicker />
  </mat-form-field>

  <pr-tags-input
    formControlName="tags"
    [tagCol]="tagsCol$ | async"
    [tagType]="tagType"
  />
</form>
