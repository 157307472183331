<div class="layout-margin" fxLayout="column">
  <h1>Operations</h1>

  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
    <div class="chart" fxFlex>
      <h2>Breakdown of Staff Days Off</h2>
      <pr-chart [chartBuilder]="staffTimeChart" />
    </div>
    <div class="chart" fxFlex>
      <h2>Completed Tasks & Lab Jobs</h2>
      <pr-chart [chartBuilder]="operationsChart" />
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
    <div class="chart" fxFlex>
      <h2>Full Time Equivalent</h2>
      <pr-chart [chartBuilder]="fullTimeEquivalentChart" />
    </div>
    <div class="chart" fxFlex>
      <h2>Ratio of Available Chair Hours to Staff Hours</h2>
      <pr-chart [chartBuilder]="fullTimeEquivalentRatioChart" />
    </div>
  </div>

  <pr-table-chart
    fxFlex
    [chart]="tableData"
    [replacementHeaders]="replacementHeaders"
   />
</div>
