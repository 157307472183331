<div class="layout-padding" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row">
    <h3 class="mat-headline-6">Transactions</h3>
    <mat-icon [matTooltip]="description">info</mat-icon>
  </div>
  <pt-download-report
    [data]="csvData$ | async"
    [dateRange]="dateRange$ | async"
    [csvTranslator]="csvTranslator$ | async"
  />
</div>

<table
  mat-table
  [dataSource]="dataSource"
  fxFlex
  matSort
  matSortActive="transactionDate"
  matSortDirection="asc"
>
  <ng-container matColumnDef="transactionDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction Date</th>
    <td mat-cell *matCellDef="let record">
      {{ record.transaction.createdAt | moment | amDateFormat: dateFormat }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="invoiceIssuedAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Issued At</th>
    <td mat-cell *matCellDef="let record">
      {{ record.invoice.issuedAt | moment | amDateFormat: dateFormat }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="invoice">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Invoice/Transaction Reference
    </th>
    <td mat-cell *matCellDef="let record">
      <div fxLayout="column">
        <a [routerLink]="record.invoice | invoiceUrl$ | async" target="_blank">
          {{ record.invoice.reference }}
        </a>
        <small class="mat-caption">({{ record.transaction.reference }})</small>
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="patient">
    <th mat-header-cell *matHeaderCellDef>Patient</th>
    <td mat-cell *matCellDef="let record">
      <a [routerLink]="record.patient | patientUrl$ | async" target="_blank">
        {{ record.patient.name }}
      </a>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="transactionType">
    <th mat-header-cell *matHeaderCellDef>Transaction Type</th>
    <td mat-cell *matCellDef="let record">
      {{ record.transaction.type | splitCamel | titlecase }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="transactionProvider">
    <th mat-header-cell *matHeaderCellDef>
      Type
      <pt-multi-filter-group
        [compact]="true"
        [statusFilters]="providerFilters$ | async"
        [activeStatuses]="selectedProviders$ | async"
        (statusFilterChange)="selectedProviders$.next($event)"
      />
    </th>
    <td mat-cell *matCellDef="let record">
      {{ record.transaction.provider | splitCamel | titlecase }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="transactionProviderType">
    <th mat-header-cell *matHeaderCellDef>
      Card / Manual Type
      <pt-multi-filter-group
        [compact]="true"
        [statusFilters]="providerTypeFilters$ | async"
        [activeStatuses]="selectedProviderTypes$ | async"
        (statusFilterChange)="selectedProviderTypes$.next($event)"
      />
    </th>
    <td mat-cell *matCellDef="let record">
      <ng-container
        *ngIf="record.transaction.extendedData?.transactionType?.name"
      >
        {{ record.transaction.extendedData.transactionType.name }}
      </ng-container>
      <ng-container *ngIf="record.transaction.extendedData?.response?.cardType">
        {{ record.transaction.extendedData.response.cardType }}
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="accountCreditPaymentType">
    <th mat-header-cell *matHeaderCellDef>
      <pr-value-with-icon>
        Account Credit Payment Type
        <pt-multi-filter-group
          [compact]="true"
          [statusFilters]="accountCreditPaymentTypeFilters$ | async"
          [activeStatuses]="selectedAccountCreditPaymentTypes$ | async"
          (statusFilterChange)="selectedAccountCreditPaymentTypes$.next($event)"
        />
      </pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record">
      <ng-container
        *ngFor="
          let creditPayment of record.accountCreditPaymentTransactions
            | map: getFilteredAccountCreditPayments$ : this
            | async;
          trackBy: trackByAccountCreditPayment
        "
      >
        <pr-value-with-icon
          [ngClass]="{ refund: creditPayment.practitionerAmount < 0 }"
        >
          <div class="transaction-details">
            <small class="mat-caption">
              ({{ creditPayment.payment.transaction | map: getProviderName }})
            </small>
            {{ creditPayment.practitionerAmount | currency }}
          </div>
        </pr-value-with-icon>
      </ng-container>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon>
        <strong>{{ accountCreditPaymentTypeTotal$ | async | currency }}</strong>
      </pr-value-with-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="accountCreditAmount">
    <th mat-header-cell *matHeaderCellDef>
      <pr-value-with-icon>Account Credits</pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record">
      <pr-value-with-icon
        [ngClass]="{ refund: record.summary.accountCreditAmount < 0 }"
      >
        {{ record.summary.accountCreditAmount | currency }}
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon>
        <strong>{{
          'accountCreditAmount' | map: sumAmount$ : this | async | currency
        }}</strong>
      </pr-value-with-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="discountAmount">
    <th mat-header-cell *matHeaderCellDef>
      <pr-value-with-icon>Discounts</pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record">
      <pr-value-with-icon
        [ngClass]="{ refund: record.summary.discountAmount < 0 }"
      >
        {{ record.summary.discountAmount | currency }}
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon>
        <strong>{{
          'discountAmount' | map: sumAmount$ : this | async | currency
        }}</strong>
      </pr-value-with-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="paymentAmount">
    <th mat-header-cell *matHeaderCellDef>
      <pr-value-with-icon>Payments</pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record">
      <pr-value-with-icon
        [ngClass]="{ refund: record.summary.paymentAmount < 0 }"
      >
        {{ record.summary.paymentAmount | currency }}
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon>
        <strong>{{
          'paymentAmount' | map: sumAmount$ : this | async | currency
        }}</strong>
      </pr-value-with-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="practitionerProportionAmount">
    <th mat-header-cell *matHeaderCellDef>
      @if (isAllocated$ | async) {
        <pr-value-with-icon>Practitioner Proportion</pr-value-with-icon>
      } @else {
        <pr-value-with-icon>Unallocated Proportion</pr-value-with-icon>
      }
    </th>
    <td mat-cell *matCellDef="let record">
      <pr-value-with-icon
        [ngClass]="{ refund: record.summary.practitionerProportionAmount < 0 }"
      >
        {{ record.summary.practitionerProportionAmount | currency }}
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <pr-value-with-icon>
        <strong>{{
          'practitionerProportionAmount'
            | map: sumAmount$ : this
            | async
            | currency
        }}</strong>
      </pr-value-with-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
