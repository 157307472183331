import { createAction, props } from '@ngrx/store';
import {
  type HealthPointReconciliationReportResponse,
  type ReconciliationReportResponse,
} from '@principle-theorem/tyro';
import { type IPrincipleReconciliationReportData } from '@principle-theorem/reporting';
import { type SerialisedData } from '@principle-theorem/shared';
import { ReconciliationReportActions } from './query.actions';

interface ISetPrincipleReport {
  report?: IPrincipleReconciliationReportData;
}

export const setPrincipleReport = createAction(
  ReconciliationReportActions.SetPrincipleReport,
  props<SerialisedData<ISetPrincipleReport>>()
);

interface ISetTyroReport {
  report?: ReconciliationReportResponse;
}

export const setTyroReport = createAction(
  ReconciliationReportActions.SetTyroReport,
  props<SerialisedData<ISetTyroReport>>()
);

interface ISetHealthPointReport {
  report?: HealthPointReconciliationReportResponse;
}

export const setHealthPointReport = createAction(
  ReconciliationReportActions.SetHealthPointReport,
  props<SerialisedData<ISetHealthPointReport>>()
);
