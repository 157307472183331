import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { type IFeeSchedule } from '@principle-theorem/principle-core/interfaces';
import { AbstractResolver } from '@principle-theorem/ng-shared';
import { type CollectionReference } from '@principle-theorem/shared';
import { getDoc$, type WithRef } from '@principle-theorem/shared';
import { type Observable, of } from 'rxjs';

@Injectable()
export class FeeScheduleResolver extends AbstractResolver<
  WithRef<IFeeSchedule>
> {
  static readonly resolverKey: string = 'feeSchedule';

  getItemById(
    id: string,
    route: ActivatedRouteSnapshot
  ): Observable<WithRef<IFeeSchedule> | undefined> {
    const collection = this._findInheritedRouteData<
      CollectionReference<IFeeSchedule>
    >(route, 'feeScheduleCollection');
    if (!collection) {
      return of(undefined);
    }
    return getDoc$<IFeeSchedule>(collection, id);
  }
}
