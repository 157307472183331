import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const ITEM_CODE_RESOURCE_TYPE = 'itemCodes';

export const ITEM_CODE_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Item Code List',
    description: '',
    idPrefix: ITEM_CODE_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface IOasisItemCode {
  id: string;
  description: string;
  descriptionExtended?: string;
  rootItemCode?: string;
  treatmentGroupId?: number;
  hasGst: boolean;
  isDiscount: boolean;
}

export function isOasisItemCode(item: unknown): item is IOasisItemCode {
  return TypeGuard.interface<IOasisItemCode>({
    id: isString,
    description: isString,
    descriptionExtended: TypeGuard.nilOr(isString),
    rootItemCode: TypeGuard.nilOr(isString),
    treatmentGroupId: TypeGuard.nilOr(isNumber),
    hasGst: isBoolean,
    isDiscount: isBoolean,
  })(item);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOasisItemCodeTranslations {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOasisItemCodeFilters {}

const ITEM_CODE_SOURCE_QUERY = `
SELECT
	REGEXP_REPLACE(SKEY, 'ITEMNUMBE', '') AS id,
	F1 AS description,
	NULLIF(F2, '') AS description_extended,
	NULLIF(F14, '') AS root_item_code,
	convert_to_integer(NULLIF(F19, '')) AS treatment_group_id,
	convert_to_boolean(F21) AS has_gst,
	convert_to_boolean(F22) AS is_discount
FROM SYTBLENT
WHERE SKEY LIKE 'ITEMNUMBE%'
`;

export class ItemCodeSourceEntity extends BaseSourceEntity<
  IOasisItemCode,
  IOasisItemCodeTranslations,
  IOasisItemCodeFilters
> {
  sourceEntity = ITEM_CODE_SOURCE_ENTITY;
  entityResourceType = ITEM_CODE_RESOURCE_TYPE;
  sourceQuery = ITEM_CODE_SOURCE_QUERY;
  verifySourceFn = isOasisItemCode;

  translate(
    _data: IOasisItemCode,
    _timezone: Timezone
  ): IOasisItemCodeTranslations {
    return {};
  }

  getSourceRecordId(data: IOasisItemCode): string {
    return this.sanitiseSourceId(data);
  }

  sanitiseSourceId(data: IOasisItemCode): string {
    return data.id.replace(/\//g, 'slash');
  }

  getSourceLabel(data: IOasisItemCode): string {
    return `${data.id} - ${data.description}`;
  }

  getFilterData(
    _data: IOasisItemCode,
    _timezone: Timezone
  ): IOasisItemCodeFilters {
    return {};
  }
}
