import { IBaseMigrationStaffer } from '../../../interfaces';
import { StaffToUserMappingHandler } from '../../../mappings/staff-to-user';
import {
  ICorePracticeProvider,
  ProviderSourceEntity,
} from '../../source/entities/providers';

export class CorePracticeStaffToUserMappingHandler extends StaffToUserMappingHandler<
  ICorePracticeProvider,
  ProviderSourceEntity
> {
  override sourceEntity = new ProviderSourceEntity();
  translateFn = (record: ICorePracticeProvider): IBaseMigrationStaffer => {
    return {
      id: record.id,
      name: `${record.firstName} ${record.providerName}`,
      providerNo: record.providerNumber,
    };
  };
}
