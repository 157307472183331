import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { type ITyroTerminal } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import {
  type IPairTyroTerminalDialogRequest,
  PairTyroTerminalDialogComponent,
} from './pair-tyro-terminal-dialog.component';

@Injectable()
export class PairTyroTerminalService {
  constructor(private _dialog: MatDialog) {}

  async pairTerminal(terminal: WithRef<ITyroTerminal>): Promise<void> {
    const data: IPairTyroTerminalDialogRequest = { terminal };
    return this._dialog
      .open<
        PairTyroTerminalDialogComponent,
        IPairTyroTerminalDialogRequest,
        undefined
      >(PairTyroTerminalDialogComponent, DialogPresets.small({ data }))
      .afterClosed()
      .toPromise();
  }
}
