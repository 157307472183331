import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ComponentsModule } from '../../components/components.module';
import { BrandsModule } from '../settings/brands/brands.module';
import { PracticesModule } from '../settings/practices/practices.module';
import { InfrastructureTestsComponent } from './infrastructure-tests.component';

@NgModule({
  declarations: [InfrastructureTestsComponent],
  imports: [
    NgPrincipleSharedModule,
    ComponentsModule,
    NgSharedModule,
    NgMaterialModule,
    RouterModule,
    PracticesModule,
    BrandsModule,
  ],
})
export class TestPagesModule {}
