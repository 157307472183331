<pr-gap-shortlist-item-container
  *ngrxLet="appointment$ as appointment"
  [appointment]="appointment"
>
  <div class="flex flex-1 flex-col">
    <pr-gap-shortlist-item-header
      [waitListItem]="appointment"
      [isShortlisted]="false"
      (addToShortlist)="addToShortlist()"
      (confirmForGap)="confirmForGap()"
      (openWaitlistConfiguration)="openWaitlistConfiguration()"
      (showSchedulingNotes)="showSchedulingNotes()"
    />
    <div class="px-4 py-2">
      <pr-gap-shortlist-item-appointment-info
        [appointment]="appointment"
        [score]="score$ | async"
      />
    </div>
  </div>
</pr-gap-shortlist-item-container>
