import {
  RawInlineNodes,
  initVersionedSchema,
  toTextContent,
} from '@principle-theorem/editor';
import {
  AppointmentStatus,
  EventType,
  IAppointment,
  ICalendarEvent,
  ICalendarEventSchedule,
  ICalendarEventTemplate,
  IPatient,
  IPractice,
  IScheduleSummary,
  IScheduleSummaryEvent,
  IStaffer,
  ParticipantType,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  CustomRecurrenceFrequency,
  DayOfWeek,
  DocumentReference,
  EndingType,
  Frequency,
  INamedDocument,
  ITimePeriod,
  Properties,
  Time24hrType,
  Timezone,
  WithRef,
  getEnumValues,
  mergeDayAndTime,
  toISODate,
  toTimePeriod,
  toTimestamp,
} from '@principle-theorem/shared';
import {
  MockDocRef,
  MockNamedDocument,
  MockReffable,
  MockWithRef,
} from '@principle-theorem/testing';
import { startCase } from 'lodash';
import * as moment from 'moment-timezone';

export function MockCalendarEvent(
  title: RawInlineNodes,
  range: ITimePeriod,
  type: EventType = EventType.Meeting,
  staffer: INamedDocument<IStaffer> = MockNamedDocument<IStaffer>(),
  overrides: Partial<ICalendarEvent> = {}
): WithRef<ICalendarEvent> {
  const participants = [{ ...staffer, type: ParticipantType.Staffer }];
  const participantRefs = participants.map((participant) => participant.ref);

  const calendarEvent: ICalendarEvent = {
    createdAt: toTimestamp(),
    deleted: false,
    event: {
      from: toTimestamp(range.from),
      to: toTimestamp(range.to),
      type,
      creator: staffer,
      organiser: staffer,
      participants,
      participantRefs,
      practice: MockNamedDocument<IPractice>('McMahons Point'),
    },
    eventTags: [],
    eventHistory: [],
    isBlocking: true,
    locked: false,
    notes: initVersionedSchema(),
    title,
    updatedAt: toTimestamp(),
    ...overrides,
  };
  return MockReffable(calendarEvent);
}

export function MockCalendarEventSchedule(
  staffer: DocumentReference<IStaffer>,
  practice: DocumentReference<IPractice>,
  from: Time24hrType,
  to: Time24hrType,
  eventType: EventType
): WithRef<ICalendarEventSchedule> {
  const date = moment('2020-01-01T00:00:00.000Z');
  const range = toTimePeriod(
    mergeDayAndTime(date, from),
    mergeDayAndTime(date, to)
  );
  const scheduleTemplate = MockCalendarEvent(
    [toTextContent(startCase(eventType))],
    range,
    eventType,
    { name: 'Staffer Name', ref: staffer }
  );

  const scheduleEvent: ICalendarEventTemplate = {
    ...scheduleTemplate,
    event: {
      type: eventType,
      practice: { name: 'Practice Name', ref: practice },
      participants: [
        {
          name: 'Staffer Name',
          ref: staffer,
          type: ParticipantType.Staffer,
        },
      ],
      participantRefs: [staffer],
    },
    isBlocking: true,
  };

  return MockWithRef<ICalendarEventSchedule>({
    item: scheduleEvent,
    createdAt: toTimestamp(range.from),
    updatedAt: toTimestamp(range.from),
    deleted: false,
    modifiers: [],
    scheduleTime: {
      from,
      to,
    },
    pattern: {
      customFrequencyType: CustomRecurrenceFrequency.Weekly,
      daysOfMonth: [],
      daysOfWeek: getEnumValues(DayOfWeek),
      endingType: EndingType.Never,
      frequencyType: Frequency.Custom,
      monthsOfYear: [],
      seperationCount: 1,
      weeksOfMonth: [],
    },
  });
}

export class ScheduleSummaryMock
  extends BaseFirestoreMock
  implements Properties<IScheduleSummary>
{
  practice = MockDocRef<IPractice>();
  staffer = MockDocRef<IStaffer>();
  timezone = Timezone.Greenwich;
  day = toISODate('2020-01-01');
  gaps = [];
  events = [
    {
      ...appointmentSummaryMock(
        moment('2020-01-01T09:00:00.000Z'),
        moment('2020-01-01T09:45:00.000Z'),
        true
      ),
    },
    {
      ...appointmentSummaryMock(
        moment('2020-01-01T10:00:00.000Z'),
        moment('2020-01-01T10:45:00.000Z'),
        true
      ),
    },
    {
      ...appointmentSummaryMock(
        moment('2020-01-01T13:00:00.000Z'),
        moment('2020-01-01T13:45:00.000Z'),
        true
      ),
    },
    {
      ...calendarEventSummaryMock(
        moment('2020-01-01T12:00:00.000Z'),
        moment('2020-01-01T12:30:00.000Z'),
        EventType.Break,
        true
      ),
    },
    {
      ...calendarEventSummaryMock(
        moment('2020-01-01T09:00:00.000Z'),
        moment('2020-01-01T17:00:00.000Z'),
        EventType.RosteredOn,
        false
      ),
    },
  ];
}

export function calendarEventSummaryMock(
  from: moment.Moment = moment('2020-01-01T12:00:00.000Z'),
  to: moment.Moment = moment('2020-01-01T12:30:00.000Z'),
  type: EventType = EventType.Meeting,
  isBlocking = true
): IScheduleSummaryEvent<ICalendarEvent> {
  return {
    ref: MockDocRef<ICalendarEvent>(),
    isBlocking,
    event: {
      type,
      from: toTimestamp(from),
      to: toTimestamp(to),
      participantRefs: [MockDocRef<IStaffer>()],
      participants: [
        {
          type: ParticipantType.Staffer,
          ...MockNamedDocument<IStaffer>('Poopy Bum'),
        },
      ],
      practice: MockNamedDocument<IPractice>(),
    },
    metadata: {
      label: type,
      tags: [],
      pinnedNotes: [],
      scheduleRef: MockDocRef<ICalendarEventSchedule>(),
    },
  };
}

export function appointmentSummaryMock(
  from: moment.Moment = moment('2020-01-01T12:00:00.000Z'),
  to: moment.Moment = moment('2020-01-01T12:30:00.000Z'),
  isBlocking = true,
  status: AppointmentStatus = AppointmentStatus.Confirmed
): IScheduleSummaryEvent<IAppointment> {
  return {
    ref: MockDocRef<IAppointment>(),
    isBlocking,
    event: {
      type: EventType.Appointment,
      from: toTimestamp(from),
      to: toTimestamp(to),
      participantRefs: [MockDocRef<IStaffer>()],
      participants: [
        {
          type: ParticipantType.Staffer,
          ...MockNamedDocument<IStaffer>('Poopy Bum'),
        },
        {
          type: ParticipantType.Patient,
          ...MockNamedDocument<IPatient>('Poopy Bum Patient'),
        },
      ],
      creator: {
        name: 'Staffer Name',
        ref: MockDocRef<IStaffer>(),
      },
      organiser: {
        name: 'Staffer Name',
        ref: MockDocRef<IStaffer>(),
      },
      practice: MockNamedDocument<IPractice>(),
    },
    metadata: {
      label: EventType.Appointment,
      tags: [],
      pinnedNotes: [],
      status,
      dependencies: [],
      treatmentPlanName: 'Treatment Plan Name',
      treatmentStepName: 'Treatment Step Name',
      patientRef: MockDocRef<IPatient>(),
    },
  };
}
