<ng-container *ngrxLet="calendarEvent$ as calendarEvent">
  <pt-dynamic-sidebar-header class="flex items-center justify-end gap-4">
    <button mat-stroked-button (click)="deleteEvent()">
      <span>Delete Event</span>
    </button>
  </pt-dynamic-sidebar-header>

  <div class="flex h-full flex-col justify-between">
    <div class="p-4">
      <pr-content-container>
        <div class="flex flex-col gap-4">
          <ng-container *ngrxLet="calendarEvent$ as calendarEvent">
            <h2 class="!mb-0">
              {{ calendarEvent.event.type | splitCamel | titlecase }}
              Details
            </h2>

            <div class="flex flex-col gap-2">
              <div class="flex items-center gap-2">
                <mat-icon matTooltip="Date">calendar_today</mat-icon>
                <span>{{ dateSummary$ | async }}</span>
              </div>
              <div class="flex items-center gap-2">
                <mat-icon matTooltip="Time">schedule</mat-icon>
                <span>
                  <pr-formatted-event-field
                    field="from"
                    [event]="calendarEvent"
                    [format]="timeFormat"
                  />
                  -
                  <pr-formatted-event-field
                    field="to"
                    [event]="calendarEvent"
                    [format]="timeFormat"
                  />
                  ({{ calendarEvent | map: getDuration }})
                </span>
              </div>

              <div class="flex items-center gap-2">
                <mat-icon matTooltip="Event type">
                  {{ calendarEvent.event.type | map: getIcon }}
                </mat-icon>
                <span>{{
                  calendarEvent.event.type | splitCamel | titlecase
                }}</span>
              </div>

              <ng-container *ngrxLet="namedDocsToTags.tags$ as tags">
                <div *ngIf="tags.length" class="flex items-center gap-2">
                  <mat-icon matTooltip="Tags">sell</mat-icon>
                  <span>
                    <pr-inline-tags-display [tags]="tags" />
                  </span>
                </div>
              </ng-container>

              <div
                *ngIf="calendarEvent.isBlocking"
                class="flex items-center gap-2"
              >
                <mat-icon matTooltip="Blocking event">event_busy</mat-icon>
                <span>Other events cannot be scheduled at the same time</span>
              </div>

              <div *ngrxLet="participants$ as participants" class="flex gap-2">
                <mat-icon matTooltip="Event participants">group</mat-icon>
                <div class="flex flex-col gap-2">
                  <div
                    *ngFor="
                      let participant of participants;
                      trackBy: trackByParticipant
                    "
                    class="flex items-center gap-2"
                  >
                    <pt-user-icon
                      [name]="participant.name"
                      [src]="participant | profileImage$ | ngrxPush"
                      [diameter]="30"
                      [matTooltip]="participant.name"
                    />
                    <span>{{ participant.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <pr-calendar-event-form
            [hiddenFields]="hiddenFields$ | async"
            (submitted)="save($event)"
            [calendarEvent]="formEventData$ | async"
            #calendarEventForm="prCalendarEventForm"
          />
        </div>
      </pr-content-container>
    </div>

    <pt-dynamic-sidebar-footer class="flex justify-end">
      <pt-loader-button
        type="flat"
        [loading]="calendarEventForm.saving$ | async"
        [disabled]="calendarEventForm.form.invalid"
        color="primary"
        (clicked)="calendarEventForm.submit()"
      >
        Save
      </pt-loader-button>
    </pt-dynamic-sidebar-footer>
  </div>
</ng-container>
