import {
  IContextBuilder,
  IReferralScopeData,
  IReferralTemplateContext,
} from '@principle-theorem/principle-core/interfaces';
import { NameHelpers } from '../../models/patient/name-helpers';
import { PatientContextBuilder } from './patient-context';
export class ReferralContextBuilder
  implements IContextBuilder<IReferralTemplateContext>
{
  private _patientContextBuilder: PatientContextBuilder;

  constructor(private _data: IReferralScopeData) {
    this._patientContextBuilder = new PatientContextBuilder(this._data);
  }

  build(): IReferralTemplateContext {
    return {
      ...this._patientContextBuilder.build(),
      referralProviderFullName: NameHelpers.fullName(this._data.contact.name),
      referralProviderFirstName: NameHelpers.firstName(this._data.contact.name),
      referralClinicName: '', // TODO: Use Organisation/Parent Contact Name
      referralClinicAddress: this._data.contact.address ?? '',
    };
  }
}
