import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  initVersionedSchema,
  MixedSchema,
  toMentionContent,
  toTextContent,
  type VersionedSchema,
} from '@principle-theorem/editor';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  isDisabled$,
  NG_SHARED_CONFIG,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
  type INgSharedConfig,
} from '@principle-theorem/ng-shared';
import {
  addInteractions,
  Interaction,
  stafferToNamedDoc,
  toMention,
} from '@principle-theorem/principle-core';
import {
  InteractionType,
  MentionResourceType,
  TemplateScope,
  TemplateType,
  type IBrand,
  type IInteractionData,
  type IInteractionV2,
  type IPractice,
  type IStaffer,
  type ITemplatesWithContext,
  type WithTemplateResource,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, Subject, type Observable } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import {
  MentionToTemplates,
  type IPopulatedTemplate,
} from '../contextual-actions/interaction-actions/template-context-resolvers/mention-to-templates';
import { EditorPresetsService } from '../editor/editor-presets.service';
import { versionedSchemaToHtml } from '../editor/version-schema-transform';
import { filterTemplatesWithContextByScopes } from '../sms/sms.component';
import { SendEmailService, type ISendEmailData } from './send-email.service';

interface IEmailFormData {
  subject: string;
  content: MixedSchema;
  practice: WithRef<IPractice>;
}

@Component({
  selector: 'pr-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _sharedConfig: INgSharedConfig = inject(NG_SHARED_CONFIG);
  trackByTemplate = TrackByFunctions.field<IPopulatedTemplate>('name');
  brands$: Observable<WithRef<IBrand>[]>;
  submitting$ = new BehaviorSubject<boolean>(false);
  isDisabled$: Observable<boolean>;
  templates$: Observable<ITemplatesWithContext>;
  emailForm = new TypedFormGroup<IEmailFormData>({
    subject: new TypedFormControl<string>('', Validators.required),
    practice: new TypedFormControl<WithRef<IPractice>>(
      undefined,
      Validators.required
    ),
    content: new TypedFormControl<VersionedSchema>(
      initVersionedSchema(),
      Validators.required
    ),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: WithTemplateResource<IInteractionData>,
    private _organisation: OrganisationService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EmailComponent>,
    private _sendEmailService: SendEmailService,
    private _currentScope: CurrentScopeFacade,
    private _editorPresets: EditorPresetsService
  ) {
    const brand$ = this._currentScope.currentBrand$.pipe(filterUndefined());
    this.brands$ = brand$.pipe(map((brand) => [brand]));
    this.isDisabled$ = isDisabled$(this.emailForm);
    this._currentScope.currentPractice$
      .pipe(filterUndefined(), takeUntil(this._onDestroy$))
      .subscribe((practice) => this.emailForm.patchValue({ practice }));

    this.templates$ = brand$.pipe(
      switchMap((brand) => {
        const mentionToTemplates = new MentionToTemplates(
          this._sharedConfig.appUrl
        );
        return mentionToTemplates.getTemplates(
          data.templateResource,
          brand.ref,
          TemplateType.Html
        );
      }),
      filterUndefined(),
      map((templates) =>
        filterTemplatesWithContextByScopes(templates, [
          TemplateScope.Appointment,
          TemplateScope.Invoice,
        ])
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async submit(): Promise<void> {
    if (!this.emailForm.valid) {
      return;
    }
    this.submitting$.next(true);
    const formData = this.emailForm.getRawValue();
    const error = await this._sendEmailService.send(
      this.data.contact,
      this._toEmailData(formData),
      formData.practice
    );
    if (error) {
      this.submitting$.next(false);
      this._snackBar.open(error.message);
      this.dialogRef.close();
      return;
    }

    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const interaction = this._getEmailInteraction(formData, staffer);
    await addInteractions(this.data, interaction);

    this.submitting$.next(false);
    this._snackBar.open('Email Sent Successfully');
    this.dialogRef.close();
  }

  templateSelected(template: IPopulatedTemplate): void {
    this.emailForm.patchValue({ content: template.content });
  }

  private _toEmailData(formData: IEmailFormData): ISendEmailData {
    const extensions = this._editorPresets.defaultToHTMLExtensions();
    const content = versionedSchemaToHtml(formData.content, extensions);
    return { subject: formData.subject, content };
  }

  private _getEmailInteraction(
    formData: IEmailFormData,
    sender: WithRef<IStaffer>
  ): IInteractionV2 {
    return Interaction.init({
      type: InteractionType.Email,
      title: [
        toMentionContent(toMention(sender, MentionResourceType.Staffer)),
        toTextContent(` sent Email to `),
        toMentionContent(this.data.contact),
      ],
      owner: stafferToNamedDoc(sender),
      content: formData.content,
    });
  }
}
