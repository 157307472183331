import { IResolvedClaimItem } from '@principle-theorem/principle-core';
import { sum } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class ClaimItemsInfoBloc {
  count$: Observable<number>;

  constructor(public items$: Observable<IResolvedClaimItem[]>) {
    this.count$ = this.items$.pipe(map((items) => this._getCount(items)));
  }

  private _getCount(items: IResolvedClaimItem[]): number {
    return sum(items.map((item) => item.claimItem.quantity));
  }
}
