import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { Brand } from '@principle-theorem/principle-core';
import { type ICalendarEvent } from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  deleteDoc,
  filterUndefined,
  isWithRef,
  unserialise$,
  type DocumentReference,
} from '@principle-theorem/shared';
import { of, type Observable } from 'rxjs';
import {
  concatMap,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { CalendarCollectionActions, CalendarEventsActions } from '../actions';
import { CalendarEventsFacade } from '../facades/calendar-events.facade';

@Injectable()
export class CalendarEventsCollectionEffects {
  private _actions$ = inject(Actions);
  private _calendarEventsFacade = inject(CalendarEventsFacade);
  private _currentScopeFacade = inject(CurrentScopeFacade);

  saveEvent$: Observable<DocumentReference> = createEffect(
    () => this._saveCalendarEvent$(),
    { dispatch: false }
  );
  deleteEvent$: Observable<Action> = createEffect(() =>
    this._deleteCalendarEvent$()
  );

  constructor(private _snackBar: MatSnackBar) {}

  private _saveCalendarEvent$(): Observable<DocumentReference<ICalendarEvent>> {
    return this._actions$.pipe(
      ofType(CalendarEventsActions.saveCalendarEvent),
      map((action) => action.calendarEvent),
      unserialise$(),
      switchMap((calendarEvent) => {
        return this._currentScopeFacade.currentBrand$.pipe(
          filterUndefined(),
          take(1),
          switchMap((brand) =>
            addDoc(Brand.calendarEventCol(brand), calendarEvent)
          )
        );
      }),
      tap((docRef) => {
        const message = docRef ? 'Event Created' : 'Failed to Create Event';
        this._snackBar.open(message);
      })
    );
  }

  private _deleteCalendarEvent$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(CalendarEventsActions.deleteCalendarEvent),
      withLatestFrom(this._calendarEventsFacade.selectedCalendarEvent$),
      concatMap(([_, event]) => {
        if (!event || !isWithRef(event)) {
          return of(undefined);
        }
        return deleteDoc(event.ref);
      }),
      map(() => CalendarCollectionActions.deleteCalendarEventSuccess()),
      tap(() => this._snackBar.open('Event Deleted'))
    );
  }
}
