import {
  IInvoice,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, getDoc, patchDoc, snapshot } from '@principle-theorem/shared';
import { OrganisationCache } from '../organisation/organisation-cache';
import { createInvoice } from './create-invoice';
import { createInvoiceForAppointment } from './create-invoice-for-appointment';
import { Invoice } from './invoice';
import { InvoiceInteractionBuilder } from './invoice-interaction-builder';
import { TaxRate } from '@principle-theorem/accounting';

export async function replaceInvoice(
  invoice: WithRef<IInvoice>,
  staffer: WithRef<IStaffer>,
  taxRate: TaxRate
): Promise<void> {
  if (!Invoice.isCancelledInvoice(invoice)) {
    throw new Error(
      `replaceInvoice - Invoice must be cancelled first ${invoice.ref.path}`
    );
  }

  if (Invoice.isReplacedInvoice(invoice)) {
    throw new Error(
      `replaceInvoice - Invoice already replaced: ${invoice.ref.path}`
    );
  }

  const newInvoice = await getNewInvoice(invoice, staffer, taxRate);
  await patchDoc(invoice.ref, {
    cancellation: {
      ...invoice.cancellation,
      replacement: newInvoice.ref,
    },
  });
  const interaction = InvoiceInteractionBuilder.replacedInteraction(
    newInvoice,
    staffer
  );
  await Invoice.addInteraction(invoice, interaction);
}

async function getNewInvoice(
  invoice: WithRef<IInvoice>,
  staffer: WithRef<IStaffer>,
  taxRate: TaxRate
): Promise<WithRef<IInvoice>> {
  const appointment = await snapshot(
    Invoice.getAssociatedAppointment$(invoice)
  );
  if (appointment) {
    const ref = await createInvoiceForAppointment(appointment.ref, taxRate);
    return getDoc(ref);
  }

  const patient = await getDoc(Invoice.patientDocRef(invoice));
  const practice = await OrganisationCache.practices.getDoc(
    invoice.practice.ref
  );
  return createInvoice(patient, practice, staffer);
}
