import {
  ConditionConfiguration,
  toINamedDocuments,
} from '@principle-theorem/principle-core';
import {
  CustomMappingType,
  type IConditionConfiguration,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import {
  XSLXImporterExporter,
  asyncForEach,
  hardDeleteDoc,
  multiSortBy$,
  nameSorter,
  snapshot,
  type INamedDocument,
  type IReffable,
  type WithRef,
  IBlobFilenamePair,
} from '@principle-theorem/shared';
import Fuse from 'fuse.js';
import { first, sortBy } from 'lodash';
import { BaseCustomMappingHandler } from '../base-custom-mapping-handler';
import { CustomMapping } from '../custom-mapping';
import { IBaseMigrationItemCode } from '../interfaces';
import { PracticeMigration } from '../practice-migrations';
import { BaseSourceEntity } from '../source/base-source-entity';
import { TranslationMapHandler } from '../translation-map';
import { ITEM_CODE_TO_CONDITION_DEFAULT_MAPPINGS } from './ada-code-mappings';
import { ItemCodeToConditionToXSLX } from './item-code-to-condition-to-xlsx';
import { XLSXToItemCodeToCondition } from './xlsx-to-item-code-to-condition';

export const ITEM_CODE_TO_CONDITION_CUSTOM_MAPPING_TYPE =
  'itemCodeToConditionMapping';

export const ITEM_CODE_TO_CONDITION_MAPPING = CustomMapping.init({
  metadata: {
    label: 'Item Code To Condition',
    description: `Used for mapping pre-existing Treatment Item Codes to Conditions in Principle.`,
    type: ITEM_CODE_TO_CONDITION_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.DocumentReference,
  labelOverrides: {
    sourceIdentifier: 'Id',
    sourceLabel: 'Item Code',
    destinationIdentifier: 'Condition Name',
  },
});

export abstract class BaseItemCodeToConditionMappingHandler<
  ItemCode extends object,
  SourceEntity extends BaseSourceEntity<ItemCode>,
> extends BaseCustomMappingHandler<IConditionConfiguration> {
  customMapping = ITEM_CODE_TO_CONDITION_MAPPING;
  abstract translateFn: (record: ItemCode) => IBaseMigrationItemCode;
  abstract sourceEntity: SourceEntity;

  async getItemCodes(
    migration: IReffable<IPracticeMigration>
  ): Promise<IBaseMigrationItemCode[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 1000)
      .toPromise();

    return records.map((record) => record.data.data).map(this.translateFn);
  }

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const records = await this.getItemCodes(migration);

    const options = records.map((record) => {
      return {
        label: this._buildLabel(record),
        value: record.id.toString(),
      };
    });

    return sortBy(options, 'label');
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<IConditionConfiguration>[]> {
    return snapshot(
      ConditionConfiguration.all$(migration.configuration.brand).pipe(
        toINamedDocuments(),
        multiSortBy$(nameSorter())
      )
    );
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, itemCodes, translator } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(fileName, itemCodes, translator);
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, itemCodes, translator } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(fileName, itemCodes, translator);
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XLSXToItemCodeToCondition()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const records = await this.getRecords(translationMap);
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));

    const sourceOptions = await this.getSourceOptions(migration);
    const conditionConfigurations = await this.getDestinationOptions(migration);

    await asyncForEach(items, async (item) => {
      const mapTo = item.mapTo;
      const label = `${item.code} - ${item.description}`;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.label === label
      )?.value;
      if (!value || !item.mapTo) {
        return;
      }

      const conditionConfiguration = conditionConfigurations.find(
        (configuration) => configuration.name === mapTo
      );

      if (!conditionConfiguration) {
        // eslint-disable-next-line no-console
        console.error(
          `Mapping error: ${this.customMapping.metadata.label} - Couldn't find condition configuration for item`,
          item
        );
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: conditionConfiguration.ref,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  async autocompleteMappings(
    migration: WithRef<IPracticeMigration>
  ): Promise<void> {
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const itemCodes = await this.getItemCodes(migration);
    const destinationOptions = await this.getDestinationOptions(migration);
    const records = await this.getRecords(translationMap);

    const mappedItemCodes = records
      .filter((record) => !!record.destinationIdentifier)
      .map((record) => record.sourceIdentifier);
    const unmappedItemCodes = itemCodes.filter(
      (option) => !mappedItemCodes.includes(option.id.toString())
    );

    await asyncForEach(unmappedItemCodes, async (record) => {
      const suggestedMappingConfigurationName =
        ITEM_CODE_TO_CONDITION_DEFAULT_MAPPINGS[record.itemCode] ?? undefined;

      const matchingConfiguration = destinationOptions.find(
        (configuration) =>
          configuration.name === suggestedMappingConfigurationName
      );

      const label = this._buildLabel(record);

      if (matchingConfiguration) {
        await this.upsertRecord(
          {
            destinationIdentifier: matchingConfiguration.ref,
            destinationValue: matchingConfiguration.name,
            sourceIdentifier: record.id.toString(),
            sourceLabel: label,
          },
          translationMap
        );
        return;
      }

      const fuse = new Fuse(destinationOptions, {
        keys: ['name'],
        includeScore: true,
        threshold: 0.3,
      });
      const matches = fuse.search(label);
      const match = first(matches)?.item;

      if (!match) {
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: match.ref,
          destinationValue: match.name,
          sourceIdentifier: record.id.toString(),
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    itemCodes: IBaseMigrationItemCode[];
    translator: ItemCodeToConditionToXSLX;
  }> {
    const fileName = this.getFileName();
    const records = await this.sourceEntity
      .getRecords$(migration, 1000)
      .toPromise();

    const options = records
      .map((record) => record.data.data)
      .map((record) => this.translateFn(record));

    const itemCodes = sortBy(options, 'itemCode');

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const translator = new ItemCodeToConditionToXSLX(
      await this.getDestinationOptions(migration),
      await this.getRecords(translationMap),
      ITEM_CODE_TO_CONDITION_DEFAULT_MAPPINGS
    );
    return { fileName, itemCodes, translator };
  }

  private _buildLabel(record: IBaseMigrationItemCode): string {
    return !record.description
      ? record.itemCode
      : `${record.itemCode} - ${record.description}`;
  }
}
