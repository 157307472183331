<pt-breadcrumbs current="Scheduling Alerts" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="wrapper">
  <mat-progress-bar *ngIf="loading$ | async" mode="indeterminate" />
  <ng-container *ngIf="schedulingAlerts$ | async as schedulingAlerts">
    <pr-scheduling-alerts-list
      *ngIf="schedulingAlerts.length; else noSchedulingAlerts"
      [schedulingAlerts]="schedulingAlerts$ | ngrxPush"
    />
  </ng-container>
</div>

<ng-template #noSchedulingAlerts>
  <pr-empty-state
    *ngIf="(loading$ | async) === false"
    image="list"
    title="scheduling alerts"
  />
</ng-template>
