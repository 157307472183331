import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  AuthProvider,
  Layout,
  NgxAuthFirebaseuiRegisterComponent,
} from 'ngx-auth-firebaseui';
import { Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import {
  debounceTime,
  map,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentType } from '@angular/cdk/portal';
import { IWorkspaceSelector } from '../workspace-selector/workspace-selector.component';
import { filterUndefined } from '@principle-theorem/shared';

@Component({
  selector: 'pt-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  providers: AuthProvider[] = [AuthProvider.Google, AuthProvider.Facebook];
  providersLayout: Layout = Layout.COLUMN;
  name$ = new ReplaySubject<string>(1);
  email$ = new ReplaySubject<string>(1);
  registerComponent$ = new ReplaySubject<NgxAuthFirebaseuiRegisterComponent>(1);
  nameCtrl$: Observable<AbstractControl<string> | undefined>;
  emailCtrl$: Observable<AbstractControl<string> | undefined>;
  userIsVerified$: Observable<boolean>;
  logoSrc$ = new ReplaySubject<string>(1);

  @Input() workspaceSelectors: ComponentType<IWorkspaceSelector>[];

  @ViewChild('registerComponent')
  set registerComponent(component: NgxAuthFirebaseuiRegisterComponent) {
    if (component) {
      this.registerComponent$.next(component);
    }
  }

  @Input()
  set name(name: string) {
    if (name) {
      this.name$.next(name);
    }
  }

  @Input()
  set email(email: string) {
    if (email) {
      this.email$.next(email);
    }
  }

  @Input()
  set logoSrc(src: string) {
    if (src) {
      this.logoSrc$.next(src);
    }
  }

  constructor(
    public auth: AuthService,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) {
    this.nameCtrl$ = this.registerComponent$.pipe(
      map((component) => component.registerForm.get('name') ?? undefined)
    );
    this.emailCtrl$ = this.registerComponent$.pipe(
      map((component) => component.registerForm.get('email') ?? undefined)
    );
    this.userIsVerified$ = this.auth.authUser$.pipe(
      filterUndefined(),
      map((user) => user.emailVerified),
      startWith(false)
    );

    combineLatest([this.name$, this.nameCtrl$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([name, nameCtrl]) => {
        if (!nameCtrl) {
          return;
        }
        nameCtrl.setValue(name);
        nameCtrl.disable();
      });

    combineLatest([this.email$, this.emailCtrl$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([email, emailCtrl]) => {
        if (!emailCtrl) {
          return;
        }
        emailCtrl.setValue(email.toLowerCase());
        emailCtrl.disable();
      });

    combineLatest([
      this.emailCtrl$,
      this.emailCtrl$.pipe(
        filterUndefined(),
        switchMap((emailCtrl) => emailCtrl.valueChanges)
      ),
    ])
      .pipe(debounceTime(250), takeUntil(this._onDestroy$))
      .subscribe(
        ([emailCtrl, value]) => emailCtrl?.setValue(value.toLowerCase())
      );
  }

  async onSuccess(): Promise<void> {
    await this.auth.updateClaims();
  }

  onError(event: Error): void {
    this._snackBar.open(event.message);
  }

  async onLogin(): Promise<void> {
    await this._router.navigate(['login']);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
