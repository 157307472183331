import { Practice } from '@principle-theorem/principle-core';
import { type ITag } from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  type INamedDocument,
  type IReffable,
  multiMap,
  multiSortBy$,
  nameSorter,
  reduce2DArray,
  snapshot,
  toNamedDocument,
  type WithRef,
  XSLXImporterExporter,
} from '@principle-theorem/shared';
import { sortBy, uniqBy } from 'lodash';
import { combineLatest } from 'rxjs';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import {
  CustomMappingType,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import {
  type ID4WPatientFileCategory,
  PatientFileCategorySourceEntity,
} from '../../source/entities/patient-file-category';
import { FileCategoriesToXSLX } from './file-categories-to-xlsx';
import { XSLXToFileCategories } from './xlsx-to-file-categories';
import { hardDeleteDoc } from '@principle-theorem/shared';

const PATIENT_FILE_CATEGORY_CUSTOM_MAPPING_TYPE =
  'patientFileCategoryCustomMapping';

export const FILE_CATEGORIES_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'File Categories',
    description:
      'This allows us to map the subfolder of a D4W file as a tag when migrating to Principle. We first need to copy the patient files into the migration bucket before the source categories can be populated.',
    type: PATIENT_FILE_CATEGORY_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.DocumentReference,
});

export class D4WFileCategoryMappingHandler extends BaseCustomMappingHandler<ITag> {
  customMapping = FILE_CATEGORIES_MAPPING;

  async getSourceOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const entity = new PatientFileCategorySourceEntity();
    const records = await entity.getRecords$(migration, 1000).toPromise();
    const options = records
      .map((record) => record.data.data)
      .map(({ name }) => ({
        label: name,
        value: name,
      }));

    return sortBy(options, 'label');
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<ITag>[]> {
    return snapshot(
      combineLatest(
        migration.configuration.practices.map((practice) =>
          Practice.mediaTags$(practice)
        )
      ).pipe(
        reduce2DArray(),
        multiMap(toNamedDocument),
        multiSortBy$(nameSorter())
      )
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const mediaTags = await this.getDestinationOptions(migration);
    const fileName = `file-cateogry-mapping`;
    const fileCategories = await this._getFileCategoryOptions(migration);
    await new XSLXImporterExporter().download(
      fileName,
      fileCategories,
      new FileCategoriesToXSLX(mediaTags)
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToFileCategories()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const records = await snapshot(this.getRecords$(translationMap));
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));

    const sourceOptions = await this.getSourceOptions(migration);
    const mediaTags = await this.getDestinationOptions(migration);

    await asyncForEach(items, async (item) => {
      const label = item.folder;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.label === label
      )?.value;
      if (!value) {
        return;
      }

      const matches = new RegExp(/^tag\(([a-zA-Z0-9]+)\).*$/).exec(item.mapTo);
      const tagId = matches ? matches[1] : undefined;

      if (!tagId) {
        return;
      }

      const tag = mediaTags.find((mediaTag) => mediaTag.ref.id === tagId);

      if (!tag) {
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: tag.ref,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getFileCategoryOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ID4WPatientFileCategory[]> {
    const fileCategoryOptions = new PatientFileCategorySourceEntity();
    const records = await fileCategoryOptions
      .getRecords$(migration, 10000)
      .toPromise();
    return sortBy(
      uniqBy(records, (record) => record.data.data.name).map(
        (record) => record.data.data
      ),
      'name'
    );
  }
}
