import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSharedModule, TypedFormControl } from '@principle-theorem/ng-shared';
import { ITimePeriod, debounceUserInput } from '@principle-theorem/shared';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { SterilisationTableFilterStore } from '../../stores/sterilisation-table-filters.store';

@Component({
    selector: 'pr-sterilisation-table-filters',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgMaterialModule,
        NgSharedModule,
    ],
    templateUrl: './sterilisation-table-filters.component.html',
    styleUrl: './sterilisation-table-filters.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SterilisationTableFiltersComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _router = inject(Router);
  readonly store = inject(SterilisationTableFilterStore);
  searchInput = new TypedFormControl<string>('');
  recordsTabActive$: Observable<boolean>;
  searchInputLabel$: Observable<string>;

  constructor() {
    this.searchInput.valueChanges
      .pipe(debounceUserInput(), takeUntil(this._onDestroy$))
      .subscribe((value) => this.store.updateSearchValue(value));

    this.recordsTabActive$ = this._router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event) => event.url.includes('records')),
      startWith(this._router.url.includes('records'))
    );

    this.searchInputLabel$ = this.recordsTabActive$.pipe(
      map(
        (recordsTabActive) =>
          `Search by ID, patient, ${
            recordsTabActive ? 'staffer, and pack type' : 'and staffer'
          }`
      )
    );
  }

  updateDates(range: ITimePeriod): void {
    this.store.updateRange(range);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
