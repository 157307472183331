import { type WithRef } from '@principle-theorem/shared';
import { getConfigurationItem } from '../../source/source';
import {
  type ISource,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';

export function getDatabaseConnectionName(source: ISource): string {
  return getConfigurationItem(source, 'database connection name', '');
}

export function getDatabaseUsername(source: ISource): string {
  return getConfigurationItem(source, 'database username', '');
}

export function getDatabasePassword(source: ISource): string {
  return getConfigurationItem(source, 'database password', '');
}

export interface ISourceDatabaseRequest {
  query: string;
  migration: WithRef<IPracticeMigration>;
}

export function runQuery<T>(
  migration: WithRef<IPracticeMigration>,
  query: string
): Promise<IQueryResult<T>> {
  return runDatabaseRequest<IQueryResult<T>>({
    migration,
    query,
  });
}

export interface IQueryResult<T> {
  rows: T[];
}

export function runDatabaseRequest<T = unknown>(
  _data: ISourceDatabaseRequest
): Promise<T> {
  throw new Error('runDatabaseRequest is not available in browser');
}
