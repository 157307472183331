import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import {
  FeeScheduleManager,
  PricedServiceCodeEntry,
  PricingRule,
} from '@principle-theorem/principle-core';
import {
  type IChartedTreatment,
  type IFeeSchedule,
  type IPricedServiceCodeEntry,
  type IPricingRule,
  type IServiceCode,
  type IServiceCodeEntry,
  type ITreatmentConfiguration,
  ServiceCodeGroupType,
} from '@principle-theorem/principle-core/interfaces';
import {
  getDoc,
  type INamedDocument,
  multiMap,
  reduce2DArray,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, flatMap, groupBy, isNil, omit, uniqBy } from 'lodash';
import { type Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'pr-service-item-list',
    templateUrl: './service-item-list.component.html',
    styleUrls: ['./service-item-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ServiceItemListComponent {
  trackByCode = TrackByFunctions.ref<IPricedServiceCodeEntry>('code.ref');
  chartedItem$ = new ReplaySubject<IChartedTreatment>(1);
  serviceCodes$ = new ReplaySubject<IPricedServiceCodeEntry[]>(1);
  groupedServiceCodes$: Observable<IPricedServiceCodeEntry[]>;
  feeSchedule$ = new ReplaySubject<INamedDocument<IFeeSchedule>>(1);
  stepTreatments$ = new ReplaySubject<IChartedTreatment[]>(1);
  treatmentConfig$ = new ReplaySubject<
    WithRef<ITreatmentConfiguration> | undefined
  >(1);
  commonCodes$: Observable<IServiceCodeEntry[]>;
  @Input() disabled = false;
  @Input() searchDisabled = false;
  @Input() compact = false;
  @ViewChild('addCodeTrigger') addCodeTrigger: ElementRef<HTMLElement>;
  @Output() itemsChange = new EventEmitter<IPricedServiceCodeEntry[]>();

  @Input()
  set chartedItem(chartedItem: IChartedTreatment) {
    if (chartedItem) {
      this.chartedItem$.next(chartedItem);
    }
  }

  @Input()
  set treatmentConfig(treatmentConfig: WithRef<ITreatmentConfiguration>) {
    if (treatmentConfig) {
      this.treatmentConfig$.next(treatmentConfig);
    }
  }

  @Input()
  set stepTreatments(stepTreatments: IChartedTreatment[]) {
    if (stepTreatments) {
      this.stepTreatments$.next(stepTreatments);
    }
  }

  @Input()
  set serviceCodes(serviceCodes: IPricedServiceCodeEntry[]) {
    if (serviceCodes) {
      this.serviceCodes$.next(serviceCodes);
    }
  }

  @Input()
  set feeSchedule(feeSchedule: INamedDocument<IFeeSchedule>) {
    if (feeSchedule) {
      this.feeSchedule$.next(feeSchedule);
    }
  }

  constructor(private _globalStore: GlobalStoreService) {
    this.commonCodes$ = this.chartedItem$.pipe(
      withLatestFrom(this.treatmentConfig$),
      switchMap(([chartedItem, treatmentConfig]) =>
        treatmentConfig ? of(treatmentConfig) : getDoc(chartedItem.config.ref)
      ),
      map((config) =>
        config.serviceCodeGroups
          .filter((group) => group.type === ServiceCodeGroupType.Optional)
          .map((group) => group.serviceCodes)
      ),
      reduce2DArray(),
      map(compact)
    );

    this.groupedServiceCodes$ = this.serviceCodes$.pipe(
      map((serviceCodes) => groupServiceCodes(serviceCodes))
    );
  }

  resetPrice(
    serviceCodes: IPricedServiceCodeEntry[],
    serviceCode: IPricedServiceCodeEntry
  ): void {
    this.itemsChange.emit(
      serviceCodes.map((item) => {
        if (item.uuid !== serviceCode.uuid) {
          return item;
        }
        return omit(item, 'priceOverride');
      })
    );
  }

  delete(
    serviceCodes: IPricedServiceCodeEntry[],
    serviceCode: IPricedServiceCodeEntry
  ): void {
    this.itemsChange.emit(
      serviceCodes.filter((item) => item.uuid !== serviceCode.uuid)
    );
  }

  resetPriceDisabled(value?: number): boolean {
    return isNil(value);
  }

  async addServiceCode(
    treatmentServiceCodes: IPricedServiceCodeEntry[],
    newCode: IServiceCode
  ): Promise<void> {
    const stepServiceCodes = await snapshot(
      this.stepTreatments$.pipe(
        multiMap((treatment) => treatment.serviceCodes),
        reduce2DArray()
      )
    );
    this.itemsChange.emit(
      await this._addFromInfo(treatmentServiceCodes, stepServiceCodes, newCode)
    );
  }

  itemUpdate(
    serviceCodes: IPricedServiceCodeEntry[],
    current: IPricedServiceCodeEntry,
    changes: Partial<IPricedServiceCodeEntry>
  ): void {
    this.itemsChange.next(
      serviceCodes.map((code) => {
        if (code.uuid !== current.uuid) {
          return code;
        }
        return {
          ...code,
          ...changes,
        };
      })
    );
  }

  updateSelectedRuleItem(
    serviceCodes: IPricedServiceCodeEntry[],
    serviceCode: IPricedServiceCodeEntry,
    pricingRule: IPricingRule
  ): void {
    this.itemsChange.emit(
      serviceCodes.map((item) => {
        if (item.uuid !== serviceCode.uuid) {
          return item;
        }
        const selectedRule = pricingRule.ruleItems.find(
          (ruleItem) => ruleItem.selected
        );
        const unitCount = selectedRule?.startUnit ?? 1;
        const itemIndex = selectedRule ? selectedRule.startUnit - 1 : 0;
        return {
          ...item,
          pricingRule,
          price:
            PricingRule.calculatePrice(pricingRule, unitCount, itemIndex) ?? 0,
        };
      })
    );
  }

  private async _addFromInfo(
    treatmentServiceCodes: IPricedServiceCodeEntry[],
    stepServiceCodes: IPricedServiceCodeEntry[],
    info: IServiceCode
  ): Promise<IPricedServiceCodeEntry[]> {
    const feeSchedule = await snapshot(
      this.feeSchedule$.pipe(
        switchMap((schedule) => this._globalStore.getFeeSchedule$(schedule.ref))
      )
    );

    const entry = await this._toPricedEntry(info);

    return [
      ...treatmentServiceCodes,
      await PricedServiceCodeEntry.applyFeeSchedule(
        entry,
        [...stepServiceCodes, entry],
        new FeeScheduleManager(of(feeSchedule)),
        false
      ),
    ];
  }

  private async _toPricedEntry(
    code: IServiceCode
  ): Promise<IPricedServiceCodeEntry> {
    const chartedItem = await snapshot(this.chartedItem$);
    return PricedServiceCodeEntry.init({
      code: code.code,
      type: code.type,
      taxStatus: code.taxStatus,
      chartedItemOrigin: chartedItem.config,
      chartedSurfaces: chartedItem.chartedSurfaces,
    });
  }
}

export function groupServiceCodes(
  serviceCodes: IPricedServiceCodeEntry[]
): IPricedServiceCodeEntry[] {
  const groupedCodes = groupBy(serviceCodes, 'code');
  const uniqueCodes = uniqBy(serviceCodes, 'code');
  return flatMap(uniqueCodes, (item) => groupedCodes[item.code]);
}
