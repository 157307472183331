import { BlockNodes, IMenuButton } from '@principle-theorem/editor';
import { MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { AIImagePromptMenuComponent } from './ai-image-prompt-menu/ai-image-prompt-menu.component';
import { BasicMenuButtonComponent } from '../../../menu-bar/basic-menu-button/basic-menu-button.component';

export const IMAGE_PROMPT_DATA: IMenuButton = {
  buttonType: 'submenu',
  buttonText: 'Generate Image',
  tooltip: `Generates an image based on prompt and style`,
  icon: 'camera_enhance',
  canActivateFn: (editor) => editor.isActive(BlockNodes.Paragraph),
  command: (editor) => editor.chain().focus().aiImagePrompt().focus().run(),
};

export function createImagePromptButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: AIImagePromptMenuComponent,
    data: IMAGE_PROMPT_DATA,
  });
}

export function createImagePromptBlockButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: AIImagePromptMenuComponent,
    data: {
      ...IMAGE_PROMPT_DATA,
      buttonType: 'block',
    },
  });
}
