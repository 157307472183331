import { Injectable, type OnDestroy } from '@angular/core';
import { CreateCalendarEventActionService } from '@principle-theorem/ng-calendar';
import {
  CreateAppointmentActionService,
  LoadContactActionService,
  LoadLabActionService,
  LoadPatientActionService,
  LoadStafferActionService,
} from '@principle-theorem/ng-interactions';
import { CreateLabJobActionService } from '@principle-theorem/ng-labs';
import {
  LastViewedService,
  OrganisationService,
  TypesenseSearchService,
} from '@principle-theorem/ng-principle-shared';
import { TypedFormControl } from '@principle-theorem/ng-shared';
import { CreateTaskActionService } from '@principle-theorem/ng-tasks';
import {
  Brand,
  ITypesensePatientWithRef,
  OrganisationCache,
} from '@principle-theorem/principle-core';
import {
  ContactResourceType,
  MentionResourceType,
  type IBrand,
  type IContact,
  type ILab,
  type IPatient,
  type IRecentlyViewed,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  asDocRef,
  asyncForEach,
  errorNil,
  isChanged$,
  isRefChanged$,
  isSameRef,
  reduceToSingleArray,
  shareReplayHot,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, isString, remove } from 'lodash';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  of,
  type Observable,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ContactLoader } from './contact-loader';
import { LabLoader } from './lab-loader';
import { PatientLoader } from './patient-loader';
import { type IProfileLoader } from './profile-loader';
import { type SearchOptionGroup } from './search.component';
import { StafferLoader } from './staffer-loader';
import { SubContactLoader } from './sub-contact-loader';

@Injectable({
  providedIn: 'root',
})
export class SearchService implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  search: TypedFormControl<string> = new TypedFormControl();
  loading$ = new BehaviorSubject<boolean>(false);
  groups$ = new ReplaySubject<SearchOptionGroup[]>(1);

  baseGroups: SearchOptionGroup[];

  constructor(
    private _createTaskActionService: CreateTaskActionService,
    private _createLabJobActionService: CreateLabJobActionService,
    private _createAppointmentActionService: CreateAppointmentActionService,
    private _createCalendarEventActionService: CreateCalendarEventActionService,
    private _loadPatientActionService: LoadPatientActionService,
    private _loadStafferActionService: LoadStafferActionService,
    private _loadLabActionService: LoadLabActionService,
    private _organisation: OrganisationService,
    private _loadContactActionService: LoadContactActionService,
    private _typesenseSearch: TypesenseSearchService,
    private _lastViewed: LastViewedService
  ) {
    this.baseGroups = [
      {
        name: 'Actions',
        options: [
          this._createAppointmentActionService,
          this._createTaskActionService,
          this._createLabJobActionService,
          this._createCalendarEventActionService,
        ],
        skipFilter: false,
      },
    ];
    const patients$ = this._typesenseSearch
      .patientQuery$(
        this.search.valueChanges.pipe(
          filter((value) => isString(value) && !!value)
        )
      )
      .pipe(
        map((response) => response.results),
        startWith([])
      );

    this._organisation.brand$
      .pipe(
        tap(() => this.loading$.next(true)),
        errorNil('No brand found'),
        isRefChanged$(),
        switchMap((brand) =>
          brand ? this.loadAsyncOptions$(brand, patients$) : of([])
        ),
        catchError(() => of([])),
        tap(() => this.loading$.next(false)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((options) => this.groups$.next(options));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  loadAsyncOptions$(
    brand: WithRef<IBrand>,
    patients$: Observable<ITypesensePatientWithRef[]>
  ): Observable<SearchOptionGroup[]> {
    return combineLatest([
      patients$,
      this._organisation.staff$.pipe(isChanged$(isSameRef)),
      Brand.labs$(brand),
      Brand.contacts$(brand),
      this._lastViewed.recentlyViewed$,
    ]).pipe(
      switchMap(async ([patients, staff, labs, contacts, recentlyViewed]) => [
        ...this.baseGroups,
        await this.mockRecentlyViewedWithService(recentlyViewed),
        this.mockPatientsWithService(patients),
        this.mockStaffWithService(staff),
        this.mockLabsWithService(labs),
        this.mockContactsWithService(contacts),
      ]),
      shareReplayHot(this._onDestroy$)
    );
  }

  async mockRecentlyViewedWithService(
    recentlyViewed: IRecentlyViewed[]
  ): Promise<SearchOptionGroup> {
    const options = compact(
      await asyncForEach(recentlyViewed, async (recentItem) => {
        if (recentItem.type === MentionResourceType.Patient) {
          return new PatientLoader(
            await OrganisationCache.patients.getDoc(
              asDocRef<IPatient>(recentItem.ref)
            ),
            this._loadPatientActionService
          );
        }
      })
    );

    return {
      name: 'Recently Viewed',
      options,
      skipFilter: false,
    };
  }

  mockPatientsWithService(
    patients: ITypesensePatientWithRef[]
  ): SearchOptionGroup {
    const options: IProfileLoader[] = patients.map(
      (patient): IProfileLoader =>
        new PatientLoader(
          {
            ...patient,
            contactNumbers: patient.contactNumbers.map((contactNumber) => ({
              label: '',
              number: contactNumber,
            })),
          },
          this._loadPatientActionService
        )
    );

    return {
      name: 'Patients',
      options,
      skipFilter: true,
    };
  }

  mockStaffWithService(staff: WithRef<IStaffer>[]): SearchOptionGroup {
    const options: IProfileLoader[] = staff.map((staffer): IProfileLoader => {
      return new StafferLoader(staffer, this._loadStafferActionService);
    });

    return {
      name: 'Staff',
      options,
      skipFilter: false,
    };
  }

  mockLabsWithService(labs: WithRef<ILab>[]): SearchOptionGroup {
    const options: IProfileLoader[] = labs.map((lab): IProfileLoader => {
      return new LabLoader(lab, this._loadLabActionService);
    });

    return {
      name: 'Labs',
      options,
      skipFilter: false,
    };
  }

  mockContactsWithService(contacts: WithRef<IContact>[]): SearchOptionGroup {
    const children: WithRef<IContact>[] = remove(
      contacts,
      (contact) =>
        contact.parentRef && contact.parentRef.type !== ContactResourceType.Lab
    );

    const combinedOptions: IProfileLoader[][] = contacts.map((contact) => {
      const contactChildren: WithRef<IContact>[] = remove(
        children,
        (child) => child.parentRef && isSameRef(child.parentRef, contact)
      );

      const subContactLoaders: IProfileLoader[] = this._getSubContactLoaders(
        contact,
        contactChildren
      );
      return [
        new ContactLoader(contact, this._loadContactActionService),
        ...subContactLoaders,
      ];
    });

    return {
      name: 'Contacts',
      options: reduceToSingleArray(combinedOptions),
      skipFilter: false,
    };
  }

  private _getSubContactLoaders(
    contact: IContact,
    children: WithRef<IContact>[]
  ): IProfileLoader[] {
    return children.map((subcontact: WithRef<IContact>) => {
      return new SubContactLoader(
        contact,
        subcontact,
        this._loadContactActionService
      );
    });
  }
}
