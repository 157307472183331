import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  type ICurrentScopeState,
  CURRENT_SCOPE_KEY,
} from '../reducers/current-scope.reducer';
import { selectRouteParam } from './principle-core.selectors';

export const getCurrentScopeState =
  createFeatureSelector<ICurrentScopeState>(CURRENT_SCOPE_KEY);

export const getBrandSlug = selectRouteParam('brand-slug');
export const getPracticeSlug = selectRouteParam('practice-slug');

export const getCurrentBrand = createSelector(
  getCurrentScopeState,
  (state) => state.brand
);
export const getCurrentPractice = createSelector(
  getCurrentScopeState,
  (state) => state.practice
);
