import { ISnippet } from '@principle-theorem/editor';
import {
  BrandCollection,
  ConversationStatus,
  CustomFormType,
  EventType,
  IAppointmentRequest,
  IBrand,
  IBrandSettings,
  ICalendarEvent,
  ICalendarEventSchedule,
  ICandidateCalendarEvent,
  IChat,
  IContact,
  ICustomFormConfiguration,
  ICustomReport,
  ILab,
  ILabJob,
  IOrganisation,
  IParticipant,
  IPatient,
  IPatientMetadataConfiguration,
  IPatientMetadataDisplay,
  IPatientPhoneNumber,
  IPractice,
  IPrescriptionItem,
  IProduct,
  IReferralSourceConfiguration,
  ISchedulingAlert,
  ISchedulingEventReason,
  IStaffer,
  ITag,
  ITask,
  ITeam,
  ITemplateDefinition,
  ITreatmentCategory,
  ITreatmentTemplate,
  IUser,
  ReportingJob,
  SMSConversation,
  SMSMessage,
} from '@principle-theorem/principle-core/interfaces';
import {
  all$,
  CollectionReference,
  doc,
  doc$,
  DocumentReference,
  Firestore,
  getDocs,
  initFirestoreModel,
  IReffable,
  isSameRef,
  ITimePeriod,
  limit,
  multiFilter,
  multiMap,
  multiSortBy$,
  nameSorter,
  orderBy,
  Query,
  query$,
  QueryConstraint,
  reduce2DArray,
  reduceToSingleArray,
  safeCombineLatest,
  slugify,
  sortByRefArray,
  subCollection,
  timePeriodsIntersect,
  Timezone,
  toMoment,
  toQuery,
  toTimePeriod,
  undeletedQuery,
  where,
  WithRef,
} from '@principle-theorem/shared';
import { chunk, compact, flatten, sortBy, uniqWith } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { filterEventsByParticipants } from './event/event';
import { LabJob } from './lab-job/lab-job';
import { OrganisationCache } from './organisation/organisation-cache';
import { Task } from './task/task';

export class BrandSettings {
  static init(): IBrandSettings {
    return {
      timezone: Timezone.AustraliaSydney,
    };
  }
}

export class Brand {
  static init(overrides?: Partial<IBrand>): IBrand {
    const brand: IBrand = {
      name: '',
      slug: '',
      settings: BrandSettings.init(),
      ...initFirestoreModel(),
      ...overrides,
      defaultSchedulingEventReasonOrder: [],
    };
    return {
      ...brand,
      slug: slugify(brand.name.toLowerCase()),
    };
  }

  static teamCol(brand: IReffable<IBrand>): CollectionReference<ITeam> {
    return subCollection<ITeam>(brand.ref, BrandCollection.Teams);
  }

  static teams$(brand: IReffable<IBrand>): Observable<WithRef<ITeam>[]> {
    return query$(undeletedQuery(Brand.teamCol(brand)));
  }

  static contactCol(brand: IReffable<IBrand>): CollectionReference<IContact> {
    return subCollection<IContact>(brand.ref, BrandCollection.Contacts);
  }

  static contacts$(brand: IReffable<IBrand>): Observable<WithRef<IContact>[]> {
    return query$(undeletedQuery(Brand.contactCol(brand)));
  }

  static contactMembers$(
    brand: IReffable<IBrand>,
    contact: IReffable<IContact>
  ): Observable<WithRef<IContact>[]> {
    return query$(
      undeletedQuery(Brand.contactCol(brand)),
      where('parentRef.ref', '==', contact.ref)
    );
  }

  static referralSourceCol(
    brand: IReffable<IBrand>
  ): CollectionReference<IReferralSourceConfiguration> {
    return subCollection<IReferralSourceConfiguration>(
      brand.ref,
      BrandCollection.ReferralSources
    );
  }

  static referralSources$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<IReferralSourceConfiguration>[]> {
    return query$(undeletedQuery(Brand.referralSourceCol(brand))).pipe(
      multiSortBy$(nameSorter())
    );
  }

  static cancellationReasonCol(
    brand: IReffable<IBrand>
  ): CollectionReference<ISchedulingEventReason> {
    return subCollection<ISchedulingEventReason>(
      brand.ref,
      BrandCollection.CancellationReasons
    );
  }

  static cancellationReasons$(
    brandReffable: IReffable<IBrand>,
    includeDeleted: boolean = false
  ): Observable<WithRef<ISchedulingEventReason>[]> {
    const collection = Brand.cancellationReasonCol(brandReffable);
    const results$ = includeDeleted
      ? all$(collection)
      : query$(undeletedQuery(collection));
    return combineLatest([
      OrganisationCache.brands.doc$(brandReffable.ref),
      results$,
    ]).pipe(
      map(([brand, reasons]) =>
        sortByRefArray(reasons, brand.defaultSchedulingEventReasonOrder)
      )
    );
  }

  static treatmentTemplateCol(
    brand: IReffable<IBrand>
  ): CollectionReference<ITreatmentTemplate> {
    return subCollection<ITreatmentTemplate>(
      brand.ref,
      BrandCollection.TreatmentTemplates
    );
  }

  static treatmentTemplates$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<ITreatmentTemplate>[]> {
    return all$(undeletedQuery(Brand.treatmentTemplateCol(brand)));
  }

  static treatmentTemplates(
    brand: IReffable<IBrand>
  ): Promise<WithRef<ITreatmentTemplate>[]> {
    return getDocs(undeletedQuery(Brand.treatmentTemplateCol(brand)));
  }

  static treatmentCategoryCol(
    brand: IReffable<IBrand>
  ): CollectionReference<ITreatmentCategory> {
    return subCollection<ITreatmentCategory>(
      brand.ref,
      BrandCollection.TreatmentCategories
    );
  }

  static treatmentCategories$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<ITreatmentCategory>[]> {
    return all$(undeletedQuery(Brand.treatmentCategoryCol(brand))).pipe(
      map((categories) =>
        sortBy(categories, (category) => category.name.toLowerCase())
      )
    );
  }

  static labCol(brand: IReffable<IBrand>): CollectionReference<ILab> {
    return subCollection<ILab>(brand.ref, BrandCollection.Labs);
  }

  static labs$(brand: IReffable<IBrand>): Observable<WithRef<ILab>[]> {
    return query$(undeletedQuery(Brand.labCol(brand)));
  }

  static stafferCol(brand: IReffable<IBrand>): CollectionReference<IStaffer> {
    return subCollection<IStaffer>(brand.ref, BrandCollection.Staff);
  }

  static staff$(
    brand: IReffable<IBrand>,
    includeDeleted: boolean = false
  ): Observable<WithRef<IStaffer>[]> {
    const staffQuery = includeDeleted
      ? Brand.stafferCol(brand)
      : undeletedQuery(Brand.stafferCol(brand));
    return OrganisationCache.staff.all
      .query$(
        {
          brandRef: brand.ref,
          includeDeleted,
        },
        staffQuery
      )
      .pipe(map((staff) => sortBy(staff, ['user.name'])));
  }

  static userStaffer$(
    brand: IReffable<IBrand>,
    user: IReffable<IUser>,
    filterDeleted: boolean = true
  ): Observable<WithRef<IStaffer> | undefined> {
    return OrganisationCache.staff.all.find$(
      {
        brandRef: brand.ref,
        userRef: user.ref,
        filterDeleted,
      },
      Brand.stafferCol(brand),
      ...compact([
        where('user.ref', '==', user.ref),
        filterDeleted ? where('deleted', '!=', true) : undefined,
      ])
    );
  }

  static calendarEventCol(
    brand: IReffable<IBrand>
  ): CollectionReference<ICalendarEvent> {
    return subCollection<ICalendarEvent>(
      brand.ref,
      BrandCollection.CalendarEvents
    );
  }

  static patientCol(brand: IReffable<IBrand>): CollectionReference<IPatient> {
    return subCollection<IPatient>(brand.ref, BrandCollection.Patients);
  }

  static practiceCol(brand: IReffable<IBrand>): CollectionReference<IPractice> {
    return subCollection<IPractice>(brand.ref, BrandCollection.Practices);
  }

  static practices$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<IPractice>[]> {
    return all$(undeletedQuery(Brand.practiceCol(brand)));
  }

  static templateCol(
    brand: IReffable<IBrand>
  ): CollectionReference<ITemplateDefinition> {
    return subCollection<ITemplateDefinition>(
      brand.ref,
      BrandCollection.Templates
    );
  }

  static templates$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<ITemplateDefinition>[]> {
    return query$(undeletedQuery(Brand.templateCol(brand)));
  }

  static schedulingAlertCol(
    brand: IReffable<IBrand>
  ): CollectionReference<ISchedulingAlert> {
    return subCollection<ISchedulingAlert>(
      brand.ref,
      BrandCollection.SchedulingAlerts
    );
  }

  static schedulingAlerts$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<ISchedulingAlert>[]> {
    return all$(Brand.schedulingAlertCol(brand));
  }

  static prescriptionItemCol(
    brand: IReffable<IBrand>
  ): CollectionReference<IPrescriptionItem> {
    return subCollection<IPrescriptionItem>(
      brand.ref,
      BrandCollection.PrescriptionItems
    );
  }

  static prescriptionItems$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<IPrescriptionItem>[]> {
    return query$(undeletedQuery(Brand.prescriptionItemCol(brand)));
  }

  static productCol(brand: IReffable<IBrand>): CollectionReference<IProduct> {
    return subCollection<IProduct>(brand.ref, BrandCollection.Products);
  }

  static products$(brand: IReffable<IBrand>): Observable<WithRef<IProduct>[]> {
    return query$(undeletedQuery(Brand.productCol(brand)));
  }

  static snippetCol(brand: IReffable<IBrand>): CollectionReference<ISnippet> {
    return subCollection<ISnippet>(brand.ref, BrandCollection.Snippets);
  }

  static snippets$(brand: IReffable<IBrand>): Observable<WithRef<ISnippet>[]> {
    return all$(Brand.snippetCol(brand));
  }

  static eventTagCol(brand: WithRef<IBrand>): CollectionReference<ITag> {
    return subCollection<ITag>(brand.ref, BrandCollection.CalendarEventTags);
  }

  static eventTags$(brand: WithRef<IBrand>): Observable<WithRef<ITag>[]> {
    return all$(undeletedQuery(Brand.eventTagCol(brand)));
  }

  static calendarEventScheduleCol(
    brand: IReffable<IBrand>
  ): CollectionReference<ICalendarEventSchedule> {
    return subCollection<ICalendarEventSchedule>(
      brand.ref,
      BrandCollection.CalendarEventSchedules
    );
  }

  static calendarEventSchedules$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<ICalendarEventSchedule>[]> {
    return query$(undeletedQuery(Brand.calendarEventScheduleCol(brand)));
  }

  static appointmentTagCol(
    brand: IReffable<IBrand>
  ): CollectionReference<ITag> {
    return subCollection<ITag>(brand.ref, BrandCollection.AppointmentTags);
  }

  static appointmentTags$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<ITag>[]> {
    return OrganisationCache.appointmentTags
      .query$(
        { brandRef: brand.ref },
        undeletedQuery(Brand.appointmentTagCol(brand))
      )
      .pipe(map((tag) => sortBy(tag, 'name')));
  }

  static patientTagCol(brand: IReffable<IBrand>): CollectionReference<ITag> {
    return subCollection<ITag>(brand.ref, BrandCollection.PatientTags);
  }

  static patientTags$(brand: IReffable<IBrand>): Observable<WithRef<ITag>[]> {
    return all$(undeletedQuery(Brand.patientTagCol(brand)));
  }

  static patientNoteTagCol(
    brand: IReffable<IBrand>
  ): CollectionReference<ITag> {
    return subCollection<ITag>(brand.ref, BrandCollection.PatientNoteTags);
  }

  static patientNoteTags$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<ITag>[]> {
    return all$(undeletedQuery(Brand.patientNoteTagCol(brand)));
  }

  static queryTasks$(
    brand: IReffable<IBrand>,
    ...queryConstraints: QueryConstraint[]
  ): Observable<WithRef<ITask>[]> {
    return from(getDocs(Brand.practiceCol(brand))).pipe(
      switchMap((practices) =>
        safeCombineLatest(
          practices.map((practice) =>
            query$(undeletedQuery(Task.col(practice)), ...queryConstraints)
          )
        )
      ),
      map(reduceToSingleArray)
    );
  }

  static labJobs$(brand: IReffable<IBrand>): Observable<WithRef<ILabJob>[]> {
    return from(Firestore.getDocs(Brand.practiceCol(brand))).pipe(
      switchMap((practices) =>
        safeCombineLatest(practices.map((practice) => LabJob.all$(practice)))
      ),
      map(reduceToSingleArray)
    );
  }

  static activeSchedulingAlerts$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<ISchedulingAlert>[]> {
    return query$(
      Brand.schedulingAlertCol(brand),
      where(
        'deadline',
        '<=',
        // TODO: Build with timezone - CU-251edxw
        moment().endOf('day').add(2, 'days').toDate()
      )
    ).pipe(
      map((alerts) =>
        alerts
          .filter((alert) =>
            toMoment(alert.deadline).isSameOrAfter(moment().startOf('day'))
          )
          .sort((alertA, alertB) => {
            if (!alertA.deadline || !alertB.deadline) {
              return 0;
            }
            return alertA.deadline.seconds > alertB.deadline.seconds ? 1 : -1;
          })
      )
    );
  }

  static medicalHistoryFormConfig$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<ICustomFormConfiguration> | undefined> {
    return doc$(
      doc(Brand.customFormsConfigCol(brand), CustomFormType.MedicalHistory)
    ).pipe(catchError(() => of(undefined)));
  }

  static customFormsConfigCol(
    brand: IReffable<IBrand>
  ): CollectionReference<ICustomFormConfiguration> {
    return subCollection<ICustomFormConfiguration>(
      brand.ref,
      BrandCollection.CustomFormConfigurations
    );
  }

  static getBrandStafferFromUser(
    brand: IReffable<IBrand>,
    user: WithRef<IUser>
  ): Observable<WithRef<IStaffer> | undefined> {
    return OrganisationCache.staff.all.find$(
      {
        brandRef: brand.ref,
        userRef: user.ref,
      },
      Brand.stafferCol(brand),
      where('user.ref', '==', user.ref),
      limit(1)
    );
  }

  static getCalendarEvents$(
    brand: IReffable<IBrand>,
    range: ITimePeriod,
    participants?: DocumentReference<IStaffer | IPatient>[],
    type?: EventType,
    practiceRef?: DocumentReference<IPractice>
  ): Observable<WithRef<ICalendarEvent>[]> {
    const inRangeEvents$ = Brand.eventQueryV2$(
      brand,
      range,
      'event.from',
      participants,
      type,
      practiceRef
    );

    const multiDayEventTypes = [EventType.Leave, EventType.PracticeClosed];
    const queryTypes: ['event.from' | 'event.to', ITimePeriod][] = [
      [
        'event.from',
        { from: range.from.clone().subtract(1, 'month'), to: range.to.clone() },
      ],
      [
        'event.to',
        {
          from: range.from.clone(),
          to: range.to.clone().add(3, 'month'),
        },
      ],
    ];
    const multiDayEvents$ = combineLatest(
      flatten(
        multiDayEventTypes.map((multiDayEventType) =>
          queryTypes.map(([field, queryRange]) =>
            Brand.eventQueryV2$(
              brand,
              queryRange,
              field,
              participants,
              multiDayEventType,
              practiceRef
            )
          )
        )
      )
    ).pipe(reduce2DArray());

    return combineLatest([inRangeEvents$, multiDayEvents$]).pipe(
      reduce2DArray(),
      map((events) => uniqWith(events, isSameRef)),
      multiFilter((event) =>
        timePeriodsIntersect(
          range,
          toTimePeriod(event.event.from, event.event.to),
          false,
          'minutes'
        )
      )
    );
  }

  // TODO: https://app.clickup.com/t/1zvg8gg
  static getGapCandidates$(
    brand: IReffable<IBrand>,
    range: ITimePeriod,
    practiceRefs: DocumentReference[] = [],
    participants: IParticipant[] = []
  ): Observable<WithRef<ICandidateCalendarEvent>[]> {
    const eventQuery = Brand.eventQuery(Brand.calendarEventCol(brand), range);
    if (practiceRefs.length) {
      return query$(Brand.eventPracticeQuery(eventQuery, practiceRefs)).pipe(
        filterEventsByParticipants(participants),
        multiMap((event) => event as WithRef<ICandidateCalendarEvent>)
      );
    }

    return query$(
      eventQuery,
      where('event.type', '==', EventType.GapCandidate)
    ).pipe(
      filterEventsByParticipants(participants),
      multiMap((event) => event as WithRef<ICandidateCalendarEvent>)
    );
  }

  static getAppointmentRequests$(
    brand: IReffable<IBrand>,
    practiceRef?: DocumentReference<IPractice>
  ): Observable<WithRef<IAppointmentRequest>[]> {
    if (!practiceRef) {
      return query$(
        undeletedQuery(Brand.calendarEventCol(brand)),
        where('event.type', '==', EventType.AppointmentRequest)
      ).pipe(multiMap((event) => event as WithRef<IAppointmentRequest>));
    }

    return query$(
      undeletedQuery(Brand.calendarEventCol(brand)),
      where('event.type', '==', EventType.AppointmentRequest),
      where('practice.ref', '==', practiceRef)
    ).pipe(multiMap((event) => event as WithRef<IAppointmentRequest>));
  }

  static eventQuery<T extends object>(
    query: Query<T> | CollectionReference<T>,
    range: ITimePeriod
  ): Query<T> {
    return toQuery(
      query,
      where('event.from', '>=', range.from.toDate()),
      where('event.from', '<=', range.to.toDate()),
      where('deleted', '==', false)
    );
  }

  static eventQueryV2<T extends object>(
    existingQuery: Query<T> | CollectionReference<T>,
    range: ITimePeriod,
    field: 'event.from' | 'event.to',
    participants?: DocumentReference<IStaffer | IPatient>[],
    type?: EventType,
    practiceRef?: DocumentReference<IPractice>
  ): Query<T> {
    let builtQuery = toQuery(
      existingQuery,
      where(field, '>=', range.from.toDate()),
      where(field, '<=', range.to.toDate()),
      where('deleted', '==', false)
    );

    if (participants && participants.length) {
      builtQuery = toQuery(
        builtQuery,
        where(
          'event.participantRefs',
          'array-contains-any', // Hard limit of 10
          participants
        )
      );
    }

    if (practiceRef) {
      builtQuery = toQuery(
        builtQuery,
        where('event.practice.ref', '==', practiceRef)
      );
    }

    if (type) {
      builtQuery = toQuery(builtQuery, where('event.type', '==', type));
    }
    return builtQuery;
  }

  static eventQueryV2$(
    brand: IReffable<IBrand>,
    range: ITimePeriod,
    field: 'event.from' | 'event.to',
    participants?: DocumentReference<IStaffer | IPatient>[],
    type?: EventType,
    practiceRef?: DocumentReference<IPractice>
  ): Observable<WithRef<ICalendarEvent>[]> {
    if (!participants || participants.length < 10) {
      return query$(
        Brand.eventQueryV2(
          Brand.calendarEventCol(brand),
          range,
          field,
          participants,
          type,
          practiceRef
        )
      );
    }

    const participantGroups = chunk(participants, 10);
    return safeCombineLatest(
      participantGroups.map((group) =>
        query$(
          Brand.eventQueryV2(
            Brand.calendarEventCol(brand),
            range,
            field,
            group,
            type,
            practiceRef
          )
        )
      )
    ).pipe(map(reduceToSingleArray));
  }

  static eventPracticeQuery<T extends object>(
    query: Query<T>,
    practiceRefs: DocumentReference[]
  ): Query<T> {
    return toQuery(query, where('event.practice.ref', 'in', practiceRefs));
  }

  static organisationRef(
    brand: IReffable<IBrand>
  ): DocumentReference<IOrganisation> {
    return Firestore.getParentDocRef<IOrganisation>(brand.ref);
  }

  static organisation$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<IOrganisation>> {
    return Firestore.doc$(this.organisationRef(brand));
  }

  static findCalendarEventsIntersectingRange$(
    brand: WithRef<IBrand>,
    range: ITimePeriod
  ): Observable<WithRef<ICalendarEvent>[]> {
    const eventCol = Brand.calendarEventCol(brand);
    const toWithinRange$ = query$(
      eventCol,
      orderBy('event.to'),
      where('event.to', '>=', range.from.toDate()),
      where('event.to', '<=', range.to.toDate())
    );
    const fromWithinRange$ = query$(
      eventCol,
      orderBy('event.from'),
      where('event.from', '>=', range.from.toDate()),
      where('event.from', '<=', range.to.toDate())
    );
    return combineLatest([toWithinRange$, fromWithinRange$]).pipe(
      reduce2DArray()
    );
  }

  static smsConversationCol(
    brand: IReffable<IBrand>
  ): CollectionReference<SMSConversation> {
    return subCollection<SMSConversation>(
      brand.ref,
      BrandCollection.SMSConversations
    );
  }

  static smsConversationByPractice$(
    brand: IReffable<IBrand>,
    practice: IReffable<IPractice>,
    status: ConversationStatus
  ): Observable<WithRef<SMSConversation>[]> {
    return query$(
      undeletedQuery(Brand.smsConversationCol(brand)),
      where('practiceRef', '==', practice.ref),
      where('status', '==', status)
    );
  }

  static chatCol(brand: IReffable<IBrand>): CollectionReference<IChat> {
    return subCollection<IChat>(brand.ref, BrandCollection.Chats);
  }

  static smsMessageCol(
    brand: IReffable<IBrand>
  ): CollectionReference<SMSMessage> {
    return subCollection<SMSMessage>(brand.ref, BrandCollection.SMSMessages);
  }

  static smsMessageByPatient$(
    brand: IReffable<IBrand>,
    patient: IReffable<IPatient>
  ): Observable<WithRef<SMSMessage>[]> {
    return query$(
      undeletedQuery(Brand.smsMessageCol(brand)),
      where('patientRef', '==', patient.ref)
    );
  }

  static patientPhoneNumberCol(
    brand: IReffable<IBrand>
  ): CollectionReference<IPatientPhoneNumber> {
    return subCollection<IPatientPhoneNumber>(
      brand.ref,
      BrandCollection.PatientPhoneNumbers
    );
  }

  static customReportCol(
    brand: IReffable<IBrand>
  ): CollectionReference<ICustomReport> {
    return subCollection<ICustomReport>(
      brand.ref,
      BrandCollection.CustomReports
    );
  }

  static customReports$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<ICustomReport>[]> {
    return all$(undeletedQuery(Brand.customReportCol(brand)));
  }

  static reportingJobCol(
    brand: IReffable<IBrand>
  ): CollectionReference<ReportingJob> {
    return subCollection<ReportingJob>(
      brand.ref,
      BrandCollection.ReportingJobs
    );
  }

  static reportingJobs$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<ReportingJob>[]> {
    return all$(undeletedQuery(Brand.reportingJobCol(brand)));
  }

  static patientMetadataConfigurationCol(
    brand: IReffable<IBrand>
  ): CollectionReference<IPatientMetadataConfiguration> {
    return subCollection<IPatientMetadataConfiguration>(
      brand.ref,
      BrandCollection.PatientMetadataConfigurations
    );
  }

  static patientMetadataConfigurations$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<IPatientMetadataConfiguration>[]> {
    return query$(undeletedQuery(Brand.patientMetadataConfigurationCol(brand)));
  }

  static patientMetadataDisplayCol(
    brand: IReffable<IBrand>
  ): CollectionReference<IPatientMetadataDisplay> {
    return subCollection<IPatientMetadataDisplay>(
      brand.ref,
      BrandCollection.PatientMetadataDisplays
    );
  }

  static patientMetadataDisplays$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<IPatientMetadataDisplay>[]> {
    return query$(undeletedQuery(Brand.patientMetadataDisplayCol(brand)));
  }

  static logoStoragePath(brand: IReffable<IBrand>): string {
    return `${brand.ref.path}/logo`;
  }
}

export const BRAND_NAMES: string[] = ['Dental Collective'];

export const BRANDS: IBrand[] = BRAND_NAMES.map((name: string) =>
  Brand.init({ name })
);
