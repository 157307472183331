import {
  DestinationEntityStatus,
  IDestinationEntity,
  IDestinationEntityHandler,
  IDestinationHandler,
  ISourceEntityHandler,
  type IPracticeMigration,
  SourceEntityStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  multiFilter,
  multiMap,
  safeCombineLatest,
  type WithRef,
} from '@principle-theorem/shared';
import { D4WDestinationHandler } from '../d4w/destination';
import { DentrixDestinationHandler } from '../dentrix/destination';
import { ExactDestinationHandler } from '../exact/destination';
import { PraktikaDestinationHandler } from '../praktika/destination';
import { CorePracticeDestinationHandler } from '../core-practice/destination';
import { OasisDestinationHandler } from '../oasis/destination';
import { Observable, of } from 'rxjs';
import { compact, flatten, values } from 'lodash';
import { map } from 'rxjs/operators';

export const DESTINATION_HANDLERS: IDestinationHandler[] = [
  new CorePracticeDestinationHandler(),
  new D4WDestinationHandler(),
  new PraktikaDestinationHandler(),
  new ExactDestinationHandler(),
  new DentrixDestinationHandler(),
  new OasisDestinationHandler(),
];

export function getDestinationHandler(
  migration: WithRef<IPracticeMigration>
): IDestinationHandler | undefined {
  return DESTINATION_HANDLERS.find((destinationHandler) => {
    if (
      destinationHandler.migrationType !== migration.metadata.name ||
      destinationHandler.migrationVersion !== migration.metadata.version
    ) {
      return false;
    }
    return destinationHandler.canHandle(migration.destination);
  });
}

export async function initialiseDestinationEntities(
  migration: WithRef<IPracticeMigration>
): Promise<void> {
  const handler = getDestinationHandler(migration);
  if (!handler) {
    return;
  }

  await handler.addEntities(migration);
}

export function getDestinationEntityHandler(
  migration: WithRef<IPracticeMigration>,
  destinationEntity: IDestinationEntity
): IDestinationEntityHandler | undefined {
  const destinationHandler = getDestinationHandler(migration);
  if (!destinationHandler) {
    return;
  }

  return destinationHandler.entityHandlers.find((entityHandler) =>
    entityHandler.canHandle(destinationEntity)
  );
}

export function getDestinationEntityHandlerByKey(
  migration: WithRef<IPracticeMigration>,
  destinationEntityKey: string
): IDestinationEntityHandler | undefined {
  const destinationHandler = getDestinationHandler(migration);
  if (!destinationHandler) {
    return;
  }

  return destinationHandler.entityHandlers.find(
    (entityHandler) =>
      entityHandler.destinationEntity.metadata.key === destinationEntityKey
  );
}

export function getDestinationEntityMigrationSourceBlockers$(
  migration: WithRef<IPracticeMigration>,
  destinationEntity: IDestinationEntity
): Observable<string[]> {
  const destinationEntityHandler = getDestinationEntityHandlerByKey(
    migration,
    destinationEntity.metadata.key
  );

  if (!destinationEntityHandler) {
    return of([]);
  }

  const requiredEntities: ISourceEntityHandler[] = values(
    destinationEntityHandler.sourceEntities ?? {}
  );

  if (!requiredEntities.length) {
    return of([]);
  }
  return safeCombineLatest(
    requiredEntities.map((requiredEntity) =>
      requiredEntity.getEntity$(migration)
    )
  ).pipe(
    map(compact),
    multiFilter(
      (requiredEntity) =>
        ![SourceEntityStatus.Locked].includes(requiredEntity.status)
    ),
    multiMap((requiredEntity) => requiredEntity.metadata.label)
  );
}

export function getDestinationEntityMigrationBlockers$(
  migration: WithRef<IPracticeMigration>,
  destinationEntity: IDestinationEntity
): Observable<string[]> {
  const destinationEntityHandler = getDestinationEntityHandlerByKey(
    migration,
    destinationEntity.metadata.key
  );

  if (!destinationEntityHandler) {
    return of([]);
  }

  const requiredEntities: IDestinationEntityHandler[] = values(
    destinationEntityHandler.destinationEntities
  );

  if (!requiredEntities.length) {
    return of([]);
  }

  return safeCombineLatest(
    requiredEntities.map((requiredEntity) =>
      requiredEntity.getEntity$(migration)
    )
  ).pipe(
    map(compact),
    multiFilter(
      (requiredEntity) =>
        ![DestinationEntityStatus.Locked].includes(requiredEntity.status)
    ),
    multiMap((requiredEntity) => requiredEntity.metadata.label)
  );
}

export function getAllDestinationEntityBlockers$(
  migration: WithRef<IPracticeMigration>,
  destinationEntity: IDestinationEntity
): Observable<string[]> {
  return safeCombineLatest([
    getDestinationEntityMigrationSourceBlockers$(migration, destinationEntity),
    getDestinationEntityMigrationBlockers$(migration, destinationEntity),
  ]).pipe(map(flatten));
}
