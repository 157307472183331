import {
  ConditionLogicId,
  DynamicFormType,
  IBrand,
  IConditionLogicImplementation,
  IDynamicForm,
  IPatientScopeData,
  isPatient,
  ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  INamedDocument,
  isSameRef,
  toNamedDocument,
  TypeGuard,
  WithRef,
} from '@principle-theorem/shared';
import { first, isEqual, isString, sortBy } from 'lodash';
import { Brand } from '../models/brand';
import { Patient } from '../models/patient/patient';

const isPatientScopeData = TypeGuard.interface<IPatientScopeData>({
  patient: isPatient,
  medicalHistoryLink: isString,
  referrer: TypeGuard.noGuard(),
});

interface IPatientHasTagConditionConfig {
  tag: INamedDocument<ITag>;
}

export class PatientHasTagCondition
  implements
    IConditionLogicImplementation<
      IPatientScopeData,
      IPatientHasTagConditionConfig,
      INamedDocument<ITag>
    >
{
  conditionId = ConditionLogicId.PatientHasTag;
  description = 'Patient must have the selected tag';
  isValidContext = isPatientScopeData;

  isTrue(
    config: IPatientHasTagConditionConfig,
    context: IPatientScopeData
  ): boolean {
    return context.patient.tags.some((patientTag) =>
      isSameRef(patientTag.ref, config.tag.ref)
    );
  }

  async getForm(
    brand: WithRef<IBrand>,
    initialValue?: IPatientHasTagConditionConfig
  ): Promise<IDynamicForm<INamedDocument<ITag>>> {
    const tags = await Firestore.getDocs(Brand.patientTagCol(brand));
    const options = tags.map((tag) => ({
      label: tag.name,
      value: toNamedDocument(tag),
    }));
    const tag = {
      type: DynamicFormType.Select,
      label: 'Tag',
      options: sortBy(options, 'label'),
      initialValue: initialValue?.tag ?? first(options)?.value,
      compareWith: isSameRef,
    };
    return { tag };
  }
}

interface IPatientHasMobileNumberConditionConfig {
  hasMobileNumber: boolean;
}

export class PatientHasMobileNumberCondition
  implements
    IConditionLogicImplementation<
      IPatientScopeData,
      IPatientHasMobileNumberConditionConfig,
      boolean
    >
{
  conditionId = ConditionLogicId.PatientHasMobileNumber;
  description = 'Patient must have a mobile number';
  isValidContext = isPatientScopeData;

  async isTrue(
    config: IPatientHasMobileNumberConditionConfig,
    context: IPatientScopeData
  ): Promise<boolean> {
    const hasMobileNumber = !!(await Patient.getMobileNumber(context.patient));
    return hasMobileNumber === config.hasMobileNumber;
  }

  async getForm(
    _brand: WithRef<IBrand>,
    initialValue?: IPatientHasMobileNumberConditionConfig
  ): Promise<IDynamicForm<boolean>> {
    const hasMobileNumber = {
      type: DynamicFormType.Select,
      label: 'Has Mobile Number',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      initialValue: initialValue?.hasMobileNumber ?? true,
      compareWith: isEqual,
    };
    return Promise.resolve({ hasMobileNumber });
  }
}

interface IPatientHasEmailAddressConditionConfig {
  hasEmailAddress: boolean;
}

export class PatientHasEmailAddressCondition
  implements
    IConditionLogicImplementation<
      IPatientScopeData,
      IPatientHasEmailAddressConditionConfig,
      boolean
    >
{
  conditionId = ConditionLogicId.PatientHasEmailAddress;
  description = 'Patient must have the selected tag';
  isValidContext = isPatientScopeData;

  isTrue(
    config: IPatientHasEmailAddressConditionConfig,
    context: IPatientScopeData
  ): boolean {
    const hasEmailAddress = !!context.patient.email;
    return hasEmailAddress === config.hasEmailAddress;
  }

  async getForm(
    _brand: WithRef<IBrand>,
    initialValue?: IPatientHasEmailAddressConditionConfig
  ): Promise<IDynamicForm<boolean>> {
    const hasEmailAddress = {
      type: DynamicFormType.Select,
      label: 'Has Email Address',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      initialValue: initialValue?.hasEmailAddress ?? true,
      compareWith: isEqual,
    };
    return Promise.resolve({ hasEmailAddress });
  }
}
