import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  type IBreadcrumb,
  InputSearchFilter,
  toSearchStream,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { type ITeam } from '@principle-theorem/principle-core/interfaces';
import { Brand, MockTeam } from '@principle-theorem/principle-core';
import {
  addDoc,
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TeamCreateDialogComponent } from '../team-create-dialog/team-create-dialog.component';

@Component({
    selector: 'pr-teams-list',
    templateUrl: './teams-list.component.html',
    styleUrls: ['./teams-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TeamsListComponent {
  trackByTeam = TrackByFunctions.ref<WithRef<ITeam>>();
  searchCtrl: TypedFormControl<string> = new TypedFormControl<string>('');
  teamsFilter: InputSearchFilter<WithRef<ITeam>>;
  breadcrumbs$: Observable<IBreadcrumb[]>;
  emptyState$: Observable<boolean>;

  constructor(
    private _dialog: MatDialog,
    private _brandScope: CurrentBrandScope
  ) {
    const brand$ = this._brandScope.doc$.pipe(filterUndefined());
    const teams$ = brand$.pipe(switchMap((brand) => Brand.teams$(brand)));
    this.breadcrumbs$ = brand$.pipe(
      map((brand) => [
        { label: 'Settings', path: '../../../' },
        { label: brand.name },
        { label: 'Teams' },
      ])
    );

    this.teamsFilter = new InputSearchFilter(
      teams$,
      toSearchStream(this.searchCtrl),
      ['name']
    );

    this.emptyState$ = teams$.pipe(map((teams) => !teams.length));
  }

  async createTeam(): Promise<void> {
    const result: Partial<ITeam> | undefined = await this._dialog
      .open<TeamCreateDialogComponent, undefined, Partial<ITeam>>(
        TeamCreateDialogComponent,
        DialogPresets.medium({ autoFocus: true })
      )
      .afterClosed()
      .toPromise();

    if (!result) {
      return;
    }
    const col = await snapshot(
      this._brandScope.doc$.pipe(
        filterUndefined(),
        map((brand) => Brand.teamCol(brand))
      )
    );
    await addDoc(col, { ...MockTeam(), ...result });
  }
}
