<h5>Treatment Options</h5>
<pr-flagged-treatment
  [selectedSurfaces]="selectedSurfaces$ | async"
  [stepDragDrop]="stepDragDrop"
  [disabled]="disabled"
  (updateChartable)="updateChartable($event)"
 />

<ng-container
  *ngFor="
    let displayType of treatmentDisplayTypes$ | async;
    trackBy: trackByDisplayType
  "
>
  <pr-filtered-flagged-treatment
    [filters]="[displayType]"
    [selectedSurfaces]="selectedSurfaces$ | async"
    [proposal]="flaggedTreatment$ | async"
    [disabled]="disabled"
    (updateChartable)="updateChartable($event)"
   />
</ng-container>
