import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  type IBreadcrumb,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';

@Component({
  selector: 'pr-task-tabs',
  templateUrl: './task-tabs.component.html',
  styleUrls: ['./task-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskTabsComponent {
  trackByLink = TrackByFunctions.label<IBreadcrumb>();
  navLinks: IBreadcrumb[] = [
    {
      label: 'Task List',
      path: ['./list'],
    },
    {
      label: 'Recurring Tasks',
      path: ['./recurring-configuration'],
    },
  ];
}
