<ng-container *ngIf="interaction$ | async as interaction">
  <div
    class="mb-1 flex flex-row"
    [ngClass]="interaction | map: getInteractionTypeColourClass"
  >
    <div class="z-10">
      <div
        [ngClass]="interaction | map: getInteractionTypeColourClass"
        class="type-icon flex h-10 w-10 flex-row items-center justify-center shadow-md"
      >
        <mat-icon class="opacity-60">{{
          interaction | map: getInteractionTypeIcon
        }}</mat-icon>
      </div>
    </div>
    <div
      class="-ml-5 flex h-full w-full flex-col rounded-md bg-white p-4 pl-8 shadow-md"
    >
      <div class="flex flex-row items-start justify-between gap-2">
        @if (schedulingEvent$ | async; as schedulingEvent) {
          <div class="flex flex-auto flex-col">
            <div class="flex flex-row flex-wrap gap-1 text-sm">
              @if (ownerMention$ | async; as ownerMention) {
                <pr-content-view [content]="ownerMention" />
              } @else {
                {{ unknown | titlecase }}
              }

              @if (schedulingEventDisplay$ | async; as display) {
                <div [ngClass]="display.textColour">
                  {{ schedulingEvent | map: getActionLabel : this }}
                </div>
              }
              <div>appointment.</div>
              @if (interaction.suffix) {
                <pr-content-view [content]="interaction.suffix" />
              }
            </div>
            <div class="text-xs opacity-50">
              @if (fullDate) {
                {{
                  schedulingEvent.scheduledAt
                    | moment
                    | amDateFormat: fullDateFormat
                }}
              } @else {
                {{
                  schedulingEvent.scheduledAt
                    | moment
                    | amDateFormat: dateFormat
                }}
              }
            </div>
          </div>
        } @else {
          <div>
            <pr-content-view [content]="title$ | async" />
            <div *ngIf="showCreatedAt$ | async" class="text-xs opacity-50">
              @if (fullDate) {
                {{
                  interaction.createdAt | moment | amDateFormat: fullDateFormat
                }}
              } @else {
                {{ interaction.createdAt | moment | amDateFormat: dateFormat }}
              }
            </div>
          </div>
        }

        <pr-interaction-actions
          [canEdit]="isEditable$ | async"
          [pinnableTypes]="pinnableTypes"
          [interaction]="interaction"
          (interactionUpdated)="updateInteraction(interaction, $event)"
          (interactionDeleted)="deleteInteraction(interaction)"
        />
      </div>
      @if (schedulingEvent$ | async; as schedulingEvent) {
        <pr-scheduling-event-interaction-details
          class="mt-0.5 rounded bg-slate-50 p-2 pl-4"
          [schedulingEvent]="schedulingEvent"
          [showSnapshotColour]="false"
        />
      }
      @if (tags$ | async; as tags) {
        @if (tags.length) {
          <pr-tag-display-list class="pb-1" [compact]="true" [tags]="tags" />
        }
      }
      @if (hasComments$ | async) {
        <pr-content-view class="mt-2" [content]="interaction.content" />
      }
    </div>
  </div>
</ng-container>
