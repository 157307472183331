import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { Brand, OrganisationCache } from '@principle-theorem/principle-core';
import {
  type IPatient,
  type IPatientRelationship,
  PatientRelationshipType,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isSameRef,
  multiFilter,
  multiMap,
  multiSwitchMap,
  query$,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CurrentScopeFacade } from '../../store';
import { where } from '@principle-theorem/shared';

@Component({
  selector: 'pr-phone-number-patient-matches',
  templateUrl: './phone-number-patient-matches.component.html',
  styleUrls: ['./phone-number-patient-matches.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneNumberPatientMatchesComponent {
  trackByPatient = TrackByFunctions.ref<IPatientRelationship>('patient.ref');
  phoneNumber$ = new ReplaySubject<string>(1);
  selectedPatient$ = new ReplaySubject<WithRef<IPatient>>(1);
  patients$: Observable<IPatientRelationship[]>;

  @Input()
  set selectedPatient(selectedPatient: WithRef<IPatient>) {
    if (selectedPatient) {
      this.selectedPatient$.next(selectedPatient);
    }
  }

  @Input()
  set phoneNumber(phoneNumber: string) {
    if (phoneNumber) {
      this.phoneNumber$.next(phoneNumber);
    }
  }

  constructor(currentScope: CurrentScopeFacade) {
    this.patients$ = combineLatest([
      this.selectedPatient$,
      this.phoneNumber$,
      currentScope.currentBrand$.pipe(filterUndefined()),
    ]).pipe(
      switchMap(([selectedPatient, phoneNumber, brand]) =>
        query$(
          Brand.patientPhoneNumberCol(brand),
          where('phoneNumber', '==', phoneNumber)
        ).pipe(
          multiFilter(
            (phoneNumberMap) =>
              !isSameRef(phoneNumberMap.patientRef, selectedPatient)
          ),
          multiSwitchMap((phoneNumberMap) =>
            OrganisationCache.patients.doc$(phoneNumberMap.patientRef)
          ),
          multiMap((patient) => ({
            patient,
            type: this._determineRelationshipType(selectedPatient, patient),
          }))
        )
      )
    );
  }

  private _determineRelationshipType(
    selectedPatient: WithRef<IPatient>,
    patient: WithRef<IPatient>
  ): PatientRelationshipType {
    const foundRelationship = selectedPatient.relationships.find(
      (relationship) => isSameRef(patient, relationship.patient)
    );

    return foundRelationship?.type ?? PatientRelationshipType.Unknown;
  }
}
