import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import {
  type InvoiceStatus,
  STATUS_COLOUR_MAP,
} from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-invoice-status',
  templateUrl: './invoice-status.component.html',
  styleUrls: ['./invoice-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceStatusComponent {
  @Input() @HostBinding('class.dense') dense = false;
  @Input() status: InvoiceStatus;

  statusColor(status: InvoiceStatus): string {
    return STATUS_COLOUR_MAP[status];
  }
}
