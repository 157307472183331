import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  Timezone,
  TypeGuard,
  toTimestamp,
} from '@principle-theorem/shared';
import { flow, isNull, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { EXACT_DATE_TIME_FORMAT, convertExactId } from '../../util/helpers';
import { PatientSourceEntity } from './patient';

export const PATIENT_DOCUMENTS_SOURCE_TYPE = 'patientDocument';

export const PATIENT_DOCUMENTS_SOURCE_ENTITY: ISourceEntity = SourceEntity.init(
  {
    metadata: {
      label: 'Patient Documents',
      description: ``,
      idPrefix: PATIENT_DOCUMENTS_SOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  }
);

export interface IExactPatientDocument {
  id: string | null;
  patient_id: string;
  source_id: string;
  date: string;
  title: string;
  source_path: string;
  user_initials: string;
  type: string;
}

export interface IExactPatientDocumentTranslations {
  date: Timestamp;
}

export interface IExactPatientDocumentFilters {
  patientId: string;
}

function isExactPatientDocument(item: unknown): item is IExactPatientDocument {
  return TypeGuard.interface<IExactPatientDocument>({
    id: [isString, isNull],
    patient_id: isString,
    source_id: isString,
    date: isString,
    title: isString,
    source_path: isString,
    user_initials: isString,
    type: isString,
  })(item);
}

const PATIENT_DOCUMENTS_SOURCE_QUERY = `
SELECT
  patientId::text AS patient_id,
  sourceId::text AS source_id,
  NULLIF(id::text, '') AS id,
  title,
  sourcePath AS source_path,
  userinitials AS user_initials,
  contactType AS type,
  date
FROM convcontact
`;

export class PatientDocumentsSourceEntity extends BaseSourceEntity<
  IExactPatientDocument,
  IExactPatientDocumentTranslations,
  IExactPatientDocumentFilters
> {
  sourceEntity = PATIENT_DOCUMENTS_SOURCE_ENTITY;
  entityResourceType = PATIENT_DOCUMENTS_SOURCE_TYPE;
  sourceQuery = PATIENT_DOCUMENTS_SOURCE_QUERY;
  verifySourceFn = isExactPatientDocument;
  override defaultOffsetSize = 50000;

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  override transformDataFn = flow([transformPatientDocumentResults]);

  getSourceRecordId(data: IExactPatientDocument): string {
    return data.id ?? data.source_id;
  }

  getSourceLabel(record: IExactPatientDocument): string {
    return `${record.patient_id} ${this.getSourceRecordId(record)}`;
  }

  translate(
    data: IExactPatientDocument,
    timezone: Timezone
  ): IExactPatientDocumentTranslations {
    return {
      date: toTimestamp(moment.tz(data.date, EXACT_DATE_TIME_FORMAT, timezone)),
    };
  }

  getFilterData(data: IExactPatientDocument): IExactPatientDocumentFilters {
    return { patientId: data.patient_id };
  }
}

function transformPatientDocumentResults(
  rows: IExactPatientDocument[]
): IExactPatientDocument[] {
  return rows.map((row) => ({
    ...row,
    id: row.id ? convertExactId(row.id) : convertExactId(row.source_id),
    patient_id: convertExactId(row.patient_id),
    source_id: convertExactId(row.source_id),
  }));
}
