import {
  InteractionType,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import { IPatientInteractionDimension } from '@principle-theorem/reporting/interfaces';
import { BigQueryTable } from '../../big-query-tables';
import { BaseDimensionMeasures } from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import { MeasureTransformMap } from '../measure-properties';
import {
  CanBeChartedProperty,
  CanDoAllProperty,
  CanQueryByTimestampProperty,
} from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { PatientDimensionMeasures } from './patient-dimension';

export class PatientInteractionDimensionMeasures
  extends BaseDimensionMeasures
  implements
    MeasureTransformMap<
      Pick<IPatientInteractionDimension, 'type' | 'pinned' | 'tags' | 'owner'>
    >
{
  get patient(): PatientDimensionMeasures {
    return new PatientDimensionMeasures(BigQueryTable.Patients, 'patient', {
      sourceJoinKey: MeasurePath.docRef('patient.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get ref(): CanBeChartedProperty {
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('ref'),
        label: 'Patient Interaction Ref',
      },
      docRef,
      this.buildQuery().attributes([docRef.attributePath]).get()
    );
  }

  get createdAt(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('createdAt'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('createdAt'),
        label: 'Interaction Created Date',
        summary:
          'Date that the patient interaction was created. This is useful for grouping patient interactions by day.',
        formatter: MeasureFormatter.Day,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get title(): CanBeChartedProperty {
    const propertyName = 'title';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Title',
        summary: 'The title of the patient interaction',
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get content(): CanBeChartedProperty {
    const propertyName = 'content';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Content',
        summary: 'The content of the patient interaction',
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get type(): CanDoAllProperty {
    const propertyName = 'type';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.enum<InteractionType>(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Type',
        summary: 'The type of patient interaction',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'None'
    );
  }

  get pinned(): CanDoAllProperty {
    const propertyName = 'pinned';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.boolean(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Is Pinned',
        summary: 'Whether the patient interaction is pinned or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get tags(): CanDoAllProperty {
    const measure = this.measureRef('tags');
    return MeasurePropertyFactory.array(
      {
        id: this._pathWithPrefix('tags'),
        label: 'Patient Interaction Tags',
        summary: 'The tags added to the patient interaction',
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'name'
    );
  }

  get owner(): CanDoAllProperty {
    const propertyName = 'owner';
    const name = this.measureRef(`${propertyName}.name`);
    const docRef = this.measureRef(MeasurePath.docRef(`${propertyName}.ref`));
    return MeasurePropertyFactory.label(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Created By',
        summary: 'The user that created the patient interaction',
      },
      docRef,
      name,
      this.buildQuery()
        .attributes([docRef.attributePath, name.attributePath])
        .get(),
      'None'
    );
  }
}
