import { Directive, type OnDestroy } from '@angular/core';
import { ActivatedRoute, type Data } from '@angular/router';
import { type IBreadcrumb } from '@principle-theorem/ng-shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseTabsComponent<T> implements OnDestroy {
  protected _onDestroy$ = new Subject<void>();
  item: T;
  breadcrumbs: IBreadcrumb[] = [];
  navLinks: IBreadcrumb[] = [];

  constructor(protected _route: ActivatedRoute) {
    this._route.data
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((data: Data) => this.setItem(data));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  abstract setItem(data: Data): void;
}
