<div fxLayout="row" fxLayoutAlign="center start" class="list-headers">
  <div fxFlex class="mat-caption item-name">Item Name</div>
  <div class="mat-caption price-input">
    {{ isPartPaid ? 'Patient Contribution' : 'Claim Amount' }}
  </div>
</div>
<div
  *ngFor="let group of itemsForm.controls; trackBy: trackByItems"
  [formGroup]="group"
  fxLayout="row"
  fxLayoutAlign="end center"
>
  <div fxFlex class="line-item-description">
    <mat-checkbox formControlName="claim" *ngIf="!isPartPaid; else itemLabel">
      {{ group.value.lineItem.description }}
    </mat-checkbox>
    <ng-template #itemLabel>
      {{ group.value.lineItem.description }}
    </ng-template>
  </div>

  <mat-form-field class="price-input" *ngIf="!isPartPaid; else itemPrice">
    <span matTextPrefix>$&nbsp;</span>
    <input matInput type="number" step="0.01" formControlName="amount" />
    <mat-hint align="end">
      Max {{ getItemMax(group.value) | currency }}
    </mat-hint>
  </mat-form-field>
  <ng-template #itemPrice>
    <div class="disabled-item-price">{{ group.value.amount | currency }}</div>
  </ng-template>
</div>

<mat-divider />
<div fxLayout="row" class="layout-margin">
  <div fxFlex>Total</div>
  <div class="amount">{{ claimTotal$ | async | currency }}</div>
</div>
