import { ILabJobDimension } from '@principle-theorem/reporting/interfaces';
import {
  MockGenerator,
  toSerialisedTimestamp,
  toTimestamp,
} from '@principle-theorem/shared';
import {
  AvroSerialisedRawInlineNodesMock,
  AvroSerialisedVersionedSchemaMock,
  BigQueryFirestoreModelMock,
  BigQueryInteractionMock,
  BigQueryNamedDocumentMock,
  BigQueryReffableMock,
  MockBigQueryStatusHistory,
} from './common.mock';
import { LabJobMock } from '@principle-theorem/principle-core';

const labJob = MockGenerator.generate(LabJobMock);

export class LabJobDimensionMock
  extends BigQueryFirestoreModelMock
  implements ILabJobDimension
{
  timestamp = toSerialisedTimestamp(toTimestamp());
  status = labJob.status;
  type = {
    ...labJob.type,
    ...MockGenerator.generate(BigQueryFirestoreModelMock),
  };
  lab = new BigQueryNamedDocumentMock();
  statusHistory = labJob.statusHistory.map((item) =>
    MockBigQueryStatusHistory(item.status)
  );
  patient = MockGenerator.generate(BigQueryNamedDocumentMock);
  appointment = labJob?.appointment
    ? {
        ...labJob.appointment,
        ...MockGenerator.generate(BigQueryReffableMock),
      }
    : undefined;
  cost = labJob.cost;
  description = MockGenerator.generate(AvroSerialisedVersionedSchemaMock);
  title = MockGenerator.generate(AvroSerialisedRawInlineNodesMock);
  dueDate = toSerialisedTimestamp(labJob.dueDate);
  completedDate = toSerialisedTimestamp(labJob.dueDate);
  interactions = [
    MockGenerator.generate(BigQueryInteractionMock),
    MockGenerator.generate(BigQueryInteractionMock),
    MockGenerator.generate(BigQueryInteractionMock),
  ];
}
