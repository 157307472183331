import { createAction, props } from '@ngrx/store';
import {
  type IPerioTable,
  type IPerioTableCell,
} from '@principle-theorem/ng-perio-charting';
import { type IEntityAction } from '@principle-theorem/ng-shared';
import { type SerialisedData } from '@principle-theorem/shared';
import { type ChartId } from '../reducers/active-charts/chart-context-state';
import { ChartActionTypes } from './action-types';

interface ILoadPerioChartSuccessData {
  tables: IPerioTable[];
}

export const loadPerioChartSuccess = createAction(
  ChartActionTypes.LoadPerioChartSuccess,
  props<SerialisedData<ILoadPerioChartSuccessData>>()
);

interface IUpsertPerioValueData extends IEntityAction<ChartId> {
  change: IPerioTableCell;
}

export const upsertPerioValue = createAction(
  ChartActionTypes.UpsertPerioValue,
  props<SerialisedData<IUpsertPerioValueData>>()
);
