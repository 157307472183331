import { IAsset } from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';

export class AssetGroup {
  filteredItems: WithRef<IAsset>[];

  constructor(public label: string, public items: WithRef<IAsset>[]) {
    this.filteredItems = this.items;
  }

  filter(value: string): AssetGroup {
    const filterValue: string = value.toLowerCase();
    this.filteredItems = this.items.filter((item: WithRef<IAsset>) => {
      return item.name.toLowerCase().includes(filterValue);
    });
    return this;
  }
}
