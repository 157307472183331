<ng-container *ngIf="patients$ | async as patients">
  <ng-container *ngIf="patients.length">
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      class="multiple-patients"
      matTooltip="This number is associated with more than one patient."
    >
      <mat-icon>group</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <ng-template matMenuContent>
        <a
          *ngFor="let patient of patients; trackBy: trackByPatient"
          [prBrandRouterLink]="['patients', patient.patient.ref.id]"
          mat-menu-item
        >
          {{ patient.patient.name }} - {{ patient.type | titlecase }}
        </a>
      </ng-template>
    </mat-menu>
  </ng-container>
</ng-container>
