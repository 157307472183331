import {
  DocumentData,
  DocumentReference,
  INamedDocument,
  WithRef,
} from '@principle-theorem/shared';
import { ICustomMapping } from './custom-mappings';
import { Observable } from 'rxjs';

export interface IHasSourceIdentifier {
  sourceIdentifier: string;
}

export interface ITranslationMap<T extends object = object, R = unknown> {
  sourceIdentifier: string;
  sourceLabel?: string;
  resourceType: string;
  destinationValue?: R;
  destinationIdentifier?: DocumentReference<T>;
  associatedValue?: string | INamedDocument;
}

export interface ITranslationMapHandler {
  findCustomMapping$(
    resourceMap: ICustomMapping
  ): Observable<WithRef<ITranslationMap> | undefined>;
  add(translationMap: ITranslationMap): Promise<void>;
  upsert(translationMap: ITranslationMap): Promise<void>;
  getByType$<T extends object = object, R = unknown>(
    resourceType: string
  ): Observable<WithRef<ITranslationMap<T, R>>[]>;
  getSource(
    destinationIdentifier: DocumentReference,
    resourceType: string
  ): Promise<string | undefined>;
  getDestination<T extends object = object>(
    sourceIdentifier: string,
    resourceType: string
  ): Promise<DocumentReference<T> | undefined>;
  getBySource<T extends object = object, R = unknown>(
    sourceIdentifier: string,
    resourceType: string
  ): Promise<WithRef<ITranslationMap<T, R>> | undefined>;
  getByDestination<T extends object = DocumentData, R = unknown>(
    destinationIdentifier: DocumentReference<T>,
    resourceType: string
  ): Promise<WithRef<ITranslationMap<T, R>> | undefined>;
}
