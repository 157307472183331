import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { type IQueryScopeRequests } from '@principle-theorem/principle-core/interfaces';
import { first } from 'lodash';
import { Subject, combineLatest, type Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {
  ReportBuilderDataSource,
  type IReportBuilderDataSource,
} from '../../../models/report-builder-data-sources/report-builder-data-source';
import { REPORT_BUILDER_DATA_SOURCES } from '../../../models/report-builder-data-sources/report-builder-data-sources';
import {
  ReportBuilderStore,
  type IReportBuilderQueryForm,
} from '../report-builder.store';

interface IReportBuilderQueryFormData
  extends Omit<IReportBuilderQueryForm, 'dataSource'> {
  dataSource: IReportBuilderDataSource;
}

class ReportBuilderQueryForm extends TypedFormGroup<IReportBuilderQueryFormData> {
  constructor(defaultDataSource?: IReportBuilderDataSource) {
    super({
      dataSource: new TypedFormControl<IReportBuilderDataSource>(
        defaultDataSource,
        Validators.required
      ),
      scopeRequests: new TypedFormControl<IQueryScopeRequests>({}),
    });
  }
}

@Component({
  selector: 'pr-report-builder-query-form',
  templateUrl: './report-builder-query-form.component.html',
  styleUrls: ['./report-builder-query-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportBuilderQueryFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByDataSource = TrackByFunctions.uniqueId<IReportBuilderDataSource>();
  dataSources = REPORT_BUILDER_DATA_SOURCES.filter(
    (dataSource) => !ReportBuilderDataSource.isRetired(dataSource)
  );
  submitDisabled$: Observable<boolean>;
  queryForm = new ReportBuilderQueryForm(first(this.dataSources));

  constructor(private _store: ReportBuilderStore) {
    this.submitDisabled$ = combineLatest([
      this._store.isLoading$,
      this.queryForm.valueChanges.pipe(
        map(() => this.queryForm.valid),
        startWith(false)
      ),
    ]).pipe(map(([isLoading, isValid]) => isLoading || !isValid));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  submit(): void {
    this._store.loadQuery({
      dataSource: this.queryForm.value.dataSource.id,
      scopeRequests: this.queryForm.value.scopeRequests,
      editMode: true,
    });
  }

  setScopeRequests(scopeRequests: IQueryScopeRequests): void {
    this.queryForm.patchValue({ scopeRequests });
  }
}
