<mat-toolbar color="accent">Clinical Notes</mat-toolbar>

<mat-dialog-content class="h-full flex-1">
  <div class="notes-container h-full flex-1">
    <pr-clinical-notes
      [staffer]="data.staffer"
      [patient]="data.patient"
      [date]="data.date"
      [displayStafferSelector]="data.displayStafferSelector"
      [displayNoteHistory]="data.displayNoteHistory"
      [dialogDisplay]="true"
     />
  </div>
</mat-dialog-content>

<mat-divider />
<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close cdkFocusInitial>Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
