import {
  HicapsConnectMethod,
  PrincipleHicapsConnectRequest,
} from '@principle-theorem/hicaps-connect';

export const MOCK_SEND_QUOTE_REQUEST_METHOD: string =
  HicapsConnectMethod.SendQuoteRequest;

export const MOCK_SEND_QUOTE_REQUEST_DATA: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendQuoteRequest>['data'] =
  {
    PmsKey: '7952265',
    TransactionAmount: 0,
    ServerUrl: '0.0.0.0:0:SE13AA:COMT',
    PrintReceiptOnTerminal: true,
    MembershipId: '01',
    ProviderNumberId: 'H6667949',
    SoftwareVendorName: 'Not 4 Public Release',
  };

export const MOCK_SEND_QUOTE_REQUEST_EXTENDED_DATA: PrincipleHicapsConnectRequest<HicapsConnectMethod.SendQuoteRequest>['extendedData'] =
  {
    addClaimLine: [
      {
        itemNumber: '014',
        serviceDate: '2023-12-11T23:32:50.709Z',
        patientId: '1',
        fee: 10,
        bodyPart: '',
      },
      {
        itemNumber: '111',
        serviceDate: '2023-12-11T23:32:50.709Z',
        patientId: '1',
        fee: 30,
        bodyPart: '',
      },
      {
        itemNumber: '111',
        serviceDate: '2023-12-11T23:32:50.709Z',
        patientId: '1',
        fee: 30,
        bodyPart: '',
      },
      {
        itemNumber: '719',
        serviceDate: '2023-12-11T23:32:50.709Z',
        patientId: '1',
        fee: 20,
        bodyPart: '',
      },
    ],
    addPatientName: [{ patientName: 'Test patient', patientId: '1' }],
  };
