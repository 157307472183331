import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { CancelAppointmentComponent } from './pages/cancel-appointment/cancel-appointment.component';
import { ManageAppointmentComponent } from './pages/manage-appointment/manage-appointment.component';
import { PagesModule } from './pages/pages.module';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'reschedule',
        data: { titleSuffix: 'Appointment Reschedule' },
        component: ManageAppointmentComponent,
      },
      {
        path: 'cancel',
        data: { titleSuffix: 'Appointment Cancel' },
        component: CancelAppointmentComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), PagesModule],
  exports: [RouterModule],
})
export class NgAppointmentSchedulingManageRoutingModule {}
