import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { IntercomProductTourTriggerComponent } from './intercom-product-tour-trigger/intercom-product-tour-trigger.component';
import { IntercomProductTourDirective } from './intercom-product-tour.directive';
import { IntercomTargetDirective } from './intercom-target.directive';

@NgModule({
  imports: [CommonModule, NgMaterialModule],
  declarations: [
    IntercomTargetDirective,
    IntercomProductTourDirective,
    IntercomProductTourTriggerComponent,
  ],
  exports: [
    IntercomTargetDirective,
    IntercomProductTourDirective,
    IntercomProductTourTriggerComponent,
  ],
})
export class NgIntercomComponentsModule {}
