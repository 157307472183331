import { isEnumValue, isObject } from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { Quadrant } from './quadrant';

export enum ToothType {
  Incisor = 'incisor',
  Canine = 'canine',
  Premolar = 'premolar',
  Molar = 'molar',
}

export const TOOTH_TYPES: ToothType[] = [
  ToothType.Incisor,
  ToothType.Canine,
  ToothType.Premolar,
  ToothType.Molar,
];

export interface ITooth {
  type: ToothType;
  toothRef: IToothRef;
  roots: number;
}

export interface IToothRef {
  quadrant: Quadrant;
  quadrantIndex: number;
}

export function isToothRef(data: unknown): data is IToothRef {
  return (
    isObject(data) &&
    'quadrant' in data &&
    isEnumValue(Quadrant, data.quadrant) &&
    isNumber(data.quadrantIndex)
  );
}

export type ToothNumber = `${ToothQuadrant}${ToothIndex}`;
export type ToothQuadrant = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export type ToothIndex = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export function isToothNumber(item: unknown): item is ToothNumber {
  if (!isString(item)) {
    return false;
  }
  const itemInt = parseInt(item, 10);
  return (
    (itemInt >= 11 && itemInt <= 18) ||
    (itemInt >= 21 && itemInt <= 28) ||
    (itemInt >= 31 && itemInt <= 38) ||
    (itemInt >= 41 && itemInt <= 48) ||
    (itemInt >= 51 && itemInt <= 58) ||
    (itemInt >= 61 && itemInt <= 68) ||
    (itemInt >= 71 && itemInt <= 78) ||
    (itemInt >= 81 && itemInt <= 88)
  );
}

export type UniversalToothNumberingSystem =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23'
  | '24'
  | '25'
  | '26'
  | '27'
  | '28'
  | '29'
  | '30'
  | '31'
  | '32';

export type BritishDentalJournalNumberingSystem =
  | 'UR1'
  | 'UR2'
  | 'UR3'
  | 'UR4'
  | 'UR5'
  | 'UR6'
  | 'UR7'
  | 'UR8'
  | 'UL1'
  | 'UL2'
  | 'UL3'
  | 'UL4'
  | 'UL5'
  | 'UL6'
  | 'UL7'
  | 'UL8'
  | 'LR1'
  | 'LR2'
  | 'LR3'
  | 'LR4'
  | 'LR5'
  | 'LR6'
  | 'LR7'
  | 'LR8'
  | 'LL1'
  | 'LL2'
  | 'LL3'
  | 'LL4'
  | 'LL5'
  | 'LL6'
  | 'LL7'
  | 'LL8'
  | 'URA'
  | 'URB'
  | 'URC'
  | 'URD'
  | 'URE'
  | 'ULA'
  | 'ULB'
  | 'ULC'
  | 'ULD'
  | 'ULE'
  | 'LRA'
  | 'LRB'
  | 'LRC'
  | 'LRD'
  | 'LRE'
  | 'LLA'
  | 'LLB'
  | 'LLC'
  | 'LLD'
  | 'LLE';
