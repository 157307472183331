import {
  IAutomationTiming,
  IEvent,
  isRelativeTimingWithTime,
  TimingDirection,
} from '@principle-theorem/principle-core/interfaces';
import {
  ITimestampRange,
  mergeDayAndTime,
  Timezone,
  toMomentTz,
  toTimestamp,
} from '@principle-theorem/shared';
import { Timestamp } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { MomentInputObject } from 'moment-timezone';
import { TimezoneResolver } from '../../timezone';
import { RecurrenceDirection, RecurrencePattern } from '../schedule';

export class AutomationTiming {
  static async resolveTriggerDateFromEvent(
    event: IEvent,
    timing: IAutomationTiming
  ): Promise<Timestamp> {
    const timezone = await TimezoneResolver.fromPracticeRef(event.practice.ref);
    return AutomationTiming.getTriggerDateFromEvent(event, timing, timezone);
  }

  static getTriggerDateFromEvent(
    event: ITimestampRange,
    timing: IAutomationTiming,
    timezone: Timezone
  ): Timestamp {
    if (timing.direction === TimingDirection.Immediately) {
      return toTimestamp(moment.tz(timezone));
    }

    const baseTime =
      timing.direction === TimingDirection.Before
        ? toMomentTz(event.from, timezone)
        : toMomentTz(event.to, timezone);

    const eventTime = isRelativeTimingWithTime(timing)
      ? mergeDayAndTime(baseTime, timing.time, timezone)
      : baseTime;

    const modifier: MomentInputObject = {
      [timing.unit]: timing.amount,
    };

    const triggerDate =
      timing.direction === TimingDirection.Before
        ? eventTime.clone().subtract(modifier)
        : eventTime.clone().add(modifier);

    return toTimestamp(
      RecurrencePattern.adjustNextOccurrenceToValidDay(
        triggerDate,
        timing.daysOfWeek,
        RecurrenceDirection.Backward,
        timezone
      )
    );
  }
}
