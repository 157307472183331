import { createAction, props } from '@ngrx/store';
import {
  type IChartedMultiStepTreatment,
  type IChartedTreatment,
  type ITreatmentPlanProposal,
} from '@principle-theorem/principle-core/interfaces';
import { type IEntityAction } from '@principle-theorem/ng-shared';
import { type AtLeast, type SerialisedData } from '@principle-theorem/shared';
import { type ChartId } from '../reducers/active-charts/chart-context-state';
import { ChartActionTypes } from './action-types';
import { type IChartSurfaceRemove } from './selected-conditions';

export interface IChartTreatmentAdd extends IEntityAction<ChartId> {
  treatment: IChartedTreatment;
}

export interface IChartTreatmentUpdate extends IEntityAction<ChartId> {
  treatment: AtLeast<IChartedTreatment, 'uuid'>;
}

export interface IChartMultiTreatmentUpdate extends IEntityAction<ChartId> {
  multiTreatment: AtLeast<IChartedMultiStepTreatment, 'uuid'>;
}

export const addTreatment = createAction(
  ChartActionTypes.AddTreatment,
  props<SerialisedData<IChartTreatmentAdd>>()
);

export interface IChartMultiTreatmentAdd extends IEntityAction<ChartId> {
  multiTreatment: IChartedMultiStepTreatment;
}

export const addMultiTreatment = createAction(
  ChartActionTypes.AddMultiTreatment,
  props<SerialisedData<IChartMultiTreatmentAdd>>()
);

export const updateTreatment = createAction(
  ChartActionTypes.UpdateTreatment,
  props<SerialisedData<IChartTreatmentUpdate>>()
);

export const updateMultiTreatment = createAction(
  ChartActionTypes.UpdateMultiTreatment,
  props<SerialisedData<IChartMultiTreatmentUpdate>>()
);

export interface IPlanProposalUpdate extends IEntityAction<ChartId> {
  proposal: ITreatmentPlanProposal;
}

export const updatePlanProposal = createAction(
  ChartActionTypes.UpdatePlanProposal,
  props<SerialisedData<IPlanProposalUpdate>>()
);

export interface IUpdateTreatmentsData extends IEntityAction<ChartId> {
  treatments: IChartedTreatment[];
}

export const updateTreatments = createAction(
  ChartActionTypes.UpdateTreatments,
  props<SerialisedData<IUpdateTreatmentsData>>()
);

export const removeTreatment = createAction(
  ChartActionTypes.RemoveTreatment,
  props<SerialisedData<IChartSurfaceRemove>>()
);

export const removeMultiTreatment = createAction(
  ChartActionTypes.RemoveMultiTreatment,
  props<SerialisedData<IChartSurfaceRemove>>()
);
