import * as d3 from 'd3';
import {
  first,
  flatten,
  groupBy,
  keys,
  mapValues,
  omit,
  omitBy,
  uniq,
} from 'lodash';
import { type IDCTransformResult } from '../../../../models/report/charts/data-transformers/dc-data-transformer';
import {
  type CrossfilterGroupMap,
  type GroupedD3ChartData,
} from './d3-grouped-crossfilter';
import { getMockDimensionMap } from './test-data';

export const schemeCategory20c = [
  '#3182bd',
  '#6baed6',
  '#9ecae1',
  '#c6dbef',
  '#e6550d',
  '#fd8d3c',
  '#fdae6b',
  '#fdd0a2',
  '#31a354',
  '#74c476',
  '#a1d99b',
  '#c7e9c0',
  '#756bb1',
  '#9e9ac8',
  '#bcbddc',
  '#dadaeb',
  '#636363',
  '#969696',
  '#bdbdbd',
  '#d9d9d9',
];

export interface ID3ChartConfig {
  colours: string[];
  width: number;
  height: number;
  margin: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
}

export const DEFAULT_D3_CHART_CONFIG: ID3ChartConfig = {
  colours: schemeCategory20c,
  width: 600,
  height: 300,
  margin: {
    top: 20,
    right: 20,
    bottom: 30,
    left: 100,
  },
};

export function translate(x: number = 0, y: number = 0): string {
  return `translate(${x}, ${y})`;
}

interface ID3ChartSetup {
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  inner: d3.Selection<SVGGElement, unknown, null, undefined>;
  innerHeight: number;
  innerWidth: number;
  xAxis: d3.ScaleLinear<number, number>;
  yAxis: d3.ScaleBand<string>;
  colourScale: d3.ScaleOrdinal<string, string>;
  groups: string[];
  subgroups: string[];
}

export function setupChart<T>(
  elem: Element,
  items: GroupedD3ChartData<T>[],
  config: ID3ChartConfig
): ID3ChartSetup {
  items.sort((a, b) => b.metadata.total - a.metadata.total);
  const groups = items.map((d) => String(d.metadata.groupBy));
  const subgroups = uniq(flatten(omit(items.map(keys), 'metadata')));

  const width = config.width - config.margin.left - config.margin.right;
  const height = config.height - config.margin.top - config.margin.bottom;

  const svg = d3
    .select(elem)
    .append('svg')
    .attr('width', width + config.margin.left + config.margin.right)
    .attr('height', height + config.margin.top + config.margin.bottom);

  const inner = svg
    .append('g')
    .attr('transform', translate(config.margin.left, config.margin.top));

  const yAxis = d3
    .scaleBand()
    .domain(groups)
    .rangeRound([0, height])
    .paddingInner(0.1);

  const xMax = d3.max(items, (d) => d.metadata.total) ?? 100;
  const xAxis = d3
    .scaleLinear()
    .rangeRound([0, width])
    .domain([0, xMax])
    .nice();

  const colourScale = d3
    .scaleOrdinal<string>()
    .domain(subgroups)
    .range(config.colours);

  return {
    groups,
    subgroups,
    svg,
    inner,
    innerHeight,
    innerWidth,
    xAxis,
    yAxis,
    colourScale,
  };
}

export function addAxisScales(
  svg: d3.Selection<SVGGElement, unknown, null, undefined>,
  height: number,
  x: d3.ScaleLinear<number, number>,
  y: d3.ScaleBand<string>
): void {
  svg
    .append('g')
    .attr('class', 'axis')
    .attr('transform', translate(0, 0))
    .call(d3.axisLeft(y));

  svg
    .append('g')
    .attr('class', 'axis')
    .attr('transform', translate(0, height))
    .call(d3.axisBottom(x).ticks(undefined, 's'));
}

export function addLegend(
  svg: d3.Selection<SVGGElement, unknown, null, undefined>,
  height: number,
  width: number,
  subgroups: string[],
  colourScale: d3.ScaleOrdinal<string, string>
): d3.Selection<SVGGElement, string, SVGGElement, unknown> {
  const legendOffset = height - 24;
  const legend = svg
    .append('g')
    .attr('font-family', 'sans-serif')
    .attr('font-size', 10)
    .attr('text-anchor', 'end')
    .selectAll('g')
    .data(subgroups.slice().reverse())
    .enter()
    .append('g')
    .attr('transform', (_d, i) => translate(0, legendOffset - i * 20));

  legend
    .append('rect')
    .attr('x', width - 19)
    .attr('width', 19)
    .attr('height', 19)
    .attr('fill', (d) => colourScale(d));

  legend
    .append('text')
    .attr('x', width - 24)
    .attr('y', 9.5)
    .attr('dy', '0.32em')
    .text((d) => d);

  return legend;
}

export function toCrossfilterGroupMap(
  results: IDCTransformResult[]
): CrossfilterGroupMap {
  const mockMap = getMockDimensionMap();
  const grouped = groupBy(results, (result) => result.measure.metadata.label);
  const mapped = mapValues(grouped, (result) => first(result)?.group);
  // TODO: remove cast. The omit doesn't exclude undefined from the typing.
  const realMap = omitBy(
    mapped,
    (group) => group === undefined
  ) as CrossfilterGroupMap;
  // eslint-disable-next-line no-console
  console.log({ results, mockMap, realMap });
  return mockMap;
}
