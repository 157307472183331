import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type ITag } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-tag-display-list',
  templateUrl: './tag-display-list.component.html',
  styleUrls: ['./tag-display-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagDisplayListComponent {
  trackByTag = TrackByFunctions.ref<WithRef<ITag>>();
  tags$ = new ReplaySubject<WithRef<ITag>[]>(1);
  @Input() compact = false;

  @Input()
  set tags(tags: WithRef<ITag>[]) {
    if (tags) {
      this.tags$.next(tags);
    }
  }
}
