import { IBaseChartBuilderConfig } from '@principle-theorem/principle-core/interfaces';
import {
  type CanBeChartedProperty,
  type IChartConfig,
} from '@principle-theorem/reporting';
import { isObject } from '@principle-theorem/shared';
import * as dc from 'dc';
import { first, get } from 'lodash';
import { type Subject } from 'rxjs';
import { type IDCTransformResult } from '../../../../models/report/charts/data-transformers/dc-data-transformer';
import { DataPointValueFormatter } from '../../data-point-value-formatter';
import {
  D3_CHART_ELEM_SELECTOR,
  DEFAULT_CHART_BUILDER_CONFIG,
  applyBaseConfigToChart,
  type IDCChartBuilder,
  type IDCChartEvent,
  ChartBuildHelpers,
} from './dc-chart-builder';
import { DCTooltip, type IDCTooltip } from './dc-tooltip';

export class DcRowChartBuilder implements IDCChartBuilder<dc.RowChart> {
  constructor(
    private _config: IBaseChartBuilderConfig = {
      ...DEFAULT_CHART_BUILDER_CONFIG,
      width: DEFAULT_CHART_BUILDER_CONFIG.width + 100,
      height: DEFAULT_CHART_BUILDER_CONFIG.height + 50,
    }
  ) {}

  getLabel(config: IChartConfig): string {
    return config.labels.title;
  }

  getChart(
    results: IDCTransformResult[],
    config: IChartConfig,
    eventListener$?: Subject<IDCChartEvent>
  ): dc.BaseMixin<dc.RowChart> | undefined {
    const result = first(results);
    const measure = first(config.builderData.measures);
    if (!result || !measure) {
      return;
    }

    const chart = this.build(D3_CHART_ELEM_SELECTOR, result, config);

    applyBaseConfigToChart(this._config, chart);

    this._buildXAxis(chart, measure.measure);
    chart.gap(1);

    if (config.builderData.groupLimit) {
      chart.cap(config.builderData.groupLimit);
    }

    if (config.builderData.otherGroupLabel) {
      chart.othersLabel(config.builderData.otherGroupLabel);
    }

    chart.label((data: unknown) =>
      isObject(data)
        ? String(get(data, 'key.label', get(data, 'key', '')))
        : String(data)
    );

    if (config.builderData.colourOverride) {
      chart.colorCalculator((data: unknown) =>
        isObject(data)
          ? String(get(data, 'key.colour', get(data, 'key', undefined)))
          : String(data)
      );
    }

    if (eventListener$) {
      chart.on('filtered', (eventChart) =>
        ChartBuildHelpers.onFilteredEvent(eventChart, config, eventListener$)
      );
    }

    return chart;
  }

  build(
    parent: string,
    result: IDCTransformResult,
    config: IChartConfig
  ): dc.RowChart {
    if (config.builderData.measures[0].filters?.length) {
      const { dimension, group } = ChartBuildHelpers.buildFilteredDimension(
        result,
        config,
        config.builderData.measures[0]
      );

      return dc.rowChart(parent).dimension(dimension).group(group);
    }

    return dc.rowChart(parent).dimension(result.dimension).group(result.group);
  }

  // getLegend(): dc.HtmlLegend {
  //   const legend = dc.htmlLegend().horizontal(false).highlightSelected(true);
  //   legend.legendText((data: unknown) =>
  //     isObject(data)
  //       ? String(get(data, 'key.label', get(data, 'key', '')))
  //       : String(data)
  //   );
  //   return legend;
  // }

  getTooltip(config: IChartConfig): IDCTooltip {
    const measure = first(config.builderData.measures);
    const format = DataPointValueFormatter.getFormatter(
      measure?.measure.metadata.formatter,
      measure?.measure.metadata.formatterValue
    );

    return new DCTooltip(
      'g.row',
      (data: unknown) => String(get(data, 'key.label', get(data, 'key', ''))),
      (data: unknown) => format(this._getValue(data))
    );
  }

  private _getValue(data: unknown): string {
    const value = get(data, 'value', '') as unknown;
    if (isObject(value)) {
      return String(value.valueOf());
    }
    return String(value);
  }

  private _buildXAxis(chart: dc.RowChart, measure: CanBeChartedProperty): void {
    const format = DataPointValueFormatter.getFormatter(
      measure.metadata.formatter,
      measure.metadata.formatterValue
    );
    // const pixelsPerTick = 75;
    // const ticks = Math.floor(chart.width() / pixelsPerTick);
    // console.log({ ticks });
    chart.elasticX(true).xAxis().ticks(2).tickFormat(format);

    if (this._config.numberOfTicks) {
      chart.xAxis().ticks(this._config.numberOfTicks);
    }
  }
}
