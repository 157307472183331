import { createEntityAdapter, type EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { type ITreatmentStep } from '@principle-theorem/principle-core/interfaces';
import {
  type ISerialisedEntityState,
  updateEntity,
} from '@principle-theorem/ng-shared';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import {
  TreatmentPlanActions,
  TreatmentStepActions,
} from '../../actions/treatment-plans';

export type ITreatmentStepsState = ISerialisedEntityState<
  WithRef<ITreatmentStep>
>;

export const treatmentStepsAdapter: EntityAdapter<
  SerialisedData<WithRef<ITreatmentStep>>
> = createEntityAdapter({
  selectId: (step) => step.ref.id,
  sortComparer: false,
});

export const initialState: ITreatmentStepsState =
  treatmentStepsAdapter.getInitialState({
    loaded: false,
  });

export const treatmentStepsReducer = createReducer(
  initialState,

  on(
    TreatmentStepActions.loadTreatmentStepsSuccess,
    (state, { treatmentSteps }) =>
      treatmentStepsAdapter.setAll(treatmentSteps, {
        ...state,
        loaded: true,
      })
  ),

  on(TreatmentStepActions.loadTreatmentStepSuccess, (state, { step }) => {
    return treatmentStepsAdapter.setOne(step, state);
  }),

  on(TreatmentStepActions.updateTreatmentStep, (state, { update }) => {
    return treatmentStepsAdapter.updateOne(update, state);
  }),

  on(TreatmentStepActions.selectTreatmentStep, (state, { id }) => ({
    ...state,
    selectedId: id,
  })),

  on(TreatmentStepActions.clearTreatmentStep, (state) => ({
    ...state,
    selectedId: undefined,
  })),

  on(TreatmentPlanActions.removeTreatmentStep, (state, { ref }) =>
    treatmentStepsAdapter.removeOne(ref.id, state)
  ),

  on(TreatmentStepActions.addTreatment, (state, action) => ({
    ...state,
    ...updateEntity(treatmentStepsAdapter, (entityState) => ({
      ...entityState,
      treatments: [...entityState.treatments, action.treatment],
    }))(state, action),
  })),

  on(TreatmentStepActions.updateTreatment, (state, action) => ({
    ...state,
    ...updateEntity(treatmentStepsAdapter, (entityState) => {
      const treatments = entityState.treatments.map((currentTreatment) => {
        if (currentTreatment.uuid !== action.treatment.uuid) {
          return currentTreatment;
        }
        return {
          ...currentTreatment,
          ...action.treatment,
        };
      });
      return {
        ...entityState,
        treatments,
      };
    })(state, action),
  }))
);
