import {
  TypeGuard,
  isObject,
  isTimestamp,
  type Timestamp,
} from '@principle-theorem/shared';
import { isString } from 'lodash';

export interface IMedicareCard {
  number: string;
  subNumerate: string;
  expiryDate?: Timestamp;
}

export interface IHealthFundCard {
  membershipNumber: string;
  memberNumber: string;
  fundCode?: string;
}

export interface IDVACard {
  number: string;
  expiryDate?: Timestamp;
}

export function isHealthFundCard(data: unknown): data is IHealthFundCard {
  return (
    isObject(data) &&
    'membershipNumber' in data &&
    isString(data.membershipNumber) &&
    'memberNumber' in data &&
    isString(data.memberNumber)
  );
}

export function isMedicareCard(data: unknown): data is IMedicareCard {
  return (
    isObject(data) &&
    'number' in data &&
    isString(data.number) &&
    'subNumerate' in data &&
    isString(data.subNumerate)
  );
}

export function isDVACard(data: unknown): data is IDVACard {
  return TypeGuard.interface<IDVACard>({
    number: isString,
    expiryDate: TypeGuard.nilOr(isTimestamp),
  })(data);
}
