<div class="mat-elevation-z1 m-4 bg-white">
  <ng-container *ngIf="dataTable.dataSource.loading$ | async">
    <mat-progress-bar mode="indeterminate" />
  </ng-container>

  <table
    mat-table
    matSort
    [dataSource]="dataTable.dataSource"
    [trackBy]="trackByRequest"
  >
    <ng-container matColumnDef="appointment-date">
      <th mat-header-cell mat-sort-header class="truncate" *matHeaderCellDef>
        Appointment Date
      </th>
      <td mat-cell class="truncate" *matCellDef="let request">
        <pr-formatted-event-field
          field="from"
          [event]="request.event"
          [format]="dateFormat"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="practitioner">
      <th mat-header-cell mat-sort-header class="truncate" *matHeaderCellDef>
        Practitioner
      </th>
      <td mat-cell class="truncate" *matCellDef="let request">
        {{ request.practitioner.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="patient-name">
      <th mat-header-cell mat-sort-header class="truncate" *matHeaderCellDef>
        Patient
      </th>
      <td mat-cell class="truncate" *matCellDef="let request">
        <span *ngIf="request.patient.patient; else primaryContact">
          {{ request.patient.patient.name }}
        </span>
        <ng-template #primaryContact>
          {{ request.patient.primaryContact.name }}
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="treatment">
      <th mat-header-cell class="truncate" mat-sort-header *matHeaderCellDef>
        Treatment
      </th>
      <td mat-cell class="truncate" *matCellDef="let request">
        {{ request.template.step.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell class="truncate" *matHeaderCellDef></th>
      <td mat-cell class="truncate" *matCellDef="let request">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px">
          <button
            mat-icon-button
            color="warn"
            matTooltip="Cancel"
            (click)="cancelAppointmentRequest(request)"
          >
            <mat-icon>close</mat-icon>
          </button>
          <button
            class="confirm-button"
            mat-icon-button
            color="primary"
            matTooltip="Confirm"
            (click)="confirmAppointmentRequest(request)"
          >
            <mat-icon>check</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="dataTable.displayColumns"></tr>
    <tr
      mat-row
      *matRowDef="let contact; columns: dataTable.displayColumns"
    ></tr>
  </table>
</div>
