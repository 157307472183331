import { isObject } from './common';
import {
  type CollectionReference,
  type DocumentReference,
  type Transaction as FirebaseTransaction,
  type Timestamp,
} from './firebase/firestore/adaptor';
import { addDoc } from './firebase/firestore/document';
import { type Reffable } from './firebase/interfaces';
import { isTimestamp, sortTimestamp } from './firebase/timestamp';
import { HISTORY_DATE_TIME_FORMAT } from './time/date-time-formatting';
import { toMoment, toTimestamp } from './time/time';

interface IArchivedDocument {
  archivedAt: Timestamp;
  archivedBy?: DocumentReference;
}

export type ArchivedDocument<T> = T & IArchivedDocument;

export function MockArchivedDocument<T>(item: T): ArchivedDocument<T> {
  return {
    ...item,
    archivedAt: toTimestamp(),
  };
}

export class DocumentArchive {
  static snapshotToArchive<T>(
    currentDoc: Reffable<T>,
    archiveCollection: CollectionReference<ArchivedDocument<T>>,
    archivedAt: Timestamp = toTimestamp(),
    archivedBy?: DocumentReference,
    atomicTransaction?: FirebaseTransaction
  ): Promise<DocumentReference<ArchivedDocument<T>>> {
    return addDoc(
      archiveCollection,
      {
        ...currentDoc,
        archivedAt,
        archivedBy,
      },
      undefined,
      atomicTransaction
    );
  }

  static sortByArchivedAt<T>(
    a: ArchivedDocument<T>,
    b: ArchivedDocument<T>
  ): number {
    return sortTimestamp(a.archivedAt, b.archivedAt);
  }

  static getLabel<T>(item: ArchivedDocument<T>): string {
    return toMoment(item.archivedAt).format(HISTORY_DATE_TIME_FORMAT);
  }

  static isArchivedDocument<T>(data: T): data is ArchivedDocument<T> {
    return isObject(data) && isTimestamp(data.archivedAt);
  }

  static findVersionAsOfDate<T>(
    archiveDocuments: ArchivedDocument<T>[],
    asOfDate: Timestamp
  ): ArchivedDocument<T> | undefined {
    const asOfMoment = toMoment(asOfDate);
    return archiveDocuments
      .sort((a, b) => this.sortByArchivedAt(a, b))
      .reverse()
      .find((item) => toMoment(item.archivedAt).isAfter(asOfMoment));
  }
}
