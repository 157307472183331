<div
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="16px"
  *ngIf="gap$ | async as gap"
>
  <ng-container *ngIf="showPractitionerIcon">
    <pt-user-icon
      [diameter]="mediaDiameter"
      [name]="stafferName$ | async"
      [src]="stafferImage$ | async"
    />
  </ng-container>

  <pr-eventable-header-template
    [start]="
      gap.event.from | momentTimezone$ : gap.event.practice.ref | ngrxPush
    "
    [end]="gap.event.to | momentTimezone$ : gap.event.practice.ref | ngrxPush"
    [title]="gap.event.type | titlecase"
  >
    <div
      prEventableHeaderIcon
      class="type-icon gap"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <mat-icon>{{ gapIcon }}</mat-icon>
    </div>
  </pr-eventable-header-template>
</div>
