import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pr-health-fund-card-preview',
  templateUrl: './health-fund-card-preview.component.html',
  styleUrls: ['./health-fund-card-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex min-w-[100px] max-w-xs flex-col gap-4 rounded-md p-2' },
})
export class HealthFundCardPreviewComponent {
  @Input() hexColour: string;
}
