import {
  CollectionGroup,
  EventType,
  NotificationType,
  isAppointmentResource,
  type IAppointment,
  type IAppointmentResources,
  type IBrand,
  type IEvent,
  type IEventHistory,
  type IEventTimePeriod,
  type IPatient,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  DataFormat,
  Firestore,
  QueryConstraint,
  RequireProps,
  WithRef,
  all$,
  asDocRef,
  collectionGroupQuery,
  getEnumValues,
  isSameRef,
  multiFilter,
  multiMap,
  multiSwitchMap,
  query$,
  reduce2DArray,
  reduceToSingleArray,
  safeCombineLatest,
  shareReplayCold,
  snapshot,
  toMomentTz,
  toTimestamp,
  where,
  type DocumentReference,
  type IDataTable,
  type INamedDocument,
  type IReffable,
  type ITimePeriod,
  type ITimestampRange,
} from '@principle-theorem/shared';
import {
  Dictionary,
  chunk,
  difference,
  flatten,
  groupBy,
  mapValues,
  sortBy,
  sum,
  toPairs,
  uniqWith,
} from 'lodash';
import { Observable, iif, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Brand } from '../models/brand';
import { practiceStaffAvailableTimes } from '../models/event/brand-event-option-finder';
import { Event } from '../models/event/event';
import { OptionFinderQuery } from '../models/event/event-option-finder';
import { Staffer } from '../models/staffer/staffer';
import { dateRangeToBrandTimezone } from './helpers';

interface IRow {
  practitionerRef: DocumentReference<IStaffer>;
  practitioner: string;
  practice: string;
  week: string;
  ftas: number;
  ftaPercent: number;
  utas: number;
  utaPercent: number;
  cancelled: number;
  cancelledPercent: number;
  rescheduled: number;
  rescheduledPercent: number;
  totalAppointmentsPerformed: number;
  bookedAppointmentsPerformedPercent: number;
  totalAppointmentsBooked: number;
}

interface IAppointmentRow {
  date: string;
  patient: string;
  practitioner: string;
  practice: string;
  fta: boolean;
  uta: boolean;
  cancelled: boolean;
  rescheduled: boolean;
  timeBeforeAppointment: number;
}

interface IPractitionerChartRow {
  practitioner: string;
  practice: string;
  bookedAppointmentsPerformedPercent: number;
  average: number;
}

interface IEventMetadata {
  staffer: INamedDocument<IStaffer>;
  practice: INamedDocument<IPractice>;
  from: FirebaseFirestore.Timestamp;
  to: FirebaseFirestore.Timestamp;
}

type PractitionerMap = Record<string, IEventMetadata[]>;
type TotalAppointments = Record<
  string,
  WithRef<RequireProps<IAppointment, 'event'>>[]
>;

export class PractitionerFTAVsUTARates {
  async run(
    dateRange: ITimePeriod,
    brandRef: DocumentReference<IBrand>,
    skipPractitioners: DocumentReference<IStaffer>[] = []
  ): Promise<
    (
      | IDataTable<IRow>
      | IDataTable<IAppointmentRow>
      | IDataTable<IPractitionerChartRow>
    )[]
  > {
    const brand = await Firestore.getDoc(brandRef);
    const brandDateRange = dateRangeToBrandTimezone(brand, dateRange);

    const appointmentRows = await this._getAppointmentsData(
      brand,
      brandDateRange
    );

    const byPractitionerData = await this._getPractitionersData(
      brand,
      brandDateRange,
      appointmentRows,
      skipPractitioners
    );

    const appointmentDrilldownData = this._toAppointmentRecords(
      brand,
      appointmentRows,
      skipPractitioners
    );

    const practitionerChartRows =
      this._toPractitionerChartRows(byPractitionerData);
    return this._toDataTables(
      byPractitionerData,
      appointmentDrilldownData,
      practitionerChartRows
    );
  }

  private async _getPractitionerMap(
    brand: WithRef<IBrand>,
    dateRange: ITimePeriod,
    appointmentGroups: Dictionary<IReportStats[]>
  ): Promise<PractitionerMap> {
    const includedTypes = difference(getEnumValues(EventType), [
      EventType.GapCandidate,
      EventType.PreBlock,
    ]);

    const availableTimes$ = Brand.practices$(brand).pipe(
      multiSwitchMap((practice) => {
        const query: OptionFinderQuery = [dateRange.from, dateRange.to];
        return of(query).pipe(
          practiceStaffAvailableTimes(
            practice,
            brand,
            undefined,
            true,
            undefined,
            includedTypes
          )
        );
      }),
      reduce2DArray(),
      take(1),
      multiMap((event: IEventTimePeriod) => ({
        staffer: event.staffer,
        practice: event.practice,
        from: toTimestamp(event.from),
        to: toTimestamp(event.to),
      }))
    );

    const availableTimes = await snapshot(availableTimes$);
    return {
      ...mapValues(appointmentGroups, (appointment) => [
        {
          staffer: appointment[0].appointment.practitioner,
          practice: appointment[0].appointment.practice,
          from: appointment[0].originalEvent.from,
          to: appointment[0].originalEvent.to,
        },
      ]),
      ...groupBy(availableTimes, (availableTime) =>
        buildWeeklyPractitionerGroupBy(
          brand,
          availableTime,
          availableTime.staffer,
          availableTime.practice
        )
      ),
    };
  }

  private async _getAppointmentsData(
    brand: WithRef<IBrand>,
    dateRange: ITimePeriod
  ): Promise<IReportStats[]> {
    const notifications$ = Brand.staff$(brand).pipe(
      multiSwitchMap((staffer) =>
        query$(
          Staffer.notificationCol(staffer),
          where('type', 'in', [
            NotificationType.StafferCancelledAppointment,
            NotificationType.StafferRescheduledAppointment,
          ])
        ).pipe(
          take(1),
          multiFilter((notification) =>
            toMomentTz(
              notification.createdAt,
              brand.settings.timezone
            ).isBetween(dateRange.from, dateRange.to)
          )
        )
      ),
      reduce2DArray()
    );

    const appointments$ = notifications$.pipe(
      multiMap((notification) => notification.resources),
      map((resources) =>
        resources.filter(
          (resource): resource is IAppointmentResources<unknown> =>
            isAppointmentResource(resource)
        )
      ),
      multiSwitchMap((resources) =>
        Firestore.doc$(asDocRef<IAppointment>(resources.appointment.ref))
      ),
      map((appointments) => uniqWith(appointments, isSameRef)),
      take(1),
      multiMap((appointment) =>
        this._findEvents(brand, appointment, dateRange)
      ),
      reduce2DArray(),
      shareReplayCold()
    );

    return snapshot(appointments$);
  }

  private async _getPractitionersData(
    brand: WithRef<IBrand>,
    dateRange: ITimePeriod,
    appointmentRows: IReportStats[],
    skipPractitioners: DocumentReference<IStaffer>[]
  ): Promise<IRow[]> {
    const appointmentGroups = groupBy(appointmentRows, (result) =>
      buildWeeklyPractitionerGroupBy(
        brand,
        result.originalEvent,
        result.appointment.practitioner,
        result.appointment.practice
      )
    );

    const practitioners = await this._getPractitionerMap(
      brand,
      dateRange,
      appointmentGroups
    );

    const totalAppointments = await this._getTotalAppointments(
      brand,
      dateRange
    );

    return this._toPractitionerResults(
      brand,
      practitioners,
      appointmentGroups,
      totalAppointments,
      skipPractitioners
    );
  }

  private async _getTotalAppointments(
    brand: WithRef<IBrand>,
    dateRange: ITimePeriod
  ): Promise<TotalAppointments> {
    const appointments$ = this._appointmentsForBrand$(brand, dateRange);
    const appointments = await snapshot(appointments$);

    return groupBy(appointments, (appointment) =>
      buildWeeklyPractitionerGroupBy(
        brand,
        appointment.event,
        appointment.practitioner,
        appointment.practice
      )
    );
  }

  private _toAppointmentRecords(
    brand: WithRef<IBrand>,
    rows: IReportStats[],
    skipPractitioners: DocumentReference<IStaffer>[]
  ): IAppointmentRow[] {
    const records = rows
      .filter(
        (row) =>
          !this._skipPractitioner(
            row.appointment.practitioner.ref,
            skipPractitioners
          )
      )
      .map((row) => ({
        date: toMomentTz(
          row.originalEvent.from,
          brand.settings.timezone
        ).format(DAY_MONTH_YEAR_FORMAT),
        patient: Event.patients(row.originalEvent)?.[0]?.name,
        practitioner: row.appointment.practitioner.name,
        practice: row.appointment.practice.name,
        fta: row.fta,
        uta: row.uta,
        cancelled: row.cancelled,
        rescheduled: row.rescheduled,
        timeBeforeAppointment: row.timeBeforeAppointment,
      }));

    return sortBy(records, 'name');
  }

  private _toPractitionerResults(
    brand: WithRef<IBrand>,
    practitioners: PractitionerMap,
    results: Dictionary<IReportStats[]>,
    totalAppointments: Dictionary<
      WithRef<RequireProps<IAppointment, 'event'>>[]
    >,
    skipPractitioners: DocumentReference<IStaffer>[]
  ): IRow[] {
    const rows = Object.entries(practitioners)
      .map(([key, events]) => {
        const appointments = results[key] ?? [];
        const totalAppointmentsPerformed = totalAppointments[key]?.length ?? 0;

        const ftas = appointments.filter(
          (appointment) => appointment.fta
        ).length;
        const utas = appointments.filter(
          (appointment) => appointment.uta
        ).length;
        const cancelled = appointments
          .filter((event) => event.fta || event.uta)
          .filter((appointment) => appointment.cancelled).length;
        const rescheduled = appointments
          .filter((event) => event.fta || event.uta)
          .filter((appointment) => appointment.rescheduled).length;
        const totalCancelled = appointments.filter(
          (appointment) => appointment.cancelled
        ).length;
        const totalRescheduled = appointments.filter(
          (appointment) => appointment.rescheduled
        ).length;

        const totalAppointmentsBooked =
          totalAppointmentsPerformed + utas + ftas;
        const bookedAppointmentsPerformedPercent =
          totalAppointmentsPerformed / totalAppointmentsBooked;

        const ftaPercent = ftas / totalAppointmentsBooked;
        const utaPercent = utas / totalAppointmentsBooked;
        const cancelledPercent = cancelled / totalAppointmentsBooked;
        const rescheduledPercent = rescheduled / totalAppointmentsBooked;

        const week = toMomentTz(events[0].from, brand.settings.timezone)
          .startOf('week')
          .format(DAY_MONTH_YEAR_FORMAT);

        return {
          practitioner: events[0].staffer.name,
          practitionerRef: events[0].staffer.ref,
          practice: events[0].practice.name,
          week,
          ftas,
          ftaPercent,
          utas,
          utaPercent,
          cancelled,
          cancelledPercent,
          rescheduled,
          rescheduledPercent,
          totalCancelled,
          totalRescheduled,
          totalAppointmentsPerformed,
          bookedAppointmentsPerformedPercent,
          totalAppointmentsBooked,
        };
      })
      .filter(
        (row) => !this._skipPractitioner(row.practitionerRef, skipPractitioners)
      );

    return sortBy(rows, 'name');
  }

  private _skipPractitioner(
    stafferRef: DocumentReference<IStaffer>,
    skipPractitioners: DocumentReference<IStaffer>[]
  ): boolean {
    return skipPractitioners
      .map((practitioner) => practitioner.path)
      .includes(stafferRef.path);
  }

  private _findEvents(
    brand: WithRef<IBrand>,
    appointment: WithRef<IAppointment>,
    request: ITimePeriod
  ): IReportStats[] {
    const validEventChanges: {
      event: Required<IEventHistory>;
      rescheduled: boolean;
      cancelled: boolean;
    }[] = [];

    const eventHistory: IEventHistory[] = [
      ...appointment.eventHistory,
      { createdAt: appointment.updatedAt, event: appointment.event },
    ];

    eventHistory.reduce((lastEvent, currentEvent) => {
      if (!lastEvent.event) {
        return currentEvent;
      }

      const lastFrom = toMomentTz(
        lastEvent.event.from,
        brand.settings.timezone
      );

      if (lastFrom.isBetween(request.from, request.to) && !currentEvent.event) {
        validEventChanges.push({
          event: lastEvent as Required<IEventHistory>,
          cancelled: true,
          rescheduled: false,
        });
        return currentEvent;
      }

      if (
        lastFrom.isBetween(request.from, request.to) &&
        currentEvent.event &&
        !toMomentTz(currentEvent.event.from, brand.settings.timezone).isSame(
          lastFrom,
          'day'
        )
      ) {
        validEventChanges.push({
          event: lastEvent as Required<IEventHistory>,
          cancelled: false,
          rescheduled: true,
        });
        return currentEvent;
      }

      return currentEvent;
    });

    return validEventChanges.map((eventChange) => {
      const timeBeforeAppointment = toMomentTz(
        eventChange.event.event.from,
        brand.settings.timezone
      ).diff(
        toMomentTz(eventChange.event.createdAt, brand.settings.timezone),
        'hours'
      );

      if (timeBeforeAppointment < 24) {
        return {
          fta: true,
          uta: false,
          cancelled: eventChange.cancelled,
          rescheduled: eventChange.rescheduled,
          appointment,
          timeBeforeAppointment,
          originalEvent: eventChange.event.event,
        };
      }

      if (timeBeforeAppointment >= 24 && timeBeforeAppointment <= 48) {
        return {
          fta: false,
          uta: true,
          cancelled: eventChange.cancelled,
          rescheduled: eventChange.rescheduled,
          appointment,
          timeBeforeAppointment,
          originalEvent: eventChange.event.event,
        };
      }

      return {
        fta: false,
        uta: false,
        cancelled: eventChange.cancelled,
        rescheduled: eventChange.rescheduled,
        appointment,
        timeBeforeAppointment,
        originalEvent: eventChange.event.event,
      };
    });
  }

  private _appointmentsForBrand$(
    brand: IReffable<IBrand>,
    range: ITimePeriod,
    participants?: DocumentReference<IStaffer | IPatient>[],
    practices?: IReffable<IPractice>[]
  ): Observable<WithRef<RequireProps<IAppointment, 'event'>>[]> {
    return iif(
      () => !!practices?.length,
      of(practices ?? []),
      Brand.practices$(brand).pipe(
        multiMap((practice) => ({ ref: practice.ref }))
      )
    ).pipe(
      multiSwitchMap((practice) => {
        if (!participants || participants.length < 10) {
          return this._appointmentsForPractice$(practice, range, participants);
        }

        const participantGroups = chunk(participants, 10);
        return safeCombineLatest(
          participantGroups.map((group) =>
            this._appointmentsForPractice$(practice, range, group)
          )
        ).pipe(map(reduceToSingleArray));
      }),
      map(reduceToSingleArray)
    );
  }

  private _appointmentsForPractice$(
    practice: IReffable<IPractice>,
    range: ITimePeriod,
    participants?: DocumentReference<IStaffer | IPatient>[]
  ): Observable<WithRef<RequireProps<IAppointment, 'event'>>[]> {
    const constraints: QueryConstraint[] = [
      where('event.practice.ref', '==', practice.ref),
      where('event.from', '>=', range.from.toDate()),
      where('event.from', '<=', range.to.toDate()),
    ];

    if (participants) {
      constraints.push(
        where('event.participantRefs', 'array-contains-any', participants)
      );
    }

    return all$(
      collectionGroupQuery<RequireProps<IAppointment, 'event'>>(
        CollectionGroup.Appointments,
        ...constraints
      )
    );
  }

  private _toPractitionerChartRows(data: IRow[]): IPractitionerChartRow[] {
    const byPractice = groupBy(data, (item) => item.practice);
    const nestedRows = toPairs(byPractice).map(
      ([practice, practiceRows]): IPractitionerChartRow[] => {
        const totalAppointmentsBooked = sum(
          practiceRows.map((practiceRow) => practiceRow.totalAppointmentsBooked)
        );
        const totalAppointmentsPerformed = sum(
          practiceRows.map(
            (practiceRow) => practiceRow.totalAppointmentsPerformed
          )
        );
        const average = totalAppointmentsPerformed / totalAppointmentsBooked;

        const practitionerRows = practiceRows.map(
          (practiceRow): IPractitionerChartRow => ({
            practice: practiceRow.practice,
            practitioner: practiceRow.practitioner,
            bookedAppointmentsPerformedPercent:
              practiceRow.bookedAppointmentsPerformedPercent,
            average,
          })
        );

        const averageRow: IPractitionerChartRow = {
          practice: practice,
          practitioner: 'Average',
          bookedAppointmentsPerformedPercent: average,
          average,
        };

        return [...practitionerRows, averageRow];
      }
    );

    return flatten(nestedRows);
  }

  private _toDataTables(
    byPractitionerData: IRow[],
    appointmentDrilldownData: IAppointmentRow[],
    practitionerChartData: IPractitionerChartRow[]
  ): (
    | IDataTable<IRow>
    | IDataTable<IAppointmentRow>
    | IDataTable<IPractitionerChartRow>
  )[] {
    const byPractitionerSheet: IDataTable<IRow> = {
      name: 'FTA & UTA by Practitioner',
      data: byPractitionerData,
      columns: [
        { key: 'week', header: 'Week' },
        { key: 'practitioner', header: 'Practitioner' },
        { key: 'practice', header: 'Practice' },
        { key: 'ftas', header: 'FTAs' },
        {
          key: 'ftaPercent',
          header: 'FTA %',
          format: DataFormat.Percent,
        },
        { key: 'utas', header: 'UTAs' },
        {
          key: 'utaPercent',
          header: 'UTA %',
          format: DataFormat.Percent,
        },
        { key: 'cancelled', header: 'Cancelled' },
        {
          key: 'cancelledPercent',
          header: 'Cancelled %',
          format: DataFormat.Percent,
        },
        { key: 'rescheduled', header: 'Rescheduled' },
        {
          key: 'rescheduledPercent',
          header: 'Rescheduled %',
          format: DataFormat.Percent,
        },
        {
          key: 'totalAppointmentsPerformed',
          header: 'Total Appointments Performed',
        },
        {
          key: 'bookedAppointmentsPerformedPercent',
          header: '% Booked Appointments Performed %',
          format: DataFormat.Percent,
        },
        { key: 'totalAppointmentsBooked', header: 'Total Appointments Booked' },
      ],
    };

    const appointmentDrilldownSheet: IDataTable<IAppointmentRow> = {
      name: 'FTA & UTA Appointments',
      data: appointmentDrilldownData,
      columns: [
        { key: 'date', header: 'Date' },
        { key: 'patient', header: 'Patient' },
        { key: 'practitioner', header: 'Practitioner' },
        { key: 'practice', header: 'Practice' },
        { key: 'fta', header: 'FTA' },
        { key: 'uta', header: 'UTA' },
        { key: 'cancelled', header: 'Cancelled' },
        { key: 'rescheduled', header: 'Rescheduled' },
        { key: 'timeBeforeAppointment', header: 'Before Appointment (hours)' },
      ],
    };

    const practitionerChartSheet: IDataTable<IPractitionerChartRow> = {
      name: 'Chart Data',
      data: practitionerChartData,
      columns: [
        { key: 'practitioner', header: 'Practitioner' },
        { key: 'practice', header: 'Practice' },
        {
          key: 'bookedAppointmentsPerformedPercent',
          header: '% Booked Appointments Seen',
          format: DataFormat.Percent,
        },
        {
          key: 'average',
          header: 'Practice Average',
          format: DataFormat.Percent,
        },
      ],
    };

    return [
      byPractitionerSheet,
      appointmentDrilldownSheet,
      practitionerChartSheet,
    ];
  }
}

export function buildWeeklyPractitionerGroupBy(
  brand: WithRef<IBrand>,
  event: ITimestampRange | ITimePeriod,
  practitioner: INamedDocument<IStaffer>,
  practice: INamedDocument<IPractice>
): string {
  return [
    toMomentTz(event.from, brand.settings.timezone)
      .startOf('week')
      .format(DAY_MONTH_YEAR_FORMAT),
    practitioner.name,
    practice.ref.id,
  ].join('-');
}

interface IReportStats {
  fta: boolean;
  uta: boolean;
  cancelled: boolean;
  rescheduled: boolean;
  timeBeforeAppointment: number;
  appointment: WithRef<IAppointment>;
  originalEvent: IEvent;
}
