import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type INamedDocument, type WithRef } from '@principle-theorem/shared';
import {
  type IAppointment,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { Appointment } from '@principle-theorem/principle-core';
import { type Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-appointment-practice',
  templateUrl: './appointment-practice.component.html',
  styleUrls: ['./appointment-practice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentPracticeComponent {
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);
  practice$: Observable<INamedDocument<IPractice> | undefined>;

  constructor() {
    this.practice$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.practice$(appointment))
    );
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }
}
