import { roundTo2Decimals } from '@principle-theorem/accounting';
import {
  ICustomLineItem,
  IInvoice,
  IInvoiceTransactionAllocations,
  ITransaction,
  ITransactionAllocation,
  SpecialTransactionAllocationTarget,
  TransactionAllocationTarget,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  ExpectationContainer,
  getExpectationErrors as getExpectationErrors,
  WithRef,
} from '@principle-theorem/shared';
import { compact, sum, uniq, uniqWith } from 'lodash';
import { determineTransactionSign } from '../../../transaction/transaction';
import { TransactionOperators } from '../../../transaction/transaction-operators';
import { Invoice } from '../../invoice';
import { TransactionAllocation } from '../../transaction-allocation';
import { AllocationTarget } from '../allocation-target';

interface ISanityCheckRequest {
  invoice: WithRef<IInvoice>;
  transactions: WithRef<ITransaction>[];
  transactionAllocations: IInvoiceTransactionAllocations[];
}

export interface ITransactionAllocationSanityCheck {
  transactionAllocationsCountErrors: string[]; // should have the same number of Transaction Allocations as Transactions
  decimalPlaceErrors: string[]; // should not have any allocations with more than 2 decimal places
  lessThanOneCentErrors: string[]; // should not have any allocations less than 1 cent
  allocateFullTransactionErrors: string[]; // should allocate the exact amount of each transaction
  overallocationErrors: string[]; // should not allocate more than a practitioner has invoiced for
  totalAllocatedAmountErrors: string[]; // should have allocated the same amount taken across all transactions
}

interface ITransactionGroup {
  transactionRef: DocumentReference<ITransaction>;
  transaction?: WithRef<ITransaction>;
  allocations: ITransactionAllocation[];
}

interface IAllocatedToGroup {
  allocatedTo: TransactionAllocationTarget;
  invoiceItems: ICustomLineItem[];
  allocations: ITransactionAllocation[];
}

export class TransactionAllocationSanityCheck {
  static perform(
    request: ISanityCheckRequest
  ): ITransactionAllocationSanityCheck {
    return {
      transactionAllocationsCountErrors: getExpectationErrors((test) =>
        this.testTransactionAllocationsCount(request, test)
      ),
      decimalPlaceErrors: getExpectationErrors((test) =>
        this.testDecimalPlaces(request, test)
      ),
      lessThanOneCentErrors: getExpectationErrors((test) =>
        this.testLessThanOneCent(request, test)
      ),
      allocateFullTransactionErrors: getExpectationErrors((test) =>
        this.testAllocateFullTransaction(request, test)
      ),
      overallocationErrors: getExpectationErrors((test) =>
        this.testForOverallocations(request, test)
      ),
      totalAllocatedAmountErrors: getExpectationErrors((test) =>
        this.testTotalAllocatedAmount(request, test)
      ),
    };
  }

  /**
   * it should have the same number of Transaction Allocations as Transactions
   */
  static testTransactionAllocationsCount(
    request: ISanityCheckRequest,
    test: ExpectationContainer
  ): void {
    test
      .expect(request.transactionAllocations.length)
      .toEqual(
        new TransactionOperators(request.transactions).completed().count()
      );
  }

  /**
   * it should not have any allocations with more than 2 decimal places
   */
  static testDecimalPlaces(
    request: ISanityCheckRequest,
    test: ExpectationContainer
  ): void {
    this.getTransactionAllocations(request).map((allocation) =>
      test
        .expect(allocation.allocatedAmount)
        .toEqual(roundTo2Decimals(allocation.allocatedAmount))
    );
  }

  /**
   * it should not have any allocations less than 1 cent
   */
  static testLessThanOneCent(
    request: ISanityCheckRequest,
    test: ExpectationContainer
  ): void {
    this.getTransactionAllocations(request).map((allocation) =>
      test
        .expect(Math.abs(allocation.allocatedAmount))
        .toBeGreaterThanOrEqual(0.01)
    );
  }

  /**
   * it should allocate the exact amount of each transaction
   */
  static testAllocateFullTransaction(
    request: ISanityCheckRequest,
    test: ExpectationContainer
  ): void {
    this.groupByTransaction(request).map((group) => {
      const allocationAmounts = group.allocations.map(
        (allocation) => allocation.allocatedAmount
      );
      const allocationTotal = roundTo2Decimals(sum(allocationAmounts));

      test.expect(allocationTotal).toBeDefined();
      if (!group.transaction) {
        return;
      }

      const transactionAmount = roundTo2Decimals(
        determineTransactionSign(
          group.transaction.type,
          group.transaction.amount
        )
      );
      test.expect(allocationTotal).toEqual(transactionAmount);
    });
  }

  /**
   * it should not allocate more than a practitioner has invoiced for
   */
  static testForOverallocations(
    request: ISanityCheckRequest,
    test: ExpectationContainer
  ): void {
    const invoiceTotal = Invoice.total(request.invoice);
    const paymentsTotal = roundTo2Decimals(
      new TransactionOperators(request.transactions).paidToDate()
    );
    const invoiceIsFullyPaid = paymentsTotal >= invoiceTotal;
    const overpaymentAmount = roundTo2Decimals(paymentsTotal - invoiceTotal);

    const specificallyAllocatedStaff = compact(
      request.transactions.map((transaction) =>
        TransactionAllocation.getTransactionAllocatedTo(transaction)
      )
    );

    this.groupByAllocatedTo(request).map((group) => {
      const invoicedAmounts = group.invoiceItems.map(
        (item) => item.amount * (item.quantity ?? 1)
      );
      const invoicedAmount = roundTo2Decimals(sum(invoicedAmounts));
      const allocationAmounts = group.allocations.map(
        (allocation) => allocation.allocatedAmount
      );
      const allocationAmount = roundTo2Decimals(sum(allocationAmounts));

      if (
        invoiceIsFullyPaid &&
        AllocationTarget.isUnallocated(group.allocatedTo)
      ) {
        const expectedAmount = roundTo2Decimals(
          invoicedAmount + overpaymentAmount
        );

        test.expect(allocationAmount).toBeCloseTo(expectedAmount, 10);
        return;
      }
      const hasSpecificallyAllocatedTransaction =
        specificallyAllocatedStaff.some((transactionAllocatedTo) =>
          AllocationTarget.isSame(transactionAllocatedTo, group.allocatedTo)
        );
      if (hasSpecificallyAllocatedTransaction) {
        // Skip as we can't assert the amount that should be allocated.
        return;
      }
      test.expect(allocationAmount).toBeLessThanOrEqual(invoicedAmount);
    });
  }

  /**
   * it should have allocated the same amount taken across all transactions
   */
  static testTotalAllocatedAmount(
    request: ISanityCheckRequest,
    test: ExpectationContainer
  ): void {
    const paymentsTotal = roundTo2Decimals(
      new TransactionOperators(request.transactions).paidToDate()
    );
    const allocatedAmounts = this.getTransactionAllocations(request).map(
      (allocation) => allocation.allocatedAmount
    );
    const totalAllocated = roundTo2Decimals(sum(allocatedAmounts));
    test.expect(totalAllocated).toEqual(paymentsTotal);
  }

  // ---------------------------- Helpers ---------------------------- //

  static getTransactionAllocations(
    request: ISanityCheckRequest
  ): ITransactionAllocation[] {
    return request.transactionAllocations
      .map((transactionAllocation) => transactionAllocation.allocations)
      .flat();
  }

  static groupByTransaction(request: ISanityCheckRequest): ITransactionGroup[] {
    const transactionRefsFromAllocations = request.transactionAllocations.map(
      (transactionAllocation) => transactionAllocation.transactionRef.path
    );
    const transactionRefsFromTransactions = request.transactions.map(
      (transaction) => transaction.ref.path
    );
    const transactionGroups = uniq([
      ...transactionRefsFromAllocations,
      ...transactionRefsFromTransactions,
    ]).map((transactionRefPath) => {
      const transactionAllocation = request.transactionAllocations.find(
        (allocation) => allocation.transactionRef.path === transactionRefPath
      );
      const groupTransaction = request.transactions.find(
        (requestTransaction) =>
          requestTransaction.ref.path === transactionRefPath
      );
      if (!transactionAllocation || !groupTransaction) {
        return;
      }
      return {
        transactionRef:
          groupTransaction?.ref ?? transactionAllocation?.transactionRef,
        allocations: transactionAllocation?.allocations ?? [],
        transaction: groupTransaction,
      };
    });
    return compact(transactionGroups);
  }

  static groupByAllocatedTo(result: ISanityCheckRequest): IAllocatedToGroup[] {
    const lineItems = result.invoice.items.map((lineItem) => ({
      lineItem,
      allocatedTo:
        Invoice.getLineItemAllocatedTo(lineItem, false)?.ref ??
        SpecialTransactionAllocationTarget.Unallocated,
    }));

    const allAllocations = this.getTransactionAllocations(result);

    const allocatedTo = uniqWith(
      [
        ...allAllocations.map((allocation) => allocation.allocatedTo),
        ...lineItems.map((lineItem) => lineItem.allocatedTo),
      ],
      (aTarget, bTarget) => AllocationTarget.isSame(aTarget, bTarget)
    );

    return allocatedTo.map((allocationTarget) => {
      const invoiceItems = lineItems
        .filter((lineItem) =>
          AllocationTarget.isSame(lineItem.allocatedTo, allocationTarget)
        )
        .map((lineItem) => lineItem.lineItem);

      const allocations = allAllocations.filter((allocation) =>
        AllocationTarget.isSame(allocation.allocatedTo, allocationTarget)
      );
      return { allocatedTo: allocationTarget, invoiceItems, allocations };
    });
  }
}
