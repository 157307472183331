import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ChartFacade,
  type ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { BaseMenuComponent } from '@principle-theorem/ng-core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IChartedItemDetail,
  type IDentalChartViewSurface,
  type ITooth,
} from '@principle-theorem/principle-core/interfaces';
import { shareReplayCold, snapshot } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CHART_ENTITY_ID } from '../../chart-entity-id';
import { ROOT_OPTIONS } from '../../models/chart-tooth-surfaces-svg-map';

@Component({
    selector: 'pr-chart-tooth-menu',
    templateUrl: './chart-tooth-menu.component.html',
    styleUrls: ['./chart-tooth-menu.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'prChartToothMenu',
    standalone: false
})
export class ChartToothMenuComponent extends BaseMenuComponent {
  private _chartId: ChartId = inject(CHART_ENTITY_ID);
  trackByRoot = TrackByFunctions.variable<number>();
  tooth$: ReplaySubject<ITooth> = new ReplaySubject(1);
  rootOptions: number[] = ROOT_OPTIONS;
  chartedItems$: Observable<IChartedItemDetail[]>;
  view$: ReplaySubject<IDentalChartViewSurface> = new ReplaySubject(1);
  disabled$: Observable<boolean>;

  constructor(
    private _snackBar: MatSnackBar,
    private _chartStore: ChartFacade
  ) {
    super();

    this.chartedItems$ = this.view$.pipe(
      map((view: IDentalChartViewSurface) => view.chartedItems),
      shareReplayCold()
    );

    this.disabled$ = this._chartStore
      .canEdit$(this._chartId)
      .pipe(map((canStore) => !canStore));
  }

  @Input()
  set tooth(tooth: ITooth) {
    this.tooth$.next(tooth);
  }

  @Input()
  set view(view: IDentalChartViewSurface) {
    this.view$.next(view);
  }

  changeRoots(roots: number, tooth: ITooth): void {
    this._chartStore.updateToothRoots(this._chartId, tooth.toothRef, roots);
    this._snackBar.open(`Updated Roots to ${roots}`);
  }

  async removeTooth(tooth: ITooth): Promise<void> {
    this._chartStore.removeTooth(this._chartId, tooth.toothRef);
    this._snackBar.open('Tooth Removed');
    const menu = await snapshot(this.menu$);
    menu.closed.next('click');
  }
}
