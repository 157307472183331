import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { CalendarFacade } from '@principle-theorem/ng-calendar/store';
import {
  CurrentPracticeScope,
  ISchedulingEventFilterFormData,
  SCHEDULING_EVENT_FILTER_FORM_DEFAULT_DATA,
  ScheduleHistoryQueryByDate,
} from '@principle-theorem/ng-principle-shared';
import {
  TypedFormControl,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  CASUAL_DATE_FORMAT,
  debounceUserInput,
  filterUndefined,
  shareReplayCold,
} from '@principle-theorem/shared';
import { Observable, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { ScheduleHistoryService } from './schedule-history.service';

@Component({
    selector: 'pr-schedule-history',
    templateUrl: './schedule-history.component.html',
    styleUrls: ['./schedule-history.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ScheduleHistoryService],
    standalone: false
})
export class ScheduleHistoryComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  readonly dateFormat = CASUAL_DATE_FORMAT;
  filterControl = new TypedFormControl<ISchedulingEventFilterFormData>(
    SCHEDULING_EVENT_FILTER_FORM_DEFAULT_DATA
  );

  constructor(
    calendarFacade: CalendarFacade,
    practiceScope: CurrentPracticeScope,
    public scheduleHistory: ScheduleHistoryService
  ) {
    const filterFormData$ = formControlChanges$(this.filterControl).pipe(
      filterUndefined(),
      shareReplayCold()
    );

    const queryFilters$ = filterFormData$.pipe(
      map((filterData) => ({
        byDate: filterData.byDate,
        eventTypes: filterData.eventTypes,
      })),
      distinctUntilChanged()
    );

    combineLatest([
      practiceScope.doc$.pipe(filterUndefined()),
      calendarFacade.range$,
      queryFilters$,
    ])
      .pipe(debounceUserInput(), takeUntil(this._onDestroy$))
      .subscribe(([practice, dateRange, queryFilters]) =>
        this.scheduleHistory.query$.next({
          practiceRef: practice.ref,
          dateRange,
          byDate: queryFilters.byDate,
          eventTypes: queryFilters.eventTypes,
        })
      );

    filterFormData$
      .pipe(
        map((filterData) => filterData.filterValue),
        distinctUntilChanged(),
        debounceUserInput(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((filterValue) =>
        this.scheduleHistory.filter$.next(filterValue)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  colourCodeDate$(date: string): Observable<string | undefined> {
    const showDateColour$ = formControlChanges$(this.filterControl).pipe(
      map(
        (filterData) =>
          filterData?.byDate === ScheduleHistoryQueryByDate.AffectsDate
      )
    );
    return showDateColour$.pipe(
      map((showDateColour) => (showDateColour ? date : undefined))
    );
  }
}
