import { type EntityState } from '@ngrx/entity';
import {
  EntityModel,
  serialise$,
  toEntityModel$,
  type IIdentifiable,
  type SerialisedData,
} from '@principle-theorem/shared';
import { type OperatorFunction } from 'rxjs';

export interface IBaseEntityState<T> extends EntityState<T> {
  selectedId?: string;
  loaded: boolean;
  error?: string;
}

export type ISerialisedEntityState<T> = IBaseEntityState<SerialisedData<T>>;

export function toSerialisedEntityModel$<T extends object>(): OperatorFunction<
  T,
  SerialisedData<EntityModel<T>>
> {
  return (source$) => {
    return source$.pipe(toEntityModel$(), serialise$());
  };
}

export function selectEntityAdapterId<T extends IIdentifiable>(): (
  item: T
) => string {
  return (item: T) => item.uid;
}
