@if (loaded$ | async) {
  <pr-print-container
    [header]="printHeader$ | async"
    [footer]="printFooter$ | async"
    [templatesResolvedExternally]="true"
  >
    <pr-content-view *ngIf="planHeader$ | async as header" [content]="header" />
    <div class="layout-margin">
      <ng-container *ngIf="practice$ | async as practice">
        <div class="flex justify-between gap-4">
          <h1 *ngIf="brand$ | async as brand">{{ brand.name }}</h1>
          <div class="text-right">
            <h1>Treatment Plan</h1>
            @if (treatmentPlanNameDisplay$ | async) {
              <h2>{{ (treatmentPlan$ | async)?.name }}</h2>
            }
          </div>
        </div>

        <div class="practice-details-container">
          <strong>{{ practice.name }}</strong>
          <div>{{ practice.address }}</div>
          <div>{{ practice.email }}</div>
          <div>{{ practice.phone }}</div>
        </div>
      </ng-container>

      <div class="patient-practitioner-container" class="flex gap-8">
        <div *ngIf="practitioner$ | async as practitioner">
          <strong>Practitioner</strong>
          <div>{{ practitioner.name }}</div>
        </div>
        <div *ngIf="patient$ | async as patient">
          <strong>Patient</strong>
          <div>{{ patient.name }}</div>
          <div>{{ patient.email }}</div>
          <div *ngIf="phone$ | async as phone">{{ phone }}</div>
        </div>
      </div>

      <ng-container *ngIf="treatmentStepDisplay$ | async as treatmentSteps">
        <div
          *ngFor="
            let step of treatmentSteps;
            let i = index;
            trackBy: trackByTreatmentStep
          "
          [ngClass]="{ 'break-inside-avoid': i !== 0 }"
        >
          <h3>
            <strong>{{ step.treatmentStep.name }}</strong>
          </h3>

          <div class="treatment-step-container">
            <pr-invoice-line-item-header />

            <pr-line-item-group
              *ngFor="let lineItem of step.lineItems; trackBy: trackByLineItem"
              [lineItem]="lineItem"
            >
              @if (lineItem.provider) {
                <pr-treatment-provider
                  class="mat-caption"
                  *ngrxLet="lineItem | map: resolveProvider$ : this as provider"
                  [name]="provider.name"
                  [providerNumber]="provider.providerNumber"
                />
              }
              <pr-invoice-line-item-display
                *ngFor="let items of lineItem.items; trackBy: trackByLineItem"
                [lineItem]="items"
              />
            </pr-line-item-group>

            <mat-divider />

            <div class="header flex items-center gap-4">
              <strong class="flex-1">Treatment Total</strong>
              <div class="amount">
                <strong class="amount-inset">
                  {{ step.summary.tax | currency }}
                </strong>
              </div>
              <div class="amount">
                <strong class="amount-inset">
                  {{ step.summary.total | currency }}
                </strong>
              </div>
            </div>
          </div>
        </div>

        <div class="total-container">
          <div
            class="header flex items-center gap-4"
            *ngIf="treatmentPlanTotal$ | async as total"
          >
            <strong class="flex-1">Total</strong>
            <div class="amount">
              <strong class="amount-inset">{{ total.tax | currency }}</strong>
            </div>
            <div class="amount">
              <strong class="amount-inset">{{ total.total | currency }}</strong>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <pr-content-view *ngIf="planFooter$ | async as footer" [content]="footer" />
  </pr-print-container>
}
