<ng-container *ngIf="multiTreatmentPackage$ | async as package">
  <mat-card appearance="outlined" class="treatment flex-1">
    <mat-card-content>
      <form [formGroup]="form" class="flex flex-col gap-2">
        <mat-form-field class="basis-9/12">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>

        <mat-slide-toggle formControlName="isDefault"
          >Apply package price by default</mat-slide-toggle
        >

        <mat-form-field>
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>

        <div class="flex flex-col gap-2">
          <div *ngFor="let step of steps$ | async; trackBy: trackByStep">
            <h2>{{ step.name }}</h2>
            <div class="flex flex-col gap-2">
              <div
                *ngFor="
                  let treatment of step.treatments;
                  trackBy: trackByTreatment
                "
              >
                <strong>{{ treatment.quantity }} x {{ treatment.name }}</strong>
                <pr-edit-treatment-package-service-items
                  *ngIf="
                    treatment.treatmentRef
                      | map : resolveTreatmentConfig
                      | async as treatmentConfig
                  "
                  [serviceCodes]="treatmentConfig | map : getServiceCodes"
                  [priceOverrides]="
                    treatmentConfig
                      | pairMap : step.uid : resolvePriceOverrides : this
                  "
                  (updateServiceItems)="
                    updateServiceItems($event, treatmentConfig, step.uid)
                  "
                 />
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-between">
          <strong>Total Package Price</strong>
          <strong>{{ totalPrice$ | async | currency }}</strong>
        </div>

        <pt-buttons-container>
          <button mat-stroked-button color="warn" (click)="delete()">
            Delete
          </button>
          <button
            mat-flat-button
            color="primary"
            [disabled]="isDisabled$ | async"
            (click)="save()"
          >
            Save
          </button>
        </pt-buttons-container>
      </form>
    </mat-card-content>
  </mat-card>
</ng-container>
