import {
  ICandidate,
  GapOfferStatus,
} from '@principle-theorem/principle-core/interfaces';
import { AtLeast } from '@principle-theorem/shared';

export class Candidate {
  static init(
    overrides: AtLeast<
      ICandidate,
      'appointment' | 'patient' | 'offerTimeFrom' | 'offerTimeTo'
    >
  ): ICandidate {
    return {
      status: GapOfferStatus.Available,
      ...overrides,
    };
  }
  /**
   * Check whether item is approved
   * @returns boolean
   */
  static isApproved(candidate: ICandidate): boolean {
    return candidate.status === GapOfferStatus.Approved;
  }

  /**
   * Check whether item is unavailable
   * @returns boolean
   */
  static isUnavailable(candidate: ICandidate): boolean {
    return candidate.status === GapOfferStatus.Unavailable;
  }

  static isAvailable(candidate: ICandidate): boolean {
    return candidate.status === GapOfferStatus.Available;
  }

  static toggleAvailable(candidate: ICandidate): ICandidate {
    if (Candidate.isUnavailable(candidate)) {
      return Candidate.available(candidate);
    }
    return Candidate.unavailable(candidate);
  }

  /**
   * Set item to unavailable
   * @returns ICandidate
   */
  static unavailable(candidate: ICandidate): ICandidate {
    return {
      ...candidate,
      status: GapOfferStatus.Unavailable,
    };
  }

  /**
   * Set item to available
   * @returns ICandidate
   */
  static available(candidate: ICandidate): ICandidate {
    return {
      ...candidate,
      status: GapOfferStatus.Available,
    };
  }

  /**
   * Approve item
   * @returns ICandidate
   */
  static approve(candidate: ICandidate): ICandidate {
    return {
      ...candidate,
      status: GapOfferStatus.Approved,
    };
  }
}
