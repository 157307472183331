import {
  type JSONSerialisable,
  Timestamp,
  TypeGuardFn,
  isEnumValue,
  isObject,
  isTimestamp,
} from '@principle-theorem/shared';
import { isString } from 'lodash';

export enum DestinationEntityRecordStatus {
  Failed = 'failed',
  MergeConflict = 'mergeConflict',
  Skipped = 'skipped',
  Migrated = 'migrated',
  MigratedWithErrors = 'migratedWithErrors',
}

export const DESTINATION_ENTITY_RECORD_STATUS_MAP: {
  [key in DestinationEntityRecordStatus]: string;
} = {
  [DestinationEntityRecordStatus.Migrated]: 'primary',
  [DestinationEntityRecordStatus.MergeConflict]: 'accent',
  [DestinationEntityRecordStatus.MigratedWithErrors]: 'accent',
  [DestinationEntityRecordStatus.Skipped]: 'primary',
  [DestinationEntityRecordStatus.Failed]: 'warn',
};

export enum DestinationEntityRecordCollection {
  Data = 'data',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDestinationEntityRecordData<T extends object = object>
  extends JSONSerialisable<T> {
  uid: string;
}

export interface IDestinationEntityRecordFile {
  uid: string;
  url: string;
  type: 'file';
}

export type MergeConflictDestinationEntityRecord = {
  status: DestinationEntityRecordStatus.MergeConflict;
};

export type FailedDestinationEntityRecord = {
  status: DestinationEntityRecordStatus.Failed;
  errorMessage: string;
  failData: object;
};

export type SkippedDestinationEntityRecord = {
  status: DestinationEntityRecordStatus.Skipped;
};

export type MigratedWithErrorsDestinationEntityRecord<
  T = object,
  R extends object = object,
> = Omit<MigratedDestinationEntityRecord<T>, 'status'> & {
  status: DestinationEntityRecordStatus.MigratedWithErrors;
  failData: R;
};

export type MigratedDestinationEntityRecord<T = object> = {
  status: DestinationEntityRecordStatus.Migrated;
  data: T;
  migratedAt: Timestamp;
};

export type IDestinationEntityRecord<
  T extends object = object,
  R extends object = object,
> = {
  uid: string;
  label: string;
} & (
  | MergeConflictDestinationEntityRecord
  | FailedDestinationEntityRecord
  | MigratedDestinationEntityRecord<T>
  | MigratedWithErrorsDestinationEntityRecord<T, R>
  | SkippedDestinationEntityRecord
);

export function isDestinationEntityRecord(
  item: unknown
): item is IDestinationEntityRecord {
  return (
    isObject(item) &&
    isString(item.uid) &&
    isString(item.label) &&
    isEnumValue(DestinationEntityRecordStatus, item.status) &&
    (item.status === DestinationEntityRecordStatus.Skipped ||
      (isString(item.errorMessage) && isObject(item.failData)) ||
      (isObject(item.data) && isTimestamp(item.migratedAt)))
  );
}

export const JOB_DATA_UID = 'jobData';
export const PRINCIPLE_DIFF_DATA_UID = 'principleDiff';

export interface IArraySorter {
  key: string;
  typeGuardFn?: TypeGuardFn<unknown>;
  sortByPath: string | string[] | ((data: unknown) => string | number);
}
