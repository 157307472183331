import { MixedSchema } from '@principle-theorem/editor';
import {
  type DocumentReference,
  type ISoftDelete,
} from '@principle-theorem/shared';
import { type ConditionLogicConfigurationCollection } from '../condition-logic';
import {
  type IAutomatedNotificationConfiguration,
  type IRenderedTemplate,
} from '../notification/automated-notification';
import { type TemplateScope } from './template-scope';

/**
 * The format of a template's content.
 */
export enum TemplateType {
  PlainText = 'Plain Text',
  Html = 'HTML',
}

export interface IRawTemplate {
  type: TemplateType;
  scope: TemplateScope;
  content: MixedSchema;
  renderedTemplate?: IRenderedTemplate;
}

export interface ITemplateDefinition extends IRawTemplate, ISoftDelete {
  name: string;
  ownerScope: DocumentReference;
  variants?: ITemplateVariant[];
  automatedConfigurationRef?: DocumentReference<IAutomatedNotificationConfiguration>;
}

export interface ITemplateVariant {
  uid: string;
  conditions: ConditionLogicConfigurationCollection;
  content: MixedSchema;
  renderedTemplate: IRenderedTemplate;
  name?: string;
}
