import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  InvoiceType,
  type IHealthcareClaim,
  type IInvoice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ITransactionDisplay } from '../transaction-display';

@Component({
    selector: 'pr-transaction-display',
    templateUrl: './transaction-display.component.html',
    styleUrls: ['./transaction-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TransactionDisplayComponent {
  display$ = new ReplaySubject<ITransactionDisplay>(1);
  invoice$ = new ReplaySubject<WithRef<IInvoice>>(1);
  claim$ = new ReplaySubject<IHealthcareClaim | undefined>(1);
  trackByTransaction = TrackByFunctions.uniqueId<ITransaction>();
  isCreditNote$: Observable<boolean>;

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this.invoice$.next(invoice);
    }
  }

  @Input()
  set transactionDisplay(transactionDisplay: ITransactionDisplay) {
    if (transactionDisplay) {
      this.display$.next(transactionDisplay);
    }
  }

  @Input()
  set claim(claim: IHealthcareClaim | undefined) {
    this.claim$.next(claim);
  }

  constructor() {
    this.isCreditNote$ = this.invoice$.pipe(
      map((invoice) => invoice.type === InvoiceType.CreditNote)
    );
  }
}
