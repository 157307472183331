import {
  MeasureReducer,
  ReportBuilderDataSourceId,
} from '@principle-theorem/principle-core/interfaces';
import { FactTables, toMeasureBuilderData } from '@principle-theorem/reporting';
import { ChartFilters } from './chart-filters';
import { ReportBuilderChartFilters } from './report-builder-chart-filters';
import { ReportBuilderColumns } from './report-builder-columns';
import { type IReportBuilderDataSource } from './report-builder-data-source';
import { ReportBuilderGroups } from './report-builder-groups';

const factTable = FactTables.appointmentEvent.latestEvent;

export const APPOINTMENTS_REPORT_BUILDER_DATA_SOURCE: IReportBuilderDataSource =
  {
    id: ReportBuilderDataSourceId.Appointments,
    name: 'Appointments',
    description:
      'One result per appointment within Principle. This includes information about the practice and patient associated with each appointment.',
    factTable,
    queryableTimestamps: [
      { property: factTable.appointment.date },
      { property: factTable.appointment.dateBookedOnline },
      { property: factTable.appointment.firstBookedAt },
      { property: factTable.appointment.confirmedAt },
    ],
    groupByOptions: [
      ReportBuilderGroups.toGroup('Common', [
        ReportBuilderGroups.toOption(factTable.practitioner.name),
        ReportBuilderGroups.toOption(factTable.practice.name),
        ReportBuilderGroups.toOption(factTable.brand.name),
        ReportBuilderGroups.toOption(factTable.nextAppointmentBooked),
        ReportBuilderGroups.toOption(factTable.isFirstAppointment),
        ReportBuilderGroups.toOption(factTable.existingPatient),
        ReportBuilderGroups.toOption(factTable.treatments, [
          MeasureReducer.Count,
        ]),
        ReportBuilderGroups.toOption(factTable.serviceCodes, [
          MeasureReducer.Count,
        ]),
        ReportBuilderGroups.toOption(factTable.count),
      ]),
      ReportBuilderGroups.appointment(factTable.appointment),
      ReportBuilderGroups.invoice(factTable.invoice),
      ReportBuilderGroups.patient(factTable.patient),
    ],
    availableColumns: [
      ReportBuilderColumns.toColumnGroup('Appointment', [
        factTable.practitioner.name,
        factTable.practice.name,
        factTable.treatmentPlan.name,
        factTable.waitTime,
        factTable.hourlyRate,
        factTable.duration,
        factTable.treatmentCost,
        factTable.nextAppointmentBooked,
        factTable.isFirstAppointment,
        factTable.treatments,
        factTable.serviceCodes,
        ...ReportBuilderColumns.appointment(factTable.appointment),
        factTable.link,
      ]),
      ReportBuilderColumns.toColumnGroup('Invoice', [
        ...ReportBuilderColumns.invoice(factTable.invoice),
        factTable.invoiceLink,
      ]),
      ReportBuilderColumns.toColumnGroup('Patient', [
        ...ReportBuilderColumns.patient(factTable.patient),
        factTable.patientLink,
      ]),
    ],
    defaultColumns: ReportBuilderColumns.toReferences([
      factTable.patient.name,
      factTable.appointment.date,
      factTable.practitioner.name,
      factTable.appointment.treatmentCategory,
      factTable.duration,
      factTable.treatmentCost,
      factTable.link,
    ]),
    availableCharts: [
      ReportBuilderChartFilters.predefined(
        'overview.brand.price',
        factTable.brand.name,
        ChartFilters.numberChart('Total Price', factTable.brand.name, [
          toMeasureBuilderData(factTable.treatmentCost),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'overview.practice',
        factTable.practice.name,
        ChartFilters.pieChart('Practice', factTable.practice.name, [
          toMeasureBuilderData(factTable.treatmentCost),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'overview.practitioner',
        factTable.practitioner.name,
        ChartFilters.rowChart(`Practitioner`, factTable.practitioner.name, [
          toMeasureBuilderData(factTable.treatmentCost),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'overview.totalCost',
        factTable.brand.name,
        ChartFilters.numberChart(
          'Total Treatment Amount',
          factTable.brand.name,
          [toMeasureBuilderData(factTable.treatmentCost)]
        )
      ),
      ReportBuilderChartFilters.predefined(
        'overview.invoice.status',
        factTable.invoice.status,
        ChartFilters.pieChart('Invoice Status', factTable.invoice.status, [
          toMeasureBuilderData(factTable.treatmentCost),
        ])
      ),
      ...ReportBuilderChartFilters.patient(
        factTable.patient,
        factTable.treatmentCost
      ),
      ReportBuilderChartFilters.predefined(
        'appointment.status',
        factTable.appointment.status,
        ChartFilters.pieChart('Status', factTable.appointment.status, [
          toMeasureBuilderData(factTable.count),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'appointment.treatmentCategory',
        factTable.appointment.treatmentCategory,
        ChartFilters.columnChart(
          'Treatment Category',
          factTable.appointment.treatmentCategory,
          [toMeasureBuilderData(factTable.treatmentCost)],
          { rotateXAxisLabels: true },
          { groupLimit: 15, otherGroupLabel: 'Other', colourOverride: true }
        )
      ),
      ReportBuilderChartFilters.predefined(
        'appointment.tags',
        factTable.appointment.tags,
        ChartFilters.rowChart(
          'Tags',
          factTable.appointment.tags,
          [toMeasureBuilderData(factTable.treatmentCost)],
          { groupLimit: 15, otherGroupLabel: 'Other' }
        )
      ),
      ReportBuilderChartFilters.predefined(
        'appointment.tags.select',
        factTable.appointment.tags,
        ChartFilters.selectMenu('Tags', factTable.appointment.tags, [
          toMeasureBuilderData(factTable.treatmentCost),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'appointment.treatments',
        factTable.treatments,
        ChartFilters.selectMenu('Treatments', factTable.treatments, [
          toMeasureBuilderData(factTable.count),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'appointment.serviceCodes',
        factTable.serviceCodes,
        ChartFilters.selectMenu('Service Codes', factTable.serviceCodes, [
          toMeasureBuilderData(factTable.count),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'appointment.isFirstAppointment',
        factTable.isFirstAppointment,
        ChartFilters.pieChart(
          'Is First Appointment',
          factTable.isFirstAppointment,
          [
            toMeasureBuilderData(
              factTable.isFirstAppointment,
              MeasureReducer.Count
            ),
          ]
        )
      ),
      ReportBuilderChartFilters.predefined(
        'appointment.isBookedOnline',
        factTable.appointment.isBookedOnline,
        ChartFilters.pieChart(
          'Is Booked Online',
          factTable.appointment.isBookedOnline,
          [toMeasureBuilderData(factTable.count)]
        )
      ),
      ReportBuilderChartFilters.predefined(
        'appointment.dateBookedOnline',
        factTable.appointment.dateBookedOnline,
        ChartFilters.columnChartOverTime(
          'Date Booked Online',
          factTable.appointment.dateBookedOnline,
          [toMeasureBuilderData(factTable.treatmentCost)]
        )
      ),
      ReportBuilderChartFilters.predefined(
        'appointment.nextAppointmentBooked',
        factTable.nextAppointmentBooked,
        ChartFilters.pieChart(
          'Next Appointment Booked At Checkout',
          factTable.nextAppointmentBooked,
          [
            toMeasureBuilderData(
              factTable.nextAppointmentBooked,
              MeasureReducer.Count
            ),
          ]
        )
      ),

      ReportBuilderChartFilters.predefined(
        'appointment.treatmentCost',
        factTable.appointment.date,
        ChartFilters.columnChartOverTime(
          'Treatment Cost',
          factTable.appointment.date,
          [toMeasureBuilderData(factTable.treatmentCost)]
        )
      ),
      ReportBuilderChartFilters.predefined(
        'appointment.averageDuration',
        factTable.appointment.date,
        ChartFilters.columnChartOverTime(
          'Average Duration',
          factTable.appointment.date,
          [toMeasureBuilderData(factTable.duration, MeasureReducer.Average)],
          { width: ChartFilters.gridSpan(2) }
        )
      ),
      ReportBuilderChartFilters.predefined(
        'appointment.hourlyRate',
        factTable.appointment.date,
        ChartFilters.columnChartOverTime(
          'Hourly Rate',
          factTable.appointment.date,
          [toMeasureBuilderData(factTable.hourlyRate, MeasureReducer.Average)],
          undefined
        )
      ),
      ReportBuilderChartFilters.predefined(
        'appointment.waitTime',
        factTable.appointment.date,
        ChartFilters.columnChartOverTime(
          'Average Wait Time',
          factTable.appointment.date,
          [toMeasureBuilderData(factTable.waitTime, MeasureReducer.Average)],
          undefined
        )
      ),
    ],
  };
