import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {
  extendSortingDataAccessor,
  ObservableDataSource,
  timestampSortingAccessor,
} from '@principle-theorem/ng-shared';
import {
  type IReconciliationTransactionReportRecord,
  type ITransactionSourceSummary,
} from '@principle-theorem/reporting';
import {
  DAY_MONTH_YEAR_FORMAT,
  type FieldsOfType,
  type ITimePeriod,
  multiMap,
} from '@principle-theorem/shared';
import { sum } from 'lodash';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReconciliationReportFacade } from '../../store/reconciliation-report.facade';
import { ReconciliationTransactionDrilldownToCSV } from './reconciliation-transactions-drilldown-to-csv';

@Component({
    selector: 'pr-reconciliation-transactions-drilldown',
    templateUrl: './reconciliation-transactions-drilldown.component.html',
    styleUrls: ['./reconciliation-transactions-drilldown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ReconciliationTransactionsDrilldownComponent implements OnDestroy {
  result$ = new ReplaySubject<ITransactionSourceSummary>(1);
  dataSource: ObservableDataSource<IReconciliationTransactionReportRecord>;
  dateFormat = DAY_MONTH_YEAR_FORMAT;
  displayedColumns = [
    'transactionDate',
    'invoiceIssuedAt',
    'patient',
    'invoice',
    'transactionProvider',
    'transactionProviderType',
    'description',
    'pendingAmount',
    'completeAmount',
  ];
  dateRange$ = new ReplaySubject<ITimePeriod>(1);
  csvData$: Observable<IReconciliationTransactionReportRecord[]>;
  csvTranslator = new ReconciliationTransactionDrilldownToCSV();

  @Input()
  set dateRange(dateRange: ITimePeriod) {
    if (dateRange) {
      this.dateRange$.next(dateRange);
    }
  }

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @Input()
  set result(result: ITransactionSourceSummary) {
    if (result) {
      this.result$.next(result);
    }
  }

  constructor(public reportFacade: ReconciliationReportFacade) {
    this.dataSource = new ObservableDataSource(
      this.result$.pipe(map((result) => result.records ?? []))
    );

    this.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, sortHeaderId) => this._sortingDataAccessor(data, sortHeaderId)
    );
    this.csvData$ = this.result$.pipe(map((result) => result.records ?? []));
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  sumAmount$(
    key: FieldsOfType<IReconciliationTransactionReportRecord, number>
  ): Observable<number> {
    return this.dataSource.filteredData$.pipe(
      multiMap(
        (item) => item[key as keyof IReconciliationTransactionReportRecord]
      ),
      map(sum)
    );
  }

  private _sortingDataAccessor(
    data: IReconciliationTransactionReportRecord,
    sortHeaderId: string
  ): string | number | undefined {
    switch (sortHeaderId) {
      case 'pendingAmount':
        return data.pending;
      case 'completeAmount':
        return data.complete;
      case 'transactionDate':
        return timestampSortingAccessor(data.transaction.createdAt);
      case 'invoiceIssuedAt':
        return data.invoice.issuedAt
          ? timestampSortingAccessor(data.invoice.issuedAt)
          : undefined;
      default:
        return;
    }
  }
}
