import { rand, randNumber } from '@ngneat/falso';
import { IScheduleSummaryEventFact } from '@principle-theorem/reporting/interfaces';
import {
  BaseMock,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
} from '@principle-theorem/shared';
import { BrandDimensionMock } from '../dimensions/brand-dimension.mock';
import { SerialisedDocumentReferenceMock } from '../dimensions/common.mock';
import { MOCKED_PRACTICES } from '../dimensions/practice-dimension.mock';
import { MOCKED_STAFF } from '../dimensions/staffer-dimension.mock';
import { ScheduleSummaryDimensionMock } from '../dimensions/schedule-summary-dimension.mock';

export class ScheduleSummaryEventFactMock
  extends BaseMock
  implements IScheduleSummaryEventFact
{
  day = toSerialisedTimestamp(MockTimestamp());
  stafferRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practiceRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  brandRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  scheduleSummaryRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  scheduleSummary = MockGenerator.generate(ScheduleSummaryDimensionMock);
  practice = rand(MOCKED_PRACTICES);
  brand = MockGenerator.generate(BrandDimensionMock);
  staffer = rand(MOCKED_STAFF);
  timestamp = toSerialisedTimestamp(MockTimestamp());
  rosterTimeInMins = randNumber({ min: 1, max: 120 });
  eventTimeInMins = randNumber({ min: 1, max: 120 });
  appointmentTimeInMins = randNumber({ min: 1, max: 120 });
  gapTimeInMins = randNumber({ min: 1, max: 120 });
  revenuePerHour = randNumber({ min: 1, max: 120 });
  revenueTotal = randNumber({ min: 1, max: 120 });
  revenue = randNumber({ min: 1, max: 120 });
  revenueLost = randNumber({ min: 1, max: 120 });
  revenueLostTotal = randNumber({ min: 1, max: 120 });
  totalRevenuePerHour = randNumber({ min: 1, max: 120 });
  appointmentRevenuePerHour = randNumber({ min: 1, max: 120 });
  eventableSummaries = [];
  preBlockSummaries = [];
}

export function MockScheduleSummaryMins(): Pick<
  IScheduleSummaryEventFact,
  'rosterTimeInMins' | 'eventableSummaries'
> {
  const timeInterval = 15;
  const isRosteredOn = randNumber({ min: 1, max: 100 }) > 25;
  const rosterTimeInMins = isRosteredOn
    ? roundToClosestInterval(randNumber({ min: 240, max: 720 }), timeInterval)
    : 0;

  return {
    rosterTimeInMins,
    eventableSummaries: [],
  };
}

function roundToClosestInterval(value: number, interval: number): number {
  return Math.round(value / interval) * interval;
}
