<ng-container *ngIf="task$ | async as task">
  <mat-toolbar color="accent">
    <div fxFlex></div>
    <button *ngIf="!task.deleted" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item color="warn" (click)="delete(task)">
        <mat-icon>delete</mat-icon>
        <span>Delete</span>
      </button>
    </mat-menu>
    <button
      mat-icon-button
      *ngIf="sidebar.isMobile$ | async"
      (click)="sidebar.toggleOpen()"
    >
      <mat-icon>menu_open</mat-icon>
    </button>
    <button mat-icon-button (click)="closeTrigger.emit()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

  <mat-drawer-container
    fxFlex
    *ngIf="sidebar.state$ | async as state"
    (backdropClick)="sidebar.toggleOpen()"
  >
    <mat-drawer-content>
      <div class="box-border flex h-full min-h-full w-full flex-col">
        <h2 class="title p-4">
          <pr-content-view [content]="task.title" />
        </h2>
        <pr-interactions
          fxFlex
          [enableScroll]="true"
          [interactions]="task.interactions"
          (interactionAdded)="addInteraction(task, $event)"
        />
        <div fxLayout="column" fxShow fxHide.gt-sm>
          <mat-divider />
          <div class="status-change flex items-stretch justify-around">
            <ng-container *ngTemplateOutlet="statusMenu" />
          </div>
        </div>
      </div>
    </mat-drawer-content>

    <mat-drawer
      position="end"
      [disableClose]="sidebar.isDesktop$ | async"
      [mode]="state.mode"
      [opened]="state.opened"
      (closedStart)="sidebar.close()"
      class="!w-auto !max-w-full"
    >
      <div class="flex h-full flex-col">
        <pr-task-edit [task]="task" (cancelled)="closeTrigger.emit()" />
        <ng-container *ngTemplateOutlet="statusMenu" />
        <ng-template #statusMenu>
          <ng-container *ngIf="!task.deleted; else restore">
            <div class="m-2 mt-auto flex flex-col justify-center gap-2">
              <button
                mat-flat-button
                color="primary"
                [disabled]="(isComplete$ | async) === false"
                (click)="setIncomplete(task)"
              >
                Incomplete
              </button>
              <mat-divider fxShow fxHide.gt-xs [vertical]="true" />
              <button
                mat-flat-button
                color="primary"
                [disabled]="isComplete$ | async"
                (click)="setComplete(task)"
              >
                Complete
              </button>
            </div>
          </ng-container>
          <ng-template #restore>
            <button mat-stroked-button (click)="undelete(task)">Restore</button>
          </ng-template>
        </ng-template>
      </div>
    </mat-drawer>
  </mat-drawer-container>
</ng-container>
