import {
  type INamedDocument,
  TypeGuard,
  isINamedDocument,
  isObject,
} from '@principle-theorem/shared';
import { type IManualTransactionType } from './manual-transaction-type';

export interface IManualExtendedData {
  transactionType?: INamedDocument<IManualTransactionType>;
}

export function isManualExtendedData(
  data: unknown
): data is IManualExtendedData {
  return (
    isObject(data) &&
    TypeGuard.undefinedOr(isINamedDocument)(data.transactionType)
  );
}
