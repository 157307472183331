<mat-list-item *ngIf="automation$ | async as automation">
  <div
    matListItemAvatar
    *ngIf="withCheckbox"
    class="checkbox"
    (click)="$event.stopPropagation()"
  >
    <mat-checkbox
      color="primary"
      [checked]="checked$ | async"
      (change)="checkedChange.emit($event)"
     />
  </div>
  <span matListItemTitle class="title truncate">
    <pr-content-text [content]="title$ | async" />
  </span>
  <div matListItemLine class="created-by">
    From: {{ automation.creator.name }}
    <mat-icon [matTooltip]="automation.creator.type | splitCamel | titlecase">
      info
    </mat-icon>
  </div>

  <div matListItemMeta>
    <div fxFlex fxLayout="row" fxLayoutAlign="end center">
      <div fxLayout="column" fxLayoutAlign="center end">
        <ng-container *ngIf="newDate$ | async as newDate; else triggerDate">
          <span
            class="trigger-date new-date"
            matTooltip="New trigger date calculated from new event time"
            *ngIf="
              newDate
                | momentTimezone$ : automation.practiceRef
                | ngrxPush as date
            "
          >
            {{ date | amDateFormat : dateFormat }}
          </span>
        </ng-container>
        <ng-template #triggerDate>
          <span *ngIf="automation.triggerDate" class="trigger-date">
            <ng-container
              *ngIf="
                automation.triggerDate
                  | momentTimezone$ : automation.practiceRef
                  | ngrxPush as triggerDate
              "
            >
              {{ triggerDate | moment | amDateFormat : dateFormat }}
            </ng-container>
          </span>
        </ng-template>
      </div>
    </div>
    <div fxFlex="85px" fxLayout="row" fxLayoutAlign="end center">
      <pr-automation-status [status]="automation.status" />
    </div>
    <ng-content></ng-content>
  </div>
</mat-list-item>
