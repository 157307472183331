import {
  AnyAutomationConfiguration,
  IAutomationTiming,
  TimingDirection,
} from '@principle-theorem/principle-core/interfaces';
import {
  MINUTES_IN_DAY,
  toInt,
  Time24hrType,
  MINUTES_IN_HOUR,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { duration } from 'moment-timezone';
import { AutomationDisplay } from '@principle-theorem/principle-core';

export class AutomationConfigurationSort {
  static sort<Automation extends AnyAutomationConfiguration>(
    automations: Automation[]
  ): Automation[] {
    const labelSorted = sortBy(automations, (automation) =>
      AutomationDisplay.getLabel(automation)
    );
    return sortBy(labelSorted, (automation) =>
      this.getAutomationTimingSortValue(automation.timing)
    );
  }

  /**
   * Get a value for the distance from an event the timing indicates.
   * Immediatley is the earliest time possible (-Infinity), before is negative,
   * after is positive. If no time of day is given, it assumes the latest possible
   * time on that day.
   */
  static getAutomationTimingSortValue(timing: IAutomationTiming): number {
    if (timing.direction === TimingDirection.Immediately) {
      return -Infinity;
    }
    const distanceFromEventInMins = duration(
      timing.amount,
      timing.unit
    ).asMinutes();
    const distanceFromStartOfDayInMins = timing.time
      ? this.minsFromStartOfDay(timing.time)
      : MINUTES_IN_DAY;

    const value = toInt(
      `${distanceFromEventInMins}${distanceFromStartOfDayInMins}`
    );
    return timing.direction === TimingDirection.Before ? -value : value;
  }

  static minsFromStartOfDay(timeOfDay: Time24hrType): number {
    const [hours, minutes] = timeOfDay.split(':');
    return toInt(hours) * MINUTES_IN_HOUR + toInt(minutes);
  }
}
