import { type IXSLXImport } from '@principle-theorem/shared';
import type { Row } from 'exceljs';
import {
  type IAppointmentStatusXSLX,
  APPOINTMENT_STATUS_HEADERS,
} from './appointment-statuses-to-xlsx';

export class XSLXToAppointmentStatuses
  implements IXSLXImport<IAppointmentStatusXSLX>
{
  headers = APPOINTMENT_STATUS_HEADERS;

  translate(row: Row): IAppointmentStatusXSLX {
    return {
      abbreviation: row.getCell('abbreviation').value?.toString() ?? '',
      status: row.getCell('status').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IAppointmentStatusXSLX['mapTo'],
    };
  }
}
