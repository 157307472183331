import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  type IBreadcrumb,
  isDisabled$,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  type IOrganisation,
  type IOrganisationSubscription,
  type IUser,
} from '@principle-theorem/principle-core/interfaces';
import { Organisation } from '@principle-theorem/principle-core';
import { doc$, type CollectionReference } from '@principle-theorem/shared';
import {
  all$,
  filterUndefined,
  findProp,
  HISTORY_DATE_FORMAT,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { type Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'pr-organisation-edit',
    templateUrl: './organisation-edit.component.html',
    styleUrls: ['./organisation-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OrganisationEditComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  breadcrumbs$: Observable<IBreadcrumb[]>;
  organisation$: Observable<WithRef<IOrganisation>>;
  users$: Observable<WithRef<IUser>[]>;
  userCollection$: Observable<CollectionReference<IUser>>;

  form = new TypedFormGroup<IOrganisationSubscription>({
    customerId: new TypedFormControl<string>(undefined, Validators.required),
    subscriptionId: new TypedFormControl<string>(
      undefined,
      Validators.required
    ),
  });
  isDisabled$ = isDisabled$(this.form);
  readonly dateFormat = HISTORY_DATE_FORMAT;

  constructor(private _route: ActivatedRoute, private _snackBar: MatSnackBar) {
    this.organisation$ = this._route.data.pipe(
      findProp<WithRef<IOrganisation>>('organisation'),
      filterUndefined(),
      switchMap((organisation) => doc$(organisation.ref))
    );

    this.breadcrumbs$ = this.organisation$.pipe(
      map((organisation) => [
        { label: 'Organisations', path: '../' },
        { label: organisation.name },
      ])
    );

    this.userCollection$ = this.organisation$.pipe(
      filterUndefined(),
      map((organisation) => Organisation.userCol(organisation))
    );

    this.users$ = this.userCollection$.pipe(
      switchMap((collection) => all$(collection)),
      map((users) => sortBy(users, 'name'))
    );

    this.organisation$
      .pipe(
        map((organisation) => organisation?.subscription),
        filterUndefined(),
        take(1),
        takeUntil(this._onDestroy$)
      )
      .subscribe((subscription) =>
        this.form.patchValue(subscription, {
          emitEvent: false,
        })
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async updateSubscriptionDetails(): Promise<void> {
    if (!this.form.valid) {
      return;
    }

    const organisation = await snapshot(this.organisation$);

    await patchDoc(organisation.ref, {
      subscription: this.form.value,
    });
    this._snackBar.open('Settings Updated');
  }
}
