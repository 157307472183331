import {
  IAllocationSummary,
  IInvoiceTransactionAllocations,
} from '../invoice/invoice';

export interface IAccountCreditSummary {
  freeCredit: number;
  depositsPaid: number;
  creditTotal: number;
  creditUsed: number;
  creditRemaining: number;
}

const BLANK_ACCOUNT_CREDIT_SUMMARY: IAccountCreditSummary = {
  freeCredit: 0,
  depositsPaid: 0,
  creditTotal: 0,
  creditUsed: 0,
  creditRemaining: 0,
};

export interface IAccountInvoiceSummary {
  treatments: number;
  products: number;
  fees: number;
  subtotal: number;
  deposits: number;
  totalInvoiced: number;
}

const BLANK_ACCOUNT_INVOICE_SUMMARY: IAccountInvoiceSummary = {
  treatments: 0,
  products: 0,
  fees: 0,
  subtotal: 0,
  deposits: 0,
  totalInvoiced: 0,
};

export interface IAccountPaymentSummary {
  discounts: number;
  writtenOff: number;
  creditUsed: number;
  totalReceivable: number;
  paymentsReceived: number;
  outstanding: number;
}

const BLANK_ACCOUNT_PAYMENT_SUMMARY: IAccountPaymentSummary = {
  discounts: 0,
  writtenOff: 0,
  creditUsed: 0,
  totalReceivable: 0,
  paymentsReceived: 0,
  outstanding: 0,
};

export interface IInvoiceSummary {
  invoiceSummary: IAccountInvoiceSummary;
  paymentSummary: IAccountPaymentSummary;
  allocationSummary: IAllocationSummary[];
  transactionAllocations: IInvoiceTransactionAllocations[];
}

export function initInvoiceSummary(): IInvoiceSummary {
  return {
    invoiceSummary: { ...BLANK_ACCOUNT_INVOICE_SUMMARY },
    paymentSummary: { ...BLANK_ACCOUNT_PAYMENT_SUMMARY },
    allocationSummary: [],
    transactionAllocations: [],
  };
}

export interface IAccountSummary extends IInvoiceSummary {
  creditSummary: IAccountCreditSummary;
}

export function initAccountSummary(): IAccountSummary {
  return {
    invoiceSummary: { ...BLANK_ACCOUNT_INVOICE_SUMMARY },
    paymentSummary: { ...BLANK_ACCOUNT_PAYMENT_SUMMARY },
    creditSummary: { ...BLANK_ACCOUNT_CREDIT_SUMMARY },
    allocationSummary: [],
    transactionAllocations: [],
  };
}
