import {
  CollectionGroup,
  EventType,
  type IBrand,
  type ICalendarEvent,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  DocumentReference,
  asyncForEach,
  collectionGroupQuery,
  query,
  toMomentTz,
  toTimestamp,
  where,
  type IDataTable,
  type ITimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
import { flatten, groupBy, sortBy } from 'lodash';
import { Brand } from '../models/brand';
import { dateRangeToPracticeTimezone } from './helpers';

interface IRow {
  week: string;
  practice: string;
  count: number;
}

export class OnlineBookings {
  async run(
    dateRange: ITimePeriod,
    brandRef: DocumentReference<IBrand>
  ): Promise<IDataTable<IRow>[]> {
    const practices = await query(
      Brand.practiceCol({ ref: brandRef }),
      where('deleted', '==', false)
    );

    const rows = await this._getRows(dateRange, practices);
    return this._toDataTables(rows);
  }

  private async _getRows(
    dateRange: ITimePeriod,
    practices: WithRef<IPractice>[]
  ): Promise<IRow[]> {
    const results = await asyncForEach(practices, async (practice) => {
      const practiceDateRange = dateRangeToPracticeTimezone(
        practice,
        dateRange
      );
      const events = await query(
        collectionGroupQuery<ICalendarEvent>(CollectionGroup.CalendarEvents),
        where('event.type', '==', EventType.AppointmentRequest),
        where('event.practice.ref', '==', practice.ref),
        where('createdAt', '>=', toTimestamp(practiceDateRange.from)),
        where('createdAt', '<=', toTimestamp(practiceDateRange.to))
      );
      return events.map((event) => ({
        week: toMomentTz(event.createdAt, practice.settings.timezone)
          .startOf('week')
          .format(DAY_MONTH_YEAR_FORMAT),
        practice: event.event.practice.name,
        practiceId: event.event.practice.ref.id,
      }));
    });
    const grouped = groupBy(
      flatten(results),
      (event) => `${event.week}-${event.practiceId}`
    );

    const unsorted = Object.values(grouped).map((events) => ({
      week: events[0].week,
      practice: events[0].practice,
      count: events.length,
    }));

    return sortBy(unsorted, 'name');
  }

  private _toDataTables(data: IRow[]): IDataTable<IRow>[] {
    return [
      {
        name: 'Online Bookings',
        data,
        columns: [
          { key: 'week', header: 'Week' },
          { key: 'practice', header: 'Practice' },
          { key: 'count', header: 'Total Online Bookings' },
        ],
      },
    ];
  }
}
