import { type TrackByFunction } from '@angular/core';
import { type IGroup } from '@principle-theorem/shared';
import { TrackByFunctions } from '../track-by';

export interface IMatSelectOption<T> {
  label: string;
  value: T;
}

export interface IMatSelectOptions<T> {
  options: IMatSelectOption<T>[];
  trackBy: TrackByFunction<IMatSelectOption<T>>;
}

export type IconInputOption = { icon: string; value: string | number };
export type OptionInput = string | number | IconInputOption;

export function matSelectOptions<T>(
  options: IMatSelectOption<T>[]
): IMatSelectOptions<T> {
  return {
    options,
    trackBy: TrackByFunctions.label<IMatSelectOption<T>>(),
  };
}
export interface IMatSelectGroupOptions<Group, Items> {
  groups: IGroup<IMatSelectOption<Items>, Group>[];
  trackByGroup: TrackByFunction<IGroup<IMatSelectOption<Items>, Group>>;
  trackByOption: TrackByFunction<IMatSelectOption<Items>>;
}

export function matSelectGroupOptions<Group, Items>(
  groups: IGroup<IMatSelectOption<Items>, Group>[]
): IMatSelectGroupOptions<Group, Items> {
  return {
    groups,
    trackByGroup:
      TrackByFunctions.field<IGroup<IMatSelectOption<Items>, Group>>('group'),
    trackByOption: TrackByFunctions.label<IMatSelectOption<Items>>(),
  };
}
