import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone, toFloat } from '@principle-theorem/shared';
import { flow, isNumber } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  convertValueFn,
} from '../../../source/source-helpers';

export const PATIENT_PAYMENT_ALLOCATION_RESOURCE_TYPE =
  'patientPaymentAllocations';

export const PATIENT_PAYMENT_ALLOCATION_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Payment Allocation List',
      description: '',
      idPrefix: PATIENT_PAYMENT_ALLOCATION_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ICorePracticePatientPaymentAllocation {
  id: number;
  amount: number; // 150.0000
  paymentId: number;
  invoiceId?: number; // Either InvoiceId, CreditNoteId, or PrepaymentId will be used
  creditNoteId?: number;
  prepaymentId?: number;
  providerId?: number;
}

export function isCorePracticePatientPaymentAllocation(
  item: unknown
): item is ICorePracticePatientPaymentAllocation {
  return TypeGuard.interface<ICorePracticePatientPaymentAllocation>({
    id: isNumber,
    amount: isNumber,
    paymentId: isNumber,
    invoiceId: TypeGuard.nilOr(isNumber),
    creditNoteId: TypeGuard.nilOr(isNumber),
    prepaymentId: TypeGuard.nilOr(isNumber),
    providerId: TypeGuard.nilOr(isNumber),
  })(item);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICorePracticePatientPaymentAllocationTranslations {}

export interface ICorePracticePatientPaymentAllocationFilters {
  paymentId: number;
  providerId?: number;
  invoiceId?: number;
  creditNoteId?: number;
  prepaymentId?: number;
}

const PATIENT_PAYMENT_ALLOCATION_SOURCE_QUERY = `
SELECT
  PaymentAllocationId AS id,
  convert_to_decimal(Amount) AS amount,
  PaymentId AS payment_id,
  InvoiceId AS invoice_id,
  convert_to_integer(CreditNoteId) AS credit_note_id,
  PrepaymentId AS prepayment_id,
  ProviderId AS provider_id
FROM tblPaymentAllocation
`;

export class PatientPaymentAllocationSourceEntity extends BaseSourceEntity<
  ICorePracticePatientPaymentAllocation,
  ICorePracticePatientPaymentAllocationTranslations,
  ICorePracticePatientPaymentAllocationFilters
> {
  sourceEntity = PATIENT_PAYMENT_ALLOCATION_SOURCE_ENTITY;
  entityResourceType = PATIENT_PAYMENT_ALLOCATION_RESOURCE_TYPE;
  sourceQuery = PATIENT_PAYMENT_ALLOCATION_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientPaymentAllocation;

  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertValueFn(toFloat, 'amount'),
  ]);

  translate(
    _data: ICorePracticePatientPaymentAllocation,
    _timezone: Timezone
  ): ICorePracticePatientPaymentAllocationTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientPaymentAllocation): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientPaymentAllocation): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientPaymentAllocation,
    _timezone: Timezone
  ): ICorePracticePatientPaymentAllocationFilters {
    return {
      paymentId: data.paymentId,
      providerId: data.providerId,
      invoiceId: data.invoiceId,
      creditNoteId: data.creditNoteId,
      prepaymentId: data.prepaymentId,
    };
  }
}
