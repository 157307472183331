<mat-toolbar color="accent">Sterilisation Cycle Edit History</mat-toolbar>

<mat-dialog-content class="flex flex-col justify-between p-4">
  @if (data.history.length) {
    <mat-table class="mb-4" [dataSource]="dataSource">
      <mat-header-row *matHeaderRowDef="displayedColumns" />
      <mat-row *matRowDef="let row; columns: displayedColumns" />

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef>Cycle ID</mat-header-cell>
        <mat-cell *matCellDef="let cycle">
          {{ cycle.id }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="machine">
        <mat-header-cell *matHeaderCellDef>Machine</mat-header-cell>
        <mat-cell *matCellDef="let cycle">
          {{ cycle.machine.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="editedAt">
        <mat-header-cell *matHeaderCellDef>Edited At</mat-header-cell>
        <mat-cell *matCellDef="let cycle">
          {{ cycle.archivedAt | moment | amDateFormat: dateFormat }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="editedBy">
        <mat-header-cell *matHeaderCellDef>Edited By</mat-header-cell>
        <mat-cell *matCellDef="let cycle">
          @if (cycle.archivedBy | map: getEditedBy | async; as editedBy) {
            {{ editedBy.user.name }}
          }
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="runDate">
        <mat-header-cell *matHeaderCellDef>Run Date</mat-header-cell>
        <mat-cell *matCellDef="let cycle">
          {{ cycle.runDate | moment | amDateFormat: dateFormat }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
        <mat-cell *matCellDef="let cycle">
          <pr-status-label
            [label]="cycle.status"
            [dense]="true"
            [color]="cycle.status | map: getStatusColour"
          />
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="cycleType">
        <mat-header-cell *matHeaderCellDef>Cycle Type</mat-header-cell>
        <mat-cell *matCellDef="let cycle">
          {{ cycle.cycleType.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="notes">
        <mat-header-cell *matHeaderCellDef>Notes</mat-header-cell>
        <mat-cell *matCellDef="let cycle">
          <div
            [ptTruncateTooltip]="cycle | map: getNoteContent"
            class="truncate"
          >
            {{ cycle | map: getNoteContent }}
          </div>
        </mat-cell>
      </ng-container>
    </mat-table>
  } @else {
    <pr-empty-state image="list" title="edit history" />
  }
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>Close</button>
</mat-dialog-actions>
