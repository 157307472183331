import { InjectionToken } from '@angular/core';

export interface ISlackIntegrationConfig {
  projectId: string;
  slack: {
    clientId: string;
  };
}

export const NG_SLACK_INTEGRATION_CONFIG: InjectionToken<ISlackIntegrationConfig> =
  new InjectionToken('ISlackIntegrationConfig');
