<pr-page-splash>
  <ng-container *ngIf="!activating; else loader">
    <form [formGroup]="form" (ngSubmit)="save()" class="layout-margin">
      <ng-template matStepLabel>
        Let's get started with some details
      </ng-template>
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            formControlName="email"
            [required]="true"
          />
        </mat-form-field>
      </div>
      <pt-buttons-container>
        <button mat-flat-button color="primary">Save</button>
      </pt-buttons-container>
    </form>
  </ng-container>

  <ng-template #loader>
    <mat-spinner />
    <p>Activating Account</p>
  </ng-template>
</pr-page-splash>
