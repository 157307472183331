import { createAction, props } from '@ngrx/store';
import {
  type IBrand,
  type IFeeSchedule,
  type IFeeScheduleGroup,
  type IOrganisation,
  type IPatient,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  type AtLeast,
  type SerialisedData,
  type WithRef,
} from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { ChartActionTypes } from './action-types';

export interface ILoadFeeSchedules {
  organisation: WithRef<IOrganisation>;
  brand?: WithRef<IBrand>;
  practice?: WithRef<IPractice>;
  staffer?: WithRef<IStaffer>;
}

export const loadFeeSchedules = createAction(
  ChartActionTypes.LoadFeeSchedules,
  props<SerialisedData<ILoadFeeSchedules>>()
);

export interface ILoadFeeSchedulesSuccess {
  feeSchedules: WithRef<IFeeSchedule>[];
  groups: IFeeScheduleGroup[];
}

export const loadFeeSchedulesSuccess = createAction(
  ChartActionTypes.LoadFeeSchedulesSuccess,
  props<SerialisedData<ILoadFeeSchedulesSuccess>>()
);

export const loadFeeSchedulesFailure = createAction(
  ChartActionTypes.LoadFeeSchedulesFailure
);

export interface IAddFeeSchedule {
  feeSchedule: WithRef<IFeeSchedule>;
}

export const addFeeSchedule = createAction(
  ChartActionTypes.AddFeeSchedule,
  props<SerialisedData<IAddFeeSchedule>>()
);

export interface IUpdateFeeSchedule {
  feeSchedule: AtLeast<WithRef<IFeeSchedule>, 'ref'>;
}

export const updateFeeSchedule = createAction(
  ChartActionTypes.UpdateFeeSchedule,
  props<SerialisedData<IUpdateFeeSchedule>>()
);

export interface IDeleteFeeSchedule {
  ref: DocumentReference<IFeeSchedule>;
}

export const deleteFeeSchedule = createAction(
  ChartActionTypes.DeleteFeeSchedule,
  props<SerialisedData<IDeleteFeeSchedule>>()
);

export interface ISelectFeeSchedule {
  ref: DocumentReference<IFeeSchedule>;
}

export const selectFeeSchedule = createAction(
  ChartActionTypes.SelectFeeSchedule,
  props<SerialisedData<ISelectFeeSchedule>>()
);

export interface ISelectDefaultFeeSchedule {
  patient: WithRef<IPatient>;
}

export const selectDefaultFeeSchedule = createAction(
  ChartActionTypes.SelectDefaultFeeSchedule,
  props<SerialisedData<ISelectDefaultFeeSchedule>>()
);

export const clearFeeSchedules = createAction(
  ChartActionTypes.ClearFeeSchedule
);
