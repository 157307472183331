@if (step$ | async; as step) {
  <div class="m-4 flex flex-col gap-2">
    <div class="flex flex-col gap-1">
      <div class="flex">
        <div class="flex flex-auto text-xl font-bold">Today's Appointment</div>

        <button mat-icon-button class="icon-md" [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <ng-template matMenuContent>
            <button mat-menu-item (click)="addSterilisationRecords()">
              <mat-icon>track_changes</mat-icon>
              Add Sterilisation Records
            </button>
            <button mat-menu-item (click)="addTask()">
              <mat-icon>check_box</mat-icon>
              Add Task
            </button>
            <button mat-menu-item (click)="addLabJob()">
              <mat-icon>local_pharmacy</mat-icon>
              Add Lab Job
            </button>
            <div
              [matTooltip]="
                !hasPrescriptionPermissions()
                  ? 'Update your permissions to manage prescriptions.'
                  : ''
              "
            ></div>
            <button
              mat-menu-item
              [disabled]="!hasPrescriptionPermissions()"
              (click)="addPrescription()"
            >
              <mat-icon>medication</mat-icon>
              Add Prescription
            </button>

            <button mat-menu-item (click)="managePatientForms()">
              <mat-icon>list_alt</mat-icon>
              Patient Forms
            </button>
          </ng-template>
        </mat-menu>
      </div>

      <ng-container *ngIf="tags$ | async as tags">
        <pr-tag-limit-list *ngIf="tags.length" [tags]="tags" />
      </ng-container>
    </div>

    <div
      *ngIf="appointmentCompleted$ | async; else invoiceCheck"
      class="flex items-center gap-2 rounded-md bg-gray-50 p-2 text-xs text-gray-500"
    >
      <div>
        <mat-icon class="icon-sm">lock</mat-icon>
      </div>
      <div class="flex-auto">
        Treatment is locked while the appointment is marked for checkout.
      </div>
      <button mat-stroked-button class="dense" (click)="unlockAppointment()">
        Unlock
      </button>
    </div>

    <ng-template #invoiceCheck>
      <div
        *ngIf="invoiceIssued$ | async"
        class="flex items-center gap-2 rounded-md bg-gray-50 p-2 text-xs text-gray-500"
      >
        <div>
          <mat-icon class="icon-sm">payments</mat-icon>
        </div>
        <div class="flex-auto">
          Treatment has an issued invoice. Changes to treatment will not be
          reflected on the invoice unless it's cancelled and reissued.
        </div>
      </div>
    </ng-template>

    <div>
      <pr-treatment-step
        [step]="step$ | async"
        [plan]="plan$ | async"
        [inlcudeMultiTreatments]="false"
        [hideDelete]="true"
        [filterByStatus]="false"
        (stepChanged)="updateStep($event)"
        (updateChartable)="updateChartable($event)"
      />
    </div>
  </div>
}
