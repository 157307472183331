import {
  type AnyChartedItemConfiguration,
  type IChartedItem,
  type IChartedItemConfiguration,
  type IChartedRef,
  type IChartedSurface,
  type IChartedTreatment,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { type INamedDocument } from '@principle-theorem/shared';

export interface IChartSurfaceEvent {
  surfaces: IChartedSurface[];
  config: AnyChartedItemConfiguration;
  attributedTo?: INamedDocument<IStaffer>;
}

export interface IChartedSurfaceProvider
  extends IAddChartedSurfaceProvider,
    IRemoveChartedSurfaceProvider {}

export interface IAddChartedSurfaceProvider {
  canProvide(chartable: AnyChartedItemConfiguration): boolean;
  addChartedItem(
    event: IChartSurfaceEvent,
    chartable: AnyChartedItemConfiguration
  ): Promise<void>;
}

export interface IRemoveChartedSurfaceProvider {
  canProvide(chartable: AnyChartedItemConfiguration): boolean;
  removeSurfacesFromChartedItem(
    surfaceRefs: Partial<IChartedRef>[],
    item: IChartedItem<IChartedItemConfiguration>
  ): Promise<void>;
}

export interface IEditTreatmentProvider {
  canProvide(chartable: AnyChartedItemConfiguration): boolean;
  addTreatments(treatments: IChartedTreatment[]): Promise<void> | void;
  removeTreatments(treatments: IChartedTreatment[]): Promise<void> | void;
  updateTreatments(
    treatments: IChartedTreatment[],
    stepTreatments: IChartedTreatment[],
    updateFn: TreatmentUpdateFn
  ): Promise<void> | void;
}

export type TreatmentUpdateFn = (
  item: IChartedTreatment,
  items: IChartedTreatment[]
) => IChartedTreatment | Promise<IChartedTreatment>;
