<pr-gap-candidate-list
  [gap]="gap$ | async"
  [gapCandidates]="shortListManager.gapCandidates$ | async"
 />
<mat-divider />
<pr-wait-list
  [gap]="gap$ | async"
  [gapCandidates]="shortListManager.gapCandidates$ | async"
  [waitlist]="shortListManager.waitList$ | async"
 />
