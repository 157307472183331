import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  TypeGuard,
  toFloat,
  toTimestamp,
  type Timezone,
  ISO_DATE_TIME_FORMAT,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  convertValueFn,
} from '../../../source/source-helpers';

export const PATIENT_PAYMENT_RESOURCE_TYPE = 'patientPayments';

export const PATIENT_PAYMENT_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient Payment List',
    description: '',
    idPrefix: PATIENT_PAYMENT_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ICorePracticePatientPayment {
  id: number;
  paymentDate: string; // 2008-10-16 00:00:00.000
  amount: number; // 80.4000
  isDeleted: boolean;
  patientId: number;
  paymentTypeId: number;
  paymentTypeName: string;
  prepaymentId?: number;
  paidByPatientId?: number;
}

export function isCorePracticePatientPayment(
  item: unknown
): item is ICorePracticePatientPayment {
  return TypeGuard.interface<ICorePracticePatientPayment>({
    id: isNumber,
    paymentDate: isString,
    amount: isNumber,
    isDeleted: isBoolean,
    patientId: isNumber,
    paymentTypeId: isNumber,
    paymentTypeName: isString,
    prepaymentId: TypeGuard.nilOr(isNumber),
    paidByPatientId: TypeGuard.nilOr(isNumber),
  })(item);
}

export interface ICorePracticePatientPaymentTranslations {
  paymentDate: Timestamp;
}

export interface ICorePracticePatientPaymentFilters {
  patientId: number;
  prepaymentId?: number;
  paidByPatientId?: number;
}

const PATIENT_PAYMENT_SOURCE_QUERY = `
SELECT
  payment.*,
  payment_type.name AS payment_type_name
FROM (
  SELECT
    PaymentId AS id,
    PaymentDate AS payment_date,
    convert_to_decimal(Amount) AS amount,
    convert_to_boolean(IsDeleted) AS is_deleted,
    PatientId AS patient_id,
    PaymentTypeId AS payment_type_id,
    PrepaymentId AS prepayment_id,
    PaidbyPatientId AS paid_by_patient_id
  FROM tblPayment
) AS payment
LEFT JOIN (
  SELECT
    PaymentTypeId as id,
    PaymentTypeName as name,
    convert_to_boolean(IsActive) AS is_active
  FROM tblPaymentType
) AS payment_type
ON payment.payment_type_id = payment_type.id
`;

export class PatientPaymentSourceEntity extends BaseSourceEntity<
  ICorePracticePatientPayment,
  ICorePracticePatientPaymentTranslations,
  ICorePracticePatientPaymentFilters
> {
  sourceEntity = PATIENT_PAYMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_PAYMENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_PAYMENT_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientPayment;

  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertValueFn(toFloat, 'amount'),
  ]);

  translate(
    data: ICorePracticePatientPayment,
    _timezone: Timezone
  ): ICorePracticePatientPaymentTranslations {
    return {
      paymentDate: toTimestamp(
        moment.utc(data.paymentDate, ISO_DATE_TIME_FORMAT)
      ),
    };
  }

  getSourceRecordId(data: ICorePracticePatientPayment): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientPayment): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientPayment,
    _timezone: Timezone
  ): ICorePracticePatientPaymentFilters {
    return {
      patientId: data.patientId,
      prepaymentId: data.prepaymentId,
      paidByPatientId: data.paidByPatientId,
    };
  }
}
