export enum Region {
  Australia = 'Australia',
  NewZealand = 'New Zealand',
}

export enum ISORegionCode {
  Australia = 'AU',
  NewZealand = 'NZ',
}

const SUPPORTED_REGION_CODE_MAP = {
  [Region.Australia]: ISORegionCode.Australia,
  [Region.NewZealand]: ISORegionCode.NewZealand,
};

export function getRegionCode(region: Region): ISORegionCode {
  return SUPPORTED_REGION_CODE_MAP[region];
}
