<mat-list *ngIf="requirements.length">
  <div mat-subheader>{{ title }}</div>

  <ng-container
    *ngFor="let requirement of requirements; trackBy: trackByRequirement"
  >
    <pr-treatment-asset-line-item
      *ngIf="findAsset(requirement.asset) as asset"
      [treatmentConfig]="treatmentConfig"
      [requirement]="requirement"
      [asset]="asset"
      (save)="handleSave()"
     />
  </ng-container>
</mat-list>
