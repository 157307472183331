import {
  getRawProviderName,
  getTransactionProviderName,
  TransactionOperators,
} from '@principle-theorem/principle-core';
import {
  IInvoice,
  IPatient,
  IPractice,
  ITransaction,
  TransactionProvider,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference, Timestamp } from '@principle-theorem/shared';
import {
  INamedDocument,
  toNamedDocument,
  WithRef,
} from '@principle-theorem/shared';
import { groupBy, sortBy, startCase, toPairs } from 'lodash';

export enum ReportType {
  Revenue = 'revenue',
  DiscountsCredits = 'discountsCredits',
  Total = 'totalIncome',
}

export interface IReconciliationReportRequest {
  startDate: Timestamp;
  endDate: Timestamp;
  practiceRef: DocumentReference<IPractice>;
}

export interface ITransactionsSummary {
  pending: number;
  complete: number;
}

export interface IPrincipleReconciliationReportData {
  revenue: IReconciliationReportItem;
  discount: IReconciliationReportItem;
  total: IReconciliationReportItem;
}

export interface IReconciliationReportItem {
  type: string;
  total: ITransactionsSummary;
  sources: ITransactionSourceSummary[];
}

export interface ITransactionSourceSummary {
  name: string;
  records?: IReconciliationTransactionReportRecord[];
  total: ITransactionsSummary;
}

export interface IReconciliationTransactionDetails {
  patient: INamedDocument<IPatient>;
  invoice: WithRef<IInvoice>;
  transaction: WithRef<ITransaction>;
}

export interface IReconciliationTransactionReportRecord
  extends ITransactionsSummary,
    IReconciliationTransactionDetails {}

export function transactionsToReconciliationReport(
  data: IReconciliationTransactionDetails[]
): IPrincipleReconciliationReportData {
  const orderedProviders = [
    TransactionProvider.Cash,
    TransactionProvider.Discount,
    TransactionProvider.AccountCredit,
    TransactionProvider.AccountCreditTransfer,
    TransactionProvider.Manual,
    TransactionProvider.TyroEftpos,
    TransactionProvider.TyroHealthPoint,
    TransactionProvider.TyroEasyClaimBulkBill,
    TransactionProvider.TyroEasyClaimPartPaid,
    TransactionProvider.TyroEasyClaimFullyPaid,
    TransactionProvider.MedipassHicaps,
    TransactionProvider.MedipassMedicare,
    TransactionProvider.MedipassGapPayment,
    TransactionProvider.MedipassPatientFunded,
    TransactionProvider.MedipassVirtualTerminal,
  ];

  const revenueSources = buildSources(
    data,
    orderedProviders,
    (summary) => summaryHasTransactions(summary) && !isDiscountOrCredit(summary)
  );

  const discountSources = buildSources(
    data,
    orderedProviders,
    (summary) => summaryHasTransactions(summary) && isDiscountOrCredit(summary)
  );

  return {
    revenue: {
      type: reportTypeToString(ReportType.Revenue),
      total: getSourceSummaryTotal(revenueSources, ReportType.Revenue),
      sources: revenueSources,
    },
    discount: {
      type: reportTypeToString(ReportType.DiscountsCredits),
      total: getSourceSummaryTotal(
        discountSources,
        ReportType.DiscountsCredits
      ),
      sources: discountSources,
    },
    total: {
      type: reportTypeToString(ReportType.Total),
      total: getSourceSummaryTotal(
        [...revenueSources, ...discountSources],
        ReportType.Total
      ),
      sources: getTotalReportSources(revenueSources, discountSources),
    },
  };
}

function buildSources(
  data: IReconciliationTransactionDetails[],
  orderedProviders: TransactionProvider[],
  filterFn: (summary: ITransactionSourceSummary) => boolean
): ITransactionSourceSummary[] {
  const summaries = getProviderTransactionSummaries(data);
  const orderedSummaries = orderSummaries(
    addMissingSummaries(summaries, orderedProviders)
  );
  return orderedSummaries.filter(filterFn);
}

export function transactionsSummaryTotal(
  incoming: ITransactionsSummary,
  outgoing: ITransactionsSummary
): ITransactionsSummary {
  return {
    pending: incoming.pending - outgoing.pending,
    complete: incoming.complete - outgoing.complete,
  };
}

function getSourceSummaryTotal(
  sources: ITransactionSourceSummary[],
  reportType: ReportType
): ITransactionsSummary {
  if (reportType === ReportType.Total) {
    return {
      pending: sources.reduce(
        (prev, source) =>
          isDiscountOrCredit(source)
            ? prev - source.total.pending
            : prev + source.total.pending,
        0
      ),
      complete: sources.reduce(
        (prev, source) =>
          isDiscountOrCredit(source)
            ? prev - source.total.complete
            : prev + source.total.complete,
        0
      ),
    };
  }
  return {
    pending: sources.reduce((prev, source) => prev + source.total.pending, 0),
    complete: sources.reduce((prev, source) => prev + source.total.complete, 0),
  };
}

function getTotalReportSources(
  revenueSources: ITransactionSourceSummary[],
  discountSources: ITransactionSourceSummary[]
): ITransactionSourceSummary[] {
  return [
    {
      name: reportTypeToString(ReportType.Revenue),
      total: getSourceSummaryTotal(revenueSources, ReportType.Revenue),
    },
    {
      name: reportTypeToString(ReportType.DiscountsCredits),
      total: getSourceSummaryTotal(
        discountSources,
        ReportType.DiscountsCredits
      ),
    },
  ];
}

export function summaryHasTransactions(
  summary: ITransactionSourceSummary
): boolean {
  return summary.records && summary.records.length > 0 ? true : false;
}

function isDiscountOrCredit(summary: ITransactionSourceSummary): boolean {
  return (
    summary.name === getRawProviderName(TransactionProvider.Discount) ||
    summary.name === getRawProviderName(TransactionProvider.AccountCredit) ||
    summary.name ===
      getRawProviderName(TransactionProvider.AccountCreditTransfer)
  );
}

function addMissingSummaries(
  summaries: ITransactionSourceSummary[],
  desiredNames: string[]
): ITransactionSourceSummary[] {
  const missingSummaries = desiredNames
    .filter(
      (name) => !summaries.some((summary) => summary.name === startCase(name))
    )
    .map((name) => getSourceSummary(name, []));
  return [...summaries, ...missingSummaries];
}

function orderSummaries(
  summaries: ITransactionSourceSummary[]
): ITransactionSourceSummary[] {
  return sortBy(summaries, 'name');
}

function getProviderTransactionSummaries(
  transactions: IReconciliationTransactionDetails[]
): ITransactionSourceSummary[] {
  const grouped = groupBy(transactions, (transaction) =>
    getTransactionProviderName(transaction.transaction)
  );
  return toPairs(grouped).map(([provider, filtered]) =>
    getSourceSummary(provider, filtered)
  );
}

function reportTypeToString(reportType: ReportType): string {
  return reportType === ReportType.DiscountsCredits
    ? 'Account Credits Used & Discounts Applied'
    : startCase(reportType);
}

function getSourceSummary(
  provider: string,
  transactions: IReconciliationTransactionDetails[]
): ITransactionSourceSummary {
  const records = transactions.map((transaction) =>
    buildTransactionRecord(transaction)
  );

  const incoming = getIncomingSummary(
    new TransactionOperators(
      transactions.map((transaction) => transaction.transaction)
    )
  );
  const outgoing = getOutgoingSummary(
    new TransactionOperators(
      transactions.map((transaction) => transaction.transaction)
    )
  );
  return {
    name: provider,
    records,
    total: transactionsSummaryTotal(incoming, outgoing),
  };
}

export function buildTransactionRecord(
  details: IReconciliationTransactionDetails
): IReconciliationTransactionReportRecord {
  const incoming = getIncomingSummary(
    new TransactionOperators([details.transaction])
  );
  const outgoing = getOutgoingSummary(
    new TransactionOperators([details.transaction])
  );

  return {
    patient: toNamedDocument(details.patient),
    invoice: details.invoice,
    transaction: details.transaction,
    pending: incoming.pending - outgoing.pending,
    complete: incoming.complete - outgoing.complete,
  };
}

function getIncomingSummary(
  transactions: TransactionOperators<ITransaction>
): ITransactionsSummary {
  const incomingComplete = transactions
    .byType([TransactionType.Incoming, TransactionType.Claim])
    .completed();

  const incomingPending = transactions
    .byType([TransactionType.Incoming, TransactionType.Claim])
    .pending()
    .filter((transaction) => {
      const completed = incomingComplete
        .byReference(transaction.reference)
        .result();
      const hasCompleted = completed.length > 0;
      return !hasCompleted;
    });

  return {
    pending: incomingPending.sum(),
    complete: incomingComplete.sum(),
  };
}

function getOutgoingSummary(
  transactions: TransactionOperators<ITransaction>
): ITransactionsSummary {
  const outgoingComplete = transactions
    .byType(TransactionType.Outgoing)
    .completed();

  const outgoingPending = transactions
    .byType(TransactionType.Outgoing)
    .pending()
    .filter((transaction) => {
      const completed = outgoingComplete
        .byReference(transaction.reference)
        .result();
      const hasCompleted = completed.length > 0;
      return !hasCompleted;
    });

  return {
    pending: outgoingPending.sum(),
    complete: outgoingComplete.sum(),
  };
}
