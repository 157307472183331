import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import {
  GlobalStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  ProfileImageService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  Appointment,
  Event,
  ParticipantAvatarFactory,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  IAppointment,
  IParticipantAvatar,
  IPatient,
  ITreatmentCategory,
  ITreatmentStep,
  isEventable,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  SHORT_DATE_TIME_WITH_YEAR_FORMAT,
  WithRef,
  asyncForEach,
  multiSwitchMap,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { Observable, ReplaySubject, from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppointmentCardAction } from '../../appointment-card/appointment-card-actions/actions/appointment-card-action-interface';
import { CheckinAppointmentActionService } from '../../appointment-card/appointment-card-actions/actions/checkin-appointment-action.service';
import { CheckoutAppointmentActionService } from '../../appointment-card/appointment-card-actions/actions/checkout-appointment-action.service';
import { CompleteAppointmentActionService } from '../../appointment-card/appointment-card-actions/actions/complete-appointment-action.service';
import { ConfirmAppointmentActionService } from '../../appointment-card/appointment-card-actions/actions/confirm-appointment-action.service';
import { OpenAppointmentActionService } from '../../appointment-card/appointment-card-actions/actions/open-appointment-action.service';
import { StartAppointmentActionService } from '../../appointment-card/appointment-card-actions/actions/start-appointment-action.service';

@Component({
  selector: 'pr-appointment-history-card-header',
  templateUrl: './appointment-history-card-header.component.html',
  styleUrls: ['./appointment-history-card-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentHistoryCardHeaderComponent {
  private _global = inject(GlobalStoreService);
  actions: AppointmentCardAction[];
  profileImage = inject(ProfileImageService);
  trackByParticipant = TrackByFunctions.ref<IParticipantAvatar>();
  dateFormat: string = SHORT_DATE_TIME_WITH_YEAR_FORMAT;
  appointment$ = new ReplaySubject<WithRef<IAppointment>>(1);
  treatmentStep$: Observable<WithRef<ITreatmentStep>>;
  participants$: Observable<IParticipantAvatar[]>;
  patient$: Observable<WithRef<IPatient>>;
  duration$: Observable<number>;
  tags$: Observable<unknown>;
  treatmentCategory$: Observable<
    DocumentReference<ITreatmentCategory> | undefined
  >;

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor(
    public organisation: OrganisationService,
    confirmAppointment: ConfirmAppointmentActionService,
    checkoutAppointment: CheckoutAppointmentActionService,
    checkinAppointment: CheckinAppointmentActionService,
    startAppointment: StartAppointmentActionService,
    openAppointment: OpenAppointmentActionService,
    finishAppointment: CompleteAppointmentActionService
  ) {
    this.patient$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.patient$(appointment))
    );
    this.actions = [
      confirmAppointment,
      checkoutAppointment,
      checkinAppointment,
      startAppointment,
      openAppointment,
      finishAppointment,
    ];
    this.participants$ = this.appointment$.pipe(
      switchMap((appointment) => this._getParticipants$(appointment))
    );
    this.duration$ = this.appointment$.pipe(
      map((appointment) =>
        isEventable(appointment) ? Event.duration(appointment.event) : 0
      )
    );
    this.tags$ = this.appointment$.pipe(
      map((appointment) => appointment.tags),
      multiSwitchMap((tag) => this._global.getTag$(tag.ref)),
      map(compact)
    );

    this.treatmentStep$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.treatmentStep$(appointment))
    );
    this.treatmentCategory$ = this.treatmentStep$.pipe(
      map(({ display }) => TreatmentStep.defaultDisplayRef(display))
    );
  }

  private _getParticipants$(
    appointment: IAppointment
  ): Observable<IParticipantAvatar[]> {
    const factory = new ParticipantAvatarFactory();
    if (!isEventable(appointment)) {
      return of([]);
    }
    return from(
      asyncForEach(Event.staff(appointment.event), (staffer) =>
        factory.create(staffer)
      )
    );
  }
}
