import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  type OnDestroy,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import {
  ChartFacade,
  ChartId,
  type IChartContextState,
  TreatmentPlanFacade,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  CurrentAppointmentScope,
  CurrentTreatmentPlanScope,
} from '@principle-theorem/ng-principle-shared';
import { TreatmentPlan } from '@principle-theorem/principle-core';
import {
  type AnyChartedItemConfiguration,
  type ChartItemDisplayType,
  type IChartedItem,
  type IChartedRef,
  isMultiTreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  isChanged$,
  isSameRef,
  multiMap,
  multiSwitchMap,
  reduce2DArray,
  shareReplayCold,
  snapshot,
  toNamedDocument,
} from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { combineLatest, type Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { AddChartable } from '../../add-chartable';
import { ChartDialogService } from '../../chart-dialog.service';
import { AddConditionToChartProvider } from '../../charted-surface/chart/add-condition-to-chart-provider';
import { AddMultiTreatmentToProposalProvider } from '../../charted-surface/chart/add-multi-treatment-to-proposal-provider';
import { AddTreatmentToProposalProvider } from '../../charted-surface/chart/add-treatment-to-proposal-provider';
import { type IChartSurfaceSelectorData } from '../chart-surface-selector-dialog/chart-surface-selector-dialog.component';
import { ClinicalChartComponent } from '../clinical-chart/clinical-chart.component';
import { CHART_ENTITY_ID } from '../dental-chart-svg/chart-entity-id';
import { ChartedItemsCollection } from '../dental-chart-svg/models/charted-items-collection';
import { ConnectedDialogConfig } from '@principle-theorem/ng-shared';

@Component({
  selector: 'pr-chart-ui',
  templateUrl: './chart-ui.component.html',
  styleUrls: ['./chart-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CHART_ENTITY_ID,
      useValue: ChartId.InAppointment,
    },
  ],
})
export class ChartUiComponent implements OnDestroy {
  private _chartId: ChartId = inject(CHART_ENTITY_ID);
  context$: Observable<IChartContextState>;
  selectedSurfaces$: Observable<Partial<IChartedRef>[]>;
  filteredChartedItems$: Observable<IChartedItem[]>;
  canEdit$: Observable<boolean>;
  @ViewChild(ClinicalChartComponent, { read: ElementRef, static: true })
  chartRef: ElementRef<HTMLElement>;
  @Input() showHistorySelector = true;

  constructor(
    public media: MediaObserver,
    private _treatmentPlanScope: CurrentTreatmentPlanScope,
    private _appointmentScope: CurrentAppointmentScope,
    private _chartStore: ChartFacade,
    private _treatmentPlanFacade: TreatmentPlanFacade,
    private _chartDialogService: ChartDialogService
  ) {
    this.context$ = this._chartStore
      .chartContextState$(this._chartId)
      .pipe(shareReplayCold());
    this.canEdit$ = this._chartStore.canEdit$(this._chartId);

    const currentStep$ = combineLatest([
      this._treatmentPlanScope.doc$,
      this._appointmentScope.doc$,
    ]).pipe(
      switchMap(([plan, appointment]) =>
        plan && appointment
          ? TreatmentPlan.findStepByAppointment$(plan, appointment.ref)
          : of(undefined)
      )
    );

    const currentStepTreatments$ = currentStep$.pipe(
      map((step) => step?.treatments ?? [])
    );

    const pendingPlanTreatments$ = combineLatest([
      this._treatmentPlanFacade.selectedTreatmentPlan$,
      currentStep$,
    ]).pipe(
      switchMap(([plan, currentStep]) => {
        if (!plan) {
          return [];
        }
        return this._treatmentPlanFacade.getTreatmentSteps$(plan.ref).pipe(
          multiMap((step) => {
            if (currentStep && isSameRef(currentStep, step)) {
              return [];
            }
            return step.treatments.filter((treatment) => !treatment.resolvedAt);
          }),
          reduce2DArray()
        );
      }),
      startWith([])
    );

    const completedTreatments$ = this._treatmentPlanFacade.treatmentPlans$.pipe(
      multiSwitchMap((plan) =>
        this._treatmentPlanFacade.getTreatmentSteps$(plan.ref)
      ),
      reduce2DArray(),
      multiMap((step) =>
        step.treatments.filter((treatment) => !!treatment.resolvedAt)
      ),
      reduce2DArray(),
      startWith([])
    );

    this.filteredChartedItems$ = combineLatest([
      this._chartStore.chartedItems$(this._chartId),
      this.context$.pipe(
        map(({ filters, chartingAs }) => ({
          filters,
          chartingAs,
        })),
        isChanged$(
          (contextA, contextB) =>
            isEqual(contextA.filters, contextB.filters) &&
            isSameRef(contextA.chartingAs, contextB.chartingAs)
        )
      ),
      currentStepTreatments$,
      completedTreatments$,
      pendingPlanTreatments$,
    ]).pipe(
      map(
        ([
          items,
          context,
          currentStepTreatments,
          completedTreatments,
          pendingPlanTreatments,
        ]) => {
          return new ChartedItemsCollection([
            ...items,
            ...currentStepTreatments,
            ...completedTreatments,
            ...pendingPlanTreatments,
          ])
            .filterByContext(context.filters, context.chartingAs)
            .deduplicate()
            .toArray();
        }
      ),
      startWith([])
    );

    this.selectedSurfaces$ = this._chartStore.selectedSurfacesState$(
      this._chartId
    );
  }

  ngOnDestroy(): void {
    this.clearSelectedSurfaces();
  }

  setFilters(filters: ChartItemDisplayType[]): void {
    this._chartStore.setChartedItemFilters(this._chartId, filters);
  }

  clearSelectedSurfaces(): void {
    this._chartStore.setSelectedSurfaces(this._chartId, []);
  }

  async addChartable(chartable: AnyChartedItemConfiguration): Promise<void> {
    if (isMultiTreatmentConfiguration(chartable)) {
      const feeSchedule = await snapshot(
        this._chartStore.getFeeScheduleManager().currentSchedule$
      );
      await new AddChartable([
        new AddMultiTreatmentToProposalProvider(
          this._chartStore,
          this._chartDialogService,
          toNamedDocument(feeSchedule)
        ),
      ]).add({
        selectedSurfaces: [],
        chartable,
        chartingAs: await snapshot(this._chartStore.chartingAs$(this._chartId)),
      });
      return;
    }

    const selectedSurfaces = await this._getSurfaces(chartable);
    if (!selectedSurfaces.length) {
      return;
    }

    this._chartStore.setSelectedSurfaces(this._chartId, []);

    const feeSchedule = await snapshot(
      this._chartStore.getFeeScheduleManager().currentSchedule$
    );

    const addSurfaceProviders = [
      new AddConditionToChartProvider(this._chartStore),
      new AddTreatmentToProposalProvider(this._chartStore, feeSchedule),
    ];
    await new AddChartable(addSurfaceProviders).add({
      selectedSurfaces,
      chartable,
      chartingAs: await snapshot(this._chartStore.chartingAs$(this._chartId)),
    });
  }

  private async _getSurfaces(
    chartable: AnyChartedItemConfiguration
  ): Promise<Partial<IChartedRef>[]> {
    const selectedSurfaces: Partial<IChartedRef>[] = await snapshot(
      this.selectedSurfaces$
    );

    if (selectedSurfaces.length) {
      return selectedSurfaces;
    }

    const offsetX = -24;
    const offsetY = -72;
    const chartIsInCurrentView = this._chartIsInCurrentView();

    const config: ConnectedDialogConfig<
      IChartSurfaceSelectorData,
      Partial<IChartedRef>[]
    > = {
      width: this._getDialogWidth(offsetX),
      restoreFocus: false,
    };

    if (chartIsInCurrentView) {
      config.connectedTo = this.chartRef;
      config.positions = [
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
          offsetX: offsetX,
          offsetY: offsetY,
        },
      ];
    }

    const surfaces = await this._chartDialogService.getSurfaces(
      chartable,
      config
    );
    if (!surfaces) {
      return [];
    }
    return surfaces;
  }

  private _getDialogWidth(offsetX: number): string {
    const width: number =
      this.chartRef.nativeElement.getBoundingClientRect().width + 2 * -offsetX;
    return `${width.toString()}px`;
  }

  private _chartIsInCurrentView(): boolean {
    const chartRect = this.chartRef.nativeElement.getBoundingClientRect();
    const viewPortHeight = window.innerHeight;
    const viewPortWidth = window.innerWidth;
    const chartTop = chartRect.top;
    const chartBottom = chartRect.bottom;
    const chartLeft = chartRect.left;
    const chartRight = chartRect.right;

    return (
      chartTop >= 0 &&
      chartLeft >= 0 &&
      chartBottom <= viewPortHeight &&
      chartRight <= viewPortWidth
    );
  }
}
