import {
  AccountCredit,
  Brand,
  Invoice,
  Patient,
  hasMergeConflicts,
} from '@principle-theorem/principle-core';
import {
  FailedDestinationEntityRecord,
  IDestinationEntityJobRunOptions,
  type IBasePatient,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IInvoice,
  type IPatient,
  type IPatientContactDetails,
  type IPracticeMigration,
  type IStaffer,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  Timestamp,
  all$,
  asyncForAll,
  asyncForEach,
  doc,
  getError,
  omitByKeys,
  runTransaction,
  type DocumentReference,
  type IIdentifiable,
  type WithRef,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { compact, sumBy } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  BasePatientInvoiceDestinationEntity,
  IInvoiceBuildData,
  IInvoiceUpdateData,
  IPatientInvoiceJobData,
  IPatientInvoiceMigrationData,
  PATIENT_INVOICE_DESTINATION_ENTITY,
  PATIENT_TRANSACTION_RESOURCE_TYPE,
} from '../../../destination/entities/patient-invoices';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import { PatientBalanceSourceEntity } from '../../source/entities/patient-balance';
import { PatientDiscountSourceEntity } from '../../source/entities/patient-discounts';
import { PatientPaymentAdjustmentSourceEntity } from '../../source/entities/patient-payment-adjustments';
import { PatientPaymentSourceEntity } from '../../source/entities/patient-payments';
import { PatientWriteOffSourceEntity } from '../../source/entities/patient-treatment-write-offs';
import { PatientTreatmentsSourceEntity } from '../../source/entities/patient-treatments';
import {
  IOasisPatient,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { OasisItemCodeMappingHandler } from '../mappings/item-codes';
import { OasisStafferMappingHandler } from '../mappings/staff';
import { OasisInvoiceBuilder } from './lib/oasis-invoice-builder';
import { PatientDestinationEntity } from './patients';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';

export const patientInvoiceDestinationEntity =
  DestinationEntity.withMetadataDescription(
    PATIENT_INVOICE_DESTINATION_ENTITY,
    `
      Oasis has a flat table of payments, transactions, adjustments, discounts, and write offs (incoming and outgoing). To deal with this, any charges made on the same day are grouped into an invoice and their line items will be built by any found treatments.

      Afterwards, all payments are ordered by date and we apply payments to the oldest invoices first. Any overpayment that exists at the end will be converted to account credit.
    `
  );

export class PatientInvoicesDestinationEntity extends BasePatientInvoiceDestinationEntity<
  IOasisPatient,
  IPatientInvoiceJobData<IOasisPatient>
> {
  override destinationEntity = patientInvoiceDestinationEntity;
  patientSourceEntity = new PatientSourceEntity();

  override canMigrateByIdRange = true;

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    discounts: new PatientDiscountSourceEntity(),
    paymentAdjustments: new PatientPaymentAdjustmentSourceEntity(),
    payments: new PatientPaymentSourceEntity(),
    writeOffs: new PatientWriteOffSourceEntity(),
    treatments: new PatientTreatmentsSourceEntity(),
    balances: new PatientBalanceSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  customMappings = {
    staff: new OasisStafferMappingHandler(),
    itemCodes: new OasisItemCodeMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientInvoiceJobData<IOasisPatient>[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const brand$ = PracticeMigration.brand$(migration);
    const practitioners$ = brand$.pipe(
      switchMap((brand) => all$(Brand.stafferCol(brand)))
    );
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      combineLatest([staff$, brand$, practitioners$, sourceItemCodes$]).pipe(
        take(1)
      ),
    ]).pipe(
      map(([patients, [staff, brand, practitioners, sourceItemCodes]]) =>
        patients.map((sourcePatient) => ({
          sourcePatient,
          staff,
          brand,
          practitioners,
          sourceItemCodes,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientInvoiceJobData<IOasisPatient>
  ): Promise<
    | IPatientInvoiceMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const practice = await Firestore.getDoc(
      migration.configuration.practices[0].ref
    );
    const patientId = data.sourcePatient.data.data.id;
    const patientRef = await translationMap.getDestination<IPatient>(
      patientId.toString(),
      PATIENT_RESOURCE_TYPE
    );
    if (!patientRef) {
      return this.buildErrorResponse(
        data.sourcePatient.record,
        `Couldn't resolve patient`
      );
    }

    const patient = await Firestore.getDoc(patientRef);

    const sourceTransactions = await this.sourceEntities.payments.filterRecords(
      migration,
      'accountPatientId',
      patientId
    );

    const sourceTreatments = await this.sourceEntities.treatments.filterRecords(
      migration,
      'accountPatientId',
      patientId
    );

    const sourceDiscounts = await this.sourceEntities.discounts.filterRecords(
      migration,
      'accountPatientId',
      patientId
    );

    const sourcePaymentAdjustments =
      await this.sourceEntities.paymentAdjustments.filterRecords(
        migration,
        'accountPatientId',
        patientId
      );

    const sourceWriteOffs = await this.sourceEntities.writeOffs.filterRecords(
      migration,
      'accountPatientId',
      patientId
    );

    const balance = sumBy(
      sourceTransactions,
      (transaction) =>
        transaction.data.data.claimAmount ?? transaction.data.data.amount ?? 0
    );

    const invoiceBuilder = new OasisInvoiceBuilder(
      sourceTreatments,
      sourceTransactions,
      sourceDiscounts,
      sourcePaymentAdjustments,
      sourceWriteOffs,
      balance,
      data.staff,
      data.practitioners,
      data.sourceItemCodes,
      translationMap,
      migration
    );

    try {
      invoiceBuilder.buildAccountSummary(practice.ref);
      invoiceBuilder.validateAccountSummary();

      const invoiceData = await invoiceBuilder.buildInvoices(
        patient as IBasePatient & IPatientContactDetails,
        practice
      );

      return {
        patientRef: patient.ref,
        data: invoiceData,
        createdAt: Timestamp.now(),
      };
    } catch (error) {
      return this.buildErrorResponse(
        data.sourcePatient.record,
        getError(error)
      );
    }
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: IPatientInvoiceJobData<IOasisPatient>,
    migrationData: IPatientInvoiceMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const invoiceRefs = await this._upsertInvoices(
        migrationData,
        translationMap,
        migrationData.patientRef
      );

      return this._buildSuccessResponse(jobData.sourcePatient, invoiceRefs);
    } catch (error) {
      return this.buildErrorResponse(
        {
          label: jobData.sourcePatient.record.label,
          uid: jobData.sourcePatient.record.uid,
          ref: jobData.sourcePatient.record.ref,
        },
        getError(error)
      );
    }
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    migrationData: IPatientInvoiceMigrationData
  ): Promise<IPatientInvoiceMigrationData | undefined> {
    const invoiceMergeConflicts: (IInvoiceBuildData | undefined)[] =
      await asyncForEach(migrationData.data, async (invoiceBuildData) => {
        try {
          const invoiceRef = await translationMap.getDestination<IInvoice>(
            invoiceBuildData.invoice.uid,
            PATIENT_TRANSACTION_RESOURCE_TYPE
          );

          if (!invoiceRef) {
            return;
          }

          const existingInvoice = await Firestore.getDoc(invoiceRef);
          const invoiceMergeConflict = hasMergeConflicts(
            omitByKeys(invoiceBuildData.invoice, ['from', 'to', 'reference']),
            omitByKeys(existingInvoice, ['from', 'to', 'reference']),
            [],
            [
              {
                key: 'transactions',
                sortByPath: 'reference',
              },
            ]
          );

          const existingTransactions: (IIdentifiable & ITransaction)[] = [];

          const transactionMergeConflicts = await asyncForEach(
            invoiceBuildData.transactions,
            async (transaction) => {
              try {
                const existingTransaction = await Firestore.getDoc(
                  doc(Invoice.transactionCol(existingInvoice), transaction.uid)
                );

                existingTransactions.push({
                  ...existingTransaction,
                });

                return hasMergeConflicts(transaction, existingTransaction);
              } catch (error) {
                return false;
              }
            }
          );

          const hasMergeConflict = [
            invoiceMergeConflict,
            ...transactionMergeConflicts,
          ].some((mergeConflict) => mergeConflict);

          if (hasMergeConflict) {
            return {
              ...invoiceBuildData,
              invoice: {
                ...existingInvoice,
                uid: invoiceBuildData.invoice.uid,
              },
              transactions: existingTransactions,
            };
          }
        } catch (error) {
          return;
        }
      });

    if (invoiceMergeConflicts.some((conflict) => conflict)) {
      return {
        ...migrationData,
        data: compact(invoiceMergeConflicts),
      };
    }
  }

  private async _upsertInvoices(
    migrationData: IPatientInvoiceMigrationData,
    translationMap: TranslationMapHandler,
    patientRef: DocumentReference<IPatient>
  ): Promise<IInvoiceUpdateData[]> {
    const invoiceData = await runTransaction(async (firestoreTransaction) =>
      asyncForAll(migrationData.data, async (invoiceBuildData) => {
        const invoiceDestinationRef =
          await translationMap.getDestination<IInvoice>(
            invoiceBuildData.invoice.uid,
            PATIENT_TRANSACTION_RESOURCE_TYPE
          );

        if (invoiceDestinationRef) {
          const existingInvoice = await Firestore.getDoc(invoiceDestinationRef);

          const existingTransactions = await Firestore.getDocs(
            Invoice.transactionCol({ ref: invoiceDestinationRef })
          );
          const invoiceIsMigrated =
            Firestore.isUpdatedByMigration(existingInvoice);
          const allTransactionsMigrated = existingTransactions.every(
            (transaction) => Firestore.isUpdatedByMigration(transaction)
          );
          if (!invoiceIsMigrated || !allTransactionsMigrated) {
            throw new Error(
              `Invoice updated outside of migration: ${invoiceDestinationRef.path}`
            );
          }

          await asyncForAll(existingTransactions, (transaction) =>
            FirestoreMigrate.deleteDoc(transaction.ref, firestoreTransaction)
          );
        }

        const invoiceRef = await FirestoreMigrate.upsertDoc(
          Patient.invoiceCol({
            ref: patientRef,
          }),
          { ...invoiceBuildData.invoice, deleted: false },
          invoiceDestinationRef?.id,
          firestoreTransaction
        );

        if (!invoiceDestinationRef) {
          await translationMap.upsert(
            {
              sourceIdentifier: invoiceBuildData.invoice.uid,
              destinationIdentifier: invoiceRef,
              resourceType: PATIENT_TRANSACTION_RESOURCE_TYPE,
            },
            firestoreTransaction
          );
        }

        const transactionRefs = await asyncForAll(
          invoiceBuildData.transactions,
          (transaction) =>
            FirestoreMigrate.upsertDoc(
              Invoice.transactionCol({
                ref: invoiceRef,
              }),
              { ...transaction, deleted: false },
              transaction.uid,
              firestoreTransaction
            )
        );

        const resolvedInvoice = {
          ...invoiceBuildData.invoice,
          ref: invoiceRef,
        };

        const existingCredits = await Firestore.getDocs(
          AccountCredit.col({ ref: patientRef })
        );

        const migratedCredits = existingCredits.filter((credit) =>
          Firestore.isUpdatedByMigration(credit)
        );

        if (migratedCredits.length) {
          await asyncForAll(
            migratedCredits.filter((credit) => !credit.deleted),
            (credit) => {
              return FirestoreMigrate.deleteDoc(
                credit.ref,
                firestoreTransaction
              );
            }
          );
        }

        const accountCredits = Invoice.getDueCredits(resolvedInvoice, []);

        const accountCreditRefs = await asyncForAll(
          accountCredits,
          (accountCredit) =>
            FirestoreMigrate.upsertDoc(
              AccountCredit.col({
                ref: patientRef,
              }),
              { ...accountCredit, deleted: false },
              accountCredit.depositUid,
              firestoreTransaction
            )
        );

        return {
          invoiceRef,
          transactionRefs,
          accountCreditRefs,
        };
      })
    );

    return compact(invoiceData);
  }
}
