<pt-feature-info />
<pt-breadcrumbs current="Automations" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="layout-margin">
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="16px">
    <pt-date-range-form
      *ngIf="dateRange$ | async as dateRange"
      [useRangePicker]="true"
      [from]="dateRange.from"
      [to]="dateRange.to"
      (changed)="dateRange$.next($event)"
    />
    <pr-practice-selector
      *ngIf="brand$ | async as brand"
      [brands]="[brand]"
      [practice]="practice$ | async"
      (practiceSelected)="practice$.next($event)"
    />
  </div>

  <pr-automations-list layout="table" />
</div>
