<ng-container *ngIf="isAppointment()">
  <pr-appointment-card
    *ngIf="appointment$ | async as appointment"
    [appointment]="appointment"
    [actions]="appointmentActions"
   />
</ng-container>

<ng-container *ngIf="isGap()">
  <pr-gap-card [gap]="gap" />
</ng-container>

<ng-container *ngIf="isLabJob()">
  <pr-lab-job-card *ngIf="labJob$ | async as labjob" [labJob]="labjob" />
</ng-container>
