import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  MONTH_YEAR_FORMATS,
  TypedAbstractControl,
  TypedFormControl,
  TypedFormGroup,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import { IMedicareCard } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject, Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  MONTH_YEAR_FORMAT,
  snapshot,
  toMoment,
  toTimestamp,
} from '@principle-theorem/shared';
import { switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-patient-details-medicare-form',
    templateUrl: './patient-details-medicare-form.component.html',
    styleUrl: './patient-details-medicare-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ...MOMENT_DATEPICKER_PROVIDERS,
        { provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR_FORMATS },
    ],
    standalone: false
})
export class PatientDetailsMedicareFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  startOfMonth = moment().startOf('month');
  medicareForm$ = new ReplaySubject<TypedFormGroup<IMedicareCard>>(1);
  expiryDateCtrl = new TypedFormControl<moment.Moment>(undefined);

  @Input()
  set form(form: TypedAbstractControl<IMedicareCard>) {
    if (form) {
      this.medicareForm$.next(form as TypedFormGroup<IMedicareCard>);
    }
  }

  constructor() {
    this.medicareForm$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((form) => {
        if (form.controls.expiryDate.value) {
          this.expiryDateCtrl.setValue(
            toMoment(form.controls.expiryDate.value)
          );
        }
      });

    this.medicareForm$
      .pipe(
        switchMap((form) => formControlChanges$(form.controls.expiryDate)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((expiryDate) =>
        expiryDate
          ? this.expiryDateCtrl.setValue(toMoment(expiryDate))
          : this.expiryDateCtrl.reset()
      );
  }

  async dateInputChangeHandler(element: EventTarget | null): Promise<void> {
    if (!element) {
      return;
    }
    const date = moment((element as HTMLInputElement).value, MONTH_YEAR_FORMAT);
    if (!date.isValid()) {
      return;
    }
    await this._updateExpiryDate(date);
  }

  async chosenDateHandler(
    dateSelected: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ): Promise<void> {
    await this._updateExpiryDate(dateSelected);
    datepicker.close();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private async _updateExpiryDate(date: moment.Moment): Promise<void> {
    this.expiryDateCtrl.setValue(date);
    const form = await snapshot(this.medicareForm$);
    form.controls.expiryDate.setValue(toTimestamp(date));
    this.medicareForm$.next(form);
  }
}
