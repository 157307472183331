<pt-feature-info />
<pt-breadcrumbs current="Sterilisation Tracking" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="layout-padding">
  <div
    fxLayout="row"
    fxLayoutGap="16px"
    class="layout-margin"
    fxLayoutAlign="end center"
  >
    <button mat-flat-button color="primary" (click)="addSteriRecord()">
      Add Record
    </button>
  </div>

  <pr-sterilisation-list
    [sterilisationRecordCol]="sterilisationRecordCol$ | async"
  />
</div>
