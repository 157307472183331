import { IRole } from '@principle-theorem/principle-core/interfaces';
import { initFirestoreModel } from '@principle-theorem/shared';

export class Role {
  static init(overrides?: Partial<IRole>): IRole {
    return {
      name: 'New Role',
      permissions: [],
      ...initFirestoreModel(),
      ...overrides,
    };
  }
}
