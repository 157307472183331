@if (labJobs$ | async; as labJobs) {
  @if (labJobs.length) {
    <mat-list>
      @for (labJob of labJobs; track labJob.ref.path) {
        <pr-lab-job-item
          [selectDisabled]="multiSelectDisabled$ | async"
          [item]="labJob"
          [selectedItem]="selectedItem"
          (selectedItemChange)="selectItem($event)"
          [selectionList]="selectionList"
        />
      }
    </mat-list>
  }
}
