import { Injectable } from '@angular/core';
import { ScheduleHistoryQueryByDate } from '@principle-theorem/ng-principle-shared';
import {
  SchedulingEvent,
  TimezoneResolver,
} from '@principle-theorem/principle-core';
import {
  IPractice,
  ISchedulingEventSummary,
  SchedulingEventType,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  ISODateType,
  ITimePeriod,
  WithRef,
  filterUndefined,
  getDaysInPeriod,
  multiSort,
  multiSortBy$,
  safeCombineLatest,
  sortTimestamp,
} from '@principle-theorem/shared';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

export interface ISchedulingEventQuery {
  practiceRef: DocumentReference<IPractice>;
  eventTypes: SchedulingEventType[];
  byDate: ScheduleHistoryQueryByDate;
  dateRange: ITimePeriod;
}

interface IDayQuery {
  query: ISchedulingEventQuery;
  date: ISODateType;
}

export interface IDayResult {
  date: ISODateType;
  events: ISchedulingEventSummary[];
}

// TODO: Convert to signals
@Injectable()
export class ScheduleHistoryService {
  loading$ = new BehaviorSubject<boolean>(false);
  query$ = new BehaviorSubject<ISchedulingEventQuery | undefined>(undefined);
  filter$ = new BehaviorSubject<string>('');
  days$: Observable<IDayResult[]>;
  hasMultipleDays$: Observable<boolean>;

  constructor() {
    const queryResults$ = this.query$.pipe(
      filterUndefined(),
      tap(() => this.loading$.next(true)),
      switchMap((query) => this._getDayQueries(query)),
      switchMap((dayQueries) => this._resolveDayQueries$(dayQueries)),
      tap(() => this.loading$.next(false))
    );

    this.days$ = combineLatest([queryResults$, this.filter$]).pipe(
      map(([queryData, filterData]) =>
        this._applyFilters(queryData, filterData)
      )
    );

    this.hasMultipleDays$ = this.days$.pipe(map((days) => days.length > 1));
  }

  private async _getDayQueries(
    query: ISchedulingEventQuery
  ): Promise<IDayQuery[]> {
    const timezone = await TimezoneResolver.fromPracticeRef(query.practiceRef);
    return getDaysInPeriod(timezone, query.dateRange).map((date) => ({
      date,
      query,
    }));
  }

  private _resolveDayQueries$(
    dayQueries: IDayQuery[]
  ): Observable<IDayResult[]> {
    const groups$ = dayQueries.map((dayQuery) =>
      this._resolveDayQuery$(dayQuery)
    );
    return safeCombineLatest(groups$).pipe(multiSortBy$((group) => group.date));
  }

  private _resolveDayQuery$(dayQuery: IDayQuery): Observable<IDayResult> {
    return this._getEvents$(
      dayQuery.query.practiceRef,
      dayQuery.query.eventTypes,
      dayQuery.query.byDate,
      dayQuery.date
    ).pipe(
      multiSort((a, b) => sortTimestamp(a.scheduledAt, b.scheduledAt)),
      map((events) => ({ date: dayQuery.date, events }))
    );
  }

  private _getEvents$(
    practiceRef: DocumentReference<IPractice>,
    eventTypes: SchedulingEventType[],
    byDate: ScheduleHistoryQueryByDate,
    isoDate: string
  ): Observable<WithRef<ISchedulingEventSummary>[]> {
    if (byDate === ScheduleHistoryQueryByDate.AffectsDate) {
      return SchedulingEvent.queryByAffectsPractice$(
        practiceRef,
        eventTypes,
        isoDate
      );
    }
    return SchedulingEvent.queryByScheduledByPractice$(
      practiceRef,
      eventTypes,
      isoDate
    );
  }

  private _applyFilters(days: IDayResult[], filterData: string): IDayResult[] {
    return days.map((day) => ({
      date: day.date,
      events: this._filterEvents(day.events, filterData),
    }));
  }

  private _filterEvents(
    events: ISchedulingEventSummary[],
    filterValue: string
  ): ISchedulingEventSummary[] {
    if (!filterValue.length) {
      return events;
    }
    const searchTerms = filterValue.toLowerCase().split(' ');
    return events.filter((event) => {
      const hasAllTerms = searchTerms.every((term) =>
        event.filterValue.includes(term)
      );
      return hasAllTerms;
    });
  }
}
