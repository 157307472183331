import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TimezoneService } from '@principle-theorem/ng-principle-shared';
import {
  type IAppointment,
  type IEvent,
} from '@principle-theorem/principle-core/interfaces';
import {
  CASUAL_DATE_WITH_YEAR,
  formatTimeFromTo,
  timePeriodToHumanisedTime,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-event-details-sidebar',
    templateUrl: './event-details-sidebar.component.html',
    styleUrls: ['./event-details-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EventDetailsSidebarComponent {
  appointment$ = new ReplaySubject<WithRef<IAppointment>>(1);
  event$ = new ReplaySubject<IEvent>(1);
  dateFormat: string = CASUAL_DATE_WITH_YEAR;
  timeDisplay$: Observable<string>;

  @Input()
  set event(event: IEvent) {
    if (event) {
      this.event$.next(event);
    }
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor(private _timezone: TimezoneService) {
    this.timeDisplay$ = this.event$.pipe(
      switchMap((event) => this._timezone.getEventRange$(event)),
      map((range) => {
        const duration = timePeriodToHumanisedTime(range);
        return `${formatTimeFromTo(range.from, range.to)} (${duration})`;
      })
    );
  }
}
