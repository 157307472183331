import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  Timezone,
  TypeGuard,
  toTimestamp,
} from '@principle-theorem/shared';
import { flow, isNull, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { PATIENT_APPOINTMENT_RESOURCE_TYPE } from '../../../destination/entities/patient-appointments';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { EXACT_DATE_TIME_FORMAT, convertExactId } from '../../util/helpers';
import { PatientSourceEntity } from './patient';

export const PATIENT_APPOINTMENT_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Appointment List',
      description: '',
      idPrefix: PATIENT_APPOINTMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IExactAppointment {
  appointment_id: string;
  patient_id: string;
  appointment_date_time: string;
  appointment_status: ExactAppointmentStatus;
  treatment_name: string;
  appointment_duration: string;
  practitioner_initials: string;
  appointment_note: string | null;
  appointment_cancel_reason: string | null;
  appointment_category: string | null;
  location_id: string | null;
  room_id: string | null;
  appointment_arrival_time: string | null;
}

export interface IExactAppointmentTranslations {
  appointmentDateTime: Timestamp;
  appointmentArrivalTime: Timestamp | undefined;
}

export interface IExactAppointmentFilters {
  patientId: string;
  from: Timestamp;
}

export enum ExactAppointmentStatus {
  Complete = 'complete',
  Failed = 'failed',
  Booked = 'booked',
  Cancelled = 'cancelled',
  Confirmed = 'confirmed',
}

function isExactPatientAppointmentListItem(
  item: unknown
): item is IExactAppointment {
  return TypeGuard.interface<IExactAppointment>({
    appointment_id: isString,
    patient_id: isString,
    appointment_date_time: isString,
    appointment_status: TypeGuard.enumValue(ExactAppointmentStatus),
    treatment_name: isString,
    appointment_duration: isString,
    practitioner_initials: isString,
    appointment_note: [isString, isNull],
    appointment_cancel_reason: [isString, isNull],
    appointment_category: [isString, isNull],
    location_id: [isString, isNull],
    room_id: [isString, isNull],
    appointment_arrival_time: [isString, isNull],
  })(item);
}

const PATIENT_APPOINTMENT_SOURCE_QUERY = `
SELECT
  sourceid::TEXT AS appointment_id,
  patientid::TEXT AS patient_id,
  datetime AS appointment_date_time,
  LOWER(status) AS appointment_status,
  service AS treatment_name,
  length AS appointment_duration,
  dentist::TEXT AS practitioner_initials,
  NULLIF(comment, '') AS appointment_note,
  NULLIF(cancelreason, '') AS appointment_cancel_reason,
  NULLIF(apptcategory, '') AS appointment_category,
  NULLIF(locationid::TEXT, '') AS location_id,
  NULLIF(roomid::TEXT, '') AS room_id,
  NULLIF(arrivaltime, '') AS appointment_arrival_time
FROM
  convappointment
`;

export class AppointmentSourceEntity extends BaseSourceEntity<
  IExactAppointment,
  IExactAppointmentTranslations,
  IExactAppointmentFilters
> {
  sourceEntity = PATIENT_APPOINTMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_APPOINTMENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_APPOINTMENT_SOURCE_QUERY;
  verifySourceFn = isExactPatientAppointmentListItem;
  override dateFilterField: keyof IExactAppointmentFilters = 'from';
  allowOffsetJob = true;
  override defaultOffsetSize = 10000;

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  override transformDataFn = flow([
    (rows: IExactAppointment[]) =>
      rows.map((appointment) => ({
        ...appointment,
        appointment_id: convertExactId(appointment.appointment_id),
        patient_id: convertExactId(appointment.patient_id),
      })),
  ]);

  translate(
    appointment: IExactAppointment,
    timezone: Timezone
  ): IExactAppointmentTranslations {
    return {
      appointmentDateTime: toTimestamp(
        moment.tz(
          appointment.appointment_date_time,
          EXACT_DATE_TIME_FORMAT,
          timezone
        )
      ),
      appointmentArrivalTime: appointment.appointment_arrival_time
        ? toTimestamp(
            moment.tz(
              appointment.appointment_arrival_time,
              EXACT_DATE_TIME_FORMAT,
              timezone
            )
          )
        : undefined,
    };
  }

  getFilterData(
    data: IExactAppointment,
    timezone: Timezone
  ): IExactAppointmentFilters {
    return {
      patientId: data.patient_id,
      from: toTimestamp(
        moment.tz(data.appointment_date_time, EXACT_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getSourceRecordId(data: IExactAppointment): string {
    return data.appointment_id;
  }

  getSourceLabel(data: IExactAppointment): string {
    return `${data.patient_id} ${data.appointment_date_time}`;
  }
}
