import { ChartType } from '@principle-theorem/reporting';
import { BaseChart } from './base-chart';
import { type IChartData, ChartOptions } from './chart-builder';

export class ComboChart extends BaseChart {
  data: IChartData = {
    type: ChartType.Combo,
    options: ChartOptions.init({
      seriesType: 'bars',
      series: {
        0: { type: 'line' },
      }, // this is first element of the metric array in IMetricTarget
    }),
  };
}
