import { Injectable } from '@angular/core';
import {
  type IHealthcareClaim,
  type IInvoice,
  TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import { Region, type WithRef } from '@principle-theorem/shared';
import { type Observable, of } from 'rxjs';
import {
  type ITransactionProvider,
  TransactionProviderType,
} from '../transaction-provider';

@Injectable()
export class MedipassGapPaymentTransactionProvider
  implements ITransactionProvider
{
  providerId = TransactionProvider.MedipassGapPayment;
  providerType = TransactionProviderType.Payment;
  providerRegions = [Region.Australia];
  isEnabled$: Observable<boolean>;

  constructor() {
    this.isEnabled$ = of(false);
  }

  canCapture$(
    _invoice: WithRef<IInvoice>,
    _claim?: IHealthcareClaim
  ): Observable<boolean> {
    return of(false);
  }

  async capture(
    _invoice: WithRef<IInvoice>,
    _claim?: IHealthcareClaim
  ): Promise<undefined> {
    return Promise.resolve(undefined);
  }
}
