import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISODateType,
  TypeGuard,
  toISODate,
  type Timezone,
  ISO_DATE_TIME_FORMAT,
} from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { OFFSET_PLACEHOLDER } from '../../../source/source-helpers';

export const PATIENT_TREATMENT_NOTE_RESOURCE_TYPE = 'patientTreatmentNotes';

export const PATIENT_TREATMENT_NOTE_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Treatment Note List',
      description: '',
      idPrefix: PATIENT_TREATMENT_NOTE_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ICorePracticePatientTreatmentNote {
  id: number;
  note: string;
  // "Reason For Appointment: Check and clean
  // Chief complaint: patient states pain on RHS. Cannot tell exactly if it comes from top or bottom teeth.
  // Patient says he has lost a few teeth and wants a general checkup to see what needs to be done.
  // Exam type: Comprehensive
  // Medical History: Reviewed on ...
  // Comments on Medical History: Diabetes, Cholesterol and HBP
  // Did not remember all the medication he takes.
  // Extraoral Exam: NAD
  // TMJ: Hypertonic masseters
  // Bruxism: Moderate Bruxisim
  // Intraoral Exam: Soft  Tissues: Tongue, palate, mucosa, FOM -NAD
  // Oral hygiene: Poor
  // Perio: Generalised gingivitis, Subgingival Calculus, Supragingival Calculus
  // Periodontitis: Stage 2, Grade B
  // PBWs taken: Today
  // PBWs radiograph: No caries lesion requiring restoration
  // OPGs taken: Today
  // OPGs radiograph: Generalized bone loss, Multiple teeth missing, RCT(s)
  // Treatment: Scaling, Prophy, Fluoride tx, OHI given, Photos taken
  // Treatment plan Discussion: Discussed comprehensive treatment.
  // Q1 - Malposition of 15,
  //   apical radiolucency of 14 with grade I mobility and TTP +ve ------ Tx depending on CBCT
  // tooth 11 - Filling needed
  // Q2 - root retained on 21 and 25 - To be removed.
  // Q4 - root retained 46, previous RCT - to be removed
  // 48 - large decay - Top be removed.

  // See charting

  // 1. CBC - plan for Implats
  // 2. Dentures? patient to decide.
  // Next visit: Filling and Extractions (90 min)
  // "
  treatmentId: number;
  patientId: number;
  providerId?: number;
  itemCode: string;
  itemCodeName: string;
  completeDate?: string; // 2023-03-21 04:53:14.293
  // This is always set when completedDate is empty
  planDate?: string; // 2023-03-21 04:32:36.890
}

export function isCorePracticePatientTreatmentNote(
  item: unknown
): item is ICorePracticePatientTreatmentNote {
  return TypeGuard.interface<ICorePracticePatientTreatmentNote>({
    id: isNumber,
    note: isString,
    treatmentId: isNumber,
    patientId: isNumber,
    providerId: TypeGuard.nilOr(isNumber),
    itemCode: isString,
    itemCodeName: isString,
    completeDate: TypeGuard.nilOr(isString),
    planDate: TypeGuard.nilOr(isString),
  })(item);
}

export interface ICorePracticePatientTreatmentNoteTranslations {
  completeDate?: ISODateType;
  planDate?: ISODateType;
}

export interface ICorePracticePatientTreatmentNoteFilters {
  treatmentId: number;
  patientId: number;
  providerId?: number;
}

const PATIENT_TREATMENT_NOTE_SOURCE_QUERY = `
SELECT
	treatment.patient_id,
	treatment.complete_date,
	treatment.plan_date,
	item_code.item_code,
	item_code.name AS item_code_name,
	note.*
FROM (
	SELECT
  		TreatmentNoteId AS id,
  		TreatmentId AS treatment_id,
		Note AS note
	FROM tblTreatmentNote
  ${OFFSET_PLACEHOLDER}
) AS note
LEFT JOIN (
  SELECT
	  CompleteDate AS complete_date,
    PlanDate AS plan_date,
    TreatmentId AS id,
    PatientId AS patient_id,
	  ItemId AS item_id,
    ProviderId AS provider_id
  FROM tblTreatment
) AS treatment
ON note.treatment_id = treatment.id
LEFT JOIN (
  SELECT
    ItemId AS id,
    ItemCode AS item_code,
    Name AS name
FROM tblItem
) AS item_code
ON item_code.id = treatment.item_id
`;

export class PatientTreatmentNoteSourceEntity extends BaseSourceEntity<
  ICorePracticePatientTreatmentNote,
  ICorePracticePatientTreatmentNoteTranslations,
  ICorePracticePatientTreatmentNoteFilters
> {
  sourceEntity = PATIENT_TREATMENT_NOTE_SOURCE_ENTITY;
  entityResourceType = PATIENT_TREATMENT_NOTE_RESOURCE_TYPE;
  sourceQuery = PATIENT_TREATMENT_NOTE_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientTreatmentNote;

  translate(
    data: ICorePracticePatientTreatmentNote,
    timezone: Timezone
  ): ICorePracticePatientTreatmentNoteTranslations {
    return {
      completeDate: data.completeDate
        ? toISODate(
            moment.utc(data.completeDate, ISO_DATE_TIME_FORMAT).tz(timezone)
          )
        : undefined,
      planDate: data.planDate
        ? toISODate(
            moment.utc(data.planDate, ISO_DATE_TIME_FORMAT).tz(timezone)
          )
        : undefined,
    };
  }

  getSourceRecordId(data: ICorePracticePatientTreatmentNote): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientTreatmentNote): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientTreatmentNote,
    _timezone: Timezone
  ): ICorePracticePatientTreatmentNoteFilters {
    return {
      treatmentId: data.treatmentId,
      patientId: data.patientId,
      providerId: data.providerId,
    };
  }
}
