import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  GapStoreService,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IInteractionTypeMap,
  interactionTypeDisplayMap,
  type ISchedulingAlert,
} from '@principle-theorem/principle-core/interfaces';
import { toMoment, toISODate } from '@principle-theorem/shared';

@Component({
  selector: 'pr-scheduling-alerts-list',
  templateUrl: './scheduling-alerts-list.component.html',
  styleUrls: ['./scheduling-alerts-list.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SchedulingAlertsListComponent {
  trackByAlert = TrackByFunctions.title<ISchedulingAlert>();
  @Input() schedulingAlerts: ISchedulingAlert[];
  typeIcon: IInteractionTypeMap = interactionTypeDisplayMap;

  constructor(
    private _gapStore: GapStoreService,
    private _stateNav: StateBasedNavigationService
  ) {}

  async displayGapOnTimeline({ gap }: ISchedulingAlert): Promise<void> {
    if (!gap) {
      return;
    }
    this._gapStore.setSelectedGap(gap);

    await this._stateNav.practice(['schedule', 'timeline'], {
      queryParams: {
        from: toISODate(toMoment(gap.event.from)),
        to: toISODate(toMoment(gap.event.to)),
      },
      queryParamsHandling: 'merge',
    });
  }
}
