import { ICustomMappings } from '@principle-theorem/principle-core/interfaces';
import { BaseCustomMappingsHandler } from '../mappings/base-custom-mappings-handler';
import { AppointmentCategoryToTreatmentCategoryMappingHandler } from './destination/mappings/appointment-category-to-treatment-category';
import { ExactContactMappingHandler } from './destination/mappings/contact-to-referral-source';
import { ExactFeeScheduleMappingHandler } from './destination/mappings/fee-schedules';
import { ExactItemCodeToConditionMappingHandler } from './destination/mappings/item-code-to-condition';
import { ExactItemCodeToNoteMappingHandler } from './destination/mappings/item-code-to-notes';
import { ExactItemCodeToTreatmentMappingHandler } from './destination/mappings/item-code-to-treatment';
import { ExactItemCodeMappingHandler } from './destination/mappings/item-codes';
import { ExactStafferMappingHandler } from './destination/mappings/staff';
import { ExactStaffToUserMappingHandler } from './destination/mappings/staff-to-user';
import { AppointmentRoomToPractionerMappingHandler } from './destination/mappings/appointment-room-to-practitioner';
import { StaffToPracticeMapping } from './destination/mappings/practitioner-to-practice-mapping';

export const EXACT_MAPPINGS: ICustomMappings = {
  metadata: {
    label: 'Exact Custom Mappings',
    description: '',
  },
};

export class ExactCustomMappingsHandler extends BaseCustomMappingsHandler {
  migrationType = 'Exact';
  migrationVersion = 'v1';
  customMapping = EXACT_MAPPINGS;

  mappingHandlers = [
    new ExactItemCodeMappingHandler(),
    new ExactContactMappingHandler(),
    new ExactStaffToUserMappingHandler(),
    new ExactStafferMappingHandler(),
    new ExactItemCodeToTreatmentMappingHandler(),
    new ExactItemCodeToConditionMappingHandler(),
    new ExactFeeScheduleMappingHandler(),
    new AppointmentCategoryToTreatmentCategoryMappingHandler(),
    new ExactItemCodeToNoteMappingHandler(),
    new AppointmentRoomToPractionerMappingHandler(),
    new StaffToPracticeMapping(),
  ];
}
