import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { getEnumValues } from '@principle-theorem/shared';
import { BehaviorSubject } from 'rxjs';

export enum TreatmentPlanViewType {
  TreatmentPlan = 'Treatment Plan',
  AllTreatments = 'All Treatments',
}

@Component({
    selector: 'pr-treatment-plan-view-type-selector',
    templateUrl: './treatment-plan-view-type-selector.component.html',
    styleUrls: ['./treatment-plan-view-type-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreatmentPlanViewTypeSelectorComponent {
  trackByFilter = TrackByFunctions.variable<TreatmentPlanViewType>();
  activeType$ = new BehaviorSubject(TreatmentPlanViewType.TreatmentPlan);
  typeFilters = getEnumValues(TreatmentPlanViewType);
  @Output() typeSelected = new EventEmitter<TreatmentPlanViewType>();

  @Input()
  set activeType(activeType: TreatmentPlanViewType) {
    if (activeType) {
      this.activeType$.next(activeType);
    }
  }
}
