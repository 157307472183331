import { rand, randNumber } from '@ngneat/falso';
import {
  Gender,
  PatientRelationshipType,
  PatientStatus,
  ReferralSourceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  DimensionsCommon,
  IBigQueryReferrer,
  IPatientDimension,
} from '@principle-theorem/reporting/interfaces';
import {
  MockGenerator,
  getEnumValues,
  toSerialisedTimestamp,
  toTimestamp,
  wrapWithSeed,
} from '@principle-theorem/shared';
import { Timestamp } from '@principle-theorem/shared';
import { uniqBy } from 'lodash';
import * as moment from 'moment-timezone';
import {
  BigQueryFirestoreModelMock,
  BigQueryNamedDocumentMock,
  BigQueryNoteMock,
  MockBigQueryNamedDocument,
  MockBigQueryStatusHistory,
} from './common.mock';
import { PatientMock, PATIENTS } from '@principle-theorem/principle-core';
import { AppointmentDimensionMock } from './appointment-dimension.mock';
import {
  stafferDimensionNamedDoc,
  MOCKED_STAFF,
} from './staffer-dimension.mock';

const patient = MockGenerator.generate(PatientMock);

export const MOCKED_REFERRERS: IBigQueryReferrer[] = wrapWithSeed(
  'MockReferrers',
  () =>
    ['Google', 'Facebook', 'Instagram', 'Patient Referrals'].map((name) => {
      return {
        ...MockBigQueryNamedDocument(name, 'referrers'),
        type: ReferralSourceType.ConfiguredReferrer,
      };
    })
);

export const MOCKED_PATIENT_TAGS = wrapWithSeed('MockPatientTags', () =>
  [
    'Influencer',
    'FTA Risk',
    'CDBS',
    'DVA',
    'Anxious',
    'Photo Release Consent',
  ].map((name) => MockBigQueryNamedDocument(name, 'patient-tags'))
);

function MockDateOfBirth(): Timestamp {
  const age = randNumber({ min: 3, max: 90 });
  return toTimestamp(moment().subtract(age, 'years'));
}

function MockPatientTags(): DimensionsCommon.IBigQueryNamedDocument[] {
  const count = randNumber({ min: 0, max: 1 });
  const tags = new Array(count).fill('').map(() => rand(MOCKED_PATIENT_TAGS));
  return uniqBy(tags, (item) => item.ref.referenceValue);
}

const lastAppointment = MockGenerator.generate(AppointmentDimensionMock);
const nextAppointment = MockGenerator.generate(AppointmentDimensionMock);

export class PatientDimensionMock
  extends BigQueryFirestoreModelMock
  implements IPatientDimension
{
  timestamp = toSerialisedTimestamp(toTimestamp());
  name = rand(PATIENTS);
  referrer = rand(MOCKED_REFERRERS);
  relationships = [
    {
      patient: MockGenerator.generate(BigQueryNamedDocumentMock),
      type: PatientRelationshipType.Parent,
    },
  ];
  dateOfBirth = toSerialisedTimestamp(MockDateOfBirth());
  gender = rand([
    Gender.Female,
    Gender.Female,
    Gender.Female,
    Gender.Female,
    Gender.Female,
    Gender.Male,
    Gender.Male,
    Gender.Male,
    Gender.Male,
    Gender.Male,
    ...getEnumValues(Gender),
  ]);
  status = rand([
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    PatientStatus.Active,
    ...getEnumValues(PatientStatus),
  ]);
  statusHistory = patient.statusHistory.map((item) =>
    MockBigQueryStatusHistory(item.status, item.updatedAt)
  );
  preferredDentist = stafferDimensionNamedDoc(rand(MOCKED_STAFF));
  preferredHygienist = stafferDimensionNamedDoc(rand(MOCKED_STAFF));
  preferredPractice = MockGenerator.generate(BigQueryNamedDocumentMock);
  preferredFeeSchedule = MockGenerator.generate(BigQueryNamedDocumentMock);
  tags = MockPatientTags();
  notes = [
    MockGenerator.generate(BigQueryNoteMock),
    MockGenerator.generate(BigQueryNoteMock),
  ];
  coordinates = {
    latitude: 123,
    longitude: 321,
  };
  lastVisit = {
    from: lastAppointment.event.from,
    to: lastAppointment.event.to,
    status: lastAppointment.status,
    practitioner: lastAppointment.practitioner,
    treatmentPlan: lastAppointment.treatmentPlan,
    tags: lastAppointment.tags,
    ref: lastAppointment.ref,
  };
  nextVisit = {
    from: nextAppointment.event.from,
    to: nextAppointment.event.to,
    status: nextAppointment.status,
    practitioner: nextAppointment.practitioner,
    treatmentPlan: nextAppointment.treatmentPlan,
    tags: nextAppointment.tags,
    ref: nextAppointment.ref,
  };
  profileImageURL = patient.profileImageURL;
  medicareCard = patient.medicareCard
    ? {
        number: patient.medicareCard.number,
        subNumerate: patient.medicareCard.subNumerate,
        expiryDate: patient.medicareCard.expiryDate
          ? toSerialisedTimestamp(patient.medicareCard.expiryDate)
          : undefined,
      }
    : undefined;
  healthFundCard = patient.healthFundCard;
  dvaCard = patient.dvaCard
    ? {
        number: patient.dvaCard.number,
        expiryDate: patient.dvaCard.expiryDate
          ? toSerialisedTimestamp(patient.dvaCard.expiryDate)
          : undefined,
      }
    : undefined;
  referenceId = patient.referenceId;
  email = patient.email;
  address = patient.address;
  contactNumbers = patient.contactNumbers;
  mobileNumber = `+614${randNumber({ min: 10000000, max: 99999999 })}`;
  accountSummary = {
    creditSummary: {
      freeCredit: 0,
      depositsPaid: randNumber({ min: 0, max: 1000 }),
      creditTotal: randNumber({ min: 0, max: 1000 }),
      creditUsed: randNumber({ min: 0, max: 1000 }),
      creditRemaining: randNumber({ min: 0, max: 1000 }),
    },
    invoiceSummary: {
      treatments: randNumber({ min: 0, max: 1000 }),
      products: randNumber({ min: 0, max: 1000 }),
      fees: randNumber({ min: 0, max: 1000 }),
      subtotal: randNumber({ min: 0, max: 1000 }),
      deposits: randNumber({ min: 0, max: 1000 }),
      totalInvoiced: randNumber({ min: 0, max: 1000 }),
    },
    paymentSummary: {
      discounts: randNumber({ min: 0, max: 1000 }),
      writtenOff: randNumber({ min: 0, max: 1000 }),
      creditUsed: randNumber({ min: 0, max: 1000 }),
      totalReceivable: randNumber({ min: 0, max: 1000 }),
      paymentsReceived: randNumber({ min: 0, max: 1000 }),
      outstanding: randNumber({ min: 0, max: 1000 }),
    },
  };
}
