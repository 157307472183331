import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnDestroy,
  inject,
} from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import {
  AnyCustomFormElement,
  CustomFormElementCategory,
  isCustomFormElementType,
} from '@principle-theorem/principle-core/interfaces';
import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { FormBuilderElementComponent } from '../form-builder-element/form-builder-element.component';
import { FormBuilderService } from '../form-builder.service';
import { CustomFormElementBlueprint } from '../lib/custom-form-element-blueprints';
import { FormBuilderDragDrop } from '../lib/form-builder-drag-drop';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
    selector: 'pr-form-builder-layout',
    templateUrl: './form-builder-layout.component.html',
    styleUrl: './form-builder-layout.component.scss',
    exportAs: 'prFormBuilderLayout',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        NgMaterialModule,
        NgSharedModule,
        FormBuilderElementComponent,
    ]
})
export class FormBuilderLayoutComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  formBuilder = inject(FormBuilderService);
  readonly rootDropListId = `${uuid()}-layout`;
  readonly allowOnlySections = FormBuilderDragDrop.onlyAllowCategoryFn(
    CustomFormElementCategory.Section
  );

  constructor() {
    this.formBuilder.registerDropLists([this.rootDropListId]);
  }

  ngOnDestroy(): void {
    this.formBuilder.unregisterDropLists([this.rootDropListId]);
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @Input()
  set layout(layout: AnyCustomFormElement[]) {
    this.formBuilder.setLayout(layout);
  }

  @Input({ transform: coerceBooleanProperty })
  set editAll(editAll: boolean) {
    this.formBuilder.setEditAll(editAll);
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscapePressed(_event: KeyboardEvent): void {
    this.formBuilder.selectElement(undefined);
  }

  dragDrop(
    event: CdkDragDrop<
      AnyCustomFormElement[],
      AnyCustomFormElement[],
      AnyCustomFormElement
    >
  ): void {
    if (isCustomFormElementType(event.item.data)) {
      const item = CustomFormElementBlueprint.create(event.item.data);
      if (!item) {
        // eslint-disable-next-line no-console
        console.warn(`Could not create element of type: ${event.item.data}`);
        return;
      }
      this.formBuilder.addElement({
        parentUid: event.container.id,
        index: event.currentIndex,
        item,
      });
      return;
    }
    this.formBuilder.moveElement({
      previousContainerId: event.previousContainer.id,
      previousContainerChildren: event.previousContainer.data,
      previousIndex: event.previousIndex,
      currentContainerId: event.container.id,
      currentContainerChildren: event.container.data,
      currentIndex: event.currentIndex,
    });
  }
}
