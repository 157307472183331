import { MixedSchema } from '@principle-theorem/editor';
import {
  type DocumentReference,
  type INamedDocument,
  type Timestamp,
} from '@principle-theorem/shared';
import { type IStatusHistory } from '../common';
import { type IStaffer } from '../staffer/staffer';
import { type ITeam } from '../team/team';
import { type IWorkflowItem } from '../workflow-item/workflow-item';
import { type IRecurringTaskConfiguration } from './recurring-task';

export enum TaskPriority {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum TaskAssigneeType {
  User = 'user',
  Team = 'team',
}

export enum TaskAction {
  Reopened = 'reopened',
  Completed = 'completed',
}

export enum TaskStatus {
  Open = 'open',
  Complete = 'complete',
}

export enum TaskType {
  Misc = 'Misc',
  FollowUp = 'Follow up',
  RebookAppointment = 'Rebook Appointment',
  LabJob = 'Lab Job',
  Recall = 'Recall',
  System = 'System',
  Gap = 'Gap',
}

// TODO: are these even applicable anymore?
export const TASK_TYPES: TaskType[] = [
  TaskType.Misc,
  TaskType.FollowUp,
  TaskType.Gap,
  TaskType.LabJob,
  TaskType.RebookAppointment,
  TaskType.Recall,
  TaskType.System,
];

export interface ITask extends IWorkflowItem {
  description?: MixedSchema;
  priority: TaskPriority;
  status: TaskStatus;
  statusHistory: IStatusHistory<TaskStatus>[];
  type: TaskType;
  recurrenceConfiguration?: DocumentReference<IRecurringTaskConfiguration>;
  assignedUser?: INamedDocument<IStaffer>;
  assignedTeam?: INamedDocument<ITeam>;
  owner?: INamedDocument<IStaffer>;
  visibleFrom: Timestamp;
  mentionRefs: DocumentReference<unknown>[];
}

export enum TaskFrequency {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  Custom = 'Custom',
}

export const TASK_PRIORITIES: TaskPriority[] = [
  TaskPriority.Low,
  TaskPriority.Medium,
  TaskPriority.High,
];

export const TASK_STATUSES: TaskStatus[] = [
  TaskStatus.Open,
  TaskStatus.Complete,
];

export const TASK_FREQUENCIES: TaskFrequency[] = [
  TaskFrequency.Daily,
  TaskFrequency.Weekly,
  TaskFrequency.Monthly,
  TaskFrequency.Yearly,
  TaskFrequency.Custom,
];

export interface IAssigneeGroup<T> {
  label: string;
  assignees: T[];
}

// TODO: https://app.clickup.com/t/24ub3q
export const taskPriorityClassMap: { [key in TaskPriority]: string } = {
  [TaskPriority.Low]: 'low-priority',
  [TaskPriority.Medium]: 'medium-priority',
  [TaskPriority.High]: 'high-priority',
};

export const TASK_PRIORITY_COLOUR_MAP: { [key in TaskPriority]: string } = {
  [TaskPriority.Low]: 'rgba(0, 0, 0, 0)',
  [TaskPriority.Medium]: 'rgba(255, 186, 0, 0.8)',
  [TaskPriority.High]: 'rgba(255, 0, 0, 0.8)',
};
