import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  initRawSchema,
  initVersionedSchema,
  mergeSchemas,
  type RawSchema,
  toListContent,
  toMentionContent,
  toTextContent,
  type VersionedSchema,
} from '@principle-theorem/editor';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  getMentionContactDetails,
  Interaction,
  stafferToNamedDoc,
  toMention,
  addInteractions,
} from '@principle-theorem/principle-core';
import {
  type IInteractionV2,
  type IInteractionData,
  InteractionType,
  type IPrincipleMention,
  type IStaffer,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first } from 'lodash';
import { from, type Observable } from 'rxjs';

interface ICommonNote {
  label: string;
  selected: boolean;
}

@Component({
  selector: 'pr-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneComponent {
  trackByNote = TrackByFunctions.label<ICommonNote>();
  phone$: Observable<string | undefined>;
  noteControl: TypedFormControl<VersionedSchema> =
    new TypedFormControl<VersionedSchema>(initVersionedSchema());
  commonNotes: ICommonNote[] = [
    {
      label: 'Left message',
      selected: false,
    },
    {
      label: 'No answer',
      selected: false,
    },
    {
      label: 'Not available',
      selected: false,
    },
    {
      label: 'Will return our call',
      selected: false,
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IInteractionData,
    private _organisation: OrganisationService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<PhoneComponent>
  ) {
    this.phone$ = from(this.getMentionPhone(this.data.contact));
  }

  isCommonNoteSelected(): boolean {
    return this.commonNotes.some((note: ICommonNote) => note.selected);
  }

  async submit(): Promise<void> {
    const notes = this._getContactNotes();
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );

    const interaction = this._getPhoneInteraction(staffer, notes);
    await addInteractions(this.data, interaction);

    this._snackBar.open('Call Notes Added');
    this.dialogRef.close();
  }

  async getMentionPhone(
    contact: IPrincipleMention
  ): Promise<string | undefined> {
    const details = await getMentionContactDetails(contact);
    const number = first(details?.contactNumbers);
    return number?.number;
  }

  private _getContactNotes(): RawSchema | undefined {
    const prefilledNotes: string[] = [];
    this.commonNotes.map((note) => {
      if (note.selected) {
        prefilledNotes.push(note.label);
      }
    });
    if (!prefilledNotes) {
      return;
    }
    return initRawSchema([toListContent(prefilledNotes)]);
  }

  private _getPhoneInteraction(
    staffer: WithRef<IStaffer>,
    notes?: RawSchema
  ): IInteractionV2 {
    return Interaction.init({
      type: InteractionType.Call,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` called `),
        toMentionContent(this.data.contact),
      ],
      owner: stafferToNamedDoc(staffer),
      content: initVersionedSchema(
        mergeSchemas(compact([notes, this.noteControl.value]))
      ),
    });
  }
}
