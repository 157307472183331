<div class="layout-padding practitioner-income-spacer">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h2>Select a referral source</h2>
    <pt-download-report
      [data]="summaries$ | async"
      [dateRange]="dateRange$ | async"
      [csvTranslator]="csvTranslator"
    />
  </div>
</div>

<table
  mat-table
  [dataSource]="dataSource"
  matSort
  matSortActive="referralSource"
  matSortDirection="asc"
>
  <ng-container matColumnDef="referralSource">
    <th mat-header-cell *matHeaderCellDef>Referral Source</th>
    <td mat-cell *matCellDef="let record">
      <a href="javascript:void(0);" (click)="store.selectSummary(record)">
        {{ record.referralSource }}
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="patientCount">
    <th mat-header-cell *matHeaderCellDef>Number Of Patients</th>
    <td mat-cell *matCellDef="let record">
      {{ record.records.length }}
    </td>
  </ng-container>

  <ng-container matColumnDef="treatmentTotal">
    <th mat-header-cell *matHeaderCellDef>Treatment Total</th>
    <td mat-cell *matCellDef="let record">
      {{ record.treatmentTotal | currency }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
