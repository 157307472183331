<mat-toolbar color="accent">{{ data.title }}</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column" fxLayoutGap="16px">
    <mat-form-field fxFlex>
      <mat-label>Amount</mat-label>
      <span matTextPrefix>$&nbsp;</span>
      <input
        matInput
        type="number"
        formControlName="amount"
        [min]="0"
        [max]="maxAmount$ | async"
        [required]="true"
      />
      <mat-hint align="end"
        >Maximum {{ maxAmount$ | async | currency }}</mat-hint
      >
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>Description</mat-label>
      <input matInput formControlName="description" />
    </mat-form-field>

    <ng-container formGroupName="extendedData">
      <mat-form-field fxFlex>
        <mat-label>Practitioner</mat-label>
        <mat-select
          formControlName="practitionerRef"
          [compareWith]="comparePractitionerRefFn"
        >
          <mat-option
            *ngFor="
              let practitioner of data.invoicePractitioners;
              trackBy: trackByPractitionerRef
            "
            [value]="practitioner.practitioner.ref"
          >
            {{ practitioner.practitioner.name }}
          </mat-option>
        </mat-select>
        <mat-hint>Limit the discount to a single practitioner</mat-hint>
      </mat-form-field>
    </ng-container>

    <mat-form-field fxFlex *prHasPermission="[accountAdminPermission]">
      <mat-label>Date Received</mat-label>
      <input
        matInput
        formControlName="dateReceived"
        [matDatepicker]="dateReceived"
        [min]="amendDateStore.minDate$ | async"
        [max]="amendDateStore.maxDate$ | async"
        (focus)="dateReceived.open()"
        (click)="dateReceived.open()"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="dateReceived"
       />
      <mat-datepicker #dateReceived />
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" [disabled]="form.invalid">
        Submit
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
