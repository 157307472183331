<mat-toolbar color="accent">Edit Opening Hours</mat-toolbar>

<mat-dialog-content>
  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="16px">
    <pr-date-selector
      fxFlex
      placeholder="Open Time"
      [required]="true"
      [interval]="timeInterval"
      [formControl]="form.controls.from"
     />

    <pr-date-selector
      fxFlex
      placeholder="Close Time"
      [required]="true"
      [interval]="timeInterval"
      [formControl]="form.controls.to"
     />
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button (click)="dialogRef.close()">Close</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="form.invalid"
      (click)="save()"
    >
      Save
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
