import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  getSchemaSize,
  type ISnippet,
  Snippet,
} from '@principle-theorem/editor';
import { type IStaffer } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SnippetFormGroup } from './snippet-form.formgroup';

@Component({
  selector: 'pr-snippet-form',
  templateUrl: './snippet-form.component.html',
  styleUrls: ['./snippet-form.component.scss'],
})
export class SnippetFormComponent {
  snippetFormGroup: SnippetFormGroup = new SnippetFormGroup();
  staffer: WithRef<IStaffer>;
  snippet: WithRef<ISnippet>;
  formDisabled$: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ISnippetFormData,
    private _dialogRef: MatDialogRef<SnippetFormComponent, ISnippet>
  ) {
    this.staffer = data.staffer;

    if (data.snippet) {
      this.snippet = data.snippet;
      this.snippetFormGroup.patchValue(this.snippet, { emitEvent: false });
    }

    this.formDisabled$ = this.snippetFormGroup.controls.body.valueChanges.pipe(
      map((body) => (body ? getSchemaSize(body) === 0 : true))
    );
  }

  save(): void {
    if (!this.snippetFormGroup.valid) {
      return;
    }

    this._dialogRef.close(Snippet.init(this.snippetFormGroup.getRawValue()));
  }
}

export interface ISnippetFormData {
  staffer: WithRef<IStaffer>;
  snippet?: WithRef<ISnippet>;
}
