import {
  AccountCreditType,
  CLAIM_PROVIDERS,
  IAccountCredit,
  IAccountCreditExtendedData,
  ICustomLineItem,
  IInvoice,
  IInvoiceCancellation,
  InvoiceStatus,
  IPractice,
  IStaffer,
  ITransaction,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  INamedDocument,
  saveDoc,
  sortByCreatedAt,
  toTimestamp,
  WithRef,
} from '@principle-theorem/shared';
import { uniq } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountCredit } from '../account-credit/account-credit';
import { Transaction } from '../transaction/transaction';
import { TransactionOperators } from '../transaction/transaction-operators';
import { Invoice } from './invoice';
import { InvoiceInteractionBuilder } from './invoice-interaction-builder';
import { RefundHelpers } from './refund-transactions';

export async function cancelInvoice(
  invoice: WithRef<IInvoice>,
  cancellation: IInvoiceCancellation,
  staffer: WithRef<IStaffer>
): Promise<void> {
  const cancelled: WithRef<IInvoice> = {
    ...Invoice.cancelInvoice(invoice),
    status: InvoiceStatus.Cancelled,
    cancelledAt: toTimestamp(),
    cancellation,
  };
  await saveDoc(cancelled);
  const interaction = InvoiceInteractionBuilder.cancelledInteraction(
    cancellation,
    staffer
  );
  await Invoice.addInteraction(invoice, interaction);
}

export function getAccountCreditFromTransactions(
  invoice: WithRef<IInvoice>,
  transactions: WithRef<ITransaction>[]
): IAccountCredit | undefined {
  const amount = RefundHelpers.getTotalPaid(transactions);
  if (!amount) {
    return;
  }

  return AccountCredit.init({
    description: `${invoice.reference} Refund`,
    type: AccountCreditType.Refund,
    amount,
    reservedFor: {
      treatment: getCreditTreatmentsFromLineItems(invoice.items),
    },
    practiceRef: invoice.practice.ref,
  });
}

function getCreditTreatmentsFromLineItems(
  _lineItems: ICustomLineItem[]
): INamedDocument[] {
  // TODO: Implement this. At the moment we don't have a way to reference treatments
  // const _treatments = lineItems.filter(isTreatmentLineItem).map((item) => item);
  return [];
}

export function getAccountCreditTransaction(
  credit: IAccountCredit,
  to: string,
  from: string,
  practiceRef: DocumentReference<IPractice>
): ITransaction<IAccountCreditExtendedData> {
  return Transaction.init({
    ...Transaction.internalReference(TransactionProvider.AccountCredit),
    type: TransactionType.Outgoing,
    status: TransactionStatus.Complete,
    from,
    to,
    amount: credit.amount,
    practiceRef,
    extendedData: {
      accountCreditsUsed: [],
    },
  });
}

export function hasTransactionsThatNeedRefunding$(
  invoice: WithRef<IInvoice>
): Observable<boolean> {
  return Invoice.transactions$(invoice).pipe(
    map((transactions) => {
      const references = uniq(
        transactions.map((transaction) => transaction.reference)
      );
      const completedPayments = references
        .map((reference) => findCompletedPayment(transactions, reference))
        .filter(
          (transaction): transaction is ITransaction =>
            transaction !== undefined
        );
      const requiringRefund = completedPayments
        .map((transaction) => {
          return {
            transaction,
            refundRemaining: getRefundRemaining(transaction, transactions),
            requiresRefundForCancel: requiresRefundForCancel(transaction),
          };
        })
        .filter(
          (item) => item.requiresRefundForCancel && item.refundRemaining > 0
        );
      return requiringRefund.length > 0;
    })
  );
}

function findCompletedPayment(
  transactions: ITransaction[],
  reference: string
): ITransaction | undefined {
  return new TransactionOperators(transactions)
    .byReference(reference)
    .incoming()
    .completed()
    .sort(sortByCreatedAt)
    .last();
}

export function amountClaimed(transactions: ITransaction[]): number {
  const allCompleted = new TransactionOperators(transactions).byProvider(
    CLAIM_PROVIDERS
  );
  const allAmount =
    allCompleted.incoming().sum() -
    allCompleted.outgoing().sum() -
    allCompleted.claim().sum();
  return allAmount;
}

export function getRefundRemaining(
  item: ITransaction,
  transactions: ITransaction[]
): number {
  const referenceCompleted = new TransactionOperators(transactions)
    .byReference(item.reference)
    .completed();
  const referenceAmount =
    referenceCompleted.incoming().sum() - referenceCompleted.outgoing().sum();

  const allAmount = RefundHelpers.getTotalPaid(transactions);
  if (referenceAmount < allAmount) {
    return referenceAmount;
  }

  return allAmount;
}

function requiresRefundForCancel(transaction: ITransaction): boolean {
  const providersThatRequireRefunds: string[] = [
    TransactionProvider.TyroEftpos,
    TransactionProvider.TyroHealthPoint,
    TransactionProvider.TyroEasyClaimBulkBill,
    TransactionProvider.TyroEasyClaimPartPaid,
    TransactionProvider.TyroEasyClaimFullyPaid,
    TransactionProvider.AccountCredit,
    TransactionProvider.Discount,
    TransactionProvider.Manual,
    TransactionProvider.Cash,
    TransactionProvider.MedipassHicaps,
    TransactionProvider.MedipassMedicare,
    TransactionProvider.MedipassGapPayment,
    TransactionProvider.MedipassPatientFunded,
    TransactionProvider.MedipassVirtualTerminal,
    TransactionProvider.HicapsConnectEftpos,
    TransactionProvider.HicapsConnectHealthFund,
    TransactionProvider.HicapsConnectMedicare,
  ];
  return providersThatRequireRefunds.includes(transaction.provider);
}
