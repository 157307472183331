import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { StorageModule } from '@ngx-pwa/local-storage';
import { NgAuthModule } from '@principle-theorem/ng-auth';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { AuthComponentsModule } from '../../components/auth/auth-components.module';
import { ComponentsModule } from '../../components/components.module';
import { CoreModule } from '../../core/core.module';
import { AccountActionsComponent } from './account-actions/account-actions.component';
import { AuthPageComponent } from './auth-page/auth-page.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';
import { UserActivateComponent } from './user-activate/user-activate.component';
import { WorkspacesComponent } from './workspaces/workspaces.component';
import { NgFirebaseModule } from '@principle-theorem/ng-firebase';
import { RegisterPageComponent } from './register-page/register-page.component';
import { UserRestrictedComponent } from './user-restricted/user-restricted.component';

@NgModule({
  declarations: [
    AccountActionsComponent,
    AuthPageComponent,
    SignUpComponent,
    UnauthorisedComponent,
    UserActivateComponent,
    WorkspacesComponent,
    RegisterPageComponent,
    UserRestrictedComponent,
  ],
  imports: [
    CommonModule,
    NgAuthModule,
    CoreModule,
    ComponentsModule,
    AuthComponentsModule,
    NgFirebaseModule,
    StorageModule,
    NgSharedModule,
    NgAuthModule,
    ReactiveFormsModule,
  ],
})
export class AuthPagesModule {}
