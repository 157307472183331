import { asyncForEach } from '@principle-theorem/shared';
import { uniq } from 'lodash';
import { IChartConfig } from '../chart-config';
import { IReportingQuery } from '../querying';
import { IMeasureBuilderData } from './measure-properties';

export interface IDataBuilder<T = unknown> {
  build(query: IReportingQuery): Promise<T[]>;
}

export interface IMeasureQuery {
  config: IChartConfig;
  measure: IMeasureBuilderData;
  query: IReportingQuery;
}

export function getMeasureQueries(config: IChartConfig): IMeasureQuery[] {
  return config.builderData.measures.map((measure) => {
    if (config.builderData.groupByDimension) {
      measure.measure.groupBy(config.builderData.groupByDimension);
    }
    return {
      config,
      measure,
      query: getMeasureQuery(config, measure.measure.measure.query),
    };
  });
}

function getMeasureQuery(
  _config: IChartConfig,
  query: IReportingQuery
): IReportingQuery {
  return {
    ...query,
    attributes: uniq(query.attributes),
  };
}

export interface IMeasureResult<T = unknown> {
  config: IChartConfig;
  measure: IMeasureBuilderData;
  data: T[];
}

export async function getMeasureResults(
  config: IChartConfig,
  dataBuilder: IDataBuilder
): Promise<IMeasureResult[]> {
  return asyncForEach(getMeasureQueries(config), async (measureQuery) => ({
    config: measureQuery.config,
    measure: measureQuery.measure,
    data: await dataBuilder.build(measureQuery.query),
  }));
}
