import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { SterilisationRecordDialogComponent } from './sterilisation-record-dialog/sterilisation-record-dialog.component';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgFirebaseModule } from '@principle-theorem/ng-firebase';
import { PackContentsEditorComponent } from './pack-contents-editor/pack-contents-editor.component';

@NgModule({
  imports: [
    CommonModule,
    NgMaterialModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    NgInteractionsModule,
    NgFirebaseModule,
    PackContentsEditorComponent,
  ],
  declarations: [SterilisationRecordDialogComponent],
})
export class ComponentsModule {}
