import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  type IReconciliationReportItem,
  type ITransactionSourceSummary,
  ReportType,
} from '@principle-theorem/reporting';
import { ReconciliationReportFacade } from '../../store/reconciliation-report.facade';
import { MatTableDataSource } from '@angular/material/table';
import { ReplaySubject } from 'rxjs';
import { startCase } from 'lodash';

@Component({
    selector: 'pr-reconciliation-transactions-summary',
    templateUrl: './reconciliation-transactions-summary.component.html',
    styleUrls: ['./reconciliation-transactions-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ReconciliationTransactionsSummaryComponent {
  dataSource: MatTableDataSource<ITransactionSourceSummary>;
  displayedColumns = ['name', 'pending', 'complete'];
  summary$ = new ReplaySubject<IReconciliationReportItem | undefined>(1);
  totalType = startCase(ReportType.Total);

  @Input()
  set summary(summary: IReconciliationReportItem) {
    if (summary) {
      this.summary$.next(summary);
      this.dataSource = new MatTableDataSource<ITransactionSourceSummary>(
        summary.sources
      );
    }
  }

  constructor(public reportFacade: ReconciliationReportFacade) {}

  colourClass(value: number): Record<string, boolean> {
    return {
      negative: value < 0,
    };
  }
}
