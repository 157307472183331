import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FirebaseTable } from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  ObservableDataTable,
  TrackByFunctions,
  extendSortingDataAccessor,
} from '@principle-theorem/ng-shared';
import { Tag } from '@principle-theorem/principle-core';
import { type ITag } from '@principle-theorem/principle-core/interfaces';
import { snapshot, type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  EditTagDialogComponent,
  type IEditTagDialogData,
} from '../edit-tag-dialog/edit-tag-dialog.component';

@Component({
    selector: 'pr-tag-list',
    templateUrl: './tag-list.component.html',
    styleUrls: ['./tag-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TagListComponent
  extends FirebaseTable<ITag>
  implements AfterViewInit
{
  private _tags$ = new BehaviorSubject<WithRef<ITag>[]>([]);
  override displayColumns: string[] = ['name'];
  override pageSize = 25;
  trackByTag = TrackByFunctions.ref<WithRef<ITag>>();
  emptyState$: Observable<boolean>;
  emptySearch$ = new BehaviorSubject<boolean>(false);
  loading$ = new BehaviorSubject<boolean>(true);
  dataTable: ObservableDataTable<WithRef<ITag>>;
  @Output() tagAdded: EventEmitter<ITag> = new EventEmitter<ITag>();

  @ViewChild(MatPaginator)
  set tablePaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @Input()
  set tags(tags: WithRef<ITag>[]) {
    if (tags) {
      this._tags$.next(tags.filter((tag) => !tag.deleted));
      this.loading$.next(false);
    }
  }

  constructor(private _dialog: MatDialog) {
    super();
    this.dataSource.items$ = this._tags$;

    this.emptyState$ = combineLatest([this._tags$, this.loading$]).pipe(
      map(([tags, loading]) => !tags.length && !loading)
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.filterPredicate = (data: ITag, filter: string) => {
      return this._filterPredicate(data, filter);
    };
    this.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, sortHeaderId) => this._sortingDataAccessor(data, sortHeaderId)
    );
  }

  async searchTags(value: string): Promise<void> {
    this.applyFilter(value);
    const emptyState = await snapshot(this.emptyState$);
    !emptyState && !this.dataSource.filteredData.length
      ? this.emptySearch$.next(true)
      : this.emptySearch$.next(false);
  }

  async add(): Promise<void> {
    const config: MatDialogConfig<IEditTagDialogData> = {
      data: { title: 'Add Tag' },
    };

    const result: Partial<ITag> | undefined = await this._dialog
      .open<
        EditTagDialogComponent,
        IEditTagDialogData,
        Partial<ITag> | undefined
      >(EditTagDialogComponent, DialogPresets.small<IEditTagDialogData>(config))
      .afterClosed()
      .toPromise();

    if (result) {
      const tag: ITag = Tag.init(result);
      this.tagAdded.next(tag);
    }
  }

  private _filterPredicate(data: ITag, filter: string): boolean {
    return data.name.toLowerCase().includes(filter);
  }

  private _sortingDataAccessor(
    tag: ITag,
    sortHeaderId: string
  ): string | undefined {
    switch (sortHeaderId) {
      case 'name':
        return tag.name.toLowerCase();
      default:
        return;
    }
  }
}
