import { Validators } from '@angular/forms';
import {
  initVersionedSchema,
  type VersionedSchema,
} from '@principle-theorem/editor';
import { IFollowUpFormData } from '@principle-theorem/ng-follow-ups';
import { IReasonSelectorValue } from '@principle-theorem/ng-principle-shared';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import * as moment from 'moment-timezone';

export interface ICancelAppointmentFormData extends IFollowUpFormData {
  reason: IReasonSelectorValue;
  comments: VersionedSchema;
}

export class CancelAppointmentFormGroup extends TypedFormGroup<ICancelAppointmentFormData> {
  constructor() {
    super({
      createFollowUp: new TypedFormControl<boolean>(true),
      followUpDate: new TypedFormControl<moment.Moment>(
        undefined,
        Validators.required
      ).withGuard(moment.isMoment),
      noFollowUpReason: new TypedFormControl<VersionedSchema>(
        initVersionedSchema()
      ),
      reason: new TypedFormControl<IReasonSelectorValue>(
        undefined,
        Validators.required
      ),
      comments: new TypedFormControl<VersionedSchema>(initVersionedSchema()),
    });
  }
}
