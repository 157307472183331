import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StateBasedNavigationService } from '@principle-theorem/ng-principle-shared';
import {
  type IReferralSource,
  isConfiguredReferrer,
  isContactReferrer,
  isPatientReferrer,
} from '@principle-theorem/principle-core/interfaces';
import { getDoc } from '@principle-theorem/shared';
import { from, type Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-patient-referrer',
    templateUrl: './patient-referrer.component.html',
    styleUrls: ['./patient-referrer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientReferrerComponent {
  referrer$ = new ReplaySubject<IReferralSource | undefined>(1);
  linkableReferrer$: Observable<string[] | undefined>;
  defaultReferrer$: Observable<string | undefined>;

  @Input()
  set referrer(referrer: IReferralSource | undefined) {
    this.referrer$.next(referrer);
  }

  constructor(private _stateNavigation: StateBasedNavigationService) {
    this.linkableReferrer$ = this.referrer$.pipe(
      switchMap((referrer) => {
        if (!referrer) {
          return of(undefined);
        }
        if (isPatientReferrer(referrer)) {
          return this._stateNavigation.link.brand$([
            'patients',
            referrer.ref.id,
          ]);
        }
        if (isContactReferrer(referrer)) {
          return this._stateNavigation.link.practice$([
            'contacts',
            referrer.ref.id,
          ]);
        }
        return of(undefined);
      })
    );
    this.defaultReferrer$ = this.referrer$.pipe(
      switchMap((referrer) => {
        if (
          !referrer ||
          isPatientReferrer(referrer) ||
          isContactReferrer(referrer)
        ) {
          return of(undefined);
        }
        if (isConfiguredReferrer(referrer)) {
          return from(getDoc(referrer.ref)).pipe(map(({ name }) => name));
        }
        return of(referrer.name);
      })
    );
  }
}
