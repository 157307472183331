import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { AbstractResolver } from '@principle-theorem/ng-shared';
import { Brand } from '@principle-theorem/principle-core';
import { type ICustomReport } from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  getDoc$,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class CustomReportResolverService extends AbstractResolver<
  WithRef<ICustomReport>
> {
  constructor(router: Router, private _organisation: OrganisationService) {
    super(router);
  }

  getItemById(uid: string): Observable<WithRef<ICustomReport>> {
    return this._organisation.brand$.pipe(
      filterUndefined(),
      switchMap((brand) => getDoc$(Brand.customReportCol(brand), uid))
    );
  }
}
