import { toTextContent } from '@principle-theorem/editor';
import {
  IInteraction,
  InteractionType,
  IStaffer,
  IWorkflowItem,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_FORMAT,
  saveDoc,
  toMoment,
  toTimestamp,
  WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { InteractionProperty } from '../interaction/interaction-title-builder';
import { InteractionTransaction } from '../interaction/interaction-transaction';

export abstract class WorkflowItemUpdater<T extends WithRef<IWorkflowItem>> {
  protected _transaction: InteractionTransaction = new InteractionTransaction();

  constructor(protected _item: T) {}

  /**
   * Check if there are any updates to be applied
   */
  hasUpdates(): boolean {
    return this._transaction.hasUpdates();
  }

  /**
   * Save updates tracking them under the given staffer
   */
  async save(owner: WithRef<IStaffer>): Promise<void> {
    const interactions: IInteraction[] =
      this._transaction.getInteractions(owner);
    this._item.interactions.push(...interactions);

    await this._transaction.commit();
    await saveDoc(this._item);

    this._transaction.reset();
  }

  /**
   * Update task due date & add note
   */
  updateDueDate(date?: moment.Moment): this {
    const interactionType: InteractionType = InteractionType.TaskEditDue;

    if (!date) {
      if (this._item.dueDate) {
        this._transaction.add(interactionType, [
          toTextContent(`removed due date`),
        ]);
      }
      this._item.dueDate = undefined;
      return this;
    }

    if (
      this._item.dueDate &&
      toMoment(this._item.dueDate).isSame(date, 'day')
    ) {
      return this;
    }

    // TODO: Build with timezone - CU-251edxw
    this._item.dueDate = toTimestamp(date.endOf('day'));
    this._transaction.add(interactionType, [
      toTextContent(`updated due date to `),
      new InteractionProperty(date.format(DATE_FORMAT)).toNodeSchema(),
    ]);
    return this;
  }
}
