import { NgModule } from '@angular/core';
import { BridgeCommandsService } from './bridge-commands.service';
import { ComponentsModule } from './components/components.module';

@NgModule({
  imports: [ComponentsModule],
  exports: [ComponentsModule],
  providers: [BridgeCommandsService],
})
export class NgPrincipleBridgeCloudModule {}
