<mat-toolbar color="accent">Account Credit</mat-toolbar>

<form [formGroup]="form" (submit)="submit()">
  <mat-dialog-content>
    <div *ngIf="!availableCredits.length" class="layout-padding no-credits">
      This user has no account credits to spend.
    </div>

    <div fxLayout="column" fxLayoutAlign="16px">
      <mat-form-field>
        <mat-label>Enter Amount to charge</mat-label>
        <span matTextPrefix>$</span>
        <input
          matInput
          type="number"
          formControlName="amount"
          min="0"
          [max]="limit$ | async"
        />
        <mat-hint
          >Available: {{ available$ | async | currency }}
          <ng-container *ngIf="isLimitedByInvoiceRemaining$ | async"
            >(Invoice remaining: {{ limit$ | async | currency }})</ng-container
          >
        </mat-hint>
      </mat-form-field>

      @if (showAttributedTo) {
        <pr-attributed-to-selector
          *prHasPermission="[accountAdminPermission]"
          class="mt-4 flex grow"
          formControlName="attributedTo"
          [staff]="staff"
        />
      }

      <mat-selection-list
        formControlName="accountCredits"
        [compareWith]="compareFn"
      >
        <div mat-subheader>
          Available Account Credits
          <mat-icon [matTooltip]="automationListMessage">info</mat-icon>
        </div>
        <mat-list-option
          *ngFor="let credit of availableCredits; trackBy: trackByAccountCredit"
          checkboxPosition="before"
          [value]="credit"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="16px"
          >
            <div fxLayout="column" fxLayoutAlign="start start">
              <span class="description">
                {{ credit.description }}
              </span>

              @if (
                credit | map: resolveReservedDetails$ : this | async;
                as reserved
              ) {
                <div class="flex flex-row text-xs opacity-50">
                  Reserved for:
                  {{ reserved.practitioner?.name }}
                  @if (reserved.practitioner && reserved.treatmentCategory) {
                    &amp;
                  }
                  @if (reserved.treatmentCategory) {
                    {{ reserved.treatmentCategory.name }}
                    <div
                      class="inline-block h-3 w-3 rounded-full"
                      [matTooltip]="reserved.treatmentCategory.name"
                      [style.backgroundColor]="
                        reserved.treatmentCategory.colour.value
                      "
                    ></div>
                  }
                </div>
              }
            </div>

            <div class="amount-remaining">
              {{ credit | map: creditRemaining | currency }}
            </div>
          </div>
        </mat-list-option>
      </mat-selection-list>

      <ng-container *ngIf="practiceOptions.options$ | async as practices">
        <mat-form-field *ngIf="practices.length > 1">
          <mat-label>Practice</mat-label>
          <mat-select
            [required]="true"
            formControlName="practiceRef"
            [compareWith]="practiceOptions.compareFn"
          >
            <mat-option
              *ngFor="
                let practice of practices;
                trackBy: practiceOptions.trackByFn
              "
              [value]="practice.ref"
            >
              {{ practice.name }}
            </mat-option>
          </mat-select>
          <mat-hint>{{ practiceOptions.hint }}</mat-hint>
        </mat-form-field>
      </ng-container>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" [disabled]="isDisabled$ | async">
        Submit
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
