import { Appointment } from '@principle-theorem/principle-core';
import {
  IAppointment,
  IPatient,
  IPinnedInteractionSummary,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { flatten } from 'lodash';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export class AppointmentPinnedNotesBloc {
  hasPinnedNotes$: Observable<boolean>;
  clinical$: Observable<IPinnedInteractionSummary[]>;
  scheduling$: Observable<IPinnedInteractionSummary[]>;
  social$: Observable<IPinnedInteractionSummary[]>;

  constructor(appointment$: Observable<WithRef<IAppointment>>) {
    const patient$ = appointment$.pipe(
      switchMap((appointment) => Appointment.patient$(appointment))
    );
    this.clinical$ = this._getClinical$(patient$, appointment$);
    this.scheduling$ = this._getScheduling$(patient$, appointment$);
    this.social$ = this._getSocial$(patient$, appointment$);
    this.hasPinnedNotes$ = combineLatest([
      this.clinical$,
      this.scheduling$,
      this.social$,
    ]).pipe(map((notes) => flatten(notes).length > 0));
  }

  private _getSocial$(
    patient$: Observable<WithRef<IPatient>>,
    _appointment$: Observable<WithRef<IAppointment>>
  ): Observable<IPinnedInteractionSummary[]> {
    return patient$.pipe(
      map((patient): IPinnedInteractionSummary[] =>
        patient.notes
          .filter((note) => note.pinned)
          .map((note) => ({
            uid: note.uid,
            content: note.content,
            createdAt: note.createdAt,
          }))
      )
    );
  }

  private _getScheduling$(
    _patient$: Observable<WithRef<IPatient>>,
    appointment$: Observable<WithRef<IAppointment>>
  ): Observable<IPinnedInteractionSummary[]> {
    const interactions$ = appointment$.pipe(
      switchMap((appointment) => Appointment.interactions$(appointment))
    );
    return interactions$.pipe(
      map((interactions) =>
        interactions
          .filter((interaction) => interaction.pinned)
          .map((interaction) => ({
            uid: interaction.uid,
            content: interaction.content,
            createdAt: interaction.createdAt,
            createdBy: interaction.owner?.ref,
          }))
      )
    );
  }

  private _getClinical$(
    _patient$: Observable<WithRef<IPatient>>,
    _appointment$: Observable<WithRef<IAppointment>>
  ): Observable<IPinnedInteractionSummary[]> {
    return of([]);
  }
}
