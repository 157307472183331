<button
  *ngrxLet="unit$ as unit"
  mat-stroked-button
  [matMenuTriggerFor]="viewMenu"
>
  {{ unit | titlecase }}
  <mat-icon>expand_more</mat-icon>
</button>

<mat-menu #viewMenu="matMenu">
  <a
    *ngFor="let viewType of viewTypes; trackBy: trackByType"
    mat-menu-item
    (click)="selectUnit(viewType)"
  >
    {{ viewType | titlecase }}
  </a>
</mat-menu>
