import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MedicalFormAlerts } from '@principle-theorem/ng-custom-forms';
import { StateBasedNavigationService } from '@principle-theorem/ng-principle-shared';
import { Patient } from '@principle-theorem/principle-core';
import {
  PatientForm,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { ReplaySubject, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-patient-medical-form-warning',
  templateUrl: './patient-medical-form-warning.component.html',
  styleUrls: ['./patient-medical-form-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientMedicalFormWarningComponent {
  patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);
  noMedicalHistory$: Observable<string | undefined>;
  bloc: MedicalFormAlerts;

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(stateNav: StateBasedNavigationService, dialog: MatDialog) {
    const latestMedicalForm$ = this.patient$.pipe(
      switchMap((patient) =>
        Patient.formData$(patient, PatientForm.MedicalHistoryForm)
      )
    );
    this.bloc = new MedicalFormAlerts(
      this.patient$,
      latestMedicalForm$,
      stateNav,
      dialog
    );
    this.noMedicalHistory$ = this.bloc.hasMedicalHistory$.pipe(
      map((hasMedicalHistory) =>
        !hasMedicalHistory ? 'No medical history' : undefined
      )
    );
  }
}
