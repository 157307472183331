<div class="flex flex-wrap items-center justify-between gap-4">
  <form class="items center flex flex-wrap gap-2" [formGroup]="form">
    <mat-form-field class="!min-w-[315px]" subscriptSizing="dynamic">
      <mat-label> Search by Surface, Name, Code </mat-label>
      <input matInput formControlName="searchTerm" />
    </mat-form-field>

    <mat-form-field subscriptSizing="dynamic" class="!max-w-[150px]">
      <mat-label>Type</mat-label>
      <mat-select formControlName="type">
        <mat-option>--</mat-option>
        @for (type of types; track type) {
          <mat-option [value]="[type]">
            {{ type | splitCamel | titlecase }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Treatment Status</mat-label>
      <mat-select formControlName="treatmentStatus" multiple>
        <mat-select-trigger>
          @if (form.controls.treatmentStatus.value) {
            <div class="flex gap-1">
              @for (
                treatmentStatus of form.controls.treatmentStatus.value;
                track treatmentStatus
              ) {
                <pr-tooth-history-status-chip
                  [displayTooltip]="false"
                  [status]="treatmentStatus"
                />
              }
            </div>
          }
        </mat-select-trigger>
        @for (status of statuses; track status) {
          <mat-option [value]="status">
            <pr-tooth-history-status-chip
              [displayTooltip]="false"
              [status]="status"
            />
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </form>

  <pt-date-range-form [useRangePicker]="true" (changed)="updateDates($event)" />
</div>
