import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { CustomFormElementType } from '@principle-theorem/principle-core/interfaces';

const CUSTOM_FORM_ELEMENT_ICONS: {
  [key in CustomFormElementType]: string;
} = {
  [CustomFormElementType.Container]: 'widgets',
  // Section & Layout
  [CustomFormElementType.Section]: 'wysiwyg',
  [CustomFormElementType.Content]: 'text_snippet',
  // Fields
  [CustomFormElementType.Text]: 'text_fields',
  [CustomFormElementType.Textarea]: 'text_fields',
  [CustomFormElementType.Number]: 'pin',
  [CustomFormElementType.Checkbox]: 'check_box',
  [CustomFormElementType.Date]: 'edit_calendar',
  [CustomFormElementType.Dropdown]: 'arrow_drop_down_circle',
  [CustomFormElementType.Signature]: 'draw',
};

@Component({
    selector: 'pr-form-builder-thumbnail',
    templateUrl: './form-builder-thumbnail.component.html',
    styleUrl: './form-builder-thumbnail.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, NgMaterialModule, NgSharedModule]
})
export class FormBuilderThumbnailComponent {
  @Input() elementType: CustomFormElementType;
  @Input() hoverEnabled = false;

  getIcon(elementType: CustomFormElementType): string {
    return CUSTOM_FORM_ELEMENT_ICONS[elementType];
  }
}
