import { mapKeys, toPairs, defaults } from 'lodash';

export type QueryParams = Record<string, string | undefined>;

export interface IBuildUrlOptions {
  path: string[];
  queryParams: QueryParams;
}

const DEFAULT_OPTIONS: IBuildUrlOptions = {
  path: [],
  queryParams: {},
};

export function buildUrl(
  root: string,
  options?: Partial<IBuildUrlOptions>
): string {
  const { path, queryParams } = defaults(options || {}, DEFAULT_OPTIONS);
  const baseUrl = [root, ...path].join('/');
  return `${baseUrl}?${reduceQueryParams(queryParams)}`;
}

function reduceQueryParams(queryParams: QueryParams): string {
  return toPairs(queryParams)
    .filter(([_key, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${value ?? ''}`)
    .join('&');
}

export function wrapQueryParams(
  parentKey: string,
  queryParams: QueryParams
): QueryParams {
  return mapKeys(queryParams, (_value, key) => `${parentKey}[${key}]`);
}
