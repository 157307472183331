import { Practice, toINamedDocuments } from '@principle-theorem/principle-core';
import {
  CustomMappingType,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  IBlobFilenamePair,
  IOpenAIQueryData,
  SerialisedData,
  XSLXImporterExporter,
  asyncForEach,
  hardDeleteDoc,
  httpsCallable,
  multiMergeMap,
  multiSortBy$,
  nameSorter,
  reduce2DArray,
  safeChunk,
  serialise,
  snapshot,
  unserialise,
  type INamedDocument,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { first, sortBy, uniqBy } from 'lodash';
import * as moment from 'moment-timezone';
import { zodResponseFormat } from 'openai/helpers/zod';
import { combineLatest } from 'rxjs';
import { scan } from 'rxjs/operators';
import { z as zod } from 'zod';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import {
  PatientFileCategorySourceEntity,
  type ID4WPatientFileCategory,
} from '../../source/entities/patient-file-category';
import { FileCategoriesToXSLX } from './file-categories-to-xlsx';
import { XSLXToFileCategories } from './xlsx-to-file-categories';

const PATIENT_FILE_CATEGORY_CUSTOM_MAPPING_TYPE =
  'patientFileCategoryCustomMapping';

export const FILE_CATEGORIES_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'File Categories',
    description:
      'This allows us to map the subfolder of a D4W file as a tag when migrating to Principle. We first need to copy the patient files into the migration bucket before the source categories can be populated.',
    type: PATIENT_FILE_CATEGORY_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.DocumentReference,
});

interface IFileCategoryJSON {
  mappedValues: {
    sourceLabel: string;
    sourceValue: string;
    matches: INamedDocument<ITag>[];
  }[];
}

const fileCategorySchema = zod.object({
  mappedValues: zod.array(
    zod.object({
      sourceLabel: zod.string(),
      sourceValue: zod.string(),
      matches: zod.array(
        zod.object({
          ref: zod.string(),
          name: zod.string(),
        })
      ),
    })
  ),
});

export class D4WFileCategoryMappingHandler extends BaseCustomMappingHandler<ITag> {
  customMapping = FILE_CATEGORIES_MAPPING;

  async getSourceOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const entity = new PatientFileCategorySourceEntity();
    const records = await entity.getRecords$(migration, 1000).toPromise();
    const options = records
      .map((record) => record.data.data)
      .map(({ name }) => ({
        label: name,
        value: name,
      }));

    return sortBy(options, 'label');
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<ITag>[]> {
    return snapshot(
      combineLatest(
        migration.configuration.practices.map((practice) =>
          Practice.mediaTags$(practice)
        )
      ).pipe(reduce2DArray(), toINamedDocuments(), multiSortBy$(nameSorter()))
    );
  }

  async autocompleteMappings(
    migration: WithRef<IPracticeMigration>
  ): Promise<void> {
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const sourceOptions = await this.getSourceOptions(migration);
    const records = await this.getRecords(translationMap);
    const mappedItemCodes = records
      .filter((record) => !!record.destinationIdentifier)
      .map((record) => record.sourceIdentifier);
    const unmappedItemCodes = sourceOptions.filter(
      (option) => !mappedItemCodes.includes(option.value)
    );

    const mediaTags = await this.getDestinationOptions(migration);

    const cleanedOptions = unmappedItemCodes
      .map((item) => ({
        value: item.value,
        // eslint-disable-next-line no-useless-escape
        label: item.label.replace(/[0-9.\/-]/g, '').trim(),
      }))
      .filter((item) => !!item.label);

    const concurrentOperations = 10;
    const mappedValues = await safeChunk(cleanedOptions, 50)
      .pipe(
        multiMergeMap(concurrentOperations, async (options) => {
          const request: IOpenAIQueryData = {
            model: 'gpt-4o',
            messages: [
              {
                role: 'system',
                content: `You are helping to map a file category from one software system (source), to a file category in another (destination). For the source's label, please ignore any dates or special characters.

                If any matches are found, please return the best match listed first. For a match to be suitable, the source label must either contain a match to the destination "name", or contain a known abbreviation, or mispelling. If an acronym is to be considered a match, it needs to be a well known brand name (eg. Invisalign for Orthodontics, Zoom for whitening).`,
              },
              {
                role: 'user',
                content: `Given the source categories:

              ${JSON.stringify(options)}

              Find a match from the destination options, comparing their "name" property:

              ${JSON.stringify(serialise(mediaTags))}`,
              },
            ],
            jsonSchema: zodResponseFormat(fileCategorySchema, 'mappedValues')
              .json_schema.schema,
          };

          const response = await snapshot(
            httpsCallable<
              SerialisedData<IOpenAIQueryData>,
              { role: 'assistant'; content: string }
            >('http-openAi-queryFn', {
              timeout: moment.duration('10 minutes').asMilliseconds(),
            })(serialise(request))
          );

          const data = unserialise<IFileCategoryJSON>(
            JSON.parse(response.content) as SerialisedData<IFileCategoryJSON>
          );

          return data.mappedValues;
        }),
        scan(
          (allValues, values) => [...allValues, ...values].flat(),
          [] as IFileCategoryJSON['mappedValues']
        )
      )
      .toPromise();

    await asyncForEach(mappedValues, async (item) => {
      const match = first(item.matches);

      if (!match) {
        return;
      }

      // eslint-disable-next-line no-console
      console.log(`Mapping ${item.sourceLabel} to ${match.name}`);

      await this.upsertRecord(
        {
          destinationIdentifier: match.ref,
          sourceIdentifier: item.sourceValue,
          sourceLabel: item.sourceLabel,
        },
        translationMap
      );
    });
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, fileCategories, translator } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(
      fileName,
      fileCategories,
      translator
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, fileCategories, translator } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(
      fileName,
      fileCategories,
      translator
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToFileCategories()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const records = await this.getRecords(translationMap);
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));

    const sourceOptions = await this.getSourceOptions(migration);
    const mediaTags = await this.getDestinationOptions(migration);

    await asyncForEach(items, async (item) => {
      const label = item.folder;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.label === label
      )?.value;
      if (!value) {
        return;
      }

      const matches = new RegExp(/^tag\(([a-zA-Z0-9]+)\).*$/).exec(item.mapTo);
      const tagId = matches ? matches[1] : undefined;

      if (!tagId) {
        // eslint-disable-next-line no-console
        console.error(
          `Mapping error: ${this.customMapping.metadata.label} - Couldn't find tag for item`,
          item
        );
        return;
      }

      const tag = mediaTags.find((mediaTag) => mediaTag.ref.id === tagId);

      if (!tag) {
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: tag.ref,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    fileCategories: ID4WPatientFileCategory[];
    translator: FileCategoriesToXSLX;
  }> {
    const mediaTags = await this.getDestinationOptions(migration);
    const fileName = this.getFileName();
    const fileCategories = await this._getFileCategoryOptions(migration);
    const translator = new FileCategoriesToXSLX(mediaTags);
    return { fileName, fileCategories, translator };
  }

  private async _getFileCategoryOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ID4WPatientFileCategory[]> {
    const fileCategoryOptions = new PatientFileCategorySourceEntity();
    const records = await fileCategoryOptions
      .getRecords$(migration, 10000)
      .toPromise();
    return sortBy(
      uniqBy(records, (record) => record.data.data.name).map(
        (record) => record.data.data
      ),
      'name'
    );
  }
}
