import { Injectable, OnDestroy, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  DatabaseUsageTracker,
  FirestoreResourceType,
  FirestoreUsageType,
} from '@principle-theorem/shared';
import { isEmpty } from 'lodash';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { bufferTime, filter, map, takeUntil } from 'rxjs/operators';
import { LoggerService } from './logger/logger.service';
import { INgSharedConfig, NG_SHARED_CONFIG } from './ng-shared-config';

interface IFirestoreUsage {
  path: string;
  url: string;
  snapshotListenerCount: number;
  getCount: number;
}

interface IFirestoreUsageStat {
  url: string;
  path: string;
  numberOfReads: number;
  usageType: FirestoreUsageType;
  resourceType: FirestoreResourceType;
}

interface ILogSummary {
  documentReads: number;
  routeSummary: {
    [key: string]: number;
  };
  routeCollections: { [key: string]: { [key: string]: number } };
}

@Injectable({
  providedIn: 'root',
})
export class DatabaseUsageTrackerService implements OnDestroy {
  private _sharedConfig: INgSharedConfig = inject(NG_SHARED_CONFIG);
  private _onDestroy$ = new Subject<void>();

  constructor(
    private _logger: LoggerService,
    private _router: Router
  ) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  track(): void {
    DatabaseUsageTracker.usageAdded$
      .pipe(
        map((event) => ({
          ...event,
          url: this._cleanUrl(),
        })),
        bufferTime(moment.duration(60, 'minutes').asMilliseconds()),
        map((usageEvents) =>
          usageEvents.reduce<{ [key: string]: IFirestoreUsage[] }>(
            (data, usage) => {
              const path = this._cleanPath(usage.path);

              if (!data[path]) {
                data[path] = this._initUrlPath(path, usage);
                return data;
              }

              data[path] = this._updateUrlPath(data[path], usage);
              return data;
            },
            {}
          )
        ),
        filter((usage) => !isEmpty(usage)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((usage) => {
        const { documentReads, routeSummary, routeCollections } =
          this._buildLogSummary(usage);

        if (!this._sharedConfig.production) {
          // eslint-disable-next-line no-console
          console.log(`Firestore usage: ${documentReads} reads`, {
            routeSummary,
            routeCollections,
          });
        }

        void this._logger.log(
          'DEBUG',
          `Firestore usage: ${documentReads} reads`,
          {
            routeSummary,
            routeCollections,
          }
        );
      });
  }

  private _initUrlPath(
    path: string,
    usage: IFirestoreUsageStat
  ): IFirestoreUsage[] {
    return [
      {
        path,
        url: usage.url,
        snapshotListenerCount:
          usage.usageType === 'snapshotListener' ? usage.numberOfReads : 0,
        getCount: usage.usageType === 'get' ? usage.numberOfReads : 0,
      },
    ];
  }

  private _updateUrlPath(
    data: IFirestoreUsage[],
    usage: IFirestoreUsageStat
  ): IFirestoreUsage[] {
    return data.map((usageData) => {
      if (usageData.url !== usage.url) {
        return usageData;
      }

      if (usage.usageType === 'snapshotListener') {
        usageData.snapshotListenerCount =
          usageData.snapshotListenerCount + usage.numberOfReads;
      }

      if (usage.usageType === 'get') {
        usageData.getCount = usageData.getCount + usage.numberOfReads;
      }

      return usageData;
    });
  }

  private _buildLogSummary(usage: {
    [key: string]: IFirestoreUsage[];
  }): ILogSummary {
    const routeSummary: ILogSummary['routeSummary'] = {};
    const routeCollections: ILogSummary['routeCollections'] = {};

    Object.values(usage).map((data) =>
      data.map((usageData) => {
        const usageCount = usageData.snapshotListenerCount + usageData.getCount;

        if (!routeSummary[usageData.url]) {
          routeSummary[usageData.url] = 0;
        }
        routeSummary[usageData.url] = routeSummary[usageData.url] + usageCount;

        if (!routeCollections[usageData.url]) {
          routeCollections[usageData.url] = {};
        }
        routeCollections[usageData.url][usageData.path] =
          (routeCollections[usageData.url][usageData.path] ?? 0) + usageCount;
      })
    );

    const documentReads: ILogSummary['documentReads'] = Object.values(
      routeSummary
    ).reduce((total, count) => total + count, 0);

    return { documentReads, routeSummary, routeCollections };
  }

  private _cleanUrl(): string {
    return this._router.url.replace(/\?.*$/, '');
  }

  private _cleanPath(path: string): string {
    return path
      .split('/')
      .map((part, index) => {
        if (index % 2 === 0) {
          return part;
        }
        return ':id';
      })
      .join('/');
  }
}
