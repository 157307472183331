import { Pipe, type PipeTransform } from '@angular/core';
import { type IDataPoint } from '../../models/report/charts/measure-data-interfaces';
import { DataPointValueFormatter } from './data-point-value-formatter';

@Pipe({
  name: 'metric',
})
export class MetricPipe implements PipeTransform {
  transform(dataPoint?: IDataPoint | null): string {
    if (!dataPoint) {
      // eslint-disable-next-line no-console
      console.error('No data point');
      return '';
    }
    return DataPointValueFormatter.format(
      dataPoint.value,
      dataPoint.formatter,
      dataPoint.formatterValue
    );
  }
}
