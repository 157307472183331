<mat-toolbar color="accent">
  <h2>Edit Custom Report</h2>
</mat-toolbar>

<mat-dialog-content class="layout-padding">
  <div class="m-4">
    <pr-information-box>
      Editing a custom report requires the report to be run to gather the
      required additional data. This may take longer than running the custom
      report itself.
    </pr-information-box>
  </div>

  <pr-run-custom-report-form
    [customReport]="customReport()"
    [editMode]="true"
    [dateRange]="dateRange()"
    (queryDataChange)="queryData.set($event)"
  />

  <pt-buttons-container>
    <button mat-stroked-button (click)="dialogRef.close()">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="dialogRef.close(queryData())"
    >
      Load Data & Edit
    </button>
  </pt-buttons-container>
</mat-dialog-content>
