import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ChartedConfigurationFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  type AnyChartedItemConfiguration,
  type IQuickChartingConfigurations,
  isConditionConfiguration,
  isMultiTreatmentConfiguration,
  isTreatmentConfiguration,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  isPathChanged$,
  reduceToSingleArray,
  type WithRef,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { type Observable, ReplaySubject } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-search-result-charted-item',
  templateUrl: './search-result-charted-item.component.html',
  styleUrls: ['./search-result-charted-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultChartedItemComponent {
  isInQuickChart$: Observable<boolean>;
  item$: ReplaySubject<AnyChartedItemConfiguration> = new ReplaySubject(1);
  isMultiTreatment$: Observable<boolean>;
  serviceCodeSummary$: Observable<string>;
  @Input() quickChartEnabled = false;

  @Input()
  set item(item: AnyChartedItemConfiguration) {
    if (item) {
      this.item$.next(item);
    }
  }

  constructor(private _chartedConditionFacade: ChartedConfigurationFacade) {
    this.isInQuickChart$ = this.item$.pipe(
      isPathChanged$('ref.id'),
      switchMap((item) =>
        this._chartedConditionFacade.itemIsInQuickChart$(
          ChartId.InAppointment,
          item
        )
      ),
      startWith(false)
    );

    this.isMultiTreatment$ = this.item$.pipe(
      map((item) => isMultiTreatmentConfiguration(item))
    );

    this.serviceCodeSummary$ = this.item$.pipe(
      filter((item): item is WithRef<ITreatmentConfiguration> =>
        isTreatmentConfiguration(item)
      ),
      map((treatment) => {
        const smartGroups = reduceToSingleArray(
          treatment.serviceCodeSmartGroups.map((group) => group.serviceCodes)
        );

        const groups = reduceToSingleArray(
          treatment.serviceCodeGroups.map((group) => group.serviceCodes)
        ).map((code) => code.code);

        return [...groups, ...smartGroups];
      }),
      map((serviceCodes) => sortBy(serviceCodes).join(', '))
    );
  }

  addToQuickChart($event: Event, item: AnyChartedItemConfiguration): void {
    $event.stopPropagation();
    this._chartedConditionFacade.addQuickChartingItem(
      ChartId.InAppointment,
      item,
      this._quickChartingAccessor(item)
    );
  }

  removeFromQuickChart($event: Event, item: AnyChartedItemConfiguration): void {
    $event.stopPropagation();
    this._chartedConditionFacade.removeQuickChartingItem(
      ChartId.InAppointment,
      item,
      this._quickChartingAccessor(item)
    );
  }

  private _quickChartingAccessor(
    item: AnyChartedItemConfiguration
  ): keyof IQuickChartingConfigurations {
    if (isTreatmentConfiguration(item)) {
      return 'treatments';
    }
    if (isMultiTreatmentConfiguration(item)) {
      return 'multiTreatments';
    }
    if (isConditionConfiguration(item)) {
      return 'conditions';
    }
    throw new Error('No accessor available');
  }
}
