import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
  type OnDestroy,
} from '@angular/core';
import { Storage } from '@angular/fire/storage';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  getSchemaText,
  initVersionedSchema,
  MixedSchema,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import { BasicMenuButtonComponent } from '@principle-theorem/ng-editor';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import {
  isDisabled$,
  NG_SHARED_CONFIG,
  NgFireMediaUploader,
  TypedFormControl,
  type INgSharedConfig,
} from '@principle-theorem/ng-shared';
import {
  addInteractions,
  findMobileNumber,
  getMentionContactDetails,
  getMentionPatientRef,
  Interaction,
  stafferToNamedDoc,
  toMention,
} from '@principle-theorem/principle-core';
import {
  InteractionType,
  MentionResourceType,
  SMS_SEGMENT_SIZE,
  TemplateScope,
  TemplateType,
  type IBrand,
  type IInteractionData,
  type IInteractionV2,
  type IPractice,
  type IStaffer,
  type ITemplatesWithContext,
  type WithTemplateResource,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  getDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { AnyExtension } from '@tiptap/core';
import {
  BehaviorSubject,
  from,
  ReplaySubject,
  Subject,
  type Observable,
} from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  MentionToTemplates,
  type IPopulatedTemplate,
} from '../contextual-actions/interaction-actions/template-context-resolvers/mention-to-templates';
import { EditorPresetsService } from '../editor/editor-presets.service';
import { SendSMSService } from './send-sms.service';

@Component({
  selector: 'pr-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SMSComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _sharedConfig: INgSharedConfig = inject(NG_SHARED_CONFIG);
  mobile: string | undefined;
  noteControl = new TypedFormControl<MixedSchema>(
    initVersionedSchema(),
    Validators.required
  );
  submitting$ = new BehaviorSubject<boolean>(false);
  isDisabled$: Observable<boolean>;
  brand$: Observable<WithRef<IBrand>>;
  selectedPractice$ = new ReplaySubject<WithRef<IPractice>>(1);
  templates$: Observable<ITemplatesWithContext>;
  segmentSize = SMS_SEGMENT_SIZE;
  extensions: AnyExtension[];
  slashMenuItems: MenuButtonLoaderFn<BasicMenuButtonComponent>[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: WithTemplateResource<IInteractionData>,
    public organisation: OrganisationService,
    private _snackBar: MatSnackBar,
    private _sendSMSService: SendSMSService,
    public dialogRef: MatDialogRef<SMSComponent>,
    private _currentScope: CurrentScopeFacade,
    private _storage: Storage,
    private _editorPresets: EditorPresetsService
  ) {
    this.brand$ = this._currentScope.currentBrand$.pipe(filterUndefined());
    this.isDisabled$ = isDisabled$(this.noteControl);
    from(getMentionContactDetails(this.data.contact))
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((details) => {
        this.mobile = findMobileNumber(details?.contactNumbers);
      });

    this._currentScope.currentPractice$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((currentPractice) =>
        this.selectedPractice$.next(currentPractice)
      );

    this.templates$ = this.brand$.pipe(
      switchMap((brand) => {
        const mentionToTemplates = new MentionToTemplates(
          this._sharedConfig.appUrl
        );
        return mentionToTemplates.getTemplates(
          data.templateResource,
          brand.ref,
          TemplateType.PlainText
        );
      }),
      filterUndefined(),
      map((templates) =>
        filterTemplatesWithContextByScopes(templates, [
          TemplateScope.Appointment,
          TemplateScope.Invoice,
        ])
      )
    );
    this.extensions = this._editorPresets.defaultToTextExtensions();
    const uploader = new NgFireMediaUploader(
      this._storage,
      this.organisation.storagePath$.pipe(filterUndefined())
    );
    this.slashMenuItems = this._editorPresets.toTextMenuItems(uploader);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async addNote(): Promise<void> {
    this.submitting$.next(true);
    const content = this.noteControl.value;
    const staffer: WithRef<IStaffer> = await snapshot(
      this.organisation.staffer$.pipe(filterUndefined())
    );

    const practice = await snapshot(this.selectedPractice$);
    const patientRef = await getMentionPatientRef(this.data.contact);

    if (!patientRef) {
      this.submitting$.next(false);
      this._snackBar.open(`Couldn't resolve patient`);
      this.dialogRef.close();
      return;
    }

    const error = await this._sendSMSService.sendMessage(
      getSchemaText(content),
      await getDoc(patientRef),
      staffer.ref,
      practice.ref
    );

    if (error) {
      this.submitting$.next(false);
      this._snackBar.open(error.message);
      this.dialogRef.close();
      return;
    }

    const interaction = this._getSMSMessage(staffer);
    await addInteractions(this.data, interaction);

    this._snackBar.open('SMS Sent Successfully');
    this.dialogRef.close();
    this.submitting$.next(false);
  }

  templateSelected(template: IPopulatedTemplate): void {
    this.noteControl.setValue(template.content);
  }

  private _getSMSMessage(staffer: WithRef<IStaffer>): IInteractionV2 {
    return Interaction.init({
      type: InteractionType.Sms,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` sent SMS to `),
        toMentionContent(this.data.contact),
      ],
      owner: stafferToNamedDoc(staffer),
      content: this.noteControl.value,
    });
  }
}

export function filterTemplatesWithContextByScopes(
  templates: ITemplatesWithContext,
  scopes: TemplateScope[]
): ITemplatesWithContext {
  return scopes.reduce((filteredTemplates, scope) => {
    const scopedOptions = templates.contextOptions.filter(
      (option) => option.scope === scope
    );
    return !scopedOptions.length
      ? {
          ...filteredTemplates,
          templates: filteredTemplates.templates.filter(
            (template) => template.scope !== scope
          ),
        }
      : filteredTemplates;
  }, templates);
}
