import { ITreatmentTemplate } from '@principle-theorem/principle-core/interfaces';
import { TreatmentTemplate } from './treatment-template';

type TreatmentMockData = Pick<ITreatmentTemplate, 'name' | 'isPublic'>;

export enum TreatmentTemplateName {
  NewPatientComprehensiveExamination = 'New Patient Comprehensive Examination',
  ExamAndClean = 'New Patient Comprehensive Examination & Clean',
  EmergencyConsultation = 'Emergency Consultation',
  InvisalignConsultation = 'Invisalign Consultation',
  Cerec = 'Cerec',
}

export const TREATMENT_TEMPLATE_DATA: TreatmentMockData[] = [
  {
    name: TreatmentTemplateName.NewPatientComprehensiveExamination,
    isPublic: true,
  },
  {
    name: TreatmentTemplateName.ExamAndClean,
    isPublic: true,
  },
  {
    name: TreatmentTemplateName.EmergencyConsultation,
    isPublic: true,
  },
  {
    name: TreatmentTemplateName.InvisalignConsultation,
    isPublic: true,
  },
  {
    name: TreatmentTemplateName.Cerec,
    isPublic: false,
  },
];

export const TREATMENT_TEMPLATES: ITreatmentTemplate[] =
  TREATMENT_TEMPLATE_DATA.map((treatmentMockData: TreatmentMockData) => {
    const treatmentTemplate: ITreatmentTemplate =
      TreatmentTemplate.init(treatmentMockData);
    return treatmentTemplate;
  });
