import {
  DocumentReference,
  TypeGuard,
  isDocRef,
} from '@principle-theorem/shared';
import { IPractice } from '../practice/practice';
import { IIntegration, IntegrationType } from '@principle-theorem/integrations';
import { isString, isUndefined } from 'lodash';

export function isTwilioIntegration(
  item: IIntegration
): item is IIntegration<ITwilioIntegrationData> {
  return item.type === IntegrationType.Twilio;
}

export interface ITwilioPracticeNumber {
  phoneNumber: string;
  practiceRef: DocumentReference<IPractice>;
  disabled?: boolean;
}

export function isTwilioPracticeNumber(
  item: unknown
): item is ITwilioPracticeNumber {
  return TypeGuard.interface<ITwilioPracticeNumber>({
    phoneNumber: isString,
    practiceRef: isDocRef,
    disabled: [isString, isUndefined],
  })(item);
}

export interface ITwilioConfig {
  authToken?: string;
  accountSid?: string;
  phoneNumbers?: ITwilioPracticeNumber[];
  outboundCost?: number;
  inboundCost?: number;
}

export interface ITwilioIntegrationData extends ITwilioConfig {
  enabled: boolean;
  testModeEnabled: boolean;
}
