<ng-container *ngrxLet="rangeService.selectedDayRange$ as selectedDayRange">
  <button
    mat-icon-button
    *ngIf="!(nextMonthDisabled$ | ngrxPush)"
    (click)="previousMonth()"
    matTooltip="Previous Month"
  >
    <mat-icon aria-label="Previous Month">arrow_back_ios</mat-icon>
  </button>

  <button
    mat-icon-button
    *ngIf="!(nextWeekDisabled$ | ngrxPush)"
    (click)="previousWeek()"
    matTooltip="Jump to previous Week"
  >
    <mat-icon aria-label="Jump to previous Week">skip_previous</mat-icon>
  </button>

  <button
    mat-icon-button
    *ngIf="!(nextDayDisabled$ | ngrxPush)"
    (click)="previousDay()"
    matTooltip="Previous Day"
  >
    <mat-icon aria-label="Previous Day">chevron_left</mat-icon>
  </button>

  <button
    mat-icon-button
    (click)="today()"
    matTooltip="Today"
    [disabled]="isToday$ | ngrxPush"
  >
    <mat-icon aria-label="Today">home</mat-icon>
  </button>

  <form [formGroup]="dateRangeForm">
    <mat-form-field class="hide-input">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="dateRangePicker">
        <input matStartDate formControlName="from" placeholder="Start date" />
        <input matEndDate formControlName="to" placeholder="End date" />
      </mat-date-range-input>
      <mat-date-range-picker
        #dateRangePicker
        [calendarHeaderComponent]="header"
        panelClass="date-range-panel"
       />
    </mat-form-field>
  </form>

  <button
    mat-icon-button
    *ngIf="!(datePickerDisabled$ | ngrxPush)"
    (click)="dateRangePicker.open()"
    matTooltip="Select a Date Range"
  >
    <mat-icon aria-label="Select Date Range">event</mat-icon>
  </button>

  <button
    mat-icon-button
    *ngIf="!(nextDayDisabled$ | ngrxPush)"
    (click)="nextDay()"
    matTooltip="Next Day"
  >
    <mat-icon aria-label="Next Day">chevron_right</mat-icon>
  </button>

  <button
    mat-icon-button
    *ngIf="!(nextWeekDisabled$ | ngrxPush)"
    (click)="nextWeek()"
    matTooltip="Jump to next week"
  >
    <mat-icon aria-label="Jump to next week">skip_next</mat-icon>
  </button>

  <button
    mat-icon-button
    *ngIf="!(nextMonthDisabled$ | ngrxPush)"
    (click)="nextMonth()"
    matTooltip="Next Month"
  >
    <mat-icon aria-label="Next Month">arrow_forward_ios</mat-icon>
  </button>
</ng-container>
