<div *ngIf="claim$ | async as claim">
  <pr-transactions-display
    [invoice]="invoice$ | async"
    [claim]="claim"
    [transactions]="transactions$ | async"
  >
    <div class="outdated" *ngIf="claim.outdatedAt">
      <strong
        matTooltip="The invoice has changed since this claim was created.
      You will not be able to take any new transactions on this claim"
      >
        OUTDATED
      </strong>
    </div>

    <pr-healthcare-claim-items-summary
      *ngIf="claimItems$ | async as items"
      [items]="items"
    />
  </pr-transactions-display>
</div>
