import { isObject } from '@principle-theorem/shared';
import { type Timestamp } from '@principle-theorem/shared';
import {
  type IBaseTransactionCompleteCallbackData,
  type IBulkBillEasyclaimRequestParams,
  isEasyclaimCompleteCallback,
  type PeriodTypeCde,
  type ReferralOverrideTypeCde,
  IEasyclaimRequestParams,
  IEasyclaimCompleteCallback,
} from '@principle-theorem/tyro';
import { type IServiceCodeLineItem } from '../../invoice/custom-line-items';
import { type IMedicareCard } from '../../patient/healthfund-cards';
import { type ITyroBaseExtendedData } from './tyro-base-extended-data';

export interface IBulkBillDialogResponse {
  medicareCard: IMedicareCard;
  itemClaims: IADACodeClaim[];
  referralData: IReferralFormData;
}

export interface IADACodeClaim {
  lineItem: IServiceCodeLineItem;
  claim: boolean;
  amount: number;
}

export interface IReferralData {
  periodTypeCde: PeriodTypeCde;
  providerNum: string;
  dateOfIssue?: Timestamp;
}
export interface INoReferralData {
  referralOverrideTypeCde: ReferralOverrideTypeCde;
}
export interface IReferralFormData {
  hasReferral: boolean;
  noReferral: INoReferralData;
  referral: IReferralData;
}

export interface IBulkBillTransactionExtendedData
  extends ITyroBaseExtendedData {
  request: IBulkBillEasyclaimRequestParams;
  response: IBaseTransactionCompleteCallbackData;
  formData: IBulkBillDialogResponse;
}

export function isBulkBillTransactionExtendedData(
  data: unknown
): data is IBulkBillTransactionExtendedData {
  // TODO: #3ax3vq Extend to check additional fields.
  return isObject(data) && isEasyclaimCompleteCallback(data.response);
}

export interface IPatientClaimDialogResponse {
  medicareCard: IMedicareCard;
  referralData: IReferralFormData;
}

export interface IPatientEClaimTransactionExtendedData {
  request: IEasyclaimRequestParams;
  response: IEasyclaimCompleteCallback;
  formData: IPatientClaimDialogResponse;
}

export function isPatientEClaimTransactionExtendedData(
  data: unknown
): data is IPatientEClaimTransactionExtendedData {
  // TODO: #3ax3vq Extend to check additional fields.
  return isObject(data) && isEasyclaimCompleteCallback(data.response);
}
