import { IMatchesADAScope } from './base-rule-handler';
import {
  IScopeRef,
  IChartedSurface,
  ServiceCodeScope,
  IADACodeRules,
} from '@principle-theorem/principle-core/interfaces';

export class PerTooth implements IMatchesADAScope {
  scope: ServiceCodeScope = ServiceCodeScope.PerTooth;

  matches(
    rule: IADACodeRules,
    _treatmentScope: IScopeRef,
    surfaces: IChartedSurface[]
  ): boolean {
    const hasMatchingSurfaces: boolean = surfaces.some(
      (surface: IChartedSurface) => {
        if (!surface.chartedRef.tooth) {
          return false;
        }
        return true;
      }
    );

    const scopes: ServiceCodeScope[] = [
      ServiceCodeScope.PerTooth,
      ServiceCodeScope.PerDeciduousTooth,
      ServiceCodeScope.PerAnterior,
      ServiceCodeScope.PerPosterior,
    ];

    if (!rule.scope) {
      return false;
    }
    return scopes.includes(rule.scope) && hasMatchingSurfaces;
  }
}
