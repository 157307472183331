import {
  IBrand,
  isStaffer,
  IStaffer,
  IUser,
} from '@principle-theorem/principle-core/interfaces';
import { limit, where } from '@principle-theorem/shared';
import {
  INamedDocument,
  IReffable,
  isINamedDocument,
  safeCombineLatest,
  toNamedDocument,
  undeletedQuery,
  WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { Observable, of, OperatorFunction } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Brand } from '../brand';
import { stafferToNamedDoc } from '../common';
import { OrganisationCache } from '../organisation/organisation-cache';

export function resolveToStaff(
  brand: IReffable<IBrand>
): OperatorFunction<WithRef<IUser>[], (WithRef<IStaffer> | undefined)[]> {
  return (source: Observable<WithRef<IUser>[]>) =>
    source.pipe(
      switchMap((users) =>
        safeCombineLatest(
          users.map((user) => resolveToStaffer(brand)(of(user)))
        )
      )
    );
}

export function resolveToStaffer(
  brand: IReffable<IBrand>
): OperatorFunction<WithRef<IUser>, WithRef<IStaffer> | undefined> {
  return (source: Observable<WithRef<IUser>>) =>
    source.pipe(
      switchMap((user) =>
        OrganisationCache.staff.all.find$(
          {
            brandRef: brand.ref,
            userRef: user.ref,
          },
          undeletedQuery(Brand.stafferCol(brand)),
          where('user.ref', '==', user.ref),
          limit(1)
        )
      )
    );
}

export function toINamedDocuments<T extends object>(): OperatorFunction<
  T[],
  INamedDocument<T>[]
> {
  return (source: Observable<T[]>) =>
    source.pipe(
      map((items: T[]) =>
        compact(
          items.map((item: T) => {
            if (isStaffer(item)) {
              return stafferToNamedDoc(item) as unknown as INamedDocument<T>;
            }

            if (isINamedDocument<T>(item)) {
              return toNamedDocument<T>(item);
            }
          })
        )
      )
    );
}
