<ng-container *ngrxLet="view$ as view">
  <div
    *ngrxLet="days$ as days"
    class="flex items-start self-start"
    [ngClass]="{
      'flex-row': view.isHorizontal$ | ngrxPush,
      'flex-col': view.isVertical$ | ngrxPush
    }"
  >
    <div
      class="headers flex self-start"
      [ngClass]="{
        'flex-col': view.isHorizontal$ | ngrxPush,
        'flex-row': view.isVertical$ | ngrxPush
      }"
    >
      <div
        class="timeline-units"
        [ngStyle]="view.spacerHeaderStyle$ | ngrxPush"
      ></div>

      <div
        *ngFor="let day of days; trackBy: trackByDay"
        class="timeline-day flex"
        [ngClass]="{
          'flex-row': view.isHorizontal$ | ngrxPush,
          'flex-col': view.isVertical$ | ngrxPush
        }"
        [ngStyle]="
          options$ | pairMap$: day : view.dayHeaderStyle : view | ngrxPush
        "
      >
        <div
          class="day-label flex place-content-center items-center self-center"
          [ngClass]="{
            'flex-col': view.isHorizontal$ | ngrxPush,
            'flex-row': view.isVertical$ | ngrxPush
          }"
        >
          <div class="day-label-inner">
            {{ day.day.from | moment | amDateFormat: dateFormat }}
          </div>

          <pr-interactive-timeline-day-menu [day]="day.day.from" />
        </div>
        <div
          class="flex flex-1"
          [ngClass]="{
            'flex-col': view.isHorizontal$ | ngrxPush,
            'flex-row': view.isVertical$ | ngrxPush
          }"
        >
          <div
            *ngFor="let group of day.groups; trackBy: trackByGroup"
            class="header-group flex"
            [ngStyle]="
              options$
                | pairMap$: group : view.groupHeaderStyle : view
                | ngrxPush
            "
          >
            <pr-interactive-timeline-header
              class="h-full w-full"
              [options]="options$ | ngrxPush"
              [group]="group.group"
              [day]="day.day.from"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="(loading$ | async) === false; else loading"
      class="timeline-area flex"
      [ngClass]="{
        'flex-col': view.isHorizontal$ | ngrxPush,
        'flex-row': view.isVertical$ | ngrxPush
      }"
    >
      <pr-interactive-timeline-units
        class="timeline-units"
        [ngStyle]="view.unitsHeaderStyle$ | ngrxPush"
        [options]="options$ | ngrxPush"
        [displayRange]="displayRange$ | async"
      />

      <pr-interactive-timeline-dragarea
        *ngrxLet="createNode$ as createNode"
        #dragArea
        class="flex"
        [options]="options$ | ngrxPush"
        [ngStyle]="view.dragareaStyle$ | ngrxPush"
        [ngClass]="{
          'flex-col': view.isHorizontal$ | ngrxPush,
          'flex-row': view.isVertical$ | ngrxPush
        }"
      >
        <ng-container *ngIf="createNode">
          <pr-interactive-timeline-node
            *ngIf="isInRange$ | ngrxPush"
            #node
            [options]="options$ | ngrxPush"
            [dragArea]="dragArea"
            [node]="createNode"
            [day]="createNode.day"
            [timeRange]="timeRange$ | async"
            [trackIndex]="createNode.trackIndex"
            (dragEnd)="handleCreateDrag($event)"
            (resizeEnd)="handleCreateResize($event)"
          >
            <pr-timeline-event-display
              [options]="options$ | ngrxPush"
              [event]="createNode.data"
              [disabled]="
                (disableTooltips$ | ngrxPush) || (node.moving$ | ngrxPush)
              "
            />
          </pr-interactive-timeline-node>
        </ng-container>
        <div
          *ngFor="let day of days; trackBy: trackByDay"
          class="timeline-day flex"
          [ngClass]="{
            'flex-col': view.isHorizontal$ | ngrxPush,
            'flex-row': view.isVertical$ | ngrxPush
          }"
          [ngStyle]="
            options$ | pairMap$: day : view.dayTimelineStyle : view | ngrxPush
          "
        >
          <div *ngIf="day.isClosed" class="closed flex items-center">
            <div class="closed-inner self-center">Closed</div>
          </div>

          <pr-interactive-timeline-dropzone
            *ngFor="let group of day.groups; trackBy: trackByGroup"
            [ngStyle]="
              options$ | pairMap$: group : view.dropzoneStyle : view | ngrxPush
            "
            [ngClass]="{
              'create-mode': isCreateMode$ | ngrxPush,
              'reschedule-mode': isRescheduleMode$ | ngrxPush
            }"
            [dragArea]="dragArea"
            [day]="day.day"
            [group]="group"
            [options]="options$ | ngrxPush"
            [timeRange]="timeRange$ | async"
            [disableDrag]="(isCreateMode$ | ngrxPush) === false"
            [disableTap]="(isRescheduleMode$ | ngrxPush) === false"
            (dragEnd)="handleCreate($event, group, day.day)"
            (tapEnd)="handleDrop($event, group, day.day, day.initialTrackIndex)"
          >
            <pr-interactive-timeline-deadzone
              *ngFor="let deadzone of group.deadzones; trackBy: trackByDeadzone"
              [options]="options$ | ngrxPush"
              [group]="group"
              [day]="day.day"
              [timeRange]="timeRange$ | async"
              [deadzone]="deadzone"
              [colour]="deadzoneColourOverride$ | async"
            />

            <ng-container
              *ngFor="
                let track of group.nodes;
                trackBy: trackByIndex;
                let trackIndex = index
              "
            >
              <ng-container *ngFor="let node of track; trackBy: trackByNode">
                <pr-interactive-timeline-node
                  *ngIf="!node.data.ref || createNode?.uid !== node.data.ref.id"
                  #timelineNode
                  [options]="options$ | ngrxPush"
                  [dragArea]="dragArea"
                  [node]="node"
                  [day]="day.day"
                  [timeRange]="timeRange$ | async"
                  [trackIndex]="trackIndex"
                  (dragEnd)="handleDragEnd($event, node)"
                  (resizeEnd)="handleResize($event, node)"
                  (tapEnd)="handleSelect($event, node)"
                  (moving)="moving$.next($event)"
                >
                  <pr-timeline-event-display
                    [options]="options$ | ngrxPush"
                    [event]="node.data"
                    [disabled]="
                      (disableTooltips$ | ngrxPush) ||
                      (timelineNode.moving$ | ngrxPush)
                    "
                  />
                </pr-interactive-timeline-node>
              </ng-container>
            </ng-container>
          </pr-interactive-timeline-dropzone>

          <pr-interactive-timeline-gridlines [options]="options$ | ngrxPush" />

          <pr-interactive-timeline-nowline
            [day]="day.day"
            [options]="options$ | ngrxPush"
            [displayRange]="displayRange$ | async"
          />

          <pr-interactive-timeline-day-line [options]="options$ | ngrxPush" />
        </div>
      </pr-interactive-timeline-dragarea>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <mat-progress-bar mode="indeterminate" />
</ng-template>
