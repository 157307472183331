import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { Appointment, LabJob } from '@principle-theorem/principle-core';
import {
  AppointmentSummary,
  type IAppointment,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { ReplaySubject, type Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

enum LabJobBadgeState {
  Received = 'received',
  SentOrSending = 'sentOrSending',
  Overdue = 'overdue',
}

@Component({
  selector: 'pr-timeline-appointment-notifications',
  templateUrl: './timeline-appointment-notifications.component.html',
  styleUrls: ['./timeline-appointment-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgMaterialModule],
})
export class TimelineAppointmentNotificationsComponent {
  showConfirmationBadge$: Observable<boolean>;
  showLabJobBadge$: Observable<boolean>;
  isNotConfirmed$: Observable<boolean>;
  hasArrived$: Observable<boolean>;
  appointment$ = new ReplaySubject<WithRef<IAppointment> | AppointmentSummary>(
    1
  );
  labJobBadgeState$: Observable<LabJobBadgeState>;
  labJobTooltip$: Observable<string | undefined>;
  @Input() tooltipsEnabled = false;

  @Input()
  set appointment(appointment: WithRef<IAppointment> | AppointmentSummary) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor() {
    this.isNotConfirmed$ = this.appointment$.pipe(
      map((appointment) => Appointment.isUnconfirmed(appointment))
    );

    this.hasArrived$ = this.appointment$.pipe(
      map((appointment) => Appointment.canRevertToConfirmed(appointment)),
      startWith(false)
    );

    const labJobs$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.labJobs$(appointment))
    );

    this.showLabJobBadge$ = labJobs$.pipe(map((labJobs) => !!labJobs.length));

    this.labJobBadgeState$ = labJobs$.pipe(
      map((labJobs) => {
        const isOverdue = labJobs.some((labJob) => LabJob.isOverdue(labJob));
        if (isOverdue) {
          return LabJobBadgeState.Overdue;
        }

        const isReceived = labJobs.some((labJob) => LabJob.complete(labJob));
        if (isReceived) {
          return LabJobBadgeState.Received;
        }

        return LabJobBadgeState.SentOrSending;
      })
    );

    this.labJobTooltip$ = this.labJobBadgeState$.pipe(
      map((state) => {
        switch (state) {
          case LabJobBadgeState.Received:
            return 'Lab Job Received';
          case LabJobBadgeState.Overdue:
            return 'Lab Job Overdue';
          case LabJobBadgeState.SentOrSending:
            return 'Lab Job Sent or Sending';
          default:
            return '';
        }
      })
    );
  }
}
