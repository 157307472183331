import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  isRefChanged$,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { type IAppointmentCardAction } from '../actions/appointment-card-action-interface';

@Component({
  selector: 'pr-appointment-card-action',
  templateUrl: './appointment-card-action.component.html',
  styleUrls: ['./appointment-card-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentCardActionComponent {
  action$ = new ReplaySubject<IAppointmentCardAction>(1);
  appointment$ = new ReplaySubject<WithRef<IAppointment>>(1);
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  badge$: Observable<number>;
  hideBadge$: Observable<boolean>;
  @Output() updateEventable = new EventEmitter<IAppointmentCardAction>();

  @Input()
  set action(action: IAppointmentCardAction) {
    if (action) {
      this.action$.next(action);
    }
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor() {
    const state$ = combineLatest([
      this.action$,
      this.patient$.pipe(isRefChanged$()),
      this.appointment$,
    ]);
    this.badge$ = state$.pipe(
      switchMap(([action, patient, appointment]) =>
        action.getBadge$(appointment, patient)
      )
    );
    this.hideBadge$ = state$.pipe(
      switchMap(([action, patient, appointment]) =>
        action.hasBadge$(appointment, patient)
      ),
      map((hasBadge) => !hasBadge)
    );
  }

  async run(action: IAppointmentCardAction): Promise<void> {
    const patient = await snapshot(this.patient$);
    const appointment = await snapshot(this.appointment$);
    this.updateEventable.emit(action);
    await action.run(appointment, patient);
  }
}
