import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { ColorPickerModule as NGXColorPickerModule } from 'ngx-color-picker';
import { ColourPickerComponent } from './colour-picker.component';

@NgModule({
  imports: [CommonModule, NgMaterialModule, NGXColorPickerModule],
  declarations: [ColourPickerComponent],
  exports: [ColourPickerComponent],
})
export class ColourPickerModule {}
