import { type WithRef } from '@principle-theorem/shared';
import { getCustomMappingsHandler } from './custom-mappings-handler';
import {
  type ICustomMapping,
  type ICustomMappingHandler,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';

export function getCustomMappingHandler(
  migration: WithRef<IPracticeMigration>,
  customMapping: WithRef<ICustomMapping>
): ICustomMappingHandler | undefined {
  const handler = getCustomMappingsHandler(migration);
  if (!handler) {
    return;
  }

  return handler.mappingHandlers.find((mappingHandler) =>
    mappingHandler.canHandle(customMapping)
  );
}
