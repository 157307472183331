<pt-search-field icon="add" [slim]="true">
  <input
    matInput
    autocomplete="off"
    [formControl]="searchCtrl"
    placeholder="Add a Line Item"
    [matAutocomplete]="autoProducts"
    #autoComplete="matAutocompleteTrigger"
    (click)="autoComplete.openPanel()"
    ptElementTarget="line-item-selector-input"
  />
  <mat-autocomplete
    #autoProducts="matAutocomplete"
    [displayWith]="displayFn"
    [autoActiveFirstOption]="true"
    (optionSelected)="optionSelected.emit($event.option.value)"
  >
    <ng-container *ngIf="miscSearch.results$ | async as misc">
      <mat-option
        *ngFor="let item of misc; trackBy: trackByItem"
        [value]="item"
      >
        {{ item.description }}
      </mat-option>
    </ng-container>

    <ng-container *ngIf="treatmentSearch.results$ | async as treatments">
      <mat-optgroup label="Treatments" *ngIf="treatments.length">
        <mat-option
          *ngFor="let treatment of treatments; trackBy: trackByTreatment"
          [value]="treatment"
        >
          <div fxLayout="row" fxLayoutAlign="start center">
            <span fxFlex>{{ treatment.treatment.config.name }}</span>
            <span class="mat-caption">
              {{ treatment.treatment.price | currency }}
            </span>
          </div>
        </mat-option>
      </mat-optgroup>
    </ng-container>

    <ng-container *ngIf="productSearch.results$ | async as products">
      <mat-optgroup label="Products" *ngIf="products.length">
        <mat-option
          *ngFor="let product of products; trackBy: trackByProduct"
          [value]="product"
        >
          <div fxLayout="row" fxLayoutAlign="start center">
            <span fxFlex>{{ product.description }}</span>
            <span class="mat-caption">{{ product.amount | currency }}</span>
          </div>
        </mat-option>
      </mat-optgroup>
    </ng-container>
  </mat-autocomplete>
</pt-search-field>
