import { rand, randNumber } from '@ngneat/falso';
import {
  CustomChartType,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import { FactsCommon } from '@principle-theorem/reporting/interfaces';
import { IChartConfig } from '../chart-config';
import { generateBuilderData } from '../models/base-measures';
import { FactTables } from '../models/fact-tables';
import { BooleanMeasureFilter } from '../models/measure-filters';
import { CommonMockValues, MockTimestampedFacts } from './common-mock-values';
import { toMeasureBuilderData } from '../models/measure-properties';

/**
 * New Patient Distribution
 */
export const newPatientDistribution: IChartConfig = {
  type: CustomChartType.Pie,
  labels: {
    title: `New Patient Distribution`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.newPatient
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceByCount()
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

interface INewPatientDistribution extends FactsCommon.ITimestampedFact {
  isFirstAppointment: boolean;
  practitioner_ref_referenceValue: string;
  practitioner_user_name: string;
}

export const newPatientDistributionResults = [
  MockTimestampedFacts<INewPatientDistribution>(
    'newPatientDistributionSeed',
    () => {
      const practitioner = rand(CommonMockValues.staff);
      return {
        isFirstAppointment: true,
        practitioner_ref_referenceValue: practitioner.ref,
        practitioner_user_name: practitioner.name,
      };
    }
  ),
];

/**
 * Revenue Distribution
 */
export const revenueDistribution: IChartConfig = {
  type: CustomChartType.Line,
  labels: {
    title: `Revenue Distribution`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        // TODO: I think this needs transaction fact table.
        FactTables.invoiceEvent.total
          .groupBy(FactTables.invoiceEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.invoiceEvent.practitioner.name,
  }),
};

export const revenueDistributionResults = [
  MockTimestampedFacts('revenueDistributionSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      total: randNumber({ min: 250, max: 1000 }),
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
    };
  }),
];

/**
 * Patient Flow
 * Patients leaving with scheduled appointment
 */
export const patientsLeavingWithScheduledAppointment: IChartConfig = {
  type: CustomChartType.Pie,
  labels: {
    title: `Patients leaving with scheduled appointment`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.nextAppointmentBooked
          .filterBy(
            new BooleanMeasureFilter(true),
            FactTables.appointmentEvent.nextAppointmentBooked.measure
          )
          .groupBy(FactTables.invoiceEvent.practitioner.name)
          .reduceByCount()
      ),
    ],
    groupByDimension: FactTables.invoiceEvent.practitioner.name,
  }),
};

export const patientsLeavingWithScheduledAppointmentResults = [
  MockTimestampedFacts('patientsLeavingWithScheduledAppointmentSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      nextAppointmentBooked: true,
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
    };
  }),
];

/**
 * Patient Flow
 * Patients leaving without scheduled appointment
 */
export const patientsLeavingWithoutScheduledAppointment: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Patients leaving with scheduled appointment`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.nextAppointmentBooked
          .filterBy(
            new BooleanMeasureFilter(false),
            FactTables.appointmentEvent.nextAppointmentBooked.measure
          )
          .groupBy(FactTables.invoiceEvent.practitioner.name)
          .reduceByCount()
      ),
    ],
    groupByDimension: FactTables.invoiceEvent.practitioner.name,
  }),
};

export const patientsLeavingWithoutScheduledAppointmentResults = [
  MockTimestampedFacts('patientsLeavingWithoutScheduledAppointmentSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      nextAppointmentBooked: false,
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
    };
  }),
];

/**
 * Cancellations, FTAS, and UTAS
 * Cancellations
 */
export const appointmentCancellations: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Appointment Cancellations`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.cancelled
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceByCount()
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const appointmentCancellationsResults = [
  MockTimestampedFacts('appointmentCancellationsSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      // ???
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
    };
  }),
];

/**
 * Cancellations, FTAS, and UTAS
 * FTAS
 * TODO: How the fuck do I know when a FTA happens?
 */
export const appointmentFTAs: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Appointment FTAs`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.cancelled
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceByCount()
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const appointmentFTAsResults = [
  MockTimestampedFacts('appointmentFTAsSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      // ???
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
    };
  }),
];

/**
 * Cancellations, FTAS, and UTAS
 * UTAS
 * TODO: How the fuck do I know when a UTA happens?
 */
export const appointmentUTAs: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Appointment UTAs`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.cancelled
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceByCount()
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const appointmentUTAsResults = [
  MockTimestampedFacts('appointmentUTAsSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      // ???
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
    };
  }),
];

/**
 * Appointment & Gap Durations
 * Appointment Durations
 */
export const appointmentDurations: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Appointment & Gap Durations - Minutes In Appointments`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.duration
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Minutes)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const appointmentDurationsResults = [
  MockTimestampedFacts('appointmentDurationsSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_name: practitioner.name,
      duration: randNumber({ min: 5, max: 120 }),
    };
  }),
];

/**
 * Appointment & Gap Durations
 * Appointment Gaps
 * TODO: How do I know when they aren't in an appointment?
 */
export const appointmentGaps: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `Appointment & Gap Durations - Minutes Not In Appointments`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.duration
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Minutes)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const appointmentGapsResults = [
  MockTimestampedFacts('appointmentDurationsSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_name: practitioner.name,
      duration: randNumber({ min: 5, max: 120 }),
    };
  }),
];
