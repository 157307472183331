import { rand, randNumber } from '@ngneat/falso';
import {
  CustomChartType,
  InvoiceStatus,
  MeasureFormatter,
  TreatmentPlanStatus,
} from '@principle-theorem/principle-core/interfaces';
import { IChartConfig } from '../chart-config';
import { generateBuilderData } from '../models/base-measures';
import { FactTables } from '../models/fact-tables';
import {
  BooleanMeasureFilter,
  ValueDoesNotEqualMeasureFilter,
} from '../models/measure-filters';
import { CommonMockValues, MockTimestampedFacts } from './common-mock-values';
import { toMeasureBuilderData } from '../models/measure-properties';

/**
 * Gross Income
 */
export const grossIncomeOverTime: IChartConfig = {
  type: CustomChartType.Line,
  labels: {
    title: `Gross Income`,
  },
  builderData: generateBuilderData({
    plottedOverTime: true,
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const grossIncomeOverTimeResults = [
  MockTimestampedFacts('grossIncomeSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      treatmentCost: randNumber({ min: 250, max: 1500 }),
    };
  }),
];

/**
 * Income vs Expenses
 * Gross Income
 */
export const incomeVsExpensesGrossIncome: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Gross Income`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const incomeVsExpensesGrossIncomeResults = [
  MockTimestampedFacts('incomeVsExpensesGrossIncomeSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      treatmentCost: randNumber({ min: 250, max: 1500 }),
    };
  }),
];

/**
 * Income vs Expenses
 * Dental Draw
 */
export const incomeVsExpensesDentalDraw: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Dental Draw`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        // TODO: This should be calculated differently. THis is just showing treatmentCost as a placeholder
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const incomeVsExpensesDentalDrawResults = [
  MockTimestampedFacts('incomeVsExpensesDentalDrawSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      treatmentCost: randNumber({ min: 250, max: 1500 }),
    };
  }),
];

/**
 * Income vs Expenses
 * Lab Fees
 */
export const incomeVsExpensesLabFees: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Lab Fees`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.labJobReceived.labJob.cost
          .groupBy(FactTables.labJobReceived.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.labJobReceived.practitioner.name,
  }),
};

export const incomeVsExpensesLabFeesResults = [
  MockTimestampedFacts('incomeVsExpensesLabFeesSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      labJob_cost: randNumber({ min: 250, max: 1500 }),
    };
  }),
];

/**
 * Income Distribution
 */
export const incomeDistribution: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Income Distribution`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const incomeDistributionResults = [
  MockTimestampedFacts('incomeDistributionSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      treatmentCost: randNumber({ min: 250, max: 1500 }),
    };
  }),
];

/**
 * Hourly Rate
 */
export const hourlyRate: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Hourly Rate`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.hourlyRate // TODO: hourly rate should be calculated differently
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const hourlyRateResults = [
  MockTimestampedFacts('hourlyRateSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      treatmentCost: randNumber({ min: 250, max: 1500 }),
    };
  }),
];

/**
 * Potential Income
 * Gross Income
 */
export const potentialIncomeGrossIncome: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Potential Income - Gross Income`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const potentialIncomeGrossIncomeResults = [
  MockTimestampedFacts('potentialIncomeGrossIncomeSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      treatmentCost: randNumber({ min: 250, max: 1500 }),
    };
  }),
];

/**
 * Potential Income
 * Lost Income
 * TODO: This needs more logic to determine the lost income. It should be:
 * Hourly Rate * Gaps In Hours
 */
export const potentialIncomeLostIncome: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Potential Income - Lost Income`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const potentialIncomeLostIncomeResults = [
  MockTimestampedFacts('potentialIncomeLostIncomeSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      treatmentCost: randNumber({ min: 250, max: 1500 }),
    };
  }),
];

/**
 * Debt Collections
 * Written Off Invoices
 * TODO: This is approximate. Need to double check this is how we want this to be calculated
 */
export const debtCollectionsWrittenOffInvoices: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Debt Collections - Written Off Invoices`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.invoiceEvent.amountRemaining
          .filterBy(
            new BooleanMeasureFilter(true),
            FactTables.invoiceEvent.writtenOff.measure
          )
          .groupBy(FactTables.invoiceEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.invoiceEvent.practitioner.name,
  }),
};

export const debtCollectionsWrittenOffInvoicesResults = [
  MockTimestampedFacts('debtCollectionsWrittenOffInvoicesSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      amountRemaining: randNumber({ min: 250, max: 1500 }),
      event_statusAfter: InvoiceStatus.WrittenOff,
    };
  }),
];

/**
 * Debt Collections
 * Overdue Invoices
 * TODO: This is approximate. Need to double check this is how we want this to be calculated
 */
export const debtCollectionsOverdueInvoices: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Debt Collections - Overdue Invoices`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.invoiceEvent.latestEvent.amountRemaining
          .filterBy(
            new BooleanMeasureFilter(true),
            FactTables.invoiceEvent.latestEvent.isOverdue.measure
          )
          .groupBy(FactTables.invoiceEvent.latestEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.invoiceEvent.latestEvent.practitioner.name,
  }),
};

export const debtCollectionsOverdueInvoicesResults = [
  MockTimestampedFacts('debtCollectionsOverdueInvoicesSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      amountRemaining: randNumber({ min: 250, max: 1500 }),
      isOverdue: true,
    };
  }),
];

/**
 * Debt Collections
 * Active Payment Plans Amount
 * TODO: I'm not sure where this is meant to be pulled from. Payment plans aren't associated with a practitioner, nor do we realy implement them at all
 */
export const debtCollectionsActivePaymentPlansAmount: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Debt Collections - Active Payment Plans Amount`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.paymentPlanEvent.paymentPlan.total
          .groupBy(FactTables.invoiceEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.invoiceEvent.practitioner.name,
  }),
};

export const debtCollectionsActivePaymentPlansAmountResults = [
  MockTimestampedFacts('debtCollectionsActivePaymentPlansAmountSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      treatmentCost: randNumber({ min: 250, max: 1500 }),
    };
  }),
];

/**
 * Time Spend
 * Appointment Durations
 */
export const timeSpendAppointmentDurations: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Time Spend - Apppointment Durations`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.duration
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Minutes)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const timeSpendAppointmentDurationsResults = [
  MockTimestampedFacts('timeSpendAppointmentDurationsSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      duration: randNumber({ min: 5, max: 120 }),
    };
  }),
];

/**
 * Time Spend
 * Gap Durations
 * TODO: Can't accurately calculate Gap time
 */
export const timeSpendGapDurations: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Time Spend - Gap Durations`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.duration
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Minutes)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const timeSpendGapDurationsResults = [
  MockTimestampedFacts('timeSpendGapDurationsSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      duration: randNumber({ min: 5, max: 120 }),
    };
  }),
];

/**
 * Appointment Spend & Appointment Duration
 * Average Appointment Duration
 */
export const averageAppointmentDuration: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Appointment Spend & Appointment Duration - Average Appointment Duration`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.duration
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceByAverage()
          .setFormatter(MeasureFormatter.Minutes)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const averageAppointmentDurationResults = [
  MockTimestampedFacts('averageAppointmentDurationSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      duration: randNumber({ min: 5, max: 120 }),
    };
  }),
];

/**
 * Appointment Spend & Appointment Duration
 * Average Patient Spend
 * TODO: Relies on treatment cost rather than invoice values etc. More than once practitioner could be on an invoice
 */
export const averagePatientSpend: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Appointment Spend & Appointment Duration - Average Patient Spend`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentCost
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceByAverage()
          .setFormatter(MeasureFormatter.Minutes)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const averagePatientSpendResults = [
  MockTimestampedFacts('averagePatientSpendSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      treatmentCost: randNumber({ min: 250, max: 1500 }),
    };
  }),
];

/**
 * Patients Seen
 * Returning Patients
 */
export const patientsSeenReturningPatients: IChartConfig = {
  type: CustomChartType.Pie,
  labels: {
    title: `Patients Seen - Returning Patients`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.existingPatient
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceByCount()
          .setFormatter(MeasureFormatter.Number)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const patientsSeenReturningPatientsResults = [
  MockTimestampedFacts('patientsSeenReturningPatientsSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      isFirstAppointment: false,
    };
  }),
];

/**
 * Patients Seen
 * New Patients
 */
export const patientsSeenNewPatients: IChartConfig = {
  type: CustomChartType.Pie,
  labels: {
    title: `Patients Seen - New Patients`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.newPatient
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceByCount()
          .setFormatter(MeasureFormatter.Number)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const patientsSeenNewPatientsResults = [
  MockTimestampedFacts('patientsSeenNewPatientsSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      isFirstAppointment: true,
    };
  }),
];

/**
 * FTAs & UTAs
 * FTAs
 * TODO: Runs off of cancelled events, who knows if it was fta or uta
 */
export const ftas: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `FTAs & UTAs - FTAs`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.latestEvent.cancelled
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceByCount()
          .setFormatter(MeasureFormatter.Number)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const ftasResults = [
  MockTimestampedFacts('ftasSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      isFirstAppointment: true,
    };
  }),
];

/**
 * FTAs & UTAs
 * UTAs
 * TODO: Runs off of cancelled events, who knows if it was fta or uta
 */
export const utas: IChartConfig = {
  type: CustomChartType.NumberSummary,
  labels: {
    title: `FTAs & UTAs - UTAs`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.latestEvent.cancelled
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceByCount()
          .setFormatter(MeasureFormatter.Number)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const utasResults = [
  MockTimestampedFacts('utasSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      isFirstAppointment: true,
    };
  }),
];

/**
 * Treatment Types Performed
 * Diagnostic Services
 * TODO: Yeah haha, how even
 */
export const treatmentTypesPerformedDiagnosticServices: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Treatment Types Performed - Diagnostic Services`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentPlan.name
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Number)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const treatmentTypesPerformedDiagnosticServicesResults = [
  MockTimestampedFacts('treatmentTypesPerformedDiagnosticServicesSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      isFirstAppointment: true,
    };
  }),
];

/**
 * Treatment Types Performed
 * Preventative, Prophylactic and Bleeching Services
 * TODO: Yeah haha, how even
 */
export const treatmentTypesPerformedPPBS: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Treatment Types Performed - Preventative, Prophylactic and Bleeching Services`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentPlan.name
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Number)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const treatmentTypesPerformedPPBSResults = [
  MockTimestampedFacts('treatmentTypesPerformedPPBSSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      isFirstAppointment: true,
    };
  }),
];

/**
 * Treatment Type Income Distribution
 * Diagnostic Treatment
 */
export const treatmentTypeIncomeDistributionDiagnosticServices: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Treatment Type Income Distribution - Diagnostic Treatment`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentPlan.name
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const treatmentTypeIncomeDistributionDiagnosticServicesResults = [
  MockTimestampedFacts(
    'treatmentTypeIncomeDistributionDiagnosticServicesSeed',
    () => {
      const practitioner = rand(CommonMockValues.staff);
      return {
        practitioner_ref_referenceValue: practitioner.ref,
        practitioner_user_name: practitioner.name,
        isFirstAppointment: true,
      };
    }
  ),
];

/**
 * Treatment Type Income Distribution
 * Preventative, Prophylactic and Bleeching Services
 * TODO: Yeah haha, how even
 */
export const treatmentTypeIncomeDistributionPPBS: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Treatment Type Income Distribution - Preventative, Prophylactic and Bleeching Services`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentPlan.name
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const treatmentTypeIncomeDistributionPPBSResults = [
  MockTimestampedFacts('treatmentTypeIncomeDistributionPPBSSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      isFirstAppointment: true,
    };
  }),
];

/**
 * Proposed Treatment Values
 * Unaccepted Treatments
 * TODO: We don't track value of treatment plan in facts atm. The filter here seem,s strange
 */
export const proposedTreatmentValuesUnacceptedTreatments: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Proposed Treatment Values - Unaccepted Treatments`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.treatmentPlanEvent.latestEvent.count
          .filterBy(
            new ValueDoesNotEqualMeasureFilter(TreatmentPlanStatus.Draft),
            FactTables.treatmentPlanEvent.event.statusAfter.measure
          )
          .filterBy(
            new ValueDoesNotEqualMeasureFilter(TreatmentPlanStatus.Accepted),
            FactTables.treatmentPlanEvent.event.statusAfter.measure
          )
          .filterBy(
            new ValueDoesNotEqualMeasureFilter(TreatmentPlanStatus.InProgress),
            FactTables.treatmentPlanEvent.event.statusAfter.measure
          )
          .filterBy(
            new ValueDoesNotEqualMeasureFilter(TreatmentPlanStatus.Completed),
            FactTables.treatmentPlanEvent.event.statusAfter.measure
          )
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const proposedTreatmentValuesUnacceptedTreatmentsResults = [
  MockTimestampedFacts(
    'proposedTreatmentValuesUnacceptedTreatmentsSeed',
    () => {
      const practitioner = rand(CommonMockValues.staff);
      return {
        practitioner_ref_referenceValue: practitioner.ref,
        practitioner_user_name: practitioner.name,
        isFirstAppointment: true,
      };
    }
  ),
];

/**
 * Treatment Type Income Distribution
 * Preventative, Prophylactic and Bleeching Services
 * TODO: Yeah haha, how even
 */
export const proposedTreatmentValuesAcceptedTreatments: IChartConfig = {
  type: CustomChartType.Bar,
  labels: {
    title: `Proposed Treatment Values - Accepted Treatments`,
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.treatmentPlan.name
          .filterBy(
            new ValueDoesNotEqualMeasureFilter(TreatmentPlanStatus.Draft),
            FactTables.treatmentPlanEvent.event.statusAfter.measure
          )
          .filterBy(
            new ValueDoesNotEqualMeasureFilter(TreatmentPlanStatus.Declined),
            FactTables.treatmentPlanEvent.event.statusAfter.measure
          )
          .filterBy(
            new ValueDoesNotEqualMeasureFilter(TreatmentPlanStatus.Offered),
            FactTables.treatmentPlanEvent.event.statusAfter.measure
          )
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceBySum()
          .setFormatter(MeasureFormatter.Currency)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

export const proposedTreatmentValuesAcceptedTreatmentsResults = [
  MockTimestampedFacts('proposedTreatmentValuesAcceptedTreatmentsSeed', () => {
    const practitioner = rand(CommonMockValues.staff);
    return {
      practitioner_ref_referenceValue: practitioner.ref,
      practitioner_user_name: practitioner.name,
      isFirstAppointment: true,
    };
  }),
];
