export interface IUserWorkspace<T extends string = string> {
  uid: string;
  workspace: string;
  type: T;
}

export interface ICheckOrganisationExists {
  workspace: string;
}

export interface IAuthClaims {
  isManagement: boolean;
  orgUids: string[];
  enabledOrgUids: { [key: string]: boolean };
}

export const LAST_ACTIVE_SYNC_INTERVAL = 1000 * 60 * 60;
