import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { Brand } from '@principle-theorem/principle-core';
import {
  getDocs,
  isSameRef,
  multiSwitchMap,
  undeletedQuery,
  type WithRef,
} from '@principle-theorem/shared';
import { from, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IBrandPractices {
  brand: WithRef<IBrand>;
  practices: WithRef<IPractice>[];
}

export class PracticeSelectorBloc {
  trackByBrand = TrackByFunctions.ref<IBrandPractices>('brand.ref');
  trackByPractice = TrackByFunctions.ref<WithRef<IPractice>>();
  groups$: Observable<IBrandPractices[]>;

  constructor(brands$: Observable<WithRef<IBrand>[]>) {
    this.groups$ = brands$.pipe(
      multiSwitchMap((brand) => this._groupForBrand$(brand))
    );
  }

  compareFn(a: WithRef<IPractice>, b: WithRef<IPractice>): boolean {
    return isSameRef(a, b);
  }

  private _groupForBrand$(brand: WithRef<IBrand>): Observable<IBrandPractices> {
    return from(getDocs(undeletedQuery(Brand.practiceCol(brand)))).pipe(
      map((practices) => ({ brand, practices }))
    );
  }
}
