<ng-container *ngrxLet="event$ as event">
  <div fxLayout="column" fxLayoutGap="6px">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon matTooltip="Date">calendar_today</mat-icon>
      <pr-formatted-event-field
        field="from"
        [event]="event"
        [format]="dateFormat"
       />
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon matTooltip="Time">schedule</mat-icon>
      <span>{{ timeDisplay$ | ngrxPush }}</span>
    </div>

    <div
      *ngrxLet="appointment$ as appointment"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
    >
      <mat-icon matTooltip="Event Name">title</mat-icon>
      <span>
        {{ appointment.treatmentPlan.name }}
        <ng-container *ngIf="appointment.treatmentPlan.treatmentStep.name">
          - {{ appointment.treatmentPlan.treatmentStep.name }}
        </ng-container>
      </span>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-icon matTooltip="Practice">location_on</mat-icon>
      <span>{{ event.practice.name }}</span>
    </div>
  </div>
</ng-container>
