import { WithRef } from '@principle-theorem/shared';
import { IDestinationEntity } from './destination-entity';
import { IPracticeMigration } from './practice-migration';
import { IDestinationEntityHandler } from './destination-entity-handler';

export interface IDestination {
  metadata: IDestinationMetadata;
  configuration: IDestinationConfigurationItem[];
}

export interface IDestinationMetadata {
  name: string;
  description: string;
}

export interface IDestinationConfigurationItem {
  name: string;
  description: string;
  value: string;
}

export interface IDestinationHandler {
  migrationType: string;
  migrationVersion: string;
  entities: IDestinationEntity[];
  entityHandlers: IDestinationEntityHandler[];
  canHandle(destination: IDestination): boolean;
  addEntities(migration: WithRef<IPracticeMigration>): Promise<void>;
}
