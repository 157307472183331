import {
  type CrossfilterGroup,
  getMeasureResults,
  type IChartConfig,
  type IDataBuilder,
  type IGroupedDimension,
  type IMeasureResult,
} from '@principle-theorem/reporting';
import { isObject, isTimestamp, toMoment } from '@principle-theorem/shared';
import crossfilter2, {
  type ComparableValue,
  type Crossfilter,
  type Dimension,
  type NaturallyOrderedValue,
} from 'crossfilter2';
import { compact } from 'lodash';
import * as moment from 'moment-timezone';
import { isMoment, type Moment } from 'moment-timezone';
import { type IDataTransformer } from './interfaces';

export class CrossfilterDataTransformer
  implements IDataTransformer<CrossfilterGroup[]>
{
  transformMeasureResults(results: IMeasureResult[]): CrossfilterGroup[] {
    return compact(
      results.map((result) => {
        const factTable = crossfilter2(result.data);
        let dimension = this._determineDimension(factTable, result.config);

        if (!dimension) {
          dimension = factTable.dimension((_facts): IGroupedDimension => {
            const label = result.measure.measure.metadata.label;
            return {
              label,
              valueOf: () => label,
            };
          });
        }

        if (!result.measure.measure.measure.reduceBy) {
          // eslint-disable-next-line no-console
          console.error('Measure does not have reduceBy', result.measure);
          return;
        }
        return result.measure.measure.measure.reduceBy(dimension);
      })
    );
  }

  async transform(
    config: IChartConfig,
    dataBuilder: IDataBuilder
  ): Promise<CrossfilterGroup[]> {
    const results = await getMeasureResults(config, dataBuilder);
    return this.transformMeasureResults(results);
  }

  protected _determineDimension(
    factTable: Crossfilter<unknown>,
    config: IChartConfig
  ): Dimension<unknown, NaturallyOrderedValue> | undefined {
    if (config.builderData.plottedOverTime) {
      // TODO: Implement accumulator type reporting referenced at
      // https://stackoverflow.com/questions/40619760/dc-js-crossfilter-add-running-cumulative-sum/40620196#40620196
      return factTable.dimension((fact): IGroupedDimension => {
        const timestamp: Moment =
          isObject(fact) &&
          'timestamp' in fact &&
          (isMoment(fact.timestamp) || isTimestamp(fact.timestamp))
            ? toMoment(fact.timestamp)
            : moment();
        let groupedDimension: ComparableValue | undefined;
        if (config.builderData.groupByDimension) {
          groupedDimension = this._getDimensionValue(config, fact);
        }

        if (!groupedDimension) {
          return {
            timestamp,
            label: timestamp.format(),
            valueOf: () => timestamp.format(),
          };
        }

        return {
          timestamp,
          dimension: groupedDimension,
          label: this._getLabel(config, fact),
          valueOf: () => `${timestamp.format()}${String(groupedDimension)}`,
        };
      });
    }

    if (config.builderData.groupByDimension) {
      return factTable.dimension((fact): IGroupedDimension => {
        const value = {
          label: this._getLabel(config, fact),
          colour: this._getColour(config, fact),
          valueOf: () => this._getDimensionValue(config, fact),
        };
        return value;
      });
    }
  }

  protected _getDimensionValue(
    config: IChartConfig,
    fact: unknown
  ): ComparableValue {
    if (!config.builderData.groupByDimension) {
      throw new DimensionNotFoundError();
    }
    try {
      return config.builderData.groupByDimension.groupMeasure.dataAccessor(
        fact
      );
    } catch (error) {
      throw new DimensionNotFoundError();
    }
  }

  protected _getLabel(config: IChartConfig, fact: unknown): string {
    if (
      config.builderData.groupByDimension &&
      config.builderData.groupByDimension.groupMeasure.labelAccessor
    ) {
      return config.builderData.groupByDimension.groupMeasure.labelAccessor(
        fact
      );
    }
    throw new Error(
      `No label accessor defined for ${
        config.builderData.groupByDimension?.metadata.label ?? ''
      }`
    );
  }

  protected _getColour(config: IChartConfig, fact: unknown): string {
    if (
      config.builderData.groupByDimension &&
      config.builderData.groupByDimension.groupMeasure.colourAccessor
    ) {
      return config.builderData.groupByDimension.groupMeasure.colourAccessor(
        fact
      );
    }
    return '';
  }
}

export class DimensionNotGivenError extends Error {}

export class DimensionNotFoundError extends Error {}
