import { type IXSLXImport } from '@principle-theorem/shared';
import { type Row } from 'exceljs';
import {
  FEE_SCHEDULE_TO_PATIENT_TAG_HEADERS,
  type IFeeScheduleToPatientTagToXSLX,
} from './fee-schedule-to-patient-tag-to-xlsx';

export class XSLXToFeeScheduleToPatientTag
  implements IXSLXImport<IFeeScheduleToPatientTagToXSLX>
{
  headers = FEE_SCHEDULE_TO_PATIENT_TAG_HEADERS;

  translate(row: Row): IFeeScheduleToPatientTagToXSLX {
    return {
      sourceId: row.getCell('sourceId').value?.toString() ?? '',
      sourceName: row.getCell('sourceName').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IFeeScheduleToPatientTagToXSLX['mapTo'],
    };
  }
}
