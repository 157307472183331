import { Validators } from '@angular/forms';
import { IGapSearch } from '@principle-theorem/ng-principle-shared';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { DEFAULT_SCHEDULE_SUMMARY_PERIOD } from '@principle-theorem/principle-core';
import {
  IScheduleRange,
  IStaffer,
  IGapDuration,
} from '@principle-theorem/principle-core/interfaces';
import {
  isTime24hrType,
  ITimePeriod,
  Time24hrType,
  WithRef,
} from '@principle-theorem/shared';
import moment from 'moment-timezone';

export class GapSearchFormGroup extends TypedFormGroup<IGapSearch> {
  hours: number[] = [];
  minutes: number[] = [];

  minDate = moment().startOf('day');
  maxDate = moment()
    .add(DEFAULT_SCHEDULE_SUMMARY_PERIOD, 'months')
    .endOf('day');

  constructor() {
    super({
      dateRange: new TypedFormGroup<ITimePeriod>({
        from: new TypedFormControl(moment().startOf('day'), [
          Validators.required,
        ]),
        to: new TypedFormControl(moment().endOf('day'), [Validators.required]),
      }),
      timeRange: new TypedFormGroup<IScheduleRange>({
        from: new TypedFormControl<Time24hrType>(undefined).withGuard(
          isTime24hrType
        ),
        to: new TypedFormControl<Time24hrType>(undefined).withGuard(
          isTime24hrType
        ),
      }),
      duration: new TypedFormGroup<IGapDuration>({
        hours: new TypedFormControl<number>(undefined),
        minutes: new TypedFormControl<number>(undefined),
      }),
      practitioners: new TypedFormControl<WithRef<IStaffer>[]>([]),
    });

    this._populateHours();
    this._populateMinutes();
  }

  updateDateRange(dateRange: ITimePeriod): void {
    this.controls.dateRange.patchValue(dateRange, { emitEvent: false });
  }

  private _populateHours(): void {
    for (let hour = 1; hour < 24; hour++) {
      this.hours.push(hour);
    }
  }

  private _populateMinutes(): void {
    for (let minute = 1; minute < 12; minute++) {
      this.minutes.push(minute * 5);
    }
  }
}
