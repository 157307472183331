import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BridgeCommandsService } from '@principle-theorem/ng-principle-bridge-cloud';
import {
  CloudToBridgeCommand,
  IBaseCommand,
} from '@principle-theorem/principle-bridge-core';
import { BridgeDevice } from '@principle-theorem/principle-core';
import { IBridgeDevice } from '@principle-theorem/principle-core/interfaces';
import { IAttachment, WithRef, all$ } from '@principle-theorem/shared';
import { Observable } from 'rxjs';

export interface IViewBridgeLogsDialogData {
  device: WithRef<IBridgeDevice>;
}

@Component({
    selector: 'pr-view-bridge-logs-dialog',
    templateUrl: './view-bridge-logs-dialog.component.html',
    styleUrls: ['./view-bridge-logs-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ViewBridgeLogsDialogComponent {
  logs$: Observable<IAttachment[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IViewBridgeLogsDialogData,
    private _snackBar: MatSnackBar,
    private _bridgeCommands: BridgeCommandsService
  ) {
    this.logs$ = all$(BridgeDevice.fileCol(data.device));
  }

  async getLogs(device: WithRef<IBridgeDevice>): Promise<void> {
    const command: IBaseCommand<CloudToBridgeCommand.GetLogs, object> = {
      type: CloudToBridgeCommand.GetLogs,
      data: {},
    };

    await this._bridgeCommands.sendCommand(command, device.ref);
    this._snackBar.open('Logs requested successfully');
  }
}
