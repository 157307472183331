<table class="w-full" mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let terminal">{{ terminal.name }}</td>
  </ng-container>

  <ng-container matColumnDef="practitioner">
    <th mat-header-cell *matHeaderCellDef>Associated Practitioner</th>
    <td mat-cell *matCellDef="let terminal">
      @if (terminal.practitionerRef) {
        <pr-staffer-display
          *ngIf="
            terminal.practitionerRef
              | map: globalStore.getStaffer$ : globalStore
              | async as staffer
          "
          [staffer]="staffer"
        />
      } @else {
        <span class="mat-caption opacity-50">No Practitioner Associated</span>
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="lastActive">
    <th mat-header-cell *matHeaderCellDef class="w-44">Last Paired</th>
    <td mat-cell *matCellDef="let terminal" class="mat-caption w-44">
      @if (terminal.lastPaired; as lastPaired) {
        {{ lastPaired | moment | amDateFormat: dateFormat }}
      } @else {
        <span class="text-red-500">Never Paired</span>
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef class="w-44">
      <button
        class="w-full"
        mat-flat-button
        color="primary"
        (click)="pairNewTerminal()"
      >
        Pair New Terminal
      </button>
    </th>
    <td mat-cell *matCellDef="let terminal" class="w-44 !text-right">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <ng-template matMenuContent>
          <button mat-menu-item (click)="editTerminalPair(terminal)">
            <mat-icon color="accent">edit</mat-icon> Edit
          </button>
          <button mat-menu-item (click)="deleteTerminalPair(terminal)">
            <mat-icon color="warn">delete</mat-icon> Delete
          </button>
          <button mat-menu-item (click)="pairTerminal(terminal)">
            <mat-icon color="accent">sync</mat-icon> Pair Terminal
          </button>
        </ng-template>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
