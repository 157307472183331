import {
  WithRef,
  type ArchivedDocument,
  type DocumentReference,
  type INamedDocument,
  type ISoftDelete,
  type Timestamp,
} from '@principle-theorem/shared';
import { type IInvoice } from '../invoice/invoice';
import { IPractice } from '../practice/practice';
import { type IStaffer } from '../staffer/staffer';
import { ITreatmentCategory } from '../treatment-category';
import { ITransaction } from '../transaction/transaction';
import { IUsedAccountCredit } from '../transaction/account-credit-transaction-extended-data';
import { Moment } from 'moment-timezone';

export enum AccountCreditCollection {
  AccountCreditHistory = 'accountCreditHistory',
}

export enum AccountCreditType {
  Deposit = 'deposit',
  Refund = 'refund',
  Overpayment = 'overpayment',
  /**
    @deprecated
  */
  Manual = 'manual',
}

export interface IAccountCredit extends ISoftDelete {
  description: string;
  amount: number;
  type: AccountCreditType;
  reference?: string;
  invoice?: DocumentReference<IInvoice>;
  practiceRef?: DocumentReference<IPractice>;
  depositUid?: string;
  reservedFor: {
    practitioner?: INamedDocument<IStaffer>;
    treatment?: INamedDocument[];
    treatmentCategory?: DocumentReference<ITreatmentCategory>;
  };
  used: number;
  issued?: Timestamp;
  amendmentOf?: DocumentReference<ArchivedDocument<IAccountCredit>>;
}

export interface IAccountCreditAttributed {
  accountCredit: WithRef<IAccountCredit>;
  amount: number;
}

export interface IPayWithCreditResults {
  remaining: number;
  alteredCredits: IAccountCreditAttributed[];
}

export interface IAccountCreditRefunded {
  accountCreditRef: DocumentReference<IAccountCredit>;
  amount: number;
  refundedAt?: Timestamp;
}

export interface IRefundCreditResults {
  remaining: number;
  alteredCredits: IAccountCreditRefunded[];
}

export interface ISplitAccountCreditFormData {
  description: string;
  amount: number;
  createdAt: Moment;
  practitioner?: INamedDocument<IStaffer>;
  treatmentCategory?: DocumentReference<ITreatmentCategory>;
}

export interface ICreditUsedData {
  credit: WithRef<IAccountCredit>;
  invoice: WithRef<IInvoice>;
  transaction: WithRef<ITransaction>;
  usedAccountCredit: IUsedAccountCredit;
}
