<div class="flex flex-col gap-4" *ngIf="attachments$ | async as attachments">
  <div class="flex flex-col gap-2" *ngIf="attachments.length">
    <div
      class="flex items-center"
      *ngFor="let attachment of attachments; trackBy: trackByAttachment"
    >
      <mat-icon class="opacity-50">folder</mat-icon>
      <div class="flex min-w-0 grow flex-col px-4">
        <a
          class="truncate"
          target="_blank"
          [href]="attachment | map : getAttachmentUrl : this | async"
          >{{ attachment.name | removeStorageUid }}</a
        >
        <small class="mat-caption">{{ attachment.lastModified | date }}</small>
      </div>

      <div>
        <button
          mat-icon-button
          color="warn"
          *ngIf="editable"
          (click)="removeAttachment(attachment)"
        >
          <mat-icon>remove_circle_outline</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <pt-file-upload-to-storage
    *ngIf="editable"
    [path]="uploadPath$ | async"
    (uploadStarted)="startedUpload($event)"
  />
</div>
