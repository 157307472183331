import {
  type IQuestionCallbackData,
  type IReceiptCallbackData,
  type StatusMessageCallbackData,
} from './tyro-callbacks';
import {
  type IBulkBillEasyclaimRequestParams,
  type IEasyclaimCompleteCallback,
  type IEasyclaimRequestParams,
} from './tyro-easyclaim';
import {
  type HealthPointReconciliationReportResponse,
  type IHealthPointCancelClaimRequestParams,
  type IHealthPointClaimRequestParams,
  type IHealthPointReconciliationReportRequestParams,
  type IHealthPointTransactionSuccessBaseCallbackData,
  type IHealthPointTransactionCompleteCallbackData,
} from './tyro-health-point';
import { type PairTerminalResponse } from './tyro-pair-terminal';
import {
  type IPurchaseRequestParams,
  type IPurchaseTransactionCompleteCallbackData,
  type IRefundRequestParams,
} from './tyro-purchase';
import {
  type IReconciliationReportRequestParams,
  type ReconciliationReportResponse,
} from './tyro-reconciliation';

export interface IPosProductData {
  /**
   * Name of your company
   */
  posProductVendor: string;

  /**
   * Name of your product
   */
  posProductName: string;

  /**
   * Version of your product
   */
  posProductVersion: string;
}

export interface IHealthPointClaimCallbacks {
  transactionCompleteCallback: (
    data: IHealthPointTransactionCompleteCallbackData
  ) => unknown;
  questionCallback?: (data: IQuestionCallbackData) => unknown;
  statusMessageCallback?: (data: StatusMessageCallbackData) => unknown;
}

export interface IPurchaseCallbacks {
  questionCallback: (data: IQuestionCallbackData) => unknown;
  statusMessageCallback: (data: StatusMessageCallbackData) => unknown;
  transactionCompleteCallback: (
    data: IPurchaseTransactionCompleteCallbackData
  ) => unknown;
  receiptCallback?: (data: IReceiptCallbackData) => unknown;
}

export type IClientWithUI = {
  new (apiKey: string, posProductData: IPosProductData): IClientWithUI;

  initiateHealthPointClaim(
    requestParams: IHealthPointClaimRequestParams,
    transactionCallbacks: IHealthPointClaimCallbacks
  ): unknown;

  initiateHealthPointRebateEstimate(
    requestParams: IHealthPointClaimRequestParams,
    transactionCallbacks: IHealthPointClaimCallbacks
  ): unknown;

  initiatePurchase(
    requestParams: IPurchaseRequestParams,
    transactionCallbacks: IPurchaseCallbacks
  ): unknown;

  initiateBulkBillEasyclaim(
    requestParams: IBulkBillEasyclaimRequestParams,
    transactionCallbacks: {
      transactionCompleteCallback: (
        data: IEasyclaimCompleteCallback
      ) => unknown;
    }
  ): unknown;

  initiateFullyPaidEasyclaim(
    requestParams: IEasyclaimRequestParams,
    transactionCallbacks: {
      transactionCompleteCallback: (
        data: IEasyclaimCompleteCallback
      ) => unknown;
    }
  ): unknown;

  initiatePartPaidEasyclaim(
    requestParams: IEasyclaimRequestParams,
    transactionCallbacks: {
      transactionCompleteCallback: (
        data: IEasyclaimCompleteCallback
      ) => unknown;
    }
  ): unknown;

  cancelHealthPointClaim(
    requestParams: IHealthPointCancelClaimRequestParams,
    transactionCallbacks: {
      transactionCompleteCallback: (
        data: IHealthPointTransactionSuccessBaseCallbackData
      ) => unknown;
    }
  ): unknown;

  initiateRefund(
    requestParams: IRefundRequestParams,
    transactionCallbacks: {
      transactionCompleteCallback: (
        data: IPurchaseTransactionCompleteCallbackData
      ) => unknown;
    }
  ): unknown;

  reconciliationReport(
    requestParams: IReconciliationReportRequestParams,
    responseReceivedCallback: (data: ReconciliationReportResponse) => unknown
  ): unknown;

  healthpointReconciliationReport(
    requestParams: IHealthPointReconciliationReportRequestParams,
    responseReceivedCallback: (
      data: HealthPointReconciliationReportResponse
    ) => unknown
  ): unknown;

  pairTerminal(
    mid: string,
    tid: string,
    responseReceivedCallback: (data: PairTerminalResponse) => unknown
  ): unknown;

  /**
   * @throws Error If there is no current transaction
   */
  cancelCurrentTransaction(): void;
};

export interface ITyroNamespace {
  IClientWithUI: IClientWithUI;
}
