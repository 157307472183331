import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  type IOptionGroup,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  ServiceCodeGroup,
  ServiceProvider,
  TreatmentConfiguration,
} from '@principle-theorem/principle-core';
import {
  type IServiceCode,
  type IServiceCodeGroup,
  type IServiceSmartGroup,
  isServiceSmartGroup,
  type ITreatmentConfiguration,
  ServiceCodeGroupType,
  SMART_GROUPS,
  IServiceProvider,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { combineLatest, type Observable, of, ReplaySubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'pr-item-code-configuration',
  templateUrl: './item-code-configuration.component.html',
  styleUrls: ['./item-code-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemCodeConfigurationComponent {
  treatmentConfig$ = new ReplaySubject<WithRef<ITreatmentConfiguration>>(1);
  trackBySmartGroup = TrackByFunctions.uniqueId<IServiceSmartGroup>();
  trackByCode = TrackByFunctions.ref<IServiceCode>();
  trackByGroup = TrackByFunctions.field<IOptionGroup<IServiceCode>>('name');
  itemCodeSelector: TypedFormControl<
    string | IServiceCode | IServiceSmartGroup
  > = new TypedFormControl();
  filteredServiceCodes$: Observable<IOptionGroup<IServiceCode>[]>;
  filteredSmartGroups$: Observable<IServiceSmartGroup[]>;

  @Input()
  set treatmentConfig(treatmentConfig: WithRef<ITreatmentConfiguration>) {
    if (treatmentConfig) {
      this.treatmentConfig$.next(treatmentConfig);
    }
  }

  constructor(
    private _snackBar: MatSnackBar,
    org: OrganisationService
  ) {
    const searchValue$ = this.itemCodeSelector.valueChanges.pipe(startWith(''));

    const serviceProviders$ = org.organisation$.pipe(
      filterUndefined(),
      map((organisation) =>
        ServiceProvider.providersByRegion(organisation.region)
      )
    );

    this.filteredSmartGroups$ = combineLatest([
      of(SMART_GROUPS),
      searchValue$,
    ]).pipe(
      map(([smartGroups, searchValue]) => {
        const search: string = isString(searchValue) ? searchValue : '';
        return this._filterSmartGroups(smartGroups, search);
      })
    );

    this.filteredServiceCodes$ = combineLatest([
      serviceProviders$,
      searchValue$,
    ]).pipe(
      map(([serviceProviders, searchValue]) => {
        const search = isString(searchValue) ? searchValue : '';
        return this._filterServiceCodes(search, serviceProviders);
      }),
      map((filteredGroups) =>
        filteredGroups.filter((provider) => provider.options.length)
      )
    );
  }

  async addGroup(): Promise<void> {
    const treatmentConfig = await snapshot(this.treatmentConfig$);
    await this.save({
      serviceCodeGroups: [
        ...treatmentConfig.serviceCodeGroups,
        this._getNewGroup(),
      ],
    });
  }

  async add(codeInfo: IServiceCode | IServiceSmartGroup): Promise<void> {
    const treatmentConfig = await snapshot(this.treatmentConfig$);

    this.clearInput();

    if (isServiceSmartGroup(codeInfo)) {
      return this.save({
        serviceCodeSmartGroups: [
          ...treatmentConfig.serviceCodeSmartGroups,
          codeInfo,
        ],
      });
    }

    await this.save({
      serviceCodeGroups: TreatmentConfiguration.addServiceCodeToGroup(
        treatmentConfig.serviceCodeGroups,
        codeInfo,
        codeInfo.type
      ),
    });
  }

  clearInput(): void {
    this.itemCodeSelector.reset();
  }

  async deleteSmartEntry(entry: IServiceSmartGroup): Promise<void> {
    const treatmentConfig = await snapshot(this.treatmentConfig$);
    await this.save({
      serviceCodeSmartGroups: TreatmentConfiguration.removeSmartGroup(
        treatmentConfig.serviceCodeSmartGroups,
        entry
      ),
    });
  }

  async updateGroups(serviceCodeGroups: IServiceCodeGroup[]): Promise<void> {
    await this.save({
      serviceCodeGroups,
    });
  }

  async save(
    itemCodes: Partial<
      Pick<
        ITreatmentConfiguration,
        'serviceCodeGroups' | 'serviceCodeSmartGroups'
      >
    >
  ): Promise<void> {
    const treatmentConfig = await snapshot(this.treatmentConfig$);
    await patchDoc(treatmentConfig.ref, itemCodes);
    this._snackBar.open('Treatment configuration saved');
  }

  private _filterServiceCodes(
    search: string,
    serviceProviders: IServiceProvider[]
  ): IOptionGroup<IServiceCode>[] {
    const filterValue = search.toLowerCase();
    return serviceProviders.map((serviceProvider) => ({
      name: `${serviceProvider.label} Codes`,
      options: Object.values(serviceProvider.items)
        .filter((serviceCode) => !serviceCode.deleted)
        .filter((item) => {
          return `${item.code} ${item.title}`
            .toLowerCase()
            .includes(filterValue);
        })
        .map((code) => ({
          ...code,
          type: serviceProvider.type,
        })),
      skipFilter: false,
    }));
  }

  private _filterSmartGroups(
    smartGroup: IServiceSmartGroup[],
    search: string
  ): IServiceSmartGroup[] {
    const filterValue = search.toLowerCase();

    return smartGroup.filter((item: IServiceSmartGroup) =>
      `${item.uid} - ${item.title}`.toLowerCase().includes(filterValue)
    );
  }

  private _getNewGroup(
    type: ServiceCodeGroupType = ServiceCodeGroupType.Optional
  ): IServiceCodeGroup {
    return ServiceCodeGroup.init({ type, serviceCodes: [] });
  }
}
