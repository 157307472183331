import { initVersionedSchema } from '@principle-theorem/editor';
import {
  Note,
  Patient,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  Gender,
  IDestinationEntityJobRunOptions,
  ITranslationMap,
  PatientStatus,
  SourceEntityRecordStatus,
  type FailedDestinationEntityRecord,
  type IBrand,
  type IContactNumber,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IFeeSchedule,
  type IGetRecordResponse,
  type IHealthFundCard,
  type IMedicareCard,
  type IPatient,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_FORMAT,
  getDoc,
  getError,
  snapshotCombineLatest,
  toISODate,
  toNamedDocument,
  type INamedDocument,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import * as he from 'he';
import * as moment from 'moment-timezone';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  BasePatientDestinationEntity,
  PATIENT_RESOURCE_TYPE,
} from '../../../destination/entities/patient';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { FEE_SCHEDULE_RESOURCE_TYPE } from '../../../mappings/fee-schedules';
import { PracticeMigration } from '../../../practice-migrations';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientSourceEntity,
  PraktikaPatientStatus,
  type IPraktikaPatient,
  type IPraktikaPatientFilters,
  type IPraktikaPatientTranslations,
} from '../../source/entities/patient';
import { PraktikaStafferMappingHandler } from '../mappings/staff';
import { StafferDestinationEntity } from './staff';
import { compact } from 'lodash';

export const PATIENT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_RESOURCE_TYPE,
    label: 'Patients',
    description: '',
  },
});

export interface IPatientJobData {
  sourcePatient: IGetRecordResponse<
    IPraktikaPatient,
    IPraktikaPatientTranslations,
    IPraktikaPatientFilters
  >;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  brand: WithRef<IBrand>;
}

export interface IPatientMigrationData {
  sourcePatientId: string;
  patient: IPatient;
  createdAt?: Timestamp;
}

export class PatientDestinationEntity extends BasePatientDestinationEntity<IPraktikaPatient> {
  destinationEntity = PATIENT_DESTINATION_ENTITY;
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
  };

  override destinationEntities = {
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new PraktikaStafferMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMapHandler),
      translationMapHandler.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const brand$ = PracticeMigration.brand$(migration);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([staff$, brand$]),
    ]).pipe(
      map(([patientRecords, [staff, brand]]) =>
        patientRecords.map((sourcePatient) => ({
          sourcePatient,
          staff,
          brand,
        }))
      )
    );
  }

  async buildMigrationData(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientJobData
  ): Promise<
    | IPatientMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    if (data.sourcePatient.record.status === SourceEntityRecordStatus.Invalid) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        'Source patient is invalid'
      );
    }

    const patient = data.sourcePatient.data.data;
    const sourcePatientId = this.sourceEntities.patients
      .getSourceRecordId(patient)
      .toString();

    try {
      return {
        sourcePatientId,
        createdAt: data.sourcePatient.data.translations.dateJoined,
        patient: Patient.init({
          name: getName(patient),
          email: patient.patient_email_personal,
          contactNumbers: getPhone(patient),
          address: getAddress(patient),
          dateOfBirth: patient.patient_dob
            ? toISODate(moment(patient.patient_dob, ISO_DATE_FORMAT))
            : undefined,
          gender: getGender(patient),
          status: getStatus(patient),
          // tags: await getTags(brand, record),
          medicareCard: getMedicareCard(patient),
          referenceId: sourcePatientId,
          healthFundCard: getHealthcareCard(patient),
          preferredDentist: patient.patient_preferredproviderid
            ? await getPreferredProvider(
                patient.patient_preferredproviderid.toString(),
                data.staff
              )
            : undefined,
          preferredFeeSchedule: patient.patient_defaultfeescheduleid
            ? await getDefaultFeeSchedule(
                patient.patient_defaultfeescheduleid.toString(),
                translationMap
              )
            : undefined,
          notes: patient.patient_notes
            ? [
                Note.init({
                  content: initVersionedSchema(
                    he.decode(patient.patient_notes)
                  ),
                  pinned: true,
                }),
              ]
            : [],
        }),
      };
    } catch (error) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        getError(error)
      );
    }
  }
}

// async function getTags(
//   brand: WithRef<IBrand>,
//   record: IGetRecordResponse<IPraktikaPatient, IPraktikaPatientTranslations>
// ): Promise<INamedDocument<ITag>[]> {
//   const badPatientTagName = 'Bad Patient';
//   const noRecallTagName = 'No Recall';

//   const badPatientTag = await snapshot(
//     find$(Brand.patientTagCol(brand), (query) =>
//       query.where('name', '==', badPatientTagName)
//     )
//   );

//   const noRecallTag = await snapshot(
//     find$(Brand.patientTagCol(brand), (query) =>
//       query.where('name', '==', noRecallTagName)
//     )
//   );

//   if (!badPatientTag || !noRecallTag) {
//     throw new Error(`Tags are missing for "Bad Patient"/"No Recall"`);
//   }

//   const tags: INamedDocument<ITag>[] = [];

//   if (record.data.data.patient_alerts.isBadPatient) {
//     tags.push(toNamedDocument(badPatientTag));
//   }

//   if (record.data.data.patient_nonrecall) {
//     tags.push(toNamedDocument(noRecallTag));
//   }

//   return tags;
// }

function getGender(patient: IPraktikaPatient): Gender {
  switch (patient.patient_gender) {
    case 'F':
      return Gender.Female;
    case 'M':
      return Gender.Male;
    case 'O':
      return Gender.Other;
    default:
      return Gender.NotSpecified;
  }
}

function getStatus(patient: IPraktikaPatient): PatientStatus {
  switch (patient.patient_statusid) {
    case PraktikaPatientStatus.Current:
    case PraktikaPatientStatus.Guarantor:
      return PatientStatus.Active;
    case PraktikaPatientStatus.Deceased:
      return PatientStatus.Deceased;
    case PraktikaPatientStatus.Suspended:
    case PraktikaPatientStatus.Deleted:
      return PatientStatus.Blocked;
    case PraktikaPatientStatus.Inactive:
    default:
      return PatientStatus.Inactive;
  }
}

function getAddress(patient: IPraktikaPatient): string {
  const addressFields = compact([
    patient.patient_address_street?.trim(),
    patient.patient_address_suburb?.trim(),
    patient.patient_address_state?.trim(),
    patient.patient_address_postcode?.toString().trim(),
  ]);

  if (!addressFields.length) {
    return '';
  }

  return addressFields.join(', ');
}

function getPhone(patient: IPraktikaPatient): IContactNumber[] {
  const praktikaContactNumbers = [
    { label: 'mobile', number: patient.patient_phone_mobile },
    { label: 'home', number: patient.patient_phone_home },
    { label: 'work', number: patient.patient_phone_work },
  ];
  return praktikaContactNumbers.filter(
    (contactNumbers) => contactNumbers.number
  );
}

export function getName(patient: IPraktikaPatient): string {
  const preferredName = patient.patient_preferredname?.trim() ?? '';

  if (!preferredName || preferredName === patient.patient_firstname) {
    return he.decode(
      `${patient.patient_firstname} ${patient.patient_lastname}`.trim()
    );
  }
  return he.decode(
    `${patient.patient_firstname} (${preferredName}) ${patient.patient_lastname}`.trim()
  );
}

function getMedicareCard(patient: IPraktikaPatient): IMedicareCard {
  return {
    number: patient.patient_medicare_number ?? '',
    subNumerate: patient.patient_medicare_subnumerate ?? '',
  };
}

function getHealthcareCard(patient: IPraktikaPatient): IHealthFundCard {
  return {
    fundCode: patient.patient_healthfund_name ?? '',
    membershipNumber: patient.patient_healthfund_membershipnumber ?? '',
    memberNumber: patient.patient_healthfund_membernumber ?? '',
  };
}

async function getPreferredProvider(
  preferredProviderId: string,
  staff: WithRef<ITranslationMap<IStaffer>>[]
): Promise<INamedDocument<IStaffer> | undefined> {
  const practitionerMap = staff.find(
    (staffer) => staffer.sourceIdentifier === preferredProviderId
  );

  if (!practitionerMap?.destinationIdentifier) {
    return;
  }

  return stafferToNamedDoc(await getDoc(practitionerMap.destinationIdentifier));
}

async function getDefaultFeeSchedule(
  defaultFeeScheduleId: string,
  translationMap: TranslationMapHandler
): Promise<INamedDocument<IFeeSchedule> | undefined> {
  const feeScheduleMap = await translationMap.getDestination<IFeeSchedule>(
    defaultFeeScheduleId,
    FEE_SCHEDULE_RESOURCE_TYPE
  );

  if (!feeScheduleMap) {
    return;
  }

  return toNamedDocument(await getDoc(feeScheduleMap));
}
