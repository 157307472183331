import { Action, createReducer, on } from '@ngrx/store';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import * as CurrentScopeActions from '../actions/current-scope.actions';

export const CURRENT_SCOPE_KEY = 'currentScope';

export interface ICurrentScopeState {
  brandSlug: string | undefined;
  practiceSlug: string | undefined;
  brand: SerialisedData<WithRef<IBrand>> | undefined;
  practice: SerialisedData<WithRef<IPractice>> | undefined;
  error?: string;
}

const initialState: ICurrentScopeState = {
  brandSlug: undefined,
  practiceSlug: undefined,
  brand: undefined,
  practice: undefined,
};

const reducer = createReducer(
  initialState,

  on(CurrentScopeActions.setBrandSlug, (state, { slug }) => ({
    ...state,
    brandSlug: slug,
  })),

  on(CurrentScopeActions.clearBrandSlug, (state) => ({
    ...state,
    brandSlug: undefined,
  })),

  on(
    CurrentScopeActions.loadBrandSuccess,
    CurrentScopeActions.loadBrandFromResolver,
    (state, { brand }) => ({
      ...state,
      brand,
    })
  ),

  on(CurrentScopeActions.clearBrand, (state) => ({
    ...state,
    brand: undefined,
  })),

  on(CurrentScopeActions.setPracticeSlug, (state, { slug }) => ({
    ...state,
    practiceSlug: slug,
  })),

  on(CurrentScopeActions.clearPracticeSlug, (state) => ({
    ...state,
    practiceSlug: undefined,
  })),

  on(
    CurrentScopeActions.loadPracticeSuccess,
    CurrentScopeActions.loadPracticeFromResolver,
    (state, { practice }) => ({
      ...state,
      practice,
    })
  ),

  on(CurrentScopeActions.clearPractice, (state) => ({
    ...state,
    practice: undefined,
  })),

  on(
    CurrentScopeActions.loadBrandFail,
    CurrentScopeActions.loadPracticeFail,
    (state, { error }) => ({
      ...state,
      error,
    })
  )
);

export function currentScopeReducer(
  state: ICurrentScopeState | undefined,
  action: Action
): ICurrentScopeState {
  return reducer(state, action);
}
