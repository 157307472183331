<form class="flex flex-col gap-4" [formGroup]="form">
  <mat-form-field subscriptSizing="dynamic">
    <mat-label>Label</mat-label>
    <input #autoFocusElement matInput formControlName="label" />
  </mat-form-field>

  <div formArrayName="options" class="flex flex-col gap-4">
    @for (option of optionsCtrl.controls; track option; let index = $index) {
      <div class="flex flex-row gap-2">
        <mat-form-field subscriptSizing="dynamic" class="flex grow">
          <mat-label>Option: {{ index + 1 }}</mat-label>
          <input matInput [formControlName]="index" />
        </mat-form-field>

        <button
          mat-icon-button
          type="button"
          color="warn"
          (click)="deleteOption(index)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    }
    <button mat-stroked-button type="button" (click)="addOption('New Option')">
      <mat-icon>add</mat-icon>
      Add Option
    </button>
  </div>

  <mat-slide-toggle
    class="px-2"
    labelPosition="before"
    formControlName="required"
  >
    Required
  </mat-slide-toggle>
</form>
