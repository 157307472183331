import {
  type ICustomMappingSourceOption,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { type IReffable } from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import {
  FeeScheduleMappingHandler,
  IBaseMigrationFeeSchedule,
} from '../../../mappings/fee-schedules';
import {
  FeeScheduleSourceEntity,
  IPraktikaFeeSchedule,
} from '../../source/entities/fee-schedule';

export class PraktikaFeeScheduleMappingHandler extends FeeScheduleMappingHandler<
  IPraktikaFeeSchedule,
  FeeScheduleSourceEntity
> {
  override sourceEntity = new FeeScheduleSourceEntity();
  translateFn = (record: IPraktikaFeeSchedule): IBaseMigrationFeeSchedule => ({
    ...record,
  });

  override async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const entity = new FeeScheduleSourceEntity();
    const records = await entity.getRecords$(migration, 1000).toPromise();
    const options = records
      .map((record) => record.data.data)
      .map((record) => ({
        label: `${record.name} ${record.practice_id ?? ''}`,
        value: entity.getSourceRecordId(record).toString(),
      }));

    return sortBy(options, 'label');
  }
}
