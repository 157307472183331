import { Money } from '@principle-theorem/accounting';
import { isObject } from '@principle-theorem/shared';
import { fromXMLPayload } from '@principle-theorem/tyro';
import { get, isArray, isString, sum } from 'lodash';
import { ElementCompact } from 'xml-js';

/**
 * PAID: Approved by Medicare.
 * NAC: Not accepted and no benefit is payable.
 * PEND: For “pended” patient claims where the claimant has not paid or only
 * partially paid the accounts, Medicare Australia will issue a statement and
 * a cheque made in favour of the Medical Practitioner where a benefit is
 * payable, and forward to the claimant's address recorded by Medicare
 * Australia.
 * PDVC: Medicare Australia will issue a statement and the Pay Doctor via
 * Claimant (PDVC) cheque, which is mailed to the claimant's address recorded
 * by Medicare Australia. This would be the case when a partly paid or a
 * unpaid patient claim is submitted.
 */
type MedicareAcceptanceType = 'PAID' | 'NAC' | 'PEND' | 'PDVC';

export interface IEasyclaimMedicareDetails {
  medicareCardNum: string;
  subnumerate: string;
}

export class TyroEasyclaimPayload {
  static getRootElement(isBulkBill: boolean): string {
    return isBulkBill
      ? 'ns1:bulkBilleClaimingResponse'
      : 'ns1:patienteClaimingResponse';
  }

  static isMedicareAcceptanceType(
    data: unknown
  ): data is MedicareAcceptanceType {
    const allowed = ['PAID', 'NAC', 'PEND', 'PDVC'];
    return isString(data) && allowed.includes(data);
  }

  static getMedicareAcceptanceType(
    xml: string
  ): MedicareAcceptanceType | undefined {
    const data = fromXMLPayload(xml);
    const result = get(
      data,
      'ns1:patienteClaimingResponse.claim._attributes.medicareAcceptanceType'
    ) as unknown;
    return this.isMedicareAcceptanceType(result) ? result : undefined;
  }

  static getErrorCode(payload: ElementCompact): string | undefined {
    return this.getClaimErrorCode(payload) ?? this.getServiceErrorCode(payload);
  }

  static getClaimErrorCode(payload: ElementCompact): string | undefined {
    const claim: unknown = get(payload, 'ns1:patienteClaimingResponse.claim');
    const isError = get(claim, '_attributes.assessmentStatus') === 'ERR';
    if (!isError) {
      return;
    }
    const result: unknown = get(claim, 'assessmentError._attributes.code');
    if (isString(result)) {
      return result;
    }
  }

  static getServiceErrorCode(payload: ElementCompact): string | undefined {
    const service: unknown = get(
      payload,
      'ns1:patienteClaimingResponse.claim.voucher.service'
    );
    const isError = get(service, '_attributes.assessmentStatus') === 'ERR';
    if (!isError) {
      return;
    }
    const result: unknown = get(
      service,
      'assessmentExplanation._attributes.code'
    );
    if (isString(result)) {
      return result;
    }
  }

  static getBulkBillBenefitAmount(xml: string): number {
    return this.getBenefitAmount(
      xml,
      TyroEasyclaimPayload.getRootElement(true),
      'benefitAssigned'
    );
  }

  static getPartPaidBenefitAmount(xml: string): number {
    return this.getBenefitAmount(
      xml,
      TyroEasyclaimPayload.getRootElement(false),
      'benefitAmount'
    );
  }

  static getBenefitAmount(
    xml: string,
    rootElem: string,
    serviceAttribute: string
  ): number {
    const payload = fromXMLPayload(xml);
    const service: unknown = get(payload, `${rootElem}.claim.voucher.service`);
    const services = isArray(service) ? service : [service];
    const benefits = services
      .map((item) =>
        isObject(item)
          ? get(item, `_attributes.${serviceAttribute}`)
          : undefined
      )
      .filter((value): value is string => isString(value))
      .map((value) => parseInt(value, 10));
    return Money.fromCents(sum(benefits));
  }

  static getMedicareDetails(
    xml: string,
    isBulkBill: boolean
  ): IEasyclaimMedicareDetails | undefined {
    const rootElem = this.getRootElement(isBulkBill);
    const payload = fromXMLPayload(xml);

    const patientAttributes: unknown = get(
      payload,
      `${rootElem}.claim.patient._attributes`
    );
    const medicareCardNum: unknown = get(
      patientAttributes,
      'currentMedicareCardNum'
    );
    const subnumerate: unknown = get(patientAttributes, 'currentSubnumerate');
    if (!isString(medicareCardNum) || !isString(subnumerate)) {
      return;
    }
    return { medicareCardNum, subnumerate };
  }
}
