import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  BasicDialogService,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { DeleteConfigurationManager } from '@principle-theorem/principle-core';
import {
  type IMultiTreatmentConfiguration,
  type ITreatmentTemplate,
} from '@principle-theorem/principle-core/interfaces';
import { patchDoc, type WithRef } from '@principle-theorem/shared';
import { isError } from 'lodash';
import { Subject } from 'rxjs';

export interface IMultiTreatmentConfigurationFormData {
  name: string;
}
@Component({
    selector: 'pr-general-multi-treatment-configuration',
    templateUrl: './general-multi-treatment-configuration.component.html',
    styleUrls: ['./general-multi-treatment-configuration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GeneralMultiTreatmentConfigurationComponent implements OnDestroy {
  private _multiTreatmentConfig: WithRef<IMultiTreatmentConfiguration>;
  private _onDestroy$: Subject<void> = new Subject();
  trackByTemplate = TrackByFunctions.ref<WithRef<ITreatmentTemplate>>();

  form = new TypedFormGroup<IMultiTreatmentConfigurationFormData>({
    name: new TypedFormControl<string>('', Validators.required),
  });

  constructor(
    private _dialog: BasicDialogService,
    private _snackBar: MatSnackBar,
    private _brandScope: CurrentBrandScope,
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @Input()
  set multiTreatmentConfig(
    multiTreatmentConfig: WithRef<IMultiTreatmentConfiguration>
  ) {
    this._multiTreatmentConfig = multiTreatmentConfig;
    this.form.patchValue(this.multiTreatmentConfig);
  }

  get multiTreatmentConfig(): WithRef<IMultiTreatmentConfiguration> {
    return this._multiTreatmentConfig;
  }

  async save(): Promise<void> {
    const data = this.form.getRawValue();
    await patchDoc(this.multiTreatmentConfig.ref, data);
    this._snackBar.open('Configuration updated');
  }

  async delete(): Promise<void> {
    const confirmed = await this._dialog.confirm({
      prompt:
        'Are you sure you want to delete this configuration? This will no longer be available for future clinical charting.',
      title: 'Delete',
      submitLabel: 'Yes, Delete',
      submitColor: 'warn',
      cancelLabel: 'Cancel',
    });

    if (!confirmed) {
      return;
    }

    const brand = await this._brandScope.toPromise();
    const manager = new DeleteConfigurationManager(brand);
    try {
      await manager.delete(this._multiTreatmentConfig);
    } catch (error) {
      if (isError(error)) {
        this._snackBar.open(error.message);
        return;
      }
      this._snackBar.open('Error deleting configuration');
      return;
    }
    this._snackBar.open('Configuration Deleted');
    await this._router.navigate(['../'], {
      relativeTo: this._route,
    });
  }
}
