import {
  MeasureFormatter,
  TransactionAction,
  TransactionStatus,
} from '@principle-theorem/principle-core/interfaces';
import { ITransactionEventFact } from '@principle-theorem/reporting/interfaces';
import { BigQueryTable } from '../../big-query-tables';
import {
  BrandQueryScopeConfig,
  DateRangeQueryScopeConfig,
  PracticeQueryScopeConfig,
} from '../../query-scope-config/query-scope-configs';
import { GroupBy, IUnnestQuery } from '../../querying';
import {
  BaseFactMeasures,
  FactTableInterface,
  latestEventId,
} from '../base-measures';
import { MeasureLinkFactory, MeasurePath } from '../data-accessor-factory';
import { BrandDimensionMeasures } from '../dimensions/brand-dimension';
import { InvoiceDimensionMeasures } from '../dimensions/invoice-dimension';
import { ModelEventDimensionMeasures } from '../dimensions/model-event-dimension';
import { PatientDimensionMeasures } from '../dimensions/patient-dimension';
import { PracticeDimensionMeasures } from '../dimensions/practice-dimension';
import { ReferrerDimensionMeasures } from '../dimensions/referrer-dimension';
import { TransactionDimensionMeasures } from '../dimensions/transaction-dimension';
import { CanBeChartedProperty, CanDoAllProperty } from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { QueryFactory } from '../query-factory';

const PRACTITIONER_PAYMENTS_UNNEST: IUnnestQuery = {
  alias: 'practitionerPayments',
  property: 'practitionerPayments',
};

export class TransactionEventFactMeasures
  extends BaseFactMeasures
  implements FactTableInterface<ITransactionEventFact>
{
  id = 'transactionEvent';
  readonly table = BigQueryTable.TransactionEvent;
  readonly name = 'Transactions';
  scopes = [
    new BrandQueryScopeConfig(),
    new PracticeQueryScopeConfig(),
    new DateRangeQueryScopeConfig(),
  ];

  get invoice(): InvoiceDimensionMeasures {
    return new InvoiceDimensionMeasures(BigQueryTable.Invoices, 'invoice', {
      sourceJoinKey: MeasurePath.docRef('invoice.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get transaction(): TransactionDimensionMeasures {
    return new TransactionDimensionMeasures(
      BigQueryTable.Transactions,
      'transaction',
      {
        sourceJoinKey: MeasurePath.docRef('transaction.ref'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get practice(): PracticeDimensionMeasures {
    return new PracticeDimensionMeasures(BigQueryTable.Practices, 'practice', {
      sourceJoinKey: MeasurePath.docRef('practice.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get patient(): PatientDimensionMeasures {
    return new PatientDimensionMeasures(BigQueryTable.Patients, 'patient', {
      sourceJoinKey: MeasurePath.docRef('patient.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get referrer(): ReferrerDimensionMeasures {
    return new ReferrerDimensionMeasures(this.table, 'referrer');
  }

  get brand(): BrandDimensionMeasures {
    return new BrandDimensionMeasures(BigQueryTable.Brands, 'brand', {
      sourceJoinKey: MeasurePath.docRef('brand.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get event(): ModelEventDimensionMeasures<
    TransactionAction,
    TransactionStatus
  > {
    return new ModelEventDimensionMeasures(
      this.table,
      'event',
      'Treatment Plan'
    );
  }

  get practitionerName(): CanDoAllProperty {
    const propertyName = PRACTITIONER_PAYMENTS_UNNEST.property;
    const name = this.measureRef(`${propertyName}.practitioner.name`);
    const docRef = this.measureRef(
      MeasurePath.docRef(`${propertyName}.practitioner.ref`)
    );
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('name'),
        label: 'Practitioner Name',
      },
      docRef,
      this.buildQuery()
        .attributes([docRef.attributePath, name.attributePath])
        .unnest(PRACTITIONER_PAYMENTS_UNNEST)
        .get(),
      name
    );
  }

  get practitionerPayments(): CanDoAllProperty {
    const propertyName = PRACTITIONER_PAYMENTS_UNNEST.property;
    const measure = this.measureRef(`${propertyName}.allocatedAmount`);
    return MeasurePropertyFactory.number(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Practitioner Proportion',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery()
        .attributes([measure.attributePath])
        .unnest(PRACTITIONER_PAYMENTS_UNNEST)
        .get()
    );
  }

  get latestEvent(): TransactionEventFactMeasures {
    const measures = new TransactionEventFactMeasures();
    measures.id = latestEventId(measures);
    measures._query = QueryFactory.fromTable(measures.table)
      .override(measures._query)
      .latestEvent(measures.table, GroupBy.Transaction)
      .filterSoftDeleted(measures.transaction.measureRef('deleted'))
      .get();
    return measures;
  }

  get patientLink(): CanBeChartedProperty {
    const brand = this.brand.measureRef('slug');
    const patient = this.patient.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.link(
      {
        id: this._pathWithPrefix('patient.link'),
        label: 'Patient Link',
        summary: 'Principle link for the given patient',
      },
      patient,
      this.buildQuery()
        .mergeJoins(this.brand.query.joins)
        .mergeJoins(this.patient.query.joins)
        .attributes([brand.attributePath, patient.attributePath])
        .get(),
      brand,
      MeasureLinkFactory.patient
    );
  }

  get invoiceLink(): CanBeChartedProperty {
    const brand = this.brand.measureRef('slug');
    const patient = this.patient.measureRef(MeasurePath.docRef('ref'));
    const invoice = this.invoice.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.link(
      {
        id: 'invoice.link',
        label: 'Invoice Link',
        summary: 'Principle link for the given invoice',
      },
      invoice,
      this.buildQuery()
        .mergeJoins(this.brand.query.joins)
        .mergeJoins(this.patient.query.joins)
        .mergeJoins(this.invoice.query.joins)
        .attributes([
          brand.attributePath,
          patient.attributePath,
          invoice.attributePath,
        ])
        .get(),
      brand,
      MeasureLinkFactory.invoice
    );
  }
}
