@if (profile$ | async; as profile) {
  <div [style.height.px]="diameter" [style.width.px]="diameter">
    <div
      class="group/pic relative h-full w-full cursor-pointer overflow-hidden rounded-full bg-slate-200"
    >
      <pt-user-icon
        class="group-hover/pic:blur-sm"
        [src]="profile | profileImage$ | async"
        [diameter]="diameter"
        [name]="profile.name"
      />
      <div
        class="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center bg-slate-50 bg-opacity-50 opacity-0 group-hover/pic:opacity-100"
        [matMenuTriggerFor]="menu"
      >
        <mat-icon>more_vert</mat-icon>
      </div>
    </div>
  </div>
}

<mat-menu #menu="matMenu">
  <pt-image-upload
    [menuButton]="true"
    [uploadPath]="storagePath$ | async"
    (imageUpload)="updateImageUrl($event)"
  />
  @if (canCaptureImage) {
    <button
      mat-menu-item
      (click)="captureImage.next()"
      matTooltip="Capture Image"
    >
      <mat-icon>add_a_photo</mat-icon> Capture Image
    </button>
  }
</mat-menu>
