<mat-toolbar color="accent">Edit Media Tags</mat-toolbar>
<div class="layout-padding">
  <pr-tags-input
    [tagCol]="tagsCol$ | async"
    [formControl]="tagsCtrl"
    [tagType]="tagType"
  />
  <pt-buttons-container>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="dialogRef.close(tagsCtrl.value)"
      [disabled]="!tagsCtrl.valid"
    >
      Update Tags
    </button>
  </pt-buttons-container>
</div>
