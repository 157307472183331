import {
  IFeature,
  FEATURE_DEFAULTS,
  FeatureVisibility,
} from '@principle-theorem/feature-flags';

export const MANAGEMENT_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  visibility: FeatureVisibility.Beta,
  label: 'management',
};
