import {
  ConditionConfigurationName,
  type ExistingTreatmentConfigurationName,
} from '@principle-theorem/principle-core';

export enum D4WCondition {
  CommunityIndexOfPeriodontalTreatmentNeeds = -2,
  NotedForTooth = -1,
  Empty = 0,
  SupraErupted = 1,
  SubErupted = 2,
  RotatedAcw = 3,
  RotatedCw = 4,
  Tipped = 5,
  OpenContact = 6,
  DriftedMesial = 7,
  DriftedDistal = 8,
  Lesion = 9,
  Defective = 10,
  Overhang = 11,
  Caries = 12,
  Watch = 13,
  Impacted = 14,
  Unerupted = 15,
  PartiallyErupted = 16,
  Recontour = 17,
  Crack = 18,
  AbrasionErosionLesion = 19,
  WearFacet = 20,
  RootStump = 21,
}

export const D4W_CONDITION_MAP: Record<D4WCondition, string> = {
  [D4WCondition.CommunityIndexOfPeriodontalTreatmentNeeds]:
    'Community Index Of Periodontal Treatment Needs (CIPTN)',
  [D4WCondition.NotedForTooth]: 'Noted For Tooth',
  [D4WCondition.Empty]: 'Empty/Missing',
  [D4WCondition.SupraErupted]: 'Supra Erupted',
  [D4WCondition.SubErupted]: 'Sub Erupted',
  [D4WCondition.RotatedAcw]: 'Rotated (Anti Clockwise)',
  [D4WCondition.RotatedCw]: 'Rotated (Clockwise)',
  [D4WCondition.Tipped]: 'Tipped',
  [D4WCondition.OpenContact]: 'Open Contact',
  [D4WCondition.DriftedMesial]: 'Drifted (Mesially)',
  [D4WCondition.DriftedDistal]: 'Drifted (Distally)',
  [D4WCondition.Lesion]: 'Lesion',
  [D4WCondition.Defective]: 'Defective',
  [D4WCondition.Overhang]: 'Overhang',
  [D4WCondition.Caries]: 'Caries',
  [D4WCondition.Watch]: 'Watch',
  [D4WCondition.Impacted]: 'Impacted',
  [D4WCondition.Unerupted]: 'Unerupted',
  [D4WCondition.PartiallyErupted]: 'Partially Erupted',
  [D4WCondition.Recontour]: 'Recontour',
  [D4WCondition.Crack]: 'Crack',
  [D4WCondition.AbrasionErosionLesion]: 'Abrasion/Erosion Lesion',
  [D4WCondition.WearFacet]: 'Wear Facet',
  [D4WCondition.RootStump]: 'Root Stump',
};

export const D4W_CONDITION_PRINCIPLE_MAP: Record<
  D4WCondition,
  ExistingTreatmentConfigurationName | ConditionConfigurationName | undefined
> = {
  [D4WCondition.CommunityIndexOfPeriodontalTreatmentNeeds]:
    ConditionConfigurationName.PeriodontalDiseaseLocalised,
  [D4WCondition.NotedForTooth]: undefined,
  [D4WCondition.Empty]: ConditionConfigurationName.MissingTooth,
  [D4WCondition.SupraErupted]: ConditionConfigurationName.OverEruptedTooth,
  [D4WCondition.SubErupted]: ConditionConfigurationName.PartiallyEruptedTooth,
  [D4WCondition.RotatedAcw]: ConditionConfigurationName.DriftedRotatedTooth,
  [D4WCondition.RotatedCw]: ConditionConfigurationName.DriftedRotatedTooth,
  [D4WCondition.Tipped]: undefined,
  [D4WCondition.OpenContact]: ConditionConfigurationName.OpenContact,
  [D4WCondition.DriftedMesial]: ConditionConfigurationName.DriftedRotatedTooth,
  [D4WCondition.DriftedDistal]: ConditionConfigurationName.DriftedRotatedTooth,
  [D4WCondition.Lesion]: ConditionConfigurationName.AbscessPeriapicalLesion,
  [D4WCondition.Defective]: undefined,
  [D4WCondition.Overhang]: ConditionConfigurationName.Overhang,
  [D4WCondition.Caries]: ConditionConfigurationName.Caries,
  [D4WCondition.Watch]: ConditionConfigurationName.WatchReviewRequired,
  [D4WCondition.Impacted]: ConditionConfigurationName.ImpactedTooth,
  [D4WCondition.Unerupted]: ConditionConfigurationName.UneruptedTooth,
  [D4WCondition.PartiallyErupted]:
    ConditionConfigurationName.PartiallyEruptedTooth,
  [D4WCondition.Recontour]: undefined,
  [D4WCondition.Crack]: ConditionConfigurationName.Crack,
  [D4WCondition.AbrasionErosionLesion]: ConditionConfigurationName.Erosion,
  [D4WCondition.WearFacet]: ConditionConfigurationName.Wear,
  [D4WCondition.RootStump]: undefined,
};
