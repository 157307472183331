import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { StafferSettingsStoreService } from '@principle-theorem/ng-principle-shared';
import {
  TypedFormControl,
  TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import {
  type IStafferSettings,
  PatientMediaGroupBy,
  PatientMediaSize,
  PatientMediaViewType,
} from '@principle-theorem/principle-core/interfaces';
import { getEnumValues } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { skip, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-media-settings',
    templateUrl: './media-settings.component.html',
    styleUrls: ['./media-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MediaSettingsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  viewTypeOptions = getEnumValues(PatientMediaViewType);
  sizeOptions = getEnumValues(PatientMediaSize);
  groupByOptions = getEnumValues(PatientMediaGroupBy);
  form = new TypedFormGroup<
    Omit<IStafferSettings['patientMedia'], 'imageEditMode'>
  >({
    viewType: new TypedFormControl<PatientMediaViewType>(),
    size: new TypedFormControl<PatientMediaSize>(),
    groupBy: new TypedFormControl<PatientMediaGroupBy>(),
  });

  constructor(private _settings: StafferSettingsStoreService) {
    validFormGroupChanges$(this.form)
      .pipe(skip(1), takeUntil(this._onDestroy$))
      .subscribe((patientMedia) => {
        this._settings.updateStafferSettings({
          patientMedia,
        });
      });

    this._settings.patientMedia$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((patientMedia) => {
        this.form.patchValue(patientMedia);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
