<div class="flex flex-row">
  <div class="flex flex-auto">
    <span class="description" [ngClass]="{ positive: value > 0 }">
      {{ description }}
    </span>
    <mat-icon *ngIf="tooltip" [matTooltip]="tooltip" matTooltipPosition="after">
      help_outline
    </mat-icon>
  </div>
  <div class="amount" [ngClass]="{ positive: value > 0 }">
    {{ value | currency }}
  </div>
</div>
