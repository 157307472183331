export enum ChartType {
  Clinical = 'clinical',
  Periodontal = 'periodontal',
}

export enum ChartView {
  Adult = 'Adult',
  Deciduous = 'Deciduous',
  All = 'All',
}

export const CHART_VIEWS: ChartView[] = [
  ChartView.Adult,
  ChartView.Deciduous,
  ChartView.All,
];

export enum ChartSection {
  BothArches = 'Both Arches',
  TopArch = 'Top Arch',
  BottomArch = 'Bottom Arch',
  TopRight = 'Q1 - Top Right',
  TopLeft = 'Q2 - Top Left',
  BottomLeft = 'Q3 - Bottom Left',
  BottomRight = 'Q4 - Bottom Right',
}

export const CHART_SECTIONS: ChartSection[] = [
  ChartSection.BothArches,
  ChartSection.TopArch,
  ChartSection.BottomArch,
  ChartSection.TopRight,
  ChartSection.TopLeft,
  ChartSection.BottomLeft,
  ChartSection.BottomRight,
];
