<form
  *ngrxLet="hasNoScope$ as noScope"
  [formGroup]="templateForm"
  class="flex flex-col gap-4"
>
  @if (hasTemplateSettings()) {
    <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
      <h3 class="!m-0 !font-bold">Template Settings</h3>

      <pr-information-box *ngIf="isSystemTemplate()">
        This is a System Template used within Principle. Some fields cannot be
        edited.
      </pr-information-box>

      @if (!hideNameField()) {
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" required />
        </mat-form-field>
      }

      @if (!isAutomationTemplate()) {
        <div class="flex flex-row gap-4">
          <mat-form-field class="flex flex-auto" subscriptSizing="dynamic">
            <mat-label>Select Type</mat-label>
            <mat-select formControlName="type" required>
              @for (option of typeOptions; track option.value) {
                <mat-option [value]="option.value">
                  {{ option.label }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field class="flex flex-auto" subscriptSizing="dynamic">
            <mat-label>Select Scope</mat-label>
            <mat-select formControlName="scope" required>
              @for (scope of scopes; track scope) {
                <mat-option [value]="scope">
                  {{ scope | splitKebab | titlecase }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      }
    </div>
  }

  <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
    <div class="flex flex-row items-center gap-2">
      <h3 class="!m-0 !font-bold">Template Variants</h3>
      @if (!variantsEnabled()) {
        <mat-icon class="icon-sm opacity-50" [matTooltip]="variantHelpText">
          info
        </mat-icon>
      }
      <div class="flex flex-auto"></div>
      <mat-slide-toggle
        color="primary"
        [checked]="variantsEnabled()"
        (change)="enableVariantsChanged($event.checked)"
      />
    </div>

    @if (variantsEnabled()) {
      <pr-information-box> {{ variantHelpText }} </pr-information-box>

      <div
        cdkDropList
        (cdkDropListDropped)="store.moveVariant($event)"
        class="flex flex-col gap-4"
      >
        @for (
          variant of store.variants$ | async;
          track trackByVariant;
          let isFirst = $first
        ) {
          <div
            class="rounded border border-solid border-slate-100 bg-slate-50"
            cdkDrag
            cdkDragLockAxis="y"
          >
            <div
              class="flex cursor-move flex-row gap-4 border-b border-solid border-slate-100 px-4 py-2"
              cdkDragHandle
            >
              <mat-icon>drag_handle</mat-icon>
              <div *ngIf="!noScope" fxFlex>
                {{ isFirst ? 'First' : 'Otherwise' }}, check if
              </div>
            </div>
            <div class="p-4">
              <pr-edit-template-variant
                class="mb-2"
                [templateType]="templateType$ | async"
                [context]="context$ | async"
                [variant]="variant"
                [hasNoScope]="noScope"
                [isSystemTemplate]="isSystemTemplate()"
              />
              <button
                mat-stroked-button
                color="warn"
                (click)="store.deleteVariant(variant)"
              >
                Delete
              </button>
            </div>
          </div>
        }
      </div>

      <div>
        <button
          mat-stroked-button
          (click)="store.addVariant(templateForm.value.content)"
        >
          Add a Variant
        </button>
      </div>
    }
  </div>

  <div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
    <h3 class="!m-0 !font-bold">Default Template</h3>
    <pr-template-editor-textarea
      formControlName="content"
      [required]="true"
      [templateType]="templateType$ | async"
      [context]="context$ | async"
    />
  </div>
</form>
