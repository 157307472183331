import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnInit,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toTextContent } from '@principle-theorem/editor';
import {
  GlobalStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  ConfirmDialogComponent,
  DialogPresets,
  SelectionListStore,
  confirmationDialogData,
  type IConfirmationDialogData,
  type IConfirmationDialogInput,
} from '@principle-theorem/ng-shared';
import {
  ITypesenseTaskWithRef,
  Practice,
  Task,
} from '@principle-theorem/principle-core';
import {
  ITask,
  TASK_PRIORITY_COLOUR_MAP,
  type IRecurringTaskConfiguration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  asDocRef,
  filterUndefined,
  getDoc,
  getDoc$,
  HISTORY_DATE_FORMAT,
  HISTORY_DATE_TIME_FORMAT,
  snapshot,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { BehaviorSubject, ReplaySubject, of, type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RecurringTaskConfirmDeleteComponent } from '../../../components/recurring-task-confirm-delete/recurring-task-confirm-delete.component';
import {
  RecurringTaskFormDialogComponent,
  type IRecurringTaskFormDialogData,
} from '../../../components/recurring-task-form-dialog/recurring-task-form-dialog.component';
import {
  TaskInteractionsDialogComponent,
  type ITaskInteractionDialogData,
} from '../../../components/task-interactions-dialog/task-interactions-dialog.component';
import { TaskManager } from '../../../task-manager';

@Component({
  selector: 'pr-task-item',
  templateUrl: './task-item.component.html',
  styleUrls: ['./task-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskItemComponent implements OnInit {
  private _taskManager: TaskManager;
  readonly dateTimeFormat = HISTORY_DATE_TIME_FORMAT;
  readonly dateFormat = HISTORY_DATE_FORMAT;
  assignedUserPic$: Observable<string | undefined>;
  selectEnabled$ = new BehaviorSubject<boolean>(true);
  item$ = new ReplaySubject<ITypesenseTaskWithRef>(1);
  task$: Observable<WithRef<ITask>>;
  @Input() selectedItem: ITypesenseTaskWithRef;
  @Input() selectionList: SelectionListStore<ITypesenseTaskWithRef>;
  @Output() selectedItemChange = new EventEmitter<ITypesenseTaskWithRef>();
  @Output() taskUpdated = new EventEmitter<void>();

  @Input()
  set selectDisabled(selectDisabled: BooleanInput) {
    this.selectEnabled$.next(!coerceBooleanProperty(selectDisabled));
  }

  @Input()
  set item(item: ITypesenseTaskWithRef) {
    if (item) {
      this.item$.next(item);
    }
  }

  constructor(
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _organisation: OrganisationService,
    private _globalStore: GlobalStoreService
  ) {
    this.task$ = this.item$.pipe(switchMap((item) => getDoc(item.ref)));

    this.assignedUserPic$ = this.item$.pipe(
      switchMap((task) => {
        if (!task.assignedRef) {
          return of(undefined);
        }
        return this._globalStore.getStafferImage$({
          ref: asDocRef<IStaffer>(task.assignedRef),
        });
      })
    );
  }

  async ngOnInit(): Promise<void> {
    const staffer: WithRef<IStaffer> = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    this._taskManager = new TaskManager(staffer);
  }

  selectItem(item: ITypesenseTaskWithRef): void {
    this.selectedItemChange.emit(item);
  }

  hasDueTime(item: ITypesenseTaskWithRef): boolean {
    return Task.hasDueTime({
      ...item,
      dueDate: item.dueDate
        ? toTimestamp(this.toMoment(item.dueDate))
        : undefined,
    });
  }

  isComplete(item: ITypesenseTaskWithRef): boolean {
    return Task.complete(item);
  }

  isLate(item: ITypesenseTaskWithRef): boolean {
    return Task.late({
      ...item,
      dueDate: item.dueDate
        ? toTimestamp(this.toMoment(item.dueDate))
        : undefined,
    });
  }

  priorityColour(item: ITypesenseTaskWithRef): string {
    return TASK_PRIORITY_COLOUR_MAP[item.priority];
  }

  toMoment(date: number): moment.Moment {
    return moment.unix(date);
  }

  async markComplete(): Promise<void> {
    const task = await snapshot(this.task$);
    await this._taskManager.close(task);
    this.taskUpdated.emit();
    this._snackBar.open('Task complete');
  }

  async markIncomplete(): Promise<void> {
    const task = await snapshot(this.task$);
    await this._taskManager.open(task);
    this.taskUpdated.emit();
    this._snackBar.open('Task returned to list');
  }

  async deleteTask(): Promise<void> {
    const task = await snapshot(this.task$);
    if (Task.recurring(task)) {
      return this.deleteRecurringTask();
    }

    const data: IConfirmationDialogData = confirmationDialogData({
      title: 'Delete Task',
      prompt: 'Are you sure you want to delete this task?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data, autoFocus: true })
      )
      .afterClosed()
      .toPromise();

    if (!confirmed) {
      return;
    }

    this.taskUpdated.emit();
    await this._taskManager.delete(task);
    this._snackBar.open('Task deleted');
  }

  async deleteRecurringTask(): Promise<void> {
    const confirmed = await this._dialog
      .open<RecurringTaskConfirmDeleteComponent, undefined, boolean>(
        RecurringTaskConfirmDeleteComponent
      )
      .afterClosed()
      .toPromise();

    if (confirmed === undefined) {
      return;
    }

    const task = await snapshot(this.task$);
    await this._taskManager.deleteRecurringTask(task, confirmed);

    const message: string = confirmed
      ? 'All Future Tasks Deleted'
      : 'Task Deleted';
    this._snackBar.open(message);
  }

  async editTask(): Promise<void> {
    const task = await snapshot(this.task$);
    const data: ITaskInteractionDialogData = { task };
    const config: MatDialogConfig = DialogPresets.fullscreen({
      data,
    });
    await this._dialog
      .open(TaskInteractionsDialogComponent, config)
      .afterClosed()
      .toPromise();
    this.taskUpdated.emit();
  }

  async editRecurringTask(): Promise<void> {
    const configuration: WithRef<IRecurringTaskConfiguration> | undefined =
      await this._resolveConfiguration();
    if (!configuration) {
      return;
    }

    const data: IRecurringTaskFormDialogData = {
      title: [toTextContent('Edit '), ...configuration.title],
      submitLabel: 'Save',
      configuration,
    };
    this._dialog.open(
      RecurringTaskFormDialogComponent,
      DialogPresets.medium({ data, autoFocus: true })
    );
  }

  async undeleteTask(): Promise<void> {
    const task = await snapshot(this.task$);
    await this._taskManager.undelete(task);
    this.taskUpdated.emit();
    this._snackBar.open('Task undeleted');
  }

  private async _resolveConfiguration(): Promise<
    WithRef<IRecurringTaskConfiguration> | undefined
  > {
    const task = await snapshot(this.task$);
    if (!task.recurrenceConfiguration) {
      return;
    }
    const practice = await snapshot(Task.practice$(task));
    return snapshot(
      getDoc$(
        Practice.recurringTaskConfigurationCol(practice),
        task.recurrenceConfiguration.id
      )
    );
  }
}
