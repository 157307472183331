import { Media, Patient, Practice } from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  ISourceEntityRecord,
  ITranslationMap,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityJobRunOptions,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IMedia,
  type IPatient,
  type IPracticeMigration,
  type ISource,
  type ITag,
  type MergeConflictDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  errorNil,
  firstResult,
  getError,
  toInt,
  toNamedDocument,
  toTimestamp,
  where,
  type DocumentReference,
  type INamedDocument,
  type Timestamp,
  type WithRef,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { compact, first, get, groupBy, uniq } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, from, type Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { PracticeIdFilter } from '../../../destination/filters/practice-id-filter';
import {
  CopyFile,
  CopyFiles,
  getBucketStoragePath,
  type IStorageSyncResponse,
} from '../../../storage';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientSourceEntity,
  type ID4WPatient,
  type ID4WPatientFilters,
  type ID4WPatientTranslations,
} from '../../source/entities/patient';
import {
  PATIENT_DOCUMENT_RESOURCE_TYPE,
  PatientDocumentSourceEntity,
  type ID4WPatientDocument,
  type ID4WPatientDocumentFilters,
  type ID4WPatientDocumentTranslations,
} from '../../source/entities/patient-document';
import { D4WFileCategoryMappingHandler } from '../mappings/file-categories';
import { PatientDestinationEntity } from './patients';

export const PATIENT_FILE_RESOURCE_TYPE = 'patientFiles';

export const PATIENT_FILE_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_FILE_RESOURCE_TYPE,
    label: 'Patient Files',
    description: '',
  },
});

export const CLOUD_STORAGE_CREATED_AT_METADATA_KEY = 'goog-reserved-file-mtime';

export interface IPatientFileDestinationRecord {
  fileRefs: DocumentReference<IMedia>[];
}

export interface IPatientFileJobData {
  sourcePatient: IGetRecordResponse<
    ID4WPatient,
    ID4WPatientTranslations,
    ID4WPatientFilters
  >;
  files: IFileSummary[];
  fileCategories: WithRef<ITranslationMap<ITag>>[];
  documentTag: WithRef<ITag>;
}

interface IFileSummary {
  patientName: string;
  patientId: string;
  fileName: string;
  folderPath: string;
  categoryName?: string;
}

export interface IPatientFileMigrationData {
  sourcePatientId: string;
  patientRef: DocumentReference<IPatient>;
  documents: IGetRecordResponse<
    ID4WPatientDocument,
    ID4WPatientDocumentTranslations,
    ID4WPatientDocumentFilters
  >[];
  files: IFileSummary[];
  fileCategories: WithRef<ITranslationMap<ITag>>[];
}

export class PatientFileDestinationEntity extends BaseDestinationEntity<
  IPatientFileDestinationRecord,
  IPatientFileJobData,
  IPatientFileMigrationData
> {
  destinationEntity = PATIENT_FILE_DESTINATION_ENTITY;

  override filters = [
    new PracticeIdFilter<IPatientFileJobData>((jobData) =>
      jobData.sourcePatient.data.data.practice_id.toString()
    ),
    new PatientIdFilter<IPatientFileJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    patientDocuments: new PatientDocumentSourceEntity(),
  };

  customMappings = {
    fileCategories: new D4WFileCategoryMappingHandler(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  sourceCountDataAccessor(
    data: IPatientFileJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientFileJobData[]> {
    const patientFiles$ = from(
      groupImagesByPatient(
        migration.configuration.projectId,
        getBucketStoragePath(migration.source),
        `files/`
      )
    );

    const documentTag$ = combineLatest(
      migration.configuration.practices.map((practice) =>
        firstResult(
          Practice.mediaTagCol(practice),
          where('name', '==', 'Letter')
        )
      )
    ).pipe(
      map(compact),
      map((mediaTags) =>
        first(mediaTags.filter((tag) => tag.name === 'Letter'))
      ),
      errorNil('Letter tag not found')
    );

    return patientFiles$.pipe(
      withLatestFrom(
        from(this.customMappings.fileCategories.getRecords(translationMap)),
        documentTag$
      ),
      switchMap(([patientFiles, fileCategories, documentTag]) => {
        return this.buildSourceRecordQuery$(
          migration,
          this.sourceEntities.patients,
          runOptions
        ).pipe(
          map((sourcePatients) =>
            sourcePatients.map((sourcePatient) => {
              let patientId = sourcePatient.data.data.patient_id.toString();
              const alternatePatientId =
                sourcePatient.data.data.patients_cart_num;

              if (alternatePatientId && patientId !== alternatePatientId) {
                patientId = alternatePatientId ?? patientId;
              }

              const files = Object.entries(patientFiles).find(([key]) => {
                if (!key.includes(patientId)) {
                  return false;
                }

                const hasPatientName = this._hasPatientName(key, sourcePatient);

                if (!hasPatientName) {
                  return false;
                }

                return true;
              });

              return {
                sourcePatient,
                fileCategories,
                documentTag,
                files: files ? files[1] : [],
              };
            })
          )
        );
      })
    );
  }

  getDestinationEntityRecordUid(data: IPatientFileJobData): string {
    return data.sourcePatient.record.uid;
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientFileJobData
  ): Promise<
    | IPatientFileMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();
    const documents = await this.sourceEntities.patientDocuments.filterRecords(
      migration,
      'patientId',
      sourcePatientId
    );

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `Couldn't resolve patient`
      );
    }

    return {
      sourcePatientId,
      patientRef,
      documents,
      files: data.files,
      fileCategories: data.fileCategories,
    };
  }

  hasMergeConflict(
    _translationMap: TranslationMapHandler,
    _data: IPatientFileMigrationData
  ): IPatientFileMigrationData | undefined {
    return;
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientFileJobData,
    _migrationData: IPatientFileMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  async runJob(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    data: IPatientFileJobData,
    migrationData: IPatientFileMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const fileRefs = await this._addMediaToPatient(
        data,
        migrationData.sourcePatientId,
        migrationData.patientRef,
        migrationData.documents,
        migrationData.files,
        migrationData.fileCategories,
        translationMapHandler,
        migration.source,
        migration.configuration.projectId,
        migration.configuration.destinationBucket
      );
      return this._buildSuccessResponse(data.sourcePatient, fileRefs);
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  private _hasPatientName(
    key: string,
    sourcePatient: IGetRecordResponse<ID4WPatient>
  ): boolean {
    const keyLower = key.toLowerCase();
    if (!keyLower.includes(sourcePatient.data.data.surname.toLowerCase())) {
      return false;
    }

    if (keyLower.includes(sourcePatient.data.data.firstname.toLowerCase())) {
      return true;
    }

    if (
      sourcePatient.data.data.preferred_name &&
      keyLower.includes(sourcePatient.data.data.preferred_name.toLowerCase())
    ) {
      return true;
    }

    // eslint-disable-next-line no-console
    console.warn(
      `Patient name mismatch: ${key} - ${sourcePatient.data.data.firstname} ${sourcePatient.data.data.surname}`
    );
    return false;
  }

  private _buildSuccessResponse(
    sourcePatient: IGetRecordResponse<ID4WPatient, ID4WPatientTranslations>,
    fileRefs: DocumentReference<IMedia>[]
  ): IDestinationEntityRecord<IPatientFileDestinationRecord> {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      data: {
        fileRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: sourcePatient.record.ref,
      migratedAt: toTimestamp(),
    };
  }

  private _buildErrorResponse(
    sourcePatient: IGetRecordResponse<ID4WPatient, ID4WPatientTranslations>,
    errorMessage: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: sourcePatient.record.ref,
      errorMessage,
      failData: {
        patientRef: sourcePatient.record.ref,
      },
    };
  }

  private async _addMediaToPatient(
    data: IPatientFileJobData,
    sourcePatientId: string,
    patientRef: DocumentReference<IPatient>,
    documents: IGetRecordResponse<
      ID4WPatientDocument,
      ID4WPatientDocumentTranslations,
      ID4WPatientDocumentFilters
    >[],
    files: IFileSummary[],
    fileCategories: WithRef<ITranslationMap<ITag>>[],
    translationMap: TranslationMapHandler,
    source: ISource,
    projectId: string,
    destinationBucket: string
  ): Promise<DocumentReference<IMedia>[]> {
    // eslint-disable-next-line no-console
    console.log(
      `Found ${files.length} files for patient ${sourcePatientId} in the bucket`
    );

    const folderPath = files[0]?.folderPath;

    if (!files.length || !folderPath) {
      return [];
    }

    const copyFolderResponse = await this._copySourceFilesToDestination(
      getBucketStoragePath(source),
      `${folderPath}/`,
      patientRef,
      projectId,
      destinationBucket
    );

    const fileRefs = await asyncForEach(
      copyFolderResponse.storageFiles,
      async (file) => {
        const fileDestinationRef = await translationMap.getDestination(
          file.path,
          PATIENT_FILE_RESOURCE_TYPE
        );

        const createdAtUnix = String(
          get(
            file.metadata,
            `metadata.${CLOUD_STORAGE_CREATED_AT_METADATA_KEY}`,
            get(file.metadata, CLOUD_STORAGE_CREATED_AT_METADATA_KEY, '')
          )
        );

        let createdAt: Timestamp | undefined;

        try {
          createdAt = toTimestamp(moment.unix(toInt(createdAtUnix)));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(`Error parsing createdAt date ${createdAtUnix}`);
        }

        const fileName = file.path.split('/').pop();
        const matchingFile = files.find(
          (fileSummary) => fileSummary.fileName === fileName
        );

        const fileTags: INamedDocument<ITag>[] = [];

        if (matchingFile?.categoryName) {
          const category = fileCategories.find(
            (fileCategory) =>
              fileCategory.sourceIdentifier === matchingFile.categoryName
          );

          if (category?.destinationIdentifier) {
            fileTags.push({
              name: category.sourceIdentifier,
              ref: category.destinationIdentifier,
            });
          }
        }

        const mediaRef = await FirestoreMigrate.upsertDoc(
          Patient.mediaCol({ ref: patientRef }),
          {
            ...Media.init({
              name: fileName,
              path: file.path,
              tags: fileTags,
            }),
            createdAt,
          },
          fileDestinationRef?.id
        );

        if (!fileDestinationRef) {
          await translationMap.upsert({
            sourceIdentifier: file.path,
            destinationIdentifier: mediaRef,
            resourceType: PATIENT_FILE_RESOURCE_TYPE,
          });
        }

        return mediaRef;
      }
    );

    const documentRefs = compact(
      await asyncForEach(documents, async (document) => {
        try {
          const path = await this._copySourceDocumentToDestination(
            getBucketStoragePath(source),
            `letters/${document.data.data.filename}`,
            patientRef,
            projectId,
            destinationBucket
          );

          if (!path) {
            throw new Error(
              `Couldn't copy document ${document.data.data.filename} to destination bucket`
            );
          }

          const documentDestinationRef = await translationMap.getDestination(
            document.record.uid,
            PATIENT_DOCUMENT_RESOURCE_TYPE
          );

          const mediaRef = await FirestoreMigrate.upsertDoc(
            Patient.mediaCol({ ref: patientRef }),
            {
              ...Media.init({
                name: document.data.data.filename,
                path,
                tags: [toNamedDocument(data.documentTag)],
              }),
              createdAt: document.data.translations.createdAt,
            },
            documentDestinationRef?.id
          );

          if (!documentDestinationRef) {
            await translationMap.upsert({
              sourceIdentifier: document.record.uid,
              destinationIdentifier: mediaRef,
              resourceType: PATIENT_DOCUMENT_RESOURCE_TYPE,
            });
          }

          return mediaRef;
        } catch (error) {
          // D4W letters can be deleted from the filesystem so this in most cases isn't an error
          // eslint-disable-next-line no-console
          console.error(
            `Error adding document ${document.data.data.filename} to patient ${sourcePatientId}`,
            error
          );
        }
      })
    );

    return [...fileRefs, ...documentRefs];
  }

  private async _copySourceFilesToDestination(
    sourceBucket: string,
    sourcePath: string,
    patientRef: DocumentReference<IPatient>,
    projectId: string,
    destinationBucket: string
  ): Promise<IStorageSyncResponse> {
    const copyStorage = new CopyFiles(
      sourceBucket,
      sourcePath,
      destinationBucket,
      Patient.storagePath({ ref: patientRef }),
      projectId
    );

    return {
      storageFiles: await copyStorage.copyAll(
        [/.*.db/],
        [CLOUD_STORAGE_CREATED_AT_METADATA_KEY],
        true
      ),
    };
  }

  private async _copySourceDocumentToDestination(
    sourceBucket: string,
    sourcePath: string,
    patientRef: DocumentReference<IPatient>,
    projectId: string,
    destinationBucket: string
  ): Promise<string> {
    const copyStorage = new CopyFile(
      sourceBucket,
      sourcePath,
      destinationBucket,
      Patient.storagePath({ ref: patientRef }),
      projectId
    );

    return copyStorage.copy();
  }
}

export async function groupImagesByPatient(
  projectId: string,
  bucketName: string,
  bucketPath: string
): Promise<Record<string, IFileSummary[]>> {
  const storage = new CopyFiles(bucketName, bucketPath, '', '', projectId)
    .storage;

  const [files] = await storage.bucket(bucketName).getFiles({
    prefix: bucketPath,
  });

  const missingFields: string[] = [];

  const bucketFiles = uniq(
    compact(
      files.map((file) => {
        const folderPath = file.name.split('/');

        const fileSummary: IFileSummary = {
          patientName: '',
          patientId: '',
          fileName: '',
          folderPath: '',
          categoryName: undefined,
        };

        if (folderPath.length > 2) {
          const patientIdMatch = new RegExp(/\[([0-9A-Za-z\s]+)\]$/).exec(
            folderPath[1]
          );
          fileSummary.patientId = patientIdMatch?.[1] ?? '';
          const patientNameMatch = new RegExp(/^([\w'\-\s]+,[\w'\-\s]+)/).exec(
            folderPath[1]
          );
          fileSummary.patientName = (patientNameMatch?.[1] ?? '')
            .split(',')
            .reverse()
            .join(' ')
            .trim();
        }

        if (folderPath.length > 3) {
          fileSummary.categoryName = folderPath[2].trim();
        }

        fileSummary.folderPath = folderPath.slice(0, 2).join('/') ?? '';
        fileSummary.fileName = folderPath.slice(2).join('_');

        if (
          !fileSummary.patientId ||
          !fileSummary.patientName ||
          !fileSummary.fileName
        ) {
          missingFields.push(file.name);
        }

        return fileSummary;
      })
    )
  );

  return groupBy(
    bucketFiles,
    (summary) => `${summary.patientId}-${summary.patientName}`
  );
}
