import { type Type } from '@angular/core';
import {
  type IHealthcareClaim,
  type IInvoice,
  type ITransaction,
  type TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Region,
  TypeGuard,
  WithRef,
  isObject,
} from '@principle-theorem/shared';
import { isFunction, isString } from 'lodash';
import { isObservable, type Observable } from 'rxjs';

export enum TransactionProviderType {
  Payment = 'payment',
  HealthFund = 'healthfund',
  Medicare = 'medicare',
  DVA = 'dva',
}

export interface ITransactionProvider {
  providerId: TransactionProvider;
  providerType: TransactionProviderType;
  providerRegions: Region[];
  isEnabled$: Observable<boolean>;

  canCapture$(invoice: WithRef<IInvoice>): Observable<boolean>;

  capture(
    invoice: WithRef<IInvoice>,
    claim?: IHealthcareClaim
  ): Promise<DocumentReference<ITransaction> | undefined>;

  refundTransaction?(
    invoice: WithRef<IInvoice>,
    transaction: WithRef<ITransaction>
  ): Promise<DocumentReference<ITransaction> | undefined>;

  getInfo$?(
    invoice: WithRef<IInvoice>,
    claim?: IHealthcareClaim
  ): Observable<string>;
}

export interface IRefundTransactionProvider {
  canRefund$(invoice: WithRef<IInvoice>): Observable<boolean>;

  refund(
    invoice: WithRef<IInvoice>,
    transaction?: WithRef<ITransaction>,
    disableAmount?: boolean
  ): Promise<DocumentReference<ITransaction> | undefined>;

  refundInfo$(invoice: WithRef<IInvoice>): Observable<string[]>;
}

export const isRefundTransactionProvider =
  TypeGuard.interface<IRefundTransactionProvider>({
    canRefund$: isFunction,
    refundInfo$: isFunction,
    refund: isFunction,
  });

export const hasGetInfo = TypeGuard.interface<
  Required<Pick<ITransactionProvider, 'getInfo$'>>
>({
  getInfo$: isFunction,
});

export function isTransactionProvider(
  item: unknown
): item is ITransactionProvider {
  return (
    isObject(item) &&
    isString(item.providerType) &&
    isObservable(item.isEnabled$) &&
    isFunction(item.capture)
  );
}

export interface IProviderOption {
  label: string;
  icon: string;
  imageUrl?: string;
  provider: Type<ITransactionProvider>;
}

export interface IResolvedTransactionOption<Provider = ITransactionProvider> {
  label: string;
  icon: string;
  imageUrl?: string;
  provider: Provider;
}

export function isResolvedTransactionOption(
  item: unknown
): item is IResolvedTransactionOption {
  return (
    isObject(item) &&
    isString(item.label) &&
    isTransactionProvider(item.provider)
  );
}

export type ResolvedRefundTransactionOption = IResolvedTransactionOption<
  ITransactionProvider & IRefundTransactionProvider
>;

export function isResolvedRefundTransactionOption(
  item: unknown
): item is ResolvedRefundTransactionOption {
  return (
    isObject(item) &&
    isResolvedTransactionOption(item) &&
    isRefundTransactionProvider(item.provider)
  );
}

export class TransactionProviderError extends Error {}
