import {
  HicapsConnectExtendedDataTypeGuard,
  IHicapsConnectHealthFundTransactionExtendedData,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import { toMoment } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ITransactionAction,
  ITransactionActionsData,
} from '../../../transaction-action';
import { TransactionHelpers } from '../../../transaction-helpers';
import { HicapsConnectHealthFundTransactionProvider } from '../hicaps-connect-health-fund-transaction-provider.service';

export class CancelHicapsConnectHealthfundTransaction
  implements
    ITransactionAction<IHicapsConnectHealthFundTransactionExtendedData>
{
  icon = 'cancel';
  label = 'Cancel';
  tooltip = 'Can only be cancelled on the same day';
  inProgress$ = new BehaviorSubject<boolean>(false);
  typeGuardFn = HicapsConnectExtendedDataTypeGuard.isHealthFund;

  constructor(private _provider: HicapsConnectHealthFundTransactionProvider) {}

  canDo$(
    data: ITransactionActionsData<IHicapsConnectHealthFundTransactionExtendedData>
  ): Observable<boolean> {
    return TransactionHelpers.lastCompletedTransaction$(
      data,
      HicapsConnectExtendedDataTypeGuard.isHealthFund
    ).pipe(
      map((transaction) => {
        if (
          data.transaction.type !== TransactionType.Incoming ||
          data.transaction.status !== TransactionStatus.Complete ||
          !transaction
        ) {
          return false;
        }
        const isSuccessfulClaim =
          transaction.type === TransactionType.Incoming &&
          transaction.status === TransactionStatus.Complete;

        const now = moment();
        const createdAt = toMoment(transaction.createdAt);
        const isSameDay = now.isSame(createdAt, 'day');

        return isSuccessfulClaim && isSameDay;
      })
    );
  }

  async do(
    data: ITransactionActionsData<IHicapsConnectHealthFundTransactionExtendedData>
  ): Promise<void> {
    this.inProgress$.next(true);
    await this._provider.cancel(
      data.invoice,
      data.latestTransaction,
      data.claim
    );
    this.inProgress$.next(false);
  }
}
