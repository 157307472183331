import { Injectable, type OnDestroy } from '@angular/core';
import { TimezoneResolver } from '@principle-theorem/principle-core';
import {
  type IEvent,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  filterUndefined,
  type ITimePeriod,
  shareReplayHot,
  type Timezone,
  toMoment,
  toTimePeriod,
} from '@principle-theorem/shared';
import { from, type Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrentPracticeScope } from './app-state/current-practice';
import { CurrentScopeFacade } from './store';
import { TimezoneManager } from './timezone-manager';

@Injectable({
  providedIn: 'root',
  deps: [CurrentPracticeScope],
})
export class TimezoneService implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  currentBrand: TimezoneManager;
  currentPractice: TimezoneManager;

  constructor(private _currentScope: CurrentScopeFacade) {
    this.currentBrand = this.for(
      this._currentScope.currentBrand$.pipe(
        filterUndefined(),
        map((brand) => brand.settings.timezone),
        shareReplayHot(this._onDestroy$)
      )
    );
    this.currentPractice = this.for(
      this._currentScope.currentPractice$.pipe(
        filterUndefined(),
        map((practice) => practice.settings.timezone),
        shareReplayHot(this._onDestroy$)
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  for(timezone$: Observable<Timezone>): TimezoneManager {
    return new TimezoneManager(timezone$);
  }

  forPractice(practiceRef?: DocumentReference<IPractice>): TimezoneManager {
    return this.for(this.resolvePracticeTimezone$(practiceRef));
  }

  getEventRange$(event: IEvent): Observable<ITimePeriod> {
    return this.forPractice(event.practice.ref).applyToTimePeriod$(
      toTimePeriod(toMoment(event.from), toMoment(event.to))
    );
  }

  resolvePracticeTimezone$(
    practiceRef?: DocumentReference<IPractice>
  ): Observable<Timezone> {
    if (!practiceRef) {
      return this.currentPractice.timezone$;
    }
    return from(TimezoneResolver.fromPracticeRef(practiceRef));
  }
}
