import { Injectable } from '@angular/core';
import { FirebaseFunctionsService } from '@principle-theorem/ng-firebase';
import {
  PATIENT_PORTAL_DISPATCHER,
  PatientAction,
  PatientActionRequest,
  type IPatientMedicalHistoryFormTokenData,
  type ISubmitMedicalFormRequest,
  type ISubmittedForm,
  type ITokenRequest,
} from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, unserialise$ } from '@principle-theorem/shared';
import { type Observable, of, from } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PatientMedicalHistoryService {
  constructor(private _functions: FirebaseFunctionsService) {}

  resolvePatientMedicalHistoryData$(
    tokenUid: string
  ): Observable<IPatientMedicalHistoryFormTokenData | undefined> {
    const payload = {
      action: PatientAction.GetPatientMedicalHistoryFormData,
      data: { tokenUid },
    };

    const request = this._functions.call<
      PatientActionRequest<ITokenRequest>,
      SerialisedData<IPatientMedicalHistoryFormTokenData>
    >(PATIENT_PORTAL_DISPATCHER, payload);

    return from(request).pipe(
      catchError(() => of(undefined)),
      unserialise$()
    );
  }

  async submitFormData(
    tokenUid: string,
    data: ISubmittedForm<object>
  ): Promise<void> {
    const payload = {
      action: PatientAction.SubmitPatientMedicalForm,
      data: { tokenUid, data },
    };

    await this._functions.call<PatientActionRequest<ISubmitMedicalFormRequest>>(
      PATIENT_PORTAL_DISPATCHER,
      payload
    );
  }
}
