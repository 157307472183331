import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'pr-pricing-unit-type-help',
    templateUrl: './pricing-unit-type-help.component.html',
    styleUrls: ['./pricing-unit-type-help.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PricingUnitTypeHelpComponent {}
