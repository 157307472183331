import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { initVersionedSchema } from '@principle-theorem/editor';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  InputSearchFilter,
  toSearchStream,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  Patient,
  PatientDocument,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  PatientRelationshipType,
  type IPatient,
  type IPatientDocument,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  filterUndefined,
  HISTORY_DATE_FORMAT,
  isWithRef,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, combineLatest, type Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { PatientsFacade } from '../../store/facades/patients.facade';
import {
  CreatePatientDocumentDialogComponent,
  type ICreatePatientDocumentResponse,
} from '../create-patient-document-dialog/create-patient-document-dialog.component';

@Component({
  selector: 'pr-patient-documents',
  templateUrl: './patient-documents.component.html',
  styleUrls: ['./patient-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientDocumentsComponent {
  trackByDocument = TrackByFunctions.ref<WithRef<IPatientDocument>>();
  documents$: Observable<WithRef<IPatientDocument>[]>;
  searchCtrl = new TypedFormControl<string>('');
  patient$: Observable<WithRef<IPatient>>;
  searchFilter: InputSearchFilter<WithRef<IPatientDocument>>;
  emptyState$: Observable<boolean>;
  loading$ = new BehaviorSubject<boolean>(true);
  readonly dateFormat = HISTORY_DATE_FORMAT;

  constructor(
    private _patient: PatientsFacade,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _org: OrganisationService,
    private _router: Router
  ) {
    this.patient$ = this._patient.currentPatient$.pipe(
      map((patient) => (isWithRef(patient) ? patient : undefined)),
      filterUndefined()
    );
    this.documents$ = this.patient$.pipe(
      tap(() => this.loading$.next(true)),
      switchMap((patient) =>
        Patient.withPatientRelationships$(
          patient,
          [PatientRelationshipType.DuplicatePatient],
          Patient.patientDocuments$
        )
      ),
      tap(() => this.loading$.next(false))
    );
    this.searchFilter = new InputSearchFilter<WithRef<IPatientDocument>>(
      this.documents$,
      toSearchStream(this.searchCtrl),
      ['name']
    );
    this.emptyState$ = combineLatest([this.documents$, this.loading$]).pipe(
      map(([documents, loading]) => !documents.length && !loading)
    );
  }

  async createDocument(): Promise<void> {
    const patient = await snapshot(this.patient$);
    const response = await this._dialog
      .open<
        CreatePatientDocumentDialogComponent,
        WithRef<IPatient>,
        ICreatePatientDocumentResponse
      >(
        CreatePatientDocumentDialogComponent,
        DialogPresets.small({
          data: patient,
        })
      )
      .afterClosed()
      .toPromise();

    if (!response) {
      return;
    }

    const staffer = await snapshot(this._org.staffer$.pipe(filterUndefined()));
    const content = response.template?.content ?? initVersionedSchema();

    const patientDocument = PatientDocument.init({
      name: response.name,
      content,
      createdBy: stafferToNamedDoc(staffer),
    });

    const doc = await addDoc(
      Patient.patientDocumentCol(patient),
      patientDocument
    );
    this._snackBar.open('Patient Document Created');
    await this._router.navigate([this._router.url, doc.id]);
  }
}
