import {
  ISourceEntity,
  SourceEntityMigrationType,
} from '@principle-theorem/principle-core/interfaces';
import { Timezone, TypeGuard } from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const THIRD_PARTY_RESOURCE_TYPE = 'thirdParties';

export const THIRD_PARTY_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Third Party List',
    description: '',
    idPrefix: THIRD_PARTY_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface IOasisThirdParty {
  id: number; // Patient ID; IDs from 99000 and above seem to be contacts
  name: string; // Surname
  addressLine1?: string; // Address 1
  addressLine2?: string; // Address 2
  suburb?: string; // Suburb
  postCode?: number; // Postcode
  homePhone?: string; //
  workPhone?: string; //
  fax?: string; //
  mobile?: string; //
  mainPhone?: string; //
  email?: string; //
}

export function isOasisThirdParty(item: unknown): item is IOasisThirdParty {
  return TypeGuard.interface<IOasisThirdParty>({
    id: isNumber,
    name: isString,
    addressLine1: TypeGuard.nilOr(isString),
    addressLine2: TypeGuard.nilOr(isString),
    suburb: TypeGuard.nilOr(isString),
    postCode: TypeGuard.nilOr(isNumber),
    homePhone: TypeGuard.nilOr(isString),
    workPhone: TypeGuard.nilOr(isString),
    fax: TypeGuard.nilOr(isString),
    mobile: TypeGuard.nilOr(isString),
    mainPhone: TypeGuard.nilOr(isString),
    email: TypeGuard.nilOr(isString),
  })(item);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOasisThirdPartyTranslations {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOasisThirdPartyFilters {}

const THIRD_PARTY_SOURCE_QUERY = `
SELECT
  convert_to_integer(PATNUMBER) AS id,
  SURNAME AS name,
  NULLIF(STREET1, '') AS address_line_1,
  NULLIF(STREET2, '') AS address_line_2,
  NULLIF(SUBURB, '') AS suburb,
  NULLIF(convert_to_integer(POSTCODE), 0) AS post_code,
  NULLIF(convert_to_text(HOMETEL), '') AS home_phone,
  NULLIF(convert_to_text(WORKTEL), '') AS work_phone,
  NULLIF(convert_to_text(FAX), '') AS fax,
  NULLIF(convert_to_text(MOBILETEL), '') AS mobile,
  NULLIF(convert_to_text(SEARCHTEL), '') AS main_phone,
  NULLIF(EMAIL, '') AS email
FROM PBPATMAS
WHERE convert_to_integer(PATNUMBER) >= 99000
`;

export class ThirdPartySourceEntity extends BaseSourceEntity<
  IOasisThirdParty,
  IOasisThirdPartyTranslations,
  IOasisThirdPartyFilters
> {
  sourceEntity = THIRD_PARTY_SOURCE_ENTITY;
  entityResourceType = THIRD_PARTY_RESOURCE_TYPE;
  sourceQuery = THIRD_PARTY_SOURCE_QUERY;
  verifySourceFn = isOasisThirdParty;

  translate(
    _data: IOasisThirdParty,
    _timezone: Timezone
  ): IOasisThirdPartyTranslations {
    return {};
  }

  getSourceRecordId(data: IOasisThirdParty): number {
    return data.id;
  }

  getSourceLabel(data: IOasisThirdParty): string {
    return `${data.id} - ${data.name}`;
  }

  getFilterData(
    _data: IOasisThirdParty,
    _timezone: Timezone
  ): IOasisThirdPartyFilters {
    return {};
  }
}
