import { IPayment } from '@principle-theorem/principle-core/interfaces';
import { AtLeast, toTimestamp } from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';

export class Payment {
  static init(overrides: AtLeast<IPayment, 'paymentType'>): IPayment {
    return {
      uid: uuid(),
      amount: 0,
      paid: false,
      paymentDate: toTimestamp(),
      ...overrides,
    };
  }
}
