import {
  ChartableSurface,
  combineCompatibleToothSurfaces,
  IChartableItem,
  IChartedRef,
} from '@principle-theorem/principle-core/interfaces';
import { compact, uniq, uniqWith } from 'lodash';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SurfaceHierarchy } from './surface-hierarchy/surface-hierarchy';
import { ToothSurfaceHierarchy } from './surface-hierarchy/tooth-surface-hierarchy';

export class ChartableSurfaceResolver {
  static getChartedRefs(
    chartableItem: IChartableItem,
    surfaces: Partial<IChartedRef>[]
  ): Partial<IChartedRef>[] {
    const hierarchy = new ToothSurfaceHierarchy();
    const refs = compact(
      surfaces.map((surface) =>
        this._findCompatibleSurfaceRef(chartableItem, surface, hierarchy)
      )
    );

    if (!refs.length) {
      // eslint-disable-next-line no-console
      console.error('getChartedRefs - No compatible surfaces resolved');
    }

    return uniqWith(
      refs,
      (refA: Partial<IChartedRef>, refB: Partial<IChartedRef>) => {
        if (!refA || !refB) {
          return false;
        }
        const mouthAreaChecks: boolean =
          refA.wholeMouth === refB.wholeMouth &&
          refA.arch === refB.arch &&
          refA.quadrant === refB.quadrant;

        if (!refA.tooth && !refB.tooth) {
          return mouthAreaChecks;
        }

        return (
          mouthAreaChecks &&
          refA.tooth !== undefined &&
          refB.tooth !== undefined &&
          refA.tooth.quadrant === refB.tooth.quadrant &&
          refA.tooth.quadrantIndex === refB.tooth.quadrantIndex &&
          refA.tooth.surface === refB.tooth.surface
        );
      }
    );
  }

  static getChartableSurfaces(
    chartableItem: IChartableItem
  ): ChartableSurface[] {
    const hierarchy: SurfaceHierarchy = new ToothSurfaceHierarchy();
    const surfaces = uniq(
      this._findCompatibleSurfaces(chartableItem, hierarchy)
    );
    return combineCompatibleToothSurfaces(surfaces);
  }

  static filterChartableItems$<T extends IChartableItem>(
    chartableItems$: Observable<T[]>,
    selectedSurfaces$: Observable<Partial<IChartedRef>[]>
  ): Observable<T[]> {
    return selectedSurfaces$.pipe(
      switchMap((selectedSurfaces) => {
        if (!selectedSurfaces.length) {
          return chartableItems$;
        }
        return chartableItems$.pipe(
          map((chartableItems) =>
            this.filterChartableItems(chartableItems, selectedSurfaces)
          )
        );
      })
    );
  }

  static filterChartableItems<T extends IChartableItem>(
    chartableItems: T[],
    selectedSurfaces: Partial<IChartedRef>[]
  ): T[] {
    const hierarchy = new ToothSurfaceHierarchy();
    return chartableItems.filter((chartableItem) => {
      return selectedSurfaces.some((surface) => {
        return this._hasCompatibleSurface(
          chartableItem,
          surface,
          hierarchy,
          true
        );
      });
    });
  }

  private static _hasCompatibleSurface(
    chartableItem: IChartableItem,
    selectedSurface: Partial<IChartedRef>,
    hierarchy: SurfaceHierarchy,
    compareParents: boolean = false
  ): boolean {
    let canBeChartedOn = false;
    const hasCompatibleSurface = hierarchy.hasCompatibleSurface(chartableItem);
    if (hasCompatibleSurface) {
      canBeChartedOn = hierarchy.canBeChartedOn(selectedSurface);
    }

    if (hasCompatibleSurface && canBeChartedOn) {
      return true;
    }

    if (!compareParents) {
      return false;
    }

    return hierarchy.parents.some((parent) => {
      return this._hasCompatibleSurface(
        chartableItem,
        selectedSurface,
        parent,
        compareParents
      );
    });
  }

  // TODO: Tighten up the typings and enforce return type https://app.clickup.com/t/50yqzq
  private static _findCompatibleSurfaceRef(
    chartableItem: IChartableItem,
    selectedSurface: Partial<IChartedRef>,
    hierarchy: SurfaceHierarchy
  ): Partial<IChartedRef> | undefined {
    const hasCompatibleSurface = hierarchy.hasCompatibleSurface(chartableItem);
    if (hasCompatibleSurface && hierarchy.canBeChartedOn(selectedSurface)) {
      return hierarchy.toChartedRef(selectedSurface);
    }

    for (let index = 0; index < hierarchy.parents.length; index++) {
      const compatibleParentSurface = this._findCompatibleSurfaceRef(
        chartableItem,
        selectedSurface,
        hierarchy.parents[index]
      );
      if (compatibleParentSurface) {
        return compatibleParentSurface;
      }
    }
  }

  private static _findCompatibleSurfaces(
    chartableItem: IChartableItem,
    hierarchy: SurfaceHierarchy
  ): ChartableSurface[] {
    const compatibleSurfaces = hierarchy.getCompatibleSurfaces(chartableItem);

    const parentSurfaces: ChartableSurface[] = hierarchy.parents.reduce(
      (surfaces: ChartableSurface[], parent: SurfaceHierarchy) => {
        return [
          ...surfaces,
          ...this._findCompatibleSurfaces(chartableItem, parent),
        ];
      },
      []
    );

    if (!parentSurfaces.length) {
      return compatibleSurfaces;
    }

    return [...compatibleSurfaces, ...parentSurfaces];
  }
}
