import { fromJSON, toJSON } from '@principle-theorem/shared';
import { BehaviorSubject } from 'rxjs';

export class LocalStorage<T> {
  value$: BehaviorSubject<T | undefined>;

  constructor(private _key: string) {
    this.value$ = new BehaviorSubject<T | undefined>(this._read());
  }

  update(data: T): void {
    localStorage.setItem(this._key, toJSON(data));
    this.value$.next(data);
  }

  delete(): void {
    localStorage.removeItem(this._key);
  }

  get value(): T | undefined {
    return this.value$.value;
  }

  private _read(): T | undefined {
    const strValue: string = localStorage.getItem(this._key) || '';
    try {
      return fromJSON(strValue);
    } catch (e) {
      return undefined;
    }
  }
}
