<pr-page-splash>
  <div class="flex flex-col items-center gap-4">
    <h1>User Account Restricted</h1>
    <p class="text-center">
      @if (restrictionReason$ | async; as reason) {
        @if (reason === timeRestriction) {
          @if (restrictedUntil$ | async; as restrictedUntil) {
            Your account has been restricted until
            {{ restrictedUntil | moment | amDateFormat: timeFormat }}.<br />
          } @else {
            Your account has been temporarily restricted by an administrator.<br />
          }
        } @else {
          Your IP address has been temporarily restricted by an
          administrator.<br />
        }
      }
      Please contact your administrator for more information.
    </p>
    <button mat-stroked-button (click)="logout()">
      Return to Login
      <mat-icon>exit_to_app</mat-icon>
    </button>
  </div>
</pr-page-splash>
