import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgFeatureFlagsModule } from '@principle-theorem/ng-feature-flags';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { AutomationComponentsModule } from '../components/components.module';
import { BrandAutomationListComponent } from './brand-automation-list/brand-automation-list.component';

@NgModule({
  declarations: [BrandAutomationListComponent],
  imports: [
    NgSharedModule,
    AutomationComponentsModule,
    NgFeatureFlagsModule,
    ReactiveFormsModule,
    NgPrincipleSharedModule,
  ],
})
export class PagesModule {}
