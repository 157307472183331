<div class="item-header flex">
  <div class="flex flex-1 items-center gap-2">
    @if (waitListItem$ | async; as waitListItem) {
      <div
        class="max-w-[200px] truncate"
        [ptTruncateTooltip]="waitListItem.patientName"
      >
        <a
          *ngrxLet="waitListPatientRef$ as patientRef"
          class="font-bold"
          [prBrandRouterLink]="['patients', patientRef.id]"
        >
          {{ waitListItem.patientName }}
        </a>
      </div>
    }

    @if (urgency$ | async; as urgency) {
      <mat-icon
        class="flag-icon mr-2"
        [ngClass]="urgency"
        [matTooltip]="urgency + ' urgency' | titlecase"
        [matTooltipDisabled]="!urgency"
      >
        flag
      </mat-icon>
    }
  </div>

  <div class="buttons-container flex items-center gap-2.5">
    <button
      mat-icon-button
      matTooltip="View Appointment on Timeline"
      (click)="viewAppointmentOnTimeline()"
    >
      <mat-icon>visibility</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="Edit Waitlist Settings"
      (click)="openWaitlistConfiguration.emit()"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="View/Add Scheduling Notes"
      (click)="showSchedulingNotes.emit()"
    >
      <mat-icon>sticky_note_2</mat-icon>
    </button>
    <button
      *ngIf="!isShortlisted; else shortlisted"
      mat-icon-button
      matTooltip="Add To Shortlist"
      (click)="addToShortlist.emit()"
    >
      <mat-icon>add_circle_outline</mat-icon>
    </button>
    <ng-template #shortlisted>
      <button
        mat-icon-button
        matTooltip="Remove From Shortlist"
        (click)="removeFromShortlist.emit()"
      >
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
    </ng-template>
    <button
      mat-icon-button
      matTooltip="Confirm For Gap"
      (click)="confirmForGap.emit()"
    >
      <mat-icon>check_circle</mat-icon>
    </button>
  </div>
</div>
