import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getError, snapshot } from '@principle-theorem/shared';
import {
  BasicMenuButtonComponent,
  cleanUpBlockCommand,
} from '../../../menu-bar/basic-menu-button/basic-menu-button.component';
import { type IUploader } from '../../uploader';
import { VideoCommandsService } from '../video-commands.service';

@Component({
  selector: 'pt-video-upload-menu',
  templateUrl: './video-upload-menu.component.html',
  styleUrls: ['./video-upload-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoUploadMenuComponent extends BasicMenuButtonComponent {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  override tooltip = 'Add Video';
  override buttonText = 'Add Video';
  override icon = 'video_call';
  @Input()
  uploader: IUploader;

  acceptedTypes: string[] = [
    'video/webp',
    'video/webm',
    'video/mp4',
    'video/quicktime',
    'video/wmv',
  ];

  constructor(
    private _snackBar: MatSnackBar,
    private _videoCommands: VideoCommandsService
  ) {
    super();
  }

  async startUpload(event: Event): Promise<void> {
    const editor = await snapshot(this.editor$);
    const buttonType = await snapshot(this.buttonType$);
    if (buttonType === 'block') {
      cleanUpBlockCommand(editor);
    }

    const element = event.target as HTMLInputElement;
    if (!element.files) {
      return;
    }

    try {
      await this._videoCommands.upload(
        this.uploader,
        editor,
        editor.view.state.selection.$from.pos,
        element.files
      );
      this.fileInput.nativeElement.value = '';
    } catch (error) {
      this._snackBar.open('Sorry, failed to upload video', getError(error));
    }
  }

  override async runCommand(): Promise<void> {
    const editor = await snapshot(this.editor$);
    this.fileInput.nativeElement.click();
    editor.view.focus();
  }
}
