/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createSelector } from '@ngrx/store';
import { type SerialisedData } from '@principle-theorem/shared';
import {
  chartAdapter,
  type ChartId,
} from '../reducers/active-charts/chart-context-state';
import { type IChartState } from '../reducers/reducers';
import { getChartModuleState } from '../selectors';

const { selectEntities } = chartAdapter.getSelectors();

export const getActiveChartsState = createSelector(
  getChartModuleState,
  (state: SerialisedData<IChartState>) => state.activeCharts
);

export const getChartEntities = createSelector(
  getActiveChartsState,
  selectEntities
);

export function getChartEntity(id: ChartId) {
  return createSelector(getChartEntities, (entities) => entities[id]);
}

export function getChartType(id: ChartId) {
  return createSelector(getChartEntity(id), (entity) => entity?.chartType);
}

export function getChartSection(id: ChartId) {
  return createSelector(getChartEntity(id), (entity) => entity?.chartSection);
}

export function getChartView(id: ChartId) {
  return createSelector(getChartEntity(id), (entity) => entity?.chartView);
}

export function getChartIsStacked(id: ChartId) {
  return createSelector(
    getChartEntity(id),
    (entity) => entity?.isStacked ?? false
  );
}

export function getChartContextFilters(id: ChartId) {
  return createSelector(getChartEntity(id), (entity) => entity?.filters);
}

export function getChartContextChartingAs(id: ChartId) {
  return createSelector(getChartEntity(id), (entity) =>
    entity ? entity.chartingAs : undefined
  );
}

export function getChartContextQuickCharting(id: ChartId) {
  return createSelector(getChartContextChartingAs(id), (chartingAs) =>
    chartingAs ? chartingAs.quickChartingConfiguration : undefined
  );
}

/*
 * Render Config Selectors
 */

export function getChartRendererConfig(id: ChartId) {
  return createSelector(getChartEntity(id), (entity) => entity?.renderConfig);
}

export function getSelectedSurfacesState(id: ChartId) {
  return createSelector(
    getChartRendererConfig(id),
    (renderConfig) => renderConfig?.selected
  );
}

export function getDisabledSurfacesState(id: ChartId) {
  return createSelector(
    getChartRendererConfig(id),
    (renderConfig) => renderConfig?.disabled ?? []
  );
}

/*
 * Chart Selectors
 */

export function getClinicalChartState(id: ChartId) {
  return createSelector(getChartEntity(id), (entity) => entity?.chart);
}

export function getPlanProposal(id: ChartId) {
  return createSelector(
    getClinicalChartState(id),
    (chart) => chart?.flaggedTreatment
  );
}

export function getChartedConditions(id: ChartId) {
  return createSelector(
    getClinicalChartState(id),
    (chart) => chart?.conditions
  );
}

export function getChartedTreatments(id: ChartId) {
  return createSelector(getPlanProposal(id), (chart) => chart?.treatments);
}

export function getChartedMultiTreatments(id: ChartId) {
  return createSelector(getPlanProposal(id), (chart) => chart?.multiTreatments);
}
