import { MatTableDataSource } from '@angular/material/table';
import { Subscription, type Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export class ObservableDataSource<
  T extends object = object,
> extends MatTableDataSource<T> {
  /** The data subscription for this table. Stored to manage cleanup on disconnect */
  private _dataSubscription: Subscription = Subscription.EMPTY;
  private _items$: Observable<T[]>;

  /** Stream emitting loading state of the table. */
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  filteredData$: Observable<T[]> = this.connect().asObservable();
  filteredLength$: Observable<number> = this.filteredData$.pipe(
    map((data) => data.length)
  );

  constructor(items$?: Observable<T[]>) {
    super();
    if (items$) {
      this.items$ = items$;
    }
  }

  set items$(items$: Observable<T[]>) {
    this._items$ = items$;
    this._updateDataSubscription();
  }

  get items$(): Observable<T[]> {
    return this._items$;
  }

  /**
   * Used by the MatTable. Called when it connects to the data source.
   * @docs-private
   */
  override connect(): BehaviorSubject<T[]> {
    this._updateDataSubscription();
    return super.connect();
  }

  /**
   * Used by the MatTable. Called when it is destroyed. No-op.
   * @docs-private
   */
  override disconnect(): void {
    this._dataSubscription.unsubscribe();
    super.disconnect();
  }

  update(data: T[]): void {
    this.data = data;
    this.loading$.next(false);
  }

  /**
   * This query method is where we can paginate/sort the data from
   * the firestore side. In this case we would override the paginator
   * and sort properties to instead trigger this method on change.
   * Table filtering as far as I can tell is not available from
   * the firestore backend.
   */
  private _updateDataSubscription(): void {
    this.loading$.next(true);
    this._dataSubscription.unsubscribe();
    if (!this._items$) {
      return;
    }
    this._dataSubscription = this.items$.subscribe((data: T[]) =>
      this.update(data)
    );
  }
}
