import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { type IHealthFundCard } from '@principle-theorem/principle-core/interfaces';
import { type EditMedicareCardComponent } from '../edit-medicare-card/edit-medicare-card.component';
import { ONLY_NUMBERS } from '@principle-theorem/shared';

@Component({
    selector: 'pr-edit-healthfund-card',
    templateUrl: './edit-healthfund-card.component.html',
    styleUrls: ['./edit-healthfund-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditHealthFundCardComponent {
  healthFundForm = new TypedFormGroup<IHealthFundCard>({
    fundCode: new TypedFormControl<string>(''),
    membershipNumber: new TypedFormControl<string>('', [
      Validators.pattern(ONLY_NUMBERS),
    ]),
    memberNumber: new TypedFormControl<string>('', [
      Validators.pattern(ONLY_NUMBERS),
      Validators.maxLength(2),
    ]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) data: IHealthFundCard | undefined,
    private _dialogRef: MatDialogRef<
      EditMedicareCardComponent,
      IHealthFundCard | undefined
    >
  ) {
    if (data) {
      this.healthFundForm.patchValue(data);
    }
  }

  submit(): void {
    if (this.healthFundForm.invalid) {
      return;
    }
    const formValue = this.healthFundForm.getRawValue();
    this._dialogRef.close(formValue);
  }
}
