import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  type IAppointment,
  type IEvent,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { Appointment } from '@principle-theorem/principle-core';
import {
  DEFAULT_INCREMENT,
  MINIMUM_DURATION,
  snapshot,
  STEP_SIZE,
  toMoment,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IUpdateAppointmentDurationData {
  appointment: WithRef<IAppointment>;
  patient: WithRef<IPatient>;
}

@Component({
  selector: 'pr-update-appointment-duration',
  templateUrl: './update-appointment-duration.component.html',
  styleUrls: ['./update-appointment-duration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateAppointmentDurationComponent {
  duration$ = new ReplaySubject<number>(1);
  endTime$: Observable<Moment>;
  max: number;
  min = MINIMUM_DURATION;
  step = STEP_SIZE;
  tickInterval = DEFAULT_INCREMENT;

  constructor(
    private _dialogRef: MatDialogRef<
      UpdateAppointmentDurationComponent,
      IEvent
    >,
    @Inject(MAT_DIALOG_DATA) public data: IUpdateAppointmentDurationData
  ) {
    const duration = Appointment.duration(this.data.appointment);
    this.duration$.next(duration);
    this.max = duration + 60;

    this.endTime$ = this.duration$.pipe(
      map((newDuration) =>
        toMoment(this.data.appointment.event?.from ?? moment()).add(
          newDuration,
          'minutes'
        )
      )
    );
  }

  async submit(): Promise<void> {
    const originalDuration = Appointment.duration(this.data.appointment);
    const newDuration = await snapshot(this.duration$);
    const event = this.data.appointment.event;

    if (originalDuration === newDuration || !event) {
      this._dialogRef.close();
      return;
    }

    this._dialogRef.close({
      ...event,
      to: toTimestamp(toMoment(event.from).add(newDuration, 'minutes')),
    });
  }

  displayWith(duration: number): string {
    return `${duration}m`;
  }
}
