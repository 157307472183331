import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { from, Observable } from 'rxjs';
import { filterUndefined, snapshot, WithRef } from '@principle-theorem/shared';
import {
  IInteractionV2,
  IPractice,
  MentionResourceType,
  SMS_SEGMENT_SIZE,
} from '@principle-theorem/principle-core/interfaces';
import {
  addInteractions,
  Patient,
  toMention,
} from '@principle-theorem/principle-core';
import { getSchemaText, MixedSchema } from '@principle-theorem/editor';
import { EditorPresetsService } from '../../editor/editor-presets.service';
import { SendSMSService } from '../send-sms.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ISystemTemplateInteractionDialogData,
  SystemTemplateInteractionDialogComponent,
} from '../../system-template-interaction-dialog/system-template-interaction-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  isDisabled$,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { Validators } from '@angular/forms';

interface IFormData {
  practice: WithRef<IPractice>;
  content: MixedSchema;
}

@Component({
    selector: 'pr-system-sms-dialog',
    templateUrl: './system-sms-dialog.component.html',
    styleUrls: ['./system-sms-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SystemSmsDialogComponent extends SystemTemplateInteractionDialogComponent {
  mobileNumber$: Observable<string | undefined>;
  isDisabled$: Observable<boolean>;
  segmentSize = SMS_SEGMENT_SIZE;
  smsForm = new TypedFormGroup<IFormData>({
    practice: new TypedFormControl<WithRef<IPractice>>(
      undefined,
      Validators.required
    ),
    content: this.noteCtrl,
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: ISystemTemplateInteractionDialogData,
    currentScope: CurrentScopeFacade,
    private _organisation: OrganisationService,
    private _smsService: SendSMSService,
    private _editorPresets: EditorPresetsService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<
      SystemSmsDialogComponent,
      IInteractionV2 | undefined
    >
  ) {
    super(data, currentScope);

    this.extensions = this._editorPresets.defaultToTextExtensions();
    this.mobileNumber$ = from(Patient.resolveMobileNumber(this.data.patient));
    this.isDisabled$ = isDisabled$(this.smsForm);
  }

  async send(): Promise<void> {
    this.submitting$.next(true);
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const formData = this.smsForm.getRawValue();

    const error = await this._smsService.sendMessage(
      getSchemaText(formData.content),
      this.data.patient,
      staffer.ref,
      formData.practice.ref
    );

    if (error) {
      this.submitting$.next(false);
      this._snackBar.open(error.message);
      this.dialogRef.close();
      return;
    }

    const contact = toMention(this.data.patient, MentionResourceType.Patient);
    const interaction = this._smsService.getSMSMessage(
      staffer,
      contact,
      formData.content
    );
    await addInteractions({ contact }, interaction);

    this._snackBar.open('SMS Sent Successfully');
    this.dialogRef.close(interaction);
    this.submitting$.next(false);
  }
}
