@if (display$ | async; as display) {
  <div
    class="flex h-12 w-48 flex-col items-start justify-center gap-1 rounded-lg p-1"
    [ngClass]="[display.textColour, display.backgroundColour]"
  >
    @if (snapshotSummary$ | async; as snapshotSummary) {
      <div class="text-xs">{{ snapshotSummary.practitionerName }}</div>
      <div class="text-xs">
        {{ snapshotSummary.from | moment | amDateFormat: dateFormat }}
        •
        {{ snapshotSummary.from | moment | amDateFormat: timeFormat }}
        •
        {{ snapshotSummary | map: getDuration : this }} mins
      </div>
    } @else {
      <div class="text-xs">Unscheduled</div>
    }
  </div>
}
