interface ITypesenseNode {
  host: string;
  port: number;
  protocol: string;
}

export interface ITypesenseConfig {
  nodes: ITypesenseNode[];
  apiKey: string;
  clusterName?: string;
}
