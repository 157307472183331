import { ElementRef } from '@angular/core';
import {
  isSameToothRef,
  labelToToothRef,
  toothRefToLabel,
} from '@principle-theorem/principle-core';
import {
  IPerioTable,
  IPerioTableCell,
  IToothRef,
  PERIO_ICON_MAP,
  PERIO_ICON_MEASUREMENTS,
  PERIO_MEASUREMENT_MAX_VALUE,
  PerioMeasurement,
} from '@principle-theorem/principle-core/interfaces';
import { groupBy, isNumber } from 'lodash';
import { PerioRecord } from './perio-record';
import { PerioTable } from './perio-table';

export class PerioTableByTooth {
  static groupByTooth(table: IPerioTable): IPerioTable[] {
    const cellsByTooth = PerioTableByTooth.groupCellsByTooth(table.rows.flat());
    const tableByTooth = PerioTableByTooth.createToothSpecificTables(
      cellsByTooth,
      table
    );
    const missingTeeth = PerioTableByTooth.addMissingTeeth(table.missingTeeth);
    return [...tableByTooth, ...missingTeeth].sort((a, b) =>
      PerioRecord.sortByQuadrantDisplayOrder(
        labelToToothRef(a.label),
        labelToToothRef(b.label)
      )
    );
  }

  static addMissingTeeth(missingTeeth: IToothRef[]): IPerioTable[] {
    return missingTeeth.map((tooth) => ({
      label: toothRefToLabel(tooth),
      rows: [],
      disabled: true,
      missingTeeth: [],
      implants: [],
    }));
  }

  static filterMissingTeeth(
    missingTeeth: IToothRef[],
    tables: IPerioTable[]
  ): IPerioTable[] {
    return tables.filter(
      (table) =>
        !missingTeeth.some((tooth) =>
          isSameToothRef(tooth, labelToToothRef(table.label))
        )
    );
  }

  static groupCellsByMeasurement(cells: IPerioTableCell[]): {
    [key: string]: IPerioTableCell[];
  } {
    return groupBy(cells, (cell) => cell.metadata.measurement);
  }

  static groupCellsByTooth(cells: IPerioTableCell[]): {
    [key: string]: IPerioTableCell[];
  } {
    return groupBy(cells, (cell) => toothRefToLabel(cell.metadata.toothRef));
  }

  static createToothSpecificTables(
    cellsByTooth: { [key: string]: IPerioTableCell[] },
    baseTable: IPerioTable
  ): IPerioTable[] {
    return Object.entries(cellsByTooth).map(([tooth, cells]) => {
      const rows = PerioTableByTooth.groupCellsByMeasurement(cells);
      return {
        ...baseTable,
        label: tooth,
        rows: Object.values(rows),
      };
    });
  }

  static isIconMeasurment(measurement: PerioMeasurement): boolean {
    return PERIO_ICON_MEASUREMENTS.includes(measurement);
  }

  static getIconClass(
    value: number | undefined,
    measurement: PerioMeasurement
  ): string {
    if (!value || !PerioTableByTooth.isIconMeasurment(measurement)) {
      return '';
    }

    const maxValue = PERIO_MEASUREMENT_MAX_VALUE[measurement] as number;
    const level = value > maxValue ? maxValue : value;
    const mapKey = `${measurement}-${level}`;
    return PERIO_ICON_MAP[mapKey] || '';
  }

  static getElementByIndex(
    index: number,
    navigationMap: Map<number, number>,
    inputs: ElementRef<HTMLInputElement>[]
  ): ElementRef<HTMLInputElement> | undefined {
    const elementIndex = navigationMap.get(index);
    if (elementIndex === undefined) {
      return undefined;
    }
    return inputs[elementIndex];
  }

  static shouldUpdateCalValue(measurement: PerioMeasurement): boolean {
    return (
      measurement === PerioMeasurement.Pocket ||
      measurement === PerioMeasurement.Recession
    );
  }

  static updateCalValue(table: IPerioTable, colIndex: number): IPerioTable {
    const pocketIndex = PerioTable.getMeasurementRowIndex(
      table,
      PerioMeasurement.Pocket
    );
    const recessionIndex = PerioTable.getMeasurementRowIndex(
      table,
      PerioMeasurement.Recession
    );
    const calIndex = PerioTable.getMeasurementRowIndex(
      table,
      PerioMeasurement.CAL
    );

    const pocketValue = table.rows[pocketIndex][colIndex].value;
    const recessionValue = table.rows[recessionIndex][colIndex].value;

    table.rows[calIndex][colIndex].value =
      isNumber(pocketValue) && isNumber(recessionValue)
        ? pocketValue + recessionValue
        : undefined;

    return table;
  }
}
