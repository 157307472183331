<pr-interactions
  editable
  [pinnableTypes]="pinnableTypes"
  [compact]="compact$ | async"
  [enableScroll]="enableScroll$ | async"
  [interactions]="interactions$ | async"
  [pinnedInteractions]="pinnedInteractions$ | async"
  (interactionAdded)="addInteraction($event)"
  (interactionUpdated)="updateInteraction($event)"
  (interactionDeleted)="deleteInteraction($event)"
  [readOnly]="readOnly$ | async"
/>
