import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import {
  ChartType,
  type IClinicalChart,
} from '@principle-theorem/principle-core/interfaces';
import { first } from 'lodash';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { type IChartHistoryItem } from '../charting-history-selector/chart-history-list';
import { getEnumValues } from '@principle-theorem/shared';

@Component({
  selector: 'pr-charting-history-list',
  templateUrl: './charting-history-list.component.html',
  styleUrls: ['./charting-history-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartingHistoryListComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  loaded$ = new BehaviorSubject<boolean>(false);
  selectedItem$ = new BehaviorSubject<IChartHistoryItem | undefined>(undefined);
  items$ = new ReplaySubject<IChartHistoryItem[]>(1);
  chartType$ = new ReplaySubject<ChartType>(1);
  selectedChartTypeIndex$: Observable<number>;
  chartTypeOption = ChartType;
  @Output() chartSelected = new EventEmitter<IClinicalChart>();
  @Output() addNewChart = new EventEmitter<void>();
  @Output() chartTypeSelected = new EventEmitter<ChartType>();

  @Input()
  set items(items: IChartHistoryItem[]) {
    if (items) {
      this.items$.next(items);
    }
  }

  @Input()
  set chartType(chartType: ChartType) {
    if (chartType) {
      this.chartType$.next(chartType);
    }
  }

  constructor() {
    this.items$
      .pipe(
        map((items) => first(items)),
        withLatestFrom(this.selectedItem$),
        filter(([_item, selected]) => !selected),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([item, _]) => this.selectChartHistory(item));

    this.selectedChartTypeIndex$ = this.chartType$.pipe(
      map((chartType) => getEnumValues(ChartType).indexOf(chartType))
    );
  }

  selectChartHistory(selected: IChartHistoryItem | undefined): void {
    this.selectedItem$.next(selected);
    this.chartSelected.emit(selected?.value);
    this.loaded$.next(true);
  }

  addFirstChart(): void {
    this.loaded$.next(false);
    this.addNewChart.emit();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  setChartType(index: number): void {
    const type = index === 0 ? ChartType.Clinical : ChartType.Periodontal;
    this.chartTypeSelected.emit(type);
  }
}
