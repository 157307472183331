<pr-appointment-selector-form
  [openTime]="openTime$ | async"
  [closeTime]="closeTime$ | async"
  (valueChange)="filterOptionsChange($event)"
 />

<mat-progress-bar
  mode="indeterminate"
  *ngIf="loading$ | async"
 />

<div class="options-container">
  <ng-template #options>
    <ng-container
      *ngIf="appointmentSuggestions$ | async as appointmentSuggestions"
    >
      <ng-container *ngIf="appointmentSuggestions.length; else noOptions">
        <pr-appointment-options
          [options]="appointmentSuggestions"
          [treatmentDuration]="selectedTreatmentDuration$ | async"
          [selected]="selectedEvent$ | async"
          (selectedChange)="selectEvent($event)"
         />
      </ng-container>
    </ng-container>

    <ng-template #noOptions>
      <div
        fxFill
        class="options-empty"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <h2 class="mat-headline-5">No appointment options found</h2>
      </div>
    </ng-template>
  </ng-template>

  <ng-container *ngIf="loading$ | async; else options">
    <pr-appointment-options-placeholder />
  </ng-container>
</div>
