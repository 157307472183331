<div *ngIf="(medicalFormAlerts.isLoading$ | async) === false">
  <div class="flex flex-wrap items-center gap-2">
    <div
      class="flex cursor-pointer items-center gap-1 rounded-md bg-red-500 py-px pl-1 pr-3 text-sm text-white"
      matRipple
      (click)="viewMedicalHistory()"
    >
      <mat-icon>local_hospital</mat-icon>
      @if (medicalFormAlerts.hasMedicalHistory$ | async) {
        <span>{{ medicalFormAlerts.alertCount$ | async }} Medical Alerts</span>
      } @else {
        <span>No Medical History</span>
      }
    </div>

    @if (medicalFormAlerts.lastUpdated$ | async; as lastUpdated) {
      <div class="text-xs opacity-50">
        Last Updated
        {{ lastUpdated | moment | amDateFormat: dateFormatShort }}
      </div>
    }

    <div class="flex-auto text-xs">
      <a [routerLink]="[]" (click)="issueAndUpdate()"> Update Now </a>
    </div>

    @if (expandable) {
      <button
        *ngIf="medicalFormAlerts.hasAlerts$ | async"
        mat-icon-button
        (click)="content.toggle()"
        class="icon-md"
      >
        <mat-icon>
          {{ content.expanded ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </button>
    }
  </div>

  <pt-collapsible #content [expanded]="expanded$ | async">
    <ng-template ptCollapsibleContent>
      @if (medicalFormAlerts.hasAlerts$ | async) {
        <div class="mt-2">
          <pr-patient-medical-alert-details
            [conditions]="medicalFormAlerts.conditions$ | async"
            [allergies]="medicalFormAlerts.allergies$ | async"
            [history]="medicalFormAlerts.history$ | async"
          />
        </div>
      }
    </ng-template>
  </pt-collapsible>
</div>
