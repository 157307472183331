import { toMentionContent, toTextContent } from '@principle-theorem/editor';
import {
  Appointment,
  Event,
  Interaction,
  TimezoneResolver,
  removeCandidateFromAllShortlists,
  removeConflictingCandidates,
  toMention,
} from '@principle-theorem/principle-core';
import {
  EventType,
  IInteractionV2,
  IPractice,
  ISchedulingEventData,
  InteractionType,
  MentionResourceType,
  type IAppointment,
  type IBrand,
  type ICandidate,
  type ICandidateCalendarEvent,
  type IEvent,
  type IPatient,
  type IStaffer,
  IScheduleSummaryEventable,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  asyncForEach,
  type WithRef,
  toMomentTz,
  DATE_TIME_FORMAT,
  deleteDoc,
} from '@principle-theorem/shared';
import {
  convertWaitListDetailsToItem,
  type IAppointmentDetails,
} from '../appointment-store';
import {
  AppointmentManager,
  type IWithEvent,
  type IWithWaitList,
} from '../appointment-store/appointment-management';

export interface IMoveAppointmentData {
  gap: IScheduleSummaryEventable;
  brand: WithRef<IBrand>;
  patient: WithRef<IPatient>;
  appointment: WithRef<IAppointment>;
  gapCandidate: WithRef<ICandidateCalendarEvent>;
}

export interface IMoveAppointmentSideBarData {
  practice: WithRef<IPractice>;
  appointment: WithRef<IAppointment>;
  patient: WithRef<IPatient>;
  gapCandidate: WithRef<ICandidateCalendarEvent>;
}

export type IGapManagerFormData = IAppointmentDetails &
  Partial<IWithWaitList> &
  IWithEvent;

export class GapManager {
  static async approveCandidate(
    staffer: WithRef<IStaffer>,
    data: IMoveAppointmentData,
    form: IGapManagerFormData,
    schedulingEventData: ISchedulingEventData
  ): Promise<void> {
    const event = form.event;
    if (!event) {
      return;
    }
    const timezone = await TimezoneResolver.fromEvent(event);
    const appointment = await Firestore.getDoc(data.appointment.ref);
    const appointmentRef = await AppointmentManager.move(
      staffer,
      data.patient,
      appointment,
      {
        ...form,
        event,
        automations: [],
        schedulingEventData,
      }
    );
    const updatedAppointment = await Firestore.getDoc(appointmentRef);

    const interactions = await this.buildApproveCandidateInteractions(
      staffer,
      data.patient,
      event
    );
    await asyncForEach(interactions, (interaction) =>
      Appointment.addInteraction(updatedAppointment, interaction)
    );

    await Firestore.patchDoc(updatedAppointment.ref, {
      waitListItem: convertWaitListDetailsToItem(timezone, form.waitListItem),
    });
    await removeCandidateFromAllShortlists(data.gapCandidate, data.brand);
    await removeConflictingCandidates(data.gapCandidate);
    await deleteDoc(data.gapCandidate.ref);
  }

  static async buildApproveCandidateInteractions(
    staffer: WithRef<IStaffer>,
    patient: WithRef<IPatient>,
    event: IEvent
  ): Promise<IInteractionV2[]> {
    const timezone = await TimezoneResolver.fromEvent(event);
    const dateFormatted = toMomentTz(event.from, timezone).format(
      DATE_TIME_FORMAT
    );

    return [
      Interaction.init({
        type: InteractionType.Gap,
        title: [
          toMentionContent(toMention(patient, MentionResourceType.Patient)),
          toTextContent(` approved for gap`),
        ],
      }),
      Interaction.init({
        type: InteractionType.AppointmentReschedule,
        title: [
          toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
          toTextContent(` rescheduled appointment to ${dateFormatted}`),
        ],
      }),
    ];
  }

  static copyGapEventToAppointment(
    gap: IScheduleSummaryEventable,
    candidate: ICandidate,
    appointment: WithRef<IAppointment>
  ): void {
    const practitioner = Event.staff(gap.event)[0];
    const event: IEvent = Event.init({
      ...gap.event,
      from: candidate.offerTimeFrom,
      to: candidate.offerTimeTo,
      type: EventType.Appointment,
      organiser: practitioner,
    });
    appointment.practitioner = practitioner;

    Appointment.replaceEvent(appointment, event);
  }
}
