import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  Signal,
  type OnDestroy,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import {
  TypedFormControl,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import {
  DynamicFormType,
  IDynamicFormSelect,
  type IDynamicForm,
  type IDynamicFormControl,
} from '@principle-theorem/principle-core/interfaces';
import { keys, toPairs } from 'lodash';
import { ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export interface IDynamicField<
  V = unknown,
  T extends IDynamicFormControl<V> = IDynamicFormControl<V>,
> {
  key: string;
  data: T;
  ctrl: TypedFormControl<V>;
}

@Component({
  selector: 'pr-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  dynamicForm$ = new ReplaySubject<IDynamicForm>(1);
  fields: Signal<IDynamicField[] | undefined>;
  formGroup = new FormGroup({});
  @Output() formChange = new EventEmitter<unknown>();

  @Input()
  set dynamicForm(dynamicForm: IDynamicForm) {
    if (dynamicForm) {
      this.dynamicForm$.next(dynamicForm);
    }
  }

  constructor() {
    const fields$ = this.dynamicForm$.pipe(
      map((dynamicForm) => this._getFields(dynamicForm))
    );
    fields$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((dynamicForm) => this._updateForm(dynamicForm));

    this.fields = toSignal(fields$);

    validFormGroupChanges$(this.formGroup)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => this.formChange.emit(value));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  isSelectField(
    field: IDynamicField<unknown>
  ): field is IDynamicField<unknown, IDynamicFormSelect<unknown>> {
    return field.data.type === DynamicFormType.Select;
  }

  private _getFields(dynamicForm: IDynamicForm): IDynamicField[] {
    return toPairs(dynamicForm).map(([key, data]) => {
      const ctrl = new TypedFormControl<unknown>(data.initialValue);
      return { key, data, ctrl };
    });
  }

  private _updateForm(fields: IDynamicField[]): void {
    keys(this.formGroup.controls).map((key) =>
      this.formGroup.removeControl(key, { emitEvent: false })
    );
    fields.map((field) =>
      this.formGroup.addControl(field.key, field.ctrl, { emitEvent: false })
    );
  }
}
