import { rand, randBoolean, randNumber } from '@ngneat/falso';
import { type IBrandStats } from '@principle-theorem/reporting';
import * as moment from 'moment-timezone';
import { BrandStatistics } from './brand-statistics';
import { PatientStatisticsMocker } from './patient-statistics.mock';
import { PracticeStatisticsMocker } from './practice-statistics.mock';
import { ReferrerStatisticsMocker } from './referrer-statistics.mock';
import { StatisticsMocker } from './statistics-mocker';

export const MOCK_BRANDS: string[] = [
  'Dental Collective',
  'Tooth Brigade',
  'Decay Busters',
  'Shogunate of Dentistry',
  'Odontology Agency',
  'Cementum',
  'The Alveolar Process',
];

export class BrandStatisticsMocker extends StatisticsMocker<
  IBrandStats,
  BrandStatistics
> {
  constructor() {
    super(MOCK_BRANDS);
  }

  mockStatistic(name: string, date: moment.Moment): BrandStatistics {
    const metrics: BrandStatistics = new BrandStatistics();
    metrics.name = name;
    metrics.date = moment(date);
    metrics.stats.ebit.metric = randNumber({ max: 60000 });
    metrics.stats.revenue.metric = randNumber({ max: 30000 });
    metrics.stats.dentalDraw.metric = randNumber({ max: 20000 });
    metrics.stats.staffExpenses.metric = randNumber({ max: 15000 });
    metrics.stats.consumablesExpended.metric = randNumber({ max: 1000 });
    metrics.stats.overheads.metric = randNumber({ max: 5000 });
    metrics.stats.capitalInvestmentCharge.metric = 15000;
    metrics.stats.revenueKPI.metric = 14000;
    metrics.stats.practitionerProductivity.metric = randNumber({
      min: 5000,
      max: 10000,
    });
    metrics.stats.fraudScore.metric = randBoolean() ? Math.random() : 0;
    metrics.stats.yearOpened.metric = rand([1998, 2012]);

    metrics.practices = new PracticeStatisticsMocker().mock(date, date);
    metrics.patients = new PatientStatisticsMocker().mock(date, date);
    metrics.referrers = new ReferrerStatisticsMocker().mock(date, date);
    return metrics;
  }
}
