<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="practitioner" sticky>
    <th mat-header-cell *matHeaderCellDef class="w-80">Practitioner</th>
    <td mat-cell *matCellDef="let record" class="w-80">
      <a href="javascript:void(0);" (click)="store.selectSummary(record)">
        {{ record.practitioner.name }}
      </a>
    </td>
    <td mat-footer-cell *matFooterCellDef class="w-80 font-bold">
      @if (footerData && footerData.practitioner) {
        {{ footerData.practitioner }}
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="reservedAccountCreditAmount">
    <th mat-header-cell *matHeaderCellDef class="w-52">
      <pr-value-with-icon>
        Reserved Account Credits
        <mat-icon [matTooltip]="tooltips.reservedAccountCredit">info</mat-icon>
      </pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record" class="w-52">
      <pr-value-with-icon>
        {{ record.accountCredits.total.reservedAccountCreditAmount | currency }}
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef class="w-52 font-bold">
      @if (footerData) {
        <pr-value-with-icon>
          {{ footerData.reservedAccountCreditAmount | currency }}
        </pr-value-with-icon>
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="accountCreditAmount">
    <th mat-header-cell *matHeaderCellDef class="w-52">
      <pr-value-with-icon>
        Account Credits Used
        <mat-icon [matTooltip]="tooltips.accountCreditUsed">info</mat-icon>
      </pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record" class="w-52">
      <pr-value-with-icon>
        {{ record.transactions.total.accountCreditAmount | currency }}
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef class="w-52 font-bold">
      @if (footerData) {
        <pr-value-with-icon>
          {{ footerData.accountCreditAmount | currency }}
        </pr-value-with-icon>
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="discountAmount">
    <th mat-header-cell *matHeaderCellDef class="w-52">
      <pr-value-with-icon>
        Discounts Given
        <mat-icon [matTooltip]="tooltips.discountsGiven">info</mat-icon>
      </pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record" class="w-52">
      <pr-value-with-icon>
        {{ record.transactions.total.discountAmount | currency }}
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef class="w-52 font-bold">
      @if (footerData) {
        <pr-value-with-icon>
          {{ footerData.discountAmount | currency }}
        </pr-value-with-icon>
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="paymentAmount">
    <th mat-header-cell *matHeaderCellDef class="w-52">
      <pr-value-with-icon>
        Payments
        <mat-icon [matTooltip]="tooltips.payments">info</mat-icon>
      </pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record" class="w-52">
      <pr-value-with-icon>
        {{ record.transactions.total.paymentAmount | currency }}
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef class="w-52 font-bold">
      @if (footerData) {
        <pr-value-with-icon>
          {{ footerData.paymentAmount | currency }}
        </pr-value-with-icon>
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="practitionerProportionAmount">
    <th mat-header-cell *matHeaderCellDef class="w-52">
      <pr-value-with-icon
        >Practitioner Proportion
        <mat-icon [matTooltip]="tooltips.practitionerProportion">info</mat-icon>
      </pr-value-with-icon>
    </th>
    <td mat-cell *matCellDef="let record" class="w-52">
      <pr-value-with-icon
        [ngClass]="{
          refund: record.transactions.total.practitionerProportionAmount < 0
        }"
      >
        {{ record.transactions.total.practitionerProportionAmount | currency }}
      </pr-value-with-icon>
    </td>
    <td mat-footer-cell *matFooterCellDef class="w-52 font-bold">
      @if (footerData) {
        <pr-value-with-icon>
          {{ footerData.practitionerProportionAmount | currency }}
        </pr-value-with-icon>
      }
    </td>
  </ng-container>

  @if (showHeaders) {
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  }
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  @if (footerData) {
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  }
</table>
