<div fxLayout="row wrap" fxLayoutGap="4px">
  <ng-container *ngIf="data$ | async as data">
    <ng-container
      *ngFor="
        let action of actions$ | async;
        trackBy: trackByAction;
        first as isFirst
      "
    >
      <pt-loader-button
        type="stroked"
        *ngIf="data | map : action.canDo$ : action | async"
        [matTooltipDisabled]="!action.tooltip"
        [matTooltip]="action.tooltip"
        [loading]="action.inProgress$ | async"
        (clicked)="action.do(data)"
      >
        {{ action.label }}
        <ng-container
          *ngFor="
            let info of [action, data] | map : getInfo$ | async;
            trackBy: trackByInfo
          "
        >
          {{ info }}
        </ng-container>
      </pt-loader-button>
    </ng-container>
  </ng-container>
</div>
