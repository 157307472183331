<div
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="16px"
  *ngIf="task$ | async as task"
>
  <pr-automation-configuration-status dense [automation]="task" />

  <div fxFlex class="task">
    <h3>
      <pr-content-view [content]="task.title" />
    </h3>
    <p *ngIf="!task.isForTeam" class="mat-caption">
      {{ task.assignee?.name }}
    </p>
    <p *ngIf="task.isForTeam" class="mat-caption">
      {{ task.assignee?.name }} Team
    </p>
    <p class="mat-caption">
      {{ task.timing | prTimingDisplay }}
    </p>
  </div>
  <ng-content></ng-content>
</div>
