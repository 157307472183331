import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { ObservableDataSource } from '@principle-theorem/ng-shared';
import { PRACTITIONER_INCOME_TOOLTIPS } from '@principle-theorem/reporting';
import { type ITimePeriod } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';
import {
  type IReferralSourcesGrouping,
  ReferralSourcesReportStore,
} from '../referral-sources-report.store';
import { ReferralSourcesSummaryToCSV } from './referral-sources-summary-to-csv';

@Component({
    selector: 'pr-referral-sources-report-summary',
    templateUrl: './referral-sources-report-summary.component.html',
    styleUrls: ['./referral-sources-report-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ReferralSourcesReportSummaryComponent implements OnDestroy {
  tooltips = PRACTITIONER_INCOME_TOOLTIPS;
  dataSource: ObservableDataSource<IReferralSourcesGrouping>;
  displayedColumns = ['referralSource', 'patientCount', 'treatmentTotal'];
  summaries$ = new ReplaySubject<IReferralSourcesGrouping[]>(1);
  dateRange$ = new ReplaySubject<ITimePeriod>(1);
  csvTranslator = new ReferralSourcesSummaryToCSV();

  @Input()
  set dateRange(dateRange: ITimePeriod) {
    if (dateRange) {
      this.dateRange$.next(dateRange);
    }
  }

  @Input()
  set summaries(summaries: IReferralSourcesGrouping[]) {
    if (summaries) {
      this.summaries$.next(summaries);
    }
  }

  constructor(public store: ReferralSourcesReportStore) {
    this.dataSource = new ObservableDataSource(this.summaries$);
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }
}
