import { BlockNodes, IMenuButton } from '@principle-theorem/editor';
import {
  ISubmenuButton,
  type MenuButtonLoaderFn,
} from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../menu-bar/basic-menu-button/basic-menu-button.component';
import { AISubmenuButtonComponent } from './ai-submenu-button/ai-submenu-button.component';
import { createAdjustToneButton } from './buttons/adjust-tone-button';
import { createAutocompleteButton } from './buttons/autocomplete-button';
import { createEmojifyButton } from './buttons/emojify-button';
import { createExtendButton } from './buttons/extend-button';
import { createFixSpellingAndGrammarButton } from './buttons/fix-spelling-and-grammar-button';
import { createImagePromptButton } from './buttons/image-prompt-button';
import { createRephraseButton } from './buttons/rephrase-button';
import { createShortenButton } from './buttons/shorten-button';
import { createSimplifyButton } from './buttons/simplify-button';
import { createSummarizeButton } from './buttons/summarise-button';
import { createTextPromptButton } from './buttons/text-prompt-button';
import { createTldrButton } from './buttons/tldr-button';

export function createAIMenuButton(
  submenuItems: MenuButtonLoaderFn[] = []
): MenuButtonLoaderFn<BasicMenuButtonComponent, ISubmenuButton> {
  return () => ({
    component: AISubmenuButtonComponent,
    data: {
      buttonType: 'submenu',
      compact: true,
      command: () => {
        //
      },
      submenuItems,
      buttonText: 'Smart AI',
      tooltip: 'Smart AI',
      icon: 'auto_awesome',
      canActivateFn: (editor) => editor.isActive(BlockNodes.Paragraph),
    },
  });
}

export interface IEditorAISubmenuButtons {
  adjustTone: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  autocomplete: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  emojify: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  extend: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  fixSpellingAndGrammar: () => MenuButtonLoaderFn<
    BasicMenuButtonComponent,
    IMenuButton
  >;
  textPrompt: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  rephrase: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  shorten: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  simplify: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  summarize: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  tldr: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
  imagePrompt: () => MenuButtonLoaderFn<BasicMenuButtonComponent, IMenuButton>;
}

export const editorAISubmenuButtons: IEditorAISubmenuButtons = {
  adjustTone: createAdjustToneButton,
  autocomplete: createAutocompleteButton,
  emojify: createEmojifyButton,
  extend: createExtendButton,
  fixSpellingAndGrammar: createFixSpellingAndGrammarButton,
  rephrase: createRephraseButton,
  shorten: createShortenButton,
  simplify: createSimplifyButton,
  summarize: createSummarizeButton,
  tldr: createTldrButton,
  textPrompt: createTextPromptButton,
  imagePrompt: createImagePromptButton,
};
