<div *ngIf="(medicalFormAlerts.isLoading$ | async) === false">
  <div class="flex flex-wrap items-center gap-2">
    <div
      class="flex cursor-pointer items-center gap-1 rounded-md bg-red-500 py-px pl-1 pr-3 text-sm text-white"
      matRipple
      (click)="medicalFormAlerts.viewMedicalHistory()"
    >
      <mat-icon>local_hospital</mat-icon>
      <ng-container
        *ngIf="
          medicalFormAlerts.hasMedicalHistory$ | async;
          else noMedicalHistory
        "
      >
        <span>{{ medicalFormAlerts.alertCount$ | async }} Medical Alerts</span>
      </ng-container>
      <ng-template #noMedicalHistory>
        <span>No Medical History</span>
      </ng-template>
    </div>
    <div
      class="text-xs opacity-50"
      *ngIf="medicalFormAlerts.lastUpdated$ | async as lastUpdated"
    >
      Last Updated
      {{ lastUpdated | moment | amDateFormat: dateFormatShort }}
    </div>
    <div class="flex-auto text-xs">
      <a [routerLink]="medicalFormAlerts.medicalHistoryLink$ | async">
        Update Now
      </a>
    </div>
    <div *ngIf="expandable">
      <button
        *ngIf="medicalFormAlerts.hasAlerts$ | async"
        mat-icon-button
        (click)="content.toggle()"
        class="icon-md"
      >
        <mat-icon>
          {{ content.expanded ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </button>
    </div>
  </div>

  <pt-collapsible #content [expanded]="expanded$ | async">
    <ng-template ptCollapsibleContent>
      <div class="mt-2" *ngIf="medicalFormAlerts.hasAlerts$ | async">
        <pr-patient-medical-alert-details
          [conditions]="medicalFormAlerts.conditions$ | async"
          [allergies]="medicalFormAlerts.allergies$ | async"
          [history]="medicalFormAlerts.history$ | async"
        />
      </div>
    </ng-template>
  </pt-collapsible>
</div>
