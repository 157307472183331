import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  type RawInlineNodes,
  toMentionContent,
} from '@principle-theorem/editor';
import { type ICreateAction } from '@principle-theorem/ng-interactions';
import {
  CurrentStafferScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  type IAppointment,
  type IPatient,
  type IPractice,
  type IPrincipleMention,
  isAppointment,
  isPatient,
  isStaffer,
  type IStaffer,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  Appointment,
  fromMention,
  toMention,
} from '@principle-theorem/principle-core';
import {
  filterUndefined,
  isWithRef,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import {
  type ITaskInitialFormData,
  TaskFormComponent,
} from './components/task-form/task-form.component';

@Injectable()
export class CreateTaskActionService implements ICreateAction {
  icon = 'add';
  name = 'New Task';

  constructor(
    private _dialog: MatDialog,
    private _organisation: OrganisationService,
    private _stafferScope: CurrentStafferScope
  ) {}

  async do(resource?: IPrincipleMention): Promise<void> {
    const practice: WithRef<IPractice> = await snapshot(
      this._organisation.practice$.pipe(filterUndefined())
    );
    const data: ITaskInitialFormData = {
      practice,
    };

    const title: RawInlineNodes = [];

    if (resource) {
      const resolvedResource = await fromMention(resource);
      if (isStaffer(resolvedResource)) {
        data.staffer = resolvedResource;
      }

      if (
        isAppointment(resolvedResource) &&
        isWithRef<IAppointment>(resolvedResource)
      ) {
        title.push(
          toMentionContent(await Appointment.toMention(resolvedResource))
        );
      }

      if (
        isPatient(resolvedResource) &&
        isWithRef<IPatient>(resolvedResource)
      ) {
        title.push(
          toMentionContent(
            toMention(resolvedResource, MentionResourceType.Patient)
          )
        );
      }
    }

    if (title.length) {
      data.title = title;
    }

    if (!data.staffer) {
      const staffer: WithRef<IStaffer> | undefined = await snapshot(
        this._stafferScope.model$
      );
      if (staffer) {
        data.staffer = staffer;
      }
    }

    const config: MatDialogConfig = DialogPresets.large({
      data,
      autoFocus: true,
    });

    await this._dialog
      .open<TaskFormComponent, ITaskInitialFormData>(TaskFormComponent, config)
      .afterClosed()
      .toPromise();
  }
}
