import {
  CLAIM_PROVIDERS,
  IAccountCredit,
  IAccountCreditExtendedData,
  IDiscountExtendedData,
  IInvoice,
  IManualExtendedData,
  isAccountCreditExtendedData,
  isDiscountExtendedData,
  isManualExtendedData,
  isTransaction,
  ITransaction,
  IUsedAccountCredit,
  TransactionCollection,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import {
  all$,
  ArchivedDocument,
  asyncForEach,
  AtLeast,
  CollectionReference,
  doc$,
  DocumentReference,
  getDoc,
  getParentDocRef,
  initFirestoreModel,
  IReffable,
  multiSort,
  snapshot,
  sortTimestamp,
  subCollection,
  WithRef,
} from '@principle-theorem/shared';
import { pick, startCase } from 'lodash';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { invoiceBalance, withTransactions$ } from '../invoice/invoice';

export class Transaction {
  static init<T>(
    overrides: AtLeast<
      ITransaction<T>,
      'provider' | 'reference' | 'from' | 'to' | 'practiceRef'
    >
  ): ITransaction<T> {
    return {
      uid: uuid(),
      amount: 0,
      type: TransactionType.Incoming,
      status: TransactionStatus.Pending,
      ...initFirestoreModel(),
      ...overrides,
    };
  }

  static internalReference(
    provider: TransactionProvider,
    uid: string = uuid()
  ): Pick<ITransaction, 'provider' | 'reference' | 'uid'> {
    return {
      uid,
      provider,
      reference: `${provider}:${uid}`,
    };
  }

  static resolveAs(
    pending: ITransaction,
    status: TransactionStatus,
    overrides?: Partial<ITransaction>
  ): ITransaction {
    return Transaction.init({
      ...pick(
        pending,
        'amount',
        'to',
        'from',
        'type',
        'reference',
        'provider',
        'practiceRef'
      ),
      status,
      ...overrides,
    });
  }

  static invoiceDocRef(
    transaction: IReffable<ITransaction>
  ): DocumentReference<IInvoice> {
    return getParentDocRef<IInvoice>(transaction.ref);
  }

  static invoice$(
    transaction: IReffable<ITransaction>
  ): Observable<WithRef<IInvoice>> {
    return doc$(Transaction.invoiceDocRef(transaction));
  }

  static async getInvoice(
    transaction: IReffable<ITransaction>
  ): Promise<WithRef<IInvoice>> {
    const invoiceRef = Transaction.invoiceDocRef(transaction);
    return getDoc(invoiceRef);
  }

  static isClaim(transaction: ITransaction): boolean {
    return CLAIM_PROVIDERS.includes(transaction.provider);
  }

  static async getAssociatedCredits(
    transaction: WithRef<ITransaction<IAccountCreditExtendedData>>
  ): Promise<WithRef<IAccountCredit>[]> {
    const usedCredits = transaction.extendedData?.accountCreditsUsed;
    if (!usedCredits) {
      return [];
    }
    return asyncForEach(usedCredits, async (usedCredit) =>
      getDoc(usedCredit.ref)
    );
  }

  static getUsedCredits(
    transaction: WithRef<ITransaction>
  ): IUsedAccountCredit[] {
    if (isAccountCreditExtendedData(transaction.extendedData)) {
      return transaction.extendedData.accountCreditsUsed;
    }
    return [];
  }

  static amendmentHistoryCol(
    transaction: IReffable<ITransaction>
  ): CollectionReference<ArchivedDocument<ITransaction>> {
    return subCollection<ArchivedDocument<ITransaction>>(
      transaction.ref,
      TransactionCollection.TransactionHistory
    );
  }

  static amendmentHistory$(
    transaction: IReffable<ITransaction>
  ): Observable<WithRef<ArchivedDocument<ITransaction>>[]> {
    return all$(Transaction.amendmentHistoryCol(transaction)).pipe(
      multiSort((a, b) => sortTimestamp(a.archivedAt, b.archivedAt))
    );
  }
}

export function getTransactionProviderName(transaction: ITransaction): string {
  return isManualTransaction(transaction)
    ? getManualTransactionProviderName(transaction)
    : getRawProviderName(transaction.provider);
}

function getManualTransactionProviderName(
  transaction: ITransaction<IManualExtendedData>
): string {
  const subTitle =
    transaction.extendedData?.transactionType?.name ?? 'No Transaction Type';
  return `Manual - ${subTitle}`;
}

export function getRawProviderName(provider: TransactionProvider): string {
  switch (provider) {
    case TransactionProvider.Discount:
      return 'Discounts Applied';
    case TransactionProvider.AccountCredit:
      return 'Account Credits Used';
    default:
      return startCase(provider);
  }
}

export function isManualTransaction(
  data: unknown
): data is ITransaction<IManualExtendedData> {
  return (
    isTransaction(data) &&
    data.provider === TransactionProvider.Manual &&
    isManualExtendedData(data.extendedData)
  );
}

export function isDiscountTransaction(
  data: unknown
): data is ITransaction<IDiscountExtendedData> {
  return (
    isTransaction(data) &&
    data.provider === TransactionProvider.Discount &&
    (!data.extendedData || isDiscountExtendedData(data.extendedData))
  );
}

export function getTransactionAmount(
  transaction: Pick<ITransaction, 'type' | 'amount'>
): number {
  return determineTransactionSign(transaction.type, transaction.amount);
}

export function determineTransactionSign(
  transactionType: TransactionType,
  amount: number
): number {
  if (transactionType === TransactionType.Outgoing) {
    return -Math.abs(amount);
  }
  return Math.abs(amount);
}

export type BaseTransaction = Pick<
  ITransaction,
  'amount' | 'type' | 'to' | 'from'
>;

export async function getBaseTransaction(
  invoice: WithRef<IInvoice>,
  type: TransactionType = TransactionType.Incoming
): Promise<BaseTransaction> {
  const isIncoming = type === TransactionType.Incoming;
  return {
    type,
    to: isIncoming ? invoice.from.name : invoice.to.name,
    from: isIncoming ? invoice.to.name : invoice.from.name,
    amount: await snapshot(invoiceBalance(withTransactions$(invoice))),
  };
}
