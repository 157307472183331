import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import {
  Event,
  ISchedulingEventDisplay,
  SchedulingEvent,
} from '@principle-theorem/principle-core';
import {
  ISchedulingEventSnapshotSummary,
  SchedulingEventType,
} from '@principle-theorem/principle-core/interfaces';
import { DAY_MONTH_YEAR_FORMAT, TIME_FORMAT } from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

const DEFAULT_DISPLAY: ISchedulingEventDisplay = {
  textColour: 'text-slate-500',
  backgroundColour: 'bg-slate-200',
  iconName: 'question_mark',
};

@Component({
  selector: 'pr-scheduling-event-snapshot',
  templateUrl: './scheduling-event-snapshot.component.html',
  styleUrls: ['./scheduling-event-snapshot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NgSharedModule],
})
export class SchedulingEventSnapshotComponent {
  readonly dateFormat = DAY_MONTH_YEAR_FORMAT;
  readonly timeFormat = TIME_FORMAT;
  snapshotSummary$ = new ReplaySubject<
    ISchedulingEventSnapshotSummary | undefined
  >(1);
  eventType$ = new ReplaySubject<SchedulingEventType | undefined>(1);
  display$: Observable<ISchedulingEventDisplay>;

  @Input()
  set snapshot(snapshot: ISchedulingEventSnapshotSummary | undefined) {
    this.snapshotSummary$.next(snapshot);
  }

  @Input()
  set eventType(eventType: SchedulingEventType | undefined) {
    this.eventType$.next(eventType);
  }

  constructor() {
    this.display$ = this.eventType$.pipe(
      map((eventType) =>
        eventType ? SchedulingEvent.getDisplay(eventType) : DEFAULT_DISPLAY
      )
    );
  }

  getDuration(snapshot?: ISchedulingEventSnapshotSummary): number {
    return snapshot ? Event.duration(snapshot) : 0;
  }
}
