import { toTimePeriod } from '../time-period';
import { type ITimePeriod } from '../interfaces';

export class SubtractTimeFilter {
  filter(times: ITimePeriod[], timesToSubtract: ITimePeriod[]): ITimePeriod[] {
    timesToSubtract.map((timeToSubtract: ITimePeriod) => {
      times = this._filterTimesWithinSubtractTime(times, timeToSubtract).reduce(
        (currentTimes: ITimePeriod[], time: ITimePeriod): ITimePeriod[] => {
          return currentTimes.concat(this._reducer(time, timeToSubtract));
        },
        []
      );
    });

    return times.filter((timePeriod: ITimePeriod): boolean => {
      return timePeriod.to.diff(timePeriod.from, 'minutes') > 0;
    });
  }

  private _filterTimesWithinSubtractTime(
    times: ITimePeriod[],
    timeToSubtract: ITimePeriod
  ): ITimePeriod[] {
    return times.filter((time: ITimePeriod): boolean => {
      return timeToSubtract.from.isSameOrBefore(time.from) &&
        timeToSubtract.to.isSameOrAfter(time.to)
        ? false
        : true;
    });
  }

  private _timesOverlap(time: ITimePeriod, other: ITimePeriod): boolean {
    return (
      time.from.isSameOrAfter(other.to) || time.to.isSameOrBefore(other.from)
    );
  }

  private _timeIsSplitBySmallerPeriod(
    time: ITimePeriod,
    other: ITimePeriod
  ): boolean {
    return (
      other.from.isSameOrAfter(time.from) && other.to.isSameOrBefore(time.to)
    );
  }

  private _timeIsSlicedFromStart(
    time: ITimePeriod,
    other: ITimePeriod
  ): boolean {
    return (
      other.from.isSameOrAfter(time.from) &&
      other.from.isSameOrBefore(time.to) &&
      other.to.isSameOrAfter(time.to)
    );
  }

  private _timeIsSlicedFromEnd(time: ITimePeriod, other: ITimePeriod): boolean {
    return (
      other.to.isSameOrBefore(time.to) &&
      other.to.isSameOrAfter(time.from) &&
      other.from.isSameOrBefore(time.from)
    );
  }

  private _reducer(
    time: ITimePeriod,
    timeToSubtract: ITimePeriod
  ): ITimePeriod[] {
    // Times do not overlap
    if (this._timesOverlap(time, timeToSubtract)) {
      return [time];
    }

    // Time is split by smaller period
    if (this._timeIsSplitBySmallerPeriod(time, timeToSubtract)) {
      return [
        toTimePeriod(time.from, timeToSubtract.from),
        toTimePeriod(timeToSubtract.to, time.to),
      ];
    }

    // Time is sliced from the start
    if (this._timeIsSlicedFromStart(time, timeToSubtract)) {
      return [toTimePeriod(time.from, timeToSubtract.from)];
    }

    // Time is sliced from the end
    if (this._timeIsSlicedFromEnd(time, timeToSubtract)) {
      return [toTimePeriod(timeToSubtract.to, time.to)];
    }

    return [];
  }
}
