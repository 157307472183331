<pr-account-card
  name="Outstanding"
  [amount]="bloc.outstanding$ | ngrxPush"
  [isLoading]="bloc.isLoading$ | ngrxPush"
  colour="red"
  [mini]="true"
  [disableTooltip]="true"
 />
<pr-account-card
  name="Credit Remaining"
  [amount]="bloc.creditRemaining$ | ngrxPush"
  [isLoading]="bloc.isLoading$ | ngrxPush"
  colour="green"
  [mini]="true"
  [disableTooltip]="true"
 />
