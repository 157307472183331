import { decode, encode } from 'base-64';
import type { IMedipassPartnerSDK } from '@medipass/partner-sdk';

function overrideDefaultBase64Conversion(): void {
  globalThis.window.btoa = encode;
  globalThis.window.atob = decode;
}

export function getMedipass(): IMedipassPartnerSDK {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports
    const medipassSDK: IMedipassPartnerSDK =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports
      require('@medipass/partner-sdk').default;
    overrideDefaultBase64Conversion();
    return medipassSDK;
  } catch (error) {
    throw new Error(`Can't load Medipass`);
  }
}
