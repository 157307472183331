import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { type MatCheckboxChange } from '@angular/material/checkbox';
import { toTextContent, type RawInlineNodes } from '@principle-theorem/editor';
import { TimezoneResolver } from '@principle-theorem/principle-core';
import {
  AnyAutomation,
  isGeneratedTask,
  type IAutomation,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_TIME_FORMAT,
  toMomentTz,
  type Timestamp,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  type Observable,
} from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-automation-list-item',
    templateUrl: './automation-list-item.component.html',
    styleUrls: ['./automation-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AutomationListItemComponent {
  checked$ = new BehaviorSubject<boolean>(false);
  automation$ = new ReplaySubject<IAutomation<AnyAutomation>>(1);
  title$: Observable<RawInlineNodes>;
  newDate$ = new ReplaySubject<Timestamp>(1);
  triggerDate$: Observable<moment.Moment | undefined>;
  isNewTriggerDate$: Observable<boolean>;
  readonly dateFormat = DATE_TIME_FORMAT;
  @Input() withCheckbox = true;

  @Input()
  set automation(automation: IAutomation<AnyAutomation>) {
    if (automation) {
      this.automation$.next(automation);
    }
  }

  @Input()
  set checked(checked: boolean) {
    if (checked) {
      this.checked$.next(checked);
    }
  }

  @Input()
  set newDate(newDate: Timestamp) {
    if (newDate) {
      this.newDate$.next(newDate);
    }
  }

  @Output()
  checkedChange = new EventEmitter<MatCheckboxChange>();

  constructor() {
    this.title$ = this.automation$.pipe(
      map((automation) =>
        isGeneratedTask(automation.data)
          ? automation.data.title
          : [toTextContent(automation.data.name)]
      )
    );
    const newDate$ = this.newDate$.pipe(startWith(undefined));
    this.triggerDate$ = combineLatest([this.automation$, newDate$]).pipe(
      switchMap(([automation, newDate]) =>
        this._getTriggerDate(automation, newDate)
      )
    );
    this.isNewTriggerDate$ = newDate$.pipe(map((newDate) => !!newDate));
  }

  private async _getTriggerDate(
    automation: IAutomation<AnyAutomation>,
    newDate?: Timestamp
  ): Promise<moment.Moment | undefined> {
    const triggerDate = newDate ?? automation.triggerDate;
    if (!triggerDate || !automation.practiceRef) {
      return;
    }
    const timezone = await TimezoneResolver.fromPracticeRef(
      automation.practiceRef
    );
    return toMomentTz(triggerDate, timezone);
  }
}
