import {
  isDocRef,
  isObject,
  type ISoftDelete,
} from '@principle-theorem/shared';
import {
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type IPractice } from '../practice/practice';
import { type IBridgeDeviceFeature } from './bridge-device-feature';

export enum BridgeDeviceCollection {
  Files = 'bridgeDeviceFiles',
}

export enum BridgeDeviceStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum BridgeDeviceDownloadStatus {
  Initialising = 'initialising',
  Downloading = 'downloading',
  Compressing = 'compressing',
  Uploading = 'uploading',
  Ready = 'ready',
  Failed = 'failed',
}

interface IBridgeDeviceDownloadStatusData {
  progress: number;
  message: string;
}

export const BRIDGE_DEVICE_DOWNLOAD_STATUS_MAP: {
  [key in BridgeDeviceDownloadStatus]: IBridgeDeviceDownloadStatusData;
} = {
  [BridgeDeviceDownloadStatus.Failed]: {
    progress: 0,
    message: 'Something went wrong. Please try again.',
  },
  [BridgeDeviceDownloadStatus.Initialising]: {
    progress: 20,
    message: 'Preparing your download...',
  },
  [BridgeDeviceDownloadStatus.Downloading]: {
    progress: 40,
    message: 'Setting things up...',
  },
  [BridgeDeviceDownloadStatus.Compressing]: {
    progress: 60,
    message: 'Almost ready...',
  },
  [BridgeDeviceDownloadStatus.Uploading]: {
    progress: 80,
    message: 'Finalising...',
  },
  [BridgeDeviceDownloadStatus.Ready]: {
    progress: 100,
    message: 'Principle Bridge ready for download.',
  },
};

export const BRIDGE_DEVICE_STATUS_COLOUR_MAP: {
  [key in BridgeDeviceStatus]: string;
} = {
  [BridgeDeviceStatus.Active]: 'primary',
  [BridgeDeviceStatus.Inactive]: 'warn',
};

interface IBaseBridgeIntegrationSettings {
  enabled: boolean;
}

export interface ISidexisIntegrationSettings
  extends IBaseBridgeIntegrationSettings {
  stationName: string;
  appPath: string;
  sidexisMailboxPath: string;
  principleMailboxPath: string;
}

export interface IEzdentIntegrationSettings
  extends IBaseBridgeIntegrationSettings {
  isMaster: boolean;
  clientId: string;
  clientSecret: string;
  serverUrl: string;
  serverPort: string;
  appPath: string;
  postgresUser: string;
  postgresPassword: string;
  postgresHost: string;
  postgresPort: string;
}

export interface ITwainIntegrationSettings
  extends IBaseBridgeIntegrationSettings {
  imagePath: string;
}

export interface IRomexisIntegrationSettings
  extends IBaseBridgeIntegrationSettings {
  dxStartEnabled: boolean;
}

export interface IHicapsConnectIntegrationSettings
  extends IBaseBridgeIntegrationSettings {
  logPath: string;
  requestDir: string;
}

export interface IVixWinIntegrationSettings
  extends IBaseBridgeIntegrationSettings {
  appPath: string;
  imagePath: string;
  isMaster: boolean;
}

export interface ICliniviewIntegrationSettings
  extends IBaseBridgeIntegrationSettings {
  appPath: string;
}

export interface IDixelIntegrationSettings
  extends IBaseBridgeIntegrationSettings {
  appPath: string;
}

export interface IVistaSoftIntegrationSettings
  extends IBaseBridgeIntegrationSettings {
  importFilePath: string;
  bridgePath: string;
  appPath: string;
}

export interface IPrincipleBridgeIntegrationSettings {
  sidexis: ISidexisIntegrationSettings;
  ezdent: IEzdentIntegrationSettings;
  twain: ITwainIntegrationSettings;
  romexis: IRomexisIntegrationSettings;
  hicapsConnect: IHicapsConnectIntegrationSettings;
  vixWin: IVixWinIntegrationSettings;
  cliniview: ICliniviewIntegrationSettings;
  iDixel: IDixelIntegrationSettings;
  vistaSoft: IVistaSoftIntegrationSettings;
}

export interface IPrincipleBridgeSettings
  extends Partial<IPrincipleBridgeIntegrationSettings> {
  version: string;
  deviceId: string;
  integrationKey: string;
}

export interface ITwainDevice extends ISoftDelete {
  name: string;
  uid: string;
  vistaSoftConnectEnabled: boolean;
  multiImageEnabled?: boolean;
}

export interface IBridgeTwainSettings {
  devices: ITwainDevice[];
  syncing: boolean;
}

export interface IBridgeDevice extends ISoftDelete {
  name: string;
  lastPaired?: Timestamp;
  deviceId?: string;
  integrationKey?: string;
  iotData?: IBridgeDeviceIOTData;
  features: IBridgeDeviceFeature[];
  status: BridgeDeviceStatus;
  lastStatusUpdate?: Timestamp;
  settings?: IPrincipleBridgeSettings;
  twainSettings?: IBridgeTwainSettings;
  downloadStatus?: BridgeDeviceDownloadStatus;
  downloadUrl?: string;
}

export interface IBridgeDeviceIOTData {
  deviceId: string;
  region: string;
  registryId: string;
  projectId: string;
}

export interface IGenerateIntegrationKeyRequest {
  deviceRef: DocumentReference<IBridgeDevice>;
  practiceRef: DocumentReference<IPractice>;
}

export interface IGenerateBridgeDownloadRequest {
  deviceRef: DocumentReference<IBridgeDevice>;
  practiceRef: DocumentReference<IPractice>;
}

export interface IGenerateIntegrationKeyResponse {
  integrationKey: string;
}

export interface IAddBridgeDeviceRequest {
  integrationKey: string;
}

export interface IGenerateDownloadRequest {
  deviceRef: DocumentReference<IBridgeDevice>;
  practiceRef: DocumentReference<IPractice>;
  deviceId: string;
}

export function isGenerateDownloadRequest(
  item: unknown
): item is IGenerateDownloadRequest {
  return (
    isObject(item) &&
    isDocRef(item.deviceRef) &&
    isDocRef(item.practiceRef) &&
    isString(item.deviceId)
  );
}

export function isGenerateIntegrationKeyRequest(
  item: unknown
): item is IGenerateIntegrationKeyRequest {
  return (
    isObject(item) && isDocRef(item.deviceRef) && isDocRef(item.practiceRef)
  );
}

export function isAddBridgeDeviceRequest(
  item: unknown
): item is IAddBridgeDeviceRequest {
  return isObject(item) && isString(item.integrationKey);
}

export interface IGenerateTokenRequest {
  deviceId: string;
  integrationKey: string;
}

export interface IGenerateTokenResponse {
  customToken: string;
}

export interface ICustomTokenData {
  deviceId: string;
  practiceId: string;
  brandId: string;
  organisationId: string;
  enabledOrgUids: { [key: string]: boolean };
  type: 'bridgeDevice';
}

export function isGenerateTokenRequest(
  item: unknown
): item is IGenerateTokenRequest {
  return (
    isObject(item) && isString(item.deviceId) && isString(item.integrationKey)
  );
}

export interface IBridgeStatus {
  status: BridgeDeviceStatus;
  updatedAt: string;
}
