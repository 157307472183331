<div fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
    <ng-container *ngIf="selectedStaff$ | async as staff">
      <div
        class="add-staffer-button"
        fxLayout="row"
        fxLayoutAlign="space-evenly center"
        matTooltip="Select Staff"
        [matMenuTriggerFor]="menu"
        [ngClass]="{ 'with-count': true }"
      >
        <span class="staffer-count">{{ staff.length }}</span>
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-icon>person_add</mat-icon>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<mat-menu #menu="matMenu">
  <div fxLayout="column" (click)="$event.stopPropagation()">
    <div
      class="sticky top-0 z-10 border-b border-solid border-gray-300 bg-white p-2"
    >
      <mat-checkbox
        class="select-all"
        [checked]="allSelected$ | ngrxPush"
        (change)="toggleAllStaff($event.checked)"
      >
        <span>Select All</span>
      </mat-checkbox>
    </div>

    <mat-selection-list
      #staffList
      cdkDropList
      [compareWith]="compareStaff"
      (selectionChange)="determineSelectedStaff()"
      (cdkDropListDropped)="drop($event)"
    >
      <mat-list-option
        *ngFor="let staffer of staff$ | ngrxPush; trackBy: trackByStaffer"
        cdkDrag
        [selected]="staffer | map: isSelected$ : this | async"
        [value]="staffer"
      >
        <mat-icon matListItemIcon cdkDragHandle class="drag-handle"
          >drag_handle</mat-icon
        >
        <div matListItemTitle>
          <pr-staffer-display [staffer]="staffer" />
        </div>

        <div *cdkDragPreview class="drag-preview">
          <mat-list-item>
            <pr-staffer-display [staffer]="staffer" />
          </mat-list-item>
        </div>
      </mat-list-option>
    </mat-selection-list>

    <div
      class="sticky bottom-0 z-10 border-t border-solid border-gray-300 bg-white p-4"
    >
      <mat-slide-toggle [formControl]="showRosteredOffCtrl">
        <div class="ml-2 flex items-center gap-2">
          <span>Show Rostered Off Staff</span>
        </div>
      </mat-slide-toggle>
    </div>
  </div>
</mat-menu>
