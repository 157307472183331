import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { type WithRef } from '@principle-theorem/shared';
import { BaseResolver } from '@principle-theorem/ng-shared';
import { type ILab } from '@principle-theorem/principle-core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class LabNameResolverService extends BaseResolver<string> {
  resolve(route: ActivatedRouteSnapshot): string {
    const lab: WithRef<ILab> | undefined = this._findInheritedRouteData(
      route,
      'lab'
    );
    return lab ? lab.name : '';
  }
}
