import { BehaviorSubject, type Observable } from 'rxjs';

export function matchMedia$(mediaQuery: MediaQueryList): Observable<boolean> {
  const isSafari: boolean =
    navigator.userAgent.toLowerCase().indexOf('safari') !== -1;
  const matches$: BehaviorSubject<boolean> = new BehaviorSubject(
    mediaQuery.matches
  );
  if (isSafari) {
    mediaQuery.addListener((event) => matches$.next(event.matches));
  }
  mediaQuery.onchange = (event: MediaQueryListEvent) =>
    matches$.next(event.matches);
  return matches$.asObservable();
}
