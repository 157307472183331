import { randFloat, randNumber } from '@ngneat/falso';
import { ITreatmentPlanCompletedFact } from '@principle-theorem/reporting/interfaces';
import {
  BaseMock,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
} from '@principle-theorem/shared';
import { AppointmentDimensionMock } from '../dimensions/appointment-dimension.mock';
import { BrandDimensionMock } from '../dimensions/brand-dimension.mock';
import { SerialisedDocumentReferenceMock } from '../dimensions/common.mock';
import { InvoiceDimensionMock } from '../dimensions/invoice-dimension.mock';
import { PatientDimensionMock } from '../dimensions/patient-dimension.mock';
import { StafferDimensionMock } from '../dimensions/staffer-dimension.mock';
import { TreatmentPlanDimensionMock } from '../dimensions/treatment-plan-dimension.mock';

export class TreatmentPlanCompletedFactMock
  extends BaseMock
  implements ITreatmentPlanCompletedFact
{
  invoiceRefs = [MockGenerator.generate(SerialisedDocumentReferenceMock)];
  appointmentRefs = [MockGenerator.generate(SerialisedDocumentReferenceMock)];
  practitionerRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  treatmentPlanRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  patientRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  brandRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practitioner = MockGenerator.generate(StafferDimensionMock);
  treatmentPlan = MockGenerator.generate(TreatmentPlanDimensionMock);
  patient = MockGenerator.generate(PatientDimensionMock);
  referrer = this.patient.referrer;
  brand = MockGenerator.generate(BrandDimensionMock);
  timestamp = toSerialisedTimestamp(MockTimestamp());
  appointments = [MockGenerator.generate(AppointmentDimensionMock)];
  invoices = [MockGenerator.generate(InvoiceDimensionMock)];
  finalCost = randFloat({
    min: 500,
    max: 5000,
  });
  initialCost = randFloat({
    min: 500,
    max: 5000,
  });
  planChanges = randNumber({
    min: 0,
    max: 10,
  });
  totalDuration = randNumber({
    min: 120,
    max: 720,
  });
}
