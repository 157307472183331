import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IFilterOption } from '@principle-theorem/ng-shared';
import { type AutomationStatus } from '@principle-theorem/principle-core/interfaces';
import { multiMap } from '@principle-theorem/shared';
import { type AutomationEntity } from '../../../../store/models';
import { compact } from 'lodash';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutomationsFacade } from '../../../../store/facades/automations.facade';

@Component({
  selector: 'pr-automation-filter-badge',
  templateUrl: './automation-filter-badge.component.html',
  styleUrls: ['./automation-filter-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomationFilterBadgeComponent {
  notificationBadgeCount$: Observable<number>;
  hideBadge$: Observable<boolean>;
  @Input() filterItem: IFilterOption<AutomationStatus, AutomationEntity>;

  constructor(private _automationFacade: AutomationsFacade) {
    this.notificationBadgeCount$ =
      this._automationFacade.filteredAutomations$.pipe(
        multiMap((automation) => this.filterItem.filter(automation)),
        map((automationType) => compact(automationType).length)
      );
    this.hideBadge$ = this.notificationBadgeCount$.pipe(
      map((count) => count === 0)
    );
  }
}
