import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  initVersionedSchema,
  VersionedSchema,
} from '@principle-theorem/editor';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { FollowUp } from '@principle-theorem/principle-core';
import {
  IAppointment,
  IFollowUp,
  IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { FollowUpsService } from '../../follow-ups.service';
export interface ICancelFollowUpRequest {
  appointment: WithRef<IAppointment>;
  patient: WithRef<IPatient>;
}

export type ICancelFollowUpFormData = Pick<IFollowUp, 'noFollowUpReason'>;

@Component({
  selector: 'pr-cancel-follow-up',
  styleUrls: ['./cancel-follow-up.component.scss'],
  templateUrl: './cancel-follow-up.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
})
export class CancelFollowUpComponent {
  form = new TypedFormGroup<ICancelFollowUpFormData>({
    noFollowUpReason: new TypedFormControl<VersionedSchema>(
      initVersionedSchema(),
      [Validators.required, Validators.minLength(1)]
    ),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: ICancelFollowUpRequest,
    public dialogRef: MatDialogRef<CancelFollowUpComponent>,
    private _followUpService: FollowUpsService
  ) {}

  async cancelFollowUp(): Promise<void> {
    if (!this.form.valid) {
      return;
    }
    const followUpData = this.form.getRawValue();
    const followUp = FollowUp.init({
      createFollowUp: false,
      followUpDate: undefined,
      noFollowUpReason: followUpData.noFollowUpReason,
    });
    await this._followUpService.addToAppointment(
      this._data.appointment,
      followUp,
      'Follow Up Cancelled'
    );
    this.dialogRef.close();
  }
}
