import { isObject } from '@principle-theorem/shared';
import { isString } from 'lodash';
import {
  type IBaseTransactionCompleteCallbackData,
  type IIntegrationKeyRequestParams,
  isBaseTransactionCompleteCallbackData,
  type ITerminalRequestParams,
} from './tyro-callbacks';

export interface IEasyclaimRequestParams
  extends ITerminalRequestParams,
    IIntegrationKeyRequestParams {
  payload: string;
}

export interface IBulkBillEasyclaimRequestParams
  extends IEasyclaimRequestParams {
  rightsAssigned: boolean;
}

export interface IEasyclaimBaseCallback
  extends IBaseTransactionCompleteCallbackData {
  easyclaimTransactionId: string;
}

export function isEasyclaimBaseCallback(
  data: unknown
): data is IEasyclaimBaseCallback {
  return (
    isObject(data) &&
    isBaseTransactionCompleteCallbackData(data) &&
    isString(data.easyclaimTransactionId)
  );
}

export interface IEasyclaimCompleteCallback extends IEasyclaimBaseCallback {
  payload: string;
}

export function isEasyclaimCompleteCallback(
  data: unknown
): data is IEasyclaimCompleteCallback {
  return (
    isObject(data) && isEasyclaimBaseCallback(data) && isString(data.payload)
  );
}

export interface IEasyclaimErrorCallback extends IEasyclaimBaseCallback {
  statusCode: string;
}

export function isEasyclaimErrorCallback(
  data: unknown
): data is IEasyclaimErrorCallback {
  return (
    isObject(data) && isEasyclaimBaseCallback(data) && isString(data.statusCode)
  );
}
