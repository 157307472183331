import {
  type IReferralSourceConfiguration,
  type ICustomMappingSourceOption,
  ITranslationMap,
} from '@principle-theorem/principle-core/interfaces';
import {
  type AtLeast,
  type INamedDocument,
  isSameRef,
  type IXSLXExport,
  type XSLXCell,
} from '@principle-theorem/shared';
import type { Column } from 'exceljs';

export interface IReferralSourceXSLX {
  sourceId: string;
  sourceName: string;
  mapTo: string;
}

export const REFERRAL_SOURCE_HEADERS: AtLeast<Column, 'key' | 'header'>[] = [
  {
    key: 'sourceId',
    header: 'Id',
  },
  {
    key: 'sourceName',
    header: 'Referral Source',
    width: 45,
  },
  {
    key: 'mapTo',
    header: 'Map To',
    width: 30,
  },
];

export class ReferralSourceToXSLX
  implements IXSLXExport<ICustomMappingSourceOption, IReferralSourceXSLX>
{
  headers = REFERRAL_SOURCE_HEADERS;

  constructor(
    private _referralSources: INamedDocument<IReferralSourceConfiguration>[],
    private _existingMappings: ITranslationMap<IReferralSourceConfiguration>[]
  ) {}

  translate(
    records: ICustomMappingSourceOption[]
  ): Record<keyof IReferralSourceXSLX, XSLXCell>[] {
    return records.map((record) => {
      const existingRecord = this._existingMappings.find(
        (existingMapping) => existingMapping.sourceIdentifier === record.value
      );

      const destinationIdentifier = existingRecord?.destinationIdentifier;
      const mappedReferralSource = destinationIdentifier
        ? this._referralSources.find((referralSource) =>
            isSameRef(referralSource, destinationIdentifier)
          )
        : undefined;

      return {
        sourceId: {
          value: record.value,
        },
        sourceName: {
          value: record.label,
        },
        mapTo: {
          value: mappedReferralSource?.name,
          dataValidation: {
            type: 'list',
            formulae: [
              `"${this._referralSources
                .map((referralSource) => referralSource.name)
                .join(',')}"`,
            ],
          },
        },
      };
    });
  }
}
