<form [formGroup]="form">
  <div class="flex flex-col gap-4">
    @if (!patientHasAddress()) {
      <div class="flex items-center gap-2 rounded-md bg-red-500 p-4 text-white">
        <mat-icon>warning</mat-icon>
        <span> Patient requires an address to print </span>
      </div>
    }
    @if (printWarning()) {
      <div class="flex items-center gap-2 rounded-md bg-red-500 p-4 text-white">
        <mat-icon>warning</mat-icon>
        <span>{{ printWarning() }}</span>
      </div>
    }
    @if (riskOfOverPrint()) {
      <pr-information-box>
        Consider splitting medication across multiple prescriptions to ensure
        everything fits when printing.
      </pr-information-box>
    }
    <pr-content-container class="sticky top-4 z-50">
      <div class="flex items-center justify-between gap-4">
        <pr-status-label
          class="w-20 align-middle"
          [dense]="true"
          [label]="selected()?.status"
          [color]="selected()?.status | map: statusColor"
        />
        <div class="flex items-center gap-4">
          <pr-prescription-actions
            (actionChange)="action($event)"
            [prescription]="selected()"
            [disabled]="
              form.invalid || !selected()?.items?.length || !patientHasAddress()
            "
          >
            <button
              color="primary"
              mat-flat-button
              [disabled]="
                form.invalid || form.pending || form.pristine || isDisabled()
              "
              (click)="save()"
            >
              Save
            </button>
          </pr-prescription-actions>
        </div>
      </div>
    </pr-content-container>

    <div
      class="grid h-full max-w-full grid-flow-row grid-rows-3 gap-4 lg:grid-flow-col lg:grid-rows-2"
    >
      <pr-content-container class="lg:col-span-2">
        <mat-form-field class="w-full">
          <mat-label>Notes</mat-label>
          <pr-content-editor
            [menuEnabled]="false"
            [disabled]="isDisabled()"
            formControlName="notes"
            placeholder="Notes"
          />
          <mat-hint>
            Any additional notes or information about the prescription for
            internal use
          </mat-hint>
        </mat-form-field>
      </pr-content-container>

      <pr-content-container class="lg:col-span-2">
        <div class="flex flex-col gap-4">
          <mat-checkbox formControlName="pbsSafetyNet">
            PBS Safety Net entitlement cardholder
          </mat-checkbox>

          <mat-checkbox formControlName="concessionalOrDependentRPBS">
            Concessional or dependent RPBS beneficiary or PBS Safety Net
            concession cardholder
          </mat-checkbox>

          <div class="flex gap-4">
            <mat-checkbox formControlName="pbs"> PBS </mat-checkbox>
            <mat-checkbox formControlName="rpbs"> RPBS </mat-checkbox>
            <mat-checkbox formControlName="brandSubstitutionNotPermitted">
              Brand substitution not permitted
            </mat-checkbox>
          </div>
        </div>
      </pr-content-container>

      <pr-content-container
        height="h-full"
        maxHeight="lg:max-h-[362px] max-h-[174px]"
        overflow="lg:overflow-x-auto overflow-y-auto"
        class="order-first lg:order-last lg:row-span-2"
        [ngClass]="{ hidden: compact }"
      >
        <p class="font-bold">Prescription History</p>
        <div
          class="custom-max-width flex flex-row items-center gap-4 lg:flex-col lg:items-start"
        >
          @if (statusHistory().length) {
            @for (status of statusHistory(); track status.updatedAt) {
              <div
                class="flex min-w-fit flex-col gap-4 lg:w-auto lg:flex-row lg:items-center"
              >
                <pt-user-icon
                  [diameter]="32"
                  [name]="
                    status.actionedByRef
                      | map: getPrescriberDisplayName$ : this
                      | async
                  "
                  [src]="
                    status.actionedByRef
                      | map: stafferProfileImage$ : this
                      | async
                  "
                />

                <pr-status-label
                  class="w-20 align-middle"
                  [label]="status.status"
                  [color]="status.status | map: statusColor"
                  [dense]="true"
                />

                <span>
                  {{ status.updatedAt | moment | amDateFormat: dateFormat }}
                </span>
              </div>
            }
          }
        </div>
      </pr-content-container>
    </div>

    <div formArrayName="items" class="flex flex-col gap-4">
      @for (control of form.items.controls; track index; let index = $index) {
        <pr-content-container [formGroupName]="index" padding="p-4 pt-2">
          <div class="mb-1 flex flex-row items-center justify-end">
            <button
              mat-icon-button
              [disabled]="isDisabled()"
              matTooltip="Remove Item"
              (click)="form.removeItem(index)"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>

          <pr-prescription-item-form
            [formGroup]="control"
            [configPrescriptionItems]="configPrescriptionItems()"
          />
        </pr-content-container>
      }
    </div>
  </div>
</form>

<pr-content-container margin="mt-2" padding="p-0">
  <button
    class="w-full"
    mat-button
    [disabled]="isDisabled()"
    (click)="form.addItem()"
  >
    <mat-icon>add</mat-icon>
    Add Item
  </button>
</pr-content-container>
