import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CurrentScopeFacade,
  IReasonSelectorValue,
} from '@principle-theorem/ng-principle-shared';
import { TypedFormControl } from '@principle-theorem/ng-shared';
import {
  Brand,
  Interaction,
  SchedulingEventReason,
} from '@principle-theorem/principle-core';
import {
  ISchedulingEvent,
  ISchedulingEventReason,
  type IInteractionV2,
  type WithContext,
  SchedulingEventType,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { type INoteFormData } from '../note-form/note-form';
import { type NoteComponent } from '../note/note.component';

export interface IEditInteractionDialogData {
  interaction:
    | WithRef<WithContext<IInteractionV2>>
    | WithContext<IInteractionV2>;
}

export interface IEditInteractionResponse {
  interaction: WithRef<IInteractionV2> | IInteractionV2;
  selectedReason: IReasonSelectorValue;
}

@Component({
    selector: 'pr-edit-interaction-dialog',
    templateUrl: './edit-interaction-dialog.component.html',
    styleUrls: ['./edit-interaction-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditInteractionDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  noteFormData$: BehaviorSubject<INoteFormData>;
  schedulingEvent$: Observable<WithRef<ISchedulingEvent> | undefined>;
  reasonControl = new TypedFormControl<IReasonSelectorValue>(
    undefined,
    Validators.required
  );
  reasons$: Observable<WithRef<ISchedulingEventReason>[]>;
  showScheduleEventReasonSelector$: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEditInteractionDialogData,
    public dialogRef: MatDialogRef<NoteComponent>,
    private _currentScopeFacade: CurrentScopeFacade
  ) {
    this.noteFormData$ = new BehaviorSubject<INoteFormData>({
      content: data.interaction.content,
      tags: data.interaction.tags,
    });
    this.schedulingEvent$ = Interaction.getSchedulingEvent$(
      this.data.interaction
    );
    this.showScheduleEventReasonSelector$ = this.schedulingEvent$.pipe(
      map((schedulingEvent) =>
        this._showScheduleEventReasonSelector(schedulingEvent)
      )
    );
    this.schedulingEvent$
      .pipe(filterUndefined(), takeUntil(this._onDestroy$))
      .subscribe((schedulingEvent) => {
        if (schedulingEvent.reason) {
          this.reasonControl.patchValue({
            reason: schedulingEvent.reason,
            reasonSetManually: schedulingEvent.reasonSetManually,
          });
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async submit(): Promise<void> {
    const { tags, content } = await snapshot(this.noteFormData$);
    const interaction = { ...this.data.interaction, tags, content };
    const selectedReason =
      this.reasonControl.getRawValue() as IReasonSelectorValue;
    this.dialogRef.close({ interaction, selectedReason });
  }

  getInteractionLabel(interaction: IInteractionV2): string {
    return Interaction.getInteractionTypeLabel(interaction);
  }

  getReasons$(
    schedulingEvent: WithRef<ISchedulingEvent>
  ): Observable<WithRef<ISchedulingEventReason>[]> {
    return this._currentScopeFacade.currentBrand$.pipe(
      filterUndefined(),
      switchMap((brand) => Brand.cancellationReasons$(brand)),
      map((reasons) =>
        SchedulingEventReason.filterByEventType(
          reasons,
          schedulingEvent.schedulingConditions.eventType
        )
      )
    );
  }

  isDisabled$(noteFormValid: boolean): Observable<boolean> {
    return combineLatest([
      this.showScheduleEventReasonSelector$,
      this.reasonControl.valueChanges,
    ]).pipe(
      map(([showScheduleEventReasonSelector]) => {
        return (
          !noteFormValid ||
          !this._isSchedulingReasonValid(showScheduleEventReasonSelector)
        );
      })
    );
  }

  private _isSchedulingReasonValid(
    showScheduleEventReasonSelector: boolean
  ): boolean {
    if (!showScheduleEventReasonSelector) {
      return true;
    }
    return this.reasonControl.valid;
  }

  private _showScheduleEventReasonSelector(
    schedulingEvent?: WithRef<ISchedulingEvent>
  ): boolean {
    return (
      !!schedulingEvent &&
      schedulingEvent.eventType !== SchedulingEventType.Schedule
    );
  }
}
