<div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="16px">
  <div class="heading">
    Date Range
    <ng-container *ngIf="rangeService.selectedDayRange$ | ngrxPush as range"
      >- {{ rangeSummary$ | ngrxPush }}</ng-container
    >
  </div>
  <button
    class="reset-button"
    mat-icon-button
    matTooltip="Change date range"
    (click)="rangeService.resetRange()"
  >
    <mat-icon>date_range</mat-icon>
  </button>
</div>
<mat-calendar-header />
