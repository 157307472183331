import {
  toTransactionDisplays,
  type ITransactionDisplay,
} from '@principle-theorem/ng-payments';
import {
  AccountCredit,
  Invoice,
  OrganisationCache,
  Staffer,
  Transaction,
  getTransactionAmount,
} from '@principle-theorem/principle-core';
import {
  CollectionGroup,
  PatientCollection,
  TransactionProvider,
  TransactionStatus,
  isAccountCreditExtendedData,
  type IAccountCredit,
  type IInvoice,
  type IPatient,
  type IPractice,
  type ITransaction,
  type IUsedAccountCredit,
  type PaidInvoice,
} from '@principle-theorem/principle-core/interfaces';
import {
  transactionsToReconciliationReport,
  type IAccountCreditReportRecord,
  type IAccountCreditTransactionsRecord,
  type IDepostPaymentBreakdown,
  type IInvoiceReportRecord,
  type IInvoiceReportRequest,
  type IPrincipleReconciliationReportData,
  type IReconciliationReportRequest,
  type IReconciliationTransactionDetails,
  type ITransactionReportRecord,
  type ITransactionReportRequest,
} from '@principle-theorem/reporting';
import {
  Firestore,
  ITimePeriod,
  Query,
  asyncForEach,
  chunkRange,
  collectionGroupQuery,
  isSameRef,
  multiConcatMap,
  multiFilter,
  orderBy,
  query,
  safeChunk,
  safeCombineLatest,
  snapshot,
  toNamedDocument,
  toTimePeriod,
  toTimestamp,
  undeletedQuery,
  where,
  type DocumentReference,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, omit } from 'lodash';
import { combineLatest, from, type Observable } from 'rxjs';
import { concatMap, defaultIfEmpty, map, mergeMap, scan } from 'rxjs/operators';

export class ReportingFunctions {
  static getReconciliationReport(
    request: IReconciliationReportRequest
  ): Promise<IPrincipleReconciliationReportData> {
    const totalRange = toTimePeriod(request.startDate, request.endDate);
    return from(chunkRange(totalRange, 6, 'months'))
      .pipe(
        concatMap((range) =>
          from(
            Firestore.getDocs(
              collectionGroupQuery<ITransaction>(
                CollectionGroup.Transactions,
                where('practiceRef', '==', request.practiceRef),
                where('createdAt', '>=', toTimestamp(range.from)),
                where('createdAt', '<=', toTimestamp(range.to))
              )
            )
          ).pipe(
            multiFilter((transaction) => !transaction.deleted),
            concatMap((transactions) => safeChunk(transactions, 200)),
            concatMap((transactions) =>
              asyncForEach(transactions, async (transaction) => {
                const invoice = await Firestore.getDoc(
                  Transaction.invoiceDocRef(transaction)
                );
                const patient = await Firestore.getDoc(
                  Invoice.patientDocRef(invoice)
                );
                return {
                  transaction,
                  patient: toNamedDocument(patient),
                  invoice,
                };
              })
            )
          )
        ),
        scan(
          (acc, records) => [...acc, ...records],
          [] as IReconciliationTransactionDetails[]
        ),
        map((response) => transactionsToReconciliationReport(response))
      )
      .toPromise();
  }

  static getTransactions(
    request: ITransactionReportRequest
  ): Promise<ITransactionReportRecord[]> {
    const totalRange = toTimePeriod(request.startDate, request.endDate);
    return from(chunkRange(totalRange, 2, 'weeks'))
      .pipe(
        mergeMap(
          (range) =>
            from(
              Firestore.getDocs(
                collectionGroupQuery<ITransaction>(
                  CollectionGroup.Transactions,
                  where('practiceRef', '==', request.practiceRef),
                  where('deleted', '==', false),
                  where('createdAt', '>=', toTimestamp(range.from)),
                  where('createdAt', '<=', toTimestamp(range.to))
                )
              )
            ).pipe(
              concatMap((transactions) => from(transactions)),
              mergeMap(async (transaction) => {
                const invoice = await Firestore.getDoc(
                  Transaction.invoiceDocRef(transaction)
                );
                const patient = await Firestore.getDoc(
                  Invoice.patientDocRef(invoice)
                );
                const accountCreditTransactions =
                  await getAccountCreditTransactions(transaction);
                return {
                  transaction,
                  patient: toNamedDocument(patient),
                  invoice,
                  accountCreditTransactions,
                };
              }, 100)
            ),
          4
        ),
        scan(
          (acc, record) => [...acc, record],
          [] as ITransactionReportRecord[]
        ),
        defaultIfEmpty([] as ITransactionReportRecord[])
      )
      .toPromise();
  }

  static getLatestTransactions(
    request: ITransactionReportRequest
  ): Promise<ILatestTransactionReportRecord[]> {
    const totalRange = toTimePeriod(request.startDate, request.endDate);
    return from(chunkRange(totalRange, 6, 'months'))
      .pipe(
        concatMap((range) =>
          from(
            query(
              collectionGroupQuery<ITransaction>(CollectionGroup.Transactions),
              where('practiceRef', '==', request.practiceRef),
              where('createdAt', '>=', toTimestamp(range.from)),
              where('createdAt', '<=', toTimestamp(range.to))
            )
          ).pipe(
            multiFilter((transaction) => !transaction.deleted),
            concatMap((transactions) => safeChunk(transactions, 200)),
            map((transactions) => toTransactionDisplays(transactions)),
            multiFilter((record) =>
              request.status ? record.latest.status === request.status : true
            ),
            concatMap((records) =>
              asyncForEach(records, async (record) => {
                const invoice = await Transaction.getInvoice(record.latest);
                const patient = await Firestore.getDoc(
                  Invoice.patientDocRef(invoice)
                );
                return {
                  ...record,
                  patient,
                  invoice,
                };
              })
            )
          )
        ),
        scan(
          (acc, records) => [...acc, ...records],
          [] as ILatestTransactionReportRecord[]
        )
      )
      .toPromise();
  }

  static getPaidInvoices(
    request: IInvoiceReportRequest
  ): Promise<IInvoiceReportRecord[]> {
    const totalRange = toTimePeriod(request.startDate, request.endDate);
    return from(chunkRange(totalRange, 6, 'months'))
      .pipe(
        concatMap((range) =>
          from(
            query(
              collectionGroupQuery<PaidInvoice>(CollectionGroup.Invoices),
              ...compact([
                request.status
                  ? where('status', '==', request.status)
                  : undefined,
                where('practice.ref', '==', request.practiceRef),
                where('paidAt', '>=', toTimestamp(range.from)),
                where('paidAt', '<=', toTimestamp(range.to)),
              ])
            )
          ).pipe(
            multiFilter((invoice) => !invoice.deleted),
            concatMap((invoices) => safeChunk(invoices, 200)),
            concatMap((invoices) => toRecordReports$(invoices))
          )
        ),
        scan(
          (acc, records) => [...acc, ...records],
          [] as IInvoiceReportRecord[]
        )
      )
      .toPromise();
  }

  static getIssuedInvoices(
    request: IInvoiceReportRequest
  ): Promise<IInvoiceReportRecord[]> {
    const totalRange = toTimePeriod(request.startDate, request.endDate);
    return from(chunkRange(totalRange, 6, 'months'))
      .pipe(
        concatMap((range) =>
          from(
            query(
              collectionGroupQuery<PaidInvoice>(CollectionGroup.Invoices),
              ...compact([
                request.status
                  ? where('status', '==', request.status)
                  : undefined,
                where('practice.ref', '==', request.practiceRef),
                where('issuedAt', '>=', toTimestamp(range.from)),
                where('issuedAt', '<=', toTimestamp(range.to)),
              ])
            )
          ).pipe(
            multiFilter((invoice) => !invoice.deleted),
            concatMap((invoices) => safeChunk(invoices, 200)),
            concatMap((invoices) => toRecordReports$(invoices))
          )
        ),
        scan(
          (acc, records) => [...acc, ...records],
          [] as IInvoiceReportRecord[]
        )
      )
      .toPromise();
  }

  static async getAccountCredits(
    request: IInvoiceReportRequest
  ): Promise<IAccountCreditReportRecord[]> {
    const totalRange = toTimePeriod(request.startDate, request.endDate);
    return from(chunkRange(totalRange, 6, 'months'))
      .pipe(
        concatMap((range) => {
          const chunkQuery = getAccountCreditQuery(request.practiceRef, range);
          return from(query(chunkQuery)).pipe(
            concatMap((credits) => safeChunk(credits, 100)),
            multiConcatMap((credits) => toCreditReportRecord(credits))
          );
        }),
        scan(
          (acc, records) => [...acc, ...records],
          [] as IAccountCreditReportRecord[]
        )
      )
      .toPromise();
  }

  static async getPractitionerAccountCredits(
    request: IAccountCreditRequest
  ): Promise<IAccountCreditReportRecord[]> {
    const practitioners = await snapshot(
      Staffer.practitionersByPractice$({ ref: request.practiceRef })
    );
    const totalRange = toTimePeriod(request.startDate, request.endDate);

    return safeChunk(practitioners, 10)
      .pipe(
        concatMap((practitionerChunk) =>
          from(chunkRange(totalRange, 6, 'months')).pipe(
            concatMap((range) =>
              from(
                query(
                  collectionGroupQuery<IAccountCredit>(
                    PatientCollection.Credits
                  ),
                  where(
                    'reservedFor.practitioner.ref',
                    'in',
                    practitionerChunk.map((practitioner) => practitioner.ref)
                  ),
                  where('createdAt', '>=', toTimestamp(range.from)),
                  where('createdAt', '<=', toTimestamp(range.to))
                )
              ).pipe(
                multiFilter((accountCredit) => !accountCredit.deleted),
                multiFilter(
                  (accountCredit) => !!accountCredit.reservedFor.practitioner
                ),
                concatMap((accountCredits) => safeChunk(accountCredits, 200)),
                concatMap((accountCredits) =>
                  asyncForEach(accountCredits, async (accountCredit) => {
                    if (!accountCredit.invoice) {
                      return;
                    }

                    const invoice = await Firestore.getDoc(
                      accountCredit.invoice
                    );
                    return snapshot(
                      toRecordReport$(invoice).pipe(
                        map((record) => ({
                          ...record,
                          accountCredit: {
                            ...accountCredit,
                            remaining:
                              accountCredit.amount - accountCredit.used,
                          },
                        }))
                      )
                    );
                  })
                ),
                map(compact),
                multiFilter((result) =>
                  isSameRef(result.invoice.practice, request.practiceRef)
                )
              )
            )
          )
        ),
        scan(
          (acc, records) => [...acc, ...records],
          [] as IAccountCreditReportRecord[]
        ),
        defaultIfEmpty([] as IAccountCreditReportRecord[])
      )
      .toPromise();
  }
}

function toRecordReports$(
  invoices: WithRef<IInvoice>[]
): Observable<IInvoiceReportRecord[]> {
  return safeCombineLatest(invoices.map((invoice) => toRecordReport$(invoice)));
}

function toRecordReport$(
  invoice: WithRef<IInvoice>
): Observable<IInvoiceReportRecord> {
  return combineLatest([
    Firestore.getDoc(Invoice.patientDocRef(invoice)),
    Firestore.getDocs(undeletedQuery(Invoice.transactionCol(invoice))),
  ]).pipe(
    map(
      ([patient, transactions]): IInvoiceReportRecord => ({
        patient,
        invoice,
        transactions: transactions.map((transaction) =>
          transaction.provider === TransactionProvider.Manual
            ? transaction
            : omit(transaction, 'extendedData')
        ),
      })
    )
  );
}

export interface IAccountCreditRequest {
  startDate: Timestamp;
  endDate: Timestamp;
  practiceRef: DocumentReference<IPractice>;
}

export interface ILatestTransactionReportRecord extends ITransactionDisplay {
  invoice: WithRef<IInvoice>;
  patient: WithRef<IPatient>;
}

async function getAccountCreditTransactions(
  transaction: WithRef<ITransaction>
): Promise<IAccountCreditTransactionsRecord[]> {
  const extendedData = transaction.extendedData;
  if (!isAccountCreditExtendedData(extendedData)) {
    return [];
  }
  const records = extendedData.accountCreditsUsed.map(async (creditUsed) => ({
    creditUsed,
    depositPayments: await getAccountCreditDepositPayments(
      await Firestore.getDoc(creditUsed.ref),
      creditUsed
    ),
  }));
  return Promise.all(records);
}

async function getAccountCreditDepositPayments(
  accountCredit: WithRef<IAccountCredit>,
  creditUsed: IUsedAccountCredit
): Promise<IDepostPaymentBreakdown[]> {
  const invoice = accountCredit.invoice
    ? await Firestore.getDoc(accountCredit.invoice)
    : undefined;
  if (!invoice) {
    return [];
  }

  const transactions = await Firestore.getDocs(Invoice.transactionCol(invoice));
  return transactions.map((transaction) => {
    const totalTransactionAmount = getTransactionAmount(transaction);
    const transactionRatio = totalTransactionAmount / accountCredit.amount;
    const transactionAmount = creditUsed.amount * transactionRatio;
    return {
      transaction,
      totalTransactionAmount,
      transactionRatio,
      transactionAmount,
    };
  });
}

function getAccountCreditQuery(
  practiceRef: DocumentReference<IPractice>,
  range: ITimePeriod
): Query<IAccountCredit> {
  return collectionGroupQuery<IAccountCredit>(
    CollectionGroup.AccountCredits,
    where('practiceRef', '==', practiceRef),
    where('deleted', '==', false),
    where('createdAt', '>=', toTimestamp(range.from)),
    where('createdAt', '<=', toTimestamp(range.to)),
    orderBy('createdAt')
  );
}

export async function toCreditReportRecord(
  credit: WithRef<IAccountCredit>
): Promise<IAccountCreditReportRecord> {
  const patientRef = AccountCredit.patientRef(credit);
  const patient = await OrganisationCache.patients.getDoc(patientRef);

  const invoice = credit.invoice
    ? await Firestore.getDoc(credit.invoice)
    : undefined;

  const rawTransactions = invoice
    ? await Firestore.getDocs(undeletedQuery(Invoice.transactionCol(invoice)))
    : [];
  const transactions = rawTransactions
    .map((transaction) =>
      transaction.provider === TransactionProvider.Manual
        ? transaction
        : omit(transaction, 'extendedData')
    )
    .filter((transaction) => transaction.status === TransactionStatus.Complete)
    .map((transaction) => ({
      ...transaction,
      amount: getTransactionAmount(transaction),
    }));

  const accountCredit = {
    ...credit,
    remaining: credit.amount - credit.used,
  };

  return {
    accountCredit,
    invoice,
    patient,
    transactions,
  };
}
