import { DataPointValue } from '@principle-theorem/reporting';
import { getEnumValues } from '@principle-theorem/shared';

export interface IMeasureMetadata {
  id: string;
  label: string;
  summary: string;
  formatter?: MeasureFormatter;
  formatterValue?: string | ((value: DataPointValue) => string);
}

export enum MeasureFormatter {
  Boolean = 'boolean',
  Currency = 'currency',
  Custom = 'custom',
  Day = 'day',
  Hours = 'hours',
  Link = 'link',
  Minutes = 'minutes',
  Month = 'month',
  Number = 'number',
  Percentage = 'percentage',
  Prefix = 'prefix',
  Suffix = 'suffix',
  Text = 'text',
  Time = 'time',
  Timestamp = 'timestamp',
}

export enum MeasureReducer {
  Sum = 'sum',
  Average = 'average',
  Count = 'count',
}

export const ALL_REDUCERS = getEnumValues(MeasureReducer);
