import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  ChartItemDisplayType,
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  type IChartedRef,
  isChartedMultiStepTreatment,
  isChartedMultiStepTreatmentStep,
  type ITreatmentPlan,
  type ITreatmentPlanProposal,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  snapshot,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { intersection } from 'lodash';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChartDialogService } from '../../../chart-dialog.service';
import {
  ChartableSurfaceUpdater,
  type IEditChartableData,
} from '../../../chartable-surface-updater';
import { type IChartedSurfaceProvider } from '../../../charted-surface/add-charted-surface-provider';
import { AddMultiTreatmentToProposalProvider } from '../../../charted-surface/chart/add-multi-treatment-to-proposal-provider';
import { AddTreatmentToMultiTreatmentProposalProvider } from '../../../charted-surface/chart/add-treatment-to-multi-treatment-proposal-provider';
import { AddTreatmentToProposalProvider } from '../../../charted-surface/chart/add-treatment-to-proposal-provider';
import { StepDragDropGroup } from '../../../step-drag-drop-group';

@Component({
  selector: 'pr-charted-treatments',
  templateUrl: './charted-treatments.component.html',
  styleUrls: ['./charted-treatments.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartedTreatmentsComponent {
  trackByDisplayType = TrackByFunctions.variable<ChartItemDisplayType>();
  treatmentDisplayTypes$: Observable<ChartItemDisplayType[]>;
  selectedSurfaces$: ReplaySubject<Partial<IChartedRef>> = new ReplaySubject(1);
  @Input() stepDragDrop: StepDragDropGroup = new StepDragDropGroup();
  @Input() disabled: boolean = false;
  flaggedTreatment$: Observable<ITreatmentPlanProposal>;

  @Input()
  set selectedSurfaces(selectedSurfaces: Partial<IChartedRef>) {
    if (selectedSurfaces) {
      this.selectedSurfaces$.next(selectedSurfaces);
    }
  }

  constructor(
    private _chartStore: ChartFacade,
    private _chartDialog: ChartDialogService
  ) {
    this.flaggedTreatment$ = this._chartStore
      .clinicalChartState$(ChartId.InAppointment)
      .pipe(map((chart) => chart.flaggedTreatment));
    this.treatmentDisplayTypes$ = this._chartStore
      .chartContextFilters$(ChartId.InAppointment)
      .pipe(map((filters) => this._onlyTreatmentFilters(filters)));
  }

  async updateChartable(data: IEditChartableData): Promise<void> {
    return ChartableSurfaceUpdater.updateTreatmentSurfaces(
      data,
      await this._buildServiceProviders(data.plan, data.step)
    );
  }

  private async _buildServiceProviders(
    plan?: IChartedMultiStepTreatment | WithRef<ITreatmentPlan>,
    step?: IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>
  ): Promise<IChartedSurfaceProvider[]> {
    const feeSchedule = await snapshot(
      this._chartStore.getFeeScheduleManager().currentSchedule$
    );

    const addSurfaceProviders: IChartedSurfaceProvider[] = [
      new AddMultiTreatmentToProposalProvider(
        this._chartStore,
        this._chartDialog,
        toNamedDocument(feeSchedule)
      ),
    ];

    if (
      plan &&
      isChartedMultiStepTreatment(plan) &&
      step &&
      isChartedMultiStepTreatmentStep(step)
    ) {
      addSurfaceProviders.push(
        new AddTreatmentToMultiTreatmentProposalProvider(
          this._chartStore,
          plan,
          step,
          feeSchedule
        )
      );
    }

    addSurfaceProviders.push(
      new AddTreatmentToProposalProvider(this._chartStore, feeSchedule)
    );

    return addSurfaceProviders;
  }

  private _onlyTreatmentFilters(
    displayTypes: ChartItemDisplayType[]
  ): ChartItemDisplayType[] {
    const treatmentTypes: ChartItemDisplayType[] = [
      ChartItemDisplayType.CompletedTreatment,
    ];
    return intersection(treatmentTypes, displayTypes);
  }
}
