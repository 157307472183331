/* eslint-disable */
import 'reflect-metadata';
import { isArray } from 'lodash';

export const nodeAttributesKey = Symbol('nodeAttributes');

/**
 * Decorator for Angular component properties that will be used to build a Node's Attribute list
 */
export function NodeAttribute(): Function {
  return function (target: any, propertyKey: string): void {
    if (!Reflect.hasMetadata(nodeAttributesKey, target.constructor.prototype)) {
      Reflect.defineMetadata(
        nodeAttributesKey,
        [propertyKey],
        target.constructor.prototype
      );
      return;
    }

    const attributes = Reflect.getMetadata(
      nodeAttributesKey,
      target.constructor.prototype
    );

    if (!isArray(attributes)) {
      return;
    }
    Reflect.defineMetadata(
      nodeAttributesKey,
      [...attributes, propertyKey],
      target.constructor.prototype
    );
  };
}
