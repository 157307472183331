import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'pr-form-skeleton',
    templateUrl: './form-skeleton.component.html',
    styleUrls: ['./form-skeleton.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormSkeletonComponent {}
