import {
  serialiseSchemaContent,
  unserialiseMixedSchemaContent,
} from '@principle-theorem/editor';
import {
  IAutomatedNotification,
  isAutomatedNotification,
  ISerialisedAutomatedNotification,
  isSerialisedAutomatedNotification,
} from '@principle-theorem/principle-core/interfaces';
import { ITransformer, TransformEvent } from '@principle-theorem/shared';

export class AutomatedNotificationSerialiser
  implements
    ITransformer<IAutomatedNotification, ISerialisedAutomatedNotification>
{
  events = [TransformEvent.ToFirestore];

  guard(item: unknown): item is IAutomatedNotification {
    return isAutomatedNotification(item);
  }

  transform(item: IAutomatedNotification): ISerialisedAutomatedNotification {
    if (!item.customTemplate) {
      return item as ISerialisedAutomatedNotification;
    }
    return {
      ...item,
      customTemplate: {
        ...item.customTemplate,
        template: serialiseSchemaContent(item.customTemplate.content),
      },
    };
  }
}

export class AutomatedNotificationUnserialiser
  implements
    ITransformer<ISerialisedAutomatedNotification, IAutomatedNotification>
{
  events = [TransformEvent.FromFirestore];

  guard(item: unknown): item is ISerialisedAutomatedNotification {
    return isSerialisedAutomatedNotification(item);
  }

  transform(item: ISerialisedAutomatedNotification): IAutomatedNotification {
    if (!item.customTemplate) {
      return item as IAutomatedNotification;
    }
    return {
      ...item,
      customTemplate: {
        ...item.customTemplate,
        content: unserialiseMixedSchemaContent(item.customTemplate.template),
      },
    };
  }
}
