import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { doc, filterUndefined, type WithRef } from '@principle-theorem/shared';
import { type IStaffer } from '@principle-theorem/principle-core/interfaces';
import { Brand, OrganisationCache } from '@principle-theorem/principle-core';
import { type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AbstractResolver } from '@principle-theorem/ng-shared';
import { OrganisationService } from './organisation.service';

@Injectable()
export class StafferResolverService extends AbstractResolver<
  WithRef<IStaffer>
> {
  constructor(
    router: Router,
    private _organisation: OrganisationService
  ) {
    super(router);
  }

  getItemById(uid: string): Observable<WithRef<IStaffer>> {
    return this._organisation.brand$.pipe(
      filterUndefined(),
      switchMap((brand) =>
        OrganisationCache.staff.get.doc$(doc(Brand.stafferCol(brand), uid))
      )
    );
  }
}
