import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  WAITLIST_SORT_OPTION_MAP,
  WaitListSortOption,
  WaitListStore,
} from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { isEqual } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-wait-list-sort-selector',
  imports: [NgMaterialModule, NgSharedModule],
  templateUrl: './wait-list-sort-selector.component.html',
  styleUrl: './wait-list-sort-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaitListSortSelectorComponent {
  private _waitListStore = inject(WaitListStore);
  private _sortOptionsMap = WAITLIST_SORT_OPTION_MAP;
  private _selectedOption$ = new BehaviorSubject<WaitListSortOption>(
    WaitListSortOption.AppointmentDate
  );
  sortOptions = Object.keys(WAITLIST_SORT_OPTION_MAP) as WaitListSortOption[];

  constructor() {
    this._waitListStore.setSortBy(
      this._sortOptionsMap[WaitListSortOption.AppointmentDate]
    );
  }

  isSelected$(option: WaitListSortOption): Observable<boolean> {
    return this._selectedOption$.pipe(
      map((selectedOption) => isEqual(selectedOption, option))
    );
  }

  selectOption(option: WaitListSortOption): void {
    this._selectedOption$.next(option);
    this._waitListStore.setSortBy(this._sortOptionsMap[option]);
  }
}
