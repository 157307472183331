import { type MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { type MatSelectChange } from '@angular/material/select';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  Practice,
  RecurrencePattern,
  Task,
} from '@principle-theorem/principle-core';
import {
  TASK_PRIORITIES,
  type IRecurringTaskConfiguration,
  type ITask,
  type TaskPriority,
} from '@principle-theorem/principle-core/interfaces';
import {
  FREQUENCIES,
  Frequency,
  filterUndefined,
  snapshot,
  toISODate,
  type IRecurrencePattern,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RecurrenceDialogComponent } from './components/recurrence-dialog/recurrence-dialog.component';
import { TaskFormGroup } from './components/task-form/task-form.formgroup';
import { taskToFormData } from './task-form-conversion';
import { TaskManager } from './task-manager';

export class BaseTaskForm {
  protected _taskManager: TaskManager;
  taskFormGroup = new TaskFormGroup();
  frequencies: Frequency[] = FREQUENCIES;
  taskPriorities: TaskPriority[] = TASK_PRIORITIES;
  recurringTaskConfiguration?: WithRef<IRecurringTaskConfiguration>;
  openTime$: Observable<moment.Moment>;
  closeTime$: Observable<moment.Moment>;
  timeInterval: moment.Duration = moment.duration(30, 'minutes');

  constructor(
    protected _dialog: MatDialog,
    protected _currentScope: CurrentScopeFacade,
    protected _organisation: OrganisationService
  ) {
    this._organisation.staffer$
      .pipe(filterUndefined())
      .subscribe((staffer) => (this._taskManager = new TaskManager(staffer)));

    this.openTime$ = this._currentScope.currentPractice$.pipe(
      filterUndefined(),
      map((practice) => Practice.openTime(practice))
    );
    this.closeTime$ = this._currentScope.currentPractice$.pipe(
      filterUndefined(),
      map((practice) => Practice.closeTime(practice))
    );
  }

  async updateFormValues(task: WithRef<ITask>): Promise<void> {
    const formData = await taskToFormData(task);
    this.taskFormGroup.patchValue(formData, { emitEvent: false });

    const recurrenceConfiguration = await snapshot(
      Task.recurrenceConfiguration$(task)
    );
    if (!recurrenceConfiguration) {
      return;
    }
    this.recurringTaskConfiguration = recurrenceConfiguration;
  }

  clearDueDate(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.taskFormGroup.controls.dueDate.setValue(undefined);
    this.taskFormGroup.controls.dueTime.setValue('');
  }

  setStartDate(date: MatDatepickerInputEvent<Moment>): void {
    if (date.value) {
      this.taskFormGroup.controls.recurrencePattern.setValue(
        RecurrencePattern.init({
          startDate: toISODate(date.value),
        })
      );
    }
  }

  /**
   * Set recurrence value, open custom dialog if requested
   * @param $event MatSelectChange
   * @returns void
   */
  async setRecurrence($event: MatSelectChange): Promise<void> {
    this.taskFormGroup.controls.recurrencePattern.setValue(undefined);
    if (!$event.value) {
      return;
    }

    if ($event.value !== Frequency.Custom) {
      this.taskFormGroup.controls.recurrencePattern.setValue(
        RecurrencePattern.init({
          frequencyType: $event.value as Frequency,
        })
      );
      return;
    }

    await this.editCustomRecurrence();
  }

  isSelectedFrequency(
    frequency: Frequency,
    selectedFrequency?: Frequency
  ): boolean {
    if (!selectedFrequency) {
      return false;
    }
    return frequency === selectedFrequency;
  }

  async editCustomRecurrence($event?: MouseEvent): Promise<void> {
    $event?.stopPropagation();

    const pattern = await this._dialog
      .open<
        RecurrenceDialogComponent,
        Partial<IRecurrencePattern>,
        IRecurrencePattern
      >(
        RecurrenceDialogComponent,
        DialogPresets.medium({
          data: this.taskFormGroup.controls.recurrencePattern.value ?? {},
          autoFocus: true,
        })
      )
      .afterClosed()
      .toPromise();

    if (!pattern) {
      return;
    }
    this.taskFormGroup.controls.recurrencePattern.setValue(pattern);
  }
}
