import { createAction, props } from '@ngrx/store';
import { getSelectAction } from '@principle-theorem/ng-shared';
import {
  type ICalendarEvent,
  type ICandidateCalendarEvent,
  type IEventTimePeriod,
} from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import {
  AppointmentSuggestionSearchType,
  type AppointmentSuggestionEntity,
} from '@principle-theorem/principle-core';

export enum AppointmentSuggestionActions {
  Load = '[Appointment Suggestions] Load Suggestions',
  LoadSuccess = '[Appointment Suggestions] Load Suggestions Success',
  Select = '[Appointment Suggestions] Select Suggestion',
  Unselect = '[Appointment Suggestions] Unselect Suggestion',
  ResetSuggestions = '[Appointment Suggestions] Reset Suggestions',
  Reset = '[Appointment Suggestions] Reset',
  GapCandidatesLoaded = '[Appointment Suggestions] Gap Candidates Loaded',
  LoadAvailability = '[Appointment Suggestions] Load Availability',
  LoadAvailabilitySuccess = '[Appointment Suggestions] Load Availability Success',
  LoadCalendarEventsSuccess = '[Appointment Suggestions] Load Calendar Events Success',
  AddAppointmentSuggestion = '[Appointment Suggestions] Add Existing Appointment Suggestion',
  RemoveSuggestion = '[Appointment Suggestion] Remove Suggestion',
  SetSearchType = '[Appointment Suggestions] Set Search Type',
}

export const loadAppointmentAvailability = createAction(
  AppointmentSuggestionActions.LoadAvailability
);

export const loadAppointmentSuggestions = createAction(
  AppointmentSuggestionActions.Load
);

export const resetAppointmentSuggestions = createAction(
  AppointmentSuggestionActions.ResetSuggestions
);

interface ILoadSuggestions {
  appointmentSuggestions: AppointmentSuggestionEntity[];
}

export const loadAppointmentSuggestionsSuccess = createAction(
  AppointmentSuggestionActions.LoadSuccess,
  props<SerialisedData<ILoadSuggestions>>()
);

export const selectSuggestion = getSelectAction(
  AppointmentSuggestionActions.Select
);

export const unselectSuggestion = createAction(
  AppointmentSuggestionActions.Unselect
);

export const reset = createAction(AppointmentSuggestionActions.Reset);

interface ILoadAvailabilitySuccess {
  availableTimes: IEventTimePeriod[];
}

export const loadAvailabilitySuccess = createAction(
  AppointmentSuggestionActions.LoadAvailabilitySuccess,
  props<SerialisedData<ILoadAvailabilitySuccess>>()
);

interface ILoadGapCandidates {
  gapCandidates: WithRef<ICandidateCalendarEvent>[];
}

export const gapCandidatesLoaded = createAction(
  AppointmentSuggestionActions.GapCandidatesLoaded,
  props<SerialisedData<ILoadGapCandidates>>()
);

interface IAddSuggestion {
  appointmentSuggestion: AppointmentSuggestionEntity;
}

export const addAppointmentSuggestion = createAction(
  AppointmentSuggestionActions.AddAppointmentSuggestion,
  props<SerialisedData<IAddSuggestion>>()
);

export const removeSuggestion = getSelectAction(
  AppointmentSuggestionActions.RemoveSuggestion
);

interface ISetSearchType {
  searchType: AppointmentSuggestionSearchType;
}

export const setSearchType = createAction(
  AppointmentSuggestionActions.SetSearchType,
  props<ISetSearchType>()
);

interface ILoadCalendarEventsSuccess {
  calendarEvents: WithRef<ICalendarEvent>[];
}

export const loadCalendarEventsSuccess = createAction(
  AppointmentSuggestionActions.LoadCalendarEventsSuccess,
  props<SerialisedData<ILoadCalendarEventsSuccess>>()
);
