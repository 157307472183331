import { Validators } from '@angular/forms';
import {
  initVersionedSchema,
  type VersionedSchema,
} from '@principle-theorem/editor';
import {
  type IWaitListDays,
  type IWaitListDetails,
} from '@principle-theorem/ng-appointment/store';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  ADD_TO_WAITLIST_BY_DEFAULT,
  type WaitListUrgency,
  WAIT_LIST_URGENCIES,
} from '@principle-theorem/principle-core/interfaces';
import { toMoment } from '@principle-theorem/shared';
import { isString } from 'lodash';
import * as moment from 'moment-timezone';

export class WaitlistFormGroup extends TypedFormGroup<IWaitListDetails> {
  constructor() {
    const dateFrom = moment();
    const dateTo = toMoment(dateFrom).add(1, 'week');

    super({
      addToWaitlist: new TypedFormControl<boolean>(ADD_TO_WAITLIST_BY_DEFAULT),
      dateFrom: new TypedFormControl<moment.Moment>(
        dateFrom,
        Validators.required
      ).withGuard(moment.isMoment),
      dateTo: new TypedFormControl<moment.Moment>(
        dateTo,
        Validators.required
      ).withGuard(moment.isMoment),
      timeFrom: new TypedFormControl<string>().withGuard(isString),
      timeTo: new TypedFormControl<string>().withGuard(isString),
      urgency: new TypedFormControl<WaitListUrgency>(
        WAIT_LIST_URGENCIES[0].value,
        Validators.required
      ),
      notes: new TypedFormControl<VersionedSchema>(
        initVersionedSchema(),
        Validators.required
      ),
      days: new TypedFormGroup<IWaitListDays>({
        monday: new TypedFormControl<boolean>(true),
        tuesday: new TypedFormControl<boolean>(true),
        wednesday: new TypedFormControl<boolean>(true),
        thursday: new TypedFormControl<boolean>(true),
        friday: new TypedFormControl<boolean>(true),
        saturday: new TypedFormControl<boolean>(false),
        sunday: new TypedFormControl<boolean>(false),
      }),
    });
  }
}
