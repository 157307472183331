<ng-container *ngIf="option$ | async as option">
  <ng-container *ngIf="option.provider.isEnabled$ | async">
    <pt-loader-button
      type="flat"
      color="primary"
      [cta]="true"
      [ngClass]="{ adding: adding$ | async }"
      [disabled]="(canCapture$ | async) === false"
      [loading]="adding$ | async"
      (clicked)="capture()"
    >
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <pr-background-image
          *ngIf="option.imageUrl; else showIcon"
          class="logo"
          [src]="option.imageUrl"
          [fitImage]="true"
        >
        </pr-background-image>

        <ng-template #showIcon>
          <mat-icon *ngIf="(adding$ | async) === false">
            {{ option.icon }}
          </mat-icon>
        </ng-template>

        <div fxLayout="column" fxLayoutAlign="center start">
          <div class="label">{{ option.label }}</div>
          <div *ngIf="info$ | async as subtext" class="subtext mat-caption">
            {{ subtext }}
          </div>
        </div>
      </div>
    </pt-loader-button>
  </ng-container>
</ng-container>
