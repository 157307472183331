import { rand, randFloat, randNumber } from '@ngneat/falso';
import {
  TransactionAction,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import {
  FactsCommon,
  IStafferDimension,
  ITransactionDimension,
  ITransactionEventFact,
} from '@principle-theorem/reporting/interfaces';
import {
  BaseMock,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
} from '@principle-theorem/shared';
import { shuffle, sum, take } from 'lodash';
import { BrandDimensionMock } from '../dimensions/brand-dimension.mock';
import { SerialisedDocumentReferenceMock } from '../dimensions/common.mock';
import { InvoiceDimensionMock } from '../dimensions/invoice-dimension.mock';
import {
  PatientDimensionMock,
  MOCKED_REFERRERS,
} from '../dimensions/patient-dimension.mock';
import { MOCKED_PRACTICES } from '../dimensions/practice-dimension.mock';
import { MOCKED_STAFF } from '../dimensions/staffer-dimension.mock';
import { TransactionDimensionMock } from '../dimensions/transaction-dimension.mock';
import { TreatmentPlanDimensionMock } from '../dimensions/treatment-plan-dimension.mock';

export class TransactionEventFactMock
  extends BaseMock
  implements ITransactionEventFact
{
  invoiceRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practiceRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  treatmentPlanRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  transactionRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  patientRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  brandRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  timestamp = toSerialisedTimestamp(MockTimestamp());
  transaction = MockGenerator.generate(TransactionDimensionMock);
  invoice = MockGenerator.generate(InvoiceDimensionMock);
  brand = MockGenerator.generate(BrandDimensionMock);
  patient = MockGenerator.generate(PatientDimensionMock);
  treatmentPlan = MockGenerator.generate(TreatmentPlanDimensionMock);
  practice = rand(MOCKED_PRACTICES);
  referrer = rand(MOCKED_REFERRERS);
  event = {
    action: TransactionAction.Approve,
    statusBefore: TransactionStatus.Pending,
    statusAfter: TransactionStatus.Complete,
  };
  practitionerPayments = MockPractitionerPayments(this.transaction);
}

function MockPractitionerPayments(
  transaction: ITransactionDimension
): FactsCommon.IBigQueryPaymentPracitionerSummary[] {
  const practitionerCount = randNumber({ min: 1, max: 3 });
  const practitioners = shuffle(MOCKED_STAFF);

  return take(practitioners, practitionerCount).reduce(
    (acc: FactsCommon.IBigQueryPaymentPracitionerSummary[], staffer) => {
      const allocated = sum(acc.map((r) => r.allocatedAmount));
      return [...acc, MockPractitionerPayment(transaction, staffer, allocated)];
    },
    []
  );
}

function MockPractitionerPayment(
  transaction: ITransactionDimension,
  staffer: IStafferDimension,
  allocated: number
): FactsCommon.IBigQueryPaymentPracitionerSummary {
  const remaining = transaction.amount - allocated;

  const availablePercent = remaining / transaction.amount;
  const allocatedPercent = randFloat({ min: 0, max: availablePercent });
  const amount = transaction.amount * allocatedPercent;

  const isNegative = transaction.type === String(TransactionType.Outgoing);
  const allocatedAmount = isNegative ? -amount : amount;

  return {
    allocatedPercent,
    allocatedAmount,
    practitioner: {
      name: staffer.user.name,
      ref: staffer.ref,
    },
  };
}
