<form [formGroup]="form" (ngSubmit)="submitted.emit()">
  <div class="!flex !gap-2">
    <pr-date-selector
      placeholder="Start"
      formControlName="from"
      [timeTo]="form.controls.to.value"
      [required]="true"
      (keydown.Enter)="submitted.emit()"
    />

    <pr-date-selector
      placeholder="End"
      formControlName="to"
      [timeFrom]="form.controls.from.value"
      [required]="true"
      (keydown.Enter)="submitted.emit()"
    />
  </div>
</form>
