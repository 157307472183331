import { Injectable } from '@angular/core';
import {
  MixedSchema,
  toMentionContent,
  toTextContent,
  versionedSchemaToHtml,
} from '@principle-theorem/editor';
import { FirebaseFunctionsService } from '@principle-theorem/ng-firebase';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  Interaction,
  addInteractions,
  getMentionContactDetails,
  stafferToNamedDoc,
  toMention,
  type IInteractionError,
} from '@principle-theorem/principle-core';
import {
  IInteractionV2,
  InteractionType,
  MentionResourceType,
  type IPractice,
  type IPrincipleMention,
  type ISendEmailRequest,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  serialise,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { EditorPresetsService } from '../editor/editor-presets.service';

export interface ISendEmailData {
  subject: string;
  content: string;
}

export interface IEmailFormData {
  subject: string;
  content: MixedSchema;
  practice: WithRef<IPractice>;
}

@Injectable()
export class SendEmailService {
  constructor(
    private _functions: FirebaseFunctionsService,
    private _editorPresets: EditorPresetsService,
    private _organisation: OrganisationService
  ) {}

  async send(
    mention: IPrincipleMention,
    formData: IEmailFormData
  ): Promise<IInteractionError | undefined> {
    const email = await this._getMentionEmail(mention);
    if (!email) {
      return { message: 'Could not resolve email address' };
    }

    const emailData = this._toEmailData(formData);

    try {
      await this._sendEmailRequest({
        to: email,
        subject: formData.subject,
        text: emailData.content,
        html: emailData.content,
        senderRef: formData.practice.ref,
      });

      const interaction = await this._getEmailInteraction(formData, mention);
      await addInteractions({ contact: mention }, interaction);
      return;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to send email:', error);
      return { message: 'Failed to send email' };
    }
  }

  private _toEmailData(formData: IEmailFormData): ISendEmailData {
    const extensions = this._editorPresets.defaultToHTMLExtensions();
    const content = versionedSchemaToHtml(formData.content, extensions);
    return { subject: formData.subject, content };
  }

  private async _getMentionEmail(
    mention: IPrincipleMention
  ): Promise<string | undefined> {
    const details = await getMentionContactDetails(mention);
    return details?.email;
  }

  private async _sendEmailRequest(request: ISendEmailRequest): Promise<void> {
    const data = serialise(request);
    await this._functions.call('http-sendgrid-sendEmail', data);
  }

  private async _getEmailInteraction(
    formData: IEmailFormData,
    contact: IPrincipleMention
  ): Promise<IInteractionV2> {
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    return Interaction.init({
      type: InteractionType.Email,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` sent Email to `),
        toMentionContent(contact),
      ],
      owner: stafferToNamedDoc(staffer),
      content: formData.content,
    });
  }
}
