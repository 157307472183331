import {
  getSchemaText,
  initVersionedSchema,
  toTextContent,
  type RawInlineNodes,
  getSchemaSize,
} from '@principle-theorem/editor';
import {
  InteractionCollection,
  InteractionType,
  interactionTypeDisplayMap,
  isInteractionV2,
  type IInteraction,
  type IInteractionV2,
  type IStaffer,
  type ITag,
  type WithContext,
  ISchedulingEvent,
  INTERACTION_TYPE_LABEL_MAP,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentArchive,
  DocumentReference,
  all$,
  doc$,
  getDoc,
  multiSort,
  multiSwitchMap,
  saveDoc,
  sortByCreatedAt,
  sortTimestamp,
  subCollection,
  toMoment,
  toTimestamp,
  type ArchivedDocument,
  type CollectionReference,
  type Transaction as FirebaseTransaction,
  type IReffable,
  type WithRef,
  Firestore,
} from '@principle-theorem/shared';
import { first, kebabCase, omit, toUpper, xor } from 'lodash';
import * as moment from 'moment-timezone';
import { of, type Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { OrganisationCache } from '../organisation/organisation-cache';

export function getLatestInteraction<T extends IInteraction>(
  interactions: T[]
): T | undefined {
  return first([...interactions].sort(sortByCreatedAt));
}

export class Interaction {
  static init(overrides: Partial<IInteractionV2>): IInteractionV2 {
    return {
      uid: uuid(),
      title: [],
      content: initVersionedSchema(),
      type: InteractionType.Note,
      action: '',
      createdAt: toTimestamp(),
      pinned: false,
      deleted: false,
      tags: [],
      ...overrides,
    };
  }

  static distance(interaction: IInteraction): string {
    const distance: string = moment().to(toMoment(interaction.createdAt));
    return distance.charAt(0).toUpperCase() + distance.slice(1);
  }

  static owner$(
    interaction: IInteraction
  ): Observable<WithRef<IStaffer> | undefined> {
    if (!interaction.owner) {
      return of(undefined);
    }
    return OrganisationCache.staff.get.doc$(interaction.owner.ref);
  }

  static tags$(interaction: IInteractionV2): Observable<WithRef<ITag>[]> {
    if (!interaction.tags) {
      return of([]);
    }

    return of(interaction.tags).pipe(multiSwitchMap((tag) => doc$(tag.ref)));
  }

  static getTitle(interaction: WithContext<IInteraction>): RawInlineNodes {
    if (!interaction.suffix) {
      return interaction.title;
    }
    return [
      ...interaction.title,
      toTextContent(' from '),
      ...interaction.suffix,
    ];
  }

  static getInteractionTypeIcon(interaction: IInteraction): string {
    return interactionTypeDisplayMap[interaction.type];
  }

  static getInteractionTypeLabel(interaction: IInteraction): string {
    return INTERACTION_TYPE_LABEL_MAP[interaction.type];
  }

  static getInteractionTypeColourClass(interaction: IInteraction): string {
    return first(`${interaction.type}`.split('.')) ?? '';
  }

  static amendmentHistoryCol(
    interaction: IReffable<IInteractionV2>
  ): CollectionReference<ArchivedDocument<IInteractionV2>> {
    return subCollection<ArchivedDocument<IInteractionV2>>(
      interaction.ref,
      InteractionCollection.History
    );
  }

  static amendmentHistory$(
    interaction: IReffable<IInteractionV2>
  ): Observable<WithRef<ArchivedDocument<IInteractionV2>>[]> {
    return all$(Interaction.amendmentHistoryCol(interaction)).pipe(
      multiSort((a, b) => sortTimestamp(a.archivedAt, b.archivedAt))
    );
  }

  static getPinnedInteractions(
    interactions: (IInteraction | IInteractionV2)[]
  ): (IInteraction | IInteractionV2)[] {
    return interactions
      .filter((interaction) => Interaction.isPinnedInteraction(interaction))
      .sort(sortByCreatedAt);
  }

  static isPinnedInteraction(
    interaction: IInteraction | IInteractionV2
  ): unknown {
    return isInteractionV2(interaction) && interaction.pinned;
  }

  static async amend(
    interaction: WithRef<WithContext<IInteractionV2>>,
    archivedBy?: DocumentReference<IStaffer>,
    atomicTransaction?: FirebaseTransaction
  ): Promise<void> {
    interaction.type = interaction.originalType ?? interaction.type;
    interaction = omit(interaction, ['originalType', 'suffix']);
    const currentInteraction = await getDoc(interaction.ref, atomicTransaction);
    const contentChanged =
      getSchemaText(currentInteraction.content) !==
      getSchemaText(interaction.content);
    const tagsChanged =
      xor(
        currentInteraction.tags.map((tag) => tag.ref.path),
        interaction.tags.map((tag) => tag.ref.path)
      ).length > 0;

    if (!contentChanged && !tagsChanged) {
      await saveDoc(interaction, undefined, atomicTransaction);
      return;
    }

    const historyRef = await DocumentArchive.snapshotToArchive(
      await getDoc(interaction.ref),
      Interaction.amendmentHistoryCol(interaction),
      undefined,
      archivedBy,
      atomicTransaction
    );
    await saveDoc(
      {
        ...interaction,
        amendmentOf: historyRef,
      },
      undefined,
      atomicTransaction
    );
  }

  static canEdit(interaction: IInteraction | WithRef<IInteractionV2>): boolean {
    const editableTypes = [
      InteractionType.Note,
      InteractionType.Call,
      InteractionType.Appointment,
    ];
    const isEditableNote =
      this.hasContent(interaction) &&
      !!interaction.owner &&
      editableTypes.includes(interaction.type);
    const isSchedulingEvent =
      isInteractionV2(interaction) && !!interaction.schedulingEvent;
    return isEditableNote || isSchedulingEvent;
  }

  static hasContent(
    interaction: IInteraction | WithRef<IInteractionV2>
  ): boolean {
    return getSchemaSize(interaction.content) > 0;
  }

  static getSchedulingEvent$(
    interaction: IInteraction | WithRef<IInteractionV2>
  ): Observable<WithRef<ISchedulingEvent> | undefined> {
    return isInteractionV2(interaction) && interaction.schedulingEvent
      ? Firestore.doc$(interaction.schedulingEvent)
      : of(undefined);
  }
}

export function formatEnumValueForInteraction(value: string): string {
  return toUpper(kebabCase(value).replace('-', ' '));
}
