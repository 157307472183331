import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import {
  formControlChanges$,
  isDisabled$,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  Invoice,
  type IPractitionerProportionInvoiceAmount,
  isDiscountTransaction,
  Transaction,
  TransactionOperators,
} from '@principle-theorem/principle-core';
import {
  type IInvoice,
  type IPractice,
  type IStaffer,
  type ITransaction,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  isSameRef,
  shareReplayCold,
  type WithRef,
} from '@principle-theorem/shared';
import { omit, sortBy } from 'lodash';
import { type Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import {
  TransactionPracticeOptionMethods,
  TransactionPracticeOptions,
} from '../transaction-components/transaction-practice-options';

export interface IDiscountTransactionData {
  invoice: WithRef<IInvoice>;
  to: string;
  from: string;
  amount: number;
}

interface IDiscountFormData extends Pick<IDiscountTransactionData, 'amount'> {
  description: string;
  practiceRef: DocumentReference<IPractice>;
  practitionerRef?: DocumentReference<IStaffer>;
}

@Component({
  selector: 'pr-discount-transaction',
  templateUrl: './discount-transaction.component.html',
  styleUrls: ['./discount-transaction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountTransactionComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  form: TypedFormGroup<IDiscountFormData>;
  isDisabled$: Observable<boolean>;
  maxAmount$: Observable<number>;
  trackByPractitionerRef =
    TrackByFunctions.ref<IPractitionerProportionInvoiceAmount>(
      'practitioner.ref'
    );
  invoicePractitioners: IPractitionerProportionInvoiceAmount[];
  practiceOptions: TransactionPracticeOptions;

  constructor(
    private _dialogRef: MatDialogRef<
      DiscountTransactionComponent,
      ITransaction
    >,
    @Inject(MAT_DIALOG_DATA) public data: IDiscountTransactionData,
    private _currentScope: CurrentScopeFacade
  ) {
    this.form = new TypedFormGroup<IDiscountFormData>({
      amount: new TypedFormControl<number>(this.data.amount, [
        Validators.required,
        Validators.max(this.data.amount),
        Validators.min(0),
      ]),
      description: new TypedFormControl<string>(''),
      practiceRef: new TypedFormControl<DocumentReference<IPractice>>(
        undefined,
        Validators.required
      ),
      practitionerRef: new TypedFormControl<DocumentReference<IStaffer>>(),
    });
    this.isDisabled$ = isDisabled$(this.form);
    this.form.patchValue(this.data);
    this.practiceOptions = new TransactionPracticeOptions(
      this._currentScope.currentPractice$,
      TransactionPracticeOptionMethods.toInvoicePracticeOption(
        this.data.invoice
      ),
      true
    );
    this.practiceOptions.initialValue$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((practice) =>
        this.form.controls.practiceRef.setValue(practice.ref)
      );

    this.invoicePractitioners = sortBy(
      Invoice.getPractitionerProportionsOnInvoice(data.invoice),
      (practitioner) => practitioner.practitioner.name
    );

    const activeDiscounts$ = Invoice.transactions$(data.invoice).pipe(
      map((transactions) =>
        new TransactionOperators(transactions)
          .byProvider(TransactionProvider.Discount)
          .transactionGuard(isDiscountTransaction)
          .result()
      )
    );

    this.maxAmount$ = formControlChanges$(
      this.form.controls.practitionerRef
    ).pipe(
      withLatestFrom(activeDiscounts$),
      map(([selectedPractitionerRef, activeDiscounts]) => {
        const existingPractitionerDiscountAmount = new TransactionOperators(
          activeDiscounts.filter((discount) => {
            if (!discount.extendedData?.practitionerRef) {
              return true;
            }

            if (
              isSameRef(
                discount.extendedData.practitionerRef,
                selectedPractitionerRef
              )
            ) {
              return true;
            }

            return false;
          })
        ).paidToDate();

        if (!selectedPractitionerRef) {
          return this.data.amount;
        }

        const practitionerProportion = this.invoicePractitioners.find(
          (practitioner) =>
            isSameRef(practitioner.practitioner, selectedPractitionerRef)
        );

        return practitionerProportion
          ? practitionerProportion.amount - existingPractitionerDiscountAmount
          : this.data.amount;
      }),
      map((maxValue) =>
        this.data.amount < maxValue ? this.data.amount : maxValue
      ),
      tap((maxValue) => {
        const currentValue = this.form.controls.amount.value ?? undefined;
        if (currentValue && currentValue > maxValue) {
          this.form.controls.amount.setValue(maxValue);
        }
        this.form.controls.amount.setValidators([
          Validators.required,
          Validators.max(maxValue),
          Validators.min(0),
        ]);
        this.form.updateValueAndValidity();
      }),
      startWith(this.data.amount),
      shareReplayCold()
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }
    const data = this.form.getRawValue();
    const transaction = Transaction.init({
      ...omit(this.data, 'invoice'),
      amount: data.amount,
      ...Transaction.internalReference(TransactionProvider.Discount),
      type: TransactionType.Incoming,
      status: TransactionStatus.Complete,
      description: data.description,
      practiceRef: data.practiceRef,
      extendedData: {
        practitionerRef: data.practitionerRef,
      },
    });
    this._dialogRef.close(transaction);
  }

  comparePractitionerRefFn(
    value: DocumentReference<IStaffer>,
    practitioner: DocumentReference<IStaffer>
  ): boolean {
    if (!value || !practitioner) {
      return false;
    }
    return isSameRef(value, practitioner);
  }
}
