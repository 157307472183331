/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isObject, type ObjectOfType } from '@principle-theorem/shared';
import { includes, isArray } from 'lodash';
import { type NodeSchema } from '../schema';

export function isNodeSchemaOfType<T extends string>(
  item: ObjectOfType<any>,
  type: T
): item is NodeSchema<T> {
  return isNodeSchema(item) && item.type === type;
}

export function isNodeSchema<Type extends string = string>(
  item: ObjectOfType<any>,
  parent?: ObjectOfType<any>
): item is NodeSchema<Type> {
  if (!item || typeof item !== 'object' || !('type' in item)) {
    return false;
  }

  if ('content' in item || 'marks' in item) {
    return true;
  }

  const isRootNode = !parent ? true : false;
  if (isRootNode) {
    return true;
  }

  if (
    parent &&
    'content' in parent &&
    Array.isArray(parent.content) &&
    includes(parent.content, item)
  ) {
    return true;
  }

  return false;
}

export function recursiveReplace(
  item: ObjectOfType<any>,
  replaceFn: (
    current: ObjectOfType<any>,
    parents: ObjectOfType<any>[],
    key?: string
  ) => ObjectOfType<any>,
  parents: ObjectOfType<any>[] = [],
  currentKey?: string
): ObjectOfType<any> {
  if (!item || typeof item !== 'object') {
    return item;
  }

  if (!(typeof item === 'object') && !Array.isArray(item)) {
    return item;
  }

  if (!parents.length) {
    item = replaceFn(item, parents);
  }

  const keyPrefix = currentKey ? `${currentKey}.` : '';

  const newParents = [...parents, item];
  for (const [key] of Object.entries(item)) {
    if (isArray(item[key])) {
      item[key].map((_value: any, index: number) => {
        item[key][index] = recursiveReplace(
          replaceFn(
            item[key][index] as ObjectOfType<any>,
            newParents,
            `${keyPrefix}${key}`
          ),
          replaceFn,
          newParents,
          `${keyPrefix}${key}`
        );
      });
    }

    if (isObject(item[key]) && !isArray(item[key])) {
      item[key] = recursiveReplace(
        replaceFn(
          item[key] as ObjectOfType<any>,
          parents,
          `${keyPrefix}${key}`
        ),
        replaceFn,
        newParents,
        `${keyPrefix}${key}`
      );
    }
  }

  return item;
}

export function recursiveFind<T extends ObjectOfType<any>>(
  item: ObjectOfType<any>,
  predicateFn: (data: unknown) => boolean
): T | undefined {
  if (!(typeof item === 'object') && !Array.isArray(item)) {
    return;
  }

  if (typeof item === 'object') {
    if (predicateFn(item)) {
      return item as T;
    }
  }

  for (const [key] of Object.entries(item)) {
    if (isArray(item[key])) {
      for (let index = 0; index < item[key].length; index++) {
        if (predicateFn(item[key][index])) {
          return item[key][index];
        }

        const result = recursiveFind<T>(
          item[key][index] as ObjectOfType<any>,
          predicateFn
        );
        if (result) {
          return result;
        }
      }
    }

    if (predicateFn(item[key])) {
      return item[key] as T;
    }
  }
}

export function recursiveFindArray<T extends ObjectOfType<any>>(
  item: ObjectOfType<any>,
  predicateFn: (data: ObjectOfType<any>) => boolean | undefined
): T[] {
  const results: T[] = [];

  if (!(typeof item === 'object') && !Array.isArray(item)) {
    return [];
  }

  if (typeof item !== 'object') {
    return results;
  }

  const result = predicateFn(item);
  if (result === undefined) {
    return [];
  }
  if (result) {
    results.push(item as T);
  }

  for (const [key] of Object.entries(item)) {
    if (isArray(item[key])) {
      for (let index = 0; index < item[key].length; index++) {
        results.push(
          ...recursiveFindArray<T>(
            item[key][index] as ObjectOfType<any>,
            predicateFn
          )
        );
      }
      continue;
    }

    if (isObject(item[key])) {
      results.push(
        ...recursiveFindArray<T>(item[key] as ObjectOfType<any>, predicateFn)
      );
    }
  }

  return results;
}
