<div class="mb-4 font-bold opacity-90">Scanned Records</div>

@if (records$ | async; as records) {
  @if (records.length) {
    <div class="flex flex-col gap-3">
      @for (record of records; track record.ref.path) {
        @defer (on viewport) {
          <pr-sterilisation-record-item
            [record]="record"
            [packOptions]="packOptions$ | async"
            [omitFields]="omitFields$ | async"
          />
        } @placeholder (minimum 150ms) {
          <ngx-skeleton-loader
            animation="pulse"
            count="1"
            [theme]="{ height: '100px' }"
          />
        }
      }
    </div>
  } @else {
    <pr-empty-state image="list" title="sterilisation records" />
  }
}
