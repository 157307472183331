import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import {
  Appointment,
  Automation,
  TreatmentStep,
  getUnique,
  upsertCommon,
  type AutomationEntity,
  type IRescheduleListData,
} from '@principle-theorem/principle-core';
import {
  AnyAutomation,
  AutomationStatus,
  IAppointment,
  type IAutomation,
  type IEvent,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  DEFAULT_TIMEZONE,
  filterUndefined,
  isWithRef,
  multiMap,
  reduce2DArray,
  snapshot,
  toEntityModel,
  toEntityModels$,
  toTimestamp,
  type AtLeast,
  type Timezone,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import {
  combineLatest,
  of,
  type Observable,
  type OperatorFunction,
} from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

export interface IAutomationRescheduleStoreState {
  updating: boolean;
  listData: IRescheduleListData[];
  automationConfigurations: IRescheduleListData[];
  timezone: Timezone;
}

const initialState: IAutomationRescheduleStoreState = {
  updating: false,
  listData: [],
  automationConfigurations: [],
  timezone: DEFAULT_TIMEZONE,
};

interface IUpdateListData {
  id: string;
  listDataChange: Partial<IRescheduleListData>;
}

interface IAddAutomation {
  automation: IAutomation<AnyAutomation> | undefined;
  newEvent: IEvent | undefined;
}

interface ISetAutomationConfigurations {
  configurations: AutomationEntity[];
  newEvent: IEvent | undefined;
}

@Injectable()
export class AutomationRescheduleStore extends ComponentStore<IAutomationRescheduleStoreState> {
  private _currentScope = inject(CurrentScopeFacade);
  readonly updating$ = this.select((store) => store.updating);
  readonly timezone$ = this.select((store) => store.timezone);
  readonly listData$ = this.select((store) => store.listData);
  readonly automationConfigurations$ = this.select(
    (store) => store.automationConfigurations
  );
  readonly automationListData$ = this.select(
    this.listData$,
    this.automationConfigurations$,
    (listData, automationConfigurations) => [
      ...listData,
      ...automationConfigurations,
    ]
  );

  readonly loadAutomationConfigurations = this.effect(
    this._loadAutomationConfigurations()
  );
  readonly setTimezone = this.effect((timezone$: Observable<Timezone>) =>
    timezone$.pipe(
      tap((timezone) => this.patchState((state) => ({ state, timezone })))
    )
  );

  readonly patchListDataById = this.updater((state, data: IUpdateListData) => {
    const newListData = this._upsertListDataChangesById(state.listData, data);
    const newConfigData = this._upsertListDataChangesById(
      state.automationConfigurations,
      data
    );
    return {
      ...state,
      listData: newListData,
      automationConfigurations: newConfigData,
    };
  });

  readonly addAutomation = this.updater((state, data: IAddAutomation) => {
    if (!data.automation) {
      return state;
    }

    const newListData = this.automationToListData(
      toEntityModel(data.automation),
      data.newEvent,
      state.timezone
    );
    return { ...state, listData: [...state.listData, newListData] };
  });

  readonly setAutomationConfigurations = this.updater(
    (state, configurationData: ISetAutomationConfigurations) => {
      const automationConfigurations = this._automationsToListData(
        configurationData.configurations,
        configurationData.newEvent,
        state.timezone
      );
      return {
        ...state,
        automationConfigurations,
      };
    }
  );

  constructor() {
    super(initialState);
  }

  buildListData(
    treatmentStepAutomations: AutomationEntity[],
    newEvent: IEvent | undefined,
    currentData: IRescheduleListData[],
    timezone: Timezone
  ): IRescheduleListData[] {
    const currentAutomations = currentData.map((data) => data.automation);
    const newAutomations = getUnique(
      currentAutomations,
      treatmentStepAutomations,
      (a, b) => a.uid === b.uid
    );
    const existingAutomations = upsertCommon(
      currentAutomations,
      treatmentStepAutomations,
      (a, b) => a.uid === b.uid
    );
    const allAutomations = [...existingAutomations, ...newAutomations];
    return this._automationsToListData(
      allAutomations,
      newEvent,
      timezone,
      currentData
    );
  }

  automationToListData(
    automation: AutomationEntity,
    newEvent: IEvent | undefined,
    timezone: Timezone,
    existing?: IRescheduleListData,
    currentDateTime: moment.Moment = moment()
  ): IRescheduleListData {
    const now = currentDateTime.tz(timezone);
    const status = existing?.changes?.status ?? automation.status;
    const stillToRun = Automation.isGoingToRun({ status });
    if (!stillToRun && !existing?.autoCancelled) {
      return {
        automation,
        reschedule: false,
        autoCancelled: false,
        changes: existing?.changes,
      };
    }

    if (!newEvent) {
      return {
        automation,
        reschedule: false,
        autoCancelled: true,
        changes: {
          ...existing?.changes,
          status: AutomationStatus.Cancelled,
        },
      };
    }

    const expectedTriggerDate = Automation.getExpectedTriggerDate(
      automation,
      newEvent,
      timezone
    );
    const triggerDate = Automation.applyGracePeriod(
      expectedTriggerDate,
      timezone,
      now
    );

    if (expectedTriggerDate.isBefore(now, 'day')) {
      return {
        automation,
        reschedule: false,
        newDate: toTimestamp(triggerDate),
        autoCancelled: true,
        changes: {
          ...existing?.changes,
          practiceRef: newEvent.practice.ref,
          status: AutomationStatus.Cancelled,
        },
      };
    }

    return {
      automation,
      reschedule: true,
      autoCancelled: false,
      newDate: toTimestamp(triggerDate),
      changes: {
        ...existing?.changes,
        practiceRef: newEvent.practice.ref,
        status: AutomationStatus.Pending,
      },
    };
  }

  async enableAutomation(
    automation: AutomationEntity,
    event?: IEvent
  ): Promise<void> {
    const listData = await snapshot(this.listData$);
    const existing = listData.find(
      (data) => data.automation.uid === automation.uid
    );
    const listDataChange: Partial<IRescheduleListData> = {
      reschedule: true,
      autoCancelled: false,
      newDate: undefined,
      changes: {
        ...existing?.changes,
        status: AutomationStatus.Pending,
      },
    };
    if (event) {
      const timezone = await snapshot(this.timezone$);
      const triggerDate = Automation.applyGracePeriod(
        Automation.getExpectedTriggerDate(automation, event, timezone),
        timezone
      );
      listDataChange.newDate = toTimestamp(triggerDate);
    }
    this.patchListDataById({ id: automation.uid, listDataChange });
  }

  async disableAutomation(automation: AutomationEntity): Promise<void> {
    const listData = await snapshot(this.listData$);
    const existing = listData.find(
      (data) => data.automation.uid === automation.uid
    );

    const status = !Automation.isGoingToRun(automation)
      ? automation.status
      : AutomationStatus.Cancelled;

    const listDataChange: Partial<IRescheduleListData> = {
      reschedule: false,
      autoCancelled: false,
      newDate: undefined,
      changes: {
        ...existing?.changes,
        status,
      },
    };

    this.patchListDataById({ id: automation.uid, listDataChange });
  }

  async updateAutomation(
    automation: AutomationEntity,
    update: AtLeast<
      IAutomation<AnyAutomation>,
      'status' | 'data' | 'triggerDate'
    >
  ): Promise<void> {
    const listData = await snapshot(this.listData$);
    const existing = listData.find(
      (data) => data.automation.uid === automation.uid
    );
    const listDataChange: Partial<IRescheduleListData> = {
      reschedule: true,
      autoCancelled: false,
      newDate: update.triggerDate,
      automation: {
        ...automation,
        data: update.data,
      },
      changes: {
        ...existing?.changes,
        status: update.status,
      },
    };

    this.patchListDataById({ id: automation.uid, listDataChange });
  }

  // TODO: https://app.clickup.com/t/2ew1wvm
  private _loadAutomationConfigurations(): OperatorFunction<
    [
      ITreatmentStep | WithRef<ITreatmentStep>,
      IEvent | undefined,
      WithRef<IAppointment> | undefined,
    ],
    unknown
  > {
    return (source$) =>
      source$.pipe(
        tap(() => this.patchState({ updating: true })),
        withLatestFrom(
          this._currentScope.currentBrand$.pipe(filterUndefined()),
          this.listData$,
          this.timezone$
        ),
        switchMap(
          ([
            [treatmentStep, newEvent, appointment],
            brand,
            currentData,
            timezone,
          ]) => {
            const triggerAfterDate = appointment
              ? Appointment.getCompletedAt(appointment)
              : undefined;
            return of(treatmentStep).pipe(
              switchMap((step) =>
                isWithRef(step) ? TreatmentStep.automations$(step) : of([])
              ),
              toEntityModels$(),
              switchMap((treatmentStepAutomations) => {
                if (treatmentStepAutomations.length || !newEvent) {
                  return of({
                    automations: treatmentStepAutomations,
                    automationConfigurations: [],
                  });
                }
                return combineLatest([
                  Automation.getAutomations$(brand, newEvent, triggerAfterDate),
                  TreatmentStep.getTreatmentConfigurationAutomations$(
                    treatmentStep,
                    newEvent,
                    brand.ref,
                    triggerAfterDate
                  ),
                ]).pipe(
                  reduce2DArray(),
                  multiMap(toEntityModel),
                  map((automationConfigurations) => ({
                    automations: [],
                    automationConfigurations,
                  }))
                );
              }),
              map(({ automationConfigurations, automations }) => {
                if (isWithRef(treatmentStep)) {
                  return {
                    automationConfigurations,
                    newEvent,
                    listData: this.buildListData(
                      automations,
                      newEvent,
                      currentData,
                      timezone
                    ),
                  };
                }
                return {
                  automationConfigurations,
                  newEvent,
                  listData: [],
                };
              })
            );
          }
        ),
        tapResponse(({ automationConfigurations, newEvent, listData }) => {
          this.setAutomationConfigurations({
            configurations: automationConfigurations,
            newEvent,
          });
          this.patchState({
            updating: false,
            listData,
          });
          // eslint-disable-next-line no-console
        }, console.error)
      );
  }

  private _automationsToListData(
    automations: AutomationEntity[],
    newEvent: IEvent | undefined,
    timezone: Timezone,
    currentData?: IRescheduleListData[]
  ): IRescheduleListData[] {
    return automations.map((automation) => {
      const existingListData = currentData?.find(
        (data) => data.automation.uid === automation.uid
      );
      return this.automationToListData(
        automation,
        newEvent,
        timezone,
        existingListData
      );
    });
  }

  private _upsertListDataChangesById(
    listData: IRescheduleListData[],
    update: IUpdateListData
  ): IRescheduleListData[] {
    return listData.map((data) => {
      return data.automation.uid === update.id
        ? { ...data, ...update.listDataChange }
        : data;
    });
  }
}
