import { HicapsConnectMethod } from '@principle-theorem/hicaps-connect';
import {
  AppointmentStatus,
  BookingWindowType,
  CollectionGroup,
  IAppointment,
  IAppointmentRequest,
  IBookingWindowTiming,
  IBrand,
  IBridgeDevice,
  IConsumableAsset,
  IContact,
  IEquipmentAsset,
  IHicapsConnectTerminal,
  IInstrumentAsset,
  IPractice,
  IPracticeSettings,
  IProduct,
  IRecurringTaskConfiguration,
  IRosterTime,
  IScheduleSummary,
  ISchedulingAlert,
  ISchedulingEventSummary,
  ISmartpayTerminal,
  ISterilisationCycle,
  ISterilisationMachine,
  ISterilisationRecord,
  ITag,
  ITyroTerminal,
  PracticeCollection,
} from '@principle-theorem/principle-core/interfaces';
import {
  CALENDAR_DAYS_OF_WEEK,
  CollectionReference,
  DayOfWeek,
  DocumentReference,
  Firestore,
  IReffable,
  ISO_DATE_FORMAT,
  ITimePeriod,
  ITimestampRange,
  TIME_FORMAT_24HR,
  Timezone,
  WithRef,
  addDoc,
  all$,
  chunkRange,
  collectionGroupQuery,
  initFirestoreModel,
  isSameRef,
  limit,
  mergeDayAndTime,
  orderBy,
  query$,
  reduce2DArray,
  slugify,
  subCollection,
  toMoment,
  toQuery,
  toTimestamp,
  undeletedQuery,
  where,
} from '@principle-theorem/shared';
import { compact, first, sortBy, uniqWith } from 'lodash';
import * as moment from 'moment-timezone';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Brand } from '../brand';
import { IHicapsConnectProcess } from '../integrations/hicaps-connect/hicaps-connect-api-adaptor';
import { Roster } from '../staffer/roster';

export class PracticeSettings {
  static init(): IPracticeSettings {
    return {
      timezone: Timezone.AustraliaSydney,
    };
  }
}

export class Practice {
  static init(overrides?: Partial<IPractice>): IPractice {
    const practice: IPractice = {
      name: '',
      address: '',
      phone: '',
      email: '',
      slug: '',
      // TODO: Implement in practice management
      yearOpened: 0,
      settings: PracticeSettings.init(),
      schedule: Roster.init(),
      ...initFirestoreModel(),
      ...overrides,
    };
    return {
      ...practice,
      slug: slugify(practice.name.toLowerCase()),
    };
  }

  static brandRef(practice: IReffable<IPractice>): DocumentReference<IBrand> {
    return Firestore.getParentDocRef<IBrand>(practice.ref);
  }

  static mediaTagCol(
    practice: IReffable<IPractice>
  ): CollectionReference<ITag> {
    return subCollection<ITag>(practice.ref, PracticeCollection.MediaTags);
  }

  static mediaTags$(
    practice: IReffable<IPractice>
  ): Observable<WithRef<ITag>[]> {
    return all$(undeletedQuery(Practice.mediaTagCol(practice)));
  }

  static instrumentCol(
    practice: WithRef<IPractice>
  ): CollectionReference<IInstrumentAsset> {
    return subCollection<IInstrumentAsset>(
      practice.ref,
      PracticeCollection.Instruments
    );
  }

  static scheduleSummaryCol(
    practice: IReffable<IPractice>
  ): CollectionReference<IScheduleSummary> {
    return subCollection<IScheduleSummary>(
      practice.ref,
      PracticeCollection.ScheduleSummaries
    );
  }

  static instruments$(
    practice: WithRef<IPractice>
  ): Observable<WithRef<IInstrumentAsset>[]> {
    return all$(Practice.instrumentCol(practice));
  }

  static equipmentCol(
    practice: WithRef<IPractice>
  ): CollectionReference<IEquipmentAsset> {
    return subCollection<IEquipmentAsset>(
      practice.ref,
      PracticeCollection.Equipment
    );
  }

  static equipment$(
    practice: WithRef<IPractice>
  ): Observable<WithRef<IEquipmentAsset>[]> {
    return all$(Practice.equipmentCol(practice));
  }

  static consumableCol(
    practice: WithRef<IPractice>
  ): CollectionReference<IConsumableAsset> {
    return subCollection<IConsumableAsset>(
      practice.ref,
      PracticeCollection.Consumables
    );
  }

  static consumables$(
    practice: WithRef<IPractice>
  ): Observable<WithRef<IConsumableAsset>[]> {
    return all$(Practice.consumableCol(practice));
  }

  static schedulingAlertCol(
    practice: WithRef<IPractice>
  ): CollectionReference<ISchedulingAlert> {
    return subCollection<ISchedulingAlert>(
      practice.ref,
      PracticeCollection.SchedulingAlerts
    );
  }

  static schedulingAlerts$(
    practice: WithRef<IPractice>
  ): Observable<WithRef<ISchedulingAlert>[]> {
    return query$(undeletedQuery(Practice.schedulingAlertCol(practice)));
  }

  static activeSchedulingAlerts$(
    practice: WithRef<IPractice>
  ): Observable<WithRef<ISchedulingAlert>[]> {
    return query$(
      Practice.schedulingAlertCol(practice),
      where('deleted', '==', false),
      where(
        'deadline',
        '<=',
        moment
          .tz(practice.settings.timezone)
          .endOf('day')
          .add(2, 'days')
          .toDate()
      )
    ).pipe(
      map((alerts) => {
        return alerts
          .filter((alert) => {
            return toMoment(alert.deadline).isSameOrAfter(
              moment.tz(practice.settings.timezone).startOf('day')
            );
          })
          .sort((alertA, alertB) => {
            if (!alertA.deadline || !alertB.deadline) {
              return 0;
            }
            return alertA.deadline.seconds > alertB.deadline.seconds ? 1 : -1;
          });
      })
    );
  }

  static recurringTaskConfigurationCol(
    practice: WithRef<IPractice>
  ): CollectionReference<IRecurringTaskConfiguration> {
    return subCollection<IRecurringTaskConfiguration>(
      practice.ref,
      PracticeCollection.RecurringTaskConfigurations
    );
  }

  static recurringTaskConfigurations$(
    practice: WithRef<IPractice>
  ): Observable<WithRef<IRecurringTaskConfiguration>[]> {
    return all$(Practice.recurringTaskConfigurationCol(practice));
  }

  static contactCol(
    practice: WithRef<IPractice>
  ): CollectionReference<IContact> {
    return subCollection<IContact>(practice.ref, PracticeCollection.Contacts);
  }

  static contacts$(
    practice: WithRef<IPractice>
  ): Observable<WithRef<IContact>[]> {
    return query$(undeletedQuery(Practice.contactCol(practice)));
  }

  static productCol(
    practice: WithRef<IPractice>
  ): CollectionReference<IProduct> {
    return subCollection<IProduct>(practice.ref, PracticeCollection.Products);
  }

  static products$(
    practice: WithRef<IPractice>
  ): Observable<WithRef<IProduct>[]> {
    return query$(undeletedQuery(Practice.productCol(practice)));
  }

  static sterilisationRecordCol(
    practice: IReffable<IPractice>
  ): CollectionReference<ISterilisationRecord> {
    return subCollection<ISterilisationRecord>(
      practice.ref,
      PracticeCollection.SterilisationRecords
    );
  }

  static sterilisationRecords$(
    practice: IReffable<IPractice>,
    range?: ITimestampRange
  ): Observable<WithRef<ISterilisationRecord>[]> {
    if (!range) {
      return query$(undeletedQuery(Practice.sterilisationRecordCol(practice)));
    }

    return query$(
      undeletedQuery(Practice.sterilisationRecordCol(practice)),
      where('createdAt', '<=', range.to),
      where('createdAt', '>=', range.from),
      orderBy('createdAt', 'desc')
    );
  }

  static sterilisationCycleCol(
    practice: IReffable<IPractice>
  ): CollectionReference<ISterilisationCycle> {
    return subCollection<ISterilisationCycle>(
      practice.ref,
      PracticeCollection.SterilisationCycles
    );
  }

  static sterilisationCycles$(
    practice: IReffable<IPractice>,
    range?: ITimestampRange
  ): Observable<WithRef<ISterilisationCycle>[]> {
    if (!range) {
      return all$(Practice.sterilisationCycleCol(practice));
    }

    return query$(
      toQuery(
        Practice.sterilisationCycleCol(practice),
        where('createdAt', '<=', range.to),
        where('createdAt', '>=', range.from),
        orderBy('createdAt', 'desc')
      )
    );
  }

  static async getLatestCycleRecord(
    practice: IReffable<IPractice>,
    machineRef: DocumentReference<ISterilisationMachine>
  ): Promise<WithRef<ISterilisationCycle> | undefined> {
    const results = await Firestore.getDocs(
      toQuery(
        Practice.sterilisationCycleCol(practice),
        where('machine.ref', '==', machineRef),
        orderBy('createdAt', 'desc'),
        limit(1)
      )
    );
    return first(results);
  }

  static async addSterilisationRecord(
    practice: IReffable<IPractice>,
    record: ISterilisationRecord
  ): Promise<void> {
    await addDoc(Practice.sterilisationRecordCol(practice), record);
  }

  static openTime(practice: WithRef<IPractice>): moment.Moment {
    const earliestDay: IRosterTime = practice.schedule.days.reduce(
      (previous: IRosterTime, current: IRosterTime): IRosterTime => {
        return previous.shift.from < current.shift.from ? previous : current;
      }
    );

    return moment.tz(
      earliestDay.shift.from,
      TIME_FORMAT_24HR,
      practice.settings.timezone
    );
  }

  static dayOfWeekOpenTime(
    practice: WithRef<IPractice>,
    dayOfWeek: DayOfWeek
  ): moment.Moment | undefined {
    const foundDay = practice.schedule.days.find(
      (day) => day.dayOfWeek === dayOfWeek
    );
    if (!foundDay) {
      return;
    }
    return moment.tz(
      foundDay.shift.from,
      TIME_FORMAT_24HR,
      practice.settings.timezone
    );
  }

  static closeTime(practice: WithRef<IPractice>): moment.Moment {
    const latestDay: IRosterTime = practice.schedule.days.reduce(
      (previous: IRosterTime, current: IRosterTime): IRosterTime => {
        return previous.shift.to > current.shift.to ? previous : current;
      }
    );

    return moment.tz(
      latestDay.shift.to,
      TIME_FORMAT_24HR,
      practice.settings.timezone
    );
  }

  static dayOfWeekCloseTime(
    practice: WithRef<IPractice>,
    dayOfWeek: DayOfWeek
  ): moment.Moment | undefined {
    const foundDay = practice.schedule.days.find(
      (day) => day.dayOfWeek === dayOfWeek
    );
    if (!foundDay) {
      return;
    }

    return moment.tz(
      foundDay.shift.to,
      TIME_FORMAT_24HR,
      practice.settings.timezone
    );
  }

  static brandDoc(practice: IReffable<IPractice>): DocumentReference<IBrand> {
    return Firestore.getParentDocRef(practice.ref.path);
  }

  static waitlistedAppointments$(
    practice: WithRef<IPractice>,
    gapDate: moment.Moment
  ): Observable<WithRef<IAppointment>[]> {
    return combineLatest([
      all$(
        collectionGroupQuery<IAppointment>(
          CollectionGroup.Appointments,
          where('event.practice.ref', '==', practice.ref),
          where('deleted', '!=', true),
          where(
            'waitListItem.availableDates',
            'array-contains',
            gapDate.format(ISO_DATE_FORMAT)
          )
        )
      ),
      all$(
        collectionGroupQuery<IAppointment>(
          CollectionGroup.Appointments,
          where('practice.ref', '==', practice.ref),
          where('deleted', '!=', true),
          where('status', '==', AppointmentStatus.Unscheduled),
          where(
            'waitListItem.availableDates',
            'array-contains',
            gapDate.format(ISO_DATE_FORMAT)
          )
        )
      ),
    ]).pipe(
      reduce2DArray(),
      map((appointments) => uniqWith(appointments, isSameRef))
    );
  }

  static unscheduledAppointments$(
    practice: WithRef<IPractice>
  ): Observable<WithRef<IAppointment>[]> {
    return all$(
      collectionGroupQuery<IAppointment>(
        CollectionGroup.Appointments,
        where('practice.ref', '==', practice.ref),
        where('status', '==', AppointmentStatus.Unscheduled)
      )
    );
  }

  static bridgeDeviceCol(
    practice: IReffable<IPractice>
  ): CollectionReference<IBridgeDevice> {
    return subCollection<IBridgeDevice>(
      practice.ref,
      PracticeCollection.BridgeDevices
    );
  }

  static bridgeDevices$(
    practice: IReffable<IPractice>
  ): Observable<WithRef<IBridgeDevice>[]> {
    return query$(
      Practice.bridgeDeviceCol(practice),
      where('deleted', '!=', true)
    ).pipe(map((device) => sortBy(device, ['name'])));
  }

  static tyroTerminalCol(
    practice: WithRef<IPractice>
  ): CollectionReference<ITyroTerminal> {
    return subCollection<ITyroTerminal>(
      practice.ref,
      PracticeCollection.TyroTerminals
    );
  }

  static tyroTerminals$(
    practice: WithRef<IPractice>
  ): Observable<WithRef<ITyroTerminal>[]> {
    return all$(undeletedQuery(Practice.tyroTerminalCol(practice)));
  }

  static isTyroEnabled(practice?: IPractice): boolean {
    return practice?.tyroSettings?.isEnabled ?? false;
  }

  static smartpayTerminalCol(
    practice: WithRef<IPractice>
  ): CollectionReference<ISmartpayTerminal> {
    return subCollection<ISmartpayTerminal>(
      practice.ref,
      PracticeCollection.SmartpayTerminals
    );
  }

  static smartpayTerminals$(
    practice: WithRef<IPractice>
  ): Observable<WithRef<ISmartpayTerminal>[]> {
    return all$(undeletedQuery(Practice.smartpayTerminalCol(practice)));
  }

  static isSmartpayEnabled(practice?: IPractice): boolean {
    return practice?.smartpaySettings?.isEnabled ?? false;
  }

  static isHicapsConnectEnabled(practice?: IPractice): boolean {
    return practice?.hicapsConnectSettings?.isEnabled ?? false;
  }

  static hicapsConnectTerminalCol(
    practice: IReffable<IPractice>
  ): CollectionReference<IHicapsConnectTerminal> {
    return subCollection<IHicapsConnectTerminal>(
      practice.ref,
      PracticeCollection.HicapsConnectTerminals
    );
  }

  static hicapsConnectTerminals$(
    practice: IReffable<IPractice>
  ): Observable<WithRef<IHicapsConnectTerminal>[]> {
    return all$(undeletedQuery(Practice.hicapsConnectTerminalCol(practice)));
  }

  static hicapsConnectProcessCol<T extends HicapsConnectMethod>(
    practice: IReffable<IPractice>
  ): CollectionReference<IHicapsConnectProcess<T>> {
    return subCollection<IHicapsConnectProcess<T>>(
      practice.ref,
      PracticeCollection.HicapsConnectProcesses
    );
  }

  static getAppointmentRequests$(
    practice: IReffable<IPractice>
  ): Observable<WithRef<IAppointmentRequest>[]> {
    const brandRef = Practice.brandDoc(practice);
    return Brand.getAppointmentRequests$({ ref: brandRef }, practice.ref);
  }

  static openingHours(
    practice: WithRef<IPractice>,
    range: ITimePeriod
  ): ITimePeriod[] {
    return compact(
      chunkRange(range, 1, 'day').map((day) => {
        const fromTime = Practice.dayOfWeekOpenTime(
          practice,
          CALENDAR_DAYS_OF_WEEK[day.from.weekday()]
        );
        const toTime = Practice.dayOfWeekCloseTime(
          practice,
          CALENDAR_DAYS_OF_WEEK[day.from.weekday()]
        );
        if (!fromTime || !toTime) {
          return;
        }
        return {
          from: mergeDayAndTime(day.from, fromTime, practice.settings.timezone),
          to: mergeDayAndTime(day.from, toTime, practice.settings.timezone),
        };
      })
    );
  }

  static getAppointmentsWithUpcomingFollowUps$(
    practice: IReffable<IPractice>,
    queryLimit = 100
  ): Observable<WithRef<IAppointment>[]> {
    return all$(
      collectionGroupQuery<IAppointment>(
        CollectionGroup.Appointments,
        ...compact([
          where('practice.ref', '==', practice.ref),
          orderBy('activeFollowUp.followUpDate', 'asc'),
          where('activeFollowUp.followUpDate', '>', toTimestamp()),
          queryLimit ? limit(queryLimit) : undefined,
        ])
      )
    );
  }

  static getAppointmentsWithExistingFollowUps$(
    practice: IReffable<IPractice>,
    queryLimit = 100
  ): Observable<WithRef<IAppointment>[]> {
    return all$(
      collectionGroupQuery<IAppointment>(
        CollectionGroup.Appointments,
        ...compact([
          where('practice.ref', '==', practice.ref),
          orderBy('activeFollowUp.followUpDate', 'asc'),
          where('activeFollowUp.followUpDate', '<=', toTimestamp()),
          queryLimit ? limit(queryLimit) : undefined,
        ])
      )
    );
  }

  static schedulingEventSummaryCol(
    practice: IReffable<IPractice>
  ): CollectionReference<ISchedulingEventSummary> {
    return subCollection<ISchedulingEventSummary>(
      practice.ref,
      PracticeCollection.SchedulingEventSummaries
    );
  }

  static getBookingWindowTiming(
    practice: WithRef<IPractice>,
    type: BookingWindowType
  ): IBookingWindowTiming | undefined {
    const bookingWindow = practice.settings.patientPortal?.bookingWindow;
    return bookingWindow ? bookingWindow[type] : undefined;
  }
}
