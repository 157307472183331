import { IProvider, WithRef } from '@principle-theorem/shared';
import {
  IBaseCommand,
  IBridgeIntegration,
  IDeviceCommand,
} from '../../send-command-to-device';
import { IPatient } from '@principle-theorem/principle-core/interfaces';

export const CLINIVIEW_INTEGRATION: IBridgeIntegration = {
  label: 'Cliniview',
  description: 'One-way integration for opening patient files within Cliniview',
};

export enum CliniviewToDeviceCommand {
  OpenPatient = 'cliniview.openPatient',
}

export type CliniviewOpenPatientCommand = IBaseCommand<
  CliniviewToDeviceCommand.OpenPatient,
  { patient: WithRef<IPatient> }
>;

export class CliniviewOpenPatient
  implements IProvider<IDeviceCommand, Promise<object>>
{
  canProvide(data: IDeviceCommand): boolean {
    return data.command.type === String(CliniviewToDeviceCommand.OpenPatient);
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async execute(data: IDeviceCommand): Promise<object> {
    return data.command;
  }
}
