import { ActionReducerMap, type MetaReducer } from '@ngrx/store';
import { routerReducer, type RouterReducerState } from '@ngrx/router-store';
import {
  type ICurrentScopeState,
  currentScopeReducer,
} from './current-scope.reducer';
import { type IMinimalRouterStateSnapshot } from '../basic-router-state-serialiser';

export interface IPrincipleState {
  router: RouterReducerState<IMinimalRouterStateSnapshot>;
  currentScope: ICurrentScopeState;
}

export const principleCoreReducers: ActionReducerMap<IPrincipleState> = {
  router: routerReducer,
  currentScope: currentScopeReducer,
};

export const principleCoreMetaReducers: MetaReducer<IPrincipleState>[] = [];
