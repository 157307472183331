<form class="flex max-w-full grow flex-col gap-4" [formGroup]="form">
  <pr-content-editor
    class="flex min-h-[500px] grow rounded border border-solid border-slate-200"
    formControlName="content"
    [menuEnabled]="true"
    [extensions]="extensions"
    [required]="true"
  />

  <mat-slide-toggle
    class="px-2"
    labelPosition="before"
    formControlName="editableOnIssue"
  >
    Allow changes when issuing
  </mat-slide-toggle>
</form>
