import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CurrentPatientScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  ConfirmDialogComponent,
  DialogPresets,
  TrackByFunctions,
  TypedFormControl,
  confirmationDialogData,
  type IConfirmationDialogInput,
} from '@principle-theorem/ng-shared';
import { Patient, SubmittedForm } from '@principle-theorem/principle-core';
import {
  FormStatus,
  type IContactNumber,
  type IPatient,
  type ISubmittedFormHistory,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  HISTORY_DATE_TIME_FORMAT,
  filterUndefined,
  isSameRef,
  patchDoc,
  snapshot,
  type WithRef,
  MONTH_YEAR_FORMAT,
} from '@principle-theorem/shared';
import { ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { FormDataProviderService } from '../form-dialog/form-data-provider.service';
import { PatientDetailsHistoryListStore } from './patient-details-history-list.store';

@Component({
  selector: 'pr-patient-details-history-list',
  templateUrl: './patient-details-history-list.component.html',
  styleUrls: ['./patient-details-history-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientDetailsHistoryListComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  readonly dateFormat = HISTORY_DATE_TIME_FORMAT;
  readonly dateOfBirthFormat = DAY_MONTH_YEAR_FORMAT;
  readonly expiryFormat = MONTH_YEAR_FORMAT;
  trackByIndex = TrackByFunctions.index<IContactNumber>();
  trackByForm = TrackByFunctions.ref<WithRef<ISubmittedFormHistory>>();
  patientDetailsFormData$ = new ReplaySubject<Partial<IPatient>>(1);
  formSelectCtrl = new TypedFormControl<
    WithRef<ISubmittedFormHistory<IPatient>>
  >(undefined);

  constructor(
    public store: PatientDetailsHistoryListStore,
    private _patientScope: CurrentPatientScope,
    private _formDataProvider: FormDataProviderService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService
  ) {
    const patient$ = this._patientScope.doc$.pipe(
      withLatestFrom(this._formDataProvider.patient$),
      map(([scopedPatient, providerPatient]) =>
        scopedPatient ? scopedPatient : providerPatient
      )
    );

    this.store.loadPatient(patient$);

    this.store.submittedForm$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((form) => {
        if (form) {
          this.formSelectCtrl.patchValue(
            form as WithRef<ISubmittedFormHistory<IPatient>>,
            { emitEvent: false }
          );
          this.patientDetailsFormData$.next(
            form.form.data as Partial<IPatient>
          );
          this._disabled(form, form);
        }
      });

    this.formSelectCtrl.valueChanges
      .pipe(
        withLatestFrom(this.store.submittedForm$.pipe(filterUndefined())),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([selected, submittedForm]) =>
        this._disabled(selected, submittedForm)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  compareFn(
    a: WithRef<ISubmittedFormHistory>,
    b: WithRef<ISubmittedFormHistory>
  ): boolean {
    return a && b ? isSameRef(a, b) : false;
  }

  async confirmPatientDetails(): Promise<void> {
    const patient = await snapshot(this.store.patient$.pipe(filterUndefined()));
    const updatedPatientDetails = await snapshot(this.patientDetailsFormData$);
    const submittedForm = await snapshot(
      this.store.submittedForm$.pipe(filterUndefined())
    );

    const data = confirmationDialogData({
      title: 'Update Patient Details',
      prompt: `Confirming will update ${patient.name} details in the system.`,
      submitLabel: 'Confirm',
      submitColor: 'primary',
    });

    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }

    const updatedPatient = { ...patient, ...updatedPatientDetails };
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    await Patient.updatePatientDetails(updatedPatient, staffer.ref);
    await patchDoc(submittedForm.ref, {
      ...SubmittedForm.updateStatus(submittedForm, FormStatus.Confirmed),
    });
    this._snackBar.open(`${patient.name} updated.`);
  }

  private _disabled(
    selected: WithRef<ISubmittedFormHistory>,
    submittedForm: WithRef<ISubmittedFormHistory>
  ): void {
    const isSubmittedForm = isSameRef(selected, submittedForm);
    const isConfirmed = submittedForm.status === FormStatus.Confirmed;

    this.store.loadDisabled(
      (isConfirmed && isSubmittedForm) || !isSubmittedForm
    );
  }
}
