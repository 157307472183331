import {
  IntegrationStorage,
  IntegrationType,
  type IIntegration,
} from '@principle-theorem/integrations';
import { type CollectionReference } from '@principle-theorem/shared';

export interface IStripeIntegrationData {
  connectedAccountId: string;
}

export class StripeIntegrationStorage extends IntegrationStorage<IStripeIntegrationData> {
  constructor() {
    super(IntegrationType.Stripe);
  }
}

export async function saveIntegration(
  integrationsRef: CollectionReference<IIntegration<IStripeIntegrationData>>,
  connectedAccountId: string
): Promise<void> {
  const store = new StripeIntegrationStorage();
  await store.add(integrationsRef, { connectedAccountId });
}
