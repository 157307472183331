import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import {
  CurrentPatientScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { Invoice, resolveProvider$ } from '@principle-theorem/principle-core';
import {
  isDepositLineItem,
  isTreatmentLineItem,
  type IAccountDetails,
  type IBalance,
  type IBrand,
  type ICustomLineItem,
  type IDepositLineItem,
  type IInvoice,
  type IInvoiceLineItemGroup,
  type IPatient,
  type IPractice,
  type IProviderData,
  type ITreatmentLineItem,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  NotImplementedError,
  filterUndefined,
  saveDoc,
  snapshot,
  type DocumentReference,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject, Subject, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { invoiceToBalance } from '../balance-display/balance';
import { LineItemsDisplay } from '../invoice-display/line-items-display';
import { AccountCreditReservedForSelectorDialogService } from './account-credit-reserved-for-selector-dialog/account-credit-reserved-for-selector-dialog.service';
import {
  LineItemsSearch,
  isTreatmentWithPlan,
  type ITreatmentWithPlan,
} from './line-items-search';
import { TaxRate } from '@principle-theorem/accounting';

@Component({
  selector: 'pr-invoice-edit',
  templateUrl: './invoice-edit.component.html',
  styleUrls: ['./invoice-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceEditComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject<void>();
  invoice$ = new ReplaySubject<WithRef<IInvoice>>(1);
  trackByGroup =
    TrackByFunctions.uniqueId<IInvoiceLineItemGroup<ICustomLineItem>>();
  trackByLineItem = TrackByFunctions.uniqueId<ICustomLineItem>();
  brand$: Observable<WithRef<IBrand>>;
  practiceRef$: Observable<DocumentReference<IPractice>>;
  balance$: Observable<IBalance>;
  taxRate$: Observable<TaxRate>;
  lineItemsSearch: LineItemsSearch;
  lineItems: LineItemsDisplay;

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this.invoice$.next(invoice);
    }
  }

  constructor(
    patientScope: CurrentPatientScope,
    private _organisation: OrganisationService,
    private _depositReservedFor: AccountCreditReservedForSelectorDialogService
  ) {
    const patient$: Observable<WithRef<IPatient>> =
      patientScope.model$.pipe(filterUndefined());
    this.brand$ = this._organisation.brand$.pipe(filterUndefined());
    this.taxRate$ = this._organisation.taxRate$.pipe(filterUndefined());
    this.lineItemsSearch = new LineItemsSearch(patient$, this.brand$);
    this.lineItems = new LineItemsDisplay(
      this.invoice$.pipe(map((invoice) => invoice.items))
    );
    this.balance$ = this.invoice$.pipe(invoiceToBalance());
    this.practiceRef$ = this.invoice$.pipe(
      map((invoice) => invoice.practice.ref)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
  }

  async addLineItem(
    lineItem: ITreatmentWithPlan | ICustomLineItem | IDepositLineItem
  ): Promise<void> {
    if (isTreatmentWithPlan(lineItem)) {
      throw new NotImplementedError();
    }
    lineItem.uid = uuid();
    const taxRate = await snapshot(this.taxRate$);

    if (isDepositLineItem(lineItem)) {
      await this.openDepositAssignment(lineItem, taxRate);
      return;
    }

    await this.upsertLineItem(lineItem, taxRate);
  }

  async openDepositAssignment(
    lineItem: IDepositLineItem,
    taxRate: TaxRate
  ): Promise<void> {
    const practiceRef = await snapshot(this.practiceRef$);
    const practice = await Firestore.getDoc(practiceRef);
    const reservedFor = await this._depositReservedFor.open({
      practice,
      lineItem,
    });
    if (!reservedFor) {
      return;
    }
    const depositLineItem: IDepositLineItem = {
      ...lineItem,
      ...reservedFor,
    };
    return this.upsertLineItem(depositLineItem, taxRate);
  }

  isTreatmentLineItem(item: ICustomLineItem): item is ITreatmentLineItem {
    return isTreatmentLineItem(item);
  }

  async updateFrom(details: IAccountDetails): Promise<void> {
    const invoice: WithRef<IInvoice> = await snapshot(this.invoice$);
    invoice.from = details;
    await saveDoc(invoice);
  }

  async upsertLineItem(
    lineItem: ICustomLineItem,
    taxRate: TaxRate
  ): Promise<void> {
    const invoice: WithRef<IInvoice> = await snapshot(this.invoice$);
    Invoice.upsertLineItem(invoice, lineItem, taxRate);
    await saveDoc(invoice);
    if (isTreatmentLineItem(lineItem)) {
      await Invoice.updateTreatmentLink(invoice, lineItem);
    }
  }

  async upsertSubLineItem(
    group: IInvoiceLineItemGroup<ICustomLineItem>,
    lineItem: ICustomLineItem
  ): Promise<void> {
    const invoice = await snapshot(this.invoice$);
    const taxRate = await snapshot(this.taxRate$);
    Invoice.upsertSubLineItem(group, lineItem, taxRate);
    Invoice.upsertLineItem(invoice, group, taxRate);
    await saveDoc(invoice);
  }

  resolveProvider$(
    group: IInvoiceLineItemGroup<ICustomLineItem>
  ): Observable<(INamedDocument & Partial<IProviderData>) | undefined> {
    return this.practiceRef$.pipe(
      switchMap((practiceRef) => resolveProvider$(group, practiceRef))
    );
  }

  isLineItemLocked$(lineItem: ICustomLineItem): Observable<boolean> {
    return this.invoice$.pipe(
      switchMap((invoice) => Invoice.isLineItemLocked$(invoice, lineItem))
    );
  }
}
