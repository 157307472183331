import { ICustomMappings } from '@principle-theorem/principle-core/interfaces';
import { BaseCustomMappingsHandler } from '../mappings/base-custom-mappings-handler';
import { OasisStafferMappingHandler } from './destination/mappings/staff';
import { OasisPracticeMappingHandler } from './destination/mappings/practices';
import { OasisFeeScheduleMappingHandler } from './destination/mappings/fee-schedules';
import { OasisPatientNoteCategoryToNoteMappingHandler } from './destination/mappings/patient-note-categories-to-notes';
import { OasisItemCodeMappingHandler } from './destination/mappings/item-codes';
import { OasisItemCodeToTreatmentMappingHandler } from './destination/mappings/item-code-to-treatment';
import { OasisItemCodeToConditionMappingHandler } from './destination/mappings/item-code-to-condition';
import { OasisAppointmentBookToPractitionerMappingHandler } from './destination/mappings/appointment-book-to-practitioner';

export const OASIS_MAPPINGS: ICustomMappings = {
  metadata: {
    label: 'OASiS Custom Mappings',
    description: '',
  },
};

export class OasisCustomMappingsHandler extends BaseCustomMappingsHandler {
  migrationType = 'OASiS';
  migrationVersion = 'v1';
  customMapping = OASIS_MAPPINGS;

  mappingHandlers = [
    new OasisFeeScheduleMappingHandler(),
    new OasisItemCodeMappingHandler(),
    new OasisItemCodeToConditionMappingHandler(),
    new OasisItemCodeToTreatmentMappingHandler(),
    new OasisPatientNoteCategoryToNoteMappingHandler(),
    new OasisPracticeMappingHandler(),
    new OasisStafferMappingHandler(),
    new OasisAppointmentBookToPractitionerMappingHandler(),
  ];
}
