import { NgModule } from '@angular/core';
import { ComponentsModule } from './components/components.module';

@NgModule({
  imports: [ComponentsModule],
  declarations: [],
  providers: [],
  exports: [ComponentsModule],
})
export class NgMediaManagerModule {}
