import {
  IGapDuration,
  IGapTimeRange,
  IScheduleSummaryEventable,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timezone,
  toMomentTz,
  WithRef,
  isSameRef,
  ITimePeriod,
} from '@principle-theorem/shared';
import { Event } from '../event/event';
import * as moment from 'moment-timezone';

export class GapFilterBuilder {
  constructor(private _gaps: IScheduleSummaryEventable[]) {}

  build(): IScheduleSummaryEventable[] {
    return this._gaps;
  }

  byTimeRange(timezone: Timezone, timeRange?: IGapTimeRange): this {
    if (!timeRange?.from && !timeRange?.to) {
      return this;
    }

    this._gaps = this._gaps.filter(({ event }) => {
      const eventDate = toMomentTz(event.from, timezone);

      const timeRangeFrom = timeRange.from
        ? eventDate.clone().startOf('day').add(moment.duration(timeRange.from))
        : eventDate.clone().startOf('day');

      const timeRangeTo = timeRange.to
        ? eventDate.clone().startOf('day').add(moment.duration(timeRange.to))
        : eventDate.clone().endOf('day');

      const eventFrom = toMomentTz(event.from, timezone);
      const eventTo = toMomentTz(event.to, timezone);

      return (
        timeRangeFrom.isSameOrBefore(eventTo) &&
        timeRangeTo.isSameOrAfter(eventFrom)
      );
    });
    return this;
  }

  byPractitioners(practitioners?: WithRef<IStaffer>[]): this {
    if (!practitioners?.length) {
      return this;
    }
    this._gaps = this._gaps.filter((gap) =>
      practitioners.some((practitioner) =>
        isSameRef(Event.staff(gap.event)[0], practitioner)
      )
    );
    return this;
  }

  byDuration(timezone: Timezone, duration?: IGapDuration): this {
    if (!duration?.hours && !duration?.minutes) {
      return this;
    }
    this._gaps = this._gaps.filter((gap) => {
      const gapEventFrom = toMomentTz(gap.event.from, timezone);
      const gapEventTo = toMomentTz(gap.event.to, timezone);

      const gapDuration = moment
        .duration(gapEventTo.diff(gapEventFrom))
        .asMinutes();

      const searchDuration = moment
        .duration({
          hours: duration.hours ?? 0,
          minutes: duration.minutes ?? 0,
        })
        .asMinutes();

      return gapDuration >= searchDuration;
    });
    return this;
  }

  byDateRange(timezone: Timezone, dateRange?: ITimePeriod): this {
    if (!dateRange) {
      return this;
    }
    this._gaps = this._gaps.filter(
      (gap) =>
        toMomentTz(gap.event.from, timezone).isSameOrAfter(dateRange.from) &&
        toMomentTz(gap.event.to, timezone).isSameOrBefore(dateRange.to)
    );

    return this;
  }
}
