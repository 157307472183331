import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, type UrlTree } from '@angular/router';
import { AuthGuard } from './auth-guard';
import { AuthService } from './auth.service';
import { WorkspaceService } from './workspace.service';
import { snapshot } from '@principle-theorem/shared';

@Injectable({
  providedIn: 'root',
})
export class HasWorkspaceGuard extends AuthGuard {
  constructor(
    private _router: Router,
    auth: AuthService,
    private _workspace: WorkspaceService
  ) {
    super(auth);
  }

  async canActivate(_next: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const storedWorkspace: string | undefined = await snapshot(
      this._workspace.workspace$
    );

    if (storedWorkspace) {
      return true;
    }

    return this._router.createUrlTree(['/workspaces']);
  }
}
