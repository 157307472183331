import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  type ChartableSurface,
  type IConditionConfiguration,
  type IHasConditions,
} from '@principle-theorem/principle-core/interfaces';
import {
  doc$,
  isSameRef,
  safeCombineLatest,
  saveDoc,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { ReplaySubject, type Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import {
  EditConditionComponent,
  type IEditConditionData,
} from './edit-condition/edit-condition.component';

@Component({
    selector: 'pr-condition-selector',
    templateUrl: './condition-selector.component.html',
    styleUrls: ['./condition-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConditionSelectorComponent {
  trackByCondition = TrackByFunctions.ref<WithRef<IConditionConfiguration>>();
  trackBySurface = TrackByFunctions.variable<ChartableSurface>();
  treatmentConfig$: ReplaySubject<WithRef<IHasConditions>> = new ReplaySubject(
    1
  );
  conditions$: Observable<WithRef<IConditionConfiguration>[]>;
  conditionsResolvedByTreatment$: Observable<
    WithRef<IConditionConfiguration>[]
  >;
  conditionTypeSelector: TypedFormControl<string> =
    new TypedFormControl<string>('');
  searchFilter: InputSearchFilter<WithRef<IConditionConfiguration>>;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _globalStore: GlobalStoreService
  ) {
    this.conditions$ = this._globalStore.conditionConfigurations$;

    this.searchFilter = new InputSearchFilter<WithRef<IConditionConfiguration>>(
      this.conditions$.pipe(map((conditions) => sortBy(conditions, ['name']))),
      this.conditionTypeSelector.valueChanges.pipe(startWith('')),
      ['name']
    );

    this.conditionsResolvedByTreatment$ = this.treatmentConfig$.pipe(
      switchMap((treatmentConfig) =>
        safeCombineLatest(treatmentConfig.conditions.map((ref) => doc$(ref)))
      )
    );
  }

  @Input()
  set treatmentConfig(treatmentConfig: WithRef<IHasConditions>) {
    if (treatmentConfig) {
      this.treatmentConfig$.next(treatmentConfig);
    }
  }

  clearInput(): void {
    this.conditionTypeSelector.setValue('');
  }

  async add(
    condition: WithRef<IConditionConfiguration>,
    treatmentConfig: WithRef<IHasConditions>
  ): Promise<void> {
    this.clearInput();
    const hasCondition: boolean = treatmentConfig.conditions.some(
      (compareConditionRef: DocumentReference) => {
        return isSameRef(condition.ref, compareConditionRef);
      }
    );
    if (hasCondition) {
      return;
    }
    treatmentConfig.conditions.push(condition.ref);
    await this.save(treatmentConfig);
  }

  async editCondition(
    condition: WithRef<IConditionConfiguration>
  ): Promise<void> {
    const config: MatDialogConfig<IEditConditionData> = DialogPresets.medium({
      data: {
        condition,
      },
    });
    const updatedCondition = await this._dialog
      .open<
        EditConditionComponent,
        IEditConditionData,
        WithRef<IConditionConfiguration> | undefined
      >(EditConditionComponent, config)
      .afterClosed()
      .toPromise();

    if (!updatedCondition) {
      return;
    }
    await saveDoc(updatedCondition);
    this._snackBar.open('Treatment configuration updated');
  }

  async save(treatmentConfig: WithRef<IHasConditions>): Promise<void> {
    await saveDoc(treatmentConfig);
    this._snackBar.open('Associated conditions updated');
  }

  async deleteCondition(
    condition: WithRef<IConditionConfiguration>,
    treatmentConfig: WithRef<IHasConditions>
  ): Promise<void> {
    const index: number = treatmentConfig.conditions.findIndex(
      (compareConditionRef) => condition.ref.path === compareConditionRef.path
    );
    if (index < 0) {
      return;
    }
    treatmentConfig.conditions.splice(index, 1);
    await this.save(treatmentConfig);
  }

  displayFn(condition?: IConditionConfiguration): string {
    return condition ? `${condition.name}` : '';
  }
}
