import { isString } from 'lodash';

export function convertUnicodeLineBreaks(item: string): string {
  return item.replace(/\\x0A/gi, '\n').replace(/\\x0D/gi, '\r');
}

export function cleanObjectStrings<T extends object>(item: T): T {
  return Object.entries(item).reduce((acc, [key, value]) => {
    if (isString(value)) {
      return {
        ...acc,
        [key]: convertUnicodeLineBreaks(value.trim()),
      };
    }
    return {
      ...acc,
      [key]: value as unknown,
    };
  }, {} as T);
}
