import { sortBy } from 'lodash';
import { WithRef } from './firebase/interfaces';
import { isSameRef } from './firebase/doc-ref';
import { DocumentReference } from './firebase/firestore/adaptor';

export function nameSorter<T extends { name: string }>(): (
  item: T
) => string | number {
  return (item) => item.name.toLowerCase();
}

export function sortByRefArray<Model extends object>(
  items: WithRef<Model>[],
  refs: DocumentReference<Model>[] = []
): WithRef<Model>[] {
  return sortBy(items, (item) => {
    const orderIndex = refs.findIndex((ref) => isSameRef(item.ref, ref));
    return orderIndex === -1 ? items.length : orderIndex;
  });
}
