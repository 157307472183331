import { Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Validators } from '@angular/forms';
import {
  initVersionedSchema,
  type VersionedSchema,
} from '@principle-theorem/editor';
import {
  IMatSelectOption,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { ConditionLogic } from '@principle-theorem/principle-core';
import {
  AUTOMATED_NOTIFICATION_TYPES,
  AutomatedNotificationType,
  ConditionLogicConfigurationCollection,
  TemplateScope,
  type IAutomatedNotificationCustomTemplate,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { type INamedDocument } from '@principle-theorem/shared';
import { Duration, duration, type Moment } from 'moment-timezone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TimingFormGroup } from '../timing.formgroup';
import { type IAutomatedNotificationFormData } from './notification-dialog.component';

export class AutomatedNotificationFormHelpers {
  static trackByPractice = TrackByFunctions.ref<INamedDocument<IPractice>>();
  static timeInterval: Duration = duration(15, 'minutes');
  static types: IMatSelectOption<AutomatedNotificationType>[] = [
    { label: 'Send Email', value: AutomatedNotificationType.EMAIL },
    { label: 'Send SMS', value: AutomatedNotificationType.SMS },
  ];

  static getTypeLabel(
    typeValue: AutomatedNotificationType
  ): string | undefined {
    const found = this.types.find((type) => type.value === typeValue);
    return found?.label;
  }
}

type CustomTemplateFormData = Omit<
  IAutomatedNotificationCustomTemplate,
  'renderedTemplate'
>;

export class CustomTemplateForm extends TypedFormGroup<CustomTemplateFormData> {
  constructor() {
    super({
      scope: new TypedFormControl<TemplateScope>(),
      content: new TypedFormControl<VersionedSchema>(
        initVersionedSchema(),
        Validators.required
      ),
    });
  }
}

export class NotificationDialogForm extends TypedFormGroup<
  Omit<
    IAutomatedNotificationFormData,
    | 'renderedTemplate'
    | 'deleted'
    | 'isActive'
    | 'treatmentRefs'
    | 'templateRef'
  >
> {
  showSubjectField: Signal<boolean | undefined>;
  isEmailNotification$: Observable<boolean>;

  constructor() {
    super({
      name: new TypedFormControl<string>('', Validators.required),
      subject: new TypedFormControl<string>('', Validators.required),
      type: new TypedFormControl<AutomatedNotificationType>(
        AUTOMATED_NOTIFICATION_TYPES[0]
      ),
      timing: new TimingFormGroup(),
      customTemplate: new CustomTemplateForm(),
      triggerDate: new TypedFormControl<Moment | undefined>({
        value: undefined,
        disabled: true,
      }),
      triggerTime: new TypedFormControl<string | undefined>({
        value: undefined,
        disabled: true,
      }),
      requiredConditions:
        new TypedFormControl<ConditionLogicConfigurationCollection>(
          ConditionLogic.collection([
            ConditionLogic.and([ConditionLogic.always()]),
          ])
        ),
    });

    this.isEmailNotification$ = this.controls.type.valueChanges.pipe(
      map((type) => type === AutomatedNotificationType.EMAIL)
    );
    this.showSubjectField = toSignal(this.isEmailNotification$);
  }

  enableTriggerControls(): void {
    this.controls.triggerDate.enable();
    this.controls.triggerTime.enable();
  }

  enableCustomTemplateControls(
    enabled: boolean,
    customTemplate?: CustomTemplateFormData
  ): void {
    this.enableSubjectControl(enabled);

    if (enabled) {
      this.controls.customTemplate.enable();
      this.controls.customTemplate.patchValue({
        content: customTemplate?.content ?? initVersionedSchema(),
        scope: customTemplate?.scope ?? TemplateScope.Appointment,
      });
      return;
    }
    this.controls.customTemplate.disable();
    this.controls.customTemplate.reset(undefined);
  }

  enableSubjectControl(enabled: boolean): void {
    if (enabled) {
      this.controls.subject.setValidators([Validators.required]);
      this.controls.subject.enable();
      this.controls.subject.updateValueAndValidity();
      return;
    }
    this.controls.subject.setValidators([]);
    this.controls.subject.disable();
    this.controls.subject.updateValueAndValidity();
  }
}
