import {
  TypeGuard,
  type INamedDocument,
  isINamedDocument,
  isObject,
} from '@principle-theorem/shared';
import { isNumber } from 'lodash';
import { type IAccountCredit } from '../account-credit/account-credit';

export interface IUsedAccountCredit extends INamedDocument<IAccountCredit> {
  amount: number;
  refunded: number;
  totalRefunded: number;
}

export interface IAccountCreditExtendedData {
  accountCreditsUsed: IUsedAccountCredit[];
}

export function isAccountCreditExtendedData(
  data: unknown
): data is IAccountCreditExtendedData {
  return TypeGuard.interface<IAccountCreditExtendedData>({
    accountCreditsUsed: (usedCredit): usedCredit is IUsedAccountCredit[] =>
      TypeGuard.arrayOf(isUsedAccountCredit)(usedCredit),
  })(data);
}

export function isUsedAccountCredit(data: unknown): data is IUsedAccountCredit {
  return (
    isObject(data) &&
    isNumber(data.amount) &&
    isNumber(data.refunded) &&
    isNumber(data.totalRefunded) &&
    isINamedDocument(data)
  );
}
