import { Pipe, type PipeTransform } from '@angular/core';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { StateBasedNavigationService } from '../navigation/state-based-navigation.service';

@Pipe({
  name: 'patientUrl$',
})
export class PatientUrlPipe implements PipeTransform {
  constructor(private _stateNav: StateBasedNavigationService) {}

  transform(patient: WithRef<IPatient>): Observable<string[]> {
    return this._stateNav.link.brand$(['patients', patient.ref.id]);
  }
}
