import {
  IOrganisation,
  IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference } from '@principle-theorem/shared';
import {
  isPodiumSMSProvider,
  isTNZSMSProvider,
} from '../organisation/organisation';
import { resolvePracticeMobileNumber } from './twilio-integration';

export class SMSIntegration {
  static async resolvePracticeNumber(
    practiceRef: DocumentReference<IPractice>,
    organisation: IOrganisation
  ): Promise<string | undefined> {
    if (isPodiumSMSProvider(organisation)) {
      return 'Podium';
    }

    if (isTNZSMSProvider(organisation)) {
      return 'TNZ';
    }

    return resolvePracticeMobileNumber(practiceRef);
  }
}
