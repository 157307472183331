import {
  IFileData,
  ISQLSeedingTranslator,
} from '@principle-theorem/principle-core/interfaces';
import { IProvider } from '@principle-theorem/shared';

export class PraktikaPatientCommunicationsTranslator
  implements ISQLSeedingTranslator, IProvider<IFileData, string[]>
{
  matchingFilenames = ['patient_comms.csv'];
  commands = [
    // eslint-disable-next-line no-useless-escape
    `sed -i -e 's/\\&lt;img src="data:image\\/[^;]*;base64[^>]*\\/\\&gt;//'`,
  ];

  canProvide(data: IFileData): boolean {
    return this.matchingFilenames.includes(data.fileName);
  }

  execute(data: IFileData): string[] {
    return this.commands.map((cmd) => `${cmd} ${data.filePath}`);
  }
}
