/* eslint-disable no-console */

import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import {
  ConfirmDialogComponent,
  IConfirmationDialogInput,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import {
  Brand,
  Media,
  Practice,
  SterilisationCycle,
  toINamedDocuments,
} from '@principle-theorem/principle-core';
import {
  ISterilisationCycle,
  ISterilisationRecord,
  ISterilisationMachine,
  ISterilisationPack,
  ISterilisationCycleType,
  IMedia,
  IBrand,
  IPractice,
  IPatient,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  INamedDocument,
  isChanged$,
  getDoc$,
  query$,
  undeletedQuery,
  deleteDoc,
  isSameRef,
  addDoc,
  snapshot,
  Firestore,
  orderBy,
  where,
} from '@principle-theorem/shared';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import {
  SterilisationCycleDialogComponent,
  ISterilisationCycleDialogData,
} from '../components/sterilisation-cycle-dialog/sterilisation-cycle-dialog.component';
import {
  ViewImageDialogComponent,
  IViewImageDialogData,
  IViewImageDialogResponse,
  CaptureMediaDialogComponent,
  ICaptureMediaDialogRequest,
  ICaptureMediaDialogResponse,
} from '@principle-theorem/ng-principle-shared';
import {
  SterilisationCycleHistoryDialogComponent,
  ISterilisationCycleHistoryDialogData,
} from '../components/sterilisation-cycle-history-dialog/sterilisation-cycle-history-dialog.component';

interface ISterilisationState {
  cycle: WithRef<ISterilisationCycle> | undefined;
  records: WithRef<ISterilisationRecord>[];
  machines: WithRef<ISterilisationMachine>[];
  isLoading: boolean;
  availablePacks: INamedDocument<ISterilisationPack>[];
  cycleTypes: WithRef<ISterilisationCycleType>[];
  media: WithRef<IMedia>[];
}

const initialState: ISterilisationState = {
  cycle: undefined,
  records: [],
  machines: [],
  isLoading: false,
  availablePacks: [],
  cycleTypes: [],
  media: [],
};

@Injectable()
export class SterilisationStore extends ComponentStore<ISterilisationState> {
  private _dialog = inject(MatDialog);

  readonly cycle$ = this.select((state) => state.cycle);
  readonly records$ = this.select((state) => state.records);
  readonly machines$ = this.select((state) => state.machines);
  readonly isLoading$ = this.select((state) => state.isLoading);
  readonly availablePacks$ = this.select((state) => state.availablePacks);
  readonly cycleTypes$ = this.select((state) => state.cycleTypes);
  readonly media$ = this.select((state) => state.media);

  readonly hasRecords$ = this.select((state) => state.records.length > 0);
  readonly canAddSteriCycle$ = this.select(
    (state) => state.machines.length > 0
  );

  readonly loadAvailablePacks = this.effect(
    (brand$: Observable<WithRef<IBrand>>) =>
      brand$.pipe(
        switchMap((brand) => Brand.sterilisationPacks$(brand)),
        toINamedDocuments(),
        tapResponse(
          (availablePacks) => this.patchState({ availablePacks }),
          console.error
        )
      )
  );

  readonly loadAvailableMachines = this.effect(
    (
      data$: Observable<{
        brand: WithRef<IBrand>;
        practice: WithRef<IPractice>;
      }>
    ) =>
      data$.pipe(
        switchMap(({ brand, practice }) =>
          Brand.sterilisationMachines$(brand, practice.ref).pipe(isChanged$())
        ),
        tapResponse((machines) => this.patchState({ machines }), console.error)
      )
  );

  readonly loadCycleTypes = this.effect((brand$: Observable<WithRef<IBrand>>) =>
    brand$.pipe(
      switchMap((brand) =>
        Brand.sterilisationCycleTypes$(brand).pipe(isChanged$())
      ),
      tapResponse(
        (cycleTypes) => this.patchState({ cycleTypes }),
        console.error
      )
    )
  );

  readonly loadCycleWithRecords = this.effect(
    (data$: Observable<[WithRef<IPractice>, string]>) =>
      data$.pipe(
        switchMap(([practice, uid]) => {
          const cycle$ = getDoc$(
            Practice.sterilisationCycleCol(practice),
            uid
          ).pipe(distinctUntilChanged());
          const cycleRecords$ = cycle$.pipe(
            switchMap((cycle) => SterilisationCycle.records$(cycle))
          );
          const cycleMedia$ = cycle$.pipe(
            switchMap((cycle) => SterilisationCycle.media$(cycle))
          );

          return combineLatest([cycle$, cycleRecords$, cycleMedia$]).pipe(
            tapResponse(
              ([cycle, records, media]) =>
                this.patchState({ cycle, records, media }),
              console.error
            )
          );
        })
      )
  );

  readonly loadCycleRecords = this.effect(
    (cycle$: Observable<WithRef<ISterilisationCycle>>) =>
      cycle$.pipe(
        switchMap((cycle) => SterilisationCycle.records$(cycle)),
        tapResponse((records) => this.patchState({ records }), console.error)
      )
  );

  readonly loadPatientRecords = this.effect(
    (
      data$: Observable<{
        patient: WithRef<IPatient>;
        practice: WithRef<IPractice>;
      }>
    ) =>
      data$.pipe(
        switchMap(({ patient, practice }) =>
          query$(
            undeletedQuery(Practice.sterilisationRecordCol(practice)),
            where('patient.ref', '==', patient.ref),
            orderBy('createdAt', 'desc')
          )
        ),
        tapResponse((records) => this.patchState({ records }), console.error)
      )
  );

  constructor() {
    super(initialState);
  }

  async deleteRecord(record: WithRef<ISterilisationRecord>): Promise<void> {
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({
          data: {
            title: 'Delete Sterilisation Record',
            prompt: [
              'Are you sure you want to delete this record?',
              'This action cannot be undone.',
            ],
            submitLabel: 'Delete',
            submitColor: 'warn',
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!confirmed) {
      return;
    }
    this.patchState((state) => ({
      records: state.records.filter((storeRecord) =>
        isSameRef(storeRecord, record)
      ),
    }));
    await deleteDoc(record.ref);
  }

  async addCycle(practice: WithRef<IPractice>): Promise<void> {
    const machines = await snapshot(this.machines$);
    const cycleTypes = await snapshot(this.cycleTypes$);
    const cycle = await this._dialog
      .open<
        SterilisationCycleDialogComponent,
        ISterilisationCycleDialogData,
        ISterilisationCycle
      >(
        SterilisationCycleDialogComponent,
        DialogPresets.medium({
          data: {
            machines,
            cycleTypes,
            practice,
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!cycle) {
      return;
    }
    await addDoc(Practice.sterilisationCycleCol(practice), cycle);
  }

  async editCycle(
    cycle: WithRef<ISterilisationCycle>,
    practice: WithRef<IPractice>,
    staffer: WithRef<IStaffer>
  ): Promise<void> {
    const machines = await snapshot(this.machines$);
    const cycleTypes = await snapshot(this.cycleTypes$);

    const changes = await this._dialog
      .open<
        SterilisationCycleDialogComponent,
        ISterilisationCycleDialogData,
        ISterilisationCycle
      >(
        SterilisationCycleDialogComponent,
        DialogPresets.medium({
          data: {
            cycle,
            machines,
            practice,
            cycleTypes,
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!changes) {
      return;
    }

    await Firestore.patchDoc(cycle.ref, { ...changes });
    if (!SterilisationCycle.isPassing(changes)) {
      await SterilisationCycle.markFailed(cycle, staffer.ref);
    }
    await SterilisationCycle.archive(cycle, staffer.ref);
  }

  async failCycle(
    cycle: WithRef<ISterilisationCycle>,
    staffer: WithRef<IStaffer>
  ): Promise<void> {
    const confirmation = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({
          data: {
            title: 'Mark cycle as failed',
            prompt: [
              'Are you sure you want to mark this cycle as failed?',
              'All records linked to this cycle will also be marked as failed.',
            ],
            submitLabel: 'Mark as failed',
            submitColor: 'warn',
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!confirmation) {
      return;
    }
    await SterilisationCycle.markFailed(cycle, staffer.ref);
  }

  async passCycle(
    cycle: WithRef<ISterilisationCycle>,
    staffer: WithRef<IStaffer>
  ): Promise<void> {
    const confirmation = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({
          data: {
            title: 'Mark cycle as passed',
            prompt: [
              'Are you sure you want to mark this cycle as passed?',
              'All records linked to this cycle will also be marked as passed.',
            ],
            submitLabel: 'Mark as passed',
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!confirmation) {
      return;
    }
    await SterilisationCycle.markPassed(cycle, staffer.ref);
  }

  async viewImages(
    cycle: WithRef<ISterilisationCycle>,
    media?: WithRef<IMedia>[],
    initialIndex?: number
  ): Promise<void> {
    const images = media ?? (await snapshot(SterilisationCycle.media$(cycle)));
    if (!images.length) {
      return;
    }
    const response = await this._dialog
      .open<
        ViewImageDialogComponent,
        IViewImageDialogData,
        IViewImageDialogResponse
      >(
        ViewImageDialogComponent,
        DialogPresets.flex({ data: { media: images, initialIndex } })
      )
      .afterClosed()
      .toPromise();

    if (response && response.deletedMedia.length) {
      await Firestore.saveDoc(cycle);
    }
  }

  async uploadImage(
    cycle: WithRef<ISterilisationCycle>,
    path: string
  ): Promise<void> {
    await addDoc(
      SterilisationCycle.mediaCol(cycle),
      Media.init({
        path,
      })
    );
    await Firestore.saveDoc(cycle);
  }

  async captureImages(cycle: WithRef<ISterilisationCycle>): Promise<void> {
    const data: ICaptureMediaDialogRequest = {
      mediaCollectionRef: SterilisationCycle.mediaCol(cycle),
      storagePath: SterilisationCycle.storagePath(cycle),
      multiCapture: true,
    };

    const response = await this._dialog
      .open<
        CaptureMediaDialogComponent,
        ICaptureMediaDialogRequest,
        ICaptureMediaDialogResponse
      >(CaptureMediaDialogComponent, DialogPresets.flex({ data }))
      .afterClosed()
      .toPromise();

    if (response && response.savedMedia.length) {
      await Firestore.saveDoc(cycle);
    }
  }

  async openHistory(cycle: WithRef<ISterilisationCycle>): Promise<void> {
    const history = await snapshot(SterilisationCycle.editHistory$(cycle));
    if (!history.length) {
      return;
    }
    this._dialog.open<
      SterilisationCycleHistoryDialogComponent,
      ISterilisationCycleHistoryDialogData
    >(
      SterilisationCycleHistoryDialogComponent,
      DialogPresets.almostFullscreen({ data: { history } })
    );
  }
}
