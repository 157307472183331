import { ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AuthProvider,
  Layout,
  AuthProcessService,
  NgxAuthFirebaseuiLoginComponent,
} from 'ngx-auth-firebaseui';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { IWorkspaceSelector } from '../workspace-selector/workspace-selector.component';
import { AuthService } from '../auth.service';
import { filterNil, filterUndefined } from '@principle-theorem/shared';
import {
  debounceTime,
  map,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AbstractControl, Validators } from '@angular/forms';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';

@Component({
  selector: 'pt-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  providers: AuthProvider[] = [AuthProvider.Google, AuthProvider.Facebook];
  providersLayout: Layout = Layout.COLUMN;
  userIsVerified$: Observable<boolean>;
  resetPassword$ = new BehaviorSubject<boolean>(false);
  emailCtrl = new TypedFormControl<string>('', [
    Validators.email,
    Validators.required,
  ]);
  resetPasswordForm = new TypedFormGroup({
    email: this.emailCtrl,
  });
  loginComponent$ = new ReplaySubject<NgxAuthFirebaseuiLoginComponent>(1);
  logoSrc$ = new ReplaySubject<string>(1);

  @ViewChild('loginComponent')
  set registerComponent(component: NgxAuthFirebaseuiLoginComponent) {
    if (component) {
      this.loginComponent$.next(component);
    }
  }
  @Input() workspaceSelectors: ComponentType<IWorkspaceSelector>[];

  @Input()
  set logoSrc(src: string) {
    if (src) {
      this.logoSrc$.next(src);
    }
  }

  @Output() afterSuccess: EventEmitter<void> = new EventEmitter<void>();
  @Output() afterError: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _authProcess: AuthProcessService,
    public auth: AuthService
  ) {
    this.userIsVerified$ = this.auth.authUser$.pipe(
      filterUndefined(),
      map((user) => user.emailVerified),
      startWith(false)
    );

    this.loginComponent$
      .pipe(
        map(
          (component) =>
            component.loginForm.get('email') as AbstractControl<string> | null
        ),
        filterNil(),
        switchMap((emailCtrl) =>
          emailCtrl.valueChanges.pipe(
            debounceTime(250),
            map((value) => ({ value, emailCtrl }))
          )
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(({ value, emailCtrl }) =>
        emailCtrl.setValue(value.toLowerCase())
      );
  }

  async onSuccess(): Promise<void> {
    await this.auth.updateClaims();
    this.afterSuccess.emit();
  }

  onError(event: Error): void {
    this._snackBar.open(event.message);
    this.afterError.emit();
  }

  async onCreateAccount(): Promise<void> {
    await this._router.navigate(['register']);
  }

  async resetPassword(): Promise<void> {
    if (!this.resetPasswordForm.valid) {
      return;
    }
    try {
      await this._authProcess.resetPassword(this.emailCtrl.value);
      this._snackBar.open(
        `Please follow the link sent to ${this.emailCtrl.value}`
      );
    } catch (error) {
      this._snackBar.open('Error sending reset link. Please try again.');
    }
    this.resetPassword$.next(false);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
