import * as moment from 'moment-timezone';
import {
  type IStatisticMetrics,
  type IStatistics,
} from '@principle-theorem/reporting';

export interface IStatisticsMocker {
  names?: string[];
  unit?: moment.DurationInputArg2;
  stats?: IStatisticMetrics;
}

export abstract class StatisticsMocker<
  R extends IStatisticMetrics = IStatisticMetrics,
  T extends IStatistics<R> = IStatistics<R>
> implements IStatisticsMocker
{
  unit: moment.DurationInputArg2 = 'day';
  stats: R;

  constructor(public names: string[] = []) {}

  abstract mockStatistic(
    name: string,
    date: moment.Moment,
    dates?: moment.Moment[]
  ): T;

  mockSingle(name: string = ''): R {
    return this.mockStatistic(name, moment()).stats;
  }

  mock(from: moment.Moment, to: moment.Moment): T[] {
    const metricNames: string[] = this.names;
    const metrics: T[] = [];
    const pointer: moment.Moment = moment(from).startOf(this.unit);
    while (pointer.isSameOrBefore(to)) {
      metricNames.map((name: string) => {
        metrics.push(this.mockStatistic(name, pointer.clone()));
      });
      pointer.add(1, this.unit);
    }
    return metrics;
  }

  protected _setOnce(
    value: number,
    date: moment.Moment,
    dates: moment.Moment[] = []
  ): number {
    return date.isSame(dates[0]) ? value : 0;
  }
}
