<ng-container *ngIf="lab$ | async as lab">
  <pt-breadcrumbs
    [breadcrumbs]="breadcrumbs"
    [current]="lab.name"
    class="bg-white"
  />
  <div class="border-b border-solid border-slate-300 bg-white px-2 pt-0">
    <nav
      mat-tab-nav-bar
      [tabPanel]="tabPanel"
      class="m-auto w-full !border-none"
    >
      <a
        *ngFor="let link of navLinks; trackBy: trackByLink"
        mat-tab-link
        [routerLink]="link.path"
        routerLinkActive
        #rla="routerLinkActive"
        [active]="rla.isActive"
      >
        {{ link.label }}
      </a>
    </nav>
  </div>
  <mat-tab-nav-panel #tabPanel>
    <div class="layout-padding page-content">
      <router-outlet />
    </div>
  </mat-tab-nav-panel>
</ng-container>
