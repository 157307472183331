import { createEntityAdapter, type EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { type ISerialisedEntityState } from '@principle-theorem/ng-shared';
import {
  type ITimePeriod,
  type SerialisedData,
} from '@principle-theorem/shared';
import {
  CalendarActions,
  CalendarCollectionActions,
  CalendarEventsActions,
} from './actions';
import { type CalendarEventEntity } from './models';
import {
  CalendarUnit,
  CalendarView,
} from '@principle-theorem/principle-core/interfaces';

export const CALENDAR_FEATURE_KEY = 'calendar';

export interface ICalendarState
  extends ISerialisedEntityState<CalendarEventEntity> {
  selectedId?: string;
  loaded: boolean;
  error?: string;
  range?: SerialisedData<ITimePeriod>;
  unit: CalendarUnit;
  view: CalendarView;
}

export interface ICalendarPartialState {
  readonly [CALENDAR_FEATURE_KEY]: ICalendarState;
}

export const calendarAdapter: EntityAdapter<
  SerialisedData<CalendarEventEntity>
> = createEntityAdapter({
  selectId: (calendarEvent) => calendarEvent.uid,
  sortComparer: false,
});

export const initialState: ICalendarState = calendarAdapter.getInitialState({
  loaded: false,
  unit: CalendarUnit.Week,
  view: CalendarView.Timeline,
});

const reducer = createReducer(
  initialState,

  on(CalendarEventsActions.loadCalendarEvents, (state) => ({
    ...state,
    loaded: false,
  })),

  on(CalendarCollectionActions.loadCalendarEventsSuccess, (state, action) =>
    calendarAdapter.setAll(action.calendarEvents, {
      ...state,
      loaded: true,
    })
  ),

  on(CalendarCollectionActions.loadCalendarEventsFail, (state, action) => ({
    ...state,
    error: action.error,
  })),

  on(CalendarActions.setCalendarRange, (state, action) => ({
    ...state,
    range: action.range,
  })),

  on(CalendarActions.setCalendarUnit, (state, action) => ({
    ...state,
    unit: action.unit,
  })),

  on(CalendarActions.setViewUnitAndRange, (state, action) => ({
    ...state,
    unit: action.unit,
    range: action.range,
    view: action.view,
  })),

  on(CalendarEventsActions.selectCalendarEvent, (state, action) => ({
    ...state,
    selectedId: action.id,
  })),

  on(CalendarCollectionActions.deleteCalendarEventSuccess, (state, _action) => {
    if (!state.selectedId) {
      return { ...state };
    }
    return calendarAdapter.removeOne(state.selectedId, {
      ...state,
      selectedId: undefined,
    });
  }),

  on(CalendarActions.resetCalendar, () => ({ ...initialState }))
);

export function calendarReducer(
  state: ICalendarState | undefined,
  action: Action
): ICalendarState {
  return reducer(state, action);
}
