import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { type ITransactionReportRequest } from '@principle-theorem/reporting';
import { type ITimePeriod, toTimePeriod } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  type ILatestTransactionReportRecord,
  ReportingFunctions,
} from '../../core/reporting-functions';

export interface IPendingPaymentsState {
  dateRange: ITimePeriod;
  records: ILatestTransactionReportRecord[];
  isLoading: boolean;
}

@Injectable()
export class PendingPaymentsStore extends ComponentStore<IPendingPaymentsState> {
  readonly isLoading$ = this.select((data) => data.isLoading);
  readonly records$ = this.select((data) => data.records);
  readonly dateRange$ = this.select((data) => data.dateRange);

  readonly loadTransactions = this.effect(
    (query$: Observable<ITransactionReportRequest>) =>
      query$.pipe(
        tap((request) =>
          this.setState({
            records: [],
            isLoading: true,
            dateRange: toTimePeriod(request.startDate, request.endDate),
          })
        ),
        switchMap((query) => ReportingFunctions.getLatestTransactions(query)),
        tap((records) => this.patchState({ records, isLoading: false }))
      )
  );
}
