@if (equipment$ | async; as equipment) {
  @if (!equipment.length) {
    <pr-empty-state image="list" title="equipment configured" />
  } @else {
    <mat-list>
      @for (item of equipment; track item.ref.path) {
        <div class="flex items-center justify-between">
          <div>
            <p class="mat-headline-6 !m-0">
              {{ item.name }}
            </p>
            <p class="mat-caption !m-0">
              {{ item.description }}
            </p>
          </div>

          <div class="flex items-center justify-end gap-4">
            <div>
              <button mat-icon-button (click)="edit.emit(item)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button color="warn" (click)="delete.emit(item)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      }
    </mat-list>
  }
}
