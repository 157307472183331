import {
  IAppointment,
  IChat,
  IContact,
  ILab,
  ILabJob,
  IMentionDeprecated,
  IPatient,
  IPrincipleMention,
  isDeprecatedMention,
  isStaffer,
  IStaffer,
  ITask,
  MentionResourceType,
  RootCollection,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  INamedDocument,
  toNamedDocument,
  WithRef,
} from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';
import { stafferToNamedDoc } from '../common';
import { OrganisationCache } from '../organisation/organisation-cache';

export function revertToDeprecatedMention(
  mention: IPrincipleMention | IMentionDeprecated
): IMentionDeprecated {
  if (isDeprecatedMention(mention)) {
    return mention;
  }

  return {
    uid: mention.resource.uid,
    key: mention.key,
    ref: mention.resource.ref,
    resourceType: mention.resource.type,
  };
}

export function translateDeprecatedMention(
  mention: IPrincipleMention | IMentionDeprecated
): IPrincipleMention {
  if (!isDeprecatedMention(mention)) {
    return mention;
  }

  return {
    uid: uuid(),
    key: mention.key,
    resource: {
      uid: mention.uid,
      ref: mention.ref,
      type: mention.resourceType,
    },
  };
}

export function toMention(
  data: INamedDocument | WithRef<IStaffer>,
  type: MentionResourceType
): IPrincipleMention {
  const namedDoc = isStaffer(data)
    ? stafferToNamedDoc(data)
    : toNamedDocument(data);
  return {
    uid: uuid(),
    key: namedDoc.name,
    resource: {
      uid: namedDoc.ref.id,
      ref: namedDoc.ref,
      type,
    },
  };
}

export async function fromMention(
  mention: IPrincipleMention
): Promise<WithRef<unknown> | undefined> {
  switch (mention.resource.type) {
    case MentionResourceType.Candidate:
    case MentionResourceType.Patient:
      return Firestore.getDoc<IPatient>(
        mention.resource.ref as DocumentReference<IPatient>
      );
    case MentionResourceType.Staffer:
      if (mention.resource.ref.path.includes(RootCollection.ManagementStaff)) {
        return;
      }
      return OrganisationCache.staff.get.getDoc(
        mention.resource.ref as DocumentReference<IStaffer>
      );
    case MentionResourceType.Lab:
      return Firestore.getDoc<ILab>(
        mention.resource.ref as DocumentReference<ILab>
      );
    case MentionResourceType.LabJob:
      return Firestore.getDoc<ILabJob>(
        mention.resource.ref as DocumentReference<ILabJob>
      );
    case MentionResourceType.Appointment:
      return Firestore.getDoc<IAppointment>(
        mention.resource.ref as DocumentReference<IAppointment>
      );
    case MentionResourceType.Contact:
      return Firestore.getDoc(
        mention.resource.ref as DocumentReference<IContact>
      );
    case MentionResourceType.Task:
      return Firestore.getDoc(mention.resource.ref as DocumentReference<ITask>);
    case MentionResourceType.Chat:
      return Firestore.getDoc(mention.resource.ref as DocumentReference<IChat>);
    default:
      return undefined;
  }
}
