/* eslint-disable @typescript-eslint/naming-convention */
import { prefixCharacters, suffixCharacters } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';
import { HicapsConnect } from './interfaces';
import { ServiceTypeCde } from './interfaces/hicaps-connect';
import { IPMSHicapsConnectConfig } from './principle-hicaps-connect';

const PMSConfig: IPMSHicapsConnectConfig = {
  PmsName: 'Not 4 Public Release',
  PmsKey: '7952265',
};

function padRight(value: string, amount: number, symbol: string = ' '): string {
  return suffixCharacters(symbol, amount)(value);
}

function padLeft(value: string, amount: number, symbol: string = ' '): string {
  return prefixCharacters(symbol, amount)(value);
}

function detailsString(segments: string[]): string {
  return segments.join('');
}

export const Version = '1.0.3.32.R1';

export const ServerUrl = '192.168.0.3';
export const ServerPort = '11000';
export const TerminalId = 'SE102A';
export const ComPort = 'COM5';

const MerchantName = 'MERCHANT#3 3DES MULTI MDOCKLANDS';
const MerchantId = '12345678';

const ProviderName = 'Provider Name';
const ProviderNumberId = '1234567F';

const PrimaryAccountNumber = '1234123412341234';
const MembershipId = '01';
const ExpiryDate = '04/25';

const PatientId = padRight('1', 2); // Length 2 (Numeric, Left zero padded)
const PatientFirstName = 'Gerard';
const PatientLastName = 'Kuiper';
const PatientName = `${PatientFirstName} ${PatientLastName}`; // Variable Length up to 39 characters
const PatientNameDetails = detailsString([PatientId, PatientName]);
const PatientMedicareCardNum = '1234123451';

const ClaimDetails = detailsString([
  '00', // PatientID (2 Numeric)  e.g “00” or “01”
  padRight('001', 4), // ItemNumber (4 AlphaNumeric) e.g “1001” or “011 “
  '11', // BodyPartNumber (2 AlphaNumeric) e.g “11” or “AB”
  '0105', // DateOfService (4 Numeric DDMM) e.g “0105” or “3006”
  '010010', // ItemClaimAmount (6 Numeric). e.g 010010 = $100.10 or 123456 = $1234.56
  '010010', // ItemBenefitAmount (6 Numeric) e.g 010010 = $100.10 or 123456 = $1234.56
  '00', // ItemResponseCode (2 AlphaNumeric) e.g “00” or “P0”
]);
// 00   11010501001001001000

const MerchantListDetails = detailsString([
  padRight(TerminalId, 7), // TerminalId (7 Alpha numeric characters) e.g “SE123A “
  padRight(MerchantId, 16), // MerchantId (16 Numeric) e.g “33123456 “
  padRight(ProviderNumberId, 8), // ProviderNumber (8 Alpha Numeric) e.g “0123456A”
  padRight(ProviderName, 15), // ProviderName (15 Alpha Numeric) e.g “DR MARY SMITH “
]);

// const MerchantListText = detailsString([
//   padRight(TerminalId, 7), // TerminalId (7 Alpha numeric characters) e.g “SE123A “
//   padRight(MerchantId, 16), // MerchantId (16 Numeric) e.g “33123456 “
//   padRight(MerchantName, 40), // MerchantName (40 Alpha Numeric) e.g “MERCHANT#3 3DES MULTI MDOCKLANDS ”
// ]);

const ProviderListText = detailsString([
  padRight(TerminalId, 7), // 1. Terminal Id (7 Alpha numeric characters) e.g “SE123A “
  padRight(MerchantId, 16), // 2. Merchant Id (16 Numeric) e.g “33123456 “
  padRight(ProviderNumberId, 8), // 3. Provider Number (8 Alpha Numeric) e.g “0123456A”
  padRight(ProviderName, 15), // 4. Provider Name (15 Alpha Numeric) e.g “DR MARY SMITH “
  padRight('S', 1), // 5. Provider Type (1 Alpha) S = Specialist, O = Optical
  padRight(ProviderNumberId, 8), // 6. Payee Provider Number (8 Alpha Numeric) e.g “0123456A”
  padRight('01', 2), // 7. Number in Terminal (2 Numeric) e.g 01 or 02
]);

const MedicareClaimDetails = detailsString([
  padLeft('12500', 6), // ItemNumber. (Left Padded length 6.) e.g “ 12500“
  padLeft('1234', 6, '0'), // FeeAmount. (Left Padded 0 length 6.) e.g “001234” = $12.34
  '31012009', // DateofService (Format DDMMYYYY, Length 8.) e.g “31012009” = 31st Jan 2009.
  'AO', // ItemOverrideCode, (Length 2.) e.g “AO”
  padLeft('12345', 6), // LSPN. (Left Padded Length 6) e.g “12345 “
  padLeft('1234', 5), // EquipmentIdentificationCde. (Left Padded Length 5) e.g “ 1234”
  'SD', // SelfDeemedCde. (Length 2.) e.g “SD”
  padLeft('1234', 6, '0'), // PatientContributionAmount. (Left Padded 0 length 6.) e.g “001234” = $12.34
  padLeft('A12', 4), // SpcId (Left Padded Length 4) e.g “ A12”
  padLeft('1234', 6, '0'), // BenefitAmount (Left Padded 0 length 6.) e.g “001234” = $12.34
  padLeft('9012', 4), // ExplanationCode (Left Padded Length 4) e.g “9012”
  padLeft('1234', 6, '0'), // BenefitAssignedAmount (Left Padded 0 length 6.) e.g “001234” = $12.34
  padLeft('1234', 6, '0'), // ScheduleFeeAmount (Left Padded 0 length 6.) e.g “001234” = $12.34
]);

// The next fields are repeated until end of field
const HicapsTotalsListDetailsData = detailsString([
  padRight('HCF', 12), // Fund Name (AlphaNumeric) Length 12
  padRight('1234', 12), // Total Amount (numeric) Length 12
  padRight('1', 4), // No. Transaction (Numeric) Length 4
  padRight('', 4), // Filler Length 4
]);

const HicapsTotalsListDetails = detailsString([
  padRight(MerchantName, 40), // Merchant Name (AlphaNumeric) Length 40.
  '110323', // Date (DDMMYY) Length 6
  '1103', // Time (HHMM) Length 4
  padRight(MerchantId, 8), // Merchant ID (Numeric) Length 8
  padRight(TerminalId, 8), // Terminal Id (AlphaNumeric) Length 8
  padRight(ProviderNumberId, 8), // Provider Number(AlphaNumeric) Length 8
  padRight(ProviderName, 15), // Proviate Name (AlphaNumeric) Length 15
  padRight('110323', 6), // Date (DDMMYY) Length 6
  padLeft('1234', 12, '0'), // Total Amount (0 Left Padded Numeric) Length 12
  padRight('1', 4), // No. Transactions (Numeric) Length 4
  padRight('', 13), // Filler Length 13
  HicapsTotalsListDetailsData,
]);

export const BaseResponse: HicapsConnect.BaseResponse = {
  ClientVersion: Version,
  ComPort,
  ResponseCode: '00',
  ResponseText: 'APPROVED',
  ResponseTime: moment().toISOString(),
  ServerUrl,
  ServerVersion: Version,
  TerminalVersion: Version,
  SoftwareVendorName: PMSConfig.PmsName,
};

export const SaleResponse: HicapsConnect.SaleResponse = {
  ...BaseResponse,
  ApprovalCode: '00',
  AuthResponseCode: '00',
  ExpiryDate,
  InvoiceNumber: uuid(),
  MerchantId,
  PrimaryAccountNumber,
  PrintReceiptData: '',
  ProviderName,
  RrnNumber: uuid(),
  SurchargeAmount: 0,
  TerminalId,
  TransactionAmount: 0,
  TransactionDate: moment().toISOString(),
};

export const SaleCashoutResponse: HicapsConnect.SaleCashoutResponse = {
  ...SaleResponse,
  CashoutAmount: 0,
};

export const RefundResponse: HicapsConnect.RefundResponse = {
  ...BaseResponse,
  ApprovalCode: '00',
  AuthResponseCode: '00',
  ExpiryDate,
  InvoiceNumber: uuid(),
  MerchantId,
  PrimaryAccountNumber,
  PrintReceiptData: '',
  ProviderName,
  RrnNumber: uuid(),
  SurchargeAmount: 0,
  TerminalId,
  TerminalSwipe: '',
  TransactionAmount: 0,
  TransactionDate: moment().toISOString(),
};

export const EftposDepositResponse: HicapsConnect.EftposDepositResponse = {
  ...RefundResponse,
};

export const CashoutResponse: HicapsConnect.CashoutResponse = {
  ...BaseResponse,
  ApprovalCode: '00',
  AuthResponseCode: '00',
  CashoutAmount: 0,
  ExpiryDate,
  InvoiceNumber: uuid(),
  MerchantId,
  PrimaryAccountNumber,
  PrintReceiptData: '',
  ProviderName,
  RrnNumber: uuid(),
  SurchargeAmount: 0,
  TerminalId,
  TerminalSwipe: '',
  TransactionDate: moment().toISOString(),
};

export const EftposTransListingResponse: HicapsConnect.EftposTransListingResponse =
  {
    ...SaleCashoutResponse,
    SaleTransactionList: [SaleResponse],
    SaleCashoutTransactionList: [SaleCashoutResponse],
    RefundTransactionList: [RefundResponse],
    EftposDepositTransactionList: [EftposDepositResponse],
    CashoutTransactionList: [CashoutResponse],
    SubTransCode: '',
    TransactionDate: moment().toISOString(),
  };

export const GetTerminalResponse: HicapsConnect.GetTerminalResponse = {
  ...BaseResponse,
  ServerData: [''],
  TerminalList: [''],
  TerminalListStr: [''],
};

export const HicapsTotalsResponse: HicapsConnect.HicapsTotalsResponse = {
  ...BaseResponse,
  HicapsTotalsListDetails: [HicapsTotalsListDetails],
  HicapsTotalsListDetailsStr: [HicapsTotalsListDetails],
  TransactionDate: moment().toISOString(),
};

export const ClaimResponse: HicapsConnect.ClaimResponse = {
  ...BaseResponse,
  BenefitAmount: 100,
  ClaimDetails: [ClaimDetails],
  ClaimDetailsStr: [ClaimDetails],
  ExpiryDate,
  MembershipId,
  MerchantId,
  PatientNameDetails: [PatientNameDetails],
  PatientNameDetailsStr: [PatientNameDetails],
  PrimaryAccountNumber,
  ProviderName,
  ProviderNumberId,
  RrnNumber: uuid(),
  TerminalId,
  TransactionAmount: 100,
  TransactionDate: moment().toISOString(),
};

export const ClaimCancelResponse: HicapsConnect.ClaimCancelResponse = {
  ...BaseResponse,
  BenefitAmount: 0,
  ClaimDetails: [ClaimDetails],
  ClaimDetailsStr: [ClaimDetails],
  ExpiryDate,
  MembershipId,
  MerchantId,
  PrimaryAccountNumber,
  ProviderName,
  ProviderNumberId,
  RrnNumber: uuid(),
  TerminalId,
  TransactionDate: moment().toISOString(),
};

export const QuoteResponse: HicapsConnect.QuoteResponse = {
  ...ClaimResponse,
};

export const HicapsTransListingResponse: HicapsConnect.HicapsTransListingResponse =
  {
    ...ClaimResponse,
    ClaimCancelTransactionList: [ClaimCancelResponse],
    ClaimTransactionList: [ClaimResponse],
    QuoteTransactionList: [QuoteResponse],
    SubTransCode: '',
  };

export const AllItemResponseCodeListResponse: HicapsConnect.AllItemResponseCodeListResponse =
  {
    ...BaseResponse,
    ItemResponseCodeDescription: [''],
    TransactionDate: moment().toISOString(),
  };

export const AllItemListResponse: HicapsConnect.AllItemListResponse = {
  ...BaseResponse,
  ItemListDescription: [],
  ItemListDescriptionStr: [],
  TransactionDate: moment().toISOString(),
};

export const AllMerchantListResponse: HicapsConnect.AllMerchantListResponse = {
  ...BaseResponse,
  MerchantListDetails: [MerchantListDetails],
  MerchantListDetailsStr: [MerchantListDetails],
  TransactionDate: moment().toISOString(),
};

export const MedicareClaimResponse: HicapsConnect.MedicareClaimResponse = {
  ...BaseResponse,
  PrimaryAccountNumber,
  ReferralIssueDate: moment().toISOString(),
  ReferralOverrideTypeCde: 'N',
  ReferralPeriodTypeCde: 'S',
  ReferringProviderName: ProviderName,
  ReferringProviderNum: ProviderNumberId,
  RequestingProviderNum: ProviderNumberId,
  RequestIssueDate: moment().toISOString(),
  RequestOverrideTypeCde: 'N',
  RequestTypeCde: ServiceTypeCde.Specialist,
  ServiceTypeCde: ServiceTypeCde.Specialist,
  ServicingProviderName: ProviderName,
  ServicingProviderNum: ProviderNumberId,
  TerminalId,
  TransactionAmount: 0,
  PayeeProviderNum: ProviderNumberId,
  TransactionDate: moment().toISOString(),
  PatientMedicareCardNum,
  PatientIRN: '1',
  AcceptanceTypeCde: '', // Not Documented
  AccountPaidInd: '', // Not Documented
  AccountReferenceId: uuid(), // PMS Generated
  AssessmentErrorCde: '',
  ClaimantFirstName: PatientFirstName,
  ClaimantIRN: '1',
  PatientLastName,
  ClaimantLastName: PatientLastName,
  MedicareClaimDetails: [MedicareClaimDetails],
  MedicareClaimDetailsStr: [MedicareClaimDetails],
  MedicareEligibilityStatus: '', // Not Documented
  MedicareError: '', // Not Documented
  MerchantId,
  PatientFirstName,
  ClaimantMedicareCardNum: PatientMedicareCardNum,
  TransactionId: uuid(),
};

export const MedicareMerchantListResponse: HicapsConnect.MedicareMerchantListResponse =
  {
    ...BaseResponse,
    MedicareMerchantListDetails: [MerchantListDetails], // Not Documented assumed MerchantListDetails
    MedicareMerchantListDetailsStr: [MerchantListDetails], // Not Documented assumed MerchantListDetails
    TransactionDate: moment().toISOString(),
  };

export const MerchantListResponse: HicapsConnect.MerchantListResponse = {
  ...BaseResponse,
  MerchantListDetails: [MerchantListDetails],
  MerchantListDetailsStr: [MerchantListDetails],
  TransactionDate: moment().toISOString(),
};

export const PrintLastReceiptResponse: HicapsConnect.PrintLastReceiptResponse =
  {
    ...ClaimResponse,
    InvoiceNumber: uuid(),
    RrnNumber: uuid(),
    SubTransCode: '',
  };

export const SettlementResponse: HicapsConnect.SettlementResponse = {
  ...BaseResponse,
  AcquirerId: '1',
  AuthResponseCode: '00',
  MerchantId,
  ProviderName,
  TerminalId,
};

export const StatusResponse: HicapsConnect.StatusResponse = {
  ...BaseResponse,
};

export const AllPharmItemListResponse: HicapsConnect.AllPharmItemListResponse =
  {
    ...AllItemListResponse,
  };

export const TerminalTestResponse: HicapsConnect.TerminalTestResponse = {
  ...BaseResponse,
  MerchantId,
  TerminalId,
  TransactionDate: moment().toISOString(),
};

export const AllProviderListResponse: HicapsConnect.AllProviderListResponse = {
  ...BaseResponse,
  ProviderListDetails: [ProviderListText], // Not documented assumed ProviderListText
  ProviderListDetailsStr: [ProviderListText], // Not documented assumed ProviderListText
  TransactionDate: moment().toISOString(),
};

export const CardListResponse: HicapsConnect.CardListResponse = {
  ...BaseResponse,
  CardFundDetails: [''], // Not Documented
  CardFundDetailsStr: [''], // Not Documented
  TransactionDate: moment().toISOString(),
};

export const CardReadResponse: HicapsConnect.CardReadResponse = {
  ...BaseResponse,
  ExpiryDate,
  PrimaryAccountNumber,
  SecondTrack: '', // Not Documented
  TrackData: '', // Not Documented
  TransactionDate: moment().toISOString(),
};

export const ClaimPharmResponse: HicapsConnect.ClaimPharmResponse = {
  ...ClaimResponse,
  ScriptDetails: [''], // Not Documented
  ScriptDetailsStr: [''], // Not Documented
};

export const AllTransCodeListResponse: HicapsConnect.AllTransCodeListResponse =
  {
    ...BaseResponse,
    TransactionDate: moment().toISOString(),
    TransCodeDescription: [''], // Not Documented
  };

export const QuotePharmResponse: HicapsConnect.QuotePharmResponse = {
  ...ClaimPharmResponse,
};
