import {
  FormStatus,
  IPatient,
  ISubmittedFormHistory,
  PatientForm,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  AtLeast,
  DocumentArchive,
  DocumentReference,
  IReffable,
  toTimestamp,
  updateDoc,
  WithRef,
} from '@principle-theorem/shared';
import { Patient } from '../patient/patient';
import { SubmittedForm } from '../patient/submitted-form';

export class SubmittedFormHistory {
  static init(
    overrides: AtLeast<ISubmittedFormHistory, 'form'>
  ): ISubmittedFormHistory {
    return {
      deleted: false,
      status: FormStatus.Submitted,
      statusHistory: [
        {
          status: overrides.status ?? FormStatus.Submitted,
          updatedAt: toTimestamp(),
        },
      ],
      ...overrides,
    };
  }

  static async add(
    patient: IReffable<IPatient>,
    formType: PatientForm,
    submission: AtLeast<ISubmittedFormHistory, 'form'>,
    currentForm?: WithRef<ISubmittedFormHistory>
  ): Promise<DocumentReference<ISubmittedFormHistory<unknown>>> {
    submission.form.date = toTimestamp();

    if (currentForm) {
      await DocumentArchive.snapshotToArchive(
        currentForm,
        SubmittedForm.archiveCol(currentForm)
      );
      await updateDoc(currentForm.ref, SubmittedFormHistory.init(submission));
      return currentForm.ref;
    }

    return addDoc(
      Patient.formCol(patient),
      SubmittedFormHistory.init(submission),
      formType
    );
  }
}
