<div fxFill fxLayout="column">
  <pr-mention-toolbar
    *ngIf="patient$ | async as patient"
    [label]="patient.name"
    [resource]="patientMention$ | async"
    [interactiveResource]="interactiveResource$ | async"
    [templateResource]="appointmentMention$ | async"
   />

  <div fxFlex class="interactions">
    <pr-appointment-interactions
      fxFill
      compact
      [appointment]="appointment$ | async"
     />
  </div>
</div>
