import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-treatment-provider',
  templateUrl: './treatment-provider.component.html',
  styleUrls: ['./treatment-provider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentProviderComponent {
  name$ = new ReplaySubject<string>(1);
  providerNumber$ = new ReplaySubject<string>(1);

  @Input()
  set providerNumber(providerNumber: string) {
    if (providerNumber) {
      this.providerNumber$.next(providerNumber);
    }
  }

  @Input()
  set name(name: string) {
    if (name) {
      this.name$.next(name);
    }
  }
}
