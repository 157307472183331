import { roundToDecimals } from '@principle-theorem/accounting';
import {
  AppointmentStatus,
  IMeasureMetadata,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import { IAppointmentDimension } from '@principle-theorem/reporting/interfaces';
import { AtLeast, ISO_TIME_FORMAT } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { IReportingQuery } from '../../querying';
import { BaseDimensionMeasures } from '../base-measures';
import { DataAccessorFactory, MeasurePath } from '../data-accessor-factory';
import {
  NullMeasureFilter,
  ValueEqualsMeasureFilter,
} from '../measure-filters';
import {
  CanBeChartedProperty,
  CanDoAllProperty,
  CanQueryByTimestampProperty,
  IMeasureRef,
  MeasureTransformMap,
  basicFilterAccessor,
  noFilterAccessor,
} from '../measure-properties';
import {
  MeasurePropertyFactory,
  defaultMetadata,
} from '../measure-property-factory';

export class AppointmentDimensionMeasures
  extends BaseDimensionMeasures
  implements MeasureTransformMap<Pick<IAppointmentDimension, 'status'>>
{
  get ref(): CanDoAllProperty {
    const propertyName = 'ref';
    const measure = this.measureRef(MeasurePath.docRef(propertyName));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Appointment Ref',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get practice(): CanDoAllProperty {
    const measure = this.measureRef('practice.name');
    return MeasurePropertyFactory.string(
      {
        id: this._pathWithPrefix('practice.name'),
        label: 'Practice Name',
        summary:
          'The name of the practice that the appointment was performed at',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get invoiceRef(): CanDoAllProperty {
    const propertyName = 'invoiceRef';
    const measure = this.measureRef(MeasurePath.docRef(propertyName));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Invoice Ref',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get status(): CanDoAllProperty {
    const measure = this.measureRef('status');
    return MeasurePropertyFactory.enum<AppointmentStatus>(
      {
        id: this._pathWithPrefix('status'),
        label: 'Appointment Status',
        summary: 'The status of the appointment',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      AppointmentStatus.Unscheduled
    );
  }

  get isBookedOnline(): CanDoAllProperty {
    const measure = this.measureRef('appointmentRequest');
    return MeasurePropertyFactory.boolean(
      {
        id: this._pathWithPrefix('isBookedOnline'),
        label: 'Is Booked Online',
        summary: 'Whether the appointment was booked online or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get confirmedAt(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('confirmedAt'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('confirmedAt'),
        label: 'Date Confirmed',
        summary: 'Date and time that the appointment was confirmed',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get firstBookedAt(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('firstBookedAt'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('firstBookedAt'),
        label: 'Date First Booked At',
        summary:
          'Date and time that the appointment was first booked in. This will be the same as the last booked date if the appointment was only booked once.',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get appointmentFirstBookedLeadTime(): CanBeChartedProperty {
    const propertyName = 'appointmentFirstBookedLeadTime';
    const firstBookedMeasure = this.measureRef(
      MeasurePath.timestamp('firstBookedAt')
    );
    const eventMeasure = this.measureRef(MeasurePath.timestamp('event.from'));
    return new CanBeChartedProperty({
      metadata: {
        id: propertyName,
        label: 'Appointment First Booked Lead Time',
        summary:
          'The lead time between when the appointment was first booked, to when it was performed,',
        formatter: MeasureFormatter.Suffix,
        formatterValue: 'days',
      },
      measure: {
        propertyName,
        query: this.buildQuery()
          .attributes([
            firstBookedMeasure.attributePath,
            eventMeasure.attributePath,
          ])
          .get(),
        dataAccessor: (fact) => {
          const firstBooked = DataAccessorFactory.timestamp(
            firstBookedMeasure.factPropertyPath,
            undefined
          )(fact);
          const event = DataAccessorFactory.timestamp(
            eventMeasure.factPropertyPath,
            undefined
          )(fact);

          if (!firstBooked || !event) {
            return 0;
          }

          const diffInDays = moment(firstBooked).diff(moment(event), 'days');
          return roundToDecimals(Math.abs(diffInDays), 1);
        },
        filterAccessor: noFilterAccessor(),
      },
    });
  }

  get lastBookedAt(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('lastBookedAt'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('lastBookedAt'),
        label: 'Date Last Booked At',
        summary:
          'Date and time that the appointment was last booked in. This will be the same as the first booked date if the appointment was only booked once.',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get appointmentLastBookedLeadTime(): CanBeChartedProperty {
    const propertyName = 'appointmentLastBookedLeadTime';
    const lastBookedMeasure = this.measureRef(
      MeasurePath.timestamp('lastBookedAt')
    );
    const eventMeasure = this.measureRef(MeasurePath.timestamp('event.from'));
    return new CanBeChartedProperty({
      metadata: {
        id: propertyName,
        label: 'Appointment Last Booked Lead Time',
        summary:
          'The lead time between when the appointment booking was last changed, to when it was performed. This might be the same as the last booked lead time if the appointment was only booked once.',
        formatter: MeasureFormatter.Suffix,
        formatterValue: 'days',
      },
      measure: {
        propertyName,
        query: this.buildQuery()
          .attributes([
            lastBookedMeasure.attributePath,
            eventMeasure.attributePath,
          ])
          .get(),
        dataAccessor: (fact) => {
          const lastBooked = DataAccessorFactory.timestamp(
            lastBookedMeasure.factPropertyPath,
            undefined
          )(fact);
          const event = DataAccessorFactory.timestamp(
            eventMeasure.factPropertyPath,
            undefined
          )(fact);

          if (!lastBooked || !event) {
            return '';
          }

          return Math.abs(moment(lastBooked).diff(moment(event), 'days'));
        },
        filterAccessor: noFilterAccessor(),
      },
    });
  }

  get dateBookedOnline(): CanQueryByTimestampProperty {
    const measure = this.measureRef(
      MeasurePath.timestamp('appointmentRequest.bookedAt')
    );
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('dateBookedOnline'),
        label: 'Date Booked Online',
        summary: 'Date that the appointment was booked online',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      MeasureFormatter.Day
    );
  }

  get bookedOnline(): CanDoAllProperty {
    return this.isBookedOnline
      .clone()
      .setMetadata({ id: this._pathWithPrefix('bookedOnline') })
      .filterBy(new NullMeasureFilter(false));
  }

  get notBookedOnline(): CanDoAllProperty {
    return this.isBookedOnline
      .clone()
      .setMetadata({ id: this._pathWithPrefix('notBookedOnline') })
      .filterBy(new NullMeasureFilter(true));
  }

  get tags(): CanDoAllProperty {
    const measure = this.measureRef('tags');
    return MeasurePropertyFactory.array(
      {
        id: this._pathWithPrefix('tags'),
        label: 'Appointment Tags',
        summary: 'The appointment tags that have been added to the appointment',
        formatter: MeasureFormatter.Text,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'name'
    );
  }

  get date(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('event.from'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('date'),
        label: 'Appointment Date',
        summary:
          'Date that the appointment was performed. This is useful for grouping appointments by day.',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      MeasureFormatter.Day
    );
  }

  get startTime(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('event.from'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('startTime'),
        label: 'Start Time',
        summary: 'Time that the appointment started at',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      MeasureFormatter.Time,
      ISO_TIME_FORMAT
    );
  }

  get endTime(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('event.to'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('endTime'),
        label: 'End Time',
        summary: 'Time that the appointment ended at',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      MeasureFormatter.Time,
      ISO_TIME_FORMAT
    );
  }

  get treatmentCategory(): CanDoAllProperty {
    const treatmentCategory = 'treatmentPlan.treatmentStep.treatmentCategory';
    const labelMeasure = this.measureRef(`${treatmentCategory}.name`);
    const colourMeasure = this.measureRef(`${treatmentCategory}.colour.value`);
    const dataMeasure = this.measureRef(
      MeasurePath.docRef(`${treatmentCategory}.ref`)
    );

    return AppointmentDimensionMeasureFactory.treatmentCategory(
      {
        id: this._pathWithPrefix('treatmentCategory'),
        label: 'Primary Treatment Category',
        summary:
          'The primary treatment category of the appointment. While an appointment may have treatments from multiple treatment categories, this is the primary category that the appointment is associated with, as seen on the Timeline.',
      },
      labelMeasure,
      colourMeasure,
      dataMeasure,
      this.buildQuery()
        .attributes([
          labelMeasure.attributePath,
          colourMeasure.attributePath,
          dataMeasure.attributePath,
        ])
        .get()
    );
  }

  get isBooked(): CanDoAllProperty {
    const metadata: IMeasureMetadata = {
      id: this._pathWithPrefix('isBooked'),
      label: 'Is Booked',
      summary: '',
    };
    const isBooked = this.status
      .clone()
      .filterBy(
        new ValueEqualsMeasureFilter([
          AppointmentStatus.Scheduled,
          AppointmentStatus.Confirmed,
        ])
      );
    this._query = { ...this._query, ...isBooked };
    return new CanDoAllProperty({
      ...isBooked,
      metadata,
    });
  }
}

export class AppointmentDimensionMeasureFactory {
  static treatmentCategory(
    metadata: AtLeast<IMeasureMetadata, 'id' | 'label'>,
    labelMeasure: IMeasureRef,
    colourMeasure: IMeasureRef,
    dataMeasure: IMeasureRef,
    measureQuery: IReportingQuery,
    defaultCategory: string = 'No Category'
  ): CanDoAllProperty {
    return new CanDoAllProperty({
      metadata: defaultMetadata(metadata),
      measure: {
        propertyName: labelMeasure.attributePath,
        query: measureQuery,
        dataAccessor: DataAccessorFactory.property(
          labelMeasure.factPropertyPath,
          defaultCategory
        ),
        filterAccessor: basicFilterAccessor(dataMeasure.factPropertyPath),
      },
      groupMeasure: {
        queryAttributes: [
          labelMeasure.attributePath,
          colourMeasure.attributePath,
          dataMeasure.attributePath,
        ],
        dataAccessor: DataAccessorFactory.property(
          dataMeasure.factPropertyPath,
          defaultCategory
        ),
        labelAccessor: DataAccessorFactory.property(
          labelMeasure.factPropertyPath,
          defaultCategory
        ),
        colourAccessor: DataAccessorFactory.property(
          colourMeasure.factPropertyPath,
          '#CCC'
        ),
      },
    });
  }
}
