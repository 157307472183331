<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="wrapper flex flex-col gap-4">
  <ng-container *ngIf="treatmentConfig$ | async as treatmentConfig">
    <h1 class="mat-headline-4">{{ treatmentConfig.name }}</h1>

    <pr-edit-treatment-configuration
      [brand]="brand$ | async"
      [treatmentConfig]="treatmentConfig"
    />
  </ng-container>
</div>
