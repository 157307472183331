import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  isPairTerminalFail,
  isPairTerminalSuccess,
  type PairTerminalResponse,
} from '@principle-theorem/tyro';
import { type ITyroTerminal } from '@principle-theorem/principle-core/interfaces';
import { patchDoc, toTimestamp, type WithRef } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TyroService } from '@principle-theorem/ng-tyro';

export interface IPairTyroTerminalDialogRequest {
  terminal: WithRef<ITyroTerminal>;
}

@Component({
    selector: 'pr-pair-tyro-terminal-dialog',
    templateUrl: './pair-tyro-terminal-dialog.component.html',
    styleUrls: ['./pair-tyro-terminal-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PairTyroTerminalDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  log?: PairTerminalResponse;
  loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: IPairTyroTerminalDialogRequest,
    private _tyro: TyroService,
    private _cdr: ChangeDetectorRef
  ) {
    this._tyro
      .pairTerminal$(`${this._data.terminal.mid}`, `${this._data.terminal.tid}`)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((response) => void this._handleResponse(response));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private async _handleResponse(response: PairTerminalResponse): Promise<void> {
    this.loading =
      !isPairTerminalSuccess(response) && !isPairTerminalFail(response);
    this.log = response;
    this._cdr.detectChanges();

    if (isPairTerminalSuccess(response)) {
      await patchDoc(this._data.terminal.ref, {
        lastPaired: toTimestamp(),
        integrationKey: response.integrationKey,
      });
    }
  }
}
