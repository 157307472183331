import {
  Component,
  Input,
  forwardRef,
  HostBinding,
  type OnDestroy,
} from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pt-colour-picker',
  templateUrl: './colour-picker.component.html',
  styleUrls: ['./colour-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColourPickerComponent),
      multi: true,
    },
  ],
})
export class ColourPickerComponent implements ControlValueAccessor, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private _changed$: Subject<string> = new Subject();
  private _touched$: Subject<void> = new Subject();
  private _colour = '#123123';
  @Input() format = 'hex';
  @Input() alphaEnabled = false;
  @Input() inputHidden = false;

  @HostBinding('class.disabled')
  isDisabled = false;

  set colour(colour: string) {
    this._colour = colour;
    this._changed$.next(colour);
    this._touched$.next();
  }

  get colour(): string {
    return this._colour;
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._changed$.complete();
    this._touched$.complete();
  }

  writeValue(colour: string | undefined): void {
    if (colour) {
      this.colour = colour;
    }
  }

  registerOnChange(fn: () => void): void {
    this._changed$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this._touched$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  handleClick($event: MouseEvent): void {
    if (this.isDisabled) {
      $event.stopPropagation();
    }
  }
}
