import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  Optional,
  Self,
  type AfterViewInit,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import { Storage } from '@angular/fire/storage';
import { NgControl, type ControlValueAccessor } from '@angular/forms';
import {
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import {
  MixedSchema,
  getSchemaSize,
  initVersionedSchema,
} from '@principle-theorem/editor';
import { BasicMenuButtonComponent } from '@principle-theorem/ng-editor';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import {
  NgFireMediaUploader,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { filterUndefined } from '@principle-theorem/shared';
import { AnyExtension } from '@tiptap/core';
import { noop } from 'lodash';
import { Subject, type Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditorPresetsService } from '../../editor/editor-presets.service';

export interface IStorageProvider {
  storagePath$: Observable<string>;
}

export type EditorFormat = 'email' | 'html' | 'text';

@Component({
  selector: 'pr-content-editor',
  templateUrl: './content-editor.component.html',
  styleUrls: ['./content-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MatFormFieldControl, useExisting: ContentEditorComponent },
  ],
})
export class ContentEditorComponent
  implements
    MatFormFieldControl<MixedSchema>,
    ControlValueAccessor,
    AfterViewInit,
    OnInit,
    OnDestroy
{
  static nextId = 0;

  private _empty = true;
  private _required = false;
  private _disabled = false;
  private _placeholder = '';
  private _onDestroy$ = new Subject<void>();

  @HostBinding('class.flex-1') isFlex = true;
  controlType = 'content-editor';
  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;

  formControl = new TypedFormControl<MixedSchema>(initVersionedSchema());
  @Input()
  extensions: AnyExtension[];
  @Input() menuItems: MenuButtonLoaderFn[] = [];
  @Input() slashMenuItems: MenuButtonLoaderFn<BasicMenuButtonComponent>[] = [];
  uploader: NgFireMediaUploader;
  @HostBinding('class.menu-padding')
  @Input()
  menuEnabled = true;
  @Input()
  format: EditorFormat = 'html';

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;

  @HostBinding()
  id = `content-editor-${ContentEditorComponent.nextId++}`;

  constructor(
    private _storage: Storage,
    private _editorPresets: EditorPresetsService,
    organisation: OrganisationService,
    @Optional() @Self() public ngControl: NgControl,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private _cdr: ChangeDetectorRef,
    @Optional() public parentFormField: MatFormField
  ) {
    this.extensions = this._editorPresets.defaultExtensions();
    this.uploader = new NgFireMediaUploader(
      this._storage,
      organisation.storagePath$.pipe(filterUndefined())
    );
    this.menuItems = this._editorPresets.defaultMenuItems(this.uploader);
    this.slashMenuItems = this._editorPresets.blockMenuItems(this.uploader);

    // eslint-disable-next-line no-null/no-null
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }

    this._focusMonitor
      .monitor(this._elementRef.nativeElement, true)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((origin) => {
        this.focused = !!origin;
        this.stateChanges.next();
      });

    this.formControl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => (this._empty = getSchemaSize(value) === 0));
  }

  set value(content: MixedSchema | null) {
    if (content) {
      this.formControl.setValue(content);
    }
    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  ngOnInit(): void {
    if (this.format === 'email') {
      this.extensions = this._editorPresets.defaultToHTMLExtensions();
    }
  }

  // eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
  onTouched = (): void => {};

  ngAfterViewInit(): void {
    this._cdr.detectChanges();
  }

  writeValue(content: MixedSchema): void {
    if (!content) {
      return;
    }
    this.formControl.setValue(content);
    this.stateChanges.next();
    this._cdr.detectChanges();
  }

  registerOnChange(fn: () => void): void {
    this.formControl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get empty(): boolean {
    return this._empty;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef.nativeElement);
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector(
      `.${this.controlType}-container`
    );
    if (!controlElement) {
      return;
    }
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_event: MouseEvent): void {
    noop();
  }
}
