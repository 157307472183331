import {
  FormStatus,
  ISubmittedFormHistory,
  SubmittedFormCollection,
} from '@principle-theorem/principle-core/interfaces';
import {
  ArchivedDocument,
  WithRef,
  query$,
  sortTimestampAsc,
  subCollection,
  toTimestamp,
  undeletedQuery,
} from '@principle-theorem/shared';
import { CollectionReference, Timestamp } from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class SubmittedForm {
  static archiveCol(
    form: WithRef<ISubmittedFormHistory>
  ): CollectionReference<ArchivedDocument<ISubmittedFormHistory>> {
    return subCollection<ArchivedDocument<ISubmittedFormHistory>>(
      form.ref,
      SubmittedFormCollection.SubmittedFormHistory
    );
  }

  static archivedSubmittedForms$(
    form: WithRef<ISubmittedFormHistory>
  ): Observable<WithRef<ArchivedDocument<ISubmittedFormHistory>>[]> {
    return query$(undeletedQuery(SubmittedForm.archiveCol(form))).pipe(
      map((forms) =>
        forms
          .sort((a, b) => sortTimestampAsc(a.form.date, b.form.date))
          .reverse()
      )
    );
  }

  static updateStatus<T extends ISubmittedFormHistory>(
    submittedForm: T,
    status: FormStatus,
    updatedAt: Timestamp = toTimestamp()
  ): T {
    if (status === FormStatus.Confirmed) {
      submittedForm.form.disableChanges = true;
    }

    submittedForm.status = status;
    submittedForm.statusHistory.push({
      status,
      updatedAt,
    });
    return submittedForm;
  }
}
