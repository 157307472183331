import {
  ChartableSurface,
  IExpectedSourceRecordSize,
  SourceEntityMigrationType,
  ToothNumber,
  type IPracticeMigration,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  Timezone,
  TypeGuard,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { flow, groupBy, isBoolean, isNull, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { runQuery } from '../../../source/connection';
import { SourceEntity } from '../../../source/source-entity';
import { EXACT_DATE_TIME_FORMAT, convertExactId } from '../../util/helpers';
import { exactPerioToothLabelConverter } from '../../util/tooth';
import { PatientSourceEntity } from './patient';
import { OFFSET_PLACEHOLDER } from '../../../source/source-helpers';

export const PATIENT_PERIO_EXAMS_RESOURCE_TYPE = 'patientPerioExams';

export const PATIENT_PERIO_EXAMS_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Perio Exams List',
      description: '',
      idPrefix: PATIENT_PERIO_EXAMS_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

enum ExactPerioRecord {
  PerioProbe = 'PerioProbe',
  ConvPerioExam = 'ConvPerioExam',
  PerioProbe4Point = 'PerioProbe4Point',
}

export interface IExactPerioExamResult {
  id: string;
  exam_date: string;
  provider_id: string | null;
  record_type: ExactPerioRecord;
  patient_id: string;
  tooth: string;
  probe_skipped: boolean;
  mobility: string | null;
  tooth_side: ExactPerioToothSide;
  central_bleeding: boolean;
  central_cal: number | null;
  central_furcationgrade: number | null;
  central_gingivalmargin: number | null;
  central_pocketdepth: number | null;
  central_suppuration: boolean;
  central_plaque: boolean;
  distal_bleeding: boolean;
  distal_cal: number | null;
  distal_furcationgrade: number | null;
  distal_gingivalmargin: number | null;
  distal_pocketdepth: number | null;
  distal_suppuration: boolean;
  distal_plaque: boolean;
  mesial_bleeding: boolean;
  mesial_cal: number | null;
  mesial_furcationgrade: number | null;
  mesial_gingivalmargin: number | null;
  mesial_pocketdepth: number | null;
  mesial_suppuration: boolean;
  mesial_plaque: boolean;
  probe_boneloss: string | null;
}

export interface IExactPerioExam
  extends Omit<IExactPerioExamResult, 'mobility' | 'tooth'> {
  mobility: number | null;
  tooth: ToothNumber;
}

export interface IExactPatientPerioChart {
  id: string;
  patient_id: string;
  provider_id: string | null;
  exam_date: string;
  records: IExactPerioExam[];
}

type ExactPerioToothSide = ChartableSurface.Facial | ChartableSurface.Lingual;

function isExactPerioToothSide(item: unknown): item is ExactPerioToothSide {
  return [ChartableSurface.Facial, ChartableSurface.Lingual].includes(
    item as ChartableSurface
  );
}

export function isExactPerioChart(
  item: unknown
): item is IExactPatientPerioChart {
  return TypeGuard.interface<IExactPatientPerioChart>({
    id: isString,
    patient_id: isString,
    exam_date: isString,
    provider_id: [isString, isNull],
    records: TypeGuard.arrayOf(isExactPerioExam),
  })(item);
}

export function isExactPerioExam(item: unknown): item is IExactPerioExam {
  return TypeGuard.interface<IExactPerioExam>({
    id: isString,
    exam_date: isString,
    provider_id: [isString, isNull],
    record_type: TypeGuard.enumValue(ExactPerioRecord),
    patient_id: isString,
    tooth: isString,
    probe_skipped: isBoolean,
    mobility: [isNumber, isNull],
    tooth_side: isExactPerioToothSide,
    central_bleeding: isBoolean,
    central_cal: [isNumber, isNull],
    central_furcationgrade: [isNumber, isNull],
    central_gingivalmargin: [isNumber, isNull],
    central_pocketdepth: [isNumber, isNull],
    central_suppuration: isBoolean,
    central_plaque: isBoolean,
    distal_bleeding: isBoolean,
    distal_cal: [isNumber, isNull],
    distal_furcationgrade: [isNumber, isNull],
    distal_gingivalmargin: [isNumber, isNull],
    distal_pocketdepth: [isNumber, isNull],
    distal_suppuration: isBoolean,
    distal_plaque: isBoolean,
    mesial_bleeding: isBoolean,
    mesial_cal: [isNumber, isNull],
    mesial_furcationgrade: [isNumber, isNull],
    mesial_gingivalmargin: [isNumber, isNull],
    mesial_pocketdepth: [isNumber, isNull],
    mesial_suppuration: isBoolean,
    mesial_plaque: isBoolean,
    probe_boneloss: [isString, isNull],
  })(item);
}

export interface IExactPerioChartTranslations {
  examDate: Timestamp;
}

export interface IExactPerioChartFilters {
  patientId: string;
  examDate: Timestamp;
}

const PERIO_EXAM_ESTIMATE_QUERY = `
SELECT convperioexam_exam_sourceid
FROM convperioexam
WHERE recordtype = '${ExactPerioRecord.ConvPerioExam}'
`;

const PERIO_EXAM_SOURCE_QUERY = `
WITH limited_exam AS (
  SELECT convperioexam_exam_sourceid
  FROM convperioexam
  WHERE recordtype = '${ExactPerioRecord.ConvPerioExam}'
  ORDER BY convert_to_integer(REGEXP_REPLACE(convperioexam_exam_sourceid, ':.*$', ''))
  ${OFFSET_PLACEHOLDER}
)
SELECT
  perioprobe.convperioexam_exam_sourceid::TEXT AS id,
  perioprobe.convperioexam_exam_servicedate AS exam_date,
  NULLIF(perioprobe.convperioexam_exam_providerid::TEXT, '') AS provider_id,
  perioprobe.convperioexam_exam_patientid::TEXT AS patient_id,
  perioprobe.recordtype AS record_type,
  NULLIF(perioprobe.perioprobe_probe_tooth, '') AS tooth,
  perioprobe.perioprobe_probe_skipped AS probe_skipped,
  NULLIF(perioprobe.perioprobe_probe_mobility, '') AS mobility,
  NULLIF(LOWER(perioprobe.perioprobe_probe_toothside), '') AS tooth_side,
  perioprobe.perioprobeatlocation_central_bleeding AS central_bleeding,
  perioprobe.perioprobeatlocation_central_cal AS central_cal,
  perioprobe.perioprobeatlocation_central_furcationgrade AS central_furcationgrade,
  perioprobe.perioprobeatlocation_central_gingivalmargin AS central_gingivalmargin,
  perioprobe.perioprobeatlocation_central_pocketdepth AS central_pocketdepth,
  perioprobe.perioprobeatlocation_central_suppuration AS central_suppuration,
  convert_to_boolean(perioprobe.perioprobeatlocation_central_plaque) AS central_plaque,
  perioprobe.perioprobeatlocation_distal_bleeding AS distal_bleeding,
  perioprobe.perioprobeatlocation_distal_cal AS distal_cal,
  perioprobe.perioprobeatlocation_distal_furcationgrade AS distal_furcationgrade,
  perioprobe.perioprobeatlocation_distal_gingivalmargin AS distal_gingivalmargin,
  perioprobe.perioprobeatlocation_distal_pocketdepth AS distal_pocketdepth,
  perioprobe.perioprobeatlocation_distal_suppuration AS distal_suppuration,
  convert_to_boolean(perioprobe.perioprobeatlocation_distal_plaque) AS distal_plaque,
  perioprobe.perioprobeatlocation_mesial_bleeding AS mesial_bleeding,
  perioprobe.perioprobeatlocation_mesial_cal AS mesial_cal,
  perioprobe.perioprobeatlocation_mesial_furcationgrade AS mesial_furcationgrade,
  perioprobe.perioprobeatlocation_mesial_gingivalmargin AS mesial_gingivalmargin,
  perioprobe.perioprobeatlocation_mesial_pocketdepth AS mesial_pocketdepth,
  perioprobe.perioprobeatlocation_mesial_suppuration AS mesial_suppuration,
  convert_to_boolean(perioprobe.perioprobeatlocation_mesial_plaque) AS mesial_plaque,
  NULLIF(perioprobe.perioprobe_probe_boneloss::TEXT, '') AS probe_boneloss
FROM
  limited_exam exam
LEFT JOIN
  convperioexam perioprobe
ON
  exam.convperioexam_exam_sourceid = perioprobe.convperioexam_exam_sourceid
  AND perioprobe.recordtype != '${ExactPerioRecord.PerioProbe4Point}'
ORDER BY
	convert_to_integer(REGEXP_REPLACE(exam.convperioexam_exam_sourceid, ':.*$', '')),
	convert_to_integer(REGEXP_REPLACE(perioprobe.convperioexam_exam_sourceid, ':.*$', ''))
`;

export class PatientPerioExamsSourceEntity extends BaseSourceEntity<
  IExactPatientPerioChart,
  IExactPerioChartTranslations,
  IExactPerioChartFilters
> {
  sourceEntity = PATIENT_PERIO_EXAMS_SOURCE_ENTITY;
  entityResourceType = PATIENT_PERIO_EXAMS_RESOURCE_TYPE;
  sourceQuery = PERIO_EXAM_SOURCE_QUERY;
  allowOffsetJob = true;
  verifySourceFn = isExactPerioChart;
  override defaultOffsetSize = 5000;
  override estimateQuery = PERIO_EXAM_ESTIMATE_QUERY;

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  override transformDataFn = flow([transformPerioResult]);

  getSourceRecordId(data: IExactPatientPerioChart): string {
    return `${data.patient_id} ${data.id}`;
  }

  getSourceLabel(data: IExactPatientPerioChart): string {
    return `${data.patient_id} ${data.id}`;
  }

  translate(
    data: IExactPatientPerioChart,
    timezone: Timezone
  ): IExactPerioChartTranslations {
    return {
      examDate: toTimestamp(
        moment.tz(data.exam_date, EXACT_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getFilterData(
    data: IExactPatientPerioChart,
    timezone: Timezone
  ): IExactPerioChartFilters {
    return {
      patientId: data.patient_id,
      examDate: toTimestamp(
        moment.tz(data.exam_date, EXACT_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const response = await runQuery<{ count: number }>(
      migration,
      `SELECT COUNT(*) FROM (
        SELECT * FROM convperioexam WHERE recordtype = '${ExactPerioRecord.ConvPerioExam}'
      ) src`
    );
    return {
      expectedSize: response.rows[0].count,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }
}

function transformPerioResult(
  rows: IExactPerioExamResult[]
): IExactPatientPerioChart[] {
  const perioChartGroups = groupBy(rows, (result) => result.id);

  return Object.values(perioChartGroups).map((perioGroup) => {
    const examRecord = perioGroup.findIndex(
      (result) => result.record_type === ExactPerioRecord.ConvPerioExam
    );
    const examRecordIndex = examRecord !== -1 ? examRecord : 0;
    const records = perioGroup
      .filter((item) => item.record_type === ExactPerioRecord.PerioProbe)
      .map((item) => ({
        ...item,
        mobility: convertMobility(item.mobility),
        tooth: exactPerioToothLabelConverter(item.tooth),
      }));

    return {
      id: convertExactId(perioGroup[examRecordIndex].id),
      patient_id: convertExactId(perioGroup[examRecordIndex].patient_id),
      provider_id: perioGroup[examRecordIndex].provider_id,
      exam_date: perioGroup[examRecordIndex].exam_date,
      records,
    };
  });
}

function convertMobility(mobility: string | null): number | null {
  switch (mobility) {
    case 'Class1':
      return 1;
    case 'Class2':
      return 2;
    case 'Class3':
      return 3;
    case 'None':
      return 0;
    default:
      // eslint-disable-next-line no-null/no-null
      return null;
  }
}
