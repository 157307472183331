import {
  AutomationType,
  IAutomatedFormIssue,
  IAutomatedFormIssueConfiguration,
  IAutomation,
  IAutomationConfiguration,
  IBrand,
  IEvent,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  INamedTypeDocument,
  Timestamp,
  WithRef,
} from '@principle-theorem/shared';
import { Automation } from '../automation/automation';
import { AutomationTiming } from '../automation/automation-timing';

export class AutomatedFormIssue {
  static async toAutomation(
    notification: IAutomatedFormIssue,
    creator: INamedTypeDocument,
    event: IEvent,
    brandRef: DocumentReference<IBrand>,
    configRef?: DocumentReference<IAutomationConfiguration>
  ): Promise<IAutomation<IAutomatedFormIssue>> {
    return Automation.init({
      type: AutomationType.AutomatedFormIssue,
      data: notification,
      creator,
      triggerDate: await AutomationTiming.resolveTriggerDateFromEvent(
        event,
        notification.timing
      ),
      brandRef,
      practiceRef: event.practice.ref,
      configRef,
    });
  }

  static async generateFromConfig(
    config: WithRef<IAutomatedFormIssueConfiguration>,
    creator: INamedTypeDocument,
    event: IEvent,
    brandRef: DocumentReference<IBrand>,
    triggerAfterDate?: Timestamp
  ): Promise<IAutomation<IAutomatedFormIssue>> {
    return Automation.init({
      type: AutomationType.AutomatedFormIssue,
      data: getAutomatedFormIssueFromConfig(config),
      creator,
      triggerDate: await AutomationTiming.resolveTriggerDateFromEvent(
        event,
        config.timing
      ),
      triggerAfterDate,
      brandRef,
      practiceRef: event.practice.ref,
      configRef: config.ref,
    });
  }
}

export function getAutomatedFormIssueFromConfig(
  config: IAutomatedFormIssueConfiguration
): IAutomatedFormIssue {
  return {
    name: config.name,
    timing: config.timing,
    requiredConditions: config.requiredConditions,
    formConfigurationRef: config.formConfigurationRef,
  };
}
