import { TaskMock } from '@principle-theorem/principle-core';
import { ITaskDimension } from '@principle-theorem/reporting/interfaces';
import {
  MockGenerator,
  toSerialisedTimestamp,
  toTimestamp,
} from '@principle-theorem/shared';
import {
  AvroSerialisedRawInlineNodesMock,
  AvroSerialisedVersionedSchemaMock,
  BigQueryFirestoreModelMock,
  BigQueryInteractionMock,
  BigQueryNamedDocumentMock,
  MockBigQueryStatusHistory,
  SerialisedDocumentReferenceMock,
} from './common.mock';

const task = MockGenerator.generate(TaskMock);

export class TaskDimensionMock
  extends BigQueryFirestoreModelMock
  implements ITaskDimension
{
  timestamp = toSerialisedTimestamp(toTimestamp());
  description = MockGenerator.generate(AvroSerialisedVersionedSchemaMock);
  priority = task.priority;
  status = task.status;
  statusHistory = task.statusHistory.map((item) =>
    MockBigQueryStatusHistory(item.status, item.updatedAt)
  );
  type = task.type;
  recurrenceConfiguration = MockGenerator.generate(
    SerialisedDocumentReferenceMock
  );
  assignedUser = MockGenerator.generate(BigQueryNamedDocumentMock);
  assignedTeam = MockGenerator.generate(BigQueryNamedDocumentMock);
  owner = MockGenerator.generate(BigQueryNamedDocumentMock);
  visibleFrom = toSerialisedTimestamp(task.visibleFrom);
  title = MockGenerator.generate(AvroSerialisedRawInlineNodesMock);
  dueDate = toSerialisedTimestamp(task.dueDate);
  completedDate = toSerialisedTimestamp(task.completedDate);
  interactions = [
    MockGenerator.generate(BigQueryInteractionMock),
    MockGenerator.generate(BigQueryInteractionMock),
    MockGenerator.generate(BigQueryInteractionMock),
  ];
}
