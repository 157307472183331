import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { shareReplayCold } from '@principle-theorem/shared';

type IpApiResponse = { ip: string };

@Injectable({
  providedIn: 'root',
})
export class UserPublicIpService {
  private _primaryApiUrl = 'https://api.ipify.org?format=json';
  private _backupApiUrl = 'https://api.seeip.org/jsonip?';
  private _cachedIp?: string;
  private _lastCachedAt?: moment.Moment;
  private _cacheDuration = moment.duration(5, 'minutes');
  private _requestTimeout = moment.duration(3, 'seconds');

  constructor(private _http: HttpClient) {}

  getIpAddress$(): Observable<string | undefined> {
    if (this._ipIsCached()) {
      return of(this._cachedIp);
    }

    return this._fetchIpAddress$(this._primaryApiUrl).pipe(
      catchError(() => this._fetchIpAddress$(this._backupApiUrl)),
      tap((ip) => {
        if (ip) {
          this._cachedIp = ip;
          this._lastCachedAt = moment();
        }
      }),
      catchError(() => EMPTY),
      shareReplayCold()
    );
  }

  private _fetchIpAddress$(url: string): Observable<string> {
    return this._http.get<IpApiResponse>(url).pipe(
      timeout(this._requestTimeout.asMilliseconds()),
      map((response) => response.ip),
      catchError((error) => {
        // eslint-disable-next-line no-console
        console.error(`Failed to get IP address from ${url}`, error);
        return throwError(error);
      })
    );
  }

  private _ipIsCached(): boolean {
    return (
      !!this._cachedIp &&
      !!this._lastCachedAt &&
      moment().diff(this._lastCachedAt) < this._cacheDuration.asMilliseconds()
    );
  }
}
