<ng-container *ngrxLet="patient$ as patient">
  <ng-container *ngrxLet="appointment$ as appointment">
    <mat-tab-group
      #mainTabGroup="matTabGroup"
      mat-align-tabs="center"
      class="sticky flex-1"
      [disablePagination]="true"
      (selectedTabChange)="onTabChange($event)"
    >
      <mat-tab>
        <ng-template matTabLabel>Appointment</ng-template>
        <div class="flex flex-col gap-2 p-4" #sidebarContainer>
          <pr-content-container>
            <div class="flex flex-col gap-2">
              <div class="flex items-center justify-start gap-4">
                <h2>Patient</h2>
                <a
                  *ngIf="!appointment"
                  href="javascript:void(0);"
                  class="action-link"
                  (click)="createNewPatient()"
                >
                  <mat-icon>add</mat-icon> Create New Patient
                </a>
                <a
                  *ngIf="patient"
                  href="javascript:void(0);"
                  class="action-link"
                  (click)="mainTabGroup.selectedIndex = 2"
                >
                  <mat-icon>edit</mat-icon> Edit Patient
                </a>
                <a
                  *ngIf="patient && !appointment"
                  href="javascript:void(0);"
                  class="action-link"
                  (click)="clearPatient()"
                >
                  <mat-icon>clear</mat-icon> Clear Patient
                </a>
              </div>

              @if (!patient) {
                <pr-patient-selector
                  class="w-full"
                  cdkFocusInitial
                  placeholder="Search for Patient"
                  [formControl]="patientSelectorCtrl"
                  (clear)="clearPatient()"
                />
              } @else {
                <pr-patient-profile-summary
                  class="w-full"
                  [patient]="patient"
                  [actions]="patientActions"
                />
              }
            </div>
          </pr-content-container>

          <div #appointmentSelectionContainer>
            <pr-content-container>
              <pr-appointment-details-sidebar
                [appointmentDetails]="appointmentDetails$ | async"
                [practitioners]="practitioners$ | async"
                [patient]="patient$ | async"
                [practices]="practices$ | async"
                (appointmentDetailsChange)="updateAppointmentDetails($event)"
                (showAppointmentOptions)="
                  showAppointmentOptions(appointmentSelectionContainer)
                "
              />
            </pr-content-container>
          </div>

          <div *ngrxLet="treatmentStepPair$ as treatmentStepPair">
            <pr-content-container>
              <pr-appointment-treatment-selector
                [treatmentStep]="treatmentStepPair?.step"
                [treatmentPlan]="treatmentStepPair?.plan"
              />
            </pr-content-container>
          </div>

          <pr-content-container padding="p-0">
            <mat-tab-group class="menu-tabs" [disablePagination]="true">
              <mat-tab>
                <ng-template matTabLabel>
                  <mat-icon matTooltip="Scheduled Communications"
                    >schedule_send</mat-icon
                  >
                  <div
                    [ngClass]="{
                      'has-notification': hasAutomations$ | async
                    }"
                  ></div>
                </ng-template>
                <div class="p-4">
                  @if (treatmentStepPair$ | async; as treatmentStepPair) {
                    <pr-appointment-automations-reschedule
                      class="step-content"
                      [treatmentStep]="treatmentStepPair.step"
                      [newEvent]="selectedEvent$ | async"
                      [patient]="patient$ | async"
                    />
                  } @else {
                    <div class="my-4 flex items-center justify-center">
                      <span class="mat-caption">
                        Please complete above steps to start adding automations
                      </span>
                    </div>
                  }
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template matTabLabel>
                  <mat-icon matTooltip="Wait List">pending_actions</mat-icon>
                  <div
                    [ngClass]="{
                      'has-notification': isOnWaitList$ | async
                    }"
                  ></div>
                </ng-template>
                <div class="p-4">
                  <pr-waitlist-configuration
                    [waitlistDetails]="waitlistDetails$ | async"
                    [event]="selectedEvent$ | async"
                    [openTime]="openTime$ | async"
                    [closeTime]="closeTime$ | async"
                    (waitListChange)="updateWaitList($event)"
                  />
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template matTabLabel>
                  <mat-icon matTooltip="Checklist Items"
                    >playlist_add_check</mat-icon
                  >
                  <div
                    [ngClass]="{
                      'has-notification': hasChecklistItems$ | async
                    }"
                  ></div>
                </ng-template>
                <div class="p-4">
                  <pr-checklist-form
                    [appointment]="appointment"
                    [checklists]="checklists$ | async"
                    (checklistsUpdated)="updateChecklists($event)"
                  />
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template matTabLabel>
                  <mat-icon matTooltip="Appointment Tags">label</mat-icon>
                  <div
                    [ngClass]="{
                      'has-notification': hasTags$ | async
                    }"
                  ></div>
                </ng-template>
                <div class="p-4">
                  <pr-tags-input
                    [formControl]="appointmentTags"
                    [tagCol]="tagCol$ | async"
                    [tagType]="tagType"
                  />
                </div>
              </mat-tab>
            </mat-tab-group>
          </pr-content-container>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template matTabLabel>Notes</ng-template>
        <ng-template matTabContent>
          <pr-scheduling-notes-tab
            class="box-border flex h-full min-h-full w-full flex-col"
            [patient]="patient"
          />
        </ng-template>
      </mat-tab>
      <mat-tab #patientTab="matTab">
        <ng-template matTabLabel>
          <span>Patient</span>
          <mat-icon
            *ngIf="(validPatientDetails$ | async) === false"
            class="has-notification"
            matTooltip="Missing Patient Details"
            color="warn"
          >
            warning
          </mat-icon>
        </ng-template>
        <div class="flex flex-col gap-4">
          <pr-scheduling-patient-tab
            #patientDetails="prSchedulingPatientTab"
            [patient]="patient"
            [newPatientName]="newPatientName$ | async"
            [tabGroup]="mainTabGroup"
          />
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</ng-container>

<pt-dynamic-sidebar-footer class="flex flex-col items-end gap-1">
  <div
    *ngIf="saveDisabledMessage$ | async as message"
    class="flex items-center"
  >
    <mat-icon color="warn" class="text-sm opacity-60">warning</mat-icon>
    <span class="mat-caption warn">
      {{ message }}
    </span>
  </div>
  <div class="flex flex-row gap-2">
    @if (requiresReschedulingReason$ | async) {
      <pr-scheduling-event-reason-selector
        class="flex"
        [formControl]="reasonControl"
        [reasons]="reasons$ | async"
        [schedulingConditions]="schedulingConditions$ | async"
      />
    }
    <pt-loader-button
      class="h-full"
      type="flat"
      [loading]="saving$ | async"
      [disabled]="(canSave$ | async) === false"
      color="primary"
      (clicked)="save()"
    >
      Save Appointment
    </pt-loader-button>
  </div>
</pt-dynamic-sidebar-footer>
