export interface IFilterOption<
  K extends string = string,
  T = Record<string, unknown>
> {
  id: K;
  label: string;
  filter: (item: T) => boolean;
}

export const DEFAULT_FILTER: IFilterOption = {
  id: '',
  label: '',
  filter: () => true,
};
