import { Pipe, type PipeTransform } from '@angular/core';
import { Invoice } from '@principle-theorem/principle-core';
import { type IInvoice } from '@principle-theorem/principle-core/interfaces';
import { type IReffable } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { StateBasedNavigationService } from '../navigation/state-based-navigation.service';

@Pipe({
    name: 'invoiceUrl$',
    standalone: false
})
export class InvoiceUrlPipe implements PipeTransform {
  constructor(private _stateNav: StateBasedNavigationService) {}

  transform(invoice: IReffable<IInvoice>): Observable<string[]> {
    return this._stateNav.link.brand$([
      'patients',
      Invoice.patientDocRef(invoice).id,
      'account',
      'invoices',
      invoice.ref.id,
    ]);
  }
}
