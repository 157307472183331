import {
  type AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import {
  FirebaseTable,
  type ICreateConfigurationData,
} from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  type IMultiTreatmentConfiguration,
  type ITreatmentTemplate,
} from '@principle-theorem/principle-core/interfaces';
import {
  type CollectionReference,
  type DocumentReference,
} from '@principle-theorem/shared';
import {
  all$,
  multiSortBy$,
  nameSorter,
  query$,
  snapshot,
  undeletedQuery,
  type WithRef,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  combineLatest,
  type Observable,
  ReplaySubject,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CreateMultiTreatmentConfigurationComponent } from '../create-multi-treatment-configuration/create-multi-treatment-configuration.component';

@Component({
  selector: 'pr-multi-treatment-configuration-list',
  templateUrl: './multi-treatment-configuration-list.component.html',
  styleUrls: ['./multi-treatment-configuration-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiTreatmentConfigurationListComponent
  extends FirebaseTable<IMultiTreatmentConfiguration>
  implements AfterViewInit
{
  private _collection$ = new ReplaySubject<
    CollectionReference<IMultiTreatmentConfiguration>
  >(1);
  @Input() basePath?: string;
  override displayColumns: string[] = ['icon', 'name'];
  override pageSize = 50;
  override pageSizeOptions = [50, 100, 200];
  treatmentTemplates: WithRef<ITreatmentTemplate>[] = [];
  emptyState$: Observable<boolean>;
  emptySearch$ = new BehaviorSubject<boolean>(false);
  @ViewChild(MatPaginator) declare paginator: MatPaginator;

  constructor(
    private _dialog: MatDialog,
    private _router: Router
  ) {
    super();
    this.emptyState$ = combineLatest([
      this._collection$.pipe(switchMap((collection) => all$(collection))),
      this.dataSource.loading$,
    ]).pipe(
      map(([configurations, loading]) => !configurations.length && !loading)
    );
  }

  @Input()
  set collection(
    collection: CollectionReference<IMultiTreatmentConfiguration>
  ) {
    if (collection) {
      this._collection$.next(collection);
      this.dataSource.items$ = query$(undeletedQuery(collection)).pipe(
        multiSortBy$(nameSorter())
      );
    }
  }

  ngAfterViewInit(): void {
    this.initSortAndPagination();
  }

  async createMultiTreatmentConfig(): Promise<void> {
    const collection = await snapshot(this._collection$);
    if (!collection) {
      return;
    }

    const data: ICreateConfigurationData<IMultiTreatmentConfiguration> = {
      collection,
    };

    const docRef = await this._dialog
      .open<
        CreateMultiTreatmentConfigurationComponent,
        ICreateConfigurationData<IMultiTreatmentConfiguration>,
        DocumentReference
      >(
        CreateMultiTreatmentConfigurationComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!docRef) {
      return;
    }

    await this._router.navigate(this.getRoute(docRef.id));
  }

  async searchMultiTreatmentConfigurations(value: string): Promise<void> {
    this.applyFilter(value);
    const emptyState = await snapshot(this.emptyState$);
    !emptyState && !this.dataSource.filteredData.length
      ? this.emptySearch$.next(true)
      : this.emptySearch$.next(false);
  }

  getRoute(uid: string): string[] {
    return [this.basePath || this._router.url, uid];
  }
}
