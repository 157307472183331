import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type IStaffer } from '@principle-theorem/principle-core/interfaces';
import { type WithRef, isSameRef } from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { type Observable, ReplaySubject, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GlobalStoreService } from '../../store/global-store.service';
import { MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'pr-assignee-profile-selector',
  templateUrl: './assignee-profile-selector.component.html',
  styleUrls: ['./assignee-profile-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssigneeProfileSelectorComponent {
  trackByStaffer = TrackByFunctions.ref<WithRef<IStaffer>>();
  staff$ = new ReplaySubject<WithRef<IStaffer>[]>(1);
  stafferName$: Observable<string>;
  stafferImage$: Observable<string | undefined>;
  selectedStaffer$ = new ReplaySubject<DocumentReference<IStaffer> | undefined>(
    1
  );
  @Output() stafferSelected = new EventEmitter<WithRef<IStaffer> | undefined>();

  @Input() disabled = false;
  @Input() disableTooltip = false;
  @Input() multiSelect = false;
  @Input() selectTooltip = 'Select Assignee';
  @Input() clearTooltip = 'Clear Assignee';
  @Input() disableClear = false;

  @Input()
  set selectedStaffer(
    selectedStaffer: DocumentReference<IStaffer> | undefined
  ) {
    this.selectedStaffer$.next(selectedStaffer);
  }

  @Input()
  set staff(staff: WithRef<IStaffer>[]) {
    if (staff) {
      this.staff$.next(staff);
    }
  }

  constructor(globalStore: GlobalStoreService) {
    this.stafferImage$ = this.selectedStaffer$.pipe(
      switchMap((staffer) =>
        staffer ? globalStore.getStafferImage$({ ref: staffer }) : of(undefined)
      )
    );
    this.stafferName$ = this.selectedStaffer$.pipe(
      switchMap((staffer) =>
        staffer ? globalStore.getStafferName$({ ref: staffer }) : of('')
      )
    );
  }

  selectStaffer(staffList: MatSelectionList): void {
    const staffer = staffList.selectedOptions.selected[0]?.value as
      | WithRef<IStaffer>
      | undefined;
    this.stafferSelected.emit(staffer);
    this.selectedStaffer$.next(staffer?.ref);
  }

  clearAssignee(): void {
    this.stafferSelected.emit(undefined);
    this.selectedStaffer$.next();
  }

  isSelected$(staffer: WithRef<IStaffer>): Observable<boolean> {
    return this.selectedStaffer$.pipe(
      map((selectedStaffer) => isSameRef(selectedStaffer, staffer))
    );
  }
}
