import {
  ITimelineDisplayOptions,
  TimelineDisplayOrientation,
  DEFAULT_PRACTICE_TIMELINE_TOOLTIP_DELAY,
  TimelineDisplayZoom,
  ITimelineDisplayRange,
} from './interactive-timeline-display-options';

export const DEFAULT_STEP_SIZE_IN_MINS = 5;
export const DEFAULT_HOUR_INCREMENT = 15;
export const DEFAULT_GUIDELINE_INCREMENT_IN_MINS = 60;

export class TimelineDisplayOptionsPresets {
  static default(
    data: Partial<ITimelineDisplayOptions & ITimelineDisplayRange>
  ): ITimelineDisplayOptions {
    return {
      orientation: TimelineDisplayOrientation.Vertical,
      guidelineIncrementInMins: DEFAULT_GUIDELINE_INCREMENT_IN_MINS,
      stepSizeInMins: DEFAULT_STEP_SIZE_IN_MINS,
      stepSizeInPixels: 15,
      trackSizeInPixels: 60,
      minimumTracksPerGroup: 1,
      hourIncrement: DEFAULT_HOUR_INCREMENT,
      tooltipDelay: DEFAULT_PRACTICE_TIMELINE_TOOLTIP_DELAY,
      showGridlines: false,
      ...data,
    };
  }

  static horizontal(
    data: Partial<ITimelineDisplayOptions>
  ): ITimelineDisplayOptions {
    return TimelineDisplayOptionsPresets.default({
      orientation: TimelineDisplayOrientation.Horizontal,
      stepSizeInMins: DEFAULT_STEP_SIZE_IN_MINS,
      stepSizeInPixels: 15,
      trackSizeInPixels: 60,
      ...data,
    });
  }

  static vertical(
    data: Partial<ITimelineDisplayOptions>
  ): ITimelineDisplayOptions {
    return TimelineDisplayOptionsPresets.default({
      orientation: TimelineDisplayOrientation.Vertical,
      stepSizeInPixels: 10,
      trackSizeInPixels: 60,
      ...data,
    });
  }

  static verticalLarge(
    data: Partial<ITimelineDisplayOptions>
  ): ITimelineDisplayOptions {
    return TimelineDisplayOptionsPresets.vertical({
      trackSizeInPixels: 150,
      ...data,
    });
  }
}

export function getPixelSizeFromDisplayZoom(
  zoom: TimelineDisplayZoom,
  orientation: TimelineDisplayOrientation,
  stepSizeInMins: number = DEFAULT_STEP_SIZE_IN_MINS
): number | undefined {
  const orientationMultiplier =
    orientation === TimelineDisplayOrientation.Horizontal ? 1.5 : 1;
  const scale = 1.5;
  switch (zoom) {
    case TimelineDisplayZoom.AutoFit:
      return;
    case TimelineDisplayZoom.Large:
      return stepSizeInMins * scale * orientationMultiplier * 1.5;
    default:
      return stepSizeInMins * scale * orientationMultiplier;
  }
}
