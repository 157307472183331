import {
  type DocumentData,
  type DocumentReference,
  type Timestamp,
} from './firestore/adaptor';

export interface IReffable<T = DocumentData> {
  ref: DocumentReference<T>;
}

export interface IIdentifiable {
  uid: string;
}

export type WithId<Model> = Model & IIdentifiable;

export type Reffable<Model> = Model & IReffable<Model>;

export interface ITimestamps {
  readonly createdAt: Timestamp;
  readonly updatedAt: Timestamp;
}

export type WithRef<T> = Reffable<T> & ITimestamps & IWithActors;

export type WithActors<T = unknown> = T & IWithActors;

export interface IWithActors {
  createdBy?: SystemActors | string;
  updatedBy?: SystemActors | string;
}

export enum SystemActors {
  Migration = 'migration',
  Unknown = 'unknown',
}

export interface IWithRef<T> extends IReffable<T>, ITimestamps, IWithActors {}

export type UnwrapReffable<T> = T extends IReffable<infer U> ? U : T;

export type UnwrapWithRef<T> = T extends WithRef<infer U> ? U : T;
