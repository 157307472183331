import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { iif, of, type Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { TypesenseSearchService } from '../../typesense/typesense-search.service';
import { ITypesensePatientWithRef } from '@principle-theorem/principle-core';
import { IPatientBlockFilters } from '../../typesense/typesense-patient-list-bloc';

export interface IPatientSelectorState {
  patients: ITypesensePatientWithRef[];
  suggestedPatients: ITypesensePatientWithRef[];
}

export interface ILoadSuggestedPatientsParams {
  similarQuery: string;
  filters?: Partial<IPatientBlockFilters>;
}

const initialState: IPatientSelectorState = {
  patients: [],
  suggestedPatients: [],
};

@Injectable()
export class PatientSelectorStore extends ComponentStore<IPatientSelectorState> {
  private _search = inject(TypesenseSearchService);
  readonly patients$ = this.select((store) => store.patients);
  readonly suggestedPatients$ = this.select((store) => store.suggestedPatients);

  readonly loadPatients = this.effect((query$: Observable<string>) => {
    return query$
      .pipe(
        switchMap((query) =>
          iif(
            () => !query,
            of([]),
            this._search
              .patientQuery$(of(query))
              .pipe(map((response) => response.results))
          )
        )
      )
      .pipe(tap((patients) => this.patchState({ patients })));
  });

  readonly loadSuggestedPatients = this.effect(
    (params$: Observable<ILoadSuggestedPatientsParams>) => {
      return params$
        .pipe(
          switchMap(({ similarQuery, filters }) =>
            iif(
              () => !similarQuery,
              of([]),
              this._search
                .similarPatientsQuery$(of(similarQuery), filters, of(10))
                .pipe(map((response) => response.results))
            )
          )
        )
        .pipe(
          tap((patients) => this.patchState({ suggestedPatients: patients }))
        );
    }
  );

  constructor() {
    super(initialState);
  }
}
