import {
  type IContact,
  type IInteractiveResource,
  type IPrincipleMention,
} from '@principle-theorem/principle-core/interfaces';
import { type IReffable } from '@principle-theorem/shared';
import { type IRoutable } from '../navigation/routable';

export interface IContextualAction {
  icon?: string;
  name: string;
  phone?: string;
  details?: string;

  do(
    resource?: IContact | IRoutable | IReffable,
    interactiveResource?: IInteractiveResource,
    templateResource?: IPrincipleMention
  ): void;
}
