import {
  IPricingRule,
  PricingRuleType,
} from '@principle-theorem/principle-core/interfaces';
import { FlatPricingProvider } from './flat';
import { PricingRuleItem } from './pricing-rule-item';
import { StairStepPricingProvider } from './stair-step';
import { TieredPricingProvider } from './tiered';

export const PRICING_RULE_PROVIDERS = [
  new FlatPricingProvider(),
  new StairStepPricingProvider(),
  new TieredPricingProvider(),
];

export class PricingRule {
  static init(overrides?: Partial<IPricingRule>): IPricingRule {
    return {
      type: PricingRuleType.Flat,
      ruleItems: [PricingRuleItem.init()],
      ...overrides,
    };
  }

  static calculatePrice(
    pricingRule: IPricingRule,
    unitCount: number,
    unitIndex: number
  ): number | undefined {
    const matchingRule = PRICING_RULE_PROVIDERS.find((provider) =>
      provider.canProvide(pricingRule)
    );

    return matchingRule
      ? matchingRule.determinePrice(pricingRule, unitCount, unitIndex)
      : 0;
  }
}
