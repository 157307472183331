import {
  EventType,
  IAppointmentSuggestion,
  ICalendarEvent,
  ICandidateCalendarEvent,
  IEnabledSuggestionMatchRules,
  IEventTimePeriod,
  ISchedulingConflict,
  isPreBlockEvent,
  IStaffer,
  ITag,
  ITreatmentCategory,
  ParticipantType,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  DocumentReference,
  INamedDocument,
  isSameRef,
  ITimestampRange,
  timePeriodsIntersect,
  toMoment,
  toTimestamp,
  WithRef,
} from '@principle-theorem/shared';
import { uniqBy } from 'lodash';
import * as moment from 'moment-timezone';
import { stafferToNamedDoc } from '../common';
import { CalendarEvent } from '../event/calendar-event';
import { Event } from '../event/event';

export class AppointmentSuggestion {
  static init(
    overrides: AtLeast<IAppointmentSuggestion, 'event' | 'practitioner'>
  ): IAppointmentSuggestion {
    return {
      intersectingTags: [],
      schedulingConflicts: [],
      score: 0,
      overlappingEvents: [],
      adjacentEvents: [],
      ...overrides,
    };
  }

  static distance(suggestion: IAppointmentSuggestion): string {
    return moment().to(toMoment(suggestion.event.from));
  }

  static isBlocked(suggestion: IAppointmentSuggestion): boolean {
    return (
      suggestion.schedulingConflicts.filter(
        (conflict: ISchedulingConflict) => conflict.blocking
      ).length > 0
    );
  }

  static getMatchScore(
    suggestion: IAppointmentSuggestion,
    range: ITimestampRange,
    eventPeriod: IEventTimePeriod,
    enabledRules: Partial<IEnabledSuggestionMatchRules> = {
      distance: false,
      duration: true,
      overlap: true,
    }
  ): number {
    const isBlocking = suggestion.schedulingConflicts.some(
      (conflict) => conflict.blocking
    );
    if (isBlocking) {
      return 0;
    }

    const eventDuration = Event.duration(range);
    const suggestionDuration = Event.duration(suggestion.event);
    if (!eventDuration || !suggestionDuration) {
      return 0;
    }

    if (
      toMoment(suggestion.event.from).isBefore(toMoment(range.from)) ||
      toMoment(suggestion.event.from).isBefore(moment())
    ) {
      return 0;
    }

    if (enabledRules.overlap) {
      const overlapScore = determineOverlapScore(
        suggestionDuration,
        eventPeriod
      );
      if (overlapScore < 1) {
        return overlapScore;
      }
    }

    const distanceWeight = 0.3;
    const distanceRatio: number = toMoment(suggestion.event.from).diff(
      toMoment(range.from),
      'days'
    );
    const distanceScore = determineDistanceScore(distanceRatio);

    const durationWeight = 0.7;
    const durationScore = determineDurationScore(
      eventDuration,
      distanceRatio,
      suggestion,
      suggestionDuration
    );

    if (!enabledRules.duration && !enabledRules.distance) {
      return 1;
    }

    if (!enabledRules.distance) {
      return durationScore * durationWeight + distanceWeight;
    }

    if (!enabledRules.duration) {
      return distanceScore * distanceWeight + durationWeight;
    }

    return durationScore * durationWeight + distanceScore * distanceWeight;
  }

  static fromEventTimePeriod(
    timePeriod: IEventTimePeriod
  ): IAppointmentSuggestion {
    const event = Event.init({
      from: toTimestamp(timePeriod.from),
      to: toTimestamp(timePeriod.to),
      practice: timePeriod.practice,
      organiser: timePeriod.staffer,
      participants: [
        {
          ...timePeriod.staffer,
          type: ParticipantType.Staffer,
        },
      ],
      type: EventType.Appointment,
    });

    return AppointmentSuggestion.init({
      event,
      practitioner: timePeriod.staffer,
      overlappingEvents: timePeriod.overlappingEvents,
      adjacentEvents: timePeriod.adjacentEvents,
    });
  }

  static convertOptionsToSuggestions(
    staffer: WithRef<IStaffer> | INamedDocument<IStaffer>,
    options: IEventTimePeriod[],
    gapConflicts: WithRef<ICandidateCalendarEvent>[],
    range: ITimestampRange,
    calendarEvents: WithRef<ICalendarEvent>[] = [],
    enabledRules?: Partial<IEnabledSuggestionMatchRules>,
    treatmentCategories: DocumentReference<ITreatmentCategory>[] = []
  ): IAppointmentSuggestion[] {
    return options.map((eventPeriod: IEventTimePeriod) => {
      const suggestion: IAppointmentSuggestion =
        AppointmentSuggestion.fromEventTimePeriod(eventPeriod);
      suggestion.event.creator = stafferToNamedDoc(staffer);

      if (eventPeriod.overlapDuration > 0) {
        suggestion.schedulingConflicts.push({
          reason: `This time overlaps another event by ${eventPeriod.overlapDuration} minutes`,
          blocking: false,
        });
      }

      const hasConflictingPreBlock = eventPeriod.overlappingEvents.some(
        (overlappingEvent) => {
          if (
            !isPreBlockEvent(overlappingEvent.event) ||
            !overlappingEvent.event.allowedTreatmentCategories.length
          ) {
            return false;
          }

          return !overlappingEvent.event.allowedTreatmentCategories.some(
            (allowedCategory) =>
              treatmentCategories.some((treatmentCategory) =>
                isSameRef(allowedCategory, treatmentCategory)
              )
          );
        }
      );

      if (hasConflictingPreBlock) {
        suggestion.schedulingConflicts.push({
          reason: `The selected treatment conflicts with a Pre Block`,
          blocking: true,
        });
      }

      suggestion.score = AppointmentSuggestion.getMatchScore(
        suggestion,
        range,
        eventPeriod,
        enabledRules
      );

      gapConflicts.map((gapTime: WithRef<ICandidateCalendarEvent>) => {
        const from: moment.Moment = toMoment(gapTime.event.from);
        const to: moment.Moment = toMoment(gapTime.event.to);
        if (
          from.isBetween(eventPeriod.from, eventPeriod.to, undefined, '[]') ||
          to.isBetween(eventPeriod.from, eventPeriod.to, undefined, '[]')
        ) {
          suggestion.schedulingConflicts.push({
            reason: 'There is an open Gap with candidate(s) at this time',
            blocking: false,
          });
        }
      });

      suggestion.intersectingTags = getIntersectingEventTags(
        eventPeriod,
        calendarEvents
      );

      return suggestion;
    });
  }
}

/**
 * Score will favour closer times and drop off progressively to 0
 */
function determineDistanceScore(distanceRatio: number): number {
  let distanceScore: number = 1 / Math.log2(distanceRatio + 1);
  if (distanceScore > 1 || isNaN(distanceScore)) {
    distanceScore = 1;
  }
  return distanceScore;
}

/**
 * As the duration ratio drifts from the current duration
 * it will have an exponentially worse score
 */
function determineDurationScore(
  eventDuration: number,
  distanceRatio: number,
  suggestion: IAppointmentSuggestion,
  suggestionDuration: number
): number {
  const durationRatio: number = eventDuration / suggestionDuration;
  let durationScore: number = durationRatio / Math.pow(durationRatio, 2);
  if (durationRatio < 1) {
    durationScore = durationRatio / Math.pow(1 + distanceRatio, 2);
  }

  // Any scheduling conflicts will give a worse score
  if (AppointmentSuggestion.isBlocked(suggestion)) {
    return 0;
  }
  return durationScore;
}

/**
 * Score will favour times that have no overlap
 */
function determineOverlapScore(
  suggestionDuration: number,
  eventPeriod: IEventTimePeriod
): number {
  const durationWithNoOverlap =
    suggestionDuration - eventPeriod.overlapDuration;

  const overlapScore: number =
    1 / suggestionDuration / (1 / durationWithNoOverlap);

  if (!eventPeriod.overlapDuration || isNaN(overlapScore)) {
    return 1;
  }
  return overlapScore;
}

function getIntersectingEventTags(
  eventPeriod: IEventTimePeriod,
  calendarEvents: WithRef<ICalendarEvent>[]
): INamedDocument<ITag>[] {
  const intersectingTags = calendarEvents
    .filter((calendarEvent) =>
      CalendarEvent.includesStaffer(calendarEvent, eventPeriod.staffer.ref)
    )
    .filter((calendarEvent) =>
      timePeriodsIntersect(
        eventPeriod,
        {
          from: toMoment(calendarEvent.event.from),
          to: toMoment(calendarEvent.event.to),
        },
        false,
        'minutes'
      )
    )
    .map((calendarEvent) => calendarEvent.eventTags)
    .reduce((allTags, tags) => [...allTags, ...tags], []);
  return uniqBy(intersectingTags, (tag) => tag.ref.path);
}
