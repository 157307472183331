import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Optional,
  Output,
  ViewChildren,
  type AfterViewInit,
  type OnDestroy,
  type QueryList,
} from '@angular/core';
import { type InteractEvent, type ResizeEvent } from '@interactjs/types';
import {
  AppointmentCreateSidebarComponent,
  AppointmentCreateSidebarService,
  AppointmentViewSidebarComponent,
  IAppointmentCreateSidebarData,
} from '@principle-theorem/ng-appointment';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import {
  CalendarEventEditSidebarComponent,
  CalendarEventSidebarStoreService,
} from '@principle-theorem/ng-calendar';
import {
  CurrentScopeFacade,
  GlobalStoreService,
  ScheduleSummaryEventActionsService,
} from '@principle-theorem/ng-principle-shared';
import {
  DynamicSidebarService,
  MouseInteractionsService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  Appointment,
  CalendarEvent,
  Event,
  ScheduleSummary,
  stafferToNamedDoc,
  stafferToParticipant,
} from '@principle-theorem/principle-core';
import {
  EventType,
  IScheduleSummaryEvent,
  IScheduleSummaryEventable,
  ParticipantType,
  TimelineMode,
  isAppointmentEventType,
  isSameOptions,
  type IAppointment,
  type ICalendarEvent,
  type IDeadzone,
  type IPractice,
  type IStaffer,
  type ITimelineDataGroup,
  type ITimelineDay,
  type ITimelineDisplayOptions,
  type ITimelineDisplayRange,
  type ITimelineNode,
  CalendarEventSummary,
} from '@principle-theorem/principle-core/interfaces';
import {
  getDoc,
  filterUndefined,
  DEADZONE_DEFAULT_COLOUR,
  isINamedDocument,
  isReffable,
  isSameIdentifier,
  isSameRef,
  shareReplayCold,
  snapshot,
  timePeriodsIntersect,
  toMomentTz,
  toTimestamp,
  type DocumentReference,
  type ITimePeriod,
  type WithRef,
  toNamedDocument,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  fromEvent,
  merge,
  of,
  type Observable,
} from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { EventableTimelineStore } from '../eventable-timeline/eventable-timeline.store';
import { InteractiveTimelineDisplayCalculator } from './interactive-timeline-display-calculator';
import {
  InteractiveTimelineDropzoneComponent,
  type ITimelineDropzoneEvent,
  type ITimelineTapEvent,
} from './interactive-timeline-dropzone/interactive-timeline-dropzone.component';
import {
  InteractiveTimelineLogic,
  type CreateNode,
  type IManageEventable,
  type ITimelineNodeSelection,
} from './interactive-timeline-logic';
import { type ITimelineNodeEvent } from './interactive-timeline-node/interactive-timeline-node.component';
import { type TimelineViewHandler } from './timeline-view-handler';
import { omit } from 'lodash';

export interface ITimelineDragEvent<T, G> {
  event: T;
  group: G;
  changes: ITimePeriod;
}

export interface ITimelineResizeEvent<T> {
  event: T;
  changes: ITimePeriod;
}

@Component({
  selector: 'pr-interactive-timeline',
  templateUrl: './interactive-timeline.component.html',
  styleUrls: ['./interactive-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InteractiveTimelineComponent implements AfterViewInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _scrolling$ = new BehaviorSubject<boolean>(false);
  readonly dateFormat = 'ddd Do';
  days$: Observable<
    ITimelineDay<IScheduleSummaryEventable, WithRef<IStaffer>>[]
  >;
  view$ = new ReplaySubject<TimelineViewHandler>(1);
  options$: Observable<ITimelineDisplayOptions>;
  timelineMode$ = new ReplaySubject<TimelineMode>(1);
  createType$ = new ReplaySubject<EventType>(1);
  isCreateMode$: Observable<boolean>;
  isRescheduleMode$: Observable<boolean>;
  practice$: Observable<WithRef<IPractice>>;
  trackByIndex = TrackByFunctions.index();
  trackByNode =
    TrackByFunctions.ref<ITimelineNode<IScheduleSummaryEventable>>('data.ref');
  trackByGroup =
    TrackByFunctions.ref<
      ITimelineDataGroup<IScheduleSummaryEventable, WithRef<IStaffer>>
    >('group.ref');
  trackByDay =
    TrackByFunctions.field<
      ITimelineDay<IScheduleSummaryEventable, WithRef<IStaffer>>
    >('day');
  trackByDeadzone = TrackByFunctions.fn<IDeadzone>(
    (item) => `${item.from.unix()}-${item.to.unix()}`
  );
  disableTooltips$: Observable<boolean>;
  selectedNode$ = new BehaviorSubject<ITimelineNodeSelection | undefined>(
    undefined
  );
  createNode$ = new BehaviorSubject<CreateNode | undefined>(undefined);
  moving$ = new BehaviorSubject<boolean>(false);
  isInRange$: Observable<boolean>;
  loading$: Observable<boolean>;
  timeRange$: Observable<ITimePeriod>;
  displayRange$: Observable<ITimelineDisplayRange>;
  deadzoneColourOverride$: Observable<string>;

  @HostBinding('class.horizontal') isHorizontal = true;
  @HostBinding('class.vertical') isVertical = false;
  @Output() selectEvent = new EventEmitter<IScheduleSummaryEventable>();
  @Output() dragEvent = new EventEmitter<
    ITimelineDragEvent<IScheduleSummaryEventable, WithRef<IStaffer>>
  >();
  @Output() resizeEvent = new EventEmitter<
    ITimelineResizeEvent<IScheduleSummaryEventable>
  >();
  @Output() updateEvent = new EventEmitter<{
    oldEvent: IScheduleSummaryEventable;
    newEvent: IScheduleSummaryEventable;
  }>();
  @ViewChildren(InteractiveTimelineDropzoneComponent) dropzones!: QueryList<
    InteractiveTimelineDropzoneComponent<
      IScheduleSummaryEventable,
      WithRef<IStaffer>
    >
  >;

  @Input()
  set timelineMode(timelineMode: TimelineMode) {
    if (timelineMode) {
      this.timelineMode$.next(timelineMode);
    }
  }

  @Input()
  set createType(createType: EventType) {
    if (createType) {
      this.createType$.next(createType);
    }
  }

  @Input()
  set view(view: TimelineViewHandler) {
    if (view) {
      this.view$.next(view);
    }
  }

  constructor(
    private _appointmentScheduling: AppointmentSchedulingFacade,
    private _appointmentCreateSidebar: AppointmentCreateSidebarService,
    private _calendarEventSidebarStore: CalendarEventSidebarStoreService,
    private _scheduleSummaryActions: ScheduleSummaryEventActionsService,
    private _currentScope: CurrentScopeFacade,
    private _sidebar: DynamicSidebarService,
    private _eventableTimeline: EventableTimelineStore,
    private _globalStore: GlobalStoreService,
    mouseInteractions: MouseInteractionsService,
    @Optional() @Inject(DOCUMENT) private _document: Document
  ) {
    this.loading$ = this._eventableTimeline.loading$;
    this.timeRange$ = this._eventableTimeline.timeRange$;
    this.displayRange$ = this._eventableTimeline.displayRange$;
    this.days$ = this._eventableTimeline.days$;
    this.practice$ =
      this._currentScope.currentPractice$.pipe(filterUndefined());

    this.isCreateMode$ = combineLatest([
      of(false),
      this.timelineMode$.pipe(
        map((timelineMode) => timelineMode === TimelineMode.Create),
        startWith(false)
      ),
    ]).pipe(
      map(([isSidebarMode, isTimelineMode]) => isSidebarMode || isTimelineMode),
      shareReplayCold()
    );

    this.isRescheduleMode$ = this._sidebar.currentComponent$.pipe(
      map(
        (currentSidebar) =>
          currentSidebar === AppointmentCreateSidebarComponent ||
          currentSidebar === AppointmentViewSidebarComponent
      ),
      startWith(false),
      shareReplayCold()
    );

    this.options$ = this.view$.pipe(
      switchMap((view) => view.options$),
      distinctUntilChanged(isSameOptions),
      shareReplayCold()
    );

    this.options$
      .pipe(
        map(InteractiveTimelineDisplayCalculator.isHorizontal),
        distinctUntilChanged(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((isHorizontal) => {
        this.isHorizontal = isHorizontal;
        this.isVertical = !isHorizontal;
      });

    this.disableTooltips$ = combineLatest([
      mouseInteractions.isDragMode$.pipe(startWith(false)),
      this._scrolling$,
    ]).pipe(
      map(([drag, scroll]) => (drag || scroll ? true : false)),
      shareReplayCold()
    );

    this._sidebar.close$.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
      this.createNode$.next(undefined);
    });

    this._sidebar.close$
      .pipe(withLatestFrom(this.selectedNode$), takeUntil(this._onDestroy$))
      .subscribe(([isSave, selectedNode]) => {
        if (isSave) {
          setTimeout(() => {
            this.selectedNode$.next(undefined);
          }, 200);
          return;
        }

        this.selectedNode$.next(undefined);
        selectedNode?.event.node.resetPosition();
      });

    this.selectedNode$
      .pipe(
        pairwise(),
        filter(
          ([selectedNodePrevious, selectedNodeCurrent]) =>
            !!selectedNodePrevious &&
            !isSameIdentifier(
              selectedNodePrevious?.item.data,
              selectedNodeCurrent?.item.data
            )
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([selectedNodePrevious]) => {
        selectedNodePrevious?.event.node.resetPosition();
      });

    this.selectedNode$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((selection) =>
        this._scheduleSummaryActions.setSelectedSummary(selection?.item.data)
      );

    combineLatest([this.selectedNode$, this.moving$])
      .pipe(debounceTime(200), takeUntil(this._onDestroy$))
      .subscribe(([selectedNode, moving]) => {
        const isActive = moving || !!selectedNode;
        this._scheduleSummaryActions.setUserUpdatingEvent(isActive);
      });

    this.isInRange$ = combineLatest([
      this._eventableTimeline.dateRange$,
      this.createNode$,
    ]).pipe(
      map(([dateRange, createNode]) => {
        if (!createNode) {
          return false;
        }
        return timePeriodsIntersect(createNode.day, dateRange, true, 'minute');
      })
    );

    this.deadzoneColourOverride$ = this.practice$.pipe(
      map(
        (practice) =>
          practice.settings.deadzoneColourOverride ?? DEADZONE_DEFAULT_COLOUR
      )
    );
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event: MouseEvent): void {
    event.preventDefault();
  }

  ngAfterViewInit(): void {
    fromEvent(
      this._document
        .getElementsByClassName('drag-scroll-container')
        .item(0) as HTMLElement,
      'scroll'
    )
      .pipe(
        switchMap(() => merge(of(true), of(false).pipe(delay(200)))),
        takeUntil(this._onDestroy$)
      )
      .subscribe((scrolling) => this._scrolling$.next(scrolling));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async handleDragEnd(
    event: ITimelineNodeEvent<InteractEvent>,
    item: ITimelineNode<IScheduleSummaryEventable>
  ): Promise<void> {
    this.selectedNode$.next({ event, item });
    const targetDropzone = this.dropzones.find(
      (dropzone) => dropzone.element === event.event.relatedTarget
    );
    if (!targetDropzone) {
      // eslint-disable-next-line no-console
      console.warn('No dropzone determined for drag', event.event);
      return;
    }

    const practice = await snapshot(this._currentScope.currentPractice$);

    if (!practice) {
      return;
    }

    const newTime = await event.node.getNodeTime(targetDropzone.day.from);

    this.dragEvent.emit({
      event: item.data,
      group: targetDropzone.group.group,
      changes: newTime,
    });

    if (isAppointmentEventType(item.data.event.type) && item.data.ref) {
      return this._handleAppointmentDrag(
        item.data as unknown as IScheduleSummaryEvent<IAppointment>,
        newTime,
        targetDropzone.group.group.ref,
        practice
      );
    }

    if (!isAppointmentEventType(item.data.event.type)) {
      return this._handleEventChange(
        item.data as CalendarEventSummary,
        practice,
        newTime,
        targetDropzone?.group.group.ref
      );
    }
  }

  async handleResize(
    event: ITimelineNodeEvent<ResizeEvent>,
    item: ITimelineNode<IScheduleSummaryEventable>
  ): Promise<void> {
    const practice = await snapshot(this._currentScope.currentPractice$);
    if (!practice) {
      return;
    }

    const day = await snapshot(event.node.day$);
    const eventable = item.data;

    if (isAppointmentEventType(item.data.event.type) && item.data.ref) {
      const currentEvent = await snapshot(
        this._appointmentScheduling.selectedEvent$
      );
      const newTime = await event.node.getNodeTime(
        currentEvent
          ? toMomentTz(currentEvent.from, practice.settings.timezone)
          : day.from
      );
      this.selectedNode$.next({ event, item: { ...item, ...newTime } });
      return this._handleAppointmentResize(
        item.data as unknown as IScheduleSummaryEvent<IAppointment>,
        newTime,
        practice
      );
    }

    if (!isAppointmentEventType(item.data.event.type)) {
      const currentEvent = await this._getCurrentEvent(
        eventable as CalendarEventSummary
      );
      const newTime = await event.node.getNodeTime(
        currentEvent
          ? toMomentTz(currentEvent.event.from, practice.settings.timezone)
          : day.from
      );

      this.selectedNode$.next({ event, item: { ...item, ...newTime } });
      return this._handleEventChange(
        eventable as CalendarEventSummary,
        practice,
        newTime
      );
    }
  }

  async handleCreateResize(
    event: ITimelineNodeEvent<ResizeEvent>
  ): Promise<void> {
    const practice = await snapshot(this._currentScope.currentPractice$);
    if (!practice) {
      return;
    }

    const day = await snapshot(event.node.day$);
    const createType = await snapshot(this.createType$);

    if (createType !== EventType.Appointment) {
      const currentEvent = await snapshot(
        this._calendarEventSidebarStore.calendarEvent$
      );

      const newTime = await event.node.getNodeTime(
        currentEvent
          ? toMomentTz(currentEvent.event.from, practice.settings.timezone)
          : day.from
      );

      this._calendarEventSidebarStore.patchEvent({
        from: toTimestamp(newTime.from),
        to: toTimestamp(newTime.to),
      });
      return;
    }

    const appointmentDetails = await snapshot(
      this._appointmentScheduling.appointmentDetails$
    );
    const staffer = isINamedDocument<IStaffer>(appointmentDetails.practitioner)
      ? appointmentDetails.practitioner
      : undefined;
    if (!staffer) {
      return;
    }

    const currentEvent = await snapshot(
      this._appointmentScheduling.selectedEvent$
    );
    const newTime = await event.node.getNodeTime(
      currentEvent
        ? toMomentTz(currentEvent.from, practice.settings.timezone)
        : day.from
    );

    this._appointmentScheduling.setRequestedAppointmentOption({
      params: {
        from: newTime.from,
        to: newTime.to,
        staffer: staffer.ref,
        practice: practice.ref,
      },
    });
  }

  async handleCreateDrag(
    event: ITimelineNodeEvent<InteractEvent>
  ): Promise<void> {
    const targetDropzone = this.dropzones.find(
      (dropzone) => dropzone.element === event.event.relatedTarget
    );
    if (!targetDropzone) {
      // eslint-disable-next-line no-console
      console.warn('No dropzone determined for drag', event.event);
      return;
    }

    const practice = await snapshot(this._currentScope.currentPractice$);
    if (!practice) {
      return;
    }

    const staffer = targetDropzone.group.group;
    const newTime = await event.node.getNodeTime(targetDropzone.day.from);
    const createType = await snapshot(this.createType$);

    if (createType !== EventType.Appointment) {
      this._calendarEventSidebarStore.patchEvent({
        from: toTimestamp(newTime.from),
        to: toTimestamp(newTime.to),
        participants: [
          {
            ...stafferToNamedDoc(staffer),
            type: ParticipantType.Staffer,
          },
        ],
        participantRefs: [staffer.ref],
      });
      return;
    }

    this._appointmentScheduling.setRequestedAppointmentOption({
      params: {
        from: newTime.from,
        to: newTime.to,
        staffer: staffer.ref,
        practice: practice.ref,
      },
    });
  }

  async handleDrop(
    event: ITimelineTapEvent,
    group: ITimelineDataGroup<IScheduleSummaryEventable, WithRef<IStaffer>>,
    day: ITimePeriod,
    initialTrackIndex: number
  ): Promise<void> {
    const result = InteractiveTimelineLogic.handleDrop(
      await snapshot(this.options$),
      await snapshot(this.selectedNode$),
      await snapshot(this._currentScope.currentPractice$),
      event,
      group,
      day,
      initialTrackIndex
    );
    if (!result) {
      return;
    }
    this.createNode$.next(result.createNode);
    this._appointmentScheduling.setRequestedAppointmentOption(
      result.appointmentOptions
    );

    const selectedNode = await snapshot(this.selectedNode$);

    if (!selectedNode) {
      return;
    }

    const practice = await snapshot(this._currentScope.currentPractice$);

    if (!practice) {
      return;
    }

    if (
      isAppointmentEventType(selectedNode.item.data.event.type) &&
      selectedNode.item.data.ref
    ) {
      await this._handleAppointmentDrag(
        selectedNode.item
          .data as unknown as IScheduleSummaryEvent<IAppointment>,
        result.createNode,
        group.group.ref,
        practice
      );
    }
  }

  async handleCreate(
    event: ITimelineDropzoneEvent<ResizeEvent>,
    group: ITimelineDataGroup<IScheduleSummaryEventable, WithRef<IStaffer>>,
    day: ITimePeriod
  ): Promise<void> {
    const practice = await snapshot(this._currentScope.currentPractice$);
    if (!practice) {
      return;
    }

    const options = await snapshot(this.options$);
    const isHorizontal =
      InteractiveTimelineDisplayCalculator.isHorizontal(options);
    const nodePosition = isHorizontal
      ? event.event.client.y
      : event.event.client.x;
    const trackIndex = InteractiveTimelineDisplayCalculator.getTrackIndex(
      options,
      nodePosition
    );

    const createType = await snapshot(this.createType$);
    const createNodeData: IManageEventable = {
      type: 'manage',
      event: Event.init({
        type: createType,
        from: toTimestamp(event.time.from),
        to: toTimestamp(event.time.to),
        participants: [
          {
            ...stafferToNamedDoc(group.group),
            type: ParticipantType.Staffer,
          },
        ],
        practice,
      }),
    };

    await this._handleSidebarCreateActions(
      event,
      group,
      createType,
      practice,
      createNodeData
    );

    this.createNode$.next({
      trackIndex,
      day,
      uid: 'create',
      from: event.time.from,
      to: event.time.to,
      dragEnabled: true,
      resizeEnabled: true,
      data: createNodeData,
    });
  }

  handleSelect(
    event: ITimelineNodeEvent<void>,
    item: ITimelineNode<IScheduleSummaryEventable>
  ): void {
    this.createNode$.next(undefined);
    this.selectedNode$.next({ event, item });
    this.selectEvent.emit(item.data);
  }

  private async _handleAppointmentDrag(
    appointmentSummary: IScheduleSummaryEvent<IAppointment>,
    newTime: ITimePeriod,
    stafferRef: DocumentReference<IStaffer>,
    practice: WithRef<IPractice>
  ): Promise<void> {
    const appointment = await getDoc(appointmentSummary.ref);
    const patient = await Appointment.patient(appointment);
    const currentAppointment = await snapshot(
      this._appointmentScheduling.currentAppointment$
    );

    this._appointmentScheduling.setRequestedAppointmentOption({
      params: {
        from: newTime.from,
        to: newTime.to,
        staffer: stafferRef,
        practice: practice.ref,
      },
      appointment,
    });

    if (!isSameRef(currentAppointment, appointment)) {
      this._appointmentScheduling.clearPatient();
      this._appointmentScheduling.setAppointment(appointment, {
        updateAppointmentDetails: true,
      });
      this._appointmentScheduling.selectPatient(patient);
    }

    const currentSidebar = await snapshot(this._sidebar.currentComponent$);
    if (currentSidebar !== AppointmentCreateSidebarComponent) {
      this._sidebar.open<
        AppointmentCreateSidebarComponent,
        IAppointmentCreateSidebarData
      >(AppointmentCreateSidebarComponent, {
        cleanUpCallbackFn: async () => {
          await this._appointmentCreateSidebar.cleanUp();
        },
        data: {
          saveFn: async () => {
            const staffer = await snapshot(
              this._globalStore.getStaffer$(stafferRef)
            );
            if (!staffer) {
              return;
            }
            const updatedAppointment = await ScheduleSummary.toSummaryEvent(
              appointment.ref,
              {
                ...appointment,
                event: Event.init({
                  ...omit(appointmentSummary.event, 'participants'),
                  from: toTimestamp(newTime.from),
                  to: toTimestamp(newTime.to),
                  participants: [
                    {
                      ...stafferToNamedDoc(staffer),
                      type: ParticipantType.Staffer,
                    },
                    {
                      ...toNamedDocument(patient),
                      type: ParticipantType.Patient,
                    },
                  ],
                }),
              },
              true
            );

            if (!updatedAppointment) {
              return;
            }

            this.updateEvent.emit({
              oldEvent: {
                ...appointmentSummary,
                uid: appointmentSummary.ref.id,
              },
              newEvent: {
                ...updatedAppointment,
                uid: appointmentSummary.ref.id,
              },
            });
          },
        },
      });
    }
  }

  private async _handleAppointmentResize(
    appointmentSummary: IScheduleSummaryEvent<IAppointment>,
    newTime: ITimePeriod,
    practice: WithRef<IPractice>
  ): Promise<void> {
    const appointment = await getDoc(appointmentSummary.ref);
    const patient = await Appointment.patient(appointment);

    const currentAppointment = await snapshot(
      this._appointmentScheduling.currentAppointment$
    );

    const appointmentDetails = await snapshot(
      this._appointmentScheduling.appointmentDetails$
    );

    const staffer = isINamedDocument<IStaffer>(appointmentDetails.practitioner)
      ? appointmentDetails.practitioner
      : appointment.practitioner;

    this._appointmentScheduling.setRequestedAppointmentOption({
      params: {
        from: newTime.from,
        to: newTime.to,
        staffer: staffer.ref,
        practice: practice.ref,
      },
      appointment,
    });

    if (!isSameRef(currentAppointment, appointment)) {
      this._appointmentScheduling.clearPatient();
      this._appointmentScheduling.setAppointment(appointment, {
        updateAppointmentDetails: true,
      });
      this._appointmentScheduling.selectPatient(patient);
    }

    const currentSidebar = await snapshot(this._sidebar.currentComponent$);
    if (currentSidebar !== AppointmentCreateSidebarComponent) {
      this._sidebar.open<
        AppointmentCreateSidebarComponent,
        IAppointmentCreateSidebarData
      >(AppointmentCreateSidebarComponent, {
        cleanUpCallbackFn: async () => {
          await this._appointmentCreateSidebar.cleanUp();
        },
        data: {
          saveFn: async () => {
            const updatedAppointment = await ScheduleSummary.toSummaryEvent(
              appointment.ref,
              {
                ...appointment,
                event: Event.init({
                  ...omit(appointmentSummary.event, 'participants'),
                  from: toTimestamp(newTime.from),
                  to: toTimestamp(newTime.to),
                  participants: [
                    {
                      ...staffer,
                      type: ParticipantType.Staffer,
                    },
                    {
                      ...toNamedDocument(patient),
                      type: ParticipantType.Patient,
                    },
                  ],
                }),
              },
              true
            );

            if (!updatedAppointment) {
              return;
            }

            this.updateEvent.emit({
              oldEvent: {
                ...appointmentSummary,
                uid: appointmentSummary.ref.id,
              },
              newEvent: {
                ...updatedAppointment,
                uid: appointmentSummary.ref.id,
              },
            });
          },
        },
      });
    }
  }

  private async _handleEventChange(
    summaryEvent: CalendarEventSummary,
    practice: WithRef<IPractice>,
    newTime: ITimePeriod,
    stafferRef?: DocumentReference<IStaffer>
  ): Promise<void> {
    const newStaffer = stafferRef
      ? await snapshot(this._globalStore.getStaffer$(stafferRef))
      : undefined;

    const updatedEventDetails = newStaffer
      ? {
          from: toTimestamp(newTime.from),
          to: toTimestamp(newTime.to),
          participants: [stafferToParticipant(newStaffer)],
          participantRefs: [newStaffer.ref],
        }
      : {
          from: toTimestamp(newTime.from),
          to: toTimestamp(newTime.to),
        };

    const selectedEvent = await snapshot(
      this._calendarEventSidebarStore.calendarEvent$
    );

    const calendarEvent = await ScheduleSummary.getCalendarEvent(
      summaryEvent,
      practice
    );

    if (!calendarEvent) {
      return;
    }

    const updatedEvent = {
      ...calendarEvent,
      event: {
        ...calendarEvent.event,
        ...updatedEventDetails,
      },
    };

    const calendarEventChanged = CalendarEvent.hasChanged(
      selectedEvent,
      updatedEvent
    );

    if (!calendarEventChanged) {
      return;
    }

    this._calendarEventSidebarStore.setState({
      calendarEvent: CalendarEvent.init(updatedEvent),
    });

    const currentSidebar = await snapshot(this._sidebar.currentComponent$);
    if (currentSidebar !== CalendarEventEditSidebarComponent) {
      this._sidebar.open(CalendarEventEditSidebarComponent, {
        data: {
          saveFn: () => {
            this.updateEvent.emit({
              oldEvent: {
                ...summaryEvent,
                uid: summaryEvent.uid,
              },
              newEvent: {
                ...summaryEvent,
                event: {
                  ...summaryEvent.event,
                  ...updatedEventDetails,
                },
                uid: summaryEvent.uid,
              },
            });
            this._calendarEventSidebarStore.patchEvent(updatedEventDetails);
          },
        },
      });
    }
  }

  private async _handleSidebarCreateActions(
    event: ITimelineDropzoneEvent<ResizeEvent>,
    group: ITimelineDataGroup<IScheduleSummaryEventable, WithRef<IStaffer>>,
    createType: EventType,
    practice: WithRef<IPractice>,
    createNodeData: IManageEventable
  ): Promise<void> {
    const currentSidebar = await snapshot(this._sidebar.currentComponent$);

    if (createType !== EventType.Appointment) {
      this._calendarEventSidebarStore.setState({
        calendarEvent: CalendarEvent.init({
          event: createNodeData.event,
          isBlocking: [EventType.PreBlock, EventType.RosteredOn].includes(
            createType
          )
            ? false
            : true,
        }),
      });

      if (currentSidebar === CalendarEventEditSidebarComponent) {
        return;
      }

      this._sidebar.open(CalendarEventEditSidebarComponent, {
        data: {
          saveFn: async () => Promise.resolve(),
        },
      });
      return;
    }

    const currentAppointment = await snapshot(
      this._appointmentScheduling.currentAppointment$
    );

    if (currentAppointment) {
      this._appointmentScheduling.reset();
    }

    this._appointmentScheduling.resetWaitList();
    this._appointmentScheduling.setRequestedAppointmentOption({
      params: {
        from: event.time.from,
        to: event.time.to,
        staffer: group.group.ref,
        practice: practice.ref,
      },
    });

    if (currentSidebar === AppointmentCreateSidebarComponent) {
      return;
    }

    this._sidebar.open<
      AppointmentCreateSidebarComponent,
      IAppointmentCreateSidebarData
    >(AppointmentCreateSidebarComponent, {
      cleanUpCallbackFn: async () => this._appointmentCreateSidebar.cleanUp(),
      data: {
        saveFn: async () => Promise.resolve(),
      },
    });
  }

  private async _getCurrentEvent(
    actionEvent: CalendarEventSummary
  ): Promise<CalendarEventSummary | ICalendarEvent | WithRef<ICalendarEvent>> {
    const selectedEvent = await snapshot(
      this._calendarEventSidebarStore.calendarEvent$
    );

    if (
      isReffable<CalendarEventSummary>(selectedEvent) &&
      isReffable<CalendarEventSummary>(actionEvent) &&
      isSameRef(selectedEvent, actionEvent)
    ) {
      return selectedEvent;
    }
    return actionEvent;
  }
}
