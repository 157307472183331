import {
  type IChartedSurfaceProvider,
  type IChartSurfaceEvent,
} from '../add-charted-surface-provider';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { TreatmentPlanProposal } from '@principle-theorem/principle-core';
import {
  type AnyChartedItemConfiguration,
  type IChartedItem,
  type IChartedItemConfiguration,
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  type IChartedRef,
  type IMultiTreatmentConfiguration,
  isMultiTreatmentConfiguration,
  ITreatmentPlanProposal,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { ChartDialogService } from '../../chart-dialog.service';

export class AddMultiTreatmentToMultiTreatmentProposalProvider
  implements IChartedSurfaceProvider
{
  constructor(
    private _chartStore: ChartFacade,
    private _treatmentPlan: IChartedMultiStepTreatment,
    private _treatmentStep: IChartedMultiStepTreatmentStep,
    private _chartDialogService: ChartDialogService
  ) {}

  canProvide(chartable: AnyChartedItemConfiguration): boolean {
    return isMultiTreatmentConfiguration(chartable);
  }

  async addChartedItem(
    _event: IChartSurfaceEvent,
    chartable: WithRef<IMultiTreatmentConfiguration>
  ): Promise<void> {
    const selectedSurfaces = await snapshot(
      this._chartStore.selectedSurfacesState$(ChartId.InAppointment)
    );
    const multiTreatment = await this._chartDialogService.chartMultiTreatment(
      chartable,
      selectedSurfaces
    );
    if (!multiTreatment) {
      return;
    }
    this._chartStore.updatePlanProposal(
      ChartId.InAppointment,
      await this._updateMultiTreatments(multiTreatment)
    );
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async removeSurfacesFromChartedItem(
    _surfaceRefs: Partial<IChartedRef>[],
    _item: IChartedItem<IChartedItemConfiguration>
  ): Promise<void> {
    // Not implemented
  }

  private async _updateMultiTreatments(
    multiTreatment: IChartedMultiStepTreatment
  ): Promise<ITreatmentPlanProposal> {
    const chart = await snapshot(
      this._chartStore.clinicalChartState$(ChartId.InAppointment)
    );
    const updatedMultiTreatmentSteps =
      TreatmentPlanProposal.addMultiTreatmentToMultiTreatmentStep(
        chart.flaggedTreatment.multiTreatments,
        multiTreatment,
        this._treatmentPlan.uuid,
        this._treatmentStep.uid
      );

    const multiTreatments = await asyncForEach(
      updatedMultiTreatmentSteps,
      async (updatedMultiTreatment) =>
        this._chartStore.updatePrimaryTreatmentCategories(updatedMultiTreatment)
    );

    return {
      ...chart.flaggedTreatment,
      multiTreatments,
    };
  }
}
