import { BlockNodes, type IMenuButton } from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../menu-bar/basic-menu-button/basic-menu-button.component';

export const BULLETED_LIST_KEYMAP = 'Shift-Mod-8';

const data: IMenuButton = {
  buttonType: 'basic',
  buttonText: 'Bulleted List',
  tooltip: 'Bulleted List',
  shortcut: BULLETED_LIST_KEYMAP,
  icon: 'format_list_bulleted',
  command: (editor) => editor.chain().focus().toggleBulletList().run(),
  isActiveFn: (editor) => editor.isActive(BlockNodes.BulletedList),
};

export function createBulletedListMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data,
  });
}

export function createBulletedListBlockMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      ...data,
      buttonType: 'block',
    },
  });
}
