import { Region, WithRef } from '@principle-theorem/shared';
import { type IContactDetails } from '../../contact/contact';
import { IPatientForm } from '../../custom-forms/patient-form';
import {
  IBasePatient,
  IPatientContactDetails,
  type IPatient,
} from '../../patient/patient';
import { type ITemplateContext, type ITemplateScopeData } from '../templating';
import { type IBaseTemplateContext } from './base-context';
import { SmsIntegrationOption } from '../../interaction/sms-integration';

export interface IPatientScopeData extends ITemplateScopeData {
  patient: WithRef<IPatient>;
  medicalHistoryLink: string;
  patientFormsLink: string;
  patientForms: WithRef<IPatientForm>[];
  region: Region;
  smsIntegration?: SmsIntegrationOption;
  primaryContact?: WithRef<IBasePatient & IPatientContactDetails>;
  referrer?: Partial<IContactDetails>;
}

export interface IPatientTemplateContext
  extends ITemplateContext,
    IBaseTemplateContext {
  patientEmail: string;
  patientFullName: string;
  patientFirstName: string;
  patientLastName: string;
  patientMobile: string;
  patientDateOfBirth: string;
  patientAddress: string;
  patientMedicareNumber: string;
  referrerName: string;
  referrerEmail: string;
  referrerAddress: string;
  referrerJobTitle: string;
  medicalHistoryLink: string;
  patientFormsLink: string;
}
