import {
  CustomChartType,
  MeasureReducer,
} from '@principle-theorem/principle-core/interfaces';
import {
  IMeasureBuilderData,
  generateBuilderData,
  type CanGroupMeasuresProperty,
  type IChartBuilderData,
} from '@principle-theorem/reporting';
import {
  DcColumnChartBuilder,
  type IColumnChartBuilderConfig,
} from '../../reporting/core/chart-builders/dc-chart-builders/dc-column-chart-builder';
import { DcPieChartBuilder } from '../../reporting/core/chart-builders/dc-chart-builders/dc-pie-chart-builder';
import { DcRowChartBuilder } from '../../reporting/core/chart-builders/dc-chart-builders/dc-row-chart-builder';
import { DcSelectMenuBuilder } from '../../reporting/core/chart-builders/dc-chart-builders/dc-select-menu-builder';
import { DcSingleValueChartBuilder } from '../../reporting/core/chart-builders/dc-chart-builders/dc-single-value-chart-builder';
import { type IChartFilterResult } from './report-builder-data-source';
import { TableChartBuilder } from '../../reporting/core/chart-builders/table-chart-builder';
import { DcLineChartBuilder } from '../../reporting/core/chart-builders/dc-chart-builders/dc-line-chart-builder';

export class ChartFilters {
  static gridSpan(
    multiplier: number,
    singleChartWidth = 260,
    gapBetweenCharts = 42
  ): number {
    const chartWidthSum = singleChartWidth * multiplier;
    const gapSum = gapBetweenCharts * (multiplier - 1);
    return chartWidthSum + gapSum;
  }

  static numberChart(
    title: string,
    groupBy: CanGroupMeasuresProperty,
    measures: IMeasureBuilderData[],
    builderData?: Partial<IChartBuilderData>
  ): IChartFilterResult {
    return {
      builderData,
      builder: new DcSingleValueChartBuilder({
        height: 200,
        width: this.gridSpan(1),
      }),
      config: {
        type: CustomChartType.NumberSummary,
        labels: { title },
        builderData: generateBuilderData({
          ...builderData,
          measures: measures.map((measure) => ({
            ...measure,
            measure: measure.measure
              .clone()
              .groupBy(groupBy)
              .reduceBy(measure?.reducer ?? MeasureReducer.Sum),
          })),
        }),
      },
    };
  }

  static tableChart(
    title: string,
    groupBy: CanGroupMeasuresProperty,
    measures: IMeasureBuilderData[],
    builderData?: Partial<IChartBuilderData>
  ): IChartFilterResult {
    return {
      builderData,
      builder: new TableChartBuilder({
        height: 200,
        width: this.gridSpan(2),
      }),
      config: {
        type: CustomChartType.Table,
        labels: { title },
        builderData: generateBuilderData({
          ...builderData,
          measures: measures.map((measure) => ({
            ...measure,
            measure: measure.measure
              .clone()
              .groupBy(groupBy)
              .reduceBy(measure.reducer ?? MeasureReducer.Sum),
          })),
          groupByDimension: groupBy,
        }),
      },
    };
  }

  static pieChart(
    title: string,
    groupBy: CanGroupMeasuresProperty,
    measures: IMeasureBuilderData[],
    builderData?: Partial<IChartBuilderData>
  ): IChartFilterResult {
    return {
      builderData,
      builder: new DcPieChartBuilder({
        height: 200,
        width: this.gridSpan(1),
      }),
      config: {
        type: CustomChartType.Pie,
        labels: { title },
        builderData: generateBuilderData({
          ...builderData,
          measures: measures.map((measure) => ({
            ...measure,
            measure: measure.measure
              .clone()
              .groupBy(groupBy)
              .reduceBy(measure?.reducer ?? MeasureReducer.Sum),
          })),
          groupByDimension: groupBy,
        }),
      },
    };
  }

  static rowChart(
    title: string,
    groupBy: CanGroupMeasuresProperty,
    measures: IMeasureBuilderData[],
    builderData?: Partial<IChartBuilderData>
  ): IChartFilterResult {
    return {
      builderData,
      builder: new DcRowChartBuilder({
        height: 250,
        width: this.gridSpan(1),
      }),
      config: {
        type: CustomChartType.Row,
        labels: { title },
        builderData: generateBuilderData({
          ...builderData,
          measures: measures.map((measure) => ({
            ...measure,
            measure: measure.measure
              .clone()
              .groupBy(groupBy)
              .reduceBy(measure?.reducer ?? MeasureReducer.Sum),
          })),
          groupByDimension: groupBy,
        }),
      },
    };
  }

  static columnChart(
    title: string,
    groupBy: CanGroupMeasuresProperty,
    measures: IMeasureBuilderData[],
    builderConfig?: Partial<IColumnChartBuilderConfig>,
    builderData?: Partial<IChartBuilderData>
  ): IChartFilterResult {
    return {
      builderConfig,
      builderData,
      builder: new DcColumnChartBuilder({
        height: 200,
        width: this.gridSpan(2),
        ...builderConfig,
      }),
      config: {
        type: CustomChartType.Column,
        labels: { title },
        builderData: generateBuilderData({
          ...builderData,
          measures: measures.map((measure) => ({
            ...measure,
            measure: measure.measure
              .clone()
              .groupBy(groupBy)
              .reduceBy(measure?.reducer ?? MeasureReducer.Sum),
          })),
          groupByDimension: groupBy,
        }),
      },
    };
  }

  static lineChart(
    title: string,
    groupBy: CanGroupMeasuresProperty,
    measures: IMeasureBuilderData[],
    builderConfig?: Partial<IColumnChartBuilderConfig>,
    builderData?: Partial<IChartBuilderData>
  ): IChartFilterResult {
    return {
      builderConfig,
      builderData,
      builder: new DcLineChartBuilder({
        height: 200,
        width: this.gridSpan(2),
        ...builderConfig,
      }),
      config: {
        type: CustomChartType.Line,
        labels: { title },
        builderData: generateBuilderData({
          ...builderData,
          measures: measures.map((measure) => ({
            ...measure,
            measure: measure.measure
              .clone()
              .groupBy(groupBy)
              .reduceBy(measure?.reducer ?? MeasureReducer.Sum),
          })),
          groupByDimension: groupBy,
        }),
      },
    };
  }

  static columnChartOverTime(
    title: string,
    groupBy: CanGroupMeasuresProperty,
    measures: IMeasureBuilderData[],
    config?: Partial<IColumnChartBuilderConfig>,
    builderData?: Partial<IChartBuilderData>
  ): IChartFilterResult {
    return ChartFilters.columnChart(title, groupBy, measures, config, {
      ...builderData,
      plottedOverTime: true,
    });
  }

  static selectMenu(
    title: string,
    groupBy: CanGroupMeasuresProperty,
    measures: IMeasureBuilderData[],
    builderData?: Partial<IChartBuilderData>
  ): IChartFilterResult {
    return {
      builderData,
      builder: new DcSelectMenuBuilder({
        height: 200,
        width: this.gridSpan(1),
      }),
      config: {
        type: CustomChartType.SelectMenu,
        labels: { title },
        builderData: generateBuilderData({
          ...builderData,
          measures: measures.map((measure) => ({
            ...measure,
            measure: measure.measure
              .clone()
              .groupBy(groupBy)
              .reduceBy(measure?.reducer ?? MeasureReducer.Sum),
          })),
          groupByDimension: groupBy,
        }),
      },
    };
  }
}
