import {
  ISourceEntity,
  SourceEntityMigrationType,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISODateTimeType,
  ISODateType,
  ISOTimeType,
  Timestamp,
  Timezone,
  TypeGuard,
  isISODateTimeType,
  isISODateType,
  isISOTimeType,
  toFloat,
  toISODate,
  toTimestamp,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertDateToTimestampFn,
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  convertValueFn,
} from '../../../source/source-helpers';

export const PATIENT_PAYMENT_ADJUSTMENT_RESOURCE_TYPE =
  'patientPaymentAdjustments';

export const PATIENT_PAYMENT_ADJUSTMENT_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Payment Adjustment List',
      description: '',
      idPrefix: PATIENT_PAYMENT_ADJUSTMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IOasisPatientPaymentAdjustment {
  id: number;
  transactionGroupId?: string;
  appointmentDate: ISODateType;
  createdAt: ISODateType;
  description?: string;
  comment?: string;

  accountPatientId?: number;
  accountThirdPartyId?: number;
  patientId?: number; // This is set for all treatments. It's only missing from IOasisTransactionType.Treatment records when the description is "Plus GST" and "Loyalty Bonus".

  practitionerId: number;
  billedToPractitionerId: number;
  statementNumber?: number;

  isDeleted: boolean;
  deletedAt?: ISODateTimeType;

  locationId?: number;
  userId: number;
  userTime?: ISOTimeType;

  amount?: number; // This is the sum of the different payment types below.
  paidAmount?: number;
  isPaid: boolean;

  showInGst: boolean; // Whether to show in GST report. Seems to be linked to having a gst_number.
}

export function isOasisPatientPaymentAdjustment(
  item: unknown
): item is IOasisPatientPaymentAdjustment {
  return TypeGuard.interface<IOasisPatientPaymentAdjustment>({
    id: isNumber,
    transactionGroupId: TypeGuard.nilOr(isString),
    appointmentDate: isISODateType,
    createdAt: isISODateType,
    description: TypeGuard.nilOr(isString),
    comment: TypeGuard.nilOr(isString),
    accountPatientId: TypeGuard.nilOr(isNumber),
    accountThirdPartyId: TypeGuard.nilOr(isNumber),
    patientId: TypeGuard.nilOr(isNumber),
    practitionerId: isNumber,
    billedToPractitionerId: isNumber,
    statementNumber: TypeGuard.nilOr(isNumber),
    isDeleted: isBoolean,
    deletedAt: TypeGuard.nilOr(isISODateTimeType),
    locationId: TypeGuard.nilOr(isNumber),
    userId: isNumber,
    userTime: TypeGuard.nilOr(isISOTimeType),
    amount: TypeGuard.nilOr(isNumber),
    paidAmount: TypeGuard.nilOr(isNumber),
    isPaid: isBoolean,
    showInGst: isBoolean,
  })(item);
}

export interface IOasisPatientPaymentAdjustmentTranslations {
  createdAt: Timestamp;
  appointmentDate: ISODateType;
  deletedAt?: Timestamp;
}

export interface IOasisPatientPaymentAdjustmentFilters {
  practitionerId: number;
  accountPatientId?: number;
  accountThirdPartyId?: number;
}

const PATIENT_PAYMENT_ADJUSTMENT_SOURCE_QUERY = `
SELECT
  SEQNUMBER AS id,
  NULLIF(RUNNUMBER::TEXT, 'NULL') AS transaction_group_id,

  CASE
    WHEN convert_to_integer(ACCNUMBER) < 99000
      THEN convert_to_integer(ACCNUMBER)
    ELSE NULL
  END AS account_patient_id,
  CASE
    WHEN convert_to_integer(ACCNUMBER) >= 99000
      THEN convert_to_integer(ACCNUMBER)
    ELSE NULL
  END AS account_third_party_id,
  convert_to_integer(PATNUMBER) AS patient_id,

  CASE
    WHEN strpos (CONSULTDATE, '/') > 0
      THEN to_char(convert_to_date(CONSULTDATE, 'DD/MM/YYYY'), 'YYYY-MM-DD')
    ELSE CONSULTDATE
  END AS appointment_date,

  CASE
    WHEN strpos(ENTRYDATE, '/') > 0
      THEN to_char(convert_to_date(ENTRYDATE, 'DD/MM/YYYY'), 'YYYY-MM-DD')
    ELSE ENTRYDATE
  END AS created_at,

  convert_to_decimal(AMOUNT) AS amount,
  convert_to_decimal(PAID) AS paid_amount,
  CASE
    WHEN convert_to_decimal(AMOUNT) = convert_to_decimal(PAID)
      THEN TRUE
    ELSE FALSE
  END AS is_paid,

  NULLIF(DESCRIPTION1, '') AS description,
  NULLIF(COMMENT, '') AS comment,

  NULLIF(STATEMENTNUMBER, 0) AS statement_number,

  CONSULTDRNUMBER AS practitioner_id,
  BILLDRNUMBER AS billed_to_practitioner_id,

  CASE WHEN DELETED = -1
    THEN TRUE
    ELSE FALSE
  END AS is_deleted,
  CASE
    WHEN strpos(DELETEDATE, '-') > 0
      THEN to_char(convert_to_date(DELETEDATE, 'YYYY-MM-DD')
      +
      CASE
		    WHEN DELETETIME ~ '^\\d{1,2}:\\d{2}:\\d{2} (AM|PM)$'
		      THEN NULLIF(TRIM(DELETETIME), '')::TIME
		    ELSE
		      NULL
		  END,
      'YYYY-MM-DD HH:MI:SS')
    ELSE to_char(
      convert_to_date(DELETEDATE, 'DD/MM/YYYY')
      +
      CASE
		    WHEN DELETETIME ~ '^\\d{1,2}:\\d{2}:\\d{2} (AM|PM)$'
		      THEN NULLIF(TRIM(DELETETIME), '')::TIME
		    ELSE
		      NULL
		  END,
      'YYYY-MM-DD HH:MI:SS'
    )
  END AS deleted_at,

  USERNUMBER AS user_id,
  NULLIF(NULLIF(USERTIME, ''), 'NULL')::TIME AS user_time,
  convert_to_integer(LOCNO) AS location_id,

  convert_to_boolean(SHOWINGST) AS show_in_gst
FROM PBARCMAS
WHERE
  convert_to_integer(TRANSTYPE) = 2
`;

export class PatientPaymentAdjustmentSourceEntity extends BaseSourceEntity<
  IOasisPatientPaymentAdjustment,
  IOasisPatientPaymentAdjustmentTranslations,
  IOasisPatientPaymentAdjustmentFilters
> {
  sourceEntity = PATIENT_PAYMENT_ADJUSTMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_PAYMENT_ADJUSTMENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_PAYMENT_ADJUSTMENT_SOURCE_QUERY;
  verifySourceFn = isOasisPatientPaymentAdjustment;
  override defaultOffsetSize = 50000;
  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertDateToTimestampFn(),
    convertValueFn(toFloat, 'amount', 'paidAmount'),
  ]);

  translate(
    data: IOasisPatientPaymentAdjustment,
    timezone: Timezone
  ): IOasisPatientPaymentAdjustmentTranslations {
    return {
      createdAt: toTimestamp(moment.tz(data.createdAt, timezone)),
      appointmentDate: toISODate(moment.tz(data.appointmentDate, timezone)),
      deletedAt: data.deletedAt
        ? toTimestamp(moment.tz(data.deletedAt, timezone))
        : undefined,
    };
  }

  getSourceRecordId(data: IOasisPatientPaymentAdjustment): number {
    return data.id;
  }

  getSourceLabel(data: IOasisPatientPaymentAdjustment): string {
    return data.id.toString();
  }

  getFilterData(
    data: IOasisPatientPaymentAdjustment,
    _timezone: Timezone
  ): IOasisPatientPaymentAdjustmentFilters {
    return {
      practitionerId: data.practitionerId,
      accountPatientId: data.accountPatientId,
      accountThirdPartyId: data.accountThirdPartyId,
    };
  }
}
