import { rand, randNumber } from '@ngneat/falso';
import {
  AppointmentDependencyType,
  CancellationReason,
} from '@principle-theorem/principle-core/interfaces';
import {
  IAppointmentDimension,
  IBigQueryNamedDocument,
} from '@principle-theorem/reporting/interfaces';
import {
  MockGenerator,
  toSerialisedTimestamp,
  toTimestamp,
  wrapWithSeed,
} from '@principle-theorem/shared';
import { uniqBy } from 'lodash';
import {
  AvroSerialisedVersionedSchemaMock,
  BigQueryFirestoreModelMock,
  BigQueryInteractionMock,
  BigQueryNamedDocumentMock,
  BigQueryReffableMock,
  MockBigQueryNamedDocument,
  MockBigQueryStatusHistory,
  SerialisedDocumentReferenceMock,
} from './common.mock';
import { AppointmentMock } from '@principle-theorem/principle-core';
import { BigQueryEventMock } from '../facts/common.mock';

const appointment = MockGenerator.generate(AppointmentMock);

export const MOCKED_APPOINTMENT_TAGS = wrapWithSeed('MockAppointmentTags', () =>
  ['Happy Gas', 'CEREC'].map((name) =>
    MockBigQueryNamedDocument(name, 'appointment-tags')
  )
);

function MockAppointmentTags(): IBigQueryNamedDocument[] {
  const count = randNumber({ min: 0, max: 1 });
  const tags = new Array(count)
    .fill('')
    .map(() => rand(MOCKED_APPOINTMENT_TAGS));
  return uniqBy(tags, (item) => item.ref.referenceValue);
}

export const MOCKED_TREATMENT_CATEGORIES = wrapWithSeed(
  'MockTreatmentCategory',
  () =>
    [
      {
        name: 'Cerec/Crown',
        colour: '#76ff03',
      },
      {
        name: 'Clear Aligner',
        colour: '#db4b4b',
      },
      {
        name: 'Consult',
        colour: '#aa00ff',
      },
      {
        name: 'Crown and Bridge',
        colour: '#ffff00',
      },
      {
        name: 'Denture',
        colour: '#00bfa5',
      },
      {
        name: 'Diagnosis and Prevention',
        colour: '#80d8ff',
      },
      {
        name: 'Extraction and Oral Surgery',
        colour: '#00c853',
      },
      {
        name: 'Filling',
        colour: '#ff9100',
      },
      {
        name: 'Fissure Seal',
        colour: '#c6ff00',
      },
      {
        name: 'Implant',
        colour: '#2962ff',
      },
      {
        name: 'Impression/Mouth guard/Splint',
        colour: '#ffe57f',
      },
      {
        name: 'Perio',
        colour: '#64ffda',
      },
      {
        name: 'RCT',
        colour: '#ff4081',
      },
      {
        name: 'Review',
        colour: '#7c4dff',
      },
      {
        name: 'Toothache',
        colour: '#d50000',
      },
      {
        name: 'Veneers',
        colour: '#eeff41',
      },
    ].map((category) => ({
      ...MockBigQueryNamedDocument(category.name, 'treatment-category'),
      colour: {
        name: '',
        value: category.colour,
      },
    }))
);

export class AppointmentDimensionMock
  extends BigQueryFirestoreModelMock
  implements IAppointmentDimension
{
  timestamp = toSerialisedTimestamp(toTimestamp());
  event = MockGenerator.generate(BigQueryEventMock);
  eventHistory = appointment.eventHistory.map((item) => ({
    createdAt: toSerialisedTimestamp(item.createdAt),
    event: MockGenerator.generate(BigQueryEventMock),
  }));
  status = appointment.status;
  statusHistory = appointment.statusHistory.map((item) =>
    MockBigQueryStatusHistory(item.status, item.updatedAt)
  );
  practitioner = MockGenerator.generate(BigQueryNamedDocumentMock);
  treatmentPlan = {
    ...MockGenerator.generate(BigQueryNamedDocumentMock),
    treatmentStep: {
      ...MockGenerator.generate(BigQueryNamedDocumentMock),
      duration: 60,
      treatmentCategory: rand(MOCKED_TREATMENT_CATEGORIES),
    },
  };
  interactions = appointment.interactions.map(() =>
    MockGenerator.generate(BigQueryInteractionMock)
  );
  waitListItem = {
    ...appointment.waitListItem,
    dateFrom: toSerialisedTimestamp(appointment.waitListItem.dateFrom),
    dateTo: toSerialisedTimestamp(appointment.waitListItem.dateTo),
    notes: MockGenerator.generate(AvroSerialisedVersionedSchemaMock),
  };
  cancellationHistory = [
    {
      reason: CancellationReason.FailedToAttend,
      comments: MockGenerator.generate(AvroSerialisedVersionedSchemaMock),
    },
  ];
  dependencies = [
    {
      ...MockGenerator.generate(BigQueryNamedDocumentMock),
      type: AppointmentDependencyType.LabJob,
    },
  ];
  clinicalChart = MockGenerator.generate(SerialisedDocumentReferenceMock);
  invoiceRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  activeFollowUp = {
    ...appointment.activeFollowUp,
    ...MockGenerator.generate(BigQueryReffableMock),
    followUpDate: appointment.activeFollowUp.followUpDate
      ? toSerialisedTimestamp(appointment.activeFollowUp.followUpDate)
      : undefined,
    noFollowUpReason: MockGenerator.generate(AvroSerialisedVersionedSchemaMock),
    createdAt: toSerialisedTimestamp(appointment.activeFollowUp.createdAt),
  };
  practice = MockGenerator.generate(BigQueryNamedDocumentMock);
  tags = MockAppointmentTags();
  appointmentRequest = {
    bookedAt: toSerialisedTimestamp(appointment.createdAt),
    ref: MockGenerator.generate(SerialisedDocumentReferenceMock),
  };
}
