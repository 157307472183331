import {
  CustomMappingStatus,
  CustomMappingType,
  ITranslationMap,
  type ICustomMapping,
  type IPracticeMigration,
  type SelectionCustomMapping,
} from '@principle-theorem/principle-core/interfaces';
import {
  all$,
  asColRef,
  find$,
  isEnumValue,
  isObject,
  where,
  type AtLeast,
  type CollectionReference,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { PracticeMigration } from './practice-migrations';

export const CUSTOM_MAPPING_STATUS_MAP: {
  [key in CustomMappingStatus]: string;
} = {
  [CustomMappingStatus.Unlocked]: 'default',
  [CustomMappingStatus.Locked]: 'primary',
};

export function isSelectionCustomMapping(
  item: ICustomMapping
): item is ICustomMapping & SelectionCustomMapping {
  return (
    isObject(item) &&
    isEnumValue(CustomMappingType, item.type) &&
    item.type === CustomMappingType.SelectionList
  );
}

export class CustomMapping {
  static init(
    overrides: AtLeast<ICustomMapping, 'metadata' | 'type'>
  ): ICustomMapping {
    return {
      uid: overrides.metadata.type,
      status: CustomMappingStatus.Unlocked,
      ...overrides,
    };
  }

  static recordCol(
    migration: IReffable<IPracticeMigration>
  ): CollectionReference<ITranslationMap> {
    return asColRef<ITranslationMap>(
      PracticeMigration.translationMapCol(migration)
    );
  }

  static records$(
    migration: IReffable<IPracticeMigration>
  ): Observable<WithRef<ITranslationMap>[]> {
    return all$(CustomMapping.recordCol(migration));
  }

  static findCustomMapping$(
    migration: IReffable<IPracticeMigration>,
    resourceMap: ICustomMapping
  ): Observable<WithRef<ITranslationMap> | undefined> {
    return find$(
      PracticeMigration.translationMapCol(migration),
      where('type', '==', resourceMap.metadata.type)
    );
  }
}
