import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';

@Component({
  selector: 'pr-appointment-options-placeholder',
  templateUrl: './appointment-options-placeholder.component.html',
  styleUrls: ['./appointment-options-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentOptionsPlaceholderComponent {
  trackByIndex = TrackByFunctions.index<number>();
  rows = new Array(11);
}
