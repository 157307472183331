<div class="layout-padding flex flex-col gap-4">
  <div *ngIf="data.gapTaken; else confirmed">
    <p>
      Sorry, this appointment offer with {{ data.practitioner }} has been filled
      by another patient.
    </p>
    <p>
      Your position on the waitlist has been reserved. {{ data.practice }} will
      update you with any future appointment offers.
    </p>
  </div>
  <ng-template #confirmed>
    <p>
      Thanks for confirming your updated appointment time at
      {{ data.practice }} with {{ data.practitioner }}.
    </p>
    <p>You may safely close this window.</p>
  </ng-template>
  <div>
    <h3>Treatment Plan</h3>
    <p>{{ data.treatment }}</p>
  </div>
  <div>
    <h3>Practitioner</h3>
    <p>{{ data.practitioner }}</p>
  </div>
  <div *ngIf="data.currentFrom && data.currentTo">
    <h3>{{ data.gapTaken ? 'Current' : 'Previous' }} Appointment Time</h3>
    <p>
      {{ data.currentFrom | moment | amDateFormat: dateFormat }}
      - {{ data.currentTo | moment | amDateFormat: timeFormat }}
    </p>
  </div>
  <div *ngIf="!data.gapTaken">
    <h3>New Appointment Time</h3>
    <p>
      {{ data.offerFrom | moment | amDateFormat: dateFormat }}
      - {{ data.offerTo | moment | amDateFormat: timeFormat }}
    </p>
  </div>
</div>
