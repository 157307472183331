@if (interaction$ | async; as interaction) {
  <div class="flex flex-row items-center gap-2">
    @if (schedulingEvent$ | async; as schedulingEvent) {
      <pr-scheduling-event-interaction-header
        class="flex flex-auto"
        [schedulingEvent]="schedulingEvent"
      />
    } @else {
      @if (!hideIcon) {
        <div class="py-1">
          <mat-icon
            class="event-type-icon {{
              interaction | map: getInteractionTypeColourClass
            }}"
          >
            {{ interaction | map: getInteractionTypeIcon }}
          </mat-icon>
        </div>
      }

      <div class="flex flex-auto flex-col">
        <div class="text-xs">
          <pr-content-view [content]="title$ | ngrxPush" />
        </div>
        <div class="text-xs opacity-50">
          {{ interaction.createdAt | moment | amDateFormat: dateFormat }}
        </div>
      </div>
    }

    <div class="flex flex-row">
      <pr-interaction-actions
        [pinnableTypes]="pinnableTypes$ | async"
        [canEdit]="canEdit$ | async"
        [interaction]="interaction"
        (interactionDeleted)="interactionDeleted.emit($event)"
        (interactionUpdated)="interactionUpdated.emit($event)"
      />
    </div>
  </div>
}
