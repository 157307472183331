import { type IToothRef } from '@principle-theorem/principle-core/interfaces';
import {
  FACIAL_PERIO_DATA_POINTS,
  PALATAL_PERIO_DATA_POINTS,
  type PerioDataPoint,
  type PerioMeasurement,
} from '@principle-theorem/principle-core/interfaces';
import { isNumber } from 'lodash';
import { type MonoTypeOperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * The intention behind this interface is to provide an object that represents /
 * joins a hands on table with a table containing metadata for each cell.
 * The metadata can be used to provide overarching information separate from
 * the data. EG. nestedHeaders, rowHeaders, mergedCells.
 */
export interface IPerioTable {
  label: string;
  rows: IPerioTableCell[][];
  disabled: boolean;
}

export interface ICellMetadata {
  toothRef: IToothRef;
  dataPath: string; // TODO: use Typescript 4.1 key value stuff
  measurement: PerioMeasurement;
  dataPoint: PerioDataPoint;
}

export interface IPerioTableCell {
  metadata: ICellMetadata;
  value: PerioMeasurementValue;
}

export type PerioMeasurementValue = number | undefined;

export function isPerioMeasurementValue(
  value: unknown
): value is PerioMeasurementValue {
  return value === undefined || isNumber(value);
}

export function resolveTableMetadata(
  perioTable: IPerioTable,
  row: number,
  column: number
): ICellMetadata {
  return perioTable.rows[row][column].metadata;
}

export enum PerioTableSide {
  Facial = 'facial',
  Palatal = 'palatal',
}

export function filterBySide(
  side: PerioTableSide
): (cell: IPerioTableCell, index: number, all: IPerioTableCell[]) => boolean {
  const validPoints: PerioDataPoint[] =
    side === PerioTableSide.Facial
      ? FACIAL_PERIO_DATA_POINTS
      : PALATAL_PERIO_DATA_POINTS;
  return (cell: IPerioTableCell) =>
    validPoints.includes(cell.metadata.dataPoint);
}

export function filterTableBySide(
  side: PerioTableSide
): MonoTypeOperatorFunction<IPerioTable> {
  return map((perioTable) => ({
    ...perioTable,
    rows: perioTable.rows.map((row) => row.filter(filterBySide(side))),
  }));
}
