import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import {
  AutomationDialogService,
  AutomationsFacade,
  IAutomatedNotificationDialogData,
  isAutomatedNotificationAutomation,
  isGeneratedTaskAutomation,
  type AutomationEntity,
  type IRescheduleAutomationListData,
} from '@principle-theorem/ng-automations';
import {
  Appointment,
  TimezoneResolver,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  IPatient,
  TemplateScope,
  type IAppointment,
  type IAppointmentScopeData,
  type IAutomatedNotification,
  type IAutomation,
  type IEvent,
  type IGeneratedTask,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  addDoc,
  asyncForEach,
  type EntityModel,
  filterUndefined,
  isWithRef,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  type Observable,
} from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AutomationRescheduleStore } from './appointment-automation-reschedule.store';

interface ITypeGroupedAutomations {
  tasks: IRescheduleAutomationListData[];
  notifications: IRescheduleAutomationListData[];
}

@Component({
  selector: 'pr-appointment-automations-reschedule',
  templateUrl: './appointment-automations-reschedule.component.html',
  styleUrls: ['./appointment-automations-reschedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AutomationRescheduleStore],
})
export class AppointmentAutomationsRescheduleComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  automationGroups$: Observable<ITypeGroupedAutomations>;
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  newEvent$ = new BehaviorSubject<IEvent | undefined>(undefined);
  appointment$ = new BehaviorSubject<IAppointment | undefined>(undefined);
  treatmentStep$ = new ReplaySubject<ITreatmentStep | WithRef<ITreatmentStep>>(
    1
  );
  hasAutomations$: Observable<boolean>;

  @Input()
  set treatmentStep(
    step: ITreatmentStep | WithRef<ITreatmentStep> | undefined
  ) {
    if (step) {
      this.treatmentStep$.next(step);
      return;
    }
    this.treatmentStep$.next(undefined);
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  @Input()
  set newEvent(selectedEvent: IEvent | undefined) {
    this.newEvent$.next(selectedEvent ?? undefined);
  }

  @Input()
  set appointment(appointment: IAppointment | undefined) {
    this.appointment$.next(appointment ?? undefined);
  }

  constructor(
    private _automationsFacade: AutomationsFacade,
    private _store: AutomationRescheduleStore,
    private _automationDialogService: AutomationDialogService
  ) {
    this.hasAutomations$ = this._store.automationListData$.pipe(
      map((listData) => listData.length > 0)
    );

    this._store.loadAutomationConfigurations(
      combineLatest([
        this.treatmentStep$.pipe(filterUndefined()),
        this.newEvent$,
      ])
    );

    this._store.setTimezone(
      this.newEvent$.pipe(
        filterUndefined(),
        switchMap((event) => TimezoneResolver.fromEvent(event))
      )
    );

    this.automationGroups$ = this._store.automationListData$.pipe(
      map((listData) => ({
        tasks: listData.filter((data) =>
          isGeneratedTaskAutomation(data.automation)
        ),
        notifications: listData.filter((data) =>
          isAutomatedNotificationAutomation(data.automation)
        ),
      }))
    );
  }

  ngOnDestroy(): void {
    this._automationsFacade.resetState();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async addTask(): Promise<void> {
    const newEvent = await snapshot(this.newEvent$);
    if (!newEvent) {
      return;
    }
    const appointment = await snapshot(this.appointment$);
    const triggerAfterDate = appointment
      ? Appointment.getCompletedAt(appointment)
      : undefined;
    const automation = await this._automationDialogService.openTaskAdd(
      newEvent,
      triggerAfterDate
    );
    this._store.addAutomation({ automation, newEvent });
  }

  async addNotification(): Promise<void> {
    const newEvent = await snapshot(this.newEvent$);
    if (!newEvent) {
      return;
    }
    const appointment = await snapshot(this.appointment$);
    const triggerAfterDate = appointment
      ? Appointment.getCompletedAt(appointment)
      : undefined;

    // TODO: Render based on actual scope data. https://app.clickup.com/t/860qzurhu
    const scopeData: IAppointmentScopeData = {} as IAppointmentScopeData;
    const dialogData: IAutomatedNotificationDialogData = {
      scope: TemplateScope.Appointment,
      scopeData,
      useRelativeTime: true,
    };
    const automation =
      await this._automationDialogService.openNotificationDialog(
        dialogData,
        newEvent,
        triggerAfterDate
      );
    this._store.addAutomation({ automation, newEvent });
  }

  async edit(automation: AutomationEntity): Promise<void> {
    if (isAutomatedNotificationAutomation(automation)) {
      return this._editNotification(automation);
    }
    if (isGeneratedTaskAutomation(automation)) {
      return this._editTask(automation);
    }
  }

  async update(): Promise<void> {
    await this._saveAutomations();
  }

  async setPending(automation: AutomationEntity): Promise<void> {
    const event = await snapshot(this.newEvent$);
    await this._store.enableAutomation(automation, event);
  }

  async cancel(automation: AutomationEntity): Promise<void> {
    await this._store.disableAutomation(automation);
  }

  private async _saveAutomations(): Promise<void> {
    await snapshot(this._store.updating$.pipe(filter((updating) => !updating)));
    const listData = await snapshot(this._store.automationListData$);
    const automations = listData.map((data) => ({
      ...data.automation,
      ...data.changes,
      triggerDate: data.newDate,
    }));

    const step = await snapshot(this.treatmentStep$);
    if (!isWithRef(step)) {
      this._automationsFacade.setAutomations(automations);
      return;
    }

    await asyncForEach(automations, async (automation) => {
      if (isWithRef(automation)) {
        await Firestore.patchDoc(automation.ref, automation);
        return;
      }

      await addDoc(TreatmentStep.automationCol(step), automation);
    });
  }

  private async _editTask(
    automation: EntityModel<IAutomation<IGeneratedTask>>
  ): Promise<void> {
    const newEvent = await snapshot(this.newEvent$);
    if (!newEvent) {
      return;
    }

    const update = await this._automationDialogService.openTaskEdit(
      automation.data,
      newEvent,
      undefined,
      automation.triggerAfterDate
    );

    if (!update) {
      return;
    }
    await this._store.updateAutomation(automation, update);
  }

  private async _editNotification(
    automation: EntityModel<IAutomation<IAutomatedNotification>>
  ): Promise<void> {
    const newEvent = await snapshot(this.newEvent$);
    if (!newEvent) {
      return;
    }
    // TODO: Render based on actual scope data. https://app.clickup.com/t/860qzurhu
    const scopeData: IAppointmentScopeData = {} as IAppointmentScopeData;
    const dialogData: IAutomatedNotificationDialogData = {
      scope: TemplateScope.Appointment,
      scopeData,
      notification: automation.data,
      triggerDate: automation.triggerDate,
      useRelativeTime: true,
      configRef: automation.configRef,
    };
    const update = await this._automationDialogService.openNotificationDialog(
      dialogData,
      newEvent,
      automation.triggerAfterDate
    );
    if (!update) {
      return;
    }
    await this._store.updateAutomation(automation, update);
  }
}
