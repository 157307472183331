import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { getSchemaSize } from '@principle-theorem/editor';
import { DateService } from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { Appointment } from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IInteractionV2,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { multiFilter, snapshot, type WithRef } from '@principle-theorem/shared';
import { last } from 'lodash';
import { type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppointmentInteractionsDialogComponent } from '../../appointment-interactions-dialog/appointment-interactions-dialog.component';

@Component({
  selector: 'pr-appointment-scheduling-notes',
  templateUrl: './appointment-scheduling-notes.component.html',
  styleUrls: ['./appointment-scheduling-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentSchedulingNotesComponent {
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);
  patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);
  lastInteraction$: Observable<IInteractionV2 | undefined>;

  constructor(public dateService: DateService, private _dialog: MatDialog) {
    this.lastInteraction$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.interactions$(appointment)),
      multiFilter((interaction) => getSchemaSize(interaction.content) > 0),
      map((interactions) => last(interactions))
    );
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  async showNotes(): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    const patient = await snapshot(this.patient$);
    const config: MatDialogConfig = DialogPresets.large({
      height: '80%',
      data: {
        appointment,
        patient,
      },
    });

    this._dialog.open(AppointmentInteractionsDialogComponent, config);
  }
}
