import { type ITag } from '@principle-theorem/principle-core/interfaces';
import {
  type AtLeast,
  type INamedDocument,
  type IXSLXExport,
  type XSLXCell,
} from '@principle-theorem/shared';
import type { Column } from 'exceljs';
import { sortBy } from 'lodash';

export interface IMediaCategoryXSLX {
  category: string;
  mapTo: string;
}

export const MEDIA_CATEGORY_HEADERS: AtLeast<Column, 'key' | 'header'>[] = [
  {
    key: 'category',
    header: 'Category',
    width: 30,
  },
  {
    key: 'mapTo',
    header: 'Map To Tag',
    width: 30,
  },
];

export class MediaCategoriesToXSLX
  implements IXSLXExport<string, IMediaCategoryXSLX>
{
  headers = MEDIA_CATEGORY_HEADERS;

  constructor(private _mediaTags: INamedDocument<ITag>[]) {}

  translate(
    categories: string[]
  ): Record<keyof IMediaCategoryXSLX, XSLXCell>[] {
    const options = sortBy(this._mediaTags, 'name').map((tag) => tag.name);
    return categories.map((category) => ({
      category: {
        value: category,
      },
      mapTo: {
        value: undefined,
        dataValidation: {
          type: 'list',
          formulae: [`"${options.join(',')}"`],
        },
      },
    }));
  }
}
