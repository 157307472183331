import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  type IBreadcrumb,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { type ILab } from '@principle-theorem/principle-core/interfaces';
import {
  doc$,
  filterUndefined,
  findProp,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { LabJobsFacade } from '../../../store/lab-jobs.facade';

@Component({
    selector: 'pr-lab-tabs',
    templateUrl: './lab-tabs.component.html',
    styleUrls: ['./lab-tabs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LabTabsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  lab$: Observable<WithRef<ILab>>;
  trackByLink = TrackByFunctions.label<IBreadcrumb>();
  breadcrumbs: IBreadcrumb[] = [{ label: 'Labs', path: ['../'] }];
  navLinks: IBreadcrumb[] = [
    { label: 'Profile', path: ['./profile'] },
    { label: 'Lab Jobs', path: ['./lab-jobs'] },
    { label: 'Lab Job Types', path: ['./lab-job-types'] },
    { label: 'Contacts', path: ['./lab-contacts'] },
    { label: 'History', path: ['./history'] },
  ];

  constructor(
    protected _route: ActivatedRoute,
    private _labJobsFacade: LabJobsFacade
  ) {
    this.lab$ = this._route.data.pipe(
      findProp<WithRef<ILab>>('lab'),
      filterUndefined(),
      switchMap((data) => doc$(data.ref)),
      tap((lab) => this._labJobsFacade.loadLabJobsForLab(lab.ref.id))
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._labJobsFacade.unsubscribeLabJobs();
  }
}
