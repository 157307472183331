import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MediaDisplayItem } from '../../media-display-item';
import {
  type IMedia,
  PatientMediaSize,
} from '@principle-theorem/principle-core/interfaces';
import { type Observable, ReplaySubject } from 'rxjs';
import { Storage } from '@angular/fire/storage';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import { map } from 'rxjs/operators';
import { WithRef } from '@principle-theorem/shared';

@Component({
  selector: 'pr-media-list-item',
  templateUrl: './media-list-item.component.html',
  styleUrls: ['./media-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaListItemComponent extends MediaDisplayItem {
  size$ = new ReplaySubject<PatientMediaSize>(1);
  fileIconSize$: Observable<string>;

  @Input()
  set media(media: WithRef<IMedia>) {
    if (media) {
      this.media$.next(media);
      this.namedDocsToTags.namedDocs$.next(media.tags);
    }
  }

  @Input()
  set size(size: PatientMediaSize) {
    if (size) {
      this.size$.next(size);
    }
  }

  constructor(storage: Storage, global: GlobalStoreService) {
    super(storage, global);
    this.fileIconSize$ = this.size$.pipe(
      map((size) => {
        switch (size) {
          case PatientMediaSize.Small:
            return 'sm';
          case PatientMediaSize.Medium:
            return 'md';
          case PatientMediaSize.Large:
            return 'lg';
          default:
            return 'sm';
        }
      })
    );
  }
}
