import {
  type IAppointment,
  type IInteractionV2,
  type IPractice,
  type ISchedulingEvent,
  type ISchedulingEventConditions,
  type ISchedulingEventReason,
  type ISchedulingEventSnapshot,
  type ISchedulingEventSummary,
  InteractionType,
  MentionResourceType,
  SchedulingEventType,
} from '@principle-theorem/principle-core/interfaces';
import {
  type AtLeast,
  type DocumentReference,
  Firestore,
  type ISODateType,
  Timestamp,
  Timezone,
  Transaction,
  type WithRef,
  isSameRef,
  limit,
  query$,
  toMomentTz,
  toTimestamp,
  undeletedQuery,
  where,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { Observable, of } from 'rxjs';
import { isSameTimestamp } from '../event/event';
import { Practice } from '../practice/practice';
import { Appointment } from './appointment';
import {
  VersionedSchema,
  initVersionedSchema,
  toMentionContent,
} from '@principle-theorem/editor';
import { stafferToNamedDoc } from '../common';
import { toMention } from '../mention/mention';

export const SCHEDULING_EVENT_ACTION_LABEL_MAP: {
  [key in SchedulingEventType]: string;
} = {
  [SchedulingEventType.Schedule]: 'scheduled',
  [SchedulingEventType.Reschedule]: 'rescheduled',
  [SchedulingEventType.Cancel]: 'cancelled',
};

export const SCHEDULING_EVENT_INTERACTION_TYPE_MAP: {
  [key in SchedulingEventType]: InteractionType;
} = {
  [SchedulingEventType.Schedule]: InteractionType.AppointmentBook,
  [SchedulingEventType.Reschedule]: InteractionType.AppointmentReschedule,
  [SchedulingEventType.Cancel]: InteractionType.AppointmentCancel,
};
export interface ISchedulingEventDisplay {
  textColour: string;
  backgroundColour: string;
  iconName: string;
}

const SCHEDULING_EVENT_TYPE_COLOUR_MAP: {
  [key in SchedulingEventType]: ISchedulingEventDisplay;
} = {
  [SchedulingEventType.Schedule]: {
    textColour: 'text-green-500',
    backgroundColour: 'bg-green-100',
    iconName: 'event_available',
  },
  [SchedulingEventType.Reschedule]: {
    textColour: 'text-cyan-500',
    backgroundColour: 'bg-cyan-100',
    iconName: 'event_upcoming',
  },
  [SchedulingEventType.Cancel]: {
    textColour: 'text-red-500',
    backgroundColour: 'bg-red-100',
    iconName: 'event_busy',
  },
};

export class SchedulingEvent {
  static init(
    overrides: AtLeast<
      ISchedulingEvent,
      'scheduledByPractice' | 'reasonSetManually' | 'schedulingConditions'
    >
  ): ISchedulingEvent {
    return {
      deleted: false,
      scheduledAt: toTimestamp(),
      eventType: overrides.schedulingConditions.eventType,
      ...overrides,
    };
  }

  static buildEventSnapshot(
    appointment: AtLeast<IAppointment, 'event' | 'practitioner'>
  ): ISchedulingEventSnapshot | undefined {
    if (!appointment.event) {
      return;
    }
    return {
      practitionerRef: appointment.practitioner.ref,
      practiceRef: appointment.event.practice.ref,
      from: appointment.event.from,
      to: appointment.event.to,
    };
  }

  static getInteractionType(
    schedulingEvent: ISchedulingEvent
  ): InteractionType {
    return SCHEDULING_EVENT_INTERACTION_TYPE_MAP[schedulingEvent.eventType];
  }

  static getActionlabel(schedulingEvent: ISchedulingEvent): string {
    return SCHEDULING_EVENT_ACTION_LABEL_MAP[schedulingEvent.eventType];
  }

  static async resolveStafferMention(
    schedulingEvent: WithRef<ISchedulingEvent>
  ): Promise<VersionedSchema | undefined> {
    if (!schedulingEvent.scheduledByStaffer) {
      return;
    }
    const staffer = await Firestore.getDoc(schedulingEvent.scheduledByStaffer);
    const namedDoc = stafferToNamedDoc(staffer);
    const mention = toMentionContent(
      toMention(namedDoc, MentionResourceType.Staffer)
    );
    return initVersionedSchema([mention]);
  }

  static willCauseSchedulingEvent(
    before?: ISchedulingEventSnapshot,
    after?: ISchedulingEventSnapshot
  ): boolean {
    const stafferChanged = !isSameRef(
      before?.practitionerRef,
      after?.practitionerRef
    );
    const practiceChanged = !isSameRef(before?.practiceRef, after?.practiceRef);
    const startChanged = !isSameTimestamp(before?.from, after?.from);
    const endChanged = !isSameTimestamp(before?.to, after?.to);
    return startChanged || endChanged || stafferChanged || practiceChanged;
  }

  static getSchedulingConditions(
    timezone: Timezone,
    beforeStartTime?: Timestamp,
    afterStartTime?: Timestamp,
    currentTime = toTimestamp(),
    fillingGap = false
  ): ISchedulingEventConditions {
    const before = beforeStartTime
      ? toMomentTz(beforeStartTime, timezone)
      : undefined;
    const after = afterStartTime
      ? toMomentTz(afterStartTime, timezone)
      : undefined;

    const moveToSameDay = before && after ? before.isSame(after, 'day') : false;

    const now = toMomentTz(currentTime, timezone);

    const hrsBeforeAppointmentScheduledFor = before
      ? before.diff(now, 'hours')
      : undefined;
    const hrsBeforeAppointmentWasScheduled = after
      ? after.diff(now, 'hours')
      : undefined;

    const eventType =
      before && after
        ? SchedulingEventType.Reschedule
        : !before
          ? SchedulingEventType.Schedule
          : SchedulingEventType.Cancel;
    return {
      eventType,
      moveToSameDayDefault: moveToSameDay,
      hrsBeforeAppointmentWasScheduled,
      hrsBeforeAppointmentScheduledFor,
      fillGapDefault: fillingGap,
    };
  }

  static getDisplay(eventType: SchedulingEventType): ISchedulingEventDisplay {
    return SCHEDULING_EVENT_TYPE_COLOUR_MAP[eventType];
  }

  static async getReason(
    schedulingEvent: ISchedulingEvent,
    transaction?: Transaction
  ): Promise<WithRef<ISchedulingEventReason> | undefined> {
    return schedulingEvent.reason
      ? Firestore.getDoc(schedulingEvent.reason, transaction)
      : undefined;
  }

  static getReason$(
    schedulingEvent: ISchedulingEvent
  ): Observable<WithRef<ISchedulingEventReason> | undefined> {
    return schedulingEvent.reason
      ? Firestore.doc$(schedulingEvent.reason)
      : of(undefined);
  }

  static appointmentRef(
    schedulingEvent: WithRef<ISchedulingEvent>
  ): DocumentReference<IAppointment> {
    return Firestore.getParentDocRef<IAppointment>(schedulingEvent.ref);
  }

  static queryByAffectsPractice$(
    practiceRef: DocumentReference<IPractice>,
    eventTypes: SchedulingEventType[],
    isoDate: ISODateType
  ): Observable<WithRef<ISchedulingEventSummary>[]> {
    if (!eventTypes.length) {
      return of([]);
    }
    return query$(
      undeletedQuery(Practice.schedulingEventSummaryCol({ ref: practiceRef })),
      where('eventType', 'in', eventTypes),
      where('affectsPracticeDates', 'array-contains', isoDate)
    );
  }

  static queryByScheduledByPractice$(
    practiceRef: DocumentReference<IPractice>,
    eventTypes: SchedulingEventType[],
    isoDate: ISODateType
  ): Observable<WithRef<ISchedulingEventSummary>[]> {
    if (!eventTypes.length) {
      return of([]);
    }
    return query$(
      undeletedQuery(Practice.schedulingEventSummaryCol({ ref: practiceRef })),
      where('eventType', 'in', eventTypes),
      where('scheduledByPracticeDate', '==', isoDate)
    );
  }

  static async findInteraction(
    schedulingEvent: WithRef<ISchedulingEvent>
  ): Promise<WithRef<IInteractionV2> | undefined> {
    const interactions = await Firestore.getDocs(
      Appointment.interactionCol({ ref: this.appointmentRef(schedulingEvent) }),
      where('schedulingEvent', '==', schedulingEvent.ref),
      limit(1)
    );
    return first(interactions);
  }
}
