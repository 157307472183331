import { NgModule } from '@angular/core';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ReportingCoreModule } from '../core/reporting-core.module';
import { ReportingComponentsModule } from '../reporting-components/reporting-components.module';
import { AcquisitionsComponent } from './acquisitions.component';

@NgModule({
  imports: [ReportingCoreModule, ReportingComponentsModule, NgSharedModule],
  declarations: [AcquisitionsComponent],
})
export class AcquisitionsModule {}
