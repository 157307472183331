import {
  AnyAutomationConfiguration,
  AutomationStatus,
  BrandCollection,
  IAutomatedFormIssueConfiguration,
  IAutomation,
  ITreatmentStep,
  TreatmentStepAutomation,
  TreatmentStepCollection,
  isAutomatedFormIssueConfiguration,
  isAutomatedNotificationConfiguration,
  isGeneratedTask,
  type IAutomatedNotificationConfiguration,
  type IAutomationConfiguration,
  type IBrand,
  type IGeneratedTaskConfiguration,
  type IPractice,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  Query,
  Timestamp,
  all$,
  collectionGroupQuery,
  deleteDoc,
  getCountFromServer,
  isSameRef,
  query$,
  subCollection,
  undeletedQuery,
  where,
  type CollectionReference,
  type DocumentReference,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class AutomationConfiguration {
  static init(
    overrides?: Partial<IAutomationConfiguration>
  ): IAutomationConfiguration {
    return {
      deleted: false,
      isActive: false,
      treatmentRefs: [],
      ...overrides,
    };
  }

  static col<T extends IAutomationConfiguration = IAutomationConfiguration>(
    brand: IReffable<IBrand>
  ): CollectionReference<T> {
    return subCollection<T>(
      brand.ref,
      BrandCollection.AutomationConfigurations
    );
  }

  static all$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<AnyAutomationConfiguration>[]> {
    return all$(undeletedQuery(AutomationConfiguration.col(brand)));
  }

  static getNotificationConfigurations$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<IAutomatedNotificationConfiguration>[]> {
    return AutomationConfiguration.all$(brand).pipe(
      map((configs) =>
        configs.filter(
          (config): config is WithRef<IAutomatedNotificationConfiguration> =>
            isAutomatedNotificationConfiguration(config)
        )
      )
    );
  }

  static getGeneratedTaskConfigurations$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<IGeneratedTaskConfiguration>[]> {
    return AutomationConfiguration.all$(brand).pipe(
      map((configs) =>
        configs.filter(
          (config): config is WithRef<IGeneratedTaskConfiguration> =>
            isGeneratedTask(config)
        )
      )
    );
  }

  static getAutomatedFormIssueConfigurations$(
    brand: IReffable<IBrand>
  ): Observable<WithRef<IAutomatedFormIssueConfiguration>[]> {
    return AutomationConfiguration.all$(brand).pipe(
      map((configs) =>
        configs.filter(
          (config): config is WithRef<IAutomatedFormIssueConfiguration> =>
            isAutomatedFormIssueConfiguration(config)
        )
      )
    );
  }

  static isGlobal(config: IAutomationConfiguration): boolean {
    return config.treatmentRefs.length === 0;
  }

  static isActive(config: IAutomationConfiguration): boolean {
    return config.isActive;
  }

  static isForPractice(
    config: IAutomationConfiguration,
    practiceRef: DocumentReference<IPractice>
  ): boolean {
    return config.practiceRef
      ? isSameRef(config.practiceRef, practiceRef)
      : true;
  }

  static isForTreatmentStep(
    config: IAutomationConfiguration,
    treatmentStep: ITreatmentStep
  ): boolean {
    const matchesTreatmentConfiguration = treatmentStep.treatments
      .map((treatment) => treatment.config.ref)
      .some((treatmentConfigRef) =>
        config.treatmentRefs.some((treatmentRef) =>
          isSameRef(treatmentConfigRef, treatmentRef)
        )
      );

    return (
      AutomationConfiguration.isGlobal(config) || matchesTreatmentConfiguration
    );
  }

  static async addTreatmentRef(
    config: WithRef<IAutomationConfiguration>,
    treatmentRef: DocumentReference<ITreatmentConfiguration>
  ): Promise<void> {
    const treatmentRefs = [...config.treatmentRefs, treatmentRef];
    await Firestore.patchDoc(config.ref, { treatmentRefs });
  }

  static async removeTreatmentRef(
    config: WithRef<IAutomationConfiguration>,
    treatmentRef: DocumentReference<ITreatmentConfiguration>
  ): Promise<void> {
    const treatmentRefs = config.treatmentRefs.filter(
      (treatment) => !isSameRef(treatment, treatmentRef)
    );

    if (treatmentRefs.length === 0) {
      await this.deleteConfiguration(config, { treatmentRefs });
      return;
    }
    await Firestore.patchDoc(config.ref, { treatmentRefs });
  }

  static async deleteConfiguration(
    config: WithRef<IAutomationConfiguration>,
    data?: Partial<IAutomationConfiguration>
  ): Promise<void> {
    await deleteDoc(config.ref, undefined, undefined, {
      ...data,
      isActive: false,
    });
    if (isAutomatedNotificationConfiguration(config)) {
      await deleteDoc(config.templateRef);
    }
  }

  static automations$(
    config: IReffable<IAutomationConfiguration>,
    dateFrom: Timestamp,
    dateTo: Timestamp
  ): Observable<WithRef<IAutomation<TreatmentStepAutomation>>[]> {
    return query$(
      this.automationsQuery(config.ref),
      where('triggerDate', '>=', dateFrom),
      where('triggerDate', '<=', dateTo)
    );
  }

  static automationsQuery(
    configRef: DocumentReference<IAutomationConfiguration>,
    onlyWithStatus?: AutomationStatus[]
  ): Query<IAutomation<TreatmentStepAutomation>> {
    const brandRef = Firestore.getParentDocRef<IBrand>(configRef);
    const constraints = [
      where('brandRef', '==', brandRef),
      where('configRef', '==', configRef),
    ];
    if (onlyWithStatus) {
      constraints.push(where('status', 'in', onlyWithStatus));
    }
    return collectionGroupQuery<IAutomation<TreatmentStepAutomation>>(
      TreatmentStepCollection.Automations,
      ...constraints
    );
  }

  static async getAutomationCount(
    configRef: DocumentReference<IAutomationConfiguration>,
    onlyWithStatus?: AutomationStatus[]
  ): Promise<number> {
    const colQuery = this.automationsQuery(configRef, onlyWithStatus);
    const result = await getCountFromServer(colQuery);
    return result.data().count;
  }
}
