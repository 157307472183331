import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DYNAMIC_COMPONENT_DATA } from '@principle-theorem/ng-shared';
import {
  ROMEXIS_CAPTURE_MODES,
  ROMEXIS_INTEGRATION,
  RomexisCaptureMode,
} from '@principle-theorem/principle-bridge-core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IIntegrationActionData } from '../../../../integration-action-data';
import { BridgeDeviceSelectorService } from '../../../bridge-device-selector-dialog/bridge-device-selector.service';
import { RomexisFeatureService } from '../romexis-feature.service';

@Component({
  selector: 'pr-romexis-open-patient',
  templateUrl: './romexis-open-patient.component.html',
  styleUrl: './romexis-open-patient.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RomexisOpenPatientComponent {
  isDisabled$: Observable<boolean>;
  captureModes = ROMEXIS_CAPTURE_MODES;

  constructor(
    @Inject(DYNAMIC_COMPONENT_DATA)
    private _data: IIntegrationActionData,
    private _romexis: RomexisFeatureService,
    private _bridgeDeviceSelector: BridgeDeviceSelectorService
  ) {
    this.isDisabled$ = of(this._data.patient).pipe(
      map((patient) => !patient.dateOfBirth)
    );
  }

  async openRomexis(mode: RomexisCaptureMode): Promise<void> {
    const deviceRef =
      await this._bridgeDeviceSelector.selectDevice(ROMEXIS_INTEGRATION);

    if (!deviceRef) {
      return;
    }

    await this._romexis.openPatient(this._data.patient, mode, deviceRef);
  }
}
