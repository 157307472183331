import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  type IPopulatedTemplate,
  MentionToTemplates,
} from '@principle-theorem/ng-interactions';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import {
  type INgSharedConfig,
  NG_SHARED_CONFIG,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { toMention } from '@principle-theorem/principle-core';
import {
  type IPatient,
  type ITemplateDefinition,
  type ITemplatesWithContext,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isSameRef,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface ICreatePatientDocumentResponse {
  name: string;
  template?: IPopulatedTemplate;
}

@Component({
    selector: 'pr-create-patient-document-dialog',
    templateUrl: './create-patient-document-dialog.component.html',
    styleUrls: ['./create-patient-document-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CreatePatientDocumentDialogComponent {
  private _sharedConfig: INgSharedConfig = inject(NG_SHARED_CONFIG);
  trackByTemplate = TrackByFunctions.uniqueId();
  templates$: Observable<ITemplatesWithContext>;
  form = new TypedFormGroup<ICreatePatientDocumentResponse>({
    name: new TypedFormControl<string>('', Validators.required),
    template: new TypedFormControl<IPopulatedTemplate>(),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) patient: WithRef<IPatient>,
    private _dialogRef: MatDialogRef<
      CreatePatientDocumentDialogComponent,
      ICreatePatientDocumentResponse
    >,
    private _scopeFacade: CurrentScopeFacade
  ) {
    const brand$ = this._scopeFacade.currentBrand$.pipe(filterUndefined());
    const practice$ =
      this._scopeFacade.currentPractice$.pipe(filterUndefined());
    this.templates$ = combineLatest([brand$, practice$]).pipe(
      switchMap(([brand, practice]) => {
        const mentionToTemplates = new MentionToTemplates(
          practice.ref,
          this._sharedConfig.appUrl
        );
        return mentionToTemplates.getTemplates(
          toMention(patient, MentionResourceType.Patient),
          brand.ref
        );
      }),
      filterUndefined()
    );
  }

  submit(): void {
    if (this.form.valid) {
      this._dialogRef.close(this.form.value);
    }
  }

  compareFn(
    a?: WithRef<ITemplateDefinition>,
    b?: WithRef<ITemplateDefinition>
  ): boolean {
    return isSameRef(a, b);
  }
}
