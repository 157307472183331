<mat-toolbar color="accent">Print Settings</mat-toolbar>

<mat-dialog-content>
  <pr-system-template-selectors
    [templates]="data"
    (templateChange)="selectedTemplates$.next($event)"
  />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button (click)="close()">Cancel</button>
    <button mat-flat-button color="primary" (click)="print()">Print</button>
  </pt-buttons-container>
</mat-dialog-actions>
