import { initVersionedSchema, toTextContent } from '@principle-theorem/editor';
import {
  Brand,
  Event,
  Interaction,
  TreatmentPlan,
  TreatmentStep,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  EventType,
  IAssociatedTreatment,
  IDestinationEntity,
  IDestinationEntityJobRunOptions,
  ITranslationMap,
  InteractionType,
  ParticipantType,
  SkippedDestinationEntityRecord,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  type FailedDestinationEntityRecord,
  type IAppointment,
  type IBrand,
  type IDestinationEntityRecord,
  type IEvent,
  type IGetRecordResponse,
  type IInteractionV2,
  type IPatient,
  type IPractice,
  type IPracticeMigration,
  type IStaffer,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  DEFAULT_TIMEZONE,
  Firestore,
  HISTORY_DATE_FORMAT,
  asyncForAll,
  asyncForEach,
  find$,
  getError,
  initFirestoreModel,
  isINamedDocument,
  multiMap,
  snapshot,
  snapshotCombineLatest,
  toISODate,
  toMoment,
  toNamedDocument,
  toTimestamp,
  where,
  type DocumentReference,
  type INamedDocument,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, sortBy } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, from, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import {
  BasePatientAppointmentDestinationEntity,
  IPatientAppointmentBuildData,
  IPatientAppointmentJobData,
  IPatientAppointmentMigrationData,
  PATIENT_APPOINTMENT_RESOURCE_TYPE,
} from '../../../destination/entities/patient-appointments';
import { findTreatmentStepForAppointment } from '../../../destination/entities/patient-treatment-plans';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { PracticeIdFilter } from '../../../destination/filters/practice-id-filter';
import { getPractitionerOrDefaultMapping } from '../../../mappings/staff';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  ID4WPatient,
  ID4WPatientTranslations,
  PatientSourceEntity,
} from '../../source/entities/patient';
import {
  ID4WAppointmentFilters,
  PatientAppointmentSourceEntity,
  type ID4WAppointment,
  type ID4WAppointmentTranslations,
} from '../../source/entities/patient-appointment';
import {
  ID4WPatientTreatment,
  ID4WPatientTreatmentFilters,
  ID4WPatientTreatmentTranslations,
  PatientTreatmentSourceEntity,
} from '../../source/entities/patient-treatment';
import { D4WAppointmentBookToPractitionerMappingHandler } from '../mappings/appointment-book-to-practitioner';
import {
  AppointmentStatusMapType,
  D4WAppointmentStatusMappingHandler,
} from '../mappings/appointment-statuses';
import { D4WExcludedAppointmentBooksMappingHandler } from '../mappings/exclude-appointment-books';
import { D4WPracticeMappingHandler } from '../mappings/practices';
import { D4WStafferMappingHandler } from '../mappings/staff';
import {
  PatientTreatmentPlanDestinationEntity,
  getStepIdentifier,
} from './patient-treatment-plans';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';

export const PATIENT_APPOINTMENT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_APPOINTMENT_RESOURCE_TYPE,
    label: 'Patient Appointments',
    description: `Unfortunately D4W doesn't have a relationship tying treatments to the appointment that they were performed in for future appointments. For this reason we can't tie exactly what was done in an appointment without having to referr to the clinical notes.

    Principle has an individual treatment step for each appointment. As such, we have created an empty treatment step for each appointment.

    The following rules are used to determine the status of the appointment. They're in order of precedence so if the first condition is met the rest are ignored:
    - Checked Out: If the patient is marked as checked out
    - Checked In: If the patient is marked as checked in
    - Arrived: If the patient is marked as arrived
    - Confirmed: If a "confirm type" appointment status is set
    - Cancelled: If a "cancel type" appointment status is set
    - Completed: If the appointment is in the past
    - Scheduled: If the appointment is in the future
    - Unscheduled: If no other conditions can be met
    `,
  },
});

export const D4W_MIGRATED_APPOINTMENTS_PLAN_NAME =
  'D4W - Migrated Appointments';

export interface IJobData
  extends IPatientAppointmentJobData<ID4WPatient, ID4WPatientTranslations> {
  practices: WithRef<ITranslationMap<IPractice>>[];
  excludedAppointmentBookIds: string[];
  appointmentBookToPractitioner: WithRef<ITranslationMap<IStaffer>>[];
}

export class PatientAppointmentDestinationEntity extends BasePatientAppointmentDestinationEntity<
  ID4WPatient,
  ID4WPatientTranslations,
  IJobData
> {
  destinationEntity = PATIENT_APPOINTMENT_DESTINATION_ENTITY;
  patientSourceEntity = new PatientSourceEntity();
  treatmentPlanName = D4W_MIGRATED_APPOINTMENTS_PLAN_NAME;

  override filters = [
    new PracticeIdFilter<IJobData>((jobData) =>
      jobData.sourcePatient.data.data.practice_id.toString()
    ),
    new PatientIdFilter<IJobData>((jobData) =>
      jobData.sourcePatient.data.data.patient_id.toString()
    ),
  ];

  override canMigrateByDateRange = true;
  override canMigrateByIdRange = true;

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
    treatments: new PatientTreatmentSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
    treatmentPlans: new PatientTreatmentPlanDestinationEntity(),
  };

  customMappings = {
    staff: new D4WStafferMappingHandler(),
    practices: new D4WPracticeMappingHandler(),
    appointmentStatuses: new D4WAppointmentStatusMappingHandler(),
    excludedAppointmentBooks: new D4WExcludedAppointmentBooksMappingHandler(),
    appointmentBookToPractitioner:
      new D4WAppointmentBookToPractitionerMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IJobData[]> {
    const brand$ = Firestore.getDoc(migration.configuration.brand.ref);
    const practices$ = this.customMappings.practices.getRecords(translationMap);
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords(translationMap),
      translationMap.getByType<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const practitioners$ = from(brand$).pipe(
      switchMap((brand) => Firestore.getDocs(Brand.stafferCol(brand)))
    );
    const appointmentBookToPractitioner$ =
      this.customMappings.appointmentBookToPractitioner.getRecords(
        translationMap
      );
    const excludedAppointmentBooks$ = from(
      this.customMappings.excludedAppointmentBooks.getRecords(translationMap)
    ).pipe(multiMap((book) => book.sourceIdentifier.toString()));

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([
        staff$,
        practitioners$,
        brand$,
        practices$,
        excludedAppointmentBooks$,
        appointmentBookToPractitioner$,
      ]),
    ]).pipe(
      map(
        ([
          sourcePatients,
          [
            staff,
            practitioners,
            brand,
            practices,
            excludedAppointmentBookIds,
            appointmentBookToPractitioner,
          ],
        ]) =>
          sourcePatients.map((sourcePatient) => ({
            sourcePatient,
            staff,
            practitioners,
            brand,
            practices,
            excludedAppointmentBookIds,
            appointmentBookToPractitioner,
          }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IJobData
  ): Promise<
    | IPatientAppointmentMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
    | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients.getSourceRecordId(
      data.sourcePatient.data.data
    );

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `No patient with id ${sourcePatientId}`
      );
    }

    const patient = await Firestore.getDoc(patientRef);

    const sourceAppointments =
      await this.sourceEntities.appointments.filterRecords(
        migration,
        'patientId',
        data.sourcePatient.data.data.patient_id.toString()
      );

    try {
      const appointments: (IPatientAppointmentBuildData | undefined)[] =
        await asyncForAll(sourceAppointments, async (sourceAppointment) => {
          const appointmentUid = this.sourceEntities.appointments
            .getSourceRecordId(sourceAppointment.data.data)
            .toString();
          const appointmentBookId =
            sourceAppointment.data.data.appointment_book_id.toString();

          const shouldExcludeAppointment =
            data.excludedAppointmentBookIds.includes(appointmentBookId);

          if (shouldExcludeAppointment) {
            return;
          }

          const providerId =
            sourceAppointment.data.data.practitioner_id?.toString();

          const practitionerMap = this.resolvePractitioner(
            data,
            appointmentBookId,
            providerId
          );

          if (!practitionerMap) {
            throw new Error(
              `No practitioner with id: ${
                providerId ?? ''
              }, appointmentBookId: ${appointmentBookId}`
            );
          }

          const practiceSourceId =
            sourceAppointment.data.data.practice_id.toString();
          const practiceMap = data.practices.find(
            (practice) => practice.sourceIdentifier === practiceSourceId
          );

          if (!practiceMap?.destinationIdentifier) {
            throw new Error(`No practice with id ${practiceSourceId}`);
          }

          const sourcePracticeId = practiceMap.sourceIdentifier;
          const practice = await Firestore.getDoc(
            practiceMap.destinationIdentifier
          );

          const createdAt = this._determineCreatedAt(sourceAppointment);

          const [appointment, interactions] = await this._buildAppointmentData(
            migration,
            sourcePatientId.toString(),
            patient,
            practitionerMap,
            sourceAppointment,
            sourceAppointments,
            practice,
            sourcePracticeId,
            data.brand,
            createdAt,
            translationMap
          );

          const planUid = `${sourcePatientId}-appointments`;
          const stepUid = `${sourcePatientId}-${appointmentUid}-appointments`;
          const plan = {
            ...TreatmentPlan.init({
              name: D4W_MIGRATED_APPOINTMENTS_PLAN_NAME,
              status: TreatmentPlanStatus.InProgress,
            }),
            sourceIdentifier: planUid,
          };

          const timezone = migration.configuration.timezone;
          const appointmentDate = moment(
            sourceAppointment.data.translations.from.toDate()
          )
            .tz(timezone)
            .format(HISTORY_DATE_FORMAT);

          const step = {
            ...TreatmentStep.init({
              name: `D4W Appointment - ${appointmentDate}`,
              status:
                appointment.status === AppointmentStatus.Complete
                  ? TreatmentStepStatus.Complete
                  : TreatmentStepStatus.Incomplete,
              schedulingRules: {
                duration: appointment.event
                  ? Event.duration(appointment.event)
                  : 0,
              },
            }),
            sourceIdentifier: stepUid,
          };

          return {
            sourcePatientId,
            patientRef,
            appointmentUid,
            createdAt,
            appointment,
            planUid,
            plan,
            stepUid,
            step,
            interactions,
          };
        });

      return {
        patientRef,
        sourcePatientId: sourcePatientId.toString(),
        appointments: compact(appointments),
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  resolvePractitioner(
    data: IJobData,
    appointmentBookId: string,
    providerId?: string
  ): DocumentReference<IStaffer> | undefined {
    const appointmentBookPractitionerMap =
      data.appointmentBookToPractitioner.find(
        (practitionerMap) =>
          practitionerMap.sourceIdentifier === appointmentBookId
      );

    if (appointmentBookPractitionerMap?.sourceLink?.sourceIdentifier) {
      return getPractitionerOrDefaultMapping(
        appointmentBookPractitionerMap.sourceLink.sourceIdentifier,
        data.staff
      )?.destinationIdentifier;
    }

    return getPractitionerOrDefaultMapping(providerId, data.staff)
      ?.destinationIdentifier;
  }

  private _determineCreatedAt(
    data: IGetRecordResponse<
      ID4WAppointment,
      ID4WAppointmentTranslations,
      ID4WAppointmentFilters
    >
  ): Timestamp {
    if (data.data.translations.createdAt) {
      return data.data.translations.createdAt;
    }

    if (toMoment(data.data.translations.from).isBefore(moment())) {
      return data.data.translations.from;
    }

    return toTimestamp();
  }

  private async _buildAppointmentData(
    migration: WithRef<IPracticeMigration>,
    sourcePatientId: string,
    patient: WithRef<IPatient>,
    practitionerRef: DocumentReference<IStaffer>,
    sourceAppointment: IGetRecordResponse<
      ID4WAppointment,
      ID4WAppointmentTranslations
    >,
    patientAppointments: IGetRecordResponse<
      ID4WAppointment,
      ID4WAppointmentTranslations
    >[],
    practice: WithRef<IPractice>,
    sourcePracticeId: string,
    brand: WithRef<IBrand>,
    createdAt: Timestamp,
    translationMap: TranslationMapHandler
  ): Promise<[IPatientAppointmentBuildData['appointment'], IInteractionV2[]]> {
    const practitioner = await Firestore.getDoc(practitionerRef);
    const translations = sourceAppointment.data.translations;
    const { status, interactions, tags } =
      await this._buildStatusAndInteractions(
        sourceAppointment.data.data,
        translations,
        translationMap,
        brand
      );

    if (sourceAppointment.data.data.notes.length) {
      interactions.unshift(
        Interaction.init({
          type: InteractionType.Note,
          title: [toTextContent('Appointment note')],
          content: initVersionedSchema(sourceAppointment.data.data.notes),
          pinned: true,
          createdAt,
        })
      );
    }

    const treatments = await this.sourceEntities.treatments.filterRecords(
      migration,
      'date',
      toISODate(
        sourceAppointment.data.translations.from,
        practice.settings.timezone
      ),
      undefined,
      undefined,
      (treatment) =>
        treatment.data.data.patient_id.toString() === sourcePatientId &&
        treatment.data.data.practice_id.toString() === sourcePracticeId
    );

    const associatedPlan = await resolveAssociatedPlan(
      migration,
      sourcePatientId,
      translationMap,
      treatments,
      sourceAppointment,
      patientAppointments
    );

    const event =
      status !== AppointmentStatus.Unscheduled
        ? getAppointmentEvent(
            sourceAppointment.data.translations,
            patient,
            practice,
            practitioner
          )
        : undefined;

    const appointment: IPatientAppointmentBuildData['appointment'] = {
      event,
      eventHistory: [],
      status,
      statusHistory: [],
      practice: toNamedDocument(practice),
      practitioner: stafferToNamedDoc(practitioner),
      cancellationHistory: [],
      dependencies: [],
      tags,
      ...initFirestoreModel(),
      treatmentPlan: associatedPlan,
    };
    return [appointment, interactions];
  }

  private async _buildStatusAndInteractions(
    appointment: ID4WAppointment,
    translations: ID4WAppointmentTranslations,
    translationMap: TranslationMapHandler,
    brand: WithRef<IBrand>
  ): Promise<
    Pick<IAppointment, 'status' | 'tags'> & { interactions: IInteractionV2[] }
  > {
    const tags: INamedDocument<ITag>[] = [];
    const interactions: IInteractionV2[] = [];
    let status: AppointmentStatus | undefined;

    if (translations.deletedAt) {
      status = AppointmentStatus.Unscheduled;
    }

    // TODO: CU-219c6hc - These date for Beyond Dental are well off. Keep this and decide whether we allow the checks to be turned on for specific practices
    // if (appointment.checked_out_at && translations.checkedOutAt) {
    //   if (!status) {
    //     status = AppointmentStatus.Complete;
    //   }
    //   interactions.push(
    //     Interaction.init({
    //       title: [toTextContent(`Checked out`)],
    //       type: InteractionType.AppointmentCheckOut,
    //       createdAt: translations.checkedOutAt,
    //     })
    //   );
    // }

    // if (appointment.checked_in_at && translations.checkedInAt) {
    //   if (!status) {
    //     status = AppointmentStatus.Complete;
    //   }
    //   interactions.push(
    //     Interaction.init({
    //       title: [toTextContent(`Checked in`)],
    //       type: InteractionType.AppointmentCheckIn,
    //       createdAt: translations.checkedInAt,
    //     })
    //   );
    // }

    // if (appointment.arrived_at && translations.arrivedAt) {
    //   if (!status) {
    //     status = AppointmentStatus.Arrived;
    //   }
    //   interactions.push(
    //     Interaction.init({
    //       title: [toTextContent(`Arrived`)],
    //       type: InteractionType.AppointmentArrived,
    //       createdAt: translations.arrivedAt,
    //     })
    //   );
    // }

    const customStatusMappings = await snapshot(
      this.customMappings.appointmentStatuses.getRecords$(translationMap)
    );

    await asyncForEach(
      appointment.statuses.reverse(),
      async (appointmentStatus) => {
        const statusMapping = customStatusMappings.find(
          (customStatusMapping) =>
            customStatusMapping.sourceIdentifier === appointmentStatus
        );

        if (status || !statusMapping) {
          return;
        }

        if (
          statusMapping.destinationValue ===
          AppointmentStatusMapType.CancelAppointment
        ) {
          status = AppointmentStatus.Cancelled;
          interactions.push(
            Interaction.init({
              title: [
                toTextContent(
                  `Appointment cancelled by D4W status: ${
                    statusMapping.sourceLabel ?? ''
                  }`
                ),
              ],
              type: InteractionType.AppointmentArrived,
              createdAt: translations.from,
            })
          );
          return;
        }

        if (
          statusMapping.destinationValue ===
          AppointmentStatusMapType.ConfirmAppointment
        ) {
          status = AppointmentStatus.Confirmed;
          interactions.push(
            Interaction.init({
              title: [
                toTextContent(
                  `Appointment confirmed by D4W status: ${
                    statusMapping.sourceLabel ?? ''
                  }`
                ),
              ],
              type: InteractionType.AppointmentArrived,
              createdAt: translations.from,
            })
          );
          return;
        }

        if (
          statusMapping.destinationValue ===
          AppointmentStatusMapType.AddAppointmentTag
        ) {
          const tagName = isINamedDocument(statusMapping.associatedValue)
            ? statusMapping.associatedValue.name
            : '';
          const tag = await snapshot(
            find$(Brand.appointmentTagCol(brand), where('name', '==', tagName))
          );
          if (!tag) {
            throw new Error(
              `No tag found for ${statusMapping.destinationValue} looking for ${tagName}`
            );
          }
          tags.push(toNamedDocument(tag));
        }

        const pinnedInteraction = appointment.description.trim();
        if (pinnedInteraction) {
          interactions.push(
            Interaction.init({
              content: initVersionedSchema(pinnedInteraction),
              type: InteractionType.Note,
              createdAt: translations.from,
              pinned: true,
            })
          );
        }
      }
    );

    const isBeforeNow = toMoment(translations.from).isBefore(
      moment.tz(DEFAULT_TIMEZONE)
    );

    const isStalledStatus = [
      AppointmentStatus.Scheduled,
      AppointmentStatus.Confirmed,
      AppointmentStatus.Arrived,
      AppointmentStatus.CheckedIn,
      AppointmentStatus.InProgress,
    ];

    if (isBeforeNow && (!status || isStalledStatus.includes(status))) {
      status = AppointmentStatus.Complete;
    }

    if (!isBeforeNow && !status) {
      status = AppointmentStatus.Scheduled;
    }

    return {
      status: status || AppointmentStatus.Unscheduled,
      interactions: sortBy(interactions, (interaction) =>
        interaction.createdAt.toDate()
      ),
      tags,
    };
  }
}

export function getAppointmentEvent(
  translations: ID4WAppointmentTranslations,
  patient: WithRef<IPatient>,
  practice: WithRef<IPractice>,
  practitioner: WithRef<IStaffer>
): IEvent {
  return Event.init({
    from: translations.from,
    to: translations.to,
    practice: toNamedDocument(practice),
    type: EventType.Appointment,
    participants: [
      {
        ...toNamedDocument(patient),
        type: ParticipantType.Patient,
      },
      {
        ...stafferToNamedDoc(practitioner),
        type: ParticipantType.Staffer,
      },
    ],
  });
}

async function resolveAssociatedPlan(
  migration: WithRef<IPracticeMigration>,
  sourcePatientId: string,
  translationMap: TranslationMapHandler,
  allTreatments: IGetRecordResponse<
    ID4WPatientTreatment,
    ID4WPatientTreatmentTranslations,
    ID4WPatientTreatmentFilters
  >[],
  appointment: IGetRecordResponse<ID4WAppointment, ID4WAppointmentTranslations>,
  patientAppointments: IGetRecordResponse<
    ID4WAppointment,
    ID4WAppointmentTranslations
  >[]
): Promise<IAssociatedTreatment | undefined> {
  const stepUid = await getStepIdentifier(
    migration,
    new PatientAppointmentSourceEntity(),
    sourcePatientId,
    migration.configuration.timezone,
    allTreatments,
    undefined,
    appointment,
    patientAppointments
  );

  if (!stepUid) {
    return;
  }

  const stepRef = await findTreatmentStepForAppointment(
    translationMap,
    stepUid
  );

  if (!stepRef) {
    return;
  }

  const step = await Firestore.getDoc(stepRef);
  const plan = await TreatmentStep.treatmentPlan(step);

  return TreatmentPlan.treatmentStepToAssociatedTreatment(plan, step);
}
