import {
  FormLayoutElementType,
  FormSchemaPropertyType,
  type IChildFlexLayoutElement,
  type IFormLayoutElement,
  type IFormSchema,
  type IParentFlexLayout,
} from '@principle-theorem/principle-core/interfaces';
import { formFlexRow, prefixLayoutItems } from '../../custom-form-helpers';

/**
 * Birth & Early Childhood
 */

export const BIRTH_AND_EARLY_CHILDHOOD_LAYOUT: IChildFlexLayoutElement[] = [
  {
    type: FormLayoutElementType.Flex,
    items: [
      formFlexRow([{ key: 'gestationalAge' }, { key: 'birthWeight' }]),
      formFlexRow([{ key: 'hadComplications' }, { key: 'complications' }]),
      formFlexRow([{ key: 'hadMedicalIssues' }, { key: 'medicalIssues' }]),
      formFlexRow([{ key: 'wasBreastfed' }, { key: 'breastfedAge' }]),
      formFlexRow([{ key: 'wasBottlefed' }, { key: 'bottlefedAge' }]),
    ],
  },
];

export const BIRTH_AND_EARLY_CHILDHOOD_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    gestationalAge: {
      type: FormSchemaPropertyType.String,
      title: 'Gestational age (weeks)',
    },
    birthWeight: {
      type: FormSchemaPropertyType.String,
      title: 'Birth weight (kg)',
    },
    hadComplications: {
      type: FormSchemaPropertyType.Boolean,
      title: `Any complications during pregnancy or during / after delivery?`,
    },
    complications: {
      type: FormSchemaPropertyType.String,
      title: 'If Yes, please provide details',
    },
    hadMedicalIssues: {
      type: FormSchemaPropertyType.Boolean,
      title: `Did your child have any medical issues from birth to three years of age?`,
    },
    medicalIssues: {
      type: FormSchemaPropertyType.String,
      title: 'If Yes, please provide details',
    },
    wasBreastfed: {
      type: FormSchemaPropertyType.Boolean,
      title: `Was your child breastfed?`,
    },
    breastfedAge: {
      type: FormSchemaPropertyType.String,
      title: 'If yes, till what age?',
    },
    wasBottlefed: {
      type: FormSchemaPropertyType.Boolean,
      title: `Was your child bottle fed?`,
    },
    bottlefedAge: {
      type: FormSchemaPropertyType.String,
      title: 'If yes, till what age?',
    },
  },
};

/**
 * Birth & Early Childhood Form
 */

export const PAEDIATRIC_BIRTH_AND_EARLY_CHILDHOOD_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    birthAndEarlyChildhood: BIRTH_AND_EARLY_CHILDHOOD_SCHEMA,
  },
};

export const PAEDIATRIC_BIRTH_AND_EARLY_CHILDHOOD_FORM_LAYOUT: (
  | IParentFlexLayout
  | IFormLayoutElement
)[] = [
  {
    key: 'birthAndEarlyChildhood',
    notitle: true,
    type: FormLayoutElementType.Flex,
    options: { flex: '1 1 100%' },
    items: prefixLayoutItems(
      BIRTH_AND_EARLY_CHILDHOOD_LAYOUT,
      'birthAndEarlyChildhood'
    ),
  },
];
