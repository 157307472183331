import {
  VersionedSchemaSerialiser,
  VersionedSchemaUnserialiser,
} from '@principle-theorem/editor';
import { FirestoreSerialiser, ITransformer } from '@principle-theorem/shared';
import {
  CustomFormContentSerialiser,
  CustomFormContentUnserialiser,
  PatientFormSerialiser,
  PatientFormUnserialiser,
} from './models/json-schema-form-serialisers';
import {
  AutomatedNotificationSerialiser,
  AutomatedNotificationUnserialiser,
} from './models/notification/automated-notification-serialiser';
import {
  PatientDocumentSerialiser,
  PatientDocumentUnserialiser,
} from './models/patient/patient-document-serialiser';

export const PRINCIPLE_CORE_SERIALISERS: ITransformer<unknown, unknown>[] = [
  // new CustomFormConfigurationSerialiser(),
  // new CustomFormConfigurationUnserialiser(),
  new CustomFormContentSerialiser(),
  new CustomFormContentUnserialiser(),
  new PatientFormSerialiser(),
  new PatientFormUnserialiser(),
  // new SourceEntityRecordDataSerialiser(),
  // new SourceEntityRecordDataUnserialiser(),
  new PatientDocumentSerialiser(),
  new PatientDocumentUnserialiser(),
  new AutomatedNotificationSerialiser(),
  new AutomatedNotificationUnserialiser(),
  new VersionedSchemaSerialiser(),
  new VersionedSchemaUnserialiser(),
  new FirestoreSerialiser(),
];
