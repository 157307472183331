<span class="mention" [matMenuTriggerFor]="menu">
  {{ mentionTrigger }}<ng-content></ng-content>
</span>
<mat-menu
  #menu="matMenu"
  xPosition="after"
  yPosition="below"
  [overlapTrigger]="false"
>
  <button
    mat-menu-item
    *ngFor="let action of actions; trackBy: trackByAction"
    (click)="runAction(action)"
  >
    <mat-icon>{{ action.icon }}</mat-icon>
    {{ action.name }}
  </button>
</mat-menu>
