import { roundTo2Decimals } from '@principle-theorem/accounting';
import {
  AccountCredit,
  Patient,
  getPatientAccountSummaryUpdates,
} from '@principle-theorem/principle-core';
import {
  PatientRelationshipType,
  type IAccountCredit,
  type IAccountSummary,
  type IInvoice,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  runTransaction,
  shareReplayCold,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

export class AccountSummaryBloc {
  invoices$: Observable<WithRef<IInvoice>[]>;
  credits$: Observable<WithRef<IAccountCredit>[]>;
  summary$: Observable<IAccountSummary>;
  outstanding$: Observable<number>;
  creditRemaining$: Observable<number>;
  isLoading$: Observable<boolean>;

  constructor(public patient$: Observable<WithRef<IPatient>>) {
    this.invoices$ = this.patient$.pipe(
      switchMap((patient) =>
        Patient.withPatientRelationships$(
          patient,
          [PatientRelationshipType.DuplicatePatient],
          Patient.invoices$
        )
      ),
      shareReplayCold()
    );
    this.credits$ = this.patient$.pipe(
      switchMap((patient) =>
        Patient.withPatientRelationships$(
          patient,
          [PatientRelationshipType.DuplicatePatient],
          AccountCredit.all$
        )
      ),
      shareReplayCold()
    );
    this.summary$ = this.patient$.pipe(
      map((patient) => patient.accountSummary),
      shareReplayCold()
    );
    this.isLoading$ = this.summary$.pipe(
      map(() => false),
      startWith(true)
    );
    this.outstanding$ = this.summary$.pipe(
      map((summary) => roundTo2Decimals(summary.paymentSummary.outstanding)),
      map((value) => (isNaN(value) ? 0 : value))
    );
    this.creditRemaining$ = this.summary$.pipe(
      map((summary) => summary.creditSummary.creditRemaining),
      map((value) => (isNaN(value) ? 0 : value))
    );
  }

  async recalculateSummary(): Promise<void> {
    const patient = await snapshot(this.patient$);

    await runTransaction(async (atomicTransaction) => {
      const accountSummary = await getPatientAccountSummaryUpdates(
        patient,
        atomicTransaction
      );

      await Firestore.patchDoc(
        patient.ref,
        { accountSummary },
        atomicTransaction
      );
    });
  }
}
