import {
  IExpectedSourceRecordSize,
  SourceEntityMigrationType,
  type IPracticeMigration,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  isObject,
  toTimestamp,
  type Timestamp,
  type Timezone,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, flow, groupBy, isNull, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { runQuery } from '../../../source/connection';
import { SourceEntity } from '../../../source/source-entity';
import { OFFSET_PLACEHOLDER } from '../../../source/source-helpers';

export const PATIENT_CLAIM_RESOURCE_TYPE = 'patientClaim';

export const PATIENT_CLAIM_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient Claim List',
    description: '',
    idPrefix: PATIENT_CLAIM_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ID4WPatientClaim {
  id: number;
  created_at: string;
  date_submitted: string;
  transaction_id: number;
  health_fund_id: number;
  patient_id: number;
  health_fund_form_type_id: number;
  health_fund_form_type_code: string;
  health_fund_form_type_name: string;
  items: ID4WPatientClaimItem[];
}

export function isD4WPatientClaim(item: unknown): item is ID4WPatientClaim {
  return (
    isObject(item) &&
    isNumber(item.id) &&
    isString(item.created_at) &&
    isString(item.date_submitted) &&
    isNumber(item.transaction_id) &&
    isNumber(item.health_fund_id) &&
    isNumber(item.patient_id) &&
    isNumber(item.health_fund_form_type_id) &&
    isString(item.health_fund_form_type_code) &&
    isString(item.health_fund_form_type_name) &&
    TypeGuard.arrayOf(isD4WPatientClaimItem)(item.items)
  );
}

export interface ID4WPatientClaimItem {
  treatment_id: number | null;
  rebate: string;
}

export function isD4WPatientClaimItem(
  item: unknown
): item is ID4WPatientClaimItem {
  return (
    isObject(item) &&
    (isNumber(item.treatment_id) || isNull(item.treatment_id)) &&
    isString(item.rebate)
  );
}

export interface ID4WPatientClaimResult
  extends ID4WPatientClaim,
    ID4WPatientClaimItem {}

export interface ID4WPatientClaimTranslations {
  createdAt: Timestamp;
}

export interface ID4WPatientClaimFilters {
  createdAt: Timestamp;
  transactionId: string;
  healthFundId: string;
  patientId: string;
  treatmentIds: string[];
}

const PATIENT_CLAIM_SOURCE_QUERY = `
SELECT
  claim.id,
  claim.created_at,
  claim.date_submitted,
  transaction.transaction_id,
  claim.health_fund_id,
  claim.patient_id,
  claim_item.treatment_id,
  claim_item.rebate,
  claim.health_fund_form_type_id,
  health_fund_form_type.code AS health_fund_form_type_code,
  health_fund_form_type.name AS health_fund_form_type_name
FROM (
  SELECT
    claim_id AS id,
    ref_number,
    convert_empty_string_to_null(ref_status) AS ref_status,
    when_created AS created_at,
    date_submit AS date_submitted,
    hf_plan_id AS health_fund_id,
    patient_id,
    hf_form_type_id AS health_fund_form_type_id
  FROM claims
  WHERE ref_status != 'D'
  ORDER BY claim_id
  ${OFFSET_PLACEHOLDER}
) AS claim
LEFT JOIN (
  SELECT
    e_trans_id AS transaction_id,
    claim_id
  FROM e_transactions
) AS transaction
ON claim.id = transaction.claim_id
LEFT JOIN (
  SELECT
    claim_id,
    treat_id AS treatment_id,
    amount_eob AS rebate
  FROM claims_items
) AS claim_item
ON claim.id = claim_item.claim_id
LEFT JOIN (
  SELECT
    type_id as id,
    type_code AS code,
    type_name AS name
  FROM hf_forms_types
) AS health_fund_form_type
ON claim.health_fund_form_type_id::TEXT = health_fund_form_type.id::TEXT
WHERE transaction_id IS NOT NULL`;

export class PatientClaimSourceEntity extends BaseSourceEntity<
  ID4WPatientClaim,
  ID4WPatientClaimTranslations,
  ID4WPatientClaimFilters
> {
  sourceEntity = PATIENT_CLAIM_SOURCE_ENTITY;
  entityResourceType = PATIENT_CLAIM_RESOURCE_TYPE;
  sourceQuery = PATIENT_CLAIM_SOURCE_QUERY;
  verifySourceFn = isD4WPatientClaim;
  override dateFilterField: keyof ID4WPatientClaimFilters = 'createdAt';
  override transformDataFn = flow([transformClaimQueryResults]);

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const response = await runQuery<{ id: number }>(
      migration,
      PATIENT_CLAIM_SOURCE_QUERY.replace(OFFSET_PLACEHOLDER, '')
    );

    const expectedSize = Object.values(
      groupBy(response.rows, (claim) => claim.id)
    ).length;

    return {
      expectedSize,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }

  translate(
    data: ID4WPatientClaim,
    timezone: Timezone
  ): ID4WPatientClaimTranslations {
    return {
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getSourceRecordId(data: ID4WPatientClaim): number {
    return data.id;
  }

  getSourceLabel(data: ID4WPatientClaim): string {
    return `${data.id}`;
  }

  getFilterData(
    data: ID4WPatientClaim,
    timezone: Timezone
  ): ID4WPatientClaimFilters {
    return {
      transactionId: data.transaction_id.toString(),
      healthFundId: data.health_fund_id.toString(),
      patientId: data.patient_id.toString(),
      treatmentIds: compact(
        data.items.map((item) => item.treatment_id?.toString())
      ),
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }
}

function transformClaimQueryResults(
  rows: ID4WPatientClaimResult[]
): ID4WPatientClaim[] {
  const claimGroups = groupBy(rows, (claim) => claim.id);

  const results = Object.values(claimGroups).map((claimGroup) => ({
    id: claimGroup[0].id,
    created_at: claimGroup[0].created_at,
    date_submitted: claimGroup[0].date_submitted,
    transaction_id: claimGroup[0].transaction_id,
    health_fund_id: claimGroup[0].health_fund_id,
    patient_id: claimGroup[0].patient_id,
    health_fund_form_type_id: claimGroup[0].health_fund_form_type_id,
    health_fund_form_type_code: claimGroup[0].health_fund_form_type_code,
    health_fund_form_type_name: claimGroup[0].health_fund_form_type_name,
    items: claimGroup.map((claim) => ({
      treatment_id: claim.treatment_id,
      rebate: claim.rebate,
    })),
  }));

  // eslint-disable-next-line no-console
  console.warn(
    `rows ${rows.length}, claimGroups: ${
      Object.values(claimGroups).length
    }, results: ${results.length}`
  );
  return results;
}
