<ng-container
  *ngFor="let section of sections$ | async; trackBy: trackBySection"
>
  <h3>{{ section.name }}</h3>

  <div
    fxLayout="row wrap"
    *ngIf="section | map: getDisplays$ : this | async as displays"
  >
    <pr-report-builder-chart
      *ngFor="let display of displays; trackBy: trackByDisplay"
      [display]="display"
      [sectionName]="section.name"
      readOnly
    >
      <ng-container ngProjectAs="report-builder-chart-action">
        @if (store.editMode$ | async) {
          <button
            mat-icon-button
            color="accent"
            matTooltip="Add to Custom Report"
            (click)="includeInCustomReport(display)"
          >
            <mat-icon>add</mat-icon>
          </button>
        }
      </ng-container>
    </pr-report-builder-chart>
  </div>
</ng-container>
