import { TypedFormGroup, TypedFormControl } from '@principle-theorem/ng-shared';
import { type IContactDetails } from '@principle-theorem/principle-core/interfaces';
import { Validators } from '@angular/forms';
import { CustomValidators } from '@principle-theorem/ng-principle-shared';

export class LabFormGroup extends TypedFormGroup<
  Pick<IContactDetails, 'name' | 'email' | 'address' | 'phone'>
> {
  constructor() {
    super(
      {
        name: new TypedFormControl<string>('', Validators.required),
        email: new TypedFormControl<string>('', Validators.email),
        address: new TypedFormControl<string>(''),
        phone: new TypedFormControl<string>('', [CustomValidators.phoneNumber]),
      },
      CustomValidators.requireOneOf(['email', 'phone'])
    );
  }
}
