import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  type ILabJobInteractionsData,
  LabJobInteractionsDialogComponent,
} from '@principle-theorem/ng-labs';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { LabJob, toMention } from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type ILabJob,
  type IPrincipleMention,
  MentionResourceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  CALENDAR_FORMAT,
  CALENDAR_TIME_FORMAT,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-lab-job-card',
    templateUrl: './lab-job-card.component.html',
    styleUrls: ['./lab-job-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LabJobCardComponent {
  appointment$: Observable<WithRef<IAppointment> | undefined>;
  labJob$ = new ReplaySubject<WithRef<ILabJob>>(1);
  patientMention$: Observable<IPrincipleMention>;
  labMention$: Observable<IPrincipleMention>;
  readonly dateFormat = CALENDAR_TIME_FORMAT;
  readonly calendarFormat = CALENDAR_FORMAT;

  constructor(private _dialog: MatDialog) {
    this.patientMention$ = this.labJob$.pipe(
      map((labJob) => toMention(labJob.patient, MentionResourceType.Patient))
    );
    this.labMention$ = this.labJob$.pipe(
      map((labJob) => toMention(labJob.lab, MentionResourceType.Lab))
    );
    this.appointment$ = this.labJob$.pipe(
      switchMap((labJop) =>
        labJop.appointment ? LabJob.appointment$(this.labJob) : of(undefined)
      )
    );
  }

  @Input()
  set labJob(labJob: WithRef<ILabJob>) {
    if (labJob) {
      this.labJob$.next(labJob);
    }
  }

  /**
   * Show lab job notes
   */
  showNotes(): void {
    const data: ILabJobInteractionsData = {
      labJob: this.labJob,
    };
    const config: MatDialogConfig = DialogPresets.large({
      height: '80%',
      data,
    });
    this._dialog.open<
      LabJobInteractionsDialogComponent,
      ILabJobInteractionsData
    >(LabJobInteractionsDialogComponent, config);
  }
}
