import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
  type OnInit,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, type Sort, type SortDirection } from '@angular/material/sort';
import { ActivatedRoute, type Params, Router } from '@angular/router';
import {
  CurrentPracticeScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  SelectionListStore,
  SidebarManagerService,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  type ILabJob,
  type IPractice,
  type IStaffer,
  type LabJobStatus,
  LAB_JOB_STATUSES,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  filterUndefined,
  isSameRef,
  slugify,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LabJobManager } from '../../lab-job-manager';
import {
  type ILabJobFilter,
  type LabJobPresetFilter,
  LAB_JOB_PRESET_FILTERS,
} from '../../lab-job-preset-filters';
import {
  type ILabJobSortOption,
  type LabJobSortId,
  LAB_JOB_SORT_OPTIONS,
} from '../../lab-job-sort-options';
import { LabJobsFacade } from '../../store/lab-jobs.facade';
import { LabJobListDataSource } from './lab-job-list-data-source';

@Component({
    selector: 'pr-lab-job-list',
    templateUrl: './lab-job-list.component.html',
    styleUrls: ['./lab-job-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SelectionListStore],
    standalone: false
})
export class LabJobListComponent implements OnInit, OnDestroy {
  trackByOption = TrackByFunctions.label<ILabJobSortOption>();
  trackByFilter = TrackByFunctions.field<ILabJobFilter>('name');
  trackByStatus = TrackByFunctions.variable<LabJobStatus>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: LabJobListDataSource = new LabJobListDataSource();
  selectedLabJob: WithRef<ILabJob>;

  statuses: LabJobStatus[] = LAB_JOB_STATUSES;
  filters: ILabJobFilter[] = LAB_JOB_PRESET_FILTERS;
  sortOptions: ILabJobSortOption[] = LAB_JOB_SORT_OPTIONS;
  sortControl: TypedFormControl<ILabJobSortOption> = new TypedFormControl();
  statusFilter$: Observable<LabJobStatus>;
  presetFilter$: Observable<LabJobPresetFilter>;
  activeSort$: Observable<LabJobSortId>;
  activeSortDirection$: Observable<SortDirection>;
  emptyState$: Observable<boolean>;
  emptySearch$: Observable<boolean>;

  constructor(
    public _snackBar: MatSnackBar,
    private _organisation: OrganisationService,
    private _practiceScope: CurrentPracticeScope,
    private _labJobsFacade: LabJobsFacade,
    private _route: ActivatedRoute,
    private _router: Router,
    public display: SidebarManagerService,
    public selectionList: SelectionListStore<WithRef<ILabJob>>
  ) {
    this.statusFilter$ = this._labJobsFacade.statusFilter$;
    this.presetFilter$ = this._labJobsFacade.activePresetFilter$;
    this.activeSort$ = this._labJobsFacade.activeSort$;
    this.activeSortDirection$ = this._labJobsFacade.activeSortOption$.pipe(
      tap((option) => this.sortControl.setValue(option)),
      map((option) => option.direction)
    );
    this.dataSource.items$ = this._labJobsFacade.filteredLabJobs$;
    this.selectionList.setCompareFn(isSameRef);
    this.selectionList.loadOptions(this.dataSource.filteredData$);

    this.emptyState$ = combineLatest([
      this._labJobsFacade.filteredLabJobs$,
      this._labJobsFacade.labJobsLoaded$,
    ]).pipe(map(([labJobs, loaded]) => !labJobs.length && loaded));

    this.emptySearch$ = combineLatest([
      this.emptyState$,
      this.dataSource.filteredData$,
    ]).pipe(
      map(([emptyState, filteredData]) => !emptyState && !filteredData.length)
    );
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this._labJobsFacade.unsubscribeLabJobs();
  }

  searchLabJobs(value: string): void {
    this.dataSource.filter = value;
  }

  async updateStatus(status: LabJobStatus): Promise<void> {
    const staffer: WithRef<IStaffer> = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const practice: WithRef<IPractice> = await this._practiceScope.toPromise();
    const labJobManager: LabJobManager = new LabJobManager(practice, staffer);

    const selectedLabJobs = await snapshot(this.selectionList.selected$);
    await asyncForEach(selectedLabJobs, (labJob) =>
      labJobManager.updateStatus(labJob, status)
    );

    this._snackBar.open(`Updated Status`);
  }

  /**
   * Open the lab job form dialog
   */
  addNewLabJob(): void {
    this._labJobsFacade.addLabJob();
  }

  /**
   * Open form to edit lab job and view interactions
   * @param labJob LabJob
   */
  async editLabJob(labJob: WithRef<ILabJob>): Promise<void> {
    await this._updateQueryParams({
      labJob: labJob.ref.id,
    });
  }

  resetSelected(): void {
    this.selectionList.resetSelected();
  }

  async updateStatusFilter(status: LabJobStatus): Promise<void> {
    await this._updateQueryParams({
      status: slugify(status),
    });
  }

  async updatePresetFilter(filter: string): Promise<void> {
    await this._updateQueryParams({
      filter,
    });
  }

  async sortChange(event: Sort): Promise<void> {
    await this._updateQueryParams({ sort: slugify(event.active) });
  }

  private async _updateQueryParams(params: Params): Promise<void> {
    await this._router.navigate([], {
      relativeTo: this._route,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}
