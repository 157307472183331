export enum RootCollection {
  Configuration = 'config',
  Organisations = 'organisations',
  PracticeMigrations = 'practiceMigrations',
  ManagementJobs = 'managementJobs',
  ManagementStaff = 'managementStaff',
  ManagementUsers = 'managementUsers',
}

export enum ConfigCollection {
  Public = 'public',
}

export interface IPublicConfig {
  version: string;
}
