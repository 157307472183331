import {
  EventType,
  IEvent,
  IPractice,
  IStaffer,
  ParticipantType,
} from '@principle-theorem/principle-core/interfaces';
import { BaseMock, MockTimestamp, Properties } from '@principle-theorem/shared';
import { MockDocRef, MockNamedDocument } from '@principle-theorem/testing';

export class EventMock extends BaseMock implements Properties<IEvent> {
  from = MockTimestamp();
  to = MockTimestamp();
  practice = MockNamedDocument<IPractice>();
  creator = MockNamedDocument<IStaffer>();
  organiser = MockNamedDocument<IStaffer>();
  participants = [
    {
      type: ParticipantType.Staffer,
      ...MockNamedDocument<IStaffer>(),
    },
  ];
  participantRefs = [MockDocRef<IStaffer>()];
  type = EventType.Appointment;
}
