import { Inject, Injectable } from '@angular/core';
import type {
  Funder,
  FunderData,
  FunderPatientDetails,
  FunderPayload,
  IClaimableItem,
  IPayloadPatient,
  IVirtualTerminalPayload,
} from '@medipass/partner-sdk';
import { roundTo2Decimals } from '@principle-theorem/accounting';
import { MedipassService } from '@principle-theorem/ng-medipass';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import {
  Invoice,
  Patient,
  resolveClaimItems,
  sanatiseHealthCardNumber,
  toSingleClaimItems,
} from '@principle-theorem/principle-core';
import {
  Gender,
  isPatientWithPrimaryContact,
  type IAppointment,
  type IHealthcareClaim,
  type IInvoice,
  type IPatient,
  type IServiceCodeLineItem,
  type IStaffer,
  type ITreatmentLineItem,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, snapshot, splitName } from '@principle-theorem/shared';
import { compact } from 'lodash';
import {
  NG_PAYMENTS_CONFIG,
  type INgPaymentsConfig,
} from '../../../ng-payments-config';
import { type ITransactionAmountDialogResult } from '../transaction-components/transaction-amount-dialog/transaction-amount-dialog.component';
import {
  getNonClaimableItemTransformables,
  resolveTransactionPracticeRef,
} from '../transaction-helpers';
import { TransactionProviderError } from '../transaction-provider';
import { hicaps, medicare } from './medipass-funders';
import { MedipassTransformers } from './medipass-transformers';
import { getWebhooks } from './medipass-webhooks';

@Injectable()
export class MedipassBuilder {
  constructor(
    private _medipass: MedipassService,
    @Inject(NG_PAYMENTS_CONFIG) private _config: INgPaymentsConfig,
    private _currentScope: CurrentScopeFacade
  ) {}

  async getVirtualTerminalPayload(
    patient: WithRef<IPatient>,
    invoice: WithRef<IInvoice>,
    formData: ITransactionAmountDialogResult,
    staffer: WithRef<IStaffer>,
    claim?: IHealthcareClaim
  ): Promise<IVirtualTerminalPayload> {
    const resolvedPatient = isPatientWithPrimaryContact(patient)
      ? await snapshot(Patient.primaryContact$(patient))
      : patient;
    const patientData = await this._getPatientData(resolvedPatient);
    const practiceRef = await resolveTransactionPracticeRef(
      this._currentScope.currentPractice$,
      invoice.practice
    );
    return {
      platform: 'virtual-terminal',
      invoiceReference: invoice.reference,
      providerNumber: claim?.providerData?.providerNumber,
      chargeAmount: `${roundTo2Decimals(formData.amount)}`,
      patient: patientData?.patient,
      webhooks: getWebhooks(
        this._config.medipass.webhookEndpoint,
        'virtual-terminal',
        staffer,
        practiceRef,
        claim
      ),
    };
  }

  async getFunderPayload(
    patient: WithRef<IPatient>,
    invoice: WithRef<IInvoice>,
    funder: Funder = 'hicaps',
    staffer: WithRef<IStaffer>,
    claim: IHealthcareClaim
  ): Promise<FunderPayload> {
    const patientData = await this._getPatientData(patient, funder);
    const providerNumber = claim.providerData?.providerNumber;
    if (!providerNumber) {
      throw new TransactionProviderError(`Missing Provider Data`);
    }

    const appointment = await snapshot(
      Invoice.getAssociatedAppointment$(invoice)
    );
    const practiceRef = await resolveTransactionPracticeRef(
      this._currentScope.currentPractice$,
      invoice.practice
    );
    return {
      platform: 'funder',
      invoiceReference: invoice.reference,
      claimableItems: this._getClaimableItems(invoice, claim, appointment),
      nonClaimableItems: this._getNonClaimableItems(invoice),
      ...patientData,
      providerNumber,
      funder,
      funderData: await this._getFunderDetails(patient, funder),
      webhooks: getWebhooks(
        this._config.medipass.webhookEndpoint,
        funder,
        staffer,
        practiceRef,
        claim
      ),
    };
  }

  private async _getFunderDetails(
    patient: WithRef<IPatient>,
    funder: Funder
  ): Promise<FunderData> {
    switch (funder) {
      case 'hicaps':
        return { hicaps: hicaps(patient) };
      case 'medicare':
        return { medicare: await medicare(patient) };
      default:
        return {};
    }
  }

  private async _getPatientData(
    patient: WithRef<IPatient>,
    funder?: Funder
  ): Promise<FunderPatientDetails & { patient?: IPayloadPatient }> {
    const contactDetails = await Patient.resolveContactDetails(patient.ref);
    const mobile = contactDetails
      ? Patient.getMobileNumber(contactDetails)
      : '';
    const email = contactDetails?.email
      ? contactDetails.email.toLowerCase()
      : '';

    const patientLookup = await this._medipass.discoverMember({
      email,
      dobString: patient.dateOfBirth,
      mobile: mobile ?? '',
    });
    if (patientLookup) {
      return {
        memberId: patientLookup._id,
      };
    }
    const [firstName, lastName] = splitName(patient.name);
    return {
      patient: {
        refId: patient.ref.id,
        firstName,
        lastName,
        mobile: mobile ?? '',
        sex: this._getPatientSex(patient.gender),
        dob: patient.dateOfBirth ?? '',
        ...this._getMembershipDetails(patient, funder),
      },
    };
  }

  private _getMembershipDetails(
    patient: IPatient,
    funder?: Funder
  ): Partial<{ accountNumber: string; reference: string }> {
    if (funder === 'hicaps' && patient.healthFundCard) {
      return {
        accountNumber: sanatiseHealthCardNumber(
          patient.healthFundCard.membershipNumber
        ),
        reference: sanatiseHealthCardNumber(
          patient.healthFundCard.memberNumber
        ),
      };
    }
    if (funder === 'medicare' && patient.medicareCard) {
      return {
        accountNumber: sanatiseHealthCardNumber(patient.medicareCard.number),
        reference: sanatiseHealthCardNumber(patient.medicareCard.subNumerate),
      };
    }
    if (funder === 'dva' && patient.dvaCard) {
      return {
        accountNumber: sanatiseHealthCardNumber(patient.dvaCard.number),
      };
    }
    return {};
  }

  private _getClaimableItems(
    invoice: IInvoice,
    claim: IHealthcareClaim,
    appointment?: WithRef<IAppointment>
  ): IClaimableItem[] {
    const resolved = compact(
      resolveClaimItems(invoice, claim).map((item) => item.resolved)
    );
    return toSingleClaimItems(resolved).map((item) =>
      this._toClaimableItem(
        invoice,
        item.treatment,
        item.serviceCode,
        appointment
      )
    );
  }

  private _getNonClaimableItems(
    invoice: IInvoice
  ): Pick<IClaimableItem, 'price' | 'description' | 'quantity'>[] {
    return getNonClaimableItemTransformables(invoice).map((item) => ({
      price: MedipassTransformers.toAmountString(item.lineItem.amount),
      description: item.lineItem.description,
      quantity: item.lineItem.quantity,
    }));
  }

  private _toClaimableItem(
    invoice: IInvoice,
    treatment: ITreatmentLineItem,
    item: IServiceCodeLineItem,
    appointment?: WithRef<IAppointment>
  ): IClaimableItem {
    const serviceDate = appointment?.event?.from ?? invoice.createdAt;
    return {
      itemCode: item.code,
      serviceDateString: MedipassTransformers.toDateString(serviceDate),
      price: MedipassTransformers.toAmountString(item.amount),
      description: treatment.description,
      quantity: 1,
      clinicalCode: item.toothId,
    };
  }

  private _getPatientSex(gender: Gender): IPayloadPatient['sex'] | undefined {
    switch (gender) {
      case Gender.Male:
        return 'M';
      case Gender.Female:
        return 'F';
      case Gender.Other:
        return 'O';
      default:
        return;
    }
  }
}
