import { InjectionToken } from '@angular/core';
import { type IMigration } from '@principle-theorem/migration-runner';
import { AddDefaultUserGroupsMigration } from './extensions/table/migration';
import { FixParagraphTypeMigration } from './extensions/paragraph/migration';
import { MoveLinkMarkToNodeMigration } from './extensions/link/migration';
import { MoveInlineImageToBlockNodeMigration } from './extensions/image/migration';
import { MoveVideoEmbedMarkToNodeMigration } from './extensions/video/migration';

export interface IEditorConfig {
  migrations: IMigration[];
}

export const NG_EDITOR_CONFIG: InjectionToken<IEditorConfig> =
  new InjectionToken('IEditorConfig');

export const NG_EDITOR_MIGRATIONS: IMigration[] = [
  new AddDefaultUserGroupsMigration(),
  new FixParagraphTypeMigration(),
  new MoveLinkMarkToNodeMigration(),
  new MoveInlineImageToBlockNodeMigration(),
  new MoveVideoEmbedMarkToNodeMigration(),
];
