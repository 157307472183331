import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  Appointment,
  dateIsWithinBounds,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  type IAppointment,
  isEventable,
} from '@principle-theorem/principle-core/interfaces';
import {
  isPathChanged$,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { combineLatest, type Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-appointment-scheduling-rules-conflict-sidebar',
  templateUrl: './appointment-scheduling-rules-conflict-sidebar.component.html',
  styleUrls: ['./appointment-scheduling-rules-conflict-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentSchedulingRulesConflictSidebarComponent {
  alert$: Observable<string | undefined>;
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor() {
    this.alert$ = this._getAlert$();
  }

  private _getAlert$(): Observable<string | undefined> {
    const plan$ = this.appointment$.pipe(
      isPathChanged$('ref.id'),
      switchMap((appointment) => Appointment.treatmentPlan$(appointment))
    );
    const step$ = this.appointment$.pipe(
      isPathChanged$('ref.id'),
      switchMap((appointment) =>
        TreatmentPlan.treatmentStepForAppointment$(appointment)
      )
    );

    const schedulingRuleMessage$ = combineLatest([
      this.appointment$,
      plan$,
      step$,
    ]).pipe(
      switchMap(([appointment, plan, step]) => {
        if (
          !plan ||
          !step ||
          !TreatmentStep.schedulingRulesHasDayRanges(step) ||
          !isEventable(appointment)
        ) {
          return of(undefined);
        }

        return TreatmentPlan.getStepAbsoluteSchedulingRules$(plan, step);
      })
    );

    return combineLatest([this.appointment$, schedulingRuleMessage$]).pipe(
      map(([appointment, schedulingRuleMessage]) => {
        const from = appointment.event?.from;
        const appointmentDate: moment.Moment = from ? toMoment(from) : moment();
        if (
          schedulingRuleMessage &&
          !dateIsWithinBounds(appointmentDate, schedulingRuleMessage)
        ) {
          return 'This appointment date conflicts with the set scheduling rules';
        }
      })
    );
  }
}
