import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'pr-profile-card-header',
    template: '<ng-content></ng-content>',
    standalone: false
})
export class ProfileCardHeaderComponent {
  @HostBinding('class') classes = 'pr-profile-card-header';
}
