import {
  IContextBuilder,
  IPatient,
  IPatientScopeData,
  IPatientTemplateContext,
} from '@principle-theorem/principle-core/interfaces';
import { DAY_MONTH_YEAR_FORMAT, toMoment } from '@principle-theorem/shared';
import { NameHelpers } from '../../models/patient/name-helpers';
import { findMobileNumber } from '../../models/patient/patient-contact-details';
import { getMissingContextPlaceholder } from '../template-context-resolver';
import { BaseContextBuilder } from './base-context';

export class PatientContextBuilder
  implements IContextBuilder<IPatientTemplateContext>
{
  private _baseContextBuilder = new BaseContextBuilder();

  constructor(private _data: IPatientScopeData) {}

  build(): IPatientTemplateContext {
    const {
      patient,
      referrer,
      medicalHistoryLink,
      patientFormsLink,
      primaryContact,
      smsIntegration,
      region,
    } = this._data;

    const patientData: IPatient = {
      ...patient,
      ...primaryContact,
      name: patient.name,
    };

    const patientDateOfBirth = patient.dateOfBirth
      ? toMoment(patient.dateOfBirth).format(DAY_MONTH_YEAR_FORMAT)
      : getMissingContextPlaceholder('patientDateOfBirth');

    const patientContext = {
      patientId: patient.ref.id,
      patientFirstName: NameHelpers.firstName(patientData.name),
      patientLastName: NameHelpers.lastName(patientData.name),
      patientFullName: NameHelpers.fullName(patientData.name),
      patientDateOfBirth,
      patientEmail: this._contextWithFallBack(
        patientData.email,
        'patientEmail'
      ),
      patientMobile: this._contextWithFallBack(
        findMobileNumber(patientData.contactNumbers, region, smsIntegration),
        'patientMobile'
      ),
      patientAddress: this._contextWithFallBack(
        patientData.address,
        'patientAddress'
      ),
      patientMedicareNumber: this._contextWithFallBack(
        patientData.medicareCard?.number,
        'patientMedicareNumber'
      ),
    };

    const referrerContext = {
      referrerName: this._contextWithFallBack(referrer?.name, 'referrerName'),
      referrerEmail: this._contextWithFallBack(
        referrer?.email,
        'referrerEmail'
      ),
      referrerAddress: this._contextWithFallBack(
        referrer?.address,
        'referrerAddress'
      ),
      referrerJobTitle: this._contextWithFallBack(
        referrer?.jobTitle,
        'referrerJobTitle'
      ),
    };

    return {
      ...this._baseContextBuilder.build(),
      ...patientContext,
      ...referrerContext,
      medicalHistoryLink,
      patientFormsLink,
    };
  }

  private _contextWithFallBack(
    data: string | undefined,
    variableName: string
  ): string {
    return data ? data : getMissingContextPlaceholder(variableName);
  }
}
