import { isObject } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type ICustomFormConfiguration } from './custom-forms/custom-form-config';

export interface ISerialisedCustomFormConfiguration
  extends Omit<ICustomFormConfiguration, 'schema' | 'layout'> {
  schema: string;
  layout: string;
}

export function isSerialisedCustomFormConfiguration(
  data: unknown
): data is ISerialisedCustomFormConfiguration {
  return (
    isObject(data) &&
    isString(data.name) &&
    isString(data.schema) &&
    isString(data.layout)
  );
}
