import {
  ChartableSurface,
  ChartedItemType,
  IChartedCondition,
  IConditionConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { AtLeast, toNamedDocument, WithRef } from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';
import { ChartedItem } from '../core/charted-item';

export class ChartedCondition extends ChartedItem {
  static override init(
    overrides: AtLeast<IChartedCondition, 'config'>
  ): IChartedCondition {
    return {
      uuid: uuid(),
      type: ChartedItemType.ChartedCondition,
      chartedSurfaces: [],
      notes: [],
      scopeRef: {
        scope: ChartableSurface.WholeMouth,
      },
      ...overrides,
    };
  }

  static fromConfig(
    config: WithRef<IConditionConfiguration>
  ): IChartedCondition {
    return ChartedCondition.init({
      config: toNamedDocument(config),
    });
  }
}
