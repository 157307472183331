import { MatSnackBar } from '@angular/material/snack-bar';
import {
  initVersionedSchema,
  MixedSchema,
  RawInlineNodes,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  addInteractions,
  Chat,
  Interaction,
  stafferToNamedDoc,
  toMention,
} from '@principle-theorem/principle-core';
import {
  ChatStatus,
  IChat,
  IChatMessage,
  IInteractionV2,
  InteractionType,
  MentionResourceType,
  WithContext,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  filterUndefined,
  Firestore,
  firstResult$,
  isPathChanged$,
  isSameRef,
  multiSort,
  orderBy,
  paginatedQuery$,
  shareReplayCold,
  snapshot,
  sortTimestampAsc,
  undeletedQuery,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, combineLatest, Subject, type Observable } from 'rxjs';
import {
  filter,
  map,
  pairwise,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { type IChatSummary } from './pages/chats-dashboard/chats-dashboard.store';
import { first } from 'lodash';

export class ChatActions {
  isRead$: Observable<boolean>;
  canMarkUnread$: Observable<boolean>;
  isOpen$: Observable<boolean>;
  isMuted$: Observable<boolean>;
  messages$: Observable<WithRef<IChatMessage>[]>;
  interactions$: Observable<WithRef<WithContext<IInteractionV2>>[]>;
  content$: Observable<
    (WithRef<WithContext<IInteractionV2>> | WithRef<IChatMessage>)[]
  >;
  contentAdded$: Observable<void>;
  loading$ = new BehaviorSubject<boolean>(true);
  canLoadMore$: Observable<boolean>;
  nextResultsTrigger$ = new Subject<void>();

  constructor(
    private _summary$: Observable<IChatSummary>,
    private _staffer$: Observable<WithRef<IStaffer>>,
    private _snackBar: MatSnackBar,
    private _onDestroy$: Observable<void>
  ) {
    const newSummary$ = this._summary$.pipe(
      filterUndefined(),
      isPathChanged$('chat.ref.path')
    );

    this.isRead$ = combineLatest([
      this._summary$.pipe(map((summary) => summary.lastMessage)),
      this._staffer$.pipe(filterUndefined()),
    ]).pipe(
      map(
        ([message, staffer]) =>
          !message ||
          message.readBy.some((readBy) => isSameRef(readBy, staffer))
      )
    );

    this.canMarkUnread$ = combineLatest([this.isRead$, this._summary$]).pipe(
      map(([isRead, summary]) => {
        if (
          !summary.lastMessage ||
          summary.chat.status === ChatStatus.Archived
        ) {
          return false;
        }
        return isRead;
      })
    );

    this.isOpen$ = this._summary$.pipe(
      map((summary) => summary.chat.status !== ChatStatus.Archived)
    );

    this.isMuted$ = this._summary$.pipe(map((summary) => !!summary.chat.muted));

    this.messages$ = newSummary$.pipe(
      switchMap((summary) =>
        paginatedQuery$(
          undeletedQuery(Chat.chatMessageCol(summary.chat)),
          20,
          this.nextResultsTrigger$,
          undefined,
          isSameRef,
          undefined,
          undefined,
          'desc'
        )
      ),
      multiSort((messageA, messageB) =>
        sortTimestampAsc(messageA.createdAt, messageB.createdAt)
      ),
      shareReplayCold()
    );

    this.interactions$ = newSummary$.pipe(
      switchMap((summary) =>
        paginatedQuery$(
          undeletedQuery(Chat.interactionCol(summary.chat)),
          20,
          this.nextResultsTrigger$,
          undefined,
          isSameRef,
          undefined,
          undefined,
          'desc'
        )
      ),
      multiSort((messageA, messageB) =>
        sortTimestampAsc(messageA.createdAt, messageB.createdAt)
      ),
      shareReplayCold()
    );

    this.content$ = combineLatest([this.interactions$, this.messages$]).pipe(
      map(([interactions, messages]) => [...interactions, ...messages]),
      multiSort((messageA, messageB) =>
        sortTimestampAsc(messageA.createdAt, messageB.createdAt)
      ),
      tap(() => this.loading$.next(false)),
      shareReplayCold()
    );

    const oldestMessage$ = newSummary$.pipe(
      switchMap((summary) =>
        firstResult$(
          undeletedQuery(Chat.chatMessageCol(summary.chat)),
          orderBy('createdAt', 'asc')
        )
      )
    );

    this.canLoadMore$ = newSummary$.pipe(
      switchMap(() =>
        combineLatest([this.messages$, oldestMessage$]).pipe(
          map(([messages, oldestMessage]) => {
            const lastMessage = first(messages);
            if (!lastMessage) {
              return false;
            }

            return !isSameRef(lastMessage, oldestMessage);
          })
        )
      )
    );

    this.contentAdded$ = this.content$.pipe(
      pairwise(),
      filter(
        ([previousContent, currentContent]) =>
          previousContent.length < currentContent.length
      ),
      map(() => undefined)
    );
  }

  static async addInteraction(
    staffer: WithRef<IStaffer>,
    chatRef: DocumentReference<IChat>,
    title: RawInlineNodes,
    content: MixedSchema = initVersionedSchema()
  ): Promise<void> {
    const interaction = Interaction.init({
      title,
      content,
      type: InteractionType.Note,
      owner: stafferToNamedDoc(staffer),
    });

    await addInteractions(
      {
        contact: toMention(
          { name: '', ref: chatRef },
          MentionResourceType.Chat
        ),
      },
      interaction
    );
  }

  loadMoreMessages(): void {
    this.nextResultsTrigger$.next();
    this.loading$.next(true);
  }

  async markRead(): Promise<void> {
    const summary = await snapshot(this._summary$);
    const staffer = await snapshot(this._staffer$);
    if (!summary.lastMessage) {
      return;
    }
    const isRead = summary.lastMessage.readBy.some((readBy) =>
      isSameRef(readBy, staffer)
    );
    if (isRead) {
      return;
    }
    await Firestore.patchDoc(summary.lastMessage.ref, {
      readBy: [...summary.lastMessage.readBy, staffer.ref],
    });
  }

  async markUnread(): Promise<void> {
    const summary = await snapshot(this._summary$);
    const staffer = await snapshot(this._staffer$);
    if (!summary.lastMessage) {
      return;
    }
    await Firestore.patchDoc(summary.lastMessage.ref, {
      readBy: summary.lastMessage.readBy.filter(
        (readBy) => !isSameRef(readBy, staffer)
      ),
    });
  }

  async archiveChat(): Promise<void> {
    const summary = await snapshot(this._summary$);
    await Firestore.patchDoc(summary.chat.ref, {
      status: ChatStatus.Archived,
    });

    const staffer = await snapshot(this._staffer$);

    await ChatActions.addInteraction(staffer, summary.chat.ref, [
      toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
      toTextContent(` archived the chat`),
    ]);

    this._snackBar
      .open('Chat Archived', 'Undo', { duration: 5000 })
      .onAction()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => void this.reopenChat());
  }

  async reopenChat(): Promise<void> {
    const summary = await snapshot(this._summary$);
    await Firestore.patchDoc(summary.chat.ref, {
      status: ChatStatus.Open,
    });
    this._snackBar.open('Chat reopened');

    const staffer = await snapshot(this._staffer$);
    await ChatActions.addInteraction(staffer, summary.chat.ref, [
      toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
      toTextContent(` reopened the chat`),
    ]);
  }

  async muteChat(): Promise<void> {
    const summary = await snapshot(this._summary$);
    await Firestore.patchDoc(summary.chat.ref, {
      muted: true,
    });
    this._snackBar.open('Chat muted');
  }

  async unmuteChat(): Promise<void> {
    const summary = await snapshot(this._summary$);
    await Firestore.patchDoc(summary.chat.ref, {
      muted: false,
    });
    this._snackBar.open('Chat unmuted');
  }
}
