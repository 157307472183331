import {
  Brand,
  Patient,
  hasMergeConflicts,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  IBrand,
  IDestinationEntity,
  IDestinationEntityRecord,
  IGetRecordResponse,
  IMigratedDataSummary,
  IPatient,
  IPracticeMigration,
  ISourceEntityHandler,
  ISourceEntityRecord,
  IStaffer,
  ITranslationMap,
  IsPrimaryContact,
  MergeConflictDestinationEntityRecord,
  WithPrimaryContact,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  Timestamp,
  WithRef,
  asDocRef,
  getError,
  toTimestamp,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { omit } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationMapHandler } from '../../translation-map';
import { BaseDestinationEntity } from '../base-destination-entity';

export const PATIENT_RESOURCE_TYPE = 'patients';

export interface IPatientDestinationRecord {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  patientRef: DocumentReference<IPatient>;
}

export interface IPatientErrorData {
  sourceRef: DocumentReference<ISourceEntityRecord>;
}

export interface IPatientJobData<PatientRecord extends object> {
  sourcePatient: IGetRecordResponse<PatientRecord>;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  brand: WithRef<IBrand>;
}

export interface IPatientMigrationData {
  sourcePatientId: string;
  patient: IPatient;
  createdAt?: Timestamp;
}

export abstract class BasePatientDestinationEntity<
  PatientRecord extends object,
> extends BaseDestinationEntity<
  IPatientDestinationRecord,
  IPatientJobData<PatientRecord>,
  IPatientMigrationData
> {
  abstract patientSourceEntity: ISourceEntityHandler<PatientRecord[]>;

  get sourceCountComparison(): ISourceEntityHandler<PatientRecord[]> {
    return this.patientSourceEntity;
  }

  sourceCountDataAccessor(
    data: IPatientJobData<PatientRecord>
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IPatientDestinationRecord>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return Firestore.doc$(record.data.patientRef).pipe(
      map((patient) => [
        {
          label: 'Patient',
          data: patient,
        },
      ])
    );
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IPatientMigrationData
  ): Promise<IPatientMigrationData | undefined> {
    const existingPatientRef = await translationMap.getDestination(
      data.sourcePatientId,
      this.patientSourceEntity.sourceEntity.metadata.idPrefix
    );

    if (!existingPatientRef) {
      return;
    }

    try {
      const existingPatient = await Firestore.getDoc(
        asDocRef<IPatient>(existingPatientRef)
      );

      const hasMergeConflict = hasMergeConflicts(
        omit(data.patient, [
          'relationships',
          'isPrimaryContact',
          'primaryContact',
          'metadata',
          'accountSummary',
          'profileImageURL',
        ]),
        omit(existingPatient, [
          'relationships',
          'isPrimaryContact',
          'primaryContact',
          'metadata',
          'accountSummary',
          'profileImageURL',
        ])
      );

      if (hasMergeConflict) {
        return {
          ...data,
          patient: Patient.init(
            omit(existingPatient, [
              'relationships',
              'isPrimaryContact',
              'primaryContact',
              'metadata',
              'accountSummary',
              'profileImageURL',
            ])
          ),
        };
      }
    } catch (error) {
      return;
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientJobData<PatientRecord>,
    _migrationData: IPatientMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  getDestinationEntityRecordUid(data: IPatientJobData<PatientRecord>): string {
    return data.sourcePatient.record.uid;
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: IPatientJobData<PatientRecord>,
    migrationData: IPatientMigrationData
  ): Promise<IDestinationEntityRecord> {
    const existingPatientRef = await translationMap.getDestination<IPatient>(
      migrationData.sourcePatientId,
      this.patientSourceEntity.sourceEntity.metadata.idPrefix
    );

    if (existingPatientRef) {
      try {
        const existingPatient = await Firestore.getDoc(
          asDocRef<IPatient & IsPrimaryContact & WithPrimaryContact>(
            existingPatientRef
          )
        );
        migrationData.patient.metadata = existingPatient.metadata;
        migrationData.patient.accountSummary = existingPatient.accountSummary;
        migrationData.patient.profileImageURL = existingPatient.profileImageURL;
        migrationData.patient.relationships = existingPatient.relationships;
        (migrationData.patient as IsPrimaryContact).isPrimaryContact =
          existingPatient.isPrimaryContact;
        (migrationData.patient as WithPrimaryContact).primaryContact =
          existingPatient.primaryContact;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          `Error getting existing patient data for ${migrationData.sourcePatientId}`,
          getError(error)
        );
      }
    }

    const patientRef = await FirestoreMigrate.upsertDoc(
      Brand.patientCol(jobData.brand),
      {
        ...migrationData.patient,
        createdAt: migrationData.createdAt,
      },
      existingPatientRef?.id
    );

    if (!existingPatientRef) {
      await translationMap.upsert({
        sourceIdentifier: migrationData.sourcePatientId,
        destinationIdentifier: patientRef,
        resourceType: this.patientSourceEntity.sourceEntity.metadata.idPrefix,
      });
    }

    return this._buildSuccessResponse(jobData.sourcePatient.record, patientRef);
  }

  protected _buildErrorResponse(
    patient: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: patient.uid,
      label: patient.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: patient.ref,
      errorMessage: errorMessage ?? `Can't resolve patient data`,
      failData: {
        patientRef: patient.ref,
      },
    };
  }

  protected _buildSuccessResponse(
    record: IGetRecordResponse['record'],
    patientRef: DocumentReference<IPatient>
  ): IDestinationEntityRecord<IPatientDestinationRecord> {
    return {
      uid: record.uid,
      label: record.label,
      data: {
        sourceRef: record.ref,
        patientRef,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: record.ref,
      migratedAt: toTimestamp(),
    };
  }
}
