<mat-toolbar color="accent">Create Condition</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button
        mat-flat-button
        type="submit"
        color="primary"
        [disabled]="form.invalid"
      >
        Create
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
