import {
  DEFAULT_SCHEDULE_ID,
  FeeSchedule,
  hasMergeConflicts,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  IBrand,
  ICustomMappingHandler,
  IDestinationEntity,
  IDestinationEntityJobRunOptions,
  IDestinationEntityRecord,
  IFeeSchedule,
  IGetRecordResponse,
  IMigratedDataSummary,
  IOrganisation,
  IPractice,
  IPracticeMigration,
  ISourceEntityHandler,
  ISourceEntityRecord,
  ITranslationMap,
  MergeConflictDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  INamedDocument,
  WithRef,
  asDocRef,
  doc,
  getError,
  snapshotCombineLatest,
  toNamedDocument,
  toTimestamp,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { Observable, combineLatest, from, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FEE_SCHEDULE_RESOURCE_TYPE } from '../../mappings/fee-schedules';
import { PracticeMigration } from '../../practice-migrations';
import { TranslationMapHandler } from '../../translation-map';
import { BaseDestinationEntity } from '../base-destination-entity';
import { DestinationEntity } from '../destination-entity';

export interface IFeeScheduleDestinationRecord {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  feeScheduleRef: DocumentReference<IFeeSchedule>;
}

export interface IFeeScheduleJobData<FeeScheduleRecord extends object> {
  feeScheduleRecord: IGetRecordResponse<FeeScheduleRecord>;
  practices: WithRef<ITranslationMap<IPractice>>[];
  brand: WithRef<IBrand>;
  feeScheduleMappings: WithRef<ITranslationMap<IFeeSchedule>>[];
}

export interface IFeeScheduleMigrationData {
  isDefault: boolean;
  sourceFeeScheduleId: string;
  feeSchedule: IFeeSchedule;
  practiceRef?: DocumentReference<IPractice>;
  organisationRef: DocumentReference<IOrganisation>;
}

export const FEE_SCHEDULE_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: FEE_SCHEDULE_RESOURCE_TYPE,
    label: 'Fee Schedules',
    description:
      'Fee Schedule custom mapping must be set after this migration is run.',
  },
});

export abstract class BaseFeeScheduleDestination<
  FeeScheduleRecord extends object,
> extends BaseDestinationEntity<
  IFeeScheduleDestinationRecord,
  IFeeScheduleJobData<FeeScheduleRecord>,
  IFeeScheduleMigrationData
> {
  abstract feeScheduleSourceEntity: ISourceEntityHandler<FeeScheduleRecord[]>;
  abstract practiceCustomMapping: ICustomMappingHandler<IPractice>;
  abstract feeScheduleCustomMapping: ICustomMappingHandler<IFeeSchedule>;

  destinationEntity = FEE_SCHEDULE_DESTINATION_ENTITY;

  get sourceCountComparison(): ISourceEntityHandler<FeeScheduleRecord[]> {
    return this.feeScheduleSourceEntity;
  }

  getDestinationEntityRecordUid(
    data: IFeeScheduleJobData<FeeScheduleRecord>
  ): string {
    return data.feeScheduleRecord.record.uid;
  }

  sourceCountDataAccessor(
    data: IFeeScheduleJobData<FeeScheduleRecord>
  ): DocumentReference<ISourceEntityRecord> {
    return data.feeScheduleRecord.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IFeeScheduleDestinationRecord>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return from(Firestore.getDoc(record.data.feeScheduleRef)).pipe(
      map((feeSchedule) => [
        {
          label: 'Fee Schedule',
          data: feeSchedule,
        },
      ])
    );
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IFeeScheduleJobData<FeeScheduleRecord>[]> {
    const practices$ = this.practiceCustomMapping.getRecords$(
      translationMapHandler
    );
    const feeScheduleMappings$ = this.feeScheduleCustomMapping.getRecords$(
      translationMapHandler
    );
    const brand$ = PracticeMigration.brand$(migration);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.feeScheduleSourceEntity,
        runOptions
      ),
      snapshotCombineLatest([brand$, practices$, feeScheduleMappings$]),
    ]).pipe(
      map(([feeScheduleRecords, [brand, practices, feeScheduleMappings]]) =>
        feeScheduleRecords.map((feeScheduleRecord) => ({
          feeScheduleRecord,
          brand,
          practices,
          feeScheduleMappings,
        }))
      )
    );
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IFeeScheduleMigrationData
  ): Promise<IFeeScheduleMigrationData | undefined> {
    const existingFeeScheduleRef = data.isDefault
      ? doc(
          data.practiceRef
            ? FeeSchedule.col({
                ref: data.practiceRef,
              })
            : FeeSchedule.col({
                ref: data.organisationRef,
              }),
          DEFAULT_SCHEDULE_ID
        )
      : await translationMap.getDestination(
          data.sourceFeeScheduleId,
          FEE_SCHEDULE_RESOURCE_TYPE
        );

    if (!existingFeeScheduleRef) {
      return;
    }

    const existingFeeSchedule = await Firestore.getDoc(
      existingFeeScheduleRef as DocumentReference<IFeeSchedule>
    );

    const hasMergeConflict = hasMergeConflicts(
      data.feeSchedule,
      existingFeeSchedule
    );

    if (hasMergeConflict) {
      return {
        ...data,
        feeSchedule: existingFeeSchedule,
      };
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IFeeScheduleJobData<FeeScheduleRecord>,
    _migrationData: IFeeScheduleMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.feeScheduleRecord.record.uid,
      label: jobData.feeScheduleRecord.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.feeScheduleRecord.record.ref,
    };
  }

  async runJob(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: IFeeScheduleJobData<FeeScheduleRecord>,
    migrationData: IFeeScheduleMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const mapping = jobData.feeScheduleMappings.find(
        (feeScheduleMapping) =>
          feeScheduleMapping.sourceIdentifier ===
          migrationData.sourceFeeScheduleId
      );

      if (mapping?.destinationIdentifier) {
        await translationMap.upsert({
          sourceIdentifier: migrationData.sourceFeeScheduleId,
          destinationIdentifier: mapping?.destinationIdentifier,
          resourceType: FEE_SCHEDULE_RESOURCE_TYPE,
        });
        return this._buildSkippedResponse(jobData.feeScheduleRecord);
      }

      const existingFeeScheduleRef = migrationData.isDefault
        ? doc(
            FeeSchedule.col({
              ref: migration.configuration.organisation.ref,
            }),
            DEFAULT_SCHEDULE_ID
          )
        : await translationMap.getDestination(
            migrationData.sourceFeeScheduleId,
            FEE_SCHEDULE_RESOURCE_TYPE
          );

      const feeScheduleRef = await FirestoreMigrate.upsertDoc(
        FeeSchedule.col({
          ref: migration.configuration.organisation.ref,
        }),
        migrationData.feeSchedule,
        existingFeeScheduleRef?.id
      );

      if (!existingFeeScheduleRef) {
        await translationMap.upsert({
          sourceIdentifier: migrationData.sourceFeeScheduleId,
          destinationIdentifier: feeScheduleRef,
          resourceType: FEE_SCHEDULE_RESOURCE_TYPE,
        });
      }

      return this._buildSuccessResponse(
        jobData.feeScheduleRecord,
        feeScheduleRef
      );
    } catch (error) {
      const errorResponseData = {
        label: jobData.feeScheduleRecord.record.label,
        uid: jobData.feeScheduleRecord.record.uid,
        ref: jobData.feeScheduleRecord.record.ref,
      };

      return this._buildErrorResponse(errorResponseData, getError(error));
    }
  }

  protected _buildErrorResponse(
    feeSchedule: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: feeSchedule.uid,
      label: feeSchedule.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: feeSchedule.ref,
      errorMessage: errorMessage ?? `Can't resolve fee schedule data`,
      failData: {
        feeScheduleRef: feeSchedule.ref,
      },
    };
  }

  protected _buildSuccessResponse(
    record: IGetRecordResponse<FeeScheduleRecord>,
    feeScheduleRef: DocumentReference<IFeeSchedule>
  ): IDestinationEntityRecord<IFeeScheduleDestinationRecord> {
    return {
      uid: record.record.uid,
      label: record.record.label,
      data: {
        sourceRef: record.record.ref,
        feeScheduleRef,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: record.record.ref,
      migratedAt: toTimestamp(),
    };
  }

  protected _buildSkippedResponse(
    record: IGetRecordResponse<FeeScheduleRecord>
  ): IDestinationEntityRecord {
    return {
      uid: record.record.uid,
      label: record.record.label,
      status: DestinationEntityRecordStatus.Skipped,
      sourceRef: record.record.ref,
    };
  }
}

export type FeeScheduleResolverFn = (
  id?: string
) => Promise<INamedDocument<IFeeSchedule>>;

export function feeScheduleResolverFn(
  translationMap: TranslationMapHandler,
  defaultFeeSchedule: INamedDocument<IFeeSchedule>
): FeeScheduleResolverFn {
  return async (id?: string) => {
    if (!id) {
      return toNamedDocument(defaultFeeSchedule);
    }
    const resolved = await translationMap.getBySource(
      id,
      FEE_SCHEDULE_RESOURCE_TYPE
    );
    if (!resolved || !resolved.destinationIdentifier) {
      return toNamedDocument(defaultFeeSchedule);
    }
    return toNamedDocument(
      await Firestore.getDoc(
        asDocRef<IFeeSchedule>(resolved.destinationIdentifier)
      )
    );
  };
}
