import {
  IFileData,
  ISQLSeedingTranslator,
} from '@principle-theorem/principle-core/interfaces';
import { IProvider } from '@principle-theorem/shared';

export class PraktikaPatientDepositAdjustmentTranslator
  implements ISQLSeedingTranslator, IProvider<IFileData, string[]>
{
  matchingFilenames = [
    'patient_deposit_adjustments.csv',
    'patient_deposit_payments.csv',
  ];
  commands = [`sed -i -e 's/vchNotes/vchNotes2/2'`];

  canProvide(data: IFileData): boolean {
    return this.matchingFilenames.includes(data.fileName);
  }

  execute(data: IFileData): string[] {
    return this.commands.map((cmd) => `${cmd} ${data.filePath}`);
  }
}
