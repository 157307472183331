import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type ICalendarEvent } from '@principle-theorem/principle-core/interfaces';

interface IAddCalendarDialogData {
  calendarEvent?: ICalendarEvent;
}

@Component({
    selector: 'pr-add-calendar-event',
    templateUrl: './add-calendar-event.component.html',
    styleUrls: ['./add-calendar-event.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddCalendarEventComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAddCalendarDialogData,
    public dialogRef: MatDialogRef<AddCalendarEventComponent, ICalendarEvent>
  ) {}

  save(event: ICalendarEvent): void {
    this.dialogRef.close(event);
  }
}
