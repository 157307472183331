import {
  DocumentReference,
  Firestore,
  WithRef,
  getError,
  snapshotCombineLatest,
  toTimestamp,
  undeletedQuery,
  where,
} from '@principle-theorem/shared';
import { DestinationEntity } from '../destination-entity';
import {
  type IDestinationEntityRecord,
  type IBrand,
  type IContactNumber,
  type IGetRecordResponse,
  type IMigratedWithErrors,
  type IPatient,
  type ISourceEntityHandler,
  type ISourceEntityRecord,
  type IMigratedDataSummary,
  DestinationEntityRecordStatus,
  type IPracticeMigration,
  type IDestinationEntity,
  type MergeConflictDestinationEntityRecord,
  type IDestinationEntityJobRunOptions,
  type MigratedDestinationEntityRecord,
  type SkippedDestinationEntityRecord,
  type FailedDestinationEntityRecord,
  SourceEntityRecordStatus,
} from '@principle-theorem/principle-core/interfaces';
import { Brand } from '@principle-theorem/principle-core';
import { BaseDestinationEntity } from '../base-destination-entity';
import { Observable, combineLatest, from, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationMapHandler } from '../../translation-map';
import { PracticeMigration } from '../../practice-migrations';

export const EXISTING_PATIENT_TYPE = 'existingPatients';

export const EXISTING_PATIENT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: EXISTING_PATIENT_TYPE,
    label: 'Link Existing Patients',
    description:
      'Link patients in the migration to existing patients in the Principle Workspace.',
  },
});

export interface IExistingPatientSuccessData {
  patientRef: DocumentReference<IPatient>;
}

export interface IExistingPatientJobData<T> {
  sourcePatient: IGetRecordResponse<T>;
  brand: WithRef<IBrand>;
}

export interface IExistingPatientMigrationData {
  sourcePatientId: string;
  patientRef?: DocumentReference<IPatient>;
  conflictSummary?: IExistingPatientMergeConflictSummary;
}

export enum MergeConflictExistingPatientStatus {
  Unresolved = 'unresolved',
  Resolved = 'resolved',
}

export interface IExistingPatientMergeConflictSummary {
  sourcePatientId: string;
  patientName: string;
  dateOfBirth: string;
  email: string;
  phone: IContactNumber[];
  gender: string;
  patientRefs: DocumentReference<IPatient>[];
  status: MergeConflictExistingPatientStatus;
}

export type ExistingPatientMergeConflict = IMigratedWithErrors<
  object,
  IExistingPatientMergeConflictSummary
>;

export abstract class BaseExistingPatientDestinationEntity<
  PatientRecord extends object,
> extends BaseDestinationEntity<
  IExistingPatientSuccessData,
  IExistingPatientJobData<PatientRecord>,
  IExistingPatientMigrationData,
  ExistingPatientMergeConflict
> {
  destinationEntity = EXISTING_PATIENT_DESTINATION_ENTITY;
  override batchLimit = 1000;
  abstract patientSourceEntity: ISourceEntityHandler<PatientRecord[]>;
  abstract getDateOfBirth(
    sourcePatient: IGetRecordResponse<PatientRecord>
  ): string | undefined;

  abstract filterMatchingPatients(
    sourcePatient: PatientRecord,
    matchingPatients: WithRef<IPatient>[]
  ): WithRef<IPatient>[];

  abstract buildConflictSummary(
    sourcePatient: PatientRecord
  ): Omit<IExistingPatientMergeConflictSummary, 'patientRefs' | 'status'>;

  get sourceCountComparison(): ISourceEntityHandler<PatientRecord[]> {
    return this.patientSourceEntity;
  }

  sourceCountDataAccessor(
    data: IExistingPatientJobData<PatientRecord>
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getDestinationEntityRecordUid(
    data: IExistingPatientJobData<PatientRecord>
  ): string {
    return data.sourcePatient.record.uid;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IExistingPatientSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return from(Firestore.getDoc(record.data.patientRef)).pipe(
      map((patient) => [
        {
          label: 'Patient',
          data: patient,
        },
      ])
    );
  }

  hasMergeConflict(
    _translationMap: TranslationMapHandler,
    _data: IExistingPatientMigrationData
  ): undefined {
    return;
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IExistingPatientJobData<PatientRecord>,
    _migrationData: IExistingPatientMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IExistingPatientJobData<PatientRecord>[]> {
    const brand$ = PracticeMigration.brand$(migration);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.patientSourceEntity,
        runOptions
      ),
      snapshotCombineLatest([brand$]),
    ]).pipe(
      map(([patients, [brand]]) =>
        patients.map((patient) => ({
          sourcePatient: patient,
          brand,
        }))
      )
    );
  }

  async buildMigrationData(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IExistingPatientJobData<PatientRecord>
  ): Promise<
    | IExistingPatientMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
    | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
  > {
    if (data.sourcePatient.record.status === SourceEntityRecordStatus.Invalid) {
      return this._buildErrorResponse(
        data.sourcePatient,
        'Source patient is invalid'
      );
    }

    const sourcePatient = data.sourcePatient.data.data;
    const sourcePatientId = this.patientSourceEntity
      .getSourceRecordId(sourcePatient)
      .toString();

    const existingPatientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      this.patientSourceEntity.sourceEntity.metadata.idPrefix
    );

    if (existingPatientRef) {
      return {
        sourcePatientId,
        patientRef: existingPatientRef,
      };
    }

    const dateOfBirth = this.getDateOfBirth(data.sourcePatient);
    if (!dateOfBirth) {
      return this._buildErrorResponse(
        data.sourcePatient,
        'Patient date of birth is missing'
      );
    }

    const matchingPatients = await Firestore.getDocs(
      undeletedQuery(Brand.patientCol({ ref: data.brand.ref })),
      where('dateOfBirth', '==', dateOfBirth)
    );

    const filteredPatients = this.filterMatchingPatients(
      sourcePatient,
      matchingPatients
    );
    if (!filteredPatients.length) {
      return this._buildSkippedResponse(data.sourcePatient);
    }

    if (filteredPatients.length > 1) {
      return {
        sourcePatientId,
        conflictSummary: {
          status: MergeConflictExistingPatientStatus.Unresolved,
          patientRefs: filteredPatients.map((patient) => patient.ref),
          ...this.buildConflictSummary(sourcePatient),
        },
      };
    }

    const patient = filteredPatients[0];
    return {
      sourcePatientId,
      patientRef: patient.ref,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: IExistingPatientJobData<PatientRecord>,
    migrationData: IExistingPatientMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      if (migrationData.conflictSummary) {
        return this._buildMigratedWithErrorsResponse(
          jobData.sourcePatient,
          migrationData.conflictSummary
        );
      }

      if (!migrationData.patientRef) {
        throw new Error('Patient reference is missing');
      }

      await translationMap.upsert({
        sourceIdentifier: migrationData.sourcePatientId,
        destinationIdentifier: migrationData.patientRef,
        resourceType: this.patientSourceEntity.sourceEntity.metadata.idPrefix,
      });

      return this._buildSuccessResponse(
        jobData.sourcePatient,
        migrationData.patientRef
      );
    } catch (error) {
      return this._buildErrorResponse(jobData.sourcePatient, getError(error));
    }
  }

  _buildErrorResponse(
    patient: IGetRecordResponse<PatientRecord>,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: patient.record.ref,
      errorMessage: errorMessage ?? `Can't resolve patient data`,
      failData: {},
    };
  }

  _buildSkippedResponse(
    patient: IGetRecordResponse<PatientRecord>
  ): IDestinationEntityRecord & SkippedDestinationEntityRecord {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      status: DestinationEntityRecordStatus.Skipped,
      sourceRef: patient.record.ref,
    };
  }

  _buildSuccessResponse(
    patient: IGetRecordResponse<PatientRecord>,
    patientRef: DocumentReference<IPatient>
  ): IDestinationEntityRecord<IExistingPatientSuccessData> &
    MigratedDestinationEntityRecord<IExistingPatientSuccessData> {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: patient.record.ref,
      data: {
        patientRef,
      },
      migratedAt: toTimestamp(),
    };
  }

  _buildMigratedWithErrorsResponse(
    patient: IGetRecordResponse<PatientRecord>,
    failData: IExistingPatientMergeConflictSummary
  ): ExistingPatientMergeConflict {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      sourceRef: patient.record.ref,
      migratedAt: toTimestamp(),
      status: DestinationEntityRecordStatus.MigratedWithErrors,
      data: {},
      failData,
    };
  }
}
