import { type ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { type MatDrawerMode } from '@angular/material/sidenav';
import { BehaviorSubject, merge, type Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISidebarComponentData } from './dynamic-sidebar.component';

export interface IDynamicSidebarComponent<T, D extends object> {
  component: ComponentType<T>;
  close: SidebarCloseCallbackFn;
  config?: Partial<IDynamicSidebarConfig<D>>;
}

export interface IDynamicSidebarConfig<D extends object> {
  data: D;
  width: number;
  mode: MatDrawerMode;
  autoFocus: boolean;
  position: 'start' | 'end';
  cleanUpCallbackFn: SidebarCloseCallbackFn;
}

export type SidebarCloseCallbackFn = (isSave?: boolean) => Promise<void>;

@Injectable({
  providedIn: 'root',
})
export class DynamicSidebarService {
  private _close$ = new Subject<boolean | void>();
  private _isOpen$ = new BehaviorSubject<boolean>(false);
  open$ = new Subject<
    IDynamicSidebarComponent<unknown, ISidebarComponentData>
  >();
  close$: Observable<boolean>;
  isOpen$: Observable<boolean> = this._isOpen$.asObservable();
  currentComponent$ = new BehaviorSubject<ComponentType<unknown> | undefined>(
    undefined
  );

  constructor() {
    this.close$ = merge(this._close$).pipe(map((isSave) => !!isSave));
  }

  open<T, D extends ISidebarComponentData>(
    component: ComponentType<T>,
    config: Partial<IDynamicSidebarConfig<D>> = {}
  ): void {
    this._isOpen$.next(true);
    this.currentComponent$.next(component);
    const closeHandler: SidebarCloseCallbackFn = async (isSave?: boolean) => {
      await config?.cleanUpCallbackFn?.();
      this._close$.next(isSave);
      this.currentComponent$.next(undefined);
    };
    this.open$.next({
      component,
      config,
      close: closeHandler,
    });
  }

  close(isSave?: boolean): void {
    this._close$.next(isSave);
    this._isOpen$.next(false);
    this.currentComponent$.next(undefined);
  }
}
