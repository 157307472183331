import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabJob } from '@principle-theorem/principle-core';
import {
  type ILabJob,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { doc$, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface ILabJobInteractionsData {
  labJob: WithRef<ILabJob>;
}

@Component({
  selector: 'pr-lab-job-interactions-dialog',
  templateUrl: './lab-job-interactions-dialog.component.html',
  styleUrls: ['./lab-job-interactions-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabJobInteractionsDialogComponent {
  labJob$: Observable<WithRef<ILabJob>>;
  practice$: Observable<WithRef<IPractice>>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ILabJobInteractionsData,
    public dialogRef: MatDialogRef<LabJobInteractionsDialogComponent>
  ) {
    this.labJob$ = doc$(data.labJob.ref);
    this.practice$ = this.labJob$.pipe(
      switchMap((labJob) => LabJob.practice$(labJob))
    );
  }

  onStatusUpdate(): void {
    this.dialogRef.close();
  }
}
