import { Injectable } from '@angular/core';
import {
  type IContextualAction,
  type IRoutable,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { isPrincipleMention } from '@principle-theorem/principle-core/interfaces';
import { type IReffable } from '@principle-theorem/shared';

@Injectable({
  providedIn: 'root',
})
export class LoadStafferActionService implements IContextualAction {
  icon = 'open_in_new';
  name = 'Staffer Profile';

  constructor(private _stateNav: StateBasedNavigationService) {}

  async do(staffer: IRoutable | IReffable): Promise<void> {
    if (isPrincipleMention(staffer)) {
      staffer = staffer.resource;
    }
    const uid = 'uid' in staffer ? staffer.uid : staffer.ref.id;
    await this._stateNav.brand(['staff', uid]);
  }
}
