export class HTMLToEmail {
  static italic(text: string): string {
    return `<em>${text}</em>`;
  }

  static bold(text: string): string {
    return `<strong>${text}</strong>`;
  }

  static link(text: string, link: string): string {
    return `<a href="${link}" target="_blank">${text}</a>`;
  }
}
