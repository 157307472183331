import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { type IOrganisation } from '@principle-theorem/principle-core/interfaces';
import { Region, getEnumValues } from '@principle-theorem/shared';

export type OrganisationFormData = Pick<IOrganisation, 'name' | 'region'>;

@Component({
  selector: 'pr-organisation-add-dialog',
  templateUrl: './organisation-add-dialog.component.html',
  styleUrls: ['./organisation-add-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationAddDialogComponent {
  regions = getEnumValues(Region);
  form: TypedFormGroup<OrganisationFormData> =
    new TypedFormGroup<OrganisationFormData>({
      name: new TypedFormControl<string>('', [Validators.required]),
      region: new TypedFormControl<Region>(undefined, [Validators.required]),
    });

  constructor(
    private _dialogRef: MatDialogRef<
      OrganisationAddDialogComponent,
      Partial<IOrganisation> | undefined
    >
  ) {}

  canSave(): boolean {
    return this.form.valid && this.form.dirty;
  }

  submit(): void {
    this._dialogRef.close(this.form.getRawValue());
  }
}
