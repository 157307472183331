/* eslint-disable no-null/no-null */
import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { isObject } from '@principle-theorem/shared';
import { flow, isNull, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const CONTACT_RESOURCE_TYPE = 'contact';

export const CONTACT_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Contact List',
    description: '',
    idPrefix: CONTACT_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ID4WContact {
  id: number;
  name: string;
  address: string | null;
  phone: string | null;
  code: string | null;
}

export function isD4WContact(item: unknown): item is ID4WContact {
  return (
    isObject(item) &&
    isString(item.name) &&
    (isString(item.address) || isNull(item.address)) &&
    (isString(item.code) || isNull(item.code)) &&
    (isString(item.phone) || isNull(item.phone))
  );
}

const CONTACT_SOURCE_QUERY = `
SELECT
  third_party_id AS id,
  name,
  NULLIF(address, '') AS address,
  NULLIF(convert_to_text(phone_1), '') AS phone,
  NULLIF(code, '') AS code
FROM third_parties
WHERE third_party_id IS NOT NULL
`;

export class ContactSourceEntity extends BaseSourceEntity<ID4WContact> {
  sourceEntity = CONTACT_SOURCE_ENTITY;
  entityResourceType = CONTACT_RESOURCE_TYPE;
  sourceQuery = CONTACT_SOURCE_QUERY;
  verifySourceFn = isD4WContact;
  override transformDataFn = flow([]);

  translate(_data: ID4WContact): unknown {
    return {};
  }

  getSourceRecordId(data: ID4WContact): string | number {
    return data.id;
  }

  getSourceLabel(data: ID4WContact): string {
    return `${data.name}`;
  }
}
