import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  type OnDestroy,
} from '@angular/core';
import { type ITimelineDisplayOptions } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { InteractiveTimelineDisplayCalculator } from '../interactive-timeline-display-calculator';

@Component({
  selector: 'pr-interactive-timeline-gridlines',
  templateUrl: './interactive-timeline-gridlines.component.html',
  styleUrls: ['./interactive-timeline-gridlines.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InteractiveTimelineGridlinesComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  @HostBinding('style.background-size') backgroundSize: string;
  @HostBinding('class.horizontal') isHorizontal = true;
  @HostBinding('class.vertical') isVertical = false;
  options$ = new ReplaySubject<ITimelineDisplayOptions>(1);

  @Input()
  set options(options: ITimelineDisplayOptions) {
    if (options) {
      this.options$.next(options);
    }
  }

  constructor() {
    this.options$
      .pipe(
        map((options) =>
          InteractiveTimelineDisplayCalculator.getGridlineSize(options)
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((backgroundSize) => (this.backgroundSize = backgroundSize));

    this.options$
      .pipe(
        map(InteractiveTimelineDisplayCalculator.isHorizontal),
        distinctUntilChanged(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((isHorizontal) => {
        this.isHorizontal = isHorizontal;
        this.isVertical = !isHorizontal;
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
