import {
  CanBeChartedProperty,
  type IChartConfig,
} from '@principle-theorem/reporting';
import { isObject, toInt } from '@principle-theorem/shared';
import * as d3 from 'd3';
import * as dc from 'dc';
import { compact, first, get, isString } from 'lodash';
import { type Subject } from 'rxjs';
import { type IDCTransformResult } from '../../../../models/report/charts/data-transformers/dc-data-transformer';
import {
  DataPointValueFormatter,
  DataPointValueFormatterFn,
} from '../../data-point-value-formatter';
import {
  D3_CHART_ELEM_SELECTOR,
  applyBaseConfigToChart,
  type ICanBuildForCompositeChart,
  type IDCChartBuilder,
  type IDCChartEvent,
  ChartBuildHelpers,
} from './dc-chart-builder';
import {
  DEFAULT_COLUMN_CHART_BUILDER_CONFIG,
  IColumnChartBuilderConfig,
} from './dc-column-chart-builder';
import { DCTooltip, IDCTooltip } from './dc-tooltip';
import { TimePlottedChart } from './time-plotted-chart';

export class DcLineChartBuilder
  implements
    ICanBuildForCompositeChart<dc.LineChart>,
    IDCChartBuilder<dc.LineChart>
{
  constructor(
    private _config: IColumnChartBuilderConfig = DEFAULT_COLUMN_CHART_BUILDER_CONFIG
  ) {}

  getLabel(config: IChartConfig): string {
    return config.labels.title;
  }

  getChartClasses(_config: IChartConfig): string[] {
    return compact([
      this._config.rotateXAxisLabels ? 'rotate-x-axis-label' : undefined,
    ]);
  }

  getChart(
    results: IDCTransformResult[],
    config: IChartConfig,
    _eventListener$?: Subject<IDCChartEvent>
  ): dc.BaseMixin<dc.LineChart> | undefined {
    const result = first(results);
    const measure = first(config.builderData.measures);
    if (!result || !measure) {
      return;
    }

    const chart = this.build(D3_CHART_ELEM_SELECTOR, result, config);
    applyBaseConfigToChart(this._config, chart);

    this._buildXAxis(chart, config, measure.measure);
    this._buildYAxis(chart, config, measure.measure);

    chart.margins({
      top: 0,
      right: 0,
      bottom: this._config.rotateXAxisLabels ? 80 : 20,
      left: 80,
    });

    if (config.builderData.colourOverride) {
      chart.colorCalculator((data: unknown) =>
        isObject(data)
          ? String(get(data, 'key.colour', get(data, 'key', undefined)))
          : String(data)
      );
    }

    const updateEventHandler = (chartData: dc.LineChart): void => {
      if (
        config.builderData.plottedOverTime &&
        config.builderData.groupByDimension
      ) {
        TimePlottedChart.preHookHandler(
          chartData,
          result,
          config.builderData.groupByDimension
        );
      }
    };
    chart.on('preRender', updateEventHandler);
    chart.on('preRedraw', updateEventHandler);

    return chart;
  }

  getTooltip(config: IChartConfig): IDCTooltip {
    const measure = first(config.builderData.measures);
    return new DCTooltip(
      '.line',
      (data: unknown) => String(get(data, 'data.key.label', '')),
      (data: unknown) => this._formatter(measure?.measure)(this._getValue(data))
    );
  }

  // getLegend(): dc.Legend {
  //   const legend = dc.legend().horizontal(false); // .highlightSelected(true);
  //   legend.legendText((data: unknown) => String(get(data, 'name.label', '')));
  //   return legend;
  // }

  build(
    parent: string | dc.CompositeChart,
    result: IDCTransformResult,
    config: IChartConfig
  ): dc.LineChart {
    if (config.builderData.measures[0].filters?.length) {
      const { dimension, group } = ChartBuildHelpers.buildFilteredDimension(
        result,
        config,
        config.builderData.measures[0]
      );

      return dc.lineChart(parent).dimension(dimension).group(group);
    }

    return dc
      .lineChart(parent)
      .dimension(result.dimension)
      .group(result.group)
      .brushOn(false);
    // .xyTipsOn(true)
    // .valueAccessor(
    //   this._formatter(measure?.measure)(this._getValue(data))
    // );
  }

  private _buildYAxis(
    chart: dc.LineChart,
    _config: IChartConfig,
    measure: CanBeChartedProperty
  ): void {
    chart.elasticY(true);
    chart.yAxis().tickFormat(this._formatter(measure));
  }

  private _buildXAxis(
    chart: dc.LineChart,
    config: IChartConfig,
    _measure: CanBeChartedProperty
  ): void {
    if (config.builderData.plottedOverTime) {
      // TimePlottedChart.xAxisSetup(chart, dateRange);
      const xScale = d3.scaleTime();
      const xUnits: dc.UnitFunction = d3.timeDays as dc.UnitFunction;
      chart.x(xScale).xUnits(xUnits);
      return;
    }

    const xScale = d3.scaleBand();
    const xUnits: dc.UnitFunction = dc.units.ordinal;
    chart
      .x(xScale)
      .xUnits(xUnits)
      .elasticX(true)
      .ordering((data) => toInt(get(data, 'value', '0')));

    chart.xAxis().tickFormat((data) => {
      const label = isString(data) ? data : String(get(data, 'label', ''));
      return label;
    });

    if (this._config.numberOfTicks) {
      chart.xAxis().ticks(this._config.numberOfTicks);
    }
  }

  private _getValue(data: unknown): string {
    const value = get(data, 'value', '') as unknown;
    if (isObject(value)) {
      return String(value.valueOf());
    }
    return String(value);
  }

  private _formatter(
    measure?: CanBeChartedProperty
  ): DataPointValueFormatterFn {
    return DataPointValueFormatter.getFormatter(
      measure?.metadata.formatter,
      measure?.metadata.formatterValue
    );
  }
}
