import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { Timestamp, Timezone, TypeGuard } from '@principle-theorem/shared';
import { compact, flow, isBoolean, isNull, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { convertExactId, getExactSourceDate } from '../../util/helpers';
import { PatientSourceEntity } from './patient';

const NOTE_TABLE_ID = '1003';
const APPOINTMENT_TABLE_ID = '120';

export const PATIENT_NOTES_RESOURCE_TYPE = 'patientNotes';

export const PATIENT_NOTES_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient Notes List',
    description: '',
    idPrefix: PATIENT_NOTES_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export enum ExactPatientNoteType {
  PatientNote = 'PatientNote',
  NextOfKin = 'NextOfKin',
  Email = 'Email',
  MedicalHistory = 'MedicalHistory',
  MedicalNote = 'MedicalNote',
  MedicalAlert = 'MedicalAlert',
  PatientForm = 'PatientForm',
  General = 'General',
}

export interface IExactPatientNote extends Partial<IExactPatientNoteSourceIds> {
  patient_id: string;
  note: string;
  entry_date: string | null;
  user_id: string | null;
  is_pop_up: boolean;
  is_dismissed: boolean;
  colour: string | null;
  type: ExactPatientNoteType;
}

interface IExactPatientNoteResult extends IExactPatientNote {
  note_id: string;
}

interface IExactPatientNoteSourceIds {
  note_id: string;
  appointment_id: string;
  source_id: string;
}

export interface IExactPatientNoteTranslations {
  entryDate: Timestamp;
}

export interface IExactPatientNoteFilters {
  patientId: string;
  appointmentId?: string;
}

function isExactPatientNote(item: unknown): item is IExactPatientNote {
  return TypeGuard.interface<IExactPatientNote>({
    patient_id: isString,
    note: isString,
    entry_date: [isString, isNull],
    user_id: [isString, isNull],
    is_pop_up: isBoolean,
    is_dismissed: isBoolean,
    colour: [isString, isNull],
    type: TypeGuard.enumValue(ExactPatientNoteType),
    note_id: TypeGuard.undefinedOr(isString),
    appointment_id: TypeGuard.undefinedOr(isString),
    source_id: TypeGuard.undefinedOr(isString),
  })(item);
}

const PATIENT_NOTES_SOURCE_QUERY = `
SELECT
  patientid::TEXT AS patient_id,
  sourceid::TEXT AS note_id,
  note,
  NULLIF(entrydate, '') AS entry_date,
  NULLIF(usercode::TEXT, '') AS user_id,
  ispopup AS is_pop_up,
  isdismissed AS is_dismissed,
  NULLIF(notecolor, '') AS colour,
  type
FROM convpatientnote
WHERE note != ''
`;

export class PatientNotesSourceEntity extends BaseSourceEntity<
  IExactPatientNote,
  IExactPatientNoteTranslations,
  IExactPatientNoteFilters
> {
  sourceEntity = PATIENT_NOTES_SOURCE_ENTITY;
  entityResourceType = PATIENT_NOTES_RESOURCE_TYPE;
  sourceQuery = PATIENT_NOTES_SOURCE_QUERY;
  verifySourceFn = isExactPatientNote;
  allowOffsetJob = true;
  override defaultOffsetSize = 20000;

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  override transformDataFn = flow([transformPatientNoteResults]);

  getSourceRecordId(data: IExactPatientNote): string {
    return `${data.patient_id} ${data.source_id}`;
  }

  getSourceLabel(data: IExactPatientNote): string {
    return `${data.patient_id} ${data.source_id}`;
  }

  translate(
    data: IExactPatientNote,
    timezone: Timezone
  ): IExactPatientNoteTranslations {
    return {
      entryDate: getExactSourceDate(data.entry_date, timezone),
    };
  }

  getFilterData(data: IExactPatientNote): IExactPatientNoteFilters {
    return {
      patientId: data.patient_id,
      appointmentId: data.appointment_id,
    };
  }
}

function transformPatientNoteResults(
  rows: IExactPatientNoteResult[]
): IExactPatientNote[] {
  return rows.map((row) => ({
    ...row,
    patient_id: convertExactId(row.patient_id),
    note: row.note.replace(/<br>/g, '\n'),
    ...sourceIdHandler(row.note_id),
  }));
}

export function sourceIdHandler(
  sourceId: string
): Partial<IExactPatientNoteSourceIds> {
  const split = sourceId.split('_').map((item) => {
    const containsNumbers = item.match(/\d+/g);
    if (containsNumbers) {
      return convertExactId(item);
    }
  });
  const ids = compact(split);
  return {
    source_id: convertExactId(sourceId),
    note_id: ids.find((id) => id.endsWith(NOTE_TABLE_ID)),
    appointment_id: ids.find((id) => id.endsWith(APPOINTMENT_TABLE_ID)),
  };
}
