@if (groups$ | async; as groups) {
  @for (group of groups; track group.key) {
    @defer (on viewport) {
      <div class="mb-2 mt-3 text-lg font-bold">
        {{ group.key | map: getGroupTitle }}
      </div>
      <pr-follow-up-list-items
        [followUps]="group.followUps"
        [hideDate]="hideDate$ | async"
      />
    } @placeholder (minimum 150ms) {
      <ngx-skeleton-loader
        animation="pulse"
        count="5"
        [theme]="{ height: '50px' }"
      />
    }
  }
}
