<pt-breadcrumbs current="Schedule Appointment" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="flex flex-auto flex-row overflow-hidden">
  <div class="flex flex-auto overflow-hidden">
    <div class="h-full w-full overflow-scroll">
      <mat-vertical-stepper
        #stepper="matVerticalStepper"
        (selectionChange)="handleStepChange($event)"
      >
        <mat-step
          #patientDetailsStep
          [stepControl]="patientDetails.patientFormGroup"
          [errorMessage]="patientDetails.errorMessage$ | async"
        >
          <ng-template matStepLabel>Patient Details</ng-template>
          <pr-patient-details
            [patient]="patient$ | async"
            (patientSelected)="selectPatient($event)"
            (patientDetailsChange)="patientDetailsChange($event)"
          />
          <div>
            <button mat-flat-button color="primary" matStepperNext>Next</button>
          </div>
        </mat-step>
        <mat-step
          [stepControl]="appointmentDetails.form"
          [errorMessage]="appointmentDetails.errorMessage$ | async"
        >
          <ng-template matStepLabel>Appointment Details</ng-template>
          <pr-appointment-details
            [appointmentDetails]="appointmentDetails$ | async"
            [planTemplates]="planTemplates$ | async"
            [practitioners]="practitioners$ | async"
            [patient]="patient$ | async"
            [practices]="practices$ | async"
            (appointmentDetailsChange)="updateAppointmentDetails($event)"
          />
          <div>
            <button mat-flat-button color="primary" matStepperNext>Next</button>
          </div>
        </mat-step>
        <mat-step #appointmentSelectStep optional>
          <ng-template matStepLabel>Select a Time</ng-template>
          <pr-appointment-selector />
          <div>
            <button mat-flat-button color="primary" matStepperNext>Next</button>
          </div>
        </mat-step>
        <mat-step optional>
          <ng-template matStepLabel>Waitlist</ng-template>
          <pr-waitlist-configuration
            [waitlistDetails]="waitlistDetails$ | async"
            [event]="selectedEvent$ | async"
            [openTime]="openTime$ | async"
            [closeTime]="closeTime$ | async"
            (waitListChange)="updateWaitList($event)"
          />
          <div>
            <button mat-flat-button color="primary" matStepperNext>Next</button>
          </div>
        </mat-step>
        <mat-step label="Automations" optional>
          <pr-appointment-automations-reschedule
            *ngIf="treatmentStepPair$ | async as treatmentStepPair"
            class="mt-4 block"
            [treatmentStep]="treatmentStepPair.step"
            [newEvent]="selectedEvent$ | async"
            [patient]="patient$ | async"
          />
        </mat-step>
      </mat-vertical-stepper>
      <div class="bg-white px-8 py-4">
        <pr-confirm-appointment (tagsChange)="updateTags($event)" />
        <div>
          <pt-loader-button
            type="flat"
            [loading]="saving$ | async"
            [disabled]="(canSave$ | async) === false"
            color="primary"
            (clicked)="save()"
          >
            Create Appointment
          </pt-loader-button>
        </div>
      </div>
    </div>
  </div>
  <div class="sidebar bg-white">
    <div class="h-full w-full overflow-scroll">
      <pr-appointment-sidebar
        class="!top-auto"
        [patient]="patient$ | async"
        [interactions]="interactions$ | async"
        (interactionsChange)="updateInteractions($event)"
      />
    </div>
  </div>
</div>
