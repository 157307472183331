export enum ResourceType {
  Appointment = 'appointment',
  AppointmentRequest = 'appointment-request',
  Automation = 'automation',
  Meeting = 'meeting',
  Break = 'break',
  Leave = 'leave',
  Gap = 'gap',
  Lab = 'lab',
  LabJob = 'lab-job',
  FollowUp = 'follow-up',
  Patient = 'patient',
  Payment = 'payment',
  Task = 'task',
  SchedulingAlert = 'scheduling-alert',
  Team = 'team',
  Sms = 'sms',
  Email = 'email',
  Call = 'call',
  Note = 'note',
  ReportLegacy = 'report-legacy',
  Report = 'report',
  ReportBuilder = 'report-builder',
  Staffer = 'staffer',
  Contact = 'contact',
  Practice = 'practice',
  Brand = 'brand',
  Calendar = 'calendar',
  Sterilisation = 'sterilisation',
  Conversations = 'conversations',
  Chats = 'chats',
  ChartingConfiguration = 'charting-configuration',
  ConditionConfiguration = 'condition-configuration',
  TreatmentConfiguration = 'treatment-configuration',
  MultiTreatmentConfiguration = 'multi-treatment-configuration',
  Tag = 'tag',
  Snippet = 'Snippet',
  MedicalHistory = 'medical-history',
  CustomForm = 'custom-form',
}

export interface IResourceDisplay {
  icon: string;
  cssClass?: string;
}

type IResourceDisplayMap = {
  [key in ResourceType]: IResourceDisplay;
};

export const RESOURCE_DISPLAY_MAP: IResourceDisplayMap = {
  [ResourceType.Appointment]: {
    icon: 'airline_seat_flat_angled',
    cssClass: 'appointment',
  },
  [ResourceType.AppointmentRequest]: {
    icon: 'edit_calendar',
  },
  [ResourceType.Automation]: {
    icon: 'smart_toy',
  },
  [ResourceType.Meeting]: {
    icon: 'work',
    cssClass: 'meeting',
  },
  [ResourceType.Break]: {
    icon: 'schedule',
    cssClass: 'break',
  },
  [ResourceType.Leave]: {
    icon: 'event_busy',
    cssClass: 'leave',
  },
  [ResourceType.Gap]: {
    icon: 'crop_free',
    cssClass: 'gap',
  },
  [ResourceType.Lab]: {
    icon: 'business',
  },
  [ResourceType.LabJob]: {
    icon: 'local_pharmacy',
    cssClass: 'lab-job',
  },
  [ResourceType.Patient]: {
    icon: 'person',
    cssClass: 'patient',
  },
  [ResourceType.Payment]: {
    icon: 'payment',
    cssClass: 'payment',
  },
  [ResourceType.Task]: {
    icon: 'check_box',
    cssClass: 'task',
  },
  [ResourceType.FollowUp]: {
    icon: 'hourglass_bottom',
  },
  [ResourceType.SchedulingAlert]: {
    icon: 'error_outline',
    cssClass: 'scheduling-alert',
  },
  [ResourceType.Sms]: {
    icon: 'message',
    cssClass: 'sms',
  },
  [ResourceType.Email]: {
    icon: 'email',
    cssClass: 'email',
  },
  [ResourceType.Call]: {
    icon: 'phone',
    cssClass: 'call',
  },
  [ResourceType.Note]: {
    icon: 'note_add',
  },
  [ResourceType.ReportLegacy]: {
    icon: 'show_chart',
  },
  [ResourceType.Report]: {
    icon: 'insert_chart',
  },
  [ResourceType.ReportBuilder]: {
    icon: 'query_stats',
  },
  [ResourceType.Staffer]: {
    icon: 'group',
  },
  [ResourceType.Contact]: {
    icon: 'contacts',
  },
  [ResourceType.Practice]: {
    icon: 'account_balance',
  },
  [ResourceType.Brand]: {
    icon: 'branding_watermark',
  },
  [ResourceType.Team]: {
    icon: 'groups',
  },
  [ResourceType.Calendar]: {
    icon: 'today',
  },
  [ResourceType.Sterilisation]: {
    icon: 'track_changes',
  },
  [ResourceType.Conversations]: {
    icon: 'question_answer',
  },
  [ResourceType.Chats]: {
    icon: 'chat',
  },
  [ResourceType.ConditionConfiguration]: {
    icon: 'healing',
  },
  [ResourceType.TreatmentConfiguration]: {
    icon: 'local_hospital',
  },
  [ResourceType.MultiTreatmentConfiguration]: {
    icon: 'local_hospital',
  },
  [ResourceType.ChartingConfiguration]: {
    icon: 'local_hospital',
  },
  [ResourceType.Tag]: {
    icon: 'sell',
  },
  [ResourceType.Snippet]: {
    icon: 'text_snippet',
  },
  [ResourceType.MedicalHistory]: {
    icon: 'medical_information',
  },
  [ResourceType.CustomForm]: {
    icon: 'list_alt',
  },
};
