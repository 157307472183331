@for (form of store.patientForms(); track form.uid) {
  <div class="flex flex-col gap-2 sm:flex-row sm:items-center sm:gap-4">
    <div class="flex grow flex-col gap-1">
      <div class="text-sm">
        {{ form.name }}
      </div>
      @if (form.description) {
        <div class="max-w-prose overflow-hidden text-xs opacity-50">
          {{ form.description }}
        </div>
      }
    </div>

    <div>
      @if (form | map: store.hasBeenSubmitted$ : store | async) {
        <div
          class="flex h-9 w-36 select-none flex-row items-center justify-center gap-1 rounded bg-slate-100 text-slate-400"
        >
          <mat-icon>check_circle</mat-icon>
          <div>Complete</div>
        </div>
      } @else {
        <button
          class="w-36"
          mat-flat-button
          color="primary"
          (click)="store.openPatientForm(form)"
        >
          Complete Form
        </button>
      }
    </div>
  </div>
} @empty {
  <pr-empty-state image="list" title="Forms" />
}
