import {
  DocumentReference,
  isDocRef,
  Timestamp,
  TypeGuard,
} from '@principle-theorem/shared';
import { ICandidateCalendarEvent } from '../event/calendar-event';

export interface IPatientConfirmGapOfferTokenData {
  gapOffer: DocumentReference<ICandidateCalendarEvent>;
}

export function isPatientConfirmGapOfferTokenData(
  data: unknown
): data is IPatientConfirmGapOfferTokenData {
  return TypeGuard.interface({
    gapOffer: isDocRef,
  })(data);
}

export interface IConfirmGapOfferRequest {
  tokenUid: string;
}

export interface IConfirmGapOfferData {
  practice: string;
  practiceAddress: string;
  practitioner: string;
  currentFrom?: Timestamp;
  currentTo?: Timestamp;
  offerFrom: Timestamp;
  offerTo: Timestamp;
  treatment: string;
  gapTaken: boolean;
}
