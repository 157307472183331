import { Subject } from 'rxjs';
import { isObject } from '../../common';
import {
  type ITransformer,
  TransformEvent,
} from '../../serialisation-provider';
import { isTimestamp } from '../timestamp';
import { isDocRef } from './document';

export class FirestoreSerialiser implements ITransformer {
  events = [TransformEvent.ToFirestore];

  guard(_: unknown): _ is unknown {
    return true;
  }

  transform<T extends object>(item: T): T {
    const data = { ...item };
    this._removeUndefined(data);
    this._removeFunctions(data);
    this._removeObservables(data);
    return this._serialiseChild(data) as T;
  }

  private _serialiseChild<T>(childItem: T): unknown {
    if (!isObject(childItem)) {
      return childItem;
    }

    if (isTimestamp(childItem) || isDocRef(childItem)) {
      return childItem;
    }

    if (Array.isArray(childItem)) {
      return this._serialiseChildren(childItem);
    }

    let child = { ...childItem };
    this._removeUndefined(child);
    this._removeFunctions(child);
    this._removeObservables(child);

    Object.keys(child).map((key: string) => {
      const value = child[key];
      child = {
        ...child,
        [key]: this._serialiseChild(value),
      };
    });

    return child;
  }

  private _serialiseChildren<T>(children: T[]): unknown[] {
    return children
      .filter((data) => data !== undefined)
      .map((data) => this._serialiseChild(data));
  }

  private _removeUndefined<T extends object>(data: T): T {
    Object.keys(data).forEach((key: string) => {
      const value = data[key as keyof T];
      if (value === undefined) {
        delete data[key as keyof T];
      }
    });
    return data;
  }

  private _removeFunctions<T extends object>(data: T): T {
    Object.keys(data).map((key: string) => {
      const value = data[key as keyof T] as unknown;
      if (value instanceof Function) {
        delete data[key as keyof T];
      }
    });
    return data;
  }

  private _removeObservables<T extends object>(data: T): T {
    Object.keys(data).map((key: string) => {
      const value = data[key as keyof T] as unknown;
      if (value instanceof Subject) {
        delete data[key as keyof T];
      }
    });
    return data;
  }
}
