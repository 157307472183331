import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartingOptionsComponent } from './charting-options/charting-options.component';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';

@NgModule({
  declarations: [ChartingOptionsComponent],
  imports: [CommonModule, NgPrincipleSharedModule, NgSharedModule],
  exports: [ChartingOptionsComponent],
})
export class ConditionChartingModule {}
