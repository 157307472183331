import { Node, mergeAttributes } from '@tiptap/core';
import { BlockNodes, NodeGroup } from '../../available-extensions';

export const COLUMN = Node.create({
  name: BlockNodes.Column,
  group: BlockNodes.Column,
  content: `(${BlockNodes.Paragraph}|${NodeGroup.Block})*`,
  isolating: true,
  selectable: false,

  renderHTML({ HTMLAttributes }) {
    const attrs = mergeAttributes(HTMLAttributes, { class: BlockNodes.Column });
    return ['div', attrs, 0];
  },

  addKeyboardShortcuts() {
    return [2, 3, 4].reduce(
      (items, columns) => ({
        ...items,
        ...{
          [`Mod-Ctrl-${columns}`]: () =>
            this.editor.commands.setColumns(columns),
        },
      }),
      {}
    );
  },
});
