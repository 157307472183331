import { type Timestamp } from '@principle-theorem/shared';

export interface IMigrationLogger {
  debug(message: string): unknown;
  info(message: string): unknown;
  warn(message: string): unknown;
  error(message: string): unknown;
}

export interface IMigrationMetadata {
  name: string;
  uid: string;
}

export interface IMigrationRunner {
  up(): Promise<void>;
  down(count: number): Promise<void>;
}

export interface IMigration<T = unknown> {
  up(
    dryRun: boolean,
    logger: IMigrationLogger,
    data?: IMigrationDataProvider<T>
  ): Promise<void>;
  down(
    dryRun: boolean,
    logger: IMigrationLogger,
    data?: IMigrationDataProvider<T>
  ): Promise<void>;
}

export interface IMigrationInstance {
  metadata: IMigrationMetadata;
  migration: IMigration;
}

export interface IMigrationSnapshot {
  migrations: IMigrationEntry[];
  lastMigrationRun?: Timestamp;
}

export interface IMigrationEntry {
  migration: IMigrationMetadata;
  action: MigrationAction;
  performedAt: Timestamp;
}

export interface IMigrationDataProvider<T = unknown> {
  get(): Promise<T>;
  update(data: T): Promise<void>;
}

export enum MigrationAction {
  Up = 'up',
  Down = 'down',
}

export interface IMigrationStorage {
  /**
   * Add a migration job to the log
   */
  add(migration: IMigrationEntry): Promise<void>;

  /**
   * Show migrations which have been applied discarding any which have been rolled back.
   */
  snapshot(): Promise<IMigrationSnapshot>;

  /**
   * Show all migrations that have been run, including ones which have been rolled back.
   */
  history(): Promise<IMigrationSnapshot>;
}
