import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomReport } from '@principle-theorem/principle-core';
import { ICustomReportColumn } from '@principle-theorem/principle-core/interfaces';
import { CanBeChartedProperty } from '@principle-theorem/reporting';
import { Subject } from 'rxjs';
import { IReportBuilderDataSource } from '../../../../models/report-builder-data-sources/report-builder-data-source';
import { ReportBuilderStore } from '../../report-builder.store';
import { ReportBuilderManageChartBloc } from '../report-builder-add-chart-dialog/report-builder-manage-chart-bloc';
import {
  ResolvedColumns,
  type IResolvedColumnProperty,
} from '../report-builder-column-selector-dialog/resolved-columns';

export interface IReportBuilderColumnsDialogRequest {
  dataSource: IReportBuilderDataSource;
  columns: ICustomReportColumn[];
}

export interface IReportBuilderColumnsDialogResponse {
  columns: ICustomReportColumn[];
}

@Component({
  selector: 'pr-report-builder-columns-dialog',
  templateUrl: './report-builder-columns-dialog.component.html',
  styleUrls: ['./report-builder-columns-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportBuilderColumnsDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  columns: IResolvedColumnProperty[] = [];
  bloc: ReportBuilderManageChartBloc;

  constructor(
    private _store: ReportBuilderStore,
    @Inject(DIALOG_DATA) private _data: IReportBuilderColumnsDialogRequest,
    private _dialogRef: MatDialogRef<
      ReportBuilderColumnsDialogComponent,
      IReportBuilderColumnsDialogResponse
    >
  ) {
    this.bloc = new ReportBuilderManageChartBloc(
      this._store,
      [],
      this._onDestroy$,
      {},
      []
    );
    this.columns = ResolvedColumns.toColumns(
      this._data.dataSource,
      this._data.columns
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
  }

  addColumn(column: CanBeChartedProperty): void {
    this.bloc.dataPointSearchCtrl.reset(undefined, { emitEvent: false });

    const newColumn = CustomReport.column(column.metadata.id);
    const resolvedColumn = ResolvedColumns.resolveColumn(
      this._data.dataSource,
      newColumn
    );
    if (!resolvedColumn) {
      return;
    }
    this.columns = [...this.columns, resolvedColumn];
  }

  removeColumn(column: IResolvedColumnProperty): void {
    this.columns = this.columns.filter(
      (selectedColumn) =>
        selectedColumn.definition.uid !== column.definition.uid
    );
  }

  submit(): void {
    this._dialogRef.close({
      columns: this.columns.map((column) => column.definition),
    });
  }
}
