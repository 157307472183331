import { NgModule } from '@angular/core';
import { IDixelFeatureService } from './idixel-feature.service';
import { IDixelOpenPatientComponent } from './idixel-open-patient/idixel-open-patient.component';
import { NgSharedModule } from '@principle-theorem/ng-shared';

@NgModule({
  declarations: [IDixelOpenPatientComponent],
  imports: [NgSharedModule],
  providers: [IDixelFeatureService],
})
export class IDixelModule {}
