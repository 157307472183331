import { type IXSLXImport } from '@principle-theorem/shared';
import { type Row } from 'exceljs';
import { PRACTICE_HEADERS, type IPracticeToXSLX } from './practice-to-xlsx';

export class XSLXToPractice implements IXSLXImport<IPracticeToXSLX> {
  headers = PRACTICE_HEADERS;

  translate(row: Row): IPracticeToXSLX {
    return {
      id: row.getCell('id').value?.toString() ?? '',
      label: row.getCell('label').value?.toString() ?? '',
      mapTo: row.getCell('mapTo').value?.toString() as IPracticeToXSLX['mapTo'],
    };
  }
}
