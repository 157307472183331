import {
  TypeGuard,
  isINamedDocument,
  isObject,
  type INamedDocument,
  type ISoftDelete,
  type Timestamp,
} from '@principle-theorem/shared';
import { isBoolean, isString } from 'lodash';
import { type IStatusHistory } from '../common';
import { PatientFormType } from '../patient/patient';
import {
  ICustomFormConfiguration,
  IJsonSchemaFormWithResolverConfig,
} from './custom-form-config';

export enum PatientFormCollection {
  SubmittedFormHistory = 'submittedFormHistory',
  PatientFormInteractions = 'patientFormInteractions',
}

export enum PatientFormStatus {
  Issued = 'issued',
  Submitted = 'submitted',
  Confirmed = 'confirmed',
}

export interface IPatientForm<T extends object = object> extends ISoftDelete {
  status: PatientFormStatus;
  statusHistory: IStatusHistory<PatientFormStatus>[];
  formType: PatientFormType;
  form: IPatientFormSchema<T>;
  template?: INamedDocument<ICustomFormConfiguration>;
}

export function isPatientForm<T extends object = object>(
  form: unknown
): form is IPatientForm<T> {
  return TypeGuard.interface<IPatientForm>({
    deleted: isBoolean,
    status: TypeGuard.enumValue(PatientFormStatus),
    statusHistory: TypeGuard.arrayOf(isObject),
    formType: isString,
    template: TypeGuard.undefinedOr(isINamedDocument),
    form: isObject,
  })(form);
}

export interface IPatientFormSchema<T extends object = object>
  extends IJsonSchemaFormWithResolverConfig {
  date?: Timestamp;
  data?: T;
}
