import {
  ChartedItem,
  ChartedSurface,
  isWithinSurfaces,
} from '@principle-theorem/principle-core';
import {
  type ChartableSurface,
  type ChartItemDisplayType,
  type IChartedItem,
  type IChartedRef,
  type IChartedSurface,
  type IStaffer,
  type IToothRef,
} from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  isSameRef,
  type WithRef,
} from '@principle-theorem/shared';
import { uniq, uniqBy, values } from 'lodash';
import { getChartItemDisplayType } from './chart-item-filter';

export type ChartedSurfacePredicate = (surface: IChartedSurface) => boolean;

export class ChartedItemsCollection<T extends IChartedItem = IChartedItem> {
  constructor(private _items: T[] = []) {}

  static filterByToothPredicate(tooth: IToothRef): ChartedSurfacePredicate {
    return (surface: IChartedSurface) =>
      ChartedSurface.isOnTooth(surface, tooth);
  }

  static filterBySurfacePredicate(
    chartableSurface?: ChartableSurface
  ): ChartedSurfacePredicate {
    return (surface: IChartedSurface) =>
      ChartedSurface.isOnSurface(surface, chartableSurface);
  }

  filterByContext(
    filters: ChartItemDisplayType[],
    chartingAs?: WithRef<IStaffer>
  ): ChartedItemsCollection {
    return this.filterByTypes(filters, chartingAs);
  }

  filterByType(
    type: ChartItemDisplayType,
    chartingAs?: WithRef<IStaffer>
  ): ChartedItemsCollection<T> {
    return this._toCollection(
      this._items.filter(
        (item: T) => getChartItemDisplayType(item, chartingAs) === type
      )
    );
  }

  filterByTypes(
    types: ChartItemDisplayType[],
    chartingAs?: WithRef<IStaffer>
  ): ChartedItemsCollection<T> {
    const collectionsByType: ChartedItemsCollection<T>[] = types.map(
      (type: ChartItemDisplayType) => this.filterByType(type, chartingAs)
    );

    const items: T[] = collectionsByType.reduce(
      (acc: T[], typeCollection: ChartedItemsCollection<T>) => [
        ...acc,
        ...typeCollection.toArray(),
      ],
      []
    );

    return this._toCollection(items);
  }

  filterBySelected(
    selectedSurfaces: Partial<IChartedRef>[]
  ): ChartedItemsCollection<T> {
    return this._toCollection(
      this._items.filter((item: T) =>
        item.chartedSurfaces.some((surface: IChartedSurface) =>
          ChartedSurface.isOnASelectedSurface(surface, selectedSurfaces)
        )
      )
    );
  }

  filterByTreatmentConfig(config: INamedDocument): ChartedItemsCollection<T> {
    return this._toCollection(
      this._items.filter((item: T) => isSameRef(item.config, config))
    );
  }

  filterByResolved(resolved: boolean = false): ChartedItemsCollection<T> {
    return this._toCollection(
      this._items.filter((item: T) => ChartedItem.isResolved(item) === resolved)
    );
  }

  filterByAllSurfacesPredicates(
    ...predicates: ChartedSurfacePredicate[]
  ): ChartedItemsCollection<T> {
    return this._toCollection(
      this._items.filter((item: T) =>
        item.chartedSurfaces.some((surface: IChartedSurface) =>
          predicates.every((predicate: ChartedSurfacePredicate) =>
            predicate(surface)
          )
        )
      )
    );
  }

  filterByTooth(tooth: IToothRef): ChartedItemsCollection<T> {
    return this._toCollection(
      this._items.filter((item: T) =>
        item.chartedSurfaces.some((surface: IChartedSurface) =>
          ChartedSurface.isOnTooth(surface, tooth)
        )
      )
    );
  }

  filterBySurface(surface?: ChartableSurface): ChartedItemsCollection<T> {
    return this._toCollection(
      this._items.filter((item: T) =>
        item.chartedSurfaces.some((chartedSurface: IChartedSurface) =>
          ChartedSurface.isOnSurface(chartedSurface, surface)
        )
      )
    );
  }

  filterByMultipleTeeth(teeth: IToothRef[]): ChartedItemsCollection<T> {
    return this._toCollection(
      this._items.filter((item: T) =>
        teeth.some((tooth: IToothRef) =>
          item.chartedSurfaces.some((surface: IChartedSurface) =>
            ChartedSurface.isOneOfTeeth(surface, tooth)
          )
        )
      )
    );
  }

  filterByChartedRef(
    chartedRef: Partial<IChartedRef>
  ): ChartedItemsCollection<T> {
    return this._toCollection(
      this._items.filter((item: T) => {
        const chartedRefs = item.chartedSurfaces.map(
          (surface: IChartedSurface) => surface.chartedRef
        );
        return isWithinSurfaces(chartedRef, chartedRefs);
      })
    );
  }

  filterByUuids(uuids: string[]): ChartedItemsCollection<T> {
    return this._toCollection(
      this._items.filter((item: T) => uuids.includes(item.uuid))
    );
  }

  toUniqueTreatments(): string[] {
    const refPaths: string[] = this._items.map(
      (item: T) => item.config.ref.path
    );
    return uniq(refPaths);
  }

  deduplicate(): ChartedItemsCollection<T> {
    return this._toCollection(uniqBy(this._items, 'uuid'));
  }

  groupByTreatmentConfig(): T[][] {
    const byConfig: Record<string, T[]> = {};
    this._items.map((item: T) => {
      let hashGroup: T[] = [];
      if (byConfig[item.config.ref.path]) {
        hashGroup = byConfig[item.config.ref.path];
      }
      hashGroup.push(item);
      byConfig[item.config.ref.path] = hashGroup;
    });
    return values(byConfig);
  }

  findByUuid(uuid: string): T | undefined {
    return this._items.find((item: T) => item.uuid === uuid);
  }

  toArray(): T[] {
    return this._items;
  }

  private _toCollection(items: T[]): ChartedItemsCollection<T> {
    return new ChartedItemsCollection<T>(items);
  }
}
