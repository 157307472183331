<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="32px" class="wrapper">
  <h1 class="mat-headline-4">Referrals</h1>

  <pt-buttons-container>
    <button mat-flat-button color="primary" (click)="upsertReferralSource()">
      Create Referral Source
    </button>
  </pt-buttons-container>

  <ng-container *ngIf="activeReferralSources$ | async as referralSources">
    <ng-container *ngIf="referralSources.length; else noReferralSources">
      <mat-list>
        <mat-list-item
          *ngFor="
            let referralSource of referralSources;
            trackBy: trackByReferralSource
          "
        >
          <div
            fxFlex
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="16px"
          >
            <div fxFlex>{{ referralSource.name }}</div>

            <div>
              <button
                mat-icon-button
                (click)="upsertReferralSource(referralSource)"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                mat-icon-button
                color="warn"
                (click)="delete(referralSource)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </ng-container>
  </ng-container>

  <ng-template #noReferralSources>
    <pr-empty-state image="list" title="referral sources" />
  </ng-template>

  <ng-container
    *ngIf="deletedReferralSources$ | async as deletedReferralSources"
  >
    <mat-list *ngIf="deletedReferralSources.length">
      <h3
        mat-subheader
        class="collapsible-subheader"
        (click)="deletedContent.toggle()"
      >
        Deleted Referral Sources
        <mat-icon>
          {{ deletedContent.expanded ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </h3>
      <pt-collapsible #deletedContent>
        <ng-template ptCollapsibleContent>
          <mat-list-item
            *ngFor="
              let type of deletedReferralSources;
              trackBy: trackByReferralSource
            "
          >
            <div
              fxFlex
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="16px"
            >
              <div fxFlex>{{ type.name }}</div>

              <div matTooltip="Restore">
                <button mat-icon-button (click)="restore(type)">
                  <mat-icon>restore</mat-icon>
                </button>
              </div>
            </div>
          </mat-list-item>
        </ng-template>
      </pt-collapsible>
    </mat-list>
  </ng-container>
</div>
