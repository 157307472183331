import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { LabJob } from '@principle-theorem/principle-core';
import {
  type ILabJob,
  type LabJobStatus,
  LAB_JOB_STATUSES,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LabJobManager } from '../../lab-job-manager';

@Component({
    selector: 'pr-lab-job-status-workflow',
    templateUrl: './lab-job-status-workflow.component.html',
    styleUrls: ['./lab-job-status-workflow.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LabJobStatusWorkflowComponent {
  labJob$ = new ReplaySubject<WithRef<ILabJob>>(1);
  statuses = LAB_JOB_STATUSES;
  trackByStatus = TrackByFunctions.variable<LabJobStatus>();
  currentStatus$ = this.labJob$.pipe(map((labJob) => labJob.status));
  nextStatus$: Observable<LabJobStatus | undefined>;
  @Input() color: ThemePalette;
  @Input() type: 'stroked' | 'flat' = 'stroked';

  @Input()
  set labJob(labJob: WithRef<ILabJob>) {
    if (labJob) {
      this.labJob$.next(labJob);
    }
  }

  constructor(
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService
  ) {
    this.nextStatus$ = this.currentStatus$.pipe(
      map((currentStatus) => {
        const currentIndex = this.statuses.indexOf(currentStatus);
        return this.statuses[currentIndex + 1];
      })
    );
  }

  isCurrentStatus$(status: LabJobStatus): Observable<boolean> {
    return this.currentStatus$.pipe(
      map((currentStatus) => currentStatus === status)
    );
  }

  async updateStatus(status?: LabJobStatus): Promise<void> {
    if (!status) {
      return;
    }

    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const labJob = await snapshot(this.labJob$);
    const practice = await snapshot(LabJob.practice$(labJob));
    const labJobManager = new LabJobManager(practice, staffer);
    await labJobManager.updateStatus(labJob, status);
    this._snackBar.open('Status updated');
  }
}
