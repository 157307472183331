import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthFirebaseFunctionsService } from '@principle-theorem/ng-auth';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  BasicDialogService,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  Brand,
  EventableQueries,
  User,
  updateStafferNames,
} from '@principle-theorem/principle-core';
import {
  IAppointment,
  IBrand,
  IPractice,
  IRole,
  IUser,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  find,
  multiSwitchMap,
  patchDoc,
  reduce2DArray,
  snapshot,
  toTimePeriod,
  where,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { UserInfo } from 'firebase/auth';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  type Observable,
  of,
} from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { type IBrandPracticeAccess } from '../access-selector/access-selector.component';
import moment from 'moment-timezone';

const WRITE_REQUEST_TIMEOUT = 2000;

type UserAccessChangesFormData = Pick<
  IUser,
  'name' | 'email' | 'roles' | 'brands' | 'practices' | 'isEnabled'
>;

@Component({
  selector: 'pr-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageUserComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  saving$ = new BehaviorSubject<boolean>(false);
  user$ = new ReplaySubject<WithRef<IUser>>(1);
  isLoggedInUser$ = new ReplaySubject<boolean>(1);
  form = new TypedFormGroup<UserAccessChangesFormData>({
    name: new TypedFormControl<string>('', Validators.required),
    email: new TypedFormControl<string>('', [
      Validators.required,
      Validators.email,
    ]),
    roles: new TypedFormControl<DocumentReference<IRole>[]>([]),
    brands: new TypedFormControl<DocumentReference<IBrand>[]>([]),
    practices: new TypedFormControl<DocumentReference<IPractice>[]>([]),
    isEnabled: new TypedFormControl<boolean>(),
  });
  brands$ = new ReplaySubject<WithRef<IBrand>[]>(1);
  roles$ = new ReplaySubject<WithRef<IRole>[]>(1);
  disabled$ = new BehaviorSubject<boolean>(false);
  @Output() inviteUser = new EventEmitter<WithRef<IUser>>();

  constructor(
    private _snackBar: MatSnackBar,
    private _functions: AuthFirebaseFunctionsService,
    private _basicDialog: BasicDialogService,
    public organisation: OrganisationService
  ) {
    this.user$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((user) => this.form.patchValue(user));

    this.form.controls.isEnabled.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value) => {
        if (!value) {
          this.form.patchValue(
            {
              practices: [],
              brands: [],
              roles: [],
            },
            { emitEvent: false }
          );
        }
        this.disabled$.next(!value);
      });
  }

  @Input()
  set isLoggedInUser(isLoggedInUser: boolean) {
    this.isLoggedInUser$.next(isLoggedInUser);
  }

  @Input()
  set user(user: WithRef<IUser>) {
    if (user) {
      this.user$.next(user);
    }
  }

  @Input()
  set brands(brands: WithRef<IBrand>[]) {
    if (brands) {
      this.brands$.next(brands);
    }
  }

  @Input()
  set roles(roles: WithRef<IRole>[]) {
    if (roles) {
      this.roles$.next(roles);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  toFirebaseUser(user: WithRef<IUser>): UserInfo {
    return User.toFirebaseUser(user);
  }

  canInvite(user: WithRef<IUser>): boolean {
    return user.firstSignedInAt ? false : true;
  }

  updateRoles(roles: DocumentReference<IRole>[]): void {
    this.form.patchValue({ roles });
  }

  updateAccess({ brands, practices }: IBrandPracticeAccess): void {
    this.form.patchValue({
      brands,
      practices,
    });
  }

  async save(): Promise<void> {
    this.saving$.next(true);
    if (!this.form.valid) {
      return;
    }

    const currentUser = await snapshot(this.user$);
    const isDisabled = await snapshot(this.disabled$);

    if (isDisabled) {
      const userAppointments = await snapshot(
        this._userAppointments$(currentUser)
      );

      if (userAppointments.length) {
        const confirmed = await this._basicDialog.confirm({
          title: 'User Has Scheduled Appointments',
          prompt: `${currentUser.name} has ${userAppointments.length} future appointment(s) scheduled. Disabling this user will prevent these from displaying on the Timeline. Proceed?`,
          toolbarColor: 'warn',
          submitColor: 'warn',
        });
        if (!confirmed) {
          this.form.patchValue(currentUser);
          this.saving$.next(false);
          return;
        }
      }
    }

    const userChanges = this.form.getRawValue();
    userChanges.name = userChanges.name.trim();
    const emailChanged = userChanges.email !== currentUser.email;

    if (emailChanged) {
      const emailInUse = await snapshot(this._emailInUse$(userChanges.email));
      if (emailInUse) {
        this.saving$.next(false);
        this._snackBar.open(
          'Sorry that email is already in use! Please use a different email.'
        );
        return;
      }
    }

    const user = await snapshot(this.user$);

    await patchDoc(user.ref, {
      ...userChanges,
      email: userChanges.email.toLowerCase(),
    });

    await updateStafferNames({
      ...user,
      ...userChanges,
    });

    this._snackBar.open('User Roles & Access Updated');

    if (emailChanged) {
      const existingFirebaseUser = await this._functions.updateUserEmail(
        currentUser.email,
        userChanges.email
      );

      if (!existingFirebaseUser) {
        const organisation = await snapshot(
          this.organisation.organisation$.pipe(filterUndefined())
        );
        await this._functions.sendOrganisationInviteEmail(organisation, {
          email: userChanges.email,
        });
        setTimeout(() => {
          this._snackBar.open(
            `Email address must be verified. Please check ${userChanges.email} for activation link.`
          );
        }, WRITE_REQUEST_TIMEOUT);
      }
    }
    this.saving$.next(false);
  }

  private _userAppointments$(
    user: WithRef<IUser>
  ): Observable<WithRef<IAppointment>[]> {
    const range = toTimePeriod(moment(), moment().add(18, 'months'));

    return this.brands$.pipe(
      multiSwitchMap((brand) =>
        Brand.getBrandStafferFromUser(brand, user).pipe(
          switchMap((staffer) =>
            staffer
              ? EventableQueries.appointments$(brand, range, [staffer.ref])
              : of([])
          )
        )
      ),
      reduce2DArray()
    );
  }

  private _emailInUse$(email: string): Observable<boolean> {
    return this.organisation.userCol$.pipe(
      filterUndefined(),
      find(where('email', '==', email.toLowerCase())),
      map((user: WithRef<IUser> | undefined) => (user ? true : false))
    );
  }
}
