<ng-container *ngIf="automations$ | async as automations">
  <div
    *ngIf="automations.length; else noAutomations"
    class="flex flex-col gap-2"
  >
    <div
      *ngFor="let automation of automations; trackBy: trackByAutomation"
      class="flex flex-row items-center gap-2"
    >
      <div class="flex min-w-0 grow flex-col">
        <pr-content-text
          class="min-w-0 truncate"
          [content]="automation | map: getTitle"
          [ptTruncateTooltip]="automation | map: getTitle"
        />
        <div class="text-xs opacity-50" *ngIf="automation.triggerDate">
          <ng-container
            *ngIf="
              automation.triggerDate
                | momentTimezone$: automation.practiceRef
                | ngrxPush as triggerDate
            "
          >
            {{ triggerDate | moment | amDateFormat: dateFormat }}
          </ng-container>
        </div>
      </div>

      <pr-automation-status
        class="w-16 text-right"
        [status]="automation.status"
      />
    </div>
  </div>
</ng-container>

<ng-template #noAutomations>
  <div class="text-xs opacity-50">
    There are no automations for this appointment
  </div>
</ng-template>
