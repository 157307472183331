<form *ngIf="form" [formGroup]="form">
  <div class="grid grid-cols-3 gap-2" formGroupName="recurrenceTiming">
    <div>
      <mat-form-field class="w-full">
        <mat-label>Amount</mat-label>
        <input matInput formControlName="amount" type="number" min="1"/>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="w-full">
        <mat-label>Unit</mat-label>
        <mat-select formControlName="unit">
          <mat-option *ngFor="let unit of timingUnits; trackBy: trackByTimingUnit" [value]="unit">
            {{ unit }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <pr-date-selector
        formControlName="time"
        class="w-full"
        placeholder="Due Time"
        [interval]="timeInterval"
        [timeFrom]="openTime$ | async"
        [timeTo]="closeTime$ | async"
       />
    </div>
  </div>
</form>

<span *ngIf="form.summary$ | async as summary" class="summary">
  {{ summary }}
</span>
