export enum TreatmentConfigurationName {
  GeneralADACode = 'General ADA Code',
  Consultation = 'Consultation',
  // EmergencyConsultation = 'Emergency Consultation',
  PeriodicExam = 'Periodic Exam',
  PeriodicExamAndClean = 'Periodic Exam & Clean',
  PeriodicExamCleanAndXrays = 'Periodic Exam, Clean & X-rays',
  ScaleAndClean = 'Scale & Clean',

  // Extraction = 'Extraction (Standard/Surgical)',

  // RestorativeComposite = 'Restoration - Composite',
  // RestorativeMetallic = 'Restoration - Metallic',
  // RestorativeIndirect = 'Restoration - Indirect',
  // Cerec = 'Cerec',

  // CBCTPartialAcquisitionAndInterpretation = 'CBCT Partial Acquisition & Interpretation',
  CBCTSingleArchAcquisitionAndInterpretation = 'CBCT - Single Arch - Acquisition & Interpretation',
  CBCTBothArchesAcquisitionAndInterpretation = 'CBCT - Both Arches - Acquisition & Interpretation',

  TeethWhiteningImpressions = 'Teeth Whitening Impressions',
  TeethWhiteningIssue = 'Teeth Whitening Issue',

  InvisalignConsultation = 'Invisalign Consultation',
  InvisalignWorkUp = 'Invisalign Work Up',
  InvisalignClincheckReview = 'Invisalign Clincheck Review',
  InvisalignMidTreatmentReview = 'Invisalign Mid Treatment Review',
  InvisalignIssuingFirstAligners = 'Invisalign Issuing First Aligners',
  InvisalignBondingOfAttachments = 'Invisalign Bonding of Attachments',
  InvisalignEndOfTreatment = 'Invisalign End of Treatment',
  InvisalignIssueRetainers = 'Invisalign Issue Retainers',

  RootCanalEmergencyExtirpation = 'Root Canal - Emergency Extirpation',
  RootCanalChemomechanicalPreparation = 'Root Canal - Chemomechanical Preparation',
  RootCanalObturation = 'Root Canal - Obturation',

  VeneerPreparationAndImpression = 'Veneer Preparation & Impression',
  // VeneerBonding = 'Veneer Bonding',

  // ImplantPlanning = 'Implants Planning',
  // ImplantSurgery = 'Implants Surgery',
  // ImplantSurgery2ndStage = 'Implant Surgery - 2nd Stage (Exposure, Placement of Healing Abutment)',
  // ImplantReview = 'Implant 10 day review',
  ImplantImpression = 'Implant Impression',
  // ImplantCrownInsertion = 'Implant Restoration',

  // SplintImpression = 'Splint Impression',
  SplintIssue = 'Splint Issue',

  // CrownBridgeIssuing = 'Crown & Bridge Issuing',
  // CrownBridgeImpressionPreparation = 'Crown & Bridge Preparation & Impression',

  // InternalBleaching = 'Internal Bleaching',
  InternalBleachingRestoration = 'Internal Bleaching Restoration',

  TemporaryCrown = 'Temporary Crown',

  // CrownImpressionPreparation = 'Crown Preparation & Impression',
  // CrownIssuingMetallic = 'Crown Issuing - Metallic',
  // CrownIssuingNonMetallic = 'Crown Issuing - Non Metallic',
  // CrownIssuingVeneer = 'Crown Issuing - Veneer',

  // PostCoreImpressionPreparation = 'Post & Core Preparation & Impression',
  // PostCorePlacement = 'Post & Core Placement',

  // // TODO
  // PeriodontalFullMouth = 'Periodontal - Full Mouth',
  // PeriodontalHalfMouth = 'Periodontal - Half Mouth',
  // PeriodontalPerioMaintenance = 'Periodontal - Perio Maintenance',
  // PeriodontalQuadrant = 'Periodontal - Quadrant',
  // Botox = 'Botox',
  // DigitalScanning = 'Digital Scanning',
  // DiagnosticWaxUpMockUp = 'Diagnostic Wax Up/Mock Up',
  // Fillers = 'Dermal Fillers',
  // MandibularAdvancementSplintWorkUp = 'Mandibular Advancement Splint Work Up',
  MandibularAdvancementSplintIssue = 'Mandibular Advancement Splint Issue',
  NewPatientComprehensiveExamination = 'New Patient Comprehensive Examination',
  // PaediatricDentistry = 'Paediatric Dentistry',
  PhotographsDSLR = 'Photographs DSLR',

  // New
  ImplantReviewAfterFixturePlacement = 'Implant Review - After Fixture Placement',
  OdontoplastyRecontouringToothStructure = 'Odontoplasty / Recontouring Tooth Structure',
  LimitedEmergencyExamination = 'Limited / Emergency Examination',
  Gingivectomy = 'Gingivectomy',
  GuidedTissueRegenerationMembranePlacement = 'Guided Tissue Regeneration - Membrane Placement',
  RootCanalReTreatmentRemovalOfRootFilling = 'Root Canal - Re-treatment / Removal of Root Filling',
  MouthguardIssue = 'Mouthguard Issue',
  AmalgamFillingDirectMetallicRestoration = 'Amalgam Filling - Direct Metallic Restoration',
  Apicectomy = 'Apicectomy',
  FixedOrthoBondedWireRetainers = 'Fixed Ortho Bonded Wire Retainers',
  ImplantRestoration = 'Implant Restoration',
  PeriodontalMaintenanceSupportiveTreatment = 'Periodontal - Maintenance / Supportive Treatment',
  InlayOnlayPreparationAndImpression = 'Inlay / Onlay - Preparation and Impression',
  ExtractionSurgical = 'Extraction - Surgical',
  CrownLengtheningPeriodontalSurgery = 'Crown Lengthening - Periodontal Surgery',
  DesensitisingTreatment = 'Desensitising Treatment',
  GuidedTissueRegenerationMembraneRemoval = 'Guided Tissue Regeneration - Membrane Removal',
  BridgePreparationAndImpression = 'Bridge Preparation & Impression',
  TemporaryFilling = 'Temporary Filling',
  SplintAdjustmentAdditionRepair = 'Splint Adjustment / Addition / Repair',
  BridgeIssuePontics = 'Bridge Issue - Pontic(s)',
  ImplantSurgeryFixturePlacement = 'Implant Surgery - Fixture Placement',
  BoneGraftParticulate = 'Bone Graft - Particulate',
  CompleteDenture = 'Complete Denture',
  CrownIssueBondingCeramicNonMetallic = 'Crown Issue / Bonding - Ceramic / Non-metallic',
  MouthguardImpressionsScan = 'Mouthguard Impressions / Scan',
  VeneerIssueBonding = 'Veneer Issue / Bonding',
  MetalBand = 'Metal Band',
  Frenectomy = 'Frenectomy',
  FissureSealant = 'Fissure Sealant',
  CrownPreparationAndImpression = 'Crown Preparation & Impression',
  CosmeticOrRestorativeWorkUp = 'Cosmetic / Restorative Work Up',
  DigitalIntraoralScan = 'Digital Intraoral Scan',
  CBCTPartialSegmentAcquisitionAndInterpretation = 'CBCT - Partial Segment - Acquisition & Interpretation',
  RemineralisingTreatment = 'Remineralising Treatment',
  ImplantPlanningWorkUp = 'Implant Planning / Work Up',
  PeriodontalActiveTreatmentPerQuadrant = 'Periodontal - Active Treatment Per Quadrant',
  ImplantSurgerySecondStageHealingAbutmentPlacement = 'Implant Surgery - Second Stage / Healing Abutment Placement',
  MinorOcclusalAdjustment = 'Minor Occlusal Adjustment',
  NewPatientComprehensiveExamAndClean = 'New Patient Comprehensive Exam & Clean',
  SplintImpressionScan = 'Splint Impression / Scan',
  DiagnosticWaxUpMockUpTryIn = 'Diagnostic Wax Up / Mock Up Try-in',
  InlayOnlayIssueBondingofMetallicIndirectRestoration = 'Inlay / Onlay - Issue / Bonding of Metallic Indirect Restoration',
  BotoxFacialInjectables = 'Botox / Facial Injectables',
  CompositeVeneer = 'Composite Veneer',
  ExtractionSectional = 'Extraction - Sectional',
  CrownIssueBondingMetallic = 'Crown Issue / Bonding - Metallic',
  MandibularAdvancementSplintReviewAdjustmentRepair = 'Mandibular Advancement Splint Review/Adjustment/Repair',
  EnamelMicroabrasion = 'Enamel Microabrasion',
  CerecInlayOnlayChairsideIndirectRestoration = 'Cerec - Inlay/Onlay - Chairside Indirect Restoration',
  DentalTreatment = 'Dental Treatment',
  DermalFillers = 'Dermal Fillers',
  NitrousOxideInhalationSedation = 'Nitrous Oxide - Inhalation Sedation',
  PostAndCorePlacement = 'Post & Core Placement',
  CompositeFillingDirectAdhesiveRestoration = 'Composite Filling - Direct Adhesive Restoration',
  IVSedation = 'IV sedation',
  CrownIssueBondingVeneeredPFM = 'Crown Issue / Bonding - Veneered / PFM',
  PrescriptionProvisionOfMedication = 'Prescription / Provision of Medication',
  InternalBleachingPlacement = 'Internal Bleaching - Placement',
  BridgeIssueAbutmentsOrRetainers = 'Bridge Issue - Abutment(s) or Retainer(s)',
  ReviewOfRecentTreatment = 'Review of Recent Treatment',
  SplintingAndStabilisation = 'Splinting and Stabilisation',
  DrainingOfAbscess = 'Draining of Abscess',
  AcutePeriodontalInfectionTreatment = 'Acute Periodontal Infection Treatment',
  RecontourPolishRestoration = 'Recontour / Polish Restoration',
  Pulpotomy = 'Pulpotomy',
  BoneGraftBlock = 'Bone Graft - Block',
  CerecFullCrown = 'Cerec - Full Crown',
  OralHygieneInstructions = 'Oral Hygiene Instructions',
  PeriodontalSurgery1Tooth = 'Periodontal Surgery - 1 Tooth',
  ExtractionStandard = 'Extraction - Standard',
  ExtractionStandardSectionalSurgical = 'Extraction - Standard / Sectional / Surgical',
  PostAndCorePreparationAndImpression = 'Post & Core Preparation & Impression',
  MandibularAdvancementSplintImpressionsScan = 'Mandibular Advancement Splint Impressions / Scan',
  GingivalSoftTissueGraft = 'Gingival / Soft Tissue Graft',
  InlayOnlayIssueBondingOfToothColouredIndirectRestoration = 'Inlay / Onlay - Issue / Bonding of Tooth-coloured Indirect Restoration',
}
