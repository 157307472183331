import {
  Invoice,
  TransactionOperators,
} from '@principle-theorem/principle-core';
import {
  IHealthcareClaim,
  IInvoice,
  ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  getDoc,
  getParentDocRef,
  isArray,
  sortByCreatedAt,
  WithRef,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ITransactionActionsData<T> {
  latestTransaction: WithRef<ITransaction<T>>;
  transaction: WithRef<ITransaction<T>>;
  invoice: WithRef<IInvoice>;
  claim?: IHealthcareClaim;
}

export interface ITransactionAction<T> {
  icon: string;
  label: string;
  tooltip?: string;
  inProgress$: Observable<boolean>;
  excludeFromReport?: boolean;
  typeGuardFn(data: T): boolean;
  canDo$(data: ITransactionActionsData<T>): Observable<boolean>;
  label$?(data: ITransactionActionsData<T>): Observable<string>;
  info$?(
    data: ITransactionActionsData<T>
  ): Observable<string[] | string | undefined>;
  do(data: ITransactionActionsData<T>): Promise<void>;
}

export async function getTransactionActionData<T>(
  transaction: WithRef<ITransaction<T>>
): Promise<ITransactionActionsData<T>> {
  const invoiceRef = getParentDocRef<IInvoice>(transaction.ref);
  const invoice = await getDoc(invoiceRef);

  const allTransactions = await Invoice.getTransactions(invoice);
  const relatedTransactions = new TransactionOperators(allTransactions)
    .byReference(transaction.reference)
    .sort(sortByCreatedAt)
    .result();
  const latestTransaction = (first(relatedTransactions) ??
    transaction) as WithRef<ITransaction<T>>;

  const claim = Invoice.findHealthcareClaimForTransaction(invoice, transaction);

  return {
    latestTransaction,
    transaction,
    invoice,
    claim,
  };
}

export class TransactionActionHelpers {
  static getInfo$<T>(
    action: ITransactionAction<T>,
    data: ITransactionActionsData<T>
  ): Observable<string[]> {
    const info$ = action.info$ ? action.info$(data) : of(undefined);
    return info$.pipe(
      map((info) => info ?? []),
      map((info) => (isArray(info) ? info : [info]))
    );
  }
}
