import {
  ITranslationMap,
  InteractionType,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  IXSLXExport,
  XSLXCell,
  getEnumValues,
} from '@principle-theorem/shared';
import { Column } from 'exceljs';
import { IBaseMigrationItemCode } from '../interfaces';

export enum ItemCodeNoteType {
  Omit = 'Omit',
  ClinicalNote = 'Clinical Note',
  GeneralPatientNote = 'General Patient Note',
  PatientCallNote = 'Patient Call Note',
  PatientEmailNote = 'Patient Email Note',
  PatientLabJobNote = 'Patient Lab Job Note',
  PatientPaymentNote = 'Patient Payment Note',
  PatientSMSNote = 'Patient SMS Note',
  PatientSocialNote = 'Patient Social Note',
  SterilisationRecord = 'Sterilisation Record',
}

export const ITEM_CODE_NOTE_TO_INTERACTION_MAP: Record<
  ItemCodeNoteType,
  InteractionType | undefined
> = {
  [ItemCodeNoteType.Omit]: undefined,
  [ItemCodeNoteType.ClinicalNote]: InteractionType.ClinicalNote,
  [ItemCodeNoteType.GeneralPatientNote]: InteractionType.Note,
  [ItemCodeNoteType.PatientCallNote]: InteractionType.Call,
  [ItemCodeNoteType.PatientEmailNote]: InteractionType.Email,
  [ItemCodeNoteType.PatientLabJobNote]: InteractionType.LabJob,
  [ItemCodeNoteType.PatientPaymentNote]: InteractionType.Payment,
  [ItemCodeNoteType.PatientSMSNote]: InteractionType.Sms,
  [ItemCodeNoteType.PatientSocialNote]: InteractionType.Note,
  [ItemCodeNoteType.SterilisationRecord]: InteractionType.Note,
};

export const ITEM_CODE_TO_NOTE_CUSTOM_MAPPING_TYPE = 'itemCodeToNoteMapping';

export interface IItemCodeToNoteXLSX {
  code: string;
  description: string;
  mapTo: ItemCodeNoteType;
}

export const ITEM_CODE_TO_NOTE_HEADERS: AtLeast<Column, 'key' | 'header'>[] = [
  {
    key: 'code',
    header: 'code',
  },
  {
    key: 'description',
    header: 'Description',
    width: 60,
  },
  {
    key: 'mapTo',
    header: 'Maps to note type',
    width: 30,
  },
];

export class ItemCodesToNotesXLSX<
  T extends IBaseMigrationItemCode = IBaseMigrationItemCode,
> implements IXSLXExport<T, IItemCodeToNoteXLSX>
{
  headers = ITEM_CODE_TO_NOTE_HEADERS;

  constructor(
    private _existingMappings: ITranslationMap<object, ItemCodeNoteType>[],
    private _suggestedMappings: { [key: string]: string }
  ) {}

  translate(records: T[]): Record<keyof IItemCodeToNoteXLSX, XSLXCell>[] {
    return records.map((record) => {
      const existingRecord = this._existingMappings.find(
        (existingMapping) =>
          existingMapping.sourceIdentifier === record.itemCode
      );

      const suggestedMappingTreatmentName =
        this._suggestedMappings[record.itemCode] ?? undefined;

      return {
        code: {
          value: record.itemCode,
        },
        description: {
          value: record.description,
        },
        mapTo: {
          value:
            existingRecord?.destinationValue ?? suggestedMappingTreatmentName,
          dataValidation: {
            type: 'list',
            formulae: [`"${getEnumValues(ItemCodeNoteType).join(',')}"`],
          },
        },
      };
    });
  }
}
