import { IntegrationType } from '@principle-theorem/integrations';
import {
  Region,
  type ISODateType,
  type WithRef,
} from '@principle-theorem/shared';
import { type SMSConversation, type SMSMessage } from './sms-message';

export const SMS_INTEGRATION_OPTIONS = [
  IntegrationType.Podium,
  IntegrationType.Twilio,
  IntegrationType.TNZ,
];

export type SmsIntegrationOption =
  | IntegrationType.Podium
  | IntegrationType.Twilio
  | IntegrationType.TNZ;

export const SMS_INTEGRATION_COUNTRY_MAP: Record<
  Region,
  SmsIntegrationOption[]
> = {
  [Region.Australia]: [IntegrationType.Twilio, IntegrationType.Podium],
  [Region.NewZealand]: [IntegrationType.TNZ],
};

export interface ISMSProvider {
  sendSMS(data: WithRef<SMSMessage>): Promise<void>;
  createConversation(conversation: WithRef<SMSConversation>): Promise<void>;
  closeConversation(conversation: WithRef<SMSConversation>): Promise<void>;
  reopenConversation(conversation: WithRef<SMSConversation>): Promise<void>;
}

export interface ISMSUsageRequest {
  from: ISODateType;
  to: ISODateType;
  orgUid: string;
}

export interface ITwilioMessageBillingSummary {
  dateSent: Date;
  numSegments: string;
  price: number;
}

export interface ITwilioBillingSummary {
  practiceName: string;
  inboundTotal: number;
  outboundTotal: number;
  total: number;
  inbound: ITwilioMessageBillingSummary[];
  inboundSegments: number;
  outbound: ITwilioMessageBillingSummary[];
  outboundSegments: number;
}

export interface ISMSUsageResponse {
  practices: ITwilioBillingSummary[];
}

export interface IPodiumSMSLocationsRequest {
  orgUid: string;
}

export interface IPodiumSMSLocation {
  uid: string;
  label: string;
}

export interface IPodiumSMSLocationsResponse {
  locations: IPodiumSMSLocation[];
}
