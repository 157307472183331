<div class="flex flex-col gap-2">
  <button
    #menuTrigger="matMenuTrigger"
    mat-stroked-button
    class="flex flex-1"
    [matMenuTriggerFor]="gapSearch.menu"
  >
    <mat-icon>search</mat-icon>
    <span>Search Gaps</span>
  </button>
  <pr-gap-search
    #gapSearch
    matTooltip="Search Gaps"
    (closeMenu)="menuTrigger.closeMenu()"
  />
  <pr-gap-search-filters />

  @if (gapStore.searchOutOfRange$ | async) {
    <pr-empty-state
      *ngIf="gapStore.defaultSearchRange$ | async as gapRange"
      image="appointment"
      title="Gaps For This Date Range"
      [descriptionEnabled]="false"
    >
      <ng-container *ngIf="nextAvailable$ | async as nextGap">
        <p class="mat-body">
          Gaps are available between
          <strong>
            {{ nextGap.event.from | moment | amDateFormat: dateFormat }}
          </strong>
          -
          <strong>{{ gapRange.to | moment | amDateFormat: dateFormat }}</strong>
        </p>
      </ng-container>
    </pr-empty-state>
  } @else {
    <div class="flex items-center justify-between">
      <strong>{{ gapCount$ | async }} Gaps Found</strong>
      @if (gapCount$ | async) {
        <span>Select a gap below to fill</span>
      }
    </div>

    @if (groupedGaps$ | async; as groups) {
      @for (group of groups; track group.day) {
        @defer (on viewport) {
          <pr-gap-day-header [gapDayPair]="group" />
          <div class="flex flex-col gap-1.5">
            @for (gap of group.gaps; track gap.uid) {
              <pr-gap-item [gap]="gap" />
            }
          </div>
        } @placeholder (minimum 200ms) {
          <ngx-skeleton-loader />
        }
      }
    }
  }
</div>
