import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  CheckinAppointmentActionService,
  CheckoutAppointmentActionService,
  CompleteAppointmentActionService,
  ConfirmAppointmentActionService,
  OpenAppointmentActionService,
  StartAppointmentActionService,
  type AppointmentCardAction,
} from '@principle-theorem/ng-appointment';
import {
  CalendarEventsFacade,
  CalendarFacade,
} from '@principle-theorem/ng-calendar/store';
import { EventableTimelineStore } from '@principle-theorem/ng-eventable';
import {
  GapStoreService,
  GlobalStoreService,
  OrganisationService,
  datePaginationQueryParam$,
} from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { Appointment } from '@principle-theorem/principle-core';
import {
  AppointmentSummary,
  EventType,
  IScheduleSummaryEventable,
  isAppointmentEventType,
  isAppointmentSummary,
  isCalendarEvent,
  isGapEventType,
  type IAppointment,
  type IEventable,
  type IPractice,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  CASUAL_DATE_WITH_YEAR,
  Firestore,
  ISO_DATE_FORMAT,
  filterUndefined,
  isRefChanged$,
  isSameRange,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import {
  ReplaySubject,
  Subject,
  combineLatest,
  of,
  type Observable,
} from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { ManageCalendarEventService } from '../../manage-calendar-event.service';
import { EventTypeFilter } from './event-type-selector/event-type-filter';
import { StafferDayEvents } from './staffer-day-events';

@Component({
    selector: 'pr-dashboard-timeline',
    templateUrl: './dashboard-timeline.component.html',
    styleUrls: ['./dashboard-timeline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [EventableTimelineStore],
    standalone: false
})
export class DashboardTimelineComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _dayEvents: StafferDayEvents;
  staffer$ = new ReplaySubject<WithRef<IStaffer>>(1);
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);
  trackBySummary = TrackByFunctions.uniqueId<IScheduleSummaryEventable>();
  headlineFormat = CASUAL_DATE_WITH_YEAR;
  actions: AppointmentCardAction[];
  eventsFilter: EventTypeFilter;
  availableEventTypes: EventType[] = [
    EventType.Appointment,
    EventType.PreBlock,
    EventType.Meeting,
    EventType.Break,
    EventType.Leave,
    EventType.Gap,
    EventType.AppointmentRequest,
  ];
  date$: Observable<Date>;

  @Input()
  set practice(practice: WithRef<IPractice>) {
    if (practice) {
      this.practice$.next(practice);
    }
  }

  @Input()
  set staffer(staffer: WithRef<IStaffer>) {
    if (!staffer) {
      return;
    }
    this.staffer$.next(staffer);
  }

  set filters(filters: EventType[]) {
    this.eventsFilter.filters$.next(filters);
  }

  get filters(): EventType[] {
    return this.eventsFilter.filters$.value;
  }

  constructor(
    public gapStore: GapStoreService,
    confirmAppointment: ConfirmAppointmentActionService,
    checkinAppointment: CheckinAppointmentActionService,
    startAppointment: StartAppointmentActionService,
    openAppointment: OpenAppointmentActionService,
    finishAppointment: CompleteAppointmentActionService,
    checkoutAppointment: CheckoutAppointmentActionService,
    organisation: OrganisationService,
    private _calendarEventsFacade: CalendarEventsFacade,
    private _calendarFacade: CalendarFacade,
    private _calendarEvent: ManageCalendarEventService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _globalStore: GlobalStoreService
  ) {
    this.actions = [
      confirmAppointment,
      checkinAppointment,
      startAppointment,
      openAppointment,
      finishAppointment,
      checkoutAppointment,
    ];

    this._dayEvents = new StafferDayEvents(
      this.staffer$,
      organisation.brand$.pipe(filterUndefined()),
      this.practice$,
      this._calendarEventsFacade,
      this._calendarFacade,
      this._globalStore
    );

    this.eventsFilter = new EventTypeFilter(this._dayEvents.events$);
    this.date$ = this._dayEvents.date$.pipe(map((date) => date.toDate()));

    combineLatest([
      this._dayEvents.events$,
      this.practice$.pipe(isRefChanged$()),
      this._dayEvents.dateRange$.pipe(distinctUntilChanged(isSameRange)),
      of([]),
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((source) => this.gapStore.setGaps(source));

    datePaginationQueryParam$(this._route)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((date) => this._dayEvents.date$.next(date));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async dateChange(date: Date): Promise<void> {
    await this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        date: moment(date).format(ISO_DATE_FORMAT),
      },
    });
  }

  isAppointmentSummary(
    eventable: IScheduleSummaryEventable
  ): AppointmentSummary | undefined {
    return isAppointmentSummary(eventable) ? eventable : undefined;
  }

  async summaryToAppointment(
    eventable: AppointmentSummary
  ): Promise<WithRef<IAppointment> | undefined> {
    if (!eventable.ref) {
      return;
    }
    return Firestore.getDoc(eventable.ref);
  }

  isGapSummary(
    eventable: IScheduleSummaryEventable
  ): IScheduleSummaryEventable | undefined {
    return isGapEventType(eventable.event.type) ? eventable : undefined;
  }

  isDefaultEventableSummary(eventable: IScheduleSummaryEventable): boolean {
    return (
      !isAppointmentEventType(eventable.event.type) &&
      !isGapEventType(eventable.event.type)
    );
  }

  statusColour(appointment: IAppointment): string {
    return Appointment.statusColour(appointment.status);
  }

  statusTooltip(appointment: IAppointment): string {
    return Appointment.statusTooltip(appointment.status);
  }

  async editCalendarEvent(calendarEvent: IEventable): Promise<void> {
    if (isCalendarEvent(calendarEvent)) {
      await this._calendarEvent.openEditDialog(calendarEvent);
    }
  }
}
