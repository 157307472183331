import { Injectable } from '@angular/core';
import { Invoice } from '@principle-theorem/principle-core';
import {
  type IHealthcareClaim,
  type IInvoice,
  type ITransaction,
  TransactionProvider,
} from '@principle-theorem/principle-core/interfaces';
import { Region, type DocumentReference } from '@principle-theorem/shared';
import { type WithRef } from '@principle-theorem/shared';
import { combineLatest, type Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TransactionHelpers } from '../transaction-helpers';
import {
  type ITransactionProvider,
  TransactionProviderType,
} from '../transaction-provider';
import { MedipassProviderService } from './medipass-provider.service';

@Injectable()
export class MedipassMedicareTransactionProvider
  implements ITransactionProvider
{
  providerId = TransactionProvider.MedipassMedicare;
  providerType = TransactionProviderType.Medicare;
  providerRegions = [Region.Australia];
  isEnabled$: Observable<boolean>;

  constructor(private _medipassProvider: MedipassProviderService) {
    this.isEnabled$ = this._medipassProvider.isEnabled$;
  }

  canCapture$(invoice: WithRef<IInvoice>): Observable<boolean> {
    return combineLatest([
      of(Invoice.canAddTransactions(invoice)),
      TransactionHelpers.hasReceivedPayment$(invoice),
    ]).pipe(
      map(
        ([canAddTransactions, hasReceivedPayment]) =>
          canAddTransactions && !hasReceivedPayment
      )
    );
  }

  async capture(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim
  ): Promise<DocumentReference<ITransaction> | undefined> {
    return this._medipassProvider.openFunder(invoice, 'medicare', claim);
  }
}
