import { type Routes } from '@angular/router';
import { AppointmentRequestsComponent } from '@principle-theorem/ng-appointment';
import {
  HasWorkspaceGuard,
  IsAuthenticatedGuard,
} from '@principle-theorem/ng-auth';
import { FeatureEnabledGuard } from '@principle-theorem/ng-feature-flags';
import {
  HasPermissionGuard,
  IsAuthorisedGuard,
  IsIpRestrictedGuard,
  IsManagementGuard,
  IsTimeRestrictedGuard,
} from '@principle-theorem/ng-principle-shared';
import {
  APPOINTMENTS_FEATURE,
  AppointmentPermissions,
  BRANDS_FEATURE,
  BrandPermissions,
  CALENDAR_FEATURE,
  CHATS_FEATURE,
  CONTACTS_FEATURE,
  CONVERSATIONS_FEATURE,
  FOLLOW_UPS_FEATURE,
  GAPS_FEATURE,
  LABS_FEATURE,
  LAB_JOBS_FEATURE,
  MANAGEMENT_FEATURE,
  PATIENTS_FEATURE,
  REPORTING_FEATURE,
  SCHEDULING_ALERTS_FEATURE,
  SEEDING_FEATURE,
  STAFF_FEATURE,
  STERILISATION_FEATURE,
  TASKS_FEATURE,
} from '@principle-theorem/principle-core/features';
import { ExternalLinkRedirectComponent } from '../external-link/external-link-redirect/external-link-redirect.component';
import { BrandResolverService } from '../layout/brand-resolver.service';
import { MainComponent } from '../layout/main/main.component';
import { PortalPageComponent } from '../layout/portal-page/portal-page.component';
import { PracticeResolverService } from '../layout/practice-resolver.service';
import { PracticeSelectorComponent } from '../layout/practice-selector/practice-selector.component';
import { SchedulingAlertsComponent } from '../scheduling-alerts/scheduling-alerts.component';

const sharedAppGuards = [
  FeatureEnabledGuard,
  HasPermissionGuard,
  IsIpRestrictedGuard,
  IsTimeRestrictedGuard,
];

export const ORGANISATION_ROUTES: Routes = [
  {
    path: 'reporting',
    canActivate: [IsAuthenticatedGuard, HasWorkspaceGuard, ...sharedAppGuards],
    data: {
      title: 'Reporting',
      feature: REPORTING_FEATURE,
    },
    loadChildren: () =>
      import('@principle-theorem/ng-reporting').then(
        (mod) => mod.ReportingRoutingModule
      ),
  },
];

export const PRACTICE_SCOPED_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'schedule/timeline',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    canActivate: sharedAppGuards,
    loadChildren: () =>
      import('../dashboard/dashboard-routing.module').then(
        (mod) => mod.DashboardRoutingModule
      ),
    data: {
      feature: APPOINTMENTS_FEATURE,
      permissions: [AppointmentPermissions.AppointmentManage],
    },
  },
  {
    path: 'gaps',
    canActivate: sharedAppGuards,
    loadChildren: () =>
      import('@principle-theorem/ng-gaps').then(
        (mod) => mod.GapPagesRoutingModule
      ),
    data: {
      title: 'Appointment Gaps',
      feature: GAPS_FEATURE,
    },
  },
  {
    path: 'appointment-requests',
    component: AppointmentRequestsComponent,
    canActivate: sharedAppGuards,
    data: {
      title: 'Appointment Requests',
      feature: APPOINTMENTS_FEATURE,
      permissions: [AppointmentPermissions.AppointmentManage],
    },
  },
  {
    path: 'follow-ups',
    canActivate: sharedAppGuards,
    data: {
      title: 'Appointment Follow Ups',
      feature: FOLLOW_UPS_FEATURE,
    },
    loadChildren: () =>
      import('@principle-theorem/ng-follow-ups').then(
        (mod) => mod.FollowUpPagesRoutingModule
      ),
  },
  {
    path: 'scheduling-alerts',
    component: SchedulingAlertsComponent,
    canActivate: sharedAppGuards,
    data: {
      title: 'Scheduling Alerts',
      feature: SCHEDULING_ALERTS_FEATURE,
    },
  },
  {
    path: 'lab-jobs',
    loadChildren: () =>
      import('@principle-theorem/ng-labs').then(
        (mod) => mod.LabJobPagesRoutingModule
      ),
    canActivate: sharedAppGuards,
    data: {
      title: 'Lab Jobs',
      feature: LAB_JOBS_FEATURE,
    },
  },
  {
    path: 'contacts',
    canActivate: sharedAppGuards,
    data: {
      title: 'Contacts',
      feature: CONTACTS_FEATURE,
    },
    loadChildren: () =>
      import('@principle-theorem/ng-contacts').then(
        (mod) => mod.ContactPagesRoutingModule
      ),
  },
  {
    path: 'tasks',
    canActivate: sharedAppGuards,
    data: {
      feature: TASKS_FEATURE,
    },
    loadChildren: () =>
      import('@principle-theorem/ng-tasks').then(
        (mod) => mod.TaskPagesRoutingModule
      ),
  },
  {
    path: 'labs',
    canActivate: sharedAppGuards,
    data: {
      feature: LABS_FEATURE,
    },
    loadChildren: () =>
      import('@principle-theorem/ng-labs').then(
        (mod) => mod.LabPagesRoutingModule
      ),
  },
  {
    path: 'schedule',
    canActivate: sharedAppGuards,
    data: { feature: CALENDAR_FEATURE },
    loadChildren: () =>
      import('@principle-theorem/ng-calendar').then(
        (mod) => mod.NgCalendarRoutingModule
      ),
  },
  {
    path: 'sterilisation',
    canActivate: sharedAppGuards,
    data: { feature: STERILISATION_FEATURE },
    loadChildren: () =>
      import('@principle-theorem/ng-sterilisation').then(
        (mod) => mod.NgSterilisationRoutingModule
      ),
  },
  {
    path: 'conversations',
    canActivate: sharedAppGuards,
    data: { feature: CONVERSATIONS_FEATURE },
    loadChildren: () =>
      import('@principle-theorem/ng-conversations').then(
        (mod) => mod.NgConversationsRoutingModule
      ),
  },
  {
    path: 'chats',
    canActivate: sharedAppGuards,
    data: { feature: CHATS_FEATURE },
    loadChildren: () =>
      import('@principle-theorem/ng-chats').then(
        (mod) => mod.NgChatsRoutingModule
      ),
  },
];

export const APP_ROUTES: Routes = [
  {
    path: '',
    canActivate: [IsAuthenticatedGuard, IsAuthorisedGuard, HasWorkspaceGuard],
    component: PracticeSelectorComponent,
  },
  {
    path: '',
    loadChildren: () =>
      import('@principle-theorem/ng-principle').then(
        (mod) => mod.AuthPagesRoutingModule
      ),
  },
  {
    path: 'redirect',
    canActivate: [IsAuthenticatedGuard, IsAuthorisedGuard, HasWorkspaceGuard],
    component: ExternalLinkRedirectComponent,
  },
  {
    path: 'portal',
    component: PortalPageComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@principle-theorem/ng-patient').then(
            (mod) => mod.NgPatientActionsRoutingModule
          ),
      },
      {
        path: 'booking',
        loadChildren: () =>
          import('@principle-theorem/ng-patient-scheduling-portal').then(
            (mod) => mod.NgPatientSchedulingPortalRoutingModule
          ),
      },
    ],
  },
  {
    path: 'confirm/:token',
    redirectTo: '/portal/confirm-appointment/:token',
  },
  {
    path: 'history/:token',
    redirectTo: '/portal/medical-history/:token',
  },
  {
    path: 'invoice/:token',
    redirectTo: '/portal/view-invoice/:token',
  },
  {
    path: 'reschedule/:token',
    redirectTo: '/portal/appointment-reschedule/:token',
  },
  {
    path: 'forms/:token',
    redirectTo: '/portal/forms/:token',
  },
  {
    path: 'settings',
    canActivate: [IsAuthenticatedGuard, IsAuthorisedGuard, ...sharedAppGuards],
    loadChildren: () =>
      import('@principle-theorem/ng-principle').then(
        (mod) => mod.SettingsPagesRoutingModule
      ),
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [
      IsAuthenticatedGuard,
      IsAuthorisedGuard,
      HasWorkspaceGuard,
      IsIpRestrictedGuard,
      IsTimeRestrictedGuard,
    ],
    children: [
      ...ORGANISATION_ROUTES,
      {
        path: 'mock-seeder',
        canActivate: [FeatureEnabledGuard],
        data: {
          title: 'Mock Seeder',
          feature: SEEDING_FEATURE,
        },
        loadChildren: () =>
          import('../mock-seeder/mock-seeder-routing.module').then(
            (mod) => mod.MockSeederRoutingModule
          ),
      },
      {
        path: 'management',
        canActivate: [FeatureEnabledGuard, IsManagementGuard],
        data: {
          feature: MANAGEMENT_FEATURE,
        },
        loadChildren: () =>
          import('@principle-theorem/ng-principle').then(
            (mod) => mod.ManagementPagesRoutingModule
          ),
      },
      {
        path: 'tests',
        canActivate: [IsAuthenticatedGuard, ...sharedAppGuards],
        loadChildren: () =>
          import('@principle-theorem/ng-principle').then(
            (mod) => mod.TestPagesRoutingModule
          ),
      },
      {
        path: 'account',
        loadChildren: () =>
          import('@principle-theorem/ng-principle').then(
            (mod) => mod.AccountRoutingModule
          ),
      },
      {
        path: ':brand-slug',
        resolve: { brand: BrandResolverService },
        children: [
          {
            path: '',
            redirectTo: 'patients',
            pathMatch: 'full',
          },
          {
            path: 'patients',
            canActivate: sharedAppGuards,
            data: {
              title: 'Patients',
              feature: PATIENTS_FEATURE,
            },
            loadChildren: () =>
              import('../patients/patients-routing.module').then(
                (mod) => mod.PatientsRoutingModule
              ),
          },
          {
            path: 'staff',
            canActivate: sharedAppGuards,
            data: {
              title: 'Staff',
              feature: STAFF_FEATURE,
            },
            loadChildren: () =>
              import('../staff/staff-routing.module').then(
                (mod) => mod.StaffRoutingModule
              ),
          },
          {
            path: 'automations',
            canActivate: sharedAppGuards,
            data: {
              title: 'Automations',
              feature: BRANDS_FEATURE,
              permissions: [BrandPermissions.AutomationManage],
            },
            loadChildren: () =>
              import('@principle-theorem/ng-automations').then(
                (mod) => mod.PagesRoutingModule
              ),
          },
          {
            path: 'migration',
            loadChildren: () =>
              import('@principle-theorem/ng-practice-migrations').then(
                (mod) => mod.PracticeMigrationsPortalPagesRoutingModule
              ),
          },
          {
            path: ':practice-slug',
            children: [...PRACTICE_SCOPED_ROUTES],
            resolve: { practice: PracticeResolverService },
          },
          {
            path: 'appointments',
            loadChildren: () =>
              import('@principle-theorem/ng-appointment').then(
                (mod) => mod.NgAppointmentSchedulingRoutingModule
              ),
          },
        ],
      },
    ],
  },
];
