<mat-toolbar color="accent">{{ data.title }}</mat-toolbar>

<mat-dialog-content>
  <ng-container *ngComponentOutlet="data.component" />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button [mat-dialog-close]="true">Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
