import {
  ActivatedRouteSnapshot,
  type Params,
  type RouterStateSnapshot,
} from '@angular/router';
import {
  type BaseRouterStoreState,
  type RouterStateSerializer,
} from '@ngrx/router-store';

export interface IRouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export interface IMinimalRouterStateSnapshot extends BaseRouterStoreState {
  root: IRouterStateUrl;
  url: string;
}

export class BasicRouterStateSerializer
  implements RouterStateSerializer<IMinimalRouterStateSnapshot>
{
  serialize(routerState: RouterStateSnapshot): IMinimalRouterStateSnapshot {
    return {
      root: this.serializeRoute(routerState.root),
      url: routerState.url,
    };
  }

  serializeRoute(route: ActivatedRouteSnapshot): IRouterStateUrl {
    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = route;
    const { params } = route;

    return { url: url.join('/'), params, queryParams };
  }
}
