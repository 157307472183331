import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Brand, ReferralSource } from '@principle-theorem/principle-core';
import {
  type IContact,
  type IReferralSource,
  type IReferralSourceConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, type WithRef } from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { CurrentBrandScope } from '../../app-state/current-brand';
import { TypesenseSearchService } from '../../typesense/typesense-search.service';

export interface IReferrerSelectorState {
  referralSources: IReferralSource[];
}

const initialState: IReferrerSelectorState = { referralSources: [] };

@Injectable()
export class ReferrerSelectorStore extends ComponentStore<IReferrerSelectorState> {
  private _search = inject(TypesenseSearchService);
  private _brandScope = inject(CurrentBrandScope);
  readonly referralSources$ = this.select((store) => store.referralSources);

  readonly loadReferrers = this.effect((searchValue$: Observable<string>) => {
    const patients$ = this._search.patientQuery$(searchValue$).pipe(
      map((response) => response.results),
      startWith([])
    );
    return combineLatest([
      this._getConfiguredReferrers$(),
      patients$,
      this._getContacts$(),
    ]).pipe(
      map(([configuredReferrers, patients, contacts]) =>
        [...configuredReferrers, ...patients, ...contacts].map(
          ReferralSource.toReferrer
        )
      ),
      tap((referralSources) => this.patchState({ referralSources }))
    );
  });

  constructor() {
    super(initialState);
  }

  private _getContacts$(): Observable<WithRef<IContact>[]> {
    return this._brandScope.doc$.pipe(
      filterUndefined(),
      switchMap((brand) => Brand.contacts$(brand))
    );
  }

  private _getConfiguredReferrers$(): Observable<
    WithRef<IReferralSourceConfiguration>[]
  > {
    return this._brandScope.doc$.pipe(
      filterUndefined(),
      switchMap((brand) => Brand.referralSources$(brand))
    );
  }
}
