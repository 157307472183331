import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type IStaffer } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';

interface ISelectPractitionerDialogData {
  practitioners: WithRef<IStaffer>[];
}

@Component({
    selector: 'pr-select-practitioner-dialog',
    templateUrl: './select-practitioner-dialog.component.html',
    styleUrls: ['./select-practitioner-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SelectPractitionerDialogComponent {
  trackByStaffer = TrackByFunctions.ref<WithRef<IStaffer>>();

  constructor(
    public dialogRef: MatDialogRef<SelectPractitionerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISelectPractitionerDialogData
  ) {}
}
