<mat-toolbar color="accent">
  <h2>Save Custom Report</h2>
</mat-toolbar>

<mat-dialog-content class="layout-padding">
  <pr-custom-report-form
    (closeDialog)="dialogRef.close()"
    (saveCustomReport)="dialogRef.close($event)"
  />
</mat-dialog-content>
