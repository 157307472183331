<mat-form-field
  fxFlex
  fxFill
  [appearance]="appearance"
  [subscriptSizing]="subscriptSizing"
  [floatLabel]="floatLabel"
>
  <mat-label>{{ label }}</mat-label>

  <input
    matInput
    matTimepicker
    color="accent"
    [placeholder]="placeholder"
    #timePicker="matTimepicker"
    mode="12h"
    [formControl]="timeControl"
    [disableDialogOpenOnClick]="true"
    [errorStateMatcher]="showOnDirtyErrorStateMatcher"
  />

  <div matTextSuffix class="relative -bottom-1 -right-4 flex flex-row">
    <button mat-icon-button (click)="timePicker.showDialog()">
      <mat-icon>access_time</mat-icon>
    </button>
    <button mat-icon-button (click)="clear()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <mat-error *ngIf="timeControl.touched && timeControl.invalid">
    Invalid Date
  </mat-error>
</mat-form-field>
