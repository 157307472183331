import {
  IInvoiceLineItem,
  isHasLineItems,
} from '@principle-theorem/principle-core/interfaces';
import { sum } from 'lodash';

export class LineItemActions {
  static sum(items: IInvoiceLineItem[]): number {
    return sum(items.map((item) => item.amount * item.quantity));
  }

  static subTotal(items: IInvoiceLineItem[]): number {
    return this.sum(items) - this.tax(items);
  }

  static tax(items: IInvoiceLineItem[]): number {
    return sum(items.map((item) => item.tax * item.quantity));
  }

  static discounts(_items: IInvoiceLineItem[]): number {
    // TODO: figure out discounts.
    return 0;
  }

  static total(items: IInvoiceLineItem[]): number {
    return this.subTotal(items) + this.tax(items) - this.discounts(items);
  }

  static recursiveFindByUuid(
    items: IInvoiceLineItem[],
    uid: string
  ): IInvoiceLineItem | undefined {
    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      if (item.uid === uid) {
        return item;
      }
      if (!isHasLineItems(item)) {
        continue;
      }
      const foundChild = LineItemActions.recursiveFindByUuid(item.items, uid);
      if (foundChild) {
        return foundChild;
      }
    }
  }
}
