import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  InputSearchFilter,
  toSearchStream,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  Automation,
  AUTOMATION_STATUS_FILTER_OPTIONS,
  AUTOMATION_TYPE_FILTER_OPTIONS,
  type AutomationEntity,
  type IAutomationListData,
} from '@principle-theorem/principle-core';
import {
  type AutomationStatus,
  type AutomationType,
} from '@principle-theorem/principle-core/interfaces';
import { isWithRef, multiMap, snapshot } from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutomationsFacade } from '../../store/facades/automations.facade';
import { type AutomationLayoutMode } from '../automation-list-display/automation-list-display.component';

@Component({
    selector: 'pr-automations-list',
    templateUrl: './automations-list.component.html',
    styleUrls: ['./automations-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AutomationsListComponent {
  loading$: Observable<boolean>;
  automations$: Observable<IAutomationListData[]>;
  activeTypeFilter$: Observable<string>;
  activeStatusFilter$: Observable<AutomationStatus[]>;
  isAllSelected$: Observable<boolean>;
  hasSomeSelected$: Observable<boolean>;
  selectedIds$: Observable<string[]>;
  statusFilters = AUTOMATION_STATUS_FILTER_OPTIONS;
  typeFilters = AUTOMATION_TYPE_FILTER_OPTIONS;
  searchControl: TypedFormControl<string> = new TypedFormControl();
  searchFilter: InputSearchFilter<IAutomationListData>;
  emptyState$: Observable<boolean>;
  emptySearch$: Observable<boolean>;
  @Input() layout: AutomationLayoutMode = 'list';

  constructor(private _automationsFacade: AutomationsFacade) {
    this.loading$ = this._automationsFacade.automationsLoaded$.pipe(
      map((loaded) => !loaded)
    );
    this.automations$ = this._automationsFacade.getFilteredListData$();
    this.activeTypeFilter$ = this._automationsFacade.activeTypeFilter$.pipe(
      map((option) => option.id)
    );
    this.activeStatusFilter$ = this._automationsFacade.statusFilter$;
    this.isAllSelected$ = this._automationsFacade.isAllSelected$;
    this.hasSomeSelected$ = combineLatest([
      this._automationsFacade.isAllSelected$,
      this._automationsFacade.isNoneSelected$,
    ]).pipe(
      map(
        ([isAllSelected, isNoneSelected]) => !isAllSelected && !isNoneSelected
      )
    );
    this.selectedIds$ = this._automationsFacade.selectedAutomationIds$;

    this.searchFilter = new InputSearchFilter<IAutomationListData>(
      this.automations$,
      toSearchStream(this.searchControl),
      [
        'automation.data.name',
        'automation.data.title',
        'automation.type',
        (automation) => automation.automation.creator.name,
      ]
    );
    this.emptyState$ = combineLatest([this.automations$, this.loading$]).pipe(
      map(([automations, loading]) => !automations.length && !loading)
    );
    this.emptySearch$ = combineLatest([
      this.automations$,
      this.searchFilter.results$,
      this.loading$,
    ]).pipe(
      map(
        ([automations, results, loading]) =>
          automations.length > 0 && !results.length && !loading
      )
    );
  }

  setTypeFilter(type: AutomationType): void {
    this._automationsFacade.setTypeFilter(type);
  }

  setStatusFilter(status: AutomationStatus[]): void {
    this._automationsFacade.setStatusFilter(status);
  }

  cancelSelected(): void {
    this._automationsFacade.cancelSelected();
  }

  async checkboxChange(checked: boolean): Promise<void> {
    if (checked) {
      const ids = await snapshot(
        this.searchFilter.results$.pipe(
          multiMap((result) => result.automation.uid)
        )
      );
      return this._automationsFacade.selectMultiple(ids);
    }
    this._automationsFacade.unselectAll();
  }

  async edit(automation: AutomationEntity): Promise<void> {
    if (!isWithRef(automation)) {
      return;
    }
    const event = await snapshot(
      Automation.resolveAppointment$(automation).pipe(
        map((appointment) => appointment?.event)
      )
    );
    if (!event) {
      return;
    }

    const useRelativeTime = this.layout === 'list' ? true : false;
    this._automationsFacade.editAutomation(
      automation,
      event,
      false,
      useRelativeTime
    );
  }

  cancelAutomation(automation: AutomationEntity): void {
    this._automationsFacade.cancelOne(automation);
  }

  selectAutomation(id: string): void {
    this._automationsFacade.selectOne(id);
  }

  unselectAutomation(id: string): void {
    this._automationsFacade.unselectOne(id);
  }

  async restore(automation: AutomationEntity): Promise<void> {
    await this._automationsFacade.restoreAutomation(automation);
  }

  resend(automation: AutomationEntity): void {
    this._automationsFacade.resend(automation);
  }
}
