import { type IXSLXImport } from '@principle-theorem/shared';
import type { Row } from 'exceljs';
import {
  type IPatientStatusXSLX,
  PATIENT_STATUS_HEADERS,
} from './patient-statuses-to-xlsx';

export class XSLXToPatientStatuses implements IXSLXImport<IPatientStatusXSLX> {
  headers = PATIENT_STATUS_HEADERS;

  translate(row: Row): IPatientStatusXSLX {
    return {
      id: row.getCell('id').value?.toString() ?? '',
      name: row.getCell('name').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IPatientStatusXSLX['mapTo'],
    };
  }
}
