import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogPresets,
  type IMatSelectGroupOptions,
  matSelectGroupOptions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  type ITemplateContextOption,
  type ITemplateDefinition,
  TemplateScope,
} from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, getEnumValues } from '@principle-theorem/shared';
import { first, sortBy } from 'lodash';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  type IPopulatedTemplate,
  populateTemplate,
} from '../contextual-actions/interaction-actions/template-context-resolvers/mention-to-templates';
import { TemplateContextSelectorDialogComponent } from '../template-context-selector-dialog/template-context-selector-dialog.component';

@Component({
  selector: 'pr-template-selector',
  templateUrl: './template-selector.component.html',
  styleUrls: ['./template-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateSelectorComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  templateCtrl = new TypedFormControl<ITemplateDefinition>();
  options$ = new ReplaySubject<
    IMatSelectGroupOptions<TemplateScope, ITemplateDefinition>
  >(1);
  contextOptions$ = new ReplaySubject<ITemplateContextOption[]>(1);
  loading$ = new BehaviorSubject<boolean>(true);
  @Output() templateSelected = new EventEmitter<IPopulatedTemplate>();
  @Input() resetOnSelection: boolean = false;

  @Input()
  set templates(templates: ITemplateDefinition[]) {
    if (!templates) {
      return;
    }
    const options = getEnumValues(TemplateScope)
      .map((scope) => ({
        group: scope,
        items: sortBy(
          templates
            .filter((template) => template.scope === scope)
            .map((template) => ({
              label: template.name,
              value: template,
            })),
          'label'
        ),
      }))
      .filter((option) => option.items.length > 0);
    this.options$.next(matSelectGroupOptions(options));
    this.loading$.next(false);
  }

  @Input()
  set contextOptions(contextOptions: ITemplateContextOption[]) {
    if (contextOptions) {
      this.contextOptions$.next(contextOptions);
    }
  }

  constructor(private _dialog: MatDialog) {
    this.templateCtrl.valueChanges
      .pipe(
        withLatestFrom(this.contextOptions$),
        switchMap(async ([template, contextOptions]) => {
          const selected = await this._selectContextOption(
            template,
            contextOptions
          );
          if (selected) {
            return populateTemplate(
              template,
              selected.context,
              selected.scopeData
            );
          }
        }),
        filterUndefined(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((template) => {
        this.templateSelected.emit(template);
        if (this.resetOnSelection) {
          this.templateCtrl.reset(undefined, { emitEvent: false });
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private async _selectContextOption(
    template: ITemplateDefinition,
    contextOptions: ITemplateContextOption[]
  ): Promise<ITemplateContextOption | undefined> {
    const filteredContextOptions = contextOptions.filter(
      (option) => option.scope === template.scope
    );
    const defaultOption = first(filteredContextOptions);
    if (!defaultOption) {
      return;
    }
    if (filteredContextOptions.length === 1) {
      return defaultOption;
    }

    const selectedOption = await this._dialog
      .open<
        TemplateContextSelectorDialogComponent,
        ITemplateContextOption[],
        ITemplateContextOption
      >(
        TemplateContextSelectorDialogComponent,
        DialogPresets.small({
          data: filteredContextOptions,
        })
      )
      .afterClosed()
      .toPromise();

    return selectedOption;
  }
}
