import {
  isRightQuadrant,
  isSameToothRef,
} from '@principle-theorem/principle-core';
import {
  IPerioTableCell,
  PERIO_DATA_POINTS,
  PERIO_MEASUREMENTS,
  PerioMeasurement,
  PerioMeasurementValue,
  Quadrant,
  type IPerioDataPoints,
  type IPerioRecord,
  type ITooth,
  type IToothRef,
} from '@principle-theorem/principle-core/interfaces';
import { invertNumber } from '@principle-theorem/shared';
import { concat, get, unzipWith } from 'lodash';

const quadrantDisplayOrder: Quadrant[] = [
  Quadrant.AdultUpperRight,
  Quadrant.AdultUpperLeft,
  Quadrant.AdultLowerRight,
  Quadrant.AdultLowerLeft,
  Quadrant.DeciduousUpperRight,
  Quadrant.DeciduousUpperLeft,
  Quadrant.DeciduousLowerRight,
  Quadrant.DeciduousLowerLeft,
];

export class PerioRecord {
  static recordsForTeeth(
    records: IPerioRecord[],
    teeth: ITooth[]
  ): IPerioRecord[] {
    return teeth.map((tooth) => {
      const mathingRecord = records.find((record) =>
        isSameToothRef(record.toothRef, tooth.toothRef)
      );

      return (
        mathingRecord ?? {
          toothRef: tooth.toothRef,
          data: {},
        }
      );
    });
  }

  static transformPerioRecords(records: IPerioRecord[]): IPerioTableCell[][] {
    const transformed = records
      .sort((a, b) =>
        PerioRecord.sortByQuadrantDisplayOrder(a.toothRef, b.toothRef)
      )
      .map((record) => PerioRecord.transformPerioRecord(record));
    return unzipWith<IPerioTableCell[], IPerioTableCell[]>(transformed, concat);
  }

  static transformPerioRecord(record: IPerioRecord): IPerioTableCell[][] {
    return PERIO_MEASUREMENTS.map((measurement) => {
      const pairs = PerioRecord.getRecordMeasurements(record, measurement);
      return isRightQuadrant(record.toothRef.quadrant)
        ? pairs
        : pairs.reverse();
    });
  }

  static getRecordMeasurements(
    record: IPerioRecord,
    measurement: PerioMeasurement
  ): IPerioTableCell[] {
    if (measurement === PerioMeasurement.Mobility) {
      return PerioRecord.asDataPointsCellPairs(
        record.toothRef,
        measurement,
        record.data[measurement]
      );
    }
    return PerioRecord.getDataPointsCellPairs(
      record.toothRef,
      measurement,
      record.data[measurement]
    );
  }

  static asDataPointsCellPairs(
    toothRef: IToothRef,
    prefix: PerioMeasurement,
    value?: PerioMeasurementValue
  ): IPerioTableCell[] {
    return PERIO_DATA_POINTS.map((dataPoint) => ({
      metadata: {
        toothRef,
        dataPath: `${prefix}`,
        dataPoint,
        measurement: prefix,
      },
      value,
    }));
  }

  static getDataPointsCellPairs(
    toothRef: IToothRef,
    prefix: PerioMeasurement,
    points?: Partial<IPerioDataPoints>
  ): IPerioTableCell[] {
    return PERIO_DATA_POINTS.map((dataPoint) => ({
      metadata: {
        toothRef,
        dataPath: `${prefix}.${dataPoint}`,
        dataPoint,
        measurement: prefix,
      },
      value: get(points, dataPoint),
    })).sort((aCell, bCell) => {
      const aIndex = PERIO_DATA_POINTS.indexOf(aCell.metadata.dataPoint);
      const bIndex = PERIO_DATA_POINTS.indexOf(bCell.metadata.dataPoint);
      return aIndex > bIndex ? 1 : -1;
    });
  }

  static sortByQuadrantDisplayOrder(a: IToothRef, b: IToothRef): number {
    if (a.quadrant === b.quadrant) {
      const result = a.quadrantIndex > b.quadrantIndex ? 1 : -1;
      return isRightQuadrant(a.quadrant) ? invertNumber(result) : result;
    }
    const aOrderIndex = quadrantDisplayOrder.indexOf(a.quadrant);
    const bOrderIndex = quadrantDisplayOrder.indexOf(b.quadrant);
    return aOrderIndex > bOrderIndex ? 1 : -1;
  }
}
