import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  IHicapsConnectTerminal,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference } from '@principle-theorem/shared';

export type EditHicapsConnectTerminalFormData = Pick<
  IHicapsConnectTerminal,
  'name' | 'practitionerRef'
>;

export interface IEditHicapsConnectTerminalRequest {
  title: string;
  formData?: Partial<EditHicapsConnectTerminalFormData>;
}

@Component({
    selector: 'pr-edit-hicaps-connect-terminal-dialog',
    templateUrl: './edit-hicaps-connect-terminal-dialog.component.html',
    styleUrls: ['./edit-hicaps-connect-terminal-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditHicapsConnectTerminalDialogComponent {
  form = new TypedFormGroup<EditHicapsConnectTerminalFormData>({
    name: new TypedFormControl<string>(''),
    practitionerRef: new TypedFormControl<DocumentReference<IStaffer>>(
      undefined
    ),
  });

  constructor(
    private _dialogRef: MatDialogRef<
      EditHicapsConnectTerminalDialogComponent,
      EditHicapsConnectTerminalFormData
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: Partial<IEditHicapsConnectTerminalRequest>,
    public globalStore: GlobalStoreService
  ) {
    if (data.formData) {
      this.form.patchValue(data.formData);
    }
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }
    this._dialogRef.close(this.form.value);
  }
}
