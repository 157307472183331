import { roundTo2Decimals } from '@principle-theorem/accounting';
import { type IADACodeClaim } from '@principle-theorem/principle-core/interfaces';
import { sum } from 'lodash';

interface IClaimSplitItem extends ISplitSegment {
  item: IADACodeClaim;
}

export function determineContributionPerItem(
  items: IADACodeClaim[],
  totalContributionAmount: number
): IClaimSplitItem[] {
  const splitItems: IClaimSplitItem[] = items.map((item) => ({
    item,
    value: item.amount,
    total: item.lineItem.amount,
  }));
  return percentBasedSplit(totalContributionAmount, splitItems);
}

export function divideContributionAmountAcrossItems(
  items: IADACodeClaim[],
  totalContributionAmount: number
): IADACodeClaim[] {
  return determineContributionPerItem(items, totalContributionAmount).map(
    (item) => ({ ...item.item, amount: item.value })
  );
}

interface ISplitSegment {
  total: number;
  value: number;
}

export function percentBasedSplit<T extends ISplitSegment>(
  requestAmount: number,
  segments: T[]
): T[] {
  const total = sum(segments.map((segment) => segment.total));
  const splitAmount = requestAmount >= total ? total : requestAmount;
  if (splitAmount >= total) {
    // eslint-disable-next-line no-console
    console.warn('Split amount greater than total');
  }
  const valuedSegments: T[] = [];
  const leftOver = segments.reduce((remaining, segment, index) => {
    const isLast = index >= segments.length - 1;
    const amount = isLast
      ? roundTo2Decimals(remaining)
      : determineSegmentAmount(segment, total, splitAmount, remaining);
    const safeAmount = amount >= total ? amount - 0.01 : amount;
    valuedSegments.push({
      ...segment,
      value: safeAmount,
    });
    return remaining - safeAmount;
  }, splitAmount);

  if (leftOver > 0) {
    // eslint-disable-next-line no-console
    console.warn(`Left over amount from split: ${leftOver}`);
  }

  return valuedSegments;
}

function determineSegmentAmount(
  segment: ISplitSegment,
  total: number,
  splitAmount: number,
  remaining: number
): number {
  const percent = segment.total / total;
  const rawAmount = roundTo2Decimals(splitAmount * percent);
  return rawAmount > remaining ? remaining : rawAmount;
}
