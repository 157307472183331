export enum RootNodes {
  Doc = 'doc',
}

export enum BlockNodes {
  Blockquote = 'blockquote',
  BulletedList = 'bullet_list',
  CodeBlock = 'code_block',
  Column = 'column',
  ColumnBlock = 'columnBlock',
  OrderedList = 'ordered_list',
  Heading = 'heading',
  HorizontalRule = 'horizontal_rule',
  Paragraph = 'paragraph',
  Image = 'image',
  ImageUploading = 'imageUploading',
  Video = 'video',
  VideoEmbed = 'videoEmbed',
  Table = 'table',
  TableCell = 'table_cell',
  TableHeader = 'table_header',
  TableRow = 'table_row',
  TableWrapper = 'tableWrapper',
  VideoUploading = 'videoUploading',
  TableOfContents = 'tableOfContents',
}

export enum ListNodes {
  ListItem = 'list_item',
}

export enum InlineNodes {
  HardBreak = 'hard_break',
  HardBreakDeprecated = 'hardBreak',
  Link = 'link',
  Mention = 'mention',
  MentionAutocomplete = 'mentionAutocomplete',
  SnippetAutocomplete = 'snippetAutocomplete',
  Text = 'text',
}

export enum TextFormatting {
  Badge = 'badge',
  Bold = 'bold',
  Code = 'code',
  Highlight = 'highlight',
  Italic = 'italic',
  Strike = 'strike',
  TextStyle = 'textStyle',
  Underline = 'underline',
}

export enum EditorPlugins {
  History = 'history',
  Redo = 'redo',
  Undo = 'undo',
}

export type EditorExtensions =
  | EditorPlugins
  | TextFormatting
  | BlockNodes
  | RootNodes
  | InlineNodes
  | ListNodes;

export enum CoreMenuButton {
  Undo = 'undoMenuButton',
  Redo = 'redoMenuButton',
  Bold = 'boldMenuButton',
  Italic = 'italicMenuButton',
  Strike = 'strikeMenuButton',
  Underline = 'underlineMenuButton',
  Blockquote = 'blockquoteMenuButton',
  BulletedList = 'bulletedListMenuButton',
  Code = 'codeMenuButton',
  CodeBlock = 'codeBlockMenuButton',
  Heading = 'headingMenuButton',
  HorizontalRule = 'horizontalRuleMenuButton',
  OrderedList = 'orderedListMenuButton',
  LeftAlign = 'leftAlignMenuButton',
  RightAlign = 'rightAlignMenuButton',
  CenterAlign = 'centerAlignMenuButton',
  JustifyAlign = 'justifyAlignMenuButton',
}

export enum NodeGroup {
  Block = 'block',
  Inline = 'inline',
  List = 'list',
}
