import { NgModule } from '@angular/core';
import { type Routes, RouterModule } from '@angular/router';
import { CreateAppointmentComponent } from './pages/create-appointment/create-appointment.component';
import { PagesModule } from './pages/pages.module';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'create',
        data: { title: 'Schedule Appointment' },
        component: CreateAppointmentComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), PagesModule],
  exports: [RouterModule],
})
export class NgAppointmentSchedulingRoutingModule {}
