import { ChartType } from '@principle-theorem/reporting';
import { BaseChart } from './base-chart';
import { type IChartData, ChartOptions } from './chart-builder';

export class BarChart extends BaseChart {
  data: IChartData = {
    type: ChartType.Bar,
    options: ChartOptions.init({
      legend: { position: 'none' },
    }),
  };
}

export const BAR_CHART_OPTIONS = {
  height: 300,
  bars: 'horizontal',
  vAxis: { title: '' },
};
