import { IntegrationStorage, IntegrationType } from './integrations';

export interface ISendgridIntegrationData {
  testModeEnabled: boolean;
}

export class SendgridIntegrationStorage extends IntegrationStorage<ISendgridIntegrationData> {
  constructor() {
    super(IntegrationType.Sendgrid);
  }
}
