import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  type IPricingRule,
  type IPricingRuleItem,
  PricingRuleType,
} from '@principle-theorem/principle-core/interfaces';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-service-pricing-rule-item-select',
    templateUrl: './service-pricing-rule-item-select.component.html',
    styleUrls: ['./service-pricing-rule-item-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ServicePricingRuleItemSelectComponent {
  pricingRule$ = new ReplaySubject<IPricingRule>(1);
  trackByRuleItems = TrackByFunctions.uniqueId<IPricingRuleItem>();
  @Input() disabled = false;
  @Output() resetPrice = new EventEmitter<void>();
  @Output() updateSelectedRuleItem = new EventEmitter<IPricingRule>();

  @Input()
  set pricingRule(pricingRule: IPricingRule) {
    if (pricingRule) {
      this.pricingRule$.next(pricingRule);
    }
  }

  updateSelected(pricingRule: IPricingRule, ruleItemUid: string): void {
    this.updateSelectedRuleItem.next({
      ...pricingRule,
      ruleItems: pricingRule.ruleItems.map((ruleItem) => {
        if (ruleItem.uid !== ruleItemUid) {
          return {
            ...ruleItem,
            selected: false,
          };
        }

        return {
          ...ruleItem,
          selected: true,
        };
      }),
    });
  }

  shouldShowPricingRules(pricingRuleType: PricingRuleType): boolean {
    return [PricingRuleType.StairStep, PricingRuleType.Tiered].includes(
      pricingRuleType
    );
  }

  isAutoSelected(pricingRuleType: PricingRuleType): boolean {
    return pricingRuleType === PricingRuleType.Tiered;
  }
}
