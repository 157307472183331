import { cloneDeep, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { type OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { isObject } from '../common';
import { asColRef } from '../firebase/firestore/collection';
import { asDocRef, isDocRef } from '../firebase/firestore/document';
import { isColRef } from '../firebase/firestore/collection';
import {
  type CollectionReference,
  Timestamp,
  DocumentReference,
} from '../firebase/firestore/adaptor';
import { isDate, toTimestamp } from '../time/time';
import { type ISerialiser } from './serialiser';
import { type NotEmpty } from '../utility-types';
import { isTimestamp } from '../firebase/timestamp';

export function isColRefPath(path: string): boolean {
  return path.split('/').length % 2 !== 0;
}

type IDocumentReference = {
  id: string;
  path: string;
} & ({ type: 'document' } | { collection: unknown });

type ICollectionReference = {
  id: string;
  path: string;
} & ({ type: 'collection' } | { doc: unknown });

export interface ISerialisedDocumentReference {
  referenceValue: string;
  id: string;
}

export function isSerialisedDocumentReference(
  data: unknown
): data is ISerialisedDocumentReference {
  return (
    isObject(data) &&
    isString(data.referenceValue) &&
    isString(data.id) &&
    !isColRefPath(data.referenceValue)
  );
}

export interface ISerialisedCollectionReference {
  referenceValue: string;
}

export function isSerialisedCollectionReference(
  data: unknown
): data is ISerialisedCollectionReference {
  return (
    isObject(data) &&
    isString(data.referenceValue) &&
    isColRefPath(data.referenceValue)
  );
}

export interface ISerialisedTimestamp {
  timestampValue: string;
  /**
   * @TJS-type integer
   */
  seconds: number;
  /**
   * @TJS-type integer
   */
  nanoseconds: number;
}

export function toSerialisedTimestamp(data: Timestamp): ISerialisedTimestamp {
  return {
    timestampValue: data.toDate().toISOString(),
    seconds: data.seconds,
    nanoseconds: data.nanoseconds,
  };
}

function isDeprecatedSerialisedTimestamp(
  data: unknown
): data is ISerialisedTimestamp {
  return isObject(data) && isString(data.timestampValue);
}

export function isSerialisedTimestamp(
  data: unknown
): data is ISerialisedTimestamp {
  return (
    isObject(data) &&
    isString(data.timestampValue) &&
    isNumber(data.seconds) &&
    isNumber(data.nanoseconds)
  );
}

export interface ISerialisedMoment {
  momentValue: string;
}

export interface ISerialisedDate {
  dateValue: string;
}

export function isSerialisedMoment(data: unknown): data is ISerialisedMoment {
  return isObject(data) && isString(data.momentValue);
}

export function isSerialisedDate(data: unknown): data is ISerialisedDate {
  return isObject(data) && isString(data.dateValue);
}

export type ISerialisedArray<T> = SerialisedData<T>[];
export type IUnserialisedArray<T> = UnserialisedData<T>[];

export type SerialisedData<T> = T extends IDocumentReference
  ? ISerialisedDocumentReference
  : T extends ISerialisedDocumentReference
    ? ISerialisedDocumentReference
    : T extends ICollectionReference
      ? ISerialisedCollectionReference
      : T extends ISerialisedCollectionReference
        ? ISerialisedCollectionReference
        : T extends Timestamp
          ? ISerialisedTimestamp
          : T extends ISerialisedTimestamp
            ? ISerialisedTimestamp
            : T extends moment.Moment
              ? ISerialisedMoment
              : T extends ISerialisedMoment
                ? ISerialisedMoment
                : T extends Date
                  ? ISerialisedDate
                  : T extends ISerialisedDate
                    ? ISerialisedDate
                    : // eslint-disable-next-line @typescript-eslint/ban-types
                      T extends Function
                      ? never
                      : T extends (infer E)[]
                        ? ISerialisedArray<E>
                        : T extends NotEmpty<T>
                          ? {
                              [K in keyof T]: SerialisedData<T[K]>;
                            }
                          : T;

export type UnserialisedData<T> = T extends ISerialisedDocumentReference
  ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
    DocumentReference<any>
  : T extends IDocumentReference
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      DocumentReference<any>
    : T extends ISerialisedCollectionReference
      ? CollectionReference<unknown>
      : T extends CollectionReference<unknown>
        ? CollectionReference<unknown>
        : T extends ISerialisedTimestamp
          ? Timestamp
          : T extends Timestamp
            ? Timestamp
            : T extends ISerialisedMoment
              ? moment.Moment
              : T extends moment.Moment
                ? moment.Moment
                : T extends ISerialisedDate
                  ? Date
                  : T extends Date
                    ? Date
                    : T extends (infer E)[]
                      ? IUnserialisedArray<E>
                      : T extends NotEmpty<T>
                        ? {
                            [K in keyof T]: UnserialisedData<T[K]>;
                          }
                        : T;

export function serialise<T>(data: T): SerialisedData<T> {
  return new PrimitiveObjectSerialiser().serialise(data);
}

export function unserialise<T>(
  data: SerialisedData<T> | T
): UnserialisedData<T> {
  return new PrimitiveObjectSerialiser().unserialise(data);
}

export function serialise$<T>(): OperatorFunction<T, SerialisedData<T>> {
  return map((data: T) => {
    return new PrimitiveObjectSerialiser().serialise(data);
  });
}

export function unserialise$<T>(): OperatorFunction<T, UnserialisedData<T>> {
  return map((data: T) => {
    return new PrimitiveObjectSerialiser().unserialise(data);
  });
}

export class PrimitiveObjectSerialiser implements ISerialiser {
  serialise<R>(data: R): SerialisedData<R> {
    return this.serialiseChild(data) as SerialisedData<R>;
  }

  unserialise<R>(data: SerialisedData<R> | R): UnserialisedData<R> {
    return this.unserialiseChild(cloneDeep(data)) as UnserialisedData<R>;
  }

  serialiseChildren<T>(
    children: T[],
    extendedSerialiseFn?: (childItem: T) => unknown
  ): unknown[] {
    return children.map((data) =>
      this.serialiseChild<T>(data, extendedSerialiseFn)
    );
  }

  serialiseChild<T>(
    childItem: T,
    extendedSerialiseFn?: (childItem: T) => unknown
  ): unknown {
    if (extendedSerialiseFn) {
      childItem = extendedSerialiseFn(childItem) as T;
    }
    if (!isObject(childItem)) {
      return childItem;
    }

    if (isTimestamp(childItem)) {
      return toSerialisedTimestamp(childItem);
    }

    if (moment.isMoment(childItem)) {
      return {
        momentValue: childItem.format(),
      };
    }

    if (isDate(childItem)) {
      return {
        dateValue: childItem.toISOString(),
      };
    }

    if (isDocRef(childItem)) {
      return {
        referenceValue: childItem.path,
        id: childItem.id,
      };
    }

    if (isColRef(childItem)) {
      return {
        referenceValue: childItem.path,
      };
    }

    if (Array.isArray(childItem)) {
      return this.serialiseChildren(childItem, extendedSerialiseFn);
    }

    const child: Record<string, unknown> = {};

    Object.entries(childItem).map(([key, value]) => {
      child[key] = this.serialiseChild(value as T, extendedSerialiseFn);
    });

    return child;
  }

  unserialiseChild<T>(
    childItem: T,
    extendedUnserialiseFn?: (childItem: T) => unknown
  ): unknown {
    if (extendedUnserialiseFn) {
      childItem = extendedUnserialiseFn(childItem) as T;
    }
    if (!isObject(childItem)) {
      return childItem;
    }

    if (isSerialisedTimestamp(childItem)) {
      return new Timestamp(childItem.seconds, childItem.nanoseconds);
    }

    if (isDeprecatedSerialisedTimestamp(childItem)) {
      return toTimestamp(moment(childItem.timestampValue));
    }

    if (isSerialisedMoment(childItem)) {
      return moment.parseZone(childItem.momentValue);
    }

    if (isSerialisedDate(childItem)) {
      return new Date(childItem.dateValue);
    }

    if (isSerialisedCollectionReference(childItem)) {
      return asColRef(childItem.referenceValue);
    }

    if (isSerialisedDocumentReference(childItem)) {
      return asDocRef(childItem.referenceValue);
    }

    if (Array.isArray(childItem)) {
      return this._unserialiseChildren(childItem);
    }

    const child: Record<string, unknown> = {};

    Object.entries(childItem).map(([key, value]) => {
      child[key] = this.unserialiseChild(value);
    });

    return child;
  }

  protected _unserialiseChildren<T>(children: T[]): unknown[] {
    return children.map((data) => this.unserialiseChild(data));
  }
}

export interface IAvroSerialisedRawInlineNodes {
  rawInlineNodesValue: {
    text: string;
    json: string;
  };
}

export interface IAvroSerialisedVersionedSchema {
  versionedSchemaValue: {
    text: string;
    json: string;
  };
}

export interface IAvroSerialisedRawSchema {
  rawSchemaValue: {
    text: string;
    json: string;
  };
}
