import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreatmentStepsDisplayService } from '../treatment-steps-editor/treatment-steps-display.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'pr-treatment-step-sort-by-menu',
    imports: [
        CommonModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
    ],
    templateUrl: './treatment-step-sort-by-menu.component.html',
    styleUrl: './treatment-step-sort-by-menu.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreatmentStepSortByMenuComponent {
  public stepDisplay = inject(TreatmentStepsDisplayService);
}
