<div class="box-border flex h-full min-h-full w-full flex-col">
  <ng-template #loading>
    <mat-progress-bar mode="indeterminate" />
  </ng-template>

  <ng-container *ngrxLet="items$ as items">
    <div
      *ngIf="!items || items.length === 0; else selectChart"
      class="flex flex-col items-center justify-center"
    >
      <div
        class="charting-empty box-border flex h-full min-h-full w-full flex-col items-center justify-center gap-4"
      >
        <pr-empty-state image="list" title="clinical charts" />
        <pt-loader-button
          type="flat"
          color="primary"
          [loading]="(loaded$ | ngrxPush) === false"
          (click)="addFirstChart()"
        >
          Add First Chart
        </pt-loader-button>
      </div>
    </div>
  </ng-container>

  <ng-template #selectChart>
    <div class="charting-history-card mat-elevation-z1 flex">
      <pr-history-list
        [items]="items$ | ngrxPush"
        [selected]="selectedItem$ | ngrxPush"
        (selectedChange)="selectChartHistory($event)"
      />
      <ng-container *ngrxLet="isPerioChart$ as isPerioChart">
        <div class="layout-margin flex-1" *ngIf="loaded$ | async; else loading">
          <pr-perio-chart-ui
            *ngIf="isPerioChart; else clinicalChart"
            [showHistorySelector]="false"
          />
          <ng-template #clinicalChart>
            <pr-chart-ui [showHistorySelector]="false" />
          </ng-template>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>
