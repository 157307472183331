<div class="flex">
  <div class="flex flex-wrap items-center gap-2">
    <button
      mat-raised-button
      class="icon"
      *ngFor="let item of items$ | async; trackBy: trackByItem; let i = index"
      [matTooltip]="item.name"
      (click)="addChartedItem(item)"
    >
      <div class="flex flex-col items-center justify-center">
        <mat-icon
          *ngIf="item.display?.icon.slug"
          [ngStyle]="{ color: item.display.colour.value }"
          [svgIcon]="item.display.icon.slug"
         />
        <span
          *ngIf="hotkeyMap[i] as hotkey"
          class="mat-caption hotkey-highlight"
        >
          {{ hotkey }}
        </span>
      </div>
    </button>
  </div>
</div>
