import { ILogger } from '@principle-theorem/developer-tools';
import { getSchemaText } from '@principle-theorem/editor';
import {
  CollectionGroup,
  IInteractionV2,
  IPractice,
  type IAppointment,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  FirestoreMigrate,
  Timestamp,
  WithRef,
  asyncForEach,
  bufferedQuery$,
  collectionGroupQuery,
  multiConcatMap,
  toQuery,
  where,
} from '@principle-theorem/shared';
import { Appointment } from '../models/appointment/appointment';

export class DeduplicateAppointmentInteractions {
  constructor(private _logger: ILogger) {}

  async run(
    practiceRef: DocumentReference<IPractice>,
    from: Timestamp
  ): Promise<void> {
    await bufferedQuery$(
      toQuery(
        collectionGroupQuery<IAppointment>(CollectionGroup.Appointments),
        where('practice.ref', '==', practiceRef),
        where('event.from', '>=', from)
      ),
      100,
      'event.from'
    )
      .pipe(
        multiConcatMap(async (appointment) => {
          const existingInteractions = await Firestore.getDocs(
            Appointment.interactionCol(appointment)
          );
          const toKeepInteractions: WithRef<IInteractionV2>[] = [];
          const duplicateInteractions: WithRef<IInteractionV2>[] = [];

          existingInteractions.map((interaction) => {
            if (
              toKeepInteractions.some((toKeep) => {
                const isSameCreateAt = toKeep.createdAt.isEqual(
                  interaction.createdAt
                );
                const isSameTitle =
                  getSchemaText(toKeep.title) ===
                  getSchemaText(interaction.title);
                const isSameContent =
                  getSchemaText(toKeep.content) ===
                  getSchemaText(interaction.content);
                return isSameCreateAt && isSameTitle && isSameContent;
              })
            ) {
              duplicateInteractions.push(interaction);
              return;
            }
            toKeepInteractions.push(interaction);
          });

          if (duplicateInteractions.length) {
            this._logger.info(
              `Found ${duplicateInteractions.length} duplicate interactions for appointment ${appointment.ref.id}`
            );
            this._logger.info(
              `Keeping ${toKeepInteractions.length} interactions and deleting ${duplicateInteractions.length} interactions`
            );
            await asyncForEach(duplicateInteractions, (interaction) =>
              FirestoreMigrate.deleteDoc(interaction.ref)
            );
          }
        })
      )
      .toPromise();

    this._logger.info(`Done`);
  }
}
