import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_MEDIA_RESOURCE_TYPE = 'patientMedia';

export const PATIENT_MEDIA_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient Media List',
    description: '',
    idPrefix: PATIENT_MEDIA_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export enum CorePracticePatientMediaCategory {
  CephalometricXRay = 1,
  IntraoralXRay = 2,
  IntraoralPhoto = 3,
  PanoramicXRay = 4,
  DigitalPhoto = 5,
  Documents = 6,
}

export const CORE_PRACTICE_MEDIA_CATEGORY_TAG_NAME_MAP: Record<
  CorePracticePatientMediaCategory,
  string
> = {
  [CorePracticePatientMediaCategory.CephalometricXRay]: 'Cephalometric X-Ray',
  [CorePracticePatientMediaCategory.IntraoralXRay]: 'Intraoral X-Ray',
  [CorePracticePatientMediaCategory.IntraoralPhoto]: 'Intraoral Photo',
  [CorePracticePatientMediaCategory.PanoramicXRay]: 'Panoramic X-Ray',
  [CorePracticePatientMediaCategory.DigitalPhoto]: 'Digital Photo',
  [CorePracticePatientMediaCategory.Documents]: 'Documents',
};

export interface ICorePracticePatientMedia {
  id: string; // 3q_tCnL6AkK7Ylnkdctimg
  fileName: string; // "SLmB34dpA0CWE7hc7SLnVw.png"
  dateTaken: string; // 2024-03-02 14:27:39.890
  patientId: number;
  categoryId?: CorePracticePatientMediaCategory;
  isDeleted: boolean;
}

export function isCorePracticeMedia(
  item: unknown
): item is ICorePracticePatientMedia {
  return TypeGuard.interface<ICorePracticePatientMedia>({
    id: isString,
    fileName: isString,
    dateTaken: isString,
    patientId: isNumber,
    categoryId: TypeGuard.nilOr(
      TypeGuard.enumValue(CorePracticePatientMediaCategory)
    ),
    isDeleted: isBoolean,
  })(item);
}

export interface ICorePracticePatientMediaTranslations {}

export interface ICorePracticePatientMediaFilters {
  patientId: number;
}

const PATIENT_MEDIA_SOURCE_QUERY = `
SELECT
  Identifier AS id,
  FileName AS file_name,
  DateTaken AS date_taken,
  PatientId AS patient_id,
  CategoryId AS category_id,
  convert_to_boolean(IsDeleted) AS is_deleted
FROM tblMedia
`;

export class PatientMediaSourceEntity extends BaseSourceEntity<
  ICorePracticePatientMedia,
  ICorePracticePatientMediaTranslations,
  ICorePracticePatientMediaFilters
> {
  sourceEntity = PATIENT_MEDIA_SOURCE_ENTITY;
  entityResourceType = PATIENT_MEDIA_RESOURCE_TYPE;
  sourceQuery = PATIENT_MEDIA_SOURCE_QUERY;
  verifySourceFn = isCorePracticeMedia;

  translate(
    _data: ICorePracticePatientMedia,
    _timezone: Timezone
  ): ICorePracticePatientMediaTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientMedia): string {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientMedia): string {
    return `${data.id} - ${data.fileName}`;
  }

  getFilterData(
    data: ICorePracticePatientMedia,
    _timezone: Timezone
  ): ICorePracticePatientMediaFilters {
    return {
      patientId: data.patientId,
    };
  }
}
