export enum FormLayoutElementType {
  Flex = 'flex',
  Help = 'help',
  Div = 'div',
  Date = 'date',
  Section = 'section',
  TextArea = 'textarea',
}

export interface IBaseFormLayoutElement {
  type?: FormLayoutElementType;
  key?: string;
  notitle?: boolean;
  title?: string;
  options?: {
    flex?: string;
  };
  items?: (IParentFlexLayout | IFormLayoutElement)[];
}

export interface IRootFormLayoutElement extends IBaseFormLayoutElement {
  htmlClass?: string;
  labelHtmlClass?: string;
  items?: (IParentFlexLayout | IFormLayoutElement)[];
}

export interface IParentFlexLayout extends IBaseFormLayoutElement {
  type: FormLayoutElementType.Flex;
  options?: {
    /**
     * @default 'row'
     */
    fxLayout?: string;
    /**
     * @default '1 1 auto'
     */
    flex?: string;
    fxLayoutWrap?: string;
    fxLayoutGap?: string;
    fxLayoutAlign?: string;
  };
  items?: IChildFlexLayoutElement[];
}

export interface IChildFlexLayoutElement extends IBaseFormLayoutElement {
  options?: {
    flex?: string;
    fxFlex?: string;
    fxFlexAlign?: string;
    fxFlexOffset?: string;
    fxFlexOrder?: string;
  };
  items?: (IParentFlexLayout | IFormLayoutElement)[];
}

export interface IFormLayoutElement extends IBaseFormLayoutElement {
  items?: (
    | IFormLayoutElement
    | IFormLayoutHtml
    | IParentFlexLayout
    | IFormLayoutSubForm
  )[];
  placeholder?: string;
}

export interface IFormLayoutHtml extends IFormLayoutElement {
  type: FormLayoutElementType.Help;
  helpvalue: string;
}

export interface IFormLayoutFlex extends IFormLayoutElement {
  type: FormLayoutElementType.Flex;
  'flex-flow'?: 'row wrap' | 'row' | 'column';
}

export interface IFormLayoutSubForm extends IFormLayoutElement {
  type: FormLayoutElementType.Div;
  htmlClass?: string;
}
