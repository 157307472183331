import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  type OnDestroy,
} from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNil } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TypedFormControl } from '../forms/typed-form-group';

@Component({
  selector: 'pt-settings-menu-slide-toggle-item',
  templateUrl: './settings-menu-slide-toggle-item.component.html',
  styleUrls: ['./settings-menu-slide-toggle-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SettingsMenuSlideToggleItemComponent),
      multi: true,
    },
  ],
})
export class SettingsMenuSlideToggleItemComponent
  implements ControlValueAccessor, OnDestroy
{
  private _onDestroy$: Subject<void> = new Subject();
  private _onChange: (value?: boolean) => void;
  disabled$ = new BehaviorSubject<boolean>(false);
  emitEvent$ = new BehaviorSubject<boolean>(true);
  @Input() label: string;
  @Input() tooltip?: string;
  formCtrl = new TypedFormControl<boolean>();

  @Input()
  set disabled(disabled: BooleanInput) {
    this.disabled$.next(coerceBooleanProperty(disabled));
  }

  @Input()
  set emitEvent(emitEvent: boolean) {
    if (!isNil(emitEvent)) {
      this.emitEvent$.next(emitEvent);
    }
  }

  constructor() {
    this.formCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((newValue) => {
        if (this._onChange) {
          this._onChange(newValue);
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  writeValue(value: boolean): void {
    this.formCtrl.setValue(value ?? '', { emitEvent: this.emitEvent$.value });
  }

  registerOnChange(fn: () => void): void {
    this._onChange = fn;
  }

  registerOnTouched(_fn: () => void): void {
    //
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formCtrl.disable();
      return;
    }
    this.formCtrl.enable();
  }
}
