import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Appointment, Patient } from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  type IAppointment,
  type IPatient,
  type IPractice,
  PatientCollection,
} from '@principle-theorem/principle-core/interfaces';
import {
  type DocumentReference,
  type Timestamp,
  where,
  getDocs,
} from '@principle-theorem/shared';
import {
  collectionGroupQuery,
  isSameRef,
  type ITimePeriod,
  multiSwitchMap,
  type RequireProps,
  sortTimestampAsc,
  toTimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first, groupBy } from 'lodash';
import { from, type Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

export interface IPatientVisitRecord {
  patient: WithRef<IPatient>;
  appointment: WithRef<RequireProps<IAppointment, 'event'>>;
  isFirstAppointment: boolean;
}

export interface IPatientVisitsReportState {
  dateRange: ITimePeriod;
  records: IPatientVisitRecord[];
  isLoading: boolean;
}

export interface IPatientVisitReportRequest {
  practiceRef: DocumentReference<IPractice>;
  startDate: Timestamp;
  endDate: Timestamp;
}

@Injectable()
export class PatientVisitsReportStore extends ComponentStore<IPatientVisitsReportState> {
  readonly isLoading$ = this.select((data) => data.isLoading);
  readonly records$ = this.select((data) => data.records);
  readonly dateRange$ = this.select((data) => data.dateRange);

  readonly loadPatientVisits = this.effect(
    (request$: Observable<IPatientVisitReportRequest>) =>
      request$.pipe(
        tap((request) =>
          this.setState({
            records: [],
            isLoading: true,
            dateRange: toTimePeriod(request.startDate, request.endDate),
          })
        ),
        switchMap((reportRequest) =>
          getDocs(
            collectionGroupQuery<RequireProps<IAppointment, 'event'>>(
              PatientCollection.Appointments
            ),
            where('practice.ref', '==', reportRequest.practiceRef),
            where('event.from', '>=', reportRequest.startDate),
            where('event.from', '<=', reportRequest.endDate),
            where('status', '==', AppointmentStatus.Complete)
          )
        ),
        multiSwitchMap((appointment) =>
          from(Appointment.patient(appointment)).pipe(
            switchMap((patient) =>
              from(Patient.firstScheduledAppointment(patient)).pipe(
                map((firstAppointment) => ({
                  patient,
                  appointment,
                  isFirstAppointment: isSameRef(appointment, firstAppointment),
                }))
              )
            )
          )
        ),
        map((records) =>
          compact(
            Object.values(
              groupBy(records, (record) => record.patient.ref.path)
            ).map((record) => {
              record.sort((recordA, recordB) =>
                sortTimestampAsc(
                  recordA.appointment.event.from,
                  recordB.appointment.event.from
                )
              );
              return first(record);
            })
          )
        ),
        tap((records) =>
          this.patchState({
            records,
            isLoading: false,
          })
        )
      )
  );
}
