import { type Meta, type StoryObj } from '@storybook/angular';
import {
  type IActionListener,
  type IControl,
  type IHiddenControl,
  type IJsonControl,
  type ISelectControl,
} from './arg-type-controls';

type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export type TypedMeta<Args = object> = Meta<Args>;

type MinimumMeta<Args> = AtLeast<TypedMeta<Args>, 'title' | 'component'>;

export function createStoriesMetadata<Args>(
  data: MinimumMeta<Args>
): Meta<Args> {
  return data;
}

export function createStory<Args>(data: StoryObj<Args>): StoryObj<Args> {
  return data;
}

export type ArgType =
  | IActionListener
  | IJsonControl
  | IControl
  | IHiddenControl
  | ISelectControl<unknown>;
