import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgAppointmentModule } from '@principle-theorem/ng-appointment';
import { NgEventableModule } from '@principle-theorem/ng-eventable';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { CandidateDetailComponent } from './candidate-detail/candidate-detail.component';
import { CandidateGapTimeComponent } from './candidate-gap-time/candidate-gap-time.component';
import { GapCandidateItemComponent } from './gap-candidate-list/gap-candidate-item/gap-candidate-item.component';
import { GapCandidateListComponent } from './gap-candidate-list/gap-candidate-list.component';
import { GapCardComponent } from './gap-card/gap-card.component';
import { GapHeaderComponent } from './gap-header/gap-header.component';
import { GapListComponent } from './gap-list/gap-list.component';
import { GapOfferComponent } from './gap-offer/gap-offer.component';
import { GapWarningsComponent } from './gap-warnings/gap-warnings.component';
import { MoveAppointmentComponent } from './move-appointment/move-appointment.component';
import { WaitListWarningsComponent } from './wait-list/wait-list-warnings/wait-list-warnings.component';
import { WaitListComponent } from './wait-list/wait-list.component';

@NgModule({
  imports: [
    NgSharedModule,
    NgPrincipleSharedModule,
    NgMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    NgAppointmentModule,
    NgInteractionsModule,
    NgEventableModule,
  ],
  declarations: [
    WaitListComponent,
    GapOfferComponent,
    MoveAppointmentComponent,
    CandidateDetailComponent,
    GapWarningsComponent,
    GapListComponent,
    GapCardComponent,
    GapCandidateListComponent,
    WaitListWarningsComponent,
    CandidateGapTimeComponent,
    GapCandidateItemComponent,
    GapHeaderComponent,
  ],
  exports: [GapCardComponent, GapHeaderComponent, GapListComponent],
})
export class ComponentsModule {}
