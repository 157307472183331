import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  type IBreadcrumb,
  StorageResponseAPI,
} from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  errorNil,
  type ITimePeriod,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { DateRangeDataBuilder } from '../../../models/report/data-builders/date-range-data-builder';
import { PatientsReportType } from './patients-report-type-selector/patients-report-type-selector.component';

@Component({
    selector: 'pr-patients-reporting',
    templateUrl: './patients-reporting.component.html',
    styleUrls: ['./patients-reporting.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientsReportingComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  from = moment().startOf('day').subtract({ months: 1 });
  to = moment().endOf('day');
  practice$ = new ReplaySubject<WithRef<IPractice>>(1);
  reportType$ = new ReplaySubject<PatientsReportType>(1);
  dataBuilder: DateRangeDataBuilder;
  breadcrumbs: IBreadcrumb[] = [{ label: 'Reporting' }];
  brands$: Observable<WithRef<IBrand>[]>;
  isPatientVisitReport$: Observable<boolean>;
  isReferralSourceReport$: Observable<boolean>;

  constructor(
    private _organisation: OrganisationService,
    scope: CurrentScopeFacade,
    api: StorageResponseAPI
  ) {
    this.dataBuilder = new DateRangeDataBuilder(
      api,
      this.from,
      this.to,
      this._organisation.brand$.pipe(errorNil()),
      this.practice$.asObservable().pipe(map((practice) => [practice]))
    );
    this.brands$ = this._organisation.userBrands$;
    this.isPatientVisitReport$ = this.reportType$.pipe(
      map((reportType) => reportType === PatientsReportType.ByPatientVisitDate)
    );
    this.isReferralSourceReport$ = this.reportType$.pipe(
      map((reportType) => reportType === PatientsReportType.ByReferralSource)
    );

    scope.currentPractice$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((practice) => this.practice$.next(practice));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  updateDateRange(dateRange: ITimePeriod): void {
    this.dataBuilder.updateRange(
      toMoment(dateRange.from),
      toMoment(dateRange.to)
    );
  }
}
