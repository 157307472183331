import {
  ALL_SERVICE_CODES,
  IServiceProvider,
  IServiceCode,
  ServiceCode,
  ServiceCodeType,
  SERVICE_PROVIDER_OPTIONS,
} from '@principle-theorem/principle-core/interfaces';
import { prefixCharacters } from '@principle-theorem/shared';
import { isString } from 'lodash';

export class ServiceProviderHandler {
  static resolveServiceProviderByType(
    type?: ServiceCodeType
  ): IServiceProvider | undefined {
    return SERVICE_PROVIDER_OPTIONS.find(
      (serviceProvider) => serviceProvider.type === type
    );
  }

  static resolveServiceCode(
    type: ServiceCodeType | undefined,
    serviceCode: ServiceCode
  ): IServiceCode | undefined {
    if (!type) {
      return;
    }

    const serviceProvider = this.resolveServiceProviderByType(type);
    if (!serviceProvider) {
      return;
    }
    const code = getServiceCodeNumberString(serviceCode);
    const foundCode =
      serviceProvider.items[code] ??
      Object.values(serviceProvider.items).find(
        (item) => item.rootCode === code
      );

    if (!foundCode) {
      return;
    }

    return {
      ...foundCode,
      type,
    };
  }

  static findServiceCode(code: ServiceCode): IServiceCode | undefined {
    return ALL_SERVICE_CODES.find(
      (currentCode) =>
        getServiceCodeNumberString(currentCode.code) ===
        getServiceCodeNumberString(code)
    );
  }
}

export function getServiceCodeNumberString(
  serviceCode: number | string
): string {
  if (isString(serviceCode)) {
    return serviceCode;
  }
  if (serviceCode <= 999) {
    const to3DigitADACode = prefixCharacters('0', 3);
    return to3DigitADACode(serviceCode);
  }
  return serviceCode.toString();
}
