import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { type Timestamp, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FollowUpsService } from '../../follow-ups.service';
import { IPendingFollowUp } from '../../pending-follow-up';

enum FollowUpPage {
  Overdue = 'overdue',
  Upcoming = 'upcoming',
}

export interface IAppointmentFollowUp {
  appointment: WithRef<IAppointment>;
  patient: WithRef<IPatient>;
  lastContactedAt?: Timestamp;
}

@Component({
  selector: 'pr-follow-ups-dashboard',
  templateUrl: './follow-ups-dashboard.component.html',
  styleUrls: ['./follow-ups-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FollowUpsDashboardComponent {
  isEmpty$: Observable<boolean>;
  followUps$: Observable<IPendingFollowUp[]>;
  selected = signal(FollowUpPage.Overdue);

  constructor(public followUpService: FollowUpsService) {
    this.followUps$ = toObservable(this.selected).pipe(
      switchMap((selected) =>
        selected === FollowUpPage.Overdue
          ? followUpService.existingFollowUps$
          : followUpService.upcomingFollowUps$
      )
    );

    this.isEmpty$ = this.followUps$.pipe(map((followUps) => !followUps.length));
  }

  showOverdue(): void {
    this.selected.set(FollowUpPage.Overdue);
  }

  showUpcoming(): void {
    this.selected.set(FollowUpPage.Upcoming);
  }
}
