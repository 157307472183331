import { ICustomMappings } from '@principle-theorem/principle-core/interfaces';
import { AppointmentRoomToPractionerMappingHandler } from '../exact/destination/mappings/appointment-room-to-practitioner';
import { ExactContactMappingHandler } from '../exact/destination/mappings/contact-to-referral-source';
import { ExactFeeScheduleMappingHandler } from '../exact/destination/mappings/fee-schedules';
import { ExactItemCodeToConditionMappingHandler } from '../exact/destination/mappings/item-code-to-condition';
import { ExactItemCodeToTreatmentMappingHandler } from '../exact/destination/mappings/item-code-to-treatment';
import { ExactItemCodeMappingHandler } from '../exact/destination/mappings/item-codes';
import { ExactStafferMappingHandler } from '../exact/destination/mappings/staff';
import { ExactStaffToUserMappingHandler } from '../exact/destination/mappings/staff-to-user';
import { BaseCustomMappingsHandler } from '../mappings/base-custom-mappings-handler';

export const DENTRIX_MAPPINGS: ICustomMappings = {
  metadata: {
    label: 'Dentrix Custom Mappings',
    description: '',
  },
};

export class DentrixCustomMappingsHandler extends BaseCustomMappingsHandler {
  migrationType = 'Dentrix';
  migrationVersion = 'v1';
  customMapping = DENTRIX_MAPPINGS;

  mappingHandlers = [
    new ExactItemCodeMappingHandler(),
    new ExactContactMappingHandler(),
    new ExactStaffToUserMappingHandler(),
    new ExactStafferMappingHandler(),
    new ExactItemCodeToTreatmentMappingHandler(),
    new ExactItemCodeToConditionMappingHandler(),
    new ExactFeeScheduleMappingHandler(),
    new AppointmentRoomToPractionerMappingHandler(),
  ];
}
