import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPatientModule } from '@principle-theorem/ng-patient';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { MomentModule } from 'ngx-moment';
import { EventParticipantLinkComponent } from './event-participant-link/event-participant-link.component';
import { EventParticipantsComponent } from './event-participants/event-participants.component';
import { EventTimeComponent } from './event-time/event-time.component';
import { EventableCardComponent } from './eventable-card/eventable-card.component';
import { EventableHeaderIconDirective } from './eventable-header-template/eventable-header-icon.directive';
import { EventableHeaderTemplateComponent } from './eventable-header-template/eventable-header-template.component';
import { EventableHeaderTitleDirective } from './eventable-header-template/eventable-header-title.directive';
import { EditStafferRosterDayDialogComponent } from './eventable-header/edit-staffer-roster-day-dialog/edit-staffer-roster-day-dialog.component';
import { EditStafferRosterDayLineItemComponent } from './eventable-header/edit-staffer-roster-day-line-item/edit-staffer-roster-day-line-item.component';
import { EventableHeaderComponent } from './eventable-header/eventable-header.component';
import { EventableMenuComponent } from './eventable-menu/eventable-menu.component';
import { EventableNextStageComponent } from './eventable-next-stage/eventable-next-stage.component';
import { EventableTimelineComponent } from './eventable-timeline/eventable-timeline.component';
import { EnableExpansionPanelHeaderActionsDirective } from './expansion-panel/enable-expansion-panel-header-actions.directive';
import { ExpansionDisableDirective } from './expansion-panel/expansion-disable.directive';
import { InteractiveTimelineDayLineComponent } from './interactive-timeline-day-line/interactive-timeline-day-line.component';
import { InteractiveTimelineDayMenuComponent } from './interactive-timeline-day-menu/interactive-timeline-day-menu.component';
import { InteractiveTimelineHeaderComponent } from './interactive-timeline-header/interactive-timeline-header.component';
import { InteractiveTimelineNowlineComponent } from './interactive-timeline-nowline/interactive-timeline-nowline.component';
import { InteractiveTimelineUnitsComponent } from './interactive-timeline-units/interactive-timeline-units.component';
import { InteractiveTimelineDeadzoneComponent } from './interactive-timeline/interactive-timeline-deadzone/interactive-timeline-deadzone.component';
import { InteractiveTimelineDragTooltipComponent } from './interactive-timeline/interactive-timeline-drag-tooltip/interactive-timeline-drag-tooltip.component';
import { InteractiveTimelineDragareaComponent } from './interactive-timeline/interactive-timeline-dragarea/interactive-timeline-dragarea.component';
import { InteractiveTimelineDropzoneComponent } from './interactive-timeline/interactive-timeline-dropzone/interactive-timeline-dropzone.component';
import { InteractiveTimelineGridlinesComponent } from './interactive-timeline/interactive-timeline-gridlines/interactive-timeline-gridlines.component';
import { InteractiveTimelineNodeComponent } from './interactive-timeline/interactive-timeline-node/interactive-timeline-node.component';
import { InteractiveTimelineComponent } from './interactive-timeline/interactive-timeline.component';
import { TimelineAppointmentNotificationsComponent } from './timeline-appointment-notifications/timeline-appointment-notifications.component';
import { TimelineEventDisplayComponent } from './timeline-event-display/timeline-event-display.component';
import { TimelineGapTooltipComponent } from './timeline-gap-tooltip/timeline-gap-tooltip.component';
import { TimelinePendingGapBadgeComponent } from './timeline-pending-gap-badge/timeline-pending-gap-badge.component';
import { GenericTimelineTooltipComponent } from './timeline-tooltip/generic-timeline-tooltip/generic-timeline-tooltip.component';
import { TimelineAppointmentTooltipComponent } from './timeline-tooltip/timeline-appointment-tooltip/timeline-appointment-tooltip.component';
import { TimelineTooltipComponent } from './timeline-tooltip/timeline-tooltip.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  imports: [
    CommonModule,
    NgMaterialModule,
    MomentModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    NgPatientModule,
    NgInteractionsModule,
    NgxSkeletonLoaderModule,
    TimelineTooltipComponent,
    TimelinePendingGapBadgeComponent,
    GenericTimelineTooltipComponent,
    TimelineGapTooltipComponent,
    TimelineAppointmentTooltipComponent,
    TimelineAppointmentNotificationsComponent,
  ],
  declarations: [
    EventableCardComponent,
    EventParticipantsComponent,
    EventTimeComponent,
    EventableHeaderComponent,
    EventableHeaderIconDirective,
    EventableHeaderTemplateComponent,
    EventableHeaderTitleDirective,
    EventableMenuComponent,
    EventableNextStageComponent,
    EnableExpansionPanelHeaderActionsDirective,
    ExpansionDisableDirective,
    TimelineEventDisplayComponent,
    EventableTimelineComponent,
    InteractiveTimelineComponent,
    InteractiveTimelineNodeComponent,
    InteractiveTimelineDropzoneComponent,
    InteractiveTimelineDragareaComponent,
    InteractiveTimelineHeaderComponent,
    InteractiveTimelineUnitsComponent,
    InteractiveTimelineNowlineComponent,
    InteractiveTimelineDragTooltipComponent,
    InteractiveTimelineDeadzoneComponent,
    EventParticipantLinkComponent,
    InteractiveTimelineGridlinesComponent,
    EditStafferRosterDayDialogComponent,
    EditStafferRosterDayLineItemComponent,
    InteractiveTimelineDayMenuComponent,
    InteractiveTimelineDayLineComponent,
  ],
  exports: [
    EventableCardComponent,
    EventParticipantsComponent,
    EventTimeComponent,
    EventableHeaderComponent,
    EventableHeaderIconDirective,
    EventableHeaderTemplateComponent,
    EventableHeaderTitleDirective,
    EventableMenuComponent,
    EventableNextStageComponent,
    EnableExpansionPanelHeaderActionsDirective,
    ExpansionDisableDirective,
    TimelineEventDisplayComponent,
    EventableTimelineComponent,
    EventParticipantLinkComponent,
    GenericTimelineTooltipComponent,
    TimelineAppointmentTooltipComponent,
    TimelineGapTooltipComponent,
  ],
})
export class ComponentsModule {}
