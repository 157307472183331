import {
  type ICustomReportChart,
  type ICustomReportColumn,
  type ICustomReportFilter,
} from '@principle-theorem/principle-core/interfaces';
import {
  QueryFactory,
  getMeasureQueries,
  resolveCanBeChartedProperty,
  type IReportingQuery,
} from '@principle-theorem/reporting';
import { compact, flatten } from 'lodash';
import {
  ReportBuilderDataSource,
  type IReportBuilderDataSource,
} from './report-builder-data-source';

export class ReportBuilderQueryBuilder {
  static buildQuery(
    dataSource: IReportBuilderDataSource,
    columns: ICustomReportColumn[],
    charts: ICustomReportChart[],
    filters: ICustomReportFilter[],
    editMode: boolean
  ): IReportingQuery | undefined {
    return QueryFactory.fromTable(dataSource.factTable.table)
      .merge([
        dataSource.factTable.query,
        ...this.getTableQueries(columns, dataSource, editMode),
        ...this.getChartQueries(charts, dataSource, editMode),
        ...this.getFilterQueries(filters, dataSource, editMode),
      ])
      .get();
  }

  static getFilterQueries(
    filters: ICustomReportFilter[],
    dataSource: IReportBuilderDataSource,
    editMode: boolean
  ): IReportingQuery[] {
    if (editMode) {
      return [];
    }
    return compact(
      filters.map((filter) => {
        const property = resolveCanBeChartedProperty(
          dataSource.factTable,
          filter.id
        );
        if (!property) {
          return;
        }
        const query = property.measure.filterAccessor(filter.values);
        if (!query) {
          return;
        }
        return QueryFactory.fromTable(dataSource.factTable.table)
          .filter(query)
          .merge([property.measure.query])
          .get();
      })
    );
  }

  static getChartQueries(
    customCharts: ICustomReportChart[],
    dataSource: IReportBuilderDataSource,
    editMode: boolean
  ): IReportingQuery[] {
    const allCharts = dataSource.availableCharts
      .filter((chart) => !!chart.availableInEditMode)
      .map((chart) => chart.chart);

    const charts = editMode ? allCharts : customCharts;
    const filterResults = charts.map((chart) =>
      ReportBuilderDataSource.buildChart(dataSource, chart)
    );
    return flatten(
      compact(filterResults).map((result) =>
        getMeasureQueries(result.config).map(
          (measureQuery) => measureQuery.query
        )
      )
    );
  }

  static getTableQueries(
    customColumns: ICustomReportColumn[],
    dataSource: IReportBuilderDataSource,
    editMode: boolean
  ): IReportingQuery[] {
    const columnIds = customColumns.map((column) => column.id);
    const allColumns = ReportBuilderDataSource.allColumns(dataSource);
    const columns = editMode
      ? allColumns
      : allColumns.filter((column) => columnIds.includes(column.id));
    const queries = columns.map((column) => {
      const property = resolveCanBeChartedProperty(
        dataSource.factTable,
        column.id
      );
      return property?.measure.query;
    });
    return compact(queries);
  }
}
