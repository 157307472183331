import { type ICSVExport } from '@principle-theorem/ng-shared';
import { type IPractitionerIncomeReportGrouping } from '@principle-theorem/reporting';

export interface IPractitionerTransactionSummaryCSV {
  practitioner: string;
  reservedAccountCreditAmount: number;
  accountCreditAmount: number;
  discountAmount: number;
  paymentAmount: number;
  practitionerProportionAmount: number;
}

export class PractitionerTransactionSummaryToCSV
  implements
    ICSVExport<
      IPractitionerIncomeReportGrouping,
      IPractitionerTransactionSummaryCSV
    >
{
  defaultFileName = 'practitioner-transactions-summary';
  headers = [
    'Practitioner',
    'Reserved Account Credits',
    'Account Credits',
    'Discounts',
    'Payments',
    'Practitioner Proportion',
  ];

  translate(
    records: IPractitionerIncomeReportGrouping[]
  ): IPractitionerTransactionSummaryCSV[] {
    return records.map((record) => {
      return {
        practitioner: record.practitioner.name,
        reservedAccountCreditAmount:
          record.accountCredits.total.reservedAccountCreditAmount,
        accountCreditAmount: record.transactions.total.accountCreditAmount,
        discountAmount: record.transactions.total.discountAmount,
        paymentAmount: record.transactions.total.paymentAmount,
        practitionerProportionAmount:
          record.transactions.total.practitionerProportionAmount,
      };
    });
  }
}
