import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  TrackByFunctions,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import {
  ClinicalChart,
  MockMolar,
  toAllChartedRefs,
} from '@principle-theorem/principle-core';
import {
  ChartableSurface,
  CHARTABLE_SURFACES,
  Quadrant,
} from '@principle-theorem/principle-core/interfaces';
import { isChanged$, snapshot } from '@principle-theorem/shared';
import { type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { CHART_ENTITY_ID } from '../../dental-chart-svg/chart-entity-id';
import {
  AvailableSurfacesFormGroup,
  getSelectedSurfaces,
  setSelectedSurfaces,
} from './available-surfaces.formgroup';

@Component({
    selector: 'pr-available-surfaces',
    templateUrl: './available-surfaces.component.html',
    styleUrls: ['./available-surfaces.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: CHART_ENTITY_ID,
            useValue: ChartId.ChartableSurfaces,
        },
    ],
    standalone: false
})
export class AvailableSurfacesComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _surfaces$ = new ReplaySubject<ChartableSurface[]>(1);
  private _chartId: ChartId = inject(CHART_ENTITY_ID);
  trackBySurface = TrackByFunctions.variable<ChartableSurface>();
  form = new AvailableSurfacesFormGroup();
  allSurfaces = CHARTABLE_SURFACES;
  @Output() selectedSurfaces = new EventEmitter<ChartableSurface[]>();
  isUnscoped$: Observable<boolean>;

  @Input()
  set surfaces(surfaces: ChartableSurface[]) {
    if (surfaces) {
      this._surfaces$.next(surfaces);
    }
  }

  constructor(private _chartStore: ChartFacade) {
    const chart = ClinicalChart.init({
      immutable: true,
      teeth: [MockMolar(Quadrant.AdultUpperRight, 1)],
    });
    this._chartStore.loadChartSuccess(this._chartId, chart);
    this._chartStore.setChartTeeth(this._chartId, chart.teeth);

    this._formChanged$()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((surfaces) => this._surfaces$.next(surfaces));

    this._surfaces$
      .pipe(
        isChanged$(),
        tap((surfaces) => void this._updateChartSelected(surfaces)),
        tap((surfaces) => setSelectedSurfaces(this.form, surfaces)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((surfaces) => this.selectedSurfaces.emit(surfaces));

    this.isUnscoped$ = this._surfaces$.pipe(
      map(
        (surfaces) =>
          !surfaces.length || surfaces.includes(ChartableSurface.Unscoped)
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _formChanged$(): Observable<ChartableSurface[]> {
    return validFormGroupChanges$(this.form).pipe(
      map((data) => getSelectedSurfaces(data))
    );
  }

  private async _updateChartSelected(
    selected: ChartableSurface[]
  ): Promise<void> {
    const chart = await snapshot(
      this._chartStore.clinicalChartState$(this._chartId)
    );
    this._chartStore.setSelectedSurfaces(
      this._chartId,
      toAllChartedRefs(selected, chart)
    );
  }
}
