import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type AtLeast } from '@principle-theorem/shared';
import {
  confirmationDialogData,
  type IConfirmationDialogData,
} from '../confirm-dialog/confirm-dialog.component';
import { TypedFormControl, TypedFormGroup } from '../forms/typed-form-group';
import { TrackByFunctions } from '../track-by';

export interface ISelectDialogOption<T> {
  label: string;
  value: T;
}

export interface ISelectDialogData<T> extends IConfirmationDialogData {
  options: ISelectDialogOption<T>[];
}

export type ISelectDialogInput<T> = AtLeast<
  ISelectDialogData<T>,
  'title' | 'prompt' | 'options'
>;

interface ISelectPromptFormData<T> {
  result: T;
}

class SelectPromptFormGroup<T> extends TypedFormGroup<
  ISelectPromptFormData<T>
> {
  constructor() {
    super({
      result: new TypedFormControl<T>(undefined, [Validators.required]),
    });
  }
}

@Component({
  selector: 'pt-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectDialogComponent {
  formGroup = new SelectPromptFormGroup();
  data: ISelectDialogData<unknown>;
  trackByOption = TrackByFunctions.index<ISelectDialogOption<unknown>>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: ISelectDialogInput<unknown>,
    public dialogRef: MatDialogRef<SelectDialogComponent, unknown>
  ) {
    this.data = {
      ...confirmationDialogData(data),
      options: data.options,
    };
  }

  submit(): void {
    if (this.formGroup.invalid) {
      return;
    }
    this.dialogRef.close(this.formGroup.value.result);
  }
}
