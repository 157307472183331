import {
  MeasureFormatter,
  SchedulingEventType,
} from '@principle-theorem/principle-core/interfaces';
import { ISchedulingEventDimension } from '@principle-theorem/reporting/interfaces';
import { BaseDimensionMeasures } from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import {
  CanBeChartedProperty,
  CanDoAllProperty,
  CanQueryByTimestampProperty,
  MeasureTransformMap,
} from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { roundToDecimals } from '@principle-theorem/accounting';
import { toFloat } from '@principle-theorem/shared';

type RequiredProperties = 'ref' | 'scheduledAt';
// | 'reason';
// | 'scheduledByStaffer'
// | 'scheduledByPractice';

export class SchedulingEventDimensionMeasures
  extends BaseDimensionMeasures
  implements
    MeasureTransformMap<Pick<ISchedulingEventDimension, RequiredProperties>>
{
  get ref(): CanBeChartedProperty {
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('ref'),
        label: 'Scheduling Event Ref',
        summary: '',
      },
      docRef,
      this.buildQuery().attributes([docRef.attributePath]).get()
    );
  }

  get eventType(): CanDoAllProperty {
    const measure = this.measureRef('eventType');
    return MeasurePropertyFactory.enum<SchedulingEventType>(
      {
        id: this._pathWithPrefix('eventType'),
        label: 'Scheduling Event Type',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      'Unknown'
    );
  }

  get scheduledAt(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('scheduledAt'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('scheduledAt'),
        label: 'Scheduled At',
        summary: 'The time a scheduling event occurred.',
        formatter: MeasureFormatter.Timestamp,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  /**
   * Event Before
   */

  get eventBeforeStartTime(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('eventBefore.from'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('eventBefore.from'),
        label: 'Appointment Moved From',
        summary:
          'The time an appointment was scheduled to start, before having been moved.',
        formatter: MeasureFormatter.Timestamp,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get eventBeforeEndTime(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('eventBefore.to'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('eventBefore.to'),
        label: 'Appointment Moved From (End Time)',
        summary:
          'The time an appointment was scheduled to end, before having been moved.',
        formatter: MeasureFormatter.Timestamp,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  /**
   * Event After
   */

  get eventAfterStartTime(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('eventAfter.from'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('eventAfter.from'),
        label: 'Appointment Moved To',
        summary:
          'The time an appointment is scheduled to start, after having been moved.',
        formatter: MeasureFormatter.Timestamp,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get eventAfterEndTime(): CanQueryByTimestampProperty {
    const measure = this.measureRef(MeasurePath.timestamp('eventAfter.to'));
    return MeasurePropertyFactory.timestamp(
      {
        id: this._pathWithPrefix('eventAfter.to'),
        label: 'Appointment Moved To (End Time)',
        summary:
          'The time an appointment is scheduled to end, after having been moved.',
        formatter: MeasureFormatter.Timestamp,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  /**
   * Scheduling Conditions
   */
  get wasMovedToSameDay(): CanDoAllProperty {
    const propertyName = 'schedulingConditions.moveToSameDayDefault';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.boolean(
      {
        id: propertyName,
        label: 'Was moved to same day',
        summary:
          'Appointment was moved or adjusted but did not change day. eg, pushed back, moved forward or had its duration adjusted.',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get wasFillingGap(): CanDoAllProperty {
    const propertyName = 'schedulingConditions.fillGapDefault';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.boolean(
      {
        id: propertyName,
        label: 'Was moved to fill a gap',
        summary:
          'Whether the appointment was moved to fill a gap via the gap filling workflow or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get daysBeforeNewAppointmentDate(): CanBeChartedProperty {
    const propertyName =
      'schedulingConditions.hrsBeforeAppointmentWasScheduled';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Days before the new/rescheduled appointment',
        summary:
          'How many days before the new appointment time from a new/rescheduled appointment',
        formatter: MeasureFormatter.Custom,
        formatterValue: (value) => {
          const days = roundToDecimals(toFloat(value), 1);
          return days < 0
            ? `${Math.abs(days)} days after`
            : `${days} days before`;
        },
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      undefined,
      (hours) => roundToDecimals(hours / 24, 1)
    );
  }

  get daysBeforeOriginalAppointmentDate(): CanBeChartedProperty {
    const propertyName =
      'schedulingConditions.hrsBeforeAppointmentScheduledFor';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: propertyName,
        label: 'Days before the original appointment',
        summary:
          'How many days before the original appointment time the appointment was rescheduled/cancelled',
        formatter: MeasureFormatter.Custom,
        formatterValue: (value) => {
          const days = roundToDecimals(toFloat(value), 1);
          return days < 0
            ? `${Math.abs(days)} days after`
            : `${days} days before`;
        },
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      undefined,
      (hours) => roundToDecimals(hours / 24, 1)
    );
  }

  // get scheduledByPractice(): CanDoAllProperty {
  //   const measure = this.measureRef('practice.name');
  //   return MeasurePropertyFactory.docRef(
  //     {
  //       id: this._pathWithPrefix('practice.name'),
  //       label: 'Practice Name',
  //     },
  //     measure,
  //     this.buildQuery().attributes([measure.attributePath]).get()
  //   );
  // }

  // TODO: Implement the rest of the properties
  // reason?: CanBeChartedProperty | undefined;
  // scheduledByStaffer?: CanBeChartedProperty | undefined;
}
