import { type ICSVExport } from '@principle-theorem/ng-shared';
import { type ITransactionSourceSummary } from '@principle-theorem/reporting';
import { startCase } from 'lodash';

export interface IReconciliationTransactionSummaryCSV {
  transactionType: string;
  totalPending: number;
  totalComplete: number;
}

export class ReconciliationTransactionSummaryToCSV
  implements
    ICSVExport<ITransactionSourceSummary, IReconciliationTransactionSummaryCSV>
{
  defaultFileName = 'reconciliation-transactions-summary';
  headers = ['Transaction Type', 'Total Pending', 'Total Complete'];

  translate(
    records: ITransactionSourceSummary[]
  ): IReconciliationTransactionSummaryCSV[] {
    return records.map((record) => ({
      transactionType: startCase(record.name),
      totalPending: record.total.pending,
      totalComplete: record.total.complete,
    }));
  }
}
