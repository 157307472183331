<div cdkDropListGroup>
  <div class="flex justify-between gap-4">
    <div class="grow">
      <div class="layout-margin flex flex-col items-stretch gap-4">
        <ng-container
          *ngFor="
            let treatmentStep of multiTreatmentConfig.steps;
            let index = index;
            trackBy: trackByStep
          "
        >
          <div class="drag-container flex-1">
            <div class="treatment-step-title flex items-center justify-between">
              <h2 class="flex-1">{{ treatmentStep.name }}</h2>
              <button
                mat-icon-button
                [matMenuTriggerFor]="treatmentStepMenu"
                ptIntercomTarget="treatment-steps.menu-button"
              >
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #treatmentStepMenu="matMenu">
                <ng-template matMenuContent>
                  <button
                    mat-menu-item
                    (click)="editTreatmentStep(treatmentStep)"
                    ptIntercomTarget="treatment-steps.edit-button"
                  >
                    <mat-icon>edit</mat-icon>
                    Edit
                  </button>
                  <button
                    mat-menu-item
                    color="warn"
                    (click)="removeTreatmentStep(treatmentStep)"
                    ptIntercomTarget="treatment-steps.delete-button"
                  >
                    <mat-icon>delete</mat-icon>
                    Delete
                  </button>
                </ng-template>
              </mat-menu>
            </div>

            <pr-relative-scheduling-summary
              *ngIf="treatmentStep.schedulingRules"
              [rules]="treatmentStep.schedulingRules"
             />

            <div
              class="treatment-list"
              cdkDropList
              [cdkDropListData]="treatmentStep.treatments"
              (cdkDropListDropped)="drop($event)"
            >
              <div
                class="treatment-box flex items-center justify-between"
                *ngFor="
                  let treatment of treatmentStep.treatments;
                  trackBy: trackByTreatment
                "
                cdkDrag
                cdkScrollable
                [cdkDragData]="treatment"
              >
                <span>{{ treatment.name }}</span>
                <div class="flex items-center">
                  <pr-increase-decrease-input
                    (increase)="increaseQuantity(treatment)"
                    (decrease)="decreaseQuantity(treatment, treatmentStep)"
                    [decreaseDisabled]="treatment.quantity < 1"
                    (mousedown)="$event.stopImmediatePropagation()"
                  >
                    <label>Qty</label>
                    {{ treatment.quantity }}
                  </pr-increase-decrease-input>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <button mat-flat-button color="primary" (click)="addTreatmentStep()">
          <mat-icon aria-label="Remove from list">add</mat-icon>
          Add New Treatment Step
        </button>
      </div>
    </div>

    <div class="grow">
      <h2>Available Treatments</h2>

      <pt-search-field
        class="layout-margin"
        ptIntercomTarget="treatment-steps.search-input"
      >
        <input
          matInput
          placeholder="Search"
          #search
          [formControl]="searchCtrl"
        />
      </pt-search-field>

      <div
        class="treatment-list layout-margin"
        cdkDropList
        [id]="treatmentOptionsId"
        [cdkDropListData]="treatmentOptions$ | async"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListEnterPredicate]="noReturnPredicate"
      >
        <div
          class="treatment-box treatment-option flex items-center justify-between"
          *ngFor="
            let treatment of treatmentOptions$ | async;
            trackBy: trackByTreatment
          "
          cdkDrag
          cdkScrollable
          [cdkDragData]="treatment"
        >
          <span>{{ treatment.name }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<pt-buttons-container padding="all-round">
  <button type="submit" mat-flat-button color="primary" (click)="save()">
    Save
  </button>
</pt-buttons-container>
