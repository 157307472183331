import {
  SerialisedMixedSchema,
  isSerialisedMixedSchema,
} from '@principle-theorem/editor';
import { isINamedDocument, isObject } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type IPatientDocument } from './patient-document';

export interface ISerialisedPatientDocument
  extends Omit<IPatientDocument, 'content'> {
  content: SerialisedMixedSchema;
}

export function isSerialisedPatientDocument(
  data: unknown
): data is IPatientDocument {
  return (
    isObject(data) &&
    isString(data.name) &&
    isINamedDocument(data.createdBy) &&
    isSerialisedMixedSchema(data.content)
  );
}
