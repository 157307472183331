import {
  Component,
  type QueryList,
  ContentChildren,
  type AfterContentInit,
  type OnDestroy,
} from '@angular/core';
import { ChartTabComponent } from '../chart-tab/chart-tab.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TrackByFunctions } from '@principle-theorem/ng-shared';

@Component({
    selector: 'pr-chart-tab-group',
    templateUrl: './chart-tab-group.component.html',
    styleUrls: ['./chart-tab-group.component.scss'],
    standalone: false
})
export class ChartTabGroupComponent implements OnDestroy, AfterContentInit {
  private _onDestroy$: Subject<void> = new Subject();
  trackByTab = TrackByFunctions.title<ChartTabComponent>();
  selected: ChartTabComponent;
  @ContentChildren(ChartTabComponent) tabs: QueryList<ChartTabComponent>;

  ngAfterContentInit(): void {
    this.tabs.changes
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this._defaultSelected());
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _defaultSelected(): void {
    this.selected = this._getDefault();
  }

  private _getDefault(): ChartTabComponent {
    const defaultValue: ChartTabComponent = this.tabs.first;
    if (!this.selected) {
      return defaultValue;
    }
    const matched: ChartTabComponent | undefined = this.tabs.find(
      (chart: ChartTabComponent) => chart.title === this.selected.title
    );
    return matched ? matched : defaultValue;
  }
}
