import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  SchedulingEvent,
  SchedulingEventSummary,
} from '@principle-theorem/principle-core';
import {
  ISchedulingEvent,
  ISchedulingEventSnapshotSummary,
  SchedulingEventType,
} from '@principle-theorem/principle-core/interfaces';
import {
  HISTORY_DATE_TIME_FORMAT,
  WithRef,
  titlecase,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  of,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-scheduling-event-interaction-details',
    templateUrl: './scheduling-event-interaction-details.component.html',
    styleUrls: ['./scheduling-event-interaction-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SchedulingEventInteractionDetailsComponent {
  readonly dateFormat = HISTORY_DATE_TIME_FORMAT;
  schedulingEvent$ = new ReplaySubject<WithRef<ISchedulingEvent>>(1);
  showSnapshotColour$ = new BehaviorSubject<boolean>(true);
  snapshotBefore$: Observable<ISchedulingEventSnapshotSummary | undefined>;
  snapshotAfter$: Observable<ISchedulingEventSnapshotSummary | undefined>;
  schedulingEventReason$: Observable<string | undefined>;
  snapshotEventType$: Observable<SchedulingEventType | undefined>;
  arrowColourClass$: Observable<string | undefined>;

  @Input()
  set schedulingEvent(schedulingEvent: WithRef<ISchedulingEvent>) {
    if (schedulingEvent) {
      this.schedulingEvent$.next(schedulingEvent);
    }
  }

  @Input()
  set showSnapshotColour(showSnapshotColour: boolean) {
    this.showSnapshotColour$.next(coerceBooleanProperty(showSnapshotColour));
  }

  constructor() {
    this.schedulingEventReason$ = this.schedulingEvent$.pipe(
      switchMap((schedulingEvent) => this._getReasonName$(schedulingEvent))
    );
    this.snapshotBefore$ = this.schedulingEvent$.pipe(
      switchMap((schedulingEvent) =>
        SchedulingEventSummary.resolveEventSnapshotSummary(
          schedulingEvent.eventBefore
        )
      )
    );
    this.snapshotAfter$ = this.schedulingEvent$.pipe(
      switchMap((schedulingEvent) =>
        SchedulingEventSummary.resolveEventSnapshotSummary(
          schedulingEvent.eventAfter
        )
      )
    );
    this.snapshotEventType$ = combineLatest([
      this.schedulingEvent$,
      this.showSnapshotColour$,
    ]).pipe(
      map(([schedulingEvent, showSnapshotColour]) =>
        showSnapshotColour ? schedulingEvent.eventType : undefined
      )
    );
    this.arrowColourClass$ = combineLatest([
      this.schedulingEvent$,
      this.showSnapshotColour$,
    ]).pipe(
      map(([schedulingEvent, showSnapshotColour]) =>
        showSnapshotColour
          ? SchedulingEvent.getDisplay(schedulingEvent.eventType).textColour
          : 'text-slate-500'
      )
    );
  }

  private _getReasonName$(
    schedulingEvent?: WithRef<ISchedulingEvent>
  ): Observable<string | undefined> {
    if (!schedulingEvent) {
      return of(undefined);
    }
    const defaultReasonName = titlecase(
      SchedulingEvent.getActionlabel(schedulingEvent)
    );
    if (schedulingEvent.reason) {
      return SchedulingEvent.getReason$(schedulingEvent).pipe(
        map((reason) => reason?.name ?? defaultReasonName)
      );
    }
    return of(defaultReasonName);
  }
}
