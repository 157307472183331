import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'pr-patient-portal-splash',
    imports: [CommonModule, MatIconModule],
    templateUrl: './patient-portal-splash.component.html',
    styleUrl: './patient-portal-splash.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientPortalSplashComponent {
  @Input() type?: 'success' | 'error';
}
