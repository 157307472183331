<button
  mat-icon-button
  [disabled]="disabled"
  [matMenuTriggerFor]="menu"
  [color]="iconColour$ | async"
  [matTooltip]="tooltip$ | async"
>
  <mat-icon>price_change</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <div *ngFor="let group of groups$ | async; trackBy: trackByGroup">
    <span class="mat-subtitle-2 group-name">{{ group.name | titlecase }}</span>
    <button
      mat-menu-item
      *ngFor="let schedule of group.schedules; trackBy: trackBySchedule"
      [ngClass]="{
        'current-fee-schedule':
          schedule | map: isCurrentFeeSchedule$:this | async
      }"
      (click)="scheduleSelected.emit(schedule)"
    >
      {{ schedule.name }}
    </button>
  </div>
</mat-menu>
