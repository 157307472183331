import {
  ChartFacade,
  ChartId,
  TreatmentPlanFacade,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  type IAppointment,
  type ITreatmentPlan,
  type ITreatmentPlanProposal,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  sortByCreatedAt,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

export class TreatmentPlanningBloc {
  flaggedTreatments$: Observable<ITreatmentPlanProposal>;
  patientPlans$: Observable<WithRef<ITreatmentPlan>[]>;
  appointmentPlan$: Observable<WithRef<ITreatmentPlan> | undefined>;
  selectedPlan$: Observable<WithRef<ITreatmentPlan>>;

  constructor(
    private _chartState: ChartFacade,
    private _treatmentPlanFacade: TreatmentPlanFacade,
    public appointment$: Observable<WithRef<IAppointment> | undefined> = of(
      undefined
    )
  ) {
    this.patientPlans$ = this._treatmentPlanFacade.treatmentPlans$.pipe(
      map((plans) => plans.sort(sortByCreatedAt))
    );
    this.appointmentPlan$ = this.appointment$.pipe(
      switchMap((appointment) =>
        appointment
          ? this._treatmentPlanFacade.getTreatmentPlan$(
              appointment.treatmentPlan.ref
            )
          : of(undefined)
      )
    );

    this.selectedPlan$ = combineLatest([
      this._treatmentPlanFacade.selectedTreatmentPlan$,
      this.appointmentPlan$,
    ]).pipe(
      tap(([plan, appointmentPlan]) => {
        if (!plan && appointmentPlan) {
          this._treatmentPlanFacade.selectTreatmentPlan(appointmentPlan.ref);
        }
      }),
      map(([plan]) => plan),
      filterUndefined()
    );

    this.flaggedTreatments$ = this._chartState
      .clinicalChartState$(ChartId.InAppointment)
      .pipe(
        filterUndefined(),
        map((chart) => chart.flaggedTreatment)
      );
  }
}
