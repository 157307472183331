import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DYNAMIC_COMPONENT_DATA } from '@principle-theorem/ng-shared';
import { SIDEXIS_INTEGRATION } from '@principle-theorem/principle-bridge-core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IIntegrationActionData } from '../../../../integration-action-data';
import { BridgeDeviceSelectorService } from '../../../bridge-device-selector-dialog/bridge-device-selector.service';
import { SidexisFeatureService } from '../sidexis-feature.service';

@Component({
  selector: 'pr-sidexis-trigger-x-ray',
  templateUrl: './sidexis-trigger-x-ray.component.html',
  styleUrls: ['./sidexis-trigger-x-ray.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidexisTriggerXRayComponent {
  isDisabled$: Observable<boolean>;

  constructor(
    @Inject(DYNAMIC_COMPONENT_DATA)
    private _data: IIntegrationActionData,
    private _sidexis: SidexisFeatureService,
    private _bridgeDeviceSelector: BridgeDeviceSelectorService
  ) {
    this.isDisabled$ = of(this._data.patient).pipe(
      map((patient) => !patient.dateOfBirth)
    );
  }

  async takeXRay(): Promise<void> {
    const deviceRef =
      await this._bridgeDeviceSelector.selectDevice(SIDEXIS_INTEGRATION);

    if (!deviceRef) {
      return;
    }

    await this._sidexis.takeXRay(this._data.patient, deviceRef);
  }
}
