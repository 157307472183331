import { rand, randNumber } from '@ngneat/falso';
import {
  initVersionedSchema,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  AppointmentStatus,
  EventType,
  IAbsoluteSchedulingRules,
  IAppointment,
  IAppointmentDependency,
  IAppointmentRequest,
  IAssociatedTreatment,
  ICancellation,
  IClinicalChart,
  IEvent,
  IEventHistory,
  IFollowUp,
  IInteractionV2,
  IInvoice,
  IPatient,
  IPractice,
  IStaffer,
  IStatusHistory,
  ITag,
  ITreatmentCategory,
  ITreatmentPlan,
  ITreatmentStep,
  IWaitListItem,
  InteractionType,
  MentionResourceType,
  ParticipantType,
  WaitListUrgency,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference } from '@principle-theorem/shared';
import {
  BaseFirestoreMock,
  DAYS_OF_WEEK,
  INamedDocument,
  MockGenerator,
  MockTimestamp,
  Properties,
  Timezone,
  WithRef,
  toMoment,
  toNamedDocument,
  toTimestamp,
} from '@principle-theorem/shared';
import {
  MockDocRef,
  MockNamedDocument,
  MockWithRef,
} from '@principle-theorem/testing';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { Event } from '../event/event';
import { EventMock } from '../event/event.mock';
import { WaitListItem } from '../gap/wait-list-item';
import { Interaction } from '../interaction/interaction';
import { InteractionMock } from '../interaction/interaction.mock';
import { toMention } from '../mention/mention';
import { Appointment } from './appointment';
import { AppointmentDependencyMock } from './appointment-dependency.mock';
import { CancellationMock } from './cancellation.mock';
import { FollowUpMock } from './follow-up.mock';
import { WaitListItemMock } from './wait-list-item.mock';

export class AppointmentMock
  extends BaseFirestoreMock
  implements Properties<IAppointment>
{
  get event(): IEvent {
    return MockGenerator.generate(EventMock);
  }

  get status(): AppointmentStatus {
    return AppointmentStatus.Arrived;
  }

  get practitioner(): INamedDocument<IStaffer> {
    return MockNamedDocument<IStaffer>();
  }

  get practice(): INamedDocument<IPractice> {
    return MockNamedDocument<IPractice>();
  }

  get treatmentPlan(): IAssociatedTreatment {
    return {
      ...MockNamedDocument<ITreatmentPlan>(),
      treatmentStep: {
        ...MockNamedDocument<ITreatmentStep>(),
        duration: randNumber({
          min: 15,
          max: 180,
        }),
        display: {
          overrideTreatmentCategory: MockDocRef<ITreatmentCategory>(),
          primaryTreatmentCategory: MockDocRef<ITreatmentCategory>(),
        },
      },
    };
  }

  get schedulingRules(): IAbsoluteSchedulingRules {
    return {
      minDate: MockTimestamp(),
      maxDate: MockTimestamp(),
      duration: randNumber({
        min: 15,
        max: 180,
      }),
    };
  }

  get waitListItem(): IWaitListItem {
    return MockGenerator.generate(WaitListItemMock);
  }

  get clinicalChart(): DocumentReference<IClinicalChart> {
    return MockDocRef<IClinicalChart>();
  }

  get statusHistory(): IStatusHistory<AppointmentStatus>[] {
    return [
      {
        status: AppointmentStatus.Scheduled,
        updatedAt: MockTimestamp(),
      },
      {
        status: AppointmentStatus.Complete,
        updatedAt: MockTimestamp(),
      },
    ];
  }

  get eventHistory(): IEventHistory[] {
    return [
      {
        createdAt: MockTimestamp(),
        event: MockGenerator.generate(EventMock),
      },
    ];
  }

  get interactions(): IInteractionV2[] {
    return [MockGenerator.generate(InteractionMock)];
  }

  get cancellationHistory(): ICancellation[] {
    return [MockGenerator.generate(CancellationMock)];
  }

  get followUpHistory(): IFollowUp[] {
    return [MockGenerator.generate(FollowUpMock)];
  }

  get dependencies(): IAppointmentDependency[] {
    return [MockGenerator.generate(AppointmentDependencyMock)];
  }

  get tags(): INamedDocument<ITag>[] {
    return [];
  }

  get invoiceRef(): DocumentReference<IInvoice> {
    return MockDocRef<IInvoice>();
  }

  get activeFollowUp(): WithRef<IFollowUp> {
    return MockWithRef(MockGenerator.generate(FollowUpMock));
  }

  get appointmentRequestRef(): DocumentReference<IAppointmentRequest> {
    return MockDocRef<IAppointmentRequest>();
  }
}

export function MockConfirmedInteractions(
  patient: INamedDocument<IPatient>,
  staffer: INamedDocument<IStaffer>,
  _: INamedDocument,
  event: IEvent
): IInteractionV2[] {
  return [
    Interaction.init({
      type: InteractionType.AppointmentBook,
      owner: staffer,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` booked appointment for `),
        toMentionContent(toMention(patient, MentionResourceType.Patient)),
      ],
      createdAt: toTimestamp(
        toMoment(event.from).subtract(randNumber({ min: 5, max: 45 }), 'days')
      ),
    }),
    Interaction.init({
      type: InteractionType.AppointmentReminder,
      owner: staffer,
      title: [
        toTextContent(`Sent appointment reminder to `),
        toMentionContent(toMention(patient, MentionResourceType.Patient)),
      ],
      createdAt: toTimestamp(toMoment(event.from).subtract(2, 'days')),
    }),
    Interaction.init({
      type: InteractionType.AppointmentConfirm,
      title: [
        toMentionContent(toMention(patient, MentionResourceType.Patient)),
        toTextContent(` confirmed appointment via sms`),
      ],
      createdAt: toTimestamp(
        toMoment(event.from).subtract(randNumber({ min: 3, max: 47 }), 'hours')
      ),
    }),
  ];
}

export function MockInProgressInteractions(
  patient: INamedDocument<IPatient>,
  staffer: INamedDocument<IStaffer>,
  practitioner: INamedDocument<IStaffer>,
  event: IEvent
): IInteractionV2[] {
  return MockConfirmedInteractions(
    patient,
    staffer,
    practitioner,
    event
  ).concat([
    Interaction.init({
      type: InteractionType.AppointmentArrived,
      owner: staffer,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` marked `),
        toMentionContent(toMention(patient, MentionResourceType.Patient)),
        toTextContent(` as arrived`),
      ],
      createdAt: toTimestamp(
        toMoment(event.from).subtract(
          randNumber({ min: 5, max: 30 }),
          'minutes'
        )
      ),
    }),
    Interaction.init({
      type: InteractionType.AppointmentCheckIn,
      owner: staffer,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` marked `),
        toMentionContent(toMention(patient, MentionResourceType.Patient)),
        toTextContent(` as ready for treatment`),
      ],
      createdAt: toTimestamp(
        toMoment(event.from).subtract(randNumber({ min: 1, max: 4 }), 'minutes')
      ),
    }),
    Interaction.init({
      type: InteractionType.AppointmentStart,
      owner: practitioner,
      title: [
        toMentionContent(toMention(practitioner, MentionResourceType.Staffer)),
        toTextContent(` started treatment on `),
        toMentionContent(toMention(patient, MentionResourceType.Patient)),
      ],
      createdAt: toTimestamp(
        toMoment(event.from).add(randNumber({ min: -5, max: 1 }), 'minutes')
      ),
    }),
  ]);
}

export function MockCheckingOutInteractions(
  patient: INamedDocument<IPatient>,
  staffer: INamedDocument<IStaffer>,
  practitioner: INamedDocument<IStaffer>,
  event: IEvent
): IInteractionV2[] {
  return MockInProgressInteractions(
    patient,
    staffer,
    practitioner,
    event
  ).concat([
    Interaction.init({
      type: InteractionType.AppointmentCheckOut,
      owner: staffer,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` marked appointment as ready for checkout`),
      ],
      createdAt: toTimestamp(
        toMoment(event.to).add(randNumber({ min: -5, max: 5 }), 'minutes')
      ),
    }),
  ]);
}

export function MockScheduledInteractions(
  patient: INamedDocument<IPatient>,
  staffer: INamedDocument<IStaffer>,
  _: INamedDocument,
  event: IEvent
): IInteractionV2[] {
  return [
    Interaction.init({
      type: InteractionType.AppointmentBook,
      owner: staffer,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` booked appointment for `),
        toMentionContent(toMention(patient, MentionResourceType.Patient)),
      ],
      createdAt: toTimestamp(
        toMoment(event.from).subtract(randNumber({ min: 5, max: 45 }), 'days')
      ),
    }),
    Interaction.init({
      type: InteractionType.AppointmentReminder,
      title: [
        toTextContent(`Sent appointment reminder to `),
        toMentionContent(toMention(patient, MentionResourceType.Patient)),
      ],
      createdAt: toTimestamp(toMoment(event.from).subtract(2, 'days')),
    }),
    Interaction.init({
      type: InteractionType.Call,
      owner: staffer,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` called `),
        toMentionContent(toMention(patient, MentionResourceType.Patient)),
      ],
      createdAt: toTimestamp(toMoment(event.from).subtract({ hours: 2 })),
      content: initVersionedSchema(
        'No answer from patient. Will try again in 1 hour'
      ),
    }),
    Interaction.init({
      type: InteractionType.Call,
      owner: staffer,
      title: [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` called `),
        toMentionContent(toMention(patient, MentionResourceType.Patient)),
      ],
      createdAt: toTimestamp(
        toMoment(event.from).subtract({ hours: 1, minutes: 28 })
      ),
      content: initVersionedSchema(
        'Left message. Will cancel appointment if no reply in the next hour'
      ),
    }),
  ];
}

export interface IAppointmentMockData {
  status: AppointmentStatus;
  from?: Moment;
  to?: Moment;
}

export function MockAppointment(
  data: IAppointmentMockData,
  patient: WithRef<IPatient>,
  staffer: INamedDocument<IStaffer>,
  practitioner: INamedDocument<IStaffer>,
  treatmentPlan: IAssociatedTreatment,
  practice: INamedDocument<IPractice>
): IAppointment {
  const event: IEvent = Event.init({
    creator: toNamedDocument(staffer),
    organiser: toNamedDocument(practitioner),
    practice,
    participants: [
      {
        name: practitioner.name,
        ref: practitioner.ref,
        type: ParticipantType.Staffer,
      },
      {
        name: patient.name,
        ref: patient.ref,
        type: ParticipantType.Patient,
      },
    ],
    type: EventType.Appointment,
    from: data.from ? toTimestamp(data.from) : undefined,
    to: data.to ? toTimestamp(data.to) : undefined,
  });

  const from = moment();
  const to = event.from ? toMoment(event.from) : moment().add(7, 'days');

  const waitListItem: IWaitListItem = WaitListItem.init({
    days: DAYS_OF_WEEK,
    dateFrom: toTimestamp(from),
    dateTo: toTimestamp(to),
    timeFrom: rand(['08:00', '12:00', '09:30']),
    timeTo: rand(['14:00', '16:00', '18:00']),
    urgency: rand([
      WaitListUrgency.Low,
      WaitListUrgency.Medium,
      WaitListUrgency.High,
    ]),
  });

  waitListItem.availableDates = WaitListItem.determineAvailableDates(
    {
      from,
      to,
    },
    DAYS_OF_WEEK,
    Timezone.AustraliaSydney
  );

  return Appointment.init({
    event,
    status: data.status,
    practitioner,
    practice,
    treatmentPlan,
    waitListItem,
  });
}

export function MockTimeUntilAppointment(): number {
  const hour = 60;
  const day: number = hour * 24;
  const week: number = day * 7;
  return randNumber({ min: 0, max: week * 4 });
}
