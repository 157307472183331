import { ITyroBaseExtendedData } from '@principle-theorem/principle-core/interfaces';
import {
  isObject,
  type Timestamp,
  toMoment,
  TypeGuard,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type Moment } from 'moment-timezone';
import {
  type IBaseTransactionCompleteCallbackData,
  type IIntegrationKeyRequestParams,
  isBaseTransactionCompleteCallbackData,
  type ITerminalRequestParams,
  TransactionCompleteResult,
} from './tyro-callbacks';
import { type BaseReconciliationReportResponse } from './tyro-reconciliation';

export interface IHealthPointClaimRequestParams
  extends ITerminalRequestParams,
    IIntegrationKeyRequestParams {
  /**
   *  The 8 character id of the provider attending the patient.
   */
  providerId: string;

  /**
   * The 1 alphabetic character representing the category of service.
   */
  serviceType: string;

  /**
   * The total number of claim items.
   */
  claimItemsCount: string | number;

  /**
   * The total amount of all claim items in cents.
   */
  totalClaimAmount: string | number;

  /**
   * A list of claim items as a JSON array.
   * Max 16 items.
   */
  claimItems: IClaimItem[];
}

export interface IHealthPointCancelClaimRequestParams
  extends IHealthPointClaimRequestParams {
  /**
   * The reference tag returned in the original claim's final transaction status response.
   */
  refTag: string;
}

export interface IClaimItem {
  /**
   * Claim amount in cents - max 10 digits
   * eg. 10000
   */
  claimAmount: number;

  /**
   * Item number service code - max 5 characters
   * eg. "00001"
   */
  serviceCode: string;

  /**
   * Description of item to appear on receipt - max 32 characters
   */
  description: string;

  /**
   * Body part or tooth number suffix - max 3 characters
   * eg. "01"
   */
  serviceReference: string;

  /**
   * Patient ID on card - exactly 2 digits
   * eg. "02"
   */
  patientId: string;

  /**
   * Claim date in YYYYMMDD format
   * eg. "20131010"
   */
  serviceDate: string;
}

export interface IHealthPointBaseTransactionCallbackData
  extends IBaseTransactionCompleteCallbackData {
  /**
   * The reference tag generated by the terminal that identifies a transaction per terminal
   */
  healthpointRefTag: string;

  /**
   * The transaction date time of the claim/s
   */
  healthpointTerminalDateTime: string;
}

export function isHealthPointBaseTransactionCallbackData(
  data: unknown
): data is IHealthPointBaseTransactionCallbackData {
  return (
    isObject(data) &&
    isBaseTransactionCompleteCallbackData(data) &&
    isString(data.healthpointRefTag) &&
    isString(data.healthpointTerminalDateTime)
  );
}

export interface IHealthPointTransactionErrorCallback
  extends IHealthPointBaseTransactionCallbackData {
  healthpointErrorCode: string;
  healthpointErrorDescription: string;
}

export function isHealthPointTransactionErrorCallback(
  data: unknown
): data is IHealthPointTransactionErrorCallback {
  return (
    isObject(data) &&
    isHealthPointBaseTransactionCallbackData(data) &&
    isString(data.healthpointErrorCode) &&
    isString(data.healthpointErrorDescription)
  );
}

const healthPointQuoteErrorStatuses: string[] = [
  TransactionCompleteResult.ERROR,
  TransactionCompleteResult.SYSTEM_ERROR,
  TransactionCompleteResult.UNKNOWN,
];

type HealthPointClaimEstimateErrorStatuses =
  | TransactionCompleteResult.ERROR
  | TransactionCompleteResult.SYSTEM_ERROR
  | TransactionCompleteResult.UNKNOWN;

export interface IHealthPointClaimEstimateErrorCallback {
  result: HealthPointClaimEstimateErrorStatuses;
  transactionId: string;
}

export function isHealthPointClaimEstimateErrorCallback(
  data: unknown
): data is IHealthPointClaimEstimateErrorCallback {
  return (
    isObject(data) &&
    isString(data.transactionId) &&
    isString(data.result) &&
    healthPointQuoteErrorStatuses.includes(data.result)
  );
}

export interface IHealthPointTransactionSuccessBaseCallbackData
  extends IHealthPointBaseTransactionCallbackData {
  /**
   * Total benefit amount for all claim items
   */
  healthpointTotalBenefitAmount?: string;

  /**
   * Settlement date time as decided by the iCS system/health fund
   */
  healthpointSettlementDateTime: string;

  /**
   * Private health fund member number of cardholder
   */
  healthpointMemberNumber: string;

  /**
   * Provider ID matching the original request
   */
  healthpointProviderId: string;

  /**
   * Service type matching the original request
   */
  healthpointServiceType: string;

  /**
   * The response code from the private health fund.
   */
  healthpointPhfResponseCode: string;

  /**
   * The description of the response code from the private health fund.
   */
  healthpointPhfResponseCodeDescription?: string;

  healthpointClaimItems: IHealthPointClaimItem[];
}

export interface IHealthPointTransactionCompleteCallbackData
  extends IHealthPointTransactionSuccessBaseCallbackData {
  /**
   * The gap amount. Will only be present on successful claims and voids.
   */
  healthpointGapAmount: string;

  /**
   * The name of the private health fund. Not present on voids.
   */
  healthpointHealthFundName?: string;

  /**
   * The identifying digits of the private health fund. Not present on voids.
   */
  healthpointHealthFundIdentifyingDigits?: string;
}

export interface IHealthPointClaimEstimateCompleteCallbackData
  extends Omit<
    IHealthPointTransactionCompleteCallbackData,
    'healthpointSettlementDateTime'
  > {
  healthpointFreeText: string;
}

export interface IHealthPointClaimItem {
  /**
   * Claim Item amount in cents (max. 10 digits)
   */
  claimAmount: string;

  /**
   * Rebate amount for the claim made (max. 10 digits)
   */
  rebateAmount: string;

  /**
   * Item Service Code
   */
  serviceCode: string;

  /**
   * Item description (max. 32 characters)
   */
  description: string;

  /**
   * Item Service Ref (max. 3 characters)
   */
  serviceReference: string;

  /**
   * Patient id as on card (2 digits)
   */
  patientId: string;

  /**
   * Date of claim in format "yyyyMMddhhmmss"
   */
  serviceDate: string;

  /**
   * Individual response code for this item
   */
  responseCode: string;
}

export function isHealthPointClaimItem(
  data: unknown
): data is IHealthPointClaimItem {
  return (
    isObject(data) &&
    isString(data.claimAmount) &&
    isString(data.rebateAmount) &&
    isString(data.serviceCode) &&
    isString(data.description) &&
    isString(data.serviceReference) &&
    isString(data.patientId) &&
    isString(data.serviceDate) &&
    isString(data.responseCode)
  );
}

export function isHealthPointTransactionSuccessBaseCallbackData(
  data: unknown
): data is IHealthPointTransactionSuccessBaseCallbackData {
  return (
    isObject(data) &&
    isHealthPointBaseTransactionCallbackData(data) &&
    isString(data.healthpointSettlementDateTime) &&
    isString(data.healthpointMemberNumber) &&
    isString(data.healthpointProviderId) &&
    isString(data.healthpointServiceType) &&
    isString(data.healthpointPhfResponseCode) &&
    TypeGuard.arrayOf(isHealthPointClaimItem)(data.healthpointClaimItems) &&
    TypeGuard.undefinedOr(isString)(
      data.healthpointPhfResponseCodeDescription
    ) &&
    TypeGuard.undefinedOr(isString)(data.healthpointTotalBenefitAmount)
  );
}

export function isHealthPointTransactionCompleteCallbackData(
  data: unknown
): data is IHealthPointTransactionCompleteCallbackData {
  return (
    isObject(data) &&
    isHealthPointTransactionSuccessBaseCallbackData(data) &&
    isString(data.healthpointGapAmount) &&
    TypeGuard.undefinedOr(isString)(data.healthpointHealthFundName) &&
    TypeGuard.undefinedOr(isString)(data.healthpointHealthFundIdentifyingDigits)
  );
}

export function isHealthPointClaimEstimateCompleteCallbackData(
  data: unknown
): data is IHealthPointClaimEstimateCompleteCallbackData {
  return (
    isObject(data) &&
    isHealthPointBaseTransactionCallbackData(data) &&
    isString(data.healthpointMemberNumber) &&
    isString(data.healthpointProviderId) &&
    isString(data.healthpointServiceType) &&
    isString(data.healthpointPhfResponseCode) &&
    TypeGuard.arrayOf(isHealthPointClaimItem)(data.healthpointClaimItems) &&
    TypeGuard.undefinedOr(isString)(
      data.healthpointPhfResponseCodeDescription
    ) &&
    TypeGuard.undefinedOr(isString)(data.healthpointTotalBenefitAmount) &&
    isHealthPointBaseTransactionCallbackData(data) &&
    isString(data.healthpointMemberNumber) &&
    isString(data.healthpointProviderId) &&
    isString(data.healthpointServiceType) &&
    isString(data.healthpointPhfResponseCode) &&
    TypeGuard.arrayOf(isHealthPointClaimItem)(data.healthpointClaimItems) &&
    TypeGuard.undefinedOr(isString)(
      data.healthpointPhfResponseCodeDescription
    ) &&
    TypeGuard.undefinedOr(isString)(data.healthpointTotalBenefitAmount) &&
    isString(data.healthpointGapAmount) &&
    isString(data.healthpointFreeText) &&
    TypeGuard.undefinedOr(isString)(data.healthpointHealthFundName) &&
    TypeGuard.undefinedOr(isString)(data.healthpointHealthFundIdentifyingDigits)
  );
}

export interface IWithHealthPointTransactionCompleteCallback {
  transactionCompleteCallback: (
    data: IHealthPointTransactionSuccessBaseCallbackData
  ) => unknown;
}

export function toHealthPointDate(date: Timestamp | Moment | Date): string {
  return toMoment(date).format('YYYYMMDD');
}

export interface IHealthPointReconciliationReportRequestParams
  extends IIntegrationKeyRequestParams {
  /**
   * The reporting date in format yyyyMMdd.
   */
  reconDate: string;
  reportType: 'payments' | 'claims';
}

export type HealthPointReconciliationReportResponse =
  BaseReconciliationReportResponse;

export interface IHealthPointTransactionExtendedData
  extends ITyroBaseExtendedData {
  request: IHealthPointClaimRequestParams;
  response:
    | IBaseTransactionCompleteCallbackData
    | IHealthPointTransactionSuccessBaseCallbackData
    | IHealthPointTransactionCompleteCallbackData;
}

export function isHealthPointTransactionExtendedData(
  data: unknown
): data is IHealthPointTransactionExtendedData {
  if (!isObject(data)) {
    return false;
  }
  const validResponse =
    isHealthPointTransactionSuccessBaseCallbackData(data.response) ||
    isHealthPointTransactionCompleteCallbackData(data.response);
  // TODO: #3ax3vq Check request property?
  return validResponse;
}
