<form [formGroup]="form" fxLayout="column" (ngSubmit)="submit()">
  <mat-form-field fxFlex *ngIf="'title' | map: fieldEnabled : this | async">
    <mat-label>Title</mat-label>
    <pr-editor-input
      cdkFocusInitial
      placeholder="Title"
      formControlName="title"
      [required]="true"
    />
  </mat-form-field>

  <mat-form-field *ngIf="'type' | map: fieldEnabled : this | async">
    <mat-label>Event Type</mat-label>
    <mat-select placeholder="Event Type" formControlName="type">
      <mat-option
        *ngFor="let type of eventTypes; trackBy: trackByEventType"
        [value]="type"
      >
        {{ type | splitCamel | titlecase }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="isPreBlock$ | async">
    <div fxLayout="column" fxLayoutGap="8px">
      <mat-checkbox
        formControlName="isPublic"
        *ngIf="'isPublic' | map: fieldEnabled : this | async"
      >
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <span>Can be Booked Online</span>
          <mat-icon matTooltip="Allow this pre-block to be booked online">
            info
          </mat-icon>
        </div>
      </mat-checkbox>

      <div
        *ngIf="'allowedTreatmentCategories' | map: fieldEnabled : this | async"
        class="flex items-center gap-2"
      >
        <mat-form-field class="flex-1">
          <mat-label>Allowed Treatment Categories</mat-label>
          <mat-select
            formControlName="allowedTreatmentCategories"
            [compareWith]="isSelectedNamedDocument"
            multiple
          >
            <mat-option
              *ngFor="
                let category of globalStore.treatmentCategories$ | async;
                trackBy: trackByCategory
              "
              [value]="category.ref"
            >
              <div fxLayout="row" fxLayoutGap="8px">
                <div
                  fxFlex="8px"
                  [style.backgroundColor]="category.colour.value"
                ></div>
                <span>{{ category.name }}</span>
              </div>
            </mat-option>
          </mat-select>

          <mat-icon
            matIconSuffix
            matTooltip="Restrict which treatments can be booked by their treatment category"
          >
            info
          </mat-icon>
        </mat-form-field>

        <ng-container *ngrxLet="allowedCategoryColours$ as colours">
          <div
            *ngIf="colours.length > 1"
            class="mb-4 flex items-center gap-2"
            (click)="$event.preventDefault()"
          >
            <button
              class="sync-button opacity-70"
              mat-icon-button
              matTooltip="Revert to default"
              (click)="form.controls.colourOverride.reset()"
            >
              <mat-icon>sync</mat-icon>
            </button>
            <pt-event-colour-picker
              [colour]="defaultPreBlockColour$ | async"
              [palette]="colours"
              [disableOpacity]="true"
              (colourSelected)="form.controls.colourOverride.setValue($event)"
            />
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="canHaveSingleParticipant$ | async">
    <mat-form-field
      fxFlex
      *ngIf="'staffSearch' | map: fieldEnabled : this | async"
    >
      <mat-label>Participant</mat-label>
      <input
        matInput
        [required]="true"
        placeholder="Add Participant"
        formControlName="staffSearch"
        [matAutocomplete]="auto"
        #autoComplete="matAutocompleteTrigger"
        (click)="autoComplete.openPanel()"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        [autoActiveFirstOption]="true"
        [displayWith]="displayFn"
        (optionSelected)="addParticipant($event)"
      >
        <mat-option
          *ngFor="
            let staffer of staffSearchFilter.results$ | async;
            trackBy: trackByStaffer
          "
          [value]="staffer"
        >
          {{ staffer.user.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="isLeave$ | async; else meetingBreakSelectors">
    <div
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="16px"
      *ngIf="'eventStartDate' | map: fieldEnabled : this | async"
    >
      <mat-form-field fxFlex="50">
        <mat-label>Event Date</mat-label>
        <input
          matInput
          formControlName="eventStartDate"
          [matDatepicker]="eventStartDate"
          (focus)="eventStartDate.open()"
          (click)="eventStartDate.open()"
          [required]="true"
        />
        <mat-datepicker-toggle matIconSuffix [for]="eventStartDate" />
        <mat-datepicker #eventStartDate />
      </mat-form-field>

      <pr-date-selector
        fxFlex="50"
        placeholder="Start Time"
        formControlName="startTime"
        [timeTo]="form.controls.endTime.value"
        [required]="true"
      />
    </div>
    <div
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="16px"
    >
      <mat-form-field
        fxFlex="50"
        *ngIf="'eventEndDate' | map: fieldEnabled : this | async"
      >
        <mat-label>Event End Date</mat-label>
        <input
          matInput
          formControlName="eventEndDate"
          [matDatepicker]="eventEndDate"
          (focus)="eventEndDate.open()"
          (click)="eventEndDate.open()"
          [min]="minimumEndDate$ | async"
          [required]="true"
        />
        <mat-datepicker-toggle matIconSuffix [for]="eventEndDate" />
        <mat-datepicker #eventEndDate />
      </mat-form-field>
      <pr-date-selector
        *ngIf="'endTime' | map: fieldEnabled : this | async"
        fxFlex="50"
        placeholder="End Time"
        formControlName="endTime"
        [timeFrom]="form.controls.startTime.value"
        [required]="true"
      />
    </div>
  </ng-container>
  <ng-template #meetingBreakSelectors>
    <div
      fxFlex
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="16px"
    >
      <mat-form-field
        fxFlex="50"
        *ngIf="'eventStartDate' | map: fieldEnabled : this | async"
      >
        <mat-label>Event Date</mat-label>
        <input
          matInput
          formControlName="eventStartDate"
          [matDatepicker]="eventStartDate"
          (focus)="eventStartDate.open()"
          (click)="eventStartDate.open()"
          [required]="true"
        />
        <mat-datepicker-toggle matIconSuffix [for]="eventStartDate" />
        <mat-datepicker #eventStartDate />
      </mat-form-field>

      <pr-date-selector
        *ngIf="'startTime' | map: fieldEnabled : this | async"
        fxFlex="25"
        placeholder="Start Time"
        formControlName="startTime"
        [timeTo]="form.controls.endTime.value"
        [required]="true"
      />

      <pr-date-selector
        *ngIf="'endTime' | map: fieldEnabled : this | async"
        fxFlex="25"
        placeholder="End Time"
        formControlName="endTime"
        [timeFrom]="form.controls.startTime.value"
        [required]="true"
      />
    </div>
  </ng-template>

  <mat-form-field *ngIf="'practice' | map: fieldEnabled : this | async">
    <mat-label>Event Location</mat-label>
    <mat-select
      formControlName="practice"
      [compareWith]="isSelectedNamedDocument"
      [required]="true"
    >
      <mat-option
        *ngFor="let practice of practices$ | async; trackBy: trackByPractice"
        [value]="practice"
      >
        {{ practice.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="canHaveMultipleParticipants$ | async">
    <ng-container *ngIf="'staffSearch' | map: fieldEnabled : this | async">
      <mat-form-field>
        <mat-label>Participants</mat-label>
        <input
          matInput
          placeholder="Add Participants"
          formControlName="staffSearch"
          [matAutocomplete]="auto"
          #autoComplete="matAutocompleteTrigger"
          (click)="autoComplete.openPanel()"
        />

        <mat-autocomplete
          #auto="matAutocomplete"
          [autoActiveFirstOption]="true"
          [displayWith]="displayFn"
          (optionSelected)="addParticipant($event)"
        >
          <ng-container *ngIf="staffSearchFilter.results$ | async as staff">
            <mat-option
              *ngFor="let staffer of staff; trackBy: trackByStaffer"
              [value]="staffer"
            >
              {{ staffer.user.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <mat-list
        fxFill
        class="participant-list"
        *ngIf="participants$ | async as participants"
      >
        <mat-list-item
          *ngFor="let participant of participants; trackBy: trackByParticipant"
        >
          <div fxFlex="10">
            <pt-user-icon
              [name]="participant.name"
              [src]="participant | profileImage$ | async"
              [diameter]="30"
            />
          </div>
          <div fxFlex="70">
            <span>{{ participant.name }}</span>
          </div>
          <div fxFlex fxLayout="row" fxLayoutAlign="end center">
            <button
              mat-icon-button
              (click)="removeParticipant(participant)"
              type="button"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </mat-list-item>
      </mat-list>
    </ng-container>
  </ng-container>

  <mat-form-field fxFlex *ngIf="'notes' | map: fieldEnabled : this | async">
    <mat-label>Description</mat-label>
    <pr-content-editor
      [menuEnabled]="false"
      formControlName="notes"
      placeholder="Add Event Description"
    />
  </mat-form-field>

  <pr-tags-input
    *ngIf="'eventTags' | map: fieldEnabled : this | async"
    formControlName="eventTags"
    [tagCol]="tagCol$ | async"
    [tagType]="tagType"
  />

  <mat-checkbox
    formControlName="locked"
    *ngIf="'locked' | map: fieldEnabled : this | async"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <span>Lock Event</span>
      <mat-icon matTooltip="Prevent other users from editing this event">
        info
      </mat-icon>
    </div>
  </mat-checkbox>

  <ng-container *ngIf="canBeBlocking$ | async">
    <mat-checkbox
      formControlName="isBlocking"
      *ngIf="'isBlocking' | map: fieldEnabled : this | async"
    >
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <span>Blocking Event</span>
        <mat-icon
          matTooltip="Prevent other events being scheduled at the same time"
        >
          info
        </mat-icon>
      </div>
    </mat-checkbox>
  </ng-container>

  <button type="submit" hidden></button>
</form>
