import { type BaseMock } from './base-mock';

export class MockGenerator {
  static generate<Mocker extends BaseMock, Interface = Mocker>(
    type: {
      new (): Mocker;
    },
    overrides?: Partial<Mocker>
  ): Interface {
    const data: Mocker = new type();
    return { ...data.serialise(), ...overrides } as unknown as Interface;
  }
}
