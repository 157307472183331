<div class="layout-margin">
  <h1>Managed Reports</h1>
  <pr-information-box>
    Managed Reports are built and managed by the Principle team for your
    practice's special use cases
  </pr-information-box>

  <div class="my-5">
    <div class="my-5 flex flex-col gap-4">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Select a report</mat-label>
        <mat-select [formControl]="reportCtrl">
          @for (
            report of availableSystemReports$ | async;
            track trackByReportName
          ) {
            <mat-option
              [value]="report"
              [matTooltip]="report.description"
              matTooltipPosition="right"
              matTooltipShowDelay="300"
            >
              {{ report.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="my-5 flex items-center gap-2">
      <pt-date-range-form
        [useRangePicker]="true"
        [from]="from"
        [to]="to"
        (changed)="updateDateRange($event)"
      />

      <mat-form-field subscriptSizing="dynamic">
        <mat-label>File Format</mat-label>
        <mat-select [formControl]="fileType">
          <mat-option value="xlsx">Excel</mat-option>
          <mat-option value="csv">CSV</mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-flat-button color="primary" (click)="runJob()">Run</button>
    </div>

    <pr-system-report-parameter-form
      [selectedType]="reportCtrl.value"
      [availablePractitioners]="practitioners$ | async"
      (formChange)="updateParams($event)"
    />
  </div>

  @if (filteredReportJobs$ | async; as jobs) {
    <h3>Report History</h3>
    @if (jobs.length) {
      <mat-list class="mat-elevation-z1 my-6">
        @for (job of jobs; track job.ref.path) {
          <mat-list-item [matTooltip]="job | map: getTooltip">
            <span matListItemTitle>
              {{ job.type | map: transformReportName | titlecase }}
            </span>
            <span matListItemLine>
              {{
                job.requestParams.range.from | moment | amDateFormat: dateFormat
              }}
              -
              {{
                job.requestParams.range.to | moment | amDateFormat: dateFormat
              }}
            </span>
            <small class="mat-caption">
              Created: {{ job.createdAt | moment | amDateFormat: dateFormat }}
            </small>
            <div matListItemMeta>
              <span class="flex flex-row items-center gap-4">
                <pr-status-label
                  [label]="job.status"
                  [color]="statusColourMap[job.status]"
                  [dense]="true"
                />
                <button
                  mat-flat-button
                  color="primary"
                  [disabled]="job | map: isNotReadyForDownload"
                  (click)="download(job)"
                >
                  Download
                </button>
              </span>
            </div>
          </mat-list-item>
        }
      </mat-list>
    } @else {
      <pr-empty-state image="list" title="reports" />
    }
  }
</div>
