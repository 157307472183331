import { get, last, uniqWith } from 'lodash';
import { EMPTY, Observable, from, merge } from 'rxjs';
import {
  concatMap,
  filter,
  map,
  scan,
  startWith,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { shareReplayCold } from '../../rxjs';
import { toTimestamp } from '../../time/time';
import { WithRef } from '../interfaces';
import {
  CollectionReference,
  Query,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from './adaptor';
import { query$ } from './collection';
import { Firestore } from './firestore';

export function paginatedQuery$<T extends object>(
  paginationQuery: CollectionReference<T> | Query<T>,
  paginationSize: number,
  nextResultsTrigger$: Observable<void>,
  sortFn?: (recordA: WithRef<T>, recordB: WithRef<T>) => number,
  uniqueFn?: (recordA: WithRef<T>, recordB: WithRef<T>) => boolean,
  querySortField: string = 'createdAt',
  newRecordsSortField: string = 'updatedAt',
  querySortOrder: 'desc' | 'asc' = 'desc',
  queryInitialRecord?: WithRef<T>
): Observable<WithRef<T>[]> {
  const queryFn = (
    collection: CollectionReference<T> | Query<T>,
    lastRecord?: WithRef<T>
  ): Query<T> => {
    const paginatedQuery = query(
      collection,
      limit(paginationSize),
      orderBy(String(querySortField), querySortOrder)
    );

    if (!lastRecord) {
      return paginatedQuery;
    }
    return query(paginatedQuery, startAfter(get(lastRecord, querySortField)));
  };

  const initialQuery$ = from(
    Firestore.getDocs(queryFn(paginationQuery, queryInitialRecord))
  );

  const paginationQuery$ = initialQuery$.pipe(
    concatMap((initialRecords) => {
      let lastRecord = last(initialRecords);
      return nextResultsTrigger$.pipe(
        concatMap(() => {
          if (!lastRecord) {
            return EMPTY;
          }
          return Firestore.getDocs(queryFn(paginationQuery, lastRecord));
        }),
        tap((results) => (lastRecord = last(results))),
        startWith(initialRecords)
      );
    }),
    takeWhile((records) => records.length > 0, true)
  );

  const newUpdates$ = query$(
    paginationQuery,
    where(newRecordsSortField, '>=', toTimestamp()),
    orderBy(newRecordsSortField, querySortOrder)
  ).pipe(filter((records) => records.length > 0));

  return merge(newUpdates$, paginationQuery$).pipe(
    scan(
      (records, newRecords) => [...records, newRecords],
      [] as WithRef<T>[][]
    ),
    map((records) => {
      const combinedRecords = records.reverse().flat();
      const uniqueRecords = uniqueFn
        ? uniqWith(combinedRecords, uniqueFn)
        : combinedRecords;

      if (!sortFn) {
        return uniqueRecords;
      }

      return uniqueRecords.sort(sortFn);
    }),
    shareReplayCold()
  );
}
