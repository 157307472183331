import { type DocumentReference } from '@principle-theorem/shared';

export interface IMention<T extends string = string> {
  uid: string;
  key: string;
  resource: IMentionResource<T>;
}

export interface IMentionResource<T extends string = string> {
  uid: string;
  ref: DocumentReference;
  type: T;
}

export const MENTION_TRIGGER = '@';

export function asMention(value: string): string {
  return `${MENTION_TRIGGER}${value}`;
}
