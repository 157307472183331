import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ChartFacade,
  ChartId,
  TreatmentStepsFacade,
} from '@principle-theorem/ng-clinical-charting/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PatientsFacade } from '@principle-theorem/ng-patient';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ClaimEstimateProviders } from '@principle-theorem/ng-payments';
import {
  CurrentAppointmentScope,
  CurrentScopeFacade,
  OrganisationService,
  SelectPractitionerDialogComponent,
} from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  SelectionListStore,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  Appointment,
  ChartedItemTotalCalculator,
  ChartedMultiStepTreatment,
  Invoice,
  Staffer,
  TreatmentStep,
  stafferToNamedDoc,
  toAccountDetails,
  treatmentStepToLineItems,
} from '@principle-theorem/principle-core';
import { AppointmentPermissions } from '@principle-theorem/principle-core/features';
import {
  AutomationStatus,
  TreatmentStepStatus,
  isChartedMultiStepTreatment,
  isChartedMultiStepTreatmentStep,
  isEventable,
  isTreatmentPlan,
  type AnyChartedItemConfiguration,
  type IAppointment,
  type IChartedItem,
  type IChartedItemSelectionSummary,
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  type IChartedRef,
  type IFeeSchedule,
  type IStaffer,
  type ITreatmentPlan,
  type ITreatmentStep,
  type ITreatmentStepDisplay,
  type PlanStepPairStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  DATE_TIME_WITH_YEAR_FORMAT,
  distinctUntilPathsChange,
  filterUndefined,
  getCount,
  getDoc,
  isSameRef,
  isWithRef,
  patchDoc,
  snapshot,
  toMoment,
  toNamedDocument,
  toQuery,
  undeletedQuery,
  where,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  of,
  type Observable,
} from 'rxjs';
import { map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { AddChartable } from '../../add-chartable';
import { ChartDialogService } from '../../chart-dialog.service';
import { type IChartedSurfaceProvider } from '../../charted-surface/add-charted-surface-provider';
import { AddTreatmentToMultiTreatmentProposalProvider } from '../../charted-surface/chart/add-treatment-to-multi-treatment-proposal-provider';
import { AddMultiTreatmentToFirestorePlanProvider } from '../../charted-surface/firestore-treatment-plan/add-multi-treatment-to-firestore-plan-provider';
import { AddTreatmentToStoreStepProvider } from '../../charted-surface/store-treatment-plan/add-treatment-to-store-step-provider';
import { EditTreatmentStepDialogComponent } from '../treatment-steps-editor/edit-treatment-step-dialog/edit-treatment-step-dialog.component';
import { TreatmentStepsEditorService } from '../treatment-steps-editor/treatment-steps-editor.service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  AppointmentAutomationsDialogComponent,
  IAutomationsDialogData,
} from 'libs/ng-appointment/src/lib/components/scheduling/appointment-automations-dialog/appointment-automations-dialog.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ChecklistFormDialogComponent } from 'libs/ng-appointment/src/lib/components/scheduling/checklist-form-dialog/checklist-form-dialog.component';
import { AddMultiTreatmentToMultiTreatmentProposalProvider } from '../../charted-surface/chart/add-multi-treatment-to-multi-treatment-proposal-provider';

@Component({
    selector: 'pr-treatment-step-header',
    templateUrl: './treatment-step-header.component.html',
    styleUrls: ['./treatment-step-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreatmentStepHeaderComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  @Output() stepDeleted = new EventEmitter<ITreatmentStep>();
  @Output() stepDisplayChange = new EventEmitter<ITreatmentStepDisplay>();

  step$ = new ReplaySubject<
    WithRef<ITreatmentStep> | IChartedMultiStepTreatmentStep
  >(1);
  plan$ = new ReplaySubject<
    IChartedMultiStepTreatment | WithRef<ITreatmentPlan>
  >(1);
  appointment$ = new ReplaySubject<WithRef<IAppointment> | undefined>(1);
  price$: Observable<number>;
  isIncomplete$: Observable<boolean>;
  isCurrent$: Observable<boolean>;
  isComplete$: Observable<boolean>;
  canSchedule$: Observable<boolean>;
  canDisable$: Observable<boolean>;
  canChangeDuration$: Observable<boolean>;
  canManageStep$: Observable<boolean>;
  automationCount$: Observable<number>;
  checklistItemCount$: Observable<number>;
  nameControl = new TypedFormControl<string>(undefined, { updateOn: 'blur' });
  durationControl = new TypedFormControl<number>(undefined, {
    updateOn: 'blur',
  });
  durationPlaceholder$: Observable<number>;
  practitioner$: Observable<DocumentReference<IStaffer> | undefined>;
  canChangePractitioner$ = new BehaviorSubject<boolean>(false);
  invoice$: Observable<WithRef<Invoice> | undefined>;
  @Input() status: PlanStepPairStatus;
  @Input() inlcudeMultiTreatments = true;
  @Input() hideDelete = false;

  @Input()
  set step(step: WithRef<ITreatmentStep> | IChartedMultiStepTreatmentStep) {
    if (step) {
      this.step$.next(step);
    }
  }

  @Input()
  set plan(plan: IChartedMultiStepTreatment | WithRef<ITreatmentPlan>) {
    if (plan) {
      this.plan$.next(plan);
    }
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment> | undefined) {
    this.appointment$.next(appointment);
  }

  constructor(
    public elementRef: ElementRef,
    private _appointmentScope: CurrentAppointmentScope,
    private _currentScope: CurrentScopeFacade,
    private _chartFacade: ChartFacade,
    private _editorService: TreatmentStepsEditorService,
    private _patientFacade: PatientsFacade,
    private _treatmentStepsFacade: TreatmentStepsFacade,
    private _dialog: MatDialog,
    public organisation: OrganisationService,
    public selectionList: SelectionListStore<
      IChartedItemSelectionSummary<IChartedItem>
    >,
    private _chartDialog: ChartDialogService,
    private _snackBar: MatSnackBar,
    public claimEstimateProvider: ClaimEstimateProviders
  ) {
    this.step$
      .pipe(
        distinctUntilPathsChange('name', 'schedulingRules.duration'),
        takeUntil(this._onDestroy$)
      )
      .subscribe((step) => {
        this.nameControl.patchValue(step.name, { emitEvent: false });
        if (step.schedulingRules.duration > 0) {
          this.durationControl.patchValue(step.schedulingRules.duration, {
            emitEvent: false,
          });
        }
      });

    this.isComplete$ = this.step$.pipe(
      map((step) => TreatmentStep.isComplete(step))
    );

    this.practitioner$ = this.appointment$.pipe(
      withLatestFrom(this.step$),
      map(([appointment, step]) => {
        if (!appointment) {
          this.canChangePractitioner$.next(true);
          return step.practitionerRef ? step.practitionerRef : undefined;
        }
        this.canChangePractitioner$.next(false);
        return appointment.practitioner.ref;
      })
    );

    this.durationPlaceholder$ = this.step$.pipe(
      switchMap((step) =>
        isWithRef(step)
          ? TreatmentStep.durationFromConfigurations$(step)
          : ChartedMultiStepTreatment.resolveStepDuration(step)
      )
    );

    this.isCurrent$ = combineLatest([
      this._appointmentScope.doc$,
      this.appointment$,
    ]).pipe(
      map(([current, stepAppointment]) => {
        if (!current || !stepAppointment) {
          return false;
        }
        return isSameRef(current, stepAppointment);
      })
    );

    this.isIncomplete$ = this.step$.pipe(
      map((step) => TreatmentStep.isIncomplete(step))
    );

    this.price$ = this.step$.pipe(
      map((step) => new ChartedItemTotalCalculator().step(step))
    );

    this.canChangeDuration$ = combineLatest([
      this.appointment$,
      this.step$,
    ]).pipe(
      map(([appointment, step]) => {
        if (step.status === TreatmentStepStatus.Complete) {
          return false;
        }
        return appointment ? Appointment.isUnscheduled(appointment) : true;
      })
    );

    this.canDisable$ = this.appointment$.pipe(
      map((appointment) => !!appointment && !isEventable(appointment))
    );

    this.canSchedule$ = combineLatest([this.appointment$, this.step$]).pipe(
      map(([appointment, step]) => !appointment && isWithRef(step))
    );

    this.durationControl.valueChanges
      .pipe(
        map((duration) => (!duration || duration < 0 ? 0 : duration)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(
        (duration) => void this.patchStep({ schedulingRules: { duration } })
      );

    this.nameControl.valueChanges
      .pipe(filterUndefined(), takeUntil(this._onDestroy$))
      .subscribe((name) => void this.patchStep({ name }));

    this.canManageStep$ = combineLatest([
      this.isComplete$,
      this.appointment$,
    ]).pipe(
      map(([isComplete, appointment]) => !isComplete && !!appointment?.event)
    );

    this.automationCount$ = this.step$.pipe(
      switchMap(async (step) =>
        isWithRef<ITreatmentStep>(step)
          ? getCount(
              toQuery(
                TreatmentStep.automationCol(step),
                where('status', '!=', AutomationStatus.Cancelled)
              )
            )
          : 0
      )
    );

    this.checklistItemCount$ = this.appointment$.pipe(
      filterUndefined(),
      switchMap((appointment) =>
        getCount(undeletedQuery(Appointment.checklistCol(appointment)))
      )
    );

    this.invoice$ = this.appointment$.pipe(
      switchMap((appointment) =>
        appointment ? Appointment.invoice$(appointment) : of(undefined)
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async openEditStepDialog(treatmentStep: ITreatmentStep): Promise<void> {
    const treatmentPlan = await snapshot(this.plan$);
    const appointment = await snapshot(this.appointment$);
    const data = { treatmentPlan, treatmentStep, appointment };
    this._dialog.open(
      EditTreatmentStepDialogComponent,
      DialogPresets.medium({ data })
    );
  }

  isUnscheduled(appointment: WithRef<IAppointment>): boolean {
    return Appointment.isUnscheduled(appointment);
  }

  appointmentDate(appointment: WithRef<IAppointment>): string | undefined {
    if (!appointment.event) {
      return;
    }
    return toMoment(appointment.event.from).format(DATE_TIME_WITH_YEAR_FORMAT);
  }

  appointmentDuration(appointment: WithRef<IAppointment>): string | undefined {
    if (!appointment.event) {
      return;
    }
    return `${Appointment.duration(appointment)} mins`;
  }

  async selectAllTreatments(
    step: WithRef<ITreatmentStep> | IChartedMultiStepTreatmentStep
  ): Promise<void> {
    const plan = await snapshot(this.plan$);
    this.selectionList.resetSelected();

    step.treatments.map((treatment) =>
      this.selectionList.toggleSelected({
        item: treatment,
        step,
        plan,
      })
    );
  }

  async generateAppointment(): Promise<void> {
    const plan = await snapshot(this.plan$);
    const step = await snapshot(this.step$);
    if (!isWithRef(plan) || !isWithRef(step)) {
      return;
    }

    const practice = await snapshot(
      this._currentScope.currentPractice$.pipe(filterUndefined())
    );

    const practitioner = await this._resolvePractitioner(plan, step);
    if (!practitioner) {
      this._noPractitionerSelectedMessage();
      return;
    }

    const patient = await snapshot(
      this._patientFacade.currentPatient$.pipe(filterUndefined())
    );

    await this._editorService.generateAppointmentForStep(
      patient,
      practitioner,
      practice,
      plan,
      step
    );
  }

  async removeAppointment(): Promise<void> {
    const step = await snapshot(this.step$);
    if (!isWithRef(step)) {
      return;
    }
    await this._editorService.removeAppointmentFromStep(step);
  }

  async patchStep(changes: Partial<ITreatmentStep>): Promise<void> {
    const step = await snapshot(this.step$);
    if (isWithRef(step)) {
      await patchDoc(step.ref, { ...changes });
      return;
    }

    const plan = await snapshot(this.plan$);
    if (!isChartedMultiStepTreatment(plan)) {
      return;
    }

    plan.steps = plan.steps.map((planStep) =>
      planStep.uid === step.uid ? { ...planStep, ...changes } : planStep
    );

    await this._chartFacade.updateMultiTreatment(ChartId.InAppointment, plan);
  }

  async addChartable(
    chartable: AnyChartedItemConfiguration,
    selectedSurfaces: Partial<IChartedRef>[]
  ): Promise<void> {
    const addSurfaceProviders = await this._buildServiceProviders();

    await new AddChartable(addSurfaceProviders).add({
      selectedSurfaces,
      chartable,
      chartingAs: await snapshot(
        this._chartFacade.chartingAs$(ChartId.InAppointment)
      ),
    });
    this._chartFacade.setSelectedSurfaces(ChartId.InAppointment, []);
  }

  selectAllDisabled(step: ITreatmentStep): boolean {
    return (
      !step.treatments.length || step.status === TreatmentStepStatus.Complete
    );
  }

  deleteDisabled(step: ITreatmentStep): boolean {
    return !!step.appointment || step.status === TreatmentStepStatus.Complete;
  }

  async getClaimEstimate(): Promise<void> {
    const plan = await snapshot(this.plan$);
    const step = await snapshot(this.step$);
    if (!isWithRef(plan) || !isWithRef(step)) {
      return;
    }

    const practice = await snapshot(
      this._currentScope.currentPractice$.pipe(filterUndefined())
    );

    const practitioner = await this._resolvePractitioner(plan, step);
    if (!practitioner) {
      this._noPractitionerSelectedMessage();
      return;
    }

    const patient = await snapshot(
      this._patientFacade.currentPatient$.pipe(filterUndefined())
    );
    const taxRate = await snapshot(
      this.organisation.taxRate$.pipe(filterUndefined())
    );

    const invoice = Invoice.init({
      from: toAccountDetails(practice),
      to: {
        name: patient.name,
        address: '',
      },
      practice: toNamedDocument(practice),
      items: treatmentStepToLineItems(
        step,
        plan,
        stafferToNamedDoc(practitioner),
        taxRate
      ),
    });

    await this.claimEstimateProvider.capture(invoice, practitioner, patient);
  }

  async addChecklistItem(): Promise<void> {
    const appointment = await snapshot(this.appointment$);

    const config = DialogPresets.medium({
      data: { appointment },
    });
    this._dialog.open(ChecklistFormDialogComponent, config);
  }

  async manageAutomations(): Promise<void> {
    const appointment = await snapshot(this.appointment$);
    if (!appointment) {
      return;
    }
    const config = DialogPresets.medium<IAutomationsDialogData>({
      data: {
        appointment,
      },
    });
    this._dialog.open(AppointmentAutomationsDialogComponent, config);
  }

  getChecklistItemsTooltip(count?: number | null): string {
    return count ? `${count} Checklist Items` : 'Manage Checklist Items';
  }

  getAutomationsTooltip(count?: number | null): string {
    return count ? `${count} Automations` : 'Manage Automations';
  }

  private async _resolvePractitioner(
    plan: WithRef<ITreatmentPlan>,
    step: WithRef<ITreatmentStep>
  ): Promise<WithRef<IStaffer> | undefined> {
    if (step.practitionerRef) {
      return getDoc(step.practitionerRef);
    }
    if (plan.practitioner) {
      return getDoc(plan.practitioner.ref);
    }
    const chartingAs = await snapshot(
      this._chartFacade.chartingAs$(ChartId.InAppointment)
    );
    const canConductAppointment = await snapshot(
      Staffer.hasPermission$(
        chartingAs,
        AppointmentPermissions.AppointmentConduct
      )
    );
    return canConductAppointment
      ? chartingAs
      : this._selectPractitionerDialog();
  }

  private _noPractitionerSelectedMessage(): void {
    const snackRef = this._snackBar.open(
      'You must select a practitioner to enable scheduling',
      'Select provider',
      { duration: 5000, panelClass: 'actionSnackBar' }
    );
    snackRef
      .onAction()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => void this._selectPractitionerDialog());
  }

  private async _selectPractitionerDialog(): Promise<
    WithRef<IStaffer> | undefined
  > {
    return this._dialog
      .open<
        SelectPractitionerDialogComponent,
        { practitioners: WithRef<IStaffer>[] },
        WithRef<IStaffer> | undefined
      >(
        SelectPractitionerDialogComponent,
        DialogPresets.medium({
          data: {
            practitioners: await snapshot(
              this.organisation.practicePractitioners$
            ),
          },
        })
      )
      .afterClosed()
      .toPromise();
  }

  private async _buildServiceProviders(): Promise<IChartedSurfaceProvider[]> {
    const step = await snapshot(this.step$);
    const plan = await snapshot(this.plan$);
    const feeSchedule = await this._resolveFeeSchedule(plan);
    const addSurfaceProviders: IChartedSurfaceProvider[] = [];

    if (isWithRef<ITreatmentStep>(step)) {
      addSurfaceProviders.push(
        new AddTreatmentToStoreStepProvider(
          this._treatmentStepsFacade,
          step,
          feeSchedule
        )
      );

      if (isWithRef(plan)) {
        addSurfaceProviders.push(
          new AddMultiTreatmentToFirestorePlanProvider(
            plan,
            step,
            this._chartDialog
          )
        );
      }
    }

    if (
      isChartedMultiStepTreatment(plan) &&
      isChartedMultiStepTreatmentStep(step)
    ) {
      addSurfaceProviders.push(
        new AddTreatmentToMultiTreatmentProposalProvider(
          this._chartFacade,
          plan,
          step,
          feeSchedule
        ),
        new AddMultiTreatmentToMultiTreatmentProposalProvider(
          this._chartFacade,
          plan,
          step,
          this._chartDialog
        )
      );
    }
    return addSurfaceProviders;
  }

  private async _resolveFeeSchedule(
    plan: ITreatmentPlan | IChartedMultiStepTreatment
  ): Promise<WithRef<IFeeSchedule>> {
    if (isTreatmentPlan(plan) && plan.feeSchedule) {
      return getDoc(plan.feeSchedule.ref);
    }
    return snapshot(this._chartFacade.getFeeScheduleManager().currentSchedule$);
  }
}
