import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { type MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { type MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ChartedConfigurationFacade } from '@principle-theorem/ng-clinical-charting/store';
import {
  DialogPresets,
  InputSearchFilter,
  toSearchStream,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { ChartableSurfaceResolver } from '@principle-theorem/principle-core';
import {
  type IChartedItemConfiguration,
  type IChartedRef,
} from '@principle-theorem/principle-core/interfaces';
import {
  debounceUserInput,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { isString, sortBy } from 'lodash';
import { BehaviorSubject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddChartedConditionComponent } from '../../add-charted-item-dialog/add-charted-condition/add-charted-condition.component';
import { type IAddChartedItem } from '../../add-charted-item-dialog/add-charted-item';

@Component({
    selector: 'pr-conditions-search',
    templateUrl: './conditions-search.component.html',
    styleUrls: ['./conditions-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConditionsSearchComponent {
  private _selectedSurfaces$: BehaviorSubject<Partial<IChartedRef>[]> =
    new BehaviorSubject<Partial<IChartedRef>[]>([]);
  trackByCondition = TrackByFunctions.ref<WithRef<IChartedItemConfiguration>>();
  conditions$: Observable<WithRef<IChartedItemConfiguration>[]>;
  searchCtrl: TypedFormControl<string> = new TypedFormControl('');
  searchFilter: InputSearchFilter<WithRef<IChartedItemConfiguration>>;
  @Output() chartableAdded: EventEmitter<WithRef<IChartedItemConfiguration>> =
    new EventEmitter<WithRef<IChartedItemConfiguration>>();

  constructor(
    private _dialog: MatDialog,
    private _conditionConfigStore: ChartedConfigurationFacade
  ) {
    this.conditions$ = ChartableSurfaceResolver.filterChartableItems$(
      this._conditionConfigStore.conditionConfigurations$,
      this._selectedSurfaces$.pipe(debounceUserInput())
    ).pipe(
      map((chartableItems) => sortBy(chartableItems, (item) => item.name))
    );

    this.searchFilter = new InputSearchFilter<
      WithRef<IChartedItemConfiguration>
    >(this.conditions$, toSearchStream(this.searchCtrl), ['name']);
  }

  @Input()
  set selectedSurfaces(selectedSurfaces: Partial<IChartedRef>[]) {
    if (selectedSurfaces) {
      this._selectedSurfaces$.next(selectedSurfaces);
    }
  }

  optionSelected($event: MatAutocompleteSelectedEvent): void {
    this.chartableAdded.emit(
      $event.option.value as WithRef<IChartedItemConfiguration>
    );
    this.searchCtrl.setValue('');
  }

  async addNewCondition(event: MatOptionSelectionChange): Promise<void> {
    if (!event.isUserInput) {
      return;
    }
    const data: IAddChartedItem = {
      name: this.searchCtrl.value,
      selectedSurfaces: await snapshot(this._selectedSurfaces$),
    };
    this._dialog.open(
      AddChartedConditionComponent,
      DialogPresets.large({ height: '80%', data })
    );
  }

  displayFn(condition?: IChartedItemConfiguration | string): string {
    if (!condition) {
      return '';
    }
    return !isString(condition) ? condition.name : condition;
  }
}
