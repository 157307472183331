import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
  PerioSettingsService,
  StafferSettingsStoreService,
} from '@principle-theorem/ng-principle-shared';
import {
  IconInputOption,
  TypedFormGroup,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  SwitchPattern,
  SwitchDirection,
  IPerioSettings,
  DEFAULT_PERIO_SETTINGS,
} from '@principle-theorem/principle-core/interfaces';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-perio-chart-settings',
  templateUrl: './perio-chart-settings.component.html',
  styleUrl: './perio-chart-settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerioChartSettingsComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  switchPatternOptions: IconInputOption[] = [
    { icon: 'subdirectory_arrow_right', value: SwitchPattern.Reset },
    { icon: 'swap_horiz', value: SwitchPattern.Zigzag },
  ];
  switchDirectionOptions: IconInputOption[] = [
    { icon: 'arrow_back', value: SwitchDirection.Left },
    { icon: 'arrow_forward', value: SwitchDirection.Right },
  ];
  perioSettingsForm = new TypedFormGroup<IPerioSettings>({
    autoSwitchCells: new TypedFormControl(
      DEFAULT_PERIO_SETTINGS.autoSwitchCells
    ),
    switchPattern: new TypedFormControl(DEFAULT_PERIO_SETTINGS.switchPattern),
    switchDirection: new TypedFormControl(
      DEFAULT_PERIO_SETTINGS.switchDirection
    ),
  });

  constructor(
    private _stafferSettings: StafferSettingsStoreService,
    private _perioSettings: PerioSettingsService
  ) {
    this._stafferSettings.charting$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((settings) => {
        this.perioSettingsForm.patchValue(settings.perio, {
          emitEvent: false,
        });
        this._perioSettings.applyStafferSettings(settings.perio);
      });

    this.perioSettingsForm.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((settings) => {
        this._perioSettings.applyStafferSettings(settings);
        this._stafferSettings.updateStafferSettings({
          charting: { perio: settings },
        });
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
