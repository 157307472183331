import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  TypedFormControl,
  InputSearchFilter,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { of, ReplaySubject } from 'rxjs';
import {
  type ITreatmentWithPlan,
  NEW_DEPOSIT,
  CANCELLATION_FEE,
} from '../line-items-search';
import { toSearchInput$ } from '@principle-theorem/ng-shared';
import { multiMap, type WithRef } from '@principle-theorem/shared';
import {
  type ICustomLineItem,
  type IDepositLineItem,
  type IProduct,
  type IProductLineItem,
} from '@principle-theorem/principle-core/interfaces';
import {
  depositToLineItem,
  feeToLineItem,
  productToLineItem,
} from '@principle-theorem/principle-core';
import { TaxRate } from '@principle-theorem/accounting';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-line-item-selector',
  templateUrl: './line-item-selector.component.html',
  styleUrls: ['./line-item-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineItemSelectorComponent {
  private _products$ = new ReplaySubject<WithRef<IProduct>[]>(1);
  private _treatmentWithPlans$ = new ReplaySubject<ITreatmentWithPlan[]>(1);
  private _taxRate$ = new ReplaySubject<TaxRate>(1);
  trackByItem = TrackByFunctions.index<ICustomLineItem>();
  trackByTreatment =
    TrackByFunctions.ref<ITreatmentWithPlan>('treatmentPlan.ref');
  trackByProduct = TrackByFunctions.index<IProductLineItem>();
  searchCtrl: TypedFormControl<string> = new TypedFormControl<string>('');
  @Output() optionSelected = new EventEmitter<ICustomLineItem>();
  productSearch: InputSearchFilter<IProductLineItem>;
  treatmentSearch: InputSearchFilter<ITreatmentWithPlan>;
  miscSearch: InputSearchFilter<ICustomLineItem | IDepositLineItem>;

  @Input()
  set products(products: WithRef<IProduct>[]) {
    if (products) {
      this._products$.next(products);
    }
  }

  @Input()
  set treatments(treatmentWithPlans: ITreatmentWithPlan[]) {
    if (treatmentWithPlans) {
      this._treatmentWithPlans$.next(treatmentWithPlans);
    }
  }

  @Input()
  set taxRate(taxRate: TaxRate) {
    if (taxRate) {
      this._taxRate$.next(taxRate);
    }
  }

  constructor() {
    this.productSearch = new InputSearchFilter<IProductLineItem>(
      this._taxRate$.pipe(
        switchMap((taxRate) =>
          this._products$.pipe(
            multiMap((product) => productToLineItem(product, taxRate))
          )
        )
      ),
      toSearchInput$(this.searchCtrl),
      ['description']
    );
    this.treatmentSearch = new InputSearchFilter<ITreatmentWithPlan>(
      this._treatmentWithPlans$,
      toSearchInput$(this.searchCtrl),
      ['treatment.config.name']
    );
    this.miscSearch = new InputSearchFilter(
      of([depositToLineItem(NEW_DEPOSIT), feeToLineItem(CANCELLATION_FEE)]),
      toSearchInput$(this.searchCtrl),
      (item) => item.description
    );
  }

  displayFn(): string {
    return '';
  }
}
