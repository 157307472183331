<ng-container *ngIf="tasks$ | async as tasks">
  <mat-card appearance="outlined" *ngIf="tasks.length">
    <mat-card-header>
      <mat-card-title>Tasks</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <pr-task-list-display
        multiSelectDisabled
        [tasks]="tasks$ | async"
        [selectionList]="selectionList"
        (selectedItemChange)="editTask($event)"
        (taskUpdated)="updateTask()"
      />
    </mat-card-content>
  </mat-card>
</ng-container>
