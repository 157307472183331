import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  isEnumValue,
  isObject,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isNull, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { PATIENT_APPOINTMENT_DESTINATION_ENTITY } from '../../destination/entities/patient-appointments';
import { PATIENT_INVOICE_DESTINATION_ENTITY } from '../../destination/entities/patient-invoices';
import { PatientSourceEntity } from './patient';
import { PATIENT_APPOINTMENT_RESOURCE_TYPE } from '../../../destination/entities/patient-appointments';

export const PATIENT_APPOINTMENT_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Appointment List',
      description: '',
      idPrefix: PATIENT_APPOINTMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export type PraktikaAppointmentId = string;

export enum PraktikaAppointmentStatus {
  Scheduled = 1,
  Modified = 2,
  Deleted = 3,
  Completed = 4,
  PaidInFull = 5,
  OnHold = 6,
}

export enum PraktikaAppointmentSMSStatus {
  Scheduled = 1,
  Sent = 2,
  Delivered = 3,
  ResponseOk = 4,
  ResponseOther = 5,
  Undelivered = 6,
}

export enum PraktikaInAppointmentStatus {
  Fta = 1,
  Arrived = 2,
  AtSurgery = 3,
  Left = 4,
}

export interface IPraktikaAppointment {
  appointment_id: number;
  appointment_patientid: number;
  appointment_statusid: PraktikaAppointmentStatus;
  appointment_starttime: string | null; // '2015-10-22 09:30:00'
  appointment_duration: number; // minutes * 15
  appointment_providerid: number | null;
  appointment_responseid: 0 | 1 | 2 | 3 | 4 | null;
  appointment_treatmenttypeid: number | null;
  appointment_arrivalstatusid: 0 | PraktikaInAppointmentStatus | null;
  appointment_createddate: string; // '21/10/2015';
  appointment_taxinvoice_id: number | null;
  appointment_taxinvoice_date: string;

  // These have queries
  // appointment_taxinvoice: IPraktikaAppointmentInvoice;
  // appointment_notes: IPraktikaAppointmentNote[];
  // appointment_procedures: IPraktikaAppointmentProcedure[];

  // This needs to be calculated
  // appointment_endtime: string | null; // '2015-10-22 10:30:00';

  // These aren't required
  // appointment_smsstatusid: PraktikaAppointmentSMSStatus;
  // appointment_practiceid: number;
  // appointment_resourceid: number;
  // appointment_treatmenttype: IPraktikaAppointmentTreatmentType;
  // appointment_icon1id: number;
  // appointment_icon2id: number;
  // appointment_icon3id: number;
  // appointment_icon4id: number;
  // appointment_balance: number;
  // appointment_value: number;
  // appointment_newpatient: boolean;
  // appointment_medicalalert: boolean;
  // appointment_onlinebooking: boolean;
  // appointment_createdbyusername: string;
}

export function isPraktikaAppointment(
  item: unknown
): item is IPraktikaAppointment {
  return (
    isObject(item) &&
    isNumber(item.appointment_id) &&
    isNumber(item.appointment_patientid) &&
    isEnumValue(PraktikaAppointmentStatus, item.appointment_statusid) &&
    (isString(item.appointment_starttime) ||
      isNull(item.appointment_starttime)) &&
    isNumber(item.appointment_duration) &&
    (isNumber(item.appointment_providerid) ||
      isNull(item.appointment_providerid)) &&
    ((isNumber(item.appointment_responseid) &&
      [0, 1, 2, 3, 4].includes(item.appointment_responseid)) ||
      isNull(item.appointment_responseid)) &&
    (isNumber(item.appointment_treatmenttypeid) ||
      isNull(item.appointment_treatmenttypeid)) &&
    ((isNumber(item.appointment_arrivalstatusid) &&
      isEnumValue(
        PraktikaInAppointmentStatus,
        item.appointment_arrivalstatusid
      )) ||
      item.appointment_arrivalstatusid === 0 ||
      isNull(item.appointment_arrivalstatusid)) &&
    isString(item.appointment_createddate) &&
    (isNumber(item.appointment_taxinvoice_id) ||
      isNull(item.appointment_taxinvoice_id)) &&
    isString(item.appointment_taxinvoice_date)

    // isNumber(item.appointment_practiceid) &&
    // isNumber(item.appointment_resourceid) &&
    // (isString(item.appointment_endtime) || isNull(item.appointment_endtime)) &&
    // isNumber(item.appointment_icon1id) &&
    // isNumber(item.appointment_icon2id) &&
    // isNumber(item.appointment_icon3id) &&
    // isNumber(item.appointment_icon4id) &&
    // isNumber(item.appointment_balance) &&
    // isNumber(item.appointment_value) &&
    // isBoolean(item.appointment_newpatient) &&
    // isBoolean(item.appointment_medicalalert) &&
    // isBoolean(item.appointment_onlinebooking) &&
    // isString(item.appointment_createdbyusername) &&
    // TypeGuard.arrayOf(isPraktikaAppointmentNote)(item.appointment_notes) &&
    // isNumber(item.appointment_smsstatusid) &&
    // TypeGuard.arrayOf(isPraktikaAppointmentProcedure)(item.appointment_procedures) &&
    // isPraktikaAppointmentTreatmentType(item.appointment_treatmenttype) &&
    // isPraktikaAppointmentInvoice(item.appointment_taxinvoice)
  );
}

export interface IPraktikaAppointmentTreatmentType {
  id: number;
  desc: string | null;
  label: string | null;
  colour: string; // hex colour '#000000'
}

export function isPraktikaAppointmentTreatmentType(
  item: unknown
): item is IPraktikaAppointmentTreatmentType {
  return (
    isObject(item) &&
    isNumber(item.id) &&
    (isString(item.desc) || isNull(item.desc)) &&
    (isString(item.label) || isNull(item.label)) &&
    isString(item.colour)
  );
}

export interface IPraktikaAppointmentTranslation {
  appointment_practiceid: number;
  appointment_resourceid: number;
  appointment_patientid: number;
  appointment_providerid: number;
}

export enum PraktikaFeeScheduleGroup {
  Examinations = 1,
  Periodontics = 7,
  Extractions = 8,
  SurgicalExtractions = 9,
  Crowns = 25,
  ProvisionalCrownsAndBridges = 26,
  Bridges = 27,
  CrownAndBridgesRepairs = 28,
  Implants = 29,
  DenturesAndComponents = 30,
  DentureMaintenance = 31,
  DentureRepairs = 32,
  Custom = 45,
}

// export interface IPraktikaAppointmentInvoice {
//   id: number;
//   number: string | number;
//   statusId: PraktikaInvoiceStatus;
//   date: string | null; // '2020-02-24'
//   providerId: number | null;
//   guarantorId: number | null;
//   adjustments: IPraktikaAppointmentInvoiceAdjustment[];
//   payments: IPraktikaAppointmentInvoicePayment[];
// }

// export enum PraktikaInvoiceStatus {
//   Draft = 1,
//   Issued = 2,
//   Paid = 3,
// }

// export function isPraktikaAppointmentInvoice(
//   item: unknown
// ): item is IPraktikaAppointmentInvoice {
//   return (
//     isObject(item) &&
//     isNumber(item.id) &&
//     (isString(item.number) || isNumber(item.number)) &&
//     isEnumValue(PraktikaInvoiceStatus, item.statusId) &&
//     (isString(item.date) || isNull(item.date)) &&
//     (isNumber(item.providerId) || isNull(item.providerId)) &&
//     (isNumber(item.guarantorId) || isNull(item.guarantorId)) &&
//     TypeGuard.arrayOf(isPraktikaAppointmentInvoiceAdjustment)(item.adjustments) &&
//     TypeGuard.arrayOf(isPraktikaAppointmentInvoicePayment)(item.payments)
//   );
// }

export interface IPraktikaAppointmentTranslations {
  from?: Timestamp;
  to?: Timestamp;
  createdAt: Timestamp;
}

export interface IPraktikaAppointmentFilters {
  patientId: string;
  status: PraktikaAppointmentStatus;
  from?: Timestamp;
  createdAt: Timestamp;
}

const PATIENT_APPOINTMENT_SOURCE_QUERY = `
SELECT
  iAppointmentId as appointment_id,
  iPatientNumber as appointment_patientid,
  dtStartTime as appointment_starttime,
  iStatusId as appointment_statusid,
  iLength as appointment_duration,
  iProviderId as appointment_providerid,
  iResponseId as appointment_responseid,
  dtCreated as appointment_createddate,
  iTreatmentTypeId as appointment_treatmenttypeid,
  iTaxInvoiceNumber as appointment_taxinvoice_id,
  dtTaxInvoiceDate as appointment_taxinvoice_date,
  iArrivalStatusId as appointment_arrivalstatusid
FROM appointments
ORDER BY appointment_createddate DESC
`;

export class PatientAppointmentSourceEntity extends BaseSourceEntity<
  IPraktikaAppointment,
  IPraktikaAppointmentTranslations,
  IPraktikaAppointmentFilters
> {
  sourceEntity = PATIENT_APPOINTMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_APPOINTMENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_APPOINTMENT_SOURCE_QUERY;
  verifySourceFn = isPraktikaAppointment;
  override transformDataFn = flow([]);
  override dateFilterField: keyof IPraktikaAppointmentFilters = 'createdAt';

  migrationDestinations = [
    PATIENT_APPOINTMENT_DESTINATION_ENTITY.metadata.key,
    PATIENT_INVOICE_DESTINATION_ENTITY.metadata.key,
  ];

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  translate(
    appointment: IPraktikaAppointment,
    timezone: Timezone
  ): IPraktikaAppointmentTranslations {
    return {
      from: appointment.appointment_starttime
        ? toTimestamp(
            moment.tz(
              appointment.appointment_starttime,
              ISO_DATE_TIME_FORMAT,
              timezone
            )
          )
        : undefined,
      to: appointment.appointment_starttime
        ? toTimestamp(
            moment
              .tz(
                appointment.appointment_starttime,
                ISO_DATE_TIME_FORMAT,
                timezone
              )
              .add({ minutes: appointment.appointment_duration * 15 })
          )
        : undefined,
      createdAt: toTimestamp(
        moment.tz(
          appointment.appointment_createddate,
          ISO_DATE_TIME_FORMAT,
          timezone
        )
      ),
    };
  }

  getSourceRecordId(data: IPraktikaAppointment): number {
    return data.appointment_id;
  }

  getSourceLabel(data: IPraktikaAppointment): string {
    return `${data.appointment_patientid} ${data.appointment_starttime ?? ''}`;
  }

  getFilterData(
    data: IPraktikaAppointment,
    timezone: Timezone
  ): IPraktikaAppointmentFilters {
    return {
      patientId: data.appointment_patientid.toString(),
      status: data.appointment_statusid,
      from: data.appointment_starttime
        ? toTimestamp(
            moment.tz(
              data.appointment_starttime,
              ISO_DATE_TIME_FORMAT,
              timezone
            )
          )
        : undefined,
      createdAt: toTimestamp(
        moment.tz(data.appointment_createddate, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }
}
