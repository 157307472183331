import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  DYNAMIC_COMPONENT_DATA,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import { TWAIN_INTEGRATION } from '@principle-theorem/principle-bridge-core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IIntegrationActionData } from '../../../../integration-action-data';
import { BridgeDeviceSelectorService } from '../../../bridge-device-selector-dialog/bridge-device-selector.service';
import { TwainFeatureService } from '../twain-feature.service';
import { MatDialog } from '@angular/material/dialog';
import {
  ITwainDeviceSelectDialogData,
  TwainDeviceSelectDialogComponent,
} from './twain-device-select-dialog/twain-device-select-dialog.component';
import { Firestore } from '@principle-theorem/shared';
import { ITwainDevice } from '@principle-theorem/principle-core/interfaces';

@Component({
    selector: 'pr-twain-trigger-scan',
    templateUrl: './twain-trigger-scan.component.html',
    styleUrls: ['./twain-trigger-scan.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TwainTriggerScanComponent {
  isDisabled$: Observable<boolean>;

  constructor(
    @Inject(DYNAMIC_COMPONENT_DATA)
    private _data: IIntegrationActionData,
    private _twain: TwainFeatureService,
    private _bridgeDeviceSelector: BridgeDeviceSelectorService,
    private _dialog: MatDialog
  ) {
    this.isDisabled$ = of(this._data.patient).pipe(
      map((patient) => !patient.dateOfBirth)
    );
  }

  async scan(): Promise<void> {
    const deviceRef =
      await this._bridgeDeviceSelector.selectDevice(TWAIN_INTEGRATION);

    if (!deviceRef) {
      return;
    }
    const device = await Firestore.getDoc(deviceRef);
    const deviceVersion = device.settings?.version;

    if (!deviceVersion || deviceVersion <= '1.163.0') {
      await this._twain.triggerScanLegacy(this._data.patient, deviceRef);
      return;
    }

    await this._twain.syncDevices(deviceRef);

    const twainDevice = await this._dialog
      .open<
        TwainDeviceSelectDialogComponent,
        ITwainDeviceSelectDialogData,
        ITwainDevice | undefined
      >(
        TwainDeviceSelectDialogComponent,
        DialogPresets.medium({
          data: { deviceRef },
        })
      )
      .afterClosed()
      .toPromise();

    if (!twainDevice) {
      return;
    }

    await this._twain.triggerScan(this._data.patient, twainDevice, deviceRef);
  }
}
