import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type ITag,
  IScheduleSummaryEventable,
} from '@principle-theorem/principle-core/interfaces';
import { multiSwitchMap, type WithRef } from '@principle-theorem/shared';
import { clamp, compact } from 'lodash';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalStoreService } from '../../store/global-store.service';

@Component({
  selector: 'pr-eventable-tags-preview',
  templateUrl: './eventable-tags-preview.component.html',
  styleUrls: ['./eventable-tags-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventableTagsPreviewComponent {
  private _tags$: Observable<WithRef<ITag>[]>;
  tagsList$: Observable<WithRef<ITag>[]>;
  eventable$ = new ReplaySubject<IScheduleSummaryEventable>(1);
  extraTagCount$: Observable<number>;
  trackByTag = TrackByFunctions.ref<WithRef<ITag>>();
  @Input() dense = false;

  @Input()
  set eventable(eventable: IScheduleSummaryEventable) {
    if (eventable) {
      this.eventable$.next(eventable);
    }
  }

  constructor(globalStore: GlobalStoreService) {
    this._tags$ = this.eventable$.pipe(
      map((eventable) => eventable.metadata.tags),
      multiSwitchMap((tag) => globalStore.getTag$(tag.ref)),
      map(compact)
    );

    this.tagsList$ = this._tags$.pipe(
      map((tags) => (this.dense ? tags.slice(0, 2) : tags))
    );

    this.extraTagCount$ = this._tags$.pipe(
      map((tags) => clamp(tags.length - 2, 0, Infinity))
    );
  }
}
