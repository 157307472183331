import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaxStrategy } from '@principle-theorem/accounting';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { type IPractice } from '@principle-theorem/principle-core/interfaces';
import { Practice } from '@principle-theorem/principle-core';
import { type WithRef } from '@principle-theorem/shared';

@Component({
  selector: 'pr-upsert-practice-dialog',
  templateUrl: './upsert-practice-dialog.component.html',
  styleUrls: ['./upsert-practice-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsertPracticeDialogComponent {
  form: TypedFormGroup<IPracticeFormData> =
    new TypedFormGroup<IPracticeFormData>({
      name: new TypedFormControl('', Validators.required),
    });
  taxStatusOptions = [TaxStrategy.GSTFree, TaxStrategy.GSTApplicable];
  trackByTaxStatus = TrackByFunctions.variable();
  update = false;

  constructor(
    private _dialogRef: MatDialogRef<UpsertPracticeDialogComponent, IPractice>,
    @Inject(MAT_DIALOG_DATA) public data?: IPracticeData
  ) {
    if (!this.data?.practice) {
      return;
    }
    this.update = true;
    this.form.patchValue(this.data.practice);
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }

    const data: IPracticeFormData = this.form.getRawValue();
    const practice: IPractice = this.data?.practice
      ? {
          ...this.data.practice,
          ...data,
        }
      : Practice.init(data);

    this._dialogRef.close(practice);
  }
}

export interface IPracticeData {
  practice?: WithRef<IPractice>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPracticeFormData extends Pick<IPractice, 'name'> {}
