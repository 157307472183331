import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  TREATMENT_PLAN_STATUS_COLOUR_MAP,
  TreatmentPlanStatus,
} from '@principle-theorem/principle-core/interfaces';

@Component({
    selector: 'pr-treatment-plan-status',
    templateUrl: './treatment-plan-status.component.html',
    styleUrls: ['./treatment-plan-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreatmentPlanStatusComponent {
  @Input() status: TreatmentPlanStatus;
  isDense = false;
  isInline = false;
  statusColourMap = TREATMENT_PLAN_STATUS_COLOUR_MAP;

  @Input()
  set dense(dense: BooleanInput) {
    this.isDense = coerceBooleanProperty(dense);
  }

  @Input()
  set inline(inline: BooleanInput) {
    this.isInline = coerceBooleanProperty(inline);
  }
}
