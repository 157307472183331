import {
  IContactNumber,
  IPatientContactDetails,
  ISORegionCode,
  SMS_INTEGRATION_REGION_CODE_MAP,
  SUPPORTED_REGION_CODE_MAP,
  SmsIntegrationOption,
} from '@principle-theorem/principle-core/interfaces';
import { compact, isEmpty, last } from 'lodash';
import { ParsedPhoneNumber, parsePhoneNumber } from 'awesome-phonenumber';
import { EMAIL_REGEX, NON_DIGITS, Region } from '@principle-theorem/shared';

export function findMobileNumber(
  contactNumbers: IContactNumber[] = [],
  region: Region,
  smsProvider?: SmsIntegrationOption
): string | undefined {
  const mobile = contactNumbers.find((contact) =>
    isMobileNumber(contact, smsProvider)
  );
  return mobile ? formatPhoneNumber(mobile.number, region) : undefined;
}

export function formatPhoneNumber(
  mobileNumber: string,
  region: Region
): string {
  const parsedNumber = parsePhoneNumber(mobileNumber, {
    regionCode: getRegionCode(region),
  });

  if (!parsedNumber || !parsedNumber.valid) {
    return mobileNumber.replace(NON_DIGITS, '');
  }

  const extension = getNumberExtension(parsedNumber);
  return `${parsedNumber.number.e164}${extension}`;
}

export function isMobileNumber(
  contactNumber: IContactNumber,
  smsProvider?: SmsIntegrationOption
): boolean {
  if (!smsProvider) {
    return false;
  }

  const validRegions = getValidRegionCodes(smsProvider);

  return validRegions.some((regionCode) => {
    const parsed = parsePhoneNumber(contactNumber.number, {
      regionCode,
    });
    return (
      !!parsed.typeIsMobile &&
      !!parsed.regionCode &&
      validRegions.includes(parsed.regionCode as ISORegionCode)
    );
  });
}

export function getNationalNumber(
  contactNumber: string,
  region: Region
): string | undefined {
  return parsePhoneNumber(contactNumber, {
    regionCode: getRegionCode(region),
  }).number?.national?.replace(NON_DIGITS, '');
}

export function hasContactableNumber(
  contactNumbers: IContactNumber[]
): boolean {
  return contactNumbers.some((contactNumber) => !isEmpty(contactNumber.number));
}

export function isEmail(data: string): boolean {
  return EMAIL_REGEX.test(data.trim());
}

export function hasValidContactDetails(
  data: Partial<IPatientContactDetails>,
  region: Region,
  smsProvider?: SmsIntegrationOption
): boolean {
  return hasValidEmail(data) || hasValidMobile(data, region, smsProvider);
}

export function hasValidMobile(
  data: Partial<IPatientContactDetails>,
  region: Region,
  smsProvider?: SmsIntegrationOption
): boolean {
  return !!findMobileNumber(data.contactNumbers, region, smsProvider);
}

export function getPhoneNumberVariants(
  contactNumber: string,
  region: Region
): string[] {
  return compact([
    formatPhoneNumber(contactNumber, region),
    getNationalNumber(contactNumber, region),
  ]);
}

export function hasValidEmail(data: Partial<IPatientContactDetails>): boolean {
  return !!data.email && isEmail(data.email);
}

export function getNumberExtension(parsedNumber: ParsedPhoneNumber): string {
  if (parsedNumber.number?.international.includes('ext. ')) {
    return last(parsedNumber.number?.international.split('ext. ')) ?? '';
  }
  return '';
}

function getValidRegionCodes(
  smsProvider: SmsIntegrationOption
): ISORegionCode[] {
  return SMS_INTEGRATION_REGION_CODE_MAP[smsProvider];
}

function getRegionCode(region: Region): ISORegionCode {
  return SUPPORTED_REGION_CODE_MAP[region];
}
