<div *ngIf="config$ | async as config" fxLayout="column">
  <mat-form-field>
    <mat-label>Action</mat-label>
    <input
      matInput
      [value]="config.type | map: formHelpers.getTypeLabel : formHelpers"
      disabled
    />
  </mat-form-field>

  <mat-form-field *ngIf="config.subject">
    <mat-label>Subject</mat-label>
    <input matInput [value]="config.subject" disabled />
  </mat-form-field>

  <ng-container *ngIf="template$ | async as template">
    <mat-form-field>
      <mat-label>Template Name</mat-label>
      <input matInput [value]="template.name" disabled />
    </mat-form-field>

    <pr-template-preview
      [template]="preview$ | async"
      [context]="context$ | async"
      [type]="template.type"
    />
  </ng-container>

  <div class="mat-caption">* This outcome is an example</div>
</div>
