<ng-container *ngIf="lastAppointment$ | async as lastAppointment">
  <div>
    <strong>Last Visit: </strong>
    <pr-formatted-event-field
      field="from"
      [event]="lastAppointment.event"
      [format]="dateFormat"
     />
    <ng-container *ngIf="lastTreatments$ | async as lastTreatments">
      <a
        class="treatments-link"
        [matTooltip]="lastTreatments"
        matTooltipClass="tooltip-multiline"
      >
        treatments
      </a>
    </ng-container>
  </div>
</ng-container>
