import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PatientActionsFactoryService } from '@principle-theorem/ng-interactions';
import {
  DateService,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { IActionButton } from '@principle-theorem/ng-shared';
import { Patient } from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  HISTORY_DATE_FORMAT,
  filterUndefined,
  type WithRef,
} from '@principle-theorem/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { BehaviorSubject, ReplaySubject, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-patient-summary',
    templateUrl: './patient-summary.component.html',
    styleUrls: ['./patient-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientSummaryComponent {
  readonly dateFormat = HISTORY_DATE_FORMAT;
  patient$ = new BehaviorSubject<WithRef<IPatient> | undefined>(undefined);
  appointment$ = new ReplaySubject<WithRef<IAppointment>>(1);
  hasAppointmentTags$: Observable<boolean>;
  lastAppointment$ = this._getLastVisit$();
  nextScheduledAppointment$ = this._getNextVisit$();
  patientActions: IActionButton[];

  @Input()
  set patient(patient: WithRef<IPatient> | undefined) {
    this.patient$.next(patient);
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor(
    public stateNav: StateBasedNavigationService,
    public dateService: DateService,
    actionFactory: PatientActionsFactoryService
  ) {
    const actionPatient$ = this.patient$.pipe(filterUndefined());
    this.patientActions = [
      actionFactory.edit(actionPatient$),
      actionFactory.sms(actionPatient$),
      actionFactory.call(actionPatient$),
      actionFactory.email(actionPatient$),
    ];

    this.hasAppointmentTags$ = this.appointment$.pipe(
      map((appointment) => appointment.tags.length > 0)
    );
  }

  private _getLastVisit$(): Observable<WithRef<IAppointment> | undefined> {
    return this.patient$.pipe(
      switchMap((patient) =>
        patient ? Patient.lastCompletedAppointment(patient) : of(undefined)
      )
    );
  }

  private _getNextVisit$(): Observable<WithRef<IAppointment> | undefined> {
    return this.patient$.pipe(
      switchMap((patient) =>
        patient ? Patient.nextScheduledAppointment$(patient) : of(undefined)
      )
    );
  }
}
