import { initVersionedSchema } from '@principle-theorem/editor';
import {
  ClinicalNote,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  IDestinationEntityJobRunOptions,
  IHasSourceIdentifier,
  ITranslationMap,
  type FailedDestinationEntityRecord,
  type IClinicalNote,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  ISO_DATE_TIME_FORMAT,
  asyncForEach,
  getError,
  snapshotCombineLatest,
  toISODate,
  type WithRef,
  toTimestamp,
} from '@principle-theorem/shared';
import * as he from 'he';
import * as moment from 'moment-timezone';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import {
  BasePatientClinicalNoteDestinationEntity,
  IClinicalNoteJobData,
  IClinicalNoteMigrationData,
} from '../../../destination/entities/patient-clinical-notes';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { getPractitionerOrDefaultMapping } from '../../../mappings/staff';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientSourceEntity,
  type IPraktikaPatient,
} from '../../source/entities/patient';
import {
  PatientClinicalNoteSourceEntity,
  PraktikaClinicalNoteType,
  type IPraktikaClinicalNote,
  type IPraktikaClinicalNoteTranslations,
} from '../../source/entities/patient-clinical-notes';
import { PraktikaItemCodeMappingHandler } from '../mappings/item-code';
import { PraktikaStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';

export class PatientClinicalNoteDestinationEntity extends BasePatientClinicalNoteDestinationEntity<IPraktikaPatient> {
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    clinicalNotes: new PatientClinicalNoteSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new PraktikaStafferMappingHandler(),
    itemCodes: new PraktikaItemCodeMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IClinicalNoteJobData<IPraktikaPatient>[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([staff$, sourceItemCodes$]),
    ]).pipe(
      map(([sourcePatients, [staff, sourceItemCodes]]) =>
        sourcePatients.map((sourcePatient) => ({
          staff,
          sourcePatient,
          sourceItemCodes,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IClinicalNoteJobData<IPraktikaPatient>
  ): Promise<
    | IClinicalNoteMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.patient_id.toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        `Couldn't resolve patient`
      );
    }

    try {
      const notes = await this.sourceEntities.clinicalNotes.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

      const clinicalNotes = await buildClinicalNotes(
        migration,
        notes,
        data.staff,
        [PraktikaClinicalNoteType.Clinical]
      );

      return {
        sourcePatientId,
        patientRef,
        clinicalNotes,
      };
    } catch (error) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        getError(error)
      );
    }
  }
}

export async function buildClinicalNotes(
  migration: WithRef<IPracticeMigration>,
  notes: IGetRecordResponse<
    IPraktikaClinicalNote,
    IPraktikaClinicalNoteTranslations
  >[],
  staff: WithRef<ITranslationMap<IStaffer>>[],
  noteTypeFilters: PraktikaClinicalNoteType[] = []
): Promise<(IClinicalNote & IHasSourceIdentifier)[]> {
  return asyncForEach(
    notes
      .filter((note) => !note.data.data.date_deleted)
      .filter((note) => {
        if (!noteTypeFilters.length) {
          return true;
        }
        return noteTypeFilters.includes(note.data.data.type);
      }),
    async (note) => {
      const authorId = note.data.data.author_id?.toString();
      const sourceOwner = getPractitionerOrDefaultMapping(authorId, staff)
        ?.destinationIdentifier;

      if (!sourceOwner) {
        throw new Error(`No author found for the id ${authorId ?? ''}`);
      }

      const owner = await Firestore.getDoc(sourceOwner);
      const createdAt = moment.tz(
        note.data.data.date_created,
        ISO_DATE_TIME_FORMAT,
        migration.configuration.timezone
      );

      return {
        sourceIdentifier: note.record.uid,
        ...ClinicalNote.init({
          owner: stafferToNamedDoc(owner),
          content: note.data.data.text
            ? initVersionedSchema(he.decode(note.data.data.text))
            : initVersionedSchema(),
          createdAt: toTimestamp(createdAt),
          immutable: true,
          recordDate: toISODate(createdAt),
        }),
      };
    }
  );
}
