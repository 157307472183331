import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { isObject, type Timezone } from '@principle-theorem/shared';
import { flow, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_APPOINTMENT_BOOK_RESOURCE_TYPE = 'appointmentBook';

export const PATIENT_APPOINTMENT_BOOK_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Appointment Book List',
      description: '',
      idPrefix: PATIENT_APPOINTMENT_BOOK_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ID4WAppointmentBook {
  id: number;
  description: string;
  practice_id: number;
}

export function isD4WAppointment(item: unknown): item is ID4WAppointmentBook {
  return (
    isObject(item) &&
    isNumber(item.id) &&
    isString(item.description) &&
    isNumber(item.practice_id)
  );
}

export interface ID4WAppointmentBookFilters {
  practiceId: string;
}

const PATIENT_APPOINTMENT_BOOK_SOURCE_QUERY = `
SELECT
  app_book_number AS id,
  app_book_description AS description,
  practice_id
FROM app_books
WHERE app_book_number IS NOT NULL
`;

export class PatientAppointmentBookSourceEntity extends BaseSourceEntity<
  ID4WAppointmentBook,
  unknown,
  ID4WAppointmentBookFilters
> {
  sourceEntity = PATIENT_APPOINTMENT_BOOK_SOURCE_ENTITY;
  entityResourceType = PATIENT_APPOINTMENT_BOOK_RESOURCE_TYPE;
  sourceQuery = PATIENT_APPOINTMENT_BOOK_SOURCE_QUERY;
  verifySourceFn = isD4WAppointment;
  override transformDataFn = flow([]);

  translate(_appointment: ID4WAppointmentBook, _timezone: Timezone): unknown {
    return {};
  }

  getSourceRecordId(data: ID4WAppointmentBook): number {
    return data.id;
  }

  getSourceLabel(data: ID4WAppointmentBook): string {
    return data.id.toString();
  }

  getFilterData(
    data: ID4WAppointmentBook,
    _timezone: Timezone
  ): ID4WAppointmentBookFilters {
    return {
      practiceId: data.practice_id.toString(),
    };
  }
}
