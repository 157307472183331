import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';
import {
  GapStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { MOMENT_DATEPICKER_PROVIDERS } from '@principle-theorem/ng-shared';
import { IEvent, IStaffer } from '@principle-theorem/principle-core/interfaces';
import { WithRef, isSameRef, toMoment } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GapSearchFormGroup } from './gap-search-form';

@Component({
  selector: 'pr-gap-search',
  templateUrl: './gap-search.component.html',
  styleUrls: ['./gap-search.component.scss'],
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GapSearchComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  readonly dateFormat = 'MMM Do';
  gapSearchForm = new GapSearchFormGroup();

  @ViewChild(MatMenu, { static: true }) menu: MatMenu;
  @ViewChild(MatMenuTrigger, { static: true }) menuTrigger: MatMenuTrigger;

  @Output() closeMenu = new EventEmitter<void>();

  constructor(
    public gapStore: GapStoreService,
    public schedulingFacade: AppointmentSchedulingFacade,
    public organisation: OrganisationService
  ) {
    this.gapStore.dateRange$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((dateRange) => this.gapSearchForm.patchValue({ dateRange }));

    this.gapStore.searchFilters$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((filters) => {
        if (!filters) {
          return;
        }
        this.gapSearchForm.patchValue(filters, { emitEvent: false });
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  compareStaff(a: WithRef<IStaffer>, b: WithRef<IStaffer>): boolean {
    return isSameRef(a, b);
  }

  async submit(): Promise<void> {
    if (this.gapSearchForm.invalid) {
      return;
    }
    await this.gapStore.getSearchResults(this.gapSearchForm.value);
  }

  async setToNextAvailableGap(gap: IEvent): Promise<void> {
    const nextAvailableDate = toMoment(gap.from);
    this.gapSearchForm.controls.dateRange.setValue({
      from: nextAvailableDate,
      to: nextAvailableDate,
    });

    await this.submit();
    this.closeMenu.emit();
  }
}
