import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import {
  MixedSchema,
  RawInlineNodes,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  CurrentScopeFacade,
  GlobalStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  Brand,
  Chat,
  Practice,
  toMention,
} from '@principle-theorem/principle-core';
import {
  ChatStatus,
  ChatType,
  IChat,
  IStaffer,
  MentionResourceType,
  type IChatMessage,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  doc$,
  filterUndefined,
  isRefChanged$,
  isSameRef,
  multiFilter,
  multiSort,
  multiSwitchMap,
  orderBy,
  query$,
  snapshot,
  snapshotDefined,
  sortTimestamp,
  toMoment,
  toQuery,
  where,
  type DocumentReference,
  type Query,
  type WithRef,
  INamedDocument,
  safeCombineLatest,
} from '@principle-theorem/shared';
import { differenceBy, first } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ChatActions } from '../../chat-actions';
import { ChatNotificationsService } from '../../chat-notifications.service';

export interface IChatSummary {
  chat: WithRef<IChat>;
  members: INamedDocument<IStaffer>[];
  lastMessage?: WithRef<IChatMessage>;
  isRead: boolean;
}

export enum ChatFilter {
  Open = 'showOpen',
  Archived = 'showArchived',
}

export interface IChatsDashboardState {
  chats: IChatSummary[];
  filterBy: ChatFilter;
  loading: boolean;
}

const initialState: IChatsDashboardState = {
  chats: [],
  filterBy: ChatFilter.Open,
  loading: true,
};

@Injectable({
  providedIn: 'root',
})
export class ChatsDashboardStore extends ComponentStore<IChatsDashboardState> {
  private _globalStore = inject(GlobalStoreService);
  private _currentScope = inject(CurrentScopeFacade);
  private _organisation = inject(OrganisationService);
  private _chatNotifications = inject(ChatNotificationsService);
  readonly chats$ = this.select((state) => state.chats);
  readonly unreadChats$ = this.select((state) =>
    state.chats.filter((chat) => !chat.isRead)
  );
  readonly loading$ = this.select((state) => state.loading);
  readonly filterBy$ = this.select((state) => state.filterBy);

  readonly setFilter = this.updater((state, filterBy: ChatFilter) => ({
    ...state,
    filterBy,
  }));

  readonly loadChats = this.effect(
    (practice$: Observable<WithRef<IPractice>>) =>
      practice$.pipe(
        switchMap((practice) =>
          combineLatest([
            of(practice),
            this._organisation.staffer$.pipe(filterUndefined()),
            this._organisation.userPractices$,
            this.filterBy$,
          ])
        ),
        tap(() => this.patchState({ loading: true })),
        switchMap(([practice, staffer, userPractices, filterBy]) => {
          let builtQuery: Query<IChat> = Brand.chatCol({
            ref: Practice.brandDoc(practice),
          });

          if (
            !staffer?.settings?.chats?.showAllPractices ||
            !userPractices.length
          ) {
            builtQuery = toQuery(
              builtQuery,
              where('practiceRef', '==', practice.ref)
            );
          } else {
            builtQuery = toQuery(
              builtQuery,
              where(
                'practiceRef',
                'in',
                userPractices.map((userPractice) => userPractice.ref)
              )
            );
          }

          builtQuery = toQuery(
            builtQuery,
            where('participants', 'array-contains', staffer.ref)
          );

          if (filterBy === ChatFilter.Archived) {
            builtQuery = toQuery(
              builtQuery,
              where('status', '==', ChatStatus.Archived)
            );
          } else {
            builtQuery = toQuery(
              builtQuery,
              where('status', '!=', ChatStatus.Archived),
              orderBy('status')
            );
          }

          return query$(builtQuery).pipe(
            multiFilter((chat) => {
              if (chat.deleted) {
                return false;
              }
              return true;
            }),
            multiSwitchMap((chat) =>
              getChatSummary$(chat, staffer, this._globalStore)
            )
          );
        }),
        multiSort((summaryA, summaryB) =>
          sortTimestamp(
            summaryA.chat.lastMessageAt ?? summaryA.chat.createdAt,
            summaryB.chat.lastMessageAt ?? summaryA.chat.createdAt
          )
        ),
        tapResponse(
          (chats) =>
            this.patchState({
              chats,
              loading: false,
            }),
          // eslint-disable-next-line no-console
          console.error
        )
      )
  );

  constructor(private _snackBar: MatSnackBar) {
    super(initialState);

    this._handleMessageNotifications();
  }

  getChatByUid$(uid: string): Observable<IChatSummary | undefined> {
    return this.chats$.pipe(
      map((chats) => chats.find((chat) => chat.chat.ref.id === uid))
    );
  }

  async addChat(
    ownerRef: DocumentReference<IStaffer>,
    participants: DocumentReference<IStaffer>[],
    chatType: ChatType
  ): Promise<DocumentReference<IChat>> {
    const brand = await snapshotDefined(this._currentScope.currentBrand$);
    const practice = await snapshotDefined(this._currentScope.currentPractice$);
    const staffer = await snapshotDefined(this._organisation.staffer$);

    const existingChat = await snapshot(
      this._getChat$(
        ownerRef,
        practice.ref,
        [ownerRef, ...participants],
        chatType
      )
    );

    if (existingChat) {
      return existingChat.chat.ref;
    }

    const docRef = await addDoc(
      Brand.chatCol(brand),
      Chat.init({
        type: chatType,
        ownerRef,
        practiceRef: practice.ref,
        participants: [ownerRef, ...participants],
      })
    );

    if (chatType === ChatType.Group) {
      await this.addInteraction(docRef, [
        toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
        toTextContent(` created chat`),
      ]);
    }

    this._snackBar.open('Chat Created');
    return docRef;
  }

  async addInteraction(
    chatRef: DocumentReference<IChat>,
    title: RawInlineNodes,
    content?: MixedSchema
  ): Promise<void> {
    const staffer = await snapshotDefined(this._organisation.staffer$);
    await ChatActions.addInteraction(staffer, chatRef, title, content);
  }

  private _getChat$(
    ownerRef: DocumentReference<IStaffer>,
    practiceRef: DocumentReference<IPractice>,
    participants: DocumentReference<IStaffer>[],
    chatType: ChatType
  ): Observable<IChatSummary | undefined> {
    if (chatType !== ChatType.Direct) {
      return of(undefined);
    }

    return this.chats$.pipe(
      map((chats) =>
        chats.find((chat) => {
          const isSameType = chat.chat.type === chatType;
          const includesStaffer = chat.chat.participants.some((participant) =>
            isSameRef(participant, ownerRef)
          );
          const isSamePractice = isSameRef(chat.chat.practiceRef, practiceRef);
          const isSameParticipants =
            differenceBy(
              chat.chat.participants,
              participants,
              (participant) => participant.path
            ).length === 0;

          return (
            isSameType &&
            includesStaffer &&
            isSamePractice &&
            isSameParticipants
          );
        })
      )
    );
  }

  private _handleMessageNotifications(): void {
    let loadTime = moment();

    combineLatest([
      this.chats$,
      this._organisation.staffer$.pipe(filterUndefined(), isRefChanged$()),
    ])
      .pipe(
        map(([chats, staffer]) => {
          return chats.filter((chat) => {
            if (!chat.lastMessage || chat.chat.muted) {
              return false;
            }
            if (isSameRef(chat.lastMessage.authorRef, staffer)) {
              return false;
            }
            return toMoment(chat.lastMessage.createdAt).isAfter(loadTime);
          });
        }),
        map((chats) => first(chats)?.lastMessage),
        filterUndefined(),
        tap(() => {
          loadTime = moment();
        })
      )
      .subscribe(
        (message) =>
          void this._chatNotifications.triggerNotificationMessage(message)
      );
  }
}

export function getChatSummary$(
  chat: WithRef<IChat>,
  staffer: WithRef<IStaffer>,
  globalStore: GlobalStoreService
): Observable<IChatSummary> {
  const lastMessage$: Observable<WithRef<IChatMessage> | undefined> =
    chat.lastMessage ? doc$(chat.lastMessage) : of(undefined);
  const members$ = safeCombineLatest(
    chat.participants.map((participant) =>
      globalStore
        .getStafferName$({ ref: participant })
        .pipe(map((name) => ({ ref: participant, name })))
    )
  );

  return combineLatest([lastMessage$, members$]).pipe(
    map(([lastMessage, members]) => ({
      chat,
      members,
      lastMessage,
      isRead: getIsRead(chat, staffer, lastMessage),
    }))
  );
}

export function getIsRead(
  chat: WithRef<IChat>,
  staffer: WithRef<IStaffer>,
  lastMessage?: WithRef<IChatMessage>
): boolean {
  if (chat.status !== ChatStatus.Open || !lastMessage) {
    return true;
  }

  return lastMessage.readBy.some((readBy) => isSameRef(readBy, staffer.ref))
    ? true
    : false;
}
