import { FeatureVisibility, type IFeature } from './feature';
import {
  findByPropertyRecursive,
  type PropertyNames,
  reduceToSingleArray,
} from '@principle-theorem/shared';
import { IPermission } from './permission';
import { PermissionDisplay, PermissionGroup } from './permission-group';
import { sortBy } from 'lodash';

export class FeaturesCollection {
  features: IFeature[] = [];

  constructor(
    private _originalFeatures: IFeature[] = [],
    private _isProduction: boolean
  ) {
    this.features = getAllFeatures(this._originalFeatures);
  }

  byVisibility(...visibility: FeatureVisibility[]): IFeature[] {
    return this.features.filter((feature: IFeature) =>
      visibility.includes(feature.visibility)
    );
  }

  findByLabel(
    featureLabel: string,
    features?: IFeature[]
  ): IFeature | undefined {
    if (!features) {
      features = this.features;
    }
    return findByPropertyRecursive<IFeature, PropertyNames<IFeature>>(
      'label',
      featureLabel,
      features,
      'features'
    );
  }

  isFeatureEnabled(feature: IFeature): boolean {
    return isFeatureEnabled(feature, this, this._isProduction);
  }

  isFeatureEnabledByLabel(label: string): boolean {
    const feature = this.findByLabel(label);
    return feature ? this.isFeatureEnabled(feature) : false;
  }

  getFeaturePermissions(): PermissionGroup[] {
    const groups = this._originalFeatures
      .map((feature) => ({
        label: feature.label,
        permissions: this.getEnabledPermissions([feature]),
      }))
      .filter((feature) => feature.permissions.length > 0)
      .map((feature) => {
        const permissions = feature.permissions.map(
          (permission) =>
            new PermissionDisplay(permission.description, permission.value)
        );

        return new PermissionGroup(feature.label, permissions);
      });

    return sortBy(groups, 'name');
  }

  getEnabledPermissions(features: IFeature[]): IPermission[] {
    if (!features.length) {
      return [];
    }
    return reduceToSingleArray(
      features
        .filter((feature) => this.isFeatureEnabled(feature))
        .map((feature) => {
          return [
            ...feature.permissions,
            ...this.getEnabledPermissions(feature.features ?? []),
          ];
        })
    );
  }
}

const devFeatureVisibility: FeatureVisibility[] = [
  FeatureVisibility.Dev,
  FeatureVisibility.Alpha,
  FeatureVisibility.Beta,
  FeatureVisibility.Stable,
];

const prodFeatureVisibility: FeatureVisibility[] = [
  FeatureVisibility.Alpha,
  FeatureVisibility.Beta,
  FeatureVisibility.Stable,
];

export function isFeatureEnabled(
  feature: IFeature,
  collection: FeaturesCollection,
  isProduction: boolean
): boolean {
  if (!isProduction) {
    return collection
      .byVisibility(...devFeatureVisibility)
      .some((enabledFeature: IFeature) => enabledFeature === feature);
  }
  return collection
    .byVisibility(...prodFeatureVisibility)
    .some((enabledFeature: IFeature) => enabledFeature === feature);
}

export function getAllFeatures(features: IFeature[]): IFeature[] {
  if (!features.length) {
    return [];
  }
  return reduceToSingleArray(
    features.map((feature) => {
      return [feature, ...getAllFeatures(feature.features ?? [])];
    })
  );
}

export function getAllPermissions(features: IFeature[]): IPermission[] {
  if (!features.length) {
    return [];
  }
  return reduceToSingleArray(
    features.map((feature) => {
      return [
        ...feature.permissions,
        ...getAllPermissions(feature.features ?? []),
      ];
    })
  );
}
