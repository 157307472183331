import { Injector } from '@angular/core';
import { wrapAngularExtension } from '@principle-theorem/ng-prosemirror';
import {
  type IMentionNodeConfig,
  MentionNodeComponent,
} from './mention-node/mention-node.component';
import { AnyExtension } from '@tiptap/core';

export function createMentionExtension(
  injector: Injector,
  config: IMentionNodeConfig
): AnyExtension {
  return wrapAngularExtension(MentionNodeComponent, [], config)(injector);
}
