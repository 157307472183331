import {
  IAppointment,
  IContact,
  IPatient,
  IPatientContactDetails,
  IPrincipleMention,
  isAppointment,
  isContact,
  isPatient,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, getParentDocRef, snapshot } from '@principle-theorem/shared';
import { DocumentReference } from '@principle-theorem/shared';
import { compact } from 'lodash';
import { Appointment } from '../appointment/appointment';
import { fromMention } from '../mention/mention';
import { Patient } from '../patient/patient';

export async function getMentionContactDetails(
  mention: IPrincipleMention
): Promise<IPatientContactDetails | undefined> {
  const item = await fromMention(mention);
  if (!item) {
    return;
  }
  return resolveContactDetails(item);
}

export async function getMentionPatientRef(
  mention: IPrincipleMention
): Promise<DocumentReference<IPatient> | undefined> {
  const item = await fromMention(mention);
  if (!item) {
    return;
  }
  if (isPatient(item)) {
    return item.ref as DocumentReference<IPatient>;
  }
  if (isAppointment(item)) {
    const appointment = item as WithRef<IAppointment>;
    return getParentDocRef<IPatient>(appointment.ref);
  }
}

export async function resolveContactDetails(
  item: WithRef<unknown>
): Promise<IPatientContactDetails | undefined> {
  if (isPatient(item)) {
    return getPatientContactDetails(item as WithRef<IPatient>);
  }
  if (isAppointment(item)) {
    const appointment = item as WithRef<IAppointment>;
    const patient = await Appointment.patient(appointment);
    return getPatientContactDetails(patient);
  }
  if (isContact(item)) {
    return coerceContactContactDetails(item);
  }
}

export async function getPatientContactDetails(
  patient: WithRef<IPatient>
): Promise<IPatientContactDetails | undefined> {
  return snapshot(Patient.resolveContactDetails$(patient));
}

export function coerceContactContactDetails(
  contact: IContact
): IPatientContactDetails | undefined {
  const mainPhone = {
    label: 'Phone',
    number: contact.phone ?? '',
  };
  const mobilePhone = contact.mobileNumber
    ? {
        label: 'Mobile Phone',
        number: contact.mobileNumber,
      }
    : undefined;
  return {
    email: contact.email,
    address: contact.address ?? '',
    contactNumbers: compact([mainPhone, mobilePhone]),
  };
}
