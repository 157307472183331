import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const ITEM_CODE_RESOURCE_TYPE = 'itemCodes';

export const ITEM_CODE_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Item Code List',
    description: '',
    idPrefix: ITEM_CODE_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ICorePracticeItemCode {
  id: number;
  itemCode: string; // "019"
  name: string; // "Letter Of Referral"
  isDeleted: boolean;
  itemSubCategoryId: number;
  itemCategoryId: number;
}

export function isCorePracticeItemCode(
  item: unknown
): item is ICorePracticeItemCode {
  return TypeGuard.interface<ICorePracticeItemCode>({
    id: isNumber,
    itemCode: isString,
    name: isString,
    isDeleted: isBoolean,
    itemSubCategoryId: isNumber,
    itemCategoryId: isNumber,
  })(item);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICorePracticeItemCodeTranslations {}

export interface ICorePracticeItemCodeFilters {
  itemSubCategoryId: number;
  itemCategoryId: number;
}

const ITEM_CODE_SOURCE_QUERY = `
SELECT
  ItemId AS id,
  ItemCode AS item_code,
  Name AS name,
  convert_to_boolean(IsDeleted) AS is_deleted,
  ItemSubCategoryId AS item_sub_category_id,
  ItemCategoryId AS item_category_id
FROM tblItem
`;

export class ItemCodeSourceEntity extends BaseSourceEntity<
  ICorePracticeItemCode,
  ICorePracticeItemCodeTranslations,
  ICorePracticeItemCodeFilters
> {
  sourceEntity = ITEM_CODE_SOURCE_ENTITY;
  entityResourceType = ITEM_CODE_RESOURCE_TYPE;
  sourceQuery = ITEM_CODE_SOURCE_QUERY;
  verifySourceFn = isCorePracticeItemCode;

  translate(
    _data: ICorePracticeItemCode,
    _timezone: Timezone
  ): ICorePracticeItemCodeTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticeItemCode): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticeItemCode): string {
    return `${data.id} - ${data.itemCode}`;
  }

  getFilterData(
    data: ICorePracticeItemCode,
    _timezone: Timezone
  ): ICorePracticeItemCodeFilters {
    return {
      itemSubCategoryId: data.itemSubCategoryId,
      itemCategoryId: data.itemCategoryId,
    };
  }
}
