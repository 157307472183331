import { ServiceCodeType } from '../service-item';
import { ServiceCodeScope, type IServiceSmartGroup } from './ada-code';

export enum ServiceSmartGroupName {
  RestorativeMetallic = 'Restorative Metallic',
  RestorativeComposite = 'Restorative Composite',
  MetallicIndirect = 'Metallic Indirect',
  ToothColouredIndirect = 'Tooth Coloured Indirect',
  Consultation = 'Consultation',
  Extraction = 'Extraction',
  Cerec = 'Cerec',
  CBCT = 'CBCT',
}

export const SMART_GROUPS: IServiceSmartGroup[] = [
  {
    uid: ServiceSmartGroupName.RestorativeMetallic,
    title: 'Metallic Restorations – Direct',
    description: '',
    scope: ServiceCodeScope.PerTooth,
    serviceCodes: [511, 512, 513, 514, 515],
    serviceCodeType: ServiceCodeType.ADA,
  },
  {
    uid: ServiceSmartGroupName.RestorativeComposite,
    title: 'Adhesive Restorations – Direct',
    description: '',
    scope: ServiceCodeScope.PerTooth,
    serviceCodes: [521, 522, 523, 524, 525, 531, 532, 533, 534, 535],
    serviceCodeType: ServiceCodeType.ADA,
  },
  {
    uid: ServiceSmartGroupName.MetallicIndirect,
    title: 'Metallic Restorations – Indirect',
    description: '',
    scope: ServiceCodeScope.PerTooth,
    serviceCodes: [541, 542, 543, 544, 545],
    serviceCodeType: ServiceCodeType.ADA,
  },
  {
    uid: ServiceSmartGroupName.ToothColouredIndirect,
    title: 'Tooth-coloured Restorations – Indirect',
    description: '',
    scope: ServiceCodeScope.PerTooth,
    serviceCodes: [551, 552, 553, 554, 555],
    serviceCodeType: ServiceCodeType.ADA,
  },
  {
    uid: ServiceSmartGroupName.Cerec,
    title: 'Cerec',
    description: '',
    scope: ServiceCodeScope.PerTooth,
    serviceCodes: [551, 552, 553, 554, 555],
    serviceCodeType: ServiceCodeType.ADA,
  },
  {
    uid: ServiceSmartGroupName.Extraction,
    title: 'Extraction',
    description: '',
    scope: ServiceCodeScope.PerTooth,
    serviceCodes: [311, 314, 322, 323, 324],
    serviceCodeType: ServiceCodeType.ADA,
  },
  {
    uid: ServiceSmartGroupName.Consultation,
    title: 'Consultation',
    description: '',
    scope: ServiceCodeScope.AppointmentTime,
    serviceCodes: [15, 14],
    serviceCodeType: ServiceCodeType.ADA,
  },
  {
    uid: ServiceSmartGroupName.CBCT,
    title: 'CBCT',
    description: '',
    scope: ServiceCodeScope.PerQuadrant,
    serviceCodes: [87, 88, 89],
    serviceCodeType: ServiceCodeType.ADA,
  },
];
