import { type IXSLXImport } from '@principle-theorem/shared';
import type { Row } from 'exceljs';
import {
  type IFileCategoryXSLX,
  FILE_CATEGORY_HEADERS,
} from './file-categories-to-xlsx';

export class XSLXToFileCategories implements IXSLXImport<IFileCategoryXSLX> {
  headers = FILE_CATEGORY_HEADERS;

  translate(row: Row): IFileCategoryXSLX {
    return {
      folder: row.getCell('folder').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IFileCategoryXSLX['mapTo'],
    };
  }
}
