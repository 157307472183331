import {
  initVersionedSchema,
  mergeSchemas,
  toTextContent,
} from '@principle-theorem/editor';
import {
  ClinicalNote,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  FailedDestinationEntityRecord,
  IHasSourceIdentifier,
  IStaffer,
  ITranslationMap,
  type IClinicalNote,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  INamedDocument,
  Timestamp,
  asyncForEach,
  getError,
  multiFilter,
  toISODate,
  toInt,
  toMomentTz,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, groupBy, sortBy } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import {
  BasePatientClinicalNoteDestinationEntity,
  IClinicalNoteJobData,
  IClinicalNoteMigrationData,
} from '../../../destination/entities/patient-clinical-notes';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { isClinicalNoteCode } from '../../../mappings/item-code-mapping-helpers';
import { ItemCodeNoteType } from '../../../mappings/item-codes-to-notes-xlsx';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { TranslationMapHandler } from '../../../translation-map';
import { ADAItemSourceEntity } from '../../source/entities/ada-item';
import {
  ID4WPatient,
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
} from '../../source/entities/patient';
import {
  ID4WPatientTreatmentNote,
  ID4WPatientTreatmentNoteTranslations,
  PatientTreatmentNoteSourceEntity,
} from '../../source/entities/patient-treatment-note';
import { D4WItemCodeToNoteMappingHandler } from '../mappings/item-code-to-note';
import { D4WItemCodeMappingHandler } from '../mappings/item-codes';
import { D4WStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';

interface ID4WClinicalNoteJobData extends IClinicalNoteJobData<ID4WPatient> {
  sourceItemCodesToNotes: WithRef<ITranslationMap<object, ItemCodeNoteType>>[];
}

export class PatientClinicalNoteDestinationEntity extends BasePatientClinicalNoteDestinationEntity<ID4WPatient> {
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    clinicalNotes: new PatientTreatmentNoteSourceEntity(),
  };

  customMappings = {
    staff: new D4WStafferMappingHandler(),
    itemCodes: new D4WItemCodeMappingHandler(),
    itemCodesToNotes: new D4WItemCodeToNoteMappingHandler(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean,
    _fromDate?: Timestamp,
    _toDate?: Timestamp,
    fromId?: string,
    toId?: string
  ): Observable<ID4WClinicalNoteJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);
    const sourceItemCodesToNotes$ =
      this.customMappings.itemCodesToNotes.getRecords$(translationMap);

    return this.sourceEntities.patients
      .getRecords$(
        migration,
        500,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        multiFilter((patient) => {
          if (!fromId || !toId) {
            return true;
          }

          return (
            patient.data.data.patient_id >= toInt(fromId) &&
            patient.data.data.patient_id <= toInt(toId)
          );
        }),
        withLatestFrom(staff$, sourceItemCodes$, sourceItemCodesToNotes$),
        map(
          ([sourcePatients, staff, sourceItemCodes, sourceItemCodesToNotes]) =>
            sourcePatients.map((sourcePatient) => ({
              staff,
              sourceItemCodes,
              sourceItemCodesToNotes,
              sourcePatient,
              destinationEntity,
            }))
        )
      );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: ID4WClinicalNoteJobData
  ): Promise<
    | IClinicalNoteMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const errorResponseData = {
      label: data.sourcePatient.record.label,
      uid: data.sourcePatient.record.uid,
      ref: data.sourcePatient.record.ref,
    };

    const sourcePatientId = this.sourceEntities.patients
      .getSourceRecordId(data.sourcePatient.data.data)
      .toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        errorResponseData,
        `Couldn't resolve patient`
      );
    }

    const notes = await this.sourceEntities.clinicalNotes.filterRecords(
      migration,
      'patientId',
      sourcePatientId
    );

    try {
      const clinicalNotes = await this.buildClinicalNotes(
        migration,
        notes,
        translationMap,
        data
      );

      return {
        sourcePatientId,
        patientRef,
        clinicalNotes,
      };
    } catch (error) {
      return this._buildErrorResponse(errorResponseData, getError(error));
    }
  }

  async buildClinicalNotes(
    migration: WithRef<IPracticeMigration>,
    notes: IGetRecordResponse<
      ID4WPatientTreatmentNote,
      ID4WPatientTreatmentNoteTranslations
    >[],
    translationMap: TranslationMapHandler,
    data: ID4WClinicalNoteJobData
  ): Promise<(IClinicalNote & IHasSourceIdentifier)[]> {
    const sourceEntity = new ADAItemSourceEntity();

    const filteredNotes = notes.filter((note) => {
      const sourceId = sourceEntity
        .getSourceRecordId({ id: note.data.data.item_id })
        .toString();
      return isClinicalNoteCode(
        note.data.data.item_code,
        sourceId,
        data.sourceItemCodes,
        data.sourceItemCodesToNotes
      );
    });

    const clinicalNotes = await asyncForEach(
      sortBy(filteredNotes, 'data.data.created_at'),
      async (note) => {
        const owner = await getClinicalNotePractitioner(
          note.data.data.provider_id.toString(),
          data.staff,
          translationMap
        );
        return {
          sourceIdentifier: note.record.uid,
          ...ClinicalNote.init({
            owner,
            content: initVersionedSchema([
              toTextContent(
                `From ${note.data.data.item_code} ${
                  note.data.data.tooth_ref ?? ''
                }${note.data.data.tooth_surface ?? ''}`
              ),
              toTextContent(note.data.data.content || ' '),
            ]),
            createdAt: note.data.translations.createdAt,
            immutable: true,
            recordDate: toISODate(
              toMomentTz(
                note.data.translations.createdAt,
                migration.configuration.timezone
              )
            ),
          }),
        };
      }
    );

    return compact(
      Object.values(
        groupBy(
          clinicalNotes,
          (clinicalNote) =>
            `${clinicalNote.recordDate}-${clinicalNote.owner.ref.id}`
        )
      ).map((groupedNotes) => {
        if (!groupedNotes.length) {
          return;
        }

        return groupedNotes.reduce(
          (combinedClinicalNote, clinicalNote) => ({
            ...combinedClinicalNote,
            content: mergeSchemas([
              combinedClinicalNote.content,
              clinicalNote.content,
            ]),
          }),
          { ...groupedNotes[0], content: initVersionedSchema() }
        );
      })
    );
  }
}

async function getClinicalNotePractitioner(
  practitionerId: string,
  staff: WithRef<ITranslationMap<IStaffer>>[],
  translationMap: TranslationMapHandler
): Promise<INamedDocument<IStaffer>> {
  const practitionerMap =
    staff.find((staffer) => staffer.sourceIdentifier === practitionerId)
      ?.destinationIdentifier ||
    ((await translationMap.getBySource(practitionerId, STAFFER_RESOURCE_TYPE))
      ?.destinationIdentifier as DocumentReference<IStaffer> | undefined);

  if (!practitionerMap) {
    throw new Error(`Couldn't find practitioner with id ${practitionerId}`);
  }

  const creator = await Firestore.getDoc(practitionerMap);
  return stafferToNamedDoc(creator);
}
