<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs()" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="wrapper flex flex-col gap-4">
  <h1 class="mat-headline-4">Sterilisation Settings</h1>

  <mat-card appearance="outlined">
    <div class="flex items-center justify-between gap-4 p-4">
      <div>
        <h2 class="!m-0 text-xl font-bold">Sterilisation Machines</h2>
        <p class="mat-caption">Configure Machines and their locations</p>
      </div>
      <button mat-flat-button color="primary" (click)="addEditMachine()">
        Add Machine
      </button>
    </div>
    <mat-card-content>
      <pr-sterilisation-machines-list
        [machines]="store.machines$ | async"
        (edit)="addEditMachine($event)"
        (delete)="store.deleteMachine($event)"
      />
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined">
    <div class="flex items-center justify-between gap-4 p-4">
      <div>
        <div>
          <h2 class="!m-0 text-xl font-bold">Sterilisation Cycle Types</h2>
        </div>
        <p class="mat-caption">
          Configure test and regular cycle types for sterilisation cycles
        </p>
      </div>
      <button mat-flat-button color="primary" (click)="addEditCycleType()">
        Add Cycle Type
      </button>
    </div>
    <mat-card-content>
      <pr-sterilisation-cycle-types-list
        [cycleTypes]="store.cycleTypes$ | async"
        (edit)="addEditCycleType($event)"
        (delete)="store.deleteCycleType($event)"
      />
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined">
    <div class="flex items-center justify-between gap-4 p-4">
      <div>
        <h2 class="!m-0 text-xl font-bold">
          Sterilisation Equipment & Instruments
        </h2>
        <p class="mat-caption">
          Reusable equipment and instruments that are included in sterilisation
          packs
        </p>
      </div>
      <button mat-flat-button color="primary" (click)="addEditEquipment()">
        Add Equipment
      </button>
    </div>
    <mat-card-content>
      <pr-sterilisation-equipment-list
        [items]="store.equipment$ | async"
        (edit)="addEditEquipment($event)"
        (delete)="deleteEquipment($event)"
      />
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined">
    <div class="flex items-center justify-between gap-4 p-4">
      <div>
        <h2 class="!m-0 text-xl font-bold">Sterilisation Packs</h2>
        <p class="mat-caption">
          Packs configured here are available at all practice locations
        </p>
      </div>
      <button mat-flat-button color="primary" (click)="addEditPack()">
        Add Pack
      </button>
    </div>
    <mat-card-content>
      <pr-sterilisation-packs-list
        [sterilisationPacks]="store.packs$ | async"
        (edit)="addEditPack($event)"
        (delete)="deletePack($event)"
      />
    </mat-card-content>
  </mat-card>
</div>
