import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchedulingScenarioService } from '@principle-theorem/ng-appointment';
import {
  CurrentScopeFacade,
  ScheduleSummaryEventActionsService,
  SummaryEventActionType,
} from '@principle-theorem/ng-principle-shared';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogInput,
} from '@principle-theorem/ng-shared';
import { Brand, Schedule } from '@principle-theorem/principle-core';
import {
  type ICalendarEvent,
  type ICalendarEventSchedule,
  isCalendarEvent,
  isCalendarEventFromSchedule,
  ScheduleModifierType,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  isWithRef,
  patchDoc,
  snapshot,
  type WithRef,
  type DocumentReference,
  type Timestamp,
  Firestore,
  toTimestamp,
} from '@principle-theorem/shared';
import { AddCalendarEventComponent } from './components/add-calendar-event/add-calendar-event.component';
import { EditCalendarEventComponent } from './components/edit-calendar-event/edit-calendar-event.component';

@Injectable()
export class ManageCalendarEventService {
  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _currentScope: CurrentScopeFacade,
    private _schedulingScenario: SchedulingScenarioService,
    private _scheduleSummaryEventActions: ScheduleSummaryEventActionsService
  ) {}

  async add(
    calendarEvent?: ICalendarEvent
  ): Promise<DocumentReference<ICalendarEvent> | undefined> {
    const config: MatDialogConfig = DialogPresets.medium({
      autoFocus: true,
      data: { calendarEvent },
    });
    const response = await this._dialog
      .open<AddCalendarEventComponent, undefined, ICalendarEvent>(
        AddCalendarEventComponent,
        config
      )
      .afterClosed()
      .toPromise();

    if (!isCalendarEvent(response)) {
      return;
    }
    return this._save(response);
  }

  async openEditDialog(
    calendarEvent: WithRef<ICalendarEvent> | ICalendarEvent
  ): Promise<DocumentReference<ICalendarEvent> | undefined> {
    const config: MatDialogConfig = DialogPresets.medium({
      autoFocus: true,
      data: { calendarEvent },
    });
    const result = await this._dialog
      .open<
        EditCalendarEventComponent,
        WithRef<ICalendarEvent> | ICalendarEvent,
        WithRef<ICalendarEvent> | ICalendarEvent
      >(EditCalendarEventComponent, config)
      .afterClosed()
      .toPromise();

    if (!result) {
      return;
    }

    return this.update(result);
  }

  async update(
    calendarEvent: WithRef<ICalendarEvent> | ICalendarEvent
  ): Promise<DocumentReference<ICalendarEvent> | undefined> {
    if (isWithRef(calendarEvent)) {
      const isBlockedByDoubleBooking =
        await this._schedulingScenario.isBlockedByDoubleBooking(calendarEvent);
      if (isBlockedByDoubleBooking) {
        return;
      }

      await this._scheduleSummaryEventActions.setCalendarEvent(
        SummaryEventActionType.Update,
        calendarEvent
      );
      await patchDoc(calendarEvent.ref, { ...calendarEvent });
      return calendarEvent.ref;
    }

    if (isCalendarEventFromSchedule(calendarEvent)) {
      await this.removeOccurenceFromSchedule(
        calendarEvent.scheduleRef,
        calendarEvent.event.from
      );
    }

    return this._save(calendarEvent);
  }

  async confirmDelete(prompt: string): Promise<boolean | undefined> {
    const data = confirmationDialogData({
      title: 'Delete Calendar Event',
      prompt,
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    return this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
  }

  async removeOccurenceFromSchedule(
    scheduleRef: DocumentReference<ICalendarEventSchedule>,
    date: Timestamp
  ): Promise<void> {
    const schedule = await Firestore.getDoc(scheduleRef);
    await Firestore.patchDoc(
      schedule.ref,
      Schedule.addModifier(schedule, {
        type: ScheduleModifierType.Delete,
        dates: [date],
      })
    );
  }

  /**
   * This function is responsible for marking a calendar event as deleted and adding it to the calendar event collection.
   * This is necessary for the schedule summary aggregates to reflect these changes.
   *
   * The function operates in the following steps:
   * 1. Checks if the calendar event is derived from a schedule. If it's not, the function terminates early without making any changes.
   * 2. Retrieves the current brand from the scope. If the brand doesn't exist, the function terminates early without making any changes.
   * 3. Adds a document to the calendar event collection of the brand. This document is a clone of the original calendar event, but with an additional `deleted` property set to `true` and `updatedAt` set to the current timestamp.
   *
   * @param calendarEvent - The calendar event that needs to be marked as deleted and added to the collection.
   * @returns A promise that resolves when the document has been successfully added to the collection. If the calendar event is not derived from a schedule or the brand doesn't exist, the function returns `undefined`.
   */
  async addDeletedEventOccurrence(
    calendarEvent: ICalendarEvent
  ): Promise<DocumentReference<ICalendarEvent> | undefined> {
    if (!isCalendarEventFromSchedule(calendarEvent)) {
      return;
    }
    const brand = await snapshot(this._currentScope.currentBrand$);
    if (!brand) {
      // eslint-disable-next-line no-console
      console.error(
        'ManageCalendarEventService addDeletedEventOccurrence - No Brand in Current Scope'
      );
      return;
    }
    const timestamp = toTimestamp();
    await addDoc(Brand.calendarEventCol(brand), {
      ...calendarEvent,
      deleted: true,
      updatedAt: timestamp,
    });
  }

  private async _save(
    calendarEvent: ICalendarEvent
  ): Promise<DocumentReference<ICalendarEvent> | undefined> {
    const isBlockedByDoubleBooking =
      await this._schedulingScenario.isBlockedByDoubleBooking(calendarEvent);
    if (isBlockedByDoubleBooking) {
      return;
    }

    const brand = await snapshot(this._currentScope.currentBrand$);
    if (!brand) {
      // eslint-disable-next-line no-console
      console.error(
        'ManageCalendarEventService _save - No Brand in Current Scope'
      );
      return;
    }
    const docRef = await addDoc(Brand.calendarEventCol(brand), calendarEvent);

    const newCalendarEvent = { ...calendarEvent, ref: docRef };

    if (isCalendarEventFromSchedule(calendarEvent)) {
      await this._scheduleSummaryEventActions.setCalendarEvent(
        SummaryEventActionType.Update,
        newCalendarEvent
      );
    } else {
      await this._scheduleSummaryEventActions.setCalendarEvent(
        SummaryEventActionType.Create,
        newCalendarEvent
      );
    }

    this._snackBar.open('Event Added');
    return docRef;
  }
}
