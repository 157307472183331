import {
  isAdultQuadrant,
  isRightQuadrant,
  isUpperQuadrant,
} from '@principle-theorem/principle-core';
import {
  ChartSection,
  ChartView,
  type ITooth,
} from '@principle-theorem/principle-core/interfaces';

export class ChartViewFactoryV2 {
  filteredTeeth: ITooth[];

  constructor(
    public teeth: ITooth[],
    public view: ChartView,
    public section: ChartSection
  ) {
    this.filteredTeeth = this.filterView(
      view,
      this.filterSection(section, teeth)
    );
  }

  filterView(view: ChartView, teeth: ITooth[]): ITooth[] {
    switch (view) {
      case ChartView.Adult:
        return this.adult(teeth);
      case ChartView.Deciduous:
        return this.deciduous(teeth);
      default:
        return teeth;
    }
  }

  filterSection(section: ChartSection, teeth: ITooth[]): ITooth[] {
    switch (section) {
      case ChartSection.TopArch:
        return this.top(teeth);
      case ChartSection.BottomArch:
        return this.bottom(teeth);
      case ChartSection.TopRight:
        return this.top(this.right(teeth));
      case ChartSection.TopLeft:
        return this.top(this.left(teeth));
      case ChartSection.BottomLeft:
        return this.bottom(this.left(teeth));
      case ChartSection.BottomRight:
        return this.bottom(this.right(teeth));
      default:
        return teeth;
    }
  }

  smartView(teeth: ITooth[]): ITooth[] {
    return teeth;
  }

  adult(teeth: ITooth[]): ITooth[] {
    return teeth.filter((tooth: ITooth) => {
      return isAdultQuadrant(tooth.toothRef.quadrant);
    });
  }

  deciduous(teeth: ITooth[]): ITooth[] {
    return teeth.filter((tooth: ITooth) => {
      return !isAdultQuadrant(tooth.toothRef.quadrant);
    });
  }

  top(teeth: ITooth[]): ITooth[] {
    return teeth.filter((tooth: ITooth) => {
      return isUpperQuadrant(tooth.toothRef.quadrant);
    });
  }

  bottom(teeth: ITooth[]): ITooth[] {
    return teeth.filter((tooth: ITooth) => {
      return !isUpperQuadrant(tooth.toothRef.quadrant);
    });
  }

  right(teeth: ITooth[]): ITooth[] {
    return teeth.filter((tooth: ITooth) => {
      return isRightQuadrant(tooth.toothRef.quadrant);
    });
  }

  left(teeth: ITooth[]): ITooth[] {
    return teeth.filter((tooth: ITooth) => {
      return !isRightQuadrant(tooth.toothRef.quadrant);
    });
  }
}
