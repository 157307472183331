import { TypeGuard, isObject } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type CustomFormDataResolverConfigMap } from './custom-form-helpers';
import {
  type IFormLayoutElement,
  type IFormLayoutHtml,
  type IRootFormLayoutElement,
} from './layout-interfaces';
import { type IFormSchema, isFormSchema } from './schema-interfaces';

export interface ICustomFormConfiguration {
  name: string;
  schema?: IFormSchema;
  layout?: (IRootFormLayoutElement | IFormLayoutHtml | IFormLayoutElement)[];
  dataResolverConfig?: CustomFormDataResolverConfigMap;
}

export function isCustomFormConfiguration(
  data: unknown
): data is ICustomFormConfiguration {
  return (
    isObject(data) &&
    isString(data.name) &&
    isFormSchema(data.schema) &&
    TypeGuard.arrayOf(isObject)(data.layout) &&
    TypeGuard.undefinedOr(isObject)(data.dataResolverConfig)
  );
}
