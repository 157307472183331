import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimezoneService } from '@principle-theorem/ng-principle-shared';
import { DEFAULT_TIME_INCREMENT } from '@principle-theorem/principle-core';
import {
  IScheduleSummaryEventable,
  type IWaitListCandidate,
} from '@principle-theorem/principle-core/interfaces';
import {
  TIME_FORMAT,
  TimeBucket,
  type ITimePeriod,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-candidate-gap-time',
  templateUrl: './candidate-gap-time.component.html',
  styleUrls: ['./candidate-gap-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CandidateGapTimeComponent {
  appointmentOptions$: Observable<ITimePeriod[]>;
  selectedOption: ITimePeriod;
  readonly timeFormat = TIME_FORMAT;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IGapTimeData,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<CandidateGapTimeComponent>,
    private _timezone: TimezoneService
  ) {
    const duration: moment.Duration = moment.duration(
      this.data.candidate.appointment.appointmentDuration ?? 0,
      'minutes'
    );

    this.appointmentOptions$ = this._timezone
      .resolvePracticeTimezone$(this.data.gap.event.practice.ref)
      .pipe(
        map((timezone) => {
          const now = moment().tz(timezone);
          return TimeBucket.fromEvents([this.data.gap.event])
            .exhaustTimeOptions({
              timeIncrement: DEFAULT_TIME_INCREMENT,
              duration,
            })
            .get()
            .map((timePeriod) => ({
              from: moment(timePeriod.from).tz(timezone),
              to: moment(timePeriod.to).tz(timezone),
            }))
            .filter((timePeriod) => !timePeriod.from.isBefore(now));
        })
      );
  }

  setSelected(timePeriod: ITimePeriod): void {
    this.selectedOption = timePeriod;
    this._snackBar.open('Selected time updated');
    this._dialogRef.close(this.selectedOption);
  }
}

export interface IGapTimeData {
  gap: IScheduleSummaryEventable;
  candidate: IWaitListCandidate;
}
