import {
  IContact,
  IPatient,
  IReferralSourceConfiguration,
  isContact,
  isPatient,
  MentionResourceType,
  IReferralSource,
  ReferralSourceType,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  INamedDocument,
  toNamedDocument,
} from '@principle-theorem/shared';
import {
  ITypesensePatientWithRef,
  isTypesensePatientWithRef,
} from '../typesense/typesense-patient';

export class ReferralSource {
  static init(
    overrides: AtLeast<IReferralSourceConfiguration, 'name'>
  ): IReferralSourceConfiguration {
    return { deleted: false, ...overrides };
  }

  static toReferrer(
    referrer:
      | INamedDocument<IPatient | IContact | IReferralSourceConfiguration>
      | ITypesensePatientWithRef
  ): IReferralSource {
    if (isPatient(referrer) || isTypesensePatientWithRef(referrer)) {
      return {
        ...toNamedDocument(referrer),
        type: MentionResourceType.Patient,
      };
    }
    if (isContact(referrer)) {
      return {
        ...toNamedDocument(referrer),
        type: MentionResourceType.Contact,
      };
    }
    return {
      ...toNamedDocument(referrer),
      type: ReferralSourceType.ConfiguredReferrer,
    };
  }
}

export const DEFAULT_REFERRAL_SOURCES: IReferralSourceConfiguration[] = [
  'Instagram',
  'Google Search',
  'Advertising',
  'Staff',
  'Word of Mouth',
  'Health Insurance',
  'Website',
  'Online Booking',
  'Letterbox Offer',
  'Facebook',
  'Walk-in',
  'Email Campaign',
].map((name) => ReferralSource.init({ name }));
