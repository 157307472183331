import { type IInvoice } from '../../invoice/invoice';
import { type ITemplateContext } from '../templating';
import { type IBaseTemplateContext } from './base-context';
import { type IPatientScopeData } from './patient-context';

export interface IInvoiceScopeData extends IPatientScopeData {
  invoice: IInvoice;
  invoiceLink: string;
}

export interface IInvoiceTemplateContext
  extends ITemplateContext,
    IBaseTemplateContext {
  invoiceLink: string;
  invoiceCreatedAt: string;
  invoiceCreatedAtDate: string;
  invoiceCreatedAtTime: string;
  invoiceReference: string;
  invoicePracticeName: string;
  patientFirstName: string;
  patientLastName: string;
  patientFullName: string;
}
