import { rand, randBoolean, randSentence } from '@ngneat/falso';
import {
  CHECKLIST_ITEM_ACTIONS,
  ChecklistItemAction,
  ChecklistType,
  IChecklistItem,
} from '@principle-theorem/principle-core/interfaces';
import { BaseMock, getEnumValues, Properties } from '@principle-theorem/shared';

export class ChecklistItemMock
  extends BaseMock
  implements Properties<IChecklistItem>
{
  get type(): ChecklistType {
    return rand(getEnumValues(ChecklistType));
  }
  get title(): string {
    return randSentence();
  }
  get action(): ChecklistItemAction {
    return rand(CHECKLIST_ITEM_ACTIONS);
  }
  get done(): boolean {
    return randBoolean();
  }
  get data(): string {
    return randSentence();
  }
  get deleted(): boolean {
    return false;
  }
}
