<mat-form-field>
  <mat-label>Filters</mat-label>
  <mat-select [formControl]="filtersCtrl" multiple>
    <mat-option
      *ngFor="let type of availableEventTypes$ | ngrxPush; trackBy: trackByType"
      [value]="type"
    >
      {{ type | splitCamel | titlecase }}
    </mat-option>
  </mat-select>
</mat-form-field>
