<mat-toolbar color="accent">Pair Terminal</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" [required]="true" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Pairing Code</mat-label>
      <input matInput formControlName="pairingCode" [required]="true" />
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <pt-loader-button
        color="primary"
        type="flat"
        [loading]="loading"
        [disabled]="form.invalid"
        (clicked)="submit()"
      >
        Submit
      </pt-loader-button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
