import { type WithRef } from '@principle-theorem/shared';
import { type IChartedItemConfiguration } from './charted-item-configuration';
import { type ChartableSurface } from './core/chartable-surface';
import { type IChartedItem, type IChartedRef } from './core/charted-item';
import { type IChartedItemDisplay } from './core/charted-item-display';
import { type ITooth } from './core/tooth';

export interface IChartedItemDetail {
  item: IChartedItem;
  config: WithRef<IChartedItemConfiguration>;
}

export interface IDentalChartViewSurface<T = Partial<IChartedRef>> {
  id: T;
  texture?: IChartedItemDisplay;
  badge: number;
  chartedItems: IChartedItemDetail[];
}

export interface IDentalChartViewTooth<T = Partial<IChartedRef>>
  extends IDentalChartViewSurface<T> {
  mesial: IDentalChartViewSurface<T>;
  distal: IDentalChartViewSurface<T>;
  occlusal: IDentalChartViewSurface<T>;
  lingual: IDentalChartViewSurface<T>;
  facial: IDentalChartViewSurface<T>;
  tooth: ITooth;
}

export interface IDentalChartViewQuadrant<T = Partial<IChartedRef>>
  extends IDentalChartViewSurface<T> {
  teeth: IDentalChartViewTooth<T>[];
}

export interface IDentalChartViewArch<T = Partial<IChartedRef>>
  extends IDentalChartViewSurface<T> {
  left?: {
    adult?: IDentalChartViewQuadrant<T>;
    deciduous?: IDentalChartViewQuadrant<T>;
  };
  right?: {
    adult?: IDentalChartViewQuadrant<T>;
    deciduous?: IDentalChartViewQuadrant<T>;
  };
}

export interface IDentalChartViewMouth<T = Partial<IChartedRef>>
  extends IDentalChartViewSurface<T> {
  upper?: IDentalChartViewArch<T>;
  lower?: IDentalChartViewArch<T>;
}

export interface IDentalChartView<T = Partial<IChartedRef>> {
  mouth: IDentalChartViewMouth<T>;
}

export interface IChartDataRenderConfig {
  teeth: ITooth[];
  chartedItems: IChartedItem[];
  selected: Partial<IChartedRef>[];
  disabled: ChartableSurface[];
}

export type IChartBuildData = Pick<
  IChartDataRenderConfig,
  'teeth' | 'chartedItems'
>;
