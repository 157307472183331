<div class="layout-margin" fxLayout="column">
  <h1>Productivity</h1>

  <div
    fxLayout="column"
    fxLayoutAlign="space-between stretch"
    fxLayoutGap="30px"
    class="chart"
  >
    <h2>Chair Utilisation</h2>
    <pr-chart fxFlex [chartBuilder]="chairTimeChart" />
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="30px">
    <div fxFlex class="layout-margin">
      <h2>Rescheduling Rate &amp; Cancellation Rate</h2>
      <pr-chart
        [chartBuilder]="rescheduleAndCancellationsChartBuilder"
       />
    </div>
    <div fxFlex class="layout-margin">
      <h2>FTAs &amp; UTAs</h2>
      <pr-chart [chartBuilder]="ftaAndUtaChartBuilder" />
    </div>
  </div>

  <pr-table-chart
    fxFlex
    [chart]="tableData"
    [replacementHeaders]="replacementHeaders"
   />
</div>
