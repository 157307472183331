import {
  ICustomLineItem,
  InvoiceLineItemType,
  IHasLineItems,
  isHasLineItems,
} from '@principle-theorem/principle-core/interfaces';
import { DimensionsCommon } from '@principle-theorem/reporting/interfaces';
import { getEnumValues } from '@principle-theorem/shared';
import { mapValues } from 'lodash';
import { breakoutMixedArray, createBreakoutMap } from './dimension-breakout';

type InvoiceDimensionItem<T> = Omit<T, 'items'> & {
  items: Partial<DimensionsCommon.IInvoiceDimensionItems>;
};

export function toInvoiceDimensionItem<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends ICustomLineItem & IHasLineItems<any>
>(base: T): InvoiceDimensionItem<T> {
  return {
    ...base,
    items: toInvoiceDimensionItems(base.items),
  };
}

export function toInvoiceDimensionItems<T extends ICustomLineItem>(
  items: T[]
): Partial<DimensionsCommon.IInvoiceDimensionItems> {
  const result = breakoutMixedArray(
    createBreakoutMap(
      getEnumValues(InvoiceLineItemType),
      (item: T, key) => String(item.type) === key
    ),
    items
  );
  return mapValues(result, (group) =>
    group.map((item) =>
      isHasLineItems(item) ? toInvoiceDimensionItem(item) : item
    )
  );
}
