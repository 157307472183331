export interface IInteractableRect {
  hidden: boolean;
  x: number;
  y: number;
  height: number;
  width: number;
}

export class InteractableRect {
  static init(overrides: Partial<IInteractableRect> = {}): IInteractableRect {
    return { hidden: false, x: 0, y: 0, height: 0, width: 0, ...overrides };
  }

  static render(rect: IInteractableRect, element: HTMLElement): void {
    Object.assign(element.style, {
      display: rect.hidden ? 'none' : 'block',
      transform: `translate(${rect.x}px, ${rect.y}px)`,
      width: `${rect.width}px`,
      height: `${rect.height}px`,
    });
  }
}
