import {
  type ISterilisationRecord,
  SterilisationStatus,
  SterilisationRecordCollection,
} from '@principle-theorem/principle-core/interfaces';
import {
  ArchivedDocument,
  AtLeast,
  CollectionReference,
  DocumentArchive,
  IReffable,
  WithRef,
  multiSort,
  query$,
  subCollection,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';

export class SterilisationRecord {
  static init(
    data: AtLeast<ISterilisationRecord, 'data' | 'practice'>
  ): ISterilisationRecord {
    return {
      status: SterilisationStatus.Pending,
      ...data,
      deleted: false,
    };
  }

  static archiveCol(
    record: IReffable<ISterilisationRecord>
  ): CollectionReference<ArchivedDocument<ISterilisationRecord>> {
    return subCollection<ArchivedDocument<ISterilisationRecord>>(
      record.ref,
      SterilisationRecordCollection.RecordHistory
    );
  }

  static editHistory$(
    cycle: IReffable<ISterilisationRecord>
  ): Observable<WithRef<ArchivedDocument<ISterilisationRecord>>[]> {
    return query$(SterilisationRecord.archiveCol(cycle)).pipe(
      multiSort((aRecord, bRecord) =>
        DocumentArchive.sortByArchivedAt(aRecord, bRecord)
      )
    );
  }
}
