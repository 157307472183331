<ng-container *ngIf="hasNoTeeth$ | async; else chart">
  <div class="layout-margin flex flex-col items-center justify-around">
    <h3>Chart is missing all teeth</h3>
    <button
      *ngIf="!disabled"
      mat-flat-button
      color="primary"
      (click)="openAddTooth()"
    >
      Add Tooth
    </button>
  </div>
</ng-container>
<ng-template #chart>
  <ng-container *ngIf="renderer$ | async as renderer; else loading">
    <pr-dental-chart-svg
      [renderer]="renderer"
      [ngClass]="{ disabled: disabled }"
     />
  </ng-container>
  <ng-template #loading>
    <mat-progress-bar mode="indeterminate" />
  </ng-template>
</ng-template>
