<mat-list-item>
  <h3 matListItemLine>{{ asset?.name }}</h3>

  <pr-increase-decrease-input
    *ngIf="showDuration()"
    (decrease)="decreaseDuration(requirement)"
    [decreaseDisabled]="requirement.isFullDuration"
    (increase)="increaseDuration(requirement)"
  >
    <label>Duration</label>
    <div *ngIf="requirement.isFullDuration; else durationDisplay">Full</div>
    <ng-template #durationDisplay>{{ requirement.duration }} mins</ng-template>
  </pr-increase-decrease-input>

  <pr-increase-decrease-input
    (decrease)="decreaseQty(requirement)"
    [decreaseDisabled]="requirement.quantity <= 1"
    (increase)="increaseQty(requirement)"
  >
    <label>Quantity</label>
    {{ requirement.quantity }}
  </pr-increase-decrease-input>

  <button mat-icon-button color="warn" (click)="delete(requirement)">
    <mat-icon>delete</mat-icon>
  </button>
</mat-list-item>
