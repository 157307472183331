<ng-template #loader>
  <mat-spinner color="accent" />
</ng-template>

<mat-sidenav-container *ngrxLet="saveDisabled$ | async as saveDisabled">
  <mat-sidenav-content>
    <div
      *ngrxLet="editMode$ | async as editMode"
      class="flex h-screen flex-col justify-center bg-black"
    >
      <ng-container *ngrxLet="isDicom$ | async as isDicom">
        <pr-media-action-buttons
          [editMode]="editMode"
          [saveDisabled]="saveDisabled"
          [isDicom]="isDicom"
          [fileName]="fileName$ | async"
          (closeMedia)="onClose()"
          (toggleSidenav)="sidenav.toggle()"
          (save)="save()"
          (previous)="goToPreviousImage()"
          (next)="goToNextImage()"
          (toggleEditMode)="toggleEditMode()"
        />

        <div class="flex-1 overflow-hidden">
          @if (isDicom) {
            <pr-dicom-viewer
              [media]="media$ | async"
              (closeDialog)="dialogRef.close()"
            />
          } @else {
            <pr-file-preview
              #preview
              [contentType]="contentType$ | async"
              [fileUrl]="fileUrl$ | async"
              [displayType]="displayType$ | async"
              [loaded]="loaded$ | async"
              [editMode]="editMode"
              (edited)="hasEdits$.next($event)"
            />
          }
        </div>
      </ng-container>
    </div>
  </mat-sidenav-content>

  <mat-sidenav #sidenav position="end">
    <div class="flex h-full flex-col justify-between">
      <pr-media-info
        [media]="media$ | async"
        (mediaChange)="this.media$.next($event); this.mediaUpdated$.next(true)"
      />
      <div *ngrxLet="saving$ as saving" class="controls">
        <pt-buttons-container padding="all-round" alignment="space-between">
          <button
            class="flex-1"
            mat-stroked-button
            (click)="sidenav.close()"
            [disabled]="saving"
          >
            Close
          </button>
          <pt-loader-button
            class="flex flex-1"
            color="primary"
            type="flat"
            [loading]="saving"
            [disabled]="saveDisabled"
            (clicked)="save()"
          >
            Save
          </pt-loader-button>
        </pt-buttons-container>
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
