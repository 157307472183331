import {
  IContextBuilder,
  IGapScopeData,
  IGapTemplateContext,
} from '@principle-theorem/principle-core/interfaces';
import {
  CALENDAR_TIME_FORMAT,
  Timezone,
  toMoment,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { AppointmentContextBuilder } from './appointment-context';

export class GapContextBuilder implements IContextBuilder<IGapTemplateContext> {
  private _appointmentContextBuilder: AppointmentContextBuilder;
  private _timezone: Timezone;

  constructor(private _data: IGapScopeData) {
    this._appointmentContextBuilder = new AppointmentContextBuilder(this._data);
    this._timezone = this._data.practice.settings.timezone;
  }

  build(): IGapTemplateContext {
    const gapOfferStartDate = moment(
      toMoment(this._data.gapCandidate.event.from)
    )
      .tz(this._timezone)
      .calendar(undefined, CALENDAR_TIME_FORMAT);

    return {
      ...this._appointmentContextBuilder.build(),
      gapOfferStartDate,
      gapOfferConfirmUrl: this._data.gapOfferConfirmUrl,
    };
  }
}
