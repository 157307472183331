<div class="grid grid-cols-5">
  <button
    mat-button
    color="warn"
    (click)="tableCommands.deleteTable(data.editor)"
    matTooltip="Delete Table"
  >
    <mat-icon svgIcon="pt-delete-table" />
  </button>
  <button
    mat-button
    (click)="tableCommands.addColumnBefore(data.editor)"
    matTooltip="Add Column Before"
  >
    <mat-icon svgIcon="pt-insert-column-left" />
  </button>
  <button
    mat-button
    (click)="tableCommands.addColumnAfter(data.editor)"
    matTooltip="Add Column After"
  >
    <mat-icon svgIcon="pt-insert-column-right" />
  </button>
  <button
    mat-button
    (click)="tableCommands.deleteColumn(data.editor)"
    matTooltip="Delete Column"
  >
    <mat-icon svgIcon="pt-delete-column" />
  </button>

  <button mat-button [matMenuTriggerFor]="extraOptions" matTooltip="more">
    <mat-icon svgIcon="pt-table-properties" />
  </button>

  <mat-menu #extraOptions="matMenu">
    <button
      mat-menu-item
      (click)="tableCommands.toggleHeaderColumn(data.editor)"
    >
      Toggle Header Column
    </button>
    <button mat-menu-item (click)="tableCommands.toggleHeaderRow(data.editor)">
      Toggle Header Row
    </button>
  </mat-menu>

  <button
    mat-button
    (click)="tableCommands.addRowBefore(data.editor)"
    matTooltip="Add Row Before"
  >
    <mat-icon svgIcon="pt-insert-row-above" />
  </button>
  <button
    mat-button
    (click)="tableCommands.addRowAfter(data.editor)"
    matTooltip="Add Row After"
  >
    <mat-icon svgIcon="pt-insert-row-below" />
  </button>
  <button
    mat-button
    (click)="tableCommands.deleteRow(data.editor)"
    matTooltip="Delete Row"
  >
    <mat-icon svgIcon="pt-delete-row" />
  </button>
  <button
    mat-button
    [disabled]="!tableCommands.canMergeCells(data.editor)"
    (click)="tableCommands.mergeCells(data.editor)"
    matTooltip="Merge Cells"
  >
    <mat-icon svgIcon="pt-merge-cells" />
  </button>
  <button
    mat-button
    [disabled]="!tableCommands.canSplitCell(data.editor)"
    (click)="tableCommands.splitCell(data.editor)"
    matTooltip="Split Cell"
  >
    <mat-icon svgIcon="pt-split-cells" />
  </button>
</div>
