import { type ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  MixedSchema,
  VersionedSchema,
  getSchemaSize,
  initVersionedSchema,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  NotesDialogComponent,
  type INotesDialogData,
} from '@principle-theorem/ng-interactions';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
  isDisabled$,
} from '@principle-theorem/ng-shared';
import {
  Appointment,
  ClinicalNote,
  Interaction,
  Patient,
  stafferToNamedDoc,
  toMention,
} from '@principle-theorem/principle-core';
import {
  IInteractionV2,
  InteractionType,
  MentionResourceType,
  PatientRelationshipType,
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  count as countOp,
  filterUndefined,
  getDoc,
  multiFilter,
  shareReplayCold,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject, combineLatest, type Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { type INoteSummary } from '../../appointment-card/appointment-all-notes/appointment-all-notes.component';
import { AppointmentInteractionsDialogComponent } from '../../appointment-interactions-dialog/appointment-interactions-dialog.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ClinicalNotesHistoryDialogComponent } from 'libs/ng-clinical-charting/src/lib/components/clinical-notes-history-dialog/clinical-notes-history-dialog.component';

interface IPinnedNote {
  content: MixedSchema;
  ref?: DocumentReference<IInteractionV2>;
}

@Component({
  selector: 'pr-appointment-all-notes-sidebar',
  templateUrl: './appointment-all-notes-sidebar.component.html',
  styleUrls: ['./appointment-all-notes-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentAllNotesSidebarComponent {
  trackByNote = TrackByFunctions.field<INoteSummary>('name');
  trackByPinnedNote = TrackByFunctions.ref<IPinnedNote>();
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);
  patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);
  noteSummaries$: Observable<INoteSummary[]>;
  pinnedNotes = [
    new TypedFormGroup<IPinnedNote>({
      content: new TypedFormControl<VersionedSchema>(initVersionedSchema()),
      ref: new TypedFormControl<DocumentReference<IInteractionV2>>(),
    }),
  ];

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService
  ) {
    this.noteSummaries$ = combineLatest([
      this._getClinicalSummary$(),
      this._getSchedulingSummary$(),
      this._getSocialSummary$(),
    ]).pipe(shareReplayCold());
  }

  async saveNote(form: TypedFormGroup<IPinnedNote>): Promise<void> {
    const note = form.getRawValue();
    const appointment = await snapshot(this.appointment$);
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    if (!note.ref) {
      await Appointment.addInteraction(
        { ref: appointment.ref },
        Interaction.init({
          pinned: true,
          content: note.content,
          type: InteractionType.Note,
          owner: stafferToNamedDoc(staffer),
          title: [
            toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
            toTextContent(` added a note`),
          ],
        })
      );
      this._snackBar.open(`Note saved`);
      return;
    }
    const originalInteraction = await getDoc(note.ref);
    await Interaction.amend(
      {
        ...originalInteraction,
        content: note.content,
      },
      staffer.ref
    );
    this._snackBar.open(`Note saved`);
  }

  isDisabled$(formGroup: TypedFormGroup<IPinnedNote>): Observable<boolean> {
    return isDisabled$(formGroup);
  }

  private _getSocialSummary$(): Observable<INoteSummary> {
    return this.patient$.pipe(
      map((patient) => ({
        name: 'Social',
        open: () => this._openSocialNotes(patient),
        count: patient.notes.length,
        pinnedNotes: patient.notes
          .filter((note) => note.pinned)
          .map((note) => ({
            uid: note.uid,
            content: note.content,
            createdAt: note.createdAt,
          })),
      }))
    );
  }

  private _getSchedulingSummary$(): Observable<INoteSummary> {
    const interactions$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.interactions$(appointment))
    );

    const count$ = interactions$.pipe(
      multiFilter((interaction) => getSchemaSize(interaction.content) > 0),
      countOp()
    );

    return combineLatest([
      this.patient$,
      this.appointment$,
      interactions$,
      count$,
    ]).pipe(
      tap(([_patient, _appointment, interactions, _count]) => {
        const pinnedNotes = interactions
          .filter((interaction) => interaction.pinned)
          .map(
            (interaction) =>
              new TypedFormGroup<IPinnedNote>({
                ref: new TypedFormControl(interaction.ref),
                content: new TypedFormControl(interaction.content),
              })
          );

        if (pinnedNotes.length) {
          this.pinnedNotes = pinnedNotes;
        }
      }),
      map(([patient, appointment, interactions, count]) => ({
        name: 'Scheduling',
        open: () => this._openInteractions(patient, appointment),
        count,
        pinnedNotes: interactions
          .filter((interaction) => interaction.pinned)
          .map((interaction) => ({
            uid: interaction.uid,
            content: interaction.content,
            createdAt: interaction.createdAt,
            createdBy: interaction.owner?.ref,
          })),
      }))
    );
  }

  private _getClinicalSummary$(): Observable<INoteSummary> {
    const count$ = this.patient$.pipe(
      switchMap((patient) =>
        Patient.withPatientRelationships$(
          patient,
          [PatientRelationshipType.DuplicatePatient],
          ClinicalNote.all$
        )
      ),
      map((clinicalNotes) => ClinicalNote.filterEmpty(clinicalNotes)),
      countOp()
    );

    return combineLatest([this.patient$, count$]).pipe(
      map(([patient, count]) => ({
        name: 'Clinical',
        open: () => this._openClinicalNotes(patient),
        count,
        pinnedNotes: [],
      }))
    );
  }

  private _openSocialNotes(patient: WithRef<IPatient>): void {
    const config: MatDialogConfig = DialogPresets.medium<INotesDialogData>({
      data: {
        title: 'Social notes',
        notable: {
          notes: patient.notes,
          ref: patient.ref,
        },
      },
    });
    this._openNotesDialog(NotesDialogComponent, config);
  }

  private _openInteractions(
    patient: WithRef<IPatient>,
    appointment: WithRef<IAppointment>
  ): void {
    const config: MatDialogConfig = DialogPresets.large({
      height: '80%',
      data: {
        appointment,
        patient,
      },
    });
    this._openNotesDialog(AppointmentInteractionsDialogComponent, config);
  }

  private _openClinicalNotes(patient: WithRef<IPatient>): void {
    const config: MatDialogConfig = DialogPresets.large({
      height: '80%',
      data: { patient },
    });
    this._dialog.closeAll();
    this._openNotesDialog(ClinicalNotesHistoryDialogComponent, config);
  }

  private _openNotesDialog<T>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config: MatDialogConfig
  ): void {
    this._dialog.open<T>(componentOrTemplateRef, config);
  }
}
