import {
  BlockNodes,
  IMenuButton,
  InlineNodes,
} from '@principle-theorem/editor';
import {
  EditorMenuItemComponent,
  MenuButtonLoaderFn,
} from '@principle-theorem/ng-prosemirror';
import { Editor } from '@tiptap/core';
import { CellSelection } from '@tiptap/pm/tables';
import {
  BubbleMenuComponent,
  IBubbleMenuData,
} from '../bubble-menu/bubble-menu.component';
import { ISelectMenuData } from '../select-menu/select-menu-view';

export function createFormattingToolbarMenu(
  editor: Editor,
  menuItems: MenuButtonLoaderFn<EditorMenuItemComponent>[]
): ISelectMenuData<BubbleMenuComponent, IBubbleMenuData> {
  return {
    repositionDialogOnSelectionChange: true,
    shouldShow: (props) => {
      const isLink = props.editor.isActive(InlineNodes.Link);
      const isMention = props.editor.isActive(InlineNodes.Mention);
      const isMedia = [
        BlockNodes.Image,
        BlockNodes.ImageUploading,
        BlockNodes.Video,
        BlockNodes.VideoUploading,
        BlockNodes.VideoEmbed,
      ].some((nodeType) => props.editor.isActive(nodeType));
      if (isLink || isMention || isMedia) {
        return false;
      }

      const isChildOfMenu = props.element.contains(document.activeElement);
      const hasEditorFocus = props.view.hasFocus();

      const selection = editor.view.state.selection;
      if (selection instanceof CellSelection) {
        return false;
      }
      const { from, to } = editor.view.state.selection;
      const text = editor.state.doc.textBetween(from, to, '\n');
      const hasContent = text.trim().length > 0;

      return (
        props.editor.isEditable &&
        hasContent &&
        hasEditorFocus &&
        (isChildOfMenu ||
          props.editor.isActive(BlockNodes.Paragraph) ||
          props.editor.isActive(BlockNodes.Heading))
      );
    },
    pluginKey: `formatting-bubble-menu`,
    nodeType: BlockNodes.Paragraph,
    component: BubbleMenuComponent,
    data: {
      editor,
      menuItems: menuItems.filter(
        (menuItem) =>
          (menuItem(editor).data as Partial<IMenuButton>)?.canActivateFn?.(
            editor
          ) ?? false
      ),
    },
  };
}
