import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { APP_VERSION } from './app-version';

@Component({
  selector: 'pt-app-version',
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppVersionComponent {
  constructor(@Inject(APP_VERSION) public version: string) {}
}
