import { type IAlertDialogInput } from '@principle-theorem/ng-shared';
import {
  getBaseTransaction,
  Transaction,
} from '@principle-theorem/principle-core';
import {
  type IInvoice,
  type IPractice,
  type ITransaction,
  type TransactionProvider,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import { type WithRef } from '@principle-theorem/shared';
import {
  type IBaseTransactionCompleteCallbackData,
  TransactionCompleteResult,
} from '@principle-theorem/tyro';

export async function toTyroTransaction(
  provider: TransactionProvider,
  invoice: WithRef<IInvoice>,
  practiceRef: DocumentReference<IPractice>,
  data: IBaseTransactionCompleteCallbackData,
  type: TransactionType = TransactionType.Incoming
): Promise<ITransaction> {
  return Transaction.init({
    ...(await getBaseTransaction(invoice, type)),
    reference: data.transactionId,
    status: getStatus(data.result),
    provider,
    practiceRef,
  });
}

export function getStatus(
  result: TransactionCompleteResult
): TransactionStatus | undefined {
  switch (result) {
    case TransactionCompleteResult.APPROVED:
      return TransactionStatus.Complete;
    case TransactionCompleteResult.CANCELLED:
    case TransactionCompleteResult.DECLINED:
    case TransactionCompleteResult.REVERSED:
    case TransactionCompleteResult.SYSTEM_ERROR:
    case TransactionCompleteResult.ERROR:
    case TransactionCompleteResult.CLAIM_DECLINED:
    case TransactionCompleteResult.CANCEL_DECLINED:
    case TransactionCompleteResult.VOIDED:
      return TransactionStatus.Failed;
    case TransactionCompleteResult.NOT_STARTED:
    case TransactionCompleteResult.UNKNOWN:
    default:
      return undefined;
  }
}

export const TYRO_ERROR_DIALOG_DATA: IAlertDialogInput = {
  title: 'Transaction Error',
  prompt:
    'Encountered an unknown error during transaction. Please check Tyro Logs.',
  submitLabel: 'Close',
};
