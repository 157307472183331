import { Injectable } from '@angular/core';
import { AppointmentManager } from '@principle-theorem/ng-appointment/store';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { Appointment } from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  AppointmentSummary,
  type IAppointment,
  type IPatient,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  IReffable,
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { AppointmentCardAction } from './appointment-card-action-interface';

@Injectable()
export class ConfirmAppointmentActionService extends AppointmentCardAction {
  name = 'Confirm Appointment';

  constructor(private _organisation: OrganisationService) {
    super();
  }

  isVisible(appointment: WithRef<IAppointment> | AppointmentSummary): boolean {
    return Appointment.canConfirm(appointment);
  }

  async run(
    appointment: WithRef<IAppointment>,
    _: IReffable<IPatient>
  ): Promise<void> {
    const staffer: WithRef<IStaffer> = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    await AppointmentManager.markConfirmed(staffer, appointment);
  }

  updateEventableSummary(
    eventable: AppointmentSummary,
    updateFn: (
      oldEvent: AppointmentSummary,
      newEvent: AppointmentSummary
    ) => void
  ): void {
    const metadata = {
      ...eventable.metadata,
      status: AppointmentStatus.Confirmed,
    };

    updateFn(eventable, { ...eventable, metadata });
  }
}
