import { getEnumValues } from './common';
import { DayOfWeek } from './time/days';
import { ISODateType } from './time/timezone';

export enum CustomRecurrenceFrequency {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export const CUSTOM_RECURRENCE_FREQUENCIES = getEnumValues(
  CustomRecurrenceFrequency
);

export interface IFrequencyDisplay {
  displayName: string;
  value: CustomRecurrenceFrequency;
}

export const RECURRENCE_FREQUENCY_DISPLAY_MAP: IFrequencyDisplay[] = [
  {
    displayName: 'days',
    value: CustomRecurrenceFrequency.Daily,
  },
  {
    displayName: 'weeks',
    value: CustomRecurrenceFrequency.Weekly,
  },
  {
    displayName: 'months',
    value: CustomRecurrenceFrequency.Monthly,
  },
  {
    displayName: 'years',
    value: CustomRecurrenceFrequency.Yearly,
  },
];

export enum Frequency {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  Custom = 'Custom',
}

export const FREQUENCIES = getEnumValues(Frequency);

export enum EndingType {
  Never = 'never',
  Date = 'on',
  Count = 'after',
}

export type CustomWeeklyPattern = {
  frequencyType: Frequency.Custom;
  customFrequencyType: CustomRecurrenceFrequency.Weekly;
  daysOfWeek: DayOfWeek[];
  seperationCount: number;
};

export type CustomFrequency = {
  frequencyType: Frequency.Custom;
  customFrequencyType: CustomRecurrenceFrequency;
};

export type FrequencyRecurrencePattern =
  | {
      frequencyType: Frequency.Daily;
    }
  | {
      frequencyType: Frequency.Monthly | Frequency.Weekly | Frequency.Yearly;
      startDate: ISODateType;
    }
  | {
      frequencyType: Frequency.Custom;
      customFrequencyType: CustomRecurrenceFrequency.Daily;
      seperationCount: number;
    }
  | CustomWeeklyPattern
  | {
      frequencyType: Frequency.Custom;
      customFrequencyType: CustomRecurrenceFrequency.Monthly;
      weeksOfMonth: number[];
      daysOfMonth: number[];
      seperationCount: number;
    }
  | {
      frequencyType: Frequency.Custom;
      customFrequencyType: CustomRecurrenceFrequency.Yearly;
      monthsOfYear: number[];
      seperationCount: number;
      startDate: ISODateType;
    };

export type EndsOnDate = {
  endingType: EndingType.Date;
  endingDate: ISODateType;
};

export type EndsOnCount = {
  endingType: EndingType.Count;
  occurrenceCount: number;
};

export type EndTypeRecurrencePattern =
  | EndsOnDate
  | EndsOnCount
  | {
      endingType: EndingType.Never;
    };

export type RecurrencePatternOption = IRecurrencePattern &
  EndTypeRecurrencePattern &
  FrequencyRecurrencePattern;

export interface IRecurrencePattern {
  // Whether the frequency of repeat is one of the standard options or custom
  frequencyType: Frequency;
  startDate?: ISODateType;
  // When the recurring tasks will complete
  endingType: EndingType;
  endingDate?: ISODateType;
  // The type of custom frequency that's being used
  customFrequencyType?: CustomRecurrenceFrequency;
  occurrenceCount?: number;
  // 'Days' and 'Years' are only affected by 'seperationCount'
  seperationCount?: number;
  // Weeks includes 'seperationCount' and 'daysOfWeek'
  daysOfWeek?: DayOfWeek[];
  // TODO: Currently available in the form but not implemented
  weeksOfMonth?: number[];
  daysOfMonth?: number[];
  monthsOfYear?: number[];
  times?: string[];
}

export function endsOnDate(
  pattern: IRecurrencePattern
): pattern is IRecurrencePattern & EndsOnDate {
  return pattern.endingType === EndingType.Date;
}

export function endsOnCount(
  pattern: IRecurrencePattern
): pattern is IRecurrencePattern & EndsOnCount {
  return pattern.endingType === EndingType.Count;
}
