import { AnyExtension, Extension } from '@tiptap/core';
import { selectAll } from '@tiptap/pm/commands';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { type ExtensionRegisterReturnFn } from '../core/extension';

export function createSelectAllExtension(): AnyExtension {
  return Extension.create({
    addProseMirrorPlugins() {
      return [createFocusSelectPlugin()(this.editor)];
    },
  });
}

function createFocusSelectPlugin(): ExtensionRegisterReturnFn<Plugin> {
  const selectAllTriggerKey = 'Tab';
  return () =>
    new Plugin({
      key: new PluginKey('tabFocusSelectAll'),
      props: {
        handleDOMEvents: {
          keyup: (view, event) => {
            if (
              !(event instanceof KeyboardEvent) ||
              event.target !== view.dom ||
              event.key !== selectAllTriggerKey
            ) {
              return true;
            }
            selectAll(view.state, view.dispatch);
            return false;
          },
        },
      },
    });
}
