import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { PATIENT_INTERACTION_RESOURCE_TYPE } from '../../../destination/entities/patient-interactions';

export const PATIENT_INTERACTION_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Interaction List',
      description: '',
      idPrefix: PATIENT_INTERACTION_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ICorePracticePatientInteraction {
  note: string; // "she has been quoted $2500 for tomorrow, remaining amount to be paid in next visit-Reecha"
  createDate: string; // 2021-11-29 01:27:36.927
  patientId: number;
}

export function isCorePracticePatientInteraction(
  item: unknown
): item is ICorePracticePatientInteraction {
  return TypeGuard.interface<ICorePracticePatientInteraction>({
    note: isString,
    createDate: isString,
    patientId: isNumber,
  })(item);
}

export interface ICorePracticePatientInteractionTranslations {}

export interface ICorePracticePatientInteractionFilters {
  patientId: number;
}

const PATIENT_INTERACTION_SOURCE_QUERY = `
SELECT
  Note AS note,
  CreateDate AS create_date,
  PatientId AS patient_id
FROM tblComment
`;

export class PatientInteractionSourceEntity extends BaseSourceEntity<
  ICorePracticePatientInteraction,
  ICorePracticePatientInteractionTranslations,
  ICorePracticePatientInteractionFilters
> {
  sourceEntity = PATIENT_INTERACTION_SOURCE_ENTITY;
  entityResourceType = PATIENT_INTERACTION_RESOURCE_TYPE;
  sourceQuery = PATIENT_INTERACTION_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientInteraction;

  translate(
    _data: ICorePracticePatientInteraction,
    _timezone: Timezone
  ): ICorePracticePatientInteractionTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientInteraction): string {
    return `${data.patientId}-${data.createDate}`;
  }

  getSourceLabel(data: ICorePracticePatientInteraction): string {
    return `${data.patientId}-${data.createDate}`;
  }

  getFilterData(
    data: ICorePracticePatientInteraction,
    _timezone: Timezone
  ): ICorePracticePatientInteractionFilters {
    return {
      patientId: data.patientId,
    };
  }
}
