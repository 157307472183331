import { createAction, props } from '@ngrx/store';
import { type ICalendarEvent } from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData } from '@principle-theorem/shared';

export enum CalendarEventsAction {
  LoadCalendarEvents = '[Calendar Events] Load Events',
  SetSelectCalendarEvent = '[Calendar Events] Set Selected Calendar Event',
  AddCalendarEvent = '[Calendar Events] Add Calendar Event',
  EditCalendarEvent = '[Calendar Events] Edit Calendar Event',
  SaveCalendarEvent = '[Calendar Events] Save Calendar Event',
  DeleteCalendarEvent = '[Calendar Events] Delete Calendar Event',
}

export interface ICalendarEventId {
  id: string;
}

export interface ISaveCalendarEventData {
  calendarEvent: ICalendarEvent;
}

export const loadCalendarEvents = createAction(
  CalendarEventsAction.LoadCalendarEvents
);

export const selectCalendarEvent = createAction(
  CalendarEventsAction.SetSelectCalendarEvent,
  props<ICalendarEventId>()
);

export const saveCalendarEvent = createAction(
  CalendarEventsAction.SaveCalendarEvent,
  props<SerialisedData<ISaveCalendarEventData>>()
);

export const deleteCalendarEvent = createAction(
  CalendarEventsAction.DeleteCalendarEvent
);
