<ng-container *ngIf="auth.authUser$ | async as authUser; else firebaseui">
  <div class="flex flex-col items-center">
    <h1 class="mat-headline-4">Welcome {{ authUser?.displayName }}!</h1>

    <ng-container *ngIf="userIsVerified$ | async; else verifyEmail">
      <div class="flex flex-col items-center gap-4">
        <pt-workspace-selector [workspaceSelectors]="workspaceSelectors" />
        <button mat-stroked-button (click)="auth.logout()">Logout</button>
      </div>
    </ng-container>

    <ng-template #verifyEmail>
      <ngx-auth-firebaseui-email-confirmation
        class="layout-margin"
        signOutText="Sign Out"
        verifyEmailGoBackText="Go Back"
        [email]="authUser?.email"
        (signOut)="auth.logout()"
      />
    </ng-template>
  </div>
</ng-container>

<ng-template #firebaseui>
  <img
    *ngIf="logoSrc$ | async as src"
    [ngSrc]="src"
    class="mx-auto py-3"
    width="150"
    height="70"
    priority
  />
  <ngx-auth-firebaseui-register
    #registerComponent
    class="flat"
    appearance="outline"
    createAccountButtonText="Register"
    titleText="Register"
    loginButtonText="Log In"
    matIconPrefix
    (onSuccess)="onSuccess()"
    (onError)="onError($event)"
    (onLoginRequested)="onLogin()"
  />

  <div class="mx-auto flex max-w-xs flex-col items-stretch gap-2">
    <strong class="self-center">Register with</strong>
    <ngx-auth-firebaseui-providers
      [providers]="providers"
      [layout]="providersLayout"
      (onSuccess)="onSuccess()"
    />
  </div>
</ng-template>
