import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  inject,
  signal,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PractitionerSelectorComponent } from '@principle-theorem/ng-clinical-charting';
import {
  ContentContainerComponent,
  EmptyStateComponent,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { Staffer } from '@principle-theorem/principle-core';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import {
  IPrescription,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  filterUndefined,
  isSameRef,
  snapshotDefined,
} from '@principle-theorem/shared';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { PatientPrescriptionsListComponent } from '../patient-prescriptions-list/patient-prescriptions-list';
import { PrescriptionService } from '../prescription.service';

@Component({
    selector: 'pr-patient-prescriptions',
    imports: [
        CommonModule,
        PatientPrescriptionsListComponent,
        PractitionerSelectorComponent,
        MatTooltipModule,
        ContentContainerComponent,
        MatButtonModule,
        EmptyStateComponent,
    ],
    providers: [PrescriptionService],
    templateUrl: './patient-prescriptions.component.html',
    styleUrl: './patient-prescriptions.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientPrescriptionsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _snackBar = inject(MatSnackBar);
  readonly title = 'Prescriptions';
  prescription = inject(PrescriptionService);
  organisation = inject(OrganisationService);
  prescribingAs = signal<WithRef<IStaffer> | undefined>(undefined);

  hasPermissions = toSignal(this._canCreatePrescription$(), {
    initialValue: false,
  });

  feedbackMessage = computed(() => {
    if (!this.hasPermissions()) {
      return 'Update your permissions to manage prescriptions.';
    }
    if (!this.prescribingAs()) {
      return 'Select a prescriber to create a prescription.';
    }
    return '';
  });

  constructor() {
    this.prescription.loadAll$.next();

    this._getPrescribingAs$()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((prescriber) => this.prescribingAs.set(prescriber));

    toObservable(this.prescription.selectedPrescription)
      .pipe(filterUndefined(), take(1), takeUntil(this._onDestroy$))
      .subscribe(
        (prescription) => void this.prescription.navigate(prescription)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async create(): Promise<void> {
    const prescriber = this.prescribingAs();
    const actionedBy = await snapshotDefined(this.organisation.staffer$);
    if (!prescriber) {
      return;
    }
    this.prescription.create(prescriber, actionedBy);
  }

  async navigate(prescription: WithRef<IPrescription>): Promise<void> {
    if (!this.hasPermissions()) {
      this._snackBar.open(this.feedbackMessage());
      return;
    }
    await this.prescription.navigate(prescription);
  }

  private _getPrescribingAs$(): Observable<WithRef<IStaffer> | undefined> {
    const staffer$ = this.organisation.staffer$.pipe(filterUndefined());
    const prescribers$ = this.organisation.prescribers$.pipe(filterUndefined());
    return combineLatest([staffer$, prescribers$]).pipe(
      map(([staffer, prescribers]) =>
        prescribers.find((prescriber) => isSameRef(staffer, prescriber))
      )
    );
  }

  private _canCreatePrescription$(): Observable<boolean> {
    return this.organisation.staffer$.pipe(
      filterUndefined(),
      switchMap((staffer) =>
        Staffer.hasPermission$(staffer, PatientPermissions.PrescriptionManage)
      )
    );
  }
}
