import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  type OnDestroy,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  ObservableDataSource,
  extendSortingDataAccessor,
} from '@principle-theorem/ng-shared';
import { MeasureFormatter } from '@principle-theorem/principle-core/interfaces';
import { DataPointValue } from '@principle-theorem/reporting';
import { filterUndefined, multiMap } from '@principle-theorem/shared';
import { type Crossfilter } from 'crossfilter2';
import { get, isNumber, isString } from 'lodash';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  of,
  type Observable,
} from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { type IDataPoint } from '../../../../../models/report/charts/measure-data-interfaces';
import { getD3NumberFormatter } from '../../../../core/chart-builders/dc-chart-builders/dc-single-value-chart-builder';
import {
  ITableRow,
  TABLE_GROUP_KEY,
} from '../../../../core/chart-builders/table-chart-builder';
import {
  IResolvedColumnProperty,
  ResolvedColumns,
} from '../../../report-builder-results-table/report-builder-column-selector-dialog/resolved-columns';
import { ReportBuilderStore } from '../../../report-builder.store';
import { IChartDisplay } from '../report-builder-chart/report-builder-chart-display';
import { ReportBuilderChartColumnManager } from './report-builder-chart-column-manager';

@Component({
    selector: 'pr-report-builder-table-chart',
    templateUrl: './report-builder-table-chart.component.html',
    styleUrls: ['./report-builder-table-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ReportBuilderChartColumnManager],
    standalone: false
})
export class ReportBuilderTableChartComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _ndx$ = new BehaviorSubject<Crossfilter<unknown> | undefined>(
    undefined
  );
  dataSource: ObservableDataSource<ITableRow>;
  columns$ = new BehaviorSubject<IResolvedColumnProperty[]>([]);
  columnIds$: Observable<string[]>;
  display$ = new ReplaySubject<IChartDisplay>(1);
  @Input() sectionName: string;
  @Input({ transform: coerceBooleanProperty }) readOnly: boolean = false;

  pageSizeOptions = [5, 10];
  previousIndex: number;

  @Input()
  set display(display: IChartDisplay) {
    if (display) {
      this.display$.next(display);
    }
  }

  @Input()
  set crossfilter(ndx: Crossfilter<unknown>) {
    if (ndx) {
      this._ndx$.next(ndx);
    }
  }

  @ViewChild(MatPaginator)
  set paginator(paginator: MatPaginator) {
    if (paginator) {
      this.dataSource.paginator = paginator;
    }
  }

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor(
    public store: ReportBuilderStore,
    public columnManager: ReportBuilderChartColumnManager
  ) {
    const filteredData$ = this.display$.pipe(
      switchMap(
        (display) => display.tableDisplay?.chart?.filteredData$ ?? of([])
      )
    );

    this.dataSource = new ObservableDataSource(filteredData$);
    this.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, sortHeaderId) => this._sortingDataAccessor(data, sortHeaderId)
    );

    const selectedColumns$ = this.display$.pipe(
      map((display) => [
        {
          uid: display.chart.groupBy,
          dataPoint: display.chart.groupBy,
          label: TABLE_GROUP_KEY,
        },
        ...display.chart.settings,
      ]),
      multiMap((column) => ({
        uid: column.uid,
        id: column.dataPoint,
        label: column.label,
      }))
    );

    combineLatest([
      this.store.dataSource$.pipe(filterUndefined()),
      selectedColumns$,
    ])
      .pipe(
        map(([dataSource, columns]) =>
          dataSource ? ResolvedColumns.toColumns(dataSource, columns) : []
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((columns) => this.columns$.next(columns));

    this.columnIds$ = this.columns$.pipe(
      multiMap((column) => column.definition.uid)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.dataSource.disconnect();
  }

  getDataPoint(data: IGetDataPointRequest<unknown>): IDataPoint {
    const fact = data.row;
    const property = data.column.property;
    const label = ResolvedColumns.getLabel(data.column);
    const value = get(
      fact,
      data.column.definition.label === TABLE_GROUP_KEY
        ? TABLE_GROUP_KEY
        : label,
      0
    ) as DataPointValue;

    if (this.canFilterDataPoint(data.column)) {
      return {
        label,
        value,
      };
    }

    return {
      label,
      value,
      formatter: property.metadata.formatter,
      formatterValue: property.metadata.formatterValue,
    };
  }

  isLink(dataPoint?: IDataPoint | null): boolean {
    return dataPoint?.formatter === MeasureFormatter.Link;
  }

  hasValidLink(dataPoint?: IDataPoint | null): boolean {
    return !!dataPoint?.value;
  }

  isText(dataPoint?: IDataPoint | null): boolean {
    return dataPoint?.formatter === MeasureFormatter.Text;
  }

  canFilterDataPoint(column: IResolvedColumnProperty): boolean {
    return !getD3NumberFormatter(
      column.property.metadata.formatter,
      column.property.metadata.formatterValue
    );
  }

  private _sortingDataAccessor(
    record: unknown,
    sortHeaderId: string
  ): string | number | undefined {
    const column = this.columns$
      .getValue()
      .find((columnItem) => columnItem.definition.uid === sortHeaderId);

    if (!column || !column.definition.label) {
      return;
    }

    const value = get(record, column.definition.label) as unknown;
    if (!value) {
      return;
    }

    if (isString(value) || isNumber(value)) {
      return value;
    }

    return String(value);
  }
}

interface IGetDataPointRequest<T> {
  row: Record<string, T>;
  column: IResolvedColumnProperty;
}
