import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  type LabJobStatus,
  LAB_JOB_STATUS_COLOUR_MAP,
} from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-lab-job-status',
  templateUrl: './lab-job-status.component.html',
  styleUrls: ['./lab-job-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabJobStatusComponent {
  @Input() status: LabJobStatus;
  @Input() dense = false;
  statusColourMap: {
    [key in LabJobStatus]: string;
  } = LAB_JOB_STATUS_COLOUR_MAP;
}
