<div fxLayout="row" fxLayoutAlign="space-between start">
  <form [formGroup]="form" (ngSubmit)="save()" fxLayout="column">
    <mat-form-field>
      <mat-label>Enter video URL</mat-label>
      <input matInput type="url" formControlName="href" />
      <span matTextSuffix fxLayout="row" fxLayoutAlign="start center">
        <button mat-flat-button color="primary">Save</button>
      </span>
      <mat-hint>Youtube, Vimeo & Loom supported</mat-hint>
    </mat-form-field>
  </form>
</div>
