import {
  DocumentReference,
  type AtLeast,
  type INamedDocument,
  type ISODateType,
  type Timezone,
} from '@principle-theorem/shared';
import { type IBrand } from '../brand';
import { type IOrganisation } from '../organisation/organisation';
import { type IPractice } from '../practice/practice';
import { IStaffer } from '../staffer/staffer';
import { type ICustomMappings } from './custom-mappings';
import { type IDestination, type IDestinationHandler } from './destination';
import { type ISource, type ISourceHandler } from './source';
import {
  type ITranslationMap,
  type ITranslationMapHandler,
} from './translation-mappings';

export enum PracticeMigrationStatus {
  Pending = 'pending',
  InProgress = 'inProgress',
  Complete = 'complete',
}

export const PRACTICE_MIGRATION_STATUS_MAP: {
  [key in PracticeMigrationStatus]: string;
} = {
  [PracticeMigrationStatus.Pending]: 'default',
  [PracticeMigrationStatus.InProgress]: 'accent',
  [PracticeMigrationStatus.Complete]: 'primary',
};

export const PRACTICE_MIGRATIONS_PROJECT = 'practice-migrations';

export const PRACTICE_MIGRATIONS_DATABASE = 'practice-migrations';

export const PRACTICE_MIGRATIONS_FIRESTORE_DATABASE = 'practice-migrations';

export const PRACTICE_MIGRATION_DATABASE_BACKUP_FOLDER = 'backup-files';

export interface IPracticeMigration {
  metadata: IPracticeMigrationMetadata;
  configuration: IPracticeMigrationConfiguration;
  source: ISource;
  destination: IDestination;
  mappings: ICustomMappings;
  status: PracticeMigrationStatus;
  translationMaps: ITranslationMap[];
  manualTranslationMap: ITranslationMap[];
}

export type PracticeMigrationEssentials = Pick<
  IPracticeMigration,
  'metadata' | 'source' | 'destination' | 'mappings'
>;

export interface IPracticeMigrationMetadata {
  name: string;
  version: string;
  supportsMultiplePractices: boolean;
  description?: string;
}

export interface IPracticeMigrationConfiguration {
  organisation: INamedDocument<IOrganisation>;
  brand: INamedDocument<IBrand>;
  practices: INamedDocument<IPractice>[];
  restrictAccessTo?: DocumentReference<IStaffer>[];
  projectId: string;
  destinationBucket: string;
  timezone: Timezone;
  backupDate: ISODateType;
  backupPath?: string;
  databaseInstanceName?: string;
  databaseName?: string;
  serviceAccount?: string;
}

export interface IPracticeMigrationHandler {
  source: ISourceHandler;
  destination: IDestinationHandler;
  translationMap: ITranslationMapHandler;
}

export enum PracticeMigrationCollection {
  TranslationMaps = 'translationMaps',
  SourceEntities = 'sourceEntities',
  DestinationEntities = 'destinationEntities',
  CustomMappings = 'customMappings',
  Notes = 'notes',
}

export type PracticeMigrationInitData = PracticeMigrationEssentials &
  AtLeast<IPracticeMigration, 'configuration'>;
