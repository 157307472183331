import { Directive, Input, type OnDestroy } from '@angular/core';
import {
  type CanBeChartedProperty,
  generateBuilderData,
  type ICanGroupMeasuresProperty,
  type IChartConfig,
  toMeasureBuilderData,
} from '@principle-theorem/reporting';
import { set } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { type ChartBuilder } from '../../models/report/charts/chart-builder';
import { type IChartCard } from '../../models/report/charts/chart-card';
import { type DateRangeDataBuilder } from '../../models/report/data-builders/date-range-data-builder';
import { type IncomeMeasure } from '../../models/report/income-measure';
import { CustomChartType } from '@principle-theorem/principle-core/interfaces';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class MeasureDataChartComponent implements OnDestroy {
  protected _onDestroy$ = new Subject<void>();
  protected _dataBuilderSub: Subscription = Subscription.EMPTY;
  protected _dataBuilder: DateRangeDataBuilder;

  ngOnDestroy(): void {
    this._dataBuilderSub.unsubscribe();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @Input()
  set dataBuilder(dataBuilder: DateRangeDataBuilder) {
    if (!dataBuilder) {
      return;
    }
    this._dataBuilder = dataBuilder;
    this._redraw();

    this._dataBuilderSub.unsubscribe();
    this._dataBuilderSub = this.dataBuilder.dateChange
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this._redraw());
  }

  get dataBuilder(): DateRangeDataBuilder {
    return this._dataBuilder;
  }

  protected abstract _redraw(): void;

  protected _chartMeasures(
    type: CustomChartType,
    measures: CanBeChartedProperty[],
    groupByDimension?: ICanGroupMeasuresProperty
  ): IChartConfig {
    return {
      type,
      builderData: generateBuilderData({
        measures: measures.map((measure) => toMeasureBuilderData(measure)),
        groupByDimension,
      }),
      labels: {
        title: '',
      },
    };
  }

  protected _tableChartCard(
    measures: CanBeChartedProperty[],
    groupByDimension: ICanGroupMeasuresProperty
  ): IChartCard {
    return this.dataBuilder.toLineChart(
      this._chartMeasures(CustomChartType.Line, measures, groupByDimension)
    );
  }

  protected _incomeMetricBarChart(
    incomeMetrics: IncomeMeasure[],
    groupByDimension: ICanGroupMeasuresProperty
  ): ChartBuilder {
    const measures = incomeMetrics.map((measure) => measure.measure);

    const options = {
      height: 250,
      backgroundColor: 'none',
      bars: 'horizontal',
      isStacked: true,
      vAxis: { title: '' },
    };

    const chart: ChartBuilder = this.dataBuilder
      .toBarChart(
        this._chartMeasures(CustomChartType.Bar, measures, groupByDimension)
      )
      .chartBuilder.addChartOptions(options);

    // TODO: Reimplement ability to mark measures as negative or positive sum values
    return chart;
    // this._transformIncomeMetrics(chart.chart.chartContents.dataPoints, incomeMetrics);
  }

  // protected _transformIncomeMetrics(
  //   dataPoints: IChartDataPoint[],
  //   incomeMetrics: IncomeMetric[]
  // ): void {
  //   dataPoints.map((chartDataPoint: IChartDataPoint) => {
  //     chartDataPoint.values.map((dataPoint: IDataPoint) => {
  //       if (this._isNegativeValue(dataPoint, incomeMetrics)) {
  //         dataPoint.value = -dataPoint.value;
  //       }
  //     });
  //   });
  // }

  // protected _isNegativeValue(
  //   _dataPoint: IDataPoint,
  //   _incomeMetrics: IncomeMetric[]
  // ): boolean {
  //   const metric: IncomeMetric | undefined = incomeMetrics
  //     .find((incomeMetric: IncomeMetric) => {
  //       const stat: IStatistic | undefined = this.dataBuilder
  //         .getStats()[incomeMetric.measure.metadata.label];
  //       return stat && stat.label === dataPoint.label;
  //     });
  //   return (metric && metric.isNegativeValue) ? true : false;
  // }

  protected _stackedAreaChart(
    measures: CanBeChartedProperty[],
    groupByDimension: ICanGroupMeasuresProperty
  ): ChartBuilder {
    const config: IChartConfig = {
      type: CustomChartType.StackedArea,
      builderData: generateBuilderData({
        plottedOverTime: true,
        measures: measures.map((measure) => toMeasureBuilderData(measure)),
        groupByDimension,
      }),
      labels: {
        title: '',
      },
    };

    const options: google.visualization.AreaChartOptions = {
      backgroundColor: 'none',
      height: 250,
      legend: { position: 'bottom' },
      chartArea: {
        top: 10,
        right: 20,
        bottom: 50,
        left: 50,
      },
    };

    return this.dataBuilder
      .toAreaChart(config)
      .chartBuilder.addChartOptions(options);
  }

  protected _pieChart(
    measures: CanBeChartedProperty[],
    groupByDimension: ICanGroupMeasuresProperty
  ): ChartBuilder {
    const chart: ChartBuilder = this.dataBuilder.toPieChart(
      this._chartMeasures(CustomChartType.Pie, measures, groupByDimension)
    ).chartBuilder;

    chart.addChartOptions({
      height: 250,
      legend: { position: 'right' },
    });

    return chart;
  }

  protected _dualAxisBarChart(
    measures: CanBeChartedProperty[],
    groupByDimension: ICanGroupMeasuresProperty
  ): ChartBuilder {
    const options = {
      series: {},
      axes: {
        x: {
          [measures[0].metadata.label]: { side: 'top' },
        },
      },
    };

    measures.map((measure: CanBeChartedProperty, index: number) => {
      set(options, `series.${index}`, { axis: measure.metadata.label });
    });

    return this.dataBuilder
      .toBarChart(
        this._chartMeasures(CustomChartType.Bar, measures, groupByDimension)
      )
      .chartBuilder.addChartOptions(options);
  }
}
