<div
  class="page-content flex flex-col gap-4"
  [ngClass]="{ 'layout-padding': display.isDesktop$ | async }"
>
  <div
    class="search-bar"
    fxLayout="row"
    fxLayoutAlign="space-between"
    fxLayoutGap="16px"
  >
    <pt-search-field fxFlex>
      <input
        #searchFilter
        matInput
        type="text"
        placeholder="Search by title, assignee, or creator"
        [formControl]="searchCtrl"
        #search
      />
      <button mat-icon-button fxHide.gt-xs fxShow (click)="addNewTask()">
        <mat-icon class="add-task-icon">add</mat-icon>
      </button>
    </pt-search-field>
    <button
      mat-raised-button
      fxHide
      fxShow.gt-xs
      color="primary"
      (click)="addNewTask()"
    >
      Create Task
    </button>
  </div>

  <div class="mb-2 mt-6 flex justify-between">
    <div class="flex flex-col items-center justify-between gap-2 md:flex-row">
      <mat-button-toggle-group
        class="filter-presets"
        (change)="updatePresetFilters($event.value)"
        [value]="taskFilter.preset$ | async"
        [hideSingleSelectionIndicator]="true"
      >
        <mat-button-toggle
          [value]="taskPreset.id"
          *ngFor="let taskPreset of taskPresetOptions; trackBy: trackByPreset"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <span>
              {{ taskPreset.label }}
            </span>
            <span
              [ngClass]="{ 'toggle-badge': taskPreset.showBadge }"
              [matBadge]="presetBadgeCounts$[taskPreset.id] | async"
              [matBadgeHidden]="!taskPreset.showBadge"
              matBadgeColor="primary"
            >
            </span>
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-button-toggle-group
        (change)="updateTaskStatusFilter($event.value)"
        [value]="taskFilter.status$ | async"
        [hideSingleSelectionIndicator]="true"
      >
        <mat-button-toggle value="active">Active</mat-button-toggle>
        <mat-button-toggle value="completed">Completed</mat-button-toggle>
        <mat-button-toggle value="deleted">Deleted</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="flex-1"></div>

    <div class="task-filter-container flex justify-end gap-2">
      <pr-range-selector
        class="mat-body-2 self-stretch"
        label="Date Range"
        [selected]="taskFilter.filteredRange$ | async"
        (rangeChanged)="dateRangeChange($event)"
        (rangeReset)="resetDate()"
      />

      <mat-form-field subscriptSizing="dynamic" class="w-36">
        <mat-label>Sort By</mat-label>
        <mat-select [formControl]="sortControl">
          <mat-option
            *ngFor="let option of sortOptions; trackBy: trackBySortOption"
            [value]="option"
          >
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="task-list-container mat-elevation-z1">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="center stretch">
        <div class="checkbox">
          <mat-checkbox
            color="primary"
            [indeterminate]="selectionList.isSomeSelected$ | async"
            [checked]="selectionList.isAllSelected$ | async"
            (change)="selectionList.toggleAllSelected()"
          />
        </div>

        <ng-container *ngIf="(selectionList.isNoneSelected$ | async) === false">
          <mat-divider [vertical]="true" />

          <div fxFlex>
            <button
              mat-icon-button
              [matMenuTriggerFor]="assigneeMenu"
              matTooltip="Change Assignee"
              matTooltipShowDelay="500"
            >
              <mat-icon>assignment_ind</mat-icon>
            </button>

            <mat-menu #assigneeMenu="matMenu">
              <ng-container *ngIf="teams$ | async as teams">
                <div mat-subheader *ngIf="teams.length" class="menu-subheader">
                  Teams
                </div>
                <button
                  mat-menu-item
                  *ngFor="let team of teams; trackBy: trackByTeam"
                  [value]="team"
                  (click)="updateAssignee(team)"
                >
                  {{ team.name }}
                </button>
              </ng-container>

              <ng-container *ngIf="staff$ | async as staff">
                <div mat-subheader *ngIf="staff.length" class="menu-subheader">
                  Staff
                </div>
                <button
                  mat-menu-item
                  *ngFor="let staffer of staff; trackBy: trackByStaffer"
                  [value]="staffer"
                  (click)="updateAssignee(staffer)"
                >
                  {{ staffer.user.name }}
                </button>
              </ng-container>
            </mat-menu>

            <button
              mat-icon-button
              [matMenuTriggerFor]="priorityMenu"
              matTooltip="Change Priority"
              matTooltipShowDelay="500"
            >
              <mat-icon>low_priority</mat-icon>
            </button>

            <mat-menu #priorityMenu="matMenu">
              <button
                mat-menu-item
                *ngFor="let priority of priorities; trackBy: trackByPriority"
                (click)="updatePriority(priority)"
              >
                {{ priority | titlecase }}
              </button>
            </mat-menu>

            <button
              mat-icon-button
              matTooltip="Change Due Date"
              matTooltipShowDelay="500"
              (click)="dueDate.open()"
            >
              <mat-icon>date_range</mat-icon>
            </button>

            <mat-form-field class="hidden-date">
              <input
                matInput
                [matDatepicker]="dueDate"
                (dateChange)="updateDueDate($event)"
              />
              <mat-datepicker #dueDate />
            </mat-form-field>

            <button
              mat-icon-button
              [matMenuTriggerFor]="completedMenu"
              matTooltip="Change Status"
              matTooltipShowDelay="500"
            >
              <mat-icon>more</mat-icon>
            </button>

            <mat-menu #completedMenu="matMenu">
              <button mat-menu-item (click)="updateCompleted(true)">
                Mark as Complete
              </button>
              <button mat-menu-item (click)="updateCompleted(false)">
                Mark as Incomplete
              </button>
            </mat-menu>
          </div>
        </ng-container>
      </div>
    </mat-toolbar>

    <pr-empty-state
      *ngIf="emptySearch$ | async"
      image="search"
      title="search results"
      [searchInputValue]="search.value"
    />

    <pr-empty-state
      *ngIf="emptyState$ | async"
      image="task"
      [title]="(taskFilter.status$ | async) + ' tasks'"
    />

    <div class="flex h-full flex-col">
      <pr-task-list-display
        [tasks]="tasks$ | async"
        [selectedItem]="selectedItem"
        [selectionList]="selectionList"
        (selectedItemChange)="openTask($event)"
        (taskUpdated)="updateTask()"
      >
        <mat-paginator
          #paginator
          class="sticky bottom-0 z-50"
          [length]="recordCount$ | ngrxPush"
          [pageSize]="paginatedTable.pageSize$ | ngrxPush"
          [pageSizeOptions]="paginatedTable.dataTable.pageSizeOptions"
          (page)="paginatedTable.updatePage($event)"
          showFirstLastButtons
        />
      </pr-task-list-display>
    </div>
  </div>
</div>
