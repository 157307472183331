<mat-toolbar color="accent">Edit Account Credit</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field fxFlex>
      <mat-label>Description</mat-label>
      <input matInput formControlName="description" />
    </mat-form-field>

    <ng-container *ngIf="data.practice">
      <div formGroupName="reservedFor">
        <pr-staff-selector
          formControlName="practitioner"
          [brand]="data.brand"
          [practice]="data.practice"
          label="Reserve for Practitioner"
          [permissions]="receiveDepositsPermissions"
          [showNone]="true"
        />

        <mat-form-field class="!flex grow" floatLabel="always">
          <mat-label>Treatment Category</mat-label>
          <mat-select
            formControlName="treatmentCategory"
            [compareWith]="treatmentCategoryBloc.compareFn"
            placeholder="None"
          >
            <mat-select-trigger>
              @if (
                treatmentCategoryBloc.selected$ | async;
                as treatmentCategory
              ) {
                {{ treatmentCategory.name }}
                <div
                  class="inline-block h-3 w-3 rounded-full"
                  [style.backgroundColor]="treatmentCategory.colour.value"
                ></div>
              }
            </mat-select-trigger>
            <mat-option>None</mat-option>
            @for (
              treatmentCategory of treatmentCategoryBloc.options$ | async;
              track treatmentCategory.ref.path
            ) {
              <mat-option [value]="treatmentCategory.ref">
                {{ treatmentCategory.name }}
                <div
                  class="inline-block h-3 w-3 rounded-full"
                  [style.backgroundColor]="treatmentCategory.colour.value"
                ></div>
              </mat-option>
            }
          </mat-select>
          <button
            type="button"
            matIconSuffix
            mat-icon-button
            [disabled]="treatmentCategoryBloc.resetDisabled$ | async"
            (click)="treatmentCategoryBloc.clear($event)"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </ng-container>

    <mat-form-field fxFlex *prHasPermission="[accountAdminPermission]">
      <mat-label>Date Created</mat-label>
      <input
        matInput
        formControlName="createdAt"
        [matDatepicker]="createdAtPicker"
        [min]="depositPaidDate$ | async"
        [max]="today"
        (focus)="createdAtPicker.open()"
        (click)="createdAtPicker.open()"
      />
      @if (depositPaidDate$ | async; as depositPaidDate) {
        <mat-hint>
          Deposit paid at
          {{ depositPaidDate | moment | amDateFormat: dateFormat }}
        </mat-hint>
      }
      <mat-datepicker-toggle matIconSuffix [for]="createdAtPicker" />
      <mat-datepicker #createdAtPicker />
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" [disabled]="isDisabled$ | async">
        Save
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
