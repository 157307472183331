import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  inject,
} from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
  validFormGroupChanges$,
} from '@principle-theorem/ng-shared';
import {
  CustomFormDropdownElement,
  ICustomFormDropdownOptions,
} from '@principle-theorem/principle-core/interfaces';
import { debounceUserInput, firstValueFrom } from '@principle-theorem/shared';
import { ReplaySubject, Subject } from 'rxjs';
import { distinctUntilKeyChanged, takeUntil } from 'rxjs/operators';
import { FormBuilderService } from '../../form-builder.service';

@Component({
    selector: 'pr-form-builder-dropdown-options',
    templateUrl: './form-builder-dropdown-options.component.html',
    styleUrl: './form-builder-dropdown-options.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, ReactiveFormsModule, NgMaterialModule]
})
export class FormBuilderDropdownOptionsComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _formBuilder = inject(FormBuilderService);
  element$ = new ReplaySubject<CustomFormDropdownElement>(1);
  form = new TypedFormGroup<ICustomFormDropdownOptions>({
    dataKey: new TypedFormControl<string>('', [Validators.required]),
    label: new TypedFormControl<string>('', [Validators.required]),
    required: new TypedFormControl<boolean>(false, []),
    options: new TypedFormArray<string>([
      new TypedFormControl<string>('Nancy', Validators.required),
      new TypedFormControl<string>('Drew', Validators.required),
    ]),
  });

  @ViewChild('autoFocusElement', { static: true })
  autoFocusElementRef: ElementRef<HTMLElement>;

  @Input()
  set element(element: CustomFormDropdownElement) {
    this.element$.next(element);
  }

  get optionsCtrl(): TypedFormArray<string> {
    return this.form.controls.options as TypedFormArray<string>;
  }

  constructor() {
    this.element$
      .pipe(distinctUntilKeyChanged('uid'), takeUntil(this._onDestroy$))
      .subscribe((element) => {
        this._patchForm(element);
        this._focus();
      });
    validFormGroupChanges$(this.form)
      .pipe(debounceUserInput(), takeUntil(this._onDestroy$))
      .subscribe((options) => void this._updateOptions(options));
  }

  ngOnDestroy(): void {
    if (this.form.valid) {
      void this._updateOptions(this.form.value);
    }
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  addOption(value: string = '', emitEvent?: boolean): void {
    this.optionsCtrl.push(
      new TypedFormControl<string>(value, [Validators.required]),
      { emitEvent }
    );
  }

  deleteOption(index: number, emitEvent?: boolean): void {
    this.optionsCtrl.removeAt(index, { emitEvent });
  }

  private _patchForm(element: CustomFormDropdownElement): void {
    const emitEvent = false;
    this.optionsCtrl.clear({ emitEvent });
    element.options.options.map((option) => this.addOption(option, emitEvent));
    this.form.patchValue(element.options, { emitEvent });
  }

  private async _updateOptions(
    options: ICustomFormDropdownOptions
  ): Promise<void> {
    const element = await firstValueFrom(this.element$);
    if (element) {
      this._formBuilder.updateElement({ ...element, options });
    }
  }

  private _focus(): void {
    this.autoFocusElementRef?.nativeElement.focus();
  }
}
