import { rand, randBoolean } from '@ngneat/falso';
import {
  IAppointmentSuggestion,
  IEventTimePeriod,
  ISchedulingConflict,
  ParticipantType,
} from '@principle-theorem/principle-core/interfaces';
import { toTimestamp } from '@principle-theorem/shared';
import { Event } from '../event/event';
import { AppointmentSuggestion } from './appointment-suggestion';

interface IMockFromEventTimePeriodData extends IEventTimePeriod {
  score?: number;
  schedulingConflicts?: ISchedulingConflict[];
}

export function MockFromEventTimePeriod(
  data: IMockFromEventTimePeriodData
): IAppointmentSuggestion {
  const suggestion = AppointmentSuggestion.init({
    practitioner: data.staffer,
    event: Event.init({
      from: toTimestamp(data.from),
      to: toTimestamp(data.to),
      practice: data.practice,
      organiser: data.staffer,
      participants: [
        {
          ...data.staffer,
          type: ParticipantType.Staffer,
        },
      ],
    }),
    score: data.score || 0,
    schedulingConflicts: data.schedulingConflicts || [],
  });

  if (!data.schedulingConflicts && randBoolean()) {
    suggestion.schedulingConflicts.push(rand(SCHEDULING_CONFLICTS));
  }

  return suggestion;
}

export const SCHEDULING_CONFLICTS: ISchedulingConflict[] = [
  {
    reason: 'Duration is 30 mins too short',
    blocking: false,
  },
  {
    reason: 'Booking will leave a 15 minute gap',
    blocking: false,
  },
  {
    reason: 'Productivity is too low',
    blocking: false,
  },
  {
    reason: 'Trios scanner not available',
    blocking: true,
  },
  {
    reason: 'Cerec machine also in use',
    blocking: false,
  },
  {
    reason: 'Denitist not available for hygiene consultation',
    blocking: false,
  },
];
