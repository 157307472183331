import { type CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import {
  TrackByFunctions,
  TypedFormControl,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import { type IStaffer } from '@principle-theorem/principle-core/interfaces';
import { isSameRef, snapshot, type WithRef } from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'pr-multi-staff-selector',
  templateUrl: './multi-staff-selector.component.html',
  styleUrls: ['./multi-staff-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiStaffSelectorComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByStaffer = TrackByFunctions.ref<WithRef<IStaffer>>();
  staff$ = new ReplaySubject<WithRef<IStaffer>[]>(1);
  selectedStaff$ = new ReplaySubject<WithRef<IStaffer>[]>(1);
  staffList$ = new ReplaySubject<MatSelectionList>(1);
  allSelected$: Observable<boolean>;
  showRosteredOffCtrl = new TypedFormControl<boolean>();
  @Output() selectionChange = new EventEmitter<WithRef<IStaffer>[]>();
  @Output() orderChange = new EventEmitter<WithRef<IStaffer>[]>();
  @Output() toggleRosteredOffStaff = new EventEmitter<boolean>();

  @Input()
  set staff(staff: WithRef<IStaffer>[]) {
    if (staff) {
      this.staff$.next(staff);
    }
  }

  @Input()
  set selectedStaff(selectedStaff: WithRef<IStaffer>[]) {
    if (selectedStaff) {
      this.selectedStaff$.next(selectedStaff);
    }
  }

  @Input()
  set showRosteredOffStaff(showRosteredOffStaff: BooleanInput) {
    this.showRosteredOffCtrl.setValue(
      coerceBooleanProperty(showRosteredOffStaff),
      { emitEvent: false }
    );
  }

  @ViewChild('staffList', { read: MatSelectionList })
  set staffList(staffList: MatSelectionList) {
    if (staffList) {
      this.staffList$.next(staffList);
    }
  }

  constructor() {
    this.allSelected$ = combineLatest([this.staff$, this.selectedStaff$]).pipe(
      map(([staff, selectedStaff]) => staff.length === selectedStaff.length)
    );

    formControlChanges$(this.showRosteredOffCtrl)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((showRosteredOffStaff) =>
        this.toggleRosteredOffStaff.emit(showRosteredOffStaff)
      );

    this.staffList$
      .pipe(
        switchMap((staffList) =>
          staffList.selectionChange.pipe(map(() => staffList))
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((staffList) => {
        if (staffList.selectedOptions.isEmpty()) {
          staffList.selectAll();
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async determineSelectedStaff(): Promise<void> {
    const staffList = await snapshot(this.staffList$);
    const selected = staffList.selectedOptions.selected.map(
      (option) => option.value as WithRef<IStaffer>
    );
    this.selectionChange.emit(selected);
  }

  async toggleAllStaff(selected: boolean): Promise<void> {
    const staffList = await snapshot(this.staffList$);
    selected ? staffList.selectAll() : staffList.deselectAll();
    await this.determineSelectedStaff();
  }

  compareStaff(a: WithRef<IStaffer>, b: WithRef<IStaffer>): boolean {
    return isSameRef(a, b);
  }

  async clearStaffer(removedStaffer: WithRef<IStaffer>): Promise<void> {
    const staff = await snapshot(this.selectedStaff$);
    this.selectionChange.emit(
      staff.filter((staffer) => !isSameRef(staffer, removedStaffer))
    );
  }

  isSelected$(staffer: WithRef<IStaffer>): Observable<boolean> {
    return this.selectedStaff$.pipe(
      map((selected) =>
        selected.some((selectedStaffer) => isSameRef(selectedStaffer, staffer))
      )
    );
  }

  async drop(event: CdkDragDrop<WithRef<IStaffer>[]>): Promise<void> {
    const staff = await snapshot(this.staff$);
    moveItemInArray(staff, event.previousIndex, event.currentIndex);
    this.staff$.next(staff);
    this.orderChange.emit(staff);
  }
}
