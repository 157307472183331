import { type Group, type NaturallyOrderedValue } from 'crossfilter2';
import { flatten, mapValues, sum, uniq, values } from 'lodash';

export interface IGroupedD3ChartMetadata {
  groupBy: NaturallyOrderedValue;
  total: number;
}

export type GroupedD3ChartData<T> = { [P in keyof T]: number } & {
  metadata: IGroupedD3ChartMetadata;
};

export type CrossfilterGroupMap<T = unknown> = Record<
  string,
  Group<T, NaturallyOrderedValue, number>
>;

// TODO: Handle dimension lables not being valid property names?
export function formatCrossfilter<T extends CrossfilterGroupMap>(
  groupMap: T
): GroupedD3ChartData<T>[] {
  const groupValues = mapValues(groupMap, (group) => group.all());
  const groupByValues = uniq(
    flatten(values(groupValues)).map((item) => item.key)
  );

  return groupByValues.map((groupByValue) => {
    const resultMap = buildResultMap(groupByValue, groupMap);
    const metadata = buildMetadata(groupByValue, resultMap);
    return { ...resultMap, metadata };
  });
}

function buildResultMap<T extends CrossfilterGroupMap>(
  groupByValue: NaturallyOrderedValue,
  groupsMap: T
): { [P in keyof T]: number } {
  return mapValues(
    groupsMap,
    (group) => group.all().find((item) => item.key === groupByValue)?.value ?? 0
  );
}

function buildMetadata(
  groupByValue: NaturallyOrderedValue,
  resultMap: Record<string, number>
): IGroupedD3ChartMetadata {
  return {
    groupBy: groupByValue,
    total: sum(values(resultMap)),
  };
}
