import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { type RawInlineNodes, toTextContent } from '@principle-theorem/editor';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IAutomatedNotification,
  type IAutomation,
  type IGeneratedTask,
  isAutomatedNotification,
} from '@principle-theorem/principle-core/interfaces';
import { DATE_TIME_FORMAT } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-appointment-automations-summary-sidebar',
    templateUrl: './appointment-automations-summary-sidebar.component.html',
    styleUrls: ['./appointment-automations-summary-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentAutomationsSummarySidebarComponent {
  trackByAutomation = TrackByFunctions.combine<
    IAutomation<IAutomatedNotification | IGeneratedTask>
  >('triggerDate', 'type');
  automations$: ReplaySubject<
    IAutomation<IAutomatedNotification | IGeneratedTask>[]
  > = new ReplaySubject(1);
  readonly dateFormat = DATE_TIME_FORMAT;
  @Output() openAutomations = new EventEmitter<void>();

  @Input()
  set automations(
    automations: IAutomation<IAutomatedNotification | IGeneratedTask>[]
  ) {
    if (automations) {
      this.automations$.next(automations);
    }
  }

  getTitle(
    automation: IAutomation<IAutomatedNotification | IGeneratedTask>
  ): RawInlineNodes {
    return isAutomatedNotification(automation.data)
      ? [toTextContent(automation.data.name)]
      : automation.data.title;
  }
}
