import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import { PatientsFacade } from '@principle-theorem/ng-patient';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IPractice,
  type IRelativeSchedulingRules,
  isEventable,
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  DEFAULT_INCREMENT,
  filterUndefined,
  getDoc,
  isSameRef,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { TreatmentStepsEditorService } from '../treatment-steps-editor.service';

export interface IEditTreatmentStepDialogData {
  treatmentPlan: WithRef<ITreatmentPlan>;
  treatmentStep: WithRef<ITreatmentStep>;
  appointment?: WithRef<IAppointment>;
}

type IStepFormData = Pick<ITreatmentStep, 'name' | 'schedulingRules'>;

@Component({
    selector: 'pr-edit-treatment-step-dialog',
    templateUrl: './edit-treatment-step-dialog.component.html',
    styleUrls: ['./edit-treatment-step-dialog.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditTreatmentStepDialogComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  isFirstStep$: Observable<boolean>;
  canSchedule$: Observable<boolean>;
  canDisable$: Observable<boolean>;
  form = new TypedFormGroup<IStepFormData>({
    name: new TypedFormControl('', Validators.required),
    schedulingRules: new TypedFormGroup<IRelativeSchedulingRules>({
      duration: new TypedFormControl<number>(undefined, [
        Validators.min(DEFAULT_INCREMENT),
      ]),
      minDays: new TypedFormControl(),
      maxDays: new TypedFormControl(),
    }),
  });
  canSetSchedulingRequirements = false;
  data$: ReplaySubject<IEditTreatmentStepDialogData> = new ReplaySubject(1);
  practice$: Observable<WithRef<IPractice>>;
  stepDuration$: Observable<number>;

  get maxDaysLowerLimit(): number {
    return this.form.value.schedulingRules.minDays
      ? this.form.value.schedulingRules.minDays
      : 0;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data: IEditTreatmentStepDialogData,
    private _dialogRef: MatDialogRef<
      EditTreatmentStepDialogComponent,
      ITreatmentStep | undefined
    >,
    private _editorService: TreatmentStepsEditorService,
    private _chartFacade: ChartFacade,
    private _patientFacade: PatientsFacade,
    private _currentScope: CurrentScopeFacade
  ) {
    this.data$.next(data);

    this.practice$ = this._currentScope.currentPractice$.pipe(
      filterUndefined()
    );

    this.isFirstStep$ = this.data$.pipe(
      map((dialogData) =>
        isSameRef(dialogData.treatmentPlan.steps[0], dialogData.treatmentStep)
          ? true
          : false
      )
    );

    this.canSchedule$ = combineLatest([this.data$, this.isFirstStep$]).pipe(
      switchMap(([dialogData, isFirstStep]) =>
        this.setCanSchedule(dialogData, isFirstStep)
      )
    );

    this.canDisable$ = combineLatest([this.data$, this.canSchedule$]).pipe(
      switchMap(([dialogData, canSchedule]) =>
        this.setCanDisableScheduling(dialogData, canSchedule)
      )
    );

    this.data$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((dialogData) => this._updateFormValues(dialogData));

    this.stepDuration$ = this.data$.pipe(
      map((dialogData) => dialogData.treatmentStep),
      switchMap((step) => TreatmentStep.getDuration$(step))
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async setCanSchedule(
    data: IEditTreatmentStepDialogData,
    isFirstStep: boolean
  ): Promise<boolean> {
    const hasScheduledAppointment: boolean = data.appointment
      ? isEventable(data.appointment)
      : false;
    this.canSetSchedulingRequirements =
      !isFirstStep && !hasScheduledAppointment;

    if (data.treatmentStep.appointment) {
      return false;
    }
    if (isFirstStep) {
      return true;
    }
    const previousStep: WithRef<ITreatmentStep> | undefined =
      await TreatmentPlan.getPreviousStep(
        data.treatmentPlan,
        data.treatmentStep
      );
    if (previousStep && previousStep.appointment) {
      return true;
    }

    return false;
  }

  async setCanDisableScheduling(
    data: IEditTreatmentStepDialogData,
    canSchedule: boolean
  ): Promise<boolean> {
    if (canSchedule) {
      return false;
    }
    const appointment = await snapshot(
      TreatmentStep.appointment$(data.treatmentStep)
    );
    if (appointment && !isEventable(appointment)) {
      return true;
    }

    return false;
  }

  updateStepFromForm(
    data: IEditTreatmentStepDialogData
  ): WithRef<ITreatmentStep> {
    const updated: WithRef<ITreatmentStep> = {
      ...data.treatmentStep,
      ...this.form.value,
    };
    return updated;
  }

  async submit(data: IEditTreatmentStepDialogData): Promise<void> {
    await patchDoc(data.treatmentStep.ref, this.form.value);
    this._dialogRef.close();
  }

  async generateAppointment(data: IEditTreatmentStepDialogData): Promise<void> {
    const updatedStep: WithRef<ITreatmentStep> = this.updateStepFromForm(data);
    const practice = await snapshot(this.practice$);
    const practitioner = await (updatedStep.practitionerRef
      ? getDoc(updatedStep.practitionerRef)
      : snapshot(this._chartFacade.chartingAs$(ChartId.InAppointment)));
    const patient = await snapshot(
      this._patientFacade.currentPatient$.pipe(filterUndefined())
    );
    await this._editorService.generateAppointmentForStep(
      patient,
      practitioner,
      practice,
      data.treatmentPlan,
      updatedStep
    );
    this._dialogRef.close();
  }

  async removeAppointment(data: IEditTreatmentStepDialogData): Promise<void> {
    await this._editorService.removeAppointmentFromStep(data.treatmentStep);
    this._dialogRef.close();
  }

  private _updateFormValues(data: IEditTreatmentStepDialogData): void {
    const name: string =
      data.treatmentStep.name || TreatmentStep.summary(data.treatmentStep);
    this.form.patchValue({ name });
    const schedulingRules: IRelativeSchedulingRules | undefined =
      data.treatmentStep.schedulingRules;
    this.form.controls.schedulingRules.patchValue({
      minDays: schedulingRules.minDays,
      maxDays: schedulingRules.maxDays,
    });
    if (schedulingRules.duration > 0) {
      this.form.controls.schedulingRules.patchValue({
        duration: schedulingRules.duration,
      });
    }
  }
}
