<div
  cdkDropList
  [cdkDropListData]="listData$ | async"
  [cdkDropListDisabled]="disabled"
  [cdkDropListEnterPredicate]="enterIfEnabledPredicateFn"
  [cdkDropListConnectedTo]="treatmentGroupDragDrop.lists$ | async"
  (cdkDropListDropped)="treatmentGroupDragDrop.drop($event)"
>
  <ng-container *ngIf="allTreatments$ | async as allTreatments">
    <ng-container *ngIf="treatmentGroups$ | async as treatmentGroups">
      <div
        *ngIf="treatmentGroups.length"
        class="treatment-group-container flex flex-col gap-2"
      >
        <ng-container
          *ngFor="
            let treatmentGroup of treatmentGroups;
            trackBy: trackByTreatmentGroup
          "
        >
          <pr-treatment-scope
            class="flex-1"
            cdkDrag
            cdkScrollable
            [cdkDragData]="treatmentGroup"
            [cdkDragDisabled]="disabled"
            [treatment]="treatmentGroup"
            [selectedSurfaces]="selectedSurfaces$ | async"
            [expanded]="expanded"
            [disabled]="disabled"
            [compact]="compact"
            [plan]="plan$ | async"
            (treatmentChanged)="updateTreatment(allTreatments, $event)"
            (treatmentDeleted)="treatmentDeleted.emit($event)"
            (updateChartable)="updateChartable.emit($event)"
            (categoryChanged)="categoryChanged.emit($event)"
          >
            <mat-icon
              cdkDragHandle
              [ngClass]="{ disabled: disabled }"
              class="drag-handle"
            >
              drag_handle
            </mat-icon>
          </pr-treatment-scope>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="treatments$ | async as treatments">
      <div *ngIf="!treatments.length" class="is-empty-placeholder mat-caption">
        No Treatments
      </div>
      <div class="flex flex-col">
        <ng-container
          *ngFor="let treatment of treatments; trackBy: trackByTreatment"
        >
          <pr-treatment-scope
            class="treatment-card flex-1"
            cdkDrag
            cdkScrollable
            [cdkDragData]="treatment"
            [cdkDragDisabled]="disabled"
            [treatment]="treatment"
            [selectedSurfaces]="selectedSurfaces$ | async"
            [step]="step$ | async"
            [plan]="plan$ | async"
            [expanded]="expanded"
            [disabled]="disabled"
            [compact]="compact"
            (treatmentChanged)="updateTreatment(allTreatments, $event)"
            (treatmentDeleted)="treatmentDeleted.emit($event)"
            (updateChartable)="updateChartable.emit($event)"
            (categoryChanged)="categoryChanged.emit($event)"
          >
            <mat-icon
              cdkDragHandle
              [ngClass]="{ disabled: disabled }"
              class="drag-handle"
            >
              drag_handle
            </mat-icon>
          </pr-treatment-scope>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
