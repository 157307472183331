import { type IXSLXImport } from '@principle-theorem/shared';
import type { Row } from 'exceljs';
import { IPaymentTypeXSLX, PAYMENT_TYPE_HEADERS } from './payment-type-to-xlsx';

export class XSLXToPaymentType implements IXSLXImport<IPaymentTypeXSLX> {
  headers = PAYMENT_TYPE_HEADERS;

  translate(row: Row): IPaymentTypeXSLX {
    return {
      sourceId: row.getCell('sourceId').value?.toString() ?? '',
      sourceName: row.getCell('sourceName').value?.toString() ?? '',
      mapTo: row
        .getCell('mapTo')
        .value?.toString() as IPaymentTypeXSLX['mapTo'],
    };
  }
}
