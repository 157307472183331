import { moveItemInArray, type CdkDragDrop } from '@angular/cdk/drag-drop';
import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { MixedSchema, initVersionedSchema } from '@principle-theorem/editor';
import {
  EditorPresetsService,
  renderVersionedSchema,
} from '@principle-theorem/ng-interactions';
import { ConditionLogic } from '@principle-theorem/principle-core';
import {
  type ConditionLogicId,
  type ITemplateVariant,
} from '@principle-theorem/principle-core/interfaces';
import { v4 as uuid } from 'uuid';

export interface ITemplateEditorFormState {
  availableConditions: ConditionLogicId[];
  variants: ITemplateVariant[];
}

@Injectable()
export class TemplateEditorFormStore extends ComponentStore<ITemplateEditorFormState> {
  private _editorPresetsService = inject(EditorPresetsService);
  readonly variants$ = this.select((store) => store.variants);
  readonly hasVariants$ = this.select((store) => store.variants.length >= 1);
  readonly availableConditions$ = this.select(
    (store) => store.availableConditions
  );

  constructor() {
    super({ availableConditions: [], variants: [] });
  }

  moveVariant(event: CdkDragDrop<ITemplateVariant[]>): void {
    this.patchState((state) => {
      moveItemInArray(state.variants, event.previousIndex, event.currentIndex);
      return state;
    });
  }

  addVariant(overrideContent?: MixedSchema): void {
    const content = overrideContent ?? initVersionedSchema();
    const renderedTemplate = renderVersionedSchema(
      content,
      this._editorPresetsService.defaultToHTMLExtensions()
    );
    const variant: ITemplateVariant = {
      uid: uuid(),
      conditions: ConditionLogic.collection([
        ConditionLogic.and([ConditionLogic.never()]),
      ]),
      content,
      renderedTemplate,
    };
    this.patchState((state) => ({
      variants: [...state.variants, variant],
    }));
  }

  updateVariant(variant: ITemplateVariant): void {
    this.patchState((state) => {
      const variants = state.variants;
      const index = variants.findIndex((item) => item.uid === variant.uid);
      variants[index] = variant;
      return { variants };
    });
  }

  deleteVariant(variant: ITemplateVariant): void {
    this.patchState((state) => {
      const variants = state.variants.filter(
        (item) => item.uid !== variant.uid
      );
      return { variants };
    });
  }
}
