import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  IBookingWindowTiming,
  OnlineBookingTimingUnit,
} from '@principle-theorem/principle-core/interfaces';
import { getEnumValues } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

export class TimingFormGroup extends TypedFormGroup<IBookingWindowTiming> {
  constructor() {
    super({
      amount: new TypedFormControl<number | undefined>(
        undefined,
        Validators.min(1)
      ),
      unit: new TypedFormControl<OnlineBookingTimingUnit | undefined>(
        undefined
      ),
    });
  }
}

@Component({
  selector: 'pr-online-booking-time-restriction-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatTooltipModule,
  ],
  templateUrl: './online-booking-time-restriction-form.component.html',
  styleUrl: './online-booking-time-restriction-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnlineBookingTimeRestrictionFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  timingUnits = getEnumValues(OnlineBookingTimingUnit);
  form = new TimingFormGroup();

  @Input({ required: true }) label: string;
  @Input({ required: true }) hint: string;
  @Output() bookingWindowChange = new EventEmitter<IBookingWindowTiming>();

  @Input()
  set value(value: IBookingWindowTiming) {
    if (value) {
      this.form.setValue(value, { emitEvent: false });
    }
  }

  constructor() {
    this.form.valueChanges
      .pipe(debounceTime(1000), takeUntil(this._onDestroy$))
      .subscribe((value) => {
        if (value.amount && value.unit) {
          this.bookingWindowChange.emit(value);
        }
      });
  }

  clearForm(): void {
    this.form.reset();
    this.bookingWindowChange.emit(undefined);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
