<ng-container *ngIf="clinicalNotes$ | async as clinicalNotes">
  <mat-toolbar color="accent">
    <div class="flex w-full items-center justify-between">
      <span>Clinical Note History</span>
      <div
        *ngrxLet="addNoteDisabled$ as disabled"
        [matTooltip]="disabled ? 'Clinical Note already exists for today' : ''"
      >
        <button
          mat-flat-button
          color="primary"
          [disabled]="disabled"
          (click)="addClinicalNote()"
        >
          Add Note
        </button>
      </div>
    </div>
  </mat-toolbar>

  <mat-dialog-content>
    <div class="notes-container flex-1">
      <pr-clinical-notes-list
        [patient]="data.patient"
        [clinicalNotes]="clinicalNotes"
      />
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="buttons-container">
    <mat-divider />
    <pt-buttons-container padding="none">
      <pr-download-clinical-notes
        [patient]="data.patient"
        [clinicalNotes]="clinicalNotes"
      />
      <button mat-stroked-button mat-dialog-close cdkFocusInitial>Close</button>
    </pt-buttons-container>
  </mat-dialog-actions>
</ng-container>
