import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  type OnDestroy,
  Output,
} from '@angular/core';
import { StafferSettingsStoreService } from '@principle-theorem/ng-principle-shared';
import { SidebarManagerService } from '@principle-theorem/ng-shared';
import { snapshot } from '@principle-theorem/shared';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'pr-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SidebarComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  readonly expandedWidth: string = '275px';
  readonly minimisedWidth: string = '64px';
  @HostBinding('style.width') width: string = this.minimisedWidth;
  width$ = new BehaviorSubject<string>(this.minimisedWidth);
  @Output() widthChange = new EventEmitter<void>();

  constructor(
    public sidebar: SidebarManagerService,
    private _settings: StafferSettingsStoreService
  ) {
    this._settings.display$
      .pipe(withLatestFrom(this.sidebar.isMobile$), takeUntil(this._onDestroy$))
      .subscribe(([display, isMobile]) => {
        if (isMobile) {
          return;
        }
        if (display.sidebarClosed) {
          this.sidebar.minimise();
          return;
        }
        this.sidebar.expand();
      });

    this.sidebar.expanded$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((expanded) => {
        this.width = expanded ? this.expandedWidth : this.minimisedWidth;
        this.width$.next(this.width);
        setTimeout(() => this.widthChange.emit());
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async toggleExpanded(): Promise<void> {
    const settings = await snapshot(this._settings.display$);
    if (settings.sidebarClosed) {
      this._settings.updateStafferSettings({
        display: { sidebarClosed: false },
      });
      return;
    }
    this._settings.updateStafferSettings({ display: { sidebarClosed: true } });
  }
}
