import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  signal,
  type OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  ConfirmDialogComponent,
  DialogPresets,
  IConfirmationDialogInput,
  TrackByFunctions,
  confirmationDialogData,
  type IBreadcrumb,
} from '@principle-theorem/ng-shared';
import { Brand } from '@principle-theorem/principle-core';
import {
  type IBrand,
  type ITreatmentTemplate,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  addDoc,
  filterUndefined,
  isSameRef,
  nameSorter,
  snapshot,
  sortByRefArray,
  type WithRef,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { Subject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  UpsertTreatmentTemplateComponent,
  type ITreatmentTemplateData,
} from './upsert-treatment-template/upsert-treatment-template.component';

@Component({
  selector: 'pr-brand-treatment-templates',
  templateUrl: './brand-treatment-templates.component.html',
  styleUrls: ['./brand-treatment-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandTreatmentTemplatesComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  brand$: Observable<WithRef<IBrand>>;
  trackByTemplate = TrackByFunctions.ref<WithRef<ITreatmentTemplate>>();
  publicTreatments = signal<WithRef<ITreatmentTemplate>[]>([]);
  privateTreatments = signal<WithRef<ITreatmentTemplate>[]>([]);
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(
    currentBrand: CurrentBrandScope,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.brand$ = currentBrand.doc$.pipe(filterUndefined());
    this.breadcrumbs$ = this.brand$.pipe(
      map((brand) => [
        { label: 'Settings', path: '../../../' },
        { label: brand.name },
        { label: 'Treatment Templates' },
      ])
    );
  }

  async ngOnInit(): Promise<void> {
    const brand = await snapshot(this.brand$);
    const templates = await Brand.treatmentTemplates(brand);
    this._setPrivateTemplates(templates);
    this._setPublicTemplates(brand, templates);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async dropPublicTemplate(
    event: CdkDragDrop<WithRef<ITreatmentTemplate>[]>
  ): Promise<void> {
    this.publicTreatments.update((templates) => {
      moveItemInArray(templates, event.previousIndex, event.currentIndex);
      return templates;
    });
    await this.savePublicTemplateOrder();
  }

  async addTemplate(): Promise<void> {
    const template = await this._dialog
      .open<
        UpsertTreatmentTemplateComponent,
        ITreatmentTemplateData,
        ITreatmentTemplate
      >(UpsertTreatmentTemplateComponent, DialogPresets.extraLarge({}))
      .afterClosed()
      .toPromise();

    if (!template) {
      return;
    }

    const brand = await snapshot(this.brand$);
    const ref = await addDoc(Brand.treatmentTemplateCol(brand), template);
    const newTemplate = await Firestore.getDoc(ref);

    await this._addTemplate(newTemplate);
    this._snackBar.open('Treatment Template Added');
  }

  async editTemplate(template: WithRef<ITreatmentTemplate>): Promise<void> {
    const updatedTemplate = await this._dialog
      .open<
        UpsertTreatmentTemplateComponent,
        ITreatmentTemplateData,
        WithRef<ITreatmentTemplate>
      >(
        UpsertTreatmentTemplateComponent,
        DialogPresets.extraLarge({
          data: {
            treatmentTemplate: template,
          },
        })
      )
      .afterClosed()
      .toPromise();

    if (!updatedTemplate) {
      return;
    }

    await Firestore.patchDoc(template.ref, updatedTemplate);
    await this._updateTemplate(template, updatedTemplate);
    this._snackBar.open('Treatment Template Updated');
  }

  async deleteTemplate(template: WithRef<ITreatmentTemplate>): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Treatment Template',
      prompt: 'Are you sure you want to delete this treatment template?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!confirmed) {
      return;
    }

    await Firestore.patchDoc(template.ref, {
      deleted: true,
    });

    await this._removeTemplate(template);
    this._snackBar.open('Treatment Template Deleted');
  }

  async savePublicTemplateOrder(): Promise<void> {
    const templates = this.publicTreatments();
    const treatmentTemplateOrder = templates.map((template) => template.ref);
    const brand = await snapshot(this.brand$);
    await Firestore.saveDoc({
      ...brand,
      settings: {
        ...brand.settings,
        onlineBookings: {
          ...brand.settings.onlineBookings,
          treatmentTemplateOrder,
        },
      },
    });
  }

  private _setPrivateTemplates(templates: WithRef<ITreatmentTemplate>[]): void {
    this.privateTreatments.set(
      sortBy(
        templates.filter((template) => !template.isPublic),
        nameSorter
      )
    );
  }

  private _setPublicTemplates(
    brand: WithRef<IBrand>,
    templates: WithRef<ITreatmentTemplate>[]
  ): void {
    this.publicTreatments.set(
      sortByRefArray(
        templates.filter((template) => template.isPublic),
        brand.settings?.onlineBookings?.treatmentTemplateOrder
      )
    );
  }

  private async _addTemplate(
    template: WithRef<ITreatmentTemplate>
  ): Promise<void> {
    if (template.isPublic) {
      this.publicTreatments.update((templates) => [...templates, template]);
      await this.savePublicTemplateOrder();
      return;
    }
    this.privateTreatments.update((templates) =>
      sortBy([...templates, template], nameSorter)
    );
  }

  private async _removeTemplate(
    template: WithRef<ITreatmentTemplate>
  ): Promise<void> {
    if (template.isPublic) {
      this.publicTreatments.update((templates) =>
        templates.filter((cur) => !isSameRef(cur, template))
      );
      await this.savePublicTemplateOrder();
      return;
    }
    this.privateTreatments.update((templates) =>
      templates.filter((cur) => !isSameRef(cur, template))
    );
  }

  private async _updateTemplate(
    old: WithRef<ITreatmentTemplate>,
    updated: WithRef<ITreatmentTemplate>
  ): Promise<void> {
    if (old.isPublic && updated.isPublic) {
      this.publicTreatments.update((templates) =>
        templates.map((cur) => (isSameRef(cur, old) ? updated : cur))
      );
      return;
    }

    await this._removeTemplate(old);
    await this._addTemplate(updated);
  }
}
