import { Editor } from '@tiptap/core';
import { Fragment } from '@tiptap/pm/model';
import { type Plugin } from '@tiptap/pm/state';
import {
  ExtensionRegisterReturnFn,
  labelPlugin,
} from '../../../core/extension';
import { InlineNodes } from '../../available-extensions';
import { pasteRule } from '../../../core/paste-rule';

/**
 * Matches any valid http/https url
 */
const LINK_REGEX =
  // eslint-disable-next-line no-useless-escape
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-zA-Z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&\/\/=]*)/g;

export function createLinkPasteRulesKeymap(): ExtensionRegisterReturnFn<Plugin> {
  return (editor: Editor) =>
    labelPlugin(
      `${InlineNodes.Link}-paste-rules`,
      pasteRule(
        LINK_REGEX,
        editor.schema.nodes[InlineNodes.Link],
        (href: string) => ({ href }),
        (href: string) => Fragment.from(editor.schema.text(href))
      )
    );
}
