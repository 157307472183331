import { ILabDimension } from '@principle-theorem/reporting/interfaces';
import {
  BigQueryFirestoreModelMock,
  BigQueryInteractionMock,
  BigQueryNoteMock,
} from './common.mock';
import {
  MockGenerator,
  toSerialisedTimestamp,
  toTimestamp,
} from '@principle-theorem/shared';
import { LabMock } from '@principle-theorem/principle-core';

const lab = MockGenerator.generate(LabMock);

export class LabDimensionMock
  extends BigQueryFirestoreModelMock
  implements ILabDimension
{
  timestamp = toSerialisedTimestamp(toTimestamp());
  name = lab.name;
  email = lab.email;
  address = lab.address;
  phone = lab.phone;
  mobileNumber = lab.mobileNumber;
  jobTitle = lab.jobTitle;
  labJobTypes = lab.labJobTypes.map((labJobType) => ({
    ...labJobType,
    ...MockGenerator.generate(BigQueryFirestoreModelMock),
  }));
  notes = [
    MockGenerator.generate(BigQueryNoteMock),
    MockGenerator.generate(BigQueryNoteMock),
  ];
  interactions = [
    MockGenerator.generate(BigQueryInteractionMock),
    MockGenerator.generate(BigQueryInteractionMock),
    MockGenerator.generate(BigQueryInteractionMock),
  ];
}
