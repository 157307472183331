import { Observable, type Subscriber } from 'rxjs';
import {
  type DocumentChange,
  type DocumentChangeType,
  type DocumentReference,
  type Query,
  type DocumentSnapshot,
  type QuerySnapshot,
  onSnapshot,
} from './adaptor';
import { FirestoreScheduler } from './firestore-scheduler';
import { DatabaseUsageTracker } from './usage-tracking';
import { get } from 'lodash';

export interface IDocumentChangeAction<T> {
  type: DocumentChangeType;
  payload: DocumentChange<T>;
}

export interface IAction<T> {
  type: string;
  payload: T;
}

export type UnsubFn = () => void;

export function createScheduledObservable$<T>(
  callback: (sub: Subscriber<T>) => UnsubFn
): Observable<T> {
  const observable$ = new Observable<T>((sub) => {
    const unsubscribe = callback(sub);
    if (FirestoreScheduler.scheduler) {
      FirestoreScheduler.scheduler.schedule(() => unsubscribe);
    }
    return () => unsubscribe();
  });
  if (FirestoreScheduler.stabiliser$) {
    return observable$.pipe(FirestoreScheduler.stabiliser$);
  }
  return observable$;
}

export function fromDocRef<T>(
  ref: DocumentReference<T>
): Observable<IAction<DocumentSnapshot<T>>> {
  return createScheduledObservable$((subscriber) => {
    return onSnapshot(ref, {
      // eslint-disable-next-line no-console
      error: (error) => console.error('fromDocRef failed', ref.path, error),
      next: (payload) => {
        DatabaseUsageTracker.track(
          ref.parent.path,
          'snapshotListener',
          'document'
        );
        return subscriber.next({
          payload,
          type: 'value',
        });
      },
    });
  });
}

export function fromCollectionRef<T>(
  ref: Query<T>
): Observable<IAction<QuerySnapshot<T>>> {
  return createScheduledObservable$((subscriber) => {
    return onSnapshot(ref, {
      error: (error) => {
        const path: unknown = get(ref, '_query.path.segments');
        // eslint-disable-next-line no-console
        console.error('fromCollectionRef failed', path, error);
      },
      next: (payload) => {
        if (payload.docs[0]) {
          DatabaseUsageTracker.track(
            payload.docs[0].ref.parent.path,
            'snapshotListener',
            'collection',
            payload.docChanges().length
          );
        }
        return subscriber.next({
          payload,
          type: 'query',
        });
      },
    });
  });
}
