import { first } from 'lodash';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { isArray } from '../common';
import { type Timestamp } from '../firebase/firestore/adaptor';
import {
  ISO_DATE_TIME_FORMAT,
  ISO_TIME_FORMAT,
  TIME_FORMAT_24HR,
} from './date-time-formatting';
import {
  HOUR_DURATION,
  type Time24hrType,
  toISODate,
  toMoment,
  toTimeString,
} from './time';
import { TimeBucket } from './time-bucket/time-bucket';
import {
  type ITimePeriod,
  type ITimestampRange,
} from './time-bucket/interfaces';
import { SYSTEM_TIMEZONE, type Timezone } from './timezone';

export class MomentRange {
  static init(from?: moment.Moment, to?: moment.Moment): ITimePeriod {
    return {
      from: from || moment().startOf('day'),
      to: to || moment().endOf('day'),
    };
  }

  static partitionBy(
    range: ITimePeriod,
    divideBy: moment.unitOfTime.DurationConstructor
  ): Moment[] {
    return overlappingChunkRange(range, 1, divideBy).map(
      (chunkedRange) => chunkedRange.from
    );
  }

  static isSameUnit(
    range: ITimePeriod,
    unit: moment.unitOfTime.StartOf
  ): boolean {
    return toMoment(range.from).isSame(range.to, unit);
  }

  static isSameDay(range: ITimePeriod): boolean {
    return MomentRange.isSameUnit(range, 'day');
  }
}

export function isSameRange(rangeA: ITimePeriod, rangeB: ITimePeriod): boolean {
  return rangeA.from.isSame(rangeB.from) && rangeA.to.isSame(rangeB.to);
}

export function toMomentRange(
  from: Timestamp | Date | Moment,
  to: Timestamp | Date | Moment
): ITimePeriod {
  return {
    from: toMoment(from),
    to: toMoment(to),
  };
}

export function getDayRange(day?: Moment): ITimePeriod {
  if (!day) {
    day = moment();
  }
  return {
    from: day.clone().startOf('day'),
    to: day.clone().endOf('day'),
  };
}

export function getWeekRange(day?: Moment): ITimePeriod {
  if (!day) {
    day = moment();
  }
  return {
    from: day.clone().day(0).startOf('day'),
    to: day.clone().day(6).endOf('day'),
  };
}

export function getMonthRange(day?: Moment): ITimePeriod {
  if (!day) {
    day = moment();
  }
  const from = day.clone().date(1).startOf('day');
  const daysInMonth = from.daysInMonth();
  const to = from
    .clone()
    .add(daysInMonth - 1, 'days')
    .endOf('day');

  return {
    from,
    to,
  };
}

export function buildMomentsRange(
  range: ITimePeriod,
  duration: moment.Duration
): moment.Moment[] {
  const times: moment.Moment[] = [];
  const start = moment(range.from);

  while (start.isBefore(range.to)) {
    times.push(start.clone());
    start.add(duration);
  }
  return times;
}

export function addToRange(
  range: ITimePeriod,
  duration: moment.Duration
): ITimePeriod {
  if (duration.asMonths() >= 1) {
    const from = range.from.clone().add(duration);
    const daysInMonth = from.daysInMonth();
    return {
      from,
      to: range.to
        .clone()
        .add(daysInMonth - 1, 'days')
        .endOf('day'),
    };
  }

  return {
    from: range.from.clone().add(duration),
    to: range.to.clone().add(duration),
  };
}

export function subtractFromRange(
  range: ITimePeriod,
  duration: moment.Duration
): ITimePeriod {
  return {
    from: range.from.clone().subtract(duration),
    to: range.to.clone().subtract(duration),
  };
}

export function rangeFromEquals(
  rangeA: ITimePeriod,
  rangeB: ITimePeriod
): boolean {
  return rangeA.from.isSame(rangeB.from);
}

export function rangeToEquals(
  rangeA: ITimePeriod,
  rangeB: ITimePeriod
): boolean {
  return rangeA.to.isSame(rangeB.to);
}

export function rangesEqual(rangeA: ITimePeriod, rangeB: ITimePeriod): boolean {
  return rangeFromEquals(rangeA, rangeB) && rangeToEquals(rangeA, rangeB);
}

export function getRangeDuration(
  range: ITimePeriod,
  unit?: moment.unitOfTime.DurationConstructor,
  precise: boolean = false
): moment.Duration {
  const diff = range.to.diff(range.from, unit, precise);
  return moment.duration(diff, unit);
}

export function rangeMaxDuration(
  range: ITimePeriod,
  max: moment.Duration
): boolean {
  const diff = range.to.diff(range.from, 'minutes', true);
  return max.asMinutes() >= diff;
}

export function rangeMinDuration(
  range: ITimePeriod,
  min: moment.Duration
): boolean {
  const diff = range.to.diff(range.from, 'minutes', true);
  return min.asMinutes() <= diff;
}

/**
 * @deprecated
 * Use chunkRange instead.
 * This causes chunks to share the same millisecond. The end of the previous
 * chunk is the start of the next chunk.
 */
export function overlappingChunkRange(
  range: ITimePeriod,
  amount: number,
  unit: moment.unitOfTime.Diff
): ITimePeriod[] {
  let fromDate = range.from;
  const dateRanges: ITimePeriod[] = [];

  while (fromDate.isSameOrBefore(range.to)) {
    if (Math.abs(fromDate.diff(range.to, unit)) < amount) {
      dateRanges.push({ from: moment(fromDate), to: moment(range.to) });
      break;
    }
    dateRanges.push({
      from: moment(fromDate),
      to: moment(fromDate).add(amount, unit),
    });
    fromDate = moment(fromDate).add(amount, unit);
  }
  return dateRanges;
}

export function chunkRange(
  range: ITimePeriod,
  amount: number,
  unit: moment.unitOfTime.Diff
): ITimePeriod[] {
  let fromDate = range.from;
  const dateRanges: ITimePeriod[] = [];

  while (fromDate.isSameOrBefore(range.to)) {
    const remainingUnits = Math.abs(fromDate.diff(range.to, unit));
    if (remainingUnits < amount) {
      dateRanges.push({ from: moment(fromDate), to: moment(range.to) });
      break;
    }
    const nextFromDate = moment(fromDate).add(amount, unit);
    dateRanges.push({
      from: moment(fromDate),
      to: moment(nextFromDate).subtract(1, 'milliseconds'),
    });
    fromDate = nextFromDate;
  }
  return dateRanges;
}

export function mergeDayAndTime(
  day: moment.Moment,
  time: moment.Moment | Time24hrType,
  timezone?: Timezone
): moment.Moment {
  return moment.tz(
    `${toISODate(day)} ${toTimeString(time, ISO_TIME_FORMAT)}`,
    ISO_DATE_TIME_FORMAT,
    timezone ?? day.tz() ?? SYSTEM_TIMEZONE
  );
}

export function isInRange(
  range: ITimePeriod,
  dateTime: moment.Moment
): boolean {
  return (
    dateTime.isSameOrAfter(range.from) && dateTime.isSameOrBefore(range.to)
  );
}

export function trimPeriodToDay(
  periodToTrim: ITimePeriod,
  day: Moment,
  dayTimes: ITimePeriod
): ITimePeriod | undefined {
  const dayRange = {
    from: mergeDayAndTime(day, dayTimes.from),
    to: mergeDayAndTime(day, dayTimes.to),
  };
  const trimmed = new TimeBucket([periodToTrim])
    .trim(dayRange.from, dayRange.to)
    .get();
  return first(trimmed);
}

export function getHoursInRange(
  range: ITimePeriod,
  format: string = TIME_FORMAT_24HR
): string[] {
  return buildMomentsRange(range, HOUR_DURATION).map((time) =>
    time.format(format)
  );
}

export function getHoursInDay(): string[] {
  return getHoursInRange(getDayRange());
}

export function logTimePeriod(
  label: string,
  timePeriod: ITimePeriod | ITimePeriod[] | ITimestampRange | ITimestampRange[]
): void {
  // eslint-disable-next-line no-console
  console.log(label, serialiseTimePeriod(timePeriod));
}

export function serialiseTimePeriod(
  timePeriod: ITimePeriod | ITimePeriod[] | ITimestampRange | ITimestampRange[]
): object | object[] {
  if (isArray<ITimePeriod | ITimestampRange>(timePeriod)) {
    // eslint-disable-next-line no-console
    return [
      timePeriod.map((currentPeriod) => ({
        from: toMoment(currentPeriod.from).format(),
        to: toMoment(currentPeriod.to).format(),
      })),
    ];
  }

  return {
    from: toMoment(timePeriod.from).format(),
    to: toMoment(timePeriod.to).format(),
  };
}
