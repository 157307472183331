import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import { IBreadcrumb } from '@principle-theorem/ng-shared';
import { SchedulingEventReason } from '@principle-theorem/principle-core';
import {
  ISchedulingEventReason,
  SchedulingEventType,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  WithRef,
  filterUndefined,
  multiFilter,
  titlecase,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SchedulingEventReasonStore } from './cancellation-reasons.store';
import { SchedulingEventReasonService } from './scheduling-event-reason.service';

@Component({
    selector: 'pr-cancellation-reasons',
    templateUrl: './cancellation-reasons.component.html',
    styleUrls: ['./cancellation-reasons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CancellationReasonsComponent {
  activeCancellationReasons$: Observable<WithRef<ISchedulingEventReason>[]>;
  deletedCancellationReasons$: Observable<WithRef<ISchedulingEventReason>[]>;
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(
    public store: SchedulingEventReasonStore,
    public schedulingReason: SchedulingEventReasonService,
    private _brandScope: CurrentBrandScope
  ) {
    this.store.loadBrand(this._brandScope.doc$.pipe(filterUndefined()));
    this.breadcrumbs$ = this.store.brand$.pipe(
      map((brand) => [
        { label: 'Settings', path: '../../../' },
        { label: brand.name },
        { label: 'Rescheduling & Cancellation Reasons' },
      ])
    );
    this.activeCancellationReasons$ = this.store.reasons$.pipe(
      multiFilter((cancellationReason) => !cancellationReason.deleted)
    );
    this.deletedCancellationReasons$ = this.store.reasons$.pipe(
      multiFilter((cancellationReason) => cancellationReason.deleted)
    );
  }

  eventTypeDisplay(eventTypes: SchedulingEventType[] = []): string {
    return eventTypes.map((eventType) => titlecase(eventType)).join(', ');
  }

  conditionsDisplay(reason: ISchedulingEventReason): string {
    return SchedulingEventReason.conditionsDisplay(reason);
  }

  async drop(
    event: CdkDragDrop<WithRef<ISchedulingEventReason>[]>
  ): Promise<void> {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
    const defaultSchedulingEventReasonOrder = event.container.data.map(
      (reason) => reason.ref
    );
    const brand = await this._brandScope.toPromise();
    await Firestore.patchDoc(brand.ref, { defaultSchedulingEventReasonOrder });
  }
}
