<div fxLayout="row">
  <mat-table fxFlex [dataSource]="dataSource" class="mat-elevation-z1" matSort>
    <ng-container
      [matColumnDef]="column"
      *ngFor="let column of headers; trackBy: trackByHeader"
    >
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{
        column
      }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element[column] | metric }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="headers" />
    <mat-row *matRowDef="let row; columns: headers" />
  </mat-table>
</div>
