<mat-toolbar color="accent">
  <span fxFlex>Manage Appointment Automations</span>
</mat-toolbar>

<mat-dialog-content>
  <pr-appointment-automations
    fxFlex
    [treatmentStep]="treatmentStep$ | async"
   />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <pt-loader-button type="flat" color="primary" (click)="save()"
      >Save</pt-loader-button
    >
  </pt-buttons-container>
</mat-dialog-actions>
