import {
  IChartedItemConfiguration,
  IPricedServiceCodeEntry,
  IPricedServiceCodeGroup,
  IServiceCodeEntry,
  IServiceCodeGroup,
  ServiceCode,
  ServiceCodeGroupType,
} from '@principle-theorem/principle-core/interfaces';
import { asyncForEach, toInt, WithRef } from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';
import { FeeScheduleManager } from '../fees/fee-schedule/fee-schedule-manager';
import { ChartedServiceExclusiveGroup } from './charted-service-exclusive-group';
import { PricedServiceCodeEntry } from './service-code';

export interface IADACodeRange {
  min: number;
  max: number;
}

export class ADACodeCategory {
  constructor(
    public title: string,
    private _ranges: IADACodeRange[],
    public colour: string
  ) {}

  isInGroup(code: ServiceCode): boolean {
    return this._ranges.some(
      (range) => toInt(code) >= range.min && toInt(code) <= range.max
    );
  }
}

export const TYRO_TEST_ADA_CODE_CATEGORY = new ADACodeCategory(
  'Tyro Test',
  [
    { min: 23, max: 24 },
    { min: 5000, max: 5001 },
    { min: 20200, max: 20201 },
  ],
  '#000000'
);

export const ADA_CODE_CATEGORIES: ADACodeCategory[] = [
  new ADACodeCategory(
    'Diagnostic Services',
    [
      { min: 0, max: 22 },
      { min: 24, max: 99 },
    ],
    '#8abe56'
  ),
  new ADACodeCategory(
    'Preventive, Prophylactic and Bleaching Services',
    [{ min: 100, max: 199 }],
    '#e78940'
  ),
  new ADACodeCategory('Periodontics', [{ min: 200, max: 299 }], '#86348b'),
  new ADACodeCategory('Oral Surgery', [{ min: 300, max: 399 }], '#c93732'),
  new ADACodeCategory('Endodontics', [{ min: 400, max: 499 }], '#599245'),
  new ADACodeCategory(
    'Restorative Services',
    [{ min: 500, max: 599 }],
    '#883637'
  ),
  new ADACodeCategory('Prosthodontics', [{ min: 600, max: 699 }], '#f4bc50'),
  new ADACodeCategory('Orthodontics', [{ min: 700, max: 799 }], '#da412b'),
  new ADACodeCategory('General Services', [{ min: 800, max: 899 }], '#7aaddb'),
  new ADACodeCategory('Miscellaneous', [{ min: 900, max: 999 }], '#c14c49'),
  new ADACodeCategory(
    'Medicare Child Dental Benefits',
    [{ min: 88000, max: 88999 }],
    '#4da6a6'
  ),
];

export class ServiceCodeGroup {
  static init<T extends IServiceCodeEntry = IServiceCodeEntry>(
    overrides?: Partial<IServiceCodeGroup<T>>
  ): IServiceCodeGroup<T> {
    return {
      uid: uuid(),
      type: ServiceCodeGroupType.Optional,
      serviceCodes: [],
      ...overrides,
    };
  }
}

export class PricedServiceCodeGroup {
  static init(
    overrides?: Partial<IPricedServiceCodeGroup>
  ): IPricedServiceCodeGroup {
    return ServiceCodeGroup.init<IPricedServiceCodeEntry>({
      type: ServiceCodeGroupType.Optional,
      serviceCodes: [],
      ...overrides,
    });
  }

  static fromServiceCodeGroup(
    group: IServiceCodeGroup,
    origin: WithRef<IChartedItemConfiguration>
  ): IPricedServiceCodeGroup {
    const serviceCodes = group.serviceCodes.map((code) => {
      const entry = PricedServiceCodeEntry.fromServiceCodeEntry(code, origin);
      return {
        ...entry,
        quantity:
          group.type === ServiceCodeGroupType.Optional ? 0 : entry.quantity,
      };
    });
    return PricedServiceCodeGroup.init({
      ...group,
      serviceCodes,
    });
  }

  static async applyFeeSchedule(
    group: IPricedServiceCodeGroup,
    stepServiceCodes: IPricedServiceCodeEntry[],
    manager: FeeScheduleManager,
    convertServiceCodes: boolean,
    changingFeeSchedule: boolean
  ): Promise<IPricedServiceCodeGroup> {
    if (group.type === ServiceCodeGroupType.Exclusive) {
      return ChartedServiceExclusiveGroup.applyFeeSchedule(
        group,
        stepServiceCodes,
        manager,
        convertServiceCodes,
        changingFeeSchedule
      );
    }
    const serviceCodes = await asyncForEach(group.serviceCodes, (code) =>
      PricedServiceCodeEntry.applyFeeSchedule(
        code,
        stepServiceCodes,
        manager,
        convertServiceCodes,
        changingFeeSchedule
      )
    );
    return {
      ...group,
      serviceCodes,
    };
  }
}
