import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ChartedItem } from '@principle-theorem/principle-core';
import {
  type IChartedCondition,
  type IChartedRef,
} from '@principle-theorem/principle-core/interfaces';
import { type Observable, ReplaySubject } from 'rxjs';
import { type IEditChartableData } from '../../../chartable-surface-updater';

@Component({
    selector: 'pr-charted-condition-summary',
    templateUrl: './charted-condition-summary.component.html',
    styleUrls: ['./charted-condition-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChartedConditionSummaryComponent {
  private _selectedSurfaces$ = new ReplaySubject<Partial<IChartedRef>[]>(1);
  condition$ = new ReplaySubject<IChartedCondition>(1);
  @Output()
  conditionChange = new EventEmitter<IChartedCondition>();
  @Output()
  conditionDelete = new EventEmitter<IChartedCondition>();
  @Output()
  updateConditionSurfaces = new EventEmitter<IEditChartableData>();
  @Input() disabled: boolean = false;
  hasSelectedSurface$: Observable<boolean>;

  constructor() {
    this.hasSelectedSurface$ = ChartedItem.hasSelectedSurface$(
      this.condition$,
      this._selectedSurfaces$
    );
  }

  @Input()
  set condition(condition: IChartedCondition) {
    if (condition) {
      this.condition$.next(condition);
    }
  }

  @Input()
  set selectedSurfaces(selectedSurfaces: Partial<IChartedRef>[]) {
    if (selectedSurfaces) {
      this._selectedSurfaces$.next(selectedSurfaces);
    }
  }
}
