import { Action, combineReducers } from '@ngrx/store';
import {
  conditionConfigurationReducer,
  type IConditionConfigurationState,
} from './charted-configurations.reducer';
import {
  type IMultiTreatmentConfigurationState,
  multiTreatmentConfigurationReducer,
} from './multi-treatment-configurations.reducer';
import {
  type ITreatmentConfigurationState,
  treatmentConfigurationReducer,
} from './treatment-configurations.reducer';

export interface IChartableConfigurationsState {
  conditions: IConditionConfigurationState;
  treatments: ITreatmentConfigurationState;
  multiTreatments: IMultiTreatmentConfigurationState;
}

export function chartableConfigurationsReducer(
  state: IChartableConfigurationsState | undefined,
  action: Action
): IChartableConfigurationsState {
  return combineReducers({
    conditions: conditionConfigurationReducer,
    treatments: treatmentConfigurationReducer,
    multiTreatments: multiTreatmentConfigurationReducer,
  })(state, action);
}
