import { type ITransaction } from '@principle-theorem/principle-core/interfaces';
import {
  sortByCreatedAt,
  sortTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { groupBy, last } from 'lodash';

export interface ITransactionDisplay {
  provider: string;
  reference: string;
  latest: WithRef<ITransaction>;
  current: WithRef<ITransaction>;
  transactions: WithRef<ITransaction>[];
}

export function toTransactionDisplays(
  transactions: WithRef<ITransaction>[]
): ITransactionDisplay[] {
  const transactionGroups = groupBy(transactions, (transaction) =>
    getUniqueReference(transaction)
  );
  return transactions
    .map((transaction) => {
      const uniqueReference = getUniqueReference(transaction);
      const relatedTransactions = transactionGroups[uniqueReference];
      return toTransactionDisplay(relatedTransactions, transaction);
    })
    .filter((group): group is ITransactionDisplay => group !== undefined)
    .sort(sortByCurrent)
    .reverse();
}

function getUniqueReference(transaction: ITransaction): string {
  return `${transaction.provider}:${transaction.reference}`;
}

function toTransactionDisplay(
  transactions: WithRef<ITransaction>[],
  current: WithRef<ITransaction>
): ITransactionDisplay | undefined {
  const ordered = transactions.sort(sortByCreatedAt).reverse();
  const latest = last(ordered);
  if (!latest) {
    return;
  }
  return {
    provider: latest.provider,
    reference: latest.reference,
    latest,
    current,
    transactions: ordered,
  };
}

function sortByCurrent(a: ITransactionDisplay, b: ITransactionDisplay): number {
  return sortTimestamp(a.current.createdAt, b.current.createdAt);
}
