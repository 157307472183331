<div fxLayout="column" fxLayoutGap="16px">
  <div class="flex gap-4">
    <div>
      <pr-clinical-chart class="small-text hide-tooth-labels" />
    </div>

    <form [formGroup]="form" class="flex flex-col">
      <mat-checkbox
        *ngFor="let surface of allSurfaces; trackBy: trackBySurface"
        color="primary"
        [formControlName]="surface"
      >
        {{ surface | splitCamel | titlecase }}
      </mat-checkbox>
    </form>
  </div>
  <div *ngIf="isUnscoped$ | async" class="flex items-center gap-2">
    <mat-icon>info</mat-icon>
    <span>
      When charted, this item will be 'unscoped' and will not be associated with
      any surface
    </span>
  </div>
</div>
