import { NgModule } from '@angular/core';
import { GeneralConfigurationComponent } from './general-configuration/general-configuration.component';
import { ItemCodeConfigurationComponent } from './item-code-configuration/item-code-configuration.component';
import { TreatmentAssetsComponent } from './treatment-assets/treatment-assets.component';
import { TreatmentAssetLineItemComponent } from './treatment-assets/treatment-asset-line-item/treatment-asset-line-item.component';
import { TreatmentAssetListComponent } from './treatment-assets/treatment-asset-list/treatment-asset-list.component';
import { ServiceGroupsModule } from '../../service-groups/service-groups.module';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';

@NgModule({
  declarations: [
    GeneralConfigurationComponent,
    ItemCodeConfigurationComponent,
    TreatmentAssetsComponent,
    TreatmentAssetLineItemComponent,
    TreatmentAssetListComponent,
  ],
  imports: [NgPrincipleSharedModule, ServiceGroupsModule, NgSharedModule],
  exports: [
    GeneralConfigurationComponent,
    ItemCodeConfigurationComponent,
    TreatmentAssetsComponent,
    TreatmentAssetLineItemComponent,
    TreatmentAssetListComponent,
  ],
})
export class TreatmentChartingModule {}
