import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TrackByFunctions,
  type TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  type IStaffer,
  type ITask,
  type ITeam,
  type TaskPriority,
} from '@principle-theorem/principle-core/interfaces';
import { snapshot, type WithRef } from '@principle-theorem/shared';
import { noop, ReplaySubject, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { BaseTaskForm } from '../../base-task-form';
import { type ITaskFormData } from '../task-form/task-form.formgroup';

@Component({
    selector: 'pr-task-edit',
    templateUrl: './task-edit.component.html',
    styleUrls: ['./task-edit.component.scss'],
    providers: [...MOMENT_DATEPICKER_PROVIDERS],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TaskEditComponent extends BaseTaskForm implements OnDestroy {
  protected _onDestroy$: Subject<void> = new Subject();
  trackByPriority = TrackByFunctions.variable<TaskPriority>();
  task$: ReplaySubject<WithRef<ITask>> = new ReplaySubject(1);
  @Output() submitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancelled: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  set task(task: WithRef<ITask>) {
    if (task) {
      this.task$.next(task);
    }
  }

  constructor(
    dialog: MatDialog,
    organisation: OrganisationService,
    currentScope: CurrentScopeFacade,
    protected _snackBar: MatSnackBar
  ) {
    super(dialog, currentScope, organisation);

    this.task$
      .pipe(
        switchMap((task) => this.updateFormValues(task)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  get assignee(): TypedFormControl<WithRef<IStaffer> | WithRef<ITeam>> {
    return this.taskFormGroup.controls.assignee as TypedFormControl<
      WithRef<IStaffer> | WithRef<ITeam>
    >;
  }

  get dueTime(): TypedFormControl<string> {
    return this.taskFormGroup.controls.dueTime as TypedFormControl<string>;
  }

  async save(): Promise<void> {
    if (!this._isValid()) {
      return;
    }
    const task: WithRef<ITask> = await snapshot(this.task$);
    await this._taskManager.update(
      task,
      this.taskFormGroup.getRawValue(),
      this.recurringTaskConfiguration
    );
    this._snackBar.open('Updated task');
    this.submitted.emit();
    this.taskFormGroup.markAsPristine();
  }

  cancel(): void {
    this.cancelled.emit();
  }

  private _isValid(): boolean {
    const data: ITaskFormData = this.taskFormGroup.getRawValue();
    if (this.taskFormGroup.invalid) {
      return false;
    }
    const hasAssignee: boolean = data.assignee ? true : false;
    if (!hasAssignee) {
      return false;
    }
    return true;
  }
}
