import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { type WithRef, isSameRef } from '@principle-theorem/shared';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  type IStaffer,
  type ITeam,
} from '@principle-theorem/principle-core/interfaces';
import { combineLatest, of, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pr-assignee-selector',
    templateUrl: './assignee-selector.component.html',
    styleUrls: ['./assignee-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AssigneeSelectorComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByStaffer = TrackByFunctions.ref<WithRef<IStaffer>>();
  trackByTeam = TrackByFunctions.ref<WithRef<ITeam>>();
  control: TypedFormControl<WithRef<IStaffer> | WithRef<ITeam>> =
    new TypedFormControl();
  selected$: ReplaySubject<WithRef<IStaffer> | WithRef<ITeam> | undefined> =
    new ReplaySubject(1);
  staff$: ReplaySubject<WithRef<IStaffer>[]> = new ReplaySubject(1);
  teams$: ReplaySubject<WithRef<ITeam>[]> = new ReplaySubject(1);

  @Output() assigneeChanged: EventEmitter<WithRef<IStaffer> | WithRef<ITeam>> =
    new EventEmitter<WithRef<IStaffer> | WithRef<ITeam>>();

  @Input()
  set staff(staff: WithRef<IStaffer>[]) {
    if (!staff || !staff.length) {
      return;
    }
    this.staff$.next(staff);
  }

  @Input()
  set teams(teams: WithRef<ITeam>[]) {
    if (!teams || !teams.length) {
      return;
    }
    this.teams$.next(teams);
  }

  @Input()
  set selected(assignee: WithRef<IStaffer> | WithRef<ITeam> | undefined) {
    this.selected$.next(assignee);
  }

  constructor() {
    this.control.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((value: WithRef<IStaffer> | WithRef<ITeam>) => {
        this.assigneeChanged.emit(value);
      });

    this.selected$
      .pipe(
        switchMap((assignee?) => {
          if (!assignee) {
            return of(undefined);
          }
          return combineLatest([this.staff$, this.teams$]).pipe(
            map(([staff, teams]) => {
              return [...staff, ...teams].find((assignable) =>
                isSameRef<IStaffer | ITeam, IStaffer | ITeam>(
                  assignable,
                  assignee
                )
              );
            })
          );
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((found) => {
        if (found) {
          this.control.setValue(found);
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
