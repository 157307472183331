import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
  forwardRef,
  type OnDestroy,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, type ControlValueAccessor } from '@angular/forms';
import { type MatAutocomplete } from '@angular/material/autocomplete';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { BrandPermissions } from '@principle-theorem/principle-core/features';
import { type ITag } from '@principle-theorem/principle-core/interfaces';
import {
  isPathChanged$,
  isSameRef,
  multiFind,
  multiSortBy$,
  nameSorter,
  shareReplayCold,
  snapshot,
  type CollectionReference,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { differenceWith, uniqBy } from 'lodash';
import { Observable, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { userHasPermissions$ } from '../../auth/has-permission.directive';
import { ManagementService } from '../../auth/management.service';
import { NamedDocsToTags } from '../../models/named-docs-to-tags';
import { OrganisationService } from '../../organisation.service';
import { GlobalStoreService, TagType } from '../../store/global-store.service';
import { RandomTagFactory } from './random-tag-factory';
import { TagSearch } from './tag-search';
import { TagsInput } from './tags-input';

@Component({
  selector: 'pr-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrls: ['./tags-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsInputComponent),
      multi: true,
    },
  ],
})
export class TagsInputComponent implements OnDestroy, ControlValueAccessor {
  private _onDestroy$ = new Subject<void>();
  private _tagCol$ = new ReplaySubject<CollectionReference<ITag>>(1);
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  trackByTag = TrackByFunctions.ref<WithRef<ITag>>();
  namedDocsToTags: NamedDocsToTags;
  tagsInput = new TagsInput();
  tagCtrl = new TypedFormControl<string | WithRef<ITag>>('');
  tagSearch: TagSearch;
  tagFactory$: Observable<RandomTagFactory>;
  tags$: Observable<WithRef<ITag>[]>;
  tagType$ = new ReplaySubject<TagType>(1);
  tagsManage = [BrandPermissions.BrandConfigure, BrandPermissions.TagsManage];
  canManageTags$: Observable<boolean>;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @Input()
  set tagCol(tagCol: CollectionReference<ITag>) {
    if (tagCol) {
      this._tagCol$.next(tagCol);
    }
  }

  @Input()
  set tagType(tagType: TagType) {
    if (tagType) {
      this.tagType$.next(tagType);
    }
  }

  constructor(
    private _global: GlobalStoreService,
    private _organisation: OrganisationService,
    private _management: ManagementService
  ) {
    this.namedDocsToTags = new NamedDocsToTags(this._global);
    this.namedDocsToTags.tags$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((tags) => this.tagsInput.setTags(tags));
    this.tagFactory$ = this._tagCol$.pipe(
      map((col) => new RandomTagFactory(col))
    );

    this.tags$ = this.tagType$.pipe(
      switchMap((tagType) => this._getAvailableTags$(tagType)),
      shareReplayCold()
    );

    this.tagSearch = new TagSearch(
      this.tagType$.pipe(
        switchMap((tagType) => this._getAvailableTags$(tagType))
      ),
      this.tagCtrl.valueChanges.pipe(startWith(''))
    );

    this.canManageTags$ = combineLatest([
      of(this.tagsManage),
      this._organisation.userPermissions$,
      this._management.user$.pipe(isPathChanged$('ref.path')),
    ]).pipe(userHasPermissions$());
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  add(tag: WithRef<ITag>): void {
    this.tagsInput.add(tag);
    this._resetInput();
  }

  async create(value: string): Promise<void> {
    if (!value || this.matAutocomplete.isOpen) {
      return;
    }

    const factory = await snapshot(this.tagFactory$);

    const matchingTag = await snapshot(
      this.tags$.pipe(
        multiFind(
          (tag) => tag.name.trim().toLowerCase() === value.trim().toLowerCase()
        )
      )
    );

    if (matchingTag) {
      return this.add(matchingTag);
    }

    const tag = await factory.create(value);
    this.tagsInput.add(tag);
    this._resetInput();
  }

  writeValue(namedDocs: INamedDocument<ITag>[]): void {
    if (!namedDocs) {
      return;
    }
    if (namedDocs.length) {
      this.namedDocsToTags.namedDocs$.next(namedDocs);
      return;
    }
    this.tagsInput.setTags([]);
  }

  registerOnChange(fn: () => void): void {
    this.tagsInput.namedDocs$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.tagsInput.namedDocs$.pipe(takeUntil(this._onDestroy$)).subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      return this.tagCtrl.disable();
    }
    this.tagCtrl.enable();
  }

  private _resetInput(): void {
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.reset();
  }

  private _getAvailableTags$(tagType: TagType): Observable<WithRef<ITag>[]> {
    return combineLatest([
      this._global.getTagsByType$(tagType),
      this.tagsInput.tags$,
    ]).pipe(
      map(([allTags, selectedTags]) =>
        differenceWith(uniqBy(allTags, 'name'), selectedTags, isSameRef)
      ),
      multiSortBy$(nameSorter())
    );
  }
}
