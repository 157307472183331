import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type WithRef } from '@principle-theorem/shared';
import { type IAppointment } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'pr-appointment-practitioner',
  templateUrl: './appointment-practitioner.component.html',
  styleUrls: ['./appointment-practitioner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentPractitionerComponent {
  appointment$: ReplaySubject<WithRef<IAppointment>> = new ReplaySubject(1);

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }
}
