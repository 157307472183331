import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import {
  CalendarEventsFacade,
  CalendarFacade,
} from '@principle-theorem/ng-calendar/store';
import { EventableTimelineStore } from '@principle-theorem/ng-eventable';
import {
  CurrentPracticeScope,
  GlobalStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { EventableQueries } from '@principle-theorem/principle-core';
import {
  CalendarUnit,
  EventType,
  IScheduleSummaryEventable,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  getEnumValues,
  isChanged$,
  isRefChanged$,
  isSameRange,
  isSameRef,
  shareReplayCold,
  sortTimestamp,
  type ITimePeriod,
} from '@principle-theorem/shared';
import { BehaviorSubject, combineLatest, Subject, type Observable } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'pr-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EventableTimelineStore],
})
export class CalendarComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();

  events$: Observable<IScheduleSummaryEventable[]>;
  unit$: Observable<CalendarUnit>;
  range$: Observable<ITimePeriod>;
  loading$ = new BehaviorSubject(false);

  constructor(
    public eventableTimelineStore: EventableTimelineStore,
    private _calendarFacade: CalendarFacade,
    private _calendarEventsFacade: CalendarEventsFacade,
    private _practiceScope: CurrentPracticeScope,
    private _organisation: OrganisationService,
    private _globalStore: GlobalStoreService
  ) {
    this.unit$ = this._calendarFacade.unit$;
    this.range$ = combineLatest([
      this._calendarFacade.range$,
      this._calendarFacade.unit$,
    ]).pipe(map(([range, unit]) => this._getLoadDataRange(range, unit)));

    const loadEventRange$ = this._calendarFacade.range$.pipe(
      map((range) => ({
        from: range.from.startOf('month').subtract(7, 'days'),
        to: range.to.endOf('month').add(7, 'days'),
      }))
    );

    const events$ = combineLatest([
      loadEventRange$.pipe(distinctUntilChanged(isSameRange)),
      this._practiceScope.doc$.pipe(filterUndefined(), isRefChanged$()),
      this._organisation.practicePractitioners$.pipe(isChanged$(isSameRef)),
      this._globalStore.rosterSchedules$,
      this._calendarFacade.unit$,
      this._calendarFacade.view$,
    ]).pipe(
      tap(() => this.loading$.next(true)),
      switchMap(([dateRange, practice, staff, allStaffSchedules, unit, view]) =>
        EventableQueries.getScheduleSummaryEventablesWithFallback$(
          dateRange,
          practice,
          staff,
          allStaffSchedules,
          getEnumValues(EventType).filter(
            (type) =>
              ![EventType.Meeting, EventType.Leave, EventType.Misc].includes(
                type
              )
          ),
          unit,
          view
        )
      ),
      map((events) =>
        events
          .sort((a, b) => sortTimestamp(a.event.from, b.event.from))
          .reverse()
      ),
      tap(() => this.loading$.next(false)),
      shareReplayCold()
    );

    combineLatest([events$, loadEventRange$])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([events, dateRange]) =>
        this.eventableTimelineStore.loadEvents({ events, dateRange })
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._calendarEventsFacade.unsubscribeEvents();
  }

  private _getLoadDataRange(
    selectedRange: ITimePeriod,
    unit: CalendarUnit
  ): ITimePeriod {
    switch (unit) {
      case CalendarUnit.Month:
        return {
          from: selectedRange.from.startOf('month'),
          to: selectedRange.to.endOf('month'),
        };
      case CalendarUnit.Week:
        return {
          from: selectedRange.from.startOf('week'),
          to: selectedRange.from.endOf('week'),
        };
      case CalendarUnit.Day:
        return {
          from: selectedRange.from.startOf('day'),
          to: selectedRange.to.endOf('day'),
        };
      default:
        return selectedRange;
    }
  }
}
