import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TimelineTooltipComponent } from '@principle-theorem/ng-eventable';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ScheduleSummary } from '@principle-theorem/principle-core';
import {
  IAppointment,
  IScheduleSummaryEventable,
  ITypesenseWaitListWithRef,
} from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, Firestore } from '@principle-theorem/shared';
import { Observable, of, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-gap-appointment-tooltip',
  templateUrl: './gap-appointment-tooltip.component.html',
  styleUrls: ['./gap-appointment-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TimelineTooltipComponent, NgSharedModule],
})
export class GapAppointmentTooltipComponent {
  appointment$ = new ReplaySubject<ITypesenseWaitListWithRef>(1);
  eventable$: Observable<IScheduleSummaryEventable<IAppointment>>;

  @Input()
  set appointment(appointment: ITypesenseWaitListWithRef) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor() {
    this.eventable$ = this.appointment$.pipe(
      switchMap((appointment) => Firestore.getDoc(appointment.ref)),
      switchMap((appointment) => {
        if (!appointment.event) {
          return of(undefined);
        }
        return ScheduleSummary.getSummaryFromEventable<IAppointment>(
          { ...appointment, event: appointment.event },
          true
        );
      }),
      filterUndefined()
    );
  }
}
