import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type WithRef, type IReffable } from '@principle-theorem/shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { type INotification } from '@principle-theorem/notifications';
import { ReplaySubject } from 'rxjs';
import { AnyExtension } from '@tiptap/core';

@Component({
  selector: 'pt-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent {
  trackByNotification = TrackByFunctions.ref<WithRef<INotification>>();
  preview$: ReplaySubject<boolean> = new ReplaySubject(1);
  notifications$: ReplaySubject<WithRef<INotification>[]> = new ReplaySubject(
    1
  );
  extensions$ = new ReplaySubject<AnyExtension[]>(1);
  currentUser$ = new ReplaySubject<IReffable>(1);

  @Input()
  set preview(preview: boolean) {
    if (preview) {
      this.preview$.next(preview);
    }
  }

  @Input()
  set notifications(notifications: WithRef<INotification>[]) {
    if (notifications) {
      this.notifications$.next(notifications);
    }
  }

  @Input()
  set extensions(extensions: AnyExtension[]) {
    if (extensions) {
      this.extensions$.next(extensions);
    }
  }

  @Input()
  set currentUser(currentUser: IReffable) {
    if (currentUser) {
      this.currentUser$.next(currentUser);
    }
  }
}
