<button
  mat-icon-button
  [matMenuTriggerFor]="moreMenu"
  matTooltip="Periodontal Settings"
  ptElementTarget="periodontal-settings-button"
>
  <mat-icon>tune</mat-icon>
</button>

<mat-menu class="view-settings" #moreMenu="matMenu">
  <form [formGroup]="perioSettingsForm">
    <pt-settings-menu-slide-toggle-item
      label="Automatic Cell Switching"
      formControlName="autoSwitchCells"
      ptElementTarget="perio-auto-switch-button"
    />

    <pt-settings-menu-toggle-group-item
      label="Side Movement"
      formControlName="switchPattern"
      ptElementTarget="perio-switch-pattern-button"
      [options]="switchPatternOptions"
    />

    <pt-settings-menu-toggle-group-item
      label="Direction"
      formControlName="switchDirection"
      ptElementTarget="perio-switch-direction-button"
      [options]="switchDirectionOptions"
    />
  </form>
</mat-menu>
