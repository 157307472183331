import { IMedia } from '@principle-theorem/principle-core/interfaces';
import { AtLeast, toMoment } from '@principle-theorem/shared';

export class Media {
  static init(overrides: AtLeast<IMedia, 'path'>): IMedia {
    return {
      name: '',
      tags: [],
      deleted: false,
      ...overrides,
    };
  }

  static hasExpired(media: IMedia): boolean {
    if (!media.expiresAt) {
      return false;
    }
    return toMoment(media.expiresAt).isBefore();
  }
}
