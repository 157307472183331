import {
  IBaseTemplateContext,
  IContextBuilder,
} from '@principle-theorem/principle-core/interfaces';
import { CASUAL_DATE_WITH_YEAR, TIME_FORMAT } from '@principle-theorem/shared';
import * as moment from 'moment-timezone';

export class BaseContextBuilder
  implements IContextBuilder<IBaseTemplateContext>
{
  build(): IBaseTemplateContext {
    return {
      nowDate: moment().format(CASUAL_DATE_WITH_YEAR),
      nowTime: moment().format(TIME_FORMAT),
    };
  }
}
