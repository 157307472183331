<button
  mat-button
  [matMenuTriggerFor]="textStyleMenu"
  [matMenuTriggerRestoreFocus]="false"
  [ngClass]="{ active: isActive$ | async }"
  [matTooltip]="tooltip"
  matTooltipShowDelay="300"
>
  <mat-icon>format_size</mat-icon>
</button>

<mat-menu #textStyleMenu="matMenu">
  <ng-container>
    <button
      mat-menu-item
      matTooltip="{{ 'Shift-Mod-0' | translateForPlatform }}"
      matTooltipPosition="after"
      [ngClass]="{ active: (activeHeadingSize$ | async) === 0 }"
      (click)="resetFormat()"
    >
      <p>Normal Text</p>
    </button>
    <ng-container *ngFor="let level of levels; trackBy: trackByLevel">
      <button
        mat-menu-item
        [ngClass]="{
          active: (activeHeadingSize$ | async) === level
        }"
        (click)="toggleHeading(level)"
        matTooltip="{{ 'Mod-Alt-' + level | translateForPlatform }}"
        matTooltipPosition="after"
      >
        <ng-container [ngSwitch]="level">
          <h1 *ngSwitchCase="1">Heading 1</h1>
          <h2 *ngSwitchCase="2">Heading 2</h2>
          <h3 *ngSwitchCase="3">Heading 3</h3>
          <h4 *ngSwitchCase="4">Heading 4</h4>
          <h5 *ngSwitchCase="5">Heading 5</h5>
          <h6 *ngSwitchCase="6">Heading 6</h6>
          <span *ngSwitchDefault>Heading {{ level }}</span>
        </ng-container>
      </button>
    </ng-container>
  </ng-container>
</mat-menu>
