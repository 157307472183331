import { createAction, props } from '@ngrx/store';
import { type IEntityAction } from '@principle-theorem/ng-shared';
import { type ChartableSurface } from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData } from '@principle-theorem/shared';
import { type ChartId } from '../reducers/active-charts/chart-context-state';
import { ChartActionTypes } from './action-types';

export interface ISetDisabledSurfaces extends IEntityAction<ChartId> {
  disabled: ChartableSurface[];
}

export const setDisabledSurfaces = createAction(
  ChartActionTypes.SetDisabledSurfaces,
  props<SerialisedData<ISetDisabledSurfaces>>()
);
