<div
  *ngrxLet="gapStore.selectedGap$ as gap"
  #shortlistContainer
  class="flex flex-col gap-4"
>
  <div class="flex flex-row">
    <button class="back-button" mat-flat-button (click)="clearGap.emit()">
      <mat-icon>chevron_left</mat-icon>
      Back
    </button>
  </div>

  <div class="flex flex-col gap-1">
    @if (gap) {
      <div class="font-bold opacity-90">
        Gap Selected: {{ gap.event.from | moment | amDateFormat: dateFormat }}
      </div>
    }
    <pr-gap-item isFocusView [gap]="gap" />
  </div>

  <mat-divider />

  <div class="flex flex-col gap-2">
    <div class="text-[1rem] font-bold opacity-90">Candidate Shortlist</div>
    @if (gapStore.gapCandidates$ | async; as gapCandidates) {
      @if (gapCandidates.length) {
        @for (candidate of gapCandidates; track candidate.ref.path) {
          <pr-gap-shortlist-item [gap]="gap" [gapCandidate]="candidate" />
        }
      } @else {
        <div class="candidates-placeholder mat-caption">
          <span>Add gap candidates </span
          ><mat-icon class="opacity-50">add_circle_outline</mat-icon
          ><span> from the waitlist below to start filling the gap.</span>
        </div>
      }
    }
  </div>

  <mat-divider />

  <mat-button-toggle-group
    class="flex items-center"
    [value]="activeViewType$ | async"
    [hideSingleSelectionIndicator]="true"
  >
    @for (filter of typeFilters; track filter.value) {
      <mat-button-toggle
        class="flex-1"
        matTooltipShowDelay="300"
        [matTooltip]="filter.tooltip"
        [value]="filter.value"
        (change)="setViewType(filter.value)"
      >
        {{ filter.value }}
      </mat-button-toggle>
    }
  </mat-button-toggle-group>

  <ng-container *ngrxLet="isSmartView$ | async as smartView">
    <div class="flex items-center gap-2">
      <mat-form-field
        appearance="outline"
        subscriptSizing="dynamic"
        class="flex-1"
      >
        <mat-icon class="opacity-70" matPrefix>search</mat-icon>
        <input matInput [formControl]="waitlistSearchCtrl" />
        <mat-label>Search by Name / Category / Duration</mat-label>
      </mat-form-field>

      <pr-multi-practitioner-select-button
        tooltipText="Filter By Practitioner"
        [preselectedPractitioners]="gapPractitioner$ | async"
        (selectionChange)="selectedPractitioners$.next($event)"
      />

      <pr-wait-list-sort-selector />
    </div>

    <div class="flex flex-col gap-3">
      @if (smartView) {
        @for (
          candidate of waitListStore.smartWaitListCandidates$ | async;
          track candidate.appointment.ref.id
        ) {
          <pr-gap-waitlist-item [waitListCandidate]="candidate" [gap]="gap" />
        } @empty {
          <pr-empty-state
            image="list"
            explicitTitle="No Waitlist Suggestions"
            description="No matching waitlisted appointments found to fill the gap."
          />
        }

        @if (waitListStore.smartWaitListResults$ | async; as results) {
          <div
            *ngIf="results.numberFound > waitListPageSize"
            class="mt-2 flex items-center gap-2"
          >
            <button
              class="mat-small flex-1"
              mat-stroked-button
              [disabled]="waitListStore.allSmartResultsLoaded$ | async"
              (click)="loadMoreSmartWaitListResults()"
            >
              Load More Results
            </button>
            <button
              mat-icon-button
              matTooltip="Scroll To Top"
              class="opacity-70"
              (click)="scrollToTop()"
            >
              <mat-icon>arrow_upward</mat-icon>
            </button>
          </div>
        }
      } @else {
        @for (
          candidate of waitListStore.allWaitListCandidates$ | async;
          track candidate.appointment.ref.id
        ) {
          <pr-gap-waitlist-item [waitListCandidate]="candidate" [gap]="gap" />
        } @empty {
          <pr-empty-state
            image="list"
            title="waitlist candidates"
            [descriptionEnabled]="false"
          />
        }

        @if (waitListStore.allWaitListResults$ | async; as results) {
          <div
            *ngIf="results.numberFound > waitListPageSize"
            class="mt-2 flex items-center gap-2"
          >
            <button
              class="mat-small flex-1"
              mat-stroked-button
              [disabled]="waitListStore.allResultsLoaded$ | async"
              (click)="loadMoreAllWaitListResults()"
            >
              Load More Results
            </button>
            <button
              mat-icon-button
              matTooltip="Scroll To Top"
              class="opacity-70"
              (click)="scrollToTop()"
            >
              <mat-icon>arrow_upward</mat-icon>
            </button>
          </div>
        }
      }
    </div>
  </ng-container>
</div>
