import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { type CollectionReference } from '@principle-theorem/shared';
import { getDoc$, type WithRef } from '@principle-theorem/shared';
import { AbstractResolver } from '@principle-theorem/ng-shared';
import { type IStaffer } from '@principle-theorem/principle-core/interfaces';
import { type Observable, of } from 'rxjs';

@Injectable()
export class StafferResolverService extends AbstractResolver<
  WithRef<IStaffer>
> {
  getItemById(
    id: string,
    route: ActivatedRouteSnapshot
  ): Observable<WithRef<IStaffer> | undefined> {
    const collection: CollectionReference<IStaffer> | undefined =
      this._findInheritedRouteData(route, 'stafferCollection');
    if (!collection) {
      return of(undefined);
    }
    return getDoc$(collection, id);
  }
}
