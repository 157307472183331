import { IBaseMigrationItemCode } from '../../../interfaces';
import { ItemCodesMappingHandler } from '../../../mappings/item-codes';
import {
  ADAItemSourceEntity,
  type IExactADAItem,
} from '../../source/entities/ada-item';

export class ExactItemCodeMappingHandler extends ItemCodesMappingHandler<
  IExactADAItem,
  ADAItemSourceEntity
> {
  override sourceEntity = new ADAItemSourceEntity();
  override translateFn = (record: IExactADAItem): IBaseMigrationItemCode => ({
    id: record.itemCode,
    itemCode: record.itemCode,
    description: record.description ?? '',
  });
}
