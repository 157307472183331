import { rand, randNumber } from '@ngneat/falso';
import { IPrescriptionItem } from '@principle-theorem/principle-core/interfaces';
import { BaseFirestoreMock, Properties } from '@principle-theorem/shared';
import { toString } from 'lodash';

export class PrescriptionItemMock
  extends BaseFirestoreMock
  implements Properties<IPrescriptionItem>
{
  get medicationName(): string {
    return rand([
      'TEST PRESCTIPTION Paracetamol',
      'TEST PRESCTIPTION Ibuprofen',
      'TEST PRESCTIPTION Aspirin',
      'TEST PRESCTIPTION Amoxicillin',
      'TEST PRESCTIPTION Omeprazole',
      'TEST PRESCTIPTION Lansoprazole',
    ]);
  }

  get quantity(): string {
    return toString(randNumber({ min: 1, max: 24 }));
  }

  get repeat(): number {
    return randNumber({ min: 0, max: 4 });
  }

  get instructions(): string {
    return rand([
      'Take one tablet every 4 hours',
      'Take two tablets every 6 hours',
      'Take one tablet every 8 hours',
      'Take one tablet every 12 hours',
      'Take one tablet every 24 hours',
    ]);
  }

  get safetyInformation(): string {
    return '';
  }
}
