<div
  *ngrxLet="treatmentCategory$ as category"
  class="waitlist-item-container flex"
>
  <div
    #tooltipOrigin="cdkOverlayOrigin"
    class="treatment-category-indicator"
    cdkOverlayOrigin
    [style.background-color]="category.colour.value"
  ></div>

  <pt-custom-tooltip
    tooltipDelay="short"
    [positions]="positions"
    [target]="tooltipOrigin"
  >
    <ng-template ptCustomTooltipContent>
      <pr-gap-appointment-tooltip [appointment]="appointment$ | async" />
    </ng-template>
  </pt-custom-tooltip>

  <ng-content />
</div>
