import { CdkDropList } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ScrollContainer,
  ScrollContainerManagerService,
} from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  type IChartedRef,
  type IChartedTreatment,
  type IChartedTreatmentGroup,
  type ITreatmentCategory,
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  multiFilter,
  multiMap,
  reduceToSingleArray,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, get, groupBy } from 'lodash';
import { combineLatest, type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  type ITreatmentDragDropNode,
  TreatmentDragDropGroup,
} from '../../treatment-drag-drop-group';
import { type IEditChartableData } from '../../chartable-surface-updater';
import { ChartedTreatmentGroup } from '@principle-theorem/principle-core';

@Component({
    selector: 'pr-treatment-scope-list',
    templateUrl: './treatment-scope-list.component.html',
    styleUrls: ['./treatment-scope-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreatmentScopeListComponent implements OnDestroy {
  private _cdkDropList$: ReplaySubject<CdkDropList> = new ReplaySubject(1);
  private _onDestroy$: Subject<void> = new Subject();
  trackByTreatment = TrackByFunctions.uniqueId<IChartedTreatment>();
  trackByTreatmentGroup = TrackByFunctions.uniqueId<IChartedTreatmentGroup>();
  @Input() disabled = false;
  @Input() compact = false;
  @Input() expanded = false;
  selectedSurfaces$ = new ReplaySubject<Partial<IChartedRef>[]>(1);
  allTreatments$ = new ReplaySubject<IChartedTreatment[]>(1);
  step$ = new ReplaySubject<
    WithRef<ITreatmentStep> | IChartedMultiStepTreatmentStep
  >(1);
  plan$ = new ReplaySubject<
    WithRef<ITreatmentPlan> | IChartedMultiStepTreatment
  >(1);
  treatments$: Observable<IChartedTreatment[]>;
  treatmentGroups$: Observable<IChartedTreatmentGroup[]>;
  scrollContainer$: Observable<HTMLElement>;
  treatmentGroupDragDrop: TreatmentDragDropGroup;
  listData$: Observable<ITreatmentDragDropNode>;
  @Output() treatmentsChange = new EventEmitter<IChartedTreatment[]>();
  @Output() treatmentDeleted = new EventEmitter<IChartedTreatment>();
  @Output() categoryChanged = new EventEmitter<WithRef<ITreatmentCategory>>();
  @Output() updateChartable = new EventEmitter<IEditChartableData>();

  @Input()
  set treatments(treatments: IChartedTreatment[]) {
    if (treatments) {
      this.allTreatments$.next(treatments);
    }
  }

  @Input()
  set selectedSurfaces(selectedSurfaces: Partial<IChartedRef>[]) {
    if (selectedSurfaces) {
      this.selectedSurfaces$.next(selectedSurfaces);
    }
  }

  @Input()
  set step(step: WithRef<ITreatmentStep> | IChartedMultiStepTreatmentStep) {
    if (step) {
      this.step$.next(step);
    }
  }

  @Input()
  set plan(plan: WithRef<ITreatmentPlan> | IChartedMultiStepTreatment) {
    if (plan) {
      this.plan$.next(plan);
    }
  }

  @ViewChild(CdkDropList, { static: true })
  set cdkDropList(cdkDropList: CdkDropList) {
    this._cdkDropList$.next(cdkDropList);
    this.treatmentGroupDragDrop.register(combineLatest([this._cdkDropList$]));
  }

  constructor(
    private _scrollContainerManager: ScrollContainerManagerService,
    private _route: ActivatedRoute
  ) {
    this.scrollContainer$ = this._scrollContainerManager.getContainer(
      ScrollContainer.InAppointment
    );
    this.listData$ = combineLatest([this.allTreatments$]).pipe(
      map(([items]) => ({ parent: undefined, items }))
    );

    this.treatments$ = this.allTreatments$.pipe(
      multiFilter((treatment) => !treatment.isGrouped)
    );

    this.treatmentGroups$ = this.allTreatments$.pipe(
      multiFilter((treatment) => !!treatment.isGrouped),
      map((treatments) =>
        Object.values(
          groupBy(treatments, (treatment) => treatment.config.ref.path)
        )
      ),
      multiMap((treatments) => {
        if (!treatments.length) {
          return;
        }

        const config = treatments[0].config;
        const feeSchedule = treatments[0].feeSchedule;
        const chartedSurfaces = reduceToSingleArray(
          treatments.map((treatment) => treatment.chartedSurfaces)
        );

        return ChartedTreatmentGroup.init({
          config,
          treatments,
          chartedSurfaces,
          feeSchedule,
        });
      }),
      map(compact)
    );

    this.treatmentGroupDragDrop = get(
      this._route.snapshot.data,
      'treatmentGroupDragDrop',
      new TreatmentDragDropGroup()
    ) as TreatmentDragDropGroup;

    combineLatest([this.treatmentGroupDragDrop.afterDrop$, this._cdkDropList$])
      .pipe(
        map(([afterDrop, dropList]) =>
          afterDrop.previous.id === dropList.id
            ? afterDrop.previous.data
            : afterDrop.current.id === dropList.id
              ? afterDrop.current.data
              : undefined
        ),
        filterUndefined(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((treatments) => this.updateOrder(treatments));
  }

  ngOnDestroy(): void {
    this.treatmentGroupDragDrop.unregister(combineLatest([this._cdkDropList$]));
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  updateOrder(node: ITreatmentDragDropNode): void {
    this.treatmentsChange.next(node.items);
  }

  updateTreatment(
    treatments: IChartedTreatment[],
    treatment: IChartedTreatment
  ): void {
    const changed = treatments.map((currentTreatment) => {
      if (currentTreatment.uuid !== treatment.uuid) {
        return currentTreatment;
      }
      return {
        ...currentTreatment,
        ...treatment,
      };
    });
    this.treatmentsChange.next(changed);
  }

  enterIfEnabledPredicateFn(): boolean {
    return !this.disabled;
  }
}
