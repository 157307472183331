export const requestUrl =
  'https://praktika.praktika.net.au/php/forms/db_getCustomerForm.php';
export const requestMethod = 'POST';

export enum PraktikaUserTitle {
  Unknown = '',
  Mr = 'Mr',
  Master = 'Master',
  Ms = 'Ms',
  Miss = 'Miss',
  Mrs = 'Mrs',
  Dr = 'Dr',
  Prof = 'Prof',
  Mx = 'Mx',
}

export const patientAssociatedTypesResponse = {
  enum_title: ['', 'Mr', 'Master', 'Ms', 'Miss', 'Mrs', 'Dr', 'Prof', 'Mx'],
  country_code: '+61',
  country_state: ['VIC', 'NSW', 'TAS', 'ACT', 'WA', 'SA', 'NT'],
  country_healthfund: [
    {
      code: 'CDH',
      name: 'Cessnock Dist Health',
    },
    {
      code: 'MYO',
      name: 'My Own Health Insurance',
    },
    {
      code: 'OMF',
      name: 'onemedifund',
    },
    {
      code: 'POLICE',
      name: 'Police Health',
    },
    {
      code: 'CBHS',
      name: 'CBHS',
    },
    {
      code: 'STL',
      name: 'St Lukes Health',
    },
    {
      code: 'CBHCRP',
      name: 'CBHS Corporate',
    },
    {
      code: 'NMW',
      name: 'Nurses &amp; Midwives Health',
    },
    {
      code: 'HOV',
      name: 'HOV',
    },
    {
      code: 'MU',
      name: 'Manchester Unity',
    },
    {
      code: 'HSL',
      name: 'HSL',
    },
    {
      code: 'HCF',
      name: 'API Health Linx',
    },
    {
      code: 'MDH',
      name: 'Mildura Health FUnd',
    },
    {
      code: 'TFH',
      name: 'UniHealth Insurance',
    },
    {
      code: 'CWHC',
      name: 'Central West Health Cover',
    },
    {
      code: 'FHI',
      name: 'Frank Health Insurance',
    },
    {
      code: 'NHBA',
      name: 'National Health Benefits Aust',
    },
    {
      code: 'RAIL',
      name: 'Railway Transport',
    },
    {
      code: 'OTHER',
      name: 'OTHER',
    },
    {
      code: 'IHMS',
      name: 'International Health and Medical Services',
    },
    {
      code: 'NIB',
      name: 'Qantas Assure',
    },
    {
      code: 'NO FUND-NIL',
      name: 'No fund',
    },
    {
      code: 'AAMI',
      name: 'AAMI',
    },
    {
      code: 'APIA',
      name: 'APIA',
    },
    {
      code: 'ESH',
      name: 'Emergency Services Health',
    },
    {
      code: 'ACA',
      name: 'ACA Health Benefits',
    },
    {
      code: 'HBA/MC',
      name: 'ANZ Health Insurance',
    },
    {
      code: 'MBP',
      name: 'Medibank Private',
    },
    {
      code: 'AHM',
      name: 'Australian Health Management',
    },
    {
      code: 'TUH',
      name: 'Teachers Union Health',
    },
    {
      code: 'TFS',
      name: 'Transport Health',
    },
    {
      code: 'WFUND',
      name: 'West Fund/Western District',
    },
    {
      code: 'THF',
      name: 'Territory Health Fund',
    },
    {
      code: 'BUPA',
      name: 'SGIC',
    },
    {
      code: 'PHNX',
      name: 'Phoenix Health Fund',
    },
    {
      code: 'TFH',
      name: 'Teachers Federation Health',
    },
    {
      code: 'RNT',
      name: 'rt Health Fund',
    },
    {
      code: 'NAVY',
      name: 'Navy Health',
    },
    {
      code: 'HCF',
      name: 'National Private Health Insurance',
    },
    {
      code: 'AU',
      name: 'Australian Unity',
    },
    {
      code: 'BUPA',
      name: 'Mutual Community',
    },
    {
      code: 'HIF',
      name: 'Health Insurance Fund of WA',
    },
    {
      code: 'QCH',
      name: 'Queensland Country Health',
    },
    {
      code: 'HCI',
      name: 'Health Care Insurance',
    },
    {
      code: 'LPC',
      name: 'Peoplecare',
    },
    {
      code: 'GMF',
      name: 'Healthguard',
    },
    {
      code: 'DHF',
      name: 'Doctors Health Fund (AMA)',
    },
    {
      code: 'BUPA',
      name: 'MBF Australia',
    },
    {
      code: 'GMF',
      name: 'GMF Health (Goldfields Medical Fund)',
    },
    {
      code: 'VWA',
      name: 'Victorian WorkSafe Authority',
    },
    {
      code: 'HEALTH',
      name: 'health.com.au',
    },
    {
      code: 'BDHI/GMHBA',
      name: 'Budget Direct Health Insurance',
    },
    {
      code: 'SCHI',
      name: 'Suncorp Health Insurance',
    },
    {
      code: 'GUH',
      name: 'Grand United',
    },
    {
      code: 'CC',
      name: 'CUA Health',
    },
    {
      code: 'SGIC',
      name: 'NRMA Health Insurance',
    },
    {
      code: 'HBF',
      name: 'HBF Health Funds inc.',
    },
    {
      code: 'LHS',
      name: 'Latrobe Health Services',
    },
    {
      code: 'DEF',
      name: 'Defence Health',
    },
    {
      code: 'CHF',
      name: 'CUA Health (Credicare)',
    },
    {
      code: 'HP',
      name: 'Health Partners',
    },
    {
      code: 'RBHS',
      name: 'Reserve Bank Health',
    },
    {
      code: 'ADFHF',
      name: 'ADF Family Health',
    },
    {
      code: 'BUPA',
      name: 'SGIO',
    },
    {
      code: 'HBA/MC',
      name: 'HBA',
    },
    {
      code: 'HHB',
      name: 'CY Health',
    },
    {
      code: 'GMHBA',
      name: 'GMHBA',
    },
    {
      code: 'HBA',
      name: 'BUPA Australia Health',
    },
    {
      code: 'ESH',
      name: 'Emergency Services Health',
    },
    {
      code: 'TRAIN',
      name: 'Training Fund',
    },
    {
      code: 'HIF',
      name: 'Health Insurance Fund of Australia',
    },
    {
      code: 'HCF',
      name: 'HCF',
    },
    {
      code: 'NIB',
      name: 'NIB',
    },
  ],
  enum_patientstatus: [
    {
      id: '1',
      status: 'CURRENT',
      label: 'Patient is current (active)',
    },
    {
      id: '2',
      status: 'GUARANTOR',
      label: 'Patient is only a guarantor',
    },
    {
      id: '3',
      status: 'DECEASED',
      label: 'Deceased',
    },
    {
      id: '4',
      status: 'SUSPENDED',
      label: 'Patient file is suspended',
    },
    {
      id: '5',
      status: 'INACTIVE',
      label: 'Patient has not showed up for a while',
    },
    {
      id: '6',
      status: 'DELETED',
      label: 'Patient removed from the directory',
    },
  ],
};

export type PraktikaPersonTitle =
  | ''
  | 'Mr'
  | 'Master'
  | 'Ms'
  | 'Miss'
  | 'Mrs'
  | 'Dr'
  | 'Prof'
  | 'Mx';

export type PraktikaState = 'VIC' | 'NSW' | 'TAS' | 'ACT' | 'WA' | 'SA' | 'NT';

export enum PatientStatus {
  Current = '1',
  Guarantor = '2',
  Deceased = '3',
  Suspended = '4',
  Inactive = '5',
  Deleted = '6',
}
