import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule, TypedFormControl } from '@principle-theorem/ng-shared';
import { SchedulingEventReason } from '@principle-theorem/principle-core';
import {
  ISchedulingEventConditions,
  ISchedulingEventReason,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  WithRef,
  isSameRef,
  titlecase,
} from '@principle-theorem/shared';
import { noop } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
} from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { SchedulingEventReasonTooltipComponent } from '../scheduling-event-reason-tooltip/scheduling-event-reason-tooltip.component';

export interface IReasonSelectorValue {
  reason: DocumentReference<ISchedulingEventReason>;
  reasonSetManually: boolean;
}

@Component({
  selector: 'pr-scheduling-event-reason-selector',
  templateUrl: './scheduling-event-reason-selector.component.html',
  styleUrls: ['./scheduling-event-reason-selector.component.scss'],
  exportAs: 'prSchedulingEventReasonSelector',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgMaterialModule,
    NgSharedModule,
    SchedulingEventReasonTooltipComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SchedulingEventReasonSelectorComponent),
    },
  ],
})
export class SchedulingEventReasonSelectorComponent
  implements ControlValueAccessor, OnDestroy
{
  private _onDestroy$ = new Subject<void>();
  reasons$ = new ReplaySubject<WithRef<ISchedulingEventReason>[]>(1);
  schedulingConditions$ = new ReplaySubject<ISchedulingEventConditions>(1);
  onChange: (_: unknown) => void = noop;
  onTouched: (_: unknown) => void = noop;
  reasonCtrl = new TypedFormControl<
    DocumentReference<ISchedulingEventReason> | undefined
  >();
  reasonSetManually = false;
  defaultReason$: Observable<WithRef<ISchedulingEventReason> | undefined>;
  label$: Observable<string>;
  state$ = new BehaviorSubject<IReasonSelectorValue | undefined>(undefined);

  @Input()
  set reasons(reasons: WithRef<ISchedulingEventReason>[]) {
    if (reasons) {
      this.reasons$.next(reasons);
    }
  }

  @Input()
  set schedulingConditions(
    schedulingConditions: WithRef<ISchedulingEventConditions>
  ) {
    if (schedulingConditions) {
      this.schedulingConditions$.next(schedulingConditions);
    }
  }

  constructor() {
    this.label$ = this.schedulingConditions$.pipe(
      map((conditions) => {
        const action = titlecase(conditions.eventType);
        return `${action} reason`;
      })
    );

    const defaultReasonData$ = combineLatest([
      this.reasons$,
      this.schedulingConditions$,
    ]).pipe(
      map(([reasons, schedulingConditions]) => {
        const defaultReason = SchedulingEventReason.determineDefaultReason(
          reasons,
          schedulingConditions
        );
        return { defaultReason, reasons, schedulingConditions };
      })
    );
    this.defaultReason$ = defaultReasonData$.pipe(
      map(({ defaultReason }) => defaultReason)
    );

    defaultReasonData$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(({ reasons, defaultReason }) =>
        this._selectDefaultReason(reasons, defaultReason)
      );

    this.state$
      .pipe(distinctUntilChanged(), takeUntil(this._onDestroy$))
      .subscribe((state) => this.onChange(state));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  compareFn(
    optionA?: DocumentReference<ISchedulingEventReason>,
    optionB?: DocumentReference<ISchedulingEventReason>
  ): boolean {
    if (!optionA || !optionB) {
      return false;
    }
    return isSameRef(optionA, optionB);
  }

  selected(event: MatSelectChange): void {
    this._setValue(
      event.value as DocumentReference<ISchedulingEventReason> | undefined,
      true
    );
  }

  writeValue(value?: IReasonSelectorValue): void {
    this._setValue(value?.reason, value?.reasonSetManually);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.reasonCtrl.disable();
      return;
    }
    this.reasonCtrl.enable();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private _selectDefaultReason(
    reasons: WithRef<ISchedulingEventReason>[],
    defaultReason?: WithRef<ISchedulingEventReason>
  ): void {
    const state = this.state$.value;

    const selectedReason = reasons.find((reason) =>
      this.compareFn(reason.ref, state?.reason)
    );
    if (state && state.reasonSetManually && selectedReason) {
      return;
    }
    this._setValue(defaultReason?.ref);
  }

  private _setValue(
    reason?: DocumentReference<ISchedulingEventReason>,
    reasonSetManually: boolean = false,
    emitEvent: boolean = false
  ): void {
    const state = reason ? { reason, reasonSetManually } : undefined;
    this.state$.next(state);
    if (!state) {
      this.reasonCtrl.reset(undefined, { emitEvent });
      return;
    }
    this.reasonCtrl.setValue(state.reason, { emitEvent });
  }
}
