import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AuthFirebaseFunctionsService } from '@principle-theorem/ng-auth';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { Organisation } from '@principle-theorem/principle-core';
import { IOrganisation } from '@principle-theorem/principle-core/interfaces';
import {
  PrimitiveObjectSerialiser,
  WithRef,
  httpsCallable,
  serialise,
  shareReplayCold,
  snapshot,
} from '@principle-theorem/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  INgPrincipleConfig,
  NG_PRINCIPLE_CONFIG,
} from '../../../ng-principle-config';

@Component({
  selector: 'pr-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ToolsComponent {
  trackByOrganisation = TrackByFunctions.ref<WithRef<IOrganisation>>();
  revokeAuthSessionsLoading$ = new BehaviorSubject<boolean>(false);
  seedingKeys$ = new BehaviorSubject<boolean>(false);
  seedingTasks$ = new BehaviorSubject<boolean>(false);
  seedingPatients$ = new BehaviorSubject<boolean>(false);
  seedingWaitLists$ = new BehaviorSubject<boolean>(false);
  convertingCustomReports$ = new BehaviorSubject<boolean>(false);
  organisations$: Observable<WithRef<IOrganisation>[]>;
  organisationControl = new TypedFormControl<WithRef<IOrganisation>>();
  isProduction: boolean;

  constructor(
    private _authFunctions: AuthFirebaseFunctionsService,
    @Inject(NG_PRINCIPLE_CONFIG) private _config: INgPrincipleConfig
  ) {
    this.organisations$ = Organisation.all$().pipe(shareReplayCold());
    this.isProduction = this._config.production;
  }

  async revokeAuthSessions(): Promise<void> {
    this.revokeAuthSessionsLoading$.next(true);
    await this._authFunctions.revokeAuthSessions();
    this.revokeAuthSessionsLoading$.next(false);
  }

  async seedTypesenseTasks(): Promise<void> {
    const organisation = this.organisationControl.value;
    if (!organisation) {
      return;
    }
    this.seedingTasks$.next(true);
    await snapshot(
      httpsCallable('http-typesense-seedTasks', {
        timeout: 3600000,
      })(
        serialise({
          orgRef: organisation.ref,
        })
      )
    );
    this.seedingTasks$.next(false);
  }

  async seedTypesensePatients(): Promise<void> {
    const organisation = this.organisationControl.value;
    if (!organisation) {
      return;
    }
    this.seedingPatients$.next(true);
    await snapshot(
      httpsCallable('http-typesense-seedPatients', {
        timeout: 3600000,
      })(
        serialise({
          orgRef: organisation.ref,
        })
      )
    );
    this.seedingPatients$.next(false);
  }

  async seedTypesenseWaitLists(): Promise<void> {
    const organisation = this.organisationControl.value;
    if (!organisation) {
      return;
    }
    this.seedingWaitLists$.next(true);
    await snapshot(
      httpsCallable('http-typesense-seedWaitLists', {
        timeout: 3600000,
      })(
        new PrimitiveObjectSerialiser().serialise({
          orgRef: organisation.ref,
        })
      )
    );
    this.seedingWaitLists$.next(false);
  }

  async seedTypesenseKeys(): Promise<void> {
    this.seedingKeys$.next(true);
    await snapshot(httpsCallable('http-typesense-seedKeys')({}));
    this.seedingKeys$.next(false);
  }
}
