import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Appointment } from '@principle-theorem/principle-core';
import {
  IPatient,
  type IAppointment,
} from '@principle-theorem/principle-core/interfaces';
import { Firestore, type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-appointment-popover',
  templateUrl: './appointment-popover.component.html',
  styleUrls: ['./appointment-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentPopoverComponent {
  appointment$: Observable<WithRef<IAppointment>>;
  patient$: Observable<WithRef<IPatient>>;

  constructor(
    @Inject(MAT_DIALOG_DATA) appointment: WithRef<IAppointment>,
    public dialogRef: MatDialogRef<AppointmentPopoverComponent>
  ) {
    this.appointment$ = Firestore.doc$(appointment.ref);
    this.patient$ = this.appointment$.pipe(
      switchMap((currentAppointment) => Appointment.patient(currentAppointment))
    );
  }

  close(): void {
    this.dialogRef.close();
  }
}
