import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MenuBarComponent } from '../menu-bar.component';

@Component({
  selector: 'pt-submenu-bar',
  templateUrl: './submenu-bar.component.html',
  styleUrls: ['./submenu-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmenuBarComponent extends MenuBarComponent {}
