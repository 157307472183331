<div #content>
  <ng-content></ng-content>
</div>

<ngx-moveable
  #moveable
  *ngIf="enabled$ | async"
  [target]="content"
  [origin]="false"
  [scalable]="true"
  [resizable]="true"
  [keepRatio]="true"
  [renderDirections]="['e']"
  (resize)="resized($event.width, $event.height)"
  (resizeEnd)="resizeEnd.emit()"
/>
