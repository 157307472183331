<mat-toolbar color="accent">Account Credit Warning</mat-toolbar>

<mat-dialog-content>
  <div fxLayout="column" fxLayoutGap="8px">
    <pr-invoice-credit-summary
      #creditSummary="prInvoiceCreditSummary"
      [credits]="data.credits"
     />
  </div>
</mat-dialog-content>

<ng-container *ngrxLet="creditSummary.canAmend$ as canRefund">
  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button mat-stroked-button [mat-dialog-close]="false">Cancel</button>
      <button
        mat-flat-button
        [mat-dialog-close]="true"
        color="primary"
        cdkFocusInitial
        [disabled]="!canRefund"
      >
        Refund
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</ng-container>
