import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DYNAMIC_COMPONENT_DATA } from '@principle-theorem/ng-shared';
import {
  ISmartpayPurchaseTransactionExtendedData,
  isSmartpayPurchaseTransactionExtendedData,
} from '@principle-theorem/principle-core/interfaces';
import { type ITransactionExtendedDataDisplayData } from '../../../transactions-display/transaction-display/transaction-display-line/transaction-display-line.component';

@Component({
  selector: 'pr-smartpay-extended-data',
  templateUrl: './smartpay-extended-data.component.html',
  styleUrls: ['./smartpay-extended-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartpayExtendedDataComponent {
  status?: string;

  constructor(
    @Inject(DYNAMIC_COMPONENT_DATA)
    public data: ITransactionExtendedDataDisplayData<ISmartpayPurchaseTransactionExtendedData>
  ) {
    this.status = this._getStatus(data.transaction.extendedData);
  }

  private _getStatus(data?: ISmartpayPurchaseTransactionExtendedData): string {
    if (!data) {
      return '';
    }
    if (
      isSmartpayPurchaseTransactionExtendedData(data) &&
      data.response.data.TransactionResult
    ) {
      return `Smartpay Transaction Status: ${data.response.data.TransactionResult}`;
    }
    return '';
  }
}
