import { _isNumberValue } from '@angular/cdk/coercion';
import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { type MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  DateService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { type IAppointmentSuggestion } from '@principle-theorem/principle-core/interfaces';
import {
  AppointmentSuggestion,
  type AppointmentSuggestionEntity,
  Event,
} from '@principle-theorem/principle-core';
import {
  CASUAL_DATE_FORMAT,
  TIME_FORMAT,
  toMoment,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { BehaviorSubject, type Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContextDisplayComponent } from '../context-display/context-display.component';
import { AppointmentPermissions } from '@principle-theorem/principle-core/features';

@Component({
    selector: 'pr-appointment-options',
    templateUrl: './appointment-options.component.html',
    styleUrls: ['./appointment-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentOptionsComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  private sort: MatSort;
  displayedColumns$: Observable<string[]>;
  dataSource: MatTableDataSource<AppointmentSuggestionEntity> =
    new MatTableDataSource<AppointmentSuggestionEntity>();
  selection: SelectionModel<string> = new SelectionModel(false);
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageSize = 10;
  selected$ = new BehaviorSubject<AppointmentSuggestionEntity | undefined>(
    undefined
  );
  canDoubleBook$: Observable<boolean>;
  readonly dateFormat = CASUAL_DATE_FORMAT;
  readonly timeFormat = TIME_FORMAT;

  @Output() selectedChange: EventEmitter<
    AppointmentSuggestionEntity | undefined
  > = new EventEmitter<AppointmentSuggestionEntity | undefined>();

  @ViewChild(MatPaginator, { static: true })
  set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort, { static: true })
  set matSort(ms: MatSort) {
    this.sort = ms;
    this._setSortingAttributes();
  }

  @Input()
  set options(options: AppointmentSuggestionEntity[]) {
    if (!options || !options.length) {
      this.dataSource.data = [];
      return;
    }
    this.dataSource.data = options;
  }

  get options(): AppointmentSuggestionEntity[] {
    return this.dataSource.data;
  }

  @Input()
  set selected(option: AppointmentSuggestionEntity | undefined) {
    this.selected$.next(option);
  }

  @Input() treatmentDuration: number;

  constructor(
    public dateService: DateService,
    private _organisation: OrganisationService,
    private _dialog: MatDialog
  ) {
    this.displayedColumns$ = this._organisation.isSingleBrand$.pipe(
      map((isSingleBrand) => this._getDisplayedColumns(isSingleBrand))
    );

    this.canDoubleBook$ = this._organisation.userHasPermission$(
      AppointmentPermissions.SchedulingDoubleBook
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  showContext(suggestion: IAppointmentSuggestion): void {
    this._dialog.open(
      ContextDisplayComponent,
      DialogPresets.large({ data: suggestion, width: '80vw' })
    );
  }

  isBlocked(suggestion: IAppointmentSuggestion): boolean {
    return AppointmentSuggestion.isBlocked(suggestion);
  }

  distance(suggestion: IAppointmentSuggestion): string {
    return AppointmentSuggestion.distance(suggestion);
  }

  showScoreIcon(suggestion: IAppointmentSuggestion): boolean {
    return (
      suggestion.intersectingTags.length > 0 ||
      suggestion.schedulingConflicts.length > 0
    );
  }

  toggle(
    option: AppointmentSuggestionEntity,
    emit: boolean = true,
    change: MatCheckboxChange
  ): void {
    const value = change.checked ? option : undefined;
    this.selected$.next(value);
    if (emit) {
      this.selectedChange.emit(value);
    }
  }

  durationShorterThanTreatment$(
    suggestion: IAppointmentSuggestion
  ): Observable<boolean> {
    const duration = Event.duration(suggestion.event);
    return of(duration < this.treatmentDuration);
  }

  isSelected(
    selected: AppointmentSuggestionEntity | undefined,
    option: AppointmentSuggestionEntity
  ): boolean {
    return selected?.uid === option.uid;
  }

  private _setSortingAttributes(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (
      data: IAppointmentSuggestion,
      sortHeaderId: string
    ): string | number => {
      switch (sortHeaderId) {
        case 'time':
          return Number(toMoment(data.event.from).format('HHmmss'));
        case 'date':
          return Number(toMoment(data.event.from).format('X'));
        case 'distance':
          return Number(moment().diff(toMoment(data.event.from)));
        case 'duration':
          return Number(Event.duration(data.event)) + data.score;
        case 'score':
          return data.score;
        default:
          const value = data[sortHeaderId as keyof IAppointmentSuggestion];
          return _isNumberValue(value) ? Number(value) : String(value);
      }
    };
  }

  private _getDisplayedColumns(isSingleBrand: boolean): string[] {
    const columns = [
      'select',
      'date',
      'time',
      'distance',
      'duration',
      'score',
      'practitioner',
    ];
    return isSingleBrand ? columns : [...columns, 'brand'];
  }
}
