<div class="label-container">
  <ng-content select="label"></ng-content>
</div>
<div fxLayout="row" fxLayoutAlign="end center">
  <span class="other-content">
    <ng-content></ng-content>
  </span>
  <div class="icon-container">
    <ng-content select="mat-icon"></ng-content>
  </div>
</div>
