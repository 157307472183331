<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>General</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <pr-general-multi-treatment-configuration
        [multiTreatmentConfig]="multiTreatmentConfig$ | async"
      />
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Charting Options</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <pr-treatment-charting-options
        [treatmentConfig]="multiTreatmentConfig$ | async"
      />
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Conditions</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <pr-condition-selector
        [treatmentConfig]="multiTreatmentConfig$ | async"
      />
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Treatment Steps</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <pr-treatment-steps-configuration
        [multiTreatmentConfig]="multiTreatmentConfig$ | async"
      />
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Packages</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <pr-multi-treatment-packages
        [multiTreatmentConfig]="multiTreatmentConfig$ | async"
      />
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
