import {
  ConditionConfigurationName,
  ExistingTreatmentConfigurationName,
} from '@principle-theorem/principle-core';
import {
  SourceEntityMigrationType,
  ToothNumber,
  isToothNumber,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { flow, isNumber, isString } from 'lodash';
import {
  D4WToothSurface,
  TOOTH_SURFACES,
} from '../../../d4w/source/entities/patient-treatment';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
} from '../../../source/source-helpers';

export const PATIENT_CHARTED_ITEM_RESOURCE_TYPE = 'patientChartedItems';

export const PATIENT_CHARTED_ITEM_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Charted Item List',
      description: '',
      idPrefix: PATIENT_CHARTED_ITEM_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export enum CorePracticePatientChartedItemType {
  PreExistingTreatment = 0,
  Planned = 1, // diagnosis is null and will link to an ItemId and TreatmentId
  MissingTooth = 2,
}

export enum CorePracticeMissingToothTypeItems {
  MissingTooth = 6,
  UneruptedSuccedaneous = 8,
}

export enum CorePracticePreExistingChartedItem {
  Caries = 2,
  Watch = 3,
  CrackedTooth = 4,
  ChippedTooth = 5,
  SubErupted = 10,
  Unerupted = 11,
  Facet = 12,
  Composite = 13,
  Note = 14,
  Defective = 15,
  Unknown1 = 17,
  Unknown2 = 31,
}

export const CORE_PRACTICE_CONDITION_MAP: Record<
  CorePracticeMissingToothTypeItems | CorePracticePreExistingChartedItem,
  ExistingTreatmentConfigurationName | ConditionConfigurationName | undefined
> = {
  [CorePracticeMissingToothTypeItems.MissingTooth]:
    ConditionConfigurationName.MissingTooth,
  [CorePracticeMissingToothTypeItems.UneruptedSuccedaneous]:
    ConditionConfigurationName.UneruptedTooth,
  [CorePracticePreExistingChartedItem.Caries]:
    ConditionConfigurationName.Caries,
  [CorePracticePreExistingChartedItem.Watch]:
    ConditionConfigurationName.WatchReviewRequired,
  [CorePracticePreExistingChartedItem.CrackedTooth]:
    ConditionConfigurationName.Crack,
  [CorePracticePreExistingChartedItem.ChippedTooth]:
    ConditionConfigurationName.ChippedBrokenTooth,
  [CorePracticePreExistingChartedItem.SubErupted]:
    ConditionConfigurationName.PartiallyEruptedTooth,
  [CorePracticePreExistingChartedItem.Unerupted]:
    ConditionConfigurationName.UneruptedTooth,
  [CorePracticePreExistingChartedItem.Facet]: ConditionConfigurationName.Wear,
  [CorePracticePreExistingChartedItem.Composite]:
    ConditionConfigurationName.CompositeFilling,
  [CorePracticePreExistingChartedItem.Note]: ConditionConfigurationName.Other,
  [CorePracticePreExistingChartedItem.Defective]:
    ConditionConfigurationName.EnamelDefect,
  [CorePracticePreExistingChartedItem.Unknown1]: undefined,
  [CorePracticePreExistingChartedItem.Unknown2]: undefined,
};

export type CorePracticeToothCombinations =
  | {
      type: CorePracticePatientChartedItemType.PreExistingTreatment;
      diagnosis: CorePracticePreExistingChartedItem;
    }
  | {
      type: CorePracticePatientChartedItemType.Planned;
    }
  | {
      type: CorePracticePatientChartedItemType.MissingTooth;
      diagnosis: CorePracticeMissingToothTypeItems;
    };

export enum CorePracticePatientChartedItemDiagnosis {}

export interface ICorePracticePatientChartedItem {
  id: string; // F2FAD886-2BD3-4DE7-BC90-ED7093D6BD7C
  date: string; // 2021-12-24 06:28:25.043
  tooth?: ToothNumber; // "27"
  surfaces: D4WToothSurface[]; // "MODB" or "M,D,La,P"
  type: CorePracticePatientChartedItemType;
  diagnosis?:
    | CorePracticeMissingToothTypeItems
    | CorePracticePreExistingChartedItem;
  note?: string; // 21 Palatal rest is debonded and got decayed
  treatmentId?: number; // 129698
  itemId?: number;
  patientId: number;
  chartId: number; // Links to chartId of treatments
}

export function isCorePracticePatientChartedItem(
  item: unknown
): item is ICorePracticePatientChartedItem {
  return TypeGuard.interface<ICorePracticePatientChartedItem>({
    id: isString,
    date: isString,
    tooth: TypeGuard.nilOr(isToothNumber),
    surfaces: TypeGuard.arrayOf(TypeGuard.enumValue(D4WToothSurface)),
    note: TypeGuard.nilOr(isString),
    type: TypeGuard.enumValue(CorePracticePatientChartedItemType),
    diagnosis: TypeGuard.nilOr(
      TypeGuard.isOneOf(
        TypeGuard.enumValue(CorePracticeMissingToothTypeItems),
        TypeGuard.enumValue(CorePracticePreExistingChartedItem)
      )
    ),
    treatmentId: TypeGuard.nilOr(isNumber),
    itemId: TypeGuard.nilOr(isNumber),
    patientId: isNumber,
    chartId: isNumber,
  })(item);
}

export interface ICorePracticePatientChartedItemTranslations {}

export interface ICorePracticePatientChartedItemFilters {
  treatmentId?: number;
  itemId?: number;
  patientId: number;
  chartId: number;
}

const PATIENT_CHARTED_ITEM_SOURCE_QUERY = `
SELECT
  Identifier AS id,
  Date AS date,
  convert_to_text(Tooth) AS tooth,
  Surface AS surface,
  Note AS note,
  Type AS type,
  Diag AS diagnosis,
  TreatmentId AS treatment_id,
  ItemId AS item_id,
  PatientId AS patient_id,
  ChartId AS chart_id
FROM tblChartItem
`;

export class PatientChartedItemSourceEntity extends BaseSourceEntity<
  ICorePracticePatientChartedItem,
  ICorePracticePatientChartedItemTranslations,
  ICorePracticePatientChartedItemFilters
> {
  sourceEntity = PATIENT_CHARTED_ITEM_SOURCE_ENTITY;
  entityResourceType = PATIENT_CHARTED_ITEM_RESOURCE_TYPE;
  sourceQuery = PATIENT_CHARTED_ITEM_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientChartedItem;
  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    transformPatientTreatmentResults,
  ]);

  translate(
    _data: ICorePracticePatientChartedItem,
    _timezone: Timezone
  ): ICorePracticePatientChartedItemTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientChartedItem): string {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientChartedItem): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientChartedItem,
    _timezone: Timezone
  ): ICorePracticePatientChartedItemFilters {
    return {
      treatmentId: data.treatmentId,
      itemId: data.itemId,
      patientId: data.patientId,
      chartId: data.chartId,
    };
  }
}

export function convertToToothSurfaces(item: string): D4WToothSurface[] {
  if (!item) {
    return [];
  }
  const surfaces = item.match(/([A-Z][a-z]?)/g) ?? [];
  const allValidSurfaces = surfaces.every((surface) =>
    TOOTH_SURFACES.includes(surface)
  );
  if (!allValidSurfaces) {
    throw new Error(`Invalid tooth surfaces: ${surfaces.join(', ')}`);
  }
  return surfaces as D4WToothSurface[];
}

function transformPatientTreatmentResults(
  rows: (Omit<ICorePracticePatientChartedItem, 'surfaces'> & {
    surface: string;
  })[]
): ICorePracticePatientChartedItem[] {
  return rows.map((row) => {
    try {
      if (row.surface) {
        return {
          ...row,
          surfaces: convertToToothSurfaces(row.surface),
        };
      }
      return {
        ...row,
        surfaces: [],
      };
    } catch (error) {
      //
    }
    return {
      ...row,
      surfaces: [],
    };
  });
}
