import { FeatureVisibility, IFeature } from '@principle-theorem/feature-flags';

export enum TyroPermissions {
  TestServiceCodes = 'test-ada-codes.read',
  TestApiEndpoints = 'test-api-endpoints.read',
}

export const TYRO_TESTING_FEATURE: IFeature = {
  label: 'tyro-testing',
  visibility: FeatureVisibility.Dev,
  permissions: [
    {
      value: TyroPermissions.TestServiceCodes,
      description: 'Include item codes for testing Tyro',
    },
    {
      value: TyroPermissions.TestApiEndpoints,
      description: 'Allow terminals to be paired with development environments',
    },
  ],
};
