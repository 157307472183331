import { type AtLeast } from '@principle-theorem/shared';
import { type ITerminalRequestParams } from './tyro-callbacks';

interface IReconciliationReportBase {
  /**
   * The report type. Valid values are summary and detail.
   */
  type: 'summary' | 'detail';

  /**
   * The report format. Valid values are txt and xml. The
   * txt version is pre-formatted in a monospaced font.
   */
  format: 'txt' | 'xml';
}

export interface IReconciliationReportRequestParams
  extends AtLeast<ITerminalRequestParams, 'mid'>,
    IReconciliationReportBase {
  /**
   * The reporting date in format yyyyMMdd.
   */
  terminalBusinessDay: string;
}

export type BaseReconciliationReportResponse<T = string> =
  | {
      result: 'success';
      data: T;
    }
  | {
      result: 'failure';
      error: string;
    };

export type ReconciliationReportResponse = BaseReconciliationReportResponse &
  IReconciliationReportBase;
