<mat-form-field class="flex grow">
  <mat-label>{{ options | map: getLabel }}</mat-label>
  <input matInput [formControl]="momentControl" [matDatepicker]="picker" />
  <mat-hint>DD/MM/YYYY</mat-hint>
  <mat-datepicker-toggle
    matIconSuffix
    [for]="picker"
    [disabled]="momentControl.disabled"
  />
  <mat-datepicker #picker [disabled]="momentControl.disabled" />
</mat-form-field>
