import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  ITimePeriod,
  filterUndefined,
  firstValueFrom,
} from '@principle-theorem/shared';
import * as dc from 'dc';
import { ReportBuilderStore } from '../report-builder.store';
import { ResolvedColumns } from './report-builder-column-selector-dialog/resolved-columns';
import {
  IReportBuilderColumnsDialogRequest,
  IReportBuilderColumnsDialogResponse,
  ReportBuilderColumnsDialogComponent,
} from './report-builder-columns-dialog/report-builder-columns-dialog.component';
import { ReportBuilderCSV } from './report-builder-csv';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-report-builder-results-table',
    templateUrl: './report-builder-results-table.component.html',
    styleUrls: ['./report-builder-results-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ReportBuilderResultsTableComponent {
  csvData$: Observable<object[]>;
  csvTranslator$: Observable<ReportBuilderCSV>;
  dateRange$: Observable<ITimePeriod>;

  constructor(
    public store: ReportBuilderStore,
    private _dialog: MatDialog
  ) {
    this.csvData$ = this.store.results$.pipe(
      map((results) => results?.filtered ?? [])
    );
    this.csvTranslator$ = combineLatest([
      this.store.dataSource$.pipe(filterUndefined()),
      this.store.display$,
    ]).pipe(
      map(
        ([dataSource, display]) =>
          new ReportBuilderCSV(
            ResolvedColumns.toColumns(dataSource, display?.columns ?? [])
          )
      )
    );
  }

  resetFilters(): void {
    dc.filterAll();
    dc.renderAll();
  }

  async editColumns(): Promise<void> {
    const dataSource = await firstValueFrom(this.store.dataSource$);
    const display = await firstValueFrom(this.store.display$);
    if (!dataSource || !display) {
      return;
    }
    const data = {
      dataSource,
      columns: display?.columns ?? [],
    };

    const response = await this._dialog
      .open<
        ReportBuilderColumnsDialogComponent,
        IReportBuilderColumnsDialogRequest,
        IReportBuilderColumnsDialogResponse
      >(
        ReportBuilderColumnsDialogComponent,
        DialogPresets.large({ maxHeight: '80vh', data })
      )
      .afterClosed()
      .toPromise();

    if (!response) {
      return;
    }
    this.store.setDisplay({ ...display, columns: response.columns });
  }
}
