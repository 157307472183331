import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Storage } from '@angular/fire/storage';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { SplitCamelPipe, getDownloadURL$ } from '@principle-theorem/ng-shared';
import { IMedia } from '@principle-theorem/principle-core/interfaces';
import { WithRef, getEnumValues, titlecase } from '@principle-theorem/shared';
import { AppOptions, App as DwvApp, ToolConfig, ViewConfig } from 'dwv';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  of,
} from 'rxjs';
import { map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

enum Tool {
  Scroll = 'Scroll',
  ZoomAndPan = 'ZoomAndPan',
  WindowLevel = 'WindowLevel',
  Draw = 'Draw',
  ClearDrawings = 'ClearDrawings',
  RotateCCW = 'RotateCounterClockwise',
  RotateCW = 'RotateClockwise',
  Orientation = 'ToggleOrientation',
  Reset = 'Reset',
}

enum Orientation {
  Axial = 'axial',
  Coronal = 'coronal',
  Sagittal = 'sagittal',
}

@Component({
  selector: 'pr-dicom-viewer',
  templateUrl: './dicom-viewer.component.html',
  styleUrls: ['./dicom-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DicomViewerComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _media$ = new ReplaySubject<WithRef<IMedia>>(1);
  private _dicomViewer: DwvApp;
  private _orientation$ = new BehaviorSubject<Orientation>(Orientation.Axial);
  private _dwvCanvas$ = new ReplaySubject<HTMLCanvasElement>(1);
  private _rotation$ = new BehaviorSubject<number>(0);
  divId$: Observable<string>;
  loading$ = new BehaviorSubject<boolean>(true);
  hideToolbar$ = new BehaviorSubject<boolean>(false);
  canScroll$ = new BehaviorSubject<boolean>(false);
  cursorStyle$: Observable<string>;
  selectedTool$ = new BehaviorSubject<Tool>(Tool.Scroll);
  tools = getEnumValues(Tool);

  @Output() closeDialog = new EventEmitter<void>();

  @Input()
  set hideToolbar(hideToolbar: BooleanInput) {
    this.hideToolbar$.next(coerceBooleanProperty(hideToolbar));
  }

  @Input()
  set media(media: WithRef<IMedia>) {
    if (media) {
      this._media$.next(media);
    }
  }

  @ViewChild('dwvDiv', { read: ElementRef, static: false }) dwvDiv: ElementRef;

  constructor(
    private _storage: Storage,
    private _renderer: Renderer2
  ) {
    this._dicomViewer = new DwvApp();

    this.divId$ = combineLatest([this._media$, this.hideToolbar$]).pipe(
      switchMap(([media, hideToolbar]) => {
        return getDownloadURL$(this._storage, media).pipe(
          map((fileUrl) => {
            const divId = hideToolbar
              ? `layerGroup-${media.ref.id}-thumbnail`
              : `layerGroup-${media.ref.id}`;
            this._initDicomViewer(divId, fileUrl);
            return divId;
          })
        );
      })
    );

    this.cursorStyle$ = this.selectedTool$.pipe(
      map((tool) => {
        switch (tool) {
          case Tool.ZoomAndPan:
            return 'grab';
          case Tool.Scroll:
            return 'ew-resize';
          case Tool.Draw:
            return 'crosshair';
          case Tool.WindowLevel:
            return 'ns-resize';
          default:
            return 'auto';
        }
      })
    );

    this._rotation$
      .pipe(withLatestFrom(this._dwvCanvas$), takeUntil(this._onDestroy$))
      .subscribe(([rotation, canvas]) =>
        this._handleRotation(canvas, rotation)
      );
  }

  selectTool(event: MatButtonToggleChange): void {
    const tool = event.value as Tool;
    const resetSelection = (): void => {
      event.source.buttonToggleGroup.value = this.selectedTool$.value;
    };

    switch (tool) {
      case Tool.ClearDrawings:
        this._dicomViewer
          .getActiveLayerGroup()
          .getActiveDrawLayer()
          .deleteDraws(() => {
            //
          });
        resetSelection();
        return;
      case Tool.Orientation:
        this._toggleOrientation();
        resetSelection();
        return;
      case Tool.RotateCW:
        this._rotateClockwise();
        resetSelection();
        return;
      case Tool.RotateCCW:
        this._rotateCounterClockwise();
        resetSelection();
        return;
      case Tool.Reset:
        this._resetDicomViewer();
        resetSelection();
        return;
      default:
        this.selectedTool$.next(tool);
        this._dicomViewer.setTool(tool);

        if (tool === Tool.Draw) {
          this._dicomViewer.setToolFeatures({ shapeName: 'Ruler' });
        }
        return;
    }
  }

  getIcon(tool: Tool): string {
    const iconMap = {
      [Tool.Scroll]: 'layers',
      [Tool.Draw]: 'edit',
      [Tool.ClearDrawings]: 'edit_off',
      [Tool.ZoomAndPan]: 'zoom_in',
      [Tool.WindowLevel]: 'contrast',
      [Tool.RotateCW]: 'rotate_right',
      [Tool.RotateCCW]: 'rotate_left',
      [Tool.Orientation]: '3d_rotation',
      [Tool.Reset]: 'restart_alt',
    };
    return iconMap[tool];
  }

  getTooltip$(tool: Tool): Observable<string> {
    if (tool === Tool.Orientation) {
      return this._orientation$.pipe(
        map((orientation) => `Toggle Orientation (${titlecase(orientation)})`)
      );
    }
    const splitCamelPipe = new SplitCamelPipe();
    return of(splitCamelPipe.transform(tool));
  }

  isDisabledTool$(tool: Tool): Observable<boolean> {
    return this.canScroll$.pipe(
      map((canScroll) => tool === Tool.Scroll && !canScroll)
    );
  }

  ngOnDestroy(): void {
    this._dicomViewer.removeEventListener('load', this._loadHandler);
    this._dicomViewer.removeEventListener('keydown', this._keydownHandler);
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _rotateClockwise(): void {
    if (this._rotation$.value === 270) {
      this._rotation$.next(0);
      return;
    }
    this._rotation$.next(this._rotation$.value + 90);
  }

  private _rotateCounterClockwise(): void {
    if (this._rotation$.value === 0) {
      this._rotation$.next(270);
      return;
    }
    this._rotation$.next(this._rotation$.value - 90);
  }

  private _handleRotation(canvas: HTMLCanvasElement, rotation: number): void {
    this._getCanvasElement();
    const layerGroup = canvas.parentElement?.parentElement;
    if (!layerGroup) {
      return;
    }

    this._renderer.setStyle(canvas, 'transform', `rotate(${rotation}deg)`);
    this._renderer.setStyle(canvas, 'transform-origin', 'center');
    const isPortrait = rotation === 90 || rotation === 270;
    canvas.width = isPortrait
      ? layerGroup.clientHeight
      : layerGroup.clientWidth;
    canvas.height = isPortrait
      ? layerGroup.clientWidth
      : layerGroup.clientHeight;

    this._dicomViewer.getActiveLayerGroup().draw();
  }

  private _toggleOrientation(): void {
    const orientations = getEnumValues(Orientation);
    const currentIndex = orientations.indexOf(this._orientation$.value);
    if (currentIndex === -1) {
      return;
    }
    const nextIndex =
      currentIndex + 1 === orientations.length ? 0 : currentIndex + 1;
    this._orientation$.next(orientations[nextIndex]);
    this._resetDataViewConfig(this._orientation$.value);
  }

  private _loadHandler = (): void => {
    this._resetDataViewConfig();
    this.canScroll$.next(this._dicomViewer.canScroll());
    this.selectedTool$.next(
      this.canScroll$.value ? Tool.Scroll : Tool.ZoomAndPan
    );
    this._dicomViewer.setTool(this.selectedTool$.value);
    this.loading$.next(false);
  };

  private _getCanvasElement(): void {
    const element = this.dwvDiv.nativeElement as HTMLDivElement;
    const canvas = element.querySelector('canvas');
    if (canvas) {
      this._dwvCanvas$.next(canvas);
    }
  }

  private _resetDataViewConfig(orientation?: Orientation): void {
    const viewConfig = new ViewConfig(this._getDivId());
    viewConfig.orientation = orientation;
    this._dicomViewer.setDataViewConfigs({ '*': [viewConfig] });
    for (let i = 0; i < this._dicomViewer.getNumberOfLoadedData(); i++) {
      this._dicomViewer.render(i);
    }
    this._getCanvasElement();
  }

  private _keydownHandler = (event: KeyboardEvent): void => {
    this._dicomViewer.defaultOnKeydown(event);
  };

  private _resetDicomViewer(): void {
    this._rotation$.next(0);
    this._orientation$.next(Orientation.Sagittal);
    this._toggleOrientation();
    this._dicomViewer.getActiveLayerGroup().reset();
  }

  private _initDicomViewer(divId: string, fileUrl: string): void {
    const viewConfig = new ViewConfig(divId);
    const options = new AppOptions({
      '*': [viewConfig],
    });
    options.tools = {
      [Tool.Scroll]: new ToolConfig(),
      [Tool.WindowLevel]: new ToolConfig(),
      [Tool.ZoomAndPan]: new ToolConfig(),
      [Tool.Draw]: new ToolConfig(['Ruler']),
    };
    this._dicomViewer.init(options);
    this._dicomViewer.addEventListener('load', this._loadHandler);
    this._dicomViewer.addEventListener('keydown', this._keydownHandler);
    this._dicomViewer.loadURLs([fileUrl]);
  }

  private _getDivId(): string {
    const viewConfig = this._dicomViewer.getDataViewConfigs();
    return viewConfig['*'][0].divId;
  }
}
