<div class="flex flex-col">
  <div class="text-xs opacity-50">Preview</div>
  <div
    class="flex flex-row items-center justify-items-start gap-4 rounded-lg bg-slate-100 p-2"
  >
    <pr-patient-metadata-display
      [value]="coercedValue$ | async"
      [display]="display$ | async"
    />
    <mat-form-field subscriptSizing="dynamic" class="flex-auto">
      <mat-label>Preview Value</mat-label>
      <input matInput [formControl]="valueCtrl" />
    </mat-form-field>
  </div>
</div>
