import { rand, randBoolean } from '@ngneat/falso';
import {
  AREA_SURFACES,
  ChartableSurface,
  TOOTH_SURFACES,
} from '@principle-theorem/principle-core/interfaces';

export function MockChartableSurfaces(): ChartableSurface[] {
  if (randBoolean()) {
    return [rand(AREA_SURFACES)];
  }

  if (randBoolean()) {
    return TOOTH_SURFACES;
  }

  return TOOTH_SURFACES.filter(() => randBoolean());
}
