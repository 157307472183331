import { type Timestamp } from '@principle-theorem/shared';

export enum PaymentType {
  Cash = 'Cash',
  Card = 'Card',
  AccountCredit = 'Account Credit',
  Manual = 'Manual',
  PaymentPlan = 'Payment Plan',
}

export interface IPayment {
  uid: string;
  amount: number;
  paid: boolean;
  paymentType: PaymentType;
  paymentDate: Timestamp;
}

export const PAYMENT_TYPES: PaymentType[] = [
  PaymentType.Cash,
  PaymentType.Card,
  PaymentType.AccountCredit,
  PaymentType.Manual,
];
