import { Brand, stafferToNamedDoc } from '@principle-theorem/principle-core';
import {
  CustomMappingType,
  ITranslationMap,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  XSLXImporterExporter,
  asyncForEach,
  isSameRef,
  multiMap,
  multiSortBy$,
  nameSorter,
  snapshot,
  undeletedQuery,
  type INamedDocument,
  type IReffable,
  type WithRef,
  Firestore,
  IBlobFilenamePair,
} from '@principle-theorem/shared';
import { first, sortBy, uniqBy } from 'lodash';
import { BaseCustomMappingHandler } from '../base-custom-mapping-handler';
import { CustomMapping } from '../custom-mapping';
import { IBaseMigrationStaffer } from '../interfaces';
import { StafferToPractitionerToXSLX } from '../mappings/staffer-to-practitioner-to-xlsx';
import { XSLXToStafferToPractitioner } from '../mappings/xlsx-to-staffer-to-practitioner';
import { PracticeMigration } from '../practice-migrations';
import { BaseSourceEntity } from '../source/base-source-entity';
import { TranslationMapHandler } from '../translation-map';
import { from } from 'rxjs';
import Fuse from 'fuse.js';

export const STAFF_CUSTOM_MAPPING_TYPE = 'staffCustomMapping';

export const STAFFER_MAPPING = CustomMapping.init({
  metadata: {
    label: 'Staff',
    description: `Used for mapping Practitioners to the correct Staffer in Principle.`,
    type: STAFF_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.DocumentReference,
  labelOverrides: {
    sourceIdentifier: 'Id',
    sourceLabel: 'Staff Member',
    destinationIdentifier: 'Principle Staff Member',
  },
});

export abstract class StafferMappingHandler<
  Staffer extends object,
  SourceEntity extends BaseSourceEntity<Staffer>,
> extends BaseCustomMappingHandler<IStaffer> {
  customMapping = STAFFER_MAPPING;
  abstract translateFn: (record: Staffer) => IBaseMigrationStaffer;
  abstract sourceEntity: SourceEntity;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 1000)
      .toPromise();
    const options = records
      .map((record) => record.data.data)
      .map((record) => {
        const staffer = this.translateFn(record);
        return {
          label: `${staffer.name} ${staffer.providerNo}`,
          value: this.sourceEntity.getSourceRecordId(record).toString(),
        };
      });

    return sortBy(
      [
        ...options,
        {
          label: `Default Practitioner (When no practitioner is found)`,
          value: '0',
        },
      ],
      'label'
    );
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<IStaffer>[]> {
    return snapshot(
      from(
        Firestore.getDocs(
          undeletedQuery(Brand.stafferCol(migration.configuration.brand))
        )
      ).pipe(
        multiMap((staffer) => ({
          name: staffer.user.name,
          ref: staffer.ref,
        })),
        multiSortBy$(nameSorter())
      )
    );
  }

  async autocompleteMappings(
    migration: WithRef<IPracticeMigration>
  ): Promise<void> {
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const sourceOptions = await this.getSourceOptions(migration);
    const destinationOptions = await this.getDestinationOptions(migration);
    const records = await this.getRecords(translationMap);

    const mappedItemCodes = records
      .filter((record) => !!record.destinationIdentifier)
      .map((record) => record.sourceIdentifier);
    const unmappedItemCodes = sourceOptions.filter(
      (option) => !mappedItemCodes.includes(option.value)
    );

    await asyncForEach(unmappedItemCodes, async (item) => {
      const fuse = new Fuse(destinationOptions, {
        keys: ['name'],
        includeScore: true,
        threshold: 0.3,
      });
      const matches = fuse.search(item.label);
      const match = first(matches)?.item;

      if (!match) {
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: match.ref,
          destinationValue: match.name,
          sourceIdentifier: item.value,
          sourceLabel: item.label,
        },
        translationMap
      );
    });
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, appointmentStatuses, translator } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(
      fileName,
      appointmentStatuses,
      translator
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, appointmentStatuses, translator } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(
      fileName,
      appointmentStatuses,
      translator
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToStafferToPractitioner()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const sourceOptions = await this.getSourceOptions(migration);
    const staff = await snapshot(Brand.staff$(migration.configuration.brand));

    await asyncForEach(items, async (item) => {
      const matchingOption = sourceOptions.find(
        (sourceOption) => sourceOption.value === item.id
      );

      if (!matchingOption || !item.mapTo) {
        return;
      }

      const label = matchingOption.label;
      const value = matchingOption.value;

      const staffer = staff.find(
        (searchStaffer) => searchStaffer.user.name === item.mapTo
      );

      if (!staffer) {
        // eslint-disable-next-line no-console
        console.error(
          `Mapping error: ${this.customMapping.metadata.label} - Couldn't find staffer for item`,
          item
        );
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: staffer.ref,
          destinationValue: item.mapTo,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    appointmentStatuses: IBaseMigrationStaffer[];
    translator: StafferToPractitionerToXSLX;
  }> {
    const fileName = this.getFileName();
    const appointmentStatuses = await this._getUserOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const translator = new StafferToPractitionerToXSLX(
      await snapshot(Brand.staff$(migration.configuration.brand)),
      await this.getRecords(translationMap)
    );
    return { fileName, appointmentStatuses, translator };
  }

  private async _getUserOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<IBaseMigrationStaffer[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 10000)
      .toPromise();
    return sortBy(
      uniqBy(
        records.map((record) => this.translateFn(record.data.data)),
        (record) => record.id
      ),
      'name'
    );
  }
}

export function getPractitionerOrDefaultMapping(
  practitionerId: string | number | undefined,
  staffMap: WithRef<ITranslationMap<IStaffer>>[]
): ITranslationMap<IStaffer> | undefined {
  const defaultProviderMap = staffMap.find(
    (staffer) => staffer.sourceIdentifier === '0'
  );

  const providerId = practitionerId?.toString();
  const providerMap = staffMap.find(
    (staffer) => staffer.sourceIdentifier === providerId
  );

  if (!providerMap && !defaultProviderMap) {
    return;
  }

  return providerMap || defaultProviderMap;
}

export function getPractitionerOrDefault(
  practitionerId: string | number | undefined,
  staffMap: WithRef<ITranslationMap<IStaffer>>[],
  practitioners: WithRef<IStaffer>[]
): INamedDocument<IStaffer> | undefined {
  const practitionerMap = getPractitionerOrDefaultMapping(
    practitionerId,
    staffMap
  )?.destinationIdentifier;

  if (!practitionerMap) {
    return;
  }

  const practitioner = practitioners.find((searchPractitioner) =>
    isSameRef(searchPractitioner.ref, practitionerMap)
  );

  return practitioner ? stafferToNamedDoc(practitioner) : undefined;
}
