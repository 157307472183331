import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_ALERTS_RESOURCE_TYPE = 'patientAlerts';

export const PATIENT_ALERTS_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient Alerts List',
    description: '',
    idPrefix: PATIENT_ALERTS_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ICorePracticePatientAlert {
  id: number;
  description: string; // "Jaw locks if opened for long"
  startDateUtc: string; // 2021-11-27 07:09:00.237
  endDateUtc?: string; // 2021-11-27 07:09:00.237
  alertCategoryId: number;
  patientId: number;
  isDeleted: boolean;
}

export function isCorePracticePatientAlert(
  item: unknown
): item is ICorePracticePatientAlert {
  return TypeGuard.interface<ICorePracticePatientAlert>({
    id: isNumber,
    description: isString,
    startDateUtc: isString,
    endDateUtc: TypeGuard.nilOr(isString),
    alertCategoryId: isNumber,
    patientId: isNumber,
    isDeleted: isBoolean,
  })(item);
}

export interface ICorePracticePatientAlertTranslations {}

export interface ICorePracticePatientAlertFilters {
  alertCategoryId: number;
  patientId: number;
}

const PATIENT_ALERTS_SOURCE_QUERY = `
SELECT
  PatientAlertId AS id,
  Description AS description,
  StartDateUtc AS start_date_utc,
  EndDateUtc AS end_date_utc,
  AlertCategoryId AS alert_category_id,
  PatientId AS patient_id,
  convert_to_boolean(IsDeleted) AS is_deleted
FROM tblPatientAlert
`;

export class PatientAlertSourceEntity extends BaseSourceEntity<
  ICorePracticePatientAlert,
  ICorePracticePatientAlertTranslations,
  ICorePracticePatientAlertFilters
> {
  sourceEntity = PATIENT_ALERTS_SOURCE_ENTITY;
  entityResourceType = PATIENT_ALERTS_RESOURCE_TYPE;
  sourceQuery = PATIENT_ALERTS_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientAlert;

  translate(
    _data: ICorePracticePatientAlert,
    _timezone: Timezone
  ): ICorePracticePatientAlertTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientAlert): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientAlert): string {
    return `${data.id} - ${data.description}`;
  }

  getFilterData(
    data: ICorePracticePatientAlert,
    _timezone: Timezone
  ): ICorePracticePatientAlertFilters {
    return {
      alertCategoryId: data.alertCategoryId,
      patientId: data.patientId,
    };
  }
}
