import {
  CustomMappingOption,
  ITranslationMap,
  type ICustomMappingSourceOption,
} from '@principle-theorem/principle-core/interfaces';
import {
  type AtLeast,
  type IXSLXExport,
  type XSLXCell,
} from '@principle-theorem/shared';
import type { Column } from 'exceljs';
import { ItemCodeNoteType } from '../../../mappings/item-codes-to-notes-xlsx';

export interface IPatientNoteCategoryToNoteXSLX {
  sourceId: string;
  sourceName: string;
  mapTo: string;
}

export const PATIENT_NOTE_CATEGORY_TO_NOTE_HEADERS: AtLeast<
  Column,
  'key' | 'header'
>[] = [
  {
    key: 'sourceId',
    header: 'Id',
  },
  {
    key: 'sourceName',
    header: 'Patient Note Category',
    width: 45,
  },
  {
    key: 'mapTo',
    header: 'Map To',
    width: 30,
  },
];

export class PatientNoteCategoryToNoteToXSLX
  implements
    IXSLXExport<ICustomMappingSourceOption, IPatientNoteCategoryToNoteXSLX>
{
  headers = PATIENT_NOTE_CATEGORY_TO_NOTE_HEADERS;

  constructor(
    private _itemCodeNoteOptions: CustomMappingOption[],
    private _existingMappings: ITranslationMap<object, ItemCodeNoteType>[]
  ) {}

  translate(
    records: ICustomMappingSourceOption[]
  ): Record<keyof IPatientNoteCategoryToNoteXSLX, XSLXCell>[] {
    return records.map((record) => {
      const existingRecord = this._existingMappings.find(
        (existingMapping) => existingMapping.sourceIdentifier === record.value
      );

      const destinationIdentifier = existingRecord?.destinationValue;
      const mappedValue = destinationIdentifier
        ? this._itemCodeNoteOptions.find(
            (itemCodeNoteOption) =>
              itemCodeNoteOption.value === destinationIdentifier
          )
        : undefined;

      return {
        sourceId: {
          value: record.value,
        },
        sourceName: {
          value: record.label,
        },
        mapTo: {
          value: mappedValue?.description,
          dataValidation: {
            type: 'list',
            formulae: [
              `"${this._itemCodeNoteOptions
                .map((itemCodeNoteOption) => itemCodeNoteOption.description)
                .join(',')}"`,
            ],
          },
        },
      };
    });
  }
}
