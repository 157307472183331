<div class="flex flex-col gap-4">
  <div class="hidden font-bold print:flex">
    {{ (isProviderReceipt$ | async) ? 'Provider Copy' : 'Patient Copy' }}
  </div>

  <div *ngIf="serviceItemSummary$ | async as serviceItemSummary">
    <div class="flex flex-row gap-2 border-b border-solid border-slate-500">
      <div class="flex w-12 shrink-0 print:w-20">Pt</div>
      <div class="flex flex-auto">Service Item</div>
      <div class="flex w-12 shrink-0 print:w-20">DOS</div>
      <div class="flex w-12 shrink-0 print:w-20">Claim</div>
      <div class="flex w-12 shrink-0 print:w-20">Benefit</div>
      <div class="flex w-12 shrink-0 print:w-20">Gap</div>
    </div>

    <div
      *ngFor="let item of serviceItemSummary.items; trackBy: trackByItems"
      class="flex flex-row gap-2 font-bold"
    >
      <div class="flex w-12 shrink-0 print:w-20">{{ item.pt }}</div>
      <div class="flex flex-auto flex-col overflow-hidden">
        <div class="truncate">{{ item.serviceItem }}</div>
        <div *ngIf="item.note" class="truncate pl-4 font-normal">
          Note: {{ item.note }}
        </div>
      </div>
      <div class="flex w-12 shrink-0 print:w-20">
        {{ item.dateOfService }}
      </div>
      <div class="flex w-12 shrink-0 print:w-20">
        {{ item.claim | currency }}
      </div>
      <div class="flex w-12 shrink-0 print:w-20">
        {{ item.benefit | currency }}
      </div>
      <div class="flex w-12 shrink-0 print:w-20">
        {{ item.gap | currency }}
      </div>
    </div>

    <div class="flex flex-row gap-2 border-t border-solid border-slate-500">
      <div class="flex flex-auto">Service Totals</div>
      <div class="flex w-12 shrink-0 print:w-20">
        {{ serviceItemSummary.totalClaim | currency }}
      </div>
      <div class="flex w-12 shrink-0 print:w-20">
        {{ serviceItemSummary.totalBenefit | currency }}
      </div>
      <div class="flex w-12 shrink-0 print:w-20">
        {{ serviceItemSummary.totalGap | currency }}
      </div>
    </div>
  </div>

  <div
    class="flex flex-auto flex-col"
    *ngIf="claimSummary$ | async as claimSummary"
  >
    <div class="flex flex-col items-center">Claim Summary</div>
    <div class="flex flex-row border border-solid border-slate-500">
      <div class="flex w-1/2 flex-col border-r border-solid border-slate-500">
        <div class="flex flex-row border-b border-solid border-slate-500 p-1">
          <div class="flex flex-auto">Provider Number</div>
          <div>{{ claimSummary.providerNumber }}</div>
        </div>
        <div class="flex flex-row border-b border-solid border-slate-500 p-1">
          <div class="flex flex-auto">Merchant Number</div>
          <div>{{ claimSummary.merchantNumber }}</div>
        </div>
        <div class="flex flex-row border-b border-solid border-slate-500 p-1">
          <div class="flex flex-auto">Health Fund</div>
          <div>{{ claimSummary.healthFund }}</div>
        </div>
        <div class="flex flex-row border-b border-solid border-slate-500 p-1">
          <div class="flex flex-auto">Card Number</div>
          <div>{{ claimSummary.cardNumber }}</div>
        </div>
        <div class="flex flex-row border-b border-solid border-slate-500 p-1">
          <div class="flex flex-auto">Membership Number</div>
          <div>{{ claimSummary.membershipNumber }}</div>
        </div>
        <div class="flex flex-row p-1">
          <div class="flex flex-auto">RRN</div>
          <div>{{ claimSummary.rrn }}</div>
        </div>
      </div>
      <div class="flex w-1/2 flex-col">
        <div class="flex flex-row border-b border-solid border-slate-500 p-1">
          <div class="flex flex-auto">Date/ Time</div>
          <div>{{ claimSummary.dateTime | amDateFormat: dateFormat }}</div>
        </div>
        <div class="flex flex-row border-b border-solid border-slate-500 p-1">
          <div class="flex flex-auto">Terminal Number</div>
          <div>{{ claimSummary.terminalNumber }}</div>
        </div>
        <div class="flex flex-row border-b border-solid border-slate-500 p-1">
          <div class="flex flex-auto">Response Code</div>
          <div>{{ claimSummary.responseCode }}</div>
        </div>
        <div class="flex flex-row border-b border-solid border-slate-500 p-1">
          <div class="flex flex-auto">Total Claim</div>
          <div>{{ claimSummary.totalClaim | currency }}</div>
        </div>
        <div class="flex flex-row border-b border-solid border-slate-500 p-1">
          <div class="flex flex-auto">Total Benefit</div>
          <div>{{ claimSummary.totalBenefit | currency }}</div>
        </div>
        <div class="flex flex-row p-1">
          <div class="flex flex-auto">Total Gap</div>
          <div>{{ claimSummary.totalGap | currency }}</div>
        </div>
      </div>
    </div>
  </div>

  <table *ngIf="isProviderReceipt$ | async" class="table-auto">
    <tbody class="border border-solid border-slate-500">
      <tr class="border-b border-solid border-slate-500">
        <td
          class="w-1/3 border-r border-solid border-slate-500 bg-slate-500/20 p-1"
        ></td>
        <td class="w-2/3 p-1">
          <ng-container
            *ngIf="isClaimCancel$ | async; else declarationText"
            [ngTemplateOutlet]="cancelClaimText"
          />
        </td>
      </tr>
      <tr>
        <td class="w-1/3 border-r border-solid border-slate-500 p-1">
          Members Signature
        </td>
        <td class="w-2/3 p-1"></td>
      </tr>
    </tbody>
  </table>

  <div class="hidden print:block">
    Printed - {{ today | moment | amDateFormat: yearFormat }}
  </div>

  <div class="hidden flex-col items-center gap-1 print:flex">
    <div class="w-1/3">
      <img [src]="hicapsLogoUrl" class="w-full grayscale" />
    </div>
    <div>Fast claims… on the spot</div>
  </div>
</div>

<ng-template #declarationText>
  <div class="flex flex-col gap-1">
    <div class="font-bold">IMPORTANT DECLARATION</div>
    <div>
      I certify the above products and services have been provided and received
      by the person(s) listed above on the specified dates, and
    </div>
    <div class="flex flex-row">
      <div class="flex w-6 shrink-0">a&rpar;</div>
      <div class="flex flex-auto">
        This claim is not subject to any other form of compensation or
        reimbursement including Medicare Australia; and
      </div>
    </div>
    <div class="flex flex-row">
      <div class="flex w-6 shrink-0">b&rpar;</div>
      <div class="flex flex-auto">
        This claim does not knowingly breach Fund rules; and
      </div>
    </div>
    <div class="flex flex-row">
      <div class="flex w-6 shrink-0">c&rpar;</div>
      <div class="flex flex-auto">
        Where this claim is for optical or orthotic appliances, it is based on a
        relevant clinical prescription.
      </div>
    </div>
    <div>
      I authorise the Fund to pay proceeds direct to the provider and to contact
      the provider or myself to verify any aspect of this claim.
    </div>
  </div>
</ng-template>

<ng-template #cancelClaimText>
  <div class="text-2xl font-bold">CLAIM CANCELLATION</div>
</ng-template>
