import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CurrentPracticeScope } from '@principle-theorem/ng-principle-shared';
import {
  DialogPresets,
  type IBreadcrumb,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { Roster } from '@principle-theorem/principle-core';
import {
  type IPractice,
  type IRosterTime,
  type IScheduleRange,
} from '@principle-theorem/principle-core/interfaces';
import {
  type DayOfWeek,
  DAYS_OF_WEEK,
  doc$,
  filterUndefined,
  findProp,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EditPracticeOpeningHoursComponent } from './edit-practice-opening-hours/edit-practice-opening-hours.component';

@Component({
  selector: 'pr-practice-opening-hours',
  templateUrl: './practice-opening-hours.component.html',
  styleUrls: ['./practice-opening-hours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PracticeOpeningHoursComponent {
  trackByDay = TrackByFunctions.field<IDayOpeningHours>('dayOfWeek');
  practice$: Observable<WithRef<IPractice>>;
  breadcrumbs$: Observable<IBreadcrumb[]>;
  openingHoursByDay$: Observable<IDayOpeningHours[]>;

  constructor(
    private _route: ActivatedRoute,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _currentPractice: CurrentPracticeScope
  ) {
    this.practice$ = this._route.data.pipe(
      findProp<WithRef<IPractice>>('practice'),
      filterUndefined(),
      switchMap((data) => doc$(data.ref))
    );

    this.breadcrumbs$ = this._currentPractice.doc$.pipe(
      filterUndefined(),
      map((practice) => [
        { label: 'Settings', path: '../../../' },
        { label: practice.name },
        { label: 'Opening Hours' },
      ])
    );

    this.openingHoursByDay$ = this.practice$.pipe(
      map((practice) => this._getOpeningHoursByDay(practice))
    );
  }

  async edit(dayOfWeek: DayOfWeek): Promise<void> {
    this._dialog.open(
      EditPracticeOpeningHoursComponent,
      DialogPresets.medium({
        data: {
          practice: await snapshot(this.practice$),
          dayOfWeek,
        },
      })
    );
  }

  async delete(dayOfWeek: DayOfWeek): Promise<void> {
    const practice = await snapshot(this.practice$);
    const days: IRosterTime[] = Roster.getByDay(practice.schedule, dayOfWeek);
    await patchDoc(practice.ref, {
      schedule: {
        ...practice.schedule,
        days: practice.schedule.days.filter((day) => !days.includes(day)),
      },
    });
    this._snackBar.open('Times updated');
  }

  private _getOpeningHoursByDay(
    practice: WithRef<IPractice>
  ): IDayOpeningHours[] {
    return DAYS_OF_WEEK.map((dayOfWeek) => ({
      dayOfWeek,
      openingTimes: Roster.getOpeningTimes(practice.schedule, dayOfWeek),
    }));
  }
}

interface IDayOpeningHours {
  dayOfWeek: DayOfWeek;
  openingTimes: IScheduleRange | undefined;
}
