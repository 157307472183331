<pr-page-splash>
  <ng-container *ngIf="mode$ | async as mode">
    <div fxLayout="column" fxLayoutAlign="start center">
      <ng-container [ngSwitch]="mode">
        <ng-container *ngSwitchCase="'resetPassword'">
          <div *ngIf="!isSubmitting; else loader">
            <ng-container *ngIf="userEmail$ | async as userEmail">
              <pr-password-form
                (submitted)="resetPassword($event)"
                #resetPasswordForm="prPasswordForm"
              >
                <h2>Reset password for {{ userEmail }}</h2>
              </pr-password-form>
              <button
                mat-flat-button
                color="primary"
                [disabled]="resetPasswordForm.form.invalid"
                (click)="resetPassword(resetPasswordForm.form.value)"
              >
                Submit
              </button>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'signIn'">
          <div
            *ngIf="!isSubmitting; else loader"
            fxLayout="column"
            fxLayoutAlign="start center"
            fxLayoutGap="16px"
          >
            <ng-container *ngIf="isNewUser; else verifyEmail">
              <pr-password-form
                (submitted)="setPassword($event)"
                #newUserPasswordForm="prPasswordForm"
              >
                <h2>Please set a password</h2>
              </pr-password-form>
              <button
                mat-flat-button
                color="primary"
                [disabled]="newUserPasswordForm.form.invalid"
                (click)="setPassword(newUserPasswordForm.form.value)"
              >
                Submit
              </button>
            </ng-container>
            <ng-template #verifyEmail>
              <h2>Please verify your email</h2>
              <mat-form-field>
                <mat-label>Email</mat-label>
                <input
                  matInput
                  type="email"
                  [formControl]="emailControl"
                  [required]="true"
                />
              </mat-form-field>
              <button
                mat-flat-button
                color="primary"
                [disabled]="emailControl.invalid"
                (click)="signInWithEmailLink(emailControl.value)"
              >
                Submit
              </button>
            </ng-template>
          </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <ng-container *ngTemplateOutlet="loader" />
        </ng-container>
      </ng-container>

      <ng-template #loader>
        <mat-spinner />
        <p>Activating Account</p>
      </ng-template>
    </div>
  </ng-container>
</pr-page-splash>
