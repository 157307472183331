<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="16px" class="wrapper">
  <h1 class="mat-headline-4">Staff</h1>

  <div fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutGap="16px">
      <pt-search-field fxFlex>
        <input
          matInput
          placeholder="Search"
          [formControl]="searchCtrl"
          #search
        />
      </pt-search-field>
    </div>

    <pr-empty-state *ngIf="emptyState$ | async" image="list" title="staff" />

    <ng-container *ngIf="staffFilter.results$ | async as staff">
      <mat-nav-list *ngIf="staff.length; else noSearchResults">
        <a
          *ngFor="let staffer of staff; trackBy: trackByTeam"
          mat-list-item
          [routerLink]="['./', staffer.ref.id]"
        >
          <span matListItemTitle>{{ staffer.user.name }}</span>
          <div matListItemMeta>
            <mat-icon>chevron_right</mat-icon>
          </div>
        </a>
      </mat-nav-list>
    </ng-container>
  </div>
</div>

<ng-template #noSearchResults>
  <pr-empty-state
    *ngIf="(emptyState$ | async) === false"
    image="search"
    title="search results"
    [searchInputValue]="search.value"
  />
</ng-template>
