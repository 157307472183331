import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DYNAMIC_COMPONENT_DATA } from '@principle-theorem/ng-shared';
import { IDixelFeatureService } from '../idixel-feature.service';
import { BridgeDeviceSelectorService } from '../../../bridge-device-selector-dialog/bridge-device-selector.service';
import { IIntegrationActionData } from '../../../../integration-action-data';
import { IDIXEL_INTEGRATION } from '@principle-theorem/principle-bridge-core';

@Component({
    selector: 'pr-idixel-open-patient',
    templateUrl: './idixel-open-patient.component.html',
    styleUrl: './idixel-open-patient.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class IDixelOpenPatientComponent {
  constructor(
    @Inject(DYNAMIC_COMPONENT_DATA)
    private _data: IIntegrationActionData,
    private _iDixel: IDixelFeatureService,
    private _bridgeDeviceSelector: BridgeDeviceSelectorService
  ) {}

  async openPatient(): Promise<void> {
    const deviceRef =
      await this._bridgeDeviceSelector.selectDevice(IDIXEL_INTEGRATION);
    if (!deviceRef) {
      return;
    }

    await this._iDixel.openPatient(this._data.patient, deviceRef);
  }
}
