import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  TypeGuard,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_IMAGE_RESOURCE_TYPE = 'patientImage';

export const PATIENT_IMAGE_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient Image List',
    description: '',
    idPrefix: PATIENT_IMAGE_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface IPraktikaPatientImage {
  created_at: string;
  patient_id: number;
  filename: string;
  md5: string;
  size_in_bytes: number;
}

export function isPraktikaPatientImage(
  item: unknown
): item is IPraktikaPatientImage {
  return TypeGuard.interface<IPraktikaPatientImage>({
    created_at: isString,
    patient_id: isNumber,
    filename: isString,
    md5: isString,
    size_in_bytes: isNumber,
  })(item);
}

export interface IPraktikaPatientImageTranslations {
  createdAt: Timestamp;
}

export interface IPraktikaPatientImageFilters {
  patientId: string;
  createdAt: Timestamp;
}

const PATIENT_IMAGE_SOURCE_QUERY = `
SELECT
  ipatientnumber AS patient_id,
  dtcreated AS created_at,
  vchmd5 AS md5,
  ifilesizebytes AS size_in_bytes,
  vchuserfilename AS filename
FROM patient_images
`;

export class PatientImageSourceEntity extends BaseSourceEntity<
  IPraktikaPatientImage,
  IPraktikaPatientImageTranslations,
  IPraktikaPatientImageFilters
> {
  sourceEntity = PATIENT_IMAGE_SOURCE_ENTITY;
  entityResourceType = PATIENT_IMAGE_RESOURCE_TYPE;
  sourceQuery = PATIENT_IMAGE_SOURCE_QUERY;
  verifySourceFn = isPraktikaPatientImage;
  override transformDataFn = flow([]);
  override dateFilterField: keyof IPraktikaPatientImageFilters = 'createdAt';

  translate(
    appointment: IPraktikaPatientImage,
    timezone: Timezone
  ): IPraktikaPatientImageTranslations {
    return {
      createdAt: toTimestamp(
        moment.tz(appointment.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getSourceRecordId(data: IPraktikaPatientImage): string {
    return `${data.patient_id}-${data.created_at}-${data.filename}`;
  }

  getSourceLabel(data: IPraktikaPatientImage): string {
    return this.getSourceRecordId(data);
  }

  getFilterData(
    data: IPraktikaPatientImage,
    timezone: Timezone
  ): IPraktikaPatientImageFilters {
    return {
      patientId: data.patient_id.toString(),
      createdAt: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }
}
