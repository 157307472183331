import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';

export interface IAddPublicKeyDialogData {
  publicKey: string;
}

@Component({
    selector: 'pr-add-public-key-dialog',
    templateUrl: './add-public-key-dialog.component.html',
    styleUrls: ['./add-public-key-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddPublicKeyDialogComponent {
  form = new TypedFormGroup<IAddPublicKeyDialogData>({
    publicKey: new TypedFormControl<string>(undefined, Validators.required),
  });

  constructor(
    private _dialogRef: MatDialogRef<
      AddPublicKeyDialogComponent,
      IAddPublicKeyDialogData
    >
  ) {}

  submit(): void {
    if (!this.form.valid) {
      return;
    }

    this._dialogRef.close(this.form.getRawValue());
  }
}
