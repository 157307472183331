import { type ValidatorFn } from '@angular/forms';
import {
  DayOfWeek,
  DAYS_OF_WEEK,
  getEnumValues,
} from '@principle-theorem/shared';
import { get } from 'lodash';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  type ISetOptionParams,
  TypedFormControl,
  TypedFormGroup,
} from './typed-form-group';

export type DayOfWeekFormData = Record<DayOfWeek, boolean>;

const atLeastOneRequiredMessage = 'At least one day of week should be selected';

export class DayOfWeekFormGroup extends TypedFormGroup<DayOfWeekFormData> {
  constructor(requireOne: boolean = false) {
    super(
      {
        monday: new TypedFormControl<boolean>(false),
        tuesday: new TypedFormControl<boolean>(false),
        wednesday: new TypedFormControl<boolean>(false),
        thursday: new TypedFormControl<boolean>(false),
        friday: new TypedFormControl<boolean>(false),
        saturday: new TypedFormControl<boolean>(false),
        sunday: new TypedFormControl<boolean>(false),
      },
      {
        validators: requireOne ? validateAtLeastOne() : undefined,
      }
    );
  }

  getErrorMessage$(): Observable<string | undefined> {
    return this.valueChanges.pipe(
      map(() =>
        this.hasError('atLeastOneRequired')
          ? atLeastOneRequiredMessage
          : undefined
      )
    );
  }

  /**
   * Turn selected days of week into array for pattern
   * @returns DayOfWeek[]
   */
  getDaysOfWeek(
    dayOfWeekGroup: DayOfWeekFormData = this.getRawValue()
  ): DayOfWeek[] {
    return getEnumValues(DayOfWeek).filter((dayOfWeek) => {
      const isSelected = get(dayOfWeekGroup, dayOfWeek, false);
      return isSelected;
    });
  }

  /**
   * Set selected days of week passing in the pattern's days of week
   * @param days DayOfWeek[]
   * @returns void
   */
  setSelectedDays(days: DayOfWeek[], options?: ISetOptionParams): void {
    days.forEach((day: DayOfWeek) => {
      this.controls[day].setValue(true, options);
    });
  }
}

function validateAtLeastOne(): ValidatorFn {
  return (control) => {
    const found = DAYS_OF_WEEK.find((key) => !!control.get(key)?.value);
    if (!found) {
      return {
        atLeastOneRequired: atLeastOneRequiredMessage,
      };
    }
    // eslint-disable-next-line no-null/no-null
    return null;
  };
}

export function getDaysOfWeekFromGroup(
  dayOfWeekGroup: DayOfWeekFormData
): DayOfWeek[] {
  return getEnumValues(DayOfWeek).filter((dayOfWeek) => {
    const isSelected = get(dayOfWeekGroup, dayOfWeek, false);
    return isSelected;
  });
}
