import {
  Component,
  ElementRef,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import {
  type ParseRule,
  type DOMOutputSpec,
  type Node as ProsemirrorNode,
} from '@tiptap/pm/model';
import { DomSanitizer, type SafeResourceUrl } from '@angular/platform-browser';
import { type IResizeEvent } from '../../../drag-resize/drag-resize.component';
import {
  EditorNode,
  EditorNodeComponent,
  type IDomParsing,
  type IHasUid,
  NodeAttribute,
} from '@principle-theorem/ng-prosemirror';
import { NodeGroup, BlockNodes } from '@principle-theorem/editor';
import { v4 as uuid } from 'uuid';

export interface IEditorVideoEmbedAttributes extends IHasUid {
  src: string;
  width: string;
  height: string;
}

@EditorNode({
  name: BlockNodes.VideoEmbed,
  group: NodeGroup.Block,
  inline: false,
  draggable: true,
  defining: true,
})
@Component({
  selector: 'pt-video-embed-node',
  templateUrl: './video-embed-node.component.html',
  styleUrls: ['./video-embed-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoEmbedNodeComponent
  extends EditorNodeComponent<IEditorVideoEmbedAttributes>
  implements IHasUid, IDomParsing, IEditorVideoEmbedAttributes
{
  private _src: string;
  embedUrl: SafeResourceUrl;

  @NodeAttribute()
  @Input()
  width = '640px';
  @NodeAttribute()
  @Input()
  height = '360px';
  @NodeAttribute()
  @Input()
  uid: string = uuid();

  constructor(private _domSanitiser: DomSanitizer, elementRef: ElementRef) {
    super(elementRef);
  }

  @NodeAttribute()
  @Input()
  set src(src: string) {
    this._src = src;
    this.embedUrl = this._domSanitiser.bypassSecurityTrustResourceUrl(src);
  }

  updateSize($event: IResizeEvent): void {
    this.width = $event.width;
    this.height = $event.height;
    this.update.next({
      src: this._src,
      width: $event.width,
      height: $event.height,
      uid: this.uid,
    });
  }

  override parseHTML(): ParseRule[] {
    return [
      {
        tag: 'iframe[src]',
        getAttrs: (dom) => {
          if (!(dom instanceof HTMLElement)) {
            return false;
          }

          const attrWidth = dom.style.width
            ? dom.style.width
            : dom.getAttribute('width');
          const attrHeight = dom.style.height
            ? dom.style.height
            : dom.getAttribute('height');

          return {
            src: dom.getAttribute('src'),
            width: attrWidth,
            height: attrHeight,
          };
        },
      },
    ];
  }

  renderHTML(node: ProsemirrorNode): DOMOutputSpec {
    return ['iframe', node.attrs ?? {}];
  }
}
