import { createAction, props } from '@ngrx/store';
import {
  type ITimePeriod,
  type SerialisedData,
} from '@principle-theorem/shared';
import { getResetAction } from '@principle-theorem/ng-shared';
import {
  CalendarUnit,
  CalendarView,
} from '@principle-theorem/principle-core/interfaces';

export enum CalendarActionTypes {
  SetCalendarUnit = '[Calendar] Set Unit',
  SetCalendarRange = '[Calendar] Set Range',
  PreviousRange = '[Calendar] Set Previous Range',
  NextRange = '[Calendar] Set Next Range',
  SetViewUnitAndRange = '[Calendar] Set View, Unit and Range Params',
  CancelAction = '[Calendar] Cancel Action',
  Reset = '[Calendar] Reset',
}

interface ISetCalendarUnitData {
  unit: CalendarUnit;
}

interface ISetCalendarRange {
  range: ITimePeriod;
}

type SetUnitAndRange = ISetCalendarUnitData & ISetCalendarRange;

export const setCalendarUnit = createAction(
  CalendarActionTypes.SetCalendarUnit,
  props<ISetCalendarUnitData>()
);

export const nextCalendarRange = createAction(CalendarActionTypes.NextRange);
export const previousCalendarRange = createAction(
  CalendarActionTypes.PreviousRange
);

export const setCalendarRange = createAction(
  CalendarActionTypes.SetCalendarRange,
  props<SerialisedData<ISetCalendarRange>>()
);

interface ISetViewUnitAndRange extends SetUnitAndRange {
  view: CalendarView;
}

export const setViewUnitAndRange = createAction(
  CalendarActionTypes.SetViewUnitAndRange,
  props<SerialisedData<ISetViewUnitAndRange>>()
);

export const resetCalendar = getResetAction(CalendarActionTypes.Reset);
