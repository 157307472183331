import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountingFunctionsService } from '@principle-theorem/ng-principle-accounting';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import { BasicDialogService } from '@principle-theorem/ng-shared';
import {
  Invoice,
  Practice,
  RefundHelpers,
  Smartpay,
} from '@principle-theorem/principle-core';
import {
  ISmartpayPurchaseTransactionExtendedData,
  SmartpayPurchaseRequestType,
  TransactionAction,
  TransactionProvider,
  isSmartpayTransactionResponse,
  type IInvoice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  Region,
  type DocumentReference,
  type WithRef,
  getError,
} from '@principle-theorem/shared';
import { of, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RefundDepositPromptService } from '../transaction-components/refund-deposit-prompt/refund-deposit-prompt.service';
import { TransactionAmountDialog } from '../transaction-components/transaction-amount-dialog/transaction-amount-dialog.service';
import { refundAvailableInfo } from '../transaction-helpers';
import {
  TransactionProviderType,
  type ITransactionProvider,
} from '../transaction-provider';
import { SelectSmartpayTerminalService } from './select-smartpay-terminal/select-smartpay-terminal.service';
import { SmartpayPurchaseBuilder } from './smartpay-purchase-builder.service';
import { SmartpayService } from './smartpay.service';

@Injectable()
export class SmartpayQRPurchaseTransactionProvider
  implements ITransactionProvider
{
  providerId = TransactionProvider.SmartpayQR;
  providerType = TransactionProviderType.Payment;
  providerRegions = [Region.NewZealand];

  isEnabled$: Observable<boolean>;

  constructor(
    private _smartpay: SmartpayService,
    private _currentScopeFacade: CurrentScopeFacade,
    private _selectTerminal: SelectSmartpayTerminalService,
    private _purchaseBuilder: SmartpayPurchaseBuilder,
    private _basicDialog: BasicDialogService,
    private _refundDepositPrompt: RefundDepositPromptService,
    private _accountFunctions: AccountingFunctionsService,
    private _snackbar: MatSnackBar,
    private _amountDialog: TransactionAmountDialog
  ) {
    this.isEnabled$ = this._currentScopeFacade.currentPractice$.pipe(
      map((practice) => Practice.isSmartpayEnabled(practice))
    );
  }

  canCapture$(invoice: WithRef<IInvoice>): Observable<boolean> {
    const canCapture =
      !Invoice.isPaid(invoice) && Invoice.canAddTransactions(invoice);
    return of(canCapture);
  }

  async capture(
    invoice: WithRef<IInvoice>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const formData = await this._purchaseBuilder.openQRPurchaseForm(invoice);
    if (!formData || formData.amount <= 0) {
      return;
    }

    const terminal = await this._selectTerminal.getTerminalData();
    if (!terminal) {
      return;
    }

    const request = this._purchaseBuilder.buildTransaction(
      invoice,
      formData,
      terminal.terminalData,
      SmartpayPurchaseRequestType.QRPurchase
    );

    try {
      const response = await this._smartpay.initiateTransaction(request);

      if (!response) {
        return;
      }

      if (!isSmartpayTransactionResponse(response)) {
        // eslint-disable-next-line no-console
        console.error('Invaid data for purchase response', response);
        await this._basicDialog.alert({
          title: 'Transaction Error',
          prompt:
            'Encountered an unknown error during transaction. Please try again.',
          submitLabel: 'Close',
        });
        return;
      }

      const transaction = await Smartpay.toPurchaseTransaction(
        TransactionProvider.SmartpayQR,
        invoice,
        terminal.practiceRef,
        request,
        response
      );

      return await this._accountFunctions.addTransactionToInvoice(
        invoice,
        transaction,
        TransactionAction.Add
      );
    } catch (error) {
      this._snackbar.open(getError(error), 'Dismiss', {
        duration: 10000,
      });
      return;
    }
  }

  canRefund$(invoice: WithRef<IInvoice>): Observable<boolean> {
    return RefundHelpers.isRefundable$(invoice);
  }

  refundInfo$(invoice: WithRef<IInvoice>): Observable<string[]> {
    return Invoice.transactions$(invoice).pipe(
      map((transactions) =>
        RefundHelpers.getTotalRefundRemaining(transactions)
      ),
      map((refundRemaining) => [refundAvailableInfo(refundRemaining)])
    );
  }

  async refundTransaction(
    invoice: WithRef<IInvoice>,
    transaction: WithRef<ITransaction<ISmartpayPurchaseTransactionExtendedData>>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const acceptedRefundAlert =
      await this._refundDepositPrompt.showRefundDepositAlert(invoice);
    if (!acceptedRefundAlert) {
      return;
    }
    const formData = await this._purchaseBuilder.openQRRefundForm({
      invoice,
      transaction,
      latestTransaction: transaction,
    });
    if (!formData || formData.amount <= 0) {
      return;
    }
    const terminal = await this._selectTerminal.getTerminalData();
    if (!terminal) {
      return;
    }

    const request = this._purchaseBuilder.buildTransaction(
      invoice,
      formData,
      terminal.terminalData,
      SmartpayPurchaseRequestType.QRRefund
    );

    try {
      const response = await this._smartpay.initiateTransaction(request);

      if (!response) {
        return;
      }

      const refundTransaction = await Smartpay.toPurchaseRefundTransaction(
        TransactionProvider.SmartpayQR,
        invoice,
        terminal.practiceRef,
        request,
        response,
        transaction
      );
      return await this._accountFunctions.addTransactionToInvoice(
        invoice,
        refundTransaction,
        TransactionAction.Refund
      );
    } catch (error) {
      this._snackbar.open(getError(error), 'Dismiss', {
        duration: 10000,
      });
      return;
    }
  }

  async refund(
    invoice: WithRef<IInvoice>,
    transaction?: WithRef<ITransaction>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    const acceptedRefundAlert =
      await this._refundDepositPrompt.showRefundDepositAlert(invoice);
    if (!acceptedRefundAlert) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }
    const formData = await this._amountDialog.openRefund(
      'Smartpay - QR Refund',
      invoice,
      transaction
    );
    if (!formData || formData.amount <= 0) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }

    const terminal = await this._selectTerminal.getTerminalData();
    if (!terminal) {
      this._snackbar.open('Transaction Cancelled');
      return;
    }

    const request = this._purchaseBuilder.buildTransaction(
      invoice,
      formData,
      terminal.terminalData,
      SmartpayPurchaseRequestType.QRRefund
    );

    try {
      const response = await this._smartpay.initiateTransaction(request);

      if (!response) {
        return;
      }

      const refundTransaction = await Smartpay.toPurchaseRefundTransaction(
        TransactionProvider.SmartpayQR,
        invoice,
        terminal.practiceRef,
        request,
        response,
        transaction as WithRef<
          ITransaction<ISmartpayPurchaseTransactionExtendedData>
        >
      );
      return await this._accountFunctions.addTransactionToInvoice(
        invoice,
        refundTransaction,
        TransactionAction.Refund
      );
    } catch (error) {
      this._snackbar.open(getError(error), 'Dismiss', {
        duration: 10000,
      });
      return;
    }
  }
}
