import {
  SourceEntityStatus,
  type IGetRecordResponse,
  type IPracticeMigration,
  type ISourceEntity,
  type ISourceEntityRecord,
  type ISourceSyncErrorData,
} from '@principle-theorem/principle-core/interfaces';
import {
  getError,
  isObject,
  isTimestamp,
  isWithRef,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { camelCase, isNull, isNumber, last, mapKeys, mapValues } from 'lodash';
import { EMPTY, from, of, type Observable, type OperatorFunction } from 'rxjs';
import { catchError, expand, map, withLatestFrom } from 'rxjs/operators';
import { runQuery } from './connection';

export function determineSourceDate(
  sourceEntity: WithRef<ISourceEntity>
): Timestamp | undefined {
  if (
    sourceEntity.status === SourceEntityStatus.Failed &&
    sourceEntity.resumeData &&
    isObject(sourceEntity.resumeData) &&
    'date' in sourceEntity.resumeData &&
    isTimestamp(sourceEntity.resumeData.date)
  ) {
    return sourceEntity.resumeData.date;
  }
}

export function determineSourceOffset(
  sourceEntity: WithRef<ISourceEntity>
): number {
  if (
    sourceEntity.status === SourceEntityStatus.Failed &&
    sourceEntity.resumeData &&
    isObject(sourceEntity.resumeData) &&
    'offset' in sourceEntity.resumeData &&
    isNumber(sourceEntity.resumeData.offset)
  ) {
    return sourceEntity.resumeData.offset;
  }
  return 0;
}

export function determineSourceLastRecord(
  sourceEntity: WithRef<ISourceEntity>
): WithRef<ISourceEntityRecord> | undefined {
  if (
    sourceEntity.status === SourceEntityStatus.Failed &&
    sourceEntity.resumeData &&
    isObject(sourceEntity.resumeData) &&
    'lastRecord' in sourceEntity.resumeData &&
    isWithRef<ISourceEntityRecord>(sourceEntity.resumeData.lastRecord)
  ) {
    return sourceEntity.resumeData.lastRecord;
  }
}

export function catchAndSetResumeData<T>(
  results$: Observable<IGetRecordResponse[]>
): OperatorFunction<T[], T[] | ISourceSyncErrorData> {
  return (source$) =>
    source$.pipe(
      catchError((error) =>
        of(undefined).pipe(
          withLatestFrom(results$),
          map(([_, results]) => ({
            resumeData: { lastRecord: last(results)?.record },
            errorMessage: getError(error),
          }))
        )
      )
    );
}

/**
 * DEPRECATED use runSourceQuery$ instead
 * @param queryFn
 * @param migration
 * @param limit
 * @param initialOffset
 * @param endOffset
 * @returns
 */
export function runSourceQueryWithFunction$<T>(
  queryFn: (
    offest: number,
    limit: number,
    migration: WithRef<IPracticeMigration>
  ) => Promise<T[]>,
  migration: WithRef<IPracticeMigration>,
  limit: number = 10000,
  initialOffset: number = 0,
  endOffset?: number
): Observable<T[] | ISourceSyncErrorData> {
  let offset = initialOffset;
  return from(queryFn(offset, limit, migration)).pipe(
    expand((data) => {
      offset += limit;
      const isPastOffset = endOffset ? offset > endOffset : false;
      if (isPastOffset || !data.length) {
        return EMPTY;
      }
      return queryFn(offset, limit, migration);
    }),
    catchError((error) =>
      of({
        resumeData: { offset: offset },
        errorMessage: getError(error),
      })
    )
  );
}

export const OFFSET_PLACEHOLDER = 'OFFSET_PLACEHOLDER';

export function runSourceQuery$<QueryResult, TransformResult = QueryResult>(
  sourceMigration: WithRef<IPracticeMigration>,
  queryString: string,
  transformRecords: (records: QueryResult[]) => TransformResult[] = (records) =>
    records as unknown as TransformResult[],
  limit: number = 10000,
  initialOffset: number = 0,
  endOffset?: number
): Observable<TransformResult[] | ISourceSyncErrorData> {
  let offset = initialOffset;
  if (limit === 0) {
    limit = 999999999;
  }

  if (endOffset && endOffset - initialOffset < limit) {
    limit = endOffset - initialOffset;
  }

  const queryFn = async (
    queryOffset: number,
    queryLimit: number,
    migration: WithRef<IPracticeMigration>
  ): Promise<TransformResult[]> => {
    const offsetStatement = `LIMIT ${queryLimit} OFFSET ${queryOffset}`;
    const query = queryString.includes(OFFSET_PLACEHOLDER)
      ? queryString.replace(OFFSET_PLACEHOLDER, offsetStatement)
      : `${queryString} ${offsetStatement};`;

    const response = await runQuery<QueryResult>(migration, query);
    return transformRecords(response.rows);
  };

  return from(queryFn(offset, limit, sourceMigration)).pipe(
    expand((data) => {
      if (endOffset && endOffset - offset < limit) {
        limit = endOffset - offset;
      }
      offset += limit;
      const isPastOffset = endOffset ? offset > endOffset : false;
      if (isPastOffset || !data.length) {
        return EMPTY;
      }
      return queryFn(offset, limit, sourceMigration);
    }),
    catchError((error) =>
      of({
        resumeData: { offset: offset },
        errorMessage: getError(error),
      })
    )
  );
}

export function convertKeysToCamelCaseFn<T extends object>(): (
  data: T[]
) => T[] {
  return (results: T[]): T[] =>
    results.map((result) =>
      mapKeys(result, (_, key) => camelCase(key.toString()))
    ) as unknown as T[];
}

export function convertNullToUndefinedFn<T extends object>(): (
  data: T[]
) => T[] {
  return (results: T[]): T[] =>
    results.map((result) =>
      mapValues(result, (value) => (isNull(value) ? undefined : value))
    ) as unknown as T[];
}

export function convertValueFn<T extends object, R>(
  translateFn: (value: unknown) => R,
  ...properties: (keyof T)[]
): (data: T[]) => T[] {
  return (results: T[]): T[] =>
    results.map((result) =>
      mapValues(result, (value, key) =>
        properties.includes(key as keyof T) ? translateFn(value) : value
      )
    ) as unknown as T[];
}
