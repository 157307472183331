import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { ICustomReportChart } from '@principle-theorem/principle-core/interfaces';
import { Subject } from 'rxjs';

export interface IReportBuilderTableNameDialogData {
  chart: ICustomReportChart;
}

export interface IReportBuildTableNameFormData {
  label: string;
}

@Component({
  selector: 'pr-report-builder-table-name-dialog',
  templateUrl: './report-builder-table-name-dialog.component.html',
  styleUrls: ['./report-builder-table-name-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportBuilderTableNameDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  form = new TypedFormGroup<IReportBuildTableNameFormData>({
    label: new TypedFormControl(),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IReportBuilderTableNameDialogData,
    public _dialogRef: MatDialogRef<
      ReportBuilderTableNameDialogComponent,
      IReportBuildTableNameFormData | undefined
    >
  ) {
    this.form.patchValue({
      label: data.chart.label,
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  submit(): void {
    const formValue = this.form.getRawValue();
    this._dialogRef.close({
      label: formValue.label,
    });
  }
}
