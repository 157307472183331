import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  Appointment,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  IAppointment,
  IChartedTreatment,
  ITreatmentPlan,
  ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, isPathChanged$ } from '@principle-theorem/shared';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-appointment-history-card-treatments',
  templateUrl: './appointment-history-card-treatments.component.html',
  styleUrls: ['./appointment-history-card-treatments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentHistoryCardTreatmentsComponent {
  trackByTreatment = TrackByFunctions.ref<IChartedTreatment>('config.ref');
  appointment$ = new ReplaySubject<WithRef<IAppointment>>(1);
  treatmentPlanLink$: Observable<string[]>;
  treatmentPlan$: Observable<WithRef<ITreatmentPlan>>;
  treatmentStep$: Observable<ITreatmentStep | undefined>;
  treatments$: Observable<IChartedTreatment[]>;
  total$: Observable<number>;

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  constructor() {
    this.treatmentPlan$ = this.appointment$.pipe(
      switchMap((appointment) => Appointment.treatmentPlan(appointment))
    );
    const patientRef$ = this.appointment$.pipe(
      map((appointment) => Appointment.patientRef(appointment))
    );

    this.treatmentStep$ = combineLatest([
      this.appointment$.pipe(isPathChanged$('ref.id')),
      this.treatmentPlan$,
    ]).pipe(
      switchMap(([appointment, treatmentPlan]) =>
        TreatmentPlan.findStepByAppointment$(treatmentPlan, appointment.ref)
      )
    );

    this.total$ = this.treatmentStep$.pipe(
      map((treatmentStep) =>
        treatmentStep ? TreatmentStep.price(treatmentStep) : 0
      )
    );

    this.treatments$ = this.treatmentStep$.pipe(
      map((treatmentStep) => (treatmentStep ? treatmentStep.treatments : []))
    );

    this.treatmentPlanLink$ = combineLatest([
      patientRef$,
      this.treatmentPlan$,
    ]).pipe(
      map(([patientRef, plan]) => [
        'patients',
        patientRef.id,
        'treatment-plans',
        plan.ref.id,
      ])
    );
  }
}
