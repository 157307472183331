import {
  type IPatient,
  type ISterilisationRecord,
  SterilisationStatus,
  SterilisationRecordCollection,
} from '@principle-theorem/principle-core/interfaces';
import {
  ArchivedDocument,
  AtLeast,
  CollectionReference,
  DocumentArchive,
  DocumentReference,
  IReffable,
  WithRef,
  isSameRef,
  multiSort,
  query$,
  subCollection,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';

export class SterilisationRecord {
  static init(
    data: AtLeast<ISterilisationRecord, 'data' | 'practice'>
  ): ISterilisationRecord {
    return {
      ...data,
      status: SterilisationStatus.Pending,
      deleted: false,
    };
  }

  static canBeLinked(
    record: ISterilisationRecord,
    patientRef?: DocumentReference<IPatient>
  ): boolean {
    if (record.appointment) {
      return false;
    }
    if (record.patient && patientRef) {
      return isSameRef(record.patient, patientRef);
    }
    return !record.patient && !record.appointment;
  }

  static archiveCol(
    record: IReffable<ISterilisationRecord>
  ): CollectionReference<ArchivedDocument<ISterilisationRecord>> {
    return subCollection<ArchivedDocument<ISterilisationRecord>>(
      record.ref,
      SterilisationRecordCollection.RecordHistory
    );
  }

  static editHistory$(
    cycle: IReffable<ISterilisationRecord>
  ): Observable<WithRef<ArchivedDocument<ISterilisationRecord>>[]> {
    return query$(SterilisationRecord.archiveCol(cycle)).pipe(
      multiSort((aRecord, bRecord) =>
        DocumentArchive.sortByArchivedAt(aRecord, bRecord)
      )
    );
  }
}
