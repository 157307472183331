import { PrincipleHicapsConnect } from '@principle-theorem/hicaps-connect';
import {
  HicapsConnectExtendedDataTypeGuard,
  IHealthFundCard,
  IMedicareCard,
  ITransaction,
  TransactionProvider,
  isPatientEClaimTransactionExtendedData,
} from '@principle-theorem/principle-core/interfaces';
import {
  isEasyclaimCompleteCallback,
  isHealthPointTransactionCompleteCallbackData,
  isHealthPointTransactionExtendedData,
} from '@principle-theorem/tyro';
import { compact, first, trim } from 'lodash';
import { TyroEasyclaimPayload } from '../integrations/tyro/tyro-easyclaim';
import { findHealthFundByCardNumber } from '../invoice/health-fund-providers';

export interface ITransactionCardDetails {
  healthFundCard?: IHealthFundCard;
  medicareCard?: IMedicareCard;
}

export class ExtractTransactionCardDetails {
  static extract(transaction: ITransaction): ITransactionCardDetails {
    return {
      healthFundCard: this.healthFundCard(transaction),
      medicareCard: this.medicareCard(transaction),
    };
  }

  static healthFundCard(
    transaction: ITransaction
  ): IHealthFundCard | undefined {
    switch (transaction.provider) {
      case TransactionProvider.TyroHealthPoint:
        return this.tyroHealthFundCard(transaction);
      case TransactionProvider.HicapsConnectHealthFund:
        return this.hicapsHealthFundCard(transaction);
      default:
        return;
    }
  }

  static medicareCard(transaction: ITransaction): IMedicareCard | undefined {
    switch (transaction.provider) {
      case TransactionProvider.TyroEasyClaimBulkBill:
      case TransactionProvider.TyroEasyClaimPartPaid:
      case TransactionProvider.TyroEasyClaimFullyPaid:
        return this.tyroMedicareCard(transaction);
      case TransactionProvider.HicapsConnectMedicare:
        return this.hicapsMedicareCard(transaction);
      default:
        return;
    }
  }

  static tyroHealthFundCard(
    transaction: ITransaction
  ): IHealthFundCard | undefined {
    if (
      !isHealthPointTransactionExtendedData(transaction.extendedData) ||
      !isHealthPointTransactionCompleteCallbackData(
        transaction.extendedData.response
      )
    ) {
      return;
    }
    const patientIds = compact(
      transaction.extendedData.response.healthpointClaimItems.map(
        (claimItem) => claimItem.patientId
      )
    );
    return {
      fundCode: transaction.extendedData.response.healthpointHealthFundName,
      membershipNumber: trim(
        transaction.extendedData.response.healthpointMemberNumber
      ), // '12345678'
      memberNumber: first(patientIds) ?? '', // '00'
    };
  }

  static tyroMedicareCard(
    transaction: ITransaction
  ): IMedicareCard | undefined {
    const extendedData = transaction.extendedData;
    if (
      !isPatientEClaimTransactionExtendedData(extendedData) ||
      !extendedData ||
      !extendedData.response ||
      !isEasyclaimCompleteCallback(extendedData.response)
    ) {
      return;
    }
    const claimDetails = TyroEasyclaimPayload.getMedicareDetails(
      extendedData.response.payload,
      transaction.provider === TransactionProvider.TyroEasyClaimBulkBill
    );
    if (!claimDetails) {
      return;
    }
    return {
      // expiryDate: '', // ??,
      number: claimDetails.medicareCardNum, // '12345678'
      subNumerate: claimDetails.subnumerate, // '00'
    };
  }

  static hicapsHealthFundCard(
    transaction: ITransaction
  ): IHealthFundCard | undefined {
    if (
      !HicapsConnectExtendedDataTypeGuard.isHealthFund(
        transaction.extendedData
      ) ||
      !transaction.extendedData.response ||
      !PrincipleHicapsConnect.hasSuccessResult(
        transaction.extendedData.response
      )
    ) {
      return;
    }
    const cardNumber =
      transaction.extendedData.response.data.PrimaryAccountNumber;
    const healthFund = findHealthFundByCardNumber(cardNumber);
    return {
      fundCode: healthFund?.name,
      membershipNumber: transaction.extendedData.response.data.MembershipId, // '12345678'
      memberNumber: transaction.extendedData.request.MembershipId, // '00'
    };
  }

  static hicapsMedicareCard(
    transaction: ITransaction
  ): IMedicareCard | undefined {
    if (
      !HicapsConnectExtendedDataTypeGuard.isMedicareClaim(
        transaction.extendedData
      ) ||
      !transaction.extendedData.response ||
      !PrincipleHicapsConnect.hasSuccessResult(
        transaction.extendedData.response
      )
    ) {
      return;
    }
    return {
      // expiryDate: '', // ??,
      number: transaction.extendedData.response.data.PatientMedicareCardNum, // '12345678'
      subNumerate: transaction.extendedData.response.data.PatientIRN, // '00'
    };
  }
}
