import {
  JSONSerialisable,
  Timestamp,
  isEnumValue,
  isObject,
  isTimestamp,
} from '@principle-theorem/shared';
import { isString } from 'lodash';

export enum SourceEntityRecordStatus {
  Valid = 'valid',
  Invalid = 'invalid',
}

export const SOURCE_ENTITY_RECORD_STATUS_MAP: {
  [key in SourceEntityRecordStatus]: string;
} = {
  [SourceEntityRecordStatus.Valid]: 'primary',
  [SourceEntityRecordStatus.Invalid]: 'warn',
};

export enum SourceEntityRecordCollection {
  Data = 'data',
}

export const DEFAULT_DATA_UID = 'default';
export const MODIFIED_DATA_UID = 'modified';

export interface ISourceEntityRecordData<T = unknown, R = unknown>
  extends JSONSerialisable<T> {
  uid: string;
  translations: R;
}

export interface ISourceEntityRecordFile<R = unknown> {
  uid: string;
  url: string;
  type: 'file';
  translations: R;
}

export interface ISourceEntityRecord<Filters extends object = object> {
  uid: string;
  label: string;
  lastSync: Timestamp;
  status: SourceEntityRecordStatus;
  filters: Filters;
  migrationDestinations?: {
    [key: string]: SourceEntityRecordMigrationStatus;
  };
}

export enum SourceEntityRecordMigrationStatus {
  Migrated = 'migrated',
  NotMigrated = 'notMigrated',
}

export function isSourceEntityRecord(
  item: unknown
): item is ISourceEntityRecord {
  return (
    isObject(item) &&
    isString(item.uid) &&
    isString(item.label) &&
    'data' in item &&
    'lastSync' in item &&
    isTimestamp(item.lastSync) &&
    'status' in item &&
    isEnumValue(SourceEntityRecordStatus, item.status)
  );
}
