<mat-toolbar color="accent">Appointment Offer</mat-toolbar>

<mat-dialog-content>
  <div fxLayout="row">
    <mat-form-field fxFlex>
      <pr-content-editor
        [formControl]="noteControl"
        [menuEnabled]="false"
        (keydown.meta.Enter)="send()"
       />
    </mat-form-field>
  </div>

  <ng-container *ngIf="patient$ | async as patient">
    <h2>Send To</h2>
    <div *ngIf="patient.email" fxLayout="row" fxLayoutAlign="start start">
      <mat-checkbox color="primary" [(ngModel)]="sendEmail" />
      <p class="contact">{{ patient.email }}</p>
    </div>
    <div
      *ngIf="phone$ | async as phone"
      fxLayout="row"
      fxLayoutAlign="start start"
    >
      <mat-checkbox color="primary" [(ngModel)]="sendSms" />
      <p class="contact">{{ phone }}</p>
    </div>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button (click)="dialogRef.close()">Close</button>
    <button mat-flat-button color="primary" (click)="send()">Send</button>
  </pt-buttons-container>
</mat-dialog-actions>
