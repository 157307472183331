import { HicapsConnectReceiptType } from '@principle-theorem/hicaps-connect';
import {
  AnyHicapsConnectExtendedData,
  IHicapsConnectHealthFundTransactionExtendedData,
} from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  ITransactionAction,
  ITransactionActionsData,
} from '../../../transaction-action';
import { HicapsConnectReceipt } from '../../hicaps-connect-receipt/hicaps-connect-receipt';
import { HicapsConnectHealthFundTransactionProvider } from '../hicaps-connect-health-fund-transaction-provider.service';

export class PrintProviderReceiptHicapsConnectHealthfundTransaction
  implements ITransactionAction<AnyHicapsConnectExtendedData>
{
  icon = 'print';
  label = 'Print Provider Receipt';
  inProgress$ = new BehaviorSubject<boolean>(false);
  typeGuardFn = HicapsConnectReceipt.hasExtendedDataToShowReceipt;

  constructor(private _provider: HicapsConnectHealthFundTransactionProvider) {}

  canDo$(
    data: ITransactionActionsData<AnyHicapsConnectExtendedData>
  ): Observable<boolean> {
    const hasReceiptData = data.transaction.extendedData
      ? HicapsConnectReceipt.hasExtendedDataToShowReceipt(
          data.transaction.extendedData
        )
      : false;
    return of(hasReceiptData);
  }

  async do(
    data: ITransactionActionsData<IHicapsConnectHealthFundTransactionExtendedData>
  ): Promise<void> {
    this.inProgress$.next(true);
    await this._provider.printReceipt(
      data.transaction,
      HicapsConnectReceiptType.Provider
    );
    this.inProgress$.next(false);
  }
}
