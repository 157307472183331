import { NgModule } from '@angular/core';
import { GeneralTrackingCodeHelpComponent } from './general-tracking-code-help/general-tracking-code-help.component';
import { GoogleTagManagerTrackingCodeHelpComponent } from './google-tag-manager-tracking-code-help/google-tag-manager-tracking-code-help.component';
import { GoogleAnalyticsTrackingCodeHelpComponent } from './google-analytics-tracking-code-help/google-analytics-tracking-code-help.component';
import { HubSpotTrackingCodeHelpComponent } from './hub-spot-tracking-code-help/hub-spot-tracking-code-help.component';
import { MetaPixelTrackingCodeHelpComponent } from './meta-pixel-tracking-code-help/meta-pixel-tracking-code-help.component';
import { TrackingCodeHelpService } from './tracking-code-help.service';

@NgModule({
  imports: [
    GeneralTrackingCodeHelpComponent,
    GoogleTagManagerTrackingCodeHelpComponent,
    GoogleAnalyticsTrackingCodeHelpComponent,
    HubSpotTrackingCodeHelpComponent,
    MetaPixelTrackingCodeHelpComponent,
  ],
  exports: [
    GeneralTrackingCodeHelpComponent,
    GoogleTagManagerTrackingCodeHelpComponent,
    GoogleAnalyticsTrackingCodeHelpComponent,
    HubSpotTrackingCodeHelpComponent,
    MetaPixelTrackingCodeHelpComponent,
  ],
  providers: [TrackingCodeHelpService],
})
export class TrackingCodeHelpModule {}
