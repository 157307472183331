import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ISterilisationPack } from '@principle-theorem/principle-core/interfaces';
import { ReplaySubject } from 'rxjs';
import { WithRef } from '@principle-theorem/shared';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { EmptyStateComponent } from '@principle-theorem/ng-principle-shared';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'pr-sterilisation-packs-list',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    EmptyStateComponent,
  ],
  templateUrl: './sterilisation-packs-list.component.html',
  styleUrl: './sterilisation-packs-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SterilisationPacksListComponent {
  sterilisationPacks$ = new ReplaySubject<WithRef<ISterilisationPack>[]>(1);
  @Output() delete = new EventEmitter<WithRef<ISterilisationPack>>();
  @Output() edit = new EventEmitter<WithRef<ISterilisationPack>>();

  @Input()
  set sterilisationPacks(sterilisationPacks: WithRef<ISterilisationPack>[]) {
    if (sterilisationPacks) {
      this.sterilisationPacks$.next(sterilisationPacks);
    }
  }
}
