<div class="medical-history-page">
  <div class="mb-2 flex w-full justify-end">
    <button mat-button color="primary" (click)="copyMedicalHistoryFormLink()">
      <mat-icon>link</mat-icon>
      <span>Copy Patient Link</span>
    </button>
  </div>

  <pr-empty-state
    *ngIf="store.emptyState$ | async"
    image="list"
    title="Medical History Forms"
  >
    <a
      mat-flat-button
      color="primary"
      routerLink="update"
      class="layout-margin"
    >
      Update Medical History
    </a>
  </pr-empty-state>

  <pr-form-skeleton *ngIf="store.loading$ | async" />

  <ng-container *ngIf="store.allForms$ | async as forms">
    <ng-container *ngIf="forms.length">
      <div class="flex flex-row place-content-between items-center gap-4 pb-2">
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>History</mat-label>
          <mat-select [formControl]="formSelectCtrl" [compareWith]="compareFn">
            <mat-option
              *ngFor="let form of forms; trackBy: trackByForm"
              [value]="form"
            >
              {{ form.form.date | moment | amDateFormat: dateFormat }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="16px">
          <a mat-flat-button color="primary" routerLink="update">
            Update Medical History
          </a>
          <button
            mat-stroked-button
            (click)="printSelected(formSelectCtrl.value)"
            matTooltip="Print Selected Form"
          >
            <mat-icon>print</mat-icon> Print
          </button>
        </div>
      </div>

      <div class="medical-history-card mat-elevation-z1">
        <div fxFlex class="form-inspector">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="16px"
            class="layout-padding"
          >
            <small class="mat-caption">
              Medical History {{ formSelectCtrl.value.status | titlecase }}
              {{ formSelectCtrl.value.updatedAt | moment | amTimeAgo }}
            </small>
            <span fxFlex></span>
            <a
              *ngIf="(store.disabled$ | async) === false"
              mat-stroked-button
              color="primary"
              (click)="logHistoryCheck()"
            >
              Confirm History Checked
            </a>
          </div>
          <mat-divider />
          <pr-custom-form
            [form]="formSelectCtrl.value.form"
            [disabled]="true"
          />
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
