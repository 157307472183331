import { BlockNodes, type IMenuButton } from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../menu-bar/basic-menu-button/basic-menu-button.component';

const data: IMenuButton = {
  buttonType: 'submenu-detail',
  buttonText: 'Code Block',
  tooltip: 'Code Block',
  icon: 'code',
  command: (editor) => editor.chain().focus().toggleCodeBlock().run(),
  isActiveFn: (editor) => editor.isActive(BlockNodes.CodeBlock),
};

export function createCodeBlockMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data,
  });
}

export function createCodeBlockBlockMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      ...data,
      buttonType: 'block',
    },
  });
}
