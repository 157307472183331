import {
  IDestinationEntity,
  IDestinationEntityHandler,
  IDestinationHandler,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { D4WDestinationHandler } from '../d4w/destination';
import { DentrixDestinationHandler } from '../dentrix/destination';
import { ExactDestinationHandler } from '../exact/destination';
import { PraktikaDestinationHandler } from '../praktika/destination';
import { CorePracticeDestinationHandler } from '../core-practice/destination';

export const DESTINATION_HANDLERS: IDestinationHandler[] = [
  new CorePracticeDestinationHandler(),
  new D4WDestinationHandler(),
  new PraktikaDestinationHandler(),
  new ExactDestinationHandler(),
  new DentrixDestinationHandler(),
];

export function getDestinationHandler(
  migration: WithRef<IPracticeMigration>
): IDestinationHandler | undefined {
  return DESTINATION_HANDLERS.find((destinationHandler) => {
    if (
      destinationHandler.migrationType !== migration.metadata.name ||
      destinationHandler.migrationVersion !== migration.metadata.version
    ) {
      return false;
    }
    return destinationHandler.canHandle(migration.destination);
  });
}

export async function initialiseDestinationEntities(
  migration: WithRef<IPracticeMigration>
): Promise<void> {
  const handler = getDestinationHandler(migration);
  if (!handler) {
    return;
  }

  await handler.addEntities(migration);
}

export function getDestinationEntityHandler(
  migration: WithRef<IPracticeMigration>,
  destinationEntity: IDestinationEntity
): IDestinationEntityHandler | undefined {
  const destinationHandler = getDestinationHandler(migration);
  if (!destinationHandler) {
    return;
  }

  return destinationHandler.entityHandlers.find((entityHandler) =>
    entityHandler.canHandle(destinationEntity)
  );
}
