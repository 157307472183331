import { CdkDrag, CdkDragDrop } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  inject,
} from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import {
  AnyCustomFormElement,
  CUSTOM_FORM_ELEMENT_DESCRIPTION,
  CustomFormElementType,
  isCustomFormElementType,
} from '@principle-theorem/principle-core/interfaces';
import { values } from 'lodash';
import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { FormBuilderThumbnailComponent } from '../form-builder-thumbnail/form-builder-thumbnail.component';
import { FormBuilderService } from '../form-builder.service';
import { CustomFormElementBlueprint } from '../lib/custom-form-element-blueprints';
import { FormBuilderElementPreviewComponent } from '../form-builder-element-preview/form-builder-element-preview.component';

interface IToolbarElement {
  elementType: CustomFormElementType;
  tooltip: string;
  preview?: AnyCustomFormElement;
}

interface IToolbarDropListIds {
  add: string;
  remove: string;
}

function getToolbarDropListIds(uid: string): IToolbarDropListIds {
  return {
    add: `${uid}-add`,
    remove: `${uid}-remove`,
  };
}

@Component({
    selector: 'pr-form-builder-toolbar',
    templateUrl: './form-builder-toolbar.component.html',
    styleUrl: './form-builder-toolbar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        NgMaterialModule,
        NgSharedModule,
        FormBuilderThumbnailComponent,
        FormBuilderElementPreviewComponent,
    ]
})
export class FormBuilderToolbarComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  formBuilder = inject(FormBuilderService);
  readonly dropListIds: IToolbarDropListIds;
  toolbarElements: IToolbarElement[];
  availableElements: CustomFormElementType[] = [
    CustomFormElementType.Section,
    CustomFormElementType.Content,
    CustomFormElementType.Text,
    CustomFormElementType.Textarea,
    CustomFormElementType.Number,
    CustomFormElementType.Checkbox,
    CustomFormElementType.Date,
    CustomFormElementType.Dropdown,
    CustomFormElementType.Signature,
  ];
  @Output() deleteElement = new EventEmitter<AnyCustomFormElement>();

  constructor() {
    this.dropListIds = getToolbarDropListIds(uuid());
    this.formBuilder.registerDropLists(values(this.dropListIds));
    this.toolbarElements = [
      CustomFormElementType.Section,
      CustomFormElementType.Content,
      CustomFormElementType.Text,
      CustomFormElementType.Textarea,
      CustomFormElementType.Number,
      CustomFormElementType.Checkbox,
      CustomFormElementType.Date,
      CustomFormElementType.Dropdown,
      CustomFormElementType.Signature,
    ].map((elementType) => this._getToolbarNode(elementType));
  }

  ngOnDestroy(): void {
    this.formBuilder.unregisterDropLists(values(this.dropListIds));
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  denyAll(): boolean {
    return false;
  }

  onlyAllowLayoutComponents(
    drag: CdkDrag<AnyCustomFormElement | CustomFormElementType>
  ): boolean {
    return !isCustomFormElementType(drag.data);
  }

  dropDelete(
    event: CdkDragDrop<
      AnyCustomFormElement[],
      AnyCustomFormElement[],
      AnyCustomFormElement | CustomFormElementType
    >
  ): void {
    if (isCustomFormElementType(event.item.data)) {
      return;
    }
    this.formBuilder.removeElement(event.item.data.uid);
    this.deleteElement.emit(event.item.data);
  }

  private _getToolbarNode(elementType: CustomFormElementType): IToolbarElement {
    return {
      elementType,
      tooltip: CUSTOM_FORM_ELEMENT_DESCRIPTION[elementType],
      preview: CustomFormElementBlueprint.create(elementType),
    };
  }
}
