import { IStatisticMetrics, IStatistic } from './base-statistics';

export interface IPracticeStats extends IStatisticMetrics {
  // Aggegate Facts
  netIncome: IStatistic;
  grossIncome: IStatistic;
  activePatients: IStatistic;
  inactivePatients: IStatistic;
  revenueKPI: IStatistic;
  emptyChairTime: IStatistic;

  staffOnAnnualLeave: IStatistic;
  staffOnSickLeave: IStatistic;
  staffOnRosteredDaysOff: IStatistic;

  timeUsed: IStatistic;
  timeUnused: IStatistic;
  numberOfChairs: IStatistic;
  numberOfStaff: IStatistic;
  chairHoursAvailable: IStatistic;
  staffHoursAvailable: IStatistic;
  fullTimeEquivalentRatio: IStatistic;
  lostIncome: IStatistic;

  labExpenses: IStatistic;
  staffExpenses: IStatistic;
  consumables: IStatistic;
  facilityCosts: IStatistic;
  completedLabJobs: IStatistic;
  completedTasks: IStatistic;

  // Instantaneous Facts
  patientsInAppointment: IStatistic;

  // Expense Facts
  dentalDraw: IStatistic;
  totalExpenses: IStatistic;

  // Appointment Facts
  ftas: IStatistic;
  utas: IStatistic;
  newPatients: IStatistic;
  patientsSeen: IStatistic;
  appointments: IStatistic;
  rescheduleRate: IStatistic;
  existingPatients: IStatistic;
  cancellationRate: IStatistic;
  appointmentDuration: IStatistic;
  averagePatientSpend: IStatistic;
  completedAppointments: IStatistic;
  scheduledAppointments: IStatistic;
  cancelledAppointments: IStatistic;
  averagePatientWaitTime: IStatistic;
  unscheduledAppointments: IStatistic;
  revenueFromCompletedAppointments: IStatistic;

  // Invoice Facts
  neverPaidRate: IStatistic;
  paidOnCheckoutRate: IStatistic;
  overdueInvoicesCount: IStatistic;
  overdueInvoicesTotal: IStatistic;
  paidAfterCheckoutRate: IStatistic;
  writtenOffInvoicesTotal: IStatistic;
  revenueFromProductsSold: IStatistic;

  // Treatment Plan Facts
  caseAcceptanceRate: IStatistic;
  conversionRate: IStatistic;

  // Payment Plan Facts
  paymentPlansCount: IStatistic;
  paymentPlansTotal: IStatistic;
}
