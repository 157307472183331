import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CurrentBrandScope } from '@principle-theorem/ng-principle-shared';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IBreadcrumb,
  type IConfirmationDialogInput,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { Brand } from '@principle-theorem/principle-core';
import { type IReferralSourceConfiguration } from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  deleteDoc,
  filterUndefined,
  multiFilter,
  patchDoc,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  EditReferralSourceDialogComponent,
  type IEditReferrerDialogData,
} from './edit-referral-source-dialog/edit-referral-source-dialog.component';
import { ReferralSourcesStore } from './referral-sources.store';

@Component({
  selector: 'pr-referral-sources',
  templateUrl: './referral-sources.component.html',
  styleUrls: ['./referral-sources.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferralSourcesComponent {
  activeReferralSources$: Observable<WithRef<IReferralSourceConfiguration>[]>;
  deletedReferralSources$: Observable<WithRef<IReferralSourceConfiguration>[]>;
  breadcrumbs$: Observable<IBreadcrumb[]>;
  trackByReferralSource = TrackByFunctions.ref<IReferralSourceConfiguration>();

  constructor(
    public store: ReferralSourcesStore,
    private _brandScope: CurrentBrandScope,
    private _dialog: MatDialog
  ) {
    this.store.loadBrand(this._brandScope.doc$.pipe(filterUndefined()));
    this.breadcrumbs$ = this.store.brand$.pipe(
      map((brand) => [
        { label: 'Settings', path: '../../../' },
        { label: brand.name },
        { label: 'Referral Sources' },
      ])
    );
    this.activeReferralSources$ = this.store.referralSources$.pipe(
      multiFilter((referrer) => !referrer.deleted)
    );
    this.deletedReferralSources$ = this.store.referralSources$.pipe(
      multiFilter((referrer) => referrer.deleted)
    );
  }

  async upsertReferralSource(
    referrer?: WithRef<IReferralSourceConfiguration>
  ): Promise<void> {
    const change = await this._getReferrer(referrer);
    if (!change) {
      return;
    }
    const brand = await snapshot(this.store.brand$);
    if (referrer) {
      await patchDoc(referrer.ref, { ...change });
      return;
    }
    await addDoc(Brand.referralSourceCol(brand), change);
  }

  async delete(referrer: WithRef<IReferralSourceConfiguration>): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Referrer',
      prompt: 'Are you sure you want to delete this referrer?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }
    await deleteDoc(referrer.ref);
  }

  async restore(
    referrer: WithRef<IReferralSourceConfiguration>
  ): Promise<void> {
    await patchDoc(referrer.ref, { deleted: false });
  }

  private async _getReferrer(
    referrer?: WithRef<IReferralSourceConfiguration>
  ): Promise<IReferralSourceConfiguration | undefined> {
    return this._dialog
      .open<
        EditReferralSourceDialogComponent,
        IEditReferrerDialogData,
        IReferralSourceConfiguration | undefined
      >(
        EditReferralSourceDialogComponent,
        DialogPresets.small({ data: { referrer } })
      )
      .afterClosed()
      .toPromise();
  }
}
