import {
  type IMeasureMetadata,
  type MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import {
  IMeasureBuilderData,
  getFormatterDefaultValue,
  type CrossfilterGroup,
  type IChartConfig,
  type IDataBuilder,
} from '@principle-theorem/reporting';
import { isObject, reduceToSingleArray } from '@principle-theorem/shared';
import { type NaturallyOrderedValue } from 'crossfilter2';
import { compact, uniq, zip } from 'lodash';
import { CrossfilterDataTransformer } from './crossfilter-data-transformer';
import { type IDataTransformer } from './interfaces';

export interface ITableTransformerData {
  label: string;
  value: NaturallyOrderedValue;
  formatter?: MeasureFormatter;
  formatterValue?: IMeasureMetadata['formatterValue'];
}

export type MatTableRow = Record<string, ITableTransformerData>;

export class TableTransformer implements IDataTransformer<MatTableRow[]> {
  transformer: CrossfilterDataTransformer = new CrossfilterDataTransformer();

  async transform(
    config: IChartConfig,
    dataBuilder: IDataBuilder
  ): Promise<MatTableRow[]> {
    const measures = config.builderData.measures;

    const tableConfig: IChartConfig = { ...config };
    tableConfig.builderData.accumulateOverTime = false;
    tableConfig.builderData.plottedOverTime = false;

    const dataGroups = await this.transformer.transform(config, dataBuilder);
    return this._transformGroups(measures, dataGroups);
  }

  private _transformGroups(
    measures: IMeasureBuilderData[],
    dataGroups: CrossfilterGroup[]
  ): MatTableRow[] {
    const columns = measures.map((measure) => measure.measure.metadata);
    const rowIds = this._getRowIds(dataGroups);
    const allCells = zip(columns, dataGroups).reduce(
      (acc: ITableCell[], [column, group]) => [
        ...acc,
        ...this._getCells(rowIds, column, group),
      ],
      []
    );

    const columnIds = compact(uniq(allCells.map((cell) => cell.columnId)));
    const finalData = rowIds.map((rowId) =>
      this._buildRow(rowId, columnIds, allCells, columns)
    );
    return finalData;
  }

  private _getRowIds(groups: CrossfilterGroup[]): string[] {
    return compact(
      uniq(
        reduceToSingleArray(
          groups.map((value) => value.all().map((item) => item.key.label))
        )
      )
    );
  }

  private _getCells(
    rowIds: string[],
    column?: IMeasureMetadata,
    group?: CrossfilterGroup
  ): ITableCell[] {
    const values = group ? group.all() : [];

    if (!values.length) {
      return rowIds.map((rowId) => ({
        columnId: column?.label ?? '',
        rowId,
        formatter: column?.formatter,
        value: column?.formatter
          ? getFormatterDefaultValue(column?.formatter)
          : 0,
        formatterValue: column?.formatterValue,
      }));
    }

    return values.map((item) => ({
      columnId: column?.label ?? '',
      rowId: item.key.label,
      formatter: column?.formatter,
      formatterValue: column?.formatterValue,
      value: isObject(item.value) ? item.value.valueOf() : item.value,
    }));
  }

  private _buildRow(
    rowId: string,
    columnIds: string[],
    cells: ITableCell[],
    columns: IMeasureMetadata[]
  ): MatTableRow {
    const rowIdData: ITableTransformerData = {
      label: 'id',
      value: rowId,
    };
    const values = cells.filter((cell) => cell.rowId === rowId);
    return columnIds.reduce(
      (row: MatTableRow, columnId) => {
        const column = columns.find(
          (columnSearch) => columnSearch.label === columnId
        );

        const result = values.find((cell) => cell.columnId === columnId);
        const data: ITableTransformerData = {
          label: column?.label ?? '',
          value: result?.value
            ? result?.value
            : column?.formatter
              ? getFormatterDefaultValue(column.formatter)
              : '',
          formatter: column?.formatter,
          formatterValue: column?.formatterValue,
        };
        return { ...row, [columnId]: data };
      },
      { id: rowIdData }
    );
  }
}

interface ITableCell {
  columnId: string;
  rowId: string;
  value: NaturallyOrderedValue;
  formatter?: MeasureFormatter;
  formatterValue?: IMeasureMetadata['formatterValue'];
}
