import { createAction, props } from '@ngrx/store';
import { ChartActionTypes } from './action-types';
import { type IChartedRef } from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData } from '@principle-theorem/shared';
import { type IEntityAction } from '@principle-theorem/ng-shared';
import { type ChartId } from '../reducers/active-charts/chart-context-state';

export interface IChartSurfaceSelect extends IEntityAction<ChartId> {
  surface: Partial<IChartedRef>;
}

export const selectSurface = createAction(
  ChartActionTypes.SelectSurface,
  props<SerialisedData<IChartSurfaceSelect>>()
);

export const deselectSurface = createAction(
  ChartActionTypes.DeselectSurface,
  props<SerialisedData<IChartSurfaceSelect>>()
);

export interface ISetSelectedSurfaces extends IEntityAction<ChartId> {
  selected: Partial<IChartedRef>[];
}

export const setSelectedSurfaces = createAction(
  ChartActionTypes.SetSelectedSurfaces,
  props<SerialisedData<ISetSelectedSurfaces>>()
);
