<ng-container *ngIf="moreButton; else noMore">
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu" [overlapTrigger]="false">
    <ng-container *ngFor="let button of buttons; trackBy: trackByAction">
      <span [matTooltip]="button.disabledMessage$ | async">
        <button
          mat-menu-item
          [disabled]="button.isDisabled$ | async"
          (click)="runAction(button.action)"
        >
          <mat-icon>{{ button.action.icon }}</mat-icon>
          {{ button.action.name }}
        </button>
      </span>
    </ng-container>
  </mat-menu>
</ng-container>

<ng-template #noMore>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <ng-container *ngFor="let button of buttons; trackBy: trackByAction">
      <span [matTooltip]="button.disabledMessage$ | async">
        <button
          mat-icon-button
          matTooltipShowDelay="500"
          [disabled]="button.isDisabled$ | async"
          [matTooltip]="button.action.name"
          (click)="runAction(button.action)"
        >
          <mat-icon>{{ button.action.icon }}</mat-icon>
        </button>
      </span>
    </ng-container>
  </div>
</ng-template>
