<div fxLayout="column">
  <ng-container *ngIf="loadingWorkspace; else workspaceSelect">
    <div fxLayout="column" fxLayoutAlign="start center">
      <mat-spinner />
      <p
        class="mat-body-2"
        *ngIf="workspaceService.workspace$ | async as workspaceValue"
      >
        Loading {{ workspaceValue }}
      </p>
    </div>
  </ng-container>

  <ng-template #workspaceSelect>
    <ng-container
      *ngIf="workspaces$ | async as workspaces; else loadingWorkspaces"
    >
      <ng-container *ngIf="workspaces.length; else noWorkspaces">
        <div fxLayout="column" fxLayoutAlign="start center">
          <ng-container #workspacesContainer />
        </div>
      </ng-container>
    </ng-container>

    <ng-template #loadingWorkspaces>
      <div fxLayout="column" fxLayoutAlign="start center">
        <mat-spinner />
        <p class="mat-body-2">Loading workspaces</p>
      </div>
    </ng-template>

    <ng-template #noWorkspaces>
      <div fxLayout="column" fxLayoutAlign="start center">
        <p>
          This email address isn't associated with any workspaces. Please
          contact your workspace admin.
        </p>
      </div>
    </ng-template>
  </ng-template>
</div>
