import { Inject, Injectable } from '@angular/core';
import type { IMedipassConfig } from '@medipass/partner-sdk';
import {
  type IIntegration,
  IntegrationType,
} from '@principle-theorem/integrations';
import {
  type IMedipassConfigProvider,
  isMedipassIntegrationData,
} from '@principle-theorem/ng-medipass';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { Organisation } from '@principle-theorem/principle-core';
import { type IOrganisation } from '@principle-theorem/principle-core/interfaces';
import { type WithRef, firstResult$ } from '@principle-theorem/shared';
import { where } from '@principle-theorem/shared';
import { type Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  type INgPrincipleConfig,
  NG_PRINCIPLE_CONFIG,
} from '../../../ng-principle-config';

@Injectable()
export class MedipassOrgConfigProvider implements IMedipassConfigProvider {
  medipassLogoUrl = 'assets/integrations/medipass.png';

  constructor(
    private _organisation: OrganisationService,
    @Inject(NG_PRINCIPLE_CONFIG) private _config: INgPrincipleConfig
  ) {}

  getConfig$(): Observable<IMedipassConfig | undefined> {
    return this._organisation.organisation$.pipe(
      switchMap((org) => this._findIntegration(org)),
      map((integration) => this._transformIntegrationData(integration))
    );
  }

  private _findIntegration(
    org?: WithRef<IOrganisation>
  ): Observable<IIntegration | undefined> {
    if (!org) {
      return of(undefined);
    }
    return firstResult$(
      Organisation.integrationCol({ ref: org.ref }),
      where('type', '==', IntegrationType.Medipass)
    );
  }

  private _transformIntegrationData(
    data?: IIntegration
  ): IMedipassConfig | undefined {
    if (!data || !isMedipassIntegrationData(data.data)) {
      return;
    }
    return {
      ...data.data,
      ...this._config.medipass,
    };
  }
}
