<mat-sidenav-container class="!bg-transparent" ngClass.gt-xs="large-toolbar">
  <mat-sidenav [fixedInViewport]="fixedInViewport" class="mat-elevation-z5">
    <pr-sidebar />
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="!bg-transparent"
    >
      <pr-principle-logo />
      <pr-toolbar-dropdown />
    </mat-toolbar>

    <div class="layout-padding">
      <div *ngIf="brands$ | async as brands; else loader" fxLayout="row">
        <div
          fxFlex="100"
          fxFlexOffset="0"
          fxFlex.gt-sm="50"
          fxFlexOffset.gt-sm="25"
        >
          <div fxLayout="column">
            <div
              *ngIf="brands.length; else noBrands"
              fxLayout="column"
              fxLayoutGap="16px"
            >
              <ng-container *ngFor="let brand of brands; trackBy: trackByBrand">
                <mat-card>
                  <mat-card-header>
                    <mat-card-title>Select a Practice</mat-card-title>
                  </mat-card-header>
                  <mat-card-content class="mt-2">
                    <div class="mb-2 text-lg">{{ brand.name }}</div>
                    <pr-practice-selector-card [brand]="brand" />
                  </mat-card-content>
                </mat-card>
              </ng-container>
            </div>

            <ng-template #noBrands>
              <ng-container
                *ngIf="canCreateBrand$ | async; else noBrandPermission"
              >
                <button mat-flat-button color="primary" (click)="addBrand()">
                  Add New Brand
                </button>
              </ng-container>

              <ng-template #noBrandPermission>
                <mat-card>
                  <mat-card-content class="mt-2">
                    <h2>No Practices available for your user</h2>
                  </mat-card-content>
                </mat-card>
              </ng-template>
            </ng-template>
          </div>
        </div>
      </div>

      <ng-template #loader>
        <mat-progress-bar mode="indeterminate" />
      </ng-template>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
