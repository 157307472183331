import { NgModule } from '@angular/core';
import {
  NgCustomFormsModule,
  PatientDetailsFormService,
} from '@principle-theorem/ng-custom-forms';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgLabsModule } from '@principle-theorem/ng-labs';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NgTasksModule } from '@principle-theorem/ng-tasks';
import { NgPrincipleAccountingModule } from '@principle-theorem/ng-principle-accounting';
import { CopyPatientIdButtonComponent } from './copy-patient-id-button/copy-patient-id-button.component';
import { CreatePatientDocumentDialogComponent } from './create-patient-document-dialog/create-patient-document-dialog.component';
import { DVACardDisplayComponent } from './dva-card-display/dva-card-display.component';
import { DVACardFormComponent } from './dva-card-form/dva-card-form.component';
import { EditDVACardComponent } from './edit-dva-card/edit-dva-card.component';
import { EditHealthFundCardComponent } from './edit-healthfund-card/edit-healthfund-card.component';
import { EditMedicareCardComponent } from './edit-medicare-card/edit-medicare-card.component';
import { EditPatientFormComponent } from './edit-patient-form/edit-patient-form.component';
import { HealthFundCardDisplayComponent } from './healthfund-card-display/healthfund-card-display.component';
import { MedicareCardDisplayComponent } from './medicare-card-display/medicare-card-display.component';
import { MedicareCardFormComponent } from './medicare-card-form/medicare-card-form.component';
import { PatientAddressComponent } from './patient-address/patient-address.component';
import { PatientConfirmAppointmentDetailsComponent } from './patient-confirm-appointment/patient-confirm-appointment-details/patient-confirm-appointment-details.component';
import { PatientConfirmAppointmentComponent } from './patient-confirm-appointment/patient-confirm-appointment.component';
import { PatientConfirmAppointmentService } from './patient-confirm-appointment/patient-confirm-appointment.service';
import { PatientDetailsDisplayComponent } from './patient-details-display/patient-details-display.component';
import { PatientDocumentPrintComponent } from './patient-document/patient-document-print/patient-document-print.component';
import { PatientDocumentComponent } from './patient-document/patient-document.component';
import { PatientDocumentsComponent } from './patient-documents/patient-documents.component';
import { PatientAgeComponent } from './patient-information/patient-age/patient-age.component';
import { PatientDateOfBirthComponent } from './patient-information/patient-date-of-birth/patient-date-of-birth.component';
import { PatientGenderComponent } from './patient-information/patient-gender/patient-gender.component';
import { PatientLastVisitComponent } from './patient-information/patient-last-visit/patient-last-visit.component';
import { PatientLabJobsComponent } from './patient-lab-jobs/patient-lab-jobs.component';
import { PatientMedicalAlertsComponent } from './patient-medical-alerts/patient-medical-alerts.component';
import { PatientMedicalFormWarningComponent } from './patient-medical-form-warning/patient-medical-form-warning.component';
import { PatientMedicalHistoryComponent } from './patient-medical-history/patient-medical-history.component';
import { PatientMedicalHistoryService } from './patient-medical-history/patient-medical-history.service';
import { PatientPhoneComponent } from './patient-phone/patient-phone.component';
import { PatientPrimaryContactFormComponent } from './patient-primary-contact-form/patient-primary-contact-form.component';
import { PatientReferrerComponent } from './patient-referrer/patient-referrer.component';
import { PatientRelationshipDialogComponent } from './patient-relationship-dialog/patient-relationship-dialog.component';
import { PatientRelationshipFormComponent } from './patient-relationship-form/patient-relationship-form.component';
import { PatientRelationshipListComponent } from './patient-relationship-list/patient-relationship-list.component';
import { PatientRelationshipsCardComponent } from './patient-relationships-card/patient-relationships-card.component';
import { PatientTasksComponent } from './patient-tasks/patient-tasks.component';
import { UpdatePatientComponent } from './update-patient/update-patient.component';
import { PatientInteractionsComponent } from './patient-interactions/patient-interactions.component';
import { PatientViewInvoiceComponent } from './patient-view-invoice/patient-view-invoice.component';
import { PatientViewInvoiceService } from './patient-view-invoice/patient-view-invoice.service';
import { PatientFeeScheduleComponent } from './patient-information/patient-fee-schedule/patient-fee-schedule.component';
import { PatientViewInvoiceTransactionComponent } from './patient-view-invoice/patient-view-invoice-transaction/patient-view-invoice-transaction.component';
import { MergePatientDialogComponent } from './merge-patient-dialog/merge-patient-dialog.component';
import { MergePatientButtonComponent } from './merge-patient-button/merge-patient-button.component';
import { DeletePatientButtonComponent } from './delete-patient-button/delete-patient-button.component';
import { PatientPrescriptionsListComponent } from './patient-prescriptions/patient-prescriptions-list/patient-prescriptions-list';
import { PatientPrescriptionComponent } from './patient-prescriptions/patient-prescription/patient-prescription.component';
import { PrescriptionItemFormComponent } from './patient-prescriptions/prescription-item-form/prescription-item-form.component';
import { PatientPrescriptionsComponent } from './patient-prescriptions/patient-prescriptions/patient-prescriptions.component';
import { PatientPrescriptionFormComponent } from './patient-prescriptions/patient-prescription-form/patient-prescription-form.component';

@NgModule({
  imports: [
    NgMaterialModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    NgInteractionsModule,
    NgLabsModule,
    NgTasksModule,
    NgCustomFormsModule,
    NgPrincipleAccountingModule,
    PatientPrescriptionsListComponent,
    PatientPrescriptionsComponent,
    PatientPrescriptionComponent,
    PrescriptionItemFormComponent,
    PatientPrescriptionFormComponent,
  ],
  declarations: [
    PatientMedicalFormWarningComponent,
    PatientConfirmAppointmentComponent,
    PatientConfirmAppointmentDetailsComponent,
    MedicareCardFormComponent,
    PatientDocumentsComponent,
    PatientDocumentComponent,
    PatientDocumentPrintComponent,
    CreatePatientDocumentDialogComponent,
    PatientAgeComponent,
    PatientDateOfBirthComponent,
    PatientGenderComponent,
    PatientLastVisitComponent,
    CopyPatientIdButtonComponent,
    UpdatePatientComponent,
    EditPatientFormComponent,
    PatientAddressComponent,
    DVACardFormComponent,
    PatientPhoneComponent,
    PatientRelationshipsCardComponent,
    PatientLabJobsComponent,
    PatientTasksComponent,
    PatientRelationshipListComponent,
    PatientRelationshipDialogComponent,
    EditMedicareCardComponent,
    EditHealthFundCardComponent,
    HealthFundCardDisplayComponent,
    MedicareCardDisplayComponent,
    DVACardDisplayComponent,
    EditDVACardComponent,
    PatientDetailsDisplayComponent,
    PatientRelationshipFormComponent,
    PatientMedicalAlertsComponent,
    PatientMedicalHistoryComponent,
    PatientReferrerComponent,
    PatientPrimaryContactFormComponent,
    PatientInteractionsComponent,
    PatientViewInvoiceComponent,
    PatientFeeScheduleComponent,
    PatientViewInvoiceTransactionComponent,
    MergePatientDialogComponent,
    MergePatientButtonComponent,
    DeletePatientButtonComponent,
  ],
  exports: [
    PatientMedicalFormWarningComponent,
    PatientConfirmAppointmentComponent,
    MedicareCardFormComponent,
    PatientDocumentsComponent,
    PatientDocumentComponent,
    PatientDocumentPrintComponent,
    CreatePatientDocumentDialogComponent,
    PatientAgeComponent,
    PatientDateOfBirthComponent,
    PatientGenderComponent,
    PatientLastVisitComponent,
    CopyPatientIdButtonComponent,
    EditPatientFormComponent,
    PatientAddressComponent,
    DVACardFormComponent,
    PatientPhoneComponent,
    PatientRelationshipsCardComponent,
    PatientLabJobsComponent,
    PatientTasksComponent,
    PatientRelationshipListComponent,
    PatientRelationshipDialogComponent,
    EditMedicareCardComponent,
    EditHealthFundCardComponent,
    HealthFundCardDisplayComponent,
    MedicareCardDisplayComponent,
    DVACardDisplayComponent,
    EditDVACardComponent,
    PatientDetailsDisplayComponent,
    PatientRelationshipFormComponent,
    PatientMedicalAlertsComponent,
    PatientReferrerComponent,
    PatientPrimaryContactFormComponent,
    PatientInteractionsComponent,
    PatientFeeScheduleComponent,
    MergePatientButtonComponent,
    DeletePatientButtonComponent,
    PatientPrescriptionsListComponent,
    PatientPrescriptionComponent,
    PrescriptionItemFormComponent,
    PatientPrescriptionFormComponent,
  ],
  providers: [
    PatientConfirmAppointmentService,
    PatientMedicalHistoryService,
    PatientDetailsFormService,
    PatientViewInvoiceService,
  ],
})
export class ComponentsModule {}
