<mat-toolbar color="accent">Amend Invoice</mat-toolbar>

<mat-dialog-content>
  <div class="flex flex-col gap-2">
    <pr-invoice-credit-summary
      #creditSummary
      [credits]="credits$ | async"
     />
    <p *ngIf="creditSummary.canAmend$ | async">
      Are you sure you want to amend this invoice?
    </p>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      [mat-dialog-close]="true"
      color="warn"
      cdkFocusInitial
      [disabled]="(creditSummary.canAmend$ | async) === false"
    >
      Amend
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
