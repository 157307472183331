<div class="flex flex-col gap-4">
  <form [formGroup]="settingsForm" (ngSubmit)="submit()" class="flex flex-row">
    <div class="flex flex-auto flex-col items-start gap-4">
      <mat-slide-toggle formControlName="isEnabled">
        Enable Hicaps
      </mat-slide-toggle>

      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>Merchant ID</mat-label>
        <input matInput formControlName="merchantId" />
      </mat-form-field>

      <div class="flex flex-row items-start gap-1">
        <mat-checkbox formControlName="addCDBSAsPending">
          Add CDBS transactions as "pending" status
        </mat-checkbox>
        <mat-icon
          class="cdbs-tooltip"
          matTooltip="This will require CDBS claims to be reconciled from the pending transactions workflow."
          >info</mat-icon
        >
      </div>

      <button mat-flat-button type="submit" color="primary">Save</button>
    </div>

    <div
      class="mb-4 flex flex-col items-start gap-4"
      *ptFeatureEnabled="'hicaps-connect-testing'"
    >
      <mat-slide-toggle formControlName="testModeEnabled">
        Enable Test Mode
      </mat-slide-toggle>
      <button type="button" mat-stroked-button (click)="customRequest()">
        Send Custom Request
      </button>
    </div>
  </form>

  <div>
    <h3 class="!m-0 !font-bold">Terminals</h3>
    <p>
      Principle uses Principle Bridge to talk to Hicaps Terminals. A Principle
      Bridge Device must be set up on a computer in order to detect and use
      terminals in your practice.
    </p>
    <pr-practice-hicaps-connect-terminals [practice]="practice$ | async" />
  </div>
</div>
