import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  CurrentScopeFacade,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  CASUAL_DATE_WITH_YEAR,
  filterUndefined,
  type ITimePeriod,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { type Observable } from 'rxjs';
import { ReconciliationReportFacade } from '../store/reconciliation-report.facade';

@Component({
    selector: 'pr-reconciliation-report-controls',
    templateUrl: './reconciliation-report-controls.component.html',
    styleUrls: ['./reconciliation-report-controls.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ReconciliationReportControlsComponent {
  headlineFormat = CASUAL_DATE_WITH_YEAR;
  brands$: Observable<WithRef<IBrand>[]>;
  currentPractice$: Observable<WithRef<IPractice>>;
  from = moment().startOf('day');
  to = moment().endOf('day');

  constructor(
    public reportFacade: ReconciliationReportFacade,
    scope: CurrentScopeFacade,
    org: OrganisationService
  ) {
    this.brands$ = org.userBrands$;
    this.currentPractice$ = scope.currentPractice$.pipe(filterUndefined());
  }

  updateDateRange(dateRange: ITimePeriod): void {
    this.reportFacade.setDateRange({
      from: toMoment(dateRange.from),
      to: toMoment(dateRange.to),
    });
  }
}
