import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IGap } from '@principle-theorem/principle-core/interfaces';
import {
  type IWaitListCandidate,
  WaitListCandidate,
} from '@principle-theorem/principle-core';
import { first } from 'lodash';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-wait-list-warnings',
  templateUrl: './wait-list-warnings.component.html',
  styleUrls: ['./wait-list-warnings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaitListWarningsComponent {
  gap$: ReplaySubject<IGap> = new ReplaySubject(1);
  waitListCandidate$: ReplaySubject<IWaitListCandidate> = new ReplaySubject(1);
  warning$: Observable<string | undefined>;

  @Input()
  set gap(gap: IGap) {
    if (gap) {
      this.gap$.next(gap);
    }
  }

  @Input()
  set waitListCandidate(waitListCandidate: IWaitListCandidate) {
    if (waitListCandidate) {
      this.waitListCandidate$.next(waitListCandidate);
    }
  }

  constructor() {
    this.warning$ = combineLatest([this.gap$, this.waitListCandidate$]).pipe(
      switchMap(([gap, waitListCandidate]) =>
        WaitListCandidate.getWarnings$(waitListCandidate.appointment, gap.event)
      ),
      map(first)
    );
  }
}
