import {
  coerceBooleanProperty,
  type BooleanInput,
} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MixedSchema, type VersionedSchema } from '@principle-theorem/editor';
import {
  ChartFacade,
  ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  CollapsibleComponent,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import { ChartedItem, ClinicalNote } from '@principle-theorem/principle-core';
import {
  isChartedMultiStepTreatment,
  type AnyChartedItemConfiguration,
  type IChartedItem,
  type IChartedItemConfiguration,
  type IChartedMultiStepTreatment,
  type IChartedRef,
  type IStaffer,
  type ITreatmentPlan,
} from '@principle-theorem/principle-core/interfaces';
import {
  getDoc,
  shareReplayCold,
  snapshot,
  toISODate,
  toNamedDocument,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  type Observable,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ChartDialogService } from '../../chart-dialog.service';
import { type IEditChartableData } from '../../chartable-surface-updater';
import {
  ChartedItemNotesDialogComponent,
  type IChartedItemNotesDialogData,
} from '../charted-items-display/charted-item-notes-dialog/charted-item-notes-dialog.component';

@Component({
  selector: 'pr-charted-item-card-v2',
  templateUrl: './charted-item-card-v2.component.html',
  styleUrls: ['./charted-item-card-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'prChartedItemCardV2',
})
export class ChartedItemCardV2Component {
  private _selectedSurfaces$ = new ReplaySubject<Partial<IChartedRef>[]>(1);
  item$ = new ReplaySubject<IChartedItem<IChartedItemConfiguration>>(1);
  config$: Observable<AnyChartedItemConfiguration>;
  hasSelectedSurface$: Observable<boolean>;
  editDisabled$: Observable<boolean>;
  disabled$ = new BehaviorSubject<boolean>(false);
  plan$ = new ReplaySubject<
    WithRef<ITreatmentPlan | IChartedMultiStepTreatment | undefined>
  >(1);
  @Output() itemChange = new EventEmitter<IChartedItem>();
  @Output() itemDelete = new EventEmitter<IChartedItem>();
  @Output() updateChartable = new EventEmitter<IEditChartableData>();
  @Input() compact: boolean = false;
  @Input() groupedTreatment = false;
  @ViewChild(CollapsibleComponent) collapsible: CollapsibleComponent;

  @Input()
  set expanded(expanded: BooleanInput) {
    if (this.collapsible) {
      this.collapsible.expanded = coerceBooleanProperty(expanded);
    }
  }

  @Input()
  set item(item: IChartedItem<IChartedItemConfiguration>) {
    if (item) {
      this.item$.next(item);
    }
  }

  @Input()
  set selectedSurfaces(selectedSurfaces: Partial<IChartedRef>[]) {
    if (selectedSurfaces) {
      this._selectedSurfaces$.next(selectedSurfaces);
    }
  }

  @Input()
  set plan(
    plan: WithRef<ITreatmentPlan | IChartedMultiStepTreatment | undefined>
  ) {
    this.plan$.next(plan);
  }

  @Input()
  set disabled(disabled: BooleanInput) {
    this.disabled$.next(coerceBooleanProperty(disabled));
  }

  constructor(
    private _dialog: MatDialog,
    private _chartStore: ChartFacade,
    private _chartDialogService: ChartDialogService,
    private _snackBar: MatSnackBar
  ) {
    this.hasSelectedSurface$ = ChartedItem.hasSelectedSurface$(
      this.item$,
      this._selectedSurfaces$
    );

    this.config$ = this.item$.pipe(
      switchMap((item) => getDoc(item.config.ref)),
      shareReplayCold()
    );

    this.editDisabled$ = combineLatest([this.item$, this.disabled$]).pipe(
      map(([item, disabled]) =>
        isChartedMultiStepTreatment(item) ? true : disabled
      )
    );
  }

  async openNoteDialog(item: IChartedItem): Promise<void> {
    const content = await this._dialog
      .open<
        ChartedItemNotesDialogComponent,
        IChartedItemNotesDialogData | undefined,
        VersionedSchema
      >(ChartedItemNotesDialogComponent, DialogPresets.small())
      .afterClosed()
      .toPromise();

    if (!content) {
      return;
    }
    await this._addNote(item, content);
  }

  async editSurfaces(): Promise<void> {
    const item = await snapshot(this.item$);
    const chartable = await snapshot(this.config$);
    const selectedSurfaces = await this._chartDialogService.getSurfaces(
      chartable,
      undefined,
      item
    );

    if (!selectedSurfaces) {
      return;
    }

    if (first(selectedSurfaces)?.unscoped) {
      this._snackBar.open('No surfaces available for this treatment');
      return;
    }

    const chartingAs = await snapshot(
      this._chartStore.chartingAs$(ChartId.InAppointment)
    );
    this.updateChartable.emit({
      chartable,
      selectedSurfaces,
      item,
      chartingAs,
    });
  }

  private async _addNote(
    item: IChartedItem,
    content: MixedSchema
  ): Promise<void> {
    const chartingAs: WithRef<IStaffer> = await snapshot(
      this._chartStore.chartingAs$(ChartId.InAppointment)
    );
    this.itemChange.emit({
      ...item,
      notes: [
        ...item.notes,
        ClinicalNote.init({
          content,
          owner: toNamedDocument({ ...chartingAs, name: chartingAs.user.name }),
          recordDate: toISODate(toTimestamp()),
        }),
      ],
    });
  }
}
