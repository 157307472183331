import * as crossfilter from 'crossfilter2';
import { type CrossfilterGroupMap } from './d3-grouped-crossfilter';

interface IMockFact {
  practitioner: string;
  type: string;
}

export const data: IMockFact[] = [
  {
    practitioner: 'Dr Lucy Taylor',
    type: 'cancel',
  },
  {
    practitioner: 'Dr Lucy Taylor',
    type: 'fta',
  },
  {
    practitioner: 'Dr Lucy Taylor',
    type: 'uta',
  },
  {
    practitioner: 'Dr Lucy Taylor',
    type: 'uta',
  },
  {
    practitioner: 'Dr Frank Abbott',
    type: 'cancel',
  },
  {
    practitioner: 'Dr Frank Abbott',
    type: 'fta',
  },
  {
    practitioner: 'Dr Frank Abbott',
    type: 'cancel',
  },
];

export function getMockDimensionMap(): CrossfilterGroupMap<IMockFact> {
  const ftaNdx = crossfilter(data);
  ftaNdx.dimension((d) => d.type).filter((d) => d === 'fta');
  const ftaPractitionerDim = ftaNdx.dimension((d) => d.practitioner);
  const fta = ftaPractitionerDim
    .group<crossfilter.NaturallyOrderedValue, number>()
    .reduceCount();

  const utaNdx = crossfilter(data);
  utaNdx.dimension((d) => d.type).filter((d) => d === 'uta');
  const utaPractitionerDim = utaNdx.dimension((d) => d.practitioner);
  const uta = utaPractitionerDim
    .group<crossfilter.NaturallyOrderedValue, number>()
    .reduceCount();

  const cancelNdx = crossfilter(data);
  cancelNdx.dimension((d) => d.type).filter((d) => d === 'cancel');
  const cancelPractitionerDim = cancelNdx.dimension((d) => d.practitioner);
  const cancel = cancelPractitionerDim
    .group<crossfilter.NaturallyOrderedValue, number>()
    .reduceCount();

  return { fta, uta, cancel };
}
