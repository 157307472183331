import { type UpdateStr } from '@ngrx/entity/src/models';
import { createAction, props } from '@ngrx/store';
import { getErrorAction, getSelectAction } from '@principle-theorem/ng-shared';
import { type AutomationEntity } from '@principle-theorem/principle-core';
import {
  type IAutomationConfiguration,
  type IBrand,
  type IPatient,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  type Reffable,
  type SerialisedData,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';

enum AutomationActionType {
  LoadTreatmentStepAutomations = '[Automations | Treatment Step] Load Automations',
  LoadBrandAutomations = '[Automations | Brand] Load Automations',
  LoadConfigAutomations = '[Automations | Config] Load Automations',
  LoadPatientAutomations = '[Automations | Patient] Load Automations',
  LoadAutomationsSuccess = '[Automations] Load Success',
  ResetStateOnLoadAutomationSuccess = '[Automations] Reset on Load Success',
  LoadAutomationsFailure = '[Automations] Load Failure',
  SelectAutomation = '[Automations] Select',
  UnselectAutomation = '[Automations] Unselect',
  ResetAutomations = '[Automations] Reset',
  SetAutomations = '[Automations] Set All',
  AddAutomations = '[Automations] Add Many',
  AddAutomation = '[Automations] Add One',
  RemoveAutomation = '[Automations] Remove One',
  RemoveAutomations = '[Automations] Remove Many',
  UpdateAutomation = '[Automations] Update One',
  UpdateAutomations = '[Automations] Update Many',
  SaveTreatmentStepAutomations = '[Automations] Save All Automations',
  SaveAutomationsSuccess = '[Automations] Save All Success',
  ForceResend = '[Automations] Force Resend',
  CancelAutomation = '[Automations] Cancel One',
  SaveAutomationSuccess = '[Automations] Save One Success',
  SaveAutomationFailure = '[Automations] Save One Failure',
}

interface ILoadBrandAutomations {
  brand: WithRef<IBrand>;
  dateFrom: Timestamp;
  dateTo: Timestamp;
}

export const loadBrandAutomations = createAction(
  AutomationActionType.LoadBrandAutomations,
  props<SerialisedData<ILoadBrandAutomations>>()
);

interface ILoadConfigAutomations {
  config: WithRef<IAutomationConfiguration>;
  dateFrom: Timestamp;
  dateTo: Timestamp;
}

export const loadConfigAutomations = createAction(
  AutomationActionType.LoadConfigAutomations,
  props<SerialisedData<ILoadConfigAutomations>>()
);

interface ILoadPatientAutomations {
  patient: WithRef<IPatient>;
}

export const loadPatientAutomations = createAction(
  AutomationActionType.LoadPatientAutomations,
  props<SerialisedData<ILoadPatientAutomations>>()
);

interface ILoadTreatmentStepAutomations {
  treatmentStep: WithRef<ITreatmentStep>;
}

export const loadTreatmentStepAutomations = createAction(
  AutomationActionType.LoadTreatmentStepAutomations,
  props<SerialisedData<ILoadTreatmentStepAutomations>>()
);

export interface ILoadAutomationsSuccess {
  automations: AutomationEntity[];
}

export const loadAutomationsSuccess = createAction(
  AutomationActionType.LoadAutomationsSuccess,
  props<SerialisedData<ILoadAutomationsSuccess>>()
);

export const resetStateOnLoadSuccess = createAction(
  AutomationActionType.ResetStateOnLoadAutomationSuccess,
  props<SerialisedData<ILoadAutomationsSuccess>>()
);

export const loadAutomationsFailure = getErrorAction(
  AutomationActionType.LoadAutomationsFailure
);

export const resetAutomationsState = createAction(
  AutomationActionType.ResetAutomations
);

interface IAddAutomations {
  automations: AutomationEntity[];
}

export const addAutomations = createAction(
  AutomationActionType.AddAutomations,
  props<SerialisedData<IAddAutomations>>()
);

export const setAutomations = createAction(
  AutomationActionType.SetAutomations,
  props<SerialisedData<IAddAutomations>>()
);

export const selectAutomation = getSelectAction(
  AutomationActionType.SelectAutomation
);

export const unselectAutomation = createAction(
  AutomationActionType.UnselectAutomation
);

interface IUpdateAutomation {
  update: UpdateStr<AutomationEntity>;
  skipEffect: boolean;
}

export const updateAutomation = createAction(
  AutomationActionType.UpdateAutomation,
  props<SerialisedData<IUpdateAutomation>>()
);

interface IUpdateAutomations {
  updates: UpdateStr<AutomationEntity>[];
}

export const updateAutomations = createAction(
  AutomationActionType.UpdateAutomations,
  props<SerialisedData<IUpdateAutomations>>()
);

export const removeAutomation = getSelectAction(
  AutomationActionType.RemoveAutomation
);

interface IAddAutomation {
  automation: AutomationEntity;
  stepRef?: DocumentReference<ITreatmentStep>;
}

export const addAutomation = createAction(
  AutomationActionType.AddAutomation,
  props<SerialisedData<IAddAutomation>>()
);

interface IRemoveAutomations {
  ids: string[];
}

export const removeAutomations = createAction(
  AutomationActionType.RemoveAutomations,
  props<IRemoveAutomations>()
);

export const saveTreatmentStepAutomations = createAction(
  AutomationActionType.SaveTreatmentStepAutomations,
  props<SerialisedData<ILoadTreatmentStepAutomations>>()
);

export const saveAutomationsSuccess = createAction(
  AutomationActionType.SaveAutomationsSuccess
);

interface IReffableAutomation {
  automation: Reffable<AutomationEntity>;
}

export const forceResend = createAction(
  AutomationActionType.ForceResend,
  props<SerialisedData<IReffableAutomation>>()
);

export const cancelAutomation = createAction(
  AutomationActionType.CancelAutomation,
  props<SerialisedData<IAddAutomation>>()
);

export const saveAutomationSuccess = createAction(
  AutomationActionType.SaveAutomationSuccess
);

export const saveAutomationFailure = getErrorAction(
  AutomationActionType.SaveAutomationFailure
);
