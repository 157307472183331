<div class="mx-auto mt-8 flex max-w-5xl flex-col gap-4">
  @if (store.loading()) {
    <div
      class="flex flex-col items-center gap-4 rounded-lg bg-white p-8 shadow-lg"
    >
      <pr-patient-portal-loader>Loading Forms...</pr-patient-portal-loader>
    </div>
  }

  @if (store.errorMessage(); as errorMessage) {
    <div
      class="flex flex-col items-center gap-4 rounded-lg bg-white p-8 shadow-lg"
    >
      <pr-patient-portal-splash type="error">
        Oops! There was a problem.
      </pr-patient-portal-splash>
      <div class="max-w-prose text-xs">{{ errorMessage }}</div>
    </div>
  }

  @if (store.tokenData(); as data) {
    <div class="flex flex-col gap-4 rounded-lg bg-white shadow-lg">
      <pr-patient-portal-header
        [brand]="data.brand"
        [practice]="data.practice"
      />
    </div>

    <div class="flex flex-col gap-4 rounded-lg bg-white p-6 shadow-lg">
      <h3 class="!m-0 !font-bold">Forms for {{ data.patientName }}</h3>
      <pr-patient-forms-list />
    </div>
  }
</div>
