import { type INamedDocument, isObject } from '@principle-theorem/shared';
import { isString } from 'lodash';
import {
  ChartedItemType,
  type IChartedItem,
  isChartedItem,
} from '../core/charted-item';
import { type INamedTreatmentTemplate } from './treatment-plan';
import { isTreatmentStep, type ITreatmentStep } from './treatment-step';
import { type IFeeSchedule } from '../fees/fee-schedule/fee-schedule';

export interface IChartedMultiStepTreatmentStep extends ITreatmentStep {
  uid: string;
}

export function isChartedMultiStepTreatmentStep(
  chartedItem: unknown
): chartedItem is IChartedMultiStepTreatmentStep {
  return (
    isObject(chartedItem) &&
    isString(chartedItem.uid) &&
    isTreatmentStep(chartedItem)
  );
}

export interface IChartedMultiStepTreatment extends IChartedItem {
  type: ChartedItemType.ChartedMultiStepTreatment;
  steps: IChartedMultiStepTreatmentStep[];
  config: INamedTreatmentTemplate;
  treatmentPackageId?: string;
  feeSchedule?: INamedDocument<IFeeSchedule>;
}

export function isChartedMultiStepTreatment(
  chartedItem: unknown
): chartedItem is IChartedMultiStepTreatment {
  return (
    isChartedItem(chartedItem) &&
    'type' in chartedItem &&
    chartedItem.type === ChartedItemType.ChartedMultiStepTreatment
  );
}
