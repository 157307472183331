<mat-toolbar color="accent">Manage Patient Forms</mat-toolbar>

<div class="p-4">
  <div class="flex flex-row items-center gap-4">
    <div class="flex grow"></div>
    <button mat-button color="primary" (click)="issueForm(data.patient)">
      <mat-icon>add</mat-icon>
      Generate Form
    </button>
  </div>

  <pr-patient-submitted-forms-list
    [patient]="data.patient"
    [loadingState]="false"
    [hideConfirmedForms]="true"
    (formSelected)="customForms.openFormDialog($event)"
  />
</div>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
