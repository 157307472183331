<div fxLayout="row" fxLayoutGap="16px" class="header mat-caption">
  <div fxFlex>Description</div>
  <div class="quantity">Quantity</div>
  <div class="amount">
    <span class="amount-inset">Tax</span>
  </div>
  <div class="amount">
    <span class="amount-inset">Amount</span>
  </div>
</div>
