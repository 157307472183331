import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { PatientForm } from '@principle-theorem/principle-core';
import {
  PatientFormStatus,
  type IPatient,
  type IPatientForm,
} from '@principle-theorem/principle-core/interfaces';
import {
  isPathChanged$,
  sortByUpdatedAt,
  type WithRef,
} from '@principle-theorem/shared';
import { groupBy, sortBy, toPairs } from 'lodash';
import { type Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

interface IPatientFormGroup {
  name: string;
  forms: WithRef<IPatientForm>[];
}

export interface IPatientSubmittedFormsListState {
  forms: WithRef<IPatientForm>[];
  hideConfirmedForms: boolean;
  loading: boolean;
}

const initialState: IPatientSubmittedFormsListState = {
  hideConfirmedForms: false,
  forms: [],
  loading: false,
};

@Injectable()
export class PatientSubmittedFormsListStore extends ComponentStore<IPatientSubmittedFormsListState> {
  private _forms$ = this.select((store) => store.forms);
  private _hideConfirmedForms$ = this.select(
    (store) => store.hideConfirmedForms
  );
  readonly loading$ = this.select((store) => store.loading);
  readonly groups$ = this.select(
    this._forms$,
    this._hideConfirmedForms$,
    (forms, hideConfirmedForms) =>
      this._toPatientFormGroups(forms, hideConfirmedForms)
  );

  readonly loadSubmittedForms = this.effect(
    (patient$: Observable<WithRef<IPatient>>) => {
      return patient$.pipe(
        isPathChanged$('ref.id'),
        tap(() =>
          this.patchState({
            forms: [],
            loading: true,
          })
        ),
        switchMap((patient) => PatientForm.allPatientForms$(patient)),
        tap((forms) => this.patchState({ forms, loading: false }))
      );
    }
  );

  constructor() {
    super(initialState);
  }

  hideConfirmedForms(hideConfirmedForms: boolean): void {
    this.patchState({ hideConfirmedForms });
  }

  private _toPatientFormGroups(
    allForms: WithRef<IPatientForm>[],
    hideConfirmedForms: boolean
  ): IPatientFormGroup[] {
    const patientForms = hideConfirmedForms
      ? allForms.filter((form) => form.status !== PatientFormStatus.Confirmed)
      : allForms;
    const pairs = groupBy(patientForms, (form) => PatientForm.getName(form));
    const groups = toPairs(pairs).map(([name, forms]) => ({
      name,
      forms: forms.sort(sortByUpdatedAt),
    }));
    return sortBy(groups, (group) => group.name);
  }
}
