import { rand, randNumber } from '@ngneat/falso';
import { IAccountCreditEventFact } from '@principle-theorem/reporting/interfaces';
import {
  BaseMock,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
} from '@principle-theorem/shared';
import { AccountCreditDimensionMock } from '../dimensions/account-credit-dimension.mock';
import { BrandDimensionMock } from '../dimensions/brand-dimension.mock';
import { SerialisedDocumentReferenceMock } from '../dimensions/common.mock';
import { InvoiceDimensionMock } from '../dimensions/invoice-dimension.mock';
import {
  PatientDimensionMock,
  MOCKED_REFERRERS,
} from '../dimensions/patient-dimension.mock';
import { PracticeDimensionMock } from '../dimensions/practice-dimension.mock';
import { TransactionDimensionMock } from '../dimensions/transaction-dimension.mock';

export class AccountCreditEventFactMock
  extends BaseMock
  implements IAccountCreditEventFact
{
  brandRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  accountCreditRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  invoiceRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  patientRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practiceRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  transactionRefs = [MockGenerator.generate(SerialisedDocumentReferenceMock)];
  accountCredit = MockGenerator.generate(AccountCreditDimensionMock);
  invoice = MockGenerator.generate(InvoiceDimensionMock);
  patient = MockGenerator.generate(PatientDimensionMock);
  practice = MockGenerator.generate(PracticeDimensionMock);
  transactions = [MockGenerator.generate(TransactionDimensionMock)];
  referrer = rand(MOCKED_REFERRERS);
  brand = MockGenerator.generate(BrandDimensionMock);
  timestamp = toSerialisedTimestamp(MockTimestamp());
  remaining = randNumber({ min: 0, max: 1000 });
}
