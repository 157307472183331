import { Injectable, inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import {
  InvoiceStatus,
  type IAppointment,
  type IInvoice,
} from '@principle-theorem/principle-core/interfaces';
import {
  multiFilter,
  serialise,
  unserialise$,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InvoiceActions } from '../actions';
import type * as fromInvoices from '../reducers/invoices.reducers';
import {
  getDraftInvoice,
  getDraftInvoiceId,
  selectAllInvoices,
} from '../reducers/invoices.reducers';
import { TaxRate } from '@principle-theorem/accounting';

@Injectable()
export class InvoicesFacade {
  private _store = inject(Store<fromInvoices.IInvoicesState>);

  draftInvoiceID$: Observable<string | undefined>;
  draftInvoice$: Observable<WithRef<IInvoice> | undefined>;
  invoices$: Observable<WithRef<IInvoice>[]>;

  unpaidInvoices$: Observable<WithRef<IInvoice>[]>;

  constructor() {
    this.draftInvoiceID$ = this._store.pipe(select(getDraftInvoiceId));
    this.draftInvoice$ = this._store.pipe(
      select(getDraftInvoice),
      unserialise$()
    );

    this.invoices$ = this._store.pipe(
      select(selectAllInvoices),
      unserialise$(),
      map((data?) => (data ? compact(data) : []))
    );
    this.unpaidInvoices$ = this.invoices$.pipe(
      multiFilter((invoice) => invoice.status === InvoiceStatus.Issued)
    );
  }

  loadInvoices(): void {
    this.dispatch(InvoiceActions.loadInvoices());
  }

  resetState(): void {
    this.dispatch(InvoiceActions.reset());
  }

  loadDraftInvoice(appointment: WithRef<IAppointment>, taxRate: TaxRate): void {
    this.dispatch(
      InvoiceActions.loadDraftInvoice(serialise({ appointment, taxRate }))
    );
  }

  dispatch(action: Action): void {
    this._store.dispatch(action);
  }
}
