import {
  FeatureVisibility,
  FEATURE_DEFAULTS,
  IFeature,
} from '@principle-theorem/feature-flags';

export enum ReportingPermissions {
  ReportingReportAccountCredits = 'reporting.reports.account-credits',
  ReportingReportDailyDashboard = 'reporting.reports.daily-dashboard',
  ReportingReportPractices = 'reporting.reports.practices',
  ReportingReportPractitioners = 'reporting.reports.practitioners',
  ReportingReportPractitionersV2 = 'reporting.reports.practitioners-v2',
  ReportingReportPatientFlow = 'reporting.reports.patient-flow',
  ReportingReportAcquisitions = 'reporting.reports.acquisitions',
  ReportingReportBuilder = 'reporting.reports.report-builder',
  ReportingCustomReportEdit = 'reporting.reports.custom-report.edit',
  ReportingCustomReportView = 'reporting.reports.custom-report.view',
  ReportingReportReconciliation = 'reporting.reports.reconciliation',
  ReportingReportOutstandingInvoices = 'reporting.reports.outstanding-invoices',
  ReportingReportPendingPayments = 'reporting.reports.pending-payments',
  ReportingReportPatients = 'reporting.reports.patients',
  ReportingReportDemos = 'reporting.reports.demos',
}

export const REPORTING_DAILY_DASHBOARD_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  label: 'reporting-dailyDashboard',
  visibility: FeatureVisibility.None,
  permissions: [
    {
      value: ReportingPermissions.ReportingReportDailyDashboard,
      description: `User can view Daily Dashboard report`,
    },
  ],
};

export const REPORTING_PRACTICES_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  label: 'reporting-practices',
  visibility: FeatureVisibility.None,
  permissions: [
    {
      value: ReportingPermissions.ReportingReportPractices,
      description: `User can view Practices report`,
    },
  ],
};

export const REPORTING_ACCOUNT_CREDITS_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  label: 'reporting-account-credits',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: ReportingPermissions.ReportingReportAccountCredits,
      description: `User can view Account Credits report`,
    },
  ],
};

export const REPORTING_PRACTITIONERS_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  label: 'reporting-practitioners',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: ReportingPermissions.ReportingReportPractitioners,
      description: `User can view Practitioner's Income report`,
    },
  ],
};

export const REPORTING_PRACTITIONERS_V2_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  label: 'reporting-practitioners-v2',
  visibility: FeatureVisibility.None,
  permissions: [
    {
      value: ReportingPermissions.ReportingReportPractitionersV2,
      description: `User can view Practitioner's report`,
    },
  ],
};

export const REPORTING_PATIENT_FLOW_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  label: 'reporting-patientFlow',
  visibility: FeatureVisibility.None,
  permissions: [
    {
      value: ReportingPermissions.ReportingReportPatientFlow,
      description: `User can view Patient Flow report`,
    },
  ],
};

export const REPORTING_ACQUISITIONS_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  label: 'reporting-acquisitions',
  visibility: FeatureVisibility.None,
  permissions: [
    {
      value: ReportingPermissions.ReportingReportAcquisitions,
      description: `User can view Acquisitions report`,
    },
  ],
};

export const REPORTING_REPORT_BUILDER_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  label: 'reporting-reportBuilder',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: ReportingPermissions.ReportingReportBuilder,
      description: `User can view Report Builder`,
    },
    {
      value: ReportingPermissions.ReportingCustomReportEdit,
      description: `User can create, edit and delete all Custom Reports`,
    },
    {
      value: ReportingPermissions.ReportingCustomReportView,
      description: `User can view Custom Reports shared with them`,
    },
  ],
};

export const REPORTING_RECONCILIATION_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  label: 'reporting-reconciliation',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: ReportingPermissions.ReportingReportReconciliation,
      description: `User can view Reconciliation Report`,
    },
  ],
};

export const REPORTING_OUTSTANDING_INVOICES_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  label: 'reporting-outstanding-invoices',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: ReportingPermissions.ReportingReportOutstandingInvoices,
      description: `User can view Outstanding Invoices Report`,
    },
  ],
};

export const REPORTING_PENDING_PAYMENTS_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  label: 'pending-payments',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: ReportingPermissions.ReportingReportPendingPayments,
      description: `User can view Pending Payments Report`,
    },
  ],
};

export const REPORTING_PATIENTS_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  label: 'reporting-patients',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: ReportingPermissions.ReportingReportPatients,
      description: `User can view Patient Reports`,
    },
  ],
};

export const REPORTING_DEMOS_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  label: 'reporting-demo',
  visibility: FeatureVisibility.None,
  permissions: [
    {
      value: ReportingPermissions.ReportingReportDemos,
      description: `User can view Demo Reports`,
    },
  ],
};

export const REPORTING_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  label: 'reporting',
  visibility: FeatureVisibility.Beta,
  features: [
    REPORTING_OUTSTANDING_INVOICES_FEATURE,
    REPORTING_RECONCILIATION_FEATURE,
    REPORTING_REPORT_BUILDER_FEATURE,
    REPORTING_ACCOUNT_CREDITS_FEATURE,
    REPORTING_ACQUISITIONS_FEATURE,
    REPORTING_PATIENT_FLOW_FEATURE,
    REPORTING_PRACTITIONERS_FEATURE,
    REPORTING_PRACTITIONERS_V2_FEATURE,
    REPORTING_PRACTICES_FEATURE,
    REPORTING_PENDING_PAYMENTS_FEATURE,
    REPORTING_PATIENTS_FEATURE,
    REPORTING_DAILY_DASHBOARD_FEATURE,
    REPORTING_DEMOS_FEATURE,
  ],
};
