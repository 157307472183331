import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrentPatientScope } from '@principle-theorem/ng-principle-shared';
import { NG_SHARED_CONFIG } from '@principle-theorem/ng-shared';
import { generateMedicalHistoryUrl } from '@principle-theorem/principle-core';
import { filterUndefined, snapshot } from '@principle-theorem/shared';
import { Observable } from 'rxjs';

interface IFormNavLink {
  label: string;
  path: string[] | string;
  action?(): Promise<void>;
}

@Component({
  selector: 'pr-patient-forms-tab',
  templateUrl: './patient-forms-tab.component.html',
  styleUrls: ['./patient-forms-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientFormsTabComponent {
  private _sharedConfig = inject(NG_SHARED_CONFIG);
  medicalFormLink$: Observable<string | undefined>;
  navLinks: IFormNavLink[] = [
    {
      label: 'Medical History',
      path: ['./medical-history-form'],
      action: this.copyMedicalHistoryFormLink.bind(this),
    },
    { label: 'Patient Details', path: ['./patient-details-form'] },
  ];

  constructor(
    private _patientScope: CurrentPatientScope,
    private _clipboard: Clipboard,
    private _snackBar: MatSnackBar
  ) {}

  async copyMedicalHistoryFormLink(): Promise<void> {
    const patient = await snapshot(
      this._patientScope.doc$.pipe(filterUndefined())
    );
    const link = await generateMedicalHistoryUrl(
      patient,
      this._sharedConfig.appUrl
    );
    this._clipboard.copy(link);
    this._snackBar.open('Link copied to clipboard');
  }
}
