import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  TypeGuard,
  type Timezone,
  Timestamp,
  toTimestamp,
  ISO_DATE_TIME_FORMAT,
} from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import * as moment from 'moment-timezone';

export const STERILISATION_RECORD_RESOURCE_TYPE = 'sterilisationRecords';

export const STERILISATION_RECORD_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Sterilisation Record List',
      description: '',
      idPrefix: STERILISATION_RECORD_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ICorePracticeSterilisationRecord {
  id: number;
  barcode: string; // "230811.154100 MAM201379C01623P"
  barcode2?: string; // "MAM201379C01623P"
  note?: string;
  cycleDateUtc: string; // 2023-10-20 04:02:00.000
  treatmentId: number;
  patientId: number;
  locationId: number;
}

export function isCorePracticeSterilisationRecord(
  item: unknown
): item is ICorePracticeSterilisationRecord {
  return TypeGuard.interface<ICorePracticeSterilisationRecord>({
    id: isNumber,
    barcode: isString,
    barcode2: TypeGuard.nilOr(isString),
    note: TypeGuard.nilOr(isString),
    cycleDateUtc: isString,
    treatmentId: isNumber,
    patientId: isNumber,
    locationId: isNumber,
  })(item);
}

export interface ICorePracticeSterilisationRecordTranslations {
  cycleDateUtc?: Timestamp;
}

export interface ICorePracticeSterilisationRecordFilters {
  treatmentId: number;
  patientId: number;
}

const STERILISATION_RECORD_SOURCE_QUERY = `
SELECT
  sterilisation.*,
  treatment.patient_id,
  treatment.location_id
FROM
(SELECT
  SteriTrackingId AS id,
  Barcode AS barcode,
  Barcode2 AS barcode_2,
  Note AS note,
  CycleDateUtc AS cycle_date_utc,
  TreatmentId AS treatment_id
FROM tblSteriTracking
ORDER BY SteriTrackingId
) as sterilisation
INNER JOIN (
  SELECT
    TreatmentId AS id,
    PatientId AS patient_id,
    LocationId AS location_id
  FROM tblTreatment
) AS treatment
ON treatment.id = sterilisation.treatment_id
`;

export class SterilisationRecordSourceEntity extends BaseSourceEntity<
  ICorePracticeSterilisationRecord,
  ICorePracticeSterilisationRecordTranslations,
  ICorePracticeSterilisationRecordFilters
> {
  sourceEntity = STERILISATION_RECORD_SOURCE_ENTITY;
  entityResourceType = STERILISATION_RECORD_RESOURCE_TYPE;
  sourceQuery = STERILISATION_RECORD_SOURCE_QUERY;
  verifySourceFn = isCorePracticeSterilisationRecord;

  translate(
    data: ICorePracticeSterilisationRecord,
    _timezone: Timezone
  ): ICorePracticeSterilisationRecordTranslations {
    return {
      cycleDateUtc: data.cycleDateUtc
        ? toTimestamp(moment.utc(data.cycleDateUtc, ISO_DATE_TIME_FORMAT))
        : undefined,
    };
  }

  getSourceRecordId(data: ICorePracticeSterilisationRecord): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticeSterilisationRecord): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticeSterilisationRecord,
    _timezone: Timezone
  ): ICorePracticeSterilisationRecordFilters {
    return {
      treatmentId: data.treatmentId,
      patientId: data.patientId,
    };
  }
}
