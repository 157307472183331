import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type ICustomReportChart,
  type ICustomReportChartSection,
} from '@principle-theorem/principle-core/interfaces';
import { flatten } from 'lodash';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReportBuilderDataSource } from '../../../../models/report-builder-data-sources/report-builder-data-source';
import { ReportBuilderStore } from '../../report-builder.store';
import { chartsToDisplays } from '../report-builder-chart-section/report-builder-chart-section.component';
import { type IChartDisplay } from '../report-builder-chart-section/report-builder-chart/report-builder-chart-display';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'pr-report-builder-available-charts',
  templateUrl: './report-builder-available-charts.component.html',
  styleUrls: ['./report-builder-available-charts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportBuilderAvailableChartsComponent {
  trackBySection = TrackByFunctions.uniqueId<ICustomReportChartSection>();
  trackByChart = TrackByFunctions.uniqueId<ICustomReportChart>();
  trackByDisplay = TrackByFunctions.nestedField<IChartDisplay>('chart.uid');
  sections$: Observable<ICustomReportChartSection[]>;

  constructor(
    public store: ReportBuilderStore,
    private _snackBar: MatSnackBar
  ) {
    this.sections$ = this.store.dataSource$.pipe(
      map((dataSource) =>
        ReportBuilderDataSource.buildChartSections(dataSource)
      )
    );
  }

  getDisplays$(
    section: ICustomReportChartSection
  ): Observable<IChartDisplay[]> {
    return combineLatest([this.store.dataSource$, this.store.ndx$]).pipe(
      map(([dataSource, ndx]) =>
        chartsToDisplays(section.charts, dataSource, ndx)
      )
    );
  }

  async includeInCustomReport(display: IChartDisplay): Promise<void> {
    await this.store.addChart(display.chart);
    this._snackBar.open('Chart added to custom report');
  }

  isInCustomReport$(chartDisplay: IChartDisplay): Observable<boolean> {
    return this.store.display$.pipe(
      map((display) =>
        flatten(display?.chartSections.map((section) => section.charts)).some(
          (chart) => chart.uid === chartDisplay.chart.uid
        )
      )
    );
  }
}
