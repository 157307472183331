import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  HostBinding,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
    selector: 'pr-status-label',
    imports: [CommonModule],
    template: '{{ label | uppercase }}',
    styleUrls: ['./status-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusLabelComponent {
  @Input() label = '';

  @Input()
  @HostBinding('attr.dense')
  dense = false;

  @Input()
  @HostBinding('class')
  color = 'default';
}
