import { isDocRef, isObject } from '@principle-theorem/shared';
import { DocumentReference } from 'firebase-admin/firestore';
import { isString } from 'lodash';
import { ICustomFormConfiguration } from '../custom-forms/custom-form-config';
import {
  IAutomationConfiguration,
  IAutomationResource,
  isAutomationConfiguration,
} from './automation';

export interface IAutomatedFormIssue extends IAutomationResource {
  name: string;
  formConfigurationRef: DocumentReference<ICustomFormConfiguration>;
}

export function isAutomatedFormIssue(
  data: unknown
): data is IAutomatedFormIssue {
  return (
    isObject(data) && isString(data.name) && isDocRef(data.formConfigurationRef)
  );
}

export interface IAutomatedFormIssueConfiguration
  extends IAutomationConfiguration,
    IAutomatedFormIssue {}

export function isAutomatedFormIssueConfiguration(
  data: unknown
): data is IAutomatedFormIssueConfiguration {
  return isAutomatedFormIssue(data) && isAutomationConfiguration(data);
}
