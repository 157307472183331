import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TIME_FORMAT } from '@principle-theorem/shared';
import type * as moment from 'moment-timezone';
import { ReplaySubject } from 'rxjs';

export interface ITimelineNodeSummary {
  from: moment.Moment;
  to: moment.Moment;
  duration: string;
}

@Component({
  selector: 'pr-interactive-timeline-drag-tooltip',
  templateUrl: './interactive-timeline-drag-tooltip.component.html',
  styleUrls: ['./interactive-timeline-drag-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InteractiveTimelineDragTooltipComponent {
  readonly timeFormat = TIME_FORMAT;
  summary$ = new ReplaySubject<ITimelineNodeSummary>(1);

  @Input()
  set summary(summary: ITimelineNodeSummary) {
    if (summary) {
      this.summary$.next(summary);
    }
  }
}
