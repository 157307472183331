import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Invoice } from '@principle-theorem/principle-core';
import {
  type IInvoice,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  doc$,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AccountInvoiceBloc } from '../../../account-invoice-bloc';
import { InvoiceActionService } from '../invoice-action.service';

@Component({
    selector: 'pr-invoice-action-menu',
    templateUrl: './invoice-action-menu.component.html',
    styleUrls: ['./invoice-action-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InvoiceActionMenuComponent {
  invoice$ = new ReplaySubject<WithRef<IInvoice>>(1);
  patient$: Observable<WithRef<IPatient>>;
  bloc: AccountInvoiceBloc;
  cancelMessage$: Observable<string>;
  dateFormat = DAY_MONTH_YEAR_FORMAT;

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this.invoice$.next(invoice);
    }
  }

  constructor(private _invoiceActions: InvoiceActionService) {
    this.bloc = new AccountInvoiceBloc(this._invoiceActions, this.invoice$);
    this.patient$ = this.invoice$.pipe(
      switchMap((invoice) => doc$(Invoice.patientDocRef(invoice)))
    );
    this.cancelMessage$ = this.bloc.requiresRefundsToCancel$.pipe(
      map((requiresRefund) =>
        requiresRefund ? 'Please refund or cancel existing payments' : ''
      )
    );
  }
}
