import {
  type INamedTypeDocument,
  type ISoftDelete,
  TypeGuard,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type IContact } from '../contact/contact';
import { MentionResourceType } from '../mention/mention';
import { type IPatient } from './patient';

export interface IReferralSourceConfiguration extends ISoftDelete {
  name: string;
}

export enum ReferralSourceType {
  ConfiguredReferrer = 'configuredReferrer',
}

export type ConfiguredReferrer = INamedTypeDocument<
  IReferralSourceConfiguration,
  ReferralSourceType.ConfiguredReferrer
>;

export type PatientReferrer = INamedTypeDocument<
  IPatient,
  MentionResourceType.Patient
>;

export type ContactReferrer = INamedTypeDocument<
  IContact,
  MentionResourceType.Contact
>;

export type IReferralSource =
  | PatientReferrer
  | ContactReferrer
  | ConfiguredReferrer
  | { name: string };

export const isReferralSource = TypeGuard.interface<IReferralSource>({
  name: isString,
});

export function isPatientReferrer(
  data: IReferralSource
): data is PatientReferrer {
  return 'type' in data && data.type === MentionResourceType.Patient;
}

export function isContactReferrer(
  data: IReferralSource
): data is ContactReferrer {
  return 'type' in data && data.type === MentionResourceType.Contact;
}

export function isConfiguredReferrer(
  data: IReferralSource
): data is ConfiguredReferrer {
  return 'type' in data && data.type === ReferralSourceType.ConfiguredReferrer;
}
