import { type ITag } from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  isChanged$,
  safeCombineLatest,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { BehaviorSubject, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GlobalStoreService } from '../store/global-store.service';

export class NamedDocsToTags {
  namedDocs$: BehaviorSubject<INamedDocument<ITag>[]> = new BehaviorSubject<
    INamedDocument<ITag>[]
  >([]);
  tags$: Observable<WithRef<ITag>[]>;

  constructor(private _global: GlobalStoreService) {
    this.tags$ = this.namedDocs$.pipe(
      isChanged$(),
      switchMap((namedDocs) => this._getTags$(namedDocs))
    );
  }

  private _getTags$(
    namedDocs: INamedDocument<ITag>[]
  ): Observable<WithRef<ITag>[]> {
    return safeCombineLatest(
      namedDocs.map((namedDoc) => this._global.getTag$(namedDoc.ref))
    ).pipe(map(compact));
  }
}
