import { type SBScalarType, type SBType } from '@storybook/csf';

export enum ControlType {
  Select = 'select',
  MultiSelect = 'multi-select',
  Text = 'text',
  Boolean = 'boolean',
  Number = 'number',
  Date = 'date',
  Object = 'object',
}

export interface IControl {
  control: {
    type: ControlType;
  };
}

export interface ISelectControl<T> {
  options: T[];
}

export interface IHiddenControl {
  table: {
    disable: boolean;
  };
}

function getControl(type: ControlType): IControl {
  return {
    control: {
      type,
    },
  };
}

export function getSelectControl<T>(
  options: T[],
  multi: boolean = false
): ISelectControl<T> {
  return {
    ...getControl(multi ? ControlType.MultiSelect : ControlType.Select),
    options,
  };
}

export interface IActionListener {
  action: string;
}

export function getActionListener(action: string): IActionListener {
  return { action };
}

export interface IJsonControl {
  type: SBType | SBScalarType['name'];
}

export function getJsonControl(): IJsonControl {
  return { type: { name: 'object', value: {} } };
}

export function getTextControl(): IControl {
  return getControl(ControlType.Text);
}

export function getNumberControl(): IControl {
  return getControl(ControlType.Number);
}

export function getBooleanControl(): IControl {
  return getControl(ControlType.Boolean);
}

export function getDateControl(): IControl {
  return getControl(ControlType.Date);
}

export function getHiddenControl(): IHiddenControl {
  return {
    table: {
      disable: true,
    },
  };
}
