import { type EntityState } from '@ngrx/entity';
import { createFeatureSelector } from '@ngrx/store';
import {
  type IInvoice,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  type SerialisedData,
  type WithId,
  type WithRef,
} from '@principle-theorem/shared';

export type PatientEntity = WithRef<IPatient> & WithId<IPatient>;

export interface IPatientsState
  extends EntityState<SerialisedData<PatientEntity>> {
  selectedId?: string; // Which Patients record has been selected
  loaded: boolean; // Has the Patients list been loaded
  error?: string; // Last known error (if any)
}

export interface IInvoicesState
  extends EntityState<SerialisedData<WithRef<IInvoice>>> {
  draftId?: string;
  loaded: boolean;
}

export interface IPatientModuleState {
  patients: IPatientsState;
  invoices: IInvoicesState;
}

export const PATIENT_MODULE_KEY = 'patientModule';

export const getPatientModuleState =
  createFeatureSelector<IPatientModuleState>(PATIENT_MODULE_KEY);
