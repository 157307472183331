import {
  IAccountCreditExtendedData,
  isAccountCreditExtendedData,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ITransactionAction,
  ITransactionActionsData,
} from '../transaction-action';
import {
  getRefundInfo$,
  getRefundLabel$,
  getRefundRemaining$,
} from '../transaction-helpers';
import { AccountCreditTransactionProvider } from './account-credit-transaction-provider.service';

export class RefundAccountCreditTransaction
  implements ITransactionAction<IAccountCreditExtendedData>
{
  icon = 'undo';
  label = 'Refund';
  inProgress$ = new BehaviorSubject<boolean>(false);
  typeGuardFn = isAccountCreditExtendedData;

  constructor(private _provider: AccountCreditTransactionProvider) {}

  canDo$(
    data: ITransactionActionsData<IAccountCreditExtendedData>
  ): Observable<boolean> {
    return getRefundRemaining$(data).pipe(
      map((remaining) => {
        const isIncoming = data.transaction.type === TransactionType.Incoming;
        const hasRemaining = remaining > 0;
        return isIncoming && hasRemaining;
      })
    );
  }

  label$(
    data: ITransactionActionsData<IAccountCreditExtendedData>
  ): Observable<string> {
    return getRefundLabel$(data);
  }

  info$(
    data: ITransactionActionsData<IAccountCreditExtendedData>
  ): Observable<string[]> {
    return getRefundInfo$(data);
  }

  async do(
    data: ITransactionActionsData<IAccountCreditExtendedData>
  ): Promise<void> {
    this.inProgress$.next(true);
    await this._provider.refundTransaction(
      data.invoice,
      data.latestTransaction
    );
    this.inProgress$.next(false);
  }
}
