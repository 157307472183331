import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[prExpansionDisable]',
    standalone: false
})
export class ExpansionDisableDirective {
  constructor(renderer: Renderer2, hostElement: ElementRef) {
    renderer.addClass(hostElement.nativeElement, EXPANSION_DISABLE_CLASS);
  }
}

export const EXPANSION_DISABLE_CLASS = 'expansion-disable';
