import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CurrentBrandScope,
  GlobalStoreService,
} from '@principle-theorem/ng-principle-shared';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IBreadcrumb,
  type IConfirmationDialogInput,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { type ITreatmentCategory } from '@principle-theorem/principle-core/interfaces';
import { Brand } from '@principle-theorem/principle-core';
import {
  addDoc,
  deleteDoc,
  filterUndefined,
  patchDoc,
  type WithRef,
} from '@principle-theorem/shared';
import { pick } from 'lodash';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EditTreatmentCategoryComponent } from '../edit-treatment-category/edit-treatment-category.component';

@Component({
    selector: 'pr-treatment-categories-list',
    templateUrl: './treatment-categories-list.component.html',
    styleUrls: ['./treatment-categories-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreatmentCategoriesListComponent {
  trackByRef = TrackByFunctions.ref<WithRef<ITreatmentCategory>>();
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(
    private _currentBrand: CurrentBrandScope,
    private _dialog: MatDialog,
    public globalStore: GlobalStoreService
  ) {
    this.breadcrumbs$ = this._currentBrand.doc$.pipe(
      filterUndefined(),
      map((brand) => [
        { label: 'Settings', path: '../../../' },
        { label: brand.name },
        { label: 'Treatment Categories' },
      ])
    );
  }

  async create(): Promise<void> {
    const result = await this._openCategoryDialog();
    if (!result) {
      return;
    }
    const brand = await this._currentBrand.toPromise();
    await addDoc(Brand.treatmentCategoryCol(brand), result);
  }

  async edit(category: WithRef<ITreatmentCategory>): Promise<void> {
    const result = await this._openCategoryDialog(category);
    if (!result) {
      return;
    }
    await patchDoc(category.ref, pick(result, ['name', 'colour']));
  }

  async delete(category: WithRef<ITreatmentCategory>): Promise<void> {
    const data = confirmationDialogData({
      title: 'Delete Treatment Category',
      prompt: 'Are you sure you want to delete this treatment Category?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }
    await deleteDoc(category.ref);
  }

  private _openCategoryDialog(
    data?: ITreatmentCategory
  ): Promise<ITreatmentCategory | undefined> {
    return this._dialog
      .open<
        EditTreatmentCategoryComponent,
        ITreatmentCategory | undefined,
        ITreatmentCategory
      >(EditTreatmentCategoryComponent, DialogPresets.small({ data }))
      .afterClosed()
      .toPromise();
  }
}
