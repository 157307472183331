import { IEventable } from '@principle-theorem/principle-core/interfaces';
import { INamedDocument, isSameRef } from '@principle-theorem/shared';

export class EventMatcher {
  constructor(
    public before: IEventable | undefined,
    public after: IEventable | undefined
  ) {}

  public isSame(): boolean {
    if (!this.before || !this.after) {
      return false;
    }

    return (
      this._matchesFrom(this.before, this.after) &&
      this._matchesTo(this.before, this.after) &&
      this._matchesPractice(this.before, this.after) &&
      this._matchesParticipants(this.before, this.after)
    );
  }

  private _matchesFrom(before: IEventable, after: IEventable): boolean {
    if (
      (before.event.from && !after.event.from) ||
      (!before.event.from && after.event.from)
    ) {
      return false;
    }
    return before.event.from.seconds === after.event.from.seconds;
  }

  private _matchesTo(before: IEventable, after: IEventable): boolean {
    if (
      (before.event.to && !after.event.to) ||
      (!before.event.to && after.event.to)
    ) {
      return false;
    }
    return before.event.to.seconds === after.event.to.seconds;
  }

  private _matchesPractice(before: IEventable, after: IEventable): boolean {
    if (!before.event.practice) {
      return false;
    }
    if (!after.event.practice) {
      return false;
    }
    return isSameRef(before.event.practice, after.event.practice);
  }

  private _matchesParticipants(before: IEventable, after: IEventable): boolean {
    const beforeParticipants: string[] = before.event.participants.map(
      (participant: INamedDocument) => participant.ref.path
    );
    const afterParticipants: string[] = after.event.participants.map(
      (participant: INamedDocument) => participant.ref.path
    );

    if (before.event.participants.length !== after.event.participants.length) {
      return false;
    }

    return (
      beforeParticipants.every(
        (participant: string) => afterParticipants.indexOf(participant) > -1
      ) &&
      afterParticipants.every(
        (participant: string) => beforeParticipants.indexOf(participant) > -1
      )
    );
  }
}
