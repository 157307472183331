import {
  HicapsConnect,
  HicapsConnectMethod,
  PrincipleHicapsConnect,
  PrincipleHicapsConnectResponse,
} from '@principle-theorem/hicaps-connect';
import {
  Transaction,
  getBaseTransaction,
} from '@principle-theorem/principle-core';
import {
  IHealthcareClaim,
  IHicapsConnectHealthFundCancelTransactionExtendedData,
  IHicapsConnectHealthFundTransactionExtendedData,
  IInvoice,
  IPractice,
  ITransaction,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  WithRef,
  toTimestamp,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { HicapsConnectHelpers } from '../hicaps-connect-helpers';

export class HicapsConnectHealthFundTransactionBuilder {
  static async toHealthFundTransaction(
    invoice: WithRef<IInvoice>,
    practiceRef: DocumentReference<IPractice>,
    claim: IHealthcareClaim,
    request: HicapsConnect.ClaimRequest,
    response: PrincipleHicapsConnectResponse<HicapsConnectMethod.SendClaimRequest>
  ): Promise<ITransaction<IHicapsConnectHealthFundTransactionExtendedData>> {
    const base = await getBaseTransaction(invoice, TransactionType.Incoming);
    const extendedData = { request, response };

    if (!PrincipleHicapsConnect.hasSuccessResult(response)) {
      return Transaction.init({
        ...base,
        createdAt: toTimestamp(moment(response.timestamp)),
        reference: response.processUid,
        status: TransactionStatus.Failed,
        provider: TransactionProvider.HicapsConnectHealthFund,
        practiceRef,
        amount: request.TransactionAmount,
        attributedTo: claim.practitioner.ref,
        extendedData,
      });
    }

    const status = HicapsConnectHelpers.getStatus(response.data.ResponseCode);
    const amount =
      status === TransactionStatus.Complete
        ? response.data.BenefitAmount
        : request.TransactionAmount;

    return Transaction.init({
      ...base,
      createdAt: HicapsConnectHelpers.getTransactionDate(
        response.data.TransactionDate,
        response.timestamp
      ),
      reference: response.processUid,
      status,
      provider: TransactionProvider.HicapsConnectHealthFund,
      practiceRef,
      amount,
      attributedTo: claim.practitioner.ref,
      extendedData,
    });
  }

  static async toHealthFundCancelTransaction(
    invoice: WithRef<IInvoice>,
    practiceRef: DocumentReference<IPractice>,
    claim: IHealthcareClaim,
    request: HicapsConnect.ClaimCancelRequest,
    response: PrincipleHicapsConnectResponse<HicapsConnectMethod.SendClaimCancelRequest>,
    initialTransaction: WithRef<
      ITransaction<IHicapsConnectHealthFundTransactionExtendedData>
    >
  ): Promise<
    ITransaction<IHicapsConnectHealthFundCancelTransactionExtendedData>
  > {
    const base = await getBaseTransaction(invoice, TransactionType.Outgoing);
    const extendedData = { request, response };

    if (!PrincipleHicapsConnect.hasSuccessResult(response)) {
      return Transaction.init({
        ...base,
        createdAt: toTimestamp(moment(response.timestamp)),
        reference: initialTransaction.reference,
        status: TransactionStatus.Failed,
        provider: TransactionProvider.HicapsConnectHealthFund,
        practiceRef,
        amount: request.TransactionAmount,
        extendedData,
        attributedTo: claim.practitioner.ref,
      });
    }

    const status = HicapsConnectHelpers.getStatus(response.data.ResponseCode);
    const amount =
      status === TransactionStatus.Complete
        ? response.data.BenefitAmount
        : request.TransactionAmount;

    return Transaction.init({
      ...base,
      createdAt: toTimestamp(moment(response.data.TransactionDate)),
      reference: initialTransaction.reference,
      status: HicapsConnectHelpers.getStatus(response.data.ResponseCode),
      provider: TransactionProvider.HicapsConnectHealthFund,
      practiceRef,
      amount,
      extendedData,
      attributedTo: claim.practitioner.ref,
    });
  }
}
