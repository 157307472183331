import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AutomationDialogService } from '@principle-theorem/ng-automations';
import {
  ConfirmDialogComponent,
  DialogPresets,
  TrackByFunctions,
  confirmationDialogData,
  type IConfirmationDialogInput,
} from '@principle-theorem/ng-shared';
import { AutomationConfiguration } from '@principle-theorem/principle-core';
import {
  isGeneratedTask,
  type IBrand,
  type IGeneratedTaskConfiguration,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  getDocs,
  getParentDocRef,
  isSameRef,
  patchDoc,
  resolveDocRefs$,
  snapshot,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-treatment-generated-task-configuration',
  templateUrl: './treatment-generated-task-configuration.component.html',
  styleUrls: ['./treatment-generated-task-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentGeneratedTaskConfigurationComponent {
  resource$ = new ReplaySubject<WithRef<ITreatmentConfiguration>>(1);
  trackByTask = TrackByFunctions.ref<WithRef<IGeneratedTaskConfiguration>>();
  generatedTasks$: Observable<WithRef<IGeneratedTaskConfiguration>[]>;

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _automationDialog: AutomationDialogService
  ) {
    this.generatedTasks$ = this.resource$.pipe(
      switchMap((resource) => resolveDocRefs$(resource.generatedTaskRefs)),
      map((configs): WithRef<IGeneratedTaskConfiguration>[] =>
        configs.filter(
          (config): config is WithRef<IGeneratedTaskConfiguration> =>
            isGeneratedTask(config)
        )
      )
    );
  }

  @Input()
  set resource(resource: WithRef<ITreatmentConfiguration>) {
    if (resource) {
      this.resource$.next(resource);
    }
  }

  async useExistingConfig(): Promise<void> {
    const resource = await snapshot(this.resource$);
    const brandRef = getParentDocRef<IBrand>(resource.ref);
    const configs = await getDocs(
      AutomationConfiguration.col({ ref: brandRef })
    );
    const taskConfigs = configs
      .filter((config): config is WithRef<IGeneratedTaskConfiguration> =>
        isGeneratedTask(config)
      )
      .filter(
        (config) =>
          !resource.generatedTaskRefs.some((ref) => isSameRef(ref, config.ref))
      );

    const response = await this._automationDialog.selectConfiguration({
      notificationConfigs: [],
      taskConfigs,
    });

    if (!response || !response.task) {
      return;
    }

    await AutomationConfiguration.addTreatmentRef(response.task, resource.ref);

    await this._save([...resource.generatedTaskRefs, response.task.ref]);
    this._snackBar.open('Task configuration added');
  }

  async createAutomationConfig(): Promise<void> {
    const task = await this._automationDialog.openTaskDialog({
      useRelativeTime: true,
    });
    if (!task) {
      return;
    }

    const resource = await snapshot(this.resource$);
    const brandRef = getParentDocRef<IBrand>(resource.ref);
    const automationConfig = AutomationConfiguration.init({
      ...task,
      treatmentRefs: [resource.ref],
    });
    const automationConfigRef = await addDoc(
      AutomationConfiguration.col({ ref: brandRef }),
      automationConfig
    );

    await this._save([
      ...resource.generatedTaskRefs,
      automationConfigRef as DocumentReference<IGeneratedTaskConfiguration>,
    ]);
    this._snackBar.open('Task configuration saved');
  }

  async edit(task: WithRef<IGeneratedTaskConfiguration>): Promise<void> {
    const updatedTask = await this._automationDialog.openTaskDialog({
      task,
      useRelativeTime: true,
    });
    if (!updatedTask) {
      return;
    }

    await patchDoc(task.ref, updatedTask);
  }

  async delete(task: WithRef<IGeneratedTaskConfiguration>): Promise<void> {
    const data = confirmationDialogData({
      title: 'Remove Generated Task',
      prompt: 'Are you sure you want to remove this generated task?',
      submitLabel: 'Remove',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }

    const resource = await snapshot(this.resource$);
    await AutomationConfiguration.removeTreatmentRef(task, resource.ref);

    const generatedTaskRefs = resource.generatedTaskRefs.filter(
      (item) => !isSameRef(task, item)
    );
    await this._save(generatedTaskRefs);
    this._snackBar.open('Generated Task Removed');
  }

  private async _save(
    generatedTaskRefs: DocumentReference<IGeneratedTaskConfiguration>[]
  ): Promise<void> {
    const resource = await snapshot(this.resource$);
    await patchDoc<ITreatmentConfiguration>(resource.ref, {
      generatedTaskRefs,
    });
  }
}
