<mat-toolbar color="accent">Display</mat-toolbar>
<div class="m-4 flex flex-col gap-6">
  @for (outlet of outlets; track outlet.value) {
    <div class="flex flex-auto flex-col gap-1">
      <h3 class="text-baseline !m-0 !font-bold">{{ outlet.label }}</h3>
      <div class="flex flex-row gap-4">
        <div class="text-sm">{{ outlet.description }}</div>
        <img
          class="flex h-24 w-80 shrink-0 grow-0 rounded-lg border border-solid border-slate-200 bg-slate-100 object-cover"
          [src]="outlet.imageUrl"
        />
      </div>
    </div>
  }
</div>
