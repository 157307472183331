import {
  getTaxRateByRegion,
  roundTo2Decimals,
  TaxStrategy,
} from '@principle-theorem/accounting';
import {
  ADA_CODE_TREATMENT_CONFIG_ID,
  Brand,
  CalculateTreatmentWeight,
  ChartedItemScopeResolver,
  ChartedSurface,
  ChartedTreatment,
  determineTaxFromStrategy,
  FeeSchedule,
  getQuadrant,
  getQuadrantIndex,
  PricedServiceCodeEntry,
  stafferToNamedDoc,
  TreatmentConfiguration,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  ChartableSurface,
  IDestinationEntityJobRunOptions,
  IPractice,
  isToothNumber,
  ITranslationMap,
  ITreatmentCategory,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  type FailedDestinationEntityRecord,
  type IChartedRef,
  type IChartedTreatment,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IFeeSchedule,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type IStaffer,
  type IToothRef,
  type ToothNumber,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  Firestore,
  getDoc$,
  getError,
  HISTORY_DATE_FORMAT,
  IReffable,
  multiSwitchMap,
  resolveSequentially,
  snapshotCombineLatest,
  sortByCreatedAt,
  sortTimestamp,
  sortTimestampAsc,
  Timestamp,
  Timezone,
  toISODate,
  toMoment,
  toMomentTz,
  toNamedDocument,
  toTimestamp,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first, groupBy, sortBy, sum, uniqBy } from 'lodash';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { determinePlanStatus } from '../../../d4w/destination/entities/patient-treatment-plans';
import { DestinationEntity } from '../../../destination/destination-entity';
import { feeScheduleResolverFn } from '../../../destination/entities/fee-schedules';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import {
  BasePatientTreatmentPlanDestinationEntity,
  IPatientTreatmentPlanJobData,
  IPatientTreatmentPlanMigrationData,
  IStepWithIdentifiers,
  ITreatmentPlanStepPair,
  PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY,
} from '../../../destination/entities/patient-treatment-plans';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { resolveMappedCode } from '../../../mappings/item-codes';
import { PRACTICE_MAPPING } from '../../../mappings/practices';
import { getPractitionerOrDefault } from '../../../mappings/staff';
import { PracticeMigration } from '../../../practice-migrations';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  IOasisPatientAppointment,
  IOasisPatientAppointmentFilters,
  IOasisPatientAppointmentTranslations,
  PatientAppointmentSourceEntity,
} from '../../source/entities/patient-appointments';
import {
  IOasisPatientTreatmentPlanTreatment,
  IOasisPatientTreatmentPlanTreatmentFilters,
  IOasisPatientTreatmentPlanTreatmentTranslations,
  PatientTreatmentPlanTreatmentsSourceEntity,
} from '../../source/entities/patient-treatment-plan-treatments';
import {
  IOasisPatientTreatment,
  IOasisPatientTreatmentFilters,
  IOasisPatientTreatmentTranslations,
  PatientTreatmentsSourceEntity,
} from '../../source/entities/patient-treatments';
import {
  IOasisPatient,
  IOasisPatientFilters,
  IOasisPatientTranslations,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { OasisItemCodeToTreatmentMappingHandler } from '../mappings/item-code-to-treatment';
import { OasisItemCodeMappingHandler } from '../mappings/item-codes';
import { OasisPracticeMappingHandler } from '../mappings/practices';
import { OasisStafferMappingHandler } from '../mappings/staff';
import { getClinicalNotePractitioner } from './patient-clinical-notes';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';

export const OASIS_PLAN_NAME = 'OASiS - Migrated Treatments';

const patientTreatmentPlanDestinationEntity =
  DestinationEntity.withMetadataDescription(
    PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY,
    `This migrates the treatments for each patient's appointment and creates a step on a single plan for each appointment.`
  );

type JobData = IPatientTreatmentPlanJobData<
  IOasisPatient,
  IOasisPatientTranslations,
  IOasisPatientFilters
> & {
  practices: WithRef<ITranslationMap<IPractice>>[];
};

export class PatientTreatmentPlanDestinationEntity extends BasePatientTreatmentPlanDestinationEntity<
  IOasisPatient,
  JobData
> {
  override destinationEntity = patientTreatmentPlanDestinationEntity;
  patientSourceEntity = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
    treatments: new PatientTreatmentsSourceEntity(),
    treatmentPlanTreatments: new PatientTreatmentPlanTreatmentsSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new OasisStafferMappingHandler(),
    practices: new OasisPracticeMappingHandler(),
    itemCodes: new OasisItemCodeMappingHandler(),
    itemCodeToTreatment: new OasisItemCodeToTreatmentMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<JobData[]> {
    const brand$ = PracticeMigration.brand$(migration);
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const practices$ =
      this.customMappings.practices.getRecords$(translationMap);
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);
    const treatmentConfigurationMappings$ =
      this.customMappings.itemCodeToTreatment.getRecords$(translationMap);
    const defaultTreatmentConfiguration$ = brand$.pipe(
      switchMap((brand) =>
        getDoc$(TreatmentConfiguration.col(brand), ADA_CODE_TREATMENT_CONFIG_ID)
      )
    );
    const treatmentConfigurations$ = brand$.pipe(
      switchMap((brand) => TreatmentConfiguration.all$(brand))
    );
    const practitioners$ = staff$.pipe(
      multiSwitchMap((staffer) =>
        staffer.destinationIdentifier
          ? Firestore.getDoc(staffer.destinationIdentifier)
          : of(undefined)
      ),
      map(compact)
    );
    const treatmentCategories$ = brand$.pipe(
      switchMap((brand) => Brand.treatmentCategories$(brand))
    );
    const taxRate$ = PracticeMigration.organisation$(migration).pipe(
      map((org) => getTaxRateByRegion(org.region))
    );

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([
        staff$,
        practitioners$,
        defaultTreatmentConfiguration$,
        treatmentConfigurations$,
        sourceItemCodes$,
        treatmentConfigurationMappings$,
        treatmentCategories$,
        practices$,
        taxRate$,
      ]),
    ]).pipe(
      map(
        ([
          sourcePatients,
          [
            staff,
            practitioners,
            defaultTreatmentConfiguration,
            treatmentConfigurations,
            sourceItemCodes,
            treatmentConfigurationMappings,
            treatmentCategories,
            practices,
            taxRate,
          ],
        ]) =>
          sourcePatients.map((sourcePatient) => ({
            sourcePatient,
            staff,
            practitioners,
            defaultTreatmentConfiguration,
            treatmentConfigurations,
            sourceItemCodes,
            treatmentConfigurationMappings,
            treatmentCategories,
            practices,
            taxRate,
          }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: JobData
  ): Promise<
    | IPatientTreatmentPlanMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients.getSourceRecordId(
      data.sourcePatient.data.data
    );

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(data.sourcePatient, 'No patient');
    }

    const sourceTreatments = await this.sourceEntities.treatments.filterRecords(
      migration,
      'patientId',
      data.sourcePatient.data.data.id
    );

    const sourceTreatmentPlanTreatments =
      await this.sourceEntities.treatmentPlanTreatments.filterRecords(
        migration,
        'patientId',
        data.sourcePatient.data.data.id
      );

    const sourceAppointments =
      await this.sourceEntities.appointments.filterRecords(
        migration,
        'patientId',
        data.sourcePatient.data.data.id
      );

    try {
      const planPairs =
        await OasisTreatmentPlanDataBuilder.buildTreatmentPlanData(
          data,
          translationMap,
          this.sourceEntities.appointments,
          sourceTreatments,
          sourceTreatmentPlanTreatments,
          sourceAppointments,
          migration,
          sourcePatientId
        );

      return {
        sourcePatientId: sourcePatientId.toString(),
        patientRef,
        planPairs,
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }
}

export class OasisTreatmentPlanDataBuilder {
  static async buildTreatmentPlanData(
    data: JobData,
    translationMap: TranslationMapHandler,
    appointmentResolver: PatientAppointmentSourceEntity,
    sourceTreatments: IGetRecordResponse<
      IOasisPatientTreatment,
      IOasisPatientTreatmentTranslations,
      IOasisPatientTreatmentFilters
    >[],
    sourceTreatmentPlanTreatments: IGetRecordResponse<
      IOasisPatientTreatmentPlanTreatment,
      IOasisPatientTreatmentPlanTreatmentTranslations,
      IOasisPatientTreatmentPlanTreatmentFilters
    >[],
    sourceAppointments: IGetRecordResponse<
      IOasisPatientAppointment,
      IOasisPatientAppointmentTranslations,
      IOasisPatientAppointmentFilters
    >[],
    migration: WithRef<IPracticeMigration>,
    sourcePatientId: number
  ): Promise<ITreatmentPlanStepPair[]> {
    const defaultFeeSchedule = await FeeSchedule.getPreferredOrDefault(
      migration.configuration.organisation
    );

    const completedSteps: IStepWithIdentifiers[] = [];
    const pendingSteps: IStepWithIdentifiers[] = [];

    const appointmentMatcher = new AppointmentMatchFinder(
      sourceAppointments.sort((appointmentA, appointmentB) =>
        sortTimestampAsc(
          appointmentA.data.translations.from,
          appointmentB.data.translations.from
        )
      ),
      (sourceAppointment) =>
        toISODate(
          sourceAppointment.data.translations.from,
          migration.configuration.timezone
        )
    );

    const planCreatedAt = this.determinePlanCreatedAt(sourceTreatments);
    // const planPractitioner = this.determinePlanPractitioner(
    //   sourceTreatments,
    //   data.staff,
    //   data.practitioners
    // );

    const plan = this.buildPlan(sourcePatientId, planCreatedAt);

    const treatmentStepGroups = groupBy(
      sourceTreatments,
      (treatment) => treatment.data.data.appointmentDate
    );

    await asyncForEach(
      Object.values(treatmentStepGroups),
      async (treatments) => {
        await resolveSequentially(treatments, async (treatment) => {
          const chartedTreatments = await this.getChartedTreatments(
            [treatment.data.data],
            feeScheduleResolverFn(translationMap, defaultFeeSchedule),
            data,
            migration
          );

          if (!chartedTreatments.length) {
            return;
          }

          const sourceIdentifier = await this.getStepIdentifier(
            migration,
            appointmentResolver,
            sourcePatientId,
            migration.configuration.timezone,
            treatment
          );

          if (!sourceIdentifier) {
            throw new Error(
              `No source identifier found for treatment: ${treatment.record.uid}`
            );
          }

          if (!treatment.data.translations.appointmentDate) {
            return;
          }

          const existingStep = completedSteps.find(
            (step) => step.sourceIdentifier === sourceIdentifier
          );

          if (existingStep) {
            existingStep.treatments.push(...chartedTreatments);
            return;
          }

          const completedStep = await this.buildCompletedStep(
            migration,
            appointmentResolver,
            treatment,
            chartedTreatments,
            data.treatmentCategories,
            sourcePatientId,
            appointmentMatcher
          );

          completedSteps.push(completedStep);
        });

        const backupDate = toMomentTz(
          migration.configuration.backupDate,
          migration.configuration.timezone
        );
        const upcomingAppointments = appointmentMatcher
          .remainingResults()
          .filter((sourceAppointment) =>
            toMoment(sourceAppointment.data.translations.from).isAfter(
              backupDate
            )
          );

        await resolveSequentially(
          upcomingAppointments,
          async (upcomingAppointment) => {
            const appointmentTreatments = sourceTreatmentPlanTreatments.filter(
              (sourceTreatmentPlanTreatment) => {
                if (
                  !sourceTreatmentPlanTreatment.data.data.appointmentRefId ||
                  !upcomingAppointment.data.data.treatmentStepId
                ) {
                  return false;
                }

                return (
                  sourceTreatmentPlanTreatment.data.data.appointmentRefId ===
                  upcomingAppointment.data.data.treatmentStepId
                );
              }
            );

            const sourceIdentifier = await this.getStepIdentifier(
              migration,
              appointmentResolver,
              sourcePatientId,
              migration.configuration.timezone,
              undefined,
              upcomingAppointment
            );

            if (!sourceIdentifier) {
              throw new Error(
                `No source identifier found for appointment: ${upcomingAppointment.record.uid}`
              );
            }

            const pendingStep: IStepWithIdentifiers =
              await this.buildPendingStep(
                upcomingAppointment,
                appointmentTreatments,
                migration,
                data,
                sourceIdentifier,
                translationMap,
                defaultFeeSchedule
              );

            const existingStep = pendingSteps.find(
              (step) => step.sourceIdentifier === sourceIdentifier
            );

            if (existingStep) {
              existingStep.treatments = uniqBy(
                [...existingStep.treatments, ...pendingStep.treatments],
                (treatment) => treatment.uuid
              );
              return;
            }

            pendingSteps.push(pendingStep);
          }
        );
      }
    );

    const steps = [
      ...sortBy(completedSteps, (step) => step.date),
      ...sortBy(pendingSteps, (step) => step.date),
    ];

    if (!planCreatedAt) {
      const earliestStepDate = [...steps].sort(sortByCreatedAt).pop()
        ?.createdAt;
      if (earliestStepDate) {
        plan.createdAt = earliestStepDate;
      }
    }

    const status = determinePlanStatus(steps, plan.createdAt);

    const incompletePlanTreatments = sourceTreatmentPlanTreatments.filter(
      (treatment) =>
        !treatment.data.data.completedAt &&
        !treatment.data.data.appointmentRefId
    );

    const incompletePlans = await this.buildIncompletePlans(
      incompletePlanTreatments,
      data,
      translationMap,
      defaultFeeSchedule,
      migration
    );

    return [
      {
        plan: {
          ...plan,
          status,
        },
        steps,
      },
      ...compact(incompletePlans),
    ];
  }

  static async buildIncompletePlans(
    incompletePlanTreatments: IGetRecordResponse<
      IOasisPatientTreatmentPlanTreatment,
      IOasisPatientTreatmentPlanTreatmentTranslations,
      IOasisPatientTreatmentPlanTreatmentFilters
    >[],
    data: JobData,
    translationMap: TranslationMapHandler,
    defaultFeeSchedule: WithRef<IFeeSchedule>,
    migration: WithRef<IPracticeMigration>
  ): Promise<(ITreatmentPlanStepPair | undefined)[]> {
    const incompleteTreatmentPlanGroups = groupBy(
      incompletePlanTreatments,
      (treatment) => treatment.data.data.treatmentPlanId
    );
    return resolveSequentially(
      Object.values(incompleteTreatmentPlanGroups),
      async (incompletePlanGroup) => {
        const planSourceTreatment = sortBy(
          incompletePlanGroup,
          (treatment) => treatment.data.data.id
        )[0];
        const planPractitioner = await getClinicalNotePractitioner(
          planSourceTreatment.data.data.practitionerId.toString(),
          data.staff
        );

        const incompleteStepGroups = groupBy(
          incompletePlanGroup,
          (treatment) => treatment.data.data.stepNumber
        );
        const incompleteSteps: (IStepWithIdentifiers | undefined)[] =
          await asyncForEach(
            Object.values(incompleteStepGroups),
            async (incompleteStepGroup) => {
              const sortedStepGroup = sortBy(
                incompleteStepGroup,
                (step) => step.data.data.id
              );
              const sourceTreatment = sortedStepGroup[0];
              const staffer = await getClinicalNotePractitioner(
                sourceTreatment.data.data.practitionerId.toString(),
                data.staff
              );
              const chartedTreatments = await this.getChartedTreatments(
                sortedStepGroup.map((group) => group.data.data),
                feeScheduleResolverFn(translationMap, defaultFeeSchedule),
                data,
                migration
              );

              if (!chartedTreatments.length) {
                return;
              }

              const sourceIdentifier =
                sourceTreatment.data.data.treatmentPlanId.toString();
              const duration = sum(
                sortedStepGroup.map((step) => step.data.data.minutes ?? 0)
              );

              const treatmentStep = TreatmentStep.init({
                name:
                  sourceTreatment.data.data.treatmentStepName ??
                  `Step ${sourceTreatment.data.data.stepNumber}`,
                treatments: chartedTreatments,
                status: TreatmentStepStatus.Incomplete,
                practitionerRef: staffer?.ref,
                schedulingRules: { duration },
              });
              const treatmentCategory =
                await CalculateTreatmentWeight.getPrimaryCategory(
                  treatmentStep,
                  data.treatmentCategories
                );

              return {
                sourceIdentifier,
                ...treatmentStep,
                display: {
                  ...treatmentStep.display,
                  primaryTreatmentCategory: treatmentCategory?.ref,
                },
                createdAt: sourceTreatment.data.translations.createdAt,
                date: toISODate(sourceTreatment.data.data.createdAt),
              };
            }
          );

        const sourceIdentifier = `${planSourceTreatment.data.data.patientId}-plan-${planSourceTreatment.data.data.treatmentPlanId}`;
        const steps = compact(incompleteSteps);
        if (!steps.length) {
          return;
        }
        return {
          plan: {
            ...TreatmentPlan.init({
              name: planSourceTreatment.data.data.treatmentPlanName,
              practitioner: planPractitioner
                ? stafferToNamedDoc(planPractitioner)
                : undefined,
              createdAt: planSourceTreatment.data.data.createdAt,
              status: determinePlanStatus(
                steps,
                planSourceTreatment.data.data.createdAt
              ),
            }),
            sourceIdentifier,
          },
          steps,
        };
      }
    );
  }

  static async buildPendingStep(
    appointment: IGetRecordResponse<
      IOasisPatientAppointment,
      IOasisPatientAppointmentTranslations,
      IOasisPatientAppointmentFilters
    >,
    treatments: IGetRecordResponse<
      IOasisPatientTreatmentPlanTreatment,
      IOasisPatientTreatmentPlanTreatmentTranslations,
      IOasisPatientTreatmentPlanTreatmentFilters
    >[],
    migration: WithRef<IPracticeMigration>,
    data: JobData,
    sourceIdentifier: string,
    translationMap: TranslationMapHandler,
    defaultFeeSchedule: WithRef<IFeeSchedule>
  ): Promise<IStepWithIdentifiers> {
    const date = toMomentTz(
      appointment.data.translations.from,
      migration.configuration.timezone
    );
    const staffer = await getClinicalNotePractitioner(
      appointment.data.data.practitionerId.toString(),
      data.staff
    );

    const chartedTreatments = await this.getChartedTreatments(
      treatments.flatMap((treatment) => treatment.data.data),
      feeScheduleResolverFn(translationMap, defaultFeeSchedule),
      data,
      migration
    );

    const step = TreatmentStep.init({
      name: `${appointment.data.data.treatmentSummary} - ${date.format(
        HISTORY_DATE_FORMAT
      )}`,
      treatments: chartedTreatments,
      status: TreatmentStepStatus.Incomplete,
      practitionerRef: staffer?.ref,
    });

    const treatmentCategory = await CalculateTreatmentWeight.getPrimaryCategory(
      step,
      data.treatmentCategories
    );

    const pendingStep: IStepWithIdentifiers = {
      ...step,
      display: {
        ...step.display,
        primaryTreatmentCategory: treatmentCategory?.ref,
      },
      createdAt: appointment.data.translations.createdAt,
      date: toISODate(date),
      sourceIdentifier,
    };
    return pendingStep;
  }

  static async buildCompletedStep(
    migration: WithRef<IPracticeMigration>,
    appointmentResolver: PatientAppointmentSourceEntity,
    treatment: IGetRecordResponse<
      IOasisPatientTreatment,
      IOasisPatientTreatmentTranslations,
      IOasisPatientTreatmentFilters
    >,
    chartedTreatments: IChartedTreatment[],
    treatmentCategories: WithRef<ITreatmentCategory>[],
    sourcePatientId: number,
    appointmentMatcher: AppointmentMatchFinder<
      IOasisPatientAppointment,
      IOasisPatientAppointmentTranslations,
      IOasisPatientAppointmentFilters
    >
  ): Promise<IStepWithIdentifiers> {
    const appointmentDate = treatment.data.translations.appointmentDate;
    const matchingAppointment = appointmentMatcher.findFirstMatch(
      toISODate(appointmentDate, migration.configuration.timezone)
    );

    if (matchingAppointment) {
      appointmentMatcher.removeMatch(matchingAppointment);
    }

    const sourceIdentifier = await this.getStepIdentifier(
      migration,
      appointmentResolver,
      sourcePatientId,
      migration.configuration.timezone,
      treatment
    );

    if (!sourceIdentifier) {
      throw new Error(
        `No source identifier found for treatment: ${treatment.record.uid}`
      );
    }

    const date = toMomentTz(appointmentDate, migration.configuration.timezone);
    const stepName = matchingAppointment
      ? `${matchingAppointment.data.data.treatmentSummary} ${date.format(
          HISTORY_DATE_FORMAT
        )}`
      : `Completed - ${date.format(HISTORY_DATE_FORMAT)}`;

    const step = TreatmentStep.init({
      name: stepName,
      treatments: chartedTreatments,
      status: TreatmentStepStatus.Complete,
    });
    const treatmentCategory = await CalculateTreatmentWeight.getPrimaryCategory(
      step,
      treatmentCategories
    );

    const completedStep: IStepWithIdentifiers = {
      ...step,
      display: {
        ...step.display,
        primaryTreatmentCategory: treatmentCategory?.ref,
      },
      createdAt: treatment.data.translations.createdAt,
      date: appointmentDate,
      sourceIdentifier,
    };
    return completedStep;
  }

  static buildPlan(
    sourcePatientId: number,
    planCreatedAt?: Timestamp,
    planPractitioner?: INamedDocument<IStaffer>
  ): ITreatmentPlanStepPair['plan'] {
    const sourceIdentifier =
      OasisTreatmentPlanDataBuilder.getPlanIdentifier(sourcePatientId);
    return {
      ...TreatmentPlan.init({
        name: OASIS_PLAN_NAME,
        practitioner: planPractitioner
          ? stafferToNamedDoc(planPractitioner)
          : undefined,
        createdAt: planCreatedAt ?? toTimestamp(),
      }),
      sourceIdentifier,
    };
  }

  static getPlanIdentifier(sourcePatientId: number): string {
    return `${sourcePatientId}-plan`;
  }

  static determinePlanPractitioner(
    treatments: IGetRecordResponse<
      IOasisPatientTreatment,
      IOasisPatientTreatmentTranslations,
      IOasisPatientTreatmentFilters
    >[],
    staff: WithRef<ITranslationMap<IStaffer>>[],
    practitioners: WithRef<IStaffer>[]
  ): INamedDocument<IStaffer> | undefined {
    const practitioner = getPractitionerOrDefault(
      treatments[0].data.data.practitionerId,
      staff,
      practitioners
    );

    if (!practitioner) {
      return;
    }

    return stafferToNamedDoc(practitioner);
  }

  static determinePlanCreatedAt(
    treatments: IGetRecordResponse<
      IOasisPatientTreatment,
      IOasisPatientTreatmentTranslations,
      IOasisPatientTreatmentFilters
    >[]
  ): Timestamp | undefined {
    return first(
      compact(
        [...treatments].map(
          (treatment) => treatment.data.translations.createdAt
        )
      )
        .sort(sortTimestamp)
        .reverse()
    );
  }

  static async resolvePractice(
    practices: WithRef<ITranslationMap<IPractice>>[],
    treatments: IGetRecordResponse<
      IOasisPatientTreatment,
      IOasisPatientTreatmentTranslations,
      IOasisPatientTreatmentFilters
    >[],
    translationMap: TranslationMapHandler
  ): Promise<WithRef<IPractice>> {
    const sourcePractice = practices.find(
      (practice) =>
        practice.sourceIdentifier ===
        treatments[0].data.data.locationId?.toString()
    );

    if (!sourcePractice) {
      throw new Error(
        `No practice found for id: ${treatments[0].data.data.locationId}`
      );
    }

    const practiceRef = await translationMap.getDestination<IPractice>(
      sourcePractice.sourceIdentifier,
      PRACTICE_MAPPING.metadata.type
    );

    if (!practiceRef) {
      throw new Error(
        `No practice found for id: ${sourcePractice.sourceIdentifier}`
      );
    }

    return Firestore.getDoc(practiceRef);
  }

  static buildPlanName(): string {
    return OASIS_PLAN_NAME;
  }

  static getPlanStatus(
    stepStatuses: TreatmentStepStatus[]
  ): TreatmentPlanStatus {
    if (!stepStatuses.length) {
      return TreatmentPlanStatus.Draft;
    }

    return stepStatuses.filter(
      (stepStatus) => stepStatus === TreatmentStepStatus.Complete
    ).length
      ? TreatmentPlanStatus.InProgress
      : TreatmentPlanStatus.Completed;
  }

  static async getChartedTreatments(
    sourceTreatments: (
      | IOasisPatientTreatment
      | IOasisPatientTreatmentPlanTreatment
    )[],
    getPreferredOrDefaultRef: (
      feeSheduleId?: string
    ) => Promise<INamedDocument<IFeeSchedule>>,
    data: JobData,
    migration: WithRef<IPracticeMigration>
  ): Promise<IChartedTreatment[]> {
    const treatments: IChartedTreatment[] = [];

    await asyncForEach(sourceTreatments, async (sourceTreatment) => {
      const treatmentProviderId = sourceTreatment.practitionerId.toString();
      const practitioner = getPractitionerOrDefault(
        treatmentProviderId,
        data.staff,
        data.practitioners
      );

      if (!practitioner) {
        throw new Error(
          `Couldn't resolve practitioner for treatment ${treatmentProviderId}`
        );
      }

      let treatmentCode = sourceTreatment.itemCode;
      if (!treatmentCode) {
        return;
      }
      if (treatmentCode.length === 2) {
        treatmentCode = `0${treatmentCode}`;
      }
      const code = resolveMappedCode(
        data.sourceItemCodes,
        sourceTreatment.itemCode,
        sourceTreatment.itemCode
      );
      if (!code) {
        return;
      }

      const itemCodeId = sourceTreatment.itemCode;
      const mappedTreatment = data.treatmentConfigurationMappings.find(
        (mapping) => mapping.sourceIdentifier === itemCodeId
      );
      const treatmentConfiguration = mappedTreatment?.destinationIdentifier
        ? await Firestore.getDoc(mappedTreatment?.destinationIdentifier)
        : data.defaultTreatmentConfiguration;

      const feeSchedule = await getPreferredOrDefaultRef();
      const taxStatus = TaxStrategy.GSTFree;
      const price = roundTo2Decimals(sourceTreatment.amount ?? 0);

      const toothNumber = sourceTreatment.toothNumber
        ? String(sourceTreatment.toothNumber).trim()
        : undefined;
      if (!isToothNumber(toothNumber) && toothNumber) {
        // eslint-disable-next-line no-console
        console.info(`${toothNumber} isn't a valid tooth number`);
      }

      const serviceCode = PricedServiceCodeEntry.init({
        code: code.code,
        type: code.type,
        taxStatus,
        price,
        quantity: sourceTreatment.quantity,
        tax: determineTaxFromStrategy(data.taxRate, {
          taxStatus: taxStatus,
          amount: price,
        }),
        chartedSurfaces: isToothNumber(toothNumber)
          ? this.getChartedRefs({ toothNumber }).map((chartedRef) =>
              ChartedSurface.init({
                chartedBy: stafferToNamedDoc(practitioner),
                resolvedBy:
                  'appointmentDate' in sourceTreatment
                    ? this.getResolvedBy(sourceTreatment, practitioner)
                    : undefined,
                resolvedAt:
                  'appointmentDate' in sourceTreatment
                    ? toTimestamp(
                        toMomentTz(
                          sourceTreatment.appointmentDate,
                          migration.configuration.timezone
                        )
                      )
                    : undefined,
                chartedRef,
              })
            )
          : [],
      });

      const scopeResolver = new ChartedItemScopeResolver();
      const scopeRefs = scopeResolver.reduceChartedSurfacesToScope(
        treatmentConfiguration,
        serviceCode.chartedSurfaces
      );
      const scopeRef = first(scopeRefs)?.scopeRef ?? {
        scope: ChartableSurface.WholeMouth,
      };

      treatments.push(
        ChartedTreatment.init({
          uuid: sourceTreatment.id.toString(),
          config: toNamedDocument(treatmentConfiguration),
          feeSchedule,
          serviceCodes: [serviceCode],
          chartedSurfaces: serviceCode.chartedSurfaces,
          attributedTo: stafferToNamedDoc(practitioner),
          scopeRef,
          resolvedBy:
            'appointmentDate' in sourceTreatment
              ? this.getResolvedBy(sourceTreatment, practitioner)
              : undefined,
          resolvedAt:
            'appointmentDate' in sourceTreatment
              ? toTimestamp(
                  toMomentTz(
                    sourceTreatment.appointmentDate,
                    migration.configuration.timezone
                  )
                )
              : undefined,
        })
      );
    });

    return treatments;
  }

  static async getStepIdentifier(
    migration: IReffable<IPracticeMigration>,
    appointmentResolver: PatientAppointmentSourceEntity,
    sourcePatientId: number,
    timezone: Timezone,
    treatment?: IGetRecordResponse<
      IOasisPatientTreatment,
      IOasisPatientTreatmentTranslations,
      IOasisPatientTreatmentFilters
    >,
    appointment?: IGetRecordResponse<
      IOasisPatientAppointment,
      IOasisPatientAppointmentTranslations
    >,
    patientAppointments?: IGetRecordResponse<
      IOasisPatientAppointment,
      IOasisPatientAppointmentTranslations
    >[]
  ): Promise<string> {
    if (treatment) {
      const completedDate = toISODate(
        treatment.data.translations.appointmentDate,
        timezone
      );

      return `${sourcePatientId}-${completedDate}`;
    }

    if (!appointment) {
      throw new Error('No appointment or treatment provided');
    }

    const appointmentDate = toISODate(
      appointment.data.translations.from,
      timezone
    );

    const sameDayAppointments = (
      patientAppointments ??
      (await appointmentResolver.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      ))
    ).filter(
      (filteredAppointment) =>
        toISODate(filteredAppointment.data.translations.from, timezone) ===
        appointmentDate
    );

    const sortedAppointments = [...sameDayAppointments].sort(
      (appointmentA, appointmentB) =>
        sortTimestampAsc(
          appointmentA.data.translations.from,
          appointmentB.data.translations.from
        )
    );

    const appointmentIndex = sortedAppointments.findIndex(
      (sortedAppointment) =>
        sortedAppointment.record.uid === appointment.record.uid
    );

    if (appointmentIndex < 1) {
      return `${sourcePatientId}-${appointmentDate}`;
    }

    return `${sourcePatientId}-${appointmentDate}-${appointmentIndex + 1}`;
  }

  static getResolvedBy(
    procedure: IOasisPatientTreatment,
    practitioner: WithRef<IStaffer> | INamedDocument<IStaffer>
  ): INamedDocument<IStaffer> | undefined {
    return procedure.appointmentDate
      ? stafferToNamedDoc(practitioner)
      : undefined;
  }

  static getChartedRefs(treatment: {
    toothNumber?: ToothNumber;
  }): Partial<IChartedRef>[] {
    const quadrant = treatment.toothNumber
      ? getQuadrant(treatment.toothNumber)
      : undefined;
    const quadrantIndex = treatment.toothNumber
      ? getQuadrantIndex(treatment.toothNumber)
      : undefined;
    if (!treatment.toothNumber || !quadrant || !quadrantIndex) {
      return [
        {
          unscoped: true,
        },
      ];
    }

    const toothRef: IToothRef = {
      quadrant,
      quadrantIndex,
    };

    return [
      {
        tooth: toothRef,
      },
    ];
  }
}

export class AppointmentMatchFinder<
  Data extends object = object,
  Translations extends object = object,
  Filters extends object = object,
> {
  private _appointments: IGetRecordResponse<Data, Translations, Filters>[];

  constructor(
    appointments: IGetRecordResponse<Data, Translations, Filters>[],
    private _getComparisonValueFn: (
      appointment: IGetRecordResponse<Data, Translations, Filters>
    ) => string
  ) {
    this._appointments = [...appointments];
  }

  remainingResults(): IGetRecordResponse<Data, Translations, Filters>[] {
    return [...this._appointments];
  }

  findMatches(
    comparisonValue: string
  ): IGetRecordResponse<Data, Translations, Filters>[] {
    return this._appointments.filter(
      (appointment) =>
        this._getComparisonValueFn(appointment) === comparisonValue
    );
  }

  findFirstMatch(
    comparisonValue: string
  ): IGetRecordResponse<Data, Translations, Filters> | undefined {
    return this._appointments.find(
      (appointment) =>
        this._getComparisonValueFn(appointment) === comparisonValue
    );
  }

  removeMatch(
    appointment: IGetRecordResponse<Data, Translations, Filters>
  ): void {
    this._appointments = this._appointments.filter(
      (existingAppointment) =>
        existingAppointment.record.uid !== appointment.record.uid
    );
  }
}
