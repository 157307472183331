import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Storage } from '@angular/fire/storage';
import {
  OrganisationService,
  TypesenseSearchService,
} from '@principle-theorem/ng-principle-shared';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  IInfrastructureTestResult,
  TestInfrastructureServices,
} from './test-infrastructure-services';

@Component({
    selector: 'pr-infrastructure-tests',
    templateUrl: './infrastructure-tests.component.html',
    styleUrls: ['./infrastructure-tests.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InfrastructureTestsComponent {
  testingServices$ = new BehaviorSubject<boolean>(false);
  testServiceUpdates$ = new BehaviorSubject<IInfrastructureTestResult[]>([]);
  infrastructureTester: TestInfrastructureServices;

  constructor(
    private _organisationService: OrganisationService,
    private _storage: Storage,
    private _typesenseSearch: TypesenseSearchService
  ) {
    this.infrastructureTester = new TestInfrastructureServices(
      this._organisationService,
      this._storage,
      this._typesenseSearch
    );
    this.testServiceUpdates$.next(
      this.infrastructureTester.getInitialTestResults()
    );
  }

  async testServices(): Promise<void> {
    this.testingServices$.next(true);
    await this.infrastructureTester
      .runTests$()
      .pipe(tap((results) => this.testServiceUpdates$.next(results)))
      .toPromise();
    this.testingServices$.next(false);
  }
}
