import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IHistoryItem } from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { Transaction } from '@principle-theorem/principle-core';
import {
  IInvoice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentArchive,
  multiMap,
  sortTimestampAsc,
  toTimestamp,
  type ArchivedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { ReplaySubject, combineLatest, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

type AmendedTransaction = WithRef<ArchivedDocument<ITransaction>>;

@Component({
    selector: 'pr-transaction-amendment-history',
    templateUrl: './transaction-amendment-history.component.html',
    styleUrls: ['./transaction-amendment-history.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TransactionAmendmentHistoryComponent {
  trackByHistory = TrackByFunctions.label<IHistoryItem<AmendedTransaction>>();
  current$ = new ReplaySubject<WithRef<ITransaction>>(1);
  amendmentHistory$ = new ReplaySubject<AmendedTransaction[]>(1);
  historyItems$: Observable<IHistoryItem<WithRef<ITransaction>>[]>;
  invoice$: Observable<WithRef<IInvoice>>;

  @Input()
  set amendmentHistory(amendmentHistory: WithRef<AmendedTransaction>[]) {
    if (amendmentHistory) {
      this.amendmentHistory$.next(amendmentHistory);
    }
  }

  @Input()
  set current(current: WithRef<ITransaction>) {
    if (current) {
      this.current$.next(current);
    }
  }

  constructor() {
    const historyItems$ = this.amendmentHistory$.pipe(
      multiMap((transaction) => this._createTransactionHistoryItem(transaction))
    );
    const currentItem$ = this.current$.pipe(
      map((current) => ({
        label: 'Current',
        timestamp: toTimestamp(),
        value: current,
      }))
    );
    this.historyItems$ = combineLatest([historyItems$, currentItem$]).pipe(
      map(([historyItems, currentItem]) => [...historyItems, currentItem]),
      map((items) =>
        items
          .sort((a, b) => sortTimestampAsc(a.timestamp, b.timestamp))
          .reverse()
      )
    );
    this.invoice$ = this.current$.pipe(
      switchMap((current) => Transaction.getInvoice(current))
    );
  }

  private _createTransactionHistoryItem(
    transaction: AmendedTransaction
  ): IHistoryItem<AmendedTransaction> {
    return {
      label: DocumentArchive.getLabel(transaction),
      timestamp: transaction.archivedAt,
      value: transaction,
    };
  }
}
