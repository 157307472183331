import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AutomationConfigurationService,
  AutomationDialogService,
  type IAutomatedNotificationConfigurationEditRequest,
} from '@principle-theorem/ng-automations';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogInput,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  AutomationConfiguration,
  Brand,
  TemplateDefinition,
} from '@principle-theorem/principle-core';
import {
  type IAutomatedNotificationConfiguration,
  type IBrand,
  isAutomatedNotificationConfiguration,
  type ITreatmentConfiguration,
  type TemplateScope,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  getDocs,
  getParentDocRef,
  isSameRef,
  patchDoc,
  resolveDocRefs$,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-treatment-automated-notification-configuration',
  templateUrl:
    './treatment-automated-notification-configuration.component.html',
  styleUrls: [
    './treatment-automated-notification-configuration.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentAutomatedNotificationConfigurationComponent {
  templateScope$ = new ReplaySubject<TemplateScope>(1);
  resource$ = new ReplaySubject<WithRef<ITreatmentConfiguration>>(1);
  trackByNotification =
    TrackByFunctions.ref<WithRef<IAutomatedNotificationConfiguration>>();
  notifications$: Observable<WithRef<IAutomatedNotificationConfiguration>[]>;

  @Input()
  set templateScope(scope: TemplateScope) {
    if (scope) {
      this.templateScope$.next(scope);
    }
  }

  @Input()
  set resource(resource: WithRef<ITreatmentConfiguration>) {
    if (resource) {
      this.resource$.next(resource);
    }
  }

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _automationConfigService: AutomationConfigurationService,
    private _automationDialog: AutomationDialogService
  ) {
    this.notifications$ = this.resource$.pipe(
      switchMap((resource) =>
        resolveDocRefs$(resource.automatedNotificationRefs)
      ),
      map((configs): WithRef<IAutomatedNotificationConfiguration>[] =>
        configs.filter(
          (config): config is WithRef<IAutomatedNotificationConfiguration> =>
            isAutomatedNotificationConfiguration(config)
        )
      )
    );
  }

  async useExistingConfig(): Promise<void> {
    const resource = await snapshot(this.resource$);
    const brandRef = getParentDocRef<IBrand>(resource.ref);
    const configs = await getDocs(
      AutomationConfiguration.col({ ref: brandRef })
    );
    const notificationConfigs = configs
      .filter(
        (config): config is WithRef<IAutomatedNotificationConfiguration> =>
          isAutomatedNotificationConfiguration(config)
      )
      .filter(
        (config) =>
          !resource.automatedNotificationRefs.some((ref) =>
            isSameRef(ref, config.ref)
          )
      );

    const response = await this._automationDialog.selectConfiguration({
      notificationConfigs,
      taskConfigs: [],
    });

    if (!response || !response.notification) {
      return;
    }

    await AutomationConfiguration.addTreatmentRef(
      response.notification,
      resource.ref
    );

    await this._save([
      ...resource.automatedNotificationRefs,
      response.notification.ref,
    ]);
    this._snackBar.open('Notification configuration added');
  }

  async createAutomationConfig(): Promise<void> {
    const request: IAutomatedNotificationConfigurationEditRequest = {
      scope: await snapshot(this.templateScope$),
    };

    const response =
      await this._automationConfigService.openEditDialog(request);
    if (!response) {
      return;
    }

    const resource = await snapshot(this.resource$);
    const brandRef = getParentDocRef<IBrand>(resource.ref);

    const templateRef = await addDoc(
      Brand.templateCol({ ref: brandRef }),
      TemplateDefinition.init({
        ...response.template,
        ownerScope: brandRef,
      })
    );

    const automatedConfigurationRef = await addDoc(
      AutomationConfiguration.col<IAutomatedNotificationConfiguration>({
        ref: brandRef,
      }),
      {
        ...response.config,
        treatmentRefs: [resource.ref],
        templateRef,
      }
    );

    await patchDoc(templateRef, {
      automatedConfigurationRef,
    });

    await this._save([
      ...resource.automatedNotificationRefs,
      automatedConfigurationRef as DocumentReference<IAutomatedNotificationConfiguration>,
    ]);
    this._snackBar.open('Notification configuration created');
  }

  async edit(
    config: WithRef<IAutomatedNotificationConfiguration>
  ): Promise<void> {
    await this._automationConfigService.editNotification(
      config,
      await snapshot(this.templateScope$)
    );
  }

  async delete(
    notification: WithRef<IAutomatedNotificationConfiguration>
  ): Promise<void> {
    const data = confirmationDialogData({
      title: 'Remove Automated Notification',
      prompt: 'Are you sure you want to remove this automated notification?',
      submitLabel: 'Remove',
      submitColor: 'warn',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
    if (!confirmed) {
      return;
    }

    const resource = await snapshot(this.resource$);
    await AutomationConfiguration.removeTreatmentRef(
      notification,
      resource.ref
    );

    const automatedNotificationRefs = resource.automatedNotificationRefs.filter(
      (item) => !isSameRef(notification, item)
    );
    await this._save(automatedNotificationRefs);
    this._snackBar.open('Automated Notification Removed');
  }

  private async _save(
    automatedNotificationRefs: DocumentReference<IAutomatedNotificationConfiguration>[]
  ): Promise<void> {
    const resource = await snapshot(this.resource$);
    await patchDoc<ITreatmentConfiguration>(resource.ref, {
      automatedNotificationRefs,
    });
  }
}
