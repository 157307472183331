import { IServiceCode } from '@principle-theorem/principle-core/interfaces';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export class ServiceCodeSearch {
  results$: Observable<IServiceCode[]> = of([]);

  constructor(
    serviceCodes: Observable<IServiceCode[]>,
    searchInput: Observable<string>
  ) {
    this.results$ = combineLatest([serviceCodes, searchInput]).pipe(
      map((value: [IServiceCode[], string]) => this._filter(...value))
    );
  }

  private _filter(
    serviceCodes: IServiceCode[],
    search: string
  ): IServiceCode[] {
    if (!search) {
      return serviceCodes;
    }
    return serviceCodes.filter((serviceCode: IServiceCode) => {
      const match = `${serviceCode.code} ${serviceCode.title} ${serviceCode.description}`;
      return match.toLowerCase().includes(search.toLowerCase());
    });
  }
}
