import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MixedSchema } from '@principle-theorem/editor';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  MOCK_ALL_RESOLVER,
  type TemplateChangeData,
} from '@principle-theorem/ng-templating';
import {
  AutomatedNotification,
  AutomationConfiguration,
  TemplateDefinition,
  getScopedConditions,
} from '@principle-theorem/principle-core';
import {
  ConditionLogicConfigurationCollection,
  ConditionLogicId,
  IAutomationTiming,
  TemplateScope,
  TemplateType,
  type IAutomatedNotificationConfiguration,
  type IPractice,
  type ITemplateContext,
  type ITemplateDefinition,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  filterUndefined,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { Subject, from, of, type Observable } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { AutomatedNotificationFormHelpers } from '../notification-dialog/notification-dialog-form';
import {
  AUTOMATION_CONDITIONS_HELP_TEXT,
  AutomatedNotificationConfigurationForm,
  type AutomatedNotificationConfigurationFormData,
} from './automated-notification-configuration-form';

export interface IAutomatedNotificationConfigurationEditRequest {
  scope: TemplateScope;
  config?: WithRef<IAutomatedNotificationConfiguration>;
  practices?: INamedDocument<IPractice>[];
}

export interface IAutomatedNotificationConfigurationEditResponse {
  config: Omit<IAutomatedNotificationConfiguration, 'templateRef'>;
  template: TemplateChangeData;
}

@Component({
    selector: 'pr-automated-notification-configuration-edit',
    templateUrl: './automated-notification-configuration-edit.component.html',
    styleUrls: ['./automated-notification-configuration-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AutomatedNotificationConfigurationEditComponent
  implements OnDestroy
{
  private _onDestroy$ = new Subject<void>();
  form = new AutomatedNotificationConfigurationForm();
  formHelpers = AutomatedNotificationFormHelpers;
  isEdit = false;
  trackByTemplate = TrackByFunctions.ref<WithRef<ITemplateDefinition>>();
  template$: Observable<ITemplateDefinition>;
  templateExample$: Observable<MixedSchema | undefined>;
  templateType$: Observable<TemplateType>;
  mockContext$: Observable<ITemplateContext>;
  noScope = true;
  availableConditions: ConditionLogicId[];
  defaultCondition: ConditionLogicId.Always = ConditionLogicId.Always;
  automationConditionsHelp = AUTOMATION_CONDITIONS_HELP_TEXT;

  constructor(
    private _dialogRef: MatDialogRef<
      AutomatedNotificationConfigurationEditComponent,
      IAutomatedNotificationConfigurationEditResponse
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: IAutomatedNotificationConfigurationEditRequest,
    currentScope: CurrentScopeFacade
  ) {
    this._getTemplateDefaults$(data.config)
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((formDefaults) => {
        if (!formDefaults) {
          return;
        }
        this.form.patchValue(formDefaults);
        this.isEdit = true;
      });

    this.templateType$ = formControlChanges$(this.form.controls.type).pipe(
      distinctUntilChanged(),
      map((notificationType) =>
        AutomatedNotification.getTemplateType(notificationType)
      )
    );

    this.template$ = currentScope.currentBrand$.pipe(
      filterUndefined(),
      switchMap(async (brand) => {
        if (data.config) {
          const template = await Firestore.getDoc(data.config.templateRef);
          return { ...template, scope: data.scope };
        }
        return TemplateDefinition.init({
          ownerScope: brand.ref,
          scope: data.scope,
        });
      })
    );
    this.templateExample$ = this._getTemplateExample$();
    this.mockContext$ = this._getContext$(data.scope);

    if (data.practices) {
      this.form.controls.practiceRef.enable();
    }

    this.noScope = data.scope === TemplateScope.None;
    this.availableConditions = getScopedConditions(data.scope).filter(
      (condition) => condition !== ConditionLogicId.Never
    );

    this.form.isEmailNotification$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((isEmailNotification) =>
        this.form.enableSubjectControl(isEmailNotification)
      );

    this.form.controls.name.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((name) => this.form.controls.template.patchValue({ name }));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async submit(): Promise<void> {
    const formData = this.form.value;
    const templateType = AutomatedNotification.getTemplateType(formData.type);
    const config = {
      ...AutomationConfiguration.init(),
      ...AutomatedNotification.init(formData),
      ...this.data.config,
      ...formData,
      template: {
        ...formData.template,
        name: formData.name,
        type: templateType,
      },
    };

    const templateRef = this.data.config?.templateRef;
    const templateData = templateRef ? await Firestore.getDoc(templateRef) : {};
    const template: TemplateChangeData = {
      ...templateData,
      ...formData.template,
      name: config.name,
      type: templateType,
    };
    this._dialogRef.close({ config, template });
  }

  templateChange(template: TemplateChangeData): void {
    this.form.controls.template.setValue(template);
  }

  requiredConditionsChange(event: ConditionLogicConfigurationCollection): void {
    this.form.controls.requiredConditions.setValue(event);
  }

  timingChange(event: IAutomationTiming): void {
    this.form.controls.timing.patchValue(event, { emitEvent: false });
  }

  private _getTemplateExample$(): Observable<MixedSchema | undefined> {
    return this.form.controls.template.valueChanges.pipe(
      startWith(this.form.controls.template.value),
      map((template) => template?.content)
    );
  }

  private _getContext$(scope: TemplateScope): Observable<ITemplateContext> {
    return of(scope).pipe(
      switchMap(async () => MOCK_ALL_RESOLVER.resolve([scope]))
    );
  }

  private _getTemplateDefaults$(
    config?: WithRef<IAutomatedNotificationConfiguration>
  ): Observable<AutomatedNotificationConfigurationFormData | undefined> {
    if (!config) {
      return of(undefined);
    }
    return from(Firestore.getDoc(config.templateRef)).pipe(
      map((template) => ({ ...config, template }))
    );
  }
}
