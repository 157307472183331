import {
  IBrand,
  IMultiTreatmentConfiguration,
  ITreatmentConfiguration,
  ITreatmentTemplate,
} from '@principle-theorem/principle-core/interfaces';
import {
  deleteDoc,
  isSameRef,
  snapshot,
  WithRef,
} from '@principle-theorem/shared';
import { Brand } from '../../brand';

export class DeleteConfigurationManager {
  constructor(private _brand: WithRef<IBrand>) {}

  async delete(
    configuration: WithRef<
      IMultiTreatmentConfiguration | ITreatmentConfiguration
    >
  ): Promise<void> {
    const treatmentTemplates = await this.findMatchingTreatmentTemplates(
      configuration
    );
    if (treatmentTemplates.length) {
      throw new Error(
        'Configuration must be removed from treatment templates before deleting'
      );
    }
    return deleteDoc(configuration.ref);
  }

  async findMatchingTreatmentTemplates(
    configuration: WithRef<
      IMultiTreatmentConfiguration | ITreatmentConfiguration
    >
  ): Promise<WithRef<ITreatmentTemplate>[]> {
    const treatmentTemplates = await snapshot(
      Brand.treatmentTemplates$(this._brand)
    );

    return treatmentTemplates.filter((treatmentTemplate) =>
      treatmentTemplate.implementedBy.some((implementor) =>
        isSameRef(implementor.treatment, configuration)
      )
    );
  }
}
