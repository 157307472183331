import { rand, randBoolean, randFloat, randNumber } from '@ngneat/falso';
import {
  ADA_SERVICE_CODES,
  AppointmentAction,
} from '@principle-theorem/principle-core/interfaces';
import { IAppointmentEventFact } from '@principle-theorem/reporting/interfaces';
import {
  BaseMock,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
} from '@principle-theorem/shared';
import { BigQueryChartedTreatmentMock } from './common.mock';
import {
  getServiceCodeNumberString,
  MockTimeUntilAppointment,
} from '@principle-theorem/principle-core';
import { AppointmentDimensionMock } from '../dimensions/appointment-dimension.mock';
import { BrandDimensionMock } from '../dimensions/brand-dimension.mock';
import { SerialisedDocumentReferenceMock } from '../dimensions/common.mock';
import { PatientDimensionMock } from '../dimensions/patient-dimension.mock';
import { PracticeDimensionMock } from '../dimensions/practice-dimension.mock';
import { StafferDimensionMock } from '../dimensions/staffer-dimension.mock';
import { TreatmentPlanDimensionMock } from '../dimensions/treatment-plan-dimension.mock';

export class AppointmentEventFactMock
  extends BaseMock
  implements IAppointmentEventFact
{
  appointmentRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practitionerRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  treatmentStepRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  treatmentPlanRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  practiceRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  patientRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  brandRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  action = AppointmentAction.Unknown;
  treatments = [
    MockGenerator.generate(BigQueryChartedTreatmentMock),
    MockGenerator.generate(BigQueryChartedTreatmentMock),
  ];
  serviceCodes = rand(
    ADA_SERVICE_CODES.map((serviceCode) => {
      return {
        code: getServiceCodeNumberString(serviceCode.code),
        type: serviceCode.type,
      };
    }),
    { length: 3 }
  );
  dentalDraw = randFloat({
    min: 150,
    max: 2000,
  });
  duration = randNumber({
    min: 15,
    max: 180,
  });
  nextAppointmentBooked = randBoolean();
  isFirstAppointment = randBoolean();
  waitTime = randNumber({
    min: 0,
    max: 30,
  });
  treatmentCost = randFloat({
    min: 100,
    max: 4000,
  });
  appointment = MockGenerator.generate(AppointmentDimensionMock);
  practitioner = MockGenerator.generate(StafferDimensionMock);
  treatmentPlan = MockGenerator.generate(TreatmentPlanDimensionMock);
  practice = MockGenerator.generate(PracticeDimensionMock);
  patient = MockGenerator.generate(PatientDimensionMock);
  referrer = this.patient.referrer;
  brand = MockGenerator.generate(BrandDimensionMock);
  timestamp = toSerialisedTimestamp(MockTimestamp());
  timeUntilAppointment = MockTimeUntilAppointment();
}
