import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { roundTo2Decimals } from '@principle-theorem/accounting';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import { type ITransaction } from '@principle-theorem/principle-core/interfaces';
import {
  mergeDayAndTime,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { determineNewCreatedAtDate } from '../../transaction-helpers';
import { AmendTransactionDateStore } from './amend-transaction-date.store';

export interface ITransactionEditDialogResult
  extends Pick<ITransaction, 'amount' | 'description'> {
  dateReceived?: Moment;
}

export interface ITransactionEditDialogData
  extends Pick<ITransactionEditDialogResult, 'dateReceived'> {
  transaction: WithRef<ITransaction>;
  title: string;
  max?: number;
}

class TransactionEditDialogForm extends TypedFormGroup<ITransactionEditDialogResult> {
  constructor(data: ITransactionEditDialogData) {
    const initialAmountValue = data.transaction.amount ?? data.max;
    const initialAmount = initialAmountValue
      ? roundTo2Decimals(initialAmountValue)
      : undefined;
    const amountValidators = compact([
      Validators.required,
      data.max ? Validators.max(data.max) : undefined,
    ]);
    super({
      description: new TypedFormControl<string>(data.transaction.description),
      dateReceived: new TypedFormControl<Moment>(data.dateReceived),
      amount: new TypedFormControl<number>(initialAmount, amountValidators),
    });
  }
}

@Component({
  selector: 'pr-transaction-edit-dialog',
  templateUrl: './transaction-edit-dialog.component.html',
  styleUrls: ['./transaction-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS, AmendTransactionDateStore],
})
export class TransactionEditDialogComponent {
  form: TransactionEditDialogForm;
  accountAdminPermission = PatientPermissions.AccountInvoiceAdmin;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ITransactionEditDialogData,
    private _dialogRef: MatDialogRef<
      TransactionEditDialogComponent,
      ITransactionEditDialogResult
    >,
    public amendDateStore: AmendTransactionDateStore
  ) {
    this.form = new TransactionEditDialogForm(data);
    this.amendDateStore.loadAmendDates(data.transaction);
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    const data = this.form.value;
    const dateReceived = data.dateReceived
      ? await determineNewCreatedAtDate(
          this.data.transaction,
          mergeDayAndTime(data.dateReceived, moment()),
          await snapshot(this.amendDateStore.minDate$),
          await snapshot(this.amendDateStore.maxDate$)
        )
      : undefined;

    this._dialogRef.close({
      ...this.form.value,
      dateReceived,
    });
  }
}
