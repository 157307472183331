import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NgTemplatingModule } from '@principle-theorem/ng-templating';
import { AutomationConfigurationListComponent } from './automated-configuration-list/automation-configuration-list.component';
import { AutomatedFormIssueDialogComponent } from './automated-form-issue-dialog/automated-form-issue-dialog.component';
import { AutomatedNotificationConfigurationEditComponent } from './automated-notification-configuration-edit/automated-notification-configuration-edit.component';
import { AutomatedNotificationConfigurationListItemComponent } from './automated-notification-configuration-list-item/automated-notification-configuration-list-item.component';
import { AutomationLabelComponent } from './automation-label/automation-label.component';
import { AutomationListDisplayComponent } from './automation-list-display/automation-list-display.component';
import { AutomationFilterBadgeComponent } from './automation-list-filters/automations-type-filter-group/automation-filter-badge/automation-filter-badge.component';
import { AutomationsTypeFilterGroupComponent } from './automation-list-filters/automations-type-filter-group/automations-type-filter-group.component';
import { AutomationListItemMenuComponent } from './automation-list-item-menu/automation-list-item-menu.component';
import { AutomationListItemComponent } from './automation-list-item/automation-list-item.component';
import { AutomationListToolbarComponent } from './automation-list-toolbar/automation-list-toolbar.component';
import { AutomationRescheduleListComponent } from './automation-reschedule-list/automation-reschedule-list.component';
import { AutomationStatusComponent } from './automation-status/automation-status.component';
import { AutomationTimingFormComponent } from './automation-timing-form/automation-timing-form.component';
import { AutomationTreatmentsComponent } from './automation-treatments/automation-treatments.component';
import { AutomationsByConfigDialogComponent } from './automations-by-config-dialog/automations-by-config-dialog.component';
import { AutomationsListComponent } from './automations-list/automations-list.component';
import { GeneratedTaskConfigurationListItemComponent } from './generated-task-configuration-list-item/generated-task-configuration-list-item.component';
import { GeneratedTaskDialogComponent } from './generated-task-dialog/generated-task-dialog.component';
import { AutomatedNotificationConfigPreviewComponent } from './notification-dialog/automated-notification-config-preview/automated-notification-config-preview.component';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { SelectAutomationConfigurationDialogComponent } from './select-automation-configuration-dialog/select-automation-configuration-dialog.component';
import { AutomationIconComponent } from './automation-icon/automation-icon.component';
import { AutomationConfigurationListItemComponent } from './automation-configuration-list-item/automation-configuration-list-item.component';
import { AutomationRescheduleTimingComponent } from './automation-reschedule-list/automation-reschedule-timing/automation-reschedule-timing.component';

@NgModule({
  imports: [
    CommonModule,
    NgMaterialModule,
    NgSharedModule,
    NgInteractionsModule,
    NgPrincipleSharedModule,
    NgTemplatingModule,
    AutomationTimingFormComponent,
    AutomationIconComponent,
    AutomationLabelComponent,
    AutomationConfigurationListItemComponent,
    AutomationRescheduleTimingComponent,
  ],
  declarations: [
    AutomationsListComponent,
    NotificationDialogComponent,
    GeneratedTaskDialogComponent,
    AutomationListItemComponent,
    AutomationListItemMenuComponent,
    AutomationsTypeFilterGroupComponent,
    AutomationListToolbarComponent,
    AutomationListDisplayComponent,
    AutomationFilterBadgeComponent,
    AutomationStatusComponent,
    AutomationRescheduleListComponent,
    SelectAutomationConfigurationDialogComponent,
    AutomatedNotificationConfigurationListItemComponent,
    GeneratedTaskConfigurationListItemComponent,
    AutomatedNotificationConfigurationEditComponent,
    AutomatedNotificationConfigPreviewComponent,
    AutomationTreatmentsComponent,
    AutomationsByConfigDialogComponent,
    AutomatedFormIssueDialogComponent,
    AutomationConfigurationListComponent,
  ],
  exports: [
    AutomationsListComponent,
    NotificationDialogComponent,
    GeneratedTaskDialogComponent,
    AutomationListItemComponent,
    AutomationListItemMenuComponent,
    AutomationsTypeFilterGroupComponent,
    AutomationListDisplayComponent,
    AutomationStatusComponent,
    AutomationRescheduleListComponent,
    AutomationTimingFormComponent,
    AutomationConfigurationListComponent,
    AutomationIconComponent,
  ],
})
export class AutomationComponentsModule {}
