import {
  ApplicationRef,
  ComponentRef,
  ElementRef,
  Type,
  createComponent,
} from '@angular/core';
import { NodeViewProps } from '@tiptap/core';
import { get, set } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IEditorNodeComponent } from './components/editor-node.component';
import { canConfigureExtension } from './decorators/editor-node';

export class AngularRenderer<
  Component extends IEditorNodeComponent,
  Props extends NodeViewProps
> {
  private _onDestroy$ = new Subject<void>();
  componentRef: ComponentRef<Component>;

  constructor(
    ViewComponent: Type<Component>,
    private _applicationRef: ApplicationRef,
    event$: Observable<Event>,
    props: Partial<Props>,
    initialData?: object
  ) {
    this.componentRef = createComponent(ViewComponent, {
      environmentInjector: this._applicationRef.injector,
    });

    this.updateProps(props);

    if (canConfigureExtension(this.componentRef.instance) && initialData) {
      this.componentRef.instance.configure(initialData);
    }

    this._registerEventHandler(event$);

    this._applicationRef.attachView(this.componentRef.hostView);
  }

  get instance(): Component {
    return this.componentRef.instance;
  }

  get elementRef(): ElementRef<HTMLElement> {
    return this.componentRef.injector.get(ElementRef);
  }

  get dom(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  updateProps<T extends Props>(props: Partial<T>): void {
    Object.entries(props).map(([key, value]) => {
      set(this.componentRef.instance, key, value as unknown);

      if (key === 'node' && 'attrs' in value) {
        Object.entries(get(value, 'attrs') as object).map(
          ([attributeKey, attributeValue]) => {
            set(
              this.componentRef.instance,
              attributeKey,
              attributeValue as unknown
            );
          }
        );
        return;
      }
    });
  }

  detectChanges(): void {
    this.componentRef.changeDetectorRef.detectChanges();
  }

  destroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.componentRef.destroy();
    this._applicationRef.detachView(this.componentRef.hostView);
  }

  private _registerEventHandler(event$: Observable<Event>): void {
    event$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((event) => this.instance.addEvent(event));
  }
}
