import { type IReferralSourceConfiguration } from '@principle-theorem/principle-core/interfaces';
import { type IReffable, multiMap } from '@principle-theorem/shared';
import { sortBy, uniqBy } from 'lodash';
import { scan } from 'rxjs/operators';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import {
  ContactsSourceEntity,
  type IExactContact,
} from '../../source/entities/contacts';
import {
  CustomMappingType,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';

export const CONTACT_SOURCE_CUSTOM_RESOURCE_TYPE = 'contacts';

export const CONTACT_SOURCES_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Contacts Mapping',
    description: `This allows us to map Exact's contacts to patient referral source`,
    type: CONTACT_SOURCE_CUSTOM_RESOURCE_TYPE,
  },
  type: CustomMappingType.DocumentReference,
});

export class ExactContactMappingHandler extends BaseCustomMappingHandler<IReferralSourceConfiguration> {
  customMapping = CONTACT_SOURCES_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const entity = new ContactsSourceEntity();
    const records = await entity
      .getRecords$(migration, 1000)
      .pipe(
        multiMap((record) => record.data.data),
        scan(
          (sources, newSources) => [...sources, ...newSources],
          [] as IExactContact[]
        )
      )
      .toPromise();

    const options = uniqBy(
      records.map((source) => ({
        label: entity.getSourceLabel(source),
        value: source.id,
      })),
      (option) => option.value
    );

    return sortBy(options, 'label');
  }
}
