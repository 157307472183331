import {
  DocumentReference,
  isDocRef,
  isObject,
  TypeGuard,
  WithRef,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { IBrand, PatientOptionalField } from '../brand';
import { ICustomFormData } from '../custom-forms/custom-form-helpers';
import { IPatientFormSchema } from '../custom-forms/patient-form';
import { IPractice } from '../practice/practice';
import { PatientHealthCardType } from './healthfund-cards';
import { IPatient, PatientFormType } from './patient';
import { IReferralSourceConfiguration } from './referral-source';

export interface IPatientOnlyToken {
  patient: DocumentReference<IPatient>;
}

export const isPatientOnlyToken = TypeGuard.interface<IPatientOnlyToken>({
  patient: isDocRef,
});

export interface IPatientPortalToken {
  patient: DocumentReference<IPatient>;
  practice: DocumentReference<IPractice>;
}

export const isPatientPortalToken = TypeGuard.interface<IPatientPortalToken>({
  patient: isDocRef,
  practice: isDocRef,
});

export interface IPatientPortalForm {
  uid: string;
  name: string;
  description: string;
  type: PatientFormType;
  data?: ICustomFormData<object>;
}

export interface IPatientFormsData {
  referralSources: WithRef<IReferralSourceConfiguration>[];
  requiredFields: PatientOptionalField[];
  healthCardTypes: PatientHealthCardType[];
  patientForms: IPatientPortalForm[];
}

export interface IPatientFormsTokenData {
  brand: WithRef<IBrand>;
  practice: WithRef<IPractice>;
  patientName: string;
  formsData: IPatientFormsData;
}

export interface ISubmitPatientFormRequest {
  tokenUid: string;
  formUid: string;
  data: IPatientFormSchema<object>;
}

export const isSubmitPatientFormRequest =
  TypeGuard.interface<ISubmitPatientFormRequest>({
    tokenUid: isString,
    formUid: isString,
    data: isObject,
  });
