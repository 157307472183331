<div
  *ngIf="displayWarning$ | async"
  class="gap-warning layout-padding"
  fxLayout="row"
  fxLayoutAlign="start center"
>
  <mat-icon class="alert-icon">warning</mat-icon>
  <div fxFlex>
    Required appointment ({{ appointmentDuration$ | async }} mins) is greater
    than the Gap ({{ gapDuration$ | async }} mins).
  </div>
</div>
<mat-divider />
