import { NgModule } from '@angular/core';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { RomexisOpenPatientComponent } from './romexis-open-patient/romexis-open-patient.component';
import { RomexisFeatureService } from './romexis-feature.service';

@NgModule({
  declarations: [RomexisOpenPatientComponent],
  imports: [NgSharedModule],
  providers: [RomexisFeatureService],
})
export class RomexisModule {}
