import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { type IContact } from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  findProp,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';

@Component({
    selector: 'pr-sub-contact-list',
    templateUrl: './sub-contact-list.component.html',
    styleUrls: ['./sub-contact-list.component.sass'],
    standalone: false
})
export class SubContactListComponent {
  contact$: Observable<WithRef<IContact>>;

  constructor(private _route: ActivatedRoute) {
    if (!this._route.parent) {
      return;
    }

    this.contact$ = this._route.parent.data.pipe(
      findProp<WithRef<IContact>>('contact'),
      filterUndefined()
    );
  }
}
