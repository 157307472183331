<mat-toolbar color="accent">Change Treatment Base Price</mat-toolbar>

<mat-dialog-content>
  <form class="flex flex-col" (ngSubmit)="submit()">
    <mat-form-field class="flex-1">
      <mat-label>Treatment Base Price</mat-label>
      <span matTextPrefix>$&nbsp;</span>
      <input matInput type="number" min="0" [formControl]="basePriceControl" />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" (click)="submit()">Save</button>
  </pt-buttons-container>
</mat-dialog-actions>
