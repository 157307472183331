import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  computed,
  inject,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  ConfirmDialogComponent,
  DialogPresets,
  IConfirmationDialogInput,
  confirmationDialogData,
} from '@principle-theorem/ng-shared';
import {
  IPrescription,
  PrescriptionStatus,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';

@Component({
    selector: 'pr-prescription-actions',
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatMenuModule,
    ],
    templateUrl: './prescription-actions.component.html',
    styleUrl: './prescription-actions.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrescriptionActionsComponent {
  private _dialog = inject(MatDialog);
  prescriptionStatus = PrescriptionStatus;
  selected = signal<WithRef<IPrescription> | undefined>(undefined);
  status = computed(() => this.selected()?.status);
  hasBeenPrescribed = computed(() => !!this.selected()?.prescribedAt);
  isDraft = computed(() => this.status() === PrescriptionStatus.Draft);
  isIssued = computed(() => this.status() === PrescriptionStatus.Issued);
  isCancelled = computed(() => this.status() === PrescriptionStatus.Cancelled);

  @Input()
  set prescription(selected: WithRef<IPrescription>) {
    if (selected) {
      this.selected.set(selected);
    }
  }

  @Input({ transform: coerceBooleanProperty }) disabled = false;
  @Output() actionChange = new EventEmitter<PrescriptionStatus>();

  async delete(): Promise<void> {
    if (await this._confirmDelete()) {
      this.actionChange.emit(PrescriptionStatus.Delete);
    }
  }

  private async _confirmDelete(): Promise<boolean | undefined> {
    const data = confirmationDialogData({
      title: 'Delete Prescription',
      prompt: 'Are you sure you want to delete this prescription?',
      submitLabel: 'Delete',
      submitColor: 'warn',
    });
    return this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogInput, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();
  }
}
