export const ICLIENT_SIMULATOR_URL = 'iclientsimulator.test.tyro.com';
export const ICLIENT_TEST_SYSTEM_URL = 'iclient.test.tyro.com';
export const ICLIENT_LIVE_URL = 'iclient.tyro.com';

export enum TyroIClientUrl {
  Development = 'development',
  Simulator = 'simulator',
  Production = 'production',
}

export const TYRO_ICLIENT_URL_MAP: Record<TyroIClientUrl, string> = {
  [TyroIClientUrl.Development]: ICLIENT_TEST_SYSTEM_URL,
  [TyroIClientUrl.Simulator]: ICLIENT_SIMULATOR_URL,
  [TyroIClientUrl.Production]: ICLIENT_LIVE_URL,
};

export function getBaseUrl(
  tyroIClientUrl: TyroIClientUrl,
  path: string = ''
): string {
  const baseUrl = TYRO_ICLIENT_URL_MAP[tyroIClientUrl];
  if (!baseUrl) {
    throw new Error(`Failed to determine tyro url from ${tyroIClientUrl}`);
  }
  return `https://${baseUrl}${path}`;
}

export function getConfigUrl(baseUrl: TyroIClientUrl): string {
  return getBaseUrl(baseUrl, '/configuration.html');
}

export function getLogsUrl(baseUrl: TyroIClientUrl): string {
  return getBaseUrl(baseUrl, '/logs.html');
}

export function getIClientWithUIUrl(baseUrl: TyroIClientUrl): string {
  return getBaseUrl(baseUrl, '/iclient-with-ui-v1.js');
}
