import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_MEDICAL_CONDITION_RESOURCE_TYPE =
  'patientMedicalConditions';

export const PATIENT_MEDICAL_CONDITION_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Medical Condition List',
      description: '',
      idPrefix: PATIENT_MEDICAL_CONDITION_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ICorePracticePatientMedicalCondition {
  patientHistoryId: number;
  description: string; // "High / Low Blood Pressure"
}

export function isCorePracticePatientMedicalCondition(
  item: unknown
): item is ICorePracticePatientMedicalCondition {
  return TypeGuard.interface<ICorePracticePatientMedicalCondition>({
    patientHistoryId: isNumber,
    description: isString,
  })(item);
}

export interface ICorePracticePatientMedicalConditionTranslations {}

export interface ICorePracticePatientMedicalConditionFilters {
  patientHistoryId: number;
}

const PATIENT_MEDICAL_CONDITION_SOURCE_QUERY = `
SELECT
  PatientHistoryId AS patient_history_id,
  Description AS description
FROM tblPatientMedCond
WHERE PatientHistoryId IS NOT NULL
`;

export class PatientMedicalConditionSourceEntity extends BaseSourceEntity<
  ICorePracticePatientMedicalCondition,
  ICorePracticePatientMedicalConditionTranslations,
  ICorePracticePatientMedicalConditionFilters
> {
  sourceEntity = PATIENT_MEDICAL_CONDITION_SOURCE_ENTITY;
  entityResourceType = PATIENT_MEDICAL_CONDITION_RESOURCE_TYPE;
  sourceQuery = PATIENT_MEDICAL_CONDITION_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientMedicalCondition;

  translate(
    _data: ICorePracticePatientMedicalCondition,
    _timezone: Timezone
  ): ICorePracticePatientMedicalConditionTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientMedicalCondition): string {
    return `${data.patientHistoryId}-${data.description}`;
  }

  getSourceLabel(data: ICorePracticePatientMedicalCondition): string {
    return `${data.patientHistoryId} - ${data.description}`;
  }

  getFilterData(
    data: ICorePracticePatientMedicalCondition,
    _timezone: Timezone
  ): ICorePracticePatientMedicalConditionFilters {
    return {
      patientHistoryId: data.patientHistoryId,
    };
  }
}
