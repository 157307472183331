import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { type ITreatmentCategory } from '@principle-theorem/principle-core/interfaces';
import { TreatmentCategory } from '@principle-theorem/principle-core';
import {
  CHARTING_COLOURS,
  type IDisplayColour,
} from '@principle-theorem/shared';
import { sample } from 'lodash';

@Component({
    selector: 'pr-edit-treatment-category',
    templateUrl: './edit-treatment-category.component.html',
    styleUrls: ['./edit-treatment-category.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditTreatmentCategoryComponent {
  colourOptions = CHARTING_COLOURS;
  categoryForm = new TypedFormGroup<Omit<ITreatmentCategory, 'deleted'>>({
    name: new TypedFormControl<string>('', Validators.required),
    colour: new TypedFormControl<IDisplayColour>(sample(this.colourOptions), [
      Validators.required,
    ]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ITreatmentCategory | undefined,
    private _dialogRef: MatDialogRef<
      EditTreatmentCategoryComponent,
      ITreatmentCategory
    >
  ) {
    if (data) {
      this.categoryForm.patchValue(data);
    }
  }

  submit(): void {
    if (!this.categoryForm.valid) {
      return;
    }
    const category = TreatmentCategory.init(this.categoryForm.value);
    this._dialogRef.close(category);
  }
}
