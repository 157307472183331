import {
  BaseManagementJob,
  ManagementJobCollection,
  ManagementJobStatus,
  RootCollection,
  type IManagementJob,
} from '@principle-theorem/principle-core/interfaces';
import {
  CollectionReference,
  IAddLogRequest,
  IReffable,
  WithRef,
  all$,
  asColRef,
  subCollection,
  undeletedQuery,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';

export class ManagementJob {
  static init<T extends Omit<BaseManagementJob, 'status' | 'deleted'>>(
    data: Omit<T, 'status' | 'deleted'>
  ): BaseManagementJob {
    return {
      ...data,
      status: ManagementJobStatus.Pending,
      deleted: false,
    };
  }

  static col(): CollectionReference<IManagementJob> {
    return asColRef<IManagementJob>(RootCollection.ManagementJobs);
  }

  static all$(): Observable<WithRef<IManagementJob>[]> {
    return all$(undeletedQuery(ManagementJob.col()));
  }

  static logCol(
    job: IReffable<IManagementJob>
  ): CollectionReference<IAddLogRequest> {
    return subCollection<IAddLogRequest>(
      job.ref,
      ManagementJobCollection.ManagementJobLogs
    );
  }
}
