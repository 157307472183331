export const ROOT_OPTIONS: number[] = [1, 2, 3, 4];

export interface IToothSurfacesSVGMap {
  mesial: string;
  facial: string;
  distal: string;
  lingual: string;
  occlusal: string;
  roots: {
    [rootCount: string]: {
      inner: string[];
      outer: string[];
    };
  };
}

export const CHART_TOOTH_SURFACES_SVG_MAP: IToothSurfacesSVGMap = {
  mesial: `M61.4,92.9c0-9.5,3.4-18.1,9-24.9L37.9,35.5C24,50.6,15.4,70.8,15.4,92.9s8.5,42.3,22.4,57.4l32.6-32.6C64.8,111.1,61.4,102.4,61.4,92.9z`,
  facial: `M100.3,131.8c-9.5,0-18.1-3.4-24.9-9l-32.6,32.6c15.1,13.9,35.3,22.4,57.4,22.4s42.3-8.5,57.4-22.4l-32.6-32.6C118.4,128.4,109.8,131.8,100.3,131.8z`,
  distal: `M130.2,68.1c5.6,6.7,9,15.4,9,24.9s-3.4,18.1-9,24.9l32.6,32.6c13.9-15.1,22.4-35.3,22.4-57.4s-8.5-42.3-22.4-57.4L130.2,68.1z`,
  lingual: `M100.3,54c9.5,0,18.1,3.4,24.9,9l32.6-32.6c-15.1-13.9-35.3-22.4-57.4-22.4S58,16.6,42.9,30.5l32.6,32.6C82.2,57.4,90.9,54,100.3,54z`,
  occlusal: `M120.2,68.1c-5.4-4.4-12.3-7-19.8-7c-7.5,0-14.4,2.6-19.8,7c-1.8,1.5-3.5,3.2-5,5c-4.4,5.4-7,12.3-7,19.8c0,7.5,2.6,14.4,7,19.8c1.5,1.8,3.2,3.5,5,5c5.4,4.4,12.3,7,19.8,7c7.5,0,14.4-2.6,19.8-7c1.8-1.5,3.5-3.2,5-5c4.4-5.4,7-12.3,7-19.8c0-7.5-2.6-14.4-7-19.8C123.7,71.2,122,69.6,120.2,68.1z`,
  roots: {
    1: {
      outer: [
        `M185.6,147.6c-7.8,38.2-21.5,74.9-35.2,111.3c-6.9,18.4-13.5,36.9-20.7,55.1c-2.5,6.4-5.2,12.7-8.7,18.5c-3.5,5.7-7.7,10.8-12.2,15.7c-0.1,0.1-0.2,0.3-0.3,0.4c-4.9,6-14.6,7.2-20.9,2.8c-7.6-5.4-10.8-15.6-14.5-23.7c-16.5-37-31.8-74.6-43.5-113.5c-6-19.9-11.1-40.2-15.3-60.6c-4.1-20.4-6.9-41.1-5.8-62c0-0.3,0.1-0.6,0.2-0.9c0.4-4.6,1.1-9.2,3.7-13.1c2.7-4.1,6.9-9.4,11.8-10.6C24.7,67,25,67,25.2,67c0.6-0.2,1.4-0.1,2.1,0.2C39.9,72.5,53.5,76.3,67,78c12.7,1.5,25.8,1.5,38.2-2.3c12.2-3.8,23.6-9.9,36.1-12.9c11.6-2.8,23.7-2.6,34.3,3.2c5.7,3.1,14.4,9,15.8,16.1c0.3,0.4,0.4,1,0.5,1.6C192.3,105.2,189.9,126.6,185.6,147.6z`,
      ],
      inner: [
        `M185.2,83.4c-0.2-3.1-5.6-7-7.9-8.8c-4.9-3.6-10.6-6.2-16.7-7c-12.4-1.5-24.5,3-35.8,7.5c-11,4.4-21.9,8.9-33.9,9.8c-22.1,1.8-44.8-3.2-65.2-11.6c-3.1,1-6.4,5.5-8,8.1c-2,3.2-2.3,7-2.6,10.7c0,0.2-0.1,0.4-0.1,0.7c-1,21.5,2.2,42.9,6.6,63.9c7.8,37,18.8,73.3,32.3,108.6c6.8,17.9,14.3,35.5,22,53c3.4,7.7,6.3,16.4,11.1,23.4c2.2,3.2,5.1,6.1,9.2,6.1c2.4,0,4.7-0.8,6.6-2.3c5.5-5.9,10.7-12.1,14.6-19.2c4-7.3,6.8-15.2,9.7-23c13.5-36,27.7-71.9,39.4-108.5c11.3-35.7,19.6-72.6,19-110.2C185.3,84.2,185.2,83.8,185.2,83.4z`,
      ],
    },
    2: {
      outer: [
        `M109.5,248.5c14.9,38.2,13.9,60.6,15.9,86.2c0.7,8.6,1.2,17.1,1.9,25.7c0.3,3.9,0.4,7.8,1,11.6c0.4,2.9,1.6,5.1,4.5,6.1c1.6,0.6,2.8-0.2,3.4-1.3c0.1-0.1,0.1-0.2,0.2-0.3c21-34.4,29.6-74.5,33.1-114.2c1-11.5,1.4-23,3.6-34.4c1.9-10,5.2-19.7,9-29.1c7.6-19.5,15.8-38.4,18.3-59.4c1.3-10.9,1-21.9-0.8-32.7c-1.4-8.3-3.2-17.4-7.3-25.1c-2-10.2-17.9-7.7-25.3-6.2c-11.8,2.5-22.7,8.1-34.4,11.4c-13.2,3.7-27,2.5-40.5,1.5c-13.8-1.1-26.5-5.7-39.8-9.5c-7.5-2.1-15.2-3.8-22.8-5.4c-6.3-1.3-13.3-3-19,0.9c-0.3,0.2-0.6,0.4-0.8,0.7c-0.6,0.4-1.1,1-1.3,1.9c-5.1,21-8.8,43.2-6.8,64.9c2,21.8,12.4,41.1,17.6,62.2c9.9,40.2,7.8,82.8,22.6,121.9c7.1,18.8,17.9,36.4,33.9,48.9c2.5,2,5.8-0.4,5.6-2.7c0.1-0.5,0.1-1,0-1.5c-3.3-13-7.1-26-9.8-39.2c-2.9-14-3-27.8-1.9-42c0.1-0.8,0.1-1.6,0.2-2.4c0,0.1,0.1,0.2,0.1,0.3c0.9-10.6,3.1-21.1,5.5-31.4c1.5-6.7,2.9-13.4,4.3-20.1c1.1-5.6,2.5-11,4.1-16.5c0.9-3.1,1.6-3.8,1.6-3.8C92.7,220.8,102.8,231.9,109.5,248.5`,
      ],
      inner: [
        `M193,108c-1.6-8.2-3.5-17.4-8.1-24.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.3-0.1-0.7-0.3-1.1-0.3c-15,30.4-46.3,51.3-82.5,51.3C64,134,32,112,17.5,80.4c-1,0.2-2,0.5-2.9,1c-5.5,23.2-9.6,48.2-5,71.9c2,10.3,5.9,20,9.4,29.7c3.6,9.9,6.5,19.9,8.6,30.2c4.3,21.1,5.9,42.6,8.9,63.9c4.5,31.8,13.2,65.8,36.6,88.7c-2.9-10.9-5.8-21.8-8-32.8c-2.7-13.9-2.8-27.7-1.7-41.7c2.1-27.5,8-54.7,17.4-80.7c0.1-0.2,0.2-0.4,0.3-0.6c0.3-0.6,0.2-1.3-0.3-1.8c-0.4-0.4-0.7-0.7-1.1-1.1c-2.4-2.2-4.8-4.4-7.4-6.4c-1.2-1-2.5-1.9-3.7-2.7c-0.7-0.5-2.1-1.6-2.4-1.8c0.2,0.1-4.1-4.1-4.5-4.5c-5.2-4.7,12.4,2.4,16,5.2c5.6,4.5,10.8,9.7,15.4,15.3c16.6,20.1,26.4,45,31.7,70.4c3.2,15.3,5,30.8,6.3,46.3c0.7,7.6,1.2,15.3,1.8,23c0.3,4.3,0.6,8.6,1,12.8c0.1,0.6,0.2,3.2,0.3,5.5c9.7-17.2,16.5-35.9,21.1-55.1c4.7-19.6,7.2-39.6,8.7-59.6c0.8-10.3,1.6-20.7,3.9-30.8c2.2-9.9,5.8-19.4,9.5-28.7c7.5-18.8,15.3-37.5,17.1-57.9C195.4,127.9,194.9,117.8,193,108z`,
      ],
    },
    3: {
      outer: [
        `M199.6,106.7c-1.4-8.3-3.2-17.4-7.3-25.1c-2-10.2-17.9-7.7-25.3-6.2c-11.8,2.5-22.7,8.1-34.4,11.4c-13.2,3.7-27,2.5-40.5,1.5c-13.8-1.1-26.5-5.7-39.8-9.5c-7.5-2.1-15.2-3.8-22.8-5.4c-6.3-1.3-13.3-3-19,0.9c-0.3,0.2-0.6,0.4-0.8,0.7c-0.6,0.4-1.1,1-1.3,1.9c-5.1,21-8.8,43.2-6.8,64.9c2,21.8,12.4,41.1,17.6,62.2c9.9,40.2,7.8,82.8,22.6,121.9c7.1,18.8,17.9,36.4,33.9,48.9c2.5,2,5.8-0.4,5.6-2.7c0.1-0.5,0.1-1,0-1.5c-3.3-13-7.1-26-9.8-39.2c-2.9-14-3-27.8-1.9-42c0.1-0.8,0.1-1.6,0.2-2.4c5.2,13.8,7.8,28.3,10.9,42.7c1.6,7.7,3.4,15.4,5.8,22.9c1.8,5.4,4.3,11.2,10.3,12.7c0.7,0.2,1.3,0.1,1.9-0.1c0.5-0.1,1-0.4,1.5-0.8c6.2-6,7.1-15,7.7-23.2c0.8-9.7,0.5-19.4,0.2-29.1c-0.6-17.8-1.3-35.8,4.1-52.9c8.2,24.4,11,50.2,13,75.8c0.7,8.6,1.2,17.1,1.9,25.7c0.3,3.9,0.4,7.8,1,11.6c0.4,2.9,1.6,5.1,4.5,6.1c1.6,0.6,2.8-0.2,3.4-1.3c0.1-0.1,0.1-0.2,0.2-0.3c21-34.4,29.6-74.5,33.1-114.2c1-11.5,1.4-23,3.6-34.4c1.9-10,5.2-19.7,9-29.1c7.6-19.5,15.8-38.4,18.3-59.4C201.8,128.5,201.4,117.5,199.6,106.7z`,
      ],
      inner: [
        `M86.3,213.9c-7.3,20.4-12.1,41.5-14.9,63c1.3,2.3,2.9,6,3.3,6.8c5.5,13.2,8.3,27.2,11.2,41.1c1.5,7.1,3,14.2,4.9,21.1c1.1,4,2.3,11.8,6.3,14.3c4.9-6.2,4.7-16.2,5-23.6c0.4-9.3,0-18.6-0.3-27.9c-0.6-18.1-1.5-37,6-53.8c1-2.3,1-2.3,1-2.3c-4.5-11.5-9.7-22.2-17-32.1C90,218.3,88.2,216.1,86.3,213.9z`,
        `M193,108c-1.6-8.2-3.5-17.4-8.1-24.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.3-0.1-0.7-0.3-1.1-0.3c-15,30.4-46.3,51.3-82.5,51.3C64,134,32,112,17.5,80.4c-1,0.2-2,0.5-2.9,1c-5.5,23.2-9.6,48.2-5,71.9c2,10.3,5.9,20,9.4,29.7c3.6,9.9,6.5,19.9,8.6,30.2c4.3,21.1,5.9,42.6,8.9,63.9c4.5,31.8,13.2,65.8,36.6,88.7c-2.9-10.9-5.8-21.8-8-32.8c-2.7-13.9-2.8-27.7-1.7-41.7c2.1-27.5,8-54.7,17.4-80.7c0.2-0.6,0.6-1.1,1-1.4c-0.7-0.7-1.4-1.4-2.1-2.1c-2.4-2.2-4.8-4.4-7.4-6.4c-1.2-1-2.5-1.9-3.7-2.7c-0.7-0.5-2.1-1.6-2.4-1.8c0.2,0.1-4.1-4.1-4.5-4.5c-5.2-4.7,12.4,2.4,16,5.2c5.6,4.5,10.8,9.7,15.4,15.3c16.6,20.1,26.4,45,31.7,70.4c3.2,15.3,5,30.8,6.3,46.3c0.7,7.6,1.2,15.3,1.8,23c0.3,4.3,0.6,8.6,1,12.8c0.1,0.6,0.2,3.2,0.3,5.5c9.7-17.2,16.5-35.9,21.1-55.1c4.7-19.6,7.2-39.6,8.7-59.6c0.8-10.3,1.6-20.7,3.9-30.8c2.2-9.9,5.8-19.4,9.5-28.7c7.5-18.8,15.3-37.5,17.1-57.9C195.4,127.9,194.9,117.8,193,108z`,
      ],
    },
    // TODO: Add a proper fourth root svg path
    4: {
      outer: [
        `M199.6,106.7c-1.4-8.3-3.2-17.4-7.3-25.1c-2-10.2-17.9-7.7-25.3-6.2c-11.8,2.5-22.7,8.1-34.4,11.4c-13.2,3.7-27,2.5-40.5,1.5c-13.8-1.1-26.5-5.7-39.8-9.5c-7.5-2.1-15.2-3.8-22.8-5.4c-6.3-1.3-13.3-3-19,0.9c-0.3,0.2-0.6,0.4-0.8,0.7c-0.6,0.4-1.1,1-1.3,1.9c-5.1,21-8.8,43.2-6.8,64.9c2,21.8,12.4,41.1,17.6,62.2c9.9,40.2,7.8,82.8,22.6,121.9c7.1,18.8,17.9,36.4,33.9,48.9c2.5,2,5.8-0.4,5.6-2.7c0.1-0.5,0.1-1,0-1.5c-3.3-13-7.1-26-9.8-39.2c-2.9-14-3-27.8-1.9-42c0.1-0.8,0.1-1.6,0.2-2.4c5.2,13.8,7.8,28.3,10.9,42.7c1.6,7.7,3.4,15.4,5.8,22.9c1.8,5.4,4.3,11.2,10.3,12.7c0.7,0.2,1.3,0.1,1.9-0.1c0.5-0.1,1-0.4,1.5-0.8c6.2-6,7.1-15,7.7-23.2c0.8-9.7,0.5-19.4,0.2-29.1c-0.6-17.8-1.3-35.8,4.1-52.9c8.2,24.4,11,50.2,13,75.8c0.7,8.6,1.2,17.1,1.9,25.7c0.3,3.9,0.4,7.8,1,11.6c0.4,2.9,1.6,5.1,4.5,6.1c1.6,0.6,2.8-0.2,3.4-1.3c0.1-0.1,0.1-0.2,0.2-0.3c21-34.4,29.6-74.5,33.1-114.2c1-11.5,1.4-23,3.6-34.4c1.9-10,5.2-19.7,9-29.1c7.6-19.5,15.8-38.4,18.3-59.4C201.8,128.5,201.4,117.5,199.6,106.7z`,
      ],
      inner: [
        `M86.3,213.9c-7.3,20.4-12.1,41.5-14.9,63c1.3,2.3,2.9,6,3.3,6.8c5.5,13.2,8.3,27.2,11.2,41.1c1.5,7.1,3,14.2,4.9,21.1c1.1,4,2.3,11.8,6.3,14.3c4.9-6.2,4.7-16.2,5-23.6c0.4-9.3,0-18.6-0.3-27.9c-0.6-18.1-1.5-37,6-53.8c1-2.3,1-2.3,1-2.3c-4.5-11.5-9.7-22.2-17-32.1C90,218.3,88.2,216.1,86.3,213.9z`,
        `M193,108c-1.6-8.2-3.5-17.4-8.1-24.4c-0.1-0.2-0.2-0.4-0.3-0.6c-0.3-0.1-0.7-0.3-1.1-0.3c-15,30.4-46.3,51.3-82.5,51.3C64,134,32,112,17.5,80.4c-1,0.2-2,0.5-2.9,1c-5.5,23.2-9.6,48.2-5,71.9c2,10.3,5.9,20,9.4,29.7c3.6,9.9,6.5,19.9,8.6,30.2c4.3,21.1,5.9,42.6,8.9,63.9c4.5,31.8,13.2,65.8,36.6,88.7c-2.9-10.9-5.8-21.8-8-32.8c-2.7-13.9-2.8-27.7-1.7-41.7c2.1-27.5,8-54.7,17.4-80.7c0.2-0.6,0.6-1.1,1-1.4c-0.7-0.7-1.4-1.4-2.1-2.1c-2.4-2.2-4.8-4.4-7.4-6.4c-1.2-1-2.5-1.9-3.7-2.7c-0.7-0.5-2.1-1.6-2.4-1.8c0.2,0.1-4.1-4.1-4.5-4.5c-5.2-4.7,12.4,2.4,16,5.2c5.6,4.5,10.8,9.7,15.4,15.3c16.6,20.1,26.4,45,31.7,70.4c3.2,15.3,5,30.8,6.3,46.3c0.7,7.6,1.2,15.3,1.8,23c0.3,4.3,0.6,8.6,1,12.8c0.1,0.6,0.2,3.2,0.3,5.5c9.7-17.2,16.5-35.9,21.1-55.1c4.7-19.6,7.2-39.6,8.7-59.6c0.8-10.3,1.6-20.7,3.9-30.8c2.2-9.9,5.8-19.4,9.5-28.7c7.5-18.8,15.3-37.5,17.1-57.9C195.4,127.9,194.9,117.8,193,108z`,
      ],
    },
  },
};
