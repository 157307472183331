import {
  IInvoice,
  ITransaction,
  NON_REFUNDABLE_PROVIDERS,
} from '@principle-theorem/principle-core/interfaces';
import { TransactionOperators } from '../transaction/transaction-operators';
import { WithRef } from '@principle-theorem/shared';
import { Observable } from 'rxjs';
import { Invoice } from './invoice';
import { map } from 'rxjs/operators';

export class RefundHelpers {
  static getTotalPaid(transactions: ITransaction[]): number {
    const allCompleted = new TransactionOperators(transactions).completed();
    const allAmount =
      allCompleted.incoming().sum() -
      allCompleted.outgoing().sum() -
      allCompleted.claim().sum();
    return allAmount;
  }

  static getTotalRefundRemaining(transactions: ITransaction[]): number {
    const nonRefundableTransactions = new TransactionOperators(transactions)
      .byProvider(NON_REFUNDABLE_PROVIDERS)
      .result();

    const nonRefundable = this.getTotalPaid(nonRefundableTransactions);
    const total = this.getTotalPaid(transactions);
    return total - nonRefundable;
  }

  static isRefundable$(invoice: WithRef<IInvoice>): Observable<boolean> {
    return Invoice.transactions$(invoice).pipe(
      map((transactions) =>
        RefundHelpers.getTotalRefundRemaining(transactions)
      ),
      map((amount) => amount > 0)
    );
  }
}
