import {
  ITranslationMap,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  type AtLeast,
  isINamedDocument,
  isSameRef,
  type IXSLXExport,
  type WithRef,
  type XSLXCell,
} from '@principle-theorem/shared';
import type { Column } from 'exceljs';
import { sortBy } from 'lodash';
import { type ID4WAppointmentStatusType } from '../../source/entities/appointment-status-type';
import { AppointmentStatusMapType } from './appointment-statuses';

export interface IAppointmentStatusXSLX {
  abbreviation: string;
  status: string;
  mapTo: AppointmentStatusMapType | string;
}

export const APPOINTMENT_STATUS_HEADERS: AtLeast<Column, 'key' | 'header'>[] = [
  {
    key: 'abbreviation',
    header: 'Abbreviation',
  },
  {
    key: 'status',
    header: 'Status',
    width: 30,
  },
  {
    key: 'mapTo',
    header: 'Map To',
    width: 30,
  },
];

export class AppointmentStatusesToXSLX
  implements IXSLXExport<ID4WAppointmentStatusType, IAppointmentStatusXSLX>
{
  headers = APPOINTMENT_STATUS_HEADERS;

  constructor(
    private _appointmentTags: WithRef<ITag>[],
    private _existingMappings: ITranslationMap<
      object,
      AppointmentStatusMapType
    >[]
  ) {}

  translate(
    records: ID4WAppointmentStatusType[]
  ): Record<keyof IAppointmentStatusXSLX, XSLXCell>[] {
    const tagOptions = sortBy(this._appointmentTags, 'name').map(
      buildTagOption
    );

    const options = [
      AppointmentStatusMapType.CancelAppointment,
      AppointmentStatusMapType.ConfirmAppointment,
      AppointmentStatusMapType.Omit,
      ...tagOptions,
    ];
    return records.map((record) => {
      const existingRecord = this._existingMappings.find(
        (existingMapping) =>
          existingMapping.sourceIdentifier === record.abbreviation
      );

      const associatedValue = existingRecord?.associatedValue;
      const mappedTag =
        associatedValue && isINamedDocument(associatedValue)
          ? this._appointmentTags.find((tag) => isSameRef(tag, associatedValue))
          : undefined;

      const existingValue = mappedTag
        ? buildTagOption(mappedTag)
        : existingRecord?.destinationValue;

      return {
        abbreviation: {
          value: record.abbreviation,
        },
        status: {
          value: record.description,
        },
        mapTo: {
          value: existingValue,
          dataValidation: {
            type: 'list',
            formulae: [`"${options.join(',')}"`],
          },
        },
      };
    });
  }
}

function buildTagOption(tag: WithRef<ITag>): string {
  return `tag - ${tag.name}`;
}
