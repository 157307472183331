<ng-container *ngIf="days$ | async as days">
  <pr-week-header [days]="days" />

  <div class="week-view-body">
    <div class="week-view-scrollable">
      <div class="week-view-pane">
        <div class="week" fxLayout="row">
          <div class="hour-list">
            <div class="hour" *ngFor="let hour of hours; trackBy: trackByHour">
              <div class="hour-text">{{ hour }}</div>
            </div>
          </div>

          <div fxFlex>
            <div fxLayout="row">
              <pr-day-times
                *ngFor="let day of days; trackBy: trackByDay"
                [day]="day"
                fxFlex
               />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
