import {
  type IStaffer,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
  CustomMappingType,
} from '@principle-theorem/principle-core/interfaces';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import {
  INamedDocument,
  IReffable,
  WithRef,
  multiMap,
  multiSortBy$,
  nameSorter,
  query$,
  snapshot,
  undeletedQuery,
} from '@principle-theorem/shared';
import { AppointmentRoomSourceEntity } from '../../source/entities/appointment-room';
import { sortBy } from 'lodash';
import { Brand } from '@principle-theorem/principle-core';

export const APPOINTMENT_ROOM_TO_PRACTITIONER_CUSTOM_MAPPING_TYPE =
  'appointmentRoomToPractitioner';

export const APPOINTMENT_ROOM_TO_PRACTITIONER_MAPPING = CustomMapping.init({
  metadata: {
    label: 'Appointment Room to Practitioner',
    description: `
        Used to map appointments to a practitioner where the appointment has a room id of a different practitioner.
      `,
    type: APPOINTMENT_ROOM_TO_PRACTITIONER_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.DocumentReference,
});

export class AppointmentRoomToPractionerMappingHandler extends BaseCustomMappingHandler<IStaffer> {
  customMapping = APPOINTMENT_ROOM_TO_PRACTITIONER_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const appointmentRooms = new AppointmentRoomSourceEntity();
    const records = await appointmentRooms
      .getRecords$(migration, 1000)
      .toPromise();
    return sortBy(
      records
        .map((record) => record.data.data)
        .map((record) => ({
          label: record.name,
          value: record.id.toString(),
        })),
      'label'
    );
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument[]> {
    return snapshot(
      query$(
        undeletedQuery(Brand.stafferCol(migration.configuration.brand))
      ).pipe(
        multiMap((staffer) => ({
          name: `${staffer.user.name} - ${staffer.ref.id}`,
          ref: staffer.ref,
        })),
        multiSortBy$(nameSorter())
      )
    );
  }
}
