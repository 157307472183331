import { IRequestUploadTokenCommand } from './request-upload-token';

export enum BridgeToCloudCommand {
  Settings = 'settings',
  RequestUploadToken = 'requestUploadToken',
  SyncUploadedImage = 'syncUploadedImage',
  HicapsConnectResponse = 'hicapsConnectResponse',
  SendLogs = 'sendLogs',
  TwainDevices = 'twainDevices',
}

export enum CloudToBridgeCommand {
  GetLogs = 'getLogs',
  GetTwainDevices = 'getTwainDevices',
  UpdateSettings = 'updateSettings',
  TriggerImageUpload = 'triggerImageUpload',
}

export enum BridgeToCloudJobStatus {
  Pending = 'pending',
  Complete = 'complete',
  Failed = 'failed',
}

export interface IBridgeToCloudJob {
  status: BridgeToCloudJobStatus;
  type: BridgeToCloudCommand;
  data: IRequestUploadTokenCommand;
}
