import {
  type AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  type OnDestroy,
  type QueryList,
  ViewChildren,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  isDisabled$,
  TrackByFunctions,
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import { filterUndefined, type WithRef } from '@principle-theorem/shared';
import { compact, isEqual, isString, last } from 'lodash';
import { type Observable, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  pairwise,
  startWith,
  takeUntil,
} from 'rxjs/operators';

export interface ISterilisationDialogData {
  patient?: WithRef<IPatient>;
}

export interface ISterilisationRecordFormData {
  data: string[];
  patient: WithRef<IPatient>;
}

@Component({
  selector: 'pr-sterilisation-record-dialog',
  templateUrl: './sterilisation-record-dialog.component.html',
  styleUrls: ['./sterilisation-record-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SterilisationRecordDialogComponent
  implements OnDestroy, AfterViewInit
{
  private _onDestroy$ = new Subject<void>();
  searchCtrl: TypedFormControl<string | IPatient> = new TypedFormControl();
  trackByIndex = TrackByFunctions.index<TypedFormControl<string>>();
  form = new TypedFormGroup<ISterilisationRecordFormData>({
    data: new TypedFormArray([new TypedFormControl('', Validators.required)]),
    patient: new TypedFormControl<WithRef<IPatient>>(
      undefined,
      Validators.required
    ),
  });
  disabled$: Observable<boolean>;
  @ViewChildren('dataInput') dataInputs: QueryList<
    ElementRef<HTMLInputElement>
  >;

  constructor(
    private _dialogRef: MatDialogRef<
      SterilisationRecordDialogComponent,
      ISterilisationRecordFormData
    >,
    @Inject(MAT_DIALOG_DATA) data?: ISterilisationDialogData
  ) {
    if (data?.patient) {
      this.searchCtrl.setValue(data.patient);
      this.searchCtrl.disable();
      this.form.patchValue(data);
      this.form.controls.patient.disable();
    }
    this.disabled$ = isDisabled$(this.form);

    this.searchCtrl.valueChanges
      .pipe(
        filter((value): value is WithRef<IPatient> => !isString(value)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((patient) => this.form.controls.patient.setValue(patient));

    this.form.controls.data.valueChanges
      .pipe(
        debounceTime(500),
        filterUndefined(),
        startWith([]),
        pairwise(),
        filter(([previous, current]) => {
          const decreaseInArraySize = previous.length > current.length;
          const noChangeToPrevious =
            previous.length === current.length &&
            isEqual(last(previous), last(current));
          const emptyLastIndex = last(current)?.trim() === '';
          if (decreaseInArraySize || noChangeToPrevious || emptyLastIndex) {
            return false;
          }
          return true;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.addRecord());
  }

  get records(): TypedFormArray<string> {
    return this.form.controls.data as TypedFormArray<string>;
  }

  ngAfterViewInit(): void {
    this.dataInputs.changes
      .pipe(
        map((inputs: QueryList<ElementRef<HTMLInputElement>>) =>
          inputs.toArray()
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((records) =>
        setTimeout(() => last(records)?.nativeElement.focus())
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }
    const formData = this.form.getRawValue();
    const data = compact(formData.data.map((record) => record.trim()));
    this._dialogRef.close({
      data,
      patient: formData.patient,
    });
  }

  addRecord(): void {
    this.records.push(new TypedFormControl(''));
  }
}
