import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPresets, TrackByFunctions } from '@principle-theorem/ng-shared';
import { type ITyroTerminal } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import {
  type ISelectedTerminal,
  SelectTyroTerminalComponent,
} from './select-tyro-terminal.component';

@Injectable()
export class SelectTyroTerminalService {
  trackByTerminal = TrackByFunctions.ref<WithRef<ITyroTerminal>>();

  constructor(private _dialog: MatDialog) {}

  async getTerminalData(): Promise<ISelectedTerminal | undefined> {
    return this._dialog
      .open<SelectTyroTerminalComponent, undefined, ISelectedTerminal>(
        SelectTyroTerminalComponent,
        DialogPresets.small()
      )
      .afterClosed()
      .toPromise();
  }
}
