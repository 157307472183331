import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IntercomService } from '@principle-theorem/ng-intercom';
import { type IConfirmAppointmentInfo } from '@principle-theorem/principle-core/interfaces';
import { isNull } from 'lodash';
import { combineLatest, type Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PatientConfirmAppointmentService } from './patient-confirm-appointment.service';
import {
  PatientConfirmState,
  PatientConfirmStateBloc,
} from './patient-confirm-state';
import { sanitiseCSRF } from '@principle-theorem/temporary-tokens';

@Component({
  selector: 'pr-patient-confirm-appointment',
  templateUrl: './patient-confirm-appointment.component.html',
  styleUrls: ['./patient-confirm-appointment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientConfirmAppointmentComponent implements OnInit, OnDestroy {
  tokenUid$: Observable<string | undefined>;
  info$: Observable<IConfirmAppointmentInfo | undefined>;
  state = new PatientConfirmStateBloc();

  constructor(
    private _route: ActivatedRoute,
    private _patientConfirmService: PatientConfirmAppointmentService,
    private _intercom: IntercomService
  ) {
    this.tokenUid$ = combineLatest([
      this._route.queryParamMap.pipe(
        map((queryParams) => queryParams.get('token')),
        map((token) => (!isNull(token) ? token : undefined))
      ),
      this._route.paramMap.pipe(
        map((params) => params.get('token')),
        map((token) => (!isNull(token) ? token : undefined))
      ),
    ]).pipe(
      map(([queryToken, routeToken]) => queryToken ?? routeToken),
      map((token) => token && sanitiseCSRF(token))
    );

    this.info$ = this.tokenUid$.pipe(
      switchMap((tokenUid) =>
        tokenUid
          ? this._patientConfirmService.resolveAppointmentInfo$(tokenUid)
          : of(undefined)
      ),
      tap((info) => this._setStateForInfo(info)),
      catchError((error) => {
        this.state.state$.next(PatientConfirmState.NotAvailable);
        // eslint-disable-next-line no-console
        console.error(error);
        return of(undefined);
      })
    );
  }

  ngOnInit(): void {
    this._intercom.hideIcon();
  }

  ngOnDestroy(): void {
    this._intercom.showIcon();
  }

  private _setStateForInfo(info?: IConfirmAppointmentInfo): void {
    if (!info) {
      this.state.state$.next(PatientConfirmState.NotAvailable);
      return;
    }
    if (info.alreadyConfirmed) {
      this.state.state$.next(PatientConfirmState.AlreadySubmitted);
      return;
    }
    this.state.state$.next(PatientConfirmState.Confirmed);
  }
}
