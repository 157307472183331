<mat-drawer-container class="h-full w-full">
  <mat-drawer mode="side" position="start" opened disableClose>
    <pr-chat-list (chatSelected)="openChat($event)" />
  </mat-drawer>
  <mat-drawer-content>
    <router-outlet #router="outlet" />

    <div
      class="flex h-full w-full items-center justify-center"
      *ngIf="!router.isActivated"
    >
      <pr-empty-state
        image="conversation"
        title="chat selected"
        [descriptionEnabled]="false"
      />
    </div>
  </mat-drawer-content>
</mat-drawer-container>
