import {
  IExpectedSourceRecordSize,
  SourceEntityMigrationType,
  type IPracticeMigration,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  TypeGuard,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import {
  compact,
  flow,
  groupBy,
  isBoolean,
  isNull,
  isNumber,
  isString,
  last,
  sortBy,
} from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { runQuery } from '../../../source/connection';
import { SourceEntity } from '../../../source/source-entity';

export const FEE_SCHEDULE_RESOURCE_TYPE = 'feeSchedule';

export const FEE_SCHEDULE_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Fee Schedule List',
    description: '',
    idPrefix: FEE_SCHEDULE_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

interface ID4WFeeScheduleResult {
  id: number;
  description: string;
  is_default: boolean;
  practice_scoped_id: number | null;
  provider_based: boolean | null;
  is_active: boolean;
  price: string | null;
  item_id: number;
  practice_id: number;
  period_id: number;
  start_date: string;
  end_date: string;
}

export interface ID4WFeeSchedule {
  id: number;
  description: string;
  is_default: boolean;
  practice_id: number;
  provider_based: boolean | null;
  is_active: boolean;
  items: ID4WFeeScheduleItem[];
}

export function isD4WFeeSchedule(item: unknown): item is ID4WFeeSchedule {
  return TypeGuard.interface<ID4WFeeSchedule>({
    id: isNumber,
    description: isString,
    practice_id: isNumber,
    is_default: isBoolean,
    provider_based: [isBoolean, isNull],
    is_active: isBoolean,
    items: TypeGuard.arrayOf(isD4WFeeScheduleItem),
  })(item);
}

export interface ID4WFeeScheduleItem {
  item_id: number;
  price: number;
  period_id: number;
  start_date: string;
  end_date: string;
}

export function isD4WFeeScheduleItem(
  item: unknown
): item is ID4WFeeScheduleItem {
  return TypeGuard.interface<ID4WFeeScheduleItem>({
    price: isNumber,
    item_id: isNumber,
    period_id: isNumber,
    start_date: isString,
    end_date: isString,
  })(item);
}

const D4W_FEE_SCHEDULE_SOURCE_QUERY = `SELECT
    fee_schedule.id,
    fee_schedule.description,
    fee_schedule.is_default,
    fee_schedule.practice_scoped_id,
    fee_schedule.provider_based,
    fee_schedule.is_active,
    fee_schedule_item.price,
    fee_schedule_item.item_id,
    fee_schedule_item.practice_id,
    fee_schedule_item.period_id,
    period.start_date,
    period.end_date
  FROM (
    SELECT
      id,
      description,
      convert_to_boolean(is_default) AS is_default,
      practice_id AS practice_scoped_id,
      convert_to_boolean(provider_based) AS provider_based,
      convert_to_boolean(is_active) AS is_active
    FROM pract_fee_levels
    ) AS fee_schedule
  INNER JOIN (
    SELECT
      pfl_id AS fee_schedule_id,
      price,
      item_id,
      practice_id,
      period_id
    FROM pract_fee
  ) AS fee_schedule_item
  ON fee_schedule.id = fee_schedule_item.fee_schedule_id
  INNER JOIN (
    SELECT
      period_id AS id,
      pfl_id AS fee_schedule_id,
      convert_empty_string_to_null(dt_start) AS start_date,
      convert_empty_string_to_null(dt_end) AS end_date
    FROM pract_fee_periods
  ) as period
  ON period.id = fee_schedule_item.period_id AND fee_schedule.id = period.fee_schedule_id
  ORDER BY fee_schedule.id ASC`;

export class FeeScheduleSourceEntity extends BaseSourceEntity<ID4WFeeSchedule> {
  sourceEntity = FEE_SCHEDULE_SOURCE_ENTITY;
  entityResourceType = FEE_SCHEDULE_RESOURCE_TYPE;
  sourceQuery = D4W_FEE_SCHEDULE_SOURCE_QUERY;
  verifySourceFn = isD4WFeeSchedule;
  override defaultOffsetSize = 0;

  override transformDataFn = flow([transformFeeScheduleResults]);

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const response = await runQuery<ID4WFeeScheduleResult>(
      migration,
      this.sourceQuery
    );

    const expectedSize = transformFeeScheduleResults(response.rows).length;

    return {
      expectedSize,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }

  translate(_staffer: ID4WFeeSchedule): object {
    return {};
  }

  getSourceRecordId(data: Pick<ID4WFeeSchedule, 'id'>): string {
    return `${data.id}`;
  }

  getSourceLabel(data: ID4WFeeSchedule): string {
    return data.description;
  }
}

function transformFeeScheduleResults(
  feeScheduleResults: ID4WFeeScheduleResult[]
): ID4WFeeSchedule[] {
  const feeScheduleGroups = groupBy(
    feeScheduleResults,
    (result) => `${result.id}-${result.practice_id}`
  );
  return Object.values(feeScheduleGroups).map((items) => {
    const groupedItems = groupBy(items, (item) => `${item.item_id}`);
    const filteredItems = compact(
      Object.values(groupedItems).map((groupItems) =>
        last(sortBy(groupItems, (item) => item.end_date))
      )
    );
    return {
      id: items[0].id,
      description: items[0].description,
      practice_id: items[0].practice_id,
      is_default: items[0].is_default,
      practice_scoped_id: items[0].practice_scoped_id,
      provider_based: items[0].provider_based,
      is_active: items[0].is_active,
      items: filteredItems.map((item) => ({
        item_id: item.item_id,
        price: parseFloat(item.price ?? '0'),
        period_id: item.period_id,
        start_date: item.start_date,
        end_date: item.end_date,
      })),
    };
  });
}
