<div class="breadcrumbs-wrapper flex items-center gap-2">
  @for (
    breadcrumb of breadcrumbsValue();
    track breadcrumb.label;
    let isLast = $last
  ) {
    @if (breadcrumb.path) {
      <a [routerLink]="breadcrumb.path" class="mat-headline-6">
        {{ breadcrumb.label }}
      </a>
    } @else {
      <span class="mat-headline-6">{{ breadcrumb.label }}</span>
    }

    @if (!isLast) {
      <mat-icon>chevron_right</mat-icon>
    }
  }

  <span class="flex-1"></span>
  <ng-content />
</div>
