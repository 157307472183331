import {
  AccountCredit,
  Brand,
  Invoice,
  Patient,
  hasMergeConflicts,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  IDestinationEntityJobRunOptions,
  IMigratedDataSummary,
  IPractice,
  ITranslationMap,
  MergeConflictDestinationEntityRecord,
  type IAccountCredit,
  type IBasePatient,
  type IBrand,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IInvoice,
  type IPatient,
  type IPatientContactDetails,
  type IPracticeMigration,
  type ISourceEntityRecord,
  type IStaffer,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  Timestamp,
  asyncForEach,
  doc,
  getError,
  omitByKeys,
  runTransaction,
  safeCombineLatest,
  snapshotCombineLatest,
  toTimestamp,
  type DocumentReference,
  type IIdentifiable,
  type WithRef,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { compact, sumBy } from 'lodash';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  PATIENT_INVOICE_RESOURCE_TYPE,
  PATIENT_TRANSACTION_RESOURCE_TYPE,
} from '../../../destination/entities/patient-invoices';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { ItemCodeResourceMapType } from '../../../mappings/item-codes-to-xlsx';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import {
  PatientSourceEntity,
  type IExactPatient,
  type IExactPatientTranslations,
} from '../../source/entities/patient';
import { PatientBalanceSourceEntity } from '../../source/entities/patient-balance';
import { PatientTransactionsSourceEntity } from '../../source/entities/patient-transactions';
import { PatientTreatmentSourceEntity } from '../../source/entities/patient-treatments';
import { ExactItemCodeMappingHandler } from '../mappings/item-codes';
import { StaffToPracticeMapping } from '../mappings/practitioner-to-practice-mapping';
import { ExactStafferMappingHandler } from '../mappings/staff';
import { ExactInvoiceBuilder } from './lib/exact-invoice-builder';
import { PatientDestinationEntity } from './patient';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';

interface IInvoiceUpdateData {
  invoiceRef: DocumentReference<IInvoice>;
  transactionRefs: DocumentReference<ITransaction>[];
  accountCreditRefs: DocumentReference<IAccountCredit>[];
}

export const PATIENT_INVOICE_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_INVOICE_RESOURCE_TYPE,
    label: 'Patient Invoices',
    description: `
      Exact has a flat table of transactions (incoming and outgoing) and does not track any payments back to any charges. To deal with this, any charges made on the same day are
      grouped into an invoice and their line items will be built by any found treatments (or the description as it exists in Exact if nothing is linked).

      Afterwards, all payments are ordered by date and we apply payments to the oldest invoices first. Any invoices that do not balance will all be the most recent ones. Any overpayments found
      at the end of this processing will be added to the last invoice in the order and will be added as deposit line items, the result will be patients that show credits in Principle.
    `,
  },
});

interface IInvoiceBuildData {
  invoice: IIdentifiable & IInvoice;
  transactions: (IIdentifiable & ITransaction)[];
}

interface IPatientInvoiceMigrationData {
  patientRef: DocumentReference<IPatient>;
  data: IInvoiceBuildData[];
  createdAt: Timestamp;
}

export interface IPatientInvoiceDestinationRecord {
  sourceRef: DocumentReference<ISourceEntityRecord<IExactPatient>>;
  invoiceData: {
    invoiceRef: DocumentReference<IInvoice>;
    transactionRefs: DocumentReference<ITransaction>[];
  }[];
}

export interface IPatientInvoiceJobData {
  sourcePatient: IGetRecordResponse<IExactPatient, IExactPatientTranslations>;
  brand: WithRef<IBrand>;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  practitioners: WithRef<IStaffer>[];
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[];
  staffToPractice: WithRef<ITranslationMap<IPractice>>[];
}

export class PatientInvoicesDestinationEntity extends BaseDestinationEntity<
  IPatientInvoiceDestinationRecord,
  IPatientInvoiceJobData,
  IPatientInvoiceMigrationData
> {
  destinationEntity = PATIENT_INVOICE_DESTINATION_ENTITY;

  sourceCountComparison = new PatientSourceEntity();

  override canMigrateByIdRange = true;

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    transactions: new PatientTransactionsSourceEntity(),
    treatments: new PatientTreatmentSourceEntity(),
    balances: new PatientBalanceSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  override filters = [
    new PatientIdFilter<IPatientInvoiceJobData>(
      (data) => data.sourcePatient.data.data.patient_id
    ),
  ];

  customMappings = {
    staff: new ExactStafferMappingHandler(),
    itemCodes: new ExactItemCodeMappingHandler(),
    staffToPractice: new StaffToPracticeMapping(),
  };

  sourceCountDataAccessor(
    data: IPatientInvoiceJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IPatientInvoiceDestinationRecord>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest(
      record.data.invoiceData.map((data) =>
        combineLatest([
          Firestore.getDoc(data.invoiceRef),
          safeCombineLatest(
            data.transactionRefs.map((transactionRef) =>
              Firestore.getDoc(transactionRef)
            )
          ),
        ])
      )
    ).pipe(
      map(([invoiceRef, transactions]) => {
        const data: IMigratedDataSummary[] = [
          {
            label: 'Invoice',
            data: invoiceRef,
          },
        ];

        data.push(
          ...transactions.map((transaction) => ({
            label: 'Transactions',
            data: transaction,
          }))
        );

        return data;
      })
    );
  }

  getDestinationEntityRecordUid(data: IPatientInvoiceJobData): string {
    return data.sourcePatient.record.uid;
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientInvoiceJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const brand$ = PracticeMigration.brand$(migration);
    const practitioners$ = brand$.pipe(
      switchMap((brand) => Firestore.getDocs(Brand.stafferCol(brand)))
    );

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([
        staff$,
        brand$,
        practitioners$,
        this.customMappings.itemCodes.getRecords$(translationMap),
        this.customMappings.staffToPractice.getRecords$(translationMap),
      ]),
    ]).pipe(
      map(
        ([
          patients,
          [staff, brand, practitioners, sourceItemCodes, staffToPractice],
        ]) =>
          patients.map((sourcePatient) => ({
            sourcePatient,
            staff,
            brand,
            practitioners,
            sourceItemCodes,
            staffToPractice,
          }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientInvoiceJobData
  ): Promise<
    | IPatientInvoiceMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const practice = await Firestore.getDoc(
      migration.configuration.practices[0].ref
    );
    const patientId = data.sourcePatient.data.data.patient_id;
    const patientRef = await translationMap.getDestination<IPatient>(
      patientId,
      PATIENT_RESOURCE_TYPE
    );
    if (!patientRef) {
      return this.buildErrorResponse(
        data.sourcePatient.record,
        `Couldn't resolve patient`
      );
    }

    const patient = await Firestore.getDoc(patientRef);
    const patientTransactions =
      await this.sourceEntities.transactions.filterRecords(
        migration,
        'patientId',
        patientId
      );

    if (!patientTransactions.length) {
      // eslint-disable-next-line no-console
      console.error(`No transactions found for patient ${patientId}`);
      return {
        patientRef: patient.ref,
        data: [],
        createdAt: Timestamp.now(),
      };
    }

    const patientBalances = await this.sourceEntities.balances.filterRecords(
      migration,
      'patientId',
      patientId
    );

    const balance = patientBalances.length
      ? sumBy(
          patientBalances,
          (patientBalance) => patientBalance.data.data.balance
        )
      : sumBy(
          patientTransactions,
          (transaction) => transaction.data.data.amount
        );

    const patientTreatments =
      await this.sourceEntities.treatments.filterRecords(
        migration,
        'patientId',
        patientId
      );

    const invoiceBuilder = new ExactInvoiceBuilder(
      patientTransactions,
      patientTreatments,
      balance,
      data.staff,
      data.sourceItemCodes,
      data.staffToPractice
    );

    try {
      invoiceBuilder.buildAccountSummary(practice.ref);
      invoiceBuilder.validateAccountSummary();

      const invoiceData = await invoiceBuilder.buildInvoices(
        patient as IBasePatient & IPatientContactDetails,
        translationMap,
        migration.configuration.practices
      );

      return {
        patientRef: patient.ref,
        data: invoiceData,
        createdAt: Timestamp.now(),
      };
    } catch (error) {
      return this.buildErrorResponse(
        data.sourcePatient.record,
        getError(error)
      );
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientInvoiceJobData,
    _migrationData: IPatientInvoiceMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  buildErrorResponse(
    patient: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: patient.uid,
      label: patient.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: patient.ref,
      errorMessage: errorMessage ?? 'Missing required properties for patient',
      failData: {
        patientRef: patient.ref,
      },
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: IPatientInvoiceJobData,
    migrationData: IPatientInvoiceMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const invoiceRefs = await this._upsertInvoices(
        migrationData,
        translationMap,
        migrationData.patientRef
      );

      return this._buildSuccessResponse(jobData.sourcePatient, invoiceRefs);
    } catch (error) {
      return this.buildErrorResponse(
        {
          label: jobData.sourcePatient.record.label,
          uid: jobData.sourcePatient.record.uid,
          ref: jobData.sourcePatient.record.ref,
        },
        getError(error)
      );
    }
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    migrationData: IPatientInvoiceMigrationData
  ): Promise<IPatientInvoiceMigrationData | undefined> {
    const invoiceMergeConflicts: (IInvoiceBuildData | undefined)[] =
      await asyncForEach(migrationData.data, async (invoiceBuildData) => {
        try {
          const invoiceRef = await translationMap.getDestination<IInvoice>(
            invoiceBuildData.invoice.uid,
            PATIENT_TRANSACTION_RESOURCE_TYPE
          );

          if (!invoiceRef) {
            return;
          }

          const existingInvoice = await Firestore.getDoc(invoiceRef);
          const invoiceMergeConflict = hasMergeConflicts(
            omitByKeys(invoiceBuildData.invoice, ['from', 'to']),
            omitByKeys(existingInvoice, ['from', 'to']),
            [],
            [
              {
                key: 'transactions',
                sortByPath: 'reference',
              },
            ]
          );

          const existingTransactions: (IIdentifiable & ITransaction)[] = [];

          const transactionMergeConflicts = await asyncForEach(
            invoiceBuildData.transactions,
            async (transaction) => {
              try {
                const existingTransaction = await Firestore.getDoc(
                  doc(Invoice.transactionCol(existingInvoice), transaction.uid)
                );

                existingTransactions.push({
                  ...existingTransaction,
                });

                return hasMergeConflicts(transaction, existingTransaction);
              } catch (error) {
                return false;
              }
            }
          );

          const hasMergeConflict = [
            invoiceMergeConflict,
            ...transactionMergeConflicts,
          ].some((mergeConflict) => mergeConflict);

          if (hasMergeConflict) {
            return {
              ...invoiceBuildData,
              invoice: {
                ...existingInvoice,
                uid: invoiceBuildData.invoice.uid,
              },
              transactions: existingTransactions,
            };
          }
        } catch (error) {
          return;
        }
      });

    if (invoiceMergeConflicts.some((conflict) => conflict)) {
      return {
        ...migrationData,
        data: compact(invoiceMergeConflicts),
      };
    }
  }

  // TODO: CU-219c758 - Do we want to set invoice id on the treatment?
  private async _upsertInvoices(
    migrationData: IPatientInvoiceMigrationData,
    translationMap: TranslationMapHandler,
    patientRef: DocumentReference<IPatient>
  ): Promise<IInvoiceUpdateData[]> {
    return runTransaction(async (firestoreTransaction) => {
      return asyncForEach(migrationData.data, async (invoiceBuildData) => {
        const invoiceDestinationRef = await translationMap.getDestination(
          invoiceBuildData.invoice.uid,
          PATIENT_TRANSACTION_RESOURCE_TYPE
        );

        const invoiceRef = await FirestoreMigrate.upsertDoc(
          Patient.invoiceCol({
            ref: patientRef,
          }),
          { ...invoiceBuildData.invoice },
          invoiceDestinationRef?.id,
          firestoreTransaction
        );

        if (!invoiceDestinationRef) {
          await translationMap.upsert(
            {
              sourceIdentifier: invoiceBuildData.invoice.uid,
              destinationIdentifier: invoiceRef,
              resourceType: PATIENT_TRANSACTION_RESOURCE_TYPE,
            },
            firestoreTransaction
          );
        }

        const transactionRefs = await asyncForEach(
          invoiceBuildData.transactions,
          (transaction) =>
            FirestoreMigrate.upsertDoc(
              Invoice.transactionCol({
                ref: invoiceRef,
              }),
              transaction,
              transaction.uid,
              firestoreTransaction
            )
        );

        const existingCredits = await Firestore.getDocs(
          AccountCredit.col({ ref: patientRef })
        );
        const accountCredits = Invoice.getDueCredits(
          { ...invoiceBuildData.invoice, ref: invoiceRef },
          existingCredits
        );

        const accountCreditRefs = await asyncForEach(
          accountCredits,
          (accountCredit) =>
            FirestoreMigrate.upsertDoc(
              AccountCredit.col({
                ref: patientRef,
              }),
              { ...accountCredit, deleted: false },
              accountCredit.depositUid,
              firestoreTransaction
            )
        );

        return {
          invoiceRef,
          transactionRefs,
          accountCreditRefs,
        };
      });
    });
  }

  private _buildSuccessResponse(
    patient: IGetRecordResponse<IExactPatient>,
    updateData?: IInvoiceUpdateData[]
  ): IDestinationEntityRecord<IPatientInvoiceDestinationRecord> {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      data: {
        sourceRef: patient.record.ref as DocumentReference<
          ISourceEntityRecord<IExactPatient>
        >,
        invoiceData: updateData ?? [],
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: patient.record.ref,
      migratedAt: toTimestamp(),
    };
  }
}
