import { ObservableDataSource } from '@principle-theorem/ng-shared';
import { all$, type WithRef } from '@principle-theorem/shared';
import { type Query } from '@principle-theorem/shared';

/**
 * Observable data table source fed from generic CollectionReference<T>
 */
export class FirebaseDataSource<
  T extends object
> extends ObservableDataSource<T> {
  private _collection: Query<T>;
  declare filteredData: WithRef<T>[];

  constructor(collection?: Query<T>) {
    super();
    if (collection) {
      this.collection = collection;
    }
  }

  set collection(collection: Query<T>) {
    this._collection = collection;
    this.items$ = all$(collection);
  }

  get collection(): Query<T> {
    return this._collection;
  }

  override update(data: WithRef<T>[]): void {
    this.data = data;
    this.loading$.next(false);
  }
}
