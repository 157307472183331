<mat-toolbar color="accent">Switch account to {{ user.name }}</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field>
      <mat-label>Password</mat-label>
      <input
        matInput
        type="password"
        placeholder="Enter password to continue"
        formControlName="password"
        cdkFocusInitial
      />
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" [disabled]="form.invalid">
        Submit
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
