import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TaxStrategy } from '@principle-theorem/accounting';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-tax-status',
  templateUrl: './tax-status.component.html',
  styleUrls: ['./tax-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxStatusComponent {
  label$: Observable<string | undefined>;
  help$: Observable<string | undefined>;
  taxStatus$: ReplaySubject<TaxStrategy> = new ReplaySubject(1);

  @Input()
  set taxStatus(taxStatus: TaxStrategy) {
    if (taxStatus) {
      this.taxStatus$.next(taxStatus);
    }
  }

  constructor() {
    this.label$ = this.taxStatus$.pipe(
      map((taxStatus) => {
        switch (taxStatus) {
          case TaxStrategy.GSTApplicable:
            return 'Included';
          case TaxStrategy.GSTFree:
            return 'None';
          case TaxStrategy.GSTPossible:
            return 'Variable';
          default:
            return;
        }
      })
    );

    this.help$ = this.taxStatus$.pipe(
      map((taxStatus) => {
        if (taxStatus !== TaxStrategy.GSTPossible) {
          return;
        }
        return 'This code might qualify for GST for part of service';
      })
    );
  }
}
