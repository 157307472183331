import { Injectable, type OnDestroy } from '@angular/core';
import {
  type IIntegration,
  type ITypesenseIntegrationData,
} from '@principle-theorem/integrations';
import { snapshot, type CollectionReference } from '@principle-theorem/shared';
import {
  ITypesenseConfig,
  Typesense,
  TypesenseMultiSearchBloc,
  TypesenseSearchBloc,
} from '@principle-theorem/typesense';
import { Subject, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  SearchOptions,
  type SearchParams,
  type SearchResponse,
} from 'typesense/lib/Typesense/Documents';
import {
  type MultiSearchRequestSchema,
  type MultiSearchRequestsSchema,
  type MultiSearchResponse,
} from 'typesense/lib/Typesense/MultiSearch';

@Injectable({
  providedIn: 'root',
})
export class TypesenseSearchService implements OnDestroy {
  private _onDestroy$ = new Subject<void>();

  query$<T extends object>(
    integrationCol: CollectionReference<
      IIntegration<ITypesenseIntegrationData>
    >,
    collectionName: string,
    searchParams: Observable<SearchParams>,
    config: ITypesenseConfig,
    searchOptionOverrides: SearchOptions = {}
  ): Observable<SearchResponse<T>> {
    return Typesense.getScopedSearchClient$(config, integrationCol).pipe(
      switchMap((client) => {
        const searchBloc = new TypesenseSearchBloc<T>(
          client,
          collectionName,
          searchParams,
          this._onDestroy$,
          searchOptionOverrides
        );

        return searchBloc.results$;
      })
    );
  }

  multiQuery$<T extends object>(
    integrationCol: CollectionReference<
      IIntegration<ITypesenseIntegrationData>
    >,
    searches$: Observable<MultiSearchRequestsSchema>,
    commonSearches: Partial<MultiSearchRequestSchema>,
    config: ITypesenseConfig
  ): Observable<MultiSearchResponse<T[]>> {
    return Typesense.getScopedSearchClient$(config, integrationCol).pipe(
      switchMap((searchClient) => {
        const searchBloc = new TypesenseMultiSearchBloc<T>(
          searchClient,
          searches$,
          commonSearches,
          this._onDestroy$
        );

        return searchBloc.results$;
      })
    );
  }

  async clearCache(
    integrationCol: CollectionReference<
      IIntegration<ITypesenseIntegrationData>
    >,
    collectionName: string,
    config: ITypesenseConfig
  ): Promise<void> {
    await snapshot(
      Typesense.getScopedSearchClient$(config, integrationCol).pipe(
        map((client) =>
          client.collections(collectionName).documents().clearCache()
        )
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
  }
}
