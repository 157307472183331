import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { EmptyStateComponent } from '@principle-theorem/ng-principle-shared';
import { ISterilisationMachine } from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { groupBy } from 'lodash';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pr-sterilisation-machines-list',
    imports: [
        CommonModule,
        MatButtonModule,
        MatListModule,
        MatIconModule,
        EmptyStateComponent,
    ],
    templateUrl: './sterilisation-machines-list.component.html',
    styleUrl: './sterilisation-machines-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SterilisationMachinesListComponent {
  machines$ = new ReplaySubject<WithRef<ISterilisationMachine>[]>(1);
  groupedMachines$: Observable<
    { practiceName: string; machines: WithRef<ISterilisationMachine>[] }[]
  >;
  @Output() delete = new EventEmitter<WithRef<ISterilisationMachine>>();
  @Output() edit = new EventEmitter<WithRef<ISterilisationMachine>>();
  displayPracticeNames$: Observable<boolean>;

  @Input()
  set machines(machines: WithRef<ISterilisationMachine>[]) {
    if (machines) {
      this.machines$.next(machines);
    }
  }

  constructor() {
    this.groupedMachines$ = this.machines$.pipe(
      map((machines) => groupBy(machines, 'practice.name')),
      map((groupedMachines) =>
        Object.entries(groupedMachines).map(([practiceName, machines]) => ({
          practiceName,
          machines,
        }))
      )
    );

    this.displayPracticeNames$ = this.groupedMachines$.pipe(
      map((machines) => machines.length > 1)
    );
  }
}
