import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type IPatient } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';

@Component({
    selector: 'pr-patient-relationships-card',
    templateUrl: './patient-relationships-card.component.html',
    styleUrls: ['./patient-relationships-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientRelationshipsCardComponent {
  @Input() patient: WithRef<IPatient>;
}
