import { Injectable } from '@angular/core';
import { BlockNodes } from '@principle-theorem/editor';
import { Editor } from '@tiptap/core';
import { FileUploader, MIMEFileType, type IUploader } from '../uploader';
import { PROVIDERS_REGEXERS, type IRegexProvider } from './paste-rules';

@Injectable()
export class VideoCommandsService {
  embed(embedLink: string, editor: Editor): void {
    PROVIDERS_REGEXERS.map((provider: IRegexProvider) => {
      const match = provider.testRegex.exec(embedLink);
      if (!match) {
        return;
      }
      editor
        .chain()
        .focus()
        .insertContentAt(editor.state.selection.$from.pos, {
          type: BlockNodes.VideoEmbed,
          attrs: {
            src: embedLink.replace(provider.urlRegex, provider.urlText),
          },
        })
        .run();
    });
  }

  async upload(
    uploader: IUploader,
    editor: Editor,
    position: number,
    fileList: FileList
  ): Promise<void> {
    await new FileUploader().upload(
      BlockNodes.VideoUploading,
      BlockNodes.Video,
      MIMEFileType.Video,
      uploader,
      editor,
      position,
      fileList
    );
  }
}
