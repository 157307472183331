<mat-toolbar color="accent">{{ snippet ? 'Edit' : 'Add' }} Snippet</mat-toolbar>

<mat-dialog-content>
  <form [formGroup]="snippetFormGroup">
    <div fxLayout="column">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" required />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput formControlName="description" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Keyword</mat-label>
        <input matInput formControlName="keyword" />
        <mat-hint align="start">
          Set a unique keyword to make finding this snippet easier
        </mat-hint>
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>Snippet Text</mat-label>
        <pr-content-editor
          class="content-editor"
          fxFill
          [menuEnabled]="true"
          formControlName="body"
          placeholder="Snippet Text"
         />
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      mat-dialog-close
      [disabled]="formDisabled$ | async"
      (click)="save()"
    >
      Save
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
