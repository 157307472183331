<ng-container *ngIf="difference$ | ngrxPush as difference">
  <pr-warning *ngIf="difference !== 0">
    Appointment is
    <strong>
      {{ absDifference$ | ngrxPush }} mins
      {{ difference > 0 ? 'shorter' : 'longer' }}
    </strong>
    than the requested {{ requestedMins$ | ngrxPush }} mins
  </pr-warning>
</ng-container>
