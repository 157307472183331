import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action } from '@ngrx/store';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { cancelAction } from '@principle-theorem/ng-shared';
import {
  Brand,
  IPatientDetails,
  Patient,
  PatientRelationship,
  isPatientDetails,
} from '@principle-theorem/principle-core';
import {
  IPatient,
  isPatientRelationship,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  asDocRef,
  filterUndefined,
  getDoc,
  serialise,
  serialise$,
  snapshot,
  toNamedDocument,
  unserialise,
  unserialise$,
} from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap, filter, map, withLatestFrom } from 'rxjs/operators';
import { PatientDetailsActions } from '../actions';
import { AppointmentSchedulingFacade } from '../facades/appointment-scheduling.facade';

@Injectable()
export class PatientDetailsEffects {
  private _actions$ = inject(Actions);
  private _schedulingFacade = inject(AppointmentSchedulingFacade);
  private _snackBar = inject(MatSnackBar);
  private _organisation = inject(OrganisationService);
  private _prevPatientDetails$ = new BehaviorSubject<
    Pick<IPatientDetails, 'name' | 'dateOfBirth'> | undefined
  >(undefined);
  savePatient$ = createEffect(() => this._savePatient$());
  saveNewPatient$ = createEffect(() => this._saveNewPatient$());
  updateExistingPatient$ = createEffect(() => this._updateExistingPatient$(), {
    dispatch: false,
  });
  patchSelectedPatient$ = createEffect(() => this._patchSelectedPatient$());

  private _patchSelectedPatient$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(PatientDetailsActions.patchPatientDetails),
      filter((action) => action.save),
      concatLatestFrom(() => this._schedulingFacade.selectedPatient$),
      map(([{ patientDetails }, selectedPatient]) => {
        if (!selectedPatient) {
          return cancelAction();
        }
        const changes = this._getPatientChangesFromDetails(
          unserialise(patientDetails)
        );
        return PatientDetailsActions.updateExistingPatient(
          serialise({ patient: selectedPatient, changes })
        );
      })
    );
  }

  private _savePatient$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(PatientDetailsActions.savePatient),
      concatLatestFrom(() => [
        this._schedulingFacade.selectedPatient$,
        this._schedulingFacade.patientDetails$,
        this._prevPatientDetails$,
      ]),
      map(([_, patient, currDetails, prevDetails]) => {
        if (!isPatientDetails(currDetails)) {
          return cancelAction();
        }

        const changes = this._getPatientChangesFromDetails(currDetails);

        if (patient) {
          return PatientDetailsActions.updateExistingPatient(
            serialise({ patient, changes })
          );
        }

        const currPatient = {
          name: currDetails.name,
          dateOfBirth: currDetails.dateOfBirth,
        };
        const prevPatient = {
          name: prevDetails?.name,
          dateOfBirth: prevDetails?.dateOfBirth,
        };

        if (isEqual(currPatient, prevPatient)) {
          return cancelAction();
        }

        this._prevPatientDetails$.next(currPatient);

        return PatientDetailsActions.saveNewPatient(
          serialise({ patient: Patient.init(changes) })
        );
      })
    );
  }

  private _saveNewPatient$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(PatientDetailsActions.saveNewPatient),
      unserialise$(),
      withLatestFrom(this._schedulingFacade.brand$),
      concatMap(async ([action, brand]) => {
        this._schedulingFacade.savingPatient$.next(true);
        const doc = await addDoc(Brand.patientCol(brand), action.patient);
        const patient = await getDoc(asDocRef<IPatient>(doc));
        await PatientRelationship.addPrimaryRelationship(patient);
        this._snackBar.open(`Patient ${patient.name} Created`);
        this._schedulingFacade.savingPatient$.next(false);
        return patient;
      }),
      serialise$(),
      map((patient) =>
        patient
          ? PatientDetailsActions.saveNewPatientSuccess({ patient })
          : cancelAction()
      )
    );
  }

  private _updateExistingPatient$(): Observable<void> {
    return this._actions$.pipe(
      ofType(PatientDetailsActions.updateExistingPatient),
      unserialise$(),
      concatMap(async ({ patient, changes }) => {
        this._schedulingFacade.savingPatient$.next(true);
        const staffer = await snapshot(
          this._organisation.staffer$.pipe(filterUndefined())
        );
        await Patient.updatePatientDetails(
          { ...patient, ...changes },
          staffer.ref
        );
        this._schedulingFacade.savingPatient$.next(false);
        await PatientRelationship.addPrimaryRelationship(patient);
      })
    );
  }

  private _getPatientChangesFromDetails(
    details: Partial<IPatientDetails>
  ): Partial<IPatient> {
    const changes = {
      ...details,
      preferredFeeSchedule: details.preferredFeeSchedule
        ? toNamedDocument(details.preferredFeeSchedule)
        : undefined,
    };
    if (isPatientRelationship(details.primaryContact)) {
      changes.primaryContact = {
        ...details.primaryContact,
        patient: toNamedDocument(details.primaryContact.patient),
      };
    }
    return changes;
  }
}
