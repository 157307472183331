import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pr-content-container',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './content-container.component.html',
  styleUrl: './content-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentContainerComponent {
  @Input() shadowColor = 'shadow-slate-500/10';
  @Input() shadow = 'shadow-md';
  @Input() padding = 'p-4';
  @Input() margin = 'm-0';
  @Input() width = 'w-full';
  @Input() borderRadius = 'rounded-md';
  @Input() backgroundColor = 'bg-white';
  @Input() overflow = '';
  @Input() height = '';
  @Input() maxHeight = '';
  @Input() maxWidth = '';
}
