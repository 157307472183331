import {
  BASIC_MEDICAL_HISTORY_FORM_SCHEMA,
  Brand,
} from '@principle-theorem/principle-core';
import {
  CustomMappingAssociatedValueType,
  CustomMappingOption,
  CustomMappingType,
  ICustomMapping,
  ICustomMappingSourceOption,
  IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { IReffable, WithRef } from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import { PatientMedicalConditionOptionSourceEntity } from '../../source/entities/patient-medical-condition-options';
import { getValidMedicalHistoryFormOptions } from './allergy-to-medical-history';

export const MEDICAL_CONDITION_CUSTOM_MAPPING_TYPE = 'medicalConditionMapping';

export const MEDICAL_CONDITION_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Medical Condition Mapping',
    description: `Used to map Core Practice medical conditions to Principle medical history.`,
    type: MEDICAL_CONDITION_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.SelectionList,
});

export class CorePracticeMedicalConditionMappingHandler extends BaseCustomMappingHandler<
  object,
  string
> {
  customMapping = MEDICAL_CONDITION_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const medicalConditionOptions =
      new PatientMedicalConditionOptionSourceEntity();
    const records = await medicalConditionOptions
      .getRecords$(migration, 1000)
      .toPromise();
    return sortBy(
      records
        .map((record) => record.data.data)
        .map((record) => ({
          label: record.description,
          value: record.description,
        })),
      'value'
    );
  }

  async getSelectionListOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<CustomMappingOption[]> {
    return Brand.medicalHistoryFormConfig$(migration.configuration.brand)
      .pipe(
        map((config) => {
          const schema = config?.schema ?? BASIC_MEDICAL_HISTORY_FORM_SCHEMA;
          return [
            {
              value: '',
              description: 'Omit',
              hasAssociatedValue: false,
              associatedValueType: CustomMappingAssociatedValueType.String,
              associatedValueDescription: '',
            },
            ...getValidMedicalHistoryFormOptions(schema).map((option) => ({
              value: option.path,
              description: option.mapTitle ?? '',
              hasAssociatedValue: option.associatedValues ? true : false,
              associatedValueType: CustomMappingAssociatedValueType.String,
              associatedValueDescription: 'Select an option',
            })),
          ];
        })
      )
      .toPromise();
  }

  getAssociatedValueOptions$(
    migration: IPracticeMigration,
    destinationValue: string
  ): Observable<{ name: string }[]> {
    return Brand.medicalHistoryFormConfig$(migration.configuration.brand).pipe(
      map((config) => {
        const schema = config?.schema ?? BASIC_MEDICAL_HISTORY_FORM_SCHEMA;
        const selectedOption = getValidMedicalHistoryFormOptions(schema).find(
          (option) => option.path === destinationValue
        );
        return (selectedOption?.associatedValues ?? []).map((name) => ({
          name,
        }));
      })
    );
  }
}
