<pt-page-footer *ngIf="summary$ | async as summary">
  <div class="flex flex-col">
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Type a message</mat-label>
      <pr-content-editor
        class="max-w-full pr-5"
        [formControl]="noteCtrl"
        (keydown.Enter)="addNote(summary.chat)"
        (keydown.meta.Enter)="addNote(summary.chat)"
        [required]="true"
        [menuEnabled]="false"
        [extensions]="extensions"
      />
    </mat-form-field>

    <div
      class="absolute bottom-[18px] right-[10px] flex items-center justify-end"
    >
      <pt-buttons-container padding="none">
        <button
          mat-icon-button
          color="primary"
          type="flat"
          matTooltip="Send"
          [disabled]="isDisabled$ | async"
          (click)="addNote(summary.chat)"
        >
          <mat-icon>send</mat-icon>
        </button>
      </pt-buttons-container>
    </div>
  </div>
</pt-page-footer>
