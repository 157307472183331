import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IPopupNotification } from '@principle-theorem/notifications';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-popup-notification',
    templateUrl: './popup-notification.component.html',
    styleUrl: './popup-notification.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PopupNotificationComponent {
  notification$ = new ReplaySubject<IPopupNotification>(1);

  @Input()
  set notification(notification: IPopupNotification) {
    if (notification) {
      this.notification$.next(notification);
    }
  }
}
