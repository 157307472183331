import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaxStrategy } from '@principle-theorem/accounting';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { type IProduct } from '@principle-theorem/principle-core/interfaces';
import { Product } from '@principle-theorem/principle-core';
import { type WithRef } from '@principle-theorem/shared';

@Component({
  selector: 'pr-upsert-product-dialog',
  templateUrl: './upsert-product-dialog.component.html',
  styleUrls: ['./upsert-product-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsertProductDialogComponent {
  form: TypedFormGroup<IProductFormData> = new TypedFormGroup<IProductFormData>(
    {
      name: new TypedFormControl('', Validators.required),
      cost: new TypedFormControl(0, Validators.required),
      taxStatus: new TypedFormControl(
        TaxStrategy.GSTApplicable,
        Validators.required
      ),
    }
  );
  taxStatusOptions = [TaxStrategy.GSTFree, TaxStrategy.GSTApplicable];
  trackByTaxStatus = TrackByFunctions.variable();
  update = false;

  constructor(
    private _dialogRef: MatDialogRef<UpsertProductDialogComponent, IProduct>,
    @Inject(MAT_DIALOG_DATA) public data?: IProductData
  ) {
    if (!this.data?.product) {
      return;
    }
    this.update = true;
    this.form.patchValue(this.data.product);
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }

    const data: IProductFormData = this.form.getRawValue();
    const product: IProduct = this.data?.product
      ? {
          ...this.data.product,
          ...data,
        }
      : Product.init(data);

    this._dialogRef.close(product);
  }
}

export interface IProductData {
  product?: WithRef<IProduct>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProductFormData
  extends Pick<IProduct, 'name' | 'cost' | 'taxStatus'> {}
