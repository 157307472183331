import {
  SpecialTransactionAllocationTarget,
  TransactionAllocationTarget,
} from '@principle-theorem/principle-core/interfaces';
import { isDocRef, isSameRef } from '@principle-theorem/shared';

export const AUTO_ALLOCATED_PLACEHOLDER = 'Auto allocated';
export const AUTO_ALLOCATED_TOOLTIP = `Auto allocation will divide this transaction across the practitioners on the invoice.`;

export class AllocationTarget {
  static isSame(
    targetA: TransactionAllocationTarget,
    targetB: TransactionAllocationTarget
  ): boolean {
    if (isDocRef(targetA) && isDocRef(targetB)) {
      return isSameRef(targetA, targetB);
    }
    return targetA === targetB;
  }

  static isUnallocated(
    allocatedTo: TransactionAllocationTarget
  ): allocatedTo is SpecialTransactionAllocationTarget.Unallocated {
    return allocatedTo === SpecialTransactionAllocationTarget.Unallocated;
  }

  static format(allocatedTo: TransactionAllocationTarget): string {
    if (isDocRef(allocatedTo)) {
      return allocatedTo.path;
    }
    return allocatedTo;
  }
}
