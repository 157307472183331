<form fxFill [formGroup]="recurrenceFormGroup" (ngSubmit)="submit()">
  <div fxLayout="column" fxLayoutAlign="space-around stretch">
    <div fxLayout="row" fxLayoutGap="5px">
      <mat-form-field>
        <mat-label>Repeat every</mat-label>
        <input
          type="number"
          min="1"
          matInput
          formControlName="seperationCount"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-select
          formControlName="customFrequencyType"
          (selectionChange)="updateMonthOptions($event)"
        >
          <mat-option
            *ngFor="let freq of frequencies; trackBy: trackByFrequency"
            [value]="freq.value"
          >
            {{ freq.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div
      *ngIf="recurrenceFormGroup.isWeekly()"
      fxLayout="row wrap"
      fxLayoutAlign="start center"
      formGroupName="daysOfWeek"
    >
      <div fxFlex="25">
        <mat-checkbox formControlName="monday">Mon</mat-checkbox>
      </div>
      <div fxFlex="25">
        <mat-checkbox formControlName="tuesday">Tue</mat-checkbox>
      </div>
      <div fxFlex="25">
        <mat-checkbox formControlName="wednesday">Wed</mat-checkbox>
      </div>
      <div fxFlex="25">
        <mat-checkbox formControlName="thursday">Thu</mat-checkbox>
      </div>
      <div fxFlex="25">
        <mat-checkbox formControlName="friday">Fri</mat-checkbox>
      </div>
      <div fxFlex="25">
        <mat-checkbox formControlName="saturday">Sat</mat-checkbox>
      </div>
      <div fxFlex="25">
        <mat-checkbox formControlName="sunday">Sun</mat-checkbox>
      </div>
    </div>

    <div *ngIf="recurrenceFormGroup.isMonthly()">
      <mat-form-field>
        <mat-select formControlName="monthlyOption">
          <mat-option
            *ngFor="let option of monthOptions; trackBy: trackByMonth"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <h3>Ends</h3>

    <div>
      <mat-radio-group
        fxFlex="100"
        formControlName="endingType"
        fxLayout="column"
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-radio-button
            class="never-ending-option"
            fxFlex="30"
            value="never"
            >Never</mat-radio-button
          >
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-radio-button fxFlex="30" value="on">On</mat-radio-button>
          <mat-form-field fxFlex>
            <mat-label>Date</mat-label>
            <input
              matInput
              formControlName="endingDate"
              [matDatepicker]="endingDate"
              (focus)="endingDate.open()"
              (click)="endingDate.open()"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="endingDate"
             />
            <mat-datepicker #endingDate />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-radio-button fxFlex="30" value="after">After</mat-radio-button>
          <mat-form-field fxFlex>
            <input type="number" matInput formControlName="occurrenceCount" />
            <span matTextSuffix>occurrences</span>
          </mat-form-field>
        </div>
      </mat-radio-group>
    </div>
  </div>

  <div *ngIf="showButtons">
    <button type="submit" mat-flat-button (click)="submit()" color="primary">
      Save
    </button>
  </div>
</form>
