<div
  class="container"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="8px"
>
  <mat-icon>error</mat-icon>
  <p>
    <ng-content></ng-content>
  </p>
</div>
