import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  type OnDestroy,
} from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {
  CurrentBrandScope,
  CurrentPracticeScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  TypedFormControl,
  validFormControlChanges$,
} from '@principle-theorem/ng-shared';
import {
  DEFAULT_SCHEDULE_ID,
  FeeSchedule,
} from '@principle-theorem/principle-core';
import {
  FeeScheduleScope,
  type IFeeSchedule,
  type IFeeScheduleGroup,
} from '@principle-theorem/principle-core/interfaces';
import {
  doc$,
  filterUndefined,
  isSameRef,
  snapshot,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { doc } from '@principle-theorem/shared';
import { compact } from 'lodash';
import { combineLatest, type Observable, Subject } from 'rxjs';
import { concatMap, map, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-patient-fee-schedule-selector',
  templateUrl: './patient-fee-schedule-selector.component.html',
  styleUrls: ['./patient-fee-schedule-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PatientFeeScheduleSelectorComponent),
      multi: true,
    },
  ],
})
export class PatientFeeScheduleSelectorComponent
  implements OnDestroy, ControlValueAccessor
{
  private _onDestroy$ = new Subject<void>();
  trackByGroup = TrackByFunctions.field<IFeeScheduleGroup>('name');
  trackBySchedule = TrackByFunctions.ref<WithRef<IFeeSchedule>>();
  control = new TypedFormControl<WithRef<IFeeSchedule>>();
  groups$: Observable<IFeeScheduleGroup[]>;

  @Input() appearance: MatFormFieldAppearance = 'outline';

  @Input()
  set schedule(schedule: WithRef<IFeeSchedule>) {
    if (schedule) {
      this.control.setValue(schedule, { emitEvent: false });
    }
  }

  constructor(
    private _organisation: OrganisationService,
    private _currentBrand: CurrentBrandScope,
    private _currentPractice: CurrentPracticeScope
  ) {
    this.groups$ = combineLatest([
      this._getOrganisationGroup$(),
      this._getCurrentScopedGroups$(),
    ]).pipe(
      map((args: [IFeeScheduleGroup, IFeeScheduleGroup[]]) => {
        const [organisationGroup, scopedGroups] = args;
        return [organisationGroup, ...scopedGroups];
      })
    );

    this._organisation.feeScheduleCol$
      .pipe(
        filterUndefined(),
        concatMap((collection) => doc$(doc(collection, DEFAULT_SCHEDULE_ID))),
        take(1),
        takeUntil(this._onDestroy$)
      )
      .subscribe((value) => {
        if (!this.control.value) {
          this.control.setValue(value, { emitEvent: false });
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  compareFn(
    value: WithRef<IFeeSchedule>,
    schedule: WithRef<IFeeSchedule>
  ): boolean {
    if (!value || !schedule) {
      return false;
    }
    return isSameRef(value, schedule);
  }

  writeValue(schedule: WithRef<IFeeSchedule>): void {
    this.control.setValue(schedule);
  }

  registerOnChange(fn: () => void): void {
    validFormControlChanges$(this.control)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(fn);
  }

  registerOnTouched(_fn: () => void): void {
    //
  }

  private _getOrganisationGroup$(): Observable<IFeeScheduleGroup> {
    return this._organisation.organisation$.pipe(
      filterUndefined(),
      switchMap(async (organisation) => {
        return {
          name: 'Organisation Schedules',
          scope: FeeScheduleScope.Organisation,
          schedules: await snapshot(FeeSchedule.all$(organisation)),
        };
      })
    );
  }

  private _getCurrentScopedGroups$(): Observable<IFeeScheduleGroup[]> {
    return combineLatest([
      this._currentBrand.doc$.pipe(
        switchMap(async (brand) => {
          if (!brand) {
            return;
          }
          const schedules = await snapshot(FeeSchedule.all$(brand));
          if (!schedules.length) {
            return;
          }
          return {
            name: toNamedDocument(brand).name,
            scope: FeeScheduleScope.Brand,
            schedules,
          };
        })
      ),
      this._currentPractice.doc$.pipe(
        switchMap(async (practice) => {
          if (!practice) {
            return;
          }
          const schedules = await snapshot(FeeSchedule.all$(practice));
          if (!schedules.length) {
            return;
          }
          return {
            name: toNamedDocument(practice).name,
            scope: FeeScheduleScope.Practice,
            schedules,
          };
        })
      ),
    ]).pipe(map(compact));
  }
}
