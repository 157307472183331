import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  TRANSACTION_ACTION_MAP,
  type IAddTransactionRequest,
  type IHealthcareClaim,
  type IInvoice,
  type ITransaction,
  type TransactionAction,
} from '@principle-theorem/principle-core/interfaces';
import {
  httpsCallable,
  serialise,
  snapshot,
  unserialise,
  type DocumentReference,
  type SerialisedData,
  type WithRef,
} from '@principle-theorem/shared';

@Injectable()
export class AccountingFunctionsService {
  constructor(private _snackbar: MatSnackBar) {}

  async addTransactionToInvoice<T>(
    invoice: WithRef<IInvoice>,
    transaction: ITransaction<T>,
    action: TransactionAction,
    claim?: IHealthcareClaim
  ): Promise<DocumentReference<ITransaction<T>> | undefined> {
    const data: IAddTransactionRequest = {
      invoiceRef: invoice.ref,
      transaction,
      action,
    };
    if (claim) {
      data.claim = claim;
    }

    const request = serialise(data);

    this._snackbar.open(`${TRANSACTION_ACTION_MAP[action]} Transaction...`);
    try {
      const addTransaction = httpsCallable<
        SerialisedData<IAddTransactionRequest>,
        SerialisedData<DocumentReference<ITransaction>> | undefined
      >('http-accounting-addTransaction');

      const response = await snapshot(addTransaction(request));
      if (!response) {
        this._snackbar.open('Transaction Failed');
        return;
      }
      this._snackbar.open('Transaction Complete');
      return unserialise(response);
    } catch (e) {
      this._snackbar.open('Transaction Failed');
      throw e;
    }
  }
}
