import {
  addDoc,
  deleteDoc,
  firstResult,
  toTimestamp,
} from '@principle-theorem/shared';
import {
  type CollectionReference,
  type Query,
  where,
} from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';
import { type ITemporaryToken, type ITokenStorage } from './temporary-tokens';

export interface ITemporaryOrgToken extends ITemporaryToken {
  orgUid: string;
}

export class TemporaryOrgTokenStorage
  implements ITokenStorage<ITemporaryOrgToken>
{
  async generate(
    data: Omit<ITemporaryOrgToken, 'tokenUid'>,
    tokensCollection: CollectionReference<ITemporaryOrgToken>
  ): Promise<string> {
    const tokenUid: string = uuid();
    await addDoc(
      tokensCollection,
      {
        ...data,
        tokenUid,
      },
      tokenUid
    );
    return tokenUid;
  }

  async get(
    tokenUid: string,
    tokensCollection: Query<ITemporaryOrgToken>
  ): Promise<ITemporaryOrgToken | undefined> {
    return firstResult(
      tokensCollection,
      where('tokenUid', '==', tokenUid),
      where('expiresAt', '>=', toTimestamp())
    );
  }

  async has(
    tokenUid: string,
    tokensCollection: CollectionReference<ITemporaryOrgToken>
  ): Promise<boolean> {
    const token = await firstResult(
      tokensCollection,
      where('tokenUid', '==', tokenUid)
    );
    return !!token;
  }

  async remove(
    tokenUid: string,
    tokensCollection: CollectionReference<ITemporaryOrgToken>
  ): Promise<void> {
    const token = await firstResult(
      tokensCollection,
      where('tokenUid', '==', tokenUid)
    );
    if (!token) {
      return;
    }
    await deleteDoc(token.ref);
  }
}
