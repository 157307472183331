<form [formGroup]="rootForm" class="flex flex-wrap gap-4">
  <ng-container
    *ngIf="rootForm.controls[scopeForms.brand.scopeId] as brandForm"
    [formGroupName]="scopeForms.brand.scopeId"
  >
    <mat-form-field
      *ngIf="scopeForms.brand.isShown$ | async"
      subscriptSizing="dynamic"
    >
      <mat-label>Select a brand</mat-label>
      <mat-select formControlName="brandRef" required>
        <mat-option
          *ngFor="
            let brand of scopeForms.brand.options$ | async;
            trackBy: scopeForms.brand.trackBy
          "
          [value]="brand.ref"
        >
          {{ brand.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container
    *ngIf="rootForm.controls[scopeForms.practice.scopeId] as practiceForm"
    [formGroupName]="scopeForms.practice.scopeId"
  >
    <mat-form-field
      *ngIf="scopeForms.practice.isShown$ | async"
      subscriptSizing="dynamic"
    >
      <mat-label>Select practices</mat-label>
      <mat-select formControlName="practiceRefs" required multiple>
        <mat-option
          *ngFor="
            let practice of scopeForms.practice.options$ | async;
            trackBy: scopeForms.practice.trackBy
          "
          [value]="practice.ref"
        >
          {{ practice.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container
    *ngIf="rootForm.controls[scopeForms.dateRange.scopeId] as dateRangeForm"
    [formGroupName]="scopeForms.dateRange.scopeId"
  >
    <ng-container *ngIf="scopeForms.dateRange.isShown$ | async">
      <mat-form-field
        *ngIf="showQueryableTimestamp$ | async"
        subscriptSizing="dynamic"
      >
        <mat-label>Date to filter by</mat-label>
        <mat-select formControlName="queryableTimestamp" required>
          <mat-option
            *ngFor="
              let queryableTimestamp of scopeForms.dateRange.options$ | async;
              trackBy: scopeForms.dateRange.trackBy
            "
            [value]="queryableTimestamp.property.metadata.id"
            [matTooltip]="queryableTimestamp.property.metadata.summary"
            [matTooltipDisabled]="!queryableTimestamp.property.metadata.summary"
            matTooltipPosition="right"
            matTooltipShowDelay="300"
          >
            {{ queryableTimestamp.property.metadata.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <pt-date-range-form
        [useRangePicker]="true"
        [range]="defaultDateRange$ | async"
        (changed)="setDateRange($event, dateRangeForm)"
      />
    </ng-container>
  </ng-container>
</form>
