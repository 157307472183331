import {
  IFeeSchedule,
  IPricingRule,
  IScopedServiceCode,
  IServiceCode,
  ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference, IReffable } from '@principle-theorem/shared';
import {
  saveDoc,
  shareReplayCold,
  snapshot,
  WithRef,
} from '@principle-theorem/shared';
import { compact, first } from 'lodash';
import { merge, Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PricingRule } from '../pricing-rules/pricing-rule';
import { FeeSchedule } from './fee-schedule';
import { getInheritedSchedule$ } from './fee-schedule-collection';
import { TaxStrategy } from '@principle-theorem/accounting';

export class FeeScheduleManager {
  private _setCurrentSchedule$: Subject<WithRef<IFeeSchedule>> = new Subject();
  currentSchedule$: Observable<WithRef<IFeeSchedule>>;
  inheritedSchedules$: Observable<WithRef<IFeeSchedule>[]>;

  constructor(private _selectFeeSchedule$: Observable<WithRef<IFeeSchedule>>) {
    this.currentSchedule$ = this._getCurrentSchedule$();
    this.inheritedSchedules$ = this.currentSchedule$.pipe(
      switchMap((feeSchedule) => this.getAllInherited(feeSchedule, [])),
      shareReplayCold()
    );
  }

  async updateInheritance(doc: DocumentReference<IFeeSchedule>): Promise<void> {
    const schedule = await snapshot(this.currentSchedule$);
    schedule.inheritedFrom = doc;
    await saveDoc(schedule);
    this._setCurrentSchedule$.next(schedule);
  }

  getDefaultServiceFee(
    inheritedSchedules: WithRef<IFeeSchedule>[],
    code: IScopedServiceCode
  ): IPricingRule {
    const pricingRules = compact(
      inheritedSchedules.map((inheritedSchedule) =>
        FeeSchedule.getServiceFeeByCode(inheritedSchedule, code)
      )
    );

    return first(pricingRules) || PricingRule.init();
  }

  getDefaultServiceFeeByCode$(
    code: IScopedServiceCode
  ): Observable<IPricingRule> {
    return this.inheritedSchedules$.pipe(
      map((schedules) => this.getDefaultServiceFee(schedules, code))
    );
  }

  getDefaultTreatmentFee(
    inheritedSchedules: WithRef<IFeeSchedule>[],
    treatment: DocumentReference<ITreatmentConfiguration>
  ): number {
    const fees = compact(
      inheritedSchedules.map((inheritedSchedule) =>
        FeeSchedule.getTreatmentFee(inheritedSchedule, treatment)
      )
    );
    return first(fees) || 0;
  }

  getDefaultTreatmentFeeByTreatment$(
    treatment: DocumentReference<ITreatmentConfiguration>
  ): Observable<number> {
    return this.inheritedSchedules$.pipe(
      map((schedules) => this.getDefaultTreatmentFee(schedules, treatment))
    );
  }

  async removeServiceFeeByCode(code: IScopedServiceCode): Promise<void> {
    return snapshot(
      this.currentSchedule$.pipe(
        map((schedule) => FeeSchedule.removeServiceFeeByCode(schedule, code))
      )
    );
  }

  async upsertServiceFeeByCode(
    code: IServiceCode,
    pricingRule: IPricingRule
  ): Promise<void> {
    return snapshot(
      this.currentSchedule$.pipe(
        map((schedule) =>
          FeeSchedule.upsertServiceFeeByCode(schedule, code, pricingRule)
        )
      )
    );
  }

  async upsertTreatmentFee(
    treatment: IReffable<ITreatmentConfiguration>,
    price?: number,
    taxStrategy?: TaxStrategy.GSTApplicable | TaxStrategy.GSTFree
  ): Promise<void> {
    return snapshot(
      this.currentSchedule$.pipe(
        map((schedule) =>
          FeeSchedule.upsertTreatmentFee(
            schedule,
            treatment,
            price,
            taxStrategy
          )
        )
      )
    );
  }

  async getAllInherited(
    schedule: WithRef<IFeeSchedule> | undefined,
    results: WithRef<IFeeSchedule>[] = []
  ): Promise<WithRef<IFeeSchedule>[]> {
    results = results.slice();
    if (!schedule || !schedule.inheritedFrom) {
      return results;
    }
    const inherited = await this._getInherited(schedule);
    if (inherited) {
      results.push(inherited);
    }
    return this.getAllInherited(inherited, results);
  }

  async getFeeByTreatment(
    treatment: DocumentReference<ITreatmentConfiguration>
  ): Promise<number> {
    const currentSchedule = await snapshot(this.currentSchedule$);
    const fee = FeeSchedule.getTreatmentFee(currentSchedule, treatment);
    if (fee !== undefined) {
      return fee;
    }
    const inheritedSchedules = await snapshot(this.inheritedSchedules$);
    return this.getDefaultTreatmentFee(inheritedSchedules, treatment);
  }

  async getFeeByCode(code: IScopedServiceCode): Promise<IPricingRule> {
    const currentSchedule = await snapshot(this.currentSchedule$);
    const fee = FeeSchedule.getServiceFeeByCode(currentSchedule, code);
    if (fee !== undefined) {
      return fee;
    }
    const inheritedSchedules = await snapshot(this.inheritedSchedules$);
    return this.getDefaultServiceFee(inheritedSchedules, code);
  }

  private async _getInherited(
    schedule: WithRef<IFeeSchedule>
  ): Promise<WithRef<IFeeSchedule> | undefined> {
    return snapshot(getInheritedSchedule$(schedule));
  }

  private _getCurrentSchedule$(): Observable<WithRef<IFeeSchedule>> {
    return merge(this._selectFeeSchedule$, this._setCurrentSchedule$).pipe(
      shareReplayCold()
    );
  }
}
