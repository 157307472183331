import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_FORMAT,
  isEnumValue,
  isObject,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNull, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { PraktikaTransationType } from './appointment-invoice-payment';

export interface IPraktikaAppointmentInvoiceAdjustment {
  id: number;
  appointment_id: number;
  type_id: PraktikaTransationType;
  description: string;
  effective_date: string; // '2020-02-24'
  is_percent_from_total: boolean;
  percent_value: number | string | null; // '10000'
  amount: number;
  notes: string;
  deposit_transfer_source_id: number | null;

  // entryType: PraktikaInvoiceAddType.Adjustment;
  // effect: PraktikaTransactionEffect;
  // createdDate: string; // '2020-02-24 15:37:34'
  // transactionId: number;
  // isCombined: boolean;
}

export function isPraktikaAppointmentInvoiceAdjustment(
  item: unknown
): item is IPraktikaAppointmentInvoiceAdjustment {
  return (
    isObject(item) &&
    isNumber(item.id) &&
    isNumber(item.appointment_id) &&
    isEnumValue(PraktikaTransationType, item.type_id) &&
    isString(item.description) &&
    isString(item.effective_date) && // '2020-02-24'
    isBoolean(item.is_percent_from_total) &&
    (isNull(item.percent_value) ||
      isString(item.percent_value) ||
      isNumber(item.percent_value)) && // '10000'
    isNumber(item.amount) &&
    isString(item.notes) &&
    (isNumber(item.deposit_transfer_source_id) ||
      isNull(item.deposit_transfer_source_id))

    // item.entryType === PraktikaInvoiceAddType.Adjustment &&
    // isEnumValue(PraktikaTransactionEffect, item.effect) &&
    // isString(item.createdDate) && // '2020-02-24 15:37:34'
    // isNumber(item.transactionId) &&
    // isBoolean(item.isCombined)
  );
}

export const PATIENT_APPOINTMENT_INVOICE_ADJUSTMENT_RESOURCE_TYPE =
  'patientAppointmentInvoiceAdjustment';

export const PATIENT_APPOINTMENT_INVOICE_ADJUSTMENT_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Appointment Invoice Adjustment List',
      description: '',
      idPrefix: PATIENT_APPOINTMENT_INVOICE_ADJUSTMENT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface IPraktikaAppointmentInvoiceAdjustmentTranslations {
  effectiveDate: Timestamp;
}

export interface IPraktikaAppointmentInvoiceAdjustmentFilters {
  appointmentId: string;
  effectiveDate: Timestamp;
}

const PATIENT_APPOINTMENT_INVOICE_ADJUSTMENT_SOURCE_QUERY = `SELECT
  convert_to_integer(iAppointmentId) as appointment_id,
  convert_to_integer(iAdjustmentId) as id,
  convert_to_integer(iAdjustmentType) as type_id,
  vchDesc as description,
  dtEffectiveDate as effective_date,
  convert_to_boolean(bPercentFromTotal) as is_percent_from_total,
  convert_to_integer(iPercentValue) as percent_value,
  convert_to_integer(iAmount) as amount,
  quote_for_export as notes,
  convert_to_integer(iDepositTransferSourceId) as deposit_transfer_source_id
FROM appointment_adjustments
ORDER BY id DESC
`;

export class PatientAppointmentInvoiceAdjustmentSourceEntity extends BaseSourceEntity<
  IPraktikaAppointmentInvoiceAdjustment,
  IPraktikaAppointmentInvoiceAdjustmentTranslations,
  IPraktikaAppointmentInvoiceAdjustmentFilters
> {
  sourceEntity = PATIENT_APPOINTMENT_INVOICE_ADJUSTMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_APPOINTMENT_INVOICE_ADJUSTMENT_RESOURCE_TYPE;
  sourceQuery = PATIENT_APPOINTMENT_INVOICE_ADJUSTMENT_SOURCE_QUERY;
  verifySourceFn = isPraktikaAppointmentInvoiceAdjustment;
  override transformDataFn = flow([]);
  override dateFilterField: keyof IPraktikaAppointmentInvoiceAdjustmentFilters =
    'effectiveDate';

  translate(
    note: IPraktikaAppointmentInvoiceAdjustment,
    timezone: Timezone
  ): IPraktikaAppointmentInvoiceAdjustmentTranslations {
    return {
      effectiveDate: toTimestamp(
        moment.tz(note.effective_date, ISO_DATE_FORMAT, timezone).startOf('day')
      ),
    };
  }

  getSourceRecordId(data: IPraktikaAppointmentInvoiceAdjustment): number {
    return data.id;
  }

  getSourceLabel(data: IPraktikaAppointmentInvoiceAdjustment): string {
    return `${data.id} ${data.appointment_id}`;
  }

  getFilterData(
    data: IPraktikaAppointmentInvoiceAdjustment,
    timezone: Timezone
  ): IPraktikaAppointmentInvoiceAdjustmentFilters {
    return {
      appointmentId: data.appointment_id.toString(),
      effectiveDate: toTimestamp(
        moment.tz(data.effective_date, ISO_DATE_FORMAT, timezone).startOf('day')
      ),
    };
  }
}
