import { Validators } from '@angular/forms';
import {
  type TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  type IContactNumber,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';

export class ContactNumberFormGroup extends TypedFormGroup<IContactNumber> {
  constructor(required: boolean = true) {
    super({
      label: new TypedFormControl<string>(''),
      number: new TypedFormControl<string>(
        '',
        required ? Validators.required : undefined
      ),
    });
  }
}

export class ContactNumberControls {
  constructor(private _formArray: TypedFormArray<IContactNumber>) {}

  get controls(): TypedFormGroup<IContactNumber>[] {
    return this._formArray.controls as TypedFormGroup<IContactNumber>[];
  }

  addControl(
    index: number,
    options?: {
      emitEvent?: boolean;
    }
  ): void {
    const insertIndex = index + 1;
    this._formArray.insert(
      insertIndex,
      new ContactNumberFormGroup(false),
      options
    );
  }

  removeControl(index: number): void {
    if (index < 1) {
      return;
    }
    this._formArray.removeAt(index);
  }

  reset(options?: { emitEvent?: boolean }): void {
    this._formArray.clear(options);
    this.addControl(0, options);
  }

  initContactNumberControls(
    patient: IPatient,
    options?: {
      emitEvent?: boolean;
    }
  ): void {
    this.reset(options);
    patient.contactNumbers?.map((_, index) =>
      index ? this.addControl(index, options) : undefined
    );
  }

  disable(): void {
    this._formArray.disable();
  }
}
