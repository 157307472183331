<pr-profile-card [hasActions]="false" *ngIf="lab$ | async as lab">
  <pr-profile-card-title>{{ lab.name }}</pr-profile-card-title>

  <mat-list dense>
    <mat-list-item>
      <mat-icon matListItemIcon>phone</mat-icon>
      <a matListItemTitle [routerLink]="[]" (click)="dialogService.openPhone()">
        {{ lab.phone }}
      </a>
    </mat-list-item>
    <mat-list-item>
      <mat-icon matListItemIcon>mail_outline</mat-icon>
      <a matListItemTitle [routerLink]="[]" (click)="dialogService.openEmail()">
        {{ lab.email }}
      </a>
    </mat-list-item>
    <mat-list-item>
      <mat-icon matListItemIcon>place</mat-icon>
      <span matListItemTitle>{{ lab.address }}</span>
    </mat-list-item>
  </mat-list>

  <mat-divider />

  <pr-map-preview [coordinates]="coordinates$ | async" />
</pr-profile-card>
