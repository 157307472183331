import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  type TreatmentStepStatus,
  TREATMENT_STEP_STATUS_COLOUR_MAP,
} from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-treatment-step-status',
  templateUrl: './treatment-step-status.component.html',
  styleUrls: ['./treatment-step-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentStepStatusComponent {
  @Input() status: TreatmentStepStatus;
  isDense = false;
  isInline = false;
  statusColourMap = TREATMENT_STEP_STATUS_COLOUR_MAP;

  @Input()
  set dense(dense: BooleanInput) {
    this.isDense = coerceBooleanProperty(dense);
  }

  @Input()
  set inline(inline: BooleanInput) {
    this.isInline = coerceBooleanProperty(inline);
  }
}
