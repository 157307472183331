import { Injectable } from '@angular/core';
import { IPopupNotification } from '@principle-theorem/notifications';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupNotificationsService {
  private _timeoutMs = 3000;
  private _maxStackSize = 8;
  notifications$ = new BehaviorSubject<IPopupNotification[]>([]);

  addNotification(
    notification: IPopupNotification | Observable<IPopupNotification>
  ): void {
    if (notification instanceof Observable) {
      notification.subscribe((resolvedNotification) =>
        this.addNotification(resolvedNotification)
      );
      return;
    }

    const currentNotifications = this.notifications$.value.filter(
      (existingNotification) =>
        existingNotification.groupId !== notification.groupId
    );

    const existingNotificationIndex = this.notifications$.value.findIndex(
      (existingNotification) => existingNotification.uid === notification.uid
    );

    if (existingNotificationIndex !== -1) {
      currentNotifications.splice(existingNotificationIndex, 1, notification);
    } else {
      if (currentNotifications.length >= this._maxStackSize) {
        currentNotifications.shift();
      }

      currentNotifications.push(notification);
    }

    this.notifications$.next(currentNotifications);

    if (!notification.hasTimeout) {
      return;
    }

    setTimeout(
      () => this.removeNotification(notification.uid),
      this._timeoutMs
    );
  }

  removeNotification(uid: string): void {
    const currentNotifications = this.notifications$.value;
    const index = currentNotifications.findIndex(
      (notification) => notification.uid === uid
    );
    if (index === -1) {
      return;
    }
    currentNotifications.splice(index, 1);
    this.notifications$.next(currentNotifications);
  }
}
