<div fxLayout="row" fxLayoutGap="16px">
  <pt-search-field icon="none" fxFlex>
    <input
      matInput
      placeholder="Filter"
      #search
      (keyup)="searchTags(search.value)"
    />
  </pt-search-field>
  <button mat-raised-button color="primary" (click)="add()">Create Tag</button>
</div>

<pr-empty-state *ngIf="emptyState$ | async" image="list" title="tags" />

<div *ngIf="dataSource.filteredData.length">
  <mat-table
    [dataSource]="dataSource"
    [trackBy]="trackByTag"
    matSort
    matSortActive="name"
    matSortDirection="asc"
  >
    <mat-header-row *matHeaderRowDef="displayColumns" />
    <mat-row *matRowDef="let row; columns: displayColumns" />

    <ng-container matColumnDef="name">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="w-full truncate"
      >
        Name
      </mat-header-cell>
      <mat-cell *matCellDef="let tag" class="w-full truncate">
        <pr-tag-list-item [tag]="tag" class="w-full" />
      </mat-cell>
    </ng-container>
  </mat-table>
  <mat-paginator
    #paginator
    [length]="dataSource.data.length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    showFirstLastButtons
    class="sticky bottom-0 z-50"
  />
</div>
