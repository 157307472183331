import { ADA_CODE_MAP } from './ada';
import {
  type IServiceCode,
  type IServiceProvider,
  type ItemCodeAliases,
  ServiceCodeType,
} from '../service-item';
import { Region } from '@principle-theorem/shared';

export const CDBS_CODE_MAP: ItemCodeAliases = Object.entries({
  '88011': {
    ...ADA_CODE_MAP['011'],
    code: '88011',
    rootCode: '011',
    fee: 57.65,
  },
  '88012': {
    ...ADA_CODE_MAP['012'],
    code: '88012',
    rootCode: '012',
    fee: 47.9,
  },
  '88013': {
    ...ADA_CODE_MAP['013'],
    code: '88013',
    rootCode: '013',
    fee: 30.1,
  },
  '88022': {
    ...ADA_CODE_MAP['022'],
    code: '88022',
    rootCode: '022',
    fee: 33.35,
  },
  '88025': {
    ...ADA_CODE_MAP['025'],
    code: '88025',
    rootCode: '025',
    fee: 67.4,
    deleted: false,
  },
  '88111': {
    ...ADA_CODE_MAP['111'],
    code: '88111',
    rootCode: '111',
    fee: 58.9,
  },
  '88114': {
    ...ADA_CODE_MAP['114'],
    code: '88114',
    rootCode: '114',
    fee: 98.2,
  },
  '88115': {
    ...ADA_CODE_MAP['115'],
    code: '88115',
    rootCode: '115',
    fee: 63.85,
  },
  '88121': {
    ...ADA_CODE_MAP['121'],
    code: '88121',
    rootCode: '121',
    fee: 37.85,
  },
  '88161': {
    ...ADA_CODE_MAP['161'],
    code: '88161',
    rootCode: '161',
    fee: 50.45,
  },
  '88162': {
    ...ADA_CODE_MAP['161'],
    code: '88162',
    rootCode: '161',
    notes: 'Subsequent 88161 treatments',
    fee: 25.25,
  },
  '88213': {
    ...ADA_CODE_MAP['213'],
    code: '88213',
    rootCode: '213',
    fee: 76.3,
  },
  '88221': {
    ...ADA_CODE_MAP['221'],
    code: '88221',
    rootCode: '221',
    fee: 58.0,
  },
  '88311': {
    ...ADA_CODE_MAP['311'],
    code: '88311',
    rootCode: '311',
    fee: 143.75,
  },
  '88314': {
    ...ADA_CODE_MAP['314'],
    code: '88314',
    rootCode: '314',
    fee: 183.75,
  },
  '88316': {
    ...ADA_CODE_MAP['311'],
    code: '88316',
    rootCode: '311',
    notes:
      'Additional extraction provided on the same day as a service described in item 88311 or 88314 is provided to the patient.',
    fee: 90.6,
  },
  '88322': {
    ...ADA_CODE_MAP['322'],
    code: '88322',
    rootCode: '322',
    fee: 233.3,
  },
  '88323': {
    ...ADA_CODE_MAP['323'],
    code: '88323',
    rootCode: '323',
    fee: 266.45,
    deleted: false,
  },
  '88324': {
    ...ADA_CODE_MAP['324'],
    code: '88324',
    rootCode: '324',
    fee: 358.4,
  },
  '88326': {
    ...ADA_CODE_MAP['324'],
    code: '88326',
    rootCode: '324',
    notes:
      'Additional surgical extraction provided on the same day as a service described in item 88322, 88323 or 88324 is provided to the patient.',
    fee: 190.85,
  },
  '88351': {
    ...ADA_CODE_MAP['351'],
    code: '88351',
    rootCode: '351',
    fee: 175.15,
  },
  '88384': {
    ...ADA_CODE_MAP['384'],
    code: '88384',
    rootCode: '384',
    fee: 209.0,
  },
  '88386': {
    ...ADA_CODE_MAP['386'],
    code: '88386',
    rootCode: '386',
    fee: 215.6,
  },
  '88387': {
    ...ADA_CODE_MAP['387'],
    code: '88387',
    rootCode: '387',
    fee: 422.2,
  },
  '88392': {
    ...ADA_CODE_MAP['392'],
    code: '88392',
    rootCode: '392',
    fee: 106.1,
  },
  '88411': {
    ...ADA_CODE_MAP['411'],
    code: '88411',
    rootCode: '411',
    fee: 38.15,
  },
  '88412': {
    ...ADA_CODE_MAP['412'],
    code: '88412',
    rootCode: '412',
    fee: 130.7,
  },
  '88414': {
    ...ADA_CODE_MAP['414'],
    code: '88414',
    rootCode: '414',
    fee: 83.3,
  },
  '88415': {
    ...ADA_CODE_MAP['415'],
    code: '88415',
    rootCode: '415',
    fee: 234.5,
  },
  '88416': {
    ...ADA_CODE_MAP['416'],
    code: '88416',
    rootCode: '416',
    fee: 111.7,
  },
  '88417': {
    ...ADA_CODE_MAP['417'],
    code: '88417',
    rootCode: '417',
    fee: 228.4,
  },
  '88418': {
    ...ADA_CODE_MAP['418'],
    code: '88418',
    rootCode: '418',
    fee: 106.8,
  },
  '88419': {
    ...ADA_CODE_MAP['419'],
    code: '88419',
    rootCode: '419',
    fee: 151.0,
  },
  '88421': {
    ...ADA_CODE_MAP['421'],
    code: '88421',
    rootCode: '421',
    fee: 130.7,
  },
  '88455': {
    ...ADA_CODE_MAP['455'],
    code: '88455',
    rootCode: '455',
    fee: 115.7,
  },
  '88458': {
    ...ADA_CODE_MAP['458'],
    code: '88458',
    rootCode: '458',
    fee: 154.35,
    deleted: false,
  },
  '88511': {
    ...ADA_CODE_MAP['511'],
    code: '88511',
    rootCode: '511',
    fee: 114.1,
  },
  '88512': {
    ...ADA_CODE_MAP['512'],
    code: '88512',
    rootCode: '512',
    fee: 139.95,
  },
  '88513': {
    ...ADA_CODE_MAP['513'],
    code: '88513',
    rootCode: '513',
    fee: 167.0,
  },
  '88514': {
    ...ADA_CODE_MAP['514'],
    code: '88514',
    rootCode: '514',
    fee: 190.35,
  },
  '88515': {
    ...ADA_CODE_MAP['515'],
    code: '88515',
    rootCode: '515',
    fee: 217.3,
  },
  '88521': {
    ...ADA_CODE_MAP['521'],
    code: '88521',
    rootCode: '521',
    fee: 126.4,
  },
  '88522': {
    ...ADA_CODE_MAP['522'],
    code: '88522',
    rootCode: '522',
    fee: 153.45,
  },
  '88523': {
    ...ADA_CODE_MAP['523'],
    code: '88523',
    rootCode: '523',
    fee: 181.75,
  },
  '88524': {
    ...ADA_CODE_MAP['524'],
    code: '88524',
    rootCode: '524',
    fee: 210.05,
  },
  '88525': {
    ...ADA_CODE_MAP['525'],
    code: '88525',
    rootCode: '525',
    fee: 246.85,
  },
  '88531': {
    ...ADA_CODE_MAP['531'],
    code: '88531',
    rootCode: '531',
    fee: 134.95,
  },
  '88532': {
    ...ADA_CODE_MAP['532'],
    code: '88532',
    rootCode: '532',
    fee: 169.45,
  },
  '88533': {
    ...ADA_CODE_MAP['533'],
    code: '88533',
    rootCode: '533',
    fee: 203.7,
  },
  '88534': {
    ...ADA_CODE_MAP['534'],
    code: '88534',
    rootCode: '534',
    fee: 229.65,
  },
  '88535': {
    ...ADA_CODE_MAP['535'],
    code: '88535',
    rootCode: '535',
    fee: 265.2,
  },
  '88572': {
    ...ADA_CODE_MAP['572'],
    code: '88572',
    rootCode: '572',
    fee: 53.4,
  },
  '88574': {
    ...ADA_CODE_MAP['574'],
    code: '88574',
    rootCode: '574',
    fee: 44.95,
  },
  '88575': {
    ...ADA_CODE_MAP['575'],
    code: '88575',
    rootCode: '575',
    fee: 30.65,
  },
  '88579': {
    ...ADA_CODE_MAP['579'],
    code: '88579',
    rootCode: '579',
    fee: 106.1,
  },
  '88586': {
    ...ADA_CODE_MAP['586'],
    code: '88586',
    rootCode: '586',
    fee: 281.5,
  },
  '88587': {
    ...ADA_CODE_MAP['587'],
    code: '88587',
    rootCode: '587',
    fee: 167.0,
  },
  '88597': {
    ...ADA_CODE_MAP['597'],
    code: '88597',
    rootCode: '597',
    fee: 96.5,
  },
  '88721': {
    ...ADA_CODE_MAP['721'],
    code: '88721',
    rootCode: '721',
    fee: 478.1,
  },
  '88722': {
    ...ADA_CODE_MAP['722'],
    code: '88722',
    rootCode: '722',
    fee: 478.1,
  },
  '88723': {
    ...ADA_CODE_MAP['723'],
    code: '88723',
    rootCode: '723',
    fee: 358.5,
  },
  '88724': {
    ...ADA_CODE_MAP['724'],
    code: '88724',
    rootCode: '724',
    fee: 358.5,
  },
  '88731': {
    ...ADA_CODE_MAP['731'],
    code: '88731',
    rootCode: '731',
    fee: 48.25,
  },
  '88733': {
    ...ADA_CODE_MAP['733'],
    code: '88733',
    rootCode: '733',
    fee: 39.6,
  },
  '88736': {
    ...ADA_CODE_MAP['736'],
    code: '88736',
    rootCode: '736',
    fee: 9.95,
  },
  '88741': {
    ...ADA_CODE_MAP['741'],
    code: '88741',
    rootCode: '741',
    fee: 57.5,
  },
  '88761': {
    ...ADA_CODE_MAP['761'],
    code: '88761',
    rootCode: '761',
    fee: 157.85,
  },
  '88762': {
    ...ADA_CODE_MAP['762'],
    code: '88762',
    rootCode: '762',
    fee: 164.95,
  },
  '88764': {
    ...ADA_CODE_MAP['764'],
    code: '88764',
    rootCode: '764',
    fee: 157.85,
  },
  '88765': {
    ...ADA_CODE_MAP['765'],
    code: '88765',
    rootCode: '765',
    fee: 164.95,
  },
  '88766': {
    ...ADA_CODE_MAP['766'],
    code: '88766',
    rootCode: '766',
    fee: 157.85,
  },
  '88768': {
    ...ADA_CODE_MAP['768'],
    code: '88768',
    rootCode: '768',
    fee: 167.0,
  },
  '88776': {
    ...ADA_CODE_MAP['776'],
    code: '88776',
    rootCode: '776',
    fee: 50.45,
  },
  '88911': {
    ...ADA_CODE_MAP['911'],
    code: '88911',
    rootCode: '911',
    fee: 74.85,
  },
  '88942': {
    ...ADA_CODE_MAP['942'],
    code: '88942',
    rootCode: '942',
    fee: 146.75,
  },
  '88943': {
    ...ADA_CODE_MAP['943'],
    code: '88943',
    rootCode: '943',
    fee: 73.35,
  },
}).reduce(
  (codes, [key, code]) => ({
    ...codes,
    [key]: {
      ...code,
      type: ServiceCodeType.CDBS,
    },
  }),
  {}
);

export const CDBS_SERVICE_CODES: IServiceCode[] = Object.values(CDBS_CODE_MAP);

export const CDBS_SERVICE: IServiceProvider = {
  label: 'CDBS',
  description: 'Medicare CDBS',
  type: ServiceCodeType.CDBS,
  parentType: ServiceCodeType.ADA,
  items: CDBS_CODE_MAP,
  region: Region.Australia,
};
