import { ServiceProviderHandler } from '@principle-theorem/principle-core';
import { ITranslationMap } from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { ItemCodeNoteType } from './item-codes-to-notes-xlsx';
import { ItemCodeResourceMapType } from './item-codes-to-xlsx';

export function isClinicalNoteCode(
  serviceCode: string,
  sourceRecordId: string,
  itemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[],
  itemCodeToNotes: WithRef<ITranslationMap<object, ItemCodeNoteType>>[]
): boolean {
  const code = ServiceProviderHandler.findServiceCode(serviceCode);
  if (code) {
    return true;
  }

  const mappedCode = itemCodes.find(
    (sourceItemCode) =>
      sourceItemCode.sourceIdentifier === sourceRecordId &&
      !!sourceItemCode.destinationValue
  );
  if (
    mappedCode &&
    (mappedCode.destinationValue === ItemCodeResourceMapType.ServiceCode ||
      mappedCode.destinationValue ===
        ItemCodeResourceMapType.TreatmentConfiguration)
  ) {
    return true;
  }

  const mappedCodeNote = itemCodeToNotes.find(
    (sourceItemCode) =>
      sourceItemCode.sourceIdentifier === sourceRecordId &&
      sourceItemCode.destinationValue === ItemCodeNoteType.ClinicalNote
  );
  if (mappedCodeNote) {
    return true;
  }

  // eslint-disable-next-line no-console
  console.error(
    `Couldn't find mapped code for ${serviceCode} with id ${sourceRecordId}`
  );
  return false;
}
