@if (healthFundForm$ | async; as form) {
  <div class="flex flex-wrap gap-4" [formGroup]="form">
    <mat-form-field class="flex-auto">
      <mat-label>Health Fund Name</mat-label>
      <input matInput formControlName="fundCode" />
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Membership Number</mat-label>
      <input matInput formControlName="membershipNumber" />
      <mat-error *ngIf="form.controls.membershipNumber.hasError('pattern')">
        Must contain only numbers
      </mat-error>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Member Number</mat-label>
      <input matInput formControlName="memberNumber" />
      <mat-error *ngIf="form.controls.memberNumber.hasError('pattern')">
        Must container only numbers
      </mat-error>
      <mat-error *ngIf="form.controls.memberNumber.hasError('maxlength')">
        You can enter a maximum of 2 numbers
      </mat-error>
    </mat-form-field>
  </div>
}
