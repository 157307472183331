import {
  getTaxRateByRegion,
  roundTo2Decimals,
  TaxStrategy,
} from '@principle-theorem/accounting';
import {
  ADA_CODE_TREATMENT_CONFIG_ID,
  Brand,
  CalculateTreatmentWeight,
  ChartedItemScopeResolver,
  ChartedSurface,
  ChartedTreatment,
  determineTaxFromStrategy,
  FeeSchedule,
  getQuadrant,
  getQuadrantIndex,
  PricedServiceCodeEntry,
  stafferToNamedDoc,
  toothNumberToDeciduous,
  TreatmentConfiguration,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  ChartableSurface,
  IChartedRef,
  IChartedTreatment,
  IDestinationEntityJobRunOptions,
  IFeeSchedule,
  IPatient,
  IPractice,
  IStaffer,
  isToothNumber,
  IToothRef,
  ITranslationMap,
  ITreatmentStep,
  ToothNumber,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  Firestore,
  getDoc,
  getDoc$,
  getError,
  HISTORY_DATE_FORMAT,
  ISO_DATE_FORMAT,
  resolveSequentially,
  snapshotCombineLatest,
  sortByCreatedAt,
  sortTimestampAsc,
  toISODate,
  toMoment,
  toMomentTz,
  toNamedDocument,
  toTimestamp,
  undeletedQuery,
  WithRef,
  type INamedDocument,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { compact, first, groupBy, sortBy } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, from, type Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import { feeScheduleResolverFn } from '../../../destination/entities/fee-schedules';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';
import {
  BasePatientTreatmentPlanDestinationEntity,
  IPatientTreatmentPlanJobData,
  IPatientTreatmentPlanMigrationData,
  IStepWithIdentifiers,
  ITreatmentPlanStepPair,
  PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY,
  PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
} from '../../../destination/entities/patient-treatment-plans';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { PracticeIdFilter } from '../../../destination/filters/practice-id-filter';
import { resolveMappedCode } from '../../../mappings/item-codes';
import { getPractitionerOrDefault } from '../../../mappings/staff';
import { PracticeMigration } from '../../../practice-migrations';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientSourceEntity,
  type ID4WPatient,
  type ID4WPatientFilters,
  type ID4WPatientTranslations,
} from '../../source/entities/patient';
import {
  ID4WAppointment,
  ID4WAppointmentTranslations,
  PatientAppointmentSourceEntity,
} from '../../source/entities/patient-appointment';
import {
  D4WToothSurface,
  ID4WPatientTreatment,
  ID4WPatientTreatmentFilters,
  ID4WPatientTreatmentTranslations,
  PatientTreatmentSourceEntity,
  TOOTH_SURFACES_MAP,
} from '../../source/entities/patient-treatment';
import { D4WFeeScheduleMappingHandler } from '../mappings/fee-schedules';
import { D4WItemCodeToTreatmentMappingHandler } from '../mappings/item-code-to-treatment';
import { D4WItemCodeMappingHandler } from '../mappings/item-codes';
import { D4WPracticeMappingHandler } from '../mappings/practices';
import { D4WStafferMappingHandler } from '../mappings/staff';
import { FeeScheduleDestinationEntity } from './fee-schedules';
import { PatientDestinationEntity } from './patients';

const patientTreatmentPlanDestinationEntity =
  DestinationEntity.withMetadataDescription(
    PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY,
    `This migrates the treatments for each patient and creates:
      - A single step for performed treatments
      - A single step for all pending treatments

      Some points:
      - Notes for each treatment are consolidated on the clinical chart notes
      - If a quadrant has been used for a tooth number, it will be omitted. It's very likely that a clinical note has accompanied the treatment so this is a negligible omission.
      `
  );

export type JobData = IPatientTreatmentPlanJobData<
  ID4WPatient,
  ID4WPatientTranslations,
  ID4WPatientFilters
> & {
  practices: WithRef<ITranslationMap<IPractice>>[];
};

export class PatientTreatmentPlanDestinationEntity extends BasePatientTreatmentPlanDestinationEntity<
  ID4WPatient,
  JobData
> {
  override destinationEntity = patientTreatmentPlanDestinationEntity;
  override patientSourceEntity = new PatientSourceEntity();

  override filters = [
    new PracticeIdFilter<JobData>((jobData) =>
      jobData.sourcePatient.data.data.practice_id.toString()
    ),
    new PatientIdFilter<JobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    treatments: new PatientTreatmentSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    feeSchedules: new FeeScheduleDestinationEntity(),
  };

  customMappings = {
    staff: new D4WStafferMappingHandler(),
    practice: new D4WPracticeMappingHandler(),
    itemCodes: new D4WItemCodeMappingHandler(),
    itemCodeToTreatment: new D4WItemCodeToTreatmentMappingHandler(),
    feeSchedules: new D4WFeeScheduleMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<JobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords(translationMap),
      translationMap.getByType<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ = from(
      this.customMappings.itemCodes.getRecords(translationMap)
    );
    const practices$ = from(
      this.customMappings.practice.getRecords(translationMap)
    );
    const brand$ = PracticeMigration.brand$(migration);
    const practitioners$ = brand$.pipe(
      switchMap((brand) => Firestore.getDocs(Brand.stafferCol(brand)))
    );
    const treatmentConfigurationMappings$ = from(
      this.customMappings.itemCodeToTreatment.getRecords(translationMap)
    );
    const defaultTreatmentConfiguration$ = brand$.pipe(
      switchMap((brand) =>
        getDoc$(TreatmentConfiguration.col(brand), ADA_CODE_TREATMENT_CONFIG_ID)
      )
    );
    const treatmentCategories$ = brand$.pipe(
      switchMap((brand) => Brand.treatmentCategories$(brand))
    );
    const feeSchedules$ = from(
      this.customMappings.feeSchedules.getRecords(translationMap)
    );
    const taxRate$ = PracticeMigration.organisation$(migration).pipe(
      map((org) => getTaxRateByRegion(org.region))
    );
    const treatmentConfigurations$ = brand$.pipe(
      switchMap((brand) =>
        Firestore.getDocs(undeletedQuery(TreatmentConfiguration.col(brand)))
      )
    );

    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([
        staff$,
        practitioners$,
        sourceItemCodes$,
        practices$,
        feeSchedules$,
        defaultTreatmentConfiguration$,
        treatmentConfigurations$,
        treatmentConfigurationMappings$,
        treatmentCategories$,
        taxRate$,
      ]),
    ]).pipe(
      map(
        ([
          sourcePatients,
          [
            staff,
            practitioners,
            sourceItemCodes,
            practices,
            feeSchedules,
            defaultTreatmentConfiguration,
            treatmentConfigurations,
            treatmentConfigurationMappings,
            treatmentCategories,
            taxRate,
          ],
        ]) =>
          sourcePatients.map((sourcePatient) => ({
            sourcePatient,
            staff,
            practitioners,
            defaultTreatmentConfiguration,
            treatmentConfigurations,
            treatmentConfigurationMappings,
            sourceItemCodes,
            practices,
            treatmentCategories,
            feeSchedules,
            taxRate,
          }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: JobData
  ): Promise<
    | IPatientTreatmentPlanMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients
      .getSourceRecordId(data.sourcePatient.data.data)
      .toString();

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(data.sourcePatient, 'No patient');
    }

    const providerId = data.sourcePatient.data.data.provider_id?.toString();
    const practitioner = getPractitionerOrDefault(
      providerId,
      data.staff,
      data.practitioners
    );

    if (!practitioner) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `Couldn't resolve practitioner ${providerId ?? ''}`
      );
    }

    try {
      const treatments = await this.sourceEntities.treatments.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

      const planPairs = await this._buildTreatmentPlanData(
        data,
        translationMap,
        practitioner,
        treatments,
        migration,
        sourcePatientId
      );

      return {
        sourcePatientId,
        patientRef,
        planPairs: planPairs.filter((planPair) => planPair.steps.length),
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  private async _buildTreatmentPlanData(
    data: JobData,
    translationMap: TranslationMapHandler,
    practitioner: INamedDocument<IStaffer>,
    sourceTreatments: IGetRecordResponse<
      ID4WPatientTreatment,
      ID4WPatientTreatmentTranslations,
      ID4WPatientTreatmentFilters
    >[],
    migration: WithRef<IPracticeMigration>,
    sourcePatientId: string
  ): Promise<ITreatmentPlanStepPair[]> {
    const defaultFeeSchedule = await FeeSchedule.getPreferredOrDefault(
      migration.configuration.organisation
    );

    const treatmentPlansGroups = groupBy(
      sourceTreatments,
      (treatment) => treatment.data.data.chart_id ?? 0
    );

    const plans = await asyncForEach(
      Object.values(treatmentPlansGroups),
      async (treatments) => {
        const planId = treatments[0].data.data.chart_id?.toString() ?? '';

        const planName = planId
          ? treatments[0].data.data.plan_name ?? ''
          : 'Misc Treatment Plan';

        const planCreatedAt =
          treatments[0].data.translations.planCreatedAt ??
          toTimestamp(
            toMomentTz(
              migration.configuration.backupDate,
              migration.configuration.timezone
            )
          );
        const planPractitioner =
          getPractitionerOrDefault(
            treatments[0].data.data.plan_provider_id ?? undefined,
            data.staff,
            data.practitioners
          ) ?? practitioner;

        const plan = {
          ...TreatmentPlan.init({
            name:
              planName.trim() ||
              `Treatment Plan - ${
                planCreatedAt
                  ? toMoment(planCreatedAt).format(HISTORY_DATE_FORMAT)
                  : planId
              }`,
            practitioner: planPractitioner
              ? stafferToNamedDoc(planPractitioner)
              : undefined,
            createdAt: planCreatedAt,
          }),
          sourceIdentifier: `${sourcePatientId}-plan-${planId}`,
        };

        const filteredTreatments = sourceTreatments.filter((treatment) =>
          data.practices.some(
            (practice) =>
              practice.sourceIdentifier === treatment.record.filters.practiceId
          )
        );

        const filteredChartsTreatments = treatments.filter((treatment) =>
          data.practices.some(
            (practice) =>
              practice.sourceIdentifier === treatment.record.filters.practiceId
          )
        );

        const allCompletedTreatments = filteredTreatments.filter(
          (treatment) => !!treatment.data.translations.date
        );

        const allPendingTreatments = filteredTreatments.filter(
          (treatment) =>
            !treatment.data.translations.date &&
            !!treatment.data.translations.planDate
        );

        const pendingSteps: IStepWithIdentifiers[] = [];
        const completedSteps: IStepWithIdentifiers[] = [];

        await resolveSequentially(
          filteredChartsTreatments,
          async (treatment) => {
            const chartedTreatments = await getChartedTreatments(
              [treatment.data.data],
              feeScheduleResolverFn(translationMap, defaultFeeSchedule),
              data,
              migration.configuration.timezone
            );

            if (!chartedTreatments.length) {
              return;
            }

            if (treatment.data.translations.date) {
              const date = toISODate(
                toMomentTz(
                  treatment.data.translations.date,
                  migration.configuration.timezone
                )
              );

              const sameDayTreatments = allCompletedTreatments.filter(
                (completedTreatment) => {
                  const completedDate =
                    completedTreatment.data.translations.date &&
                    toISODate(
                      toMomentTz(
                        completedTreatment.data.translations.date,
                        migration.configuration.timezone
                      )
                    );

                  return completedDate === date;
                }
              );

              const sourceIdentifier = await getStepIdentifier(
                migration,
                new PatientAppointmentSourceEntity(),
                sourcePatientId,
                migration.configuration.timezone,
                sameDayTreatments,
                treatment
              );

              if (!sourceIdentifier) {
                throw new Error(
                  `No source identifier found for treatment: ${treatment.record.uid}`
                );
              }

              const existingStep = completedSteps.find(
                (step) => step.sourceIdentifier === sourceIdentifier
              );

              if (existingStep) {
                existingStep.treatments.push(...chartedTreatments);
                return;
              }

              const stepDestinationRef =
                await translationMap.getDestination<ITreatmentStep>(
                  sourceIdentifier,
                  PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
                );

              const appointment = stepDestinationRef
                ? (await Firestore.getDoc(stepDestinationRef)).appointment
                : undefined;

              const step = TreatmentStep.init({
                name: `Completed ${toMomentTz(
                  date,
                  migration.configuration.timezone
                ).format(HISTORY_DATE_FORMAT)}`,
                treatments: chartedTreatments,
                status: TreatmentStepStatus.Complete,
                appointment,
              });
              const treatmentCategory =
                await CalculateTreatmentWeight.getPrimaryCategory(
                  step,
                  data.treatmentCategories
                );

              const createdAt = treatment.data.translations.date
                ? toTimestamp(
                    toMomentTz(
                      treatment.data.translations.date,
                      migration.configuration.timezone
                    )
                  )
                : toTimestamp();

              completedSteps.push({
                ...step,
                display: {
                  ...step.display,
                  primaryTreatmentCategory: treatmentCategory?.ref,
                },
                createdAt,
                date,
                sourceIdentifier,
              });
              return;
            }

            if (!treatment.data.translations.planDate) {
              return;
            }

            const date = toISODate(
              toMomentTz(
                treatment.data.translations.planDate,
                migration.configuration.timezone
              )
            );
            const stepNumber = treatment.data.data.plan_visit_no || 0;

            const sameDayTreatments = allPendingTreatments.filter(
              (pendingTreatment) => {
                const completedDate =
                  pendingTreatment.data.translations.planDate &&
                  toISODate(
                    toMomentTz(
                      pendingTreatment.data.translations.planDate,
                      migration.configuration.timezone
                    )
                  );

                return completedDate === date;
              }
            );

            const sourceIdentifier = await getStepIdentifier(
              migration,
              new PatientAppointmentSourceEntity(),
              sourcePatientId,
              migration.configuration.timezone,
              sameDayTreatments,
              treatment
            );

            if (!sourceIdentifier) {
              throw new Error(
                `No source identifier found for treatment: ${treatment.record.uid}`
              );
            }

            const existingStep = pendingSteps.find(
              (step) => step.sourceIdentifier === sourceIdentifier
            );

            if (existingStep) {
              existingStep.treatments.push(...chartedTreatments);
              return;
            }

            const step = TreatmentStep.init({
              name: `Pending Step ${
                stepNumber ||
                toMomentTz(
                  treatment.data.translations.planDate,
                  migration.configuration.timezone
                ).format(HISTORY_DATE_FORMAT)
              }`,
              treatments: chartedTreatments,
              status: TreatmentStepStatus.Incomplete,
            });

            const treatmentCategory =
              await CalculateTreatmentWeight.getPrimaryCategory(
                step,
                data.treatmentCategories
              );

            const createdAt = treatment.data.translations.date
              ? toTimestamp(
                  toMomentTz(
                    treatment.data.translations.date,
                    migration.configuration.timezone
                  )
                )
              : toTimestamp();

            pendingSteps.push({
              ...step,
              display: {
                ...step.display,
                primaryTreatmentCategory: treatmentCategory?.ref,
              },
              createdAt,
              date,
              sourceIdentifier,
            });
          }
        );

        const steps = [
          ...sortBy(completedSteps, (step) => step.date),
          ...sortBy(pendingSteps, (step) => step.name),
        ];

        if (!planCreatedAt) {
          const earliestStepDate = steps.sort(sortByCreatedAt).pop()?.createdAt;
          if (earliestStepDate) {
            plan.createdAt = earliestStepDate;
          }
        }

        const status = determinePlanStatus(steps, plan.createdAt);

        return {
          plan: {
            ...plan,
            status,
          },
          steps,
        };
      }
    );

    return compact(
      sortBy(plans, 'sourceIdentifier').reduce((mergedPlans, plan) => {
        const planMatchFn = (mergedPlan: ITreatmentPlanStepPair): boolean =>
          mergedPlan.plan.name === plan.plan.name;

        const existingPlan = mergedPlans.find(planMatchFn);
        if (!existingPlan) {
          return [...mergedPlans, plan];
        }

        const mergedSteps = sortBy(
          [...existingPlan.steps, ...plan.steps],
          'sourceIdentifier'
        ).reduce((steps, step) => {
          const stepMatchFn = (mergedStep: IStepWithIdentifiers): boolean =>
            mergedStep.name === step.name &&
            mergedStep.status === TreatmentStepStatus.Complete &&
            step.status === TreatmentStepStatus.Complete;

          const existingStep = steps.find(stepMatchFn);

          if (!existingStep) {
            return [...steps, step];
          }

          return steps.map((filterStep) => {
            if (!stepMatchFn(filterStep)) {
              return filterStep;
            }

            return {
              ...filterStep,
              treatments: [...filterStep.treatments, ...step.treatments],
            };
          });
        }, [] as IStepWithIdentifiers[]);

        const mergedPlanStatus = determinePlanStatus(
          mergedSteps,
          existingPlan.plan.createdAt
        );

        const mergedPlan = {
          ...existingPlan,
          steps: mergedSteps,
          plan: {
            ...existingPlan.plan,
            status: mergedPlanStatus,
          },
        };

        return [
          ...mergedPlans.filter((filterPlan) => !planMatchFn(filterPlan)),
          mergedPlan,
        ];
      }, [] as ITreatmentPlanStepPair[])
    );
  }
}

export function determinePlanStatus(
  steps: IStepWithIdentifiers[],
  planCreatedAt: Timestamp
): TreatmentPlanStatus {
  let preliminaryStatus: TreatmentPlanStatus | undefined;

  if (steps.length === 0) {
    preliminaryStatus = TreatmentPlanStatus.Draft;
  }

  const someComplete = steps.some(
    (step) => step.status === TreatmentStepStatus.Complete
  );

  if (someComplete) {
    preliminaryStatus = TreatmentPlanStatus.InProgress;
  }

  const allComplete = steps.every(
    (step) => step.status === TreatmentStepStatus.Complete
  );

  if (allComplete) {
    preliminaryStatus = TreatmentPlanStatus.Completed;
  }

  const allIncomplete = steps.every(
    (step) => step.status === TreatmentStepStatus.Incomplete
  );

  if (allIncomplete) {
    preliminaryStatus = TreatmentPlanStatus.Draft;
  }

  if (!preliminaryStatus) {
    preliminaryStatus = TreatmentPlanStatus.Draft;
  }

  const planAgeInMonths = moment().diff(toMoment(planCreatedAt), 'months');

  if (
    preliminaryStatus === TreatmentPlanStatus.Draft &&
    planAgeInMonths > 12 &&
    allIncomplete
  ) {
    return TreatmentPlanStatus.Declined;
  }

  return preliminaryStatus;
}

export async function getChartedTreatments(
  sourceTreatments: ID4WPatientTreatment[],
  getPreferredOrDefaultRef: (
    feeSheduleId?: string
  ) => Promise<INamedDocument<IFeeSchedule>>,
  data: JobData,
  timezone: Timezone
): Promise<IChartedTreatment[]> {
  const treatments: IChartedTreatment[] = [];

  await asyncForEach(sourceTreatments, async (sourceTreatment) => {
    const treatmentProviderId = sourceTreatment.provider_id.toString();
    const practitioner = getPractitionerOrDefault(
      treatmentProviderId,
      data.staff,
      data.practitioners
    );

    if (!practitioner) {
      throw new Error(
        `Couldn't resolve practitioner for treatment ${treatmentProviderId}`
      );
    }

    if (!sourceTreatment.item_code) {
      return;
    }
    const procedureCode = sourceTreatment.item_code;
    const code = resolveMappedCode(
      data.sourceItemCodes,
      sourceTreatment.item_id.toString(),
      procedureCode
    );
    if (!code) {
      return;
    }

    const itemCodeId = sourceTreatment.item_id.toString();
    const mappedTreatment = data.treatmentConfigurationMappings.find(
      (mapping) => mapping.sourceIdentifier === itemCodeId
    );
    const treatmentConfiguration = mappedTreatment?.destinationIdentifier
      ? await getDoc(mappedTreatment?.destinationIdentifier)
      : data.defaultTreatmentConfiguration;

    const sourceFeeScheduleId =
      sourceTreatment.payment_plans[0]?.fee_level_id?.toString();
    const feeSchedule = await getPreferredOrDefaultRef(sourceFeeScheduleId);
    const taxStatus = TaxStrategy.GSTFree;
    const price = roundTo2Decimals(parseFloat(sourceTreatment.fee));

    const toothNumber = sourceTreatment.tooth_ref
      ? String(sourceTreatment.tooth_ref).trim()
      : undefined;
    if (!isToothNumber(toothNumber) && toothNumber) {
      // eslint-disable-next-line no-console
      console.info(`${toothNumber} isn't a valid tooth number`);
    }

    const serviceCode = PricedServiceCodeEntry.init({
      code: code.code,
      type: code.type,
      taxStatus,
      price,
      quantity: sourceTreatment.times,
      tax: determineTaxFromStrategy(data.taxRate, {
        taxStatus: taxStatus,
        amount: price,
      }),
      chartedSurfaces: isToothNumber(toothNumber)
        ? getChartedRefs(
            toothNumber,
            sourceTreatment.tooth_surface,
            sourceTreatment.is_baby_tooth
          ).map((chartedRef) =>
            ChartedSurface.init({
              chartedBy: stafferToNamedDoc(practitioner),
              resolvedBy: getResolvedBy(sourceTreatment.date, practitioner),
              resolvedAt: getResolvedAt(sourceTreatment.date, timezone),
              chartedRef,
            })
          )
        : [],
    });

    const scopeResolver = new ChartedItemScopeResolver();
    const scopeRefs = scopeResolver.reduceChartedSurfacesToScope(
      treatmentConfiguration,
      serviceCode.chartedSurfaces
    );
    const scopeRef = first(scopeRefs)?.scopeRef ?? {
      scope: ChartableSurface.WholeMouth,
    };

    treatments.push(
      ChartedTreatment.init({
        uuid: sourceTreatment.id.toString(),
        config: toNamedDocument(treatmentConfiguration),
        feeSchedule,
        serviceCodes: [serviceCode],
        chartedSurfaces: serviceCode.chartedSurfaces,
        attributedTo: stafferToNamedDoc(practitioner),
        scopeRef,
        resolvedBy: getResolvedBy(sourceTreatment.date, practitioner),
        resolvedAt: getResolvedAt(sourceTreatment.date, timezone),
      })
    );
  });

  return treatments;
}

export function getChartedRefs(
  toothNumber: ToothNumber | null | undefined,
  surfaces: D4WToothSurface[] = [],
  isDeciduous: boolean = false
): Partial<IChartedRef>[] {
  const adjustedToothNumber =
    isDeciduous && toothNumber
      ? toothNumberToDeciduous(toothNumber)
      : toothNumber;
  const quadrant = adjustedToothNumber
    ? getQuadrant(adjustedToothNumber)
    : undefined;
  const quadrantIndex = adjustedToothNumber
    ? getQuadrantIndex(adjustedToothNumber)
    : undefined;
  if (!adjustedToothNumber || !quadrant || !quadrantIndex) {
    return [
      {
        unscoped: true,
      },
    ];
  }

  const toothRef: IToothRef = {
    quadrant,
    quadrantIndex,
  };

  if (!surfaces.length) {
    return [
      {
        tooth: toothRef,
      },
    ];
  }

  return surfaces.map((individualSurface) => {
    const surface = TOOTH_SURFACES_MAP[individualSurface];

    return {
      tooth: {
        quadrant,
        quadrantIndex,
        surface,
      },
    };
  });
}

export function getResolvedAt(
  completedDate: string | undefined | null,
  timezone: Timezone
): Timestamp | undefined {
  return completedDate
    ? toTimestamp(
        moment.tz(completedDate, ISO_DATE_FORMAT, timezone).startOf('day')
      )
    : undefined;
}

export function getResolvedBy(
  completedDate: string | undefined | null,
  practitioner: INamedDocument<IStaffer>
): INamedDocument<IStaffer> | undefined {
  return completedDate ? stafferToNamedDoc(practitioner) : undefined;
}

export async function getStepIdentifier(
  migration: WithRef<IPracticeMigration>,
  appointmentResolver: PatientAppointmentSourceEntity,
  sourcePatientId: string,
  timezone: Timezone,
  allPatientTreatments: IGetRecordResponse<
    ID4WPatientTreatment,
    ID4WPatientTreatmentTranslations,
    ID4WPatientTreatmentFilters
  >[],
  treatment?: IGetRecordResponse<
    ID4WPatientTreatment,
    ID4WPatientTreatmentTranslations,
    ID4WPatientTreatmentFilters
  >,
  appointment?: IGetRecordResponse<
    ID4WAppointment,
    ID4WAppointmentTranslations
  >,
  patientAppointments?: IGetRecordResponse<
    ID4WAppointment,
    ID4WAppointmentTranslations
  >[]
): Promise<string | undefined> {
  const chartGroupedTreatments = groupBy(
    sortBy(
      allPatientTreatments,
      (allDateTreatment) => allDateTreatment.record.uid
    ),
    (allDateTreatment) => allDateTreatment.data.data.chart_id
  );

  if (treatment) {
    const treatmentGroupIndex = Object.values(chartGroupedTreatments).findIndex(
      (groupedTreatmentList) =>
        groupedTreatmentList.some(
          (groupedTreatment) =>
            groupedTreatment.record.uid === treatment.record.uid
        )
    );

    if (treatmentGroupIndex === -1) {
      throw new Error('No treatment found in grouped treatments');
    }

    const completedDate = treatment?.data.translations.date
      ? toISODate(toMomentTz(treatment.data.translations.date, timezone))
      : undefined;

    if (completedDate) {
      return `${sourcePatientId}-${completedDate}-completed-${treatmentGroupIndex}`;
    }

    if (!treatment.data.translations.planDate) {
      throw new Error('No plan date provided');
    }

    const planDate = toISODate(
      toMomentTz(treatment.data.translations.planDate, timezone)
    );
    const stepNumber = treatment.data.data.plan_visit_no || 0;
    return `${sourcePatientId}-${planDate}-pending-${treatmentGroupIndex}-${stepNumber}`;
  }

  if (!appointment) {
    throw new Error('No appointment or treatment provided');
  }

  const appointmentDate = toISODate(
    appointment.data.translations.from,
    timezone
  );

  const sameDayAppointments = (
    patientAppointments ??
    (await appointmentResolver.filterRecords(
      migration,
      'patientId',
      sourcePatientId
    ))
  ).filter(
    (filteredAppointment) =>
      toISODate(filteredAppointment.data.translations.from, timezone) ===
      appointmentDate
  );

  const sortedAppointments = [...sameDayAppointments].sort(
    (appointmentA, appointmentB) =>
      sortTimestampAsc(
        appointmentA.data.translations.from,
        appointmentB.data.translations.from
      )
  );

  const appointmentIndex = sortedAppointments.findIndex(
    (sortedAppointment) =>
      sortedAppointment.record.uid === appointment.record.uid
  );

  const isFutureAppointment = toMoment(appointmentDate).isAfter(
    toMomentTz(migration.configuration.backupDate, timezone)
  );
  const idSuffix = isFutureAppointment ? 'pending' : 'completed';

  return `${sourcePatientId}-${appointmentDate}-${idSuffix}-${appointmentIndex}`;
}
