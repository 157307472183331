import { ActivatedRouteSnapshot } from '@angular/router';
import { type ITemplateDefinition } from '@principle-theorem/principle-core/interfaces';
import { type CollectionReference } from '@principle-theorem/shared';
import { type ResolverResult } from '@principle-theorem/shared';

export abstract class TemplateCollectionResolver {
  static readonly resolverKey: string = 'templateCollection';

  abstract resolve(
    route: ActivatedRouteSnapshot
  ): ResolverResult<CollectionReference<ITemplateDefinition> | undefined>;
}
