<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="wrapper flex flex-col gap-4">
  <ng-container *ngIf="conditionConfig$ | async as conditionConfig">
    <h1 class="mat-headline-4">{{ conditionConfig.name }}</h1>
    <pr-edit-condition-configuration [conditionType]="conditionConfig" />
  </ng-container>
</div>
