import { MeasureFormatter } from '@principle-theorem/principle-core/interfaces';
import { IAccountCreditEventFact } from '@principle-theorem/reporting/interfaces';
import { BigQueryTable } from '../../big-query-tables';
import {
  BrandQueryScopeConfig,
  DateRangeQueryScopeConfig,
  PracticeQueryScopeConfig,
} from '../../query-scope-config/query-scope-configs';
import { GroupBy } from '../../querying';
import { BaseFactMeasures, latestEventId } from '../base-measures';
import { MeasureLinkFactory, MeasurePath } from '../data-accessor-factory';
import { AccountCreditDimensionMeasures } from '../dimensions/account-credit-dimension';
import { AppointmentDimensionMeasureFactory } from '../dimensions/appointment-dimension';
import { BrandDimensionMeasures } from '../dimensions/brand-dimension';
import { InvoiceDimensionMeasures } from '../dimensions/invoice-dimension';
import { PatientDimensionMeasures } from '../dimensions/patient-dimension';
import { PracticeDimensionMeasures } from '../dimensions/practice-dimension';
import { ReferrerDimensionMeasures } from '../dimensions/referrer-dimension';
import {
  CanBeChartedProperty,
  CanDoAllProperty,
  ComparableProperties,
  MeasureTransformMap,
} from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { QueryFactory } from '../query-factory';

export class AccountCreditEventFactMeasures
  extends BaseFactMeasures
  implements MeasureTransformMap<ComparableProperties<IAccountCreditEventFact>>
{
  id = 'accountCreditEvent';
  readonly table = BigQueryTable.AccountCreditEvent;
  readonly name = 'Account Credits';
  scopes = [
    new BrandQueryScopeConfig(),
    new PracticeQueryScopeConfig(),
    new DateRangeQueryScopeConfig(),
  ];

  get brand(): BrandDimensionMeasures {
    return new BrandDimensionMeasures(BigQueryTable.Brands, 'brand', {
      sourceJoinKey: MeasurePath.docRef('brand.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get practice(): PracticeDimensionMeasures {
    return new PracticeDimensionMeasures(BigQueryTable.Practices, 'practice', {
      sourceJoinKey: MeasurePath.docRef('practice.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get patient(): PatientDimensionMeasures {
    return new PatientDimensionMeasures(BigQueryTable.Patients, 'patient', {
      sourceJoinKey: MeasurePath.docRef('patient.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get referrer(): ReferrerDimensionMeasures {
    return new ReferrerDimensionMeasures(this.table, 'referrer');
  }

  get invoice(): InvoiceDimensionMeasures {
    return new InvoiceDimensionMeasures(BigQueryTable.Invoices, 'invoice', {
      sourceJoinKey: MeasurePath.docRef('invoice.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get accountCredit(): AccountCreditDimensionMeasures {
    return new AccountCreditDimensionMeasures(
      BigQueryTable.AccountCredits,
      'accountCredit',
      {
        sourceJoinKey: MeasurePath.docRef('accountCredit.ref'),
        orderByProperty: MeasurePath.timestamp('updatedAt'),
      }
    );
  }

  get latestEvent(): AccountCreditEventFactMeasures {
    const measures = new AccountCreditEventFactMeasures();
    measures.id = latestEventId(measures);
    measures._query = QueryFactory.fromTable(measures.table)
      .override(measures._query)
      .latestEvent(measures.table, GroupBy.AccountCredit)
      .filterSoftDeleted(measures.accountCredit.measureRef('deleted'))
      .get();
    return measures;
  }

  get remaining(): CanDoAllProperty {
    const propertyName = 'remaining';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: `accountCredit.${propertyName}`,
        label: 'Account Credit Remaining Amount',
        summary:
          'The amount of account credit remaining for the given account credit',
        formatter: MeasureFormatter.Currency,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get hasRemaining(): CanDoAllProperty {
    const measure = this.measureRef('remaining');
    return MeasurePropertyFactory.boolean(
      {
        id: 'accountCredit.hasRemaining',
        label: 'Account Credit Has Remaining',
        summary: 'Whether the account credit has any remaining balance or not',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get accountCreditLink(): CanBeChartedProperty {
    const brand = this.brand.measureRef('slug');
    const patient = this.patient.measureRef(MeasurePath.docRef('ref'));
    const accountCredit = this.accountCredit.measureRef(
      MeasurePath.docRef('ref')
    );
    return MeasurePropertyFactory.link(
      {
        id: 'accountCredit.link',
        label: 'Account Credit Link',
        summary: 'Principle link for the given account credit',
      },
      accountCredit,
      this.buildQuery()
        .mergeJoins(this.brand.query.joins)
        .mergeJoins(this.patient.query.joins)
        .attributes([
          brand.attributePath,
          patient.attributePath,
          accountCredit.attributePath,
        ])
        .get(),
      brand,
      MeasureLinkFactory.accountCredit
    );
  }

  get invoiceLink(): CanBeChartedProperty {
    const brand = this.brand.measureRef('slug');
    const patient = this.patient.measureRef(MeasurePath.docRef('ref'));
    const invoice = this.invoice.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.link(
      {
        id: 'invoice.link',
        label: 'Invoice Link',
        summary: 'Principle link for the given invoice',
      },
      invoice,
      this.buildQuery()
        .mergeJoins(this.brand.query.joins)
        .mergeJoins(this.patient.query.joins)
        .mergeJoins(this.invoice.query.joins)
        .attributes([
          brand.attributePath,
          patient.attributePath,
          invoice.attributePath,
        ])
        .get(),
      brand,
      MeasureLinkFactory.invoice
    );
  }

  get patientLink(): CanBeChartedProperty {
    const brand = this.brand.measureRef('slug');
    const patient = this.patient.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.link(
      {
        id: 'patient.link',
        label: 'Patient Link',
        summary: 'Principle link for the given patient',
      },
      patient,
      this.buildQuery()
        .mergeJoins(this.brand.query.joins)
        .mergeJoins(this.patient.query.joins)
        .attributes([brand.attributePath, patient.attributePath])
        .get(),
      brand,
      MeasureLinkFactory.patient
    );
  }

  get treatmentCategory(): CanDoAllProperty {
    const treatmentCategory = 'treatmentCategory';
    const labelMeasure = this.measureRef(`${treatmentCategory}.name`);
    const colourMeasure = this.measureRef(`${treatmentCategory}.colour.value`);
    const dataMeasure = this.measureRef(
      MeasurePath.docRef(`${treatmentCategory}.ref`)
    );

    return AppointmentDimensionMeasureFactory.treatmentCategory(
      {
        id: this._pathWithPrefix('treatmentCategory'),
        label: 'Reserved For Treatment Category',
        summary: 'The treatment category the account credit is reserved for',
      },
      labelMeasure,
      colourMeasure,
      dataMeasure,
      this.buildQuery()
        .attributes([
          labelMeasure.attributePath,
          colourMeasure.attributePath,
          dataMeasure.attributePath,
        ])
        .get()
    );
  }
}
