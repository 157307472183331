import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  type OnDestroy,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GlobalStoreService,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  BasicDialogService,
  DialogPresets,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  ChatType,
  MentionResourceType,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  isPathChanged$,
  isSameRef,
  patchDoc,
  shareReplayCold,
  snapshot,
  type DocumentReference,
  type WithRef,
  safeCombineLatest,
  snapshotDefined,
  Firestore,
} from '@principle-theorem/shared';
import {
  ReplaySubject,
  Subject,
  combineLatest,
  type Observable,
  of,
} from 'rxjs';
import {
  debounceTime,
  map,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { type ChatActions } from '../../chat-actions';
import {
  ChatsDashboardStore,
  type IChatSummary,
} from '../../pages/chats-dashboard/chats-dashboard.store';
import { EditChatMembersDialogComponent } from '../edit-chat-members-dialog/edit-chat-members-dialog.component';
import { toMentionContent, toTextContent } from '@principle-theorem/editor';
import { toMention } from '@principle-theorem/principle-core';

@Component({
    selector: 'pr-chat-header',
    templateUrl: './chat-header.component.html',
    styleUrls: ['./chat-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChatHeaderComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  chatNameCtrl = new TypedFormControl<string | undefined>();
  participants$: Observable<DocumentReference<IStaffer>[]>;
  staff$: Observable<WithRef<IStaffer>[]>;
  staffer$: Observable<WithRef<IStaffer>>;
  isGroupChat$: Observable<boolean>;
  chatLabel$: Observable<string>;
  @Input() actions: ChatActions;
  @Input() compact: boolean = false;
  @Output() closeChat = new EventEmitter<void>();
  summary$ = new ReplaySubject<IChatSummary>(1);

  @Input()
  set summary(summary: IChatSummary) {
    if (summary) {
      this.summary$.next(summary);
    }
  }

  constructor(
    private _organisation: OrganisationService,
    private _chatFacade: ChatsDashboardStore,
    private _router: Router,
    private _route: ActivatedRoute,
    private _globalStore: GlobalStoreService,
    private _dialog: BasicDialogService
  ) {
    this.staff$ = this._organisation.staff$;
    this.staffer$ = this._organisation.staffer$.pipe(filterUndefined());

    this.participants$ = combineLatest([
      this.staffer$,
      this.summary$.pipe(map((summary) => summary.chat.participants)),
    ]).pipe(
      map(([staffer, participants]) =>
        participants.filter((participant) => !isSameRef(participant, staffer))
      ),
      shareReplayCold()
    );

    this.summary$
      .pipe(isPathChanged$('chat.label'), takeUntil(this._onDestroy$))
      .subscribe((summary) => {
        this.chatNameCtrl.patchValue(summary.chat.label, {
          emitEvent: false,
        });
      });

    this.chatNameCtrl.valueChanges
      .pipe(
        debounceTime(500),
        filterUndefined(),
        withLatestFrom(this.summary$),
        takeUntil(this._onDestroy$)
      )
      .subscribe(
        ([label, summary]) => void patchDoc(summary.chat.ref, { label })
      );

    this.isGroupChat$ = this.summary$.pipe(
      map((summary) => summary.chat.type === ChatType.Group)
    );

    this.chatLabel$ = combineLatest([this.summary$, this.participants$]).pipe(
      switchMap(([summary, participants]) => {
        if (summary.chat.label) {
          return of(summary.chat.label);
        }

        if (!participants.length) {
          return of('Empty Group');
        }

        return safeCombineLatest(
          participants.map((participantRef) =>
            this.stafferName$(participantRef)
          )
        ).pipe(map((participantNames) => participantNames.join(', ')));
      })
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async archiveChat(): Promise<void> {
    const confirmed = await this._dialog.confirm({
      title: 'Archive Chat',
      prompt:
        'Are you sure you want to archive this chat? This will no longer be available to any members.',
      submitLabel: 'Yes, Archive',
      submitColor: 'warn',
      cancelLabel: 'Cancel',
    });

    if (!confirmed) {
      return;
    }

    await this.actions.archiveChat();
    this.closeChat.emit();

    if (this.compact) {
      return;
    }

    await this.goToNextChat();
  }

  async manageMembers(): Promise<void> {
    const summary = await snapshot(this.summary$);

    this._dialog.open<EditChatMembersDialogComponent>(
      EditChatMembersDialogComponent,
      DialogPresets.medium({ data: { chat: summary.chat } })
    );
  }

  async renameChat(): Promise<void> {
    const summary = await snapshot(this.summary$);
    const label = await this._dialog.prompt({
      title: 'Change Group Name',
      prompt: 'Enter a name for the group',
      defaultValue: summary.chat.label,
      submitLabel: 'Save',
    });

    if (!label) {
      return;
    }

    await Firestore.patchDoc(summary.chat.ref, { label });

    const staffer = await snapshotDefined(this._organisation.staffer$);
    await this._chatFacade.addInteraction(summary.chat.ref, [
      toMentionContent(toMention(staffer, MentionResourceType.Staffer)),
      toTextContent(` changed the chat name to "${label}"`),
    ]);
  }

  async goToNextChat(): Promise<boolean> {
    const chats = await snapshot(this._chatFacade.chats$);
    if (chats.length < 2) {
      return false;
    }

    const summary = await snapshot(this.summary$);
    const currentIndex = chats.findIndex((chat) =>
      isSameRef(chat.chat, summary.chat)
    );
    const nextIndex =
      currentIndex === chats.length - 1 ? currentIndex - 1 : currentIndex + 1;

    return this._router.navigate([`../${chats[nextIndex].chat.ref.id}`], {
      relativeTo: this._route,
    });
  }

  stafferImage$(
    stafferRef: DocumentReference<IStaffer>
  ): Observable<string | undefined> {
    return this._globalStore.getStafferImage$({ ref: stafferRef });
  }

  stafferName$(
    stafferRef: DocumentReference<IStaffer>
  ): Observable<string | undefined> {
    return this._globalStore.getStafferName$({ ref: stafferRef });
  }
}
