import {
  FeatureVisibility,
  FEATURE_DEFAULTS,
  IFeature,
} from '@principle-theorem/feature-flags';

export enum OrganisationPermissions {
  OrganisationConfigure = 'organisation.configure',
  OrganisationConfigureIntegrations = 'organisation.configure.integrations',
  OrganisationConfigureAuth = 'organisation.configure.auth',
  OrganisationConfigureFeeSchedules = 'organisation.configure.fee-schedules',
}

export const ORGANISATION_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  label: 'workspace',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: OrganisationPermissions.OrganisationConfigure,
      description: `User can modify ALL settings for the workspace`,
    },
    {
      value: OrganisationPermissions.OrganisationConfigureIntegrations,
      description: `User can modify settings for the workspace's external integrations`,
    },
    {
      value: OrganisationPermissions.OrganisationConfigureFeeSchedules,
      description: `User can view and edit the workspace's fee schedules`,
    },
    {
      value: OrganisationPermissions.OrganisationConfigureAuth,
      description: `User can manage users and roles for the workspace`,
    },
  ],
};
