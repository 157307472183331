<mat-toolbar color="accent">
  <div class="flex grow items-center justify-between">
    <span>Device Logs</span>
    <button mat-flat-button color="primary" (click)="getLogs(data.device)">
      <span>Get New Device Logs</span>
    </button>
  </div>
</mat-toolbar>

<mat-dialog-content>
  <pt-attachments [editable]="false" [attachments]="logs$ | async" />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button type="button" mat-stroked-button mat-dialog-close>Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
