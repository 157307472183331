<form [formGroup]="formGroup" class="flex grow flex-col gap-4">
  @for (field of fields(); track field.key) {
    <mat-form-field
      subscriptSizing="dynamic"
      [floatLabel]="(field | map: floatLabel : this) ? 'always' : 'auto'"
    >
      <mat-label>{{ field.data.label }}</mat-label>

      @if (field | typeGuard: isSelectField; as selectField) {
        <mat-select
          [formControl]="field.ctrl"
          [compareWith]="selectField.data.compareWith"
          [multiple]="field.data.isMultiple"
          [placeholder]="field | map: placeholder : this"
        >
          @for (option of selectField.data.options; track option.label) {
            <mat-option [value]="option.value">
              {{ option.label | splitCamel | titlecase }}
            </mat-option>
          }
        </mat-select>
      }

      @if (field | typeGuard: isNumberField; as numberField) {
        <input type="number" matInput [formControl]="field.ctrl" />
      }

      @if (field | map: showClearButton : this) {
        <button
          mat-icon-button
          matSuffix
          class="opacity-50"
          [disabled]="field.ctrl.value | map: isEmpty : this"
          (click)="clearField($event, field)"
        >
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>
  }
</form>
