<div
  class="flex flex-col border-b border-solid border-slate-300 pb-1"
  *ngIf="treatment$ | async as treatment"
>
  <div class="flex items-center gap-1 p-1" *ngIf="config$ | async as config">
    <mat-icon
      *ngIf="config.display?.icon.slug"
      [ngStyle]="{ color: config.display.colour.value }"
      [svgIcon]="config.display.icon.slug"
    />

    <div [matTooltip]="config.name" [matTooltipShowDelay]="300">
      {{ config.name }}
      @if (treatment.chartedSurfaces.length) {
        -
        <pr-charted-items-surface-list
          [chartedSurfaces]="treatment.chartedSurfaces"
          [availableSurfaces]="config.availableSurfaces"
          [disabled]="true"
        />
      }
    </div>

    <pr-treatment-category-indicator
      *ngIf="treatmentCategory$ | async as category"
      [category]="category"
      displayOnly
    />
  </div>

  <div class="ml-8 flex gap-2" *ngIf="showBasePrice$ | async">
    <div class="flex flex-auto">Base Price</div>
    <div class="w-16 text-right">{{ treatment.basePrice | currency }}</div>
  </div>

  <div class="flex flex-col gap-0.5">
    @for (
      serviceCode of serviceCodes$ | async;
      track trackByServiceCode($index, serviceCode)
    ) {
      <ng-container
        *ngTemplateOutlet="serviceCodeTemplate; context: { serviceCode }"
      />
    }
  </div>
</div>

<ng-template #serviceCodeTemplate let-serviceCode="serviceCode">
  <div class="ml-8 flex text-xs">
    <div
      class="min-w-0 grow truncate"
      *ngrxLet="serviceCode | map: getCodeName$ : this as codeName"
      [ptTruncateTooltip]="codeName"
    >
      {{ codeName }}
    </div>
    <div class="ml-2 shrink-0">x{{ serviceCode.quantity }}</div>
    <div class="w-14 shrink-0 text-right">
      <ng-container *ngIf="serviceCode.priceOverride; else defaultPrice">
        {{ serviceCode.priceOverride | currency }}
      </ng-container>
      <ng-template #defaultPrice>
        {{ serviceCode.price | currency }}
      </ng-template>
    </div>
  </div>
</ng-template>
