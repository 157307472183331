import { Injectable } from '@angular/core';
import { type Data, Router } from '@angular/router';
import { type WithRef } from '@principle-theorem/shared';
import { type IPractice } from '@principle-theorem/principle-core/interfaces';
import { BaseCurrentFirestoreModel } from './base-current-firestore-model';

@Injectable({
  providedIn: 'root',
  useFactory: (route: Router) => new CurrentPracticeScope(route),
  deps: [Router],
})
export class CurrentPracticeScope extends BaseCurrentFirestoreModel<
  WithRef<IPractice>
> {
  protected _propertyAccessor(data: Data): WithRef<IPractice> | undefined {
    if (data.practice) {
      return data.practice as WithRef<IPractice>;
    }
  }
}
