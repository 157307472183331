import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  AutomatedFormIssue,
  AutomatedNotification,
  AutomationConfiguration,
  AutomationDisplay,
  GeneratedTask,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  AnyAutomation,
  AnyAutomationConfiguration,
  AutomationCreator,
  IAutomatedFormIssue,
  IAutomatedFormIssueConfiguration,
  IAutomatedNotificationConfiguration,
  IBrand,
  IGeneratedTaskConfiguration,
  IStaffer,
  isAutomatedFormIssueConfiguration,
  isAutomatedNotificationConfiguration,
  isGeneratedTaskConfiguration,
  type IAutomatedNotification,
  type IAutomation,
  type IAutomationConfiguration,
  type IEvent,
  type IGeneratedTask,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  filterUndefined,
  isWithRef,
  snapshot,
  snapshotDefined,
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { from, type Observable } from 'rxjs';
import { concatMap, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  AutomatedFormIssueDialogComponent,
  IAutomatedFormIssueDialogData,
  IAutomatedFormIssueReturnData,
} from '../components/automated-form-issue-dialog/automated-form-issue-dialog.component';
import {
  GeneratedTaskDialogComponent,
  type IGeneratedTaskDialogData,
  type IGeneratedTaskReturnData,
} from '../components/generated-task-dialog/generated-task-dialog.component';
import {
  NotificationDialogComponent,
  type IAutomatedNotificationDialogData,
  type IAutomatedNotificationReturnData,
} from '../components/notification-dialog/notification-dialog.component';
import {
  ISelectAutomationTemplateResponse,
  SelectAutomationConfigurationDialogComponent,
  type ISelectAutomationTemplateRequest,
} from '../components/select-automation-configuration-dialog/select-automation-configuration-dialog.component';

@Injectable()
export class AutomationDialogService {
  constructor(
    private _dialog: MatDialog,
    private _organisation: OrganisationService
  ) {}

  async selectConfiguration(
    request: ISelectAutomationTemplateRequest
  ): Promise<ISelectAutomationTemplateResponse | undefined> {
    return this._dialog
      .open<
        SelectAutomationConfigurationDialogComponent,
        ISelectAutomationTemplateRequest,
        ISelectAutomationTemplateResponse
      >(
        SelectAutomationConfigurationDialogComponent,
        DialogPresets.large({ height: '80%', data: request })
      )
      .afterClosed()
      .toPromise();
  }

  async openTaskDialog(
    data: IGeneratedTaskDialogData
  ): Promise<IGeneratedTaskReturnData | undefined> {
    return this._dialog
      .open<
        GeneratedTaskDialogComponent,
        IGeneratedTaskDialogData,
        IGeneratedTaskReturnData
      >(
        GeneratedTaskDialogComponent,
        DialogPresets.large({
          autoFocus: true,
          height: '80%',
          data,
        })
      )
      .afterClosed()
      .toPromise();
  }

  async openAutomatedFormIssueDialog(
    data: IAutomatedFormIssueDialogData
  ): Promise<IAutomatedFormIssueReturnData | undefined> {
    return this._dialog
      .open<
        AutomatedFormIssueDialogComponent,
        IAutomatedFormIssueDialogData,
        IAutomatedFormIssueReturnData
      >(
        AutomatedFormIssueDialogComponent,
        DialogPresets.large({
          autoFocus: true,
          height: '80%',
          data,
        })
      )
      .afterClosed()
      .toPromise();
  }

  async openTaskAdd(
    event: IEvent,
    triggerAfterDate?: Timestamp
  ): Promise<IAutomation<IGeneratedTask> | undefined> {
    const generatedTask = await this.openTaskDialog({
      triggerDate: event.from,
      useRelativeTime: true,
    });
    return this._toTaskAutomation(event, triggerAfterDate, generatedTask);
  }

  async openTaskEdit(
    task: IGeneratedTask,
    event: IEvent,
    triggerDate?: Timestamp,
    triggerAfterDate?: Timestamp,
    useRelativeTime: boolean = true
  ): Promise<IAutomation<IGeneratedTask> | undefined> {
    const generatedTask = await this.openTaskDialog({
      task,
      triggerDate,
      useRelativeTime,
    });
    return this._toTaskAutomation(event, triggerAfterDate, generatedTask);
  }

  async openNotificationDialog(
    data: IAutomatedNotificationDialogData,
    event: IEvent,
    triggerAfterDate?: Timestamp
  ): Promise<IAutomation<IAutomatedNotification> | undefined> {
    const automatedNotification = await this._dialog
      .open<
        NotificationDialogComponent,
        IAutomatedNotificationDialogData,
        IAutomatedNotificationReturnData
      >(
        NotificationDialogComponent,
        DialogPresets.large({ height: '80%', data })
      )
      .afterClosed()
      .toPromise();
    return this._toNotificationAutomation(
      event,
      triggerAfterDate,
      automatedNotification
    );
  }

  async openAutomatedFormIssueAdd(
    event: IEvent,
    triggerAfterDate?: Timestamp
  ): Promise<IAutomation<IAutomatedFormIssue> | undefined> {
    const formIssue = await this.openAutomatedFormIssueDialog({
      triggerDate: event.from,
      useRelativeTime: true,
    });
    return this._toAutomatedFormIssueAutomation(
      event,
      triggerAfterDate,
      formIssue
    );
  }

  async openAutomatedFormIssueEdit(
    formIssue: IAutomatedFormIssue,
    event: IEvent,
    triggerDate?: Timestamp,
    triggerAfterDate?: Timestamp,
    useRelativeTime: boolean = true
  ): Promise<IAutomation<IAutomatedFormIssue> | undefined> {
    const automatedFormIssue = await this.openAutomatedFormIssueDialog({
      formIssue,
      triggerDate,
      useRelativeTime,
    });
    return this._toAutomatedFormIssueAutomation(
      event,
      triggerAfterDate,
      automatedFormIssue
    );
  }

  openFromTemplateDialog(
    practiceRef: DocumentReference<IPractice>,
    event: IEvent,
    triggerAfterDate?: Timestamp
  ): Observable<IAutomation<AnyAutomation> | undefined> {
    return from(this._getAutomationConfigs(practiceRef)).pipe(
      switchMap((request) => this.selectConfiguration(request)),
      withLatestFrom(this._organisation.staffer$.pipe(filterUndefined())),
      concatMap(async ([response, staffer]) => {
        if (!response) {
          return;
        }
        const brand = await snapshot(
          this._organisation.brand$.pipe(filterUndefined())
        );
        return this._createFromConfig(
          response.config,
          event,
          staffer,
          brand.ref,
          triggerAfterDate
        );
      })
    );
  }

  private async _toNotificationAutomation(
    event: IEvent,
    triggerAfterDate?: Timestamp,
    automatedNotification?: IAutomatedNotificationReturnData
  ): Promise<IAutomation<IAutomatedNotification> | undefined> {
    if (!automatedNotification) {
      return;
    }
    const staffer = await snapshotDefined(this._organisation.staffer$);
    const brand = await snapshotDefined(this._organisation.brand$);
    const automation = await AutomatedNotification.toAutomation(
      automatedNotification,
      {
        ...stafferToNamedDoc(staffer),
        type: AutomationCreator.Staffer,
      },
      event,
      brand.ref,
      automatedNotification.configRef
    );
    if (automatedNotification.triggerDate) {
      automation.triggerDate = automatedNotification.triggerDate;
    }

    automation.triggerAfterDate = triggerAfterDate;
    return automation;
  }

  private async _toTaskAutomation(
    event: IEvent,
    triggerAfterDate?: Timestamp,
    generatedTask?: IGeneratedTaskReturnData
  ): Promise<IAutomation<IGeneratedTask> | undefined> {
    if (!generatedTask) {
      return;
    }
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const brand = await snapshot(
      this._organisation.brand$.pipe(filterUndefined())
    );
    const automation = await GeneratedTask.toAutomation(
      generatedTask,
      {
        ...stafferToNamedDoc(staffer),
        type: AutomationCreator.Staffer,
      },
      event,
      brand.ref
    );
    if (generatedTask.triggerDate) {
      automation.triggerDate = generatedTask.triggerDate;
    }
    automation.triggerAfterDate = triggerAfterDate;
    return automation;
  }

  private async _toAutomatedFormIssueAutomation(
    event: IEvent,
    triggerAfterDate?: Timestamp,
    automatedFormIssue?: IAutomatedFormIssueReturnData
  ): Promise<IAutomation<IAutomatedFormIssue> | undefined> {
    if (!automatedFormIssue) {
      return;
    }
    const staffer = await snapshot(
      this._organisation.staffer$.pipe(filterUndefined())
    );
    const brand = await snapshot(
      this._organisation.brand$.pipe(filterUndefined())
    );
    const automation = await AutomatedFormIssue.toAutomation(
      automatedFormIssue,
      {
        ...stafferToNamedDoc(staffer),
        type: AutomationCreator.Staffer,
      },
      event,
      brand.ref,
      automatedFormIssue.configRef
    );
    if (automatedFormIssue.triggerDate) {
      automation.triggerDate = automatedFormIssue.triggerDate;
    }

    automation.triggerAfterDate = triggerAfterDate;
    return automation;
  }

  private async _getAutomationConfigs(
    practiceRef: DocumentReference<IPractice>
  ): Promise<ISelectAutomationTemplateRequest> {
    const brand = await snapshot(this._organisation.brand$);
    if (!brand) {
      return { configs: [] };
    }

    const allConfigs = await snapshot(AutomationConfiguration.all$(brand));
    const availableConfigs = allConfigs.filter((config) =>
      automationConfigIsAvailable(config, practiceRef)
    );
    const configs = sortBy(availableConfigs, (config) =>
      AutomationDisplay.getLabel(config)
    );

    return { configs };
  }

  private async _createFromConfig(
    config: WithRef<AnyAutomationConfiguration>,
    event: IEvent,
    staffer: WithRef<IStaffer>,
    brandRef: DocumentReference<IBrand>,
    triggerAfterDate?: Timestamp
  ): Promise<IAutomation<AnyAutomation> | undefined> {
    const creator = {
      ...stafferToNamedDoc(staffer),
      type: AutomationCreator.Staffer,
    };

    if (
      isAutomatedNotificationConfiguration(config) &&
      isWithRef<IAutomatedNotificationConfiguration>(config)
    ) {
      return AutomatedNotification.generateFromConfig(
        config,
        creator,
        event,
        brandRef,
        triggerAfterDate
      );
    }

    if (
      isGeneratedTaskConfiguration(config) &&
      isWithRef<IGeneratedTaskConfiguration>(config)
    ) {
      return GeneratedTask.generateFromConfig(
        config,
        creator,
        event,
        brandRef,
        triggerAfterDate
      );
    }

    if (
      isAutomatedFormIssueConfiguration(config) &&
      isWithRef<IAutomatedFormIssueConfiguration>(config)
    ) {
      return AutomatedFormIssue.generateFromConfig(
        config,
        creator,
        event,
        brandRef,
        triggerAfterDate
      );
    }
  }
}

function automationConfigIsAvailable(
  config: IAutomationConfiguration,
  practiceRef: DocumentReference<IPractice>
): boolean {
  return (
    config.isActive &&
    AutomationConfiguration.isGlobal(config) &&
    AutomationConfiguration.isForPractice(config, practiceRef)
  );
}
