import {
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import { type MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { type ITreatmentConfiguration } from '@principle-theorem/principle-core/interfaces';
import { all$, type WithRef } from '@principle-theorem/shared';
import { CurrentPracticeScope } from '@principle-theorem/ng-principle-shared';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  type IAsset,
  type IConsumableAsset,
  type IEquipmentAsset,
  type IInstrumentAsset,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  AssetGroup,
  AssetRequirements,
  Practice,
} from '@principle-theorem/principle-core';
import { BehaviorSubject, combineLatest, type Observable, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'pr-treatment-assets',
  templateUrl: './treatment-assets.component.html',
  styleUrls: ['./treatment-assets.component.sass'],
})
export class TreatmentAssetsComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByGroup = TrackByFunctions.label<AssetGroup>();
  trackByAsset = TrackByFunctions.ref<WithRef<IAsset>>();
  @Input() treatmentConfig: WithRef<ITreatmentConfiguration>;
  @Output() save: EventEmitter<void> = new EventEmitter<void>();
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  filteredGroups$: Observable<AssetGroup[]>;
  assets: WithRef<IAsset>[] = [];
  searchCtrl: TypedFormControl<string> = new TypedFormControl<string>('');

  constructor(private _practiceScope: CurrentPracticeScope) {
    this._practiceScope
      .asObservable()
      .pipe(
        tap(() => this.loading$.next(true)),
        switchMap((practice) => this._getAssetGroups$(practice)),
        tap((groups: AssetGroup[]) => {
          this._updateAssets(groups);
          this._updateFilteredObservable(groups);
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => {
        this.loading$.next(false);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getSearchName(value: string | WithRef<IAsset>): string {
    return typeof value === 'string' ? value : value.name;
  }

  handleSave(): void {
    this.save.emit();
  }

  handleSelected($event: MatAutocompleteSelectedEvent): void {
    this._add($event.option.value as WithRef<IAsset>);
    this.searchCtrl.setValue('');
  }

  get hasAssets(): boolean {
    return AssetRequirements.count(this.treatmentConfig.assetRequirements)
      ? true
      : false;
  }

  private _add(asset: WithRef<IAsset>): void {
    AssetRequirements.addRequirement(
      this.treatmentConfig.assetRequirements,
      asset
    );
    this.save.emit();
  }

  private _getAssetGroups$(
    practice: WithRef<IPractice>
  ): Observable<AssetGroup[]> {
    const instruments: Observable<AssetGroup> = all$(
      Practice.instrumentCol(practice)
    ).pipe(
      map((items: WithRef<IInstrumentAsset>[]) => {
        return new AssetGroup('Instruments', items);
      })
    );

    const equipment: Observable<AssetGroup> = all$(
      Practice.equipmentCol(practice)
    ).pipe(
      map(
        (items: WithRef<IEquipmentAsset>[]) =>
          new AssetGroup('Equipment', items)
      )
    );

    const consumables: Observable<AssetGroup> = all$(
      Practice.consumableCol(practice)
    ).pipe(
      map(
        (items: WithRef<IConsumableAsset>[]) =>
          new AssetGroup('Consumables', items)
      )
    );

    return combineLatest([instruments, equipment, consumables]);
  }

  private _updateAssets(groups: AssetGroup[]): void {
    this.assets = groups
      .map((group: AssetGroup) => group.items)
      .reduce((accumulator, current) => accumulator.concat(current), []);
  }

  private _updateFilteredObservable(groups: AssetGroup[]): void {
    this.filteredGroups$ = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map((value: string | WithRef<IAsset>) => this.getSearchName(value)),
      map((value: string) => this._filter(groups, value))
    );
  }

  private _filter(groups: AssetGroup[], value: string): AssetGroup[] {
    return groups
      .map((group: AssetGroup) => group.filter(value))
      .filter((group: AssetGroup) => group.filteredItems.length > 0);
  }
}
