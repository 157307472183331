import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  Brand,
  GeneratedTask,
  getScopedConditions,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  ConditionLogicConfigurationCollection,
  ConditionLogicId,
  IAutomationTiming,
  TASK_PRIORITIES,
  TemplateScope,
  isGeneratedTaskConfiguration,
  isStaffer,
  type IGeneratedTask,
  type IGeneratedTaskConfiguration,
  type IPractice,
  type IStaffer,
  type ITeam,
  type TaskPriority,
} from '@principle-theorem/principle-core/interfaces';
import {
  TIME_FORMAT_24HR,
  isSameRef,
  mergeDayAndTime,
  toMoment,
  toNamedDocument,
  toTimestamp,
  type INamedDocument,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { duration, type Moment } from 'moment-timezone';
import { Subject, of, type Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GeneratedTaskDialogForm } from './generated-task-dialog-form';
import { AUTOMATION_CONDITIONS_HELP_TEXT } from '../automated-notification-configuration-edit/automated-notification-configuration-form';

export interface IGeneratedTaskDialogData {
  task?: IGeneratedTask;
  useRelativeTime: boolean;
  triggerDate?: Timestamp;
  practices?: INamedDocument<IPractice>[];
}

export interface IGeneratedTaskFormData extends IGeneratedTaskConfiguration {
  assignee: INamedDocument<IStaffer | ITeam>;
  triggerDate?: Moment;
  triggerTime?: string;
}

export interface IGeneratedTaskReturnData extends IGeneratedTask {
  assignee: INamedDocument<IStaffer | ITeam>;
  triggerDate?: Timestamp;
}

@Component({
  selector: 'pr-generated-task-dialog',
  templateUrl: './generated-task-dialog.component.html',
  styleUrls: ['./generated-task-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS],
})
export class GeneratedTaskDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  trackByTeam = TrackByFunctions.ref<WithRef<ITeam>>();
  trackByStaff = TrackByFunctions.ref<WithRef<IStaffer>>();
  trackByPriority = TrackByFunctions.variable<TaskPriority>();
  trackByPractice = TrackByFunctions.ref<INamedDocument>();
  form = new GeneratedTaskDialogForm();
  teams$: Observable<WithRef<ITeam>[]>;
  staff$: Observable<WithRef<IStaffer>[]>;
  taskPriorities: TaskPriority[] = TASK_PRIORITIES;
  timeInterval = duration(15, 'minutes');
  taskConfig?: IGeneratedTaskConfiguration | undefined;
  noScope: boolean;
  availableConditions: ConditionLogicId[];
  defaultCondition = ConditionLogicId.Always;
  automationConditionsHelp = AUTOMATION_CONDITIONS_HELP_TEXT;

  constructor(
    private _dialogRef: MatDialogRef<
      GeneratedTaskDialogComponent,
      IGeneratedTaskReturnData
    >,
    private _organisation: OrganisationService,
    @Inject(MAT_DIALOG_DATA) public data?: IGeneratedTaskDialogData
  ) {
    if (data?.task) {
      this.form.patchValue(data.task);
      this.taskConfig = isGeneratedTaskConfiguration(data.task)
        ? data.task
        : undefined;
    }

    if (data?.triggerDate && !data.useRelativeTime) {
      this.form.enableTriggerControls();
      this.form.controls.triggerDate.setValue(toMoment(data.triggerDate));
      this.form.controls.triggerTime.setValue(
        toMoment(data.triggerDate).format(TIME_FORMAT_24HR)
      );
    }

    if (data?.practices) {
      this.form.controls.practiceRef.enable();
    }

    this.staff$ = this._organisation.staff$;

    this.teams$ = this._organisation.brand$.pipe(
      switchMap((brand) => (brand ? Brand.teams$(brand) : of([])))
    );

    this.availableConditions = getScopedConditions(
      TemplateScope.Appointment
    ).filter((condition) => condition !== ConditionLogicId.Never);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  clearTriggerDate(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.form.controls.triggerDate.reset();
  }

  requiredConditionsChange(event: ConditionLogicConfigurationCollection): void {
    this.form.controls.requiredConditions.setValue(event);
  }

  timingChange(event: IAutomationTiming): void {
    this.form.controls.timing.setValue({
      ...event,
      // eslint-disable-next-line no-null/no-null
      time: event.time ?? null,
    });
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }
    const formValue = this.form.value;
    formValue.isForTeam = !isStaffer(formValue.assignee);
    formValue.assignee = isStaffer(formValue.assignee)
      ? stafferToNamedDoc(formValue.assignee)
      : toNamedDocument(formValue.assignee);
    this._dialogRef.close({
      ...formValue,
      ...GeneratedTask.init(formValue),
      triggerDate:
        formValue.triggerDate && formValue.triggerTime
          ? toTimestamp(
              mergeDayAndTime(
                toMoment(formValue.triggerDate),
                moment(formValue.triggerTime, TIME_FORMAT_24HR)
              )
            )
          : undefined,
    });
  }

  compareFn(value: INamedDocument, assignee: INamedDocument): boolean {
    if (!value || !assignee) {
      return false;
    }

    return isSameRef(value, assignee);
  }
}
