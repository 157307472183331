import {
  BrandCollection,
  IBrand,
  IManualTransactionType,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  CollectionReference,
  IReffable,
  Region,
  WithRef,
  all$,
  multiSortBy$,
  nameSorter,
  query$,
  subCollection,
  undeletedQuery,
} from '@principle-theorem/shared';
import { Observable } from 'rxjs';

export class ManualTransactionType {
  static init(
    overrides: AtLeast<IManualTransactionType, 'name'>
  ): IManualTransactionType {
    return { deleted: false, ...overrides };
  }

  static col(
    brand: IReffable<IBrand>
  ): CollectionReference<IManualTransactionType> {
    return subCollection<IManualTransactionType>(
      brand.ref,
      BrandCollection.ManualTransactionTypes
    );
  }

  static all$(
    brand: IReffable<IBrand>,
    includeDeleted: boolean = false
  ): Observable<WithRef<IManualTransactionType>[]> {
    const collection = this.col(brand);
    const results$ = includeDeleted
      ? all$(collection)
      : query$(undeletedQuery(collection));
    return results$.pipe(multiSortBy$(nameSorter()));
  }

  static getDefaultTransactionTypes(region: Region): IManualTransactionType[] {
    const regionSpecificTypes: Record<Region, string[]> = {
      [Region.Australia]: AUSTRALIAN_MANUAL_TRANSACTION_TYPES,
      [Region.NewZealand]: [],
    };

    return [
      ...COMMON_MANUAL_TRANSACTION_TYPES,
      ...(regionSpecificTypes[region] || []),
    ].map((name) => ManualTransactionType.init({ name }));
  }
}

export const COMMON_MANUAL_TRANSACTION_TYPES = [
  'Direct Deposit',
  'EFTPOS',
  'ZIP Pay', // Avalible in AU and NZ
  'Afterpay', // Avalible in AU and NZ
];

export const AUSTRALIAN_MANUAL_TRANSACTION_TYPES = [
  'CDBS',
  'DVA',
  'Government Voucher',
  'Health Fund Claim',
];
