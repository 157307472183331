<mat-toolbar color="accent">
  {{ data.editMode ? 'Edit' : 'Add' }} Tooth
</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content>
    <div class="flex flex-col" formGroupName="toothRef">
      <mat-form-field>
        <mat-label>Quadrant</mat-label>
        <mat-select
          formControlName="quadrant"
          required
          [disabled]="data.editMode"
        >
          <mat-option
            *ngFor="let quadrant of quadrants; trackBy: trackByQuadrant"
            [value]="quadrant"
          >
            {{ quadrant }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Tooth Index</mat-label>
        <input
          matInput
          type="number"
          min="1"
          formControlName="quadrantIndex"
          [required]="true"
          [disabled]="data.editMode"
        />
      </mat-form-field>
    </div>

    <div class="flex items-center gap-4">
      <mat-form-field class="w-full">
        <mat-label>Tooth Type</mat-label>
        <mat-select formControlName="type" required [disabled]="data.editMode">
          <mat-option
            *ngFor="let toothType of toothTypes; trackBy: trackByToothType"
            [value]="toothType"
          >
            {{ toothType | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="w-full">
        <mat-label>Number of Roots</mat-label>
        <mat-select formControlName="roots" required>
          <mat-option
            *ngFor="let roots of rootOptions; trackBy: trackByRoot"
            [value]="roots"
          >
            {{ roots }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" [disabled]="form.invalid">
        Save
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
