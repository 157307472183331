<ng-container *ngIf="tags$ | async as tags">
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
    *ngIf="tags.length"
  >
    <mat-icon class="tag-icon" matTooltip="Appointment Tags">
      airline_seat_flat_angled
    </mat-icon>
    <mat-chip-listbox>
      <mat-chip
        *ngFor="let tag of tags; trackBy: trackByTag"
        [style.--mdc-chip-label-text-color]="tag | tagTextColour"
        [style.background-color]="tag.hexColour"
      >
        {{ tag.name }}
      </mat-chip>
    </mat-chip-listbox>
  </div>
</ng-container>
