<div class="perio-table-container">
  <h3>{{ label$ | async }} Facial</h3>
  <pr-perio-table-display
    [perioTable]="facial$ | async"
    (perioCellChange)="perioCellChange.emit($event)"
   />
</div>

<div class="perio-table-container">
  <h3>{{ label$ | async }} Palatal</h3>
  <pr-perio-table-display
    [perioTable]="palatal$ | async"
    (perioCellChange)="perioCellChange.emit($event)"
   />
</div>
