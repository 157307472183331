import { QueryScopeConfigId } from '@principle-theorem/principle-core/interfaces';
import { flatMap, uniq } from 'lodash';
import { toDatasetName } from '../helpers';
import { BaseMeasures } from '../models/base-measures';
import { IReportingQuery, IScopedQuery } from '../querying';
import { IQueryScope } from '../scoped-query';
import { BuiltQueryScope } from './query-scope-config';

export class QueryScopeBuilder {
  constructor(public factTable: BaseMeasures) {}

  buildScopedQuery(query: IReportingQuery, scope: IQueryScope): IScopedQuery {
    const scopeQuery = this.buildQueryScope(scope);
    return {
      dataSet: toDatasetName(scope.orgRef),
      query: {
        ...query,
        attributes: [...scopeQuery.attributes, ...query.attributes],
        filters: [...scopeQuery.filters, ...(query.filters ?? [])],
      },
    };
  }

  hasScope(id: string): boolean {
    return this.factTable.scopes.some((scope) => scope.id === id);
  }

  buildQueryScope(scope: IQueryScope): BuiltQueryScope {
    const queries = this.factTable.scopes.map((config) => {
      const scopeRequest = scope.scopeRequests[config.id as QueryScopeConfigId];
      if (!config.isValid(scopeRequest)) {
        throw new Error(`Invalid scope given for scope config: ${config.id}`);
      }
      return config.buildQuery(scopeRequest, { factTable: this.factTable });
    });
    return {
      attributes: uniq(flatMap(queries, (query) => query.attributes)),
      filters: uniq(flatMap(queries, (query) => query.filters)),
    };
  }
}
