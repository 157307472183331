import {
  ITreatmentCategory,
  ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  getDoc,
  isSameRef,
  WithRef,
} from '@principle-theorem/shared';
import { compact, last, orderBy } from 'lodash';

interface ICategoryWeight {
  weight: number;
  category?: WithRef<ITreatmentCategory>;
}

export class CalculateTreatmentWeight {
  static async getPrimaryCategory(
    step: ITreatmentStep,
    treatmentCategories: WithRef<ITreatmentCategory>[]
  ): Promise<WithRef<ITreatmentCategory> | undefined> {
    const weights = await CalculateTreatmentWeight.getTreatmentCategoryWeights(
      step,
      treatmentCategories
    );
    const uniqueWeights = orderBy(
      CalculateTreatmentWeight.accumulateCategoryWeights(weights),
      'weight',
      'asc'
    );
    return uniqueWeights.length > 0 ? last(uniqueWeights)?.category : undefined;
  }

  static async getTreatmentCategoryWeights(
    step: ITreatmentStep,
    treatmentCategories: WithRef<ITreatmentCategory>[]
  ): Promise<ICategoryWeight[]> {
    const results = await asyncForEach(step.treatments, async (treatment) => {
      const { duration, category } = await getDoc(treatment.config.ref);

      if (!category) {
        return;
      }

      const treatmentCategory = treatmentCategories.find((cachedCategory) =>
        isSameRef(cachedCategory, category)
      );
      return { weight: duration ?? 0, category: treatmentCategory };
    });
    return compact(results);
  }

  static accumulateCategoryWeights(
    items: ICategoryWeight[]
  ): ICategoryWeight[] {
    return items.reduce((acc: ICategoryWeight[], item) => {
      const existing = acc.find((searchItem) =>
        isSameRef(item.category, searchItem.category)
      );
      if (!existing) {
        return [...acc, item];
      }
      existing.weight += item.weight;
      return acc;
    }, []);
  }
}
