import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  Input,
} from '@angular/core';
import {
  EditorNode,
  EditorNodeComponent,
  NodeAttribute,
} from '@principle-theorem/ng-prosemirror';
import { BlockNodes, NodeGroup } from '@principle-theorem/editor';
import { ReplaySubject } from 'rxjs';

@EditorNode({
  name: BlockNodes.ImageUploading,
  group: NodeGroup.Block,
  inline: false,
  selectable: false,
})
@Component({
  selector: 'pt-image-uploading',
  templateUrl: './image-uploading.component.html',
  styleUrls: ['./image-uploading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageUploadingComponent extends EditorNodeComponent {
  progress$ = new ReplaySubject<number>(1);

  @NodeAttribute()
  @Input()
  set progress(progress: number) {
    this.progress$.next(progress);
  }

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }
}
