import {
  ChartableSurface,
  IExpectedSourceRecordSize,
  IPracticeMigration,
  ISourceEntity,
  SourceEntityMigrationType,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  Timezone,
  TypeGuard,
  WithRef,
  isTimestamp,
  toFloat,
  toMoment,
  toTimestamp,
} from '@principle-theorem/shared';
import { flow, groupBy, isBoolean, isNumber, isString, uniq } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { runQuery } from '../../../source/connection';
import { SourceEntity } from '../../../source/source-entity';
import {
  OFFSET_PLACEHOLDER,
  convertDateToTimestampFn,
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  convertValueFn,
} from '../../../source/source-helpers';

export const PATIENT_TREATMENT_PLAN_RESOURCE_TYPE = 'patientTreatmentPlan';

export const PATIENT_TREATMENT_PLAN_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Treatment Plan List',
      description: '',
      idPrefix: PATIENT_TREATMENT_PLAN_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export const TOOTH_SURFACES = ['M', 'O', 'I', 'D', 'La', 'B', 'L', 'P'];

export enum OasisToothSurface {
  Occlusal = 'O',
  Buccal = 'B',
  Lingual = 'L',
  Mesial = 'M',
  Distal = 'D',
  Labial = 'La',
  Palatal = 'P',
  Incisal = 'I',
}

const toothSurfacesMap: Record<OasisToothSurface, ChartableSurface> = {
  M: ChartableSurface.Mesial,
  O: ChartableSurface.Occlusal,
  I: ChartableSurface.Occlusal,
  D: ChartableSurface.Distal,
  B: ChartableSurface.Facial,
  La: ChartableSurface.Facial,
  L: ChartableSurface.Lingual,
  P: ChartableSurface.Lingual,
};

export function convertToToothSurfaces(item: string): ChartableSurface[] {
  if (!item) {
    return [];
  }
  const surfaces = uniq(
    item
      .replace(/-/g, '')
      .match(/([A-Z]?[^A-Z]*)/g)
      ?.slice(0, -1) ?? []
  );
  const allValidSurfaces = surfaces.every((surface) =>
    TOOTH_SURFACES.includes(surface)
  );
  if (!allValidSurfaces) {
    throw new Error(`Invalid tooth surfaces: ${surfaces.join(', ')}`);
  }
  return surfaces.map(
    (surface) => toothSurfacesMap[surface as OasisToothSurface]
  );
}

export interface IOasisPatientTreatmentPlanTreatment {
  id: number;
  patientId: number;
  treatmentPlanId: number;
  treatmentPlanName: string;
  treatmentStepName?: string;
  planNumber: number;
  stepNumber: number;
  appointmentRefId?: number;
  appointmentTime?: string;
  itemCode: string;
  itemCodeDescription: string;
  isCompleted: boolean;
  isPlanHeader: boolean;
  isStepHeader: boolean;
  surfaces?: ChartableSurface[];
  comment?: string;
  thirdPartyAmount?: number;
  balance?: number;
  amount?: number;
  createdAt: Timestamp;
  completedAt?: Timestamp;
  recommendedAt?: Timestamp;
  acceptedAt?: Timestamp;
  practitionerId: number;
  toothNumber?: number;
  quantity: number;
  minutes?: number;
  userId: number;
  userName: string;
  useGroup: boolean;
  explodeGroup: boolean;
  isOnHold: boolean;
  skipCompleted: boolean;
  category?: string;
}

export function isOasisPatientTreatmentPlan(
  item: unknown
): item is IOasisPatientTreatmentPlanTreatment {
  return TypeGuard.interface<IOasisPatientTreatmentPlanTreatment>({
    id: isNumber,
    patientId: isNumber,
    treatmentPlanId: isNumber,
    treatmentPlanName: isString,
    treatmentStepName: TypeGuard.nilOr(isString),
    planNumber: isNumber,
    stepNumber: isNumber,
    appointmentRefId: TypeGuard.nilOr(isNumber),
    appointmentTime: TypeGuard.nilOr(isString),
    itemCode: isString,
    itemCodeDescription: isString,
    isCompleted: isBoolean,
    isPlanHeader: isBoolean,
    isStepHeader: isBoolean,
    surfaces: TypeGuard.nilOr(
      TypeGuard.arrayOf(TypeGuard.enumValue(ChartableSurface))
    ),
    comment: TypeGuard.nilOr(isString),
    thirdPartyAmount: TypeGuard.nilOr(isNumber),
    balance: TypeGuard.nilOr(isNumber),
    amount: TypeGuard.nilOr(isNumber),
    createdAt: isTimestamp,
    completedAt: TypeGuard.nilOr(isTimestamp),
    recommendedAt: TypeGuard.nilOr(isTimestamp),
    acceptedAt: TypeGuard.nilOr(isTimestamp),
    practitionerId: isNumber,
    toothNumber: TypeGuard.nilOr(isNumber),
    quantity: isNumber,
    minutes: TypeGuard.nilOr(isNumber),
    userId: isNumber,
    userName: isString,
    useGroup: isBoolean,
    explodeGroup: isBoolean,
    isOnHold: isBoolean,
    skipCompleted: isBoolean,
    category: TypeGuard.nilOr(isString),
  })(item);
}

export interface IOasisPatientTreatmentPlanTreatmentQuery
  extends Omit<IOasisPatientTreatmentPlanTreatment, 'surfaces'> {
  surfaces?: string;
}

export interface IOasisPatientTreatmentPlanTreatmentTranslations {
  createdAt: Timestamp;
  completedAt?: Timestamp;
  recommendedAt?: Timestamp;
  acceptedAt?: Timestamp;
}

export interface IOasisPatientTreatmentPlanTreatmentFilters {
  practitionerId: number;
  patientId: number;
  treatmentPlanId: number;
  itemCode: string;
  createdAt: Timestamp;
}

export const PATIENT_TREATMENT_PLAN_SOURCE_QUERY = `
SELECT
	treatment_plan_header.treatment_plan_id,
	treatment_plan_header.treatment_plan_name,
	treatment_step_header.treatment_step_name,
	treatment_step_header.appointment_ref_id,
	treatment_step_header.appointment_time,
	treatment_plan.*
FROM (
	SELECT
    SEQNUMBER AS id,
		convert_to_integer(PATNUMBER) AS patient_id,
		NULLIF(APPTIME, '') AS appointment_time,
		PLANNUMBER AS plan_number,
		VISITNUMBER AS step_number,
		DETAILS AS item_code_description,
		ITEMCODE AS item_code,
    CASE WHEN COMPLETED = -1
			THEN TRUE
			ELSE FALSE
		END AS is_completed,
		CASE WHEN HEADER = -1
			THEN TRUE
			ELSE FALSE
		END AS is_plan_header,
		CASE WHEN VISITHEADER = -1
			THEN TRUE
			ELSE FALSE
		END AS is_step_header,
		NULLIF(SURFACES, '') AS surfaces,
		NULLIF(COMMENT, '') AS comment,
		convert_to_decimal(NULLIF(THIRDPARTYAMOUNT, 0)) AS third_party_amount,
		convert_to_decimal(NULLIF(BALANCE, 0)) AS balance,
		convert_to_decimal(NULLIF(AMOUNT, 0)) AS amount,

    CASE
      WHEN strpos(DATECOMPLETED, '/') > 0
        THEN convert_to_date(NULLIF(DATECOMPLETED, 'NULL'), 'DD/MM/YYYY')
      ELSE convert_to_date(NULLIF(DATECOMPLETED, ''), 'YYYY-MM-DD')
		END AS completed_at,

    CASE
      WHEN strpos(DATECREATED, '/') > 0
        THEN convert_to_date(NULLIF(DATECREATED, 'NULL'), 'DD/MM/YYYY')
      ELSE convert_to_date(NULLIF(DATECREATED, ''), 'YYYY-MM-DD')
		END AS created_at,

    CASE
      WHEN strpos(DATERECOMMENDED, '/') > 0
        THEN convert_to_date(NULLIF(DATERECOMMENDED, 'NULL'), 'DD/MM/YYYY')
      ELSE convert_to_date(NULLIF(DATERECOMMENDED, ''), 'YYYY-MM-DD')
    END AS recommended_at,

    CASE
      WHEN strpos(DATEACCEPTED, '/') > 0
        THEN convert_to_date(NULLIF(DATEACCEPTED, 'NULL'), 'DD/MM/YYYY')
      ELSE convert_to_date(NULLIF(DATEACCEPTED, ''), 'YYYY-MM-DD')
    END AS accepted_at,

		DOCTORNUMBER AS practitioner_id,
		NULLIF(TOOTHNUMBER, 0) AS tooth_number,
		convert_to_integer(FREQUENCY) AS quantity,
		NULLIF(MINUTES, 0) AS minutes,
		CASE WHEN USEGROUP = -1
			THEN TRUE
			ELSE FALSE
		END AS use_group,
		CASE WHEN EXPLODEGROUP = -1
			THEN TRUE
			ELSE FALSE
		END AS explode_group,
		CASE WHEN ONHOLD = -1
			THEN TRUE
			ELSE FALSE
		END AS is_on_hold,
		ENTRYUSERNUMBER AS user_id,
		ENTRYUSERNAME AS user_name,
		convert_to_boolean(SKIPCOMPLETED) AS skip_completed,
		NULLIF(NULLIF(VISITCODE, ''), 'NULL') AS category
	FROM PTPATTPN
	WHERE
		HEADER != -1
		AND VISITHEADER != -1
  ${OFFSET_PLACEHOLDER}
) AS treatment_plan
LEFT JOIN (
	SELECT
		convert_to_integer(SKEY) AS treatment_plan_id,
		DETAILS AS treatment_plan_name,
		convert_to_integer(PATNUMBER) AS patient_id,
		PLANNUMBER AS plan_number
	FROM PTPATTPN
	WHERE HEADER = -1
) AS treatment_plan_header
ON
	treatment_plan.patient_id = treatment_plan_header.patient_id
	AND treatment_plan.plan_number = treatment_plan_header.plan_number
LEFT JOIN (
	SELECT
		SKEY AS treatment_plan_id,
		DETAILS AS treatment_step_name,
		NULLIF(REFNUMBER, 0) AS appointment_ref_id,
		NULLIF(APPTIME, '') AS appointment_time,
		convert_to_integer(PATNUMBER) AS patient_id,
		PLANNUMBER AS plan_number,
		CASE WHEN VISITHEADER = -1
			THEN TRUE
			ELSE FALSE
		END AS is_step_header,
		VISITNUMBER AS step_number
	FROM PTPATTPN
	WHERE VISITHEADER = -1
) AS treatment_step_header
ON
	treatment_plan.patient_id = treatment_step_header.patient_id
	AND treatment_plan.plan_number = treatment_step_header.plan_number
	AND treatment_plan.step_number = treatment_step_header.step_number
WHERE
	treatment_plan_header.treatment_plan_id IS NOT NULL
ORDER BY
	treatment_plan.patient_id ASC,
	treatment_plan.plan_number,
	treatment_plan.step_number,
	treatment_plan.id
`;

export class PatientTreatmentPlanTreatmentsSourceEntity extends BaseSourceEntity<
  IOasisPatientTreatmentPlanTreatment,
  IOasisPatientTreatmentPlanTreatmentTranslations,
  IOasisPatientTreatmentPlanTreatmentFilters
> {
  sourceEntity = PATIENT_TREATMENT_PLAN_SOURCE_ENTITY;
  entityResourceType = PATIENT_TREATMENT_PLAN_RESOURCE_TYPE;
  sourceQuery = PATIENT_TREATMENT_PLAN_SOURCE_QUERY;
  allowOffsetJob = true;
  verifySourceFn = isOasisPatientTreatmentPlan;
  override defaultOffsetSize = 25000;
  override dateFilterField: keyof IOasisPatientTreatmentPlanTreatmentFilters =
    'createdAt';
  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertDateToTimestampFn(),
    convertValueFn(toFloat, 'thirdPartyAmount', 'balance', 'amount'),
    transformPatientTreatmentPlanResults,
  ]);

  override async getExpectedRecordSize(
    migration: WithRef<IPracticeMigration>
  ): Promise<IExpectedSourceRecordSize> {
    const response = await runQuery<{ id: number }>(
      migration,
      PATIENT_TREATMENT_PLAN_SOURCE_QUERY.replace(OFFSET_PLACEHOLDER, '')
    );

    const expectedSize = Object.values(
      groupBy(response.rows, (claim) => claim.id)
    ).length;

    return {
      expectedSize,
      expectedSizeCalculatedAt: toTimestamp(),
    };
  }

  translate(
    data: IOasisPatientTreatmentPlanTreatment,
    timezone: Timezone
  ): IOasisPatientTreatmentPlanTreatmentTranslations {
    return {
      createdAt: toTimestamp(moment.tz(toMoment(data.createdAt), timezone)),
      completedAt: data.completedAt
        ? toTimestamp(moment.tz(toMoment(data.completedAt), timezone))
        : undefined,
      recommendedAt: data.recommendedAt
        ? toTimestamp(moment.tz(toMoment(data.recommendedAt), timezone))
        : undefined,
      acceptedAt: data.acceptedAt
        ? toTimestamp(moment.tz(toMoment(data.acceptedAt), timezone))
        : undefined,
    };
  }

  getSourceRecordId(data: IOasisPatientTreatmentPlanTreatment): number {
    return data.id;
  }

  getSourceLabel(data: IOasisPatientTreatmentPlanTreatment): string {
    return `${data.id} ${data.patientId} ${data.treatmentPlanName || ''}`;
  }

  getFilterData(
    data: IOasisPatientTreatmentPlanTreatment,
    timezone: Timezone
  ): IOasisPatientTreatmentPlanTreatmentFilters {
    return {
      practitionerId: data.practitionerId,
      patientId: data.patientId,
      itemCode: data.itemCode,
      treatmentPlanId: data.treatmentPlanId,
      createdAt: toTimestamp(moment.tz(data.createdAt, timezone)),
    };
  }
}

function transformPatientTreatmentPlanResults(
  rows: IOasisPatientTreatmentPlanTreatmentQuery[]
): IOasisPatientTreatmentPlanTreatment[] {
  return rows.map((row) => {
    try {
      if (row.surfaces) {
        return {
          ...row,
          surfaces: convertToToothSurfaces(row.surfaces),
        };
      }
    } catch (error) {
      //
    }
    return {
      ...row,
      surfaces: [],
    };
  });
}
