import { getSchemaText } from '@principle-theorem/editor';
import {
  AnyAutomation,
  isGeneratedTask,
  AutomatedNotificationType,
  isAutomatedNotification,
  isAutomatedFormIssue,
} from '@principle-theorem/principle-core/interfaces';

export interface IAutomationIconDisplay {
  icon: string;
  cssClass: string;
  tooltip: string;
}

export const SMS_ICON: IAutomationIconDisplay = {
  icon: 'sms',
  cssClass: 'bg-sky-500 ',
  tooltip: 'Automated  SMS',
};

export const EMAIL_ICON: IAutomationIconDisplay = {
  icon: 'email',
  cssClass: 'bg-purple-500',
  tooltip: 'Automated Email',
};

export const TASK_ICON: IAutomationIconDisplay = {
  icon: 'check_box',
  cssClass: 'bg-yellow-400',
  tooltip: 'Generated Task',
};

export const FORM_ICON: IAutomationIconDisplay = {
  icon: 'list_alt',
  cssClass: 'bg-pink-500',
  tooltip: 'Generated Form',
};

export const UNKNOWN_ICON: IAutomationIconDisplay = {
  icon: 'question_mark',
  cssClass: 'bg-slate-300',
  tooltip: 'Unknown',
};

export class AutomationDisplay {
  static getLabel(config: AnyAutomation): string {
    if (isGeneratedTask(config)) {
      return getSchemaText(config.title);
    }
    return config.name;
  }

  static getIcon(config: AnyAutomation): IAutomationIconDisplay {
    if (isAutomatedNotification(config)) {
      if (config.type === AutomatedNotificationType.SMS) {
        return SMS_ICON;
      }
      return EMAIL_ICON;
    }
    if (isGeneratedTask(config)) {
      return TASK_ICON;
    }
    if (isAutomatedFormIssue(config)) {
      return FORM_ICON;
    }
    return UNKNOWN_ICON;
  }
}
