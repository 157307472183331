import { TaxStrategy } from '@principle-theorem/accounting';
import {
  TypeGuard,
  type INamedDocument,
  isArray,
  isObject,
} from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';
import { type IChartedItemConfiguration } from '../../charted-item-configuration';
import { type IChartedSurface } from '../../core/charted-surface';
import { type IPricingRule } from '../../fees/pricing-rules/pricing-rule-provider';
import {
  type IScopedServiceCode,
  type IServiceCode,
  type ServiceCode,
  ServiceCodeType,
} from '../service-item';

export interface IServiceCodeSchemaItem {
  code: string;
  taxStatus: TaxStrategy;
  title: string;
  description: string;
  deleted: boolean;
}
export type ServiceCodeInfoSchema = IServiceCodeSchemaItem[];

export function isServiceCode(item: unknown): item is IServiceCode {
  return TypeGuard.interface<IServiceCode>({
    code: TypeGuard.isOneOf(isString, isNumber),
    title: isString,
    description: isString,
    taxStatus: TypeGuard.enumValue(TaxStrategy),
    rules: isObject,
    scope: isArray,
    type: TypeGuard.enumValue(ServiceCodeType),
    claimCode: TypeGuard.undefinedOr(TypeGuard.isOneOf(isString, isNumber)),
    rootCode: TypeGuard.undefinedOr(TypeGuard.isOneOf(isString, isNumber)),
    notes: TypeGuard.undefinedOr(isString),
    fee: TypeGuard.isOneOf(isNumber, isArray),
    deleted: isBoolean,
  })(item);
}

export interface IADACodeRules {
  scope?: ServiceCodeScope;
  scopeValue?: boolean | number;

  // ADA codes which already cater for this code and is therefore not needed.
  includedInCodes?: ServiceCode[];

  // Must be performed in conjunction with this item code
  inConjunctionWith?: {
    required: ServiceCode[];
    otherwise?: ServiceCode;
  };

  optionalRelevantCodes?: ServiceCode[];
}

export interface IServiceCodeEntry extends IScopedServiceCode {
  quantity: number;
}

export function isServiceCodeEntry(item: unknown): item is IServiceCodeEntry {
  return TypeGuard.interface<IServiceCodeEntry>({
    type: TypeGuard.enumValue(ServiceCodeType),
    code: [isString, isNumber],
    quantity: isNumber,
  })(item);
}

type PricedServiceCodeEntry = Pick<
  IPricedServiceCodeEntry,
  'price' | 'tax' | 'chartedSurfaces' | 'taxStatus'
>;

export function isPricedServiceCodeEntry(
  item: unknown
): item is IPricedServiceCodeEntry {
  return (
    isServiceCodeEntry(item) &&
    TypeGuard.interface<PricedServiceCodeEntry>({
      price: isNumber,
      tax: isNumber,
      chartedSurfaces: isArray,
      taxStatus: TypeGuard.enumValue(TaxStrategy),
    })(item)
  );
}

export interface IPricedServiceCodeEntry extends IServiceCodeEntry {
  uuid: string;
  pricingRule: IPricingRule;
  price: number;
  priceOverride?: number;
  tax: number;
  taxStatus: TaxStrategy;
  chartedItemOrigin?: INamedDocument<IChartedItemConfiguration>;
  chartedSurfaces: IChartedSurface[];
}

export interface IServiceCodeGroup<
  T extends IServiceCodeEntry = IServiceCodeEntry,
> {
  uid: string;
  type: ServiceCodeGroupType;
  serviceCodes: T[];
}

export function isServiceCodeGroup(item: unknown): item is IServiceCodeGroup {
  return TypeGuard.interface<IServiceCodeGroup>({
    uid: isString,
    type: TypeGuard.enumValue(ServiceCodeGroupType),
    serviceCodes: isArray,
  })(item);
}

export interface IServiceSmartGroup<T = ServiceCode> {
  uid: string;
  title: string;
  description: string;
  scope: ServiceCodeScope;
  serviceCodeType: ServiceCodeType;
  serviceCodes: T[];
}

export function isServiceSmartGroup(item: unknown): item is IServiceSmartGroup {
  return TypeGuard.interface<IServiceSmartGroup>({
    uid: isString,
    title: isString,
    description: isString,
    scope: TypeGuard.enumValue(ServiceCodeScope),
    serviceCodeType: TypeGuard.enumValue(ServiceCodeType),
    serviceCodes: isArray,
  })(item);
}

export interface IChartedServiceSmartGroup
  extends IServiceSmartGroup<IPricedServiceCodeEntry> {
  selected?: ServiceCode;
}

export interface IChartedServiceExclusiveGroup
  extends IServiceCodeGroup<IPricedServiceCodeEntry> {
  selected?: ServiceCode;
}

export function isServiceExclusiveGroup(
  data: unknown
): data is IChartedServiceExclusiveGroup {
  return (
    isObject(data) &&
    isServiceCodeGroup(data) &&
    TypeGuard.undefinedOr(isString)(data.selected)
  );
}

export type IPricedServiceCodeGroup =
  IServiceCodeGroup<IPricedServiceCodeEntry>;

export enum ServiceCodeScope {
  // Automatically derivable scopes
  PerDay = 'PerDay',
  PerAppointment = 'PerAppointment',
  PerArch = 'PerArch',
  PerJaw = 'PerJaw',
  PerQuadrant = 'PerQuadrant',
  PerAnterior = 'PerAnterior',
  PerPosterior = 'PerPosterior',
  PerTooth = 'PerTooth',
  PerDeciduousTooth = 'PerDeciduousTooth',
  PerImplant = 'PerImplant',
  PerMultipleSurface = 'PerMultipleSurface',
  PerAnteriorMultipleSurface = 'PerAnteriorMultipleSurface',
  PerPosteriorMultipleSurface = 'PerPosteriorMultipleSurface',

  // Item codes which change to another one when time is greater than 30 minutes. Not too sure how we should approach this
  AppointmentTime = 'AppointmentTime', // 014 for less than 30 mins, 015 otherwise. Same for 016/017.

  // Can ask for the qty to determine how many of this ADA code are required

  // Restorative
  PerAnteriorCorner = 'PerAnteriorCorner', // 578
  PerPosteriorCusp = 'PerPosteriorCusp', // 577
  PerPin = 'PerPin', // 575

  // Diagnositc Services
  PerModel = 'PerModel', // For diagnostic services such as 071 model
  PerExposure = 'PerExposure', // For diagnostic services such as radiography

  // Root Canal
  PerRoot = 'PerRoot',
  PerCanal = 'PerCanal',
  FirstCanal = 'FirstCanal',
  AdditionalCanal = 'AdditionalCanal',

  // Crown & Bridge
  PerPontic = 'PerPontic', // Artificial tooth in a bridge; A 3-unit bridge would have 1 pontic
  PerAbutment = 'PerAbutment', // Natural tooth in a bridge; A 3-unit bridge would have 2 abutments

  PerSegment = 'PerSegment',
  PerSinus = 'PerSinus',
  PerTime = 'PerTime', // 942 for every 30 mins.
  PerPeriodMonths = 'PerPeriodMonths',

  // Dentures
  PerRest = 'PerRest', // Tooth that a denture rests on. This should be indicated
  PerBacking = 'PerBacking', // The backing of a metal partial denture
  PerClasp = 'PerClasp',
  PerDenture = 'PerDenture',
}

export enum ServiceCodeGroupType {
  Required = 'required',
  Exclusive = 'exclusive',
  Optional = 'optional',
  Smart = 'smart',
}
