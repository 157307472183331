import { Validators } from '@angular/forms';
import {
  initVersionedSchema,
  type MixedSchema,
} from '@principle-theorem/editor';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  type IAppointment,
  type ILab,
  type ILabJob,
  type ILabJobType,
  type IPatient,
  LabJobStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  isINamedDocument,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';

export interface ILabJobFormData
  extends Omit<
    ILabJob,
    | 'statusHistory'
    | 'dueDate'
    | 'completedDate'
    | 'interactions'
    | 'createdAt'
    | 'title'
    | 'updatedAt'
    | 'deleted'
    | 'appointment'
  > {
  appointment?: WithRef<IAppointment>;
  dueDate?: moment.Moment;
}

export class LabJobFormGroup extends TypedFormGroup<ILabJobFormData> {
  constructor() {
    super({
      lab: new TypedFormControl<INamedDocument<ILab>>(
        undefined,
        Validators.required
      ).withGuard(isINamedDocument),
      patient: new TypedFormControl<INamedDocument<IPatient>>(
        undefined,
        Validators.required
      ).withGuard(isINamedDocument),
      appointment: new TypedFormControl<WithRef<IAppointment> | undefined>({
        value: undefined,
        disabled: true,
      }),
      dueDate: new TypedFormControl<moment.Moment>().withGuard(moment.isMoment),
      type: new TypedFormControl<ILabJobType>(undefined, Validators.required),
      cost: new TypedFormControl<number>(0),
      description: new TypedFormControl<MixedSchema>(initVersionedSchema()),
      status: new TypedFormControl<LabJobStatus>(LabJobStatus.Sending),
    });
  }

  override getRawValue(): ILabJobFormData {
    const data: ILabJobFormData = super.getRawValue();
    data.lab = toNamedDocument(data.lab);
    data.patient = toNamedDocument(data.patient);
    if (!data.appointment) {
      data.appointment = undefined;
    }
    return data;
  }

  patchFromLabJob(labJob: ILabJobFormData): void {
    this.patchValue(
      {
        lab: labJob.lab,
        patient: labJob.patient,
        type: labJob.type,
        cost: labJob.cost,
        description: labJob.description || initVersionedSchema(),
        status: labJob.status,
        dueDate: labJob.dueDate,
        appointment: labJob.appointment,
      },
      { emitEvent: false }
    );
  }
}
