import {
  type AnyChartedItemConfiguration,
  type IChartedItem,
  type IChartedItemConfiguration,
  type IChartedRef,
  type IChartedTreatment,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import {
  type IAddChartedSurfaceProvider,
  type IRemoveChartedSurfaceProvider,
  type IEditTreatmentProvider,
  type TreatmentUpdateFn,
} from './charted-surface/add-charted-surface-provider';
import {
  ChartedSurfaceEventHandler,
  ChartedSurfaceRemovalHandler,
} from './charted-surface/charted-surface-event-handler';

export class AddChartable {
  constructor(private _addSurfaceProviders: IAddChartedSurfaceProvider[]) {}

  async add({
    selectedSurfaces,
    chartable,
    chartingAs,
  }: {
    selectedSurfaces: Partial<IChartedRef>[];
    chartable: AnyChartedItemConfiguration;
    chartingAs: WithRef<IStaffer>;
  }): Promise<void> {
    const chartedSurfaceEventHandler = new ChartedSurfaceEventHandler(
      chartingAs,
      this._addSurfaceProviders
    );
    await chartedSurfaceEventHandler.addChartable(chartable, selectedSurfaces);
  }
}

export class RemoveChartable {
  constructor(
    private _removeSurfaceProviders: IRemoveChartedSurfaceProvider[]
  ) {}

  async remove(
    chartable: AnyChartedItemConfiguration,
    surfaces: Partial<IChartedRef>[],
    item: IChartedItem<IChartedItemConfiguration>
  ): Promise<void> {
    const surfaceRemovalHandler = new ChartedSurfaceRemovalHandler(
      this._removeSurfaceProviders
    );
    await surfaceRemovalHandler.removeSurfaces(chartable, surfaces, item);
  }
}

export class MoveChartable {
  async move(
    chartedTreatment: IChartedTreatment[],
    removeProvider: IEditTreatmentProvider,
    addProvider?: IEditTreatmentProvider
  ): Promise<void> {
    await removeProvider.removeTreatments(chartedTreatment);
    if (addProvider) {
      await addProvider.addTreatments(chartedTreatment);
    }
  }
}

export class UpdateChartable {
  async update(
    chartedTreatments: IChartedTreatment[],
    stepTreatments: IChartedTreatment[],
    updateFn: TreatmentUpdateFn,
    updateProvider: IEditTreatmentProvider
  ): Promise<void> {
    await updateProvider.updateTreatments(
      chartedTreatments,
      stepTreatments,
      updateFn
    );
  }
}
