import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import {
  type ConditionLogicConfigurationCollection,
  type ConditionLogicId,
} from '@principle-theorem/principle-core/interfaces';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { ConditionalLogicStore } from './conditional-logic-store.service';

@Component({
    selector: 'pr-conditional-logic-form',
    templateUrl: './conditional-logic-form.component.html',
    styleUrls: ['./conditional-logic-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ConditionalLogicStore],
    standalone: false
})
export class ConditionalLogicFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();

  @Output() conditionsUpdated =
    new EventEmitter<ConditionLogicConfigurationCollection>();

  @Input()
  set availableConditions(availableConditions: ConditionLogicId[]) {
    if (availableConditions) {
      this.store.patchState({ availableConditions });
    }
  }

  @Input()
  set defaultCondition(
    defaultCondition: ConditionLogicId.Never | ConditionLogicId.Always
  ) {
    if (defaultCondition) {
      this.store.patchState({ defaultCondition });
    }
  }

  @Input()
  set conditions(conditions: ConditionLogicConfigurationCollection) {
    if (conditions) {
      this.store.patchState({ conditions });
    }
  }

  constructor(public store: ConditionalLogicStore) {
    this.store.conditions$
      .pipe(skip(1), takeUntil(this._onDestroy$))
      .subscribe((conditions) => this.conditionsUpdated.emit(conditions));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
