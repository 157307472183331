import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  type IAccountCredit,
  type IInvoice,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';

export interface IRefundDepositPromptData {
  invoice: WithRef<IInvoice>;
  credits: WithRef<IAccountCredit>[];
}

@Component({
  selector: 'pr-refund-deposit-prompt',
  templateUrl: './refund-deposit-prompt.component.html',
  styleUrls: ['./refund-deposit-prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RefundDepositPromptComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IRefundDepositPromptData) {}
}
