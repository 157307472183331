<mat-toolbar color="accent">Update Lab Job Type</mat-toolbar>
<div class="layout-padding">
  <pr-lab-job-type-form
    [labJobType]="data.labJobType"
    (submitted)="submitted($event)"
  >
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary">Update</button>
  </pr-lab-job-type-form>
</div>
