import {
  FormLayoutElementType,
  FormSchemaPropertyType,
  type IFormLayoutElement,
  type IFormSchema,
} from '@principle-theorem/principle-core/interfaces';
import { formFlexRow, formHtml } from '../../custom-form-helpers';

export const PAEDIATRIC_TERMS_AND_CONDITIONS_FORM_SCHEMA: IFormSchema = {
  type: 'object',
  properties: {
    relationshipToChild: {
      type: FormSchemaPropertyType.String,
      title: 'Relationship to child',
    },
    agreed: {
      type: FormSchemaPropertyType.Boolean,
      title: 'I agree to the terms and conditions',
      const: true,
    },
  },
  required: ['agreed', 'relationshipToChild'],
};

export const PAEDIATRIC_TERMS_AND_CONDITIONS_FORM_LAYOUT: IFormLayoutElement[] =
  [
    formHtml(`
    <p class="mat-caption">
      All personal information collected is confidential and handled in
      accordance to our privacy policy. See
      <a href="https://principle.dental/legal/privacy-policy" target="_blank">principle.dental/legal/privacy-policy</a>
    </p>
    <p class="mat-caption">
      By attending your child's initial consultation, you are confirming that you have read and understood our
      privacy policy and policy / procedures. You also provide financial consent for all treatment we will carry out
      for your child.
    </p>
  `),
    {
      type: FormLayoutElementType.Flex,
      items: [formFlexRow([{ key: 'agreed' }, { key: 'relationshipToChild' }])],
    },
  ];
