import { roundTo2Decimals } from '@principle-theorem/accounting';
import { AccountCredit } from '@principle-theorem/principle-core';
import {
  AccountCreditType,
  IPractice,
  ITranslationMap,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  IDestinationEntityJobRunOptions,
} from '@principle-theorem/principle-core/interfaces';
import { getError, type WithRef } from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import {
  BasePatientDepositDestinationEntity,
  IDepositBuildData,
  IDepositJobData,
  IDepositMigrationData,
} from '../../../destination/entities/patient-deposits';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { PracticeIdFilter } from '../../../destination/filters/practice-id-filter';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientSourceEntity,
  type ID4WPatient,
} from '../../source/entities/patient';
import {
  PatientDepositSourceEntity,
  type ID4WPatientDeposit,
  type ID4WPatientDepositFilters,
  type ID4WPatientDepositTranslations,
} from '../../source/entities/patient-deposit';
import { D4WPracticeMappingHandler } from '../mappings/practices';
import { PatientDestinationEntity } from './patients';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';

export class PatientDepositDestinationEntity extends BasePatientDepositDestinationEntity<
  ID4WPatient,
  IDepositJobData<ID4WPatient>
> {
  override patientSourceEntity = new PatientSourceEntity();
  override filters = [
    new PracticeIdFilter<IDepositJobData<ID4WPatient>>((jobData) =>
      jobData.sourcePatient.data.data.practice_id.toString()
    ),
    new PatientIdFilter<IDepositJobData<ID4WPatient>>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    deposits: new PatientDepositSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  customMappings = {
    practices: new D4WPracticeMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IDepositJobData<ID4WPatient>[]> {
    const practices$ =
      this.customMappings.practices.getRecords$(translationMap);

    return this.buildSourceRecordQuery$(
      migration,
      this.patientSourceEntity,
      runOptions
    ).pipe(
      withLatestFrom(practices$),
      map(([sourcePatients, practices]) =>
        sourcePatients.map((sourcePatient) => ({
          sourcePatient,
          practices,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IDepositJobData<ID4WPatient>
  ): Promise<
    | IDepositMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const patientId = this.sourceEntities.patients
      .getSourceRecordId(data.sourcePatient.data.data)
      .toString();
    const patientRef = await translationMap.getDestination<IPatient>(
      patientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(data.sourcePatient, 'No patient found');
    }

    const sourceDeposits = await this.sourceEntities.deposits.filterRecords(
      migration,
      'patientId',
      patientId
    );

    try {
      const deposits = sourceDeposits.map((deposit) => {
        const depositData = this._buildDepositData(deposit, data.practices);

        if (!depositData) {
          throw new Error(`Deposit ${deposit.record.uid} has no amount`);
        }

        return {
          ...depositData,
          transactions: [],
          createdAt: deposit.data.translations.createdAt,
        };
      });

      return {
        patientRef,
        deposits: sortBy(deposits, 'uid'),
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  private _buildDepositData(
    deposit: IGetRecordResponse<
      ID4WPatientDeposit,
      ID4WPatientDepositTranslations,
      ID4WPatientDepositFilters
    >,
    practices: WithRef<ITranslationMap<IPractice>>[]
  ): IDepositBuildData | undefined {
    const used = roundTo2Decimals(
      parseFloat(deposit.data.data.deposit_used_total)
    );
    const amount = roundTo2Decimals(
      parseFloat(deposit.data.data.deposit_amount_total)
    );

    if (!amount) {
      return;
    }

    const uid = this.sourceEntities.deposits
      .getSourceRecordId(deposit.data.data)
      .toString();

    const practiceSourceId = deposit.data.data.practice_id.toString();
    const practiceMap = practices.find(
      (practice) => practice.sourceIdentifier === practiceSourceId
    );

    if (!practiceMap?.destinationIdentifier) {
      throw new Error(`No practice with id ${practiceSourceId}`);
    }

    return {
      uid,
      accountCredit: {
        ...AccountCredit.init({
          type: AccountCreditType.Deposit,
          description: `Deposit ${deposit.data.data.original_total_payment_id}`,
          amount,
          used: roundTo2Decimals(used),
          practiceRef: practiceMap.destinationIdentifier,
        }),
        createdAt: deposit.data.translations.createdAt,
        issued: deposit.data.translations.createdAt,
      },
      transactions: [],
    };
  }
}
