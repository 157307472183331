import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { toTextContent, type RawInlineNodes } from '@principle-theorem/editor';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  Automation,
  TimezoneResolver,
} from '@principle-theorem/principle-core';
import {
  AnyAutomation,
  isGeneratedTask,
  type IAutomation,
} from '@principle-theorem/principle-core/interfaces';
import { SHORT_DATE_TIME_FORMAT, toMomentTz } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'pr-appointment-automations-summary',
    templateUrl: './appointment-automations-summary.component.html',
    styleUrls: ['./appointment-automations-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentAutomationsSummaryComponent {
  trackByAutomation = TrackByFunctions.combine<IAutomation<AnyAutomation>>(
    'triggerDate',
    'type'
  );
  automations$ = new ReplaySubject<IAutomation<AnyAutomation>[]>(1);
  readonly dateFormat = SHORT_DATE_TIME_FORMAT;

  @Input()
  set automations(automations: IAutomation<AnyAutomation>[]) {
    if (automations) {
      this.automations$.next(Automation.sort(automations));
    }
  }

  getTitle(automation: IAutomation<AnyAutomation>): RawInlineNodes {
    return isGeneratedTask(automation.data)
      ? automation.data.title
      : [toTextContent(automation.data.name)];
  }

  async getTriggerDate(
    automation: IAutomation<AnyAutomation>
  ): Promise<moment.Moment | undefined> {
    if (!automation.triggerDate || !automation.practiceRef) {
      return;
    }
    const timezone = await TimezoneResolver.fromPracticeRef(
      automation.practiceRef
    );
    return toMomentTz(automation.triggerDate, timezone);
  }
}
