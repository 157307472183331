import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import {
  type IEvent,
  type IEventable,
  isEvent,
  isEventable,
} from '@principle-theorem/principle-core/interfaces';
import { toMoment } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type Moment } from 'moment-timezone';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TimezoneService } from '../../timezone.service';

type DateField = keyof Pick<IEvent, 'from' | 'to'>;

@Component({
    selector: 'pr-formatted-event-field',
    templateUrl: './formatted-event-field.component.html',
    styleUrls: ['./formatted-event-field.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormattedEventFieldComponent {
  event$ = new ReplaySubject<IEvent>(1);
  field$ = new ReplaySubject<DateField>(1);
  format$ = new ReplaySubject<string | Record<string, string>>(1);
  isDateFormat$: Observable<boolean>;
  date$: Observable<Moment>;

  @Input()
  set format(format: string | Record<string, string>) {
    if (format) {
      this.format$.next(format);
    }
  }

  @Input()
  set field(field: DateField) {
    if (field) {
      this.field$.next(field);
    }
  }

  @Input()
  set event(event: IEvent | IEventable) {
    if (!event) {
      return;
    }
    if (isEvent(event)) {
      this.event$.next(event);
      return;
    }
    if (isEventable(event)) {
      this.event$.next(event.event);
    }
  }

  constructor(private _timezone: TimezoneService) {
    this.date$ = combineLatest([this.event$, this.field$]).pipe(
      switchMap(([event, field]) =>
        this._timezone
          .forPractice(event.practice.ref)
          .applyToMoment$(toMoment(event[field]))
      )
    );
    this.isDateFormat$ = this.format$.pipe(map((format) => isString(format)));
  }
}
