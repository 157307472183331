import { BehaviorSubject, type Observable } from 'rxjs';

export class ArrayBloc<T> {
  private _items$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

  constructor(items: T[] = []) {
    this.set(items);
  }

  get items$(): Observable<T[]> {
    return this._items$.asObservable();
  }

  push(item: T): void {
    const currentItems: T[] = this._items$.value.slice();
    this._items$.next([...currentItems, item]);
  }

  set(items: T[]): void {
    this._items$.next(items);
  }
}
