import { Pipe, type PipeTransform } from '@angular/core';
import {
  type IChartedRef,
  Arch,
  type IToothRef,
  ChartableSurface,
} from '@principle-theorem/principle-core/interfaces';

@Pipe({
  name: 'chartedRefLabel',
})
export class ChartedRefLabelPipe implements PipeTransform {
  transform(chartedRef: Partial<IChartedRef>): string {
    if (chartedRef.tooth) {
      return `${chartedRef.tooth.quadrant}${chartedRef.tooth.quadrantIndex}\
        ${chartedRef.tooth.surface ?? ChartableSurface.Unscoped}`;
    }
    if (chartedRef.quadrant) {
      return `Quadrant: ${chartedRef.quadrant}`;
    }
    if (chartedRef.arch) {
      const position: string =
        chartedRef.arch === Arch.Upper ? 'Upper' : 'Lower';
      return `${position} Arch`;
    }
    if (chartedRef.multipleTeeth) {
      const teeth: string = chartedRef.multipleTeeth
        .map((tooth: IToothRef) => {
          return ` ${tooth.quadrant}${tooth.quadrantIndex}`;
        })
        .join(',');
      return `Multiple Teeth: ${teeth}`;
    }
    return 'Whole Mouth';
  }
}
