import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  type IBasePatient,
  type IPatientContactDetails,
  type IPatientRelationship,
  isPatientRelationship,
  type PatientRelationshipType,
} from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  isObject,
  toNamedDocument,
} from '@principle-theorem/shared';
import { isNil } from 'lodash';

export interface IPatientPrimaryContact {
  primaryContact?: IPatientRelationship;
}
export interface IPatientRelationships {
  relationships: IPatientRelationship[];
}

export type PrimaryContactWithRelationships = IPatientRelationships &
  IPatientPrimaryContact;

export class RelationshipFormGroup extends TypedFormGroup<IPatientRelationship> {
  constructor() {
    super({
      patient: new TypedFormControl<
        INamedDocument<IBasePatient & IPatientContactDetails>
      >(),
      type: new TypedFormControl<PatientRelationshipType>(),
    });
  }
}

export function transformRelationshipData(
  data: IPatientRelationship | undefined
): IPatientRelationship | undefined {
  if (!isPatientRelationship(data)) {
    return;
  }
  return { ...data, patient: toNamedDocument(data.patient) };
}

export function isValidPatientRelationshipFormData(
  data: unknown
): data is IPatientRelationship | undefined {
  return (
    (isObject(data) && isNil(data.patient) && isNil(data.type)) ||
    isPatientRelationship(data)
  );
}
