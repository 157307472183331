import {
  ChartableSurface,
  IChartedRef,
  IClinicalChart,
  isToothSurface,
} from '@principle-theorem/principle-core/interfaces';
import { compact, uniq } from 'lodash';
import { ClinicalChart } from './clinical-chart';
import { surfaceFromRef, toChartedRef } from './core/selected-surface';

export function toChartableSurfaces(
  chartedRefs: Partial<IChartedRef>[]
): ChartableSurface[] {
  return uniq(
    compact(chartedRefs.map((chartedRef) => surfaceFromRef(chartedRef)))
  );
}

export function toAllChartedRefs(
  surfaces: ChartableSurface[],
  chart: IClinicalChart
): Partial<IChartedRef>[] {
  return surfaces.reduce(
    (acc: Partial<IChartedRef>[], surface) => [
      ...acc,
      ...toChartedRefs(surface, chart),
    ],
    []
  );
}

export function toChartedRefs(
  surface: ChartableSurface,
  chart: IClinicalChart
): Partial<IChartedRef>[] {
  if (surface === ChartableSurface.WholeMouth) {
    return [toChartedRef({ surface })];
  }
  if (surface === ChartableSurface.Arch) {
    return ClinicalChart.getArches(chart).map((arch) =>
      toChartedRef({ surface, area: arch })
    );
  }
  if (surface === ChartableSurface.Quadrant) {
    return ClinicalChart.getQuadrants(chart).map((quadrant) =>
      toChartedRef({ surface, area: quadrant })
    );
  }
  if (surface === ChartableSurface.MultipleTeeth) {
    return [toChartedRef({ surface, teeth: chart.teeth })];
  }
  if (
    isToothSurface(surface) ||
    surface === ChartableSurface.WholeTooth ||
    surface === ChartableSurface.Crown
  ) {
    return chart.teeth.map((tooth) => toChartedRef({ surface, tooth }));
  }
  return [];
}
