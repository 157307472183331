@if (followUps$ | async; as followUps) {
  <div class="flex flex-col gap-2">
    @for (followUp of followUps; track followUp.appointment.ref.id) {
      @defer (on viewport) {
        <pr-follow-up-list-item [followUp]="followUp" [hideDate]="hideDate" />
      } @placeholder (minimum 150ms) {
        <ngx-skeleton-loader
          animation="pulse"
          count="1"
          [theme]="{ height: '50px' }"
        />
      }
    }
  </div>
}
