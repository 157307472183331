import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, type Timezone } from '@principle-theorem/shared';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { CorePracticePatientMediaCategory } from './patient-media';

export const PATIENT_MEDIA_CATEGORY_RESOURCE_TYPE = 'patientMediaCategory';

export const PATIENT_MEDIA_CATEGORY_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Media Category List',
      description: '',
      idPrefix: PATIENT_MEDIA_CATEGORY_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ICorePracticePatientMediaCategory {
  id: CorePracticePatientMediaCategory;
}

export function isCorePracticeMediaCategory(
  item: unknown
): item is ICorePracticePatientMediaCategory {
  return TypeGuard.interface<ICorePracticePatientMediaCategory>({
    id: TypeGuard.enumValue(CorePracticePatientMediaCategory),
  })(item);
}

export interface ICorePracticePatientMediaTranslations {}

export interface ICorePracticePatientMediaFilters {}

const PATIENT_MEDIA_CATEGORY_SOURCE_QUERY = `
SELECT
  CategoryId AS id
FROM tblMedia
WHERE CategoryId IS NOT NULL
GROUP BY CategoryId
`;

export class PatientMediaCategorySourceEntity extends BaseSourceEntity<ICorePracticePatientMediaCategory> {
  sourceEntity = PATIENT_MEDIA_CATEGORY_SOURCE_ENTITY;
  entityResourceType = PATIENT_MEDIA_CATEGORY_RESOURCE_TYPE;
  sourceQuery = PATIENT_MEDIA_CATEGORY_SOURCE_QUERY;
  verifySourceFn = isCorePracticeMediaCategory;

  translate(
    _data: ICorePracticePatientMediaCategory,
    _timezone: Timezone
  ): object {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientMediaCategory): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientMediaCategory): string {
    return data.id.toString();
  }

  getFilterData(
    _data: ICorePracticePatientMediaCategory,
    _timezone: Timezone
  ): object {
    return {};
  }
}
