import { ITreatmentPlan } from '../../clinical-charting/treatment/treatment-plan';
import { ITemplateContext } from '../templating';
import { IPatientScopeData, IPatientTemplateContext } from './patient-context';

export interface ITreatmentPlanScopeData extends IPatientScopeData {
  treatmentPlan: ITreatmentPlan;
}

export interface ITreatmentPlanTemplateContext
  extends ITemplateContext,
    IPatientTemplateContext {
  treatmentPlanName: string;
  treatmentPlanCreatedAt: string;
  treatmentPlanCreatedAtDate: string;
  treatmentPlanCreatedAtTime: string;
}
