<ng-template #loading>
  <mat-progress-bar mode="indeterminate" />
</ng-template>

<ng-container *ngIf="appointment$ | async as appointment; else loading">
  <ng-container *ngIf="chart$ | async as chart">
    <pr-perio-chart-ui *ngIf="isPerioChart$ | async; else clinicalChart" />
    <ng-template #clinicalChart>
      <pr-chart-ui />
    </ng-template>
  </ng-container>

  <div fxFill class="no-chart" fxLayout="column" fxLayoutAlign="center center">
    <span [ngSwitch]="missingChartReason$ | async">
      <div *ngSwitchCase="'subsequentAppointmentExists'">
        <h2 class="mat-headline-5">
          A chart has been created on a later appointment. No chart can be
          generated for this appointment.
        </h2>
        <a [routerLink]="subsequentAppointmentLink$ | async">
          Please chart on the latest version.
        </a>
      </div>

      <div *ngSwitchCase="'appointmentNotScheduled'">
        <h2 class="mat-headline-5">
          Charting isn't available until the appointment has been scheduled.
        </h2>
      </div>

      <div *ngSwitchCase="'appointmentInFuture'">
        <h2 class="mat-headline-5">
          The chart hasn't been generated due to the appointment date being in
          the future. Would you still like to generate the chart? This will lock
          any charts prior to this appointment.
        </h2>
        <button
          mat-flat-button
          color="primary"
          (click)="generateChart()"
          [disabled]="generatingChart$ | async"
        >
          Generate
        </button>
      </div>

      <div *ngSwitchCase="'appointmentInPast'">
        <h2 class="mat-headline-5">
          The appointment wasn't started on the scheduled day and is now in the
          past. Would you still like to generate the chart?
        </h2>
        <button
          mat-flat-button
          color="primary"
          (click)="generateChart()"
          [disabled]="generatingChart$ | async"
        >
          Generate
        </button>
      </div>

      <div *ngSwitchCase="'noChartGenerated'">
        <h2 class="mat-headline-5">
          This appointment doesn't have a chart associated with it. Would you
          like to generate the chart? This will lock any previous charts.
        </h2>
        <div fxLayout="row" fxLayoutGap="16px">
          <button
            mat-flat-button
            color="primary"
            (click)="generateChart()"
            [disabled]="generatingChart$ | async"
          >
            Generate
          </button>
          <button mat-flat-button color="primary" (click)="viewLatestChart()">
            View Latest Chart
          </button>
        </div>
      </div>
    </span>
  </div>
</ng-container>
