import * as dailyDashboard from './daily-dashboard-chart-configs';
import * as dailyDashboardTests from './daily-dashboard-chart-config-tests';
import * as patientFlow from './patient-flow-chart-configs';
import * as acquisitions from './acquisitions-chart-configs';
import * as practitioners from './practitioners-chart-configs';
import * as locations from './locations-chart-configs';

export * from './common-mock-values';

export class ChartConfigs {
  static dailyDashboard = dailyDashboard;
  static patientFlow = patientFlow;
  static practitioners = practitioners;
  static acquisitions = acquisitions;
  static locations = locations;
}

export class ChartConfigTests {
  static dailyDashboard = dailyDashboardTests;
  // static patientFlow = patientFlow;
  // static practitioners = practitioners;
  // static acquisitions = acquisitions;
  // static locations = locations;
}
