import { roundTo2Decimals } from '@principle-theorem/accounting';
import { type ICSVExport } from '@principle-theorem/ng-shared';
import { getTransactionProviderName } from '@principle-theorem/principle-core';
import { type IAccountCreditReportRecord } from '@principle-theorem/reporting';
import {
  DAY_MONTH_YEAR_FORMAT,
  reduceToSingleArrayFn,
  toMoment,
} from '@principle-theorem/shared';

export interface IAccountCreditCSV {
  issuedAt: string;
  patient: string;
  description: string;
  reservedFor: string;
  invoiceStatus?: string;
  invoice?: string;
  transactionType: string;
  transactionAmount: number;
  accountCreditTotal: number;
  accountCreditUsed: number;
  accountCreditRemaining: number;
}

export class AccountCreditToCSV
  implements ICSVExport<IAccountCreditReportRecord, IAccountCreditCSV>
{
  defaultFileName = 'account-credits-report';
  headers = [
    'Patient',
    'Description',
    'Reserved For',
    'Date Issued',
    'Invoice Status',
    'Invoice Reference',
    'Transaction Type',
    'Transaction Amount',
    'Account Credit Total',
    'Account Credit Used',
    'Account Credit Remaining',
  ];

  translate(records: IAccountCreditReportRecord[]): IAccountCreditCSV[] {
    return records
      .map((record) => {
        if (record.transactions.length) {
          return record.transactions.map((transaction) => ({
            patient: record.patient.name,
            description: record.accountCredit.description,
            reservedFor:
              record.accountCredit.reservedFor.practitioner?.name ?? '',
            issuedAt: toMoment(record.accountCredit.createdAt).format(
              DAY_MONTH_YEAR_FORMAT
            ),
            invoiceStatus: record.invoice?.status ?? '',
            invoice: record.invoice?.reference ?? '',
            transactionType: getTransactionProviderName(transaction),
            transactionAmount: roundTo2Decimals(transaction.amount),
            accountCreditTotal: roundTo2Decimals(record.accountCredit.amount),
            accountCreditUsed: roundTo2Decimals(record.accountCredit.used),
            accountCreditRemaining: roundTo2Decimals(
              record.accountCredit.remaining
            ),
          }));
        }

        return [
          {
            patient: record.patient.name,
            description: record.accountCredit.description,
            reservedFor:
              record.accountCredit.reservedFor.practitioner?.name ?? '',
            issuedAt: toMoment(record.accountCredit.createdAt).format(
              DAY_MONTH_YEAR_FORMAT
            ),
            invoiceStatus: record.invoice?.status ?? '',
            invoice: record.invoice?.reference ?? '',
            transactionType: '',
            transactionAmount: 0,
            accountCreditTotal: roundTo2Decimals(record.accountCredit.amount),
            accountCreditUsed: roundTo2Decimals(record.accountCredit.used),
            accountCreditRemaining: roundTo2Decimals(
              record.accountCredit.remaining
            ),
          },
        ];
      })
      .reduce(reduceToSingleArrayFn);
  }
}
