import {
  CustomMappingType,
  ISourceLinkOption,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  IBlobFilenamePair,
  XSLXImporterExporter,
  asyncForEach,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import Fuse from 'fuse.js';
import { first, sortBy, uniqBy } from 'lodash';
import { BaseCustomMappingHandler } from '../base-custom-mapping-handler';
import { CustomMapping } from '../custom-mapping';
import { IBaseMigrationAppointmentBook } from '../interfaces';
import { PracticeMigration } from '../practice-migrations';
import { BaseSourceEntity } from '../source/base-source-entity';
import { TranslationMapHandler } from '../translation-map';
import { AppointmentBookToPractitionerToXSLX } from './appointment-book-to-practitioner-to-xlsx';
import { XSLXToAppointmentBookToPractitioner } from './xlsx-to-appointment-book-to-practitioner';

export const APPOINTMENT_BOOK_TO_PRACTITIONER_CUSTOM_MAPPING_TYPE =
  'appointmentBookToPractitioner';

export const APPOINTMENT_BOOK_TO_PRACTITIONER_MAPPING = CustomMapping.init({
  metadata: {
    label: 'Appointment Book to Practitioner',
    description: `Used to map appointments to a practitioner that are a part of an appointment book where the appointment had no practitioner selected.`,
    type: APPOINTMENT_BOOK_TO_PRACTITIONER_CUSTOM_MAPPING_TYPE,
    allowManualInput: true,
  },
  type: CustomMappingType.SourceLink,
  labelOverrides: {
    sourceIdentifier: 'Id',
    sourceLabel: 'Appointment Book',
    destinationIdentifier: 'Principle Staff Member',
  },
});

export abstract class AppointmentBookToPractitionerMappingHandler<
  AppointmentBook extends object,
  SourceEntity extends BaseSourceEntity<AppointmentBook>,
> extends BaseCustomMappingHandler<IStaffer> {
  customMapping = APPOINTMENT_BOOK_TO_PRACTITIONER_MAPPING;
  abstract sourceEntity: SourceEntity;

  abstract translateFn: (
    record: AppointmentBook
  ) => IBaseMigrationAppointmentBook;

  abstract getSourceLinkOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ISourceLinkOption[]>;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 1000)
      .toPromise();
    const options = records
      .map((record) => record.data.data)
      .map((record) => {
        const appointmentBook = this.translateFn(record);
        return {
          label: appointmentBook.description,
          value: this.sourceEntity.getSourceRecordId(record).toString(),
        };
      });

    return sortBy(options, 'label');
  }

  async autocompleteMappings(
    migration: WithRef<IPracticeMigration>
  ): Promise<void> {
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const sourceOptions = await this.getSourceOptions(migration);
    const sourceLinkOptions = await this.getSourceLinkOptions(migration);
    const records = await this.getRecords(translationMap);

    const mappedAppointmentBooks = records
      .filter((record) => !!record.destinationIdentifier)
      .map((record) => record.sourceIdentifier);
    const unmappedAppointmentBooks = sourceOptions.filter(
      (option) => !mappedAppointmentBooks.includes(option.value)
    );

    await asyncForEach(unmappedAppointmentBooks, async (item) => {
      const fuse = new Fuse(sourceLinkOptions, {
        keys: ['label'],
        includeScore: true,
        threshold: 0.3,
      });
      const matches = fuse.search(item.label);
      const match = first(matches)?.item;

      if (!match) {
        return;
      }

      await this.upsertRecord(
        {
          sourceLink: match,
          sourceIdentifier: item.label,
          sourceLabel: item.value,
        },
        translationMap
      );
    });
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, appointmentBooks, translator } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(
      fileName,
      appointmentBooks,
      translator
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, appointmentBooks, translator } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(
      fileName,
      appointmentBooks,
      translator
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToAppointmentBookToPractitioner()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const sourceOptions = await this.getSourceOptions(migration);
    const staff = await this.getSourceLinkOptions(migration);

    await asyncForEach(items, async (item) => {
      if (!item.mapTo) {
        return;
      }

      const matchingOption = sourceOptions.find(
        (sourceOption) => sourceOption.value === item.id
      );

      const label = matchingOption?.label || item.name;
      const value = matchingOption?.value || item.id;

      const staffer = staff.find(
        (searchStaffer) => searchStaffer.sourceLabel === item.mapTo
      );

      if (!staffer) {
        // eslint-disable-next-line no-console
        console.error(
          `Mapping error: ${this.customMapping.metadata.label} - Couldn't find staffer for item`,
          item
        );
        return;
      }

      await this.upsertRecord(
        {
          sourceLink: staffer,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    appointmentBooks: IBaseMigrationAppointmentBook[];
    translator: AppointmentBookToPractitionerToXSLX;
  }> {
    const fileName = this.getFileName();
    const appointmentBooks = await this._getAppointmentBookOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const translator = new AppointmentBookToPractitionerToXSLX(
      await this.getSourceLinkOptions(migration),
      await this.getRecords(translationMap)
    );
    return { fileName, appointmentBooks, translator };
  }

  private async _getAppointmentBookOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<IBaseMigrationAppointmentBook[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 10000)
      .toPromise();
    return sortBy(
      uniqBy(
        records.map((record) => this.translateFn(record.data.data)),
        (record) => record.id
      ),
      'description'
    );
  }
}
