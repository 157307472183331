import * as moment from 'moment-timezone';
import {
  MockTotalNumberOfCancelledAppointmentsStatistic,
  MockNewPatientsStatistic,
  MockActivePatientsStatistic,
  MockInactivePatientsStatistic,
  MockCostPerConversionStatistic,
  MockMeanTreatmentPlanValueStatistic,
  MockNextVisitsBookedStatistic,
  MockNoNextAppointmentStatistic,
  MockGrossIncomeStatistic,
  MockTreatmentPlanAcceptanceRateStatistic,
  MockTotalExpensesStatistic,
} from './base-statistics';
import {
  type IReferrerStats,
  type IStatistics,
} from '@principle-theorem/reporting';

export class ReferrerStatistics implements IStatistics<IReferrerStats> {
  date: moment.Moment = moment();
  name = '';
  stats: IReferrerStats = {
    newPatients: MockNewPatientsStatistic(),
    activePatients: MockActivePatientsStatistic(),
    inactivePatients: MockInactivePatientsStatistic(),
    caseAcceptanceRate: MockTreatmentPlanAcceptanceRateStatistic(),
    totalRevenue: MockGrossIncomeStatistic(),
    nextVisitsBooked: MockNextVisitsBookedStatistic(),
    noNextAppointment: MockNoNextAppointmentStatistic(),
    cancellations: MockTotalNumberOfCancelledAppointmentsStatistic(),
    advertisingCosts: MockTotalExpensesStatistic(),
    costPerConversion: MockCostPerConversionStatistic(),
    meanTreatmentPlanValue: MockMeanTreatmentPlanValueStatistic(),
  };
}
