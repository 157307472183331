import {
  CustomMappingType,
  IPracticeMigration,
  ISourceLinkOption,
} from '@principle-theorem/principle-core/interfaces';
import { IReffable } from '@principle-theorem/shared';
import { CustomMapping } from '../../../custom-mapping';
import { IBaseMigrationAppointmentBook } from '../../../interfaces';
import { AppointmentBookToPractitionerMappingHandler } from '../../../mappings/appointment-book-to-practitioner';
import {
  AppointmentRoomSourceEntity,
  IExactAppointmentRoom,
} from '../../source/entities/appointment-room';
import { ExactStafferMappingHandler } from './staff';

export const APPOINTMENT_ROOM_TO_PRACTITIONER_CUSTOM_MAPPING_TYPE =
  'appointmentRoomToPractitioner';

export const APPOINTMENT_ROOM_TO_PRACTITIONER_MAPPING = CustomMapping.init({
  metadata: {
    label: 'Appointment Room to Practitioner',
    description: `
        Used to map appointments to a practitioner where the appointment has a room id of a different practitioner.
      `,
    type: APPOINTMENT_ROOM_TO_PRACTITIONER_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.DocumentReference,
  labelOverrides: {
    sourceIdentifier: 'Id',
    sourceLabel: 'Appointment Room',
    destinationIdentifier: 'Practitioner',
  },
});

export class AppointmentRoomToPractionerMappingHandler extends AppointmentBookToPractitionerMappingHandler<
  IExactAppointmentRoom,
  AppointmentRoomSourceEntity
> {
  override customMapping = APPOINTMENT_ROOM_TO_PRACTITIONER_MAPPING;
  sourceEntity = new AppointmentRoomSourceEntity();
  translateFn = (
    record: IExactAppointmentRoom
  ): IBaseMigrationAppointmentBook => ({
    id: record.id.toString(),
    description: record.name,
  });

  override async getSourceLinkOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ISourceLinkOption[]> {
    const handler = new ExactStafferMappingHandler();
    const options = await handler.getSourceOptions(migration);

    const omitOption = {
      value: '0',
      label: 'Omit',
    };
    return [omitOption, ...options].map((option) => ({
      sourceIdentifier: option.value,
      sourceLabel: option.label,
      resourceType: handler.sourceEntity.sourceEntity.metadata.idPrefix,
    }));
  }
}
