import {
  CLAIM_LEVEL_HICAPS_CONNECT_RESPONSE_CODE_MAP,
  CLAIM_LEVEL_HICAPS_CONNECT_RESPONSE_SUCCESS_CODES,
  IResponseCodeInfo,
  ITEM_LEVEL_HICAPS_CONNECT_RESPONSE_CODE_MAP,
} from '@principle-theorem/hicaps-connect';

export class HicapsConnectResponseCodes {
  static findResponseCode(responseCode: string): IResponseCodeInfo | undefined {
    return CLAIM_LEVEL_HICAPS_CONNECT_RESPONSE_CODE_MAP[responseCode];
  }

  static isClaimSuccessful(responseCode: string): boolean {
    return CLAIM_LEVEL_HICAPS_CONNECT_RESPONSE_SUCCESS_CODES.includes(
      responseCode
    );
  }

  static findServiceItemResponseCode(
    responseCode: string
  ): IResponseCodeInfo | undefined {
    return ITEM_LEVEL_HICAPS_CONNECT_RESPONSE_CODE_MAP[responseCode];
  }
}
