<div class="box-border flex h-screen min-h-full w-full flex-1">
  <div
    class="h-full min-h-full basis-1/5 border-r-2 border-solid border-gray-300 print:hidden"
  >
    <h2 class="!m-0 p-4 hover:cursor-pointer" routerLink="../forms">Forms</h2>
    <mat-nav-list dense>
      @for (link of navLinks; track link.path) {
        <div class="flex items-center">
          <mat-list-item [routerLink]="link.path" routerLinkActive="selected">
            <a matListItemTitle>
              {{ link.label }}
            </a>
          </mat-list-item>
          <button
            *ngIf="link.action"
            mat-icon-button
            color="primary"
            matTooltip="Copy Patient Link"
            (click)="link.action()"
          >
            <mat-icon>link</mat-icon>
          </button>
        </div>
      }
    </mat-nav-list>
  </div>
  <div class="layout-margin flex-1">
    <router-outlet />
  </div>
</div>
