/* eslint-disable no-null/no-null */
import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard } from '@principle-theorem/shared';
import { flow, isBoolean, isNumber } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';

export const PATIENT_RELATIONSHIP_RESOURCE_TYPE = 'patientRelationship';

export const PATIENT_RELATIONSHIP_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Relationship List',
      description: '',
      idPrefix: PATIENT_RELATIONSHIP_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export enum PraktikaPatientRelationshipTypeLabels {
  Self = 'Self',
  Spouse = 'Spouse',
  Partner = 'Partner',
  FosterChild = 'Foster child',
  StepChild = 'Step child',
  Grandchild = 'Grandchild',
  Grandparent = 'Grandparent',
  FosterParent = 'Foster parent',
  Dependent = 'Dependent',
  Carer = 'Carer',
  Employee = 'Employee',
  Beneficiary = 'Beneficiary',
  Parent = 'Parent',
  StepParent = 'Step parent',
  Child = 'Child',
  InsuredPlaintiff = 'Insured Plaintiff',
  Sibling = 'Sibling',
  Unknown = 'Unknown',
}

export enum PraktikaPatientRelationshipTypes {
  Self = 1,
  Spouse = 2,
  Partner = 3,
  Parent = 4,
  Child = 5,
  FosterChild = 6,
  StepChild = 7,
  Grandchild = 8,
  StepParent = 9,
  Grandparent = 10,
  FosterParent = 11,
  Dependent = 12,
  Carer = 13,
  Employee = 15,
  Beneficiary = 16,
  InsuredPlaintiff = 17,
  Sibling = 18,
  Unknown = 19,
}

export const PRAKTIKA_PATIENT_RELATIONSHIP_TYPES: Record<
  PraktikaPatientRelationshipTypeLabels,
  number
> = {
  [PraktikaPatientRelationshipTypeLabels.Self]: 1,
  [PraktikaPatientRelationshipTypeLabels.Spouse]: 2,
  [PraktikaPatientRelationshipTypeLabels.Partner]: 3,
  [PraktikaPatientRelationshipTypeLabels.FosterChild]: 6,
  [PraktikaPatientRelationshipTypeLabels.StepChild]: 7,
  [PraktikaPatientRelationshipTypeLabels.Grandchild]: 8,
  [PraktikaPatientRelationshipTypeLabels.Grandparent]: 10,
  [PraktikaPatientRelationshipTypeLabels.FosterParent]: 11,
  [PraktikaPatientRelationshipTypeLabels.Dependent]: 12,
  [PraktikaPatientRelationshipTypeLabels.Carer]: 13,
  [PraktikaPatientRelationshipTypeLabels.Employee]: 15,
  [PraktikaPatientRelationshipTypeLabels.Beneficiary]: 16,
  [PraktikaPatientRelationshipTypeLabels.Parent]: 4,
  [PraktikaPatientRelationshipTypeLabels.StepParent]: 9,
  [PraktikaPatientRelationshipTypeLabels.Child]: 5,
  [PraktikaPatientRelationshipTypeLabels.InsuredPlaintiff]: 17,
  [PraktikaPatientRelationshipTypeLabels.Sibling]: 18,
  [PraktikaPatientRelationshipTypeLabels.Unknown]: 19,
};

export const patientRelationshipTypes: IPraktikaPatientRelationshipType[] = [
  {
    id: 1,
    name: PraktikaPatientRelationshipTypeLabels.Self,
    description: null,
    isPersonal: true,
    reverseId: 1,
  },
  {
    id: 2,
    name: PraktikaPatientRelationshipTypeLabels.Spouse,
    description: null,
    isPersonal: true,
    reverseId: 2,
  },
  {
    id: 3,
    name: PraktikaPatientRelationshipTypeLabels.Partner,
    description: null,
    isPersonal: true,
    reverseId: 3,
  },
  {
    id: 6,
    name: PraktikaPatientRelationshipTypeLabels.FosterChild,
    description: null,
    isPersonal: true,
    reverseId: 11,
  },
  {
    id: 7,
    name: PraktikaPatientRelationshipTypeLabels.StepChild,
    description: null,
    isPersonal: true,
    reverseId: 9,
  },
  {
    id: 8,
    name: PraktikaPatientRelationshipTypeLabels.Grandchild,
    description: null,
    isPersonal: true,
    reverseId: 10,
  },
  {
    id: 10,
    name: PraktikaPatientRelationshipTypeLabels.Grandparent,
    description: null,
    isPersonal: true,
    reverseId: 8,
  },
  {
    id: 11,
    name: PraktikaPatientRelationshipTypeLabels.FosterParent,
    description: null,
    isPersonal: true,
    reverseId: 6,
  },
  {
    id: 12,
    name: PraktikaPatientRelationshipTypeLabels.Dependent,
    description: null,
    isPersonal: true,
    reverseId: 13,
  },
  {
    id: 13,
    name: PraktikaPatientRelationshipTypeLabels.Carer,
    description: null,
    isPersonal: true,
    reverseId: 12,
  },
  {
    id: 15,
    name: PraktikaPatientRelationshipTypeLabels.Employee,
    description: null,
    isPersonal: false,
    reverseId: 0,
  },
  {
    id: 16,
    name: PraktikaPatientRelationshipTypeLabels.Beneficiary,
    description: null,
    isPersonal: false,
    reverseId: 0,
  },
  {
    id: 30,
    name: PraktikaPatientRelationshipTypeLabels.Unknown,
    description: null,
    isPersonal: false,
    reverseId: 0,
  },
  {
    id: 4,
    name: PraktikaPatientRelationshipTypeLabels.Parent,
    description: null,
    isPersonal: true,
    reverseId: 5,
  },
  {
    id: 9,
    name: PraktikaPatientRelationshipTypeLabels.StepParent,
    description: null,
    isPersonal: true,
    reverseId: 7,
  },
  {
    id: 5,
    name: PraktikaPatientRelationshipTypeLabels.Child,
    description: null,
    isPersonal: true,
    reverseId: 4,
  },
  {
    id: 17,
    name: PraktikaPatientRelationshipTypeLabels.InsuredPlaintiff,
    description: null,
    isPersonal: false,
    reverseId: 0,
  },
  {
    id: 18,
    name: PraktikaPatientRelationshipTypeLabels.Sibling,
    description: '',
    isPersonal: true,
    reverseId: 18,
  },
  {
    id: 19,
    name: PraktikaPatientRelationshipTypeLabels.Unknown,
    description: null,
    isPersonal: true,
    reverseId: 19,
  },
];

export interface IPraktikaPatientRelationshipType {
  id: number;
  name: string;
  description: null | string;
  isPersonal: boolean;
  reverseId: number; // Points to relationshipType that this relationship complements
}

export interface IPraktikaPatientRelationship {
  patient_id: number;
  is_person: boolean;
  person_id: number | null;
  organisation_id: number | null;
  relationship_type_id: PraktikaPatientRelationshipTypes;
  is_default: boolean;

  // name: string;
  // relationship: PraktikaPatientRelationshipTypeLabels;
}

export interface IPraktikaPatientRelationshipFilters {
  patientId: string;
}

export function isPraktikaPatientRelationship(
  item: unknown
): item is IPraktikaPatientRelationship {
  return TypeGuard.interface<IPraktikaPatientRelationship>({
    patient_id: isNumber,
    is_person: isBoolean,
    person_id: TypeGuard.nilOr(isNumber),
    organisation_id: TypeGuard.nilOr(isNumber),
    relationship_type_id: TypeGuard.enumValue(PraktikaPatientRelationshipTypes),
    is_default: isBoolean,
  })(item);
}

const PATIENT_RELATIONSHIP_SOURCE_QUERY = `
SELECT
  iPatientNumber as patient_id,
  bPerson as is_person,
  iGuarantorPersonPatientNumber as person_id,
  convert_to_integer(iGuarantorOrganisationId) as organisation_id,
  iRelationToPatientId as relationship_type_id,
  bDefaultGuarantor as is_default
FROM patient_guarantors
ORDER BY patient_id
`;

export class PatientRelationshipSourceEntity extends BaseSourceEntity<
  IPraktikaPatientRelationship,
  unknown,
  IPraktikaPatientRelationshipFilters
> {
  sourceEntity = PATIENT_RELATIONSHIP_SOURCE_ENTITY;
  entityResourceType = PATIENT_RELATIONSHIP_RESOURCE_TYPE;
  sourceQuery = PATIENT_RELATIONSHIP_SOURCE_QUERY;
  verifySourceFn = isPraktikaPatientRelationship;
  override transformDataFn = flow([]);

  getSourceRecordId(data: IPraktikaPatientRelationship): string {
    return `${data.patient_id.toString()}.${data.person_id?.toString() ?? ''}.${
      data.relationship_type_id
    }`;
  }

  getSourceLabel(data: IPraktikaPatientRelationship): string {
    return this.getSourceRecordId(data);
  }

  translate(_initialValue: IPraktikaPatientRelationship): unknown {
    return {};
  }

  getFilterData(
    data: IPraktikaPatientRelationship
  ): IPraktikaPatientRelationshipFilters {
    const patientId = data.patient_id.toString();
    return {
      patientId,
    };
  }
}
