import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timestamp,
  TypeGuard,
  toFloat,
  toTimestamp,
  type Timezone,
  ISO_DATE_TIME_FORMAT,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  convertValueFn,
} from '../../../source/source-helpers';
import { PATIENT_DEPOSIT_RESOURCE_TYPE } from '../../../destination/entities/patient-deposits';

export const PATIENT_PREPAYMENT_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Prepayment List',
      description: '',
      idPrefix: PATIENT_DEPOSIT_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ICorePracticePatientPrepayment {
  id: number;
  prepaymentDate: string; // 2023-07-01 03:35:48.480
  note: string; // "prepayment for splint"
  total: number; // 1000.0000
  paid: number; // 1000.0000
  remainingCredit?: number; // 750.0000
  isPaid: boolean;
  isVoided: boolean;
  isDeleted: boolean;
  locationId: number;
  providerId: number;
  patientId: number;
  payeeId: number;
}

export function isCorePracticePatientPrepayment(
  item: unknown
): item is ICorePracticePatientPrepayment {
  return TypeGuard.interface<ICorePracticePatientPrepayment>({
    id: isNumber,
    prepaymentDate: isString,
    note: isString,
    total: isNumber,
    paid: isNumber,
    remainingCredit: TypeGuard.nilOr(isNumber),
    isPaid: isBoolean,
    isVoided: isBoolean,
    isDeleted: isBoolean,
    locationId: isNumber,
    providerId: isNumber,
    patientId: isNumber,
    payeeId: isNumber,
  })(item);
}

export interface ICorePracticePatientPrepaymentTranslations {
  prepaymentDate: Timestamp;
}

export interface ICorePracticePatientPrepaymentFilters {
  locationId: number;
  providerId: number;
  patientId: number;
  payeeId: number;
}

const PATIENT_PREPAYMENT_SOURCE_QUERY = `
SELECT
  PrepaymentId AS id,
  PrepaymentDate AS prepayment_date,
  Note AS note,
  convert_to_decimal(Total) AS total,
  convert_to_decimal(Paid) AS paid,
  convert_to_decimal(RemainingCredit) AS remaining_credit,
  convert_to_boolean(IsPaid) AS is_paid,
  convert_to_boolean(IsVoided) AS is_voided,
  convert_to_boolean(IsDeleted) AS is_deleted,
  LocationId AS location_id,
  ProviderId AS provider_id,
  PatientId AS patient_id,
  PayeeId AS payee_id
FROM tblPrepayment
`;

export class PatientPrepaymentSourceEntity extends BaseSourceEntity<
  ICorePracticePatientPrepayment,
  ICorePracticePatientPrepaymentTranslations,
  ICorePracticePatientPrepaymentFilters
> {
  sourceEntity = PATIENT_PREPAYMENT_SOURCE_ENTITY;
  entityResourceType = PATIENT_DEPOSIT_RESOURCE_TYPE;
  sourceQuery = PATIENT_PREPAYMENT_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientPrepayment;

  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertValueFn(toFloat, 'total', 'paid', 'remainingCredit'),
  ]);

  translate(
    data: ICorePracticePatientPrepayment,
    _timezone: Timezone
  ): ICorePracticePatientPrepaymentTranslations {
    return {
      prepaymentDate: toTimestamp(
        moment.utc(data.prepaymentDate, ISO_DATE_TIME_FORMAT)
      ),
    };
  }

  getSourceRecordId(data: ICorePracticePatientPrepayment): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientPrepayment): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientPrepayment,
    _timezone: Timezone
  ): ICorePracticePatientPrepaymentFilters {
    return {
      locationId: data.locationId,
      providerId: data.providerId,
      patientId: data.patientId,
      payeeId: data.payeeId,
    };
  }
}
