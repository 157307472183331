import { injectScript } from '@principle-theorem/ng-shared';
import {
  getIClientWithUIUrl,
  type ITyroNamespace,
  type TyroIClientUrl,
} from '@principle-theorem/tyro';
import { isString } from 'lodash';

declare const TYRO: ITyroNamespace;

export async function loadTyroIClient(
  tyroIClientUrl: TyroIClientUrl
): Promise<ITyroNamespace> {
  try {
    await injectScript(
      'tyro-iclient',
      getIClientWithUIUrl(tyroIClientUrl),
      'text/javascript',
      true,
      true
    );
  } catch (e) {
    if (isString(e)) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }
  return TYRO;
}
