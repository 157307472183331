import { roundTo2Decimals } from '@principle-theorem/accounting';
import {
  HicapsConnect,
  HicapsConnectExtended,
  IPMSHicapsConnectConfig,
  PrincipleHicapsConnect,
} from '@principle-theorem/hicaps-connect';
import {
  MedicareValidation,
  resolveClaimItems,
  toClaimableItems,
  toSingleClaimItems,
} from '@principle-theorem/principle-core';
import {
  IADACodeClaim,
  IBulkBillDialogResponse,
  IClaimProviderData,
  IHealthcareClaim,
  IHicapsConnectTerminal,
  IInvoice,
  IMedicareCard,
  IPatientClaimDialogResponse,
  IReferralFormData,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, toMoment } from '@principle-theorem/shared';
import { sum, trimStart } from 'lodash';
import { Moment } from 'moment-timezone';
import {
  filterClaimableItems,
  lineItemToClaim,
} from '../../transaction-components/medicare-components/select-claim-items/select-claim-items.component';
import { TransactionProviderError } from '../../transaction-provider';
import { HicapsConnectHelpers } from '../hicaps-connect-helpers';

export interface IMedicareClaimData {
  request: HicapsConnect.MedicareClaimRequest;
  extendedData: HicapsConnectExtended.SendMedicareClaimRequest;
}

type MedicarePatientData = Pick<
  HicapsConnect.MedicareClaimRequest,
  | 'PatientMedicareCardNum'
  | 'PatientIRN'
  | 'ClaimantMedicareCardNum'
  | 'ClaimantIRN'
>;

type MedicareReferralData = Pick<
  HicapsConnect.MedicareClaimRequest,
  | 'ReferralPeriodTypeCde'
  | 'ReferralOverrideTypeCde'
  | 'ReferralIssueDate'
  | 'ReferringProviderNum'
>;

type MedicareProviderData = Pick<
  HicapsConnect.MedicareClaimRequest,
  'ServicingProviderNum' | 'PayeeProviderNum'
>;

export class HicapsConnectMedicareRequestBuilder {
  static buildBulkBill(
    config: IPMSHicapsConnectConfig,
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim,
    terminal: WithRef<IHicapsConnectTerminal>,
    formData: IBulkBillDialogResponse,
    serviceDate: Moment
  ): IMedicareClaimData | undefined {
    return this.buildMedicareClaim(
      config,
      HicapsConnect.ClaimTypeCde.BulkBillClaim,
      invoice,
      claim,
      terminal,
      formData.itemClaims,
      formData.medicareCard,
      formData.referralData,
      serviceDate
    );
  }

  static buildPartPaid(
    config: IPMSHicapsConnectConfig,
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim,
    terminal: WithRef<IHicapsConnectTerminal>,
    formData: IBulkBillDialogResponse,
    serviceDate: Moment
  ): IMedicareClaimData | undefined {
    return this.buildMedicareClaim(
      config,
      HicapsConnect.ClaimTypeCde.PartPaid,
      invoice,
      claim,
      terminal,
      formData.itemClaims,
      formData.medicareCard,
      formData.referralData,
      serviceDate
    );
  }

  static buildFullyPaid(
    config: IPMSHicapsConnectConfig,
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim,
    terminal: WithRef<IHicapsConnectTerminal>,
    formData: IPatientClaimDialogResponse,
    serviceDate: Moment
  ): IMedicareClaimData | undefined {
    const claimItems = toSingleClaimItems(
      toClaimableItems(resolveClaimItems(invoice, claim))
    ).map((item) => lineItemToClaim(item.serviceCode));

    return this.buildMedicareClaim(
      config,
      HicapsConnect.ClaimTypeCde.FullyPaid,
      invoice,
      claim,
      terminal,
      claimItems,
      formData.medicareCard,
      formData.referralData,
      serviceDate
    );
  }

  static buildUnPaid(
    config: IPMSHicapsConnectConfig,
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim,
    terminal: WithRef<IHicapsConnectTerminal>,
    formData: IPatientClaimDialogResponse,
    serviceDate: Moment
  ): IMedicareClaimData | undefined {
    const claimItems = toSingleClaimItems(
      toClaimableItems(resolveClaimItems(invoice, claim))
    ).map((item) => lineItemToClaim(item.serviceCode));

    return this.buildMedicareClaim(
      config,
      HicapsConnect.ClaimTypeCde.UnPaid,
      invoice,
      claim,
      terminal,
      claimItems,
      formData.medicareCard,
      formData.referralData,
      serviceDate
    );
  }

  static buildMedicareClaim(
    config: IPMSHicapsConnectConfig,
    claimType: HicapsConnect.ClaimTypeCde,
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim,
    terminal: WithRef<IHicapsConnectTerminal>,
    items: IADACodeClaim[],
    medicareCard: IMedicareCard,
    referralData: IReferralFormData,
    serviceDate: Moment
  ): IMedicareClaimData | undefined {
    if (!claim.providerData) {
      return;
    }

    const services = filterClaimableItems(items);
    const claimItems = toSingleClaimItems(
      toClaimableItems(resolveClaimItems(invoice, claim))
    );
    const total = sum(claimItems.map((item) => item.serviceCode.amount));

    const request: HicapsConnect.MedicareClaimRequest = {
      ...HicapsConnectHelpers.buildBaseRequest(config, terminal),
      ClaimType: claimType,
      ServiceTypeCde: HicapsConnect.ServiceTypeCde.Specialist,
      ...this.getMedicareProviderDetails(claim.providerData),
      ...this.getMedicarePatientDetails(medicareCard),
      ...this.getMedicareReferralData(referralData),
      CevRequestInd:
        claimType === HicapsConnect.ClaimTypeCde.BulkBillClaim ? 'Y' : 'N',
      AccountReferenceId: `${invoice.reference}_${claim.uid}`,
      TransactionAmount: roundTo2Decimals(total),
    };

    const addMediClaimLine = services.map((item) =>
      this.getMedicareLineItem(claimType, serviceDate, item)
    );

    const extendedData: HicapsConnectExtended.SendMedicareClaimRequest = {
      addMediClaimLine,
    };

    return { request, extendedData };
  }

  static getMedicareLineItem(
    claimType: HicapsConnect.ClaimTypeCde,
    serviceDate: Moment,
    item: IADACodeClaim
  ): HicapsConnectExtended.AddMediClaimLine {
    const bulkBillItem: HicapsConnectExtended.AddMediClaimLine = {
      itemNum: item.lineItem.code,
      dateOfService: PrincipleHicapsConnect.toDateString(serviceDate),
      chargeAmount: 0,
      contribPatientAmount: 0,
    };

    switch (claimType) {
      case HicapsConnect.ClaimTypeCde.FullyPaid:
        return {
          ...bulkBillItem,
          chargeAmount: item.lineItem.amount,
          contribPatientAmount: item.lineItem.amount,
        };
      case HicapsConnect.ClaimTypeCde.PartPaid:
        return {
          ...bulkBillItem,
          chargeAmount: item.lineItem.amount,
          contribPatientAmount: item.amount,
        };
      case HicapsConnect.ClaimTypeCde.UnPaid:
        return {
          ...bulkBillItem,
          chargeAmount: item.lineItem.amount,
          contribPatientAmount: 0,
        };
      default:
        return bulkBillItem;
    }
  }

  static getMedicareReferralData(
    referralData: IReferralFormData
  ): MedicareReferralData {
    if (!referralData.hasReferral) {
      return {
        ReferralOverrideTypeCde:
          referralData.noReferral.referralOverrideTypeCde,
      };
    }
    if (!referralData.referral.dateOfIssue) {
      throw new TransactionProviderError(`Missing Referral Date`);
    }
    if (
      !PrincipleHicapsConnect.isReferralPeriodType(
        referralData.referral.periodTypeCde
      )
    ) {
      throw new TransactionProviderError(`Invlaid Period Type Cde`);
    }
    return {
      ReferringProviderNum: referralData.referral.providerNum,
      ReferralPeriodTypeCde: referralData.referral.periodTypeCde,
      ReferralIssueDate: PrincipleHicapsConnect.toDateString(
        toMoment(referralData.referral.dateOfIssue)
      ),
    };
  }

  static getMedicarePatientDetails(
    medicareCard: IMedicareCard
  ): MedicarePatientData {
    if (
      !MedicareValidation.isValidMemberNumber(medicareCard.number) ||
      !MedicareValidation.isValidMemberNumber(medicareCard.subNumerate)
    ) {
      throw new TransactionProviderError('Invalid Medicare Card Details');
    }
    const medicarePatientDetails = {
      ClaimantMedicareCardNum: medicareCard.number,
      ClaimantIRN: trimStart(medicareCard.subNumerate, '0'),
      PatientMedicareCardNum: medicareCard.number,
      PatientIRN: trimStart(medicareCard.subNumerate, '0'),
    };
    return medicarePatientDetails;
  }

  static getMedicareProviderDetails(
    claimProviderData: IClaimProviderData
  ): MedicareProviderData {
    const medicareProviderData: MedicareProviderData = {
      ServicingProviderNum: claimProviderData.providerNumber,
    };
    if (claimProviderData.payeeProviderNumber) {
      medicareProviderData.PayeeProviderNum =
        claimProviderData.payeeProviderNumber;
    }
    return medicareProviderData;
  }
}
