<ng-container
  *ngFor="
    let treatment of filter.treatments$ | async;
    trackBy: trackByTreatment
  "
>
  <pr-charted-item-card-v2
    #card="prChartedItemCardV2"
    class="charted-item-card"
    [item]="treatment"
    [selectedSurfaces]="selectedSurfaces$ | async"
    [disabled]="disabled"
    (itemChange)="treatmentChange($event)"
    (itemDelete)="deleteTreatment($event)"
    (updateChartable)="updateChartable.emit($event)"
  >
    <ng-container *ngIf="card.collapsible?.expanded">
      <pr-service-item-smart-group-list
        [chartedSurfaces]="treatment.chartedSurfaces"
        [groups]="treatment.serviceCodeSmartGroups"
        [disabled]="disabled"
        (groupsChange)="updateSmartGroups($event, treatment)"
      />

      <pr-service-item-exclusive-group-list
        [scope]="treatment.scopeRef"
        [chartedSurfaces]="treatment.chartedSurfaces"
        [groups]="treatment.serviceCodeGroups"
        [disabled]="disabled"
        (groupsChange)="updateExclusiveGroups($event, treatment)"
      />

      <pr-service-item-list
        *ngIf="context$ | async as context"
        [chartedItem]="treatment"
        [stepTreatments]="[treatment]"
        [serviceCodes]="treatment.serviceCodes"
        [disabled]="disabled"
        [feeSchedule]="treatment.feeSchedule"
        (itemsChange)="updateServiceCodes($event, treatment)"
      />

      <pr-charted-item-notes-list [item]="treatment" />
    </ng-container>
  </pr-charted-item-card-v2>
</ng-container>

<ng-container
  *ngFor="
    let multiTreatment of filter.multiTreatments$ | async;
    trackBy: trackByMultiTreatment
  "
>
  <pr-charted-item-card-v2
    #card="prChartedItemCardV2"
    class="charted-item-card"
    [item]="multiTreatment"
    [disabled]="disabled"
    [selectedSurfaces]="selectedSurfaces$ | async"
    (itemChange)="updateMultiTreatment($event)"
    (itemDelete)="deleteMultiTreatment($event)"
    (updateChartable)="updateChartable.emit($event)"
  >
    <ng-container *ngIf="card.collapsible?.expanded">
      <pr-charted-item-notes-list [item]="multiTreatment" />

      <pr-multi-step-treatment
        [steps]="multiTreatment.steps"
        [plan]="multiTreatment"
        [disabled]="disabled"
        (stepChange)="updateMultiTreatmentStep($event, multiTreatment)"
        (stepDelete)="removeMultiTreatmentStep($event, multiTreatment)"
        (stepAdd)="addMultiTreatmentStep(multiTreatment)"
        (updateChartable)="updateChartable.emit($event)"
      />
    </ng-container>
  </pr-charted-item-card-v2>
</ng-container>
