@for (message of messages$ | async; track message.ref.id) {
  @if (message | map: isMessage) {
    <div
      #messageElement
      [ngClass]="{
        inbound: (message | map: isMessageAuthor : this | async) === false,
        outbound: (message | map: isMessageAuthor : this | async)
      }"
    >
      <div
        class="message flex max-w-full items-center gap-4"
        [ngClass]="{
          'flex-row-reverse': (message | map: isMessageAuthor : this | async)
        }"
      >
        <pt-user-icon
          *ngIf="(message | map: isMessageAuthor : this | async) === false"
          [name]="message | map: getAuthorName$ : this | async"
          [src]="message | map: getAuthorImage$ : this | async"
          [diameter]="32"
        />
        <div
          class="group/item flex min-w-0 items-center gap-4"
          [ngClass]="{
            'flex-row-reverse': (message | map: isMessageAuthor : this | async)
          }"
        >
          <div class="message-body relative flex min-w-0 flex-col items-end">
            <pr-content-view [content]="message.content" />
            <div
              class="flex"
              [ngClass]="{
                'justify-end': (message | map: isMessageAuthor : this | async)
              }"
            >
              <small class="message-time">
                <span
                  [matTooltip]="
                    message.createdAt | moment | amDateFormat: dateFormat
                  "
                  >{{ message.createdAt | moment | amTimeAgo }}</span
                >
              </small>
            </div>

            @if (message | map: getReactions; as reactions) {
              <pr-chat-message-reactions
                *ngIf="reactions.length"
                class="absolute"
                [ngClass]="{
                  'left-2':
                    (message | map: isMessageAuthor : this | async) === false,
                  'right-2': (message | map: isMessageAuthor : this | async)
                }"
                [reactions]="reactions"
              />
            }
          </div>

          <div
            class="group/edit actions invisible mr-auto flex bg-white group-hover/item:visible"
          >
            <div #reactionButton>
              <button
                class="icon-sm opacity-50"
                mat-icon-button
                matTooltip="Add Reaction"
                matTooltipShowDelay="300"
                (click)="addReaction(message, reactionButton)"
              >
                <mat-icon>add_reaction</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  } @else {
    <div
      #messageElement
      class="interaction -my-2 flex flex-col items-center justify-center"
    >
      <div class="flex flex-col gap-2">
        <pr-content-view [content]="message | map: getInteractionTitle" />
        <div *ngIf="message | map: hasContent" class="notes">
          <pr-content-view [content]="message.content" />
        </div>
      </div>
      <small class="message-time text-center">
        <span
          [matTooltip]="message.createdAt | moment | amDateFormat: dateFormat"
          >{{ message.createdAt | moment | amTimeAgo }}</span
        >
      </small>
    </div>
  }
}
