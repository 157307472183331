<mat-sidenav-container
  #container
  class="!bg-transparent"
  ngClass.gt-xs="large-toolbar"
  fxFill
>
  <mat-sidenav
    [class.!-z-10]="(sidebar.isMobile$ | async) === false"
    [fixedInViewport]="true"
    [mode]="sidebar.state.mode"
    [opened]="sidebar.state.opened"
    (closedStart)="sidebar.close()"
  >
    <pr-sidebar #sidebarContainer>
      <pr-settings-sidebar
        [expanded]="sidebar.state.expanded"
        (widthChange)="container.updateContentMargins()"
      />
    </pr-sidebar>
  </mat-sidenav>

  <mat-sidenav-content class="flex-col overflow-x-hidden bg-transparent">
    <pr-toolbar
      class="fixed inset-x-0 -z-10 mt-0 bg-transparent"
      [class.is-mobile]="sidebar.isMobile"
      [style.padding-left]="sidebarContainer.width"
      [chatCount]="chatCount$ | ngrxPush"
      [notificationsCount]="(notifications$ | async)?.length"
      (notificationsToggle)="notificationsOpen = !notificationsOpen"
    />
    <div class="toolbar-spacer -z-50"></div>
    <div
      fxFlex
      class="overflow-hidden rounded-t-lg bg-[#fafafa] md:mx-1 md:mb-1 md:rounded-b-lg"
    >
      <div class="h-full w-full overflow-scroll">
        <router-outlet #router="outlet" />
        @if (!router.isActivated) {
          <pr-settings-splash />
        }
      </div>
    </div>
  </mat-sidenav-content>

  <mat-sidenav
    #sidenav="matSidenav"
    mode="over"
    position="end"
    [(opened)]="notificationsOpen"
    class="!bg-[#fafafa]"
  >
    <ng-container *ngIf="notifications$ | async as notifications">
      <pr-notifications-sidebar
        [notifications]="notifications"
        (closed)="sidenav.close()"
      />
    </ng-container>
  </mat-sidenav>
</mat-sidenav-container>
