<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" color="accent">
  Add to Checklist
</mat-toolbar>

<mat-dialog-content>
  <pr-checklist-form [appointment]="data.appointment" />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Close</button>
  </pt-buttons-container>
</mat-dialog-actions>
