import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { type IDVACard } from '@principle-theorem/principle-core/interfaces';
import { HISTORY_DATE_FORMAT } from '@principle-theorem/shared';

@Component({
  selector: 'pr-dva-card-display',
  templateUrl: './dva-card-display.component.html',
  styleUrls: ['./dva-card-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DVACardDisplayComponent {
  readonly dateFormat: string = HISTORY_DATE_FORMAT;
  @Input() dvaCard?: IDVACard;
  @Output() remove = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();
}
