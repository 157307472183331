import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  DATE_FORMAT,
  multiSort,
  sortTimestampAsc,
  toMoment,
} from '@principle-theorem/shared';
import { uniqWith } from 'lodash';
import type * as moment from 'moment-timezone';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { type IGap } from '@principle-theorem/principle-core/interfaces';
import { TrackByFunctions } from '@principle-theorem/ng-shared';

@Component({
  selector: 'pr-gap-list',
  templateUrl: './gap-list.component.html',
  styleUrls: ['./gap-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GapListComponent {
  private _expanded?: string;
  trackBySection = TrackByFunctions.date<ISection>('date');
  trackByGap = TrackByFunctions.combine<IGap>(
    'practitioner.ref.id',
    'event.from'
  );
  gaps$: ReplaySubject<IGap[]> = new ReplaySubject(1);
  sections$: Observable<ISection[]>;
  readonly dateFormat = DATE_FORMAT;

  @Input()
  set gaps(gaps: IGap[]) {
    if (gaps) {
      this.gaps$.next(gaps);
    }
  }

  constructor() {
    this.initSections$();
  }

  initSections$(): void {
    this.sections$ = this.gaps$.pipe(
      map((gaps) => gaps.map((gap) => toMoment(gap.event.from))),
      map((dates) => uniqWith(dates, (a, b) => a.isSame(b, 'day'))),
      map((dates) => {
        return dates.map((date: moment.Moment) => {
          return {
            date,
            gaps$: this.filterGapsByDate$(date),
          };
        });
      }),
      multiSort((sectionA, sectionB) =>
        sortTimestampAsc(sectionA.date, sectionB.date)
      )
    );
  }

  filterGapsByDate$(date: moment.Moment): Observable<IGap[]> {
    return this.gaps$.pipe(
      map((gaps) =>
        gaps.filter((gap) => {
          return toMoment(gap.event.from).isSame(date, 'day');
        })
      )
    );
  }

  isExpanded(gap: IGap): boolean {
    return this._expanded === getGapUid(gap);
  }

  setExpanded(gap: IGap): void {
    this._expanded = getGapUid(gap);
  }
}

function getGapUid(gap: IGap): string {
  return gap.event.from.toDate().toString() + gap.practitioner.ref.path;
}

interface ISection {
  date: moment.Moment;
  gaps$: Observable<IGap[]>;
}
