import {
  type ICustomReport,
  type IQueryScopeRequests,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, type AtLeast } from '@principle-theorem/shared';
import { resolveDataSource } from '../../models/report-builder-data-sources/report-builder-data-sources';
import {
  type IReportBuilderDisplay,
  type IReportBuilderQuery,
  type IReportBuilderQueryForm,
} from './report-builder.store';

export function toCustomReport(
  base: AtLeast<ICustomReport, 'name' | 'restrictAccessTo'>,
  query: IReportBuilderQueryForm,
  display: IReportBuilderDisplay
): ICustomReport {
  return {
    ...base,
    deleted: false,
    dataSource: query.dataSource,
    columns: display.columns.map((column) => ({
      uid: column.uid,
      id: column.id,
      label: column.label,
    })),
    staticFilters: display.dynamicFilters,
    chartSections: display.chartSections,
    queryScopes: query.scopeRequests,
  };
}

export function toCustomReportQuery(
  report: WithRef<ICustomReport>,
  scopeRequests: IQueryScopeRequests,
  editMode = false
): IReportBuilderQuery | undefined {
  const dataSource = resolveDataSource(report.dataSource);
  if (!dataSource) {
    // eslint-disable-next-line no-console
    console.error(`Could not resolve data source ${report.dataSource}`);
    return;
  }
  return {
    dataSource: report.dataSource,
    staticFilters: report.staticFilters,
    columns: report.columns,
    chartSections: report.chartSections,
    scopeRequests,
    currentReport: report,
    editMode,
  };
}
