import { type ICSVExport } from '@principle-theorem/ng-shared';
import { type IPractitionerReportRecord } from '@principle-theorem/reporting';
import { DAY_MONTH_YEAR_FORMAT, toMoment } from '@principle-theorem/shared';

export interface IInvoiceIssuedInspectorCSV {
  issuedAt: string;
  status: string;
  invoice: string;
  patient: string;
  treatmentsInvoicedAmount: string;
  depositsTakenAmount: string;
  discountsAppliedAmount: string;
  invoicedCommissionAmount: string;
}

export class InvoiceIssuedInspectorToCSV
  implements ICSVExport<IPractitionerReportRecord, IInvoiceIssuedInspectorCSV>
{
  defaultFileName = 'invoice-issued-drilldown';
  headers = [
    'Issued At',
    'Status',
    'Invoice',
    'Patient',
    'Treatment Invoiced',
    'Deposits',
    'Discounts',
    'Recievable Amount',
  ];

  translate(
    records: IPractitionerReportRecord[]
  ): IInvoiceIssuedInspectorCSV[] {
    return records.map((record) => {
      return {
        issuedAt: record.invoice.issuedAt
          ? toMoment(record.invoice.issuedAt).format(DAY_MONTH_YEAR_FORMAT)
          : '',
        status: record.invoice.status,
        invoice: record.invoice.reference,
        patient: record.patient.name,
        treatmentsInvoicedAmount:
          record.summary.treatmentsInvoicedAmount.toString(),
        depositsTakenAmount: record.summary.depositsTakenAmount.toString(),
        discountsAppliedAmount:
          record.summary.discountsAppliedAmount.toString(),
        invoicedCommissionAmount:
          record.summary.invoicedCommissionAmount.toString(),
      };
    });
  }
}
