<div class="table-wrapper">
  <table mat-table [dataSource]="dataSource" cdkDropListGroup matSort>
    @if (display$ | async; as display) {
      @for (
        column of columns$ | async;
        track column.definition.uid;
        let index = $index;
        let isLast = $last
      ) {
        <ng-container [matColumnDef]="column.definition.uid">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            <pr-report-builder-resolved-column-label
              [column]="column"
              [hideSubtitle]="true"
            />

            @if (!readOnly) {
              <button
                mat-icon-button
                [matMenuTriggerFor]="columnMenu"
                class="small-button"
                (click)="$event.stopPropagation()"
              >
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #columnMenu="matMenu">
                @if (index === 0) {
                  <button
                    mat-menu-item
                    (click)="
                      columnManager.editGroup(
                        column,
                        display.chart,
                        sectionName
                      )
                    "
                  >
                    <mat-icon>edit</mat-icon> Edit
                  </button>
                } @else {
                  <button
                    mat-menu-item
                    (click)="
                      columnManager.edit(column, display.chart, sectionName)
                    "
                  >
                    <mat-icon>edit</mat-icon> Edit
                  </button>
                }
                <button
                  mat-menu-item
                  [disabled]="index === 0"
                  (click)="
                    columnManager.remove(column, display.chart, sectionName)
                  "
                >
                  <mat-icon>delete</mat-icon> Remove
                </button>
                <mat-divider />
                <button
                  mat-menu-item
                  [disabled]="index < 2"
                  (click)="
                    columnManager.moveLeft(column, display.chart, sectionName)
                  "
                >
                  <mat-icon>arrow_back</mat-icon> Move Left
                </button>
                <button
                  mat-menu-item
                  [disabled]="index === 0 || isLast"
                  (click)="
                    columnManager.moveRight(column, display.chart, sectionName)
                  "
                >
                  <mat-icon>arrow_forward</mat-icon> Move Right
                </button>
                <mat-divider />
                <button
                  mat-menu-item
                  [disabled]="index === 0"
                  (click)="
                    columnManager.addLeft(column, display.chart, sectionName)
                  "
                >
                  <mat-icon>add</mat-icon> Add Column Left
                </button>
                <button
                  mat-menu-item
                  (click)="
                    columnManager.addRight(column, display.chart, sectionName)
                  "
                >
                  <mat-icon>add</mat-icon> Add Column Right
                </button>
              </mat-menu>
            }
          </th>
          <td mat-cell *matCellDef="let row">
            <ng-container
              *ngIf="{ row, column } | map: getDataPoint : this as dataPoint"
            >
              <ng-container *ngIf="dataPoint | map: isLink; else textValue">
                <a
                  *ngIf="dataPoint | map: hasValidLink"
                  href="{{ dataPoint | metric }}"
                  target="_blank"
                  >Open Link</a
                >
              </ng-container>

              <ng-template #textValue>
                <span
                  class="text"
                  *ngIf="dataPoint | map: isText; else metricValue"
                  [matTooltip]="dataPoint | metric"
                  matTooltipShowDelay="500"
                >
                  {{ dataPoint | metric }}
                </span>
              </ng-template>

              <ng-template #metricValue>
                {{ dataPoint | metric }}
              </ng-template>
            </ng-container>
          </td>
        </ng-container>
      }
    }
    <tr mat-header-row *matHeaderRowDef="columnIds$ | async"></tr>
    <tr mat-row *matRowDef="let row; columns: columnIds$ | async"></tr>
  </table>
</div>
<mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons />
