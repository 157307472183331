import { NgModule } from '@angular/core';
import { PhoneService } from './interaction-actions/phone.service';
import { SmsService } from './interaction-actions/sms.service';
import { LoadLabActionService } from './routing-actions/load-lab-action.service';
import { LoadLabJobActionService } from './routing-actions/load-lab-job-action.service';
import { LoadPatientActionService } from './routing-actions/load-patient-action.service';
import { EmailService } from './interaction-actions/email.service';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { LoadStafferActionService } from './routing-actions/load-staffer-action.service';
import { LoadContactActionService } from './routing-actions/load-contact-action.service';
import { CreateAppointmentActionService } from './create-actions/create-appointment-action.service';
import { NoteService } from './interaction-actions/note.service';
import { LoadTimelineActionService } from './routing-actions/load-timeline-action.service';

@NgModule({
  imports: [NgPrincipleSharedModule],
  providers: [
    LoadPatientActionService,
    LoadLabActionService,
    LoadLabJobActionService,
    LoadStafferActionService,
    LoadContactActionService,
    PhoneService,
    SmsService,
    EmailService,
    NoteService,
    CreateAppointmentActionService,
    LoadTimelineActionService,
  ],
})
export class ContextualActionsModule {}
