import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type INextStage } from '@principle-theorem/principle-core/interfaces';
import * as moment from 'moment-timezone';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-eventable-next-stage',
  templateUrl: './eventable-next-stage.component.html',
  styleUrls: ['./eventable-next-stage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventableNextStageComponent {
  nextStage$: ReplaySubject<INextStage> = new ReplaySubject(1);
  isOverdue$: Observable<boolean>;

  constructor() {
    this.isOverdue$ = this.nextStage$.pipe(
      map((nextStage) => {
        if (nextStage && nextStage.due) {
          return moment().isAfter(nextStage.due);
        }
        return false;
      })
    );
  }

  @Input()
  set nextStage(nextStage: INextStage) {
    if (nextStage) {
      this.nextStage$.next(nextStage);
    }
  }
}
