import { createAction, props } from '@ngrx/store';
import { IFollowUpFormData } from '@principle-theorem/ng-follow-ups';
import { ISchedulingEventData } from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData } from '@principle-theorem/shared';

enum CancelAppointmentActions {
  Cancel = '[Appointment Cancel] Cancel',
}

interface ICancelRequest {
  followUpData: IFollowUpFormData;
  schedulingEventData: ISchedulingEventData;
}

export const cancelAppointment = createAction(
  CancelAppointmentActions.Cancel,
  props<SerialisedData<ICancelRequest>>()
);
