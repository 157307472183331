import { Injectable } from '@angular/core';
import {
  PATIENT_PORTAL_DISPATCHER,
  PatientAction,
  PatientActionRequest,
  type IPatient,
  type ISubmitPatientDetailsFormRequest,
  type ISubmittedForm,
} from '@principle-theorem/principle-core/interfaces';
import {
  httpsCallable,
  serialise,
  type SerialisedData,
} from '@principle-theorem/shared';
import { from, type Observable } from 'rxjs';

@Injectable()
export class PatientDetailsFormService {
  submitFormData(
    tokenUid: string,
    formData: ISubmittedForm<Partial<IPatient>>
  ): Observable<unknown> {
    const data = serialise({
      tokenUid,
      data: formData,
    });

    const payload = {
      action: PatientAction.SubmitPatientDetailsForm,
      data,
    };

    const request = httpsCallable<
      PatientActionRequest<SerialisedData<ISubmitPatientDetailsFormRequest>>,
      void
    >(PATIENT_PORTAL_DISPATCHER)(payload);

    return from(request);
  }
}
