<div fxFlex fxLayout="column" class="editor mat-typography max-w-full">
  <pt-menu-bar
    *ngIf="menuEnabled"
    [menuItems]="menuItems$ | async"
    [editor]="editorBloc.editor$ | async"
  />

  <tiptap-editor
    *ngIf="editorBloc.editor$ | async as editor"
    outputFormat="json"
    ptImageUploadDropzone
    ptVideoUploadDropzone
    [uploader]="editorBloc.uploader$ | async"
    [editor]="editor"
    [formControl]="editorCtrl"
  />
</div>
