import {
  ITranslationMap,
  IUser,
} from '@principle-theorem/principle-core/interfaces';
import {
  isSameRef,
  type AtLeast,
  type IXSLXExport,
  type WithRef,
  type XSLXCell,
} from '@principle-theorem/shared';
import type { Column } from 'exceljs';
import { sortBy } from 'lodash';
import { type IBaseMigrationStaffer } from '../interfaces';

export interface IStafferToUserXSLX {
  id: string;
  name: string;
  mapTo: string;
}

export const STAFFER_TO_USER_HEADERS: AtLeast<Column, 'key' | 'header'>[] = [
  {
    key: 'id',
    header: 'Id',
  },
  {
    key: 'name',
    header: 'Name',
    width: 30,
  },
  {
    key: 'mapTo',
    header: 'Map To',
    width: 30,
  },
];

export class StafferToUserToXSLX<
  T extends IBaseMigrationStaffer = IBaseMigrationStaffer,
> implements IXSLXExport<T, IStafferToUserXSLX>
{
  headers = STAFFER_TO_USER_HEADERS;

  constructor(
    private _users: WithRef<IUser>[],
    private _existingMappings: ITranslationMap<IUser>[]
  ) {}

  translate(records: T[]): Record<keyof IStafferToUserXSLX, XSLXCell>[] {
    const userOptions = sortBy(this._users, 'name').map((user) => user.name);

    return records.map((record) => {
      const existingRecord = this._existingMappings.find(
        (existingMapping) =>
          existingMapping.sourceIdentifier === record.id.toString()
      );

      const associatedValue = existingRecord?.destinationIdentifier;
      const mappedUser = associatedValue
        ? this._users.find((user) => isSameRef(user, associatedValue))
        : undefined;

      const existingValue = mappedUser ? mappedUser.name : undefined;

      return {
        id: {
          value: record.id,
        },
        name: {
          value: record.name,
        },
        mapTo: {
          value: existingValue,
          dataValidation: {
            type: 'list',
            formulae: [`"${userOptions.join(',')}"`],
          },
        },
      };
    });
  }
}
