import {
  IAppointment,
  ITypesenseWaitList,
  WaitListUrgency,
} from '@principle-theorem/principle-core/interfaces';
import {
  DayOfWeek,
  Firestore,
  TypeGuard,
  durationToHumanisedTime,
  getEnumValues,
  isObject,
  serialise,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import { isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { type CollectionCreateSchema } from 'typesense/lib/Typesense/Collections';
import { Appointment } from '../appointment/appointment';
import { TreatmentStep } from '../clinical-charting/treatment/treatment-step';
import { getAppointmentEventTitle } from '../event/event';
import { PrincipleTypesenseCollection } from './typesense';
import { duration } from 'moment-timezone';
import { NameHelpers } from '../patient/name-helpers';

export const WAIT_LIST_COLLECTION_SCHEMA: CollectionCreateSchema = {
  name: PrincipleTypesenseCollection.WaitLists,
  fields: [
    {
      name: 'id',
      type: 'string',
    },
    {
      name: 'urgency',
      type: 'string',
    },
    {
      name: 'urgencyIndex',
      type: 'int32',
      sort: true,
    },
    {
      name: 'inShortList',
      type: 'bool',
      optional: true,
    },
    {
      name: 'dateFrom',
      type: 'int64',
      sort: true,
    },
    {
      name: 'dateTo',
      type: 'int64',
      sort: true,
    },
    {
      name: 'timeFrom',
      type: 'string',
      sort: true,
      optional: true,
    },
    {
      name: 'timeTo',
      type: 'string',
      sort: true,
      optional: true,
    },
    {
      name: 'days',
      type: 'string[]',
    },
    {
      name: 'notes',
      type: 'auto',
      optional: true,
      index: false,
    },
    {
      name: 'availableDates',
      type: 'string[]',
    },
    {
      name: 'appointmentRef',
      type: 'string',
    },
    {
      name: 'patientRef',
      type: 'string',
    },
    {
      name: 'patientName',
      type: 'string',
    },
    {
      name: 'searchPatientNames',
      type: 'string[]',
    },
    {
      name: 'practitionerName',
      type: 'string',
    },
    {
      name: 'practitionerRef',
      type: 'string',
    },
    {
      name: 'practiceName',
      type: 'string',
    },
    {
      name: 'practiceRef',
      type: 'string',
    },
    {
      name: 'brandRef',
      type: 'string',
    },
    {
      name: 'treatmentCategoryRef',
      type: 'string',
      optional: true,
    },
    {
      name: 'treatmentCategoryName',
      type: 'string',
      optional: true,
    },
    {
      name: 'appointmentTitle',
      type: 'string',
    },
    {
      name: 'appointmentFrom',
      type: 'int64',
      optional: true,
      sort: true,
    },
    {
      name: 'appointmentTo',
      type: 'int64',
      optional: true,
    },
    {
      name: 'appointmentDuration',
      type: 'int32',
      optional: true,
    },
    {
      name: 'appointmentDurationHuman',
      type: 'string',
      optional: true,
    },
    {
      name: 'appointmentTimezone',
      type: 'string',
    },
  ],
};

export class TypesenseWaitList {
  static async fromAppointment(
    appointment: WithRef<IAppointment>
  ): Promise<ITypesenseWaitList | undefined> {
    if (!appointment.event) {
      return;
    }

    const patientRef = Firestore.getParentDocRef(appointment.ref).path;
    const appointmentRef = appointment.ref.path;
    const practitionerRef = appointment.practitioner.ref.path;
    const practiceRef = appointment.practice.ref.path;
    const brandRef = Firestore.getParentDocRef(practiceRef).path;
    const patient = await Appointment.patient(appointment);
    const practice = await Firestore.getDoc(appointment.practice.ref);
    const treatmentCategoryRef = TreatmentStep.defaultDisplayRef(
      appointment.treatmentPlan.treatmentStep.display
    );
    const treatmentCategory = treatmentCategoryRef
      ? await Firestore.getDoc(treatmentCategoryRef)
      : undefined;

    return {
      id: appointment.ref.id,
      ref: appointmentRef,
      appointmentRef,
      patientName: patient.name,
      searchPatientNames: [
        NameHelpers.fullName(patient.name),
        `${NameHelpers.nickname(patient.name)} ${NameHelpers.lastName(
          patient.name
        )}`,
      ],
      patientRef,
      practitionerName: appointment.practitioner.name,
      practitionerRef,
      practiceName: practice.name,
      practiceRef,
      brandRef,
      urgency: appointment.waitListItem?.urgency ?? WaitListUrgency.Low,
      urgencyIndex: getEnumValues(WaitListUrgency).indexOf(
        appointment.waitListItem?.urgency ?? WaitListUrgency.Low
      ),
      inShortList: appointment.waitListItem?.inShortList ?? false,
      dateFrom: appointment.waitListItem
        ? toMoment(appointment.waitListItem.dateFrom).unix()
        : moment().unix(),
      dateTo: appointment.waitListItem
        ? toMoment(appointment.waitListItem.dateTo).unix()
        : toMoment(appointment.event.to).unix(),
      timeFrom: appointment.waitListItem?.timeFrom,
      timeTo: appointment.waitListItem?.timeTo,
      days: appointment.waitListItem?.days ?? [],
      notes: appointment.waitListItem?.notes
        ? serialise(appointment.waitListItem.notes)
        : undefined,
      availableDates: appointment.waitListItem?.availableDates ?? [],
      appointmentTitle: getAppointmentEventTitle(appointment),
      appointmentFrom: toMoment(appointment.event.from).unix(),
      appointmentTo: toMoment(appointment.event.to).unix(),
      appointmentDuration: Appointment.duration(appointment),
      appointmentDurationHuman: durationToHumanisedTime(
        duration(Appointment.duration(appointment), 'minutes')
      ),
      appointmentTimezone: practice.settings.timezone,
      treatmentCategoryRef: treatmentCategoryRef?.path,
      treatmentCategoryName: treatmentCategory?.name,
    };
  }
}

export function isTypesenseWaitList(item: unknown): item is ITypesenseWaitList {
  return TypeGuard.interface<ITypesenseWaitList>({
    id: isString,
    ref: isString,
    urgency: TypeGuard.enumValue(WaitListUrgency),
    urgencyIndex: isNumber,
    inShortList: TypeGuard.undefinedOr(isBoolean),
    dateFrom: isNumber,
    dateTo: isNumber,
    timeFrom: isString,
    timeTo: isString,
    days: TypeGuard.arrayOf(TypeGuard.enumValue(DayOfWeek)),
    notes: TypeGuard.undefinedOr(isObject),
    availableDates: TypeGuard.arrayOf(isString),
    appointmentRef: isString,
    patientRef: isString,
    patientName: isString,
    searchPatientNames: TypeGuard.arrayOf(isString),
    practitionerName: isString,
    practitionerRef: isString,
    practiceName: isString,
    practiceRef: isString,
    brandRef: isString,
    appointmentTitle: isString,
    appointmentFrom: TypeGuard.undefinedOr(isNumber),
    appointmentTo: TypeGuard.undefinedOr(isNumber),
    appointmentDuration: TypeGuard.undefinedOr(isNumber),
    appointmentDurationHuman: TypeGuard.undefinedOr(isString),
    appointmentTimezone: isString,
    treatmentCategoryRef: TypeGuard.undefinedOr(isString),
    treatmentCategoryName: TypeGuard.undefinedOr(isString),
  })(item);
}
