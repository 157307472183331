<div class="flex flex-col gap-4 rounded-md bg-white p-4 shadow-md">
  <h3 class="!m-0 !font-bold">Treatments using this Configuration</h3>

  @if (treatments$ | async; as treatments) {
    @if (treatments.length) {
      <mat-list class="rounded bg-slate-50 !p-0">
        @for (treatment of treatments; track trackByTreatment) {
          <mat-list-item>{{ treatment.name }}</mat-list-item>
        }
      </mat-list>
    } @else {
      <p class="text-sm opacity-50">
        This configuration is not used in any treatments
      </p>
    }
  }
</div>
