import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnInit,
} from '@angular/core';
import { DateService } from '@principle-theorem/ng-principle-shared';
import {
  type IAppointment,
  type IGap,
  type ILabJob,
  type IPatient,
  isAppointment,
  type ISchedulingAlert,
  isGap,
} from '@principle-theorem/principle-core/interfaces';
import {
  Appointment,
  Event,
  SchedulingAlert,
} from '@principle-theorem/principle-core';
import { snapshot, type WithRef } from '@principle-theorem/shared';

@Component({
  selector: 'pr-alerts-header',
  templateUrl: './alerts-header.component.html',
  styleUrls: ['./alerts-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsHeaderComponent implements OnInit {
  @Input() schedulingAlert: ISchedulingAlert;
  target?: WithRef<IAppointment> | IGap | WithRef<ILabJob>;
  patient?: WithRef<IPatient>;

  constructor(public dateService: DateService) {}

  async ngOnInit(): Promise<void> {
    await this._load();
  }

  get duration(): number | undefined {
    if (isGap(this.target)) {
      return Event.duration(this.target.event);
    }
    if (isAppointment(this.target)) {
      return Appointment.duration(this.target);
    }
  }

  get icon(): string {
    return SchedulingAlert.getIcon(this.schedulingAlert);
  }

  get isAppointment(): boolean {
    return isAppointment(this.target);
  }

  private async _load(): Promise<void> {
    await this._loadTarget();
    await this._loadPatient();
  }

  private async _loadTarget(): Promise<void> {
    this.target = await snapshot(SchedulingAlert.target$(this.schedulingAlert));
  }

  private async _loadPatient(): Promise<void> {
    if (isAppointment(this.target)) {
      this.patient = await Appointment.patient(this.target);
    }
  }
}
