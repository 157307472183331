import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  MixedSchema,
  getSchemaText,
  initVersionedSchema,
  isMixedSchema,
  type RawInlineNodes,
} from '@principle-theorem/editor';
import { filterUndefined, shareReplayCold } from '@principle-theorem/shared';
import { ReplaySubject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-content-text',
  templateUrl: './content-text.component.html',
  styleUrls: ['./content-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentTextComponent {
  private _content$: ReplaySubject<MixedSchema | RawInlineNodes> =
    new ReplaySubject(1);
  content$: Observable<string>;

  constructor() {
    this.content$ = this._content$.pipe(
      filterUndefined(),
      map((content) =>
        getSchemaText(
          isMixedSchema(content) ? content : initVersionedSchema(content)
        )
      ),
      shareReplayCold()
    );
  }

  @Input()
  set content(content: MixedSchema | RawInlineNodes) {
    if (content) {
      this._content$.next(content);
    }
  }
}
