import {
  BritishDentalJournalNumberingSystem,
  ITooth,
  IToothRef,
  Quadrant,
  ToothNumber,
  ToothType,
  UniversalToothNumberingSystem,
  isToothNumber,
} from '@principle-theorem/principle-core/interfaces';
import { AtLeast } from '@principle-theorem/shared';

export function isSameToothRef(toothA: IToothRef, toothB: IToothRef): boolean {
  return (
    toothA.quadrant === toothB.quadrant &&
    toothA.quadrantIndex === toothB.quadrantIndex
  );
}

export function toothRefToLabel(toothRef: IToothRef): string {
  return `${toothRef.quadrant}${toothRef.quadrantIndex}`;
}

export class Tooth {
  static init(overrides: AtLeast<ITooth, 'toothRef'>): ITooth {
    return {
      type: ToothType.Incisor,
      roots: 1,
      ...overrides,
    };
  }
}

export class ToothNumberConversion {
  static convertToISONotation(toothNumber: string): ToothNumber {
    if (isToothNumber(toothNumber)) {
      return toothNumber;
    }

    try {
      return ToothNumberConversion.universalToISONotation(toothNumber);
    } catch (error) {
      // Do nothing
    }

    try {
      return ToothNumberConversion.britishDentalJournalToISONotation(
        toothNumber
      );
    } catch (error) {
      // Do nothing
    }

    throw new Error(`Invalid tooth number: ${toothNumber}`);
  }

  static universalToISONotation(universalNumber: string): ToothNumber {
    const toothReference = universalNumber as UniversalToothNumberingSystem;
    const conversions: Record<UniversalToothNumberingSystem, ToothNumber> = {
      '1': '18',
      '2': '17',
      '3': '16',
      '4': '15',
      '5': '14',
      '6': '13',
      '7': '12',
      '8': '11',
      '9': '21',
      '10': '22',
      '11': '23',
      '12': '24',
      '13': '25',
      '14': '26',
      '15': '27',
      '16': '28',
      '17': '38',
      '18': '37',
      '19': '36',
      '20': '35',
      '21': '34',
      '22': '33',
      '23': '32',
      '24': '31',
      '25': '41',
      '26': '42',
      '27': '43',
      '28': '44',
      '29': '45',
      '30': '46',
      '31': '47',
      '32': '48',
    };

    try {
      return conversions[toothReference];
    } catch (error) {
      throw new Error(`Invalid universal tooth number: ${universalNumber}`);
    }
  }

  static britishDentalJournalToISONotation(bdjNumber: string): ToothNumber {
    const toothReference = bdjNumber as BritishDentalJournalNumberingSystem;
    const conversions: Record<
      BritishDentalJournalNumberingSystem,
      ToothNumber
    > = {
      UR1: '11',
      UR2: '12',
      UR3: '13',
      UR4: '14',
      UR5: '15',
      UR6: '16',
      UR7: '17',
      UR8: '18',
      UL1: '21',
      UL2: '22',
      UL3: '23',
      UL4: '24',
      UL5: '25',
      UL6: '26',
      UL7: '27',
      UL8: '28',
      LR1: '41',
      LR2: '42',
      LR3: '43',
      LR4: '44',
      LR5: '45',
      LR6: '46',
      LR7: '47',
      LR8: '48',
      LL1: '31',
      LL2: '32',
      LL3: '33',
      LL4: '34',
      LL5: '35',
      LL6: '36',
      LL7: '37',
      LL8: '38',
      URA: '51',
      URB: '52',
      URC: '53',
      URD: '54',
      URE: '55',
      ULA: '61',
      ULB: '62',
      ULC: '63',
      ULD: '64',
      ULE: '65',
      LLA: '71',
      LLB: '72',
      LLC: '73',
      LLD: '74',
      LLE: '75',
      LRA: '81',
      LRB: '82',
      LRC: '83',
      LRD: '84',
      LRE: '85',
    };

    try {
      return conversions[toothReference];
    } catch (error) {
      throw new Error(
        `Invalid British Dental Journal tooth number: ${bdjNumber}`
      );
    }
  }
}

export function toothNumberToDeciduous(toothNumber: ToothNumber): ToothNumber {
  if (parseInt(toothNumber, 10) >= 50) {
    return toothNumber;
  }
  return (parseInt(toothNumber, 10) + 40).toString() as ToothNumber;
}

export function getQuadrant(toothNumber: ToothNumber): Quadrant | undefined {
  switch (parseInt(toothNumber[0], 10)) {
    case 1:
      return Quadrant.AdultUpperRight;
    case 2:
      return Quadrant.AdultUpperLeft;
    case 3:
      return Quadrant.AdultLowerLeft;
    case 4:
      return Quadrant.AdultLowerRight;
    case 5:
      return Quadrant.DeciduousUpperRight;
    case 6:
      return Quadrant.DeciduousUpperLeft;
    case 7:
      return Quadrant.DeciduousLowerLeft;
    case 8:
      return Quadrant.DeciduousLowerRight;
    default:
      break;
  }
}

export function getQuadrantIndex(toothNumber: ToothNumber): number {
  return parseInt(toothNumber[1], 10);
}
