import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';
import { ChatComponent } from './components/chat/chat.component';
import { NgChatsModule } from './ng-chats.module';
import { ChatsDashboardComponent } from './pages/chats-dashboard/chats-dashboard.component';
import { HasAccessToChatGuard } from './has-access-to-chat.guard';

const routes: Routes = [
  {
    path: '',
    component: ChatsDashboardComponent,
    children: [
      {
        path: ':uid',
        component: ChatComponent,
        canActivate: [HasAccessToChatGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), NgChatsModule],
  exports: [RouterModule],
})
export class NgChatsRoutingModule {}
