<mat-toolbar color="accent">Merge Patient</mat-toolbar>

<mat-dialog-content>
  <div class="flex flex-col gap-4">
    <div>
      {{ data.patient.name }} will be marked as a duplicate. All of their
      Interactions, Appointments, Invoices, Treatment Plans, and Files, will be
      available on the selected patient.
    </div>
    <div>This can be undone at any time.</div>
    <pr-patient-selector
      [formControl]="patientCtrl"
      label="Select Patient to merge with"
      [filterPatient]="data.patient"
    />
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="!patientCtrl.valid"
      (click)="mergePatients()"
    >
      Merge Patients
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
