import { createAction, props, ActionCreator } from '@ngrx/store';
import { type TypedAction } from '@ngrx/store/src/models';

export enum CoreActionType {
  Cancel = '[Core] Cancel Action / NOOP',
}

export const cancelAction = createAction(CoreActionType.Cancel);

export interface ISelectItem {
  id: string;
}

export interface ISetError {
  error: string;
}

export function getSelectAction<T extends string>(
  actionName: T
): ActionCreator<T, (props: ISelectItem) => ISelectItem & TypedAction<T>> {
  return createAction(actionName, props<ISelectItem>());
}

export function getErrorAction<T extends string>(
  actionName: T
): ActionCreator<T, (props: ISetError) => ISetError & TypedAction<T>> {
  return createAction(actionName, props<ISetError>());
}

export function getResetAction<T extends string>(
  actionName: T
): ActionCreator<T, () => TypedAction<T>> {
  return createAction(actionName);
}
