@if (store.form$ | async; as form) {
  <pr-patient-form-confirm-button />

  @if (store.isCustomForm$ | async) {
    <a mat-stroked-button color="primary" routerLink="update"> Update </a>
  }

  <button mat-icon-button [matMenuTriggerFor]="actionMenu">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #actionMenu="matMenu">
    @if (store.isCustomForm$ | async) {
      <div
        matTooltipPosition="left"
        matTooltip="This form has not been submitted"
        [matTooltipDisabled]="form.status !== 'issued'"
      >
        <a
          mat-menu-item
          routerLink="print"
          [ngClass]="{
            'pointer-events-none opacity-50': form.status === 'issued'
          }"
        >
          <mat-icon>print</mat-icon> Print
        </a>
      </div>
    }

    @if (store.isCustomForm$ | async) {
      <mat-divider />
    }

    <button
      mat-menu-item
      matTooltipPosition="left"
      matTooltip="This form has not been submitted"
      [disabled]="form.status === 'issued'"
      [matTooltipDisabled]="form.status !== 'issued'"
      (click)="reissueForm(form)"
    >
      <mat-icon>undo</mat-icon> Reissue
    </button>

    <button
      mat-menu-item
      color="warn"
      (click)="customForms.deleteForm(form, true)"
    >
      <mat-icon color="warn">delete</mat-icon> Delete
    </button>
  </mat-menu>
}
