<mat-toolbar color="accent">{{ data.title }}</mat-toolbar>
<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field fxFlex>
      <mat-label>Amount</mat-label>
      <span matTextPrefix>$&nbsp;</span>
      <input
        matInput
        type="number"
        formControlName="amount"
        [min]="0"
        [max]="data.max"
        [required]="true"
      />
      <mat-hint *ngIf="data.max" align="end"
        >Maximum {{ data.max | currency }}</mat-hint
      >
    </mat-form-field>

    <ng-container *ngIf="options.enableDateReceived">
      <mat-form-field fxFlex *prHasPermission="[accountAdminPermission]">
        <mat-label>Date Received</mat-label>
        <input
          matInput
          formControlName="dateReceived"
          [matDatepicker]="dateReceived"
          [min]="amendDateStore.minDate$ | async"
          [max]="amendDateStore.maxDate$ | async"
          (focus)="dateReceived.open()"
          (click)="dateReceived.open()"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="dateReceived"
         />
        <mat-datepicker #dateReceived />
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="practiceOptions.options$ | async as practices">
      <mat-form-field *ngIf="practices.length > 1">
        <mat-label>Practice</mat-label>
        <mat-select
          [required]="true"
          formControlName="practiceRef"
          [compareWith]="practiceOptions.compareFn"
        >
          <mat-option
            *ngFor="
              let practice of practices;
              trackBy: practiceOptions.trackByFn
            "
            [value]="practice.ref"
          >
            {{ practice.name }}
          </mat-option>
        </mat-select>
        <mat-hint>{{ practiceOptions.hint }}</mat-hint>
      </mat-form-field>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" [disabled]="form.invalid">
        Submit
      </button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
