import {
  AppointmentSummary,
  IScheduleSummaryEventable,
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { IReffable, type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, type Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IAppointmentCardAction {
  icon?: string;
  name: string;
  isVisible: (
    appointment: WithRef<IAppointment> | AppointmentSummary
  ) => boolean;
  run: (
    appointment: WithRef<IAppointment>,
    patient: IReffable<IPatient>
  ) => Promise<void> | void;
  updateEventableSummary: (
    eventable: AppointmentSummary,
    updateFn: (
      oldEvent: IScheduleSummaryEventable,
      newEvent: IScheduleSummaryEventable
    ) => void
  ) => void;
  getBadge$: (
    appointment: WithRef<IAppointment>,
    patient: WithRef<IPatient>
  ) => Observable<number>;
  hasBadge$: (
    appointment: WithRef<IAppointment>,
    patient: WithRef<IPatient>
  ) => Observable<boolean>;
  loading$: Observable<boolean>;
}

export abstract class AppointmentCardAction implements IAppointmentCardAction {
  abstract name: string;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  icon?: string;
  abstract isVisible(
    appointment: WithRef<IAppointment> | AppointmentSummary
  ): boolean;
  abstract run(
    appointment: WithRef<IAppointment>,
    patient: IReffable<IPatient>
  ): Promise<void> | void;
  abstract updateEventableSummary(
    eventable: AppointmentSummary,
    updateFn: (
      oldEvent: IScheduleSummaryEventable,
      newEvent: IScheduleSummaryEventable
    ) => void
  ): void;
  getBadge$(
    _appointment: WithRef<IAppointment>,
    _patient: WithRef<IPatient>
  ): Observable<number> {
    return of(0);
  }

  hasBadge$(
    appointment: WithRef<IAppointment>,
    patient: WithRef<IPatient>
  ): Observable<boolean> {
    return this.getBadge$(appointment, patient).pipe(map((count) => count > 0));
  }
}
