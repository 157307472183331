import { Injectable } from '@angular/core';
import { FirebaseFunctionsService } from '@principle-theorem/ng-firebase';
import { from, type Observable, of } from 'rxjs';
import {
  IPatientViewInvoiceRequest,
  PATIENT_PORTAL_DISPATCHER,
  PatientAction,
  PatientActionRequest,
  type IInvoiceWithProviderDetails,
} from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData, unserialise$ } from '@principle-theorem/shared';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PatientViewInvoiceService {
  constructor(private _functions: FirebaseFunctionsService) {}

  resolveInvoice$(
    tokenUid: string
  ): Observable<IInvoiceWithProviderDetails | undefined> {
    const payload = {
      action: PatientAction.GetPatientInvoice,
      data: { tokenUid },
    };

    const request = this._functions.call<
      PatientActionRequest<IPatientViewInvoiceRequest>,
      SerialisedData<IInvoiceWithProviderDetails>
    >(PATIENT_PORTAL_DISPATCHER, payload);

    return from(request).pipe(
      catchError(() => of(undefined)),
      unserialise$()
    );
  }
}
