import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PopupNotificationsService } from '../popup-notifications.service';

@Component({
    selector: 'pr-popup-notifications',
    templateUrl: './popup-notifications.component.html',
    styleUrl: './popup-notifications.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PopupNotificationsComponent {
  constructor(public notifications: PopupNotificationsService) {}
}
