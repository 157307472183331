import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MixedSchema, getSchemaText } from '@principle-theorem/editor';
import { TypedFormControl } from '@principle-theorem/ng-shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IChartedItemNotesDialogData {
  content: MixedSchema;
}

@Component({
  selector: 'pr-charted-item-notes-dialog',
  templateUrl: './charted-item-notes-dialog.component.html',
  styleUrls: ['./charted-item-notes-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartedItemNotesDialogComponent {
  noteCtrl = new TypedFormControl<MixedSchema>(undefined, Validators.required);
  saveDisabled$: Observable<boolean>;

  constructor(
    private _dialogRef: MatDialogRef<
      ChartedItemNotesDialogComponent,
      MixedSchema
    >,
    @Inject(MAT_DIALOG_DATA) public data?: IChartedItemNotesDialogData
  ) {
    if (this.data) {
      this.noteCtrl.patchValue(this.data.content);
    }

    this.saveDisabled$ = this.noteCtrl.valueChanges.pipe(
      map((content) => !getSchemaText(content).length)
    );
  }

  saveNote(): void {
    this._dialogRef.close(this.noteCtrl.value);
  }
}
