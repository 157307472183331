/* eslint-disable no-null/no-null */
import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard } from '@principle-theorem/shared';
import { flow, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { FeeScheduleSourceEntity } from './fee-schedule';
import { cleanObjectStrings } from './lib/conversion-helpers';

export const PATIENT_STATUS_RESOURCE_TYPE = 'patientStatus';

export const PATIENT_STATUS_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Patient Status List',
    description: '',
    idPrefix: PATIENT_STATUS_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface ID4WPatientStatus {
  id: number;
  name: string;
}

export function isD4WPatientStatus(item: unknown): item is ID4WPatientStatus {
  return TypeGuard.interface<ID4WPatientStatus>({
    id: isNumber,
    name: isString,
  })(item);
}

const PATIENT_STATUS_SOURCE_QUERY = `
SELECT
  pcat_id as id,
  name
FROM patients_categories
WHERE
  pcat_id IS NOT NULL
`;

export class PatientStatusSourceEntity extends BaseSourceEntity<ID4WPatientStatus> {
  sourceEntity = PATIENT_STATUS_SOURCE_ENTITY;
  entityResourceType = PATIENT_STATUS_RESOURCE_TYPE;
  sourceQuery = PATIENT_STATUS_SOURCE_QUERY;
  verifySourceFn = isD4WPatientStatus;

  override requiredEntities = {
    feeSchedule: new FeeScheduleSourceEntity(),
  };

  override transformDataFn = flow([
    (rows: ID4WPatientStatus[]) => rows.map(cleanObjectStrings),
  ]);

  translate(): object {
    return {};
  }

  getSourceRecordId(data: ID4WPatientStatus): string | number {
    return data.id;
  }

  getSourceLabel(data: ID4WPatientStatus): string {
    return `${data.id} ${data.name}`;
  }
}
