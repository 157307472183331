import {
  FeatureVisibility,
  FEATURE_DEFAULTS,
  IFeature,
} from '@principle-theorem/feature-flags';

export enum AppointmentPermissions {
  AppointmentManage = 'appointment.manage',
  AppointmentConduct = 'appointment.conduct',
  AppointmentStart = 'appointment.start',
  SchedulingAlertsManage = 'scheduling-alerts.manage',
  SchedulingDoubleBook = 'scheduling.double-book',
  GapManage = 'gaps.manage',
  FollowUpsManage = 'follow-ups.manage',
}

export const APPOINTMENTS_CHECKLISTS_ACTIONS_FEATURE: IFeature = {
  label: 'actions',
  visibility: FeatureVisibility.None,
  permissions: [],
};

export const APPOINTMENTS_CHECKLISTS_FEATURE: IFeature = {
  label: 'checklists',
  visibility: FeatureVisibility.Beta,
  permissions: [],
  features: [APPOINTMENTS_CHECKLISTS_ACTIONS_FEATURE],
};

export const APPOINTMENTS_DASHBOARD_FEATURE: IFeature = {
  label: 'dashboard',
  visibility: FeatureVisibility.Beta,
  permissions: [],
};

export const APPOINTMENTS_TIMELINE_FEATURE: IFeature = {
  label: 'timeline',
  visibility: FeatureVisibility.Beta,
  permissions: [],
};

export const APPOINTMENTS_HISTORY_FEATURE: IFeature = {
  label: 'appointments-history',
  visibility: FeatureVisibility.Beta,
  permissions: [],
};

export const APPOINTMENT_REQUESTS_FEATURE: IFeature = {
  label: 'appointment-requests',
  visibility: FeatureVisibility.Beta,
  permissions: [],
};

export const SCHEDULING_ALERTS_FEATURE: IFeature = {
  ...FEATURE_DEFAULTS,
  label: 'scheduling-alerts',
  visibility: FeatureVisibility.None,
  permissions: [
    {
      value: AppointmentPermissions.SchedulingAlertsManage,
      description: `User can view, interact with, and manage scheduling alerts`,
    },
  ],
};

export const GAPS_FEATURE: IFeature = {
  label: 'gaps',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: AppointmentPermissions.GapManage,
      description: `User can view, interact with, and fill gaps`,
    },
  ],
};

export const FOLLOW_UPS_FEATURE: IFeature = {
  label: 'follow-ups',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: AppointmentPermissions.FollowUpsManage,
      description: `User can view, interact with, and perform follow ups`,
    },
  ],
};

export const APPOINTMENTS_FEATURE: IFeature = {
  label: 'appointments',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: AppointmentPermissions.AppointmentConduct,
      description: `User can conduct an appointment with the patient. This would be required for a practitioner or nurse.`,
    },
    {
      value: AppointmentPermissions.AppointmentStart,
      description: `User can start appointments`,
    },
    {
      value: AppointmentPermissions.AppointmentManage,
      description: `User can schedule and interact with patient's appointments`,
    },
    {
      value: AppointmentPermissions.SchedulingDoubleBook,
      description: `User can double-book over appointments and blocking events`,
    },
  ],
  features: [
    APPOINTMENTS_CHECKLISTS_FEATURE,
    APPOINTMENTS_DASHBOARD_FEATURE,
    APPOINTMENT_REQUESTS_FEATURE,
    APPOINTMENTS_TIMELINE_FEATURE,
    SCHEDULING_ALERTS_FEATURE,
    FOLLOW_UPS_FEATURE,
    GAPS_FEATURE,
    APPOINTMENTS_HISTORY_FEATURE,
  ],
};
