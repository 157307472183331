import { AnyExtension } from '@tiptap/core';
import { TextAlign, TextAlignOptions } from '@tiptap/extension-text-align';
import { BlockNodes } from '../../available-extensions';

export function createTextAlignExtension(): AnyExtension {
  return TextAlign.extend<TextAlignOptions>({
    addGlobalAttributes() {
      return [
        {
          types: this.options.types,
          attributes: {
            align: {
              default: this.options.defaultAlignment,
              parseHTML: (element) =>
                element.style.textAlign || this.options.defaultAlignment,
              renderHTML: (attributes) => {
                if (attributes.align === this.options.defaultAlignment) {
                  return {};
                }

                return { style: `text-align: ${attributes.align || 'left'}` };
              },
            },
          },
        },
      ];
    },

    addCommands() {
      return {
        setTextAlign:
          (alignment: string) =>
          ({ commands }) => {
            if (!this.options.alignments.includes(alignment)) {
              return false;
            }

            return this.options.types.every((type) =>
              commands.updateAttributes(type, { align: alignment })
            );
          },

        unsetTextAlign:
          () =>
          ({ commands }) => {
            return this.options.types.every((type) =>
              commands.resetAttributes(type, 'align')
            );
          },
      };
    },
  }).configure({
    types: [BlockNodes.Heading, BlockNodes.Paragraph],
  });
}
