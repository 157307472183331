import { FeatureVisibility, IFeature } from '@principle-theorem/feature-flags';

export enum StafferPermissions {
  RosterManage = 'roster.manage',
  RosterView = 'roster.view',
  TreatmentPlansView = 'treatment-plans.view',
  StatsView = 'staff.stats',
}

export const STAFF_CONFIGURATIONS_TREATMENTS_ASSETS_FEATURE: IFeature = {
  label: 'assets',
  visibility: FeatureVisibility.Dev,
  permissions: [],
};

export const STAFF_CONFIGURATIONS_TREATMENTS_FEATURE: IFeature = {
  label: 'treatments',
  visibility: FeatureVisibility.None,
  permissions: [],
  features: [STAFF_CONFIGURATIONS_TREATMENTS_ASSETS_FEATURE],
};

export const STAFF_CONFIGURATIONS_FEATURE: IFeature = {
  label: 'configurations',
  visibility: FeatureVisibility.Dev,
  permissions: [],
  features: [STAFF_CONFIGURATIONS_TREATMENTS_FEATURE],
};

export const STAFF_FEATURE: IFeature = {
  label: 'staff',
  visibility: FeatureVisibility.Beta,
  permissions: [
    {
      value: StafferPermissions.RosterManage,
      description: `User can manage rosters of other staffer members`,
    },
    {
      value: StafferPermissions.RosterView,
      description: `User can view rosters of other staffer members`,
    },
    // {
    //   value: StafferPermissions.StatsView,
    //   description: `User can view stats of other staffer members`,
    // },
    {
      value: StafferPermissions.TreatmentPlansView,
      description: `User can view treatment plans of other staffer members`,
    },
  ],
  features: [STAFF_CONFIGURATIONS_FEATURE],
};
