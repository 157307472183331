<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="32px" class="wrapper">
  <h1 class="mat-headline-4">Automation Configurations</h1>

  <mat-tab-group>
    <mat-tab label="Appointment">
      <ng-template matTabContent>
        <pr-automated-notification-configuration
          [brand]="brand$ | async"
          [configs]="appointmentNotifications$ | async"
          [templateScope]="automatedNotificationScope"
        >
          <pt-buttons-container>
            <button mat-flat-button color="primary" (click)="addNotification()">
              Add Notification
            </button>
          </pt-buttons-container>
        </pr-automated-notification-configuration>
        <pr-generated-task-configuration
          [brand]="brand$ | async"
          [configs]="appointmentTasks$ | async"
        >
          <pt-buttons-container>
            <button mat-flat-button color="primary" (click)="addTask()">
              Add Task
            </button>
          </pt-buttons-container>
        </pr-generated-task-configuration>
      </ng-template>
    </mat-tab>

    <mat-tab label="Treatment">
      <ng-template matTabContent>
        <pr-automated-notification-configuration
          [brand]="brand$ | async"
          [configs]="treatmentNotifications$ | async"
          [templateScope]="automatedNotificationScope"
        />

        <pr-generated-task-configuration
          [brand]="brand$ | async"
          [configs]="treatmentTasks$ | async"
        />
      </ng-template>
    </mat-tab>

    <mat-tab label="Deleted">
      <ng-template matTabContent>
        <pr-automated-notification-configuration
          [brand]="brand$ | async"
          [configs]="deletedNotifications$ | async"
          [templateScope]="automatedNotificationScope"
        />

        <pr-generated-task-configuration
          [brand]="brand$ | async"
          [configs]="deletedTasks$ | async"
        />
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
