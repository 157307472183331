import {
  type CrossfilterGroup,
  type CrossfilterGroupValue,
  type IChartConfig,
} from '@principle-theorem/reporting';
import { isObject } from '@principle-theorem/shared';
import { type NaturallyOrderedValue } from 'crossfilter2';
import { GenericDataTransformer } from './generic-data-transformer';

export class SanKeyDataTransformer extends GenericDataTransformer {
  protected override _buildDataTable(
    _config: IChartConfig,
    groups: CrossfilterGroup[],
    headers: string[]
  ): google.visualization.DataTable {
    const chartData = new google.visualization.DataTable();
    chartData.addColumn('string', 'From');
    chartData.addColumn('string', 'To');
    chartData.addColumn('number', '');

    groups.map((group: CrossfilterGroup, index: number) => {
      if (!group.size()) {
        return;
      }
      const label: string = group.top(1)[0].key.label;
      const values: NaturallyOrderedValue[] = group
        .all()
        .map((groupData: CrossfilterGroupValue) =>
          isObject(groupData.value)
            ? groupData.value.valueOf()
            : groupData.value
        );
      chartData.addRows([[headers[index], label, values[0] || 0]]);
    });

    // TODO: Add formatters
    // this._addFormatters(chartData, true);
    return chartData;
  }
}
