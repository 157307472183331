import {
  CalendarEventsFacade,
  CalendarFacade,
} from '@principle-theorem/ng-calendar/store';
import { GlobalStoreService } from '@principle-theorem/ng-principle-shared';
import {
  EventableQueries,
  Gap,
  buildGapEvent,
  stafferAllAvailableTimes,
} from '@principle-theorem/principle-core';
import {
  CalendarUnit,
  CalendarView,
  EventType,
  ICalendarEventSchedulesMap,
  IScheduleSummaryEventable,
  type IPractice,
  type IStaffer,
  IBrand,
} from '@principle-theorem/principle-core/interfaces';
import {
  MomentRange,
  timePeriodsIntersect,
  toEntityModel,
  toTimePeriod,
  type ITimePeriod,
  type WithRef,
  multiFilter,
  isSameRef,
  multiMap,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { BehaviorSubject, combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

export class StafferDayEvents {
  // TODO: Build with timezone - CU-251edxw
  date$ = new BehaviorSubject<moment.Moment>(moment().startOf('day'));
  events$: Observable<IScheduleSummaryEventable[]>;

  constructor(
    staffer$: Observable<WithRef<IStaffer>>,
    private _brand$: Observable<WithRef<IBrand>>,
    private _practice$: Observable<WithRef<IPractice>>,
    private _calendarEventsFacade: CalendarEventsFacade,
    private _calendarFacade: CalendarFacade,
    private _globalStore: GlobalStoreService
  ) {
    this.events$ = combineLatest([
      this.date$,
      staffer$,
      this._brand$,
      this._practice$,
      this._globalStore.rosterSchedules$,
      combineLatest([
        this._calendarFacade.unit$,
        this._calendarFacade.view$,
      ]).pipe(
        map(([unit, view]) => ({
          unit,
          view,
        }))
      ),
    ]).pipe(
      switchMap(
        ([
          date,
          staffer,
          brand,
          practice,
          allStaffSchedules,
          { unit, view },
        ]) => {
          const range = MomentRange.init(
            moment(date).startOf('day'),
            moment(date).endOf('day')
          );
          return this._combineGapsAndEventables(
            this._getEvents$(
              range,
              staffer,
              practice,
              allStaffSchedules,
              unit,
              view
            ),
            this._getGaps$(brand, range, staffer, practice)
          ).pipe(
            map((eventables) => {
              return eventables.filter((eventable) =>
                timePeriodsIntersect(
                  range,
                  toTimePeriod(eventable.event.from, eventable.event.to),
                  false,
                  'minutes'
                )
              );
            })
          );
        }
      )
    );
  }

  private _getEvents$(
    range: ITimePeriod,
    staffer: WithRef<IStaffer>,
    practice: WithRef<IPractice>,
    allStaffSchedules: ICalendarEventSchedulesMap,
    unit: CalendarUnit,
    view: CalendarView
  ): Observable<IScheduleSummaryEventable[]> {
    return EventableQueries.getScheduleSummaryEventablesWithFallback$(
      range,
      practice,
      [staffer],
      allStaffSchedules,
      [EventType.GapCandidate],
      unit,
      view
    ).pipe(
      tap((events) => {
        const entities = events.map((event) =>
          toEntityModel<IScheduleSummaryEventable>(event)
        );
        this._calendarEventsFacade.addDashboardEvents(entities);
      })
    );
  }

  private _getGaps$(
    brand: WithRef<IBrand>,
    day: ITimePeriod,
    staffer: WithRef<IStaffer>,
    practice: WithRef<IPractice>
  ): Observable<IScheduleSummaryEventable[]> {
    const timePeriods$ = combineLatest([of(day.from), of(day.to)]).pipe(
      stafferAllAvailableTimes(staffer, brand),
      map((timePeriods) => timePeriods.map(Gap.fromEventTimePeriod))
    );
    if (practice) {
      return timePeriods$.pipe(
        multiFilter((gap) => isSameRef(practice, gap.event.practice)),
        multiMap((gap) => buildGapEvent(gap.event, practice, staffer))
      );
    }
    return timePeriods$.pipe(
      multiMap((gap) => buildGapEvent(gap.event, practice, staffer))
    );
  }

  private _combineGapsAndEventables(
    events$: Observable<IScheduleSummaryEventable[]>,
    gaps$: Observable<IScheduleSummaryEventable[]>
  ): Observable<IScheduleSummaryEventable[]> {
    return combineLatest([events$, gaps$]).pipe(
      map(([events, gaps]) => {
        return [...events, ...gaps];
      })
    );
  }
}
