import { BigQueryTable } from '../../big-query-tables';
import {
  BaseDimensionMeasures,
  BaseDimensionTableJoin,
} from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import { CanBeChartedProperty, CanDoAllProperty } from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';

export class StafferDimensionMeasures extends BaseDimensionMeasures {
  name: CanDoAllProperty;

  constructor(
    table: BigQueryTable,
    id: string,
    tableJoin?: BaseDimensionTableJoin
  ) {
    super(table, id, tableJoin);
    this.name = this._getNameProperty();
  }

  get ref(): CanBeChartedProperty {
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('ref'),
        label: 'Practitioner Ref',
        summary: '',
      },
      docRef,
      this.buildQuery().attributes([docRef.attributePath]).get()
    );
  }

  private _getNameProperty(): CanDoAllProperty {
    const name = this.measureRef('user.name');
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix('name'),
        label: 'Practitioner Name',
        summary: '',
      },
      docRef,
      this.buildQuery()
        .attributes([name.attributePath, docRef.attributePath])
        .get(),
      name
    );
  }
}
