import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { roundTo2Decimals } from '@principle-theorem/accounting';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import {
  MOMENT_DATEPICKER_PROVIDERS,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import { type IPractice } from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import {
  type INamedDocument,
  mergeDayAndTime,
  snapshot,
  toTimestamp,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import * as moment from 'moment-timezone';
import { type Moment } from 'moment-timezone';
import { type Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { determineNewCreatedAtDate } from '../../transaction-helpers';
import { AmendTransactionDateStore } from '../transaction-edit-dialog/amend-transaction-date.store';
import { TransactionPracticeOptions } from '../transaction-practice-options';

export interface ITransactionAmountFormOptions {
  enableDateReceived: boolean;
  enableCurrentPractice: boolean;
  initialAmount?: number;
  disableAmount?: boolean;
}

export interface ITransactionAmountDialogData {
  title: string;
  max?: number;
  practice: INamedDocument<IPractice>;
  options: Partial<ITransactionAmountFormOptions>;
}

export interface ITransactionAmountDialogResult {
  amount: number;
  dateReceived: Moment;
  practiceRef: DocumentReference<IPractice>;
}

@Component({
  selector: 'pr-transaction-amount-dialog',
  templateUrl: './transaction-amount-dialog.component.html',
  styleUrls: ['./transaction-amount-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...MOMENT_DATEPICKER_PROVIDERS, AmendTransactionDateStore],
})
export class TransactionAmountDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  form: TypedFormGroup<ITransactionAmountDialogResult>;
  accountAdminPermission = PatientPermissions.AccountInvoiceAdmin;
  practices$: Observable<INamedDocument<IPractice>[]>;
  practiceOptions: TransactionPracticeOptions;
  options: ITransactionAmountFormOptions;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ITransactionAmountDialogData,
    private _dialogRef: MatDialogRef<
      TransactionAmountDialogComponent,
      ITransactionAmountDialogResult
    >,
    public amendDateStore: AmendTransactionDateStore,
    private _currentScope: CurrentScopeFacade
  ) {
    this.options = {
      enableCurrentPractice: false,
      enableDateReceived: false,
      initialAmount: data.max,
      ...data.options,
    };

    const initialAmount = this.options.initialAmount
      ? roundTo2Decimals(this.options.initialAmount)
      : undefined;
    const amountValidators = compact([
      Validators.required,
      data.max ? Validators.max(data.max) : undefined,
    ]);
    this.form = new TypedFormGroup<ITransactionAmountDialogResult>({
      amount: new TypedFormControl<number>(initialAmount, amountValidators),
      dateReceived: new TypedFormControl<Moment>(moment()),
      practiceRef: new TypedFormControl<DocumentReference<IPractice>>(),
    });

    if (data.options.disableAmount) {
      this.form.controls.amount.disable();
    }

    this.practiceOptions = new TransactionPracticeOptions(
      this._currentScope.currentPractice$,
      this.data.practice,
      this.options.enableCurrentPractice
    );
    this.practiceOptions.initialValue$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((practice) =>
        this.form.controls.practiceRef.setValue(practice.ref)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    const data = this.form.getRawValue();

    const dateReceived = data.dateReceived
      ? await determineNewCreatedAtDate(
          { createdAt: toTimestamp(), practiceRef: data.practiceRef },
          mergeDayAndTime(data.dateReceived, moment()),
          await snapshot(this.amendDateStore.minDate$),
          await snapshot(this.amendDateStore.maxDate$)
        )
      : moment();

    this._dialogRef.close({
      ...this.form.getRawValue(),
      dateReceived,
    });
  }
}
