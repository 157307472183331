import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BaseResolver } from '@principle-theorem/ng-shared';
import { MultiTreatmentConfiguration } from '@principle-theorem/principle-core';
import {
  type IMultiTreatmentConfiguration,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import { type CollectionReference } from '@principle-theorem/shared';
import { type WithRef } from '@principle-theorem/shared';

@Injectable()
export class StafferMultiTreatmentConfigurationCollectionResolverService extends BaseResolver<
  CollectionReference<IMultiTreatmentConfiguration>
> {
  resolve(
    route: ActivatedRouteSnapshot
  ): CollectionReference<IMultiTreatmentConfiguration> {
    const staffer = this._findInheritedRouteData<WithRef<IStaffer>>(
      route,
      'staffer'
    );
    if (!staffer) {
      throw new Error('No Staffer found');
    }
    return MultiTreatmentConfiguration.col(staffer);
  }
}
