import {
  IChartedMultiStepTreatment,
  IChartedMultiStepTreatmentStep,
  IChartedTreatment,
  ITreatmentFork,
  ITreatmentPlanProposal,
} from '@principle-theorem/principle-core/interfaces';
import { isSameRef } from '@principle-theorem/shared';
import { isEqual, uniqWith } from 'lodash';
import { ChartedMultiStepTreatment } from './charted-multi-step-treatment';

export class TreatmentPlanProposal {
  static init(
    overrides?: Partial<ITreatmentPlanProposal>
  ): ITreatmentPlanProposal {
    return {
      forks: [],
      treatments: [],
      multiTreatments: [],
      ...overrides,
    };
  }

  static addMultiTreatmentToMultiTreatmentStep(
    multiTreatments: IChartedMultiStepTreatment[],
    multiTreatment: IChartedMultiStepTreatment,
    multiTreatmentUid: string,
    treatmentStepUid: string
  ): IChartedMultiStepTreatment[] {
    return multiTreatments.map((currentMultiTreatment) => {
      if (currentMultiTreatment.uuid !== multiTreatmentUid) {
        return currentMultiTreatment;
      }

      const stepIndex = currentMultiTreatment.steps.findIndex(
        (step) => step.uid === treatmentStepUid
      );
      const position =
        stepIndex !== -1 ? stepIndex + 1 : multiTreatment.steps.length;
      currentMultiTreatment.steps.splice(position, 0, ...multiTreatment.steps);
      return currentMultiTreatment;
    });
  }

  static updateTreatmentsOnMultiTreatment(
    multiTreatments: IChartedMultiStepTreatment[],
    treatments: IChartedTreatment[],
    multiTreatment: IChartedMultiStepTreatment,
    treatmentStepUid: string
  ): IChartedMultiStepTreatment[] {
    return multiTreatments.map((currentMultiTreatment) => {
      if (currentMultiTreatment.uuid !== multiTreatment.uuid) {
        return currentMultiTreatment;
      }

      const treatmentStep: IChartedMultiStepTreatmentStep | undefined =
        ChartedMultiStepTreatment.findTreatmentStepById(
          multiTreatment,
          treatmentStepUid
        );

      if (!treatmentStep) {
        return currentMultiTreatment;
      }

      treatments.map((treatment) => {
        let treatmentFound = false;

        treatmentStep.treatments = treatmentStep.treatments.map(
          (currentTreatment) => {
            const isDifferentTreatment = !isSameRef(
              treatment.config,
              currentTreatment.config
            );
            const isSameScope = isEqual(
              treatment.scopeRef,
              currentTreatment.scopeRef
            );

            if (isDifferentTreatment || !isSameScope) {
              return currentTreatment;
            }

            treatmentFound = true;
            return {
              ...currentTreatment,
              ...treatment,
              chartedSurfaces: uniqWith(
                [
                  ...currentTreatment.chartedSurfaces,
                  ...treatment.chartedSurfaces,
                ],
                (surfaceA, surfaceB) =>
                  isEqual(surfaceA.chartedRef, surfaceB.chartedRef)
              ),
            };
          }
        );

        if (!treatmentFound) {
          treatmentStep.treatments = [...treatmentStep.treatments, treatment];
        }
      });

      currentMultiTreatment.steps = currentMultiTreatment.steps.map(
        (currentStep) => {
          if (currentStep.uid !== treatmentStep.uid) {
            return currentStep;
          }
          return {
            ...currentStep,
            ...treatmentStep,
          };
        }
      );

      return currentMultiTreatment;
    });
  }
}

/**
 * Treatment Fork
 *
 * Groups treatments/treatment groups? and multi-stage treatments to select
 * which one to add to the treatment plan. An optional can contain treatments
 * and multi-stage treatments however multi-stage treatments can't be
 * configured with options.
 */
export class TreatmentFork implements ITreatmentFork {
  options: IChartedMultiStepTreatment[] = [];
}
