import * as moment from 'moment-timezone';
import {
  MockCapitalInvestmentChargeStatistic,
  MockConsumablesExpendedStatistic,
  MockDentalDrawStatistic,
  MockEbitStatistic,
  MockOverheadsStatistic,
  MockRevenueKPIStatistic,
  MockProductivityStatistic,
  MockFraudScoreStatistic,
  MockGrossIncomeStatistic,
  MockStaffExpensesStatistic,
  MockYearOpenedStatistic,
} from './base-statistics';
import { type PracticeStatistics } from './practice-statistics';
import { type PatientStatistics } from './patient-statistics';
import { type ReferrerStatistics } from './referrer-statistics';
import {
  type IBrandStats,
  type IStatistics,
} from '@principle-theorem/reporting';

export class BrandStatistics implements IStatistics<IBrandStats> {
  name = ''; // Change to INamedDocument
  date: moment.Moment = moment();

  practices: PracticeStatistics[];
  patients: PatientStatistics[];
  referrers: ReferrerStatistics[];

  stats: IBrandStats = {
    ebit: MockEbitStatistic(),
    revenue: MockGrossIncomeStatistic(),
    dentalDraw: MockDentalDrawStatistic(),
    staffExpenses: MockStaffExpensesStatistic(),
    consumablesExpended: MockConsumablesExpendedStatistic(),
    overheads: MockOverheadsStatistic(),
    capitalInvestmentCharge: MockCapitalInvestmentChargeStatistic(),
    revenueKPI: MockRevenueKPIStatistic(),
    practitionerProductivity: MockProductivityStatistic(),
    fraudScore: MockFraudScoreStatistic(),
    yearOpened: MockYearOpenedStatistic(),
  };
}
