import { createAction, props } from '@ngrx/store';
import {
  type IAppointment,
  type IChartedItem,
  type IChartedRef,
  type IChartedSurface,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { type IClinicalChart } from '@principle-theorem/principle-core/interfaces';
import { type IEntityAction } from '@principle-theorem/ng-shared';
import { type SerialisedData, type WithRef } from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { type ChartId } from '../reducers/active-charts/chart-context-state';
import { ChartActionTypes } from './action-types';

export interface ILoadChartData extends IEntityAction<ChartId> {
  chartRef: DocumentReference<IClinicalChart>;
}

export interface ISetChart extends IEntityAction<ChartId> {
  chart: IClinicalChart;
}

export const setChart = createAction(
  ChartActionTypes.SetChart,
  props<SerialisedData<ISetChart>>()
);

export const loadChart = createAction(
  ChartActionTypes.LoadChart,
  props<SerialisedData<ILoadChartData>>()
);

export interface ILoadChartFromAppointmentData extends IEntityAction<ChartId> {
  appointment: WithRef<IAppointment>;
  patient: WithRef<IPatient>;
}

export const loadChartFromAppointment = createAction(
  ChartActionTypes.LoadChartFromAppointment,
  props<SerialisedData<ILoadChartFromAppointmentData>>()
);

export const resetChart = createAction(
  ChartActionTypes.ResetChart,
  props<SerialisedData<IEntityAction<ChartId>>>()
);

export const removeChart = createAction(
  ChartActionTypes.RemoveChart,
  props<SerialisedData<IEntityAction<ChartId>>>()
);

export interface ILoadChartSuccessData extends IEntityAction<ChartId> {
  chart: IClinicalChart;
}

export const loadChartSuccess = createAction(
  ChartActionTypes.LoadChartSuccess,
  props<SerialisedData<ILoadChartSuccessData>>()
);

export const saveChart = createAction(
  ChartActionTypes.SaveChart,
  props<IEntityAction<ChartId>>()
);

export interface IUpdateChartedSurfaces extends IEntityAction<ChartId> {
  chartedSurfaces: IChartedSurface[];
}

export const updateChartedSurfaces = createAction(
  ChartActionTypes.UpdateChartedSurfaces,
  props<SerialisedData<IUpdateChartedSurfaces>>()
);

export interface IRemoveChartedSurface extends IEntityAction<ChartId> {
  item: IChartedItem;
  chartedSurface: Partial<IChartedRef>;
}

export const removeChartedSurface = createAction(
  ChartActionTypes.RemoveChartedSurface,
  props<SerialisedData<IRemoveChartedSurface>>()
);

export interface IRemoveChartedSurfaces extends IEntityAction<ChartId> {
  item: IChartedItem;
  chartedSurfaces: Partial<IChartedRef>[];
}

export const removeChartedSurfaces = createAction(
  ChartActionTypes.RemoveChartedSurfaces,
  props<SerialisedData<IRemoveChartedSurfaces>>()
);
