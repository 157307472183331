<ng-container>
  <div
    class="calendar-heading layout-padding"
    fxLayout="row"
    fxLayoutAlign="end center"
  >
    <pr-calendar-view-selector
      [unit]="unit$ | ngrxPush"
     />
  </div>
</ng-container>
