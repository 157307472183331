import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ITransactionAction,
  ResolvedRefundTransactionOption,
  TransactionProviders,
  getTransactionActionData,
} from '@principle-theorem/ng-payments';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  RefundHelpers,
  withTransactions$,
} from '@principle-theorem/principle-core';
import {
  IInvoice,
  ITransaction,
  isCreditRefundLineItem,
  isCreditTransferLineItem,
} from '@principle-theorem/principle-core/interfaces';
import {
  WithRef,
  asyncForEach,
  multiMap,
  safeCombineLatest,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ITransactionActionsData } from '../transaction-providers/transaction-action';
import { TransactionActionFactoryService } from '../transaction-providers/transaction-action-factory.service';

interface IRefundableTransactionOption<T = unknown> {
  transaction: WithRef<ITransaction<T>>;
  action: ITransactionAction<T>;
  data: ITransactionActionsData<T>;
}

@Component({
  selector: 'pr-add-refund-button',
  templateUrl: './add-refund-button.component.html',
  styleUrls: ['./add-refund-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddRefundButtonComponent {
  invoice$ = new ReplaySubject<WithRef<IInvoice>>(1);
  canCapture$: Observable<boolean>;
  adding$: Observable<boolean>;
  isDisabled$: Observable<boolean>;
  info$: Observable<string | undefined>;
  options: ResolvedRefundTransactionOption[];
  refundableTransactions$: Observable<IRefundableTransactionOption[]>;
  trackByRefundable = TrackByFunctions.ref('transaction.ref');

  @Input()
  set invoice(invoice: WithRef<IInvoice>) {
    if (invoice) {
      this.invoice$.next(invoice);
    }
  }

  constructor(
    public transactions: TransactionProviders,
    private _transactionActions: TransactionActionFactoryService
  ) {
    const invoiceAndTransactions$ = this.invoice$.pipe(
      switchMap((invoice) => withTransactions$(invoice))
    );

    const refundRemaining$ = invoiceAndTransactions$.pipe(
      map(([_invoice, invoiceTransactions]) =>
        RefundHelpers.getTotalRefundRemaining(invoiceTransactions)
      )
    );

    this.canCapture$ = refundRemaining$.pipe(map((remaining) => remaining > 0));
    this.isDisabled$ = combineLatest([this.canCapture$, this.invoice$]).pipe(
      map(
        ([canCapture, invoice]) =>
          !canCapture ||
          invoice.items.some(isCreditRefundLineItem) ||
          invoice.items.some(isCreditTransferLineItem)
      )
    );

    this.info$ = refundRemaining$.pipe(
      map((remaining) => `$${remaining.toFixed(2)} available`)
    );

    this.refundableTransactions$ = invoiceAndTransactions$.pipe(
      switchMap(([_invoice, invoiceTransactions]) =>
        this._getRefundableTransactions(invoiceTransactions)
      )
    );

    this.adding$ = this.refundableTransactions$.pipe(
      multiMap((refundable) => refundable.action.inProgress$),
      switchMap((inProgress$) => safeCombineLatest(inProgress$)),
      map((inProgress) => inProgress.some((v) => v))
    );
  }

  private async _getRefundableTransactions(
    transactions: WithRef<ITransaction<unknown>>[]
  ): Promise<IRefundableTransactionOption[]> {
    const refundables = await asyncForEach(
      transactions,
      async (transaction) => {
        const action = this._transactionActions.getRefundAction(transaction);
        if (!action) {
          return undefined;
        }
        const data = await getTransactionActionData(transaction);
        return { transaction, action, data };
      }
    );

    return compact(refundables);
  }
}
