import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { ReportingCoreModule } from '../../core/reporting-core.module';
import { ReportingComponentsModule } from '../../reporting-components/reporting-components.module';
import { PractitionerIncomeSelectorComponent } from './practitioner-income-selector/practitioner-income-selector.component';
import { PractitionerIncomeTableComponent } from './practitioner-income-table/practitioner-income-table.component';
import { PractitionerIncomeReportEffects } from './store/practitioner-income.effects';
import { PractitionerIncomeReportFacade } from './store/practitioner-income.facade';
import {
  metaReducers,
  PRACTITIONER_INCOME_REPORT_FEATURE_KEY,
  reducers,
} from './store/practitioner-income.reducers';
import { PractitionerIncomeReportTypeSelectorComponent } from './practitioner-income-report-type-selector/practitioner-income-report-type-selector.component';
import { PractitionerIncomeReportComponent } from './practitioner-income-report.component';
import { PractitionerTransactionsReportComponent } from './practitioner-transactions-report/practitioner-transactions-report.component';
import { PractitionerTransactionsReportSummaryComponent } from './practitioner-transactions-report/practitioner-transactions-report-summary/practitioner-transactions-report-summary.component';
import { PractitionerTransactionsReportDrilldownComponent } from './practitioner-transactions-report/practitioner-transactions-report-drilldown/practitioner-transactions-report-drilldown.component';
import { PractitionerTransactionsReportComplexDrilldownComponent } from './practitioner-transactions-report/practitioner-transactions-report-complex-drilldown/practitioner-transactions-report-complex-drilldown.component';
import { PractitionerIncomeInspectorComponent } from './practitioner-income-inspector/practitioner-income-inspector.component';
import { PractitionerTransactionsReportStore } from './practitioner-transactions-report/practitioner-transactions-report.store';
import { AccountCreditsReportModule } from '../account-credits/account-credits-report.module';
import { PractitionerTransactionsReportSummaryTableComponent } from './practitioner-transactions-report/practitioner-transactions-report-summary/practitioner-transactions-report-summary-table/practitioner-transactions-report-summary-table.component';

@NgModule({
  imports: [
    RouterModule,
    ReportingCoreModule,
    ReportingComponentsModule,
    AccountCreditsReportModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    StoreModule.forFeature(PRACTITIONER_INCOME_REPORT_FEATURE_KEY, reducers, {
      metaReducers,
    }),
    EffectsModule.forFeature([PractitionerIncomeReportEffects]),
  ],
  declarations: [
    PractitionerIncomeReportComponent,
    PractitionerIncomeTableComponent,
    PractitionerIncomeSelectorComponent,
    PractitionerIncomeInspectorComponent,
    PractitionerIncomeReportTypeSelectorComponent,
    PractitionerTransactionsReportComponent,
    PractitionerTransactionsReportSummaryComponent,
    PractitionerTransactionsReportSummaryTableComponent,
    PractitionerTransactionsReportDrilldownComponent,
    PractitionerTransactionsReportComplexDrilldownComponent,
  ],
  providers: [
    PractitionerIncomeReportFacade,
    PractitionerTransactionsReportStore,
  ],
})
export class PractitionerIncomeReportModule {}
