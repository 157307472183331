import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard } from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from './../../../source/source-entity';
import { PraktikaUserTitle } from './lib/patient-associated-types';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';

export const STAFFER_SOURCE_ENTITY: ISourceEntity = SourceEntity.init({
  metadata: {
    label: 'Staffer List',
    description: '',
    idPrefix: STAFFER_RESOURCE_TYPE,
    migrationType: SourceEntityMigrationType.Automatic,
  },
});

export interface IPraktikaStaffer {
  id: number;
  title: PraktikaUserTitle;
  first_name: string;
  last_name: string;
  initials: string;
  position_id: string;
  position: string;
  is_primary_practice: boolean;
  is_provider: true;
  provider_number: string | null;
  colour: string; // Hex colour code. Eg: #cc26cc
  start_date: string; // '2015-01-17'
  resignation_date: string | null;
}

function isPraktikaStafferListItem(item: unknown): item is IPraktikaStaffer {
  return TypeGuard.interface<IPraktikaStaffer>({
    id: isNumber,
    title: TypeGuard.enumValue(PraktikaUserTitle),
    first_name: isString,
    last_name: isString,
    initials: isString,
    position_id: isString,
    position: isString,
    is_primary_practice: isBoolean,
    is_provider: isBoolean,
    provider_number: TypeGuard.nilOr(isString),
    colour: isString,
    start_date: isString,
    resignation_date: TypeGuard.nilOr(isString),
  })(item);
}

const STAFFER_SOURCE_QUERY = `
SELECT
  convert_to_integer(iStaffId) as id,
  vchTitle as title,
  vchFirstName as first_name,
  vchLastName as last_name,
  vchInitials as initials,
  vchPosition as position_id,
  vchPositionDesc as position,
  bPrimaryPractice as is_primary_practice,
  bProvider as is_provider,
  vchProviderNo as provider_number,
  vchColour as colour,
  dtStartDate as start_date,
  dtResignationDate as resignation_date
FROM practice_staff
ORDER BY id
`;

export class StafferSourceEntity extends BaseSourceEntity<IPraktikaStaffer> {
  sourceEntity = STAFFER_SOURCE_ENTITY;
  entityResourceType = STAFFER_RESOURCE_TYPE;
  sourceQuery = STAFFER_SOURCE_QUERY;
  verifySourceFn = isPraktikaStafferListItem;
  override transformDataFn = flow([]);

  translate(_staffer: IPraktikaStaffer): object {
    return {};
  }

  getSourceRecordId(data: IPraktikaStaffer): number {
    return data.id;
  }

  getSourceLabel(data: IPraktikaStaffer): string {
    return `${data.first_name} ${data.last_name}`;
  }
}
