import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IClaimEstimateProvider } from '../claim-estimate-provider';
import { TrackByFunctions } from '@principle-theorem/ng-shared';

@Component({
  selector: 'pr-select-claim-provider',
  templateUrl: './select-claim-provider.component.html',
  styleUrls: ['./select-claim-provider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectClaimProviderComponent {
  trackByProvider =
    TrackByFunctions.field<IClaimEstimateProvider>('providerId');

  constructor(
    @Inject(MAT_DIALOG_DATA) public providers: IClaimEstimateProvider[]
  ) {}
}
