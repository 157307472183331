<pt-breadcrumbs current="Follow Ups" class="bg-white" />
<div class="border-b border-solid border-slate-300 bg-white px-2 pt-0">
  <nav
    mat-tab-nav-bar
    class="mx-auto max-w-[1000px] !border-none"
    [tabPanel]="tabPanel"
  >
    <a mat-tab-link (click)="showOverdue()" [active]="selected() === 'overdue'">
      Due
    </a>
    <a
      mat-tab-link
      (click)="showUpcoming()"
      [active]="selected() === 'upcoming'"
    >
      Upcoming
    </a>
  </nav>
</div>

<mat-tab-nav-panel
  #tabPanel
  class="mx-auto mb-4 flex max-w-[1000px] flex-col gap-4 px-2 sm:px-0"
>
  @if (isEmpty$ | async) {
    <pr-empty-state image="list" title="follow ups" />
  } @else {
    <pr-follow-up-list-view [followUps]="followUps$ | async" />
  }
</mat-tab-nav-panel>
