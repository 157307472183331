import { createAction, props } from '@ngrx/store';
import {
  ITreatmentStep,
  type AutomationStatus,
  type AutomationType,
  type IAutomatedNotification,
  type IEvent,
  type IGeneratedTask,
  type ITemplateScopeData,
  type TemplateScope,
} from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData } from '@principle-theorem/shared';
import {
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';

export enum AutomationListAction {
  AddTask = '[AutomationsList] Add Task',
  EditTask = '[AutomationsList] Edit Task',
  AddFromTemplate = '[AutomationsList] Add From Template',
  AddNotification = '[AutomationsList] Add Notification',
  EditNotification = '[AutomationsList] Edit Notification',
  SetStatusFilter = '[AutomationsList] Set Status Filter',
  SetTypeFilter = '[AutomationsList] Set Type Filter',
  AddSelectedId = '[AutomationsList] Add Selected Id',
  RemoveSelectedId = '[AutomationsList] Remove Selected Id',
  SelectMultiple = '[AutomationsList] Select Multiple',
  UnselectAll = '[AutomationsList] UnSelect All',
  CancelSelected = '[AutomationsList] Cancel Selected',
  CancelSelectedConfirmed = '[AutomationsList] Cancel Selected Confirmed',
  SetSortingId = '[AutomtationsList] Set Sorting Id',
  SetPracticeFilter = '[AutomationsList] Set Practice Filter',
}

interface IAddGeneratedTask {
  event: IEvent;
  stepRef?: DocumentReference<ITreatmentStep>;
  triggerAfterDate?: Timestamp;
}

export const addGeneratedTask = createAction(
  AutomationListAction.AddTask,
  props<SerialisedData<IAddGeneratedTask>>()
);

interface IEditGeneratedTask {
  id: string;
  generatedTask: IGeneratedTask;
  triggerDate?: Timestamp;
  triggerAfterDate?: Timestamp;
  event: IEvent;
  skipEffect: boolean;
  useRelativeTime: boolean;
}

export const editGeneratedTask = createAction(
  AutomationListAction.EditTask,
  props<SerialisedData<IEditGeneratedTask>>()
);

interface IAddNotification {
  scope: TemplateScope;
  scopeData: ITemplateScopeData;
  event: IEvent;
  stepRef?: DocumentReference<ITreatmentStep>;
  triggerAfterDate?: Timestamp;
}

export const addNotification = createAction(
  AutomationListAction.AddNotification,
  props<SerialisedData<IAddNotification>>()
);

interface IAddFromTemplate {
  scope: TemplateScope;
  event: IEvent;
  stepRef?: DocumentReference<ITreatmentStep>;
}

export const addFromTemplate = createAction(
  AutomationListAction.AddFromTemplate,
  props<SerialisedData<IAddFromTemplate>>()
);

interface IEditNotification {
  id: string;
  notification: IAutomatedNotification;
  triggerDate?: Timestamp;
  triggerAfterDate?: Timestamp;
  event: IEvent;
  skipEffect: boolean;
  useRelativeTime: boolean;
  configRef?: DocumentReference<IAutomatedNotification>;
}

export const editNotification = createAction(
  AutomationListAction.EditNotification,
  props<SerialisedData<IEditNotification>>()
);

interface ISetStatusFilter {
  statusFilter: AutomationStatus[];
}

export const setStatusFilter = createAction(
  AutomationListAction.SetStatusFilter,
  props<ISetStatusFilter>()
);

interface ISetTypeFilter {
  typeFilter: AutomationType;
}

export const setTypeFilter = createAction(
  AutomationListAction.SetTypeFilter,
  props<ISetTypeFilter>()
);

export const setPracticeFilter = createAction(
  AutomationListAction.SetPracticeFilter,
  props<{ practiceFilter: string | undefined }>()
);

interface ISelectAutomation {
  id: string;
}

export const selectAutomation = createAction(
  AutomationListAction.AddSelectedId,
  props<ISelectAutomation>()
);

export const unselectAutomation = createAction(
  AutomationListAction.RemoveSelectedId,
  props<ISelectAutomation>()
);

interface ISelectMultipleAutomations {
  ids: string[];
}

export const selectMultipleAutomations = createAction(
  AutomationListAction.SelectMultiple,
  props<ISelectMultipleAutomations>()
);
export const unselectAll = createAction(AutomationListAction.UnselectAll);

export const cancelSelected = createAction(AutomationListAction.CancelSelected);

export const cancelSelectedConfirmed = createAction(
  AutomationListAction.CancelSelectedConfirmed,
  props<ISelectMultipleAutomations>()
);
