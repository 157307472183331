import { type ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  InjectionToken,
  Injector,
  Input,
  type StaticProvider,
  type Type,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
} from '@angular/core';
import { DynamicComponentPortalDirective } from './dynamic-component-portal.directive';

export interface IDynamicComponentRenderer<T, D> {
  component: Type<T>;
  isFor(item: D): boolean;
}

export function findRenderer<T, D>(
  renderers: IDynamicComponentRenderer<T, D>[],
  item: D
): IDynamicComponentRenderer<T, D> | undefined {
  return renderers.find((renderer) => renderer.isFor(item));
}

export function filterRenderers<T, D>(
  renderers: IDynamicComponentRenderer<T, D>[],
  item: D
): IDynamicComponentRenderer<T, D>[] {
  return renderers.filter((renderer) => renderer.isFor(item));
}

/**
 * Used for creating components programatically by providing a component and
 * any data that should be set on the component after initialisation.
 */
export type ComponentLoader<Q, T extends object> = {
  component: ComponentType<Q>;
  data?: T;
};

/**
 * Injection token that can be used to access the data that was passed in.
 */
export const DYNAMIC_COMPONENT_DATA = new InjectionToken<unknown>(
  'DynamicComponentData'
);

@Component({
  selector: 'pt-dynamic-component',
  templateUrl: './dynamic-component.component.html',
  styleUrls: ['./dynamic-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicComponentComponent<Q, T extends object> {
  private _componentRef?: ComponentRef<Q>;
  @ViewChild(DynamicComponentPortalDirective, { static: true })
  portal: DynamicComponentPortalDirective;

  constructor(private _viewContainerRef: ViewContainerRef) {}

  @Input()
  set definition(definition: ComponentLoader<Q, T>) {
    if (definition) {
      this._loadComponent(definition);
    }
  }

  private _loadComponent(definition: ComponentLoader<Q, T>): void {
    const viewContainerRef = this.portal.viewContainerRef;
    viewContainerRef.clear();
    if (this._componentRef) {
      this._componentRef.destroy();
    }
    this._componentRef = this._viewContainerRef.createComponent<Q>(
      definition.component,
      {
        injector: this._createInjector(viewContainerRef, definition),
      }
    );
  }

  private _createInjector(
    viewContainerRef: ViewContainerRef,
    definition: ComponentLoader<Q, T>
  ): Injector {
    const providers: StaticProvider[] = [
      { provide: DYNAMIC_COMPONENT_DATA, useValue: definition.data },
    ];
    return Injector.create({
      parent: viewContainerRef.injector,
      providers,
    });
  }
}
