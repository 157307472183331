<div class="page-wrap">
  <div class="display-box">
    @if (state.isLoading$ | async) {
      <div class="layout-padding flex flex-col items-center justify-center">
        <mat-spinner />
        <h3>Loading Appointment Offer Details</h3>
      </div>
    } @else if (state.isNotAvailable$ | async) {
      <pr-empty-state
        image="broken"
        title="gap offer found"
        [descriptionEnabled]="false"
      />
    }

    @if (data$ | async; as data) {
      @if (state.isConfirmed$ | async) {
        <div class="flex flex-col items-center justify-center">
          <div class="confirmation-message flex flex-wrap justify-center gap-4">
            <mat-icon>check_circle_outline</mat-icon>
            <h1>Appointment Confirmed</h1>
          </div>
          <pr-patient-gap-offer-details [data]="data" />
        </div>
      } @else if (state.isAlreadyFilled$ | async) {
        <div class="layout-padding flex items-center justify-center">
          <pr-patient-gap-offer-details [data]="data" />
        </div>
      }
    }
  </div>
</div>
