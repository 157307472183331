/* eslint-disable no-console */
import {
  type IMigration,
  type IMigrationLogger,
} from '@principle-theorem/migration-runner';
import { resolveDataSource } from '@principle-theorem/ng-reporting';
import {
  BrandCollection,
  ICustomChartSettings,
  ICustomReportChart,
  ReportingReference,
  type ICustomReport,
  ICustomReportChartSection,
  CustomChartType,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  bufferedQuery$,
  collectionGroupQuery,
  multiConcatMap,
  type DocumentReference,
  type WithRef,
  isObject,
} from '@principle-theorem/shared';
import { ReportBuilderDataSource } from 'libs/ng-reporting/src/lib/models/report-builder-data-sources/report-builder-data-source';
import { compact, has, omit } from 'lodash';
import { type Observable } from 'rxjs';

export interface ICustomReportChartV1 extends ICustomReportChart {
  id: string;
  groupBy: ReportingReference;
}

export interface ICustomReportV1 extends Omit<ICustomReport, 'chartSections'> {
  chartSections?: {
    uid: string;
    name: string;
    charts: ICustomReportChartV1[];
  }[];
}

function isOldReport(
  report: WithRef<ICustomReportV1> | WithRef<ICustomReport>
): report is WithRef<ICustomReportV1> {
  const charts =
    report.chartSections?.flatMap((section) => section.charts).flat() ?? [];
  if (!charts.length) {
    return false;
  }
  return charts.some((chart) => {
    return !has(chart, 'groupBy');
  });
}

export class RefactorCustomReportsToDefineOwnCharts implements IMigration {
  async up(dryRun: boolean, logger: IMigrationLogger): Promise<void> {
    await this._getCustomReports$()
      .pipe(
        multiConcatMap((report) => this._customReportUp(dryRun, logger, report))
      )
      .toPromise();
  }

  down(_dryRun: boolean, _logger: IMigrationLogger): Promise<void> {
    throw new Error('Method not implemented.');
  }

  private _getCustomReports$(
    bufferSize: number = 25
  ): Observable<WithRef<ICustomReportV1>[]> {
    const query = collectionGroupQuery<ICustomReportV1>(
      BrandCollection.CustomReports
    );
    return bufferedQuery$(query, bufferSize, 'ref');
  }

  private async _customReportUp(
    dryRun: boolean,
    logger: IMigrationLogger,
    report: WithRef<ICustomReportV1> | WithRef<ICustomReport>
  ): Promise<void> {
    if (!report.chartSections?.length) {
      return;
    }

    if (!isOldReport(report)) {
      console.log('already migrated', report);
      return;
    }

    const dataSource = resolveDataSource(report.dataSource);
    if (!dataSource) {
      logger.error(`Couldn't determine datasource for: ${report.ref.path}`);
      return;
    }
    logger.debug(
      `Updating datasource on: ${report.ref.path} to ${dataSource.id}`
    );

    const chartSections: ICustomReportChartSection[] = (
      report.chartSections ?? []
    ).map((section) => ({
      ...section,
      charts: compact(
        section.charts.map((chart) => {
          if (chart.id === CustomChartType.NumberSummary) {
            const settings = isObject(chart.settings)
              ? [chart.settings as unknown as ICustomChartSettings]
              : chart.settings ?? [];
            return {
              ...chart,
              type: CustomChartType.NumberSummary,
              groupBy: dataSource.factTable.count.metadata.id,
              settings: compact(
                settings.map((setting) => {
                  const column = ReportBuilderDataSource.findColumn(
                    dataSource,
                    setting.dataPoint
                  );
                  if (!column) {
                    console.error(
                      `No column found for ${dataSource.id} - ${setting.dataPoint}`
                    );
                    return;
                  }

                  return {
                    ...setting,
                    dataPoint: column.measure.metadata.id,
                  };
                })
              ),
            };
          }

          const existingDataSourceChart = dataSource.availableCharts.find(
            (availableChart) => availableChart.id === chart.id
          );

          if (!existingDataSourceChart) {
            console.error(
              `No existingDataSourceChart for ${dataSource.id} - ${chart.id}`,
              chart
            );
            return;
          }

          const { builderConfig, builderData } = existingDataSourceChart.build(
            dataSource.factTable,
            chart
          );

          const newChart: ICustomReportChart<ICustomChartSettings> = {
            ...chart,
            type: existingDataSourceChart.chart.type,
            groupBy: existingDataSourceChart.groupBy.metadata.id,
            settings: existingDataSourceChart.chart.settings,
            builderConfig,
            builderData: omit(builderData, ['measures', 'groupByDimension']),
          };

          return newChart;
        })
      ),
    }));

    console.log('chartSections updated', report.chartSections, chartSections);

    if (!dryRun) {
      console.log('updating', report.ref.path);
      await Firestore.patchDoc(report.ref as DocumentReference<ICustomReport>, {
        chartSections,
      });
    }
  }
}
