import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { FeeScheduleCollectionResolver } from '@principle-theorem/ng-clinical-fees';
import { CurrentPracticeScope } from '@principle-theorem/ng-principle-shared';
import { FeeSchedule } from '@principle-theorem/principle-core';
import { type IFeeSchedule } from '@principle-theorem/principle-core/interfaces';
import { type CollectionReference } from '@principle-theorem/shared';

@Injectable()
export class PracticeFeeScheduleResolver extends FeeScheduleCollectionResolver {
  constructor(private _router: Router) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot
  ): CollectionReference<IFeeSchedule> | undefined {
    const currentPractice = new CurrentPracticeScope(this._router);
    const practice = currentPractice.findModel(route);
    if (!practice) {
      return;
    }
    return FeeSchedule.col(practice);
  }
}
