import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'pr-save-custom-report-dialog',
    templateUrl: './save-custom-report-dialog.component.html',
    styleUrl: './save-custom-report-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SaveCustomReportDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SaveCustomReportDialogComponent>
  ) {}
}
