import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  inject,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgInteractionsModule } from '@principle-theorem/ng-interactions';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  NgPrincipleSharedModule,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  NgSharedModule,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import { Practice } from '@principle-theorem/principle-core';
import {
  IStaffer,
  ISterilisationCycleType,
  type IPractice,
  type ISterilisationCycle,
  type ISterilisationMachine,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  INamedDocument,
  WithRef,
  filterUndefined,
  isSameRef,
  snapshot,
  to24hrTime,
  toISODate,
  toMoment,
} from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import * as moment from 'moment-timezone';
import { Observable, Subject, from, of } from 'rxjs';
import {
  concatMap,
  filter,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { SterilisationCycleForm } from './sterilisation-cycle-form';

export interface ISterilisationCycleDialogData {
  machines: WithRef<ISterilisationMachine>[];
  practice: WithRef<IPractice>;
  cycleTypes: WithRef<ISterilisationCycleType>[];
  cycle?: WithRef<ISterilisationCycle>;
}

@Component({
  selector: 'pr-sterilisation-cycle-dialog',
  imports: [
    CommonModule,
    NgMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    NgInteractionsModule,
  ],
  templateUrl: './sterilisation-cycle-dialog.component.html',
  styleUrl: './sterilisation-cycle-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SterilisationCycleDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _dialogRef = inject(MatDialogRef<SterilisationCycleDialogComponent>);
  readonly data = inject<ISterilisationCycleDialogData>(MAT_DIALOG_DATA);
  orgService = inject(OrganisationService);
  latestCycleId$: Observable<string | undefined>;
  isTestCycle$: Observable<boolean>;
  selectedCycleType$: Observable<
    INamedDocument<ISterilisationCycleType> | undefined
  >;
  staffer$: Observable<WithRef<IStaffer>>;

  maxDate = moment();
  form = new SterilisationCycleForm();

  constructor() {
    this.staffer$ = this.orgService.staffer$.pipe(filterUndefined());
    this.staffer$
      .pipe(take(1), takeUntil(this._onDestroy$))
      .subscribe((staffer) =>
        this.form.controls.staffer.setValue(this.data.cycle?.staffer ?? staffer)
      );

    this.initialiseForm();

    this.latestCycleId$ = this.form.controls.machine.valueChanges.pipe(
      startWith(this.form.controls.machine.value),
      filter((machine): machine is WithRef<ISterilisationMachine> => !!machine),
      concatMap(async (machine) => {
        const cycleRecord = await Practice.getLatestCycleRecord(
          this.data.practice,
          machine.ref
        );
        return cycleRecord ? String(Number(cycleRecord.id) + 1) : '1';
      })
    );

    this.latestCycleId$.pipe(takeUntil(this._onDestroy$)).subscribe((id) => {
      if (this.data.cycle) {
        return;
      }
      this.form.controls.id.setValue(id);
    });

    const cycleType$ = formControlChanges$(this.form.controls.cycleType);
    this.isTestCycle$ = cycleType$.pipe(
      switchMap((cycleType) =>
        !cycleType
          ? of(false)
          : from(Firestore.getDoc(cycleType.ref)).pipe(
              map(({ isTestType }) => !!isTestType)
            )
      )
    );
    this.selectedCycleType$ = cycleType$.pipe(
      startWith(this.data.cycleTypes[0])
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }
    this._dialogRef.close(this.form.toSterilisationCycle(this.data.cycle));
  }

  initialiseForm(): void {
    if (this.data.cycle) {
      this.form.patchValue({
        ...this.data.cycle,
        cycleRunDate: toISODate(toMoment(this.data.cycle.runDate)),
        cycleRunTime: to24hrTime(this.data.cycle.runDate),
      });
      this.form.controls.id.disable();
      return;
    }

    this.form.patchValue({
      machine: this.data.machines[0],
      cycleType: this.data.cycleTypes[0],
    });
    if (this.data.machines.length === 1) {
      this.form.controls.machine.disable();
    }
    if (this.data.cycleTypes.length === 1) {
      this.form.controls.cycleType.disable();
    }
  }

  async resetOnEmpty(): Promise<void> {
    const id = this.form.controls.id.value;
    if (id || this.data.cycle) {
      return;
    }
    const latestId = await snapshot(this.latestCycleId$);
    this.form.controls.id.setValue(latestId);
  }

  isSelectedNamedDocument(
    a: INamedDocument<ISterilisationMachine>,
    b: INamedDocument<ISterilisationMachine>
  ): boolean {
    try {
      return isSameRef(a, b);
    } catch (error) {
      return isEqual(a, b);
    }
  }
}
