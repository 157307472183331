import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { Appointment } from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  safeCombineLatest,
  TIME_FORMAT,
  type WithRef,
} from '@principle-theorem/shared';
import { from, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IRelatedAppointmentsDialogData {
  appointments: WithRef<IAppointment>[];
}

interface IAppointmentLink {
  patient: WithRef<IPatient>;
  appointment: WithRef<IAppointment>;
  linkSegments: string[];
}

@Component({
  selector: 'pr-related-appointments-dialog',
  templateUrl: './related-appointments-dialog.component.html',
  styleUrls: ['./related-appointments-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedAppointmentsDialogComponent {
  links$: Observable<IAppointmentLink[]>;
  trackByLink = TrackByFunctions.ref<IAppointmentLink>('appointment.ref');
  dateFormat = DAY_MONTH_YEAR_FORMAT;
  timeFormat = TIME_FORMAT;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IRelatedAppointmentsDialogData
  ) {
    this.links$ = safeCombineLatest(
      data.appointments.map((appointment) =>
        this._getAppointmentLink$(appointment)
      )
    );
  }

  private _getAppointmentLink$(
    appointment: WithRef<IAppointment>
  ): Observable<IAppointmentLink> {
    return from(Appointment.patient(appointment)).pipe(
      map((patient) => ({
        appointment,
        patient,
        linkSegments: [
          'patients',
          patient.ref.id,
          'appointments',
          appointment.ref.id,
          'outgoing',
        ],
      }))
    );
  }
}
