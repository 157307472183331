import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  IHealthcareClaim,
  IInvoice,
  IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  BuildClaimDialogComponent,
  IBuildHealthcareClaimRequest,
} from './build-claim-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  IResolvedClaimItem,
  isReadyToBeClaimed,
} from '@principle-theorem/principle-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WithRef } from '@principle-theorem/shared';
import { Injectable } from '@angular/core';

@Injectable()
export class BuildClaimDialogService {
  constructor(
    private _dialog: MatDialog,
    private _snackbar: MatSnackBar
  ) {}

  async open(
    invoice: IInvoice,
    practitioner: WithRef<IStaffer>,
    unclaimedItems: IResolvedClaimItem[],
    submitLabel: string = 'Claim'
  ): Promise<IHealthcareClaim | undefined> {
    const data: IBuildHealthcareClaimRequest = {
      submitLabel,
      invoice,
      unclaimedItems,
      practitioner,
    };

    const claim = await this._dialog
      .open<
        BuildClaimDialogComponent,
        IBuildHealthcareClaimRequest,
        IHealthcareClaim
      >(
        BuildClaimDialogComponent,
        DialogPresets.medium({ height: '80%', data })
      )
      .afterClosed()
      .toPromise();
    if (!claim) {
      return;
    }

    if (!isReadyToBeClaimed(claim)) {
      this._snackbar.open('Claim is missing Provider Number');
      return;
    }

    return claim;
  }
}
