<pt-breadcrumbs
  [breadcrumbs]="breadcrumbs"
  current="Reconciliation"
  class="bg-white"
/>
<div class="border-b border-solid border-slate-300"></div>

<div class="layout-padding">
  <div fxLayout="column">
    <h2 class="layout-margin">Reconciliation Report</h2>
    <pr-reconciliation-report-controls />

    <div
      fxFlex
      fxLayout="row"
      fxLayoutAlign="center center"
      *ngIf="reportFacade.loading$ | async; else showPage"
    >
      <mat-spinner mode="indeterminate" />
    </div>

    <ng-template #showPage>
      <pr-principle-reconciliation-report
        [report]="reportFacade.principleReport$ | async"
      />
    </ng-template>
  </div>
</div>
