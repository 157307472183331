import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  datePaginationQueryParam$,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import {
  type IBreadcrumb,
  StorageResponseAPI,
} from '@principle-theorem/ng-shared';
import {
  CustomChartType,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import {
  type CanBeChartedProperty,
  ChartType,
  FactTables,
  generateBuilderData,
  type IChartConfig,
  toMeasureBuilderData,
} from '@principle-theorem/reporting';
import {
  errorNil,
  HISTORY_DATE_FORMAT,
  toMoment,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { type IChartCard } from '../../models/report/charts/chart-card';
import { DateRangeDataBuilder } from '../../models/report/data-builders/date-range-data-builder';
import {
  type ChartComponentLoader,
  resolveDynamicChartDefinition,
} from '../core/chart-builders/dynamic-chart-resolver';
import { type ITableHeaderReplacement } from '../reporting-components/table-chart/table-chart.component';

@Component({
  selector: 'pr-daily-dashboard',
  templateUrl: './daily-dashboard.component.html',
  styleUrls: ['./daily-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DailyDashboardComponent implements OnInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  readonly dateFormat = HISTORY_DATE_FORMAT;
  breadcrumbs: IBreadcrumb[] = [{ label: 'Reporting' }];
  date$ = new BehaviorSubject<moment.Moment>(moment());
  dataBuilder: DateRangeDataBuilder;
  replacementHeaders: ITableHeaderReplacement[] = [
    { from: 'Owner', to: 'Practitioner' },
  ];

  totalRevenueMeasure: CanBeChartedProperty;
  totalRevenueChart: IChartCard;

  newPatientsMeasure: CanBeChartedProperty;
  newPatientsChart: IChartCard;

  totalReschedulesMeasure: CanBeChartedProperty;
  totalReschedulesChart: IChartCard;

  totalNewAppointmentsMeasure: CanBeChartedProperty;
  totalNewAppointmentsChart: IChartCard;

  totalCancellationsMeasure: CanBeChartedProperty;
  totalCancellationsChart: IChartCard;

  // patientDistribution$: Observable<IDCChartDisplay<unknown>>;
  // revenueDistribution$: Observable<IDCChartDisplay<unknown>>;
  // timeSpent$: Observable<IDCChartDisplay<unknown>>;
  // cancellations$: Observable<IDCChartDisplay<unknown>>;

  testChart: ChartComponentLoader;
  patientFlow: ChartComponentLoader;
  // patientFlow$: Observable<IChartDisplay<unknown>>;

  // tableChart: IChartCard;

  constructor(
    private _organisation: OrganisationService,
    private _route: ActivatedRoute,
    api: StorageResponseAPI
  ) {
    this.dataBuilder = new DateRangeDataBuilder(
      api,
      moment().startOf('day'),
      moment().endOf('day'),
      this._organisation.brand$.pipe(errorNil()),
      this._organisation.userPractices$
    );

    datePaginationQueryParam$(this._route)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((date) => this.date$.next(date));

    this.date$
      .pipe(
        map((date) => ({
          from: moment(date).startOf('day'),
          to: moment(date).endOf('day'),
        })),
        takeUntil(this._onDestroy$)
      )
      .subscribe((range) => {
        this.dataBuilder.updateRange(range.from, range.to);
        this.loadCharts();
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    this.loadCharts();
  }

  updateRange($event: moment.Moment | Date): void {
    this.date$.next(toMoment($event));
  }

  loadCharts(): void {
    this._buildSingleValueCharts();
    // this._buildTableData();

    // this.patientDistribution$ = buildDCChartDisplay$(
    //   PATIENT_DISTRIBUTION_PIE,
    //   this.dataBuilder,
    //   new DcPieChartBuilder()
    // );

    // this.revenueDistribution$ = buildDCChartDisplay$(
    //   REVENUE_DISTRIBUTION,
    //   this.dataBuilder,
    //   new DcPieChartBuilder()
    // );

    // this.timeSpent$ = buildDCChartDisplay$(
    //   PRACTITIONER_TIME,
    //   this.dataBuilder,
    //   new DcRowChartBuilder()
    // );

    // this.cancellations$ = buildDCChartDisplay$(
    //   CANCELLATIONS,
    //   this.dataBuilder,
    //   new DcRowChartBuilder() // TODO: We want this to be stacked bar
    // );

    this.testChart = resolveDynamicChartDefinition(
      this.dataBuilder,
      PATIENT_DISTRIBUTION_PIE,
      ChartType.Pie
    );
    this.patientFlow = resolveDynamicChartDefinition(
      this.dataBuilder,
      // PATIENT_FLOW,
      PATIENT_DISTRIBUTION_PIE,
      ChartType.RowGrouped
    );
  }

  private _buildSingleValueCharts(): void {
    // TODO: I don't believe this is accurate.
    this.totalRevenueMeasure =
      FactTables.invoiceEvent.invoice.total.reduceBySum(); // TODO: https://app.clickup.com/t/2x03mtk
    this.totalRevenueChart = this.dataBuilder.toSingleValueChart(
      [toMeasureBuilderData(this.totalRevenueMeasure)],
      'Total Revenue'
    );

    this.newPatientsMeasure =
      FactTables.appointmentEvent.newPatient.reduceByCount();
    this.newPatientsChart = this.dataBuilder.toSingleValueChart(
      [toMeasureBuilderData(this.newPatientsMeasure)],
      'Total New Patients Seen'
    );

    this.totalReschedulesMeasure = FactTables.appointmentEvent
      .scopeBy(FactTables.appointmentEvent.rescheduled)
      .count.reduceByCount();
    this.totalReschedulesChart = this.dataBuilder.toSingleValueChart(
      [toMeasureBuilderData(this.totalReschedulesMeasure)],
      'Rescheduled Appointments'
    );

    this.totalNewAppointmentsMeasure = FactTables.appointmentEvent
      .scopeBy(FactTables.appointmentEvent.scheduled)
      .count.reduceByCount();
    this.totalNewAppointmentsChart = this.dataBuilder.toSingleValueChart(
      [toMeasureBuilderData(this.totalNewAppointmentsMeasure)],
      'Total New Appointments'
    );

    this.totalCancellationsMeasure = FactTables.appointmentEvent
      .scopeBy(FactTables.appointmentEvent.scheduled)
      .count.reduceByCount();
    this.totalCancellationsChart = this.dataBuilder.toSingleValueChart(
      [toMeasureBuilderData(this.totalCancellationsMeasure)],
      'Total Cancellations'
    );
  }

  // private _buildTableData(): void {
  //   this.tableChart = this.dataBuilder.toLineChart(TABLE_TARGET);
  // }
}

// const TABLE_TARGET: IChartConfig = {
//   labels: {
//     title: 'Numbers',
//   },
//   builderData: generateBuilderData({
//     measures: [
//       // 'totalRevenue',
//       FactTables.invoicePaid.invoice.total.reduceBySum(),
//       // 'hourlyRate',
//       FactTables.appointmentCompleted.hourlyRate.reduceBySum(),
//       // 'averagePatientSpend',
//       FactTables.appointmentCompleted.treatmentCost.reduceByAverage(),
//       // 'patientsSeen',
//       FactTables.appointmentCompleted.appointment.status.reduceByCount(),
//       // 'cancellations',
//       FactTables.appointmentEvent.scopeBy(FactTables.appointmentEvent.cancelled)
//         .count.setLabel('Cancellations'),
//       // 'treatmentPlanAcceptanceRate',
//       FactTables.treatmentPlanEvent.accepted.reduceByRatio(
//         FactTables.treatmentPlanEvent.offered
//       ).setLabel('Treatment Plan Acceptance Rate).setFormatter(MeasureFormatter.Percentage),
//       // 'patientRebookingRate',
//       FactTables.appointmentCompleted
//         .scopeBy(
//           FactTables.appointmentCompleted.nextAppointmentBooked.filterBy(
//             new BooleanMeasureFilter(true)
//           )
//         )
//         .count.reduceByRatio(FactTables.appointmentCompleted.count).setLabel('Rebooking Rate')
//         .setFormatter(MeasureFormatter.Percentage),
//       // 'rescheduleRate',
//       FactTables.appointmentEvent
//         .scopeBy(FactTables.appointmentEvent.rescheduled)
//         .count.reduceByRatio(
//           FactTables.appointmentEvent.scopeBy(
//             FactTables.appointmentEvent.scheduled
//           ).count.setLabel('Reschedule Rate')
//         ).setLabel('Reschedule Rate')setFormatter(MeasureFormatter.Percentage),
//       // 'paidOnCheckoutRate',
//       FactTables.invoicePaid
//         .scopeBy(
//           FactTables.invoicePaid.paidOnCheckout.filterBy(
//             new BooleanMeasureFilter(true)
//           )
//         )
//         .count.reduceByRatio(
//           FactTables.invoiceEvent.scopeBy(
//             FactTables.invoiceEvent.event.action.filterBy(
//               new ValueEqualsMeasureFilter(InvoiceAction.Issued)
//             )
//           ).count.setLabel('Paid On Checkout Rate')
//         ).setLabel('Paid On Checkout Rate').setFormatter(MeasureFormatter.Percentage),
//     ],
//     groupByDimension: FactTables.appointmentCompleted.practitioner.name,
//   }),
// };

const PATIENT_DISTRIBUTION_PIE: IChartConfig = {
  type: CustomChartType.Pie,
  labels: {
    title: 'New Patient Distribution',
  },
  builderData: generateBuilderData({
    measures: [
      toMeasureBuilderData(
        FactTables.appointmentEvent.newPatient
          .groupBy(FactTables.appointmentEvent.practitioner.name)
          .reduceByCount()
          .setFormatter(MeasureFormatter.Number)
      ),
    ],
    groupByDimension: FactTables.appointmentEvent.practitioner.name,
  }),
};

// const REVENUE_DISTRIBUTION: IChartConfig = {
//   labels: {
//     title: 'Revenue Distribution',
//   },
//   builderData: generateBuilderData({
//     measures: [
//       FactTables.invoicePaid.invoice.total
//         .groupBy(FactTables.appointmentCompleted.practitioner.name)
//         .reduceBySum(),
//     ],
//     groupByDimension: FactTables.invoicePaid.practitioner.name,
//   }),
// };

// const PATIENT_FLOW: IChartConfig = {
//   labels: {
//     title: 'Patient Flow',
//   },
//   builderData: generateBuilderData({
//     measures: [
//       // 'patientsLeavingWithAppointment',
//       FactTables.appointmentCompleted.nextAppointmentBooked
//         .filterBy(new BooleanMeasureFilter(true))
//         .reduceByCount(),
//       // 'patientsLeavingWithoutAppointment'
//       FactTables.appointmentCompleted.nextAppointmentBooked
//         .filterBy(new BooleanMeasureFilter(false))
//         .reduceByCount(),
//     ],
//     groupByDimension: FactTables.appointmentCompleted.practitioner.name,
//   }),
// };

// const CANCELLATIONS: IChartConfig = {
//   labels: {
//     title: 'Cancellations, FTAS, and UTAS',
//   },
//   builderData: generateBuilderData({
//     measures: [
//       // 'cancellations',
//       FactTables.appointmentEvent
//         .scopeBy(FactTables.appointmentEvent.cancelled)
//         .count.setLabel('Cancellations').groupBy(FactTables.appointmentEvent.practitioner.name)
//         .reduceByCount(),
//       // 'ftas',
//       FactTables.appointmentEvent
//         .scopeBy(
//           FactTables.appointmentEvent.timeUntilAppointment.filterBy(
//             new LessThanMeasureFilter(60)
//           )
//         )
//         .scopeBy(FactTables.appointmentEvent.cancelled)
//         .count.setLabel('FTAs').groupBy(FactTables.appointmentEvent.practitioner.name)
//         .reduceByCount(),
//       // 'utas'
//       FactTables.appointmentEvent
//         .scopeBy(
//           FactTables.appointmentEvent.timeUntilAppointment
//             .filterBy(new LessThanMeasureFilter(720))
//             .filterBy(new GreaterThanMeasureFilter(60, true))
//         )
//         .scopeBy(FactTables.appointmentEvent.cancelled)
//         .count.setLabel('UTAs').groupBy(FactTables.appointmentEvent.practitioner.name)
//         .reduceByCount(),
//     ],
//     groupByDimension: FactTables.appointmentEvent.practitioner.name,
//   }),
// };

// const PRACTITIONER_TIME: IChartConfig = {
//   labels: {
//     title: 'Appointment & Gap Durations',
//   },
//   builderData: generateBuilderData({
//     measures: [
//       FactTables.appointmentCompleted.duration
//         .groupBy(FactTables.appointmentCompleted.practitioner.name)
//         .reduceBySum()
//         .setLabel('Appointment Duration'),
//     ],
//     groupByDimension: FactTables.appointmentCompleted.practitioner.name,
//   }),
// };
