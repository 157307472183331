import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  ChartedItemScopeResolver,
  TreatmentStep,
  upsertTreatmentsToStep,
} from '@principle-theorem/principle-core';
import {
  type IChartedSurface,
  type IChartedTreatment,
  type IFeeSchedule,
  type IStaffer,
  type ITreatmentConfiguration,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  serialise,
  snapshot,
  unserialise$,
  type AtLeast,
  type DocumentReference,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TreatmentStepActions } from '../../actions';
import { type IChartState } from '../../reducers/reducers';
import { getSelectedTreatmentPlansTreatmentSteps } from '../../selectors/treatment-plans/core.selectors';
import {
  getSelectedTreatmentStep,
  getTreatmentStepById,
} from '../../selectors/treatment-plans/treatment-steps.selectors';

@Injectable()
export class TreatmentStepsFacade {
  private _store = inject(Store<IChartState>);
  selectedTreatmentStep$: Observable<WithRef<ITreatmentStep> | undefined>;
  selectedPlanSteps$: Observable<WithRef<ITreatmentStep>[] | undefined>;

  constructor() {
    this.selectedTreatmentStep$ = this._store.pipe(
      select(getSelectedTreatmentStep),
      unserialise$()
    );
    this.selectedPlanSteps$ = this._store.pipe(
      select(getSelectedTreatmentPlansTreatmentSteps),
      unserialise$(),
      map(compact)
    );
  }

  selectTreatmentStep(ref: DocumentReference<ITreatmentStep>): void {
    this._store.dispatch(
      TreatmentStepActions.selectTreatmentStep({ id: ref.id })
    );
  }

  getTreatmentStep$(
    id: string
  ): Observable<WithRef<ITreatmentStep> | undefined> {
    return this._store.pipe(select(getTreatmentStepById(id)), unserialise$());
  }

  updateTreatmentStep(
    id: string,
    changes: Partial<WithRef<ITreatmentStep>>
  ): void {
    const update = { id, changes };
    this._store.dispatch(
      TreatmentStepActions.updateTreatmentStep(serialise({ update }))
    );
  }

  async updateTreatmentFromStep(
    treatment: AtLeast<IChartedTreatment, 'uuid'>,
    treatmentStepRef: DocumentReference<ITreatmentStep>
  ): Promise<void> {
    const treatmentStep: WithRef<ITreatmentStep> | undefined = await snapshot(
      this.getTreatmentStep$(treatmentStepRef.id)
    );
    if (!treatmentStep) {
      return;
    }

    treatmentStep.treatments = treatmentStep.treatments.map(
      (currentTreatment) => {
        if (currentTreatment.uuid !== treatment.uuid) {
          return currentTreatment;
        }
        return {
          ...currentTreatment,
          ...treatment,
        };
      }
    );

    this.updateTreatmentStep(treatmentStep.ref.id, treatmentStep);
  }

  async addTreatmentToStep(
    config: WithRef<ITreatmentConfiguration>,
    surfaces: IChartedSurface[],
    treatmentStep: WithRef<ITreatmentStep>,
    feeSchedule: WithRef<IFeeSchedule>,
    attributedTo?: INamedDocument<IStaffer>
  ): Promise<void> {
    const scopeResolver = new ChartedItemScopeResolver();
    const surfaceScopeRefPairs = scopeResolver.reduceChartedSurfacesToScope(
      config,
      surfaces
    );
    const updatedStep = await upsertTreatmentsToStep(
      treatmentStep,
      config,
      surfaceScopeRefPairs,
      scopeResolver,
      feeSchedule,
      attributedTo
    );
    this.updateTreatmentStep(treatmentStep.ref.id, updatedStep);
  }

  async removeTreatmentFromStep(
    treatmentUuid: string,
    treatmentStepRef: DocumentReference<ITreatmentStep>
  ): Promise<void> {
    const treatmentStep: WithRef<ITreatmentStep> | undefined = await snapshot(
      this.getTreatmentStep$(treatmentStepRef.id)
    );
    if (!treatmentStep) {
      return;
    }

    this.updateTreatmentStep(
      treatmentStep.ref.id,
      TreatmentStep.deleteTreatment(treatmentStep, treatmentUuid)
    );
  }
}
