<svg:text
  class="tooth-label"
  matTooltip="View Tooth History"
  matTooltipShowDelay="300"
  (click)="openToothHistory()"
  [prSVGTransform]="label$ | async"
>
  {{ labelText$ | async }}
</svg:text>

<ng-container *ngIf="tooth$ | async as tooth">
  <ng-container *ngIf="view$ | async as view">
    <svg:g transform="translate(0 30)">
      <svg:g
        #toothChartSurface="prChartSurface"
        [ngClass]="{
          selected: toothChartSurface.selected$ | async,
          disabled: toothChartSurface.disabled$ | async
        }"
        prChartSurface
        prChartedItemsBadgeHover
        [view]="view"
        [ptRightClickMenu]="menu"
      >
        <defs>
          <ng-container *ngIf="icon$ | async as icon">
            <pattern
              [id]="fillPath$ | async"
              x="0"
              y="0"
              patternUnits="userSpaceOnUse"
              patternTransform="translate(0 0)"
              prSvgIcon
              [ngStyle]="{ color: 'white' }"
              [icon]="view.texture?.icon.slug"
              [width]="icon.width"
              [height]="icon.height"
              [prSVGTransform]="icon"
            />
          </ng-container>
        </defs>

        <svg:g [prChartSurfaceAttrFill]="view" [fillPath]="fillPath$ | async">
          <svg:path
            *ngFor="
              let path of paths.roots[tooth.tooth.roots].outer;
              trackBy: trackByPath
            "
            class="no-events"
            prChartSurfaceStyleFill
            [view]="view"
            class="background tooth-outline"
            [attr.d]="path"
          />

          <svg:g class="no-events">
            <svg:path
              *ngFor="
                let path of paths.roots[tooth.tooth.roots].inner;
                trackBy: trackByPath
              "
              [attr.d]="path"
            />

            <ng-container *ngIf="icon$ | async as icon">
              <svg:g
                prSvgIcon
                *ngIf="!view.texture?.hasFill"
                [ngStyle]="{ color: 'white' }"
                [icon]="view.texture?.icon.slug"
                [width]="icon.width"
                [height]="icon.height"
                [prSVGTransform]="icon"
              />
            </ng-container>
            <ng-container *ngIf="badge$ | async as badge">
              <svg:g
                *ngIf="badge > 1"
                prChartedItemsBadge
                [prSVGTransform]="indicator$ | async"
                [count]="badge"
              />
            </ng-container>
          </svg:g>
        </svg:g>
      </svg:g>

      <!-- Crown -->
      <svg:g>
        <circle
          class="background"
          cx="100.3"
          cy="92.9"
          r="91.9"
          prChartSurfaceStyleFill
          [view]="view"
        />

        <svg:g
          #mesial="prChartSurface"
          [ngClass]="{
            selected: mesial.selected$ | async,
            disabled: mesial.disabled$ | async
          }"
          prChartSurface
          prChartedItemsBadgeHover
          prChartToothSurface
          prChartSurfaceStyleFill
          [view]="tooth.display.mesial"
          [tooth]="tooth"
          [path]="paths.mesial"
          [prChartSurfaceDisable]="view"
          [ptRightClickMenu]="mesialMenu"
        />
        <foreignObject>
          <pr-chart-tooth-menu
            #mesialMenu="prChartToothMenu"
            [view]="tooth.display.mesial"
            [tooth]="tooth.display.tooth"
          />
        </foreignObject>

        <svg:g
          #facial="prChartSurface"
          [ngClass]="{
            selected: facial.selected$ | async,
            disabled: facial.disabled$ | async
          }"
          prChartSurface
          prChartedItemsBadgeHover
          prChartToothSurface
          prChartSurfaceStyleFill
          [view]="tooth.display.facial"
          [tooth]="tooth"
          [path]="paths.facial"
          [prChartSurfaceDisable]="view"
          [ptRightClickMenu]="facialMenu"
        />
        <foreignObject>
          <pr-chart-tooth-menu
            #facialMenu="prChartToothMenu"
            [view]="tooth.display.facial"
            [tooth]="tooth.display.tooth"
          />
        </foreignObject>

        <svg:g
          #distal="prChartSurface"
          [ngClass]="{
            selected: distal.selected$ | async,
            disabled: distal.disabled$ | async
          }"
          prChartSurface
          prChartedItemsBadgeHover
          prChartToothSurface
          prChartSurfaceStyleFill
          [view]="tooth.display.distal"
          [tooth]="tooth"
          [path]="paths.distal"
          [prChartSurfaceDisable]="view"
          [ptRightClickMenu]="distalMenu"
        />
        <foreignObject>
          <pr-chart-tooth-menu
            #distalMenu="prChartToothMenu"
            [view]="tooth.display.distal"
            [tooth]="tooth.display.tooth"
          />
        </foreignObject>

        <svg:g
          #lingual="prChartSurface"
          [ngClass]="{
            selected: lingual.selected$ | async,
            disabled: lingual.disabled$ | async
          }"
          prChartSurface
          prChartedItemsBadgeHover
          prChartToothSurface
          prChartSurfaceStyleFill
          [view]="tooth.display.lingual"
          [tooth]="tooth"
          [path]="paths.lingual"
          [prChartSurfaceDisable]="view"
          [ptRightClickMenu]="lingualMenu"
        />
        <foreignObject>
          <pr-chart-tooth-menu
            #lingualMenu="prChartToothMenu"
            [view]="tooth.display.lingual"
            [tooth]="tooth.display.tooth"
          />
        </foreignObject>

        <svg:g
          #occlusal="prChartSurface"
          [ngClass]="{
            selected: occlusal.selected$ | async,
            disabled: occlusal.disabled$ | async
          }"
          prChartSurface
          prChartedItemsBadgeHover
          prChartToothSurface
          prChartSurfaceStyleFill
          [view]="tooth.display.occlusal"
          [tooth]="tooth"
          [path]="paths.occlusal"
          [prChartSurfaceDisable]="view"
          [ptRightClickMenu]="occlusalMenu"
        />
        <foreignObject>
          <pr-chart-tooth-menu
            #occlusalMenu="prChartToothMenu"
            [view]="tooth.display.occlusal"
            [tooth]="tooth.display.tooth"
          />
        </foreignObject>
      </svg:g>

      <foreignObject>
        <pr-chart-tooth-menu
          #menu="prChartToothMenu"
          [view]="view"
          [tooth]="tooth.tooth"
        />
      </foreignObject>
    </svg:g>
  </ng-container>
</ng-container>
