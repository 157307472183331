import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgSharedModule } from '@principle-theorem/ng-shared';

@Component({
  selector: 'pr-patient-portal-loader',
  templateUrl: './patient-portal-loader.component.html',
  styleUrl: './patient-portal-loader.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NgSharedModule],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'flex flex-col items-center justify-center gap-2 p-4' },
})
export class PatientPortalLoaderComponent {}
