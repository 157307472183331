import { IGeoCoordinates } from '@principle-theorem/principle-core/interfaces';
import {
  getDocUid,
  getParentDocPath,
  ISO_DATE_TIME_FORMAT,
} from '@principle-theorem/shared';
import { ComparableValue } from 'crossfilter2';
import * as geolib from 'geolib';
import { get, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { DataAccessor } from './measure-properties';

export class MeasurePath {
  static timestamp(path: string): string {
    return `${path}.timestampValue`;
  }

  static docRef(path: string): string {
    return `${path}.referenceValue`;
  }
}

export function getProperty<T>(
  fact: unknown,
  propertyPath: string,
  defaultValue: T
): T {
  const rawValue: unknown = get(fact, propertyPath, defaultValue);
  return (rawValue ?? defaultValue) as T;
}

export class DataAccessorFactory {
  static property<T extends ComparableValue>(
    propertyPath: string,
    defaultValue: T,
    dataAccessorFn?: (data: unknown) => T,
    postProcessFn?: (value: string | T) => string | T
  ): (fact: unknown) => string | T {
    return (fact: unknown) => {
      const value = dataAccessorFn
        ? dataAccessorFn(fact)
        : getProperty(fact, propertyPath, defaultValue);
      return postProcessFn ? postProcessFn(value) : value;
    };
  }

  static array<T, R>(
    propertyPath: string,
    dataAccessorFn?: (data: T) => R,
    mapFn?: (data: T[]) => R[]
  ): DataAccessor {
    return (fact: unknown) => {
      const value = getProperty<T[]>(fact, propertyPath, []);
      const mappedValue = mapFn
        ? mapFn(value)
        : dataAccessorFn
          ? value.map(dataAccessorFn)
          : (value as unknown as R[]);
      return mappedValue as unknown as ComparableValue;
    };
  }

  static castUndefined(
    propertyPath: string,
    undefinedValue: ComparableValue
  ): DataAccessor {
    return (fact: unknown) => {
      const value = getProperty<ComparableValue | undefined>(
        fact,
        propertyPath,
        undefined
      );
      return value !== undefined ? value : undefinedValue;
    };
  }

  static timestamp<T>(
    propertyPath: string,
    undefinedValue: T,
    format: string = ISO_DATE_TIME_FORMAT
  ): (fact: unknown) => string | T {
    return (fact: unknown) => {
      const value = getProperty(fact, propertyPath, undefined);
      if (!isString(value) && !isNumber(value)) {
        return undefinedValue;
      }
      return moment(value).format(format);
    };
  }

  static distance(
    aPropertyPath: string,
    bPropertyPath: string,
    accuracyInMeters: number = 100
  ): DataAccessor {
    return (fact) => {
      const pointA = getProperty<IGeoCoordinates | undefined>(
        fact,
        aPropertyPath,
        undefined
      );
      const pointB = getProperty<IGeoCoordinates | undefined>(
        fact,
        bPropertyPath,
        undefined
      );
      if (!pointA || !pointB) {
        return 0;
      }
      return geolib.getDistance(pointA, pointB, accuracyInMeters);
    };
  }
}

export class MeasureLinkFactory {
  static patient(slug: string, patientPath: string): string | undefined {
    const patientUid = getDocUid(patientPath);
    if (!patientUid) {
      return;
    }
    return `/${slug}/patients/${patientUid}`;
  }

  static invoice(slug: string, invoicePath?: string): string | undefined {
    if (!invoicePath) {
      return;
    }
    const invoiceUid = getDocUid(invoicePath);
    const patientUid = getDocUid(getParentDocPath(invoicePath));
    if (!patientUid || !invoiceUid) {
      return;
    }
    return `/${slug}/patients/${patientUid}/account/invoices/${invoiceUid}`;
  }

  static accountCredit(slug: string, creditPath: string): string | undefined {
    const creditUid = getDocUid(creditPath);
    const patientUid = getDocUid(getParentDocPath(creditPath));
    if (!patientUid || !creditUid) {
      return;
    }
    return `/${slug}/patients/${patientUid}/account/credits/${creditUid}`;
  }

  static appointment(
    slug: string,
    appointmentPath?: string
  ): string | undefined {
    if (!appointmentPath) {
      return;
    }
    const appointmentUid = getDocUid(appointmentPath);
    const patientUid = getDocUid(getParentDocPath(appointmentPath));
    if (!patientUid || !appointmentUid) {
      return;
    }
    return `/${slug}/patients/${patientUid}/appointments/${appointmentUid}/treatment-planning`;
  }

  static treatmentPlan(
    slug: string,
    treatmentPlan?: string
  ): string | undefined {
    if (!treatmentPlan) {
      return;
    }
    const treatmentPlanUid = getDocUid(treatmentPlan);
    const patientUid = getDocUid(getParentDocPath(treatmentPlan));
    if (!patientUid || !treatmentPlanUid) {
      return;
    }
    return `/${slug}/patients/${patientUid}/treatment-plans/${treatmentPlanUid}`;
  }
}
