import {
  Arch,
  ChartableSurface,
  hasArch,
  hasQuadrant,
  hasTooth,
  IChartedRef,
  Quadrant,
} from '@principle-theorem/principle-core/interfaces';
import { isUpperQuadrant } from '../quadrant';
import { MouthHierarchy } from './mouth-hierarchy';
import { SurfaceHierarchy } from './surface-hierarchy';

type AllowedArchRef =
  | Pick<IChartedRef, 'arch'>
  | Pick<IChartedRef, 'quadrant'>
  | Pick<IChartedRef, 'tooth'>;

export class ArchHierarchy extends SurfaceHierarchy<AllowedArchRef> {
  override surfaces: ChartableSurface[] = [ChartableSurface.Arch];
  override parents = [new MouthHierarchy()];

  canBeChartedOn(
    selectedSurface: Partial<IChartedRef>
  ): selectedSurface is AllowedArchRef {
    return (
      hasArch(selectedSurface) ||
      hasQuadrant(selectedSurface) ||
      hasTooth(selectedSurface)
    );
  }

  toChartedRef(currentRef: AllowedArchRef): Pick<IChartedRef, 'arch'> {
    if (hasArch(currentRef)) {
      return {
        arch: currentRef.arch,
      };
    }
    if (hasQuadrant(currentRef)) {
      return this._archFromQuadrant(currentRef.quadrant);
    }
    return this._archFromQuadrant(currentRef.tooth.quadrant);
  }

  private _archFromQuadrant(quadrant: Quadrant): Pick<IChartedRef, 'arch'> {
    if (isUpperQuadrant(quadrant)) {
      return {
        arch: Arch.Upper,
      };
    }
    return {
      arch: Arch.Lower,
    };
  }
}
