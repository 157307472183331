import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  TrackByFunctions,
  TypedFormControl,
  TypedFormGroup,
  isDisabled$,
} from '@principle-theorem/ng-shared';
import {
  Brand,
  ManagementJob,
  Organisation,
  toINamedDocuments,
} from '@principle-theorem/principle-core';
import {
  BigQueryManagementJob,
  ManagementJobStatus,
  isBigQueryJob,
  type IBigQueryJob,
  type IBrand,
  type IManagementJob,
  type IOrganisation,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  Firestore,
  ISO_DATE_TIME_FORMAT,
  addDoc,
  asyncForEach,
  deleteDoc,
  getEnumValues,
  limit,
  orderBy,
  query$,
  shareReplayCold,
  toMoment,
  toTimestampRange,
  type INamedDocument,
  type ITimePeriod,
  type WithRef,
} from '@principle-theorem/shared';
import { Subject, of, type Observable } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

interface IFormData
  extends Omit<
    IBigQueryJob,
    'range' | 'organisationRef' | 'brandRef' | 'practiceRef'
  > {
  range: ITimePeriod;
  organisation: INamedDocument<IOrganisation>;
  brand: INamedDocument<IBrand>;
  practices: INamedDocument<IPractice>[];
}

@Component({
    selector: 'pr-big-query-job-selector',
    templateUrl: './big-query-job-selector.component.html',
    styleUrls: ['./big-query-job-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BigQueryJobSelectorComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  readonly fullDateFormat = ISO_DATE_TIME_FORMAT;
  jobOptions = getEnumValues(BigQueryManagementJob);
  organisations$: Observable<INamedDocument<IOrganisation>[]>;
  brands$: Observable<INamedDocument<IBrand>[]>;
  practices$: Observable<INamedDocument<IPractice>[]>;
  jobs$: Observable<WithRef<IManagementJob>[]>;
  trackByJobOption = TrackByFunctions.variable<BigQueryManagementJob>();
  trackByOrganisation = TrackByFunctions.ref<INamedDocument<IOrganisation>>();
  trackByBrand = TrackByFunctions.ref<INamedDocument<IBrand>>();
  trackByPractice = TrackByFunctions.ref<INamedDocument<IPractice>>();
  trackByJob = TrackByFunctions.ref<WithRef<IManagementJob>>();
  form = new TypedFormGroup<IFormData>({
    type: new TypedFormControl<BigQueryManagementJob>(
      undefined,
      Validators.required
    ),
    range: new TypedFormControl<ITimePeriod>(undefined, Validators.required),
    organisation: new TypedFormControl<INamedDocument<IOrganisation>>(
      undefined,
      Validators.required
    ),
    brand: new TypedFormControl<INamedDocument<IBrand>>(
      undefined,
      Validators.required
    ),
    practices: new TypedFormControl<INamedDocument<IPractice>[]>(
      [],
      Validators.required
    ),
  });
  isDisabled$ = isDisabled$(this.form);

  constructor(private _snackBar: MatSnackBar) {
    this.organisations$ = Organisation.all$().pipe(
      toINamedDocuments(),
      shareReplayCold()
    );
    const organisationChange$ = this.form.controls.organisation.valueChanges;
    const brandChange$ = this.form.controls.brand.valueChanges;

    organisationChange$.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
      this.form.controls.brand.reset();
      this.form.controls.practices.reset();
    });

    brandChange$.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
      this.form.controls.practices.reset();
    });

    this.brands$ = organisationChange$.pipe(
      switchMap((organisation) =>
        organisation ? Organisation.brands$(organisation) : of([])
      ),
      toINamedDocuments(),
      tap((brands) => {
        if (brands.length === 1) {
          this.form.controls.brand.setValue(brands[0]);
        }
      })
    );

    this.practices$ = brandChange$.pipe(
      switchMap((brand) => (brand ? Brand.practices$(brand) : of([]))),
      toINamedDocuments(),
      tap((practices) => {
        if (practices.length === 1) {
          this.form.controls.practices.setValue(practices);
        }
      })
    );

    this.jobs$ = query$(
      ManagementJob.col(),
      orderBy('createdAt', 'desc'),
      limit(50)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  updateDateRange(dateRange: ITimePeriod): void {
    this.form.controls.range.setValue(dateRange);
  }

  async runSync(): Promise<void> {
    if (!this.form.valid) {
      return;
    }

    const managementJob = this.form.getRawValue();

    await asyncForEach(managementJob.practices, (practice) =>
      addDoc(ManagementJob.col(), {
        type: managementJob.type,
        range: toTimestampRange(managementJob.range),
        organisationRef: managementJob.organisation.ref,
        brandRef: managementJob.brand.ref,
        practiceRef: practice.ref,
        status: ManagementJobStatus.Pending,
        deleted: false,
      })
    );

    this._snackBar.open('Sync job started');
  }

  isLoading(job: WithRef<IManagementJob>): boolean {
    return [
      ManagementJobStatus.Pending,
      ManagementJobStatus.InProgress,
    ].includes(job.status);
  }

  canDismiss(job: WithRef<IManagementJob>): boolean {
    return [ManagementJobStatus.Failed, ManagementJobStatus.Complete].includes(
      job.status
    );
  }

  async dismissJob(job: WithRef<IManagementJob>): Promise<void> {
    await deleteDoc(job.ref);
  }

  async getJobDescription(job: WithRef<IManagementJob>): Promise<string> {
    if (!isBigQueryJob(job)) {
      return '';
    }
    const org = await Firestore.getDoc(job.organisationRef);
    const practice = await Firestore.getDoc(job.practiceRef);
    return [
      toMoment(job.range.from).format(DAY_MONTH_YEAR_FORMAT),
      toMoment(job.range.to).format(DAY_MONTH_YEAR_FORMAT),
      org.name,
      practice.name,
    ].join(' - ');
  }
}
