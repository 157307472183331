import {
  type ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { IntercomModule } from '@supy-io/ngx-intercom';
import { type IIntercomConfig, NG_INTERCOM_CONFIG } from './intercom-config';

@NgModule({
  imports: [IntercomModule],
})
export class NgIntercomModule {
  constructor(@Optional() @SkipSelf() parentModule: NgIntercomModule) {
    if (parentModule) {
      throw new Error(
        'NgIntercomModule is already loaded. Import it in the AppModule only'
      );
    }
  }

  static forRoot(
    config: IIntercomConfig
  ): ModuleWithProviders<NgIntercomModule> {
    return {
      ngModule: NgIntercomModule,
      providers: [
        {
          provide: NG_INTERCOM_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
