import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IAutomationConfiguration,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import { resolveDocRefs, type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-automation-treatments',
    templateUrl: './automation-treatments.component.html',
    styleUrls: ['./automation-treatments.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AutomationTreatmentsComponent {
  private _config$ = new ReplaySubject<WithRef<IAutomationConfiguration>>(1);
  treatments$: Observable<WithRef<ITreatmentConfiguration>[]>;
  trackByTreatment = TrackByFunctions.ref<WithRef<ITreatmentConfiguration>>();

  @Input()
  set config(config: WithRef<IAutomationConfiguration>) {
    if (config) {
      this._config$.next(config);
    }
  }

  constructor() {
    this.treatments$ = this._config$.pipe(
      switchMap((config) => resolveDocRefs(config.treatmentRefs))
    );
  }
}
