import { Injectable, inject } from '@angular/core';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import {
  routerNavigatedAction,
  type RouterNavigatedPayload,
  type SerializedRouterStateSnapshot,
} from '@ngrx/router-store';
import { filter, withLatestFrom, map, switchMap } from 'rxjs/operators';
import { LAB_JOBS_FEATURE_KEY } from './lab-jobs.reducer';
import { LabJobsFacade } from './lab-jobs.facade';
import { LAB_JOB_STATUSES } from '@principle-theorem/principle-core/interfaces';
import * as LabJobActions from './lab-jobs.actions';
import { type Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { LAB_JOB_SORT_OPTIONS } from '../lab-job-sort-options';
import { LAB_JOB_PRESET_FILTERS } from '../lab-job-preset-filters';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class LabJobsRouterEffects {
  private _actions$ = inject(Actions);
  private _labJobsFacade = inject(LabJobsFacade);
  statusFilter$: Observable<Action> = createEffect(() => this._statusFilter$());
  sortFilter$: Observable<Action> = createEffect(() => this._sortFilter$());
  presetFilter$: Observable<Action> = createEffect(() => this._presetFilter$());
  labJobParam$: Observable<Action> = createEffect(() => this._labJobParam$());
  clearLabJobParam$: Observable<boolean> = createEffect(
    () => this._clearLabJobParam$(),
    { dispatch: false }
  );

  constructor(private _route: ActivatedRoute, private _router: Router) {}

  private _isLabJobsUrl(
    payload: RouterNavigatedPayload<SerializedRouterStateSnapshot>
  ): boolean {
    return payload.event.url.includes(LAB_JOBS_FEATURE_KEY);
  }

  private _statusFilter$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(routerNavigatedAction),
      filter(({ payload }) => this._isLabJobsUrl(payload)),
      withLatestFrom(this._labJobsFacade.statusFilterParam$),
      map(([_, statusParam]) => {
        const status = LAB_JOB_STATUSES.find(
          (labJobStatus) => labJobStatus === statusParam
        );
        if (!status) {
          return LabJobActions.cancelFilterAction();
        }
        return LabJobActions.setStatusFilter({ status });
      })
    );
  }

  private _sortFilter$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(routerNavigatedAction),
      filter(({ payload }) => this._isLabJobsUrl(payload)),
      withLatestFrom(this._labJobsFacade.activeSortParam$),
      map(([_, sortParam]) => {
        const found = LAB_JOB_SORT_OPTIONS.find(
          (option) => option.id === sortParam
        );
        if (!found) {
          return LabJobActions.cancelFilterAction();
        }
        return LabJobActions.setSortOption({ id: found.id });
      })
    );
  }

  private _presetFilter$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(routerNavigatedAction),
      filter(({ payload }) => this._isLabJobsUrl(payload)),
      withLatestFrom(this._labJobsFacade.presetFilterParam$),
      map(([_, presetParam]) => {
        const found = LAB_JOB_PRESET_FILTERS.find(
          (presetFilter) => presetFilter.id === presetParam
        );
        if (!found) {
          return LabJobActions.cancelFilterAction();
        }
        return LabJobActions.setPresetFilter({ preset: found.id });
      })
    );
  }

  private _labJobParam$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(routerNavigatedAction),
      filter(({ payload }) => this._isLabJobsUrl(payload)),
      withLatestFrom(this._labJobsFacade.labJobIdParam$),
      map(([_, id]) => {
        if (!id) {
          return LabJobActions.cancelFilterAction();
        }
        return LabJobActions.selectJobFromRoute({ id });
      })
    );
  }

  private _clearLabJobParam$(): Observable<boolean> {
    return this._actions$.pipe(
      ofType(LabJobActions.clearSelectedLabJob),
      switchMap(() => {
        return this._router.navigate([], {
          relativeTo: this._route,
          // eslint-disable-next-line no-null/no-null
          queryParams: { labJob: null },
          queryParamsHandling: 'merge',
        });
      })
    );
  }
}
