import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  type IReconciliatonReportState,
  RECONCILIATON_REPORT_FEATURE_KEY,
} from './reducers/main';

export const getReconciliationReportState =
  createFeatureSelector<IReconciliatonReportState>(
    RECONCILIATON_REPORT_FEATURE_KEY
  );

export const getReconciliationReportQuery = createSelector(
  getReconciliationReportState,
  (state) => state.query
);

export const getDateRange = createSelector(
  getReconciliationReportQuery,
  (state) => state.range
);

export const getPractice = createSelector(
  getReconciliationReportQuery,
  (state) => state.practice
);

export const getPrincipleReport = createSelector(
  getReconciliationReportState,
  (state) => state.principleReport
);

export const getTyroReport = createSelector(
  getReconciliationReportState,
  (state) => state.tyroReport
);

export const getHealthPointReport = createSelector(
  getReconciliationReportState,
  (state) => state.healthPointReport
);

export const getSelectedSummary = createSelector(
  getReconciliationReportState,
  (state) => state.selectedSummary
);
