import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  IMenuButton,
  TextFormatting,
  fromEditorEvents,
} from '@principle-theorem/editor';
import { EditorMenuItemComponent } from '@principle-theorem/ng-prosemirror';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { snapshot } from '@principle-theorem/shared';
import { combineLatest, type Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pt-text-badge-menu',
  templateUrl: './text-badge-menu.component.html',
  styleUrls: ['./text-badge-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextBadgeMenuComponent extends EditorMenuItemComponent {
  trackByItem = TrackByFunctions.field<ColourMapWithButton>('buttonText');
  override tooltip = 'Badge Colour';
  textBadges = textBadgeButtons();
  activeBadgeColour$: Observable<string | undefined>;
  isActive$: Observable<boolean>;

  constructor() {
    super();
    this.activeBadgeColour$ = this.editor$.pipe(
      switchMap((editor) =>
        fromEditorEvents(editor).pipe(
          startWith(undefined),
          map(() =>
            editor.state.selection.$head
              .marks()
              .find((mark) => mark.type.name === String(TextFormatting.Badge))
          ),
          map((mark) => (mark?.attrs?.color as string) ?? undefined)
        )
      )
    );

    this.isActive$ = combineLatest([this.activeBadgeColour$]).pipe(
      map(([activeBadgeColour]) => (activeBadgeColour ? true : false))
    );
  }

  async runCommand(colour: ColourMapWithButton): Promise<void> {
    const editor = await snapshot(this.editor$);
    colour.command(editor);
  }
}

interface IColourMap {
  label: string;
  colour?: string;
}

type ColourMapWithButton = IMenuButton & Pick<IColourMap, 'colour'>;

const badgeColours: IColourMap[] = [
  {
    label: 'Default',
  },
  {
    label: 'Red',
    colour: '#fae3e1',
  },
  {
    label: 'Orange',
    colour: '#fae7da',
  },
  {
    label: 'Yellow',
    colour: '#fef2d6',
  },
  {
    label: 'Blue',
    colour: '#e4e9ff',
  },
  {
    label: 'Purple',
    colour: '#e5e4fc',
  },
  {
    label: 'Pink',
    colour: '#fce5ef',
  },
  {
    label: 'Green',
    colour: '#dcf2e2',
  },
  {
    label: 'Grey',
    colour: '#f0f1f3',
  },
];

export function textBadgeButtons(): ColourMapWithButton[] {
  return badgeColours.map((colour) => {
    return {
      buttonType: 'submenu',
      buttonText: colour.label,
      icon: 'capture',
      colour: colour.colour,
      tooltip: '',
      command: (editor) => {
        if (
          !colour.colour ||
          editor.isActive(TextFormatting.Badge, { color: colour.colour })
        ) {
          return editor.chain().focus().unsetBadge().run();
        }
        return editor.chain().focus().setBadge({ color: colour.colour }).run();
      },
      isActiveFn: (editor) =>
        editor.isActive(TextFormatting.Badge, { color: colour.colour }),
    };
  });
}
