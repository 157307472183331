import { IStatisticMetrics, IStatistic } from './base-statistics';

export interface IPatientStats extends IStatisticMetrics {
  // Aggregate Facts
  totalNumberOfAppointments: IStatistic;
  totalValueOfTreatmentsApplied: IStatistic;
  totalNumberOfRebookedAppointments: IStatistic;
  totalNumberOfCancelledAppointments: IStatistic;
  totalPatientSpend: IStatistic;

  // Treatment Plan Facts
  incompleteTreatments: IStatistic;

  // Appointment Facts
  isNew: IStatistic;
  isExisting: IStatistic;

  // Dimensions
  age: IStatistic;
  isMale: IStatistic;
  isFemale: IStatistic;
  distance: IStatistic;
}
