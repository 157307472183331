<pr-service-line-item
  *ngIf="serviceCode$ | async as serviceCode"
  [disableQuantity]="disableQuantity$ | async"
  [serviceCode]="serviceCode"
  [name]="serviceCode | map : getCodeName : this"
  [tooltip]="serviceCode | map : getCodeName : this"
  [price]="price$ | async"
  [quantity]="quantity$ | async"
  [disabled]="disabled"
  [compact]="compact"
  (itemChange)="itemUpdate.emit($event)"
  (addServiceCode)="addServiceCode.emit($event)"
>
  <ng-content></ng-content>
</pr-service-line-item>
