<div *ngIf="enabled$ | async; else disabled">
  <button mat-stroked-button (click)="removeIntegration()">
    Remove Slack Integration
  </button>
</div>

<ng-template #disabled>
  <a [href]="slackLink$ | async" target="_blank">
    <img
      alt="Add to Slack"
      height="40"
      width="139"
      src="https://platform.slack-edge.com/img/add_to_slack.png"
      srcset="
        https://platform.slack-edge.com/img/add_to_slack.png    1x,
        https://platform.slack-edge.com/img/add_to_slack@2x.png 2x
      "
  /></a>
</ng-template>
