import {
  isContactReferrer,
  isPatientReferrer,
  type IAppointment,
  type IAppointmentScopeData,
  type IContactDetails,
  type IInvoice,
  type IInvoiceScopeData,
  type IPatient,
  type IPatientScopeData,
  type IPractice,
  type IPracticeScopeData,
  type ITreatmentPlan,
  type ITreatmentPlanScopeData,
  type ICandidateCalendarEvent,
  type IGapScopeData,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  undeletedQuery,
  snapshot,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { generateSelfConfirmUrl } from '../models/appointment/patient-confirm-appointment';
import { generateViewInvoiceUrl } from '../models/invoice/patient-view-invoice';
import {
  generateMedicalHistoryUrl,
  generatePatientFormsUrl,
} from '../models/patient/generate-medical-history-link';
import { Patient } from '../models/patient/patient';
import { generateGapOfferUrl } from '../models/gap/patient-confirm-gap-offer';
import { Brand } from '../models/brand';

export class ScopeDataBuilder {
  static async buildPatientScopeData(
    initialPatient: IReffable<IPatient>,
    practice: IReffable<IPractice>,
    appUrl: string
  ): Promise<IPatientScopeData> {
    const patient = await Firestore.getDoc(initialPatient.ref);
    const organisation = await snapshot(
      Brand.organisation$({ ref: Patient.brandRef({ ref: patient.ref }) })
    );
    const primaryContact = await Patient.resolvePrimaryContact(
      initialPatient.ref
    );
    const referrer = await this.resolveReferrer(patient);
    const patientForms = await Firestore.getDocs(
      undeletedQuery(Patient.formCol(patient))
    );
    return {
      patient,
      primaryContact,
      medicalHistoryLink: await generateMedicalHistoryUrl(patient, appUrl),
      patientFormsLink: await generatePatientFormsUrl(
        patient,
        practice,
        appUrl
      ),
      patientForms,
      referrer,
      smsIntegration: organisation.integrations?.smsProvider,
      region: organisation.region,
    };
  }

  static async resolveReferrer(
    patient: WithRef<IPatient>
  ): Promise<undefined | Partial<IContactDetails>> {
    if (!patient.referrer) {
      return undefined;
    }
    if (isPatientReferrer(patient.referrer)) {
      const referrer = await Firestore.getDoc(patient.referrer.ref);
      return {
        name: referrer.name,
        email: referrer.email,
        address: referrer.address,
        jobTitle: undefined,
      };
    }
    if (isContactReferrer(patient.referrer)) {
      const referrer = await Firestore.getDoc(patient.referrer.ref);
      return {
        name: referrer.name,
        email: referrer.email,
        address: referrer.address,
        jobTitle: undefined,
      };
    }
    return {
      name: patient.referrer.name,
      email: undefined,
      address: undefined,
      jobTitle: undefined,
    };
  }

  static async buildPracticeScopeData(
    practice: WithRef<IPractice>
  ): Promise<IPracticeScopeData> {
    return Promise.resolve({ practice });
  }

  static async buildAppointmentScopeData(
    appointment: WithRef<IAppointment>,
    appUrl: string
  ): Promise<IAppointmentScopeData> {
    const patientRef = Firestore.getParentDocRef<IPatient>(appointment.ref);
    const practice = await Firestore.getDoc(appointment.practice.ref);
    const patientScopeData = await this.buildPatientScopeData(
      { ref: patientRef },
      practice,
      appUrl
    );
    const practiceScopeData = await this.buildPracticeScopeData(
      await Firestore.getDoc(appointment.practice.ref)
    );
    return {
      ...patientScopeData,
      ...practiceScopeData,
      appointment,
      practitioner: await Firestore.getDoc(appointment.practitioner.ref),
      confirmLink: await generateSelfConfirmUrl(
        patientRef,
        appointment,
        appUrl
      ),
    };
  }

  static async buildInvoiceScopeData(
    invoice: WithRef<IInvoice>,
    appUrl: string
  ): Promise<IInvoiceScopeData> {
    const patientRef = Firestore.getParentDocRef<IPatient>(invoice.ref);
    const practice = await Firestore.getDoc(invoice.practice.ref);
    const patientScopeData = await this.buildPatientScopeData(
      { ref: patientRef },
      practice,
      appUrl
    );
    return {
      ...patientScopeData,
      invoice,
      invoiceLink: await generateViewInvoiceUrl(invoice, appUrl),
    };
  }

  static async buildGapScopeData(
    gapCandidate: WithRef<ICandidateCalendarEvent>,
    appUrl: string
  ): Promise<IGapScopeData | undefined> {
    const appointment = await Firestore.getDoc(
      gapCandidate.candidate.appointment
    );
    const appointmentScopedData = await this.buildAppointmentScopeData(
      appointment,
      appUrl
    );
    if (!appointmentScopedData) {
      return;
    }

    return {
      ...appointmentScopedData,
      gapCandidate,
      gapOfferConfirmUrl: await generateGapOfferUrl(gapCandidate, appUrl),
    };
  }

  static async buildTreatmentPlanScopeData(
    treatmentPlan: WithRef<ITreatmentPlan>,
    practice: WithRef<IPractice>,
    appUrl: string
  ): Promise<ITreatmentPlanScopeData> {
    const patientRef = Firestore.getParentDocRef<IPatient>(treatmentPlan.ref);
    const patientScopeData = await this.buildPatientScopeData(
      { ref: patientRef },
      practice,
      appUrl
    );
    return {
      ...patientScopeData,
      treatmentPlan,
    };
  }
}
