import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  isObject,
  toTimestamp,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { PatientSourceEntity } from './patient';
import { OFFSET_PLACEHOLDER } from '../../../source/source-helpers';

export const PATIENT_AUTOCLAVE_RESOURCE_TYPE = 'patientAutoclave';

export const PATIENT_AUTOCLAVE_SOURCE_ENTITY: ISourceEntity = SourceEntity.init(
  {
    metadata: {
      label: 'Patient Autoclave List',
      description: '',
      idPrefix: PATIENT_AUTOCLAVE_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  }
);

export interface IPraktikaAutoclaveRecord {
  patient_id: number;
  appointment_id: number;
  label: string; // '6/4/19 9424 A | 6/4/19 5765 B | 6/4/19 5767 B'
  created_by: number;
  created_at: string;
}

function isPraktikaAutoclaveRecord(
  item: unknown
): item is IPraktikaAutoclaveRecord {
  return (
    isObject(item) &&
    isNumber(item.patient_id) &&
    isNumber(item.appointment_id) &&
    isString(item.label) &&
    isNumber(item.created_by) &&
    isString(item.created_at)
  );
}

export interface IPraktikaPatientAutoclaveFilters {
  appointmentId: string;
  patientId: string;
  date: Timestamp;
}

export interface IPraktikaPatientAutoclaveTranslations {
  date: Timestamp;
}

const PATIENT_AUTOCLAVE_SOURCE_QUERY = `
SELECT sterilisation_record.*, appointment.patient_id FROM (
  SELECT
    iAppointmentId as appointment_id,
    vchLabel as label,
    dtCreated as created_at,
    iCreatedByStaffId as created_by
  FROM autoclave_labels
  ORDER BY iAppointmentId
  ${OFFSET_PLACEHOLDER}
) AS sterilisation_record
LEFT JOIN (
  SELECT
    iAppointmentId AS id,
    iPatientNumber AS patient_id
  FROM appointments
) AS appointment
ON sterilisation_record.appointment_id = appointment.id
`;

const PATIENT_AUTOCLAVE_ESTIMATE_QUERY = `
SELECT sterilisation_record.appointment_id FROM (
  SELECT iAppointmentId as appointment_id FROM autoclave_labels
) AS sterilisation_record
LEFT JOIN (
  SELECT iAppointmentId as id FROM appointments
) AS appointment
ON sterilisation_record.appointment_id = appointment.id
`;

export class PatientAutoclaveSourceEntity extends BaseSourceEntity<
  IPraktikaAutoclaveRecord,
  IPraktikaPatientAutoclaveTranslations,
  IPraktikaPatientAutoclaveFilters
> {
  sourceEntity = PATIENT_AUTOCLAVE_SOURCE_ENTITY;
  entityResourceType = PATIENT_AUTOCLAVE_RESOURCE_TYPE;
  sourceQuery = PATIENT_AUTOCLAVE_SOURCE_QUERY;
  override estimateQuery = PATIENT_AUTOCLAVE_ESTIMATE_QUERY;
  verifySourceFn = isPraktikaAutoclaveRecord;
  override transformDataFn = flow([]);
  override dateFilterField: keyof IPraktikaPatientAutoclaveFilters = 'date';

  override requiredEntities = {
    patients: new PatientSourceEntity(),
  };

  translate(
    autoclaveRecord: IPraktikaAutoclaveRecord,
    timezone: Timezone
  ): IPraktikaPatientAutoclaveTranslations {
    return {
      date: toTimestamp(
        moment.tz(autoclaveRecord.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }

  getSourceRecordId(data: IPraktikaAutoclaveRecord): string {
    return `${data.appointment_id}-${data.label.replace(
      // eslint-disable-next-line no-useless-escape
      /[\/\s:]/g,
      '-'
      // eslint-disable-next-line no-useless-escape
    )}-${data.created_at.replace(/[\/\s:]/g, '-')}-${data.created_at}`;
  }

  getSourceLabel(data: IPraktikaAutoclaveRecord): string {
    return data.label;
  }

  getFilterData(
    data: IPraktikaAutoclaveRecord,
    timezone: Timezone
  ): IPraktikaPatientAutoclaveFilters {
    return {
      patientId: data.patient_id.toString(),
      appointmentId: data.appointment_id.toString(),
      date: toTimestamp(
        moment.tz(data.created_at, ISO_DATE_TIME_FORMAT, timezone)
      ),
    };
  }
}
