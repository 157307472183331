import { DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  type OnDestroy,
} from '@angular/core';
import { AppointmentSchedulingFacade } from '@principle-theorem/ng-appointment/store';

@Component({
    selector: 'pr-appointment-selector-dialog',
    templateUrl: './appointment-selector-dialog.component.html',
    styleUrls: ['./appointment-selector-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentSelectorDialogComponent implements OnDestroy {
  constructor(
    private _dialogRef: DialogRef<AppointmentSelectorDialogComponent>,
    private _schedulingFacade: AppointmentSchedulingFacade
  ) {
    this._schedulingFacade.loadAppointmentAvailability();
  }

  ngOnDestroy(): void {
    this._schedulingFacade.resetAppointmentSuggestions();
  }

  close(): void {
    this._dialogRef.close();
  }
}
