import {
  type CrossfilterGroup,
  type IChartConfig,
  type IDataBuilder,
} from '@principle-theorem/reporting';
import { isObject } from '@principle-theorem/shared';
import { type NaturallyOrderedValue } from 'crossfilter2';
import { fill, groupBy, sum, zip } from 'lodash';
import { CrossfilterDataTransformer } from '../crossfilter-data-transformer';
import { type IDataTransformer } from '../interfaces';

export class GenericDataTransformer
  implements IDataTransformer<google.visualization.DataTable>
{
  baseTransformer = new CrossfilterDataTransformer();

  async transform(
    config: IChartConfig,
    dataBuilder: IDataBuilder
  ): Promise<google.visualization.DataTable> {
    const dataGroups: CrossfilterGroup[] = await this.baseTransformer.transform(
      config,
      dataBuilder
    );

    const headers: string[] = this._getHeaders(config, dataGroups);
    return this._buildDataTable(config, dataGroups, headers);
    // TODO: Hook up formatters
    // this._addFormatters(dataTable, true);
  }

  protected _getHeaders(
    config: IChartConfig,
    _groups: CrossfilterGroup[]
  ): string[] {
    const firstLabel = config.builderData.plottedOverTime
      ? 'Date'
      : config.labels.horizontalAxis ?? '';

    const measureLabels = config.builderData.measures.map(
      (measure) => measure.measure.metadata.label
    );
    const labels: string[] = [firstLabel, ...measureLabels];
    return labels;
  }

  protected _buildDataTable(
    config: IChartConfig,
    groups: CrossfilterGroup[],
    headers: string[]
  ): google.visualization.DataTable {
    const measures = config.builderData.measures;
    const chartData = zip(measures, groups).reduce(
      (allValues: NaturallyOrderedValue[][], [measure, group]) => {
        if (!measure || !group || !group.size()) {
          return allValues;
        }
        const values: NaturallyOrderedValue[][] = group
          .all()
          .map((groupData) => {
            const rowIndex = measures.findIndex(
              (item) =>
                item.measure.metadata.label === measure.measure.metadata.label
            );
            const row: NaturallyOrderedValue[] = fill(
              Array(measures.length + 1),
              0
            );
            row[0] = groupData.key.label;
            if (rowIndex !== -1) {
              row[rowIndex + 1] = isObject(groupData.value)
                ? groupData.value.valueOf()
                : groupData.value;
            }
            return row;
          });
        return [...allValues, ...values];
      },
      []
    );

    const dataGroups = groupBy(chartData, (columns) => columns[0]);
    const mergedData = Object.entries(dataGroups).map(([key, rows]) => {
      const data = rows
        .map((row) => row.slice(1))
        .reduce(
          (combined, subRow) => {
            subRow.map((value, index) => {
              combined[index] = sum([combined[index], value]);
            });
            return combined;
          },
          fill(Array(measures.length), 0)
        );
      return [key, ...data];
    });

    const dataArray = [headers, ...mergedData];
    return google.visualization.arrayToDataTable(dataArray);
  }
}
