import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import {
  ButtonsContainerComponent,
  DialogPresets,
} from '@principle-theorem/ng-shared';
import { IPrescriptionItem } from '@principle-theorem/principle-core/interfaces';
import { PrescriptionItemDialogComponent } from '../prescription-item-dialog/prescription-item-dialog.component';

@Component({
    selector: 'pr-prescription-add-item',
    imports: [CommonModule, ButtonsContainerComponent, MatButtonModule],
    templateUrl: './prescription-add-item.component.html',
    styleUrl: './prescription-add-item.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrescriptionAddItemComponent {
  private _dialog = inject(MatDialog);

  @Output() itemAdd = new EventEmitter<IPrescriptionItem>();

  async add(): Promise<void> {
    const change = await this._dialog
      .open<PrescriptionItemDialogComponent, undefined, IPrescriptionItem>(
        PrescriptionItemDialogComponent,
        DialogPresets.large()
      )
      .afterClosed()
      .toPromise();

    if (!change) {
      return;
    }
    this.itemAdd.emit(change);
  }
}
