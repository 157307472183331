import {
  TypedAbstractControl,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import { ITreatmentCategory } from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  WithRef,
  isSameRef,
} from '@principle-theorem/shared';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

export class TreatmentCategorySelectorBloc {
  selected$: Observable<WithRef<ITreatmentCategory> | undefined>;
  resetDisabled$: Observable<boolean>;

  constructor(
    public options$: Observable<WithRef<ITreatmentCategory>[]>,
    public formCtrl: TypedAbstractControl<
      DocumentReference<ITreatmentCategory> | undefined
    >
  ) {
    const formValue$ = formControlChanges$(this.formCtrl);
    this.selected$ = combineLatest([this.options$, formValue$]).pipe(
      map(([categories, formValue]) =>
        categories.find((category) => isSameRef(category, formValue))
      )
    );
    this.resetDisabled$ = formValue$.pipe(map((value) => !value));
  }

  compareFn(
    aReffable?: DocumentReference<ITreatmentCategory>,
    bReffable?: DocumentReference<ITreatmentCategory>
  ): boolean {
    return isSameRef(aReffable, bReffable);
  }

  clear(event: Event): void {
    event.stopPropagation();
    this.formCtrl.reset();
    this.formCtrl.markAsDirty();
    this.formCtrl.updateValueAndValidity();
  }
}
