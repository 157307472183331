import { toJSON, fromJSON, getError } from '@principle-theorem/shared';
import { BehaviorSubject, fromEvent, Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil, filter, map } from 'rxjs/operators';

abstract class BrowserStorage<T> {
  private _onDestroy$ = new Subject<void>();
  value$: BehaviorSubject<T | undefined>;

  constructor(private _key: string) {
    this.value$ = new BehaviorSubject<T | undefined>(this._read());
    this._storageEventValue$().subscribe((value) => this.value$.next(value));
  }

  update(data: T): void {
    this.storage.setItem(this._key, toJSON(data));
    this.value$.next(data);
  }

  delete(): void {
    this.storage.removeItem(this._key);
  }

  get value(): T | undefined {
    return this.value$.value;
  }

  destroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _read(): T | undefined {
    const strValue: string = this.storage.getItem(this._key) || '';
    try {
      return fromJSON(strValue);
    } catch (e) {
      return undefined;
    }
  }

  private _storageEventValue$(): Observable<T | undefined> {
    return fromEvent<StorageEvent>(window, 'storage').pipe(
      filter(
        (event) => event.key === this._key && event.storageArea === this.storage
      ),
      map((event) =>
        event.newValue ? fromJSON<T>(event.newValue) : undefined
      ),
      catchError((error) => {
        // eslint-disable-next-line no-console
        console.error('Error parsing storage value', getError(error));
        return of(undefined);
      }),
      takeUntil(this._onDestroy$)
    );
  }

  protected abstract get storage(): Storage;
}

export class LocalStorage<T> extends BrowserStorage<T> {
  protected get storage(): Storage {
    return localStorage;
  }
}

export class SessionStorage<T> extends BrowserStorage<T> {
  protected get storage(): Storage {
    return sessionStorage;
  }
}
