import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
  type QueryList,
  ViewChildren,
} from '@angular/core';
import {
  queryListToObservable,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  type IChartedRef,
  type IDentalChartViewMouth,
} from '@principle-theorem/principle-core/interfaces';
import { reduceToSingleArray } from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { ChartSurfaceDirective } from './chart-surface/chart-surface.directive';
import { ChartToothComponent } from './chart-tooth/chart-tooth.component';
import { type IChartArch } from './renderers/chart-arch';
import { type IChartElement } from './renderers/chart-element';
import { type IChartQuadrant } from './renderers/chart-quadrant';
import { type IChartRow } from './renderers/chart-row';
import { type IChartRenderView } from './renderers/chart-svg-layout-renderer';
import { type IChartTooth } from './renderers/chart-tooth';

@Component({
    selector: 'pr-dental-chart-svg',
    templateUrl: './dental-chart-svg.component.html',
    styleUrls: ['./dental-chart-svg.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DentalChartSvgComponent implements OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();
  private _chartSurfaces$ = new ReplaySubject<QueryList<ChartSurfaceDirective>>(
    1
  );
  private _chartTeeth$ = new ReplaySubject<QueryList<ChartToothComponent>>(1);
  private _renderer$: ReplaySubject<IChartRenderView> = new ReplaySubject(1);

  trackByArch = TrackByFunctions.field<IChartArch>('arch');
  trackByQuadrant = TrackByFunctions.field<IChartQuadrant>('quadrant');
  surfaces$: Observable<ChartSurfaceDirective[]>;
  mouth$: Observable<IDentalChartViewMouth>;
  element$: Observable<IChartElement>;
  arches$: Observable<IChartArch[]>;
  rows$: Observable<IChartRow[]>;
  viewBox$: Observable<string>;

  @Output() selectionChange: EventEmitter<Partial<IChartedRef>[]> =
    new EventEmitter<Partial<IChartedRef>[]>();

  constructor() {
    this.surfaces$ = combineLatest([
      this._chartSurfaces$.pipe(
        switchMap((chartSurfaces: QueryList<ChartSurfaceDirective>) =>
          queryListToObservable(chartSurfaces)
        )
      ),
      this._chartTeeth$.pipe(
        switchMap((chartTeeth: QueryList<ChartToothComponent>) =>
          this._getTeethSurfaces$(queryListToObservable(chartTeeth))
        )
      ),
    ]).pipe(map(reduceToSingleArray), takeUntil(this._destroy$));

    this.mouth$ = this._renderer$.pipe(map((renderer) => renderer.mouth));
    this.arches$ = this._renderer$.pipe(map((renderer) => renderer.arches));
    this.rows$ = this._renderer$.pipe(map((renderer) => renderer.rows));
    this.viewBox$ = this._renderer$.pipe(map((renderer) => renderer.viewBox));
    this.element$ = this._renderer$.pipe(map((renderer) => renderer.element));
  }

  @Input()
  set renderer(renderer: IChartRenderView) {
    if (renderer) {
      this._renderer$.next(renderer);
    }
  }

  @ViewChildren(ChartSurfaceDirective)
  set _chartSurfaces(surfaces: QueryList<ChartSurfaceDirective>) {
    this._chartSurfaces$.next(surfaces);
  }

  @ViewChildren(ChartToothComponent)
  set _chartTeeth(teeth: QueryList<ChartToothComponent>) {
    this._chartTeeth$.next(teeth);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  trackByRow(_: number, row: IChartRow): string {
    return `${row.width} ${row.height} ${row.xPos} ${row.yPos} ${String(
      row.xFlipped
    )} ${String(row.yFlipped)}`;
  }

  trackByTooth(_: number, tooth: IChartTooth): string {
    return `${tooth.tooth.toothRef.quadrant}${tooth.tooth.toothRef.quadrantIndex}`;
  }

  private _getTeethSurfaces$(
    toothComponents: Observable<ChartToothComponent[]>
  ): Observable<ChartSurfaceDirective[]> {
    return toothComponents.pipe(
      switchMap((teeth) =>
        combineLatest(
          teeth.map((tooth: ChartToothComponent) => tooth.surfaces$)
        )
      ),
      map(reduceToSingleArray)
    );
  }
}
