import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ArchivedDocument,
  HISTORY_DATE_FORMAT,
  WithRef,
} from '@principle-theorem/shared';
import { IClinicalNote } from '@principle-theorem/principle-core/interfaces';

export interface IClinicalNotesArchiveDialogData {
  history: ArchivedDocument<WithRef<IClinicalNote>>[];
}

@Component({
  selector: 'pr-clinical-notes-archive-dialog',
  templateUrl: './clinical-notes-archive-dialog.component.html',
  styleUrl: './clinical-notes-archive-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClinicalNotesArchiveDialogComponent {
  readonly dateFormat = HISTORY_DATE_FORMAT;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IClinicalNotesArchiveDialogData
  ) {}
}
