<mat-toolbar color="accent">Custom Recurrence</mat-toolbar>

<mat-dialog-content>
  <pr-recurrence-form
    #recurrenceForm
    [showButtons]="false"
    [recurrencePattern]="recurrencePattern"
    (submitted)="submitted($event)"
   />
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button (click)="dialogRef.close()">Cancel</button>
    <button mat-flat-button color="primary" (click)="recurrenceForm.submit()">
      Save
    </button>
  </pt-buttons-container>
</mat-dialog-actions>
