import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { TreatmentPackage } from '@principle-theorem/principle-core';
import {
  type ITreatmentConfiguration,
  type ITreatmentPackage,
} from '@principle-theorem/principle-core/interfaces';
import { patchDoc, snapshot, type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { type TreatmentPackageForm } from './edit-treatment-package/edit-treatment-package.component';

@Component({
    selector: 'pr-treatment-packages',
    templateUrl: './treatment-packages.component.html',
    styleUrls: ['./treatment-packages.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreatmentPackagesComponent {
  treatmentConfig$ = new ReplaySubject<WithRef<ITreatmentConfiguration>>(1);
  trackByPackage = TrackByFunctions.uniqueId<ITreatmentPackage>();
  packages$: Observable<ITreatmentPackage[]>;

  @Input()
  set treatmentConfig(treatmentConfig: WithRef<ITreatmentConfiguration>) {
    if (treatmentConfig) {
      this.treatmentConfig$.next(treatmentConfig);
    }
  }

  constructor(private _snackBar: MatSnackBar) {
    this.packages$ = this.treatmentConfig$.pipe(
      map((treatmentConfig) => treatmentConfig.packages)
    );
  }

  async addPackage(): Promise<void> {
    const treatmentConfig = await snapshot(this.treatmentConfig$);
    await patchDoc(treatmentConfig.ref, {
      packages: [...treatmentConfig.packages, TreatmentPackage.init()],
    });
    this._snackBar.open('Package added');
  }

  async deletePackage(packageUid: string): Promise<void> {
    const treatmentConfig = await snapshot(this.treatmentConfig$);
    await patchDoc(treatmentConfig.ref, {
      packages: treatmentConfig.packages.filter(
        (treatmentPackage) => treatmentPackage.uid !== packageUid
      ),
    });
    this._snackBar.open('Package removed');
  }

  async updatePackage(
    packageUid: string,
    packageData: TreatmentPackageForm
  ): Promise<void> {
    const treatmentConfig = await snapshot(this.treatmentConfig$);
    await patchDoc(treatmentConfig.ref, {
      packages: treatmentConfig.packages.map((treatmentPackage) => {
        if (treatmentPackage.uid !== packageUid) {
          return treatmentPackage;
        }
        return {
          ...treatmentPackage,
          ...packageData,
        };
      }),
    });
    this._snackBar.open('Package updated');
  }
}
