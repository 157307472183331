import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Invoice } from '@principle-theorem/principle-core';
import { InvoiceStatus } from '@principle-theorem/principle-core/interfaces';
import {
  type IInvoiceReportRecord,
  type IInvoiceReportRequest,
} from '@principle-theorem/reporting';
import { serialise$ } from '@principle-theorem/shared';
import { from, type Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ReportingFunctions } from '../../../core/reporting-functions';
import {
  loadReport,
  loadReportFailure,
  loadReportSuccess,
  OutstandingInvoicesReportActions,
} from './outstanding-invoices.actions';
import { OutstandingInvoicesReportFacade } from './outstanding-invoices.facade';

@Injectable()
export class OutstandingInvoicesReportEffects {
  private _actions$ = inject(Actions);
  private _reportFacade = inject(OutstandingInvoicesReportFacade);
  loadReport$: Observable<Action> = createEffect(() => this._loadReport$());
  setResults$: Observable<Action> = createEffect(() => this._setResults$());

  private _loadReport$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(OutstandingInvoicesReportActions.SetQuery),
      map(() => loadReport())
    );
  }

  private _setResults$(): Observable<Action> {
    return this._actions$.pipe(
      ofType(OutstandingInvoicesReportActions.LoadReport),
      withLatestFrom(this._reportFacade.query$),
      switchMap(([_, query]) =>
        this._getIssuedInvoices$(query).pipe(
          serialise$(),
          map((results) => loadReportSuccess({ results })),
          catchError((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            const action = loadReportFailure({
              errorMessage: 'Oops! There was a problem loading this report',
            });
            return of(action);
          })
        )
      )
    );
  }

  private _getIssuedInvoices$(
    query?: IInvoiceReportRequest
  ): Observable<IInvoiceReportRecord[]> {
    if (!query) {
      return of([]);
    }
    return from(
      ReportingFunctions.getIssuedInvoices({
        ...query,
        status: InvoiceStatus.Issued,
      })
    ).pipe(
      map((report) =>
        report.filter(
          (record) => Invoice.balance(record.invoice, record.transactions) > 0
        )
      )
    );
  }
}
