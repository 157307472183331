import {
  IAccountCredit,
  IInvoice,
  InvoiceStatus,
  IPatient,
  IPractice,
  isDepositLineItem,
  IStaffer,
  isTreatmentLineItem,
  ITransaction,
  IUsedAccountCredit,
  TransactionStatus,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference, Timestamp } from '@principle-theorem/shared';
import {
  customGroupBy,
  INamedDocument,
  isSameRef,
  WithRef,
} from '@principle-theorem/shared';
import { compact, flatten, uniqWith } from 'lodash';
import {
  IPractitionerIncomeSummary,
  PractitionerIncomeSummary,
} from './practitioner-income-summary';
import { createRecordSummary } from './practitioner-income-summary-creator';

export interface IInvoiceReportRequest {
  startDate: Timestamp;
  endDate: Timestamp;
  practiceRef: DocumentReference<IPractice>;
  status?: InvoiceStatus;
}

export interface ITransactionReportRequest {
  startDate: Timestamp;
  endDate: Timestamp;
  practiceRef: DocumentReference<IPractice>;
  status?: TransactionStatus;
}

export interface IInvoiceReportRecord {
  invoice: WithRef<IInvoice>;
  patient: WithRef<IPatient>;
  transactions: WithRef<Omit<ITransaction, 'extendedData'>>[];
}

export interface IAccountCreditReportRecord {
  accountCredit: WithRef<IAccountCredit> & { remaining: number };
  invoice?: WithRef<IInvoice>;
  patient: WithRef<IPatient>;
  transactions: WithRef<ITransaction>[];
}

export interface ITransactionReportRecord {
  invoice: WithRef<IInvoice>;
  patient: INamedDocument<IPatient>;
  transaction: WithRef<ITransaction>;
  accountCreditTransactions: IAccountCreditTransactionsRecord[];
}

export interface IAccountCreditTransactionsRecord {
  creditUsed: IUsedAccountCredit;
  depositPayments: IDepostPaymentBreakdown[];
}

export interface IDepostPaymentBreakdown {
  transaction: WithRef<ITransaction>;
  totalTransactionAmount: number;
  transactionRatio: number;
  transactionAmount: number;
}

export interface IPractitionerReportRecord {
  patient: WithRef<IPatient>;
  practitioner: INamedDocument<IStaffer>;
  invoice: WithRef<IInvoice>;
  summary: IPractitionerIncomeSummary;
}

export function toPractitionerIncomeRecords(
  records: IInvoiceReportRecord[]
): IPractitionerReportRecord[] {
  return flatten(
    records.map((record) => groupedPractitionerIncomeRecords(record))
  );
}

function groupedPractitionerIncomeRecords(
  record: IInvoiceReportRecord
): IPractitionerReportRecord[] {
  const treatmentPractitioners = record.invoice.items
    .filter(isTreatmentLineItem)
    .map((treatment) => treatment.treatmentRef.attributedTo);
  const depositPractitioners = compact(
    record.invoice.items
      .filter(isDepositLineItem)
      .map((deposit) => deposit.attributedTo)
  );
  return uniqWith(
    [...treatmentPractitioners, ...depositPractitioners],
    isSameRef
  ).map((practitioner) => ({
    invoice: record.invoice,
    patient: record.patient,
    practitioner,
    summary: createRecordSummary(
      record.invoice,
      record.transactions,
      practitioner.ref
    ),
  }));
}

export interface IPractitionerGrouping {
  practitioner: INamedDocument<IStaffer>;
  records: IPractitionerReportRecord[];
  total: IPractitionerIncomeSummary;
}

export function practionerSummary(
  records: IPractitionerReportRecord[]
): IPractitionerGrouping[] {
  return customGroupBy(records, (record) => record.practitioner, isSameRef).map(
    (group) => ({
      practitioner: group.group,
      records: group.items,
      total: PractitionerIncomeSummary.reduce(
        group.items.map((item) => item.summary)
      ),
    })
  );
}
