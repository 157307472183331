import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ChartFacade,
  ChartId,
  TreatmentPlanFacade,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  confirmationDialogData,
  ConfirmDialogComponent,
  DialogPresets,
  type IConfirmationDialogData,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import {
  ChartedMultiStepTreatment,
  ChartedTreatment,
  ChartedTreatmentUpdater,
  FeeScheduleManager,
} from '@principle-theorem/principle-core';
import {
  type IChartedMultiStepTreatment,
  type IChartedMultiStepTreatmentStep,
  type IChartedRef,
  type IChartedTreatment,
  type IFeeSchedule,
  type IMultiTreatmentPackage,
  type IPatient,
  isChartedMultiStepTreatmentStep,
  type ITreatmentFork,
  type ITreatmentPlanProposal,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  doc$,
  filterUndefined,
  getDoc,
  shareReplayCold,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable, ReplaySubject, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { StepDragDropGroup } from '../../../../step-drag-drop-group';
import { type IEditChartableData } from '../../../../chartable-surface-updater';
import { CurrentPatientScope } from '@principle-theorem/ng-principle-shared';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'pr-flagged-treatment',
    templateUrl: './flagged-treatment.component.html',
    styleUrls: ['./flagged-treatment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FlaggedTreatmentComponent {
  trackByMultiTreatment =
    TrackByFunctions.uniqueId<IChartedMultiStepTreatment>();
  forks$: Observable<ITreatmentFork[]>;
  treatments$: Observable<IChartedTreatment[]>;
  multiTreatments$: Observable<IChartedMultiStepTreatment[]>;
  proposal$: Observable<ITreatmentPlanProposal>;
  canAcceptSteps$: Observable<boolean>;
  patient$: Observable<WithRef<IPatient>>;
  acceptStepsTooltip$: Observable<string>;
  selectedSurfaces$: ReplaySubject<Partial<IChartedRef>[]> = new ReplaySubject(
    1
  );
  defaultFeeSchedule$: Observable<WithRef<IFeeSchedule>>;
  @Output() updateChartable = new EventEmitter<IEditChartableData>();

  @Input() stepDragDrop = new StepDragDropGroup();
  @Input() disabled: boolean = false;

  constructor(
    private _snackBar: MatSnackBar,
    private _chartStore: ChartFacade,
    private _patientScope: CurrentPatientScope,
    private _treatmentPlanFacade: TreatmentPlanFacade,
    private _dialog: MatDialog
  ) {
    this.patient$ = this._patientScope.doc$.pipe(filterUndefined());
    this.proposal$ = this._chartStore.planProposal$(ChartId.InAppointment);
    this.forks$ = this.proposal$.pipe(
      map((proposal) => proposal.forks),
      shareReplayCold()
    );
    this.treatments$ = this.proposal$.pipe(
      map((proposal) => proposal.treatments),
      shareReplayCold()
    );
    this.multiTreatments$ = this.proposal$.pipe(
      map((proposal) => proposal.multiTreatments),
      shareReplayCold()
    );
    this.canAcceptSteps$ =
      this._treatmentPlanFacade.selectedTreatmentPlan$.pipe(
        map((plan) => !!plan)
      );
    this.acceptStepsTooltip$ = this.canAcceptSteps$.pipe(
      map((canAccept) =>
        canAccept
          ? 'Accept all steps onto treatment plan'
          : 'No plan selected to add steps to'
      )
    );
    this.defaultFeeSchedule$ =
      this._chartStore.getFeeScheduleManager().currentSchedule$;
  }

  @Input()
  set selectedSurfaces(selectedSurfaces: Partial<IChartedRef>[]) {
    if (selectedSurfaces) {
      this.selectedSurfaces$.next(selectedSurfaces);
    }
  }

  async updateTreatments(treatments: IChartedTreatment[]): Promise<void> {
    this._chartStore.updateTreatments(
      ChartId.InAppointment,
      await ChartedTreatmentUpdater.syncPricingRules(treatments)
    );
  }

  async deleteTreatment(treatment: IChartedTreatment): Promise<void> {
    const removed = await snapshot(
      this.treatments$.pipe(
        map((treatments) =>
          treatments.filter((flagged) => flagged.uuid !== treatment.uuid)
        ),
        concatMap(ChartedTreatmentUpdater.syncPricingRules)
      )
    );

    this._chartStore.updateTreatments(ChartId.InAppointment, removed);
    this._snackBar.open(`Treatment removed`);
  }

  async updateMultiTreatment(
    multiTreatment: IChartedMultiStepTreatment
  ): Promise<void> {
    await this._chartStore.updateMultiTreatment(
      ChartId.InAppointment,
      multiTreatment
    );
  }

  async updateMultiTreatmentStep(
    step: IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>,
    multiTreatment: IChartedMultiStepTreatment
  ): Promise<void> {
    if (!isChartedMultiStepTreatmentStep(step)) {
      return;
    }
    await this.updateMultiTreatment(
      ChartedMultiStepTreatment.updateTreatmentStep(multiTreatment, step)
    );
  }

  async removeMultiTreatmentStep(
    step: IChartedMultiStepTreatmentStep | WithRef<ITreatmentStep>,
    multiTreatment: IChartedMultiStepTreatment
  ): Promise<void> {
    if (!isChartedMultiStepTreatmentStep(step)) {
      return;
    }
    await this.updateMultiTreatment(
      ChartedMultiStepTreatment.removeTreatmentStep(multiTreatment, step)
    );
  }

  async addMultiTreatmentStep(
    multiTreatment: IChartedMultiStepTreatment
  ): Promise<void> {
    await this.updateMultiTreatment(
      ChartedMultiStepTreatment.addStep(multiTreatment)
    );
  }

  deleteMultiTreatment(multiTreatment: IChartedMultiStepTreatment): void {
    this._chartStore.removeMultiTreatment(
      ChartId.InAppointment,
      multiTreatment.uuid
    );
    this._snackBar.open(`Treatment removed`);
  }

  async acceptTreatment(
    multiTreatment: IChartedMultiStepTreatment
  ): Promise<void> {
    await this._treatmentPlanFacade.acceptTreatment(multiTreatment);
  }

  async getMultiTreatmentPackages(
    treatment: IChartedMultiStepTreatment
  ): Promise<IMultiTreatmentPackage[]> {
    const config = await getDoc(treatment.config.ref);
    return config.packages;
  }

  async setTreatmentPackage(
    selectedPackageUid: string | undefined,
    packages: IMultiTreatmentPackage[],
    treatment: IChartedMultiStepTreatment
  ): Promise<void> {
    if (!selectedPackageUid) {
      await this.updateMultiTreatment(
        ChartedMultiStepTreatment.clearPackagePricing(treatment)
      );
      return;
    }

    const selectedPackage = packages.find(
      (multiTreatmentPackage) =>
        multiTreatmentPackage.uid === selectedPackageUid
    );

    if (!selectedPackage) {
      return;
    }

    await this.updateMultiTreatment(
      ChartedMultiStepTreatment.applyPackagePricing(treatment, selectedPackage)
    );
  }

  async updateFeeSchedule(
    multiTreatment: IChartedMultiStepTreatment,
    feeSchedule: WithRef<IFeeSchedule>
  ): Promise<void> {
    const data = confirmationDialogData({
      title: 'Apply Fee Schedule',
      prompt:
        'Apply selected fee schedule to multi treatment and all associated treatments?',
      submitColor: 'primary',
      submitLabel: 'Apply to All',
    });
    const confirmed = await this._dialog
      .open<ConfirmDialogComponent, IConfirmationDialogData, boolean>(
        ConfirmDialogComponent,
        DialogPresets.small({ data })
      )
      .afterClosed()
      .toPromise();

    if (!confirmed) {
      return;
    }

    const steps = await asyncForEach(multiTreatment.steps, async (step) => ({
      ...step,
      treatments: await asyncForEach(step.treatments, async (treatment) => {
        return ChartedTreatment.applyFeeSchedule(
          treatment,
          step.treatments,
          new FeeScheduleManager(of(feeSchedule)),
          true,
          true
        );
      }),
    }));

    const updatedMultiTreatment = {
      ...multiTreatment,
      feeSchedule,
      steps,
    };
    await this.updateMultiTreatment(updatedMultiTreatment);
  }

  multiTreatmentFeeSchedule$(
    multiTreatment: IChartedMultiStepTreatment
  ): Observable<WithRef<IFeeSchedule>> {
    return multiTreatment.feeSchedule
      ? doc$(multiTreatment.feeSchedule.ref)
      : this.defaultFeeSchedule$;
  }
}
