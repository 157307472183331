import { ITag } from '@principle-theorem/level-up-core';
import { Region } from '@principle-theorem/shared';
import { Tag } from './tag';

export const DEFAULT_PATIENT_TAGS = [
  { name: 'Relative of Staff', hexColour: '#7d21f3' },
  { name: 'Minor', hexColour: '#7be2fd' },
  { name: 'Vision Impaired', hexColour: '#ff0000' },
  { name: 'Staff Member', hexColour: '#a3ff3e' },
  { name: 'Cross-location Patient', hexColour: '#fff700' },
  { name: 'Gagger', hexColour: '#26832d' },
  { name: 'Dental Anxiety', hexColour: '#ff0000' },
  { name: 'Female Dentist Only', hexColour: '#99113f' },
  { name: 'Payment Plan', hexColour: '#6efdeb' },
  { name: '5 Star Reviewer', hexColour: '#fbfd83' },
  { name: 'Antibiotic Cover', hexColour: '#ff9696' },
  { name: 'VIP', hexColour: '#181b2b' },
  { name: 'English Second Language', hexColour: '#547074' },
  { name: 'Influencer', hexColour: '#ff2bdf' },
  { name: `Frequently FTA's`, hexColour: '#ff0000' },
  { name: 'Hearing Impaired', hexColour: '#ff0000' },
  { name: 'Top Referrer', hexColour: '#1728ff' },
  { name: 'Wheelchair Access', hexColour: '#2ac0fd' },
  { name: 'Only Book with Dr. X', hexColour: '#e586ff' },
  { name: 'DO NOT BOOK PATIENT!!', hexColour: '#a90202' },
];

export const AUSTRALIAN_DEFAULT_PATIENT_TAGS = [
  { name: 'DVA', hexColour: '#8d7837' },
  { name: 'CDBS', hexColour: '#f9bb1f' },
  { name: 'Health Fund Preferred', hexColour: '#7085db' },
];

export const NEW_ZEALAND_DEFAULT_PATIENT_TAGS = [
  { name: 'MOH', hexColour: '#8d7837' },
  { name: 'ACC', hexColour: '#f9bb1f' },
];

export const DEFAULT_PATIENT_NOTE_TAGS = [
  { name: 'Accounts', hexColour: '#de4312' },
  { name: 'Complaint Note', hexColour: '#702427' },
  { name: 'Communication Preference', hexColour: '#00caff' },
  { name: 'Offboarding Note', hexColour: '#6015eb' },
  { name: 'Payment Plan Details', hexColour: '#69f0ae' },
  { name: 'Feedback Note', hexColour: '#ff9100' },
].map((tag) => Tag.init({ name: tag.name, hexColour: tag.hexColour }));

export const DEFAULT_APPOINTMENT_TAGS = [
  { name: 'Scanner Needed', hexColour: '#b2002e' },
  { name: 'Sterile Set Up', hexColour: '#9db3f7' },
  { name: 'Multiple Treatments', hexColour: '#f7abdb' },
  { name: 'Double Booked', hexColour: '#fff816' },
  { name: 'No Fluoride', hexColour: '#9dfbbc' },
  { name: 'Quote Patient Before', hexColour: '#bcaaa4' },
  { name: 'In-house Lab Job', hexColour: '#4443eb' },
  { name: 'GA', hexColour: '#583509' },
  { name: 'Awaiting Lab Job', hexColour: '#fde672' },
  { name: 'Patient Running Late', hexColour: '#17fffe' },
  { name: 'IV Sedation', hexColour: '#cf92ff' },
  { name: 'Online Booking', hexColour: '#009fff' },
  { name: 'Records Transfer', hexColour: '#b2ff59' },
  { name: 'Do Not Move!', hexColour: '#d4000a' },
  { name: 'No X-Rays', hexColour: '#7a7a7a' },
  { name: `Don't Keep Waiting!!`, hexColour: '#fd4304' },
  { name: 'Lab Job Arrived', hexColour: '#00b165' },
  { name: `Patient's Birthday`, hexColour: '#7f3c23' },
  { name: 'Rescheduled By Practice', hexColour: '#45464e' },
  { name: 'Perio Patient', hexColour: '#fb00ca' },
  { name: 'Family Appointment', hexColour: '#a400ff' },
  { name: 'Dentist Running Late', hexColour: '#ffa100' },
  { name: 'No LA', hexColour: '#e0e0e0' },
  { name: 'Take Deposit', hexColour: '#2e7fa9' },
  { name: 'Nitrous Oxide Gas', hexColour: '#5200bc' },
  { name: 'Nitrous Oxide Gas', hexColour: '#5200bc' },
].map((tag) => Tag.init({ name: tag.name, hexColour: tag.hexColour }));

export const DEFAULT_CALENDAR_EVENT_TAGS = [
  { name: 'Meet in Consult Room', hexColour: '#ffaf3b' },
  { name: 'Virtual Meeting', hexColour: '#82d9ff' },
  { name: 'Public Holiday', hexColour: '#475863' },
].map((tag) => Tag.init({ name: tag.name, hexColour: tag.hexColour }));

export function getDefaultPatientTags(region: Region): ITag[] {
  const regionSpecificTags: Record<Region, Partial<ITag>[]> = {
    [Region.Australia]: AUSTRALIAN_DEFAULT_PATIENT_TAGS,
    [Region.NewZealand]: NEW_ZEALAND_DEFAULT_PATIENT_TAGS,
  };

  return [...DEFAULT_PATIENT_TAGS, ...(regionSpecificTags[region] || [])].map(
    (tag) => Tag.init({ name: tag.name, hexColour: tag.hexColour })
  );
}
