import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import {
  ChartFacade,
  type ChartId,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  isUpperQuadrant,
  surfaceFromRef,
} from '@principle-theorem/principle-core';
import { type IDentalChartViewSurface } from '@principle-theorem/principle-core/interfaces';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { CHART_ENTITY_ID } from '../chart-entity-id';
import { ChartElement, type IChartElement } from '../renderers/chart-element';
import { type IChartQuadrant } from '../renderers/chart-quadrant';
import { CHART_MARGIN } from '../renderers/chart-svg-layout-renderer';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[prChartQuadrant]',
  templateUrl: './chart-quadrant.component.html',
  styleUrls: ['./chart-quadrant.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartQuadrantComponent {
  private _chartId: ChartId = inject(CHART_ENTITY_ID);
  view$: ReplaySubject<IDentalChartViewSurface> = new ReplaySubject(1);
  quadrant$: ReplaySubject<IChartQuadrant> = new ReplaySubject(1);
  selector$: Observable<IChartElement>;
  indicator$: Observable<IChartElement>;
  label$: Observable<IChartElement>;
  disabled$: Observable<boolean>;
  badge$: Observable<number>;
  labelText$: Observable<string>;

  constructor(private _chartStore: ChartFacade) {
    this.disabled$ = this.view$.pipe(
      switchMap((view) =>
        this._chartStore.isDisabledSurface$(
          this._chartId,
          surfaceFromRef(view.id)
        )
      )
    );
    this.selector$ = this.quadrant$.pipe(
      map((quadrant: IChartQuadrant) => this._buildSelector(quadrant))
    );
    this.indicator$ = combineLatest([this.quadrant$, this.selector$]).pipe(
      map(([quadrant, selector]: [IChartQuadrant, IChartElement]) =>
        this._buildIndicator(quadrant, selector)
      )
    );
    this.label$ = this.quadrant$.pipe(
      map((quadrant: IChartQuadrant) => this._buildLabel(quadrant))
    );
    this.badge$ = this.view$.pipe(
      map((view: IDentalChartViewSurface) => view.badge),
      startWith(0)
    );
    this.labelText$ = this.quadrant$.pipe(
      map((quadrant: IChartQuadrant) => `Q${quadrant.quadrant}`),
      startWith('')
    );
  }

  @Input()
  set view(view: IDentalChartViewSurface) {
    this.view$.next(view);
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('prChartQuadrant')
  set quadrant(quadrant: IChartQuadrant) {
    this.quadrant$.next(quadrant);
  }

  private _buildSelector(parent: IChartQuadrant): IChartElement {
    const selector: ChartElement = new ChartElement();
    selector.width = parent.width + CHART_MARGIN;
    selector.height = 300;
    selector.yPos = 120;
    return selector.toInterface();
  }

  private _buildLabel(parent: IChartQuadrant): IChartElement {
    const padding = 10;
    const textWidth = 25;
    const textHeight = 22;
    const label: ChartElement = new ChartElement();
    label.xFlipped = parent.xFlipped;
    label.yFlipped = isUpperQuadrant(parent.quadrant);
    label.xPos = parent.width + CHART_MARGIN - textWidth - padding;
    label.yPos = parent.height - textHeight - padding;
    return label.toInterface();
  }

  private _buildIndicator(
    parent: IChartQuadrant,
    selector: IChartElement
  ): IChartElement {
    const xOffset = -35;
    const indicator: ChartElement = new ChartElement();
    indicator.xFlipped = parent.xFlipped;
    indicator.yFlipped = isUpperQuadrant(parent.quadrant);
    indicator.xPos = parent.width + CHART_MARGIN + xOffset;
    indicator.yPos = parent.height - selector.height / 2;
    return indicator.toInterface();
  }
}
