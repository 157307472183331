import { ChangeDetectionStrategy, Component } from '@angular/core';
import { type IChartCard } from '../../../models/report/charts/chart-card';
import { MeasureDataChartComponent } from '../../core/measure-data-chart-component';
import { type ITableHeaderReplacement } from '../../reporting-components/table-chart/table-chart.component';

@Component({
    selector: 'pr-practitioner-income',
    templateUrl: './practitioner-income.component.html',
    styleUrls: ['./practitioner-income.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PractitionerIncomeComponent extends MeasureDataChartComponent {
  tableChartCard: IChartCard;
  replacementHeaders: ITableHeaderReplacement[] = [
    { from: 'id', to: 'Practitioner Name' },
  ];

  // incomeOverTimeChartBuilder: ChartBuilder;
  // incomeVsExpensesChartBuilder: ChartBuilder;
  // incomeDistributionChartBuilder: ChartBuilder;
  // hourlyRateChartBuilder: ChartBuilder;
  // potentialIncomeChartBuilder: ChartBuilder;

  _redraw(): void {
    // this.incomeOverTimeChartBuilder = this._stackedAreaChart(
    //   [
    //     // 'totalRevenue',
    //     FactTables.invoiceEvent.invoice.total.reduceBySum(), // TODO: https://app.clickup.com/t/2x03mtk
    //   ],
    //   FactTables.invoiceEvent.practitioner.name // TODO: https://app.clickup.com/t/2x03mtk
    // );
    // this.incomeDistributionChartBuilder = this._pieChart(
    //   [
    //     // 'totalRevenue',
    //     FactTables.invoiceEvent.invoice.total.reduceBySum(), // TODO: https://app.clickup.com/t/2x03mtk
    //   ],
    //   FactTables.invoiceEvent.practitioner.name // TODO: https://app.clickup.com/t/2x03mtk
    // );
    // this.hourlyRateChartBuilder = this.dataBuilder.toBarChart(
    //   this._chartMeasures(
    //     [
    //       // 'hourlyRate',
    //       FactTables.appointmentCompleted.hourlyRate.reduceBySum(),
    //     ],
    //     FactTables.appointmentCompleted.practitioner.name
    //   )
    // ).chartBuilder;
    // this.potentialIncomeChartBuilder = this.dataBuilder
    //   .toBarChart(
    //     this._chartMeasures(
    //       [
    //         // 'totalRevenue',
    //         FactTables.invoiceEvent.invoice.total.reduceBySum(), // TODO: https://app.clickup.com/t/2x03mtk
    //         // 'lostIncome',
    //         FactTables.appointmentCompleted.hourlyRate.reduceByMultiplier(
    //           FactTables.gapEvent.latestEvent.duration
    //         ),
    //       ],
    //       FactTables.appointmentCompleted.practitioner.name
    //     )
    //   )
    //   .chartBuilder.addChartOptions({ isStacked: true });
    // this.tableChartCard = this._tableChartCard(
    //   [
    //     // 'totalRevenue',
    //     // FactTables.invoiceEvent.practitioner.name, // TODO: https://app.clickup.com/t/2x03mtk
    //     FactTables.invoiceEvent.invoice.practitionerIncome.reduceBySum(), // TODO: https://app.clickup.com/t/2x03mtk
    //     // treatmentRef.attributedTo
    //     // 'labFees',
    //     // FactTables.labJobReceived.labJob.cost.reduceBySum(),
    //     // 'dentalDraw',
    //     // FactTables.appointmentCompleted.dentalDraw.reduceBySum(),
    //     // 'dentalDrawPercent',
    //     // FactTables.appointmentCompleted.dentalDraw.reduceByRatio(
    //     //   FactTables.appointmentCompleted.treatmentCost
    //     // ).setLabel('Dental Draw vs. Treatment Cost').setFormatter(MeasureFormatter.Percentage),
    //     // 'costs',
    //     // TODO: Work out what this is
    //     // 'netIncome',
    //     // 'hourlyRate',
    //     // FactTables.appointmentCompleted.hourlyRate.reduceBySum(),
    //     // 'timeAvailable',
    //     // FactTables.gapEvent.duration.reduceBySum(),
    //   ],
    //   // FactTables.invoiceEvent.invoice.attributedTo // TODO: https://app.clickup.com/t/2x03mtk
    // );
    // this.incomeVsExpensesChartBuilder = this._incomeMetricBarChart(
    //   [
    //     new IncomeMeasure(
    //       FactTables.invoiceEvent.invoice.total.reduceBySum() // TODO: https://app.clickup.com/t/2x03mtk
    //     ),
    //     new IncomeMeasure(
    //       FactTables.appointmentCompleted.dentalDraw.reduceBySum(),
    //       true
    //     ),
    //     new IncomeMeasure(
    //       FactTables.labJobReceived.labJob.cost.reduceBySum(),
    //       true
    //     ),
    //   ],
    //   FactTables.appointmentCompleted.practitioner.name
    // );
  }
}
