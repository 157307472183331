import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import {
  NgSharedModule,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  ReportingJobType,
  type IPractitionerFTAReportParams,
  type IPractitionerGapsJobParams,
  type IReportingJobDisplay,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  type DocumentReference,
  type INamedDocument,
} from '@principle-theorem/shared';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

const FTA_PARAM_FORM = new TypedFormGroup<IPractitionerFTAReportParams>({
  skipPractitioners: new TypedFormControl<DocumentReference<IStaffer>[]>([]),
});

const PRACTITIONER_GAP_FORM = new TypedFormGroup<IPractitionerGapsJobParams>({
  groupRecordsBy: new TypedFormControl<'day' | 'week'>('day'),
  skipPractitioners: new TypedFormControl<DocumentReference<IStaffer>[]>([]),
});

const JOB_TYPE_FORMS_MAP = {
  [ReportingJobType.PractitionerFTAVsUTARates]: FTA_PARAM_FORM,
  [ReportingJobType.PractitionerGaps]: PRACTITIONER_GAP_FORM,
};

@Component({
  selector: 'pr-system-report-parameter-form',
  standalone: true,
  imports: [CommonModule, NgPrincipleSharedModule, NgSharedModule],
  templateUrl: './system-report-parameter-form.component.html',
  styleUrl: './system-report-parameter-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemReportParameterFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  formMap = JOB_TYPE_FORMS_MAP;
  form$: Observable<
    | TypedFormGroup<IPractitionerFTAReportParams>
    | TypedFormGroup<IPractitionerGapsJobParams>
    | undefined
  >;
  selectedType$ = new ReplaySubject<ReportingJobType>(1);
  availablePractitioners$ = new ReplaySubject<INamedDocument<IStaffer>[]>(1);
  practitionerGapReport = ReportingJobType.PractitionerGaps;

  @Output() formChange = new EventEmitter<
    IPractitionerFTAReportParams | IPractitionerGapsJobParams | undefined
  >();

  @Input()
  set selectedType(job: IReportingJobDisplay) {
    if (job) {
      this.selectedType$.next(job.type);
    }
  }

  @Input()
  set availablePractitioners(
    availablePractitioners: INamedDocument<IStaffer>[]
  ) {
    if (availablePractitioners) {
      this.availablePractitioners$.next(availablePractitioners);
    }
  }

  constructor() {
    this.form$ = this.selectedType$.pipe(
      map((selectedType) =>
        this._isAvailableReportType(selectedType)
          ? this.formMap[selectedType]
          : undefined
      )
    );

    this.form$
      .pipe(
        switchMap((form) => {
          if (!form) {
            return of(undefined);
          }
          this.formChange.emit(form.value);
          return form.valueChanges;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((formValue) => {
        this.formChange.emit(formValue);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _isAvailableReportType(
    type: ReportingJobType
  ): type is
    | ReportingJobType.PractitionerFTAVsUTARates
    | ReportingJobType.PractitionerGaps {
    return (
      type === ReportingJobType.PractitionerFTAVsUTARates ||
      type === ReportingJobType.PractitionerGaps
    );
  }
}
