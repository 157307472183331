import * as moment from 'moment-timezone';
import { type ITimeRange, TimeRangeTitle } from './time-range';

// TODO: Build with timezone - CU-251edxw
export const DEFAULT_TIME_RANGES: ITimeRange[] = [
  {
    title: TimeRangeTitle.Today,
    fromTo: {
      from: moment().startOf('day'),
      to: moment().endOf('day'),
    },
  },
  {
    title: TimeRangeTitle.Tomorrow,
    fromTo: {
      from: moment().add(1, 'day').startOf('day'),
      to: moment().add(1, 'day').endOf('day'),
    },
  },
  {
    title: TimeRangeTitle.Yesterday,
    fromTo: {
      from: moment().subtract(1, 'day').startOf('day'),
      to: moment().subtract(1, 'day').endOf('day'),
    },
  },
  {
    title: TimeRangeTitle.ThisWeek,
    fromTo: {
      from: moment().startOf('week'),
      to: moment().endOf('week'),
    },
  },
  {
    title: TimeRangeTitle.NextWeek,
    fromTo: {
      from: moment().add(1, 'week').startOf('week'),
      to: moment().add(1, 'week').endOf('week'),
    },
  },
  {
    title: TimeRangeTitle.LastWeek,
    fromTo: {
      from: moment().subtract(1, 'week').startOf('week'),
      to: moment().subtract(1, 'week').endOf('week'),
    },
  },
];
