import {
  ChartableSurface,
  isMultiTreatmentConfiguration,
  type IChartedMultiStepTreatment,
  type IChartedTreatment,
  type IEnabledPractice,
  type IFeeSchedule,
  type IImplementsTreatmentTemplate,
  type IMultiTreatmentConfiguration,
  type IPractice,
  type IScopeRef,
  type IStaffer,
  type ITreatmentCategory,
  type ITreatmentConfiguration,
  type ITreatmentStep,
  type ITreatmentTemplate,
  type TreatmentTemplateTreatments,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  asyncForEach,
  initFirestoreModel,
  isSameRef,
  toNamedDocument,
  type INamedDocument,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { first, groupBy, maxBy, minBy, remove } from 'lodash';
import { stafferToNamedDoc } from '../../common';
import { ChartedItemScopeResolver } from '../core/charted-item-scope-resolver';
import { FeeScheduleManager } from '../fees/fee-schedule/fee-schedule-manager';
import { ChartedMultiStepTreatment } from './charted-multi-step-treatment';
import { ChartedTreatment } from './charted-treatment';
import { TreatmentStep } from './treatment-step';

export class TreatmentTemplate {
  static init(overrides?: Partial<ITreatmentTemplate>): ITreatmentTemplate {
    return {
      name: '',
      isPublic: false,
      enabledPractices: [],
      implementedBy: [],
      ...initFirestoreModel(),
      ...overrides,
    };
  }

  static practiceIsEnabled(
    template: ITreatmentTemplate,
    practice: IReffable
  ): boolean {
    return template.enabledPractices.some(
      (enabledPractice: IEnabledPractice) => {
        return isSameRef(enabledPractice.practice, practice);
      }
    );
  }

  static addPractice(
    template: ITreatmentTemplate,
    practice: INamedDocument<IPractice>,
    price?: number
  ): void {
    if (TreatmentTemplate.practiceIsEnabled(template, practice)) {
      return;
    }

    template.enabledPractices.push({
      practice: toNamedDocument(practice),
      priceOverride: price,
    });
  }

  static removePractice(
    template: ITreatmentTemplate,
    practice: IReffable<IPractice>
  ): void {
    remove(template.enabledPractices, (enabledPractice: IEnabledPractice) => {
      return isSameRef(enabledPractice.practice, practice);
    });
  }

  static resetPractices(template: ITreatmentTemplate): void {
    template.enabledPractices = [];
  }

  static resetImplementors(template: ITreatmentTemplate): void {
    template.implementedBy = [];
  }

  static removeImplementor(
    template: ITreatmentTemplate,
    staffer: IReffable<IStaffer>
  ): void {
    remove(
      template.implementedBy,
      (implementor: IImplementsTreatmentTemplate) => {
        return isSameRef(implementor.staffer, staffer);
      }
    );
  }

  static hasImplementor(
    template: ITreatmentTemplate,
    staffer: IReffable<IStaffer>
  ): boolean {
    return template.implementedBy.some(
      (implementor: IImplementsTreatmentTemplate) => {
        return isSameRef(implementor.staffer, staffer);
      }
    );
  }

  static getImplementorIndex(
    template: ITreatmentTemplate,
    staffer: IReffable<IStaffer>
  ): number | undefined {
    return template.implementedBy.findIndex((implementor) =>
      isSameRef(implementor.staffer, staffer)
    );
  }

  static getImplementor(
    template: ITreatmentTemplate,
    staffer: IReffable<IStaffer>
  ): IImplementsTreatmentTemplate | undefined {
    return template.implementedBy.find(
      (implementor: IImplementsTreatmentTemplate) => {
        return isSameRef(implementor.staffer, staffer);
      }
    );
  }

  static getImplementors(
    template: ITreatmentTemplate,
    staff: IReffable<IStaffer>[]
  ): IImplementsTreatmentTemplate[] {
    return template.implementedBy.filter((implementor) =>
      staff.some((staffer) => isSameRef(implementor.staffer, staffer))
    );
  }

  static updateImplementor(
    template: ITreatmentTemplate,
    staffer: INamedDocument<IStaffer> | WithRef<IStaffer>,
    treatment: TreatmentTemplateTreatments,
    duration: number
  ): ITreatmentTemplate {
    const implementation: IImplementsTreatmentTemplate = {
      staffer: stafferToNamedDoc(staffer),
      treatment,
      duration,
    };

    if (!TreatmentTemplate.hasImplementor(template, staffer)) {
      template.implementedBy.push(implementation);
      return template;
    }

    const oldImplementationIndex = TreatmentTemplate.getImplementorIndex(
      template,
      staffer
    );
    if (oldImplementationIndex) {
      template.implementedBy[oldImplementationIndex] = {
        ...template.implementedBy[oldImplementationIndex],
        ...implementation,
      };
    }
    return template;
  }

  static getDuration(template: WithRef<ITreatmentTemplate>): number {
    if (template.defaltDuration) {
      return template.defaltDuration;
    }

    const min = minBy(template.implementedBy, 'duration');
    return min?.duration || 0;
  }

  static getTreatment(
    template: WithRef<ITreatmentTemplate>
  ): TreatmentTemplateTreatments | undefined {
    if (template.defaultTreatment) {
      return template.defaultTreatment;
    }

    const grouped = groupBy(template.implementedBy, 'treatment.ref.id');
    const mostCommon = maxBy(Object.values(grouped), (group) => group.length);
    return mostCommon ? mostCommon[0].treatment : undefined;
  }

  static canBeBookedOnline(
    template: ITreatmentTemplate,
    practice: IReffable
  ): boolean {
    return (
      template.isPublic &&
      TreatmentTemplate.practiceIsEnabled(template, practice)
    );
  }

  static async toTreatmentSteps(
    implementor: IImplementsTreatmentTemplate,
    feeSchedule: WithRef<IFeeSchedule>,
    treatmentCategories: WithRef<ITreatmentCategory>[],
    attributedTo?: INamedDocument<IStaffer>
  ): Promise<ITreatmentStep[]> {
    const config = await Firestore.getDoc(implementor.treatment.ref);
    const scopeResolver = new ChartedItemScopeResolver();
    const scopeRefs = scopeResolver.reduceChartedSurfacesToScope(config, []);
    const scopeRef = first(scopeRefs)?.scopeRef ?? {
      scope: ChartableSurface.WholeMouth,
    };

    if (isMultiTreatmentConfiguration(config)) {
      const multiTreatment = await TreatmentTemplate.getMultiChartedTreatment(
        config as WithRef<IMultiTreatmentConfiguration>,
        feeSchedule,
        scopeRef,
        attributedTo
      );
      const steps = multiTreatment.steps.length
        ? multiTreatment.steps
        : [TreatmentStep.init({ name: config.name })];
      return asyncForEach(steps, (step) =>
        TreatmentStep.updateDisplayPrimaryCategory(step, treatmentCategories)
      );
    }

    const treatment = await TreatmentTemplate.getChartedTreatment(
      config as WithRef<ITreatmentConfiguration>,
      feeSchedule,
      scopeRef,
      attributedTo
    );

    return [
      await TreatmentStep.updateDisplayPrimaryCategory(
        TreatmentStep.init({
          name: config.name,
          treatments: [treatment],
        }),
        treatmentCategories
      ),
    ];
  }

  static async getMultiChartedTreatment(
    config: WithRef<IMultiTreatmentConfiguration>,
    feeSchedule: WithRef<IFeeSchedule>,
    scopeRef: IScopeRef,
    attributedTo?: INamedDocument<IStaffer>
  ): Promise<IChartedMultiStepTreatment> {
    const multiStep = await ChartedMultiStepTreatment.fromConfig(
      config,
      feeSchedule,
      scopeRef,
      attributedTo
    );
    const defaultPackage = config.packages.find(
      (configPackage) => configPackage.isDefault
    );
    return defaultPackage
      ? ChartedMultiStepTreatment.applyPackagePricing(multiStep, defaultPackage)
      : multiStep;
  }

  static async getChartedTreatment(
    config: WithRef<ITreatmentConfiguration>,
    feeSchedule: WithRef<IFeeSchedule>,
    scopeRef: IScopeRef,
    attributedTo?: INamedDocument<IStaffer>
  ): Promise<IChartedTreatment> {
    const treatment = ChartedTreatment.fromConfig(
      config,
      feeSchedule,
      scopeRef,
      attributedTo
    );
    const feeScheduleManager = new FeeScheduleManager(
      Firestore.doc$(feeSchedule.ref)
    );
    const defaultPackage = config.packages.find(
      (configPackage) => configPackage.isDefault
    );
    return ChartedTreatment.applyPricing(
      treatment,
      [treatment],
      feeScheduleManager,
      false,
      defaultPackage
    );
  }
}
