import { Injectable, Injector, type Type } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  TransactionProvider,
  type IInvoice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  multiFilter,
  multiMap,
  type DocumentReference,
  type WithRef,
  filterUndefined,
  multiSwitchMap,
  multiFind,
  snapshot,
  asyncForEach,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IProviderOption,
  IResolvedTransactionOption,
  ITransactionProvider,
  ResolvedRefundTransactionOption,
  TransactionProviderError,
  isRefundTransactionProvider,
  isResolvedRefundTransactionOption,
  TransactionProviderType,
} from './transaction-provider';
import { ALL_PROVIDER_OPTIONS } from './transaction-provider-options/transaction-provider-options';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { compact } from 'lodash';

@Injectable()
export class TransactionProviders {
  trackByOption = TrackByFunctions.label<IResolvedTransactionOption>();
  allProviderOptions$: Observable<IResolvedTransactionOption[]>;
  topLevelProviderOptions$: Observable<IResolvedTransactionOption[]>;
  paymentProviderOptions$: Observable<IResolvedTransactionOption[]>;
  refundProviderOptions$: Observable<ResolvedRefundTransactionOption[]>;

  constructor(
    private _injector: Injector,
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService
  ) {
    this.allProviderOptions$ = this.resolveProviders$(ALL_PROVIDER_OPTIONS);

    this.topLevelProviderOptions$ = this.allProviderOptions$.pipe(
      multiFilter((option) => this._isTopLevelProvider(option))
    );
    this.paymentProviderOptions$ = this.allProviderOptions$.pipe(
      multiFilter((option) => this._isPaymentProvider(option))
    );
    this.refundProviderOptions$ = this.allProviderOptions$.pipe(
      map((options) => options.filter(isResolvedRefundTransactionOption))
    );
  }

  async capture(
    provider: ITransactionProvider,
    invoice: WithRef<IInvoice>
  ): Promise<DocumentReference<ITransaction> | undefined> {
    try {
      return await provider.capture(invoice);
    } catch (e) {
      if (e instanceof TransactionProviderError) {
        this._snackBar.open(e.message);
      }
      throw e;
    }
  }

  async refund(
    provider: ITransactionProvider,
    invoice: WithRef<IInvoice>,
    disableAmount?: boolean
  ): Promise<DocumentReference<ITransaction> | undefined> {
    try {
      if (!isRefundTransactionProvider(provider)) {
        throw new TransactionProviderError(`Provider does not support refunds`);
      }
      return await provider.refund(invoice, undefined, disableAmount);
    } catch (e) {
      if (e instanceof TransactionProviderError) {
        this._snackBar.open(e.message);
      }
      throw e;
    }
  }

  resolveProviders$(
    options: IProviderOption[]
  ): Observable<IResolvedTransactionOption[]> {
    return this._organisation.organisation$.pipe(
      filterUndefined(),
      map((organisation) =>
        options
          .map((option) => ({
            label: option.label,
            icon: option.icon,
            imageUrl: option.imageUrl,
            provider: this._injectProvider(option.provider),
          }))
          .filter((option) =>
            option.provider.providerRegions.includes(organisation.region)
          )
      )
    );
  }

  onlyEnabled$(): Observable<IResolvedTransactionOption[]> {
    return this.allProviderOptions$.pipe(
      multiSwitchMap((option) =>
        option.provider.isEnabled$.pipe(
          map((isEnabled) => ({ isEnabled, option }))
        )
      ),
      multiFilter((item) => item.isEnabled),
      multiMap((item) => item.option)
    );
  }

  optionsByProviderType$(
    type: TransactionProviderType
  ): Observable<IResolvedTransactionOption[]> {
    return this.onlyEnabled$().pipe(
      multiFilter((option) => option.provider.providerType === type)
    );
  }

  async findProviderById(
    type: TransactionProvider
  ): Promise<IResolvedTransactionOption | undefined> {
    return snapshot(
      this.allProviderOptions$.pipe(
        multiFind((provider) => provider.provider.providerId === type)
      )
    );
  }

  async filterTransactionsByProviderType(
    transactions: WithRef<ITransaction>[],
    type: TransactionProviderType
  ): Promise<WithRef<ITransaction>[]> {
    return compact(
      (
        await asyncForEach(transactions, async (transaction) => ({
          transaction,
          option: await this.findProviderById(transaction.provider),
        }))
      )
        .filter(({ option }) => option?.provider.providerType === type)
        .map(({ transaction }) => transaction)
    );
  }

  private _isTopLevelProvider(option: IResolvedTransactionOption): boolean {
    const topLevelProviderIds = [
      TransactionProvider.Discount,
      TransactionProvider.AccountCredit,
    ];
    return topLevelProviderIds.includes(option.provider.providerId);
  }

  private _isPaymentProvider(option: IResolvedTransactionOption): boolean {
    return (
      !this._isTopLevelProvider(option) &&
      option.provider.providerType === TransactionProviderType.Payment
    );
  }

  private _injectProvider(
    provider: Type<ITransactionProvider>
  ): ITransactionProvider {
    return this._injector.get<ITransactionProvider>(provider, undefined);
  }
}
