import { ITreatmentPlanDimension } from '@principle-theorem/reporting/interfaces';
import {
  MockGenerator,
  toSerialisedTimestamp,
  toTimestamp,
} from '@principle-theorem/shared';
import {
  AvroSerialisedVersionedSchemaMock,
  BigQueryFirestoreModelMock,
  BigQueryNamedDocumentMock,
  MockBigQueryStatusHistory,
  SerialisedDocumentReferenceMock,
} from './common.mock';
import { TreatmentPlanMock } from '@principle-theorem/principle-core';

const plan = MockGenerator.generate(TreatmentPlanMock);

export class TreatmentPlanDimensionMock
  extends BigQueryFirestoreModelMock
  implements ITreatmentPlanDimension
{
  timestamp = toSerialisedTimestamp(toTimestamp());
  name = plan.name;
  steps = [
    MockGenerator.generate(SerialisedDocumentReferenceMock),
    MockGenerator.generate(SerialisedDocumentReferenceMock),
    MockGenerator.generate(SerialisedDocumentReferenceMock),
  ];
  practitioner = MockGenerator.generate(BigQueryNamedDocumentMock);
  status = plan.status;
  statusHistory = plan.statusHistory.map((item) =>
    MockBigQueryStatusHistory(item.status)
  );
  followUpHistory = plan.followUpHistory.map((item) => ({
    ...item,
    noFollowUpReason: MockGenerator.generate(AvroSerialisedVersionedSchemaMock),
    createdAt: toSerialisedTimestamp(item.createdAt),
    followUpDate: item.followUpDate
      ? toSerialisedTimestamp(item.followUpDate)
      : undefined,
  }));
  type = plan.type;
  children = [MockGenerator.generate(BigQueryNamedDocumentMock)];
  parent = MockGenerator.generate(BigQueryNamedDocumentMock);
  config = MockGenerator.generate(SerialisedDocumentReferenceMock);
  template = MockGenerator.generate(SerialisedDocumentReferenceMock);
}
