import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  EditorMenuItemComponent,
  type MenuButtonLoaderFn,
} from '@principle-theorem/ng-prosemirror';

@Component({
  selector: 'pt-menu-divider',
  templateUrl: './menu-divider.component.html',
  styleUrls: ['./menu-divider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuDividerComponent extends EditorMenuItemComponent {
  override tooltip = '';
  @Input() vertical = true;
}

export function createMenuDivider(
  vertical: boolean = true
): MenuButtonLoaderFn {
  return () => ({
    component: MenuDividerComponent,
    data: {
      vertical,
      command: () => {
        //
      },
    },
  });
}
