import { DialogRef } from '@angular/cdk/dialog';
import {
  ComponentType,
  ConnectedPosition,
  FlexibleConnectedPositionStrategyOrigin,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import { getActiveNode } from '@principle-theorem/editor';
import {
  BasicDialogService,
  ComponentLoader,
  ConnectedDialogConfig,
} from '@principle-theorem/ng-shared';
import { Editor, isNodeSelection, posToDOMRect } from '@tiptap/core';
import {
  BubbleMenuPluginProps,
  BubbleMenuView,
} from '@tiptap/extension-bubble-menu';
import { EditorState, Plugin, PluginKey, Transaction } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { Observable, Subject } from 'rxjs';

export interface ISelectMenuData<
  Component = unknown,
  Data extends object = object
> extends ComponentLoader<Component, Data> {
  shouldShow:
    | ((props: {
        editor: Editor;
        view: EditorView;
        state: EditorState;
        oldState?: EditorState;
        from: number;
        to: number;
        element: HTMLElement;
      }) => boolean)
    | null;
  pluginKey: BubbleMenuPluginProps['pluginKey'];
  nodeType: string;
  repositionDialogOnSelectionChange?: boolean;
}

export interface IMenuInfo {
  editor: Editor;
  event$: Observable<Event>;
  transactions$: Observable<IEditorTransaction>;
}

export type ActivateType = 'focus' | 'selection';

interface ISelectMenuPluginProps
  extends Omit<BubbleMenuPluginProps, 'shouldShow'> {
  shouldShowOverride: ISelectMenuData['shouldShow'];
  nodeType: string;
  dialog: BasicDialogService;
  scrollStrategy: () => ScrollStrategy;
  component: ISelectMenuData;
  activateType: ActivateType;
  repositionDialogOnSelectionChange?: boolean;
}

export class SelectMenuView extends BubbleMenuView {
  dialogRef?: DialogRef<unknown>;
  nodeType: string;
  component: ISelectMenuData;
  dialog: BasicDialogService;
  scrollStrategy: () => ScrollStrategy;
  activateType: ActivateType;
  event$: Observable<Event>;
  repositionDialogOnSelectionChange: boolean = false;
  shouldShowOverride: ISelectMenuData['shouldShow'];

  constructor(
    props: ISelectMenuPluginProps & {
      view: EditorView;
    },
    private _event$: Observable<Event>,
    private _transactions$: Observable<IEditorTransaction>
  ) {
    super({ ...props, updateDelay: 0 });
    this.nodeType = props.nodeType;
    this.dialog = props.dialog;
    this.scrollStrategy = props.scrollStrategy;
    this.component = props.component;
    this.activateType = props.activateType;
    if (props.shouldShowOverride) {
      this.shouldShowOverride = props.shouldShowOverride;
    }
    if (props.component.repositionDialogOnSelectionChange) {
      this.repositionDialogOnSelectionChange =
        props.component.repositionDialogOnSelectionChange;
    }

    this.view.dom.parentElement?.addEventListener(
      'drag',
      this.dragstartHandler
    );

    this.editor.on('blur', this.blurHandler);
  }

  override dragstartHandler = (): void => {
    this.hideIt();
  };

  override blurHandler = ({ event }: { event: FocusEvent }): void => {
    if (this.preventHide) {
      this.preventHide = false;
      return;
    }

    const dialogElement = this.dialogRef?.componentRef?.location
      ?.nativeElement as HTMLElement;
    const isDialog =
      dialogElement &&
      dialogElement.parentNode?.contains(event.relatedTarget as Node);
    const containsTarget = this.element.parentNode?.contains(
      event.relatedTarget as Node
    );

    if (event?.relatedTarget && (containsTarget || isDialog)) {
      return;
    }

    this.hideIt();
  };

  override update(view: EditorView, oldState?: EditorState): void {
    const hasValidSelection =
      this.activateType === 'focus'
        ? true
        : view.state.selection.$from.pos !== view.state.selection.$to.pos;

    if (!hasValidSelection) {
      this.hideIt();
      return;
    }

    if (this.updateDelay > 0 && hasValidSelection) {
      this.handleDebouncedUpdate(view, oldState);
      return;
    }

    const selectionChanged = !oldState?.selection.eq(view.state.selection);
    const docChanged = !oldState?.doc.eq(view.state.doc);

    this.updateHandler(view, selectionChanged, docChanged, oldState);
  }

  override updateHandler = (
    view: EditorView,
    selectionChanged: boolean,
    docChanged: boolean,
    oldState?: EditorState
  ): void => {
    const { state, composing } = view;
    const { selection } = state;

    const isSame = !selectionChanged && !docChanged;

    if (composing || isSame) {
      return;
    }

    // support for CellSelections
    const { ranges } = selection;
    const from = Math.min(...ranges.map((range) => range.$from.pos));
    const to = Math.max(...ranges.map((range) => range.$to.pos));

    const element =
      (this.dialogRef?.componentRef?.location?.nativeElement as HTMLElement) ||
      this.element;

    const shouldShow = this.shouldShow?.({
      editor: this.editor,
      view,
      state,
      oldState,
      from,
      to,
    });

    const shouldShowOverride = this.shouldShowOverride?.({
      editor: this.editor,
      view,
      state,
      oldState,
      from,
      to,
      element,
    });

    if (this.shouldShowOverride ? !shouldShowOverride : !shouldShow) {
      this.hideIt();
      return;
    }

    const data = {
      ...this.component.data,
      ...this._getAttributes(),
      nodeType: this.component.nodeType,
      editor: this.editor,
      transactions$: this._transactions$,
      event$: this._event$,
    };

    const position = this._getPosition(view, from, to);
    this.showMenu(position, this.component.component, data);
  };

  showMenu(
    position: FlexibleConnectedPositionStrategyOrigin,
    component: ComponentType<unknown>,
    data: object
  ): void {
    if (this.dialogRef) {
      if (this.repositionDialogOnSelectionChange) {
        this.dialogRef.close();
      } else {
        return;
      }
    }
    const config = this._getDialogConfig(position, data);
    const dialogRef = this.dialog.connected(component, config);
    this.dialogRef = dialogRef;
  }

  override hide(): void {
    //
  }

  hideIt(): void {
    this.dialogRef?.close();
    this.dialogRef = undefined;
  }

  override destroy(): void {
    this.editor.off('blur', this.blurHandler);
    try {
      super.destroy();
      this.view.dom.parentElement?.removeEventListener(
        'drag',
        this.dragstartHandler
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    this.hideIt();
  }

  private _getAttributes(): object {
    if (this.editor.isActive(this.nodeType)) {
      const node = getActiveNode(this.editor.view.state, this.nodeType);
      return node?.attrs ?? {};
    }
    return {};
  }

  private _getPosition(
    view: EditorView,
    from: number,
    to: number
  ): FlexibleConnectedPositionStrategyOrigin {
    const fromNode = view.domAtPos(from);
    const toNode = view.domAtPos(to);

    if (toNode) {
      const tableNode = findParentElementByTag(toNode.node as HTMLElement, [
        'table',
        'tr',
      ]);
      const isCellSelection = (toNode.node as HTMLElement)?.tagName === 'TD';

      if (isCellSelection && fromNode.node.isSameNode(toNode.node)) {
        return toNode.node as HTMLElement;
      }

      if (isCellSelection && tableNode) {
        return tableNode;
      }
    }

    if (isNodeSelection(view.state.selection)) {
      let node = view.nodeDOM(from) as HTMLElement | undefined;
      const nodeViewWrapper = node?.dataset?.nodeViewWrapper
        ? node
        : node?.querySelector?.('[data-node-view-wrapper]');

      if (nodeViewWrapper) {
        node = nodeViewWrapper.firstChild as HTMLElement;
      }

      if (node) {
        return node;
      }
    }

    return posToDOMRect(view, from, to);
  }

  private _getDialogConfig(
    position: FlexibleConnectedPositionStrategyOrigin,
    data: unknown
  ): ConnectedDialogConfig {
    const panelClass = ['no-padding'];
    const xAxisOffsets = [
      -240, -220, -200, -180, -160, -140, -120, -100, -80, -60, -40, -20, 0, 20,
      40, 60, 80, 100, 120, 140, 160, 180, 200, 220, 240,
    ];

    return {
      width: 'auto',
      maxHeight: '50vh',
      restoreFocus: false,
      connectedTo: position,
      data,
      positions: [
        {
          panelClass,
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetX: 0,
          offsetY: 20,
        },
        {
          panelClass,
          originX: 'center',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
          offsetX: 0,
          offsetY: 20,
        },
        {
          panelClass,
          originX: 'center',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
          offsetX: 0,
          offsetY: -20,
        },
        {
          panelClass,
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetX: 0,
          offsetY: -20,
        },
        {
          panelClass,
          originX: 'center',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
          offsetX: 0,
          offsetY: -20,
        },
        {
          panelClass,
          originX: 'center',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
          offsetX: 32,
          offsetY: -20,
        },
        ...xAxisOffsets.map(
          (offsetY): ConnectedPosition => ({
            panelClass,
            originX: 'end',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'center',
            offsetX: 32,
            offsetY,
          })
        ),
        ...xAxisOffsets.map(
          (offsetX): ConnectedPosition => ({
            panelClass,
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetX,
            offsetY: 20,
          })
        ),
      ],
      hasBackdrop: false,
      scrollStrategy: this.scrollStrategy(),
      autoFocus: '__non_existing_element__',
      disableClose: true,
    };
  }
}

export function selectMenuPlugin(
  options: Omit<ISelectMenuPluginProps, 'event$'>
): Plugin {
  const transaction$ = new Subject<IEditorTransaction>();
  const event$ = new Subject<Event>();

  return new Plugin({
    key:
      typeof options.pluginKey === 'string'
        ? new PluginKey(options.pluginKey)
        : options.pluginKey,
    view: (view) =>
      new SelectMenuView({ view, ...options }, event$, transaction$),
    props: {
      handleKeyDown(this: unknown, _view: EditorView, event: KeyboardEvent) {
        event$.next(event);
        return false;
      },
    },
    appendTransaction: (transactions, oldState, newState) => {
      transaction$.next({
        transactions,
        oldState,
        newState,
      });
      return undefined;
    },
  });
}

export interface IEditorTransaction {
  transactions: readonly Transaction[];
  oldState: EditorState;
  newState: EditorState;
}

function findParentElementByTag(
  element: HTMLElement,
  tagNames: string[]
): HTMLElement | undefined {
  if (!element.parentNode) {
    return;
  }
  while (element.parentNode) {
    if (
      tagNames
        .map((tagName) => tagName.toUpperCase())
        .includes(element.tagName?.toUpperCase())
    ) {
      return element;
    }
    element = element.parentNode as HTMLElement;
  }
}
