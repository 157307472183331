import { type Timestamp } from '@principle-theorem/shared';
import { ISO_DATE_FORMAT, toMoment } from '@principle-theorem/shared';

export class MedipassTransformers {
  static toAmountString(val: number): string {
    return `$${val.toFixed(2)}`;
  }

  static toDateString(timestamp: Timestamp): string {
    return toMoment(timestamp).format(ISO_DATE_FORMAT);
  }
}
