import { User } from '@principle-theorem/principle-core';
import {
  ServiceTypeModality,
  SourceEntityRecordStatus,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  BaseStafferDestinationEntity,
  IStafferJobData,
  IStafferMigrationData,
} from '../../../destination/entities/staff';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  ProviderSourceEntity,
  type ICorePracticeProvider,
} from '../../source/entities/providers';
import { CorePracticeStafferMappingHandler } from '../mappings/staff';
import { CorePracticeStaffToUserMappingHandler } from '../mappings/staff-to-user';

export const STAFF_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: 'staff',
    label: 'Staff',
    description: `Roles will need to be assigned to practitioners with the "can conduct appointments" permission before any further migrating can be done.

    In the case of multiple practice migrations where there are staff accounts for the same person in each location, the user will need to be created beforehand and mapped to the CorePractice staff accounts using the "Multi-staffer to User" custom mapping.`,
  },
});

export class StafferDestinationEntity extends BaseStafferDestinationEntity<ICorePracticeProvider> {
  destinationEntity = STAFF_DESTINATION_ENTITY;
  stafferSourceEntity = new ProviderSourceEntity();
  stafferCustomMapping = new CorePracticeStafferMappingHandler();
  staffToUserCustomMapping = new CorePracticeStaffToUserMappingHandler();

  override sourceEntities = {
    staff: new ProviderSourceEntity(),
  };

  buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IStafferJobData<ICorePracticeProvider>
  ):
    | IStafferMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord) {
    if (data.stafferRecord.record.status === SourceEntityRecordStatus.Invalid) {
      const errorResponseData = {
        label: data.stafferRecord.record.label,
        uid: data.stafferRecord.record.uid,
        ref: data.stafferRecord.record.ref,
      };
      return this._buildErrorResponse(
        errorResponseData,
        'Source staffer is invalid'
      );
    }

    const sourceStafferId = this.sourceEntities.staff
      .getSourceRecordId(data.stafferRecord.data.data)
      .toString();

    const user = User.init({
      name: `${data.stafferRecord.data.data.firstName} ${data.stafferRecord.data.data.providerName}`,
      email: '',
      practices: migration.configuration.practices.map(
        (practice) => practice.ref
      ),
      brands: [migration.configuration.brand.ref],
      isEnabled: false,
    });

    return {
      sourceStafferId,
      user,
      stafferDetails: {
        providerNumber:
          data.stafferRecord.data.data.providerNumber ?? undefined,
        providerModality: ServiceTypeModality.GeneralDentist,
      },
    };
  }
}
