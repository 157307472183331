import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MedicalFormAlerts,
  mergeSubmittedFormWithConfig,
} from '@principle-theorem/ng-custom-forms';
import {
  CurrentScopeFacade,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { Brand, Patient } from '@principle-theorem/principle-core';
import {
  PatientForm,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  filterUndefined,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'pr-patient-medical-alerts',
  templateUrl: './patient-medical-alerts.component.html',
  styleUrls: ['./patient-medical-alerts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientMedicalAlertsComponent {
  readonly dateFormatShort = DAY_MONTH_YEAR_FORMAT;
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  expanded$ = new BehaviorSubject<boolean>(true);
  medicalFormAlerts: MedicalFormAlerts;

  @Input() expandable: boolean = true;

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(
    stateNav: StateBasedNavigationService,
    dialog: MatDialog,
    currentScope: CurrentScopeFacade
  ) {
    const latestMedicalForm$ = combineLatest([
      this.patient$.pipe(
        switchMap((patient) =>
          Patient.formData$(patient, PatientForm.MedicalHistoryForm)
        )
      ),
      currentScope.currentBrand$.pipe(
        filterUndefined(),
        switchMap((brand) => Brand.medicalHistoryFormConfig$(brand))
      ),
    ]).pipe(
      map(([patientForm, config]) =>
        mergeSubmittedFormWithConfig(patientForm, config)
      )
    );

    this.medicalFormAlerts = new MedicalFormAlerts(
      this.patient$,
      latestMedicalForm$,
      stateNav,
      dialog
    );
  }
}
