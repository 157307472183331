import { type IChecklistItem } from './checklist-item';

export interface IAppointmentChecklist {
  pre: IChecklistItem[];
  post: IChecklistItem[];
}

export enum ChecklistType {
  Pre = 'pre',
  Post = 'post',
}

export const CHECKLIST_TYPES: ChecklistType[] = [
  ChecklistType.Pre,
  ChecklistType.Post,
];
