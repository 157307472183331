import {
  IContactNumber,
  IPatientContactDetails,
} from '@principle-theorem/principle-core/interfaces';
import { compact, isEmpty, last } from 'lodash';
import { ParsedPhoneNumber, parsePhoneNumber } from 'awesome-phonenumber';
import {
  EMAIL_REGEX,
  NON_DIGITS,
  Region,
  getRegionCode,
} from '@principle-theorem/shared';

export function findMobileNumber(
  contactNumbers: IContactNumber[] = []
): string | undefined {
  const mobile = contactNumbers.find((contact) => isMobileNumber(contact));
  return mobile ? mobile.number : undefined;
}

export function formatPhoneNumber(
  mobileNumber: string,
  region: Region
): string {
  const parsedNumber = parseContactNumber(mobileNumber, region);

  if (!parsedNumber || !parsedNumber.valid) {
    return mobileNumber.replace(NON_DIGITS, '');
  }

  const extension = getNumberExtension(parsedNumber);
  return `${parsedNumber.number.e164}${extension}`;
}

export function parseContactNumber(
  contactNumber: string,
  region: Region
): ParsedPhoneNumber {
  return parsePhoneNumber(contactNumber, { regionCode: getRegionCode(region) });
}

export function isMobileNumber(contactNumber: IContactNumber): boolean {
  return !!parsePhoneNumber(contactNumber.number).typeIsMobile;
}

export function getNationalNumber(
  contactNumber: string,
  region: Region
): string | undefined {
  return parseContactNumber(contactNumber, region).number?.national.replace(
    NON_DIGITS,
    ''
  );
}

export function hasContactableNumber(
  contactNumbers: IContactNumber[]
): boolean {
  return contactNumbers.some((contactNumber) => !isEmpty(contactNumber.number));
}

export function isEmail(data: string): boolean {
  return EMAIL_REGEX.test(data.trim());
}

export function hasValidContactDetails(
  data: Partial<IPatientContactDetails>
): boolean {
  return hasValidEmail(data) || hasValidMobile(data);
}

export function hasValidMobile(data: Partial<IPatientContactDetails>): boolean {
  return findMobileNumber(data.contactNumbers) !== undefined;
}

export function getPhoneNumberVariants(
  contactNumber: string,
  region: Region
): string[] {
  return compact([
    formatPhoneNumber(contactNumber, region),
    getNationalNumber(contactNumber, region),
  ]);
}

export function hasValidEmail(data: Partial<IPatientContactDetails>): boolean {
  return !!data.email && isEmail(data.email);
}

export function getNumberExtension(parsedNumber: ParsedPhoneNumber): string {
  if (parsedNumber.number?.international.includes('ext. ')) {
    return last(parsedNumber.number?.international.split('ext. ')) ?? '';
  }
  return '';
}
