import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import {
  ButtonsContainerComponent,
  PipesModule,
} from '@principle-theorem/ng-shared';
import { type IMedicareCard } from '@principle-theorem/principle-core/interfaces';
import { MONTH_YEAR_FORMAT } from '@principle-theorem/shared';
import { MomentModule } from 'ngx-moment';

@Component({
    selector: 'pr-medicare-card-display',
    imports: [
        CommonModule,
        MatListModule,
        ButtonsContainerComponent,
        MatButtonModule,
        PipesModule,
        MomentModule,
    ],
    templateUrl: './medicare-card-display.component.html',
    styleUrls: ['./medicare-card-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedicareCardDisplayComponent {
  readonly dateFormat: string = MONTH_YEAR_FORMAT;
  @Input() medicareCard?: IMedicareCard;
  @Output() remove = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();
}
