<h3 class="mat-headline-6">Run BigQuery Sync Job</h3>

<form [formGroup]="form">
  <div class="layout-margin" fxLayout="column">
    <mat-form-field>
      <mat-label>Job Type</mat-label>
      <mat-select formControlName="type" required>
        <mat-option
          *ngFor="let jobOption of jobOptions; trackBy: trackByJobOption"
          [value]="jobOption"
        >
          {{ jobOption }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Organisation</mat-label>
      <mat-select formControlName="organisation" [required]="true">
        <mat-option
          *ngFor="
            let organisation of organisations$ | async;
            trackBy: trackByOrganisation
          "
          [value]="organisation"
          >{{ organisation.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Brand</mat-label>
      <mat-select formControlName="brand" [required]="true">
        <mat-option
          *ngFor="let brand of brands$ | async; trackBy: trackByBrand"
          [value]="brand"
          >{{ brand.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Practices</mat-label>
      <mat-select
        formControlName="practices"
        [required]="true"
        [multiple]="true"
      >
        <mat-option
          *ngFor="let practice of practices$ | async; trackBy: trackByPractice"
          [value]="practice"
          >{{ practice.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <pt-date-range-form
      [useRangePicker]="true"
      (changed)="updateDateRange($event)"
    />
  </div>
</form>

<button
  mat-flat-button
  color="primary"
  [disabled]="isDisabled$ | async"
  (click)="runSync()"
>
  Run Sync
</button>

<ng-container *ngIf="jobs$ | async as jobs">
  <div class="jobs" *ngIf="jobs.length">
    <div
      *ngFor="let job of jobs; trackBy: trackByJob"
      fxLayout="column"
      fxLayoutAlign="start start"
    >
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <span
          >{{ job.createdAt | moment | amDateFormat: fullDateFormat }} -
          {{ job.type }} - {{ job.status }}</span
        >
        <ng-container *ngIf="job | map: isLoading">
          <mat-spinner diameter="16" />
        </ng-container>
        <button
          mat-icon-button
          *ngIf="job | map: canDismiss"
          (click)="dismissJob(job)"
          matTooltip="Dismiss Job"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="mat-caption">
        {{ job | map: getJobDescription : this | async }}
      </div>
    </div>
  </div>
</ng-container>
