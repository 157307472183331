import { Mark, mergeAttributes } from '@tiptap/core';

export interface IHighlightOptions {
  HTMLAttributes: object;
}

declare module '@tiptap/core' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Commands<ReturnType> {
    disableSlashMenu: {
      disableSlashMenu: (attributes?: { color: string }) => ReturnType;
    };
  }
}

export const disableSlashMenuMark = Mark.create<IHighlightOptions>({
  name: 'disableSlashMenu',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      disableSlashMenu: {
        // eslint-disable-next-line no-null/no-null
        default: null,
        parseHTML: (element) => element.getAttribute('data-disable-slash-menu'),
        renderHTML: (): object => {
          return {
            'data-disable-slash-menu': true,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      disableSlashMenu:
        (attributes) =>
        ({ commands }) => {
          return commands.setMark(this.name, attributes);
        },
    };
  },
});
