import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { type ICreateAction } from '@principle-theorem/ng-interactions';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { Brand } from '@principle-theorem/principle-core';
import { addDoc, filterUndefined } from '@principle-theorem/shared';
import { noop } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { LabCreateComponent } from './pages/labs/lab-create/lab-create.component';

@Injectable()
export class CreateLabActionService implements ICreateAction {
  icon = 'add';
  name = 'New Lab';

  constructor(
    private _dialog: MatDialog,
    private _organisation: OrganisationService
  ) {}

  do(): void {
    const config: MatDialogConfig = DialogPresets.medium({
      data: { type: 'Add' },
      autoFocus: true,
    });

    this._dialog
      .open(LabCreateComponent, config)
      .afterClosed()
      .pipe(
        withLatestFrom(this._organisation.brand$.pipe(filterUndefined())),
        switchMap(async ([lab, brand]) => {
          if (lab) {
            await addDoc(Brand.labCol(brand), lab);
          }
        })
      )
      .subscribe(noop);
  }
}
