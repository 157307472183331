import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { isString } from 'lodash';
import * as moment from 'moment-timezone';
import {
  DEFAULT_FROM_DATE,
  type IAppointmentFilterOptions,
} from '@principle-theorem/principle-core';

export class AppointmentSelectorFormGroup extends TypedFormGroup<IAppointmentFilterOptions> {
  constructor() {
    super({
      fromDate: new TypedFormControl<moment.Moment>(
        DEFAULT_FROM_DATE
      ).withGuard(moment.isMoment),
      toDate: new TypedFormControl<moment.Moment>(DEFAULT_FROM_DATE).withGuard(
        moment.isMoment
      ),
      fromTime: new TypedFormControl<string | undefined>(undefined).withGuard(
        isString
      ),
      toTime: new TypedFormControl<string | undefined>(undefined).withGuard(
        isString
      ),
    });
  }
}
