export interface IRequestUploadTokenCommand {
  fileName: string;
  patientName: string;
  dateOfImage: string;
  timeOfImage: string;
  imageType?: string;
  patientId?: string;
  patientDateOfBirth?: string;
}

export interface ISyncUploadedImageCommand extends IRequestUploadTokenCommand {
  bucketPath: string;
}

export interface IGeneratedUploadTokenCommand
  extends IRequestUploadTokenCommand {
  bucketName: string;
  bucketPath: string;
  signedUrl: string;
}
