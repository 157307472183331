import { createAction, props } from '@ngrx/store';
import { type IEvent } from '@principle-theorem/principle-core/interfaces';
import { type SerialisedData } from '@principle-theorem/shared';
import { type IAppointmentFilterOptions } from '@principle-theorem/principle-core';

enum AppointmentSelectorActions {
  SelectEvent = '[Appointment Selector] Select Event',
  FilterOptionsUpdate = '[Appointment Selector] Filter Options Update',
}

interface ISelectEvent {
  event: IEvent;
}

export const selectEvent = createAction(
  AppointmentSelectorActions.SelectEvent,
  props<SerialisedData<ISelectEvent>>()
);

interface IUpdateFilterOptions {
  change: Partial<IAppointmentFilterOptions>;
}

export const updateFilterOptions = createAction(
  AppointmentSelectorActions.FilterOptionsUpdate,
  props<SerialisedData<IUpdateFilterOptions>>()
);
