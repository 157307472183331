import {
  SourceEntityMigrationType,
  type IPracticeMigration,
  type ISourceEntity,
  type ISourceSyncErrorData,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, getError, type WithRef } from '@principle-theorem/shared';
import { compact, isString, uniq } from 'lodash';
import { from, of, type Observable, EMPTY } from 'rxjs';
import { catchError, expand } from 'rxjs/operators';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import { CopyFiles, getBucketStoragePath } from '../../../storage';

export const PATIENT_FILE_CATEGORY_RESOURCE_TYPE = 'patientFileCategory';

export const PATIENT_FILE_CATEGORY_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient File Category List',
      description: '',
      idPrefix: PATIENT_FILE_CATEGORY_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ID4WPatientFileCategory {
  name: string;
}

export function isD4WPatientFileCategory(
  item: unknown
): item is ID4WPatientFileCategory {
  return TypeGuard.interface<ID4WPatientFileCategory>({
    name: isString,
  })(item);
}

export class PatientFileCategorySourceEntity extends BaseSourceEntity<ID4WPatientFileCategory> {
  sourceEntity = PATIENT_FILE_CATEGORY_SOURCE_ENTITY;
  entityResourceType = PATIENT_FILE_CATEGORY_RESOURCE_TYPE;
  sourceQuery = '';
  verifySourceFn = isD4WPatientFileCategory;
  override defaultOffsetSize = 1000000;
  isSingleRun = true;

  override getFromSource$(
    migration: WithRef<IPracticeMigration>
  ): Observable<ID4WPatientFileCategory[] | ISourceSyncErrorData> {
    return from(this._get(migration)).pipe(
      expand(() => EMPTY),
      catchError((error) =>
        of({
          resumeData: {},
          errorMessage: getError(error),
        })
      )
    );
  }

  translate(): object {
    return {};
  }

  getSourceRecordId(data: ID4WPatientFileCategory): string {
    return data.name;
  }

  getSourceLabel(data: ID4WPatientFileCategory): string {
    return data.name;
  }

  private async _get(
    migration: WithRef<IPracticeMigration>
  ): Promise<ID4WPatientFileCategory[]> {
    const fileCategories = await getFileCategories(
      migration.configuration.projectId,
      getBucketStoragePath(migration.source),
      `files/`
    );

    return fileCategories.map((category) => ({ name: category }));
  }
}

async function getFileCategories(
  projectId: string,
  bucketName: string,
  bucketPath: string
): Promise<string[]> {
  const storage = new CopyFiles(bucketName, bucketPath, '', '', projectId)
    .storage;

  const [files] = await storage.bucket(bucketName).getFiles({
    prefix: bucketPath,
  });

  return uniq(
    compact(
      files.map((file) => {
        const folderPath = file.name.split('/');

        if (folderPath.length > 4) {
          return folderPath[3].trim();
        }
      })
    )
  );
}
