import {
  CustomChartType,
  ICustomReportChart,
  MeasureReducer,
  type ICustomChartSettings,
} from '@principle-theorem/principle-core/interfaces';
import {
  IMeasureBuilderData,
  toMeasureBuilderData,
  type AccountCreditDimensionMeasures,
  type CanBeChartedProperty,
  type CanGroupMeasuresProperty,
  type InvoiceDimensionMeasures,
  type PatientDimensionMeasures,
  type PatientInteractionDimensionMeasures,
} from '@principle-theorem/reporting';
import { uid } from '@principle-theorem/shared';
import { ChartFilters } from './chart-filters';
import {
  type IChartFilter,
  type IChartFilterResult,
} from './report-builder-data-source';

export class ReportBuilderChartFilters {
  static predefined(
    id: string,
    groupBy: CanGroupMeasuresProperty,
    result: IChartFilterResult
  ): IChartFilter {
    return {
      id,
      chart: {
        uid: uid(),
        type: result.config.type,
        label: result.config.labels.title,
        groupBy: groupBy.metadata.id,
        settings: result.config.builderData.measures.map((measure) => ({
          uid: uid(),
          label: measure.label ?? measure.measure.metadata.label,
          dataPoint: measure.measure.metadata.id,
          reducer: measure.reducer || MeasureReducer.Sum,
          filters: measure.filters,
        })),
        builderData: {
          plottedOverTime: result.config.builderData.plottedOverTime,
          accumulateOverTime: result.config.builderData.accumulateOverTime,
          colourOverride: result.config.builderData.colourOverride,
          groupLimit: result.config.builderData.groupLimit,
          otherGroupLabel: result.config.builderData.otherGroupLabel,
        },
        builderConfig: result.builderConfig,
      },
      groupBy,
      availableInEditMode: true,
      build: () => result,
    };
  }

  static configureChart(
    chart: ICustomReportChart,
    groupBy: CanGroupMeasuresProperty,
    measures: IMeasureBuilderData[]
  ): IChartFilter<ICustomChartSettings> | undefined {
    switch (chart.type) {
      case CustomChartType.NumberSummary:
        return ReportBuilderChartFilters.predefined(
          groupBy.metadata.id,
          groupBy,
          ChartFilters.numberChart(
            chart.label,
            groupBy,
            measures,
            chart.builderData
          )
        );
      case CustomChartType.Table:
        return ReportBuilderChartFilters.predefined(
          groupBy.metadata.id,
          groupBy,
          ChartFilters.tableChart(
            chart.label,
            groupBy,
            measures,
            chart.builderData
          )
        );
      case CustomChartType.Column:
        return ReportBuilderChartFilters.predefined(
          groupBy.metadata.id,
          groupBy,
          ChartFilters.columnChart(
            chart.label,
            groupBy,
            measures,
            chart.builderConfig,
            chart.builderData
          )
        );
      case CustomChartType.Line:
        return ReportBuilderChartFilters.predefined(
          groupBy.metadata.id,
          groupBy,
          ChartFilters.lineChart(
            chart.label,
            groupBy,
            measures,
            chart.builderConfig,
            chart.builderData
          )
        );
      case CustomChartType.Pie:
        return ReportBuilderChartFilters.predefined(
          groupBy.metadata.id,
          groupBy,
          ChartFilters.pieChart(
            chart.label,
            groupBy,
            measures,
            chart.builderData
          )
        );
      case CustomChartType.Row:
        return ReportBuilderChartFilters.predefined(
          groupBy.metadata.id,
          groupBy,
          ChartFilters.rowChart(
            chart.label,
            groupBy,
            measures,
            chart.builderData
          )
        );
      case CustomChartType.SelectMenu:
        return ReportBuilderChartFilters.predefined(
          groupBy.metadata.id,
          groupBy,
          ChartFilters.selectMenu(
            chart.label,
            groupBy,
            measures,
            chart.builderData
          )
        );
      default:
        // eslint-disable-next-line no-console
        console.error(
          `ReportBuilderChartFilters.configureChart - unknown type "${chart.type}"`
        );
        return;
    }
  }

  static patient(
    patient: PatientDimensionMeasures,
    sumBy: CanBeChartedProperty
  ): IChartFilter[] {
    const measure = [toMeasureBuilderData(sumBy)];
    return [
      ReportBuilderChartFilters.predefined(
        'patient.gender',
        patient.gender,
        ChartFilters.pieChart('Gender', patient.gender, measure)
      ),
      ReportBuilderChartFilters.predefined(
        'patient.ageRange',
        patient.ageRange,
        ChartFilters.rowChart('Age Range', patient.ageRange, measure)
      ),
      ReportBuilderChartFilters.predefined(
        'patient.status',
        patient.status,
        ChartFilters.pieChart('Status', patient.status, measure)
      ),
      ReportBuilderChartFilters.predefined(
        'patient.referrerType',
        patient.referrerType,
        ChartFilters.rowChart(
          'Referrer Type',
          patient.referrerType,
          [toMeasureBuilderData(patient.count)],
          {
            groupLimit: 15,
            otherGroupLabel: 'Other',
          }
        )
      ),
      ReportBuilderChartFilters.predefined(
        'patient.patientReferrers',
        patient.patientReferrers,
        ChartFilters.selectMenu('Patient Referrers', patient.patientReferrers, [
          toMeasureBuilderData(patient.count),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'patient.tags',
        patient.tags,
        ChartFilters.rowChart('Tags', patient.tags, measure, {
          groupLimit: 15,
          otherGroupLabel: 'Other',
        })
      ),
      ReportBuilderChartFilters.predefined(
        'patient.tags.select',
        patient.tags,
        ChartFilters.selectMenu('Tags', patient.tags, measure)
      ),
      ReportBuilderChartFilters.predefined(
        'patient.hasMobileNumber',
        patient.hasMobileNumber,
        ChartFilters.pieChart('Has Mobile Number', patient.hasMobileNumber, [
          toMeasureBuilderData(patient.count),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'patient.hasEmail',
        patient.hasEmail,
        ChartFilters.pieChart('Has Email', patient.hasEmail, [
          toMeasureBuilderData(patient.count),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'patient.nextVisitBooked',
        patient.nextVisitBooked,
        ChartFilters.pieChart('Next Visit Booked', patient.nextVisitBooked, [
          toMeasureBuilderData(patient.count),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'patient.preferredPractice',
        patient.preferredPractice,
        ChartFilters.rowChart(
          'Preferred Practice',
          patient.preferredPractice,
          measure,
          { groupLimit: 15, otherGroupLabel: 'Other' }
        )
      ),
      ReportBuilderChartFilters.predefined(
        'patient.preferredDentist',
        patient.preferredDentist,
        ChartFilters.rowChart(
          'Preferred Dentist',
          patient.preferredDentist,
          measure,
          { groupLimit: 15, otherGroupLabel: 'Other' }
        )
      ),
      ReportBuilderChartFilters.predefined(
        'patient.preferredHygienist',
        patient.preferredHygienist,
        ChartFilters.rowChart(
          'Preferred Hygienist',
          patient.preferredHygienist,
          measure,
          { groupLimit: 15, otherGroupLabel: 'Other' }
        )
      ),
      ReportBuilderChartFilters.predefined(
        'insurance.hasMedicare',
        patient.hasMedicareCard,
        ChartFilters.pieChart('Has Medicare Card', patient.hasMedicareCard, [
          toMeasureBuilderData(patient.count),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'insurance.hasDVA',
        patient.hasDvaCard,
        ChartFilters.pieChart('Has DVA Card', patient.hasDvaCard, [
          toMeasureBuilderData(patient.count),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'insurance.hasHealthFund',
        patient.hasHealthFundCard,
        ChartFilters.pieChart(
          'Has Health Fund Card',
          patient.hasHealthFundCard,
          [toMeasureBuilderData(patient.count)]
        )
      ),
      ReportBuilderChartFilters.predefined(
        'insurance.healthFundType.select',
        patient.healthFundType,
        ChartFilters.selectMenu('Health Fund Type', patient.healthFundType, [
          toMeasureBuilderData(patient.count),
        ])
      ),
    ];
  }

  static patientInteraction(
    patientInteraction: PatientInteractionDimensionMeasures,
    sumBy: CanBeChartedProperty
  ): IChartFilter[] {
    const measure = [toMeasureBuilderData(sumBy)];
    return [
      ReportBuilderChartFilters.predefined(
        'patientInteraction.type',
        patientInteraction.type,
        ChartFilters.pieChart('Type', patientInteraction.type, measure)
      ),
      ReportBuilderChartFilters.predefined(
        'patientInteraction.pinned',
        patientInteraction.pinned,
        ChartFilters.pieChart('Pinned', patientInteraction.pinned, measure)
      ),
      ReportBuilderChartFilters.predefined(
        'patientInteraction.owner',
        patientInteraction.owner,
        ChartFilters.rowChart('Owner', patientInteraction.owner, measure, {
          groupLimit: 15,
          otherGroupLabel: 'Other',
        })
      ),
      ReportBuilderChartFilters.predefined(
        'patientInteraction.tags',
        patientInteraction.tags,
        ChartFilters.rowChart('Tags', patientInteraction.tags, measure, {
          groupLimit: 15,
          otherGroupLabel: 'Other',
        })
      ),
    ];
  }

  static invoice(
    invoice: InvoiceDimensionMeasures,
    sumBy: CanBeChartedProperty
  ): IChartFilter[] {
    const measure = [toMeasureBuilderData(sumBy)];
    return [
      ReportBuilderChartFilters.predefined(
        'invoice.status',
        invoice.status,
        ChartFilters.pieChart('Status', invoice.status, measure)
      ),
      ReportBuilderChartFilters.predefined(
        'invoice.issuedDate',
        invoice.issuedAt,
        ChartFilters.columnChartOverTime(
          'Issued Date',
          invoice.issuedAt,
          measure
        )
      ),
      ReportBuilderChartFilters.predefined(
        'invoice.paidDate',
        invoice.paidAt,
        ChartFilters.columnChartOverTime('Paid Date', invoice.paidAt, measure)
      ),
      ReportBuilderChartFilters.predefined(
        'invoice.deposits',
        invoice.issuedAt,
        ChartFilters.columnChartOverTime('Deposits', invoice.issuedAt, [
          toMeasureBuilderData(invoice.depositTotal),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'invoice.productsTotal',
        invoice.issuedAt,
        ChartFilters.columnChartOverTime('Products Total', invoice.issuedAt, [
          toMeasureBuilderData(invoice.productTotal),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'invoice.treatmentsTotal',
        invoice.issuedAt,
        ChartFilters.columnChartOverTime('Treatments Total', invoice.issuedAt, [
          toMeasureBuilderData(invoice.treatmentTotal),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'invoice.products',
        invoice.products,
        ChartFilters.selectMenu('Products', invoice.products, [
          toMeasureBuilderData(invoice.count),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'invoice.treatments',
        invoice.treatments,
        ChartFilters.selectMenu('Treatments', invoice.treatments, [
          toMeasureBuilderData(invoice.count),
        ])
      ),
      ReportBuilderChartFilters.predefined(
        'invoice.serviceCodes',
        invoice.serviceCodes,
        ChartFilters.selectMenu('Service Codes', invoice.serviceCodes, [
          toMeasureBuilderData(invoice.count),
        ])
      ),
    ];
  }

  static accountCredit(
    accountCredit: AccountCreditDimensionMeasures,
    sumBy: CanBeChartedProperty
  ): IChartFilter[] {
    return [
      ReportBuilderChartFilters.predefined(
        'accountCredit.reservedForPractitioner',
        accountCredit.reservedForPractitioner,
        ChartFilters.columnChart(
          'Reserved For Practitioner',
          accountCredit.reservedForPractitioner,
          [toMeasureBuilderData(sumBy)],
          { rotateXAxisLabels: true }
        )
      ),
    ];
  }
}
