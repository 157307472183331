<div
  *ngIf="nextStage$ | ngrxPush as nextStage"
  [ngClass]="{ overdue: isOverdue$ | ngrxPush, ready: !nextStage.due }"
>
  <ng-container *ngIf="!nextStage.due">
    Ready For {{ nextStage.name | titlecase }}!
  </ng-container>

  <ng-container *ngIf="nextStage.due">
    Due: {{ nextStage.due | moment | amTimeAgo }}
  </ng-container>
</div>
