import {
  AtLeast,
  type INamedDocument,
  type IXSLXExport,
  type XSLXCell,
  isSameRef,
} from '@principle-theorem/shared';
import { type Column } from 'exceljs';
import { type ICorePracticeEventClass } from '../../source/entities/event-classes';
import {
  ITranslationMap,
  type ITag,
  type ITreatmentCategory,
} from '@principle-theorem/principle-core/interfaces';

export enum EventClassResourceType {
  TreatmentCategory = 'Treatment Category',
  AppointmentTag = 'Appointment Tag',
}

export type EventClassDestination = ITreatmentCategory | ITag;

export interface IEventClassToTreatmentCategoryXSLX {
  label: string;
  mapTo: string;
}

export const EVENT_CLASS_TO_TREATMENT_CATEGORY_HEADERS: AtLeast<
  Column,
  'key' | 'header'
>[] = [
  {
    key: 'label',
    header: 'Label',
  },
  {
    key: 'mapTo',
    header: 'Map To',
  },
];

export class EventClassToTreatmentCategoryToXSLX
  implements
    IXSLXExport<ICorePracticeEventClass, IEventClassToTreatmentCategoryXSLX>
{
  headers = EVENT_CLASS_TO_TREATMENT_CATEGORY_HEADERS;

  constructor(
    private _existingMappings: ITranslationMap<EventClassDestination>[],
    private _treatmentCategories: INamedDocument<ITreatmentCategory>[],
    private _appointmentTags: INamedDocument<ITag>[]
  ) {}

  translate(
    records: ICorePracticeEventClass[]
  ): Record<keyof IEventClassToTreatmentCategoryXSLX, XSLXCell>[] {
    return records.map((record) => {
      const existingRecord = this._existingMappings.find(
        (existingMapping) =>
          existingMapping.sourceIdentifier === record.id.toString()
      );
      const destinationIdentifier = existingRecord?.destinationIdentifier;
      const options: INamedDocument[] = [
        ...this._treatmentCategories,
        ...this._appointmentTags,
      ];
      const mappedCategory = destinationIdentifier
        ? options.find((category) => isSameRef(category, destinationIdentifier))
        : undefined;

      const formattedOptions = [
        ...this._treatmentCategories,
        ...this._appointmentTags,
      ].map((category) => category.name);

      return {
        label: { value: record.description },
        mapTo: {
          value: mappedCategory?.name,
          dataValidation: {
            type: 'list',
            formulae: [`"${formattedOptions.join(',')}"`],
          },
        },
      };
    });
  }
}
