import {
  IChartedServiceSmartGroup,
  IChartedMultiStepTreatment,
  IChartedTreatment,
  IPricedServiceCodeEntry,
  ITreatmentPackage,
} from '@principle-theorem/principle-core/interfaces';
import { IChartedSurface } from '@principle-theorem/principle-core/interfaces';
import { isEqual, uniqWith } from 'lodash';
import { SmartGroupRuleHandler } from '../service-codes/smart-group-rule-handler';
import { ISurfaceScopeRefPair } from '../core/charted-item-scope-resolver';
import { FeeScheduleManager } from '../fees/fee-schedule/fee-schedule-manager';
import { ChartedTreatment } from './charted-treatment';
import { asyncForEach, doc$ } from '@principle-theorem/shared';

export function getSelectedSmartCodes(
  treatment: IChartedTreatment
): IChartedServiceSmartGroup[] {
  const ruleHandler = new SmartGroupRuleHandler();
  return treatment.serviceCodeSmartGroups.map((serviceCodeSmartGroup) => ({
    ...serviceCodeSmartGroup,
    selected: ruleHandler.evaluateSelected(
      serviceCodeSmartGroup,
      treatment.scopeRef,
      treatment.chartedSurfaces
    ),
  }));
}

export function uniqWithChartedRef(
  surfaceA: IChartedSurface,
  surfaceB: IChartedSurface
): boolean {
  return isEqual(surfaceA.chartedRef, surfaceB.chartedRef);
}

export class ChartedTreatmentUpdater {
  static async syncPricingRules(
    treatments: IChartedTreatment[]
  ): Promise<IChartedTreatment[]> {
    return asyncForEach(treatments, (treatment) => {
      const feeScheduleManager = new FeeScheduleManager(
        doc$(treatment.feeSchedule.ref)
      );
      return ChartedTreatment.applyFeeSchedule(
        treatment,
        treatments,
        feeScheduleManager,
        false,
        undefined,
        true
      );
    });
  }

  async updatedNestedTreatmentsByScope(
    multiTreatment: IChartedMultiStepTreatment,
    surfaceScopeRefPair: ISurfaceScopeRefPair
  ): Promise<IChartedMultiStepTreatment> {
    return this.updatedNestedTreatmentsFn(
      multiTreatment,
      (treatment: IChartedTreatment) => {
        treatment.scopeRef = surfaceScopeRefPair.scopeRef;
        return treatment;
      }
    );
  }

  async updatedNestedTreatmentsFn(
    multiTreatment: IChartedMultiStepTreatment,
    fn: (
      treatment: IChartedTreatment
    ) => Promise<IChartedTreatment> | IChartedTreatment
  ): Promise<IChartedMultiStepTreatment> {
    const stepPromises = multiTreatment.steps.map(async (step) => {
      const treatmentPromises = step.treatments.map(
        async (treatment: IChartedTreatment) => fn(treatment)
      );
      step.treatments = await Promise.all(treatmentPromises);
      return step;
    });
    multiTreatment.steps = await Promise.all(stepPromises);
    return multiTreatment;
  }

  async updatedTreatmentBySurface(
    treatment: IChartedTreatment,
    stepTreatments: IChartedTreatment[],
    surfaces: IChartedSurface[],
    feeScheduleManager: FeeScheduleManager,
    treatmentPackage?: ITreatmentPackage
  ): Promise<IChartedTreatment> {
    treatment.chartedSurfaces = uniqWith(surfaces, uniqWithChartedRef);
    treatment.serviceCodeSmartGroups = getSelectedSmartCodes(treatment);
    return this._syncChartedSurfacesToServiceCodes(
      await ChartedTreatment.applyPricing(
        treatment,
        [...stepTreatments, treatment],
        feeScheduleManager,
        false,
        treatmentPackage
      )
    );
  }

  private _syncChartedSurfacesToServiceCodes(
    treatment: IChartedTreatment
  ): IChartedTreatment {
    treatment.serviceCodes = this._setServiceCodesChartedSurfaces(
      treatment.serviceCodes,
      treatment.chartedSurfaces
    );

    treatment.serviceCodeGroups = treatment.serviceCodeGroups.map(
      (serviceCodeGroup) => ({
        ...serviceCodeGroup,
        serviceCodes: this._setServiceCodesChartedSurfaces(
          serviceCodeGroup.serviceCodes,
          treatment.chartedSurfaces
        ),
      })
    );

    treatment.serviceCodeSmartGroups = treatment.serviceCodeSmartGroups.map(
      (serviceCodeSmartGroup) => ({
        ...serviceCodeSmartGroup,
        serviceCodes: this._setServiceCodesChartedSurfaces(
          serviceCodeSmartGroup.serviceCodes,
          treatment.chartedSurfaces
        ),
      })
    );

    return treatment;
  }

  private _setServiceCodesChartedSurfaces(
    serviceCodes: IPricedServiceCodeEntry[],
    chartedSurfaces: IChartedSurface[]
  ): IPricedServiceCodeEntry[] {
    return serviceCodes.map((serviceCode) => ({
      ...serviceCode,
      chartedSurfaces,
    }));
  }
}
