<div class="page-wrap">
  <div class="display-box">
    <div
      *ngIf="state.isLoading$ | async"
      fxLayout="column"
      fxLayoutAlign="center center"
      class="layout-padding"
    >
      <mat-spinner />
      <h1 class="confirming-appointment">Loading Appointment</h1>
    </div>

    <div
      *ngIf="state.isAlreadySubmitted$ | async"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <ng-container
        [ngTemplateOutlet]="confirmationMessage"
        [ngTemplateOutletContext]="{ message: 'Appointment Already Confirmed' }"
       />
    </div>

    <div
      *ngIf="state.isConfirmed$ | async"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <ng-container
        [ngTemplateOutlet]="confirmationMessage"
        [ngTemplateOutletContext]="{ message: 'Appointment Confirmed' }"
       />
    </div>

    <ng-container *ngIf="info$ | async as info">
      <pr-patient-confirm-appointment-details
        [info]="info"
       />
    </ng-container>

    <ng-container *ngIf="state.isNotAvailable$ | async">
      <pr-empty-state
        image="broken"
        title="appointment found"
        [descriptionEnabled]="false"
       />
    </ng-container>
  </div>
</div>

<ng-template #confirmationMessage let-message="message">
  <div class="confirmation-message">
    <mat-icon>check_circle_outline</mat-icon>
    <h1>{{ message }}</h1>
  </div>
</ng-template>
