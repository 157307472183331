import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import {
  type IAppointment,
  isEventable,
} from '@principle-theorem/principle-core/interfaces';
import { DATE_TIME_FORMAT, type WithRef } from '@principle-theorem/shared';

@Component({
  selector: 'pr-appointment-scheduling-header',
  templateUrl: './appointment-scheduling-header.component.html',
  styleUrls: ['./appointment-scheduling-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentSchedulingHeaderComponent {
  private _appointment: WithRef<IAppointment>;
  readonly dateFormat = DATE_TIME_FORMAT;
  deleteDisabled = true;
  @Input() showDelete = false;
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  @HostBinding('class.required')
  required = false;

  @HostBinding('class.scheduled')
  get scheduled(): boolean {
    return isEventable(this._appointment);
  }

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    this._appointment = appointment;
    const canBeDeleted: boolean = appointment
      ? !isEventable(appointment)
      : false;
    this.deleteDisabled = !canBeDeleted;
  }
  get appointment(): WithRef<IAppointment> {
    return this._appointment;
  }
}
