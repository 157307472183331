<form [formGroup]="form" class="flex flex-col gap-2">
  <mat-form-field class="mb-4 flex-1">
    <mat-label>Full Name</mat-label>
    <input matInput type="text" formControlName="name" required />
    <mat-hint>
      Preferred name example: Leonardo (Leo) Dicaprio. (Leo) will be removed
      from patient correspondence.
    </mat-hint>
  </mat-form-field>

  <mat-form-field class="flex-1">
    <mat-label>Gender</mat-label>
    <mat-select formControlName="gender" required>
      <mat-option
        *ngFor="let gender of genders; trackBy: trackByGender"
        [value]="gender"
      >
        {{ gender | splitCamel | titlecase }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <pt-iso-date-selector
    class="flex-1"
    placeholder="Date of birth"
    formControlName="dateOfBirth"
    [required]="'dateOfBirth' | map: isRequired$ : this | async"
    [max]="today"
  />

  <pr-address-input formControlName="address" />

  <mat-form-field class="flex-1">
    <mat-label>Email</mat-label>
    <input
      matInput
      type="email"
      formControlName="email"
      [required]="'email' | map: isRequired$ : this | async"
    />
  </mat-form-field>

  <div formArrayName="contactNumbers" fxLayout="column">
    <pr-contact-number-input
      *ngFor="
        let control of contactNumberControls.controls;
        trackBy: trackByIndex;
        index as index;
        first as isFirst
      "
      [form]="control"
      [isFirst]="isFirst"
      [required]="'contactNumbers' | map: isRequired$ : this | async"
    >
      <div class="flex flex-row content-center">
        <button
          mat-icon-button
          (click)="contactNumberControls.addControl(index)"
        >
          <mat-icon>add</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="contactNumberControls.removeControl(index)"
        >
          <mat-icon>remove</mat-icon>
        </button>
      </div>
    </pr-contact-number-input>
  </div>

  <mat-form-field
    *ngIf="referralSources$ | async as referralSources"
    class="flex-1"
  >
    <mat-label>Referrer</mat-label>
    <mat-select
      formControlName="referrer"
      [required]="'referrer' | map: isRequired$ : this | async"
    >
      <mat-option
        *ngFor="let source of referralSources; trackBy: trackByReferralSource"
        [value]="source | map: toReferralSource : this"
      >
        {{ source.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="mat-toolbar mat-toolbar-single-row -ml-4">
    Health Fund Card Details
  </div>

  <mat-checkbox formControlName="showHealthFundCard">
    Health Fund Card
  </mat-checkbox>
  <pr-patient-details-health-fund-form
    *ngIf="form.controls.showHealthFundCard.value"
    [form]="form.controls.healthFundCard"
  />

  <mat-checkbox formControlName="showMedicareCard">
    Medicare Card
  </mat-checkbox>
  <pr-patient-details-medicare-form
    *ngIf="form.controls.showMedicareCard.value"
    [form]="form.controls.medicareCard"
  />

  <mat-checkbox formControlName="showDVACard">DVA Card</mat-checkbox>
  <pr-patient-details-dva-form
    *ngIf="form.controls.showDVACard.value"
    [form]="form.controls.dvaCard"
  />
</form>
