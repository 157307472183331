import {
  IPricingRule,
  IPricingRuleProvider,
  PricingRuleType,
} from '@principle-theorem/principle-core/interfaces';
import { first } from 'lodash';

export class FlatPricingProvider implements IPricingRuleProvider {
  canProvide(rule: IPricingRule): boolean {
    return rule.type === PricingRuleType.Flat;
  }

  determinePrice(
    rule: IPricingRule,
    _unitCount: number,
    _unitIndex: number
  ): number | undefined {
    return first(rule.ruleItems)?.price;
  }
}
