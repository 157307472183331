import { type IMenuButton } from '@principle-theorem/editor';
import { type MenuButtonLoaderFn } from '@principle-theorem/ng-prosemirror';
import { BasicMenuButtonComponent } from '../../menu-bar/basic-menu-button/basic-menu-button.component';

export function createTableOfContentsMenuButton(): MenuButtonLoaderFn<
  BasicMenuButtonComponent,
  IMenuButton
> {
  return () => ({
    component: BasicMenuButtonComponent,
    data: {
      buttonType: 'basic',
      buttonText: 'Table Of Contents',
      tooltip: 'Table Of Contents',
      shortcut: '',
      icon: 'toc',
      command: (editor) => {
        editor.chain().focus().addTableOfContents().run();
      },
    },
  });
}
