<mat-toolbar class="flex items-center" color="accent">
  <span>Email {{ data.patient.name }}</span>
</mat-toolbar>

<mat-dialog-content>
  <form class="flex flex-col gap-2" [formGroup]="emailForm">
    <div [hidden]="hasSinglePractice$ | async">
      <pr-practice-selector
        class="flex-1"
        label="Send From"
        formControlName="practice"
        [brands]="brands$ | async"
      />
    </div>

    <mat-form-field class="flex-1">
      <mat-label>Subject</mat-label>
      <input matInput formControlName="subject"/>
    </mat-form-field>

    <mat-form-field class="w-full" subscriptSizing="dynamic">
      <mat-label>Content</mat-label>
      <pr-content-editor
        placeholder="Email Message"
        [formControl]="emailForm.controls.content"
        [menuEnabled]="false"
        [required]="true"
        [extensions]="extensions"
      />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button (click)="dialogRef.close()">Close</button>
    <button
      mat-stroked-button
      [disabled]="(canRevert$ | async) === false"
      (click)="revertToSystemTemplate()"
    >
      Revert To System Template
    </button>
    <pt-loader-button
      color="primary"
      type="flat"
      [loading]="submitting$ | async"
      [disabled]="isDisabled$ | async"
      (click)="send()"
    >
      Send
    </pt-loader-button>
  </pt-buttons-container>
</mat-dialog-actions>
