@if (element$ | async; as element) {
  @if (editableContent$ | async; as content) {
    <div class="flex grow rounded border border-solid border-slate-200">
      <pr-content-editor
        class="min-h-[250px]"
        [formControl]="contentCtrl"
        [menuEnabled]="true"
        [extensions]="extensions"
        [required]="true"
      />
    </div>
  } @else {
    <pr-form-builder-element-preview [element]="element" />
  }

  <div
    class="element-children flex h-full grow gap-2"
    [ngClass]="{
      'flex-col': (isHorizontal$ | async) === false,
      'flex-row flex-wrap': isHorizontal$ | async
    }"
  >
    @for (child of element.children; track child.uid) {
      <pr-preview-form-issue-element [element]="child" />
    }
  </div>
}
