<mat-toolbar color="accent">Medicare - {{ data.title }}</mat-toolbar>

<mat-vertical-stepper>
  <mat-step [stepControl]="medicareCardForm.medicareForm">
    <ng-template matStepLabel>Medicare Card</ng-template>
    <pr-medicare-card-form
      #medicareCardForm="prMedicareCardForm"
      [medicareCard]="medicareCard"
      (medicareCardChange)="updateMedicareCard($event)"
     />
    <button mat-flat-button color="primary" matStepperNext>Next</button>
  </mat-step>

  <mat-step [stepControl]="referralForm.referralForm">
    <ng-template matStepLabel>Referral Information</ng-template>
    <pr-claim-referral-form
      #referralForm="prClaimReferralForm"
      [maxDate]="maxDate"
      (referralChange)="referralData = $event"
     />
    <button
      mat-flat-button
      color="primary"
      [disabled]="
        isInvalid(medicareCardForm.medicareForm, referralForm.referralForm)
      "
      (click)="submit()"
    >
      Submit
    </button>
  </mat-step>
</mat-vertical-stepper>
