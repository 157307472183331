<h3 class="!font-bold !opacity-80">Scanned Records</h3>

@if (records$ | async; as records) {
  <div class="flex flex-col gap-3">
    @for (record of records; track record.ref.path) {
      @defer (on viewport) {
        <pr-sterilisation-record-item
          [record]="record"
          [packOptions]="packOptions$ | async"
          [omitFields]="omitFields$ | async"
        />
      } @placeholder (minimum 150ms) {
        <ngx-skeleton-loader
          animation="pulse"
          count="1"
          [theme]="{ height: '100px' }"
        />
      }
    }
  </div>
}
