@if (loaded$ | async) {
  <div
    *ngrxLet="disabled$ as disabled"
    class="sticky bottom-0 z-50 flex justify-end gap-4 border border-solid border-t-gray-300 bg-white p-4"
  >
    <button mat-stroked-button (click)="cancel()">Cancel</button>
    <button
      mat-flat-button
      color="warn"
      [disabled]="disabled"
      (click)="discardChanges()"
    >
      Discard Changes
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="disabled"
      (click)="saveChanges()"
    >
      Save Changes
    </button>
  </div>
}
