import {
  PatientEventAction,
  PatientStatus,
} from '@principle-theorem/principle-core/interfaces';
import { IPatientEventFact } from '@principle-theorem/reporting/interfaces';
import {
  BaseMock,
  MockGenerator,
  MockTimestamp,
  toSerialisedTimestamp,
} from '@principle-theorem/shared';
import { BrandDimensionMock } from '../dimensions/brand-dimension.mock';
import { SerialisedDocumentReferenceMock } from '../dimensions/common.mock';
import { PatientDimensionMock } from '../dimensions/patient-dimension.mock';

export class PatientEventFactMock
  extends BaseMock
  implements IPatientEventFact
{
  patientRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  brandRef = MockGenerator.generate(SerialisedDocumentReferenceMock);
  patient = MockGenerator.generate(PatientDimensionMock);
  referrer = this.patient.referrer;
  brand = MockGenerator.generate(BrandDimensionMock);
  event = {
    action: PatientEventAction.Activated,
    statusBefore: PatientStatus.Lead,
    statusAfter: PatientStatus.Active,
  };
  timestamp = toSerialisedTimestamp(MockTimestamp());
}
