import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class RedirectGuard {
  private _document: Document = inject(DOCUMENT);

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this._document.defaultView) {
      return false;
    }
    this._document.defaultView.location.href = String(
      route.data['externalUrl']
    );
    return true;
  }
}
