import { type ComponentType } from '@angular/cdk/portal';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgCoreModule } from '@principle-theorem/ng-core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgProsemirrorModule } from '@principle-theorem/ng-prosemirror';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { NgxMoveableModule } from 'ngx-moveable';
import { NgxTiptapModule } from 'ngx-tiptap';
import { DragResizeComponent } from '../drag-resize/drag-resize.component';
import { EditorAutocompleteTriggerDirective } from '../editor-autocomplete/editor-autocomplete-trigger.directive';
import { AIImagePromptDialogComponent } from './ai/buttons/ai-image-prompt-dialog/ai-image-prompt-dialog.component';
import { AIImagePromptMenuComponent } from './ai/buttons/ai-image-prompt-menu/ai-image-prompt-menu.component';
import { AITextPromptDialogComponent } from './ai/buttons/ai-text-prompt-dialog/ai-text-prompt-dialog.component';
import { AITextPromptMenuComponent } from './ai/buttons/ai-text-prompt-menu/ai-text-prompt-menu.component';
import { HeadingMenuComponent } from './heading/heading-menu/heading-menu.component';
import { ImageCommandsService } from './image/image-commands.service';
import { ImageEditMenuComponent } from './image/image-edit-menu/image-edit-menu.component';
import { ImageNodeComponent } from './image/image-node/image-node.component';
import { ImageUploadMenuComponent } from './image/image-upload-menu/image-upload-menu.component';
import { ImageUploadingComponent } from './image/image-uploading/image-uploading.component';
import { LinkHoverMenuComponent } from './link/link-hover-menu/link-hover-menu.component';
import { MentionAutocompleteNodeComponent } from './mention/mention-autocomplete-node/mention-autocomplete-node.component';
import { MentionNodeComponent } from './mention/mention-node/mention-node.component';
import { SnippetAutocompleteNodeComponent } from './snippet/snippet-autocomplete-node/snippet-autocomplete-node.component';
import { TableOfContentsNodeComponent } from './table-of-contents/table-of-contents-node.component';
import { TableCommandsService } from './table/table-commands.service';
import { TableEditMenuComponent } from './table/table-edit-menu/table-edit-menu.component';
import { TextBadgeMenuComponent } from './text-styling/text-badge-menu/text-badge-menu.component';
import { TextStylingMenuComponent } from './text-styling/text-styling-menu/text-styling-menu.component';
import { VideoCommandsService } from './video/video-commands.service';
import { VideoEmbedDialogComponent } from './video/video-embed-dialog/video-embed-dialog.component';
import { VideoEmbedMenuComponent } from './video/video-embed-menu/video-embed-menu.component';
import { VideoEmbedNodeComponent } from './video/video-embed-node/video-embed-node.component';
import { VideoNodeComponent } from './video/video-node/video-node.component';
import { VideoUploadMenuComponent } from './video/video-upload-menu/video-upload-menu.component';
import { VideoUploadingComponent } from './video/video-uploading/video-uploading.component';
import { ImageNodePreviewDialogComponent } from './image/image-node-preview-dialog/image-node-preview-dialog.component';

const NODES: ComponentType<unknown>[] = [
  ImageNodeComponent,
  VideoNodeComponent,
  VideoEmbedNodeComponent,
  MentionNodeComponent,
  ImageUploadingComponent,
  VideoUploadingComponent,
  MentionAutocompleteNodeComponent,
  SnippetAutocompleteNodeComponent,
  TableOfContentsNodeComponent,
];

const MENU_ITEMS: ComponentType<unknown>[] = [
  ImageUploadMenuComponent,
  VideoUploadMenuComponent,
  VideoEmbedMenuComponent,
  HeadingMenuComponent,
  TextStylingMenuComponent,
  TextBadgeMenuComponent,
  AITextPromptMenuComponent,
  AIImagePromptMenuComponent,
];

@NgModule({
  imports: [
    CommonModule,
    NgCoreModule,
    NgSharedModule,
    NgMaterialModule,
    ReactiveFormsModule,
    NgProsemirrorModule,
    NgxTiptapModule,
    RouterModule,
    NgxMoveableModule,
    NgOptimizedImage,
  ],
  declarations: [
    ...NODES,
    ...MENU_ITEMS,
    DragResizeComponent,
    TableEditMenuComponent,
    VideoEmbedDialogComponent,
    EditorAutocompleteTriggerDirective,
    ImageEditMenuComponent,
    LinkHoverMenuComponent,
    AIImagePromptDialogComponent,
    AITextPromptDialogComponent,
    ImageNodePreviewDialogComponent,
  ],
  exports: [...NODES, ...MENU_ITEMS],
  providers: [ImageCommandsService, VideoCommandsService, TableCommandsService],
})
export class NgEditorExtensionsModule {}
