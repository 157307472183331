import { Injectable } from '@angular/core';
import { Money } from '@principle-theorem/accounting';
import {
  Invoice,
  MedicareValidation,
  resolveClaimItems,
  toClaimableItems,
  toSingleClaimItems,
} from '@principle-theorem/principle-core';
import {
  InvoiceStatus,
  MAX_CLAIMABLE_ITEMS,
  type IAppointment,
  type IClaimableItem,
  type IHealthPointTransactionExtendedData,
  type IHealthcareClaim,
  type IInvoice,
  type IPatient,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  prefixCharacters,
  snapshot,
  toMoment,
  type WithRef,
} from '@principle-theorem/shared';
import {
  isHealthPointTransactionCompleteCallbackData,
  isHealthPointTransactionSuccessBaseCallbackData,
  toHealthPointDate,
  type IClaimItem,
  type IHealthPointCancelClaimRequestParams,
  type IHealthPointClaimRequestParams,
} from '@principle-theorem/tyro';
import { pick, sum, truncate } from 'lodash';
import * as moment from 'moment-timezone';
import { IHealthFundMemberNumberDialogData } from '../../transaction-components/health-fund-member-number-dialog/health-fund-member-number-dialog.component';
import { HealthFundMemberNumberDialogService } from '../../transaction-components/health-fund-member-number-dialog/health-fund-member-number-dialog.service';
import { TransactionProviderError } from '../../transaction-provider';
import { type IntegrationKeyFormData } from '../select-tyro-terminal/select-tyro-terminal.component';

const MAX_DESCRIPTION_LENGTH = 32;
const toTyroADACode = prefixCharacters('0', 5);
const toTyroPatientId = prefixCharacters('0', 2);

@Injectable()
export class HealthPointBuilder {
  constructor(
    private _memberNumberDialog: HealthFundMemberNumberDialogService
  ) {}

  async openHealthPointForm(
    invoice: IInvoice | WithRef<IInvoice>,
    patient?: WithRef<IPatient>
  ): Promise<IHealthFundMemberNumberDialogData | undefined> {
    return this._memberNumberDialog.open(invoice, patient);
  }

  async buildHealthPointClaim(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim,
    result: IHealthFundMemberNumberDialogData,
    terminalData: IntegrationKeyFormData
  ): Promise<IHealthPointClaimRequestParams> {
    if (!this._isInvoiceWithinClaimPeriod(invoice)) {
      throw new TransactionProviderError(
        `Invoice is not within claimable period`
      );
    }

    if (!MedicareValidation.isValidMemberNumber(result.memberNumber)) {
      throw new TransactionProviderError(
        `Invalid Member Number: ${result.memberNumber}`
      );
    }

    const appointment = await snapshot(
      Invoice.getAssociatedAppointment$(invoice)
    );

    const claimItems = this._getServices(
      invoice,
      result.memberNumber,
      claim,
      appointment
    );
    const totalClaimAmount = sum(claimItems.map((item) => item.claimAmount));

    if (claimItems.length > MAX_CLAIMABLE_ITEMS) {
      throw new TransactionProviderError(
        `Too many claimable items. ${claimItems.length} given, maximum allowed ${MAX_CLAIMABLE_ITEMS}.`
      );
    }

    if (!claim.providerData) {
      throw new TransactionProviderError(`Missing Provider Data`);
    }

    return {
      ...terminalData,
      providerId: claim.providerData.providerNumber,
      serviceType: claim.providerData.providerModality,
      claimItemsCount: claimItems.length,
      totalClaimAmount,
      claimItems,
    };
  }

  buildHealthPointClaimEstimate(
    invoice: IInvoice,
    claim: IHealthcareClaim,
    result: IHealthFundMemberNumberDialogData,
    terminalData: IntegrationKeyFormData
  ): IHealthPointClaimRequestParams {
    if (!MedicareValidation.isValidMemberNumber(result.memberNumber)) {
      throw new TransactionProviderError(
        `Invalid Member Number: ${result.memberNumber}`
      );
    }

    if (!claim.providerData) {
      throw new TransactionProviderError(`Missing Provider Data`);
    }

    const claimItems = this._getServices(invoice, result.memberNumber, claim);
    const totalClaimAmount = sum(claimItems.map((item) => item.claimAmount));

    if (claimItems.length > MAX_CLAIMABLE_ITEMS) {
      throw new TransactionProviderError(
        `Too many claimable items. ${claimItems.length} given, maximum allowed ${MAX_CLAIMABLE_ITEMS}.`
      );
    }

    return {
      ...terminalData,
      providerId: claim.providerData.providerNumber,
      serviceType: claim.providerData.providerModality,
      claimItemsCount: claimItems.length,
      totalClaimAmount,
      claimItems,
    };
  }

  buildHealthPointClaimCancel(
    transaction: ITransaction<IHealthPointTransactionExtendedData>,
    terminalData: IntegrationKeyFormData
  ): IHealthPointCancelClaimRequestParams | undefined {
    const data = transaction.extendedData;
    if (!data) {
      return;
    }
    if (
      !isHealthPointTransactionSuccessBaseCallbackData(data.response) &&
      !isHealthPointTransactionCompleteCallbackData(data.response)
    ) {
      return;
    }
    const requestProperties = pick(data.request, [
      'providerId',
      'serviceType',
      'claimItemsCount',
      'totalClaimAmount',
      'claimItems',
    ]);
    return {
      ...terminalData,
      ...requestProperties,
      refTag: data.response.healthpointRefTag,
    };
  }

  transformItemDescription(description: string): string {
    const invalidCharactersRegex = /(&|<|>|"|')/g;
    const escaped = description.replace(invalidCharactersRegex, '');
    return truncate(escaped, { length: MAX_DESCRIPTION_LENGTH });
  }

  private _isInvoiceWithinClaimPeriod(invoice: WithRef<IInvoice>): boolean {
    const issuedAtRaw = Invoice.lastEnteredStatus(
      invoice,
      InvoiceStatus.Issued
    );
    const issuedAt = issuedAtRaw ? toMoment(issuedAtRaw) : moment();
    const now = moment();
    if (issuedAt.isAfter(now)) {
      return false;
    }
    const mustClaimBy = issuedAt.add(12, 'months');
    if (now.isAfter(mustClaimBy)) {
      return false;
    }
    return true;
  }

  private _getServices(
    invoice: IInvoice,
    patientId: string,
    claim: IHealthcareClaim,
    appointment?: WithRef<IAppointment>
  ): IClaimItem[] {
    return toSingleClaimItems(
      toClaimableItems(resolveClaimItems(invoice, claim))
    ).map((item) => this._toClaimItem(invoice, item, patientId, appointment));
  }

  private _toClaimItem(
    invoice: IInvoice,
    item: IClaimableItem,
    patientId: string,
    appointment?: WithRef<IAppointment>
  ): IClaimItem {
    const serviceCode = toTyroADACode(item.serviceCode.code);
    if (!MedicareValidation.isValidADACodeNumber(serviceCode)) {
      throw new TransactionProviderError(`Invalid ADA Code: ${serviceCode}`);
    }
    return {
      claimAmount: Money.toCents(item.serviceCode.amount),
      serviceCode,
      serviceReference: item.serviceCode.toothId || '',
      serviceDate: toHealthPointDate(
        appointment?.event?.from ?? invoice.createdAt
      ),
      patientId: toTyroPatientId(patientId),
      description: this.transformItemDescription(
        `${item.serviceCode.description} (${item.treatment.description})`
      ),
    };
  }
}
