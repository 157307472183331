import { APPOINTMENTS_FEATURE } from './appointments';
import { BRANDS_FEATURE } from './brands';
import { CALENDAR_FEATURE } from './calendar';
import { CONTACTS_FEATURE } from './contacts';
import {
  HICAPS_CONNECT_FEATURE,
  HICAPS_CONNECT_TESTING_FEATURE,
} from './hicaps';
import { LABS_FEATURE } from './labs';
import { MANAGEMENT_FEATURE } from './management';
import { ORGANISATION_FEATURE } from './organisation';
import { PATIENTS_FEATURE } from './patients';
import { PRACTICES_FEATURE } from './practices';
import { REPORTING_FEATURE } from './reporting';
import { SEEDING_FEATURE } from './seeding';
import { STAFF_FEATURE } from './staff';
import { TYRO_TESTING_FEATURE } from './tyro';

export const ALL_FEATURES = [
  APPOINTMENTS_FEATURE,
  BRANDS_FEATURE,
  CALENDAR_FEATURE,
  CONTACTS_FEATURE,
  LABS_FEATURE,
  MANAGEMENT_FEATURE,
  ORGANISATION_FEATURE,
  PATIENTS_FEATURE,
  PRACTICES_FEATURE,
  REPORTING_FEATURE,
  SEEDING_FEATURE,
  STAFF_FEATURE,
  TYRO_TESTING_FEATURE,
  HICAPS_CONNECT_FEATURE,
  HICAPS_CONNECT_TESTING_FEATURE,
];

export const ALL_PERMISSIONS = ALL_FEATURES.map((feature) =>
  feature.permissions.map((permission) => permission.value)
).flat();
