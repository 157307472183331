import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppointmentManager } from '@principle-theorem/ng-appointment/store';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  Appointment,
  OrganisationCache,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  AppointmentSummary,
  type IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  IReffable,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import {
  InboundChecklistComponent,
  type IInboundChecklistData,
} from '../../../scheduling/inbound-checklist/inbound-checklist.component';
import { AppointmentCardAction } from './appointment-card-action-interface';

@Injectable()
export class CheckinAppointmentActionService extends AppointmentCardAction {
  name = 'Check In Patient';

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _organisation: OrganisationService
  ) {
    super();
  }

  isVisible(appointment: WithRef<IAppointment> | AppointmentSummary): boolean {
    return Appointment.canCheckIn(appointment);
  }

  async run(
    appointment: WithRef<IAppointment>,
    patient: IReffable<IPatient>
  ): Promise<void> {
    const checkInRequirementCount = await snapshot(
      AppointmentManager.getCheckInRequirementCount$(patient, appointment)
    );

    if (checkInRequirementCount === 0) {
      const staffer = await snapshot(
        this._organisation.staffer$.pipe(filterUndefined())
      );
      await AppointmentManager.markCheckedIn(staffer, appointment);
      this._snackBar.open('Patient checked in');
      return;
    }

    const config = DialogPresets.medium<IInboundChecklistData>({
      data: {
        appointment,
        patient: await OrganisationCache.patients.getDoc(patient.ref),
      },
    });
    this._dialog.open<
      InboundChecklistComponent,
      IInboundChecklistData,
      AppointmentStatus
    >(InboundChecklistComponent, config);
  }

  updateEventableSummary(
    eventable: AppointmentSummary,
    updateFn: (
      oldEvent: AppointmentSummary,
      newEvent: AppointmentSummary
    ) => void
  ): void {
    const metadata = {
      ...eventable.metadata,
      status: AppointmentStatus.CheckedIn,
    };
    updateFn(eventable, { ...eventable, metadata });
  }

  override getBadge$(
    appointment: WithRef<IAppointment>,
    patient: WithRef<IPatient>
  ): Observable<number> {
    return AppointmentManager.getCheckInRequirementCount$(patient, appointment);
  }
}
