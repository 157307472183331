<mat-toolbar color="accent">Pricing Unit Type</mat-toolbar>
<div class="layout-margin">
  <p>
    <strong>Per Tooth</strong><br />
    Used when the the Unit count is per Tooth, not number of times the ADA code
    was used.<br />
    <small class="mat-caption"
      >Eg. A 721 is charted once and the price is dependent on the number of
      teeth the treatment involves. This is different to a normal case where you
      might have several of the same ADA code in one appointment.</small
    >
  </p>
  <p>
    <strong>None</strong><br />
    Select None if the pricing should be calculated based of the number of times
    the ADA code was performed.
  </p>
</div>
