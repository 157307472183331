<mat-toolbar color="accent">Stripe Transaction</mat-toolbar>

<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field>
      <mat-icon matIconPrefix>attach_money</mat-icon>
      <mat-label>Amount</mat-label>
      <input
        matInput
        type="number"
        formControlName="amount"
        [max]="limit"
        [required]="true"
      />
    </mat-form-field>

    <pt-card-input formControlName="token" />

    <ng-container *ngIf="practiceOptions.options$ | async as practices">
      <mat-form-field *ngIf="practices.length > 1">
        <mat-label>Practice</mat-label>
        <mat-select
          [required]="true"
          formControlName="practiceRef"
          [compareWith]="practiceOptions.compareFn"
        >
          <mat-option
            *ngFor="
              let practice of practices;
              trackBy: practiceOptions.trackByFn
            "
            [value]="practice.ref"
          >
            {{ practice.name }}
          </mat-option>
        </mat-select>
        <mat-hint>{{ practiceOptions.hint }}</mat-hint>
      </mat-form-field>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary">Submit</button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
