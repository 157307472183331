<h2 pt-dialog-title class="!mx-6 !mb-0 !mt-4">
  Select Surfaces for {{ data.label }}
</h2>

<pt-dialog-content>
  <div class="box-border flex h-full min-h-full w-full flex-col">
    <pr-clinical-chart
      [stacked]="isStacked$ | async"
      [chartedItems]="filteredChartedItems$ | async"
    />
  </div>
</pt-dialog-content>

<pt-dialog-actions>
  <pt-buttons-container padding="none">
    <button mat-stroked-button (click)="close()">Cancel</button>
    <button mat-flat-button color="primary" (click)="save()">Chart</button>
  </pt-buttons-container>
</pt-dialog-actions>
