import {
  Component,
  Input,
  HostBinding,
  ChangeDetectionStrategy,
} from '@angular/core';
import { DomSanitizer, type SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'pt-profile-pic',
  standalone: true,
  template: '',
  styleUrls: ['./profile-pic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePicComponent {
  @Input() src = '';
  @Input() diameter = 40;

  constructor(private _sanitizer: DomSanitizer) {}

  @HostBinding('style.height')
  @HostBinding('style.width')
  @HostBinding('style.line-height')
  get size(): string {
    return `${this.diameter}px`;
  }

  @HostBinding('style.background-image')
  get backgroundImage(): SafeStyle | string {
    const url = `url("${this.src}")`;
    return this._sanitizer.bypassSecurityTrustStyle(url);
  }
}
