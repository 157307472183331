<div *ngIf="dataSource.filteredData.length" class="flex flex-col">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    class="flex-1"
    matSortActive="plan.createdAt"
    matSortDirection="desc"
  >
    <ng-container matColumnDef="plan.status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let data">
        <pr-treatment-plan-status
          class="w-20"
          dense
          [status]="data.plan.status"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="patient.name">
      <th mat-header-cell *matHeaderCellDef>Patient</th>
      <td mat-cell *matCellDef="let data">
        <a [prBrandRouterLink]="['patients', data.patient.ref.id]">
          {{ data.patient.name }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="plan.name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let data">
        <a
          [prBrandRouterLink]="[
            'patients',
            data.patient.ref.id,
            'treatment-plans',
            data.plan.ref.id
          ]"
        >
          {{ data.plan.name }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="plan.createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
      <td mat-cell *matCellDef="let data">
        {{ data.plan.createdAt | moment | amDateFormat: dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef class="right-align">Price</th>
      <td mat-cell *matCellDef="let data" class="right-align">
        {{ data | map: price$ | ngrxPush | currency }}
      </td>
    </ng-container>

    <ng-container matColumnDef="progress">
      <th mat-header-cell *matHeaderCellDef class="right-align">Progress</th>
      <td mat-cell *matCellDef="let data" class="right-align">
        {{ data | map: progress$ | ngrxPush | percent }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="right-align">Actions</th>
      <td mat-cell *matCellDef="let data">
        <div class="flex items-center justify-end gap-2">
          <button
            *ngIf="data | map: canBeAccepted"
            mat-stroked-button
            color="primary"
            aria-label="Accept"
            matTooltip="Accept Treatment Plan"
            (click)="acceptPlan(data)"
          >
            Accept
          </button>
          <button
            *ngIf="data | map: canBeDeclined"
            mat-stroked-button
            color="warn"
            aria-label="Decline"
            matTooltip="Decline Treatment Plan"
            (click)="declinePlan(data)"
          >
            Decline
          </button>
          <button
            *ngIf="data | map: canBeCompleted$ | ngrxPush"
            mat-stroked-button
            color="accent"
            aria-label="Complete"
            matTooltip="Complete Treatment Plan"
            (click)="completePlan(data)"
          >
            Complete
          </button>
          <ng-container *ngIf="data | map: isComplete">
            <button mat-icon-button [matMenuTriggerFor]="moreMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #moreMenu="matMenu">
              <button mat-menu-item (click)="reactivatePlan(data)">
                <mat-icon>restart_alt</mat-icon>
                <span>Reactivate Plan</span>
              </button>
            </mat-menu>
          </ng-container>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
