import { last } from 'lodash';

export function getDocUid(path: string): string | undefined {
  if (!isDocPath(path)) {
    throw new Error(`${path} is not a document path`);
  }
  return last(path.split('/'));
}

export function isDocPath(path: string): boolean {
  const segments = path.split('/');
  return segments.length % 2 === 0;
}
