import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { type ICreateAction } from '@principle-theorem/ng-interactions';
import {
  CurrentAppointmentScope,
  CurrentLabScope,
  CurrentPatientScope,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  type IAppointment,
  type ILab,
  type IPatient,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import {
  type ILabJobCreateData,
  LabJobCreateComponent,
} from './components/lab-job-create/lab-job-create.component';

@Injectable()
export class CreateLabJobActionService implements ICreateAction {
  icon = 'add';
  name = 'New Lab Job';

  constructor(
    private _dialog: MatDialog,
    private _organisation: OrganisationService,
    private _patientScope: CurrentPatientScope,
    private _appointmentScope: CurrentAppointmentScope,
    private _labScope: CurrentLabScope
  ) {}

  async do(): Promise<void> {
    const practice: WithRef<IPractice> = await snapshot(
      this._organisation.practice$.pipe(filterUndefined())
    );
    const lab: WithRef<ILab> | undefined = await snapshot(this._labScope.doc$);
    const patient: WithRef<IPatient> | undefined = await snapshot(
      this._patientScope.doc$
    );
    const appointment: WithRef<IAppointment> | undefined = await snapshot(
      this._appointmentScope.doc$
    );

    const data: ILabJobCreateData = {
      practice,
      lab,
      patient,
      appointment,
    };

    const config: MatDialogConfig = DialogPresets.medium({
      data,
      autoFocus: false,
    });

    this._dialog.open<LabJobCreateComponent, ILabJobCreateData>(
      LabJobCreateComponent,
      config
    );
  }
}
