import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  IAppointment,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  type Observable,
} from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IAppointmentCardAction,
  type AppointmentCardAction,
} from './actions/appointment-card-action-interface';

@Component({
  selector: 'pr-appointment-card-actions',
  templateUrl: './appointment-card-actions.component.html',
  styleUrls: ['./appointment-card-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentCardActionsComponent {
  private _actions$ = new BehaviorSubject<AppointmentCardAction[]>([]);
  trackByAction = TrackByFunctions.field<AppointmentCardAction>('name');
  appointment$ = new ReplaySubject<WithRef<IAppointment>>(1);
  patient$: ReplaySubject<WithRef<IPatient>> = new ReplaySubject(1);
  visibleActions$: Observable<AppointmentCardAction[]>;
  @Output() updateEventable = new EventEmitter<IAppointmentCardAction>();

  @Input()
  set appointment(appointment: WithRef<IAppointment>) {
    if (appointment) {
      this.appointment$.next(appointment);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  @Input()
  set actions(actions: AppointmentCardAction[]) {
    if (actions) {
      this._actions$.next(actions);
    }
  }

  constructor() {
    this.visibleActions$ = combineLatest([
      this._actions$,
      this.appointment$,
    ]).pipe(
      map(([actions, appointment]) => this._getVisible(actions, appointment))
    );
  }

  private _getVisible(
    actions: AppointmentCardAction[],
    appointment: WithRef<IAppointment>
  ): AppointmentCardAction[] {
    return actions.filter((action: AppointmentCardAction) =>
      action.isVisible(appointment)
    );
  }
}
