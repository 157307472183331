import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { SchedulingEventReason } from '@principle-theorem/principle-core';
import {
  ISchedulingEventConditions,
  ISchedulingEventReason,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  WithRef,
  isSameRef,
} from '@principle-theorem/shared';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pr-scheduling-event-reason-tooltip',
  templateUrl: './scheduling-event-reason-tooltip.component.html',
  styleUrls: ['./scheduling-event-reason-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NgMaterialModule, NgSharedModule],
})
export class SchedulingEventReasonTooltipComponent {
  reasons$ = new ReplaySubject<WithRef<ISchedulingEventReason>[]>(1);
  selectedReason$ = new ReplaySubject<
    DocumentReference<ISchedulingEventReason> | undefined
  >(1);
  defaultReason$: Observable<WithRef<ISchedulingEventReason> | undefined>;
  schedulingConditions$ = new ReplaySubject<
    ISchedulingEventConditions | undefined
  >(1);

  @Input()
  set reasons(reasons: WithRef<ISchedulingEventReason>[]) {
    if (reasons) {
      this.reasons$.next(reasons);
    }
  }

  @Input()
  set selectedReason(
    reason: DocumentReference<ISchedulingEventReason> | undefined
  ) {
    if (reason) {
      this.selectedReason$.next(reason);
    }
  }

  @Input()
  set schedulingConditions(
    schedulingConditions: ISchedulingEventConditions | undefined
  ) {
    this.schedulingConditions$.next(schedulingConditions);
  }

  constructor() {
    this.defaultReason$ = combineLatest([
      this.reasons$,
      this.schedulingConditions$,
    ]).pipe(
      map(([reasons, schedulingConditions]) =>
        schedulingConditions
          ? SchedulingEventReason.determineDefaultReason(
              reasons,
              schedulingConditions
            )
          : undefined
      )
    );
  }

  conditionsDisplay(reason: ISchedulingEventReason): string {
    const text = SchedulingEventReason.conditionsDisplay(reason);
    return text.length > 0 ? `${text}.` : text;
  }

  isSelectedReason$(
    reason: WithRef<ISchedulingEventReason>
  ): Observable<boolean> {
    return this.selectedReason$.pipe(
      map((selectedReason) => isSameRef(selectedReason, reason))
    );
  }

  isDefaultReason$(
    reason: WithRef<ISchedulingEventReason>
  ): Observable<boolean> {
    return this.defaultReason$.pipe(
      map((defaultReason) => isSameRef(defaultReason, reason))
    );
  }
}
