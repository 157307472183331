import {
  ADA_CODE_TREATMENT_CONFIG_ID,
  ClinicalChart,
  MockAllTeeth,
  TreatmentConfiguration,
} from '@principle-theorem/principle-core';
import {
  IDestinationEntityJobRunOptions,
  ITranslationMap,
  type FailedDestinationEntityRecord,
  type IClinicalChart,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type IStaffer,
  type ITreatmentConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  Timezone,
  getDoc$,
  getError,
  toMomentTz,
  toTimestamp,
  type IIdentifiable,
  type WithRef,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import { combineLatest, type Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  BasePatientClinicalChartDestinationEntity,
  IBaseClinicalChartJobData,
  IClinicalChartMigrationData,
  IGeneratedCharts,
  PATIENT_CLINICAL_CHART_RESOURCE_TYPE,
} from '../../../destination/entities/patient-clinical-charts';
import { PracticeMigration } from '../../../practice-migrations';
import { type TranslationMapHandler } from '../../../translation-map';
import { PatientAppointmentSourceEntity } from '../../source/entities/patient-appointments';
import {
  IOasisPatientPeriodontalChart,
  IOasisPatientPeriodontalChartFilters,
  IOasisPatientPeriodontalChartTranslations,
  PATIENT_PERIODONTAL_CHART_RESOURCE_TYPE,
  PatientPeriodontalChartSourceEntity,
} from '../../source/entities/patient-periodontal-charts';
import {
  IOasisPatient,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { PROVIDER_RESOURCE_TYPE } from '../../source/entities/providers';
import { OasisItemCodeToTreatmentMappingHandler } from '../mappings/item-code-to-treatment';
import { OasisItemCodeMappingHandler } from '../mappings/item-codes';
import { OasisStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';

export const PATIENT_CLINICAL_CHART_DESTINATION_ENTITY = DestinationEntity.init(
  {
    metadata: {
      key: PATIENT_CLINICAL_CHART_RESOURCE_TYPE,
      label: 'Patient Clinical Charts',
      description: `The conversion will be done as follows:
        Each Periodontal Chart will be added as a separate clinical chart.
      `,
    },
  }
);

export interface IClinicalChartJobData
  extends IBaseClinicalChartJobData<IOasisPatient> {
  defaultTreatmentConfiguration: WithRef<ITreatmentConfiguration>;
  treatmentConfigurations: WithRef<ITreatmentConfiguration>[];
  treatmentConfigurationMappings: WithRef<
    ITranslationMap<ITreatmentConfiguration>
  >[];
}

export class PatientClinicalChartDestinationEntity extends BasePatientClinicalChartDestinationEntity<
  IOasisPatient,
  IClinicalChartJobData
> {
  destinationEntity = PATIENT_CLINICAL_CHART_DESTINATION_ENTITY;
  periodontalResourceType = PATIENT_PERIODONTAL_CHART_RESOURCE_TYPE;
  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
    periodontalChart: new PatientPeriodontalChartSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new OasisStafferMappingHandler(),
    itemCodes: new OasisItemCodeMappingHandler(),
    itemCodeToTreatment: new OasisItemCodeToTreatmentMappingHandler(),
  };

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IClinicalChartJobData[]> {
    const brand$ = PracticeMigration.brand$(migration);
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(PROVIDER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);
    const treatmentConfigurationMappings$ =
      this.customMappings.itemCodeToTreatment.getRecords$(translationMap);
    const defaultTreatmentConfiguration$ = brand$.pipe(
      switchMap((brand) =>
        getDoc$(TreatmentConfiguration.col(brand), ADA_CODE_TREATMENT_CONFIG_ID)
      )
    );
    const treatmentConfigurations$ = brand$.pipe(
      switchMap((brand) => TreatmentConfiguration.all$(brand))
    );

    return this.buildSourceRecordQuery$(
      migration,
      this.sourceEntities.patients,
      runOptions
    ).pipe(
      withLatestFrom(
        staff$,
        defaultTreatmentConfiguration$,
        treatmentConfigurations$,
        sourceItemCodes$,
        treatmentConfigurationMappings$
      ),
      map(
        ([
          sourcePatients,
          staff,
          defaultTreatmentConfiguration,
          treatmentConfigurations,
          sourceItemCodes,
          treatmentConfigurationMappings,
        ]) =>
          sourcePatients.map((sourcePatient) => ({
            staff,
            sourcePatient,
            defaultTreatmentConfiguration,
            treatmentConfigurations,
            sourceItemCodes,
            treatmentConfigurationMappings,
          }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IClinicalChartJobData
  ): Promise<
    | IClinicalChartMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.id;
    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        `Couldn't resolve patient`
      );
    }

    try {
      const charts = await this._buildClinicalChartData(
        migration,
        sourcePatientId
      );

      return {
        sourcePatientId: sourcePatientId.toString(),
        patientRef,
        charts,
      };
    } catch (error) {
      return this._buildErrorResponse(
        data.sourcePatient.record,
        getError(error)
      );
    }
  }

  private async _buildClinicalChartData(
    migration: WithRef<IPracticeMigration>,
    patientUid: number
  ): Promise<IGeneratedCharts> {
    const sourcePerioCharts =
      await this.sourceEntities.periodontalChart.filterRecords(
        migration,
        'patientId',
        patientUid
      );

    const perioClinicalCharts = buildPerioCharts(
      sourcePerioCharts,
      migration.configuration.timezone
    );

    return {
      clinicalCharts: [],
      perioCharts: compact(perioClinicalCharts),
    };
  }
}

function buildPerioCharts(
  sourcePerioCharts: IGetRecordResponse<
    IOasisPatientPeriodontalChart,
    IOasisPatientPeriodontalChartTranslations,
    IOasisPatientPeriodontalChartFilters
  >[],
  timezone: Timezone
): (IClinicalChart & IIdentifiable)[] {
  return sourcePerioCharts.map((sourcePerioChart) => {
    const uid = new PatientPeriodontalChartSourceEntity().getSourceRecordId(
      sourcePerioChart.data.data
    );
    const createdAt = toTimestamp(
      toMomentTz(sourcePerioChart.data.data.date, timezone)
    );
    const perioRecords = sourcePerioChart.data.data.teeth;

    return {
      ...ClinicalChart.init({
        perioRecords,
        teeth: MockAllTeeth(),
        createdAt,
        createdBy: undefined,
        immutable: true,
      }),
      uid,
    };
  });
}
