<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div class="wrapper flex flex-col gap-4">
  <ng-container *ngIf="multiTreatmentConfig$ | async as multiTreatmentConfig">
    <h1 class="mat-headline-4">{{ multiTreatmentConfig.name }}</h1>

    <pr-edit-multi-treatment-configuration
      [multiTreatmentConfig]="multiTreatmentConfig"
    />
  </ng-container>
</div>
