<button
  mat-icon-button
  class="square"
  [matMenuTriggerFor]="menu"
  matTooltip="Filter by Tags"
>
  <mat-icon>filter_list</mat-icon>
  <div
    [ngClass]="{
      'has-notification': showNotification$ | async
    }"
  ></div>
</button>

<mat-menu #menu="matMenu">
  <mat-selection-list
    #selectionList="matSelectionList"
    [compareWith]="isSelectedNamedDocument"
    (click)="$event.stopPropagation()"
  >
    <mat-list-option
      #listOption="matListOption"
      *ngFor="let tag of filters$ | async; trackBy: trackByTag"
      checkboxPosition="before"
      [value]="tag"
      [selected]="tag | map : isSelected$ : this | async"
      (click)="toggleSelected(tag)"
    >
      <mat-chip-listbox class="mat-typography">
        <mat-chip
          [style.--mdc-chip-label-text-color]="tag | tagTextColour"
          [style.background-color]="tag.hexColour"
        >
          {{ tag.name }}
        </mat-chip>
      </mat-chip-listbox>
    </mat-list-option>
  </mat-selection-list>
</mat-menu>
