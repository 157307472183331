import {
  ChartableSurface,
  ChartedItemType,
  IChartedTreatmentGroup,
} from '@principle-theorem/principle-core/interfaces';
import { AtLeast } from '@principle-theorem/shared';
import { v4 as uuid } from 'uuid';

export class ChartedTreatmentGroup {
  static init(
    overrides: AtLeast<
      IChartedTreatmentGroup,
      'treatments' | 'config' | 'feeSchedule'
    >
  ): IChartedTreatmentGroup {
    return {
      uuid: uuid(),
      type: ChartedItemType.ChartedTreatmentGroup,
      chartedSurfaces: [],
      notes: [],
      scopeRef: {
        scope: ChartableSurface.WholeMouth,
      },
      ...overrides,
    };
  }
}
