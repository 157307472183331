import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
} from '@angular/core';
import {
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { Patient, TreatmentPlan } from '@principle-theorem/principle-core';
import {
  type IAppointment,
  type IAssociatedTreatment,
  type IPatient,
  isEventable,
  type ITreatmentPlan,
  type ITreatmentStep,
  PatientRelationshipType,
} from '@principle-theorem/principle-core/interfaces';
import { type DocumentReference } from '@principle-theorem/shared';
import { isSameRef, snapshot, type WithRef } from '@principle-theorem/shared';
import { type Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

export interface IOptionsReloadedEvent {
  source: NextTreatmentStepSelectorComponent;
  options: IAssociatedTreatment[];
}

@Component({
    selector: 'pr-next-treatment-step-selector',
    templateUrl: './next-treatment-step-selector.component.html',
    styleUrls: ['./next-treatment-step-selector.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NextTreatmentStepSelectorComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  trackByOption = TrackByFunctions.ref<IAssociatedTreatment>();
  options$: Observable<IAssociatedTreatment[]> = of([]);
  selectedCtrl: TypedFormControl<IAssociatedTreatment> = new TypedFormControl();
  @Output()
  selectedChanged = new EventEmitter<IAssociatedTreatment>();
  @Output()
  optionsReloaded = new EventEmitter<IOptionsReloadedEvent>();

  constructor() {
    this.selectedCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((selected: IAssociatedTreatment) => {
        this.selectedChanged.emit(selected);
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    this.options$ = Patient.withPatientRelationships$(
      patient,
      [PatientRelationshipType.DuplicatePatient],
      TreatmentPlan.all$
    ).pipe(
      switchMap((plans) => this._getSelectionsFromPlans(plans)),
      tap((options: IAssociatedTreatment[]) => {
        this.selectedCtrl.setValue(options[0]);
        this.optionsReloaded.emit({
          source: this,
          options,
        });
      })
    );
  }

  async selectByPlanRef(treatmentPlan: DocumentReference): Promise<void> {
    const options: IAssociatedTreatment[] = await snapshot(this.options$);

    const selection: IAssociatedTreatment | undefined = options.find(
      (option: IAssociatedTreatment) => option.ref.path === treatmentPlan.path
    );

    if (!selection) {
      return;
    }
    this.selectedCtrl.setValue(selection);
  }

  isSameTreatmentPlan(
    option1: IAssociatedTreatment,
    option2: IAssociatedTreatment
  ): boolean {
    return isSameRef(option1, option2);
  }

  private async _getSelectionsFromPlans(
    plans: WithRef<ITreatmentPlan>[]
  ): Promise<IAssociatedTreatment[]> {
    const promises: Promise<IAssociatedTreatment[]>[] = plans.map(
      async (plan) => this._getStepsToSchedule(plan)
    );
    const associatedTreatments: IAssociatedTreatment[][] =
      await Promise.all(promises);

    return associatedTreatments.reduce(
      (
        combined: IAssociatedTreatment[],
        treatments: IAssociatedTreatment[]
      ) => {
        return [...combined, ...treatments];
      },
      []
    );
  }

  private async _getStepsToSchedule(
    treatmentPlan: WithRef<ITreatmentPlan>
  ): Promise<IAssociatedTreatment[]> {
    const steps = await TreatmentPlan.findStepsBy(
      treatmentPlan,
      (_step: ITreatmentStep, appointment?: IAppointment) => {
        return appointment ? !isEventable(appointment) : false;
      }
    );

    return steps.map((step) =>
      TreatmentPlan.treatmentStepToAssociatedTreatment(treatmentPlan, step)
    );
  }
}
