import { AnyExtension } from '@tiptap/core';
import { HorizontalRule } from '@tiptap/extension-horizontal-rule';
import { BlockNodes } from '../../available-extensions';

export function createHorizontalRuleExtension(): AnyExtension {
  const horizontalRule = HorizontalRule.configure();

  return horizontalRule.extend({
    name: BlockNodes.HorizontalRule,
  });
}
