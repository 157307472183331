import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  MedicalFormAlerts,
  PatientCustomFormsService,
} from '@principle-theorem/ng-custom-forms';
import { Brand, Patient, PatientForm } from '@principle-theorem/principle-core';
import {
  IPatientForm,
  PatientFormType,
  type IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  Firestore,
  addDocAsWithRef,
  asReffable,
  snapshot,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pr-patient-medical-alerts',
    templateUrl: './patient-medical-alerts.component.html',
    styleUrls: ['./patient-medical-alerts.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PatientMedicalAlertsComponent {
  private _router = inject(Router);
  private _patientCustomForms = inject(PatientCustomFormsService);
  private _submittedMedicalHistoryForms$: Observable<
    WithRef<IPatientForm> | undefined
  >;
  readonly dateFormatShort = DAY_MONTH_YEAR_FORMAT;
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  expanded$ = new BehaviorSubject<boolean>(true);
  medicalFormAlerts: MedicalFormAlerts;

  @Input() expandable: boolean = true;

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor() {
    this._submittedMedicalHistoryForms$ = this.patient$.pipe(
      switchMap((patient) =>
        Patient.medicalHistoryForms$(patient).pipe(
          map((forms) => PatientForm.latestSubmittedForm(forms))
        )
      )
    );

    const brand$ = this.patient$.pipe(
      map((patient) => asReffable(Patient.brandRef(patient)))
    );
    this.medicalFormAlerts = new MedicalFormAlerts(
      brand$,
      this._submittedMedicalHistoryForms$.pipe(map((form) => form?.form))
    );
  }

  async viewMedicalHistory(): Promise<void> {
    const form = await snapshot(this._submittedMedicalHistoryForms$);
    if (!form) {
      return;
    }
    await this._patientCustomForms.openFormDialog(form, true);
  }

  async issueAndUpdate(): Promise<void> {
    const patient = await snapshot(this.patient$);
    if (!patient) {
      return;
    }

    const forms = await snapshot(Patient.medicalHistoryForms$(patient));
    const lastIssued = PatientForm.latestIssuedForm(forms);
    if (lastIssued) {
      return this._navigateToForm(lastIssued, ['update']);
    }

    const formConfig = await Firestore.getDoc(
      Brand.medicalHistoryFormRef({ ref: Patient.brandRef(patient) })
    );
    const patientForm = PatientForm.init({
      formType: PatientFormType.CustomForm,
      template: toNamedDocument(formConfig),
    });
    const result = await addDocAsWithRef(Patient.formCol(patient), patientForm);
    return this._navigateToForm(result, ['update']);
  }

  private async _navigateToForm(
    form: WithRef<IPatientForm>,
    appendSegments: string[] = []
  ): Promise<void> {
    const formUrl = await PatientForm.getPatientFormUrl(form);
    await this._router.navigate([...formUrl, ...appendSegments]);
  }
}
