import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import { Brand, toINamedDocuments } from '@principle-theorem/principle-core';
import {
  type IBrand,
  type IGeneratedTaskConfiguration,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  type INamedDocument,
  isSameRef,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AutomationConfigurationService } from '../../services/automation-configuration.service';

interface IPracticeConfigurations {
  practice: INamedDocument<IPractice> | undefined;
  configurations: WithRef<IGeneratedTaskConfiguration>[];
}

@Component({
  selector: 'pr-generated-task-configuration',
  templateUrl: './generated-task-configuration.component.html',
  styleUrls: ['./generated-task-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneratedTaskConfigurationComponent {
  brand$ = new ReplaySubject<WithRef<IBrand>>(1);
  configs$ = new ReplaySubject<WithRef<IGeneratedTaskConfiguration>[]>(1);
  trackByTask = TrackByFunctions.ref<WithRef<IGeneratedTaskConfiguration>>();
  practices$: Observable<INamedDocument<IPractice>[]>;
  practiceConfigMaps$: Observable<IPracticeConfigurations[]>;
  trackByMap = TrackByFunctions.ref<IPracticeConfigurations>('practice.ref');
  noConfigs$: Observable<boolean>;

  @Input()
  set configs(configs: WithRef<IGeneratedTaskConfiguration>[]) {
    this.configs$.next(configs ?? []);
  }

  @Input()
  set brand(brand: WithRef<IBrand>) {
    if (brand) {
      this.brand$.next(brand);
    }
  }

  constructor(
    private _automationConfiguration: AutomationConfigurationService
  ) {
    this.practices$ = this.brand$.pipe(
      switchMap((brand) => Brand.practices$(brand)),
      toINamedDocuments()
    );

    this.practiceConfigMaps$ = combineLatest([
      this.configs$,
      this.practices$,
    ]).pipe(
      map(([configs, practices]) => {
        return [
          {
            practice: undefined,
            configurations: configs.filter((config) => !config.practiceRef),
          },
          ...practices.map((practice) => ({
            practice,
            configurations: configs.filter((config) =>
              isSameRef(config.practiceRef, practice.ref)
            ),
          })),
        ];
      })
    );
    this.noConfigs$ = this.configs$.pipe(map((configs) => !configs.length));
  }

  async edit(task: WithRef<IGeneratedTaskConfiguration>): Promise<void> {
    await this._automationConfiguration.editTask(task);
  }

  async delete(task: WithRef<IGeneratedTaskConfiguration>): Promise<void> {
    await this._automationConfiguration.deleteConfig(task);
  }

  async restore(task: WithRef<IGeneratedTaskConfiguration>): Promise<void> {
    await this._automationConfiguration.restoreConfig(task);
  }

  async setActive(
    task: WithRef<IGeneratedTaskConfiguration>,
    isActive: boolean
  ): Promise<void> {
    await this._automationConfiguration.setActive(task, isActive);
  }

  async openAutomations(
    notification: WithRef<IGeneratedTaskConfiguration>
  ): Promise<void> {
    await this._automationConfiguration.openAutomations(notification);
  }
}
