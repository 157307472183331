import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  type BooleanInput,
  coerceBooleanProperty,
} from '@angular/cdk/coercion';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  type AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  type OnDestroy,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CollapsibleContentDirective } from './collapsible-content.directive';

/**
 * Duration for collapse in seconds
 */
const COLLAPSE_DURATION = 0.15;

@Component({
  selector: 'pt-collapsible',
  templateUrl: './collapsible.component.html',
  styleUrls: ['./collapsible.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  exportAs: 'ptCollapsible',
  animations: [
    trigger('expansion', [
      state(
        'open',
        style({
          height: 'auto',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
        })
      ),
      transition('open => closed', animate(`${COLLAPSE_DURATION}s`)),
      transition('closed => open', animate(`${COLLAPSE_DURATION}s`)),
    ]),
  ],
})
export class CollapsibleComponent implements AfterContentInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();
  portal?: TemplatePortal;
  @Output() opened = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();
  expanded$ = new BehaviorSubject<boolean>(false);

  @ContentChild(CollapsibleContentDirective)
  _lazyContent: CollapsibleContentDirective;

  @Input()
  set expanded(expanded: BooleanInput) {
    this.expanded$.next(coerceBooleanProperty(expanded));
  }

  get expanded(): boolean {
    return this.expanded$.value;
  }

  constructor(private _viewContainerRef: ViewContainerRef) {}

  ngAfterContentInit(): void {
    this.expanded$.pipe(takeUntil(this._onDestroy$)).subscribe((expanded) => {
      if (this._lazyContent && expanded && !this.portal) {
        this.portal = new TemplatePortal(
          this._lazyContent.template,
          this._viewContainerRef
        );
        return;
      }
      if (expanded) {
        return;
      }
      this.portal = undefined;
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    if (this.portal?.isAttached) {
      this.portal.detach();
    }
  }

  toggle(): void {
    const explaned = !this.expanded$.value;
    this.expanded$.next(explaned);
    if (explaned) {
      return this.opened.next();
    }
    this.closed.next();
  }

  open(): void {
    this.expanded$.next(true);
    this.opened.next();
  }

  close(): void {
    this.expanded$.next(true);
    this.closed.next();
  }
}
