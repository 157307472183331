<div class="flex flex-row flex-wrap items-center justify-between gap-1">
  @if (schedulingEventReason$ | async; as schedulingEventReason) {
    <div class="font-bold">{{ schedulingEventReason }}</div>
  }

  @if (schedulingEvent$ | async; as schedulingEvent) {
    <div class="flex flex-row items-center">
      <pr-scheduling-event-snapshot
        [eventType]="snapshotEventType$ | async"
        [snapshot]="snapshotBefore$ | async"
      />
      <mat-icon [ngClass]="arrowColourClass$ | async">arrow_forward</mat-icon>
      <pr-scheduling-event-snapshot
        [eventType]="snapshotEventType$ | async"
        [snapshot]="snapshotAfter$ | async"
      />
    </div>
  }
</div>
