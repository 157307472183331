<mat-form-field subscriptSizing="dynamic" class="flex flex-auto">
  <mat-label>{{ label$ | async }}</mat-label>
  <mat-select
    [formControl]="reasonCtrl"
    [required]="true"
    [compareWith]="compareFn"
    (selectionChange)="selected($event)"
  >
    @for (reason of reasons$ | async; track reason.ref.path) {
      <mat-option [value]="reason.ref">
        {{ reason.name }}
      </mat-option>
    }
  </mat-select>

  <mat-icon
    matSuffix
    class="opacity-50"
    #typeTooltipOrigin="cdkOverlayOrigin"
    cdkOverlayOrigin
  >
    help_outline
  </mat-icon>
  <pt-custom-tooltip [target]="typeTooltipOrigin">
    <pr-scheduling-event-reason-tooltip
      [reasons]="reasons$ | async"
      [selectedReason]="reasonCtrl.value"
      [schedulingConditions]="schedulingConditions$ | async"
    />
  </pt-custom-tooltip>
</mat-form-field>
