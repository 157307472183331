import { FeaturesCollection } from '@principle-theorem/feature-flags';
import {
  HicapsConnectMethod,
  MockPrincipleHicapsConnectResponse,
  PrincipleHicapsConnect,
} from '@principle-theorem/hicaps-connect';
import {
  HICAPS_CONNECT_INTEGRATION,
  HicapsConnectToDeviceCommand,
  IDeviceCommand,
} from '@principle-theorem/principle-bridge-core';
import { HICAPS_CONNECT_TESTING_FEATURE } from '@principle-theorem/principle-core/features';
import {
  BridgeDeviceStatus,
  IBrand,
  IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { Firestore, WithRef } from '@principle-theorem/shared';
import {
  HicapsConnectProcessStatus,
  IHicapsConnectProcess,
} from './hicaps-connect-api-adaptor';
import { BridgeDevice } from '../../bridge-device/bridge-device';

export class HicapsConnectProcessHandler {
  static async isTestModeEnabled(
    hicapsProcess: IHicapsConnectProcess<HicapsConnectMethod>,
    features: FeaturesCollection
  ): Promise<boolean> {
    const testingFeatureEnabled = features.isFeatureEnabled(
      HICAPS_CONNECT_TESTING_FEATURE
    );
    if (!testingFeatureEnabled) {
      return false;
    }
    const practiceRef = Firestore.getParentDocRef<IPractice>(
      hicapsProcess.bridgeDevice
    );
    const practice = await Firestore.getDoc(practiceRef);
    return practice.hicapsConnectSettings?.testModeEnabled ?? false;
  }

  static getTestResponse<T extends HicapsConnectMethod>(
    hicapsProcess: IHicapsConnectProcess<T>
  ): IHicapsConnectProcess<T> {
    const mocker = new MockPrincipleHicapsConnectResponse();
    return {
      ...hicapsProcess,
      status: HicapsConnectProcessStatus.Success,
      response: mocker.mock(hicapsProcess.request),
    };
  }

  static async getPreflightError(
    hicapsProcess: IHicapsConnectProcess<HicapsConnectMethod>
  ): Promise<string | undefined> {
    const device = await Firestore.getDoc(hicapsProcess.bridgeDevice);
    if (!device.deviceId) {
      return `Bridge device is not configured: ${device.name}`;
    }
    if (device.status !== BridgeDeviceStatus.Active) {
      return `Bridge device is not active: ${device.name}`;
    }
    if (
      !BridgeDevice.hasIntegrationEnabled(device, HICAPS_CONNECT_INTEGRATION)
    ) {
      return `Hicaps is not enabled on Bridge Device: ${device.name}`;
    }
  }

  static getErrorResponse<T extends HicapsConnectMethod>(
    hicapsProcess: IHicapsConnectProcess<T>,
    message: string
  ): IHicapsConnectProcess<T> {
    return {
      ...hicapsProcess,
      status: HicapsConnectProcessStatus.Error,
      response: PrincipleHicapsConnect.createErrorResponse(
        hicapsProcess.request.processUid,
        message
      ),
    };
  }

  static toDeviceCommand(
    request: WithRef<IHicapsConnectProcess<HicapsConnectMethod>>
  ): IDeviceCommand {
    const practiceRef = Firestore.getParentDocRef<IPractice>(
      request.bridgeDevice
    );
    const brandRef = Firestore.getParentDocRef<IBrand>(practiceRef);
    return {
      deviceRef: request.bridgeDevice,
      practiceRef,
      brandRef,
      command: {
        type: HicapsConnectToDeviceCommand.RunProcess,
        data: request.request,
      },
    };
  }
}
