import { getAllPermissions } from '@principle-theorem/feature-flags';
import { type IRole } from '@principle-theorem/principle-core/interfaces';
import { Role } from '@principle-theorem/principle-core';
import { ALL_FEATURES } from '@principle-theorem/principle-core/features';

export const ADMIN_ROLE: IRole = Role.init({
  name: 'Administrator',
  permissions: getAllPermissions(ALL_FEATURES).map(
    (permission) => permission.value
  ),
});
