import { Injectable } from '@angular/core';
import { CurrentScopeFacade } from '@principle-theorem/ng-principle-shared';
import {
  IBridgeIntegration,
  IDeviceCommand,
} from '@principle-theorem/principle-bridge-core';
import {
  BridgeDevice,
  BridgeDeviceFeature,
  Practice,
} from '@principle-theorem/principle-core';
import {
  BridgeDeviceFeatureStatus,
  IBridgeDevice,
  IBridgeDeviceFeature,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  WithRef,
  filterUndefined,
  getDoc,
  getParentDocRef,
  multiFind,
  reduceToSingleArray,
  serialise,
  snapshot,
} from '@principle-theorem/shared';
import { push, set } from 'firebase/database';
import { uniqBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class BridgeCommandsService {
  availableDevices$: Observable<WithRef<IBridgeDevice>[]>;
  availableFeatures$: Observable<IAvailableBridgeFeature[]>;

  constructor(private _currentScope: CurrentScopeFacade) {
    this.availableDevices$ = this._currentScope.currentPractice$.pipe(
      switchMap((practice) =>
        practice ? Practice.bridgeDevices$(practice) : of([])
      )
    );

    this.availableFeatures$ = this.availableDevices$.pipe(
      map((devices) => {
        const features = uniqBy(
          reduceToSingleArray(devices.map((device) => device.features)),
          'label'
        );

        return features.map((feature) => {
          const availableDevices = devices.filter((device) =>
            BridgeDevice.hasFeatureEnabled(device, feature)
          );

          const status =
            availableDevices.length > 0
              ? BridgeDeviceFeatureStatus.Enabled
              : BridgeDeviceFeatureStatus.Disabled;

          return {
            ...feature,
            status,
            availableDevices,
          };
        });
      })
    );
  }

  async sendCommand(
    command: IDeviceCommand['command'],
    deviceRef: DocumentReference<IBridgeDevice>
  ): Promise<void> {
    const device = await getDoc(deviceRef);
    const practice = await snapshot(
      this._currentScope.currentPractice$.pipe(filterUndefined())
    );
    const brandRef = Practice.brandDoc(practice);
    const organisationId = getParentDocRef(brandRef).id;

    const deviceCommand: IDeviceCommand = {
      deviceRef,
      practiceRef: practice.ref,
      brandRef: brandRef,
      command,
    };

    if (device.deviceId) {
      const deviceDatabaseRef = BridgeDevice.getDeviceDatabaseRef(
        organisationId,
        device.deviceId,
        '/commands'
      );
      const commandRef = await push(deviceDatabaseRef);
      await set(commandRef, serialise(deviceCommand));
    }
  }

  devicesByIntegration$(
    integration: IBridgeIntegration
  ): Observable<WithRef<IBridgeDevice>[]> {
    return this.availableFeatures$.pipe(
      multiFind((feature) =>
        BridgeDeviceFeature.isIntegration(feature, integration)
      ),
      map((feature) => feature?.availableDevices ?? [])
    );
  }
}

export interface IAvailableBridgeFeature extends IBridgeDeviceFeature {
  availableDevices: WithRef<IBridgeDevice>[];
}
