import {
  FeeSchedule,
  serviceCodeToFee,
  ServiceProviderHandler,
} from '@principle-theorem/principle-core';
import {
  ServiceCodeType,
  SourceEntityRecordStatus,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { toInt, type WithRef } from '@principle-theorem/shared';
import { compact, groupBy, last, sortBy } from 'lodash';
import {
  BaseFeeScheduleDestination,
  IFeeScheduleJobData,
  IFeeScheduleMigrationData,
} from '../../../destination/entities/fee-schedules';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  FeeScheduleSourceEntity,
  type IPraktikaFeeSchedule,
} from '../../source/entities/fee-schedule';
import { PraktikaFeeScheduleMappingHandler } from '../mappings/fee-schedules';
import { PraktikaPracticeMappingHandler } from '../mappings/practices';

export class FeeScheduleDestinationEntity extends BaseFeeScheduleDestination<IPraktikaFeeSchedule> {
  feeScheduleSourceEntity = new FeeScheduleSourceEntity();
  practiceCustomMapping = new PraktikaPracticeMappingHandler();
  feeScheduleCustomMapping = new PraktikaFeeScheduleMappingHandler();

  buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IFeeScheduleJobData<IPraktikaFeeSchedule>
  ):
    | IFeeScheduleMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord) {
    const errorResponseData = {
      label: data.feeScheduleRecord.record.label,
      uid: data.feeScheduleRecord.record.uid,
      ref: data.feeScheduleRecord.record.ref,
    };

    if (
      data.feeScheduleRecord.record.status === SourceEntityRecordStatus.Invalid
    ) {
      return this._buildErrorResponse(
        errorResponseData,
        'Source fee schedule is invalid'
      );
    }

    const practiceId = data.feeScheduleRecord.data.data.practice_id;

    const practice = data.practices.find(
      (searchPractice) =>
        searchPractice.sourceIdentifier === practiceId?.toString()
    );

    const items = groupBy(
      data.feeScheduleRecord.data.data.items,
      (item) => `${item.code_id}`
    );

    // TODO: Look to see if there's instances where the same code_id is used
    // across items on a single fee schedule.
    const filteredItems = compact(
      Object.values(items).map((groupItems) =>
        last(sortBy(groupItems, (item) => item.item_id))
      )
    );

    return {
      isDefault: data.feeScheduleRecord.data.data.is_default,
      practiceRef: practice?.destinationIdentifier,
      organisationRef: migration.configuration.organisation.ref,
      sourceFeeScheduleId: this.feeScheduleSourceEntity
        .getSourceRecordId(data.feeScheduleRecord.data.data)
        .toString(),
      feeSchedule: FeeSchedule.init({
        name: data.feeScheduleRecord.data.data.name,
        serviceCodeType: ServiceCodeType.ADA,
        serviceCodes: compact(
          filteredItems.map((item) => {
            const serviceCode = toInt(item.code);
            const foundServiceCode =
              ServiceProviderHandler.findServiceCode(serviceCode);

            if (!foundServiceCode) {
              // eslint-disable-next-line no-console
              console.error(
                `Can't find service code info for code ${serviceCode}`
              );
              return;
            }

            return serviceCodeToFee(foundServiceCode, item.price);
          })
        ),
      }),
    };
  }
}
