import {
  AccountCreditCollection,
  IAccountCredit,
  IAccountCreditExtendedData,
  IPatient,
  IPayWithCreditResults,
  IRefundCreditResults,
  IStaffer,
  ITransaction,
  IUsedAccountCredit,
  PatientCollection,
} from '@principle-theorem/principle-core/interfaces';
import {
  ArchivedDocument,
  AtLeast,
  CollectionReference,
  deleteDoc,
  DocumentArchive,
  DocumentReference,
  Transaction as FirebaseTransaction,
  Firestore,
  initFirestoreModel,
  IReffable,
  isSameRef,
  ITimestamps,
  query$,
  resolveParallel,
  subCollection,
  Timestamp,
  undeletedQuery,
  WithRef,
} from '@principle-theorem/shared';
import { clamp } from 'lodash';
import { Observable } from 'rxjs';

export class AccountCredit {
  static init(
    overrides: AtLeast<IAccountCredit & ITimestamps, 'type' | 'practiceRef'>
  ): IAccountCredit {
    return {
      description: '',
      amount: 0,
      used: 0,
      reservedFor: {},
      ...initFirestoreModel(),
      ...overrides,
    };
  }

  static patientRef(
    credit: IReffable<IAccountCredit>
  ): DocumentReference<IPatient> {
    return Firestore.getParentDocRef<IPatient>(credit.ref);
  }

  static col(
    patient: IReffable<IPatient>
  ): CollectionReference<IAccountCredit> {
    return subCollection<IAccountCredit>(patient, PatientCollection.Credits);
  }

  static all$(
    patient: IReffable<IPatient>
  ): Observable<WithRef<IAccountCredit>[]> {
    return query$(undeletedQuery(AccountCredit.col(patient)));
  }

  static remaining(credit: IAccountCredit): number {
    return credit.amount - credit.used;
  }

  static summary(_credit: IAccountCredit): string {
    return '';
  }

  static async deleteUnusedAccountCredits(
    credits: WithRef<IAccountCredit>[],
    transaction?: FirebaseTransaction
  ): Promise<void> {
    const refs = credits
      .filter((credit) => credit.used === 0)
      .map((credit) => credit.ref);
    await resolveParallel(refs.map((ref) => deleteDoc(ref, transaction)));
  }

  static archiveCol(
    credit: WithRef<IAccountCredit>
  ): CollectionReference<ArchivedDocument<IAccountCredit>> {
    return subCollection<ArchivedDocument<IAccountCredit>>(
      credit.ref,
      AccountCreditCollection.AccountCreditHistory
    );
  }

  static usedAccountCreditsFromTransaction(
    credit: WithRef<IAccountCredit>,
    transaction: WithRef<ITransaction<IAccountCreditExtendedData>>
  ): IUsedAccountCredit[] {
    if (!transaction.extendedData) {
      return [];
    }
    return transaction.extendedData.accountCreditsUsed.filter((usedCredit) =>
      isSameRef(usedCredit, credit)
    );
  }

  static async amend(
    credit: WithRef<IAccountCredit>,
    stafferRef: DocumentReference<IStaffer>,
    atomicTransaction?: FirebaseTransaction
  ): Promise<void> {
    const historyRef = await DocumentArchive.snapshotToArchive(
      await Firestore.getDoc(credit.ref, atomicTransaction),
      AccountCredit.archiveCol(credit),
      undefined,
      stafferRef,
      atomicTransaction
    );
    await Firestore.saveDoc(
      {
        ...credit,
        amendmentOf: historyRef,
      },
      undefined,
      atomicTransaction
    );
  }

  static async getDepositPaidDate(
    credit: WithRef<IAccountCredit>
  ): Promise<Timestamp | undefined> {
    if (!credit.invoice) {
      return;
    }
    const invoice = await Firestore.getDoc(credit.invoice);
    return invoice.paidAt;
  }
}

export function payWithCredits(
  credits: WithRef<IAccountCredit>[],
  amount: number
): IPayWithCreditResults {
  return credits.reduce(
    (results: IPayWithCreditResults, credit: WithRef<IAccountCredit>) => {
      const { remaining, alteredCredits } = payWithCredit(
        results.remaining,
        credit
      );
      return {
        remaining,
        alteredCredits: [...results.alteredCredits, ...alteredCredits],
      };
    },
    { remaining: amount, alteredCredits: [] }
  );
}

export function payWithCredit(
  amountDue: number,
  credit: WithRef<IAccountCredit>
): IPayWithCreditResults {
  if (amountDue <= 0) {
    return { remaining: amountDue, alteredCredits: [] };
  }
  const available = AccountCredit.remaining(credit);
  const amountPayable = clamp(available, amountDue);
  const remaining = amountDue - amountPayable;
  const alteredCredit = {
    ...credit,
    used: credit.used + amountPayable,
  };
  return {
    remaining,
    alteredCredits: [
      {
        accountCredit: alteredCredit,
        amount: amountPayable,
      },
    ],
  };
}

export function refundUsedCredits(
  credits: IUsedAccountCredit[],
  amount: number
): IRefundCreditResults {
  return credits.reduce(
    (results: IRefundCreditResults, credit) => {
      const { remaining, alteredCredits } = refundUsedCredit(
        results.remaining,
        credit
      );
      return {
        remaining,
        alteredCredits: [...results.alteredCredits, ...alteredCredits],
      };
    },
    { remaining: amount, alteredCredits: [] }
  );
}

export function refundUsedCredit(
  amountToRefund: number,
  accountCredit: IUsedAccountCredit
): IRefundCreditResults {
  if (amountToRefund <= 0) {
    return { remaining: amountToRefund, alteredCredits: [] };
  }
  const amountRefundable = clamp(accountCredit.amount, amountToRefund);
  const remaining = amountToRefund - amountRefundable;
  return {
    remaining,
    alteredCredits: [
      {
        accountCreditRef: accountCredit.ref,
        amount: amountRefundable,
      },
    ],
  };
}
