import {
  AllocationTarget,
  Invoice,
  LineItemActions,
} from '@principle-theorem/principle-core';
import {
  IInvoice,
  IInvoicePracitionerSummary,
  INamedAllocationTarget,
  IPaymentPracitionerSummary,
  ITransaction,
  SpecialTransactionAllocationTarget,
  isDepositLineItem,
  isTreatmentLineItem,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import {
  IPractitionerTransactionGrouping,
  PractitionerTransactionSummary,
  createRecordSummary,
} from './practitioner-transaction-report';

export class PractitionerInvoiceBreakdown {
  static getSummaries(
    invoice: WithRef<IInvoice>,
    transactions: WithRef<ITransaction>[]
  ): Pick<IPractitionerTransactionGrouping, 'practitioner' | 'total'>[] {
    return Invoice.getPractitionersOnInvoice(invoice).map((practitioner) => {
      const sumaries = transactions.map((transaction) =>
        createRecordSummary(invoice, transaction, practitioner)
      );
      const total = PractitionerTransactionSummary.reduce(sumaries);
      const allocationTarget: INamedAllocationTarget = {
        name: practitioner.name,
        ref: practitioner.ref,
      };
      return { practitioner: allocationTarget, total };
    });
  }

  static getFeeBreakdowns(
    invoice: WithRef<IInvoice>
  ): IInvoicePracitionerSummary[] {
    return PractitionerInvoiceBreakdown.getSummaries(invoice, []).map(
      ({ practitioner }) => {
        const invoiceTotal = Invoice.total(invoice);
        const practitionerTreatments = invoice.items
          .filter(isTreatmentLineItem)
          .filter((lineItem) =>
            AllocationTarget.isSame(
              lineItem.treatmentRef.attributedTo.ref,
              practitioner.ref
            )
          );

        const treatmentsTotal = LineItemActions.total(practitionerTreatments);
        const practitionerDeposits = invoice.items
          .filter(isDepositLineItem)
          .filter((lineItem) =>
            AllocationTarget.isSame(
              lineItem.attributedTo?.ref ??
                SpecialTransactionAllocationTarget.Unallocated,
              practitioner.ref
            )
          );
        const depositsTotal = LineItemActions.total(practitionerDeposits);

        const practitionerTotal = treatmentsTotal + depositsTotal;
        return {
          practitioner,
          treatments: practitionerTreatments,
          treatmentsTotal,
          deposits: practitionerDeposits,
          depositsTotal,
          practitionerTotal,
          allocatedPercent: practitionerTotal / invoiceTotal,
        };
      }
    );
  }

  static getPaymentBreakdowns(
    invoice: WithRef<IInvoice>,
    transactions: WithRef<ITransaction>[]
  ): IPaymentPracitionerSummary[] {
    return PractitionerInvoiceBreakdown.getSummaries(invoice, transactions).map(
      (summary) => ({
        practitioner: summary.practitioner,
        allocatedPercent:
          summary.total.practitionerProportionAmount /
          summary.total.paymentAmount,
        allocatedAmount: summary.total.practitionerProportionAmount,
      })
    );
  }
}
