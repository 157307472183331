import { Media, Patient } from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  type IDestinationEntityJobRunOptions,
  type ISourceEntityRecord,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IMedia,
  type IPatient,
  type IPracticeMigration,
  type ISource,
  type MergeConflictDestinationEntityRecord,
  type ITranslationMap,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  Timestamp,
  asyncForEach,
  getError,
  toTimestamp,
  type DocumentReference,
  type Timezone,
  type WithRef,
  snapshotCombineLatest,
  type INamedDocument,
  FirestoreMigrate,
} from '@principle-theorem/shared';
import { compact, uniq } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { DestinationEntity } from '../../../destination/destination-entity';
import { CopyFiles, StorageFile, getBucketStoragePath } from '../../../storage';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  ICorePracticePatientAttachment,
  ICorePracticePatientAttachmentFilters,
  ICorePracticePatientAttachmentTranslations,
  PatientAttachmentSourceEntity,
} from '../../source/entities/patient-attachments';
import {
  CORE_PRACTICE_MEDIA_CATEGORY_TAG_NAME_MAP,
  ICorePracticePatientMedia,
  ICorePracticePatientMediaFilters,
  ICorePracticePatientMediaTranslations,
  PatientMediaSourceEntity,
} from '../../source/entities/patient-media';
import {
  ICorePracticePatient,
  ICorePracticePatientFilters,
  ICorePracticePatientTranslations,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { PatientDestinationEntity } from './patients';
import { CorePracticeMediaTagMappingHandler } from '../mappings/media-category-to-media-tag';
import { CorePracticeAttachmentTagMappingHandler } from '../mappings/attachment-to-media-tags';
import { PATIENT_RESOURCE_TYPE } from '../../../destination/entities/patient';

export const PATIENT_FILE_RESOURCE_TYPE = 'patientFiles';

export const PATIENT_FILE_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_FILE_RESOURCE_TYPE,
    label: 'Patient Files',
    description: '',
  },
});

export interface IPatientFileDestinationRecord {
  fileRefs: DocumentReference<IMedia>[];
}

export interface IPatientFileJobData {
  sourcePatient: IGetRecordResponse<
    ICorePracticePatient,
    ICorePracticePatientTranslations,
    ICorePracticePatientFilters
  >;
  mediaCategories: WithRef<ITranslationMap<ITag>>[];
  attachmentCategories: WithRef<ITranslationMap<ITag>>[];
}

interface IFileSummary {
  fileName: string;
  folderPath: string;
}

export interface IPatientFileMigrationData {
  sourcePatientId: string;
  patientRef: DocumentReference<IPatient>;
  images: IGetRecordResponse<
    ICorePracticePatientMedia,
    ICorePracticePatientMediaTranslations,
    ICorePracticePatientMediaFilters
  >[];
  attachments: IGetRecordResponse<
    ICorePracticePatientAttachment,
    ICorePracticePatientAttachmentTranslations,
    ICorePracticePatientAttachmentFilters
  >[];
  files: IFileSummary[];
  mediaCategories: WithRef<ITranslationMap<ITag>>[];
  attachmentCategories: WithRef<ITranslationMap<ITag>>[];
}

export class PatientFileDestinationEntity extends BaseDestinationEntity<
  IPatientFileDestinationRecord,
  IPatientFileJobData,
  IPatientFileMigrationData
> {
  destinationEntity = PATIENT_FILE_DESTINATION_ENTITY;

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    patientMedia: new PatientMediaSourceEntity(),
    patientAttachments: new PatientAttachmentSourceEntity(),
  };

  customMappings = {
    mediaCategories: new CorePracticeMediaTagMappingHandler(),
    attachmentCategories: new CorePracticeAttachmentTagMappingHandler(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  sourceCountDataAccessor(
    data: IPatientFileJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getDestinationEntityRecordUid(data: IPatientFileJobData): string {
    return data.sourcePatient.record.uid;
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    runOptions: IDestinationEntityJobRunOptions
  ): Observable<IPatientFileJobData[]> {
    return combineLatest([
      this.buildSourceRecordQuery$(
        migration,
        this.sourceEntities.patients,
        runOptions
      ),
      snapshotCombineLatest([
        this.customMappings.mediaCategories.getRecords$(translationMap),
        this.customMappings.attachmentCategories.getRecords$(translationMap),
      ]),
    ]).pipe(
      map(([sourcePatients, [mediaCategories, attachmentCategories]]) =>
        sourcePatients.map((sourcePatient) => ({
          sourcePatient,
          mediaCategories,
          attachmentCategories,
        }))
      )
    );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientFileJobData
  ): Promise<
    | IPatientFileMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = data.sourcePatient.data.data.id;
    const images = await this.sourceEntities.patientMedia.filterRecords(
      migration,
      'patientId',
      sourcePatientId
    );

    const attachments =
      await this.sourceEntities.patientAttachments.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `Couldn't resolve patient`
      );
    }

    const files = await getPatientFiles(
      migration.configuration.projectId,
      getBucketStoragePath(migration.source),
      '',
      [
        ...attachments.map((source) => ({ id: source.data.data.id })),
        ...images.map((source) => ({ id: source.data.data.id })),
      ]
    );

    // eslint-disable-next-line no-console
    console.log(`Found ${files.length} files for patient ${sourcePatientId}`);

    return {
      sourcePatientId: sourcePatientId.toString(),
      patientRef,
      images,
      attachments,
      files,
      mediaCategories: data.mediaCategories,
      attachmentCategories: data.attachmentCategories,
    };
  }

  hasMergeConflict(
    _translationMap: TranslationMapHandler,
    _data: IPatientFileMigrationData
  ): IPatientFileMigrationData | undefined {
    return;
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientFileJobData,
    _migrationData: IPatientFileMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
      sourceRef: jobData.sourcePatient.record.ref,
    };
  }

  async runJob(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    data: IPatientFileJobData,
    migrationData: IPatientFileMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const fileRefs = await this._addMediaToPatient(
        migrationData,
        translationMapHandler,
        migration.source,
        migration.configuration.projectId,
        migration.configuration.destinationBucket,
        migration.configuration.timezone
      );
      return this._buildSuccessResponse(data.sourcePatient, fileRefs);
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  getCopyStorage(
    sourceBucket: string,
    patientRef: DocumentReference<IPatient>,
    projectId: string,
    destinationBucket: string
  ): StorageFile {
    return new StorageFile(
      sourceBucket,
      destinationBucket,
      Patient.storagePath({ ref: patientRef }),
      projectId
    );
  }

  private _buildSuccessResponse(
    sourcePatient: IGetRecordResponse<
      ICorePracticePatient,
      ICorePracticePatientTranslations
    >,
    fileRefs: DocumentReference<IMedia>[]
  ): IDestinationEntityRecord<IPatientFileDestinationRecord> {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      data: {
        fileRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      sourceRef: sourcePatient.record.ref,
      migratedAt: toTimestamp(),
    };
  }

  private _buildErrorResponse(
    sourcePatient: IGetRecordResponse<
      ICorePracticePatient,
      ICorePracticePatientTranslations
    >,
    errorMessage: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: sourcePatient.record.uid,
      label: sourcePatient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      sourceRef: sourcePatient.record.ref,
      errorMessage,
      failData: {
        patientRef: sourcePatient.record.ref,
      },
    };
  }

  private async _addMediaToPatient(
    migrationData: IPatientFileMigrationData,
    translationMap: TranslationMapHandler,
    source: ISource,
    projectId: string,
    destinationBucket: string,
    _timezone: Timezone
  ): Promise<DocumentReference<IMedia>[]> {
    const sourcePatientId = migrationData.sourcePatientId;
    const patientRef = migrationData.patientRef;
    const images = migrationData.images;
    const attachments = migrationData.attachments;
    const files = migrationData.files;

    // eslint-disable-next-line no-console
    console.log(
      `Found ${files.length} files for patient ${sourcePatientId} in the bucket`
    );
    if (!files.length) {
      return [];
    }

    const copyStorage = this.getCopyStorage(
      getBucketStoragePath(source),
      patientRef,
      projectId,
      destinationBucket
    );

    return asyncForEach(files, async (file) => {
      const destinationPath = await copyStorage.copy(file.folderPath);
      if (!destinationPath) {
        throw new Error(`Failed to copy ${file.folderPath}`);
      }

      const matchingImage = images.find(
        (image) => image.data.data.id === file.fileName
      );
      const matchingAttachment = attachments.find(
        (attachment) => attachment.data.data.id === file.fileName
      );

      if (!matchingImage && !matchingAttachment) {
        throw new Error(
          `No matching image or attachment found for ${file.folderPath}`
        );
      }

      const fileDestinationRef = await translationMap.getDestination(
        file.folderPath,
        PATIENT_FILE_RESOURCE_TYPE
      );

      const createdAt = this._determineCreatedAt(
        matchingImage,
        matchingAttachment
      );
      const fileName = this._determineFileName(
        matchingImage,
        matchingAttachment
      );
      const tags = compact([
        this._getTags(
          migrationData.mediaCategories,
          migrationData.attachmentCategories,
          matchingImage,
          matchingAttachment
        ),
      ]);

      const mediaRef = await FirestoreMigrate.upsertDoc(
        Patient.mediaCol({ ref: patientRef }),
        {
          ...Media.init({
            name: fileName,
            path: destinationPath,
            tags,
          }),
          createdAt,
        },
        fileDestinationRef?.id
      );

      if (!fileDestinationRef) {
        await translationMap.upsert({
          sourceIdentifier: file.folderPath,
          destinationIdentifier: mediaRef,
          resourceType: PATIENT_FILE_RESOURCE_TYPE,
        });
      }

      return mediaRef;
    });
  }

  private _determineCreatedAt(
    matchingImage?: IGetRecordResponse<ICorePracticePatientMedia>,
    matchingAttachment?: IGetRecordResponse<ICorePracticePatientAttachment>
  ): Timestamp {
    if (matchingImage) {
      return toTimestamp(
        moment.utc(matchingImage.data.data.dateTaken, ISO_DATE_TIME_FORMAT)
      );
    }

    if (matchingAttachment) {
      return toTimestamp(
        moment.utc(
          matchingAttachment.data.data.attachmentDate,
          ISO_DATE_TIME_FORMAT
        )
      );
    }

    return toTimestamp();
  }

  private _determineFileName(
    matchingImage?: IGetRecordResponse<ICorePracticePatientMedia>,
    matchingAttachment?: IGetRecordResponse<ICorePracticePatientAttachment>
  ): string {
    if (matchingImage) {
      return matchingImage.data.data.fileName;
    }

    if (matchingAttachment) {
      return matchingAttachment.data.data.fileName;
    }

    return '';
  }

  private _getTags(
    mediaCategories: WithRef<ITranslationMap<ITag>>[],
    attachmentCategories: WithRef<ITranslationMap<ITag>>[],
    matchingImage?: IGetRecordResponse<ICorePracticePatientMedia>,
    matchingAttachment?: IGetRecordResponse<ICorePracticePatientAttachment>
  ): INamedDocument<ITag> | undefined {
    if (matchingImage && matchingImage.data.data.categoryId) {
      const category =
        CORE_PRACTICE_MEDIA_CATEGORY_TAG_NAME_MAP[
          matchingImage.data.data.categoryId
        ];
      const found = mediaCategories.find(
        (mediaCategory) => category === mediaCategory.sourceIdentifier
      );

      if (!found || !found.destinationIdentifier) {
        return;
      }
      return {
        name: found.sourceIdentifier,
        ref: found.destinationIdentifier,
      };
    }

    if (matchingAttachment && matchingAttachment.data.data.tag) {
      const found = attachmentCategories.find(
        (attachmentCategory) =>
          matchingAttachment.data.data.tag ===
          attachmentCategory.sourceIdentifier
      );

      if (!found || !found.destinationIdentifier) {
        return;
      }

      return {
        name: found.sourceIdentifier,
        ref: found.destinationIdentifier,
      };
    }
  }
}

export async function getPatientFiles(
  projectId: string,
  bucketName: string,
  bucketPath: string,
  sources: { id: string }[]
): Promise<IFileSummary[]> {
  const storage = new CopyFiles(bucketName, bucketPath, '', '', projectId)
    .storage;

  const [files] = await storage.bucket(bucketName).getFiles({
    prefix: bucketPath,
  });

  return uniq(
    compact(
      files.map((file) => {
        const folderParts = file.name.split('/');
        const fileName = folderParts[folderParts.length - 1].split('.')[0];

        const foundSource = sources.find((source) => source.id === fileName);
        if (!foundSource) {
          return;
        }

        return {
          fileName,
          folderPath: file.name,
        };
      })
    )
  );
}
