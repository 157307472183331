import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Invoice } from '@principle-theorem/principle-core';
import { type IInvoice } from '@principle-theorem/principle-core/interfaces';
import {
  type ArchivedDocument,
  filterUndefined,
  type WithRef,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IInvoiceAmendmentHistoryDialogData {
  invoice: WithRef<IInvoice>;
  selectedInvoice?: WithRef<ArchivedDocument<IInvoice>>;
}

@Component({
    selector: 'pr-invoice-amendment-history-dialog',
    templateUrl: './invoice-amendment-history-dialog.component.html',
    styleUrls: ['./invoice-amendment-history-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InvoiceAmendmentHistoryDialogComponent {
  amendmentHistory$: Observable<WithRef<ArchivedDocument<IInvoice>>[]>;
  selectedInvoice$: Observable<WithRef<ArchivedDocument<IInvoice>>>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: IInvoiceAmendmentHistoryDialogData
  ) {
    this.amendmentHistory$ = Invoice.amendmentHistory$(data.invoice);
    this.selectedInvoice$ = this.amendmentHistory$.pipe(
      map(
        (amendmentHistory) => data.selectedInvoice ?? first(amendmentHistory)
      ),
      filterUndefined()
    );
  }
}
