import { Injectable } from '@angular/core';
import { httpsCallable, isObject, snapshot } from '@principle-theorem/shared';
import { isString } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class FirebaseFunctionsService {
  async call<T = unknown, R = unknown>(
    name: string,
    requestData: T
  ): Promise<R> {
    return snapshot(httpsCallable<T, R>(name)(requestData));
  }
}

export type HttpsErrorImpl = {
  code: string;
  message: string;
  stack: string;
  originalStack: string;
};

export function isHttpsErrorImpl(data: unknown): data is HttpsErrorImpl {
  return (
    isObject(data) &&
    isString(data.code) &&
    isString(data.message) &&
    isString(data.stack) &&
    isString(data.originalStack)
  );
}
