import { type PracticeMigrationEssentials } from '@principle-theorem/principle-core/interfaces';
import { D4W_DESTINATION } from './destination';
import { D4W_MAPPINGS } from './mappings';
import { D4W_SOURCE } from './source';

export const D4W_MIGRATION: PracticeMigrationEssentials = {
  metadata: {
    name: 'D4W',
    version: 'v1',
    supportsMultiplePractices: true,
    description: `
    # Migration Summary
    The goal of this process is to compare D4W records against the migrated records in Principle.

    Principle's migration system is performed programatically which ensures that any issues in the migration process can be corrected and fixes will be reflected through the whole system. We implement many checks and balances before the data is migrated and best efforts have been made so far to ensure the integrity of the data.

    ## Recording errors
    If a discrepancy is found between D4W's and Principle's records, please note the patient name, the url in Principle, as well as any details that illustrate the discrepancy. This helps us to investigate the cause of the discrepancy so that it can be fixed throughout the system.

    ## Primary testing areas
    - Appointments
      - From "Timeline" - Comparing days in the appointment books between D4W and Principle
      - From "Appointment History" tab on patient profile - Comparing a patients appointments
    - Invoices ("account" tab on patient profile); Confirming remaining balance & invoice items
    - Deposits ("account" tab on patient profile)
    - Patient details (main patient profile page)
    - Patient conditions - Review patient's clinical chart to compare conditions list
    - Performed treatments - Review "D4W - Migrated Treatments" against D4W treatment list
    - Patient notes (main patient profile page)

    ## What is migrated
    - Patients, including relationships, preferred practitioner and fee schedules.
    - Patient files including images, PDFs, Letters etc...
    - Appointments, including scheduling notes and appointment statuses (More on this later)
    - Invoices, payments and deposits
    - Fee Schedules
    - Treatments, both "performed" treatments, and "future planned" treatments
    - Treatment Notes. Note: ADA code treatments will be added to clinical notes by default, however you can configure other notes such as "Steri" to be migrated as Sterilisation records to our system (More on this later)
    - Clinical conditions
    - Missing teeth

    ## What isn't migrated
    We make our best effort to migrate as much as possible from your PMS however some data can't be translated. In the case of D4W the following can't be migrated:
    - Patient medical history (A new medical history will need to be recorded for Principle)
    - Pre-existing treatments (as shown in the top dental chart in D4W)

    ## Mapping D4W Appointment Statuses
    Appointment statuses in D4W can represent many things, whereas the appointment status in Principle is purely tied to the transitioning of the appointment event.

    For this reason, we need to know which appointment statuses actually affect the appointment, as well as what appointment statuses would act as a tag on the appointment.

    Below is a list of Appointment Statuses in your D4W installation. We have aimed to categorise them as best as we can, however please update the list if any appointment status has not been correctly assigned to a category.

    **Confirm Appointment**
    C (Confirmed)
    O (SMS Confirmation eAppointments)
    Y (SMS Reply - Confirmed)
    e (Email Confirmation eAppointments)
    p (Phone Confirmation eAppointments)

    **Cancel Appointment**
    G (Appt RES/CANCEL COVID 19)
    Z (SMS Reply - Cancelled)
    F (FTA)
    U (UTA)

    **Add Appointment Tag**
    D (CDBS)
    L (Lab work needed)
    N (New patient)
    Q (Lab arrived)
    V (Hospital Voucher)
    d (DVA)
    z (PAYMENT PLAN)

    **Omit**
    \\+ (Hygienist)
    -1 (Doctor out)
    -2 (Doctor in)
    -3 (Doctor more)
    . (Nurse)
    / (Dentist)
    0 (No status)
    = (Other)
    A (Past)
    B (Booked pt from follow up call)
    E (Appointment notifications)
    H (Maintenance booked)
    M (Message given)
    P (Phone patient)
    R (Reminder call made (2 week))
    S (SMS Sent)
    T (Treatment booked)
    X (TX Notes completed)
    h (Maintenance not booked)
    m (Medical History Required)
    o (Online eAppointments)
    q (Recall eAppointments)
    r (Booked from recall list)
    s (Standby)
    t (treatment not booked)
    u (Unmatched Patient)
    w (NO DOCS NEEDED)
    y (YES)

    ## Mapping D4W Item Code Notes
    As D4W has allows for the creation of non-ADA item codes, we need to determine where any notes will be stored in Principle that originate from non-ADA item codes.

    In the case that a treatment has a fee, the treatment will be reflected on the invoice as expected, regardless of the item code.

    **Clinical Note**
    020 - Combination Gmhba 014/023
    021 - Complete Intraoral Series &gt;10 Films
    024 - Additional X-Ray
    034 - Lateral Skull X-ray
    10975 - Dental Assessment and Report
    10976 - Dental Treatment For Cond. That Is Exacerbating Px Chronic Med C
    10977 - Dental Assess. Or Treat &amp; Report By Dental Specialist On Referra
    1stImp - First Impression
    225 - Non-surgical Perio Treat Per Visit (DVA ONLY)
    2ndImp - Second Impression
    316 - Removal Additional Tooth
    321 - Surgical Removal Erupted Tooth
    329 - Non Routine Post-Op Treatment
    396 - Orthopaedic
    397 - Reconstructive Plastic Surgery
    398 - Minor Soft Tissue Surgery
    472 - Lab fee/labour on above item (DVA)
    482 - Lab fee/labour on above item (DVA)
    482a - Step Down Fee Fee For Subsequent Items (DVA)
    483 - Lab fee/labour on above item (DVA)
    484 - Lab fee/labour on above item (DVA)
    485 - Lab fee/labour on above item (DVA)
    486 - Lab fee/labour on above item (DVA)
    488 - Lab fee/labour on above item (DVA)
    567 - Inlay/Onlay- white- 1 surf
    568 - Inlay/Onlay- white- 2 surf
    569 - Inlay/Onlay- white- 3+ surf
    576 - Metallic Crown - preformed
    598 - Full coronal- metallic- direct
    599 - Full coronal ant.- white- direct
    620 - Three quarter crown- indirect
    621 - Three Quarter Crown-Non-Met- Indirect
    662 - Provisional Implant crown abutment-per abutment (DVA ONLY)
    694 - Bone augmentation - per segment
    770DVA - For provision of dentures in difficult case - DVA only
    800IMP - Appliances Impression
    88000 - Preventative Dental Check
    88576 - Metal Crown - Preformed
    922 - Drug Admin.- Intramuscular
    931 - Home Visit
    932 - Hospital Visit
    993 - Received O P G
    994 - Received Specialist Report
    9999 - Dental Treatment - See Tx Notes
    AR - Amalgam Fill
    Bite - Bite
    CDBS - Cdbs Notes
    CR - Composite Fill
    Chk B - Check Occlusion
    EPCU10 - Existing Patient Check Up 10 And Under (012,114,121)
    EPCU11 - Existing Patient Check Up 11-17 (012,114,121)
    EPCX10 - Existing Patient Check Up And X-Rays 10 And Under (012,114,121,022,022)
    EPCX11 - Existing Patient Check Up And X-Rays 11-17 (012,114,121,022,022)
    INVIS - Patients Receiving Invisalign Tx
    Insert - Insert
    NPCU10 - New Patient Check Up 10 And Under (011,114,121)
    NPCU11 - New Patient Check Up 11-17 (011,114,121)
    NPCX10 - New Patient Check Up And X-Rays 10 And Under (011,114,121,022,022)
    NPCX11 - New Patient Check Up And X-Rays 11-17 (011,114,121,022,022)
    PLAN - Application
    Per - Perusal/Consideration Of Matters
    Recall - Recall Notes
    Retry - Try In With Teeth
    Rev - Review Insurance Case
    Review - Review
    S+C - Scale and Clean
    SAS - Share A Smile Card
    SRIGHT - Plans Sent Through Smile Right/Smile Right Notes
    Try-in - Try-In
    Txplan - Notes About Treatment Plans
    cem - Cementation
    implan - Implant Cases
    met - Metal Try In
    spl - fit splint
    super - Supernumerary

    **General Patient Note**
    000 - Reactivation Letter Notes
    995 - Oral Hygiene Products
    Admin - Administration Notes
    DONE - Done
    FEEDBK - Feedback
    R.Link - Review Link
    Ref - Referral
    Reschd - Patient Rescheduled
    fta - F T A - Fail To Attend
    uta - U T A - Unable To Attend

    **Omit Notes**
    No items

    **Patient Call Note**
    TLC - Tlc Call

    **Patient Email Note**
    No items

    **Patient Lab Job Note**
    No items

    **Patient Payment Note**
    988 - Bounced Cheque Returned
    989 - Debt Collector Fee
    992 - Refund To Patient
    cancel - Cancellation Fee
    996 - A/c Error Correction
    997 - Balance Of Account
    998 - Total Cost Of Treatment
    Acc - Accounts
    DVA - All Claims Done Under Veteran Affairs
    E.Card - E-Gift Card For Patient Referral
    Gst - Gst Component
    Refund - Refund To Patient
    stock - Stock/Products

    **Patient SMS Note**
    No items

    **Patient Social Note**
    PN - Personal Notes

    **Sterilisation Record**
    Steri - Sterilisation
    `,
  },
  source: D4W_SOURCE,
  destination: D4W_DESTINATION,
  mappings: D4W_MAPPINGS,
};
