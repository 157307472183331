import {
  getEnumValues,
  type INamedDocument,
  isEnumValue,
  isINamedDocument,
  isObject,
} from '@principle-theorem/shared';
import {
  type DocumentReference,
  type Timestamp,
} from '@principle-theorem/shared';
import { type IPatient } from '../patient/patient';
import { type IPractice } from '../practice/practice';
import { type IStaffer } from '../staffer/staffer';
import { type ITreatmentCategory } from '../treatment-category';

export interface IParticipant<T = IStaffer | IPatient>
  extends INamedDocument<T> {
  type: ParticipantType;
}

export enum ParticipantType {
  Staffer = 'staffer',
  Patient = 'patient',
}

export type BaseEvent = {
  from: Timestamp;
  to: Timestamp;
  practice: INamedDocument<IPractice>;
  creator?: INamedDocument<IStaffer>;
  organiser?: INamedDocument<IStaffer>;
  participants: IParticipant[];
  participantRefs: DocumentReference<IStaffer | IPatient>[];
  type: EventType;
};

export type PreBlockEvent = Omit<BaseEvent, 'type'> & {
  type: EventType.PreBlock;
  allowedTreatmentCategories: DocumentReference<ITreatmentCategory>[];
  isPublic: boolean;
  colourOverride?: string;
};

export type IEvent = BaseEvent | PreBlockEvent;

export type EventTemplate = Omit<IEvent, 'from' | 'to'>;

export function isEvent(item: unknown): item is IEvent {
  return (
    isObject(item) &&
    'from' in item &&
    typeof item.from &&
    'to' in item &&
    isINamedDocument(item.practice) &&
    typeof item.to &&
    'type' in item &&
    isEnumValue(EventType, item.type) &&
    'participants' in item &&
    Array.isArray(item.participants) &&
    'participantRefs' in item &&
    Array.isArray(item.participantRefs)
  );
}

export function isPreBlockEvent(
  item: IEvent | EventTemplate
): item is PreBlockEvent {
  return item.type === EventType.PreBlock;
}

export enum EventType {
  Appointment = 'appointment',
  // WaitList = 'waitList',
  Meeting = 'meeting',
  Break = 'break',
  Leave = 'leave',
  Misc = 'misc',
  Gap = 'gap',
  GapCandidate = 'gapCandidate',
  AppointmentRequest = 'appointmentRequest',
  PracticeClosed = 'practiceClosed',
  RosteredOn = 'rosteredOn',
  PreBlock = 'preBlock',
}

export const ROSTERED_OFF_EVENT_TYPES = [
  EventType.Leave,
  EventType.PracticeClosed,
];

export const ROSTER_SCHEDULE_EVENT_TYPES = [
  EventType.RosteredOn,
  EventType.Break,
  EventType.PreBlock,
];

type EventTypeColourMap = {
  [key in EventType]: string;
};

export const EVENT_TYPE_COLOUR_MAP: EventTypeColourMap = {
  [EventType.Appointment]: '#4285f4',
  [EventType.Meeting]: '#7986cb',
  [EventType.Break]: '#CCCCCC',
  [EventType.Leave]: '#CCCCCC',
  [EventType.Misc]: '#283044',
  [EventType.Gap]: '#C5C4F7',
  [EventType.GapCandidate]: '#C5C4F7',
  [EventType.AppointmentRequest]: '#f50057',
  [EventType.PracticeClosed]: '#9c9c9c',
  [EventType.RosteredOn]: '#34eb89',
  [EventType.PreBlock]: '#b16ffc',
};

export const EVENT_TYPES: EventType[] = getEnumValues(EventType);

export const EVENT_TYPES_ICONS: EventIconMap = {
  [EventType.Appointment]: 'airline_seat_flat_angled',
  [EventType.GapCandidate]: 'schedule',
  [EventType.Meeting]: 'work',
  [EventType.Break]: 'restaurant',
  [EventType.Leave]: 'event_busy',
  [EventType.Misc]: 'miscellaneous_services',
  [EventType.Gap]: 'crop_free',
  [EventType.AppointmentRequest]: 'edit_calendar',
  [EventType.PracticeClosed]: 'group_off',
  [EventType.RosteredOn]: 'schedule',
  [EventType.PreBlock]: 'shield',
};

export type EventIconMap = {
  [key in EventType]: string;
};

export interface IEventable<T extends object = object> {
  event: IEvent;
  ref?: DocumentReference<T>;
}

export type WithEvent<T extends object> = T & IEventable<T>;

export function isEventable<T extends object>(
  item: T & Partial<IEventable<T>>
): item is WithEvent<T> {
  return isObject(item) && 'event' in item && isEvent(item.event);
}

export interface IEventHistory {
  createdAt: Timestamp;
  event?: IEvent;
}

export interface IEventStyle {
  backgroundColor: string;
  color: string;
}
