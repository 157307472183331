import { Brand, toINamedDocuments } from '@principle-theorem/principle-core';
import {
  CustomMappingType,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
  type IReferralSourceConfiguration,
} from '@principle-theorem/principle-core/interfaces';
import {
  IBlobFilenamePair,
  XSLXImporterExporter,
  asyncForEach,
  hardDeleteDoc,
  multiMap,
  multiSortBy$,
  nameSorter,
  snapshot,
  type INamedDocument,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import Fuse from 'fuse.js';
import { first, sortBy } from 'lodash';
import { BaseCustomMappingHandler } from '../base-custom-mapping-handler';
import { CustomMapping } from '../custom-mapping';
import { PracticeMigration } from '../practice-migrations';
import { BaseSourceEntity } from '../source/base-source-entity';
import { TranslationMapHandler } from '../translation-map';
import { ReferralSourceToXSLX } from './referral-sources-to-xlsx';
import { XSLXToReferralSources } from './xlsx-to-referral-sources';

export interface IBaseMigrationReferralSource {
  id: string;
  name: string;
}

export const REFERRAL_SOURCE_CUSTOM_MAPPING_TYPE = 'referralSource';

export const REFERRAL_SOURCE_MAPPING = CustomMapping.init({
  metadata: {
    label: 'Referral Sources',
    description: `Used for mapping Contacts to the correct Referral Sources in Principle.`,
    type: REFERRAL_SOURCE_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.DocumentReference,
  labelOverrides: {
    sourceIdentifier: 'Id',
    sourceLabel: 'Referral Source',
    destinationIdentifier: 'Principle Referral Source',
  },
});

export abstract class BaseReferralSourceMappingHandler<
  ReferralSource extends object,
  SourceEntity extends BaseSourceEntity<ReferralSource>,
> extends BaseCustomMappingHandler<IReferralSourceConfiguration> {
  customMapping = REFERRAL_SOURCE_MAPPING;
  abstract translateFn: (
    record: ReferralSource
  ) => IBaseMigrationReferralSource;
  abstract sourceEntity: SourceEntity;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const records = await this.sourceEntity
      .getRecords$(migration, 1000)
      .toPromise();
    const options = records
      .map((record) => record.data.data)
      .map((record) => {
        const referralSource = this.translateFn(record);
        return {
          label: referralSource.name,
          value: this.sourceEntity.getSourceRecordId(record).toString(),
        };
      });

    return sortBy(options, 'label');
  }

  async getDestinationOptions(
    migration: WithRef<IPracticeMigration>
  ): Promise<INamedDocument<IReferralSourceConfiguration>[]> {
    return snapshot(
      Brand.referralSources$(migration.configuration.brand).pipe(
        toINamedDocuments(),
        multiSortBy$(nameSorter()),
        multiMap((referralSource) => ({
          name: referralSource.name,
          ref: referralSource.ref,
        })),
        multiSortBy$(nameSorter())
      )
    );
  }

  async autocompleteMappings(
    migration: WithRef<IPracticeMigration>
  ): Promise<void> {
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const sourceOptions = await this.getSourceOptions(migration);
    const destinationOptions = await this.getDestinationOptions(migration);
    const records = await this.getRecords(translationMap);

    const mappedItemCodes = records
      .filter((record) => !!record.destinationIdentifier)
      .map((record) => record.sourceIdentifier);
    const unmappedItemCodes = sourceOptions.filter(
      (option) => !mappedItemCodes.includes(option.value)
    );

    await asyncForEach(unmappedItemCodes, async (item) => {
      const fuse = new Fuse(destinationOptions, {
        keys: ['name'],
        includeScore: true,
        threshold: 0.3,
      });
      const matches = fuse.search(item.label);
      const match = first(matches)?.item;

      if (!match) {
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: match.ref,
          destinationValue: match.name,
          sourceIdentifier: item.value,
          sourceLabel: item.label,
        },
        translationMap
      );
    });
  }

  async getMappingBlob(
    migration: WithRef<IPracticeMigration>
  ): Promise<IBlobFilenamePair> {
    const { fileName, referralSources, translators } =
      await this._getExporterData(migration);

    return new XSLXImporterExporter().getBlob(
      fileName,
      referralSources,
      translators
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const { fileName, referralSources, translators } =
      await this._getExporterData(migration);

    await new XSLXImporterExporter().download(
      fileName,
      referralSources,
      translators
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToReferralSources()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const records = await this.getRecords(translationMap);
    await asyncForEach(records, (record) => hardDeleteDoc(record.ref));

    const sourceOptions = await this.getSourceOptions(migration);
    const referralSourceConfigurations =
      await this.getDestinationOptions(migration);

    await asyncForEach(items, async (item) => {
      const mapTo = item.mapTo;
      const id = item.sourceId;
      const label = item.sourceName;
      const value = sourceOptions.find(
        (sourceOption) => sourceOption.value === id
      )?.value;
      if (!value || !item.mapTo) {
        return;
      }

      const referralSourceConfiguration = referralSourceConfigurations.find(
        (configuration) => configuration.name === mapTo
      );

      if (!referralSourceConfiguration) {
        // eslint-disable-next-line no-console
        console.error(
          `Mapping error: ${this.customMapping.metadata.label} - Couldn't find referral source for item`,
          item
        );
        return;
      }

      await this.upsertRecord(
        {
          destinationIdentifier: referralSourceConfiguration.ref,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getExporterData(
    migration: WithRef<IPracticeMigration>
  ): Promise<{
    fileName: string;
    referralSources: ICustomMappingSourceOption[];
    translators: ReferralSourceToXSLX;
  }> {
    const fileName = this.getFileName();
    const referralSources = await this.getSourceOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );
    const translators = new ReferralSourceToXSLX(
      await snapshot(Brand.referralSources$(migration.configuration.brand)),
      await this.getRecords(translationMap)
    );
    return { fileName, referralSources, translators };
  }
}
