import {
  FeeScheduleMappingHandler,
  IBaseMigrationFeeSchedule,
} from '../../../mappings/fee-schedules';
import {
  FeeScheduleSourceEntity,
  IExactFeeSchedule,
} from '../../source/entities/fee-schedule';

export class ExactFeeScheduleMappingHandler extends FeeScheduleMappingHandler<
  IExactFeeSchedule,
  FeeScheduleSourceEntity
> {
  override sourceEntity = new FeeScheduleSourceEntity();
  translateFn = (record: IExactFeeSchedule): IBaseMigrationFeeSchedule => ({
    ...record,
    id: record.name,
  });
}
