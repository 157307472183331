<div class="m-2 flex flex-col gap-2">
  <pr-account-summary-datapoint
    description="Free Credit"
    [value]="summary.creditSummary.freeCredit"
    tooltip="Credit awarded without payment"
  />
  <pr-account-summary-datapoint
    description="Deposits Paid"
    [value]="summary.creditSummary.depositsPaid"
    tooltip="Credit resulting from paid deposits"
  />
  <pr-account-summary-datapoint
    description="Credit Total"
    [value]="summary.creditSummary.creditTotal"
    tooltip="Free Credit + Deposits Paid"
    class="subtotal"
  />

  <mat-divider />

  <pr-account-summary-datapoint
    description="Credit Used"
    [value]="summary.creditSummary.creditUsed"
    tooltip="Credit used to pay for invoices"
  />
  <pr-account-summary-datapoint
    description="Credit Remaining"
    [value]="summary.creditSummary.creditRemaining"
    tooltip="Credit Total - Credit Used"
    class="total green"
  />
</div>
