import { Money, MoneyInput } from '@principle-theorem/accounting';
import {
  SpecialTransactionAllocationTarget,
  TransactionAllocationTarget,
} from '@principle-theorem/principle-core/interfaces';
import * as Dinero from 'dinero.js';
import { clamp, compact } from 'lodash';
import { AllocationTarget } from './allocation-target';

export interface IAmountAllocation {
  allocatedTo: TransactionAllocationTarget;
  amount: Dinero.Dinero;
}

export class AmountAllocation {
  static format(
    allocation: IAmountAllocation | IAmountAllocation[],
    delimiter: string = ', '
  ): string {
    const allocations = Array.isArray(allocation) ? allocation : [allocation];
    return allocations
      .map((item) => {
        const target = AllocationTarget.format(item.allocatedTo);
        return `${item.amount.toFormat()} -> ${target}`;
      })
      .join(delimiter);
  }

  static create(
    allocatedTo?: TransactionAllocationTarget,
    amount: MoneyInput = 0
  ): IAmountAllocation {
    return {
      allocatedTo:
        allocatedTo ?? SpecialTransactionAllocationTarget.Unallocated,
      amount: Money.from(amount),
    };
  }

  static omitEmpty(allocations: IAmountAllocation[]): IAmountAllocation[] {
    return compact(allocations).filter(
      (allocation) => !allocation.amount.isZero()
    );
  }

  static merge(allocations: IAmountAllocation[]): IAmountAllocation[] {
    return this.add([], allocations);
  }

  static add(
    allocations: IAmountAllocation[],
    ...subsuquentAllocations: IAmountAllocation[][]
  ): IAmountAllocation[] {
    return this.math(
      allocations,
      subsuquentAllocations.flat(),
      (aAllocation, bAllocation) => aAllocation.add(bAllocation)
    );
  }

  static subtract(
    allocations: IAmountAllocation[],
    ...subsuquentAllocations: IAmountAllocation[][]
  ): IAmountAllocation[] {
    return this.math(
      allocations,
      subsuquentAllocations.flat(),
      (aAllocation, bAllocation) => aAllocation.subtract(bAllocation)
    );
  }

  static math(
    existingAllocations: IAmountAllocation[],
    incomingAllocations: IAmountAllocation[],
    mathFn: (
      existingValue: Dinero.Dinero,
      incomingValue: Dinero.Dinero
    ) => Dinero.Dinero
  ): IAmountAllocation[] {
    const clonedExisting = existingAllocations.map((allocation) =>
      this.create(allocation.allocatedTo, allocation.amount)
    );
    const result = incomingAllocations.reduce(
      (acc: IAmountAllocation[], incoming: IAmountAllocation) => {
        const existing = this.find(incoming.allocatedTo, acc);
        const existingValue = Money.from(existing?.amount ?? 0);
        const newValue = mathFn(existingValue, incoming.amount);
        if (existing) {
          existing.amount = newValue;
          return acc;
        }
        return [...acc, this.create(incoming.allocatedTo, newValue)];
      },
      clonedExisting
    );
    return this.omitEmpty(result);
  }

  static sum(allocations: IAmountAllocation[]): Dinero.Dinero {
    const values = allocations.map((allocation) => allocation.amount);
    return Money.sum(values);
  }

  static clamp(
    allocation: IAmountAllocation,
    clampToAmount: number = 0
  ): IAmountAllocation {
    const allocationAmount = Money.amount(allocation.amount);
    const min = allocationAmount >= 0 ? 0 : -clampToAmount;
    const max = allocationAmount >= 0 ? clampToAmount : 0;
    const clamped = clamp(allocationAmount, min, max);
    return this.create(allocation.allocatedTo, clamped);
  }

  static sort(allocations: IAmountAllocation[]): IAmountAllocation[] {
    return allocations.sort((a, b) => {
      if (AllocationTarget.isUnallocated(a.allocatedTo)) {
        return 1;
      }
      if (AllocationTarget.isUnallocated(b.allocatedTo)) {
        return -1;
      }
      return 0;
    });
  }

  static find(
    target: TransactionAllocationTarget,
    allocations: IAmountAllocation[]
  ): IAmountAllocation | undefined {
    return allocations.find((allocation) =>
      AllocationTarget.isSame(allocation.allocatedTo, target)
    );
  }
}
