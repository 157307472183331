import { initVersionedSchema, toTextContent } from '@principle-theorem/editor';
import {
  AutomationType,
  getGeneratedTaskFromConfiguration,
  IAutomation,
  IAutomationConfiguration,
  IBrand,
  IEvent,
  IGeneratedTask,
  IGeneratedTaskConfiguration,
  IStaffer,
  ITask,
  ITeam,
  TaskPriority,
  TimingDirection,
  TimingUnit,
} from '@principle-theorem/principle-core/interfaces';
import { DocumentReference, Timestamp } from '@principle-theorem/shared';
import {
  AtLeast,
  INamedDocument,
  INamedTypeDocument,
  WithRef,
} from '@principle-theorem/shared';
import { Automation } from '../automation/automation';
import { AutomationTiming } from '../automation/automation-timing';
import { Task } from './task';

export class GeneratedTask {
  static init(overrides: AtLeast<IGeneratedTask, 'timing'>): IGeneratedTask {
    return {
      title: [],
      comment: initVersionedSchema(),
      isForTeam: false,
      priority: TaskPriority.Medium,
      ...overrides,
    };
  }

  static async toAutomation(
    task: IGeneratedTask | WithRef<IGeneratedTask>,
    creator: INamedTypeDocument,
    event: IEvent,
    brandRef: DocumentReference<IBrand>,
    configRef?: DocumentReference<IAutomationConfiguration>
  ): Promise<IAutomation<IGeneratedTask>> {
    return Automation.init({
      type: AutomationType.GeneratedTask,
      data: task,
      creator,
      triggerDate: await AutomationTiming.resolveTriggerDateFromEvent(
        event,
        task.timing
      ),
      brandRef,
      practiceRef: event.practice.ref,
      configRef,
    });
  }

  static async generateFromConfig(
    config: WithRef<IGeneratedTaskConfiguration>,
    creator: INamedTypeDocument,
    event: IEvent,
    brandRef: DocumentReference<IBrand>,
    triggerAfterDate?: Timestamp
  ): Promise<IAutomation<IGeneratedTask>> {
    return Automation.init({
      type: AutomationType.GeneratedTask,
      data: getGeneratedTaskFromConfiguration(config),
      creator,
      triggerDate: await AutomationTiming.resolveTriggerDateFromEvent(
        event,
        config.timing
      ),
      triggerAfterDate,
      brandRef,
      practiceRef: event.practice.ref,
      configRef: config.ref,
    });
  }

  static toTask(generatedTask: IGeneratedTask): ITask {
    const assignment = generatedTask.isForTeam
      ? { assignedTeam: generatedTask.assignee as INamedDocument<ITeam> }
      : { assignedUser: generatedTask.assignee as INamedDocument<IStaffer> };
    return Task.init({ ...generatedTask, ...assignment });
  }
}

export const MOCK_GENERATED_TASKS: IGeneratedTask[] = [
  {
    title: [toTextContent('Check in on Implant 47')],
    timing: {
      direction: TimingDirection.After,
      amount: 1,
      unit: TimingUnit.Days,
    },
    priority: TaskPriority.Medium,
    isForTeam: false,
    comment: initVersionedSchema(
      'Implant 47 should not be exhibiting high levels of aggression'
    ),
  },
  {
    title: [toTextContent('Follow up on patient')],
    timing: {
      direction: TimingDirection.Before,
      amount: 2,
      unit: TimingUnit.Days,
    },
    priority: TaskPriority.Medium,
    isForTeam: false,
    comment: initVersionedSchema('See how patient is doing after surgery'),
  },
  {
    title: [toTextContent('Check ingredients for anaesthesia mixture')],
    timing: {
      direction: TimingDirection.Before,
      amount: 1,
      unit: TimingUnit.Months,
    },
    priority: TaskPriority.High,
    isForTeam: false,
    comment: initVersionedSchema('Shake it and bake it'),
  },
  {
    title: [toTextContent('Follow up with patient about Invisalign')],
    timing: {
      direction: TimingDirection.Before,
      amount: 1,
      unit: TimingUnit.Months,
    },
    priority: TaskPriority.Medium,
    isForTeam: false,
    comment: initVersionedSchema('Pitch invisalign'),
  },
];
