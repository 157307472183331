import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditPatientMetadataDisplayFormData } from '@principle-theorem/ng-principle-shared';
import {
  IMatSelectOption,
  TypedFormControl,
  TypedFormGroup,
} from '@principle-theorem/ng-shared';
import {
  IPatientMetadataDisplay,
  ISchedulingEventReason,
  PatientMetadataDisplayFormat,
  SchedulingEventType,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, randomHexColour, snapshot } from '@principle-theorem/shared';
import { ReplaySubject } from 'rxjs';

export interface IEditCancellationReasonRequest {
  cancellationReason?: WithRef<ISchedulingEventReason>;
  metadataDisplay?: IPatientMetadataDisplay;
}

export interface IEditCancellationReasonResponse {
  schedulingEventReason: SchedulingEventReasonFormData;
  metadataDisplay: EditPatientMetadataDisplayFormData;
}

export type SchedulingEventReasonFormData = Omit<
  ISchedulingEventReason,
  | 'deleted'
  | 'metadataKey'
  | 'isSystemReason'
  | 'displayRef'
  | 'metadataDisplayRef'
>;

@Component({
    selector: 'pr-edit-cancellation-reason-dialog',
    templateUrl: './edit-cancellation-reason-dialog.component.html',
    styleUrls: ['./edit-cancellation-reason-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditCancellationReasonDialogComponent {
  title: string = 'Add Rescheduling & Cancellation Reason';
  form = new TypedFormGroup<SchedulingEventReasonFormData>({
    name: new TypedFormControl<string>('', Validators.required),
    description: new TypedFormControl<string>(''),
    eventTypes: new TypedFormControl<
      SchedulingEventReasonFormData['eventTypes']
    >(
      [SchedulingEventType.Reschedule, SchedulingEventType.Cancel],
      Validators.required
    ),
    hrsBeforeAppointment: new TypedFormControl<number | undefined>(undefined),
    moveToSameDayDefault: new TypedFormControl<boolean>(false),
    fillGapDefault: new TypedFormControl<boolean>(false),
  });
  eventTypes: IMatSelectOption<SchedulingEventType>[] = [
    { label: 'Rescheduling', value: SchedulingEventType.Reschedule },
    { label: 'Cancelling', value: SchedulingEventType.Cancel },
  ];
  metadataDisplayFormValue$ =
    new ReplaySubject<EditPatientMetadataDisplayFormData>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEditCancellationReasonRequest,
    private _dialogRef: MatDialogRef<
      EditCancellationReasonDialogComponent,
      IEditCancellationReasonResponse
    >
  ) {
    if (data.cancellationReason) {
      this.title = 'Edit Rescheduling & Cancellation Reason';
      this.form.patchValue(data.cancellationReason);
    }

    this.metadataDisplayFormValue$.next({
      format: PatientMetadataDisplayFormat.LabelValue,
      hexColour: randomHexColour(),
      displayTargets: [],
      onlyShowIfHasValue: true,
      label: data.cancellationReason?.name ?? 'Label',
      ...data.metadataDisplay,
    });
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    const formValue = this.form.getRawValue();
    this._dialogRef.close({
      metadataDisplay: await snapshot(this.metadataDisplayFormValue$),
      schedulingEventReason: {
        ...formValue,
        hrsBeforeAppointment: formValue.hrsBeforeAppointment ?? undefined,
        moveToSameDayDefault: formValue.moveToSameDayDefault ?? false,
        fillGapDefault: formValue.fillGapDefault ?? false,
      },
    });
  }
}
