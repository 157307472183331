import {
  MedicareValidation,
  Transaction,
  TyroEasyclaimPayload,
} from '@principle-theorem/principle-core';
import {
  IPatientClaimDialogResponse,
  IPatientEClaimTransactionExtendedData,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
  type IBulkBillDialogResponse,
  type IBulkBillTransactionExtendedData,
  type IHealthcareClaim,
  type IInvoice,
  type IPractice,
  type ITransaction,
} from '@principle-theorem/principle-core/interfaces';
import {
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import {
  TransactionCompleteResult,
  isEasyclaimCompleteCallback,
  type IBaseTransactionCompleteCallbackData,
  type IBulkBillEasyclaimRequestParams,
  type IEasyclaimRequestParams,
} from '@principle-theorem/tyro';
import { sum } from 'lodash';
import {
  getServiceItems,
  totalClaimAmount,
} from '../../transaction-components/medicare-components/select-claim-items/select-claim-items.component';
import { getStatus, toTyroTransaction } from '../base-transaction';

export async function toBulkBillTransaction(
  invoice: WithRef<IInvoice>,
  practiceRef: DocumentReference<IPractice>,
  request: IBulkBillEasyclaimRequestParams,
  response: IBaseTransactionCompleteCallbackData,
  formData: IBulkBillDialogResponse,
  shouldDefaultToPending: boolean
): Promise<ITransaction<IBulkBillTransactionExtendedData>> {
  const amount = isEasyclaimCompleteCallback(response)
    ? TyroEasyclaimPayload.getBulkBillBenefitAmount(response.payload)
    : totalClaimAmount(formData.itemClaims);
  const baseTyroTransaction = await toTyroTransaction(
    TransactionProvider.TyroEasyClaimBulkBill,
    invoice,
    practiceRef,
    response
  );

  const status = MedicareValidation.coerceTransactionStatus(
    baseTyroTransaction.status,
    shouldDefaultToPending
  );

  return Transaction.init({
    ...baseTyroTransaction,
    status,
    amount,
    extendedData: {
      request,
      response,
      formData,
    },
  });
}

export type EasyclaimTransactionExtendedData =
  | IBulkBillTransactionExtendedData
  | IPatientEClaimTransactionExtendedData;

export async function toEasyclaimFullyPaidTransaction(
  invoice: WithRef<IInvoice>,
  practiceRef: DocumentReference<IPractice>,
  claim: IHealthcareClaim,
  request: IEasyclaimRequestParams,
  response: IBaseTransactionCompleteCallbackData,
  formData: IPatientClaimDialogResponse
): Promise<ITransaction> {
  const itemClaims = getServiceItems(invoice, claim);
  const amount = isEasyclaimCompleteCallback(response)
    ? TyroEasyclaimPayload.getPartPaidBenefitAmount(response.payload)
    : totalClaimAmount(itemClaims);
  const baseTyroTransaction = await toTyroTransaction(
    TransactionProvider.TyroEasyClaimFullyPaid,
    invoice,
    practiceRef,
    response,
    TransactionType.Claim
  );
  return Transaction.init({
    ...baseTyroTransaction,
    status: getPatientEClaimStatus(response),
    amount,
    extendedData: {
      request,
      response,
      formData,
    },
  });
}

export async function toEasyclaimPartPaidTransaction(
  invoice: WithRef<IInvoice>,
  practiceRef: DocumentReference<IPractice>,
  request: IEasyclaimRequestParams,
  response: IBaseTransactionCompleteCallbackData,
  formData: IBulkBillDialogResponse
): Promise<ITransaction> {
  const totalPatientContribution = totalClaimAmount(formData.itemClaims);
  const totalChargedAmount = sum(
    formData.itemClaims
      .filter((item) => item.claim)
      .map((item) => item.lineItem.amount)
  );
  const amount = isEasyclaimCompleteCallback(response)
    ? TyroEasyclaimPayload.getPartPaidBenefitAmount(response.payload)
    : totalChargedAmount - totalPatientContribution;
  const baseTyroTransaction = await toTyroTransaction(
    TransactionProvider.TyroEasyClaimPartPaid,
    invoice,
    practiceRef,
    response
  );
  return Transaction.init({
    ...baseTyroTransaction,
    status: getPatientEClaimStatus(response),
    amount,
    extendedData: {
      request,
      response,
      formData,
    },
  });
}

function getPatientEClaimStatus(
  complete: IBaseTransactionCompleteCallbackData
): TransactionStatus | undefined {
  if (
    !isEasyclaimCompleteCallback(complete) ||
    complete.result !== TransactionCompleteResult.APPROVED
  ) {
    return getStatus(complete.result);
  }
  switch (TyroEasyclaimPayload.getMedicareAcceptanceType(complete.payload)) {
    case 'PAID':
    case 'PEND':
    case 'PDVC':
      return TransactionStatus.Complete;
    case 'NAC':
      return TransactionStatus.Failed;
    default:
      return;
  }
}
