import { toMentionContent, toTextContent } from '@principle-theorem/editor';
import {
  AppointmentManager,
  convertWaitListDetailsToItem,
  type IAppointmentDetails,
  type IWithEvent,
  type IWithWaitList,
} from '@principle-theorem/ng-appointment/store';
import {
  Appointment,
  Candidate,
  Event,
  Interaction,
  TimezoneResolver,
  toMention,
} from '@principle-theorem/principle-core';
import {
  EventType,
  ISchedulingEventData,
  InteractionType,
  MentionResourceType,
  ParticipantType,
  type IAppointment,
  type IBrand,
  type ICandidate,
  type ICandidateCalendarEvent,
  type IEvent,
  type IGap,
  type IPatient,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  getDoc,
  patchDoc,
  saveDoc,
  toNamedDocument,
  type WithRef,
} from '@principle-theorem/shared';

export interface IMoveAppointmentData {
  gap: IGap;
  brand: WithRef<IBrand>;
  patient: WithRef<IPatient>;
  appointment: WithRef<IAppointment>;
  gapCandidate: WithRef<ICandidateCalendarEvent>;
}

export class GapManager {
  async approveCandidate(
    staffer: WithRef<IStaffer>,
    data: IMoveAppointmentData,
    form: IAppointmentDetails & IWithWaitList & IWithEvent,
    schedulingEventData: ISchedulingEventData
  ): Promise<void> {
    const event = form.event;
    if (!event) {
      return;
    }
    const timezone = await TimezoneResolver.fromEvent(event);

    const appointmentRef = await AppointmentManager.move(
      staffer,
      data.patient,
      data.appointment,
      {
        ...form,
        event,
        automations: [],
        schedulingEventData,
      }
    );

    const updatedAppointment = await getDoc(appointmentRef);

    Candidate.approve(data.gapCandidate.candidate);
    await saveDoc(data.gapCandidate);

    const interactions = [
      Interaction.init({
        type: InteractionType.Gap,
        title: [
          toMentionContent(
            toMention(data.patient, MentionResourceType.Patient)
          ),
          toTextContent(` approved for gap`),
        ],
      }),
    ];

    await asyncForEach(interactions, (interaction) =>
      Appointment.addInteraction(updatedAppointment, interaction)
    );

    const changes: Partial<IAppointment> = {};

    if (form.waitListItem) {
      changes.waitListItem = convertWaitListDetailsToItem(
        form.waitListItem,
        timezone
      );
    }

    await patchDoc(updatedAppointment.ref, changes);
  }

  copyGapEventToAppointment(
    gap: IGap,
    candidate: ICandidate,
    appointment: WithRef<IAppointment>,
    patient: WithRef<IPatient>
  ): void {
    const participants = [
      {
        ...toNamedDocument(patient),
        type: ParticipantType.Patient,
      },
      {
        ...toNamedDocument(gap.practitioner),
        type: ParticipantType.Staffer,
      },
    ];
    const event: IEvent = Event.init({
      participants,
      practice: gap.event.practice,
      from: candidate.offerTimeFrom,
      to: candidate.offerTimeTo,
      type: EventType.Appointment,
      organiser: gap.practitioner,
    });
    appointment.practitioner = gap.practitioner;

    Appointment.replaceEvent(appointment, event);
  }
}
