import { isMixedSchema, MixedSchema } from '@principle-theorem/editor';
import {
  isINamedDocument,
  isObject,
  type DocumentReference,
  type INamedDocument,
  type ISoftDelete,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { type IStaffer } from '../staffer/staffer';
import { type ITemplateDefinition } from '../templating/template-definition';

export interface IPatientDocument extends ISoftDelete {
  name: string;
  content: MixedSchema;
  createdBy: INamedDocument<IStaffer>;
  templateRef?: DocumentReference<ITemplateDefinition>;
}

export function isPatientDocument(data: unknown): data is IPatientDocument {
  return (
    isObject(data) &&
    isString(data.name) &&
    isINamedDocument(data.createdBy) &&
    isMixedSchema(data.content)
  );
}
