import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import { TypeGuard, toFloat, type Timezone } from '@principle-theorem/shared';
import { flow, isNumber } from 'lodash';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  convertValueFn,
} from '../../../source/source-helpers';

export const PATIENT_INVOICE_LINE_ITEM_RESOURCE_TYPE =
  'patientInvoiceLineItems';

export const PATIENT_INVOICE_LINE_ITEM_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Invoice Line Item List',
      description: '',
      idPrefix: PATIENT_INVOICE_LINE_ITEM_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ICorePracticePatientInvoiceLineItem {
  id: number;
  quantity: number;
  unitAmount: number; // 35.4500
  lineAmount: number; // 35.4500
  discount?: number; // 20.9700
  patientId: number;
  invoiceId: number;
  providerId: number;
  treatmentId: number;
  itemId: number;
  paidAmount?: number; // 14.4800
  taxAmount?: number; // .0000
}

export function isCorePracticePatientInvoiceLineItem(
  item: unknown
): item is ICorePracticePatientInvoiceLineItem {
  return TypeGuard.interface<ICorePracticePatientInvoiceLineItem>({
    id: isNumber,
    quantity: isNumber,
    unitAmount: isNumber,
    lineAmount: isNumber,
    discount: TypeGuard.nilOr(isNumber),
    patientId: isNumber,
    invoiceId: isNumber,
    providerId: isNumber,
    treatmentId: isNumber,
    itemId: isNumber,
    paidAmount: TypeGuard.nilOr(isNumber),
    taxAmount: TypeGuard.nilOr(isNumber),
  })(item);
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICorePracticePatientInvoiceLineItemTranslations {}

export interface ICorePracticePatientInvoiceLineItemFilters {
  patientId: number;
  invoiceId: number;
  providerId: number;
  treatmentId: number;
  itemId: number;
}

const PATIENT_INVOICE_LINE_ITEM_SOURCE_QUERY = `
SELECT
  InvoiceLineItemId AS id,
  Quantity AS quantity,
  convert_to_decimal(UnitAmount) AS unit_amount,
  convert_to_decimal(LineAmount) AS line_amount,
  convert_to_decimal(Discount) AS discount,
  PatientId AS patient_id,
  InvoiceId AS invoice_id,
  ProviderId AS provider_id,
  TreatmentId AS treatment_id,
  ItemId AS item_id,
  convert_to_decimal(PaidAmount) AS paid_amount,
  convert_to_decimal(TaxAmount) AS tax_amount
FROM tblInvoiceLineItem
ORDER BY InvoiceLineItemId
`;

export class PatientInvoiceLineItemSourceEntity extends BaseSourceEntity<
  ICorePracticePatientInvoiceLineItem,
  ICorePracticePatientInvoiceLineItemTranslations,
  ICorePracticePatientInvoiceLineItemFilters
> {
  sourceEntity = PATIENT_INVOICE_LINE_ITEM_SOURCE_ENTITY;
  entityResourceType = PATIENT_INVOICE_LINE_ITEM_RESOURCE_TYPE;
  sourceQuery = PATIENT_INVOICE_LINE_ITEM_SOURCE_QUERY;
  allowOffsetJob = true;
  override defaultOffsetSize = 50000;
  verifySourceFn = isCorePracticePatientInvoiceLineItem;

  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertValueFn(
      toFloat,
      'unitAmount',
      'lineAmount',
      'discount',
      'paidAmount',
      'taxAmount'
    ),
  ]);

  translate(
    _data: ICorePracticePatientInvoiceLineItem,
    _timezone: Timezone
  ): ICorePracticePatientInvoiceLineItemTranslations {
    return {};
  }

  getSourceRecordId(data: ICorePracticePatientInvoiceLineItem): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientInvoiceLineItem): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientInvoiceLineItem,
    _timezone: Timezone
  ): ICorePracticePatientInvoiceLineItemFilters {
    return {
      patientId: data.patientId,
      invoiceId: data.invoiceId,
      providerId: data.providerId,
      treatmentId: data.treatmentId,
      itemId: data.itemId,
    };
  }
}
