import { ChartOptions, type IChartData } from './chart-builder';
import { ChartType } from '@principle-theorem/reporting';
import { BaseChart } from './base-chart';
import { PieDataTransformer } from './data-transformers/google-charts/pie-data-transformer';

export class PieChart extends BaseChart {
  override dataTransformer = new PieDataTransformer();
  data: IChartData = {
    type: ChartType.Pie,
    options: ChartOptions.init(),
  };
}

export const PIE_CHART_OPTIONS = ChartOptions.init({
  backgroundColor: 'none',
  pieHole: 0.4,
  legend: { position: 'none' },
  chartArea: {
    top: 10,
    right: 10,
    bottom: 10,
    left: 10,
  },
});
