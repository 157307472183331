import { getSchemaText } from '@principle-theorem/editor';
import { IRecurringTaskConfiguration } from '@principle-theorem/principle-core/interfaces';
import {
  CustomRecurrenceFrequency,
  Frequency,
  WithRef,
} from '@principle-theorem/shared';
import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export class RecurringTaskConfigurationGrouper {
  groups: IRecurringTaskConfigurationGroupSorter[] = [
    {
      name: 'Daily Tasks',
      isInGroup: (recurringTaskConfiguration: IRecurringTaskConfiguration) => {
        return (
          (recurringTaskConfiguration.recurrencePattern.frequencyType ===
            Frequency.Daily ||
            recurringTaskConfiguration.recurrencePattern.customFrequencyType ===
              CustomRecurrenceFrequency.Daily) &&
          !recurringTaskConfiguration.deleted
        );
      },
    },
    {
      name: 'Weekly Tasks',
      isInGroup: (recurringTaskConfiguration: IRecurringTaskConfiguration) => {
        return (
          (recurringTaskConfiguration.recurrencePattern.frequencyType ===
            Frequency.Weekly ||
            recurringTaskConfiguration.recurrencePattern.customFrequencyType ===
              CustomRecurrenceFrequency.Weekly) &&
          !recurringTaskConfiguration.deleted
        );
      },
    },
    {
      name: 'Monthly Tasks',
      isInGroup: (recurringTaskConfiguration: IRecurringTaskConfiguration) => {
        return (
          (recurringTaskConfiguration.recurrencePattern.frequencyType ===
            Frequency.Monthly ||
            recurringTaskConfiguration.recurrencePattern.customFrequencyType ===
              CustomRecurrenceFrequency.Monthly) &&
          !recurringTaskConfiguration.deleted
        );
      },
    },
    {
      name: 'Yearly Tasks',
      isInGroup: (recurringTaskConfiguration: IRecurringTaskConfiguration) => {
        return (
          (recurringTaskConfiguration.recurrencePattern.frequencyType ===
            Frequency.Yearly ||
            recurringTaskConfiguration.recurrencePattern.customFrequencyType ===
              CustomRecurrenceFrequency.Yearly) &&
          !recurringTaskConfiguration.deleted
        );
      },
    },
    {
      name: 'Deleted Tasks',
      isInGroup: (recurringTaskConfiguration: IRecurringTaskConfiguration) => {
        return recurringTaskConfiguration.deleted;
      },
    },
  ];

  getByGroup(
    configurations: WithRef<IRecurringTaskConfiguration>[]
  ): IRecurringTaskConfigurationGroup[] {
    return this.groups.map((sorter: IRecurringTaskConfigurationGroupSorter) => {
      const filtered: WithRef<IRecurringTaskConfiguration>[] = configurations
        .filter((configuration) => sorter.isInGroup(configuration))
        .sort((configurationA, configurationB): number => {
          return getSchemaText(configurationA.title).toLowerCase() >
            getSchemaText(configurationB.title).toLowerCase()
            ? 1
            : -1;
        });

      return {
        name: sorter.name,
        configurations: filtered,
      };
    });
  }
}

export interface IRecurringTaskConfigurationGroup {
  name: string;
  configurations: WithRef<IRecurringTaskConfiguration>[];
}

interface IRecurringTaskConfigurationGroupSorter {
  name: string;
  isInGroup: (
    recurringTaskConfiguration: IRecurringTaskConfiguration
  ) => boolean;
}

export function sortRecurringTaskConfigByGroup(): OperatorFunction<
  WithRef<IRecurringTaskConfiguration>[],
  IRecurringTaskConfigurationGroup[]
> {
  const grouper: RecurringTaskConfigurationGrouper =
    new RecurringTaskConfigurationGrouper();
  return map((configurations) => grouper.getByGroup(configurations));
}
