import {
  type IFirestoreModel,
  type IRecurrencePattern,
} from '@principle-theorem/shared';
import { type ScheduleModifierOption } from './schedule-modifier';
import { type ScheduleTime } from './schedule-time';

export interface ISchedule<T = object> extends IFirestoreModel {
  item: T;
  pattern: IRecurrencePattern;
  scheduleTime?: ScheduleTime;
  modifiers: ScheduleModifierOption[];
}
