import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectQueryParam } from '@principle-theorem/ng-principle-shared';
import {
  type ILabJobsState,
  labJobAdapter,
  LAB_JOBS_FEATURE_KEY,
} from './lab-jobs.reducer';

const { selectAll, selectEntities } = labJobAdapter.getSelectors();

export const getLabJobsState =
  createFeatureSelector<ILabJobsState>(LAB_JOBS_FEATURE_KEY);

export const getLabsJobsLoaded = createSelector(
  getLabJobsState,
  (state) => state.loaded
);

export const getLabJobs = createSelector(getLabJobsState, (state) =>
  selectAll(state)
);

export const getLabJobsEntities = createSelector(getLabJobsState, (state) =>
  selectEntities(state)
);

export const getSelectedLabJobId = createSelector(
  getLabJobsState,
  (state) => state.selectedId
);

export const getSelectedLabJob = createSelector(
  getLabJobsEntities,
  getSelectedLabJobId,
  (entities, id) => {
    if (!id) {
      return;
    }
    return entities[id];
  }
);

export const getStatusFilterParam = selectQueryParam('status');
export const getStatusFilter = createSelector(
  getLabJobsState,
  (state) => state.statusFilter
);

export const getPresetFilterParam = selectQueryParam('filter');
export const getPresetFilter = createSelector(
  getLabJobsState,
  (state) => state.presetFilter
);

export const getSortParam = selectQueryParam('sort');
export const getSortOption = createSelector(
  getLabJobsState,
  (state) => state.sortOption
);

export const getLabJobIdParam = selectQueryParam('labJob');
