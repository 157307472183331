import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Input,
  Signal,
  signal,
} from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { type IGeoCoordinates } from '@principle-theorem/principle-core/interfaces';
import { GeocodeService } from '../../maps/geocode.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'pr-map-preview',
    templateUrl: './map-preview.component.html',
    styleUrls: ['./map-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, GoogleMapsModule],
    providers: [GeocodeService]
})
export class MapPreviewComponent {
  private _geocodeService = inject(GeocodeService);
  mapsLoaded = toSignal(this._geocodeService.mapsLoaded$());
  latLong = signal<google.maps.LatLngLiteral | undefined>(undefined);
  enableControls = signal(true);
  options: Signal<google.maps.MapOptions | undefined>;
  @Input() height: string = '300px';

  @Input()
  set coordinates(coordinates: IGeoCoordinates | undefined) {
    const latLong = coordinates
      ? { lat: coordinates.latitude, lng: coordinates.longitude }
      : undefined;
    this.latLong.set(latLong);
  }

  @Input({ transform: coerceBooleanProperty })
  set locked(locked: boolean) {
    this.enableControls.set(!locked);
  }

  constructor() {
    this.options = computed((): google.maps.MapOptions | undefined => {
      const center = this.latLong();
      if (!center) {
        return;
      }
      const enabledControls = this.enableControls();
      return {
        zoom: 14,
        center,
        disableDefaultUI: !enabledControls,
        scrollwheel: enabledControls,
        keyboardShortcuts: enabledControls,
        gestureHandling: enabledControls ? 'auto' : 'none',
      };
    });
  }
}
