import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  type OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { type IChartElement } from '../renderers/chart-element';

@Directive({
    selector: '[prSVGTransform]',
    standalone: false
})
export class SVGTransformDirective implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _isGroup = false;
  private _element$: Subject<IChartElement> = new Subject<IChartElement>();
  @HostBinding('attr.transform') transform?: string;
  @HostBinding('style.width.px') width?: number;
  @HostBinding('style.height.px') height?: number;

  constructor(elementRef: ElementRef<SVGGElement>) {
    this._isGroup = elementRef.nativeElement instanceof SVGGElement;

    this._element$
      .pipe(
        map((element: IChartElement) => element.width),
        takeUntil(this._onDestroy$)
      )
      .subscribe(
        (width: number) => (this.width = this._isGroup ? undefined : width)
      );

    this._element$
      .pipe(
        map((element: IChartElement) => element.height),
        takeUntil(this._onDestroy$)
      )
      .subscribe(
        (height: number) => (this.height = this._isGroup ? undefined : height)
      );

    this._element$
      .pipe(
        map((element: IChartElement) => element.transform),
        takeUntil(this._onDestroy$)
      )
      .subscribe((transform: string) => (this.transform = transform));
  }

  @Input('prSVGTransform')
  set element(element: IChartElement) {
    if (!element) {
      return;
    }
    this._element$.next(element);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
