import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type ICalendarEventStyle } from '@principle-theorem/ng-calendar/store';
import {
  CurrentPracticeScope,
  TimezoneService,
} from '@principle-theorem/ng-principle-shared';
import { DynamicSidebarService } from '@principle-theorem/ng-shared';
import {
  ScheduleSummary,
  getEventTitle,
} from '@principle-theorem/principle-core';
import {
  EVENT_TYPE_COLOUR_MAP,
  ICalendarEvent,
  IScheduleSummaryEventable,
} from '@principle-theorem/principle-core/interfaces';
import {
  TIME_FORMAT,
  exceedsLuminanceThreshold,
  filterUndefined,
  getDayRange,
  getRangeDuration,
  rangeMaxDuration,
  snapshot,
  type ITimePeriod,
} from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { CalendarEventEditSidebarComponent } from '../calendar-event-edit-sidebar/calendar-event-edit-sidebar.component';
import { CalendarEventSidebarStoreService } from '../calendar-event-edit-sidebar/calendar-event-sidebar-store.service';

@Component({
    selector: 'pr-calendar-event',
    templateUrl: './calendar-event.component.html',
    styleUrls: ['./calendar-event.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CalendarEventComponent {
  event$ = new ReplaySubject<IScheduleSummaryEventable<ICalendarEvent>>(1);
  eventTime$: Observable<string | undefined>;
  title$: Observable<string>;
  eventStyle$: Observable<Partial<ICalendarEventStyle>>;

  @Input() index = 0;
  @Input() day: Moment;
  @Input()
  set event(event: IScheduleSummaryEventable<ICalendarEvent>) {
    if (!event) {
      return;
    }
    this.event$.next(event);
  }

  constructor(
    private _sidebar: DynamicSidebarService,
    private _calendarEventSidebarStore: CalendarEventSidebarStoreService,
    private _practice: CurrentPracticeScope,
    private _timezone: TimezoneService
  ) {
    this.eventTime$ = this.event$.pipe(
      switchMap((event) => this._timezone.getEventRange$(event.event)),
      filter((eventRange) => {
        const duration = getRangeDuration(getDayRange()).subtract(1, 'minute');
        return rangeMaxDuration(eventRange, duration);
      }),
      map((eventRange) =>
        !this.startsBeforeDay(eventRange)
          ? eventRange.from.format(TIME_FORMAT)
          : undefined
      )
    );

    this.title$ = this.event$.pipe(
      map((event) => getEventTitle(event as IScheduleSummaryEventable))
    );

    this.eventStyle$ = combineLatest([
      this.event$,
      this._practice.doc$.pipe(filterUndefined()),
    ]).pipe(
      map(([event, practice]) => {
        const backgroundColor =
          practice.settings.eventColourOverrides?.[event.event.type] ??
          EVENT_TYPE_COLOUR_MAP[event.event.type];
        const color = exceedsLuminanceThreshold(backgroundColor, 0.5)
          ? 'rgba(0, 0, 0, .6)'
          : 'rgba(255, 255, 255, .9)';

        return {
          backgroundColor,
          color,
        };
      })
    );
  }

  async selectEvent(
    event: IScheduleSummaryEventable<ICalendarEvent>
  ): Promise<void> {
    const practice = await snapshot(
      this._practice.doc$.pipe(filterUndefined())
    );
    const calendarEvent = await ScheduleSummary.getCalendarEvent(
      event,
      practice
    );
    this._calendarEventSidebarStore.setState({ calendarEvent });
    return this._sidebar.open(CalendarEventEditSidebarComponent);
  }

  startsBeforeDay(eventRange: ITimePeriod): boolean {
    return eventRange.from.isBefore(this.day.clone().startOf('day'));
  }
}
