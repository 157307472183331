import { type IFirestoreModel, isObject } from '@principle-theorem/shared';

export enum AssetType {
  Instrument = 'instrument',
  Equipment = 'equipment',
  Consumable = 'consumable',
}

export interface IAsset extends IFirestoreModel {
  name: string;
  count: number;
  type: AssetType;
}

export interface IConsumableAsset extends IAsset {
  type: AssetType.Consumable;
}

export function isConsumableAsset(item: unknown): item is IConsumableAsset {
  return isObject(item) && 'type' in item && item.type === AssetType.Consumable;
}

export interface IEquipmentAsset extends IAsset {
  type: AssetType.Equipment;
}

export function isEquipmentAsset(item: unknown): item is IEquipmentAsset {
  return isObject(item) && 'type' in item && item.type === AssetType.Equipment;
}

export interface IInstrumentAsset extends IAsset {
  type: AssetType.Instrument;
}

export function isInstrumentAsset(item: unknown): item is IInstrumentAsset {
  return isObject(item) && 'type' in item && item.type === AssetType.Instrument;
}
