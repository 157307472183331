import { Component, Input } from '@angular/core';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IInteractionTypeMap,
  interactionTypeDisplayMap,
  type ISchedulingAlert,
} from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-scheduling-alerts-list',
  templateUrl: './scheduling-alerts-list.component.html',
  styleUrls: ['./scheduling-alerts-list.component.sass'],
})
export class SchedulingAlertsListComponent {
  trackByAlert = TrackByFunctions.title<ISchedulingAlert>();
  @Input() schedulingAlerts: ISchedulingAlert[];
  typeIcon: IInteractionTypeMap = interactionTypeDisplayMap;
}
