import { TreatmentStepStatus } from '@principle-theorem/principle-core/interfaces';
import { ITreatmentStepDimension } from '@principle-theorem/reporting/interfaces';
import { BaseDimensionMeasures } from '../base-measures';
import { MeasurePath } from '../data-accessor-factory';
import {
  CanBeChartedProperty,
  CanDoAllProperty,
  MeasureTransformMap,
} from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';

export class TreatmentStepDimensionMeasures
  extends BaseDimensionMeasures
  implements
    MeasureTransformMap<Pick<ITreatmentStepDimension, 'status' | 'name'>>
{
  get ref(): CanBeChartedProperty {
    const propertyName = 'ref';
    const docRef = this.measureRef(MeasurePath.docRef(propertyName));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Treatment Step Ref',
        summary: '',
      },
      docRef,
      this.buildQuery().attributes([docRef.attributePath]).get()
    );
  }

  get name(): CanDoAllProperty {
    const propertyName = 'name';
    const name = this.measureRef(propertyName);
    const docRef = this.measureRef(MeasurePath.docRef('ref'));
    return MeasurePropertyFactory.docRef(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Treatment Step Name',
        summary: '',
      },
      docRef,
      this.buildQuery()
        .attributes([docRef.attributePath, name.attributePath])
        .get(),
      name
    );
  }

  get status(): CanDoAllProperty {
    const propertyName = 'status';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.enum<TreatmentStepStatus>(
      {
        id: this._pathWithPrefix(propertyName),
        label: 'Treatment Step Status',
        summary: '',
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get(),
      TreatmentStepStatus.Incomplete
    );
  }
}
