import { ObservableDataSource } from '@principle-theorem/ng-shared';
import { IBaseChartBuilderConfig } from '@principle-theorem/principle-core/interfaces';
import {
  DataPointValue,
  ICanGroupMeasuresProperty,
  IChartConfig,
  IMeasureBuilderData,
} from '@principle-theorem/reporting';
import { WithId } from '@principle-theorem/shared';
import { Grouping, NaturallyOrderedValue } from 'crossfilter2';
import * as dc from 'dc';
import { get, groupBy, sortBy, toPairs } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { IDCTransformResult } from '../../../models/report/charts/data-transformers/dc-data-transformer';
import {
  ChartBuildHelpers,
  D3_CHART_ELEM_SELECTOR,
  DEFAULT_CHART_BUILDER_CONFIG,
  IDCChartEvent,
  ITableBuilder,
} from './dc-chart-builders/dc-chart-builder';

export interface ITableChartBuilderConfig extends IBaseChartBuilderConfig {}

export const TABLE_GROUP_KEY = 'group';

export const DEFAULT_TABLE_CHART_BUILDER_CONFIG: ITableChartBuilderConfig = {
  ...DEFAULT_CHART_BUILDER_CONFIG,
};

export interface IGroupedDataPoint {
  groupKey: DataPointValue;
  measureKey: string;
  value: DataPointValue;
}

export interface ITableRow {
  [TABLE_GROUP_KEY]: string;
  [key: string]: NaturallyOrderedValue;
}

export class TableChartBuilder implements ITableBuilder {
  constructor(
    _config: ITableChartBuilderConfig = DEFAULT_TABLE_CHART_BUILDER_CONFIG
  ) {}

  getLabel(config: IChartConfig): string {
    return config.labels.title;
  }

  getChart(
    results: IDCTransformResult[],
    config: IChartConfig,
    eventListener$?: Subject<IDCChartEvent>
  ): ObservableDataSource<ITableRow> | undefined {
    if (!config.builderData.groupByDimension || !results.length) {
      return;
    }

    const group = config.builderData.groupByDimension;
    const rows$ = new BehaviorSubject(this._buildRows(results, config, group));

    if (eventListener$) {
      const chart = dc
        .dataTable(D3_CHART_ELEM_SELECTOR)
        .dimension(results[0].dimension);
      dc.registerChart(chart);

      chart.on('preRedraw', () =>
        rows$.next(this._buildRows(results, config, group))
      );
      chart.on('renderlet', () =>
        rows$.next(this._buildRows(results, config, group))
      );
    }

    return new ObservableDataSource(rows$);
  }

  getColumns(
    _results: IDCTransformResult[],
    config: IChartConfig
  ): WithId<{ id: string; label: string }>[] {
    return config.builderData.measures.map((measure) => ({
      id: measure.measure.metadata.id,
      uid: measure.uid,
      label: measure.label ?? measure.measure.metadata.label,
    }));
  }

  joinTableData(allDataPoints: IGroupedDataPoint[]): ITableRow[] {
    const groups = sortBy(
      toPairs(groupBy(allDataPoints, (dataPoint) => dataPoint.groupKey)),
      ([group]) => group
    );
    return groups.map(([groupKey, dataPoints]) =>
      dataPoints.reduce(
        (row: ITableRow, dataPoint: IGroupedDataPoint) => {
          return {
            ...row,
            [dataPoint.measureKey]: dataPoint.value,
          };
        },
        { [TABLE_GROUP_KEY]: groupKey }
      )
    );
  }

  private _buildRows(
    results: IDCTransformResult[],
    config: IChartConfig,
    _group: ICanGroupMeasuresProperty
  ): ITableRow[] {
    const measureColumns: IGroupedDataPoint[] = results
      .map((result) => {
        const measure = config.builderData.measures.find(
          (builderMeasure) => builderMeasure.uid === result.measureUid
        );
        const measureKey = measure?.label ?? result.measure.metadata.label;
        return this._getGroupedData(config, result, measure).map((data) => ({
          groupKey: String(get(data, 'key.label', get(data, 'key', ''))),
          measureKey,
          value: data.value.valueOf(),
        }));
      })
      .flat();

    return this.joinTableData(measureColumns);
  }

  private _getGroupedData(
    config: IChartConfig,
    result: IDCTransformResult,
    measure?: IMeasureBuilderData
  ): readonly Grouping<NaturallyOrderedValue, NaturallyOrderedValue>[] {
    const filteredResult = ChartBuildHelpers.buildFilteredDimension(
      result,
      config,
      measure
    );
    return filteredResult.group?.all() ?? [];
  }
}
