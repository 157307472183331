<div
  fxFlex
  fxLayout="row"
  fxLayoutAlign="center center"
  *ngIf="store.isLoading$ | async; else showPage"
>
  <mat-spinner mode="indeterminate" />
</div>

<ng-template #showPage>
  <ng-container *ngIf="store.selectedSummary$ | async as result; else selector">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
      class="layout-padding"
    >
      <button mat-stroked-button (click)="store.clearSelectedSummary()">
        Back
      </button>
      <h2>{{ result.practitioner.name }}</h2>
    </div>

    <pr-practitioner-transactions-report-drilldown
      [result]="result.transactions"
      [dateRange]="store.dateRange$ | async"
    />

    <div>
      <pr-account-credits-report-table
        title="Practitioner Reserved Account Credits"
        tooltip="Reserved Account Credits for {{ result.practitioner.name }}"
        [records]="result.accountCredits.records"
        [dateRange]="store.dateRange$ | async"
      />
    </div>
  </ng-container>

  <ng-template #selector>
    <pr-practitioner-transactions-report-summary
      [summaries]="store.summaries$ | async"
      [dateRange]="store.dateRange$ | async"
    />
  </ng-template>

  <div class="layout-margin mat-caption">
    * List of transactions
    <strong>created</strong>
    within the selected date range.
  </div>
</ng-template>
