import {
  SourceEntityMigrationType,
  type ISourceEntity,
} from '@principle-theorem/principle-core/interfaces';
import {
  ISO_DATE_TIME_FORMAT,
  Timestamp,
  TypeGuard,
  toFloat,
  toTimestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { flow, isBoolean, isNumber, isString } from 'lodash';
import * as moment from 'moment-timezone';
import { BaseSourceEntity } from '../../../source/base-source-entity';
import { SourceEntity } from '../../../source/source-entity';
import {
  convertKeysToCamelCaseFn,
  convertNullToUndefinedFn,
  convertValueFn,
} from '../../../source/source-helpers';

export const PATIENT_CREDIT_NOTE_RESOURCE_TYPE = 'patientCreditNotes';

export const PATIENT_CREDIT_NOTE_SOURCE_ENTITY: ISourceEntity =
  SourceEntity.init({
    metadata: {
      label: 'Patient Credit Note List',
      description: '',
      idPrefix: PATIENT_CREDIT_NOTE_RESOURCE_TYPE,
      migrationType: SourceEntityMigrationType.Automatic,
    },
  });

export interface ICorePracticePatientCreditNote {
  id: number;
  creditNoteDate: string; // 2023-04-29 03:08:16.963
  note?: string;
  total: number; // 1700.0000
  paid: number; // .0000
  isPaid: boolean;
  isVoided: boolean;
  isDeleted: boolean;
  locationId: number;
  providerId: number;
  patientId: number;
  payeeId: number;
  totalTax: number; // .0000
  isTaxInclusive: boolean;
}

export function isCorePracticePatientCreditNote(
  item: unknown
): item is ICorePracticePatientCreditNote {
  return TypeGuard.interface<ICorePracticePatientCreditNote>({
    id: isNumber,
    creditNoteDate: isString,
    note: TypeGuard.nilOr(isString),
    total: isNumber,
    paid: isNumber,
    isPaid: isBoolean,
    isVoided: isBoolean,
    isDeleted: isBoolean,
    locationId: isNumber,
    providerId: isNumber,
    patientId: isNumber,
    payeeId: isNumber,
    totalTax: isNumber,
    isTaxInclusive: isBoolean,
  })(item);
}

export interface ICorePracticePatientCreditNoteTranslations {
  creditNoteDate: Timestamp;
}

export interface ICorePracticePatientCreditNoteFilters {
  locationId: number;
  providerId: number;
  patientId: number;
  payeeId: number;
}

const PATIENT_CREDIT_NOTE_SOURCE_QUERY = `
SELECT
  CreditNoteId AS id,
  CreditNoteDate AS credit_note_date,
  Note AS note,
  convert_to_decimal(Total) AS total,
  convert_to_decimal(Paid) AS paid,
  convert_to_boolean(IsPaid) AS is_paid,
  convert_to_boolean(IsVoided) AS is_voided,
  convert_to_boolean(IsDeleted) AS is_deleted,
  LocationId AS location_id,
  ProviderId AS provider_id,
  PatientId AS patient_id,
  PayeeId AS payee_id,
  convert_to_decimal(TotalTax) AS total_tax,
  convert_to_boolean(IsTaxInclusive) AS is_tax_inclusive
FROM tblCreditNote
`;

export class PatientCreditNoteSourceEntity extends BaseSourceEntity<
  ICorePracticePatientCreditNote,
  ICorePracticePatientCreditNoteTranslations,
  ICorePracticePatientCreditNoteFilters
> {
  sourceEntity = PATIENT_CREDIT_NOTE_SOURCE_ENTITY;
  entityResourceType = PATIENT_CREDIT_NOTE_RESOURCE_TYPE;
  sourceQuery = PATIENT_CREDIT_NOTE_SOURCE_QUERY;
  verifySourceFn = isCorePracticePatientCreditNote;

  override transformDataFn = flow([
    convertKeysToCamelCaseFn(),
    convertNullToUndefinedFn(),
    convertValueFn(toFloat, 'total', 'paid', 'totalTax'),
  ]);

  translate(
    data: ICorePracticePatientCreditNote,
    _timezone: Timezone
  ): ICorePracticePatientCreditNoteTranslations {
    return {
      creditNoteDate: toTimestamp(
        moment.utc(data.creditNoteDate, ISO_DATE_TIME_FORMAT)
      ),
    };
  }

  getSourceRecordId(data: ICorePracticePatientCreditNote): number {
    return data.id;
  }

  getSourceLabel(data: ICorePracticePatientCreditNote): string {
    return data.id.toString();
  }

  getFilterData(
    data: ICorePracticePatientCreditNote,
    _timezone: Timezone
  ): ICorePracticePatientCreditNoteFilters {
    return {
      locationId: data.locationId,
      providerId: data.providerId,
      patientId: data.patientId,
      payeeId: data.payeeId,
    };
  }
}
