<form class="appointment-selector-form" [formGroup]="form">
  <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px">
    <mat-form-field fxFlex>
      <mat-label>Date From</mat-label>
      <input
        matInput
        formControlName="fromDate"
        [matDatepicker]="dateFrom"
        [min]="now"
        (focus)="dateFrom.open()"
        (click)="dateFrom.open()"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="dateFrom"
       />
      <mat-datepicker #dateFrom />
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>Date To (optional)</mat-label>
      <input
        matInput
        formControlName="toDate"
        [matDatepicker]="dateTo"
        [min]="form.controls.fromDate.value"
        (focus)="dateTo.open()"
        (click)="dateTo.open()"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="dateTo"
       />
      <mat-datepicker #dateTo />
    </mat-form-field>
  </div>
  <ng-container *ngIf="showTimeFilters">
    <div fxLayout="column" fxLayoutGap="16px" fxLayout.gt-sm="row">
      <pr-date-selector
        fxFlex="50"
        placeholder="Time From"
        [interval]="timeInterval"
        [timeFrom]="openTime$ | async"
        [timeTo]="closeTime$ | async"
        [formControl]="form.controls.fromTime"
       />
      <pr-date-selector
        fxFlex="50"
        placeholder="Time To"
        [interval]="timeInterval"
        [timeFrom]="openTime$ | async"
        [timeTo]="closeTime$ | async"
        [formControl]="form.controls.toTime"
       />
    </div>
  </ng-container>
</form>
