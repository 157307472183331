import { isObject } from '@principle-theorem/shared';
import { isString } from 'lodash';

export interface IDefaultOption {
  name: string;
}

const DEFAULT_OPTION_NAME = 'Any';

export const ANY_OPTION: IDefaultOption = { name: DEFAULT_OPTION_NAME };

export function isDefaultOption(data: unknown): data is IDefaultOption {
  return (
    isObject(data) && isString(data.name) && data.name === DEFAULT_OPTION_NAME
  );
}
