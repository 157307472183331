import { Injectable, inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { type IPrincipleState } from '@principle-theorem/ng-principle-shared';
import {
  CalendarEventSummary,
  IScheduleSummaryEvent,
  IScheduleSummaryEventable,
  isCalendarEventType,
  type IAppointment,
  type ICalendarEvent,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  isWithRef,
  serialise,
  shareReplayCold,
  unserialise$,
  type WithRef,
} from '@principle-theorem/shared';
import { type Moment } from 'moment-timezone';
import { Subject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CalendarActions,
  CalendarCollectionActions,
  CalendarEventsActions,
} from '../actions';
import { type ICalendarPartialState } from '../calendar.reducer';
import * as CalendarSelectors from '../calendar.selectors';
import { type CalendarEventEntity } from '../models';
import {
  filterEventsIncludedInDay$,
  filterEventsStartEndOnDay$,
  filterToFullDayEvents$,
  filterToPartialDayEvents$,
} from '../models/calendar-events-filtering';

@Injectable()
export class CalendarEventsFacade {
  private _store = inject(Store<ICalendarPartialState & IPrincipleState>);
  private _calendarEventEntities$: Observable<CalendarEventEntity[]>;
  unsubscribeCalendarEvents$: Subject<void> = new Subject();
  allEvents$: Observable<CalendarEventEntity[]>;
  appointments$: Observable<IScheduleSummaryEvent<IAppointment>[]>;
  calendarEvents$: Observable<CalendarEventSummary[]>;
  selectedCalendarEvent$: Observable<CalendarEventEntity | undefined>;

  constructor() {
    this._calendarEventEntities$ = this._store.pipe(
      select(CalendarSelectors.getCalendarEvents),
      unserialise$()
    );
    this.allEvents$ = this._store.pipe(
      select(CalendarSelectors.getAllCalendarEvents),
      unserialise$(),
      shareReplayCold()
    );
    this.appointments$ = this._store.pipe(
      select(CalendarSelectors.getAppointmentEvents),
      unserialise$(),
      map((apppointments) =>
        apppointments.filter((apppointment) =>
          isWithRef<IAppointment>(apppointment)
        )
      ),
      map(
        (appointments) => appointments as IScheduleSummaryEvent<IAppointment>[]
      ),
      shareReplayCold()
    );
    this.calendarEvents$ = this._store.pipe(
      select(CalendarSelectors.getCalendarEvents),
      unserialise$(),
      map((events) =>
        events.filter((event) => isCalendarEventType(event.event.type))
      ),
      map((events) => events as CalendarEventSummary[]),
      shareReplayCold()
    );
    this.selectedCalendarEvent$ = this._store.pipe(
      select(CalendarSelectors.getSelectedCalendarEvent),
      unserialise$()
    );
  }

  getEventsByParticipant$(
    participant: WithRef<IStaffer>
  ): Observable<CalendarEventEntity[]> {
    return this._store.pipe(
      select(CalendarSelectors.getEventsByParticipant(participant.ref)),
      unserialise$()
    );
  }

  unsubscribeEvents(): void {
    this.unsubscribeCalendarEvents$.next();
  }

  addDashboardEvents(calendarEvents: CalendarEventEntity[]): void {
    this._dispatch(
      CalendarCollectionActions.loadCalendarEventsSuccess(
        serialise({ calendarEvents })
      )
    );
  }

  loadCalendarEvents(): void {
    this._dispatch(CalendarEventsActions.loadCalendarEvents());
  }

  selectEvent(id: string): void {
    this._dispatch(CalendarEventsActions.selectCalendarEvent({ id }));
  }

  saveEvent(event: ICalendarEvent): void {
    const calendarEvent = serialise(event);
    this._dispatch(CalendarEventsActions.saveCalendarEvent({ calendarEvent }));
  }

  deleteEvent(id: string): void {
    if (id) {
      this.selectEvent(id);
    }
    this._dispatch(CalendarEventsActions.deleteCalendarEvent());
  }

  getDayHeaderEvents$(day: Moment): Observable<CalendarEventEntity[]> {
    return this._calendarEventEntities$.pipe(
      filterToFullDayEvents$(),
      filterEventsIncludedInDay$(day)
    );
  }

  getDayTimeEvents$(
    day: Moment
  ): Observable<CalendarEventEntity[] | IScheduleSummaryEventable[]> {
    return this._calendarEventEntities$.pipe(
      filterToPartialDayEvents$(),
      filterEventsStartEndOnDay$(day)
    );
  }

  getCalendarEventsAtDay$(day: Moment): Observable<CalendarEventEntity[]> {
    return this._calendarEventEntities$.pipe(filterEventsIncludedInDay$(day));
  }

  reset(): void {
    this._dispatch(CalendarActions.resetCalendar());
  }

  private _dispatch(action: Action): void {
    this._store.dispatch(action);
  }
}
