import { Validators } from '@angular/forms';
import {
  initVersionedSchema,
  type MixedSchema,
} from '@principle-theorem/editor';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';
import { type IFollowUpFormData } from '@principle-theorem/principle-core/interfaces';
import * as moment from 'moment-timezone';

export class FollowUpFormGroup extends TypedFormGroup<IFollowUpFormData> {
  constructor() {
    super({
      createFollowUp: new TypedFormControl<boolean>(true),
      followUpDate: new TypedFormControl<moment.Moment>(
        moment().add(1, 'month'),
        Validators.required
      ).withGuard(moment.isMoment),
      noFollowUpReason: new TypedFormControl<MixedSchema>(
        initVersionedSchema()
      ),
    });
  }
}
