import {
  GapAction,
  GapStatus,
  MeasureFormatter,
} from '@principle-theorem/principle-core/interfaces';
import { IGapEventFact } from '@principle-theorem/reporting/interfaces';
import { BigQueryTable } from '../../big-query-tables';
import {
  BrandQueryScopeConfig,
  DateRangeQueryScopeConfig,
  PracticeQueryScopeConfig,
} from '../../query-scope-config/query-scope-configs';
import { GroupBy } from '../../querying';
import { BaseFactMeasures, latestEventId } from '../base-measures';
import { getProperty, MeasurePath } from '../data-accessor-factory';
import { BrandDimensionMeasures } from '../dimensions/brand-dimension';
import { ModelEventDimensionMeasures } from '../dimensions/model-event-dimension';
import { PracticeDimensionMeasures } from '../dimensions/practice-dimension';
import { StafferDimensionMeasures } from '../dimensions/staffer-dimension';
import { ValueDoesNotEqualMeasureFilter } from '../measure-filters';
import {
  ComparableProperties,
  MeasureTransformMap,
  noFilterAccessor,
} from '../measure-properties';
import { CanBeChartedProperty } from '../measure-properties';
import { MeasurePropertyFactory } from '../measure-property-factory';
import { QueryFactory } from '../query-factory';

export class GapEventFactMeasures
  extends BaseFactMeasures
  implements MeasureTransformMap<ComparableProperties<IGapEventFact>>
{
  id = 'gapEvent';
  readonly table = BigQueryTable.GapEvent;
  readonly name = 'Gaps';
  scopes = [
    new BrandQueryScopeConfig(),
    new PracticeQueryScopeConfig(),
    new DateRangeQueryScopeConfig(),
  ];

  get practitioner(): StafferDimensionMeasures {
    return new StafferDimensionMeasures(BigQueryTable.Staff, 'practitioner', {
      sourceJoinKey: MeasurePath.docRef('practitioner.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get practice(): PracticeDimensionMeasures {
    return new PracticeDimensionMeasures(BigQueryTable.Practices, 'practice', {
      sourceJoinKey: MeasurePath.docRef('practice.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get brand(): BrandDimensionMeasures {
    return new BrandDimensionMeasures(BigQueryTable.Brands, 'brand', {
      sourceJoinKey: MeasurePath.docRef('brand.ref'),
      orderByProperty: MeasurePath.timestamp('updatedAt'),
    });
  }

  get event(): ModelEventDimensionMeasures<GapAction, GapStatus> {
    return new ModelEventDimensionMeasures(this.table, 'event', 'Gap');
  }

  get latestEvent(): GapEventFactMeasures {
    const measures = new GapEventFactMeasures();
    measures.id = latestEventId(measures);
    measures._query = QueryFactory.fromTable(measures.table)
      .override(measures._query)
      .latestEvent(measures.table, GroupBy.Gap)
      .get();
    return measures;
  }

  get duration(): CanBeChartedProperty {
    const propertyName = 'duration';
    const measure = this.measureRef(propertyName);
    return MeasurePropertyFactory.number(
      {
        id: 'duration',
        label: 'Duration',
        summary: '',
        formatter: MeasureFormatter.Minutes,
      },
      measure,
      this.buildQuery().attributes([measure.attributePath]).get()
    );
  }

  get timeUnused(): CanBeChartedProperty {
    const latestEvent: GapEventFactMeasures = this.latestEvent;
    const propertyName = 'duration';
    const measure = this.measureRef(propertyName);
    return new CanBeChartedProperty({
      metadata: {
        id: 'timeUnused',
        label: 'Time Unused in Hours',
        summary: '',
        formatter: MeasureFormatter.Minutes,
      },
      measure: {
        propertyName,
        query: latestEvent
          .buildQuery()
          .attributes([measure.attributePath])
          .get(),
        dataAccessor: (fact) =>
          getProperty(fact, measure.factPropertyPath, 0) / 60 || 0,
        filterAccessor: noFilterAccessor(),
      },
    }).filterBy(
      new ValueDoesNotEqualMeasureFilter([GapAction.Filled]),
      this.event.action.measure
    );
  }
}
