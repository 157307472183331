<ng-container>
  <pr-appointment-conflicts-sidebar
    [appointment]="appointment$ | ngrxPush"
    [patient]="patient$ | ngrxPush"
  />
  <pr-appointment-scheduling-rules-conflict-sidebar
    [appointment]="appointment$ | ngrxPush"
  />
  <pr-appointment-duration-warning-sidebar
    [appointment]="appointment$ | ngrxPush"
  />
</ng-container>
