import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
} from '@angular/core';
import {
  ChartFacade,
  ChartId,
  PerioChartFacade,
} from '@principle-theorem/ng-clinical-charting/store';
import {
  recordsForTeeth,
  transformPerioRecords,
  type IPerioTable,
  type IPerioTableCell,
} from '@principle-theorem/ng-perio-charting';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type ChartSection,
  type ChartView,
  type IPerioRecord,
  type ITooth,
} from '@principle-theorem/principle-core/interfaces';
import { isChanged$ } from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import { Subject, combineLatest, type Observable } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { ChartViewFactory } from '../dental-chart-svg/models/chart-view-factory';

@Component({
  selector: 'pr-perio-chart-ui',
  templateUrl: './perio-chart-ui.component.html',
  styleUrls: ['./perio-chart-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerioChartUiComponent implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  perioTables$: Observable<IPerioTable[]>;
  trackByLabel = TrackByFunctions.label<IPerioTable>();
  @Input() showHistorySelector = true;

  constructor(
    private _perio: PerioChartFacade,
    private _chart: ChartFacade
  ) {
    this.perioTables$ = this._perio.perioTables$;
    const chart$ = this._chart.clinicalChartState$(ChartId.InAppointment);

    combineLatest([
      chart$.pipe(
        map((chart) => chart.teeth),
        distinctUntilChanged<ITooth[]>(isEqual)
      ),
      chart$.pipe(
        map((chart) => chart.immutable),
        distinctUntilChanged<boolean>(isEqual)
      ),
      chart$.pipe(
        isChanged$((a, b) => a.createdAt.isEqual(b.createdAt)),
        map((chart) => chart.perioRecords)
      ),
      this._chart.chartView$(ChartId.InAppointment),
      this._chart.chartSection$(ChartId.InAppointment),
    ])
      .pipe(
        map(([teeth, immutable, perioRecords, view, section]) =>
          toPerioTables(teeth, immutable, view, section, perioRecords)
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((tables) => this._perio.setPerioTables(tables));
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  handleChange(change: IPerioTableCell): void {
    this._perio.upsertPerioValue(change);
  }
}

function toPerioTables(
  teeth: ITooth[],
  immutable: boolean,
  view: ChartView,
  section: ChartSection,
  perioRecords: IPerioRecord[] = []
): IPerioTable[] {
  return new ChartViewFactory(teeth)
    .filterSection(section)
    .filterView(view)
    .byArch()
    .map((arch) => ({
      label: arch.label,
      records: recordsForTeeth(perioRecords, arch.teeth),
    }))
    .filter((table) => table.records.length >= 1)
    .map((table) => ({
      label: table.label,
      disabled: immutable,
      rows: transformPerioRecords(table.records),
    }));
}
