import { Brand } from '@principle-theorem/principle-core';
import {
  CustomMappingOption,
  type ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  type INamedDocument,
  type IReffable,
  isEnumValue,
  multiMap,
  multiSortBy$,
  nameSorter,
  snapshot,
  toNamedDocument,
  type WithRef,
  XSLXImporterExporter,
} from '@principle-theorem/shared';
import { sortBy, uniqBy } from 'lodash';
import { type Observable, of } from 'rxjs';
import { BaseCustomMappingHandler } from '../../../base-custom-mapping-handler';
import { CustomMapping } from '../../../custom-mapping';
import {
  CustomMappingAssociatedValueType,
  CustomMappingType,
  type ICustomMapping,
  type ICustomMappingSourceOption,
  type IPracticeMigration,
} from '@principle-theorem/principle-core/interfaces';
import { PracticeMigration } from '../../../practice-migrations';
import { TranslationMapHandler } from '../../../translation-map';
import {
  AppointmentStatusTypeSourceEntity,
  type ID4WAppointmentStatusType,
} from '../../source/entities/appointment-status-type';
import { AppointmentStatusesToXSLX } from './appointment-statuses-to-xlsx';
import { XSLXToAppointmentStatuses } from './xlsx-to-appointment-statuses';

export const APPOINTMENT_STATUS_CUSTOM_MAPPING_TYPE =
  'appointmentStatusMapping';

export enum AppointmentStatusMapType {
  AddAppointmentTag = 'Add Appointment Tag',
  CancelAppointment = 'Cancel Appointment',
  ConfirmAppointment = 'Confirm Appointment',
  Omit = 'Omit',
}

export const APPOINTMENT_STATUS_MAPPING: ICustomMapping = CustomMapping.init({
  metadata: {
    label: 'Appointment Statuses',
    description: `Used for mapping appointment statuses to Principle.

    Appointment statuses in D4W can represent many things whereas the appointment status in Principle is purely tied to the transitioning of the appointment event.

    For this reason, we need to know which appointment statuses actually effect the appointment, as well as what appointment statuses would act as a tag on the appointment.`,
    type: APPOINTMENT_STATUS_CUSTOM_MAPPING_TYPE,
  },
  type: CustomMappingType.SelectionList,
});

export class D4WAppointmentStatusMappingHandler extends BaseCustomMappingHandler<
  object,
  AppointmentStatusMapType,
  INamedDocument
> {
  customMapping = APPOINTMENT_STATUS_MAPPING;

  async getSourceOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ICustomMappingSourceOption[]> {
    const appointmentStatusOptions = new AppointmentStatusTypeSourceEntity();
    const records = await appointmentStatusOptions
      .getRecords$(migration, 1000)
      .toPromise();
    return sortBy(
      records
        .map((record) => record.data.data)
        .map((record) => ({
          label: record.description ?? '',
          value: record.abbreviation,
        })),
      'value'
    );
  }

  selectedOptionRequiresValue(
    destinationValue: AppointmentStatusMapType
  ): boolean {
    if (destinationValue !== AppointmentStatusMapType.AddAppointmentTag) {
      return false;
    }

    return true;
  }

  async getSelectionListOptions(
    _migration: WithRef<IPracticeMigration>
  ): Promise<CustomMappingOption[]> {
    return snapshot(
      of([
        {
          value: AppointmentStatusMapType.AddAppointmentTag,
          description: '',
          hasAssociatedValue: true,
          associatedValueType: CustomMappingAssociatedValueType.NamedDocument,
          associatedValueDescription:
            'Which tag should be used for this status?',
        },
        {
          value: AppointmentStatusMapType.CancelAppointment,
          description:
            'Set the appointment as cancelled if the last status matches',
          hasAssociatedValue: false,
        },
        {
          value: AppointmentStatusMapType.ConfirmAppointment,
          description:
            'Set the appointment as confirmed if the last status matches',
          hasAssociatedValue: false,
        },
        {
          value: AppointmentStatusMapType.Omit,
          description: `Don't include this appointment status`,
          hasAssociatedValue: false,
        },
      ])
    );
  }

  getAssociatedValueOptions$(
    migration: IPracticeMigration,
    destinationValue: AppointmentStatusMapType
  ): Observable<INamedDocument[]> {
    if (destinationValue !== AppointmentStatusMapType.AddAppointmentTag) {
      return of([]);
    }
    return Brand.appointmentTags$(migration.configuration.brand).pipe(
      multiMap(toNamedDocument),
      multiSortBy$(nameSorter())
    );
  }

  async downloadMapping(migration: WithRef<IPracticeMigration>): Promise<void> {
    const fileName = `appointment-status-mapping`;
    const appointmentStatuses =
      await this._getAppointmentStatusOptions(migration);
    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    await new XSLXImporterExporter().download(
      fileName,
      appointmentStatuses,
      new AppointmentStatusesToXSLX(
        await snapshot(Brand.appointmentTags$(migration.configuration.brand)),
        await snapshot(this.getRecords$(translationMap))
      )
    );
  }

  async uploadMapping(
    migration: WithRef<IPracticeMigration>,
    file: File
  ): Promise<void> {
    const items = await new XSLXImporterExporter().parse(
      file,
      new XSLXToAppointmentStatuses()
    );

    const translationMap = new TranslationMapHandler(
      PracticeMigration.translationMapCol(migration)
    );

    const sourceOptions = await this.getSourceOptions(migration);
    const appointmentTags = await snapshot(
      Brand.appointmentTags$(migration.configuration.brand)
    );

    await asyncForEach(items, async (item) => {
      const matchingOption = sourceOptions.find(
        (sourceOption) => sourceOption.value === item.abbreviation
      );

      if (!matchingOption) {
        return;
      }

      const label = matchingOption.label;
      const value = matchingOption.value;
      const mapTo = item.mapTo || AppointmentStatusMapType.Omit;

      let tag: WithRef<ITag> | undefined;

      if (!isEnumValue(AppointmentStatusMapType, mapTo)) {
        const matches = new RegExp(/^tag - (.*)$/).exec(mapTo);
        const tagName = matches ? matches[1] : undefined;

        if (!tagName) {
          return;
        }

        tag = appointmentTags.find(
          (appointmentTag) => appointmentTag.name === tagName
        );

        if (!tag) {
          return;
        }
      }

      await this.upsertRecord(
        {
          associatedValue: tag ? toNamedDocument(tag) : undefined,
          destinationValue: isEnumValue(AppointmentStatusMapType, mapTo)
            ? mapTo
            : AppointmentStatusMapType.AddAppointmentTag,
          sourceIdentifier: value,
          sourceLabel: label,
        },
        translationMap
      );
    });
  }

  private async _getAppointmentStatusOptions(
    migration: IReffable<IPracticeMigration>
  ): Promise<ID4WAppointmentStatusType[]> {
    const appointmentStatusOptions = new AppointmentStatusTypeSourceEntity();
    const records = await appointmentStatusOptions
      .getRecords$(migration, 10000)
      .toPromise();
    return sortBy(
      uniqBy(records, (record) => record.data.data.id).map(
        (record) => record.data.data
      ),
      'description'
    );
  }
}
