<div
  (click)="$event.stopPropagation()"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="8px"
>
  <span>
    {{ label }}
    <mat-icon *ngIf="tooltip" [matTooltip]="tooltip">info</mat-icon>
  </span>

  <mat-form-field subscriptSizing="dynamic">
    <mat-label>{{ label }}</mat-label>
    <mat-select [formControl]="formCtrl" multiple>
      <mat-option
        *ngFor="let option of options$ | async; trackBy: trackByOption"
        [value]="option"
        >{{
          option | map : optionToLabel : this | splitCamel | titlecase
        }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
