<div class="flex flex-col gap-2">
  <h3 class="!m-0 !font-bold">Add & Remove Elements</h3>
  <div class="flex flex-col gap-1">
    <div
      class="flex flex-row flex-wrap gap-2"
      cdkDropList
      cdkDropListSortingDisabled
      [id]="dropListIds.add"
      [cdkDropListConnectedTo]="formBuilder.dropListIds$ | async"
      [cdkDropListEnterPredicate]="denyAll"
    >
      @for (element of toolbarElements; track element.elementType) {
        <div
          cdkDrag
          [cdkDragData]="element.elementType"
          (cdkDragStarted)="formBuilder.dragging$.next(true)"
          (cdkDragEnded)="formBuilder.dragging$.next(false)"
        >
          <div *cdkDragPreview>
            <pr-form-builder-element-preview
              class="flex min-h-[50px] min-w-[300px]"
              [element]="element.preview"
            />
          </div>
          <div
            *cdkDragPlaceholder
            class="border-primary-200 bg-primary-50 h-16 w-full rounded border border-solid"
          ></div>

          <pr-form-builder-thumbnail
            [elementType]="element.elementType"
            [hoverEnabled]="true"
            [matTooltip]="element.tooltip"
          />
        </div>
      }
    </div>
  </div>

  <div>
    <div
      class="delete-drop-area flex h-16 select-none flex-col items-center justify-center rounded border-2 border-solid border-red-100 bg-red-50 text-red-300"
      cdkDropList
      [id]="dropListIds.remove"
      [cdkDropListConnectedTo]="formBuilder.dropListIds$ | async"
      [cdkDropListEnterPredicate]="onlyAllowLayoutComponents"
      (cdkDropListDropped)="dropDelete($event)"
    >
      <div class="text-xs font-bold">Drag elements here to remove them</div>
    </div>
  </div>
</div>
