<pt-feature-info />
<pt-breadcrumbs [breadcrumbs]="breadcrumbs$ | async" class="bg-white" />
<div class="border-b border-solid border-slate-300"></div>

<div fxLayout="column" fxLayoutGap="32px" class="wrapper">
  <h1 class="mat-headline-4">Tags</h1>

  <div class="content-card">
    <h3 class="mat-headline-6">Calendar Event Tags</h3>
    <pr-calendar-event-tags [brand]="brand$ | async" />
  </div>

  <div class="content-card">
    <h3 class="mat-headline-6">Appointment Tags</h3>
    <pr-brand-appointment-tags [brand]="brand$ | async" />
  </div>

  <div class="content-card">
    <h3 class="mat-headline-6">Patient Tags</h3>
    <pr-patient-tags [brand]="brand$ | async" />
  </div>

  <div class="content-card">
    <h3 class="mat-headline-6">Note Tags</h3>
    <pr-patient-note-tags [brand]="brand$ | async" />
  </div>
</div>
