import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ScrollStrategy } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Optional,
  Self,
  inject,
  type AfterViewInit,
  type OnDestroy,
} from '@angular/core';
import {
  FormGroupDirective,
  NgControl,
  type ControlValueAccessor,
} from '@angular/forms';
import {
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import {
  Content,
  DEFAULT_INLINE_EXTENSIONS,
  MixedSchema,
} from '@principle-theorem/editor';
import { CONNECTED_DIALOG_SCROLL_STRATEGY } from '@principle-theorem/ng-core';
import {
  BasicDialogService,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import { snapshot } from '@principle-theorem/shared';
import { AnyExtension, Editor } from '@tiptap/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditorBloc } from '../editor-bloc';
import { createLinkMenu } from '../extensions/link/link-menu';
import {
  ActivateType,
  ISelectMenuData,
  selectMenuPlugin,
} from '../menu-bar/select-menu/select-menu-view';

@Component({
  selector: 'pt-editor-input',
  templateUrl: './editor-input.component.html',
  styleUrls: ['./editor-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MatFormFieldControl, useExisting: EditorInputComponent },
  ],
})
export class EditorInputComponent
  implements
    MatFormFieldControl<MixedSchema>,
    ControlValueAccessor,
    AfterViewInit,
    OnDestroy
{
  static nextId = 0;

  private _empty = true;
  private _required = false;
  private _disabled = false;
  private _placeholder = '';
  private _onDestroy$: Subject<void> = new Subject();
  private _document: Document = inject(DOCUMENT);

  editorCtrl = new TypedFormControl<Content | MixedSchema>();
  controlType = 'editor-input';
  stateChanges: Subject<void> = new Subject();
  editorBloc: EditorBloc;
  focused = false;
  errorState = false;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;

  @HostBinding()
  id = `editor-input-${EditorInputComponent.nextId++}`;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private _cdr: ChangeDetectorRef,
    private _dialog: BasicDialogService,
    @Inject(CONNECTED_DIALOG_SCROLL_STRATEGY)
    private _scrollStrategy: () => ScrollStrategy,
    @Optional() public parentFormField?: MatFormField,
    @Optional() private _parentFormGroup?: FormGroupDirective
  ) {
    this.editorBloc = new EditorBloc(true);

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this._focusMonitor
      .monitor(this._elementRef.nativeElement, true)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((origin) => {
        this.focused = !!origin;
        this.stateChanges.next();
      });

    this.editorBloc.editorHasContent$
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(
        (editorHasContent) => (this._empty = editorHasContent ? false : true)
      );
  }

  set value(content: MixedSchema | null) {
    if (content) {
      this.editorBloc.content$.next(content);
    }
    this.stateChanges.next();
  }

  @HostListener('keydown.enter')
  submit(): void {
    this._parentFormGroup?.ngSubmit.emit();
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  // eslint-disable-next-line no-empty,@typescript-eslint/no-empty-function
  onChange: (_: unknown) => void = (_: unknown) => {};

  // eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
  onTouched = (): void => {};

  async ngAfterViewInit(): Promise<void> {
    this._cdr.detectChanges();
    const editor = await snapshot(this.editorBloc.editor$);

    this._registerMenuPlugin(editor, createLinkMenu(), 'focus');
  }

  writeValue(content: MixedSchema): void {
    this.editorBloc.content$.next(content);
    this.stateChanges.next();
    this._cdr.detectChanges();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  @Input()
  set extensions(extensions: AnyExtension[]) {
    if (extensions) {
      this.editorBloc.extensions$.next([
        ...DEFAULT_INLINE_EXTENSIONS,
        ...extensions,
      ]);
    }
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get empty(): boolean {
    return this._empty;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef.nativeElement);
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector(
      `.${this.controlType}-container`
    );
    if (!controlElement) {
      return;
    }
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_event: MouseEvent): void {
    // We don't need to do anything here as it's handled by Prosemirror
  }

  private _registerMenuPlugin(
    editor: Editor,
    component: ISelectMenuData,
    activateType: ActivateType
  ): void {
    editor.registerPlugin(
      selectMenuPlugin({
        pluginKey: component.pluginKey,
        editor,
        element: this._document.createElement('span'),
        shouldShowOverride: component.shouldShow,
        nodeType: component.nodeType,
        dialog: this._dialog,
        scrollStrategy: this._scrollStrategy,
        component,
        activateType,
      })
    );
  }
}
