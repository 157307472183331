<div
  class="icon-container"
  fxLayout="row"
  fxLayoutAlign="start center"
  *ngIf="hasIcon"
>
  <mat-icon>{{ icon$ | async }}</mat-icon>
</div>

<div fxFill class="mat-typography">
  <div fxLayout="row">
    <ng-content select="input" />
    <ng-content select="button" />
    <ng-content />
  </div>
</div>
