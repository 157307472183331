import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  type OnDestroy,
} from '@angular/core';
import {
  ChartFacade,
  TreatmentStepsFacade,
} from '@principle-theorem/ng-clinical-charting/store';
import { type IMatSelectOption } from '@principle-theorem/ng-shared';
import {
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import { PatientPermissions } from '@principle-theorem/principle-core/features';
import {
  AppointmentStatus,
  isTreatmentPlan,
  type IPatient,
  type ITreatmentPlan,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  addDoc,
  asyncForEach,
  isSameRef,
  isWithRef,
  patchDoc,
  snapshot,
  type DocumentReference,
  type IGroup,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, uniqBy } from 'lodash';
import { Subject, noop } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';
import {
  ChartableSurfaceUpdater,
  type IEditChartableData,
} from '../../chartable-surface-updater';
import { AddTreatmentToStoreStepProvider } from '../../charted-surface/store-treatment-plan/add-treatment-to-store-step-provider';
import {
  ConsolidatedPlanDragDropGroup,
  type IConsolidatedPlanDragDropNode,
} from '../../consolidated-plan-drag-drop-group';
import { type IDropEvent } from '../../drag-drop-group';
import { StepDragDropGroup } from '../../step-drag-drop-group';
import { TreatmentStepsDisplayService } from '../treatment-steps-editor/treatment-steps-display.service';
import {
  TreatmentPlansConsolidatedStore,
  type ITreatmentPlanStepPair,
} from './treatment-plans-consolidated.store';

@Component({
    selector: 'pr-treatment-plans-consolidated',
    templateUrl: './treatment-plans-consolidated.component.html',
    styleUrls: ['./treatment-plans-consolidated.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreatmentPlansConsolidatedComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  dragDrop = new ConsolidatedPlanDragDropGroup();
  treatmentPlanManagePermission = [PatientPermissions.TreatmentPlanManage];
  @Input() stepDragDrop = new StepDragDropGroup();
  @Output() selectedTreatmentPlan = new EventEmitter<WithRef<ITreatmentPlan>>();

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.store.initialiseStore(patient);
    }
  }

  constructor(
    public store: TreatmentPlansConsolidatedStore,
    public stepDisplay: TreatmentStepsDisplayService,
    private _treatmentStepsFacade: TreatmentStepsFacade,
    private _chartStore: ChartFacade
  ) {
    this.dragDrop.afterDrop$
      .pipe(
        concatMap((event) => this._handleStepDrag(event)),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  dragDisabled(pair: ITreatmentPlanStepPair): boolean {
    if (pair.appointment) {
      return pair.appointment.status !== AppointmentStatus.Unscheduled;
    }
    return !TreatmentStep.isIncomplete(pair.treatmentStep);
  }

  toDragNode(
    group: IGroup<
      IMatSelectOption<ITreatmentPlanStepPair>,
      WithRef<ITreatmentPlan>
    >
  ): IConsolidatedPlanDragDropNode {
    return {
      parent: group.group,
      items: group.items.map((item) => item.value),
    };
  }

  canAddStep(
    group: IGroup<
      IMatSelectOption<ITreatmentPlanStepPair>,
      WithRef<ITreatmentPlan>
    >
  ): boolean {
    return isTreatmentPlan(group.group);
  }

  async addStep(plan: WithRef<ITreatmentPlan>): Promise<void> {
    if (this.stepDisplay.isUnscheduledHidden()) {
      this.stepDisplay.enableUnscheduledFilter();
    }
    await this.store.addStep(plan);
  }

  async updateSurfaces(
    data: IEditChartableData,
    step: WithRef<ITreatmentStep>
  ): Promise<void> {
    const feeSchedule = await snapshot(
      this._chartStore.getFeeScheduleManager().currentSchedule$
    );
    return ChartableSurfaceUpdater.updateTreatmentSurfaces(data, [
      new AddTreatmentToStoreStepProvider(
        this._treatmentStepsFacade,
        step,
        feeSchedule
      ),
    ]);
  }

  private async _handleStepDrag(
    event: IDropEvent<WithRef<ITreatmentPlan>, ITreatmentPlanStepPair>
  ): Promise<void> {
    const movedFromPlan = isTreatmentPlan(event.previous.data.parent);
    const movedToPlan = isTreatmentPlan(event.current.data.parent);
    const [previousPlan, nextPlan] = this._getSourcePlans(event);

    if (previousPlan && !isSameRef(previousPlan, nextPlan)) {
      return;
    }

    const planStepsChangeSource = movedToPlan
      ? event.current.data.items
      : movedFromPlan
        ? event.previous.data.items
        : undefined;

    if (planStepsChangeSource && nextPlan) {
      const updatedSteps = await asyncForEach(
        planStepsChangeSource,
        async (step) =>
          isWithRef(step.treatmentStep)
            ? step.treatmentStep.ref
            : addDoc(
                TreatmentPlan.treatmentStepCol(nextPlan),
                step.treatmentStep
              )
      );

      const combinedStepOrder = this._mergeSteps(nextPlan, updatedSteps);

      await patchDoc(nextPlan.ref, { steps: compact(combinedStepOrder) });
    }
  }

  /**
   * We merge treatment steps with the assumption that incomplete treatment steps - which are the only
   * draggable steps - are at the end of the treatment plan. With this assumption, we can get the last
   * occurence of each treatment step as the new order of steps.
   */
  private _mergeSteps(
    nextPlan: WithRef<ITreatmentPlan>,
    updatedSteps: DocumentReference<ITreatmentStep>[]
  ): DocumentReference<ITreatmentStep>[] {
    const currentStepOrder = nextPlan.steps;
    const combinedSteps = [...currentStepOrder, ...updatedSteps];
    const combinedStepOrder = uniqBy(
      combinedSteps.reverse(),
      (step) => step.path
    ).reverse();
    return combinedStepOrder;
  }

  private _getSourcePlans(
    event: IDropEvent<WithRef<ITreatmentPlan>, ITreatmentPlanStepPair>
  ): [
    WithRef<ITreatmentPlan> | undefined,
    WithRef<ITreatmentPlan> | undefined,
  ] {
    const previousPlan = isTreatmentPlan(event.previous.data.parent)
      ? event.previous.data.parent
      : undefined;
    const nextPlan = isTreatmentPlan(event.current.data.parent)
      ? event.current.data.parent
      : undefined;
    return [previousPlan, nextPlan];
  }
}
