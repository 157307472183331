import { CommonModule } from '@angular/common';
import { type ModuleWithProviders, NgModule } from '@angular/core';
import { type Route, RouterModule } from '@angular/router';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  type ISlackIntegrationConfig,
  NG_SLACK_INTEGRATION_CONFIG,
} from './config';
import { ManageSlackIntegrationComponent } from './manage-slack-integration/manage-slack-integration.component';

export const slackIntegrationRoutes: Route[] = [];

@NgModule({
  imports: [CommonModule, RouterModule, NgMaterialModule],
  declarations: [ManageSlackIntegrationComponent],
  exports: [ManageSlackIntegrationComponent],
})
export class NgSlackIntegrationModule {
  static forRoot(
    config: ISlackIntegrationConfig
  ): ModuleWithProviders<NgSlackIntegrationModule> {
    return {
      ngModule: NgSlackIntegrationModule,
      providers: [
        {
          provide: NG_SLACK_INTEGRATION_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
