<mat-toolbar color="accent">Checklist Item</mat-toolbar>

<form [formGroup]="checklistForm" (ngSubmit)="save()">
  <mat-dialog-content>
    <div fxLayout="column">
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Action</mat-label>
        <mat-select formControlName="action" required>
          <mat-option
            *ngFor="let action of itemActions; trackBy: trackByAction"
            [value]="action"
          >
            {{ action }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Checklist</mat-label>
        <mat-select formControlName="type" [required]="true">
          <mat-option
            *ngFor="let type of checklistTypes; trackBy: trackByChecklistType"
            [value]="type"
          >
            {{ type }} appointment
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Data</mat-label>
        <textarea matInput formControlName="data" rows="7"></textarea>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <pt-buttons-container padding="none">
      <button type="button" mat-stroked-button mat-dialog-close>Cancel</button>
      <button type="submit" mat-flat-button color="primary">Save</button>
    </pt-buttons-container>
  </mat-dialog-actions>
</form>
