<div class="notification rounded-lg drop-shadow-md">
  <pr-content-view [content]="notification.title" />
  <pr-content-view [content]="notification.content" />

  @if (notification.actions) {
    <div class="flex justify-end gap-4">
      @for (action of notification.actions; track action.label) {
        <pt-loader-button
          [color]="action.colour"
          [type]="action.style"
          (clicked)="action.actionFn()"
        >
          {{ action.label }}
        </pt-loader-button>
      }
    </div>
  }
</div>
