import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { type ThemePalette } from '@angular/material/core';
import { Router, Scroll, Event, NavigationEnd } from '@angular/router';
import { ChatListDialogComponent } from '@principle-theorem/ng-chats';
import {
  BasicDialogService,
  BreakpointService,
  DialogPresets,
  SidebarManagerService,
} from '@principle-theorem/ng-shared';
import { SearchComponent } from './search/search.component';
import { SearchService } from './search/search.service';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  IntercomService,
  resolveHelpArticleId,
} from '@principle-theorem/ng-intercom';

@Component({
    selector: 'pr-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ToolbarComponent {
  helpArticleId$: Observable<number | undefined>;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('matBadge') color: ThemePalette;
  @Input() chatCount = 0;
  @Input() notificationsCount = 0;
  @Output() notificationsToggle: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private _router: Router,
    private _dialog: BasicDialogService,
    public intercom: IntercomService,
    public sidebar: SidebarManagerService,
    public searchService: SearchService,
    public breakpoint: BreakpointService
  ) {
    this.helpArticleId$ = this._router.events.pipe(
      filter(this._isScrollEvent),
      map(this._getRouteSegments),
      map(resolveHelpArticleId)
    );
  }

  async logout(): Promise<void> {
    await this._router.navigate(['/login']);
  }

  search(): void {
    this._dialog.open(SearchComponent, DialogPresets.large({ height: '90vh' }));
  }

  async showChats(): Promise<void> {
    await this._dialog
      .open<ChatListDialogComponent>(
        ChatListDialogComponent,
        DialogPresets.flex({
          position: {
            bottom: '20px',
            right: '20px',
            top: '20px',
          },
          width: '400px',
          backdropClass: 'no-backdrop',
          autoFocus: true,
        })
      )
      .afterClosed()
      .toPromise();
  }

  private _isScrollEvent(event: Event): event is Scroll {
    return event instanceof Scroll;
  }

  private _getRouteSegments(event: Scroll): string[] {
    const routerEvent = event.routerEvent;
    const url =
      routerEvent instanceof NavigationEnd
        ? routerEvent.urlAfterRedirects
        : routerEvent.url;
    return url.split('/');
  }
}
