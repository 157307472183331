import { InjectionToken } from '@angular/core';
import { type MedipassAppConfig } from '@principle-theorem/medipass';
import { type ITyroConfig } from '@principle-theorem/ng-tyro';

export interface INgPrincipleConfig {
  production: boolean;
  medipass: MedipassAppConfig;
  tyro: ITyroConfig;
}

export const NG_PRINCIPLE_CONFIG: InjectionToken<INgPrincipleConfig> =
  new InjectionToken('INgPrincipleConfig');
