import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DYNAMIC_COMPONENT_DATA } from '@principle-theorem/ng-shared';
import {
  type IManualExtendedData,
  isManualExtendedData,
} from '@principle-theorem/principle-core/interfaces';
import { type ITransactionExtendedDataDisplayData } from '../../../transactions-display/transaction-display/transaction-display-line/transaction-display-line.component';

@Component({
    selector: 'pr-manual-extended-data',
    templateUrl: './manual-extended-data.component.html',
    styleUrls: ['./manual-extended-data.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ManualExtendedDataComponent {
  transactionType?: string;

  constructor(
    @Inject(DYNAMIC_COMPONENT_DATA)
    public data: ITransactionExtendedDataDisplayData<IManualExtendedData>
  ) {
    this._loadData(data.transaction.extendedData);
  }

  private _loadData(data?: IManualExtendedData): void {
    if (isManualExtendedData(data)) {
      this.transactionType = data.transactionType?.name;
    }
  }
}
