import { versionedSchemaToHtml } from '@principle-theorem/editor';
import { CustomFormElement } from '@principle-theorem/principle-core';
import {
  AnyCustomFormElement,
  AnyFormLayout,
  CustomFormElementType,
  FormLayoutElementType,
  FormSchemaPropertyType,
  ICustomFormContainerOptions,
  ICustomFormContentOptions,
  ICustomFormSectionOptions,
  IFormSchema,
  IFormSchemaProperty,
  IJsonSchemaForm,
} from '@principle-theorem/principle-core/interfaces';
import { isObject } from '@principle-theorem/shared';
import { AnyExtension } from '@tiptap/core';
import { compact, first, mapValues, set, toPairs } from 'lodash';
import { AngularJsonSchemaForm } from './angular-json-schema-form';

interface IFormSchemaPropertyMap {
  [key: string]: IFormSchemaProperty;
}

interface IHasProperties {
  properties: object;
}

export class CustomFormToAJSF {
  static transform(
    elements: AnyCustomFormElement[],
    editorExtensions: AnyExtension[]
  ): IJsonSchemaForm {
    const layout = compact(
      elements.map((element) => this.getLayout(element, editorExtensions))
    );
    const propertyMap = this.mergeSchemaProperties(
      elements.map((element) => this.getSchemaProperties(element))
    );
    const schema = this.propertyMapToSchema(propertyMap);

    return { layout, schema };
  }

  static getLayout(
    element: AnyCustomFormElement,
    editorExtensions: AnyExtension[]
  ): AnyFormLayout | undefined {
    const items = compact(
      element.children.map((child) => this.getLayout(child, editorExtensions))
    );
    switch (element.type) {
      case CustomFormElementType.Container:
        return this.toContainer(element.options, items);
      case CustomFormElementType.Section:
        return this.toSection(element.options, items);
      case CustomFormElementType.Content:
        return {
          type: FormLayoutElementType.EditorContent,
          content: element.options.content,
          htmlClass: 'form-builder-content',
          items,
        };
      case CustomFormElementType.Text:
        return {
          key: element.options.dataKey,
          htmlClass: 'form-builder-text',
          items,
        };
      case CustomFormElementType.Textarea:
        return {
          type: FormLayoutElementType.CustomTextArea,
          key: element.options.dataKey,
          htmlClass: 'form-builder-textarea',
          items,
        };
      case CustomFormElementType.Number:
        return {
          key: element.options.dataKey,
          htmlClass: 'form-builder-number',
          items,
        };
      case CustomFormElementType.Checkbox:
        return {
          key: element.options.dataKey,
          htmlClass: 'form-builder-checkbox',
          items,
        };
      case CustomFormElementType.Date:
        return {
          type: FormLayoutElementType.CustomDate,
          key: element.options.dataKey,
          htmlClass: 'form-builder-date',
          items,
        };
      case CustomFormElementType.Dropdown:
        return {
          key: element.options.dataKey,
          htmlClass: 'form-builder-dropdown',
          items,
        };
      case CustomFormElementType.Signature:
        return {
          type: FormLayoutElementType.Signature,
          key: element.options.dataKey,
          htmlClass: 'form-builder-content',
          items,
        };
      default:
        return undefined;
    }
  }

  static toSection(
    options: ICustomFormSectionOptions,
    items: AnyFormLayout[]
  ): AnyFormLayout {
    const helpvalue = this.getSectionHtml(options);
    const sectionContent = helpvalue
      ? { type: FormLayoutElementType.Help, helpvalue }
      : undefined;
    const container = this.toContainer({ direction: 'row' }, items);
    return {
      type: FormLayoutElementType.Flex,
      htmlClass: 'form-builder-section form-builder-column',
      items: compact([sectionContent, container]),
    };
  }

  static toContainer(
    options: ICustomFormContainerOptions,
    items: AnyFormLayout[]
  ): AnyFormLayout {
    const isRow = options.direction === 'row';
    return {
      type: FormLayoutElementType.Flex,
      htmlClass: isRow
        ? 'form-builder-container form-builder-row'
        : 'form-builder-container form-builder-column',
      items,
    };
  }

  static getSchemaProperties(
    element: AnyCustomFormElement
  ): IFormSchemaPropertyMap {
    const childProperties = element.children.map((child) =>
      this.getSchemaProperties(child)
    );
    const parentProperties = this.getElementSchemaProperties(element);
    return this.mergeSchemaProperties([...childProperties, parentProperties]);
  }

  static getElementSchemaProperties(
    element: AnyCustomFormElement
  ): IFormSchemaPropertyMap {
    if (!CustomFormElement.isInputField(element)) {
      return {};
    }
    const property = this.buildSchemaProperty(element);
    if (!element.options.dataKey || !property) {
      return {};
    }
    return { [element.options.dataKey]: property };
  }

  static buildSchemaProperty(
    component: AnyCustomFormElement
  ): IFormSchemaProperty | undefined {
    switch (component.type) {
      case CustomFormElementType.Container:
      case CustomFormElementType.Section:
      case CustomFormElementType.Content:
        return;
      case CustomFormElementType.Text:
        return {
          type: FormSchemaPropertyType.String,
          title: component.options.label,
          required: component.options.required,
        };
      case CustomFormElementType.Textarea:
        return {
          type: FormSchemaPropertyType.String,
          title: component.options.label,
          required: component.options.required,
        };
      case CustomFormElementType.Number:
        return {
          type: FormSchemaPropertyType.Number,
          title: component.options.label,
          required: component.options.required,
        };
      case CustomFormElementType.Checkbox:
        const label = compact([
          component.options.label,
          component.options.required ? '*' : undefined,
        ]).join('');
        const requiredProperties = component.options.required
          ? { const: true, required: true }
          : {};
        return {
          type: FormSchemaPropertyType.Boolean,
          title: label,
          ...requiredProperties,
        };
      case CustomFormElementType.Date:
        return {
          type: FormSchemaPropertyType.String,
          title: component.options.label,
          required: component.options.required,
        };
      case CustomFormElementType.Dropdown:
        return {
          type: FormSchemaPropertyType.String,
          title: component.options.label,
          enum: component.options.options,
          default: first(component.options.options),
          required: component.options.required,
        };
      case CustomFormElementType.Signature:
        return {
          type: FormSchemaPropertyType.String,
          title: component.options.label,
          required: component.options.required,
        };
      default:
        return;
    }
  }

  static getSectionHtml(
    options: ICustomFormSectionOptions
  ): string | undefined {
    return options.title
      ? `<h3 class="!m-0 !font-bold pt-8">${options.title}</h3>`
      : undefined;
  }

  static getContentHtml(
    options: ICustomFormContentOptions,
    editorExtensions: AnyExtension[]
  ): string | undefined {
    const contentHtml = versionedSchemaToHtml(
      options.content,
      editorExtensions
    );
    return `<div class="mat-typography">${contentHtml}</div>`;
  }

  static mergeSchemaProperties(
    schemaProperties: IFormSchemaPropertyMap[]
  ): IFormSchemaPropertyMap {
    return schemaProperties.reduce(
      (acc, properties) => ({
        ...acc,
        ...properties,
      }),
      {}
    );
  }

  static propertyMapToSchema(propertyMap: IFormSchemaPropertyMap): IFormSchema {
    const schema = toPairs(propertyMap).reduce(
      (acc: IFormSchema, [key, property]) =>
        set(acc, AngularJsonSchemaForm.getSchemaPropertyPath(key), property),
      { properties: {}, type: 'object' }
    );
    return this.addObjectPropertyTypes(schema) as IFormSchema;
  }

  static addObjectPropertyTypes(item: object): object {
    if (this.hasProperties(item)) {
      const properties = mapValues(item.properties, (property) =>
        this.addObjectPropertyTypes(property)
      );
      return { ...item, properties, type: 'object' };
    }
    return item;
  }

  static hasProperties(item: unknown): item is IHasProperties {
    return isObject(item) && isObject(item.properties);
  }
}
