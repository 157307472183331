<pt-loader-button
  type="flat"
  color="primary"
  [cta]="true"
  [matMenuTriggerFor]="menu"
  [loading]="adding$ | async"
  [ngClass]="{ adding: adding$ | async, disabled: disabled$ | async }"
  [disabled]="disabled$ | async"
>
  <div
    class="flex"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
  >
    <mat-icon *ngIf="(adding$ | async) === false"> health_and_safety </mat-icon>

    <div fxLayout="column" fxLayoutAlign="center start">
      <div class="label">Claim</div>
      <div class="subtext mat-caption">
        <ng-container *ngIf="disabled$ | async; else showInfo">
          Not configured
        </ng-container>
        <ng-template #showInfo>
          <ng-content></ng-content>
        </ng-template>
      </div>
    </div>
  </div>
</pt-loader-button>

<mat-menu #menu="matMenu" class="no-padding">
  <ng-container *ngFor="let group of groups$ | async; trackBy: trackByGroup">
    <div class="mat-caption px-2 pt-2">{{ group.label }}</div>

    <ng-container *ngFor="let option of group.options; trackBy: trackByOption">
      <pt-action-list-button
        *ngIf="option.provider.isEnabled$ | async"
        [imageUrl]="option.imageUrl"
        [icon]="option.icon"
        [label]="option.label"
        [isDisabled]="(option | map: canCapture$ : this | async) === false"
        (click)="providerSelected.emit(option.provider)"
      />
    </ng-container>
    <mat-divider />
  </ng-container>
</mat-menu>
