import {
  CustomFormConfigurationCollection,
  ReservedCustomFormId,
  IBrand,
  ICustomFormConfiguration,
  ICustomFormContent,
  IJsonSchemaFormWithResolverConfig,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  CollectionReference,
  DocumentReference,
  Firestore,
  FirestoreTransactionRunner,
  IReffable,
  WithRef,
  addDoc,
  getEnumValues,
  initFirestoreModel,
  initTimestamps,
  multiSort,
  query$,
  sortTimestamp,
  subCollection,
  undeletedQuery,
} from '@principle-theorem/shared';
import { first } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Brand } from '../brand';
import { CustomFormContent } from './custom-form-content';
import { toMedicalHistoryForm } from './schema/medical-history-form-schema';

export class CustomFormConfiguration {
  static init(
    overrides: AtLeast<ICustomFormConfiguration, 'name'>
  ): ICustomFormConfiguration {
    return {
      ...initFirestoreModel(),
      description: '',
      ...overrides,
    };
  }

  static brandRef(
    customForm: IReffable<ICustomFormConfiguration>
  ): DocumentReference<IBrand> {
    return Firestore.getParentDocRef<IBrand>(customForm.ref);
  }

  static contentHistoryCol(
    customForm: IReffable<ICustomFormConfiguration>
  ): CollectionReference<ICustomFormContent> {
    return subCollection<ICustomFormContent>(
      customForm.ref,
      CustomFormConfigurationCollection.ContentHistory
    );
  }

  static async contentHistory(
    customForm: IReffable<ICustomFormConfiguration>
  ): Promise<WithRef<ICustomFormContent>[]> {
    const history = await Firestore.getDocs(
      undeletedQuery(this.contentHistoryCol(customForm))
    );
    return history.sort((a, b) => sortTimestamp(a.updatedAt, b.updatedAt));
  }

  static contentHistory$(
    customForm: IReffable<ICustomFormConfiguration>
  ): Observable<WithRef<ICustomFormContent>[]> {
    return query$(undeletedQuery(this.contentHistoryCol(customForm))).pipe(
      multiSort((a, b) => sortTimestamp(a.updatedAt, b.updatedAt))
    );
  }

  static async latestContent(
    customForm: IReffable<ICustomFormConfiguration>
  ): Promise<WithRef<ICustomFormContent> | undefined> {
    const history = await this.contentHistory(customForm);
    return first(history);
  }

  static latestContent$(
    customForm: IReffable<ICustomFormConfiguration>
  ): Observable<WithRef<ICustomFormContent> | undefined> {
    return this.contentHistory$(customForm).pipe(
      map((history) => first(history))
    );
  }

  static isSystemForm(
    customForm: IReffable<ICustomFormConfiguration>
  ): boolean {
    const systemCustomFormIds: string[] = getEnumValues(ReservedCustomFormId);
    return systemCustomFormIds.includes(customForm.ref.id);
  }

  static isDraft(customForm: WithRef<ICustomFormConfiguration>): boolean {
    return !customForm.activeContent;
  }

  static async getContent(
    customForm: WithRef<ICustomFormConfiguration>
  ): Promise<WithRef<ICustomFormContent> | undefined> {
    if (!customForm.activeContent) {
      return;
    }
    return Firestore.getDoc(customForm.activeContent);
  }

  static getContent$(
    customForm: WithRef<ICustomFormConfiguration>
  ): Observable<WithRef<ICustomFormContent> | undefined> {
    if (!customForm.activeContent) {
      return of(undefined);
    }
    return Firestore.doc$(customForm.activeContent);
  }

  static async addToBrand(
    brand: IReffable<IBrand>,
    customForm: ICustomFormConfiguration,
    content?: ICustomFormContent,
    setActive: boolean = false
  ): Promise<DocumentReference<ICustomFormConfiguration>> {
    return FirestoreTransactionRunner.run(async (transaction) => {
      const customFormRef = await addDoc<ICustomFormConfiguration>(
        Brand.customFormConfigCol(brand),
        customForm,
        undefined,
        transaction.transaction
      );
      const customFormContent = CustomFormContent.init(content);
      const customFormContentRef = await addDoc(
        CustomFormConfiguration.contentHistoryCol({ ref: customFormRef }),
        customFormContent,
        undefined,
        transaction.transaction
      );
      if (setActive) {
        await Firestore.patchDoc(
          customFormRef,
          { activeContent: customFormContentRef },
          transaction.transaction
        );
      }
      return customFormRef;
    });
  }

  static async makeACopy(
    config: WithRef<ICustomFormConfiguration>,
    useContent?: Partial<ICustomFormContent>
  ): Promise<DocumentReference<ICustomFormConfiguration>> {
    const configCopy = CustomFormConfiguration.init({
      ...config,
      ...initTimestamps(),
      name: `${config.name} (Copy)`,
      activeContent: undefined,
    });

    const content = useContent ?? (await this.getContent(config));
    const contentCopy = CustomFormContent.init({
      ...content,
      ...initTimestamps(),
    });

    return this.addToBrand(
      { ref: this.brandRef(config) },
      configCopy,
      contentCopy
    );
  }

  static async getJsonSchemaForm(
    configRef: DocumentReference<ICustomFormConfiguration>,
    resolvedConfig?: WithRef<ICustomFormConfiguration>
  ): Promise<IJsonSchemaFormWithResolverConfig | undefined> {
    const config = resolvedConfig
      ? resolvedConfig
      : await Firestore.safeGetDoc(configRef);
    const content = config
      ? await CustomFormConfiguration.getContent(config)
      : undefined;
    const jsonSchema = content
      ? CustomFormContent.getJsonSchemaForm(content)
      : undefined;
    return this.isMedicalHistoryForm({ ref: configRef })
      ? toMedicalHistoryForm(jsonSchema)
      : jsonSchema;
  }

  static isMedicalHistoryForm(
    customForm: IReffable<ICustomFormConfiguration>
  ): boolean {
    return customForm.ref.id === ReservedCustomFormId.MedicalHistory;
  }
}
