import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IChat } from '@principle-theorem/principle-core/interfaces';
import { DocumentReference } from '@principle-theorem/shared';
import { ChatNotificationsService } from '../../chat-notifications.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'pr-chat-list-dialog',
    templateUrl: './chat-list-dialog.component.html',
    styleUrl: './chat-list-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ChatListDialogComponent {
  constructor(
    private _chatNotifications: ChatNotificationsService,
    private _dialogRef: MatDialogRef<ChatListDialogComponent>
  ) {}

  showChat(chatRef: DocumentReference<IChat>): void {
    this._dialogRef.close();
    this._chatNotifications.showPopupChat(chatRef);
  }
}
